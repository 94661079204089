import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import { Node } from 'slate';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import * as Validator from "util/Validation";
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogActions, DialogContent, Slide, DialogTitle, Grid, IconButton,
    Accordion, AccordionSummary, AccordionDetails, CircularProgress
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { findRequirementPreviousInteraction } from 'util/index'
import SqureButton from "Components/Common/SqureButton";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import { showSnackBar } from "redux/snackbar/action";
import { askSellerMultiRequirement, askSellerWithSellerResponse } from "redux/templates/evaluation/action";
import { tooglePreviousInteractionDialog, addRequirementResponse } from "redux/evaluation/criteria/action"
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.evaludationCriteria.showPreviousInteractionDialog,
    responseTab: state.evaludationCriteria.responseTab,
    product: state.templateEvaluation.multiSelectAskProduct,
    isOpenAskSeller: state.templateEvaluation.dialogEvaluationAskseller,
    askSellerWithSellerResponseProgress: state.templateEvaluation.askSellerWithSellerResponseProgress,
    askSellerWithSellerResponseError: state.templateEvaluation.askSellerWithSellerResponseError,
    previousInteractions: state.evaludationCriteria.previousInteractions,
    evaluation: state.evaluationMetaData.evaluation,
    selectedRequirementId: state.evaludationCriteria.selectedRequirementId,
    selectedProductId: state.evaludationCriteria.selectedProductId,
    addResponseProgress: state.evaludationCriteria.addResponseProgress,
    requirementResponses: state.evaludationCriteria.requirementResponses,
    requirementUserResponses: state.evaludationCriteria.requirementUserResponses,
});

const connectionActions = {
    tooglePreviousInteractionDialog: tooglePreviousInteractionDialog,
    addRequirementResponse: addRequirementResponse,
    showSnackBar: showSnackBar,
    askSellerMultiRequirement: askSellerMultiRequirement,
    askSellerWithSellerResponse: askSellerWithSellerResponse
}

var connector = connect(connectedProps, connectionActions);

const styles = () => createStyles({
    container: {
        '& [class*="MuiAccordionDetails-root-"]': {
            margin: 0,
            padding: '0px 1em'
        }
    },
    workflow: {
        cursor: 'pointer',
        '& [class*="MuiAccordionSummary-content"]': {
            margin: 0
        }
    },
    title: {
        margin: 0,
        padding: 0,
        fontSize: 16,
        fontWeight: 600,
    },
    subTitle: {
        margin: 0,
        marginBottom: 10,
        padding: 0,
        fontSize: 14,
        fontWeight: 500,
    },
    date: {
        fontSize: 12,
        fontWeight: 400,
        color: 'gray'
    },
    dec: {
        margin: 0,
        padding: 0,
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 10,
        color: 'gray'
    },
    req: {
        width: '100%',
        padding: '5px 10px',
        marginBottom: 5,
        borderRadius: 5,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'lightgray',
        }
    },
    selected: {
        padding: '3px 8px',
        border: '2px solid hsl(187deg 55% 82%)',
        boxShadow: '0px 1px 10px -5px rgb(157 133 133 / 77%)',
        backgroundColor: 'hsl(196deg 72% 93%)',
        '&:hover': {
            backgroundColor: 'hsl(196deg 72% 93%)',
        }
    },
    headerContainer: {
        flex: 1
    }
});

class PreviousInteractionDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedReq: false,
            expanded: 0,
        }
    }

    componentDidUpdate(prevProps) {

        if (!this.props.addResponseProgress && prevProps.addResponseProgress) {
            if (this.props.isOpen) {
                this.handleClose()
            }
        }
        if (!this.props.askSellerWithSellerResponseProgress && prevProps.askSellerWithSellerResponseProgress) {
            if (this.props.isOpen) {
                if (this.props.askSellerWithSellerResponseError === null) {
                    this.handleClose()
                    this.props.showSnackBar("Request sent to seller", "success", 3000)
                }
            } else {
                this.props.showSnackBar("Request failed to sent to seller", "error", 3000)
            }
        }
    }


    handleChange = (panel) => (event, newExpanded) => {
        this.setState({ expanded: newExpanded ? panel : false, selectedReq: false });
    };

    handleClose = () => {
        this.setState({
            selectedReq: false,
            expanded: 0,
        }, () => {
            this.props.tooglePreviousInteractionDialog()
        })
    }

    getDateFormat = (date) => {
        let rt = date
        if (date) {
            rt = " - " + format(parseISO(date), "MMM do yyyy")
        }
        return rt
    }

    handleMyResponse = () => {
        const { selectedReq } = this.state;
        let response_options = {};
        let note = selectedReq.note;
        var activeReq = this.props.requirementResponses;
        var activeResponse = (this.props.requirementUserResponses === null || this.props.requirementUserResponses.length <= 0) ? null : this.props.requirementUserResponses[0];
        const answerType = activeReq?.answer_type;
        const value = activeResponse == null ? null : activeResponse.Response;
        let addOther = activeResponse?.UserOptions.filter((item) => item.OptionID === 0).sort((a, b) => b.ID - a.ID);
        if (answerType === 5 || answerType === 6) {
            response_options = {
                answer: (activeResponse?.UserOptions[0]?.Answer || '')
            }
        } else if (answerType === 4 || answerType === 1 || answerType === 7) {
            response_options = {
                answer: answerType === 1 || answerType === 7 ? 'Done' : (activeResponse?.UserOptions[0]?.Answer || '')
            }
            if (answerType === 7 && Validator.isJson(note)) {
                let newNote = JSON.parse(note)
                newNote = newNote.map(n => Node.string(n)).join(' ')
                note = newNote
                if (activeReq?.options && activeReq?.options.length === 1) {
                    const option = activeReq?.options[0]
                    try {
                        const limit = parseInt(option.Title)
                        if (limit > 0) {
                            note = note.substring(0, limit)
                        }
                    } catch (e) { }
                }
            }
        } else {
            response_options = {
                option_ids: (activeResponse == null || activeResponse.UserOptions == null ? [] : activeResponse.UserOptions.map((i) => {
                    return i.OptionID
                })),
                answer: answerType === 2 ? (addOther[0]?.Answer) : (activeResponse?.UserOptions[0]?.Answer || '')
            }
        }
        this.props.addRequirementResponse(
            this.props.match.params.evaluation_id,
            this.props.selectedProductId,
            this.props.selectedRequirementId,
            {
                note, value, response_options,
            }
        );
    }


    sendWithoutResponse = () => {
        const { current_requirement } = findRequirementPreviousInteraction(this.props.selectedRequirementId, this.props.previousInteractions, 'seller')
        let description = current_requirement.description
        if (description.length === 0) {
            description = current_requirement.name
        }
        this.props.askSellerMultiRequirement(
            this.props.product.VendorUUID,
            [
                {
                    'requirementId': parseInt(current_requirement.id),
                    'response': description
                }
            ]
        )
    }

    sendWithSellerResponse = () => {
        let response = this.state.selectedReq.note;
        var activeReq = this.props.requirementResponses;
        if (activeReq?.answerType === 7 && Validator.isJson(response)) {
            let newNote = JSON.parse(response)
            newNote = newNote.map(n => Node.string(n)).join(' ')
            response = newNote
            if (activeReq?.options && activeReq?.options.length === 1) {
                const option = activeReq?.options[0]
                try {
                    const limit = parseInt(option.Title)
                    if (limit > 0) {
                        response = response.substring(0, limit)
                    }
                } catch (e) { }
            }
        }

        let description = activeReq.description
        if (description.length === 0) {
            description = activeReq.name
        }
        this.props.askSellerWithSellerResponse(
            this.props.product.VendorUUID,
            {
                request: [
                    {
                        requirementId: this.props.selectedRequirementId,
                        seller_response: response,
                        response: description
                    }
                ]
            }
        )
    }

    render() {
        const { previousInteractions, classes, responseTab } = this.props;
        const { current_requirement, previous_requirements } = findRequirementPreviousInteraction(
            this.props.selectedRequirementId,
            previousInteractions,
            responseTab === 0 ? 'buyer' : 'seller'
        )
        return (
            <Dialog
                onClose={this.handleClose}
                aria-labelledby="app-integrationDialog"
                open={this.props.isOpen}
                TransitionComponent={Transition}
                disableBackdropClick={true}
                fullWidth={true}
                maxWidth={"md"}
                scroll="body"
                id="evMetaDialog"
            >
                <DialogTitle>
                    <Grid container justifyContent='space-between'>
                        <div className={classes.headerContainer}>
                            <p className={classes.title}>{current_requirement?.name}</p>
                            <p className={classes.dec}>
                                <SlateReadonlyField initValue={current_requirement?.description} />
                            </p>
                        </div>
                        <IconButton style={{ width: 44, height: 44 }} onClick={this.handleClose}>
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.container}>
                        {previous_requirements && previous_requirements.map((obj, index) => (
                            <Accordion
                                key={index}
                                expanded={this.state.expanded === index}
                                onChange={this.handleChange(index)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={obj.evaluation_id}
                                    id={obj.evaluation_id}
                                    className={classes.workflow}
                                >
                                    <h1 className={classes.title}>Evaluation: {obj.evaluation_name}<span className={classes.date}>{this.getDateFormat(obj.evaluation_date)}</span></h1>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ width: '100%' }}>
                                        {obj.requirements.map(req => (
                                            <div className={classNames(classes.req, req.id === this.state.selectedReq?.id ? classes.selected : {})} key={req.id} onClick={(e) => {
                                                e.stopPropagation()
                                                let selectedReq = req
                                                if (this.state?.selectedReq?.id === req.id) selectedReq = false
                                                this.setState({ selectedReq })
                                            }}>
                                                <h2 className={classes.subTitle}>Requirement: {req.requirement_name}</h2>
                                                <h2 className={classes.dec}>
                                                    <SlateReadonlyField initValue={req.requirement_description} />
                                                </h2>
                                                <div>
                                                    <p>Response<span className={classes.date}>{this.getDateFormat(req.updated_at)}</span></p>
                                                    <SlateReadonlyField initValue={req.note} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    {(this.props.askSellerWithSellerResponseProgress || this.props.addResponseProgress) && <CircularProgress />}
                    {!this.props.addResponseProgress &&
                        (this.props.responseTab === 0 || this.props.responseTab === 1) && (
                            <SqureButton
                                disabled={this.state.selectedReq === false}
                                variant="contained"
                                onClick={this.handleMyResponse}>
                                USE AS MY RESPONSE
                            </SqureButton>
                        )}
                    {!this.props.askSellerWithSellerResponseProgress && this.props.responseTab === 2 && (
                        <>
                            <SqureButton
                                disabled={this.state.selectedReq === false}
                                variant="contained"
                                onClick={this.sendWithSellerResponse}
                            >
                                SEND WITH SELLER RESPONSE
                            </SqureButton>
                            &nbsp;
                            <SqureButton
                                variant="outline"
                                onClick={this.sendWithoutResponse}
                            >
                                SEND WITHOUT SELLER RESPONSE
                            </SqureButton>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        )
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(PreviousInteractionDialog));