import { Dialog, DialogContent, Slide, Typography, FormControl, MenuItem, Select, CircularProgress, Button, Grid, InputAdornment } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { connect } from "react-redux";

import * as Environment from "util/Environment";
import { CommonFn } from "services/commonFn";
import axios from "axios";

import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import SqureButton from "Components/Common/SqureButton";
import Alert from "@material-ui/lab/Alert";
import { hideAddUpdateTaskDialog, addNewProductTask, updateProductTask, fetchProductTasks } from "redux/evaluation/productTask/action";
import { showSnackBar } from "redux/snackbar/action";
import Tasks from "../ProductTasks/Tasks";
import { withAsyncPaginate } from "react-select-async-paginate";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "assets/images/calendar.svg"
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import Image from 'Components/Common/image.jsx';
import ReactSelect from "react-select";
import { taskTypes } from "util/AppCatalogTypeOptions"

const CreatableAsyncPaginate = withAsyncPaginate(ReactSelect);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.productTasks.showAddTaskDialog,
  addUpdateTaskData: state.productTasks.addUpdateTaskData,
  isNewTask: state.productTasks.isNewTask,
  addNewTaskProgress: state.productTasks.addNewTaskProgress,
  addNewTaskError: state.productTasks.addNewTaskError,
  newTaskData: state.productTasks.newTaskData,
  updateNewTaskProgress: state.productTasks.updateNewTaskProgress,
  updateNewTaskError: state.productTasks.updateNewTaskError,
  fetchProductTasksProgress: state.productTasks.fetchProductTasksProgress,
  fetchProductTasksError: state.productTasks.fetchProductTasksError,
  productTasks: state.productTasks.productTasks,
  deleteProductTaskProgress: state.productTasks.deleteProductTaskProgress,
  deleteProductTaskError: state.productTasks.deleteProductTaskError,
  fetchExpentDirectoryProductsProgress: state.expentDirectory.fetchExpentDirectoryProductsProgress,
  fetchExpentDirectoryProductsError: state.expentDirectory.fetchExpentDirectoryProductsError,
  expentDirectoryProducts: state.expentDirectory.products,
});

const connectionActions = {
  hideDialog: hideAddUpdateTaskDialog,
  addNewProductTask: addNewProductTask,
  updateProductTask: updateProductTask,
  fetchProductTasks: fetchProductTasks,
  showSnackBar: showSnackBar,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
      marginBottom: 60,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    body: {
      paddingTop: 20,
      "& [class*='editableField']": {
        maxHeight: "105px !important;",
        minHeight: "50px !important",
      },
      "& [class*='MuiFormHelperText-root']": {
        color: "red !important;",
        fontSize: 14,
      },
    },
    formControl: {
      width: "100%",
      "& > div": {
        color: "#282D30",
        fontSize: theme.spacing(2.2),
      },
    },
    filterTitle: {
      marginBottom: theme.spacing(1),
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    formItem: {
      marginBottom: 20,
    },
    formItem_p1: {
      marginLeft: 10,
      "& [class*='MuiFormLabel-root']": {
        color: '#475867'
      },
      "& [class*=MuiOutlinedInput-root]": {
        minHeight: 40,
        height: 40,
        color: '#282D30'
      },
      "& [class*=react-datepicker-wrapper]": {
        display:'block'
      }
    },
    bottomActions: {
      textAlign: "right",
    },
    actionBtn: {
      marginLeft: 10,
    },
    alert: {
      marginBottom: 10,
      marginTop: 25,
    },
    // viewBtn: {
    //   cursor: 'pointer',
    //   color: '#1f73b7',
    //   fontSize: 12
    // }
  });

class AddUpdateTaskDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      note: "",
      taskType: 1,
      priorityType: 1,
      taskStatus: 1,
      types: taskTypes,
      priorityTypes: [
        {
          name: "Low",
          value: 1,
        },
        {
          name: "Medium",
          value: 2,
        },
        {
          name: "High",
          value: 3,
        },
      ],
      statusMap: [
        {
          name: "New",
          value: 1,
        },
        {
          name: "In Progress",
          value: 2,
        },
        {
          name: "Completed",
          value: 3,
        },
      ],
      showView: false,
      updateTaskData: null,
      isNewTask: true,
      productId: null,
      dueDate: new Date()
    };

    this.addTask = this.addTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.editSelectedTask = this.editSelectedTask.bind(this);
  }

  componentDidMount() {
    this.setState({
      isNewTask: this.props.isNewTask,
      taskType: this.props.addUpdateTaskData.addForTaskType || 1,
    });
    let product = this.props.addUpdateTaskData?.product;
    if (!this.props.addUpdateTaskData.enableProductSelection) {
      this.setState({ productId: product?.ID || product?.productID });
    }

    if (this.props.isNewTask) {
      this.fetchTasks();
      // this.getOptions('');
    } else {
      this.updateFormDetails();
    }
  }

  componentDidUpdate(prevProps) {
    const { addNewTaskProgress, addNewTaskError, updateNewTaskProgress, updateNewTaskError } = this.props;
    if (!addNewTaskProgress && prevProps.addNewTaskProgress) {
      if (addNewTaskError === null) {
        this.props.showSnackBar("Product task added successfully.", "success", 3000);
        this.props.hideDialog();
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }

    if (!updateNewTaskProgress && prevProps.updateNewTaskProgress) {
      if (updateNewTaskError === null) {
        this.props.showSnackBar("Product task updated successfully.", "success", 3000);
        this.props.hideDialog();
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }
  }

  fetchTasks() {
    const { addUpdateTaskData } = this.props;
    let product = addUpdateTaskData?.product;
    let requirement = addUpdateTaskData?.requirement;
    var queryParams = {
      page: 0,
      pageSize: 100,
      requirement_ids: [requirement?.id],
      types: [1, 2, 3],
      priorities: [1, 2, 3],
      status: [1, 2, 3],
    };
    this.props.fetchProductTasks(product?.ID, queryParams);
  }

  clearError() {
    this.setState({
      error: "",
      success: "",
    });
  }

  addTask() {
    this.clearError();
    const { note, taskType, priorityType, dueDate } = this.state;
    const { addUpdateTaskData } = this.props;
    let requirement = addUpdateTaskData?.requirement;

    if (addUpdateTaskData?.enableProductSelection && !this.state.productId) {
      this.props.showSnackBar("Please select product.", "error", 3000);
      return;
    }

    var bodyText = document.querySelectorAll("#productTaskDetails .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "");
    console.log(bodyText);
    if (String(note).trim().length === 0 || String(bodyText).trim().length === 0) {
      this.setState({
        error: "Please add task details",
      });
      return;
    }

    var params = {
      requirement_id: requirement.id,
      name: note,
      type: taskType,
      priority: priorityType,
      due_date: dueDate.toISOString(),
      status: 1,
    };

    this.props.addNewProductTask(this.state.productId, params);
  }

  updateTask() {
    this.clearError();
    const { note, taskType, priorityType, updateTaskData, taskStatus,dueDate } = this.state;
    const { addUpdateTaskData } = this.props;
    let pId = addUpdateTaskData?.productId;
    var bodyText = document.querySelectorAll("#productTaskDetails .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "");
    console.log(bodyText);
    if (String(note).trim().length === 0 || String(bodyText).trim().length === 0) {
      this.setState({
        error: "Please add task details",
      });
      return;
    }

    var params = {
      name: note,
      type: taskType,
      priority: priorityType,
      status: taskStatus,
      due_date: dueDate.toISOString(),
    };

    this.props.updateProductTask(pId, updateTaskData?.ID, params, this.props.addUpdateTaskData?.from);
  }

  updateFormDetails() {
    const { addUpdateTaskData } = this.props;
    let task = addUpdateTaskData.task;
    console.log(addUpdateTaskData, "addUpdateTaskData");
    this.setState({
      updateTaskData: task,
      isNewTask: false,
      showView: false,
      taskType: task?.Type,
      priorityType: task?.Priority,
      taskStatus: task?.Status,
      note: task?.Name,
      dueDate: task?.DueDate === null || task?.DueDate === '' ? null : new Date(task?.DueDate),
    });
  }

  editSelectedTask(task) {
    console.log(task, "TaskDataaaaaaaaaaa");
    this.setState({
      updateTaskData: task,
      isNewTask: false,
      showView: false,
      taskType: task?.Type,
      priorityType: task?.Priority,
      taskStatus: task?.Status,
      note: task?.Name,
      dueDate: task?.DueDate === null || task?.DueDate === '' ? null : new Date(task?.DueDate),
    });
  }

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("SEARCH");
    const timestamp = new Date().getTime();
    // if (!inputValue) {
    //     return {
    //         options: [],
    //         hasMore: false,
    //         additional: {
    //             page: 0,
    //         },
    //     };
    // }
    let formData = {
      field_setname: "all",
      text: inputValue,
      page,
      page_size: 150,
      sort_fields: [],
      filter_fields: {},
      merge_existing_others: true,
    };
    let url = `${api_server}/v2/dsl/expent_catalogue?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let products = response?.data?.body?.items || [];
    let total = response?.data?.body?.total || 0;

    products = products.map((m) => {
      m.value = parseInt(m.id);
      m.label = m.name;
      m.isProduct = true;
      m.productStates = m.states;
      m.isDisabled = m.states?.blocked !== undefined;
      return m;
    });
    return {
      options: products,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  ProductLabels = (props) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
        <div>
          <span style={{ color: "#000", fontSize: "16px" }}>{props.label}</span>&nbsp;
          {props.isProduct !== undefined && props.isProduct && <span style={{ color: "#6C6C6C", fontSize: "12px" }}>By {props.company_name}</span>}
        </div>
      </div>
    );
  };

  render() {
    const classes = this.props.classes;
    const { addUpdateTaskData } = this.props;
    const { showView, isNewTask } = this.state;
    let product = addUpdateTaskData?.product;
    let requirement = addUpdateTaskData?.requirement;
    let productName = product?.Name || product?.name || "product";

    return (
      <Dialog
        onClose={this.props.hideDialog}
        aria-labelledby="app-integrationDialog"
        open={this.props.isOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        id="productTaskDetails"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.hideDialog()}>
            <CloseIcon />
          </div>

          {isNewTask && !showView && (
            <Typography variant={"h4"} className={classes.title}>
              {addUpdateTaskData?.enableProductSelection ? "Add Risk" : "Add Tasks for " + productName} &nbsp;&nbsp;
              {!this.props.addUpdateTaskData.addOnly && (
                <Button
                  className={classes.viewBtn}
                  variant={"contained"}
                  color="primary"
                  size="small"
                  onClick={() => {
                    this.setState({
                      showView: true,
                    });
                  }}
                >
                  View All Tasks
                </Button>
              )}
            </Typography>
          )}

          {isNewTask && showView && (
            <Typography variant={"h4"} className={classes.title}>
              Tasks for {addUpdateTaskData?.product?.Name} &nbsp;&nbsp;
              <Button
                className={classes.viewBtn}
                variant={"contained"}
                color="primary"
                size="small"
                onClick={() => {
                  this.setState({
                    showView: false,
                  });
                }}
              >
                Add Task
              </Button>
            </Typography>
          )}

          {!isNewTask && (
            <Typography variant={"h4"} className={classes.title}>
              Update Task
            </Typography>
          )}

          {!showView && (
            <div className={classes.body}>
              {false && (
                <div className={classes.alert}>
                  {this.state.error.length > 0 && (
                    <Alert variant="filled" severity="error">
                      {this.state.error}
                    </Alert>
                  )}
                </div>
              )}

              {!addUpdateTaskData?.enableProductSelection && (
                <div className={classes.formItem}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Task Type
                  </Typography>
                  <FormControl variant="outlined" className={classes.formControl} size="small">
                    <Select
                      disabled={this.props.addUpdateTaskData.addOnly || !isNewTask ? true : false}
                      value={this.state.taskType}
                      onChange={(e) => {
                        this.setState({ taskType: e.target.value });
                      }}
                    >
                      {this.state.types.map((item, k) => (
                        <MenuItem value={item.value} style={{ fontSize: 13 }}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}

              {addUpdateTaskData?.enableProductSelection && (
                <div className={classes.formItem}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Select Product
                  </Typography>
                  <FormControl variant="outlined" className={classes.formControl} size="small">
                    <CreatableAsyncPaginate
                      isSearchable={true}
                      classNamePrefix="select"
                      placeholder="Name of the vendor"
                      // onInputChange={(e) => {
                      //     this.getOptions(e)
                      // }}
                      onChange={(e) => {
                        if (e?.value) {
                          this.setState({
                            productId: e?.value,
                          });
                        }
                      }}
                      additional={{
                        page: 0,
                      }}
                      formatOptionLabel={this.ProductLabels}
                      isValidNewOption={(input) => input.length > 0}
                      loadOptions={this.getOptions}
                    />
                  </FormControl>
                </div>
              )}

              <div className={classes.formItem}>
                <Typography variant={"h4"} className={classes.filterTitle}>
                  {addUpdateTaskData?.enableProductSelection ? "Risk" : "Task"} Details
                </Typography>
                <SlateInputField
                  folderPath={`/evaluation/${this.props.match?.params?.evaluation_id}`}
                  style={{ margin: 0 }}
                  as={SlateInputField}
                  onChangeEvent={(value) => {
                    this.setState({
                      note: value,
                      error: "",
                    });
                  }}
                  maxLength={5}
                  initValue={this.state.note}
                  // formControlStyle={{ overflow: "overlay" }}
                  textContainerStyle={{ color: "#707070", fontSize: 14 }}
                  helperText={this.state.error}
                />
              </div>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xl={6} lg={6} md={6} sm={6} className={classes.headSection}>
                  <div className={classes.formItem}>
                    <Typography variant={"h4"} className={classes.filterTitle}>
                      Priority
                    </Typography>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                      <Select
                        value={this.state.priorityType}
                        onChange={(e) => {
                          this.setState({ priorityType: e.target.value });
                        }}
                      >
                        {this.state.priorityTypes.map((item, k) => (
                          <MenuItem value={item.value} style={{ fontSize: 13 }}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} className={classes.headSection}>
                  <div className={classes.formItem_p1}>
                    <Typography variant={"h4"} className={classes.filterTitle}>
                      Due Date
                    </Typography>
                    <DatePicker
                      selected={this.state.dueDate}
                      onChange={date => {
                        this.setState({ dueDate: date });
                      }}
                      portalId="productTaskDetails"
                      dateFormat="MMM do yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <OutlinedInput
                          readOnlyInput={true}
                          error={''}
                          helperText={''}
                          startAdornment={
                            <InputAdornment>
                              <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                            </InputAdornment>
                          }
                        />
                      }
                    />
                  </div>
                </Grid>
              </Grid>

              {!isNewTask && (
                <div className={classes.formItem}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Status
                  </Typography>
                  <FormControl variant="outlined" className={classes.formControl} size="small">
                    <Select
                      value={this.state.taskStatus}
                      onChange={(e) => {
                        this.setState({ taskStatus: e.target.value });
                      }}
                    >
                      {this.state.statusMap.map((item, k) => (
                        <MenuItem value={item.value} style={{ fontSize: 13 }}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              <div className={classes.bottomActions}>
                <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>
                  Cancel
                </SqureButton>

                {isNewTask && (
                  <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.addTask}>
                    {this.props.addNewTaskProgress ? <CircularProgress style={{ width: 24, height: 24 }} /> : "Add"}
                  </SqureButton>
                )}

                {!isNewTask && (
                  <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.updateTask}>
                    {this.props.updateNewTaskProgress ? <CircularProgress style={{ width: 24, height: 24 }} /> : "Update"}
                  </SqureButton>
                )}
              </div>
            </div>
          )}

          {showView && (
            <div className={classes.body}>
              <Tasks
                key={"tasks_for_" + requirement.id}
                from={"EVALUATION"}
                showTypeFilter={true}
                showPriorityFilter={true}
                showStatusFilter={true}
                filterTypes={0}
                type={123}
                product={product}
                productId={product?.ID}
                requirementIds={[requirement.id]}
                updateAccess={true}
                onDelete={(task) => { }}
                onEdit={(task) => {
                  this.editSelectedTask(task);
                }}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

AddUpdateTaskDialog.defaultProps = {
  isWorkFlow: false,
  addForTaskType: 1,
};

export default connector(compose(withRouter, withStyles(styles))(AddUpdateTaskDialog));
