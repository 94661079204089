import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { Dialog, DialogContent, RadioGroup, Radio, MenuItem, FormControl, FormControlLabel, Grid, Slide, DialogTitle, Typography, IconButton, DialogActions, Button } from "@material-ui/core";
import ReactSelect from "react-select";
import { Select as MaterialSelect } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
// import uniqid from "uniqid";
import { showSnackBar } from "redux/snackbar/action";
import * as Validator from "util/Validation";
// dialogs
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  orgUser: state.orgUser,
  vendorCatalogViews: state.vendors.vendorCatalogViews,
});

const connectionActions = {
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      overflowY: "visible",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },

    supportLabel: {
      marginBottom: 6,
      display: "block",
    },
    actionBtn: {
      minWidth: "200px",
      marginLeft: 10,
    },
    workflowBtns: {
      textAlign: "right",
    },
    rowWrap: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    flexWrap: {
      display: "flex",
    },
    columnWrap: {
      display: "flex",
      alignItems: "center",
      minWidth: 300,
    },
    paper: {
      width: "100%",
      padding: "1rem",
      position: "relative",
    },
    visualizationWrap: {},
    label: {
      color: "#282D30",
      fontSize: theme.spacing(1.6),
    },
    formControl: {
      width: "100%",
    },
    widthFields: {
      height: 30,
    },
    textFields: {
      width: "100%",
      marginBottom: 15,
      "& [class*='MuiInputBase']": {
        fontSize: 14,
      },
    },
    actionButton: {
      cursor: "pointer",
      position: "absolute",
      right: 15,
      top: 15,
      display: "flex",
      alignItems: "center",
      gap: 15,
    },
    formGroupElements: {
      marginTop: 10,
    },
    btnWrap: {
      display: "flex",
      gap: 20,
      padding: 12,
    },
  });

const visualizationTypes = [
  {
    label: "Visualization",
    value: "visualization",
  },
  {
    label: "Action Cards",
    value: "actionCard",
  },
  {
    label: "Prebuilt Table",
    value: "prebuilt_table",
  },
];

const prebuiltTableOptions = [
  {
    label: "Projects",
    value: "projects",
  },
  {
    label: "Catalog",
    value: "catalog",
  },
  {
    label: "Renewal",
    value: "renewal",
  },
];

const projectOptions = [
  {
    label: "All Projects",
    value: "all_projects",
  },
  {
    label: "Project Tracking",
    value: "project_tracking",
  },
];

const quiceAccessLinks = [
  // {
  //   label: "Start a new project",
  //   value: "start_a_new_project",
  // },
  // {
  //   label: "Vendor catalog",
  //   value: "vendor_catalog",
  // },
  // {
  //   label: "Expent vendor directory",
  //   value: "expent_vendor_directory",
  // },
  // {
  //   label: "Activity",
  //   value: "activity",
  // },
  // {
  //   label: "Link to page url",
  //   value: "link",
  // },
  {
    label: "Section",
    value: "heading",
  },
];

class AddUpdateDashboardCardDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      subTitle: "",
      visualizationType: "visualization",
      visualization: null,
      projectType: null,
      selectedView: "",
      vendorCatalogViews: [],
      allProjectViews: [],
      projectTrackingViews: [],
      renewalViews: [],
      actionCardTitle: "",
      actionCardLink: "",
      dataGrid: {
        i: "card-" + this.props.layout?.length + this.props.match.params.id,
        x: (this.props.layout?.length * 4) % 12,
        y: Infinity,
        w: 4,
        h: 20,
      },
    };
  }

  componentDidMount() {
    let { showAddUpdateCardDialogData } = this.props;
    if (showAddUpdateCardDialogData) {
      this.setState({
        title: showAddUpdateCardDialogData?.title || "",
        subTitle: showAddUpdateCardDialogData?.subTitle || "",
        visualizationType: showAddUpdateCardDialogData?.visualizationType || "visualization",
        visualization: showAddUpdateCardDialogData?.visualization || null,
        actionCardTitle: showAddUpdateCardDialogData?.actionCardTitle || "",
        actionCardLink: showAddUpdateCardDialogData?.actionCardLink || "",
        projectType: showAddUpdateCardDialogData?.projectType || null,
        selectedView: showAddUpdateCardDialogData?.selectedView || "",
        dataGrid: showAddUpdateCardDialogData?.dataGrid || this.state.dataGrid,
      });
    }

    let vendorCatalogViews = [{ id: "contract_view", name: "Contract View" }];
    vendorCatalogViews.push(...this.props.vendorCatalogViews);
    // let vendorCatalogViews = [...this.props.vendorCatalogViews];

    const { orgUser } = this.props;

    let allProjectViews = this.getConfigValue(orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "ALL_PROJECTS_VIEWS") || [];
    allProjectViews = Validator.isJson(allProjectViews) ? JSON.parse(allProjectViews) : [];

    let projectTrackingViews = this.getConfigValue(orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "ALL_PROJECTS_TRACKING_VIEWS") || [];
    projectTrackingViews = Validator.isJson(projectTrackingViews) ? JSON.parse(projectTrackingViews) : [];

    let renewalViews = this.getConfigValue(orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "RENEWAL_VIEWS") || [];
    renewalViews = Validator.isJson(renewalViews) ? JSON.parse(renewalViews) : [];

    this.setState({
      vendorCatalogViews: vendorCatalogViews,
      allProjectViews: allProjectViews,
      projectTrackingViews: projectTrackingViews,
      renewalViews: renewalViews,
    });
  }

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) {}
      return result.trim() || "";
    }
  };

  onSave = () => {
    const { title, subTitle, visualizationType, visualization, actionCardTitle, actionCardLink, dataGrid, projectType, selectedView } = this.state;
    // if (!visualizationType) {
    //   this.props.showSnackBar("Please select visualization type", "error", 3000);
    //   return;
    // }
    // if (!visualization) {
    //   this.props.showSnackBar("Please select visualization", "error", 3000);
    //   return;
    // }
    const data = {
      title,
      subTitle,
      visualization,
      visualizationType,
      actionCardTitle,
      actionCardLink,
      dataGrid,
      projectType,
      selectedView,
    };
    this.props.handleSubmit(data);
  };

  render() {
    const { classes, visualizationLists, showAddUpdateCardDialogData } = this.props;
    const { title, subTitle, visualizationType, visualization, actionCardTitle, actionCardLink, projectType, selectedView, vendorCatalogViews, allProjectViews, projectTrackingViews, renewalViews } =
      this.state;

    let viewsOptions = [];
    if (visualization?.value === "catalog") {
      viewsOptions = vendorCatalogViews;
    } else if (visualization?.value === "projects") {
      if (projectType?.value === "all_projects") {
        viewsOptions = allProjectViews;
      } else if (projectType?.value === "project_tracking") {
        viewsOptions = projectTrackingViews;
      }
    } else if (visualization?.value === "renewal") {
      viewsOptions = renewalViews;
    }

    return (
      <Dialog
        onClose={this.props.onClose}
        aria-labelledby="app-evRuleDialog"
        open={true}
        TransitionComponent={Transition}
        disableBackdropClick={false}
        fullWidth={true}
        maxWidth={"sm"}
        scroll="body"
        id="evAddUpdateDashboardCardDialog"
        PaperProps={{ style: { overflowY: "visible" } }}
      >
        <DialogTitle disableTypography>
          <Typography variant={"h6"}>{showAddUpdateCardDialogData ? "Update" : "Add"} Card</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.infoWrap}>
            <TextField variant="outlined" label="Title" value={title} size="small" className={classes.textFields} onChange={(e) => this.setState({ title: e.target.value })} />
            <TextField variant="outlined" label="Sub Title" value={subTitle} size="small" className={classes.textFields} onChange={(e) => this.setState({ subTitle: e.target.value })} />
          </div>
          <div className={classes.visualizationWrap}>
            <RadioGroup
              aria-label="binary"
              name="binary"
              value={visualizationType}
              onChange={(e) => {
                this.setState({ visualizationType: e.target.value, visualization: null, projectType: null, selectedView: "" });
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                {visualizationTypes &&
                  visualizationTypes.map((item, i) => (
                    <Grid item key={i}>
                      <FormControlLabel value={item.value} control={<Radio />} label={item.label} />
                    </Grid>
                  ))}
              </Grid>
            </RadioGroup>
            {visualizationType === "visualization" && (
              <div className={classes.formGroupElements}>
                <span className={classes.supportLabel}>Add Visualization:</span>
                <ReactSelect
                  isClearable={false}
                  options={visualizationLists}
                  value={visualization}
                  classNamePrefix="select"
                  placeholder={"Select Visualization"}
                  onChange={(e) => {
                    this.setState({ visualization: e });
                  }}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
            )}
            {visualizationType === "actionCard" && (
              <div className={classes.formGroupElements}>
                <span className={classes.supportLabel}>Add Action Card:</span>
                <ReactSelect
                  isClearable={false}
                  options={quiceAccessLinks}
                  value={visualization}
                  classNamePrefix="select"
                  placeholder={"Select Quick Access Link"}
                  onChange={(e) => {
                    this.setState({ visualization: e });
                  }}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
                <TextField
                  placeholder="Card Title"
                  value={actionCardTitle}
                  size="small"
                  variant="outlined"
                  style={{ marginTop: 15, width: "100%" }}
                  onChange={(e) => this.setState({ actionCardTitle: e.target.value })}
                />
                {visualization?.value === "link" && (
                  <TextField
                    placeholder="Link eg: https://abc.xyz"
                    value={actionCardLink}
                    size="small"
                    variant="outlined"
                    style={{ marginTop: 15, width: "100%" }}
                    onChange={(e) => this.setState({ actionCardLink: e.target.value })}
                  />
                )}
              </div>
            )}
            {visualizationType === "prebuilt_table" && (
              <div className={classes.formGroupElements}>
                <span className={classes.supportLabel}>Module Type:</span>
                <ReactSelect
                  isClearable={false}
                  options={prebuiltTableOptions}
                  value={visualization}
                  classNamePrefix="select"
                  placeholder={"Select Module Type"}
                  onChange={(e) => {
                    this.setState({ visualization: e, projectType: null, selectedView: "" });
                  }}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
                {visualization?.value === "projects" && (
                  <div className={classes.formGroupElements}>
                    <span className={classes.supportLabel}>Project Type:</span>
                    <ReactSelect
                      isClearable={false}
                      options={projectOptions}
                      value={projectType}
                      classNamePrefix="select"
                      placeholder={"Select Project Type"}
                      onChange={(e) => {
                        this.setState({ projectType: e, selectedView: "" });
                      }}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                )}
                {(visualization?.value === "catalog" || visualization?.value === "renewal" || (visualization?.value === "projects" && projectType?.value)) && (
                  <div className={classes.formGroupElements}>
                    <span className={classes.supportLabel}>Select View:</span>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                      <MaterialSelect
                        value={selectedView}
                        onChange={(e) => {
                          this.setState({ selectedView: e.target.value });
                        }}
                      >
                        {viewsOptions.map((item, i) => (
                          <MenuItem value={item.id} style={{ fontSize: 13 }}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </MaterialSelect>
                    </FormControl>
                  </div>
                )}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnWrap}>
            <Button variant="outlined" color="secondary" className={classes.saveBtn} onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button variant="contained" color="secondary" className={classes.saveBtn} onClick={this.onSave}>
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(AddUpdateDashboardCardDialog));
