import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";
import * as Validator from "util/Validation";
import { getMentionedUsersIds } from "util/Common";
import _ from "underscore";

export  function sendAddMentionNotification(comment,type,additional_data={}){
  let collaboratorIds = [];

  if (Validator.isJson(comment) && Array.isArray(JSON.parse(comment))) {
    collaboratorIds = getMentionedUsersIds(JSON.parse(comment));
  } 

  let UniqeCollaboratorIds = _.uniq(collaboratorIds);

  if (UniqeCollaboratorIds.length > 0) {
    let data = {
      user_ids: UniqeCollaboratorIds,
      link: window.location.href,
      type: type,
      additional_data 
    }
    
    try{
        let api_server = Environment.api_host("EVALUATION");
        let timestamp     =   (new Date()).getTime();
        let url   =   `${api_server}/mention?t=${timestamp}`;
        if(type === "Vendor_Catalog_Note"){
          api_server = Environment.api_host("PRODUCT");
          timestamp     =   (new Date()).getTime();
          url   =   `${api_server}/vendor/note/mention?t=${timestamp}`;
        }
        HttpFactory.instance().postMethod(url,data); 
    } catch(e){
        console.log('error occured',e)
    }
  }
} 