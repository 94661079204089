import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, Avatar, Button } from "@material-ui/core";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import CommonCss from "commonCss";
import { CommonFn } from 'services/commonFn';
import EvaluationEmptyState from "assets/images/evaluation_empty_state.svg";
import { Add as AddIcon } from "@material-ui/icons"
import { showAddNewEvaluationDialog } from "redux/evaluate/action";

import deleteIcon from "assets/images/delete.svg";

import Image from 'Components/Common/image';
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";

import { showSnackBar } from "redux/snackbar/action";
import Tooltip from "@material-ui/core/Tooltip";
import NewEvaluationDialog from "../../Evaluate/Components/Common/NewEvaluationDialog";
import { fetchInprogressRequests } from "redux/evaluation/requests/action";

const connectedProps = (state) => ({
  userType: state.authUser.user?.Role,
  user: state.authUser.user,
  deleteInprogressRequestProgress: state.evaluationRequestData.deleteInprogressRequestProgress,
  deleteInprogressRequestError: state.evaluationRequestData.deleteInprogressRequestError,
  fetchInprogressRequestProgress: state.evaluationRequestData.fetchInprogressRequestProgress,
  fetchInprogressRequestError: state.evaluationRequestData.fetchInprogressRequestError,
  requestInprogressData: state.evaluationRequestData.requestInprogressData,
});

const connectionActions = {
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  showSnackBar: showSnackBar,
  fetchInprogressRequests: fetchInprogressRequests,
  showAddNewEvaluationDialog: showAddNewEvaluationDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    topContainer: {
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      "& [class*=MuiAvatar-root]": {
        width: 25,
        height: 25,
        fontSize: 14,
        marginRight: 2
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    title: {
      fontSize: theme.spacing(2.4),

    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 18,
      fontWeight: 500,
      margin: "0px !important",
    },

    productTitleSection: {
      ...CommonCss.productTitleSection,
      marginTop: 40,
      marginBottom: 10,
    },
    ...CommonCss.Home,
    btnReview: {
      width: '38px',
      height: '32px',
      borderRadius: '5px',
      cursor: 'pointer',
      textAlign: 'center',
      fontSize: '13px',
      color: '#626675',
      paddingTop: 2,
      zIndex: 12,
      position: 'relative',
      border: '1px solid #EEEEEE',
      background: '#ffffff'
    },
    evaluateCard: {
      ...CommonCss.Home.evaluateCard,
      alignItems: 'center'
    },
    content: {
      padding: theme.spacing(4, 10),
      background: "#F7F7F7",
      // minHeight: "100vh"
    },
    productImageContainer: {
      width: 67,
      height: 57,
      padding: 13,
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 1px 6px #0000001A',
      borderRadius: 10,
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    productImage: {
      width: '100%',
      height: 'auto',
    },
    evaluateCardLabel: {
      background: '#1f73b7',
      width: 'max-content',
      margin: 0,
      borderRadius: '4px',
      color: '#fff',
      padding: '2px 10px',
      fontSize: 10
    }
  });

class InprogressRequestEvaluations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageSize: 5,
    };
    this.fetchData = this.fetchData.bind(this);
    this.avatarName = this.avatarName.bind(this);

  }
  avatarName(name) {
    return name.substring(0, 1);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.deleteInprogressRequestProgress && prevProps.deleteInprogressRequestProgress) {
      if (this.props.deleteInprogressRequestError === null) {
        this.fetchData();
        this.props.showSnackBar("Request deleted successfully", "success", 3000);
      } else {
        if (this.props.deleteInprogressRequestError.message !== undefined) {
          this.props.showSnackBar("Failed to delete request", "error", 3000);
        } else {
          this.props.showSnackBar("Something went wrong", "error", 3000);
        }
      }
    }
  }

  fetchData() {
    this.props.fetchInprogressRequests({ query: '', page: this.state.page, pageSize: this.state.pageSize, status: [2,3], type: [1,2,3,4,10] });
  }

  routeChange = (item, id) => {
    let redirectPath = `/app/evaluate/${id}/workflow/interim`;
    this.props.history.push(redirectPath);
  }

  render() {
    const classes = this.props.classes;
    if (this.props.fetchInprogressRequestProgress) {
      return (<span className={classes.loader}><CircularProgress /></span>)
    }

    if (!this.props.fetchInprogressRequestProgress && this.props.requestInprogressData?.total === 0) {
      return (<>
        <div className={classes.bottomHead}>
          <p className={classnames(classes.productHead)}>In Progress Projects</p>
        </div>
        <div className={classes.DashboardNoDataLayout}>
          <Image src={EvaluationEmptyState} />
          <h2>No Evaluation found</h2>
          <p>Get started on your first evaluation by tapping New Evaluation below</p>
          <Button variant="outlined" color="secondary" className={classes.button} startIcon={<AddIcon />}
            onClick={() => { this.props.showAddNewEvaluationDialog() }}
          >New Evaluation</Button>
        </div>
        <NewEvaluationDialog />
      </>)
    }

    

    let userRole = ["OrgAdmin","OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;

    console.log(this.props.requestInprogressData, 'requestInprogressData')

    return (
      <Grid>
          <div className={classes.bottomHead}>
            <p className={classnames(classes.productHead)}>In Progress Projects</p>
            {this.props.requestInprogressData?.total > 5 &&
              <Link
                to="/app/evaluate/request/in-progress"
                className={classes.viewAll}
              >
                View all ({this.props.requestInprogressData?.total.toLocaleString("en-US")})
              </Link>
            }
          </div>
          <div>
            {this.props.requestInprogressData && this.props.requestInprogressData.Data?.map((item, index) =>
              <div className={classes.evaluateCard} key={index} onClick={() => {
                this.routeChange(item, item.ID)
              }}>
                <div>
                  <div className={classes.productImageContainer}>
                    {
                      item.Products?.length && <Image className={classes.productImage} src={item.Products[0].ProductLogo} alt={item.Products[0].Name} title={item.Products[0].Name} />
                    }
                  </div>
                </div>
                <div className={classes.evaluateTextSurvey}>
                  <p className={classes.evaluateCardTitle}>{item.Name}</p>
                  <p className={classes.evaluateCardLabel}>{item?.Workflow?.Name}</p>
                  <p className={classes.evaluateCardSubTitle}>Updated At: {CommonFn.formateDate(item.UpdatedAt)}</p>
                </div>
                <div className={classes.evaluateLogo}>
                  {this.props.userType === 'OrgAdmin' && <div className={classes.productLogoContainer}>
                    {
                      item.Collaborators?.slice(0, item.Collaborators.length < 5 ? item.Collaborators.length : 3).map((item, i) => {
                        return (
                          <Tooltip
                            placement="top"
                            title={
                              <span
                                style={{
                                  fontSize: "14px",
                                  whiteSpace: "pre-line",
                                  display: "inline-block",
                                  height: "25px",
                                  lineHeight: "25px",
                                }}
                              >
                                {item.Name + "\n" + item.Email}
                              </span>
                            }
                            arrow
                          >
                            <Avatar key={i}>
                              {" "}
                              {this.avatarName(item.Name)}
                            </Avatar>
                          </Tooltip>
                        )
                      })
                    }
                    {
                      item.Collaborators.length > 4 &&
                      <div className={classes.productLogo} title={item.Collaborators.length - 3 + ' more collaborators'}> + {item.Collaborators.length - 3}</div>
                    }
                  </div>}
                  {adminFlag && <button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={(e) => this.props.deleteInprogressReview(e, item.ID)}><Image
                    src={deleteIcon}
                    alt="forgotpassword_image"
                    className={classes.titleImage}
                  /></button>}
                </div>
              </div>)}
            {this.props.fetchInprogressRequestProgress &&
              <span className={classes.loader}><CircularProgress /></span>
            }
            {!this.props.fetchInprogressRequestProgress && this.props.requestInprogressData?.total === 0 &&
              <span className={classes.noMoreData}>No more data </span>
            }
          </div>
          
      </Grid>       
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(InprogressRequestEvaluations));