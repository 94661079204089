import { Dialog, DialogContent, Slide, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@material-ui/core";
import { showSnackBar } from "redux/snackbar/action";
import { importSellerAssessment } from "redux/seller/assessment/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  importAssessmentData: state.sellerAssessment.importAssessmentData,
  importSellerAssessmentProgress: state.sellerAssessment.importSellerAssessmentProgress,
  importSellerAssessmentError: state.sellerAssessment.importSellerAssessmentError,
  sellerAssessmentImportSuccess: state.sellerAssessment.sellerAssessmentImportSuccess,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  importSellerAssessment: importSellerAssessment,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
      marginBottom: 10,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    paper: {
      width: "100%",
      overflow: "hidden",
      marginTop: 10,
    },
    importQuestions: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 15,
    },
  });

class ShowFinalizeDataDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
    };
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {
    const { importSellerAssessmentProgress, sellerAssessmentImportSuccess } = this.props;
    if (!importSellerAssessmentProgress && prevProps.importSellerAssessmentProgress === true) {
      if (!sellerAssessmentImportSuccess) {
        this.props.showSnackBar("Failed to import.", "error", 3000);
      } else {
        let assessmentID = sellerAssessmentImportSuccess?.AssessmentID;
        this.props.showSnackBar("Assessment imported successfully.", "success", 3000);
        this.props.hideDialog();
        if (this.props.type === "library") {
          this.props.history.push("/sellerapp/library");
        } else {
          this.props.history.push("/sellerapp/assessment/" + assessmentID + "/questions");
        }
      }
    }
  }

  onSubmit = () => {
    const { finalizedData, importAssessmentData } = this.props;
    const datas = finalizedData?.tableDatas || [];
    // const columns = finalizedData?.formFields || [];
    const filePath = finalizedData?.filePath || "";
    let dummyData = { cell_id: "", data: "" };

    let params = {
      is_global: importAssessmentData?.product?.value === "global" ? 1 : 0,
      product_id: importAssessmentData?.product?.value === "global" ? null : importAssessmentData?.product?.value,
      questions: datas?.map((item) => ({
        question_title: item.question_title || dummyData,
        question: item.question || dummyData,
        answer: item.answer || dummyData,
        note: item.note || dummyData,
        answer_options: item.answer_options || { cell_id: "", data: [] },
      })),
    };
    if (this.props.type === "assessment") {
      params["assessment"] = {
        name: importAssessmentData?.name,
        file_path: filePath,
        sheet_name: this.props.selectedCells?.SelectedSheet,
      };
    }
    this.props.importSellerAssessment(this.props.type, params);
  };

  render() {
    const classes = this.props.classes;
    const { finalizedData } = this.props;
    console.log(finalizedData, "finalizedData");

    const columns = finalizedData?.formFields;
    const datas = finalizedData?.tableDatas;

    return (
      <Dialog
        onClose={this.props.hideDialog}
        aria-labelledby="app-integrationDialog"
        open={this.props.isOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"lg"}
        scroll="body"
        id="evMetaDialog"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.hideDialog()}>
            <CloseIcon />
          </div>
          <Typography variant={"h4"} className={classes.title}>
            Final Data
          </Typography>
          <div className={classes.body}>
            <Paper className={classes.paper}>
              <TableContainer className={classes.tableContainer} style={{ maxHeight: "70vh" }}>
                <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={true ? "small" : "medium"} aria-label="enhanced table">
                  <TableHead>
                    <TableRow>
                      {columns &&
                        columns !== null &&
                        columns.map((headCell, index) => {
                          return (
                            <TableCell key={headCell.label + index} align={"left"} style={{ whiteSpace: "nowrap" }} padding={headCell.disablePadding ? "none" : "normal"}>
                              {<span>{headCell.label}</span>}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {datas.length > 0 &&
                      datas.map((row, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.name + index} style={{ cursor: "pointer" }}>
                            {columns &&
                              columns !== null &&
                              columns.map((col) => {
                                if (col.key === "answer_options") {
                                  return <TableCell align={"left"}>{row[col.key]?.data?.join(", ")}</TableCell>;
                                } else {
                                  return <TableCell align={"left"}>{row[col.key]?.data || ""}</TableCell>;
                                }
                              })}
                          </TableRow>
                        );
                      })}

                    {datas.length <= 0 && (
                      <TableRow>
                        <TableCell colSpan={columns.length} style={{ textAlign: "center" }} className={classes.nameCol}>
                          No Items
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
          <div className={classes.importQuestions}>
            <Button variant="outlined" color="secondary" onClick={this.props.hideDialog}>
              Cancel
            </Button>
            &nbsp; &nbsp;
            <Button disabled={this.props.importSellerAssessmentProgress} variant="contained" color="secondary" onClick={this.onSubmit}>
              Confirm
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

ShowFinalizeDataDialog.defaultProps = {
  isWorkFlow: false,
};

export default connector(compose(withRouter, withStyles(styles))(ShowFinalizeDataDialog));
