import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Paper, Typography, IconButton, InputAdornment, CircularProgress } from "@material-ui/core";
import SqureButton from "Components/Common/SqureButton";
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import classnames from "classnames";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
import moment from "moment";
import * as Validator from "util/Validation";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import CalendarImage from "assets/images/calendar.svg";
// import CheckIcon from '@material-ui/icons/Check';
import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/delete.svg";
import { fetchOrgProductDetailsTabs } from "redux/product/orgProduct/action";
import Alert from "@material-ui/lab/Alert";
import { fetchAlertRules, updateAlertRule, addNewAlertRule, deleteAlertRule } from "redux/alerts/action";
import { showSnackBar } from "redux/snackbar/action";
import Tooltip from "@material-ui/core/Tooltip";
import { showMessageDialog, hideMessageDialog } from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { typeWithConditionsMap } from "Components/Application/Components/Settings/Configurations/CustomFields/data";
const connectedProps = (state) => ({
  orgProductDetailTabs: state.orgProduct.orgProductDetailTabs,
  orgProductDetailTabsProgress: state.orgProduct.orgProductDetailTabsProgress,
  deleteAlertUserProgress: state.alert.deleteAlertUserProgress,
  deleteAlertUserError: state.alert.deleteAlertUserError,
  addAlertRuleProgress: state.alert.addAlertRuleProgress,
  addAlertRuleError: state.alert.addAlertRuleError,
  deleteAlertRuleProgress: state.alert.deleteAlertRuleProgress,
  deleteAlertRuleError: state.alert.deleteAlertRuleError,
  updateAlertRuleProgress: state.alert.updateAlertRuleProgress,
  updateAlertRuleError: state.alert.updateAlertRuleError,
  fetchAlertRuleProgress: state.alert.fetchAlertRuleProgress,
  fetchAlertRuleError: state.alert.fetchAlertRuleError,
  alertRules: state.alert.alertRules,
});

const connectionActions = {
  fetchOrgProductDetailsTabs: fetchOrgProductDetailsTabs,
  fetchAlertRules: fetchAlertRules,
  updateAlertRule: updateAlertRule,
  addNewAlertRule: addNewAlertRule,
  deleteAlertRule: deleteAlertRule,
  showSnackBar: showSnackBar,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    paper: {
      padding: "30px 20px",
      minHeight: "87vh",
      position: "relative",
    },
    addRuleDiv: {
      textAlign: "center",
      marginTop: 50,
    },
    rules: {
      marginTop: 20,
    },
    addMore: {
      fontSize: "12px",
      color: "#4175DF",
      cursor: "pointer",
      display: "inline-block",
      marginTop: 10,
    },
    input: {
      minHeight: 38,
      padding: "2px 8px",
      border: "1px solid #cccccc",
      borderRadius: 3,
      fontSize: 15,
      color: "rgb(51, 51, 51)",
      width: "100%",
    },
    operand: {
      minWidth: 87,
    },
    ruleTable: {
      width: "100%",
      "& [class*=MuiSelect-root]": {
        minHeight: 22,
      },
    },
    ruleRightElement: {},
    ruleLeftElement: {},
    condition: {
      marginTop: 8,
      fontSize: 14,
      fontWeight: 500,
    },
    op2: {
      width: "15%",
      "& [class*=MuiInputBase-formControl]": {
        marginRight: 10,
        margin: "0 auto",
        width: "100%",
      },
    },
    op1: {
      width: "10%",
      paddingRight: 10,
      paddingLeft: 10,
      paddingTop: 10,
      "& [class*=MuiInputBase-formControl]": {
        width: "100%",
        marginRight: 10,
        margin: "0 auto",
      },
    },
    leftOp: {
      width: "30%",
      paddingTop: 10,
    },
    rightOp: {
      width: "25%",
      paddingTop: 10,
      "& [class*=select__menu]": {
        maxHeight: 100,
      },
      "& [class*=react-datepicker-wrapper]": {
        width: "100%",
      },
    },
    noPaddingTop: {
      paddingTop: 0,
    },
    action: {
      width: "20%",
      textAlign: "center",
    },
    alerts: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    ruleHead: {
      fontSize: 16,
      fontWeight: 500,
    },
    "@global": {
      ".react-datepicker__input-container > div": {
        margin: 0,
      },
      ".react-datepicker__input-container [class*=MuiInputBase-formControl]": {
        minHeight: 39,
        margin: 0,
      },
      ".react-datepicker__input-container [class*=MuiOutlinedInput-input]": {
        padding: 0,
        fontSize: 14,
        paddingLeft: 10,
        color: "rgb(51, 51, 51)",
      },
    },
    indexNumber: {
      width: 10,
      marginTop: 5,
    },
    inputSection: {},
    inputSection2: {},
    requiredField: {
      color: "red",
      marginLeft: 5,
    },
    inputController: {},
    image: {
      marginTop: 5,
      minWidth: 10,
      cursor: "pointer",
    },
    bottomActions: {
      position: "absolute",
      bottom: "0",
      padding: theme.spacing(1),
      left: 0,
      right: 0,
      paddingLeft: theme.spacing(24),
      paddingRight: theme.spacing(4),
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px -1px 6px #00000010",
      zIndex: 1000,
      textAlign: "right",
      marginTop: 25,
    },
    actionBtn: {
      marginLeft: 10,
    },
    alert: {
      marginBottom: 10,
      marginTop: 25,
    },
  });

class Rules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleElements: [],
      ruleDatas: [],
      error: "",
      success: "",
      isLoadingRules: true,
      optionIdMap: {},
      deleteRuleId: null,
    };

    this.addNewRuleElement = this.addNewRuleElement.bind(this);
    this.onLeftChange = this.onLeftChange.bind(this);
    this.onRightChange = this.onRightChange.bind(this);
    this.operand2Change = this.operand2Change.bind(this);
    this.operad1Change = this.operad1Change.bind(this);
    this.createOptionMap = this.createOptionMap.bind(this);
    this.saveRule = this.saveRule.bind(this);
    this.saveNewRule = this.saveNewRule.bind(this);
    this.updateRule = this.updateRule.bind(this);
    this.initRuleElements = this.initRuleElements.bind(this);
  }

  componentDidMount() {
    this.fetchRules();
  }

  componentDidUpdate(prevProps) {
    const {
      orgProductDetailTabsProgress,
      fetchAlertRuleProgress,
      fetchAlertRuleError,
      addAlertRuleProgress,
      addAlertRuleError,
      deleteAlertRuleProgress,
      deleteAlertRuleError,
      updateAlertRuleProgress,
      updateAlertRuleError,
    } = this.props;

    if (!orgProductDetailTabsProgress && prevProps.orgProductDetailTabsProgress) {
      this.createOptionMap();
    }

    if (!fetchAlertRuleProgress && prevProps.fetchAlertRuleProgress) {
      if (fetchAlertRuleError === null) {
        if (this.props.alertRules.length <= 0) {
          this.setState({
            isLoadingRules: false,
          });
          this.addNewRuleElement();
        } else {
          this.initRuleElements();
        }
      } else {
        this.props.showSnackBar("Something went wrong to fetch alert rules.", "error", 3000);
      }
    }

    if (!addAlertRuleProgress && prevProps.addAlertRuleProgress) {
      if (addAlertRuleError === null) {
        this.props.showSnackBar("Rule added successfully.", "success", 3000);
      } else {
        this.props.showSnackBar("Something went wrong to add alert rule.", "error", 3000);
      }
    }

    if (!deleteAlertRuleProgress && prevProps.deleteAlertRuleProgress) {
      if (deleteAlertRuleError === null) {
        this.props.showSnackBar("Rule deleted successfully.", "success", 3000);
      } else {
        this.props.showSnackBar("Something went wrong to delete alert rule.", "error", 3000);
      }
    }

    if (!updateAlertRuleProgress && prevProps.updateAlertRuleProgress) {
      if (updateAlertRuleError === null) {
        this.props.showSnackBar("Rule updated successfully.", "success", 3000);
      } else {
        this.props.showSnackBar("Something went wrong to add alert rule.", "error", 3000);
      }
    }
  }

  fetchRules() {
    this.props.fetchAlertRules(this.props.alertId);
  }

  initRuleElements() {
    const { alertRules, orgProductDetailTabs } = this.props;

    if (orgProductDetailTabs === undefined || orgProductDetailTabs === null || orgProductDetailTabs.length <= 0 || alertRules === null || alertRules.length <= 0) {
      return;
    }

    const { optionIdMap } = this.state;
    let me = this;
    let ruleElements = [];
    if (alertRules.length > 0) {
      alertRules.forEach(function (rule) {
        var leftId = rule.LeftOp !== null ? parseInt(rule.LeftOp) : null;
        if (leftId === null || optionIdMap[leftId] === undefined) {
          return;
        }

        let obj = {
          leftOp: optionIdMap[leftId],
          op1: rule.OpID,
          rightOP: me.getRightExpression(optionIdMap[leftId], rule.RightOp, optionIdMap[leftId].data),
          ID: rule.ID,
          error: null,
        };

        ruleElements.push(obj);
      });
    } else {
      this.addNewRuleElement();
    }

    this.setState({
      ruleElements: ruleElements,
    });
  }

  createOptionMap() {
    var optionsData = [];
    var optionMap = {};
    var validTypes = [1, 2, 3, 4, 5, 19];
    let optionIdMap = {};
    const { orgProductDetailTabs } = this.props;
    if (orgProductDetailTabs === undefined || orgProductDetailTabs === null || orgProductDetailTabs.length <= 0) {
      return;
    }
    this.props.orgProductDetailTabs.forEach(function (tab) {
      // if (tab.TabUID !== 'tab-l3wvezqv-1654165478263') {
      //   return;
      // }
      tab.OrgProductTabSections.forEach(function (section) {
        section.OrgProductTabItems.forEach(function (item) {
          if (validTypes.indexOf(item.Type) !== -1) {
            let tabName = tab.TabName;
            let sectionName = section.SectionName;
            let itemName = sectionName;
            let itemId = item.ID;
            var parentOpName = tabName;
            // var parentOpName = tabName + ' - ' + sectionName;
            if ((item.ItemName === null || item.ItemName === "") && (sectionName === null || sectionName === "")) {
              return;
            }
            if(item.ItemName !== null && item.ItemName !== "") {
              itemName = itemName + " - " + item.ItemName
            }

            let isCustom = item.ItemUID.indexOf("item-") !== -1 ? true : false;

            if (!isCustom) {
              return;
            }

            if (optionMap[parentOpName] === undefined) {
              optionMap[parentOpName] = {
                label: parentOpName,
                options: [
                  {
                    label: itemName,
                    value: itemId,
                    type: item.Type,
                    data: Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [],
                  },
                ],
              };
            } else {
              optionMap[parentOpName].options.push({
                label: itemName,
                value: itemId,
                type: item.Type,
                data: Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [],
              });
            }

            optionIdMap[itemId] = {
              label: itemName,
              value: itemId,
              type: item.Type,
              data: Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [],
            };
          }
        });
      });
    });

    Object.keys(optionMap).forEach(function (key) {
      optionsData.push(optionMap[key]);
    });
    this.setState(
      {
        ruleDatas: optionsData,
        optionIdMap: optionIdMap,
      },
      () => {
        this.initRuleElements();
      }
    );
  }

  getLeftExpression = (value, array) => {
    const leftObj = array.find((item) => item.Name === value);
    if (leftObj) {
      return {
        value: leftObj.Name,
        label: leftObj.Label,
        type: leftObj.Type,
        data: leftObj.Data && Validator.isJson(leftObj.Data) && JSON.parse(leftObj.Data),
      };
    }
    return null;
  };

  getRightExpression = (leftObj = {}, value = "", array = []) => {
    // console.log('-----Right------')
    // console.log(value, 'value')
    // console.log(array, 'array')
    // console.log(leftObj, value, 'leftObj & value')
    if (leftObj.type === 1 || leftObj.type === 2 || leftObj.type === 19) {
      return value;
    } else if (leftObj.type === 3) {
      if (leftObj.data) {
        return leftObj.data.find((item) => item.value === value);
      }
    } 
    // else if (leftObj.type === 4) {
    //   if (leftObj.data) {
    //     return leftObj.data.find((item) => item.value === parseInt(value));
    //   }
    // }
     else if (leftObj.type === 5 || leftObj.type === 4) {
      if (leftObj.data) {
        const convertedValue = Validator.isJson(value) ? JSON.parse(value) : [];
        return leftObj.data.filter((item) => Array.isArray(convertedValue) && convertedValue.indexOf(item.value) !== -1);
      }
    }
    return null;
  };

  handleDelete = (id, index) => {
    var me = this;
    console.log(id, index);
    if (id) {
      this.setState({ deleteRuleId: id, deleteRuleIndex: index }, () => {
        me.props.showMessageDialog();
      });
    } else if (index >= 0) {
      let ruleElements = [...this.state.ruleElements];
      ruleElements.splice(index, 1);
      this.setState({ ruleElements: ruleElements });
    }
  };

  callBack = () => {
    this.props.hideMessageDialog();
    var ruleElements = this.state.ruleElements;
    ruleElements.splice(this.state.deleteRuleIndex, 1);
    this.setState({
      ruleElements: ruleElements,
    });
    // if (this.state.deleteRuleId) {
    //   this.props.deleteAlertRule(this.props.alertId, this.state.deleteRuleId);
    // }
  };

  onLeftChange = (k, e) => {
    var ruleElements = this.state.ruleElements;
    var rule = ruleElements[k];
    rule.leftOp = e;
    rule.rightOP = null;
    ruleElements[k] = rule;
    if (e.type === 4 || e.type === 5) {
      rule.op1 = "4";
    } else {
      rule.op1 = "3";
    }

    this.setState({
      ruleElements: ruleElements,
    });
  };

  onRightChange = (k, e) => {
    var ruleElements = this.state.ruleElements;
    var rule = ruleElements[k];
    rule.rightOP = e;
    ruleElements[k] = rule;
    this.setState({
      ruleElements: ruleElements,
    });
  };

  operand2Change = (k, e) => {
    var ruleElements = this.state.ruleElements;
    var rule = ruleElements[k];
    rule.op2 = e.target.value;
    ruleElements[k] = rule;
    this.setState({
      ruleElements: ruleElements,
    });
  };

  operad1Change = (k, e) => {
    var rule = this.state.ruleElements[k];
    if (e.target?.value) {
      rule.op1 = e.target.value.toString();
    } else if (!e.target?.value) {
      rule.op1 = "";
    }
    var ruleElements = this.state.ruleElements;
    ruleElements[k] = rule;
    this.setState({
      ruleElements: ruleElements,
    });
  };

  addNewRuleElement() {
    var fields = this.state.ruleElements;
    var newData = [
      ...fields,
      {
        op2: "1",
        leftOp: null,
        op1: "3",
        rightOP: null,
        ID: null,
        error: null,
      },
    ];
    this.setState({
      ruleElements: newData,
    });
  }

  clearError() {
    this.setState({
      error: "",
    });
  }

  saveNewRule(index, ruleId) {
    this.clearError();
    let ruleElements = this.state.ruleElements;
    let rule = ruleElements[index];
    rule.error = null;
    if (rule.leftOp === null) {
      rule.error = `Please select rule.`;
    } else if (rule.op1 === null) {
      rule.error = `Please select oprand.`;
    } else if (rule.rightOP === null || rule.rightOP?.length === 0) {
      rule.error = `Please enter/select option.`;
    }

    if (rule.error !== null) {
      this.setState({
        ruleElements: ruleElements,
      });
      return;
    }

    const rightExp = !Array.isArray(rule.rightOP)
      ? rule.rightOP?.value
        ? rule.rightOP?.value
        : rule.rightOP
      : JSON.stringify(
          rule.rightOP.map((i) => {
            return i.value;
          })
        );

    var payload = {
      leftOp: rule.leftOp.value.toString(),
      opId: parseInt(rule.op1),
      rightOp: rightExp.toString(),
    };

    if (ruleId === null) {
      this.props.addNewAlertRule(this.props.alertId, payload);
    } else {
      this.props.updateAlertRule(this.props.alertId, ruleId, payload);
    }
  }

  updateRule(rule, index) {
    alert("Update Item");
  }

  saveRule = () => {
    this.setState({
      error: "",
      success: "",
    });

    const requests = [];
    let hasError = false;
    let ruleElements = [...this.state.ruleElements];

    this.state.ruleElements.forEach((ruleElem, k) => {
      ruleElements[k]["error"] = "";
      if (!ruleElem.leftOp) {
        ruleElements[k]["error"] = `please select rule.`;
        hasError = true;
        return;
      }

      if (!ruleElem.op2 && k > 2) {
        ruleElements[k]["error"] = `please select oprand`;
        hasError = true;
        return;
      }

      if (!ruleElem.op1) {
        ruleElements[k]["error"] = `please select oprand`;
        hasError = true;
        return;
      }

      if (!ruleElem.rightOP || ruleElem.rightOP?.length === 0) {
        ruleElements[k]["error"] = `please enter/select option.`;
        hasError = true;
        return;
      }

      const rightExp = !Array.isArray(ruleElem.rightOP)
        ? ruleElem.rightOP?.value
          ? ruleElem.rightOP?.value
          : ruleElem.rightOP
        : JSON.stringify(
            ruleElem.rightOP.map((i) => {
              return i.value;
            })
          );

      requests.push({
        leftOp: ruleElem.leftOp.value.toString(),
        opId: parseInt(ruleElem.op1),
        rightOp: rightExp.toString(),
      });
    });

    this.setState({
      ruleElements: ruleElements,
    });

    if (!hasError) {
      this.props.addNewAlertRule(this.props.alertId, { rules: requests });
    }
  };

  render() {
    const { classes } = this.props;
    const { ruleElements, ruleDatas } = this.state;
    console.log({ ruleElements, ruleDatas }, "ruleData");
    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    const { isLoadingRules } = this.state;

    if (isLoadingRules) {
      <div style={{ textAlign: "center", marginTop: 50 }}>
        <CircularProgress />
      </div>;
    }

    return (
      <Paper className={classes.paper}>
        <Typography style={{ fontWeight: 600 }}>Rules</Typography>
        <div className={classes.ruleBody}>
          <div className={classes.alert}>
            {this.state.error.length > 0 && (
              <Alert variant="filled" severity="error">
                {this.state.error}
              </Alert>
            )}
          </div>
          {ruleElements.length === 0 && (
            <span style={{ margin: "0 2px" }}>
              <Tooltip
                className={"tooltip"}
                placement="top"
                title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>Add Rule</span>}
                arrow
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    this.addNewRuleElement();
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </span>
          )}
          {ruleElements.length > 0 && (
            <div className={classes.rules}>
              <table className={classes.ruleTable}>
                <tbody>
                  {ruleElements.map((rule, k) => {
                    return (
                      <React.Fragment key={k}>
                        {rule.error !== null && (
                          <tr>
                            <td colSpan="4" style={{ width: "100%" }}>
                              {rule.error !== null && <span style={{ color: "red" }}>{rule.error}</span>}
                            </td>
                          </tr>
                        )}
                        <tr className={rule.error === null ? classes.inputSection : classes.inputSection2}>
                          <td className={classes.op2}>
                            {k !== 0 && <div>AND</div>}
                            {k === 0 && <div className={classes.condition}>Condition</div>}
                          </td>
                          <td className={classnames(classes.inputController, classes.leftOp, rule.error !== null ? classes.noPaddingTop : "")}>
                            <ReactSelect
                              isClearable
                              menuPosition="fixed"
                              isSearchable={true}
                              options={ruleDatas}
                              classNamePrefix="select"
                              placeholder="Search rule"
                              onChange={(e) => {
                                this.onLeftChange(k, e);
                              }}
                              value={rule.leftOp}
                            />
                          </td>
                          <td className={classnames(classes.op1, rule.error !== null ? classes.noPaddingTop : "")}>
                            <OutlinedSelectInput
                              style={{ width: "100%" }}
                              placeholder="Operand"
                              value={rule.op1}
                              options={rule?.leftOp?.type ? typeWithConditionsMap[rule.leftOp.type] : []}
                              noMargin={true}
                              compact={true}
                              onChange={(e) => {
                                this.operad1Change(k, e);
                              }}
                            />
                          </td>
                          <td className={classnames(classes.ruleRightElement, classes.rightOp, rule.error !== null ? classes.noPaddingTop : "")}>
                            {!rule?.leftOp?.type && (
                              <div>
                                <input placeholder="Enter Option" className={classes.input} />
                              </div>
                            )}
                            {rule?.leftOp?.type === 1 && (
                              <div>
                                <input
                                  className={classes.input}
                                  placeholder="Enter Option"
                                  onChange={(e) => {
                                    this.onRightChange(k, e.target?.value || "");
                                  }}
                                  value={rule.rightOP}
                                />
                              </div>
                            )}
                            {rule?.leftOp?.type === 19 && (
                              <div>
                                <input
                                  className={classes.input}
                                  type="number"
                                  placeholder="Enter Option"
                                  onChange={(e) => {
                                    this.onRightChange(k, e.target?.value || "");
                                  }}
                                  value={rule.rightOP}
                                />
                              </div>
                            )}
                            {rule?.leftOp?.type === 2 && (
                              <div>
                                <DatePicker
                                  value={rule.rightOP && moment(rule.rightOP).format("DD/MM/YYYY")}
                                  selected={""}
                                  onChange={(date) => {
                                    this.onRightChange(k, date.toISOString() || "");
                                  }}
                                  placeholder="Choose Date"
                                  dateFormat="MMM do yyyy"
                                  portalId="workflow"
                                  minDate={""}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={
                                    <OutlinedInput
                                      readOnlyInput={true}
                                      startAdornment={
                                        <InputAdornment>
                                          <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                        </InputAdornment>
                                      }
                                    />
                                  }
                                />
                              </div>
                            )}
                            {(rule?.leftOp?.type === 4 || rule?.leftOp?.type === 5) && (
                              <ReactSelect
                                isClearable
                                isSearchable={true}
                                isMulti={true}
                                menuPosition="fixed"
                                options={rule?.leftOp?.data}
                                classNamePrefix="select"
                                placeholder="Search Option"
                                onChange={(e) => {
                                  this.onRightChange(k, e);
                                }}
                                value={rule.rightOP}
                              />
                            )}
                            {rule?.leftOp?.type === 3 && (
                              <ReactSelect
                                isClearable
                                menuPosition="fixed"
                                options={rule?.leftOp?.data}
                                classNamePrefix="select"
                                placeholder="Search Option"
                                onChange={(e) => {
                                  this.onRightChange(k, e);
                                }}
                                value={rule.rightOP}
                              />
                            )}
                          </td>
                          <td className={classes.action}>
                            <span style={{ margin: "0 7px" }}>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  this.handleDelete(rule.ID, k);
                                }}
                              >
                                <Image alt="Delete" src={deleteIcon} />
                              </IconButton>
                            </span>
                            {/* <span style={{margin: '0 7px'}}>
                            <Tooltip className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{rule.ID === null ? 'Add' : 'Update'}</span>} arrow>
                              <IconButton size="small" onClick={() => {
                                this.saveNewRule(k, rule.ID)
                              }
                              }>
                                <CheckIcon />
                              </IconButton>
                            </Tooltip>
                          </span> */}
                            <span style={{ margin: "0 2px" }} hidden={k !== ruleElements.length - 1}>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  this.addNewRuleElement();
                                }}
                              >
                                <AddCircleIcon />
                              </IconButton>
                            </span>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <div className={classes.bottomActions}>
            <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.saveRule}>
              Save
            </SqureButton>
          </div>

          {false && (
            <div className={classes.addRuleDiv}>
              <SqureButton
                cls={classes.actionBtn}
                variant={"contained"}
                onClick={() => {
                  this.addNewRuleElement();
                }}
              >
                + Add Rule
              </SqureButton>
            </div>
          )}
        </div>
        <MessageDialog messageData={messageData} callBack={this.callBack} />
      </Paper>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Rules));
