import React, { useState } from 'react';
import { ClickAwayListener, Divider, ListItemText, Paper, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Logo from "assets/images/navitems/expent-logo.svg";

import Image from "Components/Common/image.jsx";
import { storeSearchString } from "redux/applications/action";

import { createStyles, withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { authorizedUser } from "redux/authuser/action";
import { updateDrawerStyle } from "redux/drawer/action";

import BookmarkIcon from "assets/images/navitems/bookmark.svg"
import DownArrowIcon from "assets/images/navitems/down-arrow.svg"
import NotesIcon from "assets/images/navitems/notes.svg"
import NotificationIcon from "assets/images/navitems/notification.svg"
import UserIcon from "assets/images/navitems/user-icon.svg"
import UndoIcon from "assets/images/navitems/undo.svg"
import SettingsIcon from "assets/images/navitems/settings.svg"
import { getNewPlatform } from 'util/Environment';
import { isEnabledProjectTracking } from "util/Common";
import { logout, setLogoutFromFlag } from "redux/authuser/action";
import { isEnabledReview, isEnabledEvaluate, isSyncActivityLog } from "util/Common";

const connectedProps = (state) => ({
  expanded: state.drawer.expanded,
  show: state.drawer.show,
  loginUser: state.authUser.user,
  orgUser: state.orgUser,
  evaluation: state.evaluationMetaData.evaluation,
});

const connectionActions = {
  storeSearchString: storeSearchString,
  authorizedUser: authorizedUser,
  updateDrawerStyle: updateDrawerStyle,
  setLogoutFromFlag: setLogoutFromFlag,
  logout: logout,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    HeaderContainer: {
      position: 'fixed',
      width: '100%',
      height: '5.25rem',
      minHeight: '5.25rem',
      display: 'flex',
      alignItems: 'center',
      padding: '0rem 3.875rem',
      backgroundColor: '#003B4D',
      zIndex: 111
    },
    LogoTextWrap: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
    },
    ExpentText: {
      fontWeight: 600,
      fontSize: '1.375rem',
      lineHeight: '140%',
      color: '#fff',
      fontFamily: 'Manrope'
    },
    UserInfoIconWrap: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      marginLeft: 'auto',
      img: {
        cursor: 'pointer',
      },
    },
    IconsWrap: {
      width: '2.75rem',
      height: '2.75rem',
      minWidth: '2.75rem',
      background: '#265868',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    NavigationWrap: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      marginLeft: '6.05rem',
    },
    UserIconsWrap: {
      background: '#fff',
      width: '2.75rem',
      height: '2.75rem',
      minWidth: '2.75rem',
      borderRadius: '50%',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    UserWrap: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      cursor: 'pointer',
    },
    UserText: {
      fontSize: '0.875rem',
      lineHeight: '140%',
      color: '#fff',
      fontWeight: 600,
      fontFamily: 'Manrope'
    },
    ActiveBorderBottom: {
      position: 'absolute',
      bottom: -3,
      left: '50%',
      width: '60%',
      borderBottom: `4px solid #17BEBB`,
      transform: 'translate(-50%, -50%)',
    },
    NavText: {
      width: 'fit-content',
      height: '100%',
      padding: '0rem 1.25rem',
      fontSize: '0.875rem',
      lineSeight: '140%',
      color: '#fff',
      opacity: '0.6',
      cursor: 'pointer',
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      letterSpacing: '0.07em',
      '&:hover': {
        background: 'linear-gradient(180deg, rgba(0, 39, 51, 0) 0%, #002733 100%)',
      },
    },
    cPaper: {
      position: 'fixed',
      width: '220px',
      top: 5,
      left: 'left',
      zIndex: 9999,
      padding: '0.25rem 0rem',
      backgroundColor: '#003B4D',
    }
  });


const ActiveBorderBottom = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: -3,
  left: '50%',
  width: '60%',
  borderBottom: `4px solid #17BEBB`,
  transform: 'translate(-50%, -50%)',
}));

const navItems2 = [
  {
    text: 'Home',
    newPlatformURL: `${getNewPlatform()}/home`,
    path: '/app/home',
    applicableFor: ['OrgAdmin', 'OrgManager', 'OrgUser'],
  },
  {
    text: 'Vendors',
    applicableFor: ['OrgAdmin', 'OrgManager', 'OrgUser'],
    childrens: [
      {
        text: 'Discover',
        path: '/app/vendors/all-vendors',
        newPlatformURL: `${getNewPlatform()}/discover/products`,
        applicableFor: ['OrgAdmin', 'OrgManager', 'OrgUser'],
      },
    ],
  },
  {
    text: 'Projects',
    path: '/app/workflows_listing',
    newPlatformURL: `${getNewPlatform()}/projects`,
    applicableFor: ['OrgAdmin', 'OrgManager', 'OrgUser'],
  },
];

let navItems = [
  {
    text: 'Home',
    newPlatformURL: `${getNewPlatform()}/home`,
    path: '/app/home',
    applicableFor: ['OrgAdmin', 'OrgManager', 'OrgUser'],
  },
  {
    text: 'Vendors',
    applicableFor: ['OrgAdmin', 'OrgManager', 'OrgUser'],
    childrens: [
      {
        text: 'Discover',
        path: '/app/vendors/all-vendors',
        newPlatformURL: `${getNewPlatform()}/discover/products`,
        applicableFor: ['OrgAdmin', 'OrgManager', 'OrgUser'],
      },
      {
        text: 'Catalog',
        path: '/app/vendors/org-vendors',
        newPlatformURL: `${getNewPlatform()}/entity-type-landing/vendor`,
        applicableFor: ['OrgAdmin', 'OrgManager', 'OrgUser'],
      },
      {
        text: 'Contracts',
        path: '/contracts-landing',
        newPlatformURL: `${getNewPlatform()}/contracts-landing`,
        applicableFor: ['OrgAdmin', 'OrgManager', 'OrgUser'],
      },
      {
        text: 'Renewals',
        path: '/app/renewal',
        newPlatformURL: `${getNewPlatform()}/renewals-landing`,
        applicableFor: ['OrgAdmin', 'OrgManager', 'OrgUser'],
      },
      {
        text: 'Analytics',
        path: '/app/metrics',
        newPlatformURL: `${getNewPlatform()}/metrics`,
        applicableFor: ['OrgAdmin'],
      },
      {
        text: 'Risks',
        path: '/app/products/risks',
        newPlatformURL: `${getNewPlatform()}/risk`,
        applicableFor: ['OrgAdmin'],
      },
    ],
  },
  {
    text: 'Projects',
    path: '/app/workflows_listing',
    newPlatformURL: `${getNewPlatform()}/projects`,
    applicableFor: ['OrgAdmin', 'OrgManager', 'OrgUser'],
  },
  {
    text: 'Dashboard',
    newPlatformURL: `${getNewPlatform()}/dashboard`,
    path: '/app/dashboard-v2',
    applicableFor: ['OrgAdmin'],
  },
  {
    text: 'Settings',
    path: '/app/settings',
    newPlatformURL: `${getNewPlatform()}/settings`,
    applicableFor: ['OrgAdmin'],
  },
];

const DrawerV2 = ({ loginUser, classes, orgUser, storeSearchString, setLogoutFromFlag, logout, ...props }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [childMenuPosition, setChildMenuPosition] = useState(null);
  const [childMenu, setChildMenu] = useState({
    open: false,
    menu: null,
  });
  let enableNewPlatform = false
  const EnableVendorCatalogV2 = isEnabledProjectTracking(orgUser?.fetchAllOrgSettingsSuccess, "EnableVendorCatalogV2");
  if (EnableVendorCatalogV2) {
    enableNewPlatform = true
  }

  const isNavLinkActive = (item) => {
    if (window.location.pathname === item.path) {
      return true;
    } else {
      return false;
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenSubMenu = (event, menu) => {
    const target = event.currentTarget?.getBoundingClientRect();
    setChildMenu({ open: true, menu: menu });
    setChildMenuPosition({ top: target.bottom, left: target.left });
  };

  if (process.env.REACT_APP_ENV === "production" && loginUser?.OrgID === 92 && loginUser?.Role === 'OrgUser') {
    navItems = navItems2;
  }

  const getHideDrawerItem = () => {
    let hideItem = [];
    let role = loginUser.Role;

    if (process.env.REACT_APP_ENV === "production") {
      hideItem.push("Survey");
    }

    if (process.env.REACT_APP_ENV === "test") {
      hideItem.push("Survey");
    }

    if (loginUser?.Organization?.ID === 67) {
      hideItem.push("Analytics");
      hideItem.push("Evaluate");
    }

    let enableNewPlatform = false
    const EnableVendorCatalogV2 = isEnabledProjectTracking(orgUser?.fetchAllOrgSettingsSuccess, "EnableVendorCatalogV2");
    if (EnableVendorCatalogV2) {
      enableNewPlatform = true
    }

    if (!enableNewPlatform) {
      hideItem.push("Dashboard");
      hideItem.push("Contracts");
    }

    const EnableProjectsforAdminCheck = isEnabledEvaluate(orgUser?.fetchAllOrgSettingsSuccess, "EnableProjectsforAdminCheck");
    const EnableProjectsforUsersCheck = isEnabledEvaluate(orgUser?.fetchAllOrgSettingsSuccess, "EnableProjectsforUsersCheck");

    if ((!EnableProjectsforAdminCheck && role !== "OrgUser") || (!EnableProjectsforUsersCheck && role === "OrgUser")) {
      hideItem.push("Projects");
    }

    const EnableReviewForUsersCheck = isEnabledReview(orgUser?.fetchAllOrgSettingsSuccess, "EnableReviewForUsersCheck");
    const EnableReviewForAdminsCheck = isEnabledReview(orgUser?.fetchAllOrgSettingsSuccess, "EnableReviewForAdminsCheck");

    if ((!EnableReviewForAdminsCheck && role !== "OrgUser") || (!EnableReviewForUsersCheck && role === "OrgUser")) {
      hideItem.push("Review");
    }

    const EnableEvaluateForUsersCheck = isEnabledEvaluate(orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForUsersCheck");
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForAdminsCheck");

    if ((!EnableEvaluateForAdminsCheck && role !== "OrgUser") || (!EnableEvaluateForUsersCheck && role === "OrgUser")) {
      hideItem.push("Evaluate");
    }

    // const EnableRenewalForUsersCheck = isEnabledEvaluate(orgUser?.fetchAllOrgSettingsSuccess, 'EnableRenewalForUsersCheck')
    const EnableRenewalForAdminsCheck = isEnabledEvaluate(orgUser?.fetchAllOrgSettingsSuccess, "EnableRenewalForAdminsCheck");

    if ((!EnableRenewalForAdminsCheck && role !== "OrgUser") || role === "OrgUser") {
      hideItem.push("Renewals");
    }

    const EnableSurveyForUsersCheck = isEnabledEvaluate(orgUser?.fetchAllOrgSettingsSuccess, "EnableSurveyForUsersCheck");
    const EnableSurveyForAdminsCheck = isEnabledEvaluate(orgUser?.fetchAllOrgSettingsSuccess, "EnableSurveyForAdminsCheck");

    if ((!EnableSurveyForAdminsCheck && role !== "OrgUser") || (!EnableSurveyForUsersCheck && role === "OrgUser")) {
      hideItem.push("Survey");
    }

    const EnableOtherWorkflowsForUsersCheck = isEnabledEvaluate(orgUser?.fetchAllOrgSettingsSuccess, "EnableOtherWorkflowsForUsersCheck");
    const EnableOtherWorkflowsForAdminsCheck = isEnabledEvaluate(orgUser?.fetchAllOrgSettingsSuccess, "EnableOtherWorkflowsForAdminsCheck");

    if ((!EnableOtherWorkflowsForAdminsCheck && role !== "OrgUser") || (!EnableOtherWorkflowsForUsersCheck && role === "OrgUser")) {
      hideItem.push("Custom");
    }

    // if(hideItem.indexOf('Evaluate') !== -1 && hideItem.indexOf('Review') !== -1 && hideItem.indexOf('Renewal') !== -1 && hideItem.indexOf('Survey') !== -1 && hideItem.indexOf('Custom') !== -1){
    //   hideItem.push('Workflows')
    // }

    const GoogleSyncActivityLog = isSyncActivityLog(orgUser?.fetchAllOrgSettingsSuccess, "Google_SYNC_ACTIVITY_LOG");
    const OCTASyncActivityLog = isSyncActivityLog(orgUser?.fetchAllOrgSettingsSuccess, "Okta_SYNC_ACTIVITY_LOG");
    if (GoogleSyncActivityLog === false && OCTASyncActivityLog === false) {
      hideItem.push("Analytics");
    }

    return hideItem;
  }

  let hideMenus = getHideDrawerItem();

  return (
    <div className={classes.HeaderContainer}>
      <div className={classes.LogoTextWrap}>
        <Image src={Logo} alt="logo" width={20} height={40} />
        <div className={classes.ExpentText}>Expent</div>
      </div>

      <div className={classes.NavigationWrap}>
        {loginUser &&
          navItems
            .filter((o) => o.applicableFor.indexOf(loginUser.Role) !== -1)
            .map((item, i) => {
              if (hideMenus.indexOf(item.text) !== -1) {
                return null;
              }
              return (
                <div className={classes.NavText}
                  key={i}
                  onClick={(e) => {
                    storeSearchString("");
                    if (enableNewPlatform) {
                      if (item.path) {
                        if (item.newPlatformURL) {
                          window.open(item.newPlatformURL, "_self")
                        } else {
                          props.history.push(item.path);
                        }
                      } else {
                        handleOpenSubMenu(e, item);
                      }
                    } else if (item.path) {
                      props.history.push(item.path);
                    } else {
                      handleOpenSubMenu(e, item);
                    }
                  }}
                  style={{
                    background: isNavLinkActive(item)
                      ? 'linear-gradient(180deg, rgba(0, 39, 51, 0) 0%, #002733 100%)'
                      : 'transparent',
                    opacity: isNavLinkActive(item) ? 1 : 0.6,
                  }}
                >
                  {item.text}
                  {isNavLinkActive(item) && <ActiveBorderBottom />}
                  {item?.childrens && item?.childrens?.length > 0 && (
                    <Image src={DownArrowIcon} alt="down-arrow" width={18} height={18} />
                  )}
                </div>
              );
            })}
        {childMenu.open && childMenu.menu?.childrens && (
          <ClickAwayListener
            onClickAway={() => {
              setChildMenu({ open: false, menu: null });
              setChildMenuPosition(null);
            }}
          >
            <Paper style={{
              position: 'fixed',
              width: '220px',
              top: (childMenuPosition?.top || 0) + 5,
              left: childMenuPosition?.left,
              zIndex: 9999,
              padding: '4px 0px',
              backgroundColor: '#003B4D',
              borderRadius: '10px'
            }}>
              {childMenu.menu?.childrens
                .filter((o) => o.applicableFor.indexOf(loginUser.Role) !== -1)
                .map((subMenu, i) => {
                  if (hideMenus.indexOf(subMenu.text) !== -1) {
                    return null;
                  }
                  return <React.Fragment key={i}>
                    <MenuItem
                      onClick={() => {
                        if (enableNewPlatform) {
                          if (subMenu.newPlatformURL) {
                            window.open(subMenu.newPlatformURL, "_self")
                          } else {
                            props.history.push(subMenu.path);
                          }
                        } else {
                          props.history.push(subMenu.path);
                        }
                        setChildMenu({ open: false, menu: null });
                        setChildMenuPosition(null);
                      }}
                      sx={{
                        '&:hover': {
                          opacity: 0.8,
                        },
                      }}
                    >
                      <ListItemText>
                        <Typography style={{
                          color: '#fff',
                          fontWeight: 400,
                          margin: 0,
                          lineHeight: 1.5,
                          fontSize: '0.875rem',
                          fontFamily: 'Manrope',
                          letterSpacing: '0.00938em',

                        }}>{subMenu.text}</Typography>
                      </ListItemText>

                    </MenuItem>
                    {childMenu.menu?.childrens && childMenu.menu?.childrens?.length - 1 > i && (
                      <Divider style={{
                        margin: '0.2rem 1rem',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        borderWidth: 0,
                        borderColor: '#ffffff26',
                        borderBottomWidth: 'thin',
                        borderStyle: 'solid',
                        lineHeight: 1.5,
                        fontEeight: 600,
                        fontSize: '0.875rem'
                      }} />
                    )}
                  </React.Fragment>
                })}
            </Paper>
          </ClickAwayListener>
        )}
      </div>

      <div className={classes.UserInfoIconWrap}>
        <div className={classes.IconsWrap}>
          <Image src={NotesIcon} alt="notes" width={14} height={18} />
        </div>
        <div className={classes.IconsWrap}>
          <Image src={BookmarkIcon} alt="bookmark" width={14} height={18} />
        </div>
        <div className={classes.IconsWrap}>
          <Image src={NotificationIcon} alt="notification" width={16} height={20} />
        </div>
        <div className={classes.UserWrap} onClick={handleOpenUserMenu}>
          <div className={classes.UserIconsWrap}>
            <Image src={UserIcon} alt="user" width={20} height={20} />
          </div>
          <div className={classes.UserText}>{loginUser?.Name}</div>
          <Image src={DownArrowIcon} alt="arrow" width={14} height={14} />
        </div>
      </div>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          onClick={() => {
            handleCloseUserMenu();
            props.history.push("/app/profile");
          }}
        >

          <ListItemText>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Image src={SettingsIcon} alt="notes" width={22} height={22} />
              <Typography style={{
                margin: 0,
                lineHeight: 1.5,
                fontSize: '0.875rem',
                fontFamily: 'Manrope',
                letterSpacing: '0.00938em',
                display: 'block',
                fontWeight: 500,
                marginLeft: '10px'

              }}> Profile </Typography>
            </div>
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseUserMenu();
            setLogoutFromFlag(true);
            logout();
          }}
        >
          <ListItemText style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Image src={UndoIcon} alt="notes" width={22} height={22} />
              <Typography style={{
                margin: 0,
                lineHeight: 1.5,
                fontSize: '0.875rem',
                fontFamily: 'Manrope',
                letterSpacing: '0.00938em',
                display: 'block',
                fontWeight: 500,
                marginLeft: '10px'
              }}> Logout </Typography>
            </div>
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default connector(compose(
  withRouter,
  withStyles(styles)
)(DrawerV2));
