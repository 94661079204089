import { useEffect, useRef } from "react";
import Spreadsheet from "x-data-spreadsheet";
import "x-data-spreadsheet/dist/xspreadsheet.css";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = () =>
  createStyles({
    disabledSheet: {
      pointerEvents: "none",
    },
  });

const ReadSheet = ({ classes, sheetState, noOfRows, noOfCols, setSheetState, selectCells, isProcessed }) => {
  const sheet = useRef(null);
  let options = {
    mode: "read",
    showToolbar: false,
    showGrid: true,
    row: {
      len: noOfRows > 0 ? noOfRows : 50,
    },
    col: {
      len: noOfCols > 0 ? noOfCols : 50,
    },
    // showContextmenu: true,
  };

  useEffect(() => {
    const newSheet = new Spreadsheet(sheet?.current, {
      view: {
        height: () => document.documentElement.clientHeight - 100,
        width: () => document.documentElement.clientWidth,
      },
      ...options,
    });
    newSheet.loadData(sheetState).change((data) => {
      setSheetState(data);
    });
    newSheet.on("cell-selected", (cell, ri, ci) => {
      selectCells(null);
    });
    newSheet.on("cells-selected", (cell, { sri, sci, eri, eci }) => {
      var range = {
        s: { r: sri, c: sci },
        e: { r: eri, c: eci },
      };
      let newRange = XLSX.utils.encode_range(range);

      let columns = [];
      // let valueMap = {}
      for (var i = sci; i <= eci; i++) {
        // valueMap[i] = {}
        let column = newSheet.cell(sri, i);
        // if (column) {
        var cellRef = XLSX.utils.encode_cell({ r: sri, c: i });
        // valueMap[i][cellRef] = column.text;
        console.log(i, cellRef, column, "cellRef");
        columns.push({
          ...column,
          name: cellRef,
          row: sri,
          col: i,
          id: sri + ":" + i,
        });
        // }
      }
      selectCells({
        SelectedSheet: newSheet.sheet?.data?.name,
        SelectedRowId: sri,
        SelectedColumnId: sci,
        EndRowId: eri,
        EndColumnId: eci,
        selectedRange: newRange,
        columns: columns,
        dataMap: newSheet.sheet.data.dataMap,
      });
    });
    newSheet.on("cell-edited", (text, ri, ci) => {});
  }, []);
  return <div ref={sheet} className={isProcessed ? classes.disabledSheet : ""}></div>;
};

// export default ReadSheet;
export default connector(compose(withStyles(styles))(ReadSheet));
