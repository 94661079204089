import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, FormHelperText,MenuItem } from "@material-ui/core"
import PropTypes from 'prop-types';
import classnames from "classnames";
import ToolTip from "Components/Common/ToolTip.jsx";

const styles = (theme) => createStyles({
    "input": {
        textAlign: "left",
        marginBottom: theme.spacing(2),
        background:"#FFF"
    },
    "noMargin": {
        marginBottom: "0 !important",
    },
    rounded:{
        borderRadius:theme.spacing(3),
    },
    "label": {
        marginBottom: theme.spacing(1),
        fontSize:theme.spacing(2),
        color:"#6C6C6C"
    },
    outlinedInput:{
        fontSize:theme.spacing(2.4),
        color:"#5F5F5F",
        padding:theme.spacing(1.5)
    },
    helperText:{
        fontSize:theme.spacing(1.5),
    },
    nobg:{
        background:"none"
    },
    inputRoot:{
        background: "#fff",
    },
    inline:{
        display:"flex",
        alignItems: "center",
        justifyContent: "center",
        "& $label":{
            marginRight: theme.spacing(2),
            marginBottom: 0,
        }
    },
    formControl:{
        minWidth:theme.spacing(15)
    },
    compact:{
        padding:theme.spacing(1,1.5),
        fontSize:theme.spacing(2),
    }
});

class Outlined extends React.Component {
    render() {
        var classes = this.props.classes;
        return <div className={classnames(classes.input,{[classes.nobg]:this.props.roundedCorner,[classes.inline]:this.props.labelStyle === "inline",[classes.noMargin]:this.props.noMargin})}>
            {this.props.label !== null && <InputLabel
                id={this.props.id+"_inputlabel"}
                className={classes.label}
                error={this.props.error}
                htmlFor={this.props.id}>{this.props.label}{this.props.required && <span style={{ 'color': 'red' }}>&nbsp;*</span>} {this.props.toolTip && <ToolTip toolTip={this.props.toolTip} />}</InputLabel>}
            <FormControl variant="outlined" fullWidth={this.props.labelStyle !== "inline"}  onClick={this.props.onClick} className={classes.formControl}>
                <Select
                    labelId={this.props.id+"_selectlabel"}
                    id={this.props.id}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled && this.props.disabled === true ? true : false}
                    classes={{
                        root:classnames(classes.inputRoot,{
                            [classes.rounded]:this.props.roundedCorner,
                            [classes.compact]:this.props.compact || this.props.labelStyle === "inline"
                        }),
                    }}
                >
                    {
                        this.props.options.map((item, k)=>{
                            return  <MenuItem id={this.props.id+"_menuitem"} value={item.value} key={k}>
                                &nbsp;&nbsp;&nbsp;
                                {item.label}
                            </MenuItem>
                        })
                    }
                </Select>
                {this.props.helperText.trim().length > 0 && <FormHelperText
                    className={classes.helperText}
                    error={this.props.error}
                >{this.props.helperText}</FormHelperText>}
            </FormControl>
        </div>
    }
}

Outlined.defaultProps = {
    labelWidth: 70,
    type: 'text',
    value: null,
    onChange: null,
    onKeyDown:null,
    icon: null,
    icon_position: 'end',
    onIconClick: null,
    placeholder: "",
    required: false,
    error: false,
    disabled: false,
    helperText: '',
    passwordStenghtMeter:false,
    label:null,
    multiline:false,
    rows:1 ,   
    roundedCorner:false,
    'id':'input',
    readOnlyInput:false,
    labelStyle:"default",
    compact:false,
    noMargin:false
}

Outlined.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    options:PropTypes.array.isRequired,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    icon: PropTypes.element,
    icon_position: PropTypes.oneOf(['end', 'start']),
    onIconClick: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    passwordStenghtMeter:PropTypes.bool,
    multiline:PropTypes.bool,
    rows:PropTypes.number,
    roundedCorner:PropTypes.bool,
    onClick:PropTypes.func,
    readOnlyInput:PropTypes.bool,
    labelStyle:PropTypes.oneOf(['default', 'inline']),
    compact:PropTypes.bool,
    noMargin:PropTypes.bool,
};

export default withStyles(styles)(Outlined)