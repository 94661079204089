import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import * as icons from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: (props) => ({
    border: props.border,
    borderLeft: props.borderLeft,
    boxShadow: "none",
  }),
  iconColor: (props) => ({
    color: props.color,
  }),
  icon: (props) => ({
    fontSize: "50px",
    color: props.color,
    marginRight: 15,
  }),
  cardContent: {
    marginBottom: "0px",
    padding: "16px !important",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 600,
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "18px",
  },
  subTitle: {
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "18px",
  },
}));

const CustomButton = withStyles((theme) => ({
  root: (props) => ({
    fontWeight: 500,
    color: props.color || theme.palette.primary.main,
    borderColor: props.color || theme.palette.primary.main,
    textAlign: "left",
    "&:hover": {
      // backgroundColor: props.hoverColor || purple[700],
      // borderColor: props.hoverColor || purple[700],
      // color: theme.palette.getContrastText(props.hoverColor || purple[700]),
    },
  }),
  startIcon: (props) => ({
    marginLeft: "-6px",
    marginRight: "16px",
  }),
}))(Button);

const LinkCard = (props) => {
  const { title, subTitle, link, iconName, color, isButton } = props;
  const [SelectedIcon, setSelectedIcon] = useState(icons[iconName]);
  const dynamicStyle = { color: color, border: `1px solid ${color}`, borderLeft: `8px solid ${color}` };
  const classes = useStyles(dynamicStyle);

  if (isButton) {
    return (
      <CustomButton href={link} target="_blank" rel="noreferrer" size="large" variant="outlined" color={color} startIcon={SelectedIcon && <SelectedIcon className={classes.iconColor} />}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ color: color, lineHeight: "20px" }}>{title}</div>
          <div style={{ color: color, fontSize: "0.75rem" }}>{subTitle}</div>
        </div>
      </CustomButton>
    );
  } else {
    return (
      <Card className={classes.card}>
        <a href={link} target="_blank" rel="noreferrer">
          <CardContent className={classes.cardContent}>
            <Grid container alignItems="center">
              <Grid item>{SelectedIcon ? <SelectedIcon className={classes.icon} /> : <DashboardIcon className={classes.icon} />}</Grid>
              <Grid item>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.subTitle}>{subTitle}</Typography>
                {/* <Typography variant="h5">{value}</Typography> */}
              </Grid>
            </Grid>
          </CardContent>
        </a>
      </Card>
    );
  }
};

export default LinkCard;
