import {
    START_EVALUATION_FETCH_FOR_SUMMARY, END_EVALUATION_FETCH_FOR_SUMMARY,
    START_EVALUATION_SCORE_FETCH, END_EVALUATION_SCORE_FETCH,
    START_EVALUATION_CRITERIA_FETCH, END_EVALUATION_CRITERIA_FETCH,
    START_EVALUATION_FORM_DECISION_ITEMS, END_EVALUATION_FORM_DECISION_ITEMS,
    SHOW_FORM_DATA_COPY_DIALOG, HIDE_FORM_DATA_COPY_DIALOG,
    SHOW_EVALUATION_DATA_COPY_DIALOG, HIDE_EVALUATION_DATA_COPY_DIALOG,
    START_FETCH_WORKFLOW_COMPONENT_DETAILS_FOR_SUMMARY,END_FETCH_WORKFLOW_COMPONENT_DETAILS_FOR_SUMMARY
} from "./action";

const initState = {
    fetchProgress: false,
    errorFetch: null,
    evaluation: null,
    fetchScoreProgress: false,
    scores: {},
    criterias: null,
    fetchCriteriaProgress: false,

    fetchDecisionItemsProgress: false,
    fetchDecisionItemsError: null,
    decisionItems: [],

    isShowFormDataCopyDialog: false,
    isShowEvaluationDataCopyDialog: false,

    workflowComponentDetail: null,
    fetchworkflowComponentDetailProgress: false,
}


export function workflowSummaryReducer(state = initState, action) {
    switch (action.type) {

        case START_EVALUATION_FETCH_FOR_SUMMARY:
            return {
                ...state,
                fetchProgress: true,
                errorFetch: false,
                evaluation: null,
            }

        case END_EVALUATION_FETCH_FOR_SUMMARY:
            return {
                ...state,
                fetchProgress: false,
                errorFetch: action.payload.error,
                evaluation: action.payload.success,
            }

        case START_EVALUATION_SCORE_FETCH:
            return {
                ...state,
                fetchScoreProgress: true,
                scores: {},
            }

        case END_EVALUATION_SCORE_FETCH:
            var iscores = action.payload.success;
            var scores = {};
            if (iscores !== null) {
                iscores.forEach((score) => {
                    if (scores[score.productId] === undefined) {
                        scores[score.productId] = {}
                    }
                    scores[score.productId][score.criteriaId] = score
                })
            }
            return {
                ...state,
                fetchScoreProgress: true,
                scores: scores,
            }

        case START_EVALUATION_CRITERIA_FETCH:
            return {
                ...state,
                fetchCriteriaProgress: true,
            }

        case END_EVALUATION_CRITERIA_FETCH:
            return {
                ...state,
                fetchCriteriaProgress: false,
                criterias: action.payload.success
            }

        case START_EVALUATION_FORM_DECISION_ITEMS:
            return {
                ...state,
                fetchDecisionItemsProgress: true,
                fetchDecisionItemsError: null,
                decisionItems: [],
            }

        case END_EVALUATION_FORM_DECISION_ITEMS:
            return {
                ...state,
                fetchDecisionItemsProgress: false,
                fetchDecisionItemsError: action.payload.error,
                decisionItems: action.payload.success,
            }

        case SHOW_FORM_DATA_COPY_DIALOG:
            return {
                ...state,
                isShowFormDataCopyDialog: true,
            };

        case HIDE_FORM_DATA_COPY_DIALOG:
            return {
                ...state,
                isShowFormDataCopyDialog: false,
            };


        case SHOW_EVALUATION_DATA_COPY_DIALOG:
            return {
                ...state,
                isShowEvaluationDataCopyDialog: true,
            };

        case HIDE_EVALUATION_DATA_COPY_DIALOG:
            return {
                ...state,
                isShowEvaluationDataCopyDialog: false,
            };

        case START_FETCH_WORKFLOW_COMPONENT_DETAILS_FOR_SUMMARY:
            return {
                ...state,
                workflowComponentDetail: null,
                fetchworkflowComponentDetailProgress: true,
            }
        case END_FETCH_WORKFLOW_COMPONENT_DETAILS_FOR_SUMMARY:
            return {
                ...state,
                workflowComponentDetail: action.payload.success,
                fetchworkflowComponentDetailProgress: false,
            };

        default:
            return state;
    }
}