import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import {  Dialog, DialogContent, Slide,DialogTitle,Typography,IconButton,DialogActions,Button, CircularProgress  } from '@material-ui/core';
import {  Delete as DeleteIcon,AddCircle as AddCircleIcon } from '@material-ui/icons';
import ReactSelect from 'react-select'
import { withRouter } from 'react-router-dom';
import * as Validator from "util/Validation";
import {hideEvaluationRuleDialog,saveEvaluationRule,fetchEvaluationRule} from "redux/evaluation/rule/action";
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import Alert from '@material-ui/lab/Alert';
// dialogs
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen:state.evaluationRule.showDialog,
  currentEvaluation:state.evaluationMetaData.evaluation,
  dialogContent:state.evaluationRule.dialogContent,
  dialogType:state.evaluationRule.dialogType,
  ruleSaveProgress:state.evaluationRule.ruleSaveProgress,
  errorRuleSave:state.evaluationRule.errorRuleSave,
  rulefetchProgress:state.evaluationRule.rulefetchProgress,
  rule:state.evaluationRule.rule,
  criterias:state.evaludationCriteria.criterias
});

const connectionActions = {
  hideDialog:hideEvaluationRuleDialog,
  saveEvaluationRule:saveEvaluationRule,
  fetchEvaluationRule:fetchEvaluationRule
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent:{
    padding: theme.spacing(2, 6) +" !important",
    minHeight:"300px"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  ruleTable:{
    width:"100%"
  },
  deleteIcon:{
    color:"red"
  },
  op2:{
    width:"10%"
  },
  op1:{
    width:"10%"
  },
  leftOp:{
    width:"35%"
  },
  rightOp:{
    width:"35%"
  },
  action:{
    width:"10%",
    textAlign:"center"
  },
  alerts:{
    marginTop:theme.spacing(2),
    marginBottom:theme.spacing(2)
  },
});

class EvaluationRuleDialog extends React.Component {

    constructor(props){
        super(props);
        this.state  =   {
          error:"",
          success:"",
          ruleElement:[
            {
              "op2":0,
              "leftOp":null,
              "op1":1,
              "rightOP":[]
            }
          ]
        }
        
        this.addNewRule   =   this.addNewRule.bind(this);
        this.deleteRule   =   this.deleteRule.bind(this);
        this.saveRule   =   this.saveRule.bind(this);
    }

    componentDidMount(){

    }

    componentDidUpdate(prevProps){
      if(this.props.isOpen && prevProps.isOpen === false){
        this.props.fetchEvaluationRule(this.props.currentEvaluation.ID,
          this.props.dialogType,
          this.props.dialogContent);

          

          this.setState({
            error:"",
            success:"",
            ruleElement:[
              {
                "op2":0,
                "leftOp":null,
                "op1":1,
                "rightOP":[]
              }
            ]
          })
      }
      if(this.props.rulefetchProgress === false && prevProps.rulefetchProgress === true){
        var requirementMap = {};
        this.props.criterias.forEach((criteria)=>{
          criteria.requirements.forEach((requirement)=>{
            requirementMap["R="+requirement.id] = requirement;
          })
        });
        if(this.props.rule.ID === 0 || this.props.rule.Elements === null){
          return;
        }
        var ruleElements = [];
        this.props.rule.Elements.sort((a,b)=>{
          if(a.Seq > b.Seq){
            return 1
          } else if(a.Seq < b.Seq){
            return -1
          } else {
            return 0
          }
        }).forEach((element,k)=>{
          if(element.OpID === 1 || element.OpID === 2){
            var op2 = 0;
            if(k !== 0){
              op2 = this.props.rule.Elements[k+1].OpID
            }

            var rightOpArray = []
            if(element.OpID === 1){
              rightOpArray = [parseInt(element.RightExpression)]
            } else {
              if(Validator.isJson(element.RightExpression)){
                rightOpArray = JSON.parse(element.RightExpression)
              }
            }
            var rightOp = requirementMap[element.LeftExpression].options.filter((op)=>{
              return rightOpArray.indexOf(op.ID) !== -1
            }).map((op)=>{
              return {value:op.ID,
                label:op.Title}
            })

            ruleElements.push({
              "op2":op2,
              "leftOp":{
                value:element.LeftExpression,
                label:requirementMap[element.LeftExpression].name,
                requirement:requirementMap[element.LeftExpression]
              },
              "op1":element.OpID,
              "rightOP":element.OpID === 1?rightOp[0]:rightOp
            })
          }
          
        })

        this.setState({
          error:"",
          success:"",
          ruleElement:ruleElements
        })
      }
      if(this.props.ruleSaveProgress === false && prevProps.ruleSaveProgress === true){
        if(this.props.errorRuleSave === null){
          this.setState({
            "success":"Rule updated successfully."
          })
        }
      }
    }

    addNewRule(){
      var rules = this.state.ruleElement;
      rules.push({
        "op2":3,
        "leftOp":null,
        "op1":1,
        "rightOP":null
      })
      this.setState({
        ruleElement:rules
      })
    }

    deleteRule(index){
      var rules = this.state.ruleElement;
      rules.splice(rules,1)
      this.setState({
        ruleElement:rules
      })
    }

    saveRule(){

      var rules = [];
      var seq = 1;

      this.setState({
        "error":"",
        "success":""
      })
      var errorMessage = "";
      this.state.ruleElement.forEach((ruleElem,k)=>{
        if(errorMessage.length > 0){
          return;
        }
        if(ruleElem.leftOp === null){
          errorMessage = `Error Row ${k+1} please select question.`
          return;
        }

        if(ruleElem.rightOP === null || ruleElem.rightOP.length === 0){
          errorMessage = `Error Row ${k+1} please select option.`
          return;
        }

        rules.push({
          leftExp:ruleElem.leftOp.value,
          opId:ruleElem.op1,
          rightExp:JSON.stringify(ruleElem.op1 === 1?ruleElem.rightOP.value:ruleElem.rightOP.map((i)=>{
            return i.value
          })),
          seq:seq
        })
        seq++;
        if(k !== 0){
          rules.push({
            leftExp:"E="+(seq-2),
            opId:ruleElem.op2,
            rightExp:"E="+(seq-1),
            seq:seq
          })
          seq++;
        }
      })
      this.props.saveEvaluationRule(
        this.props.currentEvaluation.ID,
        this.props.dialogType,
        this.props.dialogContent,
        rules
      )
    }

    render() {
        const classes = this.props.classes;
        var me  =   this;

        var requirementOptions = [];
        if(this.props.criterias !== null){
          this.props.criterias.forEach((criteria)=>{
            var optionsLenght = criteria?.requirements.filter((r)=>{
              if(r.answer_type === 1){
                return false;
              }
              return true;
            }).length
            if(optionsLenght === 0){
              return;
            }
            requirementOptions.push({
              value:"C="+criteria.id,
              label:criteria.name,
              isDisabled:true
            })
            criteria.requirements.forEach((requirement)=>{
              if(requirement.answer_type === 1){
                return;
              }
              requirementOptions.push({
                value:"R="+requirement.id,
                label:requirement.name,
                requirement:requirement
              })
            })
          })
        }
        
        return <Dialog 
                    onClose={this.props.hideDialog} 
                    aria-labelledby="app-evRuleDialog" 
                    open={ this.props.isOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={false}
                    fullWidth={true}
                    maxWidth={"md"}
                    scroll="body"
                    id="evRuleDialog"
                    // PaperProps={{
                    //   id:"evRuleDialog"
                    // }}
                    
                    >
                    <DialogTitle disableTypography>
                        {this.props.dialogContent !== null && <>
                        <Typography variant={"h6"}> Rules For {this.props.dialogType === "criteria"?"Criteria":"Requirement"} {this.props.dialogContent.name}</Typography>
                        </>}
                        {this.props.dialogContent === null && <>
                        <Typography variant={"h6"}> Rules</Typography>
                        </>}
                        <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.hideDialog}>
                          <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    {this.props.rulefetchProgress && <DialogContent dividers={true} classes={{root:classes.dialogContent}}>
                      <CircularProgress/>
                    </DialogContent>}
                    {!this.props.rulefetchProgress && <>
                    <DialogContent dividers={true} classes={{root:classes.dialogContent}}>
                        <div className={classes.alerts}>
                            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                            {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                        </div>
                        <table className={classes.ruleTable}>
                          {
                            this.state.ruleElement.map((rule,k)=>{
                              var options = [];
                              var operand = [
                                {
                                  value:1,
                                  label:"=="
                                },
                                {
                                  value:2,
                                  label:"in"
                                }
                              ]
                              if(rule.leftOp !== null){
                                rule.leftOp.requirement.options.forEach((op)=>{
                                  options.push({
                                    value:op.ID,
                                    label:op.Title
                                  })
                                })
                                operand = [];
                                if(rule.leftOp.requirement.answer_type === 3){
                                  operand.push({
                                    value:1,
                                    label:"=="
                                  })
                                } else if (rule.leftOp.requirement.answer_type === 2){
                                  operand.push({
                                    value:2,
                                    label:"in"
                                  })
                                }
                              }
                              return <tr key={k}>
                                      <td className={classes.op2}>
                                        <div style={{visibility:k === 0?"hidden":"visible"}}>
                                          <OutlinedSelectInput
                                            value={rule.op2}
                                            options={[
                                                {
                                                    value:3,
                                                    label:"And"
                                                },
                                                {
                                                    value:4,
                                                    label:"Or"
                                                }

                                            ]}
                                            noMargin={true}
                                            compact={true}
                                            onChange={(event) => {
                                                var ruleElements    =   me.state.ruleElement;
                                                var ruleElement  =  ruleElements[k];
                                                ruleElement.op2    = event.target.value;
                                                this.setState({ ruleElement: ruleElements });
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td className={classes.leftOp}>
                                        <ReactSelect
                                              isClearable
                                              isSearchable={true}
                                              options={requirementOptions}
                                              classNamePrefix="select"
                                              placeholder="Search Requirement" 
                                              onChange={(e,action)=>{
                                                  var ruleElements    =   me.state.ruleElement;
                                                  var ruleElement  =  ruleElements[k];
                                                  ruleElement.leftOp    =e;
                                                  if(e.requirement.answer_type === 3){
                                                    ruleElement.op1 = 1
                                                  } else {
                                                    ruleElement.op1 = 2
                                                  }
                                                  
                                                  this.setState({ ruleElement: ruleElements });
                                              }}
                                              menuPortalTarget={document.getElementById("evRuleDialog")} 
                                              value={rule.leftOp}
                                          />
                                      </td>
                                      <td className={classes.op1}>
                                        <OutlinedSelectInput
                                          value={rule.op1}
                                          options={operand}
                                          noMargin={true}
                                          compact={true}
                                          onChange={(event) => {
                                            return;
                                          }}
                                        />
                                      </td>
                                      <td className={classes.rightOp}>
                                        <ReactSelect
                                              isClearable
                                              isSearchable={true}
                                              isMulti={rule.op1 === 2}
                                              options={options}
                                              classNamePrefix="select"
                                              placeholder="Search Options" 
                                              onChange={(e,action)=>{
                                                  var ruleElements    =   me.state.ruleElement;
                                                  var ruleElement  =  ruleElements[k];
                                                  ruleElement.rightOP    =e;
                                                  this.setState({ ruleElement: ruleElements });
                                              }}
                                              menuPortalTarget={document.getElementById("evRuleDialog")} 
                                              value={rule.rightOP}
                                          />
                                      </td>
                                      <td className={classes.action}>
                                        {k !== this.state.ruleElement.length-1 && <IconButton size="small" onClick={this.deleteRule}>
                                          <DeleteIcon className={classes.deleteIcon}/>
                                        </IconButton>}
                                        {k === this.state.ruleElement.length-1 && <IconButton size="small" onClick={this.addNewRule}>
                                          <AddCircleIcon/>
                                        </IconButton>}
                                      </td>
                                    </tr>
                            })
                          }
                            
                        </table>
                    </DialogContent>
                    <DialogActions>
                      {!this.props.ruleSaveProgress && <>
                      <Button variant="contained" color="secondary" onClick={this.saveRule}>
                        Save
                      </Button>
                      <Button variant="outlined" color="secondary" onClick={this.props.hideDialog}>
                        Cancel
                      </Button>
                      </>}
                      {this.props.ruleSaveProgress && <CircularProgress/> }
                  </DialogActions>
                  </>}
                </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(EvaluationRuleDialog));