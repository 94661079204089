import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "../Style/index.css";
import Arrow from '../Components/Arrow';
import SubTreeStartArrow from '../Components/SubTreeStartArrow';
import ActionNode from './ActionNode';
import { showBuilderConfigDrawer } from "redux/automations/builder/action"
import CallSplitIcon from '@material-ui/icons/CallSplit';
import SubTreeEndArrow from '../Components/SubTreeEndArrow';

import StepMenu from './StepMenu';
import { getComponentType, getTargetComponentId } from "../Components/FlowData"
import { getControlItems } from "../Components/BuilderFunctions"
import {
  addNewAutomationAction
} from 'redux/automations/action';
import LoopNode from './LoopNode';
const connectedProps = (state) => ({
  masterTriggers: state.automation.masterTriggers,
  masterActions: state.automation.masterActions,

  fetchActionConnectionsProgress: state.automation.fetchActionConnectionsProgress,
  fetchActionConnectionsError: state.automation.fetchActionConnectionsError,
});

const connectionActions = {
  showBuilderConfigDrawer: showBuilderConfigDrawer,
  addNewAutomationAction: addNewAutomationAction
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({

});

class IfConditionNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      childrens: [],
      controlComponents: [],
      callOnceDone: false,
      isShowBottomSubStepMenu: false,
      isShowTopSubStepMenu: false,
      automationComponents: [],
      automationConnections: []
    }
  }

  componentDidMount() {
    this.initializeChildrens()
  }

  componentDidUpdate(prevProps) {

  }

  getMasterAction(id) {
    let action = ''
    const { masterActions } = this.props;
    if (masterActions) {
      action = masterActions.filter(o => o.id === id)[0];
    }
    return action;
  }

  getConnectionId(sourceId, connections, targetId) {
    console.log(sourceId, 'sourceId')
    console.log(targetId, 'targetId')
    console.log(connections, 'connections')
    let connectionId = null;
    if (connections) {
      try {
        connectionId = connections.filter((co) => co.SourceComponentID === sourceId && co.TargetComponentID === targetId)[0].ID
      } catch (e) { }
    }

    return connectionId
  }


  initializeChildrens() {
    const { data } = this.props;
    const automationComponents = data.automation_Components;
    const automationConnections = data.automation_Connections;
    // console.group('---------------Initializing--------------')
    // console.log(this.props.data)
    // console.log(automationComponents)
    // console.log(automationConnections)
    // console.groupEnd()
    this.setState({
      automationComponents: automationComponents,
      automationConnections: automationConnections,
    })
    let childrenDatas = getControlItems(this.props.data?.id, automationConnections, automationComponents, 'IF')
    console.log(childrenDatas, 'childrenDatas')
    let elementList = []
    let me = this;
    if (childrenDatas && childrenDatas) {
      childrenDatas.forEach(function (action) {
        if (action) {
          if (action.Type === 'Action') {
            let masterData = '';
            if (action.RefType === 'Master') {
              masterData = me.getMasterAction(action.RefID)
            }
            let dataObjects = {
              id: action.ID,
              type: 'actionNode',
              componentType: action.Type,
              slug: 'ACTION',
              dragHandle: '.custom-drag-handle',
              style: { opacity: 1 },
              position: { x: 100, y: 50 },
              data: {
                id: action?.ID,
                isNew: false,
                label: (!masterData || masterData === '') ? 'Select Action Type' : masterData?.name,
                type: 'actionNode',
                componentType: action.Type,
                slug: 'ACTION',
                inputJson: (!masterData || masterData === '') ? '' : masterData.inputJson ? masterData.inputJson : '',
                responseJson: action.InputData,
                object: action,
                masterData: masterData,
                isShowAddOption: me.props.data.isShowAddOption,
                automation_Components: automationComponents,
                automation_Connections: automationConnections,
              }
            }
            elementList.push(dataObjects)
          } else if (true && action.Type === 'Control' && (action.RefID === 'if' || action.RefID === 'ifelse' || action.RefID === 'else')) {
            let dataObjects = {
              id: action.ID,
              type: 'ifConditionNode',
              componentType: action.Type,
              slug: 'ACTION',
              dragHandle: '.custom-drag-handle',
              style: { opacity: 1 },
              position: { x: 100, y: 50 },
              data: {
                id: action?.ID,
                isNew: false,
                label: 'Select Action Type',
                type: 'ifConditionNode',
                componentType: action.Type,
                slug: 'ACTION',
                inputJson: '',
                responseJson: action.InputData,
                object: action,
                masterData: '',
                isShowAddOption: me.props.data.isShowAddOption,
                automation_Components: automationComponents,
                automation_Connections: automationConnections,
              }
            }
            elementList.push(dataObjects)
          } else if (true && action.Type === 'Control' && (action.RefID === 'for' || action.RefID === 'for_end')) {
            let dataObjects = {
              id: action.ID,
              type: 'loopNode',
              componentType: action.Type,
              slug: 'ACTION',
              dragHandle: '.custom-drag-handle',
              style: { opacity: 1 },
              position: { x: 100, y: 50 },
              data: {
                id: action?.ID,
                isNew: false,
                label: 'Select Action Type',
                type: 'loopNode',
                componentType: action.Type,
                slug: 'ACTION',
                inputJson: '',
                responseJson: action.InputData,
                object: action,
                masterData: '',
                isShowAddOption: me.props.data.isShowAddOption,
                automation_Components: automationComponents,
                automation_Connections: automationConnections,
              }
            }
            elementList.push(dataObjects)
          }
        }

      })
      console.log(elementList, 'elementList')
    }
    this.setState({
      childrens: elementList,
      controlComponents: childrenDatas,
      callOnceDone: true
    })
  }


  handleActionTypeClick(componentType, forEdgeType) {
    const { data } = this.props;
    const { controlComponents } = this.state;
    if (controlComponents) {
      let componentData = getComponentType(componentType);
      const targetTd = forEdgeType === 'FIRST' ? controlComponents[0].ID : controlComponents[controlComponents.length - 1].ID
      const connectionId = this.getConnectionId(data.id, data?.automation_Connections, targetTd)
      const params = {
        ...componentData,
        connectionID: connectionId
      }
      this.props.addNewAutomationAction(data?.object?.AutomationID, params)
      this.setState({
        isShowTopSubStepMenu: false,
        isShowBottomSubStepMenu: false,
      })
    }

  }

  getLastItemConnectionId(id) {


  }

  addComponent(component_type) {
    const { childrens } = this.state;
    const { data } = this.props;
    let componentData = getComponentType(component_type)
    const connectionId = getTargetComponentId(childrens[childrens.length - 1].id, data?.automation_Connections)
    const params = {
      ...componentData,
      connectionID: connectionId
    }
    this.props.addNewAutomationAction(data?.object?.AutomationID, params)
    this.setState({
      isShowTopSubStepMenu: false,
      isShowBottomSubStepMenu: false,
    })
  }

  render() {
    const { childrens, isShowBottomSubStepMenu, isShowTopSubStepMenu } = this.state;
    const { data } = this.props;
    const isShowAddOption = data.isShowAddOption

    return (
      <>
        <div className={'e_step_header'} onClick={() => {
          this.props.showBuilderConfigDrawer(this.props.data)
        }}>
          <div className={'e_step_icon'}>
            <CallSplitIcon />
          </div>
          <div className={'e_step_title'}>
            <p className={'e_step_title_text'}>
              {this.props.data && this.props.data.object && this.props.data?.object?.RefID === 'else' && <span style={{ border: '1px solid #222', padding: '1px 7px', background: '#d58585', marginRight: 10 }}>ELSE </span>}
              Setup your condition
            </p>
          </div>
        </div>

        <Arrow
          type={'common'}
          isShowArrow={false}
          isShowAddOption={false}
        />

        <div class="recipe-action-step__arrows-container recipe-action-step__arrows-container_if">
          <SubTreeStartArrow
            label={'Yes'}
            type={'condition'}
            isShowAddOption={isShowAddOption}
            isShowTopSubStepMenu={this.state.isShowTopSubStepMenu}
            showSubMenu={() => {
              this.setState({
                isShowTopSubStepMenu: true,
                isShowBottomSubStepMenu: false
              })
            }}
          />

          <Arrow
            type={'else'}
            label={'No'}
            isShowArrow={true}
            isShowAddOption={false}
          />

          <div class="recipe-action-step__block ">

            {isShowTopSubStepMenu && <StepMenu
              showArrowBottom={true}
              onClickHandle={(type) => {
                this.handleActionTypeClick(type, 'FIRST')
              }}
              hideStepMenu={() => {
                this.setState({
                  isShowTopSubStepMenu: false
                })
              }}
            />}


            {childrens && childrens.map((com, index) => {
              return <>
                {com.type === 'actionNode' && <ActionNode
                  key={'if_index' + index}
                  showArrow={childrens.length !== index + 1 ? true : false}
                  {...com}
                />}

                {com.type === "ifConditionNode" && <IfConditionNode
                  key={'if_index' + index}
                  showArrow={childrens.length !== index + 1 ? true : false}
                  automationConnections={this.state.automationConnections}
                  automationComponents={this.state.automationComponents}
                  masterActions={this.props.masterActions}
                  masterTriggers={this.props.masterTriggers}
                  addNewAutomationAction={this.props.addNewAutomationAction}
                  showBuilderConfigDrawer={this.props.showBuilderConfigDrawer}
                  {...com}
                />}

                {com.type === "loopNode" && <LoopNode
                  key={'if_index' + index}
                  showArrow={childrens.length !== index + 1 ? true : false}
                  automationConnections={this.state.automationConnections}
                  automationComponents={this.state.automationComponents}
                  masterActions={this.props.masterActions}
                  masterTriggers={this.props.masterTriggers}
                  addNewAutomationAction={this.props.addNewAutomationAction}
                  showBuilderConfigDrawer={this.props.showBuilderConfigDrawer}
                  {...com}
                />}

              </>

            })}

            {isShowBottomSubStepMenu && <StepMenu
              showArrowTop={true}
              onClickHandle={(type) => {
                this.addComponent(type, 'LAST')
              }}
              hideStepMenu={() => {
                this.setState({ isShowBottomSubStepMenu: false })
              }}
            />}

          </div>

          <SubTreeEndArrow
            isShowAddOption={isShowAddOption}
            isShowBottomSubStepMenu={isShowAddOption && this.state.isShowBottomSubStepMenu}
            showSubMenu={() => {
              this.setState({
                isShowBottomSubStepMenu: true,
                isShowTopSubStepMenu: false,
              })
            }}
          />

        </div>
      </>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(IfConditionNode));