/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React from "react";
import { Button, Grid, CircularProgress, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import DefaultImage from "assets/images/evaluation/default.svg";
// Assets
import WinnerTrophy from "assets/images/winner_trophy.svg";
import CircleProgress from "Components/Common/CircleProgress";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// Components
import Image from "Components/Common/image.jsx";
import { connect } from "react-redux";
import { compose } from "recompose";
import { addApprovalCriteriaApprover, updateApprovalCriteriaSequence } from "redux/evaluation/approval/action";
import { showEvaluationCriteriaAddDialog } from "redux/evaluation/criteria/action";
import ApprovalList from "./ApprovalList";
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { deleteApprovalCriteria,skipApprovalCriteria } from "redux/evaluation/approval/action";
import { getNextComponent } from "util/Common";

const connectedProps = (state) => ({
  user: state.authUser.user,
  evaluation: state.evaluationMetaData.evaluation,
  criterias: state.evaludationCriteria.criterias,
  scores: state.evaludationCriteria.scores,
  productApprovalDetails: state.evaluationApproval.productApprovalDetails,
  approvalCriterias: state.evaluationApproval.approvalCriterias,
  fetchApprovalCriteriasProgress: state.evaluationApproval.fetchApprovalCriteriasProgress,
  updateSequenceApprovalCriteriaProgress: state.evaluationApproval.updateSequenceApprovalCriteriaProgress,
});

const connectionActions = {
  showEvaluationCriteriaAddDialog: showEvaluationCriteriaAddDialog,
  addApprovalCriteriaApprover: addApprovalCriteriaApprover,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  deleteApprovalCriteria: deleteApprovalCriteria,
  skipApprovalCriteria: skipApprovalCriteria,
  updateApprovalCriteriaSequence: updateApprovalCriteriaSequence,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    productDetails: {
      background: "#ffffff",
      padding: "15px",
      borderRadius: 5,
      marginBottom: 35,
    },
    prodHeading: {
      padding: theme.spacing(3, 2, 2, 2),
    },
    winnerTrophy: {
      position: "absolute",
      left: 0,
      top: 0,
    },
    productImageContainer: {
      textAlign: "center",
    },
    productImage: {
      width: "auto",
      height: "70px",
      maxWidth: "50%",
      objectFit: "contain",
    },
    productTitle: {
      textAlign: "center",
      fontWeight: 600,
      color: "#20253A",
      fontSize: 15,
      marginTop: 10,
    },
    divProductScore: {
      position: "absolute",
      right: 0,
      top: "-10px",
    },
    criteriaHeading: {
      fontSize: 16,
      fontWeight: 500,
    },
    addCriteria: {
      textAlign: "center",
      width: "80%",
      margin: "0 auto",
      marginTop: theme.spacing(2),
      "& button": {
        background: "#ffffff",
        "&:hover": {
          background: "#ffffff",
        },
      },
    },
  });

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteId: null,
      skipId: null,
      isShowDialog:false
    };
  }

  getApprovalStatus = (criteria_id,isOtherCriteria) => {
    let status = {
      1: "Preliminary Approval",
      2: "Approved",
      3: "Approved with exceptions",
      4: "Rejected",
    };
    
    const findCriteria =
      this.props.productApprovalDetails && this.props.productApprovalDetails.length ? this.props.productApprovalDetails.find((item) => item.CriteriaID === parseInt(criteria_id)) : null;
    if (findCriteria) {
      let result = findCriteria;
      result.StatusText = status[findCriteria.Status];
      return result;
    } else {
      let result = {};
      result.Status = 0;
      result.StatusText = !isOtherCriteria ? "Awaiting your response" : "Awaiting response";
      return result;
    }
  };

  criteriaAccess = (criteria) => {
    if (criteria && criteria.Status !==0 && criteria.Status !== 5) {
      return true;
    } else {
      return false;
    }
  };

  selectCriteria = (criteria) => {
    if (this.criteriaAccess(criteria)) {
      this.props.setActiveCriteria(criteria);
      this.props.setActiveResponse(null);
    }
  };

  deleteCriteria = (id) => {
    this.setState({deleteId: id,isShowDialog:true}, () => {
        this.props.showMessageDialog();
    })
  }

  skipCriteria = (criteria) => {
    this.setState({skipCriteria: criteria,isShowDialog:true}, () => {
        this.props.showMessageDialog();
    })
  }

  callBack = () => {
      if (this.state.deleteId && this.props.evaluation_id) {
        this.props.hideMessageDialog();
        this.props.deleteApprovalCriteria(this.props.evaluation_id,this.state.deleteId);
        this.setState({deleteId: null,isShowDialog:false})
      } 
      if (this.state.skipCriteria && this.props.evaluation_id) {
        this.props.hideMessageDialog();
    
        const criteria = this.state.skipCriteria;
        let users = [];
        let teams = [];
        if (criteria?.Approvers?.Users || criteria?.Approvers?.Teams) {
          criteria?.Approvers?.Users?.forEach((data) => {
            users.push(data.ID);
          });
          criteria?.Approvers?.Teams?.forEach((data) => {
            teams.push(data.ID);
          });
        }

        let data = {
          stakeHolder: criteria.CriteriaName,
          status: 5,
          approvers: {
            approver_ids: users,
            team_ids: teams,
            productID: Number(this.state.product_id),
          },
          configID: String(criteria?.ID) || "",
          componentId: Number(this.props.component_id),
        };

        this.props.skipApprovalCriteria(this.props.evaluation_id,criteria.ID,data);
        this.setState({skipCriteria: null,isShowDialog:false});
      }
  };

  onCriteriaDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { destination, draggableId, source } = result;
    if (draggableId && destination.index !== source.index) {
      const itemIndex = parseInt(draggableId)
      const destinationIndex = parseInt(destination.index)
      const criteria = this.props.approvalCriterias?.Response[itemIndex]
      const data = {
        stakeHolder: criteria.CriteriaName,
        status: criteria?.Status,
        seq:destinationIndex,
        configID: String(criteria?.ID) || "",
        componentId: Number(this.props.component_id),
      };
      this.props.updateApprovalCriteriaSequence(this.props.evaluation_id,criteria?.ID,data)
    }
  }

  render() {
    const classes = this.props.classes;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to do this action? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    var nextComponent = getNextComponent(this.props.userTimeline, Number(this.props.component_id));
    let isDisable = false;
    if(nextComponent !== null){
      isDisable = true;
    }

    return (
      <div className={classes.productDetails}>
        <div className={classes.prodHeading}>
          <Grid container alignItems={"center"} style={{ position: "relative" }}>
            {this.props.topper && this.props.topper == this.props.product.ID && (
              <div className={classes.winnerTrophy}>
                <Image alt="Winer" src={WinnerTrophy} />
              </div>
            )}
            <div item style={{ flex: 1 }}>
              <div className={classes.productImageContainer}>
                <Image
                  alt=""
                  src={this.props.product.ProductLogo == null || this.props.product.ProductLogo.length == 0 ? DefaultImage : this.props.product.ProductLogo}
                  className={classes.productImage}
                />
              </div>
              <div>
                <Typography className={classes.productTitle}>{this.props.product.Name}</Typography>
              </div>
            </div>
            <div className={classes.divProductScore}>
              {false && <CircleProgress value={this.props.product.score} />}
            </div>
          </Grid>
          {!isDisable && <div className={classes.addCriteria}>
            <Button
              onClick={(e) => {
                this.props.showEvaluationCriteriaAddDialog(this.props.evaluation_id, true, this.props.component_id, this.props.product_id);
              }}
              variant="contained"
              fullWidth
            >
              + New Approval Step
            </Button>
          </div>}
        </div>
        {/* Approval lists */}
        <div className={classes.approvalList}>
          <h3 className={classes.criteriaHeading}>Approvers</h3>
          {this.props.approvalCriterias?.Response?.map((criteria, index) => (      
            <ApprovalList
              key={index}
              criteria={criteria}
              getApprovalStatus={this.getApprovalStatus}
              selectCriteria={this.selectCriteria}
              criteriaAccess={this.criteriaAccess}
              scores={this.props.scores}
              product={this.props.product}
              activeCriteria={this.props.activeCriteria}
              evaluation_id={this.props.evaluation_id}
              component_id={this.props.component_id}
              approvalCriterias={this.props.approvalCriterias}
              deleteCriteria={this.deleteCriteria}
              skipCriteria={this.skipCriteria}
              addRemoveApproverFn={this.props.addRemoveApproverFn}
              addApproverProgress={this.props.addApproverProgress}
              addApprovalCriteriaApprover={this.props.addApprovalCriteriaApprover}
              otherCriteria={!criteria.YourResponse}
            />
          ))}
        </div>
        {this.state.isShowDialog && <MessageDialog messageData={messageData} callBack={this.callBack} />}
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(ProductDetails));