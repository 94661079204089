import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import Home from "./Components/Home/Home";
import Evaluation from "./Components/Evaluation/index";
import { fetchEvaluationCart } from "../../../../redux/evaluation/cart/action";
import EvaluationMetaDataUpdateDialog from "./Components/Common/EvaluationMetaDataUpdateDialog";
import FeatureDialog from './Components/Common/FeatureDialog';
import { updateDrawerStyle } from "../../../../redux/drawer/action";

// views
import VendorEvaluationCards from 'Components/Views/Buyer/VendorEvaluationCards';
import VendorWorkflowCards from 'Components/Views/Buyer/VendorWorkflowCards';
import RequestDetailsCart from "./Components/Common/RequestDetailsCart";

import ProjectSummary from "./Components/Evaluation/WorkFlow/Components/Summary/ProjectSummary";
import RequestSummaryCart from "./Components/Common/RequestSummaryCart";

const connectedProps = (state) => ({
  isRequestSummaryCartOpen: state.evaluationWorkflow.isRequestSummaryCartOpen,
});

const connectionActions = {
  fetchEvaluationCart: fetchEvaluationCart,
  updateDrawerStyle: updateDrawerStyle,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class Evaluate extends React.Component {
  componentDidMount() {
    this.props.fetchEvaluationCart();
    this.props.updateDrawerStyle(true, true);
  }
  render() {
    return (
      <React.Fragment>
        <RequestDetailsCart />
        <Switch>
          <Route exact path={this.props.match.path} component={Home} />
          <Route
            exact
            path={`${this.props.match.path}/in-progress`}
            render={(props) => (
              <VendorEvaluationCards {...props} layout="inProgress" title="In Progress" />
            )} />
          <Route
            exact
            path={`${this.props.match.path}/completed`}
            render={(props) => (
              <VendorEvaluationCards {...props} layout="completed" title="Completed" />
            )} />

          <Route
            exact
            path={`${this.props.match.path}/request/in-progress`}
            render={(props) => (
              <VendorWorkflowCards {...props} layout="inProgress" title="In Progress" />
            )} />
          <Route
            exact
            path={`${this.props.match.path}/request/completed`}
            render={(props) => (
              <VendorWorkflowCards {...props} layout="completed" title="Completed" />
            )} />

          <Route
            path={`${this.props.match.path}/:evaluation_id/project_summary`}
            component={ProjectSummary}
          />

          <Route
            path={`${this.props.match.path}/:evaluation_id`}
            component={Evaluation}
          />


        </Switch>
        <EvaluationMetaDataUpdateDialog />
        <FeatureDialog />
        {this.props.isRequestSummaryCartOpen && <RequestSummaryCart />}
      </React.Fragment>
    );
  }
}

export default connector(compose(withStyles(styles))(Evaluate));
