import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress} from "@material-ui/core";
import AppBarV2 from "Components/Application/AppBarV2";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import ProductTypeFilter from "Components/Common/Filters/ProductTypeFilter";
// redux
import { updateDrawerStyle } from "redux/drawer/action";
import { showSnackBar } from "redux/snackbar/action";
import { matchPath } from "react-router-dom";

import { storeSearchString } from "redux/applications/action";
import { fetchExternalCollaboratorProducts } from "redux/externalCollaborator/action";

const connectedProps = (state) => ({
  orgUser: state.orgUser,
  orgName: state.authUser.user?.Organization?.Name,
  searchString: state.applications.searchString,
  searchType: state.applications.searchType,

  externalProducts: state.externalCollaborator.externalProducts,
  externalProductsFetchProgress: state.externalCollaborator.externalProductsFetchProgress,
  externalProductsFetchError: state.externalCollaborator.externalProductsFetchError,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  showSnackBar: showSnackBar,
  fetchExternalCollaboratorProducts: fetchExternalCollaboratorProducts,
  storeSearchString: storeSearchString,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
      paddingLeft: 20,
    },
    productContainer: {
      maxHeight: "calc(100vh - 250px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 250px)",
      padding: theme.spacing(4, 0),
    },
    productCounts: {
      fontSize: "12px",
      marginLeft: "auto",
      fontWeight: "600",
      color: "#4a87f8",
    },
    productMain: {
      padding: "2rem",
    }
  });

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: queryString.parse(this.props.location.search),
      filters: {
        sortby: "relevance",
        order: "",
      },
      selectedState: [],
      searchObj: {
        field_setname: "all",
        text: "",
        page: 0,
        page_size: 30,
      },
      showActionMenu: null,
      lastLocation: this.props.lastLocation,
      productType: "SOFTWARE",
      searchRestored: false,
    };
  }

  componentDidMount() {
    const urlPrams = new URLSearchParams(this.props.location.search);
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);
    if (urlPrams.get("s") !== null) {
      this.setState({ searchObj: { ...this.state.searchObj, text: urlPrams.get("s") }},
        () => this.fetchData()
      );
    } else {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { lastLocation } = this.state;
    var pathName = lastLocation?.pathname;
    var isMatched = false;
    if (pathName !== undefined) {
      if (
        matchPath(pathName, {
          path: "/app/products/org-product-details/:id",
          exact: true,
          strict: false,
        })
      ) {
        isMatched = true;
      }
    }

    if (isMatched && pathName && this.props.searchString !== "" && this.state.searchRestored === false) {
      this.setState(
        {
          searchObj: { ...this.state.searchObj, field_setname: this.props.searchType, text: prevProps.searchString },
          searchRestored: true,
        },
        () => {
          this.fetchData();
        }
      );
    }
  }

  fetchData() {
    const { searchObj } = this.state;
    let queryParams = {
      query: searchObj.text,
      page_size: searchObj.page_size,
      page: searchObj.page,
      filters: searchObj.filters || {},
    };
    queryParams.filters.states = this.state.selectedState;

    this.props.fetchExternalCollaboratorProducts({ ...queryParams, filters: { ...queryParams.filters, is_org: "1" } });
  }

  handleRedirect = (type) => {
    if (type === "VENDOR") {
      this.props.history.push(`/app/external/vendors`);
    } else if (type === "SOFTWARE") {
      this.props.history.push(`/app/external/products`);
    } else if (type === "SERVICE") {
      this.props.history.push(`/app/external/services`);
    }
  };

  render() {
    const { classes, externalProducts, externalProductsFetchProgress } = this.props;

    return (
      <div className={classes.container}>
        <AppBarV2 title={"Products"} withBack={false} dropdown={true} />
          <div className={classes.productMain}>
            <ProductTypeFilter
              type={this.state.productType}
              hideHardware
              onChange={(type) => {
                this.handleRedirect(type);
              }}
            />
            <Grid
              container
              justifyContent="space-between"
              className={classes.productSectionNew}
            >
              <Grid item>
                <p className={classes.productCount}>{this.props.externalProducts?.length} Products found</p>
              </Grid>
            </Grid>
          
          <div className={classes.productContainer} id="productContainer">
            <InfiniteScroll
              scrollableTarget="productContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                marginRight: 20,
                paddingLeft: 20,
              }}
              dataLength={externalProducts?.length || 0}
              next={() => {
                this.setState({ searchObj: { ...this.state.searchObj, page: this.state.searchObj.page + 1 } }, () => {
                  this.fetchData();
                });
              }}
              hasMore={externalProducts?.total > externalProducts?.length}
            >
              {externalProducts && (
                <Grid container spacing={3} style={{ marginBottom: 10 }}>
                  {externalProducts?.map((product, index) => (
                    <Grid item lg={2} md={3} sm={4} xs={6} key={index}>
                      <LayeredCard
                        title={product.Name || ""}
                        subTitle={product.CompanyName || ""}
                        description={product.Description || ""}
                        cardType={"product"}
                        image={product.ProductLogo || ""}
                        onClick={() => {
                          this.props.history.push("/app/products/org-product-details/" + product.ID);
                        }}
                        numLayer={0}
                        id={parseInt(product.ID)}
                        uniqueId={product.ID + "_" + index}
                        type={"product"}
                        isExisting={true}
                        typeOfBlock="all_product_blocking"
                        typeOfFeatured="featured"
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              {externalProductsFetchProgress && (
                <Grid container justify="center" style={{ marginTop: 30 }}>
                  <CircularProgress />
                </Grid>
              )}
            </InfiniteScroll>
        </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(withStyles(styles), withLastLocation)(Products));
