import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Dialog, DialogContent, Slide, Typography, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import {hideSaveEvaluationAsTemplateDialog,saveFromOrgEvaluationTemplate,updateFromOrgEvaluationTemplate} from "../../../../../../../redux/templates/evaluation/org/action";
import OutlinedInput from "../../../../../../Common/Input/OutlinedInput";
import Alert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router-dom';
import SqureButton from '../../../../../../Common/SqureButton';
import {showSnackBar} from "../../../../../../../redux/snackbar/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen:state.orgTemplateEvaluation.evaluationTemplateSaveDialog,
    saveProgress:state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateProg,
    saveError:state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateError,
    evaluation:state.evaluationMetaData.evaluation,
    criterias:state.evaludationCriteria.criterias
});

const connectionActions = {
    hideDialog:hideSaveEvaluationAsTemplateDialog,
    saveFromOrgEvaluationTemplate:saveFromOrgEvaluationTemplate,
    updateFromOrgEvaluationTemplate:updateFromOrgEvaluationTemplate,
    showSnackBar:showSnackBar
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent:{
        padding: theme.spacing(6, 12) +" !important"
    },
    modalTitle:{
        fontSize:theme.spacing(2.5),
        marginBottom:theme.spacing(4),
        textAlign:"center"
    },
    helptext:{
        fontSize:theme.spacing(1.8)
    },
    alerts:{
        marginTop:theme.spacing(4)
    },
    form:{
        marginTop:theme.spacing(2),
        width:'60%',
    },
    input:{
        marginBottom:theme.spacing(3)
    },
    
    actions:{
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3),
        textAlign:"center",
    },
    loader:{
        textAlign:"center",
        padding:theme.spacing(10,0)
    },
    dialogAction:{
        textAlign:"center"
    },
    addNewButton:{
        fontWeight:600,
        fontSize:theme.spacing(2)
    },
    actionBtn:{
        minWidth:110
    },
   
    nameYour: {
        marginTop: 40,
        "& [class*=MuiInputBase-root]": {
            maxHeight: 40,
            minHeight: 40,
            "& input": {
                fontSize: theme.spacing(1.8)
            }
        },
    },
    criteriaList: {
        maxWidth: 500,
        width: 500,
        marginBottom: 20
    },

    criteriaText: {
        maxWidth: 250,
        display: 'flex',
        alignItems: 'start'
    },
    criteriaItem: {
        paddingTop: 10
    }
});

class SaveTemplateDialog extends React.Component {

    constructor(props){
        super(props);
        this.state  =   {
            error:"",
            success:"",
            'name':'',
            'name_error':'',
            'criterias_error':'',
            selectedCriterias:[]
        }

        this.submitResponse    = this.submitResponse.bind(this);
        this.cleanError    = this.cleanError.bind(this);
        this.cleanState     =   this.cleanState.bind(this);
    }
    
    cleanState(){
        this.setState({
            error:"",
            success:"",
            'name':'',
            'name_error':'',
            'criterias_error':'',
            'addStatus':true
        });
    }

    componentDidUpdate(prevProps){
        if(this.props.isOpen === true && prevProps.isOpen === false){
            
            if(this.props.evaluation.TemplateId !== 0){
                this.setState({
                    addStatus:false
                })
            } else {
                this.setState({
                    addStatus:true
                })
            }
        }
        if(this.props.saveProgress === false && prevProps.saveProgress === true){
            if(this.props.saveError === null){
                //Migrate to different page
                this.props.hideDialog();
                this.cleanState();
                this.props.showSnackBar("Template Saved","success",3000);
            } else {
                if(this.props.saveError.message !== undefined){
                    this.setState({
                        "error":"Failed to save template."
                    })
                } else {
                    this.setState({
                        "error":"Something went wrong."
                    })
                }
            }
        }
    }

    cleanError(){
        this.setState({
            error:"",
            name_error:"",
            criterias_error:"",
            success:"",
        })
    }

    submitResponse(){
        this.cleanError();
        let scope = this;
        var newArray = this.props.criterias.filter(function (el) {
            return  (scope.state.selectedCriterias.indexOf(el.id) > -1)
          });
        var inputCriteria = newArray.map((c)=>{
            return {
                "name":c.name,
                "is_editable":c.is_editable,
                "is_mandatory":c.is_mandatory,
                "requirements":c.requirements.map((r)=>{
                    return {
                        "name": r.name,
                        "description": r.description,
                        "priority": r.priority,
                        "scoring_type": r.scoring_type,
                        "answer_type":r.answer_type,
                        "is_editable":r.is_editable,
                        "is_mandatory":r.is_mandatory,
                        "options":r.options.map((op,i)=>{
                            return {
                                title:op.Title,
                                sequence:(i+1),
                            }
                        })
                    }
                })
            }
        })
        if(this.state.addStatus){
            var name    =   this.state.name;
            // validation
            if(name.trim().length === 0){
                this.setState({
                    name_error:"Please enter Template Name."
                });
            }
            if(scope.state.selectedCriterias.length === 0){
                this.setState({
                    criterias_error:"Please select atleast one criteria."
                });
            }
            if(name.trim().length === 0 || scope.state.selectedCriterias.length === 0){
                return;
            }

            this.props.saveFromOrgEvaluationTemplate({
                "name":name,
                "evaluationId":this.props.evaluation.ID,
                "criterias":inputCriteria
            })
        } else {
            this.props.updateFromOrgEvaluationTemplate(this.props.evaluation.TemplateId,{
                "evaluationId":this.props.evaluation.ID,
                "criterias":inputCriteria
            })
        }
        
    }

    handleChange = (value) => {
        const {selectedCriterias} = this.state;
        let values = [...selectedCriterias]
        if(selectedCriterias.indexOf(value) > -1){
            const index = values.indexOf(value);
            if (index > -1) {
                values.splice(index, 1);
            }
        }else{
            values.push(value)
        }
        this.setState({
            selectedCriterias:values
        })
    };

    render() {
        const classes = this.props.classes;
        return <Dialog 
                    onClose={this.props.hideDialog} 
                    aria-labelledby="app-integrationDialog" 
                    open={this.props.isOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"md"}
                    scroll="body"
                    id="evMetaDialog"
                    >
                        {this.state.addStatus && <DialogContent classes={{root:classes.dialogContent}} >
                            <Typography variant={"h2"} className={classes.modalTitle}>Create Template</Typography>
                            <div className={classes.alerts}>
                                {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                                {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                            </div>
                            <div className={classes.criteriaDetail}>
                           <Grid container>
                                <Grid item lg={12} style={{ margin: '0px auto'}}>
                                    <div className={classes.nameYour}>
                                    <OutlinedInput
                                        label="Name your Template"
                                        error={this.state.name_error.length > 0}
                                        helperText={this.state.name_error}
                                        value={this.state.name}
                                        onChange={(event) => {
                                            this.setState({ name: event.target.value });
                                        }}
                                        style={{width:'480px', margin: '0px auto'}}
                                    /> 
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item style={{ margin: '0px auto'}}>
                                    <div className={classes.criteriaList}>
                                        { this.state.criterias_error && this.state.selectedCriterias.length === 0 && <span style={{ fontSize: '0.75em',color: '#f44336',margin: '14px',marginBottom: '0px',display: 'block'}}>{this.state.criterias_error}</span>}
                                        <Grid container>
                                            {
                                                this.props.criterias.map( (item, i) => {
                                                    return( <Grid item sm={6} className={classes.criteriaText} key={i}><Checkbox
                                                        checked={(this.state.selectedCriterias.indexOf(item.id) > -1) ? true : false}
                                                        onChange={()=>{this.handleChange(item.id)}}
                                                        inputProps={{ 'aria-label': item.name }}
                                                      /><span className={classes.criteriaItem}>{item.name}</span></Grid>)
                                                })
                                            }
                                       </Grid>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>          
                        {!this.props.saveProgress && <div className={classes.actions}>
                            <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                            <SqureButton  cls={classes.actionBtn} variant={"contained"} 
                            onClick={this.submitResponse}>Save</SqureButton>
                        </div>}
                        {this.props.saveProgress && <div className={classes.actions}>
                            <CircularProgress />
                        </div>}
                    </DialogContent>}
                    {!this.state.addStatus && <DialogContent classes={{root:classes.dialogContent}} >
                            <Typography variant={"h2"} className={classes.modalTitle}>Update Exiting Template?</Typography>
                            <div className={classes.alerts}>
                                {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                                {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                            </div>
                        {!this.props.saveProgress && <div className={classes.actions}>
                            <SqureButton  cls={classes.actionBtn} variant={"contained"} 
                            onClick={this.submitResponse}>Yes</SqureButton>
                            &nbsp;&nbsp;&nbsp;
                            <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={()=>{
                                this.setState({
                                    addStatus:true
                                })
                            }}>Create New</SqureButton>&nbsp;&nbsp;&nbsp;
                            <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={()=>{
                                this.props.hideDialog()
                            }}>Cancel</SqureButton>
                            
                        </div>}
                        {this.props.saveProgress && <div className={classes.actions}>
                            <CircularProgress />
                        </div>}
                    </DialogContent>}
                </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(SaveTemplateDialog));