import React, { Component, Children } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import ToolTip from "Components/Common/ToolTip.jsx";

export default class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
        value: 0
    };
  }

  componentDidMount() {
      // console.log('componentDidMount')
    const { children } = this.props;
    const tabs = Children.toArray(children);
    this.setState({ value: tabs[0].props.value });
    // console.log('tabs',tabs)
  }

  isSelected(tab) {
    return tab.props.value === this.state.value;
  }

  selectTab(e, value) {
    this.setState({ value });
    this.props.onChange(e, value);
  }

  getHeader(tabs) {
    return tabs.map((tab, i) => {
      const style = this.isSelected(tab)
        ? activeTabHeaderStyle
        : tabHeaderStyle;

      return (
        <span
          key={tab.props.value} 
          onClick={e => {this.selectTab(e, tab.props.value); this.props.emitTabEvent(e, tab.props.value)}}
          style={i === 0 ? style : Object.assign({}, style)}
        >
          {tab.props.isDot &&  <span style={ tab.props.toolTip ? communityDot : communityDotWithTooltip }></span>} {tab.props.header} {tab.props.toolTip && <ToolTip toolTip={tab.props.toolTip}/>}
        </span>
      );
    });
  }

  render() {
    const { children } = this.props;
    const tabs = Children.toArray(children);

    return (
      <div style={tabsStyle}>
        <div style={tabsHeaderStyle}>{this.getHeader(tabs)}</div>
        <div style={this.props.tabsContentStyle}>
          {_.find(tabs, tab => this.isSelected(tab))}
        </div>
      </div>
    );
  }
}

Tabs.defaultProps = {
  onChange: _.noop,
  tabsContentStyle: {
    padding: "10px",
    borderTop: "none",
    borderRadius: "0 0 8px 8px"
  }  
};

Tabs.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func
};

// Style
const borderStyle = "1px solid #eee";
const tabsStyle = {
//   boxShadow: "0 10px 40px -20px rgba(0,0,0,0.35)",
//   border: borderStyle,
//   borderRadius: "8px"
    width: '100%'
};

const tabsHeaderStyle = {
  display: "flex",
  // justifyContent: "space-between",
//   backgroundColor: "#f7f7f7",
//   borderRadius: "8px 8px 0 0",
  overflow: "hidden"
};

const tabHeaderStyle = {
    padding: "16px 12px 12px 12px",
    textAlign: "center",
    cursor: "pointer",
    borderBottom: borderStyle,
    fontFamily: 'Poppins',
    letterSpacing: '0px',
    color: '#9B9EAC',
    opacity: 1,
    fontSize: '13px'
};

const activeTabHeaderStyle = Object.assign({}, tabHeaderStyle, {
  color: '#04363D',
  cursor: "auto",
  borderBottom: '2px solid #398AF5'
});

const communityDot = {
  width: 5,
  height: 5,
  background: 'red',
  position: 'absolute',
  top: 14,
  left: 144,
  borderRadius: '50%'
}
const communityDotWithTooltip = {
  width: 5,
  height: 5,
  background: 'red',
  position: 'absolute',
  top: 14,
  left: 128,
  borderRadius: '50%'
}

const sideHeaderStyle = { 
  flex: 1
 };
