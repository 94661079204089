import React, { useEffect, useState } from 'react';
import { format } from 'date-fns'
import { uniq } from 'underscore';
import moment from 'moment';
import { connect } from "react-redux";
import classnames from "classnames";
import { InvoiceParser } from 'services/invoiseParser';
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createStyles, withStyles } from '@material-ui/core/styles';
import axios from "axios";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import {
    Radio, Dialog, Chip, CircularProgress,
    DialogTitle, DialogContent, DialogActions,
    Slide, Grid, Checkbox, TextField, OutlinedInput,
    Table, TableBody, TableCell, TableHead, TableRow, Divider, FormControl, InputAdornment
} from '@material-ui/core';
import CommonCss from '../../commonCss';
import DocViewer from '../Common/DocViewer'
import SqureButton from 'Components/Common/SqureButton';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import CalendarImage from "assets/images/calendar.svg";

import Select from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";

import {
    addOrgDocument,
    hideAddOrgProductContractDetailsDialog,
    showAddOrgProductContractDetailsDialog,
    fetchParseContractDetails,
    addContractDetails, updateOrgDocument, updateFinancialDetailsData
} from "redux/product/orgProduct/action.js";
import { showSnackBar } from "redux/snackbar/action";

import { currencyOptions, currencyCodeMap } from "util/Currency";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import FoldersTreeSelect from "../Common/FoldersTreeSelect";

const SelectAsyncPaginate = withAsyncPaginate(Select);

var currencyStyles = {
    control: styles => ({ ...styles, height: 56 }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    option: (provided, state) => ({
        ...provided,
    }),
    singleValue: (provided, state) => ({
        ...provided,
    })
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpenOrgProductContractDetailsDialog: state.orgProduct.isOpenOrgProductContractDetailsDialog,
    orgProductContractDetailsDialog: state.orgProduct.orgProductContractDetailsDialog,
    addOrgDocumentProgress: state.orgProduct.addOrgDocumentProgress,
    addOrgDocumentSuccess: state.orgProduct.addOrgDocumentSuccess,
    fetchParseContractDetailsProg: state.orgProduct.fetchParseContractDetailsProg,
    parseContractDetails: state.orgProduct.parseContractDetails,
    parseContractDetailsError: state.orgProduct.parseContractDetailsError,
    addOrgDocumentError: state.orgProduct.addOrgDocumentError,
    addContractDetailsProg: state.orgProduct.addContractDetailsProg,
    addContractDetailsError: state.orgProduct.addContractDetailsError,
    documentTypes: state.documentType.types,
    updateContractDetailsProg: state.orgProduct.updateContractDetailsProg,
    updateContractDetailsError: state.orgProduct.updateContractDetailsError,
    contractFoldersData: state.orgProduct.contractFoldersData,
});

const connectionActions = {
    hideDialog: hideAddOrgProductContractDetailsDialog,
    showDialog: showAddOrgProductContractDetailsDialog,
    fetchParseContractDetails: fetchParseContractDetails,
    addContractDetails: addContractDetails,
    showSnackBar: showSnackBar,
    addOrgDocument: addOrgDocument,
    updateOrgDocument: updateOrgDocument,
    updateFinancialDetailsData: updateFinancialDetailsData
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogLG: {
        '& [class*=MuiDialog-paperFullWidth]': {
            maxWidth: '100% !important',
            width: '100% !important',
            margin: '0px !important',
            minHeight: '100vh',
        }
    },
    formControl: {
        ...CommonCss.formControl,
        '& > div': {
            backgroundColor: 'tarsparent !important',
            border: 'none',
            paddingRight: 0,
        },
        padding: '5px !important',
        minHeight: 'auto !important',
        '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            padding: '0px !important',
            fontSize: '11px !important'
        },
        '& [class*="MuiInputBase-input"]': {
            padding: '0px !important',
            fontSize: '11px !important'
        }
    },
    currencyFormControl: {
        ...CommonCss.formControl,
        display: 'flex',
        padding: '0px !important',
        minHeight: 'auto !important',
        '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            padding: '0px !important',
            fontSize: '11px !important'
        },
        "& [class*=MuiSelect-select]": {
            paddingRight: 0,
            '&:focus': {
                backgroundColor: 'transparent'
            }
        },
        '& [class*="MuiInputBase-root"]': {
            padding: '0px 5px !important',
            fontSize: '11px !important'
        }
    },
    formGroup: {
        marginTop: 5,
        marginBottom: 16
    },
    dialogContent: {
        minHeight: 170,
        overflowY: 'hidden',
        "& [class*=select__menu]": {
            maxHeight: 100
        }
    },
    optionUl: {
        listStyle: "none",
        paddingLeft: 0,
        "& li": {
            marginBottom: "8px",
            "& span": {
                fontSize: 13,
                color: "#6D7278",
            },
        },
    },
    formInput: {
        width: '100%',
        '& > div': {
            backgroundColor: 'transparent !important',
            border: 'none',
            paddingRight: '0px !important',
        },
        '& > div:focus': {
            backgroundColor: 'transparent !important',
            border: 'none',
            paddingRight: '0px !important',
        }
    },
    pdrl: {
        padding: 15,
    },
    formContainer: {
        margin: '0 auto',
    },
    label: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1.6,
        color: '#000000de',
        marginBottom: 10,
    },
    datePicker: {
        "& .react-datepicker-wrapper": {
            width: '100%',
        },
        "& .react-datepicker__input-container > div": {
            display: 'flex',
            marginBottom: 0,
            alignItems: 'center',
            "& [class*=MuiInputBase-root]": {
                maxHeight: 33,
                minHeight: 33,
                width: 'inherit',
            },
            "& [class*=MuiFormLabel-root]": {
                marginBottom: 0,
                marginRight: 29
            }
        },
        "[class*=MuiMenuItem-root]": {
            color: "#707070",
            fontSize: '11px !important'
        },
    },
    deleteImage: {
        zIndex: 20,
        padding: 4,
        cursor: 'pointer'
    },
    dateAttributes: {
        "& [class*=addDate]": {
            visibility: 'hidden',
        },
        "&:hover [class*=addDate]": {
            visibility: 'visible',
        }
    },
    formSelect: {
        width: '100%'
    },
    bodyitm: {
        marginBottom: 10
    }
});

class DialogOrgProductContractDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            description: '',
            option: false,
            isLoading: false,
            isParse: false,
            isLoadingParseStep: false,
            fileTypes: [
                { label: 'Contract', value: 'contract' },
                { label: 'Invoice', value: 'invoice' },
                { label: 'Form', value: 'form' },
                { label: `I don't know`, value: `I don't know` }
            ],
            data: [],
            type: '',
            user: '',
            parentfolder: null
        }
    }

    componentDidUpdate(prevProps) {
        const {
            addOrgDocumentProgress, fetchParseContractDetailsProg,
            addOrgDocumentError, addContractDetailsProg, addContractDetailsError, parseContractDetailsError, updateContractDetailsProg, updateContractDetailsError
        } = this.props;

        if (!updateContractDetailsProg && prevProps.updateContractDetailsProg) {
            if (updateContractDetailsError === null) {
                this.props.hideDialog();
            }
        }

        const { step } = this.props.orgProductContractDetailsDialog
        if (step === 1 && !addOrgDocumentProgress && prevProps.addOrgDocumentProgress && addOrgDocumentError === null) {
            this.props.showSnackBar("Document added successfully", "success", 3000);
            if (this.state.isParse) {
                this.setState({
                    isLoadingParseStep: true
                }, () => {
                    this.props.showDialog({
                        ...this.props.orgProductContractDetailsDialog,
                        head: "",
                        step: 2,
                        nextBtnText: 'Submit',
                        cancelBtnText: 'Cancel',
                    })
                    setTimeout(() => {
                        this.submitResponse()
                    }, 2000);
                })
            } else {
                this.clear()
                this.props.hideDialog()
            }
        }
        if (step === 1 && !addOrgDocumentProgress && prevProps.addOrgDocumentProgress && addOrgDocumentError !== null) {
            let msg = 'Something went wrong.'
            if (addOrgDocumentError.message !== undefined) {
                msg = "Failed to upload document."
            }
            this.props.showSnackBar(msg, "error", 3000);
            this.clear()
            this.props.hideDialog()
        }

        if (step === 2 && !fetchParseContractDetailsProg && prevProps.fetchParseContractDetailsProg) {

            if (parseContractDetailsError === null) {
                this.setState({
                    isLoadingParseStep: false
                }, () => {
                    this.props.showDialog({
                        ...this.props.orgProductContractDetailsDialog,
                        step: 3,
                        head: '',
                        nextBtnText: 'Save',
                        cancelBtnText: 'Cancel',
                    });
                })

            } else {
                let msg = 'Something went wrong. Please try again!'
                if (parseContractDetailsError?.message) {
                    msg = "Unable to parse this file. Please upload a docx or pdf version of the document."
                }
                this.props.showSnackBar(msg, "error", 3000);
                this.clear()
                this.props.hideDialog()
            }
        }
        if (step === 3 && !addContractDetailsProg && prevProps.addContractDetailsProg) {
            if (addContractDetailsError !== null) {
                let msg = 'Something went wrong.'
                if (addOrgDocumentError?.message !== undefined) {
                    msg = "Failed to add details"
                }
                this.props.showSnackBar(msg, "error", 3000);
            } else {
                this.props.showSnackBar("Details added successfully", "success", 3000);
                this.clear()
                this.props.hideDialog()
            }

        }
    }

    clear = () => {
        this.setState({
            description: '',
            option: '',
            isLoading: false,
            isParse: false,
        })
    }

    getOptions = async (inputValue, loadedOptions, additional) => {
        var page = 0;
        if (additional !== undefined && additional.page !== undefined) {
            page = additional.page;
        }
        const api_server = Environment.api_host("USER");
        const timestamp = new Date().getTime();
        if (!inputValue) {
            return {
                options: [],
                hasMore: false,
                additional: {
                    page: 0,
                },
            };
        }

        let formData = {
            page: 0,
            pageSize: 20,
            query: inputValue
        };
        const url = `${api_server}/fetch?t=${timestamp}`;
        const response = await axios.post(url, formData, {
            headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
        });
        console.log(response)

        let apps = response?.data?.Data || [];
        let total = response?.data?.Data?.total || 0;
        apps = apps.map((m) => ({
            label: m.Name,
            value: m.ID,
        }));
        return {
            options: apps,
            hasMore: (page + 1) * 10 < total ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };


    submitResponse = (finalData) => {
        const { step, filePath, title, productName } = this.props.orgProductContractDetailsDialog;
        const { type, user, parentfolder } = this.state;
        if (step === 1) {
            if (type === undefined || type === null || type === '') {
                this.props.showSnackBar('Please select type.', 'error', 3000);
                return;
            }

            if (title && filePath) {
                let params = {
                    description: this.state.description,
                    title: title,
                    url: filePath,
                    folder: parentfolder?.id,
                    documentTypeId: type.value
                }

                if (user !== undefined && user !== null && user !== '') {
                    params['ownerId'] = user.value
                }

                this.props.addOrgDocument(
                    this.props.match?.params?.id,
                    {
                        documents: [params]
                    }
                );
            }
        }
        if (step === 2) {
            const data = {
                product_id: this.props.match?.params?.id,
                product_name: productName,
                contract_url: filePath,
                file_type: this.state.type.file_type
            }
            this.props.fetchParseContractDetails(data)
        }
        if (step === 3) {
            const data = { ...finalData }
            console.log(data, 'Data')
            // this.props.addContractDetails(this.props.match?.params?.id,this.props.addOrgDocumentSuccess.ID, data)
            this.props.updateFinancialDetailsData(this.props.match?.params?.id, this.props.addOrgDocumentSuccess[0].ID, data)
        }
    }

    render() {
        const classes = this.props.classes;
        const { description, option, isParse, fileTypes, user, parentfolder, type } = this.state;
        const { fetchParseContractDetailsProg, addOrgDocumentProgress, parseContractDetails, imgSrc, documentTypes } = this.props;
        const typeOptions = documentTypes.map(o => {
            return { label: o.name, value: o.id, file_type: o.type }
        })

        const {
            step, title, head, cancelBtnText, nextBtnText, filePath
        } = this.props.orgProductContractDetailsDialog;
        let fileInput = ''
        if (title) {
            fileInput = title.split('-')
            fileInput.splice(0, 1)
            fileInput = fileInput.join("-")
        }
        let stepDialogProps = {}
        let isPreviewAvailable = false
        let isImage = false
        let isPdf = true
        let source = filePath
        if (step === 3) {
            stepDialogProps = { maxWidth: 'md' }
            const fileData = filePath.split('.')
            if (fileData && fileData.length > 0) {
                const fileType = fileData.pop()
                isImage = ['jpeg', 'jpg', 'png'].includes(fileType.toLowerCase())
                isPdf = ['pdf'].includes(fileType.toLowerCase())
                isPreviewAvailable = true
                if (isPdf || isImage) {
                    source = imgSrc
                }
            }
        }
        return (
            <Dialog
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        this.clear()
                        this.props.hideDialog()
                    }
                }}
                aria-labelledby="new-champions-dialog"
                open={this.props.isOpenOrgProductContractDetailsDialog}
                TransitionComponent={Transition}
                scroll={"body"}
                fullWidth={true}
                {...stepDialogProps}
                className={classnames(isPreviewAvailable ? classes.dialogLG : classes.dialog)}
                id="productChampDialog"
            >
                <DialogTitle id="form-dialog-title">{head || ''}</DialogTitle>
                {this.state.isLoadingParseStep && <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </div>}
                {parseContractDetails !== null && step === 3 && (
                    <Grid container>
                        {isPreviewAvailable && (
                            <Grid item xs={5} style={{ overflowY: 'scroll', height: '90vh' }}>
                                <DocViewer isImage={isImage} isPdf={isPdf} source={source} />
                            </Grid>
                        )}
                        <Grid item xs={isPreviewAvailable ? 7 : 12} style={{ overflowY: 'scroll', height: '90vh' }}>
                            <ContractParsedField
                                classes={classes}
                                filePath={this.props.orgProductContractDetailsDialog.filePath}
                                parseContractDetails={parseContractDetails}
                                clear={this.clear}
                                hideDialog={this.props.hideDialog}
                                isSubmitting={this.props.addContractDetailsProg}
                                onSubmit={data => this.submitResponse(data)}
                            />
                        </Grid>
                    </Grid>
                )}
                {step !== 3 && (
                    <>
                        <DialogContent classes={{ root: classes.dialogContent }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    {step === 1 && (
                                        <>
                                            <div className={classes.formGroup}>
                                                <input
                                                    className={classes.formControl}
                                                    value={fileInput}
                                                    disabled
                                                />
                                            </div>
                                            <div className={classes.formGroup}>
                                                <input
                                                    className={classes.formControl}
                                                    value={description}
                                                    placeholder="Description"
                                                    onChange={(e) => this.setState({
                                                        description: e.target.value
                                                    })}
                                                />
                                            </div>
                                            <div className={classes.bodyitm}>
                                                <FormControl className={classes.formSelect} style={{ zIndex: 11 }}>
                                                    <Select
                                                        isClearable
                                                        isMulti={false}
                                                        labelId="singleSelect-select-label"
                                                        id="singleSelect-select"
                                                        isDisabled={false}
                                                        value={type}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                type: e
                                                            })
                                                        }}
                                                        fullWidth
                                                        options={typeOptions}
                                                        menuPortalTarget={document.body}
                                                        // menuPosition={'fixed'}
                                                        menuPlacement={'auto'}
                                                        placeholder={"Select Document Type"}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={classes.bodyitm}>
                                                <FormControl className={classes.formSelect} style={{ zIndex: 11 }}>
                                                    <SelectAsyncPaginate
                                                        isClearable={true}
                                                        isMulti={false}
                                                        isSearchable={true}
                                                        value={user}
                                                        menuPosition='fixed'
                                                        classNamePrefix="select"
                                                        isLoading={this.props.userSearchProgress}
                                                        placeholder="Search User"
                                                        additional={{
                                                            page: 0,
                                                        }}
                                                        loadOptions={this.getOptions}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                user: e
                                                            })
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>
                                            {this.props.contractFoldersData?.meta?.length > 0 && <div className={classes.bodyitm}>
                                                <FoldersTreeSelect handleChange={(value)=>this.setState({parentfolder: value})} value={this.state.parentfolder}/>
                                            </div>}
                                            <Grid container alignItems="center">
                                                <Checkbox
                                                    id="parse"
                                                    checked={isParse}
                                                    name="parse"
                                                    color="primary"
                                                    onChange={e => {
                                                        this.setState({
                                                            isParse: e.target.checked
                                                        })
                                                    }}
                                                />
                                                &nbsp;
                                                <label htmlFor="parse">
                                                    Parse this file
                                                </label>
                                            </Grid>
                                        </>
                                    )}
                                    {false && step === 2 && (
                                        <ul className={classes.optionUl}>
                                            {fileTypes.map((o, index) => (
                                                <li key={`ui-li-${index}`}>
                                                    <Grid container alignItems="center">
                                                        <Radio
                                                            id={o.value}
                                                            checked={(option && option?.label) === o.label}
                                                            inputProps={{
                                                                name: "RadioData",
                                                                "data-sequence": "data",
                                                            }}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    option: o
                                                                })
                                                            }}
                                                        />
                                                        <label htmlFor={o.value}>
                                                            {o.label}
                                                        </label>
                                                    </Grid>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <div style={{ padding: '10px 24px' }}>
                                <SqureButton
                                    cls={classes.actionBtn} variant={"outlined"}
                                    onClick={() => {
                                        this.clear()
                                        this.props.hideDialog()
                                    }}
                                    disabled={fetchParseContractDetailsProg || addOrgDocumentProgress || this.state.isLoadingParseStep}
                                >
                                    {cancelBtnText || 'Cancel'}
                                </SqureButton>
                                &nbsp;&nbsp;&nbsp;
                                <SqureButton
                                    cls={classes.actionBtn} variant={"contained"}
                                    disabled={fetchParseContractDetailsProg || addOrgDocumentProgress || this.state.isLoadingParseStep}
                                    onClick={() => {
                                        this.submitResponse()
                                    }}
                                >
                                    {addOrgDocumentProgress
                                        ? 'Uploading...'
                                        : fetchParseContractDetailsProg || this.state.isLoadingParseStep
                                            ? 'Parsing Data...'
                                            : step === 1 && !isParse ? 'Upload' : nextBtnText || 'Save'}
                                </SqureButton>
                            </div>
                        </DialogActions>

                    </>
                )}
            </Dialog >
        )
    }
}


export default connector(compose(withRouter, withStyles(styles))(DialogOrgProductContractDetails));


class ContractParsedField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            otherDates: [],
            dataSelectedDate: [],
            attributes: [],
            lineItems: [],
            lineItemHeader: [],
            colCurrency: {},
        };
        this.handleSelecteDates = this.handleSelecteDates.bind(this)
        this.submitResponse = this.submitResponse.bind(this)
        this.handleChangeDataValue = this.handleChangeDataValue.bind(this)
        this.handleChangeLincensesValue = this.handleChangeLincensesValue.bind(this)
        this.handleDeleteLicenseRow = this.handleDeleteLicenseRow.bind(this)
    }

    componentDidMount() {
        const { parseContractDetails } = this.props
        if (parseContractDetails) {
            const attributes = {}
            let dataSelectedDate = []
            Object.keys(parseContractDetails).filter(key => !['items', 'status', 'other_dates', 'licenses', 'currency'].includes(key))
                .forEach(key => {
                    const data = parseContractDetails[key]
                    data.value = InvoiceParser.getData(data.type, data.value)
                    if (data.type === 'price') {
                        let value = parseContractDetails?.currency?.value && parseContractDetails?.currency?.value !== "NaN" && parseContractDetails?.currency?.value !== undefined ? parseContractDetails?.currency?.value : "USD0";
                        let currency = value.substring(0, 3);
                        if (currency.length < 3) {
                            currency = { label: 'USD', value: 'USD' }
                        } else {
                            currency = { label: currency, value: currency }
                        }
                        data.currency = currency
                    }
                    if (data.type === 'date' && data.value) {
                        data.value = data.value.replace(/-/, '/').replace(/-/, '/')
                        dataSelectedDate.push(data.value)
                    }
                    attributes[key] = data;
                })
            let items = parseContractDetails?.licenses?.value
            let lineItems = []
            let lineItemHeader = []
            let colCurrency = {}
            if (items && items.length > 0) {
                lineItemHeader = uniq(items.map(item => Object.keys(item).join(',')).join(',').split(","))
                lineItems = items.map(item => {
                    const row = {}
                    lineItemHeader.forEach(key => {
                        const column = item[key]
                        column.value = InvoiceParser.getData(column.type, column.value)
                        if (column.type === 'price') {
                            column.currency = parseContractDetails.currency.value || 'USD'
                            colCurrency[key] = column.currency
                        }
                        column.value = InvoiceParser.getData(column.type, column.value)
                        if (column.type === 'date' && column.value) {
                            column.value = column.value.replace(/-/, '/').replace(/-/, '/')
                        }
                        row[key] = column;
                    })
                    return row
                })
            }
            let otherDates = []
            dataSelectedDate = uniq(dataSelectedDate)
            otherDates = Array.isArray(parseContractDetails?.other_dates?.value) && parseContractDetails.other_dates.value.length > 0 ? parseContractDetails.other_dates.value : []
            otherDates = uniq([...dataSelectedDate, ...otherDates])
            otherDates = otherDates.filter(o => o !== "" && o !== null && o !== undefined)
            if (otherDates.length) {
                otherDates = otherDates.map(dt => dt.replace(/-/, '/').replace(/-/, '/'))
                otherDates = otherDates.filter(function (item, pos, self) {
                    return self.indexOf(item) === pos;
                })
            }
            this.setState({ colCurrency, attributes, allLineItems: lineItems, lineItems, lineItemHeader, otherDates, dataSelectedDate })
        }
    }

    addLicense(oldLineItems) {
        const data = {}
        const item = oldLineItems[0]
        Object.keys(item).forEach(key => {
            data[key] = { ...item[key] }
            if (data[key].type === 'price' || data[key].type === "number") {
                data[key].value = 0
            } else {
                data[key].value = ""
            }
        });
        this.setState({ lineItems: [...oldLineItems, data] })
    }

    handleChangeDataValue = (key, value, isCurrency) => {
        const { attributes } = this.state
        if (isCurrency) {
            attributes[key]['currency'] = value
        } else {
            attributes[key]['value'] = value
        }
        this.setState({ attributes })
    }

    handleSelecteDates = (action, value, selected) => {
        const { dataSelectedDate } = this.state
        if (action) {
            if (selected) {
                const index = dataSelectedDate.indexOf(selected);
                if (index > -1) {
                    dataSelectedDate.splice(index, 1);
                }
            }
            dataSelectedDate.push(value)
        } else {
            const index = dataSelectedDate.indexOf(value);
            if (index > -1) {
                dataSelectedDate.splice(index, 1);
            }
        }
        this.setState({ dataSelectedDate })
    }

    handleChangeLincensesValue = (index, key, value) => {
        const { lineItems } = this.state
        lineItems[index][key].value = value
        this.setState({ lineItems })
    }

    handleChangeLincensesDateValue = (index, key, value) => {
        const { lineItems } = this.state
        lineItems[index][key].value = moment(value).format("MMMM-DD-YY")
        this.setState({ lineItems })
    }

    handleDeleteLicenseRow = (index) => {
        const { lineItems } = this.state
        lineItems.splice(index, 1)
        this.setState({ lineItems })
    }

    submitResponse = () => {
        let { lineItems, attributes, colCurrency } = this.state
        attributes = Object.keys(attributes).map(key => {
            const data = { ...attributes[key], attribute: key }
            if (data.type === 'price') {
                data.value = `${data.currency.value}${data.value}`
                delete data.currency
            }
            data.value = String(data.value)
            return data
        })
        lineItems = lineItems.map(item => (
            Object.keys(item).map(key => {
                const data = { ...item[key], attribute: key }
                if (data.type === 'price') {
                    data.value = `${colCurrency[key] || 'USD'}${data.value}`
                    delete data.currency
                }
                data.value = String(data.value)
                return data
            })
        ))
        this.props.onSubmit({ attributes, lineItemAttributes: lineItems })
    }

    handleTheadCurrency = (type, value) => {
        this.setState({ colCurrency: { ...this.state.colCurrency, [type]: value } })
    }

    render() {
        const { classes, isSubmitting, clear, hideDialog } = this.props;
        const { attributes, lineItems, lineItemHeader, otherDates, dataSelectedDate } = this.state;

        console.log(attributes,'attributes')
        console.log(lineItems,'lineItems')
        console.log(lineItemHeader,'lineItemHeader')
        return (
            <>
                <DialogContent classes={{ root: classnames(classes.dialogContent, classes.pdrl) }}>
                    <div className={classes.formContainer}>
                        <Grid container spacing={2}>
                            {attributes && Object.keys(attributes).map((key, index) => {
                                const obj = attributes[key]
                                return (
                                    <Grid item xs={12} key={`DialogContent-${index}`} >
                                        <Grid container alignItems="center" className={classes.label}>
                                            <div><label>{obj.label}</label></div>
                                        </Grid>
                                        {['number', 'price', 'text','array'].includes(obj.type) && (
                                            <Grid item>
                                                <FormInputField
                                                    classes={classes}
                                                    obj={obj}
                                                    isCurrency={obj.type.includes('price')}
                                                    onChange={value => this.handleChangeDataValue(key, value)}
                                                    onChangeCurrency={value => this.handleChangeDataValue(key, value, true)}
                                                />
                                            </Grid>
                                        )}
                                        {['date'].includes(obj.type) && (
                                            <Grid container alignItems="center" className={classes.dateAttributes}>
                                                {obj.value && (
                                                    <Grid item>
                                                        <Chip
                                                            label={format(new Date(obj.value), "MMM do yyyy")}
                                                            style={{ margin: '0px 5px 5px 0px' }}
                                                            key={`chip-${index}`}
                                                            color="primary"
                                                            onDelete={() => {
                                                                this.handleSelecteDates(false, obj.value, false)
                                                                this.handleChangeDataValue(key, "")
                                                            }}
                                                        />
                                                    </Grid>
                                                )}
                                                {otherDates
                                                    .filter(o => !["", undefined, obj, ...dataSelectedDate].includes(o))
                                                    .map((o, index) => (
                                                        <Grid item key={`other-date-chip-${index}`}>
                                                            <Chip
                                                                key={`chip-otherDates-${index}`}
                                                                label={format(new Date(o), "MMM do yyyy")}
                                                                style={{ margin: '0px 5px 5px 0px' }}
                                                                clickable
                                                                color="primary"
                                                                variant={obj.value === o ? "default" : "outlined"}
                                                                onClick={() => {
                                                                    this.handleSelecteDates(true, o, obj.value)
                                                                    this.handleChangeDataValue(key, o)
                                                                }}
                                                            />
                                                        </Grid>
                                                    ))
                                                }
                                                <Grid item className={classes.addDate}>
                                                    <div className={classes.datePicker}>
                                                        <DatePicker
                                                            calendarIcon={<p>Date</p>}
                                                            showPopperArrow={false}
                                                            selected={obj.value ? new Date(obj.value) : null}
                                                            onChange={date => {
                                                                this.handleSelecteDates(false, obj.value, false)
                                                                this.handleChangeDataValue(key, date)
                                                            }}
                                                            dateFormat="MMM do yyyy"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            customInput={
                                                                <Chip
                                                                    variant="outline"
                                                                    label="Add Date"
                                                                    style={{ margin: '0px 5px 5px 0px' }}
                                                                    clickable
                                                                    key={`chip-${index}`}
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <br />
                        <Divider />
                        <br />
                        {lineItems && lineItems.length > 0 && (
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {lineItemHeader.map((th, index) => {
                                              let width = "20%";
                                              let textAlign = 'flex-start';
                                              if ([1, 2].includes(index)) {
                                                width = "12%";
                                              } else if ([3].includes(index)) {
                                                width = "6%";
                                              } else if ([4, 5].includes(index)) {
                                                width = "25%";
                                                textAlign = 'flex-end'
                                              }
                                            return (
                                                <TableCell key={`tablehead-tableCell-${index}`} style={{ padding: 5, width, fontSize: 14 }}>
                                                    <Grid container alignItems="center" justifyContent={textAlign}>
                                                        <div>{th.toUpperCase().split('_').join(" ")}</div>
                                                        {th.includes('price') && (
                                                            <>&nbsp;
                                                                <FormInputFieldLineItem
                                                                    classes={classes}
                                                                    obj={{ type: 'price', currency: this.state.colCurrency[th] || 'USD' }}
                                                                    isCurrency={true}
                                                                    isOnlyCurrency={true}
                                                                    onChangeCurrency={(value) => {
                                                                        this.handleTheadCurrency(th, value)
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </Grid>
                                                </TableCell>

                                            )
                                        })}
                                        <TableCell style={{ padding: 5 }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {lineItems.map((item, il) => (
                                        <TableRow key={`tableRow-${il}`}>
                                            {item && Object.keys(item).map((k, i) => {
                                                const obj = item[k]
                                                let width = "20%";
                                                let textAlign = 'left';
                                                if ([1, 2].includes(i)) {
                                                    width = "12%";
                                                } else if ([3].includes(i)) {
                                                    width = "6%";
                                                } else if ([4, 5].includes(i)) {
                                                    width = "25%";
                                                    textAlign = 'right'
                                                }
                                                return (
                                                    <TableCell style={{ padding: 5, width,textAlign }} key={`tableRow-tablecell-${i}`}>
                                                        {['number', 'price', 'text'].includes(obj.type) && (
                                                            <FormInputFieldLineItem
                                                                classes={classes}
                                                                obj={obj}
                                                                onChange={(value) => this.handleChangeLincensesValue(il, k, value)}
                                                            />
                                                        )}
                                                        {['date'].includes(obj.type) && (
                                                            <div className={classes.datePicker}>
                                                                <DatePicker
                                                                    key={`${obj.type}-date-${i}-${il}`}
                                                                    selected={obj.value ? new Date(obj.value) : null}
                                                                    onChange={date => {
                                                                        this.handleChangeLincensesValue(il, k, date, true)
                                                                    }}
                                                                    dateFormat="MMM do yyyy"
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    className={classes.datePicker}
                                                                    customInput={
                                                                        <OutlinedInput
                                                                            readOnlyInput={true}
                                                                            startAdornment={
                                                                                <InputAdornment>
                                                                                    <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                                                                </InputAdornment>
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                )
                                            })}
                                            {lineItems && lineItems.length > 1 && (
                                                <TableCell style={{ padding: 5 }}>
                                                    <Image
                                                        src={deleteIcon}
                                                        alt="delete"
                                                        className={classes.deleteImage}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            this.handleDeleteLicenseRow(il)
                                                        }}
                                                    />
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table >
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row-reverse" justifyContent="space-between" style={{ padding: '10px 24px' }}>
                        {isSubmitting && (
                            <CircularProgress />
                        )}
                        {!isSubmitting && (
                            <div>
                                <SqureButton
                                    cls={classes.actionBtn} variant={"outlined"}
                                    onClick={() => {
                                        clear()
                                        hideDialog()
                                    }}
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </SqureButton>
                                &nbsp;&nbsp;&nbsp;
                                <SqureButton
                                    cls={classes.actionBtn} variant={"contained"}
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        this.submitResponse()
                                    }}
                                >
                                    Save
                                </SqureButton>
                            </div>
                        )}
                        {!isSubmitting && false && (
                            <SqureButton
                                cls={classes.actionBtn}
                                onClick={() => this.addLicense(lineItems)}
                            >
                                Add Row
                            </SqureButton>
                        )}
                    </Grid>
                </DialogActions>
            </>
        )
    }
}

ContractParsedField.defaultProps = {
    isSubmitting: false,
}

// const FormInputField_bkp = ({ obj, classes, onChange, onChangeCurrency, isCurrency = false, isOnlyCurrency = false }) => {
//     if (obj.type === 'price') {
//         return (
//             <Grid container alignItems="center" className={classes.currencyFormControl} style={{ width: 'auto' }}>
//                 {isCurrency && <TextField
//                     select
//                     selected={obj.currency}
//                     InputProps={{ disableUnderline: true }}
//                     value={obj.currency}
//                     style={{ width: 52 }}
//                     placeholder="Currency"
//                     onChange={e => onChangeCurrency(e.target.value)}
//                 >
//                     {currencyList.map((option) => (
//                         <option key={option} value={option}>
//                             {option}
//                         </option>
//                     ))}
//                 </TextField>}
//                 {!isOnlyCurrency && (
//                     <TextField
//                         style={{ flex: 1 }}
//                         InputProps={{ disableUnderline: true }}
//                         fullWidth
//                         value={obj.value}
//                         placeholder={obj.label}
//                         type="number"
//                         onChange={(e) => onChange(e.target.value)}
//                     />)}
//             </Grid>
//         )
//     } else {
//         return (
//             <TextField
//                 InputProps={{ disableUnderline: true }}
//                 fullWidth
//                 value={obj.value}
//                 placeholder={obj.label}
//                 type={obj.type}
//                 onChange={(e) => onChange(e.target.value)}
//                 className={classnames(classes.formControl, classes.formInput)}
//             />
//         )
//     }
// }

const FormInputField = ({ newZIndex, obj, classes, onChange, onChangeCurrency, isCurrency = false, isOnlyCurrency = false }) => {
    // console.log(newZIndex, 'newZIndex')
    const [value, setValue] = useState();
    useEffect(() => {
        if (obj && obj.value) {
            setValue(obj.value);
        }
    }, [obj]);
    if (obj.type === "price") {
        return (
            <>
                {/* // <Grid container alignItems="center" className={classes.currencyFormControl}> */}
                {false && isCurrency && (
                    <TextField
                        select
                        selected={obj.currency}
                        InputProps={{ disableUnderline: true }}
                        value={obj.currency}
                        style={{ width: 52 }}
                        placeholder="Currency"
                        onChange={(e) => onChangeCurrency(e.target.value)}
                    >
                        {currencyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                )}

                {isCurrency && (
                    <div className={classes.currencyTypeFields} style={{ display: 'flex' }}>
                        {isCurrency && <div style={{ width: 130, marginRight: 5, zIndex: newZIndex }}>
                            <Select
                                isMulti={false}
                                labelId="singleSelect-select-label"
                                id="singleSelect-select"
                                value={obj.currency}
                                disabled={false}
                                onChange={(e) => {
                                    console.log(e)
                                    onChangeCurrency(e)
                                }}
                                options={currencyOptions}
                                placeholder={"Select a Option"}
                                styles={currencyStyles}
                            >
                            </Select>
                        </div>}

                        {<CurrencyTextField
                            // label={title}
                            variant="outlined"
                            placeholder={obj.label}
                            value={value}
                            disabled={false}
                            currencySymbol={currencyCodeMap[obj.currency.value]}
                            //minimumValue="0"
                            style={{ textAlign: 'left' }}
                            outputFormat="string"
                            decimalCharacter="."
                            digitGroupSeparator=","
                            required={false}
                            onChange={(e, v) => {
                                setValue(v)
                            }}
                            onBlur={() => onChange(value)}
                        />}
                    </div>
                )}
                {/* {!isOnlyCurrency && (
            <TextField
              style={{ flex: 1 }}
              InputProps={{ disableUnderline: true }}
              fullWidth
              value={value}
              placeholder={obj.label}
              type="number"
              onChange={(e) => setValue(e.target.value)}
              onBlur={() => onChange(value)}
            />
          )} */}
                {/* </Grid> */}
            </>
        );
    }
    return (
        // <TextField
        //   InputProps={{ disableUnderline: true }}
        //   fullWidth
        //   value={value}
        //   placeholder={obj.label}
        //   type={obj.type}
        //   onChange={(e) => setValue(e.target.value)}
        //   className={classnames(classes.formControl, classes.formInput)}
        //   onBlur={() => onChange(value)}
        // />

        <TextField
            id="outlined-full-width"
            required={false}
            disabled={false}
            type={obj.type}
            placeholder={obj.label}
            // label={title}
            // style={{ margin: 8 }}
            fullWidth
            variant="outlined"
            InputLabelProps={{
                shrink: true,
            }}
            value={value}
            onChange={(e) => {
                setValue(e.target.value)
                // this.updateTextValue(e.target.value, true);
            }}
            onBlur={() => onChange(value)}

        />
    );
};


const FormInputFieldLineItem = ({ newZIndex, obj, classes, onChange, onChangeCurrency, isCurrency = false, isOnlyCurrency = false }) => {
    console.log(newZIndex, 'newZIndex')
    const [value, setValue] = useState();
    useEffect(() => {
        if (obj && obj.value) {
            setValue(obj.value);
        }
    }, [obj]);
    if (obj.type === "price") {
        return (
            <>
                {/* <Grid container alignItems="center" className={classes.dd}> */}
                {isCurrency && (
                    // <div style={{ width: 130, marginRight: 5, zIndex: newZIndex }}>
                    //   <Select
                    //     isMulti={false}
                    //     labelId="singleSelect-select-label"
                    //     id="singleSelect-select"
                    //     value={obj.currency}
                    //     disabled={false}
                    //     onChange={(e) => {
                    //       console.log(e)
                    //       onChangeCurrency(e)
                    //     }}
                    //     options={currencyOptions}
                    //     placeholder={"Select a Option"}
                    //     styles={currencyStyles}
                    //   >
                    //   </Select>
                    // </div>
                    <TextField
                        select
                        selected={obj.currency}
                        InputProps={{ disableUnderline: true }}
                        value={obj.currency}
                        // style={{ width: 52 }}
                        placeholder="Currency"
                        onChange={(e) => {
                            console.log(e.target.value, 'NewwwwwwwwVal')
                            onChangeCurrency(e.target.value)
                        }
                        }
                    >
                        {currencyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                )}


                {!isOnlyCurrency && (
                    // <TextField
                    //   style={{ flex: 1 }}
                    //   InputProps={{ disableUnderline: true }}
                    //   fullWidth
                    //   value={value}
                    //   placeholder={obj.label}
                    //   type="number"
                    //   onChange={(e) => setValue(e.target.value)}
                    //   onBlur={() => onChange(value)}
                    // />
                    <TextField
                        id="outlined-full-width"
                        required={false}
                        disabled={false}
                        placeholder={obj.label}
                        // label={title}
                        // style={{ margin: 8 }}
                        fullWidth
                        variant="outlined"
                        type={'number'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value)
                        }}
                        onBlur={() => onChange(value)}
                    />
                )}
                {/* </Grid> */}
            </>
        );
    }
    return (
        <TextField
            id="outlined-full-width"
            required={false}
            disabled={false}
            type={obj.type}
            placeholder={obj.label}
            // label={title}
            // style={{ margin: 8 }}
            fullWidth
            variant="outlined"
            InputLabelProps={{
                shrink: true,
            }}
            value={value}
            onChange={(e) => {
                setValue(e.target.value)
                // this.updateTextValue(e.target.value, true);
            }}
            onBlur={() => onChange(value)}

        />
    );
};