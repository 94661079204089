import {
  Avatar, Card, CardHeader, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Button,
  Popover, List, ListItem, Typography, Divider
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import RightArrow from "assets/images/right-arrow.svg";
import classnames from "classnames";
import CommonCss from "commonCss";
// import { size } from 'underscore';
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import MetricVendor from "Components/Application/Components/Metrics/MetricVendor";
import Image from "Components/Common/image.jsx";
import moment from 'moment';
import React from "react";
import { showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
// Redux
import {
  createEvaluationCart, fetchEvaluationCart, removeProductFromCart
} from "redux/evaluation/cart/action";
import {
  fetchAlternativeProducts, fetchOrgContactDetails,
  fetchOrgDocuments, fetchOrgExistingSimilarProducts,
  fetchOrgFinanceDetails
} from "redux/product/orgProduct/action.js";
import { CommonFn } from "services/commonFn";
import PricingSection from "../PricingSection";
import CustomSection from "./CustomSection";
import CollaboratorPopper from './Common/CollaboratorPopper';
import { fetchProductPrice } from "redux/product/orgProduct/action";
import { fetchProductSurveys, fetchCompletedProductSurveys } from "redux/survey/action";
import SurveyData from "./Common/SurveyData";
import queryString from 'query-string';
import { fetchProductInprogressRequests, fetchProductCompletedRequests } from "redux/evaluation/requests/action";
import { showEvaluationProductSelectionDialog } from "redux/evaluation/metadata/action";
import { isEnabledEvaluate } from "util/Common";
import * as Validator from "util/Validation";
import StartRenewalDialog from "../../Application/Components/Renewal/StartRenewalDialog"
import AttachRequestDialog from "../../Application/Components/Renewal/AttachRequestDialog"
import { showSnackBar } from "redux/snackbar/action";
import { currencyCodeMap } from "util/Currency";

// import LinkIcon from '@material-ui/icons/Link';
// import Tooltip from '@material-ui/core/Tooltip';
import { updateRenewal } from "redux/renewal/action";

import { attachProductToRequest } from "redux/renewal/action";
import FileAncher from "../FileAncher";

const connectedProps = (state) => ({
  evaluationCart: state.evaluationCart.evaluation,
  orgContactDetails: state.orgProduct.orgContactDetails,
  orgContactDetailsFetchProgress: state.orgProduct.orgContactDetailsFetchProgress,
  orgFetchDocuments: state.orgProduct.orgFetchDocuments,
  orgFetchDocumentsProgress: state.orgProduct.orgFetchDocumentsProgress,

  fetchInprogressProductRequestProgress: state.evaluationRequestData.fetchInprogressProductRequestProgress,
  fetchInprogressProductRequestError: state.evaluationRequestData.fetchInprogressProductRequestError,
  requestInprogressProductData: state.evaluationRequestData.requestInprogressProductData,

  fetchCompletedProductRequestProgress: state.evaluationRequestData.fetchCompletedProductRequestProgress,
  fetchCompletedProductRequestError: state.evaluationRequestData.fetchCompletedProductRequestError,
  requestCompletedProductData: state.evaluationRequestData.requestCompletedProductData,

  orgExisingSimilarProducts: state.orgProduct.orgExisingSimilarProducts,
  fetchExistingSimilarProductsProgress: state.orgProduct.fetchExistingSimilarProductsProgress,
  orgAlternativeProducts: state.orgProduct.orgAlternativeProducts,
  fetchAlternativeProductsProgress: state.orgProduct.fetchAlternativeProductsProgress,
  orgFinanceDetails: state.orgProduct.orgFinanceDetails,
  orgFinanceDetailsProgress: state.orgProduct.orgFinanceDetailsProgress,
  fetchProductSurveysProgress: state.survey.fetchProductSurveysProgress,
  productSurveys: state.survey.productSurveys,
  userType: state.authUser.user?.Role,
  fetchCompletedProductSurveysProgress: state.survey.fetchCompletedProductSurveysProgress,
  productCompletedSurveys: state.survey.productCompletedSurveys,
  productPrice: state.orgProduct.productPrice,
  isOpen: state.evaluationMetaData.showEvaluationProductSelectionDialog,
  orgUser: state.orgUser,

  attachProductToRequestProg: state.renewal.attachProductToRequestProg,
  attachProductToRequestErr: state.renewal.attachProductToRequestErr,

  updateRenewalProg: state.renewal.updateRenewalProg,

});

const connectionActions = {
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
  fetchOrgContactDetails: fetchOrgContactDetails,
  fetchOrgDocuments: fetchOrgDocuments,
  fetchOrgExistingSimilarProducts: fetchOrgExistingSimilarProducts,
  fetchAlternativeProducts: fetchAlternativeProducts,
  fetchOrgFinanceDetails: fetchOrgFinanceDetails,
  fetchProductSurveys: fetchProductSurveys,
  fetchCompletedProductSurveys: fetchCompletedProductSurveys,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
  fetchProductPrice: fetchProductPrice,
  fetchProductInprogressRequests: fetchProductInprogressRequests,
  fetchProductCompletedRequests: fetchProductCompletedRequests,
  showEvaluationProductSelectionDialog: showEvaluationProductSelectionDialog,
  updateRenewal: updateRenewal,
  attachProductToRequest: attachProductToRequest,
  showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    ...CommonCss.ProductDetails,
    ...CommonCss.Home,
    itemSection: {
      border: '1px solid #d8d8d8',
      padding: 17,
      borderRadius: 5,
      background: '#FFF',
      marginBottom: 17,
      position: 'relative',
      "& [class*=PricingSection-head]": {
        color: '#6C6C6C',
        fontSize: 18,
        opacity: 1,
        fontWeight: 600,
        margin: 0,
        marginBottom: 11
      },
    },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    menuList: {
      padding: '0px',
      "& > hr": {
        "&:first-child": {
          display: 'none'
        },
      }
    },
    popover: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        "&::before": {
          top: "50px",
          left: "-16px",
          width: "0",
          borderTop: "8px solid transparent",
          height: "0px",
          content: "''",
          display: "block",
          position: "absolute",
          borderBottom: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid #fff"
        },
      },
    },
    // threeDot: {
    //   position: "absolute",
    //   zIndex: 11,
    //   right: 5,
    //   top: 5,
    //   padding: 5
    // },
    head: {
      color: '#6C6C6C',
      fontSize: 18,
      opacity: 1,
      fontWeight: 600,
      margin: 0,
      marginBottom: 11
    },
    paper: {
      width: '100%',
      boxShadow: 'none',
      border: '1px solid #ddd'
    },
    resourceLinkText: {
      color: '#4b86f8 !important',
      cursor: 'pointer',
      padding: 10
    },
    tableCell: {
      width: "30%",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 'calc(100vw - 99vw)'
    },
    bottomHead: {
      ...CommonCss.ProductDetails.bottomHead,
      margin: 0
    },
    evaluateCard: {
      ...CommonCss.Home.evaluateCard,
      padding: "24px 24px",
      border: "1px solid #ddd",
      position: "relative",
    },
    evaluateType: {
      borderRadius: "5px",
      position: "absolute",
      textAlign: "left",
      fontSize: "11px",
      padding: "3px 10px",
      right: "0px",
      top: "0px",
    },
    isEvaluate: {
      background: "#E6FFF3",
      color: "#2EB77D",
    },
    isReview: {
      background: "#cce5ff",
      color: "#004085",
    },
    contactCardHeader: {
      flexDirection: "column",
      padding: "16px 4px !important",
      "& [class*='MuiCardHeader-avatar-']": {
        margin: "0px",
        marginBottom: 12,
      },
      "& [class*='MuiCardHeader-content']": {
        width: "100%",
      },
      "& [class*=MuiTypography-displayBlock]": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        margin: "0px -19px 0px 0px",
      },
    },
    addReviewBtn: {
      position: 'absolute',
      right: 0,
      top: 0
    },
    contentSection: {
    },
    topHead: {
      position: 'relative',
      minHeight: 50
    },
    selectedRow: {
      boxShadow: 'inset 0 4px 24px 0 rgb(34 41 47 / 10%)',
      background: '#e7e7e7'
    },
    selectStatus: {
      background: "#fff",
      width: 200,
      textAlign: "left",
      marginTop: 0,
      marginBottom: 0,
      '& [class*=MuiSelect-root]': {
        padding: 9
      }
    },
  });

class OrgProductDetailsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      currentRenewal: null,
      queryParams: queryString.parse(this.props.location.search),
      selectedRenewalProduct: null,
      selectedRenewalContract: null,
      selectedRenewalDate: '',
      showAttachRequestDialog: false,
    };
    this.btnRef = React.createRef();
  }


  getParameterByName(name, url = window.location.href) {
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentDidMount() {
    var evId = this.getParameterByName("evaluationId");
    this.props.fetchProductPrice(this.props.match?.params?.id);
    if (evId !== null) {
      setTimeout(() => {
        this.props.fetchEvaluationCart(evId);
      }, 1000);
    }

    this.fetchOrgContactDetails()
    this.fetchOrgDocuments();
    this.fetchOrgproductInprogressReview()
    this.fetchOrgproductCompletedReview()
    this.fetchSimilarProducts()
    this.fetchAlternativeProducts()
    this.props.fetchOrgFinanceDetails(this.props.match?.params?.id)
    // this.fetchLatestSurveys()
  }

  componentDidUpdate(prevProps) {
    if (!this.props.attachProductToRequestProg && prevProps.attachProductToRequestProg) {
      if (this.props.attachProductToRequestErr) {
        this.props.showSnackBar("Failed to attach", "error", 3000)
      } else {
        this.fetchOrgDocuments();
        this.props.showSnackBar("Attached successfully", "success", 3000)
      }
    }

    if (!this.props.updateRenewalProg && prevProps.updateRenewalProg) {
      this.fetchOrgDocuments();
      this.props.showSnackBar("Status updated successfully", "success", 3000)
    }
  }

  fetchOrgContactDetails = () => {
    if (this.props.match?.params?.id) {
      this.props.fetchOrgContactDetails(this.props.match?.params?.id, {
        type: ["BusinessPoc", "Champion"],
      });
    }
  };

  fetchOrgDocuments = () => {
    this.props.fetchOrgDocuments(this.props.match?.params?.id, true);
  }

  fetchOrgproductInprogressReview = () => {
    let productId = parseInt(this.props.match?.params?.id);
    this.props.fetchProductInprogressRequests({ product_ids: [productId], query: '', page: 0, pageSize: 5, status: [2, 3], type: [1, 2, 3, 4, 10] });
  };

  fetchOrgproductCompletedReview = () => {
    let productId = parseInt(this.props.match?.params?.id);
    this.props.fetchProductCompletedRequests({ product_ids: [productId], query: '', page: 0, pageSize: 5, status: [4], type: [1, 2, 3, 4, 10] });
  };

  fetchLatestSurveys() {
    this.props.fetchProductSurveys(this.props.productDetails.productID, {
      status: [1, 2, 3],
      type: [3],
      page: 0,
      pageSize: 1
    });

    this.props.fetchCompletedProductSurveys(this.props.productDetails.productID, {
      status: [4],
      type: [3],
      page: 0,
      pageSize: 1
    });
  }

  fetchSimilarProducts = () => {
    this.props.fetchOrgExistingSimilarProducts(this.props.match?.params?.id);
  }

  fetchAlternativeProducts = () => {
    this.props.fetchAlternativeProducts(this.props.match?.params?.id);
  }


  addToCart(item) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(item.ID);
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(item.ID) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, item.ID);
      } else {
        this.props.createEvaluationCart(item.ID, this.props.evaluationCart.ID);
      }
    }
  }

  scrollContent = (e) => {
    e.persist();
  };

  routeChange = (item) => {
    let redirectPath = '';
    if (item.WorkflowID === undefined || item.WorkflowID === null || item.WorkflowID === '') {
      redirectPath = `/app/evaluate/${item.ID}/setup`;
      this.props.history.push(redirectPath);
    } else {
      redirectPath = `/app/evaluate/${item.ID}/workflow/interim`;
      this.props.history.push(redirectPath);
    }
  }

  viewRenewal(evaluationId) {
    if (evaluationId !== undefined && evaluationId !== null && evaluationId !== 0) {
      this.props.history.push(`/app/evaluate/${evaluationId}/workflow/interim`);
    }
  }

  getConfigValue(array, key, value) {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  }

  handleClick = (event, row) => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget || event.current,
      currentRenewal: row,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      currentRenewal: null,
    });
  };

  getCost = (input) => {
    let currencyCode = input.replace(/[0-9.]/g, '');

    if (currencyCodeMap[currencyCode]) {
      let amount = input.replace(currencyCode, '');
      if (isNaN(amount) || Number(amount) === 0) {
        return "-";
      }
      return currencyCodeMap[currencyCode] + amount;
    } else {
      return "-";
    }
  }


  getCost = (input) => {
    let currencyCode = input.replace(/[0-9.]/g, '');

    if (currencyCodeMap[currencyCode]) {
      let amount = input.replace(currencyCode, '');
      if (isNaN(amount) || Number(amount) === 0) {
        return "-";
      }
      return currencyCodeMap[currencyCode] + amount;
    } else {
      return "-";
    }
  }

  render() {
    const {
      classes,
      productDetails, orgContactDetailsFetchProgress, orgFetchDocumentsProgress, orgExisingSimilarProducts,
      requestCompletedProductData, fetchCompletedProductRequestProgress, fetchInprogressProductRequestProgress,
      requestInprogressProductData, orgContactDetails, orgFetchDocuments,
      fetchExistingSimilarProductsProgress, fetchAlternativeProductsProgress, orgAlternativeProducts,
      orgFinanceDetailsProgress, orgFinanceDetails, adminFlag, hideFlagForUser, evaluationCart
      // productCompletedSurveys, productSurveys,
    } = this.props;

    const businessPocDetails = orgContactDetails.map((e) => {
      if (e.ContactType === "BusinessPoc") {
        return e;
      } else {
        return false
      }
    }).filter(o => o !== false);
    const similarProducts = orgExisingSimilarProducts?.similar_products;

    let alternativeProducts = [];
    if (orgAlternativeProducts && orgAlternativeProducts.length > 0) {
      alternativeProducts = alternativeProducts.concat(orgAlternativeProducts);
    }

    var addedToCart =
      evaluationCart == null
        ? []
        : evaluationCart.Products.map((p) => p.ID);

    // console.log(productDetails, 'orgFinanceDetails-01')
    let isRequestType = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'REQUEST_TYPES')
    let requestTypesData = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "REQUEST_TYPES") || [];
    let requestTypes = [];
    if (isRequestType && Validator.isJson(requestTypesData) && requestTypesData !== undefined && requestTypesData !== "NULL" && requestTypesData !== null && requestTypesData !== "null" && requestTypesData.length > 0) {
      requestTypes = JSON.parse(requestTypesData);
    }

    const openAction = Boolean(this.state.anchorEl);

    console.log(orgFetchDocuments, 'orgFetchDocuments')

    return (
      <>
        <div className={classes.contentSection} onScroll={(e) => this.scrollContent(e)}>

          {false && <div className={classes.topHead}>
            {false && <Button
              variant="contained"
              color="primary"
              className={classes.addReviewBtn}
              onClick={() => {
                alert('Work in progress')

              }}> Renew Product
            </Button>}
          </div>}
          {(
            orgContactDetailsFetchProgress ||
            orgFetchDocumentsProgress || fetchCompletedProductRequestProgress ||
            fetchInprogressProductRequestProgress || fetchExistingSimilarProductsProgress ||
            fetchAlternativeProductsProgress || orgFinanceDetailsProgress
          ) && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            )}
          {this.props.sections && this.props.sections.length > 0 && this.props.sections.map(section => {
            // return (<div className={classes.itemSection}>{section.SectionUID}</div>)
            if (section.IsDeletable && section.IsEditable) {
              return (
                <CustomSection
                  key={section.SectionUID}
                  section={section}
                  isEditable={(section.Permission === "write") || (section.Access === 2)}
                  productID={productDetails.productID}
                />
              )
            } else if (section.SectionUID === "business_point_of_contact") {
              return (
                <>
                  {/* orgBussinessPocDetails */}
                  {hideFlagForUser.business_point_of_contact && (
                    <div className={classes.itemSection}>
                      {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                      <p className={classes.head}>{section.SectionName}</p>
                      <Grid container className={classes.root} spacing={2}>
                        {businessPocDetails
                          ?.slice(0, 5)
                          ?.map((item, index) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={2}
                                key={section.SectionUID}
                                className={classes.cardSection}
                              >
                                <Card variant="outlined">
                                  <CardHeader
                                    className={classes.contactCardHeader}
                                    style={{ textAlign: "center" }}
                                    avatar={
                                      <Avatar
                                        aria-label="recipe"
                                        className={classes.contactCardAvatar}
                                      >
                                        {item.User && item.User?.Name?.slice(0, 1)}
                                      </Avatar>
                                    }
                                    title={item.User?.Name}
                                    subheader={item.User?.Email}
                                  />
                                </Card>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </div>
                  )}
                </>
              )
            }
            else if (section.SectionUID === "start_date") {
              let hasStartDate = false;
              return (
                <>
                  {hideFlagForUser.start_date &&
                    orgFinanceDetails[orgFinanceDetails.length - 1]?.OrgProductFinancialDocumentAttributes?.map((o, index) => {
                      if (['start_date'].includes(o.Attribute) && o.Value) {
                        hasStartDate = true;
                        return (
                          <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                            {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                            <p key={`start_date-${index}`}><strong>{section.SectionName}:</strong> {o.Value}</p>
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {hideFlagForUser.start_date && !hasStartDate && <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                    {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                    <p key={`start_date-0`}><strong>{section.SectionName}:</strong> Not Available</p>
                  </div>}
                </>
              )
            }
            else if (section.SectionUID === "renewal_date") {
              let hasRenewalDate = false;
              return (
                <>
                  {hideFlagForUser.renewal_date &&
                    orgFinanceDetails[orgFinanceDetails.length - 1]?.OrgProductFinancialDocumentAttributes?.map((o, index) => {
                      if (["renewal_date"].includes(o.Attribute) && o.Value) {
                        hasRenewalDate = true;
                        return (
                          <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                            {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                            <p key={`renewal_date-${index}`}><strong>{section.SectionName}:</strong> {o.Value}</p>
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {hideFlagForUser.renewal_date && !hasRenewalDate && <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                    {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                    <p key={`renewal_date-0`}><strong>{section.SectionName}:</strong> Not Available</p>
                  </div>}
                </>
              )
            } else if (section.SectionUID === "end_date") {
              let hasEndDate = false;
              return (
                <>
                  {hideFlagForUser.end_date &&
                    orgFinanceDetails[orgFinanceDetails.length - 1]?.OrgProductFinancialDocumentAttributes?.map((o, index) => {
                      if (['end_date'].includes(o.Attribute) && o.Value) {
                        hasEndDate = true;
                        return (
                          <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                            {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                            <p key={`end_date-${index}`}><strong>{section.SectionName}:</strong> {o.Value}</p>
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {hideFlagForUser.end_date && !hasEndDate && <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                    {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                    <p key={`end_date-0`}><strong>{section.SectionName}:</strong> Not Available</p>
                  </div>}
                </>
              )
            } else if (section.SectionUID === "total_cost_from_contract") {
              let hasCost = false;
              return (
                <>
                  {hideFlagForUser.total_cost_from_contract &&
                    orgFinanceDetails[orgFinanceDetails.length - 1]?.OrgProductFinancialDocumentAttributes?.map((o, index) => {
                      if (['total_cost'].includes(o.Attribute) && o.Value) {
                        hasCost = true;
                        return (
                          <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                            {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                            <p key={`total_cost-${index}`}><strong>{section.SectionName}:</strong> {`${o.Value.slice(0, 3)} ${o.Value.slice(3)}`}</p>
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  {hideFlagForUser.total_cost_from_contract && !hasCost && <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                    {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                    <p key={`total_cost-0`}><strong>{section.SectionName}:</strong> Not Available</p>
                  </div>}
                </>
              )
            }
            else if (section.SectionUID === "contract") {
              return (
                <>
                  {/* Contract */}
                  {hideFlagForUser.contract && (
                    <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                      {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                      <p className={classes.head}>{section.SectionName}</p>
                      <Paper className={classnames(classes.card, classes.paper)}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell className={classnames(classes.tableCell)}><span className={classes.resourceHead}>Resource</span></TableCell>
                              <TableCell >Type</TableCell>
                              <TableCell >Start Date</TableCell>
                              <TableCell >End Date</TableCell>
                              <TableCell >Renewal Date</TableCell>
                              <TableCell >Total Cost</TableCell>
                              <TableCell >Status</TableCell>
                              {/* <TableCell >Action</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orgFetchDocuments.map((item, index) => {
                              return (
                                <TableRow
                                  key={index}
                                  style={{ cursor: 'pointer' }}
                                  className={String(item.ID) === this.state.queryParams.document_id ? classes.selectedRow : ''}
                                >
                                  <TableCell className={classnames(classes.tableCell)}>
                                    <div>
                                      {(!item.URL || item.URL === "") ? (
                                        <p style={{ marging: 0, padding: "0px 10px" }}>{item?.Title}</p>
                                      ) : (
                                        // <a href={item.URL !== undefined && item.URL !== null ? item.URL : ''} className={classes.resourceLinkText} target="_blank" rel="noopener noreferrer">
                                        //   {item?.URL?.indexOf('.expent.ai') > 0 ? item.Title : item?.URL}
                                        // </a>
                                        <>
                                          {item.URL && item.URL !== '' && <FileAncher
                                            fileName={item.Title}
                                            fileURL={item.URL}
                                          />}
                                        </>
                                      )}

                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {item.DocumentType ? item.DocumentType.name : 'Other'}
                                  </TableCell>
                                  <TableCell>
                                    {item?.startDate !== null ? moment(item?.startDate).format("DD-MMM-YYYY") : '-'}
                                  </TableCell>
                                  <TableCell>
                                    {item?.endDate !== null ? moment(item?.endDate).format("DD-MMM-YYYY") : '-'}
                                  </TableCell>
                                  <TableCell>
                                    {item?.renewalDate !== null ? moment(item?.renewalDate).format("DD-MMM-YYYY") : '-'}
                                  </TableCell>
                                  <TableCell>
                                    {item?.totalCost !== null ? this.getCost(item?.totalCost) : '-'}
                                  </TableCell>
                                  <TableCell>
                                    {item?.isActive ? 'Active' : 'Inactive'}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Paper>
                    </div >
                  )}
                </>
              )
            }
            else if (section.SectionUID === "usage_summary") {
              return (
                <>
                  {hideFlagForUser.usage_summary && (
                    <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                      {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                      <p className={classes.head}>{section.SectionName}</p>
                      <MetricVendor
                        params={{ id: this.props.match?.params?.id }}
                        isOrgProductDetaisPage
                        isHideDateAction={true}
                        isHideShare={true}
                        isHideUserActivity={true}
                        duration={100}
                        adminFlag={adminFlag}
                      />
                    </div>
                  )}
                </>
              )
            }
            else if (section.SectionUID === "latest_review_and_evaluation") {
              return (
                <>
                  {/* Latest review and evaluation */}
                  {hideFlagForUser.latest_review_and_evaluation && (
                    <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                      {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                      {/* <p className={classes.head}>{section.SectionName}</p> */}
                      <p className={classes.head}>Workflows</p>
                      <Grid container>
                        {requestInprogressProductData?.Data && requestInprogressProductData?.Data.length > 0 && (
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <p className={classes.bottomHead}> In Progress</p>
                            <div>
                              {requestInprogressProductData?.Data?.map((item, index) => (
                                <div
                                  className={classes.evaluateCard}
                                  key={index}
                                  onClick={() => this.routeChange(item)}
                                >
                                  <span
                                    className={classnames(
                                      classes.evaluateType,
                                      item.Type === 1
                                        ? classes.isEvaluate
                                        : classes.isReview
                                    )}
                                  >
                                    {item.Type === 1 ? "Evaluation" : item.Type === 2 ? 'Review' : 'Survey'}
                                  </span>
                                  <div className={classes.evaluateText}>
                                    <p className={classes.evaluateCardTitle}>
                                      {item.Name}
                                    </p>
                                    <p className={classes.evaluateCardSubTitle}>
                                      Updated At:{" "}
                                      {CommonFn.formateDate(item.UpdatedAt)}
                                    </p>
                                  </div>
                                  <div className={classes.evaluateLogo}>
                                    <div className={classes.productLogoContainer}>
                                      {item.Products?.slice(
                                        0,
                                        item.Products?.length < 5
                                          ? item?.Products?.length
                                          : 3
                                      ).map((item, i) => {
                                        return (
                                          <div
                                            className={classes.productLogo}
                                            title={item.Name}
                                          >
                                            <Image
                                              src={item.ProductLogo}
                                              alt={item.Name}
                                              title={item.Name}
                                            />
                                          </div>
                                        );
                                      })}
                                      {item.Products?.length > 4 && (
                                        <div
                                          className={classes.productLogo}
                                          title={
                                            item?.Products?.length -
                                            3 +
                                            "more product"
                                          }
                                        >
                                          {" "}
                                          + {item.Products?.length - 3}
                                        </div>
                                      )}
                                    </div>
                                    <Image
                                      src={RightArrow}
                                      className={classes.rightArrow}
                                    />
                                  </div>
                                </div>
                              )
                              )}
                              {fetchInprogressProductRequestProgress && (
                                <span className={classes.loader}>
                                  <CircularProgress />
                                </span>
                              )}
                            </div>
                          </Grid>
                        )}
                        {requestCompletedProductData?.Data && requestCompletedProductData?.Data.length > 0 && (
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <p className={classnames(classes.bottomHead)}>Completed</p>
                            <div>
                              {requestCompletedProductData?.Data?.map(
                                (item, index) => (
                                  <div
                                    className={classes.evaluateCard}
                                    key={index}
                                    onClick={() => this.routeChange(item)}
                                  >
                                    <span
                                      className={classnames(
                                        classes.evaluateType,
                                        item.Type === 1
                                          ? classes.isEvaluate
                                          : classes.isReview
                                      )}
                                    >
                                      {item.Type === 1 ? "Evaluation" : "Review"}
                                    </span>
                                    <div className={classes.evaluateText}>
                                      <p className={classes.evaluateCardTitle}>
                                        {item.Name}
                                      </p>
                                      <p className={classes.evaluateCardSubTitle}>
                                        Updated At:{" "}
                                        {CommonFn.formateDate(item.UpdatedAt)}
                                      </p>
                                    </div>
                                    <div className={classes.evaluateLogo}>
                                      <div className={classes.productLogoContainer}>
                                        {item.Products?.slice(
                                          0,
                                          item.Products?.length < 5
                                            ? item.Products?.length
                                            : 3
                                        ).map((item, i) => {
                                          return (
                                            <div
                                              className={classes.productLogo}
                                              title={item.Name}
                                              key={i}
                                            >
                                              <Image
                                                src={item.ProductLogo}
                                                alt={item.Name}
                                                title={item.Name}
                                              />
                                            </div>
                                          );
                                        })}
                                        {item.Products?.length > 4 && (
                                          <div
                                            className={classes.productLogo}
                                            title={
                                              item.Products?.length -
                                              3 +
                                              "more product"
                                            }
                                          >
                                            {" "}
                                            + {item.Products?.length - 3}
                                          </div>
                                        )}
                                      </div>
                                      <Image
                                        src={RightArrow}
                                        className={classes.rightArrow}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                              {fetchCompletedProductRequestProgress && (
                                <span className={classes.loader}>
                                  <CircularProgress />
                                </span>
                              )}
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  )}
                </>
              )
            }
            else if (section.SectionUID === "latest_survey") {
              return (
                <>
                  <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                    {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                    <p className={classes.head}>{section.SectionName}</p>
                    <SurveyData
                      productDetails={productDetails}
                      productId={productDetails.productID}
                      updateProducts={this.props.updateProducts}
                      hideFlagForUser={this.props.hideFlagForUser}
                      sections={this.props.sections}
                      adminFlag={this.props.adminFlag}
                      page={0}
                      pageSize={1}
                      cPage={0}
                      cPageSize={1}
                    />
                  </div>
                </>
              )
            }
            else if (section.SectionUID === "pricing") {
              return (
                <>
                  {/* Pricing */}
                  {hideFlagForUser.pricing ?
                    <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                      {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                      <PricingSection
                        productName={productDetails?.name}
                        productId={this.props?.match?.params?.id}
                        title={section?.SectionName}
                      />
                    </div> : null}
                </>
              )
            }
            else if (section.SectionUID === "existing_similar_products") {
              return (
                <>
                  {/* similar Products */}
                  {hideFlagForUser.existing_similar_products && (
                    <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                      {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                      <p className={classes.head}>{section.SectionName}</p>
                      <Grid container spacing={2}>
                        {similarProducts?.slice(0, 7)?.map((product, k) => (
                          <Grid item xs={12} sm={4} md={3} lg={2}>
                            <LayeredCard
                              title={product.Name}
                              subTitle={product.CompanyName}
                              description={product.Description}
                              customRoute={'/app/products/org-product-details/'}
                              image={product.ProductLogo}
                              onClick={() => { }}
                              numLayer={0}
                              checked={false}
                              showCheckbox={false}
                              id={product.ID}
                              uniqueId={product.ID + "_q_" + k}
                              type={"product"}
                              IsExisting={product.IsExisting}
                              fireEvent
                              eventPage={'orgProductDetails'}
                              eventData={{
                                type: 'event.click.org-product.section',
                                data: {
                                  url: this.props.location.pathname + this.props?.location?.search,
                                  section: {
                                    id: section.SectionUID,
                                    name: section.SectionName
                                  },
                                  product: {
                                    id: product.ID,
                                    name: product.Name,
                                    position: k + 1
                                  }
                                }
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  )}
                </>
              )
            } else if (section.SectionUID === "alternative_products") {
              return (
                <>
                  {/* Alternatives Products */}
                  {hideFlagForUser.alternative_products && (
                    <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                      {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                      <p className={classes.head}>{section.SectionName}</p>
                      <Grid container spacing={2}>
                        {alternativeProducts
                          .slice(0, 7)
                          .map((product, k) => (
                            <Grid item xs={12} sm={4} md={3} lg={2}>
                              <LayeredCard
                                title={product.Name}
                                subTitle={product.CompanyName}
                                description={product.Description}
                                customRoute={'/app/products/product-details/'}
                                image={product.ProductLogo}
                                onClick={() => { }}
                                numLayer={0}
                                // checked={false}
                                showCheckbox={true}
                                id={product.ID}
                                uniqueId={product.ID + "_q_" + k}
                                type={"product"}
                                IsExisting={product.IsExisting}
                                toogleCheck={() => {
                                  this.addToCart(product);
                                }}
                                checked={addedToCart.indexOf(product.ID) !== -1}
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </div>
                  )}
                </>
              )
            } else {
              return null
            }
          })}
        </div >
        {
          this.props.isOpen && <StartRenewalDialog
            selectedRenewalProduct={this.state.selectedRenewalProduct}
            selectedRenewalContract={this.state.selectedRenewalContract}
            selectedRenewalDate={this.state.selectedRenewalDate}
            isRequestType={isRequestType && requestTypes.length > 0 ? true : false}
            requestTypes={requestTypes}
          />
        }

        {
          this.state.showAttachRequestDialog && <AttachRequestDialog
            selectedRenewalProduct={this.state.selectedRenewalProduct}
            selectedRenewalContract={this.state.selectedRenewalContract}
            hideDialog={() => {
              this.setState({ showAttachRequestDialog: false });
            }}
            onSubmit={(requestId) => {
              this.props.attachProductToRequest(this.state.selectedRenewalProduct.id,
                requestId,
                {
                  contractID: this.state.selectedRenewalContract?.id
                });
              this.setState({ showAttachRequestDialog: false });
            }}
          />
        }

        {
          this.state.currentRenewal && <Popover
            className={classes.popover}
            id={'add-vendor'}
            open={openAction}
            anchorEl={this.state.anchorEl}
            onClose={() => this.handleClose()}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}>
            <List>
              {(this.state.currentRenewal.project === undefined || this.state.currentRenewal.project === null || this.state.currentRenewal.project === '') && <ListItem
                button
                onClick={() => {
                  this.setState({
                    selectedRenewalContract: {
                      id: this.state.currentRenewal.ID
                    },
                    selectedRenewalProduct: {
                      productLogo: productDetails?.productLogo,
                      name: productDetails?.name,
                      id: productDetails?.productID,
                      type: "PRODUCT",
                    },
                    selectedRenewalDate: this.state.currentRenewal.renewalDate
                  }, () => {
                    this.props.showEvaluationProductSelectionDialog();
                    this.handleClose();
                  })
                }}
              >
                <Typography variant="inherit" >
                  Start Renewal
                </Typography>
              </ListItem>}

              {this.state.currentRenewal.project && <ListItem
                button
                onClick={() => {
                  this.viewRenewal(this.state.currentRenewal.project?.id)
                  this.handleClose();
                }}
              >
                <Typography variant="inherit" >
                  View Renewal
                </Typography>
              </ListItem>}

              {(this.state.currentRenewal.project === undefined || this.state.currentRenewal.project === null || this.state.currentRenewal.project === '') && <Divider />}
              {(this.state.currentRenewal.project === undefined || this.state.currentRenewal.project === null || this.state.currentRenewal.project === '') && <ListItem
                button
                onClick={() => {
                  this.setState({
                    showAttachRequestDialog: true,
                    selectedRenewalProduct: {
                      productLogo: productDetails?.productLogo,
                      name: productDetails?.name,
                      id: productDetails?.productID,
                    },
                    selectedRenewalContract: { id: this.state.currentRenewal.ID },
                    selectedRenewalDate: this.state.currentRenewal.renewalDate
                  })
                  this.handleClose();
                }}
                className={this.state.currentRenewal.requestStatus !== "Draft" ? classes.disabled : ''}
              >
                <Typography variant="inherit">
                  Link Project
                </Typography>
              </ListItem>}
            </List>
          </Popover>
        }
      </>
    );
  }
}

export default connector(
  compose(withRouter, withStyles(styles))(OrgProductDetailsTab)
);
