import {
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import SearchIcon from "@material-ui/icons/Search";
import CustomImage from "./CustomImage";
import Workflow from "assets/images/workflow/workflow.svg";
import {InputAdornment} from "@material-ui/core";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import Image from 'Components/Common/image.jsx'

import Clear from "@material-ui/icons/Clear";
import TooltipMsg from '@material-ui/core/Tooltip';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
});

const connectionActions = {
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    searchArea: {
        background: '#ffffff 0% 0% no-repeat padding-box',
        border: '0.5px solid #ebebeb',
        borderRadius: 3,
        opacity: 1,
        padding: '12px 12px 0',
        marginTop: 20
    },
    resultArea: {
        overflowY: 'auto',
        padding: '5px 0',
        zIndex: 10,
        marginTop: 2,
        "& .draggable": {
            border: '1px solid #D8D8D8',
            borderRadius: 5,
            marginBottom: 12,
            padding: 12,
            width: '100%',
            "& .draggableLabel": {
                fontSize: 13,
                color: '#333333',
                fontWeight: 600,
                margin: 0,
                marginBottom: 2,
            },
            "& .draggableDec": {
                fontSize: 14,
                color: '#9B9EAC',
                margin: 0
            }
        },
    },
    inputContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        background: '#ffffff 0% 0% no-repeat padding-box',
        border: '1px solid #cfd7df',
        borderRadius: 4,
        opacity: 1,
        padding: '2px 3px',
    },
    workflowSearchInput: {
        fontSize: 16,
        border: 0,
        color: '#9b9eac',
        opacity: 1,
        width: '100%'
    },
    searchIcon: {
        fontSize: 16,
        color: "#9B9EAC",
        marginTop: -0.5
    },
    searchSection: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        // marginRight: 40,
        '& [class*="MuiOutlinedInput-root"]': {
            maxHeight: 40,
            minHeight: 40,
            maxWidth: 343,
        },
        "& [class*='MuiInputBase-input']": {
            fontSize: 13,
            width: 300,
        },
        "& [class*='MuiSvgIcon-root']": {
            fill: "#707070",
        },
        "& [class*=MuiGrid-container]": {
            alignItems: "center",
            marginBottom: 20,
        },
        "&>div ": {
            marginBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
});

class AddComponentDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            query: '',
            targetAction: this.props.targetAction,
            masterWorkflows: this.props.masterWorkflows,
            orgWorkflows: this.props.orgWorkflows,
            workflows: [],
            workflowComponent: this.props.workflowComponent,
            orgWorkFlowList: this.props.orgWorkFlowList,
            masterWorkFlowList: this.props.masterWorkFlowList
        }
        this.handleClear = this.handleClear.bind(this);
        this.searchData = this.searchData.bind(this);

    }

    componentDidMount() {
        this.init()
        // console.log(this.props, 'Propssssssssssssssssssssssss')
    }

    componentDidUpdate(prevProps) {

    }

    init(){
        var workflows = []
        this.props.workflowComponent.forEach(function(itm){
            if(itm.Name !== 'Start'){
                workflows.push(itm)
            }
        })
        this.setState({
            workflows: workflows
        })

    }

    handleClear(event) {
        event.stopPropagation();
        var me = this;
        this.setState({ query: '', page: 0 }, () => {
            me.searchData();
        });
    }

    searchData() {
        const { targetAction, workflowComponent, orgWorkFlowList, masterWorkFlowList } = this.state;
        var search = this.state.query;
        if (targetAction === "component") {
            if (search && workflowComponent.length > 0) {
                const filteredlist = workflowComponent.filter(
                    (val) =>
                        val.Name.toLowerCase() === search.toLowerCase() ||
                        val.Name.toLowerCase().includes(search.toLowerCase())
                );
                this.setState({
                    workflows: filteredlist
                })
            } else {
                this.setState({
                    workflows: workflowComponent
                })
            }
        } else {
            let orgWF = orgWorkFlowList?.Data?.length > 0 ? orgWorkFlowList?.Data : [];
            let masterWF = masterWorkFlowList?.Data?.length > 0 ? masterWorkFlowList?.Data : [];
            if (search) {
                const filteredOrglist = orgWF.filter((val) =>
                    val.Name.toLowerCase() === search.toLowerCase() ||
                    val.Name.toLowerCase().includes(search.toLowerCase())
                );
                const filteredMasterlist = orgWF.filter((val) =>
                    val.Name.toLowerCase() === search.toLowerCase() ||
                    val.Name.toLowerCase().includes(search.toLowerCase())
                );
                this.setState({
                    orgWorkflows: filteredOrglist,
                    masterWorkflows: filteredMasterlist
                })
            } else {
                this.setState({
                    orgWorkflows: orgWF,
                    masterWorkflows: masterWF
                })
            }
        }
    }

    render() {
        const classes = this.props.classes;

        const { targetAction, masterWorkflows, orgWorkflows, workflows } = this.state;

        console.log(workflows, targetAction,'workflows123')

        // console.log(workflows, 'workflows11111111111111111')

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>
                    {targetAction === "workflow" ? 'Add Workflows' : 'Add Component'}
                    </Typography>
                <div className={classes.body}>
                    <div className={classes.searchArea}>
                        <div className={classes.searchSection}>
                            <OutlinedInput
                                id={"searchProducts"}
                                placeholder={'Search'}
                                value={this.state.query}
                                onChange={(event) => {
                                    // console.log(event)
                                    // console.log(event.target)
                                    this.setState({
                                        query: event?.target?.value
                                    }, () => {
                                        this.searchData();
                                    })
                                    // var me = this;
                                    // this.setState({ query: event.target.value }, () => {
                                    //     me.searchData(event.target.value);
                                    // });
                                }}
                                startAdornment={
                                    <InputAdornment>
                                        <SearchIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment>
                                        {this.state.query.length > 0 && this.state.query !== '' && this.state.query !== null && <TooltipMsg placement="top" title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Clear</span>} arrow>
                                            <Clear className={classes.searchIconPop} onClick={this.handleClear} />
                                        </TooltipMsg>}
                                    </InputAdornment>
                                }
                                roundedCorner={true}
                            />

                        </div>
                        <div className={classes.resultArea}>
                            {targetAction === "workflow" && (
                                <>
                                    {false && masterWorkflows.length && (
                                        <p
                                            style={{ color: "#9b9eac" }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            Expent Workflow Library
                                        </p>
                                    )}
                                    {false && masterWorkflows.map((wrkf, index) => (
                                        <p
                                            style={{ cursor: "pointer" }}
                                            key={wrkf.Name}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                // onClickWorkFlowComponent(e, 'MASTER', wrkf?.ID, wrkf?.Name, wrkf?.Description)
                                                // setTargetAction(false);
                                            }}
                                        >
                                            {index + 1}. {wrkf.Name}
                                        </p>
                                    ))}
                                    {false && orgWorkflows.length > 0 && (
                                        <p
                                            style={{ color: "#9b9eac" }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            Custom Workflow
                                        </p>
                                    )}
                                    {orgWorkflows.map((wrkf, index) => (
                                        <div
                                            key={index}
                                            id={'master_workflow_' + wrkf.ID}
                                            className="draggable"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.addWorkFlowComponent({
                                                    event: e,
                                                    type: 'ORG',
                                                    id: wrkf?.ID,
                                                    name: wrkf?.Name,
                                                    description: wrkf?.Description
                                                })
                                                this.props.setTargetAction(false);
                                            }}
                                        >
                                            <div className="img"><Image src={Workflow} alt="workflow" /></div>
                                            <div>
                                                <p className="draggableLabel">{wrkf.Name}</p>
                                                <p className="draggableDec">{wrkf?.Description}</p>
                                            </div>
                                        </div>
                                        // <p
                                        //     style={{ cursor: "pointer" }}
                                        //     key={wrkf.Name}
                                        //     onClick={(e) => {
                                        //         e.stopPropagation();
                                        //         this.props.addWorkFlowComponent({
                                        //             event: e,
                                        //             type: 'ORG',
                                        //             id: wrkf?.ID,
                                        //             name: wrkf?.Name,
                                        //             description: wrkf?.Description
                                        //         })
                                        //         this.props.setTargetAction(false);
                                        //     }}
                                        // >
                                        //     {index + 1}. {wrkf.Name}
                                        // </p>
                                    ))}
                                </>
                            )}
                            {targetAction === "component" &&
                                workflows.map((component, index) => {
                                    // if (component.Name === 'Start') {
                                    //     return null
                                    // }
                                    return <div
                                        id={'div_' + component.ID}
                                        key={index}
                                        draggable="true"
                                        className="draggable"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.props.onSelectCompoent(e, component.ID);
                                            this.props.setTargetAction(false);
                                        }}
                                    >
                                        <div className="img"><CustomImage slug={component.Slug} /></div>
                                        <div>
                                            <p className="draggableLabel">{component.Name}</p>
                                            <p className="draggableDec">{component?.Description}</p>
                                        </div>
                                    </div>
                                })}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    }
}

AddComponentDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AddComponentDialog));