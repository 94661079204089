import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { updateDrawerStyle } from "redux/drawer/action";
import ModeSwitch from "../Components/ModeSwitch";
import FlowBuilder from "../../FlowBuilder";

const connectedProps = () => ({});

const connectionActions = {
    updateDrawerStyle: updateDrawerStyle
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {

    }
});

class Builder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.updateDrawerStyle(false, true);

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        const { classes,isShowModeSwitch } = this.props;
        return (
            <div className={classes.root}>
                {isShowModeSwitch && <ModeSwitch active={'RECIPE'}/>}
                <div className={classes.mainContainer}>
                    <FlowBuilder
                        {...this.props}
                    />
                </div>
            </div>
        );
    }
}

Builder.defaultProps = {
    isShowModeSwitch:false,
    isForTestJobs:false, 
    isForSummary: false
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Builder));
