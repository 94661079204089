import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Select as MaterialSelect } from "@material-ui/core";

import moment from "moment";
import { uniq } from "underscore";
import _ from "underscore";
import { format } from "date-fns";
import { connect } from "react-redux";
// import classnames from "classnames";
// import { currencyList } from "services/invoiseParser";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid, TextField, MenuItem, OutlinedInput, InputAdornment, Button, Table, FormControl, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import CommonCss from "commonCss";
import SqureButton from "Components/Common/SqureButton";
import Image from "Components/Common/image.jsx";
import CalendarImage from "assets/images/calendar.svg";
import deleteIcon from "assets/images/delete.svg";
import {
  updateFinancialDetailsData,
  fetchOrgFinanceDetails,
  activeContractDetailsDocument,
  addNewContractDetails,
  getOrgDocumentDetails,
  attachFileToDocument,
} from "redux/product/orgProduct/action.js";
import { showSnackBar } from "redux/snackbar/action";
import { currencyOptions, currencyCodeMap } from "util/Currency";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import UpdateDocumentFileDialog from "./Dialogs/UpdateDocumentFileDialog";
import { documentGlobalFields } from "util/AppCatalogTypeOptions";
import { dataKeys, defaultLicense } from "services/invoiseParser";

const connectedProps = (state) => ({
  updateContractDetailsProg: state.orgProduct.updateContractDetailsProg,
  updateContractDetailsError: state.orgProduct.updateContractDetailsError,
  orgFinanceDetails: state.orgProduct.orgFinanceDetails,
  orgFinanceDetailsProgress: state.orgProduct.orgFinanceDetailsProgress,
  activeContractDocument: state.orgProduct.activeContractDocument,
  orgFetchDocuments: state.orgProduct.orgFetchDocuments,
  orgFetchDocumentsProgress: state.orgProduct.orgFetchDocumentsProgress,
  addContractDetailsProg: state.orgProduct.addContractDetailsProg,
  addContractProgress: state.orgProduct.addContractProgress,
  addContractError: state.orgProduct.addContractError,
  addContractSuccess: state.orgProduct.addContractSuccess,

  getOrgDocumentDetailsProgress: state.orgProduct.getOrgDocumentDetailsProgress,
  getOrgDocumentDetailsError: state.orgProduct.getOrgDocumentDetailsError,
  getOrgDocumentDetailsSuccess: state.orgProduct.getOrgDocumentDetailsSuccess,
});

const connectionActions = {
  updateFinancialDetailsData: updateFinancialDetailsData,
  showSnackBar: showSnackBar,
  fetchOrgFinanceDetails: fetchOrgFinanceDetails,
  activeContractDetailsDocument: activeContractDetailsDocument,
  addNewContractDetails: addNewContractDetails,
  getOrgDocumentDetails: getOrgDocumentDetails,
  attachFileToDocument: attachFileToDocument,
};

var connector = connect(connectedProps, connectionActions);

var currencyStyles = {
  control: (styles) => ({ ...styles, height: 37 }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  }),
};

const customStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white", height: 37 }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
  // input: (styles) => ({ ...styles, padding: 12 }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles }),
  menuPortal: (base) => ({ ...base, zIndex: 99999 }),
};

const styles = (theme) =>
  createStyles({
    root: {
      "& [class*=MuiInputBase-root]": {
        fontSize: "14px !important",
      },
      padding: 17,
      borderRadius: 5,
      background: "#FFF",
      marginBottom: 17,
    },
    link: {
      color: "#4b86f8 ",
    },
    formControl: {
      ...CommonCss.formControl,
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
      },
      padding: "5px !important",
      minHeight: "auto !important",
      "& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        appearance: "none",
        padding: "0px !important",
        fontSize: "11px !important",
      },
      '& [class*="MuiInputBase-input"]': {
        padding: "0px !important",
        fontSize: "11px !important",
      },
    },
    head: {
      ...CommonCss.ProductDetails.head,
      marginBottom: 0,
    },
    deleteImage: {
      zIndex: 20,
      padding: 4,
      cursor: "pointer",
    },
    currencyFormControl: {
      ...CommonCss.formControl,
      display: "flex",
      padding: "0px !important",
      minHeight: "auto !important",
      "& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        appearance: "none",
        padding: "0px !important",
        fontSize: "11px !important",
      },
      "& [class*=MuiSelect-select]": {
        paddingRight: 0,
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      '& [class*="MuiInputBase-root"]': {
        padding: "0px 5px !important",
        fontSize: "11px !important",
      },
    },
    formGroup: {
      marginTop: 5,
      marginBottom: 16,
    },
    formInput: {
      width: "100%",
      "& > div": {
        backgroundColor: "transparent !important",
        border: "none",
        paddingRight: "0px !important",
      },
      "& > div:focus": {
        backgroundColor: "transparent !important",
        border: "none",
        paddingRight: "0px !important",
      },
    },
    formContainer: {
      margin: "0 auto",
    },
    label: {
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: 1.6,
      color: "#000000de",
      marginBottom: 10,
    },
    datePicker: {
      "& .react-datepicker-wrapper": {
        width: "100%",
      },
      "& .react-datepicker__input-container > div": {
        display: "flex",
        marginBottom: 0,
        alignItems: "center",
        "& [class*=MuiInputBase-root]": {
          maxHeight: 33,
          minHeight: 33,
          width: "inherit",
          fontSize: "11px !important",
        },
        "& [class*=MuiFormLabel-root]": {
          marginBottom: 0,
          marginRight: 29,
        },
      },
      "[class*=MuiMenuItem-root]": {
        color: "#707070",
        fontSize: "11px !important",
      },
    },
    container: {
      border: "1px solid #ddd",
      borderRadius: 5,
      padding: 10,
      margin: "20px 0px",
    },
    priceContainer: {
      '& [class*="FinancialDetails-currencyFormControl"]': {
        width: "max-content !important",
      },
    },
    addNewButton: {
      fontWeight: 600,
      fontSize: theme.spacing(2),
    },
    currencyTypeFields: {
      '& [class*="MuiFormControl-root"]': {
        width: "100%",
      },
      '& [class*="MuiInputBase-input"]': {
        textAlign: "left",
      },
      '& [class*="MuiSelect-root"]': {
        height: 56,
      },
    },
    calendarPopout: {
      zIndex: "100 !important",
    },
    customTypeFields: {
      '& [class*="MuiFormControl-root"]': {
        width: "100%",
      },
      '& [class*="MuiInputBase-input"]': {
        textAlign: "left",
      },
      '& [class*="MuiSelect-root"]': {
        fontSize: 13,
        color: "#707070",
        padding: "0.29em 0.75em",
      },
      '& [class*="MuiSelect-select"]': {
        padding: 20,
      },
    },
    newUserBtn: {
      display: "inline",
      background: "#fff",
      borderRadius: 5,
      color: "#3C3C3C",
      fontSize: theme.spacing(1.9),
      border: "1px solid #3c3c3c",
      minHeight: 35,
      padding: 8,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
        color: "#fff",
      },
    },
    linkText: {
      position: "relative",
      cursor: "pointer",
      width: "100%",
      height: "100%",
    },
    uploadImageLabel: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    uploadImage: {
      overflow: "hidden",
      width: 0,
      position: "absolute",
      top: 10,
      left: 0,
      opacity: 0,
    },
    date_popper: {
      zIndex: 2222,
    },
  });

class FinancialDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lineItems: [],
      attributes: {},
      isEdit: false,
      documentId: null,
      lineItemHeader: [],
      colCurrency: {},
      checkOnlyOnce: true,
      paymentTermOptions: [
        {
          label: "Net 15",
          value: "Net 15",
        },
        {
          label: "Net 30",
          value: "Net 30",
        },
        {
          label: "Net 45",
          value: "Net 45",
        },
        {
          label: "Net 60",
          value: "Net 60",
        },
        {
          label: "Net 90",
          value: "Net 90",
        },
        {
          label: "Due on receipt",
          value: "Due on receipt",
        },
      ],
      showUpdateFileDialog: false,
    };
    this.submitResponse = this.submitResponse.bind(this);
    this.handleChangeDataValue = this.handleChangeDataValue.bind(this);
    this.handleChangeLincensesValue = this.handleChangeLincensesValue.bind(this);
    this.handleDeleteLicenseRow = this.handleDeleteLicenseRow.bind(this);
  }

  componentDidMount() {
    if (this.props.document) {
      this.props.getOrgDocumentDetails(this.props.match?.params?.id, this.props.document.ID);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.getOrgDocumentDetailsProgress && prevProps.getOrgDocumentDetailsProgress) {
      if (this.props.getOrgDocumentDetailsError === null) {
        this.updateFinancialDetailsNew();
      }
    }

    if (!this.props.orgFetchDocumentsProgress && prevProps.orgFetchDocumentsProgress) {
      this.props.activeContractDetailsDocument(null);
      if (this.props.orgFetchDocuments && this.props.orgFetchDocuments.length > 0) {
        const docUrl = this.props.orgFetchDocuments[this.props.orgFetchDocuments.length - 1].URL;
        setTimeout(() => {
          this.props.activeContractDetailsDocument(docUrl);
        }, 300);
      }
    }
    if (this.props.activeContractDocument !== prevProps.activeContractDocument) {
      this.props.getOrgDocumentDetails(this.props.match?.params?.id, this.props.document.ID);
    }

    if (!this.props.updateContractDetailsProg && prevProps.updateContractDetailsProg) {
      this.props.fetchOrgFinanceDetails(this.props.match?.params?.id);
      if (this.props.updateContractDetailsError === null) {
        this.props.showSnackBar("Financial Details Updated Successfully", "success", 3000);
      }
    }

    if (!this.props.addContractDetailsProg && prevProps.addContractDetailsProg) {
      this.props.fetchOrgFinanceDetails(this.props.match?.params?.id);
    }

    if (!this.props.addContractProgress && prevProps.addContractProgress) {
      if (this.props.addContractError === null) {
        this.updateFinancialForNewContract();
      }
    }
  }

  updateFinancialDetailsNew() {
    const { getOrgDocumentDetailsSuccess, document } = this.props;
    if (getOrgDocumentDetailsSuccess) {
      const attributes = {};
      let hiddenData = {};
      let lineItemHeader = [];
      if (document && document.DocumentType?.AdditionalData) {
        try {
          hiddenData = JSON.parse(document.DocumentType.AdditionalData);
        } catch (e) { }
      }
      let resAttributes = {} ;
      getOrgDocumentDetailsSuccess.Attributes?.forEach((o) => {
        resAttributes[o.Attribute] = {
            value: o.Value,
            label: o.Label,
            attribute: o.Attribute,
            type: o.Type,
            id: o.ID,
          };
        });

        documentGlobalFields.forEach((o) => {
          if (!hiddenData[[o.Attribute]]) {
            attributes[o.Attribute] = {
              value: resAttributes[o.Attribute]?.value || '',
              label: o.Label,
              attribute: o.Attribute,
              type: resAttributes[o.Attribute]?.type || o.Type,
              id: o.ID || '',
            };
            if (o.Type === "price") {
              let value = resAttributes[o.Attribute]?.value && resAttributes[o.Attribute]?.value !== "NaN" && resAttributes[o.Attribute]?.value !== undefined ? resAttributes[o.Attribute]?.value : "0";
              if (value && parseInt(value) <= 0) {
                value = "USD0";
              }
              let currency = value.substring(0, 3);
              if (currency.length < 3) {
                currency = { label: "USD", value: "USD" };
              } else {
                currency = { label: currency, value: currency };
              }
              attributes[o.Attribute].currency = currency;
              value = value.substring(3);
              if (value === "") {
                value = "0";
              }
              attributes[o.Attribute].value = parseFloat(value.trim().split(",").join("").trim()).toFixed(2);
            }
            if (o.Type === "payment_term") {
              let value = resAttributes[o.Attribute]?.value && resAttributes[o.Attribute]?.value !== "NaN" && resAttributes[o.Attribute]?.value !== undefined ? resAttributes[o.Attribute]?.value : "";
              let options;
              if (value === "" || value === "0") {
                options = [];
              } else {
                options = value.split(",").map((o) => {
                  return {
                    value: o,
                    label: o,
                  };
                });
              }
              attributes[o.Attribute].value = options;
            }
            if (o.Type === "number") {
              let value = resAttributes[o.Attribute]?.value && resAttributes[o.Attribute]?.value !== "NaN" && resAttributes[o.Attribute]?.value !== undefined ? resAttributes[o.Attribute]?.value : "0";
              if (value === "") {
                value = 0;
              }
              attributes[o.Attribute].value = parseFloat(value.trim().split(",").join("").trim()).toFixed(2);
            }
          }
        });
      

      let colCurrency = {};
      const lineItems =
        getOrgDocumentDetailsSuccess.LineItems === null
          ? []
          : getOrgDocumentDetailsSuccess.LineItems.map((item) => {
            const row = {};
            item.OrgProductFinancialDocumentLineItemAttributes.forEach((o) => {
              row[o.Attribute] = {
                value: o.Value,
                label: o.Label,
                attribute: o.Attribute,
                type: o.Type,
                id: o.ID,
              };
              lineItemHeader.push(o.Attribute);
              if (o.Type === "price") {
                let value = o.Value && o.Value !== "NaN" && o.Value !== undefined ? o.Value : "USD0";
                let currencyVal = value.substring(0, 3);
                let currency;
                if (currencyVal.length < 3) {
                  currency = "USD";
                } else {
                  currency = currencyVal;
                }
                row[o.Attribute].currency = currency;
                colCurrency[o.Attribute] = currency;
                value = value.substring(3);
                row[o.Attribute].value = parseFloat(value.trim().split(",").join("").trim()).toFixed(2);
              }
              if (o.Type === "number") {
                let value = o.Value && o.Value !== "NaN" && o.Value !== undefined ? o.Value : "0";
                if (value === "") {
                  value = 0;
                }
                row[o.Attribute].value = parseFloat(value.trim().split(",").join("").trim()).toFixed(2);
              }
            });
            return row;
          });
      lineItemHeader = uniq(lineItemHeader);
      console.log(attributes, document, "lineItemHeader");
      this.setState({ attributes, lineItems, lineItemHeader, colCurrency });
    }
  }

  handleChangeDataValue = (key, value, isCurrency) => {
    const { attributes } = this.state;
    if (isCurrency) {
      attributes[key]["currency"] = value;
    } else {
      attributes[key]["value"] = value;
    }
    this.setState({ attributes });
  };

  handleChangeLincensesValue = (index, key, value, isDate, currency) => {
    const { lineItems } = this.state;
    if (!!isDate) {
      lineItems[index][key].value = moment(value).format("MMMM-DD-YY");
    } else {
      if (currency) {
        lineItems[index][key].currency = value;
      } else {
        lineItems[index][key].value = value;
      }
    }
    this.setState({ lineItems });
  };

  handleDeleteLicenseRow = (index) => {
    const { lineItems } = this.state;
    lineItems.splice(index, 1);
    this.setState({ lineItems });
  };

  submitResponse = () => {
    let { lineItems, attributes, colCurrency } = this.state;
    // console.log(lineItems, 'lineItems')
    // console.log(attributes, 'attributes')
    attributes = Object.keys(attributes).map((key) => {
      const data = {
        attribute: attributes[key].attribute,
        label: attributes[key].label,
        type: attributes[key].type,
      };
      if (attributes[key].type === "price") {
        data.value = `${attributes[key].currency.value}${attributes[key].value}`;
      } else if (attributes[key].type === "payment_term" && attributes[key].value !== null && attributes[key].value !== "") {
        data.value = attributes[key].value.map((o) => o.value).join();
      } else {
        data.value = attributes[key].value;
      }
      data.value = String(data.value);
      return data;
    });
    const lineItemAttributes = [];
    lineItems.forEach((item) => {
      let objData = [];
      Object.keys(item).forEach((key) => {
        const data = {
          attribute: item[key].attribute,
          label: item[key].label,
          type: item[key].type,
        };
        if (item[key].type === "price") {
          data.value = `${colCurrency[key] || "USD"}${item[key].value}`;
        } else {
          data.value = item[key].value;
        }
        data.value = String(data.value);
        objData.push(data);
      });
      lineItemAttributes.push(objData);
    });
    this.props.updateFinancialDetailsData(this.props.match?.params?.id, this.props.document.ID, { attributes, lineItemAttributes: lineItemAttributes });
  };

  handleTheadCurrency = (type, value) => {
    // console.log('-------------------')
    // console.log(type, 'type')
    // console.log(value, 'value')
    // console.log(this.state.colCurrency, 'colCurrency')
    // let newT = type?.value;
    this.setState({
      colCurrency: {
        ...this.state.colCurrency,
        [type]: value,
      },
    });
  };

  addLicense(oldLineItems) {
    const data = {};
    const item = oldLineItems[0];
    Object.keys(item).forEach((key) => {
      data[key] = { ...item[key] };
      if (data[key].type === "price" || data[key].type === "number") {
        data[key].value = 0;
      } else {
        data[key].value = "";
      }
    });
    this.setState({ lineItems: [...oldLineItems, data] });
  }

  checkAttributes() {
    const { orgFinanceDetails } = this.props;
    const { attributes } = this.state;
    let isAdded = true;
    if (_.isEmpty(orgFinanceDetails) || _.isEmpty(attributes)) {
      isAdded = false;
    }
    return isAdded;
  }

  checkAndAddAttributes() {
    const { orgFinanceDetails } = this.props;
    const { attributes } = this.state;
    // console.log(attributes, 'attributes')
    // console.log(orgFinanceDetails, 'orgFinanceDetails')
    let isAdded = true;
    if (_.isEmpty(orgFinanceDetails) || _.isEmpty(attributes)) {
      isAdded = false;
    }
    if (!isAdded) {
      let data = {
        attributes: [],
        lineItems: [defaultLicense],
        name: this.props.document?.Title,
        path: this.props.document?.URL,
        type: this.props.document?.Type,
      };
      Object.keys(dataKeys).forEach((key) => {
        if (key !== "license") {
          const attribute = { ...dataKeys[key], attribute: key };
          if (attribute.type === "price") {
            attribute.value = `USD${0}`;
          }
          attribute.value = String(attribute.value);
          if (attribute.type === "date") {
            attribute.value = null;
          }
          attribute.confidence = 1;
          data.attributes.push(attribute);
        }
      });
      this.props.addNewContractDetails(this.props.match?.params?.id, data);
    }
  }

  updateFinancialForNewContract(file) {
    const { addContractSuccess } = this.props;
    // console.log(addContractSuccess, 'addContractSuccess')
    let documentId = null;
    if (addContractSuccess) {
      let detailsData = addContractSuccess;
      const attributes = {};
      let lineItemHeader = [];
      if (detailsData === undefined) {
        this.setState({ attributes, lineItemHeader, lineItems: {} });
        return true;
      }
      detailsData.OrgProductFinancialDocumentAttributes.forEach((o) => {
        attributes[o.Attribute] = {
          value: o.Value,
          label: o.Label,
          type: o.Type,
          id: o.ID,
        };
        if (o.Type === "price") {
          let value = o.Value && o.Value !== "NaN" && o.Value !== undefined ? o.Value : "USD0";
          attributes[o.Attribute].currency = value.substring(0, 3);
          value = value.substring(3);
          attributes[o.Attribute].value = parseFloat(value.trim().split(",").join("").trim()).toFixed(2);
        }
        if (o.Type === "number") {
          let value = o.Value && o.Value !== "NaN" && o.Value !== undefined ? o.Value : "0";
          attributes[o.Attribute].value = parseFloat(value.trim().split(",").join("").trim()).toFixed(2);
        }
        documentId = o.DocumentID;
      });
      let colCurrency = {};
      const lineItems = detailsData.OrgProductFinancialDocumentLineItems.map((item) => {
        const row = {};
        item.OrgProductFinancialDocumentLineItemAttributes.forEach((o) => {
          row[o.Attribute] = {
            value: o.Value,
            label: o.Label,
            type: o.Type,
            id: o.ID,
          };
          lineItemHeader.push(o.Attribute);
          if (o.Type === "price") {
            let value = o.Value && o.Value !== "NaN" && o.Value !== undefined ? o.Value : "USD0";
            row[o.Attribute].currency = value.substring(0, 3);
            colCurrency[o.Attribute] = value.substring(0, 3);
            value = value.substring(3);
            row[o.Attribute].value = parseFloat(value.trim().split(",").join("").trim()).toFixed(2);
          }
          if (o.Type === "number") {
            let value = o.Value && o.Value !== "NaN" && o.Value !== undefined ? o.Value : "0";
            row[o.Attribute].value = parseFloat(value.trim().split(",").join("").trim()).toFixed(2);
          }
        });
        return row;
      });
      lineItemHeader = uniq(lineItemHeader);
      this.setState({ attributes, lineItems, documentId, lineItemHeader });
    }
  }

  onUploadContractFile = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var formData = new FormData();
      if (file) {
        var reader = new FileReader();
        reader.onloadend = () => this.setState({ imgSrc: reader.result });
        reader.readAsDataURL(file);
      }
      formData.append("folder", `/orgProduct/contractsAndAgreements/${this.props.match?.params?.id}`);
      const timestamp = new Date().getTime();
      formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf(".") + 1)}`);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;

      axios
        .post(url, formData, {
          headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response, "SuccessResponse");
          this.attachFileToDocument(response?.data?.filepath);
        })
        .catch((err) => {
          if (err.response) {
            this.props.showSnackBar(err.response.data.error, "error", 3000);
          } else if (err.request) {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          } else {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          }
        });
    }
  };

  attachFileToDocument(filePath) {
    this.props.attachFileToDocument(this.props.match?.params?.id, this.props.document?.ID, {
      url: filePath,
    });
  }

  render() {
    const { classes, orgFinanceDetails, userType, isEditable, document } = this.props;
    const { attributes, lineItems, lineItemHeader, isEdit } = this.state;
    // let isAddedAttributes = this.checkAttributes()
    // if (!isAddedAttributes) {
    //   return null;
    // }
    let zIndexForItems = 0;

    if (this.props.getOrgDocumentDetailsProgress) {
      return (
        <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
          <CircularProgress />
        </div>
      );
    }

    console.log(orgFinanceDetails, "orgFinanceDetails-22");
    console.log(document, "document-22");

    return (
      <div className={classes.root} id={this.props.sectionName}>
        {(userType === "OrgAdmin" || isEditable) && !isEdit && ![null, undefined, "", {}].includes(orgFinanceDetails) && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div>

            </div>
            <div>
              {/* {document && (document?.URL === "" || document?.URL === null || document?.URL === undefined) && (
                <div className={classes.newUserBtn}>
                  <label htmlFor="uploadFileContractV2" className={classes.linkText}>
                    + Upload File
                    <input
                      id="uploadFileContractV2"
                      accept=".png, .jpg, .jpeg,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      className={classes.uploadImage}
                      onChange={this.onUploadContractFile}
                      type="file"
                    />
                  </label>
                </div>
              )} */}
              <SqureButton cls={classes.actionBtn} onClick={() => this.setState({ showUpdateFileDialog: true })}>
                Update File
              </SqureButton>
              &nbsp;&nbsp;&nbsp;
              <SqureButton cls={classes.actionBtn} onClick={() => this.setState({ isEdit: true })}>
                Edit
              </SqureButton>
            </div>
          </div>
        )}
        <div className={classes.container} id={'portal_' + document?.ID}>
          <div className={classes.formContainer}>
            <Grid container spacing={2}>
              {attributes &&
                Object.keys(attributes).map((k, index) => {
                  const obj = attributes[k];
                  zIndexForItems++;
                  let newZIndex = Object.keys(attributes).length + 10 - zIndexForItems;

                  return (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={obj.id}>
                      <div className={classes.label}>
                        <label>{obj.label}</label>
                      </div>
                      <div>
                        {!isEdit && (
                          <>
                            {["date"].includes(obj.type) && <Typography>{!["", "null", undefined, null, NaN].includes(obj.value) ? format(new Date(obj.value), "MMM do yyyy") : ""}</Typography>}
                            {!["date"].includes(obj.type) && obj.type !== "payment_term" && (
                              <>
                                {obj.currency ? (
                                  <Typography>{`${obj.currency.label} ${obj.value}`}</Typography>
                                ) : (
                                  <>
                                    {"supplier_website" === k ? (
                                      <a href={obj.value} target="_blank" rel="noreferrer">
                                        <Typography className={classes.link}>{obj.value}</Typography>
                                      </a>
                                    ) : (
                                      <Typography>{obj.value}</Typography>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {obj.type === "payment_term" && <>{obj.value.map((o) => o.value).join()}</>}
                          </>
                        )}
                        {isEdit && ["number", "price", "text"].includes(obj.type) && (
                          <FormInputField
                            newZIndex={newZIndex}
                            classes={classes}
                            obj={obj}
                            key={`FormInputField-${obj.type}-${obj.id}`}
                            isCurrency={obj.type.includes("price")}
                            onChange={(value) => this.handleChangeDataValue(k, value)}
                            onChangeCurrency={(value) => this.handleChangeDataValue(k, value, true)}
                          />
                        )}
                        {isEdit && obj.type === "payment_term" && (
                          <div className={classes.customTypeFields}>
                            <FormControl className={classes.formSelect} style={{ zIndex: newZIndex }}>
                              <Select
                                isClearable
                                isMulti={true}
                                labelId="singleSelect-select-label"
                                id="singleSelect-select"
                                isDisabled={false}
                                value={obj.value}
                                onChange={(e) => {
                                  // console.log(e, 'e.target.value')
                                  this.handleChangeDataValue(k, e);
                                }}
                                styles={customStyles}
                                fullWidth
                                options={this.state.paymentTermOptions}
                                menuPortalTarget={document.body}
                                // menuPosition={'fixed'}
                                menuPlacement={"auto"}
                                placeholder={"Select a Option"}
                              ></Select>
                            </FormControl>
                          </div>
                        )}

                        {isEdit && ["date"].includes(obj.type) && (
                          <div className={classes.datePicker}>
                            <DatePicker
                              className={classes.datePicker}
                              selected={!["", "null", undefined, null, NaN].includes(obj.value) ? new Date(obj.value) : null}
                              popperClassName={classes.calendarPopout}
                              disabled={false}
                              onChange={(date) => {
                                this.handleChangeDataValue(k, new Date(date).toISOString());
                              }}
                              dateFormat="MMM do yyyy"
                              portalId="evMetaDialog"
                              minDate={null}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              customInput={
                                <OutlinedInput
                                  readOnlyInput={true}
                                  startAdornment={
                                    <InputAdornment>
                                      <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                    </InputAdornment>
                                  }
                                />
                              }
                            />
                          </div>
                        )}
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
            <br />
            {lineItems && lineItems.length > 0 && (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {lineItemHeader.map((th, index) => {
                      let width = "20%";
                      let textAlign = "flex-start";
                      if ([1, 2].includes(index)) {
                        width = "12%";
                      } else if ([3].includes(index)) {
                        width = "6%";
                      } else if ([4, 5].includes(index)) {
                        width = "25%";
                        textAlign = "flex-end";
                      }
                      return (
                        <TableCell key={`head-${index}`} style={{ padding: isEdit ? "10px 5px 10px 0px" : "10px 0px", width }}>
                          <Grid container alignItems="center" className={classes.priceContainer} justifyContent={textAlign}>
                            <div>
                              <strong>{th.toUpperCase().split("_").join(" ")}</strong>
                            </div>
                            {isEdit && th.includes("price") && (
                              <>
                                &nbsp;
                                <FormInputFieldLineItem
                                  classes={classes}
                                  obj={{
                                    type: "price",
                                    currency: this.state.colCurrency[th] || "USD",
                                    // currency: this.state.colCurrency[th] ? { label: this.state.colCurrency[th], value: this.state.colCurrency[th] } : { label: "USD", value: 'USD' }
                                  }}
                                  key={`price-${index}`}
                                  isCurrency={true}
                                  isOnlyCurrency={true}
                                  // onChange={(value) => this.handleChangeLincensesValue(il, k, value)}
                                  onChangeCurrency={(value) => {
                                    this.handleTheadCurrency(th, value);
                                  }}
                                />
                              </>
                            )}
                          </Grid>
                        </TableCell>
                      );
                    })}
                    {isEdit && <TableCell key="body-edit" style={{ padding: 5 }}></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lineItems.map((item, index) => (
                    <TableRow key={`body-${index}`} >
                      {item &&
                        Object.keys(item).map((k, i) => {
                          const obj = item[k];
                          let width = "20%";
                          let textAlign = "left";
                          if ([1, 2].includes(i)) {
                            width = "12%";
                          } else if ([3].includes(i)) {
                            width = "6%";
                          } else if ([4, 5].includes(i)) {
                            width = "25%";
                            textAlign = "right";
                          }
                          return (
                            <TableCell key={`body-row-${i}`} style={{ padding: isEdit ? "10px 5px 10px 0px" : "10px 0px", width, textAlign }}>
                              {!isEdit && (
                                <>
                                  {["date"].includes(obj.type) ? (
                                    <Typography>{!["", "null", undefined, null, NaN].includes(obj.value) ? format(new Date(obj.value), "MMM do yyyy") : ""}</Typography>
                                  ) : (
                                    <>
                                      {obj.currency ? (
                                        <Typography>{`${obj.currency} ${[undefined, null, "NaN"].includes(obj.value) ? 0 : obj.value}`}</Typography>
                                      ) : (
                                        <Typography>{obj.value || ""}</Typography>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {isEdit && ["number", "price", "text"].includes(obj.type) && (
                                <FormInputFieldLineItem
                                  classes={classes}
                                  obj={obj}
                                  onChange={(value) => this.handleChangeLincensesValue(index, k, value)}
                                  onChangeCurrency={(value) => this.handleChangeLincensesValue(index, k, value, false, true)}
                                />
                              )}
                              {isEdit && ["date"].includes(obj.type) && (
                                <div className={classes.datePicker}>
                                  <DatePicker
                                    selected={!["", "null", undefined, null, NaN].includes(obj.value) ? new Date(obj.value) : null}
                                    onChange={(date) => {
                                      this.handleChangeLincensesValue(index, k, date, true);
                                    }}
                                    popperPlacement="top-start"
                                    popperClassName={classes.date_popper}
                                    dateFormat="MMM do yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className={classes.datePicker}
                                    portalId={'portal_' + document?.ID}
                                    customInput={
                                      <OutlinedInput
                                        value={obj.value}
                                        readOnlyInput={true}
                                        startAdornment={
                                          <InputAdornment>
                                            <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                          </InputAdornment>
                                        }
                                      />
                                    }
                                  />
                                </div>
                              )}
                            </TableCell>
                          );
                        })}
                      {isEdit && lineItems && lineItems.length > 1 && (
                        <TableCell style={{ padding: 5 }}>
                          <Image
                            src={deleteIcon}
                            alt="delete"
                            className={classes.deleteImage}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.handleDeleteLicenseRow(index);
                            }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
          <Grid container direction="row-reverse" justifyContent="space-between" style={{ padding: "10px 24px", marginTop: 20 }}>
            {this.props.updateContractDetailsProg && <CircularProgress />}
            {isEdit && !this.props.updateContractDetailsProg && (
              <div>
                <SqureButton
                  cls={classes.actionBtn}
                  variant={"outlined"}
                  onClick={() => {
                    this.setState({ isEdit: false }, () => {
                      this.props.activeContractDetailsDocument(this.props.activeContractDocument);
                    });
                  }}
                >
                  Cancel
                </SqureButton>
                &nbsp;&nbsp;&nbsp;
                <SqureButton
                  cls={classes.actionBtn}
                  variant={"contained"}
                  onClick={() => {
                    this.submitResponse();
                  }}
                >
                  Save
                </SqureButton>
              </div>
            )}
            {isEdit && (
              <Button className={classes.addNewButton} onClick={() => this.addLicense(lineItems)}>
                + Add Row
              </Button>
            )}
          </Grid>
        </div>
        {this.props.orgFinanceDetailsProgress && <CircularProgress />}
        {this.state.showUpdateFileDialog && <UpdateDocumentFileDialog
          hideDialog={() => {
            this.setState({ showUpdateFileDialog: false })
          }}
          document={this.props.document}
        />}

      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(FinancialDetails));

const FormInputField = ({ newZIndex, obj, classes, onChange, onChangeCurrency, isCurrency = false, isOnlyCurrency = false }) => {
  // console.log(newZIndex, 'newZIndex')
  const [value, setValue] = useState();
  useEffect(() => {
    if (obj && obj.value) {
      setValue(obj.value);
    }
  }, [obj]);
  if (obj.type === "price") {
    return (
      <>
        {/* // <Grid container alignItems="center" className={classes.currencyFormControl}> */}
        {false && isCurrency && (
          <TextField
            select
            selected={obj.currency}
            InputProps={{ disableUnderline: true }}
            value={obj.currency}
            style={{ width: 52 }}
            placeholder="Currency"
            onChange={(e) => onChangeCurrency(e.target.value)}
          >
            {currencyOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        )}

        {isCurrency && (
          <div className={classes.currencyTypeFields} style={{ display: "flex" }}>
            {isCurrency && (
              <div style={{ width: 130, marginRight: 5, zIndex: newZIndex }}>
                <Select
                  isMulti={false}
                  labelId="singleSelect-select-label"
                  id="singleSelect-select"
                  value={obj.currency}
                  disabled={false}
                  onChange={(e) => {
                    console.log(e);
                    onChangeCurrency(e);
                  }}
                  options={currencyOptions}
                  placeholder={"Select a Option"}
                  styles={currencyStyles}
                ></Select>
              </div>
            )}

            <CurrencyTextField
              // label={title}
              variant="outlined"
              placeholder={obj.label}
              value={value}
              disabled={false}
              currencySymbol={currencyCodeMap[obj.currency.value]}
              //minimumValue="0"
              style={{ textAlign: "left" }}
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              required={false}
              onChange={(e, v) => {
                setValue(v);
              }}
              onBlur={() => onChange(value)}
            />
          </div>
        )}
        {/* {!isOnlyCurrency && (
          <TextField
            style={{ flex: 1 }}
            InputProps={{ disableUnderline: true }}
            fullWidth
            value={value}
            placeholder={obj.label}
            type="number"
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => onChange(value)}
          />
        )} */}
        {/* </Grid> */}
      </>
    );
  }
  return (
    // <TextField
    //   InputProps={{ disableUnderline: true }}
    //   fullWidth
    //   value={value}
    //   placeholder={obj.label}
    //   type={obj.type}
    //   onChange={(e) => setValue(e.target.value)}
    //   className={classnames(classes.formControl, classes.formInput)}
    //   onBlur={() => onChange(value)}
    // />

    <TextField
      id="outlined-full-width"
      required={false}
      disabled={false}
      type={obj.type}
      placeholder={obj.label}
      // label={title}
      // style={{ margin: 8 }}
      fullWidth
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        // this.updateTextValue(e.target.value, true);
      }}
      onBlur={() => onChange(value)}
    />
  );
};

const FormInputFieldLineItem = ({ newZIndex, obj, classes, onChange, onChangeCurrency, isCurrency = false, isOnlyCurrency = false }) => {
  // console.log(newZIndex, 'newZIndex')
  const [value, setValue] = useState();
  useEffect(() => {
    if (obj && obj.value) {
      setValue(obj.value);
    }
  }, [obj]);
  if (obj.type === "price") {
    return (
      <>
        {/* <Grid container alignItems="center" className={classes.dd}> */}
        {isCurrency && (
          <MaterialSelect
            value={obj.currency}
            onChange={(e) => {
              onChangeCurrency(e.target.value);
            }}
          >
            {currencyOptions.map((option) => (
              <MenuItem value={option.value} style={{ fontSize: 13 }}>
                {option.label}
              </MenuItem>
            ))}
          </MaterialSelect>
          // <TextField
          //   select
          //   selected={obj.currency}
          //   InputProps={{ disableUnderline: true }}
          //   value={obj.currency}
          //   style={{ width: 52 }}
          //   placeholder="Currency"
          //   onChange={(e) => {
          //     console.log(e.target.value, 'NewwwwwwwwVal')
          //     onChangeCurrency(e.target.value)
          //   }
          //   }
          // >
          //   {currencyOptions.map((option) => (
          //     <option key={option.value} value={option.value}>
          //       {option.label}
          //     </option>
          //   ))}
          // </TextField>
        )}

        {!isOnlyCurrency && (
          <TextField
            id="outlined-full-width"
            required={false}
            disabled={false}
            placeholder={obj.label}
            // label={title}
            // style={{ margin: 8 }}
            fullWidth
            variant="outlined"
            type={"number"}
            InputLabelProps={{
              shrink: true,
            }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={() => onChange(value)}
          />
        )}
      </>
    );
  }
  return (
    <TextField
      id="outlined-full-width"
      required={false}
      disabled={false}
      type={obj.type}
      placeholder={obj.label}
      fullWidth
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={() => onChange(value)}
    />
  );
};
