import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";
import AppBarV2 from "Components/Application/AppBarV2";
import { Backdrop, Typography, CircularProgress, Checkbox, ListItem, List, ListItemIcon, Popover } from "@material-ui/core";
import * as Validator from "util/Validation";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SqureButton from "Components/Common/SqureButton";
import { fetchVisualization, updateDashboard, getDashboardDetails } from "redux/visualization/action";
import { showSnackBar } from "redux/snackbar/action";
import { fetchVendorCatalogViews } from "redux/vendor/action";
import { fetchAllOrgSettings } from "redux/org/user/action";
import CommonCss from "commonCss";
import ProductSelectionDialog from "../../Evaluate/Components/Evaluation/Common/ProductSelectionDialog";
import { isEnabledEvaluate } from "util/Common";
import DashboardRender from "../Common/DashboardRender.jsx";
import AddUpdateDashboardCardDialog from "../dialogs/AddUpdateDashboardCardDialog.jsx";

const connectedProps = (state) => ({
  orgUser: state.orgUser,
  visualizations: state.visualization.visualizations,
  visualizationFetchProgress: state.visualization.visualizationFetchProgress,
  visualizationFetchError: state.visualization.visualizationFetchError,

  updateDashboardProgress: state.visualization.updateDashboardProgress,
  updateDashboardSuccess: state.visualization.updateDashboardSuccess,
  updateDashboardError: state.visualization.updateDashboardError,

  dashboardDetails: state.visualization.dashboardDetails,
  fetchDashboardDetailsProgress: state.visualization.fetchDashboardDetailsProgress,
  fetchDashboardDetailsError: state.visualization.fetchDashboardDetailsError,
});

const connectionActions = {
  fetchVisualization: fetchVisualization,
  updateDashboard: updateDashboard,
  getDashboardDetails: getDashboardDetails,
  fetchVendorCatalogViews: fetchVendorCatalogViews,
  fetchAllOrgSettings: fetchAllOrgSettings,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
    },
    root2: {
      padding: theme.spacing(4),
      paddingTop: 0,
    },
    "@global": {
      ".applicationContent": {
        padding: "0 !important",
      },
    },
    filterWrap: {
      padding: "1rem",
    },
    dashboardWrap: {
      marginTop: "1rem",
    },
    secondarySection: {
      height: 50,
      display: "flex",
      background: "white",
      alignItems: "center",
      paddingLeft: "32px",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      paddingRight: 15,
      borderBottom: "1px solid #f1f1f1",
    },
    actionBtn: {
      minWidth: "200px",
      marginLeft: 10,
    },
    workflowBtns: {
      textAlign: "right",
      display: "flex",
      gap: 15,
    },
    rowWrap: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    flexWrap: {
      display: "flex",
    },
    columnWrap: {
      display: "flex",
      // alignItems: "center",
      minWidth: 300,
    },
    columnUpdateWrap: {
      display: "flex",
      alignItems: "center",
      minWidth: 300,
    },
    paper: {
      width: "100%",
      padding: "1rem",
      position: "relative",
    },
    visualizationWrap: {
      padding: "1rem",
      paddingTop: "2rem",
    },
    label: {
      color: "#282D30",
      fontSize: theme.spacing(1.6),
    },
    widthFields: {
      height: 30,
    },
    textFields: {
      width: "90%",
      marginBottom: 10,
      "& [class*='MuiInputBase']": {
        fontSize: 14,
      },
    },
    checkbox: {
      padding: "0px !important",
    },

    chartContent: {
      padding: "10px",
      width: "100%",
    },
    contentRoot: {
      border: "1px solid #d8d8d8",
      padding: 10,
      borderRadius: 5,
      background: "#FFF",
    },
    inputLabel: {
      color: "#6C6C6C",
      fontSize: 15,
      opacity: 0.9,
      marginBottom: 5,
    },
    inputSubLabel: {
      color: "#6C6C6C",
      fontSize: 13,
      opacity: 0.9,
      marginBottom: 11,
    },
    button: {
      padding: "6px 8px",
      fontSize: 13,
      fontWeight: 400,
      width: theme.spacing(30),
      marginRight: 15,
    },
    fullSceen: CommonCss.backdrop,
  });

class DashboardBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibility: [],
      anchorEl: null,
      edit: false,
      layout: [],
      showAddUpdateCardDialog: false,
      showAddUpdateCardDialogDataIndex: null,
    };
  }

  componentDidMount() {
    this.props.fetchVendorCatalogViews();
    this.props.fetchAllOrgSettings();
    if (this.props.dashboardFor === "HOME") {
      this.props.getDashboardDetails(this.props.id);
    } else {
      this.props.fetchVisualization();
      if (this.props.match.params.id) {
        this.props.getDashboardDetails(this.props.match.params.id);
      } else {
        this.props.showSnackBar("Something went wrong", "error", 3000);
        this.props.history.push(`/app/dashboard-v2/list`);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.updateDashboardProgress && prevProps.updateDashboardProgress) {
      if (this.props.updateDashboardSuccess) {
        this.props.getDashboardDetails(this.props.match.params.id);
        this.props.showSnackBar("Updated successfully", "success", 3000);
      } else {
        this.props.showSnackBar("Failed to update", "error", 3000);
      }
    }

    if (!this.props.fetchDashboardDetailsProgress && prevProps.fetchDashboardDetailsProgress) {
      if (this.props.dashboardDetails) {
        let data = [];
        let edit = true;
        if (Validator.isJson(this.props.dashboardDetails?.configuration)) {
          data = JSON.parse(this.props.dashboardDetails?.configuration, function (key, value) {
            return value === "Infinity" ? Infinity : value;
          });
          edit = false;
        }

        this.setState({
          layout: data,
          visibility: [1],
          edit: edit,
        });
      } else {
        this.props.showSnackBar("Failed to fetch dashboard details", "error", 3000);
      }
    }
  }

  RemoveWidget = (i) => {
    let layout = [...this.state.layout];
    layout.splice(i, 1);
    this.setState({ layout: layout });
  };

  updateFieldValue = (rowIndex, columnIndex, key, value) => {
    let allRows = [...this.state.rowsData];
    let currentColumns = allRows[rowIndex].dashboardColumns;
    let currentColumn = { ...currentColumns[columnIndex] };
    currentColumn[key] = value;
    allRows[rowIndex].dashboardColumns[columnIndex] = currentColumn;

    this.setState({ rowsData: allRows });
  };

  updateVisibility = (value) => {
    let newVisibility = [...this.state.visibility];
    if (newVisibility.includes(value)) {
      let index = newVisibility.indexOf(value);
      newVisibility.splice(index, 1);
    } else {
      newVisibility.push(value);
    }
    this.setState({ visibility: newVisibility });
  };

  censor(key, value) {
    // eslint-disable-next-line eqeqeq
    if (value == Infinity) {
      return "Infinity";
    }
    return value;
  }

  publishDashboard = (status) => {
    let data = {
      name: this.props.dashboardDetails.name,
      configuration: JSON.stringify(this.state.layout, this.censor),
      // visibility: this.state.visibility,
      visibility: 1,
      status: status,
      userTeamIds: this.props.dashboardDetails.userTeamIds,
    };
    this.props.updateDashboard(this.props.match.params.id, data);
  };

  getConfigValue(array, key, value) {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) {}
      return result.trim() || "";
    }
  }

  onLayoutChange = (dataGrids) => {
    let layout = [...this.state.layout];
    dataGrids.forEach((grid) => {
      let index = layout.findIndex((item) => item?.dataGrid?.i === grid.i);
      if (index > -1) {
        layout[index] = { ...layout[index], dataGrid: grid };
      }
    });
    console.log(dataGrids, layout, "layoutsss3");
    this.setState({ layout: layout });
  };

  render() {
    const { classes, visualizations, dashboardDetails } = this.props;
    const { visibility, layout, showAddUpdateCardDialog, showAddUpdateCardDialogDataIndex, edit } = this.state;
    const visualizationLists = [];
    visualizations?.data?.forEach((item) => {
      let config = {};
      if (Validator.isJson(item.configuration)) {
        config = JSON.parse(item.configuration);
      }
      visualizationLists.push({
        type: config.chartType,
        label: item.name,
        value: item.visualizationID,
      });
    });

    let isRequestType = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "REQUEST_TYPES");
    let requestTypesData = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "REQUEST_TYPES") || [];
    let requestTypes = [];
    if (
      isRequestType &&
      Validator.isJson(requestTypesData) &&
      requestTypesData !== undefined &&
      requestTypesData !== "NULL" &&
      requestTypesData !== null &&
      requestTypesData !== "null" &&
      requestTypesData.length > 0
    ) {
      requestTypes = JSON.parse(requestTypesData);
    }

    return (
      <div className={classes.container}>
        {this.props.fetchDashboardDetailsProgress && (
          <Backdrop className={classes.fullSceen} open={true} transitionDuration={100}>
            <CircularProgress />
          </Backdrop>
        )}
        {this.props.dashboardFor !== "HOME" && (
          <AppBarV2
            title={dashboardDetails?.name}
            newHeader={true}
            withBack={true}
            isEditableTitle={true}
            submitTitle={(value) => {
              this.props.updateDashboard(this.props.match.params.id, {
                name: value,
              });
            }}
            isSubmittingTitle={this.props.updateDashboardProgress}
          />
        )}
        {this.props.dashboardFor !== "HOME" && (
          <div className={classes.secondarySection}>
            <div className={classes.workflowBtns}>
              {edit ? (
                <>
                  <SqureButton
                    cls={classes.actionBtn}
                    variant={"outlined"}
                    onClick={(e) => {
                      this.publishDashboard(1);
                    }}
                  >
                    Save as Draft
                  </SqureButton>
                  <SqureButton
                    className={classes.actionBtn}
                    variant={"contained"}
                    onClick={(e) => {
                      this.publishDashboard(2);
                    }}
                  >
                    {dashboardDetails.status === 2 ? "Published" : "Publish"}
                  </SqureButton>
                  <SqureButton
                    className={classes.actionBtn}
                    variant={"contained"}
                    startIcon={<DashboardIcon />}
                    onClick={() => {
                      this.setState({ showAddUpdateCardDialog: true });
                    }}
                  >
                    Add New
                  </SqureButton>
                </>
              ) : (
                <SqureButton
                  className={classes.actionBtn}
                  variant={"contained"}
                  onClick={() => {
                    this.setState({ edit: true });
                  }}
                >
                  Edit
                </SqureButton>
              )}
            </div>
          </div>
        )}
        <Popover id="Popover-one" open={this.state.open} anchorEl={this.state.anchorEl} onClose={() => this.setState({ anchorEl: null, open: null })} placement={"bottom"}>
          <List>
            <ListItem
              classes={{
                button: classes.listItem,
              }}
            >
              <Typography variant="inherit">Visible For</Typography>
            </ListItem>
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={(e) => {
                e.stopPropagation();
                this.updateVisibility(1);
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <Checkbox className={classes.checkbox} checked={visibility.includes(1)} />
              </ListItemIcon>
              <Typography variant="inherit">Admin</Typography>
            </ListItem>
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={(e) => {
                e.stopPropagation();
                this.updateVisibility(2);
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <Checkbox className={classes.checkbox} disabled={false} checked={visibility.includes(2)} />
              </ListItemIcon>
              <Typography variant="inherit">End User</Typography>
            </ListItem>
            <ListItem
              disabled={false}
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={(e) => {
                e.stopPropagation();
                this.updateVisibility(3);
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <Checkbox className={classes.checkbox} disabled={false} checked={visibility.includes(3)} />
              </ListItemIcon>
              <Typography variant="inherit">Teams</Typography>
            </ListItem>
          </List>
        </Popover>
        <div className={classes.productMain}>
          <div className={this.props.dashboardFor === "HOME" ? classes.root2 : classes.root}>
            <DashboardRender
              onLayoutChange={this.onLayoutChange}
              layout={layout}
              AddWidget={this.AddWidget}
              RemoveWidget={this.RemoveWidget}
              editMode={edit}
              onEdit={(index) => {
                this.setState({
                  showAddUpdateCardDialog: true,
                  showAddUpdateCardDialogDataIndex: index,
                });
              }}
            />
          </div>
        </div>
        <ProductSelectionDialog isRequestType={isRequestType && requestTypes.length > 0 ? true : false} requestTypes={requestTypes} />
        {showAddUpdateCardDialog && (
          <AddUpdateDashboardCardDialog
            handleSubmit={(data) => {
              let newLayout = [...layout];
              if (showAddUpdateCardDialogDataIndex !== null) {
                newLayout[showAddUpdateCardDialogDataIndex] = data;
              } else {
                newLayout.push(data);
              }
              this.setState({ layout: newLayout, showAddUpdateCardDialog: false, showAddUpdateCardDialogDataIndex: null });
            }}
            onClose={() => {
              this.setState({ showAddUpdateCardDialog: false, showAddUpdateCardDialogDataIndex: null });
            }}
            visualizationLists={visualizationLists}
            showAddUpdateCardDialogData={layout[showAddUpdateCardDialogDataIndex]}
            layout={layout}
            dashboardDetails={dashboardDetails?.id}
          />
        )}
      </div>
    );
  }
}

DashboardBuilder.defaultProps = {
  dashboardFor: "EDIT_VIEW",
};
export default connector(compose(withStyles(styles), withLastLocation)(DashboardBuilder));
