import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core"
import PropTypes from 'prop-types';
import classnames from "classnames";

const styles = (theme) => createStyles({
    btn:{
        borderRadius:theme.spacing(0.5),
        padding:theme.spacing(1,3.8)
    },
    outlined:{
        color:"#707070",
        borderColor:"#707070",
        "&:hover":{
            color:"#707070",
            borderColor:"#707070",
        }
    },
    contained:{
        backgroundColor:"#3C3C3C",
        "&:hover":{
            backgroundColor:"#3C3C3C",
        }
    },
    activeBlock:{
        borderColor:'#398AF5',
    }
});

class SqureButton extends React.Component {
    render() {
        var classes = this.props.classes;
        return  <Button {...this.props} variant={this.props.variant} color="primary" className={classnames(classes.btn,{
            [classes.outlined]:this.props.variant === "outlined",
            [classes.contained]:this.props.variant === "contained",
            [this.props.cls]:this.props.cls.length > 0
        })} onClick={this.props.onClick} startIcon={this.props.startIcon} endIcon={this.props.endIcon}>
                    {this.props.children}
                </Button>
    }
}

SqureButton.defaultProps = {
    variant:"contained",
    startIcon:null,
    endIcon:null,
    onClick:null,
    cls:"",
}

SqureButton.propTypes = {
    variant:PropTypes.oneOf(['outlined','contained']),
    startIcon:PropTypes.element,
    endIcon:PropTypes.element,
    onClick:PropTypes.func,
    cls:PropTypes.string
};

export default withStyles(styles)(SqureButton)