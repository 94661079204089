import React, { useEffect, useState } from 'react';
import classnames from 'classnames'
import { generate } from 'shortid';
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from '@material-ui/core';
import WinnerTrophy from "assets/images/winner_trophy.svg"
import Image from 'Components/Common/image.jsx'
import SqureButton from 'Components/Common/SqureButton';
import OverallScoreChart from "./overallScoreChart";
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';
import DefaultImage from "assets/images/evaluation/default.svg"
import { setSummaryType } from "redux/workflow/action";

const styles = (theme) =>
	createStyles({
		label: {
			color: '#20253A',
			fontFamily: 'Poppins',
			fontWeight: '600',
			fontSize: '1.25rem',
			paddingBottom: theme.spacing(2)
		},
		productContainer: {
			paddingTop: theme.spacing(4)
		},
		productBoxContainer: {
			display: 'grid',
			width: '100%',
			gap: '20px',
			gridTemplateColumns: 'repeat(4, 4fr)',
			[theme.breakpoints.down('md')]: {
				gridTemplateColumns: 'repeat(3,3fr)',
			},
			[theme.breakpoints.down('sm')]: {
				gridTemplateColumns: 'repeat(2, 2fr)',
			},
			[theme.breakpoints.down('xs')]: {
				gridTemplateColumns: 'repeat(1, 1fr)',
			},
		},
		topSection: {
			background: '#FFFFFF',
			borderRadius: 5,
			padding: 30,
		},
		productBox: {
			borderRadius: 5,
			border: '1px solid transparent',
			'&:hover': {
				border: '1px solid #007BF7'
			}
		},
		boxPadding: {
			padding: 16
		},
		boxHeader: {
			borderBottom: '1px solid #EBEBEB',
		},
		boxHeaderText: {
			color: '#20253A',
			fontSize: 18,
			fontWeight: 600,
		},
		productEvaluation: {
			marginBottom: 20,
			justifyContent: 'space-between'
		},
		prodImage: {
			width: 24,
			height: 'auto',
		},
		prodName: {
			marginLeft: 10,
			marginRight: 10,
			color: '#20253A',
			fontSize: 12,
			fontWeight: 600,
			flex: 1,
			wordBreak: 'break-all',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap'
		},
		progressContainer: {
			flex: 1,
			background: '#F2F2F2 0% 0% no-repeat padding-box',
			boxShadow: '0px 3px 14px #00000012',
			borderRadius: 5
		},
		progressbar: {
			height: 8,
			borderRadius: 5
		},
		productName: {
			maxWidth: '45%',
		},
		progressLabel: {
			width: 32,
			color: '#6b6565',
			fontSize: 10,
			fontWeight: 'normal',
			marginLeft: 10,
			textAlign: 'end'
		},
		topperName: {
			color: '#20253A',
			fontSize: 15,
			fontWeight: 600,
		},
		topperSection: {
			justifyContent: 'space-between',
			[theme.breakpoints.down('sm')]: {
				display: 'block'
			},
			[theme.breakpoints.down('xs')]: {
				display: 'block'
			},
		},
		summaryChartContainer: {
			padding: '20px 20px 0px 0px',
		},
		legend: {
			color: '#20253A',
			fontSize: 12,
			fontWeight: 600,
			marginBottom: 10,
		},
		legendBar: {
			width: 50,
			height: 8,
			borderRadius: 5,
		},
		low: {
			backgroundColor: '#cb3837'
		},
		medium: {
			backgroundColor: '#fec93e'
		},
		high: {
			backgroundColor: '#63ac67'
		},
		optionsHide: {
			opacity: 0.2,
			pointerEvents: 'none',
		}
	});

const connectedProps = (state) => ({
	evaluation: state.evaluationMetaData.evaluation,
});

const connectionActions = {
	setSummaryType: setSummaryType,
}

var connector = connect(connectedProps, connectionActions);

const ProductNew = ({ response, topper, classes, isWorkFlow, history, match, isReport = false, evaluation, productScoreMap, setSummaryType, criteriaResponsesCountMap }) => {
	const [criterias, setCriterias] = useState([])

	console.log(criteriaResponsesCountMap, 'criteriaResponsesCountMap')
	useEffect(() => {
		if (response && response.criterias) {
			setCriterias(response.criterias)
		}
	}, [response])

	const getBackgroundColor = (score) => {
		let color = '#cb3837'
		if (score >= 75) color = '#63ac67'
		else if (score >= 25) color = '#fec93e'
		return color
	}

	const renderTopperLabel = () => {
		const product = response.evaluation !== null && response.evaluation.Products.find(product => (topper === product.ID))
		if (!product) return null
		return (
			<Grid container alignItems="center" direction="row">
				<Image alt="Winer" src={WinnerTrophy} />&nbsp;&nbsp;&nbsp;&nbsp;
				<Image alt="product" width="24px" height="auto" src={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo} className={classes.productImg} />&nbsp;&nbsp;&nbsp;&nbsp;
				<Typography className={classes.topperName}>{product.Name}</Typography>
			</Grid>
		)
	}

	const renderProgressBarWithLabel = (criteriaId, productId, maxScore) => {
		var criteria_score = 0;
		const scores = response.scores;

		if (scores[productId] !== undefined && scores[productId][criteriaId] !== undefined) {
			var score_obj = scores[productId][criteriaId];
			if (score_obj.totalOpted > 0 && score_obj.totalMax > 0) {
				criteria_score = parseFloat((parseFloat(score_obj.totalOpted) / parseFloat(score_obj.totalMax)) * 100).toFixed(1)
			}
		}

		var criteriaAbsoluteScore = ((Math.round(criteria_score) * maxScore) / 100);
		if (isNaN(criteriaAbsoluteScore)) {
			criteriaAbsoluteScore = 0;
		}

		return (
			<>
				<div className={classes.progressContainer}>
					<div className={classes.progressbar} style={{ width: `${criteria_score}%`, backgroundColor: getBackgroundColor(criteria_score) }}></div>
				</div>
				<Typography component="p" className={classes.progressLabel}>{criteria_score}%</Typography>
				<Typography component="p" className={classes.progressLabel}>{criteriaAbsoluteScore + '/' + maxScore}</Typography>
			</>
		)
	}

	const hideAnwserOptionFn = (rule, product, requirements) => {
		let isShow = false;
		const requirementsObj = response.userReponses[product.ID];
		if (requirementsObj) {
			for (let requirement in requirementsObj) {
				const response = { [requirement]: [] };
				requirementsObj[requirement].UserOptions.map((option) => {
					const reqObj = requirements.filter(req => req.id === parseInt(requirement));
					if (reqObj && reqObj[0]?.answer_type === 4) {
						response[requirement].push(option.Answer);
					} else {
						response[requirement].push(option.OptionID);
					}
					return option;
				});
				try {
					if (eval(rule.JsExpression)) {
						isShow = true;
						break;
					}
				} catch (e) { }
			}
		}
		return isShow;
	}

	const hideAllAnwserOptions = (renderCriteria, product) => {
		// let isShow = true;
		// criterias && criterias.forEach((criteria) => {
		// 	for (let i = 0; i < criteria.rules.length; i++) {
		// 		let rule = criteria.rules[i];
		// 		if (renderCriteria.id === parseInt(rule.ActionData)) {
		// 			isShow = false;
		// 			if (hideAnwserOptionFn(rule, product, criteria.requirements)) {
		// 				isShow = true;
		// 				break;
		// 			}
		// 		}
		// 	}
		// });
		return true;
	}
	if (response && response.criterias === null) return null;

	var maxScore = evaluation !== undefined && evaluation !== null && evaluation?.MaxScore && evaluation?.MaxScore !== null && evaluation?.MaxScore !== '' ? evaluation.MaxScore : 5;

	return (
		<>
			{/* <Typography component="p" className={classes.label}>Overall Score</Typography> */}

			<div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
				<div>
					<Typography variant="h5" className={classes.title}>Overall Score</Typography>
				</div>
				{false && process.env.REACT_APP_ENV === 'dev' && <div style={{ textAlign: 'right', flex: 1 }}>
					<SqureButton
						cls={classes.actionBtn}
						variant={"outlined"}
						onClick={() => { setSummaryType('WORKFLOW_SUMMARY') }}>
						Project Summary
					</SqureButton>
				</div>}
			</div>

			<div className={classes.topSection}>
				<Grid container className={classes.topperSection} direction="row">
					<Grid item style={{ flex: 1, marginBottom: 10 }}>
						{renderTopperLabel()}
					</Grid>
					{!isReport && (
						<Grid item>
							<SqureButton
								variant="outlined"
								onClick={() => {
									if (match && match.params && match.params.evaluation_id) {
										history.push(`/app/evaluate/${match.params.evaluation_id}/report/workflow/${match.params.workflow_id}/component/${match.params.component_id}?isWorkflow=true`)
										// history.push(`/app/evaluate/${match.params.evaluation_id}/report`)
									}
								}}
							>
								View Report
							</SqureButton>
						</Grid>
					)}
				</Grid>
				<Grid alignItems="center" justifyContent="space-between" className={classes.summaryChartContainer} container>
					<Grid item xs={10}>
						<OverallScoreChart
							getBackgroundColor={score => getBackgroundColor(score)}
							products={response.evaluation.Products}
							scores={response.scores}
							maxScore={maxScore}
							productScoreMap={productScoreMap}
						/>
					</Grid>
					<Grid item>
						<Grid container alignItems="center" className={classes.legend}>
							<div className={classnames(classes.legendBar, classes.low)}></div>&nbsp;&nbsp;&nbsp;0-25%
						</Grid>
						<Grid container alignItems="center" className={classes.legend}>
							<div className={classnames(classes.legendBar, classes.medium)}></div>&nbsp;&nbsp;&nbsp;26-75%
						</Grid>
						<Grid container alignItems="center" className={classes.legend}>
							<div className={classnames(classes.legendBar, classes.high)}></div>&nbsp;&nbsp;&nbsp;76-100%
						</Grid>
					</Grid>
				</Grid>
			</div>
			<div className={classes.productContainer}>
				<Typography component="p" className={classes.label}>Scores by Criteria</Typography>
				<div className={classes.productBoxContainer}>
					{criterias !== null && criterias.map((criteria) => {
						const crespondable = criteriaResponsesCountMap && criteriaResponsesCountMap[criteria.id] && criteriaResponsesCountMap[criteria.id] > 0 ? true : false;
						if (!crespondable) {
							return null;
						} else {
							return <Grid item className={classes.productBox} style={{ backgroundColor: 'white' }} key={generate()}>
								<Grid item container alignItems="center" className={classnames(classes.boxHeader, classes.boxPadding)}>
									<Typography component="p" className={classes.boxHeaderText}>{criteria.name}</Typography>
								</Grid>
								<Grid item className={classes.boxPadding}>
									{response.evaluation !== null && response.evaluation.Products.map(product => {
										// const crespondable = hideAllAnwserOptions(criteria, product);
										return (
											<Grid
												container direction="row"
												alignItems="center" key={generate()}
												className={classnames(classes.productEvaluation, crespondable ? {} : classes.optionsHide)}
											>
												<Grid item container direction="row" alignItems="center" key={generate()} className={classes.productName}>
													<Image alt="product" width="24px" height="auto" src={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo} className={classes.productImg} />

													<Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{product.Name}</span>} arrow>
														<Typography component="p" className={classes.prodName}>{product.Name}</Typography>
													</Tooltip>
												</Grid>
												{renderProgressBarWithLabel(criteria.id, product.ID, maxScore)}
											</Grid>
										)
									})}
								</Grid>
							</Grid>
						}

					})}
				</div>
			</div>
		</>
	);
}

export default connector(compose(
	withRouter,
	withStyles(styles)
)(ProductNew));

// export default React.memo(withStyles(styles)(ProductNew));