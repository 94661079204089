import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, Collapse, IconButton, Typography, Card } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
// import { getRequirementType } from 'util/RequirementTypeOptions';
import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";
import { CommonFn } from "services/commonFn";
// components
// assets
import ReqQuestionUpdate from "../Common/ReqQuestionUpdate";
import ReqAnswerUpdate from "../Common/ReqAnswerUpdate";
import Response from "../Common/Response";

const connectedProps = (state) => ({
  addReqToSellerLibraryProgress: state.sellerEvaluate.addReqToSellerLibraryProgress,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      flex: 1,
      position: "relative",
      border: "1px solid #EBEBEB",
      borderRadius: 5,
      marginBottom: 15,
      backgroundColor: "#FFFFFF",
    },
    expand: {
      transform: "rotate(0deg)",
      padding: 0,
      borderRadius: 3,
      border: "1px solid #EDEDED",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    requirementExpanded: {
      border: "1px solid #49B8FA",
    },
    requirementCollapsed: {
      paddingBottom: 20,
    },
    requirementCollapse: {
      padding: "12px 16px 0 25px",
      cursor: "pointer",
    },
    requirementContainer: {
      padding: "30px 25px 10px 25px",
      width: "100%",
    },
    title: {
      fontSize: theme.spacing(2),
      color: "#333333",
      fontWeight: 600,
      width: "78%",
      "& > img": {
        display: "none",
      },
      "&:hover > img": {
        display: "initial",
      },
    },
    title2: {
      fontSize: theme.spacing(1.8),
      color: "#333333",
      fontWeight: 400,
    },

    leftPanel: {},

    editIcon: {
      color: "#999999",
    },
    "@global": {
      ".rootRequirementHight": {
        "& [class*='editor-formControl']": {
          maxHeight: 165,
        },
      },
    },
    label: {
      color: "#707070",
      fontSize: 12,
      fontWeight: 400,
    },
    Btn: {
      marginLeft: 10,
    },
    tagBlue: {
      background: "#216ba5",
      color: "#fff",
      padding: "2px 10px",
      borderRadius: 4,
    },
    tagGreen: {
      background: "#186225",
      color: "#fff",
      padding: "2px 10px",
      borderRadius: 4,
    },
    actionButton: {
      display: "flex",
      alignItems: "center",
      gap: 20,
      position: "absolute",
      top: 16,
      right: 25,
      cursor: "pointer",
      justifyContent: "flex-end",
    },
    slateWrap: {
      width: "75%",
      display: "block",
      "& [class*='editableField']": {
        "white-space": "normal !important",
      },
    },
    slateWrap2: {
      display: "block",
      "& [class*='editableField']": {
        "white-space": "normal !important",
      },
    },
  });

class Requirement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editQuestion: null,
      editAnswer: null,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (!this.props.addReqToSellerLibraryProgress && prevProps.addReqToSellerLibraryProgress) {
      if (!this.props.addReqToSellerLibraryError) {
        this.setState({
          editQuestion: null,
          editAnswer: null,
        });
      }
    }
  }

  render() {
    const classes = this.props.classes;
    const { data, reqIndex } = this.props;
    const { editQuestion, editAnswer } = this.state;
    if (data.question == null || data.question === undefined) {
      return null;
    }
    const requirement = data.question;
    const answers = data.answers;
    const reqId = data?.ref_requirement_id;

    return (
      <Grid container alignItems="center">
        <div id={`requirement_${reqId}`} className={classnames(classes.root, `requirement_${reqId}`, this.props.isOpen ? classes.requirementExpanded : classes.requirementCollapsed)}>
          {editQuestion !== requirement?.id && (
            <div className={classes.actionButton}>
              {(data.product_id === null || data.product_id === "") && <span className={classes.tagBlue}>Global</span>}
              {data.product_id !== null && data.product_id !== "" && data.product_name !== null && <span className={classes.tagGreen}>{data.product_name}</span>}

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ editQuestion: requirement?.id });
                  this.props.toggleRequirement(reqIndex);
                }}
              >
                <Image src={editIcon} />
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.handleDelete({ id: requirement?.id, type: "QUESTION" });
                }}
              >
                <Image src={deleteIcon} />
              </div>
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: this.props.isOpen,
                })}
                aria-expanded={reqId}
                aria-label="show more"
                onClick={() => this.props.toggleRequirement(this.props.isOpen ? false : reqIndex)}
              >
                <ArrowDropDownIcon />
              </IconButton>
            </div>
          )}

          {!this.props.isOpen && (
            <div className={classes.requirementCollapse} onClick={(e) => this.props.toggleRequirement(reqIndex)}>
              {/* {(data.product_id === null || data.product_id === "") && <span className={classes.tagBlue}>Global</span>}
              {data.product_id !== null && data.product_id !== "" && data.product_name !== null && <span className={classes.tagGreen}>{data.product_name}</span>} */}
              <Typography className={classes.title}>{requirement?.name}</Typography>
              <div className={classes.slateWrap}>
                <SlateReadonlyField initValue={requirement?.description} />
              </div>
              <Typography className={classes.title2}>Responses ({data.answers?.length})</Typography>
            </div>
          )}

          <Collapse in={this.props.isOpen} timeout="auto" unmountOnExit>
            <Grid container className={classes.requirementContainer}>
              {editQuestion !== requirement?.id && (
                <Grid item xs={12}>
                  <Typography className={classes.title}>{requirement?.name}</Typography>
                  <div className={classes.slateWrap2}>
                    <SlateReadonlyField initValue={requirement?.description || requirement.Description} />
                  </div>
                </Grid>
              )}
              {editQuestion === requirement?.id && (
                <Card style={{ width: "100%", marginBottom: 20 }}>
                  <ReqQuestionUpdate
                    requirement={requirement}
                    product={data}
                    onCancel={() => {
                      this.setState({ editQuestion: null });
                    }}
                  />
                </Card>
              )}
              <Typography className={classes.title} style={{ marginBottom: 15 }}>
                Responses
              </Typography>
              <Grid item className={classes.leftPanel} style={{ width: "100%" }}>
                {answers?.map((response, i) => {
                  let answerType = response?.answer_type;
                  let options = response?.options_answer;
                  let printableAnswer = null;
                  let answer = response?.answer;
                  let note = response?.note || "";
                  try {
                    if (![2, 3, 4, 14, 15].includes(answerType)) {
                      if (answer && answerType === 11) {
                        printableAnswer = CommonFn.formateDate(answer, true);
                      } else if (answer && answerType === 13) {
                        let data = answer;
                        printableAnswer = data.value + " " + data.type.label;
                      } else if (answer && answer !== null && answer !== "" && answerType === 6) {
                        printableAnswer = answer?.map((dt) => {
                          return (
                            <p>
                              <a style={{ color: "#4b86ff" }} without rel="noreferrer" target={"_blank"} href={dt?.url}>
                                {dt.name}
                              </a>
                            </p>
                          );
                        });
                      } else if (answerType === 1 || answerType === 7) {
                        note = "";
                        printableAnswer = <SlateReadonlyField initValue={answer} />;
                      } else {
                        printableAnswer = answer;
                      }
                    } else {
                      printableAnswer = options?.map((u) => u.title).join(", ") || "-";
                    }
                  } catch (e) {}
                  return (
                    <>
                      {editAnswer !== response.id && (
                        <Response
                          key={response.id}
                          showDelete={i > 0}
                          response={response}
                          note={note}
                          printableAnswer={printableAnswer}
                          onEdit={() => {
                            this.setState({ editAnswer: response.id });
                          }}
                          onDelete={() => {
                            this.props.handleDelete({ type: "ANSWER", id: response.id, questionId: requirement.id });
                          }}
                        />
                      )}
                      {editAnswer === response?.id && (
                        <Card style={{ width: "100%", marginBottom: 20 }}>
                          <ReqAnswerUpdate
                            requirement={requirement}
                            response={response}
                            product={data}
                            onCancel={() => {
                              this.setState({ editAnswer: null });
                            }}
                          />
                        </Card>
                      )}
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </Collapse>
        </div>
      </Grid>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Requirement));
