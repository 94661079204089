import { Avatar, CircularProgress, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowForward from "assets/images/arrow_forward_white.svg";
import SummaryIcon from "assets/images/icon_summary_icon.svg";
import classnames from "classnames";
// Components
import { withRouter } from 'react-router-dom';
import Image from "Components/Common/image.jsx";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import ToolTip from "Components/Common/ToolTip.jsx";
import ProductsRankingDialog from "Components/Dialogs/ProductsRankingDialog";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  toggleEvaluateNeverReviewed, toggleEvaluateView
} from "redux/applications/action";
// Dialogs
import { showProductRankingDialog } from "redux/evaluate/action";
import { fetchUserRequirementResponse } from "redux/evaluation/criteria/action";
import { showEvaluationLinkDialog } from "redux/evaluation/link/action";
import { updateEvaluationMetaData } from "redux/evaluation/metadata/action";
import { addWorkflowComponentUserResponse } from "redux/workflow/action";
import { generate } from "shortid";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  fetchCriteria,
  fetchScores, fetchRequirementTree
} from "../../../../../../../redux/evaluation/criteria/action";
import { fetchEvaluationFeedback } from "../../../../../../../redux/evaluation/feedback/action";
import { fetchEvaluationMetaData } from "../../../../../../../redux/evaluation/metadata/action";
import { downloadEvaluationReport } from "../../../../../../../redux/evaluation/report/action";
import SqureButton from "../../../../../../Common/SqureButton";
import BottomAction from "../Common/BottomAction";
import EvaluationBar from "../Common/EvaluationBar";
import ApprovalDialog from "./approvalDialog";
import { fetchEvaluationReportData } from "redux/evaluation/reportdata/action";
// Views
import Product from "./products";

import { getNextComponent } from "util/Common";

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  scores: state.evaludationCriteria.scores,
  fetchScoreProgress: state.evaludationCriteria.fetchScoreProgress,
  feedbacks: state.evaluationFeedback.feedback,
  fetchProgress: state.evaluationFeedback.feedbackFetchProgress,
  productRankingDialogOpen: state.evaluate.productRankingDialogOpen,
  colloborators: state.evaludationCriteria.colloborators,
  evaluateView: state.applications.evaluateView,
  evaluateViewId: state.applications.evaluateViewId,
  evaluateNeverReview: state.applications.evaluateNeverReview,
  evaluateNeverReviewId: state.applications.evaluateNeverReviewId,
  criterias: state.evaludationCriteria.criterias,
  criteriasFetchProgress: state.evaludationCriteria.fetchProgress,
  evaluationFetchProgress: state.evaluationMetaData.fetchProgress,
  updateProgress: state.evaluationMetaData.updateProgress,
  downloadReportProg: state.evalutionReport.downloadProg,
  userReponses: state.evaludationCriteria.userReponses,
  report: state.evalutionReport.report,
  userRole: state.authUser.user?.Role,
  user: state.authUser.user,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
  userTimeline: state.workflow.userTimeline,
  reportData: state.evaluationReportData.report,
  isLoadingReport: state.evaluationReportData.isLoading,
});

const connectionActions = {
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  fetchCriteria: fetchCriteria,
  fetchScores: fetchScores,
  fetchEvaluationFeedback: fetchEvaluationFeedback,
  showProductRankingDialog: showProductRankingDialog,
  toggleEvaluateView: toggleEvaluateView,
  toggleEvaluateNeverReviewed: toggleEvaluateNeverReviewed,
  downloadEvaluationReport: downloadEvaluationReport,
  showEvaluationLinkDialog: showEvaluationLinkDialog,
  fetchUserRequirementResponse: fetchUserRequirementResponse,
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  updateEvaluationMetaData: updateEvaluationMetaData,
  fetchEvaluationReportData: fetchEvaluationReportData,
  fetchRequirementTree: fetchRequirementTree
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    productContainer: {
      paddingTop: theme.spacing(4)
    },
    bottomNav: {
      padding: theme.spacing(1.6, 0),
    },
    sectionTitle: {
      fontSize: theme.spacing(2),
      fontWeight: 600,
      marginBottom: 10,
    },
    winner: {
      fontSize: theme.spacing(2),
      fontWeight: 600,
    },
    root: {
      marginTop: 20,
      marginRight: 20,
      "&[class*=MuiPaper-elevation]": {
        boxShadow: "none",
      },
      "& [class*=MuiCardContent-root]": {
        paddingTop: 0,
      },
    },
    feedback: {
      background: "#F4F6F8",
      borderRadius: 7,
      color: "#7A7E81",
      lineHeight: 1.5,
      padding: "10px 15px",
      marginTop: 9,
      fontSize: 12,
    },
    finishBtn: {
      "& > button": {
        paddingLeft: 40,
        paddingRight: 40,
      },
    },
    summaryContent: {
      marginBottom: 80,
    },
    loader: {
      position: "fixed",
      right: "45%",
      top: "40%",
    },
    content: {
      padding: theme.spacing(4, 4),
      background: "#F7F7F7",
      minHeight: "100vh",
    },
    colbTitle: {
      paddingBottom: theme.spacing(2),
    }
  });

class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: null,
      isApprovalFlow: true,
      eval_id: null,
      isRedirectUrl: false,
      redirectURL: null,
      isEnableApproval: false,
      isSummaryPage: true,
      showFinishMessage: false,
      nextClickLoader: false,
      criteriaResponsesCountMap: {}
    };
  }

  componentDidMount() {
    let eval_id = null
    const { isWorkFlow, evaluation_id } = this.props
    if (isWorkFlow) {
      eval_id = evaluation_id
      //   if(this.props.evaluation.ID === evaluation_id){
      //     this.props.updateEvaluationMetaData(eval_id, {
      //      Status: 4
      //    });
      //  }
    } else {
      eval_id = this.props.match.params.evaluation_id
    }

    this.props.fetchEvaluationMetaData(eval_id);
    if (!this.props.isReport) {
      this.getReportData(eval_id);
      this.props.fetchScores(eval_id);
    }

    var componentId = this.props.match.params.component_id !== undefined ? this.props.match.params.component_id : 0;

    this.props.fetchCriteria(eval_id, true, componentId);
    // this.props.fetchScores(eval_id);
    this.props.fetchEvaluationFeedback(eval_id);
    this.props.fetchUserRequirementResponse(eval_id)
    this.setState({
      eval_id,
    })

  }

  getReportData(evaId) {
    var params = {
      approval: true,
      colloborator: true,
      feedback: true,
      seller_response: true,
      all_products: true,
      all_criterias: true
    }
    this.props.fetchEvaluationReportData(
      evaId,
      params
    );
  }

  componentWillUnmount() {
    try {
      this.props.toggleEvaluateView(false, null);
      this.props.toggleEvaluateNeverReviewed(false, null);
      document.body.classList.remove("evaluate-view");
    } catch (e) { }
  }

  checkOrgUserConditions() {
    const { evaluation } = this.props;
    console.log(evaluation, 'Evaluation000001');
    if (this.props.userRole !== "OrgAdmin" && this.props.user.ID !== evaluation.UserID) {
      this.setState({
        isHideTopBar: true
      })
    }
  }

  setReview = () => {
    try {
      if (
        this.props.evaluation?.Status === 4 &&
        this.props.evaluation?.Type === 2 &&
        moment(new Date()).diff(
          moment(this.props.evaluation?.EvaluationUpdatedAt),
          "days"
        ) > 30
      ) {
        document.body.classList.add("evaluate-view");
        this.props.toggleEvaluateView(
          true,
          this.props.evaluation?.Products[0]?.ID
        );
      } else if (
        (this.props.evaluation?.Status <= 3 ||
          this.props.evaluation?.Status === 4) &&
        moment(new Date()).diff(
          moment(this.props.evaluation?.EvaluationUpdatedAt),
          "days"
        ) <= 30 &&
        this.props.evaluation?.Type === 2
      ) {
        this.props.toggleEvaluateNeverReviewed(
          true,
          this.props.evaluation?.Products[0]?.ID
        );
      }
    } catch (e) { }
  };

  componentDidUpdate(preProp) {
    if (
      !this.props.errorFetch &&
      this.props.evaluationFetchProgress === false &&
      preProp.evaluationFetchProgress === true
    ) {
      this.setReview();
    }

    if (
      this.props.updateProgress === false &&
      this.props.updateError === null &&
      preProp.updateProgress === true
    ) {
      this.setReview();
    }

    if (this.props.evaluateNeverReview && !this.props.criteriasFetchProgress && preProp.criteriasFetchProgress) {
      let isApprovalFlow = false;
      for (let i = 0; i < this.props.criterias.length; i++) {
        if (!isApprovalFlow) {
          isApprovalFlow = this.props.criterias[i]?.colloborator.some(
            (item) => {
              return item.IsApprover;
            }
          );
        } else {
          break;
        }
      }
      this.setState({ isApprovalFlow: isApprovalFlow });
    }

    // if(this.props.criteriasFetchProgress === false && preProp.criteriasFetchProgress === true && this.props.isWorkFlow === true){
    //   if(this.props.criterias === undefined || this.props.criterias === null || this.props.criterias.length <= 0){
    //     this.setState({
    //       isSummaryPage : false
    //     })
    //   }
    // }

    if (this.props.fetchScoreProgress === false && preProp.fetchScoreProgress) {
      let criteriaResponsesCountMap = {};
      const { scores } = this.props;
      if (scores) {
        Object.keys(scores).forEach(function (key) {
          let productDatas = scores[key]
          if (productDatas) {
            Object.keys(productDatas).forEach(function (pk) {
              let data = productDatas[pk]
              if (criteriaResponsesCountMap[data.criteriaId] === undefined) {
                criteriaResponsesCountMap[data.criteriaId] = data.totalResponses
              } else {
                let totalOld = criteriaResponsesCountMap[data.criteriaId];
                let newCount = totalOld + data.totalResponses
                criteriaResponsesCountMap[data.criteriaId] = newCount
              }
            });
          }
        })
        this.setState({
          criteriaResponsesCountMap:criteriaResponsesCountMap
        })
      }
    }

    if (this.props.evaluateView && !preProp.evaluateView) {
      let isApprovalFlow = false;
      this.props.evaluation.Products.map((product) => {
        if (this.props.evaluateView) {
          if (
            product.ID === parseInt(this.props.evaluateViewId) &&
            product.IsApproved
          ) {
            isApprovalFlow = true;
          }
        } else {
        }
        return true;
      });
      this.setState({ isApprovalFlow: isApprovalFlow });
    }

    if (
      this.props.downloadReportProg === false &&
      preProp.downloadReportProg === true
    ) {
      if (this.props.report !== null) {
        window.open(this.props.report.ReportUrl, "_blank");
      }
    }

  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  setSelectedProduct = (productId) => {
    this.setState({
      selectedProduct: productId,
    });
  };

  goToNextPage = () => {
    this.setState({
      nextClickLoader: true
    }, () => {
      var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);
      if (nextComponent !== null) {
        let redirectPath = `/app/evaluate/${nextComponent.EvaluationId}/workflow/${nextComponent.WorkflowId}/component/${nextComponent.ComponentId}`;
        this.props.history.push(redirectPath);
        return;
      } else {
        var responseDatas = [];
        this.props.workflowComponentsForms.forEach((data) => {
          if (data.IsConfiguration === false) {
            var obj = {
              "form_id": data.ID
            }
            var productIds = this.state.products.map((p) => {
              return p.ID
            });
            if (data.Name === 'evFeedback.product_ranking') {
              obj['value'] = JSON.stringify(productIds);
            }
            responseDatas.push(obj);
          }
        })

        this.props.addWorkflowComponentUserResponse(this.props.evaluation_id, this.props.workflowComponentId, {
          "data": responseDatas,
          "forward": true
        });
      }
    })
  }


  checkWorkflowConfiguration() {
    const { workflowComponentsForms, workflowComponentDetail } = this.props;
    console.log(this.props.workflowComponentsForms, 'workflowComponentsForms');
    console.log(this.props.workflowComponentDetail, 'workflowComponentDetail');
    if (workflowComponentsForms === null || workflowComponentDetail === null) {
      return;
    }

    var configurations = workflowComponentDetail.OrgWorkflowComponentConfigurations;
    if (configurations === undefined || configurations === null || configurations.length <= 0) {
      if (this.props.criterias === undefined || this.props.criterias === null || this.props.criterias.length <= 0) {
        this.setState({
          showFinishMessage: true
        })
      }
    } else {
      var redirectFormId = workflowComponentsForms.filter(o => o.Name === "finish_redirect").map(o => o.ID)[0];
      var redirectUrlValue = configurations.filter(o => o.FormID === redirectFormId).map(o => o.Value)[0];
      redirectUrlValue = redirectUrlValue === 'false' ? false : true;
      if (redirectUrlValue === true) {
        var redirectUrlFormId = workflowComponentsForms.filter(o => o.Name === "finish_redirect_url").map(o => o.ID)[0];
        var redirectUrl = configurations.filter(o => o.FormID === redirectUrlFormId).map(o => o.Value)[0];
        if (redirectUrl !== null) {
          // window.location = redirectUrl;
          this.setState({
            isRedirectUrl: true,
            redirectURL: redirectUrl
          })
        }
      }

      var approvalFormId = workflowComponentsForms.filter(o => o.Name === "enable_approval").map(o => o.ID)[0];
      var enableApproval = configurations.filter(o => o.FormID === approvalFormId).map(o => o.Value)[0];
      enableApproval = enableApproval === 'false' ? false : true;
      this.setState({
        isEnableApproval: enableApproval,
      }, () => {
        if (this.props.criterias === undefined || this.props.criterias === null || this.props.criterias.length <= 0) {
          if (this.state.isEnableApproval === true) {
            this.setState({
              isSummaryPage: false
            })
          } else {
            this.setState({
              showFinishMessage: true
            }, () => {
              if (this.state.isRedirectUrl) {
                this.openInNewTab(this.state.redirectURL);
              }
            })
          }
        }
      })


    }
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  render() {
    const classes = this.props.classes;
    const { adminForward, isCreatorOrAdmin, reportData } = this.props;
    if (this.props.criteriasFetchProgress || this.props.evaluationFetchProgress || this.props.isLoadingReport) {
      return (
        <span className={classes.loader}>
          {!this.props.isReport ? <CircularProgress /> : ''}
        </span>
      );
    }

    // find top ranker
    let high_score = 0;
    let topper = null;
    if (this.props.evaluation?.Products.length) {
      for (let product of this.props.evaluation?.Products) {
        let product_score = 0;
        if (this.props.scores[product.ID] !== undefined) {
          var score_obj = this.props.scores[product.ID];
          var totalOpted = 0;
          var totalMax = 0;
          for (var criteriId in score_obj) {
            var criteriScore = score_obj[criteriId];
            totalOpted += criteriScore.totalOpted;
            totalMax += criteriScore.totalMax;
          }
          if (totalMax !== 0) {
            product_score = Math.round(
              parseFloat((parseFloat(totalOpted) / parseFloat(totalMax)) * 100)
            );
          }
        }
        if (product_score > high_score && product_score > 0) {
          high_score = product_score;
          topper = product.ID;
        }
      }
    }

    var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);

    var productScoreMap = {};
    if (reportData !== null && reportData.Products !== undefined && reportData.Products !== null) {
      reportData.Products.forEach(function (itm) {
        productScoreMap[itm.ID] = itm.Stats;
      })
    }

    return (
      <>
        {!this.props.isReport && !this.props.isWorkFlow && (this.props.userRole === "OrgAdmin" || this.props.user.ID === this.props.evaluation?.UserID) && <EvaluationBar />}

        {!this.props.isReport && this.props.isWorkFlow && <EvaluationBar
          isShowCollabrations={true}
          isShowEvalDetails={false}
          isWorkFlow={this.props.isWorkFlow}
          isShowViewBtns={false}
        />}

        <div className={classnames(classes.summaryContent, classes.content)} style={{ minHeight: this.props.isPDfReport ? 0 : 'initial' }}>
          {!this.props.onlyShowFeedBack && this.props.evaluation !== null && (
            <Product
              isReport={this.props.isReport}
              response={this.props}
              topper={topper}
              isWorkFlow={this.props.isWorkFlow}
              productScoreMap={productScoreMap}
              criteriaResponsesCountMap={this.state.criteriaResponsesCountMap}
            />
          )}

          {this.props.feedbacks !== null && this.props.feedbacks.length !== 0 && <div className={classes.productContainer}>
            <Typography variant={"h6"} className={classes.colbTitle}>
              Stakeholders Summary <ToolTip toolTip="40" />
            </Typography>
            <Grid container spacing="3">
              {this.props.feedbacks !== null &&
                this.props.feedbacks.map((feedback) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={generate()}>
                    <SummaryFeedback
                      feedback={feedback}
                      showProductRankingDialog={
                        this.props.showProductRankingDialog
                      }
                    />
                  </Grid>
                ))}
            </Grid>
          </div>}
          {this.props.isReport === false && !this.props.isPDfReport && <div id="pdfHideButton"><BottomAction>

            <Grid container className={classes.bottomNav}>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <div className={classes.finishBtn}>
                  <SqureButton
                    variant={"contained"}
                    disabled={(((!adminForward || (adminForward && isCreatorOrAdmin)) && !this.props.isReport && (this.props.userRole === "OrgAdmin" || this.props.user.ID === this.props.evaluation?.UserID)) || nextComponent !== null) ? false : true}
                    onClick={() => {
                      if (this.props.isWorkFlow) {
                        this.goToNextPage();
                      } else {
                        this.props.history.push(
                          `/app/evaluate/${this.state.eval_id}/approval`
                        );
                      }
                    }}
                    endIcon={<Image src={ArrowForward} alt="arrow forward" />}
                  >
                    {this.props.isWorkFlow ? this.state.nextClickLoader ? <CircularProgress style={{ width: 27, height: 27 }} /> : 'Next' : 'Approval'}
                  </SqureButton>
                </div>
              </Grid>
            </Grid>

          </BottomAction></div>}

        </div>
        {this.props.productRankingDialogOpen === true && (
          <ProductsRankingDialog />
        )}
        <ApprovalDialog />
      </>
    );

  }
}

Summary.defaultProps = {
  isWorkFlow: false,
  isFinish: false,
  isReport: false,
  onlyShowFeedBack: false
}
export default connector(compose(withRouter, withStyles(styles))(Summary));

/**
 * Summary feedback grid start
 */
const stylesSummaryFeedback = makeStyles((theme) => ({
  summaryContainer: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 10px #00000022",
    borderRadius: "5px",
    opacity: 1,
    padding: "15px",
  },
  flexRow: {
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    placeContent: "stretch flex-start",
    alignItems: "stretch",
  },
  flexSpaceBetCenter: {
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    placeContent: "center space-between",
    alignItems: "center",
  },
  summaryHeader: {
    marginBottom: "20px",
  },
  headTitles: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  title: {
    fontWeight: 600,
    fontSize: "13px",
    // margin: '0px',
    // marginBottom: '5px'
  },
  subTitle: {
    fontWeight: 400,
    fontSize: "12px",
    margin: "0px",
  },
  headRightCol: {
    display: "flex",
    placeContent: "center space-between",
    alignItems: "center",
  },
  summaryBody: {
    background: "#F4F6F8 0% 0% no-repeat padding-box",
    borderRadius: "7px",
    textAlign: "left",
    fontWeight: 500,
    // letterSpacing: '0.58px',
    color: "#7A7E81",
    // textTransform: 'capitalize',
    fontSize: "11px",
    minHeight: "70px",
    padding: "10px",
    "& [class*='editableField']": {
      maxHeight: "auto",
      minHeight: "auto !important",
      margin: "0px !important",
      height: "60px !important",
      background: "#F4F6F8 0% 0% no-repeat padding-box",
    },
  },
  textEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
}));

function SummaryFeedback(props) {
  const { feedback, showProductRankingDialog } = props;
  const classes = stylesSummaryFeedback();

  const avatarName = (name) => {
    return name.substring(0, 1);
  };

  return (
    <>
      <div className={classes.summaryContainer}>
        <div
          className={
            classes.summaryHeader +
            " " +
            classes.flexRow +
            " " +
            classes.flexSpaceBetCenter
          }
        >
          <div className={classes.headLeftCol + " " + classes.flexRow}>
            <Avatar aria-label="recipe" className={classes.avatar}>
              {avatarName(feedback.FeedbackBy.Name)}
            </Avatar>
            <div className={classes.headTitles}>
              <h3 className={classes.title}>{feedback.FeedbackBy.Name}</h3>
              {/* <h6 className={classes.subTitle}>{feedback.Products[0].Name}</h6> */}
            </div>
          </div>
          <div className={classes.headRightCol}>
            <Image
              style={{ marginRight: "15px", maxWidth: "50px" }}
              src={feedback.Products[0]?.ProductLogo}
              alt="Logo"
            />
            <Image
              onClick={() => {
                showProductRankingDialog(feedback);
              }}
              style={{ width: "15px", cursor: "pointer" }}
              src={SummaryIcon}
              alt="Icon"
            />
          </div>
        </div>
        <div className={classes.summaryBody}>
          <SlateReadonlyField initValue={feedback.Feedback} />
        </div>
      </div>
    </>
  );
}

/**
 * Summary feedback grid end
 */
