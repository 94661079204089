
import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  TableContainer, Button, Table, TableBody, TableCell, TableHead, TableRow, TablePagination,
  CircularProgress, IconButton, Paper, ListItemSecondaryAction, Typography, MenuItem, FormControl,
  Select, Tooltip, Popover, List, Switch, Divider, ListItem, ListItemIcon, Grid
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from "classnames";

import axios from 'axios'
import * as Environment from "util/Environment";
// import Image from 'Components/Common/image.jsx';
import { CommonFn } from "services/commonFn";
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import SqureButton from "Components/Common/SqureButton";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ScoreIcon from '@material-ui/icons/Score';
// import deleteIcon from "assets/images/delete.svg";
// import editIcon from "assets/images/edit_icon.svg";
// import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
import AppBarV2 from "Components/Application/AppBarV2";
import { showSnackBar } from "redux/snackbar/action";
import ReactSelect from 'react-select';
import { withAsyncPaginate } from "react-select-async-paginate";
import { showAddUpdateTaskDialog } from "redux/evaluation/productTask/action";
import AddUpdateTaskDialog from 'Components/Common/Dialogs/AddUpdateTaskDialog';
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action"
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { fetchOrgForms, fetchOrgFormUsingId } from "redux/forms/org/action";
import { addOrgSettings } from "redux/org/user/action";
import { fetchProductRisk, deleteProductRisk, showUpdateRiskScoreDialog } from "redux/risk/action";

import UpdateScoreDialog from "../Dialogs/UpdateScoreDialog";
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorOutlinedIcon from "@material-ui/icons/DragIndicatorOutlined";
import { getRiskRegisterColumns } from "util/Columns"

const CreatableAsyncPaginate = withAsyncPaginate(ReactSelect);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const connectedProps = (state) => ({
  orgUser: state.orgUser,

  deleteProductRiskProgress: state.risk.deleteProductRiskProgress,
  deleteProductRiskError: state.risk.deleteProductRiskError,
  fetchProductRisksProgress: state.risk.fetchProductRisksProgress,
  fetchProductRisksError: state.risk.fetchProductRisksError,
  productRisks: state.risk.productRisks,
  isOpenTaskDialog: state.productTasks.showAddTaskDialog,
  addNewTaskProgress: state.productTasks.addNewTaskProgress,
  updateNewTaskProgress: state.productTasks.updateNewTaskProgress,
  updatedTaskData: state.productTasks.updatedTaskData,
  updateNewTaskError: state.productTasks.updateNewTaskError,
  fetchExpentDirectoryProductsProgress: state.expentDirectory.fetchExpentDirectoryProductsProgress,
  fetchExpentDirectoryProductsError: state.expentDirectory.fetchExpentDirectoryProductsError,
  expentDirectoryProducts: state.expentDirectory.products,

  fetchOrgFormsProgress: state.orgForms.fetchOrgFormsProgress,
  orgForms: state.orgForms.forms,

  orgFormData: state.orgForms.orgFormData,
  fetchOrgFormsByIdProgress: state.orgForms.fetchOrgFormsByIdProgress,

  showUpdateScoreDialog: state.risk.showUpdateScoreDialog,

  updateProductRiskProgess: state.risk.updateProductRiskProgess,
  updateProductRiskError: state.risk.updateProductRiskError,
  updateProductRiskSuccess: state.risk.updateProductRiskSuccess,

  updateProductScoreProgess: state.risk.updateProductScoreProgess,
  updateProductScoreError: state.risk.updateProductScoreError,
  productRiskScore: state.risk.productRiskScore,
});

const connectionActions = {
  deleteProductRisk: deleteProductRisk,
  showSnackBar: showSnackBar,
  fetchProductRisk: fetchProductRisk,
  showAddUpdateTaskDialog: showAddUpdateTaskDialog,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  fetchOrgForms: fetchOrgForms,
  fetchOrgFormUsingId: fetchOrgFormUsingId,
  addOrgSettings: addOrgSettings,
  showUpdateRiskScoreDialog: showUpdateRiskScoreDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    padding: '2.5rem',
    "& [class*='editableField']": {
      minHeight: 'auto !important',
    },
  },
  nameCol: {
    width: '25%',
    // zIndex: 0,
  },
  statusCol: {
    width: '15%',
  },
  dueDateCol: {
    width: '10%',
  },
  actionCol: {
    textAlign: 'center',
    width: '20%',
    // zIndex: 0,
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: 15
  },
  responseFilter: {
    marginRight: 20
  },
  filterTitle1: {
    marginBottom: 1,
    color: "#282D30",
    fontSize: 14,
  },
  formControl: {
    width: 170,
    "& > div": {
      color: "hsl(0,0%,50%)",
      fontSize: 14,
      background: '#fff'
    },
  },
  mandatoryTable: {
    zIndex: 0,
  },
  newRiskBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    marginLeft: 'auto',
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  actionBtns: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    marginTop: 10
  },
  columnDrawer: {
    width: 400,
    right: "0",
    padding: "15px",
    zIndex: "9",
  },
  bottomActions: {
    textAlign: "right",
  },
  bodyConfig: {
    marginTop: 20,
    marginBottom: 20
  },
  stateLabel: {
    cursor: "pointer",
    backgroundColor: "#fff",
    border: "1px #dadce0 solid",
    borderRadius: 8,
    height: 32,
    padding: "0 10px",
    margin: "0px 3px",
    display: "flex",
    alignItems: "center",
  },
  selected: {
    backgroundColor: "#a3dcfc33",
  },
  stateLabelText: {
    color: "#5f6368",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "1px",
  },
  labelInit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 16,
    height: 16,
    marginRight: 8,
    color: "#fff",
    borderRadius: 8,
    fontSize: 10,
    background: "#4a87f8",
  },
  isFeatured: {
    background: "#ff9800",
    color: "#fff",
  },
  filterOptions: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    padding: theme.spacing(3, 3),
    paddingLeft: 0,
    paddingTop: 0
  },
});

class RiskHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 20,
      priorityFilter: 0,
      statusFilter: 0,
      productFilterId: null,
      risks: [],
      loader: true,
      priorityMap: {
        1: 'Low',
        2: 'Medium',
        3: 'High',
      },
      statusMap: {
        1: 'New',
        2: 'In Progress',
        3: 'Completed',
      },
      totalRisks: 0,
      isDataLoading: true,
      priorityFIlterDropdown: [{
        name: 'All',
        value: 0
      }, {
        name: 'Low',
        value: 'LOW'
      }, {
        name: 'Medium',
        value: 'MEDIUM'
      }, {
        name: 'High',
        value: 'HIGH'
      }, {
        name: 'Critical',
        value: 'CRITICAL'
      }],
      statusFilterDropdown: [{
        name: 'All',
        value: 0
      }, {
        name: 'New',
        value: 1
      }, {
        name: 'In Progress',
        value: 2
      }, {
        name: 'Completed',
        value: 3
      }],
      deleteRisk: null,
      riskRegisterForm: '',
      riskFormItemIds: [],
      riskFormItems: [],
      isShowProjectConfigPopover: false,
      projectTrackingEl: null,
      trackFormConfig: '',
      riskRegisterColumns: [
        { id: 'ProductName', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Product' },
        { id: 'RiskName', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Name' },
        { id: 'OverallScore', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Overall Risk' },
        { id: 'LS', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Likelihood score' },
        { id: 'IS', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Impact score' },
        { id: 'Action', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Action' },
      ],
      anchorEl: null,
      currentColumn: null,
      isShowPageLoader: true,
      isShowSubMenu: false,
      subMenuEl: null,
      colVisibilityData: null,
      availableFilters: [
        { label: "Risk", slug: "risk", isShow: true },
      ],
      showFilters: []
    }
    this.btnRef = React.createRef();

    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.riskFiltering = this.riskFiltering.bind(this);
    this.priorityFiltering = this.priorityFiltering.bind(this);
    this.statusFiltering = this.statusFiltering.bind(this);
    this.hideConfigurationPopover = this.hideConfigurationPopover.bind(this);
    this.handleClose = this.handleClose.bind(this)
    this.updateColVisibilityStatus = this.updateColVisibilityStatus.bind(this);
  }

  componentDidMount() {
    this.props.fetchOrgForms(0, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.deleteProductRiskProgress === false && prevProps.deleteProductRiskProgress === true) {
      if (this.props.deleteProductRiskError === null) {
        this.fetchData();
        this.props.showSnackBar("Risk deleted successfully.", "success", 3000);
      } else {
        this.props.showSnackBar("Something went wrong to delete risk.", "error", 3000)
      }
    }

    if (this.props.fetchProductRisksProgress === false && prevProps.fetchProductRisksProgress === true) {
      if (this.props.fetchProductRisksError === null) {
        console.log(this.props.productRisks, 'this.props.productRisks')
        console.log(this.props.productRisks, 'productRisks')
        let newRows = []
        if (this.props.productRisks && this.props.productRisks.Data) {
          newRows = this.props.productRisks?.Data.map((o) => {
            return {
              ...o,
              ...o?.FormFields
            };
          });
        }
        this.setState({
          loader: false,
          risks: newRows,
          totalRisks: this.props.productRisks?.total,
          isDataLoading: false
        })
      }
    }

    if (!this.props.updateNewTaskProgress && prevProps.updateNewTaskProgress) {
      if (this.props.updateNewTaskError === null) {
        this.fetchData();
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!this.props.addNewTaskProgress && prevProps.addNewTaskProgress) {
      this.fetchData();
    }

    if (this.props.orgUser.addOrgSettingsInprogress === false && prevProps.orgUser.addOrgSettingsInprogress === true) {
      if (this.props.orgUser.addOrgSettingsFailure == null) {
        this.getRiskRegisterForm('UPDATE');
        this.props.showSnackBar("Project Tracking form updated successfully", "success", 3000)
      }
    }

    if (this.props.fetchOrgFormsProgress === false && prevProps.fetchOrgFormsProgress) {
      this.getRiskRegisterForm('ONLOAD');
    }

    if (this.props.fetchOrgFormsByIdProgress === false && prevProps.fetchOrgFormsByIdProgress === true) {
      this.initializeRiskRegisterColumns();
    }

    if (this.props.updateProductRiskProgess === false && prevProps.updateProductRiskProgess === true) {
      if (this.props.updateProductScoreError === null) {
        this.fetchData()
      }
    }

    if (this.props.updateProductScoreProgess === false && prevProps.updateProductScoreProgess === true) {
      if (this.props.updateProductRiskError === null) {
        this.fetchData()
      }
    }

  }

  fetchData() {
    const { priorityFilter, productFilterId } = this.state;
    var queryParams = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      productID: productFilterId,
    }

    if (productFilterId !== null && productFilterId !== '') {
      queryParams['productID'] = productFilterId;
    }

    if (priorityFilter !== 0 && priorityFilter !== '') {
      queryParams['priority'] = priorityFilter;
    }

    if (this.state.riskFormItemIds.length > 0) {
      queryParams['items'] = this.state.riskFormItemIds;
    }

    this.props.fetchProductRisk(queryParams);
  }

  handleChangePage(e, newPage) {
    this.setState({
      page: newPage,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleChangeRowsPerPage(e) {
    this.setState({
      page: 0,
      pageSize: e.target.value,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleEdit(risk) {
    this.props.onEdit(risk)
  }

  handleDelete = (risk) => {
    var me = this;
    this.setState({ deleteRisk: risk }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.deleteRisk) {
      this.props.hideMessageDialog();
      this.props.deleteProductRisk(this.state.deleteRisk.ID);
    }
  };

  riskFiltering(newValue) {
    this.setState({
      page: 0,
      typeFilter: newValue
    }, () => {
      this.fetchData()
    })
  }

  priorityFiltering(newValue) {
    this.setState({
      page: 0,
      priorityFilter: newValue
    }, () => {
      this.fetchData()
    })
  }

  statusFiltering(newValue) {
    this.setState({
      page: 0,
      statusFilter: newValue
    }, () => {
      this.fetchData()
    })
  }

  productFiltering(newValue) {
    this.setState({
      page: 0,
      productFilterId: newValue
    }, () => {
      this.fetchData()
    })
  }

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page
    }
    const api_server = Environment.api_host("SEARCH");
    const timestamp = (new Date()).getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      field_setname: 'all',
      text: inputValue,
      page,
      page_size: 150,
      sort_fields: [],
      filter_fields: {},
      merge_existing_others: true
    }
    let url = `${api_server}/v2/dsl/expent_catalogue?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'application/json' }
    });

    let products = response?.data?.body?.items || [];
    let total = response?.data?.body?.total || 0;
    products = products.map(m => {
      m.value = parseInt(m.id);
      m.label = m.name;
      m.isProduct = true;
      m.productStates = m.states;
      m.isDisabled = m.states?.blocked !== undefined
      return m
    });
    return {
      options: products,
      hasMore: ((page + 1) * 10) < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  }

  ProductLabels = (props) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', cursor: 'pointer' }}>
        <div>
          <span style={{ 'color': '#000', fontSize: '16px' }}>{props.label}</span>&nbsp;
          {props.isProduct !== undefined && props.isProduct && <span style={{ 'color': '#6C6C6C', fontSize: '12px' }}>By {props.company_name}</span>}
        </div>
      </div>
    )
  }

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  };

  getRiskRegisterForm(type) {
    const { orgForms } = this.props;
    if (orgForms) {
      let riskRegisterForm;
      if (type === 'ONLOAD') {
        riskRegisterForm = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "RISK_REGISTER_FORM") || '';
      } else if (type === 'UPDATE') {
        riskRegisterForm = this.props.orgUser?.addOrgSettingsSuccess[0]?.Value || null;
      }
      if (riskRegisterForm !== undefined && riskRegisterForm !== null && riskRegisterForm !== 'null' && riskRegisterForm !== '') {
        let selectedForm = orgForms.filter(o => o.ID === parseInt(riskRegisterForm))[0]
        console.log(selectedForm, 'selectedForm')
        if (selectedForm) {
          this.setState({
            riskRegisterForm: {
              label: selectedForm.FormTitle,
              value: selectedForm.ID,
            },
          }, () => {
            this.getFormDetails();
          })
        }
      } else {
        this.fetchData()
      }
    } else {
      this.fetchData()
    }
    this.setState({
      isShowPageLoader: false
    })
  }

  getFormDetails() {
    const { riskRegisterForm } = this.state;
    console.log(riskRegisterForm, 'riskRegisterForm111')

    if (riskRegisterForm) {
      this.props.fetchOrgFormUsingId(riskRegisterForm.value);
    }
  }

  updateRiskRegisterForm() {
    const form = this.state.trackFormConfig;
    if (form === undefined || form === null || form === '') {
      this.props.showSnackBar('Please select form', 'error', 3000)
      return;
    }
    let org_settings = [
      {
        name: "RISK_REGISTER_FORM",
        value: String(form.value)
      }
    ]
    this.hideConfigurationPopover()
    this.props.addOrgSettings(org_settings);
  }

  showProjectTrackingConfigPoper(event) {
    this.setState({
      projectTrackingEl: event.currentTarget || event.current,
      isShowProjectConfigPopover: true,
    });
  }

  hideConfigurationPopover() {
    this.setState({
      isShowProjectConfigPopover: false,
      projectTrackingEl: null,
    });
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
      currentColumn: null,
    });
  };

  handleActionClick = (event, row) => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget || event.current,
      currentColumn: row,
    });
  };

  routeChange = (item) => {
    console.log(item, 'item')
    const { riskRegisterForm } = this.state;
    this.props.history.push(`/app/products/risks/risk/${item.ID}/${riskRegisterForm !== null && riskRegisterForm !== '' ? riskRegisterForm?.value : 0}`)
    // this.props.history.push(`/app/project/${item.id}/tracking/${this.props.projectTrackingForm.value}`);
  };

  updateScore(risk) {
    this.props.showUpdateRiskScoreDialog(risk)
  }

  addNewRisk() {
    const { riskRegisterForm } = this.state;
    this.props.history.push(`/app/products/risks/risk/0/${riskRegisterForm !== null && riskRegisterForm !== '' ? riskRegisterForm?.value : 0}?isNewRisk=true`)
  }

  initializeRiskRegisterColumns() {
    const { orgFormData } = this.props;
    let formItems = [];
    let itemIds = [];

    let riskRegisterColumns = [
      { id: 'ProductName', type: '', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Product' },
      { id: 'RiskName', type: '', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Name' }
    ]

    // console.log(orgFormData,'orgFormDataNewwwwwwww')
    if (orgFormData.OrgWorkflowTemplateFormsSection) {
      orgFormData.OrgWorkflowTemplateFormsSection.forEach(function (section) {
        if (section.OrgWorkflowTemplateFormSectionItems) {
          section.OrgWorkflowTemplateFormSectionItems.forEach(function (itm) {
            let obj = {
              id: itm.ID,
              label: itm.ItemName
            }

            let colObj = {
              id: itm.ID,
              numeric: false,
              align: 'left',
              isVisible: true,
              isSortRequire: false,
              disablePadding: false,
              label: itm.ItemName,
              type: itm.Type,
            }

            itemIds.push(itm.ID)
            formItems.push(obj)
            riskRegisterColumns.push(colObj)
          })
        }
      })
    }

    riskRegisterColumns.push({ id: 'OverallScore', type: '', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Overall Risk' },
      { id: 'LS', type: '', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Likelihood score' },
      { id: 'IS', type: '', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Impact score' }, {
      id: 'Action',
      type: '',
      numeric: false,
      align: 'left',
      isVisible: true,
      isSortRequire: false,
      disablePadding: false,
      label: 'Action'
    }
    )

    let finalColumns = getRiskRegisterColumns(riskRegisterColumns);

    this.setState({
      riskFormItemIds: itemIds,
      riskFormItems: formItems,
      riskRegisterColumns: finalColumns,
      colVisibilityData: null
    }, () => {
      this.fetchData();
      // updateTrackingColumns(formItems)
    })
  }

  handleCloseSubMenu() {
    this.setState({
      isShowSubMenu: false,
      subMenuEl: null,
      colVisibilityData: null,
    });
  }

  showColumnVisibityPoper(event) {
    this.setState({
      subMenuEl: event.currentTarget || event.current,
      isShowSubMenu: true,
      colVisibilityData: [...this.state.riskRegisterColumns],
    });
  }

  updateColVisibilityStatus(oldStatus, index) {
    let colVisibilityData = [...this.state.colVisibilityData];
    let myCol = colVisibilityData[index];
    let isVisible = oldStatus === true ? false : true;
    myCol = {
      ...myCol,
      isVisible: isVisible,
    };
    colVisibilityData[index] = myCol;
    this.setState({
      colVisibilityData: colVisibilityData,
    });
  }


  setVisibilityForColumns() {
    console.log(this.state.colVisibilityData, 'colVisibilityData')
    this.setColumns(this.state.colVisibilityData);
    this.handleCloseSubMenu();
  }

  setColumns(columns) {
    this.setState({
      riskRegisterColumns: columns,
      colVisibilityData: null,
    }, () => {
      window.localStorage.setItem("riskRegisterColumns", JSON.stringify(columns));
    }
    );
  }

  onColumnsDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { destination, draggableId, source } = result;
    if (draggableId && destination.index !== source.index) {
      const newColVisibilityData = reorder(this.state.colVisibilityData, source.index, destination.index);

      this.setState({
        colVisibilityData: newColVisibilityData,
      });
    }
  }

  redirectToForm = () => {
    const { riskRegisterForm } = this.state;
    this.props.history.push(`/app/settings/templates/forms/custom/${riskRegisterForm.value}`)
  }

  handleOnClickFilter(value, index) {
    let showFilters = this.state.showFilters;
    if (showFilters.indexOf(value) !== -1) {
      showFilters.splice(showFilters.indexOf(value), 1)
    } else {
      showFilters.push(value)
    }
    this.setState({
      showFilters: showFilters
    })
  }

  render() {
    const { classes } = this.props;
    const { risks, page, pageSize, totalRisks, isDataLoading,
      statusFilter, priorityFilter,
      priorityFIlterDropdown, statusFilterDropdown, riskRegisterForm,
      trackFormConfig, riskRegisterColumns, isShowPageLoader, colVisibilityData, availableFilters, showFilters } = this.state;

    if (isShowPageLoader) {
      return <div style={{ textAlign: 'center', marginTop: 30 }}>
        <CircularProgress />
      </div>
    }
    const openAction = Boolean(this.state.anchorEl);

    var noTaskMsg = 'No Risks'

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <div>
        <AppBarV2 title="Risk Register" withBack={true} />
        <div className={classes.root} key={this.props.key}>
          <div className={classes.filters}>
            <div className={classes.responseFilter}>
              <Typography variant={"h4"} className={classes.filterTitle1}>
                Product
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small" style={{ width: '450px' }}>
                <CreatableAsyncPaginate
                  noOptionsMessage={() => "Start typing to select product"}
                  isSearchable={true}
                  isClearable
                  createOptionPosition={"last"}
                  classNamePrefix="select"
                  placeholder="Name of the vendor"
                  // onInputChange={(e) => {
                  //     this.getOptions(e)
                  // }}
                  onChange={(e) => {
                    if (e?.value) {
                      this.productFiltering(e.value);
                    } else {
                      this.setState({
                        page: 0,
                        productFilterId: null
                      }, () => {
                        this.fetchData()
                      })
                    }
                  }}
                  additional={{
                    page: 0,
                  }}
                  formatOptionLabel={this.ProductLabels}
                  isValidNewOption={(input) => input.length > 0}
                  loadOptions={this.getOptions}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 999 }) }}
                />

              </FormControl>
            </div>
            {showFilters.indexOf('risk') !== -1 && <div className={classes.responseFilter}>
              <Typography variant={"h4"} className={classes.filterTitle1}>
                Risk
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <Select
                  value={priorityFilter}
                  onChange={(e) => {
                    this.priorityFiltering(e.target.value)
                  }}
                >
                  {priorityFIlterDropdown.map((itm) => {
                    return <MenuItem value={itm.value} style={{ fontSize: 13 }}>
                      {itm.name}
                    </MenuItem>
                  })}
                </Select>
              </FormControl>
            </div>}

            {false && <div className={classes.responseFilter}>
              <Typography variant={"h4"} className={classes.filterTitle1}>
                Select Status
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <Select
                  value={statusFilter}
                  onChange={(e) => {
                    this.statusFiltering(e.target.value)
                  }}
                >
                  {statusFilterDropdown.map((itm) => {
                    return <MenuItem value={itm.value} style={{ fontSize: 13 }}>
                      {itm.name}
                    </MenuItem>
                  })}
                </Select>
              </FormControl>
            </div>}

            <div className={classes.actionBtns}>
              {(riskRegisterForm !== null && riskRegisterForm !== '') && <Button className={classes.newRiskBtn} onClick={() => {
                this.addNewRisk()
              }}>
                + New Risk
              </Button>}

              {(riskRegisterForm === null || riskRegisterForm === '') && <Tooltip title="Configuration">
                <IconButton
                  aria-label="filter list"
                  onClick={(event) => {
                    this.showProjectTrackingConfigPoper(event);
                  }}
                >
                  <PermDataSettingIcon />
                </IconButton>
              </Tooltip>}

              {(riskRegisterForm !== null && riskRegisterForm !== '') && <Tooltip title="Add More Fields">
                <IconButton
                  aria-label="filter list"
                  onClick={(event) => {
                    this.showColumnVisibityPoper(event);

                    // this.props.history.push(`/app/settings/templates/forms/custom/${riskRegisterForm.value}`)
                  }}
                >
                  <SettingsOutlined />
                </IconButton>
              </Tooltip>}
            </div>

          </div>
          <div className={classes.filterOptions}>
            <Grid container>
              {availableFilters.map((objState, index) => {
                if (!objState.isShow) {
                  return null
                }
                return (
                  <Grid
                    item
                    key={index}
                    className={classNames(classes.stateLabel, showFilters.indexOf(objState.slug) !== -1 ? classes.selected : {})}
                    onClick={() => this.handleOnClickFilter(objState.slug, index)}
                  >
                    <Typography className={classes.stateLabelText}>{objState.label} +</Typography>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <Paper style={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer style={{ maxHeight: '65vh' }}>
              <Table stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
                <TableHead>
                  <TableRow>
                    {riskRegisterColumns && riskRegisterColumns.map((col) => {
                      if (col.isVisible) {
                        return <TableCell
                          key={col.id}
                          align={col.numeric ? "right" : "left"}
                          style={{ whiteSpace: "nowrap" }}
                          padding={col.disablePadding ? "none" : "normal"}
                        >{col.label}</TableCell>
                      } else {
                        return null
                      }
                    })}
                    {/* <TableCell className={classes.nameCol}>Product</TableCell>
                    <TableCell className={classes.nameCol}>Risk</TableCell>
                    <TableCell className={classes.statusCol}>Priority</TableCell>
                    <TableCell className={classes.statusCol}>Status</TableCell>
                    <TableCell className={classes.dueDateCol}>Due Date</TableCell>
                    <TableCell className={classes.actionCol}>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>

                  {/* {!isDataLoading && risks.slice(page * pageSize, page * pageSize + pageSize).map((risk) => { */}
                  {!isDataLoading && risks.map((risk) => {
                    return <TableRow
                      hover
                      tabIndex={-1}
                      style={{ cursor: "pointer" }}
                      key={'risk_' + risk.ID}>

                      {riskRegisterColumns && riskRegisterColumns.map((col) => {
                        if (col.isVisible) {
                          if (col.type === 2) {
                            return <TableCell
                              key={col.id}
                              align={col.numeric ? "right" : "left"}
                              style={{ whiteSpace: "nowrap" }}
                              padding={col.disablePadding ? "none" : "normal"}
                            >{risk[col.id] ? CommonFn.formateDate(risk[col.id], true) : ''}</TableCell>
                          } else if (col.id === 'Name') {
                            return <TableCell
                              key={col.id}
                              align={col.numeric ? "right" : "left"}
                              style={{ whiteSpace: "nowrap" }}
                              padding={col.disablePadding ? "none" : "normal"}
                            >
                              <SlateReadonlyField initValue={risk?.Name} />
                            </TableCell>
                          } else if (col.id === 'Action') {
                            return <TableCell
                              key={col.id}
                              align={col.numeric ? "right" : "left"}
                              style={{ whiteSpace: "nowrap" }}
                              padding={col.disablePadding ? "none" : "normal"}
                            >
                              <IconButton
                                ref={this.btnRef}
                                className={classes.threeDot}
                                onClick={(e) => this.handleActionClick(e, risk)}>
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          } else {
                            return <TableCell
                              key={col.id}
                              align={col.numeric ? "right" : "left"}
                              style={{ whiteSpace: "nowrap" }}
                              padding={col.disablePadding ? "none" : "normal"}
                            >{risk[col.id]}</TableCell>
                          }
                        } else {
                          return null
                        }
                      })}
                    </TableRow>
                  })}

                  {(!isDataLoading && (risks === undefined || risks === null || risks.length <= 0)) && <TableRow>
                    <TableCell colSpan={riskRegisterColumns.length + 1} style={{ textAlign: 'center' }} className={classes.nameCol}>
                      {noTaskMsg}
                    </TableCell>
                  </TableRow>}

                  {isDataLoading && <TableRow>
                    <TableCell colSpan={riskRegisterColumns.length + 1} style={{ textAlign: 'center' }} className={classes.nameCol}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>}

                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={totalRisks || 0}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Paper>
        </div>
        <MessageDialog messageData={messageData} callBack={this.callBack} />
        {this.props.isOpenTaskDialog && <AddUpdateTaskDialog />}

        {this.state.isShowProjectConfigPopover && (
          <Popover
            open={this.state.isShowProjectConfigPopover}
            anchorEl={this.state.projectTrackingEl}
            onClose={this.hideConfigurationPopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={classes.columnDrawer}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography>Risk Register Configuration</Typography>
              </div>

              <div className={classes.bodyConfig}>
                <Typography style={{ fontSize: 14 }}>Select Form</Typography>
                <ReactSelect
                  isClearable
                  disabled={this.props.workflowStatus === 2 ? true : false}
                  isSearchable={true}
                  menuPosition='fixed'
                  options={this.props.orgForms && this.props.orgForms.map((item) => ({ label: item.FormTitle, value: item.ID }))}
                  classNamePrefix="select"
                  placeholder="Search Form"
                  onChange={(e) => {
                    this.setState({
                      trackFormConfig: e
                    })
                  }}
                  value={trackFormConfig}
                />
              </div>

              <div className={classes.bottomActions}>
                {
                  <SqureButton
                    style={{ marginLeft: "10px" }}
                    variant={"outlined"}
                    onClick={() => {
                      this.hideConfigurationPopover();
                    }}
                  >
                    Cancel
                  </SqureButton>
                }


                {
                  <SqureButton
                    style={{ marginLeft: "10px" }}
                    variant={"contained"}
                    onClick={() => {
                      this.updateRiskRegisterForm();
                    }}
                  >
                    Update
                  </SqureButton>
                }
              </div>
            </Paper>
          </Popover>
        )}

        {this.state.currentColumn && <Popover
          className={classes.popover}
          id={'add-vendor'}
          open={openAction}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handleClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}>

          <List className={this.state.currentColumn.requestStatus !== "Draft" ? classes.disabledMenu : ''}>
            <ListItem
              button
              onClick={() => {
                this.routeChange(this.state.currentColumn);
                this.handleClose();
              }}
              className={this.state.currentColumn.requestStatus !== "Draft" ? classes.disabled : ''}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Edit Risk
              </Typography>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.updateScore(this.state.currentColumn);
                this.handleClose();
              }}
              className={this.state.currentColumn.requestStatus !== "Draft" ? classes.disabled : ''}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <ScoreIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Update Score
              </Typography>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.handleDelete(this.state.currentColumn)
                this.handleClose();
              }}
              className={this.state.currentColumn.requestStatus !== "Draft" ? classes.disabled : ''}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {/* <Image src={deleteIcon} alt="delete Image" className={classes.deleteImage} /> */}
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Delete
              </Typography>
            </ListItem>
          </List>
        </Popover>}

        {this.props.showUpdateScoreDialog && <UpdateScoreDialog />}

        {this.state.isShowSubMenu && (
          <Popover
            open={this.state.isShowSubMenu}
            anchorEl={this.state.subMenuEl}
            onClose={this.handleCloseSubMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={classes.columnDrawer}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography>Select Visible Column</Typography>
                <SqureButton variant={"outlined"} onClick={this.redirectToForm}>
                  Add More Fields
                </SqureButton>
              </div>
              {colVisibilityData !== null && (
                <DragDropContext onDragEnd={this.onColumnsDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <List>
                          {colVisibilityData.map((col, k) => {
                            if (col.id === 'Action') {
                              return null
                            }
                            return <Draggable key={String(k)} draggableId={String(k)} index={k}>
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <ListItem button style={{ cursor: 'grab' }}>
                                    <DragIndicatorOutlinedIcon id={k} style={{ color: '#D9D9D9', marginRight: 5 }} />
                                    <Typography variant="inherit" style={{ paddingRight: 10 }}>{col.label}</Typography>
                                    {/* {!col.isDynamic && ( */}
                                    <ListItemSecondaryAction>
                                      <Switch
                                        checked={col.isVisible}
                                        onChange={(event) => {
                                          this.updateColVisibilityStatus(col.isVisible, k);
                                        }}
                                        color="primary"
                                        name="is_editable"
                                        inputProps={{ "aria-label": "is_editable checkbox" }}
                                      />
                                    </ListItemSecondaryAction>
                                    {/* )} */}
                                  </ListItem>
                                  <Divider />
                                </div>
                              )}
                            </Draggable>
                          })}
                        </List>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}

              <div className={classes.bottomActions}>
                {
                  <SqureButton
                    style={{ marginLeft: "10px" }}
                    variant={"outlined"}
                    onClick={() => {
                      this.handleCloseSubMenu();
                    }}
                  >
                    Cancel
                  </SqureButton>
                }

                {
                  <SqureButton
                    style={{ marginLeft: "10px" }}
                    variant={"contained"}
                    onClick={() => {
                      this.setVisibilityForColumns();
                    }}
                  >
                    Confirm
                  </SqureButton>
                }
              </div>
            </Paper>
          </Popover>
        )}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(RiskHome));
