import * as Environment from "../util/Environment";
import {HttpFactory} from "./httpService";

export class SellerEvaluationFactory{
    static _instance;
    
    static instance() {
        if (SellerEvaluationFactory._instance == null) {
            SellerEvaluationFactory._instance = new SellerEvaluationFactory();
        }
        return SellerEvaluationFactory._instance;
    }

    async getVendorEvaluation(queryParam,duplicateCall=false){
        const { page = 0, pageSize = 10, status = [], type = [1],query=""} = queryParam;
        const api_server = Environment.api_host("EVALUATION");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/seller/evaluations?t=${timestamp}`;


        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url, {
                page,pageSize,status,type,query
            },duplicateCall);
            return [response,error]
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
    }

}