import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { CircularProgress, Grid, Typography } from "@material-ui/core";
import WinnerTrophy from "assets/images/winner_trophy.svg";
import classnames from "classnames";
import Image from "Components/Common/image.jsx";
import SqureButton from "Components/Common/SqureButton";
import { fetchScores } from "redux/evaluation/criteria/action";
import { fetchEvaluationMetaData, updateEvaluationProduct, updateEvaluationInfo, updateApprovalInfo } from "redux/evaluation/metadata/action";
import EvaluationBar from "../Common/EvaluationBar";
import SearchUserPoper from "Components/Common/UserGroup/SearchUserPoper";
import { getPreviousComponent } from "util/Common";
import { fetchWorkflowComponentDetails } from "redux/workflow/action";

// import {
//   showConfirmCallbackDialog,
//   hideConfirmCallbackDialog
// } from "redux/applications/action";

// import ConfirmCallbackDialog from "Components/Common/ConfirmCallbackDialog";


const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  scores: state.evaludationCriteria.scores,
  fetchScoreProgress: state.evaludationCriteria.fetchScoreProgress,
  evaluationFetchProgress: state.evaluationMetaData.fetchProgress,
  user: state.authUser.user,
  saveProgress: state.evaluationMetaData.evaluationProductUpdateProgress,
  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
  collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
  userTimeline: state.workflow.userTimeline,
  evalInfoUpdateProgress: state.evaluationMetaData.evalInfoUpdateProgress,
  evalInfoUpdateError: state.evaluationMetaData.evalInfoUpdateError,

  evalApprovalInfoUpdateProgress: state.evaluationMetaData.evalApprovalInfoUpdateProgress,
  evalApprovalInfoUpdateError: state.evaluationMetaData.evalApprovalInfoUpdateError,
  evalApprovalInfo: state.evaluationMetaData.evalApprovalInfo,
  fetchworkflowComponentDetailProgress: state.workflow.fetchworkflowComponentDetailProgress,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
});

const connectionActions = {
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  fetchScores: fetchScores,
  updateEvaluationProduct: updateEvaluationProduct,
  // showConfirmCallbackDialog: showConfirmCallbackDialog,
  // hideConfirmCallbackDialog: hideConfirmCallbackDialog,
  updateEvaluationInfo: updateEvaluationInfo,
  updateApprovalInfo: updateApprovalInfo,
  fetchWorkflowComponentDetails: fetchWorkflowComponentDetails
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 10),
    },
    container: {
      backgroundColor: "#FFFFFF",
      borderRadius: 5,
      padding: 24,
      width: "100%",
    },
    title: {
      fontSize: theme.spacing(2),
      color: "#20253A",
      fontWeight: 600,
      marginBottom: 34,
    },
    btns: {
      "& button": {
        boxShadow: "4px 0px 4px #2B2D3703",
        border: "1px solid #20253a33",
        background: "transparent !important",
        borderRadius: 3,
        opacity: 1,
        color: "#707070 !important",
        fontSize: 13,
        width: 195,
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "rgba(75, 134, 255, 0.04)",
        },
      },
    },
    productRoot: {
      flexGrow: 1,
    },
    productCard: {
      minWidth: 120,
      maxWidth: 120,
      minHeight: 120,
      height: "100%",
      border: "1px solid #E2E2E2",
      borderRadius: 10,
      opacity: 1,
      padding: "12px 5px",
      position: "relative",
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      aligmItems: "center",
      justifyContent: "space-between",
    },
    logoContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
      "& > img": {
        width: 40,
        height: "auto",
        marginBottom: 10,
      },
    },
    winner: {
      position: "absolute",
      top: -15,
      right: -15,
      width: 40,
      height: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      borderRadius: 24,
      opacity: 1,
      "& > img": {
        width: 21,
        height: 23,
      },
    },
    productName: {
      color: "#707070",
      fontSize: 13,
      marginBottom: 9,
      fontWeight: 600,
      textAlign: "center",
    },
    score: {
      width: 57,
      height: 22,
      background: "#F4F4F4 0% 0% no-repeat padding-box",
      borderRadius: 15,
      opacity: 1,
      color: "#707070",
      fontSize: 13,
      textAlign: "center",
      fontWeight: 600,
      paddingTop: 3,
    },
    bgGreen: {
      background: "#EAFFF5 0% 0% no-repeat padding-box",
      color: "#2EB77D",
    },
    selected: {
      boxShadow: "0px 3px 25px #398AF526",
      border: "1px solid #398AF5",
    },
  });

class Approval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWorkFlow: true,
      selected: 0,
      selectedProductId: 0,
      products: [],
      isUpdate: false,
      evaluation_id: this.props.match?.params?.evaluation_id,
      component_id: this.props.match?.params?.component_id,
      workflow_id: this.props.match?.params?.workflow_id,
      previousComponentData: null,
      approvalConfirmType: null
    }

    // this.callBack = this.callBack.bind(this);
    this.onCancelWithdraw = this.onCancelWithdraw.bind(this);
  }

  componentDidMount() {
    this.props.fetchEvaluationMetaData(this.state.evaluation_id);
    this.props.fetchScores(this.state.evaluation_id);
    // this.props.fetchWorkflowComponentDetails(this.state.workflow_id, this.state.component_id, this.state.evaluation_id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.evaluationFetchProgress === false && prevProps.evaluationFetchProgress === true) {
      if (!this.props.errorFetch) {
        this.updateProducts();
        // this.checkPreviousComponent();
      }
    }

    if (this.props.fetchScoreProgress === false && prevProps.fetchScoreProgress === true) {
      this.updateProducts();
    }


    if (!this.props.evalApprovalInfoUpdateProgress && prevProps.evalApprovalInfoUpdateProgress) {
      if (this.props.evalApprovalInfoUpdateError === null) {
        this.checkProducts();
      } else {
        this.props.showSnackBar(
          "Failed to update project.",
          "error",
          3000
        );
      }
    }

    // if (!this.props.fetchworkflowComponentDetailProgress && prevProps.fetchworkflowComponentDetailProgress) {
    //   this.checkApprovalConfig();
    // }
  }

  checkPreviousComponent() {
    const { evaluation, userTimeline } = this.props;
    if (evaluation && userTimeline) {
      const { evaluation } = this.props;
      if (evaluation.ApprovalStarted === 0) {
        this.props.showConfirmCallbackDialog();
      }
      let preComponent = getPreviousComponent(userTimeline, this.props.match.params.component_id);
      if (preComponent !== undefined && preComponent !== null && preComponent !== '') {
        this.setState({
          previousComponentData: preComponent
        })
      }
    }
  }

  updateProducts() {
    const { evaluation, scores } = this.props;
    // console.log(evaluation, 'evaluationDDD')
    // console.log(scores, 'scores')
    if (evaluation && scores) {
      const { Products } = evaluation;
      var data = [];
      for (var productId in Products) {
        var product_score = 0;
        var product = Products[productId];
        if (scores[product.ID] !== undefined) {
          var score_obj = scores[product.ID];
          var totalOpted = 0;
          var totalMax = 0;
          for (var criteriId in score_obj) {
            var criteriScore = score_obj[criteriId];
            totalOpted += criteriScore.totalOpted;
            totalMax += criteriScore.totalMax;
          }
          if (totalMax !== 0) {
            product_score = Math.round(parseFloat((parseFloat(totalOpted) / parseFloat(totalMax)) * 100));
          }
        }
        data.push({ Score: product_score, ...product });
      }
      const finalProduct = data.sort((a, b) => b.Score - a.Score);
      if (finalProduct.length) {
        if (finalProduct.length === 1) {
          this.approveProduct(finalProduct[0].ID, finalProduct[0].EntityObjectID);
        } else {
          let approvedProduct = finalProduct.find((product) => product.IsApproved);
          if (approvedProduct) {
            this.approveProduct(approvedProduct.ID, approvedProduct.EntityObjectID);
          }
        }
      }
      // console.log("finalProduct", finalProduct, data);
      this.setState({
        products: finalProduct
      }, () => {
        this.checkProducts()
        // this.checkApprovalConfig();
      })
    }
  }

  approveProduct(id, entityObjId) {
    const { evaluation_id, isWorkFlow, workflow_id, component_id } = this.state;
    const { evaluation } = this.props;
    if (evaluation.ApprovalStarted === undefined || evaluation.ApprovalStarted === null || evaluation.ApprovalStarted === 0) {
      return;
    }
    this.props.updateEvaluationProduct(evaluation_id, entityObjId, { is_approved: true });
    if (isWorkFlow) {
      this.props.history.push("/app/evaluate/" + evaluation_id + "/workflow/" + workflow_id + "/component/" + component_id + "/approval/" + id + '?dispatchTo=true');
    } else {
      this.props.history.push(`/app/evaluate/${evaluation_id}/approval/${id}`);
    }
    this.setState({
      isUpdate: true
    })
  };

  proceedToApproval(id, entityObjId) {
    const { evaluation_id, workflow_id, component_id } = this.state;
    this.props.updateEvaluationProduct(evaluation_id, entityObjId, { is_approved: true });
    this.props.history.push("/app/evaluate/" + evaluation_id + "/workflow/" + workflow_id + "/component/" + component_id + "/approval/" + id);
  };

  checkProducts() {
    const { evaluation_id, products, workflow_id, component_id } = this.state;
    // console.log(products, 'productsproductsproducts')
    if (products.length) {
      if (products.length === 1) {
        this.props.updateEvaluationProduct(evaluation_id, products[0].EntityObjectID, { is_approved: true });
        this.props.history.push("/app/evaluate/" + evaluation_id + "/workflow/" + workflow_id + "/component/" + component_id + "/approval/" + products[0].ID + '?dispatchTo=true');
      } else {
        let approvedProduct = products.find((product) => product.IsApproved);
        if (approvedProduct) {
          this.props.history.push("/app/evaluate/" + evaluation_id + "/workflow/" + workflow_id + "/component/" + component_id + "/approval/" + approvedProduct.ID + '?dispatchTo=true');
        }
      }
    }
  }

  // callBack() {
  //   // this.props.updateEvaluationInfo(this.state.evaluation_id, {
  //   //   approvalStarted: true
  //   // })

  //   this.props.updateApprovalInfo(this.state.evaluation_id, {
  //     type: 'start',
  //     approval_component_id: parseInt(this.state.component_id)
  //   })

  //   this.props.hideConfirmCallbackDialog();
  // }

  onCancelWithdraw() {
    this.props.hideConfirmCallbackDialog();
    if (this.state.previousComponentData !== null) {
      this.props.history.push("/app/evaluate/" + this.state.previousComponentData?.EvaluationId + "/workflow/" + this.state.previousComponentData?.WorkflowId + "/component/" + this.state.previousComponentData?.ComponentId);
    } else {
      window.location.reload()
    }
  }

  checkApprovalConfig() {
    // console.log(this.props.workflowComponentDetail, 'workflowComponentDetail')
    let isCheckDefaultConditions = true;
    if (this.props.workflowComponentDetail?.OrgWorkflowComponentConfigurations?.length) {
      let componentConfig = this.props.workflowComponentDetail?.OrgWorkflowComponentConfigurations.filter(o => o.Form.Name === 'is_final_approval')[0];
      // console.log(componentConfig, 'componentConfig')
      if (componentConfig !== undefined && componentConfig !== null) {
        if (componentConfig?.Value === true || componentConfig?.Value === 'true') {
          this.checkPreviousComponent();
          isCheckDefaultConditions = false;
        }
      }
    }

    if (isCheckDefaultConditions) {
      this.checkProducts();
    }
  }

  render() {
    const { isWorkFlow, selected, products, selectedProductId } = this.state;
    const { classes, user, evaluationFetchProgress, evaluation, collaboratorDialogOpen, collaboratorDialogData } = this.props;

    if (evaluationFetchProgress || products === undefined || products.length <= 0) {
      return <div style={{ textAlign: 'center', marginTop: 50 }}>
        <CircularProgress />
      </div>
    }

    // let messageData = {
    //   head: "Are you sure?",
    //   subHead: "You are about to submit the request for approval. You will not be able to edit your request.",
    //   firstBtn: "Cancel",
    //   secondBtn: "Yes",
    // };


    return (
      <>
        {isWorkFlow ? <EvaluationBar isShowCollabrations={false} isShowEvalDetails={true} isWorkFlow={true} /> : <EvaluationBar isWorkFlow={false} />}
        <div className={classes.root}>
          <div className={classes.container}>
            {(user.Role === "OrgAdmin" || user.Role === "OrgManager") && <Typography className={classes.title}>Select a product for Approval</Typography>}
            {user.Role === "OrgUser" && <Typography className={classes.title}>Product Pending Approval</Typography>}
            <div className={classes.productRoot}>
              <Grid container spacing={6}>
                {!evaluationFetchProgress && products.length > 0 && products.map((product, index) => (
                  <Grid item>
                    <div className={classnames(classes.productCard, selected === product.EntityObjectID ? classes.selected : {})}
                      onClick={() => {
                        this.setState({
                          selected: product.EntityObjectID,
                          selectedProductId: product.ID
                        })
                      }}>
                      {index === 0 && (
                        <div className={classes.winner}>
                          <Image src={WinnerTrophy} alt="winner" />
                        </div>
                      )}
                      <div className={classes.logoContainer}>
                        <Image src={product.ProductLogo} alt={product.Name} />
                        <Typography className={classes.productName}>{product.Name}</Typography>
                      </div>
                      <Grid container justify="center">
                        <div className={classnames(classes.score, index === 0 ? classes.bgGreen : {})}>{product.Score}%</div>
                      </Grid>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
            {!evaluationFetchProgress && (
              <div className={selected ? {} : classes.btns} style={{ marginTop: 32 }}>
                {(user?.Role === "OrgAdmin" || user?.Role === "OrgManager" || evaluation?.UserID === user?.ID) && (
                  <SqureButton
                    disabled={selected === false}
                    ariant="outlined"
                    onClick={() => {
                      if (selected) {
                        this.proceedToApproval(selectedProductId, selected);
                        // if (isWorkFlow) {
                        //   history.push("/app/evaluate/" + evaluation_id + "/workflow/" + match.params.workflow_id + "/component/" + match.params.component_id + "/approval/" + selected)
                        // } else {
                        //   history.push(`/app/evaluate/${match.params.evaluation_id}/approval/${selected}`)
                        // }
                      }
                    }}
                  >
                    Proceed to Approval
                  </SqureButton>
                )}
                {user.Role === "OrgUser" && evaluation?.UserID !== user?.ID && (
                  <SqureButton
                    ariant="outlined"
                    onClick={() => {
                      this.props.history.push("/app/home");
                    }}
                  >
                    Home
                  </SqureButton>
                )}
              </div>
            )}
          </div>
          {collaboratorDialogOpen && collaboratorDialogData?.position === "EvaluationBar" && <SearchUserPoper {...collaboratorDialogData} />}
        </div>
        {/* <ConfirmCallbackDialog
          messageData={messageData}
          callBack={this.callBack}
          closeCallBack={this.onCancelWithdraw}
          closeDialog={this.onCancelWithdraw}
        /> */}
      </>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Approval));
