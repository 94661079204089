import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
import AppBarV2 from "../../../../../AppBarV2";
import { CircularProgress, Tabs, Tab, Card, Divider, Grid, Button, Typography, FormControl, Select, MenuItem } from "@material-ui/core";
import EmailIcon from '@material-ui/icons/Email';
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { updateOauth, fetchOauth } from "redux/oauth/action";
import { showSnackBar } from "redux/snackbar/action";
import General from "./General";
import Scopes from "./Scopes";

const connectedProps = (state) => ({
  updateOauthProgress: state.oauth.updateOauthProgress,
  updateOauthError: state.oauth.updateOauthError,
  updateOauthSuccess: state.oauth.updateOauthSuccess,
  fetchOauthProgress: state.oauth.fetchOauthProgress,
  fetchOauthError: state.oauth.fetchOauthError,
  oauth: state.oauth.oauthDetails,
  fetchOauthScopeProgress: state.oauth.fetchOauthScopeProgress,
  fetchOauthScopeError: state.oauth.fetchOauthScopeError,
});

const connectionActions = {
  updateOauth: updateOauth,
  fetchOauth: fetchOauth,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    formControl: {
      ...CommonCss.formControl,
    },
    tickMark: {
      color: "#4b86f8",
      border: "1px solid #4b86f8",
      marginRight: 10,
      minWidth: "50px !important",
      "&:hover": {
        color: "#4b86f8",
      },
    },
    paper: {
      padding: 20,
    },
    card: {
      maxWidth: "1000px",
      margin: "0 auto",
      padding: 30,
    },
    flexHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    flexName: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    buttonGroup: {
      alignItems: "center",
      position: "relative",
      zIndex: 1000,
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 6,
      marginBottom: 5,
      cursor: "pointer",
    },
    supportLabel: {
      fontWeight: 600,
      marginTop: 3,
      marginBottom: 3,
      display: "inline-block",
      paddingRight: 10,
    },
    formGroup: {
      display: "flex",
      alignItems: "center",
    },
    emailWrap: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      marginBottom: 20,
      marginTop:5
    }
  });

class AppInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      status: 1,
      name: "",
      email: "",
      isEdit: false,
    };
  }

  componentDidMount() {
    let oauthId = this.props.match?.params?.id;
    if (oauthId) {
      this.props.fetchOauth(oauthId);
    } else {
      this.props.showSnackBar("Failed to fetch application details", "error", 3000);
      this.props.history.push("/app/settings/oauth");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetchOauthProgress && !this.props.fetchOauthProgress) {
      if (this.props.fetchOauthError) {
        this.props.showSnackBar("Failed to fetch application details", "error", 3000);
        this.props.history.push("/app/settings/oauth");
      } else if (this.props.oauth) {
        this.setState({
          name: this.props.oauth.Name,
          status: this.props.oauth.Status,
          email: this.props.oauth.IntegrationUserEmail,
        });
      }
    }

    if (prevProps.updateOauthProgress && !this.props.updateOauthProgress) {
      if (this.props.updateOauthError) {
        this.setState({
          name: this.props.oauth.Name,
          status: this.props.oauth.Status,
          email: this.props.oauth.IntegrationUserEmail,
        });
        this.props.showSnackBar("Failed to update application", "error", 3000);
      } else {
        this.props.showSnackBar("Application updated successfully", "success", 3000);
      }
    }
  }

  updateName = () => {
    if (this.state.name.length) {
      this.setState({ isEdit: false });
      this.props.updateOauth(this.props.match?.params?.id, { name: this.state.name, email: this.state.email  });
    } else {
      this.props.showSnackBar("Application name can not be empty", "error", 3000);
    }
  };

  render() {
    const classes = this.props.classes;
    const { name, email, status, tabValue } = this.state;

    return (
      <div>
        <AppBarV2 title={"OAuth"} withBack={true} />
        <div className={classes.rootContainer}>
          <div className={classes.body}>
            <Card className={classes.card}>
              {this.props.fetchOauthProgress ? (
                <CircularProgress style={{ margin: "0 auto", display: "block" }} />
              ) : (
                <>
                  <div className={classes.flexHeader}>
                    {!this.state.isEdit ? (
                      <div className={classes.flexName}>
                        <Typography variant={"h6"}>{name}</Typography>
                        <EditIcon
                          fontSize={"small"}
                          className={classes.editIcon}
                          onClick={() => {
                            this.setState({ isEdit: true });
                          }}
                        />
                      </div>
                    ) : (
                      <Grid container spacing={1} className={classes.buttonGroup}>
                        <Grid item sm={8}>
                          <div className={classes.formGroup}>
                            <span className={classes.supportLabel} style={{ width: "100%" }}>
                              Application Name:{" "}
                            </span>
                            <input
                              style={{ minHeight: 40 }}
                              className={classes.formControl}
                              placeholder="Enter the Application Name"
                              onChange={(event) => {
                                this.setState({ name: event.target.value });
                              }}
                              value={name}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={4}>
                          <Button
                            className={classes.tickMark}
                            onClick={() => {
                              this.updateName();
                            }}
                          >
                            <DoneIcon />
                          </Button>
                          <Button className={classes.tickMark} onClick={() => this.setState({ isEdit: false, name: this.props.oauth.Name })}>
                            <CloseIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    
                    <div className={classes.formItem}>
                      <FormControl variant="outlined" size="small">
                        <Select
                          disabled={false}
                          value={status}
                          onChange={(e) => {
                            this.setState({ status: e.target.value });
                            this.props.updateOauth(this.props.match?.params?.id, { status: e.target.value });
                          }}
                        >
                          <MenuItem value={1} style={{ fontSize: 13 }}>
                            Active
                          </MenuItem>
                          <MenuItem value={2} style={{ fontSize: 13 }}>
                            Deactive
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className={classes.emailWrap}>
                      <EmailIcon style={{fontSize:24}}/>
                      <Typography style={{fontSize:16}}>{email}</Typography>
                  </div>
                  <Divider />
                  <div className={classes.tabSection}>
                    <Tabs
                      value={tabValue}
                      onChange={(event, newValue) => {
                        this.setState({ tabValue: newValue });
                      }}
                      aria-label="simple tabs example"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="General" key="expent-categories" />
                      <Tab label="OAuth API Scopes" key="inventory" />
                    </Tabs>
                  </div>

                  {tabValue === 0 && <General {...this.props} />}

                  {tabValue === 1 && <Scopes {...this.props} appName={name} />}
                </>
              )}
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(AppInfo));
