import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "../Style/index.css";

import { getComponentType, getTargetComponentId } from "./FlowData"
import {
  addNewAutomationAction
} from 'redux/automations/action';

import SubMenu from "./SubMenu"
const connectedProps = (state) => ({
  automationConnections: state.automation.actionConnections,
});

const connectionActions = {
  addNewAutomationAction: addNewAutomationAction
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  triggerNode: {
    border: '1px solid #222'
  }
});

class Arrow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMenu: false
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  onConnect = (params) => {
    // console.log('handle onConnect', params)
  };

  hideShowMenu(state) {
    this.setState({
      isShowMenu: state
    })
  }

  handleActionTypeClick(component_type) {
    let componentData = getComponentType(component_type)
    const connectionId = getTargetComponentId(this.props.forComponent.id, this.props.automationConnections)
    const params = {
      ...componentData,
      connectionID: connectionId
    }
    this.props.addNewAutomationAction(this.props.forComponent?.object?.AutomationID, params)
    this.hideShowMenu(false)
  }

  addNewComponent = (component_type) => {
    // console.log(component_type, 'Type')
    // console.log(this.props.forComponent, 'TyforComponentpe')
    // let componentData = getComponentType(component_type)
    // console.log(componentData, 'TyforComponentpe')

    // const { automationComponents } = this.props;
    // const { automationDetails } = this.state;
    // console.log(automationComponents, 'automationComponents')
    // const params = {
    //   ...componentData,
    //   sourceComponentID: automationComponents[automationComponents.length - 1].ID
    // }
    // this.props.addNewAutomationAction(automationDetails?.ID, params)
  }


  render() {
    const { height, label, isShowArrow, type, isShowAddOption } = this.props;

    return (
      <>
        <div class={"straight-arrow straight-arrow_" + type}>
          <div class="straight-arrow__container" style={{ height: height }}>
            <div class="adaptive-arrow">
              <svg preserveAspectRatio="none" viewBox="0 0 2 1" class="adaptive-arrow__start" style={{ flexBasis: 18 }}>
                <path fill="none" d="M 1 0 v 18"></path>
              </svg>
              {label && <div class="adaptive-arrow__label">{label}</div>}
              <svg preserveAspectRatio="none" viewBox="0 0 2 1" class="adaptive-arrow__end">
                <path d="M 1 0 v 1" fill="none">
                </path>
              </svg>
              {isShowArrow && <div class="adaptive-arrow__pointer adaptive-arrow__pointer_with-icon"></div>}
            </div>
          </div>

          {isShowAddOption && <SubMenu
            forComponent={this.props.forComponent}
          />}

        </div>
      </>
    );
  }
}

Arrow.defaultProps = {
  label: null,
  isShowAddOption: true
};

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Arrow));