import { createStyles, withStyles } from "@material-ui/core/styles";
import { IconButton, Drawer, Typography, Button, FormControlLabel, Checkbox, Select, MenuItem } from "@material-ui/core";

import * as Environment from "util/Environment";
import axios from "axios";
import { CommonFn } from "services/commonFn";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import classnames from "classnames";
// components
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as XLSX from "xlsx";

const sellerQuestionFields = [
  { key: "question_title", label: "Question Title", value: { cell_id: "", data: "" } },
  { key: "question", label: "Question", value: { cell_id: "", data: "" } },
  { key: "answer", label: "Response", value: { cell_id: "", data: "" } },
  { key: "answer_options", label: "Response Options", value: { cell_id: "", data: [] } },
  { key: "note", label: "Comments", value: { cell_id: "", data: "" } },
];

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    drawer: {
      width: "23%",
      flexShrink: 0,
      transition: "width 300ms",
      background: "#F7F7F7",
    },
    drawerPaper: {
      width: "23%",
      transition: "width 300ms",
      paddingTop: theme.spacing(2),
      background: "#ffffff",
    },

    cartContainer: {
      padding: "0px 20px",
      "&.close": {
        opacity: 0,
      },
    },
    title: {
      fontSize: theme.spacing(2.4),
      // textAlign: "center",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    title2: {
      fontSize: theme.spacing(2.4),
      marginTop: theme.spacing(1),
    },
    closeIcon: {
      width: "36px",
      padding: "8px",
    },
    btnToggle: {
      opacity: 0,
      // transition: 'opacity 5s',
      // '-webkit-transition': 'opacity 5s',
      position: "fixed",
      width: "30px",
      height: "30px",
      background: "#ffffff",
      // display: 'inline-block',
      border: "2px solid #202639",
      zIndex: 99999,
      borderRadius: "50%",
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&.max": {},
      "&.min": {
        "& svg": {
          marginLeft: "3px",
        },
      },
      "& svg": {
        width: "17px",
        height: "17px",
      },
    },
    drawerCompressed: {
      width: theme.spacing(30),
      flexShrink: 0,
    },
    drawerPaperCompressed: {
      width: theme.spacing(30),
      paddingTop: theme.spacing(5),
      transition: "width 300ms",
      "@media (max-width: 991.98px)": {
        width: "6em",
        "& [class*='CDrawer-menu_item-']": {
          display: "flex",
          placeContent: "center",
        },
      },
    },
    rangeDetails: {
      background: "#f7f7f7",
      padding: 10,
      borderRadius: 4,
    },
    processAction: {
      marginTop: 10,
      textAlign: "right",
    },
    mappings: {
      marginTop: 10,
    },
    maps: {
      marginTop: 10,
    },
    map: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 20,
      width: "100%",
    },
    mapName: {
      flex: 0.6,
    },
    nameM: {
      fontSize: 14,
    },
    mapSelection: {
      flex: 0.4,
    },
    fieldSelect: {
      width: "100%",
    },
    actionBtn: {
      marginTop: 10,
      textAlign: "right",
    },
    Btn: {
      marginLeft: 10,
    },
  });

class RequestDetailsCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHeadersAvailable: false,
      columnOptions: [],
      formFields: sellerQuestionFields,
      optionName: "",
      loading: false,
      optionsObject: {},
      filePath: "",
    };
  }

  componentDidMount() {
    this.setState({
      formFields: sellerQuestionFields,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isProcessed && !prevProps.isProcessed) {
      this.initColumnOptions();
    }
  }

  initColumnOptions() {
    const { selectedCells } = this.props;
    // const { isHeadersAvailable } = this.state;
    let columns = [
      // {
      // label: 'Select Column',
      // value: '0',
      // colName: '0:0'
      // }
    ];
    if (selectedCells) {
      selectedCells.columns.forEach(function (cl, index) {
        let label = cl.name;
        // if (isHeadersAvailable) {
        // label = label + " (" + cl.text + ")";
        // }
        columns.push({
          label: label,
          value: cl.name,
        });
      });
    }
    this.setState({
      columnOptions: columns,
    });
  }

  updateColumnMapping(index, value) {
    let fields = this.state.formFields;
    let field = fields[index];
    field.value = value;
    this.setState({
      formFields: fields,
    });
  }

  fetchFileOptions = () => {
    this.setState({ loading: true });
    const file = this.props.file;
    var formData = new FormData();
    formData.append("folder", `/sellerApp/assessment/import/`);
    const timestamp = new Date().getTime();
    formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf(".") + 1)}`);
    const api_server = Environment.api_host("STORAGE");
    const url = `${api_server}/add?t=${timestamp}`;
    axios
      .post(url, formData, {
        headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        console.log("response", response);
        let dataObj = {
          sheet_name: this.props.selectedCells?.SelectedSheet,
          file_path: response?.data?.filepath,
        };
        const api_server2 = Environment.api_host("DOMAIN");
        const url2 = `${api_server2}/requirements/parse?sheet_name=${dataObj.sheet_name}&file_path=${dataObj.file_path}&t=${timestamp}`;
        axios
          .post(url2, {}, { headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "multipart/form-data" } })
          .then((response2) => {
            console.log("response2", response2);
            this.setState({ loading: false, optionsObject: response2.data, filePath: response?.data?.filepath }, () => {
              this.submit();
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  submit() {
    const { selectedCells } = this.props;
    const { formFields, isHeadersAvailable, optionsObject, filePath } = this.state;
    let dataMap = selectedCells.dataMap;

    let startRow = selectedCells.SelectedRowId;
    let firstRow = selectedCells.SelectedRowId;
    let endRow = selectedCells.EndRowId;
    let tableDatas = [];
    for (var i = startRow; i <= endRow; i++) {
      if (isHeadersAvailable && firstRow === i) {
      } else {
        const row = i;
        let colObj = {};
        formFields.forEach(function (ff) {
          var cellRef = XLSX.utils.decode_cell(ff.value);
          let col = cellRef.c;
          const cellId = XLSX.utils.encode_cell({ r: row, c: col });

          if (dataMap[row] !== undefined && dataMap[row][col] !== undefined) {
            colObj[ff.key] = {
              cell_id: cellId,
              data: dataMap[row][col] || "",
            };
          }
          if (ff.key === "answer") {
            if (optionsObject[cellId] && optionsObject[cellId].length > 0) {
              colObj["answer_options"] = {
                cell_id: cellId,
                data: optionsObject[cellId].split(","),
              };
            }
          }
        });
        if (Object.keys(colObj).length > 0) {
          tableDatas.push(colObj);
        }
      }
    }

    this.props.showFinalizeDataDialog(true, {
      formFields: formFields,
      tableDatas: tableDatas,
      filePath: filePath,
    });
    console.log(tableDatas, "tableDatas");
  }

  cancel() {
    this.props.setIsProcessed(false);
    this.setState({
      formFields: sellerQuestionFields,
    });
  }

  render() {
    const { classes, selectedCells, isProcessed, expanded } = this.props;
    const { formFields, columnOptions } = this.state;
    console.log(formFields, columnOptions, selectedCells, "formFields");
    return (
      <>
        <Drawer
          className={classnames(classes.appDrawer, classes.drawer)}
          variant="permanent"
          classes={{
            paper: this.props.expanded ? classes.drawerPaper : classes.drawerPaperCompressed,
          }}
          anchor="right"
        >
          <IconButton
            className={classes.closeIcon}
            onClick={() => {
              this.props.setCollapsed(expanded ? false : true);
            }}
          >
            {this.props.expanded ? <ArrowForwardIosIcon fontSize="small" /> : <ArrowBackIosIcon fontSize="small" />}
          </IconButton>
          <div className={classnames(classes.cartContainer)}>
            <div style={{ paddingBottom: "2rem" }}>
              <Typography variant={"h5"} className={classes.title}>
                Selected Fields
              </Typography>

              <Typography variant={"h5"} className={classes.rangeDetails}>
                {selectedCells?.selectedRange}
              </Typography>

              {selectedCells && !isProcessed && (
                <div className={classes.processAction}>
                  <Button
                    className={classes.Btn}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      this.props.setCollapsed(true);
                      this.props.setIsProcessed(true);
                    }}
                  >
                    Process
                  </Button>
                </div>
              )}

              {expanded && selectedCells && isProcessed && (
                <div className={classes.mappings}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isHeadersAvailable}
                        onChange={(event) => {
                          this.setState(
                            {
                              isHeadersAvailable: event.target.checked,
                            },
                            () => {
                              this.initColumnOptions();
                            }
                          );
                        }}
                        name="headers_available"
                      />
                    }
                    label="Does selection have headers"
                  />

                  <div className={classes.maps}>
                    {formFields.map((col, index) => {
                      if (col.key === "answer_options") {
                        return null;
                      }
                      return (
                        <div className={classes.map}>
                          <div className={classes.mapName}>
                            <Typography variant={"h5"} className={classes.nameM}>
                              {col.label}
                            </Typography>
                          </div>
                          <div className={classes.mapSelection}>
                            <Select
                              className={classes.fieldSelect}
                              value={col.value}
                              onChange={(e) => {
                                this.updateColumnMapping(index, e.target.value);
                              }}
                            >
                              {columnOptions.map((cl, i) => {
                                return <MenuItem value={cl.value}>{cl.label}</MenuItem>;
                              })}
                            </Select>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {selectedCells && isProcessed && (
                    <div className={classes.actionBtn}>
                      <Button
                        className={classes.Btn}
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={() => {
                          this.cancel();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.Btn}
                        variant="contained"
                        disabled={this.state.loading}
                        size="large"
                        color="primary"
                        onClick={() => {
                          this.fetchFileOptions();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Drawer>
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(RequestDetailsCart));
