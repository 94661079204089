import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, List, ListItem, ListItemAvatar, ListItemText, Avatar } from "@material-ui/core";
import classnames from "classnames";
import { Dialog, DialogContent, Slide } from '@material-ui/core';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
// redux
import AddUserDialog from "./AddUserDialog";

import { hideSellerCollaboratorDialog, showAddNewUserDialog } from "redux/seller/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    open: state.seller.sellerCollaboratorDialogOpen,
    isAddNewOpen: state.seller.addNewCollaboratorDialogOpen,
    sellerCollaboratorData: state.seller.sellerCollaboratorDialogData,
});

const connectionActions = {
    hideSellerCollaboratorDialog: hideSellerCollaboratorDialog,
    showAddNewUserDialog: showAddNewUserDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    collaboratorContainer: {
        minWidth: '310px',
        // padding: "20px",
        '& ul': {
            padding: 0
        }
    },
    collaboratorHeader: {
        marginTop: 0,
        // padding: "20px",
        paddingBottom: 0
    },
    searchCol: {
        paddingTop: '16px',
        paddingBottom: '16px',
        '& span': {
            textAlign: 'left',
            fontSize: 12,
            color: '#202124',
            float: 'right'
        }
    },
    title: {
        textAlign: "left",
        fontWeight: 400,
        // color: '#20253A',
        // fontSize: '1.375rem',
        // marginBottom: '14px',
        color: '#20253A',
        display: 'flex',
        placeItems: 'center'
    },
    input: {
        // background:"#F9FAFC",
        padding: theme.spacing(0.5, 2),
        fontSize: '.875rem',
        marginRight: 15,
        // border: '0.5px solid #B6B6B6',
        // borderRadius: '3px',
        background: '#f8f9fa',
        width: '100%',
        border: '0px',
        borderBottom: '1px solid #4b86f7',
        outline: '1px solid transparent',
        borderRadius: '6px 6px 0 0',
        minHeight: 27
    },
    listItem: {
        borderBottom: '1px solid #EEEEEE',
        padding: '10px 0px',
        '&:last-child': {
            borderBottom: 'unset'
        }
    },
    avatar: {
        width: '2.25rem',
        height: '2.25rem',
        fontSize: '1.125rem'
    },
    userEmail: {
        fontSize: '13px',
        color: '#4B4B4B'
    },
    userEmailText: {
        cursor: 'pointer',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        placeItems: 'start',
        '& svg': {
            position: 'relative',
            left: '5px',
            marginRight: 10
        }
    },
    isMember: {
        //textDecoration: 'line-through'
    },
    userState: {
        position: 'relative',
        display: 'inline-block',
        width: '40px',
        height: '12px',
        background: '#DDDDDD',
        opacity: 1,
        borderRadius: '20px',
        cursor: 'pointer',
        '&::after': {
            content: "''",
            height: '20px',
            width: '20px',
            display: 'inline-block',
            position: 'absolute',
            background: ' #DDDDDD',
            border: ' 5px solid #A0A5BA',
            borderRadius: '50%',
            top: '-4px',
            left: '0px'
        },
        '&.active': {
            '&::after': {
                background: '#81A5F2',
                borderColor: '#4175DF',
                left: '20px'
            }
        },
        '&.inprogress': {
            cursor: 'not-allowed'
        }
    },
    newUserBtn: {
        background: "#3C3C3C",
        borderRadius: 5,
        color: '#ffffff !important',
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        minWidth: 140,
        marginLeft: '10px',
        "&:hover": {
            background: "#3C3C3C",
            color: '#ffffff !important',
        },
    },
    shareIcon: {
        background: '#4175DF',
        color: '#ffffff',
        width: '40px',
        height: '40px',
        display: 'inline-block',
        borderRadius: '50%',
        textAlign: 'center',
        lineHeight: '50px',
        marginRight: '10px',
        '& svg': {
            marginTop: '6px'
        }
    },
    footerAction: {
        padding: '0 20px 20px 20px',
        textAlign: 'right',
        '& button': {
            backgroundColor: '#4175DF',
            color: 'white'
        },
        '& button:hover': {
            backgroundColor: '#4175DF',
            color: 'white'
        }
    },
    btnAdd: {
        marginRight: 10
    }
});

class SearchUserPoper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            user: null,
            editValue: {},
            showActionMenu: null,
            showActionId: null,
            users_list: [{
                Name: 'Sunil',
                Email: 'Sunil@gmail.com'
            }, {
                Name: 'Anil',
                Email: 'Anil@gmail.com'
            }, {
                Name: 'Bikash',
                Email: 'Bikash@gmail.com'
            }]
        };
        this.avatarName = this.avatarName.bind(this);
    }

    componentDidMount() {
        this.setState({ query: '', user: null })
    }

    componentDidUpdate(prevProps) {

    }

    avatarName(name) {
        return name.substring(0, 1);
    }

    render() {
        const classes = this.props.classes;
        return <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                open={this.props.open}
                onClose={(event, reason) => {
                    this.props.hideSellerCollaboratorDialog()
                }}
                aria-labelledby="add-collaborator-dialog"
                TransitionComponent={Transition}
                className={classnames(classes.dialog)}
            >
                <DialogContent >
                    <div className={classes.collaboratorContainer}>
                        <div className={classes.collaboratorHeader}>
                            {/* {this.props.showToggle && ' or Approver'} */}
                            <Typography className={classes.title}>
                                <span className={classes.shareIcon}><GroupAddOutlinedIcon /></span> Add users and teams
                            </Typography>
                        </div>
                    </div>

                    {this.props.sellerCollaboratorData.existingUsers !== null && this.props.sellerCollaboratorData.existingUsers.length > 0 &&
                        <List dense={true}>
                            {
                                this.props.sellerCollaboratorData.existingUsers.map((user, k) => {
                                    return <ListItem key={k} className={classes.listItem}>
                                        <ListItemAvatar className={classes.listAvatar}>
                                            <Avatar className={classes.avatar}>
                                                {this.avatarName(user.Name)}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={
                                            <div style={{ display: 'flex', placeContent: 'center space-between' }}>
                                                <span className={classes.userEmailText}>
                                                    <span style={{ fontWeight: 500, color: '#202124' }}>{user.Name}</span>
                                                    <span style={{ color: '#5f6368' }}>{user.Email}</span>
                                                </span>
                                            </div>
                                        } />
                                    </ListItem>
                                })
                            }
                        </List>
                    }

                    {this.props.sellerCollaboratorData.existingUsers !== null && this.props.sellerCollaboratorData.existingUsers.length <= 0 &&
                        <List dense={true}>
                            <ListItem className={classes.listItem}>
                            <ListItemText primary={
                                <div style={{ display: 'flex', placeContent: 'center space-between' }}>
                                    <span className={classes.userEmailText}>
                                        No collaborator added yet
                                    </span>
                                </div>
                            } />
                        </ListItem>
                        </List>
                    }

                    {
                        this.props.isAddNewOpen &&
                        <AddUserDialog
                            addUserType={this.props.sellerCollaboratorData.addUserType}
                            isEdit={false}
                            editValue={'Sunil'}
                        />
                    }

                </DialogContent>
                <div className={classes.footerAction}>
                    <Button className={classes.btnAdd} onClick={() => { this.props.showAddNewUserDialog() }}>
                        + Add User
                    </Button>
                    <Button className={classes.btn} onClick={() => { this.props.hideSellerCollaboratorDialog() }}>
                        Done
                    </Button>
                </div>
            </Dialog>



        </>
    }
}

SearchUserPoper.defaultProps = {
    searchTitle: '',
    selectedUser: [],
    showToggle: false,
    isfromSettingsTemplate: false,
    criterias: [],
    isNew: false,
    updateNewCollaborator: () => { },
    removeCollaborator: () => { },
    hideCollaborator: false,
}

export default connector(compose(
    withStyles(styles)
)(SearchUserPoper));