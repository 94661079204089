import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CommonCss from 'commonCss';
import { CommonFn } from "services/commonFn";
import moment from "moment";
import AppBarV2 from "Components/Application/AppBarV2";
import { isEnabledEvaluate } from "util/Common";
import * as Validator from "util/Validation";

import MessageDialog from "Components/Common/MessageDialog";
import { isEnabledReview } from "util/Common";
import { addOrgSettings, fetchAllOrgSettings } from "redux/org/user/action";

// Dilogs
import { getUpdatedWorkflowColumns, getFinalTrackingColumns, updateTrackingColumns, workflowColumns } from 'util/Columns';
import { isEnabledProjectTracking } from "util/Common";
import { getOrgWorkflow } from "redux/evaluation/workflow/action";
import { updateEvaluationMetaData, undoEvaluation } from "redux/evaluation/metadata/action";

import {
    showMessageDialog,
    hideMessageDialog,
} from "redux/applications/action";
import { deleteProductReview } from "redux/product/orgProduct/action";
import ListingTable from "Components/Common/Tables/ListingTable";
import { showSnackBar } from "redux/snackbar/action";
import {
    fetchOrgWorkflows, exportWorkflowData, showAddFormColumnsDialog, showWorkflowViewsDialog,
    fetchWorkflowProjectTracking, setActiveProjectsTab, fetchProjectRequestTypes
} from "redux/workflow/action";
import { fetchOrgForms, fetchOrgFormUsingId } from "redux/forms/org/action";
import { CircularProgress } from "@material-ui/core";
import { showEvaluationProductSelectionDialog, hideEvaluationProductSelectionDialog } from "redux/evaluation/metadata/action";
import ProductSelectionDialog from "Components/Application/Components/Evaluate/Components/Evaluation/Common/ProductSelectionDialog";
import ViewsDialog from "./Common/ViewsDialog";
import AddUpdateViewDialog from "./Common/AddUpdateViewDialog";

const connectedProps = (state) => ({
    fetchWorkflowsProgress: state.workflow.fetchWorkflowsProgress,
    fetchWorkflowsError: state.workflow.fetchWorkflowsError,
    // selectedColumnsIds: state.workflow.selectedColumnsIds,
    orgWorkflows: state.workflow.orgWorkflows,
    orgWorkflowsTotal: state.workflow.orgWorkflowsTotal,
    deleteProductReviewProgress: state.orgProduct.deleteProductReviewProgress,
    deleteProductReviewError: state.orgProduct.deleteProductReviewError,
    user: state.authUser.user,
    fetchExportWorkflowDataLInk: state.workflow.fetchExportWorkflowDataLInk,
    fetchExportWorkflowDataLInkProg: state.workflow.fetchExportWorkflowDataLInkProg,
    userType: state.authUser.user?.Role,
    orgUser: state.orgUser,
    evalTitleUpdateProgress: state.evaluationMetaData.evalTitleUpdateProgress,
    evalTitleUpdateError: state.evaluationMetaData.evalTitleUpdateError,
    fetchOrgFormsProgress: state.orgForms.fetchOrgFormsProgress,
    orgForms: state.orgForms.forms,

    fetchWorkflowsTrackingProgress: state.workflow.fetchWorkflowsTrackingProgress,
    fetchWorkflowsTrackingError: state.workflow.fetchWorkflowsTrackingError,
    orgWorkflowsTracking: state.workflow.orgWorkflowsTracking,

    orgWorkflowsTrackingTotal: state.workflow.orgWorkflowsTrackingTotal,
    trackingFields: state.workflow.trackingFields,

    orgFormData: state.orgForms.orgFormData,
    fetchOrgFormsByIdProgress: state.orgForms.fetchOrgFormsByIdProgress,
    activeProjectsTab: state.workflow.activeProjectsTab,

    orgWorkFlowProgress: state.evaluationWorkflow.orgWorkFlowProgress,
    orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
    orgWorkFlowError: state.evaluationWorkflow.orgWorkFlowError,

    updateProgress: state.evaluationMetaData.updateProgress,

    undoEvaluationProgress: state.evaluationMetaData.undoEvaluationProgress,
    errorUndoEvaluation: state.evaluationMetaData.errorUndoEvaluation,

    showEvaluationProductSelectionDialog: state.evaluationMetaData.showEvaluationProductSelectionDialog,

    messageDialogOpen: state.applications.resMessageDialogOpen,

    fetchProjectReqTypesProgress: state.workflow.fetchProjectReqTypesProgress,
    fetchProjectReqTypesError: state.workflow.fetchProjectReqTypesError,
    requestTypes: state.workflow.requestTypes,

    showWorkflowViewDialog: state.workflow.showWorkflowViewDialog,
    showWorkflowViewData: state.workflow.showWorkflowViewData,
    fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
});

const connectionActions = {
    fetchOrgWorkflows: fetchOrgWorkflows,
    exportWorkflowData: exportWorkflowData,
    showAddFormColumnsDialog: showAddFormColumnsDialog,
    showMessageDialog: showMessageDialog,
    hideMessageDialog: hideMessageDialog,
    showSnackBar: showSnackBar,
    deleteProductReview: deleteProductReview,
    fetchOrgForms: fetchOrgForms,
    addOrgSettings: addOrgSettings,
    showWorkflowViewsDialog: showWorkflowViewsDialog,
    fetchWorkflowProjectTracking: fetchWorkflowProjectTracking,
    fetchOrgFormUsingId: fetchOrgFormUsingId,
    setActiveProjectsTab: setActiveProjectsTab,
    getOrgWorkflow: getOrgWorkflow,
    updateEvaluationMetaData: updateEvaluationMetaData,
    undoEvaluation: undoEvaluation,
    showEvaluationProductSelectionDialog: showEvaluationProductSelectionDialog,
    hideEvaluationProductSelectionDialog: hideEvaluationProductSelectionDialog,
    fetchProjectRequestTypes: fetchProjectRequestTypes,
    fetchAllOrgSettings: fetchAllOrgSettings

};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    rootContainer: CommonCss.rootContainer,
    workflowPaper: {
        padding: 20
    }
});

class Workflows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 20,
            query: '',
            fetchWithStatus: [2, 3, 4],
            activeWorkflowTab: this.props.activeTab ? this.props.activeTab : this.props.activeProjectsTab, // 0:All,1:Requested by me,2:Assigned to me,3:Project Tracking
            isUpdateData: true,
            sortField: 'creation_date',
            sortType: 'desc',
            statusFilter: ['completed', 'in_progress', 'closed'],
            projectStatusFilter: ['Draft', 'PendingApproval', 'Approved', 'Rejected', 'CLOSED', 'FINISHED'],
            workflowNameFilter: '0',
            requestTypeFilter: '0',
            workflowNameOptions: [],
            projectOwnerIds: [],
            requestorIds: [],
            formFieldsIds: [],
            deleteId: null,
            startDate: CommonFn.getPreviousDate(90),
            endDate: new Date(),
            dates: null,
            duration: 0,
            tabsOptions: [],
            projectTrackingForm: '',
            updateTrackingForm: false,
            trackingFormItemIds: [],
            trackingFormItems: [],
            formItemsIdMap: {},
            trackingFormFields: [],
            firstCallDone: false,
            filterTabWiseHistory: {},
            isChangesInFilters: false,
            showFilters: [],
            showProjectDeleteConfirm: false,
            projectColumnWithNames: null,
            configSuccessMsg: '',
            requestTypes: [],
            tabsHashMap: {
                0: 'all',
                1: 'requested_by_me',
                2: 'assigned_to_me',
                3: 'project_tracking',
            },
            columns: [],
            selectedView: null,
            viewsList: [],
            addUpdateDialog: false,
        }
        this.divRef = React.createRef();
        this.updatePageAndSize = this.updatePageAndSize.bind(this);
        this.updateSearchString = this.updateSearchString.bind(this);
        this.selectNewTab = this.selectNewTab.bind(this);
        this.updateSorting = this.updateSorting.bind(this);
        this.callBack = this.callBack.bind(this);
    }

    componentDidMount() {
        this.props.fetchProjectRequestTypes()
        this.props.hideEvaluationProductSelectionDialog()
        this.props.fetchOrgForms(0, 1000);
        this.props.getOrgWorkflow({ types: [1], page: 0, pageSize: 1000 })
        this.setWorkflowFormFields();
        this.setTrackingFormFields();
        this.props.fetchAllOrgSettings()
        setTimeout(() => {
            this.getTabs()
        }, 2000);
    }

    componentDidUpdate(prevProps) {

        if (this.props.evalTitleUpdateProgress === false && prevProps.evalTitleUpdateProgress === true) {
            if (this.props.evalTitleUpdateError === null) {
                this.props.showSnackBar('Request Updated Successfully', "success", 3000);
            } else {
                this.props.showSnackBar('Failed to update Procurement Owner', "error", 3000);
            }
        }

        if (this.props.fetchWorkflowsProgress === false && prevProps.fetchWorkflowsProgress === true) {
            if (this.props.fetchWorkflowsError === null) {
                this.setState({
                    isUpdateData: this.state.isUpdateData === true ? false : true
                })
            }
        }

        if (!this.props.fetchWorkflowsError && prevProps.fetchWorkflowsError) {
            this.setState({
                isUpdateData: this.state.isUpdateData === true ? false : true
            })
        }

        if (!this.props.deleteProductReviewProgress && prevProps.deleteProductReviewProgress) {
            if (!this.props.deleteProductReviewError) {
                this.fetchData();
            } else {
                this.props.showSnackBar(this.props.deleteProductReviewError, "error", 3000);
            }
        }

        if (this.props.fetchExportWorkflowDataLInkProg === false && prevProps.fetchExportWorkflowDataLInkProg === true) {
            const { fetchExportWorkflowDataLInk } = this.props;
            if (fetchExportWorkflowDataLInk) {
                const link = document.createElement('a');
                link.href = fetchExportWorkflowDataLInk;
                link.setAttribute('download', "request_data.xlsx");
                this.divRef.current.appendChild(link)
                link.click();
                link.parentNode.removeChild(link);
            } else {
                this.props.showSnackBar("Failed to export data", "error", 3000)
            }
        }

        if (this.props.orgUser.addOrgSettingsInprogress === false && prevProps.orgUser.addOrgSettingsInprogress === true) {
            if (this.props.orgUser.addOrgSettingsFailure == null) {
                this.props.showSnackBar("Updated Successfully", "success", 3000);
                this.getTrackingForm('UPDATE');
            } else {
                this.props.showSnackBar("Failed to update", "error", 3000);
            }
            this.props.fetchAllOrgSettings()
        }

        if (this.props.fetchWorkflowsTrackingProgress === false && prevProps.fetchWorkflowsTrackingProgress === true) {
            if (this.props.fetchWorkflowsTrackingError === null) {
                // console.log(this.props.orgWorkflowsTracking, 'orgWorkflowsTracking8888')
                this.setState({
                    isUpdateData: this.state.isUpdateData === true ? false : true
                })
            }
        }

        if (!this.props.fetchWorkflowsTrackingError && prevProps.fetchWorkflowsTrackingError) {
            this.setState({
                isUpdateData: this.state.isUpdateData === true ? false : true
            })
        }

        if (this.props.fetchAllOrgSettingsInprogress === false && prevProps.fetchAllOrgSettingsInprogress === true) {
            this.initViews();
        }

        if (this.props.fetchOrgFormsProgress === false && prevProps.fetchOrgFormsProgress) {
            this.getTrackingForm('ONLOAD');
        }

        if (this.props.fetchOrgFormsByIdProgress === false && prevProps.fetchOrgFormsByIdProgress === true) {
            this.initializeTrackingColumns();
        }

        if (!this.props.orgWorkFlowProgress && prevProps.orgWorkFlowProgress) {
            if (this.props.orgWorkFlowError === null) {
                this.initWorkflowNameFilter()
            }
        }

        if (this.props.updateProgress === false && prevProps.updateProgress === true) {
            this.props.showSnackBar("Project reopened successfully.", "success", 3000)
            this.fetchData()
        }

        if (this.props.undoEvaluationProgress === false && prevProps.undoEvaluationProgress === true) {
            if (this.props.errorUndoEvaluation) {
                this.props.showSnackBar("Failed to reopen.", "error", 3000)
            } else {
                this.props.showSnackBar("Project reopened successfully.", "success", 3000)
                this.fetchData()
            }
        }

        if (this.props.fetchProjectReqTypesProgress === false && prevProps.fetchProjectReqTypesProgress) {
            if (this.props.fetchProjectReqTypesError === null && this.props.requestTypes) {
                // console.log(this.props.requestTypes,'RequestTypessssssss')
                let reqTypeOptions = [{
                    label: 'All',
                    value: '0'
                }];
                this.props.requestTypes?.RequestTypes.forEach(function (rt) {
                    reqTypeOptions.push({
                        label: rt,
                        value: rt,
                    })
                })
                this.setState({
                    requestTypes: reqTypeOptions
                })
            }
        }

    }

    initViews = () => {
        if ([0, 3].includes(this.state.activeWorkflowTab)) {
            const { orgUser } = this.props;
            let configKey = "ALL_PROJECTS_VIEWS";
            if (this.state.activeWorkflowTab === 3) {
                configKey = "ALL_PROJECTS_TRACKING_VIEWS"
            }
            let viewsList = this.getConfigValue(orgUser?.fetchAllOrgSettingsSuccess, "SettingName", configKey) || [];
            viewsList = Validator.isJson(viewsList) ? JSON.parse(viewsList) : [];

            this.setState({
                viewsList: viewsList,
                isLoading: false,
            }, () => {
                let defaultView = viewsList.find(view => view.isDefault)
                if (defaultView && !this.state.selectedView) {
                    this.selectView(defaultView.id);
                }
            });
        }
    }

    setTrackingFormFields = () => {
        let columns = getFinalTrackingColumns();
        let columnIds = [];
        columns.forEach(O => {
            if (O.isDynamic) {
                columnIds.push(O.id);
            }
        });
        this.setState({
            trackingFormFields: columnIds
        })
    }

    setWorkflowFormFields = () => {
        let columns = getUpdatedWorkflowColumns();
        let columnIds = [];
        columns.forEach(O => {
            if (O.isDynamic) {
                columnIds.push(O.id);
            }
        });
        this.setState({
            formFieldsIds: columnIds
        }, () => {
            // if (this.state.activeWorkflowTab !== 3) {
            // this.fetchData()
            if (this.props?.location && this.props?.location?.hash) {
                const hashVal = this.props?.location?.hash;
                let tab = 0;
                if (hashVal === "#all") tab = 0
                if (hashVal === "#requested_by_me") tab = 1
                if (hashVal === "#assigned_to_me") tab = 2
                if (hashVal === "#project_tracking") tab = 3
                this.setState({
                    activeWorkflowTab: tab
                }, () => {
                    this.setFilterOnLoad(tab)
                })
            } else {
                this.setFilterOnLoad(this.state.activeWorkflowTab)
            }
            // }
        })

    }

    getConfigValue = (array, key, value) => {
        if (value) {
            let result = "";
            try {
                result = array.find((element) => element[key] === value)["Value"];
            } catch (e) { }
            return result.trim() || "";
        }
    };

    getPageSortType() {
        let sortStr = 'peo.created_at';
        const { sortField } = this.state;
        if (sortField === 'name') {
            sortStr = 'peo.name'
        } else if (sortField === 'workflow_name') {
            sortStr = 'owo.name'
        } else if (sortField === 'workflow_type') {
            sortStr = 'peo.type'
        } else if (sortField === 'requester_name') {
            sortStr = 'u.name'
        } else if (sortField === 'status') {
            sortStr = 'peo.status'
        } else if (sortField === 'creation_date') {
            sortStr = 'peo.created_at'
        } else if (sortField === 'last_update') {
            sortStr = 'peo.updated_at'
        }
        return sortStr;
    }

    getStatusFilter() {
        let statusForFiltering = [];
        const { statusFilter } = this.state;

        if (statusFilter.includes('closed')) {
            statusForFiltering.push(5)
        }
        if (statusFilter.includes('completed')) {
            statusForFiltering.push(4)
        }
        if (statusFilter.includes('in_progress')) {
            statusForFiltering.push(2, 3)
        }
        return statusForFiltering;
    }

    getProjectStatusFilter() {
        const { projectStatusFilter } = this.state;
        return projectStatusFilter;
    }

    initWorkflowNameFilter() {
        const { orgWorkFlowList } = this.props;
        // console.log(orgWorkFlowList, 'orgWorkFlowList')
        if (orgWorkFlowList && orgWorkFlowList.Data) {
            let workflowNameOptions = [{
                label: 'All',
                value: '0'
            }];
            orgWorkFlowList.Data.forEach((itm) => {
                workflowNameOptions.push({
                    label: itm.Name,
                    value: itm.Name
                })
            })

            this.setState({
                workflowNameOptions: workflowNameOptions
            })
        }
    }

    fetchData() {
        let sortType = this.getPageSortType();
        let statusFilter = this.getStatusFilter();
        let projectStatusFilter = this.getProjectStatusFilter()
        let parameters = {
            query: this.state.query,
            page: this.state.page,
            pageSize: this.state.pageSize,
            status: statusFilter,
            type: [1, 2, 4, 10],
            sortBy: sortType,
            sortOrder: this.state.sortType,
            requestStatus: projectStatusFilter,
            product_ids: this.props.filterProjects,
            entity_ids: this.props.filterEntities
        }

        if(this.state.duration !== 0){
            parameters['from_date'] = moment(this.state.startDate).toISOString();
            parameters['to_date'] = moment(this.state.endDate).toISOString();
        }

        // console.log(this.state.duration,'duration44444')
        // if (this.state.activeWorkflowTab === 0) {
        //     delete parameters.from_date;
        //     delete parameters.to_date;
        // }
        if (this.state.activeWorkflowTab === 0 || this.state.activeWorkflowTab === 3) {
            parameters['allEvaluations'] = true;
        } else if (this.state.activeWorkflowTab === 1) {
            if (this.props.from === 'PAGE') {
                parameters['requestor_ids'] = [this.props.user.ID];
            }
        } else if (this.state.activeWorkflowTab === 2) {
            parameters['colloborator_ids'] = [this.props.user.ID];
        }
        if (this.state.formFieldsIds?.length > 0) {
            parameters['form_fields'] = this.state.formFieldsIds;
        }
        if (this.state.projectOwnerIds?.length > 0) {
            parameters['project_owner_ids'] = this.state.projectOwnerIds?.map(item => item.value) || [];
        }

        if (this.state.activeWorkflowTab !== 1 && this.state.requestorIds && this.state.requestorIds.length > 0) {
            parameters['requestor_ids'] = this.state.requestorIds?.map(item => item.value) || [];
        }


        if (this.state.activeWorkflowTab === 3) {
            parameters['form_fields'] = [...this.state.trackingFormFields, ...this.state.trackingFormItemIds]
        }

        if (this.state.workflowNameFilter !== '0') {
            parameters['workflowName'] = this.state.workflowNameFilter;
        }

        if (this.state.requestTypeFilter !== '0') {
            parameters['request_type'] = this.state.requestTypeFilter;
        }

        this.props.fetchOrgWorkflows(parameters, this.state.activeWorkflowTab);
        // }

        // if (this.state.activeWorkflowTab === 3) {
        //     let parameters = {
        //         query: this.state.query,
        //         page: this.state.page,
        //         pageSize: this.state.pageSize,
        //         status: statusFilter,
        //         type: [1, 2, 4, 10],
        //         items: this.state.trackingFormItemIds
        //     }
        //     this.props.fetchWorkflowProjectTracking(parameters)
        // }

        if (!this.state.firstCallDone) {
            this.setState({
                firstCallDone: true
            })
        }
    }

    exportData(dates) {
        let sortType = this.getPageSortType();
        let statusFilter = this.getStatusFilter();
        let projectStatusFilter = this.getProjectStatusFilter();
        let date = new Date(this.state.endDate)
        let endDate = date.setDate(date.getDate() + 1);
        let parameters = {
            from_date: moment(this.state.startDate).toISOString(),
            to_date: moment(endDate).toISOString(),
            query: this.state.query,
            status: statusFilter,
            type: [1, 2, 4, 10],
            sortBy: sortType,
            sortOrder: this.state.sortType,
            requestStatus: projectStatusFilter,
            product_ids: this.props.filterProjects,
            entity_ids: this.props.filterEntities
        }

        if (this.state.activeWorkflowTab === 0 || this.state.activeWorkflowTab === 3) {
            parameters['allEvaluations'] = true;
        } else if (this.state.activeWorkflowTab === 1) {
            parameters['requestor_ids'] = [this.props.user.ID];
        } else if (this.state.activeWorkflowTab === 2) {
            parameters['colloborator_ids'] = [this.props.user.ID];
        }
        if (this.state.formFieldsIds.length > 0) {
            parameters['form_fields'] = this.state.formFieldsIds;
        }
        if (this.state.projectOwnerIds?.length > 0) {
            parameters['project_owner_ids'] = this.state.projectOwnerIds?.map(item => item.value) || [];
        }
        if (this.state.requestorIds?.length > 0 && this.state.activeWorkflowTab !== 1) {
            parameters['requestor_ids'] = this.state.requestorIds?.map(item => item.value) || [];
        }

        if (this.state.activeWorkflowTab === 3) {
            parameters['form_fields'] = [...this.state.trackingFormFields, ...this.state.trackingFormItemIds]
        }
        if (this.state.workflowNameFilter !== '0') {
            parameters['workflowName'] = this.state.workflowNameFilter;
        }

        if (this.state.requestTypeFilter !== '0') {
            parameters['request_type'] = this.state.requestTypeFilter;
        }

        if (this.state.activeWorkflowTab === 3) {
            const trackingFormColumns = window.localStorage.getItem("projectTrackingTableColumns");
            let formColumns = [];
            if (trackingFormColumns !== undefined && trackingFormColumns !== null && Validator.isJson(trackingFormColumns)) {
                let trackingColumns = JSON.parse(trackingFormColumns)
                
                trackingColumns.forEach(function (cl) {
                    if(cl && cl.isVisible){
                        formColumns.push({
                            id: typeof cl.id === 'number' ? cl.id : "",
                            name: cl.label,
                            isDynamic: typeof cl.id === 'number' ? true : false,
                            slug: cl.id
                        })
                    }
                })
            }
            parameters['forms'] = formColumns;
        } else {
            const trackingFormColumns = window.localStorage.getItem("workflowListingColumns");
            let formColumns = [];
            if (trackingFormColumns !== undefined && trackingFormColumns !== null && Validator.isJson(trackingFormColumns)) {
                let trackingColumns = JSON.parse(trackingFormColumns)
                trackingColumns.forEach(function (cl) {
                    if(cl && cl.isVisible){
                        formColumns.push({
                            id: typeof cl.id === 'number' ? cl.id : "",
                            name: cl.label,
                            isDynamic: typeof cl.id === 'number' ? true : false,
                            slug: cl.id
                        })
                    }
                })
            }
            parameters['forms'] = formColumns;
        }

        this.props.exportWorkflowData(parameters);
    }

    updateSearchString(page, pageSize, searchString) {
        this.setState({
            query: searchString,
            page: page,
            pageSize: pageSize
        }, () => {
            this.fetchData();
        })
    }

    updatePageAndSize(page, pageSize) {
        this.setState({
            page: page,
            pageSize: pageSize,
        }, () => {
            this.fetchData();
        })
    }

    updateSorting(type, field) {
        this.setState({
            page: 0,
            sortField: field,
            sortType: type
        }, () => {
            this.fetchData();
        })
    }

    selectNewTab(newTab, showFilters) {
        // console.log(this.state.filterTabWiseHistory, 'filterTabWiseHistory')
        // console.log(this.state.activeWorkflowTab, 'activeWorkflowTab-Old')
        // console.log(newTab, 'newTab')
        const { tabsHashMap } = this.state;

        this.props.history.push({ hash: tabsHashMap[newTab] })
        this.saveOldTabHostory(this.state.activeWorkflowTab, showFilters);
        this.setState({
            page: 0,
            activeWorkflowTab: newTab,
            selectedView: null
        }, () => {
            this.initViews();
            this.props.setActiveProjectsTab(newTab)
            this.clearAllFilters(newTab, false);
        })
    }

    saveOldTabHostory(tab, showFilters) {
        let filterTabWiseHistory = {};
        let filterDatas = window.localStorage.getItem("filterTabWiseHistory");
        if (filterDatas) {
            filterTabWiseHistory = JSON.parse(filterDatas)
        }
        filterTabWiseHistory[tab] = {
            statusFilter: this.state.statusFilter,
            projectStatusFilter: this.state.projectStatusFilter,
            workflowNameFilter: this.state.workflowNameFilter,
            requestTypeFilter: this.state.requestTypeFilter,
            projectOwnerIds: this.state.projectOwnerIds,
            requestorIds: this.state.requestorIds,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            duration: this.state.duration,
            query: this.state.query,
            showFilters: showFilters
        }
        this.setState({
            filterTabWiseHistory: filterTabWiseHistory
        }, () => {
            window.localStorage.setItem("filterTabWiseHistory", JSON.stringify(filterTabWiseHistory));
        })
    }

    setFilterOnLoad(newTab) {
        if (this.props.userType === "OrgUser") {
            this.fetchData();
        } else {
            let filterTabWiseHistory = {};
            let filterDatas = window.localStorage.getItem("filterTabWiseHistory");
            if (filterDatas) {
                filterTabWiseHistory = JSON.parse(filterDatas)
            }
            if (newTab !== undefined && newTab !== null && newTab !== '' && filterTabWiseHistory[newTab]) {
                const filterD = filterTabWiseHistory[newTab];
                // console.log(filterD, 'filterD')
                // let endDate = filterD.endDate;
                // let startDate = filterD.startDate;
                // if (filterD.duration && filterD.duration === 100) {
                //     endDate = new Date();
                // } else if (filterD.duration && filterD.duration === 0) {
                //     startDate = new Date(CommonFn.getPreviousDate(90));
                //     endDate = new Date();
                // } else if (filterD.duration && filterD.duration > 0) {
                //     startDate = new Date(CommonFn.getPreviousDate(filterD.duration))
                //     endDate = new Date();
                // }

                this.setState({
                    statusFilter: filterD.statusFilter,
                    projectStatusFilter: filterD.projectStatusFilter,
                    workflowNameFilter: filterD.workflowNameFilter,
                    requestTypeFilter: filterD.requestTypeFilter,
                    projectOwnerIds: filterD.projectOwnerIds,
                    requestorIds: filterD.requestorIds,
                    // startDate: startDate,
                    // endDate: endDate,
                    // duration: filterD.duration,
                    showFilters: filterD.showFilters,
                    // isChangesInFilters: this.state.isChangesInFilters ? false : true
                }, () => {
                    this.fetchData();
                })
            } else {
                this.fetchData();
            }
        }

    }

    clearAllFilters(newTab, isOnlyClearHistory) {
        let startDate = new Date(CommonFn.getPreviousDate(90));
        let endDate = new Date();
        // let filterTabWiseHistory = this.state.filterTabWiseHistory;
        let filterTabWiseHistory = {};
        let filterDatas = window.localStorage.getItem("filterTabWiseHistory");
        if (filterDatas) {
            filterTabWiseHistory = JSON.parse(filterDatas)
        }

        if (isOnlyClearHistory === false && newTab !== undefined && newTab !== null && newTab !== '' && filterTabWiseHistory[newTab]) {
            const filterD = filterTabWiseHistory[newTab];
            this.setState({
                statusFilter: filterD.statusFilter,
                projectStatusFilter: filterD.projectStatusFilter,
                workflowNameFilter: filterD.workflowNameFilter,
                requestTypeFilter: filterD.requestTypeFilter,
                projectOwnerIds: filterD.projectOwnerIds,
                requestorIds: filterD.requestorIds,
                query: filterD.query,
                // startDate: filterD.startDate,
                // endDate: filterD.endDate,
                showFilters: filterD.showFilters,
                startDate: startDate,
                endDate: endDate,
                duration: 0,
                // duration: filterD.duration,
                isChangesInFilters: this.state.isChangesInFilters ? false : true
            }, () => {
                this.fetchData();
            })
        } else {
            this.setState({
                statusFilter: ['completed', 'in_progress', 'closed'],
                projectStatusFilter: ['Draft', 'PendingApproval', 'Approved', 'Rejected', 'CLOSED', 'FINISHED'],
                workflowNameFilter: '0',
                requestTypeFilter: '0',
                projectOwnerIds: [],
                requestorIds: [],
                query: '',
                startDate: startDate,
                endDate: endDate,
                duration: 0,
                showFilters: [],
                isChangesInFilters: this.state.isChangesInFilters ? false : true
            }, () => {
                this.fetchData();
                this.saveOldTabHostory(this.state.activeWorkflowTab, [])
            })
        }
    }

    updateFormIds(ids) {
        if (this.state.activeWorkflowTab && this.state.activeWorkflowTab === 3) {
            this.setState({
                page: 0,
                trackingFormFields: ids,
            }, () => {
                this.fetchData();
            })
        } else {
            this.setState({
                page: 0,
                formFieldsIds: ids,
            }, () => {
                this.fetchData();
            })
        }
    }

    ProjectOwnersFilter(values) {
        this.setState({
            page: 0,
            projectOwnerIds: values || [],
        }, () => {
            this.fetchData();
            this.saveOldTabHostory(this.state.activeWorkflowTab, this.state.showFilters)
        })
    }

    requestorFilter(values) {
        this.setState({
            page: 0,
            requestorIds: values || [],
        }, () => {
            this.fetchData();
            this.saveOldTabHostory(this.state.activeWorkflowTab, this.state.showFilters)
        })
    }

    updateFiltering(statusFilter) {
        this.setState({
            page: 0,
            statusFilter: statusFilter,
        }, () => {
            this.fetchData();
            this.saveOldTabHostory(this.state.activeWorkflowTab, this.state.showFilters)
        })
    }

    updateProjectStatusFiltering(projectStatusFilter) {
        this.setState({
            page: 0,
            projectStatusFilter: projectStatusFilter,
        }, () => {
            this.fetchData();
            this.saveOldTabHostory(this.state.activeWorkflowTab, this.state.showFilters)
        })
    }

    updateWorkflowNameFiltering(workflowNameFilter) {
        this.setState({
            page: 0,
            workflowNameFilter: workflowNameFilter,
        }, () => {
            this.fetchData();
            this.saveOldTabHostory(this.state.activeWorkflowTab, this.state.showFilters)
        })
    }

    updateRequestTypeFiltering(requestTypeFilter) {
        this.setState({
            page: 0,
            requestTypeFilter: requestTypeFilter,
        }, () => {
            this.fetchData();
            this.saveOldTabHostory(this.state.activeWorkflowTab, this.state.showFilters)
        })
    }

    deleteWorkflow = (id) => {
        this.setState({ deleteId: id, showProjectDeleteConfirm: true }, () => {
            this.props.showMessageDialog();
        })
    }

    callBack = () => {
        if (this.state.deleteId) {
            this.props.hideMessageDialog();
            this.props.deleteProductReview(this.state.deleteId);
            this.setState({ deleteId: null, showProjectDeleteConfirm: false })
        }
    }

    dateChangeFilter = (dates, from_date, to_date, duration) => {
        let startDate = CommonFn.getPreviousDate(dates?.duration || 30);
        let endDate = new Date();
        let to_date_new = '';
        let from_date_new = '';
        if (dates !== undefined && !dates.isAny && !dates.isCustomDates && startDate && endDate) {
            from_date_new = startDate;
            to_date_new = endDate;
        } else if (dates !== undefined && dates.isAny) {
            let startDaten = CommonFn.getPreviousDate(90);
            let endDaten = new Date();
            from_date_new = startDaten;
            to_date_new = endDaten;
        } else {
            from_date_new = from_date;
            to_date_new = to_date;
        }
        this.setState({
            startDate: from_date_new,
            endDate: to_date_new,
            duration: duration,
            page: 0,
        }, () => {
            this.fetchData();
            this.saveOldTabHostory(this.state.activeWorkflowTab, this.state.showFilters)
        })
    }

    getTrackingForm(type) {
        const { orgForms } = this.props;
        if (orgForms) {
            let trackForm;
            if (type === 'ONLOAD') {
                trackForm = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "PROJECT_TRACKING_FORM") || '';
            } else if (type === 'UPDATE') {
                trackForm = this.props.orgUser?.addOrgSettingsSuccess[0]?.Value || null;
            }
            let projectColumnWithNames = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "PROJECTS_TABLE_COLUMN_NAMES") || '';
            if (trackForm !== undefined && trackForm !== null && trackForm !== 'null' && trackForm !== '') {
                let selectedForm = orgForms.filter(o => o.ID === parseInt(trackForm))[0]
                if (selectedForm) {
                    this.setState({
                        projectTrackingForm: {
                            label: selectedForm.FormTitle,
                            value: selectedForm.ID,
                        },
                        updateTrackingForm: this.state.updateTrackingForm ? false : true,
                        projectColumnWithNames: projectColumnWithNames
                    }, () => {
                        this.getFormDetails();
                    })
                }else{
                    this.setState({
                        projectColumnWithNames: projectColumnWithNames,
                        isNameChanges : this.state.isNameChanges ? false : true
                    })
                }
            } else {
                this.fetchData()
            }
        } else {
            this.fetchData()
        }
    }

    getFormDetails() {
        const { projectTrackingForm } = this.state;
        if (projectTrackingForm) {
            this.props.fetchOrgFormUsingId(projectTrackingForm.value);
        }
    }

    updateTrackingForm(form) {
        if (form === undefined || form === null || form === '') {
            this.props.showSnackBar('Please select form', 'error', 3000)
            return;
        }
        let org_settings = [
            {
                name: "PROJECT_TRACKING_FORM",
                value: String(form.value)
            }
        ]
        this.setState({
            configSuccessMsg: "Project Tracking form updated successfully"
        }, () => {
            this.props.addOrgSettings(org_settings);
        })
    }

    updateColumnNames(columns) {
        if (columns === undefined || columns === null || columns === '') {
            return;
        }
        let org_settings = [
            {
                name: "PROJECTS_TABLE_COLUMN_NAMES",
                value: JSON.stringify(columns)
            }
        ]
        this.setState({
            configSuccessMsg: "Column name updated successfully"
        }, () => {
            this.props.addOrgSettings(org_settings);
        })
    }

    initializeTrackingColumns() {
        const { orgFormData } = this.props;
        let formItems = [];
        let formItemsIdMap = {};
        let itemIds = [];
        // console.log(orgFormData,'orgFormDataNewwwwwwww')
        if (orgFormData?.OrgWorkflowTemplateFormsSection) {
            orgFormData.OrgWorkflowTemplateFormsSection.forEach(function (section) {
                if (section.OrgWorkflowTemplateFormSectionItems) {
                    section.OrgWorkflowTemplateFormSectionItems.forEach(function (itm) {
                        let obj = {
                            id: itm.ID,
                            label: itm.ItemName,
                            type: itm.Type,
                        }
                        itemIds.push(itm.ID)
                        formItems.push(obj)
                        formItemsIdMap[itm.ID] = obj;
                    })
                }
            })
        }

        const trackingFormColumns = window.localStorage.getItem("projectTrackingFormColumns");
        if (trackingFormColumns !== undefined && trackingFormColumns !== null && Validator.isJson(trackingFormColumns)) {
            let oldColumns = JSON.parse(trackingFormColumns)
            oldColumns.forEach(function (cl) {
                let obj = {
                    id: cl.id,
                    label: cl.label,
                    type: cl.type,
                }
                formItemsIdMap[cl.id] = obj;
                formItems.push(cl)
                itemIds.push(cl.ID)
            })
        }

        let finalColumns = [...workflowColumns, ...formItems]
        this.setState({
            trackingFormItemIds: itemIds,
            trackingFormItems: finalColumns,
            formItemsIdMap: formItemsIdMap
        }, () => {
            this.fetchData();
            updateTrackingColumns(finalColumns)
        })
    }

    getTabs() {
        let tabsOptionsAll = [{
            label: 'All',
            value: 0,
        }, {
            label: 'Requested By Me',
            value: 1,
        }, {
            label: 'Assigned to Me',
            value: 2,
        }, {
            label: 'Project Tracking',
            value: 3,
        }]
        let newTabs = [];
        let isHideAllOption = false;
        let isHideProjectTracking = true;
        if (this.props.userType === "OrgUser") {
            const EnableShowAllProjectsCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableShowAllProjectsCheck");
            isHideAllOption = EnableShowAllProjectsCheck;
            isHideProjectTracking = true;
        } else {
            // console.log(this.props.orgUser, 'OrgUserrrrrrrrr')
            const EnableProjectsTrackingCheck = isEnabledProjectTracking(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableProjectsTrackingCheck");
            // console.log(EnableProjectsTrackingCheck, 'EnableProjectsTrackingCheck')
            isHideProjectTracking = EnableProjectsTrackingCheck ? false : true;
        }

        tabsOptionsAll.forEach(function (tab) {
            if (tab.value === 0 && isHideAllOption) {
                return;
            } else if (tab.value === 3 && isHideProjectTracking) {
                return;
            }
            newTabs.push(tab)
        })

        let activeWorkflowTab = this.state.activeWorkflowTab;
        if (activeWorkflowTab === 3 && isHideProjectTracking) {
            activeWorkflowTab = 1
        }
        if (this.props.activeTab && this.props.activeTab !== '') {
            activeWorkflowTab = this.props.activeTab
        }
        this.setState({
            tabsOptions: newTabs,
            activeWorkflowTab: activeWorkflowTab
        })
    }

    reOpenProject(project) {
        this.props.updateEvaluationMetaData(project?.id, {
            status: 2
        })
    }

    undoProject(project) {
        this.props.undoEvaluation(project?.id)
    }

    selectView = (id) => {
        let view = this.state.viewsList?.find(view => view.id === id);
        if (view) {
            this.setState({
                pageSize: view?.filters?.pageSize || this.state.pageSize,
                query: view?.filters?.query,
                startDate: view?.filters?.startDate || this.state.startDate,
                endDate: view?.filters?.endDate || this.state.endDate,
                dates: view?.filters?.dates || this.state.dates,
                duration: view?.filters?.duration || this.state.duration,
                columns: view?.filters?.columns || this.state.columns,
                sortField: view?.filters?.sortField || this.state.sortField,
                sortType: view?.filters?.sortType || this.state.sortType,
                statusFilter: view?.filters?.statusFilter || this.state.statusFilter,
                projectStatusFilter: view?.filters?.projectStatusFilter || this.state.projectStatusFilter,
                workflowNameFilter: view?.filters?.workflowNameFilter || this.state.workflowNameFilter,
                requestTypeFilter: view?.filters?.requestTypeFilter || this.state.requestTypeFilter,
                projectTrackingForm: view?.filters?.projectTrackingForm || this.state.projectTrackingForm,
                showFilters: view?.filters?.showFilters || this.state.showFilters,
                projectOwnerIds: view?.filters?.projectOwnerIds || this.state.projectOwnerIds,
                requestorIds: view?.filters?.requestorIds || this.state.requestorIds,
            }, () => {
                this.fetchData();
            })
        }
        this.setState({ selectedView: id });
    }

    updateView = () => {
        let viewsList = this.state.viewsList;
        let index = viewsList.findIndex(view => view.id === this.state.selectedView);
        if (index > -1) {
            viewsList[index]['filters'] = {
                pageSize: this.state.pageSize,
                query: this.state.query,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                dates: this.state.dates,
                duration: this.state.duration,
                columns: this.state.columns,
                sortField: this.state.sortField,
                sortType: this.state.sortType,
                statusFilter: this.state.statusFilter,
                projectStatusFilter: this.state.projectStatusFilter,
                workflowNameFilter: this.state.workflowNameFilter,
                requestTypeFilter: this.state.requestTypeFilter || "0",
                projectTrackingForm: this.state.projectTrackingForm,
                showFilters: this.state.showFilters,
                projectOwnerIds: this.state.projectOwnerIds,
                requestorIds: this.state.requestorIds,
            }
        }
        let configKey = "ALL_PROJECTS_VIEWS";
        if (this.props.activeWorkflowTab === 3) {
            configKey = "ALL_PROJECTS_TRACKING_VIEWS"
        }
        var notificationConfig = [
            {
                name: configKey,
                value: JSON.stringify(viewsList),
            }
        ];
        this.props.addOrgSettings(notificationConfig);
    }


    render() {
        const classes = this.props.classes;
        const { projectTrackingForm, updateTrackingForm, tabsOptions } = this.state;
        let messageData = {
            head: "Are you sure?",
            subHead: "Do you really want to delete? This process cannot be undone.",
            firstBtn: "Cancel",
            secondBtn: "Yes",
        };

        if (!this.state.firstCallDone) {
            return <div style={{ textAlign: 'center', marginTop: 50 }}>
                <CircularProgress />
            </div>
        }

        let requestTypes = [];
        let isRequestType = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "REQUEST_TYPES");
        let requestTypesData = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "REQUEST_TYPES") || [];
        if (
            isRequestType &&
            Validator.isJson(requestTypesData) &&
            requestTypesData !== undefined &&
            requestTypesData !== "NULL" &&
            requestTypesData !== null &&
            requestTypesData !== "null" &&
            requestTypesData.length > 0
        ) {
            requestTypes = JSON.parse(requestTypesData);
        }

        // console.log(this.state.formFieldsIds, 'formFieldsIds')
        return <div>
            {this.props.showTopBar && <AppBarV2 title="Projects" dropdown={true} withBack={true} />}
            <div className={this.props.showTopBar ? classes.rootContainer : ''}>
                <ListingTable
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    searchQuery={this.state.query}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    dates={this.state.dates}
                    duration={this.state.duration}
                    columns={this.state.columns}
                    sortField={this.state.sortField}
                    sortType={this.state.sortType}
                    statusFilter={this.state.statusFilter}
                    projectStatusFilter={this.state.projectStatusFilter}
                    workflowNameFilter={this.state.workflowNameFilter}
                    workflowFilterOptions={this.state.workflowNameOptions}
                    isLoading={this.props.fetchWorkflowsProgress || this.props.fetchWorkflowsTrackingProgress}
                    isUpdatedNewData={this.state.isUpdateData}
                    rows={this.props.orgWorkflows[this.state.activeWorkflowTab] !== undefined ? this.props.orgWorkflows[this.state.activeWorkflowTab] : []}
                    totalRows={this.props.orgWorkflowsTotal[this.state.activeWorkflowTab] !== undefined ? this.props.orgWorkflowsTotal[this.state.activeWorkflowTab] : 0}
                    trackDataRows={this.props.orgWorkflowsTracking}
                    trackDataTotalRows={this.props.orgWorkflowsTrackingTotal}
                    from={'WORKFLOW_MENU'}
                    searchPlaceHolder={'Project Name'}
                    isShowSearch={true}
                    isShowStatusFilter={true}
                    isShowTabs={this.props.showTabs}
                    tableMaxHeight={'64vh'}
                    isGlobalSortRequire={true}
                    showFormColumns={true}
                    showActions={true}
                    showExport={true}
                    loadingExportBtn={this.props.fetchExportWorkflowDataLInkProg}
                    tabs={tabsOptions}
                    activeTab={this.state.activeWorkflowTab}
                    onDelete={(id) => {
                        this.deleteWorkflow(id)
                    }}
                    projectTrackingForm={projectTrackingForm}
                    updateTrackingForm={updateTrackingForm}
                    trackingColumns={this.props.trackingFields}
                    trackingFormItems={this.state.trackingFormItems}
                    formItemsIdMap={this.state.formItemsIdMap}
                    isChangesInFilters={this.state.isChangesInFilters}
                    showFilters={this.state.showFilters}
                    projectOwnerIds={this.state.projectOwnerIds}
                    requestorIds={this.state.requestorIds}
                    showStartNewProjectBtn={this.props.showStartNewProjectBtn}
                    projectColumnWithNames={this.state.projectColumnWithNames}
                    requestTypeFilter={this.state.requestTypeFilter}
                    requestTypeOptions={this.state.requestTypes}
                    selectedView={this.state.selectedView}
                    viewsList={this.state.viewsList}
                    selectView={(id) => {
                        this.selectView(id);
                    }}
                    showWorkflowViewsDialog={(colomns) => {
                        this.setState({ columns: colomns }, () => {
                            this.props.showWorkflowViewsDialog()
                        })
                    }}
                    handleDateChange={(dates, start, end, duration) => {
                        this.dateChangeFilter(dates, start, end, duration);
                    }}
                    exportData={(dates) => {
                        this.exportData(dates);
                    }}
                    // updateView={() => {
                    //     this.updateView();
                    // }}
                    onChangePage={(page, pageSize) => {
                        this.updatePageAndSize(page, pageSize);
                    }}
                    onChangeSearch={(page, pageSize, searchString) => {
                        this.updateSearchString(page, pageSize, searchString);
                    }}
                    onChangeTab={(newTab, showFilters) => {
                        this.selectNewTab(newTab, showFilters);
                    }}
                    onChangeSort={(type, field) => {
                        this.updateSorting(type, field)
                    }}
                    onSelectProjectOwnersFilter={(values) => {
                        this.ProjectOwnersFilter(values)
                    }}
                    onSelectRequestorFilter={(values) => {
                        this.requestorFilter(values)
                    }}
                    onChangeStatusFilter={(newValue) => {
                        this.updateFiltering(newValue)
                    }}
                    onChangeProjectStatusFilter={(newValue) => {
                        this.updateProjectStatusFiltering(newValue)
                    }}
                    onChangeWorkflowNameFilter={(newValue) => {
                        this.updateWorkflowNameFiltering(newValue)
                    }}
                    onChangeRequestTypeFilter={(newValue) => {
                        this.updateRequestTypeFiltering(newValue)
                    }}
                    onSetFormColumnsIds={(ids) => {
                        this.updateFormIds(ids)
                    }}
                    onShowFormColumns={() => {
                        this.props.showAddFormColumnsDialog();
                    }}
                    updateTrackingConfiguration={(form) => {
                        this.updateTrackingForm(form)
                    }}
                    updateColumnNames={(columns) => {
                        this.updateColumnNames(columns)
                    }}
                    showSnackBar={(msg, type, time) => {
                        this.props.showSnackBar(msg, type, time)
                    }}
                    clearFilters={() => {
                        this.clearAllFilters(null, true)
                    }}
                    reOpenProject={(project) => {
                        this.reOpenProject(project)
                    }}
                    undoProject={(project) => {
                        this.undoProject(project)
                    }}
                    updateShowFilters={(showFilters) => {
                        this.setState({
                            showFilters: showFilters
                        })
                    }}
                    onClickStartNewProject={() => {
                        this.props.showEvaluationProductSelectionDialog()
                    }}
                    updateFormIdMap={(map) => {
                        this.setState({
                            formItemsIdMap: map
                        })
                    }}
                    showAddUpdateDialog={() => {
                        let columns = [];
                        if (this.state.activeWorkflowTab === 0) {
                            columns = getUpdatedWorkflowColumns()
                        } else if (this.state.activeWorkflowTab === 3) {
                            columns = getFinalTrackingColumns()
                        }
                        this.setState({ addUpdateDialog: true, columns: columns });
                    }}
                />
                <div ref={this.divRef} id="downloadlink"></div>
            </div>
            {this.state.showProjectDeleteConfirm && <MessageDialog messageData={messageData} callBack={this.callBack} />}
            {this.props.showEvaluationProductSelectionDialog && <ProductSelectionDialog
                isRequestType={isRequestType && requestTypes.length > 0 ? true : false}
                requestTypes={requestTypes}
                selectedProduct={this.props.selectionForStart}
                selectedVendorType={this.props.selectedVendorType}
            />}
            {this.props.showWorkflowViewDialog && (
                <ViewsDialog
                    tab={this.state.activeWorkflowTab}
                    filters={{
                        pageSize: this.state.pageSize,
                        query: this.state.query,
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                        dates: this.state.dates,
                        duration: this.state.duration,
                        columns: this.state.columns,
                        sortField: this.state.sortField,
                        sortType: this.state.sortType,
                        statusFilter: this.state.statusFilter,
                        projectStatusFilter: this.state.projectStatusFilter,
                        workflowNameFilter: this.state.workflowNameFilter,
                        requestTypeFilter: this.state.requestTypeFilter || "0",
                        projectTrackingForm: this.state.projectTrackingForm,
                        showFilters: this.state.showFilters,
                        projectOwnerIds: this.state.projectOwnerIds,
                        requestorIds: this.state.requestorIds,
                    }}
                />
            )}
            {this.state.addUpdateDialog && (
                <AddUpdateViewDialog
                    tab={this.state.activeWorkflowTab}
                    hideDialog={() => this.setState({ addUpdateDialog: false })}
                    filters={{
                        pageSize: this.state.pageSize,
                        query: this.state.query,
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                        dates: this.state.dates,
                        duration: this.state.duration,
                        columns: this.state.columns,
                        sortField: this.state.sortField,
                        sortType: this.state.sortType,
                        statusFilter: this.state.statusFilter,
                        projectStatusFilter: this.state.projectStatusFilter,
                        workflowNameFilter: this.state.workflowNameFilter,
                        requestTypeFilter: this.state.requestTypeFilter || "0",
                        projectTrackingForm: this.state.projectTrackingForm,
                        showFilters: this.state.showFilters,
                        projectOwnerIds: this.state.projectOwnerIds,
                        requestorIds: this.state.requestorIds,
                    }}
                />
            )}
        </div>
    }
}

Workflows.defaultProps = {
    filterProjects: [],
    filterEntities: [],
    activeTab: null,
    showTopBar: true,
    showTabs: true,
    showStartNewProjectBtn: false,
    selectedVendorType: {
        label: 'Products',
        value: 'PRODUCT'
    },
    selectionForStart: null,
    from: 'PAGE'
};

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Workflows));