import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  OutlinedInput,
  Popover,
  Typography,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { createStyles, withStyles } from "@material-ui/core/styles";
import {
  ArrowDropDown as ArrowDropDownIcon,
  PowerSettingsNew as LogoutIcon,
  SettingsOutlined as SettingIcon,
} from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import NotificationsIcon from "@material-ui/icons/Notifications";
import classnames from "classnames";
import NotificationPopover from "Components/Application/Components/Notification/NotificationPopover";
import NotificationToast from "Components/Application/Components/Notification/NotificationToast";
import Image from "Components/Common/image.jsx";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { fetchEvaluationMetaData } from "redux/evaluation/metadata/action";
import { fetchNotifications } from "redux/notification/action";
import { showSnackBar } from "redux/snackbar/action";
import ArrowBack from "../../assets/images/arrow_back.svg";
import { logout, setLogoutFromFlag } from "../../redux/authuser/action";

const connectedProps = (state) => ({
  user: state.authUser.user,
  evalTitleUpdateProgress: state.evaluationMetaData.evalTitleUpdateProgress,
  evalTitleUpdateError: state.evaluationMetaData.evalTitleUpdateError,
  fetchNotificationProgress: state.notifications.fetchNotificationProgress,
  fetchNotificationError: state.notifications.fetchNotificationError,
  notifications: state.notifications.notifications,
  totalNotifications: state.notifications.totalNotifications,
  toastNotificationCount: state.notifications.toastNotificationCount,
  saveFromOrgEvaluationTemplateProg: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateProg,
  updateOrgWorkFlowProgress: state.evaluationWorkflow.updateOrgWorkFlowProgress,
});

const connectionActions = {
  logout: logout,
  showSnackBar: showSnackBar,
  fetchDetails: fetchEvaluationMetaData,
  fetchNotifications: fetchNotifications,
  setLogoutFromFlag: setLogoutFromFlag
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {},
    title: {
      fontSize: theme.spacing(2.8),
      width: '65%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    isEditable: {
      cursor: "pointer",
      "&:hover": {
        background: "#f7f7f7",
        padding: "0xp 2px ",
        borderRadius: 2,
      },
    },
    titleInput: {
      height: 31.54,
      width: '65%',
      "& > [class*=MuiInputBase-input]": {
        fontSize: "1.4em",
        fontWeight: 600,
        padding: 2,
      },
      "& > [class*=MuiOutlinedInput-notchedOutline-]": {},
    },
    actionIcon: {
      width: 31.54,
      height: 31.54,
      borderRadius: 5,
      textAlign: "center",
      marginLeft: 5,
      border: "1px solid #4b86f8",
      cursor: "pointer",
      background: "#fff",
      zIndex: 1,
    },
    micon: {
      fontSize: 24,
      marginTop: 3,
      color: "#4b86f8",
    },
    notificationIcon: {
      fontSize: 24,
      marginTop: 5,
      color: "#4b86f8",
    },
    profileAction: {
      textAlign: "right",
      "& [class*='outlinedSecondary']:hover": {
        background: "#fff",
      },
    },
    profileMenu: {
      background: theme.palette.common.white,
      top: "10px !important",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      // width: "70%",
    },
    iconContainer: {
      marginRight: theme.spacing(3),
      cursor: "pointer",
      display: "flex",
    },
    image: {
      width: theme.spacing(3.5),
      minWidth: theme.spacing(3.5),
    },
    button: {
      fontSize: theme.spacing(2),
      background: "transparent",
      color: "#707070",
      border: "none",
      "&:hover": {
        border: "none !important",
      },
    },
    listItem: {
      paddingTop: theme.spacing(2),
    },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    appBar: {
      background: "#fff",
      padding: theme.spacing(1, 2),
      boxShadow: "none",
      borderBottom: "2px solid #f1f1f1",
    },
    noMargin: {
      margin: "0px!important",
    },
    editTitleSec: {
      // width : '70%'
    },
    leftItems: {
      display: "flex",
      justifyContent: "right",
      alignItems: "center",
    },
    notification: {
      padding: "0 10px",
      cursor: "pointer",
      position: "relative",
      "& :hover": {
        // border: '1px solid #f7f7f7',
      },
    },
    notiCount: {
      position: "absolute",
      background: "red",
      display: "flex",
      alignItems: "center",
      color: "#fff",
      right: 3,
      top: -1,
      width: 18,
      height: 18,
      fontSize: 9,
      justifyContent: "center",
      borderRadius: 10,
    },
  });

class AppBarV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileAnchorEl: null,
      openNotification: null,
      editTitle: false,
      title: "",
      totalUnread: 0,
    };
  }

  componentDidMount() {
    this.setState({
      title: this.props.title,
    });
    if (this.props.hideNotifications === undefined) {
      this.props.fetchNotifications({
        page: 0,
        pageSize: 1000,
        readStatus: "ALL",
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.evalTitleUpdateProgress && prevProps.evalTitleUpdateProgress) {
      if (this.props.evalTitleUpdateError === null) {
        this.setState({
          editTitle: false,
        });
      } else {
        this.props.showSnackBar(
          "Failed to update project.",
          "error",
          6000
        );
      }
    }

    if (!this.props.updateOrgWorkFlowProgress && prevProps.updateOrgWorkFlowProgress) {
      this.setState({
        editTitle: false,
      });
    }

    if (!this.props.isSubmittingTitle && prevProps.isSubmittingTitle) {
      this.setState({
        editTitle: false,
      });
    }



    if (
      !this.props.saveFromOrgEvaluationTemplateProg &&
      prevProps.saveFromOrgEvaluationTemplateProg
    ) {
      this.setState({
        editTitle: false,
      });
    }

    if (this.props.title !== prevProps.title) {
      this.setState({
        title: this.props.title,
      });
    }

    if (
      this.props.fetchNotificationProgress === false &&
      prevProps.fetchNotificationProgress === true &&
      this.props.hideNotifications === undefined
    ) {
      if (this.props.fetchNotificationError === null) {
        this.checkNotifications();
      } else {
        // this.props.showSnackBar("Something went wrong.", "error", 6000)
      }
    }

    if (
      this.props.toastNotificationCount !== prevProps.toastNotificationCount
    ) {
      this.checkNotifications();
    }
  }

  checkNotifications() {
    var totalUnread = "";
    this.props.notifications.forEach(function (item, k) {
      if (item.readAt === null) {
        totalUnread++;
      }
    });
    this.setState({
      totalUnread: totalUnread,
    });
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  render() {
    if (this.props.user == null) {
      return null;
    }
    const classes = this.props.classes;
    var gridSize = [4, 4, 7];
    if (this.props.children === undefined) {
      gridSize = [6, false, 6];
    }

    const { totalUnread } = this.state;
    // console.log(this.props.dispathFrmApproval, 'dispathFrmApproval')
    return (
      <div className={classnames(classes.root, "appBar")}>
        <AppBar className={classes.appBar} position="static" color="default">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={gridSize[0]}>
              <div
                className={classnames(classes.titleContainer, classes.noMargin)}
              >
                {this.props.withBack && (
                  <div
                    className={classes.iconContainer}
                    onClick={() => {
                      if (this.props.dispatchEvent) {
                        this.props.targetEvent();
                      } else if (this.props.dispathFrmApproval) {
                        this.props.history.go(-2);
                      } else {
                        this.props.history.goBack();
                      }
                    }}
                  >
                    <Image
                      src={ArrowBack}
                      alt="arrow_back"
                      className={classes.image}
                    />
                  </div>
                )}
                {true && this.state.editTitle && (
                  <Grid
                    container
                    alignItems="center"
                    className={classes.editTitleSec}
                  >
                    <OutlinedInput
                      fullWidth
                      autoFocus
                      value={this.state.title}
                      onChange={(event) => {
                        event.stopPropagation();
                        this.setState({ title: event.target.value });
                      }}
                      className={classes.titleInput}
                    />
                    &nbsp;
                    {this.props.isSubmittingTitle && (
                      <CircularProgress size={18} />
                    )}
                    {!this.props.isSubmittingTitle && (
                      <div
                        className={classes.actionIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ editTitle: false, title: this.props.title });
                        }}
                      >
                        <ClearIcon className={classes.micon} />
                      </div>
                    )}
                    {!this.props.isSubmittingTitle && (
                      <div
                        className={classes.actionIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.submitTitle(this.state.title);
                        }}
                      >
                        <CheckIcon className={classes.micon} />
                      </div>
                    )}
                  </Grid>
                )}
                {!this.state.editTitle && (
                  <Typography
                    title={this.state.title}
                    variant={"h6"}
                    onClick={() => {
                      if (this.props.isEditableTitle) {
                        this.setState({
                          editTitle: true,
                          // title: this.props.title
                        });
                      }
                    }}
                    className={classnames(
                      classes.title,
                      this.props.isEditableTitle ? classes.isEditable : {}
                    )}
                  >
                    {this.state.title}
                  </Typography>
                )}
              </div>
            </Grid>
            {/* <Grid item xs={gridSize[1]}>
              
            </Grid> */}
            <Grid item xs={gridSize[2]}>
              <div className={classes.leftItems}>
                {this.props.children}
                {false && this.props.hideNotifications === undefined && (
                  <div className={classes.notiDiv}
                    onClick={(event) => {
                      this.setState({
                        openNotification: event.currentTarget,
                      });
                    }}
                  >
                    <div className={classes.notification}>
                      <NotificationsIcon
                        className={classes.notificationIcon}
                      />
                      {totalUnread > 0 && (
                        <span className={classes.notiCount}>{totalUnread}</span>
                      )}
                    </div>
                  </div>
                )}
                {false && <div className={classes.userProfile}>
                  {this.props.dropdown && (
                    <div className={classes.profileAction}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.button}
                        classes={{
                          label: classes.button,
                        }}
                        startIcon={
                          <Avatar
                            style={{
                              width: "25px",
                              height: "25px",
                              fontSize: "14px",
                            }}
                            alt={this.props.user.Name}
                          >
                            {this.avatarName(this.props.user.Name)}
                          </Avatar>
                        }
                        onClick={(event) => {
                          this.setState({
                            profileAnchorEl: event.currentTarget,
                          });
                        }}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        {this.props.user.Name}
                      </Button>
                    </div>
                  )}
                </div>}
              </div>
            </Grid>
          </Grid>
        </AppBar>
        <Popover
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          id="profileMenu"
          classes={{ paper: classes.profileMenu }}
          anchorEl={this.state.profileAnchorEl}
          keepMounted
          elevation={2}
          open={Boolean(this.state.profileAnchorEl)}
          onClose={() => {
            this.setState({ profileAnchorEl: null });
          }}
        >
          <List>
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={() => {
                this.setState({ profileAnchorEl: null });
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {/* <FaceIcon /> */}
                <Avatar
                  style={{ width: "25px", height: "25px", fontSize: "14px" }}
                  alt={this.props.user.Name}
                >
                  {this.avatarName(this.props.user.Name)}
                </Avatar>
              </ListItemIcon>
              <Typography variant="inherit">{this.props.user.Name}</Typography>
              <ListItemSecondaryAction>
                <ArrowDropDownIcon />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={() => {
                this.setState({ profileAnchorEl: null });
                this.props.history.push("/app/profile");
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <SettingIcon />
              </ListItemIcon>
              <Typography variant="inherit">Account Settings</Typography>
            </ListItem>
            <Divider />
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={() => {
                this.setState({ profileAnchorEl: null });
                this.props.setLogoutFromFlag(true);
                this.props.logout();
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <LogoutIcon />
              </ListItemIcon>
              <Typography variant="inherit">Logout</Typography>
            </ListItem>
          </List>
        </Popover>
        <NotificationToast />
        {this.state.openNotification && (
          <NotificationPopover
            isOpen={this.state.openNotification}
            onClose={() => {
              this.setState({
                openNotification: null,
              });
            }}
          />
        )}
      </div>
    );
  }
}

AppBarV2.defaultProps = {
  dropdown: true,
  withBack: false,
  dispatchEvent: false,
  isEditableTitle: false,
  isSubmittingTitle: false,
  dispathFrmApproval: false
};

export default connector(compose(withRouter, withStyles(styles))(AppBarV2));
