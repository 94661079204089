import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
    TableContainer, Table, TableBody, CircularProgress, TableCell, TableHead, TableRow, TextField, IconButton, Paper,
    InputAdornment,
    OutlinedInput,
} from '@material-ui/core';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import SqureButton from "Components/Common/SqureButton";
import DatePicker from "react-datepicker";
import CalendarImage from "assets/images/calendar.svg";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import classnames from "classnames";
import PublishIcon from '@material-ui/icons/Publish';
import { currencyOptions, currencyCodeMap } from "util/Currency";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

import { withAsyncPaginate } from "react-select-async-paginate";
import DoneIcon from "@material-ui/icons/Done";
import { showSnackBar } from "redux/snackbar/action";
import axios from "axios";
import Select from "react-select";
import FileAncher from "../FileAncher";

const SelectAsyncPaginate = withAsyncPaginate(Select);

var currencyTableTypeStyles = {
    control: styles => ({ ...styles, height: 46 }),
    menuPortal: base => ({ ...base, zIndex: 10 }),
    option: (provided, state) => ({
        ...provided,
    }),
    singleValue: (provided, state) => ({
        ...provided,
    })
};

const connectedProps = (state) => ({
});

const connectionActions = {
    showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        "& [class*='editableField']": {
            minHeight: 'auto !important',
        },
    },
    actionCol: {
        textAlign: 'center',
        width: '20%'
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 25
    },
    responseFilter: {
        marginRight: 20
    },
    filterTitle1: {
        marginBottom: 1,
        color: "#282D30",
        fontSize: 14,
    },
    formControl: {
        "& > div": {
            color: "hsl(0,0%,50%)",
            fontSize: 14,
            background: '#fff',
            border: '1px solid #222',
            borderRadius: 4,
            padding: 5
        },
    },
    topActions: {
        textAlign: 'right',
        marginBottom: 10
    },
    calendarPopout: {
        zIndex: '100 !important'
    },
});

class TableTypeReply extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeFilter: 0,
            priorityFilter: 0,
            statusFilter: 0,
            tasks: [],
            loader: true,
            page: 0,
            pageSize: 5,
            totalTasks: 0,
            isDataLoading: false,
            datas: []
        }
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.getColumnsType = this.getColumnsType.bind(this)
    }

    componentDidMount() {

        // this.fetchData();
        // this.setState({
        //     datas : JSON.parse(this.props.value) || []
        // })
    }

    componentDidUpdate(prevProps) {
        if (this.props.tableDatas.length !== prevProps.tableDatas.length) {
            this.initializeData()
        }
    }

    getColumnsType(colId) {
        const { additionalData } = this.props;
        let type = additionalData.filter(o => o.id === colId)[0].type;
        return type || 1;
    }

    initializeData() {
        console.log(this.props, 'PropsssDataTable')
        console.log(this.props.tableDatas, 'tableDatas')
        const { tableDatas } = this.props;
        let datas = []
        if (tableDatas) {
            tableDatas.forEach((dta) => {
                dta.forEach(function (im) {
                    let newRow = {
                        edit: false,
                        columns: im
                    }
                    datas.push(newRow)
                })
            })
            this.setState({
                datas: datas,
                isDataLoading: false
            })
        }


    }

    fetchData() {
        let params = {
            entity_id: this.props.appId,
            entity_type: this.props.catalogType === 'vendor' ? 'vendor' : 'vendorservice',
            item_slug: this.props.slug
        }
        this.setState({
            isDataLoading: true
        }, () => {
            this.props.fetchTableDatas(params);
        })
    }

    handleChangePage(e, newPage) {
        this.setState({
            page: newPage,
        })
    }

    handleChangeRowsPerPage(e) {
        this.setState({
            page: 0,
            pageSize: e.target.value,
        })
    }


    addRow = () => {
        let columns = new Array(this.props.additionalData.length).fill().map((o, index) => {
            const d = this.props.additionalData[index];
            return {
                label: d.id,
                value: d.type === 20 ? d.value : "",
                id: d.id,
                type: d.type,
            };
        });
        let newRow = {
            edit: true,
            columns: columns
        }
        let datas = this.state.datas;
        if (datas === null) {
            datas = [newRow];
        } else {
            datas.push(newRow);
        }
        this.setState({ datas });
    };

    showTableColumnFields = (column, index, i, classes) => {
        const { type } = column;
        const value = column?.value;
        // console.log('=============')
        // console.log(column)
        // console.log(index)
        // console.log(i)
        // console.log(classes)
        if (type === 2) {
            return <div className={classes.datePicker}>
                <DatePicker
                    readOnly={false}
                    selected={value ? new Date(value) : ''}
                    onChange={(date) => this.handleOnChangeValue(date, index, i)}
                    dateFormat="MMM do yyyy"
                    showMonthDropdown
                    showYearDropdown
                    popperClassName={classes.calendarPopout}
                    dropdownMode="select"
                    customInput={
                        <OutlinedInput
                            value={value}
                            readOnlyInput={true}
                            placeholder="Select Date"
                            labelStyle={"inline"}
                            startAdornment={
                                <InputAdornment>
                                    <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                </InputAdornment>
                            }
                        />
                    }
                />
            </div>
        } else if (type === 7) {
            return <SelectAsyncPaginate
                isClearable={false}
                isSearchable={true}
                menuPosition='fixed'
                classNamePrefix="select"
                isLoading={false}
                placeholder="Search user"
                value={value}
                additional={{
                    page: 0,
                }}
                loadOptions={this.getUserOptions}
                onChange={(e) => {
                    this.handleOnChangeValue(e, index, i);
                }}
                styles={{ control: base => ({ ...base, height: 46 }) }}
            />
        } else if (type === 8) {
            return <SelectAsyncPaginate
                isClearable={false}
                isSearchable={true}
                menuPosition='fixed'
                classNamePrefix="select"
                isLoading={false}
                placeholder="Search group"
                value={value}
                additional={{
                    page: 0,
                }}
                loadOptions={this.getTeamOptions}
                onChange={(e) => {
                    this.handleOnChangeValue(e, index, i);
                }}
                styles={{ control: base => ({ ...base, height: 46 }) }}
            />
        } else if (type === 9) {
            return <label htmlFor={`UploadTableColumnFile_${index}_${i}`} className={classnames(classes.tableColumnLinkText)}>
                {value?.name} {(this.state.uploadingTableColumnFileLoading?.index === index) && (this.state.uploadingTableColumnFileLoading?.i === i) ? <CircularProgress style={{ width: 30, height: 30 }} /> : <PublishIcon />}
                <input id={`UploadTableColumnFile_${index}_${i}`} className={classes.uploadImage} onChange={(e) => this.onTableColumnFileChange(e, index, i)} type="file" />
            </label>
        }
        else if (type === 11) {
            return <div className={classes.slateWrap2} id="userReplyAdd">
                <SlateInputField
                    enableMention={true}
                    folderPath={`/org_product/details/${this.props.match?.params?.id}`}
                    placeholder={"Enter value"}
                    style={{ margin: 0 }}
                    as={SlateInputField}
                    onChangeEvent={value => this.handleOnChangeValue(value, index, i)}
                    maxLength={10}
                    initValue={(value !== null && value) || ""}
                    actionButtonsRequired={false}
                    formControlStyle={{ overflow: 'overlay' }}
                    textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', color: "#707070", fontSize: 14 }}
                />
            </div>
        } else if (type === 20) {
            return <div className={classes.currencyTypeFields} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div style={{ width: 100, marginRight: 5, zIndex: 1 }}>
                    <Select
                        isMulti={false}
                        labelId="singleSelect-select-label"
                        id="singleSelect-select"
                        value={value?.type}
                        onChange={(e) => {
                            let newValue = {
                                type: e,
                                value: value?.value
                            }
                            this.handleOnChangeValue(newValue, index, i);
                        }}
                        options={currencyOptions}
                        placeholder={"Select a Option"}
                        styles={currencyTableTypeStyles}
                    >
                    </Select>
                </div>
                <CurrencyTextField
                    variant="outlined"
                    value={value.value}
                    currencySymbol={currencyCodeMap[value?.type?.value]}
                    style={{ textAlign: 'left', flex: 1, height: 46 }}
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    required={false}
                    onChange={(e, v) => {
                        let newValue = {
                            type: value?.type,
                            value: v
                        }
                        this.handleOnChangeValue(newValue, index, i);
                    }}
                />
            </div>
        } else {
            return <InputField
                classes={classes}
                val={value}
                type={(type === 13 || type === 19) ? "number" : "text"}
                placeholder="Enter value"
                onChange={(value) => {
                    this.handleOnChangeValue(value, index, i);
                }}
            />
        }
    }

    printTableColumnData = (data, type, classes) => {
        if (!data) {
            return "";
        }
        else if (type === 2) {
            return CommonFn.formateDate(data, true);
        }
        else if (type === 7 || type === 8) {
            return data.label
        } else if (type === 9) {
            return <FileAncher
            fileName={data.name}
            fileURL={data.link}
            />
        } else if (type === 11) {
            return (
                <div className={classes.slateWrap2}>
                    <SlateInputFieldV2
                        readOnly={true}
                        isToolBar={false}
                        placeholder=""
                        style={{ margin: 0 }}
                        as={SlateInputFieldV2}
                        initValue={data}
                        formControlStyle={{ border: "none", borderColor: "#fff", overflow: "hidden", margin: "0px !important" }}
                        textContainerStyle={{ minHeight: "10px !important", margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }}
                    />
                </div>
            );
        } else if (type === 20) {
            return <div>
                {data && data.value && data.value !== '' && <p className={classes.data}>{currencyCodeMap[data.type.value] + '' + data?.value}</p>}
                {data && data.value === '' && <p className={classes.data}>-</p>}
            </div>
        } else {
            return data;
        }
    };

    getUserOptions = async (inputValue, loadedOptions, additional) => {
        var page = 0;
        if (additional !== undefined && additional.page !== undefined) {
            page = additional.page;
        }
        const api_server = Environment.api_host("USER");
        const timestamp = new Date().getTime();
        if (!inputValue) {
            return {
                options: [],
                hasMore: false,
                additional: {
                    page: 0,
                },
            };
        }

        let formData = {
            page: 0,
            pageSize: 20,
            query: inputValue
        };
        const url = `${api_server}/fetch?t=${timestamp}`;
        const response = await axios.post(url, formData, {
            headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
        });
        // console.log(response)

        let apps = response?.data?.Data || [];
        let total = response?.data?.Data?.total || 0;
        apps = apps.map((m) => ({
            ...m,
            label: m.Name,
            value: m.ID,
        }));
        return {
            options: apps,
            hasMore: (page + 1) * 10 < total ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };

    getTeamOptions = async (inputValue, loadedOptions, additional) => {
        var page = 0;
        if (additional !== undefined && additional.page !== undefined) {
            page = additional.page;
        }
        const api_server = Environment.api_host("USER");
        const timestamp = new Date().getTime();
        if (!inputValue) {
            return {
                options: [],
                hasMore: false,
                additional: {
                    page: 0,
                },
            };
        }

        const url = `${api_server}/groups?page=0&pageSize=20&query=${inputValue}&is_suppressed=no&t=${timestamp}`;
        const response = await axios.get(url, {
            headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
        });

        let apps = response?.data?.Data || [];
        let total = response?.data?.Data?.total || 0;
        apps = apps.map((m) => ({
            ...m,
            label: m.Name,
            value: m.ID,
        }));
        return {
            options: apps,
            hasMore: (page + 1) * 10 < total ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };

    onTableColumnFileChange = async (event, index, i) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            var formData = new FormData();
            formData.append("folder", `/orgProduct/TableTypeFiles/${this.props.match?.params?.id}`);
            const timestamp = new Date().getTime();
            formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf(".") + 1)}`);
            const api_server = Environment.api_host("STORAGE");
            const url = `${api_server}/add?t=${timestamp}`;
            this.setState({ uploadingTableColumnFileLoading: { index: index, i: i } });

            axios.post(url, formData, {
                headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "multipart/form-data" },
            })
                .then((response) => {
                    let data = {
                        name: file.name,
                        link: response?.data?.filepath,
                        createdAt: new Date(),
                    };
                    this.handleOnChangeValue(data, index, i);
                    this.setState({ uploadingTableColumnFileLoading: null });
                })
        }
    };

    handleEdit(row, index) {
        const datas = this.state.datas;
        const erow = datas[index];
        erow.edit = true;
        datas[index] = erow;
        this.setState({ datas });
    }

    handleDelete(row, index) {
        // this.setState({ isDataLoading: true });
        const datas = this.state.datas;
        // const erow = datas[index];
        datas.splice(index, 1);
        this.setState({
            datas: datas
        }, () => {
            this.props.updateData(datas)
        })
    }

    handleOnChangeValue = (value, row, column) => {
        const datas = this.state.datas;
        const erow = datas[row];
        erow.columns[column].value = value;
        datas[row] = erow;
        this.setState({ datas });
    };

    handleAddOrUpdate(row, index) {
        console.log(row)
        console.log(index)
        let datas = this.state.datas;
        let rowData = datas[index]
        rowData.edit = false;

        let finalDatas = [];
        datas.forEach(function (d) {
            if (!d.edit) {
                finalDatas.push(d.columns)
            }
        })
        // console.log(datas,'datas')
        // console.log(finalDatas,'finalDatas')

        this.setState({
            datas: datas
        }, () => {
            this.props.updateData([finalDatas])
        })
    }

    render() {
        const { classes, additionalData, isEdit } = this.props;
        const { datas, isDataLoading } = this.state;

        var noTaskMsg = 'No Data'

        console.log(datas, 'datasTable')
        return (
            <div className={classes.root} key={this.props.key}>
                <Paper style={{ width: '100%', overflow: 'hidden' }}>
                    {isEdit && <div className={classes.topActions}>
                        <SqureButton onClick={() => this.addRow()}>+ Add Row</SqureButton>
                    </div>}
                    <TableContainer style={{ maxHeight: 560 }}>
                        <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
                            <TableHead>
                                <TableRow>
                                    {additionalData.map((item) => (
                                        <TableCell style={{ minWidth: 250 }}>{item.label}</TableCell>
                                    ))}
                                    {isEdit && <TableCell style={{ minWidth: 250, textAlign: 'center' }}>Action</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isDataLoading && datas.map((row, index) => {
                                    return <TableRow key={`${this.props.itemId}_table_row_${index}`}>
                                        {row.columns.map((column, i) => (
                                            <TableCell key={`${this.props.itemId}_table_row_${index}_cell_${i}`}>
                                                {row.edit ? (
                                                    this.showTableColumnFields(column, index, i, classes)
                                                ) : (
                                                    <p className={classes.tableColumnData}>{this.printTableColumnData(column.value, column.type, classes)}</p>
                                                )}
                                            </TableCell>
                                        ))}
                                        {isEdit && <TableCell className={classes.actionCol}>
                                            {!row.edit && <IconButton onClick={() => this.handleEdit(row, index)}>
                                                <Image src={editIcon} />
                                            </IconButton>}

                                            {row.edit && <IconButton onClick={() => this.handleAddOrUpdate(row, index)}>
                                                <DoneIcon />
                                            </IconButton>}

                                            <IconButton onClick={() => this.handleDelete(row, index)}>
                                                <Image src={deleteIcon} />
                                            </IconButton>
                                        </TableCell>}
                                    </TableRow>
                                })}

                                {(!isDataLoading && (datas === undefined || datas === null || datas.length <= 0)) && <TableRow>
                                    <TableCell colSpan={additionalData.length + 1} style={{ textAlign: 'center' }}>
                                        {noTaskMsg}
                                    </TableCell>
                                </TableRow>}

                                {isDataLoading && <TableRow>
                                    <TableCell colSpan={additionalData.length + 1} style={{ textAlign: 'center' }}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(TableTypeReply));

const InputField = ({ classes, onChange, val, type, placeholder, isFocused = false }) => {
    const [value, setValue] = useState("");
    useEffect(() => {
        setValue(val);
    }, [val]);
    return (
        <TextField
            fullWidth
            autoFocus={isFocused}
            className={classes.formControl}
            value={value}
            type={type ? type : 'text'}
            InputProps={{ disableUnderline: true }}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};
