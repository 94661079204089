import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import DefaultImage from "assets/images/evaluation/default.svg";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import SearchInput from "Components/Common/Input/SearchInput";
// Redux
import { fetchSellerProducts } from "redux/seller/product/action";
import AppBarV2 from "../../../../../Application/AppBarV2";
import CommonCss from 'commonCss';

const connectedProps = (state) => ({
  sellerProductProg: state.sellerProduct.sellerProductProg,
  sellerProducts: state.sellerProduct.sellerProducts,
  addOrgProductProgress: state.orgProduct.addOrgProductProgress,
});

const connectionActions = {
  fetchSellerProducts: fetchSellerProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(5),
      maxHeight: "calc(100vh - 200px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 200px)",
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
      textAlign: "center",
    },
    rootContainer: CommonCss.rootContainer
  });

class MyProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchObj: {
        page: 0,
        size: 100,
        text: "",
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const searchObj = this.state.searchObj;
    this.props.fetchSellerProducts([1], {
      query: searchObj.text,
      page: searchObj.page,
      pageSize: searchObj.size,
    });
  }

  render() {
    const classes = this.props.classes;
    return (
      <>
        <AppBarV2 title="Unverified Products" withBack={true} />
        <div className={classes.rootContainer}>
          <div className={classes.stickyBar}>
            <Grid container justify="center">
              <SearchInput
                roundedCorner
                placeholder="Search Product"
                showTypes={false}
                isFilter={false}
                page="metrics"
                searchIcon={true}
                searchObj={this.state.searchObj}
                onChange={(searchObj) => {
                  this.setState({ searchObj }, () => {
                    this.fetchData();
                  });
                }}
              />
            </Grid>
          </div>
          <div className={classes.root} id="productContainer">
            <InfiniteScroll
              scrollableTarget="productContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
              dataLength={this.props.sellerProducts?.total || 0}
              next={() => {
                this.setState(
                  {
                    searchObj: {
                      ...this.state.searchObj,
                      page: this.state.searchObj.page + 1,
                    },
                  },
                  () => {
                    this.fetchData();
                  }
                );
              }}
              hasMore={
                parseInt((this.props.sellerProducts?.Data && this.props.sellerProducts?.Data.length) || 0) >
                (this.state.searchObj.page + 1) * this.state.searchObj.size
              }
              endMessage={<></>}
            >
              <Grid container alignItems="center" justify="center">
                {this.props.sellerProductProg && <CircularProgress />}
                {!this.props.sellerProductProg &&
                  this.props.sellerProducts?.Data &&
                  this.props.sellerProducts?.Data.length === 0 && (
                    <Typography align="center">No Data Found!</Typography>
                  )}
              </Grid>
              {!this.props.sellerProductProg && (
                <Grid container spacing={3}>
                  {this.props.sellerProducts?.Data &&
                    this.props.sellerProducts?.Data.map((product, index) => (
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2} key={index}>
                        <LayeredCard
                          title={product.Name}
                          subTitle={product.CompanyName}
                          description={product.Description}
                          image={
                            product.ProductLogo == null ||
                            product.ProductLogo.length === 0
                              ? DefaultImage
                              : product.ProductLogo
                          }
                          toogleCheck={() => {}}
                          onClick={() => {}}
                          numLayer={0}
                          id={product.ID}
                          uniqueId={product.ID + "_f_" + index}
                          type={"product"}
                          source={"SELLER"}
                          customRoute={"/sellerapp/products/product-details/"}
                        />
                      </Grid>
                    ))}
                </Grid>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </>
    );
  }
}

export default connector(compose(withStyles(styles))(MyProducts));
