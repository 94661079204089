import { Grid } from "@material-ui/core";
import { createStyles, withStyles } from '@material-ui/styles';
import CommonCss from "commonCss";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import classnames from "classnames";
import React, { Component } from 'react';
import CustomItem from './CustomItem';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';

const styles = (theme) => createStyles({
  ...CommonCss.ProductDetails,
  root: {
    border: '1px solid #d8d8d8',
    padding: 17,
    borderRadius: 5,
    background: '#FFF',
    marginBottom: 17,
    position: 'relative'
  },
  editIcon: {
    color: "#4A87F8",
    cursor: "pointer",
    fontSize: 16
  },
  head: {
    color: '#6C6C6C',
    fontSize: 18,
    opacity: 1,
    fontWeight: 600,
    margin: 0,
    marginBottom: 15,
    '&:hover': {
      background: '#f7f7f7'
    }
  },
  actionIcon: {
    width: 30,
    height: 30,
    borderRadius: 5,
    textAlign: 'center',
    marginLeft: 5,
    border: '1px solid #4b86f8'
  },
  micon: {
    fontSize: 16,
    marginTop: 6,
    color: '#4b86f8',
    cursor: 'pointer'
  },
  OutlinedInput: {
    minHeight: '25px !important',
    padding: 6,
    border: '1px solid #c4c4c4',
    borderRadius: 2,
    color: '#5F5F5F',
    fontSize: '1.1em'
  },
  dragIndicator: {
    color: '#D9D9D9',
    position: 'absolute',
    top: 15,
    left: '50%',
    transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
  },
  sectionTitle: {
    "& [class*=edit-btn]": {
      visibility: 'hidden',
    },
    "&:hover [class*=edit-btn]": {
      visibility: 'visible',
    }
  }
})

class CustomSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSection: null,
      name: props.section.Name,
      isEditName: false
    }
  }

  render() {
    const { classes, section, productID } = this.props;
    let items = []

    if (section && section.SubSections && section.SubSections.length) {
      items = section.SubSections
    }

    return (
      <div className={classes.root} id={`${section.Name.toLowerCase().split(" ").join("-")}`}>
        {this.props.user?.Role === 'OrgAdmin' && <DragIndicatorOutlinedIcon className={classes.dragIndicator} />}
        <Grid container alignItems="center" spacing={2} justifyContent="space-between">
          <Grid item className={classes.sectionTitle}>
            {!this.state.isEditName && (
              <Grid container alignItems="center">
                <p
                  className={classes.head}
                  onClick={() => {
                    if (this.props.user?.Role === 'OrgAdmin') {
                      this.setState({ isEditName: true })
                    }
                  }}
                >{this.state.name}
                </p>
                {this.props.user?.Role === 'OrgAdmin' && (
                  <IconButton
                    style={{ width: 22, height: 22, marginBottom: 15 }}
                    onClick={() => {
                      this.setState({
                        isEditName: true
                      });
                    }}
                    className="edit-btn"
                  >
                    <EditIcon
                      fontSize={"small"}
                      className={classes.editIcon}
                    />
                  </IconButton>
                )}
              </Grid>
            )}
            {this.state.isEditName && (
              <Grid container style={{ alignItems: 'end' }}>
                <input
                  value={this.state.name}
                  onChange={(event) => {
                    this.setState({ name: event.target.value });
                  }}
                  className={classes.OutlinedInput}
                />
                <Grid container style={{ width: 'max-content', alignItems: 'end' }}>
                  <div
                    className={classes.actionIcon}
                    onClick={(e) => {
                      e.stopPropagation()
                      this.setState({ name: section.Name, isEditName: false })
                    }}
                  >
                    <ClearIcon className={classes.micon} />
                  </div>
                  {this.state.name && (
                    <div
                      className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (this.state.name) {
                          this.setState({ isEditName: false })
                          this.props.updateOrgSectionTitle(
                            this.props.categoryID,
                            this.props.tabUID,
                            this.props.section.ID,
                            { name: this.state.name }
                          )
                        }
                      }}
                    >
                      <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          {this.props.user?.Role === 'OrgAdmin' && (
            <Grid item>
              <Image
                src={deleteIcon}
                className={classes.deleteImage}
                style={{ top: 15 }}
                onClick={(event) => {
                  event.stopPropagation()
                  this.props.deleteOrgTabSection(
                    this.props.categoryID,
                    this.props.tabUID,
                    this.props.section.ID,
                  )
                }}
              />
            </Grid>
          )}
        </Grid>
        {items && items.length > 0 && items.map((item, index) => (
          <CustomItem
            key={item.ID}
            index={index}
            type={item.InuptType}
            title={item.Name}
            additionalData={item.AdditionalData}
            answer={item.Answer}
            itemUID={item.ID}
            tabUID={this.props.tabUID}
            categoryID={this.props.categoryID}
            productID={productID}
            sectionUID={section.ID}
            isAdmin={this.props.user?.Role === 'OrgAdmin'}
            sectionName={section.Name}
            item={item}
            updateSubSection={this.props.updateSubSection}
          />
        ))}
      </div>
    )
  }
}

export default React.memo(withStyles(styles)(CustomSection))
