import React from "react";
import { size } from "underscore";
import {
  Dialog, DialogContent, Slide, Table, TableBody, TableRow, TableCell, DialogTitle, Grid
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { createStyles, withStyles } from '@material-ui/core/styles';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => createStyles({
});

const PricingDialog = ({ isOpen, handleOnClose, classes, pricingData }) => (
  <Dialog
    onClose={handleOnClose}
    aria-labelledby="PricingDialog"
    open={isOpen}
    TransitionComponent={Transition}
    scroll={"body"}
    fullWidth={true}
    maxWidth="md"
    className={classes.dialog}
    id={`pricing_dialog`}
  >
    <DialogTitle style={{ paddingTop: 0, paddingBottom: 0 }}>
      <Grid container alignItems="center" justifyContent="space-between">
        <p>Pricing Information</p>
        <ClearIcon style={{ cursor: "pointer" }} onClick={handleOnClose}/>
      </Grid>
    </DialogTitle>
    <DialogContent>
      {pricingData && size(pricingData.plans) > 0 ? (
        <Table>
          <TableBody>
          <TableRow>
          {pricingData.plans.map((plan, index) => (
            <TableCell style={{ border: '1px solid #bdbdc0', verticalAlign: 'baseline' }}>
              <p><strong>{plan?.name}</strong></p>
              {(plan?.billing?.monthly === 'nan' && plan?.billing?.yearly === 'nan') || plan?.billing?.unknown === 'nan' ? (
                <p>Not Available</p>
              ):(
                <>
                  <p style={{ whiteSpace: 'pre-line' }}>{plan?.billing?.monthly === 'nan' ? '' : plan?.billing?.monthly}</p>
                  <p style={{ whiteSpace: 'pre-line' }}>{plan?.billing?.yearly === 'nan' ? '' : plan?.billing?.yearly}</p>
                  <p style={{ whiteSpace: 'pre-line' }}>{plan?.billing?.unknown === 'nan' ? '' : plan?.billing?.unknown}</p>
                </>
              )}
              {plan?.description === 'nan' || plan?.description === 'null' 
                ? (''
                ):(
                  <ul style={{ padding:0, paddingLeft: 16 }}> 
                    {plan?.description && plan?.description.split("\n").map(item => (
                      <li>{item}</li>
                    ))}
                  </ul>
                )}
            </TableCell>
          ))}
          </TableRow>
          </TableBody>
        </Table>
      ) : (
        <p>{pricingData.plan_desc}</p>
      )}
      {![null, undefined].includes(pricingData.pricing_url) && (
        <p>Please visit seller
          <span >
            {' '}<a href={pricingData.pricing_url} style={{ color: 'blue', cursor: 'pointer' }} target="_blank" rel="noopener noreferrer"><u>pricing</u></a>{' '}
          </span>
          page for more information.
        </p>
      )}
      <p style={{ fontSize: '0.8rem' }}>
        Pricing information for {pricingData?.name} is supplied by the software provider or retrieved from publicly accessible pricing materials. Final cost negotiations to purchase {pricingData?.name} must be conducted with the seller.
      </p>
    </DialogContent>
  </Dialog >
)

export default React.memo(withStyles(styles)(PricingDialog))
