import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment"

export const START_FETCH_RENEWAL = "START_FETCH_RENEWAL";
export const END_FETCH_RENEWAL = "END_FETCH_RENEWAL";

export const START_ATTACH_PRODUCT_TO_REQUEST = "START_ATTACH_PRODUCT_TO_REQUEST";
export const END_ATTACH_PRODUCT_TO_REQUEST = "END_ATTACH_PRODUCT_TO_REQUEST";

export const START_UPDATE_RENEWAL = "START_UPDATE_RENEWAL";
export const END_UPDATE_RENEWAL = "END_UPDATE_RENEWAL";

export const START_ADD_RENEWAL_COLLOBORATOR = "START_ADD_RENEWAL_COLLOBORATOR";
export const END_ADD_RENEWAL_COLLOBORATOR = "END_ADD_RENEWAL_COLLOBORATOR";

export const START_DELETE_RENEWAL_COLLOBORATOR = "START_DELETE_RENEWAL_COLLOBORATOR";
export const END_DELETE_RENEWAL_COLLOBORATOR = "END_DELETE_RENEWAL_COLLOBORATOR";

export const START_NEW_RENEWAL="START_NEW_RENEWAL";
export const END_NEW_RENEWAL="END_NEW_RENEWAL";

export const START_UPDATE_ENTITY_RENEWAL="START_UPDATE_ENTITY_RENEWAL";
export const END_UPDATE_ENTITY_RENEWAL="END_UPDATE_ENTITY_RENEWAL";

function startFetchRenewal() {
    return {
        type: START_FETCH_RENEWAL,
        payload: {}
    }
}

function endFetchRenewal(success, error) {
    return {
        type: END_FETCH_RENEWAL,
        payload: {
            success,
            error
        }
    }
}


function startAttachProductToRequest() {
    return {
        type: START_ATTACH_PRODUCT_TO_REQUEST,
        payload: {}
    }
}

function endAttachProductToRequest(success, error) {
    return {
        type: END_ATTACH_PRODUCT_TO_REQUEST,
        payload: {
            success,
            error
        }
    }
}

export function attachProductToRequest(productId, requestId, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${requestId}/renewals/product/${productId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAttachProductToRequest());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAttachProductToRequest(response, error));
        } catch (e) {
            dispatch(endAttachProductToRequest(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function fetchRenewal(data = {}) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/renewals?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchRenewal());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
            dispatch(endFetchRenewal(response, error));
        } catch (e) {
            dispatch(endFetchRenewal(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateRenewal() {
    return {
        type: START_UPDATE_RENEWAL,
        payload: {}
    }
}

function endUpdateRenewal(success, error) {
    return {
        type: END_UPDATE_RENEWAL,
        payload: {
            success,
            error
        }
    }
}

export function updateRenewal(productId, data = {}) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/renewal/${productId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateRenewal());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateRenewal(response, error));
        } catch (e) {
            dispatch(endUpdateRenewal(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateEntityRenewal() {
    return {
        type: START_UPDATE_ENTITY_RENEWAL,
        payload: {}
    }
}

function endUpdateEntityRenewal(success, error) {
    return {
        type: END_UPDATE_ENTITY_RENEWAL,
        payload: {
            success,
            error
        }
    }
}

export function updateEntityRenewal(productId, data = {}) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/entity-renewal/${productId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateEntityRenewal());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateEntityRenewal(response, error));
        } catch (e) {
            dispatch(endUpdateEntityRenewal(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startstartNewRenewalProcess() {
    return {
        type: START_NEW_RENEWAL,
        payload: {}
    }
}

function endstartNewRenewalProcess(success, error) {
    return {
        type: END_NEW_RENEWAL,
        payload: {
            success,
            error
        }
    }
}

export function startNewRenewalProcess(productId,data = {}) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/renewal/${productId}/start?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startstartNewRenewalProcess());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endstartNewRenewalProcess(response, error));
        } catch (e) {
            dispatch(endstartNewRenewalProcess(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startRenewalColloboratorAdd(){
    return {
        type:START_ADD_RENEWAL_COLLOBORATOR,
        payload:{}
    };
}

function endRenewalColloboratorAdd(evId,isWatcher,success,error){
    return {
        type:END_ADD_RENEWAL_COLLOBORATOR,
        payload:{
            success,error,isWatcher,evId
        }
    };
}


//Thunk to perform the authorizedUser
export  function addColloborator(evId,userId,isWatcher=false){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/colloborator?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startRenewalColloboratorAdd());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,{
                userId:userId,
                isWatcher:isWatcher
            });
            dispatch(endRenewalColloboratorAdd(evId,isWatcher,response,error));
        } catch(e){
            dispatch(endRenewalColloboratorAdd(evId,isWatcher,null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startRenewalColloboratorDelete(){
    return {
        type:START_DELETE_RENEWAL_COLLOBORATOR,
        payload:{}
    };
}

function endRenewalColloboratorDelete(userId,evId,isWatcher,success,error){
    return {
        type:END_DELETE_RENEWAL_COLLOBORATOR,
        payload:{
            success,error,isWatcher,evId,userId
        }
    };
}


export  function deleteRenewalColloborator(evId,userId,isWatcher=false){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/colloborator/${userId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startRenewalColloboratorDelete());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
            dispatch(endRenewalColloboratorDelete(userId,evId,isWatcher,response,error));
        } catch(e){
            dispatch(endRenewalColloboratorDelete(userId,evId,isWatcher,null,{
                message:e.message
            }));
            return;
        }
    };
} 
