import React from 'react';
import { connect } from "react-redux";
import { createStyles, withStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import OutlinedInput from "../Input/OutlinedInput";
import { InputAdornment, Grid, Button, Paper, Typography } from '@material-ui/core';
import { Search as SearchIcon } from "@material-ui/icons";
import AppCard from "./AppCard";
import OktaIntegrationCard from "./OktaIntegrationCard";
import AzureIntegrationCard from "./AzureIntegrationCard";
import SlackIntegrationCard from "./SlackIntegrationCard";
import { fetch, fetchAppConfig } from "../../../redux/applications/action";
import AppIntegrationFormDialog from "./AppIntegrationFormDialog";
import classnames from "classnames";
import { updateInitalFlowFlag } from "../../../redux/InitialFlowFlag/action"
import { getEnabledCognitoSSOApps, getSlackStateCode } from "redux/authuser/action";

import Clear from "@material-ui/icons/Clear";
import TooltipMsg from '@material-ui/core/Tooltip';


const connectedProps = (state) => ({
    isProgress: state.applications.isProgress,
    isinitialFlowFlagProgress: state.initialFlowFlag.isProgress,
    error: state.applications.error,
    applications: state.applications.apps,
    user: state.authUser.user,
    slackStateCode: state.authUser.slackStateCode,
    slackStateCodeProgress: state.authUser.slackStateCodeProgress,
    enabledCognitoSSOAppsData: state.authUser.enabledCognitoSSOAppsData,
    slackStateCodeError: state.authUser.slackStateCodeError,
});

const connectionActions = {
    fetch: fetch,
    fetchAppConfig: fetchAppConfig,
    updateInitalFlowFlag: updateInitalFlowFlag,
    getSlackStateCode: getSlackStateCode,
    getEnabledCognitoSSOApps: getEnabledCognitoSSOApps,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    'root': {
        boxShadow: "none",
        minHeight: 'calc(100vh - 3em)',
        padding: theme.spacing(3.4, 16),
        border: "1px solid #E5E5E5",
        borderRadius: 0,
        "&.SettingsIntegrations": {
            background: '#f7f7f7',
            padding: theme.spacing(3, 0, 0, 0),
            border: "none",

            "& $searchBar": {
                display: "inline-block",
                flexGrow: 2,
                paddingRight: "155px",
                maxWidth: "650px",
                marginBottom: "0"
            },

            "& $title": {
                maxWidth: "155px",
                fontSize: theme.spacing(2.4)
            }
        },
    },
    "heading": {
        margin: '0 auto',
        textAlign: "center",
        marginBottom: theme.spacing(10),
    },

    "searchIcon": {
        marginRight: theme.spacing(3)
    },

    "searchBar": {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        '& [class*="MuiOutlinedInput-root"]': {
            maxHeight: 40,
            minHeight: 40,
            maxWidth: 343,
            margin: '0px auto'
        },
        "& [class*='MuiInputBase-input']": {
            fontSize: 13,
            width: 300,
        },
        "& [class*='MuiSvgIcon-root']": {
            fill: "#707070",
        },
        "& [class*=MuiGrid-container]": {
            alignItems: "center",
            marginBottom: 20,
        },
        "&>div ": {
            marginBottom: 0,
        },
    },
    "apps": {
        margin: '0 auto',
        padding: theme.spacing(2, 0),
        marginBottom: theme.spacing(10),
    },

    appFromSetting: {
        marginTop: theme.spacing(0),
    },

    textAndSearchFromSetting: {
        marginBottom: theme.spacing(4),
    },

    "formAction": {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: "center",
    },

    "button": {
        padding: theme.spacing(1.2),
        fontSize: theme.spacing(2),
        fontWeight: 600,
        width: "170px"
    },

    "title": {
        fontSize: theme.spacing(3.5),
        flexGrow: 1,
        margin: theme.spacing(4, 0, 1),
        fontWeight: 500
    },

    titleFromSetting: {
        marginTop: 0
    },

    "textAndSearch": {
        // display: "flex",
        // alignItems: "center",
        flexDirection: 'row',
        boxSizing: ' border-box',
        display: 'flex',
        placeContent: 'center space-between',
        alignItems: 'center'
    },

    skipButton: {
        marginLeft: 10,
        marginRight: 20,
        padding: "7.4px 54px",
        border: "1px solid #20253A",
        fontSize: 14
    },
    searchIconPop: {
        cursor: 'pointer',
        fontSize: theme.spacing(3),
        marginRight: '10px',
        "&:hover": {
          color: "#fff !important",
          fill: "#fff !important",
          background: "#707070",
          borderColor: "#707070",
          padding: '3px',
          borderRadius: theme.spacing(20),
          fontSize: theme.spacing(3)
        }
      },
});

class Apps extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 9,
            query: "",
        }
        this.fetchApplication = this.fetchApplication.bind(this);
        this.searchInput = this.searchInput.bind(this);
        this.handleClear = this.handleClear.bind(this);

    }

    componentDidMount() {
        this.props.getSlackStateCode()
        this.props.getEnabledCognitoSSOApps()
        this.fetchApplication();

    }

    fetchApplication() {
        this.props.fetch(this.state.page, this.state.pageSize, this.state.query);
    }

    handleClear(event) {
        event.stopPropagation();
        var me = this;
        this.setState({ query: '',page :0}, () => {
          me.fetchApplication()
        });
      }
    

    searchInput(classes) {
        return (<div className={classes.searchBar}>
            <OutlinedInput
                id={"searchIntegrations"}
                placeholder="Search for Integrations (eg Okta)"
                value={this.state.query}
                onChange={(event) => {
                    this.setState({ 'query': event.target.value, page: 0 }, () => {
                        this.fetchApplication()
                    })
                }}
                // onKeyDown={(event) => {
                //     this.setState({ 'query': event.target.value, page: 0 }, () => {
                //         this.fetchApplication()
                //     })
                // }}
                startAdornment={
                    <InputAdornment>
                        <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment>
                      {this.state.query.length > 0 && this.state.query !== '' && this.state.query !== null && <TooltipMsg placement="top" title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Clear</span>} arrow>
                        <Clear className={classes.searchIconPop} onClick={this.handleClear} />
                      </TooltipMsg>}
                    </InputAdornment>
                  }

                roundedCorner={true}
            />
        </div>)
    }

    componentDidUpdate(prevProps) {
        if (this.props.isFromSetting) {
            for (let i = 0; i < this.props.applications.length; i++) {
                if (this.props.applications[i] !== prevProps.applications[i]) {
                    this.props.fetchAppConfig(this.props.applications[i]);
                }
            }
        }

        if (this.props.isinitialFlowFlagProgress === false && prevProps.isinitialFlowFlagProgress === true) {
            if (this.props.error !== null) {
                var error = this.props.error;
                alert(error.message);
            } else {
                this.props.history.push("/app/settings")
            }
        }
    }

    render() {
        const classes = this.props.classes;

        if (this.props.user == null) {
            return null;
        }
        var org = this.props.user.Organization

        const configPresent = [];
        const configAbsent = [];
        for (let i = 0; i < this.props.applications.length; i++) {
            if (this.props.applications[i].currentConfig) {
                configPresent.push(this.props.applications[i]);
            } else {
                configAbsent.push(this.props.applications[i]);
            }
        }

        console.log(configAbsent, 'configAbsent')

        const azureAd = {
            isComingSoon: true,
            AppCategory: "Active Directory",
            AppLink: "https://devapi.expent.ai/okta",
            AppLogo: "https://assets.expent.ai/azure-sso.png",
            AppName: "Azure AD single sign-on",
            CreatedAt: "2020-11-18T18:35:15Z",
            Description: "Secure and seamless access for all your users and apps—from any location, using any device",
            WebLink: "https://docs.microsoft.com/en-us/azure/active-directory/hybrid/how-to-connect-sso-how-it-works",
        }

        return <Paper className={classnames(classes.root, this.props.root)}>

            {/* start: On boarding page - integrations section */}

            {!this.props.isFromSetting && <> <div className={classes.heading}>
                <Typography variant={"h4"} className={classes.title} color="secondary">Integrations for you</Typography>
                <Typography>Add one or more of the below integrations to get metrics and visibility into your existing products or choose skip to add integrations later.</Typography>
            </div>
                {false && this.searchInput(classes)}

                <div className={classes.apps}>
                    <Grid container spacing={3}>
                        {
                            this.props.applications.length !== 0 && this.props.applications.map((app, k) => {
                                return <Grid item lg={4} md={6} sm={12} xs={12} key={k}>
                                    <AppCard app={app} config={app.currentConfig} />
                                </Grid>
                            })
                        }
                    </Grid>
                </div>

                <div className={classes.formAction}>
                    <Button variant="outlined" color="secondary" className={classes.skipButton} onClick={() => {
                        this.props.updateInitalFlowFlag();
                    }} >Skip</Button>
                    <Button variant="contained" color="secondary" className={classes.button} onClick={() => {
                        this.props.history.push("/onboard/team");
                    }}>Next</Button>
                </div>
            </>}

            {/* end: On boarding page - integrations section */}

            {/* start: Setting page - integrations section */}
            {this.props.isFromSetting &&
                <div className={classnames(classes.apps, classes.appsFromSetting)}>
                    <div className={classnames(classes.textAndSearch, classes.textAndSearchFromSetting)}>
                        {(configPresent.length > 0 || this.props.enabledCognitoSSOAppsData?.find(item=> item.Driver === 'okta')) && <>
                            <Typography variant={"h6"} className={classnames(classes.title, classes.titleFromSetting)}>Your Integrations</Typography>
                            {false && this.searchInput(classes)}
                            <div></div>
                        </>}
                    </div>
                    <Grid container spacing={3}>
                        {
                            configPresent.length !== 0 && configPresent.map((app, k) => {
                                return <React.Fragment key={k}>{<Grid item lg={3} md={3} sm={4} xs={12}>
                                    <AppCard app={app} config={app.currentConfig} root={this.props.root} isFromSetting={this.props.isFromSetting} /></Grid>
                                }
                                </React.Fragment>
                            })
                        }
                        {this.props.isFromSetting && this.props.enabledCognitoSSOAppsData?.find(item=> item.Driver === 'okta') && <Grid item lg={3} md={3} sm={4} xs={12} >
                            <OktaIntegrationCard root={this.props.root} isFromSetting={this.props.isFromSetting} />
                        </Grid>}
                        {this.props.isFromSetting && this.props.enabledCognitoSSOAppsData?.find(item=> item.Driver === 'azure') && <Grid item lg={3} md={3} sm={4} xs={12} >
                            <AzureIntegrationCard root={this.props.root} isFromSetting={this.props.isFromSetting} />
                        </Grid>}
                        {!this.props.slackStateCodeProgress && this.props.slackStateCode && (
                            <Grid item lg={3} md={3} sm={4} xs={12} >
                                <SlackIntegrationCard root={this.props.root} isFromSetting={this.props.isFromSetting} />
                            </Grid>
                        )}
                    </Grid>
                    <div className={classnames(classes.textAndSearch, classes.textAndSearchFromSetting)}>
                        {(configPresent.length > 0 || this.props.enabledCognitoSSOAppsData?.find(item=> item.Driver === 'okta')) && <Typography variant={"h6"} className={classes.title}>All Integrations</Typography>}
                        {false && configPresent.length === 0 && (configAbsent.length > 0 || org.IsOktaSSO !== true) && this.searchInput(classes)}
                    </div>
                    <Grid container spacing={3}>
                        {
                            configAbsent.length > 0 && configAbsent.map((app, k) => {
                                return <React.Fragment key={k}>{<Grid item lg={3} md={3} sm={4} xs={12} >
                                    <AppCard app={app} config={app.currentConfig} root={this.props.root} isFromSetting={this.props.isFromSetting} />
                                </Grid>
                                }
                                </React.Fragment>
                            })
                        }
                        {this.props.isFromSetting && !this.props.enabledCognitoSSOAppsData?.find(item=> item.Driver === 'okta') && <Grid item lg={3} md={3} sm={4} xs={12} >
                            <OktaIntegrationCard root={this.props.root} isFromSetting={this.props.isFromSetting} />
                        </Grid>}
                        {this.props.isFromSetting && !this.props.enabledCognitoSSOAppsData?.find(item=> item.Driver === 'azure') && <Grid item lg={3} md={3} sm={4} xs={12} >
                            <AzureIntegrationCard root={this.props.root} isFromSetting={this.props.isFromSetting} />
                        </Grid>}
                        {!this.props.slackStateCodeProgress && !this.props.slackStateCode && (
                            <Grid item lg={3} md={3} sm={4} xs={12} >
                                <SlackIntegrationCard root={this.props.root} isFromSetting={this.props.isFromSetting} />
                            </Grid>
                        )}
                    </Grid>
                    <div className={classnames(classes.textAndSearch, classes.textAndSearchFromSetting)}>
                        <Typography variant={"h6"} className={classes.title}>Coming Soon</Typography>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item lg={3} md={3} sm={4} xs={12} >
                            <AppCard app={azureAd} config={azureAd.currentConfig} root={this.props.root} isFromSetting={this.props.isFromSetting} />
                        </Grid>
                    </Grid>
                </div>}

            {/* end: Setting page - integrations section */}
            <AppIntegrationFormDialog />
        </Paper>
    }
}

Apps.defaultProps = {
    isFromSetting: false,
    root: '',
};

export default connector(compose(
    withStyles(styles)
)(Apps));