import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Card, CardContent, Typography, IconButton, Paper, Tooltip } from '@material-ui/core';
import { CommonFn } from "services/commonFn";
import deleteIcon from "assets/images/delete.svg";
import Image from 'Components/Common/image.jsx';
import editIcon from "assets/images/edit_icon.svg";
import classnames from "classnames";

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    nameCol: {
        width: '30%'
    },
    statusCol: {
        width: '15%'
    },
    actionCol: {
        textAlign: 'center',
        width: '10%'
    },
    automationContent: {
        display: 'flex',
        alignItems: 'center',
    },
    active: {
        background: '#e0f5cf',
        color: '#44791e',
        borderRadius: 8,
        padding: '2px 10px',
        border: '1px solid #9ad86a',
        margin: 0,
        width: 'max-content',
        marginBottom: 10
    },
    inactive: {
        background: '#ced5db80',
        border: '1px solid #bac4cc',
        color: '#24434f',
        borderRadius: 8,
        padding: '2px 10px',
        margin: 0,
        width: 'max-content',
        marginBottom: 10
    },
    iconDiv: {
        color: '#fff',
        padding: '8px 15px',
        borderRadius: '50%',
        background: '#1f75d7cc',
    },
    createdDate: {
        color: '#637a87'
    },
    cardRoot: {
        marginBottom: 15,
        cursor: 'pointer'
    },
    cardContentRoot: {
        padding: '10px 20px'
    },
    detailCard: {
        flex: 2
    },
    statusCard: {
        flex: 2
    },
    actionCard: {
        flex: 1,
        textAlign: 'right'
    },
    default: {
        width: 'max-content',
        padding: '2px 10px',
        marginLeft: 20,
        background: '#e38715',
        color: '#fff',
        borderRadius: 4
    },
    paper: {
        marginTop: 20,
        padding: 10,
        position: "relative",
        border: "1px solid #f7f7f7"
    },
    rules: {
    },
    ruleTable: {
        width: "100%",
        "& [class*=MuiSelect-root]": {
            minHeight: 22,
        },
    },
    condition: {
        marginTop: 8,
        fontSize: 14,
        fontWeight: 500,
    },
    op2: {
        width: "10%",
        "& [class*=MuiInputBase-formControl]": {
            marginRight: 10,
            margin: "0 auto",
            width: "100%",
        },
    },
    op1: {
        width: "10%",
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 10,
        "& [class*=MuiInputBase-formControl]": {
            width: "100%",
            marginRight: 10,
            margin: "0 auto",
        },
    },
    leftOp: {
        width: "10%",
        paddingTop: 10,
    },
    rightOp: {
        width: "30%",
        paddingTop: 10,
        "& [class*=select__menu]": {
            maxHeight: 100,
        },
        "& [class*=react-datepicker-wrapper]": {
            width: "100%",
        },
    },
});

class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.avatarName = this.avatarName.bind(this);

    }

    componentDidMount() { }

    componentDidUpdate(prevProps) {

    }

    avatarName(name) {
        if (name && name !== '') {
            return name.substring(0, 1);
        } else {
            return 'A'
        }

    }

    render() {
        const { classes, approver, conditions, approvers } = this.props;
        return (
            <Card className={classes.cardRoot} variant="outlined" key={this.props.key}>
                <CardContent className={classes.cardContentRoot}>
                    <div className={classes.automationContent}>
                        <div className={classes.detailCard}>
                            <Typography> <span className={classes.iconDiv}>
                                {this.avatarName(approver?.Name)}
                            </span> &nbsp; {approver?.Name}
                                {conditions === null && <span className={classes.default}>Default</span>}
                            </Typography>

                        </div>
                        <div className={classes.statusCard}>
                            <Typography className={classes.createdDate}>Created on {CommonFn.formateDateTime(approver?.CreatedAt, true)}</Typography>
                        </div>

                        <div className={classes.actionCard}>
                            <IconButton ref={this.btnRef}
                                className={classes.threeDot}
                                onClick={(e) => this.props.handleEdit()}>
                                <Image
                                    src={editIcon}
                                />
                            </IconButton>
                            {(conditions !== null || (conditions === null && approvers?.length === 1)) && <IconButton ref={this.btnRef}
                                className={classes.threeDot}
                                onClick={(e) => this.props.handleDelete()}>
                                <Image
                                    src={deleteIcon}
                                />
                            </IconButton>}

                        </div>
                    </div>
                    {conditions && <Paper className={classes.paper}>
                        <Typography style={{ fontWeight: 600 }}>Conditions</Typography>
                        <div className={classes.ruleBody}>
                            {conditions.length > 0 && (
                                <div className={classes.rules}>
                                    <table className={classes.ruleTable}>
                                        <tbody>
                                            {conditions.map((rule, k) => {
                                                return (
                                                    <React.Fragment key={k}>
                                                        <tr className={rule.error === null ? classes.inputSection : classes.inputSection2}>
                                                            <td className={classnames(classes.inputController, classes.leftOp, rule.error !== null ? classes.noPaddingTop : "")}>
                                                                {rule.Field ? rule.Field : ''}
                                                            </td>
                                                            <td className={classnames(classes.op1, rule.error !== null ? classes.noPaddingTop : "")}>
                                                                {rule.Operation ? rule.Operation : ''}
                                                            </td>
                                                            <td className={classnames(classes.ruleRightElement, classes.rightOp, rule.error !== null ? classes.noPaddingTop : "")}>
                                                                {rule.Value && rule.Value !== '' && <span>{rule.Value.map(o => o.value).join()}</span>}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </Paper>}
                </CardContent>
            </Card>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Details));
