import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles, createStyles } from "@material-ui/core/styles";
import AppBarV2 from "Components/Application/AppBarV2";
import {
  Grid,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import SearchInput from "Components/Common/Input/SearchInput";
// redux
import {
  productSearch,
  fetchProductReaction,
  fetchPersonalizedRecommendationsProducts,
  fetchWishListedProducts,
} from "redux/product/action";
import { updateDrawerStyle } from "redux/drawer/action";
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import {
  allproductSearch,
  fetchOrgProductsSimilarExisting,
} from "redux/product/orgProduct/action";
import {
  createEvaluationCart,
  removeProductFromCart,
  fetchEvaluationCart,
} from "redux/evaluation/cart/action";
import CreateClusterGroupProductDialog from "Components/Dialogs/CreateClusterGroupProductDialog";
import { showSnackBar } from "redux/snackbar/action";
import {
  fetchClusterGroups,
  fetchDeleteProductCluster,
  showClusterProductsDialog,
  showCreateClusterGroupDialog,
  storeClusterGroupProducts,
  hideClusterProductsDialog,
} from "redux/product/cluster/action";

import { fetchAllCategories } from "redux/product/category/action";
// Dilogs
import CommonCss from "commonCss";
import DefaultImage from "assets/images/evaluation/default.svg";
import ExistingProductImage from "assets/images/evaluation/existing_product.svg";

import ProductTypeFilter from "Components/Common/Filters/ProductTypeFilter";

const connectedProps = (state) => ({
  productSearchProg: state.products.productSearchProg,
  productSearchError: state.products.productSearchError,
  evaluationCart: state.evaluationCart.evaluation,
  productFilters: state.products.productFilters,
  deleteProductStateProg: state.products.deleteProductStateProg,
  addProductStateProg: state.products.addProductStateProg,
  personalizedRecommendationsProducts: state.products.personalizedRecommendationsProducts,
  fetchAllCategoryProgress: state.productCategory.fetchAllCategoryProgress,
  allCategories: state.productCategory.allCategories,
  fetchAllCategoryError: state.productCategory.fetchAllCategoryError,
  fetchOrgProductsSimilarExistingProgress: state.orgProduct.fetchOrgProductsSimilarExistingProgress,
  orgProductsSimilarExisting: state.orgProduct.orgProductsSimilarExisting,
  productsRec: state.products,
  fetchProductReactionByTypeProgress: state.products.fetchProductReactionByTypeProgress,
  _createClusterGroupDialog: state.clusterProductReducer._createClusterGroupDialog,
  _clusterProductsDialog: state.clusterProductReducer._clusterProductsDialog,
  userType: state.authUser.user?.Role,
  orgUser: state.orgUser,
  clusterProducts: state.clusterProductReducer.storeClusterGroupProducts,
  orgName: state.authUser.user?.Organization?.Name,
});

const connectionActions = {
  hideDialog: hideClusterProductsDialog,
  productSearch: productSearch,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  fetchProductReaction: fetchProductReaction,
  updateDrawerStyle: updateDrawerStyle,
  fetchEvaluationCart: fetchEvaluationCart,
  fetchAllCategories: fetchAllCategories,
  fetchPersonalizedRecommendationsProducts:
    fetchPersonalizedRecommendationsProducts,
  allproductSearch: allproductSearch,
  fetchOrgProductsSimilarExisting: fetchOrgProductsSimilarExisting,
  fetchWishListedProducts: fetchWishListedProducts,
  showCreateClusterGroupDialog: showCreateClusterGroupDialog,
  storeClusterGroupProducts: storeClusterGroupProducts,
  showClusterProductsDialog: showClusterProductsDialog,
  fetchDeleteProductCluster: fetchDeleteProductCluster,
  fetchClusterGroups: fetchClusterGroups,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 2),
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
      textAlign: "center",
    },
    emptyText: {
      cursor: 'pointer',
      textAlign: "center",
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
      paddingLeft: 5,
    },
    productContainer: {
      maxHeight: "calc(100vh - 250px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 250px)",
      padding: theme.spacing(4, 0),
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    label: {
      fontSize: "1.4em",
      fontWeight: 600,
      lineHeight: "1.6",
      marginBottom: 10,
    },
    productMain: {
      flex: 1,
    },
    productHead: {
      ...CommonCss.productHead,
      fontSize: 18,
      margin: "0px !important",
    },
    productMenu: {
      background: "#FFFFFF",
      boxShadow: "0px 2px 10px #0000000B",
      padding: theme.spacing(4, 0, 4, 2.8),
      width: 270,
      minHeight: "100vh",
      position: "fixed",
    },
    menuHead: {
      display: "flex",
      marginBottom: 18,
    },
    container: {
      display: "flex",
    },
    recommendedText: {
      fontSize: 12,
      fontWeight: 500,
      color: "#2EB77D",
      textDecoration: "underline",
      marginTop: 10,
      width: "max-content",
    },
    topContainer: {
      marginTop: theme.spacing(3),
    },
    headSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterSection: {
      ...CommonCss.filterSection,
      marginRight: 20,
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
    },
    title: {
      fontSize: theme.spacing(2.4),
      flex: 1,
    },
    categoriesText: {
      color: "#000000",
      fontSize: 18,
      marginLeft: 16,
      fontWeight: 500,
    },
    categoriesList: {
      marginTop: theme.spacing(6.4),
      minHeight: 200,
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    main: {
      maxWidth: "calc(100% - 280px)",
      flexBasis: "calc(100% - 280px)",
    },
    subCategory: {
      height: "100vh",
      overflowY: "overlay",
      background: '#fff',
      padding: theme.spacing(2),
      maxWidth: 250,
      maxHeight: "100vh"
    },
    innerText: {
      color: "#696B6E",
      padding: theme.spacing(1, 1.5, 1, 1),
      cursor: "pointer",
      display: "block",
      margin: 0,
    },
    "@global": {
      ".applicationContent": {
        padding: "0 !important",
      },
    },
    products: {
      display: "flex",
    },
    activeSubCategory: {
      color: "#4A87F8",
      background: "#F2F7FF",
      fontSize: 13,
      marginRight: theme.spacing(3.2),
    },
    emptyMessage: {
      textAlign: "center",
      flex: 1,
      "& p": {
        margin: 5,
      },
    },
    suggestText: {
      fontWeight: 600,
    },
    filter: {
      // flex: 1,
      textAlign: "right",
    },
    txtCenter: {
      textAlign: "center",
      marginBottom: 16,
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
  });

class AppHardwareCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageSize: 1000,
      activeCategoryId: null,
      lg_01: window.matchMedia("(min-width: 1281px) and (max-width: 1850px)")
        .matches,
      lg_02: window.matchMedia("(min-width: 668px) and (max-width: 767.98px)")
        .matches,
      headerSectionWidth: null,
      selectedProduct: null,
      isProduct: false,
      queryParams: queryString.parse(this.props.location.search),
      filters: {
        sortby: "relevance",
        order: "",
      },
      isCreateGroup: false,
      selectedGroupProduct: [],
      selectedState: [],
      searchObj: {
        search_name: "app_catalogue",
        type: "all",
        page: 0,
        size: 100,
        text: "",
        filter_fields_value: {},
        sort_field: null,
        ascending: true,
      },
      showActionMenu: null,
      isSimilarExistingProduct: false,
      productType: 'HARDWARE',
      productSearchData: {
        "aggregations": {
          "features": [
            [
              "61",
              "Billing & Invoicing"
            ],
            [
              "105",
              "Compliance Management"
            ],
            [
              "502",
              "Risk Management"
            ],
            [
              "4966",
              "Online payment portal"
            ],
            [
              "64",
              "Document Management"
            ],
            [
              "5",
              "Reporting/Analytics"
            ],
            [
              "425",
              "Expense Management"
            ],
            [
              "234",
              "Inventory Management"
            ],
            [
              "576",
              "Electronic Signature"
            ],
            [
              "892",
              "Auditing"
            ],
            [
              "70",
              "Accounts Receivable"
            ],
            [
              "2604",
              "Discount Management"
            ],
            [
              "81",
              "Tax Management"
            ],
            [
              "520",
              "Barcode Scanning"
            ],
            [
              "1647",
              "Customer Account Profiles"
            ],
            [
              "555",
              "Multi-Currency"
            ],
            [
              "85",
              "Cash Management"
            ],
            [
              "342",
              "Audit Trail"
            ],
            [
              "556",
              "Online Invoicing"
            ],
            [
              "558",
              "Recurring/Subscription Billing"
            ],
            [
              "298",
              "Multi-Location"
            ],
            [
              "189",
              "Mobile Device Management"
            ],
            [
              "551",
              "Customizable Invoices"
            ],
            [
              "240",
              "Returns Management"
            ],
            [
              "71",
              "Bank Reconciliation"
            ]
          ],
          "platforms": [
            [
              "1",
              "Web"
            ],
            [
              "2",
              "Cloud"
            ],
            [
              "4",
              "Windows"
            ],
            [
              "5",
              "iPhone/iPad"
            ],
            [
              "3",
              "Android"
            ]
          ]
        },
        "org_products": [
          {
            "access_link": null,
            "company_name": "Apple",
            "description": "MacBook Pro delivers groundbreaking processing, graphics, and machine learning (ML) performance whether running on battery or plugged in, as well as amazing battery life - enabling workflows previously unimaginable on a notebook. It also features a stunning Liquid Retina XDR display, a wide range of ports for advanced connectivity, a 1080p FaceTime HD camera, and great audio system. Shattering the limits of what a notebook can do, MacBook Pro is designed for developers, photographers, filmmakers, 3D artists, scientists, music producers, and anyone who wants a powerful notebook.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6738975c?$product-detail$",
            "name": "Apple MacBook Pro",
            "product_id": "160",
            "score": 1,
            "sort_score": null,
            "states": {},
            "type": "1"
          },
          {
            "access_link": null,
            "company_name": "Acer",
            "description": "This workhorse has the security and speed demanded by the modern business person as they work and play throughout the day. Work on and share documents in comfort with the professional design features which make working a little easier.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6314278?$product-200x144$",
            "name": "Acer TravelMate Spin P4",
            "product_id": "35930",
            "score": 1,
            "sort_score": null,
            "states": {},
            "type": "1"
          },
          {
            "access_link": null,
            "company_name": "Boxx",
            "description": "Bring CAD design and motion media to completion faster with the BOXX GoBOXX SLM 17 Notebook Computer. This workstation laptop is perfect for a variety of professional applications. Driven by the Intel® Core™ i7-11800H processor and 32 GB of RAM, the GoBOXX SLM provides ample performance and reliability, empowering you to work from anywhere. A storage capacity of 1 TB accommodates the OS, data and software.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6801621a?$product-detail$",
            "name": "BOXX GoBOXX",
            "product_id": "160",
            "score": 1,
            "sort_score": null,
            "states": {},
            "type": "1"
          }
        ],
        "products": [
          {
            "company_name": "Dell",
            "description": "Dell Latitude 5420 |CORE_I5_11-1145_G7|8GB (1DIMMS)|256GB SS|TCH|1920x1080|WLS|W10|4C|14.0in.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6404350a?$product-detail$",
            "name": "Dell Latitude 5420 - 14",
            "product_id": "35930",
            "score": 2.135286,
            "sort_score": null,
            "states": {}
          },
          {
            "company_name": "HP",
            "description": "The sleek, thin, and light HP EliteBook x360 1040 transforms to the workstyle of your choice. A powerful processor, wireless connections, and strong security help keep you safely connected with coworkers and clients.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6688896d?$product-detail$",
            "name": "HP EliteBook",
            "product_id": "160",
            "score": 2.11662,
            "sort_score": null,
            "states": {}
          },
          {
            "company_name": "Lenovo",
            "description": "Premium processing on the Intel Evo platformStunning clarity and vibrant sound optimized with Dolby Vision Ease of use– features best-in-class connectivity& incredible battery lifeAI-optimized performance that adapts to your needsUltralight& portable, you can rely at home and on the road.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6738284?$product-detail$",
            "name": "Lenovo IdeaPad",
            "product_id": "35930",
            "score": 2.1035645,
            "sort_score": null,
            "states": {}
          },
          {
            "company_name": "Cherry",
            "description": "MFG.PART: G80-11900LUMEU-0 CDW PART: 973876 UNSPSC: 43211706",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/973876?$product-detail$",
            "name": "Cherry TouchBoard G80-11900",
            "product_id": "160",
            "score": 2.1035645,
            "sort_score": null,
            "states": {}
          },
          {
            "company_name": "Adesso",
            "description": "Adesso Tru-Form Media Contoured Ergonomic Keyboard with Hot Keys",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/947770?$product-detail$",
            "name": "Adesso Tru-Form",
            "product_id": "35930",
            "score": 2.1035645,
            "sort_score": null,
            "states": {}
          }
        ],
        'featured': [
          {
            "company_name": "Adesso",
            "description": "Adesso Tru-Form Media Contoured Ergonomic Keyboard with Hot Keys",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/947770?$product-detail$",
            "name": "Adesso Tru-Form",
            "product_id": "160",
            "score": 2.1035645,
            "sort_score": null,
            "states": {}
          },
          {
            "company_name": "Lenovo",
            "description": "Premium processing on the Intel Evo platformStunning clarity and vibrant sound optimized with Dolby Vision Ease of use– features best-in-class connectivity& incredible battery lifeAI-optimized performance that adapts to your needsUltralight& portable, you can rely at home and on the road.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6738284?$product-detail$",
            "name": "Lenovo IdeaPad",
            "product_id": "35930",
            "score": 2.1035645,
            "sort_score": null,
            "states": {}
          },
          {
            "access_link": null,
            "company_name": "Boxx",
            "description": "Bring CAD design and motion media to completion faster with the BOXX GoBOXX SLM 17 Notebook Computer. This workstation laptop is perfect for a variety of professional applications. Driven by the Intel® Core™ i7-11800H processor and 32 GB of RAM, the GoBOXX SLM provides ample performance and reliability, empowering you to work from anywhere. A storage capacity of 1 TB accommodates the OS, data and software.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6801621a?$product-detail$",
            "name": "BOXX GoBOXX",
            "product_id": "160",
            "score": 1,
            "sort_score": null,
            "states": {},
            "type": "1"
          },
          {
            "company_name": "HP",
            "description": "The sleek, thin, and light HP EliteBook x360 1040 transforms to the workstyle of your choice. A powerful processor, wireless connections, and strong security help keep you safely connected with coworkers and clients.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6688896d?$product-detail$",
            "name": "HP EliteBook",
            "product_id": "35930",
            "score": 2.11662,
            "sort_score": null,
            "states": {}
          },
          {
            "company_name": "Cherry",
            "description": "MFG.PART: G80-11900LUMEU-0 CDW PART: 973876 UNSPSC: 43211706",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/973876?$product-detail$",
            "name": "Cherry TouchBoard G80-11900",
            "product_id": "160",
            "score": 2.1035645,
            "sort_score": null,
            "states": {}
          },
        ],
        "total_products": 6935
      }
    };
  }

  componentDidMount() {
    this.props.fetchWishListedProducts();
    const urlPrams = new URLSearchParams(this.props.location.search);
    this.props.fetchAllCategories(0, 500, "");
    this.props.fetchPersonalizedRecommendationsProducts();
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);

    var evId = urlPrams.get("evaluationId");
    if (evId !== null) {
      setTimeout(() => {
        this.props.fetchEvaluationCart(evId);
      }, 1000);
    }
    if (urlPrams.get("s") !== null) {
      this.setState(
        {
          searchObj: { ...this.state.searchObj, text: urlPrams.get("s") },
        },
        () => this.fetchData()
      );
    } else {
      this.fetchData();
    }
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.addProductInClusterGroupInprogress &&
      prevProps.addProductInClusterGroupSuccess
    ) {
      this.props.showSnackBar("Group updated successfully", "success", 3000);
      this.props.clearStoreState({
        "clusterProductReducer.addProductInClusterGroupSuccess": null,
      });
      this.props.hideDialog();
    }
    this.updateDimensions();
  }

  getProductReactions(productIDs) {
    this.props.fetchProductReaction("all_product_blocking", {
      productIDs,
      source: "all_product_blocking",
      sourceData: "all_product_blocking",
    });
  }
  updateDimensions = () => {
    const headerSectionWidth = this.divElement.clientWidth;
    if (this.state.headerSectionWidth !== headerSectionWidth) {
      this.setState({ headerSectionWidth });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  fetchData() {
    const { searchObj, filters, selectedState, activeCategoryId, isProduct } =
      this.state;
    if (isProduct) {
      this.props.allproductSearch({
        search_name: "all_products",
        type: "all",
        page: 0,
        size: 10,
        text: searchObj.text,
        filter_fields_value: {},
        sort_field: null,
        ascending: null,
      });
      return true;
    } else {
      searchObj.filter_fields_value = {};
      if (selectedState && selectedState.length > 0) {
        searchObj.filter_fields_value["state"] = selectedState;
      }
      if (activeCategoryId !== null) {
        searchObj.filter_fields_value["category_ids"] = [
          parseInt(activeCategoryId),
        ];
      } else {
        if (searchObj.filter_fields_value["category_ids"]) {
          delete searchObj.filter_fields_value["category_ids"];
        }
      }
      searchObj.sort_field = null;
      if (filters && filters?.sortby && filters.sortby !== "relevance") {
        searchObj.sort_field = filters?.sortby;
      }
      if (filters && filters?.order && filters.order === "most") {
        searchObj.ascending = false;
      } else {
        searchObj.ascending = true;
      }
      this.props.productSearch(searchObj);
    }
  }

  productStates = (obj) => {
    if (obj) {
      const data = Object.keys(obj).map((key) => ({
        StateName: obj[key],
        StateID: parseInt(key),
      }));
      return data;
    } else {
      return [];
    }
  };

  handleOnClickState(newState) {
    let selectedState = this.state.selectedState;
    if (selectedState.includes(newState)) {
      selectedState = selectedState.filter((o) => o !== newState);
    } else {
      selectedState.push(newState);
    }
    this.setState(
      { selectedState, searchObj: { ...this.state.searchObj, page: 0 } },
      () => {
        this.fetchData();
      }
    );
  }

  addToCart(product_id) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(parseInt(product_id));
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(parseInt(product_id)) !== -1) {
        this.props.removeProductFromCart(
          this.props.evaluationCart.ID,
          parseInt(product_id)
        );
      } else {
        this.props.createEvaluationCart(
          parseInt(product_id),
          this.props.evaluationCart.ID
        );
      }
    }
  }

  reviewProduct = (id) => {
    let redirectPath = `/app/products/product-review/${id}/`;
    this.props.history.push(redirectPath);
  };

  setSearchType = (type) => {
    const searchObj = this.state.searchObj;
    if (type === "features") {
      searchObj.type = "features";
    } else if (type === "subcategories") {
      searchObj.type = "subcategories";
    }
    searchObj.page = 0;
    searchObj.text = "";
    this.setState({ searchObj }, () => {
      this.fetchData();
    });
  };

  handleActionClick = (event) => {
    this.setState({ showActionMenu: event.currentTarget });
  };

  handleActionClose = () => {
    this.setState({ showActionMenu: null });
  };

  handleCusterProduct = (status, product) => {
    let products = this.props.clusterProducts || [];
    if (status) {
      const data = {
        AccessLink: product.access_link,
        CompanyName: product.company_name,
        Description: product.description,
        ID: parseInt(product.product_id),
        IsExisting: true,
        Name: product.name,
        ProductGroups: [],
        ProductLogo: product.logo,
        ProductStates: [],
      };
      products.push(data);
    } else {
      products = products.filter((o) => o.ID !== parseInt(product.product_id));
    }
    this.props.storeClusterGroupProducts(products);
  };

  isSelectedGroupProduct = (id) => {
    const products = this.props.clusterProducts || [];
    return products.findIndex((obj) => obj.ID === id) > -1;
  };

  handleSimilarExistin = () => {
    this.setState({
      isProduct: true,
      isSimilarExistingProduct: true,
      searchObj: { ...this.state.searchObj, type: "products" },
    }, () => this.fetchData());
  };

  render() {
    const {
      classes,
      productSearchProg,
    } = this.props;

    const { productSearchData } = this.state;
    const { searchObj, isProduct } = this.state;
    let totalCount = 0;
    if (isProduct) {
      totalCount = 0;
    } else {
      totalCount += productSearchData?.org_products
        ? productSearchData?.org_products.length
        : 0;
      totalCount += productSearchData?.sorted_org_products
        ? productSearchData?.sorted_org_products.length
        : 0;
      totalCount += productSearchData?.featured
        ? productSearchData?.featured.length
        : 0;
    }
    let orgExistingProduct = [];
    let selectedOptions = [];

    return (
      <>
        <div className={classes.container}>
          <div className={classes.productMain}>
            <AppBarV2
              title={this.props.orgName ? this.props.orgName + "'s Application Catalog" : "Application Catalog"}
              withBack={true}
              dropdown={true}
            />
            <div className={classes.root}>
              <div className={classes.stickyBar}>
                <Grid
                  container
                  justifyContent="space-between"
                  ref={(divElement) => {
                    this.divElement = divElement;
                  }}
                  className={classes.productSectionNew}
                >
                  <Grid item>
                    {false && <p className={classes.productCount}>
                      {!isProduct && (
                        <>
                          {totalCount.toLocaleString("en-US")}
                          {totalCount > 1 ? " Products" : " Product"} found
                        </>
                      )}
                    </p>}
                  </Grid>
                  <Grid item>
                    {process.env.REACT_APP_ENV === 'dev' && <ProductTypeFilter
                      type={this.state.productType}
                      onChange={() => {
                        let redirectPath = `/app/products/org-products`;
                        this.props.history.push(redirectPath);
                      }}
                    />}
                    <SearchInput
                      page="app_catalog"
                      isSubCategory={false}
                      searchIcon={false}
                      isSelectInput={isProduct}
                      loadingOption={false}
                      dropdownOptions={selectedOptions}
                      onClickOption={(e) => {
                        this.setState(
                          {
                            selectedProduct: e.id,
                          },
                          () => {
                            this.props.fetchOrgProductsSimilarExisting(e.id);
                          }
                        );
                      }}
                      filters={this.state.filters}
                      selectedState={this.state.selectedState}
                      onChangeState={(newState) =>
                        this.handleOnClickState(newState)
                      }
                      showState={!isProduct}
                      onChangeSelectInput={(text) => {
                        this.setState(
                          {
                            searchObj: { ...searchObj, text, page: 0 },
                          },
                          () => {
                            this.fetchData();
                          }
                        );
                      }}
                      onChangeFilter={(filters) => {
                        this.setState(
                          {
                            filters,
                            searchObj: { ...searchObj, page: 0 },
                          },
                          () => {
                            this.fetchData();
                          }
                        );
                      }}
                      searchObj={this.state.searchObj}
                      onChange={(searchObj) => {
                        const isProduct = searchObj.type === "products";
                        const data = {
                          searchObj,
                          isProduct,
                        };
                        if (isProduct) {
                          data.page = 0;
                          data.query = "";
                          data.selectedProduct = null;
                        }
                        this.setState(data, () => {
                          this.fetchData();
                        });
                      }}
                    />

                  </Grid>
                  <Grid item>
                    {/* {adminFlag && (
                      <>
                        <ClickAwayListener
                          onClickAway={() =>
                            this.setState({ showActionMenu: null })
                          }
                        >
                          <Button
                            className={classes.newUserBtn}
                            onClick={(e) => this.handleActionClick(e)}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                          >
                            {this.state.isCreateGroup ? "Group" : "+ Add New"}
                          </Button>
                        </ClickAwayListener>
                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.showActionMenu}
                          keepMounted
                          open={Boolean(this.state.showActionMenu)}
                          onClose={() => {
                            this.setState({ showActionMenu: null });
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              if (this.state.isCreateGroup) {
                                const products =
                                  this.props.clusterProducts || [];
                                if (products.length === 0) {
                                  this.props.showSnackBar(
                                    "Please select atleast one product",
                                    "error",
                                    3000
                                  );
                                  return;
                                }
                                this.props.showCreateClusterGroupDialog(true);
                              } else {
                                this.setState({ showActionMenu: null });
                                this.props.showAddProductManuallyDialog();
                              }
                            }}
                            value={10}
                          >
                            {this.state.isCreateGroup
                              ? "Create Group"
                              : "Product"}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              this.setState({
                                showActionMenu: null,
                                isCreateGroup: !this.state.isCreateGroup,
                              });
                              this.props.storeClusterGroupProducts([]);
                            }}
                            value={20}
                          >
                            {this.state.isCreateGroup
                              ? "Cancel"
                              : "Products Group"}
                          </MenuItem>
                        </Menu>
                      </>
                    )} */}
                  </Grid>
                </Grid>
                {this.props.personalizedRecommendationsProducts !== null &&
                  this.props.personalizedRecommendationsProducts.length > 0 && (
                    <Grid container justify="center">
                      <Link to="/app/products/org-products/user-recommendation">
                        <Typography
                          align="center"
                          className={classes.recommendedText}
                        >
                          You have{" "}
                          {
                            this.props.personalizedRecommendationsProducts
                              .length
                          }{" "}
                          product recommendations.
                        </Typography>
                      </Link>
                    </Grid>
                  )}
              </div>
              <div className={classes.productContainer} id="productContainer">
                {!isProduct && (
                  <InfiniteScroll
                    scrollableTarget="productContainer"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                      marginRight: 20,
                      paddingLeft: 20,
                    }}
                    dataLength={productSearchData?.products?.length || 0}
                    next={() => {
                      this.setState(
                        {
                          searchObj: { ...searchObj, page: searchObj.page + 1 },
                        },
                        () => {
                          this.fetchData();
                        }
                      );
                    }}
                    hasMore={
                      false
                    }
                    endMessage={
                      <>
                        {!productSearchProg && (
                          <div>
                            {this.state.searchObj.text && (
                              <p className={classes.emptyText} onClick={() => this.handleSimilarExistin()}>
                                Similar Existing Products
                              </p>
                            )}
                            <p className={classes.emptyText}>
                              <span>
                                <span>
                                  Not finding what you are looking for? Search
                                  the{" "}
                                </span>
                                <span
                                  className={classes.addProductLink}
                                  onClick={() => this.setSearchType("features")}
                                >
                                  features
                                </span>
                                <span> and </span>
                                <span
                                  className={classes.addProductLink}
                                  onClick={() =>
                                    this.setSearchType("subcategories")
                                  }
                                >
                                  sub-categories.
                                </span>
                                <span> or in the </span>
                                <span
                                  className={classes.addProductLink}
                                  onClick={() => {
                                    this.props.history.push(
                                      `/app/products/all-products?s=${this.state.searchObj.text}`
                                    );
                                  }}
                                >
                                  Expent's product directory
                                </span>
                                <span> for {this.state.searchObj.text}.</span>
                              </span>
                            </p>
                          </div>
                        )}
                      </>
                    }
                  >
                    {productSearchData?.featured &&
                      productSearchData?.featured.length > 0 && (
                        <>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography className={classes.label}>
                              Featured Products
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            spacing={3}
                            style={{ marginBottom: 10 }}
                          >
                            {productSearchData.featured.map(
                              (product, index) => (
                                <Grid
                                  item
                                  lg={2}
                                  md={3}
                                  sm={4}
                                  xs={6}
                                  key={
                                    "product_" + parseInt(product.product_id)
                                  }
                                >
                                  <LayeredCard
                                    isSelectable={this.state.isCreateGroup}
                                    selected={this.isSelectedGroupProduct(
                                      parseInt(product.product_id)
                                    )}
                                    onSelect={(status, productID) =>
                                      this.handleCusterProduct(status, product)
                                    }
                                    isActionButtons
                                    title={
                                      product.name || product.company_name || ""
                                    }
                                    subTitle={
                                      product.company_name || product.name || ""
                                    }
                                    requestLink={product.access_link}
                                    description={product.description || ""}
                                    image={product.logo || ""}
                                    toogleCheck={(e) => {
                                      this.reviewProduct(product.product_id);
                                    }}
                                    onClick={() => { }}
                                    numLayer={0}
                                    IsExisting
                                    showCheckbox={true}
                                    id={parseInt(product.product_id)}
                                    uniqueId={"_featured_" + index}
                                    type={"product"}
                                    customRoute={`/app/products/org-hardware-product-details/`}
                                    productStates={this.productStates(
                                      product.states
                                    )}
                                    onClickState={(state) =>
                                      this.handleOnClickState(state)
                                    }
                                    isOrgProduct
                                    showReactionResponses
                                    typeOfBlock="all_product_blocking"
                                    typeOfFeatured="featured"
                                  />
                                </Grid>
                              )
                            )}
                          </Grid>
                        </>
                      )}

                    {((productSearchData?.org_products &&
                      productSearchData?.org_products.length > 0) ||
                      (productSearchData?.sorted_org_products &&
                        productSearchData?.sorted_org_products.length > 0)) && (
                        <>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography className={classes.label}>
                              Existing Products
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            spacing={3}
                            style={{ marginBottom: 20 }}
                          >
                            {productSearchData?.sorted_org_products &&
                              productSearchData.sorted_org_products.map(
                                (product, index) => (
                                  <Grid
                                    item
                                    lg={2}
                                    md={3}
                                    sm={4}
                                    xs={6}
                                    key={
                                      "product_" + parseInt(product.product_id)
                                    }
                                  >
                                    <LayeredCard
                                      isSelectable={this.state.isCreateGroup}
                                      selected={this.isSelectedGroupProduct(
                                        parseInt(product.product_id)
                                      )}
                                      onSelect={(status, productID) =>
                                        this.handleCusterProduct(status, product)
                                      }
                                      isActionButtons
                                      title={
                                        product.name || product.company_name || ""
                                      }
                                      subTitle={
                                        product.company_name || product.name || ""
                                      }
                                      requestLink={product.access_link}
                                      description={product.description || ""}
                                      image={product.logo || ""}
                                      toogleCheck={(e) => {
                                        this.reviewProduct(product.product_id);
                                      }}
                                      onClick={() => { }}
                                      numLayer={0}
                                      IsExisting
                                      showCheckbox={true}
                                      id={parseInt(product.product_id)}
                                      uniqueId={"_sorted_orgj_" + index}
                                      type={"product"}
                                      customRoute={`/app/products/org-hardware-product-details/`}

                                      productStates={this.productStates(
                                        product.states
                                      )}
                                      onClickState={(state) =>
                                        this.handleOnClickState(state)
                                      }
                                      typeOfBlock="all_product_blocking"
                                      isOrgProduct
                                      typeOfFeatured="featured"
                                      showReactionResponses
                                    />
                                  </Grid>
                                )
                              )}
                          </Grid>

                          <Grid container spacing={3}>
                            {productSearchData.org_products.map(
                              (product, index) => (
                                <Grid
                                  item
                                  lg={2}
                                  md={3}
                                  sm={4}
                                  xs={6}
                                  key={"product_" + parseInt(product.product_id)}
                                >
                                  <LayeredCard
                                    isSelectable={this.state.isCreateGroup}
                                    selected={this.isSelectedGroupProduct(
                                      parseInt(product.product_id)
                                    )}
                                    onSelect={(status, productID) =>
                                      this.handleCusterProduct(status, product)
                                    }
                                    isActionButtons
                                    title={
                                      product.name || product.company_name || ""
                                    }
                                    subTitle={
                                      product.company_name || product.name || ""
                                    }
                                    requestLink={product.access_link}
                                    description={product.description || ""}
                                    image={product.logo || ""}
                                    toogleCheck={(e) => {
                                      this.reviewProduct(product.product_id);
                                    }}
                                    onClick={() => { }}
                                    numLayer={0}
                                    IsExisting
                                    showCheckbox={true}
                                    id={parseInt(product.product_id)}
                                    uniqueId={"_orgj_" + index}
                                    type={"product"}
                                    customRoute={`/app/products/org-hardware-product-details/`}
                                    productStates={this.productStates(
                                      product.states
                                    )}
                                    onClickState={(state) =>
                                      this.handleOnClickState(state)
                                    }
                                    typeOfBlock="all_product_blocking"
                                    isOrgProduct
                                    typeOfFeatured="featured"
                                    showReactionResponses
                                  />
                                </Grid>
                              )
                            )}
                          </Grid>
                        </>
                      )}

                    {!this.state.isCreateGroup &&
                      productSearchData?.groups &&
                      productSearchData?.groups.length > 0 && (
                        <>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                              className={classes.label}
                              style={{ margin: "15px 0px 25px 0px" }}
                            >
                              Groups
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            spacing={3}
                            style={{ marginBottom: 10 }}
                          >
                            {productSearchData?.groups.map((product, index) => (
                              <Grid
                                item
                                lg={2}
                                md={3}
                                sm={4}
                                xs={6}
                                key={`${index}_groups_product`}
                              >
                                <LayeredCard
                                  title={product.name}
                                  image={ExistingProductImage}
                                  subTitle={product.name}
                                  color="BLUE"
                                  onClick={() => {
                                    this.props.history.push(
                                      `/app/products/cluster-products/${parseInt(
                                        product.group_id
                                      )}`
                                    );
                                  }}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </>
                      )}

                    {productSearchData?.products &&
                      productSearchData?.products.length > 0 && (
                        <>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography className={classes.label}>
                              Other Products
                            </Typography>
                          </Grid>
                          <Grid container spacing={3}>
                            {productSearchData.products.map(
                              (product, index) => (
                                <Grid
                                  item
                                  lg={2}
                                  md={3}
                                  sm={4}
                                  xs={6}
                                  key={
                                    "product_" + parseInt(product.product_id)
                                  }
                                >
                                  <LayeredCard
                                    isSelectable={this.state.isCreateGroup}
                                    selected={this.isSelectedGroupProduct(
                                      parseInt(product.product_id)
                                    )}
                                    onSelect={(status, productID) =>
                                      this.handleCusterProduct(status, product)
                                    }
                                    isActionButtons
                                    title={
                                      product.name || product.company_name || ""
                                    }
                                    subTitle={
                                      product.company_name || product.name || ""
                                    }
                                    requestLink={product.access_link}
                                    description={product.description || ""}
                                    image={product.logo || ""}
                                    toogleCheck={(e) => {
                                      this.addToCart(product.product_id);
                                    }}
                                    onClick={() => { }}
                                    numLayer={0}
                                    showCheckbox={true}
                                    id={parseInt(product.product_id)}
                                    uniqueId={"_product_" + index}
                                    type={"product"}
                                    customRoute={`/app/products/hardware-product-details/`}
                                    productStates={this.productStates(
                                      product.states
                                    )}
                                    onClickState={(state) =>
                                      this.handleOnClickState(state)
                                    }
                                    typeOfBlock="all_product_blocking"
                                    isOrgProduct
                                    typeOfFeatured="featured"
                                    showReactionResponses
                                  />
                                </Grid>
                              )
                            )}
                          </Grid>
                        </>
                      )}
                    
                  </InfiniteScroll>
                )}
                {isProduct && (
                  <InfiniteScroll
                    scrollableTarget="productContainer"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                      marginRight: 20,
                    }}
                    dataLength={0}
                    next={() => { }}
                    hasMore={false}
                    endMessage={<></>}
                  >
                    {this.state.selectedProduct !== null && (
                      <>
                        {orgExistingProduct && orgExistingProduct.length > 0 ? (
                          <Grid
                            container
                            spacing={3}
                            style={{ marginBottom: 20 }}
                          >
                            {orgExistingProduct.map((product, i) => (
                              <Grid
                                item
                                lg={2}
                                md={3}
                                sm={4}
                                xs={6}
                                key={`orgExistingProduct-${product.product_id}`}
                              >
                                <LayeredCard
                                  isActionButtons
                                  title={
                                    product.name || product.company_name || ""
                                  }
                                  subTitle={
                                    product.company_name || product.name || ""
                                  }
                                  requestLink={product.access_link}
                                  description={product.description || ""}
                                  image={product.logo || ""}
                                  toogleCheck={(e) => {
                                    this.reviewProduct(product.product_id);
                                  }}
                                  IsExisting
                                  onClick={() => { }}
                                  numLayer={0}
                                  showCheckbox={true}
                                  id={product.product_id}
                                  productStates={this.productStates(
                                    product.states
                                  )}
                                  uniqueId={product.product_id + "_o_" + i}
                                  customRoute={`/app/products/hardware-product-details/`}

                                  type={"product"}
                                  typeOfFeatured="featured"
                                  typeOfBlock="all_product_blocking"
                                  showReactionResponses
                                />
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <p className={classes.emptyText}>
                            <span>
                              <span>
                                The product that you chose is not in the org,
                                please search from the{" "}
                              </span>
                              <span
                                className={classes.addProductLink}
                                onClick={() => {
                                  this.props.history.push(
                                    `/app/products/all-products?s=${this.state.searchObj.text}`
                                  );
                                }}
                              >
                                Expent product directory.
                              </span>
                            </span>
                          </p>
                        )}
                        {!this.props.fetchOrgProductsSimilarExistingProgress &&
                          this.props.orgProductsSimilarExisting &&
                          this.props.orgProductsSimilarExisting.length > 0 && (
                            <>
                              <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography className={classes.label}>
                                  Similar Existing Products
                                </Typography>
                              </Grid>
                              <Grid container spacing={3}>
                                {/* Products list */}
                                {this.props.orgProductsSimilarExisting.map(
                                  (product, i) => (
                                    <Grid
                                      item
                                      lg={2}
                                      md={3}
                                      sm={4}
                                      xs={6}
                                      key={product.ID}
                                    >
                                      <LayeredCard
                                        isActionButtons
                                        title={product.Name}
                                        subTitle={product.CompanyName}
                                        description={product.Description}
                                        requestLink={product?.AccessLink}
                                        image={
                                          product.ProductLogo === null ||
                                            product.ProductLogo.length === 0
                                            ? DefaultImage
                                            : product.ProductLogo
                                        }
                                        toogleCheck={(e) => {
                                          this.reviewProduct(product.ID);
                                        }}
                                        IsExisting
                                        onClick={() => { }}
                                        numLayer={0}
                                        //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                                        showCheckbox={true}
                                        id={product.ID}
                                        productStates={product?.ProductStates}
                                        uniqueId={product.ID + "_o_" + i}
                                        customRoute={`/app/products/hardware-product-details/`}


                                        typeOfFeatured="featured"
                                        type={"product"}
                                        showReactionResponses
                                      />
                                    </Grid>
                                  )
                                )}
                              </Grid>
                              {this.props
                                .fetchOrgProductsSimilarExistingProgress && (
                                  <CircularProgress />
                                )}
                            </>
                          )}
                      </>
                    )}
                  </InfiniteScroll>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.props._createClusterGroupDialog && (
          <CreateClusterGroupProductDialog isNew={true} />
        )}
      </>
    );
  }
}

export default connector(
  compose(withStyles(styles), withLastLocation)(AppHardwareCatalog)
);
