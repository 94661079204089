import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  Button, FormControlLabel, TextField, Checkbox, CircularProgress
} from "@material-ui/core";
import CommonCss from 'commonCss';
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import classnames from "classnames";
import { isEnabledProjectTracking } from "util/Common";
import ReactSelect from "react-select";

const connectedProps = (state) => ({

});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    workflowConfigBtn: {
      border: "none",
      padding: 0,
      marginBottom: 15,
      color: "#6C6C6C",
      fontSize: 15,
    },
    dashboardSelect: {
      width: '100%'
    },
    popover: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        width: "300px",
      },
    },
    label: {
      fontWeight: 400,
      ...CommonCss.label,
      display: 'flex'
    },
    inputSection: {
      "& [class*='select__control']": {
        lineHeight: '30px'
      }
    }
  });


class GeneralConfigurationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configurations: {},
      imageUrl: "",
      isEdit: false,
      error: "",
      success: "",
      EnableVendorCatalogV2: "",
      EndUserHelpLink: "",
      HomePage: "",
      HomePageAdmin: "",
      isLoading: true,
      UserDepartmentField: '',
      userDepartmentFieldOptions: [],
      ManagerIdentifier: '',
      managerIdentifiesOptions: [
        { label: 'ID', value: 'id' },
        { label: 'Email', value: 'email' },
      ],
      ManagerIdentifierField: '',
      RiskScoreForm: '',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.initConfiguration();
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.user?.fetchAllOrgSettingsInprogress === true && this.props.user?.fetchAllOrgSettingsInprogress === false) ||
      (prevProps.user?.fetchAllOrgSettingsFailure === "cancelled" && this.props.user?.fetchAllOrgSettingsFailure === null)
    ) {
      this.initConfiguration();
    }
    if (this.props.user.addOrgSettingsInprogress === false && prevProps.user.addOrgSettingsInprogress === true) {
      if (this.props.user.addOrgSettingsFailure == null) {
        this.setState({ isEdit: false });
      }
    }
  }

  initConfiguration() {
    const EndUserHelpLink = this.getConfigValue(this.props?.user?.fetchAllOrgSettingsSuccess, "SettingName", "EndUserHelpLink");
    const EnableVendorCatalogV2 = isEnabledProjectTracking(this.props?.user?.fetchAllOrgSettingsSuccess, "EnableVendorCatalogV2");
    const HomePageValue = this.getConfigValue(this.props?.user?.fetchAllOrgSettingsSuccess, "SettingName", "HomePage") || "true";
    const HomePageAdminValue = this.getConfigValue(this.props?.user?.fetchAllOrgSettingsSuccess, "SettingName", "HomePageAdmin") || "1";

    const riskScoreForm = this.getConfigValue(this.props?.user?.fetchAllOrgSettingsSuccess, "SettingName", "RiskScoreForm") || '';
    const RiskScoreFormValue = riskScoreForm !== '' ? JSON.parse(riskScoreForm) : '';

    const userDepValue = this.getConfigValue(this.props?.user?.fetchAllOrgSettingsSuccess, "SettingName", "UserDepartmentField") || '';
    const UserDepartmentValue = userDepValue !== '' ? JSON.parse(userDepValue) : '';

    const managerIden = this.getConfigValue(this.props?.user?.fetchAllOrgSettingsSuccess, "SettingName", "ManagerIdentifier") || '';
    const ManagerIdentifierValue = managerIden !== '' ? JSON.parse(managerIden) : '';

    const managerIdenField = this.getConfigValue(this.props?.user?.fetchAllOrgSettingsSuccess, "SettingName", "ManagerIdentifierField") || '';
    const ManagerIdentifierFieldValue = managerIdenField !== '' ? JSON.parse(managerIdenField) : '';

    this.setState({
      EnableVendorCatalogV2: EnableVendorCatalogV2,
      EndUserHelpLink: EndUserHelpLink,
      HomePage: HomePageValue,
      HomePageAdmin: HomePageAdminValue,
      configurations: this.props.details,
      imageUrl: this.props.details?.Logo || this.imageUrl || "",
      isLoading: false,
      UserDepartmentField: UserDepartmentValue,
      ManagerIdentifier: ManagerIdentifierValue,
      ManagerIdentifierField: ManagerIdentifierFieldValue,
      RiskScoreForm: RiskScoreFormValue
    });
  }

  clearError() {
    this.setState({
      error: "",
      success: "",
    });
  }

  handleSubmit = () => {
    this.setState({
      error: "",
      success: "",
    });

    let org_settings = [
      {
        name: "EnableVendorCatalogV2",
        value: String(this.state.EnableVendorCatalogV2),
      },
      {
        name: "EndUserHelpLink",
        value: String(this.state.EndUserHelpLink),
      },
      {
        name: "HomePageAdmin",
        value: String(this.state.HomePageAdmin),
      },
      {
        name: "HomePage",
        value: String(this.state.HomePage),
      },
      {
        name: "UserDepartmentField",
        value: Object.keys(this.state.UserDepartmentField).length > 0 ? JSON.stringify(this.state.UserDepartmentField) : null,
      },
      {
        name: "ManagerIdentifier",
        value: Object.keys(this.state.ManagerIdentifier).length > 0 ? JSON.stringify(this.state.ManagerIdentifier) : null,
      },
      {
        name: "ManagerIdentifierField",
        value: Object.keys(this.state.ManagerIdentifierField).length > 0 ? JSON.stringify(this.state.ManagerIdentifierField) : null,
      },
      {
        name: "RiskScoreForm",
        value: JSON.stringify(this.state.RiskScoreForm)
      },
    ];

    this.props.addOrgSettings(org_settings);
  };

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  };

  render() {
    const classes = this.props.classes;

    if (this.state.isLoading) {
      return (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <form className={classes.productForm}>
        <div className={classes.btnSection}>
          {!this.state.isEdit && (
            <Button className={classes.newUserBtn} onClick={() => this.setState({ isEdit: !this.state.isEdit })}>
              Edit
            </Button>
          )}
        </div>
        <div className={classes.addProductSection}>
          <div className={classnames(classes.rightSection, this.state.isEdit ? "editField" : "readOnly")}>
            <div className={classes.alerts}>
              {this.state.error.length > 0 && (
                <Alert variant="filled" severity="error">
                  {this.state.error}
                </Alert>
              )}
              {this.state.success.length > 0 && (
                <Alert variant="filled" severity="success">
                  {this.state.success}
                </Alert>
              )}
            </div>


            <div className={classes.formGroup}>
              <label className={classes.label}>User help Link</label>
              <div className={classnames(classes.inputSection, "inputSection")}>
                <input
                  defaultValue={this.state.EndUserHelpLink}
                  className={classes.formControl}
                  placeholder="User help Link"
                  name="EndUserHelpLink"
                  onChange={(e) => {
                    this.setState({
                      EndUserHelpLink: e.target.value,
                    });
                  }}
                // ref={register()}
                />
              </div>
              <div className={classnames(classes.textSection, "textSection")}>
                {!this.props.user.fetchAllOrgSettingsInprogress && (
                  <>
                    {this.state.EndUserHelpLink ? (
                      <p
                        style={{
                          margin: 0,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <a href={this.state.EndUserHelpLink} target="_blank" rel="noreferrer">
                          {this.state.EndUserHelpLink}
                        </a>
                      </p>
                    ) : (
                      <span className={classes.defaultLabel}>Add User help link</span>
                    )}
                  </>
                )}
                {this.props.user.fetchAllOrgSettingsInprogress && <Skeleton className={classes.loader} variant="text" />}
              </div>
            </div>

            <div className={classes.formGroup}>
              <label className={classes.label}>Home Page for Admin</label>
              <div className={classnames(classes.inputSection, "inputSection")}>
                <Autocomplete
                  className={classes.selectField}
                  options={["2", "4"]}
                  getOptionLabel={(option) => {
                    if (option === "2") {
                      return "Catalog";
                    } else {
                      return "Dashboard";
                    }
                  }}
                  defaultValue={this.state.HomePageAdmin}
                  value={this.state.HomePageAdmin}
                  onChange={(e, value) => {
                    this.setState({ HomePageAdmin: value });
                  }}
                  renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                />

              </div>
              <div className={classnames(classes.textSection, "textSection")}>
                <p className={classes.defaultLabel}>{this.state.HomePageAdmin === "2" ? "Catalog" : "Dashboard"}</p>
              </div>
            </div>

            <div className={classes.formGroup} >
              <label className={classes.label}>Home Page for User</label>
              <div className={classnames(classes.inputSection, "inputSection")}>
                <Autocomplete
                  // {...register("HomePage")}
                  className={classes.selectField}
                  options={["false", "true"]}
                  getOptionLabel={(option) => {
                    if (option === "false") {
                      return "Dashboard";
                    } else {
                      return "Catalog";
                    }
                  }}
                  defaultValue={this.state.HomePage}
                  value={this.state.HomePage}
                  onChange={(e, value) => {
                    this.setState({ HomePage: value });
                  }}
                  renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                />
              </div>
              <div className={classnames(classes.textSection, "textSection")}>
                <p className={classes.defaultLabel}>{this.state.HomePage === "true" ? "Catalog" : "Dashboard"}</p>
              </div>
            </div>

            <div className={classes.formGroup}>
              <div className={classnames(classes.inputSection, classes.checkboxSection)} style={{ alignItems: "center" }}>
                <div className={classnames(classes.inputSection, "inputSection")}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                        defaultChecked={this.state.EnableVendorCatalogV2}
                        checked={this.state.EnableVendorCatalogV2}
                        onChange={(e) => {
                          this.setState({
                            EnableVendorCatalogV2: e.target.checked,
                          });
                        }}
                      />
                    }
                    name="EnableVendorCatalogV2"
                  // inputRef={register()}
                  />
                </div>
                <div className={classes.checkBoxLabel}>
                  <label className={classes.label} style={{ marginBottom: 0 }}>
                    Enable Vendor Catalog Version-2&nbsp;&nbsp;
                    {!this.state.isEdit && <>{this.state.EnableVendorCatalogV2 ? <CheckIcon /> : <ClearIcon />}</>}
                  </label>
                </div>
              </div>
            </div>


            {false && <>
              <div className={classes.formGroup}>
                <label className={classes.label}>User Department Field</label>
                <div className={classnames(classes.inputSection, "inputSection")}>
                  <div style={{ width: "100%" }}>
                    <ReactSelect
                      isClearable={false}
                      options={this.props.userProfileMeta ? this.props.userProfileMeta.map((itm) => {
                        return { label: itm, value: itm }
                      }) : []}
                      classNamePrefix="select"
                      placeholder={"Select"}
                      onChange={(e) => {
                        this.setState({ UserDepartmentField: e })
                      }}
                      value={this.state.UserDepartmentField}
                    />
                  </div>
                </div>
                <div className={classnames(classes.textSection, "textSection")}>
                  <p className={classes.defaultLabel}>{this.state.UserDepartmentField?.label}</p>
                </div>
              </div>

              <div className={classes.formGroup}>
                <label className={classes.label}>Manager Identifier</label>
                <div className={classnames(classes.inputSection, "inputSection")}>
                  <div style={{ width: "100%" }}>
                    <ReactSelect
                      isClearable={false}
                      options={this.state.managerIdentifiesOptions}
                      classNamePrefix="select"
                      placeholder={"Select"}
                      onChange={(e) => {
                        this.setState({ ManagerIdentifier: e, ManagerIdentifierField: '' })
                      }}
                      value={this.state.ManagerIdentifier}
                    />
                  </div>
                </div>
                <div className={classnames(classes.textSection, "textSection")}>
                  <p className={classes.defaultLabel}>{this.state.ManagerIdentifier?.label}</p>
                </div>
              </div>

              {this.state.ManagerIdentifier !== '' && this.state.ManagerIdentifier !== null && <div className={classes.formGroup}>
                <label className={classes.label}>{this.state.ManagerIdentifier.value === 'id' ? 'Manager Id Field' : 'Manager Email Field'}</label>
                <div className={classnames(classes.inputSection, "inputSection")}>
                  <div style={{ width: "100%" }}>
                    <ReactSelect
                      isClearable={false}
                      options={this.props.userProfileMeta ? this.props.userProfileMeta.map((itm) => {
                        return { label: itm, value: itm }
                      }) : []}
                      classNamePrefix="select"
                      placeholder={"Select"}
                      onChange={(e) => {
                        this.setState({ ManagerIdentifierField: e })
                      }}
                      value={this.state.ManagerIdentifierField}
                    />
                  </div>
                </div>
                <div className={classnames(classes.textSection, "textSection")}>
                  <p className={classes.defaultLabel}>{this.state.ManagerIdentifierField?.label}</p>
                </div>
              </div>}

              <div className={classes.formGroup}>
                <label className={classes.label}>Risk Score Form</label>
                <div className={classnames(classes.inputSection, "inputSection")}>
                  <div style={{ width: "100%" }}>
                    <ReactSelect
                      isClearable={true}
                      options={this.props.orgForms && this.props.orgForms.map((item) => ({ label: item.FormTitle, value: item.ID }))}
                      classNamePrefix="select"
                      placeholder={"Select Risk Score Form"}
                      onChange={(e) => {
                        this.setState({ RiskScoreForm: e }, () => {
                          this.clearError()
                        })
                      }}
                      value={this.state.RiskScoreForm}
                    />
                  </div>
                </div>
                <div className={classnames(classes.textSection, "textSection")}>
                  <p className={classes.defaultLabel}>{this.state.RiskScoreForm?.label}</p>
                </div>
              </div>
            </>}


          </div>
        </div >
        {this.state.isEdit && (
          <div className={classes.buttonSection}>
            <>
              <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleSubmit}>
                Update
              </Button>
              <Button
                variant="contained"
                style={{ background: "#fff" }}
                onClick={() => {
                  this.setState({
                    isEdit: false,
                    error: "",
                    success: "",
                  });
                  this.initConfiguration();
                }}
                className={classes.button}
                type="button"
              >
                Cancel
              </Button>
            </>
          </div>
        )}
      </form >
    );
  }
}


export default connector(compose(withRouter, withStyles(styles))(GeneralConfigurationForm));
