import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Typography, Popover, Grid, Button, IconButton, Divider, CircularProgress } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import classnames from "classnames"
import LinesEllipsis from 'react-lines-ellipsis'
import Image from 'Components/Common/image.jsx'
import { withRouter } from 'react-router-dom';
import {
    uninstallSlack, installSlack, deleteNotificationSettings, getSlackStateCode
} from "redux/authuser/action";
const connectedProps = (state) => ({
    user: state.authUser.user,
    unInstallSlackProgress: state.authUser.unInstallSlackProgress,
    unInstallSlackError: state.authUser.unInstallSlackError,
    installSlackProgress: state.authUser.installSlackProgress,
    installSlackError: state.authUser.installSlackError,
    installedData: state.authUser.installedData,
    slackStateCode: state.authUser.slackStateCode,
    deleteNotificationProgress: state.authUser.deleteNotificationProgress,
});

const connectionActions = {
    uninstallSlack: uninstallSlack,
    installSlack: installSlack,
    getSlackStateCode: getSlackStateCode,
    deleteNotificationSettings: deleteNotificationSettings,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    'root': {
        textAlign: "center",
        borderRadius: theme.spacing(0.55),
        border: "solid 1px #CFD7DF",
        padding: theme.spacing(3),
        position: "relative",

        "&.SettingsIntegrations": {
            background: "#FFFFFF",
            border: "1px solid #CFD7DF",
            borderRadius: "4px",

            "& $activeCheckmark": {
                display: "none"
            }
        }
    },
    "app_image": {
        height: "40px",
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1.9),
    },
    "action": {
        marginTop: theme.spacing(3),
        display: "flex",
        textAlign: "center",
        alignContent: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    "label": {
        color: "#2C5CC5",
        fontSize: theme.spacing(2)
    },
    "labelIcon": {
        color: "#2C5CC5",
        width: '20px',
        marginLeft: '4px',
        position: 'relative',
        top: '-1px'
    },
    "appDetails": {
        cursor: "pointer"
    },
    "active": {
        border: "solid 1px #2EB77D",
    },
    "checkMark": {
        position: "absolute",
        right: "10px",
        top: "10px",
        width: "20px",
        display: "none"
    },
    "activeCheckmark": {
        display: "block"
    },
    "progressMark": {
        position: "absolute",
        right: "10px",
        top: "10px",
        width: "20px",
    },

    "description": {
        color: "#475867",
        marginTop: theme.spacing(2),
        fontSize: theme.spacing(2.2),
        minHeight:65
    },

    "category": {
        color: "#aaacb4",
        fontSize: theme.spacing(2),
    },

    "appname": {
        fontSize: theme.spacing(2.4),
    },

    "popoverBtn": {
        width: "100%",
        padding: theme.spacing(0.5, 8, 0.5, 2),
        minHeight: "30px",
        lineHeight: "13px",

        "& > span": {
            textAlign: "left",
            display: "block"
        }
    },

    "threeDot": {
        position: "absolute",
        zIndex: 1,
        right: 0,
        top: 0
    },

    "popover": {
        "& [class*='paper']": {
            boxShadow: "0px 8px 12px #0000001D",
            border: "1px solid #EEEEEE",
            borderRadius: "5px",
            overflow: "inherit",

            "&::before": {
                top: "10px",
                left: "-16px",
                width: "0",
                borderTop: "8px solid transparent",
                height: "0px",
                content: "''",
                display: "block",
                position: "absolute",
                borderBottom: "8px solid transparent",
                borderLeft: "8px solid transparent",
                borderRight: "8px solid #fff"
            },
        },

    },

    "popoverText": {
        color: "#74798C"
    }
});

class SlackIntegrationCard extends React.Component {

    constructor(props) {
        super(props);
        this.btnRef = React.createRef();
        this.state = {
            anchorEl: null
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.unInstallSlackProgress && prevProps.unInstallSlackProgress) {
            if (this.props.unInstallSlackError === null) {
                this.props.deleteNotificationSettings()
            }
        }
        if (!this.props.installSlackProgress && prevProps.installSlackProgress) {
            if (this.props.installSlackError === null && this.props.installedData) {
                window.location.href = this.props.installedData
            }
        }
        if (!this.props.deleteNotificationProgress && prevProps.deleteNotificationProgress) {
            this.props.getSlackStateCode()
        }
    }

    handleClick(event) {
        event.stopPropagation()
        this.setState({ anchorEl: event.currentTarget || event.current });
    };

    handleClose(event) {
        event.stopPropagation()
        this.setState({ anchorEl: null });
    };

    render() {
        const classes = this.props.classes;
        if (this.props.user == null) {
            return null;
        }
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'popover' : undefined;

        return <div className={classnames(this.props.root, classes.root)} onClick={() => {
            if (!this.props.installSlackProgress && this.props.slackStateCode) {
                this.props.history.push("/app/settings/slack-notifications-intergration")
            }
        }}>
            <Popover
                className={classes.popover}
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}>
                <Button className={classes.popoverBtn} onClick={(event) => {
                    this.handleClose(event);
                    this.props.history.push('/app/settings/slack-notifications-intergration')
                }}> <p className={classes.popoverText} >Edit</p></Button>
                <Divider />
                <Button className={classes.popoverBtn} onClick={(event) => {
                    this.handleClose(event);
                    this.props.uninstallSlack()
                }}> <p className={classes.popoverText}>Uninstall</p></Button>
            </Popover>
            {this.props.slackStateCode && (
                <IconButton ref={this.btnRef} className={classes.threeDot} onClick={this.handleClick}>
                    {(this.props.unstallSlackProgress || this.props.deleteNotificationProgress) ? (
                        <CircularProgress />
                    ) : (
                        <MoreVertIcon />
                    )}
                </IconButton>
            )}
            <div className={classes.appDetails} >
                <Image src={"/images/applications/slack.png"} alt="app_name" className={classes.app_image} />
                <Typography variant={"h6"} className={classes.appname}>Slack</Typography>
                <Typography variant={"subtitle1"} className={classes.category}>Notifications</Typography>
                <div className={classes.description}>
                    <LinesEllipsis
                        text={"Integrate to receive notifications and access app catalog through Slack"}
                        maxLine='3'
                        ellipsis='...'
                        basedOn='letters'
                    />
                </div>
            </div>
            {!this.props.installSlackProgress && !this.props.slackStateCode &&
                <div className={classes.action}>
                    <Typography className={classes.label} onClick={(e) => {
                        e.stopPropagation()
                        this.props.installSlack()
                    }}>
                        Add to Expent
                    </Typography><ArrowForwardIcon className={classes.labelIcon} />
                </div>}
            {this.props.installSlackProgress && (
                <Grid container justify="center" spacing={1}>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
        </div>
    }
}

SlackIntegrationCard.defaultProps = {
    isFromSetting: false,
    root: '',
};

export default connector(compose(
    withStyles(styles),
    withRouter
)(SlackIntegrationCard));