import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import Apps from "../../../../Common/Apps/index";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import CommonCss from '../../../../../commonCss';
import AppBarV2 from 'Components/Application/AppBarV2';

const connectedProps = (state) => ({
    isProgress: state.applications.isProgress,
});

const connectionActions = {

}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    backdrop: CommonCss.backdrop,
    rootContainer: CommonCss.rootContainer
});

class Integrations extends React.Component {

    render() {
        const classes = this.props.classes;

        return <div>
            <AppBarV2 title="Integrations" withBack={true} />
            <div className={classes.rootContainer}>
                <Backdrop className={classes.backdrop} open={this.props.isProgress} transitionDuration={100}>
                    <CircularProgress />
                </Backdrop>
                <Apps isFromSetting={true} root={'SettingsIntegrations'}></Apps>
            </div>
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(Integrations));