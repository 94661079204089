import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const SHOW_EVALUATION_TEMPLATE_CHOOSE_DIALOG =
  "SHOW_EVALUATION_TEMPLATE_CHOOSE_DIALOG";
export const HIDE_EVALUATION_TEMPLATE_CHOOSE_DIALOG =
  "HIDE_EVALUATION_TEMPLATE_CHOOSE_DIALOG";

export const SELECT_EVALUATION_TEMPLATE_TYPE =
  "SELECT_EVALUATION_TEMPLATE_TYPE";
export const SELECT_EVALUATION_TEMPLATE = "SELECT_EVALUATION_TEMPLATE";

export const SELECT_EVALUATION_TEMPLATE_CRITERIA =
  "SELECT_EVALUATION_TEMPLATE_CRITERIA";
export const UNSELECT_EVALUATION_TEMPLATE_CRITERIA =
  "UNSELECT_EVALUATION_TEMPLATE_CRITERIA";
export const SHOW_ADD_TEMPLATE_DIALOG = "SHOW_ADD_TEMPLATE_DIALOG";
export const HIDE_ADD_TEMPLATE_DIALOG = "HIDE_ADD_TEMPLATE_DIALOG";

export const SELECT_ORG_TEMPLATE = "SELECT_ORG_TEMPLATE";
export const UNSELECT_ORG_TEMPLATE = "UNSELECT_ORG_TEMPLATE";

export const SELECT_MASTER_TEMPLATE = "SELECT_MASTER_TEMPLATE";
export const UNSELECT_MASTER_TEMPLATE = "UNSELECT_MASTER_TEMPLATE";

export const UNSELECT_ALL_TEMPLATE = "UNSELECT_ALL_TEMPLATE";

export const SHOW_EVALUATION_ASKSELLER_CHOOSE_DIALOG =
  "SHOW_EVALUATION_ASKSELLER_CHOOSE_DIALOG";
export const HIDE_EVALUATION_ASKSELLER_CHOOSE_DIALOG =
  "HIDE_EVALUATION_ASKSELLER_CHOOSE_DIALOG";

export const START_ASKSELLER_MULTISELECT_REQUIREMENT =
  "START_ASKSELLER_MULTISELECT_REQUIREMENT";
export const END_ASKSELLER_MULTISELECT_REQUIREMENT =
  "END_ASKSELLER_MULTISELECT_REQUIREMENT";

  export const START_ASKSELLER_SEND_REQUIREMENT =
  "START_ASKSELLER_SEND_REQUIREMENT";
export const END_ASKSELLER_SEND_REQUIREMENT =
  "END_ASKSELLER_SEND_REQUIREMENT";

  

export const ADD_CRITERIA_SELECTION = "ADD_CRITERIA_SELECTION";
export const START_ADD_TEMPLATE_WITH_CRITERIA_SELECT =
  "START_ADD_TEMPLATE_WITH_CRITERIA_SELECT";
export const END_ADD_TEMPLATE_WITH_CRITERIA_SELECT =
  "END_ADD_TEMPLATE_WITH_CRITERIA_SELECT";

export const START_ASK_SELLER_WITH_SELLER_RESPONSE = "START_ASK_SELLER_WITH_SELLER_RESPONSE"
export const END_ASK_SELLER_WITH_SELLER_RESPONSE = "END_ASK_SELLER_WITH_SELLER_RESPONSE"
export const START_RE_SEND_ASKSELLER_MULTISELECT_REQUIREMENT = "START_RE_SEND_ASKSELLER_MULTISELECT_REQUIREMENT"
export const END_RE_SEND_ASKSELLER_MULTISELECT_REQUIREMENT = "END_RE_SEND_ASKSELLER_MULTISELECT_REQUIREMENT"

export function addCriteriaSelection(isCriteria,criteriaID,requirementIds,checked,reset) {
  return {
    type: ADD_CRITERIA_SELECTION,
    payload: {
      isCriteria,
      criteriaID,
      requirementIds,
      checked,
      reset,
    },
  };
}
export function showEvaluationSelectDialog(evaluationId) {
  return {
    type: SHOW_EVALUATION_TEMPLATE_CHOOSE_DIALOG,
    payload: evaluationId,
  };
}

export function hideEvaluationSelectDialog() {
  return {
    type: HIDE_EVALUATION_TEMPLATE_CHOOSE_DIALOG,
    payload: {},
  };
}

export function selectEvaluationTemplateType(type) {
  return {
    type: SELECT_EVALUATION_TEMPLATE_TYPE,
    payload: type,
  };
}

export function selectEvaluationTemplateID(template_id) {
  return {
    type: SELECT_EVALUATION_TEMPLATE,
    payload: template_id,
  };
}

export function selectEvaluationTemplateCriteria(ctiteria_id) {
  return {
    type: SELECT_EVALUATION_TEMPLATE_CRITERIA,
    payload: ctiteria_id,
  };
}

export function unselectEvaluationTemplateCriteria(ctiteria_id) {
  return {
    type: UNSELECT_EVALUATION_TEMPLATE_CRITERIA,
    payload: ctiteria_id,
  };
}

export function selectOrgTemplate(template_id) {
  return {
    type: SELECT_ORG_TEMPLATE,
    payload: template_id,
  };
}

export function unselectOrgTemplate(template_id) {
  return {
    type: UNSELECT_ORG_TEMPLATE,
    payload: template_id,
  };
}

export function selectMasterTemplate(template_id) {
  return {
    type: SELECT_MASTER_TEMPLATE,
    payload: template_id,
  };
}

export function unselectMasterTemplate(template_id) {
  return {
    type: UNSELECT_MASTER_TEMPLATE,
    payload: template_id,
  };
}

export function unselectAllTemplate() {
  return {
    type: UNSELECT_ALL_TEMPLATE,
    payload: null,
  };
}

export function showAddTemplateDialog(payload = {}) {
  let defaultValue = {
    isRequiredTemplateSection: true,
    isRequiredCriteriaSection: true,
    isRequiredRequirementSection: true,
    isRequiredAddRequirement: true,
    saveButtonText: "Save",
    templateId: null,
    criteriaId: null,
    requirementId: null,
    criteria: "",
    requirement: {},
    ...payload,
  };

  console.log(defaultValue);
  return {
    type: SHOW_ADD_TEMPLATE_DIALOG,
    payload: defaultValue,
  };
}

export function hideAddTemplateDialog() {
  return {
    type: HIDE_ADD_TEMPLATE_DIALOG,
    payload: {},
  };
}

export function showEvaluationAsksellerSelectDialog(evaluationId, product) {
  return {
    type: SHOW_EVALUATION_ASKSELLER_CHOOSE_DIALOG,
    payload: { evaluationId, product },
  };
}

export function hideEvaluationAsksellerSelectDialog() {
  return {
    type: HIDE_EVALUATION_ASKSELLER_CHOOSE_DIALOG,
    payload: {},
  };
}

function startAskSellerMultiRequirement() {
  return {
    type: START_ASKSELLER_MULTISELECT_REQUIREMENT,
    payload: {},
  };
}

function endAskSellerMultiRequirement(success, error) {
  return {
    type: END_ASKSELLER_MULTISELECT_REQUIREMENT,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function askSellerMultiRequirement(uuid, data) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${uuid}/requirement/bulk-askseller?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAskSellerMultiRequirement());
    try {
      let [response, error] = await await HttpFactory.instance().postMethod(
        url,
        {
          request: data,
        }
      );
      dispatch(endAskSellerMultiRequirement(response, error));
    } catch (e) {
      dispatch(
        endAskSellerMultiRequirement(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startreSendAskSellerMultiRequirement() {
  return {
    type: START_RE_SEND_ASKSELLER_MULTISELECT_REQUIREMENT,
    payload: {},
  };
}

function endreSendAskSellerMultiRequirement(success, error) {
  return {
    type: END_RE_SEND_ASKSELLER_MULTISELECT_REQUIREMENT,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function reSendAskSellerMultiRequirement(uuid, data=[]) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${uuid}/askseller/resend?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startreSendAskSellerMultiRequirement());
    try {
      let [response, error] = await await HttpFactory.instance().postMethod(
        url,
        {
          request: data,
        }
      );
      dispatch(endreSendAskSellerMultiRequirement(response, error));
    } catch (e) {
      dispatch(
        endreSendAskSellerMultiRequirement(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}


function startAskSellerWithSellerResponse() {
  return {
    type: START_ASK_SELLER_WITH_SELLER_RESPONSE,
    payload: {},
  };
}

function endAskSellerWithSellerResponse(success, error) {
  return {
    type: END_ASK_SELLER_WITH_SELLER_RESPONSE,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the askSellerWithSellerResponse
export function askSellerWithSellerResponse(uuid, data) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${uuid}/requirement/askseller/with-response?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAskSellerWithSellerResponse());
    try {
      let [response, error] = await await HttpFactory.instance().postMethod(url, data);
      dispatch(endAskSellerWithSellerResponse(response, error));
    } catch (e) {
      dispatch(
        endAskSellerWithSellerResponse(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startAddTemplateWithCriteriaSelect() {
  return {
    type: START_ADD_TEMPLATE_WITH_CRITERIA_SELECT,
    payload: {},
  };
}

function endAddTemplateWithCriteriaSelect(success, error) {
  return {
    type: END_ADD_TEMPLATE_WITH_CRITERIA_SELECT,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function addTemplateWithCriteriaSelect(data) {
  const api_server = Environment.api_host("TEMPLATES");
  const timestamp = new Date().getTime();
  const url = `${api_server}/evaluation/org/template?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAddTemplateWithCriteriaSelect());
    try {
      let [response, error] = await await HttpFactory.instance().postMethod(
        url,
        data
      );
      dispatch(endAddTemplateWithCriteriaSelect(response, error));
    } catch (e) {
      dispatch(
        endAddTemplateWithCriteriaSelect(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}


function startAskSellerSendRequirments() {
  return {
    type: START_ASKSELLER_SEND_REQUIREMENT,
    payload: {},
  };
}

function endAskSellerSendRequirments(success, error) {
  return {
    type: END_ASKSELLER_SEND_REQUIREMENT,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the authorizedUser
export function askSellerSendRequirments(uuid, data) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/${uuid}/requirement/bulk-askseller?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAskSellerSendRequirments());
    try {
      let [response, error] = await await HttpFactory.instance().postMethod(
        url,
        {
          request: data,
        }
      );
      dispatch(endAskSellerSendRequirments(response, error));
    } catch (e) {
      dispatch(
        endAskSellerSendRequirments(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}