import { Dialog, DialogContent, Slide, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import classnames from "classnames";
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import * as Validator from "util/Validation";
import { hideAddVendorDialog } from "redux/vendor/vendorCatalog/action";
import { showEvaluationProductSelectionDialog } from "redux/evaluation/metadata/action";
import AddVendorDialog from './AddVendorDialog';
import ProductSelectionDialog from 'Components/Application/Components/Evaluate/Components/Evaluation/Common/ProductSelectionDialog';
import { isEnabledEvaluate } from "util/Common";
import AddProductManuallyDialog from "Components/Application/Components/Settings/AddProductManuallyDialog";
import { showAddProductManuallyDialog } from "redux/productsSettings/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  orgUser: state.orgUser,
  addProductManuallyDialogOpen: state.productsSettings.addProductManuallyDialogOpen,
});

const connectionActions = {
  hideAddVendorDialog,
  showEvaluationProductSelectionDialog,
  showAddProductManuallyDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important"
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(2.5),
    },
    uploadSection: {
        textAlign: 'center',
        border: '1px dotted #333',
        padding: theme.spacing(6, 0),
        "&.activeDrop": {
            border: '2px dotted #4a88ff',
        }
    },
    uploadSubText: {
        color: '#475867'
    },
    uploadAnchor: {
        color: '#0091FF !important',
        textDecoration: 'underline',
        opacity: 0,
        width: 0
    },
    btnSection: {
        marginTop: 20,
        textAlign: 'center',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center'
    },
    labelFile: {
        color: '#4a87f8',
        marginLeft: 5,
        border: '1px solid #4a87f8',
        padding: '5px 10px',
        borderRadius: 4,
        cursor: 'pointer'
    },
    arrow: {
        paddingLeft: 5
    },
    downloadTemplate: {
        textDecoration: 'underline',
        color: '#0091FF !important',
    },
    progress: {
        textAlign: 'center',
        paddingTop: 15
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    delete: {
        cursor: 'pointer',
        marginLeft:10
    }, 
    fileNameSection:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    card:{
        display:"flex"
    },
    cardItem:{
        flex:1
    }
});

class AddVendorOptionDialog extends React.Component {
    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.state = {
            error:"",
            success:"",
            openAddVendorDialog: false
        }
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    getConfigValue(array, key, value) {
      if (value) {
        let result = "";
        try {
          result = array.find((element) => element[key] === value)["Value"];
        } catch (e) { }
        return result.trim() || "";
      }
    }

    render() {
        const classes = this.props.classes;
        const { hideAddVendorDialog } = this.props;
        const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "DisableCatalogFeature");
        let isRequestType = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "REQUEST_TYPES");
        let requestTypesData = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "REQUEST_TYPES") || [];
        let requestTypes = [];
        if (
          isRequestType &&
          Validator.isJson(requestTypesData) &&
          requestTypesData !== undefined &&
          requestTypesData !== "NULL" &&
          requestTypesData !== null &&
          requestTypesData !== "null" &&
          requestTypesData.length > 0
        ) {
          requestTypes = JSON.parse(requestTypesData);
        }

        return (
          <Dialog
              onClose={hideAddVendorDialog}
              aria-labelledby="add-upload-dialog"
              open={true}
              TransitionComponent={Transition}
              disableBackdropClick={true}
              fullWidth={true}
              maxWidth={"md"}
              scroll={"body"}
          >
              <DialogContent classes={{ root: classes.dialogContent }}>
                  <div className={classes.close} onClick={() => hideAddVendorDialog()}><CloseIcon /></div>
                  <br/>
                  <div className={classes.card}>
                      <div className={classnames(classes.uploadSection,classes.cardItem)} onDrop={(event) => this.dropHandler(event)} onDragOver={(event) => this.dragOverHandler(event)} onDragLeave={() => this.dragLeaveHandler()}>
                          <Typography variant={"h6"} className={classes.title}>Onboard vendor</Typography>
                          <label className={classes.labelFile} onClick={()=>{
                              if(DisableVendor){
                                this.props.showAddProductManuallyDialog();
                              }else{
                                this.setState({
                                  openAddVendorDialog : true
                                })
                              }
                          }}>Onboard</label>
                      </div>
                      <div className={classnames(classes.uploadSection, this.state.activeOnDrop,classes.cardItem)} onDrop={(event) => this.dropHandler(event)} onDragOver={(event) => this.dragOverHandler(event)} onDragLeave={() => this.dragLeaveHandler()}>
                          <Typography variant={"h6"} className={classes.title}>Request new vendor</Typography>
                          <label className={classes.labelFile} onClick={()=>{
                              this.props.showEvaluationProductSelectionDialog();
                          }}>Request</label>
                      </div>
                  </div>
                  
              </DialogContent>
              
              {this.state.openAddVendorDialog && <AddVendorDialog
                  isOpen={this.state.openAddVendorDialog}
                  type={this.props.type}
                  hideDialog={()=>{
                    this.setState({
                      openAddVendorDialog: false
                    })
                  }}
              />}
              {this.state.addProductManuallyDialogOpen && <AddProductManuallyDialog/>}
              <ProductSelectionDialog isRequestType={isRequestType && requestTypes.length > 0 ? true : false} requestTypes={requestTypes} />
          </Dialog>
        )
    }
}
AddVendorOptionDialog.defaultProps = {
    isFromProduct: false
};

export default connector(compose(
    withStyles(styles)
)(AddVendorOptionDialog));