import React from 'react';
import {
    CircularProgress,
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
    TableContainer, Table, TableBody,TableCell, TableHead, TableRow, TablePagination,Button
  } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LoopIcon from '@material-ui/icons/Loop';
import { CommonFn } from "services/commonFn";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import { hideSyncHistoryDialog, fetchSyncHistory, SyncApplication } from "redux/applications/action";
import { showSnackBar } from "redux/snackbar/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen:state.applications.showSyncHistoryDialog,
    syncHistory: state.applications.syncHistory,
    fetchSyncHistoryProgress: state.applications.fetchSyncHistoryProgress,
    totalSync: state.applications.totalSync,
    appConfigSyncSuccess: state.applications.appConfigSyncSuccess,
    appConfigSyncProgress: state.applications.appConfigSyncProgress,
    appConfigSyncError: state.applications.appConfigSyncError,
});

const connectionActions = {
    hideDialog: hideSyncHistoryDialog,
    fetchSyncHistory: fetchSyncHistory,
    SyncApplication: SyncApplication,
    showSnackBar: showSnackBar,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    card: {
        border: "2px solid #EEEEEE",
        borderRadius: '10px',
        background: "#fff",
        paddingLeft: theme.spacing(2),
        cursor: "pointer",
        display: 'flex',
        placeContent: 'center',
        width: '100%',
        padding: '14px 16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 80,
    },
    cardText: {
        display: 'flex',
        flexDirection: 'column'
    },
    box: {
        marginTop: 25
    },
    actionButton: {
        textAlign: 'right',
        marginTop: 25
    },
    alert: {
        marginBottom: 10,
        marginTop: 25,
    }
});

const status = {
  NotStarted: "Not Started",
  InProgress: "InProgress",
  FailedWithError: "Failed with error",
  Completed: "Completed",
}

const requests = {
    SyncUser: "Sync Users",
    SyncGroup: "Sync Groups",
    SyncGroupUser: "Sync Group Users",
    SyncApplication: "Sync Applications",
    SyncApplicationUser: "Sync Application Users",
    SyncApplicationGroup: "Sync Application Groups",
    SyncApplicationLog: "Sync Application Log",
  }

class SyncHistoryDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 20,
            error: "",
        }

        this.handleClear = this.handleClear.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }
    componentDidUpdate(prevProps) {
        if(!this.props.appConfigSyncProgress && prevProps.appConfigSyncProgress){
            if(this.props.appConfigSyncError){
                this.props.showSnackBar("Failed to Sync", "error", 3000);
            }else{
                this.props.showSnackBar("Success", "success", 3000);
                this.props.hideDialog(); 
            }
        }
    }

    fetchData = () => {
        this.props.fetchSyncHistory(
          this.state.page,
          this.state.pageSize
        );
      };

    handleClear(event) {
        event.stopPropagation();
        var me = this;
        this.setState({
          page: 0,
        }, () => {
          me.fetchData();
        });
      }

    handleChangePage(e, newPage) {
        this.setState({
          page: newPage,
        }, () => {
          this.fetchData()
        })
      }
    
      handleChangeRowsPerPage(e) {
        this.setState({
          page: 0,
          pageSize: e.target.value
        }, () => {
          this.fetchData()
        })
      }

      rerunJob = (item) => {
        let data = {
            source: this.props.appName,
            request_type :item.RequestTypes,
            request_data: item.RequestData,
        }
        this.props.SyncApplication(data);
    }

    render() {
        const {classes, fetchSyncHistoryProgress,appConfigSyncProgress,syncHistory,totalSync } = this.props;
        const {page, pageSize } = this.state;
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"lg"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Sync History</Typography>
                <div className={classes.body}>
                    <div className={classes.box}>
                        <TableContainer style={{ maxHeight: '65vh' }}>
                            <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.statusCol}>Source</TableCell>
                                    <TableCell className={classes.statusCol}>API</TableCell>
                                    <TableCell className={classes.statusCol}>Requestor</TableCell>
                                    <TableCell className={classes.statusCol}>Status</TableCell>
                                    <TableCell className={classes.statusCol}>Message</TableCell>
                                    <TableCell className={classes.actionCol}>Started At</TableCell>
                                    <TableCell className={classes.actionCol}>Ended At</TableCell>
                                    <TableCell className={classes.actionCol} align='center'>Rerun</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!fetchSyncHistoryProgress && !appConfigSyncProgress && syncHistory.map((item) => {
                                return <TableRow key={'sync_' + item.ID}>
                                    <TableCell className={classes.statusCol}>{item.Source}</TableCell>
                                    <TableCell className={classes.statusCol}>{item?.RequestTypes?.split(",")?.map((request,id)=>{
                                        let applications  = [];
                                        let groups  = [];
                                        if(item.Applications?.length){
                                            applications = item.Applications.map(o=> o.Name);
                                        }
                                        if(item.Groups?.length){
                                            groups = item.Groups.map(o=> o.Name);
                                        }
                                        if(request === "SyncApplicationUser" || request === "SyncApplicationGroup"){
                                            return <div key={id} style={{marginBottom:5}}><div style={{fontWeight:500}}>{requests[request]} </div>
                                                {applications.join(', ')}                  
                                            </div>
                                        } else if(request === "SyncGroupUser"){
                                            return <div key={id} style={{marginBottom:5}}><div style={{fontWeight:500}}>{requests[request]} </div>
                                                {groups.join(', ')}                  
                                            </div>
                                        }else {
                                            return <div key={id} style={{marginBottom:5}}><div style={{fontWeight:500}}>{requests[request]}</div></div>
                                        }
                                        
                                    })}</TableCell>
                                    <TableCell className={classes.statusCol}>{item.Requestor?.Name || "System"}</TableCell>
                                    <TableCell className={classes.statusCol}>{status[item.Status]}</TableCell>
                                    <TableCell className={classes.statusCol}>{item.Message}</TableCell>
                                    <TableCell className={classes.statusCol}>{item?.StartedAt ? CommonFn.formateDateTime(item?.StartedAt, true) : "-"}</TableCell>
                                    <TableCell className={classes.statusCol}>{item?.EndedAt ? CommonFn.formateDateTime(item?.EndedAt, true) : "-"}</TableCell>
                                    <TableCell className={classes.statusCol}><Button onClick={()=> this.rerunJob(item)}><LoopIcon/></Button></TableCell>
                                </TableRow>
                                })}

                                {(!fetchSyncHistoryProgress && (syncHistory === undefined || syncHistory === null || syncHistory.length <= 0)) && <TableRow>
                                <TableCell colSpan={8} style={{ textAlign: 'center' }} className={classes.nameCol}>
                                    No Data
                                </TableCell>
                                </TableRow>}

                                {(fetchSyncHistoryProgress || appConfigSyncProgress) && <TableRow>
                                <TableCell colSpan={8} style={{ textAlign: 'center' }} className={classes.nameCol}>
                                    <CircularProgress />
                                </TableCell>
                                </TableRow>}

                            </TableBody>
                            </Table>
                        </TableContainer>
                        <hr/>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20, 50]}
                            component="div"
                            count={totalSync || 0}
                            rowsPerPage={pageSize}
                            page={page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(SyncHistoryDialog));