import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment"

export const SHOW_ADD_SURVEY_DIALOG = "SHOW_ADD_SURVEY_DIALOG";
export const HIDE_ADD_SURVEY_DIALOG = "HIDE_ADD_SURVEY_DIALOG";

export const START_ADD_NEW_SURVEY = "START_ADD_NEW_SURVEY";
export const END_ADD_NEW_SURVEY = "END_ADD_NEW_SURVEY";

export const START_UPDATE_NEW_SURVEY = "START_UPDATE_NEW_SURVEY";
export const END_UPDATE_NEW_SURVEY = "END_UPDATE_NEW_SURVEY";

export const START_FETCH_COMPLETED_SURVEY = "START_FETCH_COMPLETED_SURVEY";
export const END_FETCH_COMPLETED_SURVEY = "END_FETCH_COMPLETED_SURVEY";

export const START_FETCH_INPROGESS_SURVEY = "START_FETCH_INPROGESS_SURVEY";
export const END_FETCH_INPROGESS_SURVEY = "END_FETCH_INPROGESS_SURVEY";

export const START_DELETE_SURVEY = "START_DELETE_SURVEY";
export const END_DELETE_SURVEY = "END_DELETE_SURVEY";

export const START_FETCH_SURVEY = "START_FETCH_SURVEY";
export const END_FETCH_SURVEY = "END_FETCH_SURVEY";

export const SHOW_UPDATE_SURVEY_DIALOG = "SHOW_UPDATE_SURVEY_DIALOG";
export const HIDE_UPDATE_SURVEY_DIALOG = "HIDE_UPDATE_SURVEY_DIALOG";

export const START_FETCH_COLLOBORATOR_STATUS = "START_FETCH_COLLOBORATOR_STATUS";
export const END_FETCH_COLLOBORATOR_STATUS = "END_FETCH_COLLOBORATOR_STATUS";

export const SHOW_COLLABORATOR_DETAILS_DIALOG = "SHOW_COLLABORATOR_DETAILS_DIALOG";
export const HIDE_COLLABORATOR_DETAILS_DIALOG = "HIDE_COLLABORATOR_DETAILS_DIALOG";

export const START_FETCH_PRODUCT_SURVEYS = "START_FETCH_PRODUCT_SURVEYS";
export const END_FETCH_PRODUCT_SURVEYS = "END_FETCH_PRODUCT_SURVEYS";

export const START_FETCH_COMPLETED_PRODUCT_SURVEYS = "START_FETCH_COMPLETED_PRODUCT_SURVEYS";
export const END_FETCH_COMPLETED_PRODUCT_SURVEYS = "END_FETCH_COMPLETED_PRODUCT_SURVEYS";

export function showAddSurveyDialog(isNew,surveyData,productId=null) {
    return {
        type: SHOW_ADD_SURVEY_DIALOG,
        payload: {isNew,surveyData,productId}
    };
}

export function hideAddSurveyDialog() {
    return {
        type: HIDE_ADD_SURVEY_DIALOG,
        payload: {}
    };
}

export  function showUpdateSurveyDialog(surveyId,isEditPage=false){
    return     {
        type:SHOW_UPDATE_SURVEY_DIALOG,
        payload:{surveyId,isEditPage}
    };
}


export  function hideUpdateSurveyDialog(evaluationId){
    return     {
        type:HIDE_UPDATE_SURVEY_DIALOG,
        payload:{}
    };
}

export  function showCollaboratorDetailsDialog(userId,userName){
    return     {
        type:SHOW_COLLABORATOR_DETAILS_DIALOG,
        payload:{userId,userName}
    };
}


export  function hideCollaboratorDetailsDialog(){
    return     {
        type:HIDE_COLLABORATOR_DETAILS_DIALOG,
        payload:{}
    };
}


function startAddNewSurvey() {
    return {
        type: START_ADD_NEW_SURVEY,
        payload: {}
    };
}

function endAddNewSurvey(success, error) {
    return {
        type: END_ADD_NEW_SURVEY,
        payload: {
            success, error
        }
    };
}

export function addNewSurvey(data = {}) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/survey?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewSurvey());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewSurvey(response, error));
        } catch (e) {
            dispatch(endAddNewSurvey(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateSurvey() {
    return {
        type: START_UPDATE_NEW_SURVEY,
        payload: {}
    };
}

function endUpdateSurvey(success, error) {
    return {
        type: END_UPDATE_NEW_SURVEY,
        payload: {
            success, error
        }
    };
}

export function updateSurvey(id, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateSurvey());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateSurvey(response, error));
        } catch (e) {
            dispatch(endUpdateSurvey(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchSurveys(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_FETCH_SURVEY,
        payload:payload
    };
}

function endFetchSurveys(success,error){
    return {
        type:END_FETCH_SURVEY,
        payload:{
            success,error
        }
    };
}

export  function fetchSurveys(queryParam){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/fetch?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchSurveys());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endFetchSurveys(response,error));
        } catch(e){
            dispatch(endFetchSurveys(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startInProgessSurveyFetch(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_FETCH_INPROGESS_SURVEY,
        payload:payload
    };
}

function endInProgessSurveyFetch(success,error){
    return {
        type:END_FETCH_INPROGESS_SURVEY,
        payload:{
            success,error
        }
    };
}

export  function fetchInProgressSurvey1(queryParam){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/surveys?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startInProgessSurveyFetch());
        try{
            let [response,error]    =   await await HttpFactory.instance().getMethod(url,queryParam,false);
            dispatch(endInProgessSurveyFetch(response,error));
        } catch(e){
            dispatch(endInProgessSurveyFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

export  function fetchInProgressSurvey(queryParam){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/fetch?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startInProgessSurveyFetch());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endInProgessSurveyFetch(response,error));
        } catch(e){
            dispatch(endInProgessSurveyFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startCompletedSurveyFetch(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_FETCH_COMPLETED_SURVEY,
        payload:payload
    };
}

function endCompletedSurveyFetch(success,error){
    return {
        type:END_FETCH_COMPLETED_SURVEY,
        payload:{
            success,error
        }
    };
}

export  function fetchCompletedSurvey1(queryParam){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/surveys?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startCompletedSurveyFetch());
        try{
            let [response,error]    =   await await HttpFactory.instance().getMethod(url,queryParam,false);
            dispatch(endCompletedSurveyFetch(response,error));
        } catch(e){
            dispatch(endCompletedSurveyFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

export  function fetchCompletedSurvey(queryParam){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/fetch?t=${timestamp}`;
    
    return async (dispatch) => {
        dispatch(startCompletedSurveyFetch());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            // let [response,error]    =   await await HttpFactory.instance().getMethod(url,queryParam,false);
            dispatch(endCompletedSurveyFetch(response,error));
        } catch(e){
            dispatch(endCompletedSurveyFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startDeleteSurvey() {
    return {
        type: START_DELETE_SURVEY,
        payload: null
    };
}

function endDeleteSurvey(success, error) {
    return {
        type: END_DELETE_SURVEY,
        payload: {
            success, error
        }
    };
}

export function deleteSurvey(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/review/${id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteSurvey());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteSurvey(response, error));
        } catch (e) {
            dispatch(endDeleteSurvey(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchColloboratorStatus(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_FETCH_COLLOBORATOR_STATUS,
        payload:payload
    };
}

function endFetchColloboratorStatus(success,error){
    return {
        type:END_FETCH_COLLOBORATOR_STATUS,
        payload:{
            success,error
        }
    };
}

export  function fetchColloboratorStatus(id){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/survey/${id}/collaborators/status?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchColloboratorStatus());
        try{
            let [response,error]    =   await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchColloboratorStatus(response,error));
        } catch(e){
            dispatch(endFetchColloboratorStatus(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//  product/:id/surveys

function startProductSurveys(){
    return {
        type:START_FETCH_PRODUCT_SURVEYS,
        payload:{}
    };
}

function endProductSurveys(success,error){
    return {
        type:END_FETCH_PRODUCT_SURVEYS,
        payload:{
            success,error
        }
    };
}

export  function fetchProductSurveys(productId,params={}){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${productId}/surveys?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startProductSurveys());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,params,false);
            dispatch(endProductSurveys(response,error));
        } catch(e){
            dispatch(endProductSurveys(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startCompletedProductSurveys(){
    return {
        type:START_FETCH_COMPLETED_PRODUCT_SURVEYS,
        payload:{}
    };
}

function endCompletedProductSurveys(success,error){
    return {
        type:END_FETCH_COMPLETED_PRODUCT_SURVEYS,
        payload:{
            success,error
        }
    };
}

export  function fetchCompletedProductSurveys(productId,params={}){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${productId}/surveys?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startCompletedProductSurveys());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,params,false);
            dispatch(endCompletedProductSurveys(response,error));
        } catch(e){
            dispatch(endCompletedProductSurveys(null,{
                message:e.message
            }));
            return;
        }
    };
} 

