import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "../Style/index.css";
const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  triggerNode: {
    border: '1px solid #222'
  },
  endNode:{
    border:'1px solid #bac4cc',
    borderRadius:6,
    padding:7,
    width:50,
    margin:0
  }
});

class FinishNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMenu: false
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }


  render() {
    const { classes } = this.props;
    // console.log(this.props, 'Propssssssssssss')
    return (
      <>
        <div class="recipe-action-steps">
          <div class="">
            <div class="add-step ">
              <p className={classes.endNode}>End</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(FinishNode));

