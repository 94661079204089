import {
    SHOW_ADD_UPDATE_TASK_DIALOG, HIDE_ADD_UPDATE_TASK_DIALOG,
    START_ADD_NEW_CATALOG_TASK, END_ADD_NEW_CATALOG_TASK,
    START_UPDATE_CATALOG_TASK, END_UPDATE_CATALOG_TASK,
    START_FETCH_CATALOG_TASKS, END_FETCH_CATALOG_TASKS,
    START_DELETE_CATALOG_TASK, END_DELETE_CATALOG_TASK,
    START_FETCH_CATALOG_TASKS_ONLY, END_FETCH_CATALOG_TASKS_ONLY,
    START_FETCH_CATALOG_RISK_TASKS, END_FETCH_CATALOG_RISK_TASKS,
    START_FETCH_CATALOG_FOLLOW_UP_TASKS, END_FETCH_CATALOG_FOLLOW_UP_TASKS
} from "./action";

const initState = {
    addUpdateTaskData: null,
    showAddTaskDialog: false,
    isNewTask: true,

    addNewTaskProgress: false,
    addNewTaskError: null,
    newTaskData: null,

    updateNewTaskProgress: false,
    updateNewTaskError: null,

    fetchCatalogTasksProgress: false,
    fetchCatalogTasksError: null,
    catalogTasks: [],

    deleteCatalogTaskProgress: false,
    deleteCatalogTaskError: null,

    fetchCatalogOnlyTasksProgress: false,
    fetchCatalogOnlyTasksError: null,

    fetchCatalogRiskTasksProgress: false,
    fetchRiskTasksError: null,

    fetchCatalogFollowUpTasksProgress: false,
    fetchCatalogFollowupTasksError: null,

    catalogOnlyTasks: [],
    catalogRiskTasks: [],
    catalogFollowupTasks: [],

    updatedTaskData:null
}

export function catalogTaskReducer(state = initState, action) {
    switch (action.type) {

        case SHOW_ADD_UPDATE_TASK_DIALOG:
            return {
                ...state,
                addUpdateTaskData: action.payload.data,
                showAddTaskDialog: true,
                isNewTask: action.payload.isNew
            }

        case HIDE_ADD_UPDATE_TASK_DIALOG:
            return {
                ...state,
                addUpdateTaskData: null,
                showAddTaskDialog: false,
            }

        case START_ADD_NEW_CATALOG_TASK:
            return {
                ...state,
                addNewTaskProgress: true,
                addNewTaskError: null,
                newTaskData: null
            }

        case END_ADD_NEW_CATALOG_TASK:
            return {
                ...state,
                addNewTaskProgress: false,
                addNewTaskError: action.payload.error,
                newTaskData: action.payload.success
            }

        case START_UPDATE_CATALOG_TASK:
            return {
                ...state,
                updateNewTaskProgress: true,
                updateNewTaskError: null,
                updatedTaskData:null,
            }

        case END_UPDATE_CATALOG_TASK:
            return {
                ...state,
                updateNewTaskProgress: false,
                updateNewTaskError: action.payload.error,
                updatedTaskData: action.payload.success,
            }

        case START_FETCH_CATALOG_TASKS:
            return {
                ...state,
                fetchCatalogTasksProgress: true,
                fetchCatalogTasksError: null,
            }

        case END_FETCH_CATALOG_TASKS:
            return {
                ...state,
                fetchCatalogTasksProgress: false,
                fetchCatalogTasksError: action.payload.error,
                catalogTasks: action.payload.success
            }

        case START_DELETE_CATALOG_TASK:
            return {
                ...state,
                deleteCatalogTaskProgress: true,
                deleteCatalogTaskError: null,
            }

        case END_DELETE_CATALOG_TASK:
            return {
                ...state,
                deleteCatalogTaskProgress: false,
                deleteCatalogTaskError: action.payload.error,
            }

        case START_FETCH_CATALOG_TASKS_ONLY:
            return {
                ...state,
                fetchCatalogOnlyTasksProgress: true,
                fetchCatalogOnlyTasksError: null,
            }
        case END_FETCH_CATALOG_TASKS_ONLY:
            return {
                ...state,
                fetchCatalogOnlyTasksProgress: false,
                fetchCatalogOnlyTasksError: action.payload.error,
                catalogOnlyTasks: action.payload.success
            }

        case START_FETCH_CATALOG_RISK_TASKS:
            return {
                ...state,
                fetchCatalogRiskTasksProgress: true,
                fetchRiskTasksError: null,
            }
        case END_FETCH_CATALOG_RISK_TASKS:
            return {
                ...state,
                fetchCatalogRiskTasksProgress: false,
                fetchRiskTasksError: action.payload.error,
                catalogRiskTasks: action.payload.success
            }

        case START_FETCH_CATALOG_FOLLOW_UP_TASKS:
            return {
                ...state,
                fetchCatalogFollowUpTasksProgress: true,
                fetchCatalogFollowupTasksError: null,
            }
        case END_FETCH_CATALOG_FOLLOW_UP_TASKS:
            return {
                ...state,
                fetchCatalogFollowUpTasksProgress: false,
                fetchCatalogFollowupTasksError: action.payload.error,
                catalogFollowupTasks: action.payload.success
            }
        default:
            return state;
    }
}