import { Button, CircularProgress, Drawer, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
// icons
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// assets
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseImage from "assets/images/close.svg";
import DefaultImage from "assets/images/evaluation/default.svg";
import classnames from "classnames";
// components
import Image from "Components/Common/image.jsx";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as Validator from "util/Validation";
//redux
import { storeSearchString } from "redux/applications/action";
import { authorizedUser } from "redux/authuser/action";
import { updateCartDrawerStyle } from "redux/drawer/action";
import { removeProductFromCart } from "redux/evaluation/cart/action";
import { showPreFeedbackTakeDialog } from "redux/evaluation/feedback/action";
import { showEvaluationMetaDialog, updateEvaluationMetaData } from "redux/evaluation/metadata/action";
import { showSnackBar } from "redux/snackbar/action";
import PreFeedbackDialog from "./Components/Evaluate/Components/Evaluation/Common/PreFeedbackDialog";
import { fireEvent } from "redux/common/action";

const connectedProps = (state) => ({
  expanded: state.drawer.cartExpanded,
  show: state.drawer.cartShow,
  user: state.authUser.user,
  settings: state.orgUser.fetchAllOrgSettingsSuccess,
  evaluationCart: state.evaluationCart.evaluation,
  removeProductInProgress: state.evaluationCart.removeProductInProgress,
  draftEvaluationFetchProgress: state.evaluationCart.draftEvaluationFetchProgress,
  addFeedbackProgress: state.evaluationFeedback.addPreFeedbackProgress,
  updateProgress: state.evaluationMetaData.updateProgress,
  updateError: state.evaluationMetaData.errorUpdate,
  configurations: state.orgUser.fetchAllOrgSettingsSuccess,
});

const connectionActions = {
  storeSearchString: storeSearchString,
  authorizedUser: authorizedUser,
  updateCartDrawerStyle: updateCartDrawerStyle,
  removeProductFromCart: removeProductFromCart,
  showEvaluationMetaDialog: showEvaluationMetaDialog,
  showPreFeedbackTakeDialog: showPreFeedbackTakeDialog,
  showSnackBar: showSnackBar,
  updateDetails: updateEvaluationMetaData,
  fireEvent: fireEvent
};

var connector = connect(connectedProps, connectionActions);

const drawerWidthExpanded = 45;
const drawerWidthCompressed = 14;

const styles = (theme) =>
  createStyles({
    drawer: {
      width: theme.spacing(drawerWidthExpanded),
      flexShrink: 0,
      transition: "width 300ms",
      background: "#F7F7F7",
    },
    drawerPaper: {
      width: theme.spacing(drawerWidthExpanded),
      transition: "width 300ms",
      paddingTop: theme.spacing(5),
      background: "#ffffff",
    },
    drawerCompressed: {
      width: theme.spacing(drawerWidthCompressed),
      transition: "width 300ms",
      flexShrink: 0,
    },
    drawerPaperCompressed: {
      width: theme.spacing(drawerWidthCompressed),
      paddingTop: theme.spacing(5),
      background: "#ffffff",
      transition: "width 300ms",
    },
    cartContainer: {
      padding: '21px',
      '&.close': {
        opacity: 0
      }
    },
    title: {
      fontSize: theme.spacing(2.4),
      textAlign: "center",
      marginTop: theme.spacing(6),
    },
    subTitle: {
      fontSize: theme.spacing(1.8),
      textAlign: "center",
      marginTop: theme.spacing(2),
      opacity: 0.5,
      display: "block",
    },
    button: {
      // width: '205px',
      // margin: '0px auto'
    },
    cartItems: {
      margin: theme.spacing(4, 0),
    },
    cartItem: {
      display: "flex",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "2px solid #EEEEEE",
      borderRadius: theme.spacing(1.2),
      margin: theme.spacing(2, 0),
    },
    productName: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
    },
    productImage: {
      width: theme.spacing(5),
    },
    productImageContainer: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1),
    },
    productRemove: {
      width: theme.spacing(3),
      marginTop: theme.spacing(1),
      cursor: "pointer",
    },
    productRemoveContainer: {
      padding: theme.spacing(1),
    },
    counter: {
      padding: theme.spacing(1),
      fontSize: theme.spacing(2.2),
      opacity: 0.5,
      marginTop: theme.spacing(1),
    },
    productNameTitle: {
      fontSize: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    progressIndicator: {
      margin: theme.spacing(2),
      textAlign: "center",
    },
    btnToggle: {
      position: 'fixed',
      width: '30px',
      height: '30px',
      background: '#ffffff',
      // display: 'inline-block',
      border: '2px solid #202639',
      zIndex: 99999,
      borderRadius: '50%',
      flexDirection: 'row',
      boxSizing: 'border-box',
      display: 'flex',
      placeContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      transition: '300ms',
      '&.max': {
        right: '300px',
      },
      '&.min': {
        right: '83px',
        '& svg': {
          marginLeft: '3px'
        }
      },
      '& svg': {
        width: '17px',
        height: '17px',
        marginLeft: '7px'
      }
    },
    minCartContainer: {
      marginTop: '100px'
    },
    minCartItem: {
      display: "flex",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "2px solid #EEEEEE",
      borderRadius: theme.spacing(1.2),
      width: '50px',
      height: '50px',
      margin: '0px auto',
      marginBottom: '20px',
      position: 'relative',
      '&:hover': {
        '& span.removeItem': {
          opacity: 1
        }
      },
      '& img': {
        width: '100%',
        height: 'auto',
        objectFit: 'contain'
      },
      '& span.removeItem': {
        display: 'inline-block',
        background: '#bdbdbd',
        color: '#ffffff',
        width: '20px',
        height: '20px',
        position: 'absolute',
        borderRadius: '50%',
        right: '-11px',
        top: '-11px',
        opacity: 0,
        cursor: 'pointer'
      }
    },
    removeProduct: {
      display: 'none'
    },
    cartIcon: {
      margin: '0px auto',
      color: '#4a87ff',
      width: '35px',
      height: '35px'
    }
  });


class CartDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWorkflowEvaluation: false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateProgress === false && prevProps.updateProgress === true) {
      if (this.props.updateError === null && this.state.isWorkflowEvaluation === true) {
        this.props.history.push(`/app/evaluate/${this.props.evaluationCart.ID}/workflow/interim`);
      }
    }
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  updateDetails = (evaluationId) => {
    console.log(this.props.evaluationCart.Products);
    if (this.state.isWorkflowEvaluation === false) {
      return;
    }
    var name = 'Request for ';
    this.props.evaluationCart.Products.forEach(function (product, k) {
      if (k > 0) {
        name += ' & ';
      }
      name += product.Name;
    })

    this.props.updateDetails(evaluationId, {
      name: name,
      status: 2
    })
  }


  fireEvent = (evaluationId, source, productIds) => {
    this.props.fireEvent({
      type: "event.evaluation.start",
      data: {
        url: this.props.location.pathname,
        evaluationId,
        source,
        productIds
      },
    })
  }

  setupWorkflow(){
    this.props.history.push('/app/settings/form-builder');
    // this.props.history.push('/app/evaluate/${this.props.evaluationCart.ID}/workflow/interim`);
  }

  startWorkflowEvaluation(items) {
    this.fireEvent(this.props.evaluationCart.ID, 'workflow_evaluation', items.map(item => item.id))
    this.setState({
      isWorkflowEvaluation: true
    }, () => {
      this.updateDetails(this.props.evaluationCart.ID);
    })
    // if (this.props.configurations !== undefined && this.props.configurations !== null) {
    //   const defaultObjRenew = this.props.configurations.find((item) => item.SettingName === "ORG_DEFAULT_EVALUATION_WORKFLOW");

    //   var defaultWorkflowId = null;
    //   if ((defaultObjRenew && defaultObjRenew?.Value && defaultObjRenew?.Value !== 'NULL')) {
    //     defaultWorkflowId = JSON.parse(defaultObjRenew?.Value).wid
    //   }

    //   if (defaultWorkflowId === null) {
    //     this.props.showSnackBar("Set default evaluation workflow before proceed.", "error", 3000)
    //     return;
    //   }
    //   this.fireEvent(this.props.evaluationCart.ID, 'workflow_evaluation', items.map(item => item.id))
    //   this.setState({
    //     isWorkflowEvaluation: true
    //   }, () => {
    //     this.updateDetails(this.props.evaluationCart.ID);
    //   })
    // } else {
    //   this.props.showSnackBar("Set default evaluation workflow before proceed.", "error", 3000)
    // }
  }

  render() {
    const classes = this.props.classes;
    const empty = [];
    var items = (this.props.evaluationCart === null || this.props.evaluationCart === undefined) ? empty : this.props.evaluationCart.Products;
    if (items === undefined) {
      items = [];
    }
    let role = this.props.user?.Role;
    let nonExistingProductCount;
    if (items !== null && items !== undefined) {
      nonExistingProductCount = items.filter((a) => {
        return !a.IsExisting
      }).length;
    }
    let org_default_workflow = false
    if (this.props.settings) {
      org_default_workflow = this.props.settings.find(o => o.SettingName === 'ORG_DEFAULT_EVALUATION_WORKFLOW')
    }


    var isShowProceedBtn = true;
    if (this.props.configurations !== undefined && this.props.configurations !== null) {
      const defaultObjRenew = this.props.configurations.find((item) => item.SettingName === "ORG_DEFAULT_EVALUATION_WORKFLOW");
      var defaultWorkflowId = null;
      if ((defaultObjRenew && defaultObjRenew?.Value && defaultObjRenew?.Value !== 'NULL')) {
        if(Validator.isJson(defaultObjRenew?.Value)){
          defaultWorkflowId = JSON.parse(defaultObjRenew?.Value).wid
        }
      }
      if (defaultWorkflowId === null) {
        isShowProceedBtn = false;
      }
    }

    return (
      <>
        <Drawer
          className={
            classnames(classes.appDrawer, this.props.expanded ? classes.drawer : classes.drawerCompressed)
          }
          variant="permanent"
          classes={{
            paper: this.props.expanded
              ? classes.drawerPaper
              : classes.drawerPaperCompressed,
          }}
          anchor="right"
        >
          <span
            className={classnames(classes.btnToggle, this.props.expanded ? 'max' : 'min')}
            onClick={() => { this.props.updateCartDrawerStyle(!this.props.expanded, true) }}>
            {/* <Tooltip title={this.props.expanded ? 'Min' : 'Max'} placement="right-start">
               
            </Tooltip> */}
            {
              this.props.expanded ? <ArrowForwardIosIcon fontSize="small" /> : <ArrowBackIosIcon fontSize="small" />
            }
          </span>
          {!this.props.draftEvaluationFetchProgress &&
            !this.props.removeProductInProgress && this.props.expanded === false && (
              <>
                <AddShoppingCartIcon className={classes.cartIcon} />
                <div className={classes.minCartContainer}>
                  {items.map((item, k) => {
                    return (
                      <div key={k}>
                        <Tooltip title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{item.Name}</span>} placement="right-start" classes={{ tooltip: classes.noMaxWidth }}>
                          <div className={classes.minCartItem}>
                            <span className={"removeItem"}
                              onClick={() => {
                                this.props.removeProductFromCart(
                                  this.props.evaluationCart.ID,
                                  item.EntityObjectID
                                );
                              }} >
                              <RemoveIcon fontSize="small" />
                            </span>

                            <Image
                              alt="product"
                              src={item.ProductLogo == null || item.ProductLogo.length === 0 ? DefaultImage : item.ProductLogo}
                              className={classes.productImage}
                            />
                            <div
                              className={classes.removeProduct}
                              onClick={() => {
                                this.props.removeProductFromCart(
                                  this.props.evaluationCart.ID,
                                  item.EntityObjectID
                                );
                              }}
                            >
                              <Image
                                src={CloseImage}
                                className={classes.productRemove} alt="cart"
                              />
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

          {
            this.props.expanded === true &&
            <div className={classnames(classes.cartContainer, this.props.expanded ? 'open' : 'close')}>

              <Typography variant={"h5"} className={classes.title}>
                Selected Product
              </Typography>

              <Typography variant={"subtitle1"} className={classes.subTitle}>
                Pick products to evaluate
              </Typography>

              {(this.props.draftEvaluationFetchProgress ||
                this.props.removeProductInProgress) && (
                  <div className={classes.progressIndicator}>
                    <CircularProgress />
                  </div>
                )}

              {!this.props.draftEvaluationFetchProgress &&
                !this.props.removeProductInProgress && (
                  <React.Fragment>
                    <div className={classes.cartItems}>
                      {items.map((item, k) => {
                        return (
                          <div className={classes.cartItem} key={k}>

                            <div className={classes.counter}>{k + 1}.</div>
                            <div className={classes.productImageContainer}>
                              <Image
                                alt="product"
                                src={
                                  item.ProductLogo == null ||
                                    item.ProductLogo.length === 0
                                    ? DefaultImage
                                    : item.ProductLogo
                                }
                                className={classes.productImage}
                              />
                            </div>
                            <div className={classes.productName}>
                              <Typography
                                variant={"h5"}
                                className={classes.productNameTitle}
                              >
                                {item.Name}
                              </Typography>
                            </div>
                            <div
                              className={classes.productRemoveContainer}
                              onClick={() => {
                                this.props.removeProductFromCart(
                                  this.props.evaluationCart.ID,
                                  item.EntityObjectID
                                );
                              }}
                            >
                              <Image
                                src={CloseImage}
                                className={classes.productRemove} alt="cart"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {isShowProceedBtn && <Button
                      style={{}}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.button}
                      onClick={() => {
                        this.startWorkflowEvaluation(items);
                      }}
                    >
                      Proceed to Evaluation
                    </Button>}

                    {!isShowProceedBtn && <Button
                      style={{}}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.button}
                      onClick={() => {
                        this.setupWorkflow()
                      }}
                    >
                      Setup Workflow
                    </Button>}

                  </React.Fragment>
                )}
            </div>
          }
        </Drawer>
        <PreFeedbackDialog />
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(CartDrawer));
