import {
    START_FETCH_EVALUATION_REPORT_DATA,
    END_FETCH_EVALUATION_REPORT_DATA,
    START_FETCH_EVALUATION_APPROVAL_STATUS,
    END_FETCH_EVALUATION_APPROVAL_STATUS,
    START_DOWNLOAD_XLS, END_DOWNLOAD_XLS,
    START_DOWNLOAD_PDF, END_DOWNLOAD_PDF
} from "./action";


const initState = {
    isLoading: false,
    report: null,
    evaluationApprovalStatus: [],
    evaluationApprovalStatusProg: false,
    evaluationApprovalStatusError: null,

    downloadxlsProg: false,
    downloadxlsData: null,
    downloadxlsError: null,

    downloadPDFProgress: false,
    downloadPDFData: null,
    downloadPDFError: null
}

export function evaluationReportDataReducer(state = initState, action) {
    switch (action.type) {
        case START_DOWNLOAD_XLS:
            return {
                ...state,
                downloadxlsProg: true,
                downloadxlsData: null,
                downloadxlsError: null,
            }
        case END_DOWNLOAD_XLS:
            return {
                ...state,
                downloadxlsProg: false,
                downloadxlsData: action.payload.success,
                downloadxlsError: action.payload.error
            }
        case START_FETCH_EVALUATION_REPORT_DATA:
            return {
                ...state,
                isLoading: true,
                report: null
            };
        case END_FETCH_EVALUATION_REPORT_DATA:
            return {
                ...state,
                isLoading: false,
                report: action.payload.success
            };
        case START_FETCH_EVALUATION_APPROVAL_STATUS:
            return {
                ...state,
                evaluationApprovalStatusProg: false,
                evaluationApprovalStatusError: null
            };
        case END_FETCH_EVALUATION_APPROVAL_STATUS:
            let evaluationApprovalStatus = state.evaluationApprovalStatus
            if (action.payload.error === null) {
                evaluationApprovalStatus = action.payload.success
            }
            return {
                ...state,
                evaluationApprovalStatus,
                evaluationApprovalStatusProg: false,
                evaluationApprovalStatusError: action.payload.error
            };

        case START_DOWNLOAD_PDF:
            return {
                ...state,
                downloadPDFProgress: true,
                downloadPDFData: null,
                downloadPDFError: null
            }

        case END_DOWNLOAD_PDF:
            return {
                ...state,
                downloadPDFProgress: false,
                downloadPDFData: action.payload.success,
                downloadPDFError: action.payload.error
            }

        default:
            return state;
    }
}




