import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AppBar from "../AppBar";

const connectedProps = (state) => ({

});

const connectionActions = {

}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
});

class Feedback extends React.Component {

    render() {
        return <div>
            <AppBar title="Feedback" />
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(Feedback));