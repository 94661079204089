import React from 'react';
import { connect } from "react-redux";
import classnames from "classnames";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Collapse, Divider, Switch, IconButton, Checkbox } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';

// redux
import { expandEvaluationCriteria } from "redux/evaluation/criteria/action";
import { addTemplateRequirementColloborator } from "redux/templates/evaluation/org/action";
import { showAddTemplateDialog, addCriteriaSelection } from "redux/templates/evaluation/action";
import { removeTemplateRequirementCollaborator } from "redux/templates/evaluation/org/action";
// components
import Image from 'Components/Common/image.jsx';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
// import OutlinedSelectInput from 'Components/Common/Input/OutlinedSelectInput';
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import EvaluateMultiChoiceAnswer from 'Components/Common/EvaluateMultiChoiceAnswer'
import UserGroup from 'Components/Common/UserGroup';
// assets
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";
import CodeIcon from '@material-ui/icons/Code';

import { getRequirementType } from 'util/RequirementTypeOptions';
import SchemaTagSelectionDialog from '../../../WorkflowBuilder/Dialogs/SchemaTagSelectionDialog.jsx';
import { showRequirementConditionDialog } from "redux/common/action";

const connectedProps = (state) => ({
    expandedCriteriaId: state.evaludationCriteria.expandedCriteriaId,
    optionUpdateProgress: state.orgTemplateEvaluation.orgRequirementTemplateOptionInprogress,
    orgRequirementTemplateOptionSuccess: state.orgTemplateEvaluation.orgRequirementTemplateOptionSuccess,
    orgRequirementTemplateQuestionOptionError: state.orgTemplateEvaluation.orgRequirementTemplateQuestionOptionError,
});

const connectionActions = {
    addRequirementColloborator: addTemplateRequirementColloborator,
    showAddTemplateDialog: showAddTemplateDialog,
    removeTemplateRequirementCollaborator: removeTemplateRequirementCollaborator,
    addCriteriaSelection: addCriteriaSelection,
    expandEvaluationCriteria: expandEvaluationCriteria,
    showRequirementConditionDialog: showRequirementConditionDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        flex: 1,
        position: 'relative',
        border: '1px solid #EBEBEB',
        borderRadius: 5,
        marginBottom: 18,
        backgroundColor: '#FFFFFF',
    },
    expand: {
        transform: 'rotate(0deg)',
        padding: 0,
        borderRadius: 3,
        position: 'absolute',
        top: 16,
        right: 16,
        border: '1px solid #EDEDED',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    requirementExpanded: {
        border: '1px solid #49B8FA',
    },
    requirementCollapsed: {
        paddingBottom: 12
    },
    requirementCollapse: {
        padding: '12px 16px 0 25px',
        cursor: 'pointer',
    },
    requirementContainer: {
        padding: '30px 16px 10px 25px',
        justifyContent: 'space-between'
    },
    title: {
        fontSize: theme.spacing(2),
        color: '#333333',
        fontWeight: 600,
        width: 'max-content',
        '& > img': {
            display: 'none'
        },
        '&:hover > img': {
            display: 'initial'
        }
    },
    collapseTitle: {
        fontSize: theme.spacing(2),
        color: '#333333',
        fontWeight: 600,
    },
    collapseTxt: {
        fontSize: theme.spacing(2),
        color: '#707070',
        marginTop: theme.spacing(1),
    },
    bottomContainer: {
        padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
        borderRadius: 8,
        marginTop: 20,
        backgroundColor: '#FAFAFA',
        justifyContent: 'space-between',
    },
    bodyTxt: {
        borderRadius: 8,
        backgroundColor: '#FAFAFA',
        padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        fontSize: theme.spacing(2)
    },
    leftPanel: {
    },
    rightPanel: {
        paddingTop: 25
    },
    actions: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        maxWidth: 80,
        marginLeft: 'auto'
    },
    actionItem: {
        textAlign: "center",
        cursor: "pointer",
        marginRight: 4
    },
    editIcon: {
        color: "#999999"
    },
    caretIcon: {
        color: "#999999"
    },
    descriptionContainer: {
        marginTop: 18
    },
    description: {
        color: "#707070",
        fontSize: 13,
        minHeight: 50 // 106
    },
    priority: {
        marginTop: theme.spacing(3),
        "& > div": {
            marginBottom: 0,
        },
        "&.evaluate-priority": {
            display: 'flex',
            marginLeft: 0,
        },
        "& [class*=MuiFormLabel-root]": {
            fontSize: '0.9em'
        },
        "& [class*=MuiInputBase-root]": {
            color: '#707070',
            fontSize: 13,
            textAlign: 'center'
        },
        "& [class*=MuiSelect-outlined]": {
            color: "#707070",
            fontSize: theme.spacing(1.8),
            "&:after": {
                content: "''",
                width: 7,
                height: 7,
                position: 'absolute',
                left: 9,
                borderRadius: '50%',
                top: '50%',
                transform: 'translate(0px, -50%)'
            },
        },
        "&.low [class*=MuiSelect-outlined]:after": {
            background: 'green'
        },
        "&.medium [class*=MuiSelect-outlined]:after": {
            background: 'orange'
        },
        "&.high [class*=MuiSelect-outlined]:after": {
            background: 'red'
        }
    },
    scoringType: {
        marginLeft: 0,
        display: 'flex',
        flexDirection: 'row-reverse',
        "& [class*=MuiFormLabel-root]": {
            fontSize: theme.spacing(1.8)
        },
        "& [class*=MuiSelect-outlined]": {
            paddingLeft: 10
        }
    },
    userAndSelect: {
        display: "flex",
        flexDirection: 'column',
        '& label': {
            color: '#6C6C6C',
            fontSize: '0.9em',
            marginBottom: '0.7em'
        }
    },
    readMore: {
        color: '#4a88ff',
        cursor: 'pointer'
    },
    descriptionSection: {
        "& [class*='editableField']": {
            minHeight: 'auto !important',
            margin: '0px 10px 0px 0px !important'
        },
        "& [class*='editor-formControl']": {
            maxHeight: 80,
            transition: 'all linear .5s',
        },
        "& [class*='makeStyles-input-']": {
            marginBottom: 0,
            '& p': {
                marginTop: 0
            }
        }
    },
    '@global': {
        '.rootRequirementHight': {
            "& [class*='editor-formControl']": {
                maxHeight: 165
            }
        }
    },
    addSchedule: {
        marginTop: 6,
        fontSize: theme.spacing(1.6),
        color: "#4175DF",
        cursor: "pointer",
        display: 'inline-block'
    },
    label: {
        color: '#707070',
        fontSize: 12,
        fontWeight: 400,
    },
    nameEditCotnainer: {
        position: 'relative',
        paddingBottom: 20,
        flex: 1,
    },
    saveNameAction: {
        display: 'flex',
        position: 'absolute',
        bottom: 8,
        right: -1,
        margin: 'auto',
    },
    actionIcon: {
        width: 30,
        height: 30,
        borderRadius: 5,
        textAlign: 'center',
        marginLeft: 5,
        border: '1px solid #4b86f8'
    },
    micon: {
        fontSize: 16,
        marginTop: 6,
        color: '#4b86f8',
    },
    btn: {
        border: '1px solid #EBEBEB',
        borderRadius: '3px',
        outline: 0,
        padding: '6px 14px',
        cursor: 'pointer'
    },
    btnCancel: {
        background: '#FAFAFA',
        color: '#707070',
        borderColor: '#EBEBEB',
        marginRight: 16
    },
    btnSave: {
        background: '#FFFFFF',
        color: '#4175DF',
        borderColor: '#9DBDFA'
    },
    dragIconArea: {
        position: 'absolute',
        top: 15,
        left: '50%',
        color: '#D9D9D9',
        transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
    },
    visiblityNone: {
        visibility: 'hidden'
    },
    priorityOutlinedInput: {
        minHeight: '25px !important',
        padding: 5,
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        color: '#5F5F5F',
        fontSize: '1.1em',
        width: 75,
        textAlign: 'center'
    },
    actionTxt: {
        color: '#4175DF',
        width: 'max-content',
        fontSize: theme.spacing(1.75),
        marginTop: 10,
        marginBottom: 10
    },
    codeIcon: {
        cursor: 'pointer'
    }
});

class Requirement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            name_error: "",
            description: '',
            answertype: 1,
            is_editable: false,
            is_mandatory: false,
            description_error: "",
            isEditName: false,
            showCheckbox: false,
            priority: '',
            priority_error: '',
            character_limit: '',
            isUpdateRequirementPriority: false,
            showProjectSchema: false
        }

        this.updateExisitngRequirement = this.updateExisitngRequirement.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.requirement, 'this.props.requirement')
        try {
            this.setState({
                name: this.props.requirement?.Name || '',
                description: this.props.requirement?.Description || '',
                priority: this.props.requirement?.Priority === 0 ? '' : this.props.requirement?.priority || '',
                answertype: this.props.requirement?.AnswerType || this.props.requirement?.AnswerType || 1,
                is_editable: this.props.requirement?.IsEditable || false,
                is_mandatory: this.props.requirement?.IsMandatory || false,
                character_limit: this.props.requirement?.CharacterLimit || 0
            })
        } catch (e) { }
    }

    componentDidUpdate(prevProps) {
        if (this.props.optionUpdateProgress === false && prevProps.optionUpdateProgress === true) {
            if (this.props.orgRequirementTemplateOptionSuccess !== undefined && this.props.orgRequirementTemplateOptionSuccess !== null && this.props.orgRequirementTemplateOptionSuccess !== '' && this.props.orgRequirementTemplateOptionSuccess.length > 0 && this.props.orgRequirementTemplateOptionSuccess[0].RequirementID === this.props.requirement?.ID) {
                if (this.props.orgRequirementTemplateQuestionOptionError === null) {
                    // console.log('iiiii555555555555555555555555555555555555555555')
                    this.updateRequirementData(this.props.criteria, this.props.requirement)
                }
            }
        }
    }

    cleanError() {
        this.setState({
            name_error: "",
            description_error: "",
        })
    }

    saveName(name, description, template_id, criteria_id, requirement_id) {
        this.cleanError();
        this.setState({
            name_error: "",
            description_error: ""
        });

        if (name.trim().length === 0) {
            this.setState({
                name_error: "Please enter Section Name."
            });
            return;
        }
        if (description.trim().length === 0) {
            this.setState({
                description_error: "Please enter Description."
            });
            return;
        }

        this.props.updateRequirement(
            template_id,
            criteria_id,
            requirement_id,
            {
                name: name,
                description: description,
                priority: this.props.requirement.Priority,
                answertype: this.props.requirement.AnswerType,
                is_editable: this.props.requirement.IsEditable,
                is_mandatory: this.props.requirement.IsMandatory
            }
        )
        this.setState({
            isEditName: false
        });
    }

    handleMultiSelect = (id, answer_type) => {
        const element = document.getElementById(id)
        if (element && element.click() && [2, 3, 4, 14, 15].includes(answer_type)) {
            element.click()
        }
    }

    getCharLimit = (req) => {
        let rt = ''
        if (req.AnswerType === 7 && req.CharacterLimit) {
            rt = `- Character Limit ${req.CharacterLimit}`
        }
        return rt
    }

    updatePriority(template_id, criteria_id, requirementId) {
        this.setState({
            priority_error: ''
        })
        if (this.state.priority <= 0 || this.state.priority > 100) {
            this.setState({
                priority_error: 'Please enter weightage between 1 to 100.'
            })
        } else {
            this.props.updateRequirement(
                template_id,
                criteria_id,
                requirementId,
                {
                    priority: parseInt(this.state.priority)
                }
            )

            this.setState({
                isUpdateRequirementPriority: false
            });
        }
    }

    updateExisitngRequirement(btnId, criteria, requirement) {
        if ([2, 3, 4, 14, 15].includes(requirement?.AnswerType)) {
            this.handleMultiSelect(btnId, requirement?.AnswerType)
        } else {
            this.updateRequirementData(criteria, requirement)
        }
    }

    updateRequirementData(criteria, requirement) {
        const { name, description } = this.state;

        this.cleanError();
        this.setState({
            name_error: "",
            description_error: ""
        });

        if (name.trim().length === 0) {
            this.setState({
                name_error: "Please enter Section Name."
            });
            return;
        }
        if (description.trim().length === 0) {
            this.setState({
                description_error: "Please enter Description."
            });
            return;
        }

        this.props.updateRequirement(
            criteria.TemplateId,
            criteria.ID,
            requirement.ID,
            {
                name: name,
                description: description,
                priority: requirement.Priority,
                answertype: requirement.AnswerType || 1,
                is_editable: requirement.IsEditable,
                is_mandatory: this.state.is_mandatory,
                character_limit: this.state.character_limit
            }
        )
        this.props.toggleRequirement(false)
    }

    addTagValue(value) {
        this.setState({
            name: this.state.name + ' {{ ' + value + ' }} '
        })
    }

    render() {
        const classes = this.props.classes;
        const requirement = this.props.requirement;
        const criteria = this.props.criteria;
        const { isFromImportTemplate } = this.props;
        if (requirement == null || requirement === undefined) {
            return null;
        }

        const btnId = `criteria_requirement_sbtn_${criteria.ID}_${requirement.ID}`;
        const isChecked =
            this.props.criteriaSelection[criteria.ID] !== undefined &&
            Array.isArray(this.props.criteriaSelection[criteria.ID]) &&
            this.props.criteriaSelection[criteria.ID].filter(req => req.id === requirement.ID).length > 0


        var priorityText = "Add Weightage";
        // if (requirement.Priority !== null && requirement.Priority !== '' && requirement.Priority === 0) {
        //     priorityText = 'Weightage: 50%';
        // }
        if (requirement.Priority !== null && requirement.Priority !== '' && requirement.Priority > 0) {
            priorityText = 'Weightage: ' + requirement.Priority + '%';
        }


        // console.log(requirement)
        // console.log(requirement?.AnswerType,'Answerrrrrrrrrrrrrrrr')
        // console.log(requirement, 'requirementDataaaaaaaaaaa')

        const ruleRequirements = criteria?.Requirements?.map((item) => {
            return {
                ...item,
                answer_type: item.AnswerType,
                id: item.ID,
                name: item.Name,
                options: item.Options
            }
        })

        // console.log(this.props.maxScore,'mmmmmmmmmaxScore')

        return (
            <Grid container alignItems="center" onMouseOver={() => this.setState({ showCheckbox: true })} onMouseOut={() => this.setState({ showCheckbox: false })}>
                {(this.props.isSettingTemplate || this.props.isFromImportTemplate) && (
                    <Checkbox
                        checked={isChecked}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={(event) => {
                            event.stopPropagation()
                            const newexpandedCriteriaId = this.props.expandedCriteriaId
                            this.props.addCriteriaSelection(false, criteria.ID, [{ id: requirement.ID, name: requirement.Name }], event.target.checked)
                            this.props.expandEvaluationCriteria(false)
                            this.props.expandEvaluationCriteria(newexpandedCriteriaId)
                            if (isFromImportTemplate) {
                                this.props.toggleRequirement(this.props.isOpen ? false : `requirement_${requirement?.ID}`)
                            }
                        }}
                        className={isChecked ? {} : this.state.showCheckbox ? {} : classes.visiblityNone}
                    />
                )}

                <div id={`requirement_${criteria.ID}_${requirement.ID}`} className={classnames(classes.root, `requirement_${criteria.ID}_${requirement.ID}`, this.props.isOpen ? classes.requirementExpanded : classes.requirementCollapsed)}>
                    {!isFromImportTemplate && <DragIndicatorOutlinedIcon className={classes.dragIconArea} />}
                    {!this.state.isEditName && (
                        <IconButton
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: this.props.isOpen,
                            })}
                            aria-expanded={requirement.ID}
                            aria-label="show more"
                            onClick={() => { this.props.toggleRequirement(this.props.isOpen ? false : `requirement_${requirement?.ID}`) }}
                        >
                            <ArrowDropDownIcon />
                        </IconButton>
                    )}
                    {!this.props.isOpen && (
                        <div
                            className={classes.requirementCollapse}
                            onClick={e => {
                                this.props.toggleRequirement(`requirement_${requirement?.ID}`)
                            }}
                        >
                            <Typography component="p" className={classes.collapseTitle}>{this.state.name}
                                {requirement.IsMandatory && <font style={{ color: "red" }}>*</font>}
                            </Typography>
                            <Typography component="p" className={classes.collapseTxt}>
                                <SlateReadonlyField initValue={this.state.description} />
                            </Typography>
                            <Typography component="p" className={classes.collapseTxt}>Answer type - {getRequirementType(requirement, requirement.AnswerType)} {this.getCharLimit(requirement)}</Typography>
                        </div>
                    )}
                    <Collapse in={this.props.isOpen} timeout="auto" unmountOnExit>
                        <Grid container className={classes.requirementContainer}>
                            <Grid item xs={12}>
                                {!this.state.isEditName
                                    ? (
                                        <Typography component="p" className={classes.title}>
                                            {this.state.name} {requirement.IsMandatory && <font style={{ color: "red" }}>*</font>}
                                            {this.props.isEditable && !this.state.isEditName && (
                                                <Image
                                                    style={{ marginLeft: 8, marginBottom: -4 }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.setState({
                                                            isEditName: true
                                                        })
                                                    }}
                                                    alt="Edit"
                                                    src={editIcon}
                                                />)}
                                        </Typography>
                                    ) : (
                                        <div className={classes.nameEditCotnainer}>
                                            <Typography style={{ display: 'flex' }}>Name Your Question &nbsp;&nbsp;
                                                <CodeIcon
                                                    onClick={() => {
                                                        this.setState({
                                                            showProjectSchema: true
                                                        })
                                                    }}
                                                />
                                            </Typography>
                                            <OutlinedInput
                                                fullWidth
                                                error={this.state.name_error.length > 0}
                                                helperText={this.state.name_error}
                                                value={this.state.name}
                                                onChange={(event) => {
                                                    this.setState({ name: event.target.value });
                                                }}
                                            />
                                            <SlateInputField
                                                error={this.state.description_error.length > 0}
                                                helperText={this.state.description_error}
                                                folderPath={`/evaluation/${this.props?.editDialog?.evaluationId}/criteria/${this.props?.editDialog?.criteriaId}/requirement/${this.props?.editDialog?.requirement?.ID}`}
                                                placeholder=""
                                                as={SlateInputField}
                                                onChangeEvent={(value) => {
                                                    this.setState({ description: value });
                                                }}
                                                initValue={this.state.description}
                                                textContainerStyle={{ color: "#707070", }}
                                                isShowAddVariableOption={true}
                                                workflowId={0}
                                            />
                                            <div className={classes.saveNameAction}>

                                                <div
                                                    className={classes.actionIcon}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.cleanError()
                                                        this.setState({ name: requirement.Name, isEditName: false })
                                                    }}
                                                >
                                                    <ClearIcon className={classes.micon} />
                                                </div>
                                                <div
                                                    className={classnames(classes.actionIcon)}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.saveName(
                                                            this.state.name,
                                                            this.state.description,
                                                            criteria.TemplateId,
                                                            criteria.ID,
                                                            requirement.ID
                                                        )
                                                    }}
                                                >
                                                    <CheckIcon className={classnames(classes.micon)} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {!this.state.isEditName && (
                                    <Typography
                                        component="p"
                                        className={classes.bodyTxt}
                                        onClick={() => {
                                            if (this.props.isEditable && !this.state.isEditName) {
                                                this.setState({
                                                    isEditName: true
                                                })
                                            }
                                        }}
                                    >
                                        <SlateReadonlyField initValue={this.state.description || requirement.Description} />
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={7} className={classes.leftPanel} style={{ margin: 'auto 0' }}>
                                <EvaluateMultiChoiceAnswer
                                    evaluation_id={criteria?.TemplateId}
                                    requirement={requirement}
                                    criteria={criteria}
                                    editable={this.props.isEditable}
                                    isTemplate={true}
                                    saveBtnId={btnId}
                                    isCriteriaRequirement
                                    onChangeCharLimit={(data) => {
                                        console.log(data, 'Datasssssssss')
                                        if (data && data.length > 0) {
                                            this.setState({
                                                character_limit: parseInt(data[0]?.title)
                                            })
                                        }
                                    }}
                                />
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={3} className={classes.RightPanel}>
                                {this.props.isEditable && <div className={classes.rightAction}>
                                    <label className={classes.label} style={{ marginBottom: 10 }}>
                                        {this.props.isEditable ? "Add members" : "Members"}
                                    </label>
                                    <UserGroup
                                        position={'TemplateRequirement'}
                                        editable={this.props.isEditable}
                                        onAdd={(user) => {
                                            this.handleMultiSelect(btnId, requirement.AnswerType)
                                            this.props.addRequirementColloborator(
                                                criteria.TemplateId,
                                                criteria.ID,
                                                requirement.ID,
                                                user.ID)
                                        }} removeCollaborator={(user) => {
                                            this.props.removeTemplateRequirementCollaborator(
                                                criteria.TemplateId,
                                                criteria.ID,
                                                requirement.ID,
                                                user.ID)
                                        }}
                                        evaluationId={criteria.TemplateId}
                                        users={requirement.Collaborators}
                                        isfromSettingsTemplate={true}
                                        criteriaId={requirement.ID}
                                        criterias={[{ id: requirement.ID, Collaborators: requirement.Collaborators }]}
                                    />
                                </div>}

                                {this.state.priority_error !== null && this.state.priority_error.length > 0 && <>
                                    <span style={{ color: 'red', fontSize: 12 }}>{this.state.priority_error}</span>
                                </>}

                                {this.props.isEditable && !this.state.isUpdateRequirementPriority &&
                                    <Typography
                                        className={classes.actionTxt}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                                isUpdateRequirementPriority: true
                                            })
                                        }}>{priorityText}</Typography>}

                                <Typography
                                    className={classes.actionTxt}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.showRequirementConditionDialog({
                                            rules: requirement.Rules,
                                            requirement: {
                                                ...requirement,
                                                answer_type: requirement.AnswerType,
                                                id: requirement.ID,
                                                name: requirement.Name,
                                                options: requirement.Options
                                            },
                                            criterias: this.props.criterias,
                                            isEvaluation: false,
                                            criteria: {
                                                ...criteria,
                                                name: criteria.Name,
                                                id: criteria.ID
                                            },
                                            criteriaId: criteria.ID,
                                            id: criteria.TemplateId,
                                            requirements: ruleRequirements,
                                            maxScore: this.props.maxScore
                                        })
                                    }}>
                                    {requirement.Rules && requirement.Rules.length > 0 ? 'Update Condition' : 'Add Condition'}
                                </Typography>

                                {this.props.isEditable && this.state.isUpdateRequirementPriority && <Grid container style={{ marginTop: 10, alignItems: 'end' }}>
                                    <input
                                        value={this.state.priority}
                                        pattern="[0-9]"
                                        className={classes.priorityOutlinedInput}
                                        onChange={(event) => {
                                            var newValue = parseInt(event.target.value) || '';
                                            this.setState({ priority: newValue });
                                        }}
                                    ></input>
                                    <Grid container style={{ width: 'max-content', alignItems: 'end' }}>
                                        <div
                                            className={classes.actionIcon}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                this.cleanError()
                                                this.setState({
                                                    priority_error: '',
                                                    priority: requirement.Priority === 0 ? '' : requirement.Priority,
                                                    isUpdateRequirementPriority: false
                                                })
                                            }}
                                        >
                                            <ClearIcon className={classes.micon} />
                                        </div>
                                        {this.state.priority && (
                                            <div
                                                className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.updatePriority(
                                                        criteria.TemplateId,
                                                        criteria.ID,
                                                        requirement.ID)
                                                }}
                                            >
                                                <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>}

                                <div className={classes.rightAction} style={{ marginTop: 10 }}>
                                    <Grid container alignItems="center" direction="row">
                                        <Grid item>
                                            <label className={classes.label} style={{ marginRight: 8 }}>
                                                Required
                                            </label>
                                            <br />
                                            <br />
                                            <label className={classes.label} style={{ marginRight: 8 }}>
                                                Editable
                                            </label>
                                        </Grid>
                                        <Grid item>
                                            <Switch
                                                checked={this.state.is_mandatory}
                                                onChange={e => {
                                                    this.handleMultiSelect(btnId, requirement.AnswerType)
                                                    this.setState({
                                                        is_mandatory: e.target.checked
                                                    }, () => {
                                                        this.props.updateRequirement(
                                                            criteria.TemplateId,
                                                            criteria.ID,
                                                            requirement.ID,
                                                            {
                                                                is_mandatory: e.target.checked
                                                            }
                                                        )
                                                    })
                                                }}
                                                disabled={!this.props.isEditable}
                                                name="is_mandatory"
                                                color="primary"
                                                inputProps={{ 'aria-label': 'is_mandatory checkbox' }}
                                            />
                                            <br />
                                            <Switch
                                                checked={requirement.IsEditable}
                                                onChange={e => {
                                                    this.handleMultiSelect(btnId, requirement.AnswerType)
                                                    this.props.updateRequirement(
                                                        criteria.TemplateId,
                                                        criteria.ID,
                                                        requirement.ID,
                                                        {
                                                            is_editable: e.target.checked
                                                        }
                                                    )
                                                }}
                                                disabled={!this.props.isEditable}
                                                color="primary"
                                                name="is_editable"
                                                inputProps={{ 'aria-label': 'is_editable checkbox' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        {this.props.isEditable && (
                            <Grid container className={classes.bottomContainer} >
                                <div className={classes.actionItem}>
                                    {this.props.isEditable && (
                                        <Grid
                                            container
                                            alignItems="center"
                                            onClick={e => {
                                                e.stopPropagation();
                                                this.props.deleteRequirement(criteria.TemplateId, criteria.ID, requirement.ID)
                                            }}
                                        >
                                            <Typography><span className={classes.label}>Delete</span></Typography>&nbsp;&nbsp;
                                            <Image alt="Delete" src={deleteIcon} />
                                        </Grid>
                                    )}
                                </div>

                                <div className={classes.actionItem}>
                                    <button
                                        type="button"
                                        className={classnames(classes.btn, classes.btnCancel)}
                                        onClick={() => { this.props.toggleRequirement(false) }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className={classnames(classes.btn, classes.btnSave)}
                                        onClick={() => {
                                            this.updateExisitngRequirement(btnId, criteria, requirement);
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Grid>
                        )}
                    </Collapse>
                </div>
                {this.state.showProjectSchema && <SchemaTagSelectionDialog
                    isOpen={this.state.showProjectSchema}
                    loadEverytime={false}
                    workflowId={0}
                    hideDialog={() => {
                        this.setState({
                            showProjectSchema: false
                        })
                    }}
                    addTagValue={(value) => {
                        this.addTagValue(value)
                        this.setState({
                            showProjectSchema: false
                        })
                    }}
                />
                }
            </Grid>
        )
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Requirement));