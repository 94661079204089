/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment";

export const START_INDUSTRIES_FETCH = "START_INDUSTRIES_FETCH";
export const END_INDUSTRIES_FETCH = "END_INDUSTRIES_FETCH";
export const START_NUMOFEMPLOYEES_FETCH = "START_NUMOFEMPLOYEES_FETCH";
export const END_NUMOFEMPLOYEES_FETCH = "END_NUMOFEMPLOYEES_FETCH";
export const START_LOCATIONS_FETCH = "START_LOCATIONS_FETCH";
export const END_LOCATIONS_FETCH = "END_LOCATIONS_FETCH";
export const START_ORG_TYPE_FETCH = "START_ORG_TYPE_FETCH";
export const END_ORG_TYPE_FETCH = "END_ORG_TYPE_FETCH";
export const START_COMPLIANCE_CERT_FETCH = 'START_COMPLIANCE_CERT_FETCH';
export const END_COMPLIANCE_CERT_FETCH = 'END_COMPLIANCE_CERT_FETCH';
export const START_PII_FETCH = 'START_PII_FETCH';
export const END_PII_FETCH = 'END_PII_FETCH';

export const START_ADD_COMPLIANCE_CERT = 'START_ADD_COMPLIANCE_CERT';
export const END_ADD_COMPLIANCE_CERT = 'END_ADD_COMPLIANCE_CERT';

export const START_ADD_PII = 'START_ADD_PII';
export const END_ADD_PII = 'END_ADD_PII';

export const START_FETCH_PLATFORM = 'START_FETCH_PLATFORM';
export const END_FETCH_PLATFORM = 'END_FETCH_PLATFORM';

export const START_FETCH_WORKFLOW_COMP = 'START_FETCH_WORKFLOW_COMP';
export const END_FETCH_WORKFLOW_COMP = 'END_FETCH_WORKFLOW_COMP';

export const START_FORM_BY_COMP_ID = "START_FORM_BY_COMP_ID";
export const END_FORM_BY_COMP_ID = "END_FORM_BY_COMP_ID";

export const START_FORM_COMP = 'START_FORM_COMP';
export const END_FORM_COMP = 'END_FORM_COMP';

export const START_FRAMEWORKS_FETCH = 'START_FRAMEWORKS_FETCH';
export const END_FRAMEWORKS_FETCH = 'END_FRAMEWORKS_FETCH';



function startIndustriesFetch() {
    return {
        type: START_INDUSTRIES_FETCH,
        payload: null
    };
}

function endIndustriesFetch(success, error) {
    return {
        type: END_INDUSTRIES_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchIndustries() {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/industries?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startIndustriesFetch());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endIndustriesFetch(response, error));
        } catch (e) {
            dispatch(endIndustriesFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startNumOfEmployeesFetch() {
    return {
        type: START_NUMOFEMPLOYEES_FETCH,
        payload: null
    };
}

function endNumOfEmployeesFetch(success, error) {
    return {
        type: END_NUMOFEMPLOYEES_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchNumOfEmployees() {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/numOfEmployees?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startNumOfEmployeesFetch());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endNumOfEmployeesFetch(response, error));
        } catch (e) {
            dispatch(endNumOfEmployeesFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startLocationsFetch() {
    return {
        type: START_LOCATIONS_FETCH,
        payload: null
    };
}

function endLocationsFetch(success, error) {
    return {
        type: END_LOCATIONS_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchLocations() {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/locations?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startLocationsFetch());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endLocationsFetch(response, error));
        } catch (e) {
            dispatch(endLocationsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startOrgTypeFetch() {
    return {
        type: START_ORG_TYPE_FETCH,
        payload: null
    };
}

function endOrgTypeFetch(success, error) {
    return {
        type: END_ORG_TYPE_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchOrgType() {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/orgType?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startOrgTypeFetch());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endOrgTypeFetch(response, error));
        } catch (e) {
            dispatch(endOrgTypeFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startComplianceCertFetch() {
    return {
        type: START_COMPLIANCE_CERT_FETCH,
        payload: null
    };
}

function endComplianceCertFetch(success, error) {
    return {
        type: END_COMPLIANCE_CERT_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchComplianceCert(query = '', page = 0, pageSize = 50) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/compliance-cert?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startComplianceCertFetch());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, { query, page, pageSize });
            dispatch(endComplianceCertFetch(response, error));
        } catch (e) {
            dispatch(endComplianceCertFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddComplianceCert() {
    return {
        type: START_ADD_COMPLIANCE_CERT,
        payload: null
    };
}

function endAddComplianceCert(success, error) {
    return {
        type: END_ADD_COMPLIANCE_CERT,
        payload: {
            success, error
        }
    };
}

export function addComplianceCert(params) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/add-compliance-cert?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddComplianceCert());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddComplianceCert(response, error));
        } catch (e) {
            dispatch(endAddComplianceCert(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startPiiFetch() {
    return {
        type: START_PII_FETCH,
        payload: null
    };
}

function endPiiFetch(success, error) {
    return {
        type: END_PII_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchPii(query = '', page = 0, pageSize = 50) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/pii?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startPiiFetch());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, { query, page, pageSize });
            dispatch(endPiiFetch(response, error));
        } catch (e) {
            dispatch(endPiiFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddPii() {
    return {
        type: START_ADD_PII,
        payload: null
    };
}

function endAddPii(success, error) {
    return {
        type: END_ADD_PII,
        payload: {
            success, error
        }
    };
}

export function addPii(params) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/add-pii?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddPii());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddPii(response, error));
        } catch (e) {
            dispatch(endAddPii(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchPlatform() {
    return {
        type: START_FETCH_PLATFORM,
        payload: null
    };
}

function endFetchPlatform(success, error) {
    return {
        type: END_FETCH_PLATFORM,
        payload: {
            success, error
        }
    };
}

export function fetchPlatform() {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/platforms?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchPlatform());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchPlatform(response, error));
        } catch (e) {
            dispatch(endFetchPlatform(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchWorkflowComponents() {
    return {
        type: START_FETCH_WORKFLOW_COMP,
        payload: null
    };
}

function endFetchWorkflowComponents(success, error) {
    return {
        type: END_FETCH_WORKFLOW_COMP,
        payload: {
            success, error
        }
    };
}

export function workflowComponents() {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/workflow-components?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflowComponents());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkflowComponents(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowComponents(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFormByComponentId() {
    return {
        type: START_FORM_BY_COMP_ID,
        payload: {}
    };
}

function endFormByComponentId(success, error) {
    return {
        type: END_FORM_BY_COMP_ID,
        payload: {
            success, error
        }
    };
}

export function formByComponentId(compId) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/workflow-components/${compId}/workflow-components-forms?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFormByComponentId());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFormByComponentId(response, error));
        } catch (e) {
            dispatch(endFormByComponentId(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFormComponent() {
    return {
        type: START_FORM_COMP,
        payload: {}
    };
}

function endFormComponent(success, error) {
    return {
        type: END_FORM_COMP,
        payload: {
            success, error
        }
    };
}

export function formComponent() {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/workflow-components-forms?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFormComponent());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFormComponent(response, error));
        } catch (e) {
            dispatch(endFormComponent(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchFrameworks() {
    return {
        type: START_FRAMEWORKS_FETCH,
        payload: null
    };
}

function endFetchFrameworks(success, error) {
    return {
        type: END_FRAMEWORKS_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchFrameworks() {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/contract_details/custom_framework?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchFrameworks());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchFrameworks(response, error));
        } catch (e) {
            dispatch(endFetchFrameworks(null, {
                message: e.message
            }));
            return;
        }
    };
}