import React from "react";
import { Button, MenuItem, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import deleteIcon from "assets/images/delete.svg";
// assets
import * as Environment from "util/Environment";
import { CommonFn } from "../../../../../services/commonFn";
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";
import axios from "axios";
import classnames from "classnames";
import Image from "Components/Common/image.jsx";
import OutlinedInput from "Components/Common/Input/OutlinedInput";

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileValue: {},
      imageUrl: "",
      isEdit: false,
      Name: "",
      Industry: "",
      NumberOfEmployees: "",
      MarketServed: [],
      Revenue: "",
      URL: "",
      CompanyDescription: "",
      OrganizationType: "",
      error: "",
      success: "",
      nameError: "",
      numberOfEmployeesError: "",
      industryError: "",
      organizationTypeError: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  deleteLogo = () => {
    let state = this.state;
    const params = {
      id: this.props.details?.OrgID,
      name: state.Name,
      industry: state.Industry ? parseInt(state.Industry) : 0,
      num_employees: parseInt(state.NumberOfEmployees),
      market_served: state.MarketServed || [],
      revenue: state.Revenue,
      url: state.URL,
      company_description: state.CompanyDescription,
      organization_type: parseInt(state.OrganizationType),
      logo: "",
    };
    this.props.updateCompanyProfile(params);
    this.setState({ isEdit: false });
  };

  handleSubmit = () => {
    this.setState({
      nameError: ''
    })
    let state = this.state;
    let isError = false;
    if (state.Name === '' || state.Name.length <= 0) {
      isError = true;
      this.setState({
        nameError: 'Please enter company name'
      })
      return;
    }
    if (isError) {
      return;
    }
    const params = {
      id: this.props.details?.OrgID,
      name: state.Name,
      industry: state.Industry ? parseInt(state.Industry) : 0,
      num_employees: parseInt(state.NumberOfEmployees),
      market_served: state.MarketServed || [],
      revenue: state.Revenue,
      url: state.URL,
      company_description: state.CompanyDescription,
      organization_type: parseInt(state.OrganizationType),
      logo: state.imageUrl,
    };

    this.props.updateCompanyProfile(params);
    this.setState({ isEdit: false });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.fetchCompanyProfileProgress === true &&
      this.props.fetchCompanyProfileProgress === false
    ) {
      this.setState({
        isEdit: false,
        Name: this.props.details.Name,
        Industry: this.props.details.Industry,
        NumberOfEmployees: this.props.details.NumberOfEmployees,
        MarketServed: this.props.details.MarketServed,
        Revenue: this.props.details.Revenue,
        URL: this.props.details.URL,
        CompanyDescription: this.props.details.CompanyDescription,
        OrganizationType: this.props.details.OrganizationType,
        imageUrl: this.props.details.Logo,
      });
    }

    if (
      this.props.updateCompanyProfileProgress === false &&
      prevProps.updateCompanyProfileProgress === true
    ) {
      if (this.props.updateErrorDetails == null) {
        this.setState({ isEdit: false });
      }
    }
  }

  // useEffect(() => {

  //   setProfileValue(props.details);
  //   setImageUrl(props.details?.Logo || imageUrl || "");
  // }, [imageUrl, props.details, reset]);

  getValue = (value, array, key) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element.id === value)[key];
      } catch (e) { }
      return result || "";
    }
  };

  getLocationValue = (value, array, parentKey, key) => {
    const area = [];
    array.map((region) => {
      region.countrylist.map((element) => {
        area.push(element);
      });
    });
    let values = [];
    if (value && value.length) {
      try {
        value.map((item) => {
          let selectedValue = area.find((element) => element.id === item);
          if (selectedValue) {
            values.push(selectedValue[key] || "");
          }
        });
      } catch (e) { }
    }
    return values.toString();
  };

  getOptions = () => {
    let marketOptions = [];
    this.props.locations.map((ele, index) => {
      ele.countrylist.map((item, childindex) => {
        marketOptions.push({ ...item, group: ele.label, name: item.label });
      });
    });
    return marketOptions;
  };

  getMarketValue = () => {
    let marketValue = [];
    if (this.state.MarketServed && this.state.MarketServed.length) {
      this.state.MarketServed.map((item) => {
        if (this.getValue(item, this.getOptions(), "label")) {
          marketValue.push({
            id: item,
            label: this.getValue(item, this.getOptions(), "label"),
          });
        }
      });
    }
    return marketValue;
  };

  fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var formData = new FormData();
      formData.append("folder", "companyProfile");
      const timestamp = new Date().getTime();
      formData.append(
        "file",
        file,
        `${timestamp}.${file.name.substring(file.name.indexOf(".") + 1)}`
      );
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;

      // var response    =   await axios.post(url, formData, {
      //     headers: {'Authorization': CommonFn.getStorage('authType')+' '+CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data'}
      // });

      // if(response.data.filepath) {
      //     setImageUrl(response.data.filepath)
      // }

      axios
        .post(url, formData, {
          headers: {
            Authorization:
              CommonFn.getStorage("authType") +
              " " +
              CommonFn.getStorage("authToken"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.filepath) {
            this.setState({ imageUrl: response.data.filepath });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.props.showSnackBar(err.response.data.error, "error", 3000);
          } else if (err.request) {
            this.props.showSnackBar(
              "Something went wrong. Please try after sometime.",
              "error",
              3000
            );
          } else {
            this.props.showSnackBar(
              "Something went wrong. Please try after sometime.",
              "error",
              3000
            );
          }
        });
    }
  };

  render() {
    const classes = this.props.classes;
    const {
      imageUrl,
      isEdit,
      Name,
      Industry,
      NumberOfEmployees,
      MarketServed,
      Revenue,
      URL,
      CompanyDescription,
      OrganizationType,
      nameError,
      numberOfEmployeesError,
      industryError,
      organizationTypeError,
    } = this.state;
    return (
      <form className={classes.productForm}>
        {/* <div className={classes.alert}>
          {   this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
      </div> */}
        <div className={classes.btnSection}>
          {!isEdit && (
            <Button
              className={classes.newUserBtn}
              onClick={() => this.setState({ isEdit: !isEdit })}
            >
              Edit
            </Button>
          )}
        </div>
        <div className={classes.addProductSection}>
          <div className={classes.productImage}>
            {((!isEdit && imageUrl.length > 0) || (isEdit && imageUrl)) && (
              <Image
                src={imageUrl}
                className={classes.image}
                alt="company profile"
              />
            )}

            {isEdit && imageUrl && (
              <div className={classes.deleteSection}>
                <div className={classes.delete} onClick={this.deleteLogo}>
                  <Image alt="Delete" src={deleteIcon} />
                </div>
              </div>
            )}

            {isEdit && !imageUrl && (
              <label
                htmlFor="companyProfile"
                className={classes.uploadImageLabel}
              >
                <Image src={AddEvaluationImage} />
                Upload logo
              </label>
            )}
            {!isEdit && !imageUrl && (
              <Image src={""} className={classes.image} alt="company profile" />
            )}
            <input
              id="companyProfile"
              className={classes.uploadImage}
              onChange={this.fileSelectHandler}
              type="file"
            />
          </div>
          <div
            className={classnames(
              classes.rightSection,
              isEdit ? "editField" : "readOnly"
            )}
          >
            <div className={classes.formGroup}>
              <label className={classes.label}>
                Name
                <span className={classnames(classes.mandatory, "mandatory")}>
                  *
                </span>
              </label>
              <div className={classnames(classes.inputSection, "inputSection")}>
                {/* <OutlinedInput
                  defaultValue={Name}
                  className={classes.formControl}
                  name="Name"
                  placeholder="Name"
                  onChange={(event) => {
                    this.setState({ Name: event.target.value })
                  }}
                  onKeyDown={(event) => {
                    console.log(event.target.value)
                    this.setState({ Name: event.target.value })
                  }}
                /> */}
                <OutlinedInput
                  id={"emailIdInput"}
                  value={this.state.Name}
                  onChange={(event) => {
                    this.setState({ Name: event.target.value,nameError:'' });
                  }}
                  error={nameError.length > 0}
                  helperText={nameError}
                  onKeyDown={this.onKeyDown}
                />

                {/* <span className={classnames(classes.mandatory, "mandatory")}>
                  {nameError && "name is required"}
                </span> */}
              </div>
              <div className={classnames(classes.textSection, "textSection")}>
                {!this.props.fetchCompanyProfileProgress && (
                  <span>{Name || <span>-</span>}</span>
                )}
                {this.props.fetchCompanyProfileProgress && (
                  <Skeleton className={classes.loader} variant="text" />
                )}
              </div>
            </div>
            <div className={classes.formGroup}>
              <label className={classes.label}>Description</label>
              <div className={classnames(classes.inputSection, "inputSection")}>
                <input
                  defaultValue={CompanyDescription}
                  className={classes.formControl}
                  placeholder="Description"
                  name="CompanyDescription"
                  onChange={(event) =>
                    this.setState({ CompanyDescription: event.target.value })
                  }
                />
              </div>
              <div className={classnames(classes.textSection, "textSection")}>
                {!this.props.fetchCompanyProfileProgress && (
                  <span>{CompanyDescription || <span>-</span>}</span>
                )}
                {this.props.fetchCompanyProfileProgress && (
                  <Skeleton className={classes.loader} variant="text" />
                )}
              </div>
            </div>
            <div className={classes.formGroup}>
              <label className={classes.label}>Website</label>
              <div className={classnames(classes.inputSection, "inputSection")}>
                <input
                  defaultValue={URL}
                  className={classes.formControl}
                  placeholder="Website"
                  name="URL"
                  onChange={(event) =>
                    this.setState({ URL: event.target.value })
                  }
                />
              </div>
              <div className={classnames(classes.textSection, "textSection")}>
                {!this.props.fetchCompanyProfileProgress && (
                  <span>{URL || <span>-</span>}</span>
                )}
                {this.props.fetchCompanyProfileProgress && (
                  <Skeleton className={classes.loader} variant="text" />
                )}
              </div>
            </div>
            <div className={classes.formGroup}>
              <label className={classes.label}>
                Number of employee{" "}
                <span className={classnames(classes.mandatory, "mandatory")}>
                  *
                </span>
              </label>
              <div className={classnames(classes.inputSection, "inputSection")}>
                <TextField
                  select
                  className={classnames(classes.formControl)}
                  onChange={(e) => {
                    this.setState({ NumberOfEmployees: e.target.value });
                  }}
                  InputProps={{ disableUnderline: true }}
                  defaultValue={NumberOfEmployees}
                  value={NumberOfEmployees}
                >
                  {this.props.numOfEmployees?.map((ele, index) => (
                    <MenuItem value={ele.id} key={index}>
                      {ele.label}
                    </MenuItem>
                  ))}
                </TextField>
                <span className={classnames(classes.mandatory, "mandatory")}>
                  {numberOfEmployeesError && "Number of employees is required"}
                </span>
              </div>
              <div className={classnames(classes.textSection, "textSection")}>
                {!this.props.fetchCompanyProfileProgress && (
                  <span>
                    {this.getValue(
                      NumberOfEmployees,
                      this.props.numOfEmployees,
                      "label"
                    ) ? (
                      this.getValue(
                        NumberOfEmployees,
                        this.props.numOfEmployees,
                        "label"
                      )
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                )}
                {this.props.fetchCompanyProfileProgress && (
                  <Skeleton className={classes.loader} variant="text" />
                )}
              </div>
            </div>
            <div className={classes.formGroup}>
              <label className={classes.label}>Organization Type</label>

              <div className={classnames(classes.inputSection, "inputSection")}>
                <TextField
                  select
                  className={classnames(classes.formControl)}
                  onChange={(e) => {
                    this.setState({ OrganizationType: e.target.value });
                  }}
                  InputProps={{ disableUnderline: true }}
                  defaultValue={OrganizationType}
                  value={OrganizationType}
                >
                  {this.props.orgType?.map((ele, index) => (
                    <MenuItem value={ele.id} key={index}>
                      {ele.name}
                    </MenuItem>
                  ))}
                </TextField>
                <span className={classnames(classes.mandatory, "mandatory")}>
                  {organizationTypeError && "Number of employees is required"}
                </span>
              </div>

              <div className={classnames(classes.textSection, "textSection")}>
                {!this.props.fetchCompanyProfileProgress && (
                  <span>
                    {this.getValue(
                      OrganizationType,
                      this.props.orgType,
                      "name"
                    ) ? (
                      this.getValue(
                        OrganizationType,
                        this.props.orgType,
                        "name"
                      )
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                )}
                {this.props.fetchCompanyProfileProgress && (
                  <Skeleton className={classes.loader} variant="text" />
                )}
              </div>
            </div>
            <div className={classes.formGroup}>
              <label className={classes.label}>Revenue</label>
              <div className={classnames(classes.inputSection, "inputSection")}>
                <input
                  defaultValue={Revenue}
                  className={classes.formControl}
                  placeholder="Revenue"
                  name="Revenue"
                  onChange={(event) => {
                    this.setState({ Revenue: event.target.value });
                  }}
                />
              </div>
              <div className={classnames(classes.textSection, "textSection")}>
                {!this.props.fetchCompanyProfileProgress && (
                  <span>{Revenue || <span>-</span>}</span>
                )}
                {this.props.fetchCompanyProfileProgress && (
                  <Skeleton className={classes.loader} variant="text" />
                )}
              </div>
            </div>
            <div className={classes.formGroup}>
              <label className={classes.label}>Markets Served</label>
              <div className={classnames(classes.inputSection, "inputSection")}>
                <input
                  type="hidden"
                  name="MarketServed"
                  onChange={(event) => {
                    this.setState({ MarketServed: event.target.value });
                  }}
                />
                {/* <select className={classnames(classes.formControl, classes.selectField)}  name="MarketServed" ref={register()}>
                          <option value="">Markets Served</option>
                          {
                              this.props.locations?.map((ele, index) => <><optgroup key={index} label={ele.label}></optgroup>
                              {ele.countrylist?.map((item, childindex) => <option key={childindex} value={item.id} >{item.label}</option>)}</>)
                          }
                      </select> */}

                <Autocomplete
                  multiple
                  className={classnames(
                    classes.selectField,
                    classes.chipElement
                  )}
                  options={this.getOptions()}
                  groupBy={(option) => option.group}
                  closeIcon={false}
                  popupIcon={false}
                  getOptionLabel={(option) => {
                    return option.label;
                  }}
                  getOptionSelected={(option, value) => {
                    return option.id === value.id;
                  }}
                  ChipProps={{ variant: "outlined", deleteIcon: <CloseIcon /> }}
                  onChange={(e, value) => {
                    this.setState({
                      MarketServed: value.map((item) => item.id),
                    });
                  }}
                  value={MarketServed !== null && MarketServed.length > 0 ? this.getMarketValue() : []}
                  defaultValue={
                    MarketServed !== null && MarketServed.length > 0 ? this.getMarketValue() : []
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="" variant="outlined" />
                  )}
                />
              </div>
              <div className={classnames(classes.textSection, "textSection")}>
                {!this.props.fetchCompanyProfileProgress && (
                  <span>
                    {this.getLocationValue(
                      MarketServed,
                      this.props.locations,
                      "label",
                      "label"
                    ) ? (
                      this.getLocationValue(
                        MarketServed,
                        this.props.locations,
                        "label",
                        "label"
                      )
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                )}
                {this.props.fetchCompanyProfileProgress && (
                  <Skeleton className={classes.loader} variant="text" />
                )}
              </div>
            </div>
            <div className={classes.formGroup}>
              <label className={classes.label}>
                Industry
                <span className={classnames(classes.mandatory, "mandatory")}>
                  *
                </span>
              </label>
              <div className={classnames(classes.inputSection, "inputSection")}>
                <input
                  type="hidden"
                  name="Industry"
                  onChange={(event) => {
                    this.setState({ Industry: event.target.value });
                  }}
                />

                <Autocomplete
                  className={classes.selectField}
                  options={this.props.industries}
                  closeIcon={false}
                  popupIcon={false}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  getOptionSelected={(option, value) => {
                    return option.id === value.id;
                  }}
                  onChange={(event, value) => {
                    this.setState({ Industry: value?.id || "" });
                  }}
                  value={
                    Industry
                      ? {
                        id: Industry,
                        name: this.getValue(
                          Industry,
                          this.props.industries,
                          "name"
                        ),
                      }
                      : null
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="" variant="outlined" />
                  )}
                />
                <span className={classnames(classes.mandatory, "mandatory")}>
                  {industryError && "Industry is required"}
                </span>
              </div>
              <div className={classnames(classes.textSection, "textSection")}>
                {!this.props.fetchCompanyProfileProgress && (
                  <span>
                    {this.getValue(Industry, this.props.industries, "name") ? (
                      this.getValue(Industry, this.props.industries, "name")
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                )}
                {this.props.fetchCompanyProfileProgress && (
                  <Skeleton className={classes.loader} variant="text" />
                )}
              </div>
            </div>
          </div>
        </div>
        {isEdit && (
          <div className={classes.buttonSection}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => { this.handleSubmit() }}
            >
              Update
            </Button>
          </div>
        )}
      </form>
    );
  }
}

export default ProfileForm;
