import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import CommonCss from "commonCss";
import VendorTabs from "./VendorTabs";
import Sections from "./Sections";
import { showAddOrgProductChampionsDialog } from "redux/product/orgProduct/action";

const connectedProps = (state) => ({
  orgUser: state.orgUser,
  userType: state.authUser.user?.Role,
});

const connectionActions = {
  showAddOrgProductChampionsDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    ...CommonCss.ProductDetails,
    ProductNameSection: {
      ...CommonCss.ProductDetails.ProductNameSection,
      justifyContent: "space-between",
      maxWidth: "100%",
    },
    accessbtn: {
      "& > botton": {
        color: "white !important",
      },

      "& > a": {
        color: "white !important",
      },
    },
    productSection: {
      display: "flex",
      alignItems: "center",
    },
    requestAccessBtn: {
      marginLeft: 20,
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 16,
      marginBottom: 5,
      cursor: "pointer",
    },
  });

class VendorInfo extends Component {
  state = {};

  render() {
    const { classes, role, catalogType, appDetails, tabValue, tabs, currentTab, sections } = this.props;
  
    return (
      <>
        <div className={classes.ProductNameSection}>
          <div className={classes.productSection}>
            <p className={classes.productName}>{catalogType === 'vendor' ? appDetails?.name : appDetails?.service?.name + " By " + appDetails?.vendor?.name }</p>
          </div>
        </div>
        <VendorTabs tabValue={tabValue} tabs={tabs} />
        <Sections 
          currentTab={currentTab} 
          sections={sections} 
          role={role} 
          appDetails={appDetails} 
          catalogType={catalogType}
        />
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(VendorInfo));
