import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import Home from "./Components/Home/Home";
import Category from "Components/Application/Components/Evaluate/Components/Category/Category.jsx";
import SubCategory from "Components/Application/Components/Evaluate/Components/SubCategory/SubCategory";
import AllProducts from "./Components/AllProductsV2";
import AllHardwareProducts from "./Components/HardwareProducts/AllHardwareProducts";
import AllCategories from "./Components/AllCategories";
import AppCategories from "./Components/AppCategories";
import ViewDetailsCustomFields from "./Components/AppCategories/ViewDetailsCustomFields";
import RecommendedProducts from "./Components/RecommendedProducts"
import Wishlisted from "./Components/Wishlisted";
import OrgWishlisted from "./Components/OrgWishlisted";
import AppCatalog from "./Components/AppCatalog";
import AppCatalogTabular from "./Components/AppCatalogTabular";
import CategoryWiseProducts from "./Components/CategoryWiseProductsV2";
import CategoryWiseHardwareProducts from "./Components/HardwareProducts/CategoryWiseHardwareProducts";
import ProductDetails from "../../../Common/ProductDetails";
// import OrgProductDetails from "../../../Common/OrgProductDetails";
import OrgProductDetailsV2 from "../../../Common/OrgProductDetailsV2/index";
// import Evaluation from "./Components/Evaluation/index";
import { fetchEvaluationCart } from "../../../../redux/evaluation/cart/action";
import EvaluationMetaDataUpdateDialog from "Components/Application/Components/Evaluate/Components/Common/EvaluationMetaDataUpdateDialog.jsx";
// import FeatureDialog from './Components/Common/FeatureDialog';
import { updateDrawerStyle } from "../../../../redux/drawer/action";

// views
import VendorInReviewProducts from 'Components/Views/Buyer/VendorInReviewProducts';

import AddProductManuallyDialog from "Components/Application/Components/Settings/AddProductManuallyDialog";
import MyProducts from "./Components/MyProducts";
import MyDevices from "./Components/MyDevices";
import ProductReviewList from "./Components/ProductReviewList";
import OrgProductRelationList from "Components/Common/OrgProductRelationList";
import ClusterProducts from "Components/Common/ClusterProducts";
import PersonalizedProducts from "Components/Common/PersonalizedProducts";

import CompanyRecommendationProducts from "./Components/CompanyRecommandation";
import CommonCss from 'commonCss';
import AppHardwareCatalog from "./Components/HardwareProducts/AppHardwareCatalog";
import AppHardwareDetails from "./Components/HardwareProducts/AppHardwareDetails"
import HardwareProductDetails from "Components/Application/Components/Products/Components/HardwareProducts/HardwareProductDetails";
import Risks from "./Components/Risks";

const connectedProps = (state) => ({
  addProductManuallyDialogOpen: state.productsSettings.addProductManuallyDialogOpen,
});

const connectionActions = {
  fetchEvaluationCart: fetchEvaluationCart,
  updateDrawerStyle: updateDrawerStyle,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer
});

class Products extends React.Component {
  componentDidMount() {
    this.props.fetchEvaluationCart();
    this.props.updateDrawerStyle(true, true);
  }
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={this.props.match.path} component={Home} />

          <Route
            exact
            path={`${this.props.match.path}/my-product`}
            render={(props) => (
              <MyProducts {...props} title="My Products" />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/product-review/:productID`}
            render={(props) => (
              <ProductReviewList {...props} title="Product Review" />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/unverified-product`}
            render={(props) => (
              <VendorInReviewProducts {...props} title="Unverified Products " />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/(my-wishlisted)`}
            component={Wishlisted}
          />
          <Route
            path={`${this.props.match.path}/(risks)`}
            component={Risks}
          />
          <Route
            exact
            path={`${this.props.match.path}/(org-wishlisted)`}
            component={OrgWishlisted}
          />
          <Route
            exact
            path={`${this.props.match.path}/category/:category_id`}
            component={Category}
          />
          <Route
            exact
            path={`${this.props.match.path}/subcategory/:subcategory_id`}
            component={SubCategory}
          />
          <Route
            exact
            path={`${this.props.match.path}/category-wise-product/:category_id`}
            component={CategoryWiseProducts}
          />
          <Route
            exact
            path={`${this.props.match.path}/category-wise-hardware-product/:category_id`}
            component={CategoryWiseHardwareProducts}
          />
          <Route
            exact
            path={`${this.props.match.path}/all-products`}
            component={AllProducts}
          />
          <Route
            exact
            path={`${this.props.match.path}/all-hardware-products`}
            component={AllHardwareProducts}
          />
          <Route
            exact
            path={`${this.props.match.path}/recommended-products`}
            component={RecommendedProducts}
          />
          <Route
            exact
            path={`${this.props.match.path}/org-products`}
            component={AppCatalog}
          />
          <Route
            exact
            path={`${this.props.match.path}/org-products-tabular`}
            component={AppCatalogTabular}
          />
          <Route
            exact
            path={`${this.props.match.path}/org-hardware-products`}
            component={AppHardwareCatalog}
          />
          <Route
            exact
            path={`${this.props.match.path}/org-hardware-product-details/:id`}
            component={AppHardwareDetails}
          />
          <Route
            exact
            path={`${this.props.match.path}/org-products/user-recommendation`}
            component={PersonalizedProducts}
          />
          <Route
            exact
            path={`${this.props.match.path}/org-categories`}
            component={AppCategories}
          />
          <Route
            exact
            path={`${this.props.match.path}/org-categories/:id/details`}
            component={ViewDetailsCustomFields}
          />
          <Route
            exact
            path={`${this.props.match.path}/cluster-products/:id`}
            component={ClusterProducts}
          />
          <Route
            exact
            path={`${this.props.match.path}/all-categories`}
            component={AllCategories}
          />
          <Route
            exact
            path={`${this.props.match.path}/product-details/:id`}
            component={ProductDetails}
          />
          <Route
            exact
            path={`${this.props.match.path}/hardware-product-details/:id`}
            component={HardwareProductDetails}
          />
          <Route
            exact
            path={`${this.props.match.path}/org-product-details/:id`}
            component={OrgProductDetailsV2}
          />

          <Route
            exact
            path={`${this.props.match.path}/org-product-relation/:id/:relationType`}
            component={OrgProductRelationList}
          />

          <Route
            exact
            path={`${this.props.match.path}/company-recommendation`}
            render={(props) => (
              <CompanyRecommendationProducts {...props} title="Company Recommendation Products " />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/my-devices`}
            component={MyDevices}
          />
        </Switch>
        <EvaluationMetaDataUpdateDialog />
        {
          this.props.addProductManuallyDialogOpen &&
          <AddProductManuallyDialog />
        }
      </React.Fragment>
    );
  }
}

export default connector(compose(withStyles(styles))(Products));
