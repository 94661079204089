import React from "react";
import { connect } from "react-redux";
import _ from "underscore";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slide } from "@material-ui/core";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";

// components
import Image from "Components/Common/image.jsx";
import OutlinedInput from "Components/Common/Input/OutlinedInput";

// redux
import { storeClusterGroupProducts, fetchCreateProductCluster, fetchUpdateProductCluster } from "redux/product/cluster/action";
import { showCreateClusterGroupDialog, hideCreateClusterGroupDialog } from "redux/product/cluster/action";
import { showSnackBar } from "redux/snackbar/action";
import { clearStoreState } from "redux/applications/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  dialog_state: state.clusterProductReducer._createClusterGroupDialog,
  isNew: state.clusterProductReducer._createClusterGroupDialogIsnew,
  isNewProductsDialog: state.clusterProductReducer._clusterProductsDialogIsNew,
  group_details: state.clusterProductReducer._createClusterGroupDialogDetails,

  productFecthProgress: state.orgProduct.orgProductFetchProg,
  clusterProducts: state.clusterProductReducer.storeClusterGroupProducts,

  addProductInClusterGroupInprogress: state.clusterProductReducer.addProductInClusterGroupInprogress,
  addProductInClusterGroupSuccess: state.clusterProductReducer.addProductInClusterGroupSuccess,

  createClusterGroupInprogress: state.clusterProductReducer.createClusterGroupInprogress,
  createClusterGroupError: state.clusterProductReducer.createClusterGroupError,
  createClusterGroupSuccess: state.clusterProductReducer.createClusterGroupSuccess,

  updateClusterGroupInprogress: state.clusterProductReducer.updateClusterGroupInprogress,
  updateClusterGroupSuccess: state.clusterProductReducer.updateClusterGroupSuccess,
  updateClusterGroupError: state.clusterProductReducer.updateClusterGroupError,
});

const connectionActions = {
  showCreateClusterGroupDialog: showCreateClusterGroupDialog,
  hideCreateClusterGroupDialog: hideCreateClusterGroupDialog,
  _clusterMergingProducts: storeClusterGroupProducts,
  fetchCreateProductCluster: fetchCreateProductCluster,
  fetchUpdateProductCluster: fetchUpdateProductCluster,
  showSnackBar: showSnackBar,
  clearStoreState: clearStoreState,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginLeft: 20,
      fontWeight: 500,
      marginBottom: 0,
    },
    dialogContent: {
      textAlign: "center",
    },
    subTitle: {
      color: "#4B4B4B",
      fontSize: 13,
    },
    dialog: {
      "& [class*=MuiDialog-paperWidth]": {
        maxWidth: 460,
        minHeight: 63,
        borderRadius: "10px",
      },
      "& [class*=MuiDialogContent-root], & [class*=MuiDialogContent-root]:first-child": {
        padding: 0,
      },
      "& [class*=Step-labelTypo]": {
        fontSize: 13,
        textAlign: "left",
      },
    },

    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
      "& svg": {
        stroke: "white",
        fontSize: "2em",
      },
    },
    productContainer: {
      padding: "20px 80px",
      "& ul": {
        listStyle: "none",
        padding: "0px",
        "& li": {
          marginBottom: "16px",
        },
      },
    },
    item: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center flex-start",
      alignItems: "center",
    },
    productItem: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center space-between",
      alignItems: "center",
      boxShadow: "0px 2px 6px #00000012",
      borderRadius: "0.6em",
      padding: "10px",
      "& img": {
        width: "35px",
        height: "auto",
      },
      "& span": {
        marginLeft: "16px",
      },
    },
    formAction: {
      marginTop: "30px",
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center space-between",
      alignItems: "center",
    },
    removeProduct: {
      color: "#dcd7d7",
      cursor: "pointer",
    },
  });

class CreateClusterGroupProductDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      name_error: "",
      new: true,
      new_products: [],
    };
  }

  componentDidMount() {
    if (this.props.isNewProductsDialog === false) {
      this.setState({
        name: this.props.group_details?.name,
        new: false,
      });
    }
    if (this.props.isNew) {
      this.setState(
        {
          name: "",
          new: true,
        },
        () => {
          console.log(this.state, this.props);
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.new &&
      ((!this.props.addProductInClusterGroupInprogress && prevProps.addProductInClusterGroupInprogress) || (!this.props.createClusterGroupInprogress && prevProps.createClusterGroupInprogress))
    ) {
      if (!this.props.createClusterGroupError && !this.props.addProductInClusterGroupError) {
        this.props.showSnackBar("Group created successfully", "success", 3000);
        this.props.clearStoreState({
          "clusterProductReducer.createClusterGroupSuccess": null,
        });
        this.props.hideCreateClusterGroupDialog();
        this.gotoFolder(this.props.createClusterGroupSuccess);
      } else {
        this.props.showSnackBar(this.props.createClusterGroupError, "error", 3000);
      }
    }

    if (!this.state.new && this.props.updateClusterGroupSuccess) {
      this.props.showSnackBar("Group updated successfully", "success", 3000);
      this.props.clearStoreState({
        "clusterProductReducer.updateClusterGroupSuccess": null,
      });
      this.props.hideCreateClusterGroupDialog();
    }

    if (this.props.dialog_state === true && prevProps.dialog_state === false) {
      if (this.props.isNewProductsDialog === false) {
        this.setState({
          name: this.props.group_details?.name,
          new: false,
        });
      }
    }
  }

  cleanError = () => {
    this.setState({
      name_error: "",
    });
  };

  gotoFolder = (group) => {
    this.props.history.push(`/app/products/cluster-products/${group?.orgProduct?.id}`);
  };

  removeProductFromGrpup = (productId) => {
    let products = this.props.clusterProducts;
    products = products.filter((o) => o.ID !== productId);
    this.props._clusterMergingProducts(products);
  };

  createClustergroup = () => {
    this.cleanError();
    var name = this.state.name;
    if (name.trim().length === 0) {
      this.setState({
        name_error: "Please enter Group Name.",
      });
      return;
    }

    if (this.state.new === true) {
      console.log("new");
      // create group
      let products = this.props.clusterProducts;
      let ids = _.map(products, "ID");
      ids = ids.map(Number);
      let create_cluster_group = {};
      create_cluster_group.name = this.state.name;
      create_cluster_group.url = "";
      create_cluster_group.companyName = "";
      create_cluster_group.buisnessCriticality = 3;
      create_cluster_group.with_products = true;
      create_cluster_group.products = ids;
      this.props.fetchCreateProductCluster(create_cluster_group);
    } else {
      console.log("old");
      // update group
      let update_cluster_group = {};
      update_cluster_group.name = this.state.name;
      this.props.fetchUpdateProductCluster(this.props.group_details?.id, update_cluster_group);
    }
  };

  render() {
    const classes = this.props.classes;

    return (
      <Dialog
        onClose={this.props.hideCreateClusterGroupDialog}
        aria-labelledby="new-evaluation-dialog"
        open={this.props.dialog_state}
        TransitionComponent={Transition}
        fullWidth={true}
        scroll={"body"}
        className={classnames(classes.dialog)}
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.hideCreateClusterGroupDialog()}>
            <CloseIcon />
          </div>
          <div className={classes.productContainer}>
            <OutlinedInput
              id={"cluster_name"}
              label="Group Name"
              value={this.state.name}
              error={this.state.name_error.length > 0}
              helperText={this.state.name_error}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
            />

            <div className={classes.productList}>
              <ul>
                {this.props.clusterProducts?.map((product, index) => (
                  <li key={index}>
                    <div className={classes.productItem}>
                      <div className={classes.item}>
                        <Image src={product.ProductLogo} />
                        <span>{product.Name}</span>
                      </div>
                      <CloseIcon
                        className={classes.removeProduct}
                        onClick={() => {
                          this.removeProductFromGrpup(product.ID);
                        }}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className={classes.formAction}>
              <Button
                onClick={() => {
                  this.props.hideCreateClusterGroupDialog();
                }}
                type="submit"
                color="secondary"
                className={classes.button}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.createClustergroup();
                }}
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
              >
                Save
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(CreateClusterGroupProductDialog));
