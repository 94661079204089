import { createStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Drawer,
  Typography,
  CircularProgress
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import classnames from "classnames";
// components
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
//redux 
import { hideBuilderConfigDrawer } from "redux/automations/builder/action";

import { showSnackBar } from "redux/snackbar/action";
import Schedular from "./Components/SchedularConfig";
import ActionConfig from "./Components/ActionConfig";
import { fetchComponentDetails, fetchComponentVariables } from 'redux/automations/action';

import ControlConfig from "./Components/ControlConfig";
import JobConfig from "./Components/JobConfig";

const connectedProps = (state) => ({
  user: state.authUser.user,
  configDatas: state.automationBuilder.configDatas,
  fetchComponentDetailsProgress: state.automation.fetchComponentDetailsProgress,
  fetchComponentDetailsError: state.automation.fetchComponentDetailsError,
  componentDetails: state.automation.componentDetails,

  fetchComponentVariablesProgress: state.automation.fetchComponentVariablesProgress,
  fetchComponentVariablesError: state.automation.fetchComponentVariablesError,
  componentVariables: state.automation.componentVariables,

  updateActionProgress: state.automation.updateActionProgress,
  updateActionError: state.automation.updateActionError,
  updateActionSuccess: state.automation.updateActionSuccess,

  masterActions: state.automation.masterActions,

});

const connectionActions = {
  hideBuilderConfigDrawer: hideBuilderConfigDrawer,
  showSnackBar: showSnackBar,
  fetchComponentDetails: fetchComponentDetails,
  fetchComponentVariables: fetchComponentVariables
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    drawer: {
      width: "40%",
      flexShrink: 0,
      transition: "width 300ms",
      background: "#F7F7F7",
    },
    drawerPaper: {
      width: "40%",
      transition: "width 300ms",
      background: "#fff",
    },

    cartContainer: {
      padding: '0px 20px',
      '&.close': {
        opacity: 0
      }
    },
    title: {
      fontSize: theme.spacing(2.4),
      // textAlign: "center",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    title2: {
      fontSize: theme.spacing(2.4),
      marginTop: theme.spacing(1),
    },
    closeIcon: {
      width: '36px',
      padding: '8px',
    },
    topBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 5,
      borderBottom: '1px solid #F7F7F7'
    },
    loader: {
      textAlign: 'center',
      marginTop: 30
    }
  });

class Configurations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteProductId: null,
      deleteEvaluationId: null,
      isloading: true,
      configDatas: ''
    }
  }

  componentDidMount() {
    this.setState({
      configDatas: this.props.configDatas
    }, () => {
      this.initData()
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchComponentDetailsProgress === false && prevProps.fetchComponentDetailsProgress) {
      if (this.props.fetchComponentDetailsError === null) {
        let configDatas = this.state.configDatas;
        if (configDatas.componentType === "Action" && this.props.componentDetails && this.props.componentDetails.InputRef) {
          configDatas.masterData = this.props.componentDetails.InputRef
        }
        this.setState({
          configDatas: configDatas,
          isloading: false
        })
      }
    }



    if (this.props.configDatas && this.props.configDatas.id !== prevProps.configDatas.id) {
      this.setState({
        isloading: true,
        configDatas: this.props.configDatas
      }, () => {
        this.initData()
      })
    }

    const { updateActionProgress, updateActionError, updateActionSuccess } = this.props;
    if (!updateActionProgress && prevProps.updateActionProgress) {
      if (updateActionError === null) {

        this.setState({
          isloading: true,
          // configDatas:configDatas
        }, () => {
          this.initData()
        })
      }
    }

  }

  initData() {
    this.props.fetchComponentDetails(this.state.configDatas?.object?.AutomationID, this.state.configDatas?.object?.ID)
    this.props.fetchComponentVariables(this.state.configDatas?.object?.AutomationID, this.state.configDatas?.object?.ID)
  }

  render() {
    const { classes, isForTestJobs, fetchComponentVariablesProgress } = this.props;
    const { isloading, configDatas } = this.state;
    // console.log(configDatas, 'configDatas')
    // console.log(this.props, 'ThissssPropsssssssss-999999999999')

    return (
      <>
        <Drawer
          className={
            classnames(classes.appDrawer, classes.drawer)
          }
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="right"
          hideBackdrop={false}
        >
          {fetchComponentVariablesProgress === false && !isloading && <div className={classes.projSummaryMain}>
            <div className={classes.topBar}>
              {configDatas && configDatas.type === "triggerNode" && <Typography>{configDatas.label}</Typography>}
              {configDatas && configDatas.componentType === "Trigger" && <Typography> {!isForTestJobs ? 'Trigger Configuration' : 'Trigger Details'}</Typography>}
              {configDatas && configDatas.componentType === "Action" && <Typography>{!isForTestJobs ? 'Action Configuration' : 'Action Details'}</Typography>}
              {configDatas && configDatas.componentType === "Control" && <Typography>{!isForTestJobs ? 'Setup your condition' : 'Control Details'}</Typography>}
              <IconButton
                className={classes.closeIcon}
                onClick={this.props.hideBuilderConfigDrawer}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>

            {!isForTestJobs && configDatas && configDatas.type === "triggerNode" && <Schedular
              data={configDatas}
              automationDetails={this.props.automationDetails}
              isForSummary={this.props.isForSummary}
              isForTestJobs={isForTestJobs}
            />}

            {!isForTestJobs && configDatas && configDatas.type === "actionNode" && <ActionConfig
              data={configDatas}
              automationDetails={this.props.automationDetails}
              isForSummary={this.props.isForSummary}
              isForTestJobs={isForTestJobs}
              inputRef={this.props.componentDetails?.InputRef}
              componentDetails={this.props.componentDetails}
              componentVariables={this.props.componentVariables}
            />}

            {!isForTestJobs && configDatas && (configDatas.type === "ifConditionNode" || configDatas.type === "loopNode") && <ControlConfig
              data={configDatas}
              automationDetails={this.props.automationDetails}
              isForSummary={this.props.isForSummary}
              isForTestJobs={isForTestJobs}
              inputRef={this.props.componentDetails?.InputRef}
              componentDetails={this.props.componentDetails}
              componentVariables={this.props.componentVariables}
            />}

            {isForTestJobs && <JobConfig
              data={configDatas}
              automationDetails={this.props.automationDetails}
              jobComponentConfigs={this.props.jobComponentConfigs}
              jobComponentConfigMap={this.props.jobComponentConfigMap}
            />}

          </div>}

          {(fetchComponentVariablesProgress || isloading) && <div className={classes.projSummaryMain}>
            <IconButton
              className={classes.closeIcon}
              onClick={this.props.hideBuilderConfigDrawer}>
              <CloseIcon fontSize="small" />
            </IconButton>
            <div className={classes.loader}>
              <CircularProgress />

            </div>
          </div>}
        </Drawer>
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Configurations));
