import React from 'react';
import Select from 'react-select';
import CommonCss from 'commonCss';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import axios from 'axios';
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AddFileDialog from './Components/AddFileDialog';
import AddUserDialog from './Components/AddUserDialog';
import {
  Typography, Grid,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button, Card, CardHeader, Avatar, CardContent
} from '@material-ui/core';
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import Radio from "@material-ui/core/Radio";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "assets/images/calendar.svg";
import deleteIcon from "assets/images/delete.svg";
import Image from 'Components/Common/image.jsx';
import TextField from '@material-ui/core/TextField';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import * as Validator from "util/Validation";
import { generate } from 'shortid';
const connectedProps = (state) => ({
});

const connectionActions = {
};

export const itemTypes = [
  { label: 'Simple Text', value: 1 },
  { label: 'Date', value: 2 },
  { label: 'Binary', value: 3 },
  { label: 'Single Select', value: 4 },
  { label: 'Multi Select', value: 5 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8 },
  { label: 'File', value: 9 },
  // { label: 'Produc?ts', value: 10 },
  { label: 'Rich Text', value: 11 },
  { label: 'Email', value: 12 },
  { label: 'Phone', value: 13 },
  { label: 'URL', value: 14 },
  { label: 'Number', value: 15 },
]

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    // marginBottom: '16px',
    width: "100%",
    "& ul.answers": {
      listStyle: "none",
      paddingLeft: 0,
      "& li": {
        border: "1px solid #EBEBEB",
        bordeRadius: "4px",
        paddingLeft: "8px",
        marginBottom: "8px",
        "& span": {
          fontSize: 13,
          color: "#6D7278",
        },
      },
    },
    "& [class*='editableField']": {
      background: '#fff',
      maxHeight: 'auto !important',
      minHeight: 'auto !important'
    }
  },
  itemTitle: {
    fontWeight: 400,
    color: '#282D30',
    fontSize: 14,
    margin: '30px 0px 0px'
  },
  formControl: {
    ...CommonCss.formControl,
  },
  inputLabel: {
    color: '#6C6C6C',
    fontSize: 15,
    opacity: 0.9,
    marginBottom: 11
  },
  inputFormControlText: {
    ...CommonCss.formControl,
    border: 'none',
    padding: 0,
    minHeight: 0,
    '& [class*="MuiInputBase-root"]': {
      color: '#6D7278',
      fontSize: 13
    },
    '& [class*="MuiOutlinedInput-input"]': {
      padding: '14px !important'
    },
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
  },
  inputFormControl: {
    ...CommonCss.formControl,
    border: 'none',
    padding: 0,
    minHeight: 0,
    '& [class*="MuiInputBase-root"]': {
      color: '#6D7278',
      fontSize: 13
    },
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
  },
  creatable: {
    padding: 0
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 16
  },
  searchAndSelect: {
    padding: 0,
    "& .select__control, & .select__control:hover": {
      border: 'none',
      height: 'inherit',
      boxShadow: 'none',
      minHeight: 44
    },
    "& .select__multi-value, & .select__multi-value__label": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20,
      padding: theme.spacing(.3, .6),
      fontSize: 13
    },
    "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20
    },
  },
  deleteIcon: {
    position: "absolute",
    right: 16,
    top: 13,
    cursor: "pointer",
  },
  contactCardHeader: {
    flexDirection: "column",
    padding: "16px 4px !important",
    "& [class*='MuiCardHeader-avatar-']": {
      margin: "0px",
      marginBottom: 12,
    },
    "& [class*='MuiCardHeader-content']": {
      width: "100%",
    },
    "& [class*=MuiTypography-displayBlock]": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      margin: "0px -19px 0px 0px",
    },
  },
  addNewChamp: {
    display: "block",
    textAlign: "center",
    color: "#bdbdbd",
    "& span": {
      display: "block",
      cursor: "pointer",
      color: "#000000",
    },
  },
  addContact: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    margin: "0px -19px 0px 0px",
  },
  dialogContent: {
    minHeight: 170,
    overflowY: 'hidden',
    "& [class*=select__menu]": {
      maxHeight: 100
    }
  },
  customTypeFields: {
    marginBottom: 15,
    marginTop: 15,
    '& [class*="MuiFormControl-root"]': {
      width: '100%'
    }
  },
  requiredField: {
    color: 'red'
  },
  inputContainer: {
    display: 'flex',
    marginBottom: 15,
    alignItems: 'center',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    padding: '5px',
    width: '50%',
  },
  count: {
    display: 'inline-block',
    textAlign: 'center',
    color: '#6D7278',
    fontSize: '13px',
    marginRight: 5
  },
  input: {
    border: '0px',
    height: '30px',
    width: 'calc(100% - 20px)',
    outline: 0,
    color: '#6D7278',
    fontSize: '13px'
  },
  dragIndicator: {
    color: '#D9D9D9',
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 35,
    padding: 0,
    marginLeft: 15,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  uploadImage: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
  },
  linkText: {
    position: 'relative',
    cursor: 'pointer',
    width: "100%",
    height: '100%'
  },
  resourceLink: {
    display: 'flex',
    alignItems: 'center',
  },
  contactCardRoot: {
    cursor: "pointer",
  },
  cardSection: {
    position: "relative",
    width: 'inherit',
  },
  contractHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  deleteImage: {
    cursor: 'pointer'
  },
  deleteImageCard: {
    position: "absolute",
    top: 10,
    zIndex: 20,
    right: 10,
    padding: 4,
    cursor: "pointer",
  },
});

class CustomReply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text_value: '',
      selectedOption: [],
      isValidationError: false,
      fileLink: "",
      fileName: "",
      uploadingFile: false,
      openAddFileDialog: false,
      openAddContactDialog: false,
    }
  }

  componentDidMount() {
    this.initValues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.initValues();
    }
  }

  initValues() {
    let { type, value, } = this.props;
    if ([1, 2, 11, 12, 13, 14, 15, 3].includes(type)) {
      this.updateTextValue(value, false, type === 2);
    } else if ([4, 5, 9, 7, 8].includes(type)) {
      this.updateSelectionValue(value, false)
    }
  }

  updateSelectionValue(value, isUpdate) {
    const selectedOption = Array.isArray(value) ? value : []
    this.setState({
      selectedOption,
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedOption, JSON.stringify(this.props.additional_data))
      }
    })
  }

  updateTextValue(value, isUpdate, isDate = false) {
    this.setState({
      isValidationError: false,
      text_value: ![undefined, null, ''].includes(value)
        ? isDate
          ? new Date(value)
          : value
        : isDate
          ? null
          : ''
    }, () => {
      if (this.props.type === 12) this.validateEmail(value)
      if (this.props.type === 14) this.validateURL(value)
      if (isUpdate) {
        this.props.onChange(this.state.text_value, JSON.stringify(this.props.additional_data))
      }
    })
  }

  validateEmail(value) {
    if (value !== '') {
      if (Validator.validateEmail(value) === false) {
        this.setState({
          isValidationError: true
        })
      } else {
        this.setState({
          isValidationError: false
        })
      }
    }
  }

  validateURL(value) {
    if (value !== '') {
      if (Validator.validateURL(value) === false) {
        this.setState({
          isValidationError: true
        })
      } else {
        this.setState({
          isValidationError: false
        })
      }
    }
  }

  onFileChange = async event => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var formData = new FormData();
      formData.append("folder", `/category/file/${this.props.match?.params?.id}`);
      const timestamp = (new Date()).getTime();
      formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf('.') + 1)}`);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;
      this.setState({ uploadingFile: true })

      axios.post(url, formData, {
        headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        this.setState({ openAddFileDialog: true, fileLink: response?.data?.filepath, fileName: file.name })
      }).catch(err => {
        this.setState({ uploadingFile: false })
        if (err.response) {
          this.props.showSnackBar(err.response.data.error, "error", 3000);
        } else if (err.request) {
          this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
        } else {
          this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
        }
      })
      this.setState({ uploadingFile: false })
    }
  }

  render() {
    let { type, additional_data } = this.props;
    const classes = this.props.classes;
    let { text_value, isValidationError, selectedOption } = this.state;

    return (
      <>
        <div className={classes.root}>
          {![7, 8, 9].includes(type) && <p className={classes.inputLabel} style={{ marginTop: 0 }}>Response</p>}
          <div className={classes.fieldContainer}>
            {[1, 12, 14, 15].includes(type) &&
              <>
                <TextField
                  id="outlined-full-width"
                  fullWidth
                  className={classes.inputFormControlText}
                  style={{ marginBottom: 10 }}
                  type={type === 15 ? 'number' : 'text'}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    disableUnderline: true
                  }}
                  value={text_value}
                  onChange={(e) => {
                    this.updateTextValue(e.target.value, true);
                  }}
                />
                {isValidationError && (
                  <Typography style={{ color: 'red' }}>
                    {type === 12 && 'Enter Valid Email'}
                    {type === 14 && 'Enter Valid Url'}
                  </Typography>
                )}
              </>
            }
            {type === 11 &&
              <SlateInputField
                style={{ width: '100%' }}
                as={SlateInputField}
                onChangeEvent={(value) => {
                  this.updateTextValue(value, true);
                }}
                placeholder=""
                initValue={text_value}
                formControlStyle={{ overflow: 'overlay' }}
                textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', margin: "0px", color: "#707070", fontSize: 16 }}
              />
            }
            {type === 13 &&
              <div className={classes.customTypeFields}>
                <PhoneInput
                  country={'us'}
                  value={text_value}
                  onChange={(value) => {
                    this.updateTextValue(value, true);
                  }}
                  required
                  inputProps={{
                    name: text_value,
                    autoFocus: false,
                    id: "outlined-full-width",
                    label: "",
                    style: { width: '100%' },
                    fullWidth: true,
                    margin: "normal",
                    variant: "outlined",
                    InputLabelProps: {
                      shrink: true
                    }
                  }}
                />
              </div>
            }
            {type === 2 &&
              <DatePicker
                selected={text_value}
                onChange={date => {
                  this.updateTextValue(date, true, true);
                }}
                dateFormat="MMM do yyyy"
                portalId="evMetaDialog"
                minDate={null}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={
                  <OutlinedInput
                    readOnlyInput={true}
                    startAdornment={
                      <InputAdornment>
                        <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                      </InputAdornment>
                    }
                  />
                }
              />
            }
            {type === 3 && additional_data.map((item, index) => (
              <Grid container spacing={1} key={index}>
                <Grid item><Radio value={item.value} checked={text_value === item.value} onChange={(event) => this.updateTextValue(event.target.value, true)} /></Grid>
                <Grid item>
                  <div className={classes.inputRow}>
                    <TextField
                      fullWidth
                      className={classes.inputFormControl}
                      value={item.label}
                      InputProps={{ disableUnderline: true }}
                      placeholder="Enter Option"
                      onChange={e => {
                        const newData = additional_data
                        newData[index].label = e.target.value
                        this.props.handleAddOption(newData)
                      }}
                    />
                  </div>
                </Grid>
              </Grid>))
            }
            {[4, 5].includes(type) && (
              <>
                <Select
                  defaultValue={selectedOption}
                  isMulti={type === 5}
                  name={`select-${this.props.key}`}
                  options={additional_data}
                  className="basic-select"
                  classNamePrefix="select"
                  onChange={e => {
                    this.props.handleOnSelectOption(type === 5 ? e : [e])
                  }}
                />
                <br />
                {additional_data.map((data, i) => (
                  <div className={classes.inputContainer} key={`additional_data-${i}`}>
                    <div className={classes.inputRow}>
                      <span className={classes.count}>{i + 1}.</span>
                      <TextField
                        fullWidth
                        className={classes.inputFormControl}
                        value={data.label}
                        InputProps={{ disableUnderline: true }}
                        placeholder="Enter Option"
                        onChange={e => {
                          let data = additional_data
                          data[i] = { ...data[i], label: e.target.value }
                          this.props.handleOptionChange(data, i)
                        }}
                      />
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <Image
                      src={deleteIcon}
                      className={classes.deleteImage}
                      onClick={() => this.props.handleDeleteOption(additional_data.filter((d, vi) => vi !== i))}
                    />
                  </div>
                ))}
                <div style={{ marginBottom: 10 }}>
                  <span
                    onClick={() => {
                      const option = { label: "", value: generate() }
                      this.props.handleAddOption([...additional_data, option])
                    }}
                    style={{
                      fontSize: "12px",
                      color: "#4175DF",
                      cursor: "pointer",
                    }}
                  >
                    + Add option
                  </span>
                </div>
              </>
            )}
            {(type === 7 || type === 8) && (
              <Grid container spacing={2} style={{ margin: 0 }}>
                {selectedOption !== null && selectedOption.length > 0 &&
                  selectedOption.map((user, userIndex) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      lg={2}
                      key={userIndex}
                      alignItems="center"
                      className={classes.cardSection}
                    >
                      <Image
                        src={deleteIcon}
                        className={classes.deleteImageCard}
                        onClick={() => this.props.handleOnSelectOption(selectedOption.filter((o, index) => index !== userIndex))}
                      />
                      <Card variant="outlined" style={{ height: '100%' }}>
                        <CardHeader
                          className={classes.contactCardHeader}
                          style={{ textAlign: "center" }}
                          avatar={
                            <Avatar
                              aria-label="recipe"
                              className={classes.contactCardAvatar}
                            >
                              {user?.name?.slice(0, 1)}
                            </Avatar>
                          }
                          title={user?.name}
                          subheader={user?.email}
                        />
                      </Card>
                    </Grid>
                  ))}
                <Grid
                  item xs={12}
                  sm={4}
                  md={3}
                  lg={2}
                >
                  <Card
                    className={classes.contactCardRoot}
                    variant="outlined"
                    onClick={() => {
                      this.setState({
                        openAddContactDialog: true,
                      })
                    }}
                  >
                    <CardContent>
                      <span className={classes.addNewChamp}>
                        <Image
                          src={AddEvaluationImage}
                          style={{ maxWidth: "58px", cursor: "pointer" }}
                        />
                        <span className={classes.addContact}>
                          {type === 7 ? 'Add User' : 'Add Group'}
                        </span>
                      </span>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
            {type === 9 && (
              <>
                <Grid container alignItems="center" justify="flex-end">
                  <Button className={classes.newUserBtn} disabled={this.state.uploadingFile} onClick={() => this.setState({ openAddFileDialog: true, flieLink: "", fileName: "" })}>
                    + Add Link
                  </Button>
                  &nbsp;
                  <Button className={classes.newUserBtn} disabled={this.state.uploadingFile}>
                    <label htmlFor="UploadContractFile" className={classes.linkText}>
                      + Add File
                      <input id="UploadContractFile" className={classes.uploadImage} onChange={this.onFileChange} type="file" />
                    </label>
                  </Button>
                </Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="40%">Name</TableCell>
                      <TableCell >Link</TableCell>
                      <TableCell width="5%"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedOption.map((option, index) => (
                      <TableRow key={index}>
                        <TableCell>{option.name}</TableCell>
                        <TableCell style={{ wordBreak: 'break-all' }}>
                          <a href={option.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>View File</a>
                        </TableCell>
                        <TableCell width="5%">
                          <IconButton
                            onClick={() => this.props.handleOnSelectOption(selectedOption.filter((o, i) => i !== index))}
                          >
                            <Image
                              src={deleteIcon}
                              className={classes.deleteImage}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </div>
        </div>
        <AddFileDialog
          isOpen={this.state.openAddFileDialog}
          onSubmit={(data) => {
            this.props.handleOnSelectOption([...selectedOption, data])
            this.setState({ openAddFileDialog: false, fileLink: "", fileName: "" })
          }}
          onClose={() => this.setState({ openAddFileDialog: false, fileLink: "", fileName: "" })}
          fileName={this.state.fileName}
          fileLink={this.state.fileLink}
        />
        {[7, 8].includes(type) && (
          <AddUserDialog
            isOpen={this.state.openAddContactDialog}
            type={type}
            onSubmit={(data) => {
              this.props.handleOnSelectOption([...selectedOption, data])
              this.setState({ openAddContactDialog: false })
            }}
            onClose={() => this.setState({ openAddContactDialog: false })}
          />
        )}
      </>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CustomReply));
