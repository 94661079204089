import {
  Chip,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

function SingleSelect(props) {
  const styles = {
    // Styles remain unchanged

  };

  const [selectedOption, setSelectedOption] = useState(
    props.selectedOption?.value || ""
  );
  const [editMode, setEditMode] = useState(props.editMode);
  const [error, setError] = useState(props?.data?.error ? true : false); // New state for error handling

  useEffect(() => {
    setError(props?.data?.error ? true : false);
  }, [props.data.error]);
  useEffect(() => {
    setEditMode(props.editMode);
  }, [props.editMode]);

  // Function to handle validation and update the error state
  const handleValidation = () => {
    const isValid = props.isRequired ? !!selectedOption : true;
    setError(!isValid);
  };

  // useEffect(() => {
  //   handleValidation();
  // }, [selectedOption]);

  return (
    <div style={{ ...styles.customTypeFields }}>
      {!editMode ? (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {" "}
          <div>
            {selectedOption ? (
              <Chip
                label={
                  props.options?.find((o) => o.value === selectedOption)?.label
                }
                style={{ padding: 0, borderRadius: "8px" }}
              />
            ) : (
              <Typography variant="body2">Select</Typography>
            )}
          </div>
        </div>
      ) : (
        <FormControl
          style={{ zIndex: 1, width: "100%" }}
          className={"formcontrol"}
        >
          <Select
            className={'snglSelect'}
            variant="outlined"
            isClearable
            disabled={!editMode || props.isDisabled}
            isMulti={false}
            value={selectedOption}
            style={{ width: '100%', height: '40px' }}
            onChange={(e) => {
              setSelectedOption(e.target.value);
              props?.onSubmit?.({
                value: e.target.value,
                label: props.options.find(
                  (opt) => opt?.value === e.target.value
                ).label,
              });
            }}
            fullWidth
            placeholder={"Select"}
          >
            {props.options.map((option) => {
              return (
                <MenuItem
                  style={{
                    borderBottom: "1px solid whitesmoke",
                  }}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {error && (
        <Typography
          variant="body2"
          color="error"
          style={{ fontFamily: "Manrope" }}
        >
          {props.isRequired ? "This field is required." : "Error"}
        </Typography>
      )}
    </div>
  );
}

export default SingleSelect;
