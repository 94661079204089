import React, { useState, useEffect } from 'react';
import { generate } from 'shortid';
import classnames from "classnames";
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogContent, DialogActions,
    Slide, DialogTitle
} from '@material-ui/core';
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import Alert from '@material-ui/lab/Alert';
import SqureButton from 'Components/Common/SqureButton';
import CommonCss from 'commonCss';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => createStyles({
    formControl: {
        ...CommonCss.formControl,
        '& > div': {
            backgroundColor: 'tarsparent !important',
            border: 'none',
            paddingRight: 0,
        },
        padding: '.375rem',
    },
    formGroup: {
        marginTop: 5,
        marginBottom: 16,
        '& input': {
            color: '#6C6C6C !important'
        }
    },
    dialogContent: {
        minHeight: 170,
        overflowY: 'hidden',
        "& [class*=select__menu]": {
            maxHeight: 100
        }
    },
});

const AddUpdateNotesDialog = ({
    classes,
    progress = false,
    onSubmit,
    onClose,
    readOnly,
    data
}) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState("");

    const handleOnClose = () => {
        setDescription("")
        setTitle("")
        onClose()
    }

    useEffect(() => {
      if(data){
        setTitle(data.title)
        setDescription(data.description)
      }
    }, [data])

    const handleSubmit = () => {
      setError("");
      if(title?.trim()?.length === 0){
        setError("Please enter title");
        return;
      }

      var bodyText = document.querySelectorAll("#userNoteAdd .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "");
      if (String(description).trim().length === 0 || String(bodyText).trim().length === 0 || String(bodyText).indexOf('Enter description') !== -1) {
        setError("Please enter description");
        return;
      }

      onSubmit(title,description);
      handleOnClose()
    }

    return (
        <Dialog
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleOnClose()
                }
            }}
            aria-labelledby=""
            open={true}
            TransitionComponent={Transition}
            scroll={"body"}
            fullWidth={true}
            className={classnames(classes.dialog)}
            id={`addNoteDialog${generate()}`}
        >
            {!readOnly && <DialogTitle id={`form-dialog-title${generate()}`} style={{paddingBottom: 0}}>
                {data ? "Update":"Add"} Note
            </DialogTitle>}
            
            <DialogContent>
                <div>
                    {error?.length > 0 && <Alert variant="filled" severity="error">{error}</Alert>}
                </div>
                <div style={{pointerEvents: readOnly ? 'none' : 'unset'}}>
                  <div className={classes.formGroup}>
                    <label style={{fontSize: '1rem', marginBottom: "8px", fontWeight: 500}}>Title</label>
                    {readOnly ? (<div>{title}</div>) : (
                      <input
                        className={classes.formControl}
                        value={title}
                        placeholder="Enter title"
                        onChange={(e) => setTitle(e.target?.value)}
                      />
                    )}
                  </div>
                  <div className={classes.formGroup} id="userNoteAdd">
                  {readOnly ? (
                    <div>
                      <label style={{fontSize: '1rem', marginBottom: "8px", fontWeight: 500}}>Description</label>
                      <SlateReadonlyField initValue={(description !== null && description) || ""} />
                    </div>
                  ) : (
                    <SlateInputField
                      enableMention={true}
                      placeholder="Enter description"
                      folderPath={`/org_vendor_details/notes/`}
                      isToolBar={true}
                      as={SlateInputField}
                      onChangeEvent={(val) => {
                          setDescription(val);
                      }}
                      initValue={description}
                      textContainerStyle={{ color: "#707070" }}
                      isFile={true}
                    />
                  )}
                  </div>
                </div>
            </DialogContent>
            <DialogActions>
              <div style={{ padding: '10px 24px' }}>
                <SqureButton
                    variant={"outlined"}
                    onClick={() => {
                        handleOnClose()
                    }}
                >
                    {readOnly ? "Close" : "Cancel"}
                </SqureButton>
                &nbsp; &nbsp; &nbsp;
                {!readOnly && <SqureButton
                    disabled={progress}
                    variant={"contained"}
                    onClick={() => {
                        handleSubmit()
                    }}
                >
                    Submit
                </SqureButton>}
              </div>
            </DialogActions>
        </Dialog >
    )
}


export default React.memo(withStyles(styles)(AddUpdateNotesDialog));