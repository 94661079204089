import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import CommonCss from "commonCss";
import Image from 'Components/Common/image';
import { CommonFn } from 'services/commonFn';
import deleteIcon from "assets/images/delete.svg";
import classnames from "classnames";

const connectedProps = (state) => ({
});

const connectionActions = {
}



var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  ...CommonCss.Home,
  evaluateCard: {
    ...CommonCss.Home.evaluateCard,
    alignItems: 'center',
    border:"solid 1px #ccc",
    marginTop:"5px",
    marginBottom:theme.spacing(2)
  },
  btnReview:{
    width: '38px',
    height: '32px',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '13px',
    color: '#626675',
    paddingTop: 2,
    zIndex: 12,
    position: 'relative',
    border: '1px solid #EEEEEE',
    background: '#ffffff'
  },
});

class EvaluationCard extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
  }
  
  render() {
      const classes   =   this.props.classes;

      const evaluation = this.props.evaluation
      return <div className={classes.evaluateCard}    onClick={this.props.onClick}>
              <div className={classes.evaluateText}>
                <p className={classes.evaluateCardTitle}>{evaluation.Name}</p>
                <p className={classes.evaluateCardSubTitle}>Updated At: {CommonFn.formateDate(evaluation.UpdatedAt)}</p>
              </div>
              <div className={classes.evaluateLogo}>
                {evaluation.Products !== null && <div className={classes.productLogoContainer}>
                {
                  evaluation.Products?.slice(0,evaluation.Products.length < 5 ? evaluation.Products.length : 3).map((item, i) => {
                    return(<div className={classes.productLogo} title={item.Name}><Image src={item.ProductLogo} alt={item.Name} title={item.Name} /></div>)
                  })
                }
                {
                  evaluation.Products.length > 4 &&
                  <div className={classes.productLogo} title={evaluation.Products.length - 3 + 'more product'}> + {evaluation.Products.length - 3}</div>
                }
              </div>}
              {this.props.onDelete && <button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={this.props.onDelete}><Image 
                  src={deleteIcon}
                  alt="forgotpassword_image" 
                  className={classes.titleImage}
              /></button>}
            </div>
          </div>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(EvaluationCard));