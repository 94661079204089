import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Button, DialogContent, Typography, Grid, InputAdornment } from '@material-ui/core';
import { hideEvaluationMetaDialog, fetchEvaluationMetaData, updateEvaluationMetaData, fetchSubCategoriesOfEvaluation, fetchEvaluationPreview, fetchEvaluationSelectedFeatures, updateEvaluationSelectedFeatures, deleteEvaluationSelectedFeatures } from "redux/evaluation/metadata/action";
import { fetchEvaluationCart } from "redux/evaluation/cart/action";
import OutlinedInput from "../../../../../../../../Common/Input/OutlinedInput";
import Alert from '@material-ui/lab/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "assets/images/calendar.svg"
import ArrowForward from "assets/images/arrow_forward_white.svg"
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import whiteTick from "assets/images/whitetick.svg";
import Image from 'Components/Common/image.jsx';
import { Step, Steper } from "Components/Common/Steper/index";
import classnames from "classnames";
import { fetchSubCategoryFeature, fetchProductSubCategory } from "redux/product/subcategory/action";
import { fetchMasterTemplate } from "redux/templates/evaluation/master/action";
import { fetchOrgTemplate } from "redux/templates/evaluation/org/action";
import ToolTip from "Components/Common/ToolTip.jsx";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import queryString from 'query-string';

import { addWorkflowComponentUserResponse } from "redux/workflow/action";
import BottomActions from "./BottomActions";

const connectedProps = (state) => ({
    isOpen: state.evaluationMetaData.showDialog,
    evaluation: state.evaluationMetaData.evaluation,
    evaluationId: state.evaluationMetaData.evaluationId,
    fetchProgress: state.evaluationMetaData.fetchProgress,
    errorFetch: state.evaluationMetaData.errorFetch,
    updateProgress: state.evaluationMetaData.updateProgress,
    updateError: state.evaluationMetaData.errorUpdate,
    subCategories: state.productSubCategory.subCategories,
    fetchSubCategoryProgress: state.productSubCategory.fetchSubCategoryProgress,
    subCategoryFeatures: state.productSubCategory.subCategoryFeatures,
    fetchSubCategoryFeaturesProgress: state.productSubCategory.fetchSubCategoryFeaturesProgress,
    subCategoriesOfEvaluationProgress: state.evaluationMetaData.subCategoriesOfEvaluationProgress,
    subCategoriesOfEvaluation: state.evaluationMetaData.subCategoriesOfEvaluation,
    subCategoriesOfEvaluationError: state.evaluationMetaData.subCategoriesOfEvaluationError,
    evaluationPreviewProgress: state.evaluationMetaData.evaluationPreviewProgress,
    evaluationPreview: state.evaluationMetaData.evaluationPreview,
    evaluationPreviewError: state.evaluationMetaData.evaluationPreviewError,
    evaluationSelectedFeaturesError: state.evaluationMetaData.evaluationSelectedFeaturesError,
    evaluationSelectedFeatures: state.evaluationMetaData.evaluationSelectedFeatures,
    evaluationSelectedFeaturesProgress: state.evaluationMetaData.evaluationSelectedFeaturesProgress,
    evaluationUpdateSelectedFeaturesProgress: state.evaluationMetaData.evaluationUpdateSelectedFeaturesProgress,
    evaluationDeleteSelectedFeaturesProgress: state.evaluationMetaData.evaluationDeleteSelectedFeaturesProgress,
    evaluateView: state.applications.evaluateView,
    evaluateViewId: state.applications.evaluateViewId,
    evaluateNeverReview: state.applications.evaluateNeverReview,
    configurations: state.orgUser.fetchAllOrgSettingsSuccess,
    template: state.masterTemplateEvaluation.template,
    fetchTemplateProg: state.masterTemplateEvaluation.fetchTemplateProg,
    orgTemplate: state.orgTemplateEvaluation.template,
    fetchOrgTemplateProg: state.orgTemplateEvaluation.fetchTemplateProg,
    workflowComponentsForms: state.workflow.workflowComponentsForms
});

const connectionActions = {
    hideDialog: hideEvaluationMetaDialog,
    fetchDetails: fetchEvaluationMetaData,
    updateDetails: updateEvaluationMetaData,
    fetchEvaluationCart: fetchEvaluationCart,
    fetchProductSubCategory: fetchProductSubCategory,
    fetchSubCategoryFeature: fetchSubCategoryFeature,
    fetchSubCategoriesOfEvaluation: fetchSubCategoriesOfEvaluation,
    fetchEvaluationPreview: fetchEvaluationPreview,
    fetchEvaluationSelectedFeatures: fetchEvaluationSelectedFeatures,
    updateEvaluationSelectedFeatures: updateEvaluationSelectedFeatures,
    deleteEvaluationSelectedFeatures: deleteEvaluationSelectedFeatures,
    fetchMasterTemplate: fetchMasterTemplate,
    fetchOrgTemplate: fetchOrgTemplate,
    addWorkflowComponentUserResponse: addWorkflowComponentUserResponse
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: "0px !important",
        background: '#FFF',
        margin: '0px auto',
        width: '80%'
    },
    title: {
        fontSize: theme.spacing(2.5),
        marginBottom: theme.spacing(2)
    },
    helptext: {
        fontSize: theme.spacing(1.8)
    },
    alerts: {
        marginTop: theme.spacing(2)
    },
    form: {
        marginTop: theme.spacing(2),
        // width:'60%',
    },
    input: {
        marginBottom: theme.spacing(3),
        "& [class*='MuiFormLabel-root']": {
            color: '#282D30',
            fontWeight: 500,
        },
        "& [class*=MuiOutlinedInput-root]": {
            minHeight: 40,
            color: '#282D30',
            padding: 0
        }
    },
    scheduleTitle: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 500,
        fontSize: theme.spacing(2)
    },
    scheduleTitle1: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 400,
        fontSize: theme.spacing(2)
    },
    actions: {
        marginTop: theme.spacing(3)
    },
    loader: {
        textAlign: "center",
        padding: theme.spacing(10, 0)
    },
    dialog: {
        "& [class*='MuiOutlinedInput-input']": {
            fontSize: theme.spacing(1.8)
        }
    },
    datePickers: {
        "& [class*='MuiFormLabel-root']": {
            color: '#475867'
        },
        "& [class*=MuiOutlinedInput-root]": {
            minHeight: 40,
            color: '#282D30'
        }
    },
    stepSection: {
        display: 'flex',
        "&.evaluate-review-details": {
            "& input, [class*=MuiOutlinedInput-root], .editor-formControl > [class*=MuiPaper-root]": {
                PointerEvent: 'none',
                background: '#f5f5f5'
            }
        },

    },
    stepLeftSection: {
        background: '#20253A',
        boxShadow: '4px 0px 4px #2B2D3703',
        // minWidth: 278,
        // width: 278,
        flex: 1
    },
    stepRightSection: {
        padding: 50,
        // minWidth: 700,
        // maxWidth: 800,
        flex: 3
        // minWidth: 600
    },
    menu: {
        marginTop: theme.spacing(5),
        marginRight: 13,
        "& .step-line": {
            borderLeft: "2px solid #4A87F8"
        },
        "& .step-hideIt": {
            boxShadow: 'none',
            visibility: 'visible !important',
            // backgroundImage: 'none !important'

        },
        "& .step-circle": {
            border: '1px solid #4A87F8',
            background: '#202639',
            boxShadow: 'none',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '4px 4.5px'
        },
        "& .step-completed .step-circle": {
            backgroundColor: '#4A87F8',
            backgroundImage: `url(${whiteTick})`,
        },
        "& [class*=MuiTypography-root]": {
            fontSize: 13,
            textAlign: 'left',
            paddingTop: 1
        }
    },
    outlined: {
        color: "#707070",
        padding: '6px 26px',
        borderColor: "#707070",
        "&:hover": {
            color: "#707070",
            borderColor: "#707070",
        }
    },
    contained: {
        color: '#fff',
        padding: '6px 26px',
        backgroundColor: "#3C3C3C",
        "&:hover": {
            backgroundColor: "#3C3C3C",
        }
    },
    image: {
        width: theme.spacing(3.5),
        marginRight: 20,
        marginTop: 5,
        cursor: 'pointer'
    },
    headSection: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    stepHead: {
        color: '#282D30',
        fontSize: 18,
        margin: 0,
        fontWeight: 500,
        marginBottom: 10
    },
    stepSubHead: {
        color: '#4B4B4B',
        fontSize: 12,
        margin: 0,
        paddingBottom: 20
    },
    inputLabel: {
        display: 'flex',
        border: '2px solid #EEEEEE',
        borderRadius: 10,
        alignItems: 'center',
        margin: '0px 25px 20px 0px',
        padding: 7
    },
    inputLabelSelected: {
        border: '2px solid #4b86f8',
    },
    categoryText: {
        color: '#282D30',
        fontSize: 14,
        fontWeight: 600,
        margin: 0,

    },
    categoryTextSection: {
        paddingLeft: 10
    },
    categorySubText: {
        color: '#282D30',
        fontSize: 12,
        opacity: .6
    },
    categorySection: {
        padding: 50,
        minWidth: 735,
        width: 735,
        minHeight: 800
    },
    categoryImage: {
        paddingLeft: 10,
        width: 57,
        maxWidth: 57,
        minWidth: 57
    },
    rightStepTwo: {
        display: 'flex'
    },
    previewSection: {
        background: '#F7F7F7',
        minWidth: 353,
        padding: '50px 30px'
    },
    previewText: {
        color: '#282D30',
        fontSize: 16,
        fontWeight: 500,
        margin: 0
    },
    previewSubText: {
        marginTop: 0,
        marginBottom: 25,
        fontSize: 12,
        color: '#4B4B4B'
    },
    requirementText: {
        color: '#282D30',
        fontSize: 14,
        fontWeight: 500,
        margin: '0px 0px 10px 0px'
    },
    questionText: {
        color: '#707070',
        fontSize: 13,
        margin: '0px 0px 17px 0px'
    },
    categoryList: {
        marginTop: 20
    },
    textSection: {
        marginTop: 40,
        lineHeight: 1.5
    },
    head: {
        color: '#282D30',
        fontSize: 14,
        marginBottom: 0,
        fontWeight: 500
    },
    featureLabel: {
        border: '1px solid #4A87F8',
        borderRadius: 20,
        padding: '7px 16px',
        marginRight: 25,
        marginTop: 18,
        color: '#4A87F8',
        display: 'inline-flex',
        fontSize: 13,
        cursor: 'pointer',
        transition: 'all'
    },
    iconEle: {
        fontSize: 19,
        marginLeft: 10
    },
    selectedLabel: {
        background: '#4A87F8',
        color: '#fff',
        cursor: 'default'
    },
    removeIcon: {
        display: 'inline-flex',
        cursor: 'pointer',
        marginLeft: 10,
        "& [class*=iconEle]": {
            marginLeft: 0,
        }
    },
    features: {
        overflow: 'auto',
        height: 200,
        marginBottom: 30
    },
    radioBox: {
        width: 13,
        height: 13,
        maxWidth: 13,
        minWidth: 13
    },
    previewInnerSection: {
        maxHeight: 650,
        overflow: 'overlay',
        paddingRight: 10
    },
    backdrop: {
        marginTop: 30,
        height: 100,
        textAlign: 'center'
    },
    editorSection: {
        border: '1px solid #ddd',
        borderRadius: 5,
        marginBottom: 20
    }
});

class EvaluationDetailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            name: "",
            name_error: "",
            problemStatement: "",
            problemStatement_error: "",
            criteriaSelectionStart: null,
            criteriaSelectionStart_error: "",
            criteriaSelectionEnd: null,
            criteriaSelectionEnd_error: "",
            evaluationStart: null,
            evaluationStart_error: "",
            evaluationEnd: null,
            evaluationEnd_error: "",
            step: 1,
            selectedFeatureList: [],
            featureSize: 50,
            featurePage: 0,
            listOfFeature: [],
            featureQuery: '',
            previewSize: 50,
            previewPage: 0,
            previewQuery: '',
            subCategoryId: '',
            stepTwoError: '',
            isUpdatePage: false,
            subCatQuery: null,
            queryParams: queryString.parse(this.props.location.search),
            featureId: [],
            preDefinedFeature: true,
            defaultTemplateType: "MASTER",
            defaultCriteriaIds: []
        }
        this.submitEvaluationMeta = this.submitEvaluationMeta.bind(this);
    }

    componentDidMount() {
        if (this.props.subCategories.length === 0) {
            this.getSubCategory('');
        }
    }


    componentDidUpdate(prevProps) {
        if (!this.state.isUpdatePage && (this.props.location.pathname.indexOf("setup") !== -1 || this.props.location.pathname.indexOf("respond") !== -1)) {
            this.setState({ isUpdatePage: true });
        }

        if (this.props.isOpen === true && prevProps.isOpen === false) {
            this.setState({ listOfFeature: this.props.subCategoryFeatures, step: 1, subCategoryId: '', stepTwoError: '', isUpdatePage: false, subCatQuery: null })
            if (this.props.evaluationId !== null) {
                this.setState({
                    error: "",
                    success: "",
                    name: "",
                    name_error: "",
                    problemStatement: "",
                    problemStatement_error: "",
                    criteriaSelectionStart: null,
                    criteriaSelectionStart_error: "",
                    criteriaSelectionEnd: null,
                    criteriaSelectionEnd_error: "",
                    evaluationStart: null,
                    evaluationStart_error: "",
                    evaluationEnd: null,
                    evaluationEnd_error: "",
                    queryParams: queryString.parse(this.props.location.search),
                    preDefinedFeature: true,
                }, () => {
                    if (Object.keys(this.state.queryParams).length) {
                        if (this.state.queryParams.featureId) {
                            this.setState({ featureId: this.state.queryParams.featureId.split(','), subCategoryId: parseInt(this.state.queryParams.subcategory) });
                        }
                    } else {
                        this.setState({ featureId: [] })
                    }
                })
                this.props.fetchDetails(this.props.evaluationId);
                this.props.fetchEvaluationSelectedFeatures(this.props.evaluationId);
            }
        }

        if (this.props.updateProgress === false && prevProps.updateProgress === true) {
            if (this.props.updateError === null) {
                var responseDatas = [];
                this.props.workflowComponentsForms.forEach((data) => {
                    if (data.IsConfiguration === false) {
                        var obj = {
                            "form_id": data.ID
                        }
                        var value = '';
                        if (data.Name === 'evaluation.name') {
                            value = this.state.name;
                        } else if (data.Name === 'evaluation.goal') {
                            value = this.state.problemStatement;
                        } else if (data.Name === 'evaluation.evaluation_criteria_start_date') {
                            value = this.state.criteriaSelectionStart.toISOString();
                        } else if (data.Name === 'evaluation.evaluation_criteria_end_date') {
                            value = this.state.criteriaSelectionEnd.toISOString();
                        } else if (data.Name === 'evaluation.evaluation_end_date') {
                            value = this.state.evaluationStart.toISOString();
                        } else if (data.Name === 'evaluation_template') {
                            value = this.state.evaluationEnd.toISOString();
                        }
                        obj['value'] = value;
                        responseDatas.push(obj);
                    }
                });

                this.props.addWorkflowComponentUserResponse(this.props.match.params.evaluation_id, this.props.workflowComponentId, {
                    "data": responseDatas
                });

            } else {
                if (this.props.updateError.message !== undefined) {
                    var errorMessage = this.props.updateError.message;
                    if (errorMessage.indexOf("NotFound Error") !== -1) {
                        this.setState({
                            "error": "Failed to update Evaluation Details."
                        })
                    } else {
                        this.setState({
                            "error": "Something went wrong."
                        })
                    }
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }


        if (this.props.fetchProgress === false && prevProps.fetchProgress === true) {
            if (this.props.errorFetch === null) {
                var state = this.state;
                var evaluation = this.props.evaluation;
                if (evaluation.Name !== null) {
                    state.name = evaluation.Name
                }

                if (evaluation.ProblemStatement !== null) {
                    state.problemStatement = evaluation.ProblemStatement
                }

                if (evaluation.CriteriaSelectionSd !== null) {
                    state.criteriaSelectionStart = new Date(evaluation.CriteriaSelectionSd)
                }

                if (evaluation.CriteriaSelectionEd !== null) {
                    state.criteriaSelectionEnd = new Date(evaluation.CriteriaSelectionEd)
                }

                if (evaluation.EvaluationSd !== null) {
                    state.evaluationStart = new Date(evaluation.EvaluationSd)
                }

                if (evaluation.EvaluationEd !== null) {
                    state.evaluationEnd = new Date(evaluation.EvaluationEd)
                }
                this.setState(state);
            } else {
                if (this.props.errorFetch.message !== undefined) {
                    let errorMessage = this.props.errorFetch.message;
                    if (errorMessage.indexOf("NotFound Error") !== -1) {
                        this.setState({
                            "error": "Failed to Fetch Evaluation Details."
                        })
                    } else {
                        this.setState({
                            "error": "Something went wrong."
                        })
                    }
                }
            }
        }
    }

    submitEvaluationMeta() {
        this.setState({
            error: "",
            success: "",
            name_error: "",
            problemStatement_error: "",
            criteriaSelectionStart_error: "",
            criteriaSelectionEnd_error: "",
            evaluationStart_error: "",
            evaluationEnd_error: "",
        });

        var name = this.state.name;
        if (name === null || name.trim().length === 0) {
            this.setState({
                name_error: ` Please specify name of ${this.props.evaluateView || this.props.evaluateNeverReview ? 'review' : 'evaluation'}.`
            })
            return
        }

        var problemStatement = this.state.problemStatement;
        if (problemStatement === null || problemStatement.trim().length === 0) {
            this.setState({
                problemStatement_error: `Please specify ${this.props.evaluateView || this.props.evaluateNeverReview ? 'review' : 'evaluation'} goal.`
            })
            return
        }

        var criteriaSelectionStart = this.state.criteriaSelectionStart;
        if (criteriaSelectionStart === null) {
            this.setState({
                criteriaSelectionStart_error: "Please select."
            })
            return
        }

        var criteriaSelectionEnd = this.state.criteriaSelectionEnd;
        if (criteriaSelectionEnd === null) {
            this.setState({
                criteriaSelectionEnd_error: "Please select."
            })
            return
        }

        var evaluationStart = this.state.evaluationStart;
        if (evaluationStart === null) {
            this.setState({
                evaluationStart_error: "Please select."
            })
            return
        }

        var evaluationEnd = this.state.evaluationEnd;
        if (evaluationEnd === null) {
            this.setState({
                evaluationEnd_error: "Please select."
            })
            return
        }

        this.updateDetails(1);
    }

    updateDetails = (status, options = {}) => {
        this.props.updateDetails(this.props.evaluation.ID, {
            name: this.state.name,
            problemStatement: this.state.problemStatement,
            criteriaSelectionSd: this.state.criteriaSelectionStart.toISOString(),
            criteriaSelectionEd: this.state.criteriaSelectionEnd.toISOString(),
            evaluationSd: this.state.evaluationStart.toISOString(),
            evaluationEd: this.state.evaluationEnd.toISOString(),
            ...options
        })
    }


    updateSelectedFeatures = async (item) => {
        for (var i = 0; i < item.length; i++) {
            // let value = await this.selectFeature(i, { ID: parseInt(item[i]) });
        }
    }

    changeStep = (step) => {
        // this.updateDetails(1, {sub_category_id: this.state.subCategoryId});
        this.submitEvaluationMeta()
    }

    evaluationEndChange = (date) => {
        this.setState({
            evaluationEnd: date
        })
    }

    evaluationStartChange = (date) => {
        this.setState({
            evaluationStart: date,
        })
        if (this.state.evaluationEnd) {
            var diff = moment(date).diff(moment(this.state.evaluationEnd), 'days');
            if (diff > 0) {
                this.setState({
                    evaluationEnd: null,
                })
            }
        }
    }

    criteriaSelectionEndChange = (date) => {
        this.setState({
            criteriaSelectionEnd: date,
        })
        if (this.state.evaluationStart) {
            var diff = moment(date).diff(moment(this.state.evaluationStart), 'days');
            if (diff > 0) {
                this.setState({
                    evaluationStart: null,
                })
            }
        }
    }

    criteriaSelectionStartChange = (date) => {
        this.setState({
            criteriaSelectionStart: date,
        })

        if (this.state.criteriaSelectionEnd) {
            var diff = moment(date).diff(moment(this.state.criteriaSelectionEnd), 'days');
            if (diff > 0) {
                this.setState({
                    criteriaSelectionEnd: null,
                })
            }
        }
    }


    getSubCategory = (e) => {
        if (e === this.state.subCatQuery) {
            return
        }
        this.props.fetchProductSubCategory(0, 0, 100, e, true);
        this.setState({ subCatQuery: e });
    }


    render() {
        const classes = this.props.classes;
        var isUpdatePage = false;
        if (this.props.location.pathname.indexOf("setup") !== -1 || this.props.location.pathname.indexOf("respond") !== -1) {
            isUpdatePage = true;
        }

        return <div>
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classnames(classes.stepSection, this.props.evaluateView ? 'evaluate-review-details' : '')}>
                    {!isUpdatePage && <div className={classes.stepLeftSection}>
                        <div className={classes.menu}>
                            <Steper>
                                <Step completed={this.state.step > 0} label={'Evaluation details'} isFirst={true} circle={this.state.step > 0} />
                            </Steper>
                        </div>
                    </div>}
                    {this.state.step === 1 && <div className={classes.stepRightSection}>
                        {this.props.fetchProgress && <div className={classes.loader}>
                            <CircularProgress />
                        </div>}
                        {!this.props.fetchProgress && <React.Fragment>
                            <div className={classes.headSection}>
                                <div>
                                    <p className={classes.stepHead}>{this.props.evaluateView || this.props.evaluateNeverReview ? 'Review' : 'Evaluation'} details </p>
                                    <p className={classes.stepSubHead}>Provide details about your {this.props.evaluateView || this.props.evaluateNeverReview ? 'review' : 'evaluation'} below so we can help you keep your {this.props.evaluateView || this.props.evaluateNeverReview ? 'review' : 'evaluation'} on track. </p>
                                </div>
                            </div>
                            {/* <Typography variant={"subtitle1"} className={classes.helptext}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse porta iaculis libero. Duis blandit hendrerit tortor, id sagittis sem hendrerit eget. </Typography> */}
                            <div className={classes.alerts}>
                                {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                                {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <OutlinedInput
                                        id={"nameOfEvaluation"}
                                        label={`Name your ${this.props.evaluateView || this.props.evaluateNeverReview ? 'review' : 'evaluation'}`}
                                        value={this.state.name}
                                        onChange={(event) => {
                                            this.setState({ name: event.target.value });
                                        }}

                                        error={this.state.name_error.length > 0}
                                        helperText={this.state.name_error}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <SlateInputField
                                        label={`${this.props.evaluateView || this.props.evaluateNeverReview ? 'Review' : 'Evaluation'} goal`}
                                        error={this.state.problemStatement_error.length > 0}
                                        helperText={this.state.problemStatement_error}
                                        placeholder=""
                                        folderPath={`/evaluation/${this.props?.evaluation?.ID}`}
                                        as={SlateInputField}
                                        onChangeEvent={(value) => {
                                            this.setState({ problemStatement: value });
                                        }}
                                        initValue={this.state.problemStatement}
                                        textContainerStyle={{}} readOnly={this.props.evaluateView ? true : false}
                                        isToolBar={this.props.evaluateView ? false : true} />
                                </div>

                                <Typography variant={"body1"} className={classes.scheduleTitle}>{this.props.evaluateView || this.props.evaluateNeverReview ? 'Review' : 'Evaluation'} schedule</Typography>
                                <Typography variant={"body1"} className={classes.scheduleTitle1}>Criteria selection <ToolTip toolTip="16" /></Typography>
                                <Grid container spacing={4} className={classes.datePickers}>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            selected={this.state.criteriaSelectionStart}
                                            onChange={date => this.criteriaSelectionStartChange(date)}
                                            portalId="evMetaDialog"
                                            dateFormat="MMM do yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <OutlinedInput
                                                    label="Start Date"
                                                    readOnlyInput={true}
                                                    error={this.state.criteriaSelectionStart_error.length > 0}
                                                    helperText={this.state.criteriaSelectionStart_error}
                                                    startAdornment={
                                                        <InputAdornment>
                                                            <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                                        </InputAdornment>
                                                    }
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            selected={this.state.criteriaSelectionEnd}
                                            onChange={date => this.criteriaSelectionEndChange(date)}
                                            dateFormat="MMM do yyyy"
                                            portalId="evMetaDialog"
                                            minDate={this.state.criteriaSelectionStart ? this.state.criteriaSelectionStart : null}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <OutlinedInput
                                                    label="End Date"
                                                    readOnlyInput={true}
                                                    error={this.state.criteriaSelectionEnd_error.length > 0}
                                                    helperText={this.state.criteriaSelectionEnd_error}
                                                    startAdornment={
                                                        <InputAdornment>
                                                            <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                                        </InputAdornment>
                                                    }
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Typography variant={"body1"} className={classes.scheduleTitle1}>{this.props.evaluateView || this.props.evaluateNeverReview ? 'Review' : 'Evaluation'} <ToolTip toolTip="17" /></Typography>
                                <Grid container spacing={4} className={classes.datePickers}>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            selected={this.state.evaluationStart}
                                            onChange={(date) => this.evaluationStartChange(date)}
                                            dateFormat="MMM do yyyy"
                                            portalId="evMetaDialog"
                                            minDate={this.state.criteriaSelectionEnd ? this.state.criteriaSelectionEnd : null}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <OutlinedInput
                                                    label="Start Date"
                                                    readOnlyInput={true}
                                                    error={this.state.evaluationStart_error.length > 0}
                                                    helperText={this.state.evaluationStart_error}
                                                    startAdornment={
                                                        <InputAdornment>
                                                            <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                                        </InputAdornment>
                                                    }
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            selected={this.state.evaluationEnd}
                                            onChange={(date) => this.evaluationEndChange(date)}
                                            dateFormat="MMM do yyyy"
                                            portalId="evMetaDialog"
                                            minDate={this.state.evaluationStart ? this.state.evaluationStart : null}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <OutlinedInput
                                                    label="End Date"
                                                    readOnlyInput={true}
                                                    error={this.state.evaluationEnd_error.length > 0}
                                                    helperText={this.state.evaluationEnd_error}
                                                    startAdornment={
                                                        <InputAdornment>
                                                            <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                                        </InputAdornment>
                                                    }
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            {this.props.updateProgress && <Grid container className={classes.actions}>
                                <Grid item xs={12} style={{ "textAlign": "right" }}>
                                    <CircularProgress />
                                </Grid>
                            </Grid>}
                            {false && !this.props.updateProgress && <Grid container className={classes.actions}>
                                <Grid item xs={6} style={{ "textAlign": "left" }}>
                                    {!this.props.evaluateView && <Button className={classes.outlined} variant="outlined" onClick={() => {
                                        this.props.history.push(`/app/evaluate`)
                                    }}>
                                        Cancel
                                    </Button>}
                                </Grid>
                                <Grid item xs={6} style={{ "textAlign": "right" }}>
                                    {!isUpdatePage && <Button className={classes.contained} endIcon={
                                        <Image src={ArrowForward} alt="arrow forward" />
                                    } onClick={() => this.changeStep(2)}>
                                        Next
                                    </Button>}
                                    {isUpdatePage && ((!this.props.evaluateView && <Button className={classes.contained} onClick={() => this.changeStep(2)}>
                                        Save
                                    </Button>) || (this.props.evaluateView && <Button className={classes.contained} onClick={this.props.hideDialog}>
                                        Close
                                    </Button>))}
                                </Grid>
                            </Grid>}
                        </React.Fragment>}
                    </div>}
                </div>
                <BottomActions
                    submitResponse={() => {
                        this.changeStep(2)
                    }}
                />
            </DialogContent>
        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(EvaluationDetailForm));
