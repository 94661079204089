import { Dialog, DialogContent,Divider, Slide } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { compose } from "recompose";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { showSnackBar } from "redux/snackbar/action";

import { hideCatalogfieldsDialog } from "redux/vendor/action";

import ViewList from "../Catalog/VendorServiceTabular/ViewList";
import SelectFieldComp from "../Catalog/VendorServiceTabular/SelectFieldComp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  showDialog: state.vendors.showDialog,
  fetchAppCatalogFieldsProgress: state.appCatalog.fetchAppCatalogFieldsProgress,
  fetchAppCatalogFieldsError: state.appCatalog.fetchAppCatalogFieldsError,
  appcatalogFields: state.appCatalog.appcatalogFields,
  orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  hideCatalogfieldsDialog: hideCatalogfieldsDialog,
};

var connector = connect(connectedProps, connectionActions);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
      marginBottom: 60,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    body: {
      marginTop: 20,
    },
    actions: {
      textAlign: "center",
    },
    actionBtn: {
      minWidth: "200px",
      marginLeft: 10,
    },
  });

class ViewsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      activeTabValue: 0,
    };
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) { }

  handleChange = (event, value) => {
    this.setState({activeTabValue: value});
  };

  render() {
    const classes = this.props.classes;
    return (
      <Dialog
        aria-labelledby="app-integrationDialog"
        open={this.props.showDialog}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        id="viewDialog"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.hideCatalogfieldsDialog()}>
            <CloseIcon />
          </div>
          <Tabs value={this.state.activeTabValue} onChange={this.handleChange} indicatorColor="primary" textColor="primary" fullWidth style={{width: '80%'}}>
            <Tab value={0} label="Create View" {...a11yProps(0)} />
            <Tab value={1} label="Manage Views" {...a11yProps(1)} />
          </Tabs>
          <Divider/>
          <TabPanel value={this.state.activeTabValue} index={0}>
            <SelectFieldComp createFieldsView={this.props.createFieldsView} />
          </TabPanel>
          <TabPanel value={this.state.activeTabValue} index={1}>
            <ViewList type={this.props.type} />
          </TabPanel>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(ViewsDialog));
