import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import addUsers from "assets/images/add_profile.svg";
import Image from 'Components/Common/image.jsx'
import { showSnackBar } from "redux/snackbar/action";
import classnames from "classnames";
import Tooltip from '@material-ui/core/Tooltip';

// redux
import { showSellerCollaboratorDialog, hideSellerCollaboratorDialog } from "redux/seller/action";
import SearchUserPoper from './SearchUserPoper';

const connectedProps = (state) => ({
  open: state.seller.sellerCollaboratorDialogOpen,
  data: state.seller.sellerCollaboratorDialogData,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  showSellerCollaboratorDialog: showSellerCollaboratorDialog,
  hideSellerCollaboratorDialog: hideSellerCollaboratorDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    display:'flex',
    "& [class*=MuiAvatar-root]": {
      width: 30,
      height: 30,
      fontSize: 14
    },
    "& [class*=MuiAvatarGroup-avatar]": {
      margin: 0
    }
  },
  'addUserIcon': {
    marginRight: theme.spacing(2)
  },
  addUser: {
    width: 23,
    cursor: "pointer",
    marginTop: 1,
    marginLeft: 1
  },
});

class UserGroup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      popoverAnchor: null,
    }
    this.handleClose = this.handleClose.bind(this);
    this.avatarName = this.avatarName.bind(this);
    this.showSellertPopup = this.showSellertPopup.bind(this);
  }

  componentDidMount() {
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  showSellertPopup(){
    alert('Hello');
  }

  handleClose(user) {
    if (user !== null && this.props.onAdd !== undefined && this.props.onAdd !== null) {
      this.props.onAdd(user);
      //this.props.showSnackBar("User added","success",3000);
    } else {
      this.setState({ popoverAnchor: null });
    }
  }

  render() {
    const classes = this.props.classes;
    var editable = true;

    if (this.props.editable !== undefined) {
      editable = this.props.editable
    }

    return <div className={classes.root} style={this.props.style !== undefined ? this.props.style : {}}>
      {this.props.users !== undefined && this.props.users !== null && <AvatarGroup onClick={()=>{
        this.props.showSellerCollaboratorDialog({
          isfromSettingsTemplate: this.props.isfromSettingsTemplate !== undefined ? this.props.isfromSettingsTemplate : false,
          isNew: this.props.isNew !== undefined ? this.props.isNew : false,
          // criterias: this.props.criterias !== undefined ? this.props.criterias : [],
          evaluationId: this.props.evaluationId,
          criteriaId: this.props.criteriaId,
          updateNewCollaborator: this.props.updateNewCollaborator,
          removeCollaborator: this.props.removeCollaborator,
          existingUsers: this.props.users || [],
          selectedUser: this.props.users || [],
          onClose: this.handleClose,
          position: this.props.position || '',
          addUserType : this.props.addUserType || '',
          requirement_info: this.props.requirement_info || {}
        })
      }} max={3} data={this.props.users}>
        {
          this.props.users.map((user, k) => {
            return <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{user.Name +"\n"+ user.Email}</span>} arrow>
            <Avatar key={k}>  {this.avatarName(user.Name)}
            </Avatar></Tooltip>
          })

        }
      </AvatarGroup>}

      {editable && <Image className={classnames(classes.addUser, 'evaluate-add-user')} alt="add user" src={addUsers} onClick={(event) => {
        // this.setState({popoverAnchor:event.currentTarget});
        this.props.showSellerCollaboratorDialog({
          isfromSettingsTemplate: this.props.isfromSettingsTemplate !== undefined ? this.props.isfromSettingsTemplate : false,
          isNew: this.props.isNew !== undefined ? this.props.isNew : false,
          // criterias: this.props.criterias !== undefined ? this.props.criterias : [],
          evaluationId: this.props.evaluationId,
          criteriaId: this.props.criteriaId,
          updateNewCollaborator: this.props.updateNewCollaborator,
          removeCollaborator: this.props.removeCollaborator,
          existingUsers: this.props.users || [],
          selectedUser: this.props.users || [],
          onClose: this.handleClose,
          position: this.props.position || '',
          addUserType : this.props.addUserType || '',
          requirement_info: this.props.requirement_info || {}
        })
      }} />}

      {
          this.props.open &&
          <SearchUserPoper {...this.props.collaboratorDialogData} />
      }
    </div>
  }
}

export default connector(compose(
  withStyles(styles)
)(UserGroup));