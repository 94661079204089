import {
    START_FETCH_DOCUMENT_V2_TYPE, END_FETCH_DOCUMENT_V2_TYPE,
    START_ADD_DOCUMENT_V2_TYPE, END_ADD_DOCUMENT_V2_TYPE,
    START_UPDATE_DOCUMENT_V2_TYPE, END_UPDATE_DOCUMENT_V2_TYPE,
    START_FETCH_DOCUMENT_TYPE_FILES, END_FETCH_DOCUMENT_TYPE_FILES,
    START_ADD_DOCUMENT_TYPE_FILES, END_ADD_DOCUMENT_TYPE_FILES,
    START_UPDATE_DOCUMENT_TYPE_FILES, END_UPDATE_DOCUMENT_TYPE_FILES,
    START_DELETE_DOCUMENT_TYPE_FILES, END_DELETE_DOCUMENT_TYPE_FILES,
    START_DELETE_DOCUMENT_V2_TYPE, END_DELETE_DOCUMENT_V2_TYPE,
    START_ADD_DOCUMENT_TYPE_COLLABORATORS, END_ADD_DOCUMENT_TYPE_COLLABORATORS,
    SHOW_ADD_CONTRACT_DETAILS_DIALOG, HIDE_ADD_CONTRACT_DETAILS_DIALOG,
    SHOW_ADD_DOCUMENT_DETAILS_DIALOG, HIDE_ADD_DOCUMENT_DETAILS_DIALOG,
    START_FETCH_DOCUMENT_TYPE_TABS,
    END_FETCH_DOCUMENT_TYPE_TABS,
    START_ADD_DOCUMENT_TYPE_SECTION,
    END_ADD_DOCUMENT_TYPE_SECTION,
    START_UPDATE_DOCUMENT_TYPE_SECTION,
    END_UPDATE_DOCUMENT_TYPE_SECTION,
    START_DELETE_DOCUMENT_TYPE_SECTION,
    END_DELETE_DOCUMENT_TYPE_SECTION,
    START_ADD_DOCUMENT_TYPE_SECTION_ITEM,
    END_ADD_DOCUMENT_TYPE_SECTION_ITEM,
    START_UPDATE_DOCUMENT_TYPE_SECTION_ITEM,
    END_UPDATE_DOCUMENT_TYPE_SECTION_ITEM,
    START_DELETE_DOCUMENT_TYPE_SECTION_ITEM,
    END_DELETE_DOCUMENT_TYPE_SECTION_ITEM,
} from "./action";

const initState = {
    fetchDocTypesProgress: false,
    fetchDocTypesError: null,
    types: [],

    addDocTypeProgress: false,
    addDocTypeError: null,
    addDocTypeSuccess: null,

    updateDocTypeProgress: false,
    updateDocTypeError: null,
    updateDocTypeSuccess: null,

    fetchDocTypeFilesProgress: false,
    fetchDocTypeFilesError: null,
    docTypeFiles: {},

    addDocTypeFileProgress: false,
    addDocTypeFileError: null,
    addDocTypeFileSuccess: null,

    updateDocTypeFileProgress: false,
    updateDocTypeFileError: null,
    updateDocTypeFileSuccess: null,

    deleteDocTypeFileProgress: false,
    deleteDocTypeFileError: null,
    deleteDocTypeFileSuccess: null,

    deleteDocTypeProgress: false,
    deleteDocTypeError: null,
    deleteDocTypeSuccess: null,

    addDocTypeColloboratorsProgress: false,
    addDocTypeColloboratorsError: null,
    addDocTypeColloboratorsSuccess: null,

    docTypeTabs: {},
    docTypeTabsProgress: false,

    addDocTypeSectionProgress: false,
    addDocTypeSectionError: null,
    addDocTypeSectionSuccess: null,

    updateDocTypeSectionProgress: false,
    updateDocTypeSectionError: null,

    deleteDocTypeSectionProgress: false,
    deleteDocTypeSectionError: null,

    addDocTypeSectionItemProgress: false,
    addDocTypeSectionItemError: null,

    updateDocTypeSectionItemProgress: false,
    updateDocTypeSectionItemError: null,

    deleteDocTypeSectionItemProgress: false,
    deleteDocTypeSectionItemError: null,

    isOpenContractDetailsDialog: false,
    contractDetailsDialog: {},

    isOpenDocumentDetailsDialog: false,
    documenttDetailsDialog: {},

}

export function documentTypeV2Reducer(state = initState, action) {
    switch (action.type) {
        case SHOW_ADD_CONTRACT_DETAILS_DIALOG:
            return {
                ...state,
                isOpenContractDetailsDialog: true,
                contractDetailsDialog: action.payload,
            };
        case HIDE_ADD_CONTRACT_DETAILS_DIALOG:
            return {
                ...state,
                isOpenContractDetailsDialog: false,
                contractDetailsDialog: {},
            };
        case SHOW_ADD_DOCUMENT_DETAILS_DIALOG:
            return {
                ...state,
                isOpenDocumentDetailsDialog: true,
                documentDetailsDialog: action.payload,
            };
        case HIDE_ADD_DOCUMENT_DETAILS_DIALOG:
            return {
                ...state,
                isOpenDocumentDetailsDialog: false,
                documenttDetailsDialog: {},
            };
        case START_FETCH_DOCUMENT_V2_TYPE:
            return {
                ...state,
                fetchDocTypesProgress: true,
                fetchDocTypesError: null,
            }
        case END_FETCH_DOCUMENT_V2_TYPE:
            return {
                ...state,
                fetchDocTypesProgress: false,
                fetchDocTypesError: action.payload.error,
                types: action.payload.success,
            }

        case START_ADD_DOCUMENT_V2_TYPE:
            return {
                ...state,
                addDocTypeProgress: true,
                addDocTypeError: null,
                addDocTypeSuccess: null,
            }
        case END_ADD_DOCUMENT_V2_TYPE:
            return {
                ...state,
                addDocTypeProgress: false,
                addDocTypeError: action.payload.error,
                addDocTypeSuccess: action.payload.success,
            }

        case START_UPDATE_DOCUMENT_V2_TYPE:
            return {
                ...state,
                updateDocTypeProgress: true,
                updateDocTypeError: null,
                updateDocTypeSuccess: null,
            }
        case END_UPDATE_DOCUMENT_V2_TYPE:
            return {
                ...state,
                updateDocTypeProgress: false,
                updateDocTypeError: action.payload.error,
                updateDocTypeSuccess: action.payload.success,
            }
        case START_FETCH_DOCUMENT_TYPE_FILES:
            return {
                ...state,
                fetchDocTypeFilesProgress: true,
                fetchDocTypeFilesError: null,
            }
        case END_FETCH_DOCUMENT_TYPE_FILES:
            const {slug,success,error} = action.payload;
            let data = {...state.docTypeFiles};
            if(slug) {
                data[slug] = success
            }
            return {
                ...state,
                fetchDocTypeFilesProgress: false,
                fetchDocTypeFilesError: error,
                docTypeFiles: data,
            }

        case START_ADD_DOCUMENT_TYPE_FILES:
            return {
                ...state,
                addDocTypeFileProgress: true,
                addDocTypeFileError: null,
                addDocTypeFileSuccess: null,
            }
        case END_ADD_DOCUMENT_TYPE_FILES:
            return {
                ...state,
                addDocTypeFileProgress: false,
                addDocTypeFileError: action.payload.error,
                addDocTypeFileSuccess: action.payload.success,
            }

        case START_UPDATE_DOCUMENT_TYPE_FILES:
            return {
                ...state,
                updateDocTypeFileProgress: true,
                updateDocTypeFileError: null,
                updateDocTypeFileSuccess: null,
            }
        case END_UPDATE_DOCUMENT_TYPE_FILES:
            return {
                ...state,
                updateDocTypeFileProgress: false,
                updateDocTypeFileError: action.payload.error,
                updateDocTypeFileSuccess: action.payload.success,
            }
        case START_DELETE_DOCUMENT_TYPE_FILES:
            return {
                ...state,
                deleteDocTypeFileProgress: true,
                deleteDocTypeFileError: null,
                deleteDocTypeFileSuccess: null,
            }
        case END_DELETE_DOCUMENT_TYPE_FILES:
            return {
                ...state,
                deleteDocTypeFileProgress: false,
                deleteDocTypeFileError: action.payload.error,
                deleteDocTypeFileSuccess: action.payload.success,
            }
        case START_DELETE_DOCUMENT_V2_TYPE:
            return {
                ...state,
                deleteDocTypeProgress: true,
                deleteDocTypeError: null,
                deleteDocTypeSuccess: null,
            }
        case END_DELETE_DOCUMENT_V2_TYPE:
            return {
                ...state,
                deleteDocTypeProgress: false,
                deleteDocTypeError: action.payload.error,
                deleteDocTypeSuccess: action.payload.success,
            }

        case START_ADD_DOCUMENT_TYPE_COLLABORATORS:
            return {
                ...state,
                addDocTypeColloboratorsProgress: true,
                addDocTypeColloboratorsError: null,
                addDocTypeColloboratorsSuccess: null,
            }

        case END_ADD_DOCUMENT_TYPE_COLLABORATORS:
            return {
                ...state,
                addDocTypeColloboratorsProgress: false,
                addDocTypeColloboratorsError: action.payload.error,
                addDocTypeColloboratorsSuccess: action.payload.success,
            }
        case START_FETCH_DOCUMENT_TYPE_TABS:
            return {
                ...state,
                docTypeTabsProgress: true,
                docTypeTabs: state.docTypeTabs,
            };
        
        case END_FETCH_DOCUMENT_TYPE_TABS: {
            const { success } = action.payload;
            let docTypeTabs = {};
            if (success !== null) {
                docTypeTabs = success || docTypeTabs;
            }
            return {
                ...state,
                docTypeTabs,
                docTypeTabsProgress: false,
            };
        }
        case START_ADD_DOCUMENT_TYPE_SECTION:
            return {
                ...state,
                addDocTypeSectionProgress: true,
                addDocTypeSectionSuccess: null,
                addDocTypeSectionError: null,
            };

        case END_ADD_DOCUMENT_TYPE_SECTION: {
            const { success, tabId, error } = action.payload;
            let docTypeTabs = state.docTypeTabs;
            if (success !== null && tabId !== null) {
                if (Array.isArray(docTypeTabs.sections)) {
                docTypeTabs.sections.push(...success);
                } else {
                docTypeTabs.sections = success;
                }
            }
            return {
                ...state,
                docTypeTabs,
                addDocTypeSectionProgress: false,
                addDocTypeSectionError: error,
                addDocTypeSectionSuccess: success,
            };
        }

        case START_UPDATE_DOCUMENT_TYPE_SECTION:
            return {
                ...state,
                updateDocTypeSectionProgress: true,
                updateDocTypeSectionError: null,
            };

        case END_UPDATE_DOCUMENT_TYPE_SECTION: {
            const { success, sectionId, error } = action.payload;
            let docTypeTabs = state.docTypeTabs;
            if (success !== null) {
                const docTypeTabSections = docTypeTabs.sections;
                const sectionIndex = docTypeTabSections.findIndex((obj) => obj.id === sectionId);
                docTypeTabSections[sectionIndex] = success;
                docTypeTabs.sections = docTypeTabSections;
            }
            return {
                ...state,
                docTypeTabs,
                updateDocTypeSectionProgress: false,
                updateDocTypeSectionError: error,
            };
        }

        case START_DELETE_DOCUMENT_TYPE_SECTION:
            return {
                ...state,
                deleteDocTypeSectionProgress: true,
                deleteDocTypeSectionError: null,
            };

            case END_DELETE_DOCUMENT_TYPE_SECTION: {
            const { success, error, sectionId } = action.payload;
            let docTypeTabs = state.docTypeTabs;
            if (success !== null) {
                const docTypeTabSections = [...docTypeTabs.sections];
                const sectionIndex = docTypeTabSections.findIndex((obj) => obj.id === sectionId);
                docTypeTabSections.splice(sectionIndex, 1);
                docTypeTabs.sections = docTypeTabSections;
            }
            return {
                ...state,
                docTypeTabs,
                deleteDocTypeSectionProgress: false,
                deleteDocTypeSectionError: error,
            };
        }

        case START_ADD_DOCUMENT_TYPE_SECTION_ITEM:
            return {
                ...state,
                addDocTypeSectionItemProgress: true,
                addDocTypeSectionItemError: null,
            };

        case END_ADD_DOCUMENT_TYPE_SECTION_ITEM: {
            const { success, sectionId, error } = action.payload;
            let docTypeTabs = state.docTypeTabs;
            if (success !== null && sectionId !== null) {
                if (docTypeTabs.sections) {
                const docTypeTabSections = [...docTypeTabs.sections];
                const sectionIndex = docTypeTabSections.findIndex((obj) => obj.id === sectionId);
                if (Array.isArray(docTypeTabSections[sectionIndex].items)) {
                    docTypeTabSections[sectionIndex].items.push(...success);
                } else {
                    docTypeTabSections[sectionIndex].items = success;
                }
                docTypeTabs.sections = docTypeTabSections;
                }
            }
            return {
                ...state,
                docTypeTabs,
                addDocTypeSectionItemProgress: false,
                addDocTypeSectionItemError: error,
            };
        }

        case START_UPDATE_DOCUMENT_TYPE_SECTION_ITEM:
            return {
                ...state,
                updateDocTypeSectionProgress: true,
                updateDocTypeSectionError: null,
            };

        case END_UPDATE_DOCUMENT_TYPE_SECTION_ITEM: {
            const { success, sectionId, itemId, error } = action.payload;
            let docTypeTabs = state.docTypeTabs;
            if (success !== null) {
                const docTypeTabSections = [...docTypeTabs.sections];
                const sectionIndex = docTypeTabSections.findIndex((obj) => obj.id === sectionId);
                const OrgVendorTabItems = [...docTypeTabSections[sectionIndex].items];
                const itemIndex = OrgVendorTabItems.findIndex((obj) => obj.id === itemId);
                OrgVendorTabItems[itemIndex] = success;
                docTypeTabSections[sectionIndex].items = OrgVendorTabItems;
                docTypeTabs.sections = docTypeTabSections;
            }
            return {
                ...state,
                docTypeTabs,
                updateDocTypeSectionProgress: false,
                updateDocTypeSectionError: error,
            };
        }

        case START_DELETE_DOCUMENT_TYPE_SECTION_ITEM:
            return {
                ...state,
                deleteDocTypeSectionItemProgress: true,
                deleteDocTypeSectionItemError: null,
            };

        case END_DELETE_DOCUMENT_TYPE_SECTION_ITEM: {
            const { success, error, sectionId, itemId } = action.payload;
            let orgVendorDetailTabs_Data = state.docTypeTabs;
            if (success !== null) {
                const docTypeTabSections = [...orgVendorDetailTabs_Data.sections];
                const sectionIndex = docTypeTabSections.findIndex((obj) => obj.id === sectionId);
                const OrgVendorTabItems = [...docTypeTabSections[sectionIndex].items];
                const itemIndex = OrgVendorTabItems.findIndex((obj) => obj.id === itemId);
                OrgVendorTabItems.splice(itemIndex, 1);
                docTypeTabSections[sectionIndex].items = OrgVendorTabItems;
                orgVendorDetailTabs_Data.sections = docTypeTabSections;
            }
            return {
                ...state,
                docTypeTabs: orgVendorDetailTabs_Data,
                deleteDocTypeSectionItemProgress: false,
                deleteDocTypeSectionItemError: error,
            };
        }
        default:
            return state;
    }
}




