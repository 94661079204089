import {ProductFactory} from "../../../services/products";
import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const START_CATEGORY_FETCH     =   "START_CATEGORY_FETCH";
export const END_CATEGORY_FETCH       =   "END_CATEGORY_FETCH";

export const START_ALL_CATEGORY_FETCH     =   "START_ALL_CATEGORY_FETCH";
export const END_ALL_CATEGORY_FETCH       =   "END_ALL_CATEGORY_FETCH";


export const START_CATEGORY_SEARCH     =   "START_CATEGORY_SEARCH";
export const END_CATEGORY_SEARCH       =   "END_CATEGORY_SEARCH";

export const START_NEW_CATEGORY_SEARCH = "START_NEW_CATEGORY_SEARCH"
export const END_NEW_CATEGORY_SEARCH = "END_NEW_CATEGORY_SEARCH"

function startProductCategoryFetch(page){
    return {
        type:START_CATEGORY_FETCH,
        payload:page
    };
}

function endProductCategoryFetch(success,error){
    return {
        type:END_CATEGORY_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchProductCategory(page,pageSize,query){
    return async (dispatch) => {
        dispatch(startProductCategoryFetch(page));
        try{
            let [response,error]    =   await ProductFactory.instance().getCategories(page,pageSize,query);
            if(!(error && error.message && error.message === 'cancelled')) {
                dispatch(endProductCategoryFetch(response,error));
            }
        } catch(e){
            dispatch(endProductCategoryFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startProductCategorySearch(page){
    return {
        type:START_CATEGORY_SEARCH,
        payload:page
    };
}

function endProductCategorySearch(success,error){
    return {
        type:END_CATEGORY_SEARCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function searchProductCategory(page,pageSize,query){
    const api_server = Environment.api_host("SEARCH");
    const timestamp     =   (new Date()).getTime();
    var url   =   `${api_server}/product/categories?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startProductCategorySearch(page));
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url, {
                page:page,
                pageSize:pageSize,
                query:query
            });
            if(!(error && error.message === 'cancelled')) {
                dispatch(endProductCategorySearch(response,error));
            }
        } catch(e){
            dispatch(endProductCategorySearch(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startNewProductCategorySearch(){
    return {
        type:START_NEW_CATEGORY_SEARCH,
        payload: {}
    };
}

function endNewProductCategorySearch(success,error){
    return {
        type:END_NEW_CATEGORY_SEARCH,
        payload:{
            success,error
        }
    };
}


//Thunk to perform the authorizedUser
export  function newSearchProductCategory(query){
    const api_server = Environment.api_host("SEARCH");
    const timestamp     =   (new Date()).getTime();
    var url   =   `${api_server}/v2/cat_subcat_feat?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startNewProductCategorySearch());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url, {
                "text": query,
                "field_types": ["cat","subcat"]
              });
            if(!(error && error.message === 'cancelled')) {
                dispatch(endNewProductCategorySearch(response,error));
            }
        } catch(e){
            dispatch(endNewProductCategorySearch(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startfetchAllcategories(){
    return {
        type:START_ALL_CATEGORY_FETCH,
        payload:{}
    };
}

function endfetchAllcategories(success,error){
    return {
        type:END_ALL_CATEGORY_FETCH,
        payload:{
            success,error
        }
    };
}

export  function fetchAllCategories(page,pageSize,query,additionalParam={}){
    return async (dispatch) => {
        dispatch(startfetchAllcategories());
        try{
            let [response,error]    =   await ProductFactory.instance().getAllCategories(page,pageSize,query,additionalParam);
            if(!(error && error.message && error.message === 'cancelled')) {
                dispatch(endfetchAllcategories(response,error));
            }
        } catch(e){
            dispatch(endfetchAllcategories(null,{
                message:e.message
            }));
            return;
        }
    };
} 