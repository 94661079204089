import { CircularProgress, Dialog, DialogContent, Divider, Slide, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import classnames from "classnames";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { showEvaluationCollaboratorDialog } from "redux/evaluate/action";
import { hideEvaluationCollaboratorDialog } from "redux/evaluate/action";
import { addApprovalCriteria, updateApprovalCriteria, fetchApprovalCriteria } from "redux/evaluation/approval/action";
import { addCriteria, hideEvaluationCriteriaUpdateDialog } from "redux/evaluation/criteria/action";
import { showSnackBar } from "redux/snackbar/action";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import SqureButton from "Components/Common/SqureButton";
import UserGroup from "Components/Common/UserGroup/index";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.evaludationCriteria.showUpdateDialog,
  criteriaData: state.evaludationCriteria.criteriaData,
  evaluationId: state.evaludationCriteria.evaluationId,
  componentId: state.evaludationCriteria.componentId,
  productId: state.evaludationCriteria.productId,
  addCriteriaProgress: state.evaludationCriteria.addCriteriaProgress,
  updateApprovalCriteriaError: state.evaluationApproval.updateApprovalCriteriaError,
  updateApprovalCriteriaProgress: state.evaluationApproval.updateApprovalCriteriaProgress,
  collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
});

const connectionActions = {
  hideDialog: hideEvaluationCriteriaUpdateDialog,
  addCriteria: addCriteria,
  addApprovalCriteria: addApprovalCriteria,
  fetchApprovalCriteria: fetchApprovalCriteria,
  showSnackBar: showSnackBar,
  showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
  updateApprovalCriteria:updateApprovalCriteria
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(6, 12) + " !important",
    },
    modalTitle: {
      fontSize: theme.spacing(2.4),
      marginBottom: theme.spacing(3),
      textAlign: "center",
    },
    helptext: {
      fontSize: theme.spacing(1.8),
    },
    alerts: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    form: {
      marginTop: theme.spacing(2),
      width: "60%",
    },
    input: {
      marginBottom: theme.spacing(3),
    },
    scheduleTitle: {
      display: "block",
      marginBottom: theme.spacing(3),
      fontWeight: 600,
      fontSize: theme.spacing(2),
    },
    scheduleTitle1: {
      display: "block",
      marginBottom: theme.spacing(3),
      fontWeight: 600,
      fontSize: theme.spacing(1.8),
    },
    actions: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      textAlign: "center",
    },
    loader: {
      textAlign: "center",
      padding: theme.spacing(10, 0),
    },
    dialogAction: {
      textAlign: "center",
    },
    addNewButton: {
      fontWeight: 600,
      fontSize: theme.spacing(2),
    },
    actionBtn: {
      minWidth: 110,
    },
    nameYour: {
      "& [class*=MuiInputBase-root]": {
        maxHeight: 40,
        minHeight: 40,
        "& input": {
          fontSize: theme.spacing(1.8),
        },
      },
    },
    nameOfCriteria: {
      "& > div": {
        marginBottom: 0,
      },
    },
    titleHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    titleImage: {
      cursor: "pointer",
    },
    marginBottom: {
      marginBottom: 10,
    },
    label: {
      color: "#6C6C6C",
      fontSize: "1em",
      margin: 0,
      padding: 0,
      marginBottom: "0.8em",
    },
    errorLabel: {
      color: "#f44336",
      fontSize: "0.75em",
      margin: "3px 14px 0px 14px",
    },
  });

class EvaluationCriteriaUpdateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      error: "",
      success: "",
      name: "",
      name_error: "",
      colloborators: [],   
    };

    this.addCriteriaColloborator = this.addCriteriaColloborator.bind(this);
    this.submitResponse = this.submitResponse.bind(this);
    this.cleanError = this.cleanError.bind(this);
    this.cleanState = this.cleanState.bind(this);
    this.removeCriteriaCollaborator = this.removeCriteriaCollaborator.bind(this);
    this.updateCriteriaColloborator = this.updateCriteriaColloborator.bind(this);
  }

  cleanState() {
    this.setState({
      error: "",
      success: "",
      name: "",
      name_error: "",
      colloborators: [],
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      if(this.props.criteriaData){
        let colloborators = [];
        if(this.props.criteriaData?.Approvers?.Users && this.props.criteriaData?.Approvers?.Users.length > 0){
          colloborators.push(...this.props.criteriaData?.Approvers?.Users)
        }
        if(this.props.criteriaData?.Approvers?.Teams && this.props.criteriaData?.Approvers?.Teams.length > 0){
          colloborators.push(...this.props.criteriaData?.Approvers?.Teams)
        }
        this.setState({
          name: this.props.criteriaData?.CriteriaName,
          colloborators: colloborators
        });
      }
    }
    if (this.props.updateApprovalCriteriaProgress === false && prevProps.updateApprovalCriteriaProgress === true) {
      if (this.props.updateApprovalCriteriaError == null) {
        this.props.hideDialog();
        this.cleanState();
        this.props.showSnackBar("Section updated", "success", 3000);
      } else {
        if (this.props.updateApprovalCriteriaError.message !== undefined) {
          this.setState({
            error: "Failed to update Section Details.",
          });
        } else {
          this.setState({
            error: "Something went wrong.",
          });
        }
      }
    }
  }
  cleanError() {
    this.setState({
      name_error: "",
    });
  }

  submitResponse() {
    this.cleanError();

    var name = this.state.name;
    if (name.trim().length === 0) {
      this.setState({
        name_error: "Please enter Approval Name.",
      });
      return;
    }
      let approver_ids = [];
      let team_ids = [];
      if (this.state.colloborators) {
        this.state.colloborators.forEach((data) => {
          if (data.hasOwnProperty("GroupId")) {
            team_ids.push(data.ID);
          } else {
            approver_ids.push(data.ID);
          }
        });
      }

      const data = {
        stakeHolder: name,
        status: this.props.criteriaData?.Status,
        approvers: {
          approver_ids,
          team_ids,
          productID: Number(this.props.productId),
        },
        configID: String(this.props.criteriaData?.ID) || "",
        componentId: Number(this.props.componentId),
      };
      this.props.updateApprovalCriteria(this.props.evaluationId,this.props.criteriaData?.ID, data);
  }


  removeCriteriaCollaborator(userId) {
    var colloborators = this.state.colloborators;
    colloborators = colloborators.filter((u) => {
      if (u.ID === userId) {
        return false;
      }
      return true;
    });
    this.setState({ colloborators }, () => {
      this.props.showEvaluationCollaboratorDialog({
        ...this.props.collaboratorDialogData,
        existingUsers: colloborators,
        selectedUser: colloborators,
      });
    });
  }

  addCriteriaColloborator(user, flag) {
    var colloborators = this.state.colloborators;
    var existing = colloborators.filter((u) => {
      if (u.ID === user.ID) {
        return true;
      }
      return false;
    });
    if (existing.length > 0) {
      return;
    }
    if (flag) {
      colloborators = user;
    } else {
      colloborators.push(user);
    }
    this.setState({ colloborators: colloborators });
    this.props.hideEvaluationCollaboratorDialog();
  }

  updateCriteriaColloborator(user) {
    var colloborators = [...this.state.colloborators];
    var collaborator = colloborators.filter((o) => o.ID === user.ID)[0];
    collaborator.TemplateCriteriaPriority = user.priority;
    this.setState({ colloborators: colloborators });
  }

  render() {
    const classes = this.props.classes;
    return (
      <>
        <Dialog
          onClose={this.props.hideDialog}
          aria-labelledby="app-integrationDialog"
          open={this.props.isOpen}
          TransitionComponent={Transition}
          disableBackdropClick={true}
          fullWidth={true}
          maxWidth={"md"}
          scroll="body"
          id="evMetaDialog"
        >
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Typography variant={"h2"} className={classes.modalTitle}>
              Update Approval Step
            </Typography>
            <div className={classes.alerts}>
              {this.state.error.length > 0 && (
                <Alert variant="filled" severity="error">
                  {this.state.error}
                </Alert>
              )}
              {this.state.success.length > 0 && (
                <Alert variant="filled" severity="success">
                  {this.state.success}
                </Alert>
              )}
            </div>
            <div className={classes.criteriaDetail}>
              <div className={classnames(classes.marginBottom, classes.nameOfCriteria, classes.nameYour)}>
                <OutlinedInput
                  label={"Name your Approval"}
                  error={this.state.name_error.length > 0}
                  helperText={this.state.name_error}
                  value={this.state.name}
                  required={true}
                  autoFocus={true}
                  onChange={(event) => {
                    this.setState({ name: event.target.value });
                  }}
                />
              </div>
              
              <div className={classes.UserGroupSection}>
                {this.state.colloborators && (
                  <UserGroup
                    position={"CriteriaHead"}
                    isNew={true}
                    onAdd={(user, flag) => {
                      this.addCriteriaColloborator(user, flag);
                    }}
                    updatePriority={(user) => {
                      this.updateCriteriaColloborator(user);
                    }}
                    removeCollaborator={this.removeCriteriaCollaborator}
                    criterias={[]}
                    users={this.state.colloborators}
                  />
                )}
              </div>
            </div>
          </DialogContent>
          <Divider />
          
          <DialogContent classes={{ root: classes.dialogAction }}>
            <div className={classes.actions}>
              <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>
                Cancel
              </SqureButton>
              &nbsp;&nbsp;&nbsp;
              {this.props.updateApprovalCriteriaProgress ? (
                <SqureButton cls={classes.actionBtn} variant={"contained"}>
                  Loading...
                </SqureButton>
              ) : (
                <SqureButton
                  cls={classes.actionBtn}
                  variant={"contained"}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.submitResponse();
                  }}
                >
                  {this.props.addCriteriaProgress ? <CircularProgress style={{ width: 27, height: 27 }} /> : "Update"}
                </SqureButton>
              )}
            </div>
          </DialogContent>
        </Dialog>
        
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(EvaluationCriteriaUpdateDialog));
