import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import { CircularProgress, Grid, Typography } from "@material-ui/core";

import {
  productSearch,
} from "redux/product/action";
import { fetchAppCatlogProducts } from "redux/product/appCatalog/action"

const connectedProps = (state) => ({
  productSearchError: state.products.productSearchError,
  fetchAppCatalogProductsProgress: state.appCatalog.fetchAppCatalogProductsProgress,
  fetchAppCatalogProductsError: state.appCatalog.fetchAppCatalogProductsError,
  appCatalogProducts: state.appCatalog.products,
});

const connectionActions = {
  productSearch: productSearch,
  fetchAppCatlogProducts: fetchAppCatlogProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  label: {
    fontSize: "1.4em",
    fontWeight: 600,
    lineHeight: "1.6",
    marginBottom: 10,
  },
});

class CategoryProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    var searchObj = {
      // "search_name": "app_catalogue",
      // "type": "all",
      // "page": 0,
      // "size": 100,
      // "text": "",
      // "filter_fields_value": { "category_ids": [this.props.categoryId] },
      // "sort_field": null,
      // "ascending": true,
      field_setname: 'all',
      text: '',
      filter_fields: { 
        subcategory_ids: [this.props.categoryId],
      },
      analytics_only: false,
      qtype_page: null,
      page_size: 100,
      sort_fields: []
    }
    this.props.fetchAppCatlogProducts(searchObj)
  }

  componentDidUpdate(prevProps) {

  }

  productStates = (obj) => {
    if (obj) {
      const data = Object.keys(obj).map((key) => ({
        StateName: key,
        StateID: obj[key],
      }));
      return data;
    } else {
      return [];
    }
  };

  render() {
    const { classes, appCatalogProducts, fetchAppCatalogProductsProgress } = this.props;

    if (fetchAppCatalogProductsProgress) {
      return <div style={{ textAlign: 'center', marginTop: 20 }}>
        <CircularProgress />
      </div>
    }

    return (
      <div>
        {appCatalogProducts?.featured?.items &&
          appCatalogProducts?.featured?.items.length > 0 && (
            <>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography className={classes.label}>
                  Featured Products
                </Typography>
              </Grid>
              <Grid
                container
                spacing={3}
                style={{ marginBottom: 10 }}
              >
                {appCatalogProducts?.featured?.items.map(
                  (product, index) => (
                    <Grid
                      item
                      lg={2}
                      md={3}
                      sm={4}
                      xs={6}
                      key={
                        "product_" + parseInt(product.id)
                      }
                    >
                      <LayeredCard
                        isSelectable={this.state.isCreateGroup}
                        selected={false}
                        onSelect={(status, productID) =>
                          this.handleCusterProduct(status, product)
                        }
                        title={
                          product.name || product.company_name || ""
                        }
                        subTitle={
                          product.company_name || product.name || ""
                        }
                        requestLink={product.access_link}
                        description={product.description || ""}
                        image={product.logo || ""}
                        toogleCheck={(e) => {
                          this.reviewProduct(product.id);
                        }}
                        onClick={() => { }}
                        numLayer={0}
                        IsExisting
                        showCheckbox={true}
                        id={parseInt(product.id)}
                        uniqueId={"_featured_" + index}
                        type={"product"}
                        customRoute={
                          "/app/products/org-product-details/"
                        }
                        productStates={this.productStates(
                          product.states
                        )}
                        onClickState={(state) =>
                          this.handleOnClickState(state)
                        }
                        isOrgProduct
                        showReactionResponses
                        typeOfBlock="all_product_blocking"
                        typeOfFeatured="featured"
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </>
          )}


        {(appCatalogProducts?.existing?.items &&
          appCatalogProducts?.existing?.items.length > 0) && (
            <>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography className={classes.label}>
                  Existing Products
                </Typography>
              </Grid>

              <Grid container spacing={3}>
                {appCatalogProducts?.existing?.items.map(
                  (product, index) => (
                    <Grid
                      item
                      lg={2}
                      md={3}
                      sm={4}
                      xs={6}
                      key={"product_" + parseInt(product.id)}
                    >
                      <LayeredCard
                        isSelectable={this.state.isCreateGroup}
                        selected={false}
                        onSelect={(status, productID) =>
                          this.handleCusterProduct(status, product)
                        }
                        isActionButtons
                        title={
                          product.name || product.company_name || ""
                        }
                        subTitle={
                          product.company_name || product.name || ""
                        }
                        requestLink={product.access_link}
                        description={product.description || ""}
                        image={product.logo || ""}
                        toogleCheck={(e) => {
                          this.reviewProduct(product.id);
                        }}
                        onClick={() => { }}
                        numLayer={0}
                        IsExisting
                        showCheckbox={true}
                        id={parseInt(product.id)}
                        uniqueId={"_orgj_" + index}
                        type={"product"}
                        customRoute={
                          "/app/products/org-product-details/"
                        }
                        productStates={this.productStates(
                          product.states
                        )}
                        onClickState={(state) =>
                          this.handleOnClickState(state)
                        }
                        typeOfBlock="all_product_blocking"
                        isOrgProduct
                        typeOfFeatured="featured"
                        showReactionResponses
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </>
          )}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CategoryProducts));
