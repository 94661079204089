import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { ListItemText, List, ListItem, DialogContent, CircularProgress, Typography, Dialog, Button, Slide } from '@material-ui/core';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({

});

const connectionActions = {

}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    button: {
        textAlign: 'right',
        marginBottom: 10
    },
    title: {
        fontSize: 18,
    },
    subHead: {
        marginTop: 20,
        fontSize: 15,
        fontWeight: 500
    },
    listItemText:{
        fontSize:'1em',
      },
    list: {
        maxHeight: 300,
        overflowY: 'auto',
        margin: 14,
        "& a": {
            padding: 0,
        }
    }
});

class QuestionWithDiffTypeDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
        }
       
    }

    componentDidMount() {
        
    }


    render() {
        const { data,classes} = this.props;
        return <Dialog 
                    onClose={this.props.onClose} 
                    open={true}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"sm"}
                    scroll={"body"}
                    >
                        <DialogContent classes={{root:classes.dialogContent}}>
                            <Typography variant={"h6"} className={classes.title}>The types of questions given below are different</Typography>
                            {this.props.usedPlacesProgress ? <div style={{textAlign:'center',marginTop:10}}>
                                <CircularProgress/>
                            </div> : (
                                <List className={classes.list}>
                                    {data?.map((item,i)=> (
                                      <ListItemText primary={`${i+1}. ${item.description}`} classes={{primary:classes.listItemText}}/>
                                    ))} 
                                </List>
                            )}
                            <div className={classes.button}>
                                <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.onClose}>Close</Button>
                            </div> 
                        </DialogContent>
                </Dialog>
    }
}


export default connector(compose(
    withStyles(styles)
)(QuestionWithDiffTypeDialog));