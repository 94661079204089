import isHotkey from 'is-hotkey';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Editor, Transforms, Range, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact, ReactEditor } from 'slate-react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/styles/makeStyles';
import classnames from "classnames";

import {
  Element as SlateElementNew,
} from 'slate'
import { withLinks } from './SlateImage';

import MenuItem from '@material-ui/core/MenuItem';

import { HOTKEYS, SlateElement, toggleMark, Portal, SlateLeaf } from './SlateEditorUtil';
import { SlateToolbar } from './SlateToolbar';
import ToolTip from "Components/Common/ToolTip.jsx";
import { searchUser } from "redux/usersearch/action";
// import withEmbeds from '../SlateEditorNew/plugins/withEmbeds';
// import withLinks from '../SlateEditorNew/plugins/withLinks';
// import { renderLeaf } from './Common/renderLeaf';
import withTables from "../SlateEditorNew/plugins/withTable";
import { withHtml } from './CopyPaster';
const connectedProps = (state) => ({
  progress: state.userSearch.searchProgress,
  users: state.userSearch.users,
});

const connectionActions = {
  searchUser: searchUser,
}

var connector = connect(connectedProps, connectionActions);

const SlateInputField1 = ({
  initValue,
  required,
  fullWidth,
  placeholder,
  textContainerStyle,
  enableMention,
  ...props
}) => {
  let editorInitialValue = [{
    type: 'paragraph',
    children: [{ text: '' }],
  }];
  const classes = useStyles(props);
  const [slateValue, setSlateValue] = useState(editorInitialValue);
  const ref = useRef()
  const [target, setTarget] = useState()
  // const [index, setIndex] = useState()
  const [search, setSearch] = useState('')
  // const [isHoverId, setIsHoverId] = useState('');

  let usersList = props.users || [];

  // const handleMouseEnter = (id) => {
  //   setIsHoverId(id);
  // };

  // const handleMouseLeave = () => {
  //   setIsHoverId('');
  // };

  const editor = useMemo(
    () => withImages(withTables(withVisualizations(withAutomationVariables(withVariables(withMentions(withLinks(withHistory(withReact(createEditor()))))))))),
    []
  )


  useEffect(() => {
    if (initValue && typeof initValue === 'string') {
      try {
        editorInitialValue = JSON.parse(initValue);
        if (typeof editorInitialValue === 'number') {
          editorInitialValue = [
            {
              type: 'paragraph',
              children: [{ text: JSON.stringify(editorInitialValue) }],
            }
          ];
        }
      } catch (e) {
        editorInitialValue = [
          {
            type: 'paragraph',
            children: [{ text: initValue }],
          }
        ];
        // console.log(e)
      }
      // console.log(JSON.stringify(editorInitialValue),"11111")
      setSlateValue(editorInitialValue);
    }
    if (initValue === 0) {
      editorInitialValue = [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        }
      ];
      // console.log(editorInitialValue,"11111")
      Transforms.select(editor, [0])
      setSlateValue(editorInitialValue);
    }
    if (!initValue) {
      editorInitialValue = [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        }
      ];
      // console.log(editorInitialValue,"11111")
      // Transforms.select(editor, [0])
      setSlateValue(editorInitialValue);
    }


  }, [initValue]);

  useEffect(() => {
    if (target) {
      props.searchUser(search);
      const el = ref.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      el.style.left = `${rect.left + window.pageXOffset}px`
    }
  }, [search, target])


  const renderElement = useCallback((props) => <SlateElement {...props} />, []);
  const renderLeaf = useCallback((props) => <SlateLeaf {...props} />, []);

  const findUrlsInText = (text) => {
    const urlRegex =
      /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
    const matches = text.match(urlRegex);
    return matches ? matches.map((m) => [m.trim(), text.indexOf(m.trim())]) : [];
  };

  const myDecorator = ([node, path]) => {
    const nodeText = node.text;
    if (!nodeText) return [];
    const urls = findUrlsInText(nodeText);
    return urls.map(([url, index]) => {
      return {
        anchor: {
          path,
          offset: index,
        },
        focus: {
          path,
          offset: index + url.length,
        },
        decoration: "link",
      };
    });
  };

  let autoFocus = props.autoFocus !== undefined ? props.autoFocus : true;

  const isTableCellActive = editor => {
    const [addNewLine] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElementNew.isElement(n) && n.type === 'table-cell',
    })
    return !!addNewLine
  }

  return (
    <div style={props.style} className={classnames(classes.input, { [classes.nobg]: props.roundedCorner, [classes.inline]: props.labelStyle === "inline" })}>
      {props.label !== null && <InputLabel
        className={classes.label}
        error={props.error}
        htmlFor={props.id}>{props.label}{required && <span style={{ 'color': 'red' }}>&nbsp;*</span>} {props.toolTip && <ToolTip toolTip={props.toolTip} />}</InputLabel>}
      <FormControl
        className={classnames(classes.formControl, 'editor-formControl')}
        style={props.formControlStyle}
        required={required}
        fullWidth={fullWidth}
      >
        <Paper className={classes.paper} style={props.paperStyle}>
          <Slate
            editor={editor}
            value={slateValue}
            onChange={(value) => {
              // console.log(value,'FinalValue')
              setSlateValue(value);
              props.onChangeEvent(JSON.stringify(value))
              if (enableMention) {
                const { selection } = editor
                if (selection && Range.isCollapsed(selection)) {
                  const [start] = Range.edges(selection)
                  const wordBefore = Editor.before(editor, start, { unit: 'word' })
                  const before = wordBefore && Editor.before(editor, wordBefore)
                  const beforeRange = before && Editor.range(editor, before, start)
                  const beforeText = beforeRange && Editor.string(editor, beforeRange)
                  const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
                  const after = Editor.after(editor, start)
                  const afterRange = Editor.range(editor, start, after)
                  const afterText = Editor.string(editor, afterRange)
                  const afterMatch = afterText.match(/^(\s|$)/)

                  if (beforeMatch && afterMatch) {
                    setTarget(beforeRange)
                    setSearch(beforeMatch[1])
                    // setIndex(0)
                    return
                  } else {
                    setTarget(null)
                    // setIsHoverId('');
                  }
                }

              }

            }}
          >
            <Editable
              autoFocus={autoFocus}
              renderLeaf={renderLeaf}
              placeholder={placeholder}
              readOnly={props.readOnly}
              renderElement={renderElement}
              decorate={myDecorator}
              className={classnames(props.isSmallContainer !== undefined && props.isSmallContainer === true ? classes.smallEditableField : classes.editableField, 'editor-editableField')}
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }

                if (isTableCellActive(editor) && event?.keyCode === 13) {
                  event.stopPropagation()
                  console.log(event, 'For TableCell')
                  // editor.insertText('and')
                }
                // console.log(event,'For 111')

                // if(enableMention){
                //   onMentionHandler(event);
                // }
              }}
              onBlur={e => console.log(e)}
              style={{ minHeight: 300, margin: 10, ...textContainerStyle }}
            />
            {enableMention && target && (
              <Portal>
                <div
                  ref={ref}
                  style={{
                    top: '-9999px',
                    left: '-9999px',
                    position: 'absolute',
                    zIndex: 9999,
                    background: 'white',
                    borderRadius: '4px',
                    boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                  }}
                  data-cy="mentions-portal"
                >

                  {props.progress ? <MenuItem>Loading...</MenuItem>
                    : usersList.length > 0 && usersList.map((user, i) => (
                      <MenuItem
                        key={i}
                        style={{
                          alignItems: 'start',
                          flexDirection: 'column',
                        }}
                        // onMouseEnter={()=>handleMouseEnter(user.ID)}
                        // onMouseLeave={()=>handleMouseLeave}
                        onClick={() => {
                          Transforms.select(editor, target)
                          insertMention(editor, user.Name, user.ID)
                          setTarget(null)
                          // setIsHoverId('');
                        }}
                      >
                        <div className={classes.name}>{user.Name}</div>
                        <div className={classes.email}>{user.Email}</div>

                      </MenuItem>
                    ))}
                </div>
              </Portal>
            )}
            {props.isToolBar && <SlateToolbar {...props} />}
          </Slate>
        </Paper>
      </FormControl>
      {props.helperText?.trim().length > 0 && <FormHelperText
        className={classes.helperText}
        error={props.error}
      >{props.helperText}</FormHelperText>}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    width: '100%',
    borderRadius: 4,
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  editableField: props => ({
    minHeight: '200px !important',
    margin: '5px 0px 5px 10px !important',
    // overflow: 'overlay',
    maxHeight: props.readOnly ? 'none' : '500px',
    overflow: props.readOnly ? 'unset' : 'auto',
    // maxHeight: '200px',
  }),
  smallEditableField: {
    minHeight: '100px !important',
    margin: '5px 0px 5px 10px !important',
    // overflow: 'overlay',
    maxHeight: '100px',
    overflow: 'auto'
    // maxHeight: '200px',
  },
  "input": {
    textAlign: "left",
    marginBottom: theme.spacing(3.5),
    background: "#FFF"
  },

  "label": {
    marginBottom: theme.spacing(1.6),
    fontSize: theme.spacing(2),
    color: "#6C6C6C"
  },
  outlinedInput: {
    fontSize: theme.spacing(2.2),
    color: "#5F5F5F",
    padding: theme.spacing(1.5),
  },
  helperText: {
    fontSize: theme.spacing(1.5),
  },
  nobg: {
    background: "none"
  },
  inputRoot: {
    background: "#fff",
    minHeight: "50px",
    width: "100%",
  },
  paper: {
    boxShadow: 'none'
  },
  name: {
    fontSize: "14px",
    fontWeight: '500',
  },
  email: {
    fontSize: "11px",
  },

}));

SlateInputField1.defaultProps = {
  isToolBar: true,
  readOnly: false,
  customBtn: false,
  isShowTableOption: false,
  isShowColorOption: true,
  isShowAddVariableOption: false,
  isShowAddVisualizationOption: false,
  isShowAlignmentOption: false,
  isShowImageOption: false,
  isShowHeadingOption: false,
  enableMention: false,
  autoFocus: false,
  workflowId: 0,
  customBtnHTML: <></>,
  isShowAddAutomationVariableOption: false,
  automationVariables: []
};

export const SlateInputField = connector(compose(withRouter)(SlateInputField1));

const insertMention = (editor, character, id) => {
  const mention = {
    type: 'mention',
    character,
    id: id,
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
  ReactEditor.focus(editor)
}

const withMentions = editor => {
  const { isInline, isVoid } = editor
  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element)
  }
  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element)
  }
  return editor
}

const withVariables = editor => {
  const { isInline, isVoid } = editor
  editor.isInline = element => {
    return element.type === 'variable' ? true : isInline(element)
  }
  editor.isVoid = element => {
    return element.type === 'variable' ? true : isVoid(element)
  }
  return editor
}

const withAutomationVariables = editor => {
  const { isInline, isVoid } = editor
  editor.isInline = element => {
    return element.type === 'automationVariable' ? true : isInline(element)
  }
  editor.isVoid = element => {
    return element.type === 'automationVariable' ? true : isVoid(element)
  }
  return editor
}

const withVisualizations = editor => {
  const { isInline, isVoid } = editor
  editor.isInline = element => {
    return element.type === 'visualization' ? true : isInline(element)
  }
  editor.isVoid = element => {
    return element.type === 'visualization' ? true : isVoid(element)
  }
  return editor
}

const withImages = editor => {
  const { isInline, isVoid } = editor

  editor.isInline = element => {
    return element.type === 'image' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  return editor
}

