import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment"

export const SHOW_ADD_UPDATE_TASK_DIALOG     =   "SHOW_ADD_UPDATE_TASK_DIALOG";
export const HIDE_ADD_UPDATE_TASK_DIALOG       =   "HIDE_ADD_UPDATE_TASK_DIALOG"

export const START_ADD_NEW_PRODUCT_TASK     =   "START_ADD_NEW_PRODUCT_TASK";
export const END_ADD_NEW_PRODUCT_TASK       =   "END_ADD_NEW_PRODUCT_TASK";

export const START_UPDATE_PRODUCT_TASK     =   "START_UPDATE_PRODUCT_TASK";
export const END_UPDATE_PRODUCT_TASK       =   "END_UPDATE_PRODUCT_TASK";

export const START_FETCH_PRODUCT_TASKS     =   "START_FETCH_PRODUCT_TASKS";
export const END_FETCH_PRODUCT_TASKS       =   "END_FETCH_PRODUCT_TASKS";

export const START_DELETE_PRODUCT_TASK     =   "START_DELETE_PRODUCT_TASK";
export const END_DELETE_PRODUCT_TASK       =   "END_DELETE_PRODUCT_TASK";

export const START_FETCH_PRODUCT_TASKS_ONLY = "START_FETCH_PRODUCT_TASKS_ONLY"
export const END_FETCH_PRODUCT_TASKS_ONLY = "END_FETCH_PRODUCT_TASKS_ONLY"

export const START_FETCH_PRODUCT_RISK_TASKS = "START_FETCH_PRODUCT_RISK_TASKS"
export const END_FETCH_PRODUCT_RISK_TASKS = "END_FETCH_PRODUCT_RISK_TASKS"

export const START_FETCH_PRODUCT_FOLLOW_UP_TASKS = "START_FETCH_PRODUCT_FOLLOW_UP_TASKS"
export const END_FETCH_PRODUCT_FOLLOW_UP_TASKS ="END_FETCH_PRODUCT_FOLLOW_UP_TASKS"

export  function showAddUpdateTaskDialog(data={},isNew=true){
    return     {
        type:SHOW_ADD_UPDATE_TASK_DIALOG,
        payload:{data,isNew}
    };
}

export  function hideAddUpdateTaskDialog(){
    return     {
        type:HIDE_ADD_UPDATE_TASK_DIALOG,
        payload:{}
    };
}

function startAddNewProductTask() {
    return {
        type: START_ADD_NEW_PRODUCT_TASK,
        payload: {}
    };
}

function endAddNewProductTask(success, error) {
    return {
        type: END_ADD_NEW_PRODUCT_TASK,
        payload: {
            success, error
        }
    };
}

export function addNewProductTask(productId, data = {}) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/${productId}/add-task?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewProductTask());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewProductTask(response, error));
        } catch (e) {
            dispatch(endAddNewProductTask(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateProductTask() {
    return {
        type: START_UPDATE_PRODUCT_TASK,
        payload: {}
    };
}

function endUpdateProductTask(from,success, error) {
    return {
        type: END_UPDATE_PRODUCT_TASK,
        payload: {
            success, error, from
        }
    };
}

export function updateProductTask(productId,taskId,data,from) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/${productId}/task/${taskId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateProductTask());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateProductTask(from,response, error));
        } catch (e) {
            dispatch(endUpdateProductTask(from,null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchProductTasks(){
    return {
        type:START_FETCH_PRODUCT_TASKS,
        payload:{}
    };
}

function endFetchProductTasks(success,error){
    return {
        type:END_FETCH_PRODUCT_TASKS,
        payload:{
            success,error
        }
    };
}

export  function fetchProductTasks(productId,queryParam){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/${productId}/fetch-task?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductTasks());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endFetchProductTasks(response,error));
        } catch(e){
            dispatch(endFetchProductTasks(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startDeleteProductTask() {
    return {
        type: START_DELETE_PRODUCT_TASK,
        payload: null
    };
}

function endDeleteProductTask(taskId, type,from,success, error) {
    return {
        type: END_DELETE_PRODUCT_TASK,
        payload: {
            success, error, type, from, taskId
        }
    };
}

export function deleteProductTask(productId,taskId, type=1, from='EVALUATION') {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/${productId}/task/${taskId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteProductTask());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteProductTask(taskId, type,from,response, error));
        } catch (e) {
            dispatch(endDeleteProductTask(taskId, type,from,null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchProductTaskOnly(){
    return {
        type:START_FETCH_PRODUCT_TASKS_ONLY,
        payload:{}
    };
}

function endFetchProductTaskOnly(success,error){
    return {
        type:END_FETCH_PRODUCT_TASKS_ONLY,
        payload:{
            success,error
        }
    };
}

export  function fetchProductTaskOnly(productId,queryParam){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/${productId}/fetch-task?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductTaskOnly());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endFetchProductTaskOnly(response,error));
        } catch(e){
            dispatch(endFetchProductTaskOnly(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startFetchProductRiskTasks(){
    return {
        type:START_FETCH_PRODUCT_RISK_TASKS,
        payload:{}
    };
}

function endFetchProductRiskTasks(success,error){
    return {
        type:END_FETCH_PRODUCT_RISK_TASKS,
        payload:{
            success,error
        }
    };
}

export  function fetchProductRiskTasks(productId,queryParam){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/${productId}/fetch-task?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductRiskTasks());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endFetchProductRiskTasks(response,error));
        } catch(e){
            dispatch(endFetchProductRiskTasks(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startFetchProductFollowUpTasks(){
    return {
        type:START_FETCH_PRODUCT_FOLLOW_UP_TASKS,
        payload:{}
    };
}

function endFetchProductFollowUpTasks(success,error){
    return {
        type:END_FETCH_PRODUCT_FOLLOW_UP_TASKS,
        payload:{
            success,error
        }
    };
}

export  function fetchProductFollowUpTasks(productId,queryParam){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/${productId}/fetch-task?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductFollowUpTasks());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endFetchProductFollowUpTasks(response,error));
        } catch(e){
            dispatch(endFetchProductFollowUpTasks(null,{
                message:e.message
            }));
            return;
        }
    };
} 
