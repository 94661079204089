import React from "react";
import Image from "Components/Common/image.jsx";
import start from "assets/images/workflow/start.svg";
import decision from "assets/images/workflow/decision.svg";
import workflow from "assets/images/workflow/workflow.svg";
import workflowDark from "assets/images/workflow/workflow-dark.svg";
import requirementSelection from "assets/images/workflow/requirementsSelection.svg";
import questionnaire from "assets/images/workflow/questionnaire.svg";
import evaluationResponse from "assets/images/workflow/evaluationResponse.svg";
import decisionComponent from "assets/images/workflow/decision-component.svg";
import component from "assets/images/workflow/component.svg";
import end from "assets/images/workflow/end.svg";
import approval from "assets/images/workflow/approval.svg";
import form from "assets/images/workflow/form.svg";
import chat from "assets/images/workflow/chat.svg";
import ScheduleIcon from '@material-ui/icons/Schedule';

const CustomImage = ({ slug, className = "", onClick = (e) => { e.stopPropagation() } }) => {
  return (
    <>
      {slug === "START" && <Image src={start} className={className} alt={slug} onClick={onClick} />}
      {slug === "WORKFLOW" && <Image src={workflow} className={className} alt={slug} onClick={onClick} />}
      {slug === "WORKFLOW_DARK" && <Image src={workflowDark} className={className} alt="workflow" onClick={onClick} />}

      {slug === "REQUIREMENTS_SELECTION" && (
        <Image src={requirementSelection} className={className} alt={slug} onClick={onClick} />
      )}
      {slug === "PRODUCT_SHORTLIST" && <Image src={approval} className={className} alt={slug} onClick={onClick} />}
      {slug === "QUESTIONNAIRE" && <Image src={questionnaire} className={className} alt={slug} onClick={onClick} />}
      {slug === "FEEDBACK" && <Image src={chat} className={className} alt={slug} onClick={onClick} />}
      {slug === "FINISH" && <Image src={end} className={className} alt={slug} onClick={onClick} />}
      {slug === "APPROVAL_WORKFLOW" && <Image src={decision} className={className} alt={slug} onClick={onClick} />}
      {slug === "FORM" && <Image src={form} className={className} alt={slug} onClick={onClick} />}
      {slug === "INTEGRATION_FORM" && <Image src={form} className={className} alt={slug} onClick={onClick} />}
      {slug === "DECISION_COMPONENT" && <Image src={decisionComponent} className={className} alt={slug} onClick={onClick} />}
      {slug === "COMPONENT" && <Image src={component} className={className} alt={slug} onClick={onClick} />}
      {slug === "EMAIL" && <Image src={evaluationResponse} className={className} alt={slug} onClick={onClick} />}
      {slug === "SUMMARY" && (
        <Image src={requirementSelection} className={className} alt={slug} onClick={onClick} />
      )}
      {slug === "WORKFLOW_SUMMARY" && (
        <Image src={requirementSelection} className={className} alt={slug} onClick={onClick} />
      )}
      {slug === "SELLER_INSTRUCTION" && (
        <Image src={requirementSelection} className={className} alt={slug} onClick={onClick} />
      )}
      {slug === "PROJECT_TIMELINE" && (
        <ScheduleIcon style={{width:20}} className={className} alt={slug} onClick={onClick}/>
        // <Image src={requirementSelection} className={className} alt={slug} onClick={onClick} />
      )}

    </>
  );
};

export default CustomImage;
