import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AppBarV2 from "../../../AppBarV2";
import { Grid } from "@material-ui/core";
import Integration from "assets/images/integration.svg";
import Templates from "assets/images/templates.svg";
import CardAction from "Components/Common/CardAction";
import { showAddCompanyProfileDialog } from "redux/companyProfileSettings/action";
import { showEvaluationSelectDialog } from "redux/templates/evaluation/action"
import CommonCss from 'commonCss';
import classnames from "classnames";
import { isEnabledEvaluate, isEnabledProjectTracking } from "util/Common";
import { getNewPlatform } from 'util/Environment';

const connectedProps = (state) => ({
    authUser: state.authUser,
    orgUser: state.orgUser,
    drawer_expanded: state.drawer.expanded,
});

const connectionActions = {
    showAddCompanyProfileDialog: showAddCompanyProfileDialog,
    showEvaluationSelectDialog: showEvaluationSelectDialog,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    "actionContainer": {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6),

        "& [class*='MuiGrid-item']": {
            padding: theme.spacing(2)
        }
    },

    "cardAction": {
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        height: '100%'
    },

    "Primary": {
        textAlign: 'center',
        fontSize: theme.spacing(2.4),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5)
    },

    "Secondary": {
        textAlign: 'center',
    },

    "Content": {
        padding: theme.spacing(2, 2, 0, 2),
        flex: 'inherit'
    },
    rootContainer: CommonCss.rootContainer
});

class SettingsCard extends React.Component {

    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.state = {
            error: '',
            sm1: window.matchMedia('(min-width: 576px) and (max-width: 667.98px)').matches,
            sm2: window.matchMedia('(min-width: 668px) and (max-width: 767.98px)').matches
        }
    }

    updateDimensions = () => {
        let sm1 = window.matchMedia('(min-width: 576px) and (max-width: 667.98px)').matches;
        let sm2 = window.matchMedia('(min-width: 668px) and (max-width: 767.98px)').matches;
        this.setState({
            sm1: sm1,
            sm2: sm2
        });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const classes = this.props.classes;
        let cardContent = [
            { primary: "General", secondary: "", image: Templates, activeImage: false, link: '/app/settings/configurations/general' },
            { primary: "Application Catalog", secondary: "", image: Integration, activeImage: false, link: '/app/settings/configurations/org-product-details' },
            { primary: "Document Types", secondary: "", image: Integration, activeImage: false, link: '/app/settings/configurations/document-types' },
        ];

        const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'DisableCatalogFeature')
        const EnableVendorCatalogV2 = isEnabledProjectTracking(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableVendorCatalogV2");
        console.log("EnableVendorCatalogV2", EnableVendorCatalogV2)

        const cardVendorContent = [
            { primary: "General", secondary: "Manage your organization settings", image: Templates, activeImage: false, link: '/app/settings/configurations/general' },
            { primary: "Application Catalog", secondary: "Manage your application catalog", image: Integration, activeImage: false, link: '/app/settings/configurations/org-product-details' },
            { primary: "Vendor Catalog", secondary: "Manage your vendor catalog", image: Integration, activeImage: false, isExternal: EnableVendorCatalogV2, link: EnableVendorCatalogV2 ? `${getNewPlatform()}/entity-type-configurations/vendor/-` :'/app/settings/configurations/org-vendor-details' },
            // { primary: "Service Catalog", secondary: "", image: Integration, activeImage: false, link: '/app/settings/configurations/org-service-details' },
            { primary: "Service Catalog", secondary: "Manage your service catalog", image: Integration, activeImage: false, link: '/app/settings/configurations/org-vendor-service-details' },
            { primary: "Document Types", secondary: "Manage your document types", image: Integration, activeImage: false,isExternal: EnableVendorCatalogV2, link: EnableVendorCatalogV2 ? `${getNewPlatform()}/settings/configurations/CONTRACTS_TYPES` : '/app/settings/configurations/document-types' },
        ];

        if(!DisableVendor){
            cardContent = cardVendorContent;
        }

        // const {drawer_expanded}= this.props;
        const { sm1, sm2 } = this.state;
        const sm = sm1 ? 12 : sm2 ? 6 : 4;

        return <div className={'PageSettings'}>
            <AppBarV2 title="Configurations" withBack={true} />
            <div className={classnames(classes.actionContainer, classes.rootContainer)}>
                <Grid container spacing={3}>
                    {cardContent.map((e, index) => {
                        if (e.isHide) {
                            return null
                        }
                        return (
                            <Grid item xs={12} sm={sm} md={3} lg={3} xl={3} key={index}>
                                <CardAction
                                    link={e.link}
                                    isExternal={e.isExternal}
                                    image={e.image}
                                    primary={e.primary}
                                    secondary={e.secondary}
                                    activeImage={e.activeImage}
                                    cardActionClass={classes.cardAction}
                                    primaryClass={classes.Primary}
                                    secondaryClass={classes.Secondary}
                                    contentClass={classes.Content}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(SettingsCard));