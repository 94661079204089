import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Paper, CircularProgress } from "@material-ui/core";
import { currencyCodeMap } from "util/Currency";
import { fetchOrgCatalogDetailsTabs } from "redux/vendor/vendorCatalog/action";
// import { addCatalogViews } from "redux/vendor/action";
// import { showCatalogfieldsDialog, hideCatalogfieldsDialog } from "redux/vendor/action";
import Contracts from "../Contracts";
import { CommonFn } from "services/commonFn";
import { fetchVendorCatalogViews, fetchServiceCatalogViews } from "redux/vendor/action";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
  user: state.authUser.user,
  orgUser: state.orgUser,

  vendorCatalogViews: state.vendors.vendorCatalogViews,
  fetchVendorCatalogViewsProgress: state.vendors.fetchVendorCatalogViewsProgress,
  fetchVendorCatalogViewsError: state.vendors.fetchVendorCatalogViewsError,

  serviceCatalogViews: state.vendors.serviceCatalogViews,
  fetchServiceCatalogViewsProgress: state.vendors.fetchServiceCatalogViewsProgress,
  fetchServiceCatalogViewsError: state.vendors.fetchServiceCatalogViewsError,

  fetchVendorServiceCatalogProgress: state.vendorServiceCatalog.fetchVendorServiceCatalogProgress,
  fetchVendorServiceCatalogError: state.vendorServiceCatalog.fetchVendorServiceCatalogError,
  VendorServiceCatalog: state.vendorServiceCatalog.VendorServiceCatalog,

  fetchVisualizationVendorCatalogProgress: state.vendorCatalog.fetchVisualizationVendorCatalogProgress,
  fetchVisualizationVendorCatalogError: state.vendorCatalog.fetchVisualizationVendorCatalogError,
  tableVisualizationDatas: state.vendorCatalog.tableVisualizationDatas,

  orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,
  orgCatalogDetailTabsProgress: state.vendorCatalog.orgCatalogDetailTabsProgress,
  orgCatalogDetailTabsError: state.vendorCatalog.orgCatalogDetailTabsError,
});

const connectionActions = {
  fetchVendorCatalogViews: fetchVendorCatalogViews,
  fetchServiceCatalogViews: fetchServiceCatalogViews,
  showSnackBar: showSnackBar,
  fetchOrgCatalogDetailsTabs: fetchOrgCatalogDetailsTabs,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    workflowPaper: {
      padding: 20,
    },
    paper: {
      width: "100%",
      height: "100%",
      overflow: "auto",
    },
    tableContainer: {
      // height: "100%",
    },
    visuallyHidden: {
      marginLeft: 5,
    },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: "1 1 100%",
    },
    columnDrawer: {
      width: 400,
      right: "0",
      padding: "15px",
      zIndex: "9",
    },
    bottomActions: {
      textAlign: "right",
    },
    autoSelectInput: {
      "& [class*=MuiInputBase-input]": {
        padding: "10px !important",
      },
    },
    searchBox: {
      padding: theme.spacing(3, 3),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    table: {},
    topHeader: {
      display: "flex",
    },
    tabsSection: {
      flex: 1,
    },
    showBtnSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 1.3rem",
      width: "100%",
    },
    answerBox: {
      backgroundColor: "#fff",
      borderBottom: "1px solid #f1f1f1",
      boxShadow: "none",
    },
    noDataField: {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
    filterTitle: {
      color: "#282D30",
      fontWeight: 400,
      fontSize: theme.spacing(2.2),
    },
    formControl: {
      width: "250px",
    },
    textWrap: {
      "& [class*='editor-editableField']": {
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
        minHeight: "unset !important",
        "& p": {
          marginTop: 0,
        },
      },
    },
    statusFilter: {
      display: "flex",
      alignItems: "center",
      gap: 20,
    },
  });

class TableContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "",
      orderBy: "",
      selected: [],
      dense: false,
      page: 0,
      rowsPerPage: 5,
      query: "",
      rows: [],
      columns: [],
      invertDirection: {
        asc: "desc",
        desc: "asc",
      },
      isShowSubMenu: false,
      subMenuEl: null,
      selectedItemIds: [],
      selectedItems: [],
      headers: [],
      totalRows: 0,
      basicInfo: [],
      selectedView: null,
      showContractView: false,
    };
  }

  componentDidMount() {
    this.props.fetchOrgCatalogDetailsTabs(this.props.type === "vendor" ? "vendor" : "vendorservice");
  }

  componentDidUpdate(prevProps) {
    if (!this.props.orgCatalogDetailTabsProgress && prevProps.orgCatalogDetailTabsProgress) {
      this.initialiseData();
    }

    if (!this.props.orgCatalogDetailTabsError && prevProps.orgCatalogDetailTabsError) {
      this.initialiseData();
    }

    if (!this.props.fetchVendorServiceCatalogProgress && prevProps.fetchVendorServiceCatalogProgress) {
      this.initialiseData();
    }

    if (!this.props.fetchVendorServiceCatalogError && prevProps.fetchVendorServiceCatalogError) {
      this.initialiseData();
    }

    if (!this.props.fetchVisualizationVendorCatalogProgress[this.props.Vid] && prevProps.fetchVisualizationVendorCatalogProgress[this.props.Vid]) {
      this.initialiseData();
    }

    if (!this.props.fetchVisualizationVendorCatalogError && prevProps.fetchVisualizationVendorCatalogError) {
      this.initialiseData();
    }

    if (!this.props.fetchVendorCatalogViewsProgress && prevProps.fetchVendorCatalogViewsProgress) {
      if (!this.props.fetchVendorCatalogViewsError) {
        // if(!this.state.selectedView){
        //   const ViewID = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "VendorTabularView");
        //   this.selectView(ViewID);
        // }else {
        this.selectView(this.props.defaultSelectedView);
        // }
      }
    }

    if (!this.props.fetchServiceCatalogViewsProgress && prevProps.fetchServiceCatalogViewsProgress) {
      if (!this.props.fetchServiceCatalogViewsError) {
        // if(!this.state.selectedView){
        //   const ViewID = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "ServiceTabularView");
        //   this.selectView(ViewID);
        // }else{
        this.selectView(this.props.defaultSelectedView);
        // }
      }
    }
  }

  selectView = (id) => {
    let selectedView = [];
    if (this.props.type === "vendor") {
      selectedView = this.props.vendorCatalogViews.find((item) => item.id === id);
    } else {
      selectedView = this.props.serviceCatalogViews.find((item) => item.id === id);
    }
    let views = selectedView?.item_slugs?.map((o) => o) || [];
    this.setState({ selectedItems: views, selectedView: id }, () => {
      this.initialiseData();
    });
  };

  initialiseData = () => {
    if (!this.props.orgCatalogDetailTabs?.length) return;

    let tabs = this.props.orgCatalogDetailTabs;

    let selectedItems = this.state.selectedItems;
    let tempSlug = [];
    let headers = [...this.state.basicInfo];
    tabs.forEach((tab) => {
      tab.sections.forEach((section) => {
        section.items.forEach((item) => {
          if (selectedItems?.includes(item.slug) && !tempSlug.includes(item.slug)) {
            tempSlug.push(item.slug);
            let itemName = item.name;
            if (!itemName || itemName.length === 0) {
              itemName = section.name;
            }
            headers.push({
              tab_slug: tab.slug,
              id: item.id,
              slug: item.slug,
              item_name: itemName,
              type: item.type,
              is_default: item.is_default,
            });
          }
        });
      });
    });

    this.setState({
      headers: headers,
    });
  };

  setOrder = (order) => {
    this.setState({
      order: order,
    });
  };

  setOrderBy = (orderBy) => {
    this.setState({
      orderBy: orderBy,
    });
  };

  setPage = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.props.onChangePage(page, this.state.rowsPerPage);
      }
    );
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState(
      {
        rowsPerPage: rowsPerPage,
      },
      () => {
        this.props.onChangePage(this.state.page, rowsPerPage);
      }
    );
  };

  updateSearchQuery = (searchQuery) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: 5,
        query: searchQuery,
        order: "",
        orderBy: "",
      },
      () => {
        this.props.onChangeSearch(0, 5, searchQuery);
      }
    );
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property ? this.state.invertDirection[this.state.order] : "asc";
    this.setState(
      {
        page: 0,
      },
      () => {
        this.setOrder(isAsc);
        this.setOrderBy(property);
        this.props.onChangeSort(isAsc, property);
      }
    );
  };

  routeChange = (item, header) => {
    let redirectPath = "";
    if (this.props.type === "vendor") {
      redirectPath = `/app/vendors/org-vendor-details/${item.id}#${header.tab_slug}`;
    } else {
      redirectPath = `/app/vendors/org-service-details/${item.id}#${header.tab_slug}`;
    }
    this.props.history.push(redirectPath);
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
      () => {
        this.props.onChangePage(this.state.page, this.state.rowsPerPage);
      }
    );
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  printData = (data, type, headerIndex) => {
    try {
      let header = this.state.headers[headerIndex];

      if (!header) {
        return data;
      }

      if (!data) {
        return "";
      }
      let value = data;
      if (type === 2) {
        return CommonFn.formateDate(value, true);
      } else if (type === 4 || type === 5 || type === 15) {
        let data1 = value?.map((o) => o.label);
        return String(data1);
      } else if (type === 7 || type === 8 || type === 10) {
        if (Array.isArray(value)) {
          let data2 = value?.map((o) => o.name);
          return String(data2);
        } else {
          return "";
        }
      } else if (type === 11) {
        return (
          <SlateInputField
            readOnly={true}
            isToolBar={false}
            placeholder=""
            style={{
              margin: 0,
            }}
            as={SlateInputField}
            initValue={value}
            formControlStyle={{ border: "none", borderColor: "#fff", overflow: "hidden", margin: "0px !important" }}
            textContainerStyle={{ minHeight: "10px !important", margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }}
          />
        );
      } else if ([1, 3, 12, 13, 14, 19].includes(type)) {
        return value;
      } else if (type === 20) {
        return (
          <div>
            {value && value.value && value.value !== "" && <div>{currencyCodeMap[value?.type?.value] + "" + value?.value}</div>}
            {value && value.value === "" && <div>-</div>}
          </div>
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const classes = this.props.classes;

    const { order, orderBy, page, dense, headers } = this.state;
    const { fetchVisualizationVendorCatalogProgress, fetchVendorServiceCatalogProgress } = this.props;
    let isLoading = fetchVisualizationVendorCatalogProgress[this.props.Vid] || fetchVendorServiceCatalogProgress;

    let data = {};
    let allViews = [{ id: "contract_view", name: "Contract View" }];
    if (this.props.type === "vendor" && this.props.tableVisualizationDatas) {
      allViews.push(...this.props.vendorCatalogViews);
      data = this.props.tableVisualizationDatas[this.props.Vid] || {};
    } else if (this.props.type === "vendorservice" && this.props.VendorServiceCatalog) {
      allViews.push(...this.props.serviceCatalogViews);
      data = this.props.VendorServiceCatalog || {};
    } else {
      return null;
    }

    return (
      <>
        <Paper className={classes.paper}>
          {this.props.defaultSelectedView === "contract_view" ? (
            <Contracts Vid={this.props.Vid} defaultSelectedView={this.props.defaultSelectedView} />
          ) : (
            <>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} aria-label="enhanced table">
                  <TableHead>
                    <TableRow>
                      {headers.map((headCell, i) => {
                        return (
                          <TableCell
                            key={i}
                            align={"left"}
                            padding={"normal"}
                            style={{ left: "unset", whiteSpace: "nowrap", minWidth: headCell.width ? headCell.width : 200 }}
                            sortDirection={orderBy === headCell.item_name ? order : false}
                          >
                            {headCell.sort && (
                              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={this.createSortHandler(headCell.id)}>
                                {headCell.item_name}
                              </TableSortLabel>
                            )}
                            {!headCell.sort && !headCell.isEmpty && <span>{headCell.item_name}</span>}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isLoading &&
                      this.state.selectedItems?.length > 0 &&
                      data?.items?.map((row, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={index} style={{ cursor: "pointer" }}>
                            {headers.map((header, i) => {
                              return (
                                <TableCell onClick={(event) => this.routeChange(row, header)}>
                                  <div className={classes.textWrap}>{this.printData(row[header.slug], header.type, i)}</div>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}

                    {!isLoading && this.state.selectedItems?.length === 0 && (
                      <TableRow>
                        <TableCell className={classes.nameCol} align="center">
                          Select view to see data
                        </TableCell>
                      </TableRow>
                    )}

                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={headers?.length} className={classes.nameCol}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}

                    {!isLoading && data?.items?.length <= 0 && (
                      <TableRow>
                        <TableCell colSpan={headers.length} align="center" className={classes.nameCol}>
                          No Items
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={data.total || 0}
                rowsPerPage={5}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </>
    );
  }
}

TableContent.defaultProps = {
  type: "vendor",
  isShowSearch: false,
  searchPlaceHolder: "Search by Name",
  page: 0,
  rowsPerPage: 5,
  query: "",
  activeWorkflowTab: 1,
  tableMaxHeight: "550px",
};

export default connector(compose(withRouter, withStyles(styles))(TableContent));
