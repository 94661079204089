import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { addWorkflowComponentUserResponse } from "redux/workflow/action";
import CommonCss from 'commonCss';
import DoneIcon from "@material-ui/icons/Done";
import { Select as MaterialSelect,FormControl,MenuItem } from "@material-ui/core";

import {
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, IconButton, Paper,
  Button
} from '@material-ui/core';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";

import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";

import { fetchDocTypes, addDocType, updateDocType, deleteDocType, addDocTypeColloborators } from 'redux/documentTypesV2/action'
import { showSnackBar } from "redux/snackbar/action";
import { showEvaluationCollaboratorDialog, hideEvaluationCollaboratorDialog } from "redux/evaluate/action";

const connectedProps = (state) => ({
  OrgID: state.authUser.user.OrgID,
  user: state.authUser.user,
  evaluation: state.evaluationMetaData.evaluation,

  fetchDocTypesProgress: state.documentTypeV2.fetchDocTypesProgress,
  fetchDocTypesError: state.documentTypeV2.fetchDocTypesError,
  types: state.documentTypeV2.types,

  addDocTypeProgress: state.documentTypeV2.addDocTypeProgress,
  addDocTypeError: state.documentTypeV2.addDocTypeError,
  addDocTypeSuccess: state.documentTypeV2.addDocTypeSuccess,

  updateDocTypeProgress: state.documentTypeV2.updateDocTypeProgress,
  updateDocTypeError: state.documentTypeV2.updateDocTypeError,
  updateDocTypeSuccess: state.documentTypeV2.updateDocTypeSuccess,

  deleteDocTypeProgress: state.documentTypeV2.deleteDocTypeProgress,
  deleteDocTypeError: state.documentTypeV2.deleteDocTypeError,
  deleteDocTypeSuccess: state.documentTypeV2.deleteDocTypeSuccess,

  addDocTypeColloboratorsProgress: state.documentTypeV2.addDocTypeColloboratorsProgress,
  addDocTypeColloboratorsError: state.documentTypeV2.addDocTypeColloboratorsError,
  addDocTypeColloboratorsSuccess: state.documentTypeV2.addDocTypeColloboratorsSuccess,

  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,

});

const connectionActions = {
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  fetchDocTypes: fetchDocTypes,
  addDocType: addDocType,
  updateDocType: updateDocType,
  deleteDocType: deleteDocType,
  showSnackBar: showSnackBar,
  showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
  addDocTypeColloborators: addDocTypeColloborators
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  root: {
    "& .react-datepicker-popper": {
      zIndex: 2222,
    },
    "& .react-datepicker__input-container > div": {
      display: "flex",
      minWidth: 300,
      marginBottom: 0,
      alignItems: "baseline",
      "& [class*=MuiInputBase-root]": {
        maxHeight: 33,
        minHeight: 33,
        width: 166,
        "& input": {
          fontSize: theme.spacing(1.8),
          // width: 107,
          textAlign: "center",
        },
      },
      "& [class*=MuiFormLabel-root]": {
        marginBottom: 0,
        marginRight: 29,
      },
    },
    "[class*=MuiMenuItem-root]": {
      color: "#707070",
      fontSize: theme.spacing(1.8),
    },
  },
  approversFlex: {
    display: 'flex',
    justifyContent: 'end',
    "& [class*=MuiAvatar-root]": {
      width: 25,
      height: 25,
      fontSize: 14
    },
    "& [class*=MuiAvatarGroup-avatar]": {
      margin: 0
    }
  },
  topActions: {
    textAlign: 'right',
    marginBottom: 20
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  nameCol: {
    width: '30%'
  },
  completedCol: {
    width: '20%'
  },
  collabCol: {
    width: '10%',
    textAlign: 'right'
  },
  commentCol: {
    width: '30%'
  },
  actionCol: {
    textAlign: 'center',
    width: '10%'
  },
  stepDetails: {
    minHeight: "25px !important",
    padding: 6,
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    color: "#5F5F5F",
    fontSize: "1.1em",
    width: '95%',
  },
  date_popper: {
    zIndex: 2222
  },
  formControlSel: {
    width: '100%'
  }
});

class VendorDocumentTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoading: true,
      page: 0,
      pageSize: 10,
      isLoading: true,
      totalAlerts: 0,
      types: [],
      typeOptions:[{
        label:'Contract',
        value:'contract'
      },{
        label:'Invoice',
        value:'invoice'
      },
      // {
      //   label:'Form',
      //   value:'form'
      // },{
      //   label:'Other',
      //   value:'other'
      // }
      ]
    }
  }

  componentDidMount() {
    this.props.fetchDocTypes()
  }

  componentDidUpdate(prevProps) {
    const { fetchDocTypesProgress, fetchDocTypesError,
      addDocTypeProgress, addDocTypeError,
      updateDocTypeProgress, updateDocTypeError,
      deleteDocTypeProgress, deleteDocTypeError, addDocTypeColloboratorsProgress,
      addDocTypeColloboratorsError
    } = this.props;

    if (!fetchDocTypesProgress && prevProps.fetchDocTypesProgress) {
      if (fetchDocTypesError === null) {
        this.initializeDocType()
      } else {
        this.setState({
          isDataLoading: false
        })
      }
    }

    if (!addDocTypeProgress && prevProps.addDocTypeProgress) {
      if (addDocTypeError === null) {
        this.props.showSnackBar("Type added successfully.", "success", 3000)
        this.removeFromEdit('ADD')
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!updateDocTypeProgress && prevProps.updateDocTypeProgress) {
      if (updateDocTypeError === null) {
        this.props.showSnackBar("Type updated successfully.", "success", 3000)
        this.removeFromEdit('UPDATE')
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!deleteDocTypeProgress && prevProps.deleteDocTypeProgress) {
      if (deleteDocTypeError === null) {
        console.log(this.props.deleteDocTypeSuccess)
        this.props.showSnackBar("Type deleted successfully.", "success", 3000)
        if (this.state.deleteItemIndex !== null) {
          this.deleteLocally()
        }
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!addDocTypeColloboratorsProgress && prevProps.addDocTypeColloboratorsProgress) {
      if (addDocTypeColloboratorsError === null) {
        this.props.showSnackBar("Type updated successfully.", "success", 3000)
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

  }

  initializeDocType() {
    const { types } = this.props;
    console.log(types,'typesDatas')
    if (types) {
      let newData = types.map((o) => {
        return {
          type: o?.type,
          name: o?.name,
          isEdit: false,
          id: o?.id,
          sequence: o?.sequence,
          tab_id: o?.tab_id,
        }
      })
      this.setState({
        types: newData,
        isDataLoading: false
      })
    }
  }

  removeFromEdit(type) {
    let { types } = this.state;
    let successData = '';
    let index = null;
    if (type === 'ADD') {
      successData = this.props.addDocTypeSuccess;
      index = types.findIndex(o => o.name === successData.name);
    } else {
      successData = this.props.updateDocTypeSuccess;
      index = types.findIndex(o => o.id === successData.id);
    }
    if (index !== -1 && index !== null) {
      let type = types[index];
      type.isEdit = false;
      type.id = successData.id;
      type.tab_id = successData.tab_id;
      this.setState({
        types: types
      })
    }
  }

  deleteLocally() {
    const { deleteItemIndex, types } = this.state;
    types.splice(deleteItemIndex, 1);
    this.setState({
      types: types,
      deleteItemIndex: null
    })
  }

  addNewDocumentType() {
    let { types } = this.state;
    var obj = {
      name: '',
      type: 'invoice',
      isEdit: true,
      sequence: types.length + 1,
      id: null,
    }
    // types.push(obj)
    // types.insert(0, obj);
    types.splice(0, 0, obj);
    this.setState({
      types: types
    })
  }

  updateFieldValue(field, newValue, index) {
    let { types } = this.state;
    let type = types[index];
    type[field] = newValue;
    this.setState({
      types: types
    })
  }

  handleAddAndUpdate(index) {
    let { types } = this.state;
    let typeData = types[index];

    if (typeData.type === '') {
      this.props.showSnackBar("Please enter type details.", "error", 3000)
      return;
    }


    let params = {
      name: typeData.name,
      type: typeData.type,
      sequence: typeData.sequence,
    }

    if (typeData.id === null) {
      this.props.addDocType(params)
    } else {
      this.props.updateDocType(typeData.id, params)
    }
  }

  handleEdit(index) {
    let { types } = this.state;
    let type = types[index];
    type.isEdit = true;
    this.setState({
      types: types
    })
  }

  handleDelete(index) {
    let { types } = this.state;
    let type = types[index];
    if (type.id === null) {
      types.splice(index, 1);
      this.setState({
        types: types
      })
    } else {
      this.setState({
        deleteItemIndex: index
      }, () => {
        this.props.deleteDocType(type.id)
      })
    }
  }


  render() {
    const { classes } = this.props;
    const { isDataLoading, types,typeOptions } = this.state;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <>
        <div>
          <div className={classes.topActions}>
            <Button className={classes.newUserBtn} onClick={() => {
              this.addNewDocumentType()
            }}>
              + New Type
            </Button>
          </div>
          <div className={classes.timelineDatas}>
            <Paper style={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer style={{ maxHeight: '78vh' }}>
                <Table className={classes.mandatoryTable} size="small" stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.nameCol}>Name</TableCell>
                      <TableCell className={classes.nameCol}>Type</TableCell>
                      <TableCell className={classes.actionCol}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isDataLoading && types.map((task, index) => {
                      if (task.isEdit) {
                        return <TableRow key={'task_' + task.id}>
                          <TableCell className={classes.nameCol}>
                            <input
                              placeholder={'Enter Type Name'}
                              value={task.name} className={classes.stepDetails}
                              onChange={(event) => {
                                this.updateFieldValue('name', event.target.value, index)
                              }} />
                          </TableCell>
                          <TableCell className={classes.nameCol}>
                            <FormControl variant="outlined" className={classes.formControlSel} size="small">
                              <MaterialSelect value={task.type} onChange={(e) => {
                                this.updateFieldValue('type', e.target.value, index)

                              }}>
                                {typeOptions.map((op) => {
                                  return <MenuItem value={op.value} style={{ fontSize: 13 }}>{op.label}</MenuItem>
                                })}
                              </MaterialSelect>
                            </FormControl>
                          </TableCell>
                          <TableCell className={classes.actionCol}>
                            <IconButton onClick={(e) => {
                              e.stopPropagation();
                              this.handleAddAndUpdate(index)
                            }}>
                              <DoneIcon />
                            </IconButton>
                            <IconButton onClick={(e) => {
                              e.stopPropagation();
                              this.handleDelete(index)
                            }}>
                              <Image src={deleteIcon} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      } else {
                        return <TableRow key={'task_' + task.id} hover style={{cursor: 'pointer'}} onClick={()=> this.props.history.push(`/app/settings/configurations/document-types/${task.tab_id}`)}>
                            <TableCell className={classes.nameCol}>{task?.name}</TableCell>
                            <TableCell className={classes.nameCol}>{task?.type}</TableCell>
                            <TableCell className={classes.actionCol}>
                              <IconButton onClick={(e) => {
                                e.stopPropagation();
                                this.handleEdit(index)
                              }}>
                                <Image src={editIcon} />
                              </IconButton>
                              <IconButton onClick={(e) => {
                                e.stopPropagation();
                                this.handleDelete(index)
                              }}>
                                <Image src={deleteIcon} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                      }
                    })}
                    {(!isDataLoading && (types === undefined || types === null || types.length <= 0)) && <TableRow>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }} className={classes.nameCol}>No Document Types</TableCell>
                    </TableRow>}

                    {isDataLoading && <TableRow>
                      <TableCell colSpan={2} style={{ textAlign: 'center' }} className={classes.nameCol}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>}

                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
        <MessageDialog messageData={messageData} callBack={this.callBack} />
      </>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(VendorDocumentTypes));
