import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "../../../../AppBar";
import LayeredCard from "../Common/LayeredCard";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";

import { Search as SearchIcon } from "@material-ui/icons";
import DefaultImage from "../../../../../../assets/images/evaluation/default.svg";
import OutlinedInput from "../../../../../Common/Input/OutlinedInput";
import { fetchProductSubCategory } from "../../../../../../redux/product/subcategory/action";
import InfiniteScroll from "react-infinite-scroll-component";
import CartDrawer from "Components/Application/CartDrawer";

import { storeSearchString } from "redux/applications/action"

const connectedProps = (state) => ({
  category: state.productSubCategory.category,
  subCategories: state.productSubCategory.subCategories,
  subCategoriesFetchProgress: state.productSubCategory.fetchSubCategoryProgress,
  evaluationCart: state.evaluationCart.evaluation,
  searchString: state.applications.searchString,
});

const connectionActions = {
  fetchProductSubCategory: fetchProductSubCategory,
  storeSearchString: storeSearchString
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    topContainer: {
      marginTop: theme.spacing(3),
    },
    headSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      "& [class*='MuiSvgIcon-root']": {
        fill: "#707070",
      },
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
     
    },

    outlinedSection: {
      '& [class*="MuiOutlinedInput-root"]': {
        maxHeight: 40,
        minHeight: 40,
        maxWidth: 343,
      },

      "& > div": {
        margin: 0
      },

      "& [class*='MuiOutlinedInput-input']": {
        fontSize: 13,
        width: 300,
      },
      "& [class*=MuiOutlinedInput-input]": {
        marginBottom: 0,
      },
    },
    title: {
      fontSize: theme.spacing(2.4),
    },
    categoriesList: {
      marginTop: theme.spacing(6.4),
      maxHeight: "calc(100vh - 210px)",
      minHeight: 200,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    main: {
      maxWidth: 'calc(100% - 280px)',
      flexBasis: 'calc(100% - 280px)',
    },
    filterSection: {
      width: 130
    }
  });

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageSize: 60,
    };
    this.fetchData = this.fetchData.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps, prevStats) {
    if(this.props.searchString !== '' && this.props.searchString !== this.state.query){
      this.setState({query:this.props.searchString},(()=>{
        this.fetchData();
      }))
    }
  }

  fetchData() {
    this.props.fetchProductSubCategory(
      this.props.match.params.category_id,
      this.state.page,
      this.state.pageSize,
      this.state.query
    );
  }

  render() {
    const classes = this.props.classes;

    const isCart = this.props.evaluationCart !== null && this.props.evaluationCart !== '' && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length > 0;

    // var addedToCart =
    //   this.props.evaluationCart == null
    //     ? []
    //     : this.props.evaluationCart.Products.map((p) => p.ID);

    return (
      <div>
        <Grid container spacing={3}>
          <Grid item lg={isCart ? 8 : 12} md={12} sm={12} className={isCart ? classes.main: ''}>

            <AppBar
              title={
                this.props.category == null ? "" : this.props.category.Name
              }
              withBack={true}
              dropdown={false}
            />
            <div className={classes.bottomContainer}>
              <div className={classes.headSection}>
                <Typography variant="h5" className={classes.title}>
                  Sub Categories
                </Typography>
                <div className={classes.outlinedSection}>
                <OutlinedInput
                  id={"searchCategories"}
                  placeholder="Search Categories"
                  value={this.state.query}
                  onChange={(event) => {
                    var me = this;
                    this.props.storeSearchString(event.target.value);
                    this.setState(
                      { query: event.target.value, page: 0 },
                      () => {
                        me.fetchData();
                      }
                    );
                  }}
                  startAdornment={
                    <InputAdornment>
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  }
                  roundedCorner={true}
                />
                </div>
                
                <div className={classes.filterSection}></div>
              </div>
              <div className={classes.categoriesList} id="categoriesList">
                <InfiniteScroll
                 scrollableTarget="categoriesList" 
                style={{ display: "flex", flexDirection: "column", overflow: "hidden", marginRight: 20 }} //To put endMessage and loader to the top.
                  dataLength={this.props.subCategories.length} //This is important field to render the next data
                  next={() => {
                    var me = this;
                    this.setState({ page: this.state.page + 1 }, () => {
                      me.fetchData();
                    });
                  }}
                  hasMore={
                    this.props.subCategories.length ===
                    (this.state.page + 1) * this.state.pageSize
                  }
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      {this.props.subCategories.length === 0 && this.props.subCategoriesFetchProgress === false && <b>No More Data</b> }
                    </p>
                  }
                >
                  <Grid container spacing={3}>
                    {this.props.subCategories.map((subCategory, k) => {
                      return (
                        <Grid item lg={isCart ? 3 : 2} key={k} md={isCart ? 4 : 3} >
                          <LayeredCard
                            title={subCategory.Name}
                            subTitle=""
                            image={
                              subCategory.Logo == null ||
                              subCategory.Logo.length === 0
                                ? DefaultImage
                                : subCategory.Logo
                            }
                            onClick={() => {
                              this.props.history.push(
                                "/app/evaluate/subcategory/" + subCategory.ID
                              );
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </InfiniteScroll>
                { this.props.subCategoriesFetchProgress === true && (
                  <div className={classes.backdrop}>
                    {" "}
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
          </Grid>
          {isCart && (
            <Grid item lg={4} style={{ position: "relative" }}>
              <CartDrawer />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(Category));