import {
    Dialog, DialogContent, Slide, Typography, FormControl, Select, MenuItem, Paper, CircularProgress
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from '@material-ui/core/Chip';
import SqureButton from "Components/Common/SqureButton";

import { hideEditSyncPreferenceDialog, updateSyncPreferences } from "redux/applications/action";
import { fetchConfig } from "redux/okta/action";
import { showSnackBar } from "redux/snackbar/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.applications.showEditPreferenceDialog,
    editPreferenceForApp: state.applications.editPreferenceForApp,
    syncPreferenceUpdateProgress: state.applications.syncPreferenceUpdateProgress,
    syncPreferenceUpdateError: state.applications.syncPreferenceUpdateError,
    syncPreferenceUpdateSuccess: state.applications.syncPreferenceUpdateSuccess,
    appConfigDetails: state.applications.appConfigDetails,
    configFetchProgress: state.okta.configFetchProgress,
    configFetchError: state.okta.configFetchError,
    config: state.okta.config,
});

const connectionActions = {
    hideDialog: hideEditSyncPreferenceDialog,
    updateSyncPreferences: updateSyncPreferences,
    fetchConfig: fetchConfig,
    showSnackBar:showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 15,
        "&$checked": {
            color: "#007BFF",
            "& svg": {
                stroke: "unset",
            },
        },
        "& svg": {
            stroke: "#999",
        },
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    body: {
        border: '1px solid #f7f7f7',
        borderRadius: 4,
        padding: 20,
        marginTop:10
    },
    userSync: {
        marginTop: 10
    },
    groupSync: {
        marginTop: 10
    },
    applicationSync: {
        marginTop: 10
    },
    itemLabel: {
        fontSize: '18px',
        fontWeight: 500
    },
    childItems: {
        paddingLeft: 30
    },
    searchAndAdd: {
        margin: 10,
    },
    newUserBtn: {
        background: "#3C3C3C",
        borderRadius: 5,
        color: "#fff",
        fontSize: theme.spacing(1.9),
        minHeight: 38,
        minWidth: 140,
        border: "none",
        cursor: "pointer",
        "&:hover": {
            background: "#3C3C3C",
        },
    },
    flexWrap: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    inputSearch: {
        minHeight: 38,
        padding: "2px 8px",
        border: "1px solid #cccccc",
        borderRadius: 3,
        fontSize: 15,
        color: "rgb(51, 51, 51)",
        width: '85%',
        marginRight: 10
    },
    paperItems: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
        boxShadow: 'none'
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    actions: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: "right",
    },
    loader:{
        textAlign:'center',
        marginTop:20
    }
});

class EditSyncPreferenceDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            syncUser: {
                sync: false,
                sync_user_to_expent: false
            },
            syncGroup: {
                sync: false,
                sync_specific_group: false,
                sync_type: 'GROUP_IDS', // STARTS_WITH/GROUP_IDS,
                starts_with: [],
                groupIds: [],
                sync_user_to_expent: false
            },
            syncApplication: {
                sync: false,
                sync_usage_activity: false
            },
            searchNameOrId: '',
            isLoader: true
        }
    }

    componentDidMount() {
        this.props.fetchConfig()
        console.log(this.props.appConfigDetails, 'appConfigDetails')
    }

    componentDidUpdate(prevProps) {
        if (this.props.configFetchProgress === false && prevProps.configFetchProgress) {
            this.initSettings()
        }

        if(!this.props.syncPreferenceUpdateProgress && prevProps.syncPreferenceUpdateProgress){
            if(this.props.syncPreferenceUpdateError === null){
                this.props.showSnackBar("Sync Preference Updated Successfully", "success", 3000);
                this.props.hideDialog()
            }else{
            this.props.showSnackBar("Something went wrong", "error", 3000);
            }
        }
    }

    initSettings() {
        const { config } = this.props;
        if (config && config.Configuration && config.Configuration !== '') {
            let configData = JSON.parse(config.Configuration);
            console.log(configData, 'config')
            this.setState({
                syncUser: configData.user,
                syncGroup: configData.group,
                syncApplication: configData.application,
            })
        }
        this.setState({
            isLoader: false
        })
    }

    updateValue(syncFor, field, value) {
        let { syncUser, syncGroup, syncApplication } = this.state;
        if (syncFor === 'syncUser') {
            syncUser[field] = value
            if (field === 'sync' && value === false) {
                syncUser.sync_user_to_expent = false
            }
        } else if (syncFor === 'syncGroup') {
            syncGroup[field] = value;
            if (field === 'sync' && value === false) {
                syncGroup.sync_specific_group = false;
                syncGroup.sync_user_to_expent = false;
                syncGroup.starts_with = [];
                syncGroup.groupIds = [];
            } else if (field === 'sync_specific_group' && value === false) {
                syncGroup.sync_user_to_expent = false;
                syncGroup.starts_with = [];
                syncGroup.groupIds = [];
            } else if (field === 'sync_type') {
                syncGroup.sync_user_to_expent = false;
                syncGroup.starts_with = [];
                syncGroup.groupIds = [];
            }
        } else if (syncFor === 'syncApplication') {
            syncApplication[field] = value;
            if (field === 'sync' && value === false) {
                syncUser.sync_usage_activity = false
            }
        }
        this.setState({
            syncUser: syncUser,
            syncGroup: syncGroup,
            syncApplication: syncApplication,
        })
    }

    addSearchItem() {
        let { syncGroup, searchNameOrId } = this.state;
        if (syncGroup.sync_type === 'STARTS_WITH') {
            syncGroup.starts_with.push(searchNameOrId);
        } else {
            syncGroup.groupIds.push(searchNameOrId);
        }
        this.setState({
            syncGroup: syncGroup,
            searchNameOrId: ''
        })
    }

    handleDelete(type, index) {
        let { syncGroup } = this.state;
        if (type === 'starts_with') {
            syncGroup.starts_with.splice(index, 1)
        } else {
            syncGroup.groupIds.splice(index, 1)
        }

        if (syncGroup.starts_with.length <= 0 && syncGroup.groupIds.length <= 0) {
            syncGroup.sync_user_to_expent = false;
        }

        this.setState({
            syncGroup: syncGroup
        })
    }

    submitPreferences() {
        let { syncUser, syncGroup, syncApplication } = this.state;
        const { editPreferenceForApp } = this.props;
        console.log(editPreferenceForApp, 'editPreferenceForApp')
        // console.log(syncGroup,'syncGroup')
        // console.log(syncApplication,'syncApplication')

        const parameters = {
            user: syncUser,
            group: syncGroup,
            application: syncApplication
        }

        this.props.updateSyncPreferences(editPreferenceForApp.AppLink, parameters)
        console.log(parameters, 'parameters')
    }

    render() {
        const classes = this.props.classes;
        const { syncUser, syncGroup, syncApplication, searchNameOrId, isLoader } = this.state;
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Edit Sync Preference</Typography>
                {isLoader && <div className={classes.loader}>
                    <CircularProgress/>
                </div>}
                {!isLoader && <div className={classes.body}>
                    <div className={classes.userSync}>
                        <Checkbox
                            classes={{ root: classes.root, checked: classes.checked }}
                            checked={syncUser.sync}
                            onChange={(e) => {
                                this.updateValue('syncUser', 'sync', syncUser.sync ? false : true)
                            }}
                            name={'Sync User'}
                        /><span className={classes.itemLabel}>{'Sync User'}</span>
                        {syncUser.sync && <div className={classes.childItems}>
                            <Checkbox
                                classes={{ root: classes.root, checked: classes.checked }}
                                checked={syncUser.sync_user_to_expent}
                                name={'Sync User Create'}
                                onChange={(e) => {
                                    this.updateValue('syncUser', 'sync_user_to_expent', syncUser.sync_user_to_expent ? false : true)
                                }}
                            /><span className={classes.itemLabel}>{'Create All User Account in Expent'}</span>
                        </div>}
                    </div>
                    <div className={classes.groupSync}>
                        <Checkbox
                            classes={{ root: classes.root, checked: classes.checked }}
                            checked={syncGroup.sync}
                            name={'Sync Group'}
                            onChange={(e) => {
                                this.updateValue('syncGroup', 'sync', syncGroup.sync ? false : true)
                            }}
                        /><span className={classes.itemLabel}>{'Sync Group'}</span>
                        {syncGroup.sync && <div className={classes.childItems}>
                            <Checkbox
                                classes={{ root: classes.root, checked: classes.checked }}
                                checked={syncGroup.sync_specific_group}
                                name={'Sync Specific Group'}
                                onChange={(e) => {
                                    this.updateValue('syncGroup', 'sync_specific_group', syncGroup.sync_specific_group ? false : true)
                                }}
                            /><span className={classes.itemLabel}>{'Sync Specific Group'}</span>

                            {syncGroup.sync_specific_group && <>
                                <div style={{ margin: 10 }}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.select}
                                        margin="dense"
                                        onClick={e => e.stopPropagation()}>
                                        <Select
                                            value={syncGroup.sync_type}
                                            onChange={(event) => {
                                                this.updateValue('syncGroup', 'sync_type', event.target.value)
                                            }}>
                                            <MenuItem value={'STARTS_WITH'}>Sync Groups Start With</MenuItem>
                                            <MenuItem value={'GROUP_IDS'}>Sync Specific Groups Ids</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className={classes.searchAndAdd}>
                                    <div className={classes.flexWrap}>
                                        <input
                                            className={classes.inputSearch}
                                            placeholder={syncGroup.sync_type === 'STARTS_WITH' ? 'Enter Group Name Start with' : 'Enter Group Id'}
                                            onChange={(e) => {
                                                this.setState({
                                                    searchNameOrId: e.target?.value
                                                })
                                            }}
                                            value={searchNameOrId}
                                        />
                                        <button
                                            type="button"
                                            disabled={this.props.workflowStatus === 2 ? true : false}
                                            className={classes.newUserBtn}
                                            onClick={() => {
                                                this.addSearchItem();
                                            }}>
                                            + Add
                                        </button>
                                    </div>
                                </div>

                                {syncGroup.sync_type === 'STARTS_WITH' && syncGroup.starts_with.length > 0 && <div className={classes.searchList}>
                                    <Paper component="ul" className={classes.paperItems}>
                                        {syncGroup.starts_with.map((data, index) => {
                                            return (
                                                <li key={'startWith_' + index}>
                                                    <Chip
                                                        label={data}
                                                        onDelete={() => {
                                                            this.handleDelete('starts_with', index)
                                                        }}
                                                        className={classes.chip}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </Paper>
                                </div>}

                                {syncGroup.sync_type === 'GROUP_IDS' && syncGroup.groupIds.length > 0 && <div className={classes.searchList}>
                                    <Paper component="ul" className={classes.paperItems}>
                                        {syncGroup.groupIds.map((data, index) => {
                                            return (
                                                <li key={'startWith_' + index}>
                                                    <Chip
                                                        label={data}
                                                        onDelete={() => {
                                                            this.handleDelete('groupIds', index)
                                                        }}
                                                        className={classes.chip}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </Paper>
                                </div>}

                                {(syncGroup.starts_with.length > 0 || syncGroup.groupIds.length > 0) && <>
                                    <Checkbox
                                        classes={{ root: classes.root, checked: classes.checked }}
                                        checked={syncGroup.sync_user_to_expent}
                                        name={'Sync Group Create'}
                                        onChange={(e) => {
                                            this.updateValue('syncGroup', 'sync_user_to_expent', syncGroup.sync_user_to_expent ? false : true)
                                        }}
                                    /><span className={classes.itemLabel}>{'Create User Account in Expent'}</span>
                                </>}
                            </>}

                        </div>}
                    </div>
                    <div className={classes.applicationSync}>
                        <Checkbox
                            classes={{ root: classes.root, checked: classes.checked }}
                            checked={syncApplication.sync}
                            name={'Sync Application'}
                            onChange={(e) => {
                                this.updateValue('syncApplication', 'sync', syncApplication.sync ? false : true)
                            }}
                        /><span className={classes.itemLabel}>{'Sync Application'}</span>
                        {syncApplication.sync && <div className={classes.childItems}>
                            <Checkbox
                                classes={{ root: classes.root, checked: classes.checked }}
                                checked={syncApplication.sync_usage_activity}
                                name={'Sync Application Usage Activity'}
                                onChange={(e) => {
                                    this.updateValue('syncApplication', 'sync_usage_activity', syncApplication.sync_usage_activity ? false : true)
                                }}
                            /><span className={classes.itemLabel}>{'Sync Application Usage Activity'}</span>
                        </div>}
                    </div>
                </div>}
                {!isLoader && <div className={classes.actions}>
                    <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={() => {
                        this.props.hideDialog()
                    }}>
                        Cancel
                    </SqureButton>
                    &nbsp;&nbsp;&nbsp;
                    <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={() => {
                        this.submitPreferences()
                    }}>
                        Update
                    </SqureButton>
                </div>}
            </DialogContent>
        </Dialog>
    }
}

EditSyncPreferenceDialog.defaultProps = {
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(EditSyncPreferenceDialog));