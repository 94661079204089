import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, Typography, Grid, InputAdornment } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon} from '@material-ui/icons';
import {hideEvaluationCriteriaEditDialog,editCriteria} from "../../../../../../redux/evaluation/criteria/action";
import {showSnackBar} from "../../../../../../redux/snackbar/action";
import OutlinedInput from "../../../../../Common/Input/OutlinedInput";
import Alert from '@material-ui/lab/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from 'react-router-dom';
import SqureButton from '../../../../../Common/SqureButton';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen:state.evaludationCriteria.criteriaEditDialog.show,
    editDialog:state.evaludationCriteria.criteriaEditDialog,
    editCriteriaProgress:state.evaludationCriteria.editCriteriaProgress,
    errorCriteriaEdit:state.evaludationCriteria.errorCriteriaEdit,
});

const connectionActions = {
    hideDialog:hideEvaluationCriteriaEditDialog,
    editCriteria:editCriteria,
    showSnackBar:showSnackBar
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent:{
        padding: theme.spacing(6, 12) +" !important"
    },
    modalTitle:{
        fontSize:theme.spacing(2.5),
        marginBottom:theme.spacing(2),
        textAlign:"center"
    },
    helptext:{
        fontSize:theme.spacing(1.8)
    },
    alerts:{
        marginTop:theme.spacing(4)
    },
    form:{
        marginTop:theme.spacing(2),
        width:'60%',
    },
    input:{
        marginBottom:theme.spacing(3)
    },
    scheduleTitle:{
        display:"block",
        marginBottom:theme.spacing(3),
        fontWeight:600,
        fontSize:theme.spacing(2)
    },
    scheduleTitle1:{
        display:"block",
        marginBottom:theme.spacing(3),
        fontWeight:600,
        fontSize:theme.spacing(1.8)
    },
    actions:{
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3),
        textAlign:"center",
    },
    loader:{
        textAlign:"center",
        padding:theme.spacing(10,0)
    },
    dialogAction:{
        textAlign:"center"
    },
    addNewButton:{
        fontWeight:600,
        fontSize:theme.spacing(2)
    },
    actionBtn:{
        minWidth:110
    },
    nameYour: {
        marginTop: 20,
        "& [class*=MuiInputBase-root]": {
            maxHeight: 40,
            minHeight: 40,
            "& input": {
                fontSize: theme.spacing(1.8)
            }
        },
    },
    addCriteriaDatePicker: {
        "& .react-datepicker__input-container > div": {
            display: 'flex',
            minWidth: 300,
            alignItems: 'baseline',
            "& [class*=MuiInputBase-root]": {
                maxHeight: 33,
                minHeight: 33,
                width: 166,
                "& input": {
                    fontSize: theme.spacing(1.8),
                    // width: 107,
                    textAlign: "center"
                }
            },
            "& [class*=MuiFormLabel-root]": {    
                marginBottom: 0,
                marginRight: 29
            }
        },
        "[class*=MuiMenuItem-root]": {
            color: "#707070",
            fontSize: theme.spacing(1.8)
        }
}
});

class CriteriaEditDialog extends React.Component {

    constructor(props){
        super(props);
        this.state  =   {
            error:"",
            success:"",
            name:"",
            name_error:"",
            criteria_schedule:new Date(),
            criteria_schedule_error:"",
        }

        this.submitResponse    = this.submitResponse.bind(this);
        this.cleanError    = this.cleanError.bind(this);
        this.cleanState     =   this.cleanState.bind(this);
    }
    
    cleanState(){
        this.setState({
            error:"",
            success:"",
            name:"",
            name_error:"",
            criteria_schedule:new Date(),
            criteria_schedule_error:"",
        });
    }

    componentDidUpdate(prevProps){

        if(this.props.isOpen && prevProps.isOpen === false){
            var criteria     =   this.props.editDialog.criteria;
            this.setState({
                'name':criteria.name,
                'criteria_schedule':new Date(criteria.schedule ? criteria.schedule : new Date()),
            })
        }

        if(this.props.editCriteriaProgress === false && prevProps.editCriteriaProgress === true){
            if(this.props.errorCriteriaEdit == null){
                //Migrate to different page
                this.props.hideDialog();
                this.cleanState();
                this.props.showSnackBar("Section Updated","success",3000);
            } else {
                if(this.props.errorCriteriaEdit.message !== undefined){
                    this.setState({
                        "error":"Failed to update Section Details."
                    })
                } else {
                    this.setState({
                        "error":"Something went wrong."
                    })
                }
            }
        }
    }

    cleanError(){
        this.setState({
            name_error:"",
            criteria_schedule_error:"",
        })
    }

    submitResponse(){
        this.cleanError();

        var name    =   this.state.name;
        if(name.trim().length === 0){
            this.setState({
                name_error:"Please enter Section Name."
            });
            return;
        }

        var criteria_schedule    =   this.state.criteria_schedule;
        if(criteria_schedule == null){
            this.setState({
                criteria_schedule_error:"Please enter Section Schedule."
            });
            return;
        }

        var editDialog     =   this.props.editDialog;
        this.props.editCriteria(editDialog.evaluationId,editDialog.criteria.id,{
            name:name,
            schedule:criteria_schedule.toISOString(),
        },this.props.match?.params?.component_id)
    }

    

    

    render() {
        const classes = this.props.classes;
        return <Dialog 
                    onClose={this.props.hideDialog} 
                    aria-labelledby="app-integrationDialog" 
                    open={this.props.isOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"md"}
                    scroll="body"
                    id="evMetaDialog"
                    >
                        <DialogContent classes={{root:classes.dialogContent}} >
                            <Typography variant={"h2"} className={classes.modalTitle}>Edit Section</Typography>
                            <div className={classes.alerts}>
                                {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                                {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                            </div>
                            <div className={classes.criteriaDetail}>
                                <Grid container>
                                    <Grid item lg={4}>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <div className={classes.addCriteriaDatePicker}>
                                            <DatePicker
                                                selected={this.state.criteria_schedule}
                                                onChange={date => {
                                                    this.setState({
                                                        criteria_schedule:date
                                                    })
                                                }}
                                                dateFormat="MMM do yyyy"
                                                portalId="evMetaDialog"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                customInput={
                                                    <OutlinedInput
                                                        label="Schedule"
                                                        readOnlyInput={true}
                                                        placeholder="Schedule"
                                                        labelStyle={"inline"}
                                                        error={this.state.criteria_schedule_error.length > 0}
                                                        helperText={this.state.criteria_schedule_error}
                                                        endAdornment={
                                                            <InputAdornment>
                                                                <ArrowDropDownIcon />
                                                            </InputAdornment>
                                                        }
                                                    />
                                                }
                                                />
                                            </div>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item lg={12}>
                                        <div className={classes.nameYour}>
                                        <OutlinedInput
                                            label="Name your Section"
                                            error={this.state.name_error.length > 0}
                                            helperText={this.state.name_error}
                                            value={this.state.name}
                                            onChange={(event) => {
                                                this.setState({ name: event.target.value });
                                            }}
                                        />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent> 
                        <DialogContent classes={{root:classes.dialogAction}} >
                            <div className={classes.actions}>
                                <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                                <SqureButton  cls={classes.actionBtn} variant={"contained"} 
                                onClick={this.submitResponse}>Save</SqureButton>
                            </div>
                        </DialogContent>

                </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(CriteriaEditDialog));