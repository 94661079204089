import {
  START_SERVICE_FETCH,
  END_SERVICE_FETCH,
  START_ALL_SERVICE_FETCH,
  END_ALL_SERVICE_FETCH,
  START_ALL_VENDOR_SERVICE_FETCH,
  END_ALL_VENDOR_SERVICE_FETCH,
  START_SERVICE_DETAILS_FETCH,
  END_SERVICE_DETAILS_FETCH,
} from "./action";

const initState = {
  fetchServiceProgress: false,
  fetchServiceError: null,
  servicesData: {},

  fetchAllServiceProgress: false,
  fetchAllServiceError: null,
  allServices: {},

  fetchAllVendorServiceProgress: false,
  fetchAllVendorServiceError: null,
  allVendorServices: {},

  serviceDetails: {},
  fetchServiceDetailsError: null,
  fetchServiceDetailsProgress: {},
};

export function serviceReducer(state = initState, action) {
  switch (action.type) {
    case START_ALL_VENDOR_SERVICE_FETCH:
      return {
        ...state,
        fetchAllVendorServiceProgress: true,
        fetchAllVendorServiceError: null,
      };

    case END_ALL_VENDOR_SERVICE_FETCH: {
      return {
        ...state,
        fetchAllVendorServiceProgress: false,
        fetchAllVendorServiceError: action.payload.error,
        allVendorServices: action.payload.success,
      };
    }
    case START_ALL_SERVICE_FETCH:
      return {
        ...state,
        fetchAllServiceProgress: true,
        fetchAllServiceError: null,
      };

    case END_ALL_SERVICE_FETCH: {
      return {
        ...state,
        fetchAllServiceProgress: false,
        fetchAllServiceError: action.payload.error,
        allServices: action.payload.success,
      };
    }
    case START_SERVICE_FETCH:
      return {
        ...state,
        fetchServiceProgress: true,
        fetchServiceError: null,
      };

    case END_SERVICE_FETCH: {
      let currentData = { ...state.servicesData };
      let newData = action.payload.success;
      if (action.payload?.searchQuery?.page > 0) {
        currentData?.items?.push(...newData?.items);
      } else {
        currentData = newData || {};
      }

      return {
        ...state,
        fetchServiceProgress: false,
        fetchServiceError: action.payload.error,
        servicesData: currentData,
      };
    }
    case START_SERVICE_DETAILS_FETCH:
      return {
        ...state,
        serviceDetails: {},
        fetchServiceDetailsError: null,
        fetchServiceDetailsProgress: true,
      };
    case END_SERVICE_DETAILS_FETCH:
      return {
        ...state,
        serviceDetails: action.payload.success,
        fetchServiceDetailsError: action.payload.error,
        fetchServiceDetailsProgress: false,
      };
    default:
      return state;
  }
}
