import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { getAllComponents } from "util/Common"
import WorkflowBuilder from "../../../../../../Settings/WorkflowBuilder/index"
import EvaluationBar from "../../../Common/EvaluationBar";
import { fetchEvaluationMetaData } from "redux/evaluation/metadata/action";

import { fetchSellerInstruction, addSellerInstruction, fetchWorkflowEvaluationMapping, fetchFinishedComponentList } from "redux/workflow/action";

import { fetchApprovalCriteria, addApprovalCriteria, deleteApprovalCriteria, addApprovalCriteriaApprover, updateApprovalCriteria, fetchProductApprovalDetails } from "redux/evaluation/approval/action";
import { fetchUserWorkflowTimeline } from "redux/workflow/action";


const connectedProps = (state) => ({
    evaluation: state.evaluationMetaData.evaluation,
    fetchProgress: state.evaluationMetaData.fetchProgress,

    fetchSellerInstructionProgress: state.workflow.fetchSellerInstructionProgress,
    fetchSellerInstructionError: state.workflow.fetchSellerInstructionError,
    sellerInstruction: state.workflow.sellerInstruction,

    fetchWorkflowEvaluationMappingProgress: state.workflow.fetchWorkflowEvaluationMappingProgress,
    fetchWorkflowEvaluationMappingError: state.workflow.fetchWorkflowEvaluationMappingError,
    workflowEvaluationMapping: state.workflow.workflowEvaluationMapping,

    fetchFinishComponentsProgress: state.workflow.fetchFinishComponentsProgress,
    fetchFinishComponentsError: state.workflow.fetchFinishComponentsError,
    finishedComponents: state.workflow.finishedComponents,

    approvalCriterias: state.evaluationApproval.approvalCriterias,
    fetchApprovalCriteriasProgress: state.evaluationApproval.fetchApprovalCriteriasProgress,
    fetchApprovalCriteriasError: state.evaluationApproval.fetchApprovalCriteriasError,

    addApprovalCriteriaError: state.evaluationApproval.addApprovalCriteriaError,
    addApprovalCriteriaSuccess: state.evaluationApproval.addApprovalCriteriaSuccess,
    addApprovalCriteriaProgress: state.evaluationApproval.addApprovalCriteriaProgress,

    updateApprovalCriteriaError: state.evaluationApproval.updateApprovalCriteriaError,
    updateApprovalCriteriaProgress: state.evaluationApproval.updateApprovalCriteriaProgress,

    productApprovalDetails: state.evaluationApproval.productApprovalDetails,
    productApprovalDetailsInProgress: state.evaluationApproval.productApprovalDetailsInProgress,

    userTimeline: state.workflow.userTimeline,
    fetchWokflowUserTimelineProgress: state.workflow.fetchWokflowUserTimelineProgress,
});

const connectionActions = {
    fetchEvaluationMetaData: fetchEvaluationMetaData,
    fetchSellerInstruction: fetchSellerInstruction,
    addSellerInstruction: addSellerInstruction,
    fetchWorkflowEvaluationMapping: fetchWorkflowEvaluationMapping,
    fetchFinishedComponentList: fetchFinishedComponentList,
    fetchApprovalCriteria: fetchApprovalCriteria,
    addApprovalCriteria: addApprovalCriteria,
    deleteApprovalCriteria: deleteApprovalCriteria,
    addApprovalCriteriaApprover: addApprovalCriteriaApprover,
    updateApprovalCriteria: updateApprovalCriteria,
    fetchProductApprovalDetails: fetchProductApprovalDetails,
    fetchUserWorkflowTimeline: fetchUserWorkflowTimeline
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    loader: {
        textAlign: 'center',
        marginTop: 30
    }
});

class Configuration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowLoader: true,
            instructionData: '',
            workflowEvaluationMapping: {},
            approvalData: [],
            approvedProductId: null,
            queryParams: queryString.parse(this.props.location.search),
            evaluationId: null,
            isEvalStarted: true,
            approvedCriteriesIds: [],
            startedComponents: [],
            finishedComponents: [],
            isShowChooseTemplateOption:true
        }
    }

    componentDidMount() {
        let evaluationId;
        let isEvalStarted = true;
        if (this.props.match.params.evaluation_id === '0' && this.state.queryParams && this.state.queryParams.parentEvaluationId && this.state.queryParams.parentEvaluationId !== '') {
            evaluationId = this.state.queryParams.parentEvaluationId;
            isEvalStarted = false;
        } else {
            evaluationId = this.props.match.params.evaluation_id;
        }
        this.setState({
            evaluationId: evaluationId,
            isEvalStarted: isEvalStarted
        }, () => {
            this.initDatas()
        })
    }

    initDatas() {
        this.props.fetchEvaluationMetaData(this.state.evaluationId);
        this.props.fetchWorkflowEvaluationMapping(this.state.evaluationId);
        this.props.fetchFinishedComponentList(this.state.evaluationId);
        this.checkUserTimelineData();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.fetchProgress && prevProps.fetchProgress) {
            this.setState({
                isShowLoader: false
            }, () => {
                this.checkApprovedProduct()
            })
        }

        if (!this.props.fetchSellerInstructionProgress && prevProps.fetchSellerInstructionProgress) {
            if (this.props.fetchSellerInstructionError === null) {
                this.setState({
                    instructionData: this.props.sellerInstruction?.Instruction || '',
                })
            }
        }

        if (!this.props.fetchWorkflowEvaluationMappingProgress && prevProps.fetchWorkflowEvaluationMappingProgress) {
            if (this.props.fetchWorkflowEvaluationMappingError === null) {
                // console.log(this.props.workflowEvaluationMapping, 'workflowEvaluationMapping')
                let map = {}
                if (this.props.workflowEvaluationMapping) {
                    this.props.workflowEvaluationMapping.forEach(function (wem) {
                        map[wem.WorkflowID] = wem.EvaluationID
                    })
                }
                this.setState({
                    workflowEvaluationMapping: map
                })
            }
        }


        if (!this.props.fetchFinishComponentsProgress && prevProps.fetchFinishComponentsProgress) {
            if (this.props.fetchFinishComponentsError === null) {
                let finishedComponents = [];
                let startedComponents = []
                let isShowChooseTemplateOption = true;
                console.log(this.props.finishedComponents, 'finishedComponents')
                if (Object.keys(this.props.finishedComponents).length > 0) {
                    if (Object.keys(this.props.finishedComponents.component_status).length > 0) {
                        let datas = this.props.finishedComponents.component_status
                        Object.keys(datas).forEach(function (comKey) {
                            let status = datas[comKey]
                            if (status === 1) { // In Progress Components
                                startedComponents.push(parseInt(comKey))
                            } else if (status === 2) { // Completed Components
                                finishedComponents.push(parseInt(comKey))
                            }
                        })
                    }
                    if(this.props.finishedComponents.requirement_count > 0){
                        isShowChooseTemplateOption = false;
                    }
                }
                this.setState({
                    finishedComponents: finishedComponents,
                    startedComponents: startedComponents,
                    isShowChooseTemplateOption:isShowChooseTemplateOption
                })
                // 1 in progress
                // 2 completed
            }
        }


        if (!this.props.fetchApprovalCriteriasProgress && prevProps.fetchApprovalCriteriasProgress) {
            if (this.props.fetchApprovalCriteriasError === null) {
                this.setState({
                    approvalData: this.props.approvalCriterias?.Response || []
                })
            }
        }

        if (this.props.addApprovalCriteriaProgress === false && prevProps.addApprovalCriteriaProgress === true) {
            if (this.props.addApprovalCriteriaError == null) {
                this.setState({
                    approvalData: this.props.addApprovalCriteriaSuccess?.Response || []
                })
            }
        }

        if (this.props.deleteApprovalCriteriaProgress === false && prevProps.deleteApprovalCriteriaProgress === true) {
            if (this.props.deleteApprovalCriteriaError == null) {
                this.getCriterias(this.state.evaluation_id, this.state.forComponent);
            }
        }

        if (prevProps.productApprovalDetailsInProgress && !this.props.productApprovalDetailsInProgress) {
            this.checkApprovedCriterias();
        }

        if (this.props.fetchWokflowUserTimelineProgress === false && prevProps.fetchWokflowUserTimelineProgress === true) {
            this.checkUserTimelineData();
        }

    }

    checkUserTimelineData() {
        const { userTimeline } = this.props;
        if (userTimeline) {
            let allComponents = getAllComponents(userTimeline, [])
            console.log(allComponents, 'allComponents')
            let startedComponents = allComponents.map(o => o.ComponentId);
            this.setState({
                startedComponents: startedComponents
            })
        }
    }

    getInstruction(component_id) {
        this.setState({
            forComponent: component_id
        }, () => {
            this.props.fetchSellerInstruction(this.state.evaluationId, component_id)
        })
    }

    updateSellerInstruction(component_id, data) {
        let newInstruction = data.configurations[0].value;
        this.setState({
            instructionData: newInstruction
        }, () => {
            this.props.addSellerInstruction(this.state.evaluationId, component_id, {
                instruction: newInstruction
            })
        })
    }

    getCriterias(component_id) {
        this.setState({
            forComponent: component_id
        }, () => {
            this.props.fetchApprovalCriteria(this.state.evaluationId, component_id);
            this.props.fetchProductApprovalDetails(this.state.evaluationId, this.state.approvedProductId, component_id)
        })
    }

    addUpdateCriterias(component, data, type) {
        console.log(data, 'addUpdateCriterias')
        console.log(component, 'component')
        if (type === 'ADD') {
            let params = {
                criterias: [{
                    stakeHolder: data.name,
                    criteriaID: data.id || 0,
                    configID: "",
                    criteriaType: "",
                    approvers: {
                        approver_ids: [],
                        team_ids: [],
                        productID: this.state.approvedProductId,
                    },
                    rules: data?.rules,
                    componentId: Number(component.id),
                }]
            }

            this.props.addApprovalCriteria(this.state.evaluationId, params)
        } else if (type === 'UPDATE_APPROVER') {
            console.log(data)
            let approver_ids = [];
            let team_ids = [];
            team_ids = data.approvers.teams.map(o => o.ID);
            approver_ids = data.approvers.users.map(o => o.ID);
            this.props.addApprovalCriteriaApprover(this.state.evaluationId, data.id, { approver_ids, team_ids, productID: this.state.approvedProductId });
        } else if (type === 'UPDATE_RULE') {
            console.log(data, 'Ruleeee')
            let approver_ids = [];
            let team_ids = [];
            team_ids = data.approvers.teams.map(o => o.ID);
            approver_ids = data.approvers.users.map(o => o.ID);
            let params = {
                "configID": String(data.criteriaId),
                "componentId": parseInt(this.state.forComponent),
                "rules": data.rules,
                "status": data.status,
                "stakeHolder": data.name,
                "conditions": data.conditions,
                "approvers": {
                    "approver_ids": approver_ids,
                    "team_ids": team_ids,
                    "productID": Number(this.state.approvedProductId),
                },
            }
            this.props.updateApprovalCriteria(this.state.evaluationId, data.criteriaId, params);
        } else if (type === 'UPDATE_CONDITION') {
            let approver_ids = [];
            let team_ids = [];
            team_ids = data.approvers.teams.map(o => o.ID);
            approver_ids = data.approvers.users.map(o => o.ID);
            let params = {
                "configID": String(data.criteriaId),
                "componentId": parseInt(this.state.forComponent),
                "rules": data.rules,
                "status": data.status,
                "stakeHolder": data.name,
                "conditions": data.conditions,
                "approvers": {
                    "approver_ids": approver_ids,
                    "team_ids": team_ids,
                    "productID": Number(this.state.approvedProductId),
                },
            }
            this.props.updateApprovalCriteria(this.state.evaluationId, data.criteriaId, params);
        } else if (type === 'DELETE') {
            this.props.deleteApprovalCriteria(this.state.evaluationId, data.id);
        }
    }

    checkApprovedProduct() {
        const { evaluation } = this.props;
        console.log(evaluation, 'evaluation111')

        if (evaluation) {
            console.log(evaluation, 'evaluation')
            let approvedProduct = evaluation.Products.find((product) => product.IsApproved);
            console.log(approvedProduct, 'approvedProduct')

            if (approvedProduct) {
                this.setState({
                    approvedProductId: approvedProduct?.ID
                })
            }
        }

    }

    checkApprovedCriterias() {
        const approvedCriterias = this.props.productApprovalDetails && this.props.productApprovalDetails.length
            ? this.props.productApprovalDetails.map((item) => item.CriteriaID)
            : [];

        console.log(approvedCriterias, 'approvedCriterias');
        this.setState({
            approvedCriteriesIds: approvedCriterias
        })
    }

    render() {
        const { classes } = this.props;
        const { isShowLoader, instructionData, workflowEvaluationMapping,
            approvalData, isEvalStarted, approvedCriteriesIds, startedComponents, finishedComponents,isShowChooseTemplateOption } = this.state;

        if (isShowLoader || this.props.fetchWorkflowEvaluationMappingProgress || this.props.fetchFinishComponentsProgress) {
            return <div className={classes.loader}><CircularProgress /></div>
        }

        console.log(finishedComponents, 'finishedComponents555')
        console.log(startedComponents, 'startedComponents')

        return (
            <>
                {this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation.Type !== 3 && <EvaluationBar
                    isShowCollabrations={false}
                    isShowEvalDetails={false}
                    isWorkFlow={true}
                    isShowViewBtns={false}
                    isShowConfigureBtn={false}
                    isShowSchedule={false}
                />}

                <WorkflowBuilder
                    builderFor={'PROJECT'}
                    isEvalStarted={isEvalStarted}
                    sellerInstruction={instructionData}
                    approvedCriteriesIds={approvedCriteriesIds}
                    isShowChooseTemplateOption={isShowChooseTemplateOption}
                    getSellerInstruction={(componentId) => {
                        this.getInstruction(componentId)
                    }}
                    updateSellerInstruction={(componentId, data) => {
                        this.updateSellerInstruction(componentId, data)
                    }}
                    finishedComponents={finishedComponents}
                    startedComponents={startedComponents}
                    projectApprovalCriterias={approvalData}
                    workflowEvaluationMapping={workflowEvaluationMapping}
                    getApprovalCriterias={(componentId) => {
                        this.getCriterias(componentId)
                    }}
                    addUpdateApprovalCriterias={(component, data, type) => {
                        this.addUpdateCriterias(component, data, type)
                    }}
                />
            </>

        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Configuration));
