import React from "react";
import { TableUtil } from "../../utils/table.js";
import { IconButton } from '@material-ui/core'
import DeleteTableIcon from "assets/images/icons/deleteTable.png"
import AddTableRowIcon from "assets/images/icons/addRow.png"
import AddTableColumnIcon from "assets/images/icons/addColumn.png"
import Image from 'Components/Common/image.jsx'

const InTable = ({ editor }) => {
  const table = new TableUtil(editor);

  const handleButtonClick = (action) => {
    switch (action) {
      case "row":
        table.insertRow();
        break;
      case "column":
        table.insertColumn();
        break;
      case "remove":
        table.removeTable();
        break;
      default:
        return;
    }
  };
  return (
    <>
      <IconButton title="insert row" onClick={() => handleButtonClick("row")}>
        <Image style={{ width: 25 }} src={AddTableRowIcon} alt="logo" />
      </IconButton>
      <IconButton title="insert column" onClick={() => handleButtonClick("column")}>
        <Image style={{ width: 25 }} src={AddTableColumnIcon} alt="logo" />
      </IconButton>
      <IconButton title="remove table" onClick={() => handleButtonClick("remove")}>
        <Image style={{ width: 25 }} src={DeleteTableIcon} alt="logo" />
      </IconButton>
    </>
  );
};

export default InTable;
