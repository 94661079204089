import {
    Dialog, DialogContent, Slide, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";

import { hideProductWhistedUserDialog } from "redux/product/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.products.showProductWhislistedUsersDialog,
    productWishlistedUsers: state.products.productWishlistedUsers,
});

const connectionActions = {
    hideDialog: hideProductWhistedUserDialog,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    actions: {
        textAlign: "center",
        marginTop: theme.spacing(2)
    },
    actionBtn: {
        minWidth: '200px'
    },
    actionBtnDisabled: {
        minWidth: '200px',
    },
    dialogContent: {
        margin: 'auto',
        marginBottom: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        textAlign: "center",
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
        margin: '20px 0px 0px'
    },
    reqName: {
        width: '10%'
    },
    userName: {
        width: '45%'
    },
    userEmail: {
        width: '45%'
    },
    finalizeForm: {
        '& [class*=MuiTable-root]': {

        },
        '& [class*=MuiTableCell]': {
            border: '1px solid #DEDEDE',
        },
        '& [class*=MuiTableCell-root]': {
            padding: theme.spacing(1, 1)
        },
    },
    mandatoryTable: {
        borderCollapse: 'separate !important',
        width: '100%',
        borderSpacing: 0,
        borderRadius: '4px',
    },
    otherProductTotal: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        color: '#282D30',
        opacity: '0.6',
        fontSize: theme.spacing(2)
    },
});

class ProductWhislistedUsersDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            feedback: "",
            feedback_error: "",
            users: []
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
    }

    init() {
        this.setState({
            users: this.props.productWishlistedUsers.Data
        })
    }

    render() {
        const classes = this.props.classes;

        const { users } = this.state;
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>

                <div className={classes.finalizeForm}>
                    <Typography variant={"h4"} className={classes.title}>Product Wishlisted Users</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            <Typography variant={"h6"} className={classes.otherProductTotal}>
                                {users.length} {users.length === 1 ? 'User' : 'Users'}
                            </Typography>
                        </div>
                    </div>

                    <Table className={classes.mandatoryTable} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.reqName}></TableCell>
                                <TableCell className={classes.userName}>Name</TableCell>
                                <TableCell className={classes.userEmail}>Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users && users.map((user, k) => {
                                return <TableRow>
                                    <TableCell className={classes.reqName}>{k + 1}</TableCell>
                                    <TableCell className={classes.userName}>{user.Name}</TableCell>
                                    <TableCell className={classes.userEmail}>{user.Email}</TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>

                </div>
            </DialogContent>
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ProductWhislistedUsersDialog));