import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";

// redux
import { showSnackBar } from "redux/snackbar/action";
import { fetchAppCatlogProducts } from "redux/product/appCatalog/action";

// Dilogs
import TableContent from "./tableContent";

const connectedProps = (state) => ({
  fetchAppCatalogProductsProgress: state.appCatalog.fetchAppCatalogProductsProgress,
  fetchAppCatalogProductsError: state.appCatalog.fetchAppCatalogProductsError,
  appCatalogProducts: state.appCatalog.products,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchAppCatlogProducts: fetchAppCatlogProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class AppCatalogTabular extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageType: 0,
      pageSize: 20,
      rows: [],
      isUpdateData: true,
      sortField: "",
      sortType: "asc",
      isTabular: true,
    };

    this.updatePageAndSize = this.updatePageAndSize.bind(this);
    this.selectNewTab = this.selectNewTab.bind(this);
    this.updateSearchString = this.updateSearchString.bind(this);
    this.updateSorting = this.updateSorting.bind(this);
  }

  componentDidMount() {
    console.log(this.props.activeTab, "FromActive");
    this.setState(
      {
        pageType: this.props.activeTab,
      },
      () => {
        this.fetchData();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fetchAppCatalogProductsProgress === false && prevProps.fetchAppCatalogProductsProgress === true) {
      if (this.props.fetchAppCatalogProductsError === null) {
        this.setState({
          rows: this.props.appCatalogProducts,
          isUpdateData: this.state.isUpdateData ? false : true,
        });
      }
    }
    if (this.props.activeTab !== prevProps.activeTab) {
      this.setState(
        {
          pageType: this.props.activeTab,
        },
        () => {
          this.fetchData();
        }
      );
    }
  }

  componentWillUnmount() {}

  getPageType(type) {
    let pageType = "existing";
    if (type === 0) {
      pageType = "existing";
    } else if (type === 1) {
      pageType = "featured";
    }
    return pageType;
  }

  getPageSortType() {
    let sortStr;
    const { sortField, sortType } = this.state;
    if (sortField.length === 0) {
      return false;
    }
    if (sortType === "asc") {
      sortStr = sortField;
    } else {
      sortStr = "-" + sortField;
    }
    return sortStr;
  }

  fetchData = () => {
    let pageType = this.getPageType(this.state.pageType);
    let sortType = this.getPageSortType();

    let params = {
      analytics_only: false,
      field_setname: "all",
      text: this.state.query,
      qtype_page: [pageType, this.state.page],
      page_size: this.state.pageSize,
      filter_fields: {},
      return_custom_fields: true,
    };
    if (sortType) {
      params["sort_fields"] = [sortType];
    } else {
      params["sort_fields"] = [];
    }

    this.props.fetchAppCatlogProducts(params, this.state.isTabular);
  };

  updatePageAndSize = (page, pageSize) => {
    this.setState(
      {
        page: page,
        pageSize: pageSize,
      },
      () => {
        this.fetchData();
      }
    );
  };

  updateSearchString = (page, pageSize, searchString) => {
    this.setState(
      {
        query: searchString,
        page: page,
        pageSize: pageSize,
        sortField: "",
        sortType: "asc",
      },
      () => {
        this.fetchData();
      }
    );
  };

  updateSorting(type, field) {
    this.setState(
      {
        page: 0,
        sortField: field,
        sortType: type,
      },
      () => {
        this.fetchData();
      }
    );
  }

  selectNewTab(newPageType) {
    this.setState(
      {
        page: 0,
        pageType: newPageType,
      },
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    const { classes, activeTab } = this.props;

    return (
      <>
        <div className={classes.rootContainers}>
          <TableContent
            page={this.state.page}
            pageSize={this.state.pageSize}
            searchQuery={this.state.query}
            sortField={this.state.sortField}
            sortType={this.state.sortType}
            pageType={this.state.pageType}
            from={"APP_CATALOG"}
            searchPlaceHolder={"Find product by name"}
            tableMaxHeight={"67vh"}
            isGlobalSortRequire={true}
            tabs={[
              {
                label: activeTab === 1 ? "Featured" : "Existing",
                value: 0,
              },
            ]}
            activeTab={0}
            onChangePage={(page, pageSize) => {
              this.updatePageAndSize(page, pageSize);
            }}
            onChangeSearch={(page, pageSize, searchString) => {
              this.updateSearchString(page, pageSize, searchString);
            }}
            onChangeTab={(newPageType) => {
              this.selectNewTab(newPageType);
            }}
            onChangeSort={(type, field) => {
              this.updateSorting(type, field);
            }}
            fetchData={this.fetchData}
          />
        </div>
      </>
    );
  }
}

export default connector(compose(withStyles(styles), withLastLocation)(AppCatalogTabular));
