import { createTheme } from "@material-ui/core/styles";

/*
import AvenirLTStdBook from "./assets/fonts/avnir/AvenirLTStd-Book.otf";
import AvenirLTStdMedium from "./assets/fonts/avnir/AvenirLTStd-Medium.otf";
import AvenirLTStdRoman from "./assets/fonts/avnir/AvenirLTStd-Roman.otf";
import AvenirBlack from "./assets/fonts/avnir/Avenir-Black.otf";
import LeagueSpartanBold from "./assets/fonts/league-spartan/LeagueSpartan-Bold.otf";
import InterSemibold from "./assets/fonts/inter/Inter-Semibold.otf";
import InterMedium from "./assets/fonts/inter/Inter-Medium.otf";
import PoppinsMedium from "./assets/fonts/poppins/Poppins-Medium.otf";
import PoppinsRegular from "./assets/fonts/poppins/Poppins-Regular.otf";
import PoppinsSemiBold from "./assets/fonts/poppins/Poppins-SemiBold.otf";
import PoppinsBold from "./assets/fonts/poppins/Poppins-Bold.otf";
import PoppinsExtraBold from "./assets/fonts/poppins/Poppins-ExtraBold.otf";
import PoppinsLight from "./assets/fonts/poppins/Poppins-Light.otf";
import SFProTextRegular from "./assets/fonts/sf-pro/SF-Pro-Text-Regular.otf";
import SFProTextMedium from "./assets/fonts/sf-pro/SF-Pro-Text-Medium.otf";
import SFProTextSemibold from "./assets/fonts/sf-pro/SF-Pro-Text-Semibold.otf";
import SFProTextBold from "./assets/fonts/sf-pro/SF-Pro-Text-Bold.otf";

const avenirLTStdBook = {
  fontFamily: "AvenirLTStdBook",
  fontStyle: "normal",
  fontWeight: 100,
  src: `url(${AvenirLTStdBook})`,
};

const avenirLTStdMedium = {
  fontFamily: "AvenirLTStdMedium",
  fontStyle: "normal",
  fontWeight: 500,
  src: `url(${AvenirLTStdMedium})`,
};

const avenirLTStdRoman = {
  fontFamily: "AvenirLTStdRoman",
  fontStyle: "normal",
  fontWeight: 500,
  src: `url(${AvenirLTStdRoman})`,
};

const avenirBlack = {
  fontFamily: "AvenirBlack",
  fontStyle: "normal",
  fontWeight: "bold",
  src: `url(${AvenirBlack})`,
};

const leagueSpartanBold = {
  fontFamily: "LeagueSpartanBold",
  fontStyle: "normal",
  fontWeight: 100,
  src: `url(${LeagueSpartanBold})`,
};

const interSemibold = {
  fontFamily: "InterSemibold",
  fontStyle: "normal",
  fontWeight: 100,
  src: `url(${InterSemibold})`,
};

const interMedium = {
  fontFamily: "InterMedium",
  fontStyle: "normal",
  fontWeight: 100,
  src: `url(${InterMedium})`,
};

const poppinsMedium = {
  fontFamily: "PoppinsMedium",
  fontStyle: "normal",
  fontWeight: 100,
  src: `url(${PoppinsMedium})`,
};

const poppinsRegular = {
  fontFamily: "PoppinsRegular",
  fontStyle: "normal",
  fontWeight: 100,
  src: `url(${PoppinsRegular})`,
};

const poppinsSemiBold = {
  fontFamily: "PoppinsSemiBold",
  fontStyle: "normal",
  fontWeight: 400,
  src: `url(${PoppinsSemiBold})`,
};

const poppinsBold = {
  fontFamily: "PoppinsBold",
  fontStyle: "normal",
  fontWeight: "bold",
  src: `url(${PoppinsBold})`,
};

const poppinsLight = {
  fontFamily: "PoppinsLight",
  fontStyle: "normal",
  fontWeight: "bold",
  src: `url(${PoppinsLight})`,
};

const poppinsExtraBold = {
  fontFamily: "PoppinsExtraBold",
  fontStyle: "normal",
  fontWeight: "bold",
  src: `url(${PoppinsExtraBold})`,
};

const sfProTextRegular = {
  fontFamily: "SFProTextRegular",
  fontStyle: "normal",
  fontWeight: 100,
  src: `url(${SFProTextRegular})`,
};

const sfProTextMedium = {
  fontFamily: "SFProTextMedium",
  fontStyle: "normal",
  fontWeight: 400,
  src: `url(${SFProTextMedium})`,
};

const sfProTextSemibold = {
  fontFamily: "SFProTextSemibold",
  fontStyle: "normal",
  fontWeight: 500,
  src: `url(${SFProTextSemibold})`,
};

const sfProTextBold = {
  fontFamily: "SFProTextBold",
  fontStyle: "normal",
  fontWeight: 500,
  src: `url(${SFProTextBold})`,
};
*/

export const ThemeType = {
  LIGHT: "LIGHT",
  DARK: "DARK",
};

export const FONTS = {}
/*
export const FONTS = {
  AvenirLTStdBook: "AvenirLTStdBook",
  AvenirLTStdMedium: "AvenirLTStdMedium",
  AvenirLTStdRoman: "AvenirLTStdRoman",
  InterSemibold: "InterSemibold",
  PoppinsMedium: "PoppinsMedium",
  PoppinsRegular: "PoppinsRegular",
  PoppinsBold: "PoppinsBold",
  PoppinsSemiBold: "PoppinsSemiBold",
  PoppinsLight: "PoppinsLight",
  PoppinsExtraBold: "PoppinsExtraBold",
  AvenirBlack: "AvenirBlack",
  SFProTextRegular: "SFProTextRegular",
  SFProTextMedium: "SFProTextMedium",
  SFProTextSemibold: "SFProTextSemibold",
  SFProTextBold: "SFProTextBold",
  InterMedium: "InterMedium",
};
*/

var lightOptions = {
    /*
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          avenirLTStdBook,
          avenirLTStdMedium,
          avenirLTStdRoman,
          leagueSpartanBold,
          interSemibold,
          poppinsMedium,
          poppinsRegular,
          poppinsSemiBold,
          poppinsBold,
          poppinsLight,
          poppinsExtraBold,
          avenirBlack,
          sfProTextRegular,
          sfProTextMedium,
          sfProTextSemibold,
          sfProTextBold,
          interMedium,
        ],
      },
    },
  },
  */
  typography: {
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#282D30",
    body1: {
      fontFamily: "Poppins",
      fontWeight: 400,
      textTransform: "none",
    },
    button: {
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    h1: {
      fontFamily: "Poppins",
      fontWeight: 600,
    },
    h2: {
      fontFamily: "Poppins",
      fontWeight: 600,
    },
    h3: {
      fontFamily: "Poppins",
      fontWeight: 600,
    },
    h5: {
      fontFamily: "Poppins",
      fontWeight: 600,
    },
    h6: {
      fontFamily: "Poppins",
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: "Poppins",
      fontWeight: 200,
      color: "#4B4B4B",
    },
    subtitle2: {
      fontFamily: "Poppins",
      fontWeight: 200,
      color: "#4B4B4B",
    },
  },
  palette: {
    type: "light",
    background: {
      paper: "#FFF",
    },
    primary: {
      main: "#4b86ff",
    },
    secondary: {
      main: "#20253A",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1280,
      lg: 1366,
      xl: 1920,
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiBackdrop: {
      // Name of the rule
      root: {
        // Some CSS
        backdropFilter: "blur(6px)",
        backgroundColor: "rgb(255 255 255 / 50%)",
      },
    },
    MuiDialog: {
      paperWidthMd: {
        maxWidth: "1000px",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
    },
  },
  spacing: (factor) => `${0.5 * factor}em`,
  appMaxWidth: "1366px",
};

export default (themeType) => {
  switch (themeType) {
    case ThemeType.LIGHT:
      return createTheme(lightOptions);
    default:
      return createTheme(lightOptions);
  }
};
