import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from "react-router-dom";
import SettingsCard from "./SettingsCard";
import Library from "../Library";
import { updateDrawerStyle } from "redux/drawer/action";
import UsersSettings from 'Components/Application/Components/Settings/UserAndGroups/UsersSettings';

const connectedProps = (state) => ({
});

const connectionActions = {
    updateDrawerStyle: updateDrawerStyle
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
});

class SettingsVendor extends React.Component {

    componentDidMount() {
        this.props.updateDrawerStyle(true, true);
    }
    render() {
        return <Switch>
            <Route exact path={this.props.match.path} component={SettingsCard} />
            <Route exact path={`${this.props.match.path}/users`} component={UsersSettings} />
            <Route exact path={`${this.props.match.path}/library`} component={Library} />
        </Switch>
    }
}

export default connector(compose(
    withStyles(styles)
)(SettingsVendor));