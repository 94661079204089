import { SHOW_ADD_COMPANY_PROFILE_DIALOG, HIDE_ADD_COMPANY_PROFILE_DIALOG, START_COMPANY_PROFILE_FETCH, END_COMPANY_PROFILE_FETCH, START_COMPANY_PROFILE_UPDATE, END_COMPANY_PROFILE_UPDATE } from "./action";

const initState = {
    total: 0,
    addCompanyProfileDialogOpen: false,
    fetchCompanyProfileProgress: false,
    updateCompanyProfileProgress: false,
    details: {},
    updateErrorDetails: null
}

export  function companyProfileSettingsReducer(state=initState,action){
    switch(action.type){
        case SHOW_ADD_COMPANY_PROFILE_DIALOG:
            return {
                ...state,
                addCompanyProfileDialogOpen: true
            };

        case HIDE_ADD_COMPANY_PROFILE_DIALOG:
            return {
                ...state,
                addCompanyProfileDialogOpen: false
            };
        case START_COMPANY_PROFILE_FETCH:
            return {
                ...state,
                fetchCompanyProfileProgress:true,
            };
        case END_COMPANY_PROFILE_FETCH:
            var success     =   action.payload.success;
            return {
                ...state,
                fetchCompanyProfileProgress:false,
                details: success
            }
        case START_COMPANY_PROFILE_UPDATE:
            return {
                ...state,
                updateCompanyProfileProgress:true,
            };
        case END_COMPANY_PROFILE_UPDATE:
            var error  =  action.payload.error;
            return {
                ...state,
                updateCompanyProfileProgress:false,
                updateErrorDetails: error
            }
        default:
            return state;
    }
}  
