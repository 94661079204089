import {
    END_EVALUATION_USER_FEEDBACK_FETCH, START_EVALUATION_USER_FEEDBACK_FETCH,
    SHOW_EVALUATION_FEEDBACK_TAKE_DIALOG, HIDE_EVALUATION_FEEDBACK_TAKE_DIALOG,
    START_EVALUATION_FEEDBACK_SUBMIT, END_EVALUATION_FEEDBACK_SUBMIT,
    START_EVALUATION_FEEDBACK_FETCH, END_EVALUATION_FEEDBACK_FETCH,
    SHOW_EVALUATION_PRE_FEEDBACK_TAKE_DIALOG, HIDE_EVALUATION_PRE_FEEDBACK_TAKE_DIALOG,
    START_EVALUATION_PRE_FEEDBACK_SUBMIT, END_EVALUATION_PRE_FEEDBACK_SUBMIT,
    SHOW_EVALUATION_FINALIZE_REQ_DIALOG, HIDE_EVALUATION_FINALIZE_REQ_DIALOG,
    SHOW_EVALUATION_PRE_FEEDBACK_ADMIN_DIALOG, HIDE_EVALUATION_PRE_FEEDBACK_ADMIN_DIALOG,
    SHOW_EVALUATION_OVERALL_REQ_DIALOG,HIDE_EVALUATION_OVERALL_REQ_DIALOG,
    SHOW_OVERALL_USER_WISE_PROGRESS_DIALOG,HIDE_OVERALL_USER_WISE_PROGRESS_DIALOG,
    SHOW_USER_COUNT_DEAILS_DIALOG,HIDE_USER_COUNT_DEAILS_DIALOG
} from "./action";

const initState = {
    showFeedbackTakeDialog: false,
    addFeedbackProgress: false,
    addFeedbackError: null,
    feedbackFetchProgress: false,
    feedback: [],
    userFeedback: null,
    userFeedbackProgress: false,
    showPreFeedbackTakeDialog: false,
    addPreFeedbackProgress: false,
    addPreFeedbackError: null,
    showFinalizeRequirementDialog: false,

    showPreFeedbackAdminDialog: false,
    isShowNextButton: null,

    showOverallRequirementDialog: false,

    showOverallUserWiseProgressDialog: false,
    showUserCountDetailsDialog: false,

    isFromNextButton:null
}

export function evaluationFeedbackReducer(state = initState, action) {
    switch (action.type) {
        case SHOW_EVALUATION_FEEDBACK_TAKE_DIALOG:
            return {
                ...state,
                showFeedbackTakeDialog: true
            }
        case HIDE_EVALUATION_FEEDBACK_TAKE_DIALOG:
            return {
                ...state,
                showFeedbackTakeDialog: false
            }
        case START_EVALUATION_FEEDBACK_SUBMIT:
            return {
                ...state,
                addFeedbackProgress: true,
                addFeedbackError: null
            }
        case END_EVALUATION_FEEDBACK_SUBMIT:
            return {
                ...state,
                addFeedbackProgress: false,
                addFeedbackError: action.payload.error
            }
        case START_EVALUATION_FEEDBACK_FETCH:
            return {
                ...state,
                feedbackFetchProgress: true,
                feedback: []
            }
        case END_EVALUATION_FEEDBACK_FETCH:
            var feedback = [];
            if (action.payload.success !== null) {
                feedback = action.payload.success;
            }
            return {
                ...state,
                feedbackFetchProgress: false,
                feedback: feedback
            }
        case START_EVALUATION_USER_FEEDBACK_FETCH:
            return {
                ...state,
                userFeedbackProgress: true,
                userFeedback: null,
            }
        case END_EVALUATION_USER_FEEDBACK_FETCH:
            var userfeedback = null;
            if (action.payload.success !== null) {
                userfeedback = action.payload.success;
            }
            return {
                ...state,
                userFeedbackProgress: false,
                userFeedback: userfeedback,
            }
        case SHOW_EVALUATION_PRE_FEEDBACK_TAKE_DIALOG:
            return {
                ...state,
                showPreFeedbackTakeDialog: true
            }
        case HIDE_EVALUATION_PRE_FEEDBACK_TAKE_DIALOG:
            return {
                ...state,
                showPreFeedbackTakeDialog: false
            }
        case START_EVALUATION_PRE_FEEDBACK_SUBMIT:
            return {
                ...state,
                addPreFeedbackProgress: true,
                addPreFeedbackError: null
            }
        case END_EVALUATION_PRE_FEEDBACK_SUBMIT:
            return {
                ...state,
                addPreFeedbackProgress: false,
                addPreFeedbackError: action.payload.error
            }
        case SHOW_EVALUATION_FINALIZE_REQ_DIALOG:
            return {
                ...state,
                showFinalizeRequirementDialog: true,
                isShowNextButton: action.payload.isShowNextButton
            }
        case HIDE_EVALUATION_FINALIZE_REQ_DIALOG:
            return {
                ...state,
                showFinalizeRequirementDialog: false,
                isShowNextButton: null
            }
        case SHOW_EVALUATION_PRE_FEEDBACK_ADMIN_DIALOG:
            return {
                ...state,
                showPreFeedbackAdminDialog: true
            }
        case HIDE_EVALUATION_PRE_FEEDBACK_ADMIN_DIALOG:
            return {
                ...state,
                showPreFeedbackAdminDialog: false
            }
        case SHOW_EVALUATION_OVERALL_REQ_DIALOG:
            return {
                ...state,
                showOverallRequirementDialog: true,
                isFromNextButton: action.payload.isFromNextButton
            }

        case HIDE_EVALUATION_OVERALL_REQ_DIALOG:
            return {
                ...state,
                showOverallRequirementDialog: false,
                isFromNextButton: null
            }

            case SHOW_OVERALL_USER_WISE_PROGRESS_DIALOG:
                return {
                    ...state,
                    showOverallUserWiseProgressDialog: true
                }
    
            case HIDE_OVERALL_USER_WISE_PROGRESS_DIALOG:
                return {
                    ...state,
                    showOverallUserWiseProgressDialog: false
                }

            case SHOW_USER_COUNT_DEAILS_DIALOG:
                return {
                    ...state,
                    showUserCountDetailsDialog: true,
                    showUserCountDetails: action.payload.userDetails,
                }
    
            case HIDE_USER_COUNT_DEAILS_DIALOG:
                return {
                    ...state,
                    showUserCountDetailsDialog: false,
                    showUserCountDetails: null,
                }

        default:
            return state;
    }
}