import { Axios } from "./index";
import * as Environment from "./../util/Environment";

export class SAMLFactory{
    static _instance;
    
    static instance() {
        if (SAMLFactory._instance == null) {
            SAMLFactory._instance = new SAMLFactory();
        }
        return SAMLFactory._instance;
    }

   
    async getAppConfig(){
        const api_server = Environment.api_host("SAML");
        console.log(api_server,"SAMLAPI");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/getConfig?t=${timestamp}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

}