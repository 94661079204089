import React from "react";
import { Dialog, DialogContent, Button, CircularProgress, Slide, Typography, Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import FlashAutoIcon from "@material-ui/icons/FlashAuto";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import Requirement from "./Requirement";
import { sellerQuestionSearch } from "redux/seller/library/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  sellerAssessmentDetails: state.sellerAssessment.sellerAssessmentDetails,
  questionSearchProgress: state.sellerLibrary.questionSearchProgress,
});

const connectionActions = {
  sellerQuestionSearch: sellerQuestionSearch,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
    },
    genieWrap: {
      marginTop: "0rem",
      margin: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title1: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    card: {
      border: "2px solid #EEEEEE",
      borderRadius: "10px",
      background: "#fff",
      paddingLeft: theme.spacing(2),
      cursor: "pointer",
      display: "flex",
      placeContent: "center",
      width: "100%",
      padding: "14px 16px",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: 80,
    },
    cardText: {
      display: "flex",
      flexDirection: "column",
    },
    box: {
      marginTop: 25,
    },
    actionButton: {
      textAlign: "right",
      marginTop: 25,
    },
    alert: {
      marginBottom: 10,
      marginTop: 25,
    },
    btn: {
      padding: "1.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    root: {
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    profileContainer: {
      // padding: "1rem",
    },
    profileInfoCard: {
      width: "100%",
      padding: "2rem",
      borderRadius: 0,
    },
    tabs: {
      width: "320px",
      paddingRight: 20,
      borderRight: "1px solid #ccc",

      "& button:nth-child(2)": {
        borderTop: "1px solid #ccc",
        borderBottom: "1px solid #ccc",
      },
    },
    tab: {
      fontSize: "0.9rem",
      fontWeight: 500,
      textTransform: "capitalize",
    },
    productBox: {},
    productContainer: {},
    productContainerReport: {
      display: "flex",
      marginBottom: 10,
    },
    report: {
      marginBottom: 0,
    },
    saveBtn: {
      display: "inline-block",
      marginRight: 30,
    },
    subText: {
      paddingBottom: theme.spacing(2),
    },
    appBar: {
      background: "#fff",
      padding: theme.spacing(1, 2),
      boxShadow: "none",
      borderBottom: "2px solid #f1f1f1",
    },
    mainLoader: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
    collabSection: {
      borderBottom: "solid 2px #f1f1f1",
      padding: theme.spacing(2),
      background: "white",
    },
    magicContainer: {
      textAlign: "right",
    },
    responseWrap: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "center",
      gap: 40,
      marginBottom: 20,
    },
    title: {
      marginBottom: "1rem",
      color: "#1f73b7",
    },
    heading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "95%",
      fontSize: 16,
      fontWeight: 500,
    },
    body: {
      padding: "2rem",
    },
  });

class GenieSummaryDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  applyGenie = () => {
    let is_global;
    if (this.props.sellerAssessmentDetails?.data?.length > 0) {
      let data = this.props.sellerAssessmentDetails?.data[0] || {};
      if (data?.assessment?.name) {
        is_global = data?.assessment?.is_global;
      }
    }
    this.props.sellerQuestionSearch({
      search_type: "embeddings",
      confidence: "high",
      assessment: { id: this.props.match.params.uuid, type: "imported" },
      filters: { is_global: is_global, different_answer_type: true },
    });
  };

  render() {
    const { classes, genieResponseCategoryMap } = this.props;

    return (
      <Dialog
        onClose={this.props.onClose}
        aria-labelledby="app-integrationDialog"
        open={true}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        id="evMetaDialog"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.onClose(genieResponseCategoryMap)}>
            <CloseIcon />
          </div>
          <Typography variant={"h4"} className={classes.title1}>
            Genie Summary
          </Typography>
          <div className={classes.body}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel1-content`} id={`panel1-header`}>
                <div className={classes.heading}>
                  <div>Auto Populated by Genie</div>
                  <div>{genieResponseCategoryMap["auto_populate"].length}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: "column", padding: "0em 1em" }}>
                {genieResponseCategoryMap["auto_populate"]?.map((data, QIndex) => (
                  <Grid className={classes.responseWrap} onClick={() => this.props.chooseCurrentQuestion(data.question)}>
                    <Grid item xs={12}>
                      <Requirement key={"req" + QIndex} requirement={data.question || {}} response={data.answer || {}} />
                    </Grid>
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-content`} id={`panel-header`}>
                <div className={classes.heading}>
                  <div>Needs Review</div>
                  <div>{genieResponseCategoryMap["needs_review"].length}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: "column", padding: "0em 1em" }}>
                {genieResponseCategoryMap["needs_review"]?.map((data, QIndex) => (
                  <Grid className={classes.responseWrap} onClick={() => this.props.chooseCurrentQuestion(data.question)}>
                    <Grid item xs={12}>
                      <Requirement key={"req" + QIndex} requirement={data.question || {}} response={data.answer || {}} />
                    </Grid>
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-content`} id={`panel-header`}>
                <div className={classes.heading}>
                  <div>No Matches</div>
                  <div>{genieResponseCategoryMap["no_match"].length}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: "column", padding: "0em 1em" }}>
                {genieResponseCategoryMap["no_match"]?.map((data, QIndex) => (
                  <Grid className={classes.responseWrap} onClick={() => this.props.chooseCurrentQuestion(data.question)}>
                    <Grid item xs={12}>
                      <Requirement key={"req" + QIndex} requirement={data.question || {}} response={data.answer || {}} />
                    </Grid>
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={classes.genieWrap}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              startIcon={<FlashAutoIcon />}
              onClick={() => {
                this.applyGenie();
              }}
            >
              {this.props.questionSearchProgress ? <CircularProgress style={{ width: 25, height: 25 }} /> : "Run Genie Again"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(GenieSummaryDialog));
