import {
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import Alert from '@material-ui/lab/Alert';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import SqureButton from 'Components/Common/SqureButton';
import { showSnackBar } from "redux/snackbar/action";

import {
    hideAddOrgCategoryDialog,
    addNewOrgCategory
} from "redux/org/categories/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.orgCategory.showAddCategoryDialog,
    addCategoryProgress: state.orgCategory.addCategoryProgress,
    addCategoryError: state.orgCategory.addCategoryError,
});


const connectionActions = {
    hideDialog: hideAddOrgCategoryDialog,
    addNewOrgCategory:addNewOrgCategory,
    showSnackBar:showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 30
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    bottomActions: {
        marginTop: 20,
        marginBottom: 20,
        textAlign: 'center'
    },
    required: {
        color: 'red'
    },
    alert: {
        marginBottom: 10
    },
    body: {
        marginTop: 30
    }
});

class AddOrgCategoryDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            categoryName: '',
            categoryDescription: ''
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidMount() {
        this.clearData();
    }

    componentDidUpdate(prevProps) {
      
    }

    clearData() {
        this.setState({
            error: "",
            success: "",
            categoryName: '',
            categoryDescription: ''
        })
    }

    clearError() {
        this.setState({
            error: "",
            success: "",
        })
    }

    handleFormSubmit() {
        this.clearError();
        const { categoryName, categoryDescription } = this.state;

        if (categoryName === '' || categoryName.length <= 0) {
            this.setState({
                error: 'Please enter category name'
            })
            return;
        }

        this.props.addNewOrgCategory({
            name:categoryName,
            description : categoryDescription
        })


    }

    render() {
        const classes = this.props.classes;
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Add Category</Typography>
                <div className={classes.body}>
                    <div className={classes.alert}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                    </div>
                    <div className={classes.nameBox}>
                        <OutlinedInput
                            label="Category Name"
                            type="text"
                            required
                            value={this.state.categoryName}
                            onChange={(event) => {
                                this.setState({
                                    categoryName: event.target.value
                                }, () => {
                                    this.clearError()
                                })
                            }}
                        />
                    </div>

                    <div className={classes.nameBox}>
                        <OutlinedInput
                            label="Description"
                            type="text"
                            value={this.state.categoryDescription}
                            onChange={(event) => {
                                this.setState({
                                    categoryDescription: event.target.value
                                }, () => {
                                    this.clearError()
                                })
                            }}
                        />
                    </div>

                    <div className={classes.bottomActions}>
                        <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                        <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={() => { this.handleFormSubmit() }}>Save</SqureButton>
                    </div>

                </div>
            </DialogContent>
        </Dialog>
    }
}

AddOrgCategoryDialog.defaultProps = {
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AddOrgCategoryDialog));