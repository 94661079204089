import React from "react";
import { Pie } from "react-chartjs-2";

import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

const PieChart = ({ chartdata, options }) => {
  const getSuitableY = (y, yArray = [], direction) => {
    let result = y;
    yArray.forEach((existedY) => {
      if (existedY - 14 < result && existedY + 14 > result) {
        if (direction === "right") {
          result = existedY + 14;
        } else {
          result = existedY - 14;
        }
      }
    });
    return result;
  };

  const getOriginPoints = (source, center, l) => {
    let a = { x: 0, y: 0 };
    var dx = (center.x - source.x) / l;
    var dy = (center.y - source.y) / l;
    a.x = center.x + l * dx;
    a.y = center.y + l * dy;
    return a;
  };

  const plugins = [
    {
      beforeInit(chart, legend, option) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          if (options?.legendBottomMargin) {
            return (this.height = this.height + options?.legendBottomMargin);
          } else {
            return (this.height += 20);
          }
        };
      },
    },
    {
      afterDatasetsDraw: (chart, args, pluginOptions) => {
        if (options.outerLabel) {
          const ctx = chart.ctx;
          // console.log("chart==", chart.config._config)
          ctx.save();
          ctx.font = "500 12px Poppins";
          const leftLabelCoordinates = [];
          const rightLabelCoordinates = [];
          const chartCenterPoint = {
            x: (chart.chartArea.right - chart.chartArea.left) / 2 + chart.chartArea.left,
            y: (chart.chartArea.bottom - chart.chartArea.top) / 2 + chart.chartArea.top,
          };

          chart.config.data.labels.forEach((label, i) => {
            /* console.log(chart.config._config.data.datasets[0].backgroundColor[i]) */
            const meta = chart.getDatasetMeta(0);
            const arc = meta.data[i];
            const dataset = chart.config.data.datasets[0];

            const centerPoint = arc.getCenterPoint();

            let color = chart.config._config.data.datasets[0].backgroundColor[i];
            // let labelColor = chart.config._config.data.datasets[0].backgroundColor[i]

            const angle = Math.atan2(centerPoint.y - chartCenterPoint.y, centerPoint.x - chartCenterPoint.x);

            let originPoint = getOriginPoints(chartCenterPoint, centerPoint, arc.outerRadius);
            const point2X = chartCenterPoint.x + Math.cos(angle) * (centerPoint.x < chartCenterPoint.x ? arc.outerRadius + 10 : arc.outerRadius + 10);
            let point2Y = chartCenterPoint.y + Math.sin(angle) * (centerPoint.y < chartCenterPoint.y ? arc.outerRadius + 15 : arc.outerRadius + 15);

            let suitableY;
            if (point2X < chartCenterPoint.x) {
              // on the left
              suitableY = getSuitableY(point2Y, leftLabelCoordinates, "left");
            } else {
              // on the right

              suitableY = getSuitableY(point2Y, rightLabelCoordinates, "right");
            }

            point2Y = suitableY;

            let value = dataset.data[i];

            if (options?.plugins?.datalabels?.transformExpression?.length > 0) {
              let formatter = Intl.NumberFormat("en", { notation: "compact" });
              try {
                value = eval(options?.plugins?.datalabels?.transformExpression);
              } catch (e) {
                console.log(e);
              }
            }

            let edgePointX = point2X < chartCenterPoint.x ? chartCenterPoint.x - arc.outerRadius - 10 : chartCenterPoint.x + arc.outerRadius + 10;

            if (point2X < chartCenterPoint.x) {
              leftLabelCoordinates.push(point2Y);
            } else {
              rightLabelCoordinates.push(point2Y);
            }

            //DRAW CODE
            // first line: connect between arc's center point and outside point
            ctx.lineWidth = 2;
            ctx.strokeStyle = color;
            ctx.beginPath();
            ctx.moveTo(originPoint.x, originPoint.y);
            ctx.lineTo(point2X, point2Y);
            ctx.stroke();
            // second line: connect between outside point and chart's edge
            ctx.beginPath();
            ctx.moveTo(point2X, point2Y);
            ctx.lineTo(edgePointX, point2Y);
            ctx.stroke();
            //fill custom label
            const labelAlignStyle = edgePointX < chartCenterPoint.x ? "right" : "left";
            const labelX = edgePointX < chartCenterPoint.x ? edgePointX : edgePointX + 0;
            const labelY = point2Y + 7;
            ctx.textAlign = labelAlignStyle;
            ctx.textBaseline = "bottom";
            ctx.font = "500 12px Poppins";
            // ctx.fillStyle = labelColor;
            ctx.fillText(" " + value + " ", labelX, labelY);
          });
          ctx.restore();
        }
      },
    },
  ];

  return <Pie data={chartdata} options={options} plugins={plugins} />;
};

export default React.memo(PieChart);
