import { createStyles, withStyles } from "@material-ui/core/styles";
import CommonCss from "commonCss";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({});

const connectionActions = {
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);
const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
      minHeight: 34,
      maxWidth: 100,
      padding: "0px 10px",
    },
    formGroup: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    label: {
      marginBottom: 5,
      display: "block",
    },
    supportLabel: {
      marginTop: 3,
      marginBottom: 3,
      display: "inline-block",
      marginRight: 30,
    },
  });

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      timer: null,
    };
  }

  componentDidMount() {
    this.setState({ value: this.props.value });
  }

  componentDidUpdate(prevProps) {
    // if (this.props.value !== prevProps.value) {
    //   this.setState({ value: this.props.value });
    // }
  }

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.formGroup}>
        <span className={classes.supportLabel}>{this.props.label}:</span>
        <input
          className={classes.formControl}
          placeholder={this.props.placeholder}
          onChange={(event) => {
            this.setState({ value: event.target.value });

            clearTimeout(this.state.timer);
            const newTimer = setTimeout(() => {
              try {
                let newValue = Math.round(parseInt(event.target.value));
                this.props.updateValue(newValue);
              } catch (e) {
                console.log(e);
                this.props.updateValue(null);
              }
            }, 500);

            this.setState({ timer: newTimer });
          }}
          min={0}
          type={"number"}
          value={this.state.value}
        />
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Input));
