import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { CommonFn } from "services/commonFn";
import classnames from "classnames";
import Image from "Components/Common/image.jsx";
import { Grid, CircularProgress } from "@material-ui/core";
import RightArrow from "assets/images/right-arrow.svg";
import CommonCss from "commonCss";

import { fetchProductSurveys, fetchCompletedProductSurveys } from "redux/survey/action";

const connectedProps = (state) => ({
  fetchProductSurveysProgress: state.survey.fetchProductSurveysProgress,
  productSurveys: state.survey.productSurveys,
  fetchCompletedProductSurveysProgress: state.survey.fetchCompletedProductSurveysProgress,
  productCompletedSurveys: state.survey.productCompletedSurveys,
  userType: state.authUser.user?.Role,
});

const connectionActions = {
  fetchProductSurveys: fetchProductSurveys,
  fetchCompletedProductSurveys: fetchCompletedProductSurveys,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  evaluateCard: {
    ...CommonCss.Home.evaluateCard,
    padding: "24px 24px",
    border: "1px solid #ddd",
    position: "relative",
  },
  evaluateCardTitle: {
    color: '#20253A',
    fontSize: 14,
    margin: 0,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  evaluateCardSubTitle: {
    fontSize: 12,
    color: '#A0A5BA',
    marginTop: "5px",
    marginBottom: 0
  },
  evaluateLogo: {
    flex: .8,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  evaluateText: {
    flex: 1.5,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  evaluateType: {
    borderRadius: "5px",
    position: "absolute",
    textAlign: "left",
    fontSize: "11px",
    padding: "3px 10px",
    right: "0px",
    top: "0px",
  },
  isEvaluate: {
    background: "#E6FFF3",
    color: "#2EB77D",
  },
  isReview: {
    background: "#cce5ff",
    color: "#004085",
  },
});

class SurveyData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.props.fetchProductSurveys(this.props.productDetails.productID, {
      status: [1, 2, 3],
      type: [3],
      page: this.props.page,
      pageSize: this.props.pageSize
    });

    this.props.fetchCompletedProductSurveys(this.props.productDetails.productID, {
      status: [4],
      type: [3],
      page: this.props.cPage,
      pageSize: this.props.cPageSize
    });
  }

  componentDidUpdate(prevProps) {

  }

  routeChange = (id) => {
    let redirectPath;
    if (this.props.userType === 'OrgAdmin') {
      redirectPath = `/app/survey/${id}`;
      this.props.history.push(redirectPath);
    } else {
      redirectPath = `/app/survey/${id}/take-survey`;
      this.props.history.push(redirectPath);
    }
  };

  render() {
    const classes = this.props.classes;
    const { productSurveys, productCompletedSurveys, fetchProductSurveysProgress, fetchCompletedProductSurveysProgress } = this.props;

    // console.log(productDetails, 'productDetails')
    // console.log(productSurveys, 'productSurveys')
    // console.log(productCompletedSurveys, 'productCompletedSurveys')

    if (fetchProductSurveysProgress || fetchCompletedProductSurveysProgress) {
      return <div style={{ textAlign: 'center', marginTop: 20 }}>
        <CircularProgress />
      </div>
    }

    let isShowCompletedSection = true;
    if (productCompletedSurveys === undefined || productCompletedSurveys === null || productCompletedSurveys.Data === undefined || productCompletedSurveys.Data === null) {
      isShowCompletedSection = false;
    }

    let isShowInProgressSection = true;
    if (productSurveys === undefined || productSurveys === null || productSurveys.Data === undefined || productSurveys.Data === null) {
      isShowInProgressSection = false;
    }

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <p className={classes.bottomHead}> In Progress</p>
          {isShowInProgressSection && productSurveys.Data.length > 0 && productSurveys?.Data?.map((item, index) => (
            <div
              className={classes.evaluateCard}
              key={index}
              onClick={() => this.routeChange(item.ID)}
            >
              <span
                className={classnames(classes.evaluateType, item.Type === 1 ? classes.isEvaluate : classes.isReview
                )}
              >
                {item.Type === 1 ? "Evaluation" : item.Type === 2 ? 'Review' : 'Survey'}
              </span>
              <div className={classes.evaluateText}>
                <p className={classes.evaluateCardTitle}>
                  {item.Name}
                </p>
                <p className={classes.evaluateCardSubTitle}>
                  Updated At:{" "}
                  {CommonFn.formateDate(item.UpdatedAt)}
                </p>
              </div>
              <div className={classes.evaluateLogo}>
                <Image
                  src={RightArrow}
                  className={classes.rightArrow}
                />
              </div>
            </div>
          )
          )}

          {(!isShowInProgressSection || productSurveys.Data.length <= 0) && <div style={{ textAlign: 'center' }}>
            No Survey
          </div>}

        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <p className={classes.bottomHead}> Completed</p>
          {isShowCompletedSection && productCompletedSurveys?.Data.length > 0 && productCompletedSurveys?.Data?.map((item, index) => (
            <div
              className={classes.evaluateCard}
              key={index}
              onClick={() => this.routeChange(item.ID)}
            >
              <span
                className={classnames(classes.evaluateType, item.Type === 1 ? classes.isEvaluate : classes.isReview
                )}
              >
                {item.Type === 1 ? "Evaluation" : item.Type === 2 ? 'Review' : 'Survey'}
              </span>
              <div className={classes.evaluateText}>
                <p className={classes.evaluateCardTitle}>
                  {item.Name}
                </p>
                <p className={classes.evaluateCardSubTitle}>
                  Updated At:{" "}
                  {CommonFn.formateDate(item.UpdatedAt)}
                </p>
              </div>
              <div className={classes.evaluateLogo}>
                <Image
                  src={RightArrow}
                  className={classes.rightArrow}
                />
              </div>
            </div>
          )
          )}

          {(!isShowCompletedSection || productCompletedSurveys.Data.length <= 0) && <div style={{ textAlign: 'center' }}>
            No Survey
          </div>}

        </Grid>
      </Grid>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(SurveyData));
