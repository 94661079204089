import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, CircularProgress, IconButton, TextField, Button } from '@material-ui/core';

import SqureButton from 'Components/Common/SqureButton';
import { EditOutlined as EditIcon } from "@material-ui/icons";

// import Form from "./Common/Form";
import Form from "./FormComponents/Form";
import Alert from '@material-ui/lab/Alert';
import { showSnackBar } from "redux/snackbar/action";

import { fetchOrgFormUsingId, updateOrgFormData } from "redux/forms/org/action";
import { fetchMasterFormUsingId } from "redux/forms/master/action";
import AppBarV2 from "Components/Application/AppBarV2";


const connectedProps = (state) => ({
  orgFormData: state.orgForms.orgFormData,
  fetchOrgFormsByIdProgress: state.orgForms.fetchOrgFormsByIdProgress,
  updateOrgFormProgress: state.orgForms.updateOrgFormProgress,
  fetchMasterFormsByIdProgress: state.masterForms.fetchMasterFormsByIdProgress,
  masterFormData: state.masterForms.masterFormData,
});

const connectionActions = {
  fetchOrgFormUsingId: fetchOrgFormUsingId,
  updateOrgFormData: updateOrgFormData,
  showSnackBar: showSnackBar,
  fetchMasterFormUsingId: fetchMasterFormUsingId
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    position: 'relative',
  },
  image: {
    width: theme.spacing(3.5),
    cursor: 'pointer'
  },
  imageSection: {
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(0.5)
  },
  bodySection: {
    background: '#f7f7f7',
    padding: 20,
    // marginTop: 20,
    minHeight: '86vh',
    height: '88vh',
    overflow: 'auto'
  },
  mainDataSection: {
    width: '50%',
    margin: '0 auto'
  },
  threeDot: {
    position: "absolute",
    zIndex: 1,
    right: 10,
    top: 10
  },
  formDetails: {
    // position: 'relative',
    // border: '1px solid #d8d8d8',
    padding: 17,
    // borderRadius: 5,
    // background: '#FFF',
  },
  formDetailFooter: {
    textAlign: 'right',
    padding: '17px 0px 0px 17px',
  },
  formName: {
    fontSize: 16,
    color: '#6C6C6C',
    opacity: 1,
    margin: 0
  },
  description: {
    fontSize: 14,
    color: '#B5BCC2',
    opacity: 1,
    margin: 0,
    marginTop: 10
  },
  alerts: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  topHeadColor: {
    background: '#1f73b7',
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    height: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%'
  },
  formBox: {
    border: '1px solid #dadce0',
    padding: '20px',
    position: 'relative',
    background: '#fff',
    marginTop: '20px',
    borderRadius: '8px',
    marginBottom: '15px',
  },
  descriptionBox: {
    marginTop: 20,
    marginBottom: 20,
  },
  uuidBox: {
    marginTop: 20,
    marginBottom: 20,
  },
  copiedText: {
    textAlign: 'left',
    fontSize: '14px',
    color: '#2EB77D',
  },
  copyText: {
    fontWeight: 400,
    fontSize: theme.spacing(2)
  },
});

class FormDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: null,
      type: 'custom',
      id: null,
      error: '',
      success: '',
      formName: '',
      formDescription: '',
      formSlug: '',
      isShowLoader: true,
      isEditFormDetails: false,
      formData: null,
      copy: false
    }
    this.updateForm = this.updateForm.bind(this);
  }

  componentDidMount() {
    console.log(this.props.match.params)
    var type = this.props.match.params.form === 'custom' ? 'custom' : 'default';
    var id = this.props.match.params.id;
    this.setState({
      type: type,
      id: id
    }, () => {
      if (type === 'custom') {
        this.props.fetchOrgFormUsingId(id);
      } else {
        this.props.fetchMasterFormUsingId(id);
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchOrgFormsByIdProgress === false && prevProps.fetchOrgFormsByIdProgress === true) {
      console.log(this.props.orgFormData, 'orgFormData')
      this.setState({
        formName: this.props.orgFormData?.FormTitle,
        formSlug: this.props.orgFormData?.FormSlug,
        formDescription: this.props.orgFormData?.FormDescription,
        formData: this.props.orgFormData,
        isShowLoader: false
      })
    }

    if (this.props.fetchMasterFormsByIdProgress === false && prevProps.fetchMasterFormsByIdProgress === true) {
      console.log(this.props.masterFormData, 'masterFormData')
      this.setState({
        formName: this.props.masterFormData?.FormTitle,
        formSlug: this.props.orgFormData?.FormSlug,
        formDescription: this.props.masterFormData?.FormDescription,
        formData: this.props.masterFormData,
        isShowLoader: false
      })
    }

    if (this.props.updateOrgFormProgress === false && prevProps.updateOrgFormProgress === true) {
      this.props.showSnackBar("Form details updated successfully", "success", 3000);
      this.setState({
        isEditFormDetails: false,
        formName: this.props.orgFormData?.FormTitle,
        formSlug: this.props.orgFormData?.FormSlug,
        formDescription: this.props.orgFormData?.FormDescription,
        formData: this.props.orgFormData,
      })
    }

  }

  updateForm() {
    this.setState({
      error: '',
      success: '',
    })

    var formName = this.state.formName || "";
    var formDescription = this.state.formDescription || "";
    var formSlug = this.state.formSlug || "";
    if (formSlug === '') {
      const timestamp = (new Date()).getTime();
      formSlug = formName.replace(/[^a-zA-Z0-9]/g, '_') + '_' + timestamp;
    } else {
      formSlug = formSlug.replace(/[^a-zA-Z0-9]/g, '_');
    }

    if (formName.trim().length === 0) {
      this.setState({
        error: "Please enter Form Name."
      });
      return;
    }

    // if (formDescription.trim().length === 0) {
    //   this.setState({
    //     error: "Please enter Form Description."
    //   });
    //   return;
    // }

    const data = {
      form_title: formName,
      form_description: formDescription,
      form_slug: formSlug
    }

    this.props.updateOrgFormData(this.props.orgFormData?.ID, data);
  }

  copyLink(slug) {
    navigator.clipboard.writeText(`${slug}`)
  }

  render() {
    const classes = this.props.classes;
    const { isShowLoader, isEditFormDetails, formData, type, copy } = this.state;


    if (isShowLoader) {
      return <div style={{ textAlign: 'center', marginTop: 20 }}>
        <CircularProgress />
      </div>
    }

    console.log(formData, 'formDataTestttttttt')
    return (
      <div>
        <AppBarV2 withBack={true} title={formData?.FormTitle} />
        <div className={classes.bodySection}>
          <div className={classes.mainDataSection}>
            <div className={classes.formBox}>
              <div className={classes.topHeadColor}></div>
              {!isEditFormDetails && <div className={classes.formDetails}>

                {type === 'custom' && <IconButton
                  className={classes.threeDot}
                  onClick={(event) => {
                    event.stopPropagation()
                    this.setState({ open: true, anchorEl: event.currentTarget || event.current })
                  }}
                >
                  {/* <MoreVertIcon /> */}
                  <EditIcon
                    fontSize={"small"}
                    className={classes.editIcon}
                    onClick={(e) => {
                      e.stopPropagation()
                      this.setState({
                        isEditFormDetails: true, anchorEl: null, open: null
                      })
                    }}
                  />
                </IconButton>}
                <div>
                  <div className={classes.fieldContainer}>
                    <p className={classes.formName}>{formData?.FormTitle}</p>
                    {false && <p className={classes.description}>{formData?.FormSlug}
                      {copy && (
                        <span className={classes.copiedText}>&nbsp;&nbsp;&nbsp;Copied Slug</span>
                      )}
                      {false && !copy && formData?.FormSlug !== '' && (
                        <Button
                          color="primary"
                          className={classes.copyText}
                          onClick={() => {
                            this.copyLink(formData?.FormSlug)
                            this.setState({
                              copy: true
                            })
                            setTimeout(() => {
                              this.setState({
                                copy: false
                              })
                            }, 3000);
                          }}
                        >
                          Copy Slug
                        </Button>
                      )}
                    </p>}
                    <p className={classes.description}>{formData?.FormDescription}</p>
                  </div>
                </div>
              </div>}
              {isEditFormDetails && <div className={classes.formDetails}>
                <div className={classes.alerts}>
                  {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                  {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                </div>
                <div>
                  <TextField
                    id="standard-multiline-flexible"
                    fullWidth
                    label="Title"
                    value={this.state.formName}
                    placeholder='Enter your form name here.'
                    onChange={(event) => {
                      this.setState({
                        formName: event.target.value
                      })
                    }}
                  />
                </div>
                {false && <div className={classes.uuidBox}>
                  <TextField
                    id="standard-multiline-flexible"
                    fullWidth
                    label="UUID"
                    value={this.state.formSlug}
                    placeholder='Enter your form UUID.'
                    onChange={(event) => {
                      this.setState({
                        formSlug: event.target.value
                      })
                    }}
                  />
                </div>}
                <div className={classes.descriptionBox}>
                  <TextField
                    id="standard-multiline-flexible"
                    fullWidth
                    label="Description"
                    value={this.state.formDescription}
                    placeholder={"Enter your description here"}
                    onChange={(event) => {
                      this.setState({
                        formDescription: event.target.value
                      })
                    }}
                  />
                </div>
                <div className={classes.formDetailFooter}>
                  <SqureButton style={{ "marginLeft": "10px" }} variant={"outlined"}
                    onClick={() => {
                      this.setState({
                        isEditFormDetails: false
                      })
                    }}>Cancel</SqureButton>
                  &nbsp;&nbsp;

                  <SqureButton style={{ "marginLeft": "10px" }} variant={"contained"}
                    onClick={this.updateForm}>Update</SqureButton>
                </div>
              </div>}
            </div>
            <Grid container>
              {this.props.formData !== null && <Grid lg={12} md={12} sm={12} xs={12}>
                <Form
                  orgFormData={formData}
                  isShowNewSectionbtn={true}
                  isEditForm={true}
                  type={this.state.type}
                />
              </Grid>}
            </Grid>
          </div>
        </div>
      </div >
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(FormDetails));
