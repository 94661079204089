import {
    START_FETCH_NEXT_COMPONENT, END_FETCH_NEXT_COMPONENT,
    START_FETCH_WORKFLOW_COMPONENT_FORM, END_FETCH_WORKFLOW_COMPONENT_FORM,
    START_FETCH_WORKFLOW_COMPONENT_USER_RESPONSE, END_FETCH_WORKFLOW_COMPONENT_USER_RESPONSE,
    START_ADD_WORKFLOW_COMPONENT_USER_RESPONSE, END_ADD_WORKFLOW_COMPONENT_USER_RESPONSE,
    START_FETCH_MASTER_COMPONENTS, END_FETCH_MASTER_COMPONENTS,
    START_FETCH_ORG_WORKFLOW_COMPONENTS, END_FETCH_ORG_WORKFLOW_COMPONENTS,
    START_FETCH_WORKFLOW_COMPONENT_DETAILS, END_FETCH_WORKFLOW_COMPONENT_DETAILS,
    START_FETCH_COMPONENT_DETAILS, END_FETCH_COMPONENT_DETAILS,
    START_ADD_CUSTOM_COMPONENT_RESPONSE, END_ADD_CUSTOM_COMPONENT_RESPONSE,
    SHOW_EVALUATION_GUIDE_FORM_DIALOG, HIDE_EVALUATION_GUIDE_FORM_DIALOG,
    START_FETCH_WORKFLOW_FORM_RESPONSE, END_FETCH_WORKFLOW_FORM_RESPONSE,
    SET_WORKFLOW_LOADER_STATUS,
    START_FETCH_WORKFLOW_USER_TIMELINE, END_FETCH_WORKFLOW_USER_TIMELINE,
    START_FETCH_WORKFLOW_FORM_USERWISE_RESPONSE, END_FETCH_WORKFLOW_FORM_USERWISE_RESPONSE,
    SHOW_START_NEW_WORKFLOW_DIALOG, HIDE_START_NEW_WORKFLOW_DIALOG,
    START_NEW_WORKFLOW_FOR_PRODUCT, END_NEW_WORKFLOW_FOR_PRODUCT,
    START_FETCH_WORKFLOW_CONFIGURATIONS, END_FETCH_WORKFLOW_CONFIGURATIONS,
    SHOW_PARALLEL_COMPONENTS_DIALOG, HIDE_PARALLEL_COMPONENTS_DIALOG,
    START_FETCH_WORKFLOW_PROGRESS_NEW, END_FETCH_WORKFLOW_PROGRESS_NEW,
    START_FETCH_WORKFLOWS, END_FETCH_WORKFLOWS,
    START_FETCH_WORKFLOW_PROGRESS_STATUS, END_FETCH_WORKFLOW_PROGRESS_STATUS,
    START_WEB_HOOK_RUN, END_WEB_HOOK_RUN,
    START_EXPORT_WORKFLOW_DATA, END_EXPORT_WORKFLOW_DATA,
    START_GET_WORKFLOW_COMPONENT_DETAILS, END_GET_WORKFLOW_COMPONENT_DETAILS,
    SET_EVALUATION_SUMMARY_TYPE,
    SHOW_ADD_FORM_COLUMNS_DIALOG, HIDE_ADD_FORM_COLUMNS_DIALOG,
    START_ADD_WORKFLOW_SELLER_INSTRUCTION, END_ADD_WORKFLOW_SELLER_INSTRUCTION,
    START_FETCH_SELLER_INSTRUCTION, END_FETCH_SELLER_INSTRUCTION,
    START_FETCH_WORKFLOWS_PROJECT_TRACKING, END_FETCH_WORKFLOWS_PROJECT_TRACKING,
    START_FETCH_PROJECT_TRACKING, END_FETCH_PROJECT_TRACKING,
    START_ADD_PROJECT_TRACKING_RESPONSE, END_ADD_PROJECT_TRACKING_RESPONSE,
    SET_ACTIVE_PROJECTS_TAB,
    START_FETCH_FINISHED_WORKFLOW_COMPONENTS, END_FETCH_FINISHED_WORKFLOW_COMPONENTS,
    START_FETCH_EVALUATION_WORKFLOW_MAPPING, END_FETCH_EVALUATION_WORKFLOW_MAPPING,
    START_POPULATE_EVALUATION_TEMPLATE_DATA, END_POPULATE_EVALUATION_TEMPLATE_DATA,
    START_FETCH_PROJECT_SCHEMA, END_FETCH_PROJECT_SCHEMA,
    START_FETCH_EMAIL_COMPONENT_ACTIVITY,
    END_FETCH_EMAIL_COMPONENT_ACTIVITY,
    START_ADD_EMAIL_COMPONENT_ACTIVITY,
    END_ADD_EMAIL_COMPONENT_ACTIVITY,
    START_FETCH_COMPONENT_COLLOBORATOR,
    END_FETCH_COMPONENT_COLLOBORATOR,
    START_ADD_COMPONENT_COLLOBORATOR,
    END_ADD_COMPONENT_COLLOBORATOR,
    START_DELETE_COMPONENT_COLLOBORATOR, END_DELETE_COMPONENT_COLLOBORATOR, START_FETCH_PROJECT_REQUEST_TYPES,
    END_FETCH_PROJECT_REQUEST_TYPES, SHOW_WORKFLOW_VIEW_DIALOG, HIDE_WORKFLOW_VIEW_DIALOG,
    START_GET_COMPONENT_PERMISSION, END_GET_COMPONENT_PERMISSION,
    START_FETCH_VISUALIZATION_WORKFLOWS, END_FETCH_VISUALIZATION_WORKFLOWS,
    START_FETCH_VISUALIZATION_WORKFLOWS_PROJECT_TRACKING, END_FETCH_VISUALIZATION_WORKFLOWS_PROJECT_TRACKING,
    START_VENDOR_SEND_ALL_QUESTIONS, END_VENDOR_SEND_ALL_QUESTIONS
} from "./action";

const initState = {
    nextComponentDetails: null,
    fetchNextComponentProgress: false,

    workflowComponentsFormsInProg: false,
    workflowComponentsForms: null,

    componentUserResponse: [],
    fetchComponentUserResponseProgress: false,

    addComponentUserResponseProgress: false,

    fetchMasterComponentsProgress: false,
    masterComponentsMap: {},

    fetchOrgWorkflowComponentsProgress: false,
    orgWorkflowComponentsDatas: [],
    orgWorkflowComponentsMap: {},

    workflowComponentDetail: null,
    fetchworkflowComponentDetailProgress: false,

    componentDetail: null,
    fetchComponentDetailProgress: false,
    componentUserResponseError: null,
    addCustomComponentUserResponseProgress: false,

    isShowEvaluationGuideFormDialog: false,

    fetchWokflowFormResponseProgress: false,
    workflowFormResponse: null,

    isShowWorkflowLoader: false,

    fetchWokflowUserTimelineProgress: false,
    userTimeline: null,

    showNewWorkflowStartDialog: false,
    startWorkflowForProduct: null,

    startNewWorkflowProgress: false,
    startNewWorkflowError: null,
    workflowData: {},

    fetchWorkflowConfigProgress: false,
    fetchWorkflowConfigError: null,
    workflowConfigurations: null,

    isShowParallelDialog: false,
    parallelComponentsData: null,

    fetchWorkflowProgress: false,
    fetchWorkflowError: null,
    workflowProgressData: null,

    fetchWorkflowsProgress: false,
    fetchWorkflowsError: null,
    orgWorkflows: {},
    orgWorkflowsTotal: {},

    fetchVisualizationWorkflowsProgress: {},
    fetchVisualizationWorkflowsError: null,
    orgVisualizationWorkflows: {},
    orgVisualizationWorkflowsTotal: {},

    fetchVisualizationProjectTrackingProgress: {},
    fetchVisualizationProjectTrackingError: null,
    orgVisualizationProjectTracking: {},
    orgVisualizationProjectTrackingTotal: {},

    fetchWorkflowsTrackingProgress: false,
    fetchWorkflowsTrackingError: null,
    orgWorkflowsTracking: [],
    trackingFields: [],
    orgWorkflowsTrackingTotal: '',

    fetchWorkflowStatusProgress: false,
    fetchWorkflowStatusError: null,
    workflowProgressStatusData: null,
    wekHookRunProgress: false,
    wekHookRunError: null,
    wekHookRunSuccess: null,

    evComponentData: null,
    fetchevComponentDataProgress: false,
    fetchevComponentDataError: null,

    isOpenAddFormColumnsDialog: false,
    selectedFormColumns: [],

    evaluationSummaryType: 'EXECUTIVE_SUMMARY',

    fetchExportWorkflowDataLInk: null,
    fetchExportWorkflowDataLInkProg: false,

    addSellerInstructionProgress: false,
    addSellerInstructionError: null,
    addSellerInstructionSuccess: null,

    fetchSellerInstructionProgress: false,
    fetchSellerInstructionError: null,
    sellerInstruction: null,

    addProjectTrackingProgress: false,
    addProjectTrackingError: null,
    addProjectTrackingSuccess: null,

    fetchProjectTrackingProgress: false,
    fetchProjectTrackingError: null,
    fetchProjectTrackingSuccess: null,

    activeProjectsTab: 1,

    fetchWorkflowEvaluationMappingProgress: false,
    fetchWorkflowEvaluationMappingError: null,
    workflowEvaluationMapping: null,

    fetchFinishComponentsProgress: false,
    fetchFinishComponentsError: null,
    finishedComponents: null,

    fetchPopulateDataProgress: false,
    fetchPopulateDataError: null,
    populatedDatas: null,

    fetchProjectSchemaProgress: true,
    fetchProjectSchemaError: null,
    projectSchema: null,

    fetchEmailComponentActivityProgress: false,
    fetchEmailComponentActivityError: null,
    emailComponentActivity: null,

    addEmailComponentActivityProgress: false,
    addEmailComponentActivityError: null,
    addEmailComponentActivitySuccess: null,

    fetchComponentColloboratorProgress: false,
    fetchComponentColloboratorError: null,
    componentColloborators: null,

    addComponentColloboratorProgress: false,
    addComponentColloboratorError: null,
    addComponentColloboratorSuccess: null,

    deleteComponentColloboratorProgress: false,
    deleteComponentColloboratorError: null,
    deleteComponentColloboratorSuccess: null,

    fetchProjectReqTypesProgress: false,
    fetchProjectReqTypesError: null,
    requestTypes: [],

    getComponentPermissionProgress: false,
    getComponentPermissionError: null,
    componentPermission: null,

    showWorkflowViewDialog: false,
    showWorkflowViewData: null,

    sendVendorQuestionsProgress: false,
    sendVendorQuestionsError: null,
    sendVendorQuestionsSuccess: null
}


export function workflowReducer(state = initState, action) {
    switch (action.type) {
        case SHOW_WORKFLOW_VIEW_DIALOG:
            return {
                ...state,
                showWorkflowViewDialog: true,
                showWorkflowViewData: action.payload.data,
            };

        case HIDE_WORKFLOW_VIEW_DIALOG:
            return {
                ...state,
                showWorkflowViewDialog: false,
                showWorkflowViewData: null
            };
        case START_FETCH_MASTER_COMPONENTS:
            return {
                ...state,
                fetchMasterComponentsProgress: true,
                masterComponentsMap: {}
            }
        case END_FETCH_MASTER_COMPONENTS:
            var masterComponents = action.payload.success;
            var masterComMap = {};
            if (masterComponents !== null && masterComponents.length > 0) {
                masterComponents.forEach(function (data) {
                    masterComMap[data.ID] = data;
                });
            }
            return {
                ...state,
                fetchMasterComponentsProgress: false,
                masterComponentsMap: masterComMap
            };

        case START_FETCH_NEXT_COMPONENT:
            return {
                ...state,
                nextComponentDetails: null,
                fetchNextComponentProgress: true,
            }
        case END_FETCH_NEXT_COMPONENT:
            return {
                ...state,
                nextComponentDetails: action.payload.success,
                fetchNextComponentProgress: false,
            };
        case START_EXPORT_WORKFLOW_DATA: {
            return {
                ...state,
                fetchExportWorkflowDataLInk: null,
                fetchExportWorkflowDataLInkProg: true,
            };
        }
        case END_EXPORT_WORKFLOW_DATA: {
            let fetchExportWorkflowDataLInk = state.fetchExportWorkflowDataLInk
            if (action.payload.error === null) {
                fetchExportWorkflowDataLInk = action.payload.success?.path || null
            }
            return {
                ...state,
                fetchExportWorkflowDataLInk,
                fetchExportWorkflowDataLInkProg: false,
            };
        }

        case START_FETCH_ORG_WORKFLOW_COMPONENTS:
            return {
                ...state,
                fetchOrgWorkflowComponentsProgress: true,
                orgWorkflowComponentsDatas: [],
                orgWorkflowComponentsMap: {}
            }
        case END_FETCH_ORG_WORKFLOW_COMPONENTS:
            var orgWorkflowComponents = action.payload.success;
            var orgWorkflowComMap = {};
            if (orgWorkflowComponents !== null && orgWorkflowComponents.length > 0) {
                orgWorkflowComponents.forEach(function (data) {
                    orgWorkflowComMap[data.ID] = data;
                });
            }

            return {
                ...state,
                fetchOrgWorkflowComponentsProgress: false,
                orgWorkflowComponentsDatas: orgWorkflowComponents,
                orgWorkflowComponentsMap: orgWorkflowComMap
            };

        case START_FETCH_WORKFLOW_COMPONENT_DETAILS:
            return {
                ...state,
                workflowComponentDetail: null,
                fetchworkflowComponentDetailProgress: true,
            }
        case END_FETCH_WORKFLOW_COMPONENT_DETAILS:
            return {
                ...state,
                workflowComponentDetail: action.payload.success,
                fetchworkflowComponentDetailProgress: false,
            };

        case START_FETCH_COMPONENT_DETAILS:
            return {
                ...state,
                componentDetail: null,
                fetchComponentDetailProgress: true,
            }
        case END_FETCH_COMPONENT_DETAILS:
            return {
                ...state,
                componentDetail: action.payload.success,
                fetchComponentDetailProgress: false,
            };

        case START_FETCH_WORKFLOW_COMPONENT_FORM:
            return {
                ...state,
                workflowComponentsFormsInProg: true,
                workflowComponentsForms: null
            }
        case END_FETCH_WORKFLOW_COMPONENT_FORM:
            return {
                ...state,
                workflowComponentsFormsInProg: false,
                workflowComponentsForms: action.payload.success
            };

        case START_FETCH_WORKFLOW_COMPONENT_USER_RESPONSE:
            return {
                ...state,
                componentUserResponse: [],
                fetchComponentUserResponseProgress: false,
            }

        case END_FETCH_WORKFLOW_COMPONENT_USER_RESPONSE:
            return {
                ...state,
                componentUserResponse: action.payload.success,
                fetchComponentUserResponseProgress: true,
            };

        case START_ADD_WORKFLOW_COMPONENT_USER_RESPONSE:
            return {
                ...state,
                addComponentUserResponseProgress: true,
                userResponseSaveType: null
            }

        case END_ADD_WORKFLOW_COMPONENT_USER_RESPONSE:
            return {
                ...state,
                componentUserResponse: action.payload.success,
                userResponseSaveType: action.payload.saveType,
                addComponentUserResponseProgress: false,
            };

        case START_ADD_CUSTOM_COMPONENT_RESPONSE:
            return {
                ...state,
                addCustomComponentUserResponseProgress: true,
                componentUserResponseError: null
            }

        case END_ADD_CUSTOM_COMPONENT_RESPONSE:
            return {
                ...state,
                componentUserResponse: action.payload.success,
                componentUserResponseError: action.payload.error,
                addCustomComponentUserResponseProgress: false,
            };

        case SHOW_EVALUATION_GUIDE_FORM_DIALOG:
            return {
                ...state,
                isShowEvaluationGuideFormDialog: true,
            }

        case HIDE_EVALUATION_GUIDE_FORM_DIALOG:
            return {
                ...state,
                isShowEvaluationGuideFormDialog: false,
            };
        case SHOW_ADD_FORM_COLUMNS_DIALOG:
            return {
                ...state,
                isOpenAddFormColumnsDialog: true,
                selectedFormColumns: action.payload.selectedColumns || []
            }

        case HIDE_ADD_FORM_COLUMNS_DIALOG:
            return {
                ...state,
                isOpenAddFormColumnsDialog: false,
                selectedFormColumns: []
            };
        case START_FETCH_WORKFLOW_FORM_RESPONSE:
            return {
                ...state,
                fetchWokflowFormResponseProgress: true,
                workflowFormResponse: null
            }

        case END_FETCH_WORKFLOW_FORM_RESPONSE:
            return {
                ...state,
                fetchWokflowFormResponseProgress: false,
                workflowFormResponse: action.payload.success
            }

        case SET_WORKFLOW_LOADER_STATUS:
            return {
                ...state,
                isShowWorkflowLoader: action.payload.status,
            }

        case START_FETCH_WORKFLOW_USER_TIMELINE:
            return {
                ...state,
                fetchWokflowUserTimelineProgress: true,
                userTimeline: null
            }

        case END_FETCH_WORKFLOW_USER_TIMELINE:
            return {
                ...state,
                fetchWokflowUserTimelineProgress: false,
                userTimeline: action.payload.success
            }

        case START_FETCH_WORKFLOW_FORM_USERWISE_RESPONSE:
            return {
                ...state,
                fetchWokflowFormResponseProgress: true,
                workflowFormResponse: null
            }

        case END_FETCH_WORKFLOW_FORM_USERWISE_RESPONSE:
            return {
                ...state,
                fetchWokflowFormResponseProgress: false,
                workflowFormResponse: action.payload.success
            }

        case SHOW_START_NEW_WORKFLOW_DIALOG:
            return {
                ...state,
                showNewWorkflowStartDialog: true,
                startWorkflowForProduct: action.payload.product
            }

        case HIDE_START_NEW_WORKFLOW_DIALOG:
            return {
                ...state,
                showNewWorkflowStartDialog: false,
                startWorkflowForProduct: null
            }

        case START_NEW_WORKFLOW_FOR_PRODUCT:
            return {
                ...state,
                startNewWorkflowProgress: true,
                startNewWorkflowError: null,
                workflowData: {}
            };

        case END_NEW_WORKFLOW_FOR_PRODUCT:
            return {
                ...state,
                startNewWorkflowProgress: false,
                startNewWorkflowError: action.payload.error,
                workflowData: action.payload.success
            };

        case START_FETCH_WORKFLOW_CONFIGURATIONS:
            return {
                ...state,
                fetchWorkflowConfigProgress: true,
                fetchWorkflowConfigError: null,
            }

        case END_FETCH_WORKFLOW_CONFIGURATIONS:
            return {
                ...state,
                fetchWorkflowConfigProgress: false,
                fetchWorkflowConfigError: action.payload.error,
                workflowConfigurations: action.payload.success,
            }

        case SHOW_PARALLEL_COMPONENTS_DIALOG:
            return {
                ...state,
                isShowParallelDialog: true,
                parallelComponentsData: action.payload.items
            }

        case HIDE_PARALLEL_COMPONENTS_DIALOG:
            return {
                ...state,
                isShowParallelDialog: false,
                parallelComponentsData: null
            }

        case START_FETCH_WORKFLOW_PROGRESS_NEW:
            return {
                ...state,
                fetchWorkflowProgress: true,
                fetchWorkflowError: null,
                workflowProgressData: null
            }

        case END_FETCH_WORKFLOW_PROGRESS_NEW:
            return {
                ...state,
                fetchWorkflowProgress: false,
                fetchWorkflowError: action.payload.error,
                workflowProgressData: action.payload.success
            }
        case START_FETCH_WORKFLOW_PROGRESS_STATUS:
            return {
                ...state,
                fetchWorkflowStatusProgress: true,
                fetchWorkflowStatusError: null,
                workflowStatusProgressData: null
            }

        case END_FETCH_WORKFLOW_PROGRESS_STATUS:
            return {
                ...state,
                fetchWorkflowStatusProgress: false,
                fetchWorkflowStatusError: action.payload.error,
                workflowProgressStatusData: action.payload.success
            }

        case START_FETCH_WORKFLOWS:
            return {
                ...state,
                fetchWorkflowsProgress: true,
                fetchWorkflowsError: null,
            };

        case END_FETCH_WORKFLOWS:
            var workflowData = {
                Data: [],
                total: 0
            };
            const activeTab = action.payload.activeTab;
            var response_completed = action.payload.success;
            if (response_completed !== null) {
                workflowData.total = response_completed.total;
                workflowData.Data = response_completed.Data;
            }

            let orgWorkflowsTotal = state.orgWorkflowsTotal;
            let orgWorkflows = state.orgWorkflows;
            orgWorkflowsTotal[activeTab] = workflowData.total;
            orgWorkflows[activeTab] = workflowData.Data;

            return {
                ...state,
                fetchWorkflowsProgress: false,
                fetchWorkflowsError: action.payload.error,
                orgWorkflows: orgWorkflows,
                orgWorkflowsTotal: orgWorkflowsTotal
            };
        case START_FETCH_VISUALIZATION_WORKFLOWS:
            return {
                ...state,
                fetchVisualizationWorkflowsProgress: {...state.fetchVisualizationWorkflowsProgress, [action.payload.id]: true},
                fetchVisualizationWorkflowsError: null,
            };
    
        case END_FETCH_VISUALIZATION_WORKFLOWS:
            var workflowVisualizationData = {
                Data: [],
                total: 0
            };
            const id = action.payload.id;
            response_completed = action.payload.success;
            if (response_completed !== null) {
                workflowVisualizationData.total = response_completed.total;
                workflowVisualizationData.Data = response_completed.Data;
            }

            let orgVisualizationWorkflowsTotal = state.orgVisualizationWorkflowsTotal;
            let orgVisualizationWorkflows = state.orgVisualizationWorkflows;
            orgVisualizationWorkflowsTotal[id] = workflowVisualizationData.total;
            orgVisualizationWorkflows[id] = workflowVisualizationData.Data;

            return {
                ...state,
                fetchVisualizationWorkflowsProgress: {...state.fetchVisualizationWorkflowsProgress, [action.payload.id]: false},
                fetchVisualizationWorkflowsError: action.payload.error,
                orgVisualizationWorkflows: orgVisualizationWorkflows,
                orgVisualizationWorkflowsTotal: orgVisualizationWorkflowsTotal
            };

        case START_WEB_HOOK_RUN:
            return {
                ...state,
                wekHookRunProgress: true,
                wekHookRunError: null,
                wekHookRunSuccess: null,
            }

        case END_WEB_HOOK_RUN:
            return {
                ...state,
                wekHookRunProgress: false,
                wekHookRunError: action.payload.error,
                wekHookRunSuccess: action.payload.success,
            }

        case START_GET_WORKFLOW_COMPONENT_DETAILS:
            return {
                ...state,
                evComponentData: null,
                fetchevComponentDataProgress: true,
                fetchevComponentDataError: null,
            }

        case END_GET_WORKFLOW_COMPONENT_DETAILS:
            return {
                ...state,
                evComponentData: action.payload.success,
                fetchevComponentDataProgress: false,
                fetchevComponentDataError: action.payload.error,
            };

        case SET_EVALUATION_SUMMARY_TYPE:
            return {
                ...state,
                evaluationSummaryType: action.payload.type
            }

        case START_ADD_WORKFLOW_SELLER_INSTRUCTION:
            return {
                ...state,
                addSellerInstructionProgress: true,
                addSellerInstructionError: null,
                addSellerInstructionSuccess: null,
            }

        case END_ADD_WORKFLOW_SELLER_INSTRUCTION:
            return {
                ...state,
                addSellerInstructionProgress: false,
                addSellerInstructionError: action.payload.error,
                addSellerInstructionSuccess: action.payload.success,
            }

        case START_FETCH_SELLER_INSTRUCTION:
            return {
                ...state,
                fetchSellerInstructionProgress: true,
                fetchSellerInstructionError: null,
                sellerInstruction: null,
            }

        case END_FETCH_SELLER_INSTRUCTION:
            return {
                ...state,
                fetchSellerInstructionProgress: false,
                fetchSellerInstructionError: action.payload.error,
                sellerInstruction: action.payload.success,
            }

        case START_FETCH_WORKFLOWS_PROJECT_TRACKING:
            return {
                ...state,
                fetchWorkflowsTrackingProgress: true,
                fetchWorkflowsTrackingError: null,
            };

        case END_FETCH_WORKFLOWS_PROJECT_TRACKING:
            var workflowDataTracking = {
                Data: [],
                total: 0
            };
            var tracking_response_completed = action.payload.success;
            // let fields = [];

            if (tracking_response_completed !== null) {
                workflowDataTracking.total = tracking_response_completed.total;
                workflowDataTracking.Data = tracking_response_completed.Data;
            }

            // console.log(workflowDataTracking,'workflowDataTracking555')

            return {
                ...state,
                fetchWorkflowsTrackingProgress: false,
                fetchWorkflowsTrackingError: action.payload.error,
                orgWorkflowsTracking: workflowDataTracking.Data,
                // trackingFields: fields,
                orgWorkflowsTrackingTotal: workflowDataTracking.total
            };
        case START_FETCH_VISUALIZATION_WORKFLOWS_PROJECT_TRACKING:
            return {
                ...state,
                fetchVisualizationProjectTrackingProgress: {...state.fetchVisualizationProjectTrackingProgress, [action.payload.id]: true},
                fetchVisualizationProjectTrackingError: null,
            };
    
        case END_FETCH_VISUALIZATION_WORKFLOWS_PROJECT_TRACKING:
            workflowDataTracking = {
                Data: [],
                total: 0
            };
            const Vid = action.payload.id;
            response_completed = action.payload.success;
            if (response_completed !== null) {
                workflowDataTracking.total = response_completed.total;
                workflowDataTracking.Data = response_completed.Data;
            }

            let orgVisualizationProjectTrackingTotal = state.orgVisualizationProjectTrackingTotal;
            let orgVisualizationProjectTracking = state.orgVisualizationProjectTracking;
            orgVisualizationProjectTrackingTotal[Vid] = workflowDataTracking.total;
            orgVisualizationProjectTracking[Vid] = workflowDataTracking.Data;

            return {
                ...state,
                fetchVisualizationProjectTrackingProgress: {...state.fetchVisualizationProjectTrackingProgress, [action.payload.id]: false},
                fetchVisualizationProjectTrackingError: action.payload.error,
                orgVisualizationProjectTracking: orgVisualizationProjectTracking,
                orgVisualizationProjectTrackingTotal: orgVisualizationProjectTrackingTotal
            };

        case START_FETCH_PROJECT_TRACKING:
            return {
                ...state,
                fetchProjectTrackingProgress: true,
                fetchProjectTrackingError: null,
                fetchProjectTrackingSuccess: null,
            }

        case END_FETCH_PROJECT_TRACKING:
            return {
                ...state,
                fetchProjectTrackingProgress: false,
                fetchProjectTrackingError: action.payload.error,
                fetchProjectTrackingSuccess: action.payload.success,
            }

        case START_ADD_PROJECT_TRACKING_RESPONSE:
            return {
                ...state,
                addProjectTrackingProgress: true,
                addProjectTrackingError: null,
                addProjectTrackingSuccess: null,
            }

        case END_ADD_PROJECT_TRACKING_RESPONSE:
            return {
                ...state,
                addProjectTrackingProgress: false,
                addProjectTrackingError: action.payload.error,
                addProjectTrackingSuccess: action.payload.success,
            }

        case SET_ACTIVE_PROJECTS_TAB:
            return {
                ...state,
                activeProjectsTab: action.payload.tab
            }

        case START_FETCH_FINISHED_WORKFLOW_COMPONENTS:
            return {
                ...state,
                fetchFinishComponentsProgress: true,
                fetchFinishComponentsError: null,
                finishedComponents: null,
            }

        case END_FETCH_FINISHED_WORKFLOW_COMPONENTS:
            return {
                ...state,
                fetchFinishComponentsProgress: false,
                fetchFinishComponentsError: action.payload.error,
                finishedComponents: action.payload.success,
            }

        case START_FETCH_EVALUATION_WORKFLOW_MAPPING:
            return {
                ...state,
                fetchWorkflowEvaluationMappingProgress: true,
                fetchWorkflowEvaluationMappingError: null,
                workflowEvaluationMapping: null,
            }

        case END_FETCH_EVALUATION_WORKFLOW_MAPPING:
            return {
                ...state,
                fetchWorkflowEvaluationMappingProgress: false,
                fetchWorkflowEvaluationMappingError: action.payload.error,
                workflowEvaluationMapping: action.payload.success,
            }

        case START_POPULATE_EVALUATION_TEMPLATE_DATA:
            return {
                ...state,
                fetchPopulateDataProgress: true,
                fetchPopulateDataError: null,
                populatedDatas: null,
            }

        case END_POPULATE_EVALUATION_TEMPLATE_DATA:
            return {
                ...state,
                fetchPopulateDataProgress: false,
                fetchPopulateDataError: action.payload.error,
                populatedDatas: action.payload.success,
            }

        case START_FETCH_PROJECT_SCHEMA:
            return {
                ...state,
                fetchProjectSchemaProgress: true,
                fetchProjectSchemaError: null,
                projectSchema: null,
            }

        case END_FETCH_PROJECT_SCHEMA:
            return {
                ...state,
                fetchProjectSchemaProgress: false,
                fetchProjectSchemaError: action.payload.error,
                projectSchema: action.payload.success,
            }


        case START_FETCH_EMAIL_COMPONENT_ACTIVITY:
            return {
                ...state,
                fetchEmailComponentActivityProgress: true,
                fetchEmailComponentActivityError: null,
                emailComponentActivity: null
            }
        case END_FETCH_EMAIL_COMPONENT_ACTIVITY:
            return {
                ...state,
                fetchEmailComponentActivityProgress: false,
                fetchEmailComponentActivityError: action.payload.error,
                emailComponentActivity: action.payload.success
            }
        case START_ADD_EMAIL_COMPONENT_ACTIVITY:
            return {
                ...state,
                addEmailComponentActivityProgress: true,
                addEmailComponentActivityError: null,
                addEmailComponentActivitySuccess: null
            }
        case END_ADD_EMAIL_COMPONENT_ACTIVITY:
            return {
                ...state,
                addEmailComponentActivityProgress: false,
                addEmailComponentActivityError: action.payload.error,
                addEmailComponentActivitySuccess: action.payload.success
            }
        case START_FETCH_COMPONENT_COLLOBORATOR:
            return {
                ...state,
                fetchComponentColloboratorProgress: true,
                fetchComponentColloboratorError: null,
                componentColloborators: null
            }
        case END_FETCH_COMPONENT_COLLOBORATOR:
            return {
                ...state,
                fetchComponentColloboratorProgress: false,
                fetchComponentColloboratorError: action.payload.error,
                componentColloborators: action.payload.success
            }
        case START_ADD_COMPONENT_COLLOBORATOR:
            return {
                ...state,
                addComponentColloboratorProgress: true,
                addComponentColloboratorError: null,
                addComponentColloboratorSuccess: null
            }
        case END_ADD_COMPONENT_COLLOBORATOR:
            return {
                ...state,
                addComponentColloboratorProgress: false,
                addComponentColloboratorError: action.payload.error,
                addComponentColloboratorSuccess: action.payload.success
            }

        case START_DELETE_COMPONENT_COLLOBORATOR:
            return {
                ...state,
                deleteComponentColloboratorProgress: true,
                deleteComponentColloboratorError: null,
                deleteComponentColloboratorSuccess: null
            }

        case END_DELETE_COMPONENT_COLLOBORATOR:
            return {
                ...state,
                deleteComponentColloboratorProgress: false,
                deleteComponentColloboratorError: action.payload.error,
                deleteComponentColloboratorSuccess: action.payload.success
            }


        case START_FETCH_PROJECT_REQUEST_TYPES:
            return {
                ...state,
                fetchProjectReqTypesProgress: true,
                fetchProjectReqTypesError: null,
                requestTypes: []
            }

        case END_FETCH_PROJECT_REQUEST_TYPES:
            return {
                ...state,
                fetchProjectReqTypesProgress: false,
                fetchProjectReqTypesError: action.payload.error,
                requestTypes: action.payload.success
            }

        case START_GET_COMPONENT_PERMISSION:
            return {
                ...state,
                getComponentPermissionProgress: true,
                getComponentPermissionError: null,
                componentPermission: null
            }

        case END_GET_COMPONENT_PERMISSION:
            return {
                ...state,
                getComponentPermissionProgress: false,
                getComponentPermissionError: action.payload.error,
                componentPermission: action.payload.success
            }

        case START_VENDOR_SEND_ALL_QUESTIONS:
            return {
                ...state,
                sendVendorQuestionsProgress: true,
                sendVendorQuestionsError: null,
                sendVendorQuestionsSuccess: null
            }

        case END_VENDOR_SEND_ALL_QUESTIONS:
            return {
                ...state,
                sendVendorQuestionsProgress: false,
                sendVendorQuestionsError: action.payload.error,
                sendVendorQuestionsSuccess: action.payload.success
            }

        default:
            return state;
    }
}