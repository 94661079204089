import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const SHOW_PRODUCT_CLAIM_DIALOG = "SHOW_PRODUCT_CLAIM_DIALOG";
export const HIDE_PRODUCT_CLAIM_DIALOG = "HIDE_PRODUCT_CLAIM_DIALOG";

export const START_CLAIM_PRODUCT = "START_CLAIM_PRODUCT";
export const END_CLAIM_PRODUCT = "END_CLAIM_PRODUCT";

export const START_FETCH_SELLER_PRODUCT = "START_FETCH_SELLER_PRODUCT";
export const END_FETCH_SELLER_PRODUCT = "END_FETCH_SELLER_PRODUCT";

export function showClaimProductDialog(query){
    return {
        type: SHOW_PRODUCT_CLAIM_DIALOG,
        payload: query || null
    };
}

export function hideClaimProductDialog(){
    return {
        type: HIDE_PRODUCT_CLAIM_DIALOG,
        payload:null
    };
}

function startFetchSellerProduct() {
    return {
        type: START_FETCH_SELLER_PRODUCT,
        payload: {}
    }
}

function endFetchSellerProduct(success, error) {
    return {
        type: END_FETCH_SELLER_PRODUCT,
        payload: {
            success,
            error
        }
    }
}

// status = [] ex: [1,2,3]
export function fetchSellerProducts(status = [1, 2], data = {}) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/fetch/seller/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchSellerProduct());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, { status, ...data });
            dispatch(endFetchSellerProduct(response, error));
        } catch (e) {
            dispatch(endFetchSellerProduct(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startClaimProduct(productIds) {
    return {
        type: START_CLAIM_PRODUCT,
        payload: {
            productIds,
        }
    }
}

function endClaimProduct(productIds, success, error) {
    return {
        type: END_CLAIM_PRODUCT,
        payload: {
            productIds,
            success,
            error
        }
    }
}

export function claimProduct(productIds) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/seller/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startClaimProduct(productIds));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, { product_ids: productIds });
            dispatch(endClaimProduct(productIds, response, error));
        } catch (e) {
            dispatch(endClaimProduct(productIds, null, {
                message: e.message
            }));
            return;
        }
    };
}