import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import classnames from "classnames";

import {
  Typography, CircularProgress
} from '@material-ui/core'

import { withRouter } from 'react-router-dom';
import { addWorkflowComponentUserResponse, addCustomFormResponse, fetchWorkflowFormResponse } from "redux/workflow/action";
import { searchUsers } from "redux/usersSettings/action";
import { fetchOrgGroup } from "redux/org/group/action";
import { fetchWorkFlowCustomForm } from "redux/evaluation/workflow/action";
import CustomReply from 'Components/Common/FormReply/CustomReply';
import BottomActions from "./BottomActions";

import { fetchOrgFormUsingId } from "redux/forms/org/action";
import { fetchMasterFormUsingId } from "redux/forms/master/action";
import { fetchSimilarExistingProducts } from "redux/product/orgProduct/action";
import Alert from '@material-ui/lab/Alert';

import EvaluationBar from "../../../Common/EvaluationBar";
// import * as Validator from "util/Validation";
import { fetchEvaluationMetaData } from "redux/evaluation/metadata/action";
import { getNextComponent } from "util/Common";

import { showSearchTeamsPoperDialog, hideSearchTeamsPoperDialog, showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import SearchTeamsPoper from "Components/Common/TeamsAndUser/SearchTeamsPoper";
import SearchUserPoper from "Components/Common/TeamsAndUser/SearchUserPoper";
import * as Validator from "util/Validation";

const connectedProps = (state) => ({
  OrgID: state.authUser.user.OrgID,
  user: state.authUser.user,
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  componentUserResponseError: state.workflow.componentUserResponseError,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  fetchCustomFormProgress: state.evaluationWorkflow.fetchCustomFormProgress,
  workflowCustomForm: state.evaluationWorkflow.workflowCustomForm,
  addCustomComponentUserResponseProgress: state.workflow.addCustomComponentUserResponseProgress,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
  orgFormData: state.orgForms.orgFormData,
  fetchOrgFormsByIdProgress: state.orgForms.fetchOrgFormsByIdProgress,
  fetchMasterFormsByIdProgress: state.masterForms.fetchMasterFormsByIdProgress,
  masterFormData: state.masterForms.masterFormData,
  fetchWokflowFormResponseProgress: state.workflow.fetchWokflowFormResponseProgress,
  workflowFormResponse: state.workflow.workflowFormResponse,
  similarExistingProductProgress: state.orgProduct.similarExistingProductProgress,
  similarExistingProducts: state.orgProduct.similarExistingProducts,
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  userTimeline: state.workflow.userTimeline,
  fetchWokflowUserTimelineProgress: state.workflow.fetchWokflowUserTimelineProgress,
  isOpenTeamDialog: state.dialogs.isShowSearchTeamPoperDialog,
  isOpenUserDialog: state.dialogs.isShowSearchUserPoperDialog,
});

const connectionActions = {
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  fetchWorkFlowCustomForm: fetchWorkFlowCustomForm,
  addCustomFormResponse: addCustomFormResponse,
  fetchUsers: searchUsers,
  fetchOrgGroup: fetchOrgGroup,
  fetchOrgFormUsingId: fetchOrgFormUsingId,
  fetchMasterFormUsingId: fetchMasterFormUsingId,
  fetchWorkflowFormResponse: fetchWorkflowFormResponse,
  fetchSimilarExistingProducts: fetchSimilarExistingProducts,
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  showSearchTeamsPoperDialog: showSearchTeamsPoperDialog,
  hideSearchTeamsPoperDialog: hideSearchTeamsPoperDialog,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  topRoot: {
    height: '89.5vh',
    overflow: 'scroll',
  },
  root: {
    // background: '#fff',
    padding: 20,
    width: '80%',
    margin: '0px auto',
    paddingBottom: 100
  },
  actions: {
    textAlign: "center",
    marginTop: theme.spacing(2)
  },
  actionBtn: {
    minWidth: '200px'
  },
  actionBtnDisabled: {
    minWidth: '200px',
  },
  dialogContent: {
    margin: 'auto',
    marginBottom: 20,
    "& [class*=MuiAvatar-root]": {
      width: 30,
      height: 30,
      fontSize: 14
    },
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  stepHead: {
    color: '#282D30',
    fontSize: 18,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  stepSubHead: {
    color: '#4B4B4B',
    fontSize: 12,
    margin: 0,
    paddingBottom: 20
  },
  customForm: {
    marginTop: 20,
    borderRadius: 8,
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    marginBottom: 15,
    padding: 20,
  },
  footer: {
    textAlign: 'right'
  },
  section: {
    margin: 0,
    // border: '1px solid #f1f1f1',
    // padding: 20
  },
  sectionItem: {
    padding: 20,
    borderRadius: 8,
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    marginBottom: 15,
  },
  sectionTitle: {
    color: '#282D30',
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  loader: {
    textAlign: "center",
    padding: theme.spacing(10, 0)
  },
  alert: {
    padding: '10px'
  },
  topHeadColor: {
    background: '#1f73b7',
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    height: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%'
  },
  disableForm: {
    pointerEvents: 'none'
  }
});

class IntegrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customFormTitle: '',
      customFormDescription: '',
      customFormData: [],
      isShowLoader: true,
      formConfigDetails: {},
      formError: '',
      showSectionMap: {},
      showSectionResponseMatch: {},
      itemIdsForSection: {},
      saveType: 'NEXT',
      isFormEditable: true,
      componentStatus: null,
      nextComponent: null,
      nextClickLoaderSave: false,
      nextClickLoader: false,
      currentSelected: null,
      IntegrationFormData:null,
      webHookItemIdMap:{}
    }
    this.avatarName = this.avatarName.bind(this);
    this.submitResponse = this.submitResponse.bind(this);
    this.setSelectedTeams = this.setSelectedTeams.bind(this);
    this.setSelectedUsers = this.setSelectedUsers.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this)
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  componentDidMount() {
    if (this.props.isWorkFlow) {
      this.checkWorkflowConfiguration();
    }
    this.props.fetchUsers(0, 30, "");
    this.props.fetchOrgGroup(0, 30, "");

    if (this.props.evaluation !== null) {
      this.props.fetchSimilarExistingProducts(this.props.evaluation.Products.map((p) => {
        return p.ID
      }))
    }

    this.props.fetchEvaluationMetaData(this.props.evaluation_id);

    // console.log(this.props.adminForward, 'adminForward1111');
    // console.log(this.props.component,'componentDetails')
    // console.log(this.props.componentId,'componentId')
    // console.log(this.props.workflowComponentId,'workflowComponentId')
    // this.props.fetchWorkFlowCustomForm(this.props.workflow_id, this.props.component_id);
    this.checkForStatus();
  }

  componentDidUpdate(prevProps) {

    if (this.props.addCustomComponentUserResponseProgress === false && prevProps.addCustomComponentUserResponseProgress === true) {
      if (this.props.componentUserResponseError === null) {
        var responseDatas = [];
        this.props.workflowComponentsForms.forEach((data) => {
          if (data.IsConfiguration === false) {
            var obj = {
              "form_id": data.ID
            }
            var value = '';
            if (data.Name === 'evaluation.name') {
              value = this.state.name;
            }
            obj['value'] = value;
            responseDatas.push(obj);
          }
        });

        this.props.addWorkflowComponentUserResponse(this.props.evaluation_id, this.props.workflowComponentId, {
          "data": responseDatas,
          "forward": this.state.saveType === 'NEXT' ? true : false
        });

      } else {
        alert('Something Went Wrong');
      }
    }

    if (this.props.workflowComponentsFormsInProg === false && prevProps.workflowComponentsFormsInProg === true) {
      if (this.props.isWorkFlow) {
        this.checkWorkflowConfiguration();
      }
    }

    if (this.props.fetchOrgFormsByIdProgress === false && prevProps.fetchOrgFormsByIdProgress === true) {
      this.checkConditions('ORG');
    }

    if (this.props.fetchMasterFormsByIdProgress === false && prevProps.fetchMasterFormsByIdProgress === true) {
      this.checkConditions('MASTER');
    }

    if (this.props.fetchWokflowFormResponseProgress === false && prevProps.fetchWokflowFormResponseProgress === true) {
      this.checkResponses();
    }

    if (this.props.fetchWokflowUserTimelineProgress === false && prevProps.fetchWokflowUserTimelineProgress === true) {
      this.checkForStatus();
    }

  }

  checkForStatus() {
    const { userTimeline } = this.props;
    this.setState({
      isFormEditable: false,
    })
    if (userTimeline !== null && userTimeline !== undefined) {
      const workflowComId = parseInt(this.props.workflowComponentId);
      var componentStatus = userTimeline.filter(o => o.ComponentId === workflowComId)[0]
      let componentIndex = userTimeline.findIndex(o => o.ComponentId === workflowComId);

      let nextComponent;
      if (userTimeline[componentIndex++] !== undefined) {
        nextComponent = userTimeline[componentIndex++]
      }


      if (componentStatus !== undefined && componentStatus !== null && componentStatus.Status === 2) {
        this.setState({
          isFormEditable: false,
          componentStatus: componentStatus,
          nextComponent: nextComponent
        })
      }
    } else {
      this.setState({
        isFormEditable: true,
      })
    }
  }

  checkResponses() {
    const { workflowFormResponse } = this.props;
    const { customFormData, formConfigDetails } = this.state;
    var me = this;
    if (customFormData === null || customFormData.length <= 0 || workflowFormResponse === null || workflowFormResponse.length <= 0) {
      return;
    }

    var responseMap = {};
    workflowFormResponse.forEach(function (item) {
      responseMap[item.ItemId] = item;
    });

    var formType = formConfigDetails?.type;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    var customFormDataNew = this.state.customFormData;
    customFormDataNew[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        if (responseMap[item.ID] === undefined) {
          return;
        }
        var value = '';
        try {
          value = JSON.parse(responseMap[item.ID].Data)
        } catch (error) {
        }
        item['value'] = value;
        if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
          me.updateShowSection(item, value);
        }
        if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
          me.updateShowSectionForNumbers(item, value)
        }
      })
    });
    this.setState({
      customFormData: customFormDataNew
    })
  }

  checkWorkflowConfiguration() {
    const { workflowComponentsForms, workflowComponentDetail } = this.props;
    if (workflowComponentsForms === null || workflowComponentDetail === null) {
      return;
    }

    var configurations = workflowComponentDetail.OrgWorkflowComponentConfigurations;
    if (configurations === undefined || configurations === null || configurations.length <= 0) {
      this.setState({
        isHideAskSeller: true
      })
    } else {
      var customFormId = workflowComponentsForms.filter(o => o.Name === 'workflow_form_template').map(o => o.ID)[0];
      var customFormValue = configurations.filter(o => o.FormID === customFormId).map(o => o.Value)[0];
     
      var integrationFormId = workflowComponentsForms.filter(o => o.Name === 'integration_form_data').map(o => o.ID)[0];
      var integrationFormValues = configurations.filter(o => o.FormID === integrationFormId).map(o => o.Value)[0];
      try {
        this.setState({
          formConfigDetails: JSON.parse(customFormValue),
          IntegrationFormData:JSON.parse(integrationFormValues)
        }, () => {
          customFormValue = JSON.parse(customFormValue);
          if (customFormValue?.type === "MASTER") {
            this.props.fetchMasterFormUsingId(customFormValue?.id);
          } else {
            this.props.fetchOrgFormUsingId(customFormValue?.id);
          }
          this.props.fetchWorkflowFormResponse(this.props.evaluation_id, customFormValue?.id, customFormValue?.type, this.props.workflowComponentId)
          this.createIntegrationFormMap()
        })
      } catch (error) {
    }
    }
  }

  createIntegrationFormMap(){
    const {IntegrationFormData} = this.state;
    let webHookItemIdMap = {};
    if(IntegrationFormData !== undefined && IntegrationFormData !== null && IntegrationFormData !== '' && IntegrationFormData.items !== undefined && IntegrationFormData.items.length > 0){
      IntegrationFormData.items.forEach(function (itm) {
        webHookItemIdMap[itm.ID] = itm.additional_data;
      })
    }
    this.setState({
      webHookItemIdMap : webHookItemIdMap
    })
    console.log(webHookItemIdMap,'webHookItemIdMap')
  }

  checkConditions(type) {
    const orgFormData = type === 'ORG' ? this.props.orgFormData : this.props.masterFormData;
    // const { orgFormData } = this.props;
    const { formConfigDetails } = this.state;

    let showSectionMap = {};
    let showSectionResponseMatch = {};
    let itemIdsForSection = {};
    var formType = formConfigDetails?.type;

    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    if(orgFormData){
      orgFormData[sectionName].forEach(function (section, k) {
        var item = section[sectionItemName][0];
        if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
          var options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];
          showSectionResponseMatch[item.ID] = {};
          itemIdsForSection[section.ID] = item.ID;
          options.forEach(function (op) {
            if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
              showSectionMap[op.condition.value] = false;
              showSectionResponseMatch[item.ID][op.value] = op.condition;
            }
          })
        }
        if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
          var num_options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];
          showSectionResponseMatch[item.ID] = {};
          itemIdsForSection[section.ID] = item.ID;
          num_options.forEach(function (op) {
            if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
              showSectionMap[op.condition.value] = false;
              showSectionResponseMatch[item.ID][op.value] = op.condition;
            }
          })
        }
      })
    }
    this.setState({
      customFormTitle: orgFormData?.FormTitle,
      customFormDescription: orgFormData?.FormDescription,
      customFormData: orgFormData,
      showSectionMap: showSectionMap,
      showSectionResponseMatch: showSectionResponseMatch,
      itemIdsForSection: itemIdsForSection,
      isShowLoader: false
    }, () => {
      this.checkResponses();
    })
  }

  updateShowSection(item, response) {
    var showSectionMap = this.state.showSectionMap;
    var showSectionResponseMatch = this.state.showSectionResponseMatch;
    const { itemIdsForSection } = this.state;
    var me = this;
    if (showSectionResponseMatch[item.ID] !== undefined) {
      var datas = showSectionResponseMatch[item.ID];
      let selectedId = null;
      Object.keys(datas).forEach(function (okey) {
        var data = datas[okey];
        if (okey === response) {
          showSectionMap[data.value] = true;
          selectedId = data.value;
        } else {
          showSectionMap[data.value] = false;
          var clearResponseArr = [];
          var itemId = itemIdsForSection[data.value];
          clearResponseArr.push(data.value);
          if (showSectionResponseMatch[itemId] !== undefined) {
            var hideSectionOptions = showSectionResponseMatch[itemId];
            Object.keys(hideSectionOptions).forEach(function (jkey) {
              var opData = hideSectionOptions[jkey];
              if (selectedId === opData.value) {
                return;
              }
              showSectionMap[opData.value] = false;
              clearResponseArr.push(opData.value);
            });
          }
          me.clearResponseForSections(clearResponseArr);
        }
      })
    }

    this.setState({
      showSectionMap: showSectionMap
    })

  }

  updateShowSectionForNumbers(item, response) {
    var showSectionMap = this.state.showSectionMap;
    var showSectionResponseMatch = this.state.showSectionResponseMatch;
    const { itemIdsForSection } = this.state;
    var me = this;
    if (showSectionResponseMatch[item.ID] !== undefined) {
      var num_options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];
      var datas = showSectionResponseMatch[item.ID];
      var checkMap = {};
      num_options.forEach(function (itm) {
        checkMap[itm.value] = {
          ...itm,
          value: parseFloat(itm.label)
        }
      })
      let resValue = parseFloat(response)
      let selectedId = null;
      Object.keys(datas).forEach(function (okey) {
        var data = datas[okey];
        if (checkMap[okey] !== undefined && checkMap[okey].operation === 1 && checkMap[okey].value === resValue) {
          showSectionMap[data.value] = true;
          selectedId = data.value;
          // console.log('Operation 1 value:' + checkMap[okey].value + ' === response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 2 && resValue > checkMap[okey].value) {
          showSectionMap[data.value] = true;
          selectedId = data.value;
          // console.log('Operation 2 value:' + checkMap[okey].value + ' > response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 3 && resValue >= checkMap[okey].value) {
          showSectionMap[data.value] = true;
          selectedId = data.value;
          // console.log('Operation 3 value:' + checkMap[okey].value + ' >= response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 4 && resValue < checkMap[okey].value) {
          showSectionMap[data.value] = true;
          selectedId = data.value;
          // console.log('Operation 4 value:' + checkMap[okey].value + ' < response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 5 && resValue <= checkMap[okey].value) {
          showSectionMap[data.value] = true;
          selectedId = data.value;
          // console.log('Operation 5 value:' + checkMap[okey].value + ' <= response:' + resValue)
        } else {
          showSectionMap[data.value] = false;
          var clearResponseArr = [];
          var itemId = itemIdsForSection[data.value];
          clearResponseArr.push(data.value);
          if (showSectionResponseMatch[itemId] !== undefined) {
            var hideSectionOptions = showSectionResponseMatch[itemId];
            Object.keys(hideSectionOptions).forEach(function (jkey) {
              var opData = hideSectionOptions[jkey];
              if (selectedId === opData.value) {
                return;
              }
              showSectionMap[opData.value] = false;
              clearResponseArr.push(opData.value);
            });
          }
          me.clearResponseForSections(clearResponseArr);
        }
      })
    }

    this.setState({
      showSectionMap: showSectionMap
    })
  }

  clearResponseForSections(sectionArr) {
    const { formConfigDetails } = this.state;
    let newData = this.state.customFormData;
    var formTypeN = formConfigDetails?.type;

    var sectionName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    newData[sectionName].forEach((section, sk) => {
      if (sectionArr.indexOf(section.ID) !== -1) {
        var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
        sectionItems.forEach(function (si) {
          si.response = '';
          si.value = '';
        })
      }
    });
    this.setState({
      customFormData: newData
    })
  }

  setSelectedTeams(team, isUpdate) {
    const { customFormData, formConfigDetails, currentSelected } = this.state;
    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    let sectionId = currentSelected.SectionID;
    let itemId = currentSelected.ID;
    if (customFormData !== null && customFormData[sectionName] !== undefined) {
      let selections = customFormData[sectionName];
      var sectionIndex = selections.findIndex(s => s.ID === sectionId);
      var section = selections[sectionIndex];
      var sectionItems = section[sectionItemName];
      var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
      var item = sectionItems[itemIndex];
      if (item.value === undefined || item.value === null || item.value === '') {
        item['value'] = [team];
      } else {
        var datas = item.value;
        var index = datas.findIndex(t => t.ID === team.ID)
        if (index === -1) {
          datas.push(team);
        } else {
          datas.splice(index, 1);
        }
        item['value'] = datas;
      }
      this.setState({
        customFormData: customFormData,
        currentSelected: item
      })
    }
  }

  setSelectedUsers(user, isUpdate) {
    const { customFormData, formConfigDetails, currentSelected } = this.state;
    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    let sectionId = currentSelected.SectionID;
    let itemId = currentSelected.ID;

    if (customFormData !== null && customFormData[sectionName] !== undefined) {
      let selections = customFormData[sectionName];
      var sectionIndex = selections.findIndex(s => s.ID === sectionId);
      var section = selections[sectionIndex];
      var sectionItems = section[sectionItemName];
      var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
      var item = sectionItems[itemIndex];
      if (item.value === undefined || item.value === null || item.value === '') {
        item['value'] = [user];
      } else {
        var datas = item.value;
        var index = datas.findIndex(t => t.ID === user.ID)
        if (index === -1) {
          datas.push(user);
        } else {
          datas.splice(index, 1);
        }
        item['value'] = datas;
      }
      this.setState({
        customFormData: customFormData,
        currentSelected: item
      })
    }
  }

  cleanErrors() {
    let { customFormData, formConfigDetails } = this.state;
    var formType = formConfigDetails?.type;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    this.state.customFormData[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        item['error'] = ''
      })
    })
    this.setState({
      customFormData: customFormData
    })
  }

  submitResponse(type) {

    // let { customFormData, formConfigDetails, isFormEditable } = this.state;

    var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);
    if (nextComponent !== undefined && nextComponent !== null) {
      let redirectPath = `/app/evaluate/${nextComponent.EvaluationId}/workflow/${nextComponent.WorkflowId}/component/${nextComponent.ComponentId}`;
      this.props.history.push(redirectPath);
      return;
    }


    var responseDatas = [];
    this.props.workflowComponentsForms.forEach((data) => {
      if (data.IsConfiguration === false) {
        var obj = {
          "form_id": data.ID
        }
        var value = '';
        if (data.Name === 'evaluation.name') {
          value = this.state.name;
        }
        obj['value'] = value;
        responseDatas.push(obj);
      }
    });

    this.props.addWorkflowComponentUserResponse(this.props.evaluation_id, this.props.workflowComponentId, {
      "data": responseDatas,
      "forward": this.state.saveType === 'NEXT' ? true : false
    });

    // this.setState({
    //   formError: '',
    //   saveType: type
    // })

    // const formId = customFormData?.ID;

    // var resData = [];
    // var formType = formConfigDetails?.type;
    // var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    // var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    // var isRequiredFieldEmpty = false;
    // var isValidationError = false;
    // var validationMsg = '';

    // var requireFieldNames = [];
    // this.state.customFormData[sectionName].forEach(function (section) {
    //   var sectionItems = section[sectionItemName];
    //   sectionItems.forEach(function (item) {
    //     console.log(item,'Itemmmmmmmmmmmm')
    //     item['error'] = '';
    //     if (item.IsRequired && (item.value === undefined || item.value === "" || item.value === null)) {
    //       isRequiredFieldEmpty = true;
    //       requireFieldNames.push(item.ItemName);
    //       item.error = 'Please enter/select option.'
    //     }

    //     if (item.IsRequired && item.Type === 12 && item.value !== '' && item.value !== null) {
    //       if (Validator.validateEmail(item.value) === false) {
    //         isValidationError = true;
    //         validationMsg = 'Please enter valid email.';
    //         item.error = 'Please enter valid email.'
    //       }
    //     }

    //     if (item.IsRequired && item.Type === 14 && item.value !== '' && item.value !== null) {
    //       if (Validator.validateURL(item.value) === false) {
    //         isValidationError = true;
    //         validationMsg = 'Please enter valid URL.';
    //         item.error = 'Please enter valid URL.'
    //       }
    //     }

    //     if (item.value === undefined || item.value === "" || item.value === null) {
    //       return;
    //     }

    //     let finalValue;
    //     if (item.Type === 4 || item.Type === 18) {
    //       finalValue = JSON.stringify(String(item.value))
    //     } else if (item.Type === 5) {
    //       // let options = item.value.map(o=>String(o))
    //       finalValue = JSON.stringify(item.value)
    //     } else {
    //       finalValue = JSON.stringify(item.value)
    //     }

    //     var obj = {
    //       itemId: item.ID,
    //       value: finalValue
    //     }
    //     resData.push(obj);
    //   })
    // });

    // if (isRequiredFieldEmpty) {
    //   var nMsg = '';
    //   if (requireFieldNames.length === 1) {
    //     nMsg += ' is required field.';
    //   } else {
    //     nMsg += ' are required fields.';
    //   }
    //   var str = requireFieldNames.join(', ');
    //   str = str + nMsg;
    //   this.setState({
    //     formError: str
    //   })
    // } else if (isValidationError) {
    //   this.setState({
    //     formError: validationMsg
    //   })
    // } else {
    //   this.setState({
    //     nextClickLoader: type === 'NEXT' ? true : false,
    //     nextClickLoaderSave: type === 'SAVE' ? true : false
    //   })

    //   this.props.addCustomFormResponse(this.props.evaluation_id, formId, {
    //     form_type: formType,
    //     data: resData,
    //     componentId: parseInt(this.props.workflowComponentId)
    //   })

    // }
  }

  render() {
    const classes = this.props.classes;
    let { customFormTitle, customFormDescription, customFormData, formConfigDetails, isShowLoader, showSectionMap, isFormEditable } = this.state;

    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    if (isShowLoader) {
      return <div className={classes.loader}><CircularProgress /></div>
    }

    return <>
      {this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation.Type !== 3 && <EvaluationBar
        isShowCollabrations={false}
        isShowEvalDetails={false}
        isWorkFlow={this.props.isWorkFlow}
        isShowViewBtns={false}
      />}
      <div className={classnames(classes.topRoot)}>
        <div className={classes.root}>
          <div classes={classes.dialogContent} >
            <div className={classes.customForm}>
              <Typography variant={"h4"} className={classes.stepHead}>{customFormTitle}</Typography>
              <span className={classes.stepSubHead}>{customFormDescription}</span>
              <div className={classes.topHeadColor}></div>
            </div>
            {false && <div className={classes.alert}>
              {this.state.formError.length > 0 && <Alert variant="filled" severity="error">{this.state.formError}</Alert>}
            </div>}
            <div className={isFormEditable === false ? '' : ''}>
              {customFormData !== null && customFormData[sectionName].map((section, sk) => {
                var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
                sectionItems = sectionItems.sort(function (a, b) {
                  if (a.Seq > b.Seq) {
                    return 1;
                  } else if (a.Seq < b.Seq) {
                    return -1
                  } else {
                    return 0;
                  }
                })
                return <>
                  <div className={classes.section} key={sk}>
                    {sectionItems.map((item, key) => {
                      if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                        return '';
                      }

                      if (item.Type === 1) {
                      }
                      var options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];

                      if (item.Type === 17 && (this.props.similarExistingProducts === undefined || this.props.similarExistingProducts === null || Object.keys(this.props.similarExistingProducts).length <= 0)) {
                        return ''
                      }

                      return <><div className={classes.sectionItem} key={item.SectionID + '_section' + key}>
                        <CustomReply
                          additional_data={options}
                          type={item.Type}
                          title={item.ItemName}
                          description={item.Description}
                          isRequired={item?.IsRequired}
                          key={item.ID + '_sectionItem'}
                          index={item.ID}
                          value={(item?.value !== undefined && item?.value !== null) ? item.value : ''}
                          selectedOption={[]}
                          evaluationId={this.props.evaluation_id}
                          orgId={this.props.OrgID}
                          workflowId={this.props.workflow_id}
                          isFormEditable={true}
                          itemId={item.ID}
                          sectionId={item.SectionID}
                          item={item}
                          error={item.error || ''}
                          isOtherOption={item?.IsOtherOption}
                          otherOptionData={item?.OtherOptionData}
                          webHookItemIdMap={this.state.webHookItemIdMap}
                          onChange={(response, isOther = false) => {
                            // if(!isOther){
                            item['value'] = response;
                            if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
                              this.updateShowSection(item, response);
                            }
                            if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
                              this.updateShowSectionForNumbers(item, response);
                            }
                            this.cleanErrors();
                            // }else{
                            //   console.log(response)
                            //   if(item.Type === 19){
                            //     item['value'] = response;
                            //   }
                            //   item['OtherOptionData'] = response;
                            // }
                          }}
                          showUserAddDialog={(item) => {
                            this.setState({
                              currentSelected: item
                            }, () => {

                            })
                            this.props.showSearchUsersPoperDialog()
                          }}
                          showTeamAddDialog={(data) => {
                            this.setState({
                              currentSelected: item
                            }, () => {
                              this.props.showSearchTeamsPoperDialog()
                            })
                          }}
                        // similarExistingProducts={this.props.similarExistingProducts}
                        // similarExistingProductProgress={this.props.similarExistingProductProgress}
                        />
                      </div>
                      </>
                    })}

                  </div>
                </>
              })}
            </div>
            <BottomActions
              user={this.props.user}
              isCreatorOrAdmin={this.props.isCreatorOrAdmin}
              adminForward={this.props.adminForward}
              submitResponse={this.submitResponse}
              nextClickLoaderSave={this.state.nextClickLoaderSave}
              nextClickLoader={this.state.nextClickLoader}
              isFormEditable={isFormEditable}
            />
          </div>
        </div>

        {this.props.isOpenTeamDialog && <SearchTeamsPoper
          selectedTeams={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
          onAdd={(team) => {
            this.setSelectedTeams({
              id: team.ID,
              ID: team.ID,
              value: team.ID,
              label: team.Name,
              Name: team.Name,
            }, true)
          }}
          onRemove={(team) => {
            this.setSelectedTeams({
              id: team.ID,
              ID: team.ID,
              value: team.ID,
              label: team.Name,
              Name: team.Name,
            }, true)
          }}
          onClose={() => {
            this.props.hideSearchTeamsPoperDialog();
          }}
        />}

        {this.props.isOpenUserDialog && <SearchUserPoper
          selectedUsers={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
          onAdd={(user) => {
            this.setSelectedUsers({
              id: user.ID,
              ID: user.ID,
              value: user.ID,
              label: user.Name,
              Name: user.Name,
            }, true)
          }}
          onRemove={(user) => {
            this.setSelectedUsers({
              id: user.ID,
              ID: user.ID,
              value: user.ID,
              label: user.Name,
              Name: user.Name,
            }, true)
          }}
          onClose={() => {
            this.props.hideSearchUsersPoperDialog();
          }}
        />}

      </div>
    </>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(IntegrationForm));