import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment";
export const START_FETCH_EVALUATION_REPORT_DATA = "START_FETCH_EVALUATION_REPORT_DATA";
export const END_FETCH_EVALUATION_REPORT_DATA = "END_FETCH_EVALUATION_REPORT_DATA";

export const START_FETCH_EVALUATION_APPROVAL_STATUS = "START_FETCH_EVALUATION_APPROVAL_STATUS";
export const END_FETCH_EVALUATION_APPROVAL_STATUS = "END_FETCH_EVALUATION_APPROVAL_STATUS";

export const START_DOWNLOAD_XLS = "START_DOWNLOAD_XLS";
export const END_DOWNLOAD_XLS = "END_DOWNLOAD_XLS";

export const START_DOWNLOAD_PDF = "START_DOWNLOAD_PDF";
export const END_DOWNLOAD_PDF = "END_DOWNLOAD_PDF";


function startDownloadxlsReport(){
    return {
        type:START_DOWNLOAD_XLS,
        payload:{}
    };
}

function endDownloadxlsReport(success,error){
    return {
        type:END_DOWNLOAD_XLS,
        payload:{
            success,error
        }
    };
}

export function downloadxlsReport(evaluationID){
    const api_server = Environment.api_host("REPORT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/evaluation/${evaluationID}/generate-excel-report?t=${timestamp}`;
     return async (dispatch) => {
        dispatch(startDownloadxlsReport());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,{});
            dispatch(endDownloadxlsReport(response,error));
        } catch(e){
            dispatch(endDownloadxlsReport(null,{
                message:e.message
            }));
            return;
        }
    };
}


function startFecthEvaluationReportData(){
    return {
        type:START_FETCH_EVALUATION_REPORT_DATA,
        payload:{}
    };
}

function endFetchEvaluationReportData(success,error){
    return {
        type:END_FETCH_EVALUATION_REPORT_DATA,
        payload:{
            success,error
        }
    };
}

export function fetchEvaluationReportData(evaluationID,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/get-report-data?t=${timestamp}`;
     return async (dispatch) => {
        dispatch(startFecthEvaluationReportData());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endFetchEvaluationReportData(response,error));
        } catch(e){
            dispatch(endFetchEvaluationReportData(null,{
                message:e.message
            }));
            return;
        }
    };
}



function startFecthEvaluationApprovalStatus(){
    return {
        type:START_FETCH_EVALUATION_APPROVAL_STATUS,
        payload:{}
    };
}

function endFecthEvaluationApprovalStatus(success,error){
    return {
        type:END_FETCH_EVALUATION_APPROVAL_STATUS,
        payload:{
            success,error
        }
    };
}

export function fetchEvaluationReportApprovalStatus(evaluationID){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/approval_status?t=${timestamp}`;
     return async (dispatch) => {
        dispatch(startFecthEvaluationApprovalStatus());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endFecthEvaluationApprovalStatus(response,error));
        } catch(e){
            dispatch(endFecthEvaluationApprovalStatus(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startDownloadPDFReport(){
    return {
        type:START_DOWNLOAD_PDF,
        payload:{}
    };
}

function endDownloadPDFReport(success,error){
    return {
        type:END_DOWNLOAD_PDF,
        payload:{
            success,error
        }
    };
}

export function downloadPDFReport(data){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/reports/url-to-pdf?t=${timestamp}`;
     return async (dispatch) => {
        dispatch(startDownloadPDFReport());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endDownloadPDFReport(response,error));
        } catch(e){
            dispatch(endDownloadPDFReport(null,{
                message:e.message
            }));
            return;
        }
    };
}