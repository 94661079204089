import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment"

export const START_APP_CATALOG_PRODUCT_SEARCH     =   "START_APP_CATALOG_PRODUCT_SEARCH";
export const END_APP_CATALOG_PRODUCT_SEARCH       =   "END_APP_CATALOG_PRODUCT_SEARCH";

export const START_FETCH_APP_CATALOG_FIELDS     =   "START_FETCH_APP_CATALOG_FIELDS";
export const END_FETCH_APP_CATALOG_FIELDS       =   "END_FETCH_APP_CATALOG_FIELDS";

export const START_SET_APP_CATALOG_FIELD_HEADERS     =   "START_SET_APP_CATALOG_FIELD_HEADERS";
export const END_SET_APP_CATALOG_FIELD_HEADERS       =   "END_SET_APP_CATALOG_FIELD_HEADERS";

export const SHOW_APP_CATALOG_FIELDS_DIALOG     =   "SHOW_APP_CATALOG_FIELDS_DIALOG";
export const HIDE_APP_CATALOG_FIELDS_DIALOG       =   "HIDE_APP_CATALOG_FIELDS_DIALOG";


// cluster products dialog
export  function showAppCatalogfieldsDialog(){
    return     {
        type: SHOW_APP_CATALOG_FIELDS_DIALOG,
        payload:{ }
    };
}
  
export  function hideAppCatalogfieldsDialog(){
    return     {
        type: HIDE_APP_CATALOG_FIELDS_DIALOG,
        payload:{ }
    };
}
  

function startAppCatlogProductSearch(searchQuery){
  return {
      type: START_APP_CATALOG_PRODUCT_SEARCH,
      payload: {
          searchQuery
      }
  }
}

function endAppCatlogProductSearch(searchQuery, success, error,isTabular){
  return {
      type: END_APP_CATALOG_PRODUCT_SEARCH,
      payload:{
          searchQuery,success,error,isTabular
      }
  }
}

export function fetchAppCatlogProducts(searchQuery,isTabular) {
  const api_server = Environment.api_host("SEARCH");
  const timestamp  = (new Date()).getTime();
  const url        = `${api_server}/v2/dsl/app_catalogue?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startAppCatlogProductSearch(searchQuery));
      try{
          let [response,error] =  await HttpFactory.instance().postMethod(url, searchQuery, true);
          dispatch(endAppCatlogProductSearch(searchQuery, response,error,isTabular));
      } catch(e){
          dispatch(endAppCatlogProductSearch(searchQuery, null,{
              message:e.message
          },isTabular));
          return;
      }
  };
} 



function startFetchAppCatalogFields(searchQuery){
    return {
        type: START_FETCH_APP_CATALOG_FIELDS,
        payload: {
            searchQuery
        }
    }
  }
  
  function endFetchAppCatalogFields(searchQuery, success, error){
    return {
        type: END_FETCH_APP_CATALOG_FIELDS,
        payload:{
            searchQuery,success,error
        }
    }
  }
  
  export function fetchAppCatalogFields(searchQuery) {
    const api_server = Environment.api_host("SEARCH");
    const timestamp  = (new Date()).getTime();
    const url        = `${api_server}/v2/dsl/app_catalogue_tabviewoptions?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAppCatalogFields(searchQuery));
        try{
            let [response,error] =  await HttpFactory.instance().postMethod(url, searchQuery, true);
            dispatch(endFetchAppCatalogFields(searchQuery, response,error));
        } catch(e){
            dispatch(endFetchAppCatalogFields(searchQuery, null,{
                message:e.message
            }));
            return;
        }
    };
  }    
  
  

  function startSetAppCatalogFieldsHeaders(searchQuery){
    return {
        type: START_SET_APP_CATALOG_FIELD_HEADERS,
        payload: {
            searchQuery
        }
    }
  }
  
  function endSetAppCatalogFieldsHeaders(searchQuery, success, error){
    return {
        type: END_SET_APP_CATALOG_FIELD_HEADERS,
        payload:{
            searchQuery,success,error
        }
    }
  }
  
  export function setAppCatalogFieldsHeaders(searchQuery) {
    const api_server = Environment.api_host("SEARCH");
    const timestamp  = (new Date()).getTime();
    const url        = `${api_server}/v2/dsl/app_catalogue_tabviewoptions?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startSetAppCatalogFieldsHeaders(searchQuery));
        try{
            let [response,error] =  await HttpFactory.instance().postMethod(url, searchQuery, true);
            dispatch(endSetAppCatalogFieldsHeaders(searchQuery, response,error));
        } catch(e){
            dispatch(endSetAppCatalogFieldsHeaders(searchQuery, null,{
                message:e.message
            }));
            return;
        }
    };
  }    


