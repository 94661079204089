import React, { Component } from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

class Breadcrumb extends Component {
  state = {};

  render() {
    const { routeList } = this.props;
    return (
      <div style={{ padding: "15px 20px" }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ margin: "0px 0px" }} />}>
          <Link component={RouterLink} to="/app">
            <Typography color="textPrimary" style={{ fontSize: "14px", color: "#91919C" }}>
              Home
            </Typography>
          </Link>
          {routeList
            ? routeList.map((route, index) => {
                return index !== routeList.length - 1 ? (
                  <Link component={RouterLink} key={index} to={route.path}>
                    <Typography color="textPrimary" style={{ fontSize: "14px", color: "#91919C" }}>
                      {route.name}
                    </Typography>
                  </Link>
                ) : (
                  <Typography color="textPrimary" style={{ fontSize: "14px" }}>
                    {route.name}
                  </Typography>
                );
              })
            : null}
        </Breadcrumbs>
      </div>
    );
  }
}

export default Breadcrumb;
