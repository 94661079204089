import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { DialogContent,DialogActions,Grid,TextField, FormControlLabel, Radio, RadioGroup, Dialog, Slide } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import SqureButton from 'Components/Common/SqureButton';
import ReactSelect from "react-select";
import { addOrgSettings } from "redux/org/user/action";
import * as Validator from "util/Validation";
import { showSnackBar } from "redux/snackbar/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    showWorkflowViewDialog: state.workflow.showWorkflowViewDialog,
    showWorkflowViewData: state.workflow.showWorkflowViewData,

    user: state.orgUser,
    fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
});

const connectionActions = {
    addOrgSettings: addOrgSettings,
    showSnackBar: showSnackBar,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  notificationRoot: {
    padding: 10,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnSection: {
    textAlign: "right",
    margin: "0 !important",
    padding: "0 20px",
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  formControl1: {
    padding: 10,
    display: "flex",
    border: "1px solid #b3b3b3",
    borderRadius: 4,
    width: '100%'
  },
  errorMessage: {
    color: 'red',
    fontSize: '12px'
  },
  criteriaName: {
    width: '60%'
  },
  actions: {
    width: '20%',
    textAlign: 'center'
  },
  defaultView: {
    width: '20%',
  },
  customTypeFields: {
    // marginBottom: 15,
    // marginTop: 15,
    '& [class*="MuiFormControl-root"]': {
      width: '100%'
    },
    '& [class*="MuiInputBase-input"]': {
      textAlign: 'left'
    }
  },
  tickMark: {
    color: "#4b86f8",
    marginLeft: 10,
    border: "1px solid #4b86f8",
    // marginRight: 10,
    padding: 6,
    minWidth: "50px !important",
    "&:hover": {
      color: "#4b86f8",
    },
  },
  mainSection: {
    padding: 20,
  },
  deleteBtn: {
    cursor: 'pointer',
    marginLeft: 10
  },
  actionBtns: {
    display: 'flex',
    gap: 25,
    alignItems: 'center',
    justifyContent: 'center'
  },
  actionBtns1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20
  },
  errorMsg: {
    color: 'red'
  }
});

class AddUpdateViewDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          viewsList: [],
          viewsOptions: [],
          selectedView: null,
          type: "ADD",
          name: "",
          isLoading: true,
        };
    }

    
  componentDidMount() {
    this.initConfiguration();
  }

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  }

  initConfiguration = () => {
    const { user, tab } = this.props;
    let configKey = "ALL_PROJECTS_VIEWS";
    if(tab === 3){
      configKey = "ALL_PROJECTS_TRACKING_VIEWS"
    }
    const viewsList = this.getConfigValue(user?.fetchAllOrgSettingsSuccess, "SettingName", configKey) || [];
    this.setState({
      viewsList: Validator.isJson(viewsList) ? JSON.parse(viewsList) : [],
      viewsOptions: Validator.isJson(viewsList) ? JSON.parse(viewsList)?.map(view=>({...view, label: view.name, value: view.id})) : [],
      isLoading: false,
    });
  }

  submit = () => {
    let viewsList = this.state.viewsList;
    if(this.state.type === "ADD"){
      if(this.state.name?.trim().length === 0){
        this.props.showSnackBar("Please enter name", "error", 3000);
        return ;
      }
     
      viewsList.push({
        id: new Date().getTime(),
        name: this.state.name,
        filters: this.props.filters || {},
        isEdit: false,
        isDefault: false,
        isNew: false,
        viewsListError: null
      })
    }else{
      if(!this.state.selectedView){
        this.props.showSnackBar("Please select view", "error", 3000);
        return ;
      }
      let index = viewsList.findIndex(view => view.id === this.state.selectedView?.value);
      if(index>-1){
          viewsList[index]['filters'] = this.props.filters || {}
      }
    }
    let configKey = "ALL_PROJECTS_VIEWS";
    if(this.props.tab === 3){
      configKey = "ALL_PROJECTS_TRACKING_VIEWS"
    }
    var notificationConfig = [
      {
        name: configKey,
        value: JSON.stringify(viewsList),
      }
    ];
    this.props.addOrgSettings(notificationConfig);
    this.props.hideDialog();
  }

  render() {
    const classes = this.props.classes;
    const { name, viewsOptions,type,selectedView } = this.state;
    
    return  <Dialog 
              onClose={this.props.hideDialog} 
              open={true}
              TransitionComponent={Transition}
              disableBackdropClick={true}
              fullWidth={true}
              maxWidth={"sm"}
              scroll={"body"}
              id="addUpdateViewDialog"
              PaperProps={{ style: { overflowY: "visible" } }}
            >
              <DialogContent classes={{root:classes.dialogContent}} style={{ overflowY: "visible" }}>
                <div className={classes.notificationRoot}>
                  <RadioGroup
                    aria-label="binary"
                    name="binary"
                    style={{marginBottom: 25}}
                    value={type}
                    onChange={(event) => {
                      this.setState({type: event.target.value});
                    }}
                  >
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <FormControlLabel value={"ADD"} control={<Radio />} label={"Create New"} />
                      </Grid>
                      <Grid item>
                        <FormControlLabel value={"UPDATE"} control={<Radio />} label={"Update Existing"} />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  {type === "UPDATE" && <div className={classes.formGroupElements}>
                    <span className={classes.supportLabel}>Select View:</span>
                    <ReactSelect
                      isClearable={false}
                      options={viewsOptions}
                      value={selectedView}
                      classNamePrefix="select"
                      placeholder={"Select View"}
                      onChange={(e) => {
                        this.setState({selectedView: e})
                      }}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>}
                  {type === "ADD" && (
                    <div className={classes.formGroupElements}>
                      <span className={classes.supportLabel}>Name:</span>
                      <TextField
                        value={name}
                        size="small"
                        variant="outlined"
                        style={{marginTop: 15, width: '100%'}}
                        onChange={(e) => this.setState({name: e.target.value})}
                      />
                    </div>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <div style={{ padding: '10px 24px' }}>
                  <SqureButton
                    variant={"outlined"}
                    onClick={() => {
                      this.props.hideDialog()
                    }}
                  >
                    Cancel
                  </SqureButton>
                  &nbsp; &nbsp; &nbsp;
                  <SqureButton
                    variant={"contained"}
                    onClick={() => this.submit()}
                  >
                    Save
                  </SqureButton>
                </div>
              </DialogActions>
            </Dialog>
  }
}


export default connector(compose(
  withRouter,
  withStyles(styles)
)(AddUpdateViewDialog));