import { Grid } from "@material-ui/core";
import { createStyles, withStyles } from '@material-ui/styles';
import CommonCss from "commonCss";
import { connect } from "react-redux";
import React, { Component } from 'react';
import CustomItem from './CustomItem';
import * as Validator from "util/Validation";
import { showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import CollaboratorPopper from './Common/CollaboratorPopper';

const connectedProps = (state) => ({
  userType: state.authUser.user?.Role,
  additionalData: state.dialogs.searchExternalUserPoperAdditionData,
  orgProductDetailTabsSectionItemDataProgress: state.orgProduct.orgProductDetailTabsSectionItemDataProgress,
});


const connectionActions = {
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog
};

var connector = connect(connectedProps, connectionActions);


const styles = (theme) => createStyles({
  ...CommonCss.ProductDetails,
  root: {
    border: '1px solid #d8d8d8',
    padding: 17,
    borderRadius: 5,
    background: '#FFF',
    marginBottom: 17,
    position: 'relative',
  },
  positionRelative: {
    position: 'relative',
  },
  threeDot: {
    position: "absolute",
    zIndex: 11,
    right: 5,
    top: 5,
    padding: 5
  },
  listIcon: {
    minWidth: theme.spacing(5),
  },
  menuList: {
    padding: '0px',
    "& > hr": {
      "&:first-child": {
        display: 'none'
      },
    }
  },
  popover: {
    "& [class*='paper']": {
      boxShadow: "0px 8px 12px #0000001D",
      border: "1px solid #EEEEEE",
      borderRadius: "5px",
      overflow: "inherit",
      "&::before": {
        top: "50px",
        left: "-16px",
        width: "0",
        borderTop: "8px solid transparent",
        height: "0px",
        content: "''",
        display: "block",
        position: "absolute",
        borderBottom: "8px solid transparent",
        borderLeft: "8px solid transparent",
        borderRight: "8px solid #fff"
      },
    },
  },
  head: {
    color: '#20253A',
    fontSize: 30,
    opacity: 1,
    fontWeight: 400,
    margin: 0,
    marginBottom: 11
  }
})

class CustomSection extends Component {
  constructor(props) {
    super()
    this.state = {
      activeSection: null,
    };
  }


  render() {
    const { classes, section, productID, isEditable } = this.props;
    const { activeSection } = this.state;
    let items = []
    if (section && section.OrgProductTabItems && section.OrgProductTabItems.length) {
      items = section.OrgProductTabItems
    }
    // if(this.props.orgProductDetailTabsSectionItemDataProgress){
    //   return null;
    // }

    return (
      <>
        <div className={classes.root} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
          {this.props.userType === "OrgAdmin" && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <p className={classes.head} style={{ marginBottom: 15 }}>{section.SectionName}</p>
            </Grid>
          </Grid>
          {items && items.length > 0 && items.map((item, index) => (
            <CustomItem
              index={index}
              activeSection={activeSection}
              type={item.Type}
              title={item.ItemName}
              additionalData={Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : []}
              itemUID={item.ItemUID}
              productID={productID}
              isAdmin={isEditable}
              sectionUID={section.SectionUID}
              sectionName={section.SectionName}
              item={item}
            />
          ))}
        </div>
      </>
    )
  }
}

export default React.memo(connector(withStyles(styles)(CustomSection)))
