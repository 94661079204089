import {START_USER_SEARCH,END_USER_SEARCH, START_USER_TEAM_SEARCH,END_USER_TEAM_SEARCH} from "./action";

const initState   =     {
    searchProgress:false,
    users:[],

    searchUserTeamError:null,
    searchUserTeamProgress:false,
    usersAndTeams:[],
}

export  function userSearchReducer(state=initState,action){
    switch(action.type){
        case START_USER_SEARCH:
            return {
                ...state,
                searchProgress:true,
                users:[]
            };
        case END_USER_SEARCH:
            return {
                ...state,
                searchProgress:false,
                users:action.payload.success,
            }
        case START_USER_TEAM_SEARCH:
            return {
                ...state,
                searchUserTeamProgress:true,
                usersAndTeams:[],
                searchUserTeamError: null
            };
        case END_USER_TEAM_SEARCH:
            return {
                ...state,
                searchUserTeamProgress:false,
                usersAndTeams:action.payload.success,
                searchUserTeamError:action.payload.error,

            }
        default:
            return state;
    }
}