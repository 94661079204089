import React, { Component } from 'react'
import { connect } from "react-redux";
import { compose } from "recompose";
import { generate } from 'shortid';
import uniqid from 'uniqid';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withStyles, createStyles } from "@material-ui/core/styles"
import {
  Grid, Tabs, Tab, Popover, List, ListItem, ListItemIcon,
  Typography, Divider, IconButton, CircularProgress
} from "@material-ui/core";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import deleteIcon from "assets/images/delete.svg";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AddIcon from '@material-ui/icons/Add';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CommonCss from 'commonCss';
import MessageDialog from "Components/Common/MessageDialog";
import Image from "Components/Common/image.jsx";
import SqureButton from 'Components/Common/SqureButton';
import AppBarV2 from "Components/Application/AppBarV2";
import SearchTeamsPoper from "./dialogs/SearchTeamsPoper";
import classnames from "classnames";
import {
  hideMessageDialog, showMessageDialog
} from "redux/applications/action";

import {
  fetchOrgProductDetailsTabs, addOrgProductDetailsTab,
  updateOrgProductDetailsTab, deleteOrgProductDetailsTab,
  addOrgProductDetailsTabSection, deleteOrgProductDetailsTabSection,
  updateOrgProductDetailsTabSection, addOrgProductDetailsTabSectionItem,
  updateOrgProductDetailsTabSectionItem, updateOrgProductDetailsTabSequence,
  updateOrgProductDetailsTabSectionSequence, deleteOrgProductDetailsTabSectionItem,
  showAddProductTeamsDialog, hideAddProductTeamsDialog
} from 'redux/product/orgProduct/action'
import { updateDrawerStyle } from "redux/drawer/action";
import AddTabDialog from './AddTabDialog.jsx'
import FormBuilder from './FormBuilder.jsx'
import { showSnackBar } from "redux/snackbar/action";
import { fetchVisualization } from "redux/visualization/action";
import { getOrgWorkflow } from "redux/evaluation/workflow/action";

const connectedProps = (state) => ({
  orgProductDetailTabs: state.orgProduct.orgProductDetailTabs,
  isLoading: state.orgProduct.orgProductDetailTabsProgress,
  addOrgProductDetailTabsProgress: state.orgProduct.addOrgProductDetailTabsProgress,
  updateOrgProductDetailTabsProgress: state.orgProduct.updateOrgProductDetailTabsProgress,
  deleteOrgProductDetailTabsProgress: state.orgProduct.deleteOrgProductDetailTabsProgress,
  addOrgProductDetailTabsSectionProgress: state.orgProduct.addOrgProductDetailTabsSectionProgress,
  addOrgProductDetailTabsSectionError: state.orgProduct.addOrgProductDetailTabsSectionError,
  updateOrgProductDetailTabsSectionError: state.orgProduct.updateOrgProductDetailTabsSectionError,
  deleteOrgProductDetailTabsSectionProgress: state.orgProduct.deleteOrgProductDetailTabsSectionProgress,
  updateOrgProductDetailTabsSectionProgress: state.orgProduct.updateOrgProductDetailTabsSectionProgress,
  addOrgProductDetailTabsSectionItemProgress: state.orgProduct.addOrgProductDetailTabsSectionItemProgress,
  updateOrgProductDetailTabsSectionItemProgress: state.orgProduct.updateOrgProductDetailTabsSectionItemProgress,
  deleteOrgProductDetailTabsSectionItemProgress: state.orgProduct.deleteOrgProductDetailTabsSectionItemProgress,
  addProductTeamsDialogOpen: state.orgProduct.addProductTeamsDialogOpen,
  selectAddProductTeamId: state.orgProduct.selectAddProductTeamId,
  selectAddProductTeamType: state.orgProduct.selectAddProductTeamType,
  deleteProductSectionTeamProgress: state.orgProduct.deleteProductSectionTeamProgress,
  deleteProductSectionTeamError: state.orgProduct.deleteProductSectionTeamError,
  addProductSectionTeamsProgress: state.orgProduct.addProductSectionTeamsProgress,
  addProductSectionTeamsError: state.orgProduct.addProductSectionTeamsError,

  visualizations: state.visualization.visualizations,
  visualizationFetchProgress: state.visualization.visualizationFetchProgress,
  visualizationFetchError: state.visualization.visualizationFetchError,

  orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
    
});

const connectionActions = {
  fetchOrgProductDetailsTabs: fetchOrgProductDetailsTabs,
  addOrgProductDetailsTab: addOrgProductDetailsTab,
  updateOrgProductDetailsTab: updateOrgProductDetailsTab,
  deleteOrgProductDetailsTab: deleteOrgProductDetailsTab,
  addOrgProductDetailsTabSection: addOrgProductDetailsTabSection,
  deleteOrgProductDetailsTabSection: deleteOrgProductDetailsTabSection,
  updateOrgProductDetailsTabSection: updateOrgProductDetailsTabSection,
  addOrgProductDetailsTabSectionItem: addOrgProductDetailsTabSectionItem,
  updateOrgProductDetailsTabSectionItem: updateOrgProductDetailsTabSectionItem,
  updateOrgProductDetailsTabSequence: updateOrgProductDetailsTabSequence,
  updateOrgProductDetailsTabSectionSequence: updateOrgProductDetailsTabSectionSequence,
  deleteOrgProductDetailsTabSectionItem: deleteOrgProductDetailsTabSectionItem,
  updateDrawerStyle: updateDrawerStyle,
  showAddProductTeamsDialog: showAddProductTeamsDialog,
  hideAddProductTeamsDialog: hideAddProductTeamsDialog,
  showSnackBar: showSnackBar,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  fetchVisualization: fetchVisualization,
  getOrgWorkflow: getOrgWorkflow,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    backgroundColor: '#FFF',
    minHeight: '100vh',
  },
  formControl: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  mb16: {
    marginBottom: 16,
  },
  formGroup: {
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  ...CommonCss.ProductDetails,
  leftSection: {
    width: 200,
    minWidth: 200,
  },
  rightSection: {
    flex: 1,
    paddingLeft: 40
  },
  tabs: {
    "& [class*='MuiTab-root']": {
      padding: '0 6px',
      maxWidth: 'max-content',
      width: 'max-content'
    },
    "border-bottom": '1px solid #d8d8d8',
  },
  tabContainer: {
    paddingTop: theme.spacing(3)
  },
  container: {
    marginTop: 81
  },
  "popoverText": {
    color: "#74798C"
  },
  listItem: {
    paddingTop: theme.spacing(2),
  },
  listIcon: {
    minWidth: theme.spacing(5),
  },
  diabled: {
    opacity: 0.4
  },
  editIcon: {
    color: "#4A87F8",
    verticalAlign: "middle",
    cursor: 'pointer'
  },
  deleteImage: {
    cursor: "pointer",
  },
  rootContainer: CommonCss.rootContainer
})

class CustomFields extends Component {
  constructor(props) {
    super()
    this.state = {
      tab: 0,
      openAddTabDialog: false,
      activeSection: null,
      editTab: null,
      open: null,
      anchorEl: null,
      activeNewSection: null,
      typeOfVisibility: null,
      newTabSection: null,
      dragFromSeq: null,
      dragTabUid: null,
      isDrapSection: false,
      deletingTab: 0,
      tabUid: null,
      isUpdateHappen: false,
      isUpdateItemHappen: false,
      isAddItemHappen: false
    }
    this.onSectionDragEnd = this.onSectionDragEnd.bind(this)
    this.drop = this.drop.bind(this)
    this.drag = this.drag.bind(this)
  }
  componentDidMount() {
    this.props.fetchOrgProductDetailsTabs()
    this.props.getOrgWorkflow({ types: [1], page: 0, pageSize: 1000 });
    this.props.fetchVisualization({
      query: '',
      status: "",
      forView: true,
    });
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);
  }

  componentDidUpdate(prevProps,prevState) {
    const {
      addOrgProductDetailTabsProgress, updateOrgProductDetailTabsProgress,
      deleteOrgProductDetailTabsProgress, orgProductDetailTabs,
      addOrgProductDetailTabsSectionProgress,
      updateOrgProductDetailTabsSectionProgress, isLoading, addOrgProductDetailTabsSectionItemProgress,
      deleteOrgProductDetailTabsSectionItemProgress,
      deleteOrgProductDetailTabsSectionProgress,
    } = this.props;

    if(!this.state.openAddTabDialog && prevState.openAddTabDialog){
      this.setState({ anchorEl: null, open: null })
    }

    if (this.props.deleteProductSectionTeamProgress === false && prevProps.deleteProductSectionTeamProgress === true) {
      if (this.props.deleteProductSectionTeamError === null) {
        this.setState({
          isUpdateHappen: this.state.isUpdateHappen ? false : true
        })
      }
    }

    if (this.props.addProductSectionTeamsProgress === false && prevProps.addProductSectionTeamsProgress === true) {
      if (this.props.addProductSectionTeamsError === null) {
        this.setState({
          isUpdateHappen: this.state.isUpdateHappen ? false : true
        })
      }
    }

    if (!isLoading && prevProps.isLoading) {
      this.setState({
        dragFromSeq: null,
        dragTabUid: null,
        isDrapSection: false,
      })
    }
    if (!addOrgProductDetailTabsProgress && prevProps.addOrgProductDetailTabsProgress) {
      this.setState({
        tab: orgProductDetailTabs.length - 1,
        activeNewSection: null,
      });
      this.props.showSnackBar("Tab added successfully.", "success", 3000);
    }

    if (!updateOrgProductDetailTabsProgress && prevProps.updateOrgProductDetailTabsProgress) {
      this.setState({
        typeOfVisibility: null
      });
      this.props.showSnackBar("Tab updated successfully.", "success", 3000);
    }

    if (!deleteOrgProductDetailTabsProgress && prevProps.deleteOrgProductDetailTabsProgress) {
      this.setState({
        tab: this.state.deletingTab > this.state.tab
          ? this.state.tab
          : this.state.tab - 1,
        editTab: null,
        open: false,
        anchorEl: null
      });
      this.props.showSnackBar("Tab deleted successfully.", "success", 3000);
    }
    if (!addOrgProductDetailTabsSectionItemProgress && prevProps.addOrgProductDetailTabsSectionItemProgress) {
    }

    if (!addOrgProductDetailTabsSectionProgress && prevProps.addOrgProductDetailTabsSectionProgress) {
      this.props.showSnackBar("Section added successfully.", "success", 3000);
      const data = this.state.newTabSection;
      const items = data?.items || []
      items.forEach((item, k) => {
        this.props.addOrgProductDetailsTabSectionItem(
          data.tabUID, data.sectionUID, item.itemUid,
          {
            name: item.name,
            description: item.description,
            visibility: item.visibility,
            type: item.type,
            additional_data: JSON.stringify(item.additional_data),
            is_multiselect: item.is_multiselect,
            seq: k + 1
          }
        )
      })
      this.setState({
        newTabSection: null,
        activeSection: null,
        activeNewSection: null
      }, () => {
        this.props.fetchOrgProductDetailsTabs()
        setTimeout(() => {
          this.setState({
            isUpdateItemHappen: this.state.isUpdateItemHappen ? false : true
          })
        }, 3000);
      })
    }

    if (!deleteOrgProductDetailTabsSectionProgress && prevProps.deleteOrgProductDetailTabsSectionProgress === true) {
      this.props.showSnackBar("Section deleted successfully.", "success", 3000);
    }

    if (!deleteOrgProductDetailTabsSectionItemProgress && prevProps.deleteOrgProductDetailTabsSectionItemProgress === true) {
      this.setState({
        activeSection: null,
        isUpdateItemHappen: this.state.isUpdateItemHappen ? false : true
      });
    }

    if (!updateOrgProductDetailTabsSectionProgress && prevProps.updateOrgProductDetailTabsSectionProgress) {
      if (this.state.newTabSection === null) {
        this.props.showSnackBar("Section updated successfully.", "success", 3000)
      }
      if (!this.state.isDrapSection) {
        const data = this.state.newTabSection;
        const items = data?.items || []
        items.map((item, k) => {
          const newData = {
            name: item.name,
            description: item.description,
            visibility: item.visibility,
            type: item.type,
            additional_data: JSON.stringify(item.additional_data),
            is_multiselect: item.is_multiselect,
            seq: k + 1
          }
          if (item?.isNew) {
            this.props.addOrgProductDetailsTabSectionItem(
              data.tabUID, data.sectionUID, item.itemUid, newData)
          } else {
            this.props.updateOrgProductDetailsTabSectionItem(
              data.tabUID, data.sectionUID, item.itemUid, newData
            )
          }
          return item
        })
      }
      this.setState({
        newTabSection: null,
        activeSection: null,
        activeNewSection: null
      }, () => {
        setTimeout(() => {
          this.setState({
            isUpdateItemHappen: this.state.isUpdateItemHappen ? false : true
          })
        }, 3000);
        // this.props.fetchOrgProductDetailsTabs()
      })
    }
  }

  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  handleAddTab = (data) => {
    const { editTab } = this.state
    if (editTab === null) {
      const tabUid = uniqid("tab-", `-${Number(new Date())}`)
      if (data?.name) {
        const newTab = { name: data.name, visibility: data.visibility }
        this.props.addOrgProductDetailsTab(tabUid, newTab)
      }
    } else {
      const updatedData = {
        name: data?.name,
        visibility: data?.visibility
      }
      this.props.updateOrgProductDetailsTab(editTab.TabUID, updatedData)
    }
    this.setState({
      editTab: null,
    })
  }

  handleDeleteTab = (tabUid, deletingTab) => {
    this.setState({ deletingTab, tabUid }, () => {
      this.setState({ anchorEl: null, open: null })
      this.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.tabUid) {
      this.props.hideMessageDialog();
      this.props.deleteOrgProductDetailsTab(this.state.tabUid)
    }
  };
  

  handleTabVisibility = (type, tab, isVisible) => {
    let visibility = tab.Visibility
    if (type === 'team') {
      if (visibility === 0 && isVisible) {
        visibility = 4
      } else if (visibility === 1 && isVisible) {
        visibility = 5
      } else if (visibility === 2 && isVisible) {
        visibility = 6
      } else if (visibility === 3 && isVisible) {
        visibility = 7
      } else if (visibility === 4 && !isVisible) {
        visibility = 0
      } else if (visibility === 5 && !isVisible) {
        visibility = 1
      } else if (visibility === 6 && !isVisible) {
        visibility = 2
      } else if (visibility === 7 && !isVisible) {
        visibility = 3
      }
    } else if (type === 'admin') {
      if (visibility === 0 && isVisible) {
        visibility = 1
      } else if (visibility === 1 && !isVisible) {
        visibility = 0
      } else if (visibility === 2 && isVisible) {
        visibility = 3
      } else if (visibility === 3 && !isVisible) {
        visibility = 2
      } else if (visibility === 4 && isVisible) {
        visibility = 5
      } else if (visibility === 5 && !isVisible) {
        visibility = 4
      } else if (visibility === 6 && isVisible) {
        visibility = 7
      } else if (visibility === 7 && !isVisible) {
        visibility = 6
      }
    } else {
      if (visibility === 0 && isVisible) {
        visibility = 2
      } else if (visibility === 1 && isVisible) {
        visibility = 3
      } else if (visibility === 2 && !isVisible) {
        visibility = 0
      } else if (visibility === 3 && !isVisible) {
        visibility = 1
      } else if (visibility === 4 && isVisible) {
        visibility = 6
      } else if (visibility === 5 && isVisible) {
        visibility = 7
      } else if (visibility === 6 && !isVisible) {
        visibility = 4
      } else if (visibility === 7 && !isVisible) {
        visibility = 5
      }
    }
    const data = {
      visibility: visibility,
      name: tab.TabName
    }
    this.setState({
      typeOfVisibility: type,
      // currentVisibility: visibility
    }, () => {
      this.props.updateOrgProductDetailsTab(tab.TabUID, data)
    })
  }

  handleChangeTab = (event, tab) => {
    this.setState({ tab, activeSection: null, activeNewSection: null, dragSection: null, isDrapSection: false })
  };

  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(dragFromSeq, dragTabUid) {
    this.setState({ dragFromSeq, dragTabUid })
  }

  drop(ev) {
    const destinationId = parseInt(ev.target.id)
    const { dragFromSeq, dragTabUid } = this.state
    if (dragTabUid && ![null, NaN, undefined].includes(destinationId) && destinationId !== dragFromSeq) {
      this.props.updateOrgProductDetailsTabSequence(dragTabUid, { seq: destinationId })
    }
  }

  handleCreateNewSection = (data, tabUID) => {
    const { newTabSection } = this.state
    if (newTabSection === null) {
      this.setState({
        newTabSection: { ...data, tabUID, sectionUID: data.sectionUid }
      }, () => {
        this.props.addOrgProductDetailsTabSection(
          tabUID,
          data.sectionUid,
          {
            name: data.name,
            description: "",
            visibility: data.visibility
          }
        )
      })
    }
  }

  handleUpdateSection = (data, isCustom, tabUID, sectionUID) => {
    const { newTabSection } = this.state
    if (newTabSection === null) {
      this.setState({
        newTabSection: isCustom ? { ...data, tabUID, sectionUID } : null
      }, () => {
        this.props.updateOrgProductDetailsTabSection(
          tabUID,
          sectionUID,
          {
            name: data.name,
            description: "",
            visibility: data.visibility
          }
        )
      })
    }
  }

  handleUpdateSectionVisibility = (data, isCustom, tabUID, sectionUID) => {
    const { newTabSection } = this.state
    if (newTabSection === null) {
      this.props.updateOrgProductDetailsTabSection(
        tabUID,
        sectionUID,
        {
          name: data.name,
          description: "",
          visibility: data.visibility
        }
      )
    }
  }

  handleDeleteSection = (tabUID, sectionUid) => {
    this.setState({ activeSection: null }, () => {
      this.props.deleteOrgProductDetailsTabSection(tabUID, sectionUid)
    })
  }

  handleDeleteItem = (tabUID, sectionUid, itemUid) => {
    this.setState({
      activeSection: null,
    }, () => {
      this.props.deleteOrgProductDetailsTabSectionItem(tabUID, sectionUid, itemUid)
    })
  }

  handleSaveSectionData = (index, data) => {
    const { tabs, tab } = this.state
    const tabView = tabs[tab]
    tabView.sections[index] = { ...tabView.sections[index], ...data }
    tabs[tab] = tabView
    this.setState({ tabs, activeSection: null })
  }

  onSectionDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const { destination, draggableId, source } = result;
    if (draggableId && destination.index !== source.index) {
      const itemIndex = parseInt(draggableId)
      const destinationIndex = parseInt(destination.index)
      const currentTab = this.props.orgProductDetailTabs[this.state.tab]
      const dragedSection = currentTab.OrgProductTabSections[itemIndex]
      this.setState({
        isDrapSection: true
      }, () => {
        this.props.updateOrgProductDetailsTabSectionSequence(
          currentTab.TabUID,
          dragedSection.SectionUID,
          {
            seq: destinationIndex
          }
        )
      })
    }
  }

  render() {
   
    const {
      classes, orgProductDetailTabs, isLoading,
      addOrgProductDetailTabsProgress, updateOrgProductDetailTabsProgress,
      deleteOrgProductDetailTabsProgress, addOrgProductDetailTabsSectionProgress,
      deleteOrgProductDetailTabsSectionProgress, updateOrgProductDetailTabsSectionProgress,
      addOrgProductDetailTabsSectionItemProgress, updateOrgProductDetailTabsSectionItemProgress,
      deleteOrgProductDetailTabsSectionItemProgress,
    } = this.props;

    const {
      tab, dragFromSeq, dragTabUid,
      openAddTabDialog, activeSection,
      open, activeNewSection, typeOfVisibility, isDrapSection, isUpdateHappen, isUpdateItemHappen
    } = this.state;
    if (
      (isLoading && orgProductDetailTabs.length === 0) ||
      (isLoading && orgProductDetailTabs.length > 0 && dragFromSeq == null && dragTabUid == null && !isDrapSection)
    ) {
      return (<Grid container alignItems="center" justify="center" style={{ height: "50vh" }}><CircularProgress /></Grid>)
    }

    if (orgProductDetailTabs.length === 0) {
      return null
    }

    let tabs = (orgProductDetailTabs && orgProductDetailTabs.length > 0 && orgProductDetailTabs) || []
    let tabView = []
    if (tabs.length > 0 && tabs[tab]?.OrgProductTabSections && tabs[tab].OrgProductTabSections.length) {
      tabView = tabs[tab].OrgProductTabSections
    }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <div className={classes.root}>
        <AppBarV2
          title="Application Catalog"
          withBack={true}
          dropdown={true}
        />
        <Grid container className={classnames(classes.container, classes.rootContainer)}>
          <div className={classes.leftSection}>
            <Image
              src="https://assets.expent.ai/Stark.png"
              style={{ maxWidth: "90%" }}
            />
            <DragDropContext onDragEnd={this.onSectionDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <ul className={classes.list}>
                      {![null, undefined, 'NaN'].includes(tabView) &&
                        tabView.filter(o => !(o.SectionName && o.SectionName?.length === 0))
                          .map((obj, index) => {
                            if (obj.SectionName === null || obj.SectionName === '' || obj.SectionName.length <= 0) {
                              return null
                            }
                            return <Draggable key={String(index)} draggableId={String(index)} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <a href={`#${obj.SectionName.toLowerCase().split(" ").join("-")}`}>
                                    <li className={classes.item} key={`section-list-${index}`}>
                                      <Grid container alignItems="center">
                                        <DragIndicatorOutlinedIcon id={index} style={{ color: '#D9D9D9' }} />
                                        <p style={{ flex: 1, padding: 0, margin: 0, wordBreak: 'break-word' }}>{obj.SectionName}</p>
                                      </Grid>
                                    </li>
                                  </a>
                                </div>
                              )}
                            </Draggable>
                          })}
                    </ul>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className={classes.rightSection}>
            <Grid container alignItems="center" className={classes.mb16}>
              <Grid item xs="11">
                {(isLoading && dragFromSeq !== null && dragTabUid !== null) ? (
                  <Grid container alignItems="center"><CircularProgress size={20} /></Grid>
                ) : (
                  <>
                    {tabs && tabs.length > 0 && (
                      <Tabs
                        value={tab}
                        onChange={this.handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        className={classes.tabs}
                      >
                        {tabs.map((obj, index) => (
                          <Tab
                            draggable="true"
                            onDrop={this.drop}
                            onDragOver={this.allowDrop}
                            onDragStart={() => this.drag(index, obj.TabUID)}
                            key={index}
                            id={index}
                            label={
                              <Grid container alignItems="center" id={index} >
                                <DragIndicatorOutlinedIcon id={index} style={{ color: '#D9D9D9', marginRight: 10 }} />
                                <Grid item id={index}>
                                  {obj.TabName}
                                </Grid>
                                &nbsp;
                                <Grid item>
                                  <IconButton
                                    className={classes.threeDot}
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      this.setState({ open: index, anchorEl: event.currentTarget || event.current })
                                    }}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </Grid>
                                <Popover
                                  id={index}
                                  open={open === index}
                                  anchorEl={this.state.anchorEl}
                                  onClose={() => this.setState({ anchorEl: null, open: null })}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                  }}>
                                  <List>
                                    <ListItem
                                      classes={{
                                        button: classes.listItem,
                                      }}
                                      button
                                      onClick={e => {
                                        e.stopPropagation()
                                        this.handleTabVisibility('admin', obj, ![1, 3, 5, 7].includes(obj.Visibility))
                                      }}
                                    >
                                      {typeOfVisibility === "admin" && open && updateOrgProductDetailTabsProgress && this.state.anchorEl ? (
                                        <Grid container justify="center">
                                          <CircularProgress size={20} />
                                        </Grid>
                                      ) : (
                                        <>
                                          <ListItemIcon classes={{ root: classes.listIcon }}>
                                            {[1, 3, 5, 7].includes(obj.Visibility) ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                          </ListItemIcon>
                                          <Typography variant="inherit">
                                            Admin
                                          </Typography>
                                        </>
                                      )}
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                      classes={{
                                        button: classes.listItem,
                                      }}
                                      button
                                      onClick={e => {
                                        e.stopPropagation()
                                        this.handleTabVisibility('user', obj, ![2, 3, 6, 7].includes(obj.Visibility))
                                      }}
                                    >
                                      {typeOfVisibility === "user" && open && updateOrgProductDetailTabsProgress && this.state.anchorEl ? (
                                        <Grid container justify="center">
                                          <CircularProgress size={20} />
                                        </Grid>
                                      ) : (
                                        <>
                                          <ListItemIcon classes={{ root: classes.listIcon }}>
                                            {[2, 3, 6, 7].includes(obj.Visibility) ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                          </ListItemIcon>
                                          <Typography variant="inherit">
                                            End User
                                          </Typography>
                                        </>
                                      )}
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                      classes={{
                                        button: classes.listItem,
                                      }}
                                      button

                                    >
                                      <ListItemIcon classes={{ root: classes.listIcon }} onClick={e => {
                                        e.stopPropagation()
                                        this.handleTabVisibility('team', obj, ![4, 5, 6, 7].includes(obj.Visibility))
                                      }}>
                                        {/* <VisibilityIcon /> */}
                                        {[4, 5, 6, 7].includes(obj.Visibility) ? <VisibilityIcon /> : <VisibilityOffIcon />}

                                      </ListItemIcon>
                                      <Typography variant="inherit" onClick={e => {
                                        e.stopPropagation()
                                        // this.handleTabVisibility('user', obj, ![2, 3].includes(obj.Visibility))
                                        this.props.showAddProductTeamsDialog(obj.ID, 'Tab', obj);

                                      }}>
                                        Teams
                                      </Typography>
                                    </ListItem>
                                    {obj?.IsEditable && (
                                      <>
                                        <Divider />
                                        <ListItem
                                          classes={{
                                            button: classes.listItem,
                                          }}
                                          button
                                          onClick={e => {
                                            e.stopPropagation()
                                            this.setState({
                                              editTab: obj,
                                              openAddTabDialog: true,
                                            })
                                          }}
                                        >
                                          {typeOfVisibility == null && updateOrgProductDetailTabsProgress ? (
                                            <Grid container justify="center">
                                              <CircularProgress size={20} />
                                            </Grid>
                                          ) : (
                                            <>
                                              <ListItemIcon classes={{ root: classes.listIcon }}>
                                                <EditIcon
                                                  fontSize={"small"}
                                                  className={classes.editIcon}
                                                />
                                              </ListItemIcon>
                                              <Typography variant="inherit">
                                                Edit Tab Title
                                              </Typography>
                                            </>
                                          )}
                                        </ListItem>
                                      </>
                                    )}
                                    {obj?.IsDeletable && (
                                      <>
                                        <Divider />
                                        <ListItem
                                          classes={{
                                            button: classes.listItem,
                                          }}
                                          button
                                          onClick={e => {
                                            e.stopPropagation()
                                            this.handleDeleteTab(obj.TabUID, index)
                                          }}
                                        >
                                          {open && this.state.anchorEl !== null && deleteOrgProductDetailTabsProgress ? (
                                            <Grid container justify="center">
                                              <CircularProgress size={20} />
                                            </Grid>
                                          ) : (
                                            <>

                                              <ListItemIcon classes={{ root: classes.listIcon }}>
                                                <Image
                                                  src={deleteIcon}
                                                  className={classes.deleteImage}
                                                />
                                              </ListItemIcon>
                                              <Typography variant="inherit">
                                                Delete Tab
                                              </Typography>
                                            </>
                                          )}
                                        </ListItem>
                                      </>
                                    )}

                                  </List>
                                </Popover>
                              </Grid>
                            }
                            {...this.a11yProps(0)}
                          />
                        ))}
                        {addOrgProductDetailTabsProgress && <Grid container alignItems="center"><CircularProgress size={20} /></Grid>}
                      </Tabs>
                    )}
                  </>
                )}
              </Grid>
              <Grid item>
                <IconButton
                  variant={"contained"}
                  onClick={() => this.setState({ openAddTabDialog: true, editTab: null, label: "" })}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <div className={classes.tabContainer}>
              {(updateOrgProductDetailTabsSectionProgress || isLoading || deleteOrgProductDetailTabsSectionItemProgress) && isDrapSection ? (
                <Grid container alignItems="center" justify="center" style={{ height: "20vh" }}><CircularProgress /></Grid>
              ) : (
                <>
                  {tabView && (
                    <>
                      <DragDropContext onDragEnd={this.onSectionDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {tabView?.map((obj, index) => (
                                <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <FormBuilder
                                        config={obj}
                                        id={`${obj.SectionName.toLowerCase().split(" ").join("-")}`}
                                        key={obj.SectionUID}
                                        isNew={false}
                                        isLoader={deleteOrgProductDetailTabsSectionProgress ||
                                          updateOrgProductDetailTabsSectionProgress ||
                                          updateOrgProductDetailTabsSectionItemProgress ||
                                          deleteOrgProductDetailTabsSectionItemProgress
                                        }
                                        isCustom={obj.IsDeletable}
                                        onDelete={() => this.handleDeleteSection(tabs[tab].TabUID, obj.SectionUID)}
                                        deleteItem={itemUid => this.handleDeleteItem(tabs[tab].TabUID, obj.SectionUID, itemUid)}
                                        onCancel={() => this.setState({ activeSection: null })}
                                        isActive={activeSection !== null && activeSection === index}
                                        activeSection={() => this.setState({ activeSection: index })}
                                        onUpdate={data => this.handleUpdateSection(data, obj.IsDeletable, tabs[tab].TabUID, obj.SectionUID)}
                                        onUpdateSectionVisibility={data => this.handleUpdateSectionVisibility(data, obj.IsDeletable, tabs[tab].TabUID, obj.SectionUID)}
                                        addTeams={() => this.props.showAddProductTeamsDialog(obj.ID, 'Section', obj)}
                                        isUpdateHappen={isUpdateHappen}
                                        isUpdateItemHappen={isUpdateItemHappen}
                                        visualizations={this.props.visualizations}
                                        orgWorkFlowList={this.props.orgWorkFlowList}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))
                              }
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </>
                  )}
                  {activeNewSection === null ? (
                    <Grid container justify="center">
                      <SqureButton
                        variant={"contained"}
                        onClick={() => {
                          this.setState({ activeNewSection: tabs[tab] })
                          setTimeout(() => {
                            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
                          }, 100)
                        }}
                      >
                        + New Section
                      </SqureButton>
                    </Grid>
                  ) : (
                    <FormBuilder
                      isNew
                      isLoader={
                        addOrgProductDetailTabsSectionProgress ||
                        addOrgProductDetailTabsSectionItemProgress
                      }
                      isCustom={true}
                      isActive={true}
                      key={generate()}
                      config={{ Visibility: 3 }}
                      onSave={data => this.handleCreateNewSection(data, tabs[tab].TabUID)}
                      onCancel={() => this.setState({ activeNewSection: null })}
                      visualizations={this.props.visualizations}
                      orgWorkFlowList={this.props.orgWorkFlowList}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Grid>
        {openAddTabDialog &&
          <AddTabDialog
            isOpen={openAddTabDialog}
            currentVisibility={tabs[tab].Visibility}
            data={this.state.editTab}
            onClose={() => this.setState({ openAddTabDialog: false })}
            onSubmit={this.handleAddTab}
          />
        }

        <MessageDialog messageData={messageData} callBack={this.callBack} />
        

        {this.props.addProductTeamsDialogOpen && <SearchTeamsPoper />}

      </div>
    )
  }
}

export default connector(compose(withStyles(styles))(CustomFields));