import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, CircularProgress
} from '@material-ui/core'

import { withRouter } from 'react-router-dom';
import Image from 'Components/Common/image';
import ReviewsEmptyState from "assets/images/reviews_empty_state.svg";

import { Button } from "@material-ui/core";

import { addWorkflowComponentUserResponse, addCustomFormResponse, fetchWorkflowFormResponse, fetchWorkflowFormUserWiseResponse } from "redux/workflow/action";
import { searchUsers } from "redux/usersSettings/action";
import { fetchOrgGroup } from "redux/org/group/action";
import { fetchWorkFlowCustomForm } from "redux/evaluation/workflow/action";
import CustomReply from 'Components/Common/FormReply/CustomReply';

import BottomActions from "./BottomActions";

import { fetchOrgFormUsingId } from "redux/forms/org/action";
import { fetchMasterFormUsingId } from "redux/forms/master/action";
import { fetchSimilarExistingProducts } from "redux/product/orgProduct/action";
import Alert from '@material-ui/lab/Alert';

import * as Validator from "util/Validation";
import { fetchEvaluationMetaData } from "redux/evaluation/metadata/action";
import { updateEvaluationMetaData } from "redux/evaluation/metadata/action";
import AppBarV2 from 'Components/Application/AppBarV2';

import { showSearchTeamsPoperDialog, hideSearchTeamsPoperDialog, showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import SearchTeamsPoper from "Components/Common/TeamsAndUser/SearchTeamsPoper";
import SearchUserPoper from "Components/Common/TeamsAndUser/SearchUserPoper";

const connectedProps = (state) => ({
  user: state.authUser.user,
  evaluation: state.evaluationMetaData.evaluation,
  OrgID: state.authUser.user.OrgID,
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  componentUserResponseError: state.workflow.componentUserResponseError,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  fetchCustomFormProgress: state.evaluationWorkflow.fetchCustomFormProgress,
  workflowCustomForm: state.evaluationWorkflow.workflowCustomForm,
  addCustomComponentUserResponseProgress: state.workflow.addCustomComponentUserResponseProgress,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
  orgFormData: state.orgForms.orgFormData,
  fetchOrgFormsByIdProgress: state.orgForms.fetchOrgFormsByIdProgress,
  fetchMasterFormsByIdProgress: state.masterForms.fetchMasterFormsByIdProgress,
  masterFormData: state.masterForms.masterFormData,
  fetchWokflowFormResponseProgress: state.workflow.fetchWokflowFormResponseProgress,
  workflowFormResponse: state.workflow.workflowFormResponse,
  similarExistingProductProgress: state.orgProduct.similarExistingProductProgress,
  similarExistingProducts: state.orgProduct.similarExistingProducts,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  isOpenTeamDialog: state.dialogs.isShowSearchTeamPoperDialog,
  isOpenUserDialog: state.dialogs.isShowSearchUserPoperDialog,
});

const connectionActions = {
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  fetchWorkFlowCustomForm: fetchWorkFlowCustomForm,
  addCustomFormResponse: addCustomFormResponse,
  fetchUsers: searchUsers,
  fetchOrgGroup: fetchOrgGroup,
  fetchOrgFormUsingId: fetchOrgFormUsingId,
  fetchMasterFormUsingId: fetchMasterFormUsingId,
  fetchWorkflowFormResponse: fetchWorkflowFormResponse,
  fetchSimilarExistingProducts: fetchSimilarExistingProducts,
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  fetchWorkflowFormUserWiseResponse: fetchWorkflowFormUserWiseResponse,
  updateDetails: updateEvaluationMetaData,
  showSearchTeamsPoperDialog: showSearchTeamsPoperDialog,
  hideSearchTeamsPoperDialog: hideSearchTeamsPoperDialog,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  topRoot: {
    height: '89.5vh',
    overflow: 'scroll',
  },
  root: {
    // background: '#fff',
    padding: 20,
    width: '80%',
    margin: '0px auto',
    paddingBottom: 100
  },
  actions: {
    textAlign: "center",
    marginTop: theme.spacing(2)
  },
  actionBtn: {
    minWidth: '200px'
  },
  actionBtnDisabled: {
    minWidth: '200px',
  },
  dialogContent: {
    margin: 'auto',
    marginBottom: 20,
    "& [class*=MuiAvatar-root]": {
      width: 30,
      height: 30,
      fontSize: 14
    },
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  stepHead: {
    color: '#282D30',
    fontSize: 18,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  sectionName: {
    color: '#282D30',
    fontSize: 18,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  stepSubHead: {
    color: '#4B4B4B',
    fontSize: 12,
    margin: 0,
    paddingBottom: 20
  },
  customForm: {
    marginTop: 20,
    borderRadius: 8,
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    marginBottom: 15,
    padding: 20,
  },
  footer: {
    textAlign: 'right'
  },
  section: {
    margin: 0,
    // border: '1px solid #f1f1f1',
    // padding: 20
  },
  sectionItem: {
    padding: 20,
    borderRadius: 8,
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    marginBottom: 15,
  },
  sectionTitle: {
    color: '#282D30',
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  loader: {
    textAlign: "center",
    padding: theme.spacing(10, 0)
  },
  alert: {
    padding: '10px 20px 0px'
  },
  topHeadColor: {
    background: '#1f73b7',
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    height: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%'
  },
  sectionBox: {
    padding: 20,
    borderRadius: 8,
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    marginBottom: 15,
    borderTop: '4px solid #7fc781'
  },
});

class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customFormTitle: '',
      customFormDescription: '',
      customFormData: [],
      isShowLoader: true,
      formConfigDetails: {},
      formError: '',
      surveyId: this.props.match.params.survey_id,
      formId: '',
      isResponseAdded: false,
      finishTitle: 'Survey Completed...!',
      showSectionMap: {},
      showSectionResponseMatch: {},
      itemIdsForSection: {},
      currentSelected: null
    }
    this.avatarName = this.avatarName.bind(this);
    this.submitResponse = this.submitResponse.bind(this);
    this.setSelectedTeams = this.setSelectedTeams.bind(this);
    this.setSelectedUsers = this.setSelectedUsers.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this)
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  componentDidMount() {
    this.props.fetchWorkflowFormUserWiseResponse(this.state.surveyId, this.props.user.ID)
    this.props.fetchUsers(0, 30, "");
    this.props.fetchOrgGroup(0, 30, "");
    this.props.fetchEvaluationMetaData(this.state.surveyId);
  }

  componentDidUpdate(prevProps) {
    const { fetchProgress, evaluation } = this.props;

    if (!fetchProgress && prevProps.fetchProgress === true) {
      if (evaluation !== undefined && evaluation !== null) {
        this.setState({
          formId: evaluation?.WorkflowID
        }, () => {
          this.props.fetchOrgFormUsingId(evaluation?.WorkflowID);
        })
      }
    }

    if (this.props.fetchOrgFormsByIdProgress === false && prevProps.fetchOrgFormsByIdProgress === true) {
      this.checkConditions();
    }

    if (this.props.fetchWokflowFormResponseProgress === false && prevProps.fetchWokflowFormResponseProgress === true) {
      this.checkResponses();
    }

    if (this.props.addCustomComponentUserResponseProgress === false && prevProps.addCustomComponentUserResponseProgress === true) {
      if (this.props.componentUserResponseError === null) {
        this.setState({
          isResponseAdded: true,
          isShowLoader: false
        }, () => {
          this.props.updateDetails(this.state.surveyId, {
            status: 4
          })
        })
      } else {
        alert('Something Went Wrong');
      }
    }
  }

  checkResponses() {
    const { workflowFormResponse } = this.props;
    // console.log(workflowFormResponse, 'workflowFormResponse')
    let responseAdded = false;
    if (workflowFormResponse !== undefined && workflowFormResponse !== null && workflowFormResponse.length > 0) {
      responseAdded = true;
    }
    this.setState({
      isResponseAdded: responseAdded,
      isShowLoader: false
    })
  }

  checkConditions() {
    const { orgFormData } = this.props;

    let showSectionMap = {};
    let showSectionResponseMatch = {};
    let itemIdsForSection = {};
    // console.log(orgFormData, 'orgFormData')
    let formType = 'ORG';
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    if(orgFormData){
      orgFormData[sectionName].forEach(function (section, k) {
        var item = section[sectionItemName][0];
        if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
          var options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];
          showSectionResponseMatch[item.ID] = {};
          itemIdsForSection[section.ID] = item.ID;
          options.forEach(function (op) {
            if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
              showSectionMap[op.condition.value] = false;
              showSectionResponseMatch[item.ID][op.value] = op.condition;
            }
          })
        }
        if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
          var num_options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];
          showSectionResponseMatch[item.ID] = {};
          itemIdsForSection[section.ID] = item.ID;
          num_options.forEach(function (op) {
            if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
              showSectionMap[op.condition.value] = false;
              showSectionResponseMatch[item.ID][op.value] = op.condition;
            }
          })
        }
      })
    }
    
    this.setState({
      customFormTitle: orgFormData?.FormTitle,
      customFormDescription: orgFormData?.FormDescription,
      customFormData: orgFormData,
      showSectionMap: showSectionMap,
      showSectionResponseMatch: showSectionResponseMatch,
      itemIdsForSection: itemIdsForSection
    })
  }

  updateShowSection(item, response) {
    var showSectionMap = this.state.showSectionMap;
    var showSectionResponseMatch = this.state.showSectionResponseMatch;
    // console.log(showSectionResponseMatch,'showSectionResponseMatch')
    // console.log(showSectionMap,'showSectionMap')
    // console.log(response,'response')
    // console.log(item,'item')
    const { itemIdsForSection } = this.state;
    var me = this;
    if (showSectionResponseMatch[item.ID] !== undefined) {
      var datas = showSectionResponseMatch[item.ID];
      let selectedId = null;
      let updateSecId = '';
      Object.keys(datas).forEach(function (okey) {
        var data = datas[okey];
        if (item.Type === 4) {
          okey = parseInt(okey)
        }
        if (okey === response) {
          showSectionMap[data.value] = true;
          updateSecId = data.value;
          selectedId = data.value;
        } else {
          showSectionMap[data.value] = false;
          var clearResponseArr = [];
          var itemId = itemIdsForSection[data.value];
          clearResponseArr.push(data.value);
          if (showSectionResponseMatch[itemId] !== undefined) {
            var hideSectionOptions = showSectionResponseMatch[itemId];
            Object.keys(hideSectionOptions).forEach(function (jkey) {
              var opData = hideSectionOptions[jkey];
              if (selectedId === opData.value) {
                return;
              }
              showSectionMap[opData.value] = false;
              clearResponseArr.push(opData.value);
            });
          }
          me.clearResponseForSections(clearResponseArr);
        }
      })

      if (updateSecId !== '') {
        showSectionMap[updateSecId] = true;
      }
    }
    // console.log(showSectionMap,'showSectionMap')
    this.setState({
      showSectionMap: showSectionMap
    })

  }

  updateShowSectionForNumbers(item, response) {
    if (item.Type === 16) {
      if (response !== undefined && response !== null && response !== '') {
        let newValue;
        try {
          newValue = JSON.parse(response);
        } catch { }
        if (newValue !== undefined && newValue !== null && newValue !== '' && newValue.value !== undefined && newValue.value !== null && newValue.value !== '') {
          response = newValue.value;
        } else if (response.type !== undefined && response.type !== null && response.type !== '') {
          response = response.value;
        }
      }
    }
    var showSectionMap = this.state.showSectionMap;
    var showSectionResponseMatch = this.state.showSectionResponseMatch;
    const { itemIdsForSection } = this.state;
    var me = this;
    if (showSectionResponseMatch[item.ID] !== undefined) {
      var num_options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];
      var datas = showSectionResponseMatch[item.ID];
      var checkMap = {};
      num_options.forEach(function (itm) {
        checkMap[itm.value] = {
          ...itm,
          value: parseFloat(itm.label)
        }
      })
      let resValue = parseFloat(response)
      let selectedId = null;
      Object.keys(datas).forEach(function (okey) {
        var data = datas[okey];
        if (checkMap[okey] !== undefined && checkMap[okey].operation === 1 && checkMap[okey].value === resValue) {
          showSectionMap[data.value] = true;
          selectedId = data.value;
          // console.log('Operation 1 value:' + checkMap[okey].value + ' === response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 2 && resValue > checkMap[okey].value) {
          showSectionMap[data.value] = true;
          selectedId = data.value;
          // console.log('Operation 2 value:' + checkMap[okey].value + ' > response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 3 && resValue >= checkMap[okey].value) {
          showSectionMap[data.value] = true;
          selectedId = data.value;
          // console.log('Operation 3 value:' + checkMap[okey].value + ' >= response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 4 && resValue < checkMap[okey].value) {
          showSectionMap[data.value] = true;
          selectedId = data.value;
          // console.log('Operation 4 value:' + checkMap[okey].value + ' < response:' + resValue)
        } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 5 && resValue <= checkMap[okey].value) {
          showSectionMap[data.value] = true;
          selectedId = data.value;
          // console.log('Operation 5 value:' + checkMap[okey].value + ' <= response:' + resValue)
        } else {
          showSectionMap[data.value] = false;
          var clearResponseArr = [];
          var itemId = itemIdsForSection[data.value];
          clearResponseArr.push(data.value);
          if (showSectionResponseMatch[itemId] !== undefined) {
            var hideSectionOptions = showSectionResponseMatch[itemId];
            Object.keys(hideSectionOptions).forEach(function (jkey) {
              var opData = hideSectionOptions[jkey];
              if (selectedId === opData.value) {
                return;
              }
              showSectionMap[opData.value] = false;
              clearResponseArr.push(opData.value);
            });
          }
          me.clearResponseForSections(clearResponseArr);
        }
      })
      if (selectedId !== null) {
        showSectionMap[selectedId] = true;
      }
    }

    this.setState({
      showSectionMap: showSectionMap
    })
  }

  clearResponseForSections(sectionArr) {
    let newData = this.state.customFormData;
    let formTypeN = 'ORG';
    var sectionName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    newData[sectionName].forEach((section, sk) => {
      if (sectionArr.indexOf(section.ID) !== -1) {
        var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
        sectionItems.forEach(function (si) {
          si.response = '';
          si.value = '';
        })
      }
    });
    this.setState({
      customFormData: newData
    })
  }

  submitResponse() {
    this.setState({
      formError: ''
    })

    let { customFormData, formConfigDetails } = this.state;

    const formId = customFormData?.ID;

    var resData = [];
    var formType = formConfigDetails?.type;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    var isRequiredFieldEmpty = false;
    var isValidationError = false;
    var validationMsg = '';

    var requireFieldNames = [];
    let firstErrorId = null;
    this.state.customFormData[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        item['error'] = '';
        if (item.IsRequired && (item.value === undefined || item.value === "" || item.value === null)) {
          isRequiredFieldEmpty = true;
          requireFieldNames.push(item.ItemName);
          item.error = 'Please enter/select option.';
          if (firstErrorId === null) {
            firstErrorId = item.ID;
          }
        }

        if (item.IsRequired && item.Type === 12 && item.value !== '' && item.value !== null) {
          if (Validator.validateEmail(item.value) === false) {
            isValidationError = true;
            validationMsg = 'Please enter valid email.';
            item.error = 'Please enter valid email.';
            if (firstErrorId === null) {
              firstErrorId = item.ID;
            }
          }
        }

        if (item.IsRequired && item.Type === 14 && item.value !== '' && item.value !== null) {
          if (Validator.validateURL(item.value) === false) {
            isValidationError = true;
            validationMsg = 'Please enter valid URL.';
            item.error = 'Please enter valid URL.';
            if (firstErrorId === null) {
              firstErrorId = item.ID;
            }
          }
        }

        if (item.value === undefined || item.value === "" || item.value === null) {
          return;
        }
        var obj = {
          itemId: item.ID,
          value: JSON.stringify(item.value)
        }
        resData.push(obj);
      })
    });

    if (isRequiredFieldEmpty) {
      var nMsg = '';
      if (requireFieldNames.length === 1) {
        nMsg += ' is required field.';
      } else {
        nMsg += ' are required fields.';
      }
      var str = requireFieldNames.join(', ');
      str = str + nMsg;
      this.setState({
        formError: str
      })
      if (firstErrorId !== null) {
        let itemId = 'section_item' + firstErrorId;
        document.getElementById(itemId).scrollIntoView({
          // behavior: 'auto',
          block: 'center',
          inline: 'center',
          behavior: 'smooth'
        });
      }
    } else if (isValidationError) {
      this.setState({
        formError: validationMsg
      })
      if (firstErrorId !== null) {
        let itemId = 'section_item' + firstErrorId;
        document.getElementById(itemId).scrollIntoView({
          // behavior: 'auto',
          block: 'center',
          inline: 'center',
          behavior: 'smooth'
        });
      }
    } else {
      this.props.addCustomFormResponse(this.state.surveyId, formId, {
        form_type: 'ORG',
        data: resData,
        componentId: parseInt(formId)
      })
    }
  }

  setSelectedTeams(team, isUpdate) {
    const { customFormData, formConfigDetails, currentSelected } = this.state;
    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    let sectionId = currentSelected.SectionID;
    let itemId = currentSelected.ID;
    if (customFormData !== null && customFormData[sectionName] !== undefined) {
      let selections = customFormData[sectionName];
      var sectionIndex = selections.findIndex(s => s.ID === sectionId);
      var section = selections[sectionIndex];
      var sectionItems = section[sectionItemName];
      var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
      var item = sectionItems[itemIndex];
      if (item.value === undefined || item.value === null || item.value === '') {
        item['value'] = [team];
      } else {
        var datas = item.value;
        var index = datas.findIndex(t => t.ID === team.ID)
        if (index === -1) {
          datas.push(team);
        } else {
          datas.splice(index, 1);
        }
        item['value'] = datas;
      }
      this.setState({
        customFormData: customFormData,
        currentSelected: item
      })
    }
  }

  setSelectedUsers(user, isUpdate) {
    const { customFormData, formConfigDetails, currentSelected } = this.state;
    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    let sectionId = currentSelected.SectionID;
    let itemId = currentSelected.ID;

    if (customFormData !== null && customFormData[sectionName] !== undefined) {
      let selections = customFormData[sectionName];
      var sectionIndex = selections.findIndex(s => s.ID === sectionId);
      var section = selections[sectionIndex];
      var sectionItems = section[sectionItemName];
      var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
      var item = sectionItems[itemIndex];
      if (item.value === undefined || item.value === null || item.value === '') {
        item['value'] = [user];
      } else {
        var datas = item.value;
        var index = datas.findIndex(t => t.ID === user.ID)
        if (index === -1) {
          datas.push(user);
        } else {
          datas.splice(index, 1);
        }
        item['value'] = datas;
      }
      this.setState({
        customFormData: customFormData,
        currentSelected: item
      })
    }
  }

  cleanErrors() {
    let { customFormData, formConfigDetails } = this.state;
    var formType = formConfigDetails?.type;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    this.state.customFormData[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        item['error'] = ''
      })
    })
    this.setState({
      customFormData: customFormData
    })
  }


  render() {
    const classes = this.props.classes;
    let { customFormTitle, customFormDescription,
      customFormData, formConfigDetails,
      isShowLoader, isResponseAdded, finishTitle, showSectionMap } = this.state;

    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    if (isShowLoader || customFormData === undefined || customFormData === null || customFormData.length <= 0) {
      return <div className={classes.loader}><CircularProgress /></div>
    }

    // console.log(customFormData, 'customFormData');


    return <>
      <AppBarV2
        title={this.props.evaluation !== undefined && this.props.evaluation !== null ? this.props.evaluation?.Name : 'Survey'}
        withBack={true} />
      <div className={classes.topRoot}>
        <div className={classes.root}>
          {!isResponseAdded && <div classes={classes.dialogContent} >
            <div className={classes.customForm}>
              <Typography variant={"h4"} className={classes.stepHead}>{customFormTitle}</Typography>
              <span className={classes.stepSubHead}>{customFormDescription}</span>
              <div className={classes.topHeadColor}></div>
            </div>
            {false && <div className={classes.alert}>
              {this.state.formError.length > 0 && <Alert variant="filled" severity="error">{this.state.formError}</Alert>}
            </div>}

            {customFormData !== null && customFormData[sectionName].map((section, sk) => {
              var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
              sectionItems = sectionItems.sort(function (a, b) {
                if (a.Seq > b.Seq) {
                  return 1;
                } else if (a.Seq < b.Seq) {
                  return -1
                } else {
                  return 0;
                }
              })

              let isHideSection = false;
              sectionItems.forEach((item, key) => {
                if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                  isHideSection = true;
                }
              })

              if (sectionItems.length <= 0) {
                return null
              }

              return <>
                {!isHideSection && section?.SectionName !== '' && <div className={classes.sectionBox}>
                  <Typography variant={"h4"} className={classes.sectionName}>{section?.SectionName}</Typography>
                  <span className={classes.stepSubHead}>{section?.Description}</span>
                </div>}
                <div className={classes.section}>
                  {/* <Typography variant={"h4"} className={classes.sectionTitle}>{section?.SectionName}</Typography> */}
                  {sectionItems.map((item, key) => {
                    // console.log(item, 'Item-Section');

                    if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                      return '';
                    }

                    if (item.Type === 1) {
                      // console.log(item, 'Item01')
                      // console.log(item.value, 'Item01')
                    }
                    var options = Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [];

                    if (item.Type === 17 && (this.props.similarExistingProducts === undefined || this.props.similarExistingProducts === null || Object.keys(this.props.similarExistingProducts).length <= 0)) {
                      return ''
                    }



                    return <><div className={classes.sectionItem} id={'section_item' + item.ID}>
                      <CustomReply
                        additional_data={options}
                        type={item.Type}
                        title={item.ItemName}
                        description={item.Description}
                        isRequired={item?.IsRequired}
                        key={item.ID + '_sectionItem'}
                        index={item.ID}
                        value={(item?.value !== undefined && item?.value !== null) ? item.value : ''}
                        selectedOption={[]}
                        evaluationId={this.state.surveyId}
                        orgId={this.props.OrgID}
                        workflowId={this.props.workflow_id}
                        isFormEditable={true}
                        itemId={item.ID}
                        sectionId={item.SectionID}
                        item={item}
                        error={item.error || ''}
                        onChange={(response) => {
                          item['value'] = response;
                          if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
                            this.updateShowSection(item, response);
                          }
                          if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
                            this.updateShowSectionForNumbers(item, response);
                          }
                          this.cleanErrors();
                        }
                        }
                        showUserAddDialog={(item) => {
                          this.setState({
                            currentSelected: item
                          }, () => {

                          })
                          this.props.showSearchUsersPoperDialog()
                        }}
                        showTeamAddDialog={(data) => {
                          this.setState({
                            currentSelected: item
                          }, () => {
                            this.props.showSearchTeamsPoperDialog()
                          })
                        }}
                      // similarExistingProducts={this.props.similarExistingProducts}
                      // similarExistingProductProgress={this.props.similarExistingProductProgress}
                      />
                    </div>
                    </>
                  })}

                </div>
              </>
            })}
            <BottomActions
              submitResponse={this.submitResponse}
            />
          </div>}


          {this.props.isOpenTeamDialog && <SearchTeamsPoper
            selectedTeams={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
            onAdd={(team) => {
              this.setSelectedTeams({
                id: team.ID,
                ID: team.ID,
                value: team.ID,
                label: team.Name,
                Name: team.Name,
              }, true)
            }}
            onRemove={(team) => {
              this.setSelectedTeams({
                id: team.ID,
                ID: team.ID,
                value: team.ID,
                label: team.Name,
                Name: team.Name,
              }, true)
            }}
            onClose={() => {
              this.props.hideSearchTeamsPoperDialog();
            }}
          />}

          {this.props.isOpenUserDialog && <SearchUserPoper
            selectedUsers={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
            onAdd={(user) => {
              this.setSelectedUsers({
                id: user.ID,
                ID: user.ID,
                value: user.ID,
                label: user.Name,
                Name: user.Name,
              }, true)
            }}
            onRemove={(user) => {
              this.setSelectedUsers({
                id: user.ID,
                ID: user.ID,
                value: user.ID,
                label: user.Name,
                Name: user.Name,
              }, true)
            }}
            onClose={() => {
              this.props.hideSearchUsersPoperDialog();
            }}
          />}

          {isResponseAdded && <div style={{ textAlign: 'center', marginTop: 50 }}>
            <div className={classes.DashboardNoDataLayout}>
              <Image src={ReviewsEmptyState} />
              <h2>{finishTitle}</h2>
              <Button variant="outlined" color="secondary" className={classes.button}
                onClick={() => { this.props.history.push('/app/home') }}
              >Home</Button>
            </div>
          </div>}

        </div>
      </div>
    </>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CustomForm));