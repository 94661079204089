import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppBarV2 from "../../../AppBarV2";
import ReactFlow, { ReactFlowProvider, Background, Controls, getBezierPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer';
import dagre from 'dagre';
import ClearIcon from "@material-ui/icons/Clear";
import {
  IconButton, Paper, Tabs, Tab, CircularProgress, Typography, Grid, Tooltip, Checkbox, Accordion, AccordionDetails, AccordionSummary,
  ListItem, List, ListItemIcon, Popover
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Image from 'Components/Common/image.jsx';
// import CloseIcon from "assets/images/closeIcon.svg";
import editIcon from "assets/images/edit_icon.svg";
import Workflow from "assets/images/workflow/workflow.svg";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import classnames from "classnames";
import deleteIcon from "assets/images/delete.svg";
import SqureButton from 'Components/Common/SqureButton';
import { isEnabledReview } from "util/Common";
import * as Validator from "util/Validation";

import WorkFlowConfiguration from './WorkFlowConfiguration';
import { WorkFlowRule } from './WorkFlowRule';
import './style.css'
import CustomImage from "./WorkFlowV2Components/CustomImage";

// Actions
import { addOrgSettings } from "redux/org/user/action";
import { showSnackBar } from "redux/snackbar/action";
import { updateDrawerStyle } from "redux/drawer/action";
import { workflowComponents, formComponent, fetchFrameworks } from "redux/master/action";

import WorkflowDetailsUpdateDialog from "./Dialogs/WorkflowDetailsUpdateDialog";
import { nodeLabel } from "./WorkFlowV2Components/data";
import MessageDialog from "Components/Common/MessageDialog";
import { getMasterWorkflows } from "redux/master/workflow/action";
import {
  ArrowDropDown as ArrowDropDownIcon,
} from "@material-ui/icons";
import { showMessageDialog, hideMessageDialog, } from "redux/applications/action";
import { fetchOrgCatalogAllTabs } from "redux/vendor/vendorCatalog/action";

import {
  getOrgWorkflow,
  getByIdOrgWorkflow,
  updateOrgWorkflow,
  addOrgWorkflowComponent,
  getOrgWorkflowComponent,
  updateOrgWorkflowComponent,
  deleteOrgWorkflowComponent,
  addOrgWorkflowAction,
  updateOrgWorkflowAction,
  fetchOrgWorkflowAction,
  deleteOrgWorkflowAction,
  showMasterComponentDialog,
  hideMasterComponentDialog,
  fetchOrgWorkflowComponentDetails,
  showWrapperWorkflowDetailsDialog,
  showWorkflowDetailUpdateDialog,
  hideWorkflowDetailUpdateDialog,
  setComponentsFlowPosition
} from "redux/evaluation/workflow/action";

// import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { fetchMasterForms } from "redux/forms/master/action";
import { fetchOrgForms } from "redux/forms/org/action";
import { fetchOrgTemplates, fetchOrgTemplate } from "redux/templates/evaluation/org/action";
import { fetchMasterTemplates, fetchMasterTemplate } from "redux/templates/evaluation/master/action";

// import { template } from "underscore";
const connectedProps = (state) => ({
  fetchWorkflowComponentProgress: state.master.fetchWorkflowComponentProgress,
  workflowComponent: state.master.workflowComponent,
  workflowFormCompList: state.master.workflowFormCompList,
  orgWorkFlowByIdProgress: state.evaluationWorkflow.orgWorkFlowByIdProgress,
  orgWorkFlowByIdList: state.evaluationWorkflow.orgWorkFlowByIdList,
  orgWorkFlowByIdError: state.evaluationWorkflow.orgWorkFlowByIdError,
  updateOrgWorkFlowProgress: state.evaluationWorkflow.updateOrgWorkFlowProgress,
  updateOrgWorkFlowSuccess: state.evaluationWorkflow.updateOrgWorkFlowSuccess,
  updateOrgWorkFlowError: state.evaluationWorkflow.updateOrgWorkFlowError,
  orgWorkFlowCompProgress: state.evaluationWorkflow.orgWorkFlowCompProgress,
  orgWorkFlowCompList: state.evaluationWorkflow.orgWorkFlowCompList,
  orgWorkFlowCompError: state.evaluationWorkflow.orgWorkFlowCompError,
  updateOrgWorkFlowCompProgress: state.evaluationWorkflow.updateOrgWorkFlowCompProgress,
  updateOrgWorkFlowCompSuccess: state.evaluationWorkflow.updateOrgWorkFlowCompSuccess,
  updateOrgWorkFlowCompError: state.evaluationWorkflow.updateOrgWorkFlowCompError,
  addOrgWorkFlowCompProgress: state.evaluationWorkflow.addOrgWorkFlowCompProgress,
  addOrgWorkFlowCompSuccess: state.evaluationWorkflow.addOrgWorkFlowCompSuccess,
  addOrgWorkFlowCompError: state.evaluationWorkflow.addOrgWorkFlowCompError,
  deleteOrgWorkFlowCompProgress: state.evaluationWorkflow.deleteOrgWorkFlowCompProgress,
  deleteOrgWorkFlowCompSuccess: state.evaluationWorkflow.deleteOrgWorkFlowCompSuccess,
  deleteOrgWorkFlowCompError: state.evaluationWorkflow.deleteOrgWorkFlowCompError,
  orgWorkFlowActionProgress: state.evaluationWorkflow.orgWorkFlowActionProgress,
  orgWorkFlowActionList: state.evaluationWorkflow.orgWorkFlowActionList,
  orgWorkFlowActionError: state.evaluationWorkflow.orgWorkFlowActionError,
  addOrgWorkFlowActionProgress: state.evaluationWorkflow.addOrgWorkFlowActionProgress,
  addOrgWorkFlowActionSuccess: state.evaluationWorkflow.addOrgWorkFlowActionSuccess,
  addOrgWorkFlowActionError: state.evaluationWorkflow.addOrgWorkFlowActionError,
  updateOrgWorkFlowActionProgress: state.evaluationWorkflow.updateOrgWorkFlowActionProgress,
  updateOrgWorkFlowActionSuccess: state.evaluationWorkflow.updateOrgWorkFlowActionSuccess,
  updateOrgWorkFlowActionError: state.evaluationWorkflow.updateOrgWorkFlowActionError,
  deleteOrgWorkFlowActionProgress: state.evaluationWorkflow.deleteOrgWorkFlowActionProgress,
  deleteOrgWorkFlowActionSuccess: state.evaluationWorkflow.deleteOrgWorkFlowActionSuccess,
  deleteOrgWorkFlowActionError: state.evaluationWorkflow.deleteOrgWorkFlowActionError,
  fetchAllOrgSettingsSuccess: state.orgUser.fetchAllOrgSettingsSuccess,
  fetchAllOrgSettingsFailure: state.orgUser.fetchAllOrgSettingsFailure,
  fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
  isMasterComponentDialogOpen: state.evaluationWorkflow.isMasterComponentDialogOpen,
  orgWorkFlowCompDetailsProgress: state.evaluationWorkflow.orgWorkFlowCompDetailsProgress,
  orgWorkFlowCompDetails: state.evaluationWorkflow.orgWorkFlowCompDetails,
  isWrapperWorflowDetailsDialogOpen: state.evaluationWorkflow.isWrapperWorflowDetailsDialogOpen,
  addOrgSettingsInprogress: state.orgUser.addOrgSettingsInprogress,
  orgWorkFlowProgress: state.evaluationWorkflow.orgWorkFlowProgress,
  orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
  orgWorkFlowError: state.evaluationWorkflow.orgWorkFlowError,
  masterWorkFlowProgress: state.masterWorkflow.masterWorkFlowProgress,
  masterWorkFlowList: state.masterWorkflow.masterWorkFlowList,
  masterWorkFlowError: state.masterWorkflow.masterWorkFlowError,
  isWorkflowDetailsUpdateDialogOpen: state.evaluationWorkflow.isWorkflowDetailsUpdateDialogOpen,
  setComponentFlowPostionProg: state.evaluationWorkflow.setComponentFlowPostionProg,
  orgTemplate: state.orgTemplateEvaluation.template,
  fetchTemplateProg: state.orgTemplateEvaluation.fetchTemplateProg,
  masterTemplate: state.masterTemplateEvaluation.template,
  fetchMasterTemplateProg: state.masterTemplateEvaluation.fetchTemplateProg,
  user: state.authUser.user,
  orgUser: state.orgUser,
  orgCatalogAllTabs: state.vendorCatalog.orgCatalogAllTabs,
  fetchFrameworksProgress: state.master.fetchFrameworksProgress,
  frameworksList: state.master.frameworksList,
});

const connectionActions = {
  getOrgWorkflow: getOrgWorkflow,
  getMasterWorkflows: getMasterWorkflows,
  addOrgSettings: addOrgSettings,
  showSnackBar: showSnackBar,
  updateDrawerStyle: updateDrawerStyle,
  workflowComponents: workflowComponents,
  formComponent: formComponent,
  getByIdOrgWorkflow: getByIdOrgWorkflow,
  updateOrgWorkflow: updateOrgWorkflow,
  addOrgWorkflowComponent: addOrgWorkflowComponent,
  getOrgWorkflowComponent: getOrgWorkflowComponent,
  updateOrgWorkflowComponent: updateOrgWorkflowComponent,
  deleteOrgWorkflowComponent: deleteOrgWorkflowComponent,
  addOrgWorkflowAction: addOrgWorkflowAction,
  updateOrgWorkflowAction: updateOrgWorkflowAction,
  fetchOrgWorkflowAction: fetchOrgWorkflowAction,
  deleteOrgWorkflowAction: deleteOrgWorkflowAction,
  showMasterComponentDialog: showMasterComponentDialog,
  hideMasterComponentDialog: hideMasterComponentDialog,
  fetchOrgWorkflowComponentDetails: fetchOrgWorkflowComponentDetails,
  showWrapperWorkflowDetailsDialog: showWrapperWorkflowDetailsDialog,
  showWorkflowDetailUpdateDialog: showWorkflowDetailUpdateDialog,
  hideWorkflowDetailUpdateDialog: hideWorkflowDetailUpdateDialog,
  fetchOrgTemplates: fetchOrgTemplates,
  fetchOrgTemplate: fetchOrgTemplate,
  fetchMasterTemplates: fetchMasterTemplates,
  fetchMasterTemplate: fetchMasterTemplate,
  fetchMasterForms: fetchMasterForms,
  fetchOrgForms: fetchOrgForms,
  setComponentsFlowPosition: setComponentsFlowPosition,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  fetchOrgCatalogAllTabs: fetchOrgCatalogAllTabs,
  fetchFrameworks: fetchFrameworks
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  formBuilderMain: {
    display: 'flex',
    flex: 1
  },
  flowSection: {
    flex: 1.5,
    height: 'calc(100vh - 100px)',
    "& .draggable": {
      display: 'none',
    },
    "& .decision-rectangle": {
      width: 65,
      height: 65,
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: 13,
      left: 58,
      border: '1px solid #EEEEEE',
      background: '#fff',
      zIndex: -1
    },
    "& .decision-component": {
      position: 'relative',
      zIndex: 1,
      top: 24,
      margin: 'auto',
      width: 'min-content',
      textAlign: 'center'
    }
  },
  dragElement: {
    maxWidth: 610,
    "& .draggable": {
      border: '1px solid #D8D8D8',
      borderRadius: 5,
      marginBottom: 12,
      padding: 12,
      width: '100%',
      "& .draggableLabel": {
        fontSize: 13,
        color: '#333333',
        fontWeight: 600,
        margin: 0,
        marginBottom: 2,
      },
      "& .draggableDec": {
        fontSize: 14,
        color: '#9B9EAC',
        margin: 0
      }
    },
    "&.hide-drag-element": {
      padding: 0,
      opacity: 0,
      flex: 0,
    }
  },
  "@global": {
    '.applicationContent': {
      background: '#fff !important',
      padding: "0 !important"
    },
    ".react-flow__node": {
      padding: '0px !important',
      boxShadow: 'none'
    },
    ".react-flow__node-default, .react-flow__node-input, .react-flow__node-output": {
      padding: '5px 10px',
      fontSize: '7.5px',
      border: 'none'
    },
    '.react-flow__edge-path': {
      strokeWidth: 1.5
    },
  },
  secondarySection: {
    // boxShadow: '0px 1px 6px #0000000D',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '32px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    paddingRight: 15,
    borderBottom: '1px solid #f1f1f1'
  },
  componentTextAndImg: {
    display: 'flex',
    marginBottom: 26
  },
  componentText: {
    marginLeft: 10,
    fontSize: 14
  },
  backImage: {
    cursor: 'pointer'
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 100,
    border: 'none',
    cursor: 'pointer',
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  publishBtn: {
    background: "#fff",
    borderRadius: 5,
    color: "#3C3C3C",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 100,
    border: '1px solid #3c3c3c',
    cursor: 'pointer',
    marginRight: 5,
    "&:hover": {
      background: "#fff",
    },
  },
  tabContentContainer: {
    padding: 16
  },
  titleImage: {
    cursor: 'pointer',
    width: 13,
    marginLeft: 10,
    position: 'relative',
    top: 1
  },
  inputSection: {
    border: "1px solid #ddd",
    borderRadius: 6,
    minHeight: 30,
    paddingLeft: 5,
    width: '30%'
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  closeIcon: {
    cursor: 'pointer',
    maxWidth: 18,
  },
  closeIconSection: {
    // position: 'absolute',
    // left: 5,
    // zIndex: 10
  },
  elementDetails: {
    width: '60vw'
  },
  componentDetailsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px',
  },
  componentDetailsBody: {
    padding: '0px 24px',
  },
  componentDetailsBodyHeader: {
    padding: '20px 24px 0px 24px',
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  componentDetailsSection: {
    position: 'relative',
    overflowY: 'auto',
    height: 'calc(92vh - 100px)',
    marginBottom: 0
  },
  detailText: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    color: '#282D30'
  },
  deleteIcon: {
    cursor: 'pointer',
    width: 16
  },
  saveBtnSection: {
    position: 'absolute',
    bottom: 10,
    right: 12,
    left: 'auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: '#f1f1f1',
    padding: '10px'
  },
  workActionHead: {
    fontSize: 19,
    fontWeight: 600,
    marginRight: 10
  },
  workActionSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  alreadyPublish: {
    opacity: .5
  },
  defaultLabel: {
    display: "inline-block",
    fontWeight: "bold"
  },
  editImage: {
    width: 10,
    height: 10,
    color: "#4A87F8",
  },
  editIconImage: {
    cursor: 'pointer',
    width: 13,
    marginLeft: 10,
    position: 'relative',
    top: 1
  },
  micon: {
    fontSize: 24,
    marginTop: 2,
    marginLeft: 10,
    position: 'absolute',
    cursor: 'pointer',
    color: '#4b86f8',
    "&:hover": {
      background: "#f8f7f7",
      padding: '2px',
      borderRadius: '4px'
    },
  },
  tabContainer: {
    position: 'relative'
  },
  tabToggleIcon: {
    position: 'absolute',
    top: 0,
    right: -48,
    backgroundColor: '#fff',
    zIndex: 10,
    borderRadius: '0px !important',
    '&:hover': {
      backgroundColor: '#fff',
    }
  },
  tabs: {
    width: '100%',
    boxShadow: 'none !important',
    borderBottom: '1px solid #0000000D',
    '& [class*="MuiTab-root"]': {
      textTransform: 'capitalize !important',
      color: '#9B9EAC',
      fontSize: 14,
      fontWeight: 500
    },
    '& [class*="Mui-selected"]': {
      color: '#333333',
      fontWeight: 600
    }
  },
  workflowBtns: {
    textAlign: 'right'
  },
  actionBtn: {
    minWidth: '200px',
    marginLeft: 10
  },
  componentname: {
    color: '#282D30',
    fontSize: 16,
    fontWeight: 600,
    padding: 2,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(235, 236, 240)'
    }
  },
  showComponent: {
    flex: 0.6,
    transition: 'flex 0.15s linear',
  },
  hideComponent: {
    flex: 0,
    transition: 'flex 0.15s linear',
  },
  accodian: {
    '& [class*="MuiAccordionDetails-root"]': {
      display: 'block'
    }
  },
  defaultForBtn: {
    border: '1px solid #222',
    padding: '6px 20px'
  },
  addMore: {
    display: 'inline-block',
    border: '1px solid #FFF',
    cursor: 'pointer',
    padding: '10px 14px',
    fontSize: '12px',
    background: '#4A87F8',
    borderRadius: '20px',
    color: 'white'
  }
});

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  // console.log('inside get layout') 
  // console.log(nodes, 'nodes')
  // console.log(edges, 'edges')
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 500;
  const nodeHeight = 150;

  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  if (nodes !== undefined && nodes !== null) {
    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });
  }

  if (edges !== undefined && edges !== null) {
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
  }

  dagre.layout(dagreGraph);

  if (nodes !== undefined && nodes !== null) {
    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? 'left' : 'top';
      node.sourcePosition = isHorizontal ? 'right' : 'bottom';

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });
  }

  return { nodes, edges };
};

// const edgesColors = ["#0000ff", '#8a2be2', '#5f9ea0', '#F24621', '#008b8b', '#2f4f4f', '#696969', '#daa520', '#cd5c5c', '#add8e6']

class DragAndDropSection extends React.Component {
  constructor(props) {
    super(props);
    this.reactFlowWrapper = React.createRef(null);
    this.state = {
      workflowDetails: {},
      updateName: false,
      isEdit: false,
      isShow: false,
      elements: [],
      isShowMasterComponent: true,
      componentDetails: {},
      edgeDetails: {},
      isComponentEdit: false,
      updateComponentName: '',
      formComponentList: [],
      defaultWorkflow: false,
      defaultForReview: false,
      defaultForRenewal: false,
      selectedStatus: 1,
      popoverState: null,
      reactFlowInstance: null,
      selectedComponentName: '',
      selectedComponentSLUG: '',
      name: '',
      activeTab: 1,
      expanded: 'master',
      newEdge: false,
      showComponent: true,
      page: 0,
      masterPage: 0,
      isFromGuidedFlow: false,
      guidedFlowData: {},
      counterForReposition: 0,
      decisionGapValue: 350,
      open: false,
      anchorEl: null,
      workflowNodes: [],
      workflowEdges: [],
      setEvaluationLoader: false,
      setReviewLoader: false,
      setRenewalLoader: false,
      deleteComponentId: null,
      isActiveDialog: false,
      pageSize: 1000
    }

    this.updateComponent = this.updateComponent.bind(this);
    this.updateWorkflowName = this.updateWorkflowName.bind(this);
    this.onConnect = this.onConnect.bind(this);
    this.onElementsRemove = this.onElementsRemove.bind(this);
    this.submitRule = this.submitRule.bind(this);
    this.resetView = this.resetView.bind(this);
    this.setPopoverStatus = this.setPopoverStatus.bind(this);
    this.publishWorkflow = this.publishWorkflow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onSelectWorkflowComponent = this.onSelectWorkflowComponent.bind(this);
    this.onClickDecisionComponent = this.onClickDecisionComponent.bind(this);
    this.onClickWorkFlowComponent = this.onClickWorkFlowComponent.bind(this);
    this.resetPositioning = this.resetPositioning.bind(this);
  }

  componentDidMount() {
    this.getOrgWorkflow({ page: 0, pageSize: 1000 })
    this.props.fetchOrgCatalogAllTabs('vendor')
    this.getMasterWorkflows()
    this.props.workflowComponents();
    this.props.fetchFrameworks();
    this.props.formComponent();
    this.props.getByIdOrgWorkflow(this.props.match.params.id);
    this.props.getOrgWorkflowComponent(this.props.match.params.id);
    this.props.fetchOrgWorkflowAction(this.props.match.params.id);
    setTimeout(
      () => this.props.updateDrawerStyle(false, true),
      500
    );
    this.props.fetchOrgTemplates(0, 1000);
    this.props.fetchMasterTemplates(0, 1000);
    this.props.fetchMasterForms(0, 1000);
    this.props.fetchOrgForms(0, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchWorkflowComponentProgress === false && prevProps.fetchWorkflowComponentProgress === true) {
      this.setElementToFlow();
      this.setState({
        isShow: true,
        isShowMasterComponent: true
      })
    }

    if (prevProps.fetchMasterTemplateProg && !this.props.fetchMasterTemplateProg) {

      let criterias = this.props.masterTemplate?.Criterias || [];
      this.autoUpdateCriteria(criterias);

    }

    if (prevProps.fetchTemplateProg && !this.props.fetchTemplateProg) {

      let criterias = this.props.orgTemplate?.Criterias || [];
      this.autoUpdateCriteria(criterias);
    }

    if (this.props.orgWorkFlowCompProgress === false && prevProps.orgWorkFlowCompProgress === true) {
      this.setElementToFlow();
      this.setState({
        isShow: true,
        isShowMasterComponent: true
      })
    }

    if (this.props.orgWorkFlowActionProgress === false && prevProps.orgWorkFlowActionProgress === true) {
      this.setElementToFlow();
    }

    if (this.props.orgWorkFlowByIdProgress === false && prevProps.orgWorkFlowByIdProgress === true) {
      if (this.props.orgWorkFlowByIdList !== null) {
        this.setState({
          workflowDetails: this.props.orgWorkFlowByIdList,
          selectedStatus: this.props.orgWorkFlowByIdList.Status,
          name: this.props.orgWorkFlowByIdList.Name
        }, () => {
          this.checkForDefault();
        })
      }
    }

    if (this.props.updateOrgWorkFlowProgress === false && prevProps.updateOrgWorkFlowProgress === true) {
      if (this.state.workflowDetails.Status !== 2) {
        this.props.showSnackBar("Workflow updated successfully", "success", 3000);
      }
      this.setState({
        workflowDetails: this.props.updateOrgWorkFlowSuccess,
        selectedStatus: this.props.updateOrgWorkFlowSuccess.Status,
        isEdit: false
      }, () => {
        this.checkForDefault();
        this.props.hideWorkflowDetailUpdateDialog();
      })
    }

    if (this.props.addOrgWorkFlowCompProgress === false && prevProps.addOrgWorkFlowCompProgress === true) {
      if (this.state.isFromGuidedFlow) {
        this.setState({
          isShow: false
        })
      }
      setTimeout(() => {
        this.props.showSnackBar("Component added successfully", "success", 3000);
        this.props.getOrgWorkflowComponent(this.props.match.params.id)
        this.props.fetchOrgWorkflowAction(this.props.match.params.id);
      }, 100)
      setTimeout(() => {
        if (this.props.addOrgWorkFlowCompSuccess) {
          let masterObj = this.getMasterObjectFromId(this.props.addOrgWorkFlowCompSuccess.ComponentID)

          if (masterObj?.Slug === 'APPROVAL_WORKFLOW') {
            let compObj = this.props.orgWorkFlowCompList.find(comp => comp.OrgWorkflowComponentConfigurations.length);
            let templateObj = compObj.OrgWorkflowComponentConfigurations.find(item => item.FormID === 2);
            let parsedTemplateObj = Validator.isJson(templateObj.Value) ? JSON.parse(templateObj.Value) : {};
            this.props.fetchOrgTemplate(parsedTemplateObj?.id);
          }
        }
      }, 101)
    }

    if (this.props.updateOrgWorkFlowCompProgress === false && prevProps.updateOrgWorkFlowCompProgress === true) {
      this.props.showSnackBar("Component updated successfully", "success", 3000);
      this.setState({
        isComponentEdit: false,
        isEdit: false
      })
      this.props.getOrgWorkflowComponent(this.props.match.params.id)
    }

    if (this.props.deleteOrgWorkFlowCompProgress === false && prevProps.deleteOrgWorkFlowCompProgress === true) {
      this.props.showSnackBar("Component deleted successfully", "success", 3000);
      this.props.getOrgWorkflowComponent(this.props.match.params.id)
      this.props.fetchOrgWorkflowAction(this.props.match.params.id);
    }

    if (this.props.addOrgWorkFlowActionProgress === false && prevProps.addOrgWorkFlowActionProgress === true) {
      this.props.fetchOrgWorkflowAction(this.props.match.params.id);
      if (this.props.addOrgWorkFlowActionSuccess?.OrgWorkflowActionRuleElements?.length) {
        this.resetView();
      } else {
        this.setState({
          edgeDetails: { ...this.state.edgeDetails, ...this.props.addOrgWorkFlowActionSuccess, id: this.props.addOrgWorkFlowActionSuccess.ID }
        })
      }
    }

    if (this.props.updateOrgWorkFlowActionProgress === false && prevProps.updateOrgWorkFlowActionProgress === true) {
      this.props.fetchOrgWorkflowAction(this.props.match.params.id);
      this.props.hideMasterComponentDialog();
      this.props.showSnackBar("Action updates successfully", "success", 3000);
      this.resetView();
    }

    if (this.props.deleteOrgWorkFlowActionProgress === false && prevProps.deleteOrgWorkFlowActionProgress === true) {
      this.props.fetchOrgWorkflowAction(this.props.match.params.id);
      this.props.showSnackBar("Action deleted successfully", "success", 3000);
      this.resetView();
    }

    if (this.props.fetchAllOrgSettingsSuccess === false && prevProps.fetchAllOrgSettingsSuccess === true) {
      this.checkForDefault();
    }

    if (this.props.addOrgSettingsInprogress === false && prevProps.addOrgSettingsInprogress === true) {
      this.checkForDefault();
    }

    if (this.props.setComponentFlowPostionProg === false && prevProps.setComponentFlowPostionProg === true) {
      setTimeout(() => {
        // this.props.showSnackBar("Reset Component successfully", "success", 3000);
        this.props.getOrgWorkflowComponent(this.props.match.params.id);
        // this.props.fetchOrgWorkflowAction(this.props.match.params.id);
      }, 100)

      setTimeout(() => {
        this.setState({
          isShow: true
        })
      }, 1000);
    }
    this.handleCheckNonActionNode();
  }

  autoUpdateCriteria = (criterias) => {

    let approvalForm = this.getMasterApprovalForm();
    let components = this.props.orgWorkFlowCompList.filter(element => element.MasterComponent.Slug === 'APPROVAL_WORKFLOW');

    components.forEach(comp => {
      let componentId = comp.ID;
      let configurations = [];

      let configDatas = [];
      criterias.forEach(function (criteria, k) {
        configDatas.push({
          name: criteria?.Name,
          criteriaId: criteria?.ID,
          id: new Date().getTime() + k,
          // componentId: componentId,
          approvers: {
            users: criteria?.Collaborators || [],
            teams: []
          },
          rules: []
        })
      })
      let item = {
        ID: approvalForm.ID,
        value: JSON.stringify(configDatas)
      }

      configurations.push({ form_id: item.ID, value: item.value })


      const update = {
        name: "Approvals",
        flow_data: this.state.componentDetails?.data?.flowData,
        configurations: configurations,
      };
      this.props.updateOrgWorkflowComponent(this.props.match.params.id, componentId, update);

    })


  }

  getOrgWorkflow() {
    this.props.getOrgWorkflow({ types: [1], page: this.state.page, pageSize: this.state.pageSize })
  }

  getMasterWorkflows() {
    this.props.getMasterWorkflows({ types: [1], page: this.state.masterPage, pageSize: this.state.pageSize })
  }

  handleCheckNonActionNode() {
    const nodeElements = document.getElementsByClassName("start-node")
    Array.prototype.forEach.call(nodeElements, function (el) {
      el.parentElement.firstChild.style.display = 'none'
    });
    const nodeElements2 = document.getElementsByClassName("finish-node")
    Array.prototype.forEach.call(nodeElements2, function (el) {
      el.parentElement.lastChild.style.display = 'none'
    });
  }

  checkForDefault() {
    if (this.props.fetchAllOrgSettingsSuccess) {
      const defaultObjWorkflow = this.props.fetchAllOrgSettingsSuccess.find((item) => item.SettingName === "ORG_DEFAULT_EVALUATION_WORKFLOW");
      const defaultObjReview = this.props.fetchAllOrgSettingsSuccess.find((item) => item.SettingName === "ORG_DEFAULT_REVIEW_WORKFLOW");
      const defaultObjRenew = this.props.fetchAllOrgSettingsSuccess.find((item) => item.SettingName === "ORG_DEFAULT_RENEWAL_WORKFLOW");
      var isDefaultForWorkflow = false;
      var isDefaultForReview = false;
      var isDefaultForRenewal = false;
      // console.log('------------------===================------------------')
      // console.log(defaultObjWorkflow, 'defaultObjWorkflow')
      if (defaultObjWorkflow && defaultObjWorkflow?.Value && defaultObjWorkflow?.Value !== 'NULL') {
        try {
          isDefaultForWorkflow = JSON.parse(defaultObjWorkflow?.Value).wid === parseInt(this.props.match.params.id);
        } catch (e) {
          console.warn(e);
        }
      }
      if (defaultObjReview && defaultObjReview?.Value && defaultObjReview?.Value !== 'NULL') {
        try {
          isDefaultForReview = JSON.parse(defaultObjReview?.Value).wid === parseInt(this.props.match.params.id);
        } catch (e) {
          console.warn(e);
        }
      }
      if (defaultObjRenew && defaultObjRenew?.Value && defaultObjRenew?.Value !== 'NULL') {
        try {
          isDefaultForRenewal = JSON.parse(defaultObjRenew?.Value).wid === parseInt(this.props.match.params.id);
        } catch (e) {
          console.warn(e);
        }
      }
      // console.log('------------------===================------------------')
      // console.log(isDefaultForWorkflow, 'isDefaultForWorkflow')
      // console.log(isDefaultForReview, 'isDefaultForReview')
      // console.log(isDefaultForRenewal, 'isDefaultForRenewal')
      this.setState({
        defaultWorkflow: isDefaultForWorkflow,
        defaultForReview: isDefaultForReview,
        defaultForRenewal: isDefaultForRenewal,
        selectedStatus: this.state.workflowDetails.Status,
        setEvaluationLoader: false,
        setReviewLoader: false,
        setRenewalLoader: false
      })
    }
  }

  getMasterFormComponent = (masterComponentId, isConfiguration) => {
    const formCompList = [];
    this.props.workflowFormCompList.forEach((item) => {
      if (masterComponentId && item.ComponentIds !== null && item.ComponentIds.indexOf(parseInt(masterComponentId)) !== -1) {
        formCompList.push(item);
      }
    });
    this.setState({
      formComponentList: formCompList
    })
    return formCompList;
  }

  getMasterId(sourceId) {
    return this.state.elements.find(element => element.id === sourceId.toString())?.data?.componentId || '';
  }

  getComponentObjectFromId = (id) => {
    return this.props.orgWorkFlowCompList && this.props.orgWorkFlowCompList.find((item) => item.ID === parseInt(id));
  }

  getMasterObjectFromId = (id) => {
    return this.props.workflowComponent && this.props.workflowComponent.find((item) => item?.ID === parseInt(id));
  }

  getWorkflowCompListFromCompId = (compId) => {
    return this.props.orgWorkFlowCompList && this.props.orgWorkFlowCompList.filter((item) => item.ComponentID === parseInt(compId));
  }

  getComponentObjectById = (id) => {
    return this.props.orgWorkFlowCompList?.find((item) => item.ID === parseInt(id))
  }

  updateComponent() {
    if (this.state.selectedComponentName) {
      var configurations = [];
      if (this.state.componentDetails !== null && this.state.componentDetails.data !== null && this.state.componentDetails.data.configurations !== null) {
        this.state.componentDetails.data.configurations.forEach(function (config) {
          var obj = {
            form_id: config.FormID,
            value: config.Value
          }
          configurations.push(obj);
        });
      }

      const update = {
        name: this.state.selectedComponentName,
        flow_data: this.state.componentDetails?.data?.flowData,
        configurations: configurations
      };
      this.props.updateOrgWorkflowComponent(this.props.match.params.id, this.state.componentDetails.id, update)
    } else {
      alert('Please enter component name');
    }
  }

  updateWorkflowName = () => {
    const { workflowDetails } = this.state;
    if (this.state.name) {
      const update = {
        name: this.state.name,
        description: workflowDetails.Description,
        status: workflowDetails.Status
      };
      this.props.updateOrgWorkflow(workflowDetails.ID, update);
    } else {
      alert('Please enter workflow name');
    }
  }

  resetView() {
    this.setState({
      componentDetails: {},
      edgeDetails: {},
      formComponentList: [],
    })
    this.props.hideMasterComponentDialog();
  }

  setElementToFlow() {
    const { orgWorkFlowCompList, orgWorkFlowActionList } = this.props;
    const decisionComponentIds = [];

    if (orgWorkFlowCompList === undefined || orgWorkFlowCompList === null || orgWorkFlowCompList === '' || orgWorkFlowActionList === undefined || orgWorkFlowActionList === null || orgWorkFlowActionList === '') {
      return;
    }

    const elementList = orgWorkFlowCompList ? orgWorkFlowCompList.map((item, index) => {
      let flowData = {};
      try {
        if (item.FlowData) {
          flowData = JSON.parse(item.FlowData);
        }
      } catch (e) {
        console.warn(e);
      }
      const masterObject = this.getMasterObjectFromId(item.ComponentID);
      let label = item.Name

      const dataObjects = {
        id: item.ID.toString(),
        data: { label, componentId: item.ComponentID.toString(), flowData: item.FlowData, configurations: item.OrgWorkflowComponentConfigurations, object: item },
        position: flowData?.position ? flowData?.position : { x: 40, y: (index + 1) * 40 },
        // position: { x: 0, y: 0 },
        style: {
          background: "transparent",
          border: "none",
          borderRadius: "0",
          width: "max-content",
          "&:hove": {
            boxShadow: "none",
          },
        },
      }

      var isShowPermissionDropDown = true;
      if (masterObject && masterObject?.Slug === 'DECISION_COMPONENT') {
        isShowPermissionDropDown = false;
        decisionComponentIds.push(item.ID)
      }

      if (masterObject?.Slug === "START") {
        isShowPermissionDropDown = false;
        dataObjects.data['type'] = 'input';
      } else if (masterObject?.Slug === 'FINISH' || masterObject?.Slug === 'STOP') {
        isShowPermissionDropDown = false;
        dataObjects.data['type'] = 'output';
      }

      var isEdit = true;
      let isShowConfigIcon = false
      if (!['START', 'STOP'].includes(masterObject?.Slug) && this.props.workflowFormCompList && Array.isArray(this.props.workflowFormCompList) && this.props.workflowFormCompList.length > 0) {
        this.props.workflowFormCompList.every((witem) => {
          if (item.ComponentID && witem.ComponentIds !== null && witem.ComponentIds.indexOf(parseInt(item.ComponentID)) !== -1) {
            isShowConfigIcon = true
            return false
          } else {
            return true
          }
        });
      }

      if (masterObject && masterObject?.Slug === 'DECISION_COMPONENT') {
        isShowConfigIcon = true
      }


      let isShowDeleteIcon = true;
      if (this.props.builderFor === 'PROJECT') {
        if (this.props.finishedComponents.indexOf(parseInt(dataObjects.id)) !== -1) {
          isEdit = false;
          isShowDeleteIcon = false;
        } else if (masterObject?.Slug === 'START' && this.props.finishedComponents.length > 0) {
          isEdit = false;
        }

        if (this.props.startedComponents.indexOf(parseInt(dataObjects.id)) !== -1) {
          isShowDeleteIcon = false;
        }
      }

      // console.log(masterObject?.Slug,'masterObjectSlug')
      // console.log(isEdit,'isEdit')

      let permission = item?.Permission;
      dataObjects.data.label = nodeLabel(
        isShowConfigIcon,
        masterObject?.Slug,
        dataObjects.id,
        item.Name,
        dataObjects.position.x,
        dataObjects.position.y,
        this.onSelectWorkflowComponent,
        this.onClickDecisionComponent,
        this.onClickWorkFlowComponent,
        isEdit,
        [],
        this.removeComponent,
        isShowPermissionDropDown,
        permission,
        this.updateComponentPermission,
        this.props.builderFor,
        isShowDeleteIcon
      )

      return dataObjects
    }) : [];

    const componentEdges = orgWorkFlowActionList ? orgWorkFlowActionList.map((item, index) => {
      return {
        id: item.ID.toString(),
        source: item.SourceComponentID.toString(),
        target: item.TargetComponentID.toString(),
        type: decisionComponentIds.includes(item.SourceComponentID) ? "step" : "step",
        label: decisionComponentIds.includes(item.SourceComponentID) ? item?.Name : "",
        style: { stroke: '#d8d8d8', strokeWidth: '1px' }
      }
    }) : [];

    if (elementList !== undefined && elementList !== null && componentEdges !== undefined && componentEdges !== null && elementList.length > 0 && componentEdges.length > 0) {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(elementList, componentEdges, 'TB');
      // console.log(layoutedNodes, 'layoutedNodes')
      // console.log(layoutedEdges, 'layoutedEdges')
      this.setState({
        elements: [...layoutedNodes, ...layoutedEdges],
        workflowNodes: elementList,
        workflowEdges: componentEdges,
      })
    } else {
      this.setState({
        elements: [...elementList, ...componentEdges],
        workflowNodes: elementList,
        workflowEdges: componentEdges,
      })
    }
  }

  allowDrop = (ev) => {
    ev.preventDefault();
  }

  setUpdateComponent() {
    if (this.state.updateComponentName) {
      const update = {
        name: this.state.updateComponentName,
        flow_data: this.state.componentDetails?.data?.flowData,
        configurations: this.state.componentDetails?.data?.configurations || []
      };
      this.props.updateOrgWorkflowComponent(this.props.match.params.id, this.state.componentDetails.id, update)
    } else {
      alert('Please enter component name');
    }
  }

  removeComponent = (id) => {
    var me = this;
    this.setState({ deleteComponentId: id, isActiveDialog: true }, () => {
      me.props.showMessageDialog();
    })
  }

  deleteCriteriaItem(e, id) {
    e.stopPropagation();
    var me = this;
    this.setState({ deleteId: id, isActiveDialog: true }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.deleteComponentId) {
      this.setState({
        isShow: false
      })
      this.props.hideMessageDialog();
      this.props.deleteOrgWorkflowComponent(this.props.match.params.id, this.state.deleteComponentId);
      this.props.hideMasterComponentDialog();
      this.setState({
        isActiveDialog: false
      })
    }
  };

  updateAdminForward = (component_id) => {
    // console.log(component_id)
    const sourceObject = this.getComponentObjectFromId(component_id);
    // console.log(sourceObject, 'CompoenentDetails')
    // console.log(sourceObject, 'SourceCompoenentDetails')
    var adminForward = sourceObject.AdminForward === undefined || sourceObject.AdminForward === null || sourceObject.AdminForward === false ? true : false;
    const update = {
      name: sourceObject?.Name,
      flow_data: sourceObject?.FlowData,
      admin_forward: adminForward
    };
    this.props.updateOrgWorkflowComponent(this.props.match.params.id, component_id, update)
  }

  updateComponentPermission = (data) => {
    console.log(data, 'UpdateData');
    const sourceObject = this.getComponentObjectFromId(data.componentId);
    console.log(sourceObject, 'SourceCompoenentDetails')
    const update = {
      name: sourceObject?.Name,
      flow_data: sourceObject?.FlowData,
      permission: data.permission
    };
    this.props.updateOrgWorkflowComponent(this.props.match.params.id, data.componentId, update)
  }

  onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView()
    this.setState({
      reactFlowInstance: reactFlowInstance
    }, () => {
      const element = document.getElementsByClassName("react-flow__controls-fitview")
      console.log(element, 'element5656565')
      if (element && element.length > 0) {
        setTimeout(() => {
          element[element.length - 1].click()
        }, 1000)
      }
    })
  }

  drag = (ev, comptype, data) => {
    ev.dataTransfer.setData("text", ev.target.id);
    ev.dataTransfer.setData("data", JSON.stringify(data));
    ev.dataTransfer.setData('type', comptype)
  }

  onDoubleClick = (ev, componentId) => {
    let position = { x: 0, y: 0 }
    const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();
    position.y = reactFlowBounds.height / 2
    const payload = {
      flow_data: JSON.stringify({ position }),
      permission: 3
    }
    if (componentId) {
      const masterObject = this.getMasterObjectFromId(componentId);
      const orgWorkflowAddedList = this.getWorkflowCompListFromCompId(componentId);
      if ((orgWorkflowAddedList?.length < masterObject?.MaxOccurrence) || masterObject?.MaxOccurrence === -1) {

        this.props.addOrgWorkflowComponent(this.props.match.params.id, componentId, payload)

      } else {
        this.setState({
          isShow: true
        })
        alert('This component is already added');
      }
    }
  }

  onDoubleClickWorkFlow = (ev, wrkflwID, type, name, description) => {
    ev.stopPropagation()
    const filtered = this.props.workflowComponent.filter(comp => comp.Slug === 'WORKFLOW')
    if (filtered.length) {
      let componentId = filtered[0]?.ID
      const data = {
        name,
        description,
        "configurations": [
          {
            form_id: this.props.workflowFormCompList.filter(o => o.Name === 'workflow_template')[0].ID,
            value: JSON.stringify({ type, id: wrkflwID })
          }
        ]
      }
      const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();
      if (componentId) {
        const position = this.state.reactFlowInstance.project({ x: 0, y: reactFlowBounds.height / 2 });
        const masterObject = this.getMasterObjectFromId(componentId);
        const orgWorkflowAddedList = this.getWorkflowCompListFromCompId(componentId);
        if ((orgWorkflowAddedList?.length < masterObject?.MaxOccurrence) || masterObject?.MaxOccurrence === -1) {
          this.props.addOrgWorkflowComponent(
            this.props.match.params.id,
            componentId,
            {
              flow_data: JSON.stringify({ position }),
              permission: 3,
              ...data
            })
        }
      }
    }
  }

  onSelectWorkflowComponent = (ev, componentId, sourceComponentId, x, y, data = {}) => {
    ev.preventDefault();
    ev.stopPropagation()
    let isFromGuidedFlow = true
    if (Object.keys(data).length > 0 && data?.isParallel && data.isParallel === true) {
      const position = this.state.reactFlowInstance.project({ x, y: y + 150 });
      this.setState({ isFromGuidedFlow, guidedFlowData: { sourceComponentId } }, () => {
        this.props.addOrgWorkflowComponent(
          this.props.match.params.id,
          componentId,
          {
            flow_data: JSON.stringify({ position }),
            permission: 3,
            parallel_component: parseInt(sourceComponentId),
            ...data
          }
        )
      })
    } else if (componentId) {
      const masterObject = this.getMasterObjectFromId(componentId);
      const position = this.state.reactFlowInstance.project({ x, y: y + 150 });
      const orgWorkflowAddedList = this.getWorkflowCompListFromCompId(componentId);
      if ((orgWorkflowAddedList?.length < masterObject?.MaxOccurrence) || masterObject?.MaxOccurrence === -1) {
        this.setState({ isFromGuidedFlow, guidedFlowData: { sourceComponentId } }, () => {

          this.props.addOrgWorkflowComponent(
            this.props.match.params.id,
            componentId,
            {
              flow_data: JSON.stringify({ position }),
              permission: 3,
              after_component: parseInt(sourceComponentId),
              ...data
            }
          )
        })
      }
    }
  }

  onClickDecisionComponent = (ev, sourceComponentId, x, y) => {
    const filtered = this.props.workflowComponent.filter(comp => comp.Slug === 'DECISION_COMPONENT')
    if (filtered.length) {
      let componentId = filtered[0]?.ID
      this.onSelectWorkflowComponent(ev, componentId, sourceComponentId, x, y)
    }
  }

  onClickWorkFlowComponent = (ev, sourceComponentId, x, y, wrkflwID, type, name, description, params = {}) => {
    const filtered = this.props.workflowComponent.filter(comp => comp.Slug === 'WORKFLOW')
    console.log(name, 'Nameeee')
    if (filtered.length) {
      let componentId = filtered[0]?.ID
      const data = {
        name,
        description,
        "configurations": [
          {
            form_id: this.props.workflowFormCompList.filter(o => o.Name === 'workflow_template')[0].ID,
            value: JSON.stringify({ type, id: wrkflwID })
          }
        ],
        ...params
      }
      this.onSelectWorkflowComponent(ev, componentId, sourceComponentId, x, y, data)
    }
  }

  drop = (ev, node = true, cID, x = 0, y = 0) => {
    ev.stopPropagation()
    ev.preventDefault();
    let position = { x, y }
    let componentId = false
    let payload = {}
    const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();
    if (ev.dataTransfer.getData("type") === 'workflow') {
      const data = Validator.isJson(ev.dataTransfer.getData("data")) ? JSON.parse(ev.dataTransfer.getData("data")) : {};
      const filtered = this.props.workflowComponent.filter(comp => comp.Slug === 'WORKFLOW')
      if (filtered.length) {
        componentId = filtered[0]?.ID
        position = this.state.reactFlowInstance.project({
          x: ev.clientX - (reactFlowBounds.left + 130),
          y: ev.clientY - reactFlowBounds.top,
        });
        payload = {
          flow_data: JSON.stringify({ position }),
          name: data.name,
          description: data.description,
          "configurations": [
            {
              form_id: this.props.workflowFormCompList.filter(o => o.Name === 'workflow_template')[0].ID,
              value: JSON.stringify({ type: data.type, id: data.id })
            }
          ]
        }
      }
    } else {
      if (node) {
        const data = ev.dataTransfer.getData("text");
        componentId = data.split('_')[1];
        position = this.state.reactFlowInstance.project({
          x: ev.clientX - (reactFlowBounds.left + 130),
          y: ev.clientY - reactFlowBounds.top,
        });
      } else {
        componentId = cID
        position.y = reactFlowBounds.height / 2
      }
      payload = {
        flow_data: JSON.stringify({ position }),
        permission: 3
      }
    }
    if (componentId) {
      const masterObject = this.getMasterObjectFromId(componentId);
      const orgWorkflowAddedList = this.getWorkflowCompListFromCompId(componentId);
      if ((orgWorkflowAddedList?.length < masterObject?.MaxOccurrence) || masterObject?.MaxOccurrence === -1) {
        this.props.addOrgWorkflowComponent(this.props.match.params.id, componentId, payload)
      } else {
        this.setState({
          isShow: true
        })
        alert('This component is already added');
      }
    }
  }

  onConnect(data) {
    const nonAnimated = [];
    const masterComponentId = this.getMasterId(data.source);
    var els = this.state.elements;

    els.forEach((item) => {
      if (!item.animated) {
        nonAnimated.push(item)
      }
    });

    const masterObject = this.getMasterObjectFromId(masterComponentId);
    const orgActionsList = this.getWorkflowActionListFromSourceId(data.source);
    let addedEdge = nonAnimated;
    if ((orgActionsList?.length < masterObject?.MaxAction) || masterObject?.MaxAction === -1) {
      this.setState({
        componentDetails: {}
      })

      const sourceObject = this.getComponentObjectFromId(data.source);
      const targetObject = this.getComponentObjectFromId(data.target);

      const edgeDetails = { ...data, sourceName: sourceObject?.Name, targetName: targetObject?.Name }

      this.setState({
        edgeDetails: edgeDetails
      })
      this.addEdgeToWorkflow(edgeDetails, []);
    } else {
      alert(`Can't create action. already created ${masterObject?.MaxAction} action(s)`, 'error', true);
    }

    this.setState({
      elements: addedEdge
    })
  }

  addEdgeToWorkflow = (data, rule) => {
    const addEdge = {
      source_component_id: parseInt(data.source),
      target_component_id: parseInt(data.target),
      action_data: '',
      seq: this.props.orgWorkFlowActionList?.length > 0 ? this.props.orgWorkFlowActionList?.length + 1 : 1,
      rules: rule
    }
    this.props.addOrgWorkflowAction(this.props.match.params.id, addEdge)
  }

  updateEdgeToWorkflow = (actionId, data, rule) => {
    const addEdge = {
      source_component_id: parseInt(data.source),
      target_component_id: parseInt(data.target),
      action_data: '',
      seq: this.props.orgWorkFlowActionList?.length > 0 ? this.props.orgWorkFlowActionList?.length + 1 : 1,
      rules: rule
    }
    this.props.updateOrgWorkflowAction(this.props.match.params.id, actionId, addEdge);
  }

  onElementsRemove = (elementsToRemove) => {
    this.setState({
      elements: this.props.removeElements(elementsToRemove, [...this.state.elements])
    })
  }

  getActionObjectFromId = (id) => {
    return this.props.orgWorkFlowActionList && this.props.orgWorkFlowActionList.find((item) => item.ID === parseInt(id));
  }

  getWorkflowActionListFromSourceId = (sourceId) => {
    return this.props.orgWorkFlowActionList && this.props.orgWorkFlowActionList.filter((item) => item.SourceComponentID === parseInt(sourceId));
  }

  openBuilderForChild(component) {
    const { workflowEvaluationMapping } = this.props;
    let configurations = component.configurations;
    let configData = configurations.filter(o => o.Form.Type === "WORKFLOW_PICKER")[0];
    console.log(configData, 'configData')
    if (configData && configData.Value !== null && configData.Value !== '') {
      var config = JSON.parse(configData.Value);
      console.log(component, 'component')
      console.log(workflowEvaluationMapping, 'workflowEvaluationMapping')
      console.log(config, 'config')
      let evaluationId = 0;
      if (workflowEvaluationMapping[config.id] !== undefined) {
        evaluationId = workflowEvaluationMapping[config.id];
      }
      let newURL = window.location.origin;
      newURL += `/app/evaluate/${evaluationId}/workflow/${config.id}/configure?hide_B=true`;
      if (evaluationId === 0) {
        newURL += '&parentEvaluationId=' + this.props.match.params.evaluation_id
      }
      window.open(newURL, '_blank');
    }
  }

  onElementClick = (element, ev = false) => {
    console.log(element, 'element555555')
    if (ev) {
      ev.stopPropagation()
      const className = ev.target.className.toString()
      const isEventTrue = className.trim().includes('show-config')
      if (!isEventTrue) return true;
    }

    if (element.data && this.props.builderFor === 'PROJECT') {
      let component = element;
      if (component && component?.data?.object?.MasterComponent?.Slug === "WORKFLOW") {
        if (component?.data?.configurations && component?.data?.configurations.length > 0) {
          console.log(component, 'ComponentDetails')
          this.openBuilderForChild(component.data);
          return;
        }
      }
    }

    this.props.hideMasterComponentDialog();
    let type = null;
    if (element.data) {
      type = 'COMPONENT';
      this.setState({
        showComponent: false,
        componentDetails: element,
        selectedComponentName: element.data?.object?.Name,
        selectedComponentSLUG: this.state.componentDetails?.data?.object?.MasterComponent?.Slug,
        edgeDetails: {}
      })
      const masterComponentId = this.getMasterId(element.id);
      this.getMasterFormComponent(masterComponentId, true);
    } else if (element?.source) {
      type = 'ACTION';
      const masterComponentId = this.getMasterId(element.source);
      const actionObject = this.getActionObjectFromId(element.id);
      const sourceObject = this.getComponentObjectFromId(element.source);
      const targetObject = this.getComponentObjectFromId(element.target);
      this.getMasterFormComponent(masterComponentId, false);
      this.setState({
        componentDetails: {},
        edgeDetails: {
          ...element, ...actionObject, sourceName: sourceObject?.Name, targetName: targetObject?.Name
        }
      })
    }

    // console.log(this.state.componentDetails,'componentDetails')
    // console.log(this.state.edgeDetails,'edgeDetails')
    if (type === 'COMPONENT' && this.props.isEvalStarted === true && this.props.builderFor === 'PROJECT' && this.state.componentDetails && this.state.componentDetails?.data?.object?.MasterComponent?.Slug === "SELLER_INSTRUCTION") {
      this.props.getSellerInstruction(this.state.componentDetails.id)
    } else if (type === 'COMPONENT' && this.props.isEvalStarted === true && this.props.builderFor === 'PROJECT' && this.state.componentDetails && this.state.componentDetails?.data?.object?.MasterComponent?.Slug === "APPROVAL_WORKFLOW") {
      this.props.getApprovalCriterias(this.state.componentDetails.id)
    }

    this.setState({
      isComponentEdit: false
    }, () => {
      this.props.showMasterComponentDialog();
    })
  }

  closeRightModal() {
    this.setState({
      showComponent: true
    })
    this.props.hideMasterComponentDialog();
  }


  isApprovalComponent = (component) => {
    if (component?.slug === 'APPROVAL_WORKFLOW') {
      return true;
    } else {
      return false;
    }
  }

  isApprovalExists = () => {
    let isFound = this.props.orgWorkFlowCompList.find(element => element.MasterComponent.Slug === 'APPROVAL_WORKFLOW');
    if (isFound) {
      return true;
    } else {
      return false;
    }
  }

  getMasterApprovalForm = () => {
    var approvalForm = this.props.workflowFormCompList.filter(o => o.Name === 'evaluation_approval')[0];
    return approvalForm;
  }

  submitConfiguration = (configurations, override_response, templateId, templateType) => {
    if (this.state.formComponentList.length === 0) {
      this.resetView();
      this.props.hideMasterComponentDialog();
    } else {
      if (this.isApprovalExists() && templateId !== null && templateType !== null) {
        if (templateType === 'master') {
          this.props.fetchMasterTemplate(templateId);
        } else {
          this.props.fetchOrgTemplate(templateId);
        }
      }

      let componentName = this.state.selectedComponentName;
      let comSlug = '';
      try {
        comSlug = this.state.componentDetails?.data?.object?.MasterComponent?.Slug;
      } catch { }


      if (comSlug === 'FORM' && componentName === 'Forms') {
        if (configurations !== undefined && configurations !== null && configurations !== '' && configurations.length > 0) {
          const formConfig = Validator.isJson(configurations[0].value) ? JSON.parse(configurations[0].value) : {}
          if (formConfig.label) {
            componentName = formConfig.label;
          }
        }
      } else if (comSlug === 'WORKFLOW' && componentName === 'Workflows') {
        if (configurations !== undefined && configurations !== null && configurations !== '' && configurations.length > 0) {
          const workflowComConfig = Validator.isJson(configurations[0].value) ? JSON.parse(configurations[0].value) : {}
          if (workflowComConfig.label) {
            componentName = workflowComConfig.label;
          }
        }
      }

      const update = {
        name: componentName,
        flow_data: this.state.componentDetails?.data?.flowData,
        configurations: configurations,
        override_response: override_response
      };
      this.props.updateOrgWorkflowComponent(this.props.match.params.id, this.state.componentDetails.id, update);
      this.props.hideMasterComponentDialog();

      if (this.props.builderFor === 'PROJECT' && comSlug === 'SELLER_INSTRUCTION') {
        this.props.updateSellerInstruction(this.state.componentDetails.id, update);
      }
    }
  }

  submitRule = (requestRule) => {
    if (this.state.formComponentList.length === 0) {
      this.resetView();
    } else {
      if (this.state.edgeDetails.id) {
        this.updateEdgeToWorkflow(this.state.edgeDetails.id, this.state.edgeDetails, requestRule)
      } else {
        this.addEdgeToWorkflow(this.state.edgeDetails, requestRule);
      }
    }
    this.props.hideMasterComponentDialog();
  }

  removeAction = (actionId) => {
    this.props.deleteOrgWorkflowAction(this.props.match.params.id, actionId);
  }

  publishWorkflow(states) {
    const update = { ...this.state.workflowDetails, status: states };
    this.setState({
      popoverState: null
    })
    this.props.updateOrgWorkflow(this.state.workflowDetails.ID, update);
    // const update = { ...workflowDetails, status: states };
    // setStatusPopupState(null);
    // dispatch(updateOrgWorkflow(workflowDetails.ID, update));
  }

  publishWorkflow_bkp_withcheck_req_config(states) {
    const { orgWorkFlowCompList } = this.props;
    var isReqGatheringAdded = false;
    var reqGatId = '';
    orgWorkFlowCompList.forEach((com) => {
      var component = this.getMasterObjectFromId(com.ComponentID);
      if (component.Slug === "REQUIREMENT_GATHERING") {
        isReqGatheringAdded = true;
        reqGatId = com.ID;
      }
    });

    if (isReqGatheringAdded === false) {
      alert("Requirement Gathering component required.");
    } else {
      const sourceObject = this.getComponentObjectFromId(reqGatId);
      if (sourceObject.OrgWorkflowComponentConfigurations.length <= 0) {
        alert("Configuration not updated for Requirement Gathering component.");
      } else {
        const update = { ...this.state.workflowDetails, status: states };
        this.setState({
          popoverState: null
        })
        this.props.updateOrgWorkflow(this.state.workflowDetails.ID, update);
      }
    }
    // const update = { ...workflowDetails, status: states };
    // setStatusPopupState(null);
    // dispatch(updateOrgWorkflow(workflowDetails.ID, update));
  }

  toggleEvaluationTemplate = (e, type) => {
    let message = "NULL";
    if (e.target.checked) {
      message = JSON.stringify({
        type: 'ORG',
        wid: parseInt(this.props.match.params.id)
      })
    }
    var setEvaluationLoader = false;
    var setReviewLoader = false;
    var setRenewalLoader = false;
    if (type === 'EVALUATION') {
      setEvaluationLoader = true;
    } else if (type === 'REVIEW') {
      setReviewLoader = true;
    } else if (type === 'RENEWAL') {
      setRenewalLoader = true;
    }

    this.setState({
      setEvaluationLoader: setEvaluationLoader,
      setReviewLoader: setReviewLoader,
      setRenewalLoader: setRenewalLoader,
    }, () => {
      this.props.addOrgSettings([
        {
          "name": "ORG_DEFAULT_" + type + "_WORKFLOW",
          "value": message
        }
      ])
    })
  }

  setPopoverStatus(event) {
    event.stopPropagation()
    this.setState({
      popoverState: event.currentTarget || event.current
    })
  }

  handleClose(event) {
    event.stopPropagation()
    this.setState({
      popoverState: null
    })
  }

  getComponentDetails(sourceId) {
    const componentData = this.getComponentObjectFromId(sourceId);
    const sourceCompDetails = this.getMasterObjectFromId(componentData?.ComponentID);
    return sourceCompDetails;
  }

  selectEdgeforEdit(data) {
    const sourceCompDetails = data.sourceComponent;
    const { orgWorkFlowActionList } = this.props;
    const componentEdges = orgWorkFlowActionList ? orgWorkFlowActionList.map((item, index) => {
      return {
        id: item.ID.toString(),
        source: item.SourceComponentID.toString(),
        target: item.TargetComponentID.toString(),
        arrowHeadType: 'arrowclosed',
        style: { stroke: '#3f4e6b' },
        type: 'buttonedge',
      }
    }) : [];
    const actionIdData = componentEdges.filter(o => parseInt(o.target) === parseInt(sourceCompDetails.target))
    this.onElementClick(actionIdData[0]);
  }

  handleOnChangeTab = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  handleAccordionChange = (panel) => (event, isExpanded) => {
    event.stopPropagation()
    this.setState({ expanded: isExpanded ? panel : false });
  };

  onNodeDragStop = (event, node) => {
    event.stopPropagation()
    return;
    // this.closeRightModal();
    // // console.log(node.position,'Node Position')
    // const componentObj = this.getComponentObjectById(node.id);
    // if (componentObj) {
    //   var configurations = [];
    //   if (componentObj.OrgWorkflowComponentConfigurations !== null && componentObj.OrgWorkflowComponentConfigurations.length > 0) {
    //     componentObj.OrgWorkflowComponentConfigurations.forEach(function (config) {
    //       var obj = {
    //         form_id: config.FormID,
    //         value: config.Value
    //       }
    //       configurations.push(obj);
    //     });
    //   }
    //   const update = {
    //     name: componentObj.Name,
    //     flow_data: JSON.stringify({ position: node.position }),
    //     description: '',
    //     configurations: configurations
    //   };
    //   this.props.updateOrgWorkflowComponent(this.props.match.params.id, node.id, update);
    // }
  }

  getConnectedActions = (id) => {
    return this.props.orgWorkFlowActionList && this.props.orgWorkFlowActionList.filter((item) => item.SourceComponentID === parseInt(id));
  }

  getOrgComponentUsingComponentId = (id, type) => {
    return this.props.orgWorkFlowCompList?.find((item) => item[type] === parseInt(id))
  }


  getConnectedComponents(source) {
    var actions = this.getConnectedActions(source.ID);
    var connectedComponents = [];
    var me = this;
    if (actions !== null && actions.length > 0) {
      actions.forEach(function (action) {
        var component = me.getOrgComponentUsingComponentId(action.TargetComponentID, 'ID');
        connectedComponents.push(component);
      });
    }
    return connectedComponents;
  }

  resetPositioning() {
    const { workflowNodes, workflowEdges } = this.state;
    console.log(workflowNodes, 'workflowNodes')
    console.log(workflowEdges, 'workflowEdges')
    let newNodes = [];
    workflowNodes.forEach(function (itm) {
      itm.position.x = 500;
      itm.position.y = 500;
      newNodes.push(itm)
    })
    this.setState({
      elements: [...newNodes, workflowEdges]
    })
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(newNodes, workflowEdges, 'TB');
    console.log(layoutedNodes, 'layoutedNodes')
    console.log(layoutedEdges, 'layoutedEdges')
    let newarr = [...layoutedNodes, ...layoutedEdges]
    console.log(newarr, 'newarr')
    this.setState({
      elements: newarr
    })
  }

  updateWorkflow = (name) => {
    const { workflowDetails } = this.state;
    // console.log(workflowDetails, 'workflowDetails555')
    if (name.trim().length === 0) {
      alert('Please enter workflow name');
      return;
    }

    const update = {
      name: name,
      // description: workflowDescription,
      status: workflowDetails.Status
    };
    this.props.updateOrgWorkflow(workflowDetails.ID, update);
  }

  render() {
    const classes = this.props.classes;

    var { isShow, elements,
      componentDetails, edgeDetails, selectedStatus,
      workflowDetails, isComponentEdit, formComponentList, expanded,
      selectedComponentName, selectedComponentSLUG, defaultWorkflow, defaultForReview, defaultForRenewal, activeTab, showComponent } = this.state;

    const { workflowComponent, fetchWorkflowComponentProgress, isMasterComponentDialogOpen } = this.props;

    const wrkComponents = workflowComponent.sort(function (a, b) { if (a.Seq > b.Seq) { return 1 } else if (a.Seq < b.Seq) { return -1 } else { return 0 } })

    let isHideDefaultForReviewOption = false;
    let role = this.props.user?.Role;
    const EnableReviewForUsersCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableReviewForUsersCheck')
    const EnableReviewForAdminsCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableReviewForAdminsCheck')
    if (((!EnableReviewForAdminsCheck && role !== 'OrgUser') || (!EnableReviewForUsersCheck && role === 'OrgUser'))) {
      isHideDefaultForReviewOption = true;
    }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let isDisable = selectedStatus;
    if (isMasterComponentDialogOpen && this.props.builderFor === 'PROJECT' && componentDetails && componentDetails.data) {
      let componentId = parseInt(componentDetails.id);
      if (this.props.finishedComponents && this.props.finishedComponents.indexOf(componentId) !== -1) {
        isDisable = 2;
      } else {
        isDisable = 1;
      }
    }

    return (
      <div>
        {this.props.builderFor === 'NEW_WORKFLOW' && <AppBarV2
          title={workflowDetails?.Name}
          newHeader={true}
          withBack={true}
          isEditableTitle={true}
          submitTitle={(value) => {
            this.updateWorkflow(value)
          }}
          isSubmittingTitle={this.props.updateOrgWorkFlowProgress}
        />}
        {this.props.builderFor === 'NEW_WORKFLOW' && <div className={classes.secondarySection}>
          <div className={classes.workflowBtns}>
            {false && <SqureButton
              style={{ marginRight: 10 }}
              cls={classes.actionBtn}
              variant={"outlined"}
              onClick={(e) => {
                this.resetPositioning()
              }}
            >
              Reset Positioning
            </SqureButton>}

            {false && workflowDetails.Status === 2 && workflowDetails?.Type && (
              <SqureButton
                className={classes.actionBtn}
                variant={"outlined"}
                endIcon={<ArrowDropDownIcon />}
                onClick={(event) => {
                  event.stopPropagation()
                  this.setState({ open: true, anchorEl: event.currentTarget || event.current })
                }}
              >
                Set Default
              </SqureButton>
            )}
            &nbsp;&nbsp;&nbsp;
            {/* <button type="button" className={classes.publishBtn} onClick={() => this.publishWorkflow(2)}>Publish</button>
            <button type="button" className={classes.newUserBtn} onClick={() => this.publishWorkflow(1)}>Draft</button> */}
            <SqureButton
              cls={classes.actionBtn}
              variant={"outlined"}
              onClick={(e) => {
                this.publishWorkflow(1)
              }}
            >

              {workflowDetails.Status === 2 ? 'Edit' : 'Save as Draft'}

            </SqureButton>
            <SqureButton
              cls={classes.actionBtn}
              variant={"contained"}
              onClick={(e) => {
                this.publishWorkflow(2)
              }}
            >
              {workflowDetails.Status === 2 ? 'Published' : 'Publish'}
            </SqureButton>

          </div>
        </div>}
        <div className={classes.formBuilderMain}>
          {!isMasterComponentDialogOpen && <div className={classnames(classes.dragElement, showComponent ? classes.showComponent : classes.hideComponent)}>
            <div className={classes.tabContainer}>
              {false && <Paper className={classes.tabs} square>
                <Tabs
                  value={activeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={this.handleOnChangeTab}
                  aria-label="workflow tabs"
                >
                  <Tab label="Workflows" />
                  <Tab label="Components" />
                </Tabs>
              </Paper>}
              <IconButton aria-label="delete" className={classnames(classes.closeIconSection, classes.tabToggleIcon)} onClick={() => this.setState({ showComponent: !showComponent })}>
                {showComponent && <Tooltip title="Close"><ClearIcon style={{ color: '#282D30' }} /></Tooltip>}
                {!showComponent && <Tooltip title="Components" ><DoubleArrowIcon style={{ color: '#282D30' }} /></Tooltip>}
              </IconButton>
            </div>
            <div className={classes.tabContentContainer}>
              {activeTab === 0 && (
                <>
                  <Accordion className={classes.accodian} expanded={expanded === 'master'} onChange={this.handleAccordionChange('master')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="master-content"
                      id="master-header"
                    >
                      <Typography>Expent Workflow Library</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          maxHeight: "calc( 100vh - 330px)",
                          paddingBottom: 15,
                          overflowY: 'auto'
                        }}
                      >
                        {this.props.masterWorkFlowList?.Data?.length > 0 && this.props.masterWorkFlowList?.Data?.map((item, index) => (
                          <div
                            key={index}
                            id={'master_workflow_' + item.ID}
                            className="draggable"
                            draggable="true"
                            onDoubleClick={(event) => this.onDoubleClickWorkFlow(event, item.ID, 'MASTER', item.Name, item.Description)}
                            onDragStart={(event, node) => this.drag(event, 'workflow', { type: 'MASTER', id: item.ID, name: item.Name, description: item.Description })}
                          >
                            <div className="img"><Image src={Workflow} alt="workflow" /></div>
                            <div>
                              <p className="draggableLabel">{item.Name}</p>
                              <p className="draggableDec">{item?.Description}</p>
                            </div>
                          </div>
                        ))}
                        {this.props.masterWorkFlowProgress && (<Grid container style={{ justifyContent: 'center' }}><CircularProgress /></Grid>)}
                        {!this.props.masterWorkFlowProgress && this.props.masterWorkFlowList?.Data?.length < parseInt(this.props.masterWorkFlowList?.total) && (
                          <div style={{ padding: '16px 0px', textAlign: 'center' }}>
                            <span
                              onClick={() => {
                                this.setState({ masterPage: this.state.masterPage + 1 }, () => {
                                  this.getMasterWorkflows();
                                });
                              }}
                              style={{
                                display: 'inline-block',
                                border: '1px solid #FFF',
                                cursor: 'pointer',
                                padding: '10px 14px',
                                fontSize: '12px',
                                background: '#4A87F8',
                                borderRadius: '20px',
                                color: 'white'
                              }}
                            >Load more</span>
                          </div>
                        )}
                      </div>

                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'org'} onChange={this.handleAccordionChange('org')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="org-content"
                      id="org-header"
                    >
                      <Typography>Custom Workflow</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          maxHeight: "calc( 100vh - 330px)",
                          overflowY: 'auto',
                          paddingBottom: 15
                        }}
                      >
                        {this.props.orgWorkFlowList?.Data?.length > 0 && this.props.orgWorkFlowList?.Data?.map((item, index) => (
                          <div
                            key={index}
                            id={'org_workflow_' + item.ID}
                            className="draggable"
                            draggable="true"
                            onDoubleClick={(event) => this.onDoubleClickWorkFlow(event, item.ID, 'ORG', item.Name, item.Description)}
                            onDragStart={(event, node) => this.drag(event, 'workflow', { type: 'ORG', id: item.ID, name: item.Name, description: item.Description })}
                          >
                            <div className="img"><Image src={Workflow} alt="workflow" /></div>
                            <div>
                              <p className="draggableLabel">{item.Name}</p>
                              <p className="draggableDec">{item?.Description}</p>
                            </div>
                          </div>
                        ))}
                        {this.props.orgWorkFlowProgress && (<Grid container style={{ justifyContent: 'center' }}><CircularProgress /></Grid>)}
                        {!this.props.orgWorkFlowProgress && this.props.orgWorkFlowList?.Data?.length < parseInt(this.props.orgWorkFlowList?.total) && (
                          <div style={{ padding: '16px 0px', textAlign: 'center' }}>
                            <span
                              style={{
                                display: 'inline-block',
                                border: '1px solid #FFF',
                                cursor: 'pointer',
                                padding: '10px 14px',
                                fontSize: '12px',
                                background: '#4A87F8',
                                borderRadius: '20px',
                                color: 'white'
                              }}
                              onClick={() => {
                                this.setState({ page: this.state.page + 1 }, () => {
                                  this.getOrgWorkflow();
                                });
                              }}
                            >Load more</span>
                          </div>
                        )}
                      </div>

                    </AccordionDetails>
                  </Accordion>
                </>
              )}

              {activeTab === 1 && (
                <div style={{ maxHeight: "calc( 100vh - 200px)", overflowY: 'auto' }} >
                  {!fetchWorkflowComponentProgress && wrkComponents.map((item, index) => (
                    <div
                      onDoubleClick={(event) => this.onDoubleClick(event, item.ID)}
                      id={'div_' + item.ID}
                      key={index}
                      draggable="true"
                      className="draggable"
                      onDragStart={(event, node) => this.drag(event, 'component', { id: item.ID, data: { label: item.Name }, position: { x: 0, y: 0 } })}
                    >
                      <div className="img"><CustomImage slug={item.Slug} /></div>
                      <div>
                        <p className="draggableLabel">{item.Name}</p>
                        <p className="draggableDec">{item?.Description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>}
          {<div className={classes.flowSection} id="dropSection" onDragOver={(event) => this.allowDrop(event)}>
            {isShow && (
              <ReactFlowProvider>
                <div style={{ width: '100%', height: '100%' }} ref={this.reactFlowWrapper}>
                  <ReactFlow
                    elements={elements}
                    onConnect={this.onConnect}
                    onElementsRemove={this.onElementsRemove}
                    onElementClick={(event, element) => this.onElementClick(element, event)}
                    onLoad={this.onLoad}
                    snapToGrid={true}
                    snapGrid={[15, 15]}
                    style={{ background: '#F8F7F7' }}
                    onNodeDragStop={this.onNodeDragStop}
                    onDrop={(event) => this.drop(event)}
                    edgeTypes={{
                      buttonedge: (props) => ButtonEdge({
                        ...props,
                        data: { ...props },
                        sourceDetails: this.getComponentDetails(props.source),
                        selectEdgeforEdit: (data) => this.selectEdgeforEdit({
                          masterComponentData: data,
                          sourceComponent: props
                        }),
                        removeEdge: (id) => this.removeAction(id)
                      }),
                    }}
                    onEdgeMouseEnter={(event, edge) => {
                      event.stopPropagation()
                      event.preventDefault()
                      if (edge.id) {
                        const element = document.getElementById(`edgebutton_${edge.id}`)
                        if (element) {
                          element.style.display = 'block'
                        }
                      }
                    }}
                    onEdgeMouseLeave={(event, edge) => {
                      event.stopPropagation()
                      event.preventDefault()
                      if (edge.id) {
                        const element = document.getElementById(`edgebutton_${edge.id}`)
                        if (element) {
                          element.style.display = 'none'
                        }
                      }
                    }}
                  >
                    <Controls
                      showInteractive={false}
                      onInteractiveChange={e => { }}
                    />
                    <Background color="#000" gap={30} />
                  </ReactFlow>
                </div>
              </ReactFlowProvider>
            )}
            {
              !isShow && <div className={classes.loader}><CircularProgress /></div>
            }
          </div>}

          {isMasterComponentDialogOpen && (
            <div className={classes.elementDetails}>
              <div className={classes.componentDetailsSection}>
                <div className={classes.componentDetailsHeader}>
                  <div style={{ flex: 1 }}>
                    {isDisable === 2 && (
                      <span
                        className={classes.componentname}
                      >
                        {selectedComponentName}
                      </span>)}

                    {isDisable === 1 && !isComponentEdit && (
                      <span
                        className={classes.componentname}
                        onClick={() => {
                          this.setState({
                            isComponentEdit: true
                          })
                        }}
                      >
                        {selectedComponentName}
                      </span>)}
                    {isComponentEdit && <span>
                      <input defaultValue={selectedComponentName}
                        onChange={(e) => {
                          this.setState({
                            selectedComponentName: e.target.value
                          })
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            this.updateComponent();
                          }, 1000);
                        }}
                        className={classes.inputSection} />
                    </span>}
                  </div>
                  &nbsp;
                  {isDisable === 1 && (
                    <Image
                      src={deleteIcon}
                      onClick={() => {
                        if (componentDetails?.data) this.removeComponent(componentDetails.id)
                        else this.removeAction(edgeDetails.id)
                      }}
                      alt="deleteIcon"
                      className={classes.deleteIcon}
                    />
                  )}
                  &nbsp;
                  <IconButton aria-label="delete" className={classes.closeIconSection} onClick={() => this.closeRightModal(componentDetails)}>
                    <ClearIcon style={{ color: '#282D30' }} />
                  </IconButton>
                </div>
                <div className={classes.componentDetailsBody}>
                  {componentDetails?.data && (
                    <WorkFlowConfiguration
                      workflowStatus={isDisable}
                      submitData={this.submitConfiguration}
                      configurations={componentDetails?.data?.configurations}
                      formComponentList={formComponentList}
                      componentDetails={componentDetails}
                      orgWorkFlowCompList={this.props.orgWorkFlowCompList}
                      workflowComponents={this.props.workflowComponent}
                      frameworksList={this.props.frameworksList}
                      workflowId={this.props.match.params.id}
                      closeConfiguration={this.resetView}
                      isMasterWorkflow={false}
                      builderFor={this.props.builderFor}
                      isEvalStarted={this.props.isEvalStarted}
                      sellerInstruction={this.props.sellerInstruction}
                      approvedCriteriesIds={this.props.approvedCriteriesIds}
                      projectApprovalCriterias={this.props.projectApprovalCriterias}
                      selectedComponentSLUG={selectedComponentSLUG}
                      startedComponents={this.props.startedComponents}
                      isShowChooseTemplateOption={this.props.isShowChooseTemplateOption}
                      addUpdateApprovalCriterias={(component, data, type) => { this.props.addUpdateApprovalCriterias(component, data, type) }}
                    />
                  )}
                  {edgeDetails?.source && (
                    <WorkFlowRule
                      workflowStatus={isDisable}
                      formComponentList={formComponentList}
                      orgWorkflowActionRuleElements={edgeDetails.OrgWorkflowActionRuleElements || []}
                      submitData={this.submitRule} />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {this.props.isWorkflowDetailsUpdateDialogOpen && <WorkflowDetailsUpdateDialog
          workflowDetails={this.state.workflowDetails}
          isNew={false}
        />}
        <Popover
          id="Popover-one"
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={() => this.setState({ anchorEl: null, open: null })}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <List>
            <ListItem
              classes={{
                button: classes.listItem,
              }}
            >
              <Typography variant="inherit">
                Set Default
              </Typography>
            </ListItem>
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {!this.state.setEvaluationLoader && <Checkbox
                  className={classes.checkbox}
                  disabled={false}
                  checked={defaultWorkflow}
                  onChange={e => {
                    e.stopPropagation()
                    this.toggleEvaluationTemplate(e, 'EVALUATION')
                  }}
                />}
                {this.state.setEvaluationLoader && <CircularProgress style={{ width: 20, height: 20, margin: 10 }} />}
              </ListItemIcon>
              <Typography variant="inherit">
                Evaluation
              </Typography>
            </ListItem>
            {isHideDefaultForReviewOption === false && <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {!this.state.setReviewLoader && <Checkbox
                  className={classes.checkbox}
                  disabled={false}
                  checked={defaultForReview}
                  onChange={e => {
                    e.stopPropagation()
                    this.toggleEvaluationTemplate(e, 'REVIEW')
                  }} />}

                {this.state.setReviewLoader && <CircularProgress style={{ width: 20, height: 20, margin: 10 }} />}

              </ListItemIcon>
              <Typography variant="inherit">
                Review
              </Typography>
            </ListItem>}
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {!this.state.setRenewalLoader && <Checkbox
                  className={classes.checkbox}
                  disabled={false}
                  checked={defaultForRenewal}
                  onChange={e => {
                    e.stopPropagation()
                    this.toggleEvaluationTemplate(e, 'RENEWAL')
                  }}
                />}

                {this.state.setRenewalLoader && <CircularProgress style={{ width: 20, height: 20, margin: 10 }} />}

              </ListItemIcon>
              <Typography variant="inherit">
                Renewal
              </Typography>
            </ListItem>
          </List>
        </Popover>
        {this.state.isActiveDialog && <MessageDialog messageData={messageData} callBack={this.callBack} />}
      </div >
    );
  }
}

DragAndDropSection.defaultProps = {
  builderFor: 'NEW_WORKFLOW',
  sellerInstruction: '',
  projectApprovalCriterias: [],
  isEvalStarted: true,
  approvedCriteriesIds: [],
  startedComponents: [],
  isShowChooseTemplateOption: true
};

export default connector(compose(
  withRouter,
  withStyles(styles)
)(DragAndDropSection));

const foreignObjectSize = 20;

function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
  removeEdge,
  sourceDetails,
  selectEdgeforEdit
}) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  var isEditButton = false;
  // var isEditButton = (sourceDetails?.MaxAction !== 0 && sourceDetails?.MaxAction !== 1) ? true : false;

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <body>
          {!isEditButton && <button
            className="edgebutton"
            id={`edgebutton_${id}`}
            onClick={() => removeEdge(id)}
          >
            <Image src={deleteIcon} alt="editIcon" style={{ width: 9, height: 9 }} />
          </button>}
          {false && isEditButton && <button
            className="edgebutton"
            id={`edgebutton_${id}`}
            onClick={() => selectEdgeforEdit(sourceDetails)}
          >
            <Image src={editIcon} alt="editIcon" style={{ width: 9, height: 9 }} />
          </button>}
        </body>
      </foreignObject>
    </>
  );
}