import Products from "assets/images/home/products.svg";
import Templates from "assets/images/templates.svg";

export const templates = [
  {
    primary: "Questionnaire",
    template: 'evaluation',
    secondary: "Manage your questionnaires",
    image: Products,
    activeImage: true,
    link: '/app/settings/templates/evaluation'
  },
  {
    primary: "Forms",
    template: 'form',
    secondary: "Manage your forms",
    image: Templates,
    activeImage: true,
    link: '/app/settings/templates/forms'
  },
]

export const templateOptions = [
  { url: "", label: "Add Manually", key: "addManually", addManually: true },
  { url: "", label: "Choose from existing template", key: "chooseViaExisting", link: "/app/settings/templates/1/preview", isCheckBox: true },
  { url: "", key: "importTemplate", label: "Import Template" },
];

export const formOptions = [
  { url: "", label: "Add Manually", key: "addManually", addManually: true },
  { url: "", label: "Choose from existing form", key: "chooseViaExisting", link: "/app/settings/form/1/preview", isCheckBox: true },
  // { url: "", key: "importForm", label: "Import Form" },
];

export const automationOptions = [
  { url: "", label: "Create from Scratch ", key: "addManually", addManually: true },
  { url: "", label: "Use Existing Template", key: "chooseViaExisting" },
  // { url: "", key: "importForm", label: "Import Form" },
];