import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";

export const START_FETCH_DOCUMENT_V2_TYPE = "START_FETCH_DOCUMENT_V2_TYPE";
export const END_FETCH_DOCUMENT_V2_TYPE = "END_FETCH_DOCUMENT_V2_TYPE";

export const START_ADD_DOCUMENT_V2_TYPE = "START_ADD_DOCUMENT_V2_TYPE";
export const END_ADD_DOCUMENT_V2_TYPE = "END_ADD_DOCUMENT_V2_TYPE";

export const START_UPDATE_DOCUMENT_V2_TYPE = "START_UPDATE_DOCUMENT_V2_TYPE";
export const END_UPDATE_DOCUMENT_V2_TYPE = "END_UPDATE_DOCUMENT_V2_TYPE";

export const START_DELETE_DOCUMENT_V2_TYPE = "START_DELETE_DOCUMENT_V2_TYPE";
export const END_DELETE_DOCUMENT_V2_TYPE = "END_DELETE_DOCUMENT_V2_TYPE";

export const START_ADD_DOCUMENT_TYPE_COLLABORATORS = "START_ADD_DOCUMENT_TYPE_COLLABORATORS";
export const END_ADD_DOCUMENT_TYPE_COLLABORATORS = "END_ADD_DOCUMENT_TYPE_COLLABORATORS";

export const START_FETCH_DOCUMENT_TYPE_TABS = "START_FETCH_DOCUMENT_TYPE_TABS";
export const END_FETCH_DOCUMENT_TYPE_TABS = "END_FETCH_DOCUMENT_TYPE_TABS";

export const START_ADD_DOCUMENT_TYPE_SECTION = "START_ADD_DOCUMENT_TYPE_SECTION";
export const END_ADD_DOCUMENT_TYPE_SECTION = "END_ADD_DOCUMENT_TYPE_SECTION";
export const START_UPDATE_DOCUMENT_TYPE_SECTION = "START_UPDATE_DOCUMENT_TYPE_SECTION";
export const END_UPDATE_DOCUMENT_TYPE_SECTION = "END_UPDATE_DOCUMENT_TYPE_SECTION";
export const START_DELETE_DOCUMENT_TYPE_SECTION = "START_DELETE_DOCUMENT_TYPE_SECTION";
export const END_DELETE_DOCUMENT_TYPE_SECTION = "END_DELETE_DOCUMENT_TYPE_SECTION";

export const START_ADD_DOCUMENT_TYPE_SECTION_ITEM = "START_ADD_DOCUMENT_TYPE_SECTION_ITEM";
export const END_ADD_DOCUMENT_TYPE_SECTION_ITEM = "END_ADD_DOCUMENT_TYPE_SECTION_ITEM";
export const START_UPDATE_DOCUMENT_TYPE_SECTION_ITEM = "START_UPDATE_DOCUMENT_TYPE_SECTION_ITEM";
export const END_UPDATE_DOCUMENT_TYPE_SECTION_ITEM = "END_UPDATE_DOCUMENT_TYPE_SECTION_ITEM";
export const START_DELETE_DOCUMENT_TYPE_SECTION_ITEM = "START_DELETE_DOCUMENT_TYPE_SECTION_ITEM";
export const END_DELETE_DOCUMENT_TYPE_SECTION_ITEM = "END_DELETE_DOCUMENT_TYPE_SECTION_ITEM";

export const START_FETCH_DOCUMENT_TYPE_FILES = "START_FETCH_DOCUMENT_TYPE_FILES";
export const END_FETCH_DOCUMENT_TYPE_FILES = "END_FETCH_DOCUMENT_TYPE_FILES";

export const START_ADD_DOCUMENT_TYPE_FILES = "START_ADD_DOCUMENT_TYPE_FILES";
export const END_ADD_DOCUMENT_TYPE_FILES = "END_ADD_DOCUMENT_TYPE_FILES";

export const START_UPDATE_DOCUMENT_TYPE_FILES = "START_UPDATE_DOCUMENT_TYPE_FILES";
export const END_UPDATE_DOCUMENT_TYPE_FILES = "END_UPDATE_DOCUMENT_TYPE_FILES";

export const START_DELETE_DOCUMENT_TYPE_FILES = "START_DELETE_DOCUMENT_TYPE_FILES";
export const END_DELETE_DOCUMENT_TYPE_FILES = "END_DELETE_DOCUMENT_TYPE_FILES";

export const SHOW_ADD_CONTRACT_DETAILS_DIALOG = "SHOW_ADD_CONTRACT_DETAILS_DIALOG";
export const HIDE_ADD_CONTRACT_DETAILS_DIALOG = "HIDE_ADD_CONTRACT_DETAILS_DIALOG";

export const SHOW_ADD_DOCUMENT_DETAILS_DIALOG = "SHOW_ADD_DOCUMENT_DETAILS_DIALOG";
export const HIDE_ADD_DOCUMENT_DETAILS_DIALOG = "HIDE_ADD_DOCUMENT_DETAILS_DIALOG";


export function showAddContractDetailsDialog(payload) {
  return {
      type: SHOW_ADD_CONTRACT_DETAILS_DIALOG,
      payload: payload
  };
}

export function hideAddContractDetailsDialog() {
  return {
      type: HIDE_ADD_CONTRACT_DETAILS_DIALOG,
      payload: {}
  };
}

export function showAddDocumentDetailsDialog(payload) {
  return {
      type: SHOW_ADD_DOCUMENT_DETAILS_DIALOG,
      payload: payload
  };
}

export function hideAddDocumentDetailsDialog() {
  return {
      type: HIDE_ADD_DOCUMENT_DETAILS_DIALOG,
      payload: {}
  };
}

function startFetchDocTypeFiles(){
  return {
      type:START_FETCH_DOCUMENT_TYPE_FILES,
      payload:{}
  };
}

function endFetchDocTypeFiles(slug,success,error){
  return {
      type:END_FETCH_DOCUMENT_TYPE_FILES,
      payload:{
          slug,success,error
      }
  };
}

export function fetchDocTypeFiles(id,type,slug){
  const api_server = Environment.api_host("CATALOG");
  const timestamp = (new Date()).getTime();
  const url = `${api_server}/document?entity_id=${id}&entity_type=${type}&item_slug=${slug}&=${timestamp}`;
  return async (dispatch) => {
      dispatch(startFetchDocTypeFiles());
      try{
          let [response,error]    =   await HttpFactory.instance().getMethod(url,true);
          dispatch(endFetchDocTypeFiles(slug,response,error));
      } catch(e){
          dispatch(endFetchDocTypeFiles(slug,null,{
              message:e.message
          }));
          return;
      }
  };
}

function startAddDocTypeFile(){
  return {
      type:START_ADD_DOCUMENT_TYPE_FILES,
      payload:{}
  };
}

function endAddDocTypeFile(success,error){
  return {
      type:END_ADD_DOCUMENT_TYPE_FILES,
      payload:{
          success,error
      }
  };
}

export function addDocTypeFile(data){
  const api_server = Environment.api_host("CATALOG");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/document?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startAddDocTypeFile());
      try{
          let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
          dispatch(endAddDocTypeFile(response,error));
      } catch(e){
          dispatch(endAddDocTypeFile(null,{
              message:e.message
          }));
          return;
      }
  };
}

function startUpdateDocTypeFile(){
  return {
      type:START_UPDATE_DOCUMENT_TYPE_FILES,
      payload:{}
  };
}

function endUpdateDocTypeFile(success,error){
  return {
      type:END_UPDATE_DOCUMENT_TYPE_FILES,
      payload:{
          success,error
      }
  };
}

export function updateDocTypeFile(id,data){
  const api_server = Environment.api_host("CATALOG");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/document/${id}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startUpdateDocTypeFile());
      try{
          let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
          dispatch(endUpdateDocTypeFile(response,error));
      } catch(e){
          dispatch(endUpdateDocTypeFile(null,{
              message:e.message
          }));
          return;
      }
  };
}

function startDeleteDocTypeFile(){
  return {
      type:START_DELETE_DOCUMENT_TYPE_FILES,
      payload:{}
  };
}

function endDeleteDocTypeFile(success,error){
  return {
      type:END_DELETE_DOCUMENT_TYPE_FILES,
      payload:{
          success,error
      }
  };
}

export function deleteDocTypeFile(id,data){
  const api_server = Environment.api_host("CATALOG");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/document/${id}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startDeleteDocTypeFile());
      try{
          let [response,error]    =   await HttpFactory.instance().deleteMethod(url,data);
          dispatch(endDeleteDocTypeFile(response,error));
      } catch(e){
          dispatch(endDeleteDocTypeFile(null,{
              message:e.message
          }));
          return;
      }
  };
}

function startFetchDocTypes(){
    return {
        type:START_FETCH_DOCUMENT_V2_TYPE,
        payload:{}
    };
}

function endFetchDocTypes(success,error){
    return {
        type:END_FETCH_DOCUMENT_V2_TYPE,
        payload:{
            success,error
        }
    };
}

export function fetchDocTypes(){
    const api_server = Environment.api_host("CATALOG");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/document/type?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchDocTypes());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endFetchDocTypes(response,error));
        } catch(e){
            dispatch(endFetchDocTypes(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startAddDocType(){
    return {
        type:START_ADD_DOCUMENT_V2_TYPE,
        payload:{}
    };
}

function endAddDocType(success,error){
    return {
        type:END_ADD_DOCUMENT_V2_TYPE,
        payload:{
            success,error
        }
    };
}

export function addDocType(data){
    const api_server = Environment.api_host("CATALOG");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/document/type?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddDocType());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endAddDocType(response,error));
        } catch(e){
            dispatch(endAddDocType(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startAddDocTypeColloborators(){
    return {
        type:START_ADD_DOCUMENT_TYPE_COLLABORATORS,
        payload:{}
    };
}

function endAddDocTypeColloborators(success,error){
    return {
        type:END_ADD_DOCUMENT_TYPE_COLLABORATORS,
        payload:{
            success,error
        }
    };
}

export function addDocTypeColloborators(evaluationID,stepId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline/${stepId}/collaborators?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddDocTypeColloborators());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endAddDocTypeColloborators(response,error));
        } catch(e){
            dispatch(endAddDocTypeColloborators(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startUpdateDocType(){
    return {
        type:START_UPDATE_DOCUMENT_V2_TYPE,
        payload:{}
    };
}

function endUpdateDocType(success,error){
    return {
        type:END_UPDATE_DOCUMENT_V2_TYPE,
        payload:{
            success,error
        }
    };
}

export function updateDocType(id,data){
    const api_server = Environment.api_host("CATALOG");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/document/type/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateDocType());
        try{
            let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
            dispatch(endUpdateDocType(response,error));
        } catch(e){
            dispatch(endUpdateDocType(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startDeleteDocType(){
    return {
        type:START_DELETE_DOCUMENT_V2_TYPE,
        payload:{}
    };
}

function endDeleteDocType(success,error){
    return {
        type:END_DELETE_DOCUMENT_V2_TYPE,
        payload:{
            success,error
        }
    };
}

export function deleteDocType(id,data){
    const api_server = Environment.api_host("CATALOG");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/document/type/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteDocType());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url,data);
            dispatch(endDeleteDocType(response,error));
        } catch(e){
            dispatch(endDeleteDocType(null,{
                message:e.message
            }));
            return;
        }
    };
}

  function startFetchDocTypeTabs() {
    return {
      type: START_FETCH_DOCUMENT_TYPE_TABS,
      payload: null,
    };
  }
  
  function endFetchDocTypeTabs(success, error) {
    return {
      type: END_FETCH_DOCUMENT_TYPE_TABS,
      payload: {
        success,
        error,
      },
    };
  }
  
  export function fetchDocTypesTabs(id) {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    const url = `${api_server}/tab/${id}?t=${timestamp}`;
    return async (dispatch) => {
      dispatch(startFetchDocTypeTabs());
      try {
        let [response, error] = await HttpFactory.instance().getMethod(url);
        dispatch(endFetchDocTypeTabs(response, error));
      } catch (e) {
        dispatch(
          endFetchDocTypeTabs(null, {
            message: e.message,
          })
        );
        return;
      }
    };
  }
  
  function startAddDocTypeSection() {
    return {
      type: START_ADD_DOCUMENT_TYPE_SECTION,
      payload: null,
    };
  }
  
  function endAddDocTypeSection(tabId, success, error) {
    return {
      type: END_ADD_DOCUMENT_TYPE_SECTION,
      payload: {
        tabId,
        success,
        error,
      },
    };
  }
  
  export function addDocTypeSection(tabId, data) {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    const url = `${api_server}/tab/${tabId}/section?t=${timestamp}`;
    return async (dispatch) => {
      dispatch(startAddDocTypeSection());
      try {
        let [response, error] = await HttpFactory.instance().postMethod(url, data);
        dispatch(endAddDocTypeSection(tabId, response, error));
      } catch (e) {
        dispatch(
          endAddDocTypeSection(null, null, {
            message: e.message,
          })
        );
        return;
      }
    };
  }

  export function updateDocTypeSequence(tabId, data, type) {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    const url = `${api_server}/tab/${tabId}?t=${timestamp}`;
    return async (dispatch) => {
      try {
        await HttpFactory.instance().putMethod(url, data);
        dispatch(fetchDocTypesTabs(type));
      } catch (e) {
        return;
      }
    };
  }
  
  function startUpdateDocTypeSection() {
    return {
      type: START_UPDATE_DOCUMENT_TYPE_SECTION,
      payload: null,
    };
  }
  
  function endUpdateDocTypeSection(tabId, sectionId, success, error) {
    return {
      type: END_UPDATE_DOCUMENT_TYPE_SECTION,
      payload: {
        tabId,
        sectionId,
        success,
        error,
      },
    };
  }
  
  export function updateDocTypeSection(tabId, sectionId, data) {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    const url = `${api_server}/tab/${tabId}/section/${sectionId}?t=${timestamp}`;
    return async (dispatch) => {
      dispatch(startUpdateDocTypeSection());
      try {
        let [response, error] = await HttpFactory.instance().putMethod(url, data);
        dispatch(endUpdateDocTypeSection(tabId, sectionId, response, error));
      } catch (e) {
        dispatch(
          endUpdateDocTypeSection(tabId, sectionId, null, {
            message: e.message,
          })
        );
        return;
      }
    };
  }
  
  export function updateDocTypeSectionSequence(tabId, sectionId, data, type) {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    const url = `${api_server}/tab/${tabId}/section/${sectionId}?t=${timestamp}`;
    return async (dispatch) => {
      try {
        await HttpFactory.instance().putMethod(url, data);
        dispatch(fetchDocTypesTabs(type));
      } catch (e) {
        return;
      }
    };
  }
  
  function startDeleteDocTypeSection() {
    return {
      type: START_DELETE_DOCUMENT_TYPE_SECTION,
      payload: null,
    };
  }
  
  function endDeleteDocTypeSection(tabId, sectionId, success, error) {
    return {
      type: END_DELETE_DOCUMENT_TYPE_SECTION,
      payload: {
        tabId,
        sectionId,
        success,
        error,
      },
    };
  }
  
  export function deleteDocTypeSection(tabId, sectionId) {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    const url = `${api_server}/tab/${tabId}/section/${sectionId}?t=${timestamp}`;
    return async (dispatch) => {
      dispatch(startDeleteDocTypeSection());
      try {
        let [response, error] = await HttpFactory.instance().deleteMethod(url);
        dispatch(endDeleteDocTypeSection(tabId, sectionId, response, error));
      } catch (e) {
        dispatch(
          endDeleteDocTypeSection(tabId, sectionId, null, {
            message: e.message,
          })
        );
        return;
      }
    };
  }
  
  function startAddDocTypeSectionItem() {
    return {
      type: START_ADD_DOCUMENT_TYPE_SECTION_ITEM,
      payload: null,
    };
  }
  
  function endAddDocTypeSectionItem(tabId, sectionId, success, error) {
    return {
      type: END_ADD_DOCUMENT_TYPE_SECTION_ITEM,
      payload: {
        tabId,
        sectionId,
        success,
        error,
      },
    };
  }
  
  export function addDocTypeSectionItem(tabId, sectionId, data) {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    const url = `${api_server}/tab/${tabId}/section/${sectionId}/item?t=${timestamp}`;
    return async (dispatch) => {
      dispatch(startAddDocTypeSectionItem());
      try {
        let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
        dispatch(endAddDocTypeSectionItem(tabId, sectionId, response, error));
      } catch (e) {
        dispatch(
          endAddDocTypeSectionItem(null, null, {
            message: e.message,
          })
        );
        return;
      }
    };
  }
  
  function startUpdateDocTypeSectionItem() {
    return {
      type: START_UPDATE_DOCUMENT_TYPE_SECTION_ITEM,
      payload: null,
    };
  }
  
  function endUpdateDocTypeSectionItem(tabId, sectionId, itemId, success, error) {
    return {
      type: END_UPDATE_DOCUMENT_TYPE_SECTION_ITEM,
      payload: {
        tabId,
        sectionId,
        itemId,
        success,
        error,
      },
    };
  }
  
  export function updateDocTypeSectionItem(tabId, sectionId, itemId, data) {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    const url = `${api_server}/tab/${tabId}/section/${sectionId}/item/${itemId}?t=${timestamp}`;
    return async (dispatch) => {
      dispatch(startUpdateDocTypeSectionItem());
      try {
        let [response, error] = await HttpFactory.instance().putMethod(url, data);
        dispatch(endUpdateDocTypeSectionItem(tabId, sectionId, itemId, response, error));
      } catch (e) {
        dispatch(
          endUpdateDocTypeSectionItem(tabId, sectionId, itemId, sectionId, null, {
            message: e.message,
          })
        );
        return;
      }
    };
  }
  
  function startDeleteDocTypeSectionItem() {
    return {
      type: START_DELETE_DOCUMENT_TYPE_SECTION_ITEM,
      payload: null,
    };
  }
  
  function endDeleteDocTypeSectionItem(tabId, sectionId, itemId, success, error) {
    return {
      type: END_DELETE_DOCUMENT_TYPE_SECTION_ITEM,
      payload: {
        tabId,
        sectionId,
        itemId,
        success,
        error,
      },
    };
  }
  
  export function deleteDocTypeSectionItem(tabId, sectionId, itemId) {
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    const url = `${api_server}/tab/${tabId}/section/${sectionId}/item/${itemId}?t=${timestamp}`;
  
    return async (dispatch) => {
      dispatch(startDeleteDocTypeSectionItem());
      try {
        let [response, error] = await HttpFactory.instance().deleteMethod(url);
        dispatch(endDeleteDocTypeSectionItem(tabId, sectionId, itemId, response, error));
      } catch (e) {
        dispatch(
          endDeleteDocTypeSectionItem(tabId, sectionId, itemId, null, {
            message: e.message,
          })
        );
        return;
      }
    };
  }
  