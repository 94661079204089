import {
    START_OKTA_CONFIG_FETCH,
    END_OKTA_CONFIG_FETCH,
    START_OKTA_USER_FETCH,
    END_OKTA_USER_FETCH,
    OKTA_ADD_TEAM,
    OKTA_UPDATE_TEAM,
    OKTA_TEAM_CHECK_ALL,
    SHOW_ADD_TEAM_DIALOG,
    HIDE_ADD_TEAM_DIALOG,
    SHOW_USER_GROUP_DIALOG,
    HIDE_USER_GROUP_DIALOG,
    START_ADD_TEAM_MEMBERS,
    END_ADD_TEAM_MEMBERS
} from "./action";

const initState   =     {
    configFetchProgress:false,
    configFetchError:null,
    config:null,
    fetchOktaUserProg:false,
    totalOktaUser:0,
    oktaUsers:[],
    team:[],
    addTeamDialogOpen:false,
    addUserGroupDialog: false,
    addUserGroupDialogId: 0,
    addTeamMemberProgress:false,
    addTeamMemberError:null
}

export  function oktaReducer(state=initState,action){
    switch(action.type){
        case START_OKTA_CONFIG_FETCH:
            return {
                ...state,
                configFetchProgress:true,
                configFetchError:null,
                config:null,
            };
        case END_OKTA_CONFIG_FETCH:
            return {
                ...state,
                configFetchProgress:false,
                configFetchError:action.payload.error,
                config:action.payload.success
            }
        case START_OKTA_USER_FETCH:
            var oktaUsers = state.oktaUsers;
            if(action.payload === 0){
                oktaUsers = []
            }
            return {
                ...state,
                fetchOktaUserProg:true,
                oktaUsers:oktaUsers,
            }
        case END_OKTA_USER_FETCH:
            var success     =   action.payload.success;
            var oktaUsers     =   state.oktaUsers;
            var totalOktaUser = state.totalOktaUser;
            if(success !== null){
                var Page    =   success.page;
                var Data    =   success.Data;
                totalOktaUser = success?.total;
                if(Page === 0){
                    oktaUsers     =  Data; 
                } else {
                    oktaUsers     = oktaUsers.concat(Data)
                }
            }
            return {
                ...state,
                fetchOktaUserProg:false,
                oktaUsers:oktaUsers,
                totalOktaUser:totalOktaUser
            }
        case OKTA_ADD_TEAM:
            var currentTeam     =   state.team;
            var user    =   action.payload;
            var exitingUser     =   currentTeam.filter((item)=>(item.email === user.email));
            if(exitingUser.length !== 0){
                return state;
            }
            
            return {
                ...state,
                team:currentTeam.concat([user])
            }
        case OKTA_UPDATE_TEAM:
            var currentTeam     =   state.team;
            var user    =   action.payload;
            var team    =       [];
            currentTeam.forEach((i)=>{
                if(i.email === user.email){
                    team.push(user);
                } else {
                    team.push(i);
                }
            });
            return {
                ...state,
                team:team
            }
        case OKTA_TEAM_CHECK_ALL:
            var currentTeam     =   state.team;
            var status    =   action.payload;
            var team    =       [];
            currentTeam.forEach((i)=>{
                i.checked = status;
                team.push(i);
            });
            return {
                ...state,
                team:team
            }
        case SHOW_ADD_TEAM_DIALOG:
            return {
                ... state,
                addTeamDialogOpen:true
            }
        case HIDE_ADD_TEAM_DIALOG:
            return {
                ... state,
                addTeamDialogOpen:false
            }

        case SHOW_USER_GROUP_DIALOG:
            let data = action.payload?.data
            return {
                ... state,
                addUserGroupDialog:true,
                addUserGroupDialogData: data
            }
        case HIDE_USER_GROUP_DIALOG:
            return {
                ... state,
                addUserGroupDialog:false,
                addUserGroupDialogData: null
            }

        case START_ADD_TEAM_MEMBERS:
            return {
                ...state,
                addTeamMemberProgress:true,
                addTeamMemberError:null
            }

        case END_ADD_TEAM_MEMBERS:
            return {
                ...state,
                addTeamMemberProgress:false,
                addTeamMemberError:action.payload.error
            }
        default:
            return state;
    }
}