import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import * as classnames from "classnames";
import { setSelectedRequirement } from "redux/seller/action";
import AnsweredIcon from 'assets/images/evaluation/reqAnswered.svg';
import AnsweredIconWhite from 'assets/images/evaluation/reqAnsweredWhite.svg';
import MandatoryIcon from 'assets/images/evaluation/reqMandatory.svg';
// import DraftIcon from 'assets/images/evaluation/reqDraft.svg';
import DraftIcon from "assets/images/edit_icon.svg";

import Image from 'Components/Common/image.jsx';

const connectedProps = (state) => ({
  selectedRequirementId: state.seller.selectedRequirementId,
  evaluation: state.seller.evaluation,
  requirementMap: state.seller.requirementMap,
  requirementTree: state.seller.requirementTree,
  threadFetchProgress: state.seller.threadFetchProgress,
});

const connectionActions = {
  setSelectedRequirement: setSelectedRequirement,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  mainRootItem: {
    border: '1px solid #222',
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '4px'
  },
  subChild: {
    paddingLeft: '20px'
  },
  parentItem: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: 0
  },
  rootItem: {
    position: 'relative',
    padding: '10px',
    '& :before': {
      position: 'absolute',
      left: '15px',
      top: '40px',
      display: 'block',
      width: '3px',
      bottom: '30px',
      borderRight: '1px solid hsl(206deg 5% 74%)',
      content: '',
    }
  },
  childItem: {
    position: 'relative',
    margin: 0,
    padding: '2px',
    cursor: 'pointer',
    '&:after': {
      position: 'absolute',
      left: '-22px',
      top: '14px',
      display: 'block',
      width: '21px',
      height: '1px',
      /* bottom: 25px, */
      background: 'hsl(206deg 5% 74%)',
      content: '',
    },
    '&:focus': {
      outline: 'none'
    }
  },
  selectedItem: {
    backgroundColor: '#398AF5',
    border: '1px solid #222',
    color: '#fff',
    padding: '2px',
    borderRadius: '4px',
    '&:focus': {
      outline: 'none'
    }
  },
  rootItemNoBefore: {
    padding: '10px'
  },
  redLegend: {
    color: 'red',
    marginLeft: '10px',
    position: 'relative',
    top: '2px',
  },
  greenLegend: {
    color: '#2EB77D',
    marginLeft: '10px',
    position: 'relative',
    top: '4px',
  },
  whiteLegend: {
    color: '#fff',
    marginLeft: '10px',
    position: 'relative',
    top: '4px',
  },
  orangeLegend: {
    color: 'orange',
    marginLeft: '10px',
    position: 'relative',
    top: '4px',
  },
});

class TreeNode extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.keyRef = React.createRef();

    this.state = {
      cursor: 0,
      result: [],
    }
    this.setSelectionNew = this.setSelectionNew.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.moveFocus();
  }

  componentDidUpdate(preProps) {
    if (this.props.selectedRequirementId !== preProps.selectedRequirementId) {
      this.handleScrollToElement();
    }
  }


  moveFocus() {
    const node = this.keyRef?.current;
    node.addEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(e) {
    const active = document.activeElement;

    var allRequirements = (this.props.requirementTree !== undefined && this.props.requirementTree !== null) ? [...this.props.requirementTree] : [];
    var requirementIds = [];
    var allItems = [];
    if (allRequirements !== null && allRequirements.length > 0) {
      requirementIds = getAllItems(this.props.requirementTree, allItems);
    }

    function getAllItems(datas, allItems) {
      datas.forEach(function (item) {
        if (item.Type !== 1) {
          allItems.push(item.ID);
        }
        if (item.Childrens != null) {
          getAllItems(item.Childrens, allItems);
        }
      });
      return allItems;
    }

    var currentReq = this.props.selectedRequirementId;

    var indexOfCurrent = requirementIds.indexOf(currentReq);
    var indexOfNext = null;
    var indexOfPrevious = null;
    var nextReqId = null;
    var previousReqId = null;

    if (indexOfCurrent !== (requirementIds.length - 1)) {
      indexOfNext = indexOfCurrent + 1;
      nextReqId = requirementIds[indexOfNext];
    }

    if (indexOfCurrent !== 0) {
      indexOfPrevious = indexOfCurrent - 1;
      previousReqId = requirementIds[indexOfPrevious];
    }

    if (e.keyCode === 40 && active.nextSibling && nextReqId !== null && this.props.threadFetchProgress === false) {
      active.nextSibling.focus();
      this.setSelectionNew(nextReqId);
    }

    if (e.keyCode === 38 && active.nextSibling && previousReqId !== null && this.props.threadFetchProgress === false) {
      active.nextSibling.focus();
      this.setSelectionNew(previousReqId);
    }

  }


  setSelectionNew(id) {
      this.props.setSelectedRequirement(id);
      this.handleScrollToElement();
  }




  handleScrollToElement(event) {
    if (this.myRef.current !== null) {

      var allRequirements = (this.props.requirementTree !== undefined && this.props.requirementTree !== null) ? [...this.props.requirementTree] : [];
      var requirementMap = {};
      var allItems = {};
      if (allRequirements !== null && allRequirements.length > 0) {
        requirementMap = createReqNameMap(this.props.requirementTree, allItems);
      }
  
      function createReqNameMap(datas, allItems) {
        datas.forEach(function (item) {
          if (item.Type !== 1) {
            allItems[item.Name] = item.ID;
          }
          if (item.Childrens != null) {
            createReqNameMap(item.Childrens, allItems);
          }
        });
        return allItems;
      }
      var reqId = requirementMap[this.myRef.current.textContent] !== undefined ? requirementMap[this.myRef.current.textContent] : '0';
      if (reqId === this.props.selectedRequirementId) {
        const containerRect = this.myRef.current.getBoundingClientRect();
        if (containerRect.top > (window.innerHeight - 110)) {
          this.myRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }
        if (containerRect.top < 150) {
          this.myRef.current.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }
  }

  render() {

    const { evaluation } = this.props;
    var criterias = evaluation.criterias !== undefined ? evaluation.criterias : [];
    var statusMap = {};
    criterias.forEach(function (criteria) {
      criteria.requirements.forEach(function (req) {
        statusMap[req.id] = {
          IsMandatory: req.is_mandatory,
          IsDraft: false,
          IsAnswered: false,
        };
        if (req.response_status === 'DRAFT') {
          statusMap[req.id].IsDraft = true;
        } else if (req.response_status === 'DONE') {
          statusMap[req.id].IsAnswered = true;
        }
      })
    });

    const { selectedItem, itemData, cls, classes, ...rest } = this.props;
    var childList = [];
    var selectedItem2 = (this.props.selectedItem !== undefined && this.props.selectedItem !== null) ? this.props.selectedItem : '';
    var selectedClass = '';
    if ((this.props.itemData !== undefined && this.props.itemData !== null && this.props.itemData.Childrens !== undefined && this.props.itemData.Childrens !== null && this.props.itemData.Childrens.length > 0)) {
      this.props.itemData.Childrens.forEach(function (item, key) {
        if (selectedItem2 === item.ID) {
          selectedClass = classes.selectedItem;
        } else {
          selectedClass = '';
        }
        childList.push(<TreeNode
          {...rest}
          key={item.ID}
          itemData={item}
          selectedItem={selectedItem2}
          cls={classnames(classes.childItem, selectedClass)}
          classes={classes}
        ></TreeNode>);
      });
    }

    return (
      <div ref={this.myRef} className={classnames((this.props.itemData.Childrens !== undefined && this.props.itemData.Childrens !== null && this.props.itemData.Childrens.length > 0) ? classes.rootItem : classes.rootItemNoBefore)}>
        <p ref={this.keyRef} tabindex={this.props.itemData.ID} className={this.props.cls} onClick={() => {
          if (this.props.itemData.Type === 1) {
            return;
          }
          this.props.setSelectedRequirement(this.props.itemData.ID)
        }}>
          {this.props.itemData.Name}

          {/* {this.props.itemData.Type !== 1 && this.props.itemData.IsAnswered === true && <span className={selectedItem2 === this.props.itemData.ID ? classes.whiteLegend : classes.greenLegend}><Image alt="Winer" src={AnsweredIcon} /> </span>}
          {this.props.itemData.Type !== 1 && this.props.itemData.IsAnswered === false && this.props.itemData.IsMandatory === true && <span className={classes.redLegend}><Image alt="Winer" src={MandatoryIcon} /> </span>}
        */}


          {/* {statusMap[this.props.itemData.ID] !== undefined && statusMap[this.props.itemData.ID]?.IsAnswered === true && <span className={selectedItem2 === this.props.itemData.ID ? classes.whiteLegend : classes.greenLegend}><Image title={'Answered'} alt="Answer Icon" src={AnsweredIcon } /> </span>} */}
          {statusMap[this.props.itemData.ID] !== undefined && statusMap[this.props.itemData.ID]?.IsAnswered === true && <span className={selectedItem2 === this.props.itemData.ID ? classes.whiteLegend : classes.greenLegend}><Image title={'Answered'} alt="Answer Icon" src={selectedItem2 === this.props.itemData.ID ? AnsweredIconWhite : AnsweredIcon} /> </span>}
          {statusMap[this.props.itemData.ID] !== undefined && statusMap[this.props.itemData.ID]?.IsDraft === true && <span className={selectedItem2 === this.props.itemData.ID ? classes.whiteLegend : classes.orangeLegend}><Image title={'Draft'} alt="Draft Icon" src={DraftIcon} /> </span>}
          {statusMap[this.props.itemData.ID] !== undefined && statusMap[this.props.itemData.ID]?.IsAnswered === false && statusMap[this.props.itemData.id]?.IsDraft === false && statusMap[this.props.itemData.id].IsMandatory === true && <span className={classes.redLegend}><Image title={'Mandatory'} alt="Mandatory Icon" src={MandatoryIcon} /> </span>}

        </p>
        <div className={classes.subChild}>
          {childList}
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(TreeNode));

// export default TreeNode;
