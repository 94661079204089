import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { Typography, Button, IconButton, Popover, Divider } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import { showApplicationFetchInput, fetchAppConfig, removeApplication } from "../../../redux/applications/action";
import classnames from "classnames"
import CheckMarkImg from "../../../assets/images/checkmark.svg";
import SpinnerImg from "../../../assets/images/spinner.gif";
import LinesEllipsis from 'react-lines-ellipsis'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Image from 'Components/Common/image.jsx'

import {
    showMessageDialog,
    hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";

const connectedProps = (state) => ({

});

const connectionActions = {
    showApplicationFetchInput: showApplicationFetchInput,
    fetchAppConfig: fetchAppConfig,
    removeApplication: removeApplication,
    showMessageDialog: showMessageDialog,
    hideMessageDialog: hideMessageDialog,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    'root': {
        textAlign: "center",
        borderRadius: theme.spacing(0.55),
        border: "solid 1px #CFD7DF",
        textAlign: "center",
        padding: theme.spacing(3),
        position: "relative",

        "&.SettingsIntegrations": {
            background: "#FFFFFF",
            border: "1px solid #CFD7DF",
            borderRadius: "4px",

            "& $activeCheckmark": {
                display: "none"
            }
        }
    },
    "app_image": {
        height: "40px",
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1.9),
    },
    "action": {
        marginTop: theme.spacing(3),
        display: "flex",
        textAlign: "center",
        alignContent: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    "label": {
        color: "#2C5CC5",
        fontSize: theme.spacing(2)
    },
    "labelIcon": {
        color: "#2C5CC5",
        width: '20px',
        marginLeft: '4px',
        position: 'relative',
        top: '-1px'
    },
    "appDetails": {
        cursor: "pointer"
    },
    "active": {
        border: "solid 1px #2EB77D",
    },
    "checkMark": {
        position: "absolute",
        right: "10px",
        top: "10px",
        width: "20px",
        display: "none"
    },
    "activeCheckmark": {
        display: "block"
    },
    "progressMark": {
        position: "absolute",
        right: "10px",
        top: "10px",
        width: "20px",
    },

    "description": {
        color: "#475867",
        marginTop: theme.spacing(2),
        fontSize: theme.spacing(2.2),
    },

    "category": {
        color: "#aaacb4",
        fontSize: theme.spacing(2),
    },

    "appname": {
        fontSize: theme.spacing(2.4),
    },

    "popoverBtn": {
        width: "100%",
        padding: theme.spacing(0.5, 8, 0.5, 2),
        minHeight: "30px",
        lineHeight: "13px",

        "& > span": {
            textAlign: "left",
            display: "block"
        }
    },

    "threeDot": {
        position: "absolute",
        zIndex: 1,
        right: 0,
        top: 0
    },

    "popover": {
        "& [class*='paper']": {
            boxShadow: "0px 8px 12px #0000001D",
            border: "1px solid #EEEEEE",
            borderRadius: "5px",
            overflow: "inherit",

            "&::before": {
                top: "10px",
                left: "-16px",
                width: "0",
                borderTop: "8px solid transparent",
                height: "0px",
                content: "''",
                display: "block",
                position: "absolute",
                borderBottom: "8px solid transparent",
                borderLeft: "8px solid transparent",
                borderRight: "8px solid #fff"
            },
        },

    },

    "popoverText": {
        color: "#74798C"
    }
});

class AppCard extends React.Component {

    constructor(props) {
        super(props);
        this.btnRef = React.createRef();
        this.state = {
            anchorEl: null,
            deleteApp: null
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.callBack = this.callBack.bind(this);
        this.handleDeleteApp = this.handleDeleteApp.bind(this);
    }

    componentDidMount() {
        if (!this.props.isFromSetting) {
            this.props.fetchAppConfig(this.props.app);
        }
    }


    handleClick(event) {
        this.setState({ anchorEl: event.currentTarget || event.current });
    };

    handleClose() {
        this.setState({ anchorEl: null });
    };

    handleDeleteApp(app) {
        var me = this;
        this.setState({ deleteApp: app }, () => {
            me.props.showMessageDialog();
        })
    }

    callBack() {
        if (this.state.deleteApp) {
            this.props.hideMessageDialog();
            this.handleClose();
            this.props.removeApplication(this.state.deleteApp)
        }
    }



    render() {
        const classes = this.props.classes;
        const app = this.props.app;
        var isFetching = app.isFetching === undefined ? false : app.isFetching;

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'popover' : undefined;

        let messageData = {
            head: "Are you sure?",
            subHead: "Do you really want to delete? This process cannot be undone.",
            firstBtn: "Cancel",
            secondBtn: "Yes",
          };

        return <div className={classnames(this.props.root, classes.root, { [classes.active]: (app.currentConfig !== undefined && app.currentConfig !== null) })}>
            <Popover
                className={classes.popover}
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={() => this.handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}>
                <Button className={classes.popoverBtn} onClick={() => {
                    this.handleClose();
                    // this.props.showApplicationFetchInput(app)
                    this.props.history.push(`/app/settings/integrations/${app?.ID}`);
                }}> <p className={classes.popoverText} >View</p></Button>
                <Divider />
                <Button className={classes.popoverBtn} onClick={() => {
                    this.handleDeleteApp(app)
                }}> <p className={classes.popoverText}>Remove</p></Button>
            </Popover>

            {/* setting page */}
            {app.currentConfig !== undefined && app.currentConfig !== null && this.props.isFromSetting && <IconButton ref={this.btnRef} className={classes.threeDot} onClick={this.handleClick}><MoreVertIcon /></IconButton>}

            <div className={classes.appDetails} onClick={() => {
                if (!app.isComingSoon) {
                    app.currentConfig !== undefined && app.currentConfig !== null && this.props.isFromSetting ? this.handleClick(this.btnRef) : this.props.showApplicationFetchInput(app)
                }
            }}>
                <Image src={app.AppLogo} alt="app_name" className={classes.app_image} />
                <Image src={CheckMarkImg} alt="checkmark" className={classnames(classes.checkMark, { [classes.activeCheckmark]: (app.currentConfig !== undefined && app.currentConfig !== null) })} />
                {isFetching && <Image src={SpinnerImg} alt="spinner" className={classnames(classes.progressMark)} />}
                <Typography variant={"h6"} className={classes.appname}>{app.AppName}</Typography>
                <Typography variant={"subtitle1"} className={classes.category}>{app.AppCategory}</Typography>
                <div className={classes.description}>
                    <LinesEllipsis
                        text={app.Description}
                        maxLine='3'
                        ellipsis='...'
                        basedOn='letters'
                    />
                </div>
            </div>
            {!this.props.isFromSetting &&
                <a href={app.WebLink} className={classes.action} target="_blank">
                    <Typography className={classes.label}>
                        Add to Expent
                    </Typography><ArrowForwardIcon className={classes.labelIcon} />
                </a>}

            {(app.currentConfig === undefined || app.currentConfig == null) && this.props.isFromSetting &&
                <a href={app.WebLink} className={classes.action} target="_blank">
                    <Typography className={classes.label}>
                        {app.isComingSoon ? 'Learn More' : 'Add to Expent'}
                    </Typography><ArrowForwardIcon className={classes.labelIcon} />
                </a>}
            <MessageDialog messageData={messageData} callBack={this.callBack} />
        </div>

    }
}

AppCard.defaultProps = {
    isFromSetting: false,
    root: '',
};

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AppCard));