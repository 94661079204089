import {
  START_ADD_NEW_NOTE, END_ADD_NEW_NOTE,
  START_UPDATE_NOTE, END_UPDATE_NOTE,
  START_FETCH_NOTES, END_FETCH_NOTES,
  START_DELETE_NOTE, END_DELETE_NOTE,
} from "./action";


// Initial State
const initState = {
  addNewNoteProgress: {},
  addNewNoteError: null,
  newNoteData: null,

  updateNoteProgress: {},
  updateNoteError: null,
  updatedNoteData: null,

  fetchDataProgress: {},
  fetchDataError: null,
  noteDatas: {},

  deleteNoteProgress: {},
  deleteNoteError: null,
}

export function cataclogNoteReducers(state = initState, action) {
  switch (action.type) {
    case START_ADD_NEW_NOTE:
      return {
        ...state,
        addNewNoteProgress: {...state.addNewNoteProgress, [action.payload.slug]: true},
        addNewNoteError: null,
        newNoteData: null
      }

    case END_ADD_NEW_NOTE:
      return {
        ...state,
        addNewNoteProgress: {...state.addNewNoteProgress, [action.payload.slug]: false},
        addNewNoteError: action.payload.error,
        newNoteData: action.payload.success
      }

    case START_UPDATE_NOTE:
      return {
        ...state,
        updateNoteProgress: {...state.updateNoteProgress, [action.payload.slug]: true},
        updateNoteError: null,
        updatedNoteData: null,
      }

    case END_UPDATE_NOTE:
      return {
        ...state,
        updateNoteProgress: {...state.updateNoteProgress, [action.payload.slug]: false},
        updateNoteError: action.payload.error,
        updatedNoteData: action.payload.success,
      }

    case START_FETCH_NOTES:
      const info = action.payload.data;
      return {
        ...state,
        fetchDataProgress: {...state.fetchDataProgress, [info.item_slug]: true},
        fetchDataError: null,
      }

    case END_FETCH_NOTES:
      let successFetchData = action.payload.success;
      const infoData = action.payload.data;
      let tableDataItems = state.noteDatas;
      tableDataItems[infoData.item_slug] = successFetchData;
      return {
        ...state,
        fetchDataError: action.payload.error,
        noteDatas: tableDataItems,
        fetchDataProgress: {...state.fetchDataProgress, [infoData.item_slug]: false},
      }

    case START_DELETE_NOTE:
      return {
        ...state,
        deleteNoteProgress: {...state.deleteNoteProgress, [action.payload.slug]: true},
        deleteNoteError: null,
      }

    case END_DELETE_NOTE:
      return {
        ...state,
        deleteNoteProgress: {...state.deleteNoteProgress, [action.payload.slug]: false},
        deleteNoteError: action.payload.error,
      }
    default:
      return state;
  }

}