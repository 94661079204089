import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
    TableContainer, Button, Table, TableBody, TableCell, TableHead, TableRow,
    CircularProgress, IconButton, Paper, MenuItem, FormControl,
    Select, Tooltip, Popover, Typography, List, ListItem, ListItemSecondaryAction, Switch, Divider
} from '@material-ui/core';

// import * as Validator from "util/Validation";

import DragIndicatorOutlinedIcon from "@material-ui/icons/DragIndicatorOutlined";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { CommonFn } from "services/commonFn";
// import { renewalTableColumns,getRenewalColumns } from "util/Columns"
import { preciseDiffMoments } from "util/Common";
import LinkIcon from '@material-ui/icons/Link';
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import SqureButton from "Components/Common/SqureButton";
import { fetchOrgCatalogDetailsTabs } from "redux/vendor/vendorCatalog/action";
import { renewalValidColumnTypes } from "util/AppCatalogTypeOptions"
import { currencyCodeMap } from "util/Currency";
import { addOrgSettings } from "redux/org/user/action";

const renewalTableColumns = [
    { id: 'contractName', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Contract' },
    { id: 'renewalDate', type: 2, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Renewal Date' },
    { id: 'renewalInDays', type: 'Number', align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Renewal In Days' },
    { id: 'ownerName', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Contract Owner' },
    { id: 'requestStatus', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Request Status' },
    { id: 'currentStep', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Current Step' },
    { id: 'projectOwnerName', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Project Owner' },
    { id: 'status', type: 100, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Status' },
    // { id: 'entity', type: 1, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Entity' },
]



const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const connectedProps = (state) => ({
    orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,
    orgCatalogDetailTabsProgress: state.vendorCatalog.orgCatalogDetailTabsProgress,
    orgUser: state.orgUser,
});

const connectionActions = {
    fetchOrgCatalogDetailsTabs: fetchOrgCatalogDetailsTabs,
    addOrgSettings:addOrgSettings
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    topHeader: {
        display: "flex",
    },
    showBtnSection: {
        flex: 1,
        textAlign: "right",
        paddingTop: 3,
        paddingRight: 10,
    },
    columnDrawer: {
        width: 500,
        right: "0",
        padding: "15px",
        zIndex: "9",
    },
    bottomActions: {
        textAlign: "right",
    },
});

class ContractList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renewalColumns: [],
            renewals: [],
            isShowSubMenu: false,
            subMenuEl: null,
            colVisibilityData: null,
            columnInitialized: false,
            columnsMap: {},
            isDataLoaded: false,
            configSuccessMsg: '',
        }
        this.showColumnVisibityPoper = this.showColumnVisibityPoper.bind(this);
        this.updateColVisibilityStatus = this.updateColVisibilityStatus.bind(this);
    }

    componentDidMount() {
        this.props.fetchOrgCatalogDetailsTabs("vendor");
    }

    componentDidUpdate(prevProps) {
        if (!this.props.orgCatalogDetailTabsProgress && prevProps.orgCatalogDetailTabsProgress) {
            this.initializeColumns();
        }

        if (this.props.contractData && this.props.contractData.length !== prevProps.contractData.length) {
            this.setState({
                isDataLoaded: false
            }, () => {
                this.initializeData()
            })
        }

        if (this.props.fetchProgress === false && prevProps.fetchProgress) {
            this.setState({
                isDataLoaded: false
            }, () => {
                this.initializeData()
            })
        }

        if (this.props.orgUser.addOrgSettingsInprogress === false && prevProps.orgUser.addOrgSettingsInprogress === true) {
            if (this.props.orgUser.addOrgSettingsFailure == null) {
                // this.props.showSnackBar(this.state.configSuccessMsg, "success", 3000)
                console.log('%c Columns Updated Successfully','color:green')
            }
        }
    }

    getConfigValue = (array, key, value) => {
        if (value) {
            let result = "";
            try {
                result = array.find((element) => element[key] === value)["Value"];
            } catch (e) { }
            return result.trim() || "";
        }
    };

    getRenewalColumns = (renewalColumns) => {
        let columns = [];
        // const localColumns = window.localStorage.getItem("renewalListingColumns");
        const localColumns = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "CONTRACT_TABLE_COLUMN") || '';
        if (localColumns !== undefined && localColumns !== null && localColumns !== 'null' && localColumns !== '') {
            let localStorageCols = JSON.parse(localColumns);
            if (localStorageCols.length > 0) {
                let availColumns = []
                let availColumnsMap = {}
                renewalColumns.forEach(function (col) {
                    availColumns.push(col.id)
                    availColumnsMap[col.id] = col;
                })
                let addedFromColumns = []
                localStorageCols.forEach(function (col) {
                    if (availColumns.indexOf(col.id) !== -1) {
                        addedFromColumns.push(String(col.id))
                        col.label = availColumnsMap[col.id].label
                        columns.push(col)
                    }
                })
                Object.keys(availColumnsMap).forEach(function (k) {
                    let col = availColumnsMap[k];
                    if (addedFromColumns.indexOf(k) === -1) {
                        columns.push(col)
                    }
                })
            } else {
                columns = renewalColumns;
            }
        } else {
            columns = renewalColumns;
        }
        this.updateColumnNames(columns)
        // window.localStorage.setItem("renewalListingColumns", JSON.stringify(columns));
        return columns;
    }

    initializeColumns() {
        let renewalColumns = renewalTableColumns;
        let tabs = this.props.orgCatalogDetailTabs;
        let validTypes = renewalValidColumnTypes()
        let columnsMap = {}
        tabs.forEach((tab) => {
            tab.sections.forEach((section) => {
                section.items.forEach((item) => {
                    if (validTypes.indexOf(item.type) === -1) {
                        return;
                    }
                    if (item.type === 6) {
                        const datas = item.additional_data ? JSON.parse(item.additional_data) : [];
                        if (datas && datas.length > 0) {
                            datas.forEach(function (ad) {
                                if ([1, 2, 12, 13, 14, 19].includes(ad.type)) {
                                    let arr = [section.name]
                                    if (item.name && item.name !== '') {
                                        arr.push(item.name)
                                    }
                                    if (ad.label && ad.label !== '') {
                                        arr.push(ad.label)
                                    }
                                    let name = arr.join(' -> ')
                                    let obj = {
                                        id: item.slug + ':tableColumn' + ad.id,
                                        type: item.type,
                                        align: 'left',
                                        isVisible: false,
                                        isDefault: false,
                                        isSortRequire: false,
                                        disablePadding: false,
                                        tableColumnId: ad.id,
                                        label: name,
                                    }
                                    renewalColumns.push(obj)
                                }
                            })
                        }
                    } else {
                        let arr = [section.name]
                        if (item.name && item.name !== '') {
                            arr.push(item.name)
                        }
                        let name = arr.join(' -> ')
                        let obj = {
                            id: item.slug,
                            type: item.type,
                            align: 'left',
                            isVisible: false,
                            isDefault: false,
                            isSortRequire: false,
                            disablePadding: false,
                            label: name,
                        }
                        renewalColumns.push(obj)
                    }
                    columnsMap[item.slug] = item.type;
                })
            })
        })

        let finalColumns = this.getRenewalColumns(renewalColumns);
        this.setState({
            columnInitialized: true,
            columnsMap: columnsMap
        }, () => {
            this.setColumns(finalColumns)
            this.initializeData()
        })
    }

    initializeData() {
        const { contractData } = this.props;
        const { columnsMap } = this.state;
        let renewals = []
        if (contractData) {
            renewals = contractData.map((o) => {
                let renewalInDays = preciseDiffMoments(new Date(), new Date(o.contract?.renewalDate))
                let obj = {
                    contractId: o.contract?.id,
                    contractName: o.contract?.name,
                    renewalDate: o.contract?.renewalDate,
                    status: o.contract?.status,
                    ownerName: o?.contract?.owner?.name,
                    ownerId: o?.contract?.owner?.id,
                    ownerEmail: o.contract?.owner?.email,
                    entity: o.entity,
                    requestStatus: o.project?.requestStatus || '',
                    currentStep: o.project?.currentStep || '',
                    projectId: o.project?.projectId || '',
                    projectOwnerName: o.project?.owner?.id || '',
                    projectOwnerId: o.project?.owner?.name || '',
                    projectOwnerEmail: o.project?.owner?.email || '',
                    renewalInDays: renewalInDays,
                    product: o.product,
                    project: o.project,
                    vendor: o.vendor,
                    contract: o.contract,
                }
                obj['name'] = o?.vendor?.name;
                obj['contractName'] = o?.vendor?.name + ' - ' + o.contract?.name;
                obj['product_id'] = o?.vendor?.id;
                obj['product_logo'] = o?.vendor?.logo;
                if (o.vendor && o.vendor.items) {
                    Object.keys(o.vendor.items).forEach(function (itm) {
                        if (columnsMap[itm] && (columnsMap[itm] === 4 || columnsMap[itm] === 5 || columnsMap[itm] === 7 || columnsMap[itm] === 8)) {
                            let str = o.vendor.items[itm] ? o.vendor.items[itm].map(o => o.label).join(', ') : '';
                            if (str) {
                                obj[itm] = str
                            }
                        } else if (columnsMap[itm] && columnsMap[itm] === 20) {
                            let str = '';
                            if (o.vendor.items[itm]) {
                                str = currencyCodeMap[o.vendor.items[itm].type.value] + '' + o.vendor.items[itm]?.value
                            }
                            if (str) {
                                obj[itm] = str
                            }
                        } else if (columnsMap[itm] && columnsMap[itm] === 6) {
                            if (o.vendor.items[itm]) {
                                let data_map = {}
                                o.vendor.items[itm].forEach(function (itm) {
                                    itm.forEach(function (dt) {
                                        if (data_map[dt.Label] === undefined) {
                                            data_map[dt.Label] = []
                                            data_map[dt.Label].push(dt.Value)
                                        } else {
                                            data_map[dt.Label].push(dt.Value)
                                        }
                                    })
                                })
                                if (data_map) {
                                    Object.keys(data_map).forEach(function (row_dt) {
                                        let str = data_map[row_dt].join(', ');
                                        if (str) {
                                            obj[parseInt(row_dt)] = str;
                                        }
                                    })
                                }
                            }
                        } else {
                            obj[itm] = o.vendor.items[itm] ? o.vendor.items[itm] : ''
                        }
                    })
                }
                return obj;
            })
        }
        this.setState({
            renewals: renewals,
            isDataLoaded: true
        })
    }

    showColumnVisibityPoper(event) {
        this.setState({
            subMenuEl: event.currentTarget || event.current,
            isShowSubMenu: true,
            colVisibilityData: [...this.state.renewalColumns],
        });
    }

    handleCloseSubMenu() {
        this.setState({
            isShowSubMenu: false,
            subMenuEl: null,
            colVisibilityData: null,
            isShowProjectConfigPopover: false,
            projectTrackingEl: null,
        });
    }

    onColumnsDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const { destination, draggableId, source } = result;
        if (draggableId && destination.index !== source.index) {
            const newColVisibilityData = reorder(this.state.colVisibilityData, source.index, destination.index);

            this.setState({
                colVisibilityData: newColVisibilityData,
            });
        }
    }

    updateColVisibilityStatus(oldStatus, index) {
        let colVisibilityData = [...this.state.colVisibilityData];
        let myCol = colVisibilityData[index];
        let isVisible = oldStatus === true ? false : true;
        myCol = {
            ...myCol,
            isVisible: isVisible,
        };
        colVisibilityData[index] = myCol;

        this.setState({
            colVisibilityData: colVisibilityData,
        });
    }

    setVisibilityForColumns() {
        // console.log(this.state.colVisibilityData, 'colVisibilityData')
        this.setColumns(this.state.colVisibilityData);
        this.handleCloseSubMenu();
    }

    setColumns(columns) {
        this.setState({
            renewalColumns: columns,
            colVisibilityData: null,
        }, () => {
            let customColumns = columns.filter(o => o.isVisible && !o.isDefault);
            // console.log(customColumns, 'customColumns')
            if (customColumns && customColumns.length > 0) {
                let columns = customColumns.map(o => {
                    return {
                        id: o.id.split(':tableColumn')[0],
                    }
                })
                this.props.fetchRenewals(columns)
            }
            // window.localStorage.setItem("renewalListingColumns", JSON.stringify(columns));
            this.updateColumnNames(columns)
        });
    }

    updateColumnNames(columns) {
        if (columns === undefined || columns === null || columns === '') {
            return;
        }
        let org_settings = [
            {
                name: "CONTRACT_TABLE_COLUMN",
                value: JSON.stringify(columns)
            }
        ]
        this.setState({
            configSuccessMsg: "Columns updated successfully"
        }, () => {
            this.props.addOrgSettings(org_settings);
        })
    }

    render() {
        const { renewalColumns, renewals, colVisibilityData, columnInitialized, isDataLoaded } = this.state;
        const { classes, fetchProgress } = this.props;

        if (!columnInitialized || !isDataLoaded) {
            return null
        }

        return (
            <div>
                <Paper style={{ width: '100%', overflow: 'hidden' }}>
                    <div className={classes.topHeader}>
                        <div className={classes.showBtnSection}>
                            <Tooltip title="Show Hide Columns">
                                <IconButton
                                    aria-label="filter list"
                                    onClick={(event) => {
                                        this.showColumnVisibityPoper(event);
                                    }}
                                >
                                    <SettingsOutlined />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>

                    <TableContainer style={{ maxHeight: '65vh' }}>
                        <Table stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
                            <TableHead>
                                <TableRow>
                                    {renewalColumns && renewalColumns.map((col) => {
                                        if (col.isVisible) {
                                            return <TableCell
                                                key={col?.id}
                                                align={"left"}
                                                style={{ whiteSpace: "nowrap" }}
                                                padding={col?.disablePadding ? "none" : "normal"}
                                            >{col.label}</TableCell>
                                        } else {
                                            return null
                                        }
                                    })}

                                    <TableCell
                                        key={'action_head'}
                                        align={"center"}
                                        style={{ whiteSpace: "nowrap" }}
                                        padding={"normal"}
                                    >Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {!fetchProgress && risks.slice(page * pageSize, page * pageSize + pageSize).map((risk) => { */}
                                {!fetchProgress && renewals.map((renewal) => {
                                    return <TableRow
                                        hover
                                        tabIndex={-1}
                                        style={{ cursor: "pointer" }}
                                        key={'renwal_' + renewal.contractId}>

                                        {renewalColumns && renewalColumns.map((col) => {
                                            if (col.isVisible) {
                                                if (col.type === 2) {
                                                    return <TableCell
                                                        key={col?.id}
                                                        align={"left"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        padding={col?.disablePadding ? "none" : "normal"}
                                                    >{renewal[col.id] ? CommonFn.formateDate(renewal[col.id], true) : ''}</TableCell>
                                                } else if (col.type === 6) {
                                                    return <TableCell
                                                        key={col?.id}
                                                        align={"left"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        padding={col?.disablePadding ? "none" : "normal"}
                                                    >{renewal[col.tableColumnId] ? renewal[col.tableColumnId] : ''}</TableCell>
                                                } else if (col.type === 20) {
                                                    return <TableCell
                                                        key={col?.id}
                                                        align={"left"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        padding={col?.disablePadding ? "none" : "normal"}
                                                    >{renewal[col.id] && renewal[col.id].value !== undefined ? renewal[col.id].value : renewal[col.id]}</TableCell>
                                                } else if (col.type === 100) {
                                                    return <TableCell
                                                        key={col?.id}
                                                        align={"left"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        padding={col.disablePadding ? "none" : "normal"}
                                                    > <FormControl
                                                        variant="outlined"
                                                        className={classes.select}
                                                        margin="dense"
                                                    >
                                                            {true && <Select
                                                                value={renewal[col.id]}
                                                                onClick={() => { }}
                                                                onChange={(event) => {
                                                                    if (event.target.value === 3) {
                                                                        this.props.showConfirmCallbackDialog(renewal.vendor, renewal.contract)
                                                                    }
                                                                    this.props.updateEntityRenewal(renewal.contract?.id, { date: renewal.contract?.renewalDate, status: event.target.value })
                                                                }}>
                                                                {<MenuItem value={1}>Not started</MenuItem>}
                                                                {<MenuItem value={2}>In progress</MenuItem>}
                                                                {<MenuItem value={3}>Renewed</MenuItem>}
                                                                {<MenuItem value={4}>Not going to renew</MenuItem>}
                                                            </Select>}
                                                        </FormControl>
                                                    </TableCell>
                                                } else {
                                                    return <TableCell
                                                        key={col.id}
                                                        align={"left"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        padding={col?.disablePadding ? "none" : "normal"}
                                                    >{renewal[col.id] ? renewal[col.id] : ''}</TableCell>
                                                }
                                            } else {
                                                return null
                                            }
                                        })}
                                        <TableCell
                                            key={'actionCol'}
                                            align={"center"}
                                            style={{ whiteSpace: "nowrap" }}
                                            padding={"normal"}
                                        >

                                            {renewal.project && <Button variant="contained" color="secondary" className={classes.sendBtn}
                                                onClick={() => {
                                                    this.props.viewRenewal(renewal.project?.id)
                                                }}
                                            > View Renewal</Button>}

                                            {renewal.project && <IconButton style={{ marginLeft: 25 }} ref={this.btnRef}></IconButton>}

                                            {(renewal.project === undefined || renewal.project === null || renewal.project === '') &&
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    className={classes.sendBtn}
                                                    onClick={() => {
                                                        const product = {
                                                            name: renewal.vendor.name,
                                                            id: renewal.vendor.id,
                                                            productLogo: renewal.vendor.logo,
                                                            type: renewal.entity
                                                        }
                                                        this.props.showEvaluationProductSelectionDialog(renewal.contract, product, renewal.contract?.renewalDate);
                                                    }}
                                                > Start Renewal</Button>}


                                            {(renewal.project === undefined || renewal.project === null || renewal.project === '') &&
                                                <Tooltip
                                                    title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{'Link Project'}</span>}
                                                    placement="top"
                                                    classes={{ tooltip: classes.noMaxWidth }}>
                                                    <IconButton ref={this.btnRef}
                                                        onClick={(e) => {
                                                            const product = {
                                                                name: renewal.vendor.name,
                                                                id: renewal.vendor.id,
                                                                productLogo: renewal.vendor.logo,
                                                                type: renewal.entity
                                                            };

                                                            this.props.showAttachRequestDialog(product, renewal.contract, renewal.contract?.renewalDate, renewal.entity)

                                                        }}>
                                                        <LinkIcon />
                                                    </IconButton>
                                                </Tooltip>}
                                        </TableCell>

                                    </TableRow>
                                })}

                                {(!fetchProgress && (renewals === undefined || renewals === null || renewals.length <= 0)) && <TableRow>
                                    <TableCell colSpan={renewalColumns.length + 1} style={{ textAlign: 'center' }} className={classes.nameCol}>
                                        {'No Renewals'}
                                    </TableCell>
                                </TableRow>}

                                {fetchProgress && <TableRow>
                                    <TableCell colSpan={renewalColumns.length + 1} style={{ textAlign: 'center' }} className={classes.nameCol}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        component="div"
                        count={totalRisks || 0}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    /> */}
                </Paper>

                {this.state.isShowSubMenu && (
                    <Popover
                        open={this.state.isShowSubMenu}
                        anchorEl={this.state.subMenuEl}
                        onClose={this.handleCloseSubMenu}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <Paper className={classes.columnDrawer}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography style={{ marginTop: 10 }}>Select Visible Column</Typography>
                            </div>
                            {colVisibilityData !== null && (
                                <DragDropContext onDragEnd={this.onColumnsDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                <List>
                                                    {colVisibilityData.map((col, k) => (
                                                        <Draggable key={String(k)} draggableId={String(k)} index={k}>
                                                            {(provided, snapshot) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <ListItem button style={{ cursor: 'grab' }}>
                                                                        <DragIndicatorOutlinedIcon id={k} style={{ color: '#D9D9D9', marginRight: 5 }} />
                                                                        <Typography variant="inherit" style={{ paddingRight: 10 }}>{col.label}</Typography>
                                                                        <ListItemSecondaryAction>
                                                                            <Switch
                                                                                checked={col.isVisible}
                                                                                onChange={(event) => {
                                                                                    this.updateColVisibilityStatus(col.isVisible, k);
                                                                                }}
                                                                                color="primary"
                                                                                name="is_editable"
                                                                                inputProps={{ "aria-label": "is_editable checkbox" }}
                                                                            />
                                                                        </ListItemSecondaryAction>
                                                                        {/* )} */}
                                                                    </ListItem>
                                                                    <Divider />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                </List>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            )}

                            <div className={classes.bottomActions}>
                                {
                                    <SqureButton
                                        style={{ marginLeft: "10px" }}
                                        variant={"outlined"}
                                        onClick={() => {
                                            this.handleCloseSubMenu();
                                        }}
                                    >
                                        Cancel
                                    </SqureButton>
                                }

                                {
                                    <SqureButton
                                        style={{ marginLeft: "10px" }}
                                        variant={"contained"}
                                        onClick={() => {
                                            this.setVisibilityForColumns();
                                        }}
                                    >
                                        Confirm
                                    </SqureButton>
                                }
                            </div>
                        </Paper>
                    </Popover>
                )}

            </div>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ContractList));
