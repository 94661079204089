import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class FileAncher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  getFileExtension(filename) {
    if (filename) {
      return filename.split('.').pop();
    } else {
      return filename;
    }
  }

  getExtUsingURL(url) {
    if (url) {
      return url.split(/[#?]/)[0].split('.').pop().trim();
    } else {
      return ''
    }
  }

  render() {
    const { fileName, fileURL } = this.props;

    let name = fileName === undefined || fileName === null || fileName === '' ? fileURL : fileName;
    let fileNameWithExt = fileName === undefined || fileName === null || fileName === '' ? fileURL : fileName;

    let isCorrectName = false;
    let ext = this.getFileExtension(fileNameWithExt);
    if (ext !== fileNameWithExt) {
      isCorrectName = true;
    }

    if (isCorrectName === false) {
      let newExt = this.getExtUsingURL(fileURL);
      fileNameWithExt += '.' + newExt
    }

    return (
      <span
        style={{ color: '#4b86f8', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => {
          fetch(fileURL)
            .then(res => {
              return res.blob();
            }).then(blob => {
              const href = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.download = fileNameWithExt
              a.href = href;
              a.click();
              a.href = "";
            }).catch(err => {
              const link = document.createElement('a');
              const body = document.getElementsByTagName('body');
              link.href = fileURL;
              link.setAttribute('download', fileNameWithExt);
              body[0].appendChild(link)
              link.click();
              body[0].removeChild(link);
            });
        }}
      >
        {name}
      </span>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(FileAncher));
