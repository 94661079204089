import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withLastLocation } from "react-router-last-location";
import { withRouter } from "react-router-dom";
import { Typography, CircularProgress, Switch } from "@material-ui/core";
import classnames from "classnames";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  fetchRequirementTree,
  fetchRequirementResponse,
  fetchRequirementUserResponse,
  setSelectedRequirement,
  setSelectedProduct,
  fetchLastEvaluationUserResponse,
  setRequirementTreeColExpand,
  setForAdminFlag,
} from "../../../../../../../../redux/evaluation/criteria/action";

import TreeNode from "./TreeNode";
import DownArrow from "assets/images/down-filled-arrow.svg";
// import * as URL from "util/URL";
import { getNextAndPreviousIds } from "util/Common";

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  requirementTree: state.evaludationCriteria.requirementTree,
  fetchReqTreeProgress: state.evaludationCriteria.fetchReqTreeUnderProgress,
  lastEvaUserReponses: state.evaludationCriteria.lastEvaUserReponses,
  fetchLastEvaluationUserResponseProgress: state.evaludationCriteria.fetchLastEvaluationUserResponseProgress,
  requirementResponses: state.evaludationCriteria.requirementResponses,
  requirementUserResponses: state.evaludationCriteria.requirementUserResponses,
  selectedRequirementId: state.evaludationCriteria.selectedRequirementId,
  selectedProductId: state.evaludationCriteria.selectedProductId,
  addResponseProgress: state.evaludationCriteria.addResponseProgress,
  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
  collapsedRequirements: state.evaludationCriteria.requirementTreeCollapsed,
  colloborators: state.evaludationCriteria.colloborators,
  criterias: state.evaludationCriteria.criterias,
  userRole: state.authUser.user?.Role,
  user: state.authUser.user,
  isRequirementForAdmin: state.evaludationCriteria.isRequirementForAdmin,
  isMoveToNextReq: state.evaludationCriteria.isMoveToNextReq,
});

const connectionActions = {
  fetchRequirementResponse: fetchRequirementResponse,
  fetchRequirementUserResponse: fetchRequirementUserResponse,
  fetchRequirementTree: fetchRequirementTree,
  setSelectedRequirement: setSelectedRequirement,
  setSelectedProduct: setSelectedProduct,
  fetchLastEvaluationUserResponse: fetchLastEvaluationUserResponse,
  setRequirementTreeColExpand: setRequirementTreeColExpand,
  setForAdminFlag: setForAdminFlag,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    reqTitle: {
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    antTree: {
      // padding: theme.spacing(2, 2),
      border: "1px solid #f1f1f1",
      borderRadius: theme.spacing(1.25),
      fontSize: theme.spacing(2),
      marginBottom: theme.spacing(2),
      position: "relative",
      "&:before": {
        position: "absolute",
        left: 0,
        top: 0,
        height: theme.spacing(7),
        display: "block",
        width: theme.spacing(0.4),
        background: "#2EB77D",
        content: '""',
      },
    },
    reqBox: {
      marginBottom: theme.spacing(2),
    },
    arrowIcon: {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(2),
      zIndex: 999,
    },
    titleReq: {
      margin: 0,
      fontWeight: "bold",
      fontSize: theme.spacing(2),
      padding: theme.spacing(2),
    },
    arrowImg: {
      cursor: "pointer",
    },
    mainRootItem: {
      // border: '1px solid #222',
      // padding: '10px',
      // marginBottom: '20px',
      // borderRadius: '4px',
    },
    subChild: {
      paddingLeft: "20px",
    },
    parentItem: {
      margin: 0,
      fontWeight: "bold",
      fontSize: theme.spacing(2),
    },
    rootItem: {
      position: "relative",
      padding: "10px",
      "&:before": {
        position: "absolute",
        left: "15px",
        top: "40px",
        display: "block",
        width: "3px",
        bottom: "30px",
        borderRight: "1px solid hsl(206deg 5% 74%)",
        content: '""',
      },
    },
    childItem: {
      position: "relative",
      margin: 0,
      padding: theme.spacing(0.2, 1),
      cursor: "pointer",
      "&:after": {
        position: "absolute",
        left: "-23px",
        top: "14px",
        display: "block",
        width: "21px",
        height: "1px",
        /* bottom: 25px, */
        background: "hsl(206deg 5% 74%)",
        content: '""',
      },
    },
    selectedItem: {
      backgroundColor: "#398AF5",
      border: "1px solid #398AF5",
      color: "#fff",
      padding: theme.spacing(0.2, 2),
      borderRadius: theme.spacing(1.25),
    },
    rootItemNoBefore: {
      padding: "10px",
    },
    activeTree: {
      background: "#f7f7f7",
    },
    reqTitleDiv: {
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
  });

class Requirements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProducts: [],
      selectedAnswers: [1, 2],
      selectedTab: 0,
      requirementCriteriaMap: {},
      forAdmin: false,
      isShowReqLoader: true,
      reqInit: false,
    };

    this.onSelect = this.onSelect.bind(this);
    this.showHide = this.showHide.bind(this);
    this.showRequirementTree = this.showRequirementTree.bind(this);
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {
    if (this.props.fetchReqTreeProgress === false && prevProps.fetchReqTreeProgress === true) {
      this.initSelectedRequirement();
    }

    if (this.props.selectedProductId !== prevProps.selectedProductId) {
      this.setState({
        isShowReqLoader: true,
      });
    }

    if (this.props.selectedRequirementId !== prevProps.selectedRequirementId && this.props.selectedRequirementId !== null && this.props.selectedProductId !== null) {
      this.props.fetchRequirementResponse(this.props.match.params.evaluation_id, this.props.selectedRequirementId);
      this.props.fetchRequirementUserResponse(this.props.match.params.evaluation_id, this.props.selectedProductId, this.props.selectedRequirementId);
    }

    if (this.props.selectedProductId !== prevProps.selectedProductId && this.props.selectedProductId !== null && this.props.selectedRequirementId !== null) {
      this.props.fetchRequirementResponse(this.props.match.params.evaluation_id, this.props.selectedRequirementId);
      this.props.fetchRequirementUserResponse(this.props.match.params.evaluation_id, this.props.selectedProductId, this.props.selectedRequirementId);
    }

    if (this.props.addResponseProgress === false && prevProps.addResponseProgress === true) {
      var nextPrevData = getNextAndPreviousIds(this.props.requirementTree, this.props.selectedRequirementId);
      // console.log(nextPrevData,'nextPrevData')
      if (!this.props.isMoveToNextReq || nextPrevData.nextReqId === null) {
        this.props.fetchRequirementUserResponse(this.props.match.params.evaluation_id, this.props.selectedProductId, this.props.selectedRequirementId);
      }
      this.props.fetchRequirementTree(this.props.match.params.evaluation_id, this.props.selectedProductId, this.props.isRequirementForAdmin);
    }

    if (this.props.collaboratorDialogOpen === false && prevProps.collaboratorDialogOpen === true) {
      this.props.fetchRequirementResponse(this.props.match.params.evaluation_id, this.props.selectedRequirementId);
    }
  }

  initSelectedRequirement() {
    const urlPrams = new URLSearchParams(this.props.location.search);
    var reqId = urlPrams.get("requirementId");
    var criteriaId = urlPrams.get("criteriaId");
    if (reqId && !this.props.reqInit) {
      this.props.setSelectedRequirement(parseInt(reqId));
      this.setState({ reqInit: true, isShowReqLoader: false });
      return;
    }

    var requirementTree = this.props.requirementTree !== null ? [...this.props.requirementTree] : [];

    if (criteriaId && !this.props.reqInit) {
      let selectedCriteria = requirementTree.find((item) => item.ID === criteriaId);
      this.props.setSelectedRequirement(parseInt(selectedCriteria?.Childrens?.[0].ID));
      this.setState({ reqInit: true, isShowReqLoader: false });
      return;
    }

    if (
      this.props.selectedRequirementId === null &&
      requirementTree.length > 0 &&
      this.props.lastEvaUserReponses !== null &&
      this.props.lastEvaUserReponses.RequirementID === undefined &&
      requirementTree?.[0]?.Childrens?.[0]?.ID
    ) {
      this.props.setSelectedRequirement(requirementTree?.[0]?.Childrens?.[0]?.ID);
    }

    var requirementIds = {};
    var allItems = {};

    if (requirementTree !== null && requirementTree.length > 0) {
      requirementIds = createCriteriaReqMap(requirementTree, allItems);
    }

    this.setState({
      requirementCriteriaMap: requirementIds,
    });

    var parent = "";
    function createCriteriaReqMap(datas, allItems) {
      datas.forEach(function (item) {
        if (item.Type === 1) {
          parent = item.ID;
          allItems[parent] = [];
        } else {
          allItems[parent].push(item.ID);
        }
        if (item.Childrens != null) {
          createCriteriaReqMap(item.Childrens, allItems);
        }
      });
      return allItems;
    }

    setTimeout(() => {
      if ((this.props.selectedRequirementId === null || this.props.selectedRequirementId === "") && requirementTree.length > 0) {
        this.props.setSelectedRequirement(requirementTree?.[0]?.Childrens?.[0]?.ID);
      }
      this.setLoaderState(false);
    }, 2000);
  }

  setLoaderState(type) {
    this.setState({
      isShowReqLoader: type,
    });
  }

  onSelect(selectedKeys, info) { }

  showHide(key, event) {
    // No Code Written
    // console.log(key)
  }

  showRequirementTree() {
    var isAdmin = this.props.isRequirementForAdmin ? false : true;
    this.props.setForAdminFlag(isAdmin);
    this.props.fetchRequirementTree(this.props.match.params.evaluation_id, this.props.selectedProductId, isAdmin);
  }

  render() {
    const classes = this.props.classes;

    const { requirementTree, fetchReqTreeProgress } = this.props;

    // console.log(this.props.isRequirementForAdmin,'isRequirementForAdmin')
    if (this.state.isShowReqLoader) {
      return (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      );
    }

    if (fetchReqTreeProgress === false && (requirementTree === undefined || requirementTree === null || requirementTree.length <= 0)) {
      return (
        <>
          <div className={classes.reqTitleDiv}>
            <div>
              <Typography variant={"h6"} className={classes.reqTitle}>
                Questions
              </Typography>
            </div>
            {this.props.isShowMyReqSwitch && (this.props.userRole === "OrgAdmin" || this.props.user.ID === this.props.evaluation.UserID) && (
              <div>
                {/* {this.state.forAdmin ? 'Show my Requirements' : 'Show all Requirements'} */}
                <span>Show my Questions</span>
                <Switch color="primary" checked={!this.props.isRequirementForAdmin} onChange={this.showRequirementTree} name="gilad" />
              </div>
            )}
          </div>
          <div className={classes.notAuthorized}>No question assigned</div>
        </>
      );
    }

    var selectedReqId = this.props.selectedRequirementId;

    // let queryReqId = URL.getQueryVariable("requirementId");
    // if (queryReqId) {
    //   selectedReqId = parseInt(queryReqId);
    // }

    const { requirementCriteriaMap } = this.state;
    var isActiveTree = "";

    if (this.props.requirementTree !== null && selectedReqId !== null) {
      Object.keys(requirementCriteriaMap).forEach(function (criteria) {
        if (requirementCriteriaMap[criteria].indexOf(selectedReqId) !== -1) {
          isActiveTree = parseInt(criteria);
        }
      });
    }

    // console.log(this.props.requirementTree,'requirementTree')

    return (
      <div>
        <div className={classes.reqTitleDiv}>
          <div>
            <Typography variant={"h6"} className={classes.reqTitle}>
              Questions
            </Typography>
          </div>
          {this.props.isShowMyReqSwitch && (this.props.userRole === "OrgAdmin" || this.props.user.ID === this.props.evaluation.UserID) && (
            <div>
              {/* {this.state.forAdmin ? 'Show my Requirements' : 'Show all Requirements'} */}
              <span>Show my Questions</span>
              <Switch color="primary" checked={!this.props.isRequirementForAdmin} onChange={this.showRequirementTree} name="gilad" />
            </div>
          )}
        </div>

        {this.props.requirementTree !== null &&
          this.props.requirementTree.map((item, index) => {
            let activeCls = "";
            if (isActiveTree === item.ID) {
              activeCls = classes.activeTree;
            }
            let isCollapsed = false;
            if (this.props.collapsedRequirements.indexOf(item.ID) !== -1) {
              isCollapsed = true;
            }

            return (
              <div className={classes.antTree} key={index + item.ID}>
                <span className={classes.arrowIcon} onClick={(evt) => this.props.setRequirementTreeColExpand(item.ID, "ADD")}>
                  <img className={classes.arrowImg} src={DownArrow} alt="Down Arrow" />
                </span>

                {isCollapsed && <p className={classes.titleReq}>{item.Name}</p>}
                {!isCollapsed && (
                  <div className={classnames(classes.mainRootItem, activeCls)}>
                    <TreeNode key={index} itemData={item} cls={classes.parentItem} selectedItem={selectedReqId} classes={this.props.classes}></TreeNode>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles), withLastLocation)(Requirements));
