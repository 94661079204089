import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppBarV2 from "Components/Application/AppBarV2";
import {
  Grid,
  Typography,
  Avatar,
  CircularProgress, Tab, Tabs
} from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Tooltip from "@material-ui/core/Tooltip";
import Image from "Components/Common/image.jsx";
import { generate } from 'shortid';

import addUsers from "assets/images/add_profile.svg";
import classnames from "classnames";

import { fetchProductDetailsPage } from "redux/product/action";
import { fetchMasterForms } from "redux/forms/master/action";
import { fetchOrgForms } from "redux/forms/org/action";
import { showSnackBar } from "redux/snackbar/action";

import BottomActions from "./Common/BottomActions";
import TemplateCard from './Common/Card'

const connectedProps = (state) => ({
  fetchProductDetailsUnderProgress: state.products.fetchProductDetailsUnderProgress,
  fetchProductDetailsUnderError: state.products.fetchProductDetailsUnderError,
  productDetails: state.products.productDetails,
  masterForms: state.masterForms.forms,
  fetchMasterFormProgress: state.masterForms.fetchMasterFormProgress,
  isShowAddFormDialog: state.orgForms.isShowAddFormDialog,
  fetchOrgFormsProgress: state.orgForms.fetchOrgFormsProgress,
  forms: state.orgForms.forms,
});

const connectionActions = {
  fetchProductDetails: fetchProductDetailsPage,
  fetchMasterForms: fetchMasterForms,
  fetchOrgForms: fetchOrgForms,
  showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
  },
  topBar: {
    background: "#fff",
    boxShadow: "none",
    borderBottom: "2px solid #f1f1f1",
  },
  appBar: {

  },
  collaboratorDetails: {
    display: "flex",
    padding: theme.spacing(1, 2),
  },
  colbTitle: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
    marginRight: 10,
  },
  approversFlex: {
    display: 'flex',
    "& [class*=MuiAvatar-root]": {
      width: 30,
      height: 30,
      fontSize: 14
    },
    "& [class*=MuiAvatarGroup-avatar]": {
      margin: 0
    }
  },
  addUser: {
    width: '28px',
    cursor: 'pointer'
  },
  tabContainer: {
    height: '100%',
  },
  evaTabs: {
    fontSize: theme.spacing(1.7),
    padding: theme.spacing(1, 0),
  },
  templates: {
    padding: theme.spacing(2, 0),
    marginBottom: 40
  },
  loader: {
    textAlign: 'center'
  },
  formBody: {
    padding: theme.spacing(2, 2),
  }
});


class ProductSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Survey',
      page: 0,
      pageSize: 1000,
      colloborators: [{
        Name: 'Sunil',
        Email: 'sunil@gmail.com'
      }, {
        Name: 'Anil',
        Email: 'Anil@gmail.com'
      }, {
        Name: 'Kishan',
        Email: 'Kishan@gmail.com'
      }],
      formActiveTab: 0,
      isCheckBox: true,
      selectedForm: null
    }

    this.avatarName = this.avatarName.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectFormFormSurvey = this.selectFormFormSurvey.bind(this)
    this.submitResponse = this.submitResponse.bind(this);
  }

  componentDidMount() {
    this.props.fetchProductDetails(this.props.match?.params?.productID);
    this.props.fetchMasterForms(this.state.page, this.state.pageSize);
    this.props.fetchOrgForms(this.state.page, this.state.pageSize);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.fetchProductDetailsUnderProgress && prevProps.fetchProductDetailsUnderProgress) {
      if (this.props.fetchProductDetailsUnderError === null) {
        console.log(this.props.productDetails, 'Product')
        this.setState({
          title: 'Survey for ' + this.props.productDetails?.Name
        })
      } else {
        alert('Something Went Wrong');
      }
    }
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  handleChange = (event, value) => {
    console.log(value)
    event.preventDefault()
    this.setState({
      selectedForm: null,
      formActiveTab: value
    })
  }

  selectFormFormSurvey(checked, formId, type) {
    if (checked) {
      this.setState({
        selectedForm: {
          formId, type
        }
      })
    } else {
      this.setState({
        selectedForm: null
      })
    }
  }
  submitResponse() {
    console.log('Selected Form')
    console.log(this.state.selectedForm)
    if (this.state.selectedForm === null) {
      this.props.showSnackBar("Please select form.", "error", 3000)
      return;
    }

    this.props.showSnackBar("Form Selected.", "success", 3000)


  }

  render() {
    const classes = this.props.classes;

    return <div className={classes.root}>
      <AppBarV2 className={classes.appBar} title={this.state.title} withBack={true} />
      <Grid container className={classes.topBar}>
        <Grid item lg={3} className={classes.collaboratorDetails}>
          {true && (
            <div>
              {true && (
                <Typography
                  variant={"h6"}
                  className={classes.colbTitle}
                >
                  Collaborators
                </Typography>
              )}
              <div className={classes.approversFlex}>
                <AvatarGroup max={3}>
                  {this.state.colloborators.map((user, k) => {
                    return (
                      <Tooltip
                        placement="top"
                        title={
                          <span
                            style={{
                              fontSize: "14px",
                              whiteSpace: "pre-line",
                              display: "inline-block",
                              height: "25px",
                              lineHeight: "25px",
                            }}
                          >
                            {user.Name + "\n" + user.Email}
                          </span>
                        }
                        arrow
                      >
                        <Avatar key={k}>
                          {" "}
                          {this.avatarName(user.Name)}
                        </Avatar>
                      </Tooltip>
                    );
                  })}
                </AvatarGroup>
                {<Image className={classnames(classes.addUser, 'evaluate-add-user')} alt="add user" src={addUsers} onClick={(event) => {

                }} />}
              </div>

            </div>
          )}
        </Grid>
        <Grid item lg={6} className={classes.evaTabsDiv}>
          <Grid container alignItems="flex-end" className={classes.tabContainer}>
            {<Tabs
              value={this.state.formActiveTab}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab value={0} isLast={false} className={classes.evaTabs} label="Expent's Form Library" />
              <Tab value={1} isLast={false} className={classes.evaTabs} label="Custom Forms" />
            </Tabs>}
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.formBody}>
        {this.state.formActiveTab === 0 && (
          <div className={classes.templates}>
            {this.props.fetchOrgFormsProgress === true && <div className={classes.loader}>
              <CircularProgress />
            </div>}
            {this.props.masterForms !== null && (
              <Grid container spacing={3}>
                {this.props.masterForms.map(form => {
                  console.log(form, 'Formmmmmmmmmmmmmmmmmm')
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={generate()}>
                      <TemplateCard
                        isCheckBox={this.state.isCheckBox}
                        form={form}
                        formId={this.props.formId}
                        selectedForm={this.state.selectedForm}
                        selectFormFormSurvey={this.selectFormFormSurvey}
                        deleteOrgForm={this.props.deleteOrgFormData}
                        type="MASTER"
                        formType="evaluation"
                        onClick={url => this.props.history.push(url)}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            )}
            {this.props.masterForms !== null && this.props.masterForms.length <= 0 && <div style={{ textAlign: 'center', marginTop: 20 }}>
              No Forms
            </div>}
          </div>
        )}
        {this.state.formActiveTab === 1 && (
          <div className={classes.templates}>
            {this.props.fetchOrgProg === true && <div className={classes.loader}>
              <CircularProgress />
            </div>}
            {this.props.forms !== null && (
              <Grid container spacing={3}>
                {this.props.forms.map((form) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={generate()}>
                      <TemplateCard
                        isCheckBox={this.state.isCheckBox}
                        form={form}
                        formId={this.props.formId}
                        selectedForm={this.state.selectedForm}
                        selectFormFormSurvey={this.selectFormFormSurvey}
                        deleteOrgForm={(id) => {
                          this.props.deleteOrgFormData(id)
                        }}
                        type="ORG"
                        formType="evaluation"
                        onClick={url => {
                          this.props.history.push(url);
                        }}
                      />
                    </Grid>
                  )
                })
                }
              </Grid>
            )}
            {this.props.forms !== null && this.props.forms.length <= 0 && <div style={{ textAlign: 'center', marginTop: 20 }}>
              No Forms
            </div>}
          </div>
        )}
      </div>
      <BottomActions
        submitResponse={this.submitResponse}
      />
    </div>

  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ProductSurvey));
