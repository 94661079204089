import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { createStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slide, Typography, Grid, CircularProgress } from "@material-ui/core";
import ReactSelect from "react-select";

import OutlinedInput from "Components/Common/Input/OutlinedInput";
import SqureButton from "Components/Common/SqureButton";
// import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { fetchOrgTemplates, saveFromOrgEvaluationTemplate } from "redux/templates/evaluation/org/action";
import { showSnackBar } from "redux/snackbar/action";
import { showEvaluationCollaboratorDialog } from "redux/evaluate/action";
import { addReqToSellerLibrary } from "redux/seller/evaluate/action";
import ReqTypeHandler from "../Home/Common/ReqTypeHandler";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  saveProgress: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateProg,
  saveError: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateError,
  collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,

  addReqToSellerLibraryProgress: state.sellerEvaluate.addReqToSellerLibraryProgress,
  addReqToSellerLibraryError: state.sellerEvaluate.addReqToSellerLibraryError,
  sellerLibraryAddData: state.sellerEvaluate.sellerLibraryAddData,

  sellerProducts: state.sellerProduct.sellerProducts,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  saveFromOrgEvaluationTemplate: saveFromOrgEvaluationTemplate,
  fetchOrgTemplates: fetchOrgTemplates,
  showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog,
  addReqToSellerLibrary: addReqToSellerLibrary,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(6, 12) + " !important",
    },
    modalTitle: {
      fontSize: theme.spacing(2.4),
      marginBottom: theme.spacing(3),
      textAlign: "center",
    },
    helptext: {
      fontSize: theme.spacing(1.8),
    },
    alerts: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    form: {
      marginTop: theme.spacing(2),
      width: "60%",
    },
    input: {
      marginBottom: theme.spacing(3),
    },
    scheduleTitle: {
      display: "block",
      marginBottom: theme.spacing(3),
      fontWeight: 600,
      fontSize: theme.spacing(2),
    },
    scheduleTitle1: {
      display: "block",
      marginBottom: theme.spacing(3),
      fontWeight: 600,
      fontSize: theme.spacing(1.8),
    },
    actions: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      textAlign: "center",
    },
    loader: {
      textAlign: "center",
      padding: theme.spacing(10, 0),
    },
    dialogAction: {
      textAlign: "center",
    },
    addNewButton: {
      fontWeight: 600,
      fontSize: theme.spacing(2),
    },
    actionBtn: {
      minWidth: 110,
    },
    nameYour: {
      "& [class*=MuiInputBase-root]": {
        maxHeight: 40,
        minHeight: 40,
        "& input": {
          fontSize: theme.spacing(1.8),
        },
      },
    },
    nameOfCriteria: {
      "& > div": {
        marginBottom: 0,
      },
    },
    priority: {
      "& > div": {
        marginBottom: 0,
        justifyContent: "flex-start",
      },
      "& [class*=MuiInputBase-root]": {
        width: 100,
        color: "#707070",
        fontSize: 13,
        textAlign: "center",
      },
      "& [class*=MuiSelect-outlined]": {
        color: "#707070",
        fontSize: theme.spacing(1.8),
        "&:after": {
          content: "''",
          width: 7,
          height: 7,
          position: "absolute",
          left: 9,
          borderRadius: "50%",
          top: "50%",
          transform: "translate(0px, -50%)",
        },
      },
      "&.low [class*=MuiSelect-outlined]:after": {
        background: "green",
      },
      "&.medium [class*=MuiSelect-outlined]:after": {
        background: "orange",
      },
      "&.high [class*=MuiSelect-outlined]:after": {
        background: "red",
      },
    },
    scoring: {
      "& > div": {
        alignItems: "baseline",
        marginBottom: 0,
      },
      "& [class*=MuiSelect-outlined]": {
        paddingLeft: 10,
      },
    },
    yourQuestion: {
      "& [class*=MuiInputBase-root]": {
        padding: 0,
        fontSize: theme.spacing(1.8),
        minHeight: 120,
        color: "#707070",
        lineHeight: 1.2,
        "& textarea": {
          minHeight: 100,
        },
      },
    },
    addCriteriaDatePicker: {
      "& .react-datepicker__input-container > div": {
        display: "flex",
        minWidth: 300,
        marginBottom: 0,
        alignItems: "baseline",
        "& [class*=MuiInputBase-root]": {
          maxHeight: 33,
          minHeight: 33,
          width: 166,
          "& input": {
            fontSize: theme.spacing(1.8),
            // width: 107,
            textAlign: "center",
          },
        },
        "& [class*=MuiFormLabel-root]": {
          marginBottom: 0,
          marginRight: 29,
        },
      },
      "[class*=MuiMenuItem-root]": {
        color: "#707070",
        fontSize: theme.spacing(1.8),
      },
    },
    titleHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    titleImage: {
      cursor: "pointer",
    },
    marginBottom: {
      marginBottom: 10,
    },
    criteriaPriority: {
      minHeight: "25px !important",
      padding: 6,
      border: "1px solid #c4c4c4",
      borderRadius: 4,
      color: "#5F5F5F",
      fontSize: "1.1em",
      marginLeft: 20,
      width: 75,
      textAlign: "center",
    },
    priorityC: {},
    filterTitle: {
      color: "#6C6C6C",
      fontSize: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
    },
    reqTypes: {
      marginBottom: theme.spacing(3.5),
    },
    formControl: {
      width: "98%",
      "& > div": {
        color: "#282D30",
        fontSize: theme.spacing(2.2),
      },
    },
  });

class CreateQuestionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      activeMultiSelectId: false,
      requirement: {
        name: "",
        name_error: "",
        scoring_type: 1,
        description: "",
        description_error: "",
        answer_type: 1,
        options: [],
        selectedOptions: [],
        is_mandatory: false,
        is_editable: false,
        is_other_option: false,
        product: null,
        note: "",
        answer: "",
        character_limit: "",
        type: 1,
      },
      productOptions: [
        {
          label: "Global",
          value: "global",
        },
      ],
      products: [],
    };

    this.submitResponse = this.submitResponse.bind(this);
    this.cleanError = this.cleanError.bind(this);
    this.cleanState = this.cleanState.bind(this);
  }

  componentDidMount() {
    if (this.props.sellerProducts && this.props.sellerProducts.Data && this.props.sellerProducts.Data.length > 0) {
      let newOptions = [...this.state.productOptions];
      this.props.sellerProducts?.Data.forEach((o) => {
        newOptions.push({
          label: o.Name,
          value: o.ID,
        });
      });
      this.setState({ productOptions: newOptions });
    }
  }

  cleanState() {
    this.setState({
      error: "",
      success: "",
      requirement: {
        name: "",
        name_error: "",
        scoring_type: 1,
        answer_type: 1,
        options: [],
        description: "",
        description_error: "",
        is_editable: false,
        is_other_option: false,
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.saveProgress === false && prevProps.saveProgress === true) {
      if (this.props.saveError === null) {
        this.props.hideDialog();
        this.cleanState();
        this.props.showSnackBar("Template Saved", "success", 3000);
        this.props.fetchOrgTemplates(0, 60);
      } else {
        if (this.props.saveError.message !== undefined) {
          this.setState({
            error: "Failed to save template.",
          });
        } else {
          this.setState({
            error: "Something went wrong.",
          });
        }
      }
    }

    if (!this.props.addReqToSellerLibraryProgress && prevProps.addReqToSellerLibraryProgress) {
      if (this.props.addReqToSellerLibraryError === null) {
        this.props.showSnackBar("Question added successfully", "success", 3000);
        this.props.hideDialog();
      }
    }
  }

  cleanError() {
    this.setState({
      error: "",
      success: "",
      requirement: { ...this.state.requirement, name_error: "", description_error: "" },
    });
  }

  handleOptions = (id, answerType) => {
    let activeMultiSelectId = id;
    if ([2, 3, 4].includes(answerType)) {
      const element = document.getElementById(activeMultiSelectId);
      if (element && element.click()) {
        element.click();
      }
      if (activeMultiSelectId !== this.state.activeMultiSelectId) {
        this.setState({ activeMultiSelectId });
      }
    }
  };

  handleUpdateRequirement = (arequirement, key, value, options = false, is_other_option = false, character_limit = 0) => {
    let requirement = this.state.requirement;
    requirement[key] = value;
    if (options) {
      requirement.options = options;
      requirement.is_other_option = is_other_option;
    }
    if (key === "answer_type" && value === 7) {
      requirement.character_limit = character_limit;
    }
    // if (key !== 'answer_type') this.handleOptions(`evalution_criteria_requiment`, requirement.answer_type)
    this.setState({ requirement });
  };

  submitResponse() {
    this.cleanError();
    var requirement = this.state.requirement;

    if (!requirement.product) {
      this.setState({
        error: "Please select product.",
      });
      return;
    }

    var rName = requirement.name;
    if (rName.trim().length === 0) {
      this.setState({
        requirement: { ...requirement, name_error: "Please enter question title.", description_error: "" },
      });
      return;
    }

    var rDesc = requirement.description;
    if (rDesc.trim().length === 0) {
      this.setState({
        requirement: { ...requirement, name_error: "", description_error: "Please enter question description." },
      });
      return;
    }

    let answer = requirement.answer || "";
    if (parseInt(requirement.answer_type) === 8) {
    } else if (parseInt(requirement.answer_type) === 11) {
      answer = answer.toISOString();
    }

    let params = {
      is_global: requirement.product?.value === "global" ? 1 : 0,
      product_id: requirement.product?.value === "global" ? null : requirement.product?.value,
      question: {
        name: rName,
        description: rDesc,
        // "character_limit": requirement.character_limit,
        // "tags": [{
        //   "tag_id": 2,
        //   "tag_name": "Security"
        // }]
      },
      answers: [
        {
          note: requirement.note || "",
          answer_type: parseInt(requirement.answer_type),
          answer: answer,
          options: requirement.options,
          response_options: requirement.selectedOptions,
        },
      ],
    };

    console.log(params, "params");

    this.props.addReqToSellerLibrary({
      source: { id: null, name: "form" },
      action: "add",
      data: [params],
    });
  }

  updateRequirementLocally(updatedData, isUpdateNow) {
    let { requirement } = this.state;
    console.log("==============================Update Locally");
    console.log(updatedData);

    let options = [];
    let selectedOptions = [];
    if (updatedData.options && updatedData.options.length > 0) {
      options = updatedData.options;
    }

    if (updatedData.selectedOptions && updatedData.selectedOptions.length > 0 && updatedData.type === 2) {
      let sOptions = updatedData.selectedOptions.map((o) => o.value);

      let sellOps = [];
      updatedData.options.forEach(function (itm) {
        if (sOptions.indexOf(itm.description) !== -1) {
          sellOps.push(itm);
        }
      });
      selectedOptions = sellOps;
    } else if (updatedData.selectedOptions && updatedData.type === 3) {
      let index = updatedData.options.findIndex((o) => o.description === updatedData.selectedOptions.value);
      selectedOptions = [updatedData.options[index]];
    } else if (updatedData.selectedOptions && (updatedData.type === 4 || updatedData.type === 14)) {
      selectedOptions = [updatedData.selectedOptions];
    } else if (updatedData.selectedOptions && updatedData.type === 15) {
      let sellOps = [];
      updatedData.options.forEach(function (itm) {
        if (updatedData.selectedOptions.indexOf(itm.description) !== -1) {
          sellOps.push(itm);
        }
      });
      selectedOptions = sellOps;
    } else {
      selectedOptions = updatedData.selectedOptions;
    }

    let answer = updatedData.text_value;
    // if(updatedData.type === 13){
    //   answer = JSON.stringify(updatedData.text_value)
    // }else{
    //   answer = updatedData.text_value
    // }

    requirement.options = options;
    requirement.selectedOptions = selectedOptions;
    requirement.answer_type = updatedData.type;
    requirement.character_limit = updatedData.character_limit;
    requirement.is_other_option = updatedData.is_other_option;
    requirement.note = updatedData.note;
    requirement.answer = answer;

    this.setState({
      requirement: requirement,
    });
  }

  render() {
    const classes = this.props.classes;
    const { requirement, productOptions } = this.state;
    // console.log(requirement, 'requirement')
    return (
      <Dialog
        onClose={this.props.hideDialog}
        aria-labelledby="app-integrationDialog"
        open={this.props.isOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        id="evMetaDialog"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Typography variant={"h2"} className={classes.modalTitle}>
            Add New Question
          </Typography>
          <div className={classes.alerts}>
            {this.state.error.length > 0 && (
              <Alert variant="filled" severity="error">
                {this.state.error}
              </Alert>
            )}
            {this.state.success.length > 0 && (
              <Alert variant="filled" severity="success">
                {this.state.success}
              </Alert>
            )}
          </div>
          <div>
            <Grid container>
              {/* <Grid item xs={6} lg={6} className={classes.reqTypes}>
              <Typography variant={"h4"} className={classes.filterTitle}>Type</Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <Select value={requirement.type} onChange={(event) => {
                  this.handleUpdateRequirement(requirement, 'type', event.target.value)
                }}>
                  <MenuItem value={1} style={{ fontSize: 13 }}>Global</MenuItem>
                  <MenuItem value={0} style={{ fontSize: 13 }}>Product</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
              <Grid item xs={12} lg={12} className={classes.reqTypes}>
                <Typography variant={"h4"} className={classes.filterTitle}>
                  Select Product
                </Typography>
                <ReactSelect
                  isClearable
                  isSearchable={true}
                  options={productOptions}
                  classNamePrefix="select"
                  placeholder="Search Product"
                  onChange={(e, action) => {
                    this.handleUpdateRequirement(requirement, "product", e);
                  }}
                  menuPortalTarget={document.getElementById("evRuleDialog")}
                  value={requirement.product}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <div className={classes.nameYour}>
                  <OutlinedInput
                    label="Question Title"
                    error={requirement.name_error.length > 0}
                    helperText={requirement.name_error}
                    value={requirement.name}
                    onChange={(event) => {
                      this.handleUpdateRequirement(requirement, "name", event.target.value);
                    }}
                  />
                </div>

                <div className={classes.nameYour}>
                  <OutlinedInput
                    label="Question Description"
                    error={requirement.description_error.length > 0}
                    helperText={requirement.description_error}
                    value={requirement.description}
                    onChange={(event) => {
                      this.handleUpdateRequirement(requirement, "description", event.target.value);
                    }}
                  />
                </div>

                {requirement && (
                  <ReqTypeHandler
                    requirement={requirement}
                    isActive={true}
                    isUpdateOnChange={true}
                    onChangeType={(updates) => {
                      alert(updates);
                    }}
                    onChangeData={(isUpdateNow, item) => {
                      this.updateRequirementLocally(item, isUpdateNow);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <DialogContent classes={{ root: classes.dialogAction }}>
          <div className={classes.actions}>
            {this.props.saveProgress && <CircularProgress />}
            {!this.props.saveProgress && (
              <>
                <SqureButton
                  cls={classes.actionBtn}
                  variant={"outlined"}
                  onClick={() => {
                    this.cleanError();
                    this.props.hideDialog();
                  }}
                >
                  Cancel
                </SqureButton>
                &nbsp;&nbsp;&nbsp;
                <SqureButton
                  cls={classes.actionBtn}
                  disabled={this.props.addReqToSellerLibraryProgress}
                  variant={"contained"}
                  onClick={(e) => {
                    e.stopPropagation();

                    this.submitResponse();
                  }}
                >
                  Save
                </SqureButton>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(CreateQuestionDialog));
