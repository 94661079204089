import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import AppBarV2 from "Components/Application/AppBarV2";
import { Grid, CircularProgress, Typography } from "@material-ui/core";

import classnames from "classnames";
import CommonCss from "commonCss";
import { CommonFn } from 'services/commonFn';

import deleteIcon from "assets/images/delete.svg";
import { deleteRequest } from "redux/evaluation/requests/action";
// Redux
import { fetchSellerAssessments, deleteSellerAssessment } from "redux/seller/assessment/action";

import Image from 'Components/Common/image';
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { showSnackBar } from "redux/snackbar/action";
import { fetchInProgressEvaluation } from "redux/seller/evaluate/action";


const connectedProps = (state) => ({
  sellerAssessmentsProg: state.sellerAssessment.sellerAssessmentsProg,
  sellerAssessmentsError: state.sellerAssessment.sellerAssessmentsError,
  sellerAssessments: state.sellerAssessment.sellerAssessments,

  deleteSellerAssessmentProg: state.sellerAssessment.deleteSellerAssessmentProg,
  deleteProductReviewError: state.sellerAssessment.deleteProductReviewError,
  userType: state.authUser.user?.Role,
  deleteRequestProgress: state.evaluationRequestData.deleteRequestProgress,
  deleteRequestError: state.evaluationRequestData.deleteRequestError,
});

const connectionActions = {
  fetchSellerAssessments: fetchSellerAssessments,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  deleteSellerAssessment: deleteSellerAssessment,
  showSnackBar: showSnackBar,
  deleteRequest: deleteRequest,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    topContainer: {
    },
    bottomContainer: {
      // marginTop: theme.spacing(5),
    },
    title: {
      fontSize: theme.spacing(2.4),
      fontWeight: 500
    },
    mainLoader: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 19,
      margin: "0px !important",
    },

    productTitleSection: {
      ...CommonCss.productTitleSection,
      marginTop: 40,
      marginBottom: 10,
    },
    ...CommonCss.Home,
    btnReview: {
      width: '38px',
      height: '32px',
      borderRadius: '5px',
      cursor: 'pointer',
      textAlign: 'center',
      fontSize: '13px',
      color: '#626675',
      paddingTop: 2,
      zIndex: 12,
      position: 'relative',
      border: '1px solid #EEEEEE',
      background: '#ffffff'
    },
    evaluateCard: {
      ...CommonCss.Home.evaluateCard,
      alignItems: 'center'
    },
    content: {
      background: "#F7F7F7",
    },
    rootContainer: CommonCss.rootContainer,
    mainData: {
      minHeight: '100vh'
    },
    topSection: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    namePart: {
      flex: 1
    },
    filterPart: {
      textAlign: 'right'
    }
  });

class AssessmentsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteId: null,
      query: "",
      page: 0,
      pageSize: 1000,
      productId: parseInt(this.props.match.params.productId),
      inPorgressStatus: [1, 2, 3],
      completedStatus: [4],
      selectedType: 1
    };
  }

  componentDidMount() {
    this.fetchAssessmentsData();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.deleteSellerAssessmentProg && prevProps.deleteSellerAssessmentProg) {
      if (!this.props.deleteProductReviewError) {
        this.props.showSnackBar("Assessment deleted successfully", "success", 3000);
        this.fetchAssessmentsData();
      } else {
        this.props.showSnackBar("Failed to delete assessment", "error", 3000);
      }
    }
    if (!this.props.deleteRequestProgress && prevProps.deleteRequestProgress) {
      if (!this.props.deleteRequestError) {
        this.props.showSnackBar("Assessment deleted successfully", "success", 3000);
        this.fetchAssessmentsData();
      } else {
        this.props.showSnackBar("Failed to delete assessment", "error", 3000);
      }
    }
  }

  fetchAssessmentsData = () => {
    // let params = {
    //   page: this.state.page,
    //   pageSize: this.state.pageSize,
    //   productID: parseInt(this.props.match.params.productId)
    // }
    this.props.fetchSellerAssessments({filters: {product_id: this.props.match.params.productId}});
  }

  assessmentRouteChange = (item) => {
    let redirectPath = `/sellerapp/assessment/${item.id}/questions`;
    if(item.type === "internal"){
      redirectPath = `/sellerapp/assessment/${item.id}/${item.buyer_org_id}/${parseInt(item.product_id)}`;
    }
    this.props.history.push(redirectPath);
  }

  deleteAssessment(e, item) {
    e.stopPropagation();
    var me = this;
    this.setState({ deleteId: item }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      if(this.state.deleteId.type === "internal"){
        this.props.deleteRequest(this.state.deleteId?.evaluation_id);
      }else {
        this.props.deleteSellerAssessment(this.state.deleteId?.id);
      }
    }
  };


  render() {
    const classes = this.props.classes;

    // if (this.props.sellerAssessmentsProg || this.props.completedEvaluationProgress) {
    //   return (<div className={classes.mainLoader}><CircularProgress /></div>)
    // }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let userRole = ["OrgAdmin","OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;

    console.log(this.props.sellerAssessments, 'InProgressData')

    return (
      <div>
        <AppBarV2
          withBack={true}
          title={"Assessments"}
        />
        <div className={classes.rootContainer}>
          <div className={classes.mainData}>
            <div className={classes.topSection}>
              <div className={classes.namePart}>
                <Typography className={classes.title}>Assessments ({this.props.sellerAssessments?.total || "0"})</Typography> 
              </div>
            </div>

            {(this.props.sellerAssessmentsProg || this.props.deleteSellerAssessmentProg) && <div className={classes.mainLoader}><CircularProgress /></div>}

            {!this.props.sellerAssessmentsProg && !this.props.deleteSellerAssessmentProg && this.props.sellerAssessments && <Grid container className={classnames(classes.content)}>
              {this.props.sellerAssessments?.data?.map((item, index) => {
                return <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={"ass-"+index}>
                  <div className={classes.evaluateCard} onClick={() => this.assessmentRouteChange(item)}>
                    <div className={classes.evaluateText}>
                      <p className={classes.evaluateCardTitle}>{item.name}</p>
                      <p className={classes.evaluateCardSubTitle}>Updated At: {CommonFn.formateDate(item.updated_at)}</p>
                    </div>
                    <div className={classes.evaluateLogo}>
                      <div className={classes.productLogoContainer}>
                        <div className={classes.productLogo} title={item.name}>
                          <Image src={item.product_logo} alt={item.name} title={item.name} />
                        </div>
                      </div>
                      {adminFlag && item.type !== "internal" &&<button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={(e) => this.deleteAssessment(e, item)}><Image
                        src={deleteIcon}
                        alt="forgotpassword_image"
                        className={classes.titleImage}
                      /></button>}
                    </div>
                  </div>
                </Grid>
              })}
            </Grid>}
          </div>
        </div>

        <MessageDialog messageData={messageData} callBack={this.callBack} />
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(AssessmentsList));
