import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slide, Typography, Button } from "@material-ui/core";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import CommonCss from "commonCss";
import { IsExistingProduct } from "Components/Common/SelectProductOption";
import { withRouter } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';

import { CommonFn } from "services/commonFn";
import ReactSelect from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
import * as Environment from "util/Environment";
import axios from "axios";
const SelectAsyncPaginate = withAsyncPaginate(ReactSelect);

// env
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({

});

const connectionActions = {

};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    header: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: " center space-between",
      alignItems: "center",
    },
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginBottom: theme.spacing(2.5),
      display: 'flex',
      alignItems: 'center',
      gap: 15
    },
    mainForm: {
      border: "1px solid #F2F2F2",
      padding: 15,
      margin: '20px 0px',
    },
    subTitle: {
      color: "#282D30",
      fontSize: 16,
    },
    formControl: CommonCss.formControl,
    label: {
      ...CommonCss.label,
      marginRight: 20
    },
    formGroup: CommonCss.formGroup,
    rightSection: {
      flex: 1,
      marginLeft: 60,
      marginRight: 30,
    },
    buttonSection: {
      textAlign: "center",
      margin: 20,
    },
    button: {},
    arrow: {
      paddingLeft: 5,
    },
    mandatory: CommonCss.mandatory,
    close: {
      position: "absolute",
      right: 15,
      top: 15,
      cursor: "pointer",
      color: "#6F6F6F",
      "& svg": {
        stroke: "white",
        fontSize: "24px",
      },
    },
    divider: {
      color: "#4b86ff",
    },
    inputField: {
      color: '#5F5F5F',
      padding: '0.5em 0.5rem',
      fontSize: '1.1em',
      borderRadius: '5px',
      width: '100%',
      "&:focus": {
        outline: 'none'
      }
    },
  });

class MergeProductDialog extends React.Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      selectedProduct: '',
      selectedProductError: '',
    };
    this.submitForm = this.submitForm.bind(this)
    this.getOptions = this.getOptions.bind(this)
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  clearError() {
    this.setState({
      selectedProductError: '',
    })
  }

  submitForm() {
    this.clearError();
    const { selectedProduct } = this.state;
    if (selectedProduct === null || selectedProduct === '' || selectedProduct.length === 0) {
      this.setState({
        selectedProductError: 'Please select project'
      })
      return;
    }
    // console.log(selectedProduct,'selectedProduct')
    this.props.onSubmit(selectedProduct.value)
  }

  async getOptions(inputValue, loadedOptions, additional) {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("SEARCH");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      field_setname: "all",
      text: inputValue,
      page,
      page_size: 150,
      sort_fields: [],
      filter_fields: {
        // verified:[1]
      },
      merge_existing_others: true,
    };
    let url = `${api_server}/v2/dsl/expent_catalogue?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let products = response?.data?.body?.items || [];
    let total = response?.data?.body?.total || 0;

    products = products.map((m) => {
      m.value = String(m.id);
      m.label = m.name;
      m.isProduct = true;
      m.productStates = m.states;
      m.isDisabled = m.states?.blocked !== undefined;
      return m;
    });
    return {
      options: products,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  }

  ProductLabels = (props) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
        <div>
          <span style={{ color: "#000", fontSize: "16px" }}>{props.label}</span>&nbsp;
          {props.isProduct !== undefined && props.isProduct && <span style={{ color: "#6C6C6C", fontSize: "12px" }}>By {props.company_name}</span>}
        </div>
      </div>
    );
  };

  render() {
    const classes = this.props.classes;
    const { selectedProductError,selectedProduct } = this.state;

   return (
      <Dialog
        onClose={this.props.hideDialog}
        aria-labelledby="add-upload-dialog"
        open={true}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"sm"}
        scroll={"body"}
        id="MergeProductDialog"
        PaperProps={{ style: { overflowY: "visible" } }}
      >
        <DialogContent classes={{ root: classes.dialogContent }} style={{ overflowY: "visible" }}>
          <div className={classes.close} onClick={this.props.hideDialog}><CloseIcon /></div>
          <Typography variant={"h6"} className={classes.title}>
            Merge Product
          </Typography>

          <div className={classes.mainForm}>
            <div className={classes.formGroup}>
              <label className={classes.label}>
                Select product <span className={classes.mandatory}>*</span>
              </label>

              <SelectAsyncPaginate
                isClearable={false}
                isSearchable={true}
                classNamePrefix="select"
                placeholder={"Select product"}
                onChange={(data) => {
                  this.setState({
                    selectedProduct: data
                  }, () => {
                    this.clearError()
                  })
                }}
                additional={{
                  page: 0,
                }}
                formatOptionLabel={IsExistingProduct}
                value={selectedProduct}
                loadOptions={this.getOptions}
              />
                
              {selectedProductError.length > 0 && <span className={classes.mandatory}>{selectedProductError}</span>}
            </div>

            <div className={classes.buttonSection}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={this.props.hideDialog}>
                Cancel
              </Button>
              &nbsp; &nbsp;
              <Button
                variant="contained"
                color="secondary"
                onClick={() => { this.submitForm() }}
                className={classes.button}>
                <span style={{ display: 'flex' }}>Next  <ArrowForwardIcon className={classes.arrow} /></span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(MergeProductDialog));
