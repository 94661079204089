import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Divider,
  ListItemSecondaryAction,
  ListItemIcon,
  ListItem,
  Popover,
  List,
} from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  PowerSettingsNew as LogoutIcon,
  SettingsOutlined as SettingIcon,
} from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import ArrowBack from "../../assets/images/arrow_back.svg";
import { logout } from "../../redux/authuser/action";
import classnames from "classnames";
import { Avatar } from "@material-ui/core";
import { EditOutlined as EditIcon } from "@material-ui/icons";

import Image from 'Components/Common/image.jsx'

const connectedProps = (state) => ({
  user: state.authUser.user,
});

const connectionActions = {
  logout: logout,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      "&.newHeader": {
        height: 50,
        display: 'flex',
        alignItems: 'center',
        padding: "0px 32px",
        borderBottom: '1px solid #EEEEEE',
        "& $title": {
          fontSize: 16
        },

        "& $headSection": {
          display: 'flex'
        },

        "& $button": {
          border: 'none'
        }
      }
    },
    headSection: {
      flex: 1,
    },
    title: {
      fontSize: theme.spacing(2.8),
    },
    profileAction: {
      textAlign: "right",
      "& [class*='outlinedSecondary']:hover": {
        background: "#fff",
      },
    },
    profileMenu: {
      background: theme.palette.common.white,
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    iconContainer: {
      marginRight: theme.spacing(3),
      cursor: "pointer",
      display: "flex",
    },
    image: {
      width: theme.spacing(3.5),
    },
    button: {
      fontSize: theme.spacing(2),
      background: "transparent",
    },
    listItem: {
      paddingTop: theme.spacing(2),
    },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 6,
      marginBottom: 5,
      cursor: "pointer",
    },
    children: {
      flex: 1
    },
  });

class AppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileAnchorEl: null,
    };
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  render() {
    if (this.props.user == null) {
      return null;
    }
    const classes = this.props.classes;

    return (
      <div className={classnames(classes.root, 'appBar', this.props.newHeader ? 'newHeader' : '')}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item className={classes.headSection}>
            <div className={classes.titleContainer}>
              {this.props.withBack && (
                <div
                  className={classes.iconContainer}
                  onClick={() => {
                    if (this.props.dispatchEvent) {
                      this.props.targetEvent()
                    } else {
                      this.props.history.goBack();
                    }
                  }}
                >
                  <Image
                    src={ArrowBack}
                    alt="arrow_back"
                    className={classes.image}
                  />
                </div>
              )}
              <Typography variant={"h6"} className={classes.title}>
                {this.props.title}
                {/* <LinesEllipsis
                  text={this.props.title}
                  maxLine="1"
                  ellipsis="..."
                  basedOn="letters"
                /> */}
                {
                  this.props.edit &&
                  <EditIcon fontSize={"small"} className={classes.editIcon}
                    onClick={() => {
                      this.props.editAction()
                    }}
                  />
                }
              </Typography>
            </div>
          </Grid>
          {this.props.children && (
            <Grid item className={classes.children}>
              {this.props.children}
            </Grid>
          )}
          <Grid item>
            {this.props.dropdown && (
              <div className={classes.profileAction}>
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.button}
                  classes={{
                    label: classes.button,
                  }}
                  startIcon={<Avatar style={{ width: '25px', height: '25px', fontSize: '14px' }} alt={this.props.user.Name} >{this.avatarName(this.props.user.Name)}</Avatar>}
                  onClick={(event) => {
                    this.setState({ profileAnchorEl: event.currentTarget });
                  }}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {this.props.user.Name}
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
        <Popover
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          id="profileMenu"
          classes={{ paper: classes.profileMenu }}
          anchorEl={this.state.profileAnchorEl}
          keepMounted
          elevation={2}
          open={Boolean(this.state.profileAnchorEl)}
          onClose={() => {
            this.setState({ profileAnchorEl: null });
          }}
        >
          <List>
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={() => {
                this.setState({ profileAnchorEl: null });
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {/* <FaceIcon /> */}
                <Avatar style={{ width: '25px', height: '25px', fontSize: '14px' }} alt={this.props.user.Name} >{this.avatarName(this.props.user.Name)}</Avatar>
              </ListItemIcon>
              <Typography variant="inherit">{this.props.user.Name}</Typography>
              <ListItemSecondaryAction>
                <ArrowDropDownIcon />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={() => {
                this.setState({ profileAnchorEl: null });
                this.props.history.push("/app/profile");
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <SettingIcon />
              </ListItemIcon>
              <Typography variant="inherit">Account Settings</Typography>
            </ListItem>
            <Divider />
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={() => {
                this.setState({ profileAnchorEl: null });
                this.props.logout();
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <LogoutIcon />
              </ListItemIcon>
              <Typography variant="inherit">Logout</Typography>
            </ListItem>
          </List>
        </Popover>
      </div>
    );
  }
}

AppBar.defaultProps = {
  dropdown: true,
  withBack: false,
  dispatchEvent: false,
  newHeader: false
};

export default connector(compose(withRouter, withStyles(styles))(AppBar));
