import React from "react";
// import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({

// }));

const HeadingCard = (props) => {
  const { title } = props;
  return (
        <div>
          <Typography variant="h6" style={{lineHeight: 1.5}}>{title}</Typography>
        </div>
  );
};

export default HeadingCard;
