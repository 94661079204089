import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CommonCss from 'commonCss';

import EvaluationBar from "../../../Common/EvaluationBar";
import EvaluationEmptyState from "assets/images/evaluation_empty_state.svg";
import Image from 'Components/Common/image'
import { Button } from '@material-ui/core';

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: {
    ...CommonCss.rootContainer,
    textAlign: 'center',
    marginTop: '5%'
  }

});

class ApprovalCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  takeToSummary(){
    const { checkTimeline } = this.props;
    if(checkTimeline && checkTimeline.workflowSummary !== undefined && checkTimeline.workflowSummary !== null){
      let summaryData = checkTimeline.workflowSummary;
      this.props.history.push("/app/evaluate/" + summaryData?.EvaluationId + "/workflow/"+ summaryData?.WorkflowId+"/component/"+ summaryData?.ComponentId);
    }
  }

  render() {
    const classes = this.props.classes;
    const { checkTimeline } = this.props;
    let isShowButton = false;
    if(checkTimeline && checkTimeline.workflowSummary !== undefined && checkTimeline.workflowSummary !== null){
      isShowButton = true;
    }

    return (
      <div>
        <EvaluationBar
          isShowCollabrations={false}
          isShowEvalDetails={false}
          isWorkFlow={this.props.isWorkFlow}
          isShowViewBtns={false}
        />
        <div className={classes.rootContainer}>
          <div style={{ height: 220 }}>
            <Image src={EvaluationEmptyState} />
          </div>
          <h2>Project Completed</h2>

          <Button variant="outlined" color="secondary" className={classes.button}
            onClick={() => { this.props.history.push('/app/home') }}
          >Home</Button>

          {isShowButton && <Button variant="contained" color="secondary" className={classes.button} style={{marginLeft:10}}
            onClick={() => { this.takeToSummary() }}
          >Take me to Project Summary</Button>}
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ApprovalCheck));
