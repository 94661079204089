import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { Drawer, Typography } from '@material-ui/core';
import {Step,Steper} from "./../Common/Steper/index";

const connectedProps = (state) => ({

});

const connectionActions = {

}


var connector = connect(connectedProps, connectionActions);

const drawerWidth = 270;

const styles = (theme) => createStyles({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        paddingTop: theme.spacing(5),
        background: "#20253A"
    },
    logoimage: {
        marginTop: theme.spacing(7),
        textAlign: "center",
    },
    title: {
        color: "#fff",
        textAlign: "center",
        fontSize: theme.spacing(2.6)
    },
    menu: {
        marginTop: theme.spacing(19)
    },
});



class CDrawer extends React.Component {

    render() {
        const classes = this.props.classes;
        var location = this.props.location.pathname;
        var appsActive = false;
        var teamActive = false;
        var completionActive = false;
        if (location == "/onboard/apps") {
            appsActive = true;
        } else if (location == "/onboard/team") {
            appsActive = true;
            teamActive = true;
        } else {
            appsActive = true;
            teamActive = true;
            completionActive = true;
        }

        return <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <div className={classes.logoimage}>
                <Typography variant={"h6"} className={classes.title}>Let's Get Started</Typography>
            </div>
            <div className={classes.menu}>
                <Steper>
                    <Step completed={appsActive} label={"Add Integrations"} isFirst={true} circle={appsActive && teamActive == false}/>
                    <Step completed={teamActive} label={"Invite your team"} circle={teamActive && completionActive == false}/>
                    <Step completed={completionActive} label={"Finish"} circle={completionActive}/>
                </Steper>
            </div>
        </Drawer>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(CDrawer));