import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slide, Typography, Button } from "@material-ui/core";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import { CommonFn } from "services/commonFn";
import CommonCss from "commonCss";
import { withRouter } from "react-router-dom";

import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { IsExistingVendor, IsExistingService } from "Components/Common/SelectVendorOption";
import { IsExistingProduct } from "Components/Common/SelectProductOption";
// Redux
import { hideAddVendorDialog } from "redux/vendor/vendorCatalog/action";
import { showSnackBar } from "redux/snackbar/action";

import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import { addOrgVendor } from "redux/vendor/vendorCatalog/action";
import * as Environment from "util/Environment";
import { fetchAllServices } from "redux/vendor/service/action";
import { fetchVendorProducts } from "redux/vendor/action";
import { fetchVendors } from "redux/vendor/action";
import { fetchServices } from "redux/vendor/service/action";

const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  user: state.authUser.user,
  addVendorDialogOpen: state.vendorCatalog.addVendorDialogOpen,
  orgVendorAddProgress: state.vendorCatalog.orgVendorAddProgress,
  orgVendorAddError: state.vendorCatalog.orgVendorAddError,

  fetchAllServiceProgress: state.services.fetchAllServiceProgress,
  fetchAllServiceError: state.services.fetchAllServiceError,
  allServices: state.services.allServices,

  fetchVendorProductsProgress: state.vendors.fetchVendorProductsProgress,
  fetchVendorProductsError: state.vendors.fetchVendorProductsError,
  vendorProducts: state.vendors.vendorProducts,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  hideAddVendorDialog: hideAddVendorDialog,
  addOrgVendor: addOrgVendor,
  fetchAllServices: fetchAllServices,
  fetchVendorProducts: fetchVendorProducts,
  fetchVendors: fetchVendors,
  fetchServices: fetchServices,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    header: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: " center space-between",
      alignItems: "center",
    },
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginBottom: theme.spacing(2.5),
      marginLeft: 20,
    },

    addVendorSection: {
      display: "flex",
      border: "1px solid #F2F2F2",
      padding: 50,
      margin: 20,
    },
    subTitle: {
      color: "#282D30",
      fontSize: 16,
    },
    formControl: CommonCss.formControl,
    label: CommonCss.label,
    formGroup: CommonCss.formGroup,
    rightSection: {
      flex: 1,
      marginLeft: 30,
      marginRight: 30,
    },
    formDivide: {
      display: "flex",
    },
    groupFlexLeft: {
      marginRight: 10,
      flex: 1,
    },
    groupFlexRight: {
      marginLeft: 10,
      flex: 1,
    },
    buttonSection: {
      textAlign: "center",
      margin: 20,
    },
    button: {},
    arrow: {
      paddingLeft: 5,
    },
    uploadImageLabel: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    uploadImage: {
      width: 0,
      overflow: "hidden",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: 11,
    },
    deleteSection: {
      position: "absolute",
      background: "#fff",
      borderRadius: 11,
      padding: "2px 2px 0px 2px",
      top: 0,
      right: 0,
      cursor: "pointer",
    },
    delete: {
      fill: "#ff1200",
    },
    mandatory: CommonCss.mandatory,
    searchAndSelect: {
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
      },

      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    otherDetails: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 10px #398AF51A",
      border: "1px solid #398AF5",
      borderRadius: "5px",
    },
    close: {
      position: "absolute",
      right: 15,
      top: 15,
      cursor: "pointer",
      color: "#6F6F6F",
      "& svg": {
        stroke: "white",
        fontSize: "24px",
      },
    },
    divider: {
      color: "#4b86ff",
    },
  });

  const stateOption = [
    { label: "Existing", value: "existing" },
    { label: "Featured", value: "featured" },
    { label: "Limited Use", value: "limited"},
    { label: "In Review", value: "in_review" },
    { label: "Blocked", value: "blocked"},
    { label: "Evaluated", value: "evaluated" },
    { label: "Deprecated", value: "deprecated" },
    { label: "Preferred", value: "preferred" },
  ]

class AddVendorOptionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      selectedVendor: null,
      selectedProducts: [],
      selectedServices: [],
      selectedVendorState: [],
      selectedServiceState: {},
      selectedProductState: {},
    };
  }

  componentDidMount() {
    this.props.fetchAllServices({query: '',page: 0, page_size: 1000,filters: {}});
  }

  componentDidUpdate(prevProps) {
    
    if (this.props.orgVendorAddProgress === false && prevProps.orgVendorAddProgress === true) {
      if (this.props.orgVendorAddError === null) {
          this.props.hideAddVendorDialog();
          if(this.props.type === "vendor"){
            this.props.showSnackBar("Vendor Added Successfully", "success", 3000);
          } else {
            this.props.showSnackBar("Service Added Successfully", "success", 3000);
          }
          if(this.props.type === "vendor"){
            // this.props.fetchVendors({
            //   query: "",
            //   page: 0,
            //   page_size: 60,
            //   filters: {}
            // });
            this.props.history.push("/app/vendors/org-vendors");
          }else {
            // this.props.fetchServices({
            //   query: "",
            //   page: 0,
            //   page_size: 60,
            //   filters: {}
            // });
            this.props.history.push("/app/vendors/org-services");
          }
      } else {
        let msg = "Something went wrong. Please try again!";
        if (this.props.orgVendorAddError?.message) {
          msg = "Failed to add vendor!";
        }
        this.props.showSnackBar(msg, "error", 3000);
      }
    }
  }

  onSubmit = () => {
    let vendor = {
      master_vendor_id: this.props.vendorId, 
    }
    let entities = [];

    if (this.state.selectedVendor?.label === undefined) {
      this.props.showSnackBar("Please Select Vendor.", "error", 3000);
      return;
    }

    vendor.states = this.state.selectedVendorState?.map(item=> ({name: item.value, description: ''}));

    if (this.state.selectedVendor.__isNew__) {
      vendor.name = this.state.selectedVendor?.label || "";
    } else {
      vendor.org_vendor_id = this.state.selectedVendor?.org_vendor_id || null;
      vendor.master_vendor_id = this.state.selectedVendor?.master_vendor_id || null;
    }

    this.state.selectedProducts?.forEach((item)=>{
      let statesArr = this.state.selectedProductState[item.value] || [];
      let states = statesArr.map(item=> ({name: item.value, description: ''}));
      entities.push({
        name: item.label,
        master_id: item.value || null,
        type: 'product',
        // states: states,
      });
    })

    this.state.selectedServices?.forEach((item)=>{
      let statesArr = this.state.selectedServiceState[item.value] || [];
      let states = statesArr.map(item=> ({name: item.value, description: ''}));
      let data = {
        name: item.label,
        type: 'service',
        master_id: item.master_service_id || null,
        states: states,
      }
      entities.push(data);
    })

    vendor.entities = entities;

    this.props.addOrgVendor(vendor);
  };

  getProductsOptions = async (inputValue, loadedOptions, additional) => {
    // console.log(inputValue,loadedOptions,additional);
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("SEARCH");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      field_setname: "all",
      text: inputValue,
      page,
      page_size: 150,
      sort_fields: [],
      filter_fields: {},
      merge_existing_others: true,
    };
    // setPage(page+1)
    let url = `${api_server}/v2/dsl/expent_catalogue?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let products = response?.data?.body?.items || [];
    let total = response?.data?.body?.total || 0;
    products = products.map((m) => {
      m.value = parseInt(m.id);
      m.label = m.name;
      m.isProduct = true;
      m.productStates = m.states;
      m.isDisabled = (m.states?.blocked !== undefined || m.states?.existing !== undefined);
      return m;
    });
    return {
      options: products,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      query: inputValue,
      page:0,
      page_size:30,
      filters: {}
    }
    // let orgFormData = {
    //   query: inputValue,
    //   page:0,
    //   page_size:30,
    //   filters: {is_org: "1"}
    // }
    let url = `${api_server}/entity/vendor/search?t=${timestamp}`;

    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    }); 

    // const orgResponse = await axios.post(url, orgFormData, {
    //   headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    // });

    let vendors = response?.data?.items || [];
    // if(response?.data?.items){
    //   vendors.push(...response?.data?.items)
    // };
    let total = response?.data?.items?.total || 0;
    // let orgTotal = orgResponse?.data?.items?.total || 0;
    // let totolVendor = total + orgTotal;

    vendors = vendors.map((m) => {
      m.value = parseInt(m.id);
      m.label = m.name;
      return m;
    });

    return {
      options: vendors,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  selectVendorHandler = (data) => {
    this.setState({ selectedVendor: data },() => {
      if(!data?.__isNew__){
        let params = {
          org_vendor_id: data?.org_vendor_id || null,
          master_vendor_id: data?.master_vendor_id || null
        }
        this.props.fetchVendorProducts(params);
      }
    });
  }


  render() {
    const classes = this.props.classes;
    
    var serviceList = this.props.allServices?.items?.map((item)=>{
      return {
          ...item,
          label:item.name,
          value:item.master_service_id || item.org_service_id,
      }
    });
    
    return (
      <Dialog
        onClose={this.props.hideDialog}
        aria-labelledby="add-vendor-dialog"
        open={this.props.addVendorDialogOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll={"body"}
        id="addVendorDialog"
        PaperProps={{ style: { overflowY: "visible" } }}
      >
        <DialogContent classes={{ root: classes.dialogContent }} style={{ overflowY: "visible" }}>
          <Typography variant={"h6"} className={classes.title}>
            {this.props.type === "vendor" ? "Add new vendor" : "Add new service"}
          </Typography>
          <div>
            <span className={classes.close} onClick={() => this.props.hideDialog()}>
              <CloseIcon className={classes.close} />
            </span>
            <div className={classes.addVendorSection}>
              <div className={classes.rightSection}>
                <div className={classes.formGroup}>
                  <label className={classes.label}>
                    Name of the vendor<span className={classes.mandatory}>*</span>
                  </label>
                  <CreatableAsyncPaginate
                    isClearable
                    formatCreateLabel={(userInput) => `Create '${userInput}'`}
                    noOptionsMessage={() => "Start typing to select or create vendor"}
                    isSearchable={true}
                    createOptionPosition={"first"}
                    classNamePrefix="select"
                    placeholder="Name of the vendor"
                    additional={{
                      page: 0,
                    }}
                    components={{ IndicatorSeparator: () => null }}
                    formatOptionLabel={IsExistingVendor}
                    isValidNewOption={(input) => input.length > 0}
                    loadOptions={this.getOptions}
                    value={this.state.selectedVendor}
                    onChange={(data) => {
                      this.selectVendorHandler(data)
                      if(data?.is_existing){
                        this.setState({selectedVendorState: [{label:'Existing', value:'existing'}]})
                      }else{
                        this.setState({selectedVendorState: []})
                      }
                    }}
                  />
                </div>
                {this.state.selectedVendor?.label && <>
                  <div className={classes.formGroup}>
                    <label className={classes.label}>
                      Tag for {this.state.selectedVendor?.label}
                    </label>
                    <ReactSelect
                      isMulti
                      placeholder="Select Tag"
                      options={stateOption}
                      value={this.state.selectedVendorState}
                      onChange={(data) => {
                        this.setState({selectedVendorState: data})
                      }}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <label className={classes.label}>
                      Products for {this.state.selectedVendor?.label}
                    </label>

                    <CreatableAsyncPaginate
                      isClearable
                      formatCreateLabel={(userInput) => `Create '${userInput}'`}
                      isSearchable={true}
                      isMulti
                      createOptionPosition={"first"}
                      classNamePrefix="select"
                      placeholder="Select or create products"
                      additional={{
                        page: 0,
                      }}
                      formatOptionLabel={IsExistingProduct}
                      isValidNewOption={(input) => input.length > 0}
                      defaultValue={this.props.addProductInitQuery?.name ? { label: this.props.addProductInitQuery?.name, value: "" } : {}}
                      loadOptions={this.getProductsOptions}
                      value={this.state.selectedProducts}
                      onChange={(data) => {
                        let newSelectedProductState = {};
                        if(data){
                          data.forEach(item=>{
                            if(this.state.selectedProductState[item.value]){
                              newSelectedProductState[item.value] = this.state.selectedProductState[item.value]
                            }else if(item.is_existing){
                              newSelectedProductState[item.value] = [{label:'Existing', value:'existing'}]
                            }
                          })
                        }
                        this.setState({
                          selectedProducts: data,
                          selectedProductState: newSelectedProductState
                        });
                      }}
                    />
                  </div>

                  {this.state.selectedProducts && this.state.selectedProducts.map(((item,index)=>(
                    <div className={classes.formGroup} key={index}>
                      <label className={classes.label}>
                        Tag for {item.label}
                      </label>
                      <ReactSelect
                        isMulti
                        placeholder="Select Tag"
                        options={stateOption}
                        value={this.state.selectedProductState[item.value]}
                        onChange={(data) => {
                          this.setState({ selectedProductState: { ...this.state.selectedProductState, [item.value]: data } });
                        }}
                      />
                    </div>
                  )))}

                  <div className={classes.formGroup}>
                    <label className={classes.label}>
                      Services for {this.state.selectedVendor?.label}
                    </label>
                    <CreatableSelect
                      isSearchable={true}
                      isMulti
                      classNamePrefix="select"
                      placeholder="Select Services"
                      options={serviceList}
                      formatOptionLabel={IsExistingService}
                      noOptionsMessage={() => "No services"}
                      value={this.state.selectedServices}
                      onChange={(data) => {
                        let newSelectedServiceState = {};
                        if(data){
                          data.forEach(item=>{
                            if(this.state.selectedServiceState[item.value]){
                              newSelectedServiceState[item.value] = this.state.selectedServiceState[item.value]
                            }else if(item.is_org){
                              newSelectedServiceState[item.value] = [{label:'Existing', value:'existing'}]
                            }
                          })
                        }
                        this.setState({
                          selectedServices: data,
                          selectedServiceState: newSelectedServiceState
                        });
                      }}
                    />
                  </div>

                  {this.state.selectedServices && this.state.selectedServices.map(((item,index)=>(
                    <div className={classes.formGroup} key={index}>
                      <label className={classes.label}>
                        Tag for {item.label}
                      </label>
                      <ReactSelect
                        isMulti
                        placeholder="Select Tag"
                        options={stateOption}
                        value={this.state.selectedServiceState[item.value]}
                        onChange={(data) => {
                          this.setState({ selectedServiceState: { ...this.state.selectedServiceState, [item.value]: data } });
                        }}
                      />
                    </div>
                  )))}
                </>}

              </div>
            </div>

            <div className={classes.buttonSection}>
              <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideDialog}>
                Cancel
              </Button>
              &nbsp; &nbsp;
              {this.props.orgVendorAddProgress ? (
                <Button variant="contained" color="secondary" type="submit" className={classes.button}>
                  Loading...
                </Button>
              ) : (
                <Button variant="contained" color="secondary" onClick={this.onSubmit} className={classes.button}>
                  {this.props.type === "vendor" ? "Add vendor" : "Add service"} <ArrowForwardIcon className={classes.arrow} />
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(AddVendorOptionDialog));
