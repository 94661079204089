/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {HttpFactory} from "../../services/httpService";
import * as Environment from "../../util/Environment";

export const START_PLANS_FETCH     =   "START_PLANS_FETCH";
export const END_PLANS_FETCH       =   "END_PLANS_FETCH";
export const START_PLAN_FETCH     =   "START_PLAN_FETCH";
export const END_PLAN_FETCH       =   "END_PLAN_FETCH";

function startPlansFetch(){
    return {
        type:START_PLANS_FETCH,
        payload:null
    };
}

function endPlansFetch(success,error){
    return {
        type:END_PLANS_FETCH,
        payload:{
            success,error
        }
    };
}

export  function fetchPlans(){
    const api_server = Environment.api_host("USER");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/payment-plans?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startPlansFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endPlansFetch(response,error));
        } catch(e){
            dispatch(endPlansFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startPlanFetch(){
    return {
        type:START_PLAN_FETCH,
        payload:null
    };
}

function endPlanFetch(success,error){
    return {
        type:END_PLAN_FETCH,
        payload:{
            success,error
        }
    };
}

export  function fetchPlan(){
    const api_server = Environment.api_host("USER");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/current-subscription?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startPlanFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endPlanFetch(response,error));
        } catch(e){
            dispatch(endPlanFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 