/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { ClickAwayListener } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import CustomImage from "./CustomImage";
import AddComponentDialog from "./AddComponentDialog";
const connectedProps = (state) => ({
  workflowComponent: state.master.workflowComponent,
  orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
  masterWorkFlowList: state.evaluationWorkflow.masterWorkFlowList,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = () => createStyles({});

const Actions = ({
  onSelectCompoent,
  onClickDecisionComponent,
  onClickWorkFlowComponent,
  workflowComponent,
  orgWorkFlowList,
  masterWorkFlowList,
}) => {
  const [showActions, setShowActions] = useState(false);
  const [targetAction, setTargetAction] = useState(false);
  const [searchquery, setSeachQuery] = useState("");
  const [workflows, setWorkflow] = useState([]);
  const [orgWorkflows, setOrgWorkflows] = useState([]);
  const [masterWorkflows, setMasterWorkflows] = useState([]);
  const [isOpenAddComponent, setOpenAddComponent] = useState(false);

  useEffect(() => {
    if (targetAction === "component") {
      if (searchquery && workflowComponent.length > 0) {
        const filteredlist = workflowComponent.filter(
          (val) =>
            val.Name.toLowerCase() === searchquery.toLowerCase() ||
            val.Name.toLowerCase().includes(searchquery.toLowerCase())
        );
        setWorkflow(filteredlist);
      } else {
        setWorkflow(workflowComponent);
      }
    } else {
      let orgWF =
        orgWorkFlowList?.Data?.length > 0 ? orgWorkFlowList?.Data : [];
      let masterWF =
        masterWorkFlowList?.Data?.length > 0 ? masterWorkFlowList?.Data : [];
      if (searchquery) {
        const filteredOrglist = orgWF.filter(
          (val) =>
            val.Name.toLowerCase() === searchquery.toLowerCase() ||
            val.Name.toLowerCase().includes(searchquery.toLowerCase())
        );
        const filteredMasterlist = orgWF.filter(
          (val) =>
            val.Name.toLowerCase() === searchquery.toLowerCase() ||
            val.Name.toLowerCase().includes(searchquery.toLowerCase())
        );
        setOrgWorkflows(filteredOrglist);
        setMasterWorkflows(filteredMasterlist);
      } else {
        setOrgWorkflows(orgWF);
        setMasterWorkflows(masterWF);
      }
    }
  }, [searchquery, targetAction]);

  if (false && targetAction) {
    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={(e) => {
          e.stopPropagation()
          setShowActions(false);
          setTargetAction(false);
          setSeachQuery("");
        }}
      >
        <div className="search-area">
          <div className="search-bar">
            <ArrowBackIosIcon
              onClick={(e) => {
                e.stopPropagation();
                setTargetAction(false);
                setShowActions(false);
              }}
              style={{ fontSize: 10, color: "#000000" }}
            />
            <div className="input-container">
              <SearchIcon
                style={{ fontSize: 10, color: "#9B9EAC", marginTop: -0.5 }}
              />
              <input
                className="workflowSearchInput"
                autoFocus
                onChange={(e) => {
                  e.stopPropagation();
                  setSeachQuery(e.target.value);
                }}
                value={searchquery}
                placeholder={`Search ${targetAction}`}
              />
            </div>
          </div>
          <div className="result-area">
            {targetAction === "workflow" && (
              <>
                {false && masterWorkflows.length && (
                  <p
                    style={{ color: "#9b9eac" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Expent Workflow Library
                  </p>
                )}
                {false && masterWorkflows.map((wrkf, index) => (
                  <p
                    style={{ cursor: "pointer" }}
                    key={wrkf.Name}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickWorkFlowComponent(e, 'MASTER', wrkf?.ID, wrkf?.Name, wrkf?.Description)
                      setTargetAction(false);
                    }}
                  >
                    {index + 1}. {wrkf.Name}
                  </p>
                ))}
                {orgWorkflows.length > 0 && (
                  <p
                    style={{ color: "#9b9eac" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Custom Workflow
                  </p>
                )}
                {orgWorkflows.map((wrkf, index) => (
                  <p
                    style={{ cursor: "pointer" }}
                    key={wrkf.Name}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickWorkFlowComponent(e, 'ORG', wrkf?.ID, wrkf?.Name, wrkf?.Description)
                      setTargetAction(false);
                    }}
                  >
                    {index + 1 + masterWorkflows.length}. {wrkf.Name}
                  </p>
                ))}
              </>
            )}
            {targetAction === "component" &&
              workflows.map((wrkf, index) => (
                <p
                  style={{ cursor: "pointer" }}
                  key={wrkf.Name}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectCompoent(e, wrkf.ID);
                    setTargetAction(false);
                  }}
                >
                  {index + 1}. {wrkf.Name}
                </p>
              ))}
          </div>
        </div>
      </ClickAwayListener>
    );
  }

  // console.log(isOpenAddComponent, 'isOpenAddComponent')
  return (
    <div className="menu-area">
      {!showActions && (
        <AddIcon
          style={{ fontSize: 10, color: "#000000", margin: 2 }}
          onClick={(e) => {
            e.stopPropagation();
            setShowActions(true);
          }}
        />
      )}
      {showActions && (
        <>
          <div className="icon">
            <CustomImage
              slug="WORKFLOW_DARK"
              onClick={(e) => {
                e.stopPropagation();
                setTargetAction("workflow");
                setOpenAddComponent(true)
              }}
            />
          </div>
          &nbsp;
          <div className="icon">
            <CustomImage
              slug="COMPONENT"
              onClick={(e) => {
                e.stopPropagation();
                setTargetAction("component");
                setOpenAddComponent(true)
              }}
            />
          </div>
          <div className="icon">
            &nbsp;
            <CustomImage
              slug="DECISION_COMPONENT"
              onClick={(e) => {
                e.stopPropagation();
                onClickDecisionComponent(e);
                setTargetAction(false);
                setShowActions(false);
              }}
            />
          </div>
        </>
      )}

      {isOpenAddComponent && <AddComponentDialog
        isOpen={isOpenAddComponent}
        targetAction={targetAction}
        showActions={showActions}
        searchquery={searchquery}
        workflows={workflows}
        orgWorkflows={orgWorkflows}
        masterWorkflows={masterWorkflows}
        workflowComponent={workflowComponent}
        orgWorkFlowList={orgWorkFlowList}
        masterWorkFlowList={masterWorkFlowList}
        setTargetAction={(type) => {
          setTargetAction(type);
        }}
        onSelectCompoent={(e, componentId) => {
          setOpenAddComponent(false);
          onSelectCompoent(e, componentId);
        }}
        addWorkFlowComponent={(data) => {
          setOpenAddComponent(false);
          onClickWorkFlowComponent(data.event, data.type, data.id, data.name, data.description)
        }}
        hideDialog={() => {
          setOpenAddComponent(false);
        }}
      />
      }

    </div>
  );
};

export default connector(compose(withStyles(styles))(Actions));
