import {
    START_METRIC_APP_FETCH,
    END_METRIC_APP_FETCH,
    START_METRIC_APP_ACTIVITY_FETCH,
    END_METRIC_APP_ACTIVITY_FETCH,
    START_METRIC_APP_CHARTDATA_FETCH,
    END_METRIC_APP_CHARTDATA_FETCH,
    START_POSSIBLE_APP_FETCH,
    END_POSSIBLE_APP_FETCH,
    START_POSSIBLE_SOURCE_FETCH,
    END_POSSIBLE_SOURCE_FETCH
} from "./action";

import {
    END_ORG_USER_UPDATE
} from "../org/user/action";

const initState   =     {
    fetchVendorProgress:false,
    vendor:null,
    fetchVendorError:null,
    activityFetchProgress:false,
    activities:[],
    chartDataFetchProgress:false,
    dailyChartData:[],
    fetchPossibleAppsProgress:false,
    possibleApps:null,
    fetchPossibleAppsError:null,
    fetchPossibleSourcesProgress:false,
    possibleSources:null,
    fetchPossibleSourcesError:null
}

export  function metricAppReducer(state=initState,action){
    switch(action.type){
        case START_POSSIBLE_APP_FETCH:
            return {
                ...state,
                fetchPossibleAppsProgress:true,
                possibleApps:null,
                fetchPossibleAppsError:null
            };
        case END_POSSIBLE_APP_FETCH:
            return {
                ...state,
                fetchPossibleAppsProgress:false,
                possibleApps:action.payload.success,
                fetchPossibleAppsError:action.payload.error
            };
        case START_POSSIBLE_SOURCE_FETCH:
            return {
                ...state,
                fetchPossibleSourcesProgress:true,
                possibleSources:null,
                fetchPossibleSourcesError:null
            };
        case END_POSSIBLE_SOURCE_FETCH:
            return {
                ...state,
                fetchPossibleSourcesProgress:false,
                possibleSources:action.payload.success,
                fetchPossibleSourcesError:action.payload.error
            };
        case START_METRIC_APP_FETCH:
            return {
                ...state,
                fetchVendorProgress:true,
                vendor:null,
                fetchVendorError:null
            };
        case END_METRIC_APP_FETCH:
            return {
                ...state,
                fetchVendorProgress:false,
                vendor:action.payload.success,
                fetchVendorError:action.payload.error
            };
        case START_METRIC_APP_ACTIVITY_FETCH:
            return {
                ...state,
                activityFetchProgress:true,
                activities:[],
            };
        case END_METRIC_APP_ACTIVITY_FETCH:
            return {
                ...state,
                activityFetchProgress:false,
                activities:action.payload.success,
            };
        case START_METRIC_APP_CHARTDATA_FETCH:
            return {
                ...state,
                chartDataFetchProgress:true,
                dailyChartData:[],
            };
        case END_METRIC_APP_CHARTDATA_FETCH:
            return {
                ...state,
                chartDataFetchProgress:false,
                dailyChartData:action.payload.success,
            };
        case END_ORG_USER_UPDATE:
            var updated_user = action.payload.success
            var new_activities = state.activities.map((activity)=>{
                if(updated_user !== null){
                    if(activity.refUserId == updated_user.ID){
                        activity.name = updated_user.Name
                        activity.email = updated_user.Email
                        if(updated_user.PrimaryGroup !== null){
                            activity.groupId = updated_user.PrimaryGroup.ID
                            activity.groupName = updated_user.PrimaryGroup.Name
                        }
                    }
                }
                return activity;
            })
            return {
                ...state,
                activities:new_activities,
            }
        default:
            return state;
    }
}