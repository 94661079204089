import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import FavoriteOutlined from "@material-ui/icons/FavoriteOutlined";
// Asets
import ImgAndroid from "assets/images/platforms/android.png";
import ImgCloud from "assets/images/platforms/cloud.png";
import ImgIos from "assets/images/platforms/ios.png";
import ImgLinux from "assets/images/platforms/linux.png";
import ImgMac from "assets/images/platforms/mac.png";
import ImgPremise from "assets/images/platforms/premise.svg";
import ImgWeb from "assets/images/platforms/web.svg";
import ImgWindows from "assets/images/platforms/windows.png";
import classnames from "classnames";
import CommonCss from "commonCss";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import Image from 'Components/Common/image.jsx';
// import Cart from "Components/Application/Components/Evaluate/Components/Common/Cart";
import ReadMore from "Components/Common/ReadMore";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
// Redux
import { clearStoreState } from "redux/applications/action";
import { hideProductDetailsDialog } from "redux/dialogs/action";
import { updateDrawerStyle } from "redux/drawer/action";
import {
    createEvaluationCart, fetchEvaluationCart, removeProductFromCart
} from "redux/evaluation/cart/action";
import {
    fetchComplementaryProductReaction, fetchProductDetailsPage,
    fetchProductReaction, fetchProductWishListedUsers, fetchWishListedProducts,
    showProductWhistedUserDialog, deleteUnVerifiedProduct
} from "redux/product/action";
import { fetchProductPrice } from "redux/product/orgProduct/action";
import { fetchAddOrgProduct, fetchCompanyProductDetails, fetchComplementaryProducts, fetchOrgProductAlternatives } from 'redux/product/orgProduct/action';
import { showSnackBar } from "redux/snackbar/action";
import { size } from "underscore";
import AppBar from "../Application/AppBar";
import ProductWhislistedUsersDialog from "../Dialogs/ProductWhislistedUsersDialog";
import PricingSection from "./PricingSection";
import { fireEvent } from 'redux/common/action'
import AppBarV2 from "Components/Application/AppBarV2";
import { isEnabledEvaluate } from "util/Common";

const connectedProps = (state) => ({
    fetchProductDetailsUnderProgress: state.products.fetchProductDetailsUnderProgress,
    fetchProductDetailsUnderError: state.products.fetchProductDetailsUnderError,
    productDetails: state.products.productDetails,
    fetchProductAlternativesUnderProgress: state.orgProduct.fetchProductAlternativesUnderProgress,
    productAlternatives: state.orgProduct.productAlternatives,
    fetchCompanyProductsUnderProgress: state.orgProduct.fetchCompanyProductsUnderProgress,
    companyProducts: state.orgProduct.companyProducts,
    evaluationCart: state.evaluationCart.evaluation,

    fetchComplementaryProductsUnderProgress: state.orgProduct.fetchComplementaryProductsUnderProgress,
    complementaryProducts: state.orgProduct.complementaryProducts,

    addOrgProductProgress: state.orgProduct.addOrgProductProgress,
    addorgProductStatus: state.orgProduct.addorgProductStatus,
    fetchProductsReactionProgress: state.products.fetchProductsReactionProgress,
    productPrice: state.orgProduct.productPrice,
    showProductWhislistedUsersDialog: state.products.showProductWhislistedUsersDialog,
    fetchProductWishlistedUsersProgress: state.products.fetchProductWishlistedUsersProgress,
    productWishlistedUsers: state.products.productWishlistedUsers,
    userRole: state.authUser.user?.Role,
    deleteUnverifiedProductProg: state.products.deleteUnverifiedProductProg,
    deleteUnverifiedProductError: state.products.deleteUnverifiedProductError,
    addEventProgress: state.common.addEventProgress,
    orgUser: state.orgUser,
});

const connectionActions = {
    updateDrawerStyle: updateDrawerStyle,
    fetchProductDetails: fetchProductDetailsPage,
    fetchOrgProductAlternatives: fetchOrgProductAlternatives,
    fetchComplementaryProducts: fetchComplementaryProducts,
    fetchCompanyProductDetails: fetchCompanyProductDetails,
    createEvaluationCart: createEvaluationCart,
    removeProductFromCart: removeProductFromCart,
    fetchEvaluationCart: fetchEvaluationCart,
    showSnackBar: showSnackBar,
    hideProductDetailsDialog: hideProductDetailsDialog,
    fetchAddOrgProduct: fetchAddOrgProduct,
    clearStoreState: clearStoreState,
    fetchProductReaction: fetchProductReaction,
    fetchComplementaryProductReaction: fetchComplementaryProductReaction,
    fetchWishListedProducts: fetchWishListedProducts,
    showProductWhistedUserDialog: showProductWhistedUserDialog,
    fetchProductWishListedUsers: fetchProductWishListedUsers,
    deleteUnVerifiedProduct: deleteUnVerifiedProduct,
    fetchProductPrice: fetchProductPrice,
    fireEvent: fireEvent
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
    createStyles({
        ...CommonCss.ProductDetails,
        rootContainer: CommonCss.rootContainer,
        linkItem: {
            cursor: 'pointer !important'
        },
        cursorTag: {
            fontSize: '12px',
            borderRadius: '25px',
            padding: '10px 14px'
        },
        emptyText: {
            textAlign: 'center',
            height: 50,
            paddingTop: 20
        },
        addProductLink: {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        mailTo: {
            color: '#4A87F8 !important',
            textDecoration: 'underline'
        },
        favLiked: {
            zIndex: 11,
            color: '#f44336'
        },
        whislistedUsers: {
            marginLeft: 20,
            display: 'flex',
            cursor: 'pointer',
            background: '#f1f1f1',
            borderRadius: '25px',
            padding: '5px 10px'
        },
        productLeftSection: {
            maxWidth: 200,
            width: 200,
            minWidth: 'auto',
            position: 'sticky',
            top: 20,
            alignSelf: 'flex-start'
        },
        list: {
            overflowY: 'auto',
            maxHeight: "calc(100vh - 280px)",
            padding: '0px 30px 30px 0px',
            fontSize: '13px',
            listStyle: 'none',
            marginTop: '30px',
            marginLeft: '0',
            paddingLeft: '0',
        }
    });

class ProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is404: false,
            showMoreProducts: false,
            showMoreCompanyProducts: false,
            showMoreComplementaryProducts: false,
            allFetchDoneCount: 0,
            viewMore: [],
            isCartShow: false,
            headerSectionWidth: null,
        };
        this.addToCart = this.addToCart.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.props.hideProductDetailsDialog();
        setTimeout(() => {
            this.props.updateDrawerStyle(false, true)

        }, 100);
        this.props.fetchProductPrice(this.props.match?.params?.id);
        this.fetchProductDetails()
        this.props.fetchWishListedProducts();
        setTimeout(() => {
            this.updateDimensions();
        }, 2000);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.deleteUnverifiedProductProg && prevProps.deleteUnverifiedProductProg) {
            if (this.props.deleteUnverifiedProductError === null) {
                this.props.showSnackBar("Product Successfully Deleted", "success", 3000);
                setTimeout(() => {
                    this.props.history.goBack();
                }, 200)
            } else {
                this.props.showSnackBar("Something went wrong, please try after sometime.", "error", 3000);
            }
        }
        if (!this.props.fetchProductDetailsUnderProgress && prevProps.fetchProductDetailsUnderProgress) {
            if (this.props.fetchProductDetailsUnderError?.message && this.props.fetchProductDetailsUnderError?.message !== null) {
                console.log('fetchProductDetailsUnderError', this.props.fetchProductDetailsUnderError)
                this.setState({ is404: true })
            }
        }

        if (!this.props.fetchProductAlternativesUnderProgress && prevProps.fetchProductAlternativesUnderProgress) {
            let totalCount = this.state.allFetchDoneCount + 1;
            this.setState({
                allFetchDoneCount: totalCount
            }, () => {
                this.checkFetchReaction();

            });
        }

        if (!this.props.fetchComplementaryProductsUnderProgress && prevProps.fetchComplementaryProductsUnderProgress) {
            let totalCounts = this.state.allFetchDoneCount + 1;
            this.setState({
                allFetchDoneCount: totalCounts
            }, () => {
                this.checkFetchReaction();
            });
        }

        if (this.props.match.params.id !== prevProps.match?.params?.id) {
            this.fetchProductDetails()
        }

        this.cartDrawer(prevProps)

        let productId = this.props.addorgProductStatus?.orgProduct?.id || 0;
        if (this.props.addorgProductStatus && this.props.addorgProductStatus) {
            this.props.showSnackBar("Product Successfully Added", "success", 3000);
            this.props.history.push("/app/products/org-products?newProductId=" + productId);
            this.props.clearStoreState({
                'orgProduct.addorgProductStatus': null
            })
        }
    }

    cartDrawer = (preProps) => {
        if ((this.props.evaluationCart && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length) !== (preProps.evaluationCart && preProps.evaluationCart.Products && preProps.evaluationCart.Products.length)) {
            this.setState({ isCartShow: true });
            setTimeout(() => {
                this.setState({ isCartShow: false })
            }, 2000);
        }
    }

    fetchProductDetails = () => {
        this.props.fetchProductDetails(this.props.match?.params?.id)
        this.props.fetchOrgProductAlternatives(this.props.match?.params?.id)
        this.props.fetchCompanyProductDetails(this.props.match?.params?.id)
        this.props.fetchComplementaryProducts(this.props.match?.params?.id)
        this.props.fetchProductWishListedUsers(this.props.match?.params?.id);
    }

    componentWillUnmount() {
        this.props.updateDrawerStyle(true, true)
        window.removeEventListener('resize', this.updateDimensions);
    }

    checkFetchReaction() {
        if (this.state.allFetchDoneCount === 2) {
            var comProductIDs = [];
            try {
                comProductIDs = (this.props.complementaryProducts === undefined || this.props.complementaryProducts === null) ? [] : this.props.complementaryProducts.map(o => o.ID)
            } catch (e) { }

            var altProductIDs = [];
            try {
                altProductIDs = (this.props.productAlternatives === undefined || this.props.productAlternatives === null) ? [] : this.props.productAlternatives.map(o => o.ID)
            } catch (e) { }

            var allproductIDs = [...altProductIDs, ...comProductIDs];
            this.getProductReactions(allproductIDs);
        }
    }

    getProductReactions(productIDs) {
        const ProductID = this.props.match?.params?.id;
        setTimeout(() => {
            this.props.fetchProductReaction('similar',
                {
                    productIDs,
                    source: 'similar',
                    sourceData: ProductID
                }
            )
        }, 1000);

        setTimeout(() => {
            this.props.fetchProductReaction('complementary', {
                productIDs,
                source: 'complementary',
                sourceData: ProductID
            })
        }, 2000);

        setTimeout(() => {
            this.props.fetchProductReaction('featured', {
                productIDs,
                source: 'featured',
                sourceData: 'featured'
            })
        }, 3000);

        setTimeout(() => {
            this.props.fetchProductReaction('all_product_blocking', {
                productIDs,
                source: 'all_product_blocking',
                sourceData: 'all_product_blocking'
            })
        }, 4000);
    }

    scrollContent = (e) => {
        e.persist();
        //console.log(e.target.scrollTop, e.target.offsetTop)
    }

    addToCart(item) {
        let id = item.ID;
        if (this.props.evaluationCart == null) {
            this.props.createEvaluationCart(item.ID);
            this.props.showSnackBar("Product added to evaluation", "success", 3000);
        } else {
            // var addedToCart =
            //     this.props.evaluationCart == null
            //         ? []
            //         : this.props.evaluationCart.Products.map((p) => p.ID);
            // if (addedToCart.indexOf(item.ID) !== -1) {
            //     this.props.removeProductFromCart(this.props.evaluationCart.ID, item.ID);
            // } else {
            //     this.props.createEvaluationCart(item.ID, this.props.evaluationCart.ID);
            //     this.props.showSnackBar("Product added to evaluation", "success", 3000);
            // }

            var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.filter((p) => parseInt(p.EntityID) === parseInt(id));
            if (addedToCart && addedToCart.length > 0) {
                this.props.removeProductFromCart(this.props.evaluationCart.ID, parseInt(addedToCart[0].EntityObjectID));
            } else {
                this.props.createEvaluationCart(parseInt(id), this.props.evaluationCart.ID, 'PRODUCT');
            }
        }
    }

    viewMore = (value, state) => {
        const { viewMore } = this.state;
        let values = [...viewMore]
        if (state) {
            const index = values.indexOf(value);
            if (index > -1) {
                values.splice(index, 1);
            }
        } else {
            values.push(value)
        }
        this.setState({
            viewMore: values
        })
    }

    cartOpen = () => {
        this.setState({ isCartShow: true });
    };

    cartClose = () => {
        this.setState({ isCartShow: false });
    };

    addProductToExisting = () => {
        let product = {};
        product.productId = parseInt(this.props.match.params.id || 0);
        product.name = this.props.productDetails?.Name;
        product.isExsting = true;
        this.props.fetchAddOrgProduct(product)
    }

    isShowPricing = () => {
        const { productPrice } = this.props;
        let min = false, max = false;
        min = productPrice?.pricing_range?.min;
        max = productPrice?.pricing_range?.max
        if (
            (productPrice?.plans && size(productPrice?.plans) > 0) ||
            max?.billing ||
            min?.billing ||
            productPrice?.pricing_url ||
            (Array.isArray(productPrice?.models) && productPrice?.models.length > 0)
        ) {
            return true;
        } else {
            return false;
        }
    }

    updateDimensions = () => {
        const headerSectionWidth = this.divElement?.clientWidth;
        if (this.state.headerSectionWidth !== headerSectionWidth) {
            this.setState({ headerSectionWidth });
        }
    };

    redirectTo = (to) => {
        if (to) {
            this.props.history.push(`/app/products/all-products?filter=${to}`)
        } else {
            this.props.history.push(`/app/products/all-products`)
        }
    }

    render() {

        const classes = this.props.classes;

        console.log(this.props, 'Propsss')

        if (this.props.fetchProductDetailsUnderProgress || this.props.fetchProductAlternativesUnderProgress || this.props.fetchCompanyProductsUnderProgress || this.props.fetchComplementaryProductsUnderProgress) {
            return <div style={{ textAlign: 'center', marginTop: 20 }}>
                <CircularProgress />
            </div>
        }

        const { productDetails } = this.props;
        const { viewMore } = this.state;

        var isCart = this.props.evaluationCart !== null;
        var addedToCart =
            this.props.evaluationCart == null
                ? []
                : this.props.evaluationCart.Products.map((p) => p.ID);

        let isCloud = productDetails?.Platforms ? productDetails?.Platforms.filter((item) => (item.Name === 'Cloud' || item.Name === 'On-Premise')) : [];
        let isDesktop = productDetails?.Platforms ? productDetails?.Platforms.filter((item) => (item.Name === 'Windows' || item.Name === 'Mac' || item.Name === 'Web' || item.Name === 'Linux' || item.Name === 'On-Premise' || item.Name === 'Chrome Extension')) : [];
        let isMobile = productDetails?.Platforms ? productDetails?.Platforms.filter((item) => (item.Name === 'Android' || item.Name === 'iPhone/iPad')) : [];

        const { headerSectionWidth } = this.state;
        const isSm = headerSectionWidth >= 768 && headerSectionWidth <= 1200 ? true : false;
        if (this.props.fetchProductDetailsUnderProgress || this.state.is404) {
            return (
                <div>
                    <AppBar title="Product Details" withBack={true} dropdown={true} />
                    <div className={classes.productDetails}>
                        {this.state.is404 && (
                            <div style={{ width: '100%', textJustify: 'center', textAlign: 'center' }}>
                                <p className={classes.productName}>
                                    404
                                </p>
                                <p className={classes.emptyText}>

                                    <span>
                                        <span>Not finding what you are looking for? Try searching based on </span>
                                        <span className={classes.addProductLink} onClick={() => this.redirectTo('features')}>features</span>
                                        <span> or </span>
                                        <span className={classes.addProductLink} onClick={() => this.redirectTo('subcategories')}>sub-categories</span>
                                        <span> or </span>
                                        <span className={classes.addProductLink} onClick={() => this.redirectTo()}>Expent product directory.
                                        </span>
                                    </span>
                                </p>
                            </div>
                        )}
                        {!this.state.is404 && (
                            <CircularProgress />
                        )}
                    </div>
                </div>
            )
        }

        const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForUsersCheck')
        const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForAdminsCheck')
        let isHideEvaluateBtn = false;
        if (((!EnableEvaluateForAdminsCheck && this.props.userRole !== 'OrgUser') || (!EnableEvaluateForUsersCheck && this.props.userRole === 'OrgUser'))) {
            isHideEvaluateBtn = true;
        }

        return (
            <div>
                <AppBarV2 title="Product Details" withBack={true} dropdown={true} />
                <div className={classnames(classes.productDetails, classes.rootContainer)}>
                    <div className={classes.productLeftSection}>
                        <Image src={productDetails?.ProductLogo ? productDetails?.ProductLogo : ''} style={{ maxWidth: '90%' }} />
                        <Grid container justify="center">
                            {!this.props.deleteUnverifiedProductProg && !productDetails?.Verified && this.props.userRole === "OrgAdmin" &&
                                <Button
                                    className={classes.btnDeleteProduct}
                                    onClick={() => this.props.deleteUnVerifiedProduct(productDetails.ID)}
                                >
                                    - Delete Product
                                </Button>
                            }
                            {this.props.deleteUnverifiedProductProg && <CircularProgress size={30} style={{ margin: 10 }} />}
                        </Grid>
                        <ul className={classes.list}>
                            {productDetails?.Description &&
                                <li className={classes.item}><a href="#about" className={this.state.activeNumber === 0 ? 'active' : ''} onClick={() => this.setState({ activeNumber: 0 })}>About {productDetails?.Name}</a></li>
                            }
                            {this.isShowPricing() && (
                                <li className={classes.item}><a href="#pricing" className={this.state.activeNumber === 8 ? 'active' : ''} onClick={() => this.setState({ activeNumber: 8 })}>Pricing Information</a></li>
                            )}
                            {
                                isDesktop && isDesktop.length > 0 &&
                                <li className={classes.item}><a href="#platforms" className={this.state.activeNumber === 1 ? 'active' : ''} onClick={() => this.setState({ activeNumber: 1 })}>Deployment & Platform</a></li>
                            }
                            {
                                productDetails?.Categories?.length > 0 &&
                                <li className={classes.item}><a href="#categories" className={this.state.activeNumber === 2 ? 'active' : ''} onClick={() => this.setState({ activeNumber: 2 })}>Categories</a></li>
                            }
                            {
                                productDetails?.SubCategories?.length > 0 &&
                                <li className={classes.item}><a href="#subcategories" className={this.state.activeNumber === 3 ? 'active' : ''} onClick={() => this.setState({ activeNumber: 3 })}>Sub Categories</a></li>
                            }
                            {
                                productDetails?.Features?.length > 0 &&
                                <li className={classes.item}><a href="#features" className={this.state.activeNumber === 4 ? 'active' : ''} onClick={() => this.setState({ activeNumber: 4 })}>Features offered</a></li>
                            }

                            {
                                this.props.productAlternatives?.length > 0 &&
                                <li className={classes.item}><a href="#alternative" className={this.state.activeNumber === 5 ? 'active' : ''} onClick={() => this.setState({ activeNumber: 5 })}>Similar Products</a></li>
                            }

                            {
                                this.props.companyProducts?.length > 0 &&
                                <li className={classes.item}><a href="#alternative" className={this.state.activeNumber === 6 ? 'active' : ''} onClick={() => this.setState({ activeNumber: 6 })}>Other products by{productDetails?.CompanyName}</a></li>
                            }

                            {
                                this.props.complementaryProducts?.length > 0 &&
                                <li className={classes.item}><a href="#complementary" className={this.state.activeNumber === 7 ? 'active' : ''} onClick={() => this.setState({ activeNumber: 7 })}>Complementary Products</a></li>
                            }
                        </ul>
                    </div>
                    <div className={classes.productRightSection}>
                        <div className={classes.ProductNameSection}>
                            <p className={classes.productName}>{productDetails?.Name}</p>

                            {!isHideEvaluateBtn && productDetails &&
                                <Button
                                    style={addedToCart.indexOf(productDetails.ID) !== -1 ? { borderColor: '#b3b3b3', minWidth: 175 } : { minWidth: 175 }}

                                    onClick={(e) => { this.addToCart(productDetails); }}
                                    className={classes.filterSection}>
                                    {addedToCart.indexOf(productDetails.ID) === -1 ? '+ Evaluate' : '- Evaluate'}
                                </Button>
                            }

                            <Button
                                disabled={productDetails?.IsExisting}
                                onClick={(e) => { this.addProductToExisting() }}
                                className={classes.filterSection}
                                style={productDetails?.IsExisting ? { borderColor: 'rgba(0, 0, 0, 0.26)', minWidth: 175 } : { minWidth: 175 }}
                            >
                                {productDetails?.IsExisting ? '' : '+ '}Existing Product
                            </Button>

                            {this.props.userRole === "OrgAdmin" && Object.keys(this.props.productWishlistedUsers).length > 0 && this.props.productWishlistedUsers.Data.length > 0 && <Typography className={classes.whislistedUsers} onClick={() => {
                                this.props.showProductWhistedUserDialog(productDetails.ID);
                            }}>
                                <FavoriteOutlined className={classes.favLiked} /> &nbsp; {this.props.productWishlistedUsers.Data.length}
                            </Typography>}

                        </div>
                        <a rel="noopener noreferrer" target="_blank" href={productDetails?.ProductUrl} className={classes.productLink}>By {productDetails?.CompanyName}</a>
                        <div className={classes.contentSection} onScroll={(e) => this.scrollContent(e)}>

                            <div className={classes.itemSection} id="about">
                                {productDetails?.Description &&
                                    <>
                                        <p className={classes.head}>About {productDetails?.Name}</p>
                                        <p className={classes.description}>
                                            <ReadMore charLimit={1500}>
                                                {productDetails?.Description}
                                            </ReadMore>
                                        </p>
                                    </>
                                }
                                {productDetails?.ProductUrl &&
                                    <p><a rel="noopener noreferrer" target="_blank" href={productDetails?.ProductUrl} className={classes.productLink}>{productDetails?.ProductUrl}</a></p>
                                }
                            </div>
                            {this.isShowPricing() &&
                                <div className={classes.itemSection} style={{ marginBottom: 40 }} id="pricing">
                                    <PricingSection
                                        productName={productDetails?.Name}
                                        productId={this.props?.match?.params?.id}
                                        title="Pricing Information"
                                    />
                                </div>}
                            {
                                (isDesktop.length || isMobile.length) > 0 &&
                                <div className={classes.itemSection} id="platforms">
                                    <p className={classes.head}>Deployment & Platform</p>
                                    <p className={classes.description}>

                                        <div className={classes.platforms}>
                                            {isDesktop.length && <div className={classes.platform}>
                                                <h5 className={classes.platformTitle} style={{ margin: 0 }}>Desktop</h5>
                                                <ul className={classes.UL}>
                                                    {
                                                        isDesktop.map((item, k) => {
                                                            return (<li key={k} style={{ margin: 0 }}>
                                                                <div className={classes.platformItem}>
                                                                    {item.Name === 'Windows' && <div><Image src={ImgWindows} alt={item.Name} /><span className={classes.platformName}>{item.Name}</span></div>}
                                                                    {item.Name === 'Mac' && <div><Image src={ImgMac} alt={item.Name} /><span className={classes.platformName}>{item.Name}</span></div>}
                                                                    {item.Name === 'Web' && <div><Image src={ImgWeb} alt={item.Name} /><span className={classes.platformName}>{item.Name}</span></div>}
                                                                    {item.Name === 'Linux' && <div><Image src={ImgLinux} alt={item.Name} /><span className={classes.platformName}>{item.Name}</span></div>}
                                                                </div>
                                                            </li>)
                                                        })
                                                    }
                                                </ul>
                                            </div>}



                                            {
                                                isMobile.length > 0 &&
                                                <div className={classes.platform}>
                                                    <h5 className={classes.platformTitle} style={{ margin: 0 }}>Mobile &#38; Tablet</h5>
                                                    <ul className={classes.UL}>
                                                        {
                                                            isMobile.map((item, k) => {
                                                                return (<li key={k} style={{ margin: 0 }}>
                                                                    <div className={classes.platformItem}>
                                                                        {item.Name === 'Android' && <div><Image src={ImgAndroid} alt={item.Name} /><span className={classes.platformName}>{item.Name}</span></div>}
                                                                        {item.Name === 'iPhone/iPad' && <div><Image src={ImgIos} alt={item.Name} /><span className={classes.platformName}>{item.Name}</span></div>}
                                                                    </div>
                                                                </li>)
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            }

                                            {
                                                isCloud.length > 0 &&
                                                <div className={classes.platform}>
                                                    <h5 className={classes.platformTitle} style={{ margin: 0 }}>Deployment</h5>
                                                    <ul className={classes.UL}>
                                                        {
                                                            isCloud.map((item, k) => {
                                                                return (<li key={k} style={{ margin: 0 }}>
                                                                    <div className={classes.platformItem}>
                                                                        {item.Name === 'Cloud' && <div><Image src={ImgCloud} alt={item.Name} /><span className={classes.platformName}>{item.Name}</span></div>}
                                                                        {item.Name === 'On-Premise' && <div><Image src={ImgPremise} alt={item.Name} /><span className={classes.platformName}>{item.Name}</span></div>}                                                                </div>
                                                                </li>)
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            }

                                        </div>

                                    </p>
                                </div>
                            }

                            {
                                productDetails?.Categories?.length > 0 &&
                                <div className={classes.itemSection} id="categories">
                                    <p className={classes.head}>Categories</p>
                                    <p className={classes.description}>
                                        <ul className={classes.UL}>
                                            {
                                                productDetails?.Categories && productDetails?.Categories.slice(0, (viewMore.indexOf('Categories') > -1) ? productDetails?.Categories?.length : 5).map((item, k) => {
                                                    return (<li key={k} style={{ marginRight: '14px' }}>
                                                        <a href='#about' className={classes.linkItem} onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.history.push("/app/products/category-wise-product/" + item?.ID);
                                                        }}>   <span className={classnames(classes.cursorTag, classes.inverse)}>
                                                                {item?.Name}
                                                            </span></a>
                                                    </li>)
                                                })
                                            }
                                            {
                                                productDetails?.Categories && productDetails?.Categories?.length > 5 &&
                                                <li key={'viewMore'} style={{ marginRight: '14px' }}>
                                                    <span className={classnames(classes.tag, classes.view_more)}
                                                        onClick={() => { this.viewMore('Categories', viewMore.indexOf('Categories') > -1) }}>{viewMore.indexOf('Categories') > -1 ? '- View less' : '+ View more'}</span>
                                                </li>
                                            }
                                        </ul>
                                    </p>
                                </div>
                            }

                            {
                                productDetails?.SubCategories?.length > 0 &&
                                <div className={classes.itemSection} id="subcategories">
                                    <p className={classes.head}>Sub Categories</p>
                                    <p className={classes.description}>
                                        <ul className={classes.UL}>
                                            {
                                                productDetails?.SubCategories && productDetails?.SubCategories.slice(0, (viewMore.indexOf('SubCategories') > -1) ? productDetails?.SubCategories.length : 5).map((item, k) => {
                                                    return (<li key={k} style={{ marginRight: '14px' }}>
                                                        <a href='#about' className={classes.linkItem} onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.history.push("/app/products/category-wise-product/" + item?.CategoryID + "?subcategory=" + item?.ID);
                                                        }}>   <span className={classnames(classes.cursorTag, classes.inverse)}>
                                                                {item?.Name}
                                                            </span></a>
                                                        {/* <span className={classnames(classes.tag, classes.inverse)}>{item?.Name}</span> */}
                                                    </li>)
                                                })
                                            }
                                            {
                                                productDetails?.SubCategories && productDetails?.SubCategories.length > 5 &&
                                                <li key={'viewMore'} style={{ marginRight: '14px' }}>
                                                    <span className={classnames(classes.tag, classes.view_more)}
                                                        onClick={() => { this.viewMore('SubCategories', viewMore.indexOf('SubCategories') > -1) }}>{viewMore.indexOf('SubCategories') > -1 ? '- View less' : '+ View more'}</span>
                                                </li>
                                            }
                                        </ul>
                                    </p>
                                </div>
                            }

                            {
                                productDetails?.Features?.length > 0 &&
                                <div className={classes.itemSection} id="features">
                                    <p className={classes.head}>Features offered</p>
                                    <p className={classes.description}>
                                        <ul className={classes.UL}>
                                            {
                                                productDetails?.Features && productDetails?.Features.slice(0, (viewMore.indexOf('Features') > -1) ? productDetails?.Features?.length : 5).map((item, k) => {
                                                    return (<li key={k} style={{ marginRight: '14px' }}>
                                                        <a href='#about' className={classes.linkItem} onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.history.push("/app/products/all-products?searchType=Features&searchValue=" + item?.ID);
                                                        }}>   <span className={classnames(classes.cursorTag, classes.inverse)}>
                                                                {item?.Name}
                                                            </span></a>
                                                        {/* <span className={classnames(classes.tag, classes.inverse)}>{item?.Name}</span> */}
                                                    </li>)
                                                })
                                            }
                                            {
                                                productDetails?.Features && productDetails?.Features.length > 5 &&
                                                <li key={'viewMore'} style={{ marginRight: '14px' }}>
                                                    <span className={classnames(classes.tag, classes.view_more)} onClick={() => { this.viewMore('Features', viewMore.indexOf('Features') > -1) }}>{viewMore.indexOf('Features') > -1 ? '- View less' : '+ View more'}</span>
                                                </li>
                                            }
                                        </ul>
                                    </p>
                                </div>
                            }

                            {/* Alternative products */}
                            {
                                !this.props.fetchProductAlternativesUnderProgress && this.props.productAlternatives && this.props.productAlternatives?.length > 0 &&
                                <>
                                    <div className={classes.itemSection} id="alternative">
                                        <h3 className={classes.head}>Similar Products</h3>
                                        <Grid container spacing={3}
                                            ref={(divElement) => { this.divElement = divElement }}
                                        >
                                            {
                                                this.props.productAlternatives && this.props.productAlternatives?.slice(0, (!this.state.showMoreProducts ? 6 : this.props.productAlternatives.length)).map((product, k) => {
                                                    return (<Grid item lg={isSm ? 3 : 2} md={isCart ? 4 : 3} sm={isCart ? 12 : 6} xs={12} key={"product_" + product.ID} >
                                                        <LayeredCard
                                                            title={product.Name}
                                                            showLike
                                                            typeOfLike="similar"
                                                            showExplanation={product.Explanation?.length > 0 ? true : false}
                                                            explanation={product.Explanation}
                                                            ishoverExplanationToolTip={true}
                                                            autoHideExplanationToolTip={false}
                                                            onHoverExplanationToolTipText="Why am i seeing this?"
                                                            subTitle={product.CompanyName}
                                                            description={product.Description}
                                                            image={product.ProductLogo}
                                                            onClick={() => { }}
                                                            numLayer={0}
                                                            checked={addedToCart.indexOf(product.ID) !== -1}
                                                            showCheckbox={true}
                                                            toogleCheck={() => {
                                                                this.addToCart(product);
                                                            }}
                                                            id={product.ID}
                                                            uniqueId={product.ID + '_u_' + k}
                                                            type={'product'}
                                                            IsExisting={product.IsExisting}
                                                            showReactionResponses
                                                            typeOfBlock="all_product_blocking"
                                                            typeOfFeatured="featured"
                                                            fireEvent
                                                            eventPage={'productDetails'}
                                                            eventData={{
                                                                type: 'event.click.product.section',
                                                                data: {
                                                                    url: this.props.location.pathname + this.props?.location?.search,
                                                                    sectionName: 'Similar Products',
                                                                    product: {
                                                                        id: product.ID,
                                                                        name: product.Name,
                                                                        position: k + 1
                                                                    }
                                                                }
                                                            }
                                                            }
                                                        />
                                                    </Grid>)
                                                })
                                            }
                                        </Grid>
                                        {
                                            this.props.productAlternatives && this.props.productAlternatives.length > 6 &&
                                            <div style={{ textAlign: 'center', padding: '35px 15px' }}>
                                                <span className={classes.show_more} onClick={() => this.setState({ showMoreProducts: !this.state.showMoreProducts })}>
                                                    {
                                                        this.state.showMoreProducts === false ? 'Show more' : 'Show less'
                                                    }
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </>
                            }

                            {/* Company products */}
                            {
                                !this.props.fetchCompanyProductsUnderProgress && this.props.companyProducts && this.props.companyProducts?.length > 0 &&
                                <>
                                    <div className={classes.itemSection} id="alternative">
                                        <h3 className={classes.head}>Other products by {productDetails?.CompanyName}</h3>
                                        <Grid container spacing={3}>
                                            {
                                                this.props.companyProducts && this.props.companyProducts?.slice(0, (!this.state.showMoreCompanyProducts ? 6 : this.props.companyProducts.length)).map((product, k) => {
                                                    return (<Grid item lg={isSm ? 3 : 2} md={isCart ? 4 : 3} sm={isCart ? 12 : 6} xs={12} key={"product_" + product.ID} >
                                                        <LayeredCard
                                                            title={product.Name}
                                                            subTitle={product.CompanyName}
                                                            description={product.Description}
                                                            image={product.ProductLogo}
                                                            onClick={() => { }}
                                                            numLayer={0}
                                                            checked={addedToCart.indexOf(product.ID) !== -1}
                                                            showCheckbox={true}
                                                            toogleCheck={() => {
                                                                this.addToCart(product);
                                                            }}
                                                            id={product.ID}
                                                            uniqueId={product.ID + '_v_' + k}
                                                            type={'product'}
                                                            IsExisting={product.IsExisting}
                                                            fireEvent
                                                            eventPage={'productDetails'}
                                                            eventData={{
                                                                type: 'event.click.product.section',
                                                                data: {
                                                                    url: this.props.location.pathname + this.props?.location?.search,
                                                                    sectionName: 'Other Products',
                                                                    product: {
                                                                        id: product.ID,
                                                                        name: product.Name,
                                                                        position: k + 1
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Grid>)
                                                })
                                            }
                                        </Grid>
                                        {
                                            this.props.companyProducts && this.props.companyProducts.length > 6 &&
                                            <div style={{ textAlign: 'center', padding: '35px 15px' }}>
                                                <span className={classes.show_more} onClick={() => this.setState({ showMoreCompanyProducts: !this.state.showMoreCompanyProducts })}>
                                                    {
                                                        this.state.showMoreCompanyProducts === false ? 'Show more' : 'Show less'
                                                    }
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </>
                            }

                            {
                                !this.props.fetchComplementaryProductsUnderProgress && this.props.complementaryProducts && this.props.complementaryProducts?.length > 0 &&
                                <>
                                    <div className={classes.itemSection} id="complementary">
                                        <h3 className={classes.head}>Complementary Products</h3>
                                        <Grid container spacing={3}>
                                            {
                                                this.props.complementaryProducts && this.props.complementaryProducts?.slice(0, (!this.state.showMoreComplementaryProducts ? 6 : this.props.complementaryProducts.length)).map((product, k) => {
                                                    return (<Grid item lg={isSm ? 3 : 2} md={isCart ? 4 : 3} sm={isCart ? 12 : 6} xs={12} key={"product_" + product.ID} >
                                                        <LayeredCard
                                                            title={product.Name}
                                                            showLike
                                                            typeOfLike="complementary"
                                                            subTitle={product.CompanyName}
                                                            description={product.Description}
                                                            image={product.ProductLogo}
                                                            onClick={() => { }}
                                                            numLayer={0}
                                                            checked={addedToCart.indexOf(product.ID) !== -1}
                                                            toogleCheck={() => {
                                                                this.addToCart(product);
                                                            }}
                                                            showCheckbox={true}
                                                            id={product.ID}
                                                            uniqueId={product.ID + '_w_' + k}
                                                            type={'product'}
                                                            IsExisting={product.IsExisting}
                                                            showReactionResponses
                                                            typeOfBlock="all_product_blocking"
                                                            typeOfFeatured="featured"
                                                            fireEvent
                                                            eventPage={'productDetails'}
                                                            eventData={{
                                                                type: 'event.click.product.section',
                                                                data: {
                                                                    url: this.props.location.pathname + this.props?.location?.search,
                                                                    sectionName: 'Complementary Products',
                                                                    product: {
                                                                        id: product.ID,
                                                                        name: product.Name,
                                                                        position: k + 1
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Grid>)
                                                })
                                            }
                                        </Grid>
                                        {
                                            this.props.complementaryProducts && this.props.complementaryProducts.length > 6 &&
                                            <div style={{ textAlign: 'center', padding: '35px 15px' }}>
                                                <span className={classes.show_more} onClick={() => this.setState({ showMoreComplementaryProducts: !this.state.showMoreComplementaryProducts })}>
                                                    {
                                                        this.state.showMoreComplementaryProducts === false ? 'Show more' : 'Show less'
                                                    }
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            <p className={classes.emptyText} style={{ alignItem: 'center' }} >
                                The information you see in this page is compiled from multiple sources. If you have any feedback, please contact us at <a className={classes.mailTo} href="mailto:sellers@expent.ai">sellers@expent.ai</a>.
                            </p>
                        </div>


                    </div>
                </div>
                {this.props.showProductWhislistedUsersDialog === true && <ProductWhislistedUsersDialog
                    productId={this.props.productDetails.ID}
                />}
            </div>
        );
    }
}

export default connector(compose(withStyles(styles))(ProductDetails));
