import React, { useRef, useEffect } from 'react'
import { createStyles } from "@material-ui/core/styles";

 
const lightGrey ='#EDF2F4';
const grey ='#8D99AE';
const primary ='#08415C';
const strokeWidth = '10';

const styles = (theme) =>
  createStyles({
    root: {

    },
})

const CircleProgress = props => {
  
    const canvasRef = useRef(null)
    
    useEffect(() => {
      
        const can = canvasRef.current
        let c = can.getContext('2d');
    
        var posX = can.width / 2,
        posY = can.height / 2;
        let percent = props.value | 0;

        var label = percent+'%';
        if(props.label !== undefined){
          label = props.label;
        }

        c.clearRect( 0, 0, can.width, can.height );
        let deegres = (360/100) * percent;

        let textString  = label;
        let textWidth = c.measureText(textString ).width;

        c.font = "11px Arial";
        c.textAlign = "start";
        c.fillText(textString, (can.width/2)-(textWidth/2), (can.height/2)+5);
 


        c.beginPath();
        c.arc( posX, posY, (can.width/2 - Math.ceil(can.width/10) - 2), (Math.PI/180) * 270, (Math.PI/180) * (270 + 360) );
        c.strokeStyle = '#F7F7F7';
        c.lineWidth = Math.ceil(can.width/10);
        c.stroke();

        c.beginPath();
        c.strokeStyle = '#63AC67';
        c.lineWidth = Math.ceil(can.width/10);
        c.arc( posX, posY, (can.width/2 - Math.ceil(can.width/10)- 2), (Math.PI/180) * 270, (Math.PI/180) * (270 + deegres) );
        c.stroke();
      
    })
    
    return <canvas ref={canvasRef} width={props.width==undefined?50:props.width} height={props.height==undefined?50:props.height} {...props}/>
  }
  
export default CircleProgress



// https://codepen.io/egorava/pen/wGmmJW?editors=1010