import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid } from '@material-ui/core';
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from 'query-string';

// Redux
import { deleteProductReview } from "redux/product/orgProduct/action";
// Views
import AppBarV2 from "Components/Application/AppBarV2";

// Common
import VendorEvaluationCard from "Components/Common/VendorEvaluationCard"
import deleteIcon from "assets/images/delete.svg";
import Image from 'Components/Common/image';
import classnames from "classnames";
import MessageDialog from "Components/Common/MessageDialog";
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";

import { fetchSurveys, showAddSurveyDialog, hideAddSurveyDialog } from "redux/survey/action";
import AddSurveyDialog from "../../Application/Components/Survey/Components/Common/AddSurveyDialog"
import { showSnackBar } from "redux/snackbar/action";


// Redux
const connectedProps = (state) => (
  {
    user: state.authUser.user,
    deleteProductReviewProgress: state.orgProduct.deleteProductReviewProgress,
    deleteProductReviewError: state.orgProduct.deleteProductReviewError,
    userType: state.authUser.user?.Role,
    fetchSurveysProgress: state.survey.fetchSurveysProgress,
    fetchSurveysError: state.survey.fetchSurveysError,
    surveyDatas: state.survey.surveyDatas,
    updateSurveyProgress: state.survey.updateSurveyProgress,
    updateSurveyError: state.survey.updateSurveyError,
    isShowAddSurveyDialog: state.survey.isShowAddSurveyDialog,
  }
);

const connectionActions = {
  fetchSurveys: fetchSurveys,
  deleteProductReview: deleteProductReview,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  showAddSurveyDialog: showAddSurveyDialog,
  hideAddSurveyDialog: hideAddSurveyDialog,
  showSnackBar: showSnackBar
};

// Styles
const styles = (theme) => createStyles({
  containerVendorEvaluation: {
    height: '100%',
    position: 'relative'
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 9999
  },
  NoMoreData: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  btnReview: {
    width: '38px',
    height: '32px',
    border: 'none',
    borderRadius: '5px',
    background: 'transparent',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '13px',
    color: '#626675',
    marginRight: 10,
    right: '2px',
    position: 'absolute',
    top: 50,
    zIndex: 12
  },
  cardSection: {
    position: 'relative'
  },
  content: {
    padding: theme.spacing(4, 10),
    background: "#F7F7F7",
    minHeight: "100vh"
  },
});


var connector = connect(connectedProps, connectionActions);


class VendorSurveyCards extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 60,
      status: [],
      queryParams: queryString.parse(this.props.location.search),
      deleteId: null
    }
  }

  componentDidMount() {
    this.fetchSurveyDatas(true);
  }

  fetchSurveyDatas = (onInit = false) => {
    let query = Object.assign(this.state)
    query.onInit = onInit
    if (this.props.layout === 'inProgress') {
      query.status = [2, 3]
      query.type = [3]
    } else if (this.props.layout === 'completed') {
      query.status = [4, 5]
      query.type = [3]
    }
    this.props.fetchSurveys(query);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.deleteProductReviewProgress && prevProps.deleteProductReviewProgress) {
      if (!this.props.deleteProductReviewError) {
        const scope = this
        this.setState({ page: 0 }, () => {
          scope.fetchSurveyDatas(true);
        });
      } else {
        this.setState({ error: this.props.deleteProductReviewError });
        setTimeout(() => {
          this.setState({ error: null });
        }, 2000)
      }
    }

    if (this.props.updateSurveyProgress === false && prevProps.updateSurveyProgress === true) {
      if (this.props.updateSurveyError === null) {
        this.props.showSnackBar("Survey successfully updated", "success", 3000);
        this.props.hideAddSurveyDialog();
        this.fetchSurveyDatas();
      } else {
        if (this.props.updateSurveyError.message !== undefined) {
          this.props.showSnackBar("Failed to update survey", "error", 3000);
        } else {
          this.props.showSnackBar("Something went wrong", "error", 3000);
        }
      }
    }

  }

  routeChange = (item, id) => {
    let redirectPath = '';
    if (this.props.user.ID === item.UserID) {
      // this.props.showAddSurveyDialog(false, item);
      redirectPath = `/app/survey/${id}`;
      this.props.history.push(redirectPath);
    } else {
      redirectPath = `/app/evaluate/${id}/workflow/interim`;
      this.props.history.push(redirectPath);
    }
  }

  deleteReview(id) {
    var me = this;
    this.setState({ deleteId: id }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteProductReview(this.state.deleteId);
    }
  };

  render() {
    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let userRole = ["OrgAdmin","OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;

    const surveyDatas = (this.props.surveyDatas && this.props.surveyDatas.Data) || [];
    const dataLength = surveyDatas.length || 0;

    const classes = this.props.classes;

    let products = [];
    if (surveyDatas && Array.isArray(surveyDatas) && surveyDatas.length > 0) {
      surveyDatas.forEach((item, i) => {
        products.push(
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2} key={i} ref={(divElement) => { this.divElement = divElement }} className={classes.cardSection}>
            {adminFlag && <button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={() => this.deleteReview(item.ID)}><Image
              src={deleteIcon}
              alt="forgotpassword_image"
              className={classes.titleImage}
            /></button>}
            <VendorEvaluationCard
              layout={this.props.layout}
              numLayer={1}
              {...item}
              onClick={() => this.routeChange(item, item.ID)}
              users={item.Collaborators}
              queryParams={this.state.queryParams}
              isSurveyCard
            />
          </Grid>
        );
      });
    }

    return <React.Fragment>
      <div className={classes.containerVendorEvaluation}>

        <AppBarV2 title={this.props.title} withBack={true} dropdown={true} />

        <div id="scrollableDiv" className={classes.content}>
          <InfiniteScroll
            dataLength={dataLength}
            style={{ display: "flex", flexDirection: "column", overflow: "hidden", marginRight: 20 }}
            next={() => {
              let scope = this;
              this.setState({ page: this.state.page + 1 }, () => {
                scope.fetchSurveyDatas(false);
              });
            }}
            hasMore={
              surveyDatas.length === (this.state.page + 1) * this.state.pageSize
            }
          >
            <Grid container spacing={3}>
              {products}
            </Grid>
          </InfiniteScroll>
        </div>

        {this.props.fetchSurveysProgress &&
          <span className={classes.loader}><CircularProgress /></span>
        }
        {!this.props.fetchSurveysProgress && surveyDatas.length === 0 &&
          <span className={classes.NoMoreData}>No more data </span>
        }

      </div>
      <MessageDialog messageData={messageData} callBack={this.callBack} />
      {this.props.isShowAddSurveyDialog && <AddSurveyDialog />}
    </React.Fragment>;
  }
}

export default connector(compose(withStyles(styles))(VendorSurveyCards));
