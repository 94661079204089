import {
  START_GET_MASTER_WORKFLOWS, END_GET_MASTER_WORKFLOWS,
  START_GET_BY_ID_MASTER_WORKFLOW, END_GET_BY_ID_MASTER_WORKFLOW,
  START_FETCH_MASTER_WORKFLOW_ACTIONS, END_FETCH_MASTER_WORKFLOW_ACTIONS,
  START_FETCH_MASTER_WORKFLOW_COMPONENTS,END_FETCH_MASTER_WORKFLOW_COMPONENTS,
  START_COPY_MASTER_TO_ORG_WORKFLOW,END_COPY_MASTER_TO_ORG_WORKFLOW,
  START_FETCH_MASTER_WORKFLOW_COMPONENT_RULES,END_FETCH_MASTER_WORKFLOW_COMPONENT_RULES
} from "./action";

const initState = {
  masterWorkFlowsProgress: false,
  masterWorkFlowList: null,
  masterWorkFlowsError: null,

  masterWorkflow: {},
  masterWorkflowError: null,
  masterWorkflowProgress: false,

  actions: [],
  actionsError: null,
  actionsProgress: false,

  components: [],
  componentsError: null,
  componentsProgress: false,

  copyMasterToOrgError: null,
  copyMasterToOrgProgress: false,
  copyMasterToOrgData:null,

  fetchWorkflowConditionRulesError: null,
  fetchMasterWorkflowConditionRulesProgress: false,
  masterWorkflowConditionRules:null
}

export function masterWorkflowReducer(state = initState, action) {
  switch (action.type) {
    case START_GET_MASTER_WORKFLOWS:
      return {
        ...state,
        masterWorkFlowsProgress: true,
        masterWorkFlowList: null,
        masterWorkFlowsError: null
      }
    case END_GET_MASTER_WORKFLOWS: {
      const { success, error } = action.payload
      let masterWorkFlowList = state.masterWorkFlowList
      if (error == null) {
        if (success.page === 0) {
          masterWorkFlowList = success
        } else {
          masterWorkFlowList.Data = [...masterWorkFlowList.Data, ...success.Data]
        }
      }
      return {
        ...state,
        masterWorkFlowsProgress: false,
        masterWorkFlowList,
        masterWorkFlowsError: error
      }
    }

    case START_GET_BY_ID_MASTER_WORKFLOW:
      return {
        ...state,
        masterWorkflow: {},
        masterWorkflowError: null,
        masterWorkflowProgress: true,
      }


    case END_GET_BY_ID_MASTER_WORKFLOW:
      return {
        ...state,
        masterWorkflow: action.payload.success,
        masterWorkflowError: action.payload.error,
        masterWorkflowProgress: false,
      }

    case START_FETCH_MASTER_WORKFLOW_ACTIONS:
      return {
        ...state,
        actions: [],
        actionsError: null,
        actionsProgress: true,
      }

    case END_FETCH_MASTER_WORKFLOW_ACTIONS:
      return {
        ...state,
        actions: action.payload.success,
        actionsError: action.payload.error,
        actionsProgress: false,
      }

      case START_FETCH_MASTER_WORKFLOW_COMPONENTS:
        return {
          ...state,
          components: [],
          componentsError: null,
          componentsProgress: true,
        }
  
      case END_FETCH_MASTER_WORKFLOW_COMPONENTS:
        return {
          ...state,
          components: action.payload.success,
          componentsError: action.payload.error,
          componentsProgress: false,
        }

        case START_COPY_MASTER_TO_ORG_WORKFLOW:
          return {
            ...state,
            copyMasterToOrgError: null,
            copyMasterToOrgProgress: true,
            copyMasterToOrgData:null
          }
    
        case END_COPY_MASTER_TO_ORG_WORKFLOW:
          return {
            ...state,
            copyMasterToOrgError: action.payload.error,
            copyMasterToOrgData: action.payload.success,
            copyMasterToOrgProgress: false,
          }

          case START_FETCH_MASTER_WORKFLOW_COMPONENT_RULES:
            return {
              ...state,
              fetchWorkflowConditionRulesError: null,
              fetchMasterWorkflowConditionRulesProgress: true,
              masterWorkflowConditionRules:null
            }
      
          case END_FETCH_MASTER_WORKFLOW_COMPONENT_RULES:
            return {
              ...state,
              fetchWorkflowConditionRulesError: action.payload.error,
              fetchMasterWorkflowConditionRulesProgress: false,
              masterWorkflowConditionRules:action.payload.success
            }

          

    default:
      return state;
  }
}