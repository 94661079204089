export const UPDATE_DRAWER_STYLE = "UPDATE_DRAWER_STYLE"
export const UPDATE_CART_DRAWER_STYLE = "UPDATE_CART_DRAWER_STYLE"

export function updateDrawerStyle(expanded,show){
    return {
        type:UPDATE_DRAWER_STYLE,
        payload:{expanded,show}
    };
}

export function updateCartDrawerStyle(expanded,show){
    return {
        type:UPDATE_CART_DRAWER_STYLE,
        payload:{expanded,show}
    };
}