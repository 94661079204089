/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {UserFactory} from "../../services/user";

export const START_FORGOTPASSWORD     =   "START_FORGOTPASSWORD";
export const END_FORGOTPASSWORD       =   "END_FORGOTPASSWORD";

//Action to start the registration
function startForgotPassword(){
    return {
        type:START_FORGOTPASSWORD,
        payload:null
    };
}

function endForgotPassword(success,error){
    return {
        type:END_FORGOTPASSWORD,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the forgot password
export  function forgotPassword(email){
    return async (dispatch) => {
        dispatch(startForgotPassword());
        try{
            let [response,error]    =   await UserFactory.instance().forgotPassword(email);
            dispatch(endForgotPassword(response,error));
        } catch(e){
            dispatch(endForgotPassword(null,{
                message:e.message
            }));
            return;
        }
    };
}   