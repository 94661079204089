import {
    Button, Grid, Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import CheckIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import deleteIcon from "assets/images/delete.svg";
import axios from 'axios';
import classnames from "classnames";
import CommonCss from 'commonCss';
import Image from 'Components/Common/image.jsx';
import moment from "moment";
import React from "react";
import CollaboratorPopper from './Common/CollaboratorPopper';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';
import { compose } from "recompose";
import { showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import { fetchComplianceCert, fetchPii } from "redux/master/action";
import { addOrgPii, addOrgSecurityCert, deleteOrgPii, deleteOrgSecurityCert, deleteOrgSecurityCertDoc, fetchOrgPii, fetchOrgRiskPosture, fetchOrgSecurityCert, fetchOrgSecurityCertDoc, hideAddOrgProductChampionsDialog, showAddOrgProductChampionsDialog, updateOrgSecurityCert } from "redux/product/orgProduct/action";
import { showSnackBar } from "redux/snackbar/action";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import CustomSection from "./CustomSection";
import FileAncher from "../FileAncher";

const connectedProps = (state) => ({
    orgUser: state.orgUser,
    complienceCert: state.master.complienceCert,
    addComplienceCertProgress: state.master.addComplienceCertProgress,
    pii: state.master.pii,
    addPiiProgress: state.master.addPiiProgress,
    orgFetchSecurityCert: state.orgProduct.orgFetchSecurityCert,
    orgFetchSecurityCertProgress: state.orgProduct.orgFetchSecurityCertProgress,
    orgFetchSecurityCertDocs: state.orgProduct.orgFetchSecurityCertDocs,
    orgFetchSecurityCertDocProgress: state.orgProduct.orgFetchSecurityCertDocProgress,
    orgAddSecurityCertProgress: state.orgProduct.orgAddSecurityCertProgress,
    orgFetchPii: state.orgProduct.orgFetchPii,
    orgFetchPiiProgress: state.orgProduct.orgFetchPiiProgress,
    orgAddPiiProgress: state.orgProduct.orgAddPiiProgress,
    orgDeletePiiProgress: state.orgProduct.orgDeletePiiProgress,
    orgUpdateSecurityCertProgress: state.orgProduct.orgUpdateSecurityCertProgress,
    orgRiskPosture: state.orgProduct.orgRiskPosture,
    orgRiskPostureProgress: state.orgProduct.orgRiskPostureProgress,
    orgDeleteSecurityCertProgress: state.orgProduct.orgDeleteSecurityCertProgress,
    userType: state.authUser.user?.Role,
});

const connectionActions = {
    fetchComplianceCert: fetchComplianceCert,
    fetchPii: fetchPii,
    fetchOrgPii,
    addOrgPii,
    deleteOrgPii,
    fetchOrgSecurityCert,
    fetchOrgSecurityCertDoc,
    addOrgSecurityCert,
    showSnackBar,
    showAddOrgProductChampionsDialog,
    hideAddOrgProductChampionsDialog,
    fetchOrgRiskPosture,
    deleteOrgSecurityCert,
    updateOrgSecurityCert,
    deleteOrgSecurityCertDoc,
    showSearchUsersPoperDialog: showSearchUsersPoperDialog,
    hideSearchUsersPoperDialog: hideSearchUsersPoperDialog
};

var connector = connect(connectedProps, connectionActions);
let userRole = ["OrgAdmin", "OrgManager"];

const styles = (theme) =>
    createStyles({
        formControl: {
            ...CommonCss.formControl,
        },
        ...CommonCss.ProductDetails,
        tabSection: {
            marginBottom: 30
        },
        positionRelative: {
            position: 'relative',
        },
        formGroup: {
            marginTop: 5,
            position: 'relative'
        },
        label: {
            marginBottom: 5,
            display: 'block'
        },
        creatable: {
            padding: 0
        },
        searchAndSelect: {
            "& .select__control, & .select__control:hover": {
                border: 'none',
                height: 'inherit',
                boxShadow: 'none',
                minHeight: 44,
            },
            "& .select__menu": {
                zIndex: '1100!important',
            },
            // " .Select-menu" : {
            //     position: 'fixed !important',
            //     margin: '1px',
            // },
            "& .select__multi-value, & .select__multi-value__label": {
                background: '#4A87F8',
                color: '#fff',
                borderRadius: 20,
                padding: theme.spacing(.3, .6),
                fontSize: 13
            },
            "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
                background: '#4A87F8',
                color: '#fff',
                borderRadius: 20
            }
        },
        uploadImageLabel: {
            width: '100%',
            height: '100%',
            cursor: 'pointer'
        },
        uploadImage: {
            overflow: 'hidden',
            width: 0,
            position: 'absolute',
            top: 10,
            left: 0,
            opacity: 0,
        },
        threeDot: {
            position: "absolute",
            zIndex: 11,
            right: 5,
            top: 5,
            padding: 5
        },
        listIcon: {
            minWidth: theme.spacing(5),
        },
        menuList: {
            padding: '0px',
            "& > hr": {
                "&:first-child": {
                    display: 'none'
                },
            }
        },
        popover: {
            "& [class*='paper']": {
                boxShadow: "0px 8px 12px #0000001D",
                border: "1px solid #EEEEEE",
                borderRadius: "5px",
                overflow: "inherit",
                "&::before": {
                    top: "50px",
                    left: "-16px",
                    width: "0",
                    borderTop: "8px solid transparent",
                    height: "0px",
                    content: "''",
                    display: "block",
                    position: "absolute",
                    borderBottom: "8px solid transparent",
                    borderLeft: "8px solid transparent",
                    borderRight: "8px solid #fff"
                },
            },
        },
        ...CommonCss.Home,
        iconEle: {
            width: 18,
            marginLeft: 8,
            cursor: 'pointer'

        },
        tagClose: {
            display: 'flex',
            alignItems: 'center',
            padding: '6px 13px'
        },
        addNew: {
            cursor: 'pointer'
        },
        contained: {
            color: '#fff',
            padding: '6px 26px',
            backgroundColor: "#4b86f8",
            "&:hover": {
                backgroundColor: "#4b86f8",
            }
        },
        tickMark: {
            color: "#4b86f8",
            border: "1px solid #4b86f8",
            marginRight: 10,
            minWidth: '50px !important',
            "&:hover": {
                color: "#4b86f8",
            }
        },
        buttonGroup: {
            alignItems: 'center',
            position: 'relative',
            zIndex: 1000
        },
        editIcon: {
            color: "#4A87F8",
            verticalAlign: "middle",
            marginLeft: 6,
            marginBottom: 5,
            cursor: "pointer"
        },
        descriptionText: {
            float: 'right'
        },
        paper: {
            boxShadow: 'none',
            border: '1px solid #ddd'
        },
        viewTable: {
            cursor: 'pointer',
            color: '#4b86f8',
            display: 'inline-block'
        },
        deleteImage: {
            zIndex: 20,
            right: 17,
            padding: 4,
            cursor: 'pointer'
        },
        pagination: {
            float: 'right'
        },
        layeredCard: {
            "& .product_container img": {
                maxWidth: 40
            }
        },
        contractHead: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            overflow: 'hidden',
            marginBottom: 25
        },
        layeredCardHead: {
            marginBottom: 0
        },
        linkText: {
            position: 'relative',
            cursor: 'pointer',
            width: "100%",
            height: '100%'
        },
        resourceLink: {
            display: 'flex',
            alignItems: 'center',
        },
        resourceLinkText: {
            color: '#4b86f8 !important',
            cursor: 'pointer',
            padding: 10
        },
        resourceHead: {
            padding: 10
        },
        tableCell: {
            width: "45%",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 'calc(100vw - 99vw)'
        },
        tabledeleteCell: {
            width: "10%"
        },
        newUserBtn: {
            background: "#3C3C3C",
            borderRadius: 5,
            color: "#fff",
            fontSize: theme.spacing(1.9),
            minHeight: 35,
            padding: 0,
            marginLeft: 15,
            minWidth: 140,
            "&:hover": {
                background: "#3C3C3C",
            },
        },
        newEvaluation: {
            color: '#fff',
            fontSize: '0.95em',
            minWidth: '140px',
            background: '#3C3C3C',
            minHeight: '32px',
            marginRight: '10px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer'
        },
        securityHead: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
        },
        nameCell: {
            width: '20%'
        },
        createAt: {
            minWidth: 200
        },
        resource: {
            width: '80%'
        },
        itemSection: {
            border: '1px solid #d8d8d8',
            padding: 17,
            borderRadius: 5,
            background: '#FFF',
            marginBottom: 17,
            position: 'relative',
            "& [class*=PricingSection-head]": {
                color: '#6C6C6C',
                fontSize: 18,
                opacity: 1,
                fontWeight: 600,
                margin: 0,
                marginBottom: 11
            },
        },
        head: {
            color: '#6C6C6C',
            fontSize: 18,
            opacity: 1,
            fontWeight: 600,
            margin: 0,
            marginBottom: 11,
        },
    });

class SecurityAndPrivacyTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMore: [],
            securityCert: [],
            pii: [],
            isSecurity: false,
            isDataShared: false,
            isBusiness: false,
            filePath: '',
            businessText: 0,
            buisnessCriticalityOption: [
                { label: "High", value: 1 },
                { label: "Medium", value: 2 },
                { label: "Low", value: 3 },
            ],
            linkText: "",
            description: "",
            companyNameText: "",
            anchorEl: null,
        };
        this.btnRef = React.createRef();
        this.addCreateOrgSecurityCert = this.addCreateOrgSecurityCert.bind(this);
    }

    componentDidMount() {
        this.fetchComplianceCert();
        this.fetchPii();
        this.fetchOrgPii();
        this.fetchOrgSecurityCert();

        this.fetchOrgRiskPosture();
    }

    componentDidUpdate(prevProps) {
        if ((!this.props.orgAddSecurityCertProgress && prevProps.orgAddSecurityCertProgress) || (!this.props.orgUpdateSecurityCertProgress && prevProps.orgUpdateSecurityCertProgress) || (!this.props.orgDeleteSecurityCertProgress && prevProps.orgDeleteSecurityCertProgress)) {
            this.props.hideAddOrgProductChampionsDialog();
            this.props.showSnackBar("security certification updated successfully", "success", 3000);
            this.fetchOrgSecurityCert();
        }

        if ((!this.props.orgAddPiiProgress && prevProps.orgAddPiiProgress) || (!this.props.orgDeletePiiProgress && prevProps.orgDeletePiiProgress)) {
            this.props.showSnackBar("Personal data shared updated successfully", "success", 3000);
            this.fetchOrgPii();
        }

        if (!this.props.addComplienceCertProgress && prevProps.addComplienceCertProgress) {
            this.props.hideAddOrgProductChampionsDialog();
            this.props.showSnackBar("Compliance certification added successfully", "success", 3000);
            this.fetchOrgSecurityCert();
        }

        if (!this.props.addPiiProgress && prevProps.addPiiProgress) {
            this.props.hideAddOrgProductChampionsDialog();
            this.props.showSnackBar("Personal data shared added successfully", "success", 3000);
            this.fetchOrgPii();
        }
    }

    fetchOrgRiskPosture = () => {
        this.props.fetchOrgRiskPosture(this.props.match?.params?.id, 0, 200);
    }

    fetchOrgPii = () => {
        this.props.fetchOrgPii(this.props.match?.params?.id)
    }

    addOrgPii = () => {
        if (this.state.pii.length) {
            const piiList = [];

            this.state.pii.map((e) => {
                piiList.push({ piiID: e.value, description: '', piiName: e.label })
            })
            this.props.addOrgPii(this.props.match?.params?.id, { pii: piiList })
            this.setState({ pii: [], isDataShared: false });
        } else {
            this.props.showSnackBar("Please choose value", "error", 3000);
        }
    }

    deleteOrgPii = (item) => {
        this.props.deleteOrgPii(this.props.match?.params?.id, item.ID);
    }

    addCreateOrgPii = (piiName) => {
        const piiList = [];
        piiList.push({ piiID: null, description: '', piiName: piiName })
        this.props.addOrgPii(this.props.match?.params?.id, { pii: piiList })
        this.setState({ pii: [], isDataShared: false });
    }

    fetchOrgSecurityCert = () => {
        this.props.fetchOrgSecurityCert(this.props.match?.params?.id)
        this.props.fetchOrgSecurityCertDoc(this.props.match?.params?.id)
    }

    addOrgSecurityCert = () => {
        if (this.state.securityCert && this.state.securityCert.length) {
            const securityList = [];
            this.state.securityCert.map((e) => {
                if (e.type === 1) {
                    securityList.push({
                        certID: e?.value,
                        certName: "",
                        certificateUrl: '',
                        type: '',
                        description: ''
                    })
                } else {
                    securityList.push({
                        certID: "",
                        certName: e?.label,
                        certificateUrl: '',
                        type: '',
                        description: ''
                    })
                }

            })
            this.props.addOrgSecurityCert(this.props.match?.params?.id, { certificates: securityList })
            this.setState({ securityCert: [], isSecurity: false });
        } else {
            this.props.showSnackBar("Please choose value", "error", 3000);
        }
    }

    addCreateOrgSecurityCert = (certName) => {
        const securityList = [
            {
                certID: "",
                certName: certName,
                certificateUrl: '',
                type: '',
                description: ''
            }
        ];
        this.props.addOrgSecurityCert(this.props.match?.params?.id, { certificates: securityList })
        this.setState({ securityCert: [], isSecurity: false });

    }

    deleteOrgSecurityCert = (item) => {
        this.props.deleteOrgSecurityCert(this.props.match?.params?.id, item.ID);
    }

    fetchComplianceCert = (value = '') => {
        this.props.fetchComplianceCert(value, 0, 50);
    }

    fetchPii = (value) => {
        this.props.fetchPii(value, 0, 50)
    }

    deleteContracts = (item) => {
        this.props.updateOrgSecurityCert(this.props.match?.params?.id, item.ID, {
            certificateUrl: '',
            type: '',
            description: ''
        });

    }

    deleteSecurityCertDoc = (item) => {
        this.props.deleteOrgSecurityCertDoc(this.props.match?.params?.id, item.ID);
    }

    componentWillUnmount() {
    }

    onFileChange = async event => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            var formData = new FormData();
            formData.append("folder", `/orgProduct/dpa/${this.props.match?.params?.id}`);
            const timestamp = (new Date()).getTime();
            formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf('.') + 1)}`);
            const api_server = Environment.api_host("STORAGE");
            const url = `${api_server}/add?t=${timestamp}`;

            axios.post(url, formData, {
                headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
            }).then(response => {
                this.setState({ filePath: response?.data?.filepath });
                document.getElementById('dpaUploadContractFile').value = "";
                const options = {
                    head: "Certifications & Agreements",
                    // isName: true,
                    // namePlaceholder: 'Type',
                    isTitle: true,
                    title: file.name,
                    isDescription: true,
                    descriptionPlaceholder: 'Description',
                    type: 'securityCert',
                    isSecurityCert: true,
                    isSecurityCertRequired: false,
                    securityCertPlacehoder: 'Security certificate',
                    filePath: response?.data?.filepath,
                }
                this.props.showAddOrgProductChampionsDialog(options)
            })
                .catch(err => {
                    if (err.response) {
                        this.props.showSnackBar(err.response.data.error, "error", 3000);
                    } else if (err.request) {
                        this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
                    } else {
                        this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
                    }
                })


        }
    }

    onLink = () => {
        const options = {
            head: "Certifications & Agreements",
            // isName: true,
            // namePlaceholder: 'Type',
            isDescription: true,
            descriptionPlaceholder: 'Description',
            type: 'securityCert',
            isSecurityCert: true,
            isSecurityCertRequired: false,
            securityCertPlacehoder: 'Security certificate',
            isLink: true,
            linkPlaceholder: 'Link*',
            isLinkRequired: true,
        }
        this.props.showAddOrgProductChampionsDialog(options)
    }

    viewMore = (value, state) => {
        const { viewMore } = this.state;
        let values = [...viewMore]
        if (state) {
            const index = values.indexOf(value);
            if (index > -1) {
                values.splice(index, 1);
            }
        } else {
            values.push(value)
        }
        this.setState({
            viewMore: values
        })
    }

    viewEvaluation = (view) => {
        this.props.history.push(`/app/evaluate/${view.evaluationId}`)
    }

    formatFloat = (n) => {
        return n % 1 === 0 ? n : n.toFixed(2);
    }

    getBusinessValue = (value) => {
        return this.state.buisnessCriticalityOption.find(
            (element) => element.value === value
        );
    };

    saveBusiness = () => {
        if (this.state.businessText) {
            this.setState({ isBusiness: false });
            this.setValue({
                ...this.props.productDetails,
                buisnessCriticality: this.state.businessText,
            });
        } else {
            this.props.showSnackBar("Please choose value", "error", 3000);
        }
    };

    setValue = (value) => {
        this.props.updateProducts(value);
        this.setState({ description: "", linkText: "", companyNameText: "" });
    };

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget || event.current });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const classes = this.props.classes;
        const { viewMore } = this.state;
        const { productDetails, userType } = this.props;

        let complienceCert = this.props.complienceCert?.map(m => {
            return {
                value: m.ID,
                label: m.Name,
                type: m.CertType
            }
        });

        let pii = this.props.pii?.map(m => {
            return {
                value: m.ID,
                label: m.Name
            }
        });

        const getConfigValue = (array, key, value) => {
            if (value) {
                let result = '';
                try {
                    result = array.find(element => element[key] === value)['Value'];
                } catch (e) { }
                return result || '';
            }
        }
        const DocumentationFolder = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'SettingName', 'DocumentationFolder')
        return (
            <div>
                {this.props.sections && this.props.sections.length > 0 && this.props.sections.map(section => {
                    const hasWriteAccess = (section.Permission === "write") || (section.Access === 2)
                    if (section.IsDeletable && section.IsEditable) {
                        return (
                            <CustomSection
                                key={section.SectionUID}
                                section={section}
                                isEditable={hasWriteAccess}
                                productID={productDetails.productID}
                            />
                        )
                    }
                    if (section.SectionUID === "prod_business_criticality") {
                        return (<>
                            {(this.props.hideFlagForUser?.prod_business_criticality) && <div className={classes.contentSection}><div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                                <p className={classes.head}>
                                    {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                                    <span>Business Criticality</span>{" "}
                                    {!this.state.isBusiness &&
                                        hasWriteAccess && (
                                            <EditIcon
                                                fontSize={"small"}
                                                className={classes.editIcon}
                                                onClick={() => {
                                                    this.setState({
                                                        isBusiness: true,
                                                        businessText: productDetails?.buisnessCriticality,
                                                    });
                                                }}
                                            />
                                        )}{" "}
                                </p>
                                {!this.state.isBusiness && (
                                    <p className={classes.description}>
                                        {productDetails?.buisnessCriticality <= 0 ?
                                            "No data" : this.getBusinessValue(productDetails?.buisnessCriticality)
                                                ?.label
                                        }
                                    </p>
                                )}
                                {this.state.isBusiness && (
                                    <Grid container spacing={3} className={classes.buttonGroup}>
                                        <Grid item sm={6}>
                                            <div className={classnames(classes.searchAndSelect, classes.nomargin)}>
                                                <CreatableSelect
                                                    isClearable
                                                    className={classnames(
                                                        classes.formControl,
                                                        classes.creatable
                                                    )}
                                                    noOptionsMessage={() =>
                                                        "Start typing to select or create business criticality"
                                                    }
                                                    isSearchable={false}
                                                    options={this.state.buisnessCriticalityOption}
                                                    classNamePrefix="select"
                                                    placeholder="Choose business criticality"
                                                    defaultValue={this.getBusinessValue(
                                                        this.state.businessText
                                                    )}
                                                    onInputChange={(e) => { }}
                                                    onChange={(e) => {
                                                        this.setState({ businessText: e.value });
                                                    }}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Button
                                                className={classes.tickMark}
                                                onClick={() => this.saveBusiness()}
                                            >
                                                <DoneIcon />
                                            </Button>
                                            <Button
                                                className={classes.tickMark}
                                                onClick={() => this.setState({ isBusiness: false })}
                                            >
                                                <CloseIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                            </div>}
                        </>
                        )
                    } else if (section.SectionUID === "prod_security_certification") {
                        return (<>
                            {/* security privacy details */}
                            <div className={classes.contentSection}>
                                {(this.props.hideFlagForUser?.prod_security_certification) && (
                                    <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                                        {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                                        <div className={classes.securityHead}>
                                            <p className={classes.head}> {section.SectionName}</p>
                                            {/* <button className={classes.newEvaluation} onClick={() => { this.props.history.push(`/app/products/product-review/${this.props.match?.params?.id}`) }}>+ New Review</button> */}
                                        </div>
                                        <div>
                                            <ul className={classes.UL}>
                                                {
                                                    this.props.orgFetchSecurityCert && this.props.orgFetchSecurityCert.slice(0, (viewMore.indexOf('orgFetchSecurityCert') > -1) ? this.props.orgFetchSecurityCert?.length : 5).map((item, k) => {
                                                        return (<li key={k} style={{ marginRight: '14px' }}>
                                                            <span className={classnames(classes.tag, classes.tagClose, classes.inverse)}><span>{item?.Name} </span>
                                                                {hasWriteAccess && <CloseIcon className={classes.iconEle} onClick={() => this.deleteOrgSecurityCert(item)} />}
                                                            </span>
                                                        </li>)
                                                    })
                                                }
                                                {
                                                    this.props?.orgFetchSecurityCert && this.props?.orgFetchSecurityCert?.length > 5 &&
                                                    <li key={'viewMore'} style={{ marginRight: '14px' }}>
                                                        <span className={classnames(classes.tag, classes.view_more)}
                                                            onClick={() => { this.viewMore('orgFetchSecurityCert', viewMore.indexOf('orgFetchSecurityCert') > -1) }}>{viewMore.indexOf('orgFetchSecurityCert') > -1 ? '- View less' : '+ View more'}</span>
                                                    </li>
                                                }
                                                {hasWriteAccess && !this.state.isSecurity && <li onClick={() => this.setState({ isSecurity: true })}>
                                                    <span className={classnames(classes.tag, classes.tagClose, classes.addNew)}><span> Add new </span><CheckIcon className={classes.iconEle} /></span>
                                                </li>
                                                }
                                            </ul>
                                            {hasWriteAccess && this.state.isSecurity && <Grid container spacing={3} className={classes.buttonGroup}>
                                                <Grid item sm={6} >
                                                    <div className={classes.searchAndSelect}>
                                                        <CreatableSelect
                                                            isClearable
                                                            className={classnames(classes.formControl, classes.creatable)}
                                                            formatCreateLabel={userInput => this.props.orgFetchSecurityCertProgress ? 'Loading...' : `Create '${userInput}'`}
                                                            noOptionsMessage={() => "Start typing to select or create security certifications"}
                                                            isSearchable={true}
                                                            isMulti
                                                            options={complienceCert}
                                                            classNamePrefix="select"
                                                            placeholder="Choose security certifications"
                                                            onCreateOption={(e) => {
                                                                this.addCreateOrgSecurityCert(e)

                                                            }}
                                                            onInputChange={(e) => {
                                                                this.fetchComplianceCert(e)
                                                            }}
                                                            onChange={(e) => {
                                                                this.setState({ securityCert: e })
                                                            }}
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item sm={6} >
                                                    <Button className={classes.tickMark} onClick={() => this.addOrgSecurityCert()}><DoneIcon /></Button>
                                                    <Button className={classes.tickMark} onClick={() => this.setState({ isSecurity: false })}><CloseIcon /></Button>
                                                </Grid>
                                            </Grid>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                        )
                    } else if (section.SectionUID === "prod_perdonal_data_shared") {
                        return (<>
                            <div className={classes.contentSection}>
                                {(this.props.hideFlagForUser?.prod_perdonal_data_shared) && (
                                    <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                                        {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                                        <p className={classes.head}> {section.SectionName}</p>
                                        <div>
                                            <ul className={classes.UL}>
                                                {
                                                    this.props.orgFetchPii && this.props.orgFetchPii?.slice(0, (viewMore.indexOf('pii') > -1) ? this.props.orgFetchPii?.length : 5).map((item, k) => {
                                                        return (<li key={k} style={{ marginRight: '14px' }}>
                                                            <span className={classnames(classes.tag, classes.tagClose, classes.inverse)}><span>{item?.Name} </span>
                                                                {hasWriteAccess && <CloseIcon className={classes.iconEle} onClick={() => { this.deleteOrgPii(item) }} />}
                                                            </span>
                                                        </li>)
                                                    })
                                                }
                                                {
                                                    this.props.orgFetchPii && this.props.orgFetchPii?.length > 5 &&
                                                    <li key={'viewMore'} style={{ marginRight: '14px' }}>
                                                        <span className={classnames(classes.tag, classes.view_more)}
                                                            onClick={() => { this.viewMore('pii', viewMore.indexOf('pii') > -1) }}>{viewMore.indexOf('pii') > -1 ? '- View less' : '+ View more'}</span>
                                                    </li>
                                                }
                                                {hasWriteAccess && !this.state.isDataShared && <li onClick={() => this.setState({ isDataShared: true })}>
                                                    <span className={classnames(classes.tag, classes.tagClose, classes.addNew)}><span> Add new</span><CheckIcon className={classes.iconEle} /></span>
                                                </li>
                                                }
                                            </ul>
                                            {this.state.isDataShared && <Grid container spacing={3} className={classes.buttonGroup}>
                                                <Grid item sm={6} >
                                                    <div className={classes.searchAndSelect}>
                                                        <CreatableSelect
                                                            isClearable
                                                            isMulti
                                                            className={classnames(classes.formControl, classes.creatable)}
                                                            formatCreateLabel={userInput => this.props.orgFetchPiiProgress ? 'Loading...' : `Create '${userInput}'`}
                                                            noOptionsMessage={() => "Start typing to select or create personal data shared"}
                                                            isSearchable={true}
                                                            options={pii}
                                                            classNamePrefix="select"
                                                            placeholder="Choose personal data shared"
                                                            onCreateOption={(e) => {
                                                                this.addCreateOrgPii(e)
                                                            }}
                                                            onInputChange={(e) => {
                                                                this.fetchPii(e)
                                                            }}
                                                            onChange={(e) => {
                                                                this.setState({ pii: e })
                                                            }}
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}

                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item sm={6}>
                                                    <Button className={classes.tickMark} onClick={() => this.addOrgPii()}><DoneIcon /></Button>
                                                    <Button className={classes.tickMark} onClick={() => this.setState({ isDataShared: false })}><CloseIcon /></Button>
                                                </Grid>
                                            </Grid>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                        )
                    } else if (section.SectionUID === "prod_certification_n_agreement") {
                        return (<>
                            <div className={classes.contentSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                                {(this.props.hideFlagForUser?.prod_certification_n_agreement) && (
                                    <div className={classes.itemSection} id="504">
                                        {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                                        <div className={classes.contractHead}>
                                            <p className={classnames(classes.head, classes.layeredCardHead)}> Certifications & Agreements</p>
                                            {hasWriteAccess && <div style={{ marginRight: '40px' }}>
                                                <span className={classnames(classes.linkText)} onClick={() => this.onLink()}> <Button className={classes.newUserBtn} >
                                                    + Add Link</Button></span>
                                                <Button className={classes.newUserBtn}> <label htmlFor="dpaUploadContractFile" className={classnames(classes.linkText)}>+ Add File <input id="dpaUploadContractFile" className={classes.uploadImage} onChange={this.onFileChange} type="file" /></label> </Button>
                                            </div>}
                                        </div>
                                        <div className={classes.labelDpa}>
                                            <Paper className={classnames(classes.card, classes.paper, this.props.params?.id ? 'details-border' : '')}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classnames(classes.nameCell)}>Name</TableCell>
                                                            <TableCell className={classnames(classes.resource)}><span className={classes.resourceHead}>Resource</span></TableCell>
                                                            <TableCell width="30%" className={classnames(classes.createAt)}>Created At</TableCell>
                                                            <TableCell width="10%"></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.props.orgFetchSecurityCertDocs && this.props.orgFetchSecurityCertDocs.map((item, i) => {
                                                            if (!item.OrgCertURL) {
                                                                return null
                                                            }
                                                            return (
                                                                <TableRow key={i}>
                                                                    <TableCell className={classnames(classes.nameCell)}>
                                                                        {item.Name == null || String(item.Name).trim().length === 0 ? "Other" : item.Name}
                                                                    </TableCell>
                                                                    <TableCell className={classnames(classes.tableCell)}>
                                                                        <div>
                                                                            {item?.OrgCertURL && item?.OrgCertURL.indexOf('devattachments') !== -1 ? <FileAncher
                                                                                fileName={item?.OrgCertTitle || item?.OrgCertURL}
                                                                                fileURL={item?.OrgCertURL}
                                                                            /> :
                                                                                <a
                                                                                    href={item?.OrgCertURL}
                                                                                    className={classes.resourceLinkText}
                                                                                    title={item?.OrgCertDescription}
                                                                                    target="_blank" rel="noreferrer">
                                                                                    {item?.OrgCertURL.indexOf('.expent.ai') > 0 ? (item?.OrgCertTitle !== null && item?.OrgCertTitle.trim().length > 0 ? item?.OrgCertTitle.trim() : 'Download') : item?.OrgCertURL}</a>}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>{moment(item?.CreatedAt).format("DD-MMM-YYYY")}</TableCell>
                                                                    <TableCell> {hasWriteAccess && <Image src={deleteIcon} alt={item.Name} className={classes.deleteImage} onClick={() => this.deleteSecurityCertDoc(item)} />}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                        {
                                                            DocumentationFolder &&
                                                            <TableRow key={this.props.orgFetchSecurityCertDocs.length}>
                                                                <TableCell className={classes.nameCell}>
                                                                    Other
                                                                </TableCell>
                                                                <TableCell className={classnames(classes.tableCell)}>
                                                                    <p
                                                                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '10px', margin: 0 }}
                                                                    ><a style={{ color: '#4A87F8', textDecoration: 'underline' }} href={DocumentationFolder} target="_blank" rel="noreferrer">{DocumentationFolder}</a></p>
                                                                </TableCell>
                                                                <TableCell>{moment(DocumentationFolder?.CreatedAt).format("DD-MMM-YYYY")}</TableCell>
                                                                {/* <TableCell>
                                                                    {hasWriteAccess && <Image src={deleteIcon} alt={DocumentationFolder} className={classes.deleteImage} />}</TableCell> */}
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </Paper>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                        )
                    } else {
                        return null
                    }
                })}
            </div >
        );
    }
}

export default connector(compose(withRouter, withStyles(styles))(SecurityAndPrivacyTab));
