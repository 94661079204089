import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { createStyles, withStyles } from "@material-ui/core/styles";
import FavoriteOutlined from "@material-ui/icons/FavoriteOutlined";
// Asets
import classnames from "classnames";
import CommonCss from "commonCss";
import { CommonFn } from "services/commonFn";
import Image from 'Components/Common/image.jsx';
// import Cart from "Components/Application/Components/Evaluate/Components/Common/Cart";
import ReadMore from "Components/Common/ReadMore";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
// Redux
import { clearStoreState } from "redux/applications/action";
import { hideProductDetailsDialog } from "redux/dialogs/action";
import { updateDrawerStyle } from "redux/drawer/action";
import {
    createEvaluationCart, fetchEvaluationCart, removeProductFromCart
} from "redux/evaluation/cart/action";
import {
    fetchComplementaryProductReaction, fetchProductDetailsPage,
    fetchProductReaction, fetchProductWishListedUsers, fetchWishListedProducts,
    showProductWhistedUserDialog, deleteUnVerifiedProduct
} from "redux/product/action";
import { fetchAddOrgProduct, fetchCompanyProductDetails, fetchComplementaryProducts, fetchOrgProductAlternatives } from 'redux/product/orgProduct/action';
import { showSnackBar } from "redux/snackbar/action";
import { size } from "underscore";
import AppBar from "../../../../AppBar";
import ProductWhislistedUsersDialog from "../../../../../Dialogs/ProductWhislistedUsersDialog";
import { fireEvent } from 'redux/common/action'
import AppBarV2 from "Components/Application/AppBarV2";

const connectedProps = (state) => ({
    fetchProductDetailsUnderProgress: state.products.fetchProductDetailsUnderProgress,
    fetchProductDetailsUnderError: state.products.fetchProductDetailsUnderError,
    productDetails: state.products.productDetails,
    fetchProductAlternativesUnderProgress: state.orgProduct.fetchProductAlternativesUnderProgress,
    productAlternatives: state.orgProduct.productAlternatives,
    fetchCompanyProductsUnderProgress: state.orgProduct.fetchCompanyProductsUnderProgress,
    companyProducts: state.orgProduct.companyProducts,
    evaluationCart: state.evaluationCart.evaluation,

    fetchComplementaryProductsUnderProgress: state.orgProduct.fetchComplementaryProductsUnderProgress,
    complementaryProducts: state.orgProduct.complementaryProducts,

    addOrgProductProgress: state.orgProduct.addOrgProductProgress,
    addorgProductStatus: state.orgProduct.addorgProductStatus,
    fetchProductsReactionProgress: state.products.fetchProductsReactionProgress,
    productPrice: state.orgProduct.productPrice,
    showProductWhislistedUsersDialog: state.products.showProductWhislistedUsersDialog,
    fetchProductWishlistedUsersProgress: state.products.fetchProductWishlistedUsersProgress,
    productWishlistedUsers: state.products.productWishlistedUsers,
    userRole: state.authUser.user?.Role,
    deleteUnverifiedProductProg: state.products.deleteUnverifiedProductProg,
    deleteUnverifiedProductError: state.products.deleteUnverifiedProductError,
    addEventProgress: state.common.addEventProgress
});

const connectionActions = {
    updateDrawerStyle: updateDrawerStyle,
    fetchProductDetails: fetchProductDetailsPage,
    fetchOrgProductAlternatives: fetchOrgProductAlternatives,
    fetchComplementaryProducts: fetchComplementaryProducts,
    fetchCompanyProductDetails: fetchCompanyProductDetails,
    createEvaluationCart: createEvaluationCart,
    removeProductFromCart: removeProductFromCart,
    fetchEvaluationCart: fetchEvaluationCart,
    showSnackBar: showSnackBar,
    hideProductDetailsDialog: hideProductDetailsDialog,
    fetchAddOrgProduct: fetchAddOrgProduct,
    clearStoreState: clearStoreState,
    fetchProductReaction: fetchProductReaction,
    fetchComplementaryProductReaction: fetchComplementaryProductReaction,
    fetchWishListedProducts: fetchWishListedProducts,
    showProductWhistedUserDialog: showProductWhistedUserDialog,
    fetchProductWishListedUsers: fetchProductWishListedUsers,
    deleteUnVerifiedProduct: deleteUnVerifiedProduct,
    fireEvent: fireEvent
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
    createStyles({
        dateTag: {
            color: "#2EB77D",
            fontSize: 12,
            marginTop: 3,
            marginBottom: 0,
        },
        ...CommonCss.ProductDetails,
        rootContainer: CommonCss.rootContainer,
        linkItem: {
            cursor: 'pointer !important'
        },
        cursorTag: {
            fontSize: '12px',
            borderRadius: '25px',
            padding: '10px 14px'
        },
        emptyText: {
            textAlign: 'center',
            height: 50,
            paddingTop: 20
        },
        addProductLink: {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        mailTo: {
            color: '#4A87F8 !important',
            textDecoration: 'underline'
        },
        favLiked: {
            zIndex: 11,
            color: '#f44336'
        },
        whislistedUsers: {
            marginLeft: 20,
            display: 'flex',
            cursor: 'pointer',
            background: '#f1f1f1',
            borderRadius: '25px',
            padding: '5px 10px'
        },
        productLink: {
            ...CommonCss.productLink,
            marginBottom: 0,
        },
        contentSection: {
            marginTop: 15
        }
    });

class HardwareProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is404: false,
            showMoreProducts: false,
            showMoreCompanyProducts: false,
            showMoreComplementaryProducts: false,
            allFetchDoneCount: 0,
            viewMore: [],
            isCartShow: false,
            headerSectionWidth: null,
            sectionDatas: [
                {
                    "FolderName": "Header",
                    "Attributes": [
                        {
                            "Key": "Manufacturer",
                            "Value": "Apple Notebook Systems"
                        },
                        {
                            "Key": "Brand",
                            "Value": "Apple"
                        },
                        {
                            "Key": "Product Line",
                            "Value": "Apple MacBook Pro"
                        },
                        {
                            "Key": "Localization",
                            "Value": "English"
                        },
                        {
                            "Key": "Country Kits",
                            "Value": "United States"
                        },
                        {
                            "Key": "Packaged Quantity",
                            "Value": "1"
                        }
                    ]
                },
                {
                    "FolderName": "System",
                    "Attributes": [
                        {
                            "Key": "Notebook Type",
                            "Value": "Notebook"
                        },
                        {
                            "Key": "Platform",
                            "Value": "Apple Mac OS"
                        },
                        {
                            "Key": "Hard Drive Capacity",
                            "Value": "1 TB"
                        },
                        {
                            "Key": "Introduced",
                            "Value": "Late 2021"
                        },
                        {
                            "Key": "Security Devices",
                            "Value": "Fingerprint reader"
                        }
                    ]
                },
                {
                    "FolderName": "Chassis",
                    "Attributes": [
                        {
                            "Key": "Sensors",
                            "Value": "Ambient light sensor"
                        }
                    ]
                },
                {
                    "FolderName": "Processor",
                    "Attributes": [
                        {
                            "Key": "Type",
                            "Value": "M1 Pro"
                        },
                        {
                            "Key": "Number of Cores",
                            "Value": "10-core"
                        },
                        {
                            "Key": "Manufacturer",
                            "Value": "Apple"
                        },
                        {
                            "Key": "Features",
                            "Value": "16-core Neural Engine"
                        }
                    ]
                },
                {
                    "FolderName": "RAM",
                    "Attributes": [
                        {
                            "Key": "Installed Size",
                            "Value": "16 GB"
                        },
                        {
                            "Key": "Configuration Features",
                            "Value": "Unified memory"
                        }
                    ]
                },
                {
                    "FolderName": "Hard Drive",
                    "Attributes": [
                        {
                            "Key": "Type",
                            "Value": "SSD"
                        },
                        {
                            "Key": "SSD Form Factor",
                            "Value": "Soldered"
                        },
                        {
                            "Key": "Capacity",
                            "Value": "1 TB"
                        }
                    ]
                },
                {
                    "FolderName": "Optical Storage",
                    "Attributes": [
                        {
                            "Key": "Drive Type",
                            "Value": "No optical drive"
                        },
                        {
                            "Key": "Type",
                            "Value": "None"
                        }
                    ]
                },
                {
                    "FolderName": "Card Reader",
                    "Attributes": [
                        {
                            "Key": "Type",
                            "Value": "Card reader"
                        },
                        {
                            "Key": "Supported Flash Memory",
                            "Value": "SDXC Memory Card"
                        }
                    ]
                },
                {
                    "FolderName": "Display",
                    "Attributes": [
                        {
                            "Key": "Type",
                            "Value": "LED"
                        },
                        {
                            "Key": "Touchscreen",
                            "Value": "No"
                        },
                        {
                            "Key": "LCD Backlight Technology",
                            "Value": "Mini LED backlight"
                        },
                        {
                            "Key": "Diagonal Size",
                            "Value": "14.2 inch"
                        },
                        {
                            "Key": "Diagonal Size (metric)",
                            "Value": "36.1 centimetre"
                        },
                        {
                            "Key": "Color Support",
                            "Value": "1 billion colors"
                        },
                        {
                            "Key": "Native Resolution",
                            "Value": "3024 x 1964"
                        },
                        {
                            "Key": "Widescreen Display",
                            "Value": "Yes"
                        },
                        {
                            "Key": "Pixel Density (ppi)",
                            "Value": "254"
                        },
                        {
                            "Key": "V-Sync Rate at Max Res.",
                            "Value": "120 hertz"
                        },
                        {
                            "Key": "Image Brightness",
                            "Value": "1000 candela per square metre"
                        },
                        {
                            "Key": "Bezel Type",
                            "Value": "Flat front"
                        },
                        {
                            "Key": "Monitor Features",
                            "Value": "1600 cd/m² peak brightness (HDR), Arsenic free, Beryllium free, BFR/PVC-free, Extreme Dynamic Range (XDR), Liquid Retina display, Mercury free, P3 Wide Color Gamut, ProMotion technology, True Tone technology"
                        }
                    ]
                },
                {
                    "FolderName": "Video Output",
                    "Attributes": [
                        {
                            "Key": "Graphics Processor",
                            "Value": "Apple M1 Pro 16-core"
                        },
                        {
                            "Key": "Graphics Processor Series",
                            "Value": "Apple M1 Pro"
                        },
                        {
                            "Key": "Max Monitors Supported",
                            "Value": "2"
                        },
                        {
                            "Key": "Video System Features",
                            "Value": "Hardware-accelerated H.264, HEVC, ProRes and ProRes RAW, ProRes encode and decode engine, Video decode engine, Video encode engine"
                        }
                    ]
                },
                {
                    "FolderName": "Digital Camera",
                    "Attributes": [
                        {
                            "Key": "Webcam Capability",
                            "Value": "Yes"
                        },
                        {
                            "Key": "Resolution",
                            "Value": "1080p"
                        },
                        {
                            "Key": "Features",
                            "Value": "Advanced image signal processor (ISP), FaceTime 1080p HD camera"
                        }
                    ]
                },
                {
                    "FolderName": "Audio Output",
                    "Attributes": [
                        {
                            "Key": "Type",
                            "Value": "Six speakers"
                        },
                        {
                            "Key": "Compliant Standards",
                            "Value": "Dolby Atmos"
                        }
                    ]
                },
                {
                    "FolderName": "Audio Input",
                    "Attributes": [
                        {
                            "Key": "Type",
                            "Value": "Three microphones"
                        }
                    ]
                },
                {
                    "FolderName": "Input Device",
                    "Attributes": [
                        {
                            "Key": "Type",
                            "Value": "Force Touch trackpad, Keyboard"
                        },
                        {
                            "Key": "Backlight",
                            "Value": "Yes"
                        },
                        {
                            "Key": "Features",
                            "Value": "Ambient light sensor, Force click, Multi-Touch Gesture Recognition, Multi-touch touchpad, Pressure sensitivity, Touch ID sensor"
                        }
                    ]
                },
                {
                    "FolderName": "Keyboard",
                    "Attributes": [
                        {
                            "Key": "Keyboard Name",
                            "Value": "Magic Keyboard"
                        },
                        {
                            "Key": "Keyboard Localization",
                            "Value": "US"
                        },
                        {
                            "Key": "Keyboard Layout",
                            "Value": "QWERTY"
                        }
                    ]
                },
                {
                    "FolderName": "Networking",
                    "Attributes": [
                        {
                            "Key": "Data Link Protocol",
                            "Value": "Bluetooth 5.0, IEEE 802.11a, IEEE 802.11ac, IEEE 802.11ax (Wi-Fi 6), IEEE 802.11b, IEEE 802.11g, IEEE 802.11n"
                        },
                        {
                            "Key": "Wireless Protocol",
                            "Value": "802.11a/b/g/n/ac/ax, Bluetooth 5.0"
                        }
                    ]
                },
                {
                    "FolderName": "Interfaces",
                    "Attributes": [
                        {
                            "Key": "USB-C Ports Qty",
                            "Value": "3"
                        },
                        {
                            "Key": "HDMI Ports Qty",
                            "Value": "1"
                        },
                        {
                            "Key": "Interfaces",
                            "Value": "3 x USB4 (supports Thunderbolt 4, DisplayPort Alt Mode), HDMI, Headphone output"
                        }
                    ]
                },
                {
                    "FolderName": "OS Provided",
                    "Attributes": [
                        {
                            "Key": "Family",
                            "Value": "macOS"
                        },
                        {
                            "Key": "Edition",
                            "Value": "macOS Monterey 12.0"
                        },
                        {
                            "Key": "Type",
                            "Value": "Apple macOS Monterey 12.0"
                        }
                    ]
                },
                {
                    "FolderName": "Software",
                    "Attributes": [
                        {
                            "Key": "Type",
                            "Value": "App Store, Apple FaceTime, Apple GarageBand, Apple iBooks, Apple iMovie, Apple Keynote, Apple Numbers, Apple Pages, Apple Pay, Apple Preview, Apple QuickTime, Apple Safari, Apple Time Machine, Apple TV, Calendar, Contacts, Dictation, Find My, Home, Mail, Maps, Messages, Music, News, Notes, Photo Booth, Photos, Podcasts, Reminders, Siri, Stocks, Voice Memos"
                        }
                    ]
                },
                {
                    "FolderName": "Power Device",
                    "Attributes": [
                        {
                            "Key": "Power Provided",
                            "Value": "96 watt"
                        },
                        {
                            "Key": "Nominal Voltage",
                            "Value": "AC 120/230 V"
                        },
                        {
                            "Key": "Voltage Required",
                            "Value": "AC 100-240 volt"
                        },
                        {
                            "Key": "Frequency Required",
                            "Value": "50/60 hertz"
                        }
                    ]
                },
                {
                    "FolderName": "Battery",
                    "Attributes": [
                        {
                            "Key": "Technology",
                            "Value": "Lithium polymer"
                        },
                        {
                            "Key": "Capacity",
                            "Value": "70 watt hour(s)"
                        },
                        {
                            "Key": "Run Time (Up To)",
                            "Value": "17 Hours"
                        }
                    ]
                },
                {
                    "FolderName": "Battery Life Details",
                    "Attributes": [
                        {
                            "Key": "Battery Run Time",
                            "Value": "Video playback: up to 17 hours, Web browsing: up to 11 hours"
                        }
                    ]
                },
                {
                    "FolderName": "Miscellaneous",
                    "Attributes": [
                        {
                            "Key": "Color",
                            "Value": "Space gray"
                        },
                        {
                            "Key": "Color Category",
                            "Value": "Gray"
                        },
                        {
                            "Key": "Case Material",
                            "Value": "Aluminum"
                        },
                        {
                            "Key": "Included Accessories",
                            "Value": "USB-C power adapter"
                        },
                        {
                            "Key": "Features",
                            "Value": "Voice Control, VoiceOver screen reader"
                        }
                    ]
                },
                {
                    "FolderName": "Environmental Standards",
                    "Attributes": [
                        {
                            "Key": "ENERGY STAR Certified",
                            "Value": "Yes"
                        }
                    ]
                },
                {
                    "FolderName": "Dimensions & Weight",
                    "Attributes": [
                        {
                            "Key": "Width",
                            "Value": "12.3 inch"
                        },
                        {
                            "Key": "Depth",
                            "Value": "8.7 inch"
                        },
                        {
                            "Key": "Height",
                            "Value": "0.6 inch"
                        },
                        {
                            "Key": "Weight",
                            "Value": "3.53 lbs"
                        }
                    ]
                },
                {
                    "FolderName": "Service & Support",
                    "Attributes": [
                        {
                            "Key": "Type",
                            "Value": "1-year warranty"
                        }
                    ]
                },
                {
                    "FolderName": "Service & Support Details",
                    "Attributes": [
                        {
                            "Key": "Service & Support",
                            "Value": "Limited warranty - 1 year, Technical support - phone consulting - 90 days"
                        }
                    ]
                },
                {
                    "FolderName": "Environmental Parameters",
                    "Attributes": [
                        {
                            "Key": "Min Operating Temperature",
                            "Value": "50 degree Fahrenheit"
                        },
                        {
                            "Key": "Max Operating Temperature",
                            "Value": "95 degree Fahrenheit"
                        },
                        {
                            "Key": "Humidity Range Operating",
                            "Value": "0 - 90% (non-condensing)"
                        }
                    ]
                }
            ]
        };
        this.addToCart = this.addToCart.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.props.hideProductDetailsDialog();
        setTimeout(() => {
            this.props.updateDrawerStyle(false, true)

        }, 100);
        this.fetchProductDetails()
        this.props.fetchWishListedProducts();
        setTimeout(() => {
            this.updateDimensions();
        }, 2000);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.deleteUnverifiedProductProg && prevProps.deleteUnverifiedProductProg) {
            if (this.props.deleteUnverifiedProductError === null) {
                this.props.showSnackBar("Product Successfully Deleted", "success", 3000);
                setTimeout(() => {
                    this.props.history.goBack();
                }, 200)
            } else {
                this.props.showSnackBar("Something went wrong, please try after sometime.", "error", 3000);
            }
        }
        if (!this.props.fetchProductDetailsUnderProgress && prevProps.fetchProductDetailsUnderProgress) {
            if (this.props.fetchProductDetailsUnderError?.message && this.props.fetchProductDetailsUnderError?.message !== null) {
                console.log('fetchProductDetailsUnderError', this.props.fetchProductDetailsUnderError)
                this.setState({ is404: true })
            }
        }

        if (!this.props.fetchProductAlternativesUnderProgress && prevProps.fetchProductAlternativesUnderProgress) {
            let totalCount = this.state.allFetchDoneCount + 1;
            this.setState({
                allFetchDoneCount: totalCount
            }, () => {
                this.checkFetchReaction();

            });
        }

        if (!this.props.fetchComplementaryProductsUnderProgress && prevProps.fetchComplementaryProductsUnderProgress) {
            let totalCounts = this.state.allFetchDoneCount + 1;
            this.setState({
                allFetchDoneCount: totalCounts
            }, () => {
                this.checkFetchReaction();
            });
        }

        if (this.props.match.params.id !== prevProps.match?.params?.id) {
            this.fetchProductDetails()
        }

        this.cartDrawer(prevProps)

        let productId = this.props.addorgProductStatus?.orgProduct?.id || 0;
        if (this.props.addorgProductStatus && this.props.addorgProductStatus) {
            this.props.showSnackBar("Product Successfully Added", "success", 3000);
            this.props.history.push("/app/products/org-products?newProductId=" + productId);
            this.props.clearStoreState({
                'orgProduct.addorgProductStatus': null
            })
        }
    }

    cartDrawer = (preProps) => {
        if ((this.props.evaluationCart && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length) !== (preProps.evaluationCart && preProps.evaluationCart.Products && preProps.evaluationCart.Products.length)) {
            this.setState({ isCartShow: true });
            setTimeout(() => {
                this.setState({ isCartShow: false })
            }, 2000);
        }
    }

    fetchProductDetails = () => {
        this.props.fetchProductDetails(this.props.match?.params?.id)
        this.props.fetchOrgProductAlternatives(this.props.match?.params?.id)
        this.props.fetchCompanyProductDetails(this.props.match?.params?.id)
        this.props.fetchComplementaryProducts(this.props.match?.params?.id)
        this.props.fetchProductWishListedUsers(this.props.match?.params?.id);
    }

    componentWillUnmount() {
        this.props.updateDrawerStyle(true, true)
        window.removeEventListener('resize', this.updateDimensions);
    }

    checkFetchReaction() {
        if (this.state.allFetchDoneCount === 2) {
            var comProductIDs = [];
            try {
                comProductIDs = (this.props.complementaryProducts === undefined || this.props.complementaryProducts === null) ? [] : this.props.complementaryProducts.map(o => o.ID)
            } catch (e) { }

            var altProductIDs = [];
            try {
                altProductIDs = (this.props.productAlternatives === undefined || this.props.productAlternatives === null) ? [] : this.props.productAlternatives.map(o => o.ID)
            } catch (e) { }

            var allproductIDs = [...altProductIDs, ...comProductIDs];
            this.getProductReactions(allproductIDs);
        }
    }

    getProductReactions(productIDs) {
        const ProductID = this.props.match?.params?.id;
        setTimeout(() => {
            this.props.fetchProductReaction('similar',
                {
                    productIDs,
                    source: 'similar',
                    sourceData: ProductID
                }
            )
        }, 1000);

        setTimeout(() => {
            this.props.fetchProductReaction('complementary', {
                productIDs,
                source: 'complementary',
                sourceData: ProductID
            })
        }, 2000);

        setTimeout(() => {
            this.props.fetchProductReaction('featured', {
                productIDs,
                source: 'featured',
                sourceData: 'featured'
            })
        }, 3000);

        setTimeout(() => {
            this.props.fetchProductReaction('all_product_blocking', {
                productIDs,
                source: 'all_product_blocking',
                sourceData: 'all_product_blocking'
            })
        }, 4000);
    }

    scrollContent = (e) => {
        e.persist();
        //console.log(e.target.scrollTop, e.target.offsetTop)
    }

    addToCart(item) {
        if (this.props.evaluationCart == null) {
            this.props.createEvaluationCart(item.ID);
            this.props.showSnackBar("Product added to evaluation", "success", 3000);
        } else {
            var addedToCart =
                this.props.evaluationCart == null
                    ? []
                    : this.props.evaluationCart.Products.map((p) => p.ID);
            if (addedToCart.indexOf(item.ID) !== -1) {
                this.props.removeProductFromCart(this.props.evaluationCart.ID, item.ID);
            } else {
                this.props.createEvaluationCart(item.ID, this.props.evaluationCart.ID);
                this.props.showSnackBar("Product added to evaluation", "success", 3000);
            }
        }
    }

    viewMore = (value, state) => {
        const { viewMore } = this.state;
        let values = [...viewMore]
        if (state) {
            const index = values.indexOf(value);
            if (index > -1) {
                values.splice(index, 1);
            }
        } else {
            values.push(value)
        }
        this.setState({
            viewMore: values
        })
    }

    cartOpen = () => {
        this.setState({ isCartShow: true });
    };

    cartClose = () => {
        this.setState({ isCartShow: false });
    };

    addProductToExisting = () => {
        let product = {};
        product.productId = parseInt(this.props.match.params.id || 0);
        product.name = this.props.productDetails?.Name;
        product.isExsting = true;
        this.props.fetchAddOrgProduct(product)
    }

    isShowPricing = () => {
        const { productPrice } = this.props;
        let min = false, max = false;
        min = productPrice?.pricing_range?.min;
        max = productPrice?.pricing_range?.max
        if (
            (productPrice?.plans && size(productPrice?.plans) > 0) ||
            max?.billing ||
            min?.billing ||
            productPrice?.pricing_url ||
            (Array.isArray(productPrice?.models) && productPrice?.models.length > 0)
        ) {
            return true;
        } else {
            return false;
        }
    }

    updateDimensions = () => {
        const headerSectionWidth = this.divElement?.clientWidth;
        if (this.state.headerSectionWidth !== headerSectionWidth) {
            this.setState({ headerSectionWidth });
        }
    };

    redirectTo = (to) => {
        if (to) {
            this.props.history.push(`/app/products/all-products?filter=${to}`)
        } else {
            this.props.history.push(`/app/products/all-products`)
        }
    }

    render() {

        const classes = this.props.classes;

        console.log(this.state.sectionDatas, 'sectionDatas')

        if (this.props.fetchProductDetailsUnderProgress || this.props.fetchProductAlternativesUnderProgress || this.props.fetchCompanyProductsUnderProgress || this.props.fetchComplementaryProductsUnderProgress) {
            return (<CircularProgress />)
        }

        const { productDetails } = this.props;

        var addedToCart =
            this.props.evaluationCart == null
                ? []
                : this.props.evaluationCart.Products.map((p) => p.ID);



        if (this.props.fetchProductDetailsUnderProgress || this.state.is404) {
            return (
                <div>
                    <AppBar title="Product Details" withBack={true} dropdown={true} />
                    <div className={classes.productDetails}>
                        {this.state.is404 && (
                            <div style={{ width: '100%', textJustify: 'center', textAlign: 'center' }}>
                                <p className={classes.productName}>
                                    404
                                </p>
                                <p className={classes.emptyText}>

                                    <span>
                                        <span>Not finding what you are looking for? Try searching based on </span>
                                        <span className={classes.addProductLink} onClick={() => this.redirectTo('features')}>features</span>
                                        <span> or </span>
                                        <span className={classes.addProductLink} onClick={() => this.redirectTo('subcategories')}>sub-categories</span>
                                        <span> or </span>
                                        <span className={classes.addProductLink} onClick={() => this.redirectTo()}>Expent product directory.
                                        </span>
                                    </span>
                                </p>
                            </div>
                        )}
                        {!this.state.is404 && (
                            <CircularProgress />
                        )}
                    </div>
                </div>
            )
        }

        let allocatedAt = false
        const params = new URLSearchParams(this.props.location.search)
        if (params.get('allocated_at')) {
            allocatedAt = params.get('allocated_at')
        }
        return (
            <div>
                <AppBarV2 title="Product Details" withBack={true} dropdown={true} />
                <div className={classnames(classes.productDetails, classes.rootContainer)}>
                    <div className={classes.productLeftSection}>
                        <Image src={'https://webobjects2.cdw.com/is/image/CDW/6738975a?$product-detail$'} style={{ maxWidth: '90%' }} />
                        <Grid container justify="center">
                            {!this.props.deleteUnverifiedProductProg && !productDetails?.Verified && this.props.userRole === "OrgAdmin" &&
                                <Button
                                    className={classes.btnDeleteProduct}
                                    onClick={() => this.props.deleteUnVerifiedProduct(productDetails.ID)}
                                >
                                    - Delete Product
                                </Button>
                            }
                            {this.props.deleteUnverifiedProductProg && <CircularProgress size={30} style={{ margin: 10 }} />}
                        </Grid>
                        <ul className={classes.list}>
                            {productDetails?.Description &&
                                <li className={classes.item}><a href="#about" className={this.state.activeNumber === 0 ? 'active' : ''} onClick={() => this.setState({ activeNumber: 0 })}>About Apple MacBook Pro</a></li>
                            }
                            {this.state.sectionDatas.map((item, k) => {
                                return <li className={classes.item}><a href={'#' + item.FolderName} className={this.state.activeNumber === k + 1 ? 'active' : ''} onClick={() => this.setState({ activeNumber: k + 1 })}>
                                    {item.FolderName}
                                </a></li>
                            })}
                        </ul>
                    </div>
                    <div className={classes.productRightSection}>
                        <div className={classes.ProductNameSection}>
                            <p className={classes.productName}>Apple MacBook Pro - 14.2</p>

                            {
                                productDetails &&
                                <Button
                                    style={addedToCart.indexOf(productDetails.ID) !== -1 ? { borderColor: '#b3b3b3' } : {}}

                                    onClick={(e) => { 
                                        // this.addToCart(productDetails); 
                                    }}
                                    className={classes.filterSection}>
                                    {addedToCart.indexOf(productDetails.ID) === -1 ? '+ Evaluate' : '- Evaluate'}
                                </Button>
                            }

                            {allocatedAt === false && this.props.userRole === "OrgAdmin" && <Button
                                disabled={false}
                                onClick={(e) => { 
                                    // this.addProductToExisting() 
                                }}
                                className={classes.filterSection}
                                style={productDetails?.IsExisting ? { borderColor: 'rgba(0, 0, 0, 0.26)' } : {}}
                            >
                                + Existing Product
                            </Button>}

                            {allocatedAt && <Button
                                disabled={false}
                                onClick={(e) => { this.addProductToExisting() }}
                                className={classes.filterSection}
                                // style={{borderColor: '#b3b3b3'}}
                            >
                                Return Asset
                            </Button>}

                            {this.props.userRole === "OrgAdmin" && Object.keys(this.props.productWishlistedUsers).length > 0 && this.props.productWishlistedUsers.Data.length > 0 && <Typography className={classes.whislistedUsers} onClick={() => {
                                this.props.showProductWhistedUserDialog(productDetails.ID);
                            }}>
                                <FavoriteOutlined className={classes.favLiked} /> &nbsp; {this.props.productWishlistedUsers.Data.length}
                            </Typography>}

                        </div>
                        <Grid container alignItems="center">
                            <a rel="noopener noreferrer" target="_blank" href={'https://webobjects2.cdw.com/is/image/CDW/6738975a?$product-detail$'} className={classes.productLink}>By Apple</a>
                            &nbsp;&nbsp;
                            {allocatedAt &&
                                <Alert severity="success">Provisioned on {CommonFn.formateDate(allocatedAt)}
                                </Alert>
                            }
                        </Grid>
                        <div className={classes.contentSection} onScroll={(e) => this.scrollContent(e)}>
                            <div className={classes.itemSection} id="about">
                                {productDetails?.Description &&
                                    <>
                                        <p className={classes.head}>About Apple MacBook Pro</p>
                                        <p className={classes.description}>
                                            <ReadMore charLimit={1500}>
                                                MacBook Pro delivers groundbreaking processing, graphics, and machine learning (ML) performance whether running on battery or plugged in, as well as amazing battery life - enabling workflows previously unimaginable on a notebook. It also features a stunning Liquid Retina XDR display, a wide range of ports for advanced connectivity, a 1080p FaceTime HD camera, and great audio system. Shattering the limits of what a notebook can do, MacBook Pro is designed for developers, photographers, filmmakers, 3D artists, scientists, music producers, and anyone who wants a powerful notebook.
                                            </ReadMore>
                                        </p>
                                    </>
                                }
                            </div>

                            {this.state.sectionDatas.map((item, k) => {
                                return <div className={classes.itemSection} id={item.FolderName}>
                                    <p className={classes.head}>{item.FolderName}</p>
                                    <p className={classes.description}>
                                        <div style={{ width: '50%' }}>
                                            {item.Attributes.map((att, k) => {
                                                return <div style={{ display: "flex" }}>
                                                    <div style={{ flex: 1 }}>
                                                        <Typography style={{ fontWeight: 400 }}>{att.Key}</Typography>
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <Typography>{att.Value}</Typography>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </p>
                                </div>
                            })}

                        </div>
                    </div>
                </div>
                {this.props.showProductWhislistedUsersDialog === true && <ProductWhislistedUsersDialog
                    productId={this.props.productDetails.ID}
                />}
            </div>
        );
    }
}

export default connector(compose(withStyles(styles))(HardwareProductDetails));
