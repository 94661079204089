import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppBarV2 from "Components/Application/AppBarV2";
import CommonCss from 'commonCss';
import { CircularProgress, Button } from '@material-ui/core';

import * as Environment from "util/Environment";
import axios from "axios";
import { CommonFn } from "services/commonFn";
import { showSnackBar } from "redux/snackbar/action";

import { getDepartment, getDepartmentApprovers, addDepartmentApprover, updateDepartmentApprover, deleteDepartmentApprover, fetchUserProfileMeta, fetchFieldOptions } from "redux/departments/action"
import Details from "./Details";
import Update from "./Update";

import {
    showMessageDialog,
    hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";

const connectedProps = (state) => ({
    fetchUserMetaProgress: state.departments.fetchUserMetaProgress,
    fetchUserMetaError: state.departments.fetchUserMetaError,
    userProfileMeta: state.departments.userProfileMeta,

    getDepartmentProgress: state.departments.getDepartmentProgress,
    getDepartmentError: state.departments.getDepartmentError,
    departmentData: state.departments.departmentData,

    getDepartmentApproversProgress: state.departments.getDepartmentApproversProgress,
    getDepartmentApproversError: state.departments.getDepartmentApproversError,
    approvers: state.departments.approvers,

    addDepartmentApproverProgress: state.departments.addDepartmentApproverProgress,
    addDepartmentApproverError: state.departments.addDepartmentApproverError,

    deleteDepartmentApproverProgress: state.departments.deleteDepartmentApproverProgress,
    deleteDepartmentApproverError: state.departments.deleteDepartmentApproverError,

    updateDepartmentApproverProgress: state.departments.updateDepartmentApproverProgress,
    updateDepartmentApproverError: state.departments.updateDepartmentApproverError,

    fetchFieldOptionsProgress: state.departments.fetchFieldOptionsProgress,
    fetchFieldOptionsError: state.departments.fetchFieldOptionsError,
    fieldOptions: state.departments.fieldOptions,

});

const connectionActions = {
    getDepartment: getDepartment,
    getDepartmentApprovers: getDepartmentApprovers,
    addDepartmentApprover: addDepartmentApprover,
    updateDepartmentApprover: updateDepartmentApprover,
    deleteDepartmentApprover: deleteDepartmentApprover,
    fetchUserProfileMeta: fetchUserProfileMeta,
    fetchFieldOptions: fetchFieldOptions,
    showSnackBar: showSnackBar,
    showMessageDialog: showMessageDialog,
    hideMessageDialog: hideMessageDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    rootContainer: CommonCss.rootContainer,
    loader: {
        textAlign: 'center',
        marginTop: 30
    },
    topActions: {
        textAlign: 'right',
        marginBottom: 20
    },
    newUserBtn: {
        background: "#3C3C3C",
        borderRadius: 5,
        color: "#fff",
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        minWidth: 140,
        "&:hover": {
            background: "#3C3C3C",
        },
    },
    nameCol: {
        width: '20%'
    },
    actionCol: {
        width: '30%',
        textAlign: 'center'
    },
});

class Department extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            department: '',
            isLoading: true,
            isLoadingApprovers: true,
            approvers: [],
            fieldOptions: [],
            operations: [{
                label: 'in',
                value: 'in'
            }, {
                label: '!in',
                value: '!in'
            }],
            showAddBtn: true,
            deleteId: null
        }
        this.callBack = this.callBack.bind(this);

    }

    componentDidMount() {
        this.props.fetchUserProfileMeta()
        this.fetchData()
        this.fetchApprovers()
    }


    componentDidUpdate(prevProps) {
        if (this.props.getDepartmentProgress === false && prevProps.getDepartmentProgress === true) {
            if (this.props.getDepartmentError === null) {
                this.setState({
                    isLoading: false,
                    department: this.props.departmentData,
                })
            }
        }

        if (this.props.getDepartmentApproversProgress === false && prevProps.getDepartmentApproversProgress === true) {
            if (this.props.getDepartmentApproversError === null) {
                let approvers = this.props.approvers ? this.props.approvers.map((o) => {
                    let conditions = o.Conditions;
                    if (conditions) {
                        conditions.forEach(function (con) {
                            con.Value = con.Value ? JSON.parse(con.Value) : '';
                        })
                    }
                    return {
                        ID: o.ID,
                        Seq: o.Seq,
                        CreatedAt: o.CreatedAt,
                        ApproverID: o.ApproverID,
                        Email: o.Email,
                        Name: o.Name,
                        isNew: false,
                        isEdit: false,
                        Conditions: conditions,
                        error: '',
                        User: {
                            label: o?.Name,
                            value: o?.ApproverID,
                        },
                    }
                }) : [];

                this.setState({
                    isLoadingApprovers: false,
                    showAddBtn: true,
                    approvers: approvers,
                })
            }
        }

        if (this.props.addDepartmentApproverProgress === false && prevProps.addDepartmentApproverProgress) {
            if (this.props.addDepartmentApproverError === null) {
                this.fetchApprovers()
                this.props.showSnackBar('Approver Added Successfully', 'success', 3000)
            } else {
                this.props.showSnackBar('Something went wrong', 'success', 3000)
            }
        }

        if (this.props.updateDepartmentApproverProgress === false && prevProps.updateDepartmentApproverProgress) {
            if (this.props.updateDepartmentApproverError === null) {
                this.fetchApprovers()
                this.props.showSnackBar('Approver Updated Successfully', 'success', 3000)
            } else {
                this.props.showSnackBar('Something went wrong', 'success', 3000)
            }
        }


        if (this.props.deleteDepartmentApproverProgress === false && prevProps.deleteDepartmentApproverProgress) {
            if (this.props.deleteDepartmentApproverError === null) {
                this.fetchApprovers()
                this.props.showSnackBar('Approver Deleted Successfully', 'success', 3000)
            } else {
                this.props.showSnackBar('Something went wrong', 'success', 3000)
            }
        }

    }

    fetchData() {
        this.props.getDepartment(this.props.match.params.id)
    }

    fetchApprovers() {
        this.setState({
            isLoadingApprovers: true
        }, () => {
            this.props.getDepartmentApprovers(this.props.match.params.id)
        })
    }

    addNewApprover = () => {
        let approvers = this.state.approvers;
        approvers.forEach(function (approver, key) {
            approver.isEdit = false;
        })
        let newApprover = {
            Conditions: approvers.length <= 0 ? null : [{ Field: '', Operation: '', Value: '' }],
            User: '',
            isNew: true,
            isEdit: true,
            error: '',
        }
        approvers.push(newApprover);

        this.setState({
            approvers: approvers,
            showAddBtn: false
        })
    }

    getFieldOptions(field, index) {
        if (field) {
            this.setState({
                selectedFieldForOptions: index
            }, () => {
                this.props.fetchFieldOptions(field)
            })
        }
    }

    handleUpdateValue(field, res, index) {
        let approvers = this.state.approvers;
        let approver = approvers[index];
        approver[field] = res ? res.value : ''
        if (field === 'Field') {
            approver.Operation = ''
            approver.Value = ''
            this.getFieldOptions(res.value, index)
        }
        this.setState({ approvers: approvers })
    }

    handleUpdateUserValue(field, value, index) {
        let approvers = this.state.approvers;
        let approver = approvers[index];
        approver[field] = value
        if (field === 'Field') {
            approver.Operation = ''
            approver.Value = ''
            this.getFieldOptions(value, index)
        }
        this.setState({ approvers: approvers })
    }


    handleEdit(aData, index) {
        let approvers = this.state.approvers;
        approvers.forEach(function (approver, key) {
            if (key === index) {
                approver.isEdit = true;
            } else {
                approver.isEdit = false;
            }
        })
        this.setState({ approvers: approvers })
    }

    handleAddApprover(approver) {
        if (approver.User === undefined || approver.User === null || approver.User === '') {
            this.props.showSnackBar('Please select approver.');
            return;
        }

        let params = {
            "approver_id": approver.User?.value,
            "field": approver.Field,
            "operation": approver.Operation,
            "value": approver.Value
        }

        this.props.addDepartmentApprover(this.props.match.params.id, params)
    }


    handleDelete(approver, index) {
        var me = this;
        if (approver.isNew) {
            let approvers = this.state.approvers;
            approvers.splice(index, 1);
            this.setState({
                approvers: approvers
            })
        } else {
            this.setState({ deleteId: approver?.ApproverID }, () => {
                me.props.showMessageDialog();
            })
        }
    }


    handleApproverUpdate(task) {
        console.log(task)
    }

    getUsersOption = async (inputValue, loadedOptions, additional) => {
        var page = 0;
        if (additional !== undefined && additional.page !== undefined) {
            page = additional.page;
        }
        const api_server = Environment.api_host("USER");
        const timestamp = new Date().getTime();
        if (!inputValue) {
            let appList = [];
            this.props.users?.forEach((item, index) => {
                appList.push({
                    label: item.Name,
                    value: item.ID,
                    id: item.ID,
                    ID: item.ID,
                    Name: item.Name,
                    Email: item.Email,
                })
            })
            return {
                options: appList,
                hasMore: false,
                additional: {
                    page: 0,
                },
            };
        }
        let formData = {
            page: 0,
            pageSize: 10,
            query: inputValue
        };
        let url = `${api_server}/fetch?t=${timestamp}`;
        const response = await axios.post(url, formData, {
            headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
        });
        let apps = response?.data?.Data || [];
        let total = response?.data?.Data?.total || 0;
        apps = apps.map((m) => ({
            label: m.Name,
            value: m.ID,
        }));
        return {
            options: apps,
            hasMore: (page + 1) * 10 < total ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };

    handleRemoveNewItem(index) {

    }

    handleDisableEdit = (index) => {
        let approvers = [...this.state.approvers];
        let approver = approvers[index];
        if (approver.isNew) {
            approvers.splice(index, 1);
        } else {
            approver.isEdit = false
        }
        this.setState({
            approvers: approvers,
            showAddBtn: true
        })
    }

    callBack() {
        if (this.state.deleteId) {
            this.props.hideMessageDialog();
            this.props.deleteDepartmentApprover(this.props.match.params.id, this.state.deleteId);
        }
    }

    handleAddUpdate(approver, condition) {
        console.log(approver, 'Approverrrrrrr')
        console.log(condition, 'Conditionnnnnnnnnn')
        let params = {
            "approver_id": approver.User?.value,
            "conditions": condition
        }
        if (approver.isNew) {
            this.props.addDepartmentApprover(this.props.match.params.id, params)
        } else {
            this.props.updateDepartmentApprover(this.props.match.params.id, approver.ID, params)
        }
    }

    render() {
        const { classes } = this.props;
        const { isLoading, department, approvers, isLoadingApprovers, showAddBtn } = this.state;

        let messageData = {
            head: "Are you sure?",
            subHead: "Do you really want to delete? This process cannot be undone.",
            firstBtn: "Cancel",
            secondBtn: "Yes",
        };

        console.log(approvers, 'approverssss')
        console.log(showAddBtn, 'showAddBtn')
        if (isLoading) {
            return <div className={classes.loader}>
                <CircularProgress />
            </div>
        }
        return (<div>
            <AppBarV2 title={department?.Name || "Department"} withBack={true} />
            <div className={classes.rootContainer}>
                {showAddBtn && <div className={classes.topActions}>
                    <Button className={classes.newUserBtn} onClick={() => {
                        this.addNewApprover()
                    }}>
                        + Add Approver
                    </Button>
                </div>}
                <div className={classes.body}>
                    {!isLoadingApprovers && approvers.map((approver, index) => {
                        if (!approver.isEdit) {
                            return <Details
                                key={index}
                                approver={approver}
                                conditions={approver?.Conditions}
                                handleDelete={() => this.handleDelete(approver, index)}
                                handleEdit={() => this.handleEdit(approver, index)}
                                approvers={approvers}
                            />;
                        } else {
                            return <Update
                                key={index}
                                approver={approver}
                                conditions={approver?.Conditions}
                                addUpdateApprover={(aprvr, cndtn) => {
                                    this.handleAddUpdate(aprvr, cndtn)
                                }}
                                openInNonEdit={() => this.handleDisableEdit(index)}
                            />;
                        }
                    })}

                    {!isLoadingApprovers && approvers.length <= 0 && <div className={classes.loader}>
                        No Approvers
                    </div>}
                </div>
            </div>
            <MessageDialog messageData={messageData} callBack={this.callBack} />
        </div>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Department));
