import { CommonFn } from 'services/commonFn';

export function getMessage(Data = {}, user = {}) {
  // console.log(Data,'NotificatioooonDataaa')
  var surveyURL = user?.Role === "OrgAdmin" ? `/app/survey/${Data?.data?.evaluation?.id}` : `/app/survey/${Data?.data?.evaluation?.id}/take-survey`;

  var flowType = "evaluation";
  if (Data.data !== undefined && Data.data.evaluation !== undefined && Data.data.evaluation !== null && Data.data.evaluation.type !== undefined && Data.data.evaluation.type !== null) {
    if (Data.data.evaluation.type === 2) {
      flowType = "review";
    }
    if (Data.data.evaluation.type === 3) {
      flowType = "survey";
    }
  }

  if (Data?.type === "notification.evaluation.create") {
    return {
      message: Data?.data?.evaluation?.name + " " + flowType + " is created by " + Data?.data?.actor?.name,
      user: Data?.data?.actor,
      redirectURL: flowType === "evaluation" || flowType === "review" ? `/app/evaluate/${Data?.data?.evaluation?.id}/workflow/interim` : surveyURL,
    };
  } else if (Data?.type === "notification.evaluation.pre-feedback.submit") {
    return {
      message: "Pre Evaluation feedback added by " + Data?.data?.actor?.name + " for evaluation.",
      user: Data?.data?.actor,
      redirectURL: `/app/evaluate/${Data?.data?.evaluation?.id}/workflow/interim`,
    };
  } else if (Data?.type === "notification.evaluation.colloborator.add") {
    return {
      message: "Collaborator added by " + Data?.data?.actor?.name + " for " + flowType + " " + Data?.data?.evaluation?.name,
      user: Data?.data?.actor,
      redirectURL: flowType === "evaluation" || flowType === "review" ? "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true" : surveyURL,
    };
  } else if (Data?.type === "notification.evaluation.criteria.add") {
    return {
      message: "New section " + Data?.data?.criteria?.name + " added by " + Data?.data?.actor?.name + " for " + flowType + " " + Data?.data?.evaluation?.name,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.criteria.update") {
    return {
      message: "Criteria " + Data?.data?.criteria?.name + " added by " + Data?.data?.actor?.name + " for " + flowType + " " + Data?.data?.evaluation?.name,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.criteria.delete") {
    return {
      message: "Criteria " + Data?.data?.criteria?.name + " deleted by " + Data?.data?.actor?.name + " for " + flowType + " " + Data?.data?.evaluation?.name,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.criteria.colloborator.add") {
    return {
      message: "New Collaborator added by " + Data?.data?.actor?.name + " for section " + Data?.data?.criteria?.name + " in " + flowType + " " + Data?.data?.evaluation?.name,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.criteria.approval.add") {
    return {
      message: "Approver added by " + Data?.data?.actor?.name + " for section " + Data?.data?.criteria?.name + " in " + flowType + " " + Data?.data?.evaluation?.name,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.requierement.add") {
    return {
      message: "New question " + Data?.data?.requirement?.name + " added by " + Data?.data?.actor?.name + " for " + flowType + " " + Data?.data?.evaluation?.name,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.requierement.update") {
    return {
      message: "Question " + Data?.data?.requirement?.name + " updated by " + Data?.data?.actor?.name + " for " + flowType + " " + Data?.data?.evaluation?.name,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.requirement.delete") {
    return {
      message: "Question " + Data?.data?.requirement?.name + " deleted by " + Data?.data?.actor?.name + " for " + flowType + " " + Data?.data?.evaluation?.name,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.requirement.colloborator.add") {
    return {
      message: "New Collaborator added by " + Data?.data?.actor?.name + " for question " + Data?.data?.requirement?.name + " in " + flowType + " " + Data?.data?.evaluation?.name,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.colloborator.nudge") {
    return {
      message: Data?.data.message,
      user: Data?.data?.actor,
      redirectURL: flowType === "evaluation" || flowType === "review" ? "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true" : surveyURL,
    };
  } else if (Data?.type === "notification.evaluation.criteria.colloborator.nudge") {
    return {
      message: Data?.data.message,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.criteria.approver.nudge") {
    return {
      message: Data?.data.message,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.requirement.colloborator.nudge") {
    return {
      message: Data?.data.message,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.product.colloborator.nudge") {
    return {
      message: Data?.data.message,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.requirement.response.reminder") {
    return {
      message: Data?.data.message,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  } else if (Data?.type === "notification.evaluation.criteria.approver.reminder") {
    return {
      message: Data?.data.message,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/setup?isNotification=true",
    };
  }else if (Data?.type === "notification.evaluation.criteria.approver.response.nudge") {
    return {
      message: Data?.data.message,
      user: Data?.data?.actor,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id,
    };
  } else if (Data?.type === "notification.org.product.add") {
    return {
      message: "New Product " + Data?.data?.product?.name + " added by " + Data?.data?.actor?.name,
      user: Data?.data?.actor,
      redirectURL: "/app/products/org-product-details/" + Data?.data?.product?.id,
    };
  } else if (Data?.type === "notification.evaluation.requirement.seller.message") {
    let seller = Data?.data?.sellerName || "Vendor";
    let user = {name: seller};
    return {
      message: seller + " has messaged you on " + Data?.data?.requirement?.name + " in " + Data?.data?.evaluation?.name,
      user: user,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/workflow/" + Data?.data?.workflowId + "/component/" + Data?.data?.componentId + "?requirementId=" + Data?.data?.requirement?.id+ "#seller",
    };
  }else if (Data?.type === "notification.evaluation.requirement.seller.response") {
    let seller = Data?.data?.sellerName || "Vendor";
    let user = {name: seller};
    return {
      message: seller + " has responded on " + Data?.data?.requirement?.name + " in " + Data?.data?.evaluation?.name,
      user: user,
      redirectURL: "/app/evaluate/" + Data?.data?.evaluation?.id + "/workflow/" + Data?.data?.workflowId + "/component/" + Data?.data?.componentId + "?requirementId=" + Data?.data?.requirement?.id+ "#seller",
    };
  } else if (Data?.type === "notification.org.product.renewal") {
    let date = CommonFn.formateDate(Data?.data?.product?.renewalDate, true)
    return {
      message: "Renewal date for " + Data?.data?.product?.name + " is " + date,
      redirectURL: "/app/renewal",
    };
  }

  return {
    message: Data?.type,
    user: null,
  };
}

// notification.evaluation.create
// notification.evaluation.pre-feedback.submit
// notification.evaluation.colloborator.add
// notification.evaluation.criteria.add
// notification.evaluation.criteria.delete
// notification.evaluation.criteria.update
// notification.evaluation.criteria.colloborator.add
// notification.evaluation.criteria.approval.add
// notification.evaluation.requierement.add
// notification.evaluation.requirement.update
// notification.evaluation.requirement.delete

// notification.evaluation.requirement.colloborator.add

// pranit has responded to you request
