import React, { useEffect, useState } from "react";
import {
  makeStyles,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/delete.svg";
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import ReactSelect from "react-select";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import DatePicker from "react-datepicker";
import CalendarImage from "assets/images/calendar.svg";
import {  AddCircle as AddCircleIcon } from '@material-ui/icons';
import * as Validator from "util/Validation";
import classnames from "classnames";
import Alert from '@material-ui/lab/Alert';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { useParams } from 'react-router-dom';
import moment from 'moment';


const workflowRoleStyle = makeStyles((theme) => ({
  indexNumber: {
    width: 10,
    marginTop: 5,
  },
  inputSection: {
    display: "flex",
    marginTop: 10,
    justifyContent: "space-between",
  },
  requiredField: {
    color: "red",
    marginLeft: 5,
  },
  inputController: {
    marginLeft: 5,
    flex: 1,
    marginRight: 5,
    minWidth: 206
  },
  image: {
    marginTop: 5,
    minWidth: 10,
    cursor: "pointer",
  },
  saveBtnSection: {
    position: "absolute",
    bottom: 10,
    right: 12,
    left: "auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: '#f1f1f1',
    padding: '10px'
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  addMore: {
    fontSize: "12px",
    color: "#4175DF",
    cursor: "pointer",
    display: "inline-block",
    marginTop: 10,
  },
  input: {
    minHeight: 38,
    padding: "2px 8px",
    border: "1px solid #cccccc",
    borderRadius: 3,
    fontSize: 15,
    color: "rgb(51, 51, 51)",
    width: '100%'
  },
  operand: {
    minWidth: 87,
  },
  ruleTable: {
    width: "100%",
    '& [class*=MuiSelect-root]': {
      minHeight: 22
     }
  },
  ruleRightElement: {
  },
  ruleLeftElement: {
  },
  condition: {
    marginTop: 8,
    fontSize: 14,
    fontWeight: 500
  },
  op2:{
    width:"15%",
    '& [class*=MuiInputBase-formControl]':{
      width: 79,
      marginRight: 10,
    },
  },
  op1:{
    width:"15%",
    marginRight: 10,
    '& [class*=MuiInputBase-formControl]':{
      width: 79,
      marginRight: 10,
    }
  },
  leftOp:{
    width:"30%",
    paddingRight: 10,
  },
  rightOp:{
    width:"30%",
    "& [class*=select__menu]": {
      maxHeight: 100,
    },
  },
  action: {
    width: '12%',
    textAlign: 'right'
  },
  alerts:{
    marginTop:theme.spacing(2),
    marginBottom:theme.spacing(2)
  },
  ruleHead: {
    fontSize: 16,
    fontWeight: 500
  },
  "@global": {
    '.react-datepicker__input-container > div': {
        margin: 0,
    },
    '.react-datepicker__input-container [class*=MuiInputBase-formControl]':{
      minHeight: 39,
      margin: 0
    },
    '.react-datepicker__input-container [class*=MuiOutlinedInput-input]': {
      padding: 0,
      fontSize: 14,
      paddingLeft: 10,
      color: 'rgb(51, 51, 51)'
    }
},
newUserBtnDisabled:{
  background: "#3C3C3C",
  borderRadius: 5,
  color: "#fff",
  fontSize: theme.spacing(1.9),
  minHeight: 32,
  minWidth: 140,
  border: "none",
  cursor: "no-drop",
  "&:hover": {
    background: "#3C3C3C",
  },
},
}));

export const WorkFlowRule = ({
  formComponentList = [],
  submitData = () => {},
  orgWorkflowActionRuleElements = [],
  workflowStatus=null,
}) => {
  const params = useParams();
  const classes = workflowRoleStyle();
  const [ruleElement, setRuleElement] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (formComponentList && formComponentList.length > 0 && orgWorkflowActionRuleElements?.length === 0) {
      setRuleElement(() => [{
        op2: '1',
        leftOp: null,
        op1: '3',
        rightOP: null,
        object:{}
      }]);
    } else if(formComponentList.length === 0) {
      setRuleElement(() => [])
    } else if(orgWorkflowActionRuleElements &&  orgWorkflowActionRuleElements?.length && formComponentList.length > 0 ) {
      const elements = [];
      orgWorkflowActionRuleElements.forEach((element) => {
          if(element.OpID === 1 || element.OpID === 2) {
            elements[elements.length - 1].op2 =  element.OpID;
          } else {
            elements.push({leftOp: getLeftExpression(element.LeftExpression, formComponentList), op1: element.OpID,  op2: '', rightOP: getRightExpression(element.LeftExpression, element.RightExpression, formComponentList)})
          }
      });
      setRuleElement(() => elements);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formComponentList, orgWorkflowActionRuleElements,workflowStatus]);


  const getLeftExpression = (value, array) => {
    const leftObj = array.find((item) => item.Name === value) 
    if(leftObj) {
      return {
        value: leftObj.Name,
        label: leftObj.Label,
        type: leftObj.Type,
        data: leftObj.Data && Validator.isJson(leftObj.Data) && JSON.parse(leftObj.Data)
      }
    }
    return null;
  }

  const getRightExpression = (leftValue = '', value = '', array = []) => {
   
    const leftObj = getLeftExpression(leftValue, array);
    if(leftObj === null){
      return;
    }
    console.log(leftObj, value, 'value')
    if(leftObj.type === 'Text' || leftObj.type === 'TextArea' || leftObj.type === 'Date' || leftObj.type === 'Boolean') {
      return value;
    } else if(leftObj.type === 'SINGLE_SELECT') {
      if(leftObj.data) {
        return leftObj.data.find((item) => item.value === value) 
      }
    } else if(leftObj.type === 'MULTI_SELECT') {
      if(leftObj.data) {
        const convertedValue = Validator.isJson(value) ? JSON.parse(value) : []
        return leftObj.data.filter((item) => ( Array.isArray(convertedValue) && convertedValue.indexOf(item.value) !== -1));;
      }
    }

    return null;
  }

  const deleteRuleElement = (index) => {
    setRuleElement((fields) => fields.filter((value, i) => i !== index));
  };

  const addNewRuleElement = () =>{
    setRuleElement((rule) => ([
      ...rule, 
      {
      "op2":'1',
      "leftOp":null,
      "op1": '3',
      "rightOP":null
    }]))
  }

  const operand = [
    {
      value: 3,
      label: "===",
    },
    {
      value: 4,
      label: "in",
    },
    {
      value: 5,
      label: ">",
    },
    {
      value: 6,
      label: ">=",
    },
    {
      value: 7,
      label: "<",
    },
    {
      value: 8,
      label: "<=",
    },
    {
      value: 9,
      label: "!in",
    },
    {
      value: 10,
      label: "!=",
    },
  ];

  let requirementOptions = [];
  if(formComponentList?.length > 0) {
    requirementOptions = formComponentList?.map((item)=>{
      return {
          value: item.Name,
          label: item.Label,
          type: item.Type,
          data: item.Data && Validator.isJson(item.Data) && JSON.parse(item.Data)
      }
    })
  }

  const onLeftChange = (k, e) => {
    var rule = ruleElement[k];
    rule.leftOp = e;
    rule.rightOP = null
    ruleElement[k] = rule;
    setRuleElement((fields) => fields.map((value, i) => {
      if(i === k) {
        return rule;
      }
      return value;
    }))
  }

  const onRightChange = (k, e) => {
    var rule = ruleElement[k];
    rule.rightOP = e
    ruleElement[k] = rule;
    setRuleElement((fields) => fields.map((value, i) => {
      if(i === k) {
        return rule;
      }
      return value;
    }))
  }

  const operand2Change = (k, e) => {
    var rule = ruleElement[k];
    rule.op2 = e.target.value;
    ruleElement[k] = rule;
    setRuleElement((fields) => fields.map((value, i) => {
      if(i === k) {
        return rule;
      }
      return value;
    }))
  }

  const operad1Change = (k, e) => {
    var rule = ruleElement[k];

    if(e.target?.value) {
      rule.op1 = e.target.value.toString();
    } else if(!e.target?.value) {
      rule.op1 = '';
    }

    ruleElement[k] = rule;
    setRuleElement((fields) => fields.map((value, i) => {
      if(i === k) {
        return rule;
      }
      return value;
    }))
  }

  const saveRule = () => {
      let seq = 1;

      setSuccess(() => '');
      setError(() => '');
      const request = [];
      let errorMessage = '';

     if(formComponentList.length) {
      ruleElement.forEach((ruleElem,k)=>{
        if(errorMessage.length > 0){
          return;
        }

        if(!ruleElem.leftOp){
           errorMessage =`Error Row ${k+1}: please select rule.`;
          return;
        }

        if(!ruleElem.op2 && k > 2){
          errorMessage = `Error Row ${k+1}: please select oprand`;
         return;
        }

        if(!ruleElem.op1){
          errorMessage = `Error Row ${k+1}: please select oprand`;
         return;
        }

        if(!ruleElem.rightOP || ruleElem.rightOP?.length === 0){
          errorMessage = `Error Row ${k+1}: please enter/select option.`;
          return;
        }

        const rightExp = !Array.isArray(ruleElem.rightOP) ? (ruleElem.rightOP?.value ? ruleElem.rightOP?.value : ruleElem.rightOP): JSON.stringify(ruleElem.rightOP.map((i)=>{
          return i.value
        }));

        request.push({
          left_expression: ruleElem.leftOp.value,
          op_id: parseInt(ruleElem.op1),
          right_expression: rightExp,
          seq: seq
        })
        seq++;

        if(k !== 0){
          request.push({
            left_expression: 'E='+(seq-2),
            op_id: parseInt(ruleElem.op2),
            right_expression: 'E='+(seq-1),
            seq: seq
          })
          seq++;
        }
      })
      setError(() => errorMessage);
     }

    if(!errorMessage) {
      submitData(request);
    }
      
  }

  
  return (
    <div>
      <form>
        <div className={classes.alerts}>
            {error.length > 0 && <Alert variant="filled" severity="error">{error}</Alert>}
            {success.length > 0 && <Alert variant="filled" severity="success">{success}</Alert>}
        </div>
        <table className={classes.ruleTable}>
          <tbody>
          { formComponentList?.length === 0 && <tr><td>Rule for this component is not available</td></tr> }
          {formComponentList?.length > 0 &&
            ruleElement.map((rule, k) => {
              return (
                <React.Fragment  key={k}>
                <tr className={classes.inputSection}>
                  <td className={classes.op2}>
                    {k !== 0 && (
                      <div>
                        <OutlinedSelectInput
                          value={rule.op2}
                          options={[
                            {
                              value: 1,
                              label: "And",
                            },
                            {
                              value: 2,
                              label: "Or",
                            },
                          ]}
                          noMargin={true}
                          compact={true}
                          onChange={(e) => {
                           operand2Change(k, e)
                          }}
                        />
                      </div>
                    )}
                    {k === 0 && (
                      <div className={classes.condition}>Condition</div>
                    )}
                  </td>
                  <td className={classnames(classes.inputController, classes.leftOp)}>
                    <ReactSelect
                        isClearable
                        menuPosition='fixed'
                        isSearchable={true}
                        options={requirementOptions}
                        classNamePrefix="select"
                        placeholder="Search rule" 
                        onChange={(e)=>{
                          onLeftChange(k, e);
                        }}
                        value={rule.leftOp}
                    />
                  </td>
                  <td className={classes.op1}>
                  <OutlinedSelectInput
                    placeholder='Operand'
                    value={rule.op1}
                    options={operand}
                    noMargin={true}
                    compact={true}
                    onChange={(e) => {
                      operad1Change(k, e)
                    }}
                  />
                  </td>
                  <td className={classnames(classes.ruleRightElement, classes.rightOp)}>
                  {!rule?.leftOp?.type && (
                      <div>
                        <input
                          placeholder='Enter Option'
                          className={classes.input}
                        />
                      </div>
                    )}
                    {rule?.leftOp?.type === "Text" && (
                      <div>
                        <input
                          className={classes.input}
                          placeholder='Enter Option'
                          onChange={(e)=>{
                            onRightChange(k, e.target?.value || '')
                          }}
                          value={rule.rightOP}
                        />
                      </div>
                    )}
                    {rule?.leftOp?.type === "Date" && (
                      <div>
                        <DatePicker
                        value={rule.rightOP && moment(rule.rightOP).format('DD/MM/YYYY') }
                          selected={""}
                          onChange={(date) => {
                            onRightChange(k, date.toISOString() || '');
                          }}
                          placeholder='Choose Date'
                          dateFormat="MMM do yyyy"
                          portalId="workflow"
                          minDate={""}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          customInput={
                            <OutlinedInput
                              readOnlyInput={true}
                              startAdornment={
                                <InputAdornment>
                                  <Image
                                    src={CalendarImage}
                                    className={classes.inputCalendar}
                                    alt="input calendar"
                                  />
                                </InputAdornment>
                              }
                            />
                          }
                        />
                      </div>
                    )}
                    { (rule?.leftOp?.type === "SINGLE_SELECT" || rule?.leftOp?.type === 'MULTI_SELECT') &&
                      <ReactSelect
                          isClearable
                          isSearchable={true}
                          isMulti={rule?.leftOp?.type === 'MULTI_SELECT' ? true: false}
                          menuPosition='fixed'
                          options={rule?.leftOp?.data}
                          classNamePrefix="select"
                          placeholder="Search Option" 
                          onChange={(e)=>{
                            onRightChange(k, e)
                          }}
                          value={rule.rightOP}
                      />
                    } 
                    { (rule?.leftOp?.type === "Boolean") &&
                      <ReactSelect
                          isClearable
                          menuPosition='fixed'
                          options={[{label: 'true', value:'true'}, {label: 'false', value:'false'}]}
                          classNamePrefix="select"
                          placeholder="Search Option" 
                          onChange={(e)=>{
                            onRightChange(k, e)
                          }}
                          value={rule.rightOP}
                      />
                    } 
                    
                  </td>
                  <td className={classes.action}>
                  { <span  hidden={ruleElement.length === 1}> <IconButton size="small" onClick={() => {deleteRuleElement(k)}}> 
                    <Image alt="Delete" src={deleteIcon}/>
                  </IconButton> </span>} 
                  {<span  hidden={k !== ruleElement.length-1 }><IconButton  size="small" onClick={() => addNewRuleElement(k)}>
                    <AddCircleIcon/>
                  </IconButton>  </span>}
                </td>
                </tr>
                {rule?.leftOp?.type === "TextArea" && <tr>
                    <div>
                        <SlateInputField 
                      folderPath={`/workflow/${params.id}/`}
                      as={SlateInputField}
                        onChangeEvent={(value) => {
                          onRightChange(k, value)
                        }}
                    initValue={rule.rightOP}
                    textContainerStyle={{ color: "rgb(51, 51, 51)"}}/>
                    </div>
                </tr> }
                </React.Fragment>
              );
            })}
            </tbody>
        </table>
        {<div className={classes.saveBtnSection}>
          <button type="button" 
          disabled={workflowStatus === 2 && formComponentList.length > 0 ? true : false}
          className={workflowStatus === 2 && formComponentList.length > 0 ? classes.newUserBtnDisabled : classes.newUserBtn}
          onClick={() => saveRule()}> {formComponentList.length > 0 ? 'Save': 'Close'} </button>
        </div>}
      </form>
    </div>
  );
};
