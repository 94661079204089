import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { createStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Button, Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
// Components
import Criteria from './Criteria';
import CriteriaAddDialog from '../../../../Evaluate/Components/Common/CriteriaAddDialog'
import RequirementAddDialog from "../../../../Evaluate/Components/Common/RequirementAddDialog"
import SearchUserPoper from "Components/Common/UserGroup/SearchUserPoper";
// redux
import { addOrgSettings } from "redux/org/user/action";
import AppBarV2 from "Components/Application/AppBarV2";
import AppBar from "Components/Application/AppBar";

import ScoreScaleDialog from "Components/Common/ScoringScale/ScoreScaleDialog"

import { showAddTemplateDialog, addCriteriaSelection, selectEvaluationTemplateCriteria, unselectEvaluationTemplateCriteria } from "redux/templates/evaluation/action";
import { showEvaluationCriteriaAddDialog } from "redux/evaluation/criteria/action"
import {
    fetchMasterTemplate,
    updateFromMasterEvaluationCriteriaTemplate,
    updateFromMasterEvaluationRequirementTemplate,
} from "redux/templates/evaluation/master/action"

import { expandEvaluationCriteria, expandEvaluationRequirement, showScoringScaleDialog, hideScoringScaleDialog } from "redux/evaluation/criteria/action";

import {
    fetchOrgTemplate,
    updateFromOrgEvaluationCriteriaTemplate,
    updateFromOrgEvaluationRequirementTemplate,
    deleteFromOrgEvaluationCriteriaTemplate,
    deleteFromOrgEvaluationRequirementTemplate,
    addTemplateCriteriaColloborator,
    fetchOrgTemplates,
    updateFromOrgEvaluationTemplate, updateTemplateCriteriaColloborator
} from "redux/templates/evaluation/org/action"
import { showSnackBar } from "redux/snackbar/action";
import CommonCss from 'commonCss';
import RequirementConditionDialog from '../../Dialogs/RequirementConditionDialog';

const connectedProps = (state) => ({
    template: state.masterTemplateEvaluation.template,
    masterTemplateProg: state.masterTemplateEvaluation?.fetchTemplateProg,
    orgTemplate: state.orgTemplateEvaluation.template,
    saveFromOrgEvaluationTemplateProg: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateProg,
    orgTemplateProg: state.orgTemplateEvaluation?.fetchTemplateProg,
    updateFromOrgEvaluationCriteriaTemplateProg: state.orgTemplateEvaluation.updateFromOrgEvaluationCriteriaTemplateProg,
    user: state.authUser.user,
    expandedCriteriaId: state.evaludationCriteria.expandedCriteriaId,
    expandedRequirementId: state.evaludationCriteria.expandedRequirementId,
    deteleCriteriaTemplateProg: state.orgTemplateEvaluation.deteleFromOrgEvaluationCriteriaTemplateProg,
    deteleCriteriaTemplateError: state.orgTemplateEvaluation.deteleFromOrgEvaluationCriteriaTemplateError,
    deteleRequirementTemplateProg: state.orgTemplateEvaluation.deteleFromOrgEvaluationRequirementTemplateProg,
    deteleRequirementTemplateError: state.orgTemplateEvaluation.deteleFromOrgEvaluationRequirementTemplateError,
    templateRuleSaveProgress: state.orgTemplateEvaluation.ruleSaveProgress,
    templateErrorRuleSave: state.orgTemplateEvaluation.errorRuleSave,
    collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
    collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
    configurations: state.orgUser.fetchAllOrgSettingsSuccess,
    criteriaSelection: state.templateEvaluation.criteriaSelection,
    isShowScoringScaleDialog: state.evaludationCriteria.isShowScoringScaleDialog,
    importCriteriaIds: state.templateEvaluation.criteriaIds,
    isReqConditionDialogOpen: state.common.isReqConditionDialogOpen,
});

const connectionActions = {
    fetchMasterTemplate: fetchMasterTemplate,
    fetchOrgTemplate: fetchOrgTemplate,
    expandEvaluationCriteria: expandEvaluationCriteria,
    expandEvaluationRequirement: expandEvaluationRequirement,
    updateFromOrgEvaluationCriteriaTemplate: updateFromOrgEvaluationCriteriaTemplate,
    updateFromMasterEvaluationCriteriaTemplate: updateFromMasterEvaluationCriteriaTemplate,
    updateFromOrgEvaluationRequirementTemplate: updateFromOrgEvaluationRequirementTemplate,
    updateFromMasterEvaluationRequirementTemplate: updateFromMasterEvaluationRequirementTemplate,
    deleteRequirement: deleteFromOrgEvaluationRequirementTemplate,
    deleteCriteria: deleteFromOrgEvaluationCriteriaTemplate,
    showSnackBar: showSnackBar,
    showAddTemplateDialog: showAddTemplateDialog,
    addOrgSettings: addOrgSettings,
    addTemplateCriteriaColloborator: addTemplateCriteriaColloborator,
    showEvaluationCriteriaAddDialog: showEvaluationCriteriaAddDialog,
    addCriteriaSelection: addCriteriaSelection,
    fetchOrgTemplates: fetchOrgTemplates,
    updateFromOrgEvaluationTemplate: updateFromOrgEvaluationTemplate,
    updateTemplateCriteriaColloborator: updateTemplateCriteriaColloborator,
    showScoringScaleDialog: showScoringScaleDialog,
    hideScoringScaleDialog: hideScoringScaleDialog,
    selectEvaluationTemplateCriteria: selectEvaluationTemplateCriteria,
    unselectEvaluationTemplateCriteria: unselectEvaluationTemplateCriteria
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    loader: {
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    root: {
        height: "100%",
        paddingBottom: 50,
        position: "relative",
    },
    bottomContainer: {
        position: 'fixed',
        width: '100%',
        left: 0,
        background: '#fff',
        height: 70,
        bottom: 0,
        boxShadow: '1px 1px 6px 2px #ddd',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '250px',
        justifyContent: 'center',
        paddingRight: 46,
        zIndex: 12
    },
    addNewCriteria: {
        boxShadow: '4px 0px 4px #2B2D3703',
        border: '1px solid #20253a33',
        background: 'transparent',
        borderRadius: '8px',
        opacity: 1,
        color: '#707070',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'rgba(75, 134, 255, 0.04)'
        }
    },
    criteriaAllSelect: {
        marginRight: 10,
        padding: 0
    },
    maxScoreItem: {
        boxShadow: '4px 0px 4px #2B2D3703',
        border: '1px solid #1f73b7',
        background: 'transparent',
        borderRadius: '8px',
        opacity: 1,
        color: '#707070',
        padding: 8,
        margin: 0,
        marginLeft: 10,
        width: 50,
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'rgba(75, 134, 255, 0.04)'
        }
    },
    rootContainer: CommonCss.rootContainer
});

const CriteriaRequirement = ({
    match,
    user,
    template,
    orgTemplate,
    fetchMasterTemplate,
    fetchOrgTemplate,
    isChooseTemplate,
    chooseTemplateId,
    chooseTemplateType,
    importCriteriaIds,
    updateFromOrgEvaluationCriteriaTemplate,
    updateFromMasterEvaluationCriteriaTemplate,
    updateFromOrgEvaluationRequirementTemplate,
    masterTemplateProg,
    orgTemplateProg,
    classes,
    deleteRequirement,
    deleteCriteria,
    addTemplateCriteriaColloborator,
    updateTemplateCriteriaColloborator,
    collaboratorDialogOpen,
    collaboratorDialogData,
    addOrgSettings,
    configurations,
    expandEvaluationCriteria,
    expandedCriteriaId,
    expandedRequirementId,
    expandEvaluationRequirement,
    showEvaluationCriteriaAddDialog,
    updateFromOrgEvaluationCriteriaTemplateProg,
    isSettingTemplate = false,
    addCriteriaSelection,
    fetchOrgTemplates,
    updateFromOrgEvaluationTemplate,
    saveFromOrgEvaluationTemplateProg,
    showSnackBar,
    isShowScoringScaleDialog,
    showScoringScaleDialog,
    hideScoringScaleDialog,
    selectEvaluationTemplateCriteria,
    unselectEvaluationTemplateCriteria,
    handleBack,
    isReqConditionDialogOpen
}) => {

    const [criterias, setCriterias] = useState(null)
    const [templateDetails, setTemplateDetails] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const [state, setState] = useState({
        isReviewChecked: false,
        isEvaluationChecked: false,
        isOnce: true,
        templateID: null,
        templateType: 'null',
        isLoadingDefault: true,
        editMaxScore: false,
        error: '',
        maxScore: '',
        editMaxScoreValue: ''
    })

    const fetchData = () => {
        let type = 'custom'
        let id = undefined
        if (isChooseTemplate) {
            type = chooseTemplateType === 'ORG' ? 'custom' : 'default';
            id = chooseTemplateId
        } else {
            type = match.params.template === 'custom' ? 'custom' : 'default';
            id = match.params.id
        }
        if (type === 'custom') {
            fetchOrgTemplate(id)
        }
        if (type === 'default') {
            fetchMasterTemplate(id)
        }
        setState(prev => ({ ...prev, templateType: type }))
        addCriteriaSelection(null, null, null, null, true)
        // fetchOrgTemplates(0, 1000);
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match, isChooseTemplate])

    useEffect(() => {
        if (saveFromOrgEvaluationTemplateProg === false) {
            // showSnackBar("Template Updated Successfully", "success", 3000);
            fetchData()
            hideScoringScaleDialog()
        }
    }, [saveFromOrgEvaluationTemplateProg])

    useEffect(() => {
        let newCriterias = [], newTemplateDetails = {}
        if (state.templateType === 'custom' && orgTemplate !== null) {
            newTemplateDetails = orgTemplate;
            newCriterias = orgTemplate?.Criterias;
            setTemplateDetails(newTemplateDetails)
            setCriterias(newCriterias)
            setState(prev => ({ ...prev, maxScore: newTemplateDetails?.MaxScore !== null && newTemplateDetails?.MaxScore !== 0 ? newTemplateDetails?.MaxScore : 5 }))
        }
        if (state.templateType === 'default' && template !== null) {
            newTemplateDetails = template;
            newCriterias = template?.Criterias
            // newCriterias = newCriterias.filter(o => o.Name !== "Product Features")
            setTemplateDetails(newTemplateDetails)
            setCriterias(newCriterias)
            setState(prev => ({ ...prev, maxScore: newTemplateDetails?.MaxScore !== null && newTemplateDetails?.MaxScore !== 0 ? newTemplateDetails?.MaxScore : 5 }))
        }
    }, [template, orgTemplate, match, state.templateType, updateFromOrgEvaluationCriteriaTemplateProg])

    useEffect(() => {
        if (templateDetails === null) {
            setIsLoading(masterTemplateProg || orgTemplateProg)
        }
    }, [masterTemplateProg, orgTemplateProg, templateDetails])


    useEffect(() => {
        let isReviewDefaultChecked = false;
        let isEvaluationDefaultChecked = false;
        if (configurations !== null && templateDetails != null) {
            var default_evaluation_setting = configurations.filter((i) => {
                return i.SettingName === "ORG_DEFAULT_EVALUATION_TEMPLATE";
            })
            var default_review_setting = configurations.filter((i) => {
                return i.SettingName === "ORG_DEFAULT_REVIEW_TEMPLATE";
            })
            var settingValue = null;
            if (default_review_setting.length > 0) {
                settingValue = default_review_setting[0].Value
                if (settingValue !== "NULL") {
                    try {
                        const parsed = JSON.parse(settingValue)
                        if (parsed.type === "ORG" && state.templateType === 'custom' && parseInt(parsed.tid) === templateDetails.ID) {
                            isReviewDefaultChecked = true;
                        } else if (parsed.type === "MASTER" && parseInt(parsed.tid) === templateDetails.ID) {
                            isReviewDefaultChecked = true;
                        }
                    } catch (e) { }
                }
            }

            // console.log(default_evaluation_setting,'default_evaluation_setting')
            // console.log(templateDetails,'templateDetails')
            // console.log(state.isDefaultTemplate,'isDefaultTemplate')
            // console.log(state,'State')
            if (default_evaluation_setting.length > 0) {
                settingValue = default_evaluation_setting[0].Value
                if (settingValue !== "NULL") {
                    try {
                        const parsed = JSON.parse(settingValue);
                        if (parsed.type === "ORG" && state.templateType === 'custom' && parseInt(parsed.tid) === templateDetails.ID) {
                            isEvaluationDefaultChecked = true;
                        } else if (parsed.type === "MASTER" && state.templateType === 'default' && parseInt(parsed.tid) === templateDetails.ID) {
                            isEvaluationDefaultChecked = true;
                        }
                    } catch (e) { }
                }
            }

            setState(prev => ({
                ...prev,
                isReviewChecked: isReviewDefaultChecked,
                isEvaluationChecked: isEvaluationDefaultChecked,
                isOnce: false,
            }))

            setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    isLoadingDefault: false
                }))
            }, 1000);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateDetails])

    const handleDeleteRequirement = (tID, cID, rID) => {
        deleteRequirement(tID, cID, rID)
    }

    const handleDeleteCriteria = (tID, cID) => {
        deleteCriteria(tID, cID)
    }

    const handleUpdateCriteria = (id, cid, data) => {
        if (state.templateType === 'custom') {
            updateFromOrgEvaluationCriteriaTemplate(id, cid, data)
        }
    }

    const handleUpdateRequirement = (id, cid, rid, data) => {
        if (state.templateType === 'custom') {
            updateFromOrgEvaluationRequirementTemplate(id, cid, rid, data)
        }
    }

    const handleToggleCriteria = (id) => {
        expandEvaluationCriteria(id)
    }

    const handleToggleRequirement = (id) => {
        expandEvaluationRequirement(id)
    }

    const handleOnChange = (e, type) => {
        var message = "NULL";
        if (e.target.checked) {
            message = JSON.stringify({
                "type": state.templateType === 'custom' ? 'ORG' : "MASTER",
                "tid": templateDetails?.ID
            })
        }

        addOrgSettings([
            {
                "name": `ORG_DEFAULT_${type}_TEMPLATE`,
                "value": message
            }
        ])
        if (type === 'EVALUATION') {
            setState(prev => ({ ...prev, isEvaluationChecked: e.target.checked, isLoadingDefault: true }))
        } else {
            setState(prev => ({ ...prev, isReviewChecked: e.target.checked, isLoadingDefault: true }))
        }
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const { destination, draggableId, source } = result;
        if (draggableId && destination.index !== source.index) {
            if (state.templateType === 'custom') {
                updateFromOrgEvaluationCriteriaTemplate(
                    match.params.id,
                    parseInt(draggableId),
                    { "seq": destination.index }
                )
            }
            if (state.templateType === 'default') {
                updateFromMasterEvaluationCriteriaTemplate(
                    match.params.id,
                    parseInt(draggableId),
                    { "seq": destination.index }
                )
            }

            setTimeout(() => {
                fetchData()
            }, 200)
        }
    }

    const selectAllCriterias = (alreadySelectAll) => {
        console.log('Select All')
        if (criterias !== null && criterias !== undefined) {
            if (alreadySelectAll) {
                criterias.forEach((criteria, index) => {
                    unselectEvaluationTemplateCriteria(criteria.ID)
                })
            } else {
                criterias.forEach((criteria, index) => {
                    selectEvaluationTemplateCriteria(criteria.ID)
                })
            }

        }
    }

    if (isLoading) {
        return (
            <div className={classes.loader}>
                <CircularProgress />
            </div>)
    }
    if (templateDetails === null || state.templateType === null) return null;

    // console.log(importCriteriaIds, 'importCriteriaIds')
    // console.log(criterias, 'criterias')
    // console.log(criterias.length, 'criterias')
    // console.log(importCriteriaIds.length, 'importCriteriaIdslength')
    let checkAllCriterias = false;
    if (criterias !== undefined && criterias !== null && importCriteriaIds !== undefined && importCriteriaIds !== null && criterias.length === importCriteriaIds.length) {
        checkAllCriterias = true;
    }

    return (
        <div className={classes.root}>
            {isChooseTemplate ? <AppBar
                title="Templates"
                withBack={true}
                dispatchEvent={true}
                targetEvent={() => {
                    handleBack()
                }}
            /> : <AppBarV2
                title={templateDetails?.Name}
                withBack={true}
                isEditableTitle={state.templateType === 'custom' ? true : false}
                submitTitle={(value) => {
                    updateFromOrgEvaluationTemplate(templateDetails.ID, {
                        name: value,
                    })
                }}
                isSubmittingTitle={saveFromOrgEvaluationTemplateProg}
            />}

            <div className={classes.rootContainer}>
                <p>
                    {isChooseTemplate
                        ? 'Preview and select section and questions below. You can add or edit section and questions once you pick this template'
                        : 'Preview and select section and questions below. You can add or edit section and questions below.'
                    }
                </p>


                {false && !isChooseTemplate && (
                    <Grid container alignItems="center">
                        <Typography>Default For : </Typography>&nbsp;&nbsp;&nbsp;
                        {!state.isLoadingDefault && <FormControlLabel
                            control={
                                !state.isLoadingDefault && <Checkbox
                                    checked={state.isEvaluationChecked}
                                    onChange={(event) => {
                                        handleOnChange(event, "EVALUATION")
                                    }}
                                    name="evaluation"
                                />
                            }
                            label="Evaluation"
                        />}&nbsp;&nbsp;
                        {!state.isLoadingDefault && <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.isReviewChecked}
                                    onChange={(event) => {
                                        handleOnChange(event, "REVIEW")
                                    }}
                                    name="review"
                                />
                            }
                            label="Review"
                        />}
                        {state.isLoadingDefault && <CircularProgress style={{ width: 40, height: 40, padding: 9 }} />}
                    </Grid>
                )}

                <br />
                {!isChooseTemplate && <Grid container alignItems='center' style={{ marginBottom: 20 }}>
                    <Grid item xl={6} md={6} sm={6}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>Scoring Scale : </Typography>
                            <p
                                className={classes.maxScoreItem}
                                onClick={() => {
                                    if (state.templateType === 'custom') {
                                        showScoringScaleDialog('template')
                                    }
                                }}
                            >{state.maxScore} </p>
                        </div>
                    </Grid>
                    {state.templateType === 'custom' && <Grid item xl={6} md={6} sm={6}>
                        <div style={{ textAlign: 'right' }}>
                            <Button
                                className={classes.addNewCriteria}
                                onClick={() => {
                                    showEvaluationCriteriaAddDialog(templateDetails.ID)
                                }}
                            >
                                + New Section
                            </Button>
                        </div>
                    </Grid>}
                </Grid>}

                {false && !isChooseTemplate && state.templateType === 'custom' && (
                    <>
                        <Grid container justify="flex-end">
                            <Button
                                className={classes.addNewCriteria}
                                onClick={() => {
                                    showEvaluationCriteriaAddDialog(templateDetails.ID)
                                }}
                            >
                                + New Section
                            </Button>
                        </Grid>
                        <br />
                    </>
                )}

                {isChooseTemplate && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 25 }}>
                    <Checkbox
                        label={'Select All'}
                        className={classes.criteriaAllSelect}
                        color="primary"
                        checked={checkAllCriterias}
                        onChange={() => {
                            selectAllCriterias(checkAllCriterias)
                        }}
                    />
                    <Typography>Select All</Typography>
                </div>}

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} >
                                {criterias !== null && criterias !== undefined && criterias.map((criteria, index) => (
                                    <Draggable key={String(criteria?.ID)} draggableId={String(criteria?.ID)} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Criteria
                                                    isChooseTemplate={isChooseTemplate}
                                                    isSettingTemplate={isSettingTemplate}
                                                    key={`criteria_${criteria.ID}`}
                                                    isEditable={!isChooseTemplate && state.templateType === 'custom'}
                                                    criteria={criteria}
                                                    template={templateDetails}
                                                    updateCriteria={(id, cid, data) => handleUpdateCriteria(id, cid, data)}
                                                    toggleCriteria={handleToggleCriteria}
                                                    isOpen={"criteria_" + criteria.ID === expandedCriteriaId}
                                                    expandedRequirementId={expandedRequirementId}
                                                    toggleRequirement={handleToggleRequirement}
                                                    updateRequirement={handleUpdateRequirement}
                                                    deleteCriteria={handleDeleteCriteria}
                                                    deleteRequirement={handleDeleteRequirement}
                                                    addTemplateCriteriaColloborator={addTemplateCriteriaColloborator}
                                                    updateTemplateCriteriaColloborator={updateTemplateCriteriaColloborator}
                                                    maxScore={state.maxScore}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <CriteriaAddDialog isTemplate />
                <RequirementAddDialog isTemplate />
                {collaboratorDialogOpen && <SearchUserPoper {...collaboratorDialogData} />}

                {isShowScoringScaleDialog && <ScoreScaleDialog
                    minValue={1}
                    maxValue={10}
                    templateDetails={templateDetails}
                />}


                {isReqConditionDialogOpen && <RequirementConditionDialog />}

            </div>
        </div>
    )
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(CriteriaRequirement));
