/*
 |  File contains the List of the Action
 |  required for registration Process
 |
 |
 */
import { UserFactory } from "../../services/user";

export const START_REGISTRATION = "START_REGISTRATION";
export const END_REGISTRATION = "END_REGISTRATION";

export const START_SSO_REGISTRATION = "START_SSO_REGISTRATION";
export const END_SSO_REGISTRATION = "END_SSO_REGISTRATION";

//Action to start the registration
function startRegistration() {
  return {
    type: START_REGISTRATION,
    payload: null,
  };
}

function endRegistration(success, error) {
  return {
    type: END_REGISTRATION,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the register
export function register(name, email, company, oid, rid) {
  return async (dispatch) => {
    dispatch(startRegistration());
    try {
      let [response, error] = await UserFactory.instance().register(name, email, company, oid, rid);
      dispatch(endRegistration(response, error));
    } catch (e) {
      dispatch(
        endRegistration(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

//Action to start the registration
function startSSORegistration() {
  return {
    type: START_SSO_REGISTRATION,
    payload: null,
  };
}

function endSSORegistration(success, error) {
  return {
    type: END_SSO_REGISTRATION,
    payload: {
      success,
      error,
    },
  };
}

//Thunk to perform the register
export function registerSSO(token, name, company) {
  return async (dispatch) => {
    dispatch(startSSORegistration());
    try {
      let [response, error] = await UserFactory.instance().registerSSO(token, name, company);
      dispatch(endSSORegistration(response, error));
    } catch (e) {
      dispatch(
        endSSORegistration(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
