import {
    RESET_ORG_GROUP_STATE,
    START_ORG_GROUP_FETCH, END_ORG_GROUP_FETCH,
    START_ORG_GROUP_PRODUCT_FETCH, END_ORG_GROUP_PRODUCT_FETCH,
    START_ORG_GROUP_VENDOR_FETCH, END_ORG_GROUP_VENDOR_FETCH,
    START_ORG_GROUP_VENDOR_UPDATE, END_ORG_GROUP_VENDOR_UPDATE,
    START_ORG_GROUP_PRODUCT_UPDATE, END_ORG_GROUP_PRODUCT_UPDATE,
    START_ORG_GROUP_ADD, END_ORG_GROUP_ADD,
    START_ORG_GROUP_UPDATE, END_ORG_GROUP_UPDATE,
    START_ORG_GROUP_USER_FETCH, END_ORG_GROUP_USER_FETCH,
    START_UPDATE_LICENSE_PURCHAGED, END_UPDATE_LICENSE_PURCHAGED,
    SHOW_GROUP_PRODUCT_DIALOG, HIDE_GROUP_PRODUCT_DIALOG, SET_ACTIVE_USER_TAB,
    SHOW_VIA_INTEGRATION_DIALOG, HIDE_VIA_INTEGRATION_DIALOG,
    SHOW_GROUP_VENDOR_DIALOG, HIDE_GROUP_VENDOR_DIALOG,
} from "./action";

const initState = {
    groups: [],
    fetchGroupTotal: 0,
    fetchGroupProg: false,

    groupProducts: [],
    fetchGroupProductTotal: 0,
    fetchGroupProductProg: false,
    fetchGroupProductError: false,

    updateGroupProductProg: false,
    updateGroupProductError: null,

    groupVendors: [],
    fetchGroupVendorTotal: 0,
    fetchGroupVendorProg: false,
    fetchGroupVendorError: false,

    updateGroupVendorProg: false,
    updateGroupVendorError: null,

    addGroupProg: false,
    fetchAddGroupAPIState: null,
    addedGroup: null,

    updateGroupProg: false,
    fetchUpdateGroupAPIState: null,
    updatedGroup: null,

    fetchGroupUserProg: false,
    fetchGroupUserAPIState: null,
    groupUser: null,

    updateLicenseProg: false,

    teamProductShowDialogOpen: false,
    teamProductShowDialogData: null,

    teamVendorShowDialogOpen: false,
    teamVendorShowDialogData: null,

    userActiveTab: 0,
    viaIntegrationDialogOpen:false
}

export function orgGroupReducer(state = initState, action) {
    switch (action.type) {
        case SET_ACTIVE_USER_TAB:
            return {
                ...state,
                userActiveTab: action.payload.id
            }
        case START_UPDATE_LICENSE_PURCHAGED:
            return {
                ...state,
                updateLicenseProg: true
            };
        case END_UPDATE_LICENSE_PURCHAGED:
            return {
                ...state,
                updateLicenseProg: false
            };
        case RESET_ORG_GROUP_STATE:
            return {
                ...state,
                fetchAddGroupAPIState: null,
                fetchUpdateGroupAPIState: null,
                fetchGroupUserAPIState: null,
            };
        case START_ORG_GROUP_VENDOR_FETCH:
            var groupVendors = state.groupVendors;
            return {
                ...state,
                fetchGroupVendorProg: true,
                groupVendors: groupVendors,
            };
        case END_ORG_GROUP_VENDOR_FETCH:
            var success = action.payload.success;
            var error = action.payload.error;
            groupVendors = state.groupVendors;
            var fetchGroupVendorTotal = state.fetchGroupVendorTotal;
            if (success !== null) {
                var Page = success.page;
                var Data = success.vendors;
                if (!Page || Page === 0) {
                    groupVendors = Data;
                } else {
                    groupVendors = groupVendors.concat(Data)
                }
                fetchGroupVendorTotal = success.total
            }
            if (error !== null) {
                Page = 0;
                groupVendors = []
                fetchGroupVendorTotal = 0
            }
            return {
                ...state,
                fetchGroupVendorProg: false,
                fetchGroupVendorError: error,
                groupVendors: groupVendors,
                fetchGroupVendorTotal: fetchGroupVendorTotal
            };
        case START_ORG_GROUP_VENDOR_UPDATE:
            return {
                ...state,
                updateGroupVendorProg: true,
                updateGroupVendorError: null,
            };

        case END_ORG_GROUP_VENDOR_UPDATE:
            return {
                ...state,
                updateGroupVendorProg: false,
                updateGroupVendorError: action.payload.error
            };
    
        case START_ORG_GROUP_PRODUCT_FETCH:
            var groupProducts = state.groupProducts;
            return {
                ...state,
                fetchGroupProductProg: true,
                groupProducts: groupProducts,
            };
        case END_ORG_GROUP_PRODUCT_FETCH:
            success = action.payload.success;
            error = action.payload.error;
            groupProducts = state.groupProducts;
            var fetchGroupProductTotal = state.fetchGroupProductTotal;
            if (success !== null) {
                Page = success.page;
                Data = success.Data;
                if (Page === 0) {
                    groupProducts = Data;
                } else {
                    groupProducts = groupProducts.concat(Data)
                }
                fetchGroupProductTotal = success.total
            }
            if (error !== null) {
                Page = 0;
                groupProducts = []
                fetchGroupProductTotal = 0
            }
            return {
                ...state,
                fetchGroupProductProg: false,
                fetchGroupProductError: error,
                groupProducts: groupProducts,
                fetchGroupProductTotal: fetchGroupProductTotal
            };
        case START_ORG_GROUP_PRODUCT_UPDATE:
            return {
                ...state,
                updateGroupProductProg: true,
                updateGroupProductError: null,
            };

        case END_ORG_GROUP_PRODUCT_UPDATE:
            return {
                ...state,
                updateGroupProductProg: false,
                updateGroupProductError: action.payload.error
            };


        case START_ORG_GROUP_FETCH:
            var groups = state.groups;
            return {
                ...state,
                fetchGroupProg: true,
                groups: groups,
            };
        case END_ORG_GROUP_FETCH:
            success = action.payload.success;
            groups = state.groups;
            var fetchGroupTotal = state.fetchGroupTotal;
            if (success !== null) {
                Page = success.page;
                Data = success.Data;
                if (Page === 0) {
                    groups = Data;
                } else {
                    groups = groups.concat(Data)
                }
                fetchGroupTotal = success.total
            }
            return {
                ...state,
                fetchGroupProg: false,
                groups: groups,
                fetchGroupTotal: fetchGroupTotal
            };
        case START_ORG_GROUP_ADD:
            return {
                ...state,
                addGroupProg: true,
                fetchAddGroupAPIState: null,
                addedGroup: null
            };
        case END_ORG_GROUP_ADD:
            var _groups = state.groups;
            var apiState = null;
            if (action.payload.success !== null) {
                _groups.push(action.payload.success)
                apiState = true;
            } else {
                apiState = false;
            }
            return {
                ...state,
                addGroupProg: false,
                groups: _groups,
                fetchAddGroupAPIState: apiState,
                addedGroup: action.payload.success
            };

        case START_ORG_GROUP_UPDATE:
            return {
                ...state,
                updateGroupProg: true,
                fetchUpdateGroupAPIState: null,
                updatedGroup: null,
                updateGroupError: null,
            };

        case END_ORG_GROUP_UPDATE:
            return {
                ...state,
                updateGroupProg: false,
                fetchUpdateGroupAPIState: apiState,
                updatedGroup: action.payload.success,
                updateGroupError: action.payload.error
            };

        case START_ORG_GROUP_USER_FETCH:
            return {
                ...state,
                fetchGroupUserProg: true,
                fetchGroupUserAPIState: null,
                groupUser: null,
            };
        case END_ORG_GROUP_USER_FETCH:
            success = action.payload.success;
            var users = [];
            apiState = null;
            if (success !== null) {
                users = success.Users
                apiState = true;
            } else {
                apiState = false;
            }
            return {
                ...state,
                fetchGroupUserProg: false,
                fetchGroupUserAPIState: apiState,
                groupUser: users,
            };
        case SHOW_GROUP_PRODUCT_DIALOG:
            return {
                ...state,
                teamProductShowDialogOpen: true,
                teamProductShowDialogData: action.payload
            };

        case HIDE_GROUP_PRODUCT_DIALOG:
            return {
                ...state,
                teamProductShowDialogOpen: false
            };
        case SHOW_GROUP_VENDOR_DIALOG:
            return {
                ...state,
                teamVendorShowDialogOpen: true,
                teamVendorShowDialogData: action.payload
            };

        case HIDE_GROUP_VENDOR_DIALOG:
            return {
                ...state,
                teamVendorShowDialogOpen: false
            };

        case SHOW_VIA_INTEGRATION_DIALOG:
            return {
                ...state,
                viaIntegrationDialogOpen: true
            };

        case HIDE_VIA_INTEGRATION_DIALOG:
            return {
                ...state,
                viaIntegrationDialogOpen: false
            };
        default:
            return state;
    }
}