import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const START_ORG_CATEGORIES_FETCH = "START_ORG_CATEGORIES_FETCH";
export const END_ORG_CATEGORIES_FETCH = "END_ORG_CATEGORIES_FETCH";

export const START_ORG_CATEGORY_FETCH = "START_ORG_CATEGORY_FETCH";
export const END_ORG_CATEGORY_FETCH = "END_ORG_CATEGORY_FETCH";

export const SHOW_ORG_CATEGORY_ADD_DIALOG = "SHOW_ORG_CATEGORY_ADD_DIALOG";
export const HIDE_ORG_CATEGORY_ADD_DIALOG = "HIDE_ORG_CATEGORY_ADD_DIALOG";

export const START_ADD_NEW_ORG_CATEGORY = "START_ADD_NEW_ORG_CATEGORY";
export const END_ADD_NEW_ORG_CATEGORY = "END_ADD_NEW_ORG_CATEGORY";

export const START_ORG_CATEGORY_UPDATE = "START_ORG_CATEGORY_UPDATE";
export const END_ORG_CATEGORY_UPDATE = "END_ORG_CATEGORY_UPDATE";

export const START_ORG_CATEGORY_DELETE = "START_ORG_CATEGORY_DELETE";
export const END_ORG_CATEGORY_DELETE = "END_ORG_CATEGORY_DELETE";

export const START_ADD_ORG_CATEGORY_TAB = "START_ADD_ORG_CATEGORY_TAB";
export const END_ADD_ORG_CATEGORY_TAB = "END_ADD_ORG_CATEGORY_TAB";

export const START_DELETE_ORG_CATEGORY_TAB = "START_DELETE_ORG_CATEGORY_TAB";
export const END_DELETE_ORG_CATEGORY_TAB = "END_DELETE_ORG_CATEGORY_TAB";

export const START_FETCH_ORG_CATEGORY_TABS = "START_FETCH_ORG_CATEGORY_TABS";
export const END_FETCH_ORG_CATEGORY_TABS = "END_FETCH_ORG_CATEGORY_TABS";

export const START_UPDATE_ORG_CATEGORY_TAB = "START_UPDATE_ORG_CATEGORY_TAB";
export const END_UPDATE_ORG_CATEGORY_TAB = "END_UPDATE_ORG_CATEGORY_TAB";

export const START_ADD_ORG_CATEGORY_TAB_SECTION = "START_ADD_ORG_CATEGORY_TAB_SECTION";
export const END_ADD_ORG_CATEGORY_TAB_SECTION = "END_ADD_ORG_CATEGORY_TAB_SECTION";

export const START_UPDATE_ORG_CATEGORY_TAB_SECTION = "START_UPDATE_ORG_CATEGORY_TAB_SECTION";
export const END_UPDATE_ORG_CATEGORY_TAB_SECTION = "END_UPDATE_ORG_CATEGORY_TAB_SECTION";

export const START_DELETE_ORG_CATEGORY_TAB_SECTION = "START_DELETE_ORG_CATEGORY_TAB_SECTION";
export const END_DELETE_ORG_CATEGORY_TAB_SECTION = "END_DELETE_ORG_CATEGORY_TAB_SECTION";

export const START_ADD_ORG_CATEGORY_TAB_SECTION_ITEM = "START_ADD_ORG_CATEGORY_TAB_SECTION_ITEM";
export const END_ADD_ORG_CATEGORY_TAB_SECTION_ITEM = "END_ADD_ORG_CATEGORY_TAB_SECTION_ITEM";

export const START_UPDATE_ORG_CATEGORY_TAB_SECTION_ITEM = "START_UPDATE_ORG_CATEGORY_TAB_SECTION_ITEM";
export const END_UPDATE_ORG_CATEGORY_TAB_SECTION_ITEM = "END_UPDATE_ORG_CATEGORY_TAB_SECTION_ITEM";

export const START_DELETE_ORG_CATEGORY_TAB_SECTION_ITEM = "START_DELETE_ORG_CATEGORY_TAB_SECTION_ITEM";
export const END_DELETE_ORG_CATEGORY_TAB_SECTION_ITEM = "END_DELETE_ORG_CATEGORY_TAB_SECTION_ITEM";

export const START_UPDATE_ORG_CATEGORY_TAB_SECTION_SEQ = "START_UPDATE_ORG_CATEGORY_TAB_SECTION_SEQ";
export const END_UPDATE_ORG_CATEGORY_TAB_SECTION_SEQ = "END_UPDATE_ORG_CATEGORY_TAB_SECTION_SEQ";

export const START_UPDATE_ORG_CATEGORY_TAB_SEQ = "START_UPDATE_ORG_CATEGORY_TAB_SEQ";
export const END_UPDATE_ORG_CATEGORY_TAB_SEQ = "END_UPDATE_ORG_CATEGORY_TAB_SEQ";

function startUpdateOrgCategoryTabSectionSequence(page) {
    return {
        type: START_UPDATE_ORG_CATEGORY_TAB_SECTION_SEQ,
        payload: page
    };
}

function endUpdateOrgCategoryTabSectionSequence(success, error) {
    return {
        type: END_UPDATE_ORG_CATEGORY_TAB_SECTION_SEQ,
        payload: {
            success, error
        }
    };
}

export function updateOrgCategoryTabSectionSequence(categoryId, tabId, sectionId, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tab/${tabId}/section/${sectionId}/seq?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgCategoryTabSectionSequence());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgCategoryTabSectionSequence(response, error));
        } catch (e) {
            dispatch(endUpdateOrgCategoryTabSectionSequence(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateOrgCategoryTabSequence(page) {
    return {
        type: START_UPDATE_ORG_CATEGORY_TAB_SEQ,
        payload: page
    };
}

function endUpdateOrgCategoryTabSequence(success, error) {
    return {
        type: END_UPDATE_ORG_CATEGORY_TAB_SEQ,
        payload: {
            success, error
        }
    };
}

export function updateOrgCategoryTabSequence(categoryId, tabId, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tab/${tabId}/seq?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgCategoryTabSequence());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgCategoryTabSequence(response, error));
        } catch (e) {
            dispatch(endUpdateOrgCategoryTabSequence(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function showAddOrgCategoryDialog() {
    return {
        type: SHOW_ORG_CATEGORY_ADD_DIALOG,
        payload: {}
    };
}

export function hideAddOrgCategoryDialog() {
    return {
        type: HIDE_ORG_CATEGORY_ADD_DIALOG,
        payload: {}
    };
}

function startOrgCategoriesFetch(page) {
    return {
        type: START_ORG_CATEGORIES_FETCH,
        payload: page
    };
}

function endOrgCategoriesFetch(success, error) {
    return {
        type: END_ORG_CATEGORIES_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchOrgCategories(page, pageSize, query = '') {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/fetch-categories?page=${page}&pageSize=${pageSize}&query=${query}&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startOrgCategoriesFetch());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endOrgCategoriesFetch(response, error));
        } catch (e) {
            dispatch(endOrgCategoriesFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startOrgCategoryFetch(page) {
    return {
        type: START_ORG_CATEGORY_FETCH,
        payload: page
    };
}

function endOrgCategoryFetch(success, error) {
    return {
        type: END_ORG_CATEGORY_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchOrgCategory(categoryId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startOrgCategoryFetch());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endOrgCategoryFetch(response, error));
        } catch (e) {
            dispatch(endOrgCategoryFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startOrgCategoryUpdate(page) {
    return {
        type: START_ORG_CATEGORY_UPDATE,
        payload: page
    };
}

function endOrgCategoryUpdate(success, error) {
    return {
        type: END_ORG_CATEGORY_UPDATE,
        payload: {
            success, error
        }
    };
}

export function updateOrgCategory(categoryId,data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startOrgCategoryUpdate());
        try {
            let [response, error] = await await HttpFactory.instance().putMethod(url,data);
            dispatch(endOrgCategoryUpdate(response, error));
        } catch (e) {
            dispatch(endOrgCategoryUpdate(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startOrgCategoryDelete(page) {
    return {
        type: START_ORG_CATEGORY_DELETE,
        payload: page
    };
}

function endOrgCategoryDelete(success, error) {
    return {
        type: END_ORG_CATEGORY_DELETE,
        payload: {
            success, error
        }
    };
}

export function deleteOrgCategory(categoryId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startOrgCategoryDelete());
        try {
            let [response, error] = await await HttpFactory.instance().deleteMethod(url);
            dispatch(endOrgCategoryDelete(response, error));
        } catch (e) {
            dispatch(endOrgCategoryDelete(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddNewOrgCategory() {
    return {
        type: START_ADD_NEW_ORG_CATEGORY,
        payload: {}
    };
}

function endAddNewOrgCategory(success, error) {
    return {
        type: END_ADD_NEW_ORG_CATEGORY,
        payload: {
            success, error
        }
    };
}

export function addNewOrgCategory(data = {}) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/category?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewOrgCategory());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewOrgCategory(response, error));
        } catch (e) {
            dispatch(endAddNewOrgCategory(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgCategoryTabs() {
    return {
        type: START_FETCH_ORG_CATEGORY_TABS,
        payload: null
    };
}

function endFetchOrgCategoryTabs(success, error) {
    return {
        type: END_FETCH_ORG_CATEGORY_TABS,
        payload: {
            success,
            error,
        }
    };
}

export function fetchOrgCategoryTabs(categoryId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tabs?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOrgCategoryTabs());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgCategoryTabs(response, error));
        } catch (e) {
            dispatch(endFetchOrgCategoryTabs(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgCategoryTab() {
    return {
        type: START_ADD_ORG_CATEGORY_TAB,
    }
}

function endAddOrgCategoryTab(success, error) {
    return {
        type: END_ADD_ORG_CATEGORY_TAB,
        payload: {
            success,
            error,
        }
    }
}


export function addOrgCategoryTab(categoryId, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tab?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgCategoryTab());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddOrgCategoryTab(response, error));
        } catch (e) {
            dispatch(endAddOrgCategoryTab(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgCategoryTab() {
    return {
        type: START_DELETE_ORG_CATEGORY_TAB,
        payload: null
    }
}

function endDeleteOrgCategoryTab(tabId, success, error) {
    return {
        type: END_DELETE_ORG_CATEGORY_TAB,
        payload: {
            tabId,
            success,
            error,
        }
    }
}

export function deleteOrgCategoryTab(categoryId, tabId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tab/${tabId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteOrgCategoryTab());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgCategoryTab(tabId, response, error));
        } catch (e) {
            dispatch(endDeleteOrgCategoryTab(tabId, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateOrgCategoryTab() {
    return {
        type: START_UPDATE_ORG_CATEGORY_TAB,
        payload: null
    };
}

function endUpdateOrgCategoryTab(tabId, success, error) {
    return {
        type: END_UPDATE_ORG_CATEGORY_TAB,
        payload: {
            tabId,
            success,
            error,
        }
    };
}

export function updateOrgCategoryTab(categoryId, tabId, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tab/${tabId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgCategoryTab());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgCategoryTab(tabId, response, error));
        } catch (e) {
            dispatch(endUpdateOrgCategoryTab(tabId, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddOrgCategoryTabSection() {
    return {
        type: START_ADD_ORG_CATEGORY_TAB_SECTION,
        payload: null
    };
}

function endAddOrgCategoryTabSection(tabId, success, error) {
    return {
        type: END_ADD_ORG_CATEGORY_TAB_SECTION,
        payload: {
            tabId,
            success,
            error,
        }
    };
}

export function addOrgCategoryTabSection(categoryId, tabId, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tab/${tabId}/section?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgCategoryTabSection());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddOrgCategoryTabSection(tabId, response, error));
        } catch (e) {
            dispatch(endAddOrgCategoryTabSection(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateOrgCategoryTabSection() {
    return {
        type: START_UPDATE_ORG_CATEGORY_TAB_SECTION,
        payload: null
    };
}

function endUpdateOrgCategoryTabSection(tabUid, sectionUid, success, error) {
    return {
        type: END_UPDATE_ORG_CATEGORY_TAB_SECTION,
        payload: {
            tabUid,
            sectionUid,
            success,
            error,
        }
    };
}

export function updateOrgCategoryTabSection(categoryId, tabId, sectionId, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tab/${tabId}/section/${sectionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgCategoryTabSection());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgCategoryTabSection(tabId, sectionId, response, error));
        } catch (e) {
            dispatch(endUpdateOrgCategoryTabSection(tabId, sectionId, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgCategoryTabSection() {
    return {
        type: START_DELETE_ORG_CATEGORY_TAB_SECTION,
        payload: null
    };
}

function endDeleteOrgCategoryTabSection(tabUid, sectionUid, success, error) {
    return {
        type: END_DELETE_ORG_CATEGORY_TAB_SECTION,
        payload: {
            tabUid,
            sectionUid,
            success,
            error,
        }
    };
}

export function deleteOrgCategoryTabSection(categoryId, tabId, sectionId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tab/${tabId}/section/${sectionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteOrgCategoryTabSection());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgCategoryTabSection(tabId, sectionId, response, error));
        } catch (e) {
            dispatch(endDeleteOrgCategoryTabSection(tabId, sectionId, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAddOrgCategoryTabSectionItem() {
    return {
        type: START_ADD_ORG_CATEGORY_TAB_SECTION_ITEM,
        payload: null
    };
}

function endAddOrgCategoryTabSectionItem(tabId, sectionId, success, error) {
    return {
        type: END_ADD_ORG_CATEGORY_TAB_SECTION_ITEM,
        payload: {
            tabId,
            sectionId,
            success,
            error,
        }
    };
}

export function addOrgCategoryTabSectionItem(categoryId, tabId, sectionId, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tab/${tabId}/section/${sectionId}/sub-section?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgCategoryTabSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data,false);
            dispatch(endAddOrgCategoryTabSectionItem(tabId, sectionId, response, error));
        } catch (e) {
            dispatch(endAddOrgCategoryTabSectionItem(null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateOrgCategoryTabSectionItem() {
    return {
        type: START_UPDATE_ORG_CATEGORY_TAB_SECTION_ITEM,
        payload: null
    };
}

function endUpdateOrgCategoryTabSectionItem(tabUid, sectionUid, itemUid, success, error) {
    return {
        type: END_UPDATE_ORG_CATEGORY_TAB_SECTION_ITEM,
        payload: {
            tabUid,
            sectionUid,
            itemUid,
            success,
            error,
        }
    };
}

export function updateOrgCategoryTabSectionItem(categoryId, tabId, sectionId, itemId, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tab/${tabId}/section/${sectionId}/sub-section/${itemId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgCategoryTabSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgCategoryTabSectionItem(tabId, sectionId, itemId, response, error));
        } catch (e) {
            dispatch(endUpdateOrgCategoryTabSectionItem(tabId, sectionId, itemId, sectionId, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgCategoryTabSectionItem() {
    return {
        type: START_DELETE_ORG_CATEGORY_TAB_SECTION_ITEM,
        payload: null
    };
}

function endDeleteOrgCategoryTabSectionItem(tabUid, sectionUid, itemUid, success, error) {
    return {
        type: END_DELETE_ORG_CATEGORY_TAB_SECTION_ITEM,
        payload: {
            tabUid,
            sectionUid,
            itemUid,
            success,
            error,
        }
    };
}


export function deleteOrgCategoryTabSectionItem(categoryId, tabId, sectionId, itemId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/category/${categoryId}/tab/${tabId}/section/${sectionId}/sub-section/${itemId}?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startDeleteOrgCategoryTabSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgCategoryTabSectionItem(tabId, sectionId, itemId, response, error));
        } catch (e) {
            dispatch(endDeleteOrgCategoryTabSectionItem(tabId, sectionId, itemId, null, {
                message: e.message
            }));
            return;
        }
    };
}
