import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";

export const START_FETCH_DOCUMENT_TYPE = "START_FETCH_DOCUMENT_TYPE";
export const END_FETCH_DOCUMENT_TYPE = "END_FETCH_DOCUMENT_TYPE";

export const START_ADD_DOCUMENT_TYPE = "START_ADD_DOCUMENT_TYPE";
export const END_ADD_DOCUMENT_TYPE = "END_ADD_DOCUMENT_TYPE";

export const START_UPDATE_DOCUMENT_TYPE = "START_UPDATE_DOCUMENT_TYPE";
export const END_UPDATE_DOCUMENT_TYPE = "END_UPDATE_DOCUMENT_TYPE";

export const START_DELETE_DOCUMENT_TYPE = "START_DELETE_DOCUMENT_TYPE";
export const END_DELETE_DOCUMENT_TYPE = "END_DELETE_DOCUMENT_TYPE";

export const START_ADD_DOCUMENT_TYPE_COLLABORATORS = "START_ADD_DOCUMENT_TYPE_COLLABORATORS";
export const END_ADD_DOCUMENT_TYPE_COLLABORATORS = "END_ADD_DOCUMENT_TYPE_COLLABORATORS";

function startFetchDocTypes(){
    return {
        type:START_FETCH_DOCUMENT_TYPE,
        payload:{}
    };
}

function endFetchDocTypes(success,error){
    return {
        type:END_FETCH_DOCUMENT_TYPE,
        payload:{
            success,error
        }
    };
}

export function fetchDocTypes(){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/org/document_types?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchDocTypes());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endFetchDocTypes(response,error));
        } catch(e){
            dispatch(endFetchDocTypes(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startAddDocType(){
    return {
        type:START_ADD_DOCUMENT_TYPE,
        payload:{}
    };
}

function endAddDocType(success,error){
    return {
        type:END_ADD_DOCUMENT_TYPE,
        payload:{
            success,error
        }
    };
}

export function addDocType(data){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/org/document_type?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddDocType());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endAddDocType(response,error));
        } catch(e){
            dispatch(endAddDocType(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startAddDocTypeColloborators(){
    return {
        type:START_ADD_DOCUMENT_TYPE_COLLABORATORS,
        payload:{}
    };
}

function endAddDocTypeColloborators(success,error){
    return {
        type:END_ADD_DOCUMENT_TYPE_COLLABORATORS,
        payload:{
            success,error
        }
    };
}

export function addDocTypeColloborators(evaluationID,stepId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline/${stepId}/collaborators?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddDocTypeColloborators());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endAddDocTypeColloborators(response,error));
        } catch(e){
            dispatch(endAddDocTypeColloborators(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startUpdateDocType(){
    return {
        type:START_UPDATE_DOCUMENT_TYPE,
        payload:{}
    };
}

function endUpdateDocType(success,error){
    return {
        type:END_UPDATE_DOCUMENT_TYPE,
        payload:{
            success,error
        }
    };
}

export function updateDocType(id,data){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/org/document_type/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateDocType());
        try{
            let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
            dispatch(endUpdateDocType(response,error));
        } catch(e){
            dispatch(endUpdateDocType(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startDeleteDocType(){
    return {
        type:START_DELETE_DOCUMENT_TYPE,
        payload:{}
    };
}

function endDeleteDocType(success,error){
    return {
        type:END_DELETE_DOCUMENT_TYPE,
        payload:{
            success,error
        }
    };
}

export function deleteDocType(id,data){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/org/document_type/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteDocType());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url,data);
            dispatch(endDeleteDocType(response,error));
        } catch(e){
            dispatch(endDeleteDocType(null,{
                message:e.message
            }));
            return;
        }
    };
}
