import React from "react";
import { connect } from "react-redux";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import DefaultImage from "assets/images/evaluation/default.svg";
import {
  Grid, CircularProgress, InputAdornment, Tab,
  Tabs
} from "@material-ui/core";
import AppBarV2 from "Components/Application/AppBarV2";
import {
  fetchProductCategory,
  searchProductCategory,
} from "redux/product/category/action";
import InfiniteScroll from "react-infinite-scroll-component";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import { Search as SearchIcon, Cancel as CancelIcon } from "@material-ui/icons";
import CommonCss from 'commonCss';
import ProductTypeFilter from "Components/Common/Filters/ProductTypeFilter";
import {searchAppProductCategory} from "redux/product/appProductCategory/action";

const connectedProps = (state) => ({
  categoryFetchProgress: state.productCategory.fetchCategoryProgress,
  categories: state.productCategory.categories,
  totalCategories: state.productCategory.totalCategories,
  orgName: state.authUser.user?.Organization?.Name,
  appProductCategories: state.appProductCategory.appProductCategories,
  fetchAppProductCategoryProgress: state.appProductCategory.fetchAppProductCategoryProgress,
  fetchAppProductCategoryError: state.appProductCategory.fetchAppProductCategoryError,
});

const connectionActions = {
  fetchProductCategory: fetchProductCategory,
  searchProductCategory: searchProductCategory,
  searchAppProductCategory: searchAppProductCategory,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    productCount: {
      color: "#282D30",
      fontSize: 14,
      flex: 1,
    },
    productSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    searchCard: {
      width: "100%",
      background: "#fff",
      margin: "0 auto",
      padding: theme.spacing(2),
      borderBottom: "solid 1px #ccc",
      cursor: "pointer",
      "&:hover": {
        background: "#eaeaea",
      },
    },
    searchSubCatTitle: {
      width: "100%",
      background: "#f0f0f0",
      margin: "0 auto",
      padding: theme.spacing(1),
      borderBottom: "solid 1px #ccc",
      // fontSize:"10px",
      fontWeight: "bold",
    },
    disablesSearchCard: {
      width: "100%",
      background: "#f0f0f0",
      margin: "0 auto",
      padding: theme.spacing(2),
      borderBottom: "solid 1px #ccc",
      cursor: "not-allowed",
      "&:hover": {
        background: "#f0f0f0",
      },
    },
    searchSection: {
      flex: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      '& [class*="MuiOutlinedInput-root"]': {
        maxHeight: 40,
        minHeight: 40,
        // maxWidth: 343,
      },
      "& [class*='MuiInputBase-input']": {
        fontSize: 13,
        // width: 300,
      },
      "& [class*='MuiSvgIcon-root']": {
        fill: "#707070",
      },
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
      "&>div ": {
        marginBottom: 0,
      },
    },
    searchResult: {
      position: "absolute",
      top: "45px",
      left: 0,
      right: 0,
      zIndex: 100,
      background: "#fff",
      maxHeight: "400px",
      overflow: "auto",
      boxShadow: " 4px 0px 4px #2b2d3703",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    categoriesList: {
      marginTop: theme.spacing(3),
      maxHeight: "calc(100vh - 210px)",
      minHeight: 200,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    filterSection: {
      flex: 1,
    },
    closeIcon: {
      cursor: "pointer",
    },
    rootContainer: CommonCss.rootContainer,
    tabSection: {
      marginTop: theme.spacing(3),
      '& [class*=MuiTabs-root]': {
          minWidth: '200px !important'
      },
      '& [class*=MuiTab-root]': {
          minWidth: '200px !important'
      },
  },
  });

class AllCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      text: "",
      types: ["subcategory", "category"],
      pageSize: 60,
      productType: 'SOFTWARE',
      hardwareCategories: [
        {
          "ID": 1,
          "Name": "Cables",
          "Logo": "https://assets.expent.ai/Accounting & Financial management.svg",
          "CreatedAt": "2021-01-15T10:06:30Z",
          "UpdatedAt": "2021-01-15T10:06:30Z"
        },
        {
          "ID": 2,
          "Name": "Computer Accessories",
          "Logo": "https://assets.expent.ai/Analytics and Business Intelligence.svg",
          "CreatedAt": "2021-01-15T10:06:30Z",
          "UpdatedAt": "2021-01-15T10:06:30Z"
        },
        {
          "ID": 3,
          "Name": "Computer Moniter & Displays",
          "Logo": "https://assets.expent.ai/Application Development & Integration.svg",
          "CreatedAt": "2021-01-15T10:06:30Z",
          "UpdatedAt": "2021-01-15T10:06:30Z"
        },
        {
          "ID": 4,
          "Name": "Computers",
          "Logo": "https://assets.expent.ai/Artificial Intelligence.svg",
          "CreatedAt": "2021-01-15T10:06:30Z",
          "UpdatedAt": "2021-01-15T10:06:30Z"
        }
      ],
      tabValue: 0,
    };
    this.fetchData = this.fetchData.bind(this);
    this.searchData = this.searchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    //source=evaluation-home
    this.props.fetchProductCategory(this.state.page, this.state.pageSize, "");
  }

  searchData() {
    const {types,text} = this.state;
    this.props.searchAppProductCategory({types: types,text: text});
  }

  goBack = () => {
    this.props.history.push("/app/products");
  };

  render() {
    const classes = this.props.classes;

    var searchResults = this.props.appProductCategories?.items || [];
   
    return (
      <div className={classes.main}>
        <AppBarV2
          title="All Categories"
          withBack={true}
          dispatchEvent={true}
          targetEvent={this.goBack}
        />
        <div className={classes.rootContainer}>


          {process.env.REACT_APP_ENV === 'dev' && <ProductTypeFilter
            type={this.state.productType}
            onChange={(type) => {
              this.setState({
                productType: type
              })
            }}
          />}

          <div className={classes.productSection}>
            <p className={classes.productCount}>
              {
                <>
                  {this.props.totalCategories.toLocaleString("en-US")}
                  {this.props.totalCategories > 1
                    ? " Categories"
                    : " Category"}{" "}
                  found
                </>
              }
            </p>
            <div className={classes.searchSection}>
              <OutlinedInput
                id={"searchCategories "}
                placeholder="Search Categories"
                value={this.state.text}
                onChange={(event) => {
                  var me = this;
                  this.setState({ text: event.target.value }, () => {
                    me.searchData();
                  });
                }}
                fullWidth={true}
                style={{ width: "100%" }}
                startAdornment={
                  <InputAdornment>
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                }
                endAdornment={
                  this.state.text.length !== 0 ? (
                    <InputAdornment>
                      <CancelIcon
                        className={classes.closeIcon}
                        onClick={() => {
                          this.setState({
                            text: "",
                          });
                        }}
                      />
                    </InputAdornment>
                  ) : null
                }
                roundedCorner={true}
              />



              {this.state.text.length !== 0 && (
                <div className={classes.searchResult} id="allCategories">
                  <div>
                    {searchResults.map((category, k) => {
                      return (
                        <div key={"searchcat_" + k}>
                            <>
                              <div
                                className={classnames(classes.searchCard, {
                                  [classes.disablesSearchCard]:
                                    !category.is_clickable,
                                })}
                                onClick={() => {
                                  if (!category.is_clickable) {
                                    return;
                                  }
                                  this.props.history.push(
                                    `/${this.props.isSeller ? "sellerapp" : "app"}/products/category-wise-product/` +
                                    category.id
                                  );
                                }}
                              >
                                <b>Category :</b> {category.name}
                              </div>
                              {category.subcategory?.length > 0 && (
                                <div
                                  className={classnames(
                                    classes.searchSubCatTitle
                                  )}
                                >
                                  {" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Subcategories :
                                </div>
                              )}
                            </>
                          
                          {category.subcategory?.map(subcat=>(
                            <div
                              className={classes.searchCard}
                              onClick={() => {
                                this.props.history.push(
                                  `/${this.props.isSeller ? "sellerapp" : "app"}/products/category-wise-product/` +
                                  category.id +
                                  "?subcategory=" +
                                  subcat.id
                                );
                              }}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                              {subcat.name}
                            </div>
                          ))}
                            
                          
                        </div>
                      );
                    })}
                    {this.props.fetchAppProductCategoryProgress === true && (
                      <div className={classes.backdrop}>
                        {" "}
                        <CircularProgress />
                      </div>
                    )}
                    {this.props.fetchAppProductCategoryProgress === false &&
                      searchResults.length === 0 && (
                        <div className={classes.backdrop}>{"No Data"}</div>
                      )}
                  </div>
                </div>
              )}
            </div>

            <div className={classes.filterSection}></div>
          </div>

          {process.env.REACT_APP_ENV === 'dev' && <div className={classes.tabSection}>
            <Tabs
              value={this.state.tabValue}
              onChange={(event, newValue) => {
                console.log(newValue)
                if(newValue === 1){
                  let redirectPath = `/app/products/org-categories`;
                  this.props.history.push(redirectPath);
                }
                this.setState({ tabValue: newValue });
              }}
              aria-label="simple tabs example"
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabWidth}
            >
              <Tab
                className={classes.orgProductTab}
                label="Expent Categories"
                key="expent-categories"
              />
              <Tab
                className={classes.orgProductTab}
                label={this.props.orgName ? this.props.orgName + "'s Categories" : "Categories"}
                key="inventory"
              />
            </Tabs>
          </div>}

          {this.state.tabValue === 0 && <>
            {this.state.productType === 'SOFTWARE' &&
              <div className={classes.categoriesList} id="allCategories">
                <InfiniteScroll
                  scrollableTarget="allCategories"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    marginRight: 20,
                  }} //To put endMessage and loader to the top.
                  dataLength={this.props.categories?.length} //This is important field to render the next data
                  next={() => {
                    var me = this;
                    this.setState({ page: this.state.page + 1 }, () => {
                      me.fetchData();
                    });
                  }}
                  hasMore={
                    this.props.categories.length ===
                    (this.state.page + 1) * this.state.pageSize
                  }
                  endMessage={
                    <p
                      style={{
                        textAlign: "center",
                        minHeight: 100,
                        paddingTop: 20,
                      }}
                    >
                      {this.props.categories.length === 0 &&
                        this.props.categoryFetchProgress === false && (
                          <span>No More Data</span>
                        )}
                    </p>
                  }
                >
                  <Grid container spacing={3}>
                    {this.props.categories.map((category, k) => {
                      return (
                        <Grid item lg={2} md={3} xs={12} sm={6} key={"cat_" + k}>
                          <LayeredCard
                            title={category.Name}
                            numTitleLines={3}
                            subTitle=""
                            image={
                              category.Logo == null || category.Logo.length === 0
                                ? DefaultImage
                                : category.Logo
                            }
                            onClick={() => {
                              this.props.history.push(
                                `/${this.props.isSeller ? "sellerapp" : "app"}/products/category-wise-product/` +
                                category.ID
                              );
                            }}
                            showSubtitle={false}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </InfiniteScroll>
                {this.props.categoryFetchProgress === true && (
                  <div className={classes.backdrop}>
                    {" "}
                    <CircularProgress />
                  </div>
                )}
              </div>
            }

            {this.state.productType === 'HARDWARE' &&
              <div className={classes.categoriesList} id="allCategories">
                <InfiniteScroll
                  scrollableTarget="allCategories"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    marginRight: 20,
                  }} //To put endMessage and loader to the top.
                  dataLength={this.state.hardwareCategories?.length} //This is important field to render the next data
                  next={() => {
                    var me = this;
                    this.setState({ page: this.state.page + 1 }, () => {
                      me.fetchData();
                    });
                  }}
                  hasMore={
                    this.state.hardwareCategories.length ===
                    (this.state.page + 1) * this.state.pageSize
                  }
                  endMessage={
                    <p
                      style={{
                        textAlign: "center",
                        minHeight: 100,
                        paddingTop: 20,
                      }}
                    >
                      {this.state.hardwareCategories.length === 0 &&
                        this.props.categoryFetchProgress === false && (
                          <span>No More Data</span>
                        )}
                    </p>
                  }
                >
                  <Grid container spacing={3}>
                    {this.state.hardwareCategories.map((category, k) => {
                      return (
                        <Grid item lg={2} md={3} xs={12} sm={6} key={"cat_" + k}>
                          <LayeredCard
                            title={category.Name}
                            numTitleLines={3}
                            subTitle=""
                            image={
                              category.Logo == null || category.Logo.length === 0
                                ? DefaultImage
                                : category.Logo
                            }
                            onClick={() => {
                              this.props.history.push(
                                `/${this.props.isSeller ? "sellerapp" : "app"}/products/category-wise-hardware-product/` +
                                category.ID
                              );
                            }}
                            showSubtitle={false}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </InfiniteScroll>
                {this.props.categoryFetchProgress === true && (
                  <div className={classes.backdrop}>
                    {" "}
                    <CircularProgress />
                  </div>
                )}
              </div>
            }
          </>}

        </div>
      </div>
    );
  }
}

AllCategories.defaultProps = {
  isSeller: false
}

export default connector(
  compose(withStyles(styles), withRouter)(AllCategories)
);
