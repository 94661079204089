import { Axios } from "./index";
import * as Environment from "./../util/Environment";
import { clientTokenHeader } from "./../util";

export class UserFactory {
    static _instance;

    static instance() {
        if (UserFactory._instance == null) {
            UserFactory._instance = new UserFactory();
        }
        return UserFactory._instance;
    }

    async login(email, password) {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/login?t=${timestamp}`;


        let response;
        const headers = clientTokenHeader()
        try {
            response = await Axios.post(url, {
                email: email,
                password: password
            }, { headers });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        console.log(response.data);
        window.localStorage.setItem("authToken", response.data['authToken']);
        window.localStorage.setItem("authType", response.data['authType']);
        return [response.data, null];
    }

    async clientToken() {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/client-token?t=${timestamp}`;

        let response;
        try {
            response = await Axios.post(url, {});
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        window.localStorage.setItem("clientToken", response.data['clientToken']);
        return [response.data, null];
    }

    async logout() {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/logout?t=${timestamp}`;

        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if (authToken == null || authType == null || authToken === undefined || authType == null) {
            return [null, { 'message': "User logged out.", "status": -1 }];
        }
        let response;
        try {
            response = await Axios.post(url, {}, {
                headers: { 'Authorization': authType + ' ' + authToken }
            });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("authType");
        return true;
    }

    async register(name, email, company, oid, rid) {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/register?t=${timestamp}`;

        let response;
        const headers = clientTokenHeader()

        let params = {
            email: email,
            name: name,
            company: company
        }

        if (oid && rid) {
            params['request_id'] = rid;
            params['org_id'] = oid;
            params["is_seller"] = true;
        }

        try {
            response = await Axios.post(url, params, { headers });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        console.log(response.data);
        return [response.data, null];
    }

    async registerSSO(token, name, company) {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/sso-register?t=${timestamp}`;

        let response;
        try {
            response = await Axios.post(url, {
                token: token,
                name: name,
                company: company
            });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        console.log(response.data);
        window.localStorage.setItem("authToken", response.data['authToken']);
        window.localStorage.setItem("authType", response.data['authType']);
        return [response.data, null];
    }

    async verifyAccount(token, password) {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/verifyAccount?t=${timestamp}`;

        let response;
        try {
            response = await Axios.post(url, {
                token: token,
                password: password
            });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        console.log(response.data);
        return [response.data, null];
    }

    async forgotPassword(email) {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/forgotPassword?t=${timestamp}`;

        let response;
        const headers = clientTokenHeader()
        try {
            response = await Axios.post(url, {
                email: email
            }, { headers });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        console.log(response.data);
        return [response.data, null];
    }

    async resetPassword(token, password) {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/resetPassword?t=${timestamp}`;

        let response;
        const headers = clientTokenHeader()
        try {
            response = await Axios.post(url, {
                token: token,
                password: password
            }, { headers });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        return [response.data, null];
    }

    async self() {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/self?t=${timestamp}`;

        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if (authToken == null || authType == null || authToken === undefined || authType == null) {
            return [null, { 'message': "User logged out.", "status": -1 }];
        }

        let response;
        try {
            response = await Axios.get(url, {
                headers: { 'Authorization': authType + ' ' + authToken }
            });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        return [response.data, null];
    }

    async enableSSO(driver) {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/cognito-login/${driver}/enable-sso?t=${timestamp}`;

        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if (authToken == null || authType == null || authToken === undefined || authType == null) {
            return [null, { 'message': "User logged out.", "status": -1 }];
        }

        let response;
        try {
            response = await Axios.post(url, {}, {
                headers: { 'Authorization': authType + ' ' + authToken }
            });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        return [response.data, null];
    }

    async disableSSO(driver) {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/cognito-login/${driver}/disable-sso?t=${timestamp}`;

        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if (authToken == null || authType == null || authToken === undefined || authType == null) {
            return [null, { 'message': "User logged out.", "status": -1 }];
        }

        let response;
        try {
            response = await Axios.post(url, {}, {
                headers: { 'Authorization': authType + ' ' + authToken }
            });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        return [response.data, null];
    }

    async updateSSOIDP(driver, idpURl) {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/cognito-login/${driver}/idpUrl?t=${timestamp}`;

        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if (authToken == null || authType == null || authToken === undefined || authType == null) {
            return [null, { 'message': "User logged out.", "status": -1 }];
        }

        let response;
        try {
            response = await Axios.put(url, {
                "idp_url": idpURl
            }, {
                headers: { 'Authorization': authType + ' ' + authToken }
            });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        return [response.data, null];
    }

    async updatePassword(oldPassword, newPassword) {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/updatePassword?t=${timestamp}`;

        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if (authToken == null || authType == null || authToken === undefined || authType == null) {
            return [null, { 'message': "User logged out.", "status": -1 }];
        }

        let response;
        try {
            response = await Axios.post(url, {
                old_password: oldPassword,
                new_password: newPassword
            }, {
                headers: { 'Authorization': authType + ' ' + authToken }
            });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        return [response.data, null];
    }

    async finishInitialFlow() {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/finishInitialFlow?t=${timestamp}`;

        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if (authToken == null || authType == null || authToken === undefined || authType == null) {
            return [null, { 'message': "User logged out.", "status": -1 }];
        }

        let response;
        try {
            response = await Axios.post(url, {
            }, {
                headers: { 'Authorization': authType + ' ' + authToken }
            });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        return [response.data, null];
    }

    async addMembers(members) {
        const api_server = Environment.api_host("USER");
        const timestamp = (new Date()).getTime();
        const url = `${api_server}/addTeamMembers?t=${timestamp}`;

        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if (authToken == null || authType == null || authToken === undefined || authType == null) {
            return [null, { 'message': "User logged out.", "status": -1 }];
        }

        let response;
        try {
            response = await Axios.post(url, {
                members: members
            }, {
                headers: { 'Authorization': authType + ' ' + authToken }
            });
        } catch (error) {
            if (error.message === "Network Error") {
                //TODO Handle Network Error
                return [null, { 'message': "Unable to connect to Server." }];
            } else if (error.response !== undefined) {
                return [null, error.response.data];
            }
        }
        if (response.data === undefined) {
            return [null, { "message": "Invalid Response from Server." }]
        }
        return [response.data, null];
    }



}