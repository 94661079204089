import rootReducer from './reducers';
import { applyMiddleware, createStore } from "redux";
import { logger } from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";

let middleware;
var isLogDebug = window.localStorage.getItem('redux_loging')

if (isLogDebug === 'true') {
    middleware = applyMiddleware(promise, thunk, logger);
} else {
    middleware = applyMiddleware(promise, thunk);
}

export default () => {
    return createStore(rootReducer, middleware);
}


