import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, CircularProgress, IconButton, Paper,
  Typography, MenuItem, FormControl, Select
} from '@material-ui/core';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

import { fetchCatalogTasks, deleteCatalogTask, fetchCatalogFollowUpTasks, fetchCatalogRiskTasks, fetchCatalogTaskOnly } from "redux/vendor/task/action";
import { showSnackBar } from "redux/snackbar/action";
import { CommonFn } from "services/commonFn";

const connectedProps = (state) => ({
  fetchCatalogTasksProgress: state.catalogTasks.fetchCatalogTasksProgress,
  fetchCatalogTasksError: state.catalogTasks.fetchCatalogTasksError,
  catalogTasks: state.catalogTasks.catalogTasks,
  deleteCatalogTaskProgress: state.catalogTasks.deleteCatalogTaskProgress,
  deleteCatalogTaskError: state.catalogTasks.deleteCatalogTaskError,
  fetchCatalogOnlyTasksProgress: state.catalogTasks.fetchCatalogOnlyTasksProgress,
  fetchCatalogOnlyTasksError: state.catalogTasks.fetchCatalogOnlyTasksError,
  fetchCatalogRiskTasksProgress: state.catalogTasks.fetchCatalogRiskTasksProgress,
  fetchCatalogRiskTasksError: state.catalogTasks.fetchCatalogRiskTasksError,
  fetchCatalogFollowUpTasksProgress: state.catalogTasks.fetchCatalogFollowUpTasksProgress,
  fetchCatalogFollowupTasksError: state.catalogTasks.fetchCatalogFollowupTasksError,
  catalogOnlyTasks: state.catalogTasks.catalogOnlyTasks,
  catalogRiskTasks: state.catalogTasks.catalogRiskTasks,
  catalogFollowupTasks: state.catalogTasks.catalogFollowupTasks,
  updateNewTaskProgress: state.catalogTasks.updateNewTaskProgress,
  updatedTaskData: state.catalogTasks.updatedTaskData,
  updateNewTaskError: state.catalogTasks.updateNewTaskError,
  addNewTaskProgress: state.catalogTasks.addNewTaskProgress,
  addNewTaskError: state.catalogTasks.addNewTaskError,
});

const connectionActions = {
  fetchCatalogTasks: fetchCatalogTasks,
  deleteCatalogTask: deleteCatalogTask,
  showSnackBar: showSnackBar,
  fetchCatalogFollowUpTasks: fetchCatalogFollowUpTasks,
  fetchCatalogRiskTasks: fetchCatalogRiskTasks,
  fetchCatalogTaskOnly: fetchCatalogTaskOnly
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    "& [class*='editableField']": {
      minHeight: 'auto !important',
    },
  },
  nameCol: {
    width: '30%'
  },
  statusCol: {
    width: '10%'
  },
  dueDateCol:{
    width: '20%'
  },
  actionCol: {
    textAlign: 'center',
    width: '20%'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25
  },
  responseFilter: {
    marginRight: 20
  },
  filterTitle1: {
    marginBottom: 1,
    color: "#282D30",
    fontSize: 14,
  },
  formControl: {
    width: 200,
    "& > div": {
      color: "hsl(0,0%,50%)",
      fontSize: 14,
      background: '#fff'
    },
  },
});

class VendorAndServiceTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeFilter: "all",
      priorityFilter: "all",
      statusFilter: "all",
      tasks: [],
      loader: true,
      priorityMap: {
        'low': 'Low',
        'medium': 'Medium',
        'high': 'High',
      },
      statusMap: {
        'new': 'New',
        'in_progress': 'In Progress',
        'completed': 'Completed',
      },
      page: 0,
      pageSize: 5,
      totalTasks: 0,
      isDataLoading: true,
      typeFilterDropdown: [{
        name: 'All',
        value: 'all_types'
      }, {
        name: 'Task',
        value: 'task'
      }, {
        name: 'Follow Up',
        value: 'follow_up'
      }],
      priorityFIlterDropdown: [{
        name: 'All',
        value: 'all'
      }, {
        name: 'Low',
        value: 'low'
      }, {
        name: 'Medium',
        value: 'medium'
      }, {
        name: 'High',
        value: 'high'
      }],
      statusFilterDropdown: [{
        name: 'All',
        value: 'all'
      }, {
        name: 'New',
        value: 'new'
      }, {
        name: 'In Progress',
        value: 'in_progress'
      }, {
        name: 'Completed',
        value: 'completed'
      }],
    }
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.taskFiltering = this.taskFiltering.bind(this);
    this.priorityFiltering = this.priorityFiltering.bind(this);
    this.statusFiltering = this.statusFiltering.bind(this);
  }

  componentDidMount() {
    this.setState({
      typeFilter: this.props.filterTypes,
    }, () => {
      this.fetchData()
    })
  }

  componentDidUpdate(prevProps) {
    
    if (this.props.fetchCatalogTasksProgress === false && prevProps.fetchCatalogTasksProgress === true && this.props.from === 'EVALUATION') {
      if (this.props.fetchCatalogTasksError === null) {
        this.setState({
          loader: false,
          tasks: this.props.catalogTasks,
          totalTasks: this.props.catalogTasks.length,
          isDataLoading: false
        })
      }
    }

    if (!this.props.addNewTaskProgress && prevProps.addNewTaskProgress) {
      if (this.props.addNewTaskError === null) {
        this.fetchData()
      }
    }

    if (this.props.deleteCatalogTaskProgress === false && prevProps.deleteCatalogTaskProgress === true) {
      if (this.props.deleteCatalogTaskError === null) {
        this.fetchData()
        this.props.showSnackBar("Task deleted successfully.", "success", 3000);
      } else {
        this.props.showSnackBar("Something went wrong to delete task.", "error", 3000)
      }
    }

    if (this.props.fetchCatalogOnlyTasksProgress === false && prevProps.fetchCatalogOnlyTasksProgress === true && this.props.type === "task") {
      if (this.props.fetchCatalogOnlyTasksError === null) {
        // console.log(this.props.catalogOnlyTasks, 'catalogOnlyTasks')
        this.setState({
          loader: false,
          tasks: this.props.catalogOnlyTasks,
          totalTasks: this.props.catalogOnlyTasks?.length,
          isDataLoading: false
        })
      }else{
        this.setState({
          loader: false,
          isDataLoading: false
        })
      }
    }

    if (this.props.fetchCatalogRiskTasksProgress === false && prevProps.fetchCatalogRiskTasksProgress === true && this.props.type === "risk") {
      if (this.props.fetchCatalogRiskTasksError === null) {
        // console.log(this.props.catalogRiskTasks, 'catalogRiskTasks')
        this.setState({
          loader: false,
          tasks: this.props.catalogRiskTasks,
          totalTasks: this.props.catalogRiskTasks?.length,
          isDataLoading: false
        })
      }else{
        this.setState({
          loader: false,
          isDataLoading: false
        })
      }
    }

    if (this.props.fetchCatalogFollowUpTasksProgress === false && prevProps.fetchCatalogFollowUpTasksProgress === true && this.props.type === "follow_up") {
      if (this.props.fetchCatalogFollowupTasksError === null) {
        // console.log(this.props.catalogFollowupTasks, 'catalogFollowupTasks')
        this.setState({
          loader: false,
          tasks: this.props.catalogFollowupTasks,
          totalTasks: this.props.catalogFollowupTasks?.length,
          isDataLoading: false
        })
      }else{
        this.setState({
          loader: false,
          isDataLoading: false
        })
      }
    }

    if (!this.props.updateNewTaskProgress && prevProps.updateNewTaskProgress) {
      if (this.props.updateNewTaskError === null) {
        this.setState({
          loader: false,
          isDataLoading: false
        })
        const { from, type } = this.props;
        if (from === 'EVALUATION') {
          this.fetchCatalogTasksData();
        } else if (from === 'ORG_VENDOR_PAGE') {
          if (type === "task") {
            this.fetchTasksOnly();
          } else if (type === "follow_up") {
            this.fetchFollowUpTasks()
          } else if (type === "risk") {
            this.fetchRiskTasks();
          }
        }
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

  }

  fetchData() {
    this.setState({
      loader: false,
      isDataLoading: false
    })
    const { entityId, entityType, from } = this.props;
    const { typeFilter, priorityFilter, statusFilter } = this.state;
    var queryParams = {
      // page: this.state.page,
      // pageSize: this.state.pageSize,
      // requirement_ids: requirementIds,
      // priority: priorityFilter,
      // status: statusFilter,
      item_slug: typeFilter,
      entity_id: entityId,
      entity_type: entityType === "vendor" ? "vendor" : "vendorservice",
    }
    if(priorityFilter !== "all"){
      queryParams['priority'] = priorityFilter;
    }
    if(statusFilter !== "all"){
      queryParams['status'] = statusFilter;
    }
    if (from === 'EVALUATION') {
      this.props.fetchCatalogTasks(queryParams);
    } else if (from === 'ORG_VENDOR_PAGE') {
      if (typeFilter === "task") {
        this.props.fetchCatalogTaskOnly(queryParams);
      } else if (typeFilter === "follow_up") {
        this.props.fetchCatalogFollowUpTasks(queryParams);
      } else if (typeFilter === "risk") {
        this.props.fetchCatalogRiskTasks(queryParams);
      }
    }
  }

  fetchCatalogTasksData() {
    const { entityId, entityType } = this.props;
    const { typeFilter, priorityFilter, statusFilter } = this.state;
    var queryParams = {
      // page: this.state.page,
      // pageSize: this.state.pageSize,
      // requirement_ids: requirementIds,
      // priority: priorityFilter,
      // status: statusFilter,
      item_slug: typeFilter,
      entity_id: entityId,
      entity_type: entityType === "vendor" ? "vendor" : "vendorservice",
    }

    if(priorityFilter !== "all"){
      queryParams['priority'] = priorityFilter;
    }
    if(statusFilter !== "all"){
      queryParams['status'] = statusFilter;
    }
    this.props.fetchCatalogTasks(queryParams);
  }

  fetchTasksOnly() {
    const { entityId, entityType } = this.props;
    const { typeFilter, priorityFilter, statusFilter } = this.state;
    var queryParams = {
      // page: this.state.page,
      // pageSize: this.state.pageSize,
      // requirement_ids: requirementIds,
      // priority: priorityFilter,
      // status: statusFilter,
      item_slug: typeFilter,
      entity_id: entityId,
      entity_type: entityType === "vendor" ? "vendor" : "vendorservice",
    }
    if(priorityFilter !== "all"){
      queryParams['priority'] = priorityFilter;
    }
    if(statusFilter !== "all"){
      queryParams['status'] = statusFilter;
    }
    this.props.fetchCatalogTaskOnly(queryParams);
  }

  fetchFollowUpTasks() {
    const { entityId, entityType } = this.props;
    const { typeFilter, priorityFilter, statusFilter } = this.state;
    var queryParams = {
      // page: this.state.page,
      // pageSize: this.state.pageSize,
      // requirement_ids: requirementIds,
      // priority: priorityFilter,
      // status: statusFilter,
      item_slug: typeFilter,
      entity_id: entityId,
      entity_type: entityType === "vendor" ? "vendor" : "vendorservice",
    }
    if(priorityFilter !== "all"){
      queryParams['priority'] = priorityFilter;
    }
    if(statusFilter !== "all"){
      queryParams['status'] = statusFilter;
    }
    this.props.fetchCatalogFollowUpTasks(queryParams);
  }

  fetchRiskTasks() {
    const { entityId, entityType } = this.props;
    const { typeFilter, priorityFilter, statusFilter } = this.state;
    var queryParams = {
      // page: this.state.page,
      // pageSize: this.state.pageSize,
      // requirement_ids: requirementIds,
      // priority: priorityFilter,
      // status: statusFilter,
      item_slug: typeFilter,
      entity_id: entityId,
      entity_type: entityType === "vendor" ? "vendor" : "vendorservice",
    }
    if(priorityFilter !== "all"){
      queryParams['priority'] = priorityFilter;
    }
    if(statusFilter !== "all"){
      queryParams['status'] = statusFilter;
    }
    this.props.fetchCatalogRiskTasks(queryParams);
  }

  handleChangePage(e, newPage) {
    this.setState({
      page: newPage,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleChangeRowsPerPage(e) {
    this.setState({
      page: 0,
      pageSize: e.target.value,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleEdit(task) {
    this.props.onEdit(task)
  }

  handleDelete(task) {
    this.props.deleteCatalogTask(task.id, task.item_slug, this.props.from);
  }

  taskFiltering(newValue) {
    this.setState({
      typeFilter: newValue
    }, () => {
      this.fetchData()
    })
  }

  priorityFiltering(newValue) {
    this.setState({
      priorityFilter: newValue
    }, () => {
      this.fetchData()
    })
  }

  statusFiltering(newValue) {
    this.setState({
      statusFilter: newValue
    }, () => {
      this.fetchData()
    })
  }

  render() {
    const { classes, showTypeFilter, showPriorityFilter, showStatusFilter } = this.props;
    const { loader, tasks, priorityMap, statusMap, page, pageSize, totalTasks, isDataLoading,
      typeFilter, statusFilter, priorityFilter,
      typeFilterDropdown, priorityFIlterDropdown, statusFilterDropdown } = this.state;
      
    var noTaskMsg = 'No Tasks'
    if (typeFilter === "task") {
        noTaskMsg = 'No Tasks';
    }else if(typeFilter === "follow_up"){
      noTaskMsg = 'No Follow ups';
    }else if(typeFilter === "risk"){
      noTaskMsg = 'No Risks';
    }
    console.log(tasks,'alltask')

    if (loader) {
      return <div style={{ textAlign: 'center', marginTop: 20 }}>
        <CircularProgress />
      </div>
    }

    return (
      <div className={classes.root} key={this.props.key}>
        <div className={classes.filters}>
          {showTypeFilter && <div className={classes.responseFilter}>
            <Typography variant={"h4"} className={classes.filterTitle1}>
              Select Task Type
            </Typography>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <Select
                value={typeFilter}
                onChange={(e) => {
                  this.taskFiltering(e.target.value)
                }}
              >
                {typeFilterDropdown.map((itm) => {
                  return <MenuItem value={itm.value} style={{ fontSize: 13 }}>
                    {itm.name}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </div>}
          {showPriorityFilter && <div className={classes.responseFilter}>
            <Typography variant={"h4"} className={classes.filterTitle1}>
              Select Priority
            </Typography>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <Select
                value={priorityFilter}
                onChange={(e) => {
                  this.priorityFiltering(e.target.value)
                }}
              >
                {priorityFIlterDropdown.map((itm) => {
                  return <MenuItem value={itm.value} style={{ fontSize: 13 }}>
                    {itm.name}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </div>}
          {showStatusFilter && <div className={classes.responseFilter}>
            <Typography variant={"h4"} className={classes.filterTitle1}>
              Select Status
            </Typography>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <Select
                value={statusFilter}
                onChange={(e) => {
                  this.statusFiltering(e.target.value)
                }}
              >
                {statusFilterDropdown.map((itm) => {
                  return <MenuItem value={itm.value} style={{ fontSize: 13 }}>
                    {itm.name}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </div>}
        </div>
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ maxHeight: 560 }}>
            <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.dueDateCol}>Task</TableCell>
                  <TableCell className={classes.nameCol}>Description</TableCell>
                  <TableCell className={classes.statusCol}>Priority</TableCell>
                  <TableCell className={classes.statusCol}>Status</TableCell>
                  <TableCell className={classes.dueDateCol}>Due Date</TableCell>
                  {this.props.updateAccess && (<TableCell className={classes.actionCol}>Action</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isDataLoading && tasks.slice(page * pageSize, page * pageSize + pageSize).map((task) => {
                  return <TableRow key={'task_' + task.id}>
                    <TableCell className={classes.statusCol}>{task?.title}</TableCell>
                    <TableCell className={classes.nameCol}>
                      <SlateInputField
                        readOnly={true}
                        isToolBar={false}
                        placeholder=""
                        style={{
                          margin: 0,
                        }}
                        as={SlateInputField}
                        initValue={task?.description}
                        formControlStyle={{ border: 'none', borderColor: '#fff', overflow: 'hidden', margin: '0px !important' }}
                        textContainerStyle={{ minHeight: '10px !important', margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }} />
                    </TableCell>
                    <TableCell className={classes.statusCol}>{priorityMap[task?.priority]}</TableCell>
                    <TableCell className={classes.statusCol}>{statusMap[task?.status]}</TableCell>
                    <TableCell className={classes.dueDateCol}>{task?.due_date === null || task?.due_date === '' ? '-' : CommonFn.formateDate(task?.due_date, true)}</TableCell>
                    {this.props.updateAccess && (<TableCell className={classes.actionCol}>
                      <IconButton
                        onClick={() => this.handleEdit(task)}
                      >
                        <Image
                          src={editIcon}
                        />
                      </IconButton>

                      <IconButton
                        onClick={() => this.handleDelete(task)}
                      >
                        <Image
                          src={deleteIcon}
                        />
                      </IconButton>

                    </TableCell>)}
                  </TableRow>
                })}

                {(!isDataLoading && (tasks === undefined || tasks === null || tasks.length <= 0)) && <TableRow>
                  <TableCell colSpan={this.props.updateAccess ? 6 : 5} style={{ textAlign: 'center' }} className={classes.nameCol}>
                    {noTaskMsg}
                  </TableCell>
                </TableRow>}

                {isDataLoading && <TableRow>
                  <TableCell colSpan={this.props.updateAccess ? 6 : 5} style={{ textAlign: 'center' }} className={classes.nameCol}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>}

              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={totalTasks || 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(VendorAndServiceTasks));
