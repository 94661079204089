import {
  Dialog, DialogContent, Slide,Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";

import { hideParallelComponentsDialog } from "redux/workflow/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  parallelComponentsData: state.workflow.parallelComponentsData,
  isOpen: state.workflow.isShowParallelDialog,
});

const connectionActions = {
  hideDialog: hideParallelComponentsDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
      margin: 'auto',
      marginBottom: 60
  },
  close: {
      position: 'absolute',
      right: 20,
      top: 20,
      cursor: 'pointer',
      color: '#6F6F6F'
  },
  title: {
      fontWeight: 500,
      color: '#282D30',
      fontSize: 20,
  },
});

class ShowParallelComponentsDialog extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
          error: "",
          success: "",
      }
  }

  componentDidMount(){
    console.log(this.props.parallelComponentsData,'parallelComponentsData')
  }

  componentDidUpdate(prevProps) {

  }

  render() {
      const classes = this.props.classes;
      return <Dialog
          onClose={this.props.hideDialog}
          aria-labelledby="app-integrationDialog"
          open={this.props.isOpen}
          TransitionComponent={Transition}
          disableBackdropClick={true}
          fullWidth={true}
          maxWidth={"md"}
          scroll="body"
          id="evMetaDialog"
      >
          <DialogContent classes={{ root: classes.dialogContent }} >
              <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
              <Typography variant={"h4"} className={classes.title}>Parallel Items</Typography>
              <div className={classes.body}>

              </div>
          </DialogContent>
      </Dialog>
  }
}

ShowParallelComponentsDialog.defaultProps = {
  isWorkFlow: false,
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ShowParallelComponentsDialog));