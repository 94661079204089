import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import io from "socket.io-client"

import { addNewNotification } from "redux/notification/action";

const connectedProps = (state) => ({
  user: state.authUser.user,
});

const connectionActions = {
  addNewNotification: addNewNotification
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({

});

let socket;

class NotificationSocket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    var url = '';
    if(process.env.REACT_APP_ENV === 'dev'){
      url = "https://devapi.expent.ai";
    }else{
      url = "https://api.expent.ai";
    }
    
    var isMyLocal = window.localStorage.getItem('mylocalpc')
    if(isMyLocal){
      return;
    }
    
    socket = io(url, {
      path: "/notifications/socket.io/",
      withCredentials: false,
    });

    socket.on("connect", (success) => {
      console.log('CallingConnect')
      socket.emit('join-user-notification-channel', JSON.stringify({
        userId: this.props.user.ID
      }))
    });

    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    socket.on("my-notification", (arg) => {
      // console.log(arg, 'Arguments - Notification');
      this.props.addNewNotification(arg);
    });

    socket.on("message", (arg) => {
      // console.log(arg, 'Arguments - Message');
    });

    // socket.on("beacon", (arg) => {
    //   console.log(arg, 'Arguments - beacon');
    // });
  }

  componentDidUpdate(prevProps) {
  }


  render() {
    return (
      <div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(NotificationSocket));
