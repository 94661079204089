import {
    Dialog, DialogContent, Slide, Typography, DialogActions, CircularProgress
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import SqureButton from 'Components/Common/SqureButton';
import ReactSelect from "react-select";
import Alert from '@material-ui/lab/Alert';
import { getOrgWorkflow } from "redux/evaluation/workflow/action";

import { hideAddProductWorkflowDialog } from "redux/product/orgProduct/action.js";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.orgProduct.dialogOrgProductWorkflowState,
    dialogOrgProductWorkflow: state.orgProduct.dialogOrgProductWorkflow,
    orgWorkFlowProgress: state.evaluationWorkflow.orgWorkFlowProgress,
    orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
});

const connectionActions = {
    hideDialog: hideAddProductWorkflowDialog,
    getOrgWorkflow: getOrgWorkflow
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    box: {
        marginTop: 25
    },
    formGroup: {
        marginTop: 5,
        marginBottom: 16
    },
    alert: {
        marginBottom: 10,
        marginTop: 25,
    }
});

class DialogOrgProductWorkflow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            isLoading: true,
            workFlowList: [],
            selectedWorkflow: null,
            workflowName: '',
        }
    }

    componentDidMount() {
        this.fetchOrgWorkFlows();
    }

    componentDidUpdate(prevProps) {
        if (this.props.orgWorkFlowProgress === false && prevProps.orgWorkFlowProgress === true) {
            let workFlowList = [];
            let selectedWorkflow = null;
            this.props.orgWorkFlowList.Data.forEach((item, index) => {
                workFlowList.push({
                    label: item.Name,
                    value: item.ID
                })
                if (item.ID === this.props.workflowID) {
                    selectedWorkflow = {
                        label: item.Name,
                        value: item.ID
                    }
                }
            })

            this.setState({
                isLoading: false,
                workFlowList: workFlowList,
                selectedWorkflow: selectedWorkflow
            })
        }
    }

    clearError() {
        this.setState({
            error: ''
        })
    }

    submitResponse = (e) => {
        const { selectedWorkflow } = this.state;
        this.clearError();

        if (selectedWorkflow === null) {
            this.setState({
                error: 'Please select workflow.'
            })
            return;
        }
        this.props.saveData(selectedWorkflow);
    }

    fetchOrgWorkFlows = (types) => {
        this.props.getOrgWorkflow({ types: [1], page: 0, pageSize: 1000 })
    }
    render() {
        const classes = this.props.classes;
        const { isLoading, workFlowList } = this.state;

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Request Access</Typography>
                <div className={classes.body}>
                    <div className={classes.alert}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                    </div>

                    {isLoading && <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <CircularProgress />
                    </div>}

                    {!isLoading && <div className={classes.box}>
                        <Typography className={classes.headingForm}>
                            Select Workflow <span className={classes.required}>*</span>
                        </Typography>
                        <ReactSelect
                            isClearable
                            isSearchable={true}
                            menuPosition='fixed'
                            options={workFlowList}
                            classNamePrefix="select"
                            placeholder="Search Workflow"
                            onChange={(e) => {
                                this.setState({
                                    selectedWorkflow: e,
                                }, () => {
                                    this.clearError();
                                })
                            }}
                            value={this.state.selectedWorkflow}
                        />
                    </div>}
                </div>
            </DialogContent>
            <DialogActions>
                <div style={{ padding: '10px 24px' }}>
                    {!this.props.orgAddVendorPocFetchProgress && <>
                        <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                        <SqureButton cls={classes.actionBtn} variant={"contained"}
                            disabled={this.state.error.length > 0}
                            onClick={this.submitResponse}>Save</SqureButton>
                    </>}
                </div>
            </DialogActions>
        </Dialog>
    }
}

DialogOrgProductWorkflow.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(DialogOrgProductWorkflow));