import {START_CATEGORY_FETCH,END_CATEGORY_FETCH,
    START_ALL_CATEGORY_FETCH,END_ALL_CATEGORY_FETCH,
    START_NEW_CATEGORY_SEARCH, END_NEW_CATEGORY_SEARCH,
START_CATEGORY_SEARCH,END_CATEGORY_SEARCH} from "./action";

const initState   =     {
    fetchCategoryProgress:false,
    categories:[],
    totalCategories: 0,

    searchCategoryProgress:false,
    searchCategories:[],
    totalSearchCategories: 0,

    fetchAllCategoryProgress:false,
    allCategories:null,
    fetchAllCategoryError: null,

    newSearchCategoryProgress:false,
    newSearchCategories:[],
}

export  function productCategoryReducer(state=initState,action){
    switch(action.type){
        case START_NEW_CATEGORY_SEARCH:
            return {
                ...state,
                newSearchCategoryProgress:true,
                newSearchCategories:[]
            };
        case END_NEW_CATEGORY_SEARCH: {
            let newSearchCategories = state.newSearchCategories
            if(action.payload.success !== null){
                newSearchCategories = action.payload.success.data
            }
            return {
                ...state,
                newSearchCategoryProgress:false,
                newSearchCategories
            }
        }
        case START_ALL_CATEGORY_FETCH:
            return {
                ...state,
                fetchAllCategoryProgress:true,
                allCategories:null,
                fetchAllCategoryError:null,
            };
        case END_ALL_CATEGORY_FETCH:
            var success     =   action.payload.success;
            var error     =   action.payload.error;
            let _data = null;
            let _error = null;
            if(error){
                _data = null;
                _error = error;
            }else{
                _data = success;
                _error = null;
            }
            
            return {
                ...state,
                fetchCategoryProgress: false,
                fetchAllCategoryProgress:false,
                allCategories: _data,
                fetchAllCategoryError: _error
            }

        case START_CATEGORY_FETCH:
            var categories = state.categories;
            if(action.payload == 0){
                categories = []
            }
            return {
                ...state,
                fetchCategoryProgress:true,
                categories:categories
            };
        case END_CATEGORY_FETCH: {
            let success     =   action.payload.success;
            let categories     =   state.categories;
            let total = state.totalCategories;
            if(success !== null){
                let Page    =   success.page;
                let Data    =   success.Data;
                    total = success?.total || 0
                if(Page == 0){
                    categories     =  Data; 
                } else {
                    categories     = categories.concat(Data)
                }
            }
            return {
                ...state,
                fetchCategoryProgress:false,
                categories:categories,
                totalCategories: total
            }
        }
        case START_CATEGORY_SEARCH:
            var scategories = state.searchCategories;
            if(action.payload == 0){
                scategories = []
            }
            return {
                ...state,
                searchCategoryProgress:true,
                searchCategories:scategories
            };
        case END_CATEGORY_SEARCH: {
            let success     =   action.payload.success;
            let scategories     =   state.searchCategories;
            let total = state.totalSearchCategories;
            if(success !== null){
                let Page    =   success.page;
                let Data    =   success.Data;
                    total = success?.total || 0
                if(Page == 0){
                    scategories     =  Data; 
                } else {
                    scategories     = scategories.concat(Data)
                }
            }
            return {
                ...state,
                searchCategoryProgress:false,
                searchCategories:scategories,
                totalSearchCategories: total
            }
        }
        default:
            return state;
    }
}