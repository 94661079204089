/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {UserFactory} from "../../services/user";

export const START_UPDATEINITIALFLOWFLAG     =   "START_UPDATEINITIALFLOWFLAG";
export const END_UPDATEINITIALFLOWFLAG       =   "END_UPDATEINITIALFLOWFLAG";

//Action to start the registration
function startUpdateOfInitialFlaf(){
    return {
        type:START_UPDATEINITIALFLOWFLAG,
        payload:null
    };
}

function endUpdateOfInitialFlaf(success,error){
    return {
        type:END_UPDATEINITIALFLOWFLAG,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the register
export  function updateInitalFlowFlag(token,password){
    return async (dispatch) => {
        dispatch(startUpdateOfInitialFlaf());
        try{
            let [response,error]    =   await UserFactory.instance().finishInitialFlow();
            dispatch(endUpdateOfInitialFlaf(response,error));
        } catch(e){
            dispatch(endUpdateOfInitialFlaf(null,{
                message:e.message
            }));
            return;
        }
    };
}   