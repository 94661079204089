import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, Typography, CircularProgress, Button, Grid} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {hideUserUploadDialog} from "../../../../../redux/usersSettings/action";
import {fetchUser, addTeamMembers} from "../../../../../redux/okta/action";
import { uploadFile } from "../../../../../redux/usersSettings/action";
import exportImage from "assets/images/export.svg"
import DeleteIcon from '@material-ui/icons/Delete';
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import classnames from "classnames";
import CommonTable from "../../../../Common/Table";
import {CommonFn} from "../../../../../services/commonFn";
import {fetchUsers} from "../../../../../redux/usersSettings/action";
import Slack from "assets/images/slack.png";
import Zendesk from "assets/images/zendesk.png";
import Asana from "assets/images/asana.png";
import CardAction from "../../../../Common/CardAction";
import Image from 'Components/Common/image.jsx';
import downloadTemplate from 'assets/templates/bulk_upload.csv';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    userUploadDialogOpen: state.usersSettings.userUploadDialogOpen,
    okta: state.okta,
    uploadedUserList: state.usersSettings.uploadedUserList,
    uploadUserError: state.usersSettings.uploadUserError,
    isUploadUserProgress: state.usersSettings.isUploadUserProgress,
});

const connectionActions = {
    hideUserUploadDialog: hideUserUploadDialog,
    fetchUser: fetchUser,
    fetchUsers: fetchUsers,
    uploadFile: uploadFile,
    addTeamMembers: addTeamMembers
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent:{
        padding: theme.spacing(6, 12) +" !important"
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(2.5),
    },

    uploadSection: {
        textAlign: 'center',
        border: '1px dotted #333',
        padding: theme.spacing(6, 0),
        "&.activeDrop": {
            border: '2px dotted #4a88ff',
        }
    },

    uploadText: {
        fontSize: 20,
        color: '#12344D',
    },

    uploadSubText: {
        color: '#475867'
    },

    uploadAnchor: {
        color: '#0091FF !important',
        textDecoration: 'underline',
        opacity: 0,
        width: 0
    },
    btnSection: {
        marginTop: 20,
        textAlign: 'center',
    },
    delete: {
        fill: "#dc2f3e",
        cursor: 'pointer',
    },
    fileText: {
        maxWidth: 300,
        wordBreak: 'break-all',
        fontSize: 14,
        border: '1px solid #333',
        borderRadius: 3,
        marginRight: 20,
        padding: 5,
        marginTop: 0,
        marginBottom: 0,
    },
    fileNameSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    labelFile: {
        color: '#0091FF',
        marginLeft: 5
    },
    button: {
    },
    arrow: {
        paddingLeft: 5
    },
    downloadTemplate: {
        textDecoration: 'underline',
        color: '#0091FF !important',
    },
    progress: {
        textAlign: 'center',
        paddingTop: 15
    },
    tbodyCell: {
        minHeight: 40
    },
    selectAll: {
        border: 0,
        color: '#0091FF',
        background: '#fff',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 15,

        "&:focus": {
            outline: 'none'
        }
    },
    headSection: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    userCount: {
        color: '#282D30',
        fontSize: 17,
    },
    buttonSection: {
        textAlign: 'center',
        marginTop: 40
    },
    checked: {
        opacity: 0,
        pointerEvents: 'none',
        width: 50
    },
    alert: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    cardAction: {
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        position: 'relative',
        marginBottom: 0,
        border: "1px solid #E2E2E2",
        
        '& [class*="MuiSvgIcon-root"]': {
            fill: '#74798C'
        },
    },

    primary: {
        textAlign: 'center',
        fontSize: theme.spacing(2.4),
        marginTop: theme.spacing(1),
        marginBottom:theme.spacing(0.5)
    },

    secondary: {
        textAlign: 'center',
    },

    content: {
        padding: theme.spacing(2, 2, 0, 2)
    },
    cardSection: {
        "& [class*='MuiGrid-item']": {
            padding: 14
        },

        "& [class*=CardAction-iconSection]": {
            position: 'absolute',
            bottom: 24,
            right: 24
        }
    },
});

class UserUploadDialog extends React.Component {

    constructor(props){
        super(props);
        this.fileRef = React.createRef();
        this.state = {
            searchQuery: '',
            fileName: '',
            activeOnDrop: "",
            file: null,
            error: '',
            userList: [],
            productList: []
        }
    }

    componentDidMount(){
       
    }

    componentDidUpdate(prevProps){
        if(this.props.userUploadDialogOpen === true && prevProps.userUploadDialogOpen === false) {
            this.setState({'error': '' })
        }

        if(!this.props.okta?.addTeamMemberProgress && prevProps.okta?.addTeamMemberProgress) {
            if(this.props.okta?.addTeamMemberError){
                this.setState({'error':this.props.okta?.addTeamMemberError?.message })
            } else if(this.props.userUploadDialogOpen) {
                this.props.hideUserUploadDialog();
                this.props.fetchUsers(0, 20, '');
            }
        }

        if(!this.props.isUploadUserProgress && prevProps.isUploadUserProgress) {
            if(this.props.uploadUserError){
                this.setState({'error':this.props.uploadUserError?.message })
            }
        }

        if(this.props.uploadedUserList !== prevProps.uploadedUserList) {
            this.setState({userList: this.props.uploadedUserList})
        } 
    }

    fileUpload(event) {
        if(event.target.files[0]) {
            this.setState({fileName: event.target.files[0].name, file: event.target.files[0]})
        }
    }

    deleteFile = () => {
        this.setState({fileName: ''});
        this.fileRef.current.value = '';
    }

    dropHandler = (event) => {
        this.setState({activeOnDrop: ''})
        event.preventDefault();
        if(event.dataTransfer.files[0]) {
            this.setState({fileName: event.dataTransfer.files[0].name,  file: event.dataTransfer.files[0]});
        }
    }

    dragOverHandler = (event) => {
        event.preventDefault();
        if(!this.state.activeOnDrop) {
            this.setState({activeOnDrop: 'activeDrop'})
        }
    }

    dragLeaveHandler = () => {
        if(this.state.activeOnDrop) {
            this.setState({activeOnDrop: ''})
        }
    }

    uploadSelectedFile = () => {
        if(!this.props.isFromProduct) {
            const formData = new FormData();
            formData.append('file', this.state.file);
            this.props.uploadFile(formData);
        } else {
            this.setState({productList: [ { primary: "Zendesk", secondary: "Customer support", image : Slack,  activeImage: true, link: '' },
            { primary: "Slack", secondary: "Customer support", image : Zendesk, activeImage: true, link: '' },
            { primary: "Office 365", secondary: "Customer support", image : Asana, activeImage: true, link: '' },
            { primary: "Asana", secondary: "Customer support", image : Asana,  activeImage: true, link: '' },]})
        }
    }

    clickOnCell = (bodyData, parentIndex, childIndex, array, item, actionItem, event) => {
        let bodyDataLocal = bodyData;
        if(item.key === 'checked') {
            bodyDataLocal[parentIndex]['checked'] = !bodyDataLocal[parentIndex]['checked'];
        }

        if(item.key === 'role') {
            console.log(bodyDataLocal[parentIndex]['role'], event.target.value)
            bodyDataLocal[parentIndex]['role'] = event.target.value;
        }

        this.setState({userList: bodyDataLocal})
    }

    selectAll() {
        let userList = this.state.userList.map((user) => {return {...user, checked: true}})
        this.setState({userList: userList})
    }

    addUser = (users) => {
        this.setState({'error': '' })
        let userList =  []
        users.map((user) => {
            if(user.checked) {
                userList.push({
                    "name": user.name,
                    "email": user.email,
                    "role": user.role,
                    "team": user.team
                })
            }
        })

        if(userList.length) {
            this.props.addTeamMembers(userList);
        }
    }

    userCheckedList = (users) => {
        let usersList = [];
        if(users) {
            usersList = users.filter((i)=>(i.checked))
        }
        return usersList.length === 0;
    }

    cancel = () => {
        this.setState({
            searchQuery: '',
            fileName: '',
            activeOnDrop: "",
            file: null,
            error: '',
            userList: [],
            productList: []
        });
        this.props.hideUserUploadDialog();
    }

    render() {
        const classes = this.props.classes;

        const tableData = { 
            class: classes.table, 
            isProgress: false,
            head: [ 
                {label: '', key:'checked', type:'checkbox' , hide: false, headClass: classes.checked, cellClass: classes.tbodyCell },
                {label: 'Name', key:'name', type:'text',  headClass: classes.thead, cellClass: classes.tbodyCell }, 
                {label: 'E-mail', key:'email',  type:'text',  headClass: classes.thead, cellClass: classes.tbodyCell}, 
                {label: 'Team',  key:'team',  type:'text',  headClass: classes.thead, cellClass: classes.tbodyCell}, 
                {label: 'Role', key:'role',  type:'select', headClass: classes.action, cellClass: classes.tbodyCell, options: CommonFn.roleOptions()}, 
            ]
        };

        let userList = this.state.userList?.map((user) => {
            return {...user, team: user.team, role: user.role === undefined ? 'OrgUser': user.role, checked: user.checked  === undefined ? false: user.checked  }
        })

        return <Dialog 
                    onClose={this.props.hideUserUploadDialog} 
                    aria-labelledby="add-upload-dialog" 
                    open={this.props.userUploadDialogOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"md"}
                    scroll={"body"}
                    >
                    <DialogContent classes={{root:classes.dialogContent}}>
                            <Typography variant={"h6"} className={classes.title}> {this.props.isFromProduct ? 'Add new vendor': 'Bulk upload'}</Typography>
                            <div className={classes.alert}>
                                {   this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                            </div>

                            {/* file upload section*/}
                            { ((this.props.isFromProduct === false && userList?.length === 0) || (this.props.isFromProduct === true && this.state.productList?.length === 0)) && <div>
                                <div className={classnames(classes.uploadSection, this.state.activeOnDrop )}  onDrop={(event)=> this.dropHandler(event)} onDragOver={(event)=> this.dragOverHandler(event)} onDragLeave={()=>this.dragLeaveHandler()}>
                                    <Image src={exportImage} alt="export image"/>
                                    <p className={classes.uploadText}>Upload {this.props.isFromProduct ? 'products': 'Users'}</p>
                                    <p className={classes.uploadSubText}>Drag and drop your CSV file or 
                                    <label htmlFor="inputFile" className={classes.labelFile}>Click here</label>
                                    <input ref={this.fileRef} id="inputFile" type="file" className={classes.uploadAnchor}  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(event)=> this.fileUpload(event)}/> to upload</p>
                                    <p> <a className={classes.downloadTemplate}  href={downloadTemplate} download>Download template</a></p>
                                    { this.state.fileName !== "" && <div className={classes.fileNameSection}>
                                        <p className={classes.fileText}>{this.state.fileName}</p>
                                        <DeleteIcon className={classes.delete} onClick={()=> this.deleteFile()}/>
                                    </div> }
                                </div>
                                <div>
                                    {(this.props.isUploadUserProgress === true) && <div className={classes.progress}><CircularProgress /></div>}
                                </div>
                                { (this.props.isUploadUserProgress === false) && <div className={classes.btnSection}>
                                    <Button variant="outlined" color="secondary" className={classes.button} onClick={this.cancel}>Cancel</Button>
                                    &nbsp;
                                    &nbsp;
                                    <Button variant="contained" color="secondary" className={classes.button} onClick={this.uploadSelectedFile} disabled={this.state.fileName? false: true }> Import {this.props.isFromProduct ? 'product': 'users'}  <ArrowForwardIcon className={classes.arrow}/></Button>
                                </div>
                                }   
                            </div>}

                            {/* imported users preview section*/}
                            {
                                this.props.isFromProduct === false && userList?.length !== 0 && <div>
                                    <div className={classes.headSection}>
                                            <p className={classes.userCount}>{userList?.length || '0'} user{ userList?.length > 1 ? 's': ''} found</p>
                                            <button className={classes.selectAll} onClick={()=> this.selectAll()}>Select all</button>
                                    </div>
                                    <div>
                                        <CommonTable tableData={tableData} bodyData={ userList } parentClickEvent={this.clickOnCell}/>
                                        {(this.props.okta?.fetchOktaUserProg === true || this.props.okta?.addTeamMemberProgress === true) && <div className={classes.progress}><CircularProgress /></div>}
                                    </div>
                                    {(this.props.okta?.fetchOktaUserProg === false && this.props.okta?.addTeamMemberProgress === false) && <div className={classes.buttonSection}>
                                        <Button variant="outlined" color="secondary" className={classes.button} onClick={this.cancel}>Cancel</Button>
                                        &nbsp;
                                        &nbsp;
                                        <Button variant="contained" color="secondary" disabled={this.userCheckedList(userList)} className={classes.button} onClick={() => this.addUser(userList)}>Add User <ArrowForwardIcon className={classes.arrow}/></Button>
                                    </div>}
                                </div>
                            }

                            {  this.props.isFromProduct === true && this.state.productList?.length !== 0 && 
                               <div>
                                    <div className={classes.cardSection}>
                                     <Grid container spacing={3}>
                                        {this.state.productList.map((e, index) => (
                                                <Grid item lg={3} md={3} key={index}>
                                                <CardAction 
                                                    link={e.link}
                                                    image={e.image}
                                                    primary={e.primary}
                                                    secondary={e.secondary}
                                                    activeImage={e.activeImage}
                                                    cardActionClass={classes.cardAction}
                                                    primaryClass={classes.primary}
                                                    secondaryClass={classes.secondary}
                                                    contentClass={classes.Content}
                                                />
                                            </Grid>
                                        ))}
                                        </Grid> 
                                </div>
                                <div>
                                    {<div className={classes.buttonSection}>
                                    <Button variant="outlined" color="secondary" className={classes.button} onClick={this.cancel}>Cancel</Button>
                                    &nbsp;
                                    &nbsp;
                                    <Button variant="contained" color="secondary" disabled={this.userCheckedList(userList)} className={classes.button} onClick={() => this.addUser(userList)}>Add vendor <ArrowForwardIcon className={classes.arrow}/></Button>
                                </div>}
                                </div>
                               </div>
                            }
                    </DialogContent>
                </Dialog>
    }
}
UserUploadDialog.defaultProps = {
    isFromProduct: false
};

export default connector(compose(
    withStyles(styles)
)(UserUploadDialog));