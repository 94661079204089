import {
  START_VENDOR_CATALOG_FETCH,
  END_VENDOR_CATALOG_FETCH,
  START_FETCH_VISUALIZATION_CONTRACT_DATA,
  END_FETCH_VISUALIZATION_CONTRACT_DATA,
  START_VISUALIZATION_VENDOR_CATALOG_FETCH,
  END_VISUALIZATION_VENDOR_CATALOG_FETCH,
  START_VENDOR_CATALOG_TAB_DATA_FETCH,
  END_VENDOR_CATALOG_TAB_DATA_FETCH,
  START_ORG_VENDOR_ADD,
  END_ORG_VENDOR_ADD,
  SHOW_ADD_VENDOR_DIALOG,
  HIDE_ADD_VENDOR_DIALOG,
  START_DELETE_ORG_VENDOR,
  END_DELETE_ORG_VENDOR,
  START_UPDATE_ORG_VENDOR_DETAILS,
  END_UPDATE_ORG_VENDOR_DETAILS,
  START_UPDATE_ORG_VENDOR_TAB_SECTION_ITEM_DATA,
  END_UPDATE_ORG_VENDOR_TAB_SECTION_ITEM_DATA,
  START_FETCH_ORG_CATALOG_BUILDER_TABS,
  END_FETCH_ORG_CATALOG_BUILDER_TABS,
  START_FETCH_EXT_ORG_CATALOG_BUILDER_TABS,
  END_FETCH_EXT_ORG_CATALOG_BUILDER_TABS,
  START_FETCH_ORG_CATALOG_TABS,
  END_FETCH_ORG_CATALOG_TABS,
  START_ADD_ORG_VENDOR_BUILDER_TAB,
  END_ADD_ORG_VENDOR_BUILDER_TAB,
  START_UPDATE_ORG_VENDOR_BUILDER_TAB,
  END_UPDATE_ORG_VENDOR_BUILDER_TAB,
  START_DELETE_ORG_VENDOR_BUILDER_TAB,
  END_DELETE_ORG_VENDOR_BUILDER_TAB,
  START_ADD_ORG_VENDOR_BUILDER_TAB_SECTION,
  END_ADD_ORG_VENDOR_BUILDER_TAB_SECTION,
  START_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION,
  END_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION,
  START_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION,
  END_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION,
  START_ADD_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
  END_ADD_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
  START_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
  END_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
  START_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
  END_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM,
  START_ORG_CATALOG_STATE_UPDATE,
  END_ORG_CATALOG_STATE_UPDATE,
  START_FETCH_SECTION_EXT_USERS,
  END_FETCH_SECTION_EXT_USERS,
  START_FETCH_TEAMS_DATA,
  END_FETCH_TEAMS_DATA,
  START_FETCH_CONTRACT_DATA,
  END_FETCH_CONTRACT_DATA
} from "./action";

const initState = {
  fetchVendorCatalogProgress: false,
  fetchVendorCatalogError: null,
  VendorCatalog: null,

  fetchVisualizationVendorCatalogError: null,
  tableVisualizationDatas: {},
  fetchVisualizationVendorCatalogProgress: {},

  fetchVendorCatalogTabDataProgress: false,
  fetchVendorCatalogTabDataError: null,
  vendorCatalogTabData: {},

  orgVendorAddProgress: false,
  orgVendorAddError: null,
  orgVendorAddSuccess: null,

  deleteOrgVendorProgress: false,
  deleteOrgVendorError: null,

  showAddVendorDialog: false,

  orgVendorDetailsUpdateProgress: false,
  orgVendorDetailsUpdateError: null,

  orgVendorTabSectionItemDataUpdateProgress: false,
  orgVendorTabSectionItemDataUpdateError: null,

  orgCatalogAllTabs: [],
  orgCatalogAllTabsProgress: false,

  orgCatalogDetailTabs: [],
  orgCatalogDetailTabsProgress: false,
  orgCatalogDetailTabsError: null,

  addOrgVendorDetailTabsProgress: false,
  addOrgVendorDetailTabsError: null,

  updateOrgVendorDetailTabsProgress: false,
  updateOrgVendorDetailTabsSuccess: null,
  updateOrgVendorDetailTabsError: null,

  deleteOrgVendorDetailTabsProgress: false,
  deleteOrgVendorDetailTabsError: null,

  addOrgVendorDetailTabsSectionProgress: false,
  addOrgVendorDetailTabsSectionError: null,
  addOrgVendorDetailTabsSectionSuccess: null,

  updateOrgVendorDetailTabsSectionProgress: false,
  updateOrgVendorDetailTabsSectionSuccess: null,
  updateOrgVendorDetailTabsSectionError: null,

  deleteOrgVendorDetailTabsSectionProgress: false,
  deleteOrgVendorDetailTabsSectionError: null,

  addOrgVendorDetailTabsSectionItemProgress: false,
  addOrgVendorDetailTabsSectionItemError: null,

  updateOrgVendorDetailTabsSectionItemProgress: false,
  updateOrgVendorDetailTabsSectionItemError: null,

  deleteOrgVendorDetailTabsSectionItemProgress: false,
  deleteOrgVendorDetailTabsSectionItemError: null,

  updateOrgCatalogStateError: null,
  updateOrgCatalogStateProgress: true,

  orgExtUserCatalogDetailTabs: [],
  orgExtUserCatalogDetailTabsProgress: false,

  sectionExternalUsers: [],
  sectionExternalUsersProgress: false,

  teamsData: [],
  teamsDataProgress: false,

  contractData: [],
  fetchContractProg: false,

  fetchVisualizationDataError: null,
  tableVisualizationContracts: {},
  fetchVisualizationContractProg: {},
};

export function vendorCatalogReducer(state = initState, action) {
  switch (action.type) {
    case START_FETCH_CONTRACT_DATA:
      return {
          ...state,
          contractData: state.contractData,
          fetchContractProg: true,
      }
    case END_FETCH_CONTRACT_DATA:
      return {
          ...state,
          contractData: action.payload.success || [],
          fetchContractProg: false,
      }
    case START_FETCH_VISUALIZATION_CONTRACT_DATA:
      return {
        ...state,
        fetchVisualizationContractProg: {...state.fetchVisualizationContractProg, [action.payload.id]: true},
        fetchVisualizationDataError: null,
      }

    case END_FETCH_VISUALIZATION_CONTRACT_DATA:
      let contractData = action.payload.success;
      const Vid = action.payload.id;
      let tableDataItems = {...state.tableVisualizationContracts, [Vid]: contractData};

      return {
        ...state,
        fetchVisualizationDataError: action.payload.error,
        tableVisualizationContracts: tableDataItems,
        fetchVisualizationContractProg: {...state.fetchVisualizationContractProg, [action.payload.id]: false},
      }
  
    case START_ORG_CATALOG_STATE_UPDATE:
      return {
        ...state,
        updateOrgCatalogStateError: null,
        updateOrgCatalogStateProgress: true,
      };
    case END_ORG_CATALOG_STATE_UPDATE:
      return {
        ...state,
        updateOrgCatalogStateError: action.payload.error,
        updateOrgCatalogStateProgress: false,
      };
    case START_VENDOR_CATALOG_FETCH:
      return {
        ...state,
        fetchVendorCatalogProgress: true,
        fetchVendorCatalogError: null,
      };

    case END_VENDOR_CATALOG_FETCH: {
      let currentData = { ...state.VendorCatalog };
      if (action.payload?.searchQuery?.page > 0) {
        currentData?.items?.push(...action.payload?.success?.items);
      } else {
        currentData = action.payload.success;
      }
      return {
        ...state,
        fetchVendorCatalogProgress: false,
        fetchVendorCatalogError: action.payload.error,
        VendorCatalog: currentData,
      };
    }
    case START_VISUALIZATION_VENDOR_CATALOG_FETCH:
      return {
        ...state,
        fetchVisualizationVendorCatalogProgress: {...state.fetchVisualizationVendorCatalogProgress, [action.payload.id]: true},
        fetchVisualizationVendorCatalogError: null,
      }
    case END_VISUALIZATION_VENDOR_CATALOG_FETCH:
      let successFetchData = action.payload.success;
      const id = action.payload.id;
      let tableVisualizationDatas = {...state.tableVisualizationDatas, [id]: successFetchData};
      return {
        ...state,
        fetchVisualizationVendorCatalogError: action.payload.error,
        tableVisualizationDatas: tableVisualizationDatas,
        fetchVisualizationVendorCatalogProgress: {...state.fetchVisualizationVendorCatalogProgress, [action.payload.id]: false},
      }
    case START_VENDOR_CATALOG_TAB_DATA_FETCH:
      return {
        ...state,
        fetchVendorCatalogTabDataProgress: true,
        fetchVendorCatalogTabDataError: null,
      };

    case END_VENDOR_CATALOG_TAB_DATA_FETCH: {
      return {
        ...state,
        fetchVendorCatalogTabDataProgress: false,
        fetchVendorCatalogTabDataError: action.payload.error,
        vendorCatalogTabData: action.payload.success,
      };
    }
    case SHOW_ADD_VENDOR_DIALOG:
      return {
        ...state,
        addVendorDialogOpen: true,
      };

    case HIDE_ADD_VENDOR_DIALOG:
      return {
        ...state,
        addVendorDialogOpen: false,
      };
    case START_DELETE_ORG_VENDOR:
      return {
        ...state,
        deleteOrgVendorProgress: true,
        deleteOrgVendorError: null,
      };
    case END_DELETE_ORG_VENDOR:
      return {
        ...state,
        deleteOrgVendorProgress: false,
        deleteOrgVendorError: action.payload.error,
      };
    case START_UPDATE_ORG_VENDOR_DETAILS:
      return {
        ...state,
        orgVendorDetailsUpdateProgress: true,
        orgVendorDetailsUpdateError: null,
      };
    case END_UPDATE_ORG_VENDOR_DETAILS:
      return {
        ...state,
        orgVendorDetailsUpdateProgress: false,
        orgVendorDetailsUpdateError: action.payload.error,
      };
    case START_UPDATE_ORG_VENDOR_TAB_SECTION_ITEM_DATA:
      return {
        ...state,
        orgVendorTabSectionItemDataUpdateProgress: true,
        orgVendorTabSectionItemDataUpdateError: null,
      };
    case END_UPDATE_ORG_VENDOR_TAB_SECTION_ITEM_DATA:
      let { item_slug, success } = action.payload;

      let newVendorCatalogTabData = { ...state.vendorCatalogTabData };
      if (success && item_slug) {
        newVendorCatalogTabData[item_slug] = success;
      }
      return {
        ...state,
        vendorCatalogTabData: newVendorCatalogTabData,
        orgVendorTabSectionItemDataUpdateProgress: false,
        orgVendorTabSectionItemDataUpdateError: action.payload.error,
      };

    case START_ORG_VENDOR_ADD:
      return {
        ...state,
        orgVendorAddProgress: true,
        orgVendorAddError: null,
        orgVendorAddSuccess: null,
      };
    case END_ORG_VENDOR_ADD:
      return {
        ...state,
        orgVendorAddProgress: false,
        orgVendorAddError: action.payload.error,
        orgVendorAddSuccess: action.payload.success,
      };
    case START_FETCH_ORG_CATALOG_TABS:
      return {
        ...state,
        orgCatalogAllTabsProgress: true,
        orgCatalogAllTabs: state.orgCatalogAllTabs,
      };

    case END_FETCH_ORG_CATALOG_TABS: {
      const { success } = action.payload;
      let orgCatalogAllTabs = [];
      if (success !== null) {
        orgCatalogAllTabs = success || orgCatalogAllTabs;
      }
      return {
        ...state,
        orgCatalogAllTabs,
        orgCatalogAllTabsProgress: false,
      };
    }
    
    case START_FETCH_EXT_ORG_CATALOG_BUILDER_TABS:
      return {
        ...state,
        orgExtUserCatalogDetailTabsProgress: true,
        orgExtUserCatalogDetailTabs: [],
      };

    case END_FETCH_EXT_ORG_CATALOG_BUILDER_TABS: {
      const { success } = action.payload;
      let orgExtUserCatalogDetailTabs = [];
      if (success !== null) {
        orgExtUserCatalogDetailTabs = success || orgExtUserCatalogDetailTabs;
      }
      return {
        ...state,
        orgExtUserCatalogDetailTabs,
        orgExtUserCatalogDetailTabsProgress: false,
      };
    }
    case START_FETCH_SECTION_EXT_USERS:
      return {
        ...state,
        sectionExternalUsersProgress: true,
        sectionExternalUsers: [],
      };

    case END_FETCH_SECTION_EXT_USERS: {
      const { success } = action.payload;
      let sectionExternalUsers = [];
      if (success !== null) {
        sectionExternalUsers = success || sectionExternalUsers;
      }
      return {
        ...state,
        sectionExternalUsers,
        sectionExternalUsersProgress: false,
      };
    }
    case START_FETCH_TEAMS_DATA:
      return {
        ...state,
        teamsDataProgress: true,
        teamsData: [],
      };

    case END_FETCH_TEAMS_DATA: {
      const { success } = action.payload;
      let teamsData = [];
      if (Array.isArray(success)) {
        teamsData = success || teamsData;
      }
      return {
        ...state,
        teamsData,
        teamsDataProgress: false,
      };
    }
    case START_FETCH_ORG_CATALOG_BUILDER_TABS:
      return {
        ...state,
        orgCatalogDetailTabsProgress: true,
        orgCatalogDetailTabs: state.orgCatalogDetailTabs,
        orgCatalogDetailTabsError: null,
      };

    case END_FETCH_ORG_CATALOG_BUILDER_TABS: {
      const { success } = action.payload;
      let orgCatalogDetailTabs = [];
      if (success !== null) {
        orgCatalogDetailTabs = success || orgCatalogDetailTabs;
      }
      return {
        ...state,
        orgCatalogDetailTabs,
        orgCatalogDetailTabsProgress: false,
        orgCatalogDetailTabsError: action.payload.error,
      };
    }

    case START_ADD_ORG_VENDOR_BUILDER_TAB:
      return {
        ...state,
        addOrgVendorDetailTabsProgress: true,
        addOrgVendorDetailTabsError: null,
      };

    case END_ADD_ORG_VENDOR_BUILDER_TAB: {
      const { success, error } = action.payload;
      let orgCatalogAllTabs = state.orgCatalogAllTabs;
      if (success !== null) {
        orgCatalogAllTabs.push(success);
      }
      return {
        ...state,
        orgCatalogAllTabs,
        addOrgVendorDetailTabsProgress: false,
        addOrgVendorDetailTabsError: error,
      };
    }

    case START_UPDATE_ORG_VENDOR_BUILDER_TAB:
      return {
        ...state,
        updateOrgVendorDetailTabsProgress: true,
        updateOrgVendorDetailTabsSuccess: null,
        updateOrgVendorDetailTabsError: null,
      };

    case END_UPDATE_ORG_VENDOR_BUILDER_TAB: {
      const { success, error, tabId } = action.payload;
      let orgCatalogAllTabs = state.orgCatalogAllTabs;
      if (success !== null) {
        const tabIndex = orgCatalogAllTabs.findIndex((obj) => obj.id === tabId);
        orgCatalogAllTabs[tabIndex] = success || orgCatalogAllTabs;
      }
      return {
        ...state,
        orgCatalogAllTabs,
        updateOrgVendorDetailTabsProgress: false,
        updateOrgVendorDetailTabsSuccess: success,
        updateOrgVendorDetailTabsError: error,
      };
    }

    case START_DELETE_ORG_VENDOR_BUILDER_TAB:
      return {
        ...state,
        deleteOrgVendorDetailTabsProgress: true,
        deleteOrgVendorDetailTabsError: null,
      };

    case END_DELETE_ORG_VENDOR_BUILDER_TAB: {
      const { success, error, tabId } = action.payload;
      let orgCatalogAllTabs = state.orgCatalogAllTabs;
      if (success !== null) {
        const tabIndex = orgCatalogAllTabs.findIndex((obj) => obj.id === tabId);
        orgCatalogAllTabs.splice(tabIndex, 1);
      }
      return {
        ...state,
        orgCatalogAllTabs,
        deleteOrgVendorDetailTabsProgress: false,
        deleteOrgVendorDetailTabsError: error,
      };
    }

    case START_ADD_ORG_VENDOR_BUILDER_TAB_SECTION:
      return {
        ...state,
        addOrgVendorDetailTabsSectionProgress: true,
        addOrgVendorDetailTabsSectionSuccess: null,
        addOrgVendorDetailTabsSectionError: null,
      };

    case END_ADD_ORG_VENDOR_BUILDER_TAB_SECTION: {
      const { success, tabId, error } = action.payload;
      let orgCatalogAllTabs = state.orgCatalogAllTabs;
      if (success !== null && tabId !== null) {
        const tabIndex = orgCatalogAllTabs.findIndex((obj) => obj.id === tabId);
        if (Array.isArray(orgCatalogAllTabs[tabIndex].sections)) {
          orgCatalogAllTabs[tabIndex].sections.push(...success);
        } else {
          orgCatalogAllTabs[tabIndex].sections = success;
        }
      }
      return {
        ...state,
        orgCatalogAllTabs,
        addOrgVendorDetailTabsSectionProgress: false,
        addOrgVendorDetailTabsSectionError: error,
        addOrgVendorDetailTabsSectionSuccess: success,
      };
    }

    case START_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION:
      return {
        ...state,
        updateOrgVendorDetailTabsSectionProgress: true,
        updateOrgVendorDetailTabsSectionSuccess: null,
        updateOrgVendorDetailTabsSectionError: null,
      };

    case END_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION: {
      const { success, tabId, sectionId, error } = action.payload;
      let orgCatalogAllTabs = state.orgCatalogAllTabs;
      if (success !== null) {
        const tabIndex = orgCatalogAllTabs.findIndex((obj) => obj.id === tabId);
        const OrgVendorTabSections = orgCatalogAllTabs[tabIndex].sections;
        const sectionIndex = OrgVendorTabSections.findIndex((obj) => obj.id === sectionId);
        OrgVendorTabSections[sectionIndex] = success;
        orgCatalogAllTabs[tabIndex].sections = OrgVendorTabSections;
      }
      return {
        ...state,
        orgCatalogAllTabs,
        updateOrgVendorDetailTabsSectionProgress: false,
        updateOrgVendorDetailTabsSectionSuccess: success,
        updateOrgVendorDetailTabsSectionError: error,
      };
    }

    case START_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION:
      return {
        ...state,
        deleteOrgVendorDetailTabsSectionProgress: true,
        deleteOrgVendorDetailTabsSectionError: null,
      };

    case END_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION: {
      const { success, error, tabId, sectionId } = action.payload;
      let orgCatalogAllTabs = [...state.orgCatalogAllTabs];
      if (success !== null) {
        const tabIndex = orgCatalogAllTabs.findIndex((obj) => obj.id === tabId);
        const OrgVendorTabSections = [...orgCatalogAllTabs[tabIndex].sections];
        const sectionIndex = OrgVendorTabSections.findIndex((obj) => obj.id === sectionId);
        OrgVendorTabSections.splice(sectionIndex, 1);
        orgCatalogAllTabs[tabIndex].sections = OrgVendorTabSections;
      }
      return {
        ...state,
        orgCatalogAllTabs,
        deleteOrgVendorDetailTabsSectionProgress: false,
        deleteOrgVendorDetailTabsSectionError: error,
      };
    }

    case START_ADD_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM:
      return {
        ...state,
        addOrgVendorDetailTabsSectionItemProgress: true,
        addOrgVendorDetailTabsSectionItemError: null,
      };

    case END_ADD_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM: {
      const { success, tabId, sectionId, error } = action.payload;
      let orgCatalogAllTabs = [...state.orgCatalogAllTabs];
      if (success !== null && tabId !== null) {
        const tabIndex = orgCatalogAllTabs.findIndex((obj) => obj.id === tabId);

        if (orgCatalogAllTabs[tabIndex].sections) {
          const OrgVendorTabSections = [...orgCatalogAllTabs[tabIndex].sections];
          const sectionIndex = OrgVendorTabSections.findIndex((obj) => obj.id === sectionId);
          if (Array.isArray(OrgVendorTabSections[sectionIndex].items)) {
            OrgVendorTabSections[sectionIndex].items.push(...success);
          } else {
            OrgVendorTabSections[sectionIndex].items = success;
          }
          orgCatalogAllTabs[tabIndex].sections = OrgVendorTabSections;
        }
      }
      return {
        ...state,
        orgCatalogAllTabs,
        addOrgVendorDetailTabsSectionItemProgress: false,
        addOrgVendorDetailTabsSectionItemError: error,
      };
    }

    case START_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM:
      return {
        ...state,
        updateOrgVendorDetailTabsSectionProgress: true,
        updateOrgVendorDetailTabsSectionError: null,
      };

    case END_UPDATE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM: {
      const { success, tabId, sectionId, itemId, error } = action.payload;
      let orgCatalogAllTabs = [...state.orgCatalogAllTabs];
      if (success !== null) {
        const tabIndex = orgCatalogAllTabs.findIndex((obj) => obj.id === tabId);
        const OrgVendorTabSections = [...orgCatalogAllTabs[tabIndex].sections];
        const sectionIndex = OrgVendorTabSections.findIndex((obj) => obj.id === sectionId);
        const OrgVendorTabItems = [...OrgVendorTabSections[sectionIndex].items];
        const itemIndex = OrgVendorTabItems.findIndex((obj) => obj.id === itemId);
        OrgVendorTabItems[itemIndex] = success;
        OrgVendorTabSections[sectionIndex].items = OrgVendorTabItems;
        orgCatalogAllTabs[tabIndex].sections = OrgVendorTabSections;
      }
      return {
        ...state,
        orgCatalogAllTabs,
        updateOrgVendorDetailTabsSectionProgress: false,
        updateOrgVendorDetailTabsSectionError: error,
      };
    }

    case START_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM:
      return {
        ...state,
        deleteOrgVendorDetailTabsSectionItemProgress: true,
        deleteOrgVendorDetailTabsSectionItemError: null,
      };

    case END_DELETE_ORG_VENDOR_BUILDER_TAB_SECTION_ITEM: {
      const { success, error, tabId, sectionId, itemId } = action.payload;
      let orgVendorDetailTabs_Data = [...state.orgCatalogAllTabs];
      if (success !== null) {
        const tabIndex = orgVendorDetailTabs_Data.findIndex((obj) => obj.id === tabId);
        const OrgVendorTabSections = [...orgVendorDetailTabs_Data[tabIndex].sections];
        const sectionIndex = OrgVendorTabSections.findIndex((obj) => obj.id === sectionId);
        const OrgVendorTabItems = [...OrgVendorTabSections[sectionIndex].items];
        const itemIndex = OrgVendorTabItems.findIndex((obj) => obj.id === itemId);
        OrgVendorTabItems.splice(itemIndex, 1);
        OrgVendorTabSections[sectionIndex].items = OrgVendorTabItems;
        orgVendorDetailTabs_Data[tabIndex].sections = OrgVendorTabSections;
      }

      return {
        ...state,
        orgCatalogAllTabs: orgVendorDetailTabs_Data,
        deleteOrgVendorDetailTabsSectionItemProgress: false,
        deleteOrgVendorDetailTabsSectionItemError: error,
      };
    }

    default:
      return state;
  }
}
