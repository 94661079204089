import { Button, Drawer, Link, List } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
// icons
import { HelpOutline as HelpOutlineIcon, Home as HomeIcon } from "@material-ui/icons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import classnames from "classnames";
// components
import Image from "Components/Common/image.jsx";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
//redux
import { storeSearchString } from "redux/applications/action";
import { authorizedUser } from "redux/authuser/action";
import { updateDrawerStyle } from "redux/drawer/action";
// util
import { getConfigValue, isEnabledReview, isEnabledEvaluate, isSyncActivityLog, isEnabledProjectTracking } from "util/Common";
import Evaluate from "../../assets/images/nav/evaluate.svg";
import EvaluateActive from "../../assets/images/nav/evaluate_active.svg";

import Feedback from "../../assets/images/nav/feedback.svg";
import FeedbackActive from "../../assets/images/nav/feedback_active.svg";

import Metric from "../../assets/images/nav/metric.svg";
import MetricActive from "../../assets/images/nav/metric_active.svg";

import Products from "../../assets/images/nav/products.svg";
import ProductsActive from "../../assets/images/nav/products_active.svg";

import Settings from "../../assets/images/nav/settings.svg";
import SettingsActive from "../../assets/images/nav/settings_active.svg";

import Dashboard from "../../assets/images/nav/dashboard.svg";
import DashboardActive from "../../assets/images/nav/dashboardActive.svg";

import Survey from "../../assets/images/nav/survey.svg";
import SurveyActive from "../../assets/images/nav/surveyActive.svg";

import RenewalInActive from "../../assets/images/nav/renewalInactive.svg";
import RenewalActive from "../../assets/images/nav/renewalActive.svg";

import WorkflowInActive from "../../assets/images/nav/workflowInactive.svg";
import WorkflowActive from "../../assets/images/nav/workflowActive.svg";

import otherInactive from "../../assets/images/nav/otherInactive.svg";
import otherActive from "../../assets/images/nav/otherActive.svg";

// assets
import Logo from "../../assets/images/white_logo.svg";
import DrawerListItem from "Components/Common/DrawerItem/DrawerListItem";
import { getNewPlatform } from "util/Environment";

const connectedProps = (state) => ({
  expanded: state.drawer.expanded,
  show: state.drawer.show,
  user: state.authUser.user,
  orgUser: state.orgUser,
  evaluation: state.evaluationMetaData.evaluation,
});

const connectionActions = {
  storeSearchString: storeSearchString,
  authorizedUser: authorizedUser,
  updateDrawerStyle: updateDrawerStyle,
};

var connector = connect(connectedProps, connectionActions);

const drawerWidthExpanded = 35;
const drawerWidthCompressed = 17;

const styles = (theme) =>
  createStyles({
    drawer: {
      width: theme.spacing(drawerWidthExpanded),
      flexShrink: 0,
      transition: "width 500ms",
      background: "#F7F7F7",
    },
    drawerPaper: {
      width: theme.spacing(drawerWidthExpanded),
      paddingTop: theme.spacing(5),
      background: "#20253A",
    },
    drawerCompressed: {
      width: theme.spacing(drawerWidthCompressed),
      flexShrink: 0,
    },
    drawerPaperCompressed: {
      width: theme.spacing(drawerWidthCompressed),
      paddingTop: theme.spacing(5),
      background: "#20253A",
      transition: "width 300ms",
      "@media (max-width: 991.98px)": {
        width: "6em",
        "& [class*='CDrawer-menu_item-']": {
          display: "flex",
          placeContent: "center",
        },
      },
    },
    logoimage: {
      textAlign: "center",
    },
    menu: {
      width: "90%",
      margin: "0 auto",
      marginTop: theme.spacing(5),
    },
    menu_item: {
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(1.2),
      "&:hover": {
        background: "#a3dcfc33",
        borderRadius: theme.spacing(1.2),
        // "& $menu_item_text": {
        //     color: "#49B8FA",
        // },
        // "& $circle": {
        //     background: "#49B8FA",
        // }
      },
    },
    menu_item_text: {
      color: "#91919C",
    },
    menu_item_icon: {
      minWidth: theme.spacing(6),
    },
    active_menu_item: {
      background: "#a3dcfc33",
      borderRadius: theme.spacing(1.2),
    },
    active_circle: {
      background: "#49B8FA",
    },
    active_menu_item_text: {
      color: "#4A87F8",
    },
    compressed: {
      padding: theme.spacing(2, 4),
    },
    compressedIcon: {
      textAlign: "center",
      margin: "0 auto",
      minWidth: "auto",
      "& img": {
        margin: "0 auto",
      },
      "& svg": {
        margin: "0 auto",
      },
    },
    logoImage: {
      width: 44,
    },
    logoimagesrc: {
      width: "30px !important",
    },
    appDrawer: {
      position: "relative",
      "&:hover": {
        "& .toggleArrow": {
          opacity: 1,
          transition: "opacity .5s",
          "-webkit-transition": "opacity .5s",
        },
      },
      "@media (max-width: 991.98px)": {
        position: "absolute",
      },
    },
    btnToggle: {
      opacity: 0,
      // transition: 'opacity 5s',
      // '-webkit-transition': 'opacity 5s',
      position: "fixed",
      width: "30px",
      height: "30px",
      background: "#ffffff",
      // display: 'inline-block',
      border: "2px solid #202639",
      zIndex: 99999,
      borderRadius: "50%",
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&.max": {
        left: "228px",
      },
      "&.min": {
        left: "102px",
        "& svg": {
          marginLeft: "3px",
        },
      },
      "& svg": {
        width: "17px",
        height: "17px",
        marginLeft: "7px",
      },
      "@media (max-width: 991.98px)": {
        "&.min": {
          left: "68px",
          opacity: 1,
        },
        "&.max": {
          opacity: 1,
        },
      },
    },
    helpButton: {
      backgroundColor: "#1f73b7 !important",
      color: "#FFFFFF !important",
      fill: "#FFFFFF !important",
      padding: "0.52857rem 1.57143rem",
      borderRadius: "999rem",
      letterSpacing: "0.6",
      fontSize: "1.07143rem",
      textDecoration: "none",
      position: "fixed",
      bottom: "10px",
      left: "5px",
      "& span": {
        textDecoration: "none",
      },
    },
  });

const Menu = [
  {
    link: "/app/home",
    newPlatformURL: `${getNewPlatform()}/home`,
    label: "Home",
    applicableFor: ["OrgAdmin", "OrgUser", "OrgManager"],
    active_icon: <HomeIcon style={{ color: "#4A87F8" }} />,
    inactive_icon: <HomeIcon style={{ color: "#91919c" }} />,
  },
  {
    link: "/app/external/products",
    label: "Home",
    applicableFor: ["OrgExternal"],
    active_icon: <HomeIcon style={{ color: "#4A87F8" }} />,
    inactive_icon: <HomeIcon style={{ color: "#91919c" }} />,
  },
  {
    link: "/app/products",
    label: "Vendors",
    newPlatformURL: `${getNewPlatform()}/entity-type-landing/vendor`,
    applicableFor: ["OrgUser", "OrgAdmin", "OrgManager"],
    active_icon: <Image src={ProductsActive} />,
    inactive_icon: <Image src={Products} />,
  },
  {
    link: "/app/evaluate,/app/review,/app/survey,/app/renewal_workflows,/app/other_workflows,/app/workflows_listing",
    secondaryLink: "/app/workflows_listing",
    label: "Projects",
    applicableFor: ["OrgAdmin", "OrgUser", "OrgManager"],
    active_icon: <Image src={WorkflowActive} />,
    inactive_icon: <Image src={WorkflowInActive} />,
    childerens: [
      {
        link: "/app/evaluate",
        label: "Evaluate",
        applicableFor: ["OrgAdmin", "OrgUser", "OrgManager"],
        active_icon: <Image src={EvaluateActive} />,
        inactive_icon: <Image src={Evaluate} />,
      },
      {
        link: "/app/review",
        label: "Review",
        applicableFor: ["OrgAdmin", "OrgUser", "OrgManager"],
        active_icon: <Image src={FeedbackActive} />,
        inactive_icon: <Image src={Feedback} />,
      },
      {
        link: "/app/survey",
        label: "Survey",
        applicableFor: ["OrgAdmin", "OrgUser", "OrgManager"],
        active_icon: <Image src={SurveyActive} />,
        inactive_icon: <Image src={Survey} />,
      },
      // {
      //   link: "/app/renewal_workflows",
      //   label: "Renewal",
      //   applicableFor: ["OrgAdmin"],
      //   active_icon: <Image src={RenewalActive} />,
      //   inactive_icon: <Image src={RenewalInActive} />,
      // },
      {
        link: "/app/other_workflows",
        label: "Custom",
        applicableFor: ["OrgAdmin", "OrgManager"],
        active_icon: <Image src={otherActive} />,
        inactive_icon: <Image src={otherInactive} />,
      },
    ],
  },
  {
    link: "/app/renewal",
    label: "Renewal",
    newPlatformURL: `${getNewPlatform()}/renewals-landing`,
    applicableFor: ["OrgAdmin", "OrgManager"],
    active_icon: <Image src={RenewalActive} />,
    inactive_icon: <Image src={RenewalInActive} />,
  },
  {
    link: "/app/metrics",
    label: "Analytics",
    applicableFor: ["OrgAdmin", "OrgManager"],
    active_icon: <Image src={MetricActive} />,
    inactive_icon: <Image src={Metric} />,
  },
  {
    link: "/app/dashboard-v2",
    label: "Dashboard",
    newPlatformURL: `${getNewPlatform()}/dashboard`,
    applicableFor: ["OrgAdmin", "OrgUser", "OrgManager"],
    active_icon: <Image src={DashboardActive} />,
    inactive_icon: <Image src={Dashboard} />,
  },
  {
    link: "/app/settings",
    label: "Settings",
    applicableFor: ["OrgAdmin"],
    active_icon: <Image src={SettingsActive} />,
    inactive_icon: <Image src={Settings} />,
  },
];

class CDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.authorizedUser();
  }

  componentDidUpdate() { }

  getHideDrawerItem() {
    let hideItem = [];
    let role = this.props.user?.Role;

    if (process.env.REACT_APP_ENV === "production") {
      hideItem.push("Dashboard");
      hideItem.push("Survey");
    }

    if (process.env.REACT_APP_ENV === "test") {
      hideItem.push("Survey");
    }

    if (this.props.user?.Organization?.ID === 67) {
      hideItem.push("Analytics");
      hideItem.push("Evaluate");
    }

    const EnableProjectsforAdminCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableProjectsforAdminCheck");
    const EnableProjectsforUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableProjectsforUsersCheck");

    if ((!EnableProjectsforAdminCheck && role !== "OrgUser") || (!EnableProjectsforUsersCheck && role === "OrgUser")) {
      hideItem.push("Projects");
    }

    const EnableReviewForUsersCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableReviewForUsersCheck");
    const EnableReviewForAdminsCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableReviewForAdminsCheck");

    if ((!EnableReviewForAdminsCheck && role !== "OrgUser") || (!EnableReviewForUsersCheck && role === "OrgUser")) {
      hideItem.push("Review");
    }

    const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForUsersCheck");
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForAdminsCheck");

    if ((!EnableEvaluateForAdminsCheck && role !== "OrgUser") || (!EnableEvaluateForUsersCheck && role === "OrgUser")) {
      hideItem.push("Evaluate");
    }

    // const EnableRenewalForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableRenewalForUsersCheck')
    const EnableRenewalForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableRenewalForAdminsCheck");

    if ((!EnableRenewalForAdminsCheck && role !== "OrgUser") || role === "OrgUser") {
      hideItem.push("Renewal");
    }

    const EnableSurveyForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableSurveyForUsersCheck");
    const EnableSurveyForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableSurveyForAdminsCheck");

    if ((!EnableSurveyForAdminsCheck && role !== "OrgUser") || (!EnableSurveyForUsersCheck && role === "OrgUser")) {
      hideItem.push("Survey");
    }

    const EnableOtherWorkflowsForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableOtherWorkflowsForUsersCheck");
    const EnableOtherWorkflowsForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableOtherWorkflowsForAdminsCheck");

    if ((!EnableOtherWorkflowsForAdminsCheck && role !== "OrgUser") || (!EnableOtherWorkflowsForUsersCheck && role === "OrgUser")) {
      hideItem.push("Custom");
    }

    // if(hideItem.indexOf('Evaluate') !== -1 && hideItem.indexOf('Review') !== -1 && hideItem.indexOf('Renewal') !== -1 && hideItem.indexOf('Survey') !== -1 && hideItem.indexOf('Custom') !== -1){
    //   hideItem.push('Workflows')
    // }

    const GoogleSyncActivityLog = isSyncActivityLog(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "Google_SYNC_ACTIVITY_LOG");
    const OCTASyncActivityLog = isSyncActivityLog(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "Okta_SYNC_ACTIVITY_LOG");
    if (GoogleSyncActivityLog === false && OCTASyncActivityLog === false) {
      hideItem.push("Analytics");
    }

    return hideItem;
  }

  render() {
    let role = this.props.user?.Role;
    const classes = this.props.classes;
    var location = this.props.location.pathname;
    // console.log(location, 'location')
    if (!this.props.show) {
      return null;
    }

    // const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "DisableCatalogFeature");
    // console.log({ DisableVendor });
    var me = this;

    let hideMenus = this.getHideDrawerItem();
    let enableNewPlatform = false
    const EnableVendorCatalogV2 = isEnabledProjectTracking(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableVendorCatalogV2");
    if (EnableVendorCatalogV2) {
      enableNewPlatform = true
    }
    const EndUserHelpLink = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "EndUserHelpLink");
    let showHelp = false;
    let helpLink = "#";
    // console.log(EndUserHelpLink.trim(),role,'i am here')
    if (EndUserHelpLink.trim().length > 0 && role === "OrgUser") {
      showHelp = true;
      helpLink = EndUserHelpLink.trim();
      if (window.zE != undefined) {
        window.zE("webWidget", "hide");
      }
      // console.log('i am here hide')
    } else {
      if (window.zE != undefined) {
        window.zE("webWidget", "show");
      }
      // console.log('i am here show');
    }

    return (
      <>
        <Drawer
          className={classnames(classes.appDrawer, this.props.expanded ? classes.drawer : classes.drawerCompressed)}
          variant="permanent"
          classes={{
            paper: this.props.expanded ? classes.drawerPaper : classes.drawerPaperCompressed,
          }}
          anchor="left"
        >
          <span
            className={classnames(classes.btnToggle, this.props.expanded ? "max" : "min", "toggleArrow")}
            onClick={() => {
              this.props.updateDrawerStyle(!this.props.expanded, true);
            }}
          >
            {this.props.expanded ? <ArrowBackIosIcon fontSize="small" /> : <ArrowForwardIosIcon fontSize="small" />}
          </span>

          <div className={classes.logoimage} id="sidebarLogo">
            <Image
              src={Logo}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.storeSearchString("");
                this.props.history.push("/app");
              }}
              alt="logo"
              className={classnames(classes.logoImage, {
                [classes.logoimagesrc]: !me.props.expanded,
              })}
            />
          </div>

          <List className={classes.menu}>
            {Menu.filter((obj) => obj.applicableFor.indexOf(role) > -1).map((item, k) => {
              var active = false;
              if (location.indexOf(item.link) !== -1 && location !== "/app/renewal_workflows") {
                active = true;
              }

              if (item.childerens !== undefined && item.childerens !== null && item.childerens.length > 0) {
                if (location !== "/app/renewal" && item.link.indexOf(location) !== -1) {
                  active = true;
                } else if (location.indexOf("app/survey") !== -1 || location.indexOf("app/evaluate") !== -1) {
                  active = true;
                }
              }

              if (hideMenus.indexOf(item.label) !== -1) {
                return null;
              }

              return (
                <DrawerListItem
                  label={item.label}
                  key={k + item.label}
                  expanded={this.props.expanded}
                  active={active}
                  link={item.link}
                  item={item}
                  evaluation={this.props.evaluation}
                  hideMenus={hideMenus}
                  onClick={(link) => {
                    this.props.storeSearchString("");
                    if (enableNewPlatform && item.newPlatformURL) {
                      window.open(item.newPlatformURL, "_self")
                    }
                    this.props.history.push(link);
                  }}
                />
              );
            })}
          </List>
          {showHelp && (
            <div>
              <Button component={Link} startIcon={<HelpOutlineIcon />} variant="contained" className={classes.helpButton} target="_blank" href={helpLink}>
                Help
              </Button>
            </div>
          )}
        </Drawer>
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(CDrawer));
