import {
  Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { TextField, FormControl } from '@material-ui/core';
import axios from "axios";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import SqureButton from 'Components/Common/SqureButton';
import { showSnackBar } from "redux/snackbar/action";
import { dataKeys, defaultLicense } from 'services/invoiseParser';

import { hideDocumentDetailDialog } from "redux/dialogs/action";
import { addContractDetails, addOrgDocument } from "redux/product/orgProduct/action.js";
import FoldersTreeSelect from "../../FoldersTreeSelect";
import Select from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
const SelectAsyncPaginate = withAsyncPaginate(Select);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.dialogs.isShowAddDocumentDetailDialog,
  addDetailsData: state.dialogs.addDetailsData,
  orgFetchDocuments: state.orgProduct.orgFetchDocuments,
  orgFetchDocumentsProgress: state.orgProduct.orgFetchDocumentsProgress,
  orgFinanceDetailsProgress: state.orgProduct.orgFinanceDetailsProgress,
  addOrgDocumentProgress: state.orgProduct.addOrgDocumentProgress,
  addOrgDocumentError: state.orgProduct.addOrgDocumentError,
  documentTypes: state.documentType.types,
  contractFoldersData: state.orgProduct.contractFoldersData,
});

const connectionActions = {
  hideDialog: hideDocumentDetailDialog,
  showSnackBar: showSnackBar,
  addContractDetails: addContractDetails,
  addOrgDocument: addOrgDocument
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
    margin: 'auto',
    marginBottom: 30
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  title: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
  },
  subMessage: {
    fontWeight: 400,
    color: '#282D30',
    fontSize: 16,
  },
  bodyitm: {
    marginTop: 20
  },
  actionBtns: {
    marginTop: 20,
    textAlign: 'right'
  },
  formSelect: {
    width: '100%',
  },
  autoSelectInput: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: 10
    }
  },
  itemTitle: {
    fontWeight: 400,
    color: '#282D30',
    fontSize: 14,
    margin: '10px 0px 5px'
  },
});

class AddDocumentDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      name: '',
      user: '',
      type: '',
      url: '',
      parentfolder: null
    }

  }

  componentDidMount() {
    this.setState({
      error: "",
      success: "",
      name: '',
      user: '',
      type: '',
      url: '',
      parentfolder: null
    })
    // console.log(this.props.requestAccInfo, 'requestAccInfo')
  }

  componentDidUpdate(prevProps) {
    if(this.props.isOpen === true && prevProps.isOpen === false){
      this.setState({
        error: "",
        success: "",
        name: '',
        user: '',
        type: '',
        url: '',
        parentfolder: null
      })
    }
  }

  onSave() {
    const { name, type, user, url } = this.state;
    if (name === '') {
      this.props.showSnackBar('Please enter name.', 'error', 3000);
      return;
    }

    if (type === undefined || type === null || type === '') {
      this.props.showSnackBar('Please select type.', 'error', 3000);
      return;
    }

    var urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
      if (url && url.length && !urlRegex.test(url)) {
        this.props.showSnackBar('Please enter valid link. i.e. https://www.abc.xyz', 'error', 3000);
        return;
      }

    let params = {
      documents: [{
        description: type.label,
        title: name,
        url: url,
        folder: this.state.parentfolder?.id,
        documentTypeId: type.value
      }]
    }

    if (user !== undefined && user !== null && user !== '') {
      params.documents[0]['ownerId'] = user.value
    }

    this.props.addOrgDocument(
      this.props.match?.params?.id,
      params
    );
    // this.handleAddDetails()
  }

  handleAddDetails = () => {
    const data = {
      name: 'default',
      path: this.state.fileName,
      type: 'default',
      lineItems: [defaultLicense],
      attributes: []
    }
    Object.keys(dataKeys).forEach(key => {
      if (key !== 'license') {
        const attribute = { ...dataKeys[key], attribute: key }
        if (attribute.type === 'price') {
          attribute.value = `USD${0}`
        }
        attribute.value = String(attribute.value)
        if (attribute.type === 'date') {
          attribute.value = null
        }
        attribute.confidence = 1
        data.attributes.push(attribute)
      }
    })
    this.props.addContractDetails(this.props.match?.params?.id, data)
  }

  onClose() {
    this.props.hideDialog()
  }

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      page: 0,
      pageSize: 20,
      query: inputValue
    };
    const url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    // console.log(response)

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };


  render() {
    const classes = this.props.classes;
    // console.log(this.props.addDetailsData, 'addDetailsData')
    const { name, user, type, url } = this.state;
    const { documentTypes } = this.props;
    const typeOptions = documentTypes.map(o => {
      return { label: o.name, value: o.id }
    })

    return <Dialog
      onClose={this.props.hideDialog}
      aria-labelledby="app-integrationDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"sm"}
      scroll="body"
      id="evMetaDialog"
    >
      <DialogContent classes={{ root: classes.dialogContent }} >
        <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
        <Typography variant={"h4"} className={classes.title}>{this.props.addDetailsData.head}</Typography>
        <div className={classes.bodyitm}>
          <Typography variant={"h5"} className={classes.itemTitle} >Document Name *</Typography>
          <TextField
            className={classes.autoSelectInput}
            value={name}
            fullWidth
            variant="outlined"
            placeholder={this.props.addDetailsData.placeholder}
            type="search"
            onInput={(e) => {
              this.setState({
                name: e.target.value
              })
            }}
          />
        </div>
        <div className={classes.bodyitm}>
          <Typography variant={"h5"} className={classes.itemTitle} >Document Type *</Typography>
          <FormControl className={classes.formSelect} style={{ zIndex: 11 }}>
            <Select
              isClearable
              isMulti={false}
              labelId="singleSelect-select-label"
              id="singleSelect-select"
              isDisabled={false}
              value={type}
              onChange={(e) => {
                this.setState({
                  type: e
                })
              }}
              fullWidth
              options={typeOptions}
              menuPortalTarget={document.body}
              // menuPosition={'fixed'}
              menuPlacement={'auto'}
              placeholder={"Select Document Type"}
              styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
            />
          </FormControl>
        </div>
        <div className={classes.bodyitm}>
          <Typography variant={"h5"} className={classes.itemTitle} >Document Link</Typography>
          <TextField
            className={classes.autoSelectInput}
            value={url}
            fullWidth
            variant="outlined"
            placeholder={"Add link"}
            onInput={(e) => {
              this.setState({
                url: e.target.value
              })
            }}
          />
        </div>
        <div className={classes.bodyitm}>
          <Typography variant={"h5"} className={classes.itemTitle} >Owner</Typography>
          <FormControl className={classes.formSelect} style={{ zIndex: 11 }}>
            <SelectAsyncPaginate
              isClearable={true}
              isMulti={false}
              isSearchable={true}
              value={user}
              menuPosition='fixed'
              classNamePrefix="select"
              isLoading={this.props.userSearchProgress}
              placeholder="Search User"
              additional={{
                page: 0,
              }}
              loadOptions={this.getOptions}
              onChange={(e) => {
                this.setState({
                  user: e
                })
              }}
            />
          </FormControl>
        </div>
        {this.props.contractFoldersData?.meta?.length > 0 && <div className={classes.bodyitm}>
            <FoldersTreeSelect handleChange={(value)=>this.setState({parentfolder: value})} value={this.state.parentfolder}/>
        </div>}
        <div className={classes.actionBtns}>
          <SqureButton
            variant={"outlined"}
            onClick={() => {
              this.onClose()
            }}
          >
            Cancel
          </SqureButton>

          <SqureButton
            style={{ marginLeft: 10 }}
            variant={"contained"}
            onClick={() => {
              this.onSave()
            }}
          >
            Save
          </SqureButton>
        </div>

      </DialogContent>
    </Dialog>
  }
}

AddDocumentDetail.defaultProps = {
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(AddDocumentDetail));