import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  CircularProgress,
  AppBar,
} from "@material-ui/core";
import DefaultImage from "assets/images/evaluation/default.svg";
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import { fetchAppCatalogFields, setAppCatalogFieldsHeaders, showAppCatalogfieldsDialog, hideAppCatalogfieldsDialog } from "redux/product/appCatalog/action";
import FieldsDialog from "./fieldsDialog";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import * as Validator from "util/Validation";
// import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";

const connectedProps = (state) => ({
  user: state.authUser.user,
  showDialog: state.appCatalog.showDialog,
  fetchAppCatalogFieldsProgress: state.appCatalog.fetchAppCatalogFieldsProgress,
  fetchAppCatalogFieldsError: state.appCatalog.fetchAppCatalogFieldsError,
  appcatalogFields: state.appCatalog.appcatalogFields,

  setAppCatalogFieldsHeadersProgress: state.appCatalog.setAppCatalogFieldsHeadersProgress,
  setAppCatalogFieldsHeadersError: state.appCatalog.setAppCatalogFieldsHeadersError,

  fetchAppCatalogProductsProgress: state.appCatalog.fetchAppCatalogProductsProgress,
  fetchAppCatalogProductsError: state.appCatalog.fetchAppCatalogProductsError,
  appCatalogProducts: state.appCatalog.products,
});

const connectionActions = {
  fetchAppCatalogFields: fetchAppCatalogFields,
  setAppCatalogFieldsHeaders: setAppCatalogFieldsHeaders,
  showAppCatalogfieldsDialog: showAppCatalogfieldsDialog,
  hideAppCatalogfieldsDialog: hideAppCatalogfieldsDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    workflowPaper: {
      padding: 20,
    },
    paper: {
      width: "100%",
      overflow: "hidden",
    },
    tableContainer: {
      // maxHeight: '68vh'
    },
    visuallyHidden: {
      marginLeft: 5,
    },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: "1 1 100%",
    },
    columnDrawer: {
      width: 400,
      right: "0",
      padding: "15px",
      zIndex: "9",
    },
    bottomActions: {
      textAlign: "right",
    },
    autoSelectInput: {
      "& [class*=MuiInputBase-input]": {
        padding: "10px !important",
      },
      width: "30%",
    },
    searchBox: {
      padding: theme.spacing(3, 3),
    },
    table: {},
    topHeader: {
      display: "flex",
    },
    tabsSection: {
      flex: 1,
    },
    showBtnSection: {
      flex: 1,
      textAlign: "right",
      paddingTop: 3,
      paddingRight: 10,
    },
    answerBox: {
      backgroundColor: "#fff",
      borderBottom: "1px solid #f1f1f1",
      boxShadow: "none",
    },
    noDataField: {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
    textWrap: {      
      "& [class*='editor-editableField']": {
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
        minHeight: 'unset !important',
        "& p": {
          marginTop: 0
        }
      },
    },
  });

class TableContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "",
      orderBy: "",
      selected: [],
      dense: false,
      page: 0,
      rowsPerPage: 20,
      query: "",
      rows: [],
      columns: [],
      invertDirection: {
        asc: "desc",
        desc: "asc",
      },
      isShowSubMenu: false,
      subMenuEl: null,
      selectedItemIds: [],
      headers: [],
      rowsData: [],
      totalRows: 0,
      basicInfo: [
        {
          id: "name",
          type: "0",
          item_name: "Name",
          sort: true,
          width: 300,
        },
        {
          id: "company_name",
          type: "1",
          item_name: "Company Name",
          sort: true,
        },
      ],
    };
  }

  componentDidMount() {
    this.props.fetchAppCatalogFields({
      action: "options",
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.setAppCatalogFieldsHeadersProgress && prevProps.setAppCatalogFieldsHeadersProgress) {
      if (!this.props.setAppCatalogFieldsHeadersError) {
        this.props.fetchData();
      }
    }

    if ((!this.props.fetchAppCatalogProductsProgress && prevProps.fetchAppCatalogProductsProgress) || (!this.props.fetchAppCatalogProductsError && prevProps.fetchAppCatalogProductsError)) {
      if (!this.props.fetchAppCatalogProductsError) {
        let data = {};
        if (this.props.pageType === 1) {
          data = this.props.appCatalogProducts?.featured || {};
        } else {
          data = this.props.appCatalogProducts?.existing || {};
        }

        const customFields = data.custom_fields || [];
        const emptyFields = data.empty_items || [];
        const basicInfo = data.items || [];
        const total = data?.total || 0;
        let selectedIds = ["Item_name", "Item_company_name"];
        let headers = [...this.state.basicInfo];
        let rowsData = [];
        basicInfo.forEach((O) => {
          let details = [{ name: O.name, logo: O.logo }, O.company_name];
          let productID = O.id;
          rowsData.push({
            productId: productID,
            details: details,
          });
        });
        customFields.forEach((item) => {
          if (item.data && item.items) {
            item.items.forEach((O) => {
              headers.push({
                id: O.id,
                item_name: O.data.item_name,
                type: O.data.type,
              });
              selectedIds.push(`Item_${O.id}`);
            });

            basicInfo.forEach((O) => {
              let productID = O.id;
              let detailsObj = item.data[productID];
              if (!detailsObj) {
                detailsObj = item.items.map((O) => "");
              }
              let index = rowsData.findIndex((O) => O.productId === productID);
              if (index > -1) {
                rowsData[index].details.push(...detailsObj);
              }
            });
          }
        });
        if (emptyFields.length > 0) {
          emptyFields.forEach((O) => {
            headers.push({
              id: O.id,
              item_name: O.item_name,
              type: "1",
              isEmpty: true,
            });
            selectedIds.push(`Item_${O.id}`);
          });
          rowsData.forEach((O, i) => {
            rowsData[i].details.push(...emptyFields.map((row) => ""));
          });
        }

        this.setState({
          headers: headers,
          rowsData: rowsData,
          totalRows: total,
          selectedItemIds: selectedIds,
        });
      }
    }
  }

  setOrder = (order) => {
    this.setState({
      order: order,
    });
  };

  setOrderBy = (orderBy) => {
    this.setState({
      orderBy: orderBy,
    });
  };

  setPage = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.props.onChangePage(page, this.state.rowsPerPage);
      }
    );
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState(
      {
        rowsPerPage: rowsPerPage,
      },
      () => {
        this.props.onChangePage(this.state.page, rowsPerPage);
      }
    );
  };

  updateSearchQuery = (searchQuery) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: 20,
        query: searchQuery,
        order: "",
        orderBy: "",
      },
      () => {
        this.props.onChangeSearch(0, 20, searchQuery);
      }
    );
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property ? this.state.invertDirection[this.state.order] : "asc";
    this.setState(
      {
        page: 0,
      },
      () => {
        this.setOrder(isAsc);
        this.setOrderBy(property);
        this.props.onChangeSort(isAsc, property);
      }
    );
  };

  routeChange = (item) => {
    let redirectPath = "";
    redirectPath = `/app/products/org-product-details/${item.productId}`;
    this.props.history.push(redirectPath);
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
      () => {
        this.props.onChangePage(this.state.page, this.state.rowsPerPage);
      }
    );
  };

  isSelected = (name) => {
    return this.state.selected.indexOf(name) !== -1;
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  setVisibilityForColumns = (item_ids) => {
    this.setState({
      selectedItemIds: item_ids,
    });
    this.props.setAppCatalogFieldsHeaders({
      action: "set",
      item_ids: item_ids,
    });
    this.props.hideAppCatalogfieldsDialog();
  };

  handleChangeTabs = (event, newValue) => {
    this.setState(
      {
        activeWorkflowTab: newValue,
        page: 0,
      },
      () => {
        this.props.onChangeTab(newValue);
      }
    );
  };

  printData = (data, headerIndex) => {
    let header = this.state.headers[headerIndex];
    if (!header) {
      return data;
    }
    if (!data) {
      return "";
    }
    if (header.type === "0") {
      //name with image
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px", fontWeight: "600" }}>
          <img src={!data?.logo || data?.logo.length === 0 ? DefaultImage : data?.logo} alt={""} style={{ width: 40, height: "100%" }} />
          {data.name}
        </div>
      );
    } 
    else if (header.type === "2") {
      if (Validator.isJson(data)) {
        let value = JSON.parse(data);
        return moment(value?.data).format("DD-MMM-YYYY");
      } else {
        return moment(data).format("DD-MMM-YYYY");
      }
    } 
    else if (header.type === "11") {
      if (Validator.isJson(data)) {
        let value = JSON.parse(data);
        return (
          <SlateInputFieldV2
            readOnly={true}
            isToolBar={false}
            placeholder=""
            style={{
              margin: 0,
            }}
            as={SlateInputFieldV2}
            initValue={value?.data}
            formControlStyle={{ border: "none", borderColor: "#fff", overflow: "hidden", margin: "0px !important" }}
            textContainerStyle={{ minHeight: "10px !important", margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }}
          />
          );
      }
    } 
    else if (["1", "3", "12", "13", "14", "19"].includes(header.type)) {
      if (Validator.isJson(data)) {
        let value = JSON.parse(data);
        if(value?.data !== undefined && value?.data !== null && value?.data !== "undefined" && value?.data !== "null"){
          return value.data;
        } else if(value !== undefined && value !== null && value !== "undefined" && value !== "null"){
          return value
        } else {
          return null
        }
      }
    } 
    else {
      return data;
    }
  };

  render() {
    const classes = this.props.classes;

    const { order, orderBy, page, rowsPerPage, dense, selectedItemIds, query, headers, rowsData, totalRows } = this.state;
    const { searchPlaceHolder, fetchAppCatalogProductsProgress, setAppCatalogFieldsHeadersProgress } = this.props;
    console.log({ headers, rowsData, selectedItemIds }, "datacustom");
    return (
      <div>
        <Paper className={classes.paper}>
          <AppBar position="static" color="default" className={classes.answerBox}>
            <div className={classes.topHeader}>
              {!this.props.fetchAppCatalogFieldsProgress && (
                <div className={classes.showBtnSection}>
                  <Tooltip title="Show Hide Columns">
                    <IconButton
                      aria-label="filter list"
                      onClick={(event) => {
                        this.props.showAppCatalogfieldsDialog();
                      }}
                    >
                      <SettingsOutlined />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          </AppBar>
          <div className={classes.searchBox}>
            <TextField
              className={classes.autoSelectInput}
              value={query}
              variant="outlined"
              placeholder={searchPlaceHolder}
              type="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onInput={(e) => {
                this.updateSearchQuery(e.target.value);
              }}
            />
          </div>

          <TableContainer className={classes.tableContainer} style={{ maxHeight: this.props.tableMaxHeight }}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  {headers.map((headCell, i) => {
                    return (
                      <TableCell
                        key={i}
                        align={"left"}
                        padding={"normal"}
                        style={{ left: "unset", whiteSpace: "nowrap", minWidth: headCell.width ? headCell.width : 200 }}
                        sortDirection={orderBy === headCell.item_name ? order : false}
                      >
                        {headCell.sort && (
                          <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={this.createSortHandler(headCell.id)}>
                            {headCell.item_name}
                          </TableSortLabel>
                        )}
                        {headCell.isEmpty && (
                          <Tooltip title="No data exist for this field" placement="top">
                            <div className={classes.noDataField}>
                              <ErrorOutlineIcon style={{ color: "orange" }} />
                              {headCell.item_name}
                            </div>
                          </Tooltip>
                        )}
                        {!headCell.sort && !headCell.isEmpty && <span>{headCell.item_name}</span>}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {!fetchAppCatalogProductsProgress &&
                  !setAppCatalogFieldsHeadersProgress &&
                  rowsData.map((row, index) => {
                    const isItemSelected = this.isSelected(row.name);
                    // const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => this.routeChange(row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                        style={{ cursor: "pointer" }}
                      >
                        {row.details?.map((col, i) => {
                          return <TableCell><div className={classes.textWrap}>{this.printData(col, i)}</div></TableCell>;
                        })}
                      </TableRow>
                    );
                  })}

                {(fetchAppCatalogProductsProgress || setAppCatalogFieldsHeadersProgress) && (
                  <TableRow>
                    <TableCell colSpan={headers.length} className={classes.nameCol}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {!(fetchAppCatalogProductsProgress || setAppCatalogFieldsHeadersProgress) && rowsData.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={headers.length} align="center" className={classes.nameCol}>
                      No Items
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 50, 100]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
        {this.props.showDialog && <FieldsDialog setVisibilityForColumns={this.setVisibilityForColumns} selectedItemIds={selectedItemIds} />}
      </div>
    );
  }
}

TableContent.defaultProps = {
  isShowSearch: false,
  searchPlaceHolder: "Search by Name",
  page: 0,
  rowsPerPage: 20,
  query: "",
  activeWorkflowTab: 1,
  tableMaxHeight: "50vh",
  isGlobalSortRequire: false,
};

export default connector(compose(withRouter, withStyles(styles))(TableContent));
