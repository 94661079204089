import { Button, Divider, Grid, IconButton, List, ListItem, ListItemIcon, Popover, Typography } from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { createStyles, withStyles } from "@material-ui/core/styles";
import PlusIcon from "@material-ui/icons/Add";
import CloseIcon from '@material-ui/icons/Close';
import * as Validator from "util/Validation";
import FavoriteBorderOutlined from "@material-ui/icons/FavoriteBorderOutlined";
import FavoriteOutlined from "@material-ui/icons/FavoriteOutlined";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import CenterFocusStrongOutlinedIcon from '@material-ui/icons/CenterFocusStrongOutlined';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import LockOutlined from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarIcon from "@material-ui/icons/Star";
import StarBorderOutlined from "@material-ui/icons/StarBorderOutlined";
import editIcon from "assets/images/edit_icon.svg";
import DisLike from 'assets/images/icons/dislike.svg';
import LikeDefault from 'assets/images/icons/like-default.svg';
import Like from 'assets/images/icons/like.svg';
import { default as classnames, default as classNames } from "classnames";
import Image from 'Components/Common/image.jsx';
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import PropTypes from "prop-types";
import React, { createRef } from 'react';
import LinesEllipsis from "react-lines-ellipsis";
import { connect, useDispatch } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import CheckOutlinedIcon from '@material-ui/icons/Check';
import { hideProductDetailsDialog, showProductDetailsDialog, showDeprecateProductMessageDialog, hideDeprecateProductMessageDialog, showBlockProductMessageDialog, hideBlockProductMessageDialog } from "redux/dialogs/action";
import { addProductState, addWishlistedProducts, deleteProductState, deleteOrgProduct, UpdateProductReaction } from "redux/product/action";
import { fetchAddOrgProduct, deleteProduct } from 'redux/product/orgProduct/action';
// images

import { isEnabledEvaluate } from "util/Common";
import { showSnackBar } from "redux/snackbar/action";

import { Node } from 'slate';
// util
import { getConfigValue, isEnabledReview } from "util/Common";
import ToolTip from "../../../../../Common/ToolTip.jsx";
// import { Tooltip as MuiTooltip } from "@material-ui/core";

import BlockProductMessageDialog from './BlockProductMessageDialog.jsx';
import DeprecateProductMessageDialog from './DeprecateProductMessageDialog.jsx';
import { fireEvent } from 'redux/common/action'

import { claimProduct } from "redux/seller/product/action";
import { updateCatalogState } from "redux/vendor/action";
import { updateOrgCatalogState } from "redux/vendor/vendorCatalog/action";
import { updateReportData } from "redux/reports/action.js";
import {
  fetchOrgProductDetails,
  showAddProductWorkflowDialog,
} from "redux/product/orgProduct/action.js";
import { hideStartNewWorkflowDialg, startNewWorkflow } from 'redux/workflow/action';
import { showRequestAccessInformationDialog } from "redux/dialogs/action";
import StartWorkflowDialog from "Components/Common/Dialogs/StartWorkflowDialog.jsx";
import RequestAccessInformation from "Components/Common/OrgProductDetailsV2/Dialogs/RequestAccessInformation.jsx";


const connectedProps = (state) => ({
  dialogProductDetailsOpen: state.dialogs.dialogProductDetailsOpen,
  isOpenDeprecateProductMessageDialog: state.dialogs.isOpenDeprecateProductMessageDialog,
  isOpenBlockProductMessageDialog: state.dialogs.isOpenBlockProductMessageDialog,
  dialogProductDetailsId: state.dialogs.dialogProductDetailsId,
  dialogProductDetailsEvent: state.dialogs.dialogProductDetailsEvent,
  fetchProductsReactionProgress: state.products.fetchProductsReactionProgress,
  products: state.products,
  wishlistedProductIds: state.products.wishlistedProductIds,
  user: state.authUser.user,
  orgUser: state.orgUser,
  userRole: state.authUser.user?.Role,
  addProductStateProg: state.products.addProductStateProg,
  addProductStateError: state.products.addProductStateError,
  deleteProductStateProg: state.products.deleteProductStateProg,
  deleteProductStateError: state.products.deleteProductStateError,
  searchData: state.reports.searchData,  
  startNewWorkflowProgress: state.workflow.startNewWorkflowProgress,
  startNewWorkflowError: state.workflow.startNewWorkflowError,
  // showNewWorkflowStartDialog: state.workflow.showNewWorkflowStartDialog,

  // isOpenReqAccInfoDialog: state.dialogs.isShowRequestAccInfoDialog,
  // requestAccInfo: state.dialogs.requestAccInfo,

  workflowData: state.workflow.workflowData,
})

const connectionActions = {
  showProductDetailsDialog: showProductDetailsDialog,
  hideProductDetailsDialog: hideProductDetailsDialog,
  UpdateProductReaction: UpdateProductReaction,
  addWishlistedProducts: addWishlistedProducts,
  fetchAddOrgProduct: fetchAddOrgProduct,
  showSnackBar: showSnackBar,
  addProductState: addProductState,
  deleteProductState: deleteProductState,
  deleteProduct: deleteProduct,
  deleteOrgProduct: deleteOrgProduct,
  fireEvent: fireEvent,
  claimProduct: claimProduct,
  updateCatalogState: updateCatalogState,
  updateOrgCatalogState: updateOrgCatalogState,
  showDeprecateProductMessageDialog: showDeprecateProductMessageDialog,
  hideDeprecateProductMessageDialog: hideDeprecateProductMessageDialog,
  showBlockProductMessageDialog: showBlockProductMessageDialog,
  hideBlockProductMessageDialog: hideBlockProductMessageDialog,
  updateReportData: updateReportData,
  fetchOrgProductDetails: fetchOrgProductDetails,
  hideStartNewWorkflowDialg: hideStartNewWorkflowDialg,
  startNewWorkflow: startNewWorkflow,
  showAddProductWorkflowDialog: showAddProductWorkflowDialog,
  showRequestAccessInformationDialog: showRequestAccessInformationDialog,
};

const stateCombinationAllowed = {
  existing: ['featured', 'limited'],
  featured: ['existing', 'limited'],
  limited: ['existing', 'featured'],
  in_review: [],
  deprecated: [],
  blocked: [],
}

var connector = connect(connectedProps, connectionActions);
const commonBlockedMsg = "Product blocked by your organization. Please contact your admin for more information"
const commonDeprecatedMsg = "Product deprecated by your organization. Please contact your admin for more information"
const styles = (theme) =>
  createStyles({
    container: {
      position: "relative",
      width: '100%',
      height: '100%'
    },
    iconButton: {
      marginLeft: 10,
      padding: '0 !important',
    },
    root: {
      position: "relative",
      height: 'inherit',
      border: "solid 3px",
      background: theme.palette.common.white,
      borderRadius: theme.spacing(2),
      zIndex: 10,
      padding: theme.spacing(0, 0.5),
      paddingBottom: theme.spacing(2),
      cursor: "pointer",
    },
    rootSelected: {
      borderColor: '#4a87f8 !important',
    },
    layer1: {
      width: "90%",
      position: "absolute",
      border: "solid 2px",
      borderRadius: theme.spacing(2),
      height: "100px",
      left: 0,
      right: 0,
      margin: "0 auto",
      zIndex: "9",
      top: theme.spacing(-1),
    },
    layer2: {
      width: "80%",
      position: "absolute",
      border: "solid 1px",
      borderRadius: theme.spacing(2),
      height: "100px",
      left: 0,
      right: 0,
      margin: "0 auto",
      zIndex: "8",
      top: theme.spacing(-1.8),
    },
    imageContainer: {
      textAlign: "center",
      padding: theme.spacing(3),
      paddingTop: '2em',
      marginTop: 10
    },
    cardImage: {
      // height: theme.spacing(8),
      // maxWidth:"100%"
      width: 'auto',
      height: '55px',
      maxWidth: '90%',
      objectFit: 'contain'
    },
    cardTitle: {
      fontSize: theme.spacing(2),
      textAlign: "center",
      padding: '0 20px',
      minHeight: theme.spacing(5),
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block'
    },
    grpTitle: {
      fontSize: 11,
      textAlign: "center",
      padding: '0 20px',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      color: '#413932',
      fontWeight: 400
    },
    cardTitle2: {
      fontSize: theme.spacing(2),
      textAlign: "center",
      padding: '0 20px',
      minHeight: theme.spacing(5),
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block'
    },
    noSubTitle: {
      marginTop: theme.spacing(1.2),
      marginBottom: theme.spacing(1.2),
    },
    cardSubTitle: {
      color: "#282D30",
      opacity: 0.6,
      textAlign: "center",
      fontSize: theme.spacing(1.7),
      fontWeight: 400,
      minHeight: 20,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '0 3px',
      marginTop: theme.spacing(2)
    },
    cardOrange: {
      "& $root": {
        borderColor: "#F8B53B",
      },
      "& $layer1": {
        borderColor: "#F8B53B",
        opacity: 0.5,
        background: "#FDF3EA",
      },
      "& $layer2": {
        borderColor: "#F8B53B",
        opacity: 0.3,
        background: "#FDF3EA",
      },
    },
    cardGreen: {
      "& $root": {
        borderColor: "#75C378",
      },
      "& $layer1": {
        borderColor: "#75C378",
        opacity: 0.5,
        background: "#C6EEC3",
      },
      "& $layer2": {
        borderColor: "#75C378",
        opacity: 0.3,
        background: "#C6EEC3",
      },
    },
    cardBlue: {
      "& $root": {
        borderColor: "#26B7FA",
      },
      "& $layer1": {
        borderColor: "#26B7FA",
        opacity: 0.5,
        background: "#FFF",
      },
      "& $layer2": {
        borderColor: "#26B7FA",
        opacity: 0.3,
        background: "#FFF",
      },
    },
    cardCyna: {
      "& $root": {
        borderColor: "#26C6DA",
        background: "#E3F8FA",
      },
      "& $layer1": {
        borderColor: "#26C6DA",
        opacity: 0.5,
        background: "#FFF",
      },
      "& $layer2": {
        borderColor: "#26C6DA",
        opacity: 0.3,
        background: "#FFF",
      },
    },
    cardGrey: {
      "& $root": {
        borderColor: "#CECECE",
        background: "#FFFFFF",
      },
      "& $layer1": {
        borderColor: "#CECECE",
        opacity: 0.5,
        background: "#FFF",
      },
      "& $layer2": {
        borderColor: "#CECECE",
        opacity: 0.3,
        background: "#FFF",
      },
    },
    cardDefault: {
      "& $root": {
        borderColor: "#EEEEEE",
      },
      "& $layer1": {
        borderColor: "#EEEEEE",
        opacity: 0.5,
        background: "#FFF",
      },
      "& $layer2": {
        borderColor: "#EEEEEE",
        opacity: 0.3,
        background: "#FFF",
      },
      "& $cardTitle": {
        height: theme.spacing(5),
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      },
      "& $cardTitle2": {
        height: theme.spacing(8),
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      },
    },
    checkbox: {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(2),
      height: theme.spacing(2),
    },

    pd_root: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 10px 20px #00000036',
      borderRadius: '10px',
      opacity: '1',
      width: '350px',
      minHeight: '150px',
      zIndex: 9999,
      padding: '30px',
      position: 'fixed',
      marginTop: '30px'
    },
    pd_headerContainer: {
      flexDirection: 'row',
      boxSizing: 'border-box',
      display: 'flex',
      placeContent: 'center flex-start',
      alignItems: 'center'
    },
    pd_title: {
      textAlign: 'left',
      wordBreak: 'break-word',
      fontWeight: 500,
      letterSpacing: '0px',
      color: '#20253A',
      opacity: 1,
      margin: '0px',
      marginBottom: '5px'
    },
    pd_subTitle: {
      textAlign: 'left',
      fontWeight: 400,
      letterSpacing: '0px',
      color: '#20253A',
      opacity: 0.5,
      margin: '0px',
    },
    popupContainer: {
      maxHeight: '250px',
      overflowY: 'auto',
    },
    pd_descriptionContainer: {
      textAlign: 'left',
      fontWeight: 500,
      letterSpacing: '0px',
      color: '#20253A',
      opacity: 0.5
    },
    pd_action: {
      textAlign: 'left',
      letterSpacing: '0px',
      color: '#0093E0 !important',
      opacity: 1,
      cursor: 'pointer',
      fontSize: theme.spacing(2.4),
    },
    ellipsis_3: {
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box'
    },
    close: {
      position: 'absolute',
      right: 8,
      top: 8,
      cursor: 'pointer',
      color: '#6F6F6F',
      '& svg': {
        stroke: 'white',
        fontSize: '24px'
      }
    },
    leftAction: {
      position: 'absolute',
      left: '10px',
      top: '10px',
      display: 'flex',
      alignItem: 'center'
    },
    actionIcon: {
      width: 24,
      height: 24,
    },
    blurContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 0,
      height: 0,
      zIndex: 11,
      transition: 'all 500ms ease',
      '-webkit-transition': 'all 500ms ease',
      '-moz-transition': 'all 500ms ease',
      borderRadius: theme.spacing(2),
      backgroundColor: '#EEEEEE8d',
      backdropFilter: 'blur(10px)',
      '-webkit-backdrop-filter': 'blur(10px)'
    },
    topRightAction: {
      cursor: 'pointer',
      position: 'absolute',
      right: 10,
      top: 10,
      display: 'flex',
      zIndex: 8,
      alignItems: 'center',
      flexDirection: 'row-reverse',
      '& > div:nth-child(1)': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
      },
      '& > div:nth-child(2)': {
        width: 0,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        '-webkit-transition': 'width 500ms',
        '-moz-transition': 'width 500ms',
        'transition': 'width 500ms',
      },
      '&:hover > div:nth-child(2)': {
        width: 63,
      },
    },
    topRightActionNew: {
      cursor: 'pointer',
      position: 'absolute',
      right: 40,
      top: 10,
      display: 'flex',
      zIndex: 12,
      alignItems: 'center',
      flexDirection: 'row-reverse',
      '& > div:nth-child(1)': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
      },
      '& > div:nth-child(2)': {
        width: 0,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        '-webkit-transition': 'width 500ms',
        '-moz-transition': 'width 500ms',
        'transition': 'width 500ms',
      },
      '&:hover > div:nth-child(2)': {
        width: 63,
      },
    },
    contentContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    cta: {
      width: "80%",
      margin: "0 auto",
      marginTop: theme.spacing(2),
      '& button': {
        background: '#ffffff',
        '&:hover': {
          background: '#ffffff',
        }
      }
    },
    threeDot: {
      position: "absolute",
      zIndex: 11,
      right: 5,
      top: 5,
      padding: 5
    },
    proBlocked: {
      zIndex: 11,
      color: '#2196f3'
    },
    featuredAdded: {
      zIndex: 11,
      color: '#ff9800'
    },
    preferredAdded: {
      zIndex: 11,
      color: '#ff9800'
    },
    proNotBlocked: {
      zIndex: 11,
      color: '#c1c1c1',
    },
    favLiked: {
      zIndex: 11,
      color: '#f44336'
    },
    favNotLiked: {
      zIndex: 11,
      color: '#c1c1c1',
    },
    favIconLiked: {
      position: "absolute",
      zIndex: 11,
      right: 40,
      top: 12,
      color: '#f44336',
      fill: '#f44336 !important',
    },
    favIconLikedNew: {
      position: "absolute",
      zIndex: 11,
      right: 70,
      top: 12,
      color: '#f44336',
      fill: '#f44336 !important',
    },
    featuredSelectedIcon: {
      position: "absolute",
      zIndex: 11,
      right: 40,
      top: 12,
      color: '#ff9800',
      fill: '#ff9800 !important',
    },
    featuredSelectedIcon1: {
      position: "absolute",
      zIndex: 11,
      right: 70,
      top: 12,
      color: '#ff9800',
      fill: '#ff9800 !important',
    },
    favIconNotLiked: {
      position: "absolute",
      zIndex: 11,
      right: 40,
      top: 12,
      color: '#c1c1c1',
    },
    "popoverBtn": {
      width: "100%",
      padding: theme.spacing(0.5, 8, 0.5, 2),
      minHeight: "30px",
      lineHeight: "13px",
      "& > span": {
        textAlign: "left",
        display: "block"
      }
    },
    popover: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        "&::before": {
          top: "50px",
          left: "-16px",
          width: "0",
          borderTop: "8px solid transparent",
          height: "0px",
          content: "''",
          display: "block",
          position: "absolute",
          borderBottom: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid #fff"
        },
      },
    },
    popover1: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        "&::before": {
          top: "75px",
          left: "-16px",
          width: "0",
          borderTop: "8px solid transparent",
          height: "0px",
          content: "''",
          display: "block",
          position: "absolute",
          borderBottom: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid #fff"
        },
      },
    },
    popover2: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        "&::before": {
          top: "10px",
          left: "-16px",
          width: "0",
          borderTop: "8px solid transparent",
          height: "0px",
          content: "''",
          display: "block",
          position: "absolute",
          borderBottom: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid #fff"
        },
      },
    },
    "popoverText": {
      color: "#74798C"
    },
    button: {
      fontSize: theme.spacing(2),
      background: "transparent",
    },
    listItem: {
      paddingTop: theme.spacing(2),
    },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    menuList: {
      "& > hr": {
        "&:first-child": {
          display: 'none'
        },
      }
    },
    tags: {
      display: 'flex',
      width: 'max-content',
      height: 16,
      paddingTop: 2
    },
    tag2: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#f7f7f7',
      textAlign: 'center',
      marginRight: 2,
      position: 'relative',
      width: 'max-content',
      height: 16,
      borderRadius: 9,
      padding: '0px 5px',
      "& > span": {
        fontSize: '11px',
        fontWeigt: '300 !important',
        lineHieght: '1',
        backgroundColor: 'inherit',
      },
    },
    tag: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#f7f7f7',
      textAlign: 'center',
      marginRight: 2,
      position: 'relative',
      width: 16,
      height: 16,
      borderRadius: 9,
      padding: '0px 5px',
      "& > span": {
        fontSize: '11px',
        fontWeigt: '300 !important',
        lineHieght: '1',
        backgroundColor: 'inherit',
      },
      "&:hover": {
        width: 'max-content',
      },
      "&:hover > span:first-child": {
        display: 'none'
      },
      "&:hover > span:nth-child(2)": {
        display: 'block',
      },
      "& span:nth-child(2)": {
        display: 'none',
        zIndex: 12,
      }
    },
    isPreferred: {
      background: "#2EB77D",
      color: "#fff",
    },
    isExisting: {
      background: '#E6FFF3',
      color: '#2EB77D',
    },
    isDeprecated: {
      background: "#ff958d",
      color: "#fff",
    },
    isBlocked: {
      background: "#f44336",
      color: "#fff",
    },
    isFeatured: {
      background: '#ff9800',
      color: '#fff',
    },
    isInReview: {
      background: '#bdbdbd',
      color: '#fff',
    },
    isLimited: {
      background: '#2e81da',
      color: '#fff',
    },
    isEvaluated: {
      background: '#bdbdbd',
      color: '#fff',
      width: 20,
    },
    checkIcon: {
      fontWeight: 'bold',
      textAlign: 'center',
      top: -10,
      left: -10,
      position: 'absolute',
      width: 20,
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      background: '#4a87f8',
    }
  });

class LayeredCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: false,
      liked: null,
      anchorEl: null,
      isOpenBlockProductMessageDialog: false,
      blockType: null,
      isOpenDeprecateProductMessageDialog: false,
      deprecateType: null,
      updatePendingStatesArray: [],
      isDeprecateEdit: false,
      isBlockEdit: false,
      productDetails: {}
    }
    this.styleToClass();
    this.dialogDetailsRef = createRef()
    this.btnRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleMouseEffects = this.handleMouseEffects.bind(this);
    this.getProductReaction = this.getProductReaction.bind(this);
    this.getProductFeatureReaction = this.getProductFeatureReaction.bind(this);
    this.getProductBlockReaction = this.getProductBlockReaction.bind(this);
    this.getProductDeprecateReaction = this.getProductDeprecateReaction.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  
  
  componentDidMount() {
    // window.addEventListener('scroll', this.handleScroll, true);
    document.addEventListener('mousedown', this.handleClickOutside);
     // Dispatch the fetchOrgProductDetails action
     this.props.fetchOrgProductDetails(this.props.id)
     .then(res => {
       console.log("res",res?.orgProduct);
       this.setState({productDetails: res?.orgProduct||{}})
       // Now you can access the data from Redux store via this.props
       // For example: this.props.productDetails
     })
     .catch(error => {
       console.error(error);
     });
  }
  componentDidUpdate(prevProps) {
    if (this.props.startNewWorkflowProgress === false && prevProps.startNewWorkflowProgress === true) {
      if (this.props.startNewWorkflowError === null) {
        this.props.hideStartNewWorkflowDialg();
        this.props.history.push(`/app/evaluate/${this.props.workflowData.ID}/workflow/interim`);
      } else {
        this.props.showSnackBar("Failed to start workflow", "error", 3000);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose() {
    this.setState({ anchorEl: null });
  };

  addWishListed(productId, type) {
    var dataObj = {
      "wishlist_products": [
        {
          "productID": productId,
          "opType": type
        }
      ]
    }
    this.props.addWishlistedProducts(dataObj);
  }

  handleAddInExistingProduct(productId, productName) {
    let product = {};
    product.productId = parseInt(productId);
    product.name = productName;
    product.isExsting = true;
    this.props.fetchAddOrgProduct(product, true);
  }


  handleDeprecate(type, isEdit) {
    if (type === 'delete') {
      this.props.UpdateProductReaction(
        'all_products_deprecating',
        [
          {
            productId: this.props.id,
            "status": 3,
            "source": 'all_products_deprecating',
            "sourceData": 'all_products_deprecating',
            "opType": type,
            "reason": "",
          }
        ]
      )
    } else {
      this.setState({
        isDeprecateEdit: isEdit,
        isOpenDeprecateProductMessageDialog: true,
        deprecateType: type,
      })
    }
  }

  handleSubmitDeprecate(message) {
    this.props.UpdateProductReaction(
      'all_products_deprecating',
      [
        {
          productId: this.props.id,
          "status": 3,
          "source": 'all_products_deprecating',
          "sourceData": 'all_products_deprecating',
          "opType": this.state.deprecateType,
          "reason": message,
        }
      ]
    )
  }

  handleBlock(type, isEdit = false) {
    if (type === 'delete') {
      this.props.UpdateProductReaction(
        this.props.typeOfBlock,
        [
          {
            productId: this.props.id,
            "status": 1,
            "source": this.props.typeOfBlock,
            "sourceData": this.props.typeOfBlock,
            "opType": type,
            "reason": "",
          }
        ]
      )
    } else {
      this.setState({
        isBlockEdit: isEdit,
        isOpenBlockProductMessageDialog: true,
        blockType: type,
      })
    }
  }

  handleSubmitBlock(message) {
    this.props.UpdateProductReaction(
      this.props.typeOfBlock,
      [
        {
          productId: this.props.id,
          "status": 1,
          "source": this.props.typeOfBlock,
          "sourceData": this.props.typeOfBlock,
          "opType": this.state.blockType,
          "reason": message,
        }
      ]
    )
  }

  handleFeatured(productId, type) {
    this.props.UpdateProductReaction(
      this.props.typeOfFeatured,
      [
        {
          productId: productId,
          "status": 1,
          "source": this.props.typeOfFeatured,
          "sourceData": this.props.typeOfFeatured,
          "opType": type
        }
      ]
    )
  }

  handleScroll = (event) => {
    if (this.props.dialogProductDetailsOpen) {
      this.props.hideProductDetailsDialog()
    }
  }

  handleClickOutside(event) {
    this.clodeProductDetailsDialog(event)
  }

  clodeProductDetailsDialog = (event) => {
    if ((this.props.type && this.props.type === 'product') && this.props.dialogProductDetailsOpen && this.props.dialogProductDetailsId === this.props.id) {
      setTimeout(() => {
        if (this.dialogDetailsRef && this.dialogDetailsRef.current && !this.dialogDetailsRef.current.contains(event.target)) {
          this.props.hideProductDetailsDialog()
        }
      }, 150);
    }
  }

  closeProductDetailDialog = (eve) => {
    if ((this.props.type && this.props.type === 'product') && this.props.dialogProductDetailsOpen && this.props.dialogProductDetailsId === this.props.id) {
      if (this.dialogDetailsRef && !this.dialogDetailsRef.current.contains(eve.target)) {
        this.props.hideProductDetailsDialog()
      }
    }
  }

  styleToClass(styleName) {
    const classes = this.props.classes;
    switch (styleName) {
      case "ORANGE":
        return classes.cardOrange;
      case "GREEN":
        return classes.cardGreen;
      case "BLUE":
        return classes.cardBlue;
      case "CYAN":
        return classes.cardCyna;
      case "GREY":
        return classes.cardGrey;
      case "DEFAULT":
      default:
        return classes.cardDefault;
    }
  }

  showPopup = (e, productStateArr) => {
    if (this.props.type === 'product') {
      if (!productStateArr.includes("blocked") && !productStateArr.includes("deprecated") && (this.props.location.pathname === "/app/home" || this.props.location.pathname === "/app/products/org-products")) {
        const route = this.props.customRoute ? (`${this.props.customRoute}` + this.props.id) : (`/app/products/product-details/` + this.props.id)
        this.props.history.push(route)
      } else {
        e.persist();
        e.stopPropagation()
        this.props.showProductDetailsDialog(e, this.props.uniqueId)
      }
    }
  }

  openToolTip = (value) => {
    if (value) {
      value.persist()
    }

    this.setState({
      event: value
    })
  }

  handleClickAway = () => {
    this.props.hideProductDetailsDialog()
  };

  handleMouseEffects = (effect) => {
    const element = document.getElementById(`${this.props.id}_blurContainer`)
    if (element) {
      if (effect === 'over') {
        element.style.cssText = `width: 100%; height: 100%;`
      }
      if (effect === 'out') {
        element.style.cssText = `width: 0px; height: 0px; `
      }
    }
  }

  handleRenderReaction = (src, value, alt = "reaction") => (
    <IconButton classes={{ root: this.props.classes.iconButton }}>
      <Image
        src={src}
        alt={alt}
        className={this.props.classes.actionIcon}
        onClick={e => {
          e.stopPropagation()
          this.props.UpdateProductReaction(
            this.props.typeOfLike,
            [
              {
                productId: this.props.id,
                "status": value ? 1 : 2,
                "source": this.props.typeOfLike,
                "sourceData": this.props.fromPage !== undefined && this.props.fromPage === 'company-recommendation' ? 'company-recommended' : this.props.typeOfLike === 'recommended' ? this.props.history.location.search.substring(1) : this.props.match.params.id,
                "opType": value === null ? 'delete' : 'upsert'
              }
            ]
          )
        }}
      />
    </IconButton>
  )

  getProductReaction = () => {
    const productID = this.props.id
    const reactions = this.props.products[`${this.props.typeOfLike}ProductsReaction`] || null
    let response = null
    if (reactions !== null && reactions !== undefined) {
      response = reactions.find(o => o.ProductID === productID)
    }
    return response;
  }

  getProductFeatureReaction = () => {
    const productID = this.props.id
    const reactions = this.props.products[`${this.props.typeOfFeatured}ProductsReaction`] || null
    let response = null
    if (reactions !== null && reactions !== undefined) {
      response = reactions.find(o => o.ProductID === productID)
    }
    return response;
  }

  getProductDeprecateReaction = () => {
    const productID = this.props.id
    const reactions = this.props.products[`all_products_deprecatingProductsReaction`] || null
    let response = null
    if (reactions !== null && reactions !== undefined) {
      response = reactions.find(o => o.ProductID === productID)
    }
    return response;
  }

  getProductBlockReaction = () => {
    const productID = this.props.id
    const reactions = this.props.products[`${this.props.typeOfBlock}ProductsReaction`] || null
    let response = null
    if (reactions !== null && reactions !== undefined) {
      response = reactions.find(o => o.ProductID === productID)
    }
    return response;
  }

  isblockedReason = (productStateArr, productBlockReaction) => {
    let reason = false
    if (productBlockReaction && productBlockReaction !== null && productBlockReaction?.Reason) {
      if (Validator.isJson(productBlockReaction?.Reason)) {
        let newNote = JSON.parse(productBlockReaction?.Reason)
        newNote = newNote.map(n => Node.string(n)).join(' ')
        if (newNote.length > 0) {
          reason = true
        }
      }
    }
    return reason;
  }

  isdeprecatedReason = (productStateArr, productDeprecateReaction) => {
    let reason = false
    if (productDeprecateReaction && productDeprecateReaction !== null && productDeprecateReaction?.Reason) {
      if (Validator.isJson(productDeprecateReaction?.Reason)) {
        let newNote = JSON.parse(productDeprecateReaction?.Reason)
        newNote = newNote.map(n => Node.string(n)).join(' ')
        if (newNote.length > 0) {
          reason = true
        }
      }
    }
    return reason;
  }

  getProductState = () => {
    const data = []
    const productStates = this.props.productStates
    if (productStates) {
      productStates.forEach(obj => {
        data.push(obj.StateName);
      })
    }
    return data
  }

  updateState = (statesArray, state, addingFlag) => {
    let mergeWithExisting = ["featured", "limited"];
    let newStates = [];
    let updatePendingStatesArray = [];
    if (state && stateCombinationAllowed[state]) {
      if (addingFlag) {
        newStates = stateCombinationAllowed[state].filter(item => statesArray.includes(item));
        newStates.push(state);
      } else {
        newStates = statesArray.filter(item => item !== state)
      }
      if (mergeWithExisting.includes(state) && !statesArray.includes("existing")) {
        newStates.push("existing")
      }
      if (!statesArray.includes('existing') && newStates.includes('existing')) {
        this.handleAddInExistingProduct(this.props.id, this.props.title)
      }
      if (statesArray.includes('existing') && !newStates.includes('existing')) {
        this.props.deleteOrgProduct(this.props.id);
      }
      if (statesArray.includes('deprecated') && !newStates.includes('deprecated')) {
        this.handleDeprecate('delete');
      }
      if (!statesArray.includes('deprecated') && newStates.includes('deprecated')) {
        updatePendingStatesArray = newStates;
        this.handleDeprecate('upsert');
      }
      if (statesArray.includes('blocked') && !newStates.includes('blocked')) {
        this.handleBlock('delete');
      }
      if (!statesArray.includes('blocked') && newStates.includes('blocked')) {
        updatePendingStatesArray = newStates;
        this.handleBlock('upsert');
      }
      if (updatePendingStatesArray.length === 0) {
        this.props.addProductState(this.props.id, newStates);
      } else {
        this.setState({ updatePendingStatesArray: updatePendingStatesArray })
      }
    }
  }

  handleCatalogStateUpdate = (productStateArr, state) => {
    if (this.props.isExisting) {
      let data = {
        value: [{ name: state, description: '' }],
        is_delete: productStateArr.includes(state)
      };
      let id = this.props.cardType === "vendor" ? this.props.org_vendor_id : this.props.org_vendorservice_id;
      this.props.updateOrgCatalogState(this.props.cardType, id, 'states', data);
    } else {
      let data = {
        master_vendor_id: this.props.master_vendor_id || this.props.org_vendor_id,
        states: [{ name: state, description: '' }]
      }
      if (this.props.cardType === 'vendor') {
        data.states = [{ name: state, description: '' }]
      } else {
        let type = this.props.cardType === "vendorservice" ? "service" : this.props.cardType;
        data.states = [{ name: state, description: '' }];
        data.entities = [{
          master_id: this.props.master_service_id,
          type: type,
          states: [{ name: state, description: '' }]
        }];
      }
      this.props.updateCatalogState(data);
    }
  }

  render() {
    const classes = this.props.classes;
    var className = this.styleToClass(this.props.color);
    const { hideProductDetailsDialog, dialogProductDetailsOpen, dialogProductDetailsId, dialogProductDetailsEvent } = this.props;
    const popupPosition = function () {
      let element = dialogProductDetailsEvent.target;

      let product_container = element.closest(".product_container")

      let domRect = product_container.getBoundingClientRect();

      if (dialogProductDetailsOpen) {
        let obj = {};
        obj.left = domRect.x;
        obj.top = domRect.y;

        if (dialogProductDetailsEvent.clientX > (document.body.clientWidth - 350)) {
          delete obj.left;
          obj.right = document.body.clientWidth - (domRect.x + domRect.width);
        }

        if (dialogProductDetailsEvent.clientY > (document.body.clientHeight - 200)) {
          delete obj.top
          obj.top = domRect.y - domRect.height;
        }
        return obj;
      } else {
        return {}
      }
    }
    var evText = "Evaluate";
    var isShowButton = true;
    let isRequestAccess = false;
    if (this.props.isOrgProduct && this.props.IsExisting) {
      evText = "Review";
      let role = this.props.user?.Role;
      const EnableReviewForUsersCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableReviewForUsersCheck')
      const EnableReviewForAdminsCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableReviewForAdminsCheck')
      if ((role === 'OrgUser' && !EnableReviewForUsersCheck) || (role !== 'OrgUser' && !EnableReviewForAdminsCheck)) {
        isRequestAccess = true
        evText = "Request Access"
      }
    }
    let AccessLink = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'SettingName', 'AccessLink')
    if (!["null", undefined, null, ''].includes(this.props.requestLink)) {
      AccessLink = this.props.requestLink;
    }

    if (this.props.source === "BROWSE") {
      evText = "Evaluate";
    }
    var isProductClaim = false;
    if (this.props.source === "SELLER") {
      evText = "Claim";
      isRequestAccess = false;
      isProductClaim = true;
    }
    if (this.props.source === 'SURVEY') {
      evText = "Survey";
      isRequestAccess = false;
    }
    if (this.props.source === 'CATEGORY_DETAILS') {
      evText = "View Details";
    }

    const productReaction = this.getProductReaction() || null;
    const productFeatureReaction = this.getProductFeatureReaction() || null;
    const productBlockReaction = this.getProductBlockReaction() || null;
    const productDeprecateReaction = this.getProductDeprecateReaction() || null;

    const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForUsersCheck')
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForAdminsCheck')

    if (evText === 'Evaluate' && ((!EnableEvaluateForAdminsCheck && this.props.userRole !== 'OrgUser') || (!EnableEvaluateForUsersCheck && this.props.userRole === 'OrgUser'))) {
      isShowButton = false;
    }

    const EnableReviewForUsersCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableReviewForUsersCheck')
    const EnableReviewForAdminsCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableReviewForAdminsCheck')
    if (evText === 'Review' && ((!EnableReviewForAdminsCheck && this.props.userRole !== 'OrgUser') || (!EnableReviewForUsersCheck && this.props.userRole === 'OrgUser'))) {
      isShowButton = false;
    }

    if (this.props.isForRequirementSelection) {
      evText = "Add to Project";
      isShowButton = true;
    }

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'popover' : undefined;
    const productStateArr = this.getProductState();
    let reason = this.isblockedReason(productStateArr, productBlockReaction)
    let deprecatedReason = this.isdeprecatedReason(productStateArr, productDeprecateReaction)
    const isExisting = this.props.IsExisting || productStateArr.includes("existing")

    return (
      <div className={classnames(classes.container, { [className]: true })} >
        {this.props.numLayer > 0 && <div className={classes.layer1}></div>}
        {this.props.numLayer > 1 && <div className={classes.layer2}></div>}
        <div>
          {this.props.showLike && <div id={`${this.props.id}_blurContainer`} className={`${classes.blurContainer}`}></div>}
          {this.props.showLike && (
            <div
              className={this.props.isActionButtons ? classes.topRightActionNew : classes.topRightAction}
              id="topRightAction"
              onMouseOver={() => this.handleMouseEffects('over')}
              onMouseOut={() => this.handleMouseEffects('out')}
            >
              <div className={classes.outHover}>
                {productReaction === null ? (
                  this.handleRenderReaction(LikeDefault, null)
                ) : (
                  <>
                    {productReaction.Status === 1 && this.handleRenderReaction(Like, null, 'Like')}
                    {productReaction.Status === 2 && this.handleRenderReaction(DisLike, null, 'DisLike')}
                  </>
                )}
              </div>
              <div className={classes.onHover}>
                {productReaction === null ? (
                  <>
                    {this.handleRenderReaction(Like, true, 'Like')}
                    {this.handleRenderReaction(DisLike, false, 'dislike')}
                  </>
                ) : (
                  <>
                    {productReaction.Status === 2 && this.handleRenderReaction(Like, true, 'Like')}
                    {productReaction.Status === 1 && this.handleRenderReaction(DisLike, false, 'DisLike')}
                  </>
                )}
              </div>
            </div>
          )}

          {this.props.showReactionResponses && this.props.wishlistedProductIds !== undefined && this.props.wishlistedProductIds.indexOf(this.props.id) !== -1 && <FavoriteOutlined className={(this.props.showLike && this.props.isActionButtons) ? classes.favIconLikedNew : classes.favIconLiked} />}
          {false && this.props.showReactionResponses && this.props.wishlistedProductIds !== undefined && this.props.wishlistedProductIds.indexOf(this.props.id) === -1 && <FavoriteBorderOutlined className={classes.favIconNotLiked} />}

          {this.props.isActionButtons && (this.props.userRole === "OrgAdmin" || (!productStateArr.includes("blocked") && !this.props.cardType)) && <div>
            <IconButton ref={this.btnRef} className={classes.threeDot} onClick={this.handleClick}><MoreVertIcon /></IconButton>
          </div>}
          {this.props.isActionButtons && <Popover
            className={this.props.userRole === "OrgAdmin" ? (this.props.IsExisting === false && productReaction === null && productFeatureReaction === null) ? classes.popover1 : classes.popover : classes.popover2}
            id={id}
            open={open}
            anchorEl={this.state.anchorEl}
            onClose={() => this.handleClose()}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}>
            {(this.props.cardType === "vendor" || this.props.cardType === "vendorservice") ? (<List className={classes.menuList}>

              {this.props.userRole === "OrgAdmin" && (
                <ListItem
                  button
                  onClick={() => {
                    this.handleClose();
                    this.handleCatalogStateUpdate(productStateArr, "preferred");
                  }}
                >
                  <ListItemIcon classes={{ root: classes.listIcon }}>
                    {productStateArr.includes("preferred") ? <CenterFocusStrongIcon className={classes.preferredAdded} /> : <CenterFocusStrongOutlinedIcon className={classes.proNotBlocked} />}
                  </ListItemIcon>
                  <Typography variant="inherit">
                    {productStateArr.includes("preferred") ? 'Remove from Preferred' : 'Add to Preferred'}
                  </Typography>
                </ListItem>
              )}
            </List>) : (

              <List className={classes.menuList}>
                {this.props.userRole === "OrgAdmin" && !isExisting && !productStateArr.includes("blocked") && (
                  <ListItem
                    classes={{
                      button: classes.listItem,
                    }}
                    button
                    onClick={() => {
                      this.handleClose();
                      this.updateState(productStateArr, 'existing', true);
                    }}
                  >
                    <ListItemIcon classes={{ root: classes.listIcon }}>
                      <PlusIcon className={classes.proNotBlocked} />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      Add to Existing
                    </Typography>
                  </ListItem>
                )}


                {this.props.userRole === "OrgAdmin" && !productStateArr.includes("blocked") && (
                  <>
                    <Divider />
                    <ListItem
                      classes={{
                        button: classes.listItem,
                      }}
                      button
                      onClick={() => {
                        this.handleClose();
                        if (productStateArr.includes("featured")) {
                          this.updateState(productStateArr, 'featured', false);
                        } else {
                          this.updateState(productStateArr, 'featured', true);
                        }
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.listIcon }}>
                        {productStateArr.includes("featured") ? <StarIcon className={classes.featuredAdded} /> : <StarBorderOutlined className={classes.proNotBlocked} />}
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {productStateArr.includes("featured") ? 'Remove from Featured' : 'Add to Featured'}
                      </Typography>
                    </ListItem>
                  </>
                )}

                {this.props.userRole === "OrgAdmin" && !productStateArr.includes("blocked") && (
                  <>
                    <Divider />
                    <ListItem
                      classes={{
                        button: classes.listItem,
                      }}
                      button
                      onClick={() => {
                        this.handleClose();
                        if (productStateArr.includes("limited")) {
                          this.updateState(productStateArr, 'limited', false);
                        } else {
                          this.updateState(productStateArr, 'limited', true);
                        }
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.listIcon }}>
                        {productStateArr.includes("limited") ? <LocalOfferIcon className={classes.proNotBlocked} /> : <LocalOfferOutlinedIcon className={classes.proNotBlocked} />}
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {productStateArr.includes("limited") ? 'Remove from Limited Use' : 'Add to Limited Use'}
                      </Typography>
                    </ListItem>
                  </>
                )}

                {this.props.userRole === "OrgAdmin" && !productStateArr.includes("blocked") && (
                  <>
                    <Divider />
                    <ListItem
                      classes={{
                        button: classes.listItem,
                      }}
                      button
                      onClick={() => {
                        this.handleClose();
                        if (productStateArr.includes("in_review")) {
                          this.updateState(productStateArr, 'in_review', false);
                        } else {
                          this.updateState(productStateArr, 'in_review', true);
                        }
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.listIcon }}>
                        {productStateArr.includes("in_review") ? <LocalOfferIcon className={classes.proNotBlocked} /> : <LocalOfferOutlinedIcon className={classes.proNotBlocked} />}
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {productStateArr.includes("in_review") ? 'Remove from In Review' : 'Add to In Review'}
                      </Typography>
                    </ListItem>
                  </>
                )}

                {this.props.userRole === "OrgAdmin" && (
                  <>
                    <Divider />
                    <ListItem
                      classes={{
                        button: classes.listItem,
                      }}
                      button
                      onClick={() => {
                        this.handleClose();
                        if (productStateArr.includes("blocked")) {
                          this.updateState(productStateArr, 'blocked', false);
                        } else {
                          this.updateState(productStateArr, 'blocked', true);
                        }
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.listIcon }}>
                        {productStateArr.includes("blocked") ? <LockOutlined className={classes.proBlocked} /> : <LockOpen className={classes.proNotBlocked} />}
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {productStateArr.includes("blocked") ? 'Remove from Blocklist' : 'Add to Blocklist'}
                      </Typography>
                    </ListItem>
                  </>
                )}
                {this.props.userRole === "OrgAdmin" && !productStateArr.includes("blocked") && (
                  <>
                    <Divider />
                    <ListItem
                      classes={{
                        button: classes.listItem,
                      }}
                      button
                      onClick={() => {
                        this.handleClose();
                        if (productStateArr.includes("deprecated")) {
                          this.updateState(productStateArr, 'deprecated', false);
                        } else {
                          this.updateState(productStateArr, 'deprecated', true);
                        }
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.listIcon }}>
                        {productStateArr.includes("deprecated") ? <LockOutlined className={classes.proBlocked} /> : <LockOpen className={classes.proNotBlocked} />}
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {productStateArr.includes("deprecated") ? 'Remove from Deprecated' : 'Add to Deprecated'}
                      </Typography>
                    </ListItem>
                  </>
                )}

                {!productStateArr.includes("blocked") && (
                  <>
                    <Divider />
                    <ListItem
                      classes={{
                        button: classes.listItem,
                      }}
                      button
                      onClick={() => {
                        this.handleClose();
                        var type = (this.props.wishlistedProductIds !== undefined && this.props.wishlistedProductIds.indexOf(this.props.id) !== -1) ? 'delete' : 'upsert';
                        this.addWishListed(this.props.id, type);
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.listIcon }}>
                        {this.props.wishlistedProductIds !== undefined && this.props.wishlistedProductIds.indexOf(this.props.id) !== -1 ? <FavoriteOutlined className={classes.favLiked} /> : <FavoriteBorderOutlined className={classes.favNotLiked} />}
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {this.props.wishlistedProductIds !== undefined && this.props.wishlistedProductIds.indexOf(this.props.id) !== -1 ? 'Remove from Wishlist' : 'Add to Wishlist'}
                      </Typography>
                    </ListItem>
                  </>
                )}
              </List>)}
          </Popover>}
        </div>
        <div className={classnames(classes.root, 'product_container', this.props.selected ? classes.rootSelected : {})}>
          {this.props.selected && (
            <div className={classes.checkIcon}>
              <CheckOutlinedIcon style={{ fontSize: 14, color: '#fff' }} />
            </div>
          )}
          <div className={classes.leftAction}>
            {this.props.showExplanation && (
              <ToolTip
                toolTip={this.props.explanation}
                showCustom={true}
                ishoverExplanationToolTip={this.props.ishoverExplanationToolTip}
                autoHideExplanationToolTip={this.props.autoHideExplanationToolTip}
                onHoverExplanationToolTipText={this.props.onHoverExplanationToolTipText}
              />
            )}
            <div className={classes.tags}>
              {productStateArr && productStateArr.includes("preferred") && (
                <div className={classNames(classes.tag, classes.isPreferred)}>
                  <span>P</span>
                  <span>Preferred</span>
                </div>
              )}
              {(this.props.IsExisting || productStateArr.includes("existing")) && (
                <div className={classNames(classes.tag, classes.isExisting)}>
                  <span>E</span>
                  <span>Existing</span>
                </div>
              )}
              {productStateArr && productStateArr.includes("blocked") && (
                <div className={classNames(classes.tag, classes.isBlocked)}>
                  <span>B</span>
                  <span onClick={() => this.props.onClickState('blocked')}>Blocked</span>
                </div>
              )}
              {productStateArr && productStateArr.includes("featured") && (
                <div className={classNames(classes.tag, classes.isFeatured)}>
                  <span>F</span>
                  <span onClick={() => this.props.onClickState('featured')}>Featured</span>
                </div>
              )}
              {productStateArr && productStateArr.includes("in_review") && (
                <div className={classNames(classes.tag, classes.isInReview)}>
                  <span>I</span>
                  <span onClick={() => this.props.onClickState('in_review')}>In Review</span>
                </div>
              )}
              {productStateArr && productStateArr.includes("pending") && (
                <div className={classNames(classes.tag2, classes.isFeatured)}>
                  <span>Pending</span>
                </div>
              )}
              {productStateArr && productStateArr.includes("my_product") && (
                <div className={classNames(classes.tag2, classes.isExisting)}>
                  <span>My Product</span>
                </div>
              )}
              {productStateArr && productStateArr.includes("limited") && (
                <div className={classNames(classes.tag, classes.isLimited)}>
                  <span>L</span>
                  <span onClick={() => this.props.onClickState('limited')}>Limited Use</span>
                </div>
              )}
              {productStateArr && productStateArr.includes("evaluated") && ((EnableEvaluateForAdminsCheck && this.props.userRole !== 'OrgUser') || (EnableEvaluateForUsersCheck && this.props.userRole === 'OrgUser')) && (
                <div className={classNames(classes.tag, classes.isEvaluated)}>
                  <span>Ev</span>
                  <span onClick={() => this.props.onClickState('evaluated')}>Evaluated</span>
                </div>
              )}
              {productStateArr && productStateArr.includes("deprecated") && (
                <div className={classNames(classes.tag, classes.isDeprecated)}>
                  <span>D</span>
                  <span onClick={() => this.props.onClickState('deprecated')}>Deprecated</span>
                </div>
              )}
            </div>
          </div>
          <div className={classnames(classes.contentContainer,)}
            onMouseEnter={(event) => this.openToolTip(event)}
            onMouseLeave={() => this.openToolTip(null)}
            onClick={(e) => {
              if (this.props.isSelectable) {
                this.props.onSelect(!this.props.selected, this.props.id)
                return;
              }
              if (this.props.fireEvent && this.props.eventData !== undefined && this.props.eventData !== null) {
                this.props.fireEvent(this.props.eventData)
              }
              this.showPopup(e, productStateArr);
              this.props.onClick()
            }}
          >
            <div className={classes.imageContainer} >
              <Image
                src={this.props.image}
                alt="cardImage"
                className={classes.cardImage}
              />
            </div>
            {this.props.title !== null && this.props.title && (
              <Typography
                variant={"h5"}
                className={classnames(this.props.numTitleLines === 3 ? classes.cardTitle2 : classes.cardTitle, 'layer-card-title')}
              >
                {/* {this.props.title} */}
                <LinesEllipsis
                  text={this.props.title}
                  maxLine={this.props.numTitleLines}
                  ellipsis="..."
                  basedOn="letters"
                />
              </Typography>
            )}

            {/* {this.props.isShowGroupName && this.props.groupName !== null && (
              <MuiTooltip title={this.props.groupName} placement="top">
                <Typography
                  variant={"h5"}
                  className={classnames(this.props.grpTitleLines === 3 ? classes.cardTitle2 : classes.grpTitle, 'layer-card-title')}
                >
                  <LinesEllipsis
                    text={this.props.groupName}
                    maxLine={this.props.grpTitleLines}
                    ellipsis="..."
                    basedOn="letters"
                  />
                </Typography>
              </MuiTooltip>
            )} */}



            {this.props.showSubtitle !== null && this.props.showSubtitle && <Typography
              variant={"subtitle1"}
              className={classnames(classes.cardSubTitle, 'layer-card-subtitle')}
            >
              {this.props.subTitle}
            </Typography>}
            {isShowButton && this.props.showCheckbox && (
              <div className={classes.cta}>
                <Button
                  disabled={productStateArr.includes("blocked") || productStateArr.includes("deprecated")}
                  onClick={async (e) => {
                    e.stopPropagation()
                    if (this.props.fireEvent) {
                      this.props.fireEvent({
                        type: isRequestAccess
                          ? "event.product.request-access.click"
                          : this.props.isOrgProduct && this.props.IsExisting
                            ? "event.product.review.click"
                            : "event.product.evaluate.click",
                        data: this.props?.eventData?.data || {
                          url: this.props?.location?.pathname + this.props?.location?.search,
                          product: {
                            id: this.props?.id,
                            name: this.props?.title,
                          },
                        },
                      })
                    }
                    if (isRequestAccess) {
                      console.log(this.state.productDetails?.AccessLinkMessage)
                      const RequestAccessConfig = Validator.isJson(this.state.productDetails?.AccessLinkMessage) && 
                      JSON.parse(this.state.productDetails?.AccessLinkMessage)?.RequestAccessTypeOptions    
                      ?
                      JSON.parse(this.state.productDetails?.AccessLinkMessage) : {
                      RequestAccessTypeOptions: [
                        { label: "Redirect to URL", value: "AccessLink" },
                        { label: "Show message", value: "RequestAccessMessage" },
                        { label: "Execute Workflow", value: "RequestAccessWorkflow" },
                      ],
                      RequestAccessType: {
                        label: "Redirect to URL",
                        value: "AccessLink",
                      },
                      RequestAccessMessage: [{ type: "paragraph", children: [{ text: "" }] }],
                      RequestAccessWorkflow: null,
                      AccessLink: "",
                    };


                      console.log(RequestAccessConfig, "RequestAccessConfig");

                      let requestLink = RequestAccessConfig?.AccessLink ||"";
                      let requestMsg = RequestAccessConfig?.RequestAccessMessage ||"";    
                      const championAccessLink = {
                        head: "Request Access",
                        isLink: true,
                        link: RequestAccessConfig?.AccessLink,
                        isLinkRequired: false,
                        linkPlaceholder: 'Link',
                        type: 'requestAccessLink',
                        isMessage: true,
                        messagePlaceholder: 'Please enter your message.',
                        message: requestMsg,
                        description: requestMsg
                      }

                      const championMessageLink = {
                        head: "Request Access",
                        isLink: true,
                        link: '',
                        isLinkRequired: false,
                        linkPlaceholder: 'Link',
                        type: 'requestAccessLink',
                        isMessage: true,
                        messagePlaceholder: 'Please enter your message.',
                        message: RequestAccessConfig?.RequestAccessMessage || '',
                        description: RequestAccessConfig?.RequestAccessMessage || ''
                      }

                      const reqAccessMessageAdmin = {
                        head: "Request Access",
                        isLink: false,
                        link: requestLink,
                        isLinkRequired: false,
                        linkPlaceholder: 'Link',
                        type: 'requestAccessLink',
                        isMessage: true,
                        messagePlaceholder: 'There is no request access link anywhere. Please edit it at the product level.',
                        message: 'There is no request access link anywhere. Please edit it at the product level.',
                      }

                      const reqAccessMessageOrgUser = {
                        head: "Request Access",
                        isLink: false,
                        link: requestLink,
                        isLinkRequired: false,
                        linkPlaceholder: 'Link',
                        type: 'requestAccessLink',
                        isMessage: true,
                        messagePlaceholder: 'Please reach out to admin for request access.',
                        message: 'Please reach out to admin for request access.',
                      }
                      const payload = {
                        query: this.props.searchData?.success?.text,
                        product_id : this.props.id,
                        event_type: "SearchAndRequested",
                        page: "software product listing page",
                      }

                      if (RequestAccessConfig?.RequestAccessType?.value === 'RequestAccessWorkflow') {
                        if (RequestAccessConfig?.RequestAccessWorkflow) {
                          let workflowID = RequestAccessConfig?.RequestAccessWorkflow?.value;
                          console.log("requestAccessWorkflow", workflowID);
                          if (workflowID !== undefined && workflowID !== null && workflowID !== 0) {
                            var data = {
                              product_ids: [{
                                entity_id: String(this.state.productDetails?.productID),
                                type: "PRODUCT",
                              }],
                              title: 'Request for ' + this.state.productDetails?.name,
                              description: '',
                              workflow_id: RequestAccessConfig?.RequestAccessWorkflow?.value,
                              type: 10
                            }
                            this.props.startNewWorkflow(data);
                          } else {
                            this.setState({
                              showProductWorkflowDialogFrom: 'BUTTON'
                            }, () => {
                              this.props.showAddProductWorkflowDialog()
                            })
                          }
                        } else {
                          var data1 = {
                            product_ids: [{
                              entity_id: String(this.state.productDetails?.productID),
                              type: "PRODUCT",
                            }],
                            title: 'Request for ' + this.state.productDetails?.name,
                            description: '',
                            workflow_id: RequestAccessConfig?.RequestAccessWorkflow.value,
                            type: 10
                          }
                          this.props.startNewWorkflow(data1);
                        }
                      } else if (RequestAccessConfig?.RequestAccessType?.value === 'AccessLink' && RequestAccessConfig?.AccessLink && RequestAccessConfig?.AccessLink?.length !== 0) {
                        console.log("championAccessLink1", championAccessLink)
                        this.props.showRequestAccessInformationDialog(championAccessLink)
                      } else if (RequestAccessConfig?.RequestAccessType?.value === 'RequestAccessMessage' && RequestAccessConfig?.RequestAccessMessage && RequestAccessConfig?.RequestAccessMessage?.length !== 0  && RequestAccessConfig?.RequestAccessMessage !== '[{"type":"paragraph","children":[{"text":""}]}]' && '{"children":[{"text":""}],"type":"paragraph"}' !== RequestAccessConfig?.RequestAccessMessage) {
                        console.log("championMessageLink", championMessageLink)
                        this.props.showRequestAccessInformationDialog(championMessageLink)
                      } else if (RequestAccessConfig?.RequestAccessType?.value === 'RequestAccessWorkflow') {

                        var data = {
                          product_ids: [{
                            entity_id: String(this.state.productDetails?.productID),
                            type: "PRODUCT",
                          }],
                          title: 'Request for ' + this.state.productDetails?.name,
                          description: '',
                          workflow_id: RequestAccessConfig?.RequestAccessWorkflow.value,
                          type: 10
                        }
                        console.log("data", data)
                        this.props.startNewWorkflow(data);
                      } else if (RequestAccessConfig?.AccessLink && RequestAccessConfig?.AccessLink?.length !== 0) {
                        console.log("championAccessLink2", championAccessLink)
                        this.props.showRequestAccessInformationDialog(championAccessLink)
                      } else {
                        if (this.props.userRole === "OrgUser") {
                          console.log("reqAccessMessageOrgUser", reqAccessMessageOrgUser)
                          this.props.showRequestAccessInformationDialog(reqAccessMessageOrgUser)
                        } else {
                          console.log("reqAccessMessageAdmin", reqAccessMessageAdmin)
                          this.props.showRequestAccessInformationDialog(reqAccessMessageAdmin)
                        }
                      }
                      this.props.updateReportData(payload);
                    } else if (isProductClaim && this.props.id) {
                      this.props.claimProduct([parseInt(this.props.id)])
                    } else if (this.props.source === 'SURVEY') {
                      this.props.selectForSurvey();
                    } else {
                      this.props.toogleCheck()
                    }
                  }}
                  variant="contained"
                  fullWidth
                >
                  {isRequestAccess ? (
                    <>
                      {evText}
                    </>
                  ) : (
                    this.props.checked ? `- ${evText}` : `+ ${evText}`
                  )}
                </Button>
              </div>
            )}
            {this.props.toolTip >= 0 && <ToolTip toolTip={this.props.toolTip} />}
          </div>
        </div>

        {(this.props.type && this.props.type === 'product') && dialogProductDetailsOpen && (dialogProductDetailsId === this.props.uniqueId) && this.props.dialogProductDetailsEvent !== null &&
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <div className={classes.pd_root}
              style={popupPosition()}
              ref={this.dialogDetailsRef}>
              <span className={classes.close} onClick={() => {
                hideProductDetailsDialog()
              }}>
                <CloseIcon className={classes.close} />
              </span>

              <div className={classes.pd_headerContainer}>
                <div style={{ width: '80px' }}>
                  <Image src={this.props.image} className={classes.pd_logo} style={{ width: '100%' }} />
                </div>
                <div style={{ marginLeft: '24px' }}>
                  {this.props.title !== null && this.props.title && (
                    <h2 className={classes.pd_title}>
                      <LinesEllipsis
                        text={this.props.title}
                        maxLine={this.props.numTitleLines}
                        ellipsis="..."
                        basedOn="letters"
                      />
                    </h2>
                  )}
                  <h5 className={classes.pd_subTitle}>{this.props.subTitle}</h5>
                </div>
              </div>

              {productStateArr.includes("blocked") ? (
                <Grid container spacing={1}>
                  <Grid item xs={11} className={classes.popupContainer}>
                    <div className={classes.pd_descriptionContainer} style={{ cursor: 'pointer' }}>
                      <p>
                        {reason ? (
                          <SlateReadonlyField initValue={productBlockReaction?.Reason} />
                        ) : (
                          commonBlockedMsg
                        )}
                      </p>
                    </div>
                  </Grid>
                  {this.props.userRole === "OrgAdmin" && (
                    <Grid item xs={1}>
                      <Image
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          this.handleBlock('upsert', true)
                        }}
                        style={{ marginLeft: 8, marginBottom: -16, cursor: 'pointer' }}
                        alt="Edit"
                        src={editIcon}
                      />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <>
                  {productStateArr.includes("deprecated") ? (
                    <Grid container spacing={1}>
                      <Grid item xs={11} className={classes.popupContainer}>
                        <div className={classes.pd_descriptionContainer} style={{ cursor: 'pointer' }}>
                          <p>
                            {deprecatedReason ? (
                              <SlateReadonlyField initValue={productDeprecateReaction?.Reason} />
                            ) : (
                              commonDeprecatedMsg
                            )}
                          </p>
                        </div>
                      </Grid>
                      {this.props.userRole === "OrgAdmin" && (
                        <Grid item xs={1}>
                          <Image
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              this.handleDeprecate('upsert', true)
                            }}
                            style={{ marginLeft: 8, marginBottom: -16, cursor: 'pointer' }}
                            alt="Edit"
                            src={editIcon}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <>
                      <div className={classes.pd_descriptionContainer}>
                        <p className={classes.ellipsis_3}>
                          <SlateReadonlyField initValue={this.props.description} /></p>
                      </div>
                      <div className={classes.pd_actionContainer} onClick={(e) => {
                        if (this.props.fireEvent && this.props.eventData !== undefined && this.props.eventData !== null) {
                          if (this.props.eventPage === 'productDetails') {
                            this.props.eventData.type = 'event.click.product.section.popup';
                          } else if (this.props.eventPage === 'orgProductDetails') {
                            this.props.eventData.type = 'event.click.org-product.section.popup';
                          } else if (this.props.eventPage === 'productRecommendation') {
                            this.props.eventData.type = 'event.click.product.on-product-recommendation.popup';
                          } else if (this.props.eventPage === 'companyRecommendation') {
                            this.props.eventData.type = 'event.click.product.on-company-recommendation.popup';
                          } else if (this.props.eventPage === 'productAllProducts') {
                            this.props.eventData.type = 'event.click.product.on-allproducts.popup';
                          }
                          this.props.fireEvent(this.props.eventData)
                        }
                        e.stopPropagation()
                        hideProductDetailsDialog()
                        let route = this.props.customRoute ? (`${this.props.customRoute}` + this.props.id) : (`/app/products/product-details/` + this.props.id)
                        this.props.history.push(route)
                      }}>
                        {/* onClick={()=>{}} */}
                        <Typography className={classes.pd_action}>
                          View details
                        </Typography>
                      </div>
                    </>
                  )}
                </>
              )}

            </div>
          </ClickAwayListener>
        }
        {this.state.isOpenBlockProductMessageDialog && <BlockProductMessageDialog
          isOpen={this.state.isOpenBlockProductMessageDialog}
          isEdit={this.state.isBlockEdit}
          onSubmit={msg => {
            this.handleSubmitBlock(msg);
          }}
          value={reason ? productBlockReaction?.Reason : commonBlockedMsg}
          productName={this.props.title}
          onClose={() => {
            this.setState({
              isOpenBlockProductMessageDialog: false,
              blockType: null,
            });
            if (!this.state.isBlockEdit) {
              this.props.addProductState(this.props.id, this.state.updatePendingStatesArray);
            }
          }}
        />}
        {this.state.isOpenDeprecateProductMessageDialog && <DeprecateProductMessageDialog
          isOpen={this.state.isOpenDeprecateProductMessageDialog}
          isEdit={this.state.isDeprecateEdit}
          productName={this.props.title}
          onSubmit={msg => {
            this.handleSubmitDeprecate(msg);
          }}
          value={deprecatedReason ? productDeprecateReaction?.Reason : commonDeprecatedMsg}
          onClose={() => {
            this.setState({
              isOpenDeprecateProductMessageDialog: false,
              deprecateType: null,
            });
            if (!this.state.isDeprecateEdit) {
              this.props.addProductState(this.props.id, this.state.updatePendingStatesArray);
            }
          }}
        />}
        {/* {this.props.showNewWorkflowStartDialog && <StartWorkflowDialog />}
        {this.props.isOpenReqAccInfoDialog && <RequestAccessInformation />} */}

      </div>
    );
  }
}

LayeredCard.defaultProps = {
  subTitle: "",
  cardType: null,
  color: "DEFAULT",
  numLayer: 2,
  checked: false,
  showCheckbox: false,
  numTitleLines: 2,
  showSubtitle: true,
  isOrgProduct: false,
  showExplanation: false,
  explanation: "",
  ishoverExplanationToolTip: false,
  autoHideExplanationToolTip: true,
  onHoverExplanationToolTipText: "",
  requestLink: "",
  productStates: [],
  onClickState: () => { },
  isSelectable: false,
  onSelect: () => { },
  selected: false,
  isShowGroupName: false,
  query: "",
};

LayeredCard.propTypes = {
  isSelectable: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  toogleCheck: PropTypes.func,
  color: PropTypes.oneOf(["ORANGE", "GREEN", "BLUE", "CYAN", 'GREY', "DEFAULT"]),
  numLayer: PropTypes.oneOf([2, 1, 0]),
  checked: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  showSubtitle: PropTypes.bool,
  numTitleLines: PropTypes.number,
  isOrgProduct: PropTypes.bool,
  showExplanation: PropTypes.bool,
  explanation: PropTypes.string,
  ishoverExplanationToolTip: PropTypes.bool,
  autoHideExplanationToolTip: PropTypes.bool,
  onHoverExplanationToolTipText: PropTypes.string,
  requestLink: PropTypes.string,
  productStates: PropTypes.array,
  onClickState: PropTypes.func,
  isForRequirementSelection: false,
  query: PropTypes.string,
};

export default connector(compose(withRouter, withStyles(styles))(LayeredCard));