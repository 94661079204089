import { createStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Grid,
  TableRow,
  CircularProgress,
  Paper,
  Typography
} from "@material-ui/core";
import { CommonFn } from "services/commonFn";
// import { geFlowType} from "util/Columns";
import Image from "Components/Common/image.jsx";
import DefaultImage from "assets/images/evaluation/default.svg";
// components
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
//redux
import { requestDetails, hideRequestDetailsCart } from "redux/evaluation/workflow/action";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
  user: state.authUser.user,
  isRequestDetailsCartOpen: state.evaluationWorkflow.isRequestDetailsCartOpen,
  requestDetailsProgress: state.evaluationWorkflow.requestDetailsProgress,
  requestDetails: state.evaluationWorkflow.requestDetails,
  requestDetailsError: state.evaluationWorkflow.requestDetailsError,
  evaluation: state.evaluationMetaData.evaluation,
  evaluateView: state.applications.evaluateView,
});

const connectionActions = {
  fetchRequestDetails: requestDetails,
  hideRequestDetailsCart: hideRequestDetailsCart,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const drawerWidthExpanded = 48;

const styles = (theme) =>
  createStyles({
    drawer: {
      width: theme.spacing(drawerWidthExpanded),
      flexShrink: 0,
      transition: "width 300ms",
      background: "#F7F7F7",
    },
    drawerPaper: {
      width: theme.spacing(drawerWidthExpanded),
      transition: "width 300ms",
      paddingTop: theme.spacing(3),
      background: "#ffffff",
    },

    cartContainer: {
      padding: '4px 20px',
      '&.close': {
        opacity: 0
      }
    },

    progressIndicator: {
      margin: theme.spacing(2),
      textAlign: "center",
    },
    headSection: {
      justifyContent: "space-between",
      minHeight: 40,
    },
    cartItem: {
      maxWidth: "400px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #EEEEEE",
      borderRadius: 10,
      margin: `${theme.spacing(2)} 0`,
      justifyContent: "space-between",
      paddingRight: '10px'
    },
    productNameContainer: {
      flex: 0.95,
    },
    productName: {
      flex: 0.95,
    },
    productImage: {
      width: "100%",
      height: "auto",
      marginRight: theme.spacing(2),
    },
    productNameTitle: {
      fontSize: theme.spacing(2),
    },
    productImageContainer: {
      width: 60,
      height: 60,
      padding: 13,
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      // boxShadow: "0px 1px 6px #0000001A",
      borderRadius: 10,
    },
  });


class RequestDetailsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this.props.fetchRequestDetails(this.props.evaluation_id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.requestDetailsProgress === false && prevProps.requestDetailsProgress === true) {
      // console.log(this.props.requestDetailsError,'this.props.requestDetailsError1')
      if (this.props.requestDetailsError !== null) {
        // this.props.showSnackBar("Failed to fetch details", "error", 3000);
      }
    }
  }

  render() {
    const { requestDetails, classes } = this.props;

    let items = [];

    if (this.props.evaluateView && this.props.evaluation && this.props.evaluation.Products) {
      this.props.evaluation.Products.forEach((product) => {
        if (product.ID === this.props.evaluateViewId) {
          items.push(product);
        }
      });
    } else if (this.props.evaluation) {
      items = this.props.evaluation.Products;
    }

    return (
      <div style={{ padding: '1rem' }}>

        {this.props.requestDetailsProgress && requestDetails === null && (
          <div className={classes.progressIndicator}>
            <CircularProgress />
          </div>
        )}

        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableBody>
                <TableRow>
                  <TableCell variant="head">Project ID</TableCell>
                  <TableCell>{requestDetails?.ID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Project Name</TableCell>
                  <TableCell>{requestDetails?.Name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Requestor</TableCell>
                  <TableCell>{requestDetails?.RequestorName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Project Status</TableCell>
                  <TableCell>{requestDetails?.RequestStatus === "PendingApproval" ? "Pending Approval" : requestDetails?.RequestStatus}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Current Step</TableCell>
                  <TableCell>{requestDetails?.CurrentComponnetName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Workflow Used</TableCell>
                  <TableCell>{requestDetails?.WorkflowName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Requested Products</TableCell>
                  <TableCell>
                    {!this.props.targetContainer &&
                      items.map((item, k) => {
                        return (
                          <Grid container alignItems="center" className={classes.cartItem}>
                            <Grid item className={classes.productImageContainer}>
                              <Image alt="product" src={item.ProductLogo == null || item.ProductLogo.length === 0 ? DefaultImage : item.ProductLogo} className={classes.productImage} />
                            </Grid>
                            <Grid item className={classes.productNameContainer}>
                              <Typography variant={"h5"} className={classes.productNameTitle}>
                                {item.Name}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Created at</TableCell>
                  <TableCell>{CommonFn.formateDate(requestDetails?.CreatedAt, true)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Last Updated</TableCell>
                  <TableCell>{CommonFn.formateDate(requestDetails?.UpdatedAt, true)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(RequestDetailsTable));
