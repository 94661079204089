/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {UserFactory} from "../../services/user";

export const START_UPDATEPASSWORD     =   "START_UPDATEPASSWORD";
export const END_UPDATEPASSWORD       =   "END_UPDATEPASSWORD";

//Action to start the registration
function startUpdatePassword(){
    return {
        type:START_UPDATEPASSWORD,
        payload:null
    };
}

function endUpdatePassword(success,error){
    return {
        type:END_UPDATEPASSWORD,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the register
export  function updatePassword(old_password,new_password){
    return async (dispatch) => {
        dispatch(startUpdatePassword());
        try{
            let [response,error]    =   await UserFactory.instance().updatePassword(old_password,new_password);
            dispatch(endUpdatePassword(response,error));
        } catch(e){
            dispatch(endUpdatePassword(null,{
                message:e.message
            }));
            return;
        }
    };
}   