import {EvaluationFactory} from "../../../services/evaluation";
import { isArray } from "underscore";
import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const START_ADD_THREAD = "START_ADD_THREAD";
export const END_ADD_THREAD = "END_ADD_THREAD";

export const START_FETCH_THREAD = "START_FETCH_THREAD";
export const END_FETCH_THREAD = "END_FETCH_THREAD";

export const START_FETCH_THREAD_REPLIES = "START_FETCH_THREAD_REPLIES";
export const END_FETCH_THREAD_REPLIES = "END_FETCH_THREAD_REPLIES";

export const START_UPDATE_THREAD = "START_UPDATE_THREAD";
export const END_UPDATE_THREAD = "END_UPDATE_THREAD";

export const START_DELETE_THREAD = "START_DELETE_THREAD";
export const END_DELETE_THREAD = "END_DELETE_THREAD";

//-------------------------------------
function startFetchThreadReplies(){
    return {
        type:START_FETCH_THREAD_REPLIES,
        payload:{}
    };
}

function endFetchThreadReplies(success,error,threadId){
    return {
        type:END_FETCH_THREAD_REPLIES,
        payload:{
            success,error,threadId
        }
    };
}

export  function fetchThreadReplies(evId,componentId,threadId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/component/${componentId}/thread/${threadId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchThreadReplies());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url,null,false);
            dispatch(endFetchThreadReplies(response,error,threadId));
        } catch(e){
            dispatch(endFetchThreadReplies(null,{
                message:e.message
            },threadId));
            return;
        }
    };
} 

//------------------------------------------



//-------------------------------------
function startAddThread(){
    return {
        type:START_ADD_THREAD,
        payload:{}
    };
}

function endAddThread(success,error){
    return {
        type:END_ADD_THREAD,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function addThread(evId,componentId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/component/${componentId}/thread?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddThread());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endAddThread(response,error));
        } catch(e){
            dispatch(endAddThread(null,{
                message:e.message
            }));
            return;
        }
    };
} 



//-------------------------------------
function startUpdateThread(){
    return {
        type:START_UPDATE_THREAD,
        payload:{}
    };
}

function endUpdateThread(success,error){
    return {
        type:END_UPDATE_THREAD,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateThread(evId,componentId,threadId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/component/${componentId}/thread/${threadId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateThread());
        try{
            let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
            dispatch(endUpdateThread(response,error));
        } catch(e){
            dispatch(endUpdateThread(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------



//-------------------------------------
function startDeleteThread(){
    return {
        type:START_DELETE_THREAD,
        payload:{}
    };
}

function endDeleteThread(success,error){
    return {
        type:END_DELETE_THREAD,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function deleteThread(evId,componentId,threadId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/component/${componentId}/thread/${threadId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteThread());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteThread(response,error));
        } catch(e){
            dispatch(endDeleteThread(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------

function startFetchThread(){
    return {
        type:START_FETCH_THREAD,
        payload:{}
    };
}

function endFetchThread(success,error){
    return {
        type:END_FETCH_THREAD,
        payload:{
            success,error
        }
    };
}

export  function fetchThread(evId,componentId,sortBy, threadType=''){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/component/${componentId}/thread?sortBy=${sortBy}&thread_type=${threadType}&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchThread());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url,null,false);
            dispatch(endFetchThread(response,error));
        } catch(e){
            dispatch(endFetchThread(null,{
                message:e.message
            }));
            return;
        }
    };
} 