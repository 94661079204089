import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide
} from '@material-ui/core';
import {hideApprovalDialog} from "redux/evaluation/approval/action"
import {showSnackBar} from "../../../../../../../redux/snackbar/action";
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import SqureButton from '../../../../../../Common/SqureButton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen:state.evaluationApproval.isOpneApprovalMessage,
    approvalMessage: state.evaluationApproval.approvalMessage,
});

const connectionActions = {
    hideDialog:hideApprovalDialog,
    showSnackBar:showSnackBar,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    title: {
        fontSize: 20,
        fontWeight: 600,
        margin: '10px 0px',
        textAlign: 'center'
    },
    description: {
        margin: '20px 0px 20px',
        textAlign: 'center'
    },
    close: {
        position: 'absolute',
        right: 15,
        top: 15,
        cursor: 'pointer',
        color: '#6F6F6F',
        '& svg':{
          stroke: 'white',
          fontSize: '24px'
        }
    },
    finishBtn: {
        marginTop: 20
    }
});

class ApprovalDialog extends React.Component {

    constructor(props){
        super(props);
        this.state  =   {
           
        }
    }

    componentDidUpdate(prevProps){
    }

    linkBtn = (link) => {
        this.props.hideDialog();
        this.props.history.push(link)
    }
    

    render() {
        const classes = this.props.classes;
        return <Dialog 
                    onClose={this.props.hideDialog} 
                    aria-labelledby="app-integrationDialog" 
                    open={this.props.isOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={false}
                    fullWidth={true}
                    maxWidth={"sm"}
                    scroll="body"
                    id="evMetaDialog"
                    >
                        <DialogContent classes={{root:classes.dialogContent}} >
                            <span className={classes.close} onClick={() => this.props.hideDialog()}>
                                <CloseIcon className={classes.close} />
                            </span>
                             <div>
                                 <h6 className={classes.title}>{this.props.approvalMessage?.title}</h6>
                                 <div className={classes.description}>{this.props.approvalMessage?.description?.map((item, index) => <div key={index}>
                                    { !item.link && <div>{item.text}</div> }
                                    { item.link &&   <div className={classes.finishBtn}>
                                        <SqureButton  variant={"contained"} onClick={()=> this.linkBtn(item.link)
                                   
                                }>{item.text}</SqureButton>
                                    </div> }
                                 </div> )}</div>
                             </div>
                        </DialogContent>
                </Dialog>
    }
}
export default connector(compose(
    withRouter,
    withStyles(styles)
)(ApprovalDialog));