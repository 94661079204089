import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import classnames from "classnames";
import { CommonFn } from "services/commonFn";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import { Avatar, Grid, Chip } from "@material-ui/core";
import { getRequirementType } from "util/RequirementTypeOptions";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    requirement: {
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
      },
      cursor: "pointer",
      display: "flex",
      overflow: "hidden",
      flexDirection: "column",
      position: "relative",
      transition: "all linear .5s",
      "&.rootRequirementHight": {
        height: 300,
      },
      "&.options-hide": {
        pointerEvents: "none",
      },
    },
    yourResponse: {
      textAlign: "left",
      letterSpacing: "0px",
      padding: "1rem",
      color: "#707070",
      opacity: 1,
      fontSize: "13px",
      transition: "height 0.3s ease-in",
    },
    response: {
      textAlign: "left",
      letterSpacing: "0px",
      color: "#04363D",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      placeContent: "center space-between",
      alignItems: "center",
      width: "100%",
      transition: "all linear 1s",
      position: "relative",
      // marginTop: 16,
      // marginBottom: 6,
      "& [class*='MuiInputBase-inputMultiline']": {
        fontSize: 13,
        height: 70,
        color: "#707070",
      },
    },
    "@global": {
      ".rootRequirementHight": {
        "& [class*='response-addText']": {
          height: "150px",
        },
        "& [class*='editor-formControl']": {
          maxHeight: "150px",
        },
      },
    },

    userResponder: {
      border: "1px solid #f2f2f2",
      padding: 10,
      marginBottom: 10,
      borderRadius: 8,
    },
    userInfoItem: {
      display: "flex",
      alignItems: "center",
      "& span": {
        marginLeft: 10,
      },
    },
    requirementExpanded01: {
      backgroundColor: "white",
      height: "100%",
    },
    disableForm: {
      marginTop: 12,
      marginLeft: 5,
    },
    collapseTxt: {
      fontSize: theme.spacing(2),
      color: "#707070",
      marginTop: theme.spacing(1),
    },
    RightPanel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    tabs: {
      "& [class*=MuiTab-root]": {
        maxWidth: "unset",
      },
    },
  });

// const MatchTypes = {
//   exact_text_exact_type: "Exact question with exact type",
//   exact_text_different_type: "Exact question with different type",
//   similar_text_exact_type: "Similar question with exact type",
//   similar_text_different_type: "Similar question with different type",
//   no_match: "Nothing matched",
// };

class Response extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      response: "",
      value: 0,
    };
    this.avatarName = this.avatarName.bind(this);
  }

  avatarName(name) {
    return "";
    // return name?.substring(0, 1);
  }

  componentDidMount() {}

  render() {
    const classes = this.props.classes;

    var response = this.props.requirement?.match_object || {};

    let answerType = response?.answer_type;
    let options = response?.options_answer;
    let printableAnswer = null;
    try {
      if (![2, 3, 4, 14, 15].includes(answerType)) {
        let answer = response?.answer;
        if (answer && answerType === 11) {
          printableAnswer = CommonFn.formateDate(answer, true);
        } else if (answer && answerType === 13) {
          let data = JSON.parse(answer);
          printableAnswer = data.value + data.type.label;
        } else if (answer && answer !== null && answer !== "" && answerType === 6) {
          printableAnswer = answer?.map((dt) => {
            return (
              <p>
                <a style={{ color: "#4b86ff" }} without rel="noreferrer" target={"_blank"} href={dt?.url}>
                  {dt.name}
                </a>
              </p>
            );
          });
        } else if (answerType === 1 || answerType === 7) {
          printableAnswer = <SlateReadonlyField initValue={answer} />;
        } else {
          printableAnswer = answer;
        }
      } else {
        printableAnswer = options?.map((u) => u.title).join(", ") || "-";
      }
    } catch (e) {}

    return (
      <div className={classes.requirementExpanded01}>
        <div className={classnames(classes.requirement, this.props.respondable ? "options-show" : "options-hide")}>
          <div className={classes.yourResponse}>
            {response?.answer === "" && response?.options_answer.length <= 0 ? (
              <div style={{ textAlign: "center" }}>No Response</div>
            ) : (
              <>
                <div className={classes.userResponder}>
                  {/* <div>
                      <Typography className={classes.userInfoItem}>
                        <Tooltip
                          placement="top"
                          title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{response?.created_by}</span>}
                          arrow
                        >
                          <Avatar className={classes.avatarImg}> {this.avatarName(response?.created_by || "")}</Avatar>
                        </Tooltip>
                        <span>{response?.created_by}</span>
                      </Typography>
                    </div> */}

                  <div className={classes.disableForm}>
                    <div className={classes.textWrap}>{printableAnswer}</div>
                  </div>
                </div>
                <Grid item md={12} className={classes.RightPanel}>
                  <div>
                    <Typography component="p" className={classes.collapseTxt}>
                      Answer type - {getRequirementType([], answerType)}
                    </Typography>
                    {[2, 3, 4, 14, 15].includes(answerType) && (
                      <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
                        {response?.options?.map((option, i) => (
                          <Chip key={i} label={option.title} variant="outlined" color="primary" size="small" />
                        ))}
                      </div>
                    )}
                  </div>
                </Grid>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Response));
