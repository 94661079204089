import { Grid } from "@material-ui/core";
import { createStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { showEvaluationSelectDialog } from "redux/templates/evaluation/action";
import Integration from "../../../../assets/images/integration.svg";
import Templates from "../../../../assets/images/templates.svg";
import User from "../../../../assets/images/user.svg";
import { showAddCompanyProfileDialog } from "../../../../redux/companyProfileSettings/action";
import CardAction from "../../../Common/CardAction";
import AppBarV2 from "../../AppBarV2";
import classnames from "classnames";
import CommonCss from 'commonCss';

const connectedProps = (state) => ({
  authUser: state.authUser,
  drawer_expanded: state.drawer.expanded,
  userRole: state.authUser.user?.Role,
});

const connectionActions = {
  showAddCompanyProfileDialog: showAddCompanyProfileDialog,
  showEvaluationSelectDialog: showEvaluationSelectDialog,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  "actionContainer": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),

    "& [class*='MuiGrid-item']": {
      padding: theme.spacing(2)
    }
  },

  "cardAction": {
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    height: '100%'
  },

  "Primary": {
    textAlign: 'center',
    fontSize: theme.spacing(2.4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },

  "Secondary": {
    textAlign: 'center',
  },

  "Content": {
    padding: theme.spacing(2, 2, 0, 2),
    flex: 'inherit'
  },
  rootContainer: CommonCss.rootContainer
});

class SettingsCard extends React.Component {

  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      error: '',
      sm1: window.matchMedia('(min-width: 576px) and (max-width: 667.98px)').matches,
      sm2: window.matchMedia('(min-width: 668px) and (max-width: 767.98px)').matches
    }
  }

  updateDimensions = () => {
    let sm1 = window.matchMedia('(min-width: 576px) and (max-width: 667.98px)').matches;
    let sm2 = window.matchMedia('(min-width: 668px) and (max-width: 767.98px)').matches;
    this.setState({
      sm1: sm1,
      sm2: sm2
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const classes = this.props.classes;
    const isHideAutomation = process.env.REACT_APP_ENV !== 'dev' || this.props.userRole !== "OrgAdmin" ? true : false;
    const isHideDepartments = this.props.userRole !== "OrgAdmin" ? true : false;
    const hideReports = (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'test') ? false : (this.props.authUser?.user?.Organization?.ID == 54 || this.props.authUser?.user?.Organization?.ID == 3) ? false : true;
    const cardContent = [
      // { primary: "Products", secondary: "Manage your vendors", image: Products,  activeImage: true, link: '/app/settings/products' },
      { primary: "Users & Teams", secondary: "Manage your Users and Teams", image: User, activeImage: false, link: '/app/settings/users' },
      { primary: "Integrations", secondary: "Manage your Integrations", image: Integration, activeImage: false, link: '/app/settings/integrations' },
      { primary: "Templates", secondary: "Manage your Templates", key: "templates", image: Templates, activeImage: false, link: '/app/settings/templates' },
      { primary: "Company Profile", secondary: "Manage your company profile", key: "companyProfile", image: Templates, activeImage: false, link: '/app/settings/company-profile' },
      // { primary: "Payments", secondary: "Manage Payment", key:"payments", image: Templates,  activeImage: false, link: '/app/settings/payment' },
      { primary: "Configurations", secondary: "Manage your configurations", key: "configuration", image: Templates, activeImage: false, link: '/app/settings/configurations' },
      {
        primary: "Quicksight",
        isHide: true,
        // isHide: !(process.env.REACT_APP_ENV === 'dev' || (process.env.REACT_APP_ENV === 'test' && this.props.authUser?.user?.Organization?.ID === 76)),
        secondary: "Manage your configuration",
        key: "quicksight",
        image: Templates,
        activeImage: false,
        link: '/app/settings/quicksight'
      },
      { primary: "Workflow builder", secondary: "Manage your Workflows", key: "formBuilder", image: Templates, activeImage: false, link: '/app/settings/form-builder' },
      {
        primary: "Alerts",
        isHide: true,
        // isHide: !(process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'test'),
        secondary: "Manage your Alerts",
        key: "alertNotifications",
        image: Templates,
        activeImage: false,
        link: '/app/settings/alerts'
      },
      {
        primary: "API",
        isHide: !(process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'test'),
        secondary: "Manage your API Access",
        key: "APIAccess",
        image: Templates,
        activeImage: false,
        link: '/app/settings/oauth'
      },
      {
        primary: "Automations",
        isHide: isHideAutomation,
        secondary: "Manage your Automations",
        key: "Automations",
        image: Templates,
        activeImage: false,
        link: '/app/settings/automations'
      },
      {
        primary: "Departments",
        isHide: isHideDepartments,
        secondary: "Manage your Departments",
        key: "Departments",
        image: Templates,
        activeImage: false,
        link: '/app/settings/departments'
      },
      {
        primary: "Reports",
        isHide: hideReports,
        secondary: "Download Reports",
        key: "Reports",
        image: Templates,
        activeImage: false,
        link: "/app/settings/reports",
      },
    ];
    console.log(process.env.REACT_APP_ENV, this.props.authUser?.user?.Organization?.ID, hideReports)
    // console.log(process.env.REACT_APP_ENV !== 'dev' ? ((this.props.authUser?.user?.Organization?.ID === 1 || this.props.authUser?.user?.Organization?.ID === 5) ? false : true) : false, "isHide")
    // const {drawer_expanded}= this.props;
    const { sm1, sm2 } = this.state;
    const sm = sm1 ? 12 : sm2 ? 6 : 4;

    return <div className={'PageSettings'}>
      <AppBarV2 title="Settings" />
      <div className={classnames(classes.actionContainer, classes.rootContainer)}>
        <Grid container spacing={3}>
          {cardContent.map((e, index) => {
            if (e.isHide) {
              return null
            }
            return (
              <Grid item xs={12} sm={sm} md={3} lg={3} xl={3} key={index}>
                <CardAction
                  link={e.link}
                  image={e.image}
                  primary={e.primary}
                  secondary={e.secondary}
                  activeImage={e.activeImage}
                  cardActionClass={classes.cardAction}
                  primaryClass={classes.Primary}
                  secondaryClass={classes.Secondary}
                  contentClass={classes.Content}
                />
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  }
}

export default connector(compose(
  withStyles(styles)
)(SettingsCard));