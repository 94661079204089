import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { fetchDashboard, deleteDashboard, addDashboard } from "redux/visualization/action";
import { showSnackBar } from "redux/snackbar/action";
import { Button, Backdrop, CircularProgress, InputAdornment } from "@material-ui/core";
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Paper } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
import { CommonFn } from "services/commonFn";
import { Search as SearchIcon } from "@material-ui/icons";
import MessageDialog from "Components/Common/MessageDialog";
import { showMessageDialog, hideMessageDialog } from "redux/applications/action";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import Image from "Components/Common/image.jsx";
import editIcon from "assets/images/edit_icon.svg";
import deleteIcon from "assets/images/delete.svg";
import AddNameDialog from "../dialogs/AddNameDialog";
import Clear from "@material-ui/icons/Clear";
import TooltipMsg from "@material-ui/core/Tooltip";

const connectedProps = (state) => ({
  dashboards: state.visualization.dashboards,
  dashboardFetchProgress: state.visualization.dashboardFetchProgress,
  dashboardFetchError: state.visualization.dashboardFetchError,

  addDashboardProgress: state.visualization.addDashboardProgress,
  addDashboardSuccess: state.visualization.addDashboardSuccess,
  addDashboardError: state.visualization.addDashboardError,

  deleteDashboardProgress: state.visualization.deleteDashboardProgress,
  deleteDashboardError: state.visualization.deleteDashboardError,
});

const connectionActions = {
  fetchDashboard: fetchDashboard,
  deleteDashboard: deleteDashboard,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  showSnackBar: showSnackBar,
  addDashboard: addDashboard,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      "& thead": {
        "& span": {
          fontWeight: 600,
        },
      },
    },
    searchSection: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      // marginRight: 40,
      '& [class*="MuiOutlinedInput-root"]': {
        maxHeight: 40,
        minHeight: 40,
        maxWidth: 343,
      },
      "& [class*='MuiInputBase-input']": {
        fontSize: 13,
        width: 300,
      },
      "& [class*='MuiSvgIcon-root']": {
        fill: "#707070",
      },
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
      "&>div ": {
        marginBottom: 0,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "16px",
      },
    },
    thead: {
      width: "20%",
    },
    action: {
      width: "15%",
      minWidth: "80px",
    },
    userCount: {
      color: "#282D30",
      fontSize: theme.spacing(2.5),
    },
    userSection: {
      margin: theme.spacing(3, 0),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    table: {},
    tbodyCell: {
      padding: theme.spacing(1.5, 0),
      height: 50,
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    menuItem: {
      color: "#74798C",
      fontSize: theme.spacing(2),
      padding: theme.spacing(2.2),
      minWidth: 140,
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    edit: {
      width: 18,
      cursor: "pointer",
      padding: theme.spacing(0.1),
      marginRight: theme.spacing(2.5),
      fill: "#4a88ff",
    },
    delete: {
      fill: "#dc2f3e",
    },

    searchInput: {
      border: "1px solid #EEEEEE",
      borderRadius: 5,
      height: 32,
      minWidth: 300,
      paddingLeft: 28,
    },
    tableSection: {
      marginTop: theme.spacing(6.4),
      minHeight: 200,
      maxHeight: "calc(100vh - 250px)",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    searchIconPop: {
      cursor: "pointer",
      fontSize: theme.spacing(3),
      marginRight: "10px",
      "&:hover": {
        color: "#fff !important",
        fill: "#fff !important",
        background: "#707070",
        borderColor: "#707070",
        padding: "3px",
        borderRadius: theme.spacing(20),
        fontSize: theme.spacing(3),
      },
    },
    fullSceen: CommonCss.backdrop,
    actionCol: {
      textAlign: "center",
      width: "20%",
    },
  });

class DashboardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      anchorEl: null,
      isEdit: false,
      editValue: {},
      deleteId: "",
      openAddNameDialog: false,
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.deleteDashboardProgress && prevProps.deleteDashboardProgress) {
      if (this.props.deleteDashboardError) {
        this.props.showSnackBar(this.props.deleteDashboardError?.message, "error", 3000);
      } else {
        this.setState({ page: 0 }, () => {
          this.fetchData();
          this.props.showSnackBar("Dashboard successfully deleted", "success", 3000);
        });
      }
    }

    if (!this.props.addDashboardProgress && prevProps.addDashboardProgress) {
      if (this.props.addDashboardSuccess) {
        this.props.showSnackBar("Added successfully", "success", 3000);
        this.props.history.push(`/app/dashboard-v2/dashboard-builder/${this.props.addDashboardSuccess.dashboardID}`);
      } else {
        this.props.showSnackBar("Failed to add", "error", 3000);
      }
    }
  }

  fetchData = () => {
    this.props.fetchDashboard({
      query: this.state.query,
      status: "",
      forView: false,
    });
  };

  handleClear(event) {
    event.stopPropagation();
    var me = this;
    this.setState({ query: "" }, () => {
      me.fetchData();
    });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDelete(data) {
    this.setState({ deleteId: data.dashboardID }, () => {
      this.props.showMessageDialog();
    });
  }

  searchInput(classes) {
    return (
      <div className={classes.searchSection}>
        <OutlinedInput
          id={"searchDashboard"}
          placeholder={"Search Dashboard"}
          value={this.state.query}
          onChange={(event) => {
            this.setState({ query: event.target.value }, () => {
              this.fetchData();
            });
          }}
          startAdornment={
            <InputAdornment>
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment>
              {this.state.query.length > 0 && this.state.query !== "" && this.state.query !== null && (
                <TooltipMsg placement="top" title={<span style={{ fontSize: "14px", display: "inline-block", height: "25px", lineHeight: "25px" }}>Clear</span>} arrow>
                  <Clear className={classes.searchIconPop} onClick={this.handleClear} />
                </TooltipMsg>
              )}
            </InputAdornment>
          }
          roundedCorner={true}
        />
      </div>
    );
  }

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteDashboard(this.state.deleteId);
    }
  };

  handleAddName = (name) => {
    this.props.addDashboard({ name });
  };

  render() {
    const { classes, dashboards } = this.props;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <div className={classes.root}>
        <Backdrop className={classes.fullSceen} open={this.props.deleteDashboardProgress} transitionDuration={100}>
          <CircularProgress />
        </Backdrop>
        <div className={classes.userSection}>
          <p className={classes.userCount}>
            {this.props.dashboards?.data?.length} dashboard
            {this.props.dashboards?.data?.length > 1 ? "s" : ""} found
          </p>
          <div className={classes.search}>{this.searchInput(classes)}</div>
          <div>
            <Button className={classes.newUserBtn} onClick={() => this.setState({ openAddNameDialog: true })}>
              + Add
            </Button>
          </div>
        </div>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "65vh" }}>
            <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={"table_" + this.props.key}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.nameCol}>S. No.</TableCell>
                  <TableCell className={classes.statusCol}>Name</TableCell>
                  <TableCell className={classes.statusCol}>Status</TableCell>
                  <TableCell className={classes.statusCol}>Created At</TableCell>
                  <TableCell className={classes.statusCol}>Updated At</TableCell>
                  <TableCell className={classes.actionCol}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboards?.data?.map((item,key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell className={classes.statusCol}>{key+1}</TableCell>
                      <TableCell className={classes.statusCol}>{item.name}</TableCell>
                      <TableCell className={classes.statusCol}>{item.status === 2 ? "Published" : "Draft"}</TableCell>
                      <TableCell className={classes.statusCol}>{CommonFn.formateDate(item.createdAt, true)}</TableCell>
                      <TableCell className={classes.statusCol}>{CommonFn.formateDate(item.updatedAt, true)}</TableCell>
                      <TableCell className={classes.actionCol}>
                        <IconButton onClick={() => this.props.history.push(`/app/dashboard-v2/dashboard-builder/${item.dashboardID}`)}>
                          <Image src={editIcon} />
                        </IconButton>
                        {item.status === 1 && (
                          <IconButton onClick={() => this.handleDelete(item)}>
                            <Image src={deleteIcon} />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}

                {!this.props.dashboardFetchProgress && dashboards?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }} className={classes.nameCol}>
                      No Dashboard
                    </TableCell>
                  </TableRow>
                )}

                {this.props.dashboardFetchProgress && (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }} className={classes.nameCol}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {this.state.openAddNameDialog && <AddNameDialog loading={this.props.addDashboardProgress} onClose={() => this.setState({ openAddNameDialog: false })} onSubmit={this.handleAddName} />}
        <MessageDialog messageData={messageData} callBack={this.callBack} />
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(DashboardList));
