export const visibilityType = [
  { label: 'None', value: 0 },
  { label: 'Admin', value: 1 },
  { label: 'End User', value: 2 },
  { label: 'Both', value: 3 }
]

// items types
export const itemTypes = [
  { label: 'Simple Text', value: 1 },
  { label: 'Date', value: 2 },
  { label: 'Binary', value: 3 },
  { label: 'Single Select', value: 4 },
  { label: 'Multi Select', value: 5 },
  // { label: 'Table', value: 6 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8},
  { label: 'File Upload', value: 9 },
  { label: 'Products', value: 10},
  { label: 'Rich Text', value: 11 },
  { label: 'Email', value: 12 },
  { label: 'Phone', value: 13 },
  { label: 'URL', value: 14 },
  { label: 'Number', value: 15 },
  { label: 'Currency', value: 16 },
  { label: 'Existing Products', value: 17 },
  { label: 'Score', value: 18 },
  { label: 'Radio', value: 19 },
  { label: 'Checkbox', value: 20 },
  { label: 'Integrations', value: 21 },
  { label: 'Show Message', value: 22 },
]

export const itemTypesProd = [
  { label: 'Simple Text', value: 1 },
  { label: 'Date', value: 2 },
  { label: 'Binary', value: 3 },
  { label: 'Single Select', value: 4 },
  { label: 'Multi Select', value: 5 },
  // { label: 'Table', value: 6 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8},
  { label: 'File Upload', value: 9 },
  { label: 'Products', value: 10},
  { label: 'Rich Text', value: 11 },
  { label: 'Email', value: 12 },
  { label: 'Phone', value: 13 },
  { label: 'URL', value: 14 },
  { label: 'Number', value: 15 },
  { label: 'Currency', value: 16 },
  { label: 'Existing Products', value: 17 },
  { label: 'Score', value: 18 },
  { label: 'Radio', value: 19 },
  { label: 'Checkbox', value: 20 },
  // { label: 'Integrations', value: 21 },
  { label: 'Show Message', value: 22 },
]

export const binaryOptions = [
  {
    label: 'Yes',
    value: 'yes'
  },
  {
    label: 'No',
    value: 'no'
  }
]

export const webHookOptions = [
  {
    label: 'Workflow Run Finish',
    value: 'workflow.run.finish'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

export const webHookFormsTypeOptions = [
  {
    label: 'Master',
    value: 'Master'
  },
  {
    label: 'Org',
    value: 'Org'
  }
]


export var customSections = [
  {
    "ID": 21,
    "SectionName": "Sunil Test ",
    "Description": "Demo",
    "Seq": 0,
    "IsDeletable": true,
    "IsEditable": true,
    "DeletedAt": null,
    "sectionItems": [
      {
        "ID": 16,
        "ItemName": "Simple Text",
        "Type": 1,
        "Description": "affsafa",
        "AdditionalData": null,
        "IsRequired": false,
        "IsMultiselect": false,
        "Seq": 0
      },
      {
        "ID": 16,
        "ItemName": "Rich Text",
        "Type": 11,
        "Description": "affsafa",
        "AdditionalData": null,
        "IsRequired": false,
        "IsMultiselect": false,
        "Seq": 0
      },
      {
        "ID": 16,
        "ItemName": "Date",
        "Type": 2,
        "Description": "affsafa",
        "AdditionalData": null,
        "IsRequired": false,
        "IsMultiselect": false,
        "Seq": 0
      },
      {
        "ID": 16,
        "ItemName": "Email",
        "Type": 12,
        "Description": "affsafa",
        "AdditionalData": null,
        "IsRequired": false,
        "IsMultiselect": false,
        "Seq": 0
      },
      {
        "ID": 16,
        "ItemName": "Phone",
        "Type": 13,
        "Description": "affsafa",
        "AdditionalData": null,
        "IsRequired": false,
        "IsMultiselect": false,
        "Seq": 0
      },
      {
        "ID": 16,
        "ItemName": "URL",
        "Type": 14,
        "Description": "affsafa",
        "AdditionalData": null,
        "IsRequired": false,
        "IsMultiselect": false,
        "Seq": 0
      },
      {
        "ID": 16,
        "ItemName": "Binary",
        "Type": 3,
        "Description": null,
        "AdditionalData": [
          {
            "label": "Yes",
            "value": "yes"
          },
          {
            "label": "No",
            "value": "no"
          }
        ],
        "IsRequired": false,
        "IsMultiselect": false,
        "Seq": 0
      },
      {
        "ID": 16,
        "ItemName": "Single Select",
        "Type": 4,
        "Description": null,
        "AdditionalData": [
          {
            "label": "1",
            "value": 1636722697491,
            "id": 1636722697491
          },
          {
            "label": "2",
            "value": 1636722699667,
            "id": 1636722699667
          }
        ],
        "IsRequired": false,
        "IsMultiselect": false,
        "Seq": 0
      },
      {
        "ID": 16,
        "ItemName": "Multi Select",
        "Type": 5,
        "Description": null,
        "AdditionalData": [
          {
            "label": "1",
            "value": 1636722713906,
            "id": 1636722713906
          },
          {
            "label": "2",
            "value": 1636722716354,
            "id": 1636722716354
          },
          {
            "label": "3",
            "value": 1636722717890,
            "id": 1636722717890
          }
        ],
        "IsRequired": false,
        "IsMultiselect": true,
        "Seq": 0
      }
    ]
  }
]

export var tabSection = {
  "ID": 21,
  "TabUID": "prod_details",
  "OrgID": 0,
  "TabName": "Product Details",
  "Visibility": 3,
  "Seq": 0,
  "IsDeletable": false,
  "IsEditable": true,
  "DeletedAt": null,
  "CreatedAt": "2021-10-21T13:50:37Z",
  "UpdatedAt": "2021-10-22T05:34:38Z",
  "OrgProductTabSections": [
    {
      "ID": 21,
      "TabID": 21,
      "SectionUID": "prod_about",
      "OrgID": 0,
      "Visibility": 3,
      "SectionName": "About",
      "Description": "Stark Industries is a multinational industrial company and the largest tech conglomerate in the world.",
      "Seq": 0,
      "IsDeletable": false,
      "IsEditable": true,
      "DeletedAt": null,
      "CreatedAt": "2021-10-21T13:50:37Z",
      "UpdatedAt": "2021-10-21T13:50:37Z",
      "OrgProductTabItems": [
        {
          "ID": 16,
          "SectionID": 21,
          "ItemUID": "prod_about_item",
          "OrgID": 0,
          "ItemName": "About",
          "Type": 1,
          "Description": "",
          "AdditionalData": null,
          "IsRequired": false,
          "IsMultiselect": false,
          "Seq": 0,
          "Visibility": 3,
          "DeletedAt": null,
          "CreatedAt": "2021-10-21T13:50:37Z",
          "UpdatedAt": "2021-10-21T13:50:37Z"
        }
      ]
    },
  ]
}