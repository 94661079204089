import React from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core';
import { compose } from "recompose";
import { Dialog, DialogContent, Slide, Typography, Grid, InputAdornment } from '@material-ui/core';

import { hideEvaluationProductEditDialog, updateProductFromCart } from "../../../../../../../redux/evaluation/cart/action";
import OutlinedInput from "../../../../../../Common/Input/OutlinedInput";
import Alert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router-dom';
import SqureButton from '../../../../../../Common/SqureButton';
import { showSnackBar } from "../../../../../../../redux/snackbar/action";
import * as Validator from "../../../../../../../util/Validation";
import CalendarImage from "assets/images/calendar.svg"
import Image from 'Components/Common/image.jsx';
import { showEvaluationAsksellerSelectDialog } from "redux/templates/evaluation/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.evaluationCart.showProductUpdateDialog,
    targetContainer: state.evaluationCart.targetContainer,
    forSeller: state.evaluationCart.forSeller,
    evaluation: state.evaluationCart.evaluation,
    product: state.evaluationCart.productToUpdate,
    evaluationId: state.evaluationCart.evaluationId,
    saveProgress: state.evaluationCart.updateProductInProgress,
});

const connectionActions = {
    hideDialog: hideEvaluationProductEditDialog,
    showSnackBar: showSnackBar,
    updateProductFromCart: updateProductFromCart,
    showEvaluationAsksellerSelectDialog: showEvaluationAsksellerSelectDialog
}


var connector = connect(connectedProps, connectionActions);

const useStyles = makeStyles(theme => ({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important",
    
    },
    datepicker:{
        '& .react-datepicker-wrapper': {
            width: '100%'
          }
    },
    alerts: {
        marginTop: theme.spacing(4)
    },
    productName: {
        fontSize: theme.spacing(2.5),
        // marginBottom:theme.spacing(4),
        textAlign: "center",
        fontWeight: 500
    },
    productLogo: {
        height: '30px',
        width: '80px',
        objectFit: 'contain',
        marginRight: '10px'
    },
    actions: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: "left",
        '& button:nth-child(1)': {
            marginRight: '15px'
        }
    },
}));


class VendorEmailDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            name: this.props.product.VendorName || '',
            name_error: '',
            email: this.props.product.VendorEmail || '',
            email_error: '',
            deadline: this.props.product && this.props.product.Deadline && this.props.product.Deadline !== '' ? new Date(this.props.product.Deadline) : '',
            deadline_error: '',
        }
        this.submitResponse = this.submitResponse.bind(this);
    }


    componentDidUpdate(prevProps) {
        if (this.props.isOpen === true && prevProps.isOpen === false) {
            this.setState({
                error: "",
                success: "",
                name: this.props.product.VendorName,
                name_error: '',
                'email': this.props.product.VendorEmail,
                'email_error': '',
            })
        }

        if (this.props.isOpen && this.props.saveProgress === false && prevProps.saveProgress === true) {
            //Migrate to different page
            this.props.hideDialog();
            this.props.showSnackBar("Seller detail updated", "success", 3000);
            this.checkNext()
        }
    }

    checkNext() {
        if (this.props.forSeller === true) {
            var product = this.props.evaluation?.Products.filter(o => o.ID === this.props.product.ID)[0];
            this.props.showEvaluationAsksellerSelectDialog(this.props.evaluationId, product)
        }
    }

    clearError(){
        this.setState({
            'error': "",
            "success": "",
            "email_error": "",
            "name_error": "",
            deadline_error:''
        });
    }
    submitResponse() {
        this.clearError()
        var me = this;
        var name = me.state.name;
        var email = me.state.email;
        var deadline = me.state.deadline;

        //validation
        if (name === '' && String(name).trim().length === 0) {
            me.setState({ name_error: "Please provide your name." });
            return;
        }

        if (email === '' && String(email).trim().length === 0) {
            me.setState({ email_error: "Please provide your email." });
            return;
        }

        if (Validator.validateEmail(email) === false) {
            me.setState({ email_error: "Please provide valid email." });
            return;
        }

        if (deadline === null || deadline === '' || String(deadline).trim().length === 0) {
            me.setState({ deadline_error: "Please provide deadline date." });
            return;
        }

        this.props.updateProductFromCart(this.props.evaluationId, this.props.product.EntityObjectID, {
            contact_name: name || '',
            contact_email: email,
            deadline: deadline.toISOString(),
        })
    }

    setInputValue = (field, value) => {
        this.clearError()
        this.setState({
            [field]: value
        })
    }

    render() {
        return <>
            {this.props.isOpen && !this.props.targetContainer &&
                <Dialog
                    onClose={this.props.hideDialog}
                    aria-labelledby="app-integrationDialog"
                    open={this.props.isOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={false}
                    fullWidth={true}
                    maxWidth={"xs"}
                    scroll="body"
                    id="evMetaDialog"
                >
                    <DialogContent>
                        <VendorEmailForm
                            product={this.props.product}
                            stateValues={this.state}
                            setInputValue={this.setInputValue}
                            submitResponse={this.submitResponse}
                            saveProgress={this.props.saveProgress}
                            hideDialog={() => { this.props.hideDialog() }} />
                    </DialogContent>
                </Dialog>
            }
            {this.props.isOpen && this.props.targetContainer &&
                <VendorEmailForm
                    product={this.props.product}
                    stateValues={this.state}
                    setInputValue={this.setInputValue}
                    submitResponse={this.submitResponse}
                    saveProgress={this.props.saveProgress}
                    hideDialog={() => { this.props.hideDialog() }} />
            }
        </>
    }
}

export default connector(compose(
    withRouter,
)(VendorEmailDialog));


function VendorEmailForm(props) {
    const classes = useStyles();
    return (
        <>
            <div style={{ display: 'flex' }}>
                <Image className={classes.productLogo} alt="product" src={props.product?.ProductLogo} />
                {props.product !== null && <Typography className={classes.productName}>{props.product.Name}</Typography>}
            </div>
            <div className={classes.alerts}>
                {props.stateValues.error.length > 0 && <Alert variant="filled" severity="error">{props.stateValues.error}</Alert>}
                {props.stateValues.success.length > 0 && <Alert variant="filled" severity="success">{props.stateValues.success}</Alert>}
            </div>
            <Grid container>
                <Grid item lg={12}>
                    <div>
                        <OutlinedInput
                            label="Contact Name"
                            error={props.stateValues.name_error.length > 0}
                            helperText={props.stateValues.name_error}
                            value={props.stateValues.name}
                            onChange={(event) => {
                                props.setInputValue('name', event.target.value);
                            }}
                        />
                    </div>
                </Grid>
                <Grid item lg={12}>
                    <div>
                        <OutlinedInput
                            label="Contact Email"
                            error={props.stateValues.email_error.length > 0}
                            helperText={props.stateValues.email_error}
                            value={props.stateValues.email}
                            onChange={(event) => {
                                props.setInputValue('email', event.target.value);
                            }}
                        />
                    </div>
                </Grid>
                <Grid item lg={12}>
                    <div className={classes.datepicker}>
                        <DatePicker
                            className={classes.datePicker}
                            selected={props.stateValues.deadline}
                            onChange={date => {
                                props.setInputValue('deadline', date);
                            }}
                            portalId="evMetaDialog"
                            dateFormat="MMM do yyyy"
                            minDate={new Date()}
                            popperClassName={classes.date_popper}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={
                                <OutlinedInput
                                    label="Deadline"
                                    readOnlyInput={true}
                                    placeholder="Deadline"
                                    error={props.stateValues.deadline_error.length > 0}
                                    helperText={props.stateValues.deadline_error}
                                    startAdornment={
                                        <InputAdornment>
                                            <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                        </InputAdornment>
                                    }
                                />
                            }
                        />
                    </div>
                </Grid>
            </Grid>
            <div className={classes.actions}>
                <SqureButton variant={"outlined"} onClick={() => { props.hideDialog() }}>Cancel</SqureButton>
                {props.saveProgress ? <SqureButton variant={"contained"} >Loading...</SqureButton> : <SqureButton variant={"contained"}
                    onClick={() => { props.submitResponse() }}>Save</SqureButton>}&nbsp;&nbsp;&nbsp;
            </div>
        </>
    )
}