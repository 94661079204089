import Checked from "./assets/images/checked.svg";
import Dropdown from "./assets/images/down-filled-arrow.svg";

const CommonCss = {
  rootContainer:{
    padding: '2em 5em',
    marginTop:'0px !important'
  },
  icon: {
    borderRadius: 5,
    width: 20,
    height: 20,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },

  checkedIcon: {
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: `url(${Checked})`,
      content: '""',
    },
    "input:hover ~ &": {
      opacity: 0.9,
    },
  },
  backdrop: {
    zIndex: 1,
    color: "#fff",
    background: "rgb(247 245 245 / 50%)",
  },
  formControl: {
    display: "block",
    width: "100%",
    padding: ".375rem .75rem",
    borderRadius: ".25rem",
    border: "1px solid #AAAAAA",
    minHeight: 44,
    borderRadius: 6,
    fontFamily: 'Poppins'
  },

  selectField: {
    background: `url(${Dropdown})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 12px)",
    appearance: "none",
  },

  label: {
    display: "inline-block",
    marginBottom: ".5rem",
    color: "#6C6C6C",
    fontSize: 15,
  },

  formGroup: {
    marginBottom: "1rem",
  },
  mandatory: {
    color: "#ff1200",
  },
  primary: {
    textAlign: "center",
    fontSize: "1.2em",
    marginTop: ".5em",
    marginBottom: ".25em",
  },

  textAlignCenter: {
    textAlign: "center",
  },

  content: {
    padding: "1em 1em 0 1em",
  },

  viewAll: {
    color: "#4A87F8 !important",
    fontSize: 16,
    paddingRight: 10,
  },
  viewAllbutton:{
    color: '#4A87F8 !important',
    paddingRight: '10px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow:' 4px 0px 4px #2b2d3703',
    border: '1px solid #4A87F8',
    fontSize: '13px',
    padding: '6px 8px',
    borderRadius: '5px'
  },
  productHead: {
    color: "#282D30",
    fontSize: 16,
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 30,
  },
  productTitleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filterSection: {
    border: "1px solid #3C3C3C",
    borderRadius: 5,
    minHeight: 32,
    minWidth: 120,
    color: "#20253A",
    fontSize: 15,
    padding: 0,
  },
  cardAction: {
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    marginBottom: 0,

    '& [class*="MuiSvgIcon-root"]': {
      fill: "#74798C",
    },
  },
  cardSection: {
    "& [class*='MuiGrid-item']": {
      padding: 14,
    },
  },
  nameYour: {
    "& [class*=MuiInputBase-root]": {
        maxHeight: 40,
        minHeight: 40,
        "& input": {
            fontSize: 14
        }
    },
  },
  yourQuestion: {
    "& [class*=MuiInputBase-root]": {
        padding: 0,
        fontSize: 14,
        minHeight: 120,
        color: "#707070",
        lineHeight: 1.2,
        "& textarea": {
            minHeight: 100,
        }
    }
  },
  Home: {
    cartSection: {
      "& [class*=Cart-root], #cartSection": {
        transition: "all 400ms linear",
        padding: 21,
        right: -300,
        "& *": {
          opacity: 0,
          transition: "all 600ms ease-in",
          // whiteSpace: 'nowrap'
        },
      },
      "&.showCart": {
        "& [class*=Cart-root], #cartSection": {
          right: "0 !important",
          "& *": {
            opacity: 1,
          },
        },
      },
    },
    newEvaluation: {
      "& .product_container": {
        paddingBottom: 0, 
        "& > div": {
          display: 'flex',
          alignItems: 'center',
        },
      },
      "& .layer-card-title": {
        padding: 0,
      },
      "& .layer-card-subtitle": {
        position: 'absolute',
        left: 99,
        top: 63
      }
    },
    bottomHead: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 32
    },
    evaluateCard: {
      width: 'calc(100% - 10px)',
      background: '#fff',
      padding: "16px 24px",
      margin: '20px 0px 0px 0px',
      boxShadow: '0px 3px 25px #55555500',
      borderRadius: 5,
      display: 'flex',
      cursor: 'pointer'
    },
    evaluateCardTitle: {
      color: '#20253A',
      fontSize: 14,
      margin:0,
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    evaluateCardSubTitle: {
      fontSize: 12,
      color: '#A0A5BA',
      marginTop:"5px",
      marginBottom:0
    },
    evaluateLogo: {
      flex: .8,
      display: 'flex',
      justifyContent: 'flex-end'
    },
    evaluateText: {
      flex: 1.5
    },
    evaluateTextSurvey: {
      flex: 0.7
    },
    loader: {
      marginTop: 20,
      marginLeft: 20,
      display: 'block'
    }, 
    noMoreData: {
      marginTop: 30,
      display: 'block'
    },
    productLogoContainer:{
      flexDirection: 'row',
      boxSizing: 'border-box',
      display: 'flex',
      placeContent: 'center flex-start',
      alignItems: 'center',
      padding: '0 15px'
    },
    productLogo: {
      float: 'left',
      width: '35px',
      height: '35px',
      border: '1px solid #EEEEEE',
      borderRadius: '4px',
      marginRight: '10px',
      textAlign: 'center',
      lineHeight: '33px',
      fontWeight: 600,
      fontSize: 11,
      color: '#A0A5BA',
      '& img':{
        width: '100%',
        height: '100%',
        objectFit: 'scale-down'
      }
    },
    rightArrow: {
      width: 17
    },
    DashboardNoDataLayout:{
      margin: '22px 0px 0px 0px',
      minHeight: 450,
      textAlign: 'center',
      // background: '#ffffff',
      padding: '23px',
      borderRadius: '6px',
      '& button':{
          margin: '20px 0',
          color: '#fff',
          background: '#3C3C3C',
          '&:hover':{
              color: '#3C3C3C',
              background: '#fff',
          }
      }
    }
   },
   ProductDetails:{
      productDetails: {
          display: 'flex',
          marginTop: 81
      },
      productName: {
          color: '#20253A',
          fontSize: 35,
          fontWeight: 500,
          margin: 0,
          minHeight: 50
          // marginBottom: 10
      },
      list: {
          listStyle: 'none',
          marginLeft: 0,
          paddingLeft: 0,
        
          fontSize: 13,
          marginTop: 30,
          padding: '0px 30px 0px 0px'
      },
      item: {
          padding: '13px 4px',
          borderBottom: '0.5px solid #d8d8d8',
          '& a': {
              color: '#696B6E !important',
          },
          '& a.active': {
              color: '#0093E0 !important'
          }
      },
      productLeftSection: {
          width: 200,
          minWidth: 200
      },
      productLink: {
          display: 'inline-block',
          color: '#0093E0',
          fontSize: 18,
          textDecoration: 'underline',
          marginTop: 0,
          marginBottom: 25
      },
      description: {
          color: '#82858F',
          fontSize: 16,
          lineHeight: 1.5,
          marginBottom: 30
      },
      head: {
          color: '#20253A',
          fontSize: 24,
          fontWeight: 500,
          marginBottom: 25,
          marginTop: 0
      },
      contentSection: {
          // height: 'calc(100vh - 260px)',
          overflow: 'overlay',
          padding: '0px 20px 10px 0px',
          scrollBehavior:'smooth'
      },
      filterSection: {
          border: "1px solid #3C3C3C",
          color: "#20253A",
          fontSize: 15,
          marginLeft: 30,
          padding: '4px 20px'
      },
      btnDeleteProduct:{
        border: "1px solid #3C3C3C",
        color: "#20253A",
        fontSize: 15,
        padding: '4px 20px',
        marginTop: 30
      },
      ProductNameSection: {
          flexDirection: 'row',
          boxSizing: 'border-box',
          display: 'flex',
          placeContent: 'center flex-start',
          alignItems: 'center',
          marginBottom: '10px'
      },
      UL: {
          margin: 0,
          padding: 0,
          '& li':{
              display: 'inline-box',
              marginBottom: '18px'
              // lineHeight: '60px',
              // marginRight: '14px'
          }
      },
      tag: {
          color: '#fff',
          cursor: 'default',
          background: '#4A87F8',
          padding: '10px',
          borderRadius: '25px',
          fontSize: '12px',
          padding: '10px 14px'
      },
      platformItem:{
          flexDirection: 'column',
          boxSizing: 'border-box',
          display: 'flex',
          placeContent: 'center space-around',
          alignItems: 'center',
          padding: 10,
          position:"relative",
          '& div':{
            marginRight: 25,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center'
          },
          '& img':{
              width: 'auto',
              height: '70px',
              maxWidth: '50px',
              objectFit: 'contain',
              margin: '0px auto'
          }
      },
      itemSection: {
          marginBottom: 15
      },
      platforms: {
          flexDirection: 'row',
          boxSizing: 'border-box',
          display: 'flex',
          placeContent: 'flex-start',
          alignItems: 'flex-start'
      },
      platform: {
          marginRight:100,
          float: 'right'
      },
      platformTitle: {
          color: '#20253A',
          fontWeight: 500
      },
      platformName: {
          color: '#20253A',
          fontWeight: 400,
          fontSize: 13
      },
      inverse: {
          border: '1px solid #4A87F8',
          background: '#fff',
          color: '#4A87F8'
      },
      view_more: {
          border: '1px solid #FFF',
          background: '#4A87F8',
          color: '#FFF',
          cursor: 'pointer'
      },
      show_more: {
          border: '1px solid #FFF',
          background: '#4A87F8',
          color: '#FFF',
          cursor: 'pointer',
          padding: '10px 14px',
          borderRadius: '20px',
          fontSize: 12
      },
      productRightSection: {
          paddingLeft: 40,
          width: '100%'
      },
      "@global": {
          '.applicationContent': {
              background: '#fff !important'
          }
      },
      cartSection: {
          "& [class*=Cart-root], #cartSection": {
            transition: "all 400ms linear",
            padding: 21,
            right: -300,
            "& *": {
              opacity: 0,
              transition: "all 600ms ease-in",
              // whiteSpace: 'nowrap'
            },
          },
          "&.showCart": {
            "& [class*=Cart-root], #cartSection": {
              right: "0 !important",
              "& *": {
                opacity: 1,
              },
            },
          },
        },
      }
};

export default CommonCss;
