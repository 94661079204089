import React from "react";
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';

const InputContainer = {
  flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'center flex-start',
    alignItems: 'center',
    width: '100%'
}

const InputRow = {
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #c4c4c4',
  borderRadius: '4px',
  padding: '4px 10px',
  width: '100%',
}

const count = {
  display: 'inline-block',
  width: '20px',
  textAlign: 'center',
  color: '#6D7278',
  fontSize: '13px'
}

const input = {
  border: '0px',
  height: '30px',
  width: 'calc(100% - 20px)',
  outline: 0,
  color: '#6D7278',
  fontSize: '13px'
}

const Answer = ({ answertype, type, index, isShowCount=true, placeholder="",answerNumber, answer, handleChange, editable }) => {
  if(answer === undefined || answer === 'undefined'){
    answer = ''
  }
  return (
    <div
      id={answerNumber}
      style={{...InputContainer, maxWidth: answertype === 7 ? '123px' :  'auto'}}>
      {isShowCount && <DragIndicatorOutlinedIcon style={{color: '#D9D9D9'}} />}
      <div  style={{...InputRow, padding:  answertype === 7 ? '0px 10px' : '4px 10px' }} class="answer-input">
        {isShowCount && <span style={count}>{index + 1}.</span>}
        <input 
          disabled={!editable}
          placeholder={placeholder}
          style={input} 
          type={type || 'text'} 
          value={answer || ''} 
          onChange={(e)=>{handleChange(e,answerNumber)}} 
        />
      </div>
    </div>
  );
};

export default Answer;
