import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import * as Validator from "util/Validation";
import {
  CircularProgress,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button
} from "@material-ui/core";

import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

// shared
import { CommonFn } from "services/commonFn";

// components
// import NewEvaluationDialog from "Components/Application/Components/Evaluate/Components/Common/NewEvaluationDialog";
import ToolTip from "Components/Common/ToolTip.jsx";

// assets
import CommonCss from "commonCss";
import Image from "Components/Common/image";
import RightArrow from "assets/images/right-arrow.svg";
import EvaluationEmptyState from "assets/images/evaluation_empty_state.svg";
import PreFeedbackAdminDialog from "../../Evaluate/Components/Evaluation/Common/PreFeedbackAdminDialog";
import { showPreFeedbackAdminDialog } from "redux/evaluation/feedback/action";

// redux
import {
  fetchNotifications,
  markAsRead,
  deleteNotification
} from "redux/notifications/action";

const connectedProps = (state) => ({
  // For Evaluation table
  notifications: state.notification.notifications,
  notificationFetchProg: state.notification.fetchProg,
  isOpen:state.evaluationFeedback.showPreFeedbackAdminDialog,
});

const connectionActions = {
  fetchNotifications: fetchNotifications,
  markAsRead: markAsRead,
  deleteNotification: deleteNotification,
  showPreFeedbackAdminDialog:showPreFeedbackAdminDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      // background:theme.palette.common.white,
      borderRadius: theme.spacing(2),
    },
    header: {
      height: "100%",
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center space-between",
      alignItems: "center",
    },
    tblContainer: {
      // background:theme.palette.common.white,
      borderRadius: theme.spacing(2),
      minHeight: 448,
    },
    tblSelecter: {
      marginRight: "16px",
      "& [class*=MuiSelect-outlined-]": {
        fontSize: 13,
        padding: "8px 12px",
        background: "#ffffff",
      },
    },
    viewAll: CommonCss.viewAll,
    viewAllbutton: {
      ...CommonCss.viewAllbutton,
      marginRight: "10px",
      float: "right",
    },
    formControl: {
      width: theme.spacing(20.5),
    },
    button: {
      padding: "6px 8px",
      fontSize: 13,
      fontWeight: 400,
      width: theme.spacing(30),
      marginRight: 15,
      // width: '100%'
    },

    rowCenterCenter: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center",
      alignItems: "center",
    },
    arrowCol: {
      padding: "8px 30px 8px 0px",
    },
    evaluateCard: {
      width: "calc(100% - 10px)",
      background: "#fff",
      padding: "16px 24px",
      margin: "20px 0px 0px 0px",
      boxShadow: "0px 3px 25px #55555500",
      borderRadius: 5,
      display: "flex",
      cursor: "pointer",
    },
    evaluateCardTitle: {
      color: "#20253A",
      fontSize: 14,
      margin: 0,
      fontWeight: 500,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    evaluateCardSubTitle: {
      fontSize: 12,
      color: "#A0A5BA",
      marginTop: "5px",
      marginBottom: 0,
    },
    evaluateLogo: {
      flex: 0.8,
      display: "flex",
      justifyContent: "flex-end",
    },
    evaluateText: {
      flex: 1.5,
    },
    productLogoContainer: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center flex-start",
      alignItems: "center",
      padding: "0 15px",
    },
    productLogo: {
      float: "left",
      width: "35px",
      height: "35px",
      border: "1px solid #EEEEEE",
      borderRadius: "4px",
      marginRight: "10px",
      textAlign: "center",
      lineHeight: "33px",
      fontWeight: 600,
      fontSize: 11,
      color: "#A0A5BA",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "scale-down",
      },
    },
    rightArrow: {
      width: 17,
    },
    bottomHead: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 32,
    },
    productHead: {
      ...CommonCss.productHead,
      fontSize: 19,
      margin: "0px !important",
    },
    actionAction: {
      textAlign: "center",
      padding: "25px 0",
      paddingRight: "10px",
    },
    DashboardNoDataLayout: {
      width: "calc(100% - 10px)",
      margin: "22px 0px 0px 0px",
      minHeight: 450,
      textAlign: "center",
      background: "#ffffff",
      padding: "23px",
      borderRadius: "6px",
      "& button": {
        margin: "20px 0",
      },
    },
    badge: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: 12,
    },
  });
class Actions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      readStatus: "UNREAD",
      page: 0,
      pageSize: 5,
      selectedEvaluationId:null,
    };
    this.myInput = React.createRef();
    this.fetchData = this.fetchData.bind(this);
    this.routeChange = this.routeChange.bind(this);
  }

  componentDidMount() {
    var me = this;
    if (this.props.match.path === "/app/notifications/:status") {
      var status = this.props.match.params.status;
      this.setState({
        pageSize: 50,
        readStatus: status
      }, () => {
        me.fetchData(this.state.readStatus);
      })
    } else {
      this.setState({
        pageSize: 5,
      }, () => {
        me.fetchData(this.state.readStatus);
      })
    }
  }

  routeChange(data){
    console.log(data)
    if(data.type === 'PRE_EVALUATION_FEEDBACK'){
      this.setState({
        selectedEvaluationId : data.id
      },()=>{
        this.props.showPreFeedbackAdminDialog();
      })
    }else{
      if (data.url) {
        this.props.history.push(data.url);
      }
    }
  }

  fetchData(readStatus) {
    this.props.fetchNotifications(this.state.page, this.state.pageSize, readStatus);
  };

  render() {
    const classes = this.props.classes;
    return (
      <>
        <div className={classes.root}>
          {!this.props.notificationFetchProg && (
            <div className={classes.header}>
              <Typography variant={"h6"} className={classes.title}>
                {this.props.title} <ToolTip toolTip={this.props.tooltip} />
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.tblSelecter}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                  >
                    <Select
                      value={this.state.readStatus}
                      onChange={(e) => {
                        this.setState({
                          readStatus: e.target.value
                        })
                        this.fetchData(e.target.value);
                      }}
                    >
                      <MenuItem value={"UNREAD"} style={{ fontSize: 13 }} >
                        Unread
                      </MenuItem>

                      <MenuItem value={"READ"} style={{ fontSize: 13 }}>
                        Read
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <Link
                    to={
                      `/app/notifications/${this.state.readStatus}`

                    }
                    className={classes.viewAllbutton}
                  >
                    View all
                  </Link>
                </div>
              </div>
            </div>
          )}
          {!this.props.notificationFetchProg &&
            this.props.notifications.length > 0 && (
              <>
                <div className={classes.tblContainer}>
                  <div>
                    {!this.props.notificationFetchProg &&
                      this.props.notifications.map((notification, i) => {
                        const jsonParsedData = Validator.isJson(notification.Data) ? JSON.parse(notification.Data) : {};
                        const item = jsonParsedData['evalution']
                        var type = 'Evaluation';
                        if(notification.Type === 'PRE_EVALUATION_FEEDBACK'){
                          type = 'PRE_EVALUATION_FEEDBACK';
                        }

                        if (item === undefined) {
                          return null;
                        }

                        return (
                          <div
                            className={classes.evaluateCard}
                            key={i}
                          >
                            <div className={classes.evaluateText}>
                              <div onClick={() => this.routeChange({'id' : item.ID, 'type': type, 'url' : notification?.Url})}>
                                <p className={classes.evaluateCardTitle}>
                                  {type === 'Evaluation' ? item.Name : 'Pre Evaluation Feedback'}
                                </p>
                                <p className={classes.evaluateCardSubTitle}>
                                  {notification.Message}
                                </p>
                                <p className={classes.evaluateCardSubTitle}>
                                  Notified At:{" "}
                                  {CommonFn.formateDateTime(notification.CreatedAt)}
                                </p>
                              </div>
                              <div>
                                <Button color="primary" style={{ paddingLeft: 0 }} onClick={(e) => {
                                  e.preventDefault()
                                  this.props.markAsRead(notification.ID)
                                }}>Mark as Read</Button>
                                &nbsp;&nbsp;
                                <Button color="secondary" style={{ paddingLeft: 0 }} onClick={(e) => {
                                  e.preventDefault()
                                  this.props.deleteNotification(notification.ID)
                                }}>Delete</Button>
                              </div>
                            </div>
                            <div className={classes.evaluateLogo} onClick={() => this.routeChange({'id' : item.ID, 'type': type, 'url' : notification?.Url})}>
                              <div className={classes.productLogoContainer}>
                                {item?.Products?.slice(
                                  0,
                                  item?.Products.length < 5
                                    ? item.Products.length
                                    : 3
                                ).map((item, i) => {
                                  return (
                                    <div
                                      className={classes.productLogo}
                                      title={item?.Name}
                                    >
                                      <Image
                                        src={item?.ProductLogo}
                                        alt={item?.Name}
                                        title={item?.Name}
                                      />
                                    </div>
                                  );
                                })}
                                {item?.Products.length > 4 && (
                                  <div
                                    className={classes.productLogo}
                                    title={
                                      item?.Products.length - 3 + "more product"
                                    }
                                  >
                                    {" "}
                                    + {item?.Products.length - 3}
                                  </div>
                                )}
                              </div>
                              <Image
                                src={RightArrow}
                                className={classes.rightArrow}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </>
            )}
          {this.props.notificationFetchProg && (
            <div className={classes.tblRow}>
              <span
                className={classes.tblCol}
                style={{ margin: "0px auto", width: "100%" }}
              >
                <span
                  style={{
                    display: "block",
                    padding: "95px",
                    textAlign: "center",
                  }}
                >
                  <CircularProgress />
                </span>
              </span>
            </div>
          )}
          {!this.props.notificationFetchProg &&
            this.props.notifications.length === 0 && (
              <div className={classes.DashboardNoDataLayout}>
                <Image src={EvaluationEmptyState} />
                <h2>No Action Item found</h2>
              </div>
            )}
        </div>
        {this.props.isOpen && <PreFeedbackAdminDialog evaluationId={this.state.selectedEvaluationId} />}
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Actions));
