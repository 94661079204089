import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment";
import { showSnackBar } from "redux/snackbar/action"

import { Types } from "./types"

export const SHOW_ORG_USER_UPDATE_DIALOG = "SHOW_ORG_USER_UPDATE_DIALOG";
export const HIDE_ORG_USER_UPDATE_DIALOG = "HIDE_ORG_USER_UPDATE_DIALOG";

export const START_ORG_USER_GET = "START_ORG_USER_GET";
export const END_ORG_USER_GET = "END_ORG_USER_GET";

export const START_ORG_USER_UPDATE = "START_ORG_USER_UPDATE";
export const END_ORG_USER_UPDATE = "END_ORG_USER_UPDATE";

//Action to show/hide user update dialog
export function showOrgUserUpdateDialog(userId) {
    return {
        type: SHOW_ORG_USER_UPDATE_DIALOG,
        payload: { userId }
    };
}

export function hideOrgUserUpdateDialog() {
    return {
        type: HIDE_ORG_USER_UPDATE_DIALOG,
        payload: null
    };
}

function startGetOrgUser() {
    return {
        type: START_ORG_USER_GET,
        payload: null
    };
}

function endGetOrgUser(success, error) {
    return {
        type: END_ORG_USER_GET,
        payload: {
            success, error
        }
    };
}

export function getOrgUser(user_id) {
    return async (dispatch) => {
        dispatch(startGetOrgUser());
        try {
            const api_server = Environment.api_host("USER");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/org-user/${user_id}?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endGetOrgUser(response, error));
        } catch (e) {
            dispatch(endGetOrgUser(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateOrgUser() {
    return {
        type: START_ORG_USER_UPDATE,
        payload: null
    };
}

function endUpdateOrgUser(success, error) {
    return {
        type: END_ORG_USER_UPDATE,
        payload: {
            success, error
        }
    };
}

export function updateOrgUser(user_id, data) {
    return async (dispatch) => {
        dispatch(startUpdateOrgUser());
        try {
            const api_server = Environment.api_host("USER");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/org-user/${user_id}?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            if (error !== null) {
                dispatch(endUpdateOrgUser(response, error));
                dispatch(showSnackBar("Failed to update user.", "error"))
            } else {
                let [responseGet, errorUser] = await HttpFactory.instance().getMethod(url);
                dispatch(endUpdateOrgUser(responseGet, errorUser));
                dispatch(showSnackBar("User updated successfully.", "success"))
            }
        } catch (e) {
            dispatch(endUpdateOrgUser(null, {
                message: e.message
            }));
            dispatch(showSnackBar("Failed to update user.", "error"))
            return;
        }
    };
}


/**
 * Add Org Settings
 */
function startAddOrgSettings() {
    return {
        type: Types.START_ADD_ORG_SETTINGS,
        payload: null
    };
}

function endAddOrgSettings(success, error) {
    return {
        type: Types.END_ADD_ORG_SETTINGS,
        payload: {
            success, error
        }
    };
}

export function addOrgSettings(settings = []) {
    return async (dispatch) => {
        dispatch(startAddOrgSettings());
        try {
            const api_server = Environment.api_host("USER");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/org-settings?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().postMethod(url, {
                org_settings: settings
            });
            dispatch(endAddOrgSettings(response, error));
        } catch (e) {
            dispatch(endAddOrgSettings(null, {
                message: e.message
            }));
            return;
        }
    };
}


/**
 * Get all Org Settings
 */
function startFetchAllOrgSettings() {
    return {
        type: Types.START_FETCH_ALL_ORG_SETTINGS,
        payload: null
    };
}

function endFetchAllOrgSettings(success, error) {
    return {
        type: Types.END_FETCH_ALL_ORG_SETTINGS,
        payload: {
            success, error
        }
    };
}

export function fetchAllOrgSettings() {
    return async (dispatch) => {
        dispatch(startFetchAllOrgSettings());
        try {
            const api_server = Environment.api_host("USER");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/all-org-settings?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAllOrgSettings(response, error));
        } catch (e) {
            dispatch(endFetchAllOrgSettings(null, {
                message: e.message
            }));
            return;
        }
    };
} 