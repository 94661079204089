import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";

export const START_PRODUCT_FETCH = "START_PRODUCT_FETCH";
export const END_PRODUCT_FETCH = "END_PRODUCT_FETCH";

export const START_PRODUCT_TAB_SECTION_FETCH = "START_PRODUCT_TAB_SECTION_FETCH";
export const END_PRODUCT_TAB_SECTION_FETCH = "END_PRODUCT_TAB_SECTION_FETCH";

export const START_PRODUCT_EXT_COLLABORATOR_ADD = "START_PRODUCT_EXT_COLLABORATOR_ADD";
export const END_PRODUCT_EXT_COLLABORATOR_ADD = "END_PRODUCT_EXT_COLLABORATOR_ADD";

export const START_PRODUCT_EXT_COLLABORATOR_REMOVE = "START_PRODUCT_EXT_COLLABORATOR_REMOVE";
export const END_PRODUCT_EXT_COLLABORATOR_REMOVE = "END_PRODUCT_EXT_COLLABORATOR_REMOVE";

export const START_PRODUCT_EXT_COLLABORATOR_FETCH = "START_PRODUCT_EXT_COLLABORATOR_FETCH";
export const END_PRODUCT_EXT_COLLABORATOR_FETCH = "END_PRODUCT_EXT_COLLABORATOR_FETCH";


//-------------------------------------
function startExternalCollaboratorProductTabFetch(){
    return {
        type:START_PRODUCT_TAB_SECTION_FETCH,
        payload:{}
    };
}

function endExternalCollaboratorProductTabFetch(success,error){
    return {
        type:END_PRODUCT_TAB_SECTION_FETCH,
        payload:{
            success,error
        }
    };
}


export  function fetchExternalCollaboratorProductTabs(productId){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${productId}/ext/user/tabs?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startExternalCollaboratorProductTabFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endExternalCollaboratorProductTabFetch(response,error));
        } catch(e){
            dispatch(endExternalCollaboratorProductTabFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 


//-------------------------------------
function startExternalCollaboratorProductFetch(){
    return {
        type:START_PRODUCT_FETCH,
        payload:{}
    };
}

function endExternalCollaboratorProductFetch(success,error){
    return {
        type:END_PRODUCT_FETCH,
        payload:{
            success,error
        }
    };
}

export  function fetchExternalCollaboratorProducts(){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/ext/products?page=0&pageSize=500&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startExternalCollaboratorProductFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endExternalCollaboratorProductFetch(response,error));
        } catch(e){
            dispatch(endExternalCollaboratorProductFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------


function startProductExternalCollaboratorFetch(){
    return {
        type:START_PRODUCT_EXT_COLLABORATOR_FETCH,
        payload:{}
    };
}

function endProductExternalCollaboratorFetch(success,error){
    return {
        type:END_PRODUCT_EXT_COLLABORATOR_FETCH,
        payload:{
            success,error
        }
    };
}

export  function fetchProductExternalCollaborators(productId,sectionId){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${productId}/section/${sectionId}/ext/users?page=0&pageSize=500&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startProductExternalCollaboratorFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endProductExternalCollaboratorFetch(response,error));
        } catch(e){
            dispatch(endProductExternalCollaboratorFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//-------------------------------------
function startProductExternalCollaboratorAdd(){
    return {
        type:START_PRODUCT_EXT_COLLABORATOR_ADD,
        payload:{}
    };
}

function endProductExternalCollaboratorAdd(success,error,additionalData){
    return {
        type:END_PRODUCT_EXT_COLLABORATOR_ADD,
        payload:{
            success,error,additionalData
        }
    };
}


export  function addProductExternalCollaborator(productId,data){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/ext/product/${productId}?t=${timestamp}`;
    return async (dispatch, getState) => {
        dispatch(startProductExternalCollaboratorAdd());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            let obj = getState();
            obj = obj?.dialogs?.searchExternalUserPoperAdditionData;
            dispatch(endProductExternalCollaboratorAdd(response,error,obj));
        } catch(e){
            dispatch(endProductExternalCollaboratorAdd(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------




//-------------------------------------
function startProductExternalCollaboratorRemove(){
    return {
        type:START_PRODUCT_EXT_COLLABORATOR_REMOVE,
        payload:{}
    };
}

function endProductExternalCollaboratorRemove(success,error,additionalData,userId){
    return {
        type:END_PRODUCT_EXT_COLLABORATOR_REMOVE,
        payload:{
            success,error,additionalData,userId
        }
    };
}

export  function removeProductExternalCollaborator(userId,productId,sectionId){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/ext/${userId}/product/${productId}/section/${sectionId}?t=${timestamp}`;
    return async (dispatch, getState) => {
        dispatch(startProductExternalCollaboratorRemove());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
            let obj = getState();
            
            obj = obj?.dialogs?.searchExternalUserPoperAdditionData;
            dispatch(endProductExternalCollaboratorRemove(response,error,obj,userId));
        } catch(e){
            dispatch(endProductExternalCollaboratorRemove(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------

