import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment";

export const START_DOWNLOAD_REPORT = "START_DOWNLOAD_REPORT";
export const END_DOWNLOAD_REPORT = "END_DOWNLOAD_REPORT";
export const RESET_DOWNLOAD_REPORT = "RESET_DOWNLOAD_REPORT";

function startDownloadReport() {
  return {
    type: START_DOWNLOAD_REPORT,
    payload: {},
  };
}

function endDownloadReport(success, error) {
  return {
    type: END_DOWNLOAD_REPORT,
    payload: {
      success,
      error,
    },
  };
}

export function resetDownloadReport() {
  return {
    type: RESET_DOWNLOAD_REPORT,
    payload: null,
  };
}

export function downloadReport(data) {
  const apiServer = Environment.api_host("REPORT");

  const url = `${apiServer}/user/event/download`;
  console.log(url, "URL");
  return async (dispatch) => {
    dispatch(startDownloadReport());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(
        url,
        data
      );
      dispatch(endDownloadReport(response, error));
      if (response) {
        const downloadUrl = response.path;
        console.log(downloadUrl, "downloadUrl");
        if (downloadUrl) {
          window.open(downloadUrl, "_blank");
        } else {
          console.error("Download URL not found in the response.");
        }
      }
    } catch (e) {
      dispatch(
        endDownloadReport(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

// Updated data update functions

export const START_UPDATE_REPORT_DATA = "START_UPDATE_REPORT_DATA";
export const END_UPDATE_REPORT_DATA = "END_UPDATE_REPORT_DATA";
export const RESET_UPDATE_REPORT_DATA = "RESET_UPDATE_REPORT_DATA";

export function startUpdateReportData() {
  return {
    type: START_UPDATE_REPORT_DATA,
    payload: {},
  };
}

export function endUpdateReportData(success, error) {
  return {
    type: END_UPDATE_REPORT_DATA,
    payload: {
      success,
      error,
    },
  };
}

export function resetUpdateReportData() {
  return {
    type: RESET_UPDATE_REPORT_DATA,
    payload: null,
  };
}

export function updateReportData(data) {
  const apiServer = Environment.api_host("REPORT");
  const url = `${apiServer}/user/event`;
  console.log(url, "URL");

  return async (dispatch) => {
    dispatch(startUpdateReportData());
    console.log("Here");
    try {
      console.log("Here");
      let [response, error] = await HttpFactory.instance().postMethod(
        url,
        data
      );
      console.log("Here");
      dispatch(endUpdateReportData(response, error));
      // Handle the response as required
    } catch (e) {
      dispatch(
        endUpdateReportData(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

export const START_UPDATE_SEARCH = "START_UPDATE_SEARCH";
export const END_UPDATE_SEARCH = "END_UPDATE_SEARCH";
export const RESET_UPDATE_SEARCH = "RESET_UPDATE_SEARCH";

export function startUpdateSearch() {
  return {
    type: START_UPDATE_SEARCH,
    payload: {},
  };
}

export function endUpdateSearch(success, error) {
  return {
    type: END_UPDATE_SEARCH,
    payload: {
      success,
      error,
    },
  };
}

export function updateSearch(data) {
  return async (dispatch) => {
    console.log("updateSearch", data);
    dispatch(startUpdateSearch());
    try {
      dispatch(endUpdateSearch(data, {}));
      // Handle the response as required
    } catch (e) {
      dispatch(
        endUpdateReportData(
          {},
          {
            message: e.message,
          }
        )
      );
      return;
    }
  };
}
