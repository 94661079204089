import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import { CommonFn } from 'services/commonFn';
import AppBarV2 from "../../../AppBarV2";
import deleteIcon from "assets/images/delete.svg";
import Alert from "@material-ui/lab/Alert";
import { Add as AddIcon } from "@material-ui/icons"
import * as Validator from "util/Validation";
import {
    CircularProgress, Button
} from "@material-ui/core";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

// components
import Image from 'Components/Common/image';

// assets
import CommonCss from "../../../../../commonCss";
import ReviewsEmptyState from "assets/images/reviews_empty_state.svg";

// Redux
import { fetchProductReviews, addProductToReview, deleteProductReview } from "redux/product/orgProduct/action";
import { fetchProductDetails } from "redux/product/action";
import MessageDialog from "Components/Common/MessageDialog";
import {
    showMessageDialog,
    hideMessageDialog,
} from "redux/applications/action";

import { fetchMasterTemplate } from "redux/templates/evaluation/master/action";
import { fetchOrgTemplate } from "redux/templates/evaluation/org/action";
import { importRequirement } from "redux/evaluation/criteria/action"
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
    fetchProductReviewsProgress: state.orgProduct.fetchProductReviewsProgress,
    productReviews: state.orgProduct.productReviews,
    productDetails: state.products.productDetails,
    evaludationDetails: state.orgProduct.evaludationDetails,
    fetchAddProductToReview: state.orgProduct.fetchAddProductToReview,
    deleteProductReviewProgress: state.orgProduct.deleteProductReviewProgress,
    deleteProductReviewError: state.orgProduct.deleteProductReviewError,
    configurations: state.orgUser.fetchAllOrgSettingsSuccess,
    configurationsFetchProg: state.orgUser.fetchAllOrgSettingsInprogress,
    template: state.masterTemplateEvaluation.template,
    fetchTemplateProg: state.masterTemplateEvaluation.fetchTemplateProg,
    orgTemplate: state.orgTemplateEvaluation.template,
    fetchOrgTemplateProg: state.orgTemplateEvaluation.fetchTemplateProg,

});

const connectionActions = {
    fetchProductReviews: fetchProductReviews,
    fetchProductDetails: fetchProductDetails,
    addProductToReview: addProductToReview,
    deleteProductReview: deleteProductReview,
    showMessageDialog: showMessageDialog,
    hideMessageDialog: hideMessageDialog,
    importRequirement: importRequirement,
    fetchMasterTemplate: fetchMasterTemplate,
    fetchOrgTemplate: fetchOrgTemplate,
    showSnackBar:showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
    createStyles({
        viewAll: CommonCss.viewAll,
        evaluateCard: {
            width: 'calc(100% - 0px)',
            background: '#fff',
            padding: "16px 24px",
            margin: '20px 0px 0px 0px',
            boxShadow: '0px 3px 25px #55555500',
            borderRadius: 5,
            display: 'flex',
            cursor: 'pointer'
        },
        reviewCompleted: {
            color: '#A0A5BA',
            fontSize: 12,
            marginTop: 3,
            marginBottom: 0
        },
        evaluateCardTitle: {
            color: '#20253A',
            fontSize: 14,
            margin: 0,
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        evaluateLogo: {
            flex: .8,
            display: 'flex',
            justifyContent: 'flex-end'
        },
        evaluateText: {
            flex: 1.5,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        loader: {
            marginTop: 20,
            marginLeft: 20,
            display: 'block'
        },
        noMoreData: {
            marginTop: 30,
            display: 'block'
        },

        List: {
            // marginTop: theme.spacing(6.4),
            maxHeight: "90vh",
            minHeight: 200,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
        },
        btnReview: {
            width: '66px',
            height: '32px',
            border: '1px solid #91949E',
            borderRadius: '5px',
            background: ' #ffffff',
            cursor: 'pointer',
            textAlign: 'center',
            fontSize: '13px',
            color: '#626675',
            marginRight: 10
        },
        deleteIcon: {
            width: 'auto'
        },
        reviewCount: {
            color: '#282D30',
            fontSize: 16,

            fontWeight: 600
        },
        reviewCountSection: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        newEvaluation: {
            color: '#fff',
            fontSize: '0.95em',
            minWidth: '140px',
            background: '#3C3C3C',
            minHeight: '32px',
            marginRight: '10px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer'
        },
        alert: {
            marginTop: 10
        },
        DashboardNoDataLayout: {
            margin: '22px 0px 0px 0px',
            minHeight: 450,
            textAlign: 'center',
            // background: '#ffffff',
            padding: '23px',
            borderRadius: '6px',
            '& button': {
                margin: '20px 0',
                color: '#fff',
                background: '#3C3C3C',
                '&:hover': {
                    color: '#FFF',
                    background: '#3C3C3C',
                }
            }
        },
        rootContainer: CommonCss.rootContainer
    });

class ProductReviewList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteId: null,
            error: null,
            defaultTemplateType: "MASTER",
            defaultCriteriaIds: [],
            reviable: false
        }
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.props.fetchProductDetails(this.props.match?.params?.productID)
        this.fetchData();
        if (this.props.configurations !== null) {
            this.fetchDefaultEvaluationTemplate();
        }
    }

    fetchDefaultEvaluationTemplate() {
        var templateType = "MASTER";
        var templateId = 1
        if (this.props.configurations !== null) {
            var default_review_setting = this.props.configurations.filter((i) => {
                return i.SettingName === "ORG_DEFAULT_REVIEW_TEMPLATE";
            })

            if (default_review_setting.length > 0) {
                var settingValue = default_review_setting[0].Value
                if (settingValue !== "NULL") {
                    try {
                        var parsed = Validator.isJson(settingValue) ? JSON.parse(settingValue) : {};
                        if (parsed.type === "ORG") {
                            templateType = "ORG"
                            templateId = parseInt(parsed.tid)
                        } else if (parsed.type === "MASTER") {
                            templateType = "MASTER"
                            templateId = parseInt(parsed.tid)
                        }
                    } catch (e) { }
                }
            }
        }
        this.setState({
            defaultTemplateType: templateType
        })
        if (templateType === "MASTER") {
            this.props.fetchMasterTemplate(templateId, this.props.evaluationId)
        } else {
            this.props.fetchOrgTemplate(templateId, this.props.evaluationId)
        }
    }

    fetchData() {
        var data = {
            productID: parseInt(this.props.match?.params?.productID),
            status: [4]
        }

        this.props.fetchProductReviews(data);
    }

    redirectPage() {
        if (this.props.configurations !== null) {
            var default_review_workflow = this.props.configurations.filter((i) => {
                return i.SettingName === "ORG_DEFAULT_REVIEW_WORKFLOW";
            })
            console.log(default_review_workflow, 'default_review_workflow')
            if (default_review_workflow.length > 0 && default_review_workflow[0].Value !== undefined && default_review_workflow[0].Value !== null && default_review_workflow[0].Value !== '' && default_review_workflow[0].Value !== "NULL" && default_review_workflow[0].Value.length > 0) {
                this.props.history.push(`/app/evaluate/${this.props.evaludationDetails?.ID}/workflow/interim`);
            } else {
                this.props.importRequirement(this.props.evaludationDetails?.ID, this.state.defaultCriteriaIds, this.state.defaultTemplateType)
                let redirectPath = `/app/evaluate/${this.props.evaludationDetails?.ID}/setup`;
                this.props.history.push(redirectPath);
            }
        } else {
            this.props.importRequirement(this.props.evaludationDetails?.ID, this.state.defaultCriteriaIds, this.state.defaultTemplateType)
            let redirectPath = `/app/evaluate/${this.props.evaludationDetails?.ID}/setup`;
            this.props.history.push(redirectPath);
        }
    }

    componentDidUpdate(preProps) {
        if (this.props.configurationsFetchProg === false && preProps.configurationsFetchProg === true) {
            if (this.props.configurations !== null) {
                this.fetchDefaultEvaluationTemplate();
            }
        }

        if (this.state.defaultTemplateType === "MASTER") {
            if (this.props.fetchTemplateProg === false && preProps.fetchTemplateProg === true) {
                if (this.props.template !== null) {
                    this.setState({
                        defaultCriteriaIds: this.props.template.Criterias.map((i) => {
                            return i.ID
                        }),
                        reviable: true
                    })
                }
            }
        } else {
            if (this.props.fetchOrgTemplateProg === false && preProps.fetchOrgTemplateProg === true) {
                if (this.props.orgTemplate !== null) {
                    this.setState({
                        defaultCriteriaIds: this.props.orgTemplate.Criterias.map((i) => {
                            return i.ID
                        }),
                        reviable: true
                    })
                }
            }
        }


        if (!this.props.fetchAddProductToReview && preProps.fetchAddProductToReview) {
            if (this.props.evaludationDetails) {
                this.redirectPage()
            }
        }

        if (!this.props.deleteProductReviewProgress && preProps.deleteProductReviewProgress) {
            if (!this.props.deleteProductReviewError) {
                this.fetchData();

            } else {
                this.setState({ error: this.props.deleteProductReviewError });
                setTimeout(() => {
                    this.setState({ error: null });
                }, 2000)
            }

        }
    }

    deleteReview(id) {
        var me = this;
        this.setState({ deleteId: id }, () => {
            me.props.showMessageDialog();
        })
    }

    callBack = (data) => {
        if (this.state.deleteId) {
            this.props.hideMessageDialog();
            this.props.deleteProductReview(this.state.deleteId);
        }
    };

    routeChange = (item) => {
        let redirectPath = '';
        if (item.WorkflowID === undefined || item.WorkflowID === null || item.WorkflowID === '') {
            redirectPath = `/app/evaluate/${item.ID}/setup`;
            this.props.history.push(redirectPath);
        } else {
            redirectPath = `/app/evaluate/${item.ID}/workflow/interim`;
            this.props.history.push(redirectPath);
        }
    }

    addNewReview(productId) {
        this.props.addProductToReview(productId, {
            status: 2
        })
        // if (this.props.configurations !== undefined && this.props.configurations !== null) {
        //     const defaultObjReview = this.props.configurations.find((item) => item.SettingName === "ORG_DEFAULT_REVIEW_WORKFLOW");
        //     var defaultWorkflowId = null;

        //     if ((defaultObjReview && defaultObjReview?.Value && defaultObjReview?.Value !== 'NULL')) {
        //         defaultWorkflowId = JSON.parse(defaultObjReview?.Value).wid
        //     }

        //     if (defaultWorkflowId === null) {
        //         this.props.showSnackBar("Set default review workflow before proceed.", "error", 3000)
        //         return;
        //     }

        //     this.props.addProductToReview(productId, {
        //         status: 2
        //     })

        // } else {
        //     this.props.showSnackBar("Set default review workflow before proceed.", "error", 3000)
        // }

    }

    setupWorkflow(){
        this.props.history.push('/app/settings/form-builder');
        // this.props.history.push('/app/evaluate/${this.props.evaluationCart.ID}/workflow/interim`);
      }


    render() {

        let messageData = {
            head: "Are you sure?",
            subHead: "Do you really want to delete? This process cannot be undone.",
            firstBtn: "Cancel",
            secondBtn: "Yes",
        };

        const classes = this.props.classes;

        var showNewReviewBtn = true;
        if (this.props.configurations !== undefined && this.props.configurations !== null) {
            const defaultObjReview = this.props.configurations.find((item) => item.SettingName === "ORG_DEFAULT_REVIEW_WORKFLOW");
            var defaultWorkflowId = null;

            if ((defaultObjReview && Validator.isJson(defaultObjReview?.Value) && defaultObjReview?.Value && defaultObjReview?.Value !== 'NULL')) {
                defaultWorkflowId = JSON.parse(defaultObjReview?.Value).wid
            }

            if (defaultWorkflowId === null) {
                showNewReviewBtn = false;
            }
        }

        return (
            <>
                <div className={classes.topHead}>
                    <AppBarV2 title={!this.props.fetchProductReviewsProgress ? this.props.productDetails?.Name + ' Review' : ''} withBack={true} dropdown={true} />
                </div>
                <div className={classes.rootContainer}>
                    <div className={classes.reviewCountSection}>
                        {this.props.productReviews?.Data?.length > 0 &&
                            <>
                                <div className={classes.reviewCount}>{this.props.productReviews?.Data?.length} review{this.props.productReviews?.Data?.length > 1 ? 's' : ''}</div>
                                {this.state.reviable && showNewReviewBtn && <button
                                    className={classes.newEvaluation}
                                    onClick={() => {
                                        this.addNewReview(this.props.match?.params?.productID)
                                    }}>
                                    + New Review
                                </button>}

                                {this.state.reviable && !showNewReviewBtn && <button
                                    className={classes.newEvaluation}
                                    onClick={() => {
                                        this.setupWorkflow()
                                    }}>
                                    Setup Workflow
                                </button>}

                                
                                {!this.state.reviable && <CircularProgress />}
                            </>
                        }
                    </div>
                    <div>
                        <div className={classes.alert}>
                            {this.state.error !== null && (
                                <Alert variant="filled" severity="error">
                                    {this.state.error}
                                </Alert>
                            )}
                        </div>
                        {!this.props.fetchUpcomingReviewProductsUnderProgress && this.props.productReviews?.Data?.length > 0 &&
                            <div className={classes.upcomingList} id="productReviewList">
                                <InfiniteScroll
                                    scrollableTarget="productReviewList"
                                    style={{ display: "flex", flexDirection: "column", overflow: "hidden", marginRight: 0 }}
                                    dataLength={this.props.productReviews?.Data?.length}
                                    next={() => {
                                        this.fetchData();
                                    }}
                                >
                                    <div>
                                        {!this.props.fetchUpcomingReviewProductsUnderProgress
                                            && this.props.productReviews?.Data?.map((item, index) =>
                                                <div className={classes.evaluateCard} key={index}>
                                                    <div className={classes.evaluateText}>
                                                        <p className={classes.evaluateCardTitle}>{item.Name}</p>
                                                        <p className={classnames(classes.reviewCompleted, classes.upcomingReview)}>Last Reviewed: {CommonFn.formateDate(item.UpdatedAt)}</p>
                                                    </div>
                                                    <div className={classes.evaluateLogo}>
                                                        <button type="button" className={classes.btnReview} onClick={() => this.routeChange(item)}>View</button>
                                                        <button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={() => this.deleteReview(item.ID)}><Image
                                                            src={deleteIcon}
                                                            alt="forgotpassword_image"
                                                            className={classes.titleImage}
                                                        /></button>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </InfiniteScroll>
                            </div>
                        }
                        {this.props.fetchProductReviewsProgress &&
                            <span className={classes.loader}><CircularProgress /></span>
                        }
                        {!this.props.fetchProductReviewsProgress && this.props.productReviews?.Data?.length === 0 &&
                            <>
                                {showNewReviewBtn && <div className={classes.DashboardNoDataLayout}>
                                    <Image src={ReviewsEmptyState} />
                                    <h2>No Reviews found</h2>
                                    
                                    <p>Get started on your first review by tapping New Review below</p>
                                    <Button variant="outlined" color="secondary" className={classes.button} startIcon={<AddIcon />}
                                        onClick={() => this.props.addProductToReview(this.props.match?.params?.productID)}
                                    >New Review</Button>

                                </div>}

                                {!showNewReviewBtn && <div className={classes.DashboardNoDataLayout}>
                                    <Image src={ReviewsEmptyState} />
                                    <h2>No Reviews found</h2>
                                    
                                    <p>Get started on your first review, by tapping Setup Workflow set default review workflow</p>
                                    <Button variant="outlined" color="secondary" className={classes.button} startIcon={<AddIcon />}
                                        onClick={() => 
                                            this.setupWorkflow()
                                        }
                                    >Setup Workflow</Button>

                                </div>}

                                
                            </>
                        }
                    </div>
                </div>
                <MessageDialog messageData={messageData} callBack={this.callBack} />
            </>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ProductReviewList));
