import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

// Actions
import { fetchOrgWorkflowComponents, fetchWorkflowComponentDetails, fetchComponentDetails, fetchWorkflowComponentsForms } from "redux/workflow/action";

import { fetchEvaluationMetaData } from "redux/evaluation/metadata/action"

// Components
import PreFeedbackDialog from "./Components/PreFeedbackDialog";
import EvaluationDetailForm from "./Components/EvaluationDetailForm";
import EvaluationGuideForm from "./Components/EvaluationGuideForm";
import RequirementGathering from "../Setup/index";
import Evaluation from "../InProgressV2/index";
import FeedbackForm from "./Components/FeedbackForm";
import Summary from "../Summary/index";
import Approval from "../Approval/index";
import DecisionComponent from "./Components/DecisionComponent";
import SendNotificationForm from "./Components/SendNotificationForm";
import CustomForm from "./Components/CustomForm";
import ProductShortlist from "./Components/ProductShortlist";


const connectedProps = (state) => ({
  nextComponentDetails: state.workflow.nextComponentDetails,
  componentUserResponse: state.workflow.componentUserResponse,
  fetchComponentUserResponseProgress: state.workflow.fetchComponentUserResponseProgress,
  fetchOrgWorkflowComponentsProgress: state.workflow.fetchOrgWorkflowComponentsProgress,
  orgWorkflowComponentsDatas: state.workflow.orgWorkflowComponentsDatas,
  orgWorkflowComponentsMap: state.workflow.orgWorkflowComponentsMap,
  masterComponentsMap: state.workflow.masterComponentsMap,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  fetchworkflowComponentDetailProgress: state.workflow.fetchworkflowComponentDetailProgress,
  componentDetail: state.workflow.componentDetail,
  fetchComponentDetailProgress: state.workflow.fetchComponentDetailProgress,
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  workflowComponentsForms: state.workflow.workflowComponentsForms
});

const connectionActions = {
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  fetchOrgWorkflowComponents: fetchOrgWorkflowComponents,
  fetchWorkflowComponentDetails: fetchWorkflowComponentDetails,
  fetchComponentDetails: fetchComponentDetails,
  fetchWorkflowComponentsForms: fetchWorkflowComponentsForms
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});


class ChildWorkFlowComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedComponentSlug: null,
      selectedComponentID: null,
    }
  }

  componentDidMount() {
    this.props.fetchEvaluationMetaData(this.props.match.params.evaluation_id);
    // this.props.fetchOrgWorkflowComponents(this.props.match.params.workflow_id);
    this.props.fetchWorkflowComponentDetails(this.props.match.params.workflow_id, this.props.match.params.component_id,this.props.match.params.evaluation_id);
  }

  componentDidUpdate(prevProps) {
    // if (this.props.fetchOrgWorkflowComponentsProgress === false && prevProps.fetchOrgWorkflowComponentsProgress === true) {
    //   var componentId = this.props.orgWorkflowComponentsMap[this.props.match.params.component_id].ComponentID;
    //   this.props.fetchWorkflowComponentDetails(this.props.match.params.workflow_id,componentId);
    //   this.setState({
    //     selectedComponentID: componentId,
    //   })
    // }

    if (this.props.fetchworkflowComponentDetailProgress === false && prevProps.fetchworkflowComponentDetailProgress === true) {
      this.setState({
        selectedComponentID: this.props.workflowComponentDetail?.ComponentID,
      }, () => {
        this.props.fetchComponentDetails(this.props.workflowComponentDetail?.ComponentID);
        this.props.fetchWorkflowComponentsForms(this.props.workflowComponentDetail?.ComponentID);
      })
    }

    if (this.props.fetchComponentDetailProgress === false && prevProps.fetchComponentDetailProgress === true) {
      this.setState({
        selectedComponentSlug: this.props.componentDetail?.Slug
      })
    }

    if (this.props.addComponentUserResponseProgress === false && prevProps.addComponentUserResponseProgress === true) {
      this.props.history.push("/app/evaluate/" + this.props.match.params.evaluation_id + "/workflow/"+ this.props.match.params.next_evaluation_id +"/interim");
    }

  }

  render() {
    const classes = this.props.classes;

    if (this.props.fetchComponentDetailProgress && this.state.selectedComponentSlug === null) {
      return <div className={classes.loader}> <CircularProgress /></div>
    }


    return (
      <div>

        {this.state.selectedComponentSlug === 'DECISION_COMPONENT' && <DecisionComponent
          title={'Decision Component'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
        />}

        {this.state.selectedComponentSlug === 'PRE_EVALUATION_FEEDBACK' && <PreFeedbackDialog
          title={'Pre Evaluation Feedback'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
        />}

        {this.state.selectedComponentSlug === 'PRE_EVALUATION_FEEDBACK_FORM' && <PreFeedbackDialog
          title={'Pre Evaluation Feedback'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
        />}

        {this.state.selectedComponentSlug === 'EVALUATION_DETAILS_FORM' && <EvaluationDetailForm
          title={'Evaluation Detail Form'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
        />}

        {this.state.selectedComponentSlug === 'EVALUATION_GUIDE_FORM' && <EvaluationGuideForm
          title={'Evaluation Guide Form'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
        />}

        {this.state.selectedComponentSlug === 'REQUIREMENT_GATHERING' && <RequirementGathering
          title={'Requirement Gathering'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
        />}

        {this.state.selectedComponentSlug === 'REQUIREMENTS_SELECTION' && <RequirementGathering
          title={'Requirement Gathering'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
        />}

        {this.state.selectedComponentSlug === 'EVALUATION' && <Evaluation
          title={'Evaluation'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
        />}

        {this.state.selectedComponentSlug === 'QUESTIONNAIRE' && <Evaluation
          title={'QUESTIONNAIRE'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
        />}

        {this.state.selectedComponentSlug === 'FEEDBACK' && <FeedbackForm
          open={true}
          title={'Feedback Form'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
        />}

        {this.state.selectedComponentSlug === 'COLLABORATOR_FEEDBACK' && <FeedbackForm
          open={true}
          title={'Feedback Form'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
        />}


        {this.state.selectedComponentSlug === 'SUMMARY_PAGE' && <Summary
          title={'Summary Page'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'APPROVAL_PAGE' && <Approval
          title={'Approval Page'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
        />}

        {this.state.selectedComponentSlug === 'EMAIL/LINK/DOC' && <SendNotificationForm
          title={'Email Notification'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
        />}

        {this.state.selectedComponentSlug === 'CUSTOM_FORM' && <CustomForm
          title={'Custom Form'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
        />}

        {this.state.selectedComponentSlug === 'RFP_PURPOSE_FORM' && <CustomForm
          title={'RFP Purpose Form'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
        />}

        {this.state.selectedComponentSlug === 'PRODUCT_SHORTLIST' && <ProductShortlist
          title={'Product Shortlist'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isOpen={true}
        />}

        {this.state.selectedComponentSlug === 'FINISH' && <Summary
          title={'Summary Page'}
          component={this.props.componentDetail}
          componentId={this.state.selectedComponentID}
          evaluation_id={this.props.match.params.evaluation_id}
          next_evaluation_id={this.props.match.params.next_evaluation_id}
          workflow_id={this.props.match.params.workflow_id}
          workflowComponentId={this.props.match.params.component_id}
          isWorkFlow={true}
          isFinish={true}
        />}

      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ChildWorkFlowComponent));
