import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Grid} from "@material-ui/core";
import classnames from "classnames";
import CommonCss from "commonCss";
import { Typography } from "@material-ui/core";
import InprogressRequestEvaluations from "./InprogressRequestEvaluations";
import CompletedRequestEvaluations from "./CompletedRequestEvaluations";
import MessageDialog from "Components/Common/MessageDialog";
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";

import { deleteInprogressRequest,deleteCompletedRequest } from "redux/evaluation/requests/action";


const connectedProps = (state) => ({
});

const connectionActions = {
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  deleteInprogressRequest: deleteInprogressRequest,
  deleteCompletedRequest: deleteCompletedRequest
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    bottomContainer: {
      marginBottom: theme.spacing(9),
      "& [class*=MuiAvatar-root]": {
        width: 25,
        height: 25,
        fontSize: 14,
        marginRight: 2
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    ...CommonCss.Home,
    content: {
      background: "#F7F7F7",
    },
    requestSection: {
      padding: '1rem',
      minWidth: '50%',
      width: '50%',
    },
  });

class RequestEvaluations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteInprogressId: null,
      deleteCompletedId: null,
    };
  }

  
  deleteInprogressReview = (e, id) => {
    e.stopPropagation();
    var me = this;
    this.setState({ deleteInprogressId: id }, () => {
      me.props.showMessageDialog();
    })
  }

  deleteCompletedReview = (e, id) => {
    e.stopPropagation();
    var me = this;
    this.setState({ deleteCompletedId: id }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack = () => {
    if(this.state.deleteCompletedId){
      this.props.deleteCompletedRequest(this.state.deleteCompletedId);
      this.props.hideMessageDialog();
      this.setState({ deleteCompletedId: null });
    }
    if(this.state.deleteInprogressId){
      this.props.deleteInprogressRequest(this.state.deleteInprogressId);
      this.props.hideMessageDialog();
      this.setState({ deleteInprogressId: null })
    }
  };

  render() {
    const classes = this.props.classes;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <div>
        <Grid container className={classnames(classes.content)}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.bottomContainer}>
            <Grid container spacing={4}>
              <div className={classes.requestSection}>
                <InprogressRequestEvaluations deleteInprogressReview={this.deleteInprogressReview}/>
              </div>
              <div className={classes.requestSection}>
                <CompletedRequestEvaluations deleteCompletedReview={this.deleteCompletedReview}/>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <MessageDialog messageData={messageData} callBack={this.callBack} />
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(RequestEvaluations));
