// items types
export const itemTypes = [
  { label: 'Simple Text', value: 1 },
  { label: 'Date', value: 2 },
  { label: 'Binary', value: 3 },
  { label: 'Single Select', value: 4 },
  { label: 'Multi Select', value: 5 },
  { label: 'Table', value: 6 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8 },
  { label: 'File Upload', value: 9 },
  { label: 'Products', value: 10 },
  { label: 'Rich Text', value: 11 },
  { label: 'Email Address', value: 12 },
  { label: 'Phone', value: 13 },
  { label: 'URL', value: 14 },
  { label: 'Number', value: 15 },
  { label: 'Currency', value: 16 },
  { label: 'Existing Products', value: 17 },
  { label: 'Score', value: 18 },
  { label: 'Radio', value: 19 },
  { label: 'Checkbox', value: 20 },
  { label: 'Integrations', value: 21 },
  { label: 'Show Message', value: 22 },
]

export const tableItemTypes = [
  { label: 'Simple Text', value: 1 },
  { label: 'Number', value: 15 },
  { label: 'Date', value: 2 },
  { label: 'Email Address', value: 12 },
  { label: 'Phone', value: 13 },
  { label: 'URL', value: 14 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8 },
  { label: 'File Upload', value: 9 },
  { label: 'Currency', value: 20 },
]

export const itemTypesProd = [
  { label: 'Simple Text', value: 1 },
  { label: 'Date', value: 2 },
  { label: 'Binary', value: 3 },
  { label: 'Single Select', value: 4 },
  { label: 'Multi Select', value: 5 },
  // { label: 'Table', value: 6 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8 },
  { label: 'File Upload', value: 9 },
  { label: 'Products', value: 10 },
  { label: 'Rich Text', value: 11 },
  { label: 'Email Address', value: 12 },
  { label: 'Phone', value: 13 },
  { label: 'URL', value: 14 },
  { label: 'Number', value: 15 },
  { label: 'Currency', value: 16 },
  { label: 'Existing Products', value: 17 },
  { label: 'Score', value: 18 },
  { label: 'Radio', value: 19 },
  { label: 'Checkbox', value: 20 },
  // { label: 'Integrations', value: 21 },
  { label: 'Show Message', value: 22 },
]

export function getFormItemType(answerType) {
  let type = 'Rich Text'
  if (answerType !== undefined && answerType !== null && answerType !== '') {
    const AnswerType = parseInt(answerType)
    if (1 === AnswerType) type = 'Simple Text'
    if (2 === AnswerType) type = 'Date'
    if (3 === AnswerType) type = 'Binary'
    if (4 === AnswerType) type = 'Single Select'
    if (5 === AnswerType) type = 'Multi Select'
    if (6 === AnswerType) type = '---'
    if (7 === AnswerType) type = 'Users'
    if (8 === AnswerType) type = 'Teams'
    if (9 === AnswerType) type = 'File'
    if (10 === AnswerType) type = 'Products'
    if (11 === AnswerType) type = 'Rich Text'
    if (12 === AnswerType) type = 'Email Address'
    if (13 === AnswerType) type = 'Phone'
    if (14 === AnswerType) type = 'URL'
    if (15 === AnswerType) type = 'Number'
    if (16 === AnswerType) type = 'Currency'
    if (17 === AnswerType) type = 'Existing Products'
    if (18 === AnswerType) type = 'Score'
    if (19 === AnswerType) type = 'Radio'
    if (20 === AnswerType) type = 'Checkbox'
    if (21 === AnswerType) type = 'Web Hook Trigger'
    if (22 === AnswerType) type = 'Show Message'
  }
  return type
}

export function getFormTypeUsingAppCatalogType(answerType) {
  let type = [1]
  if (answerType !== undefined && answerType !== null && answerType !== '') {
    const AnswerType = parseInt(answerType)
    if (1 === AnswerType) type = [1] //Simple
    if (2 === AnswerType) type = [2] // Date
    if (3 === AnswerType) type = [3] // Binary
    if (4 === AnswerType) type = [4]  // Single Select
    if (5 === AnswerType) type = [5]  // Multi Select
    if (6 === AnswerType) type = [1, 2, 3, 7, 8, 11, 12, 13, 14, 15, 18, 19, 20]  // Table
    if (7 === AnswerType) type = [7] // Users
    if (8 === AnswerType) type = [8] // Teams
    if (9 === AnswerType) type = [9] // File
    if (10 === AnswerType) type = [10] // Products
    if (11 === AnswerType) type = [1, 11]  // Rich Text
    if (12 === AnswerType) type = [12] // Email
    if (13 === AnswerType) type = [13]  // Phone
    if (14 === AnswerType) type = [14] // URL
    if (19 === AnswerType) type = [15]  // Number
    if (20 === AnswerType) type = [16]  // Currency
  }
  return type
}

// export function getApplicationCatalogTypeForForm(ss) {
//   let types = [
//     1, // Simple Text
//     2, // Date
//     3, // Binary
//     4, // Single Select
//     5, // Multi Select
//     6, // Table
//     7, // Users
//     8, // Teams
//     10, // Products
//     11, // Rich Text
//     12, // Email
//     13, // Phone
//     14, // URL
//     19, // Number
//     20  // Currency
//   ]
//   return types
// }

export function getSortByCreatedDate(sectionDatas) {
  let newData = [];
  if (sectionDatas !== undefined && sectionDatas !== null && sectionDatas !== '') {
    newData = sectionDatas.sort(function (a, b) {
      return new Date(a.CreatedAt) - new Date(b.CreatedAt);
    });
  }
  return newData;
}

export function getSortBySeq([...sectionDatas]) {
  let newData = [];
  if (sectionDatas !== undefined && sectionDatas !== null && sectionDatas !== '') {
    newData = sectionDatas.sort(function (a, b) {
      if (a.Seq > b.Seq) {
        return -1;
      } else if (a.Seq < b.Seq) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  return newData;
}

export function getSortBySeqAscending([...sectionDatas]) {
  let newData = [];
  if (sectionDatas !== undefined && sectionDatas !== null && sectionDatas !== '') {
    newData = sectionDatas.sort(function (a, b) {
      if (a.Seq > b.Seq) {
        return 1;
      } else if (a.Seq < b.Seq) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  return newData;
}


export function getFormSectionSeqOriginal(sectionData) {
  let map = {};
  if (sectionData !== undefined && sectionData !== null && sectionData !== '') {
    sectionData.forEach(function (section, index) {
      map[index] = section.Seq;
    })
  }
  return map;
}

export function getFormSequenceMap(sectionData) {
  let map = {};
  let seqMap = {};
  if (sectionData !== undefined && sectionData !== null && sectionData !== '') {
    sectionData.forEach(function (section, index) {
      seqMap[section.Seq] = index;
    })
    sectionData.forEach(function (section, index) {
      if (seqMap[section.Seq + 1] === undefined) {
        map[index] = 'next';
      } else {
        map[index] = seqMap[section.Seq + 1];
      }
    })
  }
  return map;
}

export function getNextSectionsForConditions(sectionsDatas, currentSectionId, activeSecItemIndex) {
  let nextItems = [];
  // console.log(currentSectionId, 'currentSectionId')
  // console.log(activeSecItemIndex, 'activeSecItemIndex')
  // console.log(sectionsDatas, 'sectionsDatas')
  if (sectionsDatas !== undefined && sectionsDatas !== null && sectionsDatas !== '') {
    let sortDatas = getSortBySeq(sectionsDatas);
    // console.log(sortDatas, 'sortDatas')
    let currentIndex = sortDatas.findIndex(o => o.ID === currentSectionId);
    sortDatas.forEach(function (si, index) {
      if (si.ID === currentSectionId) {
        if (si.OrgWorkflowTemplateFormSectionItems !== undefined && si.OrgWorkflowTemplateFormSectionItems !== null && si.OrgWorkflowTemplateFormSectionItems !== '' && si.OrgWorkflowTemplateFormSectionItems.length > 0) {
          let sortItemDatas = getSortBySeqAscending(si.OrgWorkflowTemplateFormSectionItems);
          // console.log(sortItemDatas, 'SectionItems')
          sortItemDatas.forEach(function (itm, indx) {
            if (indx <= activeSecItemIndex) {
              return;
            }
            nextItems.push({
              label: 'Go to Question ' + itm?.ItemName,
              value: itm?.ID,
              type: 'item'
            })
          })
        }
      }

      if (currentIndex <= index) {
        return;
      }

      nextItems.push({
        label: 'Go to Section ' + si?.SectionName,
        value: si?.ID,
        type: 'section'
      })
    })
  }
  return nextItems;
}

export function checkSectionItemsConditions(sectionsDatas, type) {
  let isTakeConditions = false;
  var sectionItemName = type === 'custom' ? 'OrgWorkflowTemplateFormSectionItems' : 'MasterWorkflowTemplateFormsSectionItems';
  if (sectionsDatas !== undefined && sectionsDatas !== null && sectionsDatas !== '') {
    sectionsDatas.forEach(function (config) {
      var sectionItems = config[sectionItemName] !== undefined ? config[sectionItemName] : [];
      sectionItems.forEach(function (sectionItem) {
        var additionalData = '';
        try {
          additionalData = JSON.parse(sectionItem.AdditionalData);
        } catch (e) {
        }
        if ([3, 4, 15, 16, 18, 19].includes(sectionItem.Type)) {
          additionalData.forEach((data, i) => {
            if (data.condition !== undefined && data.condition !== null) {
              isTakeConditions = true;
            }
          })
        }
      });
    })
  }
  return isTakeConditions;
}



export const visibilityType = [
  { label: 'None', value: 0 },
  { label: 'Admin', value: 1 },
  { label: 'End User', value: 2 },
  { label: 'Both', value: 3 }
]



export const binaryOptions = [
  {
    label: 'Yes',
    value: 'yes'
  },
  {
    label: 'No',
    value: 'no'
  }
]

export const webHookOptions = [
  {
    label: 'Workflow Run Finish',
    value: 'workflow.run.finish'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

export const webHookFormsTypeOptions = [
  {
    label: 'Master',
    value: 'Master'
  },
  {
    label: 'Org',
    value: 'Org'
  }
]

