import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";

export const START_NOTIFY_ME_FETCH     =   "START_NOTIFY_ME_FETCH";
export const END_NOTIFY_ME_FETCH       =   "END_NOTIFY_ME_FETCH";

export const RESET_NOTIFY_ME_FETCH       =   "RESET_NOTIFY_ME_FETCH";

export const HIDE_CRITERIA_RULE_DIALOG = 'HIDE_CRITERIA_RULE_DIALOG';
export const SHOW_CRITERIA_RULE_DIALOG = "SHOW_CRITERIA_RULE_DIALOG";

export const START_ADD_EVENT = 'START_ADD_EVENT';
export const END_ADD_EVENT = "END_ADD_EVENT";

export const HIDE_APPROVAL_CRITERIA_RULE_DIALOG="HIDE_APPROVAL_CRITERIA_RULE_DIALOG";
export const SHOW_APPROVAL_CRITERIA_RULE_DIALOG="SHOW_APPROVAL_CRITERIA_RULE_DIALOG";

export const SHOW_REQUIREMENT_CONDITION_DIALOG="SHOW_REQUIREMENT_CONDITION_DIALOG";
export const HIDE_REQUIREMENT_CONDITION_DIALOG="HIDE_REQUIREMENT_CONDITION_DIALOG";

/**
 * Product stats
 */
 function startNotifyMeFetch(){
    return {
        type:START_NOTIFY_ME_FETCH,
        payload:{}
    };
}

function endNotifyMeFetch(success,error){
    return {
        type:END_NOTIFY_ME_FETCH,
        payload:{
            success,error
        }
    };
}

export function resetNotifyMeFetch(){
    return {
        type:RESET_NOTIFY_ME_FETCH,
        payload:null
    };
}


export  function notifyMeFetch(data){
    const api_server = Environment.api_host("USER");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/interest?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startNotifyMeFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endNotifyMeFetch(response,error));
        } catch(e){
            dispatch(endNotifyMeFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

export function showCriteriaRuleDialog(payload){
    return {
        type:SHOW_CRITERIA_RULE_DIALOG,
        payload: payload
    };
}

export function hideCriteriaRuleDialog(payload){
    return {
        type:HIDE_CRITERIA_RULE_DIALOG,
        payload:payload
    };
}

export function showRequirementConditionDialog(payload){
    return {
        type:SHOW_REQUIREMENT_CONDITION_DIALOG,
        payload: payload
    };
}

export function hideRequirementConditionDialog(payload){
    return {
        type:HIDE_REQUIREMENT_CONDITION_DIALOG,
        payload:payload
    };
}





function startFireEvent(success,error){
    return {
        type:START_ADD_EVENT,
        payload:{
            success,error
        }
    };
}

export function endFireEvent(){
    return {
        type:END_ADD_EVENT,
        payload:null
    };
}


export  function fireEvent(data){
    const api_server = Environment.api_host("USER");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/fire-event?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFireEvent());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endFireEvent(response,error));
        } catch(e){
            dispatch(endFireEvent(null,{
                message:e.message
            }));
            return;
        }
    };
} 
  

export function hideApprovalCriteriaRuleDialog(payload){
    return {
        type:HIDE_APPROVAL_CRITERIA_RULE_DIALOG,
        payload:payload
    };
}

export function showApprovalCriteriaRuleDialog(payload){
    return {
        type:SHOW_APPROVAL_CRITERIA_RULE_DIALOG,
        payload: payload
    };
}
