import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, CircularProgress, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import { hideAddNewEvaluationDialog } from "../../../../../../redux/evaluate/action";
import classnames from "classnames";
import { Step, Steper } from "../../../../../Common/Steper/index";
import { withRouter } from "react-router-dom";
import whiteTick from "../../../../../../assets/images/white_tick.svg";
import findProduct from "../../../../../../assets/images/find_product.svg";
import knewProduct from "../../../../../../assets/images/knew_product.svg";
import Beta from "assets/images/beta.svg";
import CheckIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { fetchProductSubCategoryV2, fetchProductSubCategory, fetchSubCategoryFeature } from "../../../../../../redux/product/subcategory/action";
import InfiniteScroll from "react-infinite-scroll-component";
import ArrowBack from "assets/images/arrow_back.svg";
import {searchAppProductCategory} from "redux/product/appProductCategory/action";

import Image from 'Components/Common/image.jsx'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    evaluate: state.evaluate,
    subCategories: state.productSubCategory.subCategoryV2,
    subCategoryFeatures: state.productSubCategory.subCategoryFeatures,
    subCategoriesFetchProgress: state.productSubCategory.fetchSubCategoryV2Progress,
    subCategoriesFetchError: state.productSubCategory.fetchSubCategoryV2Error,
    fetchSubCategoryFeaturesProgress: state.productSubCategory.fetchSubCategoryFeaturesProgress,
    fetchSubCategoryFeaturesError: state.productSubCategory.fetchSubCategoryFeaturesError,
    appProductCategories: state.appProductCategory.appProductCategories,
    fetchAppProductCategoryProgress: state.appProductCategory.fetchAppProductCategoryProgress,
    fetchAppProductCategoryError: state.appProductCategory.fetchAppProductCategoryError,
});

const connectionActions = {
    hideAddNewEvaluationDialog: hideAddNewEvaluationDialog,
    fetchProductSubCategory: fetchProductSubCategory,
    fetchSubCategoryFeature: fetchSubCategoryFeature,
    fetchProductSubCategoryV2: fetchProductSubCategoryV2,
    searchAppProductCategory: searchAppProductCategory,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginLeft: 20,
        fontWeight: 500,
        marginBottom: 0
    },
    dialogContent: {
        textAlign: 'center'
    },
    subTitle: {
        color: '#4B4B4B',
        fontSize: 13,
    },
    dialog: {
        "& [class*=MuiDialog-paperWidthSm]": {
            maxWidth: 750,
            minHeight: 63
        },
        "& [class*=MuiDialog-paperWidthMd]": {
            maxWidth: 1050,
            minHeight: 63
        },
        "& [class*=MuiDialogContent-root], & [class*=MuiDialogContent-root]:first-child": {
            padding: 0,
        },

    },
    cardSection: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30
    },
    card: {
        border: "1px solid #BEBEBE",
        borderRadius: 6,
        maxWidth: 240,
        margin: 20,
        padding: 25,
        cursor: 'pointer',
        position: 'relative',

        "&.active": {
            boxShadow: '0px 10px 20px #00000022',
            border: '1px solid #4A87F8'
        }
    },
    cardLabel: {
        color: '#282D30',
        margin: 0,
        fontWeight: 500
    },
    applyBtn: {
        background: '#3C3C3C',
        borderRadius: 5,
        minHeight: 35,
        width: 200,
        margin: 23,
        color: '#fff',
        fontSize: 14,
        padding: theme.spacing(.5, 8),

        "&:hover": {
            background: '#3C3C3C',
        }
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    stepOne: {
        padding: '15px 15px 40px'
    },
    stepHead: {
        color: '#282D30',
        fontSize: 18,
        margin: 0,
        marginBottom: 10
    },
    stepSubHead: {
        color: '#4B4B4B',
        fontSize: 12,
        margin: 0
    },
    stepTwoLeft: {
        background: '#20253A',
        boxShadow: '4px 0px 4px #2B2D3703',
        minWidth: 278,
        width: 278
    },
    stepTwoSection: {
        display: 'flex'
    },
    menu: {
        marginTop: theme.spacing(5),
        marginRight: 13,
        "& .step-line": {
            borderLeft: "2px solid #4A87F8"
        },
        "& .step-hideIt": {
            boxShadow: 'none',
            visibility: 'visible !important',
            backgroundImage: `url(${whiteTick})`
        },
        "& .step-circle": {
            border: '1px solid #4A87F8',
            background: '#202639'
        },
        "& .step-completed .step-circle": {
            backgroundColor: '#4A87F8'
        },
        "& [class*=MuiTypography-root]": {
            fontSize: 13,
            textAlign: 'left'
        }
    },
    stepRight: {
        padding: "0 50px 50px 50px",
        marginTop: 50,
        textAlign: 'left',
        minHeight: 600,
        width: 775,
        position: 'relative'
    },
    innterText: {
        color: '#282D30',
        fontSize: 14,
        lineHeight: 5
    },
    textSection: {
        marginTop: 40,
        lineHeight: 1.5
    },
    dropdown: {
        position: 'absolute',
        // display: 'flex',
        // flexDirection: 'column',
        boxShadow: '0px 3px 6px #00000029',
        padding: 10,
        top: 20,
        left: 2,
        overflow: 'auto',
        background: '#fff',
        zIndex: 2,
        maxHeight: 300,
        minWidth: 140
    },
    inputField: {
        width: '100%',
        border: 'none',
        height: 21,
        position: 'absolute',
        color: '#282D30',
        fontSize: 14,
        top: -6,
        textAlign: 'left',
        zIndex: 2,
        paddingTop: 5,
        "&:focus": {
            outline: 'none'
        }
    },
    list: {
        fontSize: 14,
        color: '#282D30',
        padding: '5px 3px',
        marginBottom: 2,
        cursor: 'pointer',
        display: 'block'
    },
    dropdownSection: {
        display: 'inline-block',
        borderBottom: "2px solid #398AF5",
        position: 'relative',
        margin: '0 11px',
        lineHeight: 1.2,
        top: 2,
        cursor: 'pointer'
    },
    improveWidth: {
        minWidth: 200
    },
    sizeWidth: {
        width: 140
    },
    platformWidth: {
        width: 108
    },
    planWidth: {
        width: 182
    },
    fillText: {
        width: '100%',
        textAlign: 'center',
        color: '#282D30',
        display: 'inline-block',
        opacity: 0

    },
    fillSection: {
        overflow: 'hidden',
        display: "inline-block",
        width: '100%',
        lineHeight: 1
    },
    stepTwoBtn: {
        position: "absolute",
        bottom: 10,
        right: 0,
    },
    arrowForward: {
        fontSize: 18,
        marginLeft: 3
    },
    nextBtn: {
        width: 'inherit',
        padding: '0 15px',
        borderRadius: 55,
        minWidth:100,
        '&:disabled': {
            color: '#fff',
            opacity: 0.5
        }
    },
    head: {
        color: '#282D30',
        fontSize: 14,
        marginBottom: 15
    },
    featureLabel: {
        border: '1px solid #4A87F8',
        borderRadius: 20,
        padding: '7px 16px',
        marginRight: 25,
        marginTop: 18,
        color: '#4A87F8',
        display: 'inline-flex',
        fontSize: 13,
        cursor: 'pointer',
        transition: 'all'
    },
    iconEle: {
        fontSize: 19,
        marginLeft: 10
    },
    selectedLabel: {
        background: '#4A87F8',
        color: '#fff',
        cursor: 'default'
    },
    removeIcon: {
        display: 'inline-flex',
        cursor: 'pointer',
        marginLeft: 10,
        "& [class*=iconEle]": {
            marginLeft: 0,
        }
    },
    features: {
        overflow: 'auto',
        height: 200,
        marginBottom: 30
    },
    imageProduct: {
        marginBottom: 20
    },
    sizeField: {
        // top: 1
    },
    backdrop: {
        textAlign: "center",
        marginTop: 10,
        minHeight: 100,
    },
    subLabel: {
        fontSize: 10,
        position: 'absolute',
        width: 193,
        paddingTop: 5
    },
    beta: {
        position: 'absolute',
        right: 0,
        top: -1,
    },
    image: {
        width: theme.spacing(3.5),
        marginRight: 20,
        marginTop: 5,
        cursor: 'pointer'
    },
    headSection: {
        display: 'flex',
        alignItems: 'flex-start'
    }
});

class NewEvaluationDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            selectedCard: -1,
            stepTwo: true,
            isImproveDropdown: true,
            improve: '',
            isSizeDropdown: false,
            size: '',
            isPlatformDropdown: false,
            platform: '',
            isPlanDropdown: false,
            plan: '',
            stepThree: false,
            listOfFeature: [],
            selectedFeatureList: [],
            page: 0,
            improveId: '',
            improveQuery: '',
            sizeQuery: '',
            featureQuery: '',
            featureSize: 10,
            featurePage: 0,
            isLoadingFeatures: false,
            isFinalLoader:false,
            text: "",
            types: ["subcategory_with_feature"]
        }
    }


    componentDidMount() {

    }

    fetchData() {
        //source=evaluation-home
        const {types,text} = this.state;
        this.props.searchAppProductCategory({types: types,text: text});
        // this.props.fetchProductSubCategoryV2({
        //     "text": this.state.improveQuery,
        //     "types": ["subcategory"]
        // });
    }

    componentDidUpdate(prevProps) {
        if (this.props.evaluate?.addNewEvaluationDialogOpen === true && prevProps.evaluate?.addNewEvaluationDialogOpen === false) {
            this.setState({ stepTwo: this.props.evaluate?.stepTwo || false, stepThree: false, selectedCard: -1, isImproveDropdown: true, improveQuery: '', improve: '', improveId: '', isSizeDropdown: false, size: '', sizeQuery: '', isPlanDropdown: false, platform: '', plan: '', listOfFeature: [], selectedFeatureList: [] }, () => { this.fetchData() })
        }

        if (!this.props.fetchSubCategoryFeaturesProgress && prevProps.fetchSubCategoryFeaturesProgress) {
            let listOfFeature = []
            if (this.props.subCategoryFeatures && Array.isArray(this.props.subCategoryFeatures) && this.props.subCategoryFeatures.length > 0) {
                listOfFeature = this.props.subCategoryFeatures
            }
            this.setState({
                listOfFeature,
                isLoadingFeatures: false,
                stepThree: true
            }, () => {
                if (listOfFeature === null || listOfFeature.length <= 0) {
                    this.props.hideAddNewEvaluationDialog()
                    setTimeout(() => this.props.history.push(`/app/products/recommended-products?subcategory=${this.state.improveId}&user=${this.state.size}&featureId=${this.state.selectedFeatureList.map((e) => e.ID)}`), 100);
                }
            })
        }

    }

    fetchFeatures = () => {
        // console.log('-------------------------')
        // console.log(this.state.improveId, 'improveId')
        // console.log(this.state.featurePage, 'featurePage')
        // console.log(this.state.featureQuery, 'featureQuery')
        this.setState({
            isLoadingFeatures: true
        }, () => {
            this.props.fetchSubCategoryFeature(this.state.improveId, this.state.featurePage, this.state.featureSize, this.state.featureQuery);
        })
    }

    nextStep() {
        // console.log(this.state.selectedCard, this.state.stepTwo);
        // console.log(this.state.improveQuery, 'improveQuery')
        if (this.state.selectedCard === 1) {
            this.props.hideAddNewEvaluationDialog()
            setTimeout(() => this.props.history.push("/app/products/all-products"), 100);
        } else if (this.state.selectedCard === 0 && !this.state.stepTwo) {
            this.fetchData();
            this.setState({ stepTwo: true })
        } else if (this.state.stepThree) {
            this.setState({
                isFinalLoader:true
            },()=>{
                this.props.hideAddNewEvaluationDialog()
                setTimeout(() => this.props.history.push(`/app/products/recommended-products?subcategory=${this.state.improveId}&user=${this.state.size}&featureId=${this.state.selectedFeatureList.map((e) => e.ID)}`), 100);
            })
        } else if (this.state.stepTwo) {
            this.setState({ page: 0, listOfFeature: [] })
            if (this.state.improveId) {
                this.fetchFeatures();
            } else {
                this.props.hideAddNewEvaluationDialog()
                setTimeout(() => this.props.history.push(`/app/products/recommended-products?subcategory=${this.state.improveId}&user=${this.state.size}&featureId=${this.state.selectedFeatureList.map((e) => e.ID)}`), 100);
            }
            // this.props.hideAddNewEvaluationDialog()
        }
    }

    setValue(e, value) {
        e.stopPropagation();
        console.log(value)
        this.setState(value)
    }

    removeFeature = (index) => {
        let listOfFeature = this.state.listOfFeature;
        let selectedFeatureList = this.state.selectedFeatureList;
        listOfFeature.push(selectedFeatureList[index]);
        selectedFeatureList.splice(index, 1);
        this.setState({ selectedFeatureList: selectedFeatureList, listOfFeature: listOfFeature });
    }

    selectFeature = (index) => {
        let listOfFeature = this.state.listOfFeature;
        let selectedFeatureList = this.state.selectedFeatureList;
        selectedFeatureList.push(listOfFeature[index]);
        listOfFeature.splice(index, 1);
        this.setState({ selectedFeatureList: selectedFeatureList, listOfFeature: listOfFeature });
    }

    render() {

        const classes = this.props.classes;
        const cardList = [{ label: 'Want Expent to recommend products?', subLabel: '', link: '', image: findProduct }, { label: 'I will manually browse and select products', subLabel: '', link: '', image: knewProduct }];

        // const improveList = [{label: 'Customer support'}, {label: 'Security'}, {label:'Collab'}, {label:'Marketing'}, {label:'CRM'}, {label:'SEO'}]; 

        const sizeList = [{ label: '0-50' }, { label: '50-500' }, { label: '500+' }];
        //0-50, 50-500, 500+ 
        // const platformList = [{label: 'Cloud'}, {label: 'Self hosted'}];

        // const planList = [{label: 'Monthly subscription'}, {label: 'Free trial'}, {label: 'Annual subscription'}, {label: 'Life time'}, {label: 'Subscription'}];

        var stepTwoActive = true;
        var stepThreeActive = false;
        var completedActive = false;
        if (stepTwoActive && this.state.stepThree) {
            stepTwoActive = true;
            stepThreeActive = true;
        }

        var searchResults = this.props.appProductCategories?.items || [];

        return <Dialog
            onClose={this.props.hideAddNewEvaluationDialog}
            aria-labelledby="new-evaluation-dialog"
            open={this.props.evaluate?.addNewEvaluationDialogOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={this.state.stepTwo === false ? 'sm' : 'md'}
            scroll={"body"}
            className={classnames(classes.dialog)}
        >
            <DialogContent classes={{ root: classes.dialogContent }}>
                <div className={classes.close} onClick={() => this.props.hideAddNewEvaluationDialog()}><CloseIcon /></div>
                {this.state.stepTwo === false && <div className={classes.stepOne}>
                    <p className={classes.title}>Add New Evaluation</p>
                    <p className={classes.subTitle}></p>
                    <div className={classes.alert}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                    </div>
                    <div className={classes.cardSection}>

                        {cardList.map((ele, index) => <div key={index} className={classnames(classes.card, this.state.selectedCard === index ? 'active' : '')} onClick={() => {
                            this.setState({ selectedCard: index }, () => {
                                this.nextStep();
                            })
                        }}> {index === 0 && <img src={Beta} alt="beta" className={classes.beta} />}
                            <Image alt="product" className={classes.imageProduct} src={ele.image} />
                            <p className={classes.cardLabel}>{ele.label}</p>
                            <p className={classnames(classes.cardLabel, classes.subLabel)}>{ele.subLabel}</p>
                        </div>)}
                    </div>
                    {/* <div className={classes.textCenter}>
                                    <Button className={classes.applyBtn} onClick={() => this.nextStep()}>Continue</Button>
                                </div> */}
                </div>}
                {this.state.stepTwo === true &&
                    <div className={classes.stepTwoSection}>
                        <div className={classes.stepTwoLeft}>
                            <div className={classes.menu}>
                                <Steper>
                                    <Step completed={stepTwoActive} label={"Tell us about the problem"} isFirst={true} circle={stepTwoActive && stepThreeActive === false} />
                                    <Step completed={stepThreeActive} label={"Tell us about the features"} circle={stepThreeActive && !completedActive} />
                                </Steper>
                            </div>
                        </div>

                        {this.state.stepThree === false && <div className={classes.stepRight}>
                            <div className={classes.headSection}>
                                <Image src={ArrowBack} alt="arrow_back" className={classes.image} onClick={() => { this.setState({ stepTwo: false }) }} />
                                <div>
                                    <p className={classes.stepHead}>I need help in finding right products </p>
                                    <p className={classes.stepSubHead}>Answer the questions and we will curate the products for you</p>
                                </div>
                            </div>
                            <div className={classes.textSection}>
                                <span className={classes.innterText}>I want to improve</span>
                                <span className={classnames(classes.dropdownSection, classes.improveWidth)} onClick={() => this.setState({ isImproveDropdown: true, isSizeDropdown: false })}>
                                    <input className={classes.inputField} value={this.state.text} autoFocus onChange={(e) => {
                                        this.setState({
                                            text: e.target.value,
                                            improveId: '',
                                            page: 0
                                        }, () => {
                                            this.fetchData();
                                        })
                                    }} />
                                    <span className={classes.fillSection}>
                                        {this.state.improve !== '' && <span className={classnames(!this.state.isImproveDropdown ? 'animate__animated animate__slideInUp animate__faster' : '', classes.fillText)}>{this.state.improve}</span>}
                                    </span>
                                    {this.state.isImproveDropdown === true && searchResults.length > 0  && <div className={classnames(classes.dropdown)}>
                                        {searchResults.map((element, index) => <span key={index} className={classnames(classes.list, 'animate__animated animate__slideInUp animate__faster')}
                                            onClick={(e) => this.setValue(e, {
                                                improve: element.name,
                                                text: element.name,
                                                improveId: parseInt(element.id),
                                                isImproveDropdown: false,
                                                selectedFeatureList: []
                                            })}>{element.name}</span>)
                                        }
                                    </div>}
                                    {this.state.improveQuery !== '' && searchResults.length === 0 && !this.props.fetchAppProductCategoryProgress && !this.props.fetchAppProductCategoryError && this.state.isImproveDropdown === true && 
                                        <div className={classnames(classes.dropdown)}>
                                            <span className={classnames(classes.list, 'animate__animated animate__slideInUp animate__faster')}>Nothing found</span>
                                        </div>
                                    }
                                </span>
                                {this.state.improve !== '' && <span className={classes.innterText}>for my organization of
                                    <span className={classnames(classes.dropdownSection, classes.sizeWidth)} onClick={() => this.setState({ isSizeDropdown: true, isImproveDropdown: false })}> <span className={classes.fillSection}>
                                        <input className={classnames(classes.inputField, classes.sizeField)} readOnly value={this.state.sizeQuery} onChange={(e) => {
                                            this.setState({ sizeQuery: e.target.value }, () => {
                                                this.fetchData();
                                            })
                                        }} />
                                        {this.state.size !== '' && <span className={classnames(!this.state.isSizeDropdown ? 'animate__animated animate__slideInUp animate__faster' : '', classes.fillText)}>{this.state.size}</span>}</span>
                                        {this.state.isSizeDropdown === true && <div className={classnames(classes.dropdown)}>
                                            {sizeList.map((element, index) => <span key={index} className={classnames(classes.list, 'animate__animated animate__slideInUp animate__faster')} onClick={(e) => this.setValue(e, { size: element.label, sizeId: element.label, sizeQuery: element.label, isSizeDropdown: false })}>{element.label}</span>)}
                                        </div>}
                                    </span>
                                </span>
                                }
                                {/* { this.state.improve !== '' && this.state.size !== '' && <span className={classes.innterText}>I'm looking for a 
                                            <span className={classnames(classes.dropdownSection, classes.platformWidth)} onClick={()=> this.setState({isPlatformDropdown: true})}> <span className={classes.fillSection}>
                                                { this.state.platform !== '' && <span className={classnames(!this.state.isPlatformDropdown ? 'animate__animated animate__slideInUp animate__faster': '', classes.fillText)}>{this.state.platform}</span> }</span>
                                                    {this.state.isPlatformDropdown === true && <div className={classnames(classes.dropdown)}> 
                                                    { platformList.map((element, index) => <span key={index} className={classnames(classes.list, 'animate__animated animate__slideInUp animate__faster')} onClick={(e)=> this.setValue(e, {platform: element.label, isPlatformDropdown: false })}>{element.label}</span>) }
                                                </div> }
                                            </span>
                                        </span>
                                        } */}
                                {/* { this.state.improve !== '' && this.state.size !== '' && this.state.platform !== '' && <span className={classes.innterText}>solution that offers
                                            <span className={classnames(classes.dropdownSection, classes.planWidth)} onClick={()=> this.setState({isPlanDropdown: true})}> <span className={classes.fillSection}>
                                                { this.state.plan !== '' && <span className={classnames(!this.state.isPlanDropdown ? 'animate__animated animate__slideInUp animate__faster': '', classes.fillText)}>{this.state.plan}</span> }</span>
                                                    {this.state.isPlanDropdown === true && <div className={classnames(classes.dropdown)}> 
                                                    { planList.map((element, index) => <span key={index} className={classnames(classes.list, 'animate__animated animate__slideInUp animate__faster')} onClick={(e)=> this.setValue(e, {plan: element.label, isPlanDropdown: false })}>{element.label}</span>) }
                                                </div> }
                                            </span>
                                        </span>
                                        } */}
                            </div>
                            <div className={classes.stepTwoBtn}>
                                <Button className={classnames(classes.applyBtn, classes.nextBtn)} onClick={() => this.nextStep()}>
                                    {this.state.isLoadingFeatures ? <CircularProgress style={{width:27,height:27}}/> : 'Next'} <ArrowForwardIcon className={classes.arrowForward} /></Button>
                            </div>
                        </div>}
                        {this.state.stepThree === true && <>
                            {this.state.isLoadingFeatures && <div style={{ width:'100%',textAlign: 'center', marginTop: 20 }}>
                                <CircularProgress />
                            </div>}
                            {!this.state.isLoadingFeatures && <div className={classes.stepRight}>
                                <div className={classes.headSection}>
                                    <Image src={ArrowBack} alt="arrow_back" className={classes.image} onClick={() => this.setState({ stepThree: false })} />
                                    <div>
                                        <p className={classes.stepHead}>I need help in finding right products </p>
                                        <p className={classes.stepSubHead}>Select features that you care about to see recommended products.</p>
                                    </div>
                                </div>
                                <div className={classes.textSection}>
                                    <p className={classes.head}>Selected Features</p>
                                    <div className={classes.features}>
                                        {this.state.selectedFeatureList.map((element, index) => <span key={index} className={classnames(classes.featureLabel, classes.selectedLabel, 'animate__animated animate__backInUp animate__faster')}>{element?.Name} <span onClick={() => this.removeFeature(index)} className={classes.removeIcon}><CloseIcon className={classes.iconEle} /></span></span>)}
                                    </div>
                                </div>
                                <div className={classes.textSection}>
                                    <p className={classes.head}>List of Features</p>
                                    <div className={classes.features} id="featuresList">
                                        <InfiniteScroll
                                            scrollableTarget="featuresList"
                                            dataLength={this.state.listOfFeature?.length} //This is important field to render the next data
                                            next={() => {
                                                var me = this;
                                                this.setState({ featurePage: this.state.featurePage + 1 }, () => {
                                                    me.fetchFeatures();
                                                });
                                            }}
                                            hasMore={
                                                this.state.listOfFeature?.length ===
                                                (this.state.featurePage + 1) * this.state.featureSize
                                            }
                                            endMessage={
                                                <p style={{ textAlign: "center" }}>
                                                    {this.state.listOfFeature?.length === 0 && this.props.fetchSubCategoryFeaturesProgress === false && <b>No More Data</b>}
                                                </p>
                                            }
                                        >
                                            {this.state.listOfFeature.map((element, index) => <span key={index} className={classnames(classes.featureLabel, 'animate__animated animate__backInDown animate__faster')} onClick={() => this.selectFeature(index)}>{element.Name} <CheckIcon className={classes.iconEle} /></span>)}
                                        </InfiniteScroll>
                                        {this.props.fetchSubCategoryFeaturesProgress === true && this.state.listOfFeature?.length === 0 && (
                                            <div className={classes.backdrop}>
                                                <CircularProgress />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.stepTwoBtn}>
                                    <Button className={classnames(classes.applyBtn, classes.nextBtn)} onClick={() => this.nextStep()} disabled={this.state.selectedFeatureList.length === 0}>
                                    {this.state.isFinalLoader ? <CircularProgress style={{width:27,height:27}}/> : 'Next'}  <ArrowForwardIcon className={classes.arrowForward} /></Button>
                                </div>
                            </div>}
                        </>
                        }
                    </div>
                }

            </DialogContent>
        </Dialog>
    }
}


export default connector(compose(
    withRouter,
    withStyles(styles)
)(NewEvaluationDialog));