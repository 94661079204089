import {
    STORE_SEARCH_STRING,
    START_APPLICATION_FETCH,
    END_APPLICATION_FETCH,
    START_APPLICATION_FETCHINPUT,
    END_APPLICATION_FETCHINPUT,
    SHOW_APPLICATION_FETCHINPUT_DIALOG,
    HIDE_APPLICATION_FETCHINPUT_DIALOG,
    START_APPLICATION_UPDATEINPUT,
    END_APPLICATION_UPDATEINPUT,
    START_APPLICATION_FETCHCONFIG,
    END_APPLICATION_FETCHCONFIG,
    START_REMOVE_APPLICATION,
    END_REMOVE_APPLICATION,
    SHOW_MESSAGE_DIALOG,
    HIDE_MESSAGE_DIALOG,
    TOGGLE_EVALUATE_VIEW,
    TOGGLE_EVALUATE_NEVER_REVIEW,
    UPDATE_CRITERIA_DYNAMIC_HEIGHT,
    SHOW_ADD_PRODUCT_SELLER_EMAIL_DIALOG,
    HIDE_ADD_PRODUCT_SELLER_EMAIL_DIALOG,
    START_APPLICATION_FETCHCONFIG_DETAILS,
    END_APPLICATION_FETCHCONFIG_DETAILS,
    START_APPLICATION_FETCH_SYNC_STATUS,
    END_APPLICATION_FETCH_SYNC_STATUS,
    START_SYNC_APPLICATION,
    END_SYNC_APPLICATION,
    SHOW_RES_MESSAGE_DIALOG, HIDE_RES_MESSAGE_DIALOG,
    SHOW_CONFIRM_MESSAGE_DIALOG,
    HIDE_CONFIRM_MESSAGE_DIALOG,
    START_APPLICATIONS_FETCH,
    END_APPLICATIONS_FETCH,
    START_GROUPS_FETCH,
    END_GROUPS_FETCH,
    SHOW_APPLICATION_GROUP_DIALOG,
    HIDE_APPLICATION_GROUP_DIALOG,
    SHOW_APPLICATION_USER_DIALOG,
    HIDE_APPLICATION_USER_DIALOG,
    SHOW_GROUP_USER_DIALOG,
    HIDE_GROUP_USER_DIALOG,
    SHOW_SYNC_HISTORY_DIALOG,
    HIDE_SYNC_HISTORY_DIALOG,
    START_SYNC_HISTORY_FETCH,
    END_SYNC_HISTORY_FETCH,
    SHOW_APPLICATION_EDIT_PREFERENCE_SYNC_DIALOG,
    HIDE_APPLICATION_EDIT_PREFERENCE_SYNC_DIALOG,
    START_SYNC_PREFERENCE_UPDATE, END_SYNC_PREFERENCE_UPDATE
} from "./action";

const initState = {
    criteria_requrement_height: {},
    isProgress: false,
    error: null,
    apps: [],
    showInputForm: false,
    inputFormApp: null,
    fetchAppInputProgress: false,
    fetchAppInputError: null,
    appInputForm: null,
    updateProgress: false,
    updateError: null,
    messageDialogOpen: false,
    searchString: '',
    evaluateView: false,
    evaluateViewId: null,
    evaluateNeverReview: false,
    evaluateNeverReviewId: null,
    dialogProductSellerEmail: false,
    resMessageDialogOpen: false,
    confirmMessageDialogOpen: false,

    appConfigDetails: null,
    appConfigDetailsProgress: false,
    appConfigDetailsError: null,

    appConfigSyncStatus: null,
    appConfigSyncStatusProgress: false,
    appConfigSyncStatusError: null,

    appConfigSyncSuccess: null,
    appConfigSyncProgress: false,
    appConfigSyncError: null,

    appsData: [],
    appsFetchProgress: false,
    appsFetchError: null,

    groupsData: [],
    groupsFetchProgress: false,
    groupsFetchError: null,

    showSyncHistoryDialog: false,
    showGroupUserDialog: false,
    showApplicationUserDialog: false,
    showApplicationGroupDialog: false,

    fetchSyncHistoryProgress: false,
    syncHistory: [],
    totalSync: 0,

    showEditPreferenceDialog: false,
    editPreferenceForApp: null,

    syncPreferenceUpdateProgress:false,
    syncPreferenceUpdateError:null,
    syncPreferenceUpdateSuccess:null,
}

export function applicationReducer(state = initState, action) {
    switch (action.type) {
        case START_SYNC_HISTORY_FETCH:
            var syncHistory = state.syncHistory
            return {
                ...state,
                fetchSyncHistoryProgress: true,
                syncHistory: syncHistory
            };

        case END_SYNC_HISTORY_FETCH:
            var success = action.payload.success;
            var syncHistoryNew = [...state.syncHistory];
            if (success) {
                syncHistoryNew = success.Data;
            }
            return {
                ...state,
                fetchSyncHistoryProgress: false,
                syncHistory: syncHistoryNew,
                totalSync: success?.total || 0
            };

        case STORE_SEARCH_STRING:
            return {
                ...state,
                searchString: action.payload.str,
                searchType: action.payload.searchType
            };

        case UPDATE_CRITERIA_DYNAMIC_HEIGHT: {
            let data = {
                ...state.criteria_requrement_height
            };
            let isExpanded = action.payload.isExpanded;
            let id = action.payload.id;
            let height = action.payload.height;
            // console.log('isExpanded',isExpanded, height)

            data[id] = height;

            if (!isExpanded) delete data[id];

            return {
                ...state,
                criteria_requrement_height: data
            };
        }

        case START_APPLICATION_FETCH:
            return {
                ...state,
                isProgress: true,
                error: null,
            };
        case END_APPLICATION_FETCH:
            var applications = action.payload.success == null ? [] : action.payload.success.Data;
            var page = action.payload.success == null ? 0 : action.payload.success.page;

            var exisitingApplications = [...state.apps];
            if (page === 0) {
                exisitingApplications = applications
            } else {
                exisitingApplications = exisitingApplications.concat(applications)
            }
            return {
                ...state,
                isProgress: false,
                error: action.payload.error,
                apps: exisitingApplications
            }

        case START_APPLICATIONS_FETCH:
            return {
                ...state,
                appsData: state.appsData,
                appsFetchProgress: true,
                appsFetchError: null,
            };
        case END_APPLICATIONS_FETCH:
            return {
                ...state,
                appsData: action.payload.success,
                appsFetchProgress: false,
                appsFetchError: action.payload.error,
            }
        case START_GROUPS_FETCH:
            return {
                ...state,
                groupsData: state.groupsData,
                groupsFetchProgress: true,
                groupsFetchError: null,
            };
        case END_GROUPS_FETCH:
            return {
                ...state,
                groupsData: action.payload.success,
                groupsFetchProgress: false,
                groupsFetchError: action.payload.error,
            }
        case START_APPLICATION_FETCHCONFIG_DETAILS:
            return {
                ...state,
                appConfigDetails: null,
                appConfigDetailsProgress: true,
                appConfigDetailsError: null,
            };
        case END_APPLICATION_FETCHCONFIG_DETAILS:
            return {
                ...state,
                appConfigDetails: action.payload.success,
                appConfigDetailsProgress: false,
                appConfigDetailsError: action.payload.error,
            }
        case START_APPLICATION_FETCH_SYNC_STATUS:
            return {
                ...state,
                appConfigSyncStatus: null,
                appConfigSyncStatusProgress: true,
                appConfigSyncStatusError: null,
            };
        case END_APPLICATION_FETCH_SYNC_STATUS:
            return {
                ...state,
                appConfigSyncStatus: action.payload.success,
                appConfigSyncStatusProgress: false,
                appConfigSyncStatusError: action.payload.error,
            }
        case START_SYNC_APPLICATION:
            return {
                ...state,
                appConfigSyncSuccess: null,
                appConfigSyncProgress: true,
                appConfigSyncError: null,
            };
        case END_SYNC_APPLICATION:
            return {
                ...state,
                appConfigSyncSuccess: action.payload.success,
                appConfigSyncProgress: false,
                appConfigSyncError: action.payload.error,
            }
        case START_APPLICATION_FETCHINPUT:
            return {
                ...state,
                fetchAppInputProgress: true,
                fetchAppInputError: null,
                appInputForm: null
            };
        case END_APPLICATION_FETCHINPUT:
            return {
                ...state,
                fetchAppInputProgress: false,
                fetchAppInputError: action.payload.error,
                appInputForm: action.payload.success
            };
        case SHOW_SYNC_HISTORY_DIALOG:
            return {
                ...state,
                showSyncHistoryDialog: true,
            };
        case HIDE_SYNC_HISTORY_DIALOG:
            return {
                ...state,
                showSyncHistoryDialog: false,
            };
        case SHOW_GROUP_USER_DIALOG:
            return {
                ...state,
                showGroupUserDialog: true,
            };
        case HIDE_GROUP_USER_DIALOG:
            return {
                ...state,
                showGroupUserDialog: false,
            };
        case SHOW_APPLICATION_USER_DIALOG:
            return {
                ...state,
                showApplicationUserDialog: true,
            };
        case HIDE_APPLICATION_USER_DIALOG:
            return {
                ...state,
                showApplicationUserDialog: false,
            };
        case SHOW_APPLICATION_GROUP_DIALOG:
            return {
                ...state,
                showApplicationGroupDialog: true,
            };
        case HIDE_APPLICATION_GROUP_DIALOG:
            return {
                ...state,
                showApplicationGroupDialog: false,
            };

        case SHOW_APPLICATION_FETCHINPUT_DIALOG:
            return {
                ...state,
                showInputForm: true,
                inputFormApp: action.payload.app,
            };
        case HIDE_APPLICATION_FETCHINPUT_DIALOG:
            return {
                ...state,
                showInputForm: false,
                inputFormApp: null,
            };


        case SHOW_APPLICATION_EDIT_PREFERENCE_SYNC_DIALOG:
            return {
                ...state,
                showEditPreferenceDialog: true,
                editPreferenceForApp: action.payload.app,
            };

        case HIDE_APPLICATION_EDIT_PREFERENCE_SYNC_DIALOG:
            return {
                ...state,
                showEditPreferenceDialog: false,
                editPreferenceForApp: null,
            };

        case START_APPLICATION_UPDATEINPUT:
            return {
                ...state,
                updateProgress: true,
                error: null,
            };
        case END_APPLICATION_UPDATEINPUT:
            var updateConfig = action.payload.success;
            var apps = state.apps;
            if (updateConfig !== null) {
                var currentApp = state.inputFormApp;
                apps = [];
                state.apps.forEach((app) => {
                    if (app.ID == currentApp.ID) {
                        app.currentConfig = updateConfig
                    }
                    apps.push(app)
                })
            }
            return {
                ...state,
                updateProgress: false,
                updateError: action.payload.error,
                apps: apps
            };
        case START_APPLICATION_FETCHCONFIG:
            var currentApp = action.payload.app;
            var apps = state.apps;
            if (currentApp !== undefined && currentApp !== null) {
                apps = [];
                state.apps.forEach((app) => {
                    if (app.ID == currentApp.ID) {
                        app.isFetching = true
                    }
                    apps.push(app)
                })
            }
            return {
                ...state,
                apps: apps,
                isProgress: true,
            };
        case END_APPLICATION_FETCHCONFIG:
            var result = action.payload.success;
            var apps = state.apps;
            if (result !== null) {
                var app = result['app'];
                var data = result['data'];
                if (app !== undefined && app !== null) {
                    apps = [];
                    state.apps.forEach((_app) => {
                        if (_app.ID == app.ID) {
                            app.isFetching = false
                            app.currentConfig = data;
                            apps.push(app)
                        } else {
                            apps.push(_app)
                        }

                    })
                }
            }

            return {
                ...state,
                apps: apps,
                isProgress: false,
            };
        case START_REMOVE_APPLICATION:
            return {
                ...state,
                removeProgress: true
            };

        case END_REMOVE_APPLICATION:
            var result = action.payload.success;
            var apps = state.apps;
            if (result !== null) {
                var app = result['app'];
                if (app !== undefined && app !== null) {
                    apps = [];
                    state.apps.forEach((_app) => {
                        if (_app.ID == app.ID) {
                            app.currentConfig = null;
                            apps.push(app)
                        } else {
                            apps.push(_app)
                        }

                    })
                }
            }

            return {
                ...state,
                removeProgress: false,
                apps: apps
            };

        case SHOW_MESSAGE_DIALOG:
            return {
                ...state,
                messageDialogOpen: true
            };
        case HIDE_MESSAGE_DIALOG:
            return {
                ...state,
                messageDialogOpen: false
            };

        case SHOW_RES_MESSAGE_DIALOG:
            return {
                ...state,
                resMessageDialogOpen: true
            };

        case HIDE_RES_MESSAGE_DIALOG:
            return {
                ...state,
                resMessageDialogOpen: false
            };
        case SHOW_CONFIRM_MESSAGE_DIALOG:
            return {
                ...state,
                confirmMessageDialogOpen: true
            };

        case HIDE_CONFIRM_MESSAGE_DIALOG:
            return {
                ...state,
                confirmMessageDialogOpen: false
            };

        case TOGGLE_EVALUATE_VIEW:
            return {
                ...state,
                evaluateView: action.payload.isView,
                evaluateViewId: action.payload.viewId
            }
        case TOGGLE_EVALUATE_NEVER_REVIEW:
            return {
                ...state,
                evaluateNeverReview: action.payload.isNeverReview,
                evaluateNeverReviewId: action.payload.neverReviewId
            }

        case SHOW_ADD_PRODUCT_SELLER_EMAIL_DIALOG:
            return {
                ...state,
                dialogProductSellerEmail: true
            };
        case HIDE_ADD_PRODUCT_SELLER_EMAIL_DIALOG:
            return {
                ...state,
                dialogProductSellerEmail: false
            };

        case START_SYNC_PREFERENCE_UPDATE: {
            return {
                ...state,
                syncPreferenceUpdateProgress:true,
                syncPreferenceUpdateError:null,
                syncPreferenceUpdateSuccess:null,
            }
        }

        case END_SYNC_PREFERENCE_UPDATE: {
            return {
                ...state,
                syncPreferenceUpdateProgress:false,
                syncPreferenceUpdateError:action.payload.error,
                syncPreferenceUpdateSuccess:action.payload.success,
            }
        }
        default:
            return state;
    }
}