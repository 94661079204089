import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';
import classnames from "classnames";
import { DragDropContext, Droppable, Draggable, } from "react-beautiful-dnd";

import { withStyles, createStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import { Checkbox, Divider, Grid, Typography, Popover, Collapse, IconButton } from '@material-ui/core';

import deleteIcon from "assets/images/delete.svg";
import Image from 'Components/Common/image.jsx';
// import AddApprover from 'Components/Common/UserGroup/AddApprover';
import UserGroup from 'Components/Common/UserGroup';


import { showEvaluationRequirementAddDialog } from "redux/evaluation/criteria/action"
import {
    showAddTemplateDialog,
    selectEvaluationTemplateCriteria,
    unselectEvaluationTemplateCriteria,
    addCriteriaSelection
} from "redux/templates/evaluation/action";
import { showCriteriaRuleDialog } from "redux/common/action";

import Requirement from './Requirement'
const connectedProps = (state) => ({
    user: state.authUser.user,
    importCriteriaIds: state.templateEvaluation.criteriaIds,
    criteriaSelection: state.templateEvaluation.criteriaSelection
});

const connectionActions = {
    showTemplateRequirementAddDialog: showEvaluationRequirementAddDialog,
    showAddTemplateDialog: showAddTemplateDialog,
    showCriteriaRuleDialog: showCriteriaRuleDialog,
    selectEvaluationTemplateCriteria: selectEvaluationTemplateCriteria,
    unselectEvaluationTemplateCriteria: unselectEvaluationTemplateCriteria,
    addCriteriaSelection: addCriteriaSelection
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        borderRadius: 5,
        marginBottom: theme.spacing(1),
        position: 'relative',
        backgroundColor: '#FFFFFF',
        paddingBottom: 10,
    },
    root1: {
        borderRadius: 5,
        marginBottom: theme.spacing(1),
        position: 'relative',
        backgroundColor: '#FFFFFF',
        paddingBottom: 10,
        border: '1px solid #f1f1f1',
    },
    btns: {
        "& button": {
            boxShadow: '4px 0px 4px #2B2D3703',
            border: '1px solid #20253a33',
            background: 'transparent',
            borderRadius: '6px',
            opacity: 1,
            color: '#707070',
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'rgba(75, 134, 255, 0.04)'
            }
        }
    },
    criteriaHead: {
        justifyContent: 'space-between',
    },
    headPadding: {
        padding: '18px 22px 18px 0px!important',
        cursor: "pointer",
    },
    criteriaExtended: {
        borderBottom: '2px solid #EEEEEE;'
    },
    expand: {
        transform: 'rotate(0deg)',
        padding: 0,
        borderRadius: 3,
        border: '1px solid #EDEDED',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    titleTxt: {
        fontSize: theme.spacing(2),
        color: '#282D30',
        fontWeight: 600,
        padding: 5,
        borderRadius: 3,
        width: 'max-content',
        '& > img': {
            display: 'none'
        },
        '&:hover': {
            backgroundColor: 'rgb(235, 236, 240)'
        }
    },
    actionTxt: {
        color: '#4175DF',
        // width: 'max-content',
        fontSize: theme.spacing(1.75),
        padding: '0px 5px',
        // width: 100
    },
    menuSection: {
        display: 'flex',
        "& > [class*=MuiSvgIcon-root]": {
            fill: '#282D30',
            height: 20
        },
        marginLeft: 10,
    },
    checkboxSection: {
        paddingRight: 15,
    },
    checkbox: {
        border: 'none',
        margin: 0
    },
    divider: {
        width: '100%',
        textAlign: 'center',
        borderBottom: '1px solid #FAFAFA',
        lineHeight: '0.1em',
        margin: '10px 0 20px',
        '& span': {
            background: '#fff',
            color: '#A0BAEF',
            padding: '0 10px',
            fontSize: theme.spacing(1.75)
        }
    },
    editIcon: {
        color: "#999999"
    },
    hrLine: {
        width: '100%',
        textAlign: 'center',
        lineHeight: '0.1em',
        margin: '0 0 20px',
        borderBottom: '1px solid #49B8FA',
        paddingTop: 20,
        '& span': {
            padding: '3px 8px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxhadow: '4px 0px 4px #2B2D3703',
            border: '1px solid #A0BAEF',
            borderRadius: 4,
            opacity: 1,
            fontSize: theme.spacing(2),
            color: '#4175DF',
            fontWeight: 400,
            cursor: 'pointer'
        }
    },
    actionIcon: {
        width: 24,
        height: 24,
        borderRadius: 5,
        textAlign: 'center',
        marginLeft: 5,
        border: '1px solid #4b86f8'
    },
    micon: {
        fontSize: 16,
        marginTop: 3,
        color: '#4b86f8',
    },
    dragIconArea: {
        position: 'absolute',
        top: 15,
        left: '50%',
        color: '#D9D9D9',
        transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
    },
    requirementConainer: {
        padding: '18px 22px 0px 18px',
    },
    addRequirementAction: {
        padding: '0 22px',
    },
    visiblityNone: {
        visibility: 'hidden'
    },
    titleInput: {
        flex: 1,
        "& > [class*=MuiInputBase-input]": {
            padding: 5,
            fontSize: theme.spacing(1.8),
            color: '#282D30',
            fontWeight: 600,
        }
    },
    criteriaHeadActionItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right'
    },
    OutlinedInput: {
        minHeight: '25px !important',
        padding: 3,
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        color: '#5F5F5F',
        fontSize: 12,
    },
    priorityOutlinedInput: {
        minHeight: '25px !important',
        padding: 3,
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        color: '#5F5F5F',
        fontSize: 12,
        width: 75,
        textAlign: 'center'
    },
});

class Criteria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: [],
            isDefaultPage: false,
            anchorEl: null,
            name: '',
            name_error: "",
            isEditName: false,
            showCheckbox: false,
            isUpdateCriteriaPriority: false,
            priority: '',
            priority_error: ''
        }
        this.onDragEnd = this.onDragEnd.bind(this);
        this.showBottomNav = this.showBottomNav.bind(this)
    }

    componentDidMount() {
        this.setState({
            name: this.props.criteria?.Name,
            priority: this.props.criteria?.Priority === 0 ? '' : this.props.criteria?.Priority,
        })
    }

    componentDidUpdate(prevProps) {
        this.showBottomNav()

    }

    showBottomNav() {
        var status = false
        const keys = Object.keys(this.props.criteriaSelection)
        const element = document.getElementById('bottomDiv')
        if (element) {
            if (keys.length > 0) {
                keys.every((element) => {
                    if (this.props.criteriaSelection[element] && this.props.criteriaSelection[element].length > 0) {
                        status = true
                        return false
                    } else return true
                })
            }
            if (status) {
                element.style.display = 'flex'
            } else {
                element.style.display = 'none'
            }
        }
    }

    handleClick = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget || event.current });
    };

    handleClose = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: null });
    };

    toggleMandatory = (event, is_editable) => {
        const criteria = this.props.criteria;
        this.props.updateCriteria(criteria.TemplateId, criteria.ID, {
            is_mandatory: event.target.checked,
            is_editable
        })
    }

    toggleEditable = (event, is_mandatory) => {
        const criteria = this.props.criteria;
        this.props.updateCriteria(criteria.TemplateId, criteria.ID, {
            is_mandatory,
            is_editable: event.target.checked
        })
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const { destination, draggableId, source } = result;
        if (draggableId && destination.index !== source.index) {
            const criteria = this.props.criteria;
            this.props.updateRequirement(
                criteria.TemplateId,
                criteria.ID,
                parseInt(draggableId),
                {
                    "seq": destination.index
                }
            )
        }
    }

    cleanError() {
        this.setState({
            name_error: "",
        })
    }

    saveName(name, settingsTemplateId, settingsCriteriaId) {
        this.cleanError();
        if (name.trim().length === 0) {
            this.setState({
                name_error: "Please enter Section Name."
            });
            return;
        }

        this.props.updateCriteria(
            settingsTemplateId,
            settingsCriteriaId,
            {
                name: name,
            }
        )

        this.setState({
            isEditName: false
        });
    }


    updatePriority(criteriaId, templateId) {
        this.setState({
            priority_error: ''
        })
        if (this.state.priority <= 0 || this.state.priority > 100) {
            this.setState({
                priority_error: 'Please enter weightage between 1 to 100.'
            })
        } else {
            this.savePriority(
                this.state.priority,
                templateId,
                criteriaId,
            )
        }
    }

    savePriority(priority, settingsTemplateId, settingsCriteriaId) {
        this.cleanError();
        this.props.updateCriteria(settingsTemplateId, settingsCriteriaId, {
            priority: parseInt(this.state.priority)
        })
        this.setState({
            isUpdateCriteriaPriority: false
        });
    }

    render() {
        const classes = this.props.classes;
        if (this.props.criteria == null || this.props.criteria === undefined) {
            return null;
        }

        const criteria = this.props.criteria;

        const open = Boolean(this.state.anchorEl);

        const templatesWithCriterias = this.props.template?.Criterias.map((item) => ({ ...item, id: item.ID, name: item.Name }));

        const ruleRequirements = criteria?.Requirements?.map((item) => {
            return {
                ...item,
                answer_type: item.AnswerType,
                id: item.ID,
                name: item.Name,
                options: item.Options
            }
        })
        const isChecked = this.props.criteriaSelection[criteria.ID] !== undefined && Array.isArray(this.props.criteriaSelection[criteria.ID]) && this.props.criteriaSelection[criteria.ID].length === criteria.Requirements.length

        var priorityText = "Add Weightage";
        // if (criteria.Priority !== null && criteria.Priority !== '' && criteria.Priority === 0) {
        //     priorityText = 'Weightage: 50%';
        // }
        if (criteria.Priority !== null && criteria.Priority !== '' && criteria.Priority > 0) {
            priorityText = 'Weightage: ' + criteria.Priority + '%';
        }

        return (
            <div className={(this.props.isFromImportTemplate !== undefined || this.props.isFromImportTemplate === true) ? classes.root1 : classes.root}

            >
                {(this.props.isFromImportTemplate === undefined || this.props.isFromImportTemplate === false) && !this.props.isOpen && !this.state.isEditName && (
                    <DragIndicatorOutlinedIcon className={classes.dragIconArea} />
                )}
                <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    onMouseOver={() => this.setState({ showCheckbox: true })} onMouseOut={() => this.setState({ showCheckbox: false })}
                    className={classnames(classes.criteriaHead, this.props.isOpen ? classes.criteriaExtended : {})}
                >
                    <Grid item sm={6} md={6} xl={6}>
                        <Grid container alignItems="center">
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div>
                                    {(this.props.isSettingTemplate || this.props.isFromImportTemplate) && (
                                        <Checkbox
                                            checked={isChecked}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            onChange={(event) => {
                                                event.stopPropagation()
                                                this.props.addCriteriaSelection(true, criteria.ID, criteria.Requirements.map(r => ({ id: r.ID, name: r.Name })), event.target.checked)
                                            }}
                                            className={isChecked ? {} : this.state.showCheckbox ? {} : classes.visiblityNone}
                                        />
                                    )}
                                    {this.props.isChooseTemplate && (
                                        <Checkbox
                                            classes={{ root: classes.checkbox, checked: classes.checked }}
                                            checked={this.props.importCriteriaIds.includes(criteria.ID)}
                                            onChange={(event) => {
                                                event.stopPropagation();
                                                if (event.target.checked) {
                                                    this.props.selectEvaluationTemplateCriteria(criteria.ID)
                                                } else {
                                                    this.props.unselectEvaluationTemplateCriteria(criteria.ID)
                                                }
                                            }}
                                            name="select"
                                        />
                                    )}
                                </div>
                                <div style={{ width: '100%' }} onClick={(event) => {
                                    event.stopPropagation()
                                    if (this.props.isOpen) {
                                        this.props.toggleCriteria(false)
                                    } else {
                                        this.props.toggleCriteria("criteria_" + criteria.ID)
                                    }
                                }}>
                                    <div style={{ width: '100%', flex: 1, display: 'flex', alignItems: 'center' }}>
                                        {!this.state.isEditName
                                            ? (
                                                <Typography className={classes.titleTxt}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (this.state.name !== "Product Features" && this.props.isSettingTemplate && this.props.isEditable) {
                                                            this.setState({
                                                                isEditName: true
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {this.state.name}
                                                    {criteria.IsMandatory && <font style={{ color: "red" }}>*</font>}
                                                </Typography>
                                            ) : (

                                                <Grid container style={{ alignItems: 'end' }}>
                                                    <input
                                                        value={this.state.name}
                                                        className={classes.OutlinedInput}
                                                        onChange={(event) => {
                                                            this.setState({ name: event.target.value });
                                                        }}
                                                    ></input>
                                                    <Grid container style={{ width: 'max-content', alignItems: 'end' }}>
                                                        <div
                                                            className={classes.actionIcon}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                this.cleanError()
                                                                this.setState({ name: criteria.Name, isEditName: false })
                                                            }}
                                                        >
                                                            <ClearIcon className={classes.micon} />
                                                        </div>
                                                        <div
                                                            className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                this.saveName(
                                                                    this.state.name,
                                                                    criteria.TemplateId,
                                                                    criteria.ID,
                                                                )
                                                            }}
                                                        >
                                                            <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                                                        </div>
                                                    </Grid>
                                                </Grid>


                                            )
                                        }
                                    </div>

                                    {this.state.priority_error !== null && this.state.priority_error.length > 0 && <>
                                        <span style={{ color: 'red', fontSize: 12 }}>{this.state.priority_error}</span>
                                    </>}

                                    {this.props.isEditable && <div style={{ width: '100%', flex: 1, display: 'flex', alignItems: 'center' }}>
                                        {false && !this.state.isUpdateCriteriaPriority && this.props.isEditable && this.state.name !== "Product Features" && (
                                            <Typography
                                                className={classes.actionTxt}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.props.showCriteriaRuleDialog({
                                                        rules: criteria?.Rules || [],
                                                        isEvaluation: false,
                                                        criterias: templatesWithCriterias,
                                                        criteria: { ...criteria, name: criteria.Name, id: criteria.ID },
                                                        criteriaId: criteria.ID,
                                                        id: criteria.TemplateId,
                                                        requirements: ruleRequirements
                                                    })
                                                }}
                                            >
                                                {criteria?.Rules?.length === 0 ? "Add Rule" : "Update Rule"}
                                            </Typography>
                                        )}

                                        {this.props.isEditable && false && !this.state.isUpdateCriteriaPriority && <Divider orientation="vertical" flexItem style={{ margin: '0px 10px' }} />}

                                        {this.props.isEditable && !this.state.isUpdateCriteriaPriority &&
                                            <Typography
                                                className={classes.actionTxt}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState({
                                                        isUpdateCriteriaPriority: true
                                                    })
                                                }}>{priorityText}</Typography>}


                                        {this.props.isEditable && this.state.isUpdateCriteriaPriority && <Grid container style={{ alignItems: 'end' }}>
                                            <input
                                                value={this.state.priority}
                                                className={classes.priorityOutlinedInput}
                                                onChange={(event) => {
                                                    var newValue = parseInt(event.target.value) || '';
                                                    this.setState({ priority: newValue });
                                                }}
                                            ></input>
                                            <Grid container style={{ width: 'max-content', alignItems: 'end' }}>
                                                <div
                                                    className={classes.actionIcon}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.cleanError()
                                                        this.setState({
                                                            priority_error: '',
                                                            priority: criteria.priority === 0 ? '' : criteria.priority,
                                                            isUpdateCriteriaPriority: false
                                                        })
                                                    }}
                                                >
                                                    <ClearIcon className={classes.micon} />
                                                </div>
                                                <div
                                                    className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.updatePriority(criteria?.ID, criteria.TemplateId)
                                                    }}
                                                >
                                                    <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                                                </div>
                                            </Grid>
                                        </Grid>}
                                    </div>}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item sm={6} md={6} xl={6}
                        className={classes.headPadding}
                        onClick={(event) => {
                            event.stopPropagation()
                            if (this.props.isOpen) {
                                this.props.toggleCriteria(false)
                            } else {
                                this.props.toggleCriteria("criteria_" + criteria.ID)
                            }
                        }}
                    >
                        <div className={classes.criteriaHeadActionItem}>
                            <UserGroup
                                evaluationId={criteria.TemplateId}
                                isfromSettingsTemplate={true}
                                criterias={templatesWithCriterias}
                                criteriaId={criteria.ID}
                                editable={this.props.isEditable}
                                position={'TemplateCriteriaHead'}
                                onAdd={(user) => {
                                    this.props.addTemplateCriteriaColloborator(
                                        criteria.TemplateId,
                                        criteria.ID,
                                        user.ID,
                                        user.isApprover)
                                }}
                                updatePriority={(user) => {
                                    this.props.updateTemplateCriteriaColloborator(
                                        criteria.TemplateId,
                                        criteria.ID,
                                        user.ID,
                                        null,
                                        user?.priority)
                                }}
                                users={criteria.Collaborators}
                            />


                            {this.props.isEditable && (
                                <div
                                    className={classes.menuSection}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (this.props.isEditable) {
                                            this.props.deleteCriteria(
                                                criteria.TemplateId,
                                                criteria.ID
                                            )
                                        }
                                    }}
                                >
                                    <Image alt="Delete" src={deleteIcon} />
                                </div>
                            )}
                            {this.props.isEditable && (
                                <div
                                    className={classes.menuSection}
                                    onClick={this.handleClick}
                                >
                                    <MoreVertIcon />
                                </div>
                            )}
                            <Popover
                                id="criteriaPopover"
                                open={open}
                                anchorEl={this.state.anchorEl}
                                onClose={(event) => this.handleClose(event)}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <div onClick={(e) => e.stopPropagation()}>
                                    <div className={classes.checkboxSection}>
                                        <Checkbox
                                            classes={{ root: classes.checkbox, checked: classes.checked }}
                                            checked={criteria?.IsMandatory}
                                            onChange={(event) => this.toggleMandatory(event, criteria?.IsMandatory)}
                                            name="Mandatory"
                                            disabled={!this.props.isEditable}
                                        />Required
                                    </div>
                                    <Divider />
                                    {criteria && !criteria.IsMandatory && <div className={classes.checkboxSection}>
                                        <Checkbox
                                            classes={{ root: classes.checkbox, checked: classes.checked }}
                                            disabled={!this.props.isEditable}
                                            checked={criteria?.IsEditable}
                                            onChange={(event) => this.toggleEditable(event, criteria?.IsEditable)}
                                            name="Editable"
                                        /> Editable
                                    </div>}
                                </div>
                            </Popover>
                            <IconButton
                                className={classnames(classes.menuSection, classes.expand, {
                                    [classes.expandOpen]: this.props.isOpen,
                                })}
                                aria-expanded={this.props.isOpen}
                                aria-label="show more"
                                onClick={() => {
                                    if (this.props.isOpen) {
                                        this.props.toggleCriteria(false)
                                    } else {
                                        this.props.toggleCriteria("criteria_" + criteria.ID)
                                    }
                                }}
                            >
                                <ArrowDropDownIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>

                <Collapse in={this.props.isOpen} timeout="auto" unmountOnExit>
                    {this.state.name === "Product Features" && <div className={classes.requirementConainer} ><Typography>
                        Product Features will be automatically populated during evaluation based on the evaluated products.
                    </Typography></div>}
                    {this.state.name !== "Product Features" && <>
                        {criteria.Requirements !== null && (
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={classes.requirementConainer}
                                            style={{ paddingLeft: this.props.isChooseTemplate ? 35 : 0 }}
                                        >
                                            {criteria.Requirements !== null &&
                                                criteria.Requirements.map((requirement, index) => (
                                                    <Draggable key={String(requirement?.ID)} draggableId={String(requirement?.ID)} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={classes.requirementCard}
                                                            >
                                                                <Requirement
                                                                    isSettingTemplate={this.props.isSettingTemplate}
                                                                    criteriaSelection={this.props.criteriaSelection}
                                                                    criteria={criteria}
                                                                    criterias={templatesWithCriterias}
                                                                    requirement={requirement}
                                                                    isEditable={this.props.isEditable}
                                                                    isOpen={this.props.expandedRequirementId === `requirement_${requirement?.ID}`}
                                                                    toggleRequirement={this.props.toggleRequirement}
                                                                    key={`requirement_${requirement?.ID}`}
                                                                    updateRequirement={this.props.updateRequirement}
                                                                    deleteRequirement={this.props.deleteRequirement}
                                                                    isFromImportTemplate={this.props.isFromImportTemplate !== undefined && this.props.isFromImportTemplate === true ? true : false}
                                                                    maxScore={this.props.maxScore}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                        {this.props.isEditable && (
                            <div className={classnames(classes.addRequirementAction, 'evaluate-add-requirement')}>
                                <div className={classes.hrLine}>
                                    <span
                                        onClick={(e) => { e.stopPropagation(); this.props.showTemplateRequirementAddDialog(criteria.TemplateId, criteria.ID) }}
                                    >+ New Question</span>
                                </div>
                            </div>
                        )}
                    </>}
                </Collapse>
            </div>
        )
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Criteria));
