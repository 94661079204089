import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
    Checkbox, Button, Dialog, DialogContent, Slide, Typography, Grid
} from '@material-ui/core';
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import Alert from '@material-ui/lab/Alert';
import DefaultImage from "assets/images/evaluation/default.svg";
import { hidePreFeedbackTakeDialog, addEvaluationPreFeedback } from "redux/evaluation/feedback/action"
import { showSnackBar } from "redux/snackbar/action";
import { fetchOrgProducts, fetchSimilarExistingProducts } from "redux/product/orgProduct/action";
import { withRouter } from 'react-router-dom';
import SqureButton from '../../../../../../../Common/SqureButton';
import CommonCss from "commonCss"
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import classnames from "classnames";
import { showEvaluationMetaDialog } from "redux/evaluation/metadata/action";

import { fetchWorkflowComponentUserResponse, addWorkflowComponentUserResponse } from "redux/workflow/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.evaluationFeedback.showPreFeedbackTakeDialog,
    addFeedbackProgress: state.evaluationFeedback.addPreFeedbackProgress,
    addFeedbackError: state.evaluationFeedback.addPreFeedbackError,
    evaluation: state.evaluationCart.evaluation,
    user: state.authUser.user,
    subcatOrgProducts: state.orgProduct.orgProducts,
    similarExistingProductProgress: state.orgProduct.similarExistingProductProgress,
    similarExistingProducts: state.orgProduct.similarExistingProducts,
    addComponentUserResponseProgress:state.workflow.addComponentUserResponseProgress,
    workflowComponentsForms: state.workflow.workflowComponentsForms
});

const connectionActions = {
    hideDialog: hidePreFeedbackTakeDialog,
    showSnackBar: showSnackBar,
    addEvaluationFeedback: addEvaluationPreFeedback,
    fetchOrgProducts: fetchOrgProducts,
    fetchSimilarExistingProducts: fetchSimilarExistingProducts,
    showEvaluationMetaDialog: showEvaluationMetaDialog,
    fetchWorkflowComponentUserResponse: fetchWorkflowComponentUserResponse,
    addWorkflowComponentUserResponse: addWorkflowComponentUserResponse
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    title: {
        textAlign: "center",
        fontWeight: 500,
        color: '#282D30',
        fontSize: 32,
        margin: '20px 0px 0px'
    },
    formControl: {
        ...CommonCss.formControl,
    },
    subTitle: {
        textAlign: 'center',
        fontSize: 13
    },
    actions: {
        textAlign: "right"
    },
    actionBtn: {
        minWidth: '200px'
    },
    dragItem: {
        borderRadius: 4,
        padding: theme.spacing(1.5),
        color: '#6D7278',
        border: '1px solid #EBEBEB',
        display: 'flex',
        justifyContent: 'space-between'
    },
    "label": {
        marginTop: theme.spacing(1.6),
        marginBottom: theme.spacing(1),
        fontSize: theme.spacing(2),
        color: "#6C6C6C",
        fontWeight: "bold"
    },
    nestedComponent: {
        padding: theme.spacing(3, 0),
    },
    dialogContent: {
        // maxWidth: 425,
        padding: theme.spacing(8),
        margin: 'auto',
        // marginBottom: 60
    },
    feedbackSection: {
        marginBottom: 40,
        "& > div": {
            color: '#475867',
            marginTop: 25,
            fontSize: 12,
            "& > label": {
                fontSize: 14,
            }
        },
        "& [class*=MuiInputBase-root]": {
            padding: 0
        },
        "& textarea": {
            height: 60,
            fontSize: 14
        }
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    feedbackText: {
        color: '#475867',
        fontSize: 14,
        marginTop: 0
    },
    feedbackImg: {
        textAlign: 'center',
        marginTop: 10
    },
    addNewButton: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    feedbackAction: {
        textAlign: "left",
        padding: theme.spacing(2)
    },
    customCheckBox: {
        "& [class*='MuiCheckbox-colorSecondary-'].Mui-checked": {
            color: '#4A87F8',
        },
        '& .Mui-checked': {
            color: '#4A87F8'
        }
    },
    itemLabel: {
        fontSize: '18px',
        fontWeight: 500
    },
    creatable: {
        marginLeft: '43px'
    },
    product_list: {
        marginBottom: '35px',
        '& .product_container': {
            marginTop: '10px'
        }
    }
});

const feedbackOptions = [
    {
        label: "No equivalent product",
        value: 1,
        selectProduct: false
    },
    {
        label: "Product present but required functionality not available",
        value: 2,
        selectProduct: true
    },
    {
        label: "Product and functionality present but does not meet needs",
        value: 3,
        selectProduct: true
    },
    {
        label: "Product and functionality present but we need a different vendor",
        value: 4,
        selectProduct: true
    },
    {
        label: "Other",
        value: 5,
        selectProduct: false
    }
]
class PreFeedbackDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            feedback: "",
            feedback_error: "",
            options: {},
            show_feedback: false,
        }
        this.submitFeedback = this.submitFeedback.bind(this);
        this.fetchProducts = this.fetchProducts.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.producDetail = this.producDetail.bind(this);
    }
    componentDidMount() {
        this.fetchProducts("")
        this.props.fetchWorkflowComponentUserResponse(this.props.match.params.evaluation_id, this.props.componentId);

        if (this.props.evaluation !== null) {
            this.props.fetchSimilarExistingProducts(this.props.evaluation.Products.map((p) => {
                return p.ID
            }))
        }
    }

    componentDidUpdate(prevProps) {

        // if(this.props.isOpen === true && prevProps.isOpen === false){
        // if(this.props.evaluation !== null){
        //     this.props.fetchSimilarExistingProducts(this.props.evaluation.Products.map((p)=>{
        //         return p.ID
        //     }))
        // }
        // }

        if (this.props.addFeedbackProgress === false && prevProps.addFeedbackProgress === true) {
            if (this.props.addFeedbackError === null) {
                // alert('Feedback Submited Added');

                //Migrate to different page
                // this.props.hideDialog();
                this.props.showSnackBar("Feedback Added", "success", 3000);
                // this.props.showEvaluationMetaDialog(
                //     this.props.evaluation.ID
                // );
                var responseDatas = [];
                var options = this.state.options;
                this.props.workflowComponentsForms.forEach((data)=>{
                    if(data.IsConfiguration === false){
                        var obj = {
                            "form_id" : data.ID
                        }
                        if(data.Name === 'evPreFeedback.feedback'){
                            obj['value'] = JSON.stringify(options);
                        }else{
                            obj['value'] = this.state.show_feedback?this.state.feedback:"";
                        }
                        responseDatas.push(obj);
                    }
                })
                this.props.addWorkflowComponentUserResponse(this.props.match.params.evaluation_id, this.props.workflowComponentId, {
                    "data": responseDatas
                });
            } else {
                if (this.props.addFeedbackError.message !== undefined) {
                    this.setState({
                        "error": "Failed to add Feedback Details."
                    })
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }

        if(this.props.addComponentUserResponseProgress === false && prevProps.addComponentUserResponseProgress === true){
            // alert('User Response Added');
            this.props.history.push("/app/evaluate/" + this.props.match.params.evaluation_id + "/workflow/interim");
        }
    }

    submitFeedback() {
        this.setState({
            success: "",
            error: "",
            feedback_error: ""
        });
        var options = this.state.options;
        if (Object.keys(options).length === 0) {
            this.setState({
                error: "Please select atleast one option."
            });
            return;
        }

        if (options[5] !== undefined) {
            var bodyText = document.querySelectorAll("#evPreFeedbackDialog .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
            if (String(bodyText).trim().length === 0) {
                this.setState({
                    feedback_error: "Please enter Feedback."
                });
                return;
            }
        }

        this.props.addEvaluationFeedback(this.props.evaluation.ID,{
            feedback:this.state.show_feedback?this.state.feedback:"",
            pick_reasons:JSON.stringify(options)
        })

    }

    fetchProducts(query) {
        this.props.fetchOrgProducts(0,
            20,
            query)
    }

    handleChange(optionValue) {
        const { options } = this.state;
        if (options[optionValue] !== undefined) {
            delete options[optionValue]
        } else {
            options[optionValue] = []
        }
        this.setState({
            options: options
        })
        if (optionValue === 5) {
            this.setState({
                show_feedback: true
            })
        }
    }

    producDetail = (e, product) => {
        this.props.history.push(`/app/products/org-product-details/${product.ID}`);
    };

    handleProductChange(optionValue, products) {
        const { options } = this.state;
        options[optionValue] = products
        this.setState({
            options: options
        })
    }

    render() {
        const classes = this.props.classes;
        if (this.props.user === null) {
            return null;
        }
        var productMap = {}
        if (this.props.evaluation !== null) {
            this.props.evaluation.Products.forEach((p) => {
                productMap[p.ID] = p
            })
        }
        var productList = this.props.subcatOrgProducts.map((item) => {
            return {
                label: item.Name + " by " + item.CompanyName,
                value: item.ID
            }
        });
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={true}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"lg"}
            scroll="body"
            id="evPreFeedbackDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <div>
                    <Typography variant={"h4"} className={classes.title}>Feedback</Typography>
                    <p className={classes.subTitle}>We noticed that you are evaluating products that are not part of {this.props.user.Organization.Name}.<br /> Please enter in more details, so that we can serve you better.</p>
                </div>
                <div className={classes.alerts}>
                    {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                    {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                </div>
                <div className={classes.form}>
                    {this.props.similarExistingProductProgress === false && this.props.evaluation.Products.map((product, k) => {
                        if (this.props.similarExistingProducts[product.ID] === undefined) {
                            return null
                        }
                        var similarProducts = this.props.similarExistingProducts[product.ID];
                        return <div className={classes.product_list}>
                            <Typography className={classes.label}>Similar product{similarProducts.length > 1 ? "s" : ""} for {product.Name}</Typography>
                            <Grid container spacing={3}>
                                {similarProducts.map((product, index) => (
                                    <Grid item lg={2} md={3} key={index}>
                                        <div onClick={(e) => {
                                            this.producDetail(e, product)
                                            this.props.hideDialog()
                                        }}>
                                            <LayeredCard
                                                title={product.Name}
                                                subTitle={product.CompanyName}
                                                description={product.Description}
                                                image={
                                                    product.ProductLogo === null ||
                                                        product.ProductLogo.length === 0
                                                        ? DefaultImage
                                                        : product.ProductLogo
                                                }
                                                toogleCheck={(e) => {
                                                    this.reviewProduct(e, product.ID);
                                                }}
                                                onClick={() => { }}
                                                numLayer={0}
                                                //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                                                // showCheckbox={true}
                                                id={product.ID}
                                                uniqueId={product.ID + "_c_" + index}
                                                //  type={'product'}
                                                IsExisting={product.IsExisting}
                                            />
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    })}
                    <div className={classes.feedbackSection}>

                        {
                            feedbackOptions.map((option, k) => {
                                return <Grid container>
                                    <Grid lg={12} key={k}>
                                        <div className={classes.customCheckBox}>
                                            <Checkbox
                                                checked={(this.state.options[option.value] !== undefined) ? true : false}
                                                onChange={() => { this.handleChange(option.value) }}
                                                inputProps={{ 'aria-label': option.label }}
                                            /><span className={classes.itemLabel}>{option.label}</span>
                                        </div>
                                    </Grid>
                                    {Object.keys(this.props.similarExistingProducts).length === 0 && option.selectProduct && (this.state.options[option.value] !== undefined) && <Grid lg={12} key={k}>
                                        <Grid lg={6} key={k}>
                                            <Select
                                                isClearable
                                                isMulti
                                                options={productList}
                                                className={classnames(classes.creatable)}
                                                classNamePrefix="select"
                                                placeholder={"Select Product"}
                                                onInputChange={(e) => {
                                                    this.fetchProducts(e);
                                                }}
                                                onChange={(e) => {
                                                    this.handleProductChange(option.value, e)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            })
                        }
                        {this.state.options[5] === undefined && <div className={classes.feedbackAction}>
                            <Button className={classes.addNewButton} onClick={(e) => {
                                this.setState({ show_feedback: !this.state.show_feedback })
                            }}>{this.state.show_feedback ? "- Hide Feedback" : "+ Add Feedback"}</Button>
                        </div>}
                        {(this.state.show_feedback) && <SlateInputField
                            label="Feedback"
                            error={this.state.feedback_error.length > 0}
                            helperText={this.state.feedback_error}
                            folderPath={`/evaluation/${this.props?.evaluation?.ID}/feedback`}
                            as={SlateInputField}
                            toolTip="38"
                            onChangeEvent={(value) => {
                                this.setState({ feedback: value });
                            }}
                            initValue={this.state.feedback}
                            textContainerStyle={{ color: "#707070", }} />}
                    </div>
                </div>
                <div className={classes.actions} style={{ textAlign: 'center' }}>
                    <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.submitFeedback}>Submit Feedback</SqureButton>
                </div>
            </DialogContent>

        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(PreFeedbackDialog));