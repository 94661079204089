import React from "react";
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme) => createStyles({
    steper:{
        paddingLeft:theme.spacing(2),
    }
});

class Steper extends React.Component {

    render(){
        const classes = this.props.classes;

        return <div className={classes.steper}>
            {this.props.children}
        </div>
    }
}
export default withStyles(styles)(Steper)