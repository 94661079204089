import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import CommonCss from 'commonCss';
import PropTypes from "prop-types";
import AppBarV2 from "Components/Application/AppBarV2";
import { fetchAutomation, updateAutomation, fetchMasterTriggers, fetchMasterActions } from 'redux/automations/action';
import { showSnackBar } from "redux/snackbar/action";
import FlowBuilder from "./Components/Builder";


const connectedProps = (state) => ({
  fetchAutomationProgress: state.automation.fetchAutomationProgress,
  fetchAutomationError: state.automation.fetchAutomationError,
  automationDetails: state.automation.automationDetails,

  updateAutomationProgress: state.automation.updateAutomationProgress,
  updateAutomationError: state.automation.updateAutomationError,
  updatedAutomationData: state.automation.updatedAutomationData,

  fetchMasterTriggerProgress: state.automation.fetchMasterTriggerProgress,
  fetchMasterTriggerError: state.automation.fetchMasterTriggerError,
  masterTriggers: state.automation.masterTriggers,

  fetchMasterActionProgress: state.automation.fetchMasterActionProgress,
  fetchMasterActionError: state.automation.fetchMasterActionError,
  masterActions: state.automation.masterActions,
});

const connectionActions = {
  updateAutomation: updateAutomation,
  fetchAutomation: fetchAutomation,
  showSnackBar: showSnackBar,
  fetchMasterTriggers: fetchMasterTriggers,
  fetchMasterActions: fetchMasterActions
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  paper: {
    padding: 20
  },
  tabPanel: {
    backgroundColor: '#fff',
    minHeight: theme.spacing(40),
  },
  tabPanelRes: {
    backgroundColor: '#fff',
    '& [class*=MuiBox-root]': {
      padding: '2px'
    },
    '& [class*=makeStyles-label]': {
      display: 'none'
    }
  },
  topActions: {
    display: 'flex',
    alignItems: 'center'
  },
  rightActions: {
    textAlign: 'right',
    flex: 1
  },
  loader: {
    textAlign: 'center',
    marginTop: 30
  }
});

class Builder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      status: 0,
      isShowLoader: true,
      automationId: this.props.match.params.id,
      name: ''
    }
  }

  componentDidMount() {
    this.props.fetchAutomation(this.props.match.params.id);
    this.props.fetchMasterTriggers()
    this.props.fetchMasterActions()

  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchAutomationProgress === false && prevProps.fetchAutomationProgress) {
      if (this.props.fetchAutomationError === null) {
        this.initData()
      }
    }

    if (!this.props.updateAutomationProgress && prevProps.updateAutomationProgress) {
      if (this.props.updateAutomationError === null) {
        this.props.showSnackBar('Automation updated successfully', 'success', 3000)
      } else {
        this.props.showSnackBar('Something went wrong', 'error', 3000)
      }
    }
  }

  initData() {
    const { automationDetails } = this.props;
    console.log(automationDetails, 'automationDetails')

    this.setState({
      isShowLoader: false,
      status: automationDetails?.Status,
      name: automationDetails?.Name
    })
  }

  updateAutomationStatus() {
    let params = {
      name: this.state.name,
      status: this.state.status
    }
    this.props.updateAutomation(this.state.automationId, params)
  }

  render() {
    const { classes } = this.props;
    const { isShowLoader, automationId } = this.state;
    const { automationDetails, fetchMasterTriggerProgress, fetchMasterActionProgress, masterTriggers, masterActions } = this.props;

    if (isShowLoader || fetchMasterTriggerProgress || fetchMasterActionProgress) {
      return <div className={classes.loader}>
        <CircularProgress />
      </div>
    }

    return (
      <div>
        <AppBarV2 title={automationDetails?.Name || 'Automation Update'} withBack={true} />
        <div className={classes.root}>
          <FlowBuilder
            id={automationId}
            automationDetails={automationDetails}
            masterTriggers={masterTriggers}
            masterActions={masterActions}
            isShowModeSwitch={true}
          />
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Builder));
