import { Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import CheckboxTree from "react-checkbox-tree";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import SqureButton from "Components/Common/SqureButton";
import Checkbox from "@material-ui/core/Checkbox";
import { showSnackBar } from "redux/snackbar/action";
import _ from "underscore";
import { hideCatalogfieldsDialog } from "redux/vendor/action";

import "./style.css";
import AddViewDialog from "../../Dialog/AddViewDialog";

const connectedProps = (state) => ({
  showDialog: state.vendors.showDialog,
  updateCatalogViewsProgress: state.vendors.updateCatalogViewsProgress,
  catalogViewsAddProgress: state.vendors.catalogViewsAddProgress,

  fetchAppCatalogFieldsProgress: state.appCatalog.fetchAppCatalogFieldsProgress,
  fetchAppCatalogFieldsError: state.appCatalog.fetchAppCatalogFieldsError,
  appcatalogFields: state.appCatalog.appcatalogFields,
  orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  hideCatalogfieldsDialog: hideCatalogfieldsDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
      marginBottom: 60,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    body: {
      marginTop: 20,
    },
    actions: {
      textAlign: "center",
    },
    actionBtn: {
      minWidth: "200px",
      marginLeft: 10,
    },
  });

class SelectField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      nodes: [],
      itemIds: [],
      selectAllItems: false,
      checked: [],
      showAddView: false,
    };
  }

  componentDidMount() {
    this.initNodes();
  }
  componentDidUpdate(prevProps) {}

  initNodes() {
    const { orgCatalogDetailTabs } = this.props;
    let tabs = orgCatalogDetailTabs || [];
    let nodes = [];
    let selecteItems = [];

    tabs.forEach((tab) => {
      var obj = {
        value: tab.id,
        label: tab.name,
        children: [],
      };
      let isGoodToAdd = true;
      if (tab.sections) {
        tab.sections.forEach((section, i) => {
          let isGoodToAddSection = true;
          let sectionName = `Section ${i + 1}`;
          if (section.name && section.name.length > 0) {
            sectionName = section.name;
          }
          var secObj = {
            value: section.id,
            label: sectionName,
            children: [],
          };
          if (section.items) {
            section.items.forEach((item) => {
              if (![16, 9, 6, 17, 18, 21, 22].includes(item.type) && !["existing_products", "existing_vendorservices"].includes(item.slug)) {
                let uniqueId = item.id + "ITEM" + item.slug;
                let itemName = item.name;
                if (!itemName || itemName.length === 0) {
                  itemName = sectionName;
                }
                secObj.children.push({
                  value: uniqueId,
                  label: itemName,
                });
                if (this.props.selectedItemIds?.includes(item.slug) && !selecteItems.includes(uniqueId)) {
                  selecteItems.push(uniqueId);
                }
              }
            });
          } else {
            isGoodToAddSection = false;
          }
          if (isGoodToAddSection && secObj.children.length > 0) {
            obj.children.push(secObj);
          }
        });
      } else {
        isGoodToAdd = true;
      }
      if (isGoodToAdd && obj.children.length > 0) {
        nodes.push(obj);
      }
    });
    this.setState({
      nodes: nodes,
      checked: selecteItems,
    });
  }

  selectAllItems = () => {
    var type = this.state.selectAllItems === true ? false : true;
    var checkedItems = [];
    var nodes = this.state.nodes;
    if (type) {
      nodes.forEach((tab) => {
        checkedItems.push(tab.value);
        tab.children.forEach((section) => {
          checkedItems.push(section.value);
          section.children.forEach((item) => {
            checkedItems.push(item.value);
          });
        });
      });
    }
    this.setState({
      selectAllItems: type,
      checked: checkedItems,
    });
  };

  confirmFields = (name) => {
    let checkedData = this.state.checked;
    var itemIds = [];
    checkedData.forEach((itm) => {
      let arr = itm?.split("ITEM");
      let slugValue = arr[1];
      if (slugValue && slugValue?.length > 0) {
        itemIds.push(slugValue);
      }
    });

    if (this.props.isUpdate) {
      this.props.updateView(_.uniq(itemIds));
    } else {
      this.props.createFieldsView(name, _.uniq(itemIds));
    }
  };

  render() {
    const classes = this.props.classes;

    const { nodes, checked, expanded } = this.state;

    const loading = this.props.updateCatalogViewsProgress || this.props.catalogViewsAddProgress;

    return (
      <div>
        <div className={classes.body}>
          <div style={{ display: "flex", alignItems: "center", marginTop: 25 }}>
            <Checkbox
              label={"Select All"}
              className={classes.criteriaAllSelect}
              color="primary"
              checked={this.state.selectAllItems}
              onChange={() => {
                this.selectAllItems();
              }}
            />
            <Typography>Select All</Typography>
          </div>

          <CheckboxTree
            nodes={nodes}
            checked={checked}
            expanded={expanded}
            onCheck={(checked) => {
              this.setState({ checked });
            }}
            onExpand={(expanded) => this.setState({ expanded })}
            iconsClass="fa5"
            icons={{
              check: <CheckBoxIcon />,
              uncheck: <CheckBoxOutlineBlankIcon />,
              expandClose: <ChevronRightIcon />,
              expandOpen: <ExpandMoreIcon />,
              halfCheck: <CheckBoxOutlineBlankIcon />,
            }}
          />
        </div>
        <div className={classes.actions}>
          <SqureButton
            disabled={loading || this.state.checked?.length === 0}
            cls={classes.actionBtn}
            variant={"contained"}
            onClick={() => {
              if (this.props.isUpdate) {
                this.confirmFields();
              } else {
                this.setState({ showAddView: true });
              }
            }}
          >
            {this.props.isUpdate ? "Update" : "Create"} View
          </SqureButton>
        </div>
        {this.state.showAddView && <AddViewDialog onSubmit={(name) => this.confirmFields(name)} onClose={() => this.setState({ showAddView: false })} />}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(SelectField));
