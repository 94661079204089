import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";

import { getNestedState } from "util/ChartConfig";

import Input from "../Common/Input";
import Number from "../Common/Number";
import Select from "../Common/Select";
import Switch from "../Common/Switch";
import Color from "../Common/Color";
import CodeEditor from "../Common/CodeEditor";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    heading: {
      fontWeight: 500,
    },
    fieldWraper: {
      margin: "6px 0px",
    },
  });

class FormSections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    const { classes, configValues, formSections } = this.props;

    if (formSections && formSections.length) {
      return (
        <div>
          {formSections.map((section, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                <div className={classes.heading}>{section.name}</div>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: "column", padding: "0em 1em" }}>
                {section.items.map((item, i) => {
                  if (item.type === "text") {
                    return (
                      <div key={i} className={classes.fieldWraper}>
                        <Input
                          label={item.label}
                          value={getNestedState(configValues, item.statePath)}
                          placeholder={item.placeholder}
                          updateValue={(value) => this.props.onUpdateHandler(item.statePath, value)}
                        />
                      </div>
                    );
                  } else if (item.type === "number") {
                    return (
                      <div key={i} className={classes.fieldWraper}>
                        <Number label={item.label} value={getNestedState(configValues, item.statePath)} updateValue={(value) => this.props.onUpdateHandler(item.statePath, value)} />
                      </div>
                    );
                  } else if (item.type === "select") {
                    return (
                      <div key={i} className={classes.fieldWraper}>
                        <Select
                          label={item.label}
                          value={getNestedState(configValues, item.statePath)}
                          options={item.options}
                          updateValue={(value) => this.props.onUpdateHandler(item.statePath, value)}
                        />
                      </div>
                    );
                  } else if (item.type === "color") {
                    return (
                      <div key={i} className={classes.fieldWraper}>
                        <Color label={item.label} value={getNestedState(configValues, item.statePath)} updateValue={(value) => this.props.onUpdateHandler(item.statePath, value)} />
                      </div>
                    );
                  } else if (item.type === "switch") {
                    return (
                      <div key={i} className={classes.fieldWraper}>
                        <Switch label={item.label} value={getNestedState(configValues, item.statePath)} updateValue={(value) => this.props.onUpdateHandler(item.statePath, value)} />
                      </div>
                    );
                  } else if (item.type === "function") {
                    return (
                      <div key={i} className={classes.fieldWraper}>
                        <CodeEditor label={item.label} value={getNestedState(configValues, item.statePath)} updateValue={(value) => this.props.onUpdateHandler(item.statePath, value)} />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default connector(compose(withStyles(styles), withLastLocation)(FormSections));
