import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import {
  fetchEvaluationMetaData,
  updateEvaluationMetaData,
  emptyProductsList,
  fetchProductsList,
  fetchEvaluationInfo
} from "redux/evaluation/metadata/action";
import {
  showEvaluationCriteriaAddDialog,
  fetchUserRequirementResponse,
  fetchScores,
  fetchColloborator,
  setSelectedSummaryView,
  setSelectedProductsForSummaryView,
  setSelectedCriteriasForSummaryView,
} from "redux/evaluation/criteria/action";
import { showFeedbackTakeDialog } from "redux/evaluation/feedback/action";
import { showSnackBar } from "redux/snackbar/action";
import { withRouter } from "react-router-dom";

import CriteriaRequirement from "./CriteriaRequirement";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Product from "./products";

import Select from 'react-select';

import { fetchProductRequirementDetails } from "redux/product/action";

// Drawers
import CollectiveResponseDialog from "Components/Dialogs/CollectiveResponseDialog";

// Shared
import ProductSlider from "./ProductSlider";
import SearchUserPoper from "Components/Common/UserGroup/SearchUserPoper";
// redux
import { showEvaluationMetaDialog } from "redux/evaluation/metadata/action";
import {
  toggleEvaluateView,
  toggleEvaluateNeverReviewed,
} from "redux/applications/action";
import { hideFeedbackTakeDialog } from "redux/evaluation/feedback/action";

import classnames from "classnames";
import { addEvaluationFeedback } from "redux/evaluation/feedback/action";
import { showEvaluationLinkDialog } from "redux/evaluation/link/action";
import { fetchEvaluationReportData } from "redux/evaluation/reportdata/action";
import AppBar from "@material-ui/core/AppBar";
import SqureButton from "../../../../../../Common/SqureButton";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Tooltip from "@material-ui/core/Tooltip";
import {
  Grid,
  Typography,
  Popover,
  Avatar,
  FormControl,
  MenuItem,
  Divider,
  Button,
  CircularProgress
} from '@material-ui/core';

import { Select as MaterialSelect } from '@material-ui/core';

import Checkbox from "@material-ui/core/Checkbox";

import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import Image from "Components/Common/image.jsx";

import {
  fetchWorkflowComponentDetails,
  fetchWorkflowComponentsForms,
  fetchComponentDetails,
} from "redux/workflow/action";

import { fetchWorkflowConfigurations } from "redux/workflow/action";


const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  collectiveResponseDialogOpen:
    state.evaludationCriteria.collectiveResponseDialogOpen,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  updateProgress: state.evaluationMetaData.updateProgress,
  updateError: state.evaluationMetaData.errorUpdate,
  addResponseProgress: state.evaludationCriteria.addResponseProgress,
  addFeedbackProgress: state.evaluationFeedback.addFeedbackProgress,
  addFeedbackError: state.evaluationFeedback.addFeedbackError,
  user: state.authUser.user,
  productRequirement: state.products.productRequirement,
  evaluateView: state.applications.evaluateView,
  evaluateViewId: state.applications.evaluateViewId,
  evaluateNeverReview: state.applications.evaluateNeverReview,
  evaluateNeverReviewId: state.applications.evaluateNeverReviewId,
  criterias: state.evaludationCriteria.criterias,
  userReponses: state.evaludationCriteria.userReponses,
  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
  summaryViewId: state.evaludationCriteria.summaryViewId,
  report: state.evaluationReportData.report,
  isLoadingReport: state.evaluationReportData.isLoading,
  colloborators: state.evaludationCriteria.colloborators,
  summaryViewProducts: state.evaludationCriteria.summaryViewProducts,
  scores: state.evaludationCriteria.scores,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
  fetchworkflowComponentDetailProgress: state.workflow.fetchworkflowComponentDetailProgress,
  summaryViewCriterias: state.evaludationCriteria.summaryViewCriterias,
  fetchWorkflowConfigProgress: state.workflow.fetchWorkflowConfigProgress,
  fetchWorkflowConfigError: state.workflow.fetchWorkflowConfigError,
  workflowConfigurations: state.workflow.workflowConfigurations,
});

const connectionActions = {
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  updateEvaluationMetaData: updateEvaluationMetaData,
  showSnackBar: showSnackBar,
  showEvaluationCriteriaAddDialog: showEvaluationCriteriaAddDialog,
  fetchUserRequirementResponse: fetchUserRequirementResponse,
  showFeedbackTakeDialog: showFeedbackTakeDialog,
  fetchScores: fetchScores,
  showEvaluationMetaDialog: showEvaluationMetaDialog,
  emptyProductsList: emptyProductsList,
  fetchProductsList: fetchProductsList,
  fetchProductRequirementDetails: fetchProductRequirementDetails,
  toggleEvaluateView: toggleEvaluateView,
  toggleEvaluateNeverReviewed: toggleEvaluateNeverReviewed,
  addEvaluationFeedback: addEvaluationFeedback,
  showEvaluationLinkDialog: showEvaluationLinkDialog,
  fetchEvaluationReportData: fetchEvaluationReportData,
  fetchColloborator: fetchColloborator,
  setSelectedSummaryView: setSelectedSummaryView,
  setSelectedProductsForSummaryView: setSelectedProductsForSummaryView,
  fetchWorkflowComponentDetails: fetchWorkflowComponentDetails,
  fetchWorkflowComponentsForms: fetchWorkflowComponentsForms,
  fetchComponentDetails: fetchComponentDetails,
  setSelectedCriteriasForSummaryView: setSelectedCriteriasForSummaryView,
  fetchEvaluationInfo: fetchEvaluationInfo,
  hideFeedbackTakeDialog: hideFeedbackTakeDialog,
  fetchWorkflowConfigurations: fetchWorkflowConfigurations
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    criteriaTitle: {
      fontSize: theme.spacing(2.5),
      margin: theme.spacing(1.6, 0),
      color: "#282D30",
    },
    bottomNav: {
      padding: theme.spacing(1.6, 0),
    },
    prodPad: {
      // height:"100px"
    },
    productBox: {
      minWidth: 450,
      marginRight: 30,
      borderRadius: 5,
    },
    productContainer: {
      display: "flex",
      overflow: "auto",
      marginBottom: 10,
    },
    productContainerReport: {
      display: "flex",
      marginBottom: 10,
    },
    contentContainer: {
      marginBottom: "100px",
    },
    report: {
      marginBottom: 0,
    },
    finalizeBtn: {
      textAlign: "right",
    },
    saveBtn: {
      display: "inline-block",
      marginRight: 30,
    },
    subText: {
      paddingBottom: theme.spacing(2),
    },
    content: {
      padding: theme.spacing(4, 4),
      background: "#F7F7F7",
      minHeight: "100vh",
    },
    appBar: {
      background: "#fff",
      padding: theme.spacing(1, 2),
      boxShadow: "none",
      borderBottom: "2px solid #f1f1f1",
    },
    evaDetails: {
      textAlign: "right",
      paddingRight: theme.spacing(1),
    },
    viewDetails: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    colbTitle: {
      fontSize: theme.spacing(2),
      fontWeight: 500,
      marginRight: 10,
    },
    filterTitle: {
      marginBottom: theme.spacing(2),
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    filterTitle1: {
      marginBottom: 1,
      color: "#282D30",
      fontSize: 14,
    },
    popover: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        width: "150px",
        minWidth: 'fit-content'
      },
    },
    formControl: {
      "& > div": {
        color: "hsl(0,0%,50%)",
        fontSize: 14,
        background: '#fff'
      },
    },
    productFilter: {
      color: "hsl(0,0%,50%)",
      fontSize: 14,
      background: '#fff'
    },
    prodctScore: {
      display: "flex",
    },
    productImg: {
      width: 24,
      height: "auto",
      marginRight: 5,
    },
    scorePer: {
      marginLeft: 10,
      background: "#ebeff0",
      padding: "3px 6px",
      borderRadius: 24,
    },
    productScr: {
      display: "flex",
      alignItems: "center",
      marginRight: 10,
      border: "1px solid #ebeff0",
      borderRadius: "10px",
      padding: "6px 10px",
    },
    collaboratorDetails: {
      display: "flex",
    },
    scoreDetails: {
      display: "flex",
      justifyContent: "end",
    },
    criteriaDetailsRow: {
      width: 800
    },
    responseRow: {
      width: 700
    },
    dataTable: {
      '& .MuiTable-root': {
        width: 'auto'
      }
    },
    reportTable: {
      width: 'auto'
    },
    creatable: {
      width: 400
    },
    lineFilter: {
      display: 'flex',
      alignItems: 'center',
      // position: 'absolute',
      padding: theme.spacing(2, 4),
    },
    productFilterBox: {
      marginLeft: 20
    },
    criteriaFilter: {
      marginLeft: 20
    }
  });

class ProgressSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProducts: [],
      productImageContainerHeight: 222,
      productImageContainerHeight2: 134,
      open: false,
      productFilterOpen: null,
      isWorkflow: false,
      scoring_mechanism: {
        id: "Textual",
        type: "Textual",
      },
      weighting_mechanism: {
        id: "Textual",
        type: "Textual",
      },
      selectedComponentID: "",
      selectedCriterias: null,
      criteriaList: [],
      updateOnce: true,
      isHideAskSeller: false,
      isHideCommunityResponse: false,
      enableScoring: false
    };
    this.avatarName = this.avatarName.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCriterias = this.handleChangeCriterias.bind(this);
  }

  componentDidMount() {
    var isWorkflow = this.getParameterByName("isWorkflow");
    console.log(isWorkflow, "isWorkflow");
    // if (isWorkflow !== null) {
      // if (isWorkflow === "true") {
        this.setState(
          {
            isWorkflow: true,
          },
          () => {
            // this.props.fetchWorkflowComponentDetails(
            //   this.props.match.params.workflow_id,
            //   this.props.match.params.component_id,
            //   this.props.match.params.evaluation_id
            // );
            this.props.fetchWorkflowConfigurations(this.props.match.params.workflow_id);
          }
        );
      // }
    // }
    this.props.fetchColloborator(this.props.match.params.evaluation_id);
    this.props.fetchEvaluationMetaData(this.props.match.params.evaluation_id);
    this.props.fetchUserRequirementResponse(
      this.props.match.params.evaluation_id
    );

    console.log(this.props.user, 'Useerrrrrrrrrrrrrrr')
    this.props.fetchScores(this.props.match.params.evaluation_id, [
      this.props.user.ID,
    ]);

    this.getReportData();
    this.requirementHight();
  }

  getReportData() {
    var params = {
      approval: true,
      colloborator: true,
      feedback: true,
      seller_response: true,
      all_products: true
    }

    const { summaryViewCriterias } = this.props;
    var criteriaIds = [];
    if (summaryViewCriterias !== null) {
      summaryViewCriterias.forEach(function (prd) {
        criteriaIds.push(prd.value)
      })
    }

    if (criteriaIds.length > 0) {
      params['all_criterias'] = false;
      params['criterias'] = criteriaIds;
    } else {
      params['all_criterias'] = true;
    }
    this.props.fetchEvaluationReportData(
      this.props.match.params.evaluation_id,
      params
    );
  }

  updateCriteriaDropdown() {
    const { report } = this.props;
    if (this.state.updateOnce) {
      var criteriaList = report?.Criterias?.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      });
      this.setState({
        criteriaList: criteriaList,
        updateOnce: false
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.addResponseProgress === false && prevProps.addResponseProgress === true) {
      this.props.fetchScores(this.props.match.params.evaluation_id);
      this.props.fetchUserRequirementResponse(
        this.props.match.params.evaluation_id
      );
    }

    if (
      this.props.fetchProgress === false &&
      prevProps.fetchProgress === true
    ) {
      let products = this.props.evaluation?.Products;

      if (products !== undefined) {
        this.props.fetchProductRequirementDetails(
          products.map((i) => {
            return i.ID;
          }),
          this.props.evaluation.ID,
          window.location.pathname
        );
        const productData = products.map((m) => {
          return {
            value: m.ID,
            label: m.Name,
            checked: true,
          };
        });
        this.setState(
          {
            selectedProducts: productData,
          },
          () => {
            this.props.setSelectedProductsForSummaryView(productData);
          }
        );
      }
    }

    if (this.props.fetchworkflowComponentDetailProgress === false && prevProps.fetchworkflowComponentDetailProgress === true) {
      this.setState(
        {
          selectedComponentID: this.props.workflowComponentDetail?.ComponentID,
        },
        () => {
          this.props.fetchComponentDetails(
            this.props.workflowComponentDetail?.ComponentID
          );
          this.props.fetchWorkflowComponentsForms(
            this.props.workflowComponentDetail?.ComponentID
          );
        }
      );
    }

    if (this.props.workflowComponentsFormsInProg === false && prevProps.workflowComponentsFormsInProg === true) {
      if (this.state.isWorkflow) {
        console.log(this.props.workflowComponentsForms, "workflowComponentsForms5555");
        this.checkWorkflowConfiguration();
      }
    }

    if (this.props.isLoadingReport === false && prevProps.isLoadingReport === true) {
      this.updateCriteriaDropdown()
    }

    if (this.props.fetchWorkflowConfigProgress === false && prevProps.fetchWorkflowConfigProgress === true) {
      if (this.props.fetchWorkflowConfigError === null) {
        console.log(this.props.workflowConfigurations, 'workflowConfigurations')
        this.checkConfigurationNew();
      } else {
        this.props.showSnackBar("Something went wrong to fetch workflow configurations.", "error", 3000)
      }
    }
  }

  checkConfigurationNew() {
    const { workflowConfigurations } = this.props;
    console.log(workflowConfigurations, 'workflowConfigurations')
    var configurations = workflowConfigurations.configurations;
    if (configurations === undefined || configurations === null || configurations.length <= 0) {
      return;
    } else {
      // var scoreConfiguration = configurations.filter((o) => o.Form.Name === "scoring_mechanism")[0];
      // if (scoreConfiguration !== undefined && scoreConfiguration !== null && scoreConfiguration.Value !== null && scoreConfiguration.Value !== '') {
      //   try {
      //     this.setState({
      //       scoring_mechanism: JSON.parse(scoreConfiguration.Value),
      //     });
      //   } catch (e) {}
      // }
      var weightConfiguration = configurations.filter((o) => o.Form.Name === "weighting_mechanism")[0];
      if (weightConfiguration !== undefined && weightConfiguration !== null && weightConfiguration.Value !== null && weightConfiguration.Value !== '') {
        try {
          this.setState({
            weighting_mechanism: JSON.parse(weightConfiguration.Value),
          });
        } catch (e) {}
      }
      var askSellerConfiguration = configurations.filter((o) => o.Form.Name === "ask_seller")[0];
      if (askSellerConfiguration !== undefined && askSellerConfiguration !== null && askSellerConfiguration.Value !== null && askSellerConfiguration.Value !== '') {
        let askSellerValue = askSellerConfiguration.Value === 'true' ? true : false;
        if (askSellerValue === false) {
          this.setState({
            isHideAskSeller: true
          })
        }
      }
      var communityResponseConfiguration = configurations.filter((o) => o.Form.Name === "community_response")[0];
      if (communityResponseConfiguration !== undefined && communityResponseConfiguration !== null && communityResponseConfiguration.Value !== null && communityResponseConfiguration.Value !== '') {
        let communityValue = communityResponseConfiguration.Value === 'true' ? true : false;
        if (communityValue === false) {
          this.setState({
            isHideCommunityResponse: true
          })
        }
      }

      var enableScoringConfiguration = configurations.filter((o) => o.Form.Name === "enable_scoring")[0];
      if (enableScoringConfiguration !== undefined && enableScoringConfiguration !== null && enableScoringConfiguration.Value !== null && enableScoringConfiguration.Value !== '') {
        let enableScoring = enableScoringConfiguration.Value === 'true' ? true : false;
        this.setState({
          enableScoring: enableScoring
        })
        console.log(enableScoring, 'enableScoring')
      }

    }
  }

  checkWorkflowConfiguration() {
    const { workflowComponentsForms, workflowComponentDetail } = this.props;
    console.log(workflowComponentsForms, 'workflowComponentsForms')
    if (workflowComponentsForms === null || workflowComponentDetail === null) {
      return;
    }
    var configurations =
      workflowComponentDetail.OrgWorkflowComponentConfigurations;
    if (
      configurations === undefined ||
      configurations === null ||
      configurations.length <= 0
    ) {
      return;
    } else {
      var scoreMachFormId = workflowComponentsForms
        .filter((o) => o.Name === "scoring_mechanism")
        .map((o) => o.ID)[0];
      var score_mechanism_val = configurations
        .filter((o) => o.FormID === scoreMachFormId)
        .map((o) => o.Value)[0];
      if (score_mechanism_val !== undefined && score_mechanism_val !== null && score_mechanism_val !== '') {
        try {
          this.setState({
            scoring_mechanism: JSON.parse(score_mechanism_val),
          });
        } catch (e) {}
        
      }

      var weightMachFormId = workflowComponentsForms.filter(o => o.Name === "weighting_mechanism").map(o => o.ID)[0];
      var weighting_mechanism_val = configurations.filter(o => o.FormID === weightMachFormId).map(o => o.Value)[0];
      if (weighting_mechanism_val !== undefined && weighting_mechanism_val !== null && weighting_mechanism_val !== '') {
        try {
          this.setState({
            weighting_mechanism: JSON.parse(weighting_mechanism_val)
          })
        } catch (e) {}
        
      }

      var askSellerFormId = workflowComponentsForms.filter(o => o.Name === 'ask_seller').map(o => o.ID)[0];
      var askSellerValue = configurations.filter(o => o.FormID === askSellerFormId).map(o => o.Value)[0];
      askSellerValue = askSellerValue === 'true' ? true : false;
      if (askSellerValue === false) {
        this.setState({
          isHideAskSeller: true
        })
      }

      var communityResponseId = workflowComponentsForms.filter(o => o.Name === 'community_response').map(o => o.ID)[0];
      var communityResponseValue = configurations.filter(o => o.FormID === communityResponseId).map(o => o.Value)[0];
      communityResponseValue = communityResponseValue === 'true' ? true : false;
      console.log(communityResponseValue, 'communityResponseValue')
      if (communityResponseValue === false) {
        this.setState({
          isHideCommunityResponse: true
        })
      }

      var enableScoringFormId = workflowComponentsForms.filter(o => o.Name === "enable_scoring").map(o => o.ID)[0];
      var enableScoringValue = configurations.filter(o => o.FormID === enableScoringFormId).map(o => o.Value)[0];
      if (enableScoringValue !== undefined && enableScoringValue !== null && enableScoringValue !== '') {
        enableScoringValue = enableScoringValue === 'true' ? true : false;
        this.setState({
          enableScoring: enableScoringValue
        })
        console.log(enableScoringValue, 'enableScoringValue')
      }

    }
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentWillUnmount() {
    try {
      this.props.toggleEvaluateView(false, null);
      this.props.toggleEvaluateNeverReviewed(false, null);
      document.body.classList.remove("evaluate-view");
    } catch (e) { }
  }

  requirementHight = () => {
    let reqEle = document.getElementById(`productImageContainer`);
    if (reqEle && this.state.requirementHight !== reqEle.offsetHeight) {
      this.setState({
        productImageContainerHeight: reqEle.offsetHeight,
        productImageContainerHeight2: reqEle.offsetHeight,
      });
    }
  };

  handleClick = (event) => {
    event.stopPropagation();
    this.setState({ productFilterOpen: event.currentTarget || event.current });
  };

  handleClose = (event) => {
    event.stopPropagation();
    this.setState({ productFilterOpen: null });
  };

  handleChange = (e) => {
    const { selectedProducts } = this.state;
    let value = parseInt(e.target.value) || 0;
    let checked = e.target.checked;
    let products = selectedProducts.map((m) => {
      if (m.value === value) {
        return { value: m.value, label: m.label, checked: checked };
      } else {
        return { value: m.value, label: m.label, checked: m.checked };
      }
    });

    this.setState({ selectedProducts: products }, () => {
      console.log("selectedProducts", products);
      this.props.setSelectedProductsForSummaryView(products);
    });

  };

  handleChangeCriterias = (e) => {
    this.props.setSelectedCriteriasForSummaryView(e)
    this.setState({
      selectedCriterias: e
    }, () => {
      setTimeout(() => {
        this.getReportData();
      }, 300);
    })
  }

  render() {
    const classes = this.props.classes;

    let products = this.props.evaluation?.Products;
    const { summaryViewId, scores, report } = this.props;
    const { criteriaList, selectedCriterias } = this.state;
    const isOpen = Boolean(this.state.productFilterOpen);

    if (report === undefined || report === null || scores === undefined || products === undefined) {
      return (
        <div style={{ textAlign: "center", marginTop: 30 }}>
          <CircularProgress />
        </div>
      );
    }

    products.forEach(function (product) {
      if (scores[product.ID] !== undefined) {
        var score_obj = scores[product.ID];
        var totalOpted = 0;
        var totalMax = 0;
        var product_score = 0;
        for (var criteriId in score_obj) {
          var criteriScore = score_obj[criteriId];
          totalOpted += criteriScore.totalOpted;
          totalMax += criteriScore.totalMax;
        }
        if (totalMax !== 0) {
          product_score = Math.round(
            parseFloat((parseFloat(totalOpted) / parseFloat(totalMax)) * 100)
          );
        }
        product["product_score"] = product_score;
      } else {
        product["product_score"] = 0;
      }
    });

    products = products.sort(function (a, b) {
      if (a.product_score < b.product_score) {
        return 1;
      } else if (a.product_score > b.product_score) {
        return -1;
      } else {
        return 0;
      }
    });

    var productScoreMap = {};
    if (report.Products !== undefined && report.Products !== null) {
      report.Products.forEach(function (itm) {
        productScoreMap[itm.ID] = itm.Stats;
      })
    }

    return (
      <div className={classes.root}>
        {!this.props.isReport && (
          <AppBar className={classes.appBar} position="static" color="default">
            <Grid container justifyContent="space-between">
              <Grid item lg={3} className={classes.collaboratorDetails}>
                {this.props.colloborators !== undefined &&
                  this.props.colloborators !== null && (
                    <div>
                      {this.props.colloborators.length > 0 && (
                        <Typography
                          variant={"h6"}
                          className={classes.colbTitle}
                        >
                          Collaborators
                        </Typography>
                      )}
                      <AvatarGroup max={3}>
                        {this.props.colloborators.map((user, k) => {
                          return (
                            <Tooltip
                              placement="top"
                              title={
                                <span
                                  style={{
                                    fontSize: "14px",
                                    whiteSpace: "pre-line",
                                    display: "inline-block",
                                    height: "25px",
                                    lineHeight: "25px",
                                  }}
                                >
                                  {user.Name + "\n" + user.Email}
                                </span>
                              }
                              arrow
                            >
                              <Avatar key={k}>
                                {" "}
                                {this.avatarName(user.Name)}
                              </Avatar>
                            </Tooltip>
                          );
                        })}
                      </AvatarGroup>
                    </div>
                  )}
              </Grid>
              <Grid item lg={9} className={classes.viewDetails}>
                {this.state.enableScoring && <div className={classes.prodctScore}>
                  {products.map((product) => {
                    return (
                      <Typography className={classes.productScr}>
                        <Image
                          className={classes.productImg}
                          src={product.ProductLogo}
                          alt={product.Name}
                        />
                        <span>{product.Name}</span>
                        <span className={classes.scorePer}>
                          {product.product_score}%{" "}
                        </span>
                      </Typography>
                    );
                  })}
                </div>}
                <SqureButton
                  variant="outlined"
                  onClick={() => {
                    if (this.state.isWorkflow) {
                      // this.props.history.push(`/app/evaluate/${this.props.match?.params?.evaluation_id}/workflow/interim`);
                      this.props.history.replace(
                        "/app/evaluate/" +
                        this.props.match.params.evaluation_id +
                        "/workflow/" +
                        this.props.match.params.workflow_id +
                        "/component/" +
                        this.props.match.params.component_id
                      );
                    } else {
                      this.props.history.push(
                        `/app/evaluate/${this.props.match?.params?.evaluation_id}/respond`
                      );
                    }
                  }}
                >
                  Focussed View
                </SqureButton>
              </Grid>
            </Grid>
          </AppBar>
        )}

        {<div className={classes.filters}>
          <div className={classes.filtersBox}>
            {false && <Typography variant={"h4"} className={classes.filterTitle}>
              Filters
            </Typography>}

            <div className={classes.lineFilter}>
              <div className={classes.responseFilter}>
                <Typography variant={"h4"} className={classes.filterTitle1}>
                  Select Response
                </Typography>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                >
                  <MaterialSelect
                    value={summaryViewId}
                    onChange={(e) => {
                      this.props.setSelectedSummaryView(e.target.value);
                    }}
                  >
                    <MenuItem value={1} style={{ fontSize: 13 }}>
                      Your Response
                    </MenuItem>
                    {this.state.isHideCommunityResponse === false && <MenuItem value={2} style={{ fontSize: 13 }}>
                      Community
                    </MenuItem>}
                    {this.state.isHideAskSeller === false && <MenuItem value={3} style={{ fontSize: 13 }}>
                      Vendor
                    </MenuItem>}
                  </MaterialSelect>
                </FormControl>
              </div>
              <div className={classes.productFilterBox}>
                <Typography variant={"h4"} className={classes.filterTitle1}>
                  Select Product
                </Typography>
                <Button
                  className={classes.productFilter}
                  variant="outlined"
                  onClick={(e) => {
                    this.handleClick(e);
                  }}
                >
                  Product Filter <ArrowDropDownIcon style={{ opacity: 0.6 }} />
                </Button>

              </div>
              <div className={classes.criteriaFilter}>
                <Typography variant={"h4"} className={classes.filterTitle1}>
                  Select Section
                </Typography>
                <Select
                  isClearable
                  isMulti
                  value={selectedCriterias}
                  options={criteriaList}
                  className={classnames(classes.creatable)}
                  classNamePrefix="select"
                  placeholder={"Select Section"}
                  onChange={(e) => {
                    this.handleChangeCriterias(e);
                  }}
                />
              </div>
            </div>

            <Popover
              id="popover"
              open={isOpen}
              className={classes.popover}
              anchorEl={this.state.productFilterOpen}
              onClose={(event) => this.handleClose(event)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {this.state.selectedProducts.map((item, i) => {
                return (
                  <div style={{marginRight: '10px'}}>
                    <Checkbox
                      classes={{
                        root: classes.checkbox,
                        checked: classes.checked,
                      }}
                      checked={item.checked}
                      value={item.value}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      id={`product_${item.value}`}
                      name="Mandatory"
                    />
                    {item.label}
                    <Divider />
                  </div>
                );
              })}
            </Popover>

          </div>
        </div>}

        {<Grid
          container
          className={classnames(classes.contentContainer, classes.content, this.props.isReport ? classes.report : {})}
        >
          <Grid item xs={6}>
            <div style={{ paddingTop: this.state.enableScoring ? this.state.productImageContainerHeight : this.state.productImageContainerHeight2 }}>
              <CriteriaRequirement
                isRespond
                isEditable={false}
                evaluationId={this.props.match.params.evaluation_id}
                weighting_mechanism={this.state.weighting_mechanism}
                isReport={this.props.isReport}
                isPDfReport={this.props.isPDfReport}
                isHideAskSeller={this.state.isHideAskSeller}
                isHideCommunityResponse={this.state.isHideCommunityResponse}
                enableScoring={this.state.enableScoring}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ position: "relative", paddingLeft: "20px" }}
          >
            <div className={!this.props.isReport ? classes.productContainer : classes.productContainerReport}>
              {this.props.evaluation !== null && products.length > 0 && <>
                {<ProductSlider products={products} isReport={this.props.isReport}>
                  {products.map((product, k) => {
                    return (
                      <div className={classes.productBox}>
                        <Product
                          isRespond
                          product={product}
                          productIndex={k}
                          scoring_mechanism={this.state.scoring_mechanism}
                          productScoreMap={productScoreMap}
                          isHideAskSeller={this.state.isHideAskSeller}
                          isHideCommunityResponse={this.state.isHideCommunityResponse}
                          enableScoring={this.state.enableScoring}
                        />
                      </div>
                    );
                  })}
                </ProductSlider>}
              </>}
            </div>
          </Grid>
        </Grid>}
        {this.props.collectiveResponseDialogOpen === true && (
          <CollectiveResponseDialog />
        )}
        {this.props.collaboratorDialogOpen && (
          <SearchUserPoper {...this.props.collaboratorDialogData} />
        )}
      </div>
    );
  }
}

ProgressSummary.default = {
  isReport: false,
  isPDfReport: false
};

export default connector(compose(withRouter, withStyles(styles))(ProgressSummary));
