import { Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import { storeData } from "redux/companyProfile/action";
import BinaryType from "./itemTypes/binaryType";
import MultiSelect from "./itemTypes/multiSelect";
import SingleSelect from "./itemTypes/singleSelect";
import TextType from "./itemTypes/textType";
import { Country, State } from "country-state-city";
import UploadType from "./itemTypes/uploadType";
import AddVendor from "./itemTypes/addVendor";
import ProfileItemPopup from "./itemTypes/popupType";
const connectedProps = () => ({});

const connectionActions = {};

const ProfileItem = ({
  classes,
  item,
  isEdit,
  activeMenu,
  section,
  itemGroup,
  itemGroupIndex
}) => {
  const dispatch = useDispatch();
  const { storeDataSuccess } =
    useSelector((state) => state.companyProfile);

  const updateValue = (value) => {
    let newStoreData = { ...storeDataSuccess };
    const sectionToUpdate = newStoreData[activeMenu]?.find(
      (s) => s.sectionKey === section.sectionKey
    );
    if (sectionToUpdate) {
      if (sectionToUpdate.itemGroups) {
        let itemGroups = sectionToUpdate.itemGroups;
        let itemGroup = itemGroups[itemGroupIndex];
        itemGroup.items.forEach(function (itm) {
          if (item.itemKey === itm.itemKey) {
            itm.value = value
          }
        })
        dispatch(storeData(newStoreData));
        // console.log(sectionToUpdate, 'sectionToUpdate-itemToUpdate000000000000000000000000000000001')
        // console.log(sectionToUpdate.itemGroups, 'sectionToUpdate-itemGroups')
        // console.log(itemGroupIndex, 'itemGroupIndex')
        // const itemGroupToUpdate = sectionToUpdate.itemGroups[itemGroupIndex]
        // const itemToUpdate = itemGroupToUpdate.items.find(
        //   (i) => i.itemKey === item.itemKey
        // );
        // console.log(itemToUpdate, 'itemToUpdate000000000000000000000000000000001')
        // if (itemToUpdate) {
        //   itemToUpdate.value = value;
        //   dispatch(storeData(newStoreData));
        // }
      } else if (sectionToUpdate.items) {
        const itemToUpdate = sectionToUpdate.items.find(
          (i) => i.itemKey === item.itemKey
        );
        console.log("itemToUpdate 2", itemToUpdate, value);
        if (itemToUpdate) {
          itemToUpdate.value = value;
          console.log("newStoreData", newStoreData);
          dispatch(storeData(newStoreData));
        }
      }
    }
  };

  const getSelectedCountryCode = () => {
    if (section.itemGroups) {
      //in this itemgroup.items find the item which has isCountry as true and get its value
      const countryItem = itemGroup.items.find(
        (i) => i.additionalData?.isCountry
      );
      if (countryItem) {
        return countryItem.value?.value;
      }
    } else if (section.items) {
      const countryItem = section.items.find(
        (i) => i.additionalData?.isCountry
      );
      if (countryItem) {
        return countryItem.value?.value;
      }
    }
    return null;
  };

  const renderComponent = () => {
    switch (item.type) {
      case "TEXT":
      case "NUMBER":
      case "EMAIL":
      case "URL":
      case "PHONE":
        return (
          <TextType
            type={item.type.toLowerCase()}
            data={item}
            value={item.value}
            isEdit={isEdit}
            onSubmit={(value) => {
              updateValue(value);
            }}
            isRequired={item.isRequired}
          />
        );
      case "IMAGE":
        return (
          <UploadType
            type={item.type}
            data={item}
            value={item.value}
            isEdit={isEdit}
            onSubmit={(value) => {
              console.log("onSubmit", value);
              updateValue(value);
            }}
            isRequired={item.isRequired}
          />
        );
      case "BINARY":
        return (
          <BinaryType
            type={item.type}
            data={item}
            value={item.value}
            isEdit={isEdit}
            onSubmit={(value) => {
              updateValue(value);
            }}
            isRequired={item.isRequired}
          />
        );
      case "SINGLESELECT":
        let options = item.additionalData?.options || [];
        let isDisabled = item.additionalData?.isDisabled || false;
        if (item.additionalData?.isCountry) {
          options = Country.getAllCountries().map((country, index) => {
            return { label: country.name, value: country.isoCode };
          });
        } else if (item.additionalData?.isState) {
          console.log('Fetch Stateeeeeeeeeeeeeeeeeee')
          //figure out if the country is selected and then make the isDisabled true/false based on that.
          //once the country is selected, we need to pass the select options value(countryCode) here.
          let selectedCountryCode = getSelectedCountryCode();
          if (selectedCountryCode) {
            options = State.getStatesOfCountry(selectedCountryCode).map(
              (state, index) => {
                return { label: state.name, value: state.isoCode };
              }
            );
          } else {
            isDisabled = true;
          }
        }
        return (
          <SingleSelect
            editMode={isEdit}
            selectedOption={item.value}
            options={options}
            data={item}
            isDisabled={isDisabled}
            isRequired={item.isRequired}
            onSubmit={(value) => {
              updateValue(value);
            }}
          />
        );
      case "MULTISELECT":
        return (
          <MultiSelect
            data={item}
            editMode={isEdit}
            selectedOptions={item.value}
            options={item.additionalData?.options || []}
            isRequired={item.isRequired}
            onSubmit={(value) => {
              updateValue(value);
            }}
          />
        );
      case "ADD_VENDOR":
        return (
          <AddVendor
            type={item.type}
            data={item}
            value={item.value}
            isEdit={isEdit}
            onSubmit={(value) => {
              updateValue(value);
            }}
            section={section}
            isRequired={item.isRequired}
          />
        );
      case "POPUP":
        return (
          <ProfileItemPopup
            type={item.type}
            data={item}
            value={item.value}
            isEdit={isEdit}
            onSubmit={(value) => {
              updateValue(value);
            }}
            activeMenu={activeMenu}
            itemGroup={itemGroup}
            section={section}
            isRequired={item.isRequired}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <Typography
        variant="h5"
        className={classes.heading}
        style={{ marginBottom: "4px" }}
      >
        {item.label}
        {item.isRequired && (
          <span style={{ color: "red", marginLeft: "4px" }}>*</span>
        )}
      </Typography>
      {renderComponent()}
    </div>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      paddingTop: "24px",
    },
    heading: {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 600,
      color: "#003b4d99",
    },
  });

export default connect(
  connectedProps,
  connectionActions
)(withStyles(styles)(ProfileItem));
