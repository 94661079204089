import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import Snackbar from "../Common/Snackbar";
import classnames from "classnames";
import { Switch, Route, withRouter } from "react-router-dom";
import Respond from "./Components/Respond.jsx"

const connectedProps = (state) => ({
});

const connectionActions = {
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(4, 10),
        background: "#F7F7F7",
        minHeight: "100vh"
    },
    logo:{
        display: 'flex',
        alignItems:"center",
        justifyContent:"center"
    },
    logoImage:{
        height:'40px'
    },
    logoFont:{
        fontSize:'2.2rem',
        fontStyle:"italic",
        textTransform: 'uppercase'
    }
});

class Seller extends React.Component {
    render() {
        const classes = this.props.classes;
        return <div className={classes.root}>
            <CssBaseline />
            <div className={classnames(classes.content, 'applicationContent')}>
                <Switch>
                    <Route exact path={`${this.props.match.path}/evaluate/:uuid/:org_id/:requirement_id`} component={Respond} />
                </Switch>
            </div>
            <Snackbar/>
        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Seller));