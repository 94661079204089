import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
 
const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class DecisionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
   
  }
  
  render() {
    return (
      <div>Hello Ssunil</div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(DecisionComponent));
