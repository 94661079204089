import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";

import { Grid, Typography } from "@material-ui/core";
import groupIconImage from "assets/images/icons/groups.svg"
import LayeredCard from "../../../Evaluate/Components/Common/LayeredCard";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class GroupsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { activeProductsTabs, data, label, classes, allProductsLength } = this.props;

    return (
      <>
        {data && data.length > 0 && (
          <>
            <Grid container alignItems="center" justifyContent="space-between" style={{marginBottom:'15px'}}>
              <Typography className={classes.label}>{label}</Typography>
              {!activeProductsTabs && allProductsLength > 6 && (
                <div onClick={this.props.setActiveProductsTab} className={classes.viewAll}>
                  View All ({allProductsLength})
                </div>
              )}
            </Grid>
            <Grid container spacing={3} style={{ marginBottom: 10 }}>
              {data.map((group, index) => (
                <Grid item lg={2} md={3} sm={4} xs={6} key={"group_" + parseInt(group.id)}>
                  <LayeredCard
                      title={group.name}
                      image={groupIconImage}
                      subTitle={group.name}
                      color="BLUE"
                      onClick={() => {
                        this.props.history.push(
                          `/app/products/cluster-products/${parseInt(
                            group.id
                          )}`
                        );
                      }}
                    />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </>
    );
  }
}

export default connector(compose(withStyles(styles), withLastLocation)(GroupsCard));
