import {
    SHOW_EVALUATION_METADATA_DIALOG, HIDE_EVALUATION_METADATA_DIALOG,
    START_EVALUATION_METADATA_UPDATE, END_EVALUATION_METADATA_STATUS_UPDATE, START_EVALUATION_METADATA_STATUS_UPDATE, END_EVALUATION_METADATA_UPDATE,
    START_EVALUATION_METADATA_FETCH, END_EVALUATION_METADATA_FETCH,
    START_FETCH_SUBCATEGORIES_EVALUATION, END_FETCH_SUBCATEGORIES_EVALUATION,
    START_EVALUATION_PREVIEW, END_EVALUATION_PREVIEW,
    START_EVALUATION_SELECTED_FEATURES, END_EVALUATION_SELECTED_FEATURES,
    START_EVALUATION_DELETE_SELECTED_FEATURES, END_EVALUATION_DELETE_SELECTED_FEATURES,
    START_EVALUATION_UPDATE_SELECTED_FEATURES, END_EVALUATION_UPDATE_SELECTED_FEATURES,
    SHOW_FEATURE_DIALOG, HIDE_FEATURE_DIALOG, EMPTY_PRODUCTS_LIST, EVALUATION_PRODUCTS_LIST_DETAIL_FETCH,
    SHOW_SEND_NOTIFICATION_DIALOG, HIDE_SEND_NOTIFICATION_DIALOG,
    START_SEND_EVALUTION_EMAIL, END_SEND_EVALUTION_EMAIL,
    SHOW_EVALUATION_FINISH_DIALOG, HIDE_EVALUATION_FINISH_DIALOG,
    START_EVALUATION_METADATA_UPDATE_WORKFLOW, END_EVALUATION_METADATA_UPDATE_WORKFLOW,
    START_PRE_EVALUATION_FEEDBACK_DATA_FETCH, END_PRE_EVALUATION_FEEDBACK_DATA_FETCH,
    START_PRODUCT_COLLOBORATOR_ADD, END_PRODUCT_COLLOBORATOR_ADD,
    START_FETCH_PRODUCT_COLLABORATORS, END_FETCH_PRODUCT_COLLABORATORS,
    START_DELETE_PRODUCT_COLLABORATOR, END_DELETE_PRODUCT_COLLABORATOR,
    SHOW_EVALUATION_PRODUCT_SELECTION_DIALOG, HIDE_EVALUATION_PRODUCT_SELECTION_DIALOG,
    START_EVALUATION_TITLE_UPDATE, END_EVALUATION_TITLE_UPDATE,
    START_EVALUATION_UNDO, END_EVALUATION_UNDO,
    START_EVALUATION_PRODUCT_UPDATE, END_EVALUATION_PRODUCT_UPDATE,
    START_FETCH_EVALUATION_INFO, END_FETCH_EVALUATION_INFO,
    START_EVALUATION_SCORING_SCALE_UPDATE, END_EVALUATION_SCORING_SCALE_UPDATE,
    START_ADD_FEATURES_TO_EVALUATION, END_ADD_FEATURES_TO_EVALUATION,
    START_EVALUATION_INFO_UPDATE, END_EVALUATION_INFO_UPDATE,
    START_DELETE_EVALUATION_APPROVALS, END_DELETE_EVALUATION_APPROVALS,
    START_EVALUATION_APPROVAL_INFO_UPDATE, END_EVALUATION_APPROVAL_INFO_UPDATE
} from "./action";

const initState = {
    showDialog: false,
    showFeatureDialog: false,
    evaluationId: null,
    isEvaluationMetaEditPage: false,
    criteriaId: null,
    updateProgress: false,
    updateProgressWorkflow: false,
    errorUpdate: null,
    fetchProgress: false,
    errorFetch: null,
    evaluation: null,
    subCategoriesOfEvaluationProgress: false,
    subCategoriesOfEvaluationError: null,
    evaluationPreviewProgress: false,
    evaluationPreview: [],
    evaluationPreviewError: null,
    evaluationSelectedFeaturesProgress: false,
    evaluationSelectedFeatures: [],
    evaluationSelectedFeaturesError: null,
    evaluationUpdateSelectedFeaturesProgress: false,
    evaluationDeleteSelectedFeaturesProgress: false,
    evaluationProductsList: [],
    isOpenSendNotificationDialog: false,
    sendNotificationEmail: null,
    sendEmailProgress: false,
    errorSendEmail: null,
    isOpenFinishDialog: false,
    fetchPreFeedbackDataProgress: false,
    preFeedbackData: null,

    product_colloborators: [],
    allProductCollaboratorProgress: false,

    showEvaluationProductSelectionDialog: false,
    evalTitleUpdateProgress: false,
    evalTitleUpdateError: null,

    evaluationProductUpdateProgress: false,

    fetchEvaluationInfoProgress: false,
    evaluationInforError: null,
    evaluationInfoData: null,

    evalScoringScaleUpdateProgress: false,
    evalScoringScaleUpdateError: null,

    addFeaturesProgress: false,
    addFeaturesError: null,
    evaluationFeatures: null,

    evalInfoUpdateProgress: false,
    evalInfoUpdateError: null,

    deleteApprovalsProgress: false,
    deleteApprovalsError: null,

    evalInfo: null,

    evalApprovalInfoUpdateProgress: false,
    evalApprovalInfoUpdateError: null,
    evalApprovalInfo: null,

    undoEvaluationProgress: false,
    errorUndoEvaluation: null,

    updateStatusProgress: false,

}

export function evaluationMetaDataReducer(state = initState, action) {
    switch (action.type) {
        case START_EVALUATION_TITLE_UPDATE:
            return {
                ...state,
                evalTitleUpdateProgress: true,
                evalTitleUpdateError: null,
            }
        case END_EVALUATION_TITLE_UPDATE:
            return {
                ...state,
                evalTitleUpdateProgress: false,
                evalTitleUpdateError: action.payload.error,
            }
        case START_EVALUATION_UNDO:
            return {
                ...state,
                undoEvaluationProgress: true,
                errorUndoEvaluation: null,
            }
        case END_EVALUATION_UNDO:
            return {
                ...state,
                undoEvaluationProgress: false,
                errorUndoEvaluation: action.payload.error,
            }

        case START_EVALUATION_INFO_UPDATE:
            return {
                ...state,
                evalInfoUpdateProgress: true,
                evalInfoUpdateError: null,
                evalInfo: null
            }
        case END_EVALUATION_INFO_UPDATE:
            return {
                ...state,
                evalInfoUpdateProgress: false,
                evalInfoUpdateError: action.payload.error,
                evalInfo: action.payload.success
            }

        case SHOW_SEND_NOTIFICATION_DIALOG:
            let email = action.payload.email;
            return {
                ...state,
                sendNotificationEmail: email,
                isOpenSendNotificationDialog: true
            }
        case HIDE_SEND_NOTIFICATION_DIALOG:
            return {
                ...state,
                sendNotificationEmail: null,
                isOpenSendNotificationDialog: false
            }
        case SHOW_EVALUATION_FINISH_DIALOG:
            return {
                ...state,
                isOpenFinishDialog: true
            }
        case HIDE_EVALUATION_FINISH_DIALOG:
            return {
                ...state,
                isOpenFinishDialog: false
            }
        case SHOW_EVALUATION_METADATA_DIALOG:
            return {
                ...state,
                showDialog: true,
                evaluationId: action.payload.evaluationId,
                isEvaluationMetaEditPage: action.payload.isEditPage
            };
        case HIDE_EVALUATION_METADATA_DIALOG:
            return {
                ...state,
                showDialog: false,
                evaluationId: null,
                isEvaluationMetaEditPage: false
            };
        case SHOW_FEATURE_DIALOG:
            return {
                ...state,
                showFeatureDialog: true,
                evaluationId: action.payload.evaluationId,
                criteriaId: action.payload.criteriaId,
            };
        case HIDE_FEATURE_DIALOG:
            return {
                ...state,
                showFeatureDialog: false,
                evaluationId: null,
            };
        case START_EVALUATION_METADATA_FETCH:
            return {
                ...state,
                fetchProgress: true,
                errorFetch: false,
                evaluation: null,
            }
        case END_EVALUATION_METADATA_FETCH:
            return {
                ...state,
                fetchProgress: false,
                errorFetch: action.payload.error,
                evaluation: action.payload.success,
            }
        case START_EVALUATION_METADATA_STATUS_UPDATE:
            return {
                ...state,
                updateStatusProgress: true,
                errorStatusUpdate: null,
            }
        case END_EVALUATION_METADATA_STATUS_UPDATE:
            return {
                ...state,
                updateStatusProgress: false,
                errorStatusUpdate: action.payload.error,
                evaluation: action.payload.success
            }
        case START_EVALUATION_METADATA_UPDATE:
            return {
                ...state,
                updateProgress: true,
                errorUpdate: null,
            }
        case END_EVALUATION_METADATA_UPDATE:
            return {
                ...state,
                updateProgress: false,
                errorUpdate: action.payload.error,
                evaluation: action.payload.success
            }

        case START_EVALUATION_METADATA_UPDATE_WORKFLOW:
            return {
                ...state,
                updateProgressWorkflow: true,
                errorUpdate: null,
            }
        case END_EVALUATION_METADATA_UPDATE_WORKFLOW:
            return {
                ...state,
                updateProgressWorkflow: false,
                errorUpdate: action.payload.error,
                evaluation: action.payload.success
            }
        case START_FETCH_SUBCATEGORIES_EVALUATION:
            return {
                ...state,
                subCategoriesOfEvaluationProgress: true,
                subCategoriesOfEvaluation: [],
                subCategoriesOfEvaluationError: null
            }
        case END_FETCH_SUBCATEGORIES_EVALUATION:
            return {
                ...state,
                subCategoriesOfEvaluationProgress: false,
                subCategoriesOfEvaluation: action.payload.success,
                subCategoriesOfEvaluationError: action.payload.error,
            }
        case START_EVALUATION_PREVIEW:
            return {
                ...state,
                evaluationPreviewProgress: true,
                evaluationPreview: [],
                evaluationPreviewError: null
            }
        case END_EVALUATION_PREVIEW:
            var success = action.payload.success;
            var evaluationPreview = state.evaluationPreview;
            if (success !== null) {
                var Page = success.page;
                var Data = success.data;
                if (Page === 0) {
                    evaluationPreview = Data;
                } else {
                    evaluationPreview = evaluationPreview.concat(Data)
                }
            }
            return {
                ...state,
                evaluationPreviewProgress: false,
                evaluationPreview: evaluationPreview,
                evaluationPreviewError: action.payload.error,
            }
        case START_EVALUATION_SELECTED_FEATURES:
            return {
                ...state,
                evaluationSelectedFeaturesProgress: true,
                evaluationSelectedFeatures: [],
                evaluationSelectedFeaturesError: null
            }
        case END_EVALUATION_SELECTED_FEATURES:
            var evasuccess = action.payload.success;
            return {
                ...state,
                evaluationSelectedFeaturesProgress: false,
                evaluationSelectedFeatures: evasuccess || [],
                evaluationSelectedFeaturesError: action.payload.error
            }
        case START_EVALUATION_UPDATE_SELECTED_FEATURES:
            return {
                ...state,
                evaluationSelectedFeaturesProgress: true,
                evaluationUpdateSelectedFeaturesProgress: true,
                evaluationSelectedFeaturesError: null
            }
        case END_EVALUATION_UPDATE_SELECTED_FEATURES:
            return {
                ...state,
                evaluationSelectedFeaturesProgress: false,
                evaluationUpdateSelectedFeaturesProgress: false,
                evaluationSelectedFeaturesError: action.payload.error
            }
        case START_EVALUATION_DELETE_SELECTED_FEATURES:
            return {
                ...state,
                evaluationSelectedFeaturesProgress: true,
                evaluationDeleteSelectedFeaturesProgress: true,
                evaluationSelectedFeaturesError: null
            }
        case END_EVALUATION_DELETE_SELECTED_FEATURES:
            return {
                ...state,
                evaluationSelectedFeaturesProgress: false,
                evaluationDeleteSelectedFeaturesProgress: false,
                evaluationSelectedFeaturesError: action.payload.error
            }
        case EMPTY_PRODUCTS_LIST:
            return {
                ...state,
                evaluationProductsList: []
            }
        case EVALUATION_PRODUCTS_LIST_DETAIL_FETCH:
            let productDetails = state.evaluationProductsList;
            var response = action.payload.success;
            if (response !== null) {
                productDetails.push(response)
            }
            return {
                ...state,
                evaluationProductsList: productDetails
            }

        case START_SEND_EVALUTION_EMAIL:
            return {
                ...state,
                sendEmailProgress: true,
                errorSendEmail: null,
            }
        case END_SEND_EVALUTION_EMAIL:
            return {
                ...state,
                sendEmailProgress: false,
                errorSendEmail: action.payload.error,
            }

        case START_PRE_EVALUATION_FEEDBACK_DATA_FETCH:
            return {
                ...state,
                fetchPreFeedbackDataProgress: true,
                preFeedbackData: null,
            }

        case END_PRE_EVALUATION_FEEDBACK_DATA_FETCH:
            var preEvaData = action.payload.success;
            // if(preEvaData.status !== undefined && preEvaData.status === '500'){
            //     preEvaData = null;
            // }
            return {
                ...state,
                fetchPreFeedbackDataProgress: false,
                preFeedbackData: preEvaData,
            }

        case START_PRODUCT_COLLOBORATOR_ADD:
            return {
                ...state,
                allProductCollaboratorProgress: true
            }

        case END_PRODUCT_COLLOBORATOR_ADD:
            let productId = action.payload.productId;
            var errorData = action.payload.error;
            var pevaluation = state.evaluation;
            if (errorData === null) {
                var products = pevaluation?.Products !== null ? pevaluation?.Products : [];
                var product = products.filter(o => o.ID === productId)[0];
                console.log(product, 'product-----------------------------01')
                console.log(product.EvaluationProductCollaborators, 'product-----------------------------01')
                if (product.EvaluationProductCollaborators === null) {
                    product.EvaluationProductCollaborators = [];
                    product.EvaluationProductCollaborators.push(action.payload.success[0])
                } else {
                    product.EvaluationProductCollaborators.push(action.payload.success[0])
                }
                pevaluation.Products = products;
            }
            return {
                ...state,
                evaluation: pevaluation,
                allProductCollaboratorProgress: false
            }

        case START_FETCH_PRODUCT_COLLABORATORS:
            return {
                ...state,
                fetchProductCollaboratorProgress: true
            }

        case END_FETCH_PRODUCT_COLLABORATORS:
            return {
                ...state,
                fetchProductCollaboratorProgress: false,
                product_colloborators: action.payload.success,
            }

        case START_DELETE_PRODUCT_COLLABORATOR:
            return {
                ...state,
                deleteProductCollaboratorProgress: true
            }

        case END_DELETE_PRODUCT_COLLABORATOR:
            let productIdDel = action.payload.productId;
            let collaboratorId = action.payload.collaboratorId;
            var errorDataN = action.payload.error;
            var p_evaluation = state.evaluation;
            if (errorDataN === null) {
                var products_Data = p_evaluation?.Products !== null ? p_evaluation?.Products : [];
                var productData = products_Data.filter(o => o.ID === productIdDel)[0];
                var pCollaborators = productData.EvaluationProductCollaborators;
                var delIndex = pCollaborators.map((o, k) => { if (o.ID === collaboratorId) { return k } }).filter(o => o !== undefined);
                pCollaborators.splice(delIndex, 1);
            }
            return {
                ...state,
                deleteProductCollaboratorProgress: false,
                evaluation: p_evaluation,
            }

        case SHOW_EVALUATION_PRODUCT_SELECTION_DIALOG:
            return {
                ...state,
                showEvaluationProductSelectionDialog: true
            }

        case HIDE_EVALUATION_PRODUCT_SELECTION_DIALOG:
            return {
                ...state,
                showEvaluationProductSelectionDialog: false
            }

        case START_EVALUATION_PRODUCT_UPDATE:
            return {
                ...state,
                evaluationProductUpdateProgress: true
            }

        case END_EVALUATION_PRODUCT_UPDATE:
            return {
                ...state,
                evaluationProductUpdateProgress: false
            }

        case START_FETCH_EVALUATION_INFO:
            return {
                ...state,
                fetchEvaluationInfoProgress: true,
                evaluationInforError: null,
                evaluationInfoData: null
            }

        case END_FETCH_EVALUATION_INFO:
            return {
                ...state,
                fetchEvaluationInfoProgress: false,
                evaluationInforError: action.payload.error,
                evaluationInfoData: action.payload.success
            }

        case START_EVALUATION_SCORING_SCALE_UPDATE:
            return {
                ...state,
                evalScoringScaleUpdateProgress: true,
                evalScoringScaleUpdateError: null,
            }

        case END_EVALUATION_SCORING_SCALE_UPDATE:
            return {
                ...state,
                evalScoringScaleUpdateProgress: false,
                evalScoringScaleUpdateError: action.payload.error,
                evaluationInfoData: action.payload.success
            }

        case START_ADD_FEATURES_TO_EVALUATION:
            return {
                ...state,
                addFeaturesProgress: true,
                addFeaturesError: null,
            }

        case END_ADD_FEATURES_TO_EVALUATION:
            return {
                ...state,
                addFeaturesProgress: false,
                addFeaturesError: action.payload.error,
                evaluationFeatures: action.payload.success
            }

        case START_DELETE_EVALUATION_APPROVALS:
            return {
                ...state,
                deleteApprovalsProgress: true,
                deleteApprovalsError: null,
            }

        case END_DELETE_EVALUATION_APPROVALS:
            return {
                ...state,
                deleteApprovalsProgress: false,
                deleteApprovalsError: action.payload.error,
            }

        case START_EVALUATION_APPROVAL_INFO_UPDATE:
            return {
                ...state,
                evalApprovalInfoUpdateProgress: true,
                evalApprovalInfoUpdateError: null,
                evalApprovalInfo: null
            }
        case END_EVALUATION_APPROVAL_INFO_UPDATE:
            return {
                ...state,
                evalApprovalInfoUpdateProgress: false,
                evalApprovalInfoUpdateError: action.payload.error,
                evalApprovalInfo: action.payload.success
            }


        default:
            return state;
    }
}




