import React from "react";
import { Dialog, DialogContent, Slide, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Box, Card, Grid, Tabs, Tab } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { showSnackBar } from "redux/snackbar/action";
import Requirement from "./Requirement";
import { addSellerAssessmentQuesionResponse } from "redux/seller/assessment/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  questionSearchProgress: state.sellerLibrary.questionSearchProgress,
  questionSearchError: state.sellerLibrary.questionSearchError,
  questionSearchData: state.sellerLibrary.questionSearchData,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  addSellerAssessmentQuesionResponse: addSellerAssessmentQuesionResponse,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title1: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    card: {
      border: "2px solid #EEEEEE",
      borderRadius: "10px",
      background: "#fff",
      paddingLeft: theme.spacing(2),
      cursor: "pointer",
      display: "flex",
      placeContent: "center",
      width: "100%",
      padding: "14px 16px",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: 80,
    },
    cardText: {
      display: "flex",
      flexDirection: "column",
    },
    box: {
      marginTop: 25,
    },
    actionButton: {
      textAlign: "right",
      marginTop: 25,
    },
    alert: {
      marginBottom: 10,
      marginTop: 25,
    },
    btn: {
      padding: "1.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    root: {
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    profileContainer: {
      // padding: "1rem",
    },
    profileInfoCard: {
      width: "100%",
      padding: "2rem",
      borderRadius: 0,
    },
    tabs: {
      width: "320px",
      paddingRight: 20,
      borderRight: "1px solid #ccc",

      "& button:nth-child(2)": {
        borderTop: "1px solid #ccc",
        borderBottom: "1px solid #ccc",
      },
    },
    tab: {
      fontSize: "0.9rem",
      fontWeight: 500,
      textTransform: "capitalize",
    },
    productBox: {},
    productContainer: {},
    productContainerReport: {
      display: "flex",
      marginBottom: 10,
    },
    report: {
      marginBottom: 0,
    },
    saveBtn: {
      display: "inline-block",
      marginRight: 30,
    },
    subText: {
      paddingBottom: theme.spacing(2),
    },
    appBar: {
      background: "#fff",
      padding: theme.spacing(1, 2),
      boxShadow: "none",
      borderBottom: "2px solid #f1f1f1",
    },
    mainLoader: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
    collabSection: {
      borderBottom: "solid 2px #f1f1f1",
      padding: theme.spacing(2),
      background: "white",
    },
    magicContainer: {
      textAlign: "right",
    },
    responseWrap: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "center",
      gap: 40,
      marginBottom: 20,
    },
    title: {
      marginBottom: "1rem",
      color: "#1f73b7",
    },
  });

class GenieResponseDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
  }

  render() {
    const { classes, genieResponseCategoryMap } = this.props;
    const { tabValue } = this.state;

    return (
      <Dialog
        onClose={this.props.onClose}
        aria-labelledby="app-integrationDialog"
        open={true}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"lg"}
        scroll="body"
        id="evMetaDialog"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.onClose(genieResponseCategoryMap)}>
            <CloseIcon />
          </div>
          <Typography variant={"h4"} className={classes.title1}>
            Genie Response Summary
          </Typography>
          <div className={classes.body}>
            <div className={classes.box}>
              <div className={classes.root}>
                <Tabs value={tabValue} onChange={(e, value) => this.setState({ tabValue: value })} aria-label="simple tabs example">
                  <Tab label="Auto Populated" {...a11yProps(0)} />
                  <Tab label="Needs Review" {...a11yProps(1)} />
                  <Tab label="No Match" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                  {genieResponseCategoryMap["auto_populate"].length > 0 ? (
                    <Typography component="h6" className={classes.title}>
                      {genieResponseCategoryMap["auto_populate"].length} {genieResponseCategoryMap["auto_populate"].length > 1 ? "Questions" : "Question"} auto populated by Genie
                    </Typography>
                  ) : (
                    <Typography component="h6" className={classes.title}>
                      No exact match found in library, please update your library
                    </Typography>
                  )}
                  {genieResponseCategoryMap["auto_populate"].length > 0 && (
                    <div className={classes.profileContainer}>
                      <Card>
                        <div className={classes.profileInfoCard}>
                          <Grid className={classes.responseWrap}>
                            <Grid item xs={12}>
                              <b>Questions</b>
                            </Grid>
                          </Grid>
                          {genieResponseCategoryMap["auto_populate"]?.map((data, QIndex) => (
                            <Grid className={classes.responseWrap} onClick={() => this.props.chooseCurrentQuestion(data.question)}>
                              <Grid item xs={12}>
                                <Requirement key={"req" + QIndex} requirement={data.question || {}} response={data.answer || {}} />
                              </Grid>
                            </Grid>
                          ))}
                        </div>
                      </Card>
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  {genieResponseCategoryMap["needs_review"].length > 0 ? (
                    <Typography component="h6" className={classes.title}>
                      {genieResponseCategoryMap["needs_review"].length} {genieResponseCategoryMap["needs_review"].length > 1 ? "Questions" : "Question"} needs your review
                    </Typography>
                  ) : (
                    <Typography component="h6" className={classes.title}>
                      No Data
                    </Typography>
                  )}
                  {genieResponseCategoryMap["needs_review"].length > 0 && (
                    <div className={classes.profileContainer}>
                      <Card>
                        <div className={classes.profileInfoCard}>
                          <Grid className={classes.responseWrap}>
                            <Grid item xs={12}>
                              <b>Questions</b>
                            </Grid>
                          </Grid>
                          {genieResponseCategoryMap["needs_review"]?.map((data, QIndex) => (
                            <Grid className={classes.responseWrap} onClick={() => this.props.chooseCurrentQuestion(data.question)}>
                              <Grid item xs={12}>
                                <Requirement key={"req" + QIndex} requirement={data.question || {}} />
                              </Grid>
                              {/* <Grid item xs={8} style={{ display: "flex", gap: 20, overflowX: "auto" }}>
                              {data?.answers?.map((response, AnsIndex) => (
                                <div key={"res" + AnsIndex} style={{ width: "50%" }}>
                                  <Response reqIndex={AnsIndex} respondable={true} requirement={response} handleChecked={(checked) => this.handleChecked(QIndex, AnsIndex, checked)} />
                                </div>
                              ))}
                            </Grid> */}
                            </Grid>
                          ))}
                        </div>
                      </Card>
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  {genieResponseCategoryMap["no_match"].length > 0 ? (
                    <Typography component="h6" className={classes.title}>
                      {genieResponseCategoryMap["no_match"].length} {genieResponseCategoryMap["no_match"].length > 1 ? "Questions have" : "Question has"} no match in library
                    </Typography>
                  ) : (
                    <Typography component="h6" className={classes.title}>
                      No Data
                    </Typography>
                  )}
                  {genieResponseCategoryMap["no_match"].length > 0 && (
                    <div className={classes.profileContainer}>
                      <Card>
                        <div className={classes.profileInfoCard}>
                          <Grid className={classes.responseWrap}>
                            <Grid item xs={12}>
                              <b>Questions</b>
                            </Grid>
                          </Grid>
                          {genieResponseCategoryMap["no_match"]?.map((data, QIndex) => (
                            <Grid className={classes.responseWrap} onClick={() => this.props.chooseCurrentQuestion(data.question)}>
                              <Grid item xs={12}>
                                <Requirement key={"req" + QIndex} requirement={data.question || {}} />
                              </Grid>
                            </Grid>
                          ))}
                        </div>
                      </Card>
                    </div>
                  )}
                </TabPanel>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(GenieResponseDialog));
