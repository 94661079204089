import { Dialog, DialogContent, Slide, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import CheckboxTree from "react-checkbox-tree";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import SqureButton from "Components/Common/SqureButton";
import Checkbox from "@material-ui/core/Checkbox";

import { showSnackBar } from "redux/snackbar/action";

import { hideAppCatalogfieldsDialog } from "redux/product/appCatalog/action";

import "./style.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  showDialog: state.appCatalog.showDialog,
  fetchAppCatalogFieldsProgress: state.appCatalog.fetchAppCatalogFieldsProgress,
  fetchAppCatalogFieldsError: state.appCatalog.fetchAppCatalogFieldsError,
  appcatalogFields: state.appCatalog.appcatalogFields,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  hideAppCatalogfieldsDialog: hideAppCatalogfieldsDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
      marginBottom: 60,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    body: {
      marginTop: 20,
    },
    actions: {
      textAlign: "center",
    },
    actionBtn: {
      minWidth: "200px",
      marginLeft: 10,
    },
  });

class fieldsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      nodes: [],
      itemIds: [],
      selectAllItems: false,
      checked: [],
    };
    this.confirmFields = this.confirmFields.bind(this);
    this.selectAllItems = this.selectAllItems.bind(this);
  }

  componentDidMount() {
    this.initNodes();
  }
  componentDidUpdate(prevProps) {}

  initNodes() {
    const { appcatalogFields } = this.props;
    let tabs = appcatalogFields?.tabs || [];
    var nodes = [];
    tabs.forEach(function (tab) {
      var obj = {
        value: "Tab_" + tab.id,
        label: tab.name,
        children: [],
      };
      let isGoodToAdd = true;
      if (tab.sections) {
        tab.sections.forEach(function (section, i) {
          let isGoodToAddSection = true;
          let sectionName = `Section ${i + 1}`;
          if (section.name && section.name.length > 0) {
            sectionName = section.name;
          }
          var secObj = {
            value: "Section_" + section.id,
            label: sectionName,
            children: [],
          };
          if (section.items) {
            section.items.forEach(function (item) {
              if (item.name && item.name.length > 0) {
                secObj.children.push({
                  value: "Item_" + item.id,
                  label: item.name,
                });
              }
            });
          } else {
            isGoodToAddSection = false;
          }
          if (isGoodToAddSection && secObj.children.length > 0) {
            obj.children.push(secObj);
          }
        });
      } else {
        isGoodToAdd = true;
      }
      if (isGoodToAdd && obj.children.length > 0) {
        nodes.push(obj);
      }
    });
    this.setState({
      nodes: nodes,
      checked: this.props.selectedItemIds,
    });
  }

  selectAllItems() {
    var type = this.state.selectAllItems === true ? false : true;
    var checkedItems = [];
    var nodes = this.state.nodes;
    if (type) {
      nodes.forEach(function (tab) {
        checkedItems.push(tab.value);
        tab.children.forEach(function (section) {
          checkedItems.push(section.value);
          section.children.forEach(function (item) {
            checkedItems.push(item.value);
          });
        });
      });
    }
    this.setState({
      selectAllItems: type,
      checked: checkedItems,
    });
  }

  confirmFields() {
    let checkedData = this.state.checked;
    var itemIds = [];
    checkedData.forEach(function (itm) {
      if (itm.indexOf("Item_") !== -1) {
        let itmId = itm.replace("Item_", "");
        itemIds.push(itmId);
      }
    });
    //   if (itemIds.length <= 0) {
    //       this.props.showSnackBar("Please select at least one item.", "error", 3000)
    //       return;
    //   }
    console.log(itemIds, checkedData, "Item-Ids");
    this.props.setVisibilityForColumns(itemIds);
  }

  render() {
    const classes = this.props.classes;

    const { nodes, checked, expanded } = this.state;
    return (
      <Dialog
        aria-labelledby="app-integrationDialog"
        open={this.props.showDialog}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        id="evMetaDialog"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.hideAppCatalogfieldsDialog()}>
            <CloseIcon />
          </div>
          <Typography variant={"h4"} className={classes.title}>
            Select Fields
          </Typography>
          <div className={classes.body}>
            <div style={{ display: "flex", alignItems: "center", marginTop: 25 }}>
              <Checkbox
                label={"Select All"}
                className={classes.criteriaAllSelect}
                color="primary"
                checked={this.state.selectAllItems}
                onChange={() => {
                  this.selectAllItems();
                }}
              />
              <Typography>Select All</Typography>
            </div>

            <CheckboxTree
              nodes={nodes}
              checked={checked}
              expanded={expanded}
              onCheck={(checked) => {
                console.log(checked, "Checkeddddd");
                this.setState({ checked });
              }}
              onExpand={(expanded) => this.setState({ expanded })}
              iconsClass="fa5"
              icons={{
                check: <CheckBoxIcon />,
                uncheck: <CheckBoxOutlineBlankIcon />,
                expandClose: <ChevronRightIcon />,
                expandOpen: <ExpandMoreIcon />,
                halfCheck: <CheckBoxOutlineBlankIcon />,
              }}
            />
          </div>
          <div className={classes.actions}>
            <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideAppCatalogfieldsDialog}>
              Cancel
            </SqureButton>
            <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.confirmFields}>
              Confirm
            </SqureButton>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(fieldsDialog));
