import React, { useState } from "react";
import { generate } from "shortid";
import classnames from "classnames";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, DialogActions, Slide, DialogTitle } from "@material-ui/core";
import SqureButton from "Components/Common/SqureButton";
import CommonCss from "commonCss";
// import { visibilityType } from './data'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
      },
      padding: ".375rem",
    },
    formGroup: {
      marginTop: 5,
      marginBottom: 16,
      "& input": {
        color: "#6C6C6C !important",
      },
    },
    dialogContent: {
      minHeight: 170,
      overflowY: "hidden",
      "& [class*=select__menu]": {
        maxHeight: 100,
      },
    },
  });

const AddTabDialog = ({ classes, onSubmit, onClose, data, loading }) => {
  const [name, setName] = useState("");
  const handleOnClose = () => {
    setName("");
    onClose();
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && name.length > 0) {
      onSubmit(name);
    }
  }

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleOnClose();
        }
      }}
      aria-labelledby=""
      open={true}
      TransitionComponent={Transition}
      scroll={"body"}
      fullWidth={true}
      className={classnames(classes.dialog)}
      id={`addNameDialog${generate()}`}
      style={{ zIndex: 10001 }}
    >
      <DialogTitle id={`form-dialog-title${generate()}`}>{data?.Name ? "Update" : "Create"}</DialogTitle>
      <DialogContent>
        <div className={classes.formGroup}>
          <label>Name</label>
          <input autoFocus className={classes.formControl} value={name} placeholder="Enter Name" onChange={(e) => setName(e.target?.value)} onKeyDown={onKeyDown}/>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ padding: "10px 24px" }}>
          <SqureButton
            variant={"outlined"}
            onClick={() => {
              handleOnClose();
            }}
          >
            Cancel
          </SqureButton>
          &nbsp; &nbsp; &nbsp;
          <SqureButton
            variant={"contained"}
            disabled={name.length === 0 || loading}
            onClick={() => {
              onSubmit(name);
            }}
          >
            Save
          </SqureButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(withStyles(styles)(AddTabDialog));
