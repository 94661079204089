import {
  SHOW_ADD_AUTOMATION_DIALOG, HIDE_ADD_AUTOMATION_DIALOG,
  START_ADD_NEW_AUTOMATION, END_ADD_NEW_AUTOMATION,
  START_UPDATE_AUTOMATION, END_UPDATE_AUTOMATION,
  START_FETCH_AUTOMATIONS, END_FETCH_AUTOMATIONS,
  START_DELETE_AUTOMATION, END_DELETE_AUTOMATION,
  START_FETCH_AUTOMATION_DATA, END_FETCH_AUTOMATION_DATA,
  START_FETCH_MASTER_TRIGGERS, END_FETCH_MASTER_TRIGGERS,
  START_FETCH_MASTER_ACTIONS, END_FETCH_MASTER_ACTIONS,
  START_ADD_NEW_AUTOMATION_ACTION, END_ADD_NEW_AUTOMATION_ACTION,
  START_UPDATE_AUTOMATION_ACTION, END_UPDATE_AUTOMATION_ACTION,
  START_DELETE_AUTOMATION_ACTION, END_DELETE_AUTOMATION_ACTION,
  START_FETCH_AUTOMATION_ACTION_DATA, END_FETCH_AUTOMATION_ACTION_DATA,
  START_ADD_NEW_AUTOMATION_ACTION_CONNECTION, END_ADD_NEW_AUTOMATION_ACTION_CONNECTION,
  START_DELETE_AUTOMATION_ACTION_CONNECTION, END_DELETE_AUTOMATION_ACTION_CONNECTION,
  START_FETCH_AUTOMATION_ACTION_CONNECTION_DATA, END_FETCH_AUTOMATION_ACTION_CONNECTION_DATA,
  START_UPDATE_AUTOMATION_CONNECTION, END_UPDATE_AUTOMATION_CONNECTION,
  START_FETCH_AUTOMATION_COMPONENTS, END_FETCH_AUTOMATION_COMPONENTS,
  START_FETCH_AUTOMATION_COMPONENT_DETAILS, END_FETCH_AUTOMATION_COMPONENT_DETAILS,
  START_FETCH_AUTOMATION_COMPONENT_VARIABLES, END_FETCH_AUTOMATION_COMPONENT_VARIABLES,
  START_FETCH_AUTOMATION_JOBS, END_FETCH_AUTOMATION_JOBS,
  START_FETCH_AUTOMATION_JOB, END_FETCH_AUTOMATION_JOB,
  START_FETCH_AUTOMATION_JOB_COMPONENT, END_FETCH_AUTOMATION_JOB_COMPONENT,
  BUILDER_SUMMARY_SELECTED_TAB,
  SHOW_AUTOMATION_OPTIONAL_FIELD_SELECT_DIALOG, HIDE_AUTOMATION_OPTIONAL_FIELD_SELECT_DIALOG,
  START_UPDATE_AUTOMATION_CONFIG_ON_CHANGE, END_UPDATE_AUTOMATION_CONFIG_ON_CHANGE,
  START_FETCH_ADMIN_AUTOMATIONS, END_FETCH_ADMIN_AUTOMATIONS,
  START_ADD_NEW_EXISTING_AUTOMATION, END_ADD_NEW_EXISTING_AUTOMATION,
} from "./action";


// Initial State
const initState = {
  dialogAddAutomationOpen: false,

  addNewAutomationProgress: false,
  addNewAutomationError: null,
  newAutomationData: null,

  updateAutomationProgress: false,
  updateAutomationError: null,
  updatedAutomationData: null,

  fetchAutomationsProgress: false,
  fetchAutomationsError: null,
  automationDatas: [],

  deleteAutomationProgress: false,
  deleteAutomationError: null,

  fetchAutomationProgress: false,
  fetchAutomationError: null,
  automationDetails: {},

  fetchMasterTriggerProgress: false,
  fetchMasterTriggerError: null,
  masterTriggers: [],

  fetchMasterActionProgress: false,
  fetchMasterActionError: null,
  masterActions: [],

  fetchActionsProgress: false,
  fetchActionsError: null,
  automationActions: null,

  addActionProgress: false,
  addActionError: null,
  addActionSuccess: null,

  updateActionProgress: false,
  updateActionError: null,
  updateActionSuccess: null,

  deleteActionProgress: false,
  deleteActionError: null,
  deleteActionSuccess: null,

  addActionConnectionProgress: false,
  addActionConnectionError: null,
  addActionConnectionSuccess: null,

  deleteActionConnectionProgress: false,
  deleteActionConnectionError: null,
  deleteActionConnectionSuccess: null,

  fetchActionConnectionsProgress: false,
  fetchActionConnectionsError: null,
  actionConnections: null,

  updateConnectionProgress: false,
  updateConnectionError: null,
  updateConnectionSuccess: null,

  fetchComponentsProgress: false,
  fetchComponentsError: null,
  automationComponents: null,

  fetchComponentDetailsProgress: false,
  fetchComponentDetailsError: null,
  componentDetails: null,

  fetchComponentVariablesProgress: false,
  fetchComponentVariablesError: null,
  componentVariables: null,

  fetchAutomationJobsProgress: false,
  fetchAutomationJobsError: null,
  automationJobs: null,

  fetchAutomationJobProgress: false,
  fetchAutomationJobError: null,
  automationJob: null,

  fetchAutomationJobComponentProgress: false,
  fetchAutomationJobComponentError: null,
  jobsComponents: null,

  builderSummarySelectedTab: 0,
  dialogSelectAutomationOptionFieldsOpen: false,

  updateActionConfigProgress: false,
  updateActionConfigError: null,
  updateActionConfigSuccess: null,

  adminAutomationsProgress: false,
  adminAutomationsError: null,
  adminAutomationsSuccess: null,

  addExistingTemplateAutomationsProgress: false,
  addExistingTemplateAutomationsError: null,
  addExistingTemplateAutomationsSuccess: null,
}


export function automationReducers(state = initState, action) {
  switch (action.type) {

    case SHOW_ADD_AUTOMATION_DIALOG:
      return {
        ...state,
        dialogAddAutomationOpen: true,
      };

    case HIDE_ADD_AUTOMATION_DIALOG:
      return {
        ...state,
        dialogAddAutomationOpen: false,
      };

    case START_ADD_NEW_AUTOMATION:
      return {
        ...state,
        addNewAutomationProgress: true,
        addNewAutomationError: null,
        newAutomationData: null
      }

    case END_ADD_NEW_AUTOMATION:
      return {
        ...state,
        addNewAutomationProgress: false,
        addNewAutomationError: action.payload.error,
        newAutomationData: action.payload.success
      }

    case START_UPDATE_AUTOMATION:
      return {
        ...state,
        updateAutomationProgress: true,
        updateAutomationError: null,
        updatedAutomationData: null,
      }

    case END_UPDATE_AUTOMATION:
      return {
        ...state,
        updateAutomationProgress: false,
        updateAutomationError: action.payload.error,
        updatedAutomationData: action.payload.success,
      }

    case START_FETCH_AUTOMATIONS:
      return {
        ...state,
        fetchAutomationsProgress: true,
        fetchAutomationsError: null,
      }

    case END_FETCH_AUTOMATIONS:
      let AutomationsDatas = state.automationDatas;
      let successFetchData = action.payload.success;
      if (action.payload.error === null) {
        if (successFetchData.page === 0) {
          AutomationsDatas = successFetchData
        } else {
          AutomationsDatas.Data = [...AutomationsDatas.Data, ...successFetchData.Data]
        }
      }

      return {
        ...state,
        fetchAutomationsProgress: false,
        fetchAutomationsError: action.payload.error,
        automationDatas: successFetchData
      }

    case START_DELETE_AUTOMATION:
      return {
        ...state,
        deleteAutomationProgress: true,
        deleteAutomationError: null,
      }

    case END_DELETE_AUTOMATION:
      const { automationId } = action.payload;
      var delErrorData = action.payload.error;
      let automationDatas = state.automationDatas;

      if (delErrorData === null) {
        var pDatas = automationDatas.Data;
        var ptaskIndex = pDatas.findIndex(o => o.ID === automationId);
        automationDatas.Data.splice(ptaskIndex, 1);
      }

      return {
        ...state,
        deleteAutomationProgress: false,
        deleteAutomationError: action.payload.error,
        automationDatas: automationDatas,
      }

    case START_FETCH_AUTOMATION_DATA:
      return {
        ...state,
        fetchAutomationProgress: true,
        fetchAutomationError: null,
      }

    case END_FETCH_AUTOMATION_DATA:
      return {
        ...state,
        fetchAutomationProgress: false,
        fetchAutomationError: action.payload.error,
        automationDetails: action.payload.success
      }

    case START_FETCH_MASTER_TRIGGERS:
      return {
        ...state,
        fetchMasterTriggerProgress: true,
        fetchMasterTriggerError: null,
        masterTriggers: [],
      }

    case END_FETCH_MASTER_TRIGGERS:
      return {
        ...state,
        fetchMasterTriggerProgress: false,
        fetchMasterTriggerError: action.payload.error,
        masterTriggers: action.payload.success,
      }

    case START_FETCH_MASTER_ACTIONS:
      return {
        ...state,
        fetchMasterActionProgress: true,
        fetchMasterActionError: null,
        masterActions: [],
      }

    case END_FETCH_MASTER_ACTIONS:
      return {
        ...state,
        fetchMasterActionProgress: false,
        fetchMasterActionError: action.payload.error,
        masterActions: action.payload.success,
      }

    case START_FETCH_AUTOMATION_ACTION_DATA:
      return {
        ...state,
        fetchActionsProgress: true,
        fetchActionsError: null,
        automationActions: null,
      }

    case END_FETCH_AUTOMATION_ACTION_DATA:
      return {
        ...state,
        fetchActionsProgress: false,
        fetchActionsError: action.payload.error,
        automationActions: action.payload.success,
      }

    case START_ADD_NEW_AUTOMATION_ACTION:
      return {
        ...state,
        addActionProgress: true,
        addActionError: null,
        addActionSuccess: null,
      }

    case END_ADD_NEW_AUTOMATION_ACTION:
      return {
        ...state,
        addActionProgress: false,
        addActionError: action.payload.error,
        addActionSuccess: action.payload.success,
      }

    case START_UPDATE_AUTOMATION_ACTION:
      return {
        ...state,
        updateActionProgress: true,
        updateActionError: null,
        updateActionSuccess: null,
      }

    case END_UPDATE_AUTOMATION_ACTION:
      return {
        ...state,
        updateActionProgress: false,
        updateActionError: action.payload.error,
        updateActionSuccess: action.payload.success,
      }

    case START_DELETE_AUTOMATION_ACTION:
      return {
        ...state,
        deleteActionProgress: true,
        deleteActionError: null,
        deleteActionSuccess: null,
      }

    case END_DELETE_AUTOMATION_ACTION:
      return {
        ...state,
        deleteActionProgress: false,
        deleteActionError: action.payload.error,
        deleteActionSuccess: action.payload.success,
      }

    case START_ADD_NEW_AUTOMATION_ACTION_CONNECTION:
      return {
        ...state,
        addActionConnectionProgress: true,
        addActionConnectionError: null,
        addActionConnectionSuccess: null
      }

    case END_ADD_NEW_AUTOMATION_ACTION_CONNECTION:
      return {
        ...state,
        addActionConnectionProgress: false,
        addActionConnectionError: action.payload.error,
        addActionConnectionSuccess: action.payload.success
      }

    case START_DELETE_AUTOMATION_ACTION_CONNECTION:
      return {
        ...state,
        deleteActionConnectionProgress: true,
        deleteActionConnectionError: null,
        deleteActionConnectionSuccess: null
      }

    case END_DELETE_AUTOMATION_ACTION_CONNECTION:
      return {
        ...state,
        deleteActionConnectionProgress: false,
        deleteActionConnectionError: action.payload.error,
        deleteActionConnectionSuccess: action.payload.success
      }

    case START_UPDATE_AUTOMATION_CONNECTION:
      return {
        ...state,
        updateConnectionProgress: true,
        updateConnectionError: null,
        updateConnectionSuccess: null,
      }

    case END_UPDATE_AUTOMATION_CONNECTION:
      return {
        ...state,
        updateConnectionProgress: false,
        updateConnectionError: action.payload.error,
        updateConnectionSuccess: action.payload.success,
      }

    case START_FETCH_AUTOMATION_ACTION_CONNECTION_DATA:
      return {
        ...state,
        fetchActionConnectionsProgress: true,
        fetchActionConnectionsError: null,
        actionConnections: null
      }

    case END_FETCH_AUTOMATION_ACTION_CONNECTION_DATA:
      return {
        ...state,
        fetchActionConnectionsProgress: false,
        fetchActionConnectionsError: action.payload.error,
        actionConnections: action.payload.success
      }

    case START_FETCH_AUTOMATION_COMPONENTS:
      return {
        ...state,
        fetchComponentsProgress: true,
        fetchComponentsError: null,
        automationComponents: null,
      }

    case END_FETCH_AUTOMATION_COMPONENTS:
      return {
        ...state,
        fetchComponentsProgress: false,
        fetchComponentsError: action.payload.error,
        automationComponents: action.payload.success,
      }

    case START_FETCH_AUTOMATION_COMPONENT_DETAILS:
      return {
        ...state,
        fetchComponentDetailsProgress: true,
        fetchComponentDetailsError: null,
        componentDetails: null,
      }

    case END_FETCH_AUTOMATION_COMPONENT_DETAILS:
      return {
        ...state,
        fetchComponentDetailsProgress: false,
        fetchComponentDetailsError: action.payload.error,
        componentDetails: action.payload.success,
      }

    case START_FETCH_AUTOMATION_COMPONENT_VARIABLES:
      return {
        ...state,
        fetchComponentVariablesProgress: true,
        fetchComponentVariablesError: null,
        componentVariables: null,
      }

    case END_FETCH_AUTOMATION_COMPONENT_VARIABLES:
      return {
        ...state,
        fetchComponentVariablesProgress: false,
        fetchComponentVariablesError: action.payload.error,
        componentVariables: action.payload.success,
      }

    case START_FETCH_AUTOMATION_JOBS:
      return {
        ...state,
        fetchAutomationJobsProgress: true,
        fetchAutomationJobsError: null,
        automationJobs: null,
      }

    case END_FETCH_AUTOMATION_JOBS:
      // let automationsJobsDatas = state.automationDatas;
      let successJobsFetchData = action.payload.success;
      // console.log(successJobsFetchData,'successJobsFetchData')
      // if (action.payload.error === null) {
      //   if (successJobsFetchData.page === 0) {
      //     automationsJobsDatas = successJobsFetchData
      //   } else {
      //     automationsJobsDatas.Data = [...automationsJobsDatas.Data, ...successJobsFetchData.Data]
      //   }
      // }

      return {
        ...state,
        fetchAutomationJobsProgress: false,
        fetchAutomationJobsError: action.payload.error,
        automationJobs: successJobsFetchData,
      }

    case START_FETCH_AUTOMATION_JOB:
      return {
        ...state,
        fetchAutomationJobProgress: true,
        fetchAutomationJobError: null,
        automationJob: null,
      }
    case END_FETCH_AUTOMATION_JOB:
      return {
        ...state,
        fetchAutomationJobProgress: false,
        fetchAutomationJobError: action.payload.error,
        automationJob: action.payload.success,
      }

    case START_FETCH_AUTOMATION_JOB_COMPONENT:
      return {
        ...state,
        fetchAutomationJobComponentProgress: true,
        fetchAutomationJobComponentError: null,
        jobsComponents: null,
      }
    case END_FETCH_AUTOMATION_JOB_COMPONENT:
      return {
        ...state,
        fetchAutomationJobComponentProgress: false,
        fetchAutomationJobComponentError: action.payload.error,
        jobsComponents: action.payload.success,
      }

    case BUILDER_SUMMARY_SELECTED_TAB:
      return {
        ...state,
        builderSummarySelectedTab: action.payload.value
      }

    case SHOW_AUTOMATION_OPTIONAL_FIELD_SELECT_DIALOG:
      return {
        ...state,
        dialogSelectAutomationOptionFieldsOpen: true,
      };

    case HIDE_AUTOMATION_OPTIONAL_FIELD_SELECT_DIALOG:
      return {
        ...state,
        dialogSelectAutomationOptionFieldsOpen: false,
      };

    case START_UPDATE_AUTOMATION_CONFIG_ON_CHANGE:
      return {
        ...state,
        updateActionConfigProgress: true,
        updateActionConfigError: null,
        updateActionConfigSuccess: null,
      }

    case END_UPDATE_AUTOMATION_CONFIG_ON_CHANGE:
      return {
        ...state,
        updateActionConfigProgress: false,
        updateActionConfigError: action.payload.error,
        updateActionConfigSuccess: action.payload.success,
      }

    case START_FETCH_ADMIN_AUTOMATIONS:
      return {
        ...state,
        adminAutomationsProgress: true,
        adminAutomationsError: null,
        adminAutomationsSuccess: null,
      }

    case END_FETCH_ADMIN_AUTOMATIONS:
      return {
        ...state,
        adminAutomationsProgress: false,
        adminAutomationsError: action.payload.error,
        adminAutomationsSuccess: action.payload.success,
      }

    case START_ADD_NEW_EXISTING_AUTOMATION:
      return {
        ...state,
        addExistingTemplateAutomationsProgress: true,
        addExistingTemplateAutomationsError: null,
        addExistingTemplateAutomationsSuccess: null,
      }

    case END_ADD_NEW_EXISTING_AUTOMATION:
      return {
        ...state,
        addExistingTemplateAutomationsProgress: false,
        addExistingTemplateAutomationsError: action.payload.error,
        addExistingTemplateAutomationsSuccess: action.payload.success,
      }


    default:
      return state;
  }

}