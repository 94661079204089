import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';
import { generate } from 'shortid';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button, Grid, CircularProgress, MenuItem, Divider, Popover, Paper, Tab, Tabs } from '@material-ui/core';
import { formOptions } from '../Constant'

import { showSnackBar } from "redux/snackbar/action";
import AppBarV2 from "../../../../AppBarV2";
import CreateFormDialog from './Common/CreateFormDialog';
import TemplateCard from './Common/Card'

import { fetchMasterForms } from "redux/forms/master/action";
import { showUsedPlacesDialog, fetchOrgForms, showAddFormDialog, showImportFormDialog, deleteOrgFormData, setFormActiveTab } from "redux/forms/org/action";

import AddFormDialog from "./Common/AddFormDialog";
import CommonCss from 'commonCss';
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import UsedPlacesDialog from "Components/Common/Dialogs/UsedPlacesDialog";

const connectedProps = (state) => ({
  masterForms: state.masterForms.forms,
  fetchMasterFormProgress: state.masterForms.fetchMasterFormProgress,
  isShowAddFormDialog: state.orgForms.isShowAddFormDialog,
  fetchOrgFormsProgress: state.orgForms.fetchOrgFormsProgress,
  forms: state.orgForms.forms,
  deleteOrgFormProgress: state.orgForms.deleteOrgFormProgress,
  formActiveTab: state.orgForms.formActiveTab,
  usedPlacesDialogOpen: state.orgForms.usedPlacesDialogOpen,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchMasterForms: fetchMasterForms,
  showAddFormDialog: showAddFormDialog,
  showImportFormDialog: showImportFormDialog,
  fetchOrgForms: fetchOrgForms,
  deleteOrgFormData: deleteOrgFormData,
  setFormActiveTab: setFormActiveTab,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  showUsedPlacesDialog: showUsedPlacesDialog,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootPaper: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    borderBottom: "solid 1px #e0e0e0",
  },
  dialogContent: {
    padding: theme.spacing(6, 12) + " !important",
    backgroundColor: "#F7F7F7",
    maxHeight: "100%",
    height: "100%",
    position: "relative",
    overflow: "auto"
  },
  title: {
    color: "#282D30",
    fontSize: theme.spacing(2.5),
    marginBottom: 0
  },
  actions: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2),
    background: "#fff",
    textAlign: "right"
  },
  templates: {
    padding: theme.spacing(2, 0),
    marginBottom: 40
  },
  tca: {
    marginTop: '10px',
  },
  iconContainer: {
    marginRight: theme.spacing(3),
    cursor: "pointer",
    display: "flex",
    marginBottom: theme.spacing(2)
  },
  image: {
    width: theme.spacing(3.5),
  },
  modalappBar: {
    display: "flex"
  },
  criteriaRequirement: {
    width: "90%",
    margin: '10px auto',
    paddingBottom: 50
  },
  headSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  menuItem: {
    color: "#74798C",
    fontSize: theme.spacing(2),
    padding: theme.spacing(2.2),
    minWidth: 140,
  },
  loader: {
    textAlign: 'center'
  },
  bottomContainer: {
    position: 'fixed',
    width: '100%',
    left: 0,
    background: '#fff',
    height: 70,
    bottom: 0,
    boxShadow: '1px 1px 6px 2px #ddd',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '250px',
    justifyContent: 'flex-end',
    paddingRight: 46,
    zIndex: 4
  },
  addNewCriteria: {
    color: '#FFFFFF',
    borderColor: '#3C3C3C',
    backgroundColor: '#3C3C3C',
    border: '1px solid',
    padding: '7px 8px',
    '&:hover': {
      color: '#FFFFFF',
      borderColor: '#3C3C3C',
      backgroundColor: '#3C3C3C'
    }
  },
  rootContainer: CommonCss.rootContainer
});


const CustomTabs = withStyles({
  root: {
    backgroundColor: 'tarsparent !imporant',
  },
  indicator: {
    backgroundColor: '#4A87F8',
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    '&:hover': {
      color: '#4A87F8',
      opacity: 1,
    },
    '&:focus': {
      color: '#4A87F8',
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

class Forms extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 1000,
      anchorEl: null,
      isCheckBox: false,
      tab: 0,
      isOpenCreateFormDialog: false,
      templates: [],
      selectedForm: null,
      deleteId: null,
    }
    this.selectTemplateForImport = this.selectTemplateForImport.bind(this)
  }

  componentDidMount() {
    this.props.fetchMasterForms(this.state.page, this.state.pageSize);
    this.props.fetchOrgForms(this.state.page, this.state.pageSize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.deleteOrgFormProgress === false && prevProps.deleteOrgFormProgress === true) {
      this.props.showSnackBar("Form deleted successfully", "success", 3000);
      this.props.fetchOrgForms(this.state.page, this.state.pageSize);
    }

    if (this.props.isShowAddFormDialog === false && prevProps.isShowAddFormDialog === true) {
      this.setState({
        selectedForm: null,
        isCheckBox: false,
      })
    }
  }

  selectTemplateForImport(checked, formId, type) {
    if (checked) {
      this.setState({
        selectedForm: {
          formId, type
        }
      })
    } else {
      this.setState({
        selectedForm: null
      })
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleChange = (event, value) => {
    event.preventDefault()
    this.props.setFormActiveTab(value)
    // this.setState({
    //   selectedForm: null,
    //   isCheckBox: false,
    // })
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  navigate = (menu) => {
    if (menu.isCheckBox) {
      this.setState({
        isCheckBox: true
      })
    } else if (menu.addManually) {
      this.setState({
        isOpenCreateFormDialog: true
      })
    } else if (menu.key === "importForm") {
      this.props.showImportFormDialog();
    }
    this.handleClose();
  };

  defaultFlags(formId, type) {
    return {
      evaluation: true,
      review: false
    }
  }

  handleDelete = (id) => {
    var me = this;
    this.setState({ deleteId: id }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteOrgFormData(this.state.deleteId);
    }
  }


  render() {
    const classes = this.props.classes;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "newTemplatePopover" : undefined;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    console.log(this.props.forms, 'Formssss')

    return (
      <>
        <div classes={{ root: classes.dialogContent }} >
          <AppBarV2 title="Forms" withBack={true} />
          <div className={classes.rootContainer}>

            <div className={classes.headSection} style={{ display: 'flex', justifyContent: 'end', width: '100%', marginTop: 20 }}>
              {false && <Paper className={classes.rootPaper}>
                <CustomTabs
                  value={this.props.formActiveTab}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <CustomTab label="Expent's Form Library" />
                  <CustomTab label="Custom" />
                </CustomTabs>
              </Paper>}

              {
                this.props.formActiveTab === 0 && this.state.isCheckBox && <Button className={classes.newUserBtn} onClick={() => {
                  this.setState({
                    isCheckBox: false
                  })
                }}>
                  Cancel Import
                </Button>
              }

              {
                this.props.formActiveTab === 1 && <div>
                  {!this.state.isCheckBox && <Button className={classes.newUserBtn} onClick={this.handleClick}>
                    + New Form
                  </Button>}

                  {this.state.isCheckBox && <Button className={classes.newUserBtn} onClick={() => {
                    this.setState({
                      isCheckBox: false
                    })
                  }}>
                    Cancel Import
                  </Button>}

                  <Popover
                    className={classes.popover}
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.handleClose()}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {formOptions?.map((item, childIndex) => {
                      return (
                        <div key={childIndex}>
                          {" "}
                          <MenuItem
                            value={item.label}
                            className={classes.menuItem}
                            onClick={() => this.navigate(item)}
                          >
                            {item.label}
                          </MenuItem>{" "}
                          <Divider />
                        </div>
                      );
                    })}
                  </Popover>
                </div>
              }
            </div >
            {
              this.props.formActiveTab === 0 && (
                <div className={classes.templates}>
                  {this.props.fetchOrgFormsProgress === true && <div className={classes.loader}>
                    <CircularProgress />
                  </div>}
                  {this.props.masterForms !== null && (
                    <Grid container spacing={3}>
                      {this.props.masterForms.map(template => {
                        return (
                          <Grid item xs={12} sm={6} md={4} lg={3} key={generate()}>
                            <TemplateCard
                              isCheckBox={this.state.isCheckBox}
                              template={template}
                              formId={this.props.formId}
                              selectedForm={this.state.selectedForm}
                              selectTemplateForImport={this.selectTemplateForImport}
                              showUsedPlacesDialog={this.props.showUsedPlacesDialog}
                              deleteOrgForm={this.props.deleteOrgFormData}
                              type="MASTER"
                              onClick={url => this.props.history.push(url)}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  )}
                  {this.props.masterForms !== null && this.props.masterForms.length <= 0 && <div style={{ textAlign: 'center', marginTop: 20 }}>
                    No Forms
                  </div>}
                </div>
              )
            }
            {
              this.props.formActiveTab === 1 && (
                <div className={classes.templates}>
                  {this.props.fetchOrgFormsProgress === true && <div className={classes.loader}>
                    <CircularProgress />
                  </div>}
                  {this.props.forms !== null && (
                    <Grid container spacing={3}>
                      {this.props.forms.map((template) => {
                        return (
                          <Grid item xs={12} sm={6} md={4} lg={3} key={generate()}>
                            <TemplateCard
                              isCheckBox={this.state.isCheckBox}
                              template={template}
                              formId={this.props.formId}
                              selectedForm={this.state.selectedForm}
                              selectTemplateForImport={this.selectTemplateForImport}
                              showUsedPlacesDialog={this.props.showUsedPlacesDialog}
                              deleteOrgForm={(id) => {
                                if (template?.UsedInWorkflowCount > 0) {
                                  this.props.showUsedPlacesDialog({ ...template, isDelete: true, dialogType: "FormTemplate" })
                                } else {
                                  this.handleDelete(id)
                                }
                              }}
                              type="ORG"
                              onClick={url => this.props.history.push(url)}
                            />
                          </Grid>
                        )
                      })
                      }
                    </Grid>
                  )}
                  {this.props.forms !== null && this.props.forms.length <= 0 && <div style={{ textAlign: 'center', marginTop: 20 }}>
                    No Forms
                  </div>}
                </div>
              )
            }
          </div >
        </div >
        {
          this.state.selectedForm != null && (
            <div className={classes.bottomContainer}>
              <Button className={classes.addNewCriteria} onClick={() => {
                this.props.showAddFormDialog({
                  saveButtonText: 'Save',
                })
                this.setState({
                  isCheckBox: false
                })
              }} > Create Form</Button>
            </div>
          )
        }
        {
          this.state.isOpenCreateFormDialog && (
            <CreateFormDialog
              isOpen={this.state.isOpenCreateFormDialog}
              hideDialog={() => this.setState({ isOpenCreateFormDialog: false })}
            />
          )
        }
        {
          this.props.isShowAddFormDialog === true && <AddFormDialog
            selectedForm={this.state.selectedForm}
            activeTab={this.props.formActiveTab}
          />
        }
        <MessageDialog messageData={messageData} callBack={this.callBack} />
        {this.props.usedPlacesDialogOpen && <UsedPlacesDialog callBack={(data) => this.props.deleteOrgFormData(data.ID)} />}
      </>
    )
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Forms));