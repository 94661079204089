import {
  CircularProgress,
  Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({

});

const connectionActions = {
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
    margin: 'auto',
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  title: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
  },
  body: {
    textAlign: 'center'
  }
});

class SendLoader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  render() {
    const classes = this.props.classes;
    return <Dialog
      onClose={() => { }}
      aria-labelledby="app-integrationDialog"
      open={true}
      TransitionComponent={Transition}
      fullWidth={false}
      maxWidth={"sm"}
      scroll="body"
      id="loaderSendQuestion"
    >
      <DialogContent classes={{ root: classes.dialogContent }} >
        <div className={classes.body}>
          <CircularProgress />
          {/* <Typography>Sending Questions to Vendor</Typography> */}
        </div>
      </DialogContent>
    </Dialog>
  }
}

SendLoader.defaultProps = {
  isWorkFlow: false,
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(SendLoader));