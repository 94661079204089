import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import classnames from "classnames";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    switchBtn: {
      textAlign: "center",
      marginBottom: 20,
      width: "70%",
      // maxWidth: 700,
      // minWidth: 700,
      maxWidth: 600,
      minWidth: 600,
      margin: "0 auto",
    },
    filBtn: {
      display: "flex",
      alignItems: "center",
      background: "#4a87f8",
      padding: 2,
      borderRadius: 4,
    },
    software: {
      flex: 1,
      cursor: "pointer",
    },
    hardware: {
      flex: 1,
      cursor: "pointer",
    },
    active: {
      background: "#fff",
      borderRadius: 4,
      // transition: 'all 0.5s 0s ease'
    },
    notActive: {
      color: "#fff !important",
    },
    itemText: {
      color: "#5f6368",
      fontSize: 15,
      padding: 5,
      fontWeight: 500,
      lineHeight: 1,
    },
  });

class ProductTypeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      type: "VENDOR",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(type) {
    console.log(type);
    this.setState({type: type}, () => {
        this.props.onChange(type);
      }
    );
  }

  componentDidMount() {
    this.setState({
      type: this.props.type,
    });
  }

  render() {
    var classes = this.props.classes;
    const { type } = this.state;
    const { isShowVendorTab, isShowProductTab, isShowServiceTab} = this.props;
    if(!isShowVendorTab && !isShowProductTab && !isShowServiceTab){
      return null
    }
    return (
      <div className={classes.switchBtn}>
        {/* <Typography>Software Products</Typography>
        <Switch color="primary" checked={isChecked} onChange={this.handleChange} name="gilad" />
        <Typography>Hardware Products</Typography> */}

        <div className={classes.filBtn}>
          {isShowVendorTab && <div className={classnames(classes.software, type === "VENDOR" ? classes.active : "")} onClick={() => this.handleChange("VENDOR")}>
            <Typography className={classnames(classes.itemText, type === "VENDOR" ? "" : classes.notActive)}>Vendors</Typography>
          </div>}
          {isShowProductTab && <div className={classnames(classes.hardware, type === "SOFTWARE" ? classes.active : "")} onClick={() => this.handleChange("SOFTWARE")}>
            <Typography className={classnames(classes.itemText, type === "SOFTWARE" ? "" : classes.notActive)}>Software Products</Typography>
          </div>}
          {!this.props.hideHardware ? <div className={classnames(classes.hardware, type === "HARDWARE" ? classes.active : "")} onClick={() => this.handleChange("HARDWARE")}>
            <Typography className={classnames(classes.itemText, type === "HARDWARE" ? "" : classes.notActive)}>Hardware Products</Typography>
          </div> : null}
          {isShowServiceTab && <div className={classnames(classes.hardware, type === "SERVICE" ? classes.active : "")} onClick={() => this.handleChange("SERVICE")}>
            <Typography className={classnames(classes.itemText, type === "SERVICE" ? "" : classes.notActive)}>Services</Typography>
          </div>}
        </div>
      </div>
    );
  }
}

ProductTypeFilter.defaultProps = {
  isShowVendorTab: true,
  isShowProductTab: true,
  isShowServiceTab: true,
};

export default connector(compose(withRouter, withStyles(styles))(ProductTypeFilter));
