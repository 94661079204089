import {
  START_VENDORD_EVALUATION_PROGRESS,
  END_VENDORD_EVALUATION_PROGRESS,
  START_INPROGESS_EVALUATION,
  END_INPROGESS_EVALUATION,
  START_COMPLETED_EVALUATION,
  END_COMPLETED_EVALUATION,
  START_FETCH_SELLER_LIBRARY,
  END_FETCH_SELLER_LIBRARY,
  START_ADD_REQ_TO_SELLER_LIBRARY,
  END_ADD_REQ_TO_SELLER_LIBRARY,
  START_REMOVE_REQ_FROM_SELLER_LIBRARY,
  END_REMOVE_REQ_FROM_SELLER_LIBRARY,
  START_CHECK_REQ_IN_SELLER_LIBRARY,
  END_CHECK_REQ_IN_SELLER_LIBRARY,
  START_IMPORT_TO_SELLER_LIBRARY,
  END_IMPORT_TO_SELLER_LIBRARY,
} from "./action";

const initState = {
  // API's
  vendorEvaluationInProgress: false,
  vendorEvaluations: {},
  evaluateStatusCountProgress: false,
  statusCount: [],
  inProgressEvaluationProgress: false,
  inProgress: [],
  inProgressTotal: 0,
  completedEvaluationProgress: false,
  completed: [],
  completedTotal: 0,
  inProgressEvaluationError: null,

  fetchSellerLibraryProgress: false,
  fetchSellerLibraryError: null,
  sellerLibrary: {},

  addReqToSellerLibraryProgress: false,
  addReqToSellerLibraryError: null,
  sellerLibraryAddData: {},

  removeReqFromSellerLibraryProgress: false,
  removeReqFromSellerLibraryError: null,
  sellerLibraryRemoveData: {},

  checkReqInSellerLibraryProgress: false,
  checkReqInSellerLibraryError: null,
  checkReqData: null,

  importToSellerLibraryProgress: false,
  importToSellerLibraryError: null,
  sellerLibraryImportData: {},
};

export function sellerEvaluateReducer(state = initState, action) {
  switch (action.type) {
    // Vendor Evaluation
    case START_VENDORD_EVALUATION_PROGRESS:
      var response = action.payload;
      if (response.onInit) {
        return {
          ...state,
          vendorEvaluationInProgress: true,
          vendorEvaluations: {},
        };
      }
      return {
        ...state,
        vendorEvaluationInProgress: true,
      };

    case END_VENDORD_EVALUATION_PROGRESS:
      var evaluation = {
        Data: [],
        total: 0,
      };
      response = action.payload.success;
      if (response !== null) {
        // component init
        if (response.onInit) {
          evaluation.total = response.total;
          evaluation.Data = response.Data;
        } else {
          // on pagination
          evaluation.total = response.total;
          if (state.vendorEvaluations?.Data?.length > 0) {
            evaluation.Data = [...state.vendorEvaluations.Data, ...response.Data];
          } else {
            evaluation.Data = response.Data;
          }
        }
      }
      return {
        ...state,
        vendorEvaluationInProgress: false,
        vendorEvaluations: evaluation,
      };
    case START_COMPLETED_EVALUATION:
      return {
        ...state,
        completedEvaluationProgress: true,
        completed: [],
        completedTotal: 0,
      };
    case END_COMPLETED_EVALUATION:
      evaluation = {
        Data: [],
        total: 0,
      };
      response = action.payload.success;
      if (response !== null) {
        evaluation.total = response.total;
        evaluation.Data = response.Data;
      }
      return {
        ...state,
        completedEvaluationProgress: false,
        completed: evaluation.Data,
        completedTotal: evaluation.total,
      };
    case START_INPROGESS_EVALUATION:
      return {
        ...state,
        inProgressEvaluationProgress: true,
        inProgressEvaluationError: null,
        inProgress: [],
        inProgressTotal: 0,
      };
    case END_INPROGESS_EVALUATION:
      evaluation = {
        Data: [],
        total: 0,
      };
      response = action.payload.success;
      if (response !== null) {
        evaluation.total = response.total;
        evaluation.Data = response.Data;
      }
      return {
        ...state,
        inProgressEvaluationProgress: false,
        inProgress: evaluation.Data,
        inProgressTotal: evaluation.total,
        inProgressEvaluationError: action.payload.error,
      };

    case START_FETCH_SELLER_LIBRARY:
      return {
        ...state,
        fetchSellerLibraryProgress: true,
        fetchSellerLibraryError: null,
      };

    case END_FETCH_SELLER_LIBRARY:
      return {
        ...state,
        fetchSellerLibraryProgress: false,
        fetchSellerLibraryError: action.payload.error,
        sellerLibrary: action.payload.success,
      };

    case START_ADD_REQ_TO_SELLER_LIBRARY:
      return {
        ...state,
        addReqToSellerLibraryProgress: true,
        addReqToSellerLibraryError: null,
        sellerLibraryAddData: {},
      };

    case END_ADD_REQ_TO_SELLER_LIBRARY:
      return {
        ...state,
        addReqToSellerLibraryProgress: false,
        addReqToSellerLibraryError: action.payload.error,
        sellerLibraryAddData: action.payload.success,
      };

    case START_REMOVE_REQ_FROM_SELLER_LIBRARY:
      return {
        ...state,
        removeReqFromSellerLibraryProgress: true,
        removeReqFromSellerLibraryError: null,
        sellerLibraryRemoveData: {},
      };

    case END_REMOVE_REQ_FROM_SELLER_LIBRARY:
      return {
        ...state,
        removeReqFromSellerLibraryProgress: false,
        removeReqFromSellerLibraryError: action.payload.error,
        sellerLibraryRemoveData: action.payload.success,
      };

    case START_CHECK_REQ_IN_SELLER_LIBRARY:
      return {
        ...state,
        checkReqInSellerLibraryProgress: true,
        checkReqInSellerLibraryError: null,
        checkReqData: null,
      };

    case END_CHECK_REQ_IN_SELLER_LIBRARY:
      return {
        ...state,
        checkReqInSellerLibraryProgress: false,
        checkReqInSellerLibraryError: action.payload.error,
        checkReqData: action.payload.success?.body,
      };

    case START_IMPORT_TO_SELLER_LIBRARY:
      return {
        ...state,
        importToSellerLibraryProgress: true,
        importToSellerLibraryError: null,
        sellerLibraryImportData: {},
      };

    case END_IMPORT_TO_SELLER_LIBRARY:
      return {
        ...state,
        importToSellerLibraryProgress: false,
        importToSellerLibraryError: action.payload.error,
        sellerLibraryImportData: action.payload.success,
      };

    default:
      return state;
  }
}
