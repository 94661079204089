import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import WebHookIntegrationDialog from "../Dialogs/WebHookIntegrationDialog";

const connectedProps = (state) => ({
  orgForms: state.orgForms.forms,
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  integrationBtn: {
    padding: '8px 10px',
    fontSize: 13,
    fontWeight: 500
  },
  webHookConfigBtn:{
    cursor:'pointer',
    color:'#1f73b7',
    fontWeight:500
  }
});

class IntegrationFormData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowWebHookDialog: false,
      orgForms: [],
      orgFormsFieldMap: {},
      isCreateOnce: true,
      selectedItemIndex: null,
      selectedItemData: null
    }
  }

  componentDidMount() {
    this.createFormsMaps();
  }

  componentDidUpdate(prevProps) {

  }

  createFormsMaps() {
    const { orgForms } = this.props;
    let orgFrms = [];
    let orgFrmsFieldMap = {};
    if (orgForms !== undefined && orgForms !== null && orgForms.length > 0) {
      orgFrms = orgForms.map(o => {
        return {
          label: o.FormTitle,
          value: o.ID
        }
      })
      orgForms.forEach(function (frm) {
        orgFrmsFieldMap[frm.ID] = [];
        frm.OrgWorkflowTemplateFormsSection.forEach(function (si) {
          si.OrgWorkflowTemplateFormSectionItems.forEach(function (ssi) {
            if (ssi.ItemName !== '') {
              orgFrmsFieldMap[frm.ID].push({
                label: ssi.ItemName,
                value: ssi.ID
              })
            }
          })
        })
      })
    }

    this.setState({
      isCreateOnce: false,
      orgForms: orgFrms,
      orgFormsFieldMap: orgFrmsFieldMap,
    })

  }

  showWebHookConfigurationsDialog(item, index) {
    this.setState({
      isShowWebHookDialog: true,
      selectedItemIndex: index,
      selectedItemData: item
    })
  }

  saveWebHookConfiguration(config) {
    const itemIndex = this.state.selectedItemIndex;
    if (itemIndex !== null) {
      // console.log(config, 'ConfigDatassssssssssssssssssssss')
      this.props.saveWebHookConfig({ itemIndex, config })
      this.setState({
        isShowWebHookDialog: false,
        selectedItemIndex: null,
        selectedItemData: null
      })
    }
  }

  render() {

    const { classes, integrationFormData } = this.props;
    const { isShowWebHookDialog, selectedItemData } = this.state;
    if (integrationFormData === null) {
      return null;
    }

    return (<div>
      {<div>
        <Typography><b>Form Identifier:</b> {integrationFormData?.formSlug}</Typography>
        <TableContainer component={Paper} style={{ marginTop: 20, height: '50vh' }}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{width:'50%'}}>Item</TableCell>
                <TableCell style={{width:'20%'}}>Type</TableCell>
                <TableCell style={{width:'30%'}}>Identifier</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {integrationFormData.items.map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell>{row.ItemName}</TableCell>
                  <TableCell>{row.TypeName}</TableCell>
                  <TableCell>
                    {row.Type === 21 && <span className={classes.webHookConfigBtn} onClick={() => {
                      this.showWebHookConfigurationsDialog(row, index);
                    }}>
                      {row.isConfigurationsAvailable ? 'Add Configuration' : 'Update Configuration'}
                    </span>}
                    {row.Type !== 21 && <span>{row.Slug}</span>}
                  </TableCell>

                  {false && <TableCell>
                    {row.Type === 21 && <Button
                      variant="contained"
                      color="secondary"
                      className={classes.integrationBtn}
                      onClick={() => {
                        this.showWebHookConfigurationsDialog(row, index);
                      }}>
                      Web Hook Configuration
                    </Button>}
                  </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      }

      {isShowWebHookDialog && <WebHookIntegrationDialog
        isOpen={isShowWebHookDialog}
        additional_data={selectedItemData.additional_data}
        orgForms={this.state.orgForms}
        orgFormsFieldMap={this.state.orgFormsFieldMap}
        onClose={() => {
          this.setState({
            isShowWebHookDialog: false
          })
        }}
        saveWebHookConfiguration={(data) => {
          // this.props.saveWebHookConfig(data)
          this.saveWebHookConfiguration(data)
        }}
      />}
    </div>);
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(IntegrationFormData));
