import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from "react-router-dom";
import Drawer from "./Drawer";
import { CssBaseline } from '@material-ui/core';
import Apps from "../Common/Apps/index";
import Completion from "./Completion/index";
import Team from "./Team/index";
import {authorizedUser,logout} from "../../redux/authuser/action";

const connectedProps = (state) => ({
    authProgress:state.authUser.authProgress,
    authError:state.authUser.authError,
    user:state.authUser.user
});

const connectionActions = {
    authorizedUser:authorizedUser,
    logout:logout
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        background: "#EBEFF3",
        minHeight: "100vh",
    }
});

class OnBoarding extends React.Component {

    componentDidMount(){
        this.props.authorizedUser();
    }

    componentDidUpdate(prevProps){
        if(this.props.authProgress === false && prevProps.authProgress === true){
            if(this.props.authError !== null){
                var error   =   this.props.authError;
                console.log(error.message);
                if(error.message.indexOf("Token") !== -1){
                    //LOGOUT
                    this.props.logout();
                    this.props.history.push("/")
                } else {
                    this.props.logout();
                    this.props.history.push("/")
                }
            } else {
                if(this.props.user == null){
                    this.props.logout();
                    this.props.history.push("/")
                } else {
                    const IsInitialFlow =  this.props.user.IsInitialFlow;
                    if(IsInitialFlow){
                        this.props.history.push("/app")
                    }
                }
            }
        }
    }

    render() {
        const classes = this.props.classes;
        return <div className={classes.root}>
            <CssBaseline />
            <Drawer />
            <div className={classes.content}>
                <Switch>
                    <Route exact path={`${this.props.match.path}/apps`} component={Apps} />
                    <Route exact path={`${this.props.match.path}/team`} component={Team} />
                    <Route exact path={`${this.props.match.path}/completion`} component={Completion} />
                    <Redirect exact path={this.props.match.path} to={`${this.props.match.path}/apps`} />
                </Switch>
            </div>
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(OnBoarding));