import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AppBarV2 from "../../AppBarV2";
import { Button, CircularProgress, Paper } from '@material-ui/core';
import { withRouter } from 'react-router-dom'
import { enableAzureSSO, updateAzureSSOIDP, disableAzureSSO, getEnabledCognitoSSOApps } from "redux/authuser/action";
import { showSnackBar } from "redux/snackbar/action";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import * as Environment from "util/Environment";
import copy from 'copy-to-clipboard';
import CommonCss from 'commonCss';

const connectedProps = (state) => ({
    user: state.authUser.user,
    azureSSOEnableProgress: state.authUser.azureSSOEnableProgress,
    azureSSODisableProgress: state.authUser.azureSSODisableProgress,
    enabledCognitoSSOAppsData: state.authUser.enabledCognitoSSOAppsData,
    enabledCognitoSSOAppsProgress: state.authUser.enabledCognitoSSOAppsProgress,
});

const connectionActions = {
    enableAzureSSO: enableAzureSSO,
    updateAzureSSOIDP: updateAzureSSOIDP,
    disableAzureSSO: disableAzureSSO,
    showSnackBar: showSnackBar,
    getEnabledCognitoSSOApps: getEnabledCognitoSSOApps,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    rootContainer: CommonCss.rootContainer,
    companySection: {
        marginTop: theme.spacing(4.2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 30
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(2.5),
        marginLeft: 20
    },
    steperContent: {
        padding: theme.spacing(4)
    },
    "button": {
        background: "#3C3C3C",
        borderRadius: 5,
        color: "#fff",
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        padding: theme.spacing(1, 3),
        marginTop: 10,
        "&:hover": {
            background: "#3C3C3C",
        },
    },
    enableSSoContainer: {
        width: "30%",
        margin: '30px auto',
        textAlign: "center"
    },
    idpSSoContainer: {
        width: "90%",
        margin: '0px auto',
        marginBottom: theme.spacing(2),
        "& li": {
            marginBottom: theme.spacing(2),
        }
    },
    idpForm: {
        width: "45%",
        // margin:'0px auto',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        border: "solid 1px #ccc",
        padding: theme.spacing(2, 6),
        borderRadius: theme.spacing(1)
    },
    alert: {
        margin: '30px auto',
    },
    formAction: {
        textAlign: "right"
    },
    disableAction: {
        textAlign: "right"
    },
    copy: {
        cursor: "pointer",
        color: "#4A87F8",
        fontSize: "12px",
        marginLeft: theme.spacing(0.5)
    }
});


class AzureSSOIntegration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: -1,
            error: "",
            idp: "",
            idp_error: "",
        }
        this.enableSSO = this.enableSSO.bind(this);
        this.updateIDP = this.updateIDP.bind(this);
        this.disableIntegration = this.disableIntegration.bind(this);
        this.copyText = this.copyText.bind(this);
    }

    componentDidMount() {
        this.props.getEnabledCognitoSSOApps()
    }

    componentDidUpdate(prevProps) {
        if (this.props.azureSSODisableProgress === false && prevProps.azureSSODisableProgress === true) {
            this.props.getEnabledCognitoSSOApps()
        }
        if (this.props.azureSSOEnableProgress === false && prevProps.azureSSOEnableProgress === true) {
            window.location.reload();
        }
    }

    enableSSO() {
        this.props.enableAzureSSO();
    }

    disableIntegration() {
        this.props.disableAzureSSO();
    }

    copyText(text) {
        copy(text)
        this.props.showSnackBar("Copied !!", "success", 500);
    }
    isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    updateIDP() {
        this.setState({
            'error': "",
            "idp_error": ""
        });
        var idp = this.state.idp;
        if (String(idp).trim().length == 0 || !this.isValidHttpUrl(String(idp).trim())) {
            this.setState({
                "idp_error": "Please enter valid url."
            });
            return;
        }

        this.props.updateAzureSSOIDP(idp)

    }
    render() {
        var user_url = Environment.api_host("USER");
        const classes = this.props.classes;
        if (this.props.user == null) {
            return null;
        }
        var org = this.props.user.Organization

        var activeStep = 0;
        var azureConfig = this.props.enabledCognitoSSOAppsData?.find(item => item.Driver === 'azure')
        if (this.state.activeStep == -1) {
            if (!azureConfig) {
                activeStep = 0;
            }

            if (azureConfig && (azureConfig?.IDPURL === undefined || azureConfig?.IDPURL === null || String(azureConfig?.IDPURL).trim().length === 0)) {
                activeStep = 1;
            }

            if (azureConfig?.IDPURL !== undefined && azureConfig?.IDPURL !== null && String(azureConfig?.IDPURL).trim().length !== 0) {
                activeStep = 2;
            }
        } else {
            activeStep = this.state.activeStep
        }

        const loading = this.props.enabledCognitoSSOAppsProgress;

        return <div>
            <AppBarV2 title="Azure SSO Integration" withBack={true} />
            <div className={classes.rootContainer}>
                <div className={classes.companySection}>
                    <p className={classes.subTitle}></p>
                </div>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step >
                        <StepLabel >Enable SSO</StepLabel>
                    </Step>
                    <Step >
                        <StepLabel >Setup Expent SAML</StepLabel>
                    </Step>
                    <Step >
                        <StepLabel >Setup Expent App</StepLabel>
                    </Step>
                </Stepper>
                <Paper elevation={0} className={classes.steperContent}>
                    {activeStep == 0 && <div>
                        <div className={classes.enableSSoContainer}>
                            <div className={classes.alert}>
                                {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                            </div>
                            {!this.props.azureSSOEnableProgress && !loading && <Button type="submit" variant="contained"
                                color="secondary" className={classes.button}
                                onClick={this.enableSSO}>
                                Enable Azure SSO
                            </Button>}
                            {(this.props.azureSSOEnableProgress || loading) && <CircularProgress />}
                        </div>
                    </div>}
                    {activeStep === 1 && <div>
                        <div className={classes.idpSSoContainer}>
                            <div className={classes.disableAction}>
                                {!this.props.azureSSODisableProgress && <Button variant="contained" color="secondary" className={classes.button} onClick={this.disableIntegration}>Disable Integration</Button>}
                                {(this.props.azureSSODisableProgress || loading) && <CircularProgress />}
                            </div>
                        </div>
                        <div className={classes.idpSSoContainer}>
                            <Typography variant={"h6"}>Create a SAML app in Azure</Typography>
                            <ol>
                                <li><Typography>Open the Azure Portal</Typography></li>
                                <li><Typography>Click on <font style={{ "fontWeight": "600" }}>All services </font>and search for  <font style={{ "fontWeight": "600" }}>Microsoft Entra ID</font></Typography></li>
                                <li><Typography>On the Microsoft Entra ID page, click on <font style={{ "fontWeight": "600" }}>Enterprise applications </font>from left menu</Typography></li>
                                <li><Typography>On the  <font style={{ "fontWeight": "600" }}>Enterprise applications </font> page, click on New application</Typography></li>
                            </ol>
                        </div>
                        <div className={classes.idpSSoContainer}>
                            <Typography variant={"h6"}>Configure SAML integration for your Azure app</Typography>
                            <ol>
                                <li><Typography>Click on Create your own application, and enter the input name as <font style={{ "fontWeight": "600" }}>Expent-SSO</font><span className={classes.copy} onClick={() => {
                                    this.copyText(`Expent-SSO`)
                                }}>Copy</span> , select option as <font style={{ "fontWeight": "600" }}>Integrate any other application you don't find in the gallery (Non-gallery)</font> and click on the Create button.</Typography></li>


                                <li><Typography>Click on the <font style={{ "fontWeight": "600" }}>Single sign-on</font> from the left menu</Typography></li>
                                <li><Typography>Select <font style={{ "fontWeight": "600" }}>SAML</font> from the options.</Typography></li>

                                <li><Typography>For <font style={{ "fontWeight": "600" }}>Identifier (Entity ID)</font>, enter <font style={{ "fontWeight": "600" }}>urn:amazon:cognito:sp:{azureConfig?.PoolId}</font><span className={classes.copy} onClick={() => {
                                    this.copyText(`urn:amazon:cognito:sp:${azureConfig?.PoolId}`)
                                }}>Copy</span></Typography></li>
                                <li>
                                    <Typography>For <font style={{ "fontWeight": "600" }}>Reply URL</font>, enter <font style={{ "fontWeight": "600" }}>{azureConfig?.DomainName}/saml2/idpresponse</font><span className={classes.copy} onClick={() => {
                                        this.copyText(`${azureConfig?.DomainName}/saml2/idpresponse`)
                                    }}>Copy</span></Typography>
                                </li>
                                {/* <li>
                                    <Typography>Under <font style={{"fontWeight":"600"}}>ATTRIBUTE STATEMENTS (OPTIONAL)</font> section, create an entry with the following information:</Typography>
                                    <Typography>For <font style={{"fontWeight":"600"}}>Name</font>, enter the SAML attribute name <font style={{"fontWeight":"600"}}>http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress</font><span className={classes.copy} onClick={()=>{
                                        this.copyText(`http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress`)
                                    }}>Copy</span></Typography>
                                    <Typography>For <font style={{"fontWeight":"600"}}>Value</font>, enter <font style={{"fontWeight":"600"}}>user.email</font><span className={classes.copy} onClick={()=>{
                                        this.copyText(`user.email`)
                                    }}>Copy</span></Typography>
                                </li> */}
                                <li><Typography>For all other settings on the page, leave them as their default values or set them according to your preference</Typography></li>
                                <li><Typography>Click on the <font style={{ "fontWeight": "600" }}>Properties</font> from the left menu and change <font style={{ "fontWeight": "600" }}>Visible to users?</font> to No.</Typography></li>

                                {/* <li><Typography>Choose <font style={{ "fontWeight": "600" }}>Next</font></Typography></li> */}
                                {/* <li><Typography>Choose a feedback response for Azure Support. Choose I'm an Azure customer adding an internal app and leave the remaining fields with default values</Typography></li> */}
                                {/* <li><Typography>Choose <font style={{ "fontWeight": "600" }}>Finish</font></Typography></li> */}
                                <li><Typography>Navigate to the <font style={{ "fontWeight": "600" }}>Users and groups</font> menu</Typography></li>
                                <li><Typography>Assign individual users or groups to the <font style={{ "fontWeight": "600" }}>Expent-SSO</font> app using the <font style={{ "fontWeight": "600" }}>Add User/Group</font> button on top left</Typography></li>
                            </ol>
                        </div>
                        <div className={classes.idpSSoContainer}>
                            <Typography variant={"h6"}>Get the IdP metadata for Azure application</Typography>
                            <ol>
                                <li><Typography>On the <font style={{ "fontWeight": "600" }}>Single sign-on</font> tab for Azure app, find the <font style={{ "fontWeight": "600" }}>App Federation Metadata Url</font> hyperlink. Right-click the hyperlink, and then copy the URL, add it to the form field below and select <font style={{ "fontWeight": "600" }}>Submit</font></Typography></li>
                            </ol>
                        </div>
                        <div className={classes.idpSSoContainer}>
                            <div className={classes.idpForm}>
                                <div className={classes.alert}>
                                    {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                                </div>
                                <div className={classes.form}>
                                    <OutlinedInput
                                        id={"idpInput"}
                                        label="Identity Provider metadata hyperlink"
                                        value={this.state.idp}
                                        onChange={(event) => {
                                            this.setState({ idp: event.target.value });
                                        }}
                                        required={true}
                                        error={this.state.idp_error.length > 0}
                                        helperText={this.state.idp_error}
                                    />
                                </div>
                                <div className={classes.formAction}>
                                    {!this.props.azureSSOEnableProgress && <Button variant="contained" color="secondary" className={classes.button} onClick={this.updateIDP}>Submit</Button>}
                                    {(this.props.azureSSOEnableProgress || loading) && <CircularProgress />}
                                </div>
                            </div>
                        </div>
                    </div>}
                    {activeStep == 2 && <div>
                        <div className={classes.idpSSoContainer}>
                            <div className={classes.disableAction}>
                                {!this.props.azureSSODisableProgress && !loading && <Button variant="contained" color="secondary" className={classes.button} onClick={this.disableIntegration}>Disable Integration</Button>}
                                {(this.props.azureSSODisableProgress || loading) && <CircularProgress />}
                            </div>
                        </div>
                        <div className={classes.idpSSoContainer}>
                            <Typography variant={"h6"}>Add an Azure bookmark application</Typography>
                            <ol>
                                <li><Typography>Sign in to your Azure admin console</Typography></li>
                                <li><Typography>Open the Azure Portal</Typography></li>
                                <li><Typography>Click on <font style={{ "fontWeight": "600" }}>All services </font>and search for  <font style={{ "fontWeight": "600" }}>Microsoft Entra ID</font></Typography></li>
                                <li><Typography>On the Microsoft Entra ID page, click on <font style={{ "fontWeight": "600" }}>Enterprise applications </font>from left menu</Typography></li>
                                <li><Typography>On the  <font style={{ "fontWeight": "600" }}>Enterprise applications </font> page, click on New application</Typography></li>

                                <li><Typography>Click on Create your own application, and enter the input name as <font style={{ "fontWeight": "600" }}>Expent</font><span className={classes.copy} onClick={() => {
                                    this.copyText(`Expent`)
                                }}>Copy</span> , select option as <font style={{ "fontWeight": "600" }}>Integrate any other application you don't find in the gallery (Non-gallery)</font> and click on the Create button.</Typography></li>
                                <li><Typography>Click on the <font style={{ "fontWeight": "600" }}>Single sign-on</font> from the left menu</Typography></li>
                                <li><Typography>Select <font style={{ "fontWeight": "600" }}>Linked</font> from the options.</Typography></li>

                                <li>
                                    <Typography>Enter Signon URL as <font style={{ "fontWeight": "600" }}>{user_url}/cognito-login/azure/{org.UUID}</font><span className={classes.copy} onClick={() => {
                                        this.copyText(user_url + `/cognito-login/azure/${org.UUID}`)
                                    }}>Copy</span> and Save.</Typography>
                                </li>
                                <li><Typography>Navigate to the <font style={{ "fontWeight": "600" }}>Users and groups</font> menu</Typography></li>
                                <li><Typography>Assign individual users or groups to the <font style={{ "fontWeight": "600" }}>Expent</font> app using the <font style={{ "fontWeight": "600" }}>Add User/Group</font> button on top left</Typography></li>

                                <li><Typography>Click on the <font style={{ "fontWeight": "600" }}>Properties</font> from the left menu and upload the Expent logo from <a style={{ color: "#4A87F8" }} href="https://assets.expent.ai/expent.png" target="_blank" rel="noreferrer">here</a>.</Typography></li>
                            </ol>
                        </div>
                    </div>}
                </Paper>
            </div>
        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AzureSSOIntegration));