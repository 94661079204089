import React from 'react';
import Select from 'react-select';
import classnames from "classnames";
import CommonCss from 'commonCss';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  Typography, Grid, Card, CardHeader, Avatar, Checkbox, Button
} from '@material-ui/core';
import { currencyCodeMap } from "util/Currency";


import _ from "underscore";
import Radio from "@material-ui/core/Radio";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import "react-datepicker/dist/react-datepicker.css";
import deleteIcon from "assets/images/delete.svg";
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";
import Image from 'Components/Common/image.jsx';

import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import SqureButton from 'Components/Common/SqureButton';

import * as Environment from "util/Environment";
import axios from "axios";
import { CommonFn } from "services/commonFn";
import { showSnackBar } from "redux/snackbar/action";
import { fetchOrgGroup } from "redux/org/group/action";
import { searchUsers } from "redux/usersSettings/action";

import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import DefaultImage from "assets/images/evaluation/default.svg";
import TextField from '@material-ui/core/TextField';
import { fetchRecommendedProducts } from "redux/product/action";

import 'react-phone-input-2/lib/material.css'

import * as Validator from "util/Validation";
import { webHookTriggerRun } from "redux/workflow/action";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
import editIcon from "assets/images/edit_icon.svg";

import TableTypeReply from './TableTypeReply';
import FileAncher from '../FileAncher';

const connectedProps = (state) => ({
  groups: state.orgGroup.groups,
  users: state.usersSettings.users,
  similarExistingProductProgress: state.orgProduct.similarExistingProductProgress,
  similarExistingProducts: state.orgProduct.similarExistingProducts,
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  products: state.products.recommendedProducts,
  wekHookRunProgress: state.workflow.wekHookRunProgress,
  wekHookRunError: state.workflow.wekHookRunError,
  wekHookRunSuccess: state.workflow.wekHookRunSuccess,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchUsers: searchUsers,
  fetchOrgGroup: fetchOrgGroup,
  fetchRecommendedProducts: fetchRecommendedProducts,
  webHookTriggerRun: webHookTriggerRun
};

export const itemTypes = [
  { label: 'Simple Text', value: 1 },
  { label: 'Date', value: 2 },
  { label: 'Binary', value: 3 },
  { label: 'Single Select', value: 4 },
  { label: 'Multi Select', value: 5 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8 },
  { label: 'File', value: 9 },
  { label: 'Products', value: 10 }, //Pending
  { label: 'Rich Text', value: 11 },
  { label: 'Email', value: 12 },
  { label: 'Phone', value: 13 },
  { label: 'Number', value: 15 },
  { label: 'URL', value: 14 },
  { label: 'Currency', value: 16 },
  { label: 'Existing Products', value: 17 },
  { label: 'Score', value: 18 },
  { label: 'Radio', value: 19 },
  { label: 'Checkbox', value: 20 },
]

var connector = connect(connectedProps, connectionActions);



const styles = (theme) => createStyles({
  root: {
    // marginBottom: '16px',
    width: "100%",
    "& ul.answers": {
      listStyle: "none",
      paddingLeft: 0,
      "& li": {
        border: "1px solid #EBEBEB",
        bordeRadius: "4px",
        paddingLeft: "8px",
        marginBottom: "8px",
        "& span": {
          fontSize: 13,
          color: "#6D7278",
        },
      },
    },
    "& [class*='editableField']": {
      background: '#fff',
      maxHeight: 'auto !important',
      minHeight: 'auto !important'
    },
    "& .react-datepicker__input-container > div": {
      marginTop: 10
    }
  },
  itemTitle: {
    fontWeight: 500,
    color: '#6C6C6C',
    fontSize: 15,
    margin: '10px 0px 0px'
  },
  itmRes: {
    fontWeight: 400,
    color: '#989191',
    fontSize: 14,
  },
  formControl: {
    ...CommonCss.formControl,
  },
  creatable: {
    padding: 0
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 16
  },
  searchAndSelect: {
    padding: 0,
    "& .select__control, & .select__control:hover": {
      border: 'none',
      height: 'inherit',
      boxShadow: 'none',
      minHeight: 44
    },
    "& .select__multi-value, & .select__multi-value__label": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20,
      padding: theme.spacing(.3, .6),
      fontSize: 13
    },
    "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20
    },
  },
  deleteIcon: {
    position: "absolute",
    right: 16,
    top: 13,
    cursor: "pointer",
  },
  cardSection: {
    position: "relative",
  },
  contactCardHeader: {
    flexDirection: "column",
    padding: "16px 4px !important",
    "& [class*='MuiCardHeader-avatar-']": {
      margin: "0px",
      marginBottom: 12,
    },
    "& [class*='MuiCardHeader-content']": {
      width: "100%",
    },
    "& [class*=MuiTypography-displayBlock]": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      margin: "0px -19px 0px 0px",
    },
  },
  addNewChamp: {
    display: "block",
    textAlign: "center",
    color: "#bdbdbd",
    "& span": {
      display: "block",
      cursor: "pointer",
      color: "#000000",
    },
  },
  addContact: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    margin: "0px -19px 0px 0px",
  },
  dialogContent: {
    minHeight: 170,
    overflowY: 'hidden',
    "& [class*=select__menu]": {
      maxHeight: 100
    }
  },
  customTypeFields: {
    marginBottom: 15,
    marginTop: 15,
    '& [class*="MuiFormControl-root"]': {
      width: '100%'
    },
    '& [class*="MuiInputBase-input"]': {
      textAlign: 'left'
    }
  },
  currencyTypeFields: {
    marginBottom: 15,
    marginTop: 15,
    '& [class*="MuiFormControl-root"]': {
      width: '100%'
    },
    '& [class*="MuiInputBase-input"]': {
      textAlign: 'left'
    },
    '& [class*="MuiSelect-root"]': {
      height: 56
    }
  },
  requiredField: {
    // color: 'red'
  },
  ratingRoot: {
    // background: '#f1f1f1',
    color: "#fff",
    padding: '1px',
    width: '100%'
  },
  rating: {
    marginTop: '10px',
    marginBottom: '5px',
    '& [class*="MuiSlider-markActive"]': {
      background: '#bfb4b4'
    }
  },
  slider: {
    background: '#f7f7f7',
    display: 'flex',
    alignItems: 'center',
    padding: '2px 10px',
    borderRadius: '10px',
  },
  sliderCount: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
  },
  thumb: {
    color: "#1f73b7"
  },
  track: {
    backgroundColor: "#1f73b7"
  },
  rail: {
    backgroundColor: "#1f73b7"
  },
  resListItem: {
    marginRight: '5px',
    marginLeft: '5px',
    borderRadius: '4px',
    border: '1px solid #707070',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: theme.spacing(1.8),
    color: '#707070',
    padding: '3px',
    width: '100%'
  },
  counter1: {
    textAlign: 'left',
    paddingLeft: 6
  },
  counter2: {
    textAlign: 'right',
    paddingRight: 6
  },
  counterItem: {
    color: '#1f73b7'
  },
  product_list: {
    marginBottom: 10
  },
  productList: {
    position: 'relative',
    '& [class*=LayeredCard-root]': {
      marginTop: 0,
    },
  },
  deleteImage: {
    position: "absolute",
    top: 10,
    zIndex: 20,
    right: 10,
    padding: 4,
    cursor: "pointer",
  },
  tickMark: {
    color: "#4b86f8",
    marginLeft: 10,
    border: "1px solid #4b86f8",
    // marginRight: 10,
    padding: 6,
    minWidth: "50px !important",
    "&:hover": {
      color: "#4b86f8",
    },
  },
  noMarginTop: {
    marginTop: 0
  },
  description: {
    color: '#4B4B4B',
    fontSize: 12,
    margin: 0,
    paddingTop: 6
  },
  deleteBtn: {
    cursor: 'pointer',
    marginLeft: 10
  },
});

class CustomReply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      text_value: '',
      selectedOption: '',
      selectedOptions: [],
      selectedFiles: [],
      fileNames: [],
      selectedUsers: [],
      selectedTeams: [],
      dialogTitle: '',
      isUser: false,
      isOpenDialog: false,
      similarProducts: {},
      isValidationError: false,
      isEditProducts: false,
      productList: [],
      selectedProducts: [],
      otherValue: '',
      currencyType: {
        label: 'USD',
        value: 'USD'
      }
    }
    this.updateDate = this.updateDate.bind(this);
    this.updateTextValue = this.updateTextValue.bind(this);
    this.setSelectedOption = this.setSelectedOption.bind(this);
    this.setSelectedOptions = this.setSelectedOptions.bind(this);
    this.fileSelectHandler = this.fileSelectHandler.bind(this);
    this.producDetail = this.producDetail.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateURL = this.validateURL.bind(this);
    this.setSingleSelectOption = this.setSingleSelectOption.bind(this);
    this.setMultiSelectOptions = this.setMultiSelectOptions.bind(this);
    this.setOtherOption = this.setOtherOption.bind(this);
    this.updateOtherValue = this.updateOtherValue.bind(this);
  }

  componentDidMount() {
    this.initValues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.initValues();
    }

    if (this.props.wekHookRunProgress === false && prevProps.wekHookRunProgress === true) {
      if (this.props.wekHookRunError === null) {
        this.props.showSnackBar("Web Hook Run Successfully", "success", 1500);
      } else {
        this.props.showSnackBar("Something went wrong", "error", 1500);
      }
    }

  }

  initValues() {
    let { type, value, additional_data } = this.props;
    if (type === 1 || type === 11 || type === 12 || type === 13 || type === 14 || type === 15 || type === 18) {
      this.updateTextValue(value, false);
    } else if (type === 2) {
      this.updateDate(value, false);
    } else if (type === 3) {
      this.setSelectedOption(value, false);
    } else if (type === 6) {
      this.updateTableValue(value, false);
    } else if (type === 16) {
      if (value !== undefined && value !== null && value !== '') {
        let newValue;
        try {
          newValue = JSON.parse(value);
        } catch { }
        if (newValue !== undefined && newValue !== null && newValue !== '' && newValue.value !== undefined && newValue.value !== null && newValue.value !== '') {
          this.setState({
            currencyType: newValue.type,
            text_value: newValue.value
          })
        } else if (value.type !== undefined && value.type !== null && value.type !== '') {
          this.setState({
            currencyType: value.type,
            text_value: value.value
          })
        } else {
          this.updateCurrencyValue(value, false);
        }
      } else {
        this.updateCurrencyValue(value, false);
      }
    } else if (type === 19) {
      if (value !== undefined && value !== null && value !== '') {
        if (value.indexOf('Other-') !== -1) {
          let newValue = value.replace('Other-', '');
          this.setOtherOption('9999999', false);
          this.updateTextValue(newValue, false);
        } else {
          this.setSelectedOption(value, false);
        }
      }
    } else if (type === 20) {
      if (value !== undefined && value !== null && value !== '') {
        // console.log(value)
        let options = [];
        let otherValue = '';
        value.forEach((itm) => {
          if (String(itm).indexOf('Other-') !== -1) {
            otherValue = itm;
          }
          options.push(itm)
        });
        this.setSelectedOptions(options, false);
        if (otherValue !== undefined && otherValue !== null && otherValue !== '') {
          if (otherValue.indexOf('Other-') !== -1) {
            let newValue = otherValue.replace('Other-', '');
            this.setOtherOption('9999999', false);
            this.updateTextValue(newValue, false);
          }
        }
      } else {
        this.setSelectedOptions(value, false);
      }
    } else if (type === 4) {
      // console.log(value, 'value')
      let option;
      additional_data.forEach(function (itm) {
        if (parseInt(value) === itm.value) {
          option = itm;
        }
      });
      this.setSingleSelectOption(option, false);
    } else if (type === 5) {
      let options = [];
      additional_data.forEach(function (itm) {
        if (value.indexOf(itm.value) !== -1) {
          options.push(itm);
        }
      });
      this.setMultiSelectOptions(options, false);
    } else if (type === 7) {
      this.setSelectedUsersOnLoad(value, false);
    } else if (type === 8) {
      this.setSelectedTeamsOnLoad(value, false);
    } else if (type === 9) {
      if (value !== undefined && value !== null && value !== '') {
        let newValue;
        try {
          newValue = JSON.parse(value);
        } catch { }
        if (newValue !== undefined && newValue !== null && newValue !== '') {
          if (Array.isArray(newValue)) {
            this.setState({
              selectedFiles: newValue,
              fileNames: newValue.map(file => file.name)
            })
          } else {
            this.setState({
              selectedFiles: [{ name: newValue.name, url: newValue.url }],
              fileNames: [newValue.name]
            })
          }
        } else if (value !== undefined && value !== null && value !== '') {
          if (Array.isArray(value)) {
            this.setState({
              selectedFiles: value,
              fileNames: value.map(file => file.name)
            })
          } else {
            this.setState({
              selectedFiles: [{ name: value.name, url: value.url }],
              fileNames: [value.name]
            })
          }
        }
      }
    } else if (type === 10) {
      this.setSelectedProducts(value, false);
    }
  }

  updateDate(date, isUpdate) {
    this.setState({
      selectedDate: (date !== undefined && date !== null && date !== '') ? new Date(date) : null
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedDate)
      }
    })
  }

  updateTextValue(value, isUpdate) {
    this.setState({
      text_value: (value !== undefined && value !== null && value !== '') ? value : ''
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.text_value)
      }
    })
  }

  updateTableValue(value, isUpdate) {
    this.setState({
      text_value: (value !== undefined && value !== null && value !== '') ? value : []
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.text_value)
      }
    })
  }

  updateCurrencyValue(value, isUpdate) {
    this.setState({
      text_value: (value !== undefined && value !== null && value !== '') ? value : ''
    }, () => {
      if (isUpdate) {
        this.props.onChange({
          type: this.state.currencyType,
          value: this.state.text_value
        })
      }
    })
  }

  setSelectedOption(value, isUpdate) {
    this.setState({
      selectedOption: (value !== undefined && value !== null && value !== '') ? value : '',
      otherValue: false
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedOption)
      }
    })
  }

  setSelectedOptions(options, isUpdate) {
    this.setState({
      selectedOptions: (options !== undefined && options !== null && options !== '') ? options : [],
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedOptions)
      }
    })
  }

  setSingleSelectOption(option, isUpdate) {
    // console.log(option)
    this.setState({
      selectedOption: (option !== undefined && option !== null && option !== '') ? option : []
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedOption.value)
      }
    })
  }

  setMultiSelectOptions(options, isUpdate) {
    this.setState({
      selectedOptions: (options !== undefined && options !== null && options !== '') ? options : []
    }, () => {
      if (isUpdate) {
        let options = [];
        this.state.selectedOptions.forEach(function (itm) {
          options.push(itm.value);
        })
        this.props.onChange(options)
      }
    })
  }

  setOtherOption(value, isUpdate) {
    // console.log(value, 'Newww Value Other')
    this.setState({
      selectedOption: '',
      otherValue: value
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedOption, true)
      }
    })
  }

  updateOtherValue(value, isUpdate) {
    this.setState({
      text_value: (value !== undefined && value !== null && value !== '') ? value : '',
      formError: '',
    }, () => {
      if (isUpdate) {
        if (this.props.type === 19) {
          let newText = 'Other-' + this.state.text_value;
          this.props.onChange(newText, true)
        } else {
          let options = [...this.state.selectedOptions];
          let newText = 'Other-' + this.state.text_value;
          let newOptions = [];
          options.forEach((itm) => {
            if (String(itm).indexOf('Other-') === -1) {
              newOptions.push(itm);
            }
          });
          newOptions.push(newText);
          // console.log(newOptions)
          this.props.onChange(newOptions, true)
        }
      }
    })
  }

  setSelectedUsersOnLoad(options, isUpdate) {
    this.setState({
      selectedUsers: (options !== undefined && options !== null && options !== '') ? options : []
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedUsers)
      }
    })
  }

  setSelectedTeamsOnLoad(options, isUpdate) {
    this.setState({
      selectedTeams: (options !== undefined && options !== null && options !== '') ? options : []
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedTeams)
      }
    })
  }

  setSelectedUsers(user, isUpdate) {
    var users = this.state.selectedUsers;
    var index = users.findIndex(t => t.ID === user.ID)
    if (index === -1) {
      users.push(user);
    } else {
      users.splice(index, 1);
    }
    this.setState({
      selectedUsers: users
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedUsers)
      }
    })
  }

  setSelectedTeams(team, isUpdate) {
    var teams = this.state.selectedTeams;
    var index = teams.findIndex(t => t.ID === team.ID)
    if (index === -1) {
      teams.push(team);
    } else {
      teams.splice(index, 1);
    }
    this.setState({
      selectedTeams: teams
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedTeams)
      }
    })
  }

  setSelectedFile(value, isUpdate) {
    var filePath = '';
    if (value !== undefined && value !== null && value !== '') {
      filePath = String(value);
      if (this.state.fileName === '') {
        let fileName = ""
        if (value.name === undefined) {
          fileName = new URL(value).pathname.split("/").pop();
        } else {
          fileName = value.name;
        }
        // 
        this.setState({
          fileName: fileName,
        })
      }
    }

    this.setState({
      selectedFile: filePath,
    }, () => {
      if (isUpdate) {
        this.props.onChange({
          url: this.state.selectedFile,
          name: this.state.fileName
        })
      }
    })
  }

  setSelectedProducts(product, isUpdate) {
    if (isUpdate) {
      var products = this.state.selectedProducts !== null ? this.state.selectedProducts : [];
      if (product !== undefined && product !== null && product !== '') {
        products.push(product)
      }
      this.setState({
        selectedProducts: products,
        selectedOption: null
      }, () => {
        this.props.onChange(this.state.selectedProducts)
      })
    } else {
      if (product !== undefined && product !== null && product !== '') {
        this.setState({
          selectedProducts: product
        })
      }
    }
  }

  handleRemoveProduct = (index) => {
    let data = this.state.selectedProducts;
    data.splice(index, 1)
    this.setState({
      selectedProducts: data
    }, () => {
      this.props.onChange(this.state.selectedProducts)
    })
  }

  fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const { orgId, evaluationId, workflowId } = this.props;
      const file = event.target.files[0];
      var formData = new FormData();
      formData.append(
        "folder",
        `${orgId}/evaluation/${evaluationId}/workflow/${workflowId}`
      );
      const timestamp = new Date().getTime();
      formData.append(
        "file",
        file,
        `${timestamp}.${file.name.substring(file.name.indexOf(".") + 1)}`
      );
      // console.log(file.name, 'file.name')
      this.setState({
        fileName: file.name
      })
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;

      axios.post(url, formData, {
        headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        this.setSelectedFile(`${response.data.filepath}`, true);
        this.props.showSnackBar("File uploaded", "success", 1500);
      })
        .catch(err => {
          if (err.response) {
            this.props.showSnackBar(err.response.data.error, "error", 3000);
          } else if (err.request) {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          } else {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          }
        })
    }
  };

  producDetail = (e, product) => {
    var url = `/app/products/org-product-details/${product.ID}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null;
  };

  validateEmail(value) {
    if (value !== '') {
      if (Validator.validateEmail(value) === false) {
        this.setState({
          isValidationError: true
        })
      } else {
        this.setState({
          isValidationError: false
        })
      }
    }
  }

  validateURL(value) {
    if (value !== '') {
      if (Validator.validateURL(value) === false) {
        this.setState({
          isValidationError: true
        })
      } else {
        this.setState({
          isValidationError: false
        })
      }
    }
  }

  fetchProduct = (value = "") => {
    this.props.fetchRecommendedProducts(0, {
      feature_ids: [],
      num_users: "",
      page: 0,
      pageSize: 10,
      query: value,
      type: 2,
    });
  };

  webHookRun() {
    let { itemId, webHookItemIdMap, evaluationId } = this.props;
    // console.log(additional_data,'additional_data')
    // console.log(item,'item')
    // console.log(evaluationId,'evaluationId')
    if (webHookItemIdMap !== undefined && webHookItemIdMap !== null && webHookItemIdMap[itemId] !== undefined) {
      let data = webHookItemIdMap[itemId];
      let dataFields = [];
      if (data) {
        dataFields = data.dataFields.map((itm) => {
          return {
            form_type: itm?.form_type?.value,
            form_id: itm?.form_id?.value,
            field_id: itm?.field_id?.value,
          }
        })
      }

      let params = {
        triggerType: data?.triggerType?.value,
        triggerURL: data?.triggerURL,
        dataFields: dataFields
      }
      this.props.webHookTriggerRun(evaluationId, params);
    }
  }

  render() {
    let { additional_data, type, title, key, isRequired, isFormEditable, error, isOtherOption, decisionItemMap, itemId, sectionId } = this.props;
    const classes = this.props.classes;
    let { selectedDate, selectedOption, text_value, selectedOptions, selectedFiles, fileNames, selectedUsers, selectedTeams, selectedProducts, isEditProducts, otherValue, currencyType } = this.state;

    // console.log(this.state.similarProducts,'similarProducts')
    // console.log(this.props.evaluation,'Evaluationnnnnnnnnnnnnnnnnnn')
    // console.log(selectedUsers, ' selectedUsers  =' + type)
    // console.log(selectedTeams, ' selectedTeams  =' + type)
    // console.log(selectedFile, 'selectedFile')
    let similarProducts = this.props.similarExistingProducts;
    // console.log(similarProducts,'similarProducts')

    let productList = []
    if (type === 10) {
      productList = this.props.products.map((m) => {
        m.value = m.ID;
        m.label = m.Name;
        return m
      });
    }

    // console.log(selectedOption, 'selectedOption');
    // console.log(additional_data, 'additional_data')
    if (type === 19 || type === 20) {
      // console.log(selectedOption, 'selectedOption')
      // console.log(selectedOptions, 'selectedOptions')
    }

    let isFieldDisabled = false;
    if (decisionItemMap && decisionItemMap !== undefined && decisionItemMap !== null) {
      if (decisionItemMap[sectionId] !== undefined && decisionItemMap[sectionId].indexOf(itemId) !== -1) {
        isFieldDisabled = true;
      }
    }

    return (
      <div key={key} className={classes.root} >
        <p style={{ color: 'red' }}>{error}</p>

        {/* <Typography variant={"h5"} className={classes.itemTitle} >{title}</Typography> */}

        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 18, 19, 20].includes(type) && <Typography variant={"h5"} className={classes.itemTitle} >{title} {isRequired ? <span className={classes.requiredField}>*</span> : ''}
          {isFieldDisabled && <span><Image className={classes.deleteBtn} src={editIcon} onClick={() => {
            this.props.showMessageDialg()
          }} /></span>}
        </Typography>}


        <div className={classes.fieldContainer} >
          {type === 1 &&
            <>
              <span className={classes.itmRes}>{text_value !== null && text_value !== '' ? text_value : '-'}</span>
            </>
          }

          {type === 2 &&
            <>
              <span className={classes.itmRes} >{selectedDate !== undefined && selectedDate !== null && selectedDate !== '' ? CommonFn.formateDate(selectedDate, true) : '-'}</span>
            </>
          }

          {type === 3 &&
            <>
              <span className={classes.itmRes}>{String(selectedOption)}</span>
            </>
          }

          {type === 4 &&
            <>
              <span className={classes.itmRes} >{selectedOption !== undefined && selectedOption !== null && selectedOption !== '' ? selectedOption.label : '-'}</span>
            </>
          }

          {type === 5 &&
            <>
              <span className={classes.itmRes} >{selectedOptions !== undefined && selectedOptions !== null && selectedOptions !== '' ? selectedOptions.map(u => u.label).join(', ') : '-'}</span>
            </>
          }

          {type === 6 &&
            <div className={classes.customTypeFields}>
              <TableTypeReply
                isEdit={false}
                tableDatas={text_value}
                additionalData={additional_data}
                updateData={(data) => {
                  this.props.onChange(data)
                }}
              />
            </div>
          }

          {type === 7 &&
            <Grid container spacing={2} className={classes.noMarginTop}>
              {selectedUsers.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={2}
                  key={index}
                  className={classes.cardSection}
                >
                  <Card variant="outlined" style={{ height: '100%' }}>
                    <CardHeader
                      className={classes.contactCardHeader}
                      style={{ textAlign: "center" }}
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          className={classes.contactCardAvatar}
                        >
                          {item.label.slice(0, 1)}
                        </Avatar>
                      }
                      title={item.label}
                      subheader={item?.Email}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          }

          {type === 8 &&
            <Grid container spacing={2} className={classes.noMarginTop}>
              {selectedTeams.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={2}
                  key={index}
                  className={classes.cardSection}
                >
                  <Card variant="outlined" style={{ height: '100%' }}>
                    <CardHeader
                      className={classes.contactCardHeader}
                      style={{ textAlign: "center" }}
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          className={classes.contactCardAvatar}
                        >
                          {item.label.slice(0, 1)}
                        </Avatar>
                      }
                      title={item.label}
                      subheader={item?.Email}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          }

          {type === 9 &&
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "16px",
                }}
              >
                {selectedFiles && selectedFiles.map((file, key) => (
                  <FileAncher
                    fileName={fileNames[key]}
                    fileURL={file.url}
                  />
                ))}

              </div>
            </>
          }

          {type === 10 && (
            <>
              <Grid container spacing={2} className={classes.noMarginTop}>
                {selectedProducts !== undefined && selectedProducts !== null && selectedProducts.length > 0 && selectedProducts.map((product, k) => {
                  return (
                    <Grid
                      item
                      key={"similar_" + product.ID}
                      className={classes.productList}
                      xs={12}
                      sm={4}
                      md={3}
                      lg={2}
                    >
                      <div style={{ position: 'relative' }}>
                        {isFormEditable && <Image
                          src={deleteIcon}
                          className={classes.deleteImage}
                          onClick={() => {
                            this.handleRemoveProduct(k)
                          }}
                        />}
                        <LayeredCard
                          title={product.Name}
                          subTitle={product.CompanyName}
                          description={product.Description}
                          customRoute={""}
                          image={product.ProductLogo}
                          onClick={() => { }}
                          numLayer={0}
                          checked={false}
                          showCheckbox={false}
                          id={product.ID}
                          uniqueId={product.ID + "_q_" + k}
                          type={"product"}
                          IsExisting={product.IsExisting}
                          fireEvent
                          eventPage={'orgProductDetails'}
                          eventData={{
                            type: 'event.click.org-product.section',
                            data: {
                              url: this.props.location.pathname + this.props?.location?.search,
                              section: {
                                id: 'customtab',
                                name: 'customtab'
                              },
                              product: {
                                id: product.ID,
                                name: product.Name,
                                position: k + 1
                              }
                            }
                          }}
                        />
                      </div>
                    </Grid>
                  );
                })}
                {isFormEditable && <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                  onClick={() => {
                    this.setState({ isEditProducts: true });
                  }}
                >
                  <LayeredCard
                    title={"+Add Vendor"}
                    onClick={() => { }}
                    numLayer={0}
                    image={AddEvaluationImage}
                    showCheckbox={false}
                  />
                </Grid>}
              </Grid>
              {isEditProducts && (
                <Grid container alignItems="center" style={{ marginTop: 20 }}>
                  <Grid item xs={6} sm={5} md={4}>
                    <Select
                      isClearable
                      menuPortalTarget={document.body}
                      isSearchable={true}
                      options={productList.filter(product => {
                        let dataArray = selectedProducts || [];
                        return !dataArray.some((item) => product.ID === item.ID)
                      })}
                      classNamePrefix="select"
                      placeholder={"Select Product"}
                      onInputChange={(e) => {
                        this.fetchProduct(e);
                      }}
                      onChange={(e) => this.setState({ selectedOption: e })}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </Grid>
                  &nbsp;
                  <ActionButtom
                    classes={classes}
                    onCancel={() => this.setState({ isEditProducts: false })}
                    onSave={() => {
                      if (!this.state.selectedOption) {
                        this.props.showSnackBar('Please select product', 'error', 3000);
                        return;
                      }
                      this.setState({ isEditProducts: false })
                      this.setSelectedProducts(this.state.selectedOption, true)
                    }}
                  />
                </Grid>
              )}
            </>
          )}

          {type === 11 &&
            <>
              <SlateInputFieldV2
                readOnly={true}
                isToolBar={false}
                placeholder=""
                style={{
                  margin: 0,
                }}
                as={SlateInputField}
                initValue={text_value !== null && text_value !== '' ? text_value : '-'}
                formControlStyle={{ border: 'none', overflow: 'hidden' }}
                textContainerStyle={{ minHeight: '100px !important', margin: "0px", background: "#f7f7f7", padding: 10, color: "#20253a", fontSize: 16 }} />
            </>
          }

          {type === 12 &&
            <>
              <span className={classes.itmRes}>{text_value !== null && text_value !== '' ? text_value : '-'}</span>
            </>
          }

          {type === 13 &&
            <>
              <span className={classes.itmRes}>{text_value !== null && text_value !== '' ? text_value : '-'}</span>

            </>
          }

          {type === 14 &&
            <>
              <span className={classes.itmRes}>{text_value !== null && text_value !== '' ? text_value : '-'}</span>

            </>
          }

          {type === 15 &&
            <>
              <span className={classes.itmRes}>{text_value !== null && text_value !== '' ? text_value : '-'}</span>

            </>
          }

          {type === 16 &&
            <>
              <span className={classes.itmRes}>{text_value !== null && text_value !== '' ? currencyCodeMap[currencyType?.value] + ' ' + text_value : '-'}</span>
            </>
          }

          {type === 17 &&
            <div className={classnames(classes.customTypeFields)}>
              {this.props.similarExistingProductProgress === false && this.props.evaluation.Products.map((product, k) => {
                if (similarProducts[product.ID] === undefined) {
                  return null
                }
                var simProducts = similarProducts[product.ID];
                return <div className={classes.product_list}>
                  <Typography className={classes.label}>Similar product{simProducts.length > 1 ? "s" : ""} for {product.Name}</Typography>
                  <Grid container spacing={3} className={classes.noMarginTop}>
                    {simProducts.map((product, index) => (
                      <Grid item lg={2} md={3} key={index}>
                        <div onClick={(e) => {
                          this.producDetail(e, product)
                        }}>
                          <LayeredCard
                            title={product.Name}
                            subTitle={product.CompanyName}
                            description={product.Description}
                            image={
                              product.ProductLogo === null ||
                                product.ProductLogo.length === 0
                                ? DefaultImage
                                : product.ProductLogo
                            }
                            toogleCheck={(e) => {
                              this.reviewProduct(e, product.ID);
                            }}
                            onClick={() => {

                            }}
                            numLayer={0}
                            //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                            // showCheckbox={true}
                            id={product.ID}
                            uniqueId={product.ID + "_c_" + index}
                            //  type={'product'}
                            IsExisting={product.IsExisting}
                          />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              })}
            </div>
          }

          {type === 18 &&
            <>
              <span className={classes.itmRes}>{text_value !== null && text_value !== '' ? text_value : '-'}</span>
            </>
          }

          {type === 19 &&
            <>
              <ul className={"answers"}>
                {additional_data.map((item, i) => {
                  return <>
                    <li key={i}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          boxSizing: "border-box",
                          placeContent: "center flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          value={String(item.id)}
                          disabled={true}
                          checked={selectedOption === String(item.id) ? true : false}
                          inputProps={{
                            name: "RadioData",
                            "data-sequence": item.Sequence,
                          }}
                          onChange={(e) => {
                            this.setSelectedOption((e.target.value), true);
                          }}
                        />
                        <label htmlFor={item.id}>{item.label}</label>
                      </div>
                    </li>
                  </>
                })}
              </ul>
            </>
          }

          {type === 20 &&
            <>
              <ul className={"answers"}>
                {additional_data.map((item, i) => {
                  return <>
                    <li key={i}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          boxSizing: "border-box",
                          placeContent: "center flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          id={item.id}
                          disabled={true}
                          checked={selectedOptions.indexOf(item.id) !== -1}
                          name={item.id}
                          color="primary"
                          onChange={(e) => {
                            let options = [];
                            let itemId = item.id;
                            if (selectedOptions.indexOf(itemId) !== -1) {
                              selectedOptions.splice(
                                selectedOptions.indexOf(itemId),
                                1
                              );
                            } else {
                              selectedOptions.push(itemId);
                            }
                            options = _.uniq(selectedOptions);
                            this.setSelectedOptions(options, true);
                          }}
                        />
                        <label htmlFor={item.id}>{item.label}</label>
                      </div>
                    </li>
                  </>
                })}
              </ul>
            </>
          }


          {(type === 19 || type === 20) && isOtherOption !== undefined && isOtherOption !== null && isOtherOption && <ul className={"answers"}>
            <li key={'other_option_single'}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  boxSizing: "border-box",
                  placeContent: "center flex-start",
                  alignItems: "center",
                }}
              >
                {type === 19 && <Radio
                  value={'99999999'}
                  checked={otherValue}
                  inputProps={{
                    name: "OtherData",
                  }}
                  onChange={(e) => {
                    this.setOtherOption('99999999', false);
                  }}
                />}

                {type === 20 && <Checkbox
                  id="other"
                  checked={otherValue}
                  name="other"
                  color="primary"
                  onChange={(e) => {
                    if (otherValue.length <= 0) {
                      this.setOtherOption('99999999', false);
                    } else {
                      this.setOtherOption('', true);
                    }
                  }}
                />}

                <TextField
                  style={{
                    width: "85%",
                    marginLeft: 10,
                    color: "#04363D",
                    fontSize: 13,
                  }}
                  value={text_value}
                  placeholder={'Other'}
                  onChange={(e) => {
                    this.updateOtherValue(e.target.value, true);
                  }}
                />

              </div>
            </li></ul>
          }

          {type === 21 &&
            <>
              <SqureButton
                style={{ "marginLeft": "10px" }}
                variant={"contained"}
                disabled={false}
                onClick={() => { this.webHookRun() }}>
                {title}
              </SqureButton>
            </>
          }

          {type === 22 &&
            <>
              <SlateInputFieldV2
                readOnly={true}
                isToolBar={false}
                placeholder=""
                style={{
                  margin: 0,
                }}
                as={SlateInputField}
                initValue={additional_data?.message}
                formControlStyle={{ border: 'none', overflow: 'hidden' }}
                textContainerStyle={{ minHeight: '100px !important', margin: "0px", background: "#f7f7f7", padding: 10, color: "#20253a", fontSize: 16 }} />
            </>
          }

        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CustomReply));


const ActionButtom = ({ classes, onSave, onCancel, isDisabled = false }) => (
  <div>
    <Button
      className={classes.tickMark}
      onClick={() => isDisabled ? {} : onSave()}
    >
      <DoneIcon />
    </Button>
    <Button
      className={classes.tickMark}
      onClick={onCancel}
    >
      <CloseIcon />
    </Button>
  </div>
)