import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";
import {
  Grid, Button, Popover, List, IconButton, FormControl, Select, MenuItem, Checkbox, FormControlLabel,
  ListItem, CircularProgress, Typography, TextField, InputAdornment, ButtonGroup
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search";
import { updateDrawerStyle } from "redux/drawer/action";
import { hideEvaluationCollaboratorDialog } from "redux/evaluate/action";
import { fetchRenewal, updateRenewal, addColloborator, startNewRenewalProcess, attachProductToRequest, updateEntityRenewal } from "redux/renewal/action";
import AppBarV2 from "../../AppBarV2";
import axios from 'axios';
import * as Validator from "util/Validation";
import ListAltIcon from '@material-ui/icons/ListAlt';
import CalendarMonthIcon from '@material-ui/icons/DateRange';
import Image from 'Components/Common/image';
import { CommonFn } from 'services/commonFn';
import { showSnackBar } from "redux/snackbar/action";
import classNames from "classnames";

// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import UserGroup from "Components/Common/UserGroup/index";
import SearchUserPoper from "Components/Common/UserGroup/SearchUserPoper";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import { fetchUsers } from "redux/usersSettings/action";
import DateFilter from "Components/Common/Filters/DateFilter";
import * as Environment from "util/Environment";
import moment from 'moment';
import ReactSelect from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
import StartRenewalDialog from "./StartRenewalDialog";
import AttachRequestDialog from "./AttachRequestDialog";
import { showEvaluationProductSelectionDialog } from "redux/evaluation/metadata/action";
import { isEnabledEvaluate, preciseDiffMoments } from "util/Common";
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';
import ListIcon from "@material-ui/icons/List";

import {
  showConfirmCallbackDialog,
  hideConfirmCallbackDialog
} from "redux/applications/action";

import ConfirmCallbackDialog from "Components/Common/ConfirmCallbackDialog";
import RenewalTable from "./Components/RenewalTable";
import AddUpdateRenewalViewDialog from "./Components/AddUpdateRenewalViewDialog";
import { fetchAllOrgSettings } from "redux/org/user/action";

const SelectAsyncPaginate = withAsyncPaginate(ReactSelect);

const connectedProps = (state) => ({
  renewalList: state.renewal.fetchRenewalData,
  fetchRenewalProg: state.renewal.fetchRenewalProg,
  updateRenewalProg: state.renewal.updateRenewalProg,
  updateEntityRenewalProg: state.renewal.updateEntityRenewalProg,
  updateRenewalColloboratorProg: state.renewal.updateRenewalColloboratorProg,
  deleteRenewalColloboratorProg: state.renewal.deleteRenewalColloboratorProg,
  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
  collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
  configurations: state.orgUser.fetchAllOrgSettingsSuccess,
  startNewRenewalProgress: state.renewal.startNewRenewalProgress,
  productRenewalInfo: state.renewal.productRenewalInfo,
  productRenewalInfoError: state.renewal.productRenewalInfoError,
  attachProductToRequestProg: state.renewal.attachProductToRequestProg,
  attachProductToRequestErr: state.renewal.attachProductToRequestErr,
  users: state.usersSettings.users,
  orgUser: state.orgUser,
  isOpen: state.evaluationMetaData.showEvaluationProductSelectionDialog,
  fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,

});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  fetchRenewal: fetchRenewal,
  fetchUsers: fetchUsers,
  updateRenewal: updateRenewal,
  showSnackBar: showSnackBar,
  addColloborator: addColloborator,
  attachProductToRequest: attachProductToRequest,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
  startNewRenewalProcess: startNewRenewalProcess,
  showEvaluationProductSelectionDialog: showEvaluationProductSelectionDialog,
  showConfirmCallbackDialog: showConfirmCallbackDialog,
  hideConfirmCallbackDialog: hideConfirmCallbackDialog,
  updateEntityRenewal: updateEntityRenewal,
  fetchAllOrgSettings: fetchAllOrgSettings
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    padding: '2em 5em',
    marginTop: '0px !important'
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
    marginBottom: 8,
    "& > .appBar": {
      flex: 1
    }
  },
  select: {
    background: "#fff",
    width: 200,
    textAlign: "left",
    marginTop: 0,
    marginBottom: 0,
    '& [class*=MuiSelect-root]': {
      padding: 9
    }
  },
  evaluateCard: {
    width: 'calc(100% - 0px)',
    background: '#fff',
    padding: "16px 24px",
    boxShadow: '0px 3px 25px #55555500',
    borderRadius: 5,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  evaluateCardTitle: {
    color: '#20253A',
    fontSize: 14,
    margin: 0,
    fontWeight: 500,
    width: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  evaluateDetailsBox: {
    display: 'flex',
    flex: '.6',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  evaluateText: {
    display: 'flex',
    flex: '.4',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  productLogo: {
    float: 'left',
    width: '50px',
    height: '50px',
    borderRadius: '4px',
    marginRight: '10px',
    textAlign: 'center',
    lineHeight: '33px',
    fontWeight: 600,
    fontSize: 11,
    color: '#A0A5BA',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'scale-down'
    },
    cursor: 'pointer'
  },
  reviewCompleted: {
    color: '#2EB77D',
    fontSize: 12,
    marginTop: 3,
    marginBottom: 0
  },
  ownerNames: {
    color: '#6d6d6d',
    fontSize: 12,
    marginTop: 3,
    marginBottom: 0
  },
  btnReview: {
    width: 'max-content',
    height: '32px',
    border: '1px solid #91949E',
    borderRadius: '5px',
    background: ' #ffffff',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '13px',
    color: '#626675',
  },
  clearFilterBtn: {
    marginLeft: 10,
    padding: '4px 20px'
  },
  filterTitle: {
    color: "#282D30",
    fontSize: theme.spacing(2.2),
  },
  topFilters: {
    marginBottom: 25
  },
  selectComp: {
    width: 250,
    marginTop: 10,
  },
  stateLabel: {
    cursor: "pointer",
    backgroundColor: "#fff",
    border: "1px #dadce0 solid",
    borderRadius: 8,
    height: 32,
    padding: "0 10px",
    margin: "0px 3px",
    display: "flex",
    alignItems: "center",
  },
  selected: {
    backgroundColor: "#a3dcfc33",
  },
  stateLabelText: {
    color: "#5f6368",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "1px",
  },
  labelInit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 16,
    height: 16,
    marginRight: 8,
    color: "#fff",
    borderRadius: 8,
    fontSize: 10,
    background: "#4a87f8",
  },
  isFeatured: {
    background: "#ff9800",
    color: "#fff",
  },
  isBlocked: {
    background: "#f44336",
    color: "#fff",
  },
  isExisting: {
    background: "#E6FFF3",
    color: "#2EB77D",
  },
  isInReview: {
    background: "#bdbdbd",
    color: "#fff",
  },
  isLimited: {
    background: "#2e81da",
    color: "#fff",
  },
  filterOptions: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    padding: theme.spacing(3, 3),
    paddingLeft: 0
  },
  autoSelectInput: {
    width: "100%",
    background: 'white',
    marginTop: 10,
    "& [class*=MuiInputBase-input]": {
      padding: "10px !important",
    },
    // width: '30%'
  },
  searchBox: {
    maxWidth: '500px',
    minWidth: '250px',
  },
  statusfilterBtn: {
    background: '#fff',
    border: '1px solid #888',
    borderRadius: 4,
    width: '100%',
    color: '#222',
    boxShadow: 'none',
    padding: '4px 20px',
    fontSize: 16,
    '&:hover': {
      background: '#fff',
      color: '#222'
    }
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  }
});

let startDate = new Date();
startDate = moment(startDate).toISOString();

const stateArr = [
  { label: 'Approved', value: 'Approved' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Draft', value: 'Draft' },
  { label: 'Pending Approval', value: 'PendingApproval' },
]

const statusFilterOptions = [
  // { label: "All", shortName: "NS", value: 0, classname: 'isFeatured' },
  { label: "Not Started", shortName: "NS", value: 1, classname: 'isFeatured' },
  { label: "In Progress", shortName: "IP", value: 2, classname: 'isExisting' },
  { label: "Renewed", shortName: "R", value: 3, classname: 'isInReview' },
  { label: "Not going to renew", shortName: "NR", value: 4, classname: 'isLimited' },
]

class Renewal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 0,
      query: "",
      duration: 0,
      startDate: new Date(),
      endDate: new Date(),
      currentView: 'ListView',
      isOpen: false,
      projectStatus: '',
      projectOwnerIDs: [],
      contractOwnerIDs: [],
      selectedRenewalProduct: null,
      selectedRenewalContract: null,
      selectedRenewalDate: '',
      selectedEntityType: null,
      anchorEl: null,
      currentRenewal: null,
      showAttachRequestDialog: false,
      dates: null,
      statusFilter: [1, 2],
      availableFilters: [
        { label: "Renewal Status", slug: "renewal_status", isShow: true },
        { label: "Date", slug: "date", isShow: true },
        { label: "Project Status", slug: "project_status", isShow: true },
        { label: "Project Owner", slug: "project_owner", isShow: true },
        { label: "Contract Owner", slug: "contract_owner", isShow: true },
      ],
      showFilters: [],
      renewalStatusfilterPopover: null,
      selectedView: 'grid',
      customColumns: [],
      showViewAddUpdateDialog: false,
      viewColumns: [],
      selectedFilterView: 0,
      onViewSelection: false
    }
    this.btnRef = React.createRef();
    this.callBack = this.callBack.bind(this);
    this.onCancelUpdateContract = this.onCancelUpdateContract.bind(this);
  }

  componentDidMount() {
    const renewalSelectedViewType = window.sessionStorage.getItem("renewalSelectedViewType");
    if(renewalSelectedViewType && renewalSelectedViewType !== ''){
      this.setNewViewType(renewalSelectedViewType)
    }
    this.props.fetchAllOrgSettings()
    this.fetchData();
    this.props.fetchUsers(0, 10, '');
    this.props.updateDrawerStyle(true, true);
  }

  componentDidUpdate(prevProps) {

    if (this.props.fetchAllOrgSettingsInprogress === false && prevProps.fetchAllOrgSettingsInprogress === true) {
      this.initViews();
    }

    if (!this.props.updateRenewalProg && prevProps.updateRenewalProg) {
      this.fetchData()
      this.props.showSnackBar("Status updated successfully", "success", 3000)
    }

    if (!this.props.updateEntityRenewalProg && prevProps.updateEntityRenewalProg) {
      this.fetchData()
      this.props.showSnackBar("Status updated successfully", "success", 3000)
    }

    if (!this.props.attachProductToRequestProg && prevProps.attachProductToRequestProg) {
      if (this.props.attachProductToRequestErr) {
        this.props.showSnackBar("Failed to attach", "error", 3000)
      } else {
        this.fetchData()
        this.props.showSnackBar("Attached successfully", "success", 3000)
      }
    }


    if (!this.props.startNewRenewalProgress && prevProps.startNewRenewalProgress) {
      if (this.props.productRenewalInfoError === null) {
        this.props.history.push(`/app/evaluate/${this.props.productRenewalInfo?.EvaluationId}/workflow/interim`);
      } else {
        this.props.showSnackBar("Something went wrong", "error", 3000)
      }
    }

    if (this.props.orgUser.addOrgSettingsInprogress === false && prevProps.orgUser.addOrgSettingsInprogress === true) {
      this.props.fetchAllOrgSettings()
    }

  }

  initViews = () => {
    const { orgUser } = this.props;
    let configKey = "RENEWAL_VIEWS";
    let viewsList = this.getConfigValue(orgUser?.fetchAllOrgSettingsSuccess, "SettingName", configKey) || [];
    viewsList = Validator.isJson(viewsList) ? JSON.parse(viewsList) : [];
    this.setState({
      viewsList: viewsList,
      isLoading: false,
    }, () => {
      let defaultView = viewsList.find(view => view.isDefault)
      if (defaultView && !this.state.selectedFilterView) {
        this.selectView(defaultView.id);
      }
    });
  }

  selectView = (id) => {
    let view = this.state.viewsList?.find(view => view.id === id);
    console.log(view, 'ViewData')
    if (view && view.filters) {
      this.setState(
        {
          ...view.filters,
          onViewSelection: this.state.onViewSelection ? false : true
        }, () => {
          this.fetchData();
        })
    } else {
      this.setState({
        onViewSelection: this.state.onViewSelection ? false : true
      }, () => {
        this.clearFilter()
      })
    }
    this.setState({ selectedFilterView: id });
  }

  clearFilter = () => {
    this.setState(
      {
        showFilters: [],
        statusFilter: [1, 2],
        projectOwnerIDs: [],
        contractOwnerIDs: [],
        query: '',
        dates: null,
        projectStatus: '',
      }, () => {
        this.fetchData();
      })
  }

  fetchData = () => {
    const data = {};
    let dates = this.state.dates;
    data.projectStatus = this.state.projectStatus?.value || '';
    data.projectOwnerIDs = this.state.projectOwnerIDs?.map(user => user.value) || [];
    data.contractOwnerIDs = this.state.contractOwnerIDs?.map(user => user.value) || [];
    data.start = startDate;
    data.query = this.state.query;
    if (dates && !dates.isAny && dates?.startDate && dates?.endDate) {
      if (dates.duration === -1) {
        data.end = dates.endDate;
        delete data.start;
      } else {
        data.start = dates.startDate
        data.end = dates.endDate
      }
    }
    data.status = this.state.statusFilter;

    if (this.state.customColumns && this.state.customColumns.length > 0) {
      console.log(this.state.customColumns, 'DefaultColumnssssssssssssssssssssssss')
      const item_ids = this.state.customColumns.map(o => o.id)
      data.itemIds = item_ids
    }
    this.props.fetchRenewal(data)
  }

  renderEventContent(eventInfo) {
    console.log(eventInfo.event)
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }

  renewProduct(productName, productId, date) {
    if (this.props.configurations !== null) {
      const defaultObjRenew = this.props.configurations.find((item) => item.SettingName === "ORG_DEFAULT_RENEWAL_WORKFLOW");

      var defaultRenewalId = null;
      if ((defaultObjRenew && Validator.isJson(defaultObjRenew?.Value) && defaultObjRenew?.Value && defaultObjRenew?.Value !== 'NULL')) {
        defaultRenewalId = JSON.parse(defaultObjRenew?.Value).wid
      }

      if (defaultRenewalId === null) {
        this.props.showSnackBar("Set default renewal workflow before renew.", "error", 3000)
        return;
      }

      this.props.startNewRenewalProcess(productId, {
        date: new Date(date),
        name: 'Renewal for ' + productName
      })
    }
  }

  viewRenewal(evaluationId) {
    if (evaluationId !== undefined && evaluationId !== null && evaluationId !== 0) {
      this.props.history.push(`/app/evaluate/${evaluationId}/workflow/interim`);
    }
  }

  getCalendarData = async (fetchInfo, successCallback) => {
    if (fetchInfo) {
      try {
        const data = {}
        data.start = new Date(fetchInfo.start);
        data.end = new Date(fetchInfo.end);
        data.projectStatus = '';
        data.projectOwnerIDs = [];
        data.contractOwnerIDs = [];

        const api_server = Environment.api_host("PRODUCT");
        const timestamp = (new Date()).getTime();

        let url = `${api_server}/renewals?t=${timestamp}`;
        const response = await axios.post(url, data, {
          headers: { 'authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'application/json' }
        });

        successCallback(
          response.data.map(item => {
            let redirectURL;
            let name;
            if (item?.entity === 'PRODUCT') {
              name = item.product?.name;
              redirectURL = `/app/products/org-product-details/${item?.product?.id}?document_id=${item.contract?.id}#renewal_page`;
            } else {
              name = item.vendor?.name;
              redirectURL = `/app/vendors/org-vendor-details/${item?.vendor?.id}?document_id=${item.contract?.id}#${item.contract?.meta?.tab_slug}`;
            }
            return { title: name, url: redirectURL, start: item.contract?.renewalDate, allDay: true };
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  }

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      let appList = [];
      this.props.users?.forEach((item, index) => {
        appList.push({
          label: item.Name,
          value: item.ID
        })
      })
      return {
        options: appList,
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      page: 0,
      pageSize: 10,
      query: inputValue
    };
    let url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };


  getConfigValue(array, key, value) {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  }

  handleClick = (event, row) => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget || event.current,
      currentRenewal: row,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      currentRenewal: null,
    });
  };

  callBack() {
    let url;
    if (this.state.selectedRenewalProduct?.type === 'PRODUCT') {
      url = `/app/products/org-product-details/${this.state.selectedRenewalProduct?.id}?document_id=${this.state.selectedRenewalContract?.id}#renewal_page`;
    } else {
      url = `/app/vendors/org-vendor-details/${this.state.selectedRenewalProduct?.id}?document_id=${this.state.selectedRenewalContract?.id}#${this.state.selectedRenewalContract?.meta?.tab_slug}`;
    }
    this.props.history.push(url)
    this.onCancelUpdateContract()
  }

  onCancelUpdateContract() {
    this.props.hideConfirmCallbackDialog()
  }

  updateStatusFilter(data) {
    let statusSelected;
    if (data === null || data === '') {
      statusSelected = [{
        label: 'All',
        value: 0
      }]
    } else {
      let isOtherSelection = data.filter(o => o.value > 0)
      if (isOtherSelection && isOtherSelection.length > 0) {
        statusSelected = isOtherSelection
      } else {
        statusSelected = [{
          label: 'All',
          value: 0
        }]
      }
    }
    console.log(data);
    this.setState({ statusFilter: statusSelected }, () => {
      // this.fetchData();
    });
  }

  handleOnClickState(newState) {
    let { statusFilter } = this.state;
    if (statusFilter.indexOf(newState) !== -1) {
      statusFilter.splice(statusFilter.indexOf(newState), 1);
    } else {
      statusFilter.push(newState)
    }
    this.setState({
      statusFilter: statusFilter
    }, () => {
      this.fetchData()
    })
  }

  handleOnClickFilter(value, index) {
    let showFilters = this.state.showFilters;
    if (showFilters.indexOf(value) !== -1) {
      showFilters.splice(showFilters.indexOf(value), 1)
    } else {
      showFilters.push(value)
    }
    this.setState({
      showFilters: showFilters
    })
  }

  searchQuery = (text) => {
    this.setState(
      {
        query: text,
      },
      () => {
        this.fetchData()
      }
    );
  }

  getCurrentFilters() {
    const filters = {
      dates: this.state.dates,
      projectStatus: this.state.projectStatus,
      projectOwnerIDs: this.state.projectOwnerIDs,
      contractOwnerIDs: this.state.contractOwnerIDs,
      query: this.state.query,
      statusFilter: this.state.statusFilter,
      customColumns: this.state.customColumns,
      showFilters: this.state.showFilters,
      viewColumns: this.state.viewColumns
    };
    return filters;
  }

  setNewViewType(type) {
    this.setState({
      currentView: type
    }, () => {
      window.sessionStorage.setItem("renewalSelectedViewType", type);
    })
  }

  render() {
    const { classes } = this.props;
    const { availableFilters, showFilters } = this.state;
    let isRequestType = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'REQUEST_TYPES')
    let requestTypesData = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "REQUEST_TYPES") || [];
    let requestTypes = [];
    if (isRequestType && Validator.isJson(requestTypesData) && requestTypesData !== undefined && requestTypesData !== "NULL" && requestTypesData !== null && requestTypesData !== "null" && requestTypesData.length > 0) {
      requestTypes = JSON.parse(requestTypesData);
    }

    const openAction = Boolean(this.state.anchorEl);

    const messageData = {
      head: "Action Needed",
      subHead: "Do you also want to add a new contract or update existing contract?",
      firstBtn: "No",
      secondBtn: "Yes",
    };

    return (
      <>
        <div className={classes.head} style={{ marginTop: 'unset' }}>
          <AppBarV2 title="Renewal" withBack={false} dropdown={true} />
        </div>
        <div className={classes.root}>
          <div className={classes.topFilters}>
            <Grid container spacing={4} alignItems="center" justifyContent="flex-start">
              <Grid item>
                {this.state.currentView !== 'CalendarView' && <div className={classes.searchBox}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Search Renewal
                  </Typography>
                  <TextField
                    className={classes.autoSelectInput}
                    value={this.state.query}
                    variant="outlined"
                    placeholder={"Search Renewal"}
                    type="search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onInput={(e) => {
                      this.searchQuery(e.target.value);
                    }}
                  />
                </div>}
              </Grid>
              {this.state.currentView === 'ListView' || this.state.currentView === 'TableView' ? (<>
                {showFilters.indexOf('renewal_status') !== -1 && <Grid item>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Renewal Status
                  </Typography>
                  <div className={classes.selectComp} style={{ width: '200px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.statusfilterBtn}
                      onClick={(event) => {
                        this.setState({
                          renewalStatusfilterPopover: event.currentTarget,
                        });
                      }}
                    >
                      Status
                    </Button>
                    <Popover
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      id="renewalStatusfilterPopover"
                      className={classes.popover}
                      anchorEl={this.state.renewalStatusfilterPopover}
                      keepMounted
                      open={Boolean(this.state.renewalStatusfilterPopover)}
                      onClose={() => {
                        this.setState({ renewalStatusfilterPopover: null });
                      }}
                    >
                      <List>
                        {statusFilterOptions.map((obj, i) => (
                          <ListItem key={i} classes={{ button: classes.listItem }} button onClick={() => { }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  onChange={(e) => {
                                    let newStatusFilter = [...this.state.statusFilter];
                                    let index = newStatusFilter.indexOf(obj.value);
                                    if (index > -1) {
                                      newStatusFilter.splice(index, 1);
                                    } else {
                                      newStatusFilter.push(obj.value)
                                    }
                                    this.setState({ statusFilter: newStatusFilter },
                                      () => {
                                        this.fetchData();
                                      }
                                    );
                                  }}
                                  checked={this.state.statusFilter?.includes(obj.value)}
                                />
                              }
                              name={obj.value}
                              label={obj.label}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Popover>
                  </div>
                </Grid>}
                {showFilters.indexOf('date') !== -1 && <Grid item>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Date
                  </Typography>
                  <DateFilter
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    duration={this.state.duration}
                    showPastDue={true}
                    onChangeDates={(dates) => {
                      this.setState({ dates: dates }, () => {
                        this.fetchData();
                      });
                    }}
                  />
                </Grid>}
                {showFilters.indexOf('project_status') !== -1 && <Grid item>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Project Status
                  </Typography>
                  <div className={classes.selectComp}>
                    <ReactSelect
                      isClearable
                      menuPosition='fixed'
                      isSearchable={false}
                      options={stateArr}
                      classNamePrefix="select"
                      placeholder="Select Project Status"
                      onChange={(e) => {
                        this.setState({ projectStatus: e }, () => {
                          this.fetchData();
                        });
                      }}
                      value={this.state.projectStatus}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </Grid>}
                {showFilters.indexOf('project_owner') !== -1 && <Grid item>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Project Owner
                  </Typography>
                  <div className={classes.selectComp}>
                    <SelectAsyncPaginate
                      isClearable={false}
                      isMulti
                      isSearchable={true}
                      value={this.state.projectOwnerIDs}
                      menuPosition='fixed'
                      classNamePrefix="select"
                      isLoading={false}
                      placeholder="Select project owner"
                      additional={{
                        page: 0,
                      }}
                      loadOptions={this.getOptions}
                      onChange={(e) => {
                        this.setState({
                          projectOwnerIDs: e,
                        }, () => {
                          this.fetchData();
                        })
                      }}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </Grid>}
                {showFilters.indexOf('contract_owner') !== -1 && <Grid item>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Contract Owner
                  </Typography>
                  <div className={classes.selectComp}>
                    <SelectAsyncPaginate
                      isClearable={false}
                      isMulti
                      isSearchable={true}
                      value={this.state.contractOwnerIDs}
                      menuPosition='fixed'
                      classNamePrefix="select"
                      isLoading={false}
                      placeholder="Select contract owner"
                      additional={{
                        page: 0,
                      }}
                      loadOptions={this.getOptions}
                      onChange={(e) => {
                        this.setState({
                          contractOwnerIDs: e,
                        }, () => {
                          this.fetchData();
                        })
                      }}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </Grid>}

              </>) : <Grid item ></Grid>}

              {<Grid item style={{ marginLeft: 'auto', marginTop: 32 }}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item>
                    {/* <Switchbox
                      default={this.state.currentView}
                      on={<ListAltIcon style={this.state.currentView === 'ListView' ? { color: 'white' } : {}} />}
                      onVal="ListView"
                      off={<CalendarMonthIcon style={this.state.currentView === 'CalendarView' ? { color: 'white' } : {}} />}
                      offVal="CalendarView"
                      handleOnChange={(val) => {
                        this.setState({ currentView: val })
                      }}
                    /> */}

                    <ButtonGroup aria-label="small outlined button group">
                      <Button
                        title={'List View'}
                        style={this.state.currentView === 'ListView' ? { color: '#fff', background: '#222' } : {}}
                        onClick={() => {
                          this.setNewViewType('ListView');
                        }}><ListAltIcon /></Button>
                      <Button
                        title={'Calendar View'}
                        style={this.state.currentView === 'CalendarView' ? { color: '#fff', background: '#222' } : {}}
                        onClick={() => {
                          this.setNewViewType('CalendarView');
                        }}><CalendarMonthIcon /></Button>
                      <Button
                        title={'Tabular View'}
                        style={this.state.currentView === 'TableView' ? { color: '#fff', background: '#222' } : {}}
                        onClick={() => {
                          this.setNewViewType('TableView');
                        }}><ListIcon /></Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </Grid>}



            </Grid>

            {(this.state.currentView === 'ListView' || this.state.currentView === 'TableView') && <div className={classes.filterOptions}>
              <Grid container>
                {availableFilters.map((objState, index) => {
                  if (!objState.isShow) {
                    return null
                  }
                  return (
                    <Grid
                      item
                      key={index}
                      className={classNames(classes.stateLabel, showFilters.indexOf(objState.slug) !== -1 ? classes.selected : {})}
                      onClick={() => this.handleOnClickFilter(objState.slug, index)}
                    >
                      <Typography className={classes.stateLabelText}>{objState.label} +</Typography>
                    </Grid>
                  );
                })}
                <Grid item key={'clearFilter'}>
                  <Button className={classes.clearFilterBtn} variant="outlined" color="secondary" onClick={() => { this.clearFilter() }}>
                    Clear Filter
                  </Button>
                </Grid>
              </Grid>
            </div>}
          </div>

          {<Grid container spacing={1} justify="center">
            {this.props.fetchRenewalProg && this.props.renewalList && this.props.renewalList.length === 0 && <CircularProgress />}
            {this.state.currentView !== 'TableView' && !this.props.fetchRenewalProg && this.props.renewalList && this.props.renewalList.length === 0 && (
              <>
                <br />
                <Typography>No Data Found.</Typography>
              </>
            )}

            {this.state.currentView === 'ListView' && this.props.renewalList && this.props.renewalList.map((item, index) => {
              // if (item?.contract?.status === 3) {
              //   return null
              // }
              // var a = moment(item.contract?.renewalDate);
              // var b = moment(new Date());

              let renewalInDays = preciseDiffMoments(new Date(), new Date(item.contract?.renewalDate))

              let url;
              if (item.entity === 'PRODUCT') {
                url = `/app/products/org-product-details/${item.product?.id}?document_id=${item.contract.id}#renewal_page`;
              } else {
                url = `/app/vendors/org-vendor-details/${item.vendor?.id}?document_id=${item.contract.id}#${item.contract.meta?.tab_slug}`;
              }

              return <Grid item xs={12}>
                <div className={classes.evaluateCard} key={`${index}-${item.entity === 'PRODUCT' ? item.product?.id : item?.vendor?.id}`}>
                  <div className={classes.productLogo} title={item.entity === 'PRODUCT' ? item.product?.productLogo : item?.vendor?.logo}
                    onClick={() => this.props.history.push(url)}>
                    <Image src={item.entity === 'PRODUCT' ? item.product?.productLogo : item?.vendor?.logo} alt={item.entity === 'PRODUCT' ? item.product?.name : item?.vendor?.name} title={item.entity === 'PRODUCT' ? item.product?.name : item?.vendor?.name} />
                  </div>
                  <div className={classes.evaluateText}
                    onClick={() => this.props.history.push(url)}>
                    <p className={classes.evaluateCardTitle}>{item.entity === 'PRODUCT' ? item?.product?.name : item?.vendor?.name} - {item.contract?.name}</p>
                    <p className={classes.reviewCompleted}>Renewal Date: {CommonFn.formateDate(item.contract?.renewalDate, true)}</p>
                    <p className={classes.reviewCompleted}>Renewal in: {renewalInDays}</p>
                  </div>

                  <div className={classes.evaluateText}
                    onClick={() => this.props.history.push(url)}>
                    {item.contract?.owner?.name && item.contract?.owner?.name.length > 0 && <p className={classes.ownerNames}>Contract owner: {item.contract?.owner.name}</p>}
                    {item.project?.owner?.name && item.project?.owner?.name.length > 0 && <p className={classes.ownerNames}>Project owner: {item.project?.owner.name}</p>}

                    {item.project && <>
                      {item.project.currentStep && <p className={classes.ownerNames}>Current Step: {item.project.currentStep}</p>}
                      {<p className={classes.ownerNames}>Request Status: {item.project.requestStatus === 'PendingApproval' ? 'Pending Approval' : item.project.requestStatus}</p>}
                    </>}
                  </div>

                  <div className={classes.evaluateDetailsBox}>
                    &nbsp;&nbsp;
                    <FormControl
                      variant="outlined"
                      className={classes.select}
                      margin="dense"
                    >
                      {true && <Select
                        value={item?.contract?.status}
                        onClick={() => { }}
                        onChange={(event) => {
                          if (event.target.value === 3) {
                            let entity
                            if (item.entity === 'VENDOR') {
                              entity = { ...item.vendor, type: 'VENDOR' }
                            } else {
                              entity = { ...item.product, type: 'PRODUCT' }
                            }
                            this.setState({
                              selectedRenewalProduct: entity,
                              selectedRenewalContract: item.contract
                            }, () => {
                              this.props.showConfirmCallbackDialog()
                            })
                          }
                          if (item.entity === 'PRODUCT') {
                            this.props.updateRenewal(item.product?.id, { date: item.contract?.renewalDate, status: event.target.value })
                          } else {
                            this.props.updateEntityRenewal(item.contract?.id, { date: item.contract?.renewalDate, status: event.target.value })
                          }
                        }}>
                        {<MenuItem value={1}>Not started</MenuItem>}
                        {<MenuItem value={2}>In progress</MenuItem>}
                        {<MenuItem value={3}>Renewed</MenuItem>}
                        {<MenuItem value={4}>Not going to renew</MenuItem>}
                      </Select>}
                    </FormControl>
                    &nbsp;&nbsp;

                    {item.project && <Button variant="contained" color="secondary" className={classes.sendBtn}
                      onClick={() => {
                        this.viewRenewal(item.project?.id)
                      }}
                    > View Renewal</Button>}
                    {item.project && <IconButton style={{ marginLeft: 25 }} ref={this.btnRef}></IconButton>}


                    {(item.entity === 'PRODUCT' && (item.project === undefined || item.project === null || item.project === '')) &&
                      <Button variant="contained" color="secondary" className={classes.sendBtn}
                        onClick={() => {
                          this.setState({ selectedRenewalContract: item.contract, selectedRenewalProduct: item.product, selectedRenewalDate: item.contract?.renewalDate }, () => {
                            this.props.showEvaluationProductSelectionDialog();
                            // this.handleClose();
                          })
                        }}
                      > Start Renewal</Button>}

                    {(item.entity !== 'PRODUCT' && (item.project === undefined || item.project === null || item.project === '')) &&
                      <Button
                        // disabled
                        variant="contained"
                        color="secondary"
                        className={classes.sendBtn}
                        onClick={() => {
                          this.setState({
                            selectedRenewalContract: item.contract,
                            selectedRenewalProduct: {
                              name: item.vendor.name,
                              id: item.vendor.id,
                              productLogo: item.vendor.logo,
                              type: item.entity
                            },
                            selectedRenewalDate: item.contract?.renewalDate
                          }, () => {
                            this.props.showEvaluationProductSelectionDialog();
                            // this.handleClose();
                          })
                        }}
                      > Start Renewal</Button>}


                    {(item.project === undefined || item.project === null || item.project === '') &&
                      <Tooltip
                        title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{'Link Project'}</span>}
                        placement="top"
                        classes={{ tooltip: classes.noMaxWidth }}>
                        <IconButton ref={this.btnRef}
                          onClick={(e) => {
                            this.setState({
                              showAttachRequestDialog: true,
                              selectedRenewalProduct: item.entity === 'PRODUCT' ? {
                                ...item.product,
                                type: item.entity
                              } : {
                                name: item.vendor.name,
                                id: item.vendor.id,
                                productLogo: item.vendor.logo,
                                type: item.entity
                              },
                              selectedRenewalContract: item.contract,
                              selectedRenewalDate: item.contract?.renewalDate,
                              selectedEntityType: item.entity
                            })
                          }}>
                          <LinkIcon />
                        </IconButton>
                      </Tooltip>}
                  </div>
                </div>
              </Grid>

            })}

            {this.state.currentView === 'CalendarView' && (
              <div style={{ background: 'white', padding: 10 }}>
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  // events={events}
                  eventContent={this.renderEventContent}
                  events={
                    (fetchInfo, successCallback, failureCallback) => this.getCalendarData(fetchInfo, successCallback, failureCallback)
                  }
                />
              </div>
            )}

          </Grid>}

          {this.state.currentView === 'TableView' && <div>
            <RenewalTable
              fetchProgress={this.props.fetchRenewalProg}
              renewalsData={this.props.renewalList}
              fetchRenewals={(columns) => {
                this.setState({
                  customColumns: columns
                }, () => {
                  this.fetchData()
                })
              }}
              viewRenewal={(id) => {
                this.viewRenewal(id)
              }}
              showConfirmCallbackDialog={(product, contract) => {
                this.setState({
                  selectedRenewalProduct: product,
                  selectedRenewalContract: contract
                }, () => {
                  this.props.showConfirmCallbackDialog()
                })
              }}
              updateRenewal={(id, params) => {
                this.props.updateRenewal(id, params)
              }}
              updateEntityRenewal={(id, params) => {
                this.props.updateEntityRenewal(id, params)
              }}
              showEvaluationProductSelectionDialog={(contract, product, date) => {
                this.setState({ selectedRenewalContract: contract, selectedRenewalProduct: product, selectedRenewalDate: date }, () => {
                  this.props.showEvaluationProductSelectionDialog();
                })
              }}
              showAttachRequestDialog={(product, contract, date, entity) => {
                this.setState({
                  showAttachRequestDialog: true,
                  selectedRenewalProduct: product,
                  selectedRenewalContract: contract,
                  selectedRenewalDate: date,
                  selectedEntityType: entity
                })
              }}
              showAddUpdateRenewalViewsDialog={(columns) => {
                this.setState({
                  viewColumns: columns,
                  showViewAddUpdateDialog: true
                })
              }}
              viewsList={this.state.viewsList}
              selectedFilterView={this.state.selectedFilterView}
              selectView={(id) => {
                this.selectView(id);
              }}
              onViewSelection={this.state.onViewSelection}
              viewColumns={this.state.viewColumns}
            />
          </div>}
        </div>

        {this.state.currentRenewal && <Popover
          className={classes.popover}
          id={'add-vendor'}
          open={openAction}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handleClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}>

          <List>
            {false && <ListItem
              button
              onClick={() => {
                this.setState({ selectedRenewalProduct: this.state.currentRenewal.product, selectedRenewalDate: this.state.currentRenewal.contract?.renewalDate }, () => {
                  this.props.showEvaluationProductSelectionDialog();
                  this.handleClose();
                })
              }}
            >
              <Typography variant="inherit" >
                Start Renewal
              </Typography>
            </ListItem>}
            {/* <Divider /> */}
            <ListItem
              button
              onClick={() => {
                this.setState({ showAttachRequestDialog: true, selectedRenewalProduct: this.state.currentRenewal.product, selectedRenewalDate: this.state.currentRenewal.contract?.renewalDate })
                this.handleClose();
              }}
              className={this.state.currentRenewal.requestStatus !== "Draft" ? classes.disabled : ''}
            >
              <Typography variant="inherit">
                Link Project
              </Typography>
            </ListItem>
          </List>
        </Popover>}

        {this.props.collaboratorDialogOpen &&
          <SearchUserPoper {...this.props.collaboratorDialogData} />
        }

        {this.state.showAttachRequestDialog && <AttachRequestDialog
          selectedRenewalProduct={this.state.selectedRenewalProduct}
          selectedRenewalContract={this.state.selectedRenewalContract}
          entityType={this.state.selectedEntityType}
          hideDialog={() => {
            this.setState({ showAttachRequestDialog: false });
          }}
          onSubmit={(requestId) => {
            if (this.state.selectedEntityType === 'PRODUCT') {
              this.props.attachProductToRequest(this.state.selectedRenewalProduct.id,
                requestId,
                {
                  contractID: parseInt(this.state.selectedRenewalContract?.id)
                });
            } else {
              this.props.updateEntityRenewal(this.state.selectedRenewalContract?.id, {
                evaluationId: requestId,
                status: 2
              })
            }
            this.setState({ showAttachRequestDialog: false });
          }}
        />}

        {this.props.isOpen && <StartRenewalDialog
          selectedRenewalProduct={this.state.selectedRenewalProduct}
          selectedRenewalContract={this.state.selectedRenewalContract}
          selectedRenewalDate={this.state.selectedRenewalDate}
          isRequestType={isRequestType && requestTypes.length > 0 ? true : false}
          requestTypes={requestTypes}
        />}

        {this.state.showViewAddUpdateDialog && (
          <AddUpdateRenewalViewDialog
            hideDialog={() => this.setState({ showViewAddUpdateDialog: false })}
            filters={this.getCurrentFilters()}
          />
        )}


        <ConfirmCallbackDialog
          messageData={messageData}
          callBack={this.callBack}
          closeCallBack={this.onCancelUpdateContract}
          closeDialog={this.onCancelUpdateContract}
        />
      </>
    );
  }
}

export default connector(compose(withStyles(styles))(Renewal));
