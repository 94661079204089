import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import classnames from "classnames";
import Snackbar from 'Components/Common/Snackbar';

import {
    Typography, CircularProgress
} from '@material-ui/core'

import { withRouter } from 'react-router-dom';
import { searchUsers } from "redux/usersSettings/action";
import { fetchOrgGroup } from "redux/org/group/action";
import CustomReply from 'Components/Common/FormReply/CustomReply';
import BottomActions from "./BottomActions";

import Alert from '@material-ui/lab/Alert';

import * as Validator from "util/Validation";

import { showSearchTeamsPoperDialog, hideSearchTeamsPoperDialog, showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import SearchTeamsPoper from "Components/Common/TeamsAndUser/SearchTeamsPoper";
import SearchUserPoper from "Components/Common/TeamsAndUser/SearchUserPoper";
import { showSnackBar } from "redux/snackbar/action";

import { addCustomFormResponse, fetchWorkflowFormResponse, fetchOrgFormUsingId, fetchMasterFormUsingId } from "redux/publicForm/action"

const connectedProps = (state) => ({
    user: state.authUser.user,
    addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
    componentUserResponseError: state.publicForm.componentUserResponseError,
    workflowComponentsForms: state.workflow.workflowComponentsForms,
    fetchCustomFormProgress: state.evaluationWorkflow.fetchCustomFormProgress,
    workflowCustomForm: state.evaluationWorkflow.workflowCustomForm,
    addCustomComponentUserResponseProgress: state.publicForm.addCustomComponentUserResponseProgress,
    componentUserResponse: state.publicForm.componentUserResponse,
    workflowComponentDetail: state.workflow.workflowComponentDetail,
    workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
    orgFormData: state.publicForm.orgFormData,
    fetchOrgFormsByIdProgress: state.publicForm.fetchOrgFormsByIdProgress,
    fetchMasterFormsByIdProgress: state.publicForm.fetchMasterFormsByIdProgress,
    masterFormData: state.publicForm.masterFormData,
    fetchWokflowFormResponseProgress: state.publicForm.fetchWokflowFormResponseProgress,
    workflowFormResponse: state.publicForm.workflowFormResponse,
    similarExistingProductProgress: state.orgProduct.similarExistingProductProgress,
    similarExistingProducts: state.orgProduct.similarExistingProducts,
    evaluation: state.evaluationMetaData.evaluation,
    fetchProgress: state.evaluationMetaData.fetchProgress,
    userTimeline: state.workflow.userTimeline,
    fetchWokflowUserTimelineProgress: state.workflow.fetchWokflowUserTimelineProgress,
    isOpenTeamDialog: state.dialogs.isShowSearchTeamPoperDialog,
    isOpenUserDialog: state.dialogs.isShowSearchUserPoperDialog,
    fetchDecisionItemsProgress: state.workflowSummary.fetchDecisionItemsProgress,
    fetchDecisionItemsError: state.workflowSummary.fetchDecisionItemsError,
    decisionItems: state.workflowSummary.decisionItems,

    fetchComponentColloboratorProgress: state.workflow.fetchComponentColloboratorProgress,
    fetchComponentColloboratorError: state.workflow.fetchComponentColloboratorError,
    componentColloborators: state.workflow.componentColloborators,

});

const connectionActions = {
    addCustomFormResponse: addCustomFormResponse,
    fetchUsers: searchUsers,
    fetchOrgGroup: fetchOrgGroup,
    fetchOrgFormUsingId: fetchOrgFormUsingId,
    fetchMasterFormUsingId: fetchMasterFormUsingId,
    fetchWorkflowFormResponse: fetchWorkflowFormResponse,
    showSearchTeamsPoperDialog: showSearchTeamsPoperDialog,
    hideSearchTeamsPoperDialog: hideSearchTeamsPoperDialog,
    showSearchUsersPoperDialog: showSearchUsersPoperDialog,
    hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
    showSnackBar: showSnackBar,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    topRoot: {
        height: '94.5vh',
        overflow: 'scroll',
        "& [class*=MuiAvatar-root]": {
            width: 30,
            height: 30,
            fontSize: 14
        },
    },
    root: {
        // background: '#fff',
        padding: 20,
        width: '80%',
        margin: '0px auto',
        paddingBottom: 100
    },
    actions: {
        textAlign: "center",
        marginTop: theme.spacing(2)
    },
    actionBtn: {
        minWidth: '200px'
    },
    actionBtnDisabled: {
        minWidth: '200px',
    },
    dialogContent: {
        margin: 'auto',
        marginBottom: 20,
        "& [class*=MuiAvatar-root]": {
            width: 30,
            height: 30,
            fontSize: 14
        },
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    stepHead: {
        color: '#282D30',
        fontSize: 18,
        margin: 0,
        fontWeight: 500,
        marginBottom: 10
    },
    stepHead2: {
        color: '#282D30',
        fontSize: 14,
        margin: 0,
        fontWeight: 500,
        marginBottom: 10
    },
    sectionName: {
        color: '#282D30',
        fontSize: 18,
        margin: 0,
        fontWeight: 500,
        marginBottom: 10
    },
    stepSubHead: {
        color: '#4B4B4B',
        fontSize: 12,
        margin: 0,
        paddingBottom: 20
    },
    customForm: {
        marginTop: 20,
        borderRadius: 8,
        background: '#fff',
        position: 'relative',
        border: '1px solid #dadce0',
        marginBottom: 15,
        padding: 20,
    },
    footer: {
        textAlign: 'right'
    },
    section: {
        margin: 0,
        // border: '1px solid #f1f1f1',
        // padding: 20
    },
    sectionItem: {
        padding: 20,
        borderRadius: 8,
        background: '#fff',
        position: 'relative',
        border: '1px solid #dadce0',
        marginBottom: 15,
    },
    sectionBox: {
        padding: 20,
        borderRadius: 8,
        background: '#fff',
        position: 'relative',
        border: '1px solid #dadce0',
        marginBottom: 15,
        borderTop: '4px solid #7fc781'
    },
    sectionTitle: {
        color: '#282D30',
        fontSize: 16,
        margin: 0,
        fontWeight: 500,
        marginBottom: 10
    },
    loader: {
        textAlign: "center",
        padding: theme.spacing(10, 0)
    },
    alert: {
        padding: '10px'
    },
    topHeadColor: {
        background: '#1f73b7',
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        height: 10,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        width: '100%'
    },
    disableForm: {
        pointerEvents: 'none'
    },
    titleFlex: {
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    },
    titlePart: {
        flex: 1
    },
    progressPart: {
        textAlign: 'right'
    },
    assignedTo: {
        marginTop: 10
    },
    addUser: {
        cursor: 'pointer'
    }
});

class CustomForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customFormTitle: '',
            customFormDescription: '',
            customFormData: [],
            isShowLoader: true,
            formError: '',
            showSectionMap: {},
            showSectionResponseMatch: {},
            itemIdsForSection: {},
            saveType: 'NEXT',
            isFormEditable: true,
            componentStatus: null,
            nextComponent: null,
            nextClickLoaderSave: false,
            nextClickLoader: false,
            currentSelected: null,
            IntegrationFormData: null,
            webHookItemIdMap: {},
            totalQuestions: 0,
            totalAnswered: 0,
            decisionItemMap: {},
            isCheckDecisionItems: false,
            showDependentItems: {},
            showSectionCheckTypeMap: {},
            formColloborator: [],
            isForFormColloborator: true,
            form_type: this.props.match.params.form_type,
            form_id: this.props.match.params.form_id,
            evaluation_id: this.props.match.params.evaluation_id,
            component_id: this.props.match.params.component_id,
            org_id: this.props.match.params.org_id,
            email_address: '',
            email_address_error: ''
        }
        this.avatarName = this.avatarName.bind(this);
        this.submitResponse = this.submitResponse.bind(this);
        this.setSelectedTeams = this.setSelectedTeams.bind(this);
        this.setSelectedUsers = this.setSelectedUsers.bind(this);
        this.cleanErrors = this.cleanErrors.bind(this)
    }

    avatarName(name) {
        return name.substring(0, 1);
    }

    componentDidMount() {
        this.checkForStatus();
        this.checkWorkflowConfiguration()
    }

    componentDidUpdate(prevProps) {
        if (this.props.addCustomComponentUserResponseProgress === false && prevProps.addCustomComponentUserResponseProgress === true) {
            this.setState({
                nextClickLoader: false,
                nextClickLoaderSave: false
            })
            if (this.props.componentUserResponseError === null) {
                this.props.showSnackBar("Response updated successfully.", "success", 3000)
            } else {
                this.props.showSnackBar("Something went wrong.", "error", 3000)
            }
        }
        if (this.props.fetchOrgFormsByIdProgress === false && prevProps.fetchOrgFormsByIdProgress === true) {
            this.checkConditions('ORG');
        }

        if (this.props.fetchMasterFormsByIdProgress === false && prevProps.fetchMasterFormsByIdProgress === true) {
            this.checkConditions('MASTER');
        }

        if (this.props.fetchWokflowFormResponseProgress === false && prevProps.fetchWokflowFormResponseProgress === true) {
            this.checkResponses();
        }

        if (this.props.fetchWokflowUserTimelineProgress === false && prevProps.fetchWokflowUserTimelineProgress === true) {
            this.checkForStatus();
        }

        if (this.props.fetchDecisionItemsProgress === false && prevProps.fetchDecisionItemsProgress === true) {
            if (this.props.fetchDecisionItemsError === null) {
                // console.log(this.props.decisionItems, 'decisionItems')
                if (this.props.decisionItems !== null && this.props.decisionItems.length > 0) {
                    let decisionItemMap = {};
                    this.props.decisionItems.forEach(function (di) {
                        if (decisionItemMap[di.SectionID] === undefined) {
                            decisionItemMap[di.SectionID] = [];
                            decisionItemMap[di.SectionID].push(di.ID)
                        } else {
                            decisionItemMap[di.SectionID].push(di.ID)
                        }
                    })
                    this.setState({
                        decisionItemMap: decisionItemMap
                    })
                }
            }
        }

        if (!this.props.fetchComponentColloboratorProgress && prevProps.fetchComponentColloboratorProgress) {
            if (this.props.fetchComponentColloboratorError === null) {
                console.log(this.props.componentColloborators, 'componentColloborators-success111')
                this.setState({
                    formColloborator: this.props.componentColloborators.map(o => {
                        return {
                            EntityID: o.EntityID,
                            ...o.User
                        }
                    })
                })
            } else {
                console.log(this.props.fetchComponentColloboratorError, 'error')
            }
        }

    }

    checkWorkflowConfiguration() {
        const { form_type, form_id, evaluation_id, component_id, org_id } = this.state;
        console.log(form_type, 'form_type')
        console.log(form_id, 'form_id')
        console.log(evaluation_id, 'evaluation_id')
        console.log(component_id, 'component_id')
        console.log(org_id, 'org_id')

        if (form_type === "MASTER") {
            this.props.fetchMasterFormUsingId(form_id, evaluation_id, org_id);
        } else {
            this.props.fetchOrgFormUsingId(form_id, evaluation_id, org_id);
        }
        this.props.fetchWorkflowFormResponse(evaluation_id, form_id, form_type, component_id, org_id)
    }

    checkForStatus() {
        this.setState({
            isFormEditable: true,
        })
    }

    checkResponses() {
        const { workflowFormResponse } = this.props;
        const { customFormData } = this.state;
        var me = this;
        if (customFormData === null || customFormData.length <= 0 || workflowFormResponse === null || workflowFormResponse.length <= 0) {
            return;
        }

        var responseMap = {};
        workflowFormResponse.forEach(function (item) {
            responseMap[item.ItemId] = item;
        });
        var formType = this.state.form_type;
        var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
        var customFormDataNew = this.state.customFormData;
        customFormDataNew[sectionName].forEach(function (section) {
            var sectionItems = section[sectionItemName];
            sectionItems.forEach(function (item) {
                if (responseMap[item.ID] === undefined) {
                    return;
                }
                var value = '';
                try {
                    value = JSON.parse(responseMap[item.ID].Data)
                } catch (error) {
                }
                item['value'] = value;
                if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
                    me.updateShowSection(item, value);
                }
                if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
                    me.updateShowSectionForNumbers(item, value)
                }
            })
        });
        this.setState({
            customFormData: customFormDataNew,
        }, () => {
            setTimeout(() => {
                this.updateTotalAnswered();
            }, 1000);
        })
    }

    createIntegrationFormMap() {
        const { IntegrationFormData } = this.state;
        let webHookItemIdMap = {};
        if (IntegrationFormData !== undefined && IntegrationFormData !== null && IntegrationFormData !== '' && IntegrationFormData.items !== undefined && IntegrationFormData.items.length > 0) {
            IntegrationFormData.items.forEach(function (itm) {
                webHookItemIdMap[itm.ID] = itm.additional_data;
            })
        }
        this.setState({
            webHookItemIdMap: webHookItemIdMap
        })
    }

    checkConditions(type) {
        const orgFormData = type === 'ORG' ? this.props.orgFormData : this.props.masterFormData;
        // const { orgFormData } = this.props;
        const { form_type } = this.state;
        console.log(orgFormData, 'orgFormData-checkConditions')

        let showSectionMap = {};
        let showSectionCheckTypeMap = {
            item: [],
            section: []
        };
        let showSectionResponseMatch = {};
        let itemIdsForSection = {};
        let showDependentItems = {}
        var sectionName = form_type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = form_type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
        if (orgFormData) {
            orgFormData[sectionName].forEach(function (section, k) {
                if (section[sectionItemName].length > 0) {
                    section[sectionItemName].forEach(function (item) {
                        try {
                            if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
                                var options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
                                showSectionResponseMatch[item.ID] = {};
                                itemIdsForSection[section.ID] = item.ID;
                                options.forEach(function (op) {
                                    if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
                                        if (showDependentItems[item.ID] === undefined) {
                                            showDependentItems[item.ID] = []
                                        }

                                        if (op.condition.type === 'item') {
                                            showSectionMap[op.condition.value] = false;
                                            showSectionCheckTypeMap.item.push(op.condition.value);
                                            showSectionResponseMatch[item.ID][op.value] = op.condition;

                                            if (showDependentItems[item.ID][op.value] === undefined) {
                                                showDependentItems[item.ID][op.value] = [];
                                                showDependentItems[item.ID][op.value].push(op.condition.value);
                                            } else {
                                                showDependentItems[item.ID][op.value].push(op.condition.value);
                                            }
                                        } else {
                                            showSectionMap[op.condition.value] = false;
                                            showSectionResponseMatch[item.ID][op.value] = op.condition;
                                            if (showDependentItems[item.ID][op.value] === undefined) {
                                                showDependentItems[item.ID][op.value] = [];
                                                showDependentItems[item.ID][op.value].push(op.condition.value);
                                            } else {
                                                showDependentItems[item.ID][op.value].push(op.condition.value);
                                            }
                                        }
                                    }
                                })
                            }
                            if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
                                var num_options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
                                showSectionResponseMatch[item.ID] = {};
                                itemIdsForSection[section.ID] = item.ID;
                                num_options.forEach(function (op) {
                                    if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
                                        showSectionMap[op.condition.value] = false;
                                        showSectionResponseMatch[item.ID][op.value] = op.condition;
                                    }
                                })
                            }
                        } catch (error) {
                        }
                    })
                }
            })
        }

        let totalQuestions = 0;
        if (orgFormData) {
            orgFormData[sectionName].forEach(function (section, k) {
                if (section[sectionItemName] !== null && section[sectionItemName].length > 0) {
                    section[sectionItemName].forEach(function (itm) {
                        if (showSectionMap[itm.ID] !== undefined && showSectionMap[itm.ID] === false) {
                            return;
                        } else if (showSectionMap[itm.SectionID] !== undefined && showSectionMap[itm.SectionID] === false) {
                            return;
                        }
                        if (itm.Type !== 17 && itm.Type !== 21 && itm.Type !== 22) {
                            totalQuestions++;
                        }
                    })
                }
            })
        }

        this.setState({
            customFormTitle: orgFormData?.FormTitle,
            customFormDescription: orgFormData?.FormDescription,
            customFormData: orgFormData,
            showSectionMap: showSectionMap,
            showSectionResponseMatch: showSectionResponseMatch,
            itemIdsForSection: itemIdsForSection,
            isShowLoader: false,
            totalQuestions: totalQuestions,
            showDependentItems: showDependentItems,
            showSectionCheckTypeMap: showSectionCheckTypeMap
        }, () => {
            this.checkResponses();
        })
    }

    updateShowSection(item, response) {
        var showSectionMap = this.state.showSectionMap;
        var showSectionResponseMatch = this.state.showSectionResponseMatch;
        const { showDependentItems } = this.state;
        if (item.Type === 19 && response.indexOf('Other-') !== -1) {
            try {
                var options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
                if (options !== undefined && options.length > 0) {
                    let otherOption = options.filter(o => o.isOtherOption !== undefined && o.isOtherOption === true)[0];
                    if (otherOption !== undefined && otherOption !== null && otherOption !== -1 && otherOption !== '') {
                        response = String(otherOption?.value)
                    }
                }
            } catch (error) {
            }
        }


        const { itemIdsForSection } = this.state;
        var me = this;
        if (showSectionResponseMatch[item.ID] !== undefined) {
            var datas = showSectionResponseMatch[item.ID];

            let selectedId = null;
            let updateSecId = '';
            Object.keys(datas).forEach(function (okey) {
                var data = datas[okey];
                if (item.Type === 4) {
                    okey = parseInt(okey)
                }
                if (okey === response) {
                    showSectionMap[data.value] = true;
                    updateSecId = data.value;
                    selectedId = data.value;
                } else {
                    showSectionMap[data.value] = false;
                    var clearResponseArr = [];
                    var itemId = itemIdsForSection[data.value];

                    // console.log(itemIdsForSection, 'itemIdsForSection')
                    // console.log(showSectionResponseMatch, 'showSectionResponseMatch')
                    // console.log(showDependentItems, 'showDependentItems')


                    // console.log(itemId, 'itemId')

                    clearResponseArr.push(data.value);
                    if (showSectionResponseMatch[itemId] !== undefined) {
                        var hideSectionOptions = showSectionResponseMatch[itemId];
                        Object.keys(hideSectionOptions).forEach(function (jkey) {
                            var opData = hideSectionOptions[jkey];
                            if (selectedId === opData.value) {
                                return;
                            }
                            showSectionMap[opData.value] = false;
                            clearResponseArr.push(opData.value);
                        });
                    }
                    me.clearResponseForSections(clearResponseArr);

                    if (showDependentItems[item.ID] !== undefined && showDependentItems[item.ID][okey] !== undefined) {
                        me.clearResponseForSectionItems(showDependentItems[item.ID][okey])
                        showDependentItems[item.ID][okey].forEach(function (depItm) {
                            if (showSectionResponseMatch[depItm] !== undefined) {
                                var hideSectionOptions = showSectionResponseMatch[depItm];
                                Object.keys(hideSectionOptions).forEach(function (jkey) {
                                    var opData = hideSectionOptions[jkey];
                                    if (selectedId === opData.value) {
                                        return;
                                    }
                                    showSectionMap[opData.value] = false;
                                    clearResponseArr.push(opData.value);
                                });
                            }
                        })
                    }
                }
            })

            if (updateSecId !== '') {
                showSectionMap[updateSecId] = true;
            }
        }
        this.setState({
            showSectionMap: showSectionMap
        })

    }

    updateShowSectionForNumbers(item, response) {
        if (item.Type === 16) {
            if (response !== undefined && response !== null && response !== '') {
                let newValue;
                try {
                    newValue = JSON.parse(response);
                } catch { }
                if (newValue !== undefined && newValue !== null && newValue !== '' && newValue.value !== undefined && newValue.value !== null && newValue.value !== '') {
                    response = newValue.value;
                } else if (response.type !== undefined && response.type !== null && response.type !== '') {
                    response = response.value;
                }
            }
        }
        var showSectionMap = this.state.showSectionMap;
        var showSectionResponseMatch = this.state.showSectionResponseMatch;
        const { showDependentItems } = this.state;
        const { itemIdsForSection } = this.state;
        var me = this;
        if (showSectionResponseMatch[item.ID] !== undefined) {
            try {
                var num_options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
                var datas = showSectionResponseMatch[item.ID];
                var checkMap = {};
                num_options.forEach(function (itm) {
                    checkMap[itm.value] = {
                        ...itm,
                        value: parseFloat(itm.label)
                    }
                })
                let resValue = parseFloat(response)
                let selectedId = null;
                Object.keys(datas).forEach(function (okey) {
                    var data = datas[okey];
                    if (checkMap[okey] !== undefined && checkMap[okey].operation === 1 && checkMap[okey].value === resValue) {
                        showSectionMap[data.value] = true;
                        selectedId = data.value;
                    } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 2 && resValue > checkMap[okey].value) {
                        showSectionMap[data.value] = true;
                        selectedId = data.value;
                    } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 3 && resValue >= checkMap[okey].value) {
                        showSectionMap[data.value] = true;
                        selectedId = data.value;
                    } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 4 && resValue < checkMap[okey].value) {
                        showSectionMap[data.value] = true;
                        selectedId = data.value;
                    } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 5 && resValue <= checkMap[okey].value) {
                        showSectionMap[data.value] = true;
                        selectedId = data.value;
                    } else {
                        showSectionMap[data.value] = false;
                        var clearResponseArr = [];
                        var itemId = itemIdsForSection[data.value];
                        clearResponseArr.push(data.value);
                        if (showSectionResponseMatch[itemId] !== undefined) {
                            var hideSectionOptions = showSectionResponseMatch[itemId];
                            Object.keys(hideSectionOptions).forEach(function (jkey) {
                                var opData = hideSectionOptions[jkey];
                                if (selectedId === opData.value) {
                                    return;
                                }
                                showSectionMap[opData.value] = false;
                                clearResponseArr.push(opData.value);
                            });
                        }
                        me.clearResponseForSections(clearResponseArr);

                        if (showDependentItems[item.ID] !== undefined && showDependentItems[item.ID][okey] !== undefined) {
                            me.clearResponseForSectionItems(showDependentItems[item.ID][okey])
                            showDependentItems[item.ID][okey].forEach(function (depItm) {
                                if (showSectionResponseMatch[depItm] !== undefined) {
                                    var hideSectionOptions = showSectionResponseMatch[depItm];
                                    Object.keys(hideSectionOptions).forEach(function (jkey) {
                                        var opData = hideSectionOptions[jkey];
                                        if (selectedId === opData.value) {
                                            return;
                                        }
                                        showSectionMap[opData.value] = false;
                                        clearResponseArr.push(opData.value);
                                    });
                                }
                            })
                        }
                    }
                })
                if (selectedId !== null) {
                    showSectionMap[selectedId] = true;
                }
            } catch { }

        }

        this.setState({
            showSectionMap: showSectionMap
        })
    }

    clearResponseForSections(sectionArr) {
        let newData = this.state.customFormData;
        var formTypeN = this.state.form_type;

        var sectionName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

        newData[sectionName].forEach((section, sk) => {
            if (sectionArr.indexOf(section.ID) !== -1) {
                var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
                sectionItems.forEach(function (si) {
                    si.response = '';
                    si.value = '';
                })
            }
        });
        this.setState({
            customFormData: newData
        })
    }

    clearResponseForSectionItems(itemArr) {
        let newData = this.state.customFormData;
        var formTypeN = this.state.form_type;
        var sectionName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
        newData[sectionName].forEach((section, sk) => {
            var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
            sectionItems.forEach(function (si) {
                if (itemArr.indexOf(si.ID) !== -1) {
                    si.response = '';
                    si.value = '';
                }
            })
        });
        this.setState({
            customFormData: newData
        })
    }

    setSelectedTeams(team, isUpdate) {
        const { customFormData, form_type, currentSelected } = this.state;
        var sectionName = form_type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = form_type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
        let sectionId = currentSelected.SectionID;
        let itemId = currentSelected.ID;
        if (customFormData !== null && customFormData[sectionName] !== undefined) {
            let selections = customFormData[sectionName];
            var sectionIndex = selections.findIndex(s => s.ID === sectionId);
            var section = selections[sectionIndex];
            var sectionItems = section[sectionItemName];
            var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
            var item = sectionItems[itemIndex];
            if (item.value === undefined || item.value === null || item.value === '') {
                item['value'] = [team];
            } else {
                var datas = item.value;
                var index = datas.findIndex(t => t.ID === team.ID)
                if (index === -1) {
                    datas.push(team);
                } else {
                    datas.splice(index, 1);
                }
                item['value'] = datas;
            }
            this.setState({
                customFormData: customFormData,
                currentSelected: item
            }, () => {
                this.updateTotalAnswered()
            })
        }
    }

    setSelectedUsers(user, isUpdate) {
        const { customFormData, form_type, currentSelected } = this.state;
        var sectionName = form_type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = form_type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

        let sectionId = currentSelected.SectionID;
        let itemId = currentSelected.ID;

        if (customFormData !== null && customFormData[sectionName] !== undefined) {
            let selections = customFormData[sectionName];
            var sectionIndex = selections.findIndex(s => s.ID === sectionId);
            var section = selections[sectionIndex];
            var sectionItems = section[sectionItemName];
            var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
            var item = sectionItems[itemIndex];
            if (item.value === undefined || item.value === null || item.value === '') {
                item['value'] = [user];
            } else {
                var datas = item.value;
                var index = datas.findIndex(t => t.ID === user.ID)
                if (index === -1) {
                    datas.push(user);
                } else {
                    datas.splice(index, 1);
                }
                item['value'] = datas;
            }
            this.setState({
                customFormData: customFormData,
                currentSelected: item
            }, () => {
                this.updateTotalAnswered()
            })
        }
    }

    cleanErrors() {
        let { customFormData, form_type } = this.state;
        var formType = form_type;
        var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
        this.state.customFormData[sectionName].forEach(function (section) {
            var sectionItems = section[sectionItemName];
            sectionItems.forEach(function (item) {
                item['error'] = ''
            })
        })
        this.setState({
            customFormData: customFormData,
        })
    }

    updateTotalAnswered() {
        let { customFormData, showSectionMap, showSectionCheckTypeMap, form_type } = this.state;
        var formType = form_type;
        let totalAnswered = 0;
        let totalQuestions = 0;
        var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
        customFormData[sectionName].forEach(function (section) {
            var sectionItems = section[sectionItemName];
            sectionItems.forEach(function (item) {
                if (item.Type !== 17 && item.Type !== 21 && item.Type !== 22) {
                    if (item.value !== undefined && item.value !== null && item.value !== '') {
                        if (item.Type === 5 || item.Type === 7 || item.Type === 8 || item.Type === 10 || item.Type === 20) {
                            if (item.value.length > 0) {
                                totalAnswered++;
                            }
                        } else if (item.Type === 16) {
                            if (item.value.value !== undefined && item.value.value !== null && item.value.value !== '') {
                                totalAnswered++;
                            }
                        } else if (item.Type === 11) {
                            let itemId = '#section_item' + item.ID + ' .editor-editableField';
                            var bodyText = document.querySelectorAll(itemId)[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
                            if (String(item.value).trim().length === 0 || String(bodyText).trim().length === 0 || String(bodyText).indexOf('Type your message here') !== -1) {
                                return;
                            } else {
                                totalAnswered++;
                            }
                        } else {
                            totalAnswered++;
                        }
                    }
                }

                if (showSectionMap[item.ID] !== undefined && showSectionMap[item.ID] === false && showSectionCheckTypeMap.item.indexOf(item.ID) !== -1) {
                    return;
                } else if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                    return;
                }
                totalQuestions++;
            })
        })
        this.setState({
            totalAnswered: totalAnswered,
            totalQuestions: totalQuestions
        })
    }

    submitResponse(type) {
        let { customFormData, showSectionMap, showSectionCheckTypeMap, evaluation_id, component_id, email_address } = this.state;

        this.setState({
            formError: '',
            email_address_error: '',
            saveType: type
        })

        if (email_address === '' && email_address.length <= 0) {
            this.setState({
                email_address_error: 'Please enter email address.'
            })
            document.getElementById('section_item_email_address').scrollIntoView({
                block: 'center',
                inline: 'center',
                behavior: 'smooth'
            });
            return;
        }

        if (email_address !== '' && email_address !== null) {
            if (Validator.validateEmail(email_address) === false) {
                this.setState({
                    email_address_error: 'Please enter valid email.'
                })
                document.getElementById('section_item_email_address').scrollIntoView({
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth'
                });
                return;
            }
        }

        const formId = customFormData?.ID;

        var resData = [];
        var formType = this.state.form_type;
        var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

        var isRequiredFieldEmpty = false;
        var isValidationError = false;
        var validationMsg = '';

        var requireFieldNames = [];
        let firstErrorId = null;

        this.state.customFormData[sectionName].forEach(function (section) {
            var sectionItems = section[sectionItemName];
            sectionItems.forEach(function (item) {
                item['error'] = '';

                if (showSectionMap[item.ID] !== undefined && showSectionMap[item.ID] === false && showSectionCheckTypeMap.item.indexOf(item.ID) !== -1) {
                    return '';
                } else if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                    return '';
                }

                if (item.IsRequired && (item.value === undefined || item.value === "" || item.value === null)) {
                    isRequiredFieldEmpty = true;
                    requireFieldNames.push(item.ItemName);
                    item.error = 'Please enter/select option.';
                    if (firstErrorId === null) {
                        firstErrorId = item.ID;
                    }
                }

                if (item.IsRequired && item.Type === 12 && item.value !== '' && item.value !== null) {
                    if (Validator.validateEmail(item.value) === false) {
                        isValidationError = true;
                        validationMsg = 'Please enter valid email.';
                        item.error = 'Please enter valid email.';
                        if (firstErrorId === null) {
                            firstErrorId = item.ID;
                        }
                    }
                }

                if (item.IsRequired && item.Type === 14 && item.value !== '' && item.value !== null) {
                    if (Validator.validateURL(item.value) === false) {
                        isValidationError = true;
                        validationMsg = 'Please enter valid URL.';
                        item.error = 'Please enter valid URL.';
                        if (firstErrorId === null) {
                            firstErrorId = item.ID;
                        }
                    }
                }

                if ((item.value === undefined || item.value === "" || item.value === null)) {
                    return;
                }

                let finalValue;
                if (item.Type === 4 || item.Type === 18) {
                    finalValue = JSON.stringify(String(item.value))
                } else if (item.Type === 5) {
                    // let options = item.value.map(o=>String(o))
                    finalValue = JSON.stringify(item.value)
                } else {
                    finalValue = JSON.stringify(item.value)
                }

                var obj = {
                    itemId: item.ID,
                    value: finalValue
                }
                resData.push(obj);
            })
        });

        if (isRequiredFieldEmpty) {
            var nMsg = '';
            if (requireFieldNames.length === 1) {
                nMsg += ' is required field.';
            } else {
                nMsg += ' are required fields.';
            }
            var str = requireFieldNames.join(', ');
            str = str + nMsg;
            this.setState({
                formError: str
            })
            if (firstErrorId !== null) {
                let itemId = 'section_item' + firstErrorId;
                document.getElementById(itemId).scrollIntoView({
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth'
                });
            }
        } else if (isValidationError) {
            this.setState({
                formError: validationMsg
            })
            if (firstErrorId !== null) {
                let itemId = 'section_item' + firstErrorId;
                document.getElementById(itemId).scrollIntoView({
                    // behavior: 'auto',
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth'
                });
            }
        } else {
            this.setState({
                nextClickLoader: type === 'NEXT' ? true : false,
                nextClickLoaderSave: type === 'SAVE' ? true : false
            })

            console.log(resData, 'resData')
            this.props.addCustomFormResponse(evaluation_id, formId, {
                form_type: formType,
                data: resData,
                componentId: parseInt(component_id),
                org_id: parseInt(this.state.org_id),
                email: email_address
            })
        }
    }

    deleteFormColloborator() {
        this.props.hideSearchUsersPoperDialog();
    }

    addFormColloborator() {
        this.props.hideSearchUsersPoperDialog();
    }

    render() {
        const classes = this.props.classes;
        let { customFormTitle, customFormDescription, customFormData, isShowLoader, showSectionMap,
            showSectionCheckTypeMap, isFormEditable, totalQuestions,
            totalAnswered, formColloborator, email_address, email_address_error } = this.state;

        var sectionName = this.state.form_type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = this.state.form_type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

        if (isShowLoader) {
            return <div className={classes.loader}><CircularProgress /></div>
        }

        let zIndexForItems = 0;


        return <>
            <div className={classnames(classes.topRoot)}>
                <div className={classes.root}>
                    <div classes={classes.dialogContent} >
                        <div className={classes.customForm}>
                            <div className={classes.titleFlex}>
                                <div className={classes.titlePart}>
                                    <Typography variant={"h4"} className={classes.stepHead}>{customFormTitle}</Typography>
                                </div>
                                <div className={classes.progressPart}>
                                    <Typography variant={"h4"} className={classes.stepHead2}>{totalAnswered} / {totalQuestions} Answered</Typography>
                                </div>
                            </div>
                            <span className={classes.stepSubHead}>{customFormDescription}</span>
                            <div className={classes.topHeadColor}></div>
                        </div>
                        {false && <div className={classes.alert}>
                            {this.state.formError.length > 0 && <Alert variant="filled" severity="error">{this.state.formError}</Alert>}
                        </div>}

                        <div className={classes.section} key={'section_email_address'}>
                            <div className={classes.sectionItem} key={'section_email_address_section'} id={'section_item_email_address'}>
                                <CustomReply
                                    additional_data={[]}
                                    type={12}
                                    title={'Email'}
                                    description={'Please enter your email address.'}
                                    isRequired={true}
                                    key={'emaill_sectionItem'}
                                    index={1111}
                                    value={email_address}
                                    selectedOption={[]}
                                    evaluationId={this.state.evaluation_id}
                                    orgId={this.state.org_id}
                                    workflowId={0}
                                    isFormEditable={true}
                                    itemId={121212}
                                    sectionId={131313}
                                    item={{}}
                                    error={email_address_error || ''}
                                    isOtherOption={false}
                                    otherOptionData={[]}
                                    webHookItemIdMap={null}
                                    zIndexForItem={0}
                                    decisionItemMap={this.state.isCheckDecisionItems ? this.state.decisionItemMap : []}
                                    onChange={(response, isOther = false) => {
                                        this.setState({
                                            email_address: response,
                                            email_address_error: ''
                                        })
                                        this.cleanErrors();
                                    }}
                                />
                            </div>
                        </div>

                        <div className={isFormEditable === false ? '' : ''}>
                            {customFormData !== null && customFormData[sectionName].map((section, sk) => {
                                var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
                                sectionItems = sectionItems.sort(function (a, b) {
                                    if (a.Seq > b.Seq) {
                                        return 1;
                                    } else if (a.Seq < b.Seq) {
                                        return -1
                                    } else {
                                        return 0;
                                    }
                                })

                                let isHideSection = false;
                                sectionItems.forEach((item, key) => {
                                    if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                                        isHideSection = true;
                                    }
                                })

                                if (sectionItems.length <= 0) {
                                    return null
                                }

                                return <>
                                    {!isHideSection && section?.SectionName !== '' && <div className={classes.sectionBox}>
                                        <Typography variant={"h4"} className={classes.sectionName}>{section?.SectionName}</Typography>
                                        <span className={classes.stepSubHead}>{section?.Description}</span>
                                    </div>}
                                    <div className={classes.section} key={sk}>
                                        {sectionItems.map((item, key) => {
                                            if (showSectionMap[item.ID] !== undefined && showSectionMap[item.ID] === false && showSectionCheckTypeMap?.item.indexOf(item.ID) !== -1) {
                                                return '';
                                            } else if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                                                return '';
                                            }


                                            if (item.Type === 1) {
                                            }
                                            var options = [];
                                            try {
                                                options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
                                            } catch { }

                                            if (item.Type === 17 && (this.props.similarExistingProducts === undefined || this.props.similarExistingProducts === null || Object.keys(this.props.similarExistingProducts).length <= 0)) {
                                                return ''
                                            }

                                            zIndexForItems++;
                                            let newZIndex = ((totalQuestions + 10) - zIndexForItems)


                                            return <><div className={classes.sectionItem} key={item.SectionID + '_section' + key} id={'section_item' + item.ID}>
                                                <CustomReply
                                                    additional_data={options}
                                                    type={item.Type}
                                                    title={item.ItemName}
                                                    description={item.Description}
                                                    isRequired={item?.IsRequired}
                                                    key={item.ID + '_sectionItem'}
                                                    index={item.ID}
                                                    value={(item?.value !== undefined && item?.value !== null) ? item.value : ''}
                                                    selectedOption={[]}
                                                    evaluationId={this.state.evaluation_id}
                                                    orgId={this.state.org_id}
                                                    workflowId={this.props.workflow_id}
                                                    isFormEditable={true}
                                                    itemId={item.ID}
                                                    sectionId={item.SectionID}
                                                    item={item}
                                                    error={item.error || ''}
                                                    isOtherOption={item?.IsOtherOption}
                                                    otherOptionData={item?.OtherOptionData}
                                                    webHookItemIdMap={null}
                                                    zIndexForItem={newZIndex}
                                                    decisionItemMap={this.state.isCheckDecisionItems ? this.state.decisionItemMap : []}
                                                    onChange={(response, isOther = false) => {
                                                        item['value'] = response;
                                                        if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
                                                            this.updateShowSection(item, response);
                                                        }
                                                        if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
                                                            this.updateShowSectionForNumbers(item, response);
                                                        }
                                                        this.cleanErrors();
                                                        this.updateTotalAnswered();
                                                    }}
                                                    showUserAddDialog={(item) => {
                                                        this.setState({
                                                            currentSelected: item,
                                                            isForFormColloborator: false
                                                        }, () => {

                                                        })
                                                        this.props.showSearchUsersPoperDialog()
                                                    }}
                                                    showTeamAddDialog={(data) => {
                                                        this.setState({
                                                            currentSelected: item
                                                        }, () => {
                                                            this.props.showSearchTeamsPoperDialog()
                                                        })
                                                    }}
                                                // similarExistingProducts={this.props.similarExistingProducts}
                                                // similarExistingProductProgress={this.props.similarExistingProductProgress}
                                                />
                                            </div>
                                            </>
                                        })}

                                    </div>
                                </>
                            })}
                        </div>
                        <BottomActions
                            user={this.props.user}
                            isCreatorOrAdmin={this.props.isCreatorOrAdmin}
                            adminForward={this.props.adminForward}
                            submitResponse={this.submitResponse}
                            nextClickLoaderSave={this.state.nextClickLoaderSave}
                            nextClickLoader={this.state.nextClickLoader}
                            isFormEditable={isFormEditable}
                        />
                    </div>
                </div>

                {this.props.isOpenTeamDialog && <SearchTeamsPoper
                    selectedTeams={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
                    onAdd={(team) => {
                        this.setSelectedTeams({
                            id: team.ID,
                            ID: team.ID,
                            value: team.ID,
                            label: team.Name,
                            Name: team.Name,
                            Email: team.Email,
                        }, true)
                    }}
                    onRemove={(team) => {
                        this.setSelectedTeams({
                            id: team.ID,
                            ID: team.ID,
                            value: team.ID,
                            label: team.Name,
                            Name: team.Name,
                            Email: team.Email,
                        }, true)
                    }}
                    onClose={() => {
                        this.props.hideSearchTeamsPoperDialog();
                    }}
                />}

                {this.props.isOpenUserDialog && <SearchUserPoper
                    selectedUsers={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
                    onAdd={(user) => {
                        if (this.state.isForFormColloborator) {
                            this.setState({
                                formColloborator: [{
                                    id: user.ID,
                                    ID: user.ID,
                                    value: user.ID,
                                    label: user.Name,
                                    Name: user.Name,
                                    Email: user.Email,
                                }]
                            }, () => {
                                this.addFormColloborator()
                            })
                        } else {
                            this.setSelectedUsers({
                                id: user.ID,
                                ID: user.ID,
                                value: user.ID,
                                label: user.Name,
                                Name: user.Name,
                                Email: user.Email,
                            }, true)
                        }
                    }}
                    onRemove={(user) => {
                        if (this.state.isForFormColloborator) {
                            this.deleteFormColloborator()
                        } else {
                            this.setSelectedUsers({
                                id: user.ID,
                                ID: user.ID,
                                value: user.ID,
                                label: user.Name,
                                Name: user.Name,
                                Email: user.Email,
                            }, true)
                        }
                    }}
                    onClose={() => {
                        this.props.hideSearchUsersPoperDialog();
                    }}
                />}
                <Snackbar />

            </div>
        </>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(CustomForm));