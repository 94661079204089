import { createStyles, withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Drawer
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import classnames from "classnames";
// components
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
//redux 
import { hideRequestSummaryCart } from "redux/evaluation/workflow/action";
import { showSnackBar } from "redux/snackbar/action";
import MessageDialog from "Components/Common/MessageDialog";
import ProjectSummary from "../Evaluation/WorkFlow/Components/Summary/ProjectSummary";

const connectedProps = (state) => ({
  user: state.authUser.user,
  targetContainer: state.evaluationCart.targetContainer,
  isRequestSummaryCartOpen: state.evaluationWorkflow.isRequestSummaryCartOpen,
  isRequestSummaryCartData: state.evaluationWorkflow.isRequestSummaryCartData,
  evaluation: state.evaluationMetaData.evaluation,
});

const connectionActions = {
  hideRequestSummaryCart: hideRequestSummaryCart,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);


const styles = (theme) =>
  createStyles({
    drawer: {
      width: "90%",
      flexShrink: 0,
      transition: "width 300ms",
      background: "#F7F7F7",
    },
    drawerPaper: {
      width: "90%",
      transition: "width 300ms",
      background: "#fff",
    },

    cartContainer: {
      padding: '0px 20px',
      '&.close': {
        opacity: 0
      }
    },
    title: {
      fontSize: theme.spacing(2.4),
      // textAlign: "center",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    title2: {
      fontSize: theme.spacing(2.4),
      marginTop: theme.spacing(1),
    },
    closeIcon: {
      width: '36px',
      marginLeft: '92%',
      padding: '8px',
    },
    progressIndicator: {
      margin: theme.spacing(2),
      textAlign: "center",
    },
    headSection: {
      justifyContent: "space-between",
      minHeight: 40,
    },
    cartItem: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #EEEEEE",
      borderRadius: 10,
      margin: `${theme.spacing(2)} 0`,
      justifyContent: "space-between",
      paddingRight: '10px'
    },
    productNameContainer: {
      flex: 0.95,
    },
    productName: {
      flex: 0.95,
    },
    productImage: {
      width: "100%",
      height: "auto",
      marginRight: theme.spacing(2),
    },
    productImageContainer: {
      width: 60,
      height: 60,
      padding: 13,
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      // boxShadow: "0px 1px 6px #0000001A",
      borderRadius: 10,
    },
    addProductImageContainer: {
      width: 60,
      height: 60,
      padding: 13,
    },
    productRemove: {
      marginTop: theme.spacing(1),
      cursor: "pointer",
    },
    counter: {
      padding: theme.spacing(1),
      fontSize: theme.spacing(2.2),
      opacity: 0.5,
      marginTop: theme.spacing(1),
    },
    productNameTitle: {
      fontSize: theme.spacing(2),
    },
    vendotDetail: {
      cursor: "pointer",
      color: "#4175DF",
      fontSize: theme.spacing(2),
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    productsWrap: {
      paddingBottom: theme.spacing(6),
    },
    editWorkflow: {
      marginLeft: '10px',
    },
    projSummaryMain: {
      // width:'90%',
      // marginLeft:'10%',
      // background:'#fff',
      // paddingTop: theme.spacing(2),

    }
  });


class RequestSummaryCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteProductId: null,
      deleteEvaluationId: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isRequestSummaryCartOpen === true && prevProps.isRequestSummaryCartOpen === false) {
      if (this.props.isRequestSummaryCartData) {
        this.props.fetchRequestDetails(this.props.isRequestSummaryCartData);
      } else {
        this.props.showSnackBar("Something went wrong", "error", 3000);
        this.props.hideRequestSummaryCart();
      }
    }
    if (this.props.requestDetailsProgress === false && prevProps.requestDetailsProgress === true) {
      if (this.props.requestDetailsError !== null) {
        // this.props.showSnackBar("Failed to fetch details", "error", 3000);
      }
    }

    if (this.props.updateProgress === false && prevProps.updateProgress === true) {
      this.props.showSnackBar("Project closed successfully.", "success", 3000)
      window.location.reload()
    }
  }

  removeProduct = (productId, evaluationId) => {
    this.setState({
      deleteProductId: productId,
      deleteEvaluationId: evaluationId,
    }, () => {
      this.props.showMessageDialog();
    })
  }

  callBackReq = () => {
    this.props.updateDetails(this.props.evaluation?.ID, {
      status: 5
    })
  };

  closeProject = () => {
    this.props.showMessageDialog();
  }

  render() {
    const { classes } = this.props;
    if (!this.props.isRequestSummaryCartOpen) {
      return null
    }

    let items = [];

    if (this.props.evaluateView && this.props.evaluation && this.props.evaluation.Products) {
      this.props.evaluation.Products.forEach((product) => {
        if (product.ID === this.props.evaluateViewId) {
          items.push(product);
        }
      });
    } else if (this.props.evaluation) {
      items = this.props.evaluation.Products;
    }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to close project?",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <>
        <Drawer
          className={
            classnames(classes.appDrawer, classes.drawer)
          }
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="right"
          hideBackdrop={false}
        >
          <div className={classes.projSummaryMain}>
            <div style={{ textAlign: 'right', width: '90%', margin: '0 auto' }}>
              <IconButton
                className={classes.closeIcon}
                onClick={this.props.hideRequestSummaryCart}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>

            <div className={classnames(classes.cartContainer)}>
              <ProjectSummary
                isFromCart={true}
                evaluationId={this.props.evaluation?.ID}
              />
            </div>
            {<MessageDialog messageData={messageData} callBack={this.callBackReq} />}
          </div>
        </Drawer>
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(RequestSummaryCart));
