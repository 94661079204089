import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import classnames from "classnames";

import {
  Typography, CircularProgress
} from '@material-ui/core'

import { withRouter } from 'react-router-dom';
import { addWorkflowComponentUserResponse, addCustomFormResponse, fetchWorkflowFormResponse } from "redux/workflow/action";
import { searchUsers } from "redux/usersSettings/action";
import { fetchOrgGroup } from "redux/org/group/action";
import { fetchWorkFlowCustomForm } from "redux/evaluation/workflow/action";
import CustomReplySummary from 'Components/Common/FormReply/CustomReplySummary';
import CustomReply from 'Components/Common/FormReply/CustomReply';
import * as Validator from "util/Validation";
import { fetchOrgFormUsingId } from "redux/forms/org/action";
import { fetchMasterFormUsingId } from "redux/forms/master/action";
import { fetchSimilarExistingProducts } from "redux/product/orgProduct/action";

import { fetchEvaluationMetaDataForSummary, fetchFormDecisionItems } from "redux/workflow/summary/action";
import SqureButton from 'Components/Common/SqureButton';

import { showSearchTeamsPoperDialog, hideSearchTeamsPoperDialog, showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import SearchTeamsPoper from "Components/Common/TeamsAndUser/SearchTeamsPoper";
import SearchUserPoper from "Components/Common/TeamsAndUser/SearchUserPoper";
import { getComponentDetails } from "redux/workflow/action";
import BottomActions from "./BottomActions";
import { showSnackBar } from "redux/snackbar/action";
import MessageDialog from "Components/Common/MessageDialog";
import { showMessageDialog, hideMessageDialog, } from "redux/applications/action";

// import ProductSelectionDialog from "../../../../../Common/ProductSelectionDialog";
// import { showEvaluationProductSelectionDialog } from "redux/evaluation/metadata/action";
// import { isEnabledEvaluate } from "util/Common";

import { showFormDataCopyDialog } from 'redux/workflow/summary/action';
import FormDataCopyDialog from '../../../CopyData/FormDataCopyDialog';
import { checkWorkflowFinished } from "util/Common";

const connectedProps = (state) => ({
  OrgID: state.authUser.user.OrgID,
  user: state.authUser.user,
  orgUser: state.orgUser,
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  componentUserResponseError: state.workflow.componentUserResponseError,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  fetchCustomFormProgress: state.evaluationWorkflow.fetchCustomFormProgress,
  workflowCustomForm: state.evaluationWorkflow.workflowCustomForm,
  addCustomComponentUserResponseProgress: state.workflow.addCustomComponentUserResponseProgress,
  componentUserResponse: state.workflow.componentUserResponse,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
  orgFormData: state.orgForms.orgFormData,
  fetchOrgFormsByIdProgress: state.orgForms.fetchOrgFormsByIdProgress,
  fetchMasterFormsByIdProgress: state.masterForms.fetchMasterFormsByIdProgress,
  masterFormData: state.masterForms.masterFormData,
  fetchWokflowFormResponseProgress: state.workflow.fetchWokflowFormResponseProgress,
  workflowFormResponse: state.workflow.workflowFormResponse,
  similarExistingProductProgress: state.orgProduct.similarExistingProductProgress,
  similarExistingProducts: state.orgProduct.similarExistingProducts,
  evaluation: state.workflowSummary.evaluation,
  fetchProgress: state.workflowSummary.fetchProgress,
  userTimeline: state.workflow.userTimeline,
  fetchWokflowUserTimelineProgress: state.workflow.fetchWokflowUserTimelineProgress,
  isOpenTeamDialog: state.dialogs.isShowSearchTeamPoperDialog,
  isOpenUserDialog: state.dialogs.isShowSearchUserPoperDialog,
  evComponentData: state.workflow.evComponentData,
  fetchevComponentDataProgress: state.workflow.fetchevComponentDataProgress,
  fetchevComponentDataError: state.workflow.fetchevComponentDataError,
  fetchDecisionItemsProgress: state.workflowSummary.fetchDecisionItemsProgress,
  fetchDecisionItemsError: state.workflowSummary.fetchDecisionItemsError,
  decisionItems: state.workflowSummary.decisionItems,
  isShowFormDataCopyDialog: state.workflowSummary.isShowFormDataCopyDialog,
  userRole: state.authUser.user?.Role,
});

const connectionActions = {
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  fetchWorkFlowCustomForm: fetchWorkFlowCustomForm,
  addCustomFormResponse: addCustomFormResponse,
  fetchUsers: searchUsers,
  fetchOrgGroup: fetchOrgGroup,
  fetchOrgFormUsingId: fetchOrgFormUsingId,
  fetchMasterFormUsingId: fetchMasterFormUsingId,
  fetchWorkflowFormResponse: fetchWorkflowFormResponse,
  fetchSimilarExistingProducts: fetchSimilarExistingProducts,
  fetchEvaluationMetaDataForSummary: fetchEvaluationMetaDataForSummary,
  showSearchTeamsPoperDialog: showSearchTeamsPoperDialog,
  hideSearchTeamsPoperDialog: hideSearchTeamsPoperDialog,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
  getComponentDetails: getComponentDetails,
  showSnackBar: showSnackBar,
  fetchFormDecisionItems: fetchFormDecisionItems,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  // showEvaluationProductSelectionDialog: showEvaluationProductSelectionDialog,
  showFormDataCopyDialog: showFormDataCopyDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  topRoot: {
    height: 'max-content',
    // overflow: 'scroll',
  },
  root: {
    // background: '#fff',
    padding: 20,
    margin: '0px auto',
    // paddingBottom: 100
  },
  actions: {
    textAlign: "center",
    marginTop: theme.spacing(2)
  },
  actionBtn: {
    minWidth: '200px'
  },
  actionBtnDisabled: {
    minWidth: '200px',
  },
  dialogContent: {
    margin: 'auto',
    marginBottom: 20,
    "& [class*=MuiAvatar-root]": {
      width: 30,
      height: 30,
      fontSize: 14
    },
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  stepHead: {
    color: '#282D30',
    fontSize: 18,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  sectionName: {
    color: '#282D30',
    fontSize: 18,
    margin: 0,
    fontWeight: 500,
  },
  stepSubHead: {
    color: '#4B4B4B',
    fontSize: 12,
    margin: 0,
    paddingBottom: 20
  },
  customForm: {
    marginTop: 20,
    borderRadius: 8,
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    marginBottom: 15,
    padding: 20,
  },
  footer: {
    textAlign: 'right'
  },
  section: {
    margin: 0,
    marginBottom: 30
    // border: '1px solid #f1f1f1',
    // padding: 20
  },
  sectionItem: {
    background: '#fff',
    position: 'relative',
    marginBottom: 15,
  },
  sectionBox: {
    background: '#fff',
    position: 'relative',
    // marginBottom: 15,
  },
  sectionTitle: {
    color: '#282D30',
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    marginBottom: 10
  },
  loader: {
    textAlign: "center",
    padding: theme.spacing(10, 0)
  },
  alert: {
    padding: '10px'
  },
  topHeadColor: {
    background: '#1f73b7',
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    height: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%'
  },
  disableForm: {
    pointerEvents: 'none'
  }
});

class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customFormTitle: '',
      customFormDescription: '',
      customFormData: [],
      isShowLoader: true,
      formConfigDetails: {},
      formError: '',
      showSectionMap: {},
      showSectionResponseMatch: {},
      itemIdsForSection: {},
      saveType: 'NEXT',
      isFormEditable: false,
      componentStatus: null,
      nextComponent: null,
      nextClickLoaderSave: false,
      nextClickLoader: false,
      currentSelected: null,
      IntegrationFormData: null,
      webHookItemIdMap: {},
      isEdit: false,
      decisionItemMap: {},
      isActiveDialog: false,
      totalQuestions: 0,
      totalAnswered: 0,
      isCheckDecisionItems: false,
      showDependentItems: {},
      showSectionCheckTypeMap: {}

    }
    this.avatarName = this.avatarName.bind(this);
    this.submitResponse = this.submitResponse.bind(this);
    this.setSelectedTeams = this.setSelectedTeams.bind(this);
    this.setSelectedUsers = this.setSelectedUsers.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this)
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  componentDidMount() {
    // const isCheckDecisionItems = window.localStorage.getItem("isCheckDecisionItems");
    // if(isCheckDecisionItems !== undefined && isCheckDecisionItems !== null && isCheckDecisionItems !== ''){
    //     this.setState({
    //       isCheckDecisionItems : isCheckDecisionItems === 'false' ? false : true
    //     })
    // }

    this.props.getComponentDetails(this.props.workflow_id, this.props.componentId, this.props.evaluation_id);
    // this.checkWorkflowConfiguration();
    this.props.fetchUsers(0, 30, "");
    this.props.fetchOrgGroup(0, 30, "");
    if (this.props.evaluation !== null) {
      this.props.fetchSimilarExistingProducts(this.props.evaluation.Products.map((p) => {
        return p.ID
      }))
    }
    this.props.fetchEvaluationMetaDataForSummary(this.props.evaluation_id);
  }

  componentDidUpdate(prevProps) {

    if (!this.props.fetchProgress && prevProps.fetchProgress) {
      this.checkWorkflowConfiguration();
    }

    if (this.props.addCustomComponentUserResponseProgress === false && prevProps.addCustomComponentUserResponseProgress === true) {
      if (this.props.componentUserResponseError === null) {
        this.setState({
          nextClickLoaderSave: false,
          isEdit: false
        }, () => {
          if (this.props.componentUserResponse.refreshNeeded) {
            let redirectPath = `/app/evaluate/${this.props.evaluation_id}/workflow/${this.props.workflow_id}/component/${this.props.componentId}`;
            this.props.history.push(redirectPath);
          }
        })
        this.props.showSnackBar("Form response updated successfully.", "success", 3000)
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (this.props.fetchDecisionItemsProgress === false && prevProps.fetchDecisionItemsProgress === true) {
      if (this.props.fetchDecisionItemsError === null) {
        // console.log(this.props.decisionItems, 'decisionItems')
        if (this.props.decisionItems !== null && this.props.decisionItems.length > 0) {
          let decisionItemMap = {};
          this.props.decisionItems.forEach(function (di) {
            if (decisionItemMap[di.SectionID] === undefined) {
              decisionItemMap[di.SectionID] = [];
              decisionItemMap[di.SectionID].push(di.ID)
            } else {
              decisionItemMap[di.SectionID].push(di.ID)
            }
          })
          this.setState({
            decisionItemMap: decisionItemMap
          })
        }
      }
    }

    if (this.props.workflowComponentsFormsInProg === false && prevProps.workflowComponentsFormsInProg === true) {
      if (this.props.isWorkFlow) {
        this.checkWorkflowConfiguration();
      }
    }

    if (this.props.fetchOrgFormsByIdProgress === false && prevProps.fetchOrgFormsByIdProgress === true) {
      this.checkConditions('ORG');
    }

    if (this.props.fetchMasterFormsByIdProgress === false && prevProps.fetchMasterFormsByIdProgress === true) {
      this.checkConditions('MASTER');
    }

    if (this.props.fetchWokflowFormResponseProgress === false && prevProps.fetchWokflowFormResponseProgress === true) {
      this.checkResponses();
    }

    if (this.props.fetchWokflowUserTimelineProgress === false && prevProps.fetchWokflowUserTimelineProgress === true) {
      this.checkForStatus();
    }


    if (this.props.fetchevComponentDataProgress === false && prevProps.fetchevComponentDataProgress === true) {
      if (this.props.fetchevComponentDataError === null) {
        this.checkWorkflowConfiguration()
      }
    }
  }

  checkForStatus() {
    const { userTimeline } = this.props;
    this.setState({
      isFormEditable: true,
    })
    if (userTimeline !== null && userTimeline !== undefined) {
      const workflowComId = parseInt(this.props.workflowComponentId);
      var componentStatus = userTimeline.filter(o => o.ComponentId === workflowComId)[0]
      let componentIndex = userTimeline.findIndex(o => o.ComponentId === workflowComId);

      let nextComponent;
      if (userTimeline[componentIndex++] !== undefined) {
        nextComponent = userTimeline[componentIndex++]
      }


      if (componentStatus !== undefined && componentStatus !== null && componentStatus.Status === 2) {
        this.setState({
          isFormEditable: false,
          componentStatus: componentStatus,
          nextComponent: nextComponent
        })
      }
    } else {
      this.setState({
        isFormEditable: true,
      })
    }
  }

  checkResponses() {
    const { workflowFormResponse } = this.props;
    const { customFormData, formConfigDetails } = this.state;
    var me = this;
    if (customFormData === null || customFormData.length <= 0 || workflowFormResponse === null || workflowFormResponse.length <= 0) {
      return;
    }

    var responseMap = {};
    workflowFormResponse.forEach(function (item) {
      responseMap[item.ItemId] = item;
    });

    var formType = formConfigDetails?.type;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    var customFormDataNew = this.state.customFormData;
    customFormDataNew[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        if (responseMap[item.ID] === undefined) {
          return;
        }
        var value = '';
        try {
          value = JSON.parse(responseMap[item.ID].Data)
        } catch (error) {
        }
        item['value'] = value;
        if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
          me.updateShowSection(item, value);
        }
        if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
          me.updateShowSectionForNumbers(item, value)
        }
      })
    });
    this.setState({
      customFormData: customFormDataNew
    })
  }

  checkWorkflowConfiguration() {
    const { evComponentData, evaluation } = this.props;
    if (evaluation) {
      let configurations = evComponentData?.OrgWorkflowComponentConfigurations[0]
      if (configurations && Validator.isJson(configurations.Value)) {
        let customFormValue = JSON.parse(configurations.Value)

        if (customFormValue?.type === "MASTER") {
          this.props.fetchMasterFormUsingId(customFormValue?.id, this.props.evaluation?.ID);
        } else {
          this.props.fetchOrgFormUsingId(customFormValue?.id, this.props.evaluation?.ID);
          this.props.fetchFormDecisionItems(this.props.evaluation_id, customFormValue?.id)
        }
        this.props.fetchWorkflowFormResponse(this.props.evaluation_id, customFormValue?.id, customFormValue?.type, this.props.workflowComponentId)
        this.setState({
          formConfigDetails: customFormValue,
        })
      }
    }
  }

  createIntegrationFormMap() {
    const { IntegrationFormData } = this.state;
    let webHookItemIdMap = {};
    if (IntegrationFormData !== undefined && IntegrationFormData !== null && IntegrationFormData !== '' && IntegrationFormData.items !== undefined && IntegrationFormData.items.length > 0) {
      IntegrationFormData.items.forEach(function (itm) {
        webHookItemIdMap[itm.ID] = itm.additional_data;
      })
    }
    this.setState({
      webHookItemIdMap: webHookItemIdMap
    })
  }

  checkConditions(type) {
    const orgFormData = type === 'ORG' ? this.props.orgFormData : this.props.masterFormData;
    // const { orgFormData } = this.props;
    const { formConfigDetails } = this.state;

    let showSectionMap = {};
    let showSectionCheckTypeMap = {
      item: [],
      section: []
    };

    let showSectionResponseMatch = {};
    let itemIdsForSection = {};
    var formType = formConfigDetails?.type;
    let showDependentItems = {}
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    if (orgFormData) {
      orgFormData[sectionName].forEach(function (section, k) {
        if (section[sectionItemName].length > 0) {
          section[sectionItemName].forEach(function (item) {
            try {
              if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
                var options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
                showSectionResponseMatch[item.ID] = {};
                itemIdsForSection[section.ID] = item.ID;
                options.forEach(function (op) {
                  if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
                    if (showDependentItems[item.ID] === undefined) {
                      showDependentItems[item.ID] = []
                    }

                    if (op.condition.type === 'item') {
                      showSectionMap[op.condition.value] = false;
                      showSectionCheckTypeMap.item.push(op.condition.value);

                      showSectionResponseMatch[item.ID][op.value] = op.condition;

                      if (showDependentItems[item.ID][op.value] === undefined) {
                        showDependentItems[item.ID][op.value] = [];
                        showDependentItems[item.ID][op.value].push(op.condition.value);
                      } else {
                        showDependentItems[item.ID][op.value].push(op.condition.value);
                      }
                    } else {
                      showSectionMap[op.condition.value] = false;
                      showSectionResponseMatch[item.ID][op.value] = op.condition;
                      if (showDependentItems[item.ID][op.value] === undefined) {
                        showDependentItems[item.ID][op.value] = [];
                        showDependentItems[item.ID][op.value].push(op.condition.value);
                      } else {
                        showDependentItems[item.ID][op.value].push(op.condition.value);
                      }
                    }
                  }
                })
              }
              if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
                var num_options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
                showSectionResponseMatch[item.ID] = {};
                itemIdsForSection[section.ID] = item.ID;
                num_options.forEach(function (op) {
                  if (op.condition !== undefined && op.condition !== null && op.condition !== '') {
                    showSectionMap[op.condition.value] = false;
                    showSectionResponseMatch[item.ID][op.value] = op.condition;
                  }
                })
              }
            } catch (error) {
            }
            // var item = section[sectionItemName][0];

          })
        }
      })
    }

    let totalQuestions = 0;
    if (orgFormData) {
      orgFormData[sectionName].forEach(function (section, k) {
        if (section[sectionItemName] !== null && section[sectionItemName].length > 0) {
          section[sectionItemName].forEach(function (itm) {
            if (itm.Type !== 17 && itm.Type !== 21 && itm.Type !== 22) {
              totalQuestions++;
            }
          })
        }
      })
    }

    this.setState({
      customFormTitle: orgFormData?.FormTitle,
      customFormDescription: orgFormData?.FormDescription,
      customFormData: orgFormData,
      showSectionMap: showSectionMap,
      showSectionResponseMatch: showSectionResponseMatch,
      itemIdsForSection: itemIdsForSection,
      isShowLoader: false,
      totalQuestions: totalQuestions,
      showDependentItems: showDependentItems,
      showSectionCheckTypeMap: showSectionCheckTypeMap

    }, () => {
      this.checkResponses();
    })
  }

  updateShowSection(item, response) {
    var showSectionMap = this.state.showSectionMap;
    var showSectionResponseMatch = this.state.showSectionResponseMatch;
    const { showDependentItems } = this.state;
    if (item.Type === 19 && response.indexOf('Other-') !== -1) {
      try {
        var options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
        if (options !== undefined && options.length > 0) {
          let otherOption = options.filter(o => o.isOtherOption !== undefined && o.isOtherOption === true)[0];
          if (otherOption !== undefined && otherOption !== null && otherOption !== -1 && otherOption !== '') {
            response = String(otherOption?.value)
          }
        }
      } catch (error) {
      }

    }

    const { itemIdsForSection } = this.state;
    var me = this;
    if (showSectionResponseMatch[item.ID] !== undefined) {
      var datas = showSectionResponseMatch[item.ID];

      let selectedId = null;
      let updateSecId = '';
      Object.keys(datas).forEach(function (okey) {
        var data = datas[okey];
        if (item.Type === 4) {
          okey = parseInt(okey)
        }
        if (okey === response) {
          showSectionMap[data.value] = true;
          updateSecId = data.value;
          selectedId = data.value;
        } else {
          showSectionMap[data.value] = false;
          var clearResponseArr = [];
          var itemId = itemIdsForSection[data.value];

          // console.log(itemIdsForSection, 'itemIdsForSection')
          // console.log(showSectionResponseMatch, 'showSectionResponseMatch')
          // console.log(showDependentItems, 'showDependentItems')


          // console.log(itemId, 'itemId')

          clearResponseArr.push(data.value);
          if (showSectionResponseMatch[itemId] !== undefined) {
            var hideSectionOptions = showSectionResponseMatch[itemId];
            Object.keys(hideSectionOptions).forEach(function (jkey) {
              var opData = hideSectionOptions[jkey];
              if (selectedId === opData.value) {
                return;
              }
              showSectionMap[opData.value] = false;
              clearResponseArr.push(opData.value);
            });
          }
          me.clearResponseForSections(clearResponseArr);

          if (showDependentItems[item.ID] !== undefined && showDependentItems[item.ID][okey] !== undefined) {
            me.clearResponseForSectionItems(showDependentItems[item.ID][okey])
            showDependentItems[item.ID][okey].forEach(function (depItm) {
              if (showSectionResponseMatch[depItm] !== undefined) {
                var hideSectionOptions = showSectionResponseMatch[depItm];
                Object.keys(hideSectionOptions).forEach(function (jkey) {
                  var opData = hideSectionOptions[jkey];
                  if (selectedId === opData.value) {
                    return;
                  }
                  showSectionMap[opData.value] = false;
                  clearResponseArr.push(opData.value);
                });
              }
            })
          }
        }
      })

      if (updateSecId !== '') {
        showSectionMap[updateSecId] = true;
      }
    }
    this.setState({
      showSectionMap: showSectionMap
    })

  }

  updateShowSectionForNumbers(item, response) {
    if (item.Type === 16) {
      if (response !== undefined && response !== null && response !== '') {
        let newValue;
        try {
          newValue = JSON.parse(response);
        } catch { }
        if (newValue !== undefined && newValue !== null && newValue !== '' && newValue.value !== undefined && newValue.value !== null && newValue.value !== '') {
          response = newValue.value;
        } else if (response.type !== undefined && response.type !== null && response.type !== '') {
          response = response.value;
        }
      }
    }
    var showSectionMap = this.state.showSectionMap;
    var showSectionResponseMatch = this.state.showSectionResponseMatch;
    const { showDependentItems } = this.state;
    const { itemIdsForSection } = this.state;
    var me = this;
    if (showSectionResponseMatch[item.ID] !== undefined) {
      try {
        var num_options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
        var datas = showSectionResponseMatch[item.ID];
        var checkMap = {};
        num_options.forEach(function (itm) {
          checkMap[itm.value] = {
            ...itm,
            value: parseFloat(itm.label)
          }
        })
        let resValue = parseFloat(response)
        let selectedId = null;
        Object.keys(datas).forEach(function (okey) {
          var data = datas[okey];
          if (checkMap[okey] !== undefined && checkMap[okey].operation === 1 && checkMap[okey].value === resValue) {
            showSectionMap[data.value] = true;
            selectedId = data.value;
          } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 2 && resValue > checkMap[okey].value) {
            showSectionMap[data.value] = true;
            selectedId = data.value;
          } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 3 && resValue >= checkMap[okey].value) {
            showSectionMap[data.value] = true;
            selectedId = data.value;
          } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 4 && resValue < checkMap[okey].value) {
            showSectionMap[data.value] = true;
            selectedId = data.value;
          } else if (checkMap[okey] !== undefined && checkMap[okey].operation === 5 && resValue <= checkMap[okey].value) {
            showSectionMap[data.value] = true;
            selectedId = data.value;
          } else {
            showSectionMap[data.value] = false;
            var clearResponseArr = [];
            var itemId = itemIdsForSection[data.value];
            clearResponseArr.push(data.value);
            if (showSectionResponseMatch[itemId] !== undefined) {
              var hideSectionOptions = showSectionResponseMatch[itemId];
              Object.keys(hideSectionOptions).forEach(function (jkey) {
                var opData = hideSectionOptions[jkey];
                if (selectedId === opData.value) {
                  return;
                }
                showSectionMap[opData.value] = false;
                clearResponseArr.push(opData.value);
              });
            }
            me.clearResponseForSections(clearResponseArr);

            if (showDependentItems[item.ID] !== undefined && showDependentItems[item.ID][okey] !== undefined) {
              me.clearResponseForSectionItems(showDependentItems[item.ID][okey])
              showDependentItems[item.ID][okey].forEach(function (depItm) {
                if (showSectionResponseMatch[depItm] !== undefined) {
                  var hideSectionOptions = showSectionResponseMatch[depItm];
                  Object.keys(hideSectionOptions).forEach(function (jkey) {
                    var opData = hideSectionOptions[jkey];
                    if (selectedId === opData.value) {
                      return;
                    }
                    showSectionMap[opData.value] = false;
                    clearResponseArr.push(opData.value);
                  });
                }
              })
            }
          }
        })
        if (selectedId !== null) {
          showSectionMap[selectedId] = true;
        }
      } catch { }

    }

    this.setState({
      showSectionMap: showSectionMap
    })
  }

  clearResponseForSections(sectionArr) {
    const { formConfigDetails } = this.state;
    let newData = this.state.customFormData;
    var formTypeN = formConfigDetails?.type;

    var sectionName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    newData[sectionName].forEach((section, sk) => {
      if (sectionArr.indexOf(section.ID) !== -1) {
        var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
        sectionItems.forEach(function (si) {
          si.response = '';
          si.value = '';
        })
      }
    });
    this.setState({
      customFormData: newData
    })
  }

  clearResponseForSectionItems(itemArr) {
    const { formConfigDetails } = this.state;
    let newData = this.state.customFormData;
    var formTypeN = formConfigDetails?.type;

    var sectionName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formTypeN === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    newData[sectionName].forEach((section, sk) => {
      var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
      sectionItems.forEach(function (si) {
        if (itemArr.indexOf(si.ID) !== -1) {
          si.response = '';
          si.value = '';
        }
      })
    });
    this.setState({
      customFormData: newData
    })
  }

  setSelectedTeams(team, isUpdate) {
    const { customFormData, formConfigDetails, currentSelected } = this.state;
    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    let sectionId = currentSelected.SectionID;
    let itemId = currentSelected.ID;
    if (customFormData !== null && customFormData[sectionName] !== undefined) {
      let selections = customFormData[sectionName];
      var sectionIndex = selections.findIndex(s => s.ID === sectionId);
      var section = selections[sectionIndex];
      var sectionItems = section[sectionItemName];
      var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
      var item = sectionItems[itemIndex];
      if (item.value === undefined || item.value === null || item.value === '') {
        item['value'] = [team];
      } else {
        var datas = item.value;
        var index = datas.findIndex(t => t.ID === team.ID)
        if (index === -1) {
          datas.push(team);
        } else {
          datas.splice(index, 1);
        }
        item['value'] = datas;
      }
      this.setState({
        customFormData: customFormData,
        currentSelected: item
      })
    }
  }

  setSelectedUsers(user, isUpdate) {
    const { customFormData, formConfigDetails, currentSelected } = this.state;
    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    let sectionId = currentSelected.SectionID;
    let itemId = currentSelected.ID;

    if (customFormData !== null && customFormData[sectionName] !== undefined) {
      let selections = customFormData[sectionName];
      var sectionIndex = selections.findIndex(s => s.ID === sectionId);
      var section = selections[sectionIndex];
      var sectionItems = section[sectionItemName];
      var itemIndex = sectionItems.findIndex(s => s.ID === itemId);
      var item = sectionItems[itemIndex];
      if (item.value === undefined || item.value === null || item.value === '') {
        item['value'] = [user];
      } else {
        var datas = item.value;
        var index = datas.findIndex(t => t.ID === user.ID)
        if (index === -1) {
          datas.push(user);
        } else {
          datas.splice(index, 1);
        }
        item['value'] = datas;
      }
      this.setState({
        customFormData: customFormData,
        currentSelected: item
      })
    }
  }

  cleanErrors() {
    let { customFormData, formConfigDetails } = this.state;
    var formType = formConfigDetails?.type;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
    this.state.customFormData[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        item['error'] = ''
      })
    })
    this.setState({
      customFormData: customFormData
    })
  }

  submitResponse(type) {
    let { customFormData, formConfigDetails, showSectionMap } = this.state;

    this.setState({
      formError: '',
      saveType: type
    })

    const formId = customFormData?.ID;

    var resData = [];
    var formType = formConfigDetails?.type;
    var sectionName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formType === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    var isRequiredFieldEmpty = false;
    var isValidationError = false;
    var validationMsg = '';

    var requireFieldNames = [];
    let firstErrorId = null;
    this.state.customFormData[sectionName].forEach(function (section) {
      var sectionItems = section[sectionItemName];
      sectionItems.forEach(function (item) {
        item['error'] = '';

        if (showSectionMap[item.ID] !== undefined && showSectionMap[item.ID] === false) {
          return '';
        } else if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
          return '';
        }

        if (item.IsRequired && (item.value === undefined || item.value === "" || item.value === null)) {
          isRequiredFieldEmpty = true;
          requireFieldNames.push(item.ItemName);
          item.error = 'Please enter/select option.';
          if (firstErrorId === null) {
            firstErrorId = item.ID;
          }
        }

        if (item.IsRequired && item.Type === 12 && item.value !== '' && item.value !== null) {
          if (Validator.validateEmail(item.value) === false) {
            isValidationError = true;
            validationMsg = 'Please enter valid email.';
            item.error = 'Please enter valid email.';
            if (firstErrorId === null) {
              firstErrorId = item.ID;
            }
          }
        }

        if (item.IsRequired && item.Type === 14 && item.value !== '' && item.value !== null) {
          if (Validator.validateURL(item.value) === false) {
            isValidationError = true;
            validationMsg = 'Please enter valid URL.';
            item.error = 'Please enter valid URL.';
            if (firstErrorId === null) {
              firstErrorId = item.ID;
            }
          }
        }

        if ((item.value === undefined || item.value === "" || item.value === null)) {
          return;
        }

        let finalValue;
        if (item.Type === 4 || item.Type === 18) {
          finalValue = JSON.stringify(String(item.value))
        } else if (item.Type === 5) {
          // let options = item.value.map(o=>String(o))
          finalValue = JSON.stringify(item.value)
        } else {
          finalValue = JSON.stringify(item.value)
        }

        var obj = {
          itemId: item.ID,
          value: finalValue
        }
        resData.push(obj);
      })
    });

    if (isRequiredFieldEmpty) {
      var nMsg = '';
      if (requireFieldNames.length === 1) {
        nMsg += ' is required field.';
      } else {
        nMsg += ' are required fields.';
      }
      var str = requireFieldNames.join(', ');
      str = str + nMsg;
      this.setState({
        formError: str
      })
      if (firstErrorId !== null) {
        let itemId = 'section_item' + firstErrorId;
        document.getElementById(itemId).scrollIntoView({
          // behavior: 'auto',
          block: 'center',
          inline: 'center',
          behavior: 'smooth'
        });
      }
    } else if (isValidationError) {
      this.setState({
        formError: validationMsg
      })
      if (firstErrorId !== null) {
        let itemId = 'section_item' + firstErrorId;
        document.getElementById(itemId).scrollIntoView({
          // behavior: 'auto',
          block: 'center',
          inline: 'center',
          behavior: 'smooth'
        });
      }
    } else {
      this.setState({
        nextClickLoader: type === 'NEXT' ? true : false,
        nextClickLoaderSave: type === 'SAVE' ? true : false
      })

      this.props.addCustomFormResponse(this.props.evaluation_id, formId, {
        form_type: formType,
        data: resData,
        componentId: parseInt(this.props.workflowComponentId)
      })
    }
  }

  showDecisionMessageDialog() {
    this.setState({ isActiveDialog: true }, () => {
      this.props.showMessageDialog();
    })
  }

  callBack = () => {
    this.props.hideMessageDialog();
    this.setState({
      isActiveDialog: false
    }, () => {
      // this.props.showEvaluationProductSelectionDialog();
    })
  };

  getConfigValue(array, key, value) {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  }

  render() {
    const classes = this.props.classes;
    let { customFormData, formConfigDetails, isShowLoader, showSectionMap, isFormEditable, isEdit, totalQuestions, showSectionCheckTypeMap } = this.state;

    var sectionName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
    var sectionItemName = formConfigDetails?.type === 'MASTER' ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';

    if (isShowLoader) {
      return <div className={classes.loader}><CircularProgress /></div>
    }

    let messageData = {
      head: "Are you sure you want to proceed?",
      subHead: "You are about to edit a field that will cause the workflow to restart the request.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let zIndexForItems = 0;

    // let isRequestType = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'REQUEST_TYPES')
    // let requestTypesData = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "REQUEST_TYPES") || [];
    // let requestTypes = [];
    // if (isRequestType && requestTypesData !== undefined && requestTypesData !== "NULL" && requestTypesData !== null && requestTypesData !== "null" && requestTypesData.length > 0) {
    //   if(Validator.isJson(requestTypesData)){
    //     requestTypes = JSON.parse(requestTypesData);
    //   }
    // }

    // console.log(this.props.evaluation,'Evaluationnnnnnnnn')
    let isApprovalStarted = false;
    // if (this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation !== '') {
    //   isApprovalStarted = this.props.evaluation.ApprovalStarted !== undefined && this.props.evaluation.ApprovalStarted !== null && this.props.evaluation.ApprovalStarted !== '' ? this.props.evaluation.ApprovalStarted === 1 ? true : false : false;
    // }
    let isShowEdit = true;
    if(this.props.isFromCart !== undefined){
      isShowEdit = false;
    }

    let checkTimeline = checkWorkflowFinished(this.props.userTimeline);

    // console.log(showSectionCheckTypeMap,'showSectionCheckTypeMap')

    return <>
      <div className={classnames(classes.topRoot)}>
        <div className={classes.root}>
          <div classes={classes.dialogContent} >
            <div style={{ textAlign: 'right' }}>
              {isShowEdit && !isEdit && !isApprovalStarted && !checkTimeline.isFinished && <SqureButton
                style={{ "marginLeft": "10px" }}
                variant={"contained"}
                onClick={() => {
                  this.setState({
                    isEdit: true
                  })
                }}>
                Edit
              </SqureButton>}

              {!isEdit && this.props.userRole === "OrgAdmin" && <SqureButton
                style={{ "marginLeft": "10px" }}
                variant={"outlined"}
                onClick={() => {
                  this.props.showFormDataCopyDialog();
                }}>
                Copy Data to Catalog
              </SqureButton>}

            </div>

            <div className={isFormEditable === true ? classes.disableForm : ''}>
              {customFormData !== null && customFormData[sectionName].map((section, sk) => {
                var sectionItems = section[sectionItemName] !== undefined ? section[sectionItemName] : [];
                sectionItems = sectionItems.sort(function (a, b) {
                  if (a.Seq > b.Seq) {
                    return 1;
                  } else if (a.Seq < b.Seq) {
                    return -1
                  } else {
                    return 0;
                  }
                })

                let isHideSection = false;
                sectionItems.forEach((item, key) => {
                  if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                    isHideSection = true;
                  }
                })

                if (sectionItems.length <= 0) {
                  return null
                }


                return <>
                  {!isHideSection && section?.SectionName !== '' && <div className={classes.sectionBox}>
                    <Typography variant={"h4"} className={classes.sectionName}>{section?.SectionName}</Typography>
                    <span className={classes.stepSubHead}>{section?.Description}</span>
                  </div>}
                  <div className={classes.section} key={sk}>
                    {sectionItems.map((item, key) => {
                      if (showSectionMap[item.ID] !== undefined && showSectionMap[item.ID] === false && showSectionCheckTypeMap?.item.indexOf(item.ID) !== -1) {
                        return '';
                      } else if (showSectionMap[item.SectionID] !== undefined && showSectionMap[item.SectionID] === false) {
                        return '';
                      }

                      if (item.Type === 1) {
                      }
                      var options = [];
                      try {
                        options = item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : [];
                      } catch { }

                      if (item.Type === 17 && (this.props.similarExistingProducts === undefined || this.props.similarExistingProducts === null || Object.keys(this.props.similarExistingProducts).length <= 0)) {
                        return ''
                      }

                      zIndexForItems++;
                      let newZIndex = ((totalQuestions + 10) - zIndexForItems)

                      return <><div className={classes.sectionItem} key={item.SectionID + '_section' + key} id={'section_item' + item.ID}>

                        {!this.state.isEdit && <CustomReplySummary
                          additional_data={options}
                          type={item.Type}
                          title={item.ItemName}
                          description={item.Description}
                          isRequired={item?.IsRequired}
                          key={item.ID + '_sectionItem'}
                          index={item.ID}
                          value={(item?.value !== undefined && item?.value !== null) ? item.value : ''}
                          selectedOption={[]}
                          evaluationId={this.props.evaluation_id}
                          orgId={this.props.OrgID}
                          workflowId={this.props.workflow_id}
                          isFormEditable={false}
                          itemId={item.ID}
                          sectionId={item.SectionID}
                          item={item}
                          error={item.error || ''}
                          isOtherOption={item?.IsOtherOption}
                          otherOptionData={item?.OtherOptionData}
                          webHookItemIdMap={null}
                          zIndexForItem={newZIndex}
                          decisionItemMap={this.state.isCheckDecisionItems ? this.state.decisionItemMap : []}
                          onChange={(response, isOther = false) => {

                          }}
                          showUserAddDialog={(item) => {

                          }}
                          showTeamAddDialog={(data) => {

                          }}
                          showMessageDialg={() => {
                            this.showDecisionMessageDialog()
                          }}
                        />}

                        {this.state.isEdit && <CustomReply
                          additional_data={options}
                          type={item.Type}
                          title={item.ItemName}
                          description={item.Description}
                          isRequired={item?.IsRequired}
                          key={item.ID + '_sectionItem'}
                          index={item.ID}
                          value={(item?.value !== undefined && item?.value !== null) ? item.value : ''}
                          selectedOption={[]}
                          evaluationId={this.props.match.params.evaluation_id}
                          orgId={this.props.OrgID}
                          workflowId={this.props.workflow_id}
                          isFormEditable={true}
                          itemId={item.ID}
                          sectionId={item.SectionID}
                          item={item}
                          error={item.error || ''}
                          isOtherOption={item?.IsOtherOption}
                          otherOptionData={item?.OtherOptionData}
                          webHookItemIdMap={null}
                          zIndexForItem={newZIndex}
                          decisionItemMap={this.state.isCheckDecisionItems ? this.state.decisionItemMap : []}
                          onChange={(response, isOther = false) => {
                            // if(!isOther){
                            item['value'] = response;
                            if (item.Type === 3 || item.Type === 4 || item.Type === 19) {
                              this.updateShowSection(item, response);
                            }
                            if (item.Type === 15 || item.Type === 16 || item.Type === 18) {
                              this.updateShowSectionForNumbers(item, response);
                            }
                            this.cleanErrors();
                          }}
                          showUserAddDialog={(item) => {
                            this.setState({
                              currentSelected: item
                            }, () => {

                            })
                            this.props.showSearchUsersPoperDialog()
                          }}
                          showTeamAddDialog={(data) => {
                            this.setState({
                              currentSelected: item
                            }, () => {
                              this.props.showSearchTeamsPoperDialog()
                            })
                          }}
                        />}

                      </div>
                      </>
                    })}

                  </div>
                </>
              })}
            </div>

            {this.state.isEdit && <BottomActions
              user={this.props.user}
              isCreatorOrAdmin={this.props.isCreatorOrAdmin}
              adminForward={this.props.adminForward}
              submitResponse={this.submitResponse}
              cancelResponse={() => {
                this.setState({
                  isEdit: false
                })
              }}
              nextClickLoaderSave={this.state.nextClickLoaderSave}
              nextClickLoader={this.state.nextClickLoader}
              isFormEditable={true}
            />
            }
            {this.state.isActiveDialog && <MessageDialog messageData={messageData} callBack={this.callBack} />}
          </div>
        </div>

        {this.props.isOpenTeamDialog && <SearchTeamsPoper
          selectedTeams={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
          onAdd={(team) => {
            this.setSelectedTeams({
              id: team.ID,
              ID: team.ID,
              value: team.ID,
              label: team.Name,
              Name: team.Name,
              Email: team.Email,
            }, true)
          }}
          onRemove={(team) => {
            this.setSelectedTeams({
              id: team.ID,
              ID: team.ID,
              value: team.ID,
              label: team.Name,
              Name: team.Name,
              Email: team.Email,
            }, true)
          }}
          onClose={() => {
            this.props.hideSearchTeamsPoperDialog();
          }}
        />}

        {this.props.isOpenUserDialog && <SearchUserPoper
          selectedUsers={this.state.currentSelected !== null && this.state.currentSelected.value !== undefined ? this.state.currentSelected.value : []}
          onAdd={(user) => {
            this.setSelectedUsers({
              id: user.ID,
              ID: user.ID,
              value: user.ID,
              label: user.Name,
              Name: user.Name,
              Email: user.Email,
            }, true)
          }}
          onRemove={(user) => {
            this.setSelectedUsers({
              id: user.ID,
              ID: user.ID,
              value: user.ID,
              label: user.Name,
              Name: user.Name,
              Email: user.Email,
            }, true)
          }}
          onClose={() => {
            this.props.hideSearchUsersPoperDialog();
          }}
        />}

        {/* <ProductSelectionDialog
          isRequestType={isRequestType && requestTypes.length > 0 ? true : false}
          requestTypes={requestTypes}
        /> */}

        {this.props.isShowFormDataCopyDialog && <FormDataCopyDialog
          evaluation={this.props.evaluation}
          formDatas={customFormData}
          from={'Form'}
          formConfigurations={formConfigDetails}
          showMap={showSectionMap}
        />}
      </div>

    </>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CustomForm));