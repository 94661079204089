import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, Typography, CircularProgress, Button, FormControl, MenuItem, Select, Checkbox } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import { hideImportTemplateDialog, showImportTemplateDialog } from "redux/templates/evaluation/org/action";
import { fetchUser, addTeamMembers } from "redux/okta/action";
import exportImage from "../../../../../../assets/images/export.svg"
// import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import classnames from "classnames";
import { fetchUsers } from "redux/usersSettings/action";
import Image from 'Components/Common/image.jsx';
import Criteria from './CriteriaRequirement/Criteria';
import { expandEvaluationCriteria, expandEvaluationRequirement } from "redux/evaluation/criteria/action";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import { showSnackBar } from "redux/snackbar/action";
import deleteIcon from "assets/images/delete.svg";
import { selectEvaluationTemplateCriteria, unselectEvaluationTemplateCriteria, addCriteriaSelection } from "redux/templates/evaluation/action";

import {
    uploadFile, saveFromOrgEvaluationTemplate, fetchOrgTemplates
} from "redux/templates/evaluation/org/action"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    importTemplateDialogOpen: state.orgTemplateEvaluation.importTemplateDialogOpen,
    okta: state.okta,
    uploadedTemplateData: state.orgTemplateEvaluation.uploadedTemplateData,
    uploadTemplateError: state.orgTemplateEvaluation.uploadTemplateError,
    isUploadTemplateProgress: state.orgTemplateEvaluation.isUploadTemplateProgress,
    expandedCriteriaId: state.evaludationCriteria.expandedCriteriaId,
    expandedRequirementId: state.evaludationCriteria.expandedRequirementId,
    criteriaSelection: state.templateEvaluation.criteriaSelection,
    saveProgress: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateProg,
    orgName: state.authUser.user?.Organization?.Name,
    criteriaSelectionProgress: state.templateEvaluation.criteriaSelectionProgress
});

const connectionActions = {
    showImportTemplateDialog: showImportTemplateDialog,
    hideImportTemplateDialog: hideImportTemplateDialog,
    fetchUser: fetchUser,
    fetchUsers: fetchUsers,
    uploadFile: uploadFile,
    addTeamMembers: addTeamMembers,
    expandEvaluationCriteria: expandEvaluationCriteria,
    expandEvaluationRequirement: expandEvaluationRequirement,
    saveFromOrgEvaluationTemplate: saveFromOrgEvaluationTemplate,
    showSnackBar: showSnackBar,
    fetchOrgTemplates: fetchOrgTemplates,
    selectEvaluationTemplateCriteria: selectEvaluationTemplateCriteria,
    unselectEvaluationTemplateCriteria: unselectEvaluationTemplateCriteria,
    addCriteriaSelection: addCriteriaSelection

}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important"
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(2.5),
    },

    uploadSection: {
        textAlign: 'center',
        border: '1px dotted #333',
        padding: theme.spacing(6, 0),
        "&.activeDrop": {
            border: '2px dotted #4a88ff',
        }
    },

    uploadText: {
        fontSize: 20,
        color: '#12344D',
    },

    uploadSubText: {
        color: '#475867'
    },

    uploadAnchor: {
        color: '#0091FF !important',
        textDecoration: 'underline',
        opacity: 0,
        width: 0
    },
    btnSection: {
        marginTop: 20,
        textAlign: 'center',
    },
    delete: {
        cursor: 'pointer',
    },
    fileText: {
        maxWidth: 300,
        wordBreak: 'break-all',
        fontSize: 14,
        border: '1px solid #333',
        borderRadius: 3,
        marginRight: 20,
        padding: 5,
        marginTop: 0,
        marginBottom: 0,
    },
    fileNameSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    labelFile: {
        color: '#4a87f8',
        marginLeft: 5,
        border: '1px solid #4a87f8',
        padding: '5px 10px',
        borderRadius: 4,
        cursor: 'pointer'
    },
    button: {
    },
    bottomAddBtn: {
        display: 'none'
    },
    arrow: {
        paddingLeft: 5
    },
    downloadTemplate: {
        textDecoration: 'underline',
        color: '#0091FF !important',
    },
    progress: {
        textAlign: 'center',
        paddingTop: 15
    },
    tbodyCell: {
        minHeight: 40
    },
    selectAll: {
        border: 0,
        color: '#0091FF',
        background: '#fff',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 15,

        "&:focus": {
            outline: 'none'
        }
    },
    headSection: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    userCount: {
        color: '#282D30',
        fontSize: 17,
    },
    buttonSection: {
        textAlign: 'center',
        marginTop: 40
    },
    checked: {
        opacity: 0,
        pointerEvents: 'none',
        width: 50
    },
    alert: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    cardAction: {
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        position: 'relative',
        marginBottom: 0,
        border: "1px solid #E2E2E2",

        '& [class*="MuiSvgIcon-root"]': {
            fill: '#74798C'
        },
    },

    primary: {
        textAlign: 'center',
        fontSize: theme.spacing(2.4),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5)
    },

    secondary: {
        textAlign: 'center',
    },

    content: {
        padding: theme.spacing(2, 2, 0, 2)
    },
    cardSection: {
        "& [class*='MuiGrid-item']": {
            padding: 14
        },

        "& [class*=CardAction-iconSection]": {
            position: 'absolute',
            bottom: 24,
            right: 24
        }
    },
    footerActions: {
        textAlign: 'right'
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    criteriaAllSelect: {
        marginRight: 10,
        padding: 0
    },
});

class ImportTemplateDialog extends React.Component {
    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.state = {
            searchQuery: '',
            fileName: '',
            activeOnDrop: "",
            file: null,
            error: '',
            templateData: null,
            criterias: null,
            productList: [],
            isCheckRes: false,
            template_name_error: "",
            selectedTemplateType: 'expent',
            counterSelectAll: 0
        }
        this.addTemplate = this.addTemplate.bind(this);
        this.selectAllCriterias = this.selectAllCriterias.bind(this)
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if(this.props.criteriaSelectionProgress !== prevProps.criteriaSelectionProgress){
            console.log('Criteria Selection Value Update')
        }

        if (this.props.importTemplateDialogOpen === true && prevProps.importTemplateDialogOpen === false) {
            this.setState({
                'error': '',
                templateData: null,
                searchQuery: '',
                fileName: '',
                activeOnDrop: "",
                file: null,
                criterias: null,
                productList: [],
                isCheckRes: false,
                template_name_error: "",
                selectedTemplateType: 'expent',
            })
        }

        if (!this.props.okta?.addTeamMemberProgress && prevProps.okta?.addTeamMemberProgress) {
            if (this.props.okta?.addTeamMemberError) {
                this.setState({ 'error': this.props.okta?.addTeamMemberError?.message })
            } else if (this.props.importTemplateDialogOpen) {
                this.props.hideImportTemplateDialog();
                this.props.fetchUsers(0, 20, '');
            }
        }

        if (!this.props.isUploadTemplateProgress && prevProps.isUploadTemplateProgress) {
            if (this.props.uploadTemplateError) {
                this.setState({ 'error': this.props.uploadTemplateError?.message })
            } else {
                var newTemplateData = this.props.uploadedTemplateData !== null ? this.props.uploadedTemplateData : {};
                if (Object.keys(newTemplateData).length > 0) {
                    var reqIdCount = 1;
                    newTemplateData.Criterias.forEach(function (criteria, j) {
                        criteria.ID = reqIdCount;
                        reqIdCount++;
                        if (criteria.Requirements !== null) {
                            criteria.Requirements.map((o, k) => {
                                o.ID = reqIdCount;
                                reqIdCount++;
                                return o;
                            })
                        }
                    })
                }

                var criterias = Object.keys(newTemplateData).length > 0 && newTemplateData.Criterias.length > 0 ? newTemplateData.Criterias : [];

                this.setState({
                    templateData: newTemplateData,
                    criterias: criterias
                })
            }
        }

        if (this.props.saveProgress === false && prevProps.saveProgress === true) {
            if (this.props.saveError == null) {
                this.props.hideImportTemplateDialog();
                this.props.showSnackBar("Template Saved", "success", 3000);
            } else {
                if (this.props.saveError.message !== undefined) {
                    this.setState({
                        "error": "Failed to save template."
                    })
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }

    }

    fileUpload(event) {
        if (event.target.files[0]) {
            this.setState({ fileName: event.target.files[0].name, file: event.target.files[0] })
        }
    }

    deleteFile = () => {
        this.setState({ fileName: '' });
        this.fileRef.current.value = '';
    }

    dropHandler = (event) => {
        this.setState({ activeOnDrop: '' })
        event.preventDefault();
        if (event.dataTransfer.files[0]) {
            this.setState({ fileName: event.dataTransfer.files[0].name, file: event.dataTransfer.files[0] });
        }
    }

    dragOverHandler = (event) => {
        event.preventDefault();
        if (!this.state.activeOnDrop) {
            this.setState({ activeOnDrop: 'activeDrop' })
        }
    }

    dragLeaveHandler = () => {
        if (this.state.activeOnDrop) {
            this.setState({ activeOnDrop: '' })
        }
    }

    uploadSelectedFile = () => {
        var isForrester = false;
        var templateType = '';
        var orgName = this.props.orgName.toLowerCase();
        if (process.env.REACT_APP_ENV === 'dev' && this.state.selectedTemplateType === 'forrester') {
            isForrester = true;
        } else if (orgName.indexOf('forrester') !== -1) {
            isForrester = true;
        }

        if (isForrester) {
            templateType = 'Forrester';
        }

        const formData = new FormData();
        formData.append('file', this.state.file);
        this.props.uploadFile(formData, {
            template_type: templateType
        });
    }

    cancel = () => {
        this.setState({
            searchQuery: '',
            fileName: '',
            activeOnDrop: "",
            file: null,
            error: '',
            templateData: null,
            productList: [],
            criterias: null,
            isCheckRes: false,
            template_name_error: "",
        });
        this.props.hideImportTemplateDialog();
    }

    handleToggleCriteria = (id) => {
        this.props.expandEvaluationCriteria(id)
    }

    handleToggleRequirement = (id) => {
        this.props.expandEvaluationRequirement(id)
    }

    addTemplate() {
        const { criteriaSelection } = this.props;
        const { criterias, templateData } = this.state;


        this.setState({
            template_name_error: ""
        });

        var templateName = templateData.Name || "";
        if (templateName.trim().length === 0) {
            this.setState({
                template_name_error: "Please enter Template Name."
            });
            return;
        }

        var criteriaIdMap = {};
        if( criterias !== null && criterias !== undefined && criterias.length>0){
            criterias.forEach(function (criteria) {
                criteriaIdMap[criteria.ID] = {
                    criteria: criteria
                };
                if( criteria.Requirements !== null && criteria.Requirements !== undefined && criteria.Requirements.length>0){
                    criteria.Requirements?.forEach(function (req) {
                        criteriaIdMap[criteria.ID][req.ID] = req;
                    })
                }  
            });
        }

        // console.log(criteriaSelection,'criteriaSelection');

        var addDatas = [];
        Object.keys(criteriaSelection).forEach(function (criteria) {
            var criteriaData = criteriaIdMap[criteria];
            var criteriaDataObj = {
                name: criteriaData?.criteria?.Name,
                is_editable: criteriaData?.criteria?.IsEditable,
                is_mandatory: criteriaData?.criteria?.IsMandatory,
                Seq: criteriaData?.criteria?.Seq,
                colloborators: criteriaData?.criteria?.Collaborators !== null ? criteriaData?.criteria?.Collaborators?.map((c) => {
                    return c.ID
                })
                    : [],
                requirements: []
            };
            var requirements = criteriaSelection[criteria];
            requirements.forEach(function (req) {
                var reqId = req.id;
                var reqData = criteriaIdMap[criteria][reqId];
                var requirement = {
                    name: reqData?.Name,
                    description: reqData?.Description,
                    priority: parseInt(reqData?.Priority),
                    scoring_type: parseInt(reqData?.ScoringType),
                    answer_type: parseInt(reqData?.AnswerType),
                    options: reqData?.Options,
                    is_mandatory: reqData?.IsMandatory,
                    is_editable: reqData?.IsEditable,
                    is_other_option: reqData?.IsOtherOption,
                    Seq: reqData?.Seq,
                    colloborators: reqData?.colloborators !== null ? reqData.colloborators?.map((c) => {
                        return c.ID
                    })
                        : [],
                }
                criteriaDataObj.requirements.push(requirement);
            });
            addDatas.push(criteriaDataObj);
        })

        console.log(addDatas, 'addDatas');

        var criteriaSorted = addDatas.sort(function (a, b) {
            if (a.Seq > b.Seq) {
                return 1;
            } else if (a.Seq < b.Seq) {
                return -1;
            } else {
                return 0;
            }
        })

        criteriaSorted.forEach(function (criteria) {
            criteria.requirements.sort(function (a, b) {
                if (a.Seq > b.Seq) {
                    return 1;
                } else if (a.Seq < b.Seq) {
                    return -1;
                } else {
                    return 0;
                }
            })
        })

        const data = {
            name: templateName,
            criterias: criteriaSorted
        }

        this.props.saveFromOrgEvaluationTemplate(data)
    }

    selectAllCriterias = (alreadySelectAll) => {
        var me = this;
        const { criterias } = this.state;
        if (criterias !== null && criterias !== undefined) {
            if (alreadySelectAll) {
                me.props.addCriteriaSelection(null, null, null, null, true)
            } else {
                criterias.forEach((criteria, index) => {
                    const isChecked = this.props.criteriaSelection[criteria.ID] !== undefined && Array.isArray(this.props.criteriaSelection[criteria.ID]) && this.props.criteriaSelection[criteria.ID].length === criteria.Requirements.length
                    if (isChecked === false) {
                        me.props.addCriteriaSelection(true, criteria.ID, criteria.Requirements.map(r => ({ id: r.ID, name: r.Name })), true)
                    }
                })
            }
        }
        var newValue = this.state.counterSelectAll + 1;
        this.setState({
            counterSelectAll: newValue
        })
    }

    render() {
        const classes = this.props.classes;
        const { criteriaSelection, isUploadTemplateProgress, orgName } = this.props;
        const { templateData, criterias, selectedTemplateType } = this.state;

        var isShowForresterTemplate = false;
        if (orgName !== undefined && orgName.toLowerCase().indexOf('forrester') !== -1) {
            isShowForresterTemplate = true;
        }

        let checkAllCriterias = false;
        let totalChecked = 0;
        if (criterias !== undefined && criterias !== null && criteriaSelection !== undefined && criteriaSelection !== null) {
            criterias.forEach((criteria, index) => {
                const isChecked = this.props.criteriaSelection[criteria.ID] !== undefined && Array.isArray(this.props.criteriaSelection[criteria.ID]) && this.props.criteriaSelection[criteria.ID].length === criteria.Requirements.length
                if (isChecked) {
                    totalChecked++;
                }
            })
            if (totalChecked === criterias.length) {
                checkAllCriterias = true;
            }
        }

        console.log(checkAllCriterias, 'checkAllCriterias111')
        console.log(criteriaSelection, 'criteriaSelection')

        return <Dialog
            onClose={this.props.hideImportTemplateDialog}
            aria-labelledby="add-upload-dialog"
            open={this.props.importTemplateDialogOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll={"body"}
        >
            <DialogContent classes={{ root: classes.dialogContent }}>
                <div className={classes.close} onClick={() => this.props.hideImportTemplateDialog()}><CloseIcon /></div>

                <Typography variant={"h6"} className={classes.title}> Import Template</Typography>
                <div className={classes.alert}>
                    {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                </div>

                {templateData === null && <div>
                    <div className={classnames(classes.uploadSection, this.state.activeOnDrop)} onDrop={(event) => this.dropHandler(event)} onDragOver={(event) => this.dragOverHandler(event)} onDragLeave={() => this.dragLeaveHandler()}>
                        <Image src={exportImage} alt="export image" />
                        {/* <p className={classes.uploadText}>Upload Template</p> */}
                        <p className={classes.uploadSubText}>Drag and drop your Excel or CSV file.</p>
                        {/* <Button htmlFor="inputFile" variant="contained" color="secondary" className={classes.labelFile} >Upload File</Button> */}
                        <label htmlFor="inputFile" className={classes.labelFile}>Upload File</label>
                        <input ref={this.fileRef}
                            id="inputFile"
                            type="file"
                            className={classes.uploadAnchor}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(event) => this.fileUpload(event)} />
                        <p> Download template: &nbsp;&nbsp;
                            <a className={classes.downloadTemplate} rel="noreferrer" target="_blank" href={'https://devapi.expent.ai/templates/static/evalaution_template.csv'}>CSV</a> &nbsp;
                            {!isShowForresterTemplate && <a className={classes.downloadTemplate} rel="noreferrer" target="_blank" href={'https://devapi.expent.ai/templates/static/evalaution_template.xlsx'}>XLS</a>} &nbsp;
                            {isShowForresterTemplate && <a className={classes.downloadTemplate} rel="noreferrer" target="_blank" href={'https://devapi.expent.ai/templates/static/evalaution_template_forrester.xlsx'}>XLS</a>} &nbsp;
                        </p>

                        {process.env.REACT_APP_ENV === 'dev' && <FormControl style={{ marginTop: 10, marginBottom: 10 }} variant="outlined" className={classes.formControl} size="small">
                            <Select value={selectedTemplateType} onChange={(e) => {
                                this.setState({
                                    selectedTemplateType: e.target.value
                                })
                            }}>
                                <MenuItem value={'expent'} style={{ fontSize: 13 }}>Expent</MenuItem>
                                <MenuItem value={'forrester'} style={{ fontSize: 13 }}>Forrester</MenuItem>
                            </Select>
                        </FormControl>}

                        {this.state.fileName !== "" && <div className={classes.fileNameSection}>
                            <p className={classes.fileText}>{this.state.fileName}</p>
                            <Image alt="Delete" className={classes.delete} src={deleteIcon} onClick={() => this.deleteFile()} />

                            {/* <DeleteIcon className={classes.delete} onClick={() => this.deleteFile()} /> */}
                        </div>}
                    </div>
                    <div>
                        {isUploadTemplateProgress === false && <div className={classes.btnSection}>
                            <Button variant="outlined" color="secondary" className={classes.button} onClick={this.cancel}>Cancel</Button>
                            &nbsp;
                            &nbsp;
                            <Button variant="contained" color="secondary" className={classes.button} onClick={this.uploadSelectedFile} disabled={this.state.fileName ? false : true}> Import Template <ArrowForwardIcon className={classes.arrow} /></Button>
                        </div>
                        }
                    </div>
                    <div>
                        {(isUploadTemplateProgress === true) && <div className={classes.progress}><CircularProgress /></div>}
                    </div>
                </div>}

                {templateData !== null && <div>
                    <OutlinedInput
                        label="Name your Template"
                        error={!!this.state.template_name_error}
                        helperText={this.state.template_name_error}
                        value={templateData.Name}
                        onChange={(event) => {
                            var template = this.state.templateData;
                            template.Name = event.target.value;
                            this.setState({
                                templateData: template,
                                template_name_error: ''
                            });
                        }}
                    />

                    {<div style={{ display: 'flex', alignItems: 'center', marginBottom: 25 }}>
                        <Checkbox
                            label={'Select All'}
                            className={classes.criteriaAllSelect}
                            color="primary"
                            checked={checkAllCriterias}
                            onChange={() => {
                                this.selectAllCriterias(checkAllCriterias)
                            }}
                        />
                        <Typography>Select All</Typography>
                    </div>}

                    {criterias !== null && criterias !== undefined && criterias.map((criteria, index) => (
                        <Criteria
                            isChooseTemplate={false}
                            isSettingTemplate={false}
                            key={`criteria_${criteria.ID}`}
                            isEditable={false}
                            criteria={criteria}
                            template={templateData}
                            toggleCriteria={this.handleToggleCriteria}
                            isOpen={"criteria_" + criteria.ID === this.props.expandedCriteriaId}
                            expandedRequirementId={this.props.expandedRequirementId}
                            toggleRequirement={this.handleToggleRequirement}
                            isFromImportTemplate={true}
                            checkAllCriterias={checkAllCriterias}
                            counterSelectAll={this.state.counterSelectAll}
                        />
                    ))}

                    <div className={classes.footerActions}>
                        <Button variant="outlined" color="secondary" className={classes.button} onClick={this.cancel}>Cancel</Button> &nbsp;&nbsp;
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={Object.keys(criteriaSelection).length <= 0 ? true : false}
                            className={classes.button}
                            onClick={() => this.addTemplate()}>
                            Add Template <ArrowForwardIcon className={classes.arrow}
                            />
                        </Button>
                    </div>
                </div>}

            </DialogContent>
        </Dialog>
    }
}
ImportTemplateDialog.defaultProps = {
    isFromProduct: false
};

export default connector(compose(
    withStyles(styles)
)(ImportTemplateDialog));