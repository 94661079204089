export function getNewActionData(slug) {
  let obj = {
    id: new Date().getTime(),
    type: getNodeType(slug),
    slug: slug,
    dragHandle: '.custom-drag-handle',
    style: { opacity: 1 },
    position: { x: 100, y: 50 },
    data: {
      id: new Date().getTime(),
      isNew: true,
      label: 'Select an app and action',
      type: getNodeType(slug),
      slug: slug,
      name: 'Trigger Node',
      inputJson: {},
      responseJson: {},
      object: {},
      masterData: {}
    }
  }
  return obj;
}


export function getNodeType(slug) {
  // const nodeTypes = {
  //   triggerNode: TriggerNode,
  //   addNode: AddNode,
  //   actionNode: ActionNode,
  //   ifConditionNode: IfConditionNode,
  //   loopNode: LoopNode
  // };
  let type = 'actionNode'
  if (slug === 'ACTION') type = 'actionNode'
  if (slug === 'IF') type = 'ifConditionNode'
  if (slug === 'IF_ELSE') type = 'ifConditionNode'
  if (slug === 'LOOP') type = 'loopNode'
  if (slug === 'FUNCTION_CALL') type = 'actionNode'
  return type;
}


