import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment"

export const SHOW_ADD_INVENTORY_DIALOG = "SHOW_ADD_INVENTORY_DIALOG";
export const HIDE_ADD_INVENTORY_DIALOG = "HIDE_ADD_INVENTORY_DIALOG";

export function showAddInventoryDialog(isNew,inventoryData) {
    return {
        type: SHOW_ADD_INVENTORY_DIALOG,
        payload: {isNew,inventoryData}
    };
}

export function hideAddInventoryDialog() {
    return {
        type: HIDE_ADD_INVENTORY_DIALOG,
        payload: {}
    };
}
