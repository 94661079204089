import React from "react";
import { connect } from "react-redux";
import { size } from 'underscore';
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommonCss from "commonCss";
import { fetchProductPrice } from "redux/product/orgProduct/action";

import { withRouter } from "react-router-dom";
import PricingDialog from "../../../../../Dialogs/PricingDialog";

const connectedProps = (state) => ({
  productPrice: state.orgProduct.productPrice,
  productPriceProg: state.orgProduct.productPriceProg,
  productPriceError: state.orgProduct.productPriceError,
});

const connectionActions = {
  fetchProductPrice: fetchProductPrice
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    ...CommonCss.ProductDetails,
    root: {
      "& [class*=head]": {
        marginBottom: "0px !important",
      },
      "& [class*=MuiAccordionSummary-root]": {
        padding: 0
      },
      "& [class*=MuiAccordionDetails]": {
        padding: 0
      },
      "& [class*=MuiAccordion-root]": {
        border: 'none',
        boxShadow: 'none'
      }
    }
  });

class PricingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPricingDialogOpen: false,
    };
  }

  componentDidMount() {
    this.props.fetchProductPrice(this.props.productId);
  }

  renderPricing = (title) => {
    const { classes, productPrice, isAccordion } = this.props;
    if (size(productPrice) === 0) return null
    let min = false, max = false
    min = productPrice?.pricing_range?.min
    max = productPrice?.pricing_range?.max

    if (isAccordion) {
      return (
        <div className={classes.root}>
          <Accordion>
            <AccordionSummary
              expanded={true}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className={classes.head}>{title}</p>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {productPrice?.plans && size(productPrice?.plans) > 0 ? (
                  <>
                    {max?.billing && min?.billing ? (
                      <p>
                        Usually ranges <b><i>from </i></b> {min?.billing} <b><i>to</i></b> {max?.billing}
                      </p>
                    ) : (
                      <>
                        {min?.billing && (
                          <p>
                            Starting <b><i>from</i></b> {min?.billing}
                          </p>
                        )}
                      </>
                    )}
                    {![undefined, null].includes(productPrice?.models) && productPrice?.models.length > 0 && (
                      <p><strong>Pricing Model</strong>: {productPrice?.models.join(", ")}</p>
                    )}
                    <p>Click{' '}
                      <span style={{ color: 'blue', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          this.setState({ isPricingDialogOpen: true })
                        }}>
                        <u>here</u>{' '}
                      </span>
                      for more details
                    </p>
                  </>
                ) : (
                  <>
                    {max?.billing && min?.billing ? (
                      <p>
                        Usually ranges from: ${min?.billing} <b><i>to</i></b> ${max?.billing}
                      </p>
                    ) : (
                      <>
                        {min?.billing && (
                          <p>
                            Starting <b><i>from</i></b>: {min?.billing}
                          </p>
                        )}
                      </>
                    )}
                    {![undefined, null, []].includes(productPrice?.models) && productPrice?.models.length > 0 && (
                      <p><strong>Pricing Model</strong>: {productPrice?.models.join(", ")}</p>
                    )}
                    {![null, undefined].includes(productPrice.pricing_url) && (
                      <p>Please visit seller
                        <span >
                          {' '}<a href={productPrice.pricing_url} style={{ color: 'blue', cursor: 'pointer' }} target="_blank" rel="noopener noreferrer">pricing</a>{' '}
                        </span>
                        page for more information
                      </p>
                    )}
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )
    } else {
      return (
        <div className={classes.root}>
          <p className={classes.head}>{title}</p>
          {productPrice?.plans && size(productPrice?.plans) > 0 ? (
            <>
              {max?.billing && min?.billing ? (
                <p>
                  Usually ranges <b><i>from </i></b> {min?.billing} <b><i>to</i></b> {max?.billing}
                </p>
              ) : (
                <>
                  {min?.billing && (
                    <p>
                      Starting <b><i>from</i></b> {min?.billing}
                    </p>
                  )}
                </>
              )}
              {![undefined, null].includes(productPrice?.models) && productPrice?.models.length > 0 && (
                <p><strong>Pricing Model</strong>: {productPrice?.models.join(", ")}</p>
              )}
              <p>Click{' '}
                <span style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    this.setState({ isPricingDialogOpen: true })
                  }}>
                  <u>here</u>{' '}
                </span>
                for more details
              </p>
            </>
          ) : (
            <>
              {max?.billing && min?.billing ? (
                <p>
                  Usually ranges from: ${min?.billing} <b><i>to</i></b> ${max?.billing}
                </p>
              ) : (
                <>
                  {min?.billing && (
                    <p>
                      Starting <b><i>from</i></b>: {min?.billing}
                    </p>
                  )}
                </>
              )}
              {![undefined, null, []].includes(productPrice?.models) && productPrice?.models.length > 0 && (
                <p><strong>Pricing Model</strong>: {productPrice?.models.join(", ")}</p>
              )}
              {![null, undefined].includes(productPrice.pricing_url) && (
                <p>Please visit seller
                  <span >
                    {' '}<a href={productPrice.pricing_url} style={{ color: 'blue', cursor: 'pointer' }} target="_blank" rel="noopener noreferrer">pricing</a>{' '}
                  </span>
                  page for more information
                </p>
              )}
            </>
          )}
        </div>
      )
    }
  }

  render() {
    const { classes, productName, title } = this.props;
    return (
      <>
        <div
          classnames={(classes.itemSection, classes.itemAboutSection)}
        >
          {this.renderPricing(title)}
        </div>
        <PricingDialog
          isOpen={this.state.isPricingDialogOpen}
          handleOnClose={() => this.setState({ isPricingDialogOpen: false })}
          pricingData={this.props.productPrice}
          productName={productName}
        />
      </>
    );
  }
}

PricingSection.defaultProps = {
  isAccordion: false
}
export default connector(
  compose(withRouter, withStyles(styles))(PricingSection)
);
