import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "../../../../AppBar";
import LayeredCard from "../Common/LayeredCard";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
  Button,
} from "@material-ui/core";

import { Search as SearchIcon } from "@material-ui/icons";

import DefaultImage from "../../../../../../assets/images/evaluation/default.svg";
// import { PRODUCTS } from '../../../../../../services/products';
import OutlinedInput from "../../../../../Common/Input/OutlinedInput";
import CartDrawer from "Components/Application/CartDrawer";
import { fetchProducts } from "../../../../../../redux/product/action";
import {
  createEvaluationCart,
  removeProductFromCart,
  fetchEvaluationCart,
} from "../../../../../../redux/evaluation/cart/action";
import { updateDrawerStyle } from "../../../../../../redux/drawer/action";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonCss from "../../../../../../commonCss";
import SliderFilter from "../../../../../Common/SliderFilter";
import AddProductButton from 'Components/Common/AddProductButton'

const connectedProps = (state) => ({
  products: state.products.products,
  fetchProductsProgress: state.products.fetchProductsProgress,
  subcategory: state.products.subCategory,
  evaluationCart: state.evaluationCart.evaluation,
});

const connectionActions = {
  fetchProducts: fetchProducts,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  updateDrawerStyle: updateDrawerStyle,
  fetchEvaluationCart: fetchEvaluationCart,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    topContainer: {
      marginTop: theme.spacing(3),
    },
    headSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterSection: {
      ...CommonCss.filterSection,
      marginRight: 20
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      
      "& [class*='MuiSvgIcon-root']": {
        fill: "#707070",
      },
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
     
    },
    outlinedSection: {
      '& [class*="MuiOutlinedInput-root"]': {
        maxHeight: 40,
        minHeight: 40,
        maxWidth: 343,
      },

      "& > div": {
        margin: 0
      },

      "& [class*='MuiOutlinedInput-input']": {
        fontSize: 13,
        width: 300,
      },
      "& [class*=MuiOutlinedInput-input]": {
        marginBottom: 0,
      },
    },
    title: {
      fontSize: theme.spacing(2.4),
    },
    categoriesList: {
      marginTop: theme.spacing(6.4),
      maxHeight: "calc(100vh - 210px)",
      minHeight: 200,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    main: {
      maxWidth: 'calc(100% - 280px)',
      flexBasis: 'calc(100% - 280px)',
    }
  });

class SubCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      items: [],
      page: 0,
      pageSize: 60,
    };
    this.addToCart = this.addToCart.bind(this);
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentDidMount() {
    this.fetchData();
    this.props.updateDrawerStyle(true, true);
    var evId = this.getParameterByName("evaluationId");
    console.log(evId, "evIdevIdevIdevIdevId")
    if (evId !== null) {
      var me = this;
      setTimeout(() => {
        me.props.fetchEvaluationCart(evId);
      }, 1000);
    }
  }

  fetchData() {
    this.props.fetchProducts(
      this.props.match.params.subcategory_id,
      this.state.page,
      this.state.pageSize,
      this.state.query
    );
  }

  toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ open: false });
  };

  addToCart(item) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(item.ID);
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(item.ID) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, item.ID);
      } else {
        this.props.createEvaluationCart(item.ID, this.props.evaluationCart.ID);
      }
    }
  }

  render() {
    const classes = this.props.classes;

    const filters = [
      {
        label: "Features",
        isMultiCheckboxSelect: true,
        isSearchRequired: true,
        placeholder: "Search features",
        list: [
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
          { label: "Data" },
        ],
      },
      {
        label: "Pricing Options",
        isMultiCheckboxSelect: true,
        list: [
          { label: "Free Trial" },
          { label: "Subscription" },
          { label: "Free Plan" },
        ],
      },
      {
        label: "Number of users",
        isMultiCheckboxSelect: true,
        list: [
          { label: "0-250" },
          { label: "250-1000" },
          { label: "Greater than 1000" },
        ],
      },
      {
        label: "Platform",
        isMultiCheckboxSelect: true,
        list: [
          { label: "Web" },
          { label: "Mobile" },
          { label: "Mac" },
          { label: "Windows" },
        ],
      },
      {
        label: "Deployment",
        isMultiCheckboxSelect: true,
        list: [{ label: "Cloud" }, { label: "Self-Hosted" }],
      },
    ];

    const isCart = this.props.evaluationCart !== null && this.props.evaluationCart !== '' && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length > 0;

    var addedToCart =
      this.props.evaluationCart == null
        ? []
        : this.props.evaluationCart.Products.map((p) => p.ID);
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item lg={isCart ? 8 : 12} md={12} className={isCart ? classes.main: ''}>

            <AppBar
              title={
                this.props.subcategory == null
                  ? ""
                  : this.props.subcategory.Name
              }
              withBack={true}
              dropdown={false}
            />

            <div className={classes.bottomContainer}>
              <div className={classes.headSection}>
                <Typography variant="h5" className={classes.title}>
                Products
                </Typography>
                <div className={classes.outlinedSection}>
                <OutlinedInput
                  id={"searchProducts"}
                  placeholder="Search Products"
                  value={this.state.query}
                  onChange={(event) => {
                    var me = this;
                    this.setState(
                      { query: event.target.value, page: 0 },
                      () => {
                        me.fetchData();
                      }
                    );
                  }}
                  startAdornment={
                    <InputAdornment>
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  }
                  roundedCorner={true}
                />
                </div>
                <div>
                  <AddProductButton title={'Add Product'}/>
                  <Button
                    className={classes.filterSection}
                    onClick={() => this.setState({ open: true })}
                  >
                    Filters
                  </Button>
                </div>
              </div> { this.props.products.length ===
                    (this.state.page + 1) * this.state.pageSize}
              <div className={classes.categoriesList} id="subCategoriesList">
                <InfiniteScroll
                  scrollableTarget="subCategoriesList" 
                  style={{ display: "flex", flexDirection: "column", overflow: "hidden", marginRight: 20 }}
                  dataLength={this.props.products.length} //This is important field to render the next data
                  next={() => {
                    var me = this;
                    console.log(this.state.page , 'this.state.page ')
                    this.setState({ page: this.state.page + 1 }, () => {
                      me.fetchData();
                    });
                  }}
                  hasMore={
                    this.props.products.length ===
                    (this.state.page + 1) * this.state.pageSize
                  }
                >
                   <p style={{ textAlign: "center" }}>
                      {this.props.products.length === 0 && this.props.fetchProductsProgress === false && <b>No More Data</b> }
                    </p>
                  <Grid container spacing={3}>
                    {this.props.products.map((product, i) => {
                      return (
                        <Grid item  lg={isCart ? 3 : 2} key={i} md={isCart ? 4 : 3}>
                          <LayeredCard
                            title={product.Name}
                            subTitle={product.Heading}
                            image={product.ProductLogo == null ||product.ProductLogo.length === 0 ? DefaultImage:product.ProductLogo}
                            toogleCheck={() => {
                              // this.props.history.push("/app/evaluate/subcategory/1")
                              this.addToCart(product);
                            }}
                            onClick={()=>{}}
                            numLayer={0}
                            checked={addedToCart.indexOf(product.ID) !== -1}
                            showCheckbox={true}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </InfiniteScroll>
                { this.props.fetchProductsProgress === true  && this.state.page === 0 && (
                  <div className={classes.backdrop}>
                    {" "}
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
          </Grid>
          {isCart && (
            <Grid item lg={4} style={{ position: "relative" }}>
              <CartDrawer />
            </Grid>
          )}
        </Grid>
        <SliderFilter
          open={this.state.open}
          toggleDrawer={this.toggleDrawer}
          filters={filters}
        />
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(SubCategory));
