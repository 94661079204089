import React, { useState } from 'react'
import {
    Transforms,
    Editor,
    Range,
    Element as SlateElement,
} from 'slate'
import { useSlate } from 'slate-react';

import { IconButton, Dialog, DialogContent, Button, Typography } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import makeStyles from '@material-ui/styles/makeStyles';
import Schema from '../VisualizationSchema/Schema';

export const InsertAddVisualizationButton = ({ icon, workflowId }) => {
    const editor = useSlate();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [placeholder, setPlaceholder] = useState('');
    // const [placeholderError, setVisualizationError] = useState('');
    const [code, setCodeValue] = useState('');
    const [selection, setSelection] = useState(null);

    const insertValue = (tagData, additional_data) => {
        // console.log(additional_data, 'additional_data')
        // // event.stopPropagation();
        // // setVisualizationError("")
        // const add_visualization = {
        //     type: 'visualization',
        //     placeholder: additional_data?.name !== '' ? additional_data?.name : tagData?.label,
        //     code: '{{ ' + tagData.value + ' }}',
        //     children: [{ text: '' }],
        // }
        // Transforms.insertNodes(editor, add_visualization)
        // Transforms.move(editor)
        // ReactEditor.focus(editor)
        // clear();
        editor.selection = selection
        insertVisualization(editor, tagData, additional_data);
        clear();
    }

    const clear = (e) => {
        setIsOpen(false);
        setPlaceholder('');
        setCodeValue('');
    }

    const addVisualizationIcon = (e) => {
        const [add_visualization] = Editor.nodes(editor, {
            match: n =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'visualization',
        })
        if (add_visualization && add_visualization[0]) {
            setPlaceholder(add_visualization[0].placeholder);
            setCodeValue(add_visualization[0].code);
        } else {
        }
        e.preventDefault()
        setIsOpen(true)
        setSelection(editor.selection)
    }

    return (
        <span>
            <ToggleButton
                className={classes.insertVisualization}
                selected={(isAddVisualizationActive(editor))}
                onMouseDown={event => addVisualizationIcon(event)}>
                <IconButton >{icon}
                </IconButton>
            </ToggleButton>
            <Dialog
                aria-labelledby="app-addVisualizationDialog"
                open={isOpen}
                disableBackdropClick={true}
                maxWidth={"md"}
                scroll="body"
                className={classes.dialog}
                id={"addVisualizationMetaDialog"}
                fullWidth={true}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Typography variant={'h5'}>Insert Visualization</Typography>
                    <Schema
                        workflowId={workflowId}
                        handleClick={(tagData, additional_data) => {
                            // console.log(tagData, 'TagData')
                            // setPlaceholder(tagData?.label)
                            // setCodeValue(tagData?.value)
                            insertValue(tagData, additional_data)
                            // this.props.addTagValue(tagData?.value)
                        }}
                    />
                    <div className={classes.btnSection}>
                        <Button variant="outlined" color="secondary" className={classes.button} onClick={(event) => {
                            clear()
                        }} >Cancel</Button>
                    </div>

                </DialogContent>
            </Dialog>
        </span>
    )
}

const unwrapVisualization = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'visualization',
    })
}

const wrapVisualization = (editor, tagData, additional_data) => {
    if (isVisualizationActive(editor)) {
        unwrapVisualization(editor)
    }

    // console.log(additional_data, 'additional_data')
    // // event.stopPropagation();
    // // setVisualizationError("")
    // const add_visualization = {
    //     type: 'visualization',
    //     placeholder: additional_data?.name !== '' ? additional_data?.name : tagData?.label,
    //     code: '{{ ' + tagData.value + ' }}',
    //     children: [{ text: '' }],
    // }
    // Transforms.insertNodes(editor, add_visualization)
    // Transforms.move(editor)
    // ReactEditor.focus(editor)
    // clear();

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
        type: 'visualization',
        placeholder: additional_data?.name !== '' ? additional_data?.name : tagData?.name,
        code: '{{ visualization.' + tagData.visualizationID + ' }}',
        children: isCollapsed ? [{ text: '' }] : [],
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
    }
}

const isVisualizationActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
}

const insertVisualization = (editor, tagData, additionalData) => {
    if (editor.selection) {
        wrapVisualization(editor, tagData, additionalData)
    }
}

export const withVisualizations = editor => {
    const { insertData, insertText, isInline } = editor

    editor.isInline = element => {
        return element.type === 'visualization' ? true : isInline(element)
    }

    editor.insertText = text => {
        if (text) {
            wrapVisualization(editor, text)
        } else {
            insertText(text)
        }
    }

    editor.insertData = data => {
        const text = data.getData('text/plain')

        if (text) {
            wrapVisualization(editor, text)
        } else {
            insertData(data)
        }
    }
    return editor
}

const isAddVisualizationActive = editor => {
    const [add_visualization] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'visualization',
    })
    return !!add_visualization
}

const useStyles = makeStyles((theme) => ({
    insertVisualization: {
        padding: 0,
        minWidth: 41,
        margin: '0.25em',
        border: 'none'
    },
    radioBox: {
        width: 13,
        height: 13,
        maxWidth: 13,
        minWidth: 13
    },
    inputBox: {
        width: '100%',
        borderRadius: 3,
        minHeight: 40,
        border: '1px solid #ddd',
        padding: 5
    },
    btnSection: {
        float: 'right',
        marginBottom: 10
    },
    label: {
        position: 'relative',
        top: -1
    },
    alert: {
        marginBottom: theme.spacing(1)
    },
    items: {
        marginBottom: 20
    },
    itemTitle: {
        fontSize: 14,
        marginBottom: 5
    }
}));
