import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "../Style/index.css";
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import Arrow from '../Components/Arrow';
import { showBuilderConfigDrawer } from "redux/automations/builder/action"
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
const connectedProps = () => ({});

const connectionActions = {
  showBuilderConfigDrawer: showBuilderConfigDrawer
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  triggerNode: {
    border: '1px solid #222'
  }
});

class ActionNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }


  render() {
    const { showArrow  } = this.props;
    const data = this.props.data;
    const isShowAddOption = data.isShowAddOption
    return (
      <>
        <div className={'e_step_header'} onClick={() => {
          this.props.showBuilderConfigDrawer(this.props.data)
        }}>
          <div className={'e_step_icon'}>
            {this.props.data && this.props.data?.componentType === 'Trigger' && <TrendingDownIcon />}
            {this.props.data && this.props.data?.componentType === 'Action' && <SystemUpdateAltIcon />}
          </div>
          <div className={'e_step_title'}>
            <p className={'e_step_title_text'}>{data?.label}</p>
          </div>
        </div>

        {showArrow && <Arrow
          forComponent={this.props.data}
          type={'common'}
          label={this.props.data && this.props.data?.componentType === 'Trigger' ? 'ACTIONS' : ''}
          height={this.props.data && this.props.data?.componentType === 'Trigger' ? 80 : 50}
          isShowArrow={true}
          isShowAddOption={isShowAddOption}
        />}
      </>
    );
  }
}

ActionNode.defaultProps = {
  showArrow: true
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ActionNode));