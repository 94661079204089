import React from 'react';
import { connect } from "react-redux";
import classnames from "classnames";
// import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { Button, Typography, Grid, CircularProgress } from '@material-ui/core';
import { CommonFn } from 'services/commonFn';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import Image from 'Components/Common/image.jsx';
import AskSeller from "assets/images/ask-seller.svg"

import { Send as SendIcon } from '@material-ui/icons';
import { Chat as ChatIcon } from '@material-ui/icons';

// redux
import { addAskSellerRequest, showAskSellerDialog, fetchAskSellerThread, updateSellerCollectiveResDatas } from "redux/evaluation/criteria/action";
import { showEvaluationProductEditDialog } from "redux/evaluation/cart/action";
import { fetchEvaluationMetaData } from "redux/evaluation/metadata/action";
import AnswerReplySeller from "Components/Common/EvaluateMultiChoiceAnswer/AnswerReplySeller";
import { showEvaluationAsksellerSelectDialog, hideEvaluationAsksellerSelectDialog } from "redux/templates/evaluation/action"


const connectedProps = (state) => ({
  authUser: state.authUser.user,
  evaluation: state.evaluationMetaData.evaluation,
  evaData :state.evaluationCart.evaluation,
  addResponseProgress: state.evaludationCriteria.addResponseProgress,
  successResponseAdd: state.evaludationCriteria.successResponseAdd,
  errorResponseAdd: state.evaludationCriteria.errorResponseAdd,
  collectiveRequirement: state.evaludationCriteria.collectiveRequirement,
  collectiveProduct: state.evaludationCriteria.collectiveProduct,
  collectiveResponseDialogOpen: state.evaludationCriteria.collectiveResponseDialogOpen,
  threadFetchProgress: state.evaludationCriteria.fetchAskSellerResponseInprogress,
  thread: state.evaludationCriteria.fetchAskSelerThread,
  saveProgress: state.evaludationCriteria.askSellerSubmitProgress,
  sellerRequirement: state.evaludationCriteria.fetchAskSelerRequirement,
  sellerResponse: state.evaludationCriteria.fetchAskSelerResponse,
});

const connectionActions = {
  updateSellerCollectiveResDatas: updateSellerCollectiveResDatas,
  addAskSellerRequest: addAskSellerRequest,
  fetchAskSellerThread: fetchAskSellerThread,
  showAskSellerDialog: showAskSellerDialog,
  showEvaluationProductEditDialog: showEvaluationProductEditDialog,
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  showEvaluationAsksellerSelectDialog:showEvaluationAsksellerSelectDialog,
  hideEvaluationAsksellerSelectDialog:hideEvaluationAsksellerSelectDialog
}

var connector = connect(connectedProps, connectionActions);

const useStyles = createStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  title: {
    fontWeight: 600,
    opacity: 1,
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: theme.spacing(2.2)
  },
  subTitle: {
    fontWeight: 600,
    color: '#282D30',
    opacity: 1,
    fontSize: '1em',
    textAlign: 'center',
    background: '#F4F6F8 0% 0% no-repeat padding-box',
    borderRadius: '7px',
    padding: '15px',
    width: '90%',
    margin: '0px auto',
    marginBottom: '30px',
    "& [class*='editableField']": {
      background: '#F4F6F8',
      maxHeight: 'auto !important',
      minHeight: 'auto !important'
    }
  },
  appBar: {
    backgroundColor: '#ffffff',
    boxShadow: 'unset',
    borderBottom: '1px solid #EEEEEE'
  },
  privateTabIndicator: {
    border: '1.5px solid #4A87F8',
    background: '#4A87F8'
  },
  tabTitle: {
    color: '#282D30 !important',
    fontSize: '13px',
    fontWeight: 500
  },
  activeTabTitle: {
    color: '#4A87F8',
    fontSize: '14px',
    fontWeight: 600
  },
  description: {
    margin: 0,
    fontWeight: 400
  },
  name: {
    margin: 0,
    fontWeight: 600
  },
  comingSoon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 15,
    height: 'calc(100vh - 300px)',
    color: '#707070'
  },
  addTextimage: {
    marginRight: 10,
    width: 21,
    position: 'relative',
    top: 2
  },
  addText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80px',
    fontSize: 13,
    color: '#707070',
    cursor: 'pointer',
    transition: 'all linear .5s',
  },
  cummunityTabIndicator: {
    display: 'block',
    width: '6px',
    height: '6px',
    background: 'red',
    borderRadius: '4px',
    position: 'absolute',
    left: '-8px',
    top: '0px'
  },
  replylink: {
    fontWeight: 'normal',
    letterSpacing: '0px',
    // color: '#000000',
    // fontSize: '12px',
    marginRight: '25px',
    fontSize: '1rem',
    textDecoration: 'underline',
    color: '#4A87F8',
    marginTop: '16px',
    display: 'block'
  },
  emptyStateAnswer: {
    "& [class*='makeStyles-scoreResponse-']": {
      marginLeft: 0
    }
  },
  username: {
    fontWeight: "600",
    display: 'flex',
    placeContent: 'center space-between',
    alignItems: 'center',
    fontSize: 14,
  },
  responseTime: {
    fontSize: 14,
    marginTop: 1
  },
  sender: {
    width: '100%',
    marginBottom: 20,

    '& .messageHeader': {
      display: 'flex',
      fontSize: 14,
      '& span': {
        marginLeft: '10px'
      }
    },
    '& .messageContainer': {
      background: '#F3F4F5 0% 0% no-repeat padding-box',
      border: '1px solid #D5D8DC',
      maxWidth: '60%',
      textAlign: 'left',
      fontSize: '14px',
      letterSpacing: '0px',
      color: '#1C2833',
      borderRadius: '18px !important',
      'border-top-left-radius': '0 !important',
      padding: '0px 16px',
      minWidth: 320
    }
  },
  receiver: {
    width: '100%',
    marginBottom: 20,
    height: '100%',
    // flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'flex-end space-between',
    alignItems: 'flex-end',
    '& .messageHeader': {
      display: 'flex',
      '& span': {
        marginLeft: '10px'
      }
    },
    '& .messageContainer': {
      background: '#4b86f7 0% 0% no-repeat padding-box',
      // border: '1px solid #1C2833',
      maxWidth: '60%',
      textAlign: 'left',
      fontSize: '14px',
      letterSpacing: '0px',
      color: '#ffffff',
      // float: 'right',
      borderRadius: '18px !important',
      'border-top-right-radius': '0 !important',
      padding: '0px 16px',
      minWidth: 320,
      "&  *": {
        color: '#ffffff !important',
      }
    }
  },
  mainLoader: {
    position: 'fixed',
    left: 'calc(50vw - 34px)',
    top: 'calc(50vh - 36px)'
  },
  askSellerBtn: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: ' 1px solid #398AF5',
    borderRadius: '4px',
    letterSpacing: '0px',
    color: '#398AF5',
    padding: '3px 10px',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    marginLeft: '6px',
    fontSize: 16
  },
  form: {
    "& [class*='editableField']": {
      minHeight: 'auto !important',
    },
  },
  sellerMsg: {
    padding: '6px',
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#707070',
    marginTop: 0,
    fontSize: 14
  },
  chatWithBuyerDiv: {
    textAlign: 'center'
  },
  buyerBtn: {
    color: '#4b86ff',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#4b86ff',
    }
  },
  currProductTitle: {
    marginBottom: theme.spacing(2),
    color: '#282D30',
    fontSize: theme.spacing(2.2)
  },
  sellerRequirementResponse: {
    // border: '1px solid #f1f1f1',
  },
  sellerResDiv: {
    border: '1px solid #f1f1f1',
    marginBottom: 50,
    padding: 10
  },
  showNoteSection: {
    width: 'calc(100% - 7px)',
    "& [class*='editableField']": {
      margin: '0px !important',
      minHeight: 'auto !important',
      maxHeight: 'auto !important',
    }
  },
}));

class CollectiveSellerResponses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: "",
      isShowLoader: true,
      hideChatScreen: true
    }
    this.submit = this.submit.bind(this);
    this.selectAskSeller = this.selectAskSeller.bind(this);
  }

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue })
  };

  selectAskSeller = (evaluationId, product) => {
    if (product.VendorEmail !== null && product.VendorEmail.length !== 0) {
      if (this.props.dialogEvaluationAskseller === true) {
        this.props.hideEvaluationAsksellerSelectDialog()
      } else {
        this.props.showEvaluationAsksellerSelectDialog(evaluationId, product)
      }
    } else {
      this.props.showEvaluationProductEditDialog(evaluationId, product, null, true);
    }
  }

  componentDidMount() {
    this.props.updateSellerCollectiveResDatas(this.props.criteria, this.props.requirement, this.props.product, this.props.evaluation_id, 2);
    setTimeout(() => {
      this.fetchSelerThread();
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.saveProgress === false && prevProps.saveProgress === true) {
      this.setState({
        message: " ",
        isShowLoader: false,
      }, () => {
        this.fetchSelerThread();
      })
    }

    if (this.props.threadFetchProgress === false && prevProps.threadFetchProgress === true) {
      const { thread } = this.props;
      var eva_products = this.props.evaluation !== undefined ? [...this.props.evaluation.Products] : [];
      var productMap = {};
      eva_products.forEach(function (item) {
        productMap[item.ID] = item;
      });
      var productId = this.props.productId;
      var product = productMap[productId];
      if (product === undefined) {
        product = this.props.collectiveProduct;
      }

      var requirement = this.props.collectiveRequirement;

      let threads = Object.assign(thread || {})
      let _thread = threads[`${product.VendorUUID}_${requirement?.id}`]
      let current_thread = _thread && _thread.length > 0 ? _thread.filter((item) => item.Type !== 0) : [];

      var hideChatScreen = true;
      if (current_thread && current_thread.length > 1) {
        hideChatScreen = false;
      }

      this.setState({
        isShowLoader: false,
        hideChatScreen: hideChatScreen
      })

    }
  }

  submit() {
    if (this.state.message.trim().length === 0) {
      return;
    }
    var me = this;
    var response = me.state.message;
    var VendorUUID = this.props.collectiveProduct.VendorUUID;
    if(VendorUUID === '' || VendorUUID === null){
      var product = this.props.evaData?.Products.filter(o=>o.ID === this.props.collectiveProduct.ID)[0];
      VendorUUID = product.VendorUUID;
    }
    this.props.addAskSellerRequest(VendorUUID, this.props.collectiveRequirement.id, response)
  }

  fetchSelerThread = () => {
    var product = this.props.collectiveProduct;
    var requirement = this.props.collectiveRequirement;
    var VendorUUID = product.VendorUUID;
    if(VendorUUID === '' || VendorUUID === null){
      var productN = this.props.evaData !== undefined && this.props.evaData !== null ? this.props.evaData?.Products.filter(o=>o.ID === this.props.collectiveProduct.ID)[0] : '';
      VendorUUID = productN?.VendorUUID;
    }
    if (product !== undefined && requirement !== undefined) {
      this.setState({
        message: ""
      }, () => {
        this.props.fetchAskSellerThread(VendorUUID, requirement.id);
      })
    } else {
      this.setState({
        isShowLoader: false
      })
    }
  }


  render() {
    const classes = this.props.classes;
    const { thread, sellerResponse, sellerRequirement } = this.props;

    if (this.state.isShowLoader) {
      return <div className={classes.mainLoader}> <CircularProgress /> </div>
    }

    var eva_products = this.props.evaluation !== undefined ? [...this.props.evaluation.Products] : [];
    var productMap = {};
    eva_products.forEach(function (item) {
      productMap[item.ID] = item;
    });
    var productId = this.props.productId;
    var product = productMap[productId];
    if (product === undefined) {
      product = this.props.collectiveProduct;
    }

    var requirement = this.props.collectiveRequirement;

    // let current_thread = thread && thread[`${product?.VendorUUID}_${requirement.id}`] || []


    let threads = Object.assign(thread || {})
    let _thread = threads[`${product.VendorUUID}_${requirement?.id}`]
    let current_thread = _thread && _thread.length > 0 ? _thread.filter((item) => item.Type !== 0) : [];


    return <div>
      {/* <h6 className={classes.title}>Collective responses</h6> */}
      <div className={classes.root} style={this.props.style}>
        {sellerResponse !== undefined && sellerResponse !== null && Object.keys(sellerResponse).length > 0 && <div className={classes.sellerResDiv}>
          {sellerRequirement !== undefined && sellerRequirement !== null && Object.keys(sellerRequirement).length > 0 && <div className={classes.sellerRequirementResponse}>
            <Typography variant={"h6"} className={classes.currProductTitle}>
              Response {product !== undefined && product.Name !== null && product.Name !== '' ? 'from ' + product.Name : ''}
            </Typography>
            {!([1].includes(sellerRequirement.AnswerType)) && <AnswerReplySeller
              menuPortalTarget={document.getElementById('root')}
              requirement={sellerRequirement}
              response={sellerResponse}
              evaluation_id={this.props.match.params.evaluation_id}
              OrgID={this.props.OrgID}
              product={product}
              userOptions={sellerResponse == null || sellerResponse.SellerOptions == null ? [] : sellerResponse.SellerOptions}
            />}

            {sellerResponse !== undefined && sellerResponse !== null && Object.keys(sellerResponse).length > 0 && <div style={{ position: 'relative', width: '100%' }}>
              <div className={classes.rNote} >
                {sellerResponse !== null && sellerResponse.Note !== null && sellerResponse.Note !== '' &&
                  <>
                    <div className={classnames(classes.showNoteSection)}>
                      <SlateInputField
                        readOnly={true}
                        isToolBar={false}
                        placeholder=""
                        style={{ margin: 0 }}
                        as={SlateInputField}
                        initValue={sellerResponse.Note}
                        formControlStyle={{ border: 'none' }}
                        textContainerStyle={{ color: "#707070", fontSize: 14 }} />
                    </div>
                  </>
                }
              </div>
            </div>
            }
          </div>}
        </div>}

        <div className={classes.sellerResDiv}>

          {(product !== null && product.VendorUUID !== null && product.VendorUUID !== '')  && <Typography variant={"h6"} className={classes.currProductTitle}>
            Chat {product !== undefined && product.Name !== null && product.Name !== '' ? 'with ' + product.Name : ''}
          </Typography>}

          <div className={classes.thread}>

            {this.state.isShowLoader === false && current_thread && current_thread.length > 0 &&
              <>
                {
                  _thread && _thread.map((response, k) => {
                    let $previousValue = _thread[k - 1];
                    if (k === 0) {
                      return '';
                    }
                    return <div key={k} className={classnames(
                      classes.response,
                      (response.Type === 2) ? classes.sender : classes.receiver,
                      ($previousValue?.Type === 2 && response?.Type === 2) ? classes.chatStart : ''
                    )}>
                      <div className={'messageHeader'}>
                        {
                          response.Type === 2 ?
                            <Typography className={classes.username}>{this.props.collectiveProduct.Name}</Typography>
                            : <Typography className={classes.username}>You</Typography>
                        }
                        &nbsp;<span className={classes.responseTime}>{CommonFn.formateDateTime(response.UpdatedAt)}</span>
                      </div>
                      <div className={'messageContainer'}>
                        <SlateReadonlyField initValue={response.Response} />
                      </div>
                    </div>
                  })
                }
              </>
            }

            {(product === null || product.VendorUUID === null || product.VendorUUID === '')  &&
              <>
                {false && <Grid item xs={12} sm={12} md={12} lg={12}>
                  <p className={classes.sellerMsg}>
                    Vendor hasn’t responded yet.
                  </p>
                </Grid>}
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <button className={classnames(classes.askSellerBtn, 'evaluate-ask-seller-btn')} onClick={() => {
                    let evaluation_id = parseInt(this.props?.match?.params.evaluation_id)
                    // if ((product.VendorEmail !== null && product.VendorEmail.length !== 0)) {
                    //   this.props.showAskSellerDialog(requirement, product, evaluation_id)
                    // } else {
                    //   this.props.showEvaluationProductEditDialog(evaluation_id, product)
                    // }
                    this.selectAskSeller(evaluation_id, product);
                  }}><Image
                      src={AskSeller}
                      alt="forgotpassword_image"
                      className={classes.askImage}
                    />&nbsp;&nbsp;Ask Vendor</button>
                </Grid>
              </>
            }

            {(product !== null && product.VendorUUID !== null && product.VendorUUID !== '') && <div className={classnames(classes.form, 'evaluate-seller-edit')}>
            {/* {((current_thread && current_thread.length > 1) || (sellerResponse !== undefined && sellerResponse !== null && Object.keys(sellerResponse).length > 0)) && <div className={classnames(classes.form, 'evaluate-seller-edit')}> */}
              {this.state.hideChatScreen && <div className={classes.chatWithBuyerDiv}>
                <Button className={classes.buyerBtn} variant="contained" color="primary" endIcon={<ChatIcon />} onClick={() => {
                  this.setState({
                    hideChatScreen: false
                  })
                }}>
                  Chat with Vendor
                </Button>
              </div>}
              {!this.state.hideChatScreen && <div className={classes.chatWithBuyerScreen}>
                <SlateInputField
                  style={{ width: '100%' }}
                  as={SlateInputField}
                  onChangeEvent={(value) => {
                    this.setState({ message: value });
                  }}
                  placeholder={"Type your message here."}
                  initValue={this.state.message}
                  formControlStyle={{ overflow: 'overlay' }}
                  textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', margin: "0px", color: "#707070", fontSize: 16 }} />

                <div style={{ textAlign: 'right' }}>
                  <Button variant="contained" color="primary" endIcon={<SendIcon />} onClick={() => { this.submit(); }}>
                    Send
                  </Button>

                </div>

              </div>}
            </div>}

          </div>
        </div>

      </div>
    </div>
  }
}

export default connector(compose(withRouter, withStyles(useStyles))(CollectiveSellerResponses));