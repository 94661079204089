import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { DialogContent, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { hideFeedbackTakeDialog, addEvaluationFeedback, fetchEvaluationUserFeedback } from "redux/evaluation/feedback/action"
import { showSnackBar } from "redux/snackbar/action";
import { withRouter } from 'react-router-dom';

import Nestable from 'react-nestable';
import DragIcon from "assets/images/drag_icon.svg"
import Feedback from "assets/images/feedback.svg"

import Image from 'Components/Common/image.jsx';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

import { addWorkflowComponentUserResponse } from "redux/workflow/action";

import EvaluationBar from "../../../Common/EvaluationBar";
import BottomActions from "./BottomActions";
import { getNextComponent } from "util/Common";


const connectedProps = (state) => ({
  isOpen: state.evaluationFeedback.showFeedbackTakeDialog,
  addFeedbackProgress: state.evaluationFeedback.addFeedbackProgress,
  addFeedbackError: state.evaluationFeedback.addFeedbackError,
  evaluation: state.evaluationMetaData.evaluation,
  user: state.authUser.user,
  userFeedback: state.evaluationFeedback.userFeedback,
  userFeedbackProgress: state.evaluationFeedback.userFeedbackProgress,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  userTimeline: state.workflow.userTimeline
});

const connectionActions = {
  hideDialog: hideFeedbackTakeDialog,
  showSnackBar: showSnackBar,
  addEvaluationFeedback: addEvaluationFeedback,
  fetchEvaluationUserFeedback: fetchEvaluationUserFeedback,
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  mainRoot: {
    height: '89vh',
    overflow: 'scroll',
  },
  root: {
    minHeight: '89vh',
    background: '#fff',
    padding: 20,
    width: 650,
    paddingLeft: 100,
    paddingRight: 100,
    margin: '0px auto'
  },
  title: {
    textAlign: "center",
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
    margin: '20px 0px 20px'
  },
  subTitle: {
    textAlign: 'center',
    fontSize: 13
  },
  subHeader: {
    fontSize: 13,
    color: '#475867',
  },
  actions: {
    textAlign: "center"
  },
  actionBtn: {
    minWidth: '200px'
  },
  dragItem: {
    borderRadius: 4,
    padding: theme.spacing(1.5),
    color: '#6D7278',
    border: '1px solid #EBEBEB',
    display: 'flex',
    justifyContent: 'space-between'
  },
  nestedComponent: {
    padding: '0px 0px 30px',
  },
  dialogContent: {
    margin: 'auto',
  },
  feedbackSection: {
    marginBottom: 40,
    "& > div": {
      color: '#475867',
      marginTop: 15,
      fontSize: 12,
      "& > label": {
        fontSize: 14,
      }
    },
    "& [class*=MuiInputBase-root]": {
      padding: 0
    },
    "& textarea": {
      height: 60,
      fontSize: 14
    }
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  feedbackText: {
    color: '#475867',
    fontSize: 14,
    marginTop: 0,
    fontWeight: 600
  },
  feedbackImg: {
    textAlign: 'center',
    marginTop: 10
  },
});

class FeedbackForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      feedback: "",
      feedback_error: "",
      products: [],
      saveType: 'NEXT',
      nextClickLoaderSave: false,
      nextClickLoader: false
    }
    this.submitFeedback = this.submitFeedback.bind(this);
  }

  componentDidMount() {
    this.setState({
      products: this.props.evaluation?.Products.map((p) => {
        p.id = p.ID
        return p;
      })
    })
    this.props.fetchEvaluationUserFeedback(this.props.match.params.evaluation_id)
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen === true && prevProps.isOpen === false) {
      this.setState({
        products: this.props.evaluation.Products.map((p) => {
          p.id = p.ID
          return p;
        })
      })
      this.props.fetchEvaluationUserFeedback(this.props.match.params.evaluation_id)
    }

    if (this.props.userFeedbackProgress === false && prevProps.userFeedbackProgress === true) {
      if (this.props.userFeedback !== null) {
        var products = this.props.evaluation.Products.map((p) => {
          p.id = p.ID
          return p;
        });
        var productSeq = [];
        if (this.props.userFeedback.ProductSeq !== null) {
          try {
            productSeq = JSON.parse(this.props.userFeedback.ProductSeq)
          } catch { }
        }
        products.sort((a, b) => {
          var aIndex = productSeq.indexOf(a.id);
          var bIndex = productSeq.indexOf(b.id);
          if (aIndex > bIndex) {
            return 1
          } else if (aIndex < bIndex) {
            return -1
          } else {
            return 0;
          }
        });
        this.setState({
          feedback: this.props.userFeedback.Feedback,
          products: products
        })
      }
    }

    if (this.props.addFeedbackProgress === false && prevProps.addFeedbackProgress === true) {
      if (this.props.addFeedbackError === null) {

        var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);
        console.log(nextComponent, 'nextComponent111111')
        if (nextComponent !== null) {
          let redirectPath = `/app/evaluate/${nextComponent.EvaluationId}/workflow/${nextComponent.WorkflowId}/component/${nextComponent.ComponentId}`;
          this.props.history.push(redirectPath);
          return;
        } else {
          var responseDatas = [];
          this.props.workflowComponentsForms.forEach((data) => {
            if (data.IsConfiguration === false) {
              var obj = {
                "form_id": data.ID
              }

              var productIds = this.state.products.map((p) => {
                return p.ID
              });

              if (data.Name === 'evFeedback.product_ranking') {
                obj['value'] = JSON.stringify(productIds);
              } else if (data.Name === 'evFeedback.feedback') {
                obj['value'] = this.state.feedback;
              }

              responseDatas.push(obj);
            }
          })

          this.props.addWorkflowComponentUserResponse(this.props.match.params.evaluation_id, this.props.workflowComponentId, {
            "data": responseDatas,
            "forward": this.state.saveType === 'NEXT' ? true : false
          });

          this.props.showSnackBar("Feedback Added", "success", 3000);
        }

      } else {
        if (this.props.addFeedbackError.message !== undefined) {
          this.setState({
            "error": "Failed to add Feedback Details."
          })
        } else {
          this.setState({
            "error": "Something went wrong."
          })
        }
      }
    }

  }

  submitFeedback(type) {
    this.setState({
      success: "",
      error: "",
      feedback_error: "",
      saveType: type
    });

    var feedback = this.state.feedback;

    var bodyText = document.querySelectorAll("#collaboratorFeedbackRes .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
    if (String(feedback).trim().length === 0 || String(bodyText).trim().length === 0 || String(bodyText).indexOf('Add response here') !== -1) {
      this.setState({
        feedback_error: "Please enter your feedback"
      });
      return
    }

    // if (feedback.trim().length === 0) {
    //   this.setState({
    //     feedback_error: "Please enter your feedback"
    //   });
    //   return;
    // }

    this.setState({
      nextClickLoader: type === 'NEXT' ? true : false,
      nextClickLoaderSave: type === 'SAVE' ? true : false
    }, () => {
      this.props.addEvaluationFeedback(this.props.match.params.evaluation_id, {
        feedback: this.state.feedback,
        productIds: this.state.products.map((p) => {
          return p.ID
        }),
      })
    });
  }

  render() {
    const classes = this.props.classes;

    var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);

    return <>
      <EvaluationBar
        isShowCollabrations={false}
        isShowEvalDetails={false}
        isWorkFlow={this.props.isWorkFlow}
        isShowViewBtns={false}
      />
      <div className={classes.mainRoot}>
        <div className={classes.root} id="evSendNotification">
          <DialogContent classes={{ root: classes.dialogContent }} >
            <div className={classes.feedbackImg} > <img src={Feedback} alt="Product ranking" /></div>
            <div>
              <Typography variant={"h4"} className={classes.title}>Feedback</Typography>
              {/* <Typography variant={"h4"} className={classes.title}>Product ranking and Feedback</Typography> */}
              {false && <p className={classes.subTitle}>Rank products based on your evaluation and provide feedback below to add more context.</p>}
            </div>
            <div className={classes.alerts}>
              {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
              {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
            </div>
            <div className={classes.form}>
              {this.state.products !== null && this.state.products.length > 1 && <div className={classes.nestedComponent}>
                <p className={classes.feedbackText}>Product ranking</p>
                <Nestable
                  items={this.state.products}
                  renderItem={({ item, index }) => {
                    return <div className={classes.dragItem}> {index + 1 || ''}{index > -1 ? '.' : ''} {item.Name} <Image alt="drag" src={DragIcon} /></div>
                  }}
                  maxDepth={1}
                  onChange={(data) => {
                    this.setState({
                      products: data
                    })
                  }}
                />
              </div>}
              <div className={classes.feedbackSection} id="collaboratorFeedbackRes">
                {process.env.REACT_APP_ENV !== 'dev' && <>
                  <p className={classes.feedbackText}>Highlights</p>
                  <p className={classes.subHeader}>1. What worked well</p>
                  <p className={classes.subHeader}>2. What needs improvement</p>
                </>}

                {process.env.REACT_APP_ENV === 'dev' && <>
                  <p className={classes.feedbackText}>Should supplier progress to next stage?</p>
                  <p className={classes.subHeader}>1. Yes</p>
                  <p className={classes.subHeader}>2. No</p>
                  <p className={classes.subHeader}>3. Maybe</p>
                  <p className={classes.subHeader}>Please add additional comments.</p>
                </>}


                <SlateInputField
                  // label="High"
                  error={this.state.feedback_error.length > 0}
                  helperText={this.state.feedback_error}
                  folderPath={`/evaluation/${this.props?.evaluation?.ID}/feedback`}
                  as={SlateInputField}
                  // toolTip="38"
                  onChangeEvent={(value) => {
                    this.setState({ feedback: value, feedback_error: "" });
                  }}
                  initValue={this.state.feedback}
                  textContainerStyle={{ color: "#707070", }} />
              </div>
            </div>
          </DialogContent>
        </div>
      </div>
      <BottomActions
        user={this.props.user}
        isCreatorOrAdmin={this.props.isCreatorOrAdmin}
        adminForward={this.props.adminForward}
        submitResponse={this.submitFeedback}
        nextClickLoaderSave={this.state.nextClickLoaderSave}
        nextClickLoader={this.state.nextClickLoader}
        nextComponent={nextComponent}
      />
    </>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(FeedbackForm));