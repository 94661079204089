/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {UserFactory} from "../../services/user";
import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment"

export const START_VERIFICATION     =   "START_VERIFICATION";
export const END_VERIFICATION       =   "END_VERIFICATION";

export const START_VERIFY_TOKEN = "START_VERIFY_TOKEN";
export const END_VERIFY_TOKEN = "END_VERIFY_TOKEN";

//Action to start the registration
function startVerification(){
    return {
        type:START_VERIFICATION,
        payload:null
    };
}

function endVerification(success,error){
    return {
        type:END_VERIFICATION,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the register
export  function verifyAccount(token,password){
    return async (dispatch) => {
        dispatch(startVerification());
        try{
            let [response,error]    =   await UserFactory.instance().verifyAccount(token,password);
            dispatch(endVerification(response,error));
        } catch(e){
            dispatch(endVerification(null,{
                message:e.message
            }));
            return;
        }
    };
}   


function startVerifyToken() {
    return {
        type: START_VERIFY_TOKEN,
        payload: {}
    };
}

function endVerifyToken(success, error) {
    return {
        type: END_VERIFY_TOKEN,
        payload: {
            success, error
        }
    };
}

export function verifyToken(data = {}) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/verify-to-redirect?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startVerifyToken());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endVerifyToken(response, error));
        } catch (e) {
            dispatch(endVerifyToken(null, {
                message: e.message
            }));
            return;
        }
    };
}