import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import classnames from "classnames";

// components
import Image from 'Components/Common/image.jsx'
import CircleProgress from 'Components/Common/CircleProgress.jsx'
import Response from './Response';
import ChooseAsksellerSelectModal from "../../../../Settings/Templates/Common/ChooseAsksellerSelectModal";

// redux
import { expandEvaluationRequirement } from "redux/evaluation/criteria/action";
import {showEvaluationAsksellerSelectDialog, hideEvaluationAsksellerSelectDialog} from "redux/templates/evaluation/action"
import {showEvaluationProductEditDialog} from "redux/evaluation/cart/action"
// assets
import DefaultImage from "assets/images/evaluation/default.svg"
import AskSeller from "assets/images/ask-seller.svg";
import { CommonFn } from "services/commonFn";

const connectedProps = (state) => ({
    criterias:state.evaludationCriteria.criterias,
    expandedCriteriaId:state.evaludationCriteria.expandedCriteriaId,
    scores:state.evaludationCriteria.scores,
    evaluationProductsList: state.evaluationMetaData.evaluationProductsList,
    dialogEvaluationAskseller:state.templateEvaluation.dialogEvaluationAskseller,
    userReponses:state.evaludationCriteria.userReponses,
    user:state.authUser.user,
});

const connectionActions = {
    showEvaluationAsksellerSelectDialog: showEvaluationAsksellerSelectDialog,
    hideEvaluationAsksellerSelectDialog: hideEvaluationAsksellerSelectDialog,
    showEvaluationProductEditDialog:showEvaluationProductEditDialog,
    expandEvaluationRequirement: expandEvaluationRequirement,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root:{
       width:"450px",
       marginRight:"30px",
       marginTop: 10,
    },
    'colbTitle':{
        fontSize:theme.spacing(2)
    },
    actions:{
        paddingTop:theme.spacing(2)
    },
    productImageContainer:{
        textAlign:"center"
    },
    productImage:{
        display:"inline-block !important",
        height:"30px"
    },
    productTitle:{
        textAlign:"center",
        fontSize:theme.spacing(2.2),
        fontWeight:500,
        color: '#20253A',
        marginTop: 10
    },
    productScore:{
        textAlign:"center",
        fontSize:theme.spacing(2.5),
        fontWeight:600,
        color:"#ccc",
        flex:1,
        alignSelf: "flex-end",
        paddingTop:theme.spacing(2)
    },
    productScoreControl:{
        
    },
    askSellerMultiSelect:{
        textAlign:"center",
        fontSize:theme.spacing(2.5),
        fontWeight:600,
        flex:1,
        cursor:"pointer",
        alignSelf: "flex-end"
    },
    askSellerMultiSelectImage:{
        height:"30px"
    },
    askSellerTitle:{
        textAlign:"center",
        fontSize:theme.spacing(1.5),
        fontWeight:500,
        color: '#20253A',
        marginTop: 10,
        marginBottom:0,
    },
    prodHeading:{
        background:"#fff",
        position: 'relative',
        height:"150px",
    },
    prodHeadingTop:{
        padding:theme.spacing(2),
        height:"100px",
        background:"#fff",
        position: 'relative',
        display:"flex",
    },
    prodHeadingBottom:{
        position: 'absolute',
        bottom:theme.spacing(2),
        left:theme.spacing(2),
        right:theme.spacing(2),
    },
    criteriaTitle:{
        fontSize:theme.spacing(2.1),
        fontWeight:500
    },
    criteriaRequirementCount:{
        fontSize:theme.spacing(2),
        fontWeight:400,
        color:"#ccc",
        textAlign:"right"
    },
    overallScore:{
        fontSize:theme.spacing(2),
        fontWeight:400,
        color:"#ccc",
        textAlign:"center"
    },
    criteria:{
        marginBottom: 15,
        background:"#fff",
        "&.options-hide": {
            opacity: 0.2,
            pointerEvents: 'none'
        }
    },
    activeCriteria: {
        paddingBottom: 18,
    },
    criteriaHead:{
        padding:theme.spacing(3.3, 2),
        borderTop:"solid 1px #EEEEEE",
        borderBottom:"solid 1px #EEEEEE",
        "&.highLight": {
            borderTop:"solid 1px #49B8FA"
        }
    },
    
    expandBottomSpace: {
        height: 66,
        background: "#f7f7f7",
        borderTop:"solid 1px #49B8FA"
    },
    percent: {
        background: '#EBEFF0',
        borderRadius: 15,
        color: '#04363D',
        fontSize: 12,
        padding: 12,
        marginRight: 10,
        fontWeight: 500
    },
    logoContainer:{
        height: '100%',
        textAlign: 'center',
        placeContent: 'center',
        alignItems: 'center',
        flex:4
    }
});

class Product extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            askSellerMulti: false
        }
        this.respondable = this.respondable.bind(this);
    }

    selectAskSeller =(evaluationId,product)=>{
        if(product.VendorEmail !== null && product.VendorEmail.length !== 0){
            if(this.props.dialogEvaluationAskseller === true){
                this.props.hideEvaluationAsksellerSelectDialog()
            }else{
                this.props.showEvaluationAsksellerSelectDialog(evaluationId,product)
            } 
        } else {
            this.props.showEvaluationProductEditDialog(evaluationId,product)
        }

    }

    respondable(obj,product){
        var result = true;
        var userProductResponses = this.props.userReponses[product.ID];
        var requirementAnswerTypeMap = {};
        this.props.criterias.forEach((c)=>{
            c.requirements.forEach((r)=>{
                requirementAnswerTypeMap[r.id] = r.answer_type;
            })
        })
        var response = {};
        for (const [key, value] of Object.entries(requirementAnswerTypeMap)) {
            response[key] = null;
            if(value === 2 || value === 3){
                if(userProductResponses !== undefined && userProductResponses[key] !== undefined){
                    if(userProductResponses[key].UserOptions == null || userProductResponses[key].UserOptions.length === 0 ){
                        continue
                    }
                    if(value === 3){
                        response[key] = userProductResponses[key].UserOptions[0].OptionID
                    } else {
                        response[key] = userProductResponses[key].UserOptions.map((op)=>{
                            return op.OptionID
                        })
                    }
                } 
            } 
          }
        
        try{
            result = eval(obj.js_expression)
        } catch(e){
            result = false
            console.log(e)
        }
        
        return result;
        
    }

    render() {
        const classes   =   this.props.classes;
        var product  =      this.props.product;
        var product_score  =   0;
        if(this.props.scores[product.ID] !== undefined ) {
            var score_obj = this.props.scores[product.ID];
            var totalOpted = 0;
            var totalMax  = 0;
            for(var criteriId in score_obj){
                var criteriScore     = score_obj[criteriId];
                totalOpted += criteriScore.totalOpted;
                totalMax += criteriScore.totalMax;
            }
            if(totalMax !== 0){
                product_score = Math.round(parseFloat((parseFloat(totalOpted)/parseFloat(totalMax))*100))
            }  
                
        }

        var requirementIdCriteriaMap  = {};
        var totalRequirements = 0;
        if(this.props.criterias !== null && this.props.criterias?.length){
            this.props.criterias.forEach((criteria)=>{
                criteria.requirements.forEach((requirement)=>{
                    requirementIdCriteriaMap[requirement.id] = criteria.id;
                    totalRequirements++;
                });
            })
        }
        

        var userResponseCriteriaProductMap = {};
        var productWiseResponsesMap = {};
        if(this.props.userReponses !== null){
            for (const [productId, product_responses] of Object.entries(this.props.userReponses)) {
                if(userResponseCriteriaProductMap[productId] === undefined){
                    userResponseCriteriaProductMap[productId] = {};
                }
                if(productWiseResponsesMap[productId] === undefined){
                    productWiseResponsesMap[productId] = 0;
                }
                for (const [requirementId, pr_responses] of Object.entries(product_responses)) {
                        var criteriaId  =   requirementIdCriteriaMap[requirementId];
                        if(criteriaId !== undefined ){
                            if(userResponseCriteriaProductMap[productId][criteriaId] === undefined){
                                userResponseCriteriaProductMap[productId][criteriaId] = 0;
                            }
                            if(pr_responses.Response !== null && String(pr_responses.Response).trim().length !== 0 &&  pr_responses.Response !== 0){
                                userResponseCriteriaProductMap[productId][criteriaId] = userResponseCriteriaProductMap[productId][criteriaId] + 1;
                                productWiseResponsesMap[productId] = productWiseResponsesMap[productId]+1;
                            }
                        }
                }  
            }
        }
        if(productWiseResponsesMap[product.ID] === undefined){
            productWiseResponsesMap[product.ID] = 0;
        }

        var ruleFailRequirementIds = []; 
        var ruleFailCriteriaIds = [];
        const rulePassRequirementIds = []; 
        const rulePassCriteriaIds = [];

        if(this.props.isRespond) {
            this.props.criterias && this.props.userReponses && this.props.criterias.forEach((criteria) => {
                if(criteria?.rules?.length) {
                    criteria.rules.forEach((rule) => {
                        if(rule?.ActionType === 2) {
                            let pushToRequirementArray = CommonFn.findAnswer(this.props.userReponses, rule, criteria.requirements);

                            if(pushToRequirementArray && ruleFailRequirementIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                ruleFailRequirementIds.push(parseInt(rule?.ActionData));
                            } else if(!pushToRequirementArray && rulePassRequirementIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                rulePassRequirementIds.push(parseInt(rule?.ActionData))
                            }
                        }
                        if(rule?.ActionType === 1) {
                            let pushToCrtieriaArray = CommonFn.findAnswer(this.props.userReponses, rule, criteria.requirements);
                            if(pushToCrtieriaArray && ruleFailCriteriaIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                ruleFailCriteriaIds.push(parseInt(rule?.ActionData));
                            } else if(!pushToCrtieriaArray && rulePassCriteriaIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                rulePassCriteriaIds.push(parseInt(rule?.ActionData))
                            }
                        }
                    });
                }
            });
        }

        ruleFailRequirementIds = ruleFailRequirementIds.filter((item ) => {
            if(rulePassRequirementIds.indexOf(item) > -1) {
                return false;
            }
            return true;
        });

        ruleFailCriteriaIds = ruleFailCriteriaIds.filter((item) => {
            if(rulePassCriteriaIds.indexOf(item) > -1) {
                return false;
            }
            return true;
        });


        const hideAnwserOptionFn = (rule, product, requirements) => {
            let isShow = false;
            const requirementsObj = this.props.userReponses[product.ID];
            if (requirementsObj) {
                for (let requirement in requirementsObj) {
                  const response = { [requirement]: [] };
                  requirementsObj[requirement].UserOptions.map((option) => {
                    const reqObj = requirements.filter(req => req.id === parseInt(requirement));
                    if (reqObj && reqObj[0]?.answer_type === 4) {
                      response[requirement].push(option.Answer);
                    } else {
                      response[requirement].push(option.OptionID);
                    }
                    return option;
                  });
                  try {
                    if (eval(rule.JsExpression)) {
                        isShow = true;
                      break;
                    }
                  } catch (e) {}
                }
              }

            return isShow;
        }

        const hideAllAnwserOptions = (renderCriteria, product) => {
            let isShow = true;
            this.props.criterias && this.props.criterias.forEach((criteria) => {
                for( let i = 0; i < criteria.rules.length; i++ ) {
                    let rule = criteria.rules[i];
                    if(renderCriteria.id === parseInt(rule.ActionData)) {
                        isShow = false;
                        if(hideAnwserOptionFn(rule, product, criteria.requirements)) {
                            isShow = true;
                            break;
                        }
                    }
                }
            });
            return isShow;
        }

        const criterias = this.props.criterias && this.props.criterias.map((criteria) => {
                criteria['hide'] = false;
                if(ruleFailCriteriaIds.indexOf(criteria.id) > -1) {
                    criteria['hide'] = true;
                }
                criteria.requirements.map((requirement) => {
                    requirement['hide'] = false;
                    if(ruleFailRequirementIds.indexOf(requirement.id) > -1) {
                        requirement['hide'] = true;
                    }
                });
            return criteria;
        });

        return <div className={classes.root}>
                    <div className={classes.prodHeading}>
                        <div className={classes.prodHeadingTop}>
                            <div className={classes.askSellerMultiSelect} onClick={()=>{
                                    let evaluation_id = parseInt(this.props?.match?.params.evaluation_id)
                                    this.selectAskSeller(evaluation_id,product)
                                }}>
                                <Image src={AskSeller} className={classes.askSellerMultiSelectImage}  />
                                <Typography className={classes.askSellerTitle}>Ask Vendor</Typography>
                            </div>
                            <Grid className={classes.logoContainer} container alignItems={'center'}>
                                <Grid item lg={12}>
                                    <div className={classes.productImageContainer}>
                                        <Image alt="" src={product.ProductLogo == null || product.ProductLogo.length === 0?DefaultImage:product.ProductLogo} className={classes.productImage}/>
                                    </div>
                                </Grid>
                                <Grid item lg={12}>
                                    <Typography className={classes.productTitle}>{product.Name}</Typography>
                                </Grid>
                            </Grid>
                            <div className={classes.productScore}>
                                <CircleProgress className={classes.productScoreControl} value={Math.floor((productWiseResponsesMap[product.ID]/totalRequirements)*100)} label={Math.floor((productWiseResponsesMap[product.ID]/totalRequirements)*100)+'%'} width={70} height={70} />
                                {/* <CircleProgress className={classes.productScoreControl} value={Math.floor((productWiseResponsesMap[product.ID]/totalRequirements)*100)} label={`${productWiseResponsesMap[product.ID]}/${totalRequirements}`} width={70} height={70} /> */}
                            </div>
                        </div>
                        <div className={classes.prodHeadingBottom}>
                            {/* <LinearProgress value={product_score} /> */}
                            <Typography className={classes.overallScore}>
                                <span className={classes.percent}>Overall Score {product_score}%</span>
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.criterias}>
                        {
                           criterias !== null && criterias.map((criteria,k)=>{
                                
                                if(criteria.hide) {
                                    return null;
                                }
                               
                                var crespondable = true
                                
                                crespondable = hideAllAnwserOptions(criteria, product);
                                var criteria_score  =   0;
                                if(this.props.scores[product.ID] !== undefined 
                                    && this.props.scores[product.ID][criteria.id] !== undefined) {
                                        var score_obj = this.props.scores[product.ID][criteria.id];
                                        criteria_score = parseFloat((parseFloat(score_obj.totalOpted)/parseFloat(score_obj.totalMax))*100).toFixed(1)
                                    }
                                return <div className={classnames(classes.criteria, crespondable ? 'options-show': 'options-hide', this.props.expandedCriteriaId === criteria.id ? classes.activeCriteria : {} )} key={k}>
                                    <div className={classnames(classes.criteriaHead, this.props.expandedCriteriaId === criteria.id? 'highLight': '')}>
                                        <Grid container>
                                            <Grid item lg={10}>
                                                {/* <Typography className={classes.criteriaTitle}>{criteria.name} ({(userResponseCriteriaProductMap[product.ID] === undefined || userResponseCriteriaProductMap[product.ID][criteria.id] === undefined)?0:userResponseCriteriaProductMap[product.ID][criteria.id]}/{criteria.requirements.length})</Typography> */}
                                                <Typography className={classes.criteriaTitle}> {criteria.name} &nbsp;
                                                ({(userResponseCriteriaProductMap[product.ID] === undefined || userResponseCriteriaProductMap[product.ID][criteria.id] === undefined) ? '0% Completed' : Math.round(userResponseCriteriaProductMap[product.ID][criteria.id] / criteria.requirements.length * 100) + "% Completed"}) </Typography>
                                            </Grid>
                                            <Grid item lg={2}>
                                                <Typography className={classes.criteriaRequirementCount}>
                                                   <span className={classes.percent}> {criteria_score}%</span>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {this.props.expandedCriteriaId === criteria.id && <div className={classes.productResponseSection}>
                                        {
                                            criteria.requirements !== null && criteria.requirements.map((requirement,j)=>{
                                                var rrespondable = true;
                                                if(requirement.hide) {
                                                    return null;
                                                }
                                                criteria.rules.map((rule) => {
                                                    if(requirement.id === parseInt(rule.ActionData)) {
                                                        rrespondable = hideAnwserOptionFn(rule, product, criteria.requirements);
                                                    }
                                                })  
                                                return ( 
                                                    <Response 
                                                        key={j} 
                                                        product={product} 
                                                        index={j} 
                                                        expand={this.props.expandEvaluationRequirement} 
                                                        respondable={rrespondable} 
                                                        requirement={requirement} 
                                                        criteria={criteria} 
                                                    />
                                                )
                                            })
                                        }
                                    </div>}
                                </div>
                            })
                        }
                    </div>
                    <ChooseAsksellerSelectModal />
                </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Product));