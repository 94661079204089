import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment"

export const SHOW_ADD_ALERT_DIALOG = "SHOW_ADD_ALERT_DIALOG";
export const HIDE_ADD_ALERT_DIALOG = "HIDE_ADD_ALERT_DIALOG";

export const START_ADD_NEW_ALERT = "START_ADD_NEW_ALERT";
export const END_ADD_NEW_ALERT = "END_ADD_NEW_ALERT";

export const START_UPDATE_ALERT = "START_UPDATE_ALERT";
export const END_UPDATE_ALERT = "END_UPDATE_ALERT";

export const START_FETCH_ALERTS = "START_FETCH_ALERTS";
export const END_FETCH_ALERTS = "END_FETCH_ALERTS";

export const START_DELETE_ALERT = "START_DELETE_ALERT";
export const END_DELETE_ALERT = "END_DELETE_ALERT";

export const START_FETCH_ALERT_DATA="START_FETCH_ALERT_DATA";
export const END_FETCH_ALERT_DATA="END_FETCH_ALERT_DATA";

export const START_ADD_NEW_ALERT_TEAM = "START_ADD_NEW_ALERT_TEAM";
export const END_ADD_NEW_ALERT_TEAM = "END_ADD_NEW_ALERT_TEAM";

export const START_FETCH_ALERT_TEAMS = "START_FETCH_ALERT_TEAMS";
export const END_FETCH_ALERT_TEAMS = "END_FETCH_ALERT_TEAMS";

export const START_DELETE_ALERT_TEAM = "START_DELETE_ALERT_TEAM";
export const END_DELETE_ALERT_TEAM = "END_DELETE_ALERT_TEAM";

export const START_ADD_NEW_ALERT_USER = "START_ADD_NEW_ALERT_USER";
export const END_ADD_NEW_ALERT_USER = "END_ADD_NEW_ALERT_USER";

export const START_FETCH_ALERT_USERS = "START_FETCH_ALERT_USERS";
export const END_FETCH_ALERT_USERS = "END_FETCH_ALERT_USERS";

export const START_DELETE_ALERT_USER = "START_DELETE_ALERT_USER";
export const END_DELETE_ALERT_USER = "END_DELETE_ALERT_USER";

export const START_ADD_NEW_ALERT_EMAIL = "START_ADD_NEW_ALERT_EMAIL";
export const END_ADD_NEW_ALERT_EMAIL = "END_ADD_NEW_ALERT_EMAIL";

export const START_FETCH_ALERT_EMAILS = "START_FETCH_ALERT_EMAILS";
export const END_FETCH_ALERT_EMAILS = "END_FETCH_ALERT_EMAILS";

export const START_DELETE_ALERT_EMAIL = "START_DELETE_ALERT_EMAIL";
export const END_DELETE_ALERT_EMAIL = "END_DELETE_ALERT_EMAIL";

export const START_ADD_NEW_ALERT_RULE = "START_ADD_NEW_ALERT_RULE"
export const END_ADD_NEW_ALERT_RULE = "END_ADD_NEW_ALERT_RULE"

export const START_UPDATE_ALERT_RULE = "START_UPDATE_ALERT_RULE"
export const END_UPDATE_ALERT_RULE = "END_UPDATE_ALERT_RULE"

export const START_FETCH_ALERT_RULES = "START_FETCH_ALERT_RULES"
export const END_FETCH_ALERT_RULES = "END_FETCH_ALERT_RULES"

export const START_DELETE_ALERT_RULE="START_DELETE_ALERT_RULE"
export const END_DELETE_ALERT_RULE="END_DELETE_ALERT_RULE"

export function showAddAlertDialog() {
    return {
        type: SHOW_ADD_ALERT_DIALOG,
        payload: {}
    };
}

export function hideAddAlertDialog() {
    return {
        type: HIDE_ADD_ALERT_DIALOG,
        payload: {}
    };
}

function startAddNewAlert() {
    return {
        type: START_ADD_NEW_ALERT,
        payload: {}
    };
}

function endAddNewAlert(success, error) {
    return {
        type: END_ADD_NEW_ALERT,
        payload: {
            success, error
        }
    };
}

export function addNewAlert(data = {}) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/add?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewAlert());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewAlert(response, error));
        } catch (e) {
            dispatch(endAddNewAlert(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateAlert() {
    return {
        type: START_UPDATE_ALERT,
        payload: {}
    };
}

function endUpdateAlert(success, error) {
    return {
        type: END_UPDATE_ALERT,
        payload: {
            success, error
        }
    };
}

export function updateAlert(alertId,data) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateAlert());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateAlert(response, error));
        } catch (e) {
            dispatch(endUpdateAlert(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchAlerts() {
    return {
        type: START_FETCH_ALERTS,
        payload: {}
    };
}

function endFetchAlerts(success, error) {
    return {
        type: END_FETCH_ALERTS,
        payload: {
            success, error
        }
    };
}

export function fetchAlerts(queryParam) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/fetch?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAlerts());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, queryParam, false);
            dispatch(endFetchAlerts(response, error));
        } catch (e) {
            dispatch(endFetchAlerts(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteAlert() {
    return {
        type: START_DELETE_ALERT,
        payload: null
    };
}

function endDeleteAlert(alertId, success, error) {
    return {
        type: END_DELETE_ALERT,
        payload: {
            success, error, alertId
        }
    };
}

export function deleteAlert(alertId) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteAlert());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteAlert(alertId,response, error));
        } catch (e) {
            dispatch(endDeleteAlert(alertId,null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchAlertData() {
    return {
        type: START_FETCH_ALERT_DATA,
        payload: {}
    };
}

function endFetchAlertData(success, error) {
    return {
        type: END_FETCH_ALERT_DATA,
        payload: {
            success, error
        }
    };
}

export function fetchAlert(alertId) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAlertData());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAlertData(response, error));
        } catch (e) {
            dispatch(endFetchAlertData(null, {
                message: e.message
            }));
            return;
        }
    };
} 

// Teams Add, Delete, Get for alert
function startAddAlertTeam() {
    return {
        type: START_ADD_NEW_ALERT_TEAM,
        payload: {}
    };
}

function endAddAlertTeam(success, error) {
    return {
        type: END_ADD_NEW_ALERT_TEAM,
        payload: {
            success, error
        }
    };
}

export function addAlertTeam(alertId,teamId,data = {}) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/team/${teamId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddAlertTeam());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url);
            dispatch(endAddAlertTeam(response, error));
        } catch (e) {
            dispatch(endAddAlertTeam(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchAlertTeams() {
    return {
        type: START_FETCH_ALERT_TEAMS,
        payload: {}
    };
}

function endFetchAlertTeams(success, error) {
    return {
        type: END_FETCH_ALERT_TEAMS,
        payload: {
            success, error
        }
    };
}

export function fetchAlertTeams(alertId) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/teams?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAlertTeams());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAlertTeams(response, error));
        } catch (e) {
            dispatch(endFetchAlertTeams(null, {
                message: e.message
            }));
            return;
        }
    };
} 

function startDeleteAlertTeam() {
    return {
        type: START_DELETE_ALERT_TEAM,
        payload: null
    };
}

function endDeleteAlertTeam(alertId, success, error) {
    return {
        type: END_DELETE_ALERT_TEAM,
        payload: {
            success, error, alertId
        }
    };
}

export function deleteAlertTeam(alertId,teamId) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/team/${teamId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteAlertTeam());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteAlertTeam(alertId,response, error));
        } catch (e) {
            dispatch(endDeleteAlertTeam(alertId,null, null, {
                message: e.message
            }));
            return;
        }
    };
}


// Custom email Add, Delete, Get for alert
function startAddAlertEmail() {
    return {
        type: START_ADD_NEW_ALERT_EMAIL,
        payload: {}
    };
}

function endAddAlertEmail(success, error) {
    return {
        type: END_ADD_NEW_ALERT_EMAIL,
        payload: {
            success, error
        }
    };
}

export function addAlertEmail(alertId,data = {}) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/emails?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddAlertEmail());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddAlertEmail(response, error));
        } catch (e) {
            dispatch(endAddAlertEmail(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchAlertEmails() {
    return {
        type: START_FETCH_ALERT_EMAILS,
        payload: {}
    };
}

function endFetchAlertEmails(success, error) {
    return {
        type: END_FETCH_ALERT_EMAILS,
        payload: {
            success, error
        }
    };
}

export function fetchAlertEmails(alertId) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/emails?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAlertEmails());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAlertEmails(response, error));
        } catch (e) {
            dispatch(endFetchAlertEmails(null, {
                message: e.message
            }));
            return;
        }
    };
} 

function startDeleteAlertEmail() {
    return {
        type: START_DELETE_ALERT_EMAIL,
        payload: null
    };
}

function endDeleteAlertEmail(emailId, success, error) {
    return {
        type: END_DELETE_ALERT_EMAIL,
        payload: {
            success, error, emailId
        }
    };
}

export function deleteAlertEmail(alertId,emailId) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/emails/${emailId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteAlertEmail());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteAlertEmail(emailId,response, error));
        } catch (e) {
            dispatch(endDeleteAlertEmail(emailId,null, null, {
                message: e.message
            }));
            return;
        }
    };
}


// User Add, Delete, Get for alert
function startAddAlertUser() {
    return {
        type: START_ADD_NEW_ALERT_USER,
        payload: {}
    };
}

function endAddAlertUser(success, error) {
    return {
        type: END_ADD_NEW_ALERT_USER,
        payload: {
            success, error
        }
    };
}

export function addAlertUser(alertId,userId,data = {}) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/user/${userId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddAlertUser());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddAlertUser(response, error));
        } catch (e) {
            dispatch(endAddAlertUser(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchAlertUsers() {
    return {
        type: START_FETCH_ALERT_USERS,
        payload: {}
    };
}

function endFetchAlertUsers(success, error) {
    return {
        type: END_FETCH_ALERT_USERS,
        payload: {
            success, error
        }
    };
}

export function fetchAlertUsers(userId) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${userId}/users?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAlertUsers());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAlertUsers(response, error));
        } catch (e) {
            dispatch(endFetchAlertUsers(null, {
                message: e.message
            }));
            return;
        }
    };
} 

function startDeleteAlertUser() {
    return {
        type: START_DELETE_ALERT_USER,
        payload: null
    };
}

function endDeleteAlertUser(userId, success, error) {
    return {
        type: END_DELETE_ALERT_USER,
        payload: {
            success, error, userId
        }
    };
}

export function deleteAlertUser(alertId,userId) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/user/${userId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteAlertUser());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteAlertUser(userId,response, error));
        } catch (e) {
            dispatch(endDeleteAlertUser(userId,null, null, {
                message: e.message
            }));
            return;
        }
    };
}

// Alert Rules APIs
function startAddNewAlertRule() {
    return {
        type: START_ADD_NEW_ALERT_RULE,
        payload: {}
    };
}

function endAddNewAlertRule(success, error) {
    return {
        type: END_ADD_NEW_ALERT_RULE,
        payload: {
            success, error
        }
    };
}

export function addNewAlertRule(alertId,data = {}) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/rule?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewAlertRule());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewAlertRule(response, error));
        } catch (e) {
            dispatch(endAddNewAlertRule(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateAlertRule() {
    return {
        type: START_UPDATE_ALERT_RULE,
        payload: {}
    };
}

function endUpdateAlertRule(success, error) {
    return {
        type: END_UPDATE_ALERT_RULE,
        payload: {
            success, error
        }
    };
}

export function updateAlertRule(alertId,ruleId,data) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/rule/${ruleId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateAlertRule());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateAlertRule(response, error));
        } catch (e) {
            dispatch(endUpdateAlertRule(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchAlertRules() {
    return {
        type: START_FETCH_ALERT_RULES,
        payload: {}
    };
}

function endFetchAlertRules(success, error) {
    return {
        type: END_FETCH_ALERT_RULES,
        payload: {
            success, error
        }
    };
}

export function fetchAlertRules(alertId,queryParam) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/rules?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAlertRules());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url, queryParam, false);
            dispatch(endFetchAlertRules(response, error));
        } catch (e) {
            dispatch(endFetchAlertRules(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteAlertRule() {
    return {
        type: START_DELETE_ALERT_RULE,
        payload: null
    };
}

function endDeleteAlertRule(alertId, success, error) {
    return {
        type: END_DELETE_ALERT_RULE,
        payload: {
            success, error, alertId
        }
    };
}

export function deleteAlertRule(alertId,ruleId) {
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/alert/${alertId}/rule/${ruleId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteAlertRule());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteAlertRule(alertId,response, error));
        } catch (e) {
            dispatch(endDeleteAlertRule(alertId,null, null, {
                message: e.message
            }));
            return;
        }
    };
}