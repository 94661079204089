import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "../Style/index.css";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from '@material-ui/icons/Close';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import LoopIcon from '@material-ui/icons/Loop';
import PhoneIcon from '@material-ui/icons/Phone';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import CallMissedIcon from '@material-ui/icons/CallMissed';
import { getComponentType, getTargetComponentId } from "./FlowData"
import {
  addNewAutomationAction
} from 'redux/automations/action';
const connectedProps = (state) => ({
  automationConnections: state.automation.actionConnections,
});

const connectionActions = {
  addNewAutomationAction: addNewAutomationAction
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  triggerNode: {
    border: '1px solid #222'
  }
});

class SubMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMenu: false
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  onConnect = (params) => {
    console.log('handle onConnect', params)
  };

  hideShowMenu(state) {
    this.setState({
      isShowMenu: state
    })
  }

  handleActionTypeClick(component_type) {
    let componentData = getComponentType(component_type)
    const connectionId = getTargetComponentId(this.props.forComponent.id, this.props.automationConnections)
    // console.log('----------------------------------')
    // console.log(this.props.forComponent,'forComponent')
    // console.log(this.props.automationConnections,'automationConnections')
    // console.log(componentData,'componentData')
    // console.log(connectionId,'connectionId')
    const params = {
      ...componentData
    }
    if (this.props.forComponent.id === connectionId) {
      params['sourceComponentID'] = connectionId
    } else {
      params['connectionID'] = connectionId
    }
    this.props.addNewAutomationAction(this.props.forComponent?.object?.AutomationID, params)
    this.hideShowMenu(false)
  }

  addNewComponent = (component_type) => {
    console.log(component_type, 'Type')
    console.log(this.props.forComponent, 'TyforComponentpe')
    let componentData = getComponentType(component_type)
    console.log(componentData, 'TyforComponentpe')

    // const { automationComponents } = this.props;
    // const { automationDetails } = this.state;
    // console.log(automationComponents, 'automationComponents')
    // const params = {
    //   ...componentData,
    //   sourceComponentID: automationComponents[automationComponents.length - 1].ID
    // }
    // this.props.addNewAutomationAction(automationDetails?.ID, params)
  }


  render() {
    const { from } = this.props;
    const { isShowMenu } = this.state;

    let divClass = 'straight-arrow__button-container';
    let divStyle = { top: 18 }
    if (from && from === 'subTreeEnd') {
      divClass = 'subtree-end-arrow__button-container subtree-end-arrow__button-container_inner';
      divStyle = {}
    }


    return (
      <>
        {!isShowMenu && <div class={divClass}>
          <div class="recipe-step-marker">
            <div class="recipe-step-marker__inner" style={divStyle}>
              <div class="recipe-step-marker__line recipe-step-marker__line_before">
              </div>
              <div class="recipe-step-marker__buttons">
                <button type="button" wtooltip="Add step" wtooltipclass="recipe-step-marker__overlay"
                  class="recipe-step-marker__button recipe-step-marker__button_add">
                  <span class="recipe-step-marker__button-inner">
                    <AddIcon
                      onClick={() => {
                        this.hideShowMenu(true)
                      }}
                    />
                  </span>
                </button>
              </div>
              {<div class="recipe-step-marker__line recipe-step-marker__line_after"></div>}
            </div>
          </div>
        </div>}

        {isShowMenu && <div role="menu" class="add-step__container" >
          <div class="add-step__header ">What happens next?</div>
          <div class="add-step__content ">
            <div data-leave-actions-controls="" class="add-step__controls ">
              <button type="button" role="menuitem" class="add-step__control"
                onClick={() => {
                  this.handleActionTypeClick('ACTION')
                }}
              >
                <span class="add-step__icon-wrapper ">
                  <SystemUpdateAltIcon />
                </span>
                <span class="add-step__name"> Action in an app </span>
              </button>
              <button type="button" role="menuitem" class="add-step__control"
                onClick={() => {
                  this.handleActionTypeClick('IF')
                }}>
                <span class="add-step__icon-wrapper ">
                  <CallMissedIcon />
                </span>
                <span class="add-step__name"> IF condition </span>
              </button>
              <button type="button" role="menuitem" class="add-step__control"
                onClick={() => {
                  this.handleActionTypeClick('IF_ELSE')
                }}>
                <span class="add-step__icon-wrapper ">
                  <CallSplitIcon />
                </span>
                <span class="add-step__name"> IF/ELSE condition </span>
              </button>
              {<button type="button" role="menuitem" class="add-step__control"
                onClick={() => {
                  this.handleActionTypeClick('LOOP')
                }}>
                <span class="add-step__icon-wrapper ">
                  <LoopIcon />
                </span>
                <span class="add-step__name"> Repeat action </span>
              </button>}
              {false && <button type="button" role="menuitem" class="add-step__control"
                onClick={() => {
                  this.handleActionTypeClick('FUNCTION_CALL')
                }}>
                <span class="add-step__icon-wrapper ">
                  <PhoneIcon />
                </span>
                <span class="add-step__name"> Call function </span>
              </button>}
            </div>
            <button type="button" aria-label="Close" class="add-step__close " onClick={() => {
              this.hideShowMenu(false)
            }}>
              <CloseIcon />
            </button>
          </div>
        </div>}
      </>
    );
  }
}

SubMenu.defaultProps = {
  label: null,
  isShowAddOption: true
};

export default connector(compose(
  withRouter,
  withStyles(styles)
)(SubMenu));
