import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog,Button, DialogContent,Paper, Typography, Slide } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Alert from '@material-ui/lab/Alert';

import IconButton from '@material-ui/core/IconButton';
// import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from 'react-router-dom';
import SqureButton from 'Components/Common/SqureButton';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import * as Validator from "util/Validation";
import { showSnackBar } from "redux/snackbar/action";
import { showAddCustomEmailPoperDialog, hideAddCustomEmailPoperDialog } from "redux/dialogs/action";
import deleteIcon from "assets/images/delete.svg";
import Image from "Components/Common/image.jsx";
import DoneIcon from "@material-ui/icons/Done";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.dialogs.isAddCustomEmailPoperDialog,
});

const connectionActions = {
    showAddCustomEmailPoperDialog: showAddCustomEmailPoperDialog,
    hideAddCustomEmailPoperDialog: hideAddCustomEmailPoperDialog,
    showSnackBar: showSnackBar,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    modalTitle: {
        fontSize: theme.spacing(2.5),
        marginBottom: theme.spacing(4),
    },
    actions: {
        float: 'right',
        marginBottom: 20
    },
    alert: {
        marginBottom: theme.spacing(1.6),
    },
    tickMark: {
        color: "#4b86f8",
        flex: '15%',
        border: "1px solid #4b86f8",
        marginRight: 10,
        padding: '10px 0px',
        minWidth: "50px !important",
        "&:hover": {
          color: "#4b86f8",
        },
    },
    emailContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 20,
        justifyContent: 'space-evenly'
    }
});

class AddCustomEmailDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            emails: [],
            email: ''
        }

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
    }

    addEmail = () => {
        this.setState({
            error: "",
        })
        var email = this.state.email;
        if (String(email).trim().length === 0) {
            this.setState({
                error: "Please enter email."
            })
            return
        }

        if (Validator.validateEmail(String(email).trim()) === false) {
            this.setState({ error: "Please provide valid email." });
            return;
        }
        let emails = [...this.state.emails];
        emails.push(email);
        this.setState({ emails: emails, email: '' });
    }

    removeEmail = (index) => {
        let emails = [...this.state.emails]
        emails.splice(index, 1);
        this.setState({ emails: emails });
    }

    onSubmit = () => {
        if(this.state.emails.length > 0) {
            this.props.onAdd(this.state.emails);
            this.props.hideAddCustomEmailPoperDialog();
        }else{
            this.setState({
                error: "Please enter email."
            })
        }
    }

    render() {
        const classes = this.props.classes;
        return <Dialog
            onClose={this.props.hideAddCustomEmailPoperDialog}
            aria-labelledby="app-emailComponent"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
        >
            <DialogContent classes={{ root: classes.dialogAction }} >
                <div id="addCustomEmailDialog">
                    <Typography variant={"h2"} className={classes.modalTitle}>Add Custom Emails</Typography>
                    <div className={classes.alert}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                    </div>
                    <div className={classes.emailContainer}>
                        <OutlinedInput
                            label="Email"
                            value={this.state.email}
                            required={true}
                            style={{flex: '80%'}}
                            autoFocus={true}
                            onChange={(event) => {
                                this.setState({
                                    email : event.target.value
                                })
                            }}
                        />
                        <Button
                            className={classes.tickMark}
                            onClick={this.addEmail}
                            >
                            <DoneIcon />
                            </Button>
                    </div>
                       
                    <List style={{marginBottom: 20}}>
                        {this.state.emails.map((email, i)=>(
                            <Paper key={i} style={{marginBottom: 5}}>
                                <ListItem>
                                    <ListItemText primary={email}/>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" onClick={() => this.removeEmail(i)}>
                                            <Image alt="Delete" src={deleteIcon} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Paper>
                        ))}
                    </List>
                    <div className={classes.actions}>
                        <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideAddCustomEmailPoperDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                        <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.onSubmit}>Done</SqureButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AddCustomEmailDialog));