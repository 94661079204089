import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slide, Typography, Button, FormControl, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import { CommonFn } from "../../../../../../../services/commonFn";
import CommonCss from "../../../../../../../commonCss";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { IsExistingProduct } from "Components/Common/SelectProductOption";
import Select from 'react-select';
// Redux
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import { fetchAddOrgProduct } from "redux/product/orgProduct/action";
import { fetchProductSubCategory } from "redux/product/subcategory/action";
import { searchUser } from "redux/usersearch/action";
import { fetchRecommendedProductsToAdd } from "redux/product/action";
import { showSnackBar } from "redux/snackbar/action";
import { resetAddOrgProductData } from "redux/product/orgProduct/action";
import { addOrgVendor } from "redux/vendor/vendorCatalog/action";

import CreatableSelect from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import { hideEvaluationProductSelectionDialog } from "redux/evaluation/metadata/action";
import { hideAddVendorDialog } from "redux/vendor/vendorCatalog/action";

import { createEvaluationCart } from "redux/evaluation/cart/action";
import { updateEvaluationMetaData } from "redux/evaluation/metadata/action";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import { startNewWorkflow } from 'redux/workflow/action';
import CloseIcon from '@material-ui/icons/Close';

// env
import * as Environment from "util/Environment";
import { isEnabledEvaluate, getConfigValue } from "util/Common";

const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);
const SelectAsyncPaginate = withAsyncPaginate(Select);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  user: state.authUser.user,
  isOpen: state.evaluationMetaData.showEvaluationProductSelectionDialog,
  addProductInitQuery: state.productsSettings.addProductInitQuery,

  subCategories: state.productSubCategory.subCategories,
  subCategoriesFetchProgress: state.productSubCategory.fetchSubCategoryProgress,

  ProductChampUserProgress: state.userSearch.searchProgress,
  ProductChampUsers: state.userSearch.users,

  products: state.products.recommendedProductsToAdd,
  addOrgProductProgress: state.orgProduct.addOrgProductProgress,
  addorgProductStatus: state.orgProduct.addorgProductStatus,
  addorgProductCatsStatus: state.orgProduct.addorgProductCatsStatus,
  addorgProductChampStatus: state.orgProduct.addorgProductChampStatus,
  addorgProductStatusError: state.orgProduct.addorgProductStatusError,
  fetchRecommendedProductsToAddProgress: state.products.fetchRecommendedProductsToAddProgress,
  cartCreationProgress: state.evaluationCart.cartCreationProgress,
  evaluationCart: state.evaluationCart.evaluation,
  updateProgress: state.evaluationMetaData.updateProgress,
  updateError: state.evaluationMetaData.errorUpdate,
  fetchExpentDirectoryProductsProgress: state.expentDirectory.fetchExpentDirectoryProductsProgress,
  fetchExpentDirectoryProductsError: state.expentDirectory.fetchExpentDirectoryProductsError,
  expentDirectoryProducts: state.expentDirectory.products,
  startNewWorkflowProgress: state.workflow.startNewWorkflowProgress,
  startNewWorkflowError: state.workflow.startNewWorkflowError,
  workflowData: state.workflow.workflowData,

  orgUser: state.orgUser,
  orgVendorAddProgress: state.vendorCatalog.orgVendorAddProgress,
  orgVendorAddError: state.vendorCatalog.orgVendorAddError,
  orgVendorAddSuccess: state.vendorCatalog.orgVendorAddSuccess,

});

const connectionActions = {
  showSnackBar: showSnackBar,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  hideEvaluationProductSelectionDialog: hideEvaluationProductSelectionDialog,
  fetchAddOrgProduct: fetchAddOrgProduct,
  fetchProductSubCategory: fetchProductSubCategory,
  searchProductChampUser: searchUser,
  fetchRecommendedProducts: fetchRecommendedProductsToAdd,
  resetAddOrgProductData: resetAddOrgProductData,
  createEvaluationCart: createEvaluationCart,
  updateDetails: updateEvaluationMetaData,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  startNewWorkflow: startNewWorkflow,
  hideAddVendorDialog: hideAddVendorDialog,
  addOrgVendor: addOrgVendor,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    header: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: " center space-between",
      alignItems: "center",
    },
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginBottom: theme.spacing(2.5),
    },
    addProductSection: {
      display: "flex",
      border: "1px solid #F2F2F2",
      padding: 50,
      margin: 20,
    },
    mainForm: {
      border: "1px solid #F2F2F2",
      padding: 50,
      margin: 20,
    },
    productImage: {
      width: 200,
      height: 200,
      border: "1px solid #AAAAAA",
      borderRadius: 11,
    },
    subTitle: {
      color: "#282D30",
      fontSize: 16,
    },
    formControl: CommonCss.formControl,
    label: CommonCss.label,
    formGroup: CommonCss.formGroup,
    rightSection: {
      flex: 1,
      marginLeft: 60,
      marginRight: 30,
    },
    formDivide: {
      display: "flex",
    },
    groupFlexLeft: {
      marginRight: 10,
      flex: 1,
    },
    groupFlexRight: {
      marginLeft: 10,
      flex: 1,
    },
    buttonSection: {
      textAlign: "center",
      margin: 20,
    },
    button: {},
    arrow: {
      paddingLeft: 5,
    },
    uploadImageLabel: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    uploadImage: {
      width: 0,
      overflow: "hidden",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: 11,
    },
    deleteSection: {
      position: "absolute",
      background: "#fff",
      borderRadius: 11,
      padding: "2px 2px 0px 2px",
      top: 0,
      right: 0,
      cursor: "pointer",
    },
    delete: {
      fill: "#ff1200",
    },
    mandatory: CommonCss.mandatory,
    searchAndSelect: {
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
      },

      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    otherDetails: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 10px #398AF51A",
      border: "1px solid #398AF5",
      borderRadius: "5px",
    },
    close: {
      position: "absolute",
      right: 15,
      top: 15,
      cursor: "pointer",
      color: "#6F6F6F",
      "& svg": {
        stroke: "white",
        fontSize: "24px",
      },
    },
    divider: {
      color: "#4b86ff",
    },
    customTypeFields: {
      // marginBottom: 15,
      // marginTop: 15,
      '& [class*="MuiFormControl-root"]': {
        width: '100%'
      },
      '& [class*="MuiInputBase-input"]': {
        textAlign: 'left'
      }
    },
  });

class ProductSelectionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      error: "",
      imageUrl: "",
      internalTool: false,
      formModel: null,
      isWorkflowEvaluation: false,
      isRequestType: true,
      requestTypes: [],
      requestTypeValue: null,
      requestTypeError: '',
      selectedProduct: [],
      selectedProductError: '',
      evaluationProducts: [],
      totalCreateProducts: 0,
      totalProductAdded: 0,
      newProducts: [],
      isAddingProducts: false,
      selectedVendorType: null,
      selectedVendorTypeError: '',
      vendorTypeOptions: [{
        label: 'Vendors',
        value: 'MASTER_VENDOR'
      }, {
        label: 'Products',
        value: 'PRODUCT'
      }, {
        label: 'Services',
        value: 'MASTER_VENDOR_SERVICE'
      }],
      isShowVendorSelection: true
    };
    this.submitForm = this.submitForm.bind(this)
    this.getOptionVendors = this.getOptionVendors.bind(this);
  }

  componentDidMount() {
    this.initialSettings()

  }



  componentDidUpdate(prevProps) {
    if (this.props.cartCreationProgress === false && prevProps.cartCreationProgress === true) {
      // console.log(this.props.evaluationCart, 'Evaluationn');
      if (this.props.evaluationCart !== null) {
        this.setState(
          {
            isWorkflowEvaluation: true,
          },
          () => {
            this.updateDetails(this.props.evaluationCart.ID);
          }
        );
      }
    }

    if (this.props.updateProgress === false && prevProps.updateProgress === true) {
      if (this.props.updateError === null && this.state.isWorkflowEvaluation === true) {
        this.props.hideEvaluationProductSelectionDialog();
        this.props.hideAddVendorDialog();
        this.props.history.push(`/app/evaluate/${this.props.evaluationCart.ID}/workflow/interim`);
      }
    }

    if (this.props.startNewWorkflowProgress === false && prevProps.startNewWorkflowProgress === true) {
      if (this.props.startNewWorkflowError === null) {
        this.props.hideEvaluationProductSelectionDialog();
        this.props.hideAddVendorDialog();
        this.props.history.push(`/app/evaluate/${this.props.workflowData.ID}/workflow/interim`);
      } else {
        this.setState({
          error: 'Something went wrong'
        })
      }
    }

    if (this.props.addOrgProductProgress === false && prevProps.addOrgProductProgress === true) {
      let { totalProductAdded, totalCreateProducts, newProducts } = this.state;
      let evaluationProducts = [...this.state.evaluationProducts];
      totalProductAdded++;
      evaluationProducts.push({
        name: this.props.addorgProductStatus?.orgProduct?.name,
        value: this.props.addorgProductStatus?.orgProduct?.productID,
        id: this.props.addorgProductStatus?.orgProduct?.productID,
      })
      this.setState({
        evaluationProducts: evaluationProducts,
        totalProductAdded: totalProductAdded
      }, () => {
        if (totalProductAdded === totalCreateProducts) {
          this.startWorkflow()
        } else {
          this.createProducts(newProducts[totalProductAdded])
        }
      })
    }

    if (this.props.orgVendorAddProgress === false && prevProps.orgVendorAddProgress === true) {
      let { totalProductAdded, totalCreateProducts, newProducts } = this.state;
      let evaluationProducts = [...this.state.evaluationProducts];
      totalProductAdded++;
      evaluationProducts.push({
        name: this.props.orgVendorAddSuccess?.vendor?.name,
        value: this.props.orgVendorAddSuccess?.vendor?.id,
        id: this.props.orgVendorAddSuccess?.vendor?.id,
        isMaster: false,
      })
      this.setState({
        evaluationProducts: evaluationProducts,
        totalProductAdded: totalProductAdded
      }, () => {
        if (totalProductAdded === totalCreateProducts) {
          this.startWorkflow()
        } else {
          this.createVendors(newProducts[totalProductAdded])
        }
      })
    }

    if (this.props.isOpen && prevProps.isOpen === false) {
      this.initialSettings()
    }
  }

  initialSettings() {
    this.setState({
      isRequestType: this.props.isRequestType,
      requestTypes: this.props.requestTypes,
      selectedProduct: this.props.selectedProduct || [],
      requestTypeValue: null,
      isAddingProducts: false,
      selectedVendorType: this.props.selectedVendorType || null,
      selectedVendorTypeError: '',
    })
    this.clearError();

    const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'DisableCatalogFeature')
    if (DisableVendor) {
      this.setState({
        isShowVendorSelection: false,
        selectedVendorType: {
          label: 'Products',
          value: 'PRODUCT'
        }
      })
    }
    const defaultProjectType = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "DEFAULT_PROJECT_TYPE_FOR_START")
    if (defaultProjectType && defaultProjectType !== '') {
      let configVal = JSON.parse(defaultProjectType);
      if (configVal) {
        this.setState({
          isShowVendorSelection: false,
          selectedVendorType: configVal
        })
      }
    }

  }

  updateDetails = (evaluationId) => {
    console.log(this.props.evaluationCart.Products);
    if (this.state.isWorkflowEvaluation === false) {
      return;
    }
    var name = "Request for ";
    this.props.evaluationCart.Products.forEach(function (product, k) {
      if (k > 0) {
        name += " & ";
      }
      name += product.name;
    });

    this.props.updateDetails(evaluationId, {
      name: name,
      status: 2,
    });
  };

  uploadImageChange = (event) => {
    console.log(event.target.files[0]);
  };

  deleteImage = () => {
    console.log("delete");
  };

  handleParentData = (formModel) => {
    this.setState({ formModel: formModel });
  };

  async getOptions(inputValue, loadedOptions, additional) {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("SEARCH");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      field_setname: "all",
      text: inputValue,
      page,
      page_size: 15,
      sort_fields: [],
      filter_fields: {
        // verified:[1]
      },
      merge_existing_others: true,
    };
    let url = `${api_server}/v2/dsl/expent_catalogue?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    // let [response, error] = await HttpFactory.instance().postMethod(url,formData, true);

    let products = response?.data?.body?.items || [];
    let total = response?.data?.body?.total || 0;

    products = products.map((m) => {
      m.value = String(m.id);
      m.label = m.name;
      m.isProduct = true;
      m.productStates = m.states;
      m.isDisabled = m.states?.blocked !== undefined;
      return m;
    });
    return {
      options: products,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  }

  async getOptionVendors(inputValue, loadedOptions, additional) {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      query: inputValue,
      page_size: 15,
      page: page,
      filters: {}
    }

    let url = `${api_server}/entity/vendor/search?t=${timestamp}`;

    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let products = response?.data?.items || [];
    let total = response?.data?.total || 0;

    products = products.map((m) => {
      m.value = m.is_master ? m.master_vendor_id : m.org_vendor_id;
      m.label = m.name;
      m.isMaster = m.is_master ? true : false;
      return m;
    });

    return {
      options: products,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  }

  async getOptionServices(inputValue, loadedOptions, additional) {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("CATALOG");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      query: inputValue,
      page_size: 15,
      page: page,
      filters: {}
    }

    const url = `${api_server}/entity/vendorservice/search?t=${timestamp}`;

    const response = await axios.post(url, formData, {
      headers: {
        Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json"
      },
    });

    let products = response?.data?.items || [];
    let total = response?.data?.total || 0;

    products = products.map((m) => {
      m.value = m.is_master ? m.master_vendor_id + '-' + m.master_service_id : m.org_vendor_id + '-' + m.org_service_id;
      m.label = m.service_name + ' - ' + m.vendor_name;
      m.name = m.service_name + ' - ' + m.vendor_name;
      m.isMaster = m.is_master ? true : false;
      return m;
    });

    return {
      options: products,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  }

  clearError() {
    this.setState({
      requestTypeError: '',
      selectedProductError: '',
      selectedVendorTypeError: ''
    })
  }

  submitForm() {
    this.clearError();
    const { selectedProduct, requestTypeValue } = this.state;
    let evaluationProducts = [];
    let newProducts = [];
    if (requestTypeValue === null || requestTypeValue === '') {
      this.setState({
        requestTypeError: 'Please select Project Type'
      })
      return;
    }

    if (selectedProduct === null || selectedProduct === '' || selectedProduct.length <= 0) {
      let msg = `Please select Vendor`;
      this.setState({
        selectedProductError: msg
      })
      return;
    } else {
      selectedProduct.forEach(function (prjt) {
        if (prjt?.__isNew__ !== undefined && prjt?.__isNew__ === true) {
          newProducts.push(prjt)
        } else {
          evaluationProducts.push(prjt)
        }
      })
    }

    if (newProducts.length > 0) {
      this.setState({
        newProducts: newProducts,
        evaluationProducts: evaluationProducts,
        totalCreateProducts: newProducts.length,
        totalProductAdded: 0,
        isAddingProducts: true
      }, () => {
        if (this.state.selectedVendorType?.value === "PRODUCT") {
          this.createProducts(newProducts[0]);
        } else if (this.state.selectedVendorType?.value === "MASTER_VENDOR") {
          this.createVendors(newProducts[0]);
        } else if (this.state.selectedVendorType?.value === "MASTER_VENDOR_SERVICE") {
          this.createServices(newProducts[0]);
        }
      })
    } else {
      console.log(evaluationProducts, 'evaluationProducts')
      this.setState({
        evaluationProducts: evaluationProducts
      }, () => {
        this.startWorkflow();
      })
    }
  }

  createVendors = (sProduct) => {
    let vendor = {
      states: [{ name: "in_review", description: "" }],
      name: sProduct.label,
      entities: []
    }
    this.props.addOrgVendor(vendor);
  }

  createServices(sProduct) {

  }

  createProducts(sProduct) {
    let me = this;
    let product = {
      name: sProduct?.label,
      companyName: '',
      productURL: '',
      productId: null,
      productLogo: '',
      description: '',
      deploymentType: '',
      phone: '',
      email: '',
      isInternal: false,
      isExsting: false,
    };
    product.financialDetails = {
      numberOfLicenseProcured: 0,
      paymentTerm: 0,
      costPerLicense: 0,
      totalCost: '0',
      invocieDiscount: 0,
    };
    product.cat = [];
    product.champ = [];
    me.props.fetchAddOrgProduct(product);

    // products.forEach((function(selectedProduct){
    //   let product = {
    //     name:selectedProduct.label,
    //     companyName: '',
    //     productURL: '',
    //     productId: null,
    //     productLogo: '',
    //     description: '',
    //     deploymentType: '',
    //     phone: '',
    //     email: '',
    //     isInternal: false,
    //     isExsting: false,
    //   };
    //   product.financialDetails = {
    //     numberOfLicenseProcured: 0,
    //     paymentTerm: 0,
    //     costPerLicense: 0,
    //     totalCost: '0',
    //     invocieDiscount: 0,
    //   };
    //   product.cat = [];
    //   product.champ = [];
    //   me.props.fetchAddOrgProduct(product);
    // }))

  }

  startWorkflow() {
    const { evaluationProducts, requestTypeValue, selectedVendorType } = this.state;
    var name = 'Request for ';
    // console.log(evaluationProducts, 'evaluationProducts')
    // console.log(selectedVendorType, 'selectedVendorType')

    evaluationProducts.forEach(function (product, k) {
      if (k > 0) {
        name += ' & ';
      }
      name += product.name;
    })

    var data = {
      product_ids: evaluationProducts.map(o => {
        return {
          entity_id: String(o.value),
          // type: this.getSelectedType(o.is_custom, selectedVendorType.value)
          type: selectedVendorType.value === 'MASTER_VENDOR' ? o?.isMaster ? 'MASTER_VENDOR' : 'CUSTOM_VENDOR' : selectedVendorType.value === 'MASTER_VENDOR_SERVICE' ? o?.isMaster ? 'MASTER_VENDOR_SERVICE' : 'CUSTOM_VENDOR_SERVICE' : selectedVendorType.value
        }
      }),
      title: name,
      description: '',
      workflow_id: requestTypeValue?.value,
      type: 10,
      request_type: requestTypeValue?.label
    }
    console.log(data, 'startNewWorkflow')
    this.props.startNewWorkflow(data);
  }

  getSelectedType = (is_custom, type) => {
    if (is_custom) {
      if (type === 'MASTER_VENDER') {
        return 'CUSTOM_VENDER';
      } else if (type === 'MASTER_VENDER_SERVICE') {
        return 'CUSTOM_VENDER_SERVICE';
      } else {
        return type
      }
    } else {
      return type
    }
  }

  render() {
    const classes = this.props.classes;
    const { isRequestType, requestTypes, requestTypeValue, requestTypeError,
      vendorTypeOptions, selectedProduct, selectedProductError, selectedVendorType,
      selectedVendorTypeError, isShowVendorSelection } = this.state;

    let placeHolderMsg = selectedVendorType === null || selectedVendorType === '' ? '-' : selectedVendorType.value === 'PRODUCT' ? 'Select Products' : selectedVendorType.value === 'MASTER_VENDOR' ? 'Select Vendors' : selectedVendorType.value === 'MASTER_VENDOR_SERVICE' ? 'Select Services' : ''

    // console.log(isRequestType,'isRequestType-22222222')
    return (
      <Dialog
        onClose={this.props.hideEvaluationProductSelectionDialog}
        aria-labelledby="add-upload-dialog"
        open={this.props.isOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll={"body"}
        id="addProductDialog"
        PaperProps={{ style: { overflowY: "visible" } }}
      >
        <DialogContent classes={{ root: classes.dialogContent }} style={{ overflowY: "visible" }}>
          <div className={classes.close} onClick={() => this.props.hideEvaluationProductSelectionDialog()}><CloseIcon /></div>

          <Typography variant={"h6"} className={classes.title}>
            Project Information
          </Typography>
          <div className={classes.alert}>
            {this.state.error.length > 0 && (
              <Alert variant="filled" severity="error">
                {this.state.error}
              </Alert>
            )}
          </div>
          {!isRequestType && <div style={{ textAlign: 'center', marginBottom: 50 }}>
            <Typography>Contact your administrator to get Project Types configured.</Typography>
          </div>}
          {isRequestType && <div className={classes.mainForm}>
            <div className={classes.formGroup}>
              <label className={classes.label}>
                Project Type <span className={classes.mandatory}>*</span>
              </label>
              <div className={classes.customTypeFields}>
                <FormControl className={classes.formSelect}>
                  <Select
                    isClearable
                    isMulti={false}
                    labelId="singleSelect-select-label"
                    id="singleSelect-select"
                    fullWidth
                    defaultValue={requestTypeValue}
                    value={requestTypeValue}
                    onChange={(e) => {
                      this.setState({
                        requestTypeValue: e
                      }, () => {
                        this.clearError()
                      })
                    }}
                    options={requestTypes.map((o => {
                      return {
                        label: o.requestType,
                        value: o.workflow?.value
                      }
                    }))}
                    placeholder={"Select a Project Type"}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  >
                  </Select>
                </FormControl>
              </div>
              {requestTypeError.length > 0 && <span className={classes.mandatory}>{requestTypeError}</span>}
            </div>

            {isShowVendorSelection && <div className={classes.formGroup}>
              <label className={classes.label}>
                Select vendor type<span className={classes.mandatory}>*</span>
              </label>
              <Select
                isClearable
                isMulti={false}
                isSearchable={true}
                classNamePrefix="select"
                placeholder="Select Vendor Type"
                onChange={(data) => {
                  this.setState({
                    selectedVendorType: data,
                    selectedProduct: null
                  }, () => {
                    this.clearError()
                  })
                }}
                formatOptionLabel={IsExistingProduct}
                value={selectedVendorType}
                defaultValue={selectedVendorType}
                options={vendorTypeOptions}
              />
              {selectedVendorTypeError.length > 0 && <span className={classes.mandatory}>{selectedVendorTypeError}</span>}
            </div>}

            {selectedVendorType !== null && selectedVendorType !== '' && <div className={classes.formGroup}>
              <label className={classes.label}>
                {placeHolderMsg}<span className={classes.mandatory}>*</span>
              </label>
              {selectedVendorType.value === 'PRODUCT' && <CreatableAsyncPaginate
                isClearable
                isMulti={true}
                formatCreateLabel={(userInput) => `Create '${userInput}'`}
                noOptionsMessage={() => "Start typing to select or create vendor"}
                isSearchable={true}
                debounceTimeout={200}
                createOptionPosition={"first"}
                classNamePrefix="select"
                placeholder={placeHolderMsg}
                onChange={(data) => {
                  this.setState({
                    selectedProduct: data
                  }, () => {
                    this.clearError()
                  })
                }}
                additional={{
                  page: 0,
                }}
                formatOptionLabel={IsExistingProduct}
                isValidNewOption={(input) => input.length > 0}
                value={selectedProduct}
                defaultValue={selectedProduct}
                loadOptions={this.getOptions}
              />}

              {selectedVendorType.value === 'MASTER_VENDOR' &&
                <CreatableAsyncPaginate
                  isClearable
                  isMulti={true}
                  isSearchable={true}
                  createOptionPosition={"first"}
                  classNamePrefix="select"
                  debounceTimeout={200}
                  createOptionPosition={"first"}
                  placeholder={placeHolderMsg}
                  onChange={(data) => {
                    this.setState({
                      selectedProduct: data
                    }, () => {
                      this.clearError()
                    })
                  }}
                  formatOptionLabel={IsExistingProduct}
                  isValidNewOption={(input) => input.length > 0}
                  value={selectedProduct}
                  defaultValue={selectedProduct}
                  loadOptions={this.getOptionVendors}
                />}

              {selectedVendorType.value === 'MASTER_VENDOR_SERVICE' &&
                <SelectAsyncPaginate
                  isClearable
                  isMulti={true}
                  isSearchable={true}
                  classNamePrefix="select"
                  debounceTimeout={200}
                  createOptionPosition={"first"}
                  placeholder={placeHolderMsg}
                  onChange={(data) => {
                    this.setState({
                      selectedProduct: data
                    }, () => {
                      this.clearError()
                    })
                  }}
                  formatOptionLabel={IsExistingProduct}
                  isValidNewOption={(input) => input.length > 0}
                  value={selectedProduct}
                  defaultValue={selectedProduct}
                  loadOptions={this.getOptionServices}
                />}


              {selectedProductError.length > 0 && <span className={classes.mandatory}>{selectedProductError}</span>}
            </div>}

            <div className={classes.buttonSection}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={this.props.hideEvaluationProductSelectionDialog}>
                Cancel
              </Button>
              &nbsp; &nbsp;
              <Button
                variant="contained"
                color="secondary"
                onClick={() => { this.submitForm() }}
                className={classes.button}>
                {this.state.isAddingProducts ? <CircularProgress style={{ width: 25, height: 25 }} /> : <span style={{ display: 'flex' }}>Next  <ArrowForwardIcon className={classes.arrow} /></span>}
              </Button>
            </div>
          </div>}
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(ProductSelectionDialog));