import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import Home from "./Components/Home/Home";
import AllProducts from "../../../Application/Components/Products/Components/AllProductsV2";
import ProductDetails from "./Components/ProductDetails";
import AllCategories from "../../../Application/Components/Products/Components/AllCategories";
import CategoryWiseProduct from "../../../Application/Components/Products/Components/CategoryWiseProductsV2";
import { updateDrawerStyle } from "redux/drawer/action";
import UnverifiedProducts from './Components/UnverifiedProducts';
import MyProducts from './Components/MyProducts';
import ClaimProductDialog from "Components/SellerApplication/Dialogs/ClaimProductDialog";
const connectedProps = (state) => ({
  claimProductDialogOpen: state.sellerProduct.claimProductDialogOpen,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class Products extends React.Component {

  componentDidMount() {
    this.props.updateDrawerStyle(true, true);
  }

  render() {
    console.log(this.props.claimProductDialogOpen, 'claimProductDialogOpen')
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={this.props.match.path} component={Home} />
          <Route
            exact
            path={`${this.props.match.path}/all-products`}
            render={(props) => <AllProducts {...props} isSeller={true} />}
          />
          <Route
            exact
            path={`${this.props.match.path}/all-categories`}
            render={(props) => <AllCategories {...props} isSeller={true} />}
          />
          <Route
            exact
            path={`${this.props.match.path}/product-details/:id`}
            component={ProductDetails}
          />

          <Route
            exact
            path={`${this.props.match.path}/category-wise-product/:category_id`}
            render={(props) => <CategoryWiseProduct {...props} isSeller={true} />}
          />
          <Route
            exact
            path={`${this.props.match.path}/unverified-products`}
            component={UnverifiedProducts}
          />
          <Route
            exact
            path={`${this.props.match.path}/my-products`}
            component={MyProducts}
          />
        </Switch>
        {this.props.claimProductDialogOpen && <ClaimProductDialog isSeller={true} />}
      </React.Fragment>
    );
  }
}

export default connector(compose(withStyles(styles))(Products));
