import {
    SHOW_BUILDER_CONFIGURATION_DIALOG, HIDE_BUILDER_CONFIGURATION_DIALOG,
} from "./action";

const initState = {
    isShowConfigDrawer: false,
    configDatas: {}
}

export function automationBuilderReducer(state = initState, action) {
    switch (action.type) {
        case SHOW_BUILDER_CONFIGURATION_DIALOG:
            return {
                ...state,
                isShowConfigDrawer: true,
                configDatas: action.payload.data,
            };

        case HIDE_BUILDER_CONFIGURATION_DIALOG:
            return {
                ...state,
                isShowConfigDrawer: false,
                configDatas: {},
            };


        default:
            return state;
    }
}