import {
    CircularProgress,
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import ReactSelect from "react-select";
import SqureButton from 'Components/Common/SqureButton';
import Alert from '@material-ui/lab/Alert';

import { getOrgWorkflow } from "redux/evaluation/workflow/action";

import { hideStartNewWorkflowDialg, startNewWorkflow } from 'redux/workflow/action';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.workflow.showNewWorkflowStartDialog,
    product: state.workflow.startWorkflowForProduct,
    orgWorkFlowProgress: state.evaluationWorkflow.orgWorkFlowProgress,
    orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
    orgWorkFlowError: state.evaluationWorkflow.orgWorkFlowError,
    startNewWorkflowProgress: state.workflow.startNewWorkflowProgress,
    startNewWorkflowError: state.workflow.startNewWorkflowError,
    workflowData: state.workflow.workflowData,
});

const connectionActions = {
    hideDialog: hideStartNewWorkflowDialg,
    getOrgWorkflow: getOrgWorkflow,
    startNewWorkflow: startNewWorkflow
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    card: {
        border: "2px solid #EEEEEE",
        borderRadius: '10px',
        background: "#fff",
        paddingLeft: theme.spacing(2),
        cursor: "pointer",
        display: 'flex',
        placeContent: 'center',
        width: '100%',
        padding: '14px 16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 80,
    },
    cardText: {
        display: 'flex',
        flexDirection: 'column'
    },
    box: {
        marginTop: 25
    },
    actionButton: {
        textAlign: 'right',
        marginTop: 25
    },
    alert: {
        marginBottom: 10,
        marginTop: 25,
    }
});

class StartWorkflowDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            isLoading: true,
            workFlowList: [],
            selectedWorkflow: null,
            workflowName: '',
        }

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.clearError = this.clearError.bind(this);
    }

    componentDidMount() {
        console.log(this.props.product, 'Productttttttttttttt')
        this.fetchOrgWorkFlows();
    }

    componentDidUpdate(prevProps) {
        if (this.props.orgWorkFlowProgress === false && prevProps.orgWorkFlowProgress === true) {
            let workFlowList = [];
            this.props.orgWorkFlowList.Data.forEach((item, index) => {
                workFlowList.push({
                    label: item.Name,
                    value: item.ID
                })
            })
            this.setState({
                isLoading: false,
                workFlowList: workFlowList
            })
        }

        if (this.props.startNewWorkflowProgress === false && prevProps.startNewWorkflowProgress === true) {
            if (this.props.startNewWorkflowError === null) {
                console.log(this.props.workflowData, 'Workflow Dataaaa')
                this.props.hideDialog();
                this.props.history.push(`/app/evaluate/${this.props.workflowData.ID}/workflow/interim`);
            } else {
                this.setState({
                    error: 'Something went wrong'
                })
            }
        }
    }

    fetchOrgWorkFlows = (types) => {
        this.props.getOrgWorkflow({ types: [1], page: 0, pageSize: 1000 })
    }

    clearError() {
        this.setState({
            error: ''
        })
    }

    handleFormSubmit() {
        const { selectedWorkflow } = this.state;
        const { product } = this.props;
        this.clearError();

        if (selectedWorkflow === null) {
            this.setState({
                error: 'Please select workflow.'
            })
            return;
        }

        var data = {
            product_ids: [{
                entity_id: String(product?.productID),
                type: 'PRODUCT'
            }],
            title: 'Request for ' + product?.name,
            description: '',
            workflow_id: selectedWorkflow.value,
            type: 10
        }

        this.props.startNewWorkflow(data);
        console.log(selectedWorkflow, 'selectedWorkflow')
    }

    render() {
        const classes = this.props.classes;
        const { isLoading, workFlowList } = this.state;

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Start New Workflow</Typography>
                <div className={classes.body}>

                    <div className={classes.alert}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                    </div>

                    {isLoading && <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <CircularProgress />
                    </div>}

                    {!isLoading && <div className={classes.box}>
                        <Typography className={classes.headingForm}>
                            Select Workflow <span className={classes.required}>*</span>
                        </Typography>

                        <ReactSelect
                            isClearable
                            isSearchable={true}
                            menuPosition='fixed'
                            options={workFlowList}
                            classNamePrefix="select"
                            placeholder="Search Workflow"
                            onChange={(e) => {
                                this.setState({
                                    selectedWorkflow: e,
                                }, () => {
                                    this.clearError();
                                })
                            }}
                            value={this.state.selectedWorkflow}
                        />

                        <div className={classes.actionButton}>
                            <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                            <SqureButton 
                            disabled={this.state.selectedWorkflow === null ? true : false}
                            cls={classes.actionBtn} 
                            variant={"contained"} 
                            onClick={() => { this.handleFormSubmit() }}>Submit</SqureButton>
                        </div>
                    </div>}
                </div>
            </DialogContent>
        </Dialog>
    }
}

StartWorkflowDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(StartWorkflowDialog));