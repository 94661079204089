import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment";

export const START_SERVICE_CATALOG_FETCH = "START_SERVICE_CATALOG_FETCH";
export const END_SERVICE_CATALOG_FETCH = "END_SERVICE_CATALOG_FETCH";

export const START_SERVICE_CATALOG_TAB_DATA_FETCH = "START_SERVICE_CATALOG_TAB_DATA_FETCH";
export const END_SERVICE_CATALOG_TAB_DATA_FETCH = "END_SERVICE_CATALOG_TAB_DATA_FETCH";

export const SHOW_ADD_SERVICE_DIALOG = "SHOW_ADD_SERVICE_DIALOG";
export const HIDE_ADD_SERVICE_DIALOG = "HIDE_ADD_SERVICE_DIALOG";

export const START_ORG_SERVICE_ADD = "START_ORG_SERVICE_ADD";
export const END_ORG_SERVICE_ADD = "END_ORG_SERVICE_ADD";

export const START_DELETE_ORG_SERVICE = "START_DELETE_ORG_SERVICE";
export const END_DELETE_ORG_SERVICE = "END_DELETE_ORG_SERVICE";

export const START_UPDATE_ORG_SERVICE_TAB_SECTION_ITEM_DATA = "START_UPDATE_ORG_SERVICE_TAB_SECTION_ITEM_DATA";
export const END_UPDATE_ORG_SERVICE_TAB_SECTION_ITEM_DATA = "END_UPDATE_ORG_SERVICE_TAB_SECTION_ITEM_DATA";

function startDeleteOrgService() {
  return {
    type: START_DELETE_ORG_SERVICE,
    payload: null,
  };
}

function endDeleteOrgService(success, error) {
  return {
    type: END_DELETE_ORG_SERVICE,
    payload: {
      success,
      error,
    },
  };
}

export function deleteOrgService(id) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendorservice/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startDeleteOrgService());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endDeleteOrgService(response, error));
    } catch (e) {
      dispatch(
        endDeleteOrgService(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

export function showAddServiceDialog() {
  return {
    type: SHOW_ADD_SERVICE_DIALOG,
    payload: {},
  };
}

export function hideAddServiceDialog() {
  return {
    type: HIDE_ADD_SERVICE_DIALOG,
    payload: {},
  };
}

function startServiceCatalogFetch(searchQuery) {
  return {
    type: START_SERVICE_CATALOG_FETCH,
    payload: {
      searchQuery,
    },
  };
}

function endServiceCatalogFetch(searchQuery, success, error) {
  return {
    type: END_SERVICE_CATALOG_FETCH,
    payload: {
      searchQuery,
      success,
      error,
    },
  };
}

export function fetchServiceCatalog(data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/service/search?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startServiceCatalogFetch(data));
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data,false);
      dispatch(endServiceCatalogFetch(data, response, error));
    } catch (e) {
      dispatch(
        endServiceCatalogFetch(data, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startServiceCatalogTabDataFetch() {
  return {
    type: START_SERVICE_CATALOG_TAB_DATA_FETCH,
    payload: {},
  };
}

function endServiceCatalogTabDataFetch(success, error) {
  return {
    type: END_SERVICE_CATALOG_TAB_DATA_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchServiceCatalogTabData(serviceId) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendorservice/org/${serviceId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startServiceCatalogTabDataFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, true);
      dispatch(endServiceCatalogTabDataFetch(response, error));
    } catch (e) {
      dispatch(
        endServiceCatalogTabDataFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startUpdateOrgServiceTabSectionItemData() {
  return {
    type: START_UPDATE_ORG_SERVICE_TAB_SECTION_ITEM_DATA,
    payload: null,
  };
}

function endUpdateOrgServiceTabSectionItemData(item_slug, success, error) {
  return {
    type: END_UPDATE_ORG_SERVICE_TAB_SECTION_ITEM_DATA,
    payload: {
      success,
      error,
      item_slug,
    },
  };
}

export function updateOrgServiceTabSectionItemData(id,slug, data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  let url = `${api_server}/entity/vendorservice/${id}/${slug}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startUpdateOrgServiceTabSectionItemData(id));
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data);
      dispatch(endUpdateOrgServiceTabSectionItemData(slug, response, error));
    } catch (e) {
      dispatch(
        endUpdateOrgServiceTabSectionItemData(slug, null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startAddOrgService() {
  return {
    type: START_ORG_SERVICE_ADD,
    payload: null,
  };
}

function endAddOrgService(success, error) {
  return {
    type: END_ORG_SERVICE_ADD,
    payload: {
      success,
      error,
    },
  };
}

export function addOrgService(data) {
  return async (dispatch) => {
    dispatch(startAddOrgService());
    try {
      const api_server = Environment.api_host("CATALOG");
      const timestamp = new Date().getTime();
      const url = `${api_server}/entity/service?t=${timestamp}`;
      let [response, error] = await HttpFactory.instance().postMethod(url, data);
      dispatch(endAddOrgService(response, error));
    } catch (e) {
      dispatch(
        endAddOrgService(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
