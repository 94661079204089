import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import * as Environment from "util/Environment";
import axios from "axios";
import { CommonFn } from "services/commonFn";
import { showSnackBar } from "redux/snackbar/action";

import ReadSheet from "./Components/ReadSheet";
// import WriteSheet from "./Components/WriteSheet";
import * as XLSX from "xlsx";
import { stox } from "./Components/converter";
import SideDrawer from "./Components/SideDrawer";
import ShowFinalizeDataDialog from "./Dialogs/ShowFinalizeDataDialog";
import { hideImportAssessmentDialog } from "redux/seller/assessment/action";

const connectedProps = (state) => ({
  importAssessmentData: state.sellerAssessment.importAssessmentData,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  hideImportAssessmentDialog: hideImportAssessmentDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {},
    fileSelector: {
      margin: "0 auto",
      width: "50%",
      marginTop: 50,
    },
    sheetBody: {
      margin: "0 auto",
      width: "99%",
      border: "1px solid #03363d",
      overflow: "hidden",
    },
  });

class ImportData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      fileName: "",
      loadSheet: false,
      sheetData: [],
      noOfRows: 0,
      noOfCols: 0,
      isReadFile: false,
      isShowInformation: false,
      isExpanded: false,
      selectedCells: null,
      isProcessed: false,
      isShowFinalizeDialog: false,
      finalizedData: null,
    };

    this.fileSelectHandler = this.fileSelectHandler.bind(this);
  }

  componentDidMount() {
    if (!this.props.importAssessmentData || !this.props.importAssessmentData?.file) {
      this.props.history.push("/sellerapp/assessment");
    } else {
      this.fileSelectHandler(this.props.importAssessmentData?.file);
    }
  }

  componentWillUnmount(prevProps) {
    this.props.hideImportAssessmentDialog();
  }

  fileSelectHandler = async (file) => {
    this.setState({ isShowLoader: true });
    const excelFile = file;
    if (!excelFile.name.match(/\.(xlsx|xls|csv|xlsm)$/)) {
      this.props.history.push("/sellerapp/assessment");
    } else {
      const data = new Promise(function (resolve, reject) {
        var reader = new FileReader();
        var rABS = !!reader.readAsBinaryString;
        reader.onload = function (e) {
          var bstr = e.target.result;
          // var wb = XLSX.read(bstr, { type: "binary" });
          var wb = XLSX.read(bstr, { type: rABS ? "binary" : "array", cellStyles: true });
          // var wb = XLSX.read(bstr, { type: rABS ? "binary" : "array",cellStyles: true,cellDates: true, cellHTML: true  });
          resolve(wb);
        };
        if (rABS) reader.readAsBinaryString(excelFile);
        else reader.readAsArrayBuffer(excelFile);
      });
      data.then((exceldata) => {
        console.log(exceldata, "ExcellllllData");
        let sheetData = stox(exceldata);
        let noOfRows = 0;
        let noOfCols = 0;
        try {
          if (exceldata?.Sheets) {
            Object.values(exceldata?.Sheets)?.forEach((item) => {
              if (item["!cols"].length > noOfCols) {
                noOfCols = item["!cols"].length;
              }
            });
          }
          sheetData.forEach((item) => {
            if (Object.keys(item?.dataMap)?.length > noOfRows) {
              noOfRows = Object.keys(item?.dataMap)?.length;
            }
          });
        } catch (e) {
          console.log(e, "eeerrrr");
        }
        this.setState({
          sheetData: sheetData,
          noOfRows: noOfRows,
          noOfCols: noOfCols,
        });
        this.setState({
          loadSheet: false,
        });
        this.setState({
          loadSheet: true,
        });
        // console.log(stox(exceldata));
        // sheetBlock.current.loadData(stox(exceldata));
      });
    }
  };

  fileSelectHandler_bkp = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ isShowLoader: true });
      const file = event.target.files[0];
      const timestamp = new Date().getTime();
      var formData = new FormData();
      formData.append("folder", "sellerImport");
      formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf(".") + 1)}`);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;

      axios
        .post(url, formData, {
          headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response, "resFileUpload");
          if (response.data.filepath) {
            console.log(response.data.filepath);
            this.excelExport(response.data.filepath);
          }
          this.props.showSnackBar("File uploaded", "success", 1500);
        })
        .catch((err) => {
          if (err.response) {
            this.props.showSnackBar(err.response.data.error, "error", 3000);
          } else if (err.request) {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          } else {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          }
          this.setState({ isShowLoader: false });
        });
    }
  };

  excelExport(filePath) {
    axios
      .get(filePath, {
        headers: {
          crossdomain: true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "'GET, POST",
          // "responseType": 'arraybuffer',
          // mode: 'no-cors'
        },
      })
      .then((result) => {
        console.log("result", result);
      })
      .catch((error) => {
        console.log("Error", error);
      });

    // ---------------------------------
    // var url = "https://devattachments.expent.ai/3/sellerImport/1669616536105.xlsx";
    // var req = new XMLHttpRequest();
    // req.open("GET", url, true);
    // req.responseType = "arraybuffer";
    // req.setRequestHeader('Access-Control-Allow-Origin', '*');
    // req.withCredentials = false;
    // req.onload = function (e) {
    //   var data = new Uint8Array(req.response);
    //   var workbook = XLSX.read(data, { type: "array" });
    // }
    // req.send();

    // axios.get(
    //   filePath, //hosted with serve
    //   {
    //     responseType: 'arraybuffer',
    //     "Access-Control-Allow-Origin": "*"
    //   }, {
    //   headers: headers
    // }) // replace this with 'arraybuffer' and response.data will be a buffer
    //   .then((response) => {
    //     console.log(response, 'FileResponseeeeeee')
    //   })
    //   .catch(err => {
    //     if (err.response) {
    //       this.props.showSnackBar(err.response.data.error, "error", 3000);
    //     } else if (err.request) {
    //       this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
    //     } else {
    //       this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
    //     }
    //     this.setState({ isShowLoader: false });
    //   })
  }

  render() {
    const { loadSheet, sheetData, noOfRows, noOfCols, isShowInformation, isExpanded, selectedCells, isProcessed, isShowFinalizeDialog, finalizedData } = this.state;

    // console.log(sheetData,'sheetData')
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className="App">
          <div className={classes.fileSelector}>
            {/* <input type="file" onChange={this.fileSelectHandler} />
            <button onClick={() => {
              this.setState({
                loadSheet: this.state.loadSheet ? false : true
              })
            }}>
              {loadSheet ? "Unload" : "Load"}
            </button> */}
            {/* {loadSheet && (
              <button onClick={() => {
                this.setState({
                  isReadFile: this.state.isReadFile ? false : true
                })
              }}>
                {isReadFile ? "Read" : "Edit"}
              </button>
            )} */}
          </div>

          {loadSheet && (
            <div className={classes.sheetBody}>
              <>
                <ReadSheet
                  sheetState={sheetData}
                  noOfRows={noOfRows}
                  noOfCols={noOfCols}
                  setSheetState={(state) => {
                    this.setState({
                      sheetData: state,
                    });
                  }}
                  selectCells={(selectedCells) => {
                    console.log(selectedCells, "selectedCells");

                    if (selectedCells) {
                      this.setState({
                        isShowInformation: true,
                        selectedCells: selectedCells,
                      });
                    } else {
                      this.setState({
                        isShowInformation: false,
                        selectedCells: selectedCells,
                      });
                    }
                    // console.log(selectedCells, 'selectedCells')
                  }}
                  isProcessed={this.state.isProcessed}
                />
                {/* {isReadFile ? (
                  <ReadSheet
                    sheetState={sheetData}
                    setSheetState={(state) => {
                      console.log(state, 'Readddddddddddddd')

                      this.setState({
                        sheetData: state
                      })
                    }}
                  />
                ) : (
                  <WriteSheet
                    sheetState={sheetData}
                    setSheetState={(state) => {
                      console.log(state, 'Writeeeeeeeeeeeee')
                      this.setState({
                        sheetData: state
                      })
                    }}
                  />
                )} */}
              </>
            </div>
          )}
        </div>
        {/* <div className={classes.fileSelector}>
            {url && url !== '' && <div>
              <a
                href={url}
                target={"_blank"}
                key={url}
                rel="noopener noreferrer"
                style={{
                  marginBottom: "25px",
                  display: "inline-block",
                  textDecoration: "underline",
                  color: "#4A87F8",
                }}
              >
                {fileName}
              </a>
              &nbsp;&nbsp;&nbsp;
              <Image
                src={deleteIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => this.handleDeleteFile()}
              />
            </div>}
            {<input
              ref={this.fileRef}
              id="uploadRequirementResponse"
              onChange={this.fileSelectHandler}
              type="file"
            />}
          </div> */}
        {isShowInformation && (
          <SideDrawer
            selectedCells={selectedCells}
            sheetData={sheetData}
            file={this.props.importAssessmentData?.file}
            expanded={isExpanded}
            isProcessed={isProcessed}
            setCollapsed={(newVal) => {
              this.setState({
                isExpanded: newVal,
              });
            }}
            setIsProcessed={(state) => {
              this.setState({
                isProcessed: state,
              });
            }}
            showFinalizeDataDialog={(stat, data) => {
              this.setState({
                isShowFinalizeDialog: stat,
                finalizedData: data,
              });
            }}
          />
        )}

        {isShowFinalizeDialog && (
          <ShowFinalizeDataDialog
            isOpen={isShowFinalizeDialog}
            finalizedData={finalizedData}
            file={this.props.importAssessmentData?.file}
            type={this.props.importAssessmentData?.type || 'library'}
            selectedCells={selectedCells}
            hideDialog={() => {
              this.setState({
                isShowFinalizeDialog: false,
                finalizedData: null,
              });
            }}
          />
        )}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(ImportData));
