import { profileDataMap } from "util/CompanyProfile";
import { Types } from "./types";

const initState = {
  addCompanySettingsInprogress: false,
  addCompanySettingsSuccess: null,
  addCompanySettingsFailure: null,

  fetchAllCompanySettingsInprogress: false,
  fetchAllCompanySettingsSuccess: null,
  fetchAllCompanySettingsFailure: null,

  storeDataInProgress: false,
  storeDataSuccess: profileDataMap,
  storeDataFailure: null,

  fetchOrgMetaRequestsInprogress: false,
  fetchOrgMetaRequestsSuccess: null,
  fetchOrgMetaRequestsFailure: null,

  updateOrgMetaRequestStatusInprogress: false,
  updateOrgMetaRequestStatusSuccess: null,
  updateOrgMetaRequestStatusFailure: null,

  fetchOrgMetaRequestDataInprogress: false,
  fetchOrgMetaRequestDataSuccess: null,
  fetchOrgMetaRequestDataFailure: null,
};

export function companyProfileReducer(state = initState, action) {
  switch (action.type) {
    case Types.START_ADD_COMPANY_PROFILE_SETTINGS:
      return {
        ...state,
        addCompanySettingsInprogress: true,
        addCompanySettingsSuccess: null,
        addCompanySettingsFailure: null,
      };
    case Types.END_ADD_COMPANY_PROFILE_SETTINGS: {
      let success = action.payload.success;
      let error = action.payload.error;
      let result = {};

      var currentSetting = {};
      if (state.fetchAllCompanySettingsSuccess !== null) {
        state.fetchAllCompanySettingsSuccess.forEach((i) => {
          currentSetting[i.ID] = i;
        });
      }

      if (success !== null) {
        success.forEach((i) => {
          currentSetting[i.ID] = i;
        });
      }
      result.addCompanySettingsInprogress = false;
      if (error) {
        result.addCompanySettingsSuccess = null;
        result.addCompanySettingsFailure = error.message;
      } else {
        result.addCompanySettingsSuccess = success;
        result.addCompanySettingsFailure = null;
        // result.fetchAllCompanySettingsSuccess = Object.values(currentSetting);
      }
      return {
        ...state,
        ...result,
      };
    }

    case Types.START_FETCH_ALL_COMPANY_PROFILE_SETTINGS:
      return {
        ...state,
        fetchAllCompanySettingsInprogress: true,
        fetchAllCompanySettingsFailure: null,
      };
    case Types.END_FETCH_ALL_COMPANY_PROFILE_SETTINGS: {
      let success = action.payload.success;
      let error = action.payload.error;
      let result = {};
      result.fetchAllCompanySettingsInprogress = false;
      if (error) {
        result.fetchAllCompanySettingsSuccess = null;
        result.fetchAllCompanySettingsFailure = error.message;
      } else {
        result.fetchAllCompanySettingsSuccess = success;
        result.fetchAllCompanySettingsFailure = null;
      }
      return {
        ...state,
        ...result,
      };
    }

    case Types.START_STORE_DATA:
      return {
        ...state,
        storeDataInProgress: true,
        storeDataSuccess: null,
        storeDataFailure: null,
      };

    case Types.RESET_STORE_DATA:
      return {
        ...state,
        storeDataInProgress: false,
        storeDataSuccess: null,
        storeDataFailure: null,
      };

    case Types.END_STORE_DATA: {
      const { data, error } = action.payload;
      return {
        ...state,
        storeDataInProgress: false,
        storeDataSuccess: data,
        storeDataFailure: error ? error.message : null,
      };
    }

    case Types.START_FETCH_ORG_META_REQUESTS:
      return {
        ...state,
        fetchOrgMetaRequestsInprogress: true,
        fetchOrgMetaRequestsSuccess: null,
        fetchOrgMetaRequestsFailure: null,
      };

    case Types.END_FETCH_ORG_META_REQUESTS:
      return {
        ...state,
        fetchOrgMetaRequestsInprogress: false,
        fetchOrgMetaRequestsSuccess: action.payload.success,
        fetchOrgMetaRequestsFailure: action.payload.error,
      };

    case Types.START_UPDATE_ORG_META_REQUEST_STATUS:
      return {
        ...state,
        updateOrgMetaRequestStatusInprogress: true,
        updateOrgMetaRequestStatusSuccess: null,
        updateOrgMetaRequestStatusFailure: null,
      };

    case Types.END_UPDATE_ORG_META_REQUEST_STATUS:
      return {
        ...state,
        updateOrgMetaRequestStatusInprogress: false,
        updateOrgMetaRequestStatusSuccess: action.payload.success,
        updateOrgMetaRequestStatusFailure: action.payload.error,
      };

    case Types.START_FETCH_ORG_META_REQUEST_DATA:
      return {
        ...state,
        fetchOrgMetaRequestDataInprogress: true,
        fetchOrgMetaRequestDataSuccess: null,
        fetchOrgMetaRequestDataFailure: null,
      };

    case Types.END_FETCH_ORG_META_REQUEST_DATA:
      return {
        ...state,
        fetchOrgMetaRequestDataInprogress: false,
        fetchOrgMetaRequestDataSuccess: action.payload.success,
        fetchOrgMetaRequestDataFailure: action.payload.error,
      };

    default:
      return state;
  }
}
