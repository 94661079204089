import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "../Style/index.css";
import AddIcon from "@material-ui/icons/Add";

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  triggerNode: {
    border: '1px solid #222'
  }
});

class SubTreeStartArrow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  onConnect = (params) => {
    console.log('handle onConnect', params)
  };

  render() {
    const { label, type,isShowTopSubStepMenu,isShowAddOption } = this.props;

    // console.log(this.props, 'Propssssssssssss')
    return (
      <>
        <div class="subtree-start-arrow">
          {type === 'condition' && <div class="subtree-start-arrow__fork-icon"></div>}
          <div class="subtree-start-arrow__container">
            <div class="labeled-path">
              {type === 'condition' && <svg class="labeled-path__svg" style={{ height: 36 }}>
                <path fill="none" d="M 1,0
                        v 24
                        q 0 6 6 6
                        h 58
                        q 6,0 6,6" mask="url(#labelCutout-4f7dcc8c-a4c5-4a4c-a3e8-e870bf85c88c)"></path>
                {label && <text dominant-baseline="hanging" class="labeled-path__label " x="24" y="24"> {label} </text>}
                <mask id="labelCutout-4f7dcc8c-a4c5-4a4c-a3e8-e870bf85c88c">
                  <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
                  <rect fill="black" x="16" y="16" width="34.85597229003906" height="31"></rect>
                </mask>
              </svg>}

              {type === 'loop' && <svg class="labeled-path__svg" style={{ height: 36 }}>
                <path fill="none" d="M 1,0
                        v 24
                        q 0 6 6 6
                        h 58
                        q 6,0 6,6"></path>
                <mask id="labelCutout-9d54b4c6-71b6-4dce-829d-d8f936132c43">
                  <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
                  <rect fill="black" x="16" y="16" width="0" height="0"></rect>
                </mask>
              </svg>}

            </div>
            <div class="subtree-start-arrow__end adaptive-arrow">
              <svg preserveAspectRatio="none" viewBox="0 0 2 1" class="adaptive-arrow__end">
                <path d="M 1 0 v 1" fill="none"></path>
              </svg>
              <div class="adaptive-arrow__pointer adaptive-arrow__pointer_with-icon"></div>
            </div>
          </div>

          {isShowAddOption && !isShowTopSubStepMenu && <div class="subtree-start-arrow__button-container" >
            <div class="recipe-step-marker">
              <div class="recipe-step-marker__inner">
                <div class="recipe-step-marker__line recipe-step-marker__line_before"></div>
                <div class="recipe-step-marker__buttons ">
                <button type="button" wtooltip="Add step" wtooltipclass="recipe-step-marker__overlay" class="recipe-step-marker__button recipe-step-marker__button_add " fdprocessedid="zayul">
                  <span class="recipe-step-marker__button-inner">
                    <AddIcon
                      onClick={() => {
                        this.props.showSubMenu(true)
                      }}
                    />
                  </span>
                </button>
                </div>
                <div class="recipe-step-marker__line recipe-step-marker__line_after"></div>
              </div>
            </div>
          </div>}
        </div>
      </>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(SubTreeStartArrow));