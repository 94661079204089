import {
  SHOW_PRODUCT_DETAILS_DIALOG,
  HIDE_PRODUCT_DETAILS_DIALOG,
  SHOW_SEARCH_TEAM_POPER_DIALOG,
  HIDE_SEARCH_TEAM_POPER_DIALOG,
  SHOW_SEARCH_USER_TEAM_POPER_DIALOG,
  HIDE_SEARCH_USER_TEAM_POPER_DIALOG,
  SHOW_CUSTOM_EMAIL_POPER_DIALOG,
  HIDE_CUSTOM_EMAIL_POPER_DIALOG,
  SHOW_USER_DETAILS_POPER_DIALOG,
  HIDE_USER_DETAILS_POPER_DIALOG,
  SHOW_SEARCH_USER_POPER_DIALOG,
  HIDE_SEARCH_USER_POPER_DIALOG,
  SHOW_BLOCK_PRODUCT_DIALOG,
  HIDE_BLOCK_PRODUCT_DIALOG,
  SHOW_DEPRECATE_PRODUCT_DIALOG,
  HIDE_DEPRECATE_PRODUCT_DIALOG,
  SHOW_SEARCH_EXTERNAL_USER_POPER_DIALOG,
  HIDE_SEARCH_EXTERNAL_USER_POPER_DIALOG,
  SHOW_SEARCH_VENDOR_EXTERNAL_USER_POPER_DIALOG,
  HIDE_SEARCH_VENDOR_EXTERNAL_USER_POPER_DIALOG,
  SHOW_REQUEST_ACCESS_INFO_DIALOG, HIDE_REQUEST_ACCESS_INFO_DIALOG,
  SHOW_DOCUMENT_DETAILS_DIALOG, HIDE_DOCUMENT_DETAILS_DIALOG
} from "./action";

import { END_PRODUCT_EXT_COLLABORATOR_ADD, END_PRODUCT_EXT_COLLABORATOR_REMOVE } from "../externalCollaborator/action";

// Initial State
const initState = {
  dialogProductDetailsOpen: false,
  dialogProductDetailsEvent: null,

  isShowSearchTeamPoperDialog: false,
  isShowSearchUserPoperDialog: false,
  searchUserPoperAdditionData: null,

  isOpenDeprecateProductMessageDialog: false,

  isOpenBlockProductMessageDialog: false,

  isShowSearchExternalUserPoperDialog: false,
  searchExternalUserPoperAdditionData: null,

  isShowSearchVendorExternalUserPoperDialog: false,
  searchVendorExternalUserPoperAdditionData: null,

  isShowSearchUserAndTeamPoperDialog: false,
  isShowSearchUserAndTeamPoperData: null,

  isAddCustomEmailPoperDialog: false,

  isUserDetailsPoperDialog: false,
  isUserDetailsPoperData: null,

  isShowRequestAccInfoDialog: false,
  requestAccInfo: {},

  isShowAddDocumentDetailDialog: false,
  addDetailsData: {}

};

export function dialogsReducer(state = initState, action) {
  switch (action.type) {
    case SHOW_PRODUCT_DETAILS_DIALOG:
      return {
        ...state,
        dialogProductDetailsOpen: true,
        dialogProductDetailsEvent: action.payload.event,
        dialogProductDetailsId: action.payload.id,
      };

    case HIDE_PRODUCT_DETAILS_DIALOG:
      return {
        ...state,
        dialogProductDetailsOpen: false,
        dialogProductDetailsEvent: null,
        dialogProductDetailsId: 0,
      };

    case SHOW_SEARCH_TEAM_POPER_DIALOG:
      return {
        ...state,
        isShowSearchTeamPoperDialog: true,
      };

    case HIDE_SEARCH_TEAM_POPER_DIALOG:
      return {
        ...state,
        isShowSearchTeamPoperDialog: false,
      };
    case SHOW_USER_DETAILS_POPER_DIALOG:
      return {
        ...state,
        isUserDetailsPoperDialog: true,
        isUserDetailsPoperData: action.payload.data,
      };

    case HIDE_USER_DETAILS_POPER_DIALOG:
      return {
        ...state,
        isUserDetailsPoperDialog: false,
        isUserDetailsPoperData: null,
      };
    case SHOW_CUSTOM_EMAIL_POPER_DIALOG:
      return {
        ...state,
        isAddCustomEmailPoperDialog: true,
      };

    case HIDE_CUSTOM_EMAIL_POPER_DIALOG:
      return {
        ...state,
        isAddCustomEmailPoperDialog: false,
      };
    case SHOW_SEARCH_USER_TEAM_POPER_DIALOG:
      return {
        ...state,
        isShowSearchUserAndTeamPoperDialog: true,
        isShowSearchUserAndTeamPoperData: action.payload.data,
      };

    case HIDE_SEARCH_USER_TEAM_POPER_DIALOG:
      return {
        ...state,
        isShowSearchUserAndTeamPoperDialog: false,
        isShowSearchUserAndTeamPoperData: null,
      };
    case SHOW_DEPRECATE_PRODUCT_DIALOG:
      return {
        ...state,
        isOpenDeprecateProductMessageDialog: true,
      };

    case HIDE_DEPRECATE_PRODUCT_DIALOG:
      return {
        ...state,
        isOpenDeprecateProductMessageDialog: false,
      };
    case SHOW_BLOCK_PRODUCT_DIALOG:
      return {
        ...state,
        isOpenBlockProductMessageDialog: true,
      };

    case HIDE_BLOCK_PRODUCT_DIALOG:
      return {
        ...state,
        isOpenBlockProductMessageDialog: false,
      };

    case SHOW_SEARCH_USER_POPER_DIALOG:
      return {
        ...state,
        isShowSearchUserPoperDialog: true,
        searchUserPoperAdditionData: action.payload.params,
      };

    case HIDE_SEARCH_USER_POPER_DIALOG:
      return {
        ...state,
        isShowSearchUserPoperDialog: false,
        searchUserPoperAdditionData: null,
      };

    case SHOW_SEARCH_EXTERNAL_USER_POPER_DIALOG:
      return {
        ...state,
        isShowSearchExternalUserPoperDialog: true,
        searchExternalUserPoperAdditionData: action.payload.params,
      };

    case HIDE_SEARCH_EXTERNAL_USER_POPER_DIALOG:
      return {
        ...state,
        isShowSearchExternalUserPoperDialog: false,
        searchExternalUserPoperAdditionData: null,
      };
    case SHOW_SEARCH_VENDOR_EXTERNAL_USER_POPER_DIALOG:
      return {
        ...state,
        isShowSearchVendorExternalUserPoperDialog: true,
        searchVendorExternalUserPoperAdditionData: action.payload.params,
      };

    case HIDE_SEARCH_VENDOR_EXTERNAL_USER_POPER_DIALOG:
      return {
        ...state,
        isShowSearchVendorExternalUserPoperDialog: false,
        searchVendorExternalUserPoperAdditionData: null,
      };
    case END_PRODUCT_EXT_COLLABORATOR_ADD:
      if (action.payload.success) {
        var additionalData = { ...state.searchExternalUserPoperAdditionData, selectedUsers: [...action.payload.success.Data] };
        return {
          ...state,
          searchExternalUserPoperAdditionData: additionalData,
        };
      } else {
        return {
          ...state,
        };
      }
    case END_PRODUCT_EXT_COLLABORATOR_REMOVE:
      additionalData = state.searchExternalUserPoperAdditionData;
      const userIndex = additionalData.selectedUsers.findIndex((obj) => obj.ID === action.payload.userId);
      if (userIndex > -1) {
        additionalData.selectedUsers.splice(userIndex, 1);
      }
      return {
        ...state,
        searchExternalUserPoperAdditionData: additionalData,
      };
    case SHOW_REQUEST_ACCESS_INFO_DIALOG:
      return {
        ...state,
        isShowRequestAccInfoDialog: true,
        requestAccInfo: action.payload,
      };

    case HIDE_REQUEST_ACCESS_INFO_DIALOG:
      return {
        ...state,
        isShowRequestAccInfoDialog: false,
        requestAccInfo: {}
      };

    case SHOW_DOCUMENT_DETAILS_DIALOG:
      return {
        ...state,
        isShowAddDocumentDetailDialog: true,
        addDetailsData: action.payload,
      };

    case HIDE_DOCUMENT_DETAILS_DIALOG:
      return {
        ...state,
        isShowAddDocumentDetailDialog: false,
        addDetailsData: {}
      };

    default:
      return state;
  }
}
