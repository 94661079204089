import {EvaluationFactory} from "../../../services/evaluation";
import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const SHOW_EVALUATION_METADATA_DIALOG         =   "SHOW_EVALUATION_METADATA_DIALOG";
export const HIDE_EVALUATION_METADATA_DIALOG         =   "HIDE_EVALUATION_METADATA_DIALOG";

export const START_EVALUATION_METADATA_FETCH         =   "START_EVALUATION_METADATA_FETCH";
export const END_EVALUATION_METADATA_FETCH           =   "END_EVALUATION_METADATA_FETCH";

export const START_EVALUATION_METADATA_UPDATE           =   "START_EVALUATION_METADATA_UPDATE";
export const END_EVALUATION_METADATA_UPDATE             =   "END_EVALUATION_METADATA_UPDATE";

export const START_EVALUATION_METADATA_STATUS_UPDATE           =   "START_EVALUATION_METADATA_STATUS_UPDATE";
export const END_EVALUATION_METADATA_STATUS_UPDATE             =   "END_EVALUATION_METADATA_STATUS_UPDATE";

export const START_FETCH_SUBCATEGORIES_EVALUATION          =   "START_FETCH_SUBCATEGORIES_EVALUATION";
export const END_FETCH_SUBCATEGORIES_EVALUATION             =   "END_FETCH_SUBCATEGORIES_EVALUATION";
export const START_EVALUATION_PREVIEW = 'START__EVALUATION_PREVIEW';
export const END_EVALUATION_PREVIEW = 'END_EVALUATION_PREVIEW';

export const START_EVALUATION_SELECTED_FEATURES = 'START_EVALUATION_SELECTED_FEATURES';
export const END_EVALUATION_SELECTED_FEATURES = 'END_EVALUATION_SELECTED_FEATURES';
export const START_EVALUATION_DELETE_SELECTED_FEATURES = 'START_EVALUATION_DELETE_SELECTED_FEATURES';
export const END_EVALUATION_DELETE_SELECTED_FEATURES = 'END_EVALUATION_DELETE_SELECTED_FEATURES';
export const START_EVALUATION_UPDATE_SELECTED_FEATURES = 'START_EVALUATION_UPDATE_SELECTED_FEATURES';
export const END_EVALUATION_UPDATE_SELECTED_FEATURES = 'END_EVALUATION_UPDATE_SELECTED_FEATURES';
export const SHOW_FEATURE_DIALOG = 'SHOW_FEATURE_DIALOG';
export const HIDE_FEATURE_DIALOG = 'HIDE_FEATURE_DIALOG';
export const EMPTY_PRODUCTS_LIST = 'EMPTY_PRODUCTS_LIST';
export const EVALUATION_PRODUCTS_LIST_DETAIL_FETCH = "EVALUATION_PRODUCTS_LIST_DETAIL_FETCH";
export const SHOW_SEND_NOTIFICATION_DIALOG = "SHOW_SEND_NOTIFICATION_DIALOG";
export const HIDE_SEND_NOTIFICATION_DIALOG = "HIDE_SEND_NOTIFICATION_DIALOG";

export const START_SEND_EVALUTION_EMAIL = "START_SEND_EVALUTION_EMAIL";
export const END_SEND_EVALUTION_EMAIL = "END_SEND_EVALUTION_EMAIL";

export const SHOW_EVALUATION_FINISH_DIALOG = "SHOW_EVALUATION_FINISH_DIALOG";
export const HIDE_EVALUATION_FINISH_DIALOG = "HIDE_EVALUATION_FINISH_DIALOG";

export const START_EVALUATION_METADATA_UPDATE_WORKFLOW = "START_EVALUATION_METADATA_UPDATE_WORKFLOW";
export const END_EVALUATION_METADATA_UPDATE_WORKFLOW = "END_EVALUATION_METADATA_UPDATE_WORKFLOW";

export const START_PRE_EVALUATION_FEEDBACK_DATA_FETCH = "START_PRE_EVALUATION_FEEDBACK_DATA_FETCH";
export const END_PRE_EVALUATION_FEEDBACK_DATA_FETCH = "END_PRE_EVALUATION_FEEDBACK_DATA_FETCH";

export const START_PRODUCT_COLLOBORATOR_ADD = "START_PRODUCT_COLLOBORATOR_ADD";
export const END_PRODUCT_COLLOBORATOR_ADD = "END_PRODUCT_COLLOBORATOR_ADD";

export const START_FETCH_PRODUCT_COLLABORATORS = "START_FETCH_PRODUCT_COLLABORATORS";
export const END_FETCH_PRODUCT_COLLABORATORS = "END_FETCH_PRODUCT_COLLABORATORS";

export const START_DELETE_PRODUCT_COLLABORATOR = "START_DELETE_PRODUCT_COLLABORATOR";
export const END_DELETE_PRODUCT_COLLABORATOR = "END_DELETE_PRODUCT_COLLABORATOR";

export const SHOW_EVALUATION_PRODUCT_SELECTION_DIALOG = "SHOW_EVALUATION_PRODUCT_SELECTION_DIALOG";
export const HIDE_EVALUATION_PRODUCT_SELECTION_DIALOG = "HIDE_EVALUATION_PRODUCT_SELECTION_DIALOG";

export const START_EVALUATION_TITLE_UPDATE = "START_EVALUATION_TITLE_UPDATE";
export const END_EVALUATION_TITLE_UPDATE = "END_EVALUATION_TITLE_UPDATE";

export const START_EVALUATION_UNDO = "START_EVALUATION_UNDO";
export const END_EVALUATION_UNDO = "END_EVALUATION_UNDO";

export const START_EVALUATION_PRODUCT_UPDATE = "START_EVALUATION_PRODUCT_UPDATE";
export const END_EVALUATION_PRODUCT_UPDATE = "END_EVALUATION_PRODUCT_UPDATE";

export const START_FETCH_EVALUATION_INFO="START_FETCH_EVALUATION_INFO";
export const END_FETCH_EVALUATION_INFO="END_FETCH_EVALUATION_INFO";

export const START_EVALUATION_SCORING_SCALE_UPDATE="START_EVALUATION_SCORING_SCALE_UPDATE";
export const END_EVALUATION_SCORING_SCALE_UPDATE="END_EVALUATION_SCORING_SCALE_UPDATE";

export const START_ADD_FEATURES_TO_EVALUATION="START_ADD_FEATURES_TO_EVALUATION"
export const END_ADD_FEATURES_TO_EVALUATION="END_ADD_FEATURES_TO_EVALUATION"

export const START_EVALUATION_INFO_UPDATE="START_EVALUATION_INFO_UPDATE"
export const END_EVALUATION_INFO_UPDATE="END_EVALUATION_INFO_UPDATE"

export const START_DELETE_EVALUATION_APPROVALS="START_DELETE_EVALUATION_APPROVALS"
export const END_DELETE_EVALUATION_APPROVALS="END_DELETE_EVALUATION_APPROVALS"

export const START_EVALUATION_APPROVAL_INFO_UPDATE="START_EVALUATION_APPROVAL_INFO_UPDATE"
export const END_EVALUATION_APPROVAL_INFO_UPDATE="END_EVALUATION_APPROVAL_INFO_UPDATE"

export  function showEvaluationMetaDialog(evaluationId,isEditPage=false){
    return     {
        type:SHOW_EVALUATION_METADATA_DIALOG,
        payload:{evaluationId,isEditPage}
    };
}


export  function hideEvaluationMetaDialog(evaluationId){
    return     {
        type:HIDE_EVALUATION_METADATA_DIALOG,
        payload:{}
    };
}

export  function showFeatureDialog(payload){
    return     {
        type:SHOW_FEATURE_DIALOG,
        payload:payload
    };
}

export  function hideFeatureDialog(){
    return     {
        type:HIDE_FEATURE_DIALOG,
        payload:{}
    };
}

function startUndoEvaluation() { 
    return {
        type: START_EVALUATION_UNDO,
        payload: {}
    };
}

function endUndoEvaluation(success, error) {
    return {
        type: END_EVALUATION_UNDO,
        payload: {
            success, error
        }
    };
}

export function undoEvaluation(evId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url =   `${api_server}/${evId}/undo?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startUndoEvaluation());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url);
            dispatch(endUndoEvaluation(response, error));
        } catch (e) {
            dispatch(endUndoEvaluation(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startEvaluationTitleUpdate(){
    return {
        type:START_EVALUATION_TITLE_UPDATE,
        payload:{}
    };
}

function endEvaluationTitleUpdate(success,error){
    return {
        type:END_EVALUATION_TITLE_UPDATE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateEvaluationTitle(evId,data){
    return async (dispatch) => {
        dispatch(startEvaluationTitleUpdate());
        try{
            let [response,error]    =   await EvaluationFactory.instance().updateEvaluation(evId,data);
            dispatch(endEvaluationTitleUpdate(response,error));
        } catch(e){
            dispatch(endEvaluationTitleUpdate(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startEvaluationScoringScaleUpdate(){
    return {
        type:START_EVALUATION_SCORING_SCALE_UPDATE,
        payload:{}
    };
}

function endEvaluationScoringScaleUpdate(success,error){
    return {
        type:END_EVALUATION_SCORING_SCALE_UPDATE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateEvaluationScoringScale(evId,data){
    return async (dispatch) => {
        dispatch(startEvaluationScoringScaleUpdate());
        try{
            let [response,error]    =   await EvaluationFactory.instance().updateEvaluation(evId,data);
            dispatch(endEvaluationScoringScaleUpdate(response,error));
        } catch(e){
            dispatch(endEvaluationScoringScaleUpdate(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startEvaluationMetaDataUpdateStatus(){
    return {
        type:START_EVALUATION_METADATA_STATUS_UPDATE,
        payload:{}
    };
}

function endEvaluationMetaDataUpdateStatus(success,error){
    return {
        type:END_EVALUATION_METADATA_STATUS_UPDATE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateEvaluationMetaDataStatus(evId,data){
    return async (dispatch) => {
        dispatch(startEvaluationMetaDataUpdateStatus());
        try{
            let [response,error]    =   await EvaluationFactory.instance().updateEvaluation(evId,data);
            dispatch(endEvaluationMetaDataUpdateStatus(response,error));
        } catch(e){
            dispatch(endEvaluationMetaDataUpdateStatus(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startEvaluationMetaDataUpdate(){
    return {
        type:START_EVALUATION_METADATA_UPDATE,
        payload:{}
    };
}

function endEvaluationMetaDataUpdate(success,error){
    return {
        type:END_EVALUATION_METADATA_UPDATE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateEvaluationMetaData(evId,data){
    return async (dispatch) => {
        dispatch(startEvaluationMetaDataUpdate());
        try{
            let [response,error]    =   await EvaluationFactory.instance().updateEvaluation(evId,data);
            dispatch(endEvaluationMetaDataUpdate(response,error));
        } catch(e){
            dispatch(endEvaluationMetaDataUpdate(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startEvaluationMetaDataUpdateWorkflow(){
    return {
        type:START_EVALUATION_METADATA_UPDATE_WORKFLOW,
        payload:{}
    };
}

function endEvaluationMetaDataUpdateWorkflow(success,error){
    return {
        type:END_EVALUATION_METADATA_UPDATE_WORKFLOW,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateEvaluationMetaDataWorkflow(evId,data){
    return async (dispatch) => {
        dispatch(startEvaluationMetaDataUpdateWorkflow());
        try{
            let [response,error]    =   await EvaluationFactory.instance().updateEvaluation(evId,data);
            dispatch(endEvaluationMetaDataUpdateWorkflow(response,error));
        } catch(e){
            dispatch(endEvaluationMetaDataUpdateWorkflow(null,{
                message:e.message
            }));
            return;
        }
    };
}


function startEvaluationMetaDataFetch(){
    return {
        type:START_EVALUATION_METADATA_FETCH,
        payload:{}
    };
}

function endEvaluationMetaDataFetch(success,error){
    return {
        type:END_EVALUATION_METADATA_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchEvaluationMetaData(evId){
    return async (dispatch) => {
        dispatch(startEvaluationMetaDataFetch());
        try{
            let [response,error]    =   await EvaluationFactory.instance().getEvaluation(evId);
            dispatch(endEvaluationMetaDataFetch(response,error));
        } catch(e){
            dispatch(endEvaluationMetaDataFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

export function showSendNotificationDialog(email = null){
    return {
        type: SHOW_SEND_NOTIFICATION_DIALOG,
        payload: {
            email
        }
    };
}

export function hideSendNotificationDialog(){
    return {
        type: HIDE_SEND_NOTIFICATION_DIALOG,
        payload:{}
    };
}

function startFetchSubCategoriesOfEvaluation(){
    return {
        type:START_FETCH_SUBCATEGORIES_EVALUATION,
        payload:{}
    };
}

function endFetchSubCategoriesOfEvaluation(success,error){
    return {
        type:END_FETCH_SUBCATEGORIES_EVALUATION,
        payload:{
            success,error
        }
    };
}

export  function fetchSubCategoriesOfEvaluation(evId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/subcategories?t=${timestamp}`;
    return async (dispatch) => {
        
        dispatch(startFetchSubCategoriesOfEvaluation());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endFetchSubCategoriesOfEvaluation(response,error));
        } catch(e){
            dispatch(endFetchSubCategoriesOfEvaluation(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startEvaluationPreview(){
    return {
        type:START_EVALUATION_PREVIEW,
        payload:{}
    };
}

function endEvaluationPreview(success,error){
    return {
        type:END_EVALUATION_PREVIEW,
        payload:{
            success,error
        }
    };
}

export  function fetchEvaluationPreview(subcategory_id, page, pageSize, query){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/subcategory/${subcategory_id}/features?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}&evaluation=true`;
    return async (dispatch) => {
        
        dispatch(startEvaluationPreview());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endEvaluationPreview(response,error));
        } catch(e){
            dispatch(endEvaluationPreview(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startFetchSelectedFeatures(){
    return {
        type:START_EVALUATION_SELECTED_FEATURES,
        payload:{}
    };
}

function endFetchSelectedFeatures(success,error){
    return {
        type: END_EVALUATION_SELECTED_FEATURES,
        payload:{
            success,error
        }
    };
}

export  function fetchEvaluationSelectedFeatures(evId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/features?t=${timestamp}`;
    return async (dispatch) => {
        
        dispatch(startFetchSelectedFeatures());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            if(!(error && error.message === 'cancelled')) {
                dispatch(endFetchSelectedFeatures(response,error));
            }
        } catch(e){
            dispatch(endFetchSelectedFeatures(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startUpdateSelectedFeatures(){
    return {
        type:START_EVALUATION_UPDATE_SELECTED_FEATURES,
        payload:{}
    };
}

function endUpdateSelectedFeatures(success,error){
    return {
        type: END_EVALUATION_UPDATE_SELECTED_FEATURES,
        payload:{
            success,error
        }
    };
}

export  function updateEvaluationSelectedFeatures(evId, params, duplicateCallNotAllow){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/features?t=${timestamp}`;
    return async (dispatch) => {
        
        dispatch(startUpdateSelectedFeatures());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url, params, duplicateCallNotAllow);
            dispatch(endUpdateSelectedFeatures(response,error));
        } catch(e){
            dispatch(endUpdateSelectedFeatures(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startDeleteSelectedFeatures(){
    return {
        type:START_EVALUATION_DELETE_SELECTED_FEATURES,
        payload:{}
    };
}

function endDeleteSelectedFeatures(success,error){
    return {
        type: END_EVALUATION_DELETE_SELECTED_FEATURES,
        payload:{
            success,error
        }
    };
}

export  function deleteEvaluationSelectedFeatures(evId, featureId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/features/${featureId}?t=${timestamp}`;
    return async (dispatch) => {
        
        dispatch(startDeleteSelectedFeatures());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteSelectedFeatures(response,error));
        } catch(e){
            dispatch(endDeleteSelectedFeatures(null,{
                message:e.message
            }));
            return;
        }
    };
} 


export  function emptyProductsList(){
    return     {
        type:EMPTY_PRODUCTS_LIST,
        payload:{}
    };
}


function productsListDetailsFetch(success,error){
    return {
        type: EVALUATION_PRODUCTS_LIST_DETAIL_FETCH,
        payload:{
            success,error
        }
    };
}


export  function fetchProductsList(productId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/product/${productId}?t=${timestamp}`;
    return async (dispatch) => {
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(productsListDetailsFetch(response,error));
        } catch(e){
            dispatch(productsListDetailsFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startSendEvaluationEmail(){
    return {
        type:START_SEND_EVALUTION_EMAIL,
        payload:{}
    };
}

function endSendEvaluationEmail(success,error){
    return {
        type: END_SEND_EVALUTION_EMAIL,
        payload:{
            success,error
        }
    };
}

export  function sendEvaluationEmail(evId, params){
    const api_server = Environment.api_host("EVALUATION");
    // const api_server = "http://localhost:3005/evaluation";
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/email?t=${timestamp}`;
    return async (dispatch) => {
        
        dispatch(startSendEvaluationEmail());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url, params);
            dispatch(endSendEvaluationEmail(response,error));
        } catch(e){
            dispatch(endSendEvaluationEmail(null,{
                message:e.message
            }));
            return;
        }
    };
} 

export function showEvaluationFinishDialog(){
    return {
        type: SHOW_EVALUATION_FINISH_DIALOG,
        payload: {
        }
    };
}

export function hideEvaluationFinishDialog(){
    return {
        type: HIDE_EVALUATION_FINISH_DIALOG,
        payload:{}
    };
}


function startPreEvaluationFeedbackDataFetch(){
    return {
        type:START_PRE_EVALUATION_FEEDBACK_DATA_FETCH,
        payload:{}
    };
}

function endPreEvaluationFeedbackDataFetch(success,error){
    return {
        type:END_PRE_EVALUATION_FEEDBACK_DATA_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchPreEvaluationFeedbackData(evId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/prefeedback?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startPreEvaluationFeedbackDataFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endPreEvaluationFeedbackDataFetch(response,error));
        } catch(e){
            dispatch(endPreEvaluationFeedbackDataFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startAddProductColloborator(){
    return {
        type:START_PRODUCT_COLLOBORATOR_ADD,
        payload:{}
    };
}

function endAddProductColloborator(productId,success,error){
    return {
        type:END_PRODUCT_COLLOBORATOR_ADD,
        payload:{
            productId,success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function addProductColloborator(evId,productId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/product/${productId}/collaborators?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddProductColloborator());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endAddProductColloborator(productId,response,error));
        } catch(e){
            dispatch(endAddProductColloborator(productId,null,{
                message:e.message
            }));
            return;
        }
    };
}

function startFetchProductColloborator(){
    return {
        type:START_FETCH_PRODUCT_COLLABORATORS,
        payload:{}
    };
}

function endFetchProductColloborator(success,error){
    return {
        type:END_FETCH_PRODUCT_COLLABORATORS,
        payload:{
            success,error
        }
    };
}

export  function fetchProductColloborator(evId,productId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/product/${productId}/colloborator?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductColloborator());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endFetchProductColloborator(response,error));
        } catch(e){
            dispatch(endFetchProductColloborator(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startDeleteProductCollaboraor(){
    return {
        type:START_DELETE_PRODUCT_COLLABORATOR,
        payload:{}
    };
}

function endDeleteProductCollaboraor(success,error,productId,collaboratorId){
    return {
        type: END_DELETE_PRODUCT_COLLABORATOR,
        payload:{
            success,error,productId,collaboratorId
        }
    };
}

export  function deleteProductCollaboraor(evId, productId,collaboratorId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/product/${productId}/collaborator/${collaboratorId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteProductCollaboraor());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteProductCollaboraor(response,error,productId,collaboratorId));
        } catch(e){
            dispatch(endDeleteProductCollaboraor(null,{
                message:e.message
            },productId,collaboratorId));
            return;
        }
    };
} 

export function showEvaluationProductSelectionDialog(){
    return {
        type:SHOW_EVALUATION_PRODUCT_SELECTION_DIALOG,
        payload:{}
    };
}

export function hideEvaluationProductSelectionDialog(){
    return {
        type:HIDE_EVALUATION_PRODUCT_SELECTION_DIALOG,
        payload:{}
    };
}

function startUpdateEvaluationProduct() { 
    return {
        type: START_EVALUATION_PRODUCT_UPDATE,
        payload: {}
    };
}

function endUpdateEvaluationProduct(success, error) {
    return {
        type: END_EVALUATION_PRODUCT_UPDATE,
        payload: {
            success, error
        }
    };
}

export function updateEvaluationProduct(evId, productId, data={}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url =   `${api_server}/${evId}/products/${productId}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startUpdateEvaluationProduct());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateEvaluationProduct(response, error));
        } catch (e) {
            dispatch(endUpdateEvaluationProduct(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startfetchEvaluationInfo(){
    return {
        type:START_FETCH_EVALUATION_INFO,
        payload:{}
    };
}

function endfetchEvaluationInfo(success,error){
    return {
        type:END_FETCH_EVALUATION_INFO,
        payload:{
            success,error
        }
    };
}

export  function fetchEvaluationInfo(evaluationId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startfetchEvaluationInfo());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endfetchEvaluationInfo(response,error));
        } catch(e){
            dispatch(endfetchEvaluationInfo(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startAddSelectedFeaturesForEvaluation(){
    return {
        type:START_ADD_FEATURES_TO_EVALUATION,
        payload:{}
    };
}

function endAddSelectedFeaturesForEvaluation(success,error){
    return {
        type: END_ADD_FEATURES_TO_EVALUATION,
        payload:{
            success,error
        }
    };
}

export  function addSelectedFeaturesForEvaluation(evId, params){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/features-multi?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddSelectedFeaturesForEvaluation());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url, params);
            dispatch(endAddSelectedFeaturesForEvaluation(response,error));
        } catch(e){
            dispatch(endAddSelectedFeaturesForEvaluation(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startUpdateEvaluationInfo(){
    return {
        type:START_EVALUATION_INFO_UPDATE,
        payload:{}
    };
}

function endUpdateEvaluationInfo(success,error){
    return {
        type:END_EVALUATION_INFO_UPDATE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateEvaluationInfo(evId,data){
    return async (dispatch) => {
        dispatch(startUpdateEvaluationInfo());
        try{
            let [response,error]    =   await EvaluationFactory.instance().updateEvaluation(evId,data);
            dispatch(endUpdateEvaluationInfo(response,error));
        } catch(e){
            dispatch(endUpdateEvaluationInfo(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startdeleteEvaluationApprovals() {
    return {
        type: START_DELETE_EVALUATION_APPROVALS,
        payload: {}
    };
}

function enddeleteEvaluationApprovals(success, error) {
    return {
        type: END_DELETE_EVALUATION_APPROVALS,
        payload: {
            success, error
        }
    };
}

//Thunk to perform the deleteEvaluationApprovals
export function deleteEvaluationApprovals(evaluationId,componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evaluationId}/approvals/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startdeleteEvaluationApprovals());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(enddeleteEvaluationApprovals(response, error));
        } catch (e) {
            dispatch(enddeleteEvaluationApprovals(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateApprovalInfo(){
    return {
        type:START_EVALUATION_APPROVAL_INFO_UPDATE,
        payload:{}
    };
}

function endUpdateApprovalInfo(success,error){
    return {
        type:END_EVALUATION_APPROVAL_INFO_UPDATE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateApprovalInfo(evId,params){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evId}/approval-action?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateApprovalInfo());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url,params);
            dispatch(endUpdateApprovalInfo(response, error));
        } catch (e) {
            dispatch(endUpdateApprovalInfo(null, {
                message: e.message
            }));
            return;
        }
    };
}