import React, { Component } from 'react';
import uniqid from 'uniqid';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Typography, TextField, Divider, Select, FormControl, MenuItem, Tooltip, CircularProgress
} from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ViewStreamOutlinedIcon from '@material-ui/icons/ViewStreamOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SqureButton from 'Components/Common/SqureButton';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import { itemTypes } from 'util/FormTypeOptions'

import FormItem from './FormItem';
// import { getSortByCreatedDate } from "util/FormTypeOptions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const styles = (theme) => createStyles({
  sectionRoot: {
    marginBottom: 50
  },
  section: {
    marginBottom: 17,
  },
  sectionNumbring: {
    background: '#673ab7',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: '2px 10px',
    width: '200px',
    textAlign: 'center'
  },
  sectionNumStat: {
    color: '#fff'
  },
  sectionInfo: {
    background: '#fff',
    position: 'relative',
    border: '1px solid #dadce0',
    padding: 30,
    borderRadius: 5,
  },
  head: {
    fontSize: 16,
    color: '#6C6C6C',
    opacity: 1,
    margin: 0,
    fontWeight: 500,
    marginBottom: 15
  },
  description: {
    fontSize: 14,
    color: '#B5BCC2',
    opacity: 1
  },
  bottomActions: {
    borderTop: '1px solid #dadce0',
    padding: '17px 0px 0px 17px',
    marginTop: 17,
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  bottomItem: {
    // marginRight: 20
  },
  dividerBtm: {
    margin: '0px 10px'
  },
  sectionDetails: {
    display: 'flex'
  },
  sectionAddBtns: {
    flex: 1,
    textAlign: 'right'
  },
  addItemBtn: {
    color: '#87929d',
    marginLeft: 10,
    cursor: 'pointer'
  },
  goToSection: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center'
  },
  goToSectionDisable: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'line-through',
    pointerEvents: 'none'
  },
  goDropdown: {
    width: 'max-content',
    marginLeft: 20,
    fontSize: 14
  },
  leftBar: {
    display: 'flex',
    height: 'calc(100% + 2px)',
    left: '-1px',
    paddingRight: '5px',
    position: 'absolute',
    bottom: '-1px',
    width: '1px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    background: '#4285f4'
  },
  greenLeftBar: {
    display: 'flex',
    height: 'calc(100% + 2px)',
    left: '-1px',
    paddingRight: '5px',
    position: 'absolute',
    bottom: '-1px',
    width: '1px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    background: 'green'
  },
});

class FormBuilder extends Component {
  constructor(props) {
    super();
    this.state = {
      sectionUid: null,
      visibility: 3,
      items: [],
      isDeletable: true,
      isEditable: true,
      activeItem: null,
      activeSecItem: null,
      activeThead: false,
      open: false,
      anchorEl: null,
      editName: null,
      type: '',
      orgForms: [],
      masterForms: [],
      orgFormsFieldMap: {},
      masterFormsFieldMap: {},
      isCreateOnce: true,
      copy: false,
      section: {
        name: '',
        desc: '',
        seq: ''
      },
      isUpdatingSeqProgress: false,
    }
    this.onSectionItemDragEnd = this.onSectionItemDragEnd.bind(this)

  }

  componentDidMount() {
    const { config, isNew } = this.props;
    var sectionItemName = this.props.type === 'custom' ? 'OrgWorkflowTemplateFormSectionItems' : 'MasterWorkflowTemplateFormsSectionItems';
    let items = [];
    let section;
    if (!isNew) {
      section = {
        name: this.props.config?.SectionName,
        desc: this.props.config?.Description,
        seq: this.props.config?.Seq,
        id: this.props.config?.ID,
      }
    } else {
      section = {
        name: '',
        desc: '',
        seq: '',
      }
    }

    if (!isNew && config[sectionItemName] !== undefined && config[sectionItemName].length > 0) {
      var sectionItems = config[sectionItemName] !== undefined ? config[sectionItemName] : [];
      sectionItems.forEach(function (sectionItem) {
        var additionalData = '';
        try {
          additionalData = JSON.parse(sectionItem.AdditionalData);
        } catch (e) {
        }
        var obj = {
          name: sectionItem?.ItemName,
          slug: sectionItem?.Slug,
          // description: sectionItem?.Description,
          sectionId: config?.ID,
          type: sectionItem.Type === null ? 1 : sectionItem.Type,
          additional_data: additionalData,
          description: sectionItem?.Type === 9 ? (sectionItem.Description) : sectionItem.Description,
          visibility: sectionItem?.Visibility,
          itemId: sectionItem.ID,
          itemUid: sectionItem?.ItemUID,
          is_multiselect: sectionItem?.IsMultiselect,
          is_required: sectionItem?.IsRequired,
          isNew: false,
          isTakeConditions: false,
          condition: null,
          is_other_option: sectionItem?.IsOtherOption,
          seq: sectionItem?.Seq,
        }

        if ([3, 4, 15, 16, 18, 19].includes(sectionItem.Type)) {
          let isTakeConditions = false;
          additionalData.forEach((data, i) => {
            if (data.condition !== null) {
              isTakeConditions = true;
            }
          })
          obj.isTakeConditions = isTakeConditions;
        }
        items.push(obj);
      });
    }

    let sortedItems = items.sort(function (a, b) {
      if (a.seq > b.seq) {
        return 1
      } else if (a.seq < b.seq) {
        return -1
      } else {
        return 0
      }
    })

    this.setState({
      items: sortedItems,
      section: section,
      isUpdatingSeqProgress: false
    })

  }

  onSectionItemDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const { destination, draggableId, source } = result;
    if (draggableId && destination.index !== source.index) {
      const itemIndex = parseInt(draggableId)
      let items = this.state.items;
      let draggedSectionItem = items[itemIndex];
      const destinationIndex = parseInt(destination.index);
      draggedSectionItem.seq = destinationIndex;

      this.setState({
        items: items,
        isUpdatingSeqProgress: true
      })

      if (!draggedSectionItem.isNew) {
        this.props.handleUpdateSectionItemSequence(draggedSectionItem.sectionId, draggedSectionItem.itemId, destinationIndex)
      }
    }
  }

  handleAddItem = () => {
    const items = this.state.items;
    let totalItms = items.length;
    let activeItm = totalItms;
    items.push({
      isNew: true,
      name: "",
      type: 1,
      additional_data: { type: 0 },
      desciption: null,
      visibility: 3,
      is_multiselect: false,
      itemUid: uniqid("item-", `-${Number(new Date())}`),
      isTakeConditions: false,
      condition: null,
      is_other_option: false,
      isActiveItem: true
    })
    this.setState({
      items: items,
      activeSecItem: activeItm
    }, () => {
      setTimeout(() => {
        document.getElementById('goToNewSecItem').scrollIntoView(false);
      }, 100)
    })
  }

  saveSection = () => {
    const { section } = this.state;
    if (this.props.isNew) {
      this.props.onSaveSection({ section })
    } else {
      this.props.onUpdateSection({ section })
    }
  }

  handleUpdateSection = (name, key) => {
    const section = this.state.section;
    section[key] = name;
    this.setState({ section })
  }

  getTypeText(type) {
    if (type === undefined || type === null || type === '') {
      return ''
    }
    var data = itemTypes.filter(it => it.value === type);
    return data[0]?.label;
  }

  activeSectionItem = (state, index) => {
    if (state) {
      this.setState({
        activeSecItem: index
      })
    } else {
      this.setState({
        activeSecItem: null
      }, () => {
        let items = this.state.items;
        let item = items[index];
        if (item.isNew) {
          items.splice(index, 1);
          this.setState({
            items: items
          })
        }
      })
    }


  }

  handleChangeSeq = (event) => {
    const { allSections, sectionIndex, sectionSeqMap } = this.props;
    let updateSection = allSections[event.target.value];
    let totalLength = allSections.length - 1;
    let newSeq;
    let currentItmSeq = sectionSeqMap[sectionIndex]

    newSeq = currentItmSeq + 1
    if (newSeq >= totalLength) {
      newSeq = totalLength;
    }
    this.props.handleUpdateSectionSequence(updateSection?.ID, newSeq)
  }

  handleChangeSeq1 = (event) => {
    const { allSections, sectionIndex, sectionSeqMap } = this.props;
    let updateSection = allSections[event.target.value];
    let newSeq;
    let currentItmSeq = sectionSeqMap[sectionIndex]
    newSeq = currentItmSeq + 1;
    this.props.handleUpdateSectionSequence(updateSection?.ID, newSeq)
  }

  moveSectionUpward() {
    const { allSections, sectionIndex } = this.props;
    let updateSection = allSections[sectionIndex];
    let newSeq = sectionIndex - 1;
    this.props.handleUpdateSectionSequence(updateSection?.ID, newSeq)
  }

  moveSectionDownward() {
    const { allSections, sectionIndex } = this.props;
    let updateSection = allSections[sectionIndex];
    let newSeq = sectionIndex + 1;
    this.props.handleUpdateSectionSequence(updateSection?.ID, newSeq)
  }

  render() {
    const { classes, isActive, allSections, config, sectionNumber, isNew, sectionViewMap, sectionIndex, isShowNewSectionBtn, isConditionsAdded } = this.props
    const { items, section, activeSecItem, isUpdatingSeqProgress } = this.state;
    let goToOptions = [];
    if (allSections !== undefined && allSections !== null) {
      let count = 0;
      allSections.forEach(function (itm, index) {
        if (index === sectionIndex) {
          count++;
          return;
        }
        let obj = {
          ...itm,
          index: count
        }
        goToOptions.push(obj);
        count++;
      })
    }

    return <div className={classes.sectionRoot}>
      <div className={classes.section} id={'Section_Box_' + section?.id}>
        {!isNew && <div className={classes.sectionDetails}>
          <div className={classes.sectionNumbring}>
            <Typography className={classes.sectionNumStat}>Section {sectionNumber} of {allSections.length}</Typography>
          </div>
          <div className={classes.sectionAddBtns}>
            {/* Start Seq Button */}
            {!isNew && !isConditionsAdded && sectionIndex !== 0 && <Tooltip
              placement="top"
              title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{'Move Section Up'}</span>}
              arrow
            >
              <ArrowUpwardIcon
                onClick={() => {
                  this.moveSectionUpward()
                }}
                className={classes.addItemBtn} />
            </Tooltip>}

            {!isNew && !isConditionsAdded && allSections?.length - 1 !== sectionIndex && <Tooltip
              placement="top"
              title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{'Move Section Down'}</span>}
              arrow
            >
              <ArrowDownwardIcon
                onClick={() => {
                  this.moveSectionDownward()
                }}
                className={classes.addItemBtn} />
            </Tooltip>}

            {/* End Seq Button */}
            <Tooltip
              placement="top"
              title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{'Add Question'}</span>}
              arrow
            >
              <AddCircleOutlineIcon
                onClick={() => {
                  this.handleAddItem()
                }}
                className={classes.addItemBtn} />
            </Tooltip>
            {isShowNewSectionBtn && <Tooltip
              placement="top"
              title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{'Add Section'}</span>}
              arrow
            >
              <ViewStreamOutlinedIcon
                onClick={() => {
                  this.props.addNewSection()
                }}
                className={classes.addItemBtn} />
            </Tooltip>}
          </div>
        </div>}

        {isNew && <div className={classes.sectionDetails}>
          <div className={classes.sectionNumbring}>
            <Typography className={classes.sectionNumStat}>New Section</Typography>
          </div>
        </div>}

        {!isActive && <div className={classes.sectionInfo} onClick={() => {
          this.props.activeSection();
        }}>
          <p className={classes.head}>{config?.SectionName}</p>
          <p className={classes.description}>{config?.Description}</p>
        </div>}

        {isActive && <div className={classes.sectionInfo}>
          {isNew && <div className={classes.greenLeftBar} id='goToNewSectionCreation'></div>}
          {!isNew && <div className={classes.leftBar}></div>}

          <div className={classes.editItem}>
            <TextField
              id="standard-multiline-flexible"
              multiline
              fullWidth
              maxRows={4}
              value={section?.name}
              style={{ fontSize: 16 }}
              placeholder='Section Name'
              onChange={e => {
                this.handleUpdateSection(e.target.value, 'name')
              }}
            />

            <TextField
              id="standard-multiline-flexible"
              multiline
              fullWidth
              maxRows={4}
              value={section?.desc}
              style={{ fontSize: 16, marginTop: 30 }}
              placeholder='Description (Optional)'
              onChange={e => {
                this.handleUpdateSection(e.target.value, 'desc')
              }}
            />
          </div>
          <div className={classes.bottomActions}>
            {!isNew && <div className={classes.bottomItem}>
              <Image
                src={deleteIcon}
                className={classes.deleteImage}
                onClick={() => {
                  this.props.onDeleteSection()
                }}
              />
            </div>}
            <Divider orientation="vertical" flexItem className={classes.dividerBtm} />
            <div className={classes.bottomItem}>
              <SqureButton
                variant={"outlined"}
                disabled={false}
                onClick={() => {
                  this.props.onCancel()
                }}
                style={{ marginRight: 10 }}
              >
                Cancel
              </SqureButton>
            </div>
            <div className={classes.bottomItem}>
              <SqureButton
                variant={"contained"}
                disabled={(section === undefined || section.name === undefined || section?.name.length === 0) ? true : false}
                onClick={this.saveSection}
              >
                Save
              </SqureButton>
            </div>
          </div>
        </div>}
      </div>

      {isUpdatingSeqProgress && (<div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>)}
      {!isUpdatingSeqProgress && items && (
        <>
          <DragDropContext onDragEnd={this.onSectionItemDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {items?.map((item, index) => (
                    <Draggable key={String(index)} draggableId={String(index)} index={index} sectionData={item}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >

                          <FormItem
                            name={item.name}
                            isActive={activeSecItem !== null && activeSecItem === index}
                            typeText={this.getTypeText(item?.type)}
                            slug={item.slug}
                            type={item.type || 1}
                            description={item.description}
                            additional_data={item.additional_data}
                            visibility={item.visibility}
                            itemId={item.itemId}
                            itemUid={item.itemUid}
                            is_multiselect={item.is_multiselect}
                            is_required={item.is_required}
                            isNew={item.isNew}
                            isTakeConditions={item.isTakeConditions}
                            condition={item.condition}
                            is_other_option={item.is_other_option}
                            allSections={allSections}
                            sectionId={item.sectionId}
                            sectionNumber={sectionNumber}
                            itemIndex={index}
                            activeItem={(state) => {
                              this.activeSectionItem(state, index)
                            }}
                            onSave={(data) => {
                              this.props.handleSaveItem(data)
                            }}
                            onUpdate={(data) => {
                              this.props.handleUpdateItem(data)
                            }}
                            onDelete={() => {
                              this.props.handleDeleteItem(item)
                            }}
                          />

                        </div>
                      )}
                    </Draggable>
                  ))
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}

      {!isNew && sectionViewMap[sectionIndex] !== 'next' && <div className={isConditionsAdded ? classes.goToSection : classes.goToSection} title={isConditionsAdded ? 'You cannot change sequence once conditions applied to sections.' : ''}>
        After Section {sectionNumber} &nbsp;
        <FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={sectionViewMap[sectionIndex]}
            onChange={this.handleChangeSeq1}
            className={classes.goDropdown}
            disabled={isConditionsAdded}
          >
            {sectionViewMap[sectionIndex] === 'next' && <MenuItem value={'next'}>Continue to next section  </MenuItem>}

            {goToOptions && goToOptions.map((itm) => {
              return <MenuItem value={itm.index}>Go to Section {itm.index + 1 + ' (' + itm.SectionName + ')'}  </MenuItem>
            })}
          </Select>
        </FormControl>
      </div>}

    </div>
  }
}

export default React.memo(withStyles(styles)(FormBuilder));
