import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import Alert from '@material-ui/lab/Alert';
import {updatePassword} from "redux/updatePassword/action";


const connectedProps = (state) => ({
    user:state.authUser.user,
    isProgress:state.updatePassword.isProgress,
    error:state.updatePassword.error,
    success:state.updatePassword.success,
});

const connectionActions = {
    updatePassword:updatePassword
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    "profileContainer": {

    },
    "profileInfoCard": {
        width: "50%",
        margin: "0 auto",
        borderRadius: 0
    },
    "profileInfoTable": {
        width: "100%",
        borderCollapse: "collapse",
        border: "none"
    },
    "profileInfoTableRow": {
        borderBottom: "solid 1px #ccc"
    },
    "profileInfoTableCelLeft": {
        borderRight: "solid 1px #ccc",
        padding: theme.spacing(1),
        width: "50%"
    },
    "profileInfoTableCelRight": {
        padding: theme.spacing(1),
        width: "50%"
    },
    content: {
        margin: "0 auto",
        textAlign: "center",
        padding: theme.spacing(4)
    },
    action: {
        marginTop: theme.spacing(4),
    },
    userAttr: {
        padding: theme.spacing(2.6, 4)
    },
    userLabel: {
        marginBottom: theme.spacing(1)
    },
    imageContainer: {
        display: "flex"
    },
    userAction: {
        textAlign: "center",
        flex: 1
    },
    userActionBtn: {
        margin: theme.spacing(0.5),
        cursor: "pointer"
    },
    alert:{
        marginBottom:theme.spacing(1)
    },
    sendBtn:{
        fontSize:theme.spacing(2.2),
        fontWeight:500,
        padding: '6px 40px'
    }
});

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'old_password':"",
            'new_password':"",
            'confirmPassword': "",
            "success":"",
            "error":""
        }

        this.updatePassword   =   this.updatePassword.bind(this);
        this.clearError     =   this.clearError.bind(this);
    }

    componentDidUpdate(prevProps){
        if(this.props.isProgress === false && prevProps.isProgress === true){
            if(this.props.error !== null){
                var error   =   this.props.error;
                if(error.status == 401){
                    this.setState({error:"incorrect current password."});
                } else {
                    this.setState({error:error.message});
                }
            } else {
                this.setState({
                    success:"Password updated successfully.",
                    old_password:"",
                    new_password:"",
                    confirmPassword:""
                });
            }
        }
    }

    clearError(){
        this.setState({
            success:"",
            error:"",
        });
    }

    updatePassword(){
        var me  =   this;
        me.clearError();
        var old_password    =   me.state.old_password;
        if(old_password == null || String(old_password).trim().length == 0){
            me.setState({error:"Please enter old password."});
            return;
        }

        var new_password    =   me.state.new_password;
        if(new_password == null || String(new_password).trim().length == 0){
            me.setState({error:"Please enter new password."});
            return;
        }

        if(new_password == null || String(new_password).trim().length < 8){
            me.setState({error:"Password should contain min of 8 chracters."});
            return;
        }

        if(new_password == old_password){
            me.setState({error:"New password should not be same as old password."});
            return;
        }

        var confirmPassword    =   me.state.confirmPassword;
        if(confirmPassword !== new_password){
            me.setState({error:"Password does not match."});
            return;
        }
        
        this.props.updatePassword(old_password,confirmPassword);
    }

    render() {
        
        const classes = this.props.classes;
        return <div>
            {this.props.user.IsSSO == false && <div className={classes.content}>
                <div className={classes.alert}>
                    {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                    {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                </div>
                <OutlinedInput
                    label="Enter old password"
                    type="password"
                    value={this.state.old_password}
                    onChange={(event) => {
                        this.setState({ old_password: event.target.value });
                    }}
                />
                <OutlinedInput
                    label="Enter new password"
                    type="password"
                    passwordStenghtMeter={true}
                    value={this.state.new_password}
                    onChange={(event) => {
                        this.setState({ new_password: event.target.value });
                    }}
                />
                <OutlinedInput
                    label="Re-Enter new password"
                    type="password"
                    value={this.state.confirmPassword}
                    onChange={(event) => {
                        this.setState({ confirmPassword: event.target.value });
                    }}
                />
                <div className={classes.action}>
                    {!this.props.isProgress && <Button variant="contained" color="secondary" className={classes.sendBtn} onClick={this.updatePassword}>Update</Button>}
                    {this.props.isProgress && <CircularProgress />}
                </div>
            </div>}     
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(ChangePassword));