import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, CircularProgress, Button } from '@material-ui/core';
import * as Validator from "util/Validation";

import FlashAutoIcon from '@material-ui/icons/FlashAuto';
import {
  fetchEvaluationMeta,
  fetchEvaluationThread,
  fetchSellerColloborator,
  hideSubmitAllDraftResponcesDialog,
  showSubmitAllDraftResponcesDialog,
  fetchSellerRequirementTree,
  fetchSellerLastUserResponse,
  setSelectedRequirement,
  addBulkRequirementResponse,
  fetchSellerDraftResponses,
  fetchSellerInstruction
} from "redux/seller/action";
import { setRequirementTreeColExpand } from "redux/evaluation/criteria/action"

import { withRouter } from 'react-router';
import { showSnackBar } from "redux/snackbar/action";

import AppBarV2 from "../../../../Application/AppBarV2";
import { updateDrawerStyle } from "redux/drawer/action";
import UserGroup from "./Common/UserGroup";
import Products from './Sections/Products';
import Chat from './Sections/Chat';
import TreeNode from './Sections/TreeNode';
import DownArrow from "assets/images/down-filled-arrow.svg";
import { sellerQuestionSearch } from "redux/seller/library/action"
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
import InstructionBottomActions from './Common/InstructionBottomActions';

import classnames from "classnames";
import QuestionWithDiffTypeDialog from './Common/QuestionWithDiffTypeDialog';

const connectedProps = (state) => ({
  metaProg: state.seller.metaFetchProgress,
  evaluation: state.seller.evaluation,
  sellerColloborators: state.seller.sellerColloborators,
  addSellerColloboratorProgress: state.seller.addSellerColloboratorProgress,
  collapsedRequirements: state.evaludationCriteria.requirementTreeCollapsed,
  updateSellerDraftResponsesProgress: state.seller.updateSellerDraftResponsesProgress,
  addSellerRequirementResponseProgress: state.seller.addSellerRequirementResponseProgress,
  requirementTree: state.seller.requirementTree,
  fetchRequirementTreeProgress: state.seller.fetchRequirementTreeProgress,
  lastUserResponse: state.seller.lastUserResponse,
  fetchLastUserResponseProgress: state.seller.fetchLastUserResponseProgress,
  selectedRequirementId: state.seller.selectedRequirementId,

  questionSearchProgress: state.sellerLibrary.questionSearchProgress,
  questionSearchError: state.sellerLibrary.questionSearchError,
  questionSearchData: state.sellerLibrary.questionSearchData,

  addBulkResponseProgress: state.seller.addBulkResponseProgress,
  addBulkResponseError: state.seller.addBulkResponseError,
  addBulkResponseSuccess: state.seller.addBulkResponseSuccess,

  fetchSellerDraftResponsesProgress: state.seller.fetchSellerDraftResponsesProgress,
  fetchSellerDraftResponsesError: state.seller.fetchSellerDraftResponsesError,
  sellerDraftResponses: state.seller.sellerDraftResponses,

  sellerInstructionProgress: state.seller.sellerInstructionProgress,
  sellerInstructionError: state.seller.sellerInstructionError,
  sellerInstructionSuccess: state.seller.sellerInstructionSuccess,

});

const connectionActions = {
  fetchEvaluationMeta: fetchEvaluationMeta,
  fetchEvaluationThread: fetchEvaluationThread,
  fetchSellerColloborator: fetchSellerColloborator,
  showSnackBar: showSnackBar,
  setRequirementTreeColExpand: setRequirementTreeColExpand,
  hideSubmitAllDraftResponcesDialog: hideSubmitAllDraftResponcesDialog,
  showSubmitAllDraftResponcesDialog: showSubmitAllDraftResponcesDialog,
  fetchSellerRequirementTree: fetchSellerRequirementTree,
  fetchSellerLastUserResponse: fetchSellerLastUserResponse,
  setSelectedRequirement: setSelectedRequirement,
  updateDrawerStyle: updateDrawerStyle,
  sellerQuestionSearch: sellerQuestionSearch,
  addBulkRequirementResponse: addBulkRequirementResponse,
  fetchSellerDraftResponses: fetchSellerDraftResponses,
  fetchSellerInstruction: fetchSellerInstruction
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    background: '#fff'
  },
  mainLoader: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  subtitle: {
    fontSize: theme.spacing(2),
    color: "#ccc",
  },
  content: {
    display: "flex"
  },
  nav: {
    flex: 1,
    minHeight: "700px",
    borderRight: "solid 2px #f1f1f1",
    minWidth: '300px'
  },
  chatArea: {
    flex: 4
  },
  topBar: {
    display: 'flex',
    borderBottom: "solid 2px #f1f1f1",
    padding: theme.spacing(2),
  },
  titleSection: {
    borderBottom: "solid 2px #f1f1f1",
    padding: theme.spacing(2),
  },
  collabSection: {
    borderBottom: "solid 2px #f1f1f1",
    padding: theme.spacing(2),
  },
  mainTitle: {
    fontSize: theme.spacing(2.8),
    fontWeight: 500,
    marginRight: 10,
  },
  title: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
    marginRight: 10,
  },
  reqContainer: {
    background: '#fff',
    maxHeight: "75vh",
    minHeight: "75vh",
    padding: theme.spacing(4),
    overflowY: 'auto'
  },
  productContainer: {
    background: '#fff',
    maxHeight: "78vh",
    minHeight: "78vh",
    padding: theme.spacing(4, 4),
    overflowY: 'auto'
  },
  mainContainer: {
    padding: theme.spacing(2, 2),
    overflow: 'hidden scroll',
    height: "78vh",
    background: '#f7f7f7'
  },
  reqTitle: {
    marginBottom: theme.spacing(2),
    color: '#282D30',
    fontSize: theme.spacing(2.2)
  },
  antTree: {
    border: '2px solid #f1f1f1',
    borderRadius: theme.spacing(1.25),
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
    "&:before": {
      position: 'absolute',
      left: 0,
      top: 0,
      height: theme.spacing(7),
      display: 'block',
      width: theme.spacing(0.4),
      background: '#2EB77D',
      content: '""',
    }
  },
  reqBox: {
    marginBottom: theme.spacing(2)
  },
  arrowIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 999
  },
  titleReq: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    padding: theme.spacing(2)
  },
  arrowImg: {
    cursor: 'pointer',
  },
  mainRootItem: {
  },
  subChild: {
    paddingLeft: '20px'
  },
  parentItem: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: theme.spacing(2)
  },
  rootItem: {
    position: 'relative',
    padding: '10px',
    "&:before": {
      position: 'absolute',
      left: '15px',
      top: '40px',
      display: 'block',
      width: '3px',
      bottom: '30px',
      borderRight: '1px solid hsl(206deg 5% 74%)',
      content: '""',
    }
  },
  childItem: {
    position: 'relative',
    margin: 0,
    padding: theme.spacing(0.2, 1),
    cursor: 'pointer',
    '&:after': {
      position: 'absolute',
      left: '-23px',
      top: '14px',
      display: 'block',
      width: '21px',
      height: '1px',
      /* bottom: 25px, */
      background: 'hsl(206deg 5% 74%)',
      content: '""',
    }
  },
  selectedItem: {
    backgroundColor: '#398AF5',
    border: '1px solid #398AF5',
    color: '#fff',
    padding: theme.spacing(0.2, 2),
    borderRadius: theme.spacing(1.25),
  },
  rootItemNoBefore: {
    padding: '10px'
  },
  activeTree: {
    background: '#f7f7f7'
  },
  contentContainer: {
    background: '#f7f7f7'
  },
  logoImage: {
    height: '40px',
    position: 'absolute',
    top: 20,
    right: 0,
    left: 0,
    margin: '0 auto'
  },
  logo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center"
  },
  topRightMenu: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
  },
  magicContainer: {
    textAlign: 'right'
  },
  instruction: {
    margin: '0 auto',
    width: '80%'
  }
});

class Respond extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requirementCriteriaMap: {},
      checkOnce: true,
      isLoader: true,
      criteriaReqMap: {},
      draftRequirements: [],
      isCompletedQuestionaire: false,
      instructionMessage: '',
      isShowInstruction: true,
      isInstructionAvailable: false,
      questionWithDiffType: [],
      magicResponse: [],
      questionWithDiffTypeDialog: false,
    }
    this.submitAll = this.submitAll.bind(this);
    this.submitInstruction = this.submitInstruction.bind(this)

  }

  submitAll() {
    this.props.showSubmitAllDraftResponcesDialog();
  }

  componentWillUnmount() {
    this.props.updateDrawerStyle(false, true);
  }

  componentDidMount() {
    this.props.fetchSellerInstruction(this.props.match.params.uuid, this.props.match.params.org_id);

    this.props.updateDrawerStyle(false, true);
    this.props.fetchSellerLastUserResponse(this.props.match.params.uuid, this.props.match.params.org_id);
    this.props.fetchSellerRequirementTree(this.props.match.params.uuid, this.props.match.params.org_id);
  }

  componentDidUpdate(preProps) {
    if (this.props.selectedRequirementId !== preProps.selectedRequirementId && this.props.selectedRequirementId !== null) {
      // console.log(this.props.selectedRequirementId, 'this.props.selectedRequirementId')
      this.props.fetchEvaluationMeta(this.props.match.params.uuid, this.props.match.params.org_id)
      this.props.fetchEvaluationThread(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId)
      this.props.fetchSellerColloborator(this.props.match.params.uuid, this.props.match.params.org_id);
      this.props.fetchSellerDraftResponses(this.props.match.params.uuid, this.props.match.params.org_id);
      this.setState({
        isLoader: false
      })
    }

    if (!this.props.questionSearchProgress && preProps.questionSearchProgress) {
      if (this.props.questionSearchError === null) {
        this.updateBulkResponses()
      }
    }

    if (this.props.addSellerColloboratorProgress === false && preProps.addSellerColloboratorProgress === true) {
      this.props.showSnackBar("Collaborator added successfully", "success", 3000);
      this.props.fetchSellerColloborator(this.props.match.params.uuid, this.props.match.params.org_id);
    }

    if (this.props.updateSellerDraftResponsesProgress === false && preProps.updateSellerDraftResponsesProgress === true) {
      this.props.showSnackBar("Draft Responses submited successfully", "success", 3000);
      this.props.hideSubmitAllDraftResponcesDialog();
      this.props.fetchEvaluationMeta(this.props.match.params.uuid, this.props.match.params.org_id)
      this.props.fetchEvaluationThread(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId)
    }

    if (this.props.fetchLastUserResponseProgress === false && preProps.fetchLastUserResponseProgress === true) {
      if (this.props.lastUserResponse === undefined || this.props.lastUserResponse === null || this.props.lastUserResponse.data === null) {
        this.setRequirementId();
      } else {
        this.props.setSelectedRequirement(this.props.lastUserResponse.RequirementID);
      }
    }

    if (this.props.fetchRequirementTreeProgress === false && preProps.fetchRequirementTreeProgress === true) {
      this.initRequirementTreeMap();
      if (this.props.selectedRequirementId === null) {
        this.setRequirementId();
      }
    }

    if (!this.props.addBulkResponseProgress && preProps.addBulkResponseProgress) {
      if (this.props.addBulkResponseError === null) {
        // this.props.fetchSellerRequirementTree(this.props.match.params.uuid, this.props.match.params.org_id);
        this.props.fetchEvaluationMeta(this.props.match.params.uuid, this.props.match.params.org_id)
        this.props.fetchEvaluationThread(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId)
        this.props.fetchSellerDraftResponses(this.props.match.params.uuid, this.props.match.params.org_id);
        this.props.showSnackBar("Reponses updated successfully", "success", 3000);
      }
    }

    if (this.props.fetchSellerDraftResponsesProgress === false && preProps.fetchSellerDraftResponsesProgress === true) {
      if (this.props.fetchSellerDraftResponsesError === null) {
        var ids = this.props.sellerDraftResponses.map(o => o.ID);
        this.setState({
          draftRequirements: ids
        })
        console.log(ids, 'draftRequirements')
      }
    }

    if (!this.props.metaProg && preProps.metaProg) {
      this.checkQuestionaireStatus();
      setTimeout(() => {
        this.props.fetchSellerDraftResponses(this.props.match.params.uuid, this.props.match.params.org_id);
      }, 200);
    }

    if (!this.props.sellerInstructionProgress && preProps.sellerInstructionProgress) {
      if (this.props.sellerInstructionError === null) {
        if (this.props.sellerInstructionSuccess) {
          this.showInstructions()
        }
      } else {
        this.setState({
          isShowInstruction: false
        })
        console.warn('Instruction not found')
      }
    }

  }

  setRequirementId() {
    if (this.props.fetchRequirementTreeProgress) {
      return;
    }
    var allRequirements = (this.props.requirementTree !== undefined && this.props.requirementTree !== null) ? [...this.props.requirementTree] : [];
    if (this.props.selectedRequirementId === null && allRequirements.length > 0 && (this.props.lastUserResponse === undefined || this.props.lastUserResponse === null || this.props.lastUserResponse.data === null)) {
      this.props.setSelectedRequirement(allRequirements?.[0]?.Childrens?.[0]?.ID);
    }
  }

  initRequirementTreeMap() {
    var allRequirements = (this.props.requirementTree !== undefined && this.props.requirementTree !== null) ? [...this.props.requirementTree] : [];
    var requirementIds = {};
    var allItems = {};
    if (allRequirements !== null && allRequirements.length > 0) {
      requirementIds = getAllItems(this.props.requirementTree, allItems);
    }
    this.setState({
      requirementCriteriaMap: requirementIds,
    })
    var parent = '';
    function getAllItems(datas, allItems) {
      datas.forEach(function (item) {
        if (item.Type === 1) {
          parent = item.ID;
          allItems[parent] = [];
        } else {
          allItems[parent].push(item.ID);
        }
        if (item.Childrens != null) {
          getAllItems(item.Childrens, allItems);
        }
      });
      return allItems;
    }
  }

  checkQuestionaireStatus() {
    const { evaluation } = this.props;
    if (evaluation) {
      var isCompletedQuestionaire = false;
      this.props.evaluation.criterias.forEach((criteria, key) => {
        criteria.requirements.forEach((requirement) => {
          if (requirement.response_status === 'DONE') {
            isCompletedQuestionaire = true;
          }
        })
      })
      this.setState({
        isCompletedQuestionaire: isCompletedQuestionaire
      })
    }
  }

  abaraKaDabra() {
    const { evaluation } = this.props;
    if (evaluation) {
      // console.log(evaluation, 'evaluation')
      let questions = []
      let criteriaReqMap = {}
      try{
        evaluation.criterias.forEach(function (criteria) {
          if (criteria.requirements) {
            criteria.requirements.forEach(function (req) {
              let desc = '';
              if (Validator.isJson(req.description)) {
                let descData = JSON.parse(req.description)
                desc = descData[0].children[0].text;
              } else if (req.description !== null && req.description !== '') {
                desc = req.description;
              }

              if (desc === '') {
                return;
              }
              questions.push(desc)
              criteriaReqMap[req.id] = {
                question: desc,
                data: req,
                id: req.id
              };
            })
          }
        })
      } catch (e) {}

      console.log(questions, 'questions')
      this.setState({
        criteriaReqMap: criteriaReqMap
      }, () => {

        // {
        //   "search_type": "string",
        //   "question_list": [
        //     "string"
        //   ],
        //   "is_bank": true,
        //   "multiple_questions": true,
        //   "min_score": 1.6,
        //   "file": "string",
        //   "query": "string"
        // }

        this.props.sellerQuestionSearch({
          search_type: "embeddings",
          question_list: questions,
          is_bank: true,
          multiple_questions: true
        });
        // this.updateBulkResponses()
      })
    }
  }

  updateBulkResponses() {
    const { criteriaReqMap } = this.state;
    const { questionSearchData } = this.props;
    console.log(criteriaReqMap, 'criteriaReqMap')
    console.log(questionSearchData, 'questionSearchData')
    if (questionSearchData === undefined || questionSearchData === null || questionSearchData === '') {
      this.props.showSnackBar("Questions not found in library. Please update your library.", "success", 3000);
      return;
    }

    let responses = [];
    let questionWithDiffType = [];

    Object.keys(criteriaReqMap).forEach(function (rec) {
      let recDatas = criteriaReqMap[rec];
      let requirement = recDatas.data;
      if (questionSearchData[recDatas.question] === undefined) {
        return;
      }
      let magicResponse = questionSearchData[recDatas.question];
      // console.log(requirement, '--------------requirement-----------')
      // console.log(magicResponse, 'magicResponse')

      if(requirement.answer_type === magicResponse.answer_type){
        let existingOptionTitles = {}
        requirement.options.forEach(function (o) {
          existingOptionTitles[o.Title] = o.ID
        })
  
        var options = {};
        let value = null;
        if ([1, 7].includes(requirement.answer_type)) {
          value = magicResponse.answer
        } else if ([5, 8, 9, 10, 11, 12,13].includes(requirement.answer_type)) {
          options.answer = magicResponse.answer
        } else if ([6].includes(requirement.answer_type)) {
          options.answer = magicResponse.answer
        } else if ([4].includes(requirement.answer_type)) {
          let selectedOp;
          if (magicResponse) {
            magicResponse.options_answer?.forEach(function (op) {
              if (existingOptionTitles[op] !== undefined) {
                selectedOp = op
              }
            })
          }
          options.answer = selectedOp
        } else if ([4, 2, 3, 14, 15].includes(requirement.answer_type)) {
          // console.log(existingOptionTitles, 'existingOptionTitles');
          let optionIds = []
          if (magicResponse) {
            magicResponse.options_answer.forEach(function (op) {
              if (existingOptionTitles[op] !== undefined) {
                optionIds.push(existingOptionTitles[op])
              }
            })
          }
          options.option_ids = optionIds
        }
        var note = requirement.answer_type === 1 || requirement.answer_type === 7 ? value : magicResponse.note;
  
        let responceObj = {
          note: note,
          value: null,
          response_options: options,
          is_draft: true,
          is_done: false,
          requirement_id: recDatas.id
        }
        responses.push(responceObj)
      } else {
        questionWithDiffType.push(requirement)
      }
    })
    if(questionWithDiffType.length > 0) {
      this.setState({magicResponse: responses, questionWithDiffType: questionWithDiffType, questionWithDiffTypeDialog: true})
    }else {
      this.setState({magicResponse: [] })
      this.props.addBulkRequirementResponse(this.props.match.params.uuid,
        this.props.match.params.org_id, {
          responses: responses
        });
    }
  }

  showInstructions() {
    const { sellerInstructionSuccess } = this.props;
    console.log(sellerInstructionSuccess, 'sellerInstructionSuccess')
    if (sellerInstructionSuccess !== undefined && sellerInstructionSuccess !== null && sellerInstructionSuccess !== '' && sellerInstructionSuccess.length > 0) {
      let firstData = sellerInstructionSuccess[0];
      let insCheckStr = this.props.match.params.uuid + '_' + this.props.match.params.org_id + '_Instructions';
      const isHideInstructions = window.sessionStorage.getItem(insCheckStr);
      let isRequireToShow = true;
      console.log(isHideInstructions, 'isHideInstructions')
      if (isHideInstructions !== undefined && isHideInstructions !== null && (isHideInstructions === true || isHideInstructions === 'true')) {
        isRequireToShow = false;
      } else {
        isRequireToShow = true;
      }

      this.setState({
        isShowInstruction: isRequireToShow,
        instructionMessage: firstData?.Instruction,
        isInstructionAvailable: true
      })
    } else {
      this.setState({
        isShowInstruction: false
      })
    }
  }

  submitInstruction() {
    let insCheckStr = this.props.match.params.uuid + '_' + this.props.match.params.org_id + '_Instructions';
    window.sessionStorage.setItem(insCheckStr, true);
    this.setState({
      isShowInstruction: false
    })
  }

  render() {
    const classes = this.props.classes;

    const { requirementCriteriaMap } = this.state;
    var selectedReqId = this.props.selectedRequirementId;
    var isActiveTree = '';
    if (this.props.requirementTree !== null && selectedReqId !== null) {
      Object.keys(requirementCriteriaMap).forEach(function (criteria) {
        if (requirementCriteriaMap[criteria].indexOf(selectedReqId) !== -1) {
          isActiveTree = parseInt(criteria);
        }
      })
    }

    if (this.props.addBulkResponseProgress) {
      return (<span className={classes.mainLoader}><CircularProgress /></span>)
    }
    // if(this.props.fetchRequirementTreeProgress || this.props.fetchLastUserResponseProgress || this.props.metaProg){
    //   return(<span className={classes.mainLoader}><CircularProgress /></span>)
    // }

    if (this.state.isLoader) {
      return (<span className={classes.mainLoader}><CircularProgress /></span>)
    }

    if (this.props.evaluation === null) return null;

    return (
      <div className={classes.root}>
        <AppBarV2
          withBack={true}
          title={`${this.props.evaluation.orgName} is Evaluating ${this.props.evaluation.productName}`}
        />
        {!this.state.isShowInstruction && <Grid container alignItems="center" className={classnames(classes.collabSection)}>
          <Grid item lg={6} md={6} sm={6} xs={12} className={classes.topContainer}>
            <Typography variant={"h5"} className={classes.title}>Collaborators</Typography>
            <UserGroup
              editable={true}
              selectedUser={[]}
              existingUsers={this.props.sellerColloborators}
              evaluationId={this.props.match.params.uuid}
              position={'SecondaryBar'}
              onAdd={(user) => {
                console.log('Adding new ')
              }}
              users={this.props.sellerColloborators}
              addUserType={'SELLER_COLLOBORATOR'}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} className={classes.magicContainer}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              startIcon={<FlashAutoIcon />}
              onClick={(event) => {
                this.abaraKaDabra()
              }}
            >
              {this.props.questionSearchProgress ? <CircularProgress style={{ width: 25, height: 25 }} /> : 'Genie'}
            </Button>
          </Grid>
        </Grid>}

        {!this.state.isShowInstruction && <Grid container className={classnames(classes.contentContainer)}>
          <Grid item lg={3} md={3} sm={3} xs={12} className={classnames(classes.reqContainer)}>
            {this.props.requirementTree !== null && this.props.requirementTree.map((item, index) => {
              let activeCls = '';
              if (isActiveTree === item.ID) {
                activeCls = classes.activeTree
              }
              let isCollapsed = false;
              if (this.props.collapsedRequirements.indexOf(item.ID) !== -1) {
                isCollapsed = true;
              }
              return <div className={classes.antTree} key={index + item.ID}>
                <span className={classes.arrowIcon} onClick={(evt) =>
                  this.props.setRequirementTreeColExpand(item.ID, 'ADD')
                }>
                  <img className={classes.arrowImg} src={DownArrow} alt='Down Arrow' />
                </span>
                {isCollapsed && <p className={classes.titleReq}>
                  {item.Name}
                </p>}
                {!isCollapsed && <div className={classnames(classes.mainRootItem, activeCls)} >
                  <TreeNode
                    key={index}
                    itemData={item}
                    cls={classes.parentItem}
                    selectedItem={this.props.selectedRequirementId}
                    classes={this.props.classes}
                  >
                  </TreeNode>
                </div>}
              </div>
            })}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} className={classnames(classes.mainContainer)}>
            <Chat
              isInstructionAvailable={this.state.isInstructionAvailable}
              showInstruction={() => {
                this.setState({
                  isShowInstruction: true
                })
              }}
              draftRequirements={this.state.draftRequirements}
              isCompletedQuestionaire={this.state.isCompletedQuestionaire}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12} className={classnames(classes.productContainer)}>
            <Products
              isCompletedQuestionaire={this.state.isCompletedQuestionaire}
            />
          </Grid>
        </Grid>}

        {this.state.isShowInstruction && <div className={classes.instruction}>
          <SlateInputFieldV2
            // label="Instruction"
            readOnly={true}
            isToolBar={false}
            placeholder=""
            style={{
              margin: 0,
            }}
            as={SlateInputFieldV2}
            initValue={this.state.instructionMessage}
            formControlStyle={{ border: 'none', overflow: 'hidden' }}
            textContainerStyle={{ minHeight: '100px !important', margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }} />

          <InstructionBottomActions
            submitInstruction={this.submitInstruction}
          />
        </div>}

        {this.state.questionWithDiffTypeDialog && <QuestionWithDiffTypeDialog 
            data={this.state.questionWithDiffType} 
            onClose={()=> {
              this.props.addBulkRequirementResponse(this.props.match.params.uuid,
                this.props.match.params.org_id, {
                  responses: this.state.magicResponse
                });
              this.setState({questionWithDiffTypeDialog: false, questionWithDiffType: []})
            }}
        />}

      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Respond));
