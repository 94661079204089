import { Card, CircularProgress, Tab, Tabs } from '@material-ui/core';
// materil ui
import { createStyles, withStyles } from '@material-ui/core/styles';
// assets
import CommonCss from 'commonCss';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
// redux
import { fetchCompanyProfile, updateCompanyProfile } from "redux/companyProfileSettings/action";
import { fetchIndustries, fetchLocations, fetchNumOfEmployees, fetchOrgType } from "redux/master/action";
import { addOrgSettings, fetchAllOrgSettings } from "redux/org/user/action";
import { showSnackBar } from "redux/snackbar/action";

// components
import AppBarV2 from "../../../AppBarV2";
import NotificationConfigurations from './Components/NotificationConfigurations';
import GeneralConfigurationsForm from "./Components/generalConfiguration";
import RequestTypeConfigurations from './Components/RequestTypeConfigurations';
import { fetchDashboard } from "redux/visualization/action";
import { getOrgWorkflow } from "redux/evaluation/workflow/action";
import { fetchOrgForms } from "redux/forms/org/action";


import { fetchUserProfileMeta } from "redux/departments/action"

const connectedProps = (state) => ({
    details: state.companyProfileSettings.details,
    locations: state.master.locations,
    orgType: state.master.orgType,
    industries: state.master.industries,
    updateCompanyProfileProgress: state.companyProfileSettings.updateCompanyProfileProgress,
    updateErrorDetails: state.companyProfileSettings.updateErrorDetails,
    fetchCompanyProfileProgress: state.companyProfileSettings.fetchCompanyProfileProgress,
    user: state.orgUser,
    dashboards: state.visualization.dashboards,
    dashboardFetchProgress: state.visualization.dashboardFetchProgress,
    dashboardFetchError: state.visualization.dashboardFetchError,
    orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
    fetchUserMetaProgress: state.departments.fetchUserMetaProgress,
    fetchUserMetaError: state.departments.fetchUserMetaError,
    userProfileMeta: state.departments.userProfileMeta,
    orgForms: state.orgForms.forms,
});

const connectionActions = {
    addOrgSettings: addOrgSettings,
    fetchAllOrgSettings: fetchAllOrgSettings,
    fetchCompanyProfile: fetchCompanyProfile,
    updateCompanyProfile: updateCompanyProfile,
    fetchIndustries: fetchIndustries,
    fetchLocations: fetchLocations,
    fetchNumOfEmployees: fetchNumOfEmployees,
    fetchOrgType: fetchOrgType,
    showSnackBar: showSnackBar,
    fetchDashboard: fetchDashboard,
    getOrgWorkflow: getOrgWorkflow,
    fetchUserProfileMeta: fetchUserProfileMeta,
    fetchOrgForms: fetchOrgForms
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    companySection: {
        marginTop: theme.spacing(4.2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 30
    },
    newUserBtn: {
        background: '#3C3C3C',
        borderRadius: 5,
        color: '#fff',
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        minWidth: 140,

        "&:hover": {
            background: '#3C3C3C',
        }
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(2.5),
        marginLeft: 20
    },
    addProductSection: {
        display: 'flex',
    },
    productImage: {
        width: 200,
        height: 200,
        border: '1px solid #AAAAAA',
        borderRadius: 11,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    formControl: {
        ...CommonCss.formControl,
        '& [class*=MuiInputBase-input-]': {
            transition: 'none !important',
            fontSize: '13px'
        },
        "& [class*=MuiInput-formControl-]": {
            width: '100%',
            '&::before': {
                borderBottom: 'unset'
            },
            '& [class*=MuiSelect-select-]': {
                '&:focus': {
                    backgroundColor: 'unset'
                }
            },
        }
    },
    label: {
        ...CommonCss.label,
        display: 'flex'
    },
    formGroup: CommonCss.formGroup,
    selectField: {
        ...CommonCss.selectField,
        "& [class*=MuiAutocomplete-inputRoot]": {
            minHeight: 44,
            padding: '0 8px',
            fontSize: 14,
            borderRadius: 6,
        },
        "& [class*=MuiSvgIcon-root-]": {
            opacity: 0
        },
        "& [class*=MuiAutocomplete-clearIndicator-]": {
            "& [class*=MuiSvgIcon-root-]": {
                opacity: 1
            },
        },
    },
    rightSection: {
        flex: 1,
        padding: '0px 20px',
        '&.editField': {
            "& .inputSection": {
                display: 'block'
            },
            "& .textSection": {
                display: 'none'
            }
        },
        '&.readOnly': {
            "& .inputSection, & .mandatory": {
                display: 'none'
            },
            "& .textSection": {
                display: 'block',
                '& a': {
                    color: '#4A87F8',
                    textDecoration: 'underline'
                }
            }
        }
    },
    inputSection: {},
    textSection: {
        fontSize: 16,
        fontWeight: 500,
    },
    formDivide: {
        display: 'flex',
    },
    groupFlexLeft: {
        marginRight: 10,
        flex: 1,
    },
    groupFlexRight: {
        marginLeft: 10,
        flex: 1,
    },
    uploadImageLabel: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        flexDirection: 'column'
    },
    uploadImage: {
        width: 0,
        overflow: 'hidden',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        borderRadius: 11
    },
    deleteSection: {
        position: 'absolute',
        background: '#fff',
        borderRadius: 11,
        padding: '2px 2px 0px 2px',
        top: 0,
        right: 0,
        cursor: 'pointer'
    },
    delete: {
        margin: 5
    },
    cancelIcon: {
        margin: 5,
        '& svg': {
            color: '#e7426f',
            width: 22,
            height: 22
        }
    },
    buttonSection: {
        textAlign: 'center',
        maxWidth: '70%',
        margin: '0px auto',
        marginBottom: '20px',
        display: 'flex',
        placeContent: 'space-evenly'
    },
    button: {
    },
    mandatory: CommonCss.mandatory,
    profileInfoCard: {
        width: "50%",
        margin: "0 auto",
        borderRadius: 0,
        minHeight: '70vh'
    },
    productForm: {
        border: '1px solid #F2F2F2',
        margin: 20,
        paddingBottom: 100
    },
    btnSection: {
        textAlign: 'right',
        margin: 10
    },
    loader: {
        height: 25,
        width: '70%',
    },
    chipElement: {
        '& [class*="MuiChip-root"]': {
            background: '#4A87F8',
            border: "1px solid #4A87F8",
            color: '#fff'
        },
        '& [class*="MuiChip-deleteIcon"]': {
            fill: '#fff',
            width: 17
        }
    },
    nocursor: {
        cursor: 'auto'
    },
    checkboxSection: {
        display: 'flex',
        alignItems: 'flex-start',
        // marginLeft: -15
    },
    checkBoxLabel: {
        width: 'calc(100% - 60px)',
        float: 'right'
    },
    sublabel: {
        color: '#989191',
        fontSize: '.9em',
        marginTop: -5
    },
    defaultLabel: {
        fontWeight: 400,
        color: '#989191',
        fontSize: '16px'
    },
    alerts: {
        marginBottom: theme.spacing(1)
    },
    homePageConfig: {
        display: 'flex',
        alignItems: 'center'
    },
    tabSection: {
        width: "50%",
        margin: "0 auto",
        marginTop: theme.spacing(3),
        borderBottom: '1px solid #88888833',
        '& [class*=MuiTabs-root]': {
            minWidth: '200px !important'
        },
        '& [class*=MuiTab-root]': {
            minWidth: '200px !important'
        },
    },
});

class Configurations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
        }
    }

    componentDidMount() {
        this.props.getOrgWorkflow({ types: [1], page: 0, pageSize: 1000 });
        this.props.fetchOrgForms(0, 1000);
        this.props.fetchAllOrgSettings()
        this.props.fetchUserProfileMeta()
        this.props.fetchDashboard({
            query: '',
            status: "",
            forView: false,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.user.addOrgSettingsInprogress === false && prevProps.user.addOrgSettingsInprogress === true && (this.state.tabValue === 0 || this.state.tabValue === 1)) {
            if (this.props.user.addOrgSettingsFailure == null) {
                this.props.fetchAllOrgSettings();
                this.props.showSnackBar("Updated Successfully.", "success", 3000)
            } else {
                this.props.showSnackBar("Failed to update fields.", "error", 3000)
            }
        }
    }

    render() {
        const classes = this.props.classes;
        const { tabValue } = this.state;
        if ((this.props.user.fetchAllOrgSettingsInprogress || this.props.user.addOrgSettingsInprogress) && (tabValue === 0 || tabValue === 1)) {
            return <div style={{ textAlign: 'center', marginTop: 20 }}>
                <CircularProgress />
            </div>
        }
        return <div>
            <AppBarV2 title="General" withBack={true} />
            <div className={classes.companySection}>
                <p className={classes.subTitle}></p>
            </div>
            <div className={classes.tabSection}>
                <Tabs
                    value={this.state.tabValue}
                    onChange={(event, newValue) => {
                        this.setState({ tabValue: newValue });
                    }}
                    aria-label="simple tabs example"
                    indicatorColor="primary"
                    textColor="primary"
                    className={classes.tabWidth}
                >
                    <Tab
                        className={classes.orgProductTab}
                        label="General"
                        key="expent-categories"
                    />
                    <Tab
                        className={classes.orgProductTab}
                        label="Notification"
                        key="inventory"
                    />
                    <Tab
                        className={classes.orgProductTab}
                        label="Project Type"
                        key="request-type"
                    />
                </Tabs>
            </div>

            {this.state.tabValue === 0 && <>
                <Card className={classes.profileInfoCard}>
                    <GeneralConfigurationsForm {...this.props} />
                </Card>
            </>}

            {this.state.tabValue === 1 && <>
                <Card className={classes.profileInfoCard}>
                    <NotificationConfigurations {...this.props} />
                </Card>
            </>}

            {this.state.tabValue === 2 && <>
                <Card className={classes.profileInfoCard}>
                    <RequestTypeConfigurations {...this.props} />
                </Card>
            </>}

        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Configurations));