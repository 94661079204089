import { Button, CircularProgress, Divider, Grid, MenuItem, Paper, Popover, Tab, Tabs } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import SearchUserPoper from "Components/Common/UserGroup/SearchUserPoper";
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import { showSnackBar } from "redux/snackbar/action";
import {
    hideEvaluationSelectDialog, selectEvaluationTemplateID, selectEvaluationTemplateType, selectMasterTemplate, selectOrgTemplate, showAddTemplateDialog, unselectAllTemplate, unselectMasterTemplate, unselectOrgTemplate
} from "redux/templates/evaluation/action";
import { fetchMasterTemplate, fetchMasterTemplates, setTemplateActiveTab } from "redux/templates/evaluation/master/action";
import { deleteFromOrgEvaluationTemplate, fetchOrgTemplate, fetchOrgTemplates, showImportTemplateDialog } from "redux/templates/evaluation/org/action";
import { generate } from 'shortid';
import { showUsedPlacesDialog } from "redux/forms/org/action";
import AppBarV2 from "../../../AppBarV2";
import AddTemplateDialog from './Common/AddTemplateDialog';
import TemplateCard from './Common/Card.jsx';
import CreateTemplateDialog from './Common/CreateTemplateDialog';
import ImportTemplateDialog from './Common/ImportTemplateDialog';
import { templateOptions, templates } from './Constant';
import CommonCss from 'commonCss';
import {
    showMessageDialog,
    hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import UsedPlacesDialog from "Components/Common/Dialogs/UsedPlacesDialog";

const connectedProps = (state) => ({
    templateType: state.templateEvaluation.templateType,
    templateMasterIds: state.templateEvaluation.templateMasterIds,
    templateOrgIds: state.templateEvaluation.templateOrgIds,
    templateId: state.templateEvaluation.templateId,
    templates: state.masterTemplateEvaluation.templates,
    template: state.masterTemplateEvaluation.template,
    fetchTemplateProg: state.masterTemplateEvaluation.fetchTemplateProg,
    fetchProg: state.masterTemplateEvaluation.fetchProg,
    orgTemplates: state.orgTemplateEvaluation.templates,
    orgTemplate: state.orgTemplateEvaluation.template,
    fetchOrgTemplateProg: state.orgTemplateEvaluation.fetchTemplateProg,
    fetchOrgProg: state.orgTemplateEvaluation.fetchProg,
    deleteProgress: state.orgTemplateEvaluation.deteleFromOrgEvaluationTemplateProg,
    deleteError: state.orgTemplateEvaluation.deteleFromOrgEvaluationTemplateError,
    deteleCriteriaTemplateProg: state.orgTemplateEvaluation.deteleFromOrgEvaluationCriteriaTemplateProg,
    deteleCriteriaTemplateError: state.orgTemplateEvaluation.deteleFromOrgEvaluationCriteriaTemplateError,
    configurations: state.orgUser.fetchAllOrgSettingsSuccess,
    collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
    collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
    importTemplateDialogOpen: state.orgTemplateEvaluation.importTemplateDialogOpen,
    templateActiveTab: state.masterTemplateEvaluation.templateActiveTab,
    saveProgress: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateProg,
    usedPlacesDialogOpen: state.orgForms.usedPlacesDialogOpen,
});

const connectionActions = {
    hideDialog: hideEvaluationSelectDialog,
    selectEvaluationTemplateType: selectEvaluationTemplateType,
    selectEvaluationTemplateID: selectEvaluationTemplateID,
    fetchMasterTemplates: fetchMasterTemplates,
    fetchMasterTemplate: fetchMasterTemplate,
    fetchOrgTemplate: fetchOrgTemplate,
    fetchOrgTemplates: fetchOrgTemplates,
    showAddTemplateDialog: showAddTemplateDialog,
    deleteFromOrgEvaluationTemplate: deleteFromOrgEvaluationTemplate,
    showSnackBar: showSnackBar,
    selectMasterTemplate: selectMasterTemplate,
    unselectMasterTemplate: unselectMasterTemplate,
    selectOrgTemplate: selectOrgTemplate,
    unselectOrgTemplate: unselectOrgTemplate,
    unselectAllTemplate: unselectAllTemplate,
    showImportTemplateDialog: showImportTemplateDialog,
    setTemplateActiveTab: setTemplateActiveTab,
    showMessageDialog: showMessageDialog,
    hideMessageDialog: hideMessageDialog,
    showUsedPlacesDialog: showUsedPlacesDialog,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    rootPaper: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none',
        borderBottom: "solid 1px #e0e0e0",
    },
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important",
        backgroundColor: "#F7F7F7",
        maxHeight: "100%",
        height: "100%",
        position: "relative",
        overflow: "auto"
    },
    title: {
        color: "#282D30",
        fontSize: theme.spacing(2.5),
        marginBottom: 0
    },
    actions: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(2),
        background: "#fff",
        textAlign: "right"
    },
    templates: {
        padding: theme.spacing(2, 0),
        marginBottom: 40
    },
    tca: {
        marginTop: '10px',
    },
    iconContainer: {
        marginRight: theme.spacing(3),
        cursor: "pointer",
        display: "flex",
        marginBottom: theme.spacing(2)
    },
    image: {
        width: theme.spacing(3.5),
    },
    modalappBar: {
        display: "flex"
    },
    criteriaRequirement: {
        width: "90%",
        margin: '10px auto',
        paddingBottom: 50
    },
    headSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    newUserBtn: {
        background: "#3C3C3C",
        borderRadius: 5,
        color: "#fff",
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        minWidth: 140,
        "&:hover": {
            background: "#3C3C3C",
        },
    },
    menuItem: {
        color: "#74798C",
        fontSize: theme.spacing(2),
        padding: theme.spacing(2.2),
        minWidth: 140,
    },
    loader: {
        textAlign: 'center'
    },
    bottomContainer: {
        position: 'fixed',
        width: '100%',
        left: 0,
        background: '#fff',
        height: 70,
        bottom: 0,
        boxShadow: '1px 1px 6px 2px #ddd',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '250px',
        justifyContent: 'flex-end',
        paddingRight: 46,
        zIndex: 4
    },
    addNewCriteria: {
        color: '#FFFFFF',
        borderColor: '#3C3C3C',
        backgroundColor: '#3C3C3C',
        border: '1px solid',
        padding: '7px 8px',
        '&:hover': {
            color: '#FFFFFF',
            borderColor: '#3C3C3C',
            backgroundColor: '#3C3C3C'
        }
    },
    rootContainer: CommonCss.rootContainer
});


const CustomTabs = withStyles({
    root: {
        backgroundColor: 'tarsparent !imporant',
    },
    indicator: {
        backgroundColor: '#4A87F8',
    },
})(Tabs);

const CustomTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        '&:hover': {
            color: '#4A87F8',
            opacity: 1,
        },
        '&:focus': {
            color: '#4A87F8',
        },
    },
    selected: {},
}))((props) => <Tab {...props} />);

class Template extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 1000,
            anchorEl: null,
            isCheckBox: false,
            selectedTemplate: null,
            tab: 0,
            isOpenCreateTemplateDialog: false,
            deleteId: null,
        }
        this.selectTemplateForImport = this.selectTemplateForImport.bind(this)
    }

    componentDidMount() {
        this.props.selectEvaluationTemplateID(null);
        // this.props.fetchMasterTemplates(this.state.page, this.state.pageSize);
        this.props.fetchOrgTemplates(this.state.page, this.state.pageSize);
    }

    componentDidUpdate(prevProps) {
        if (this.props.deleteProgress === false && prevProps.deleteProgress === true) {
            if (this.props.deleteError == null) {
                this.props.showSnackBar("Template deleted successfully", "success", 3000);
                this.props.fetchOrgTemplates(0, 1000);
                this.props.selectEvaluationTemplateType("ORG")
            } else {
                if (this.props.deleteError.message !== undefined) {
                    this.setState({
                        "error": "Failed to deleted template."
                    })
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }

        if (this.props.saveProgress === false && prevProps.saveProgress === true) {
            if (this.props.saveError == null) {
                this.props.setTemplateActiveTab(1);
            }
        }
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget || event.current });
    };

    handleChange = (event, value) => {
        event.preventDefault()
        // this.setState({
        //     tab: value
        // })
        console.log(value);
        this.props.setTemplateActiveTab(value);
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    navigate = (menu) => {
        if (menu.isCheckBox) {
            this.setState({
                isCheckBox: true
            })
        } else if (menu.addManually) {
            this.setState({
                isOpenCreateTemplateDialog: true
            })
        } else if (menu.key === "importTemplate") {
            this.props.showImportTemplateDialog();
        }
        this.handleClose();
    };

    defaultFlags(templateId, type) {
        let isReviewDefaultChecked = false;
        let isEvaluationDefaultChecked = false;
        if (this.props.configurations !== null) {
            let default_evaluation_setting = false
            let default_review_setting = false
            if (this.props.configurations) {
                default_evaluation_setting = this.props.configurations.find((i) => i.SettingName === "ORG_DEFAULT_EVALUATION_TEMPLATE")
                default_review_setting = this.props.configurations.find((i) => i.SettingName === "ORG_DEFAULT_REVIEW_TEMPLATE")
            }
            if (default_review_setting?.Value && default_review_setting?.Value !== "NULL") {
                try {
                    default_review_setting = JSON.parse(default_review_setting.Value)
                    if (default_review_setting?.tid && default_review_setting?.type === type && parseInt(default_review_setting?.tid) === templateId) {
                        isReviewDefaultChecked = true;
                    }
                } catch (e) { }
            }
            if (default_evaluation_setting?.Value && default_evaluation_setting?.Value !== "NULL") {
                try {
                    default_evaluation_setting = JSON.parse(default_evaluation_setting.Value)
                    if (default_evaluation_setting?.type === type && parseInt(default_evaluation_setting.tid) === templateId) {
                        isEvaluationDefaultChecked = true;
                    }
                } catch (e) { }
            }
        }
        return {
            evaluation: isEvaluationDefaultChecked,
            review: isReviewDefaultChecked
        }
    }

    selectTemplateForImport(checked, templateId, type) {
        if (checked) {
            this.setState({
                selectedTemplate: {
                    templateId, type
                }
            })
        } else {
            this.setState({
                selectedTemplate: null
            })
        }
    }

    getAppBarTitle = () => {
        let title = ''
        const data = templates.find(o => o.template === "evaluation")
        if (data) {
            title = data.primary
        }
        return title
    }

    removeTemplate = (id) => {
        var me = this;
        this.setState({ deleteId: id }, () => {
            me.props.showMessageDialog();
        })
    }

    callBack = () => {
        if (this.state.deleteId) {
            this.props.hideMessageDialog();
            this.props.deleteFromOrgEvaluationTemplate(this.state.deleteId);
        }
    };

    render() {
        const classes = this.props.classes;
        const open = Boolean(this.state.anchorEl);
        const id = open ? "newTemplatePopover" : undefined;

        let messageData = {
            head: "Are you sure?",
            subHead: "Do you really want to delete? This process cannot be undone.",
            firstBtn: "Cancel",
            secondBtn: "Yes",
        };

        return (
            <>
                <div classes={{ root: classes.dialogContent }} >
                    <AppBarV2 title={this.getAppBarTitle()} withBack={true} />
                    <div className={classes.rootContainer}>
                        <div className={classes.headSection}>
                            {false && <Paper className={classes.rootPaper}>
                                <CustomTabs
                                    value={this.props.templateActiveTab}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <CustomTab label="Expent's Template Library" />
                                    <CustomTab label="Custom" />
                                </CustomTabs>
                            </Paper>}

                            {this.props.templateActiveTab === 1 && <div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginTop: 20 }}>
                                {!this.state.isCheckBox && <Button className={classes.newUserBtn} onClick={this.handleClick}>
                                    + New Template
                                </Button>}

                                {this.state.isCheckBox &&
                                    <Button className={classes.newUserBtn} onClick={() => {
                                        this.setState({
                                            isCheckBox: false,
                                            selectedTemplate: null,
                                        })
                                    }}>
                                        Cancel Import
                                    </Button>
                                }

                                <Popover
                                    className={classes.popover}
                                    id={id}
                                    open={open}
                                    anchorEl={this.state.anchorEl}
                                    onClose={() => this.handleClose()}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                    {templateOptions?.map((item, childIndex) => {
                                        return (
                                            <div key={childIndex}>
                                                {" "}
                                                <MenuItem
                                                    value={item.label}
                                                    className={classes.menuItem}
                                                    onClick={() => this.navigate(item)}
                                                >
                                                    {item.label}
                                                </MenuItem>{" "}
                                                <Divider />
                                            </div>
                                        );
                                    })}
                                </Popover>
                            </div>}
                        </div>
                        {this.props.templateActiveTab === 0 && (
                            <div className={classes.templates}>
                                {this.props.fetchProg === true && <div className={classes.loader}>
                                    <CircularProgress />
                                </div>}
                                {this.props.templates !== null && (
                                    <Grid container spacing={3}>
                                        {this.props.templates.map(template => {
                                            var dflags = this.defaultFlags(template.ID, 'MASTER')
                                            return (
                                                <Grid item xs={12} sm={6} md={4} lg={3} key={generate()}>
                                                    <TemplateCard
                                                        isCheckBox={this.state.isCheckBox}
                                                        template={template}
                                                        templateId={this.props.templateId}
                                                        templateType="evaluation"
                                                        dflags={dflags}
                                                        selectedTemplate={this.state.selectedTemplate}
                                                        selectTemplateForImport={this.selectTemplateForImport}
                                                        deleteFromOrgEvaluationTemplate={(id) => {
                                                            if (template?.UsedInWorkflowCount > 0) {
                                                                this.props.showUsedPlacesDialog({ ...template, isDelete: true, dialogType: "EvaluationTemplate" })
                                                            } else {
                                                                this.removeTemplate(id)
                                                            }
                                                        }}
                                                        showUsedPlacesDialog={this.props.showUsedPlacesDialog}
                                                        type="MASTER"
                                                        onClick={url => this.props.history.push(url)}
                                                    />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )}
                            </div>
                        )}
                        {this.props.templateActiveTab === 1 && (
                            <div className={classes.templates}>
                                {this.props.fetchOrgProg === true && <div className={classes.loader}>
                                    <CircularProgress />
                                </div>}
                                {this.props.orgTemplates !== null && (
                                    <Grid container spacing={3}>
                                        {this.props.orgTemplates.map((template) => {
                                            var dflags = this.defaultFlags(template.ID, 'ORG')
                                            return (
                                                <Grid item xs={12} sm={6} md={4} lg={3} key={generate()}>
                                                    <TemplateCard
                                                        isCheckBox={this.state.isCheckBox}
                                                        template={template}
                                                        templateId={this.props.templateId}
                                                        templateType="evaluation"
                                                        dflags={dflags}
                                                        selectedTemplate={this.state.selectedTemplate}
                                                        selectTemplateForImport={this.selectTemplateForImport}
                                                        showUsedPlacesDialog={this.props.showUsedPlacesDialog}
                                                        deleteFromOrgEvaluationTemplate={(id) => {
                                                            if (template?.UsedInWorkflowCount > 0) {
                                                                this.props.showUsedPlacesDialog({ ...template, isDelete: true, dialogType: "EvaluationTemplate" })
                                                            } else {
                                                                this.removeTemplate(id)
                                                            }
                                                        }}
                                                        type="ORG"
                                                        onClick={url => this.props.history.push(url)}
                                                    />
                                                </Grid>
                                            )
                                        })
                                        }

                                    </Grid>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {this.state.selectedTemplate != null && (
                    <div className={classes.bottomContainer}>
                        <Button className={classes.addNewCriteria} onClick={() => {
                            this.props.showAddTemplateDialog({
                                isRequiredTemplateSection: true,
                                isRequiredCriteriaSection: false,
                                isRequiredRequirementSection: false,
                                isRequiredAddRequirement: false,
                                saveButtonText: 'Save',
                            })
                            this.setState({
                                isCheckBox: false
                            })
                        }} > Create Template</Button>
                    </div>
                )}
                {this.state.isOpenCreateTemplateDialog && (
                    <CreateTemplateDialog
                        isOpen={this.state.isOpenCreateTemplateDialog}
                        hideDialog={() => this.setState({ isOpenCreateTemplateDialog: false })}
                    />
                )}
                {this.props.collaboratorDialogOpen && <SearchUserPoper {...this.props.collaboratorDialogData} />}
                <AddTemplateDialog selectedTemplate={this.state.selectedTemplate} />
                <ImportTemplateDialog />
                <MessageDialog messageData={messageData} callBack={this.callBack} />
                {this.props.usedPlacesDialogOpen && <UsedPlacesDialog callBack={(data) => this.props.deleteFromOrgEvaluationTemplate(data.ID)} />}
            </>
        )
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Template));