import axios from 'axios';

axios.interceptors.response.use(
  function (response) {
    if ( response.status === 401 && window.location.pathname !== '/' && (window.location.pathname.includes('app') || window.location.pathname.includes('sellerapp')) ) {
      let redirectTo = window.location.pathname + window.location.search + window.location.hash
      window.location.replace("/?redirectTo=" + encodeURIComponent(redirectTo));
    }
    return response;
  },
  function (error) {
    if ( error?.response?.status === 401 && window.location.pathname !== '/' && (window.location.pathname.includes('app') || window.location.pathname.includes('sellerapp')) ) {
      let redirectTo = window.location.pathname + window.location.search + window.location.hash
      window.location.replace("/?redirectTo=" + encodeURIComponent(redirectTo));
    }
    return Promise.reject(error);
  }
);


export const Axios = axios;
