import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid } from '@material-ui/core';
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from 'query-string';
import AppBarV2 from "Components/Application/AppBarV2";

// Common
import VendorEvaluationCard from "Components/Common/VendorEvaluationCard";
import deleteIcon from "assets/images/delete.svg";
import Image from 'Components/Common/image';
import classnames from "classnames";
import MessageDialog from "Components/Common/MessageDialog";
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";

import { fetchRequests, deleteRequest } from "redux/evaluation/requests/action";
import { showSnackBar } from "redux/snackbar/action";

// Redux
const connectedProps = (state) => ({
    user: state.authUser.user,
    deleteRequestProgress: state.evaluationRequestData.deleteRequestProgress,
    deleteRequestError: state.evaluationRequestData.deleteRequestError,
    userType: state.authUser.user?.Role,
    fetchRequestProgress: state.evaluationRequestData.fetchRequestProgress,
    fetchRequestError: state.evaluationRequestData.fetchRequestError,
    requestData: state.evaluationRequestData.requestData,
});

const connectionActions = {
  fetchRequests: fetchRequests,
  deleteRequest: deleteRequest,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  showSnackBar: showSnackBar
};

// Styles
const styles = (theme) => createStyles({
  containerVendorEvaluation: {
    height: '100%',
  },
  loader: {
    textAlign: 'center',
    padding: '2rem',
    display: 'block',
  },
  NoMoreData: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  btnReview: {
    width: '38px',
    height: '32px',
    border: 'none',
    borderRadius: '5px',
    background: 'transparent',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '13px',
    color: '#626675',
    marginRight: 10,
    right: '2px',
    position: 'absolute',
    top: 50,
    zIndex: 12
  },
  cardSection: {
    position: 'relative'
  },
  content: {
    padding: theme.spacing(4, 10),
    background: "#F7F7F7",
    minHeight: "100vh"
  },
});


var connector = connect(connectedProps, connectionActions);


class VendorWorkflowCards extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 60,
      status: [],
      queryParams: queryString.parse(this.props.location.search),
      deleteId: null
    }
  }

  componentDidMount() {
    this.fetchRequestsData(true);
  }

  fetchRequestsData = (onInit = false) => {
    let query = Object.assign(this.state)
    query.onInit = onInit
    if (this.props.layout === 'inProgress') {
      query.status = [2, 3]
      query.type = [1,2,3,4,10]
    } else if (this.props.layout === 'completed') {
      query.status = [4]
      query.type = [1,2,3,4,10]
    }
    this.props.fetchRequests(query);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.deleteRequestProgress && prevProps.deleteRequestProgress) {
      if (!this.props.deleteRequestError) {
        const scope = this
        this.setState({ page: 0 }, () => {
          this.props.showSnackBar("Request deleted successfully", "success", 3000);
          scope.fetchRequestsData(true);
        });
      } else {
        this.props.showSnackBar("Failed to delete request", "error", 3000);
      }
    }
  }

  routeChange = (item, id) => {
    let redirectPath = `/app/evaluate/${id}/workflow/interim`;
    this.props.history.push(redirectPath);
  }

  deleteReview(id) {
    var me = this;
    this.setState({ deleteId: id }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteRequest(this.state.deleteId);
    }
  };

  render() {
    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let userRole = ["OrgAdmin","OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;

    const requestData = (this.props.requestData && this.props.requestData.Data) || [];
    const dataLength = requestData.length || 0;

    const classes = this.props.classes;

    let products = [];
    if (requestData && Array.isArray(requestData) && requestData.length > 0) {
      requestData.forEach((item, i) => {
        products.push(
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2} key={i} ref={(divElement) => { this.divElement = divElement }} className={classes.cardSection}>
            {adminFlag && <button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={() => this.deleteReview(item.ID)}><Image
              src={deleteIcon}
              alt="forgotpassword_image"
              className={classes.titleImage}
            /></button>}
            <VendorEvaluationCard
              layout={this.props.layout}
              numLayer={1}
              {...item}
              onClick={() => this.routeChange(item, item.ID)}
              users={item.Collaborators}
              queryParams={this.state.queryParams}
            />
          </Grid>
        );
      });
    }

    return <React.Fragment>
      <div className={classes.containerVendorEvaluation}>
      <AppBarV2 title={this.props.title} withBack={true} dropdown={true}/>

        <div id="scrollableDiv" className={classes.content}>
          <InfiniteScroll
            dataLength={dataLength}
            style={{ display: "flex", flexDirection: "column", overflow: "hidden", marginRight: 20 }}
            next={() => {
              let scope = this;
              this.setState({ page: this.state.page + 1 }, () => {
                scope.fetchRequestsData(false);
              });
            }}
            hasMore={
              requestData.length === (this.state.page + 1) * this.state.pageSize
            }
          >
            {(!this.props.fetchRequestProgress || this.state.page !== 0) && <Grid container spacing={3}>
              {products}
            </Grid>}
            {this.props.fetchRequestProgress &&
              <span className={classes.loader}><CircularProgress /></span>
            }
          </InfiniteScroll>
        </div>

        
        {!this.props.fetchRequestProgress && requestData.length === 0 &&
          <span className={classes.NoMoreData}>No more data </span>
        }

      </div>
      <MessageDialog messageData={messageData} callBack={this.callBack} />
    </React.Fragment>;
  }
}

export default connector(compose(withStyles(styles))(VendorWorkflowCards));
