import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import * as Validator from "util/Validation";
import { Dialog, DialogContent, Slide, DialogTitle, Typography, IconButton, DialogActions, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Image from 'Components/Common/image.jsx';
import {
  Grid, InputAdornment
} from '@material-ui/core';
import classnames from 'classnames';
import ReactSelect, { components } from "react-select";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import SqureButton from 'Components/Common/SqureButton';
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import deleteIcon from "assets/images/delete.svg";
import DatePicker from "react-datepicker";
import CalendarImage from "assets/images/calendar.svg";
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import {
  fetchWorkflowConditionRules,
  addOrgWorkflowAction,
  updateOrgWorkflowAction,
} from "redux/evaluation/workflow/action";

import { fetchMasterWorkflowRulesConditions } from "redux/master/workflow/action";
import { fetchFieldOptions } from "redux/departments/action"
import { currencyOptions, currencyCodeMap } from "util/Currency";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'


// dialogs
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  fetchWorkflowConditionRulesProgress: state.evaluationWorkflow.fetchWorkflowConditionRulesProgress,
  workflowConditionRules: state.evaluationWorkflow.workflowConditionRules,
  orgWorkFlowCompList: state.evaluationWorkflow.orgWorkFlowCompList,
  orgWorkFlowActionList: state.evaluationWorkflow.orgWorkFlowActionList,
  fetchWorkflowConditionRulesError: state.masterWorkflow.fetchWorkflowConditionRulesError,
  fetchMasterWorkflowConditionRulesProgress: state.masterWorkflow.fetchMasterWorkflowConditionRulesProgress,
  masterWorkflowConditionRules: state.masterWorkflow.masterWorkflowConditionRules,
  masterWorkFlowActionList: state.masterWorkflow.actions,
  masterWorkFlowCompList: state.masterWorkflow.components,

  userProfileMeta: state.departments.userProfileMeta,
  fieldOptions: state.departments.fieldOptions,
});

const connectionActions = {
  fetchWorkflowConditionRules: fetchWorkflowConditionRules,
  addOrgWorkflowAction: addOrgWorkflowAction,
  updateOrgWorkflowAction: updateOrgWorkflowAction,
  fetchMasterWorkflowRulesConditions: fetchMasterWorkflowRulesConditions,
  fetchFieldOptions: fetchFieldOptions
}

var currencyStyles = {
  control: styles => ({ ...styles, height: 40 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  })
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
    padding: theme.spacing(2, 2) + " !important",
    //minHeight:"300px"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  ruleTableDisabled: {
    width: "100%",
    border: '1px solid #ddd',
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
    pointerEvents: 'none',
  },
  deleteIcon: {
    color: "red"
  },
  decisionRoot: {
    "& ul.answers": {
      listStyle: "none",
      paddingLeft: 0,
      "& li": {
        border: "1px solid #EBEBEB",
        bordeRadius: "4px",
        paddingLeft: "8px",
        marginBottom: "8px",
        "& span": {
          fontSize: 13,
          color: "#6D7278",
        },
      },
    },
    "& [class*='editableField']": {
      background: '#fff',
      maxHeight: 'auto !important',
      minHeight: 'auto !important'
    }
  },
  input: {
    minHeight: 38,
    padding: "2px 8px",
    border: "1px solid #cccccc",
    borderRadius: 3,
    fontSize: 15,
    color: "rgb(51, 51, 51)",
    width: '100%'
  },
  loader: {
    padding: theme.spacing(4),
    textAlign: "center"
  },
  addRuleBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
  conditionElements: {
    marginTop: 20,
    marginBottom: 20,
  },
  indexNumber: {
    width: 10,
    marginTop: 5,
  },
  inputSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15
  },
  requiredField: {
    color: "red",
    marginLeft: 5,
  },
  inputController: {
    flex: 1,
    marginRight: 5,
  },
  formTypeController: {
    marginLeft: 5,
    flex: 1,
    marginRight: 5,
  },
  image: {
    marginTop: 5,
    minWidth: 10,
    cursor: "pointer",
  },
  saveBtnSection: {
    position: "absolute",
    bottom: 0,
    right: 12,
    left: "auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: '#f1f1f1',
    padding: '10px'
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  addMore: {
    fontSize: "12px",
    color: "#4175DF",
    cursor: "pointer",
    display: "inline-block",
    marginTop: 10,
  },
  operand: {
    minWidth: 87,
  },
  ruleTable: {
    width: "100%",
    '& [class*=MuiSelect-root]': {
      minHeight: 22
    }
  },
  ruleRightElement: {
  },
  ruleLeftElement: {
  },
  condition: {
    marginTop: 8,
    fontSize: 14,
    fontWeight: 500
  },
  op2: {
    width: "12%",
    '& [class*=MuiInputBase-formControl]': {
      width: 90,
      marginRight: 10,
    },
  },
  op1: {
    width: "12%",
    marginRight: 5,
    // '& [class*=MuiInputBase-formControl]': {
    //   width: 90,
    //   marginRight: 10,
    // }
    '& [class*=Outlined-formControl]': {
      minWidth: '100%',
      marginRight: 10,
    }
  },
  leftFormOp: {
    width: "22%",
  },
  leftOp: {
    width: "22%",
  },
  rightOp: {
    width: "22%",
    marginLeft: 5,
    "& [class*=select__menu]": {
      maxHeight: 100,
    },
  },
  action: {
    width: '10%',
    textAlign: 'right'
  },
  alerts: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  ruleHead: {
    fontSize: 16,
    fontWeight: 500
  },
  datePicker: {
    "& .react-datepicker__input-container > div": {
      display: "flex",
      minWidth: 300,
      marginBottom: 0,
      alignItems: "baseline",
      "& [class*=MuiInputBase-root]": {
        maxHeight: 33,
        minHeight: 33,
        width: 166,
        "& input": {
          fontSize: theme.spacing(1.8),
          // width: 107,
          textAlign: "center",
        },
      },
      "& [class*=MuiFormLabel-root]": {
        marginBottom: 0,
        marginRight: 29,
      },
    },
    "[class*=MuiMenuItem-root]": {
      color: "#707070",
      fontSize: theme.spacing(1.8),
    },
    "& .react-datepicker-popper": {
      zIndex: 9999,
    }
  },
  decisionSection: {
  },
  itemTitle: {
    fontWeight: 400,
    color: '#282D30',
    fontSize: 14,
    margin: '30px 0px 0px'
  },
  creatable: {
    padding: 0
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 16
  },
  searchAndSelect: {
    padding: 0,
    "& .select__control, & .select__control:hover": {
      border: 'none',
      height: 'inherit',
      boxShadow: 'none',
      minHeight: 44
    },
    "& .select__multi-value, & .select__multi-value__label": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20,
      padding: theme.spacing(.3, .6),
      fontSize: 13
    },
    "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20
    },
  },

});

class ApprovalCriteriaConditionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowRules: [],
      connectedEdges: [],
      conditionElements: [],
      typeOptions: [],
      typeOptionMap: {},
      success: '',
      error: '',
      isShowLoader: true,
      alreadyAddedEdges: [],
      isShowAddRuleButton: false,
    }
  }

  componentDidMount() {
    if (this.props.isMasterWorkflow) {
      this.props.fetchMasterWorkflowRulesConditions(this.props.workflowId, this.props.componentId);
    } else {
      this.props.fetchWorkflowConditionRules(this.props.workflowId, this.props.componentId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchWorkflowConditionRulesProgress === false && prevProps.fetchWorkflowConditionRulesProgress === true) {
      this.initConditions();
    }

    if (this.props.fetchMasterWorkflowConditionRulesProgress === false && prevProps.fetchMasterWorkflowConditionRulesProgress === true) {
      this.initConditions();
    }
  }

  initConditions = () => {
    var formOptions = [];
    var objectOptions = [];
    var typeOptionMap = {};

    let conditionRules = [];
    const { isMasterWorkflow } = this.props;
    if (isMasterWorkflow) {
      conditionRules = this.props.masterWorkflowConditionRules;
    } else {
      conditionRules = this.props.workflowConditionRules;
    }

    conditionRules.push({
      elements: this.props.userProfileMeta.map((o) => {
        return {
          "name": o,
          "condition": 'requestor_profile.' + o,
          "operations": [
            4, 9
          ],
          "type": 100,
          "additional_data": ""
        }
      }),
      form_name: "Requestor",
      is_form: false
    })

    conditionRules.forEach(function (item) {
      if (item.is_form) {
        formOptions.push({
          label: item.form_name,
          value: item.form_name,
        })
      } else {
        objectOptions.push({
          label: item.form_name,
          value: item.form_name,
        })
      }
      typeOptionMap[item.form_name] = [];
      item.elements.forEach(function (elem) {
        typeOptionMap[item.form_name].push(elem);
      });
    });

    var groupedOptions = [
      {
        // label: "Object",
        options: objectOptions
      },
      {
        label: "Forms",
        options: formOptions
      }
    ]
    this.setState({
      workflowRules: isMasterWorkflow ? this.props.masterWorkflowConditionRules : this.props.workflowConditionRules,
      typeOptions: groupedOptions,
      typeOptionMap: typeOptionMap
    }, () => {
      this.checkExistingActions();
    })
  }

  getLeftExpression = (data) => {
    var value = data.value;
    var array = data.formData;
    const leftObj = array.find((item) => item.condition === value)
    if (leftObj) {
      return {
        value: leftObj.condition,
        label: leftObj.name,
        type: leftObj.type,
        data: Validator.isJson(leftObj.additional_data) && JSON.parse(leftObj.additional_data)
      }
    }
    return null;
  }

  getRightExpression = (data) => {
    console.log(data, 'RighExpGet');
    var value = data.value;
    var leftObj = data.leftData;
    if (leftObj.type === 1) {
      return value;
    } else if (leftObj.type === 2) {
      return new Date(value);
    } else if (leftObj.type === 3) {
      if (leftObj.data) {
        return leftObj.data.find((item) => item.value === value)
      }
    } else if (leftObj.type === 4 || leftObj.type === 19) {
      if (leftObj.data) {

        if (value.includes('[')) {
          const convertedValue = Validator.isJson(value) ? JSON.parse(value) : []
          return leftObj.data.filter((item) => (Array.isArray(convertedValue) && convertedValue.indexOf(item.value) !== -1));
        }
        return leftObj.data.find((item) => item.value === parseInt(value))
      }
    } else if (leftObj.type === 5 || leftObj.type === 20) {
      if (leftObj.data) {
        const convertedValue = Validator.isJson(value) ? JSON.parse(value) : []
        return leftObj.data.filter((item) => (Array.isArray(convertedValue) && convertedValue.indexOf(item.value) !== -1));
      }
    } else if (leftObj.type === 16) {
      const convertedValue = Validator.isJson(value) ? JSON.parse(value) : {}
      return convertedValue
    } else if (leftObj.type === 100) {
      const convertedValue = Validator.isJson(value) ? JSON.parse(value) : []
      if (convertedValue) {
        return convertedValue.map((o) => { return { label: o, value: o } })
      } else {
        return ''
      }
    } else {
      return value;
    }
    return null;
  }

  checkExistingActions = () => {
    var me = this;
    const { workflowRules, typeOptionMap } = this.state;
    var formComponentList = [];
    var componentMap = {};
    workflowRules.forEach(function (wr) {
      wr.elements.forEach(function (ele) {
        formComponentList.push(ele);
        componentMap[ele.condition] = wr.form_name;
      });
    });

    var conditionElements = this.props.conditionDialog?.conditions;

    let rules = [];
    if (conditionElements !== undefined && conditionElements !== null && conditionElements.length > 0) {
      conditionElements.forEach((element) => {
        if (element.op_id === 1 || element.op_id === 2) {
          rules[rules.length - 1].op2 = element.op_id;
        } else {
          let leftData = me.getLeftExpression({ value: element.left_expression, formData: formComponentList });
          console.log(leftData, 'leftData')
          if (leftData !== null) {
            var rulee = leftData.value;
            var ruleSelectedType = componentMap[rulee];
            var options = typeOptionMap[ruleSelectedType];
            var reqOptions = options.map((itm) => {
              var obj = {
                value: itm.condition,
                label: itm.name,
                type: itm.type,
                data: Validator.isJson(itm.additional_data) && JSON.parse(itm.additional_data),
                operations: itm.operations,
              }
              return obj;
            });
            rules.push({
              leftOp: leftData,
              op1: element.op_id,
              op2: '',
              rightOP: me.getRightExpression({ value: element.right_expression, leftData: leftData }),
              selectedType: {
                label: ruleSelectedType,
                value: ruleSelectedType
              },
              requirementOptions: reqOptions
            })
          }
        }
      });
    }

    this.setState({
      conditionElements: rules,
      isShowLoader: false
    })
  }

  addNewEdgeConditions = () => {
    var conditionElements = [...this.state.conditionElements];
    var newItem = {
      op2: '1',
      leftOp: null,
      op1: '3',
      rightOP: null,
      selectedType: '',
      requirementOptions: []
    };
    conditionElements.push(newItem);
    this.setState({
      conditionElements: conditionElements
    })
  }

  customGroupHeading = (props) => {
    function handleHeaderClick(id) {
      const node = document.querySelector(`#${id}`).parentElement
        .nextElementSibling;
      const classes = node.classList;
      if (classes.contains("collapsed")) {
        node.classList.remove("collapsed");
      } else {
        node.classList.add("collapsed");
      }
    };
    return (
      <div
        className="group-heading-wrapper"
        onClick={() => {
          handleHeaderClick(props.id)
        }}
      >
        <components.GroupHeading {...props} />
      </div>
    );
  };

  getComponentObjectFromId = (id) => {
    return this.props.orgWorkFlowCompList && this.props.orgWorkFlowCompList.find((item) => item.ID === parseInt(id));
  }

  getMasterComponentObjectFromId = (id) => {
    return this.props.masterWorkFlowCompList && this.props.masterWorkFlowCompList.find((item) => item.ID === parseInt(id));
  }

  addNewRuleForEdge = (index) => {
    var conditionElements = [...this.state.conditionElements];
    var newRule = {
      op2: '1',
      leftOp: null,
      op1: '3',
      rightOP: null,
      selectedType: '',
      requirementOptions: []
    }
    conditionElements.splice(index + 1, 0, newRule);
    this.setState({
      conditionElements: conditionElements
    })
  }

  deleteRuleForEdge = (index) => {
    var conditionElements = [...this.state.conditionElements];
    conditionElements.splice(index, 1);
    this.setState({
      conditionElements: conditionElements
    })
  };

  handleChangeType = (selectedType, index) => {
    var conditionElements = [...this.state.conditionElements];
    var rules = conditionElements[index];
    rules.selectedType = selectedType;
    rules.leftOp = null;
    rules.rightOP = null;
    var options = this.state.typeOptionMap[selectedType.label];
    var reqOptions = options.map((itm) => {
      var obj = {
        value: itm.condition,
        label: itm.name,
        type: itm.type,
        data: Validator.isJson(itm.additional_data) && JSON.parse(itm.additional_data),
        operations: itm.operations,
      }
      return obj;
    });
    conditionElements[index].requirementOptions = reqOptions;
    this.setState({
      conditionElements: conditionElements
    })
  }

  onLeftChange = (index, value) => {
    var conditionElements = [...this.state.conditionElements];
    var rule = conditionElements[index];
    rule.leftOp = value;
    if (value.type === 16) {
      rule.rightOP = {
        type: {
          "label": "USD",
          "value": "USD"
        }, value: 0
      };
    } else {
      rule.rightOP = null;
    }
    this.setState({
      conditionElements: conditionElements
    })
  }

  onRightChange = (index, value) => {
    var conditionElements = [...this.state.conditionElements];
    var rule = conditionElements[index];
    rule.rightOP = value;
    this.setState({
      conditionElements: conditionElements
    })
  }

  handleChangeCurrencyValue(field, value, index) {
    var conditionElements = [...this.state.conditionElements];
    var rule = conditionElements[index];
    rule.rightOP[field] = value;
    this.setState({
      conditionElements: conditionElements
    })
  }

  operad1Change = (index, e) => {
    var value = '';
    if (e.target?.value) {
      value = e.target.value.toString();
    } else if (!e.target?.value) {
      value = '';
    }
    var conditionElements = [...this.state.conditionElements];
    var rule = conditionElements[index];
    rule.op1 = value;
    this.setState({
      conditionElements: conditionElements
    })
  }

  operand2Change = (index, e) => {
    var value = '';
    if (e.target?.value) {
      value = e.target.value.toString();
    } else if (!e.target?.value) {
      value = '';
    }
    var conditionElements = [...this.state.conditionElements];
    var rule = conditionElements[index];
    rule.op2 = value;
    this.setState({
      conditionElements: conditionElements
    })
  }

  saveDecisionConditions = () => {
    this.setState({
      success: '',
      error: ''
    })

    const { conditionElements } = this.state;
    let request = [];
    let errorMessage = '';
    let seq = 1;
    conditionElements.forEach((ruleElem, index) => {
      if (errorMessage.length > 0) {
        return;
      }

      if (!ruleElem.leftOp) {
        errorMessage = `Error Row ${index + 1}: please select rule.`;
        return;
      }

      if (!ruleElem.op2 && index > 2) {
        errorMessage = `Error Row ${index + 1}: please select oprand`;
        return;
      }

      if (!ruleElem.op1) {
        errorMessage = `Error Row ${index + 1}: please select oprand`;
        return;
      }

      if (!ruleElem.rightOP || ruleElem.rightOP?.length === 0) {
        errorMessage = `Error Row ${index + 1}: please enter/select option.`;
        return;
      }

      let rightExp;
      if (ruleElem.leftOp.type === 16) {
        rightExp = JSON.stringify(ruleElem.rightOP)
      } else {
        rightExp = !Array.isArray(ruleElem.rightOP) ? (ruleElem.rightOP?.value ? ruleElem.rightOP?.value : ruleElem.rightOP) : JSON.stringify(ruleElem.rightOP.map((i) => {
          return i.value
        }));

        if (ruleElem.leftOp.type === 4 || ruleElem.leftOp.type === 19) {
          rightExp = rightExp.toString();
        }
      }

      request.push({
        left_expression: ruleElem.leftOp.value,
        op_id: parseInt(ruleElem.op1),
        right_expression: rightExp,
        seq: seq
      })
      seq++;
      if (index !== 0) {
        request.push({
          left_expression: 'E=' + (seq - 2),
          op_id: parseInt(ruleElem.op2),
          right_expression: 'E=' + (seq - 1),
          seq: seq
        })
        seq++;
      }
    })
    this.setState({
      error: errorMessage
    });
    if (!errorMessage) {
      this.props.onSave(request);
    }
  }

  getFieldOptions(field) {
    if (field) {
      this.props.fetchFieldOptions(field?.label)
    }
  }


  render() {
    const classes = this.props.classes;
    let { conditionElements, typeOptions, error, success } = this.state;
    console.log({ conditionElements, error })
    const operand = [
      {
        value: 3,
        label: "===",
      },
      {
        value: 4,
        label: "in",
      },
      {
        value: 5,
        label: ">",
      },
      {
        value: 6,
        label: ">=",
      },
      {
        value: 7,
        label: "<",
      },
      {
        value: 8,
        label: "<=",
      },
      {
        value: 9,
        label: "!in",
      },
      {
        value: 10,
        label: "!=",
      },
    ];

    console.log(typeOptions, 'TypeeeeeeOptionssssssssss')


    return <Dialog
      onClose={this.props.onClose}
      aria-labelledby="app-evconditionDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={false}
      fullWidth={true}
      maxWidth={"md"}
      scroll="body"
      id="evApprovalCriteriaConditionDialog"
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      <DialogTitle disableTypography>
        {this.props.dialogContent == null && <>
          <Typography variant={"h6"}> {this.props.conditionDialog?.criteria?.name} Conditions</Typography>
        </>}
        <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true} classes={{ root: classes.dialogContent }} style={{ overflowY: 'visible' }}>
        <div className={classes.decisionSection}>
          <div className={classes.decisionRoot}>
            <div className={classes.alerts}>
              {error.length > 0 && <Alert variant="filled" severity="error">{error}</Alert>}
              {success.length > 0 && <Alert variant="filled" severity="success">{success}</Alert>}
            </div>

            {conditionElements.map((rule, index) => {

              return <div key={index} className={classes.conditionElements}>
                {<table className={classes.ruleTable}>
                  <tbody>
                    <tr className={classes.inputSection}>
                      <td className={classes.op2}>
                        {index !== 0 && (
                          <div>
                            <OutlinedSelectInput
                              value={rule.op2}
                              options={[
                                {
                                  value: 1,
                                  label: "And",
                                },
                                {
                                  value: 2,
                                  label: "Or",
                                },
                              ]}
                              noMargin={true}
                              compact={true}
                              onChange={(e) => {
                                this.operand2Change(index, e)
                              }}
                            />
                          </div>
                        )}
                        {index === 0 && (
                          <div className={classes.condition}>Condition</div>
                        )}
                      </td>
                      <td className={classnames(classes.formTypeController, classes.leftFormOp)}>
                        <ReactSelect
                          options={typeOptions}
                          blurInputOnSelect={false}
                          closeMenuOnSelect={true}
                          placeholder="Select Type"
                          components={{ GroupHeading: this.customGroupHeading }}
                          value={rule.selectedType}
                          onChange={(value) => {
                            this.handleChangeType(value, index);
                          }}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                      </td>
                      <td className={classnames(classes.inputController, classes.leftOp)}>
                        <ReactSelect
                          isClearable
                          menuPosition='fixed'
                          isSearchable={true}
                          options={rule.requirementOptions}
                          classNamePrefix="select"
                          placeholder="Search rule"
                          onChange={(e) => {
                            // console.log(e, 'Selected Left')
                            this.onLeftChange(index, e);
                          }}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          value={rule.leftOp}
                        />
                      </td>
                      <td className={classes.op1}>
                        <OutlinedSelectInput
                          placeholder='Operand'
                          value={rule.op1}
                          // options={operand}
                          options={rule.requirementOptions !== undefined && rule.leftOp !== null ? operand.filter(o => rule.requirementOptions.filter(j => j.label === rule.leftOp.label)[0]?.operations.indexOf(o.value) !== -1) : operand}
                          noMargin={true}
                          compact={true}
                          onChange={(e) => {
                            this.operad1Change(index, e)
                          }}
                        />
                      </td>
                      <td className={classnames(classes.ruleRightElement, classes.rightOp)}>
                        {!rule?.leftOp?.type && (
                          <div>
                            <input
                              placeholder='Enter Option'
                              className={classes.input}
                            />
                          </div>
                        )}

                        {(rule?.leftOp?.type === 1 || rule?.leftOp?.type === 18 || rule?.leftOp?.type === 12 || rule?.leftOp?.type === 13 || rule?.leftOp?.type === 12 || rule?.leftOp?.type === 15) && <div>
                          <input
                            className={classes.input}
                            placeholder='Enter Option'
                            onChange={(e) => {
                              this.onRightChange(index, e.target?.value || '')
                            }}
                            value={rule.rightOP}
                          />
                        </div>}

                        {rule?.leftOp?.type === 2 &&
                          <div className={classes.datePicker}>
                            <DatePicker
                              selected={rule.rightOP}
                              onChange={date => {
                                this.onRightChange(index, new Date(date))
                              }}
                              dateFormat="MMM do yyyy"
                              portalId="evMetaDialogApprovalCondition"
                              minDate={null}
                              showMonthDropdown
                              showYearDropdown
                              className={classes.datePicker}
                              dropdownMode="select"
                              customInput={
                                <OutlinedInput
                                  readOnlyInput={true}
                                  startAdornment={
                                    <InputAdornment>
                                      <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                    </InputAdornment>
                                  }
                                />
                              }
                            />
                          </div>
                        }

                        {(rule?.leftOp?.type === 3 || rule?.leftOp?.type === 4 || rule?.leftOp?.type === 5 || rule?.leftOp?.type === 19 || rule?.leftOp?.type === 20) && <div>
                          <ReactSelect
                            isClearable
                            isSearchable={true}
                            isMulti={rule?.leftOp?.type === 5 || rule?.leftOp?.type === 20 ? true : (rule?.leftOp?.type === 4 && (rule?.op1 === 4 || rule?.op1 === 9) ? true : false)}
                            menuPosition='fixed'
                            options={rule?.leftOp?.data}
                            classNamePrefix="select"
                            placeholder="Search Option"
                            onChange={(e) => {
                              this.onRightChange(index, e)
                            }}
                            value={rule.rightOP}
                          />
                        </div>}

                        {rule?.leftOp?.type === 100 &&
                          <div className={classes.datePicker}>
                            <ReactSelect
                              isMulti={true}
                              isClearable={false}
                              options={this.props.fieldOptions ? this.props.fieldOptions.map((itm) => {
                                return { label: itm, value: itm }
                              }) : []}
                              classNamePrefix="select"
                              placeholder={"Select Value"}
                              onChange={(e) => {
                                this.onRightChange(index, e)
                              }}
                              onFocus={() => {
                                this.getFieldOptions(rule.leftOp)
                              }}
                              value={rule.rightOP}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                          </div>
                        }

                        {rule?.leftOp?.type === 16 &&
                          <div className={classes.currencyTypeFields} style={{ display: 'flex' }}>
                            <div style={{ width: 200, marginRight: 5, zIndex: 6565 }}>
                              <ReactSelect
                                isMulti={false}
                                labelId="singleSelect-select-label"
                                id="singleSelect-select"
                                value={rule.rightOP && rule.rightOP.type ? rule.rightOP.type : {
                                  "label": "USD",
                                  "value": "USD"
                                }}
                                onChange={(e) => {
                                  this.handleChangeCurrencyValue('type', e, index)
                                }}
                                options={currencyOptions}
                                placeholder={"Select a Option"}
                                styles={currencyStyles}
                              >
                              </ReactSelect>
                            </div>

                            <CurrencyTextField
                              variant="outlined"
                              value={rule.rightOP && rule.rightOP.value ? rule.rightOP.value : '0'}
                              currencySymbol={currencyCodeMap[rule?.rightOP?.value]}
                              style={{ textAlign: 'left', height: 40 }}
                              outputFormat="string"
                              decimalCharacter="."
                              digitGroupSeparator=","
                              onChange={(e, v) => {
                                this.handleChangeCurrencyValue('value', v, index)
                              }}
                            />
                          </div>
                        }

                      </td>
                      <td className={classes.action}>
                        {<span hidden={conditionElements.length === 1}> <IconButton size="small" onClick={() => { this.deleteRuleForEdge(index) }}>
                          <Image alt="Delete" src={deleteIcon} />
                        </IconButton> </span>}
                        {<span hidden={index !== conditionElements.length - 1}><IconButton size="small" onClick={() => { this.addNewRuleForEdge(index) }}>
                          <AddCircleIcon />
                        </IconButton>  </span>}
                      </td>
                    </tr>
                    {rule?.leftOp?.type === "TextArea" && <tr>
                      <div>
                        <SlateInputField
                          // folderPath={`/workflow/${params.id}/`}
                          as={SlateInputField}
                          onChangeEvent={(value) => {
                            // onRightChange(k, value)
                          }}
                          initValue={rule.rightOP}
                          textContainerStyle={{ color: "rgb(51, 51, 51)" }} />
                      </div>
                    </tr>}
                  </tbody>
                </table>}
              </div>
            })}

            {conditionElements.length <= 0 && <Grid container justify="center" className={classes.addRuleBtn}>
              <SqureButton
                variant={"contained"}
                onClick={() => {
                  this.addNewEdgeConditions();
                }}
              >
                + Add Conditions
              </SqureButton>
            </Grid>}

          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" className={classes.saveBtn} onClick={this.saveDecisionConditions}>
          Save
        </Button>
        <Button variant="outlined" color="secondary" className={classes.saveBtn} onClick={this.props.onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ApprovalCriteriaConditionDialog));