import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { Grid } from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import SqureButton from 'Components/Common/SqureButton';

import { showSubmitAllDraftResponcesDialog, hideSubmitAllDraftResponcesDialog, setSelectedRequirement } from "redux/seller/action";
import SubmitAllResponsesDialog from "../Common/SubmitAllResponsesDialog";

const connectedProps = (state) => ({
  evaluation: state.seller.evaluation,
  isOpenSubmitAllDialog: state.seller.submitAllSellerResponseDialogOpen,
  requirementTree: state.seller.requirementTree,
  selectedRequirementId: state.seller.selectedRequirementId,
});

const connectionActions = {
  setSelectedRequirement: setSelectedRequirement,
  showSubmitAllDraftResponcesDialog: showSubmitAllDraftResponcesDialog,
  hideSubmitAllDraftResponcesDialog: hideSubmitAllDraftResponcesDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  draftBtn: {
    marginRight: 10,
    color: '#707070',
    borderColor: '#707070',
  },
  bottomLinks: {
    position: "fixed",
    bottom: "0",
    padding: theme.spacing(1),
    left: 0,
    right: 0,
    paddingLeft: theme.spacing(24),
    paddingRight: theme.spacing(4),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px -1px 6px #00000010",
    zIndex: 1000
  }
});

class BottomActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.saveAsDraft = this.saveAsDraft.bind(this);
    this.submit = this.submit.bind(this);
    this.submitAll = this.submitAll.bind(this);
  }

  submit() {
    this.props.submit();
  }

  submitAll() {
    // this.props.showSubmitAllDraftResponcesDialog();
    this.props.submitQuesnnaire()
  }

  saveAsDraft() {
    this.props.saveAsDraft();
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  render() {
    const classes = this.props.classes;
    return (
      <div>
        <Grid container spacing={2} className={classes.bottomLinks}>
          <Grid item xs={8}>
          </Grid>
          <Grid item xs={4} lg={4} md={4} sm={4} style={{ textAlign: 'right' }}>
            {this.props.isBackBtnRequired && <SqureButton variant={"outlined"} startIcon={<ArrowBackIosIcon />} onClick={this.props.goToInstruction}>Go to Instructions</SqureButton>}
            <SqureButton
              disabled={this.props.isDisable}
              style={{ "marginLeft": "10px" }}
              variant={"contained"}
              endIcon={<SendIcon />}
              onClick={this.submitAll}>Submit Questionnaire</SqureButton>
          </Grid>
        </Grid>

        {this.props.isOpenSubmitAllDialog && <SubmitAllResponsesDialog />}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(BottomActions));
