import React from 'react';
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from 'moment';
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Popover, Divider, IconButton, Collapse, CircularProgress } from '@material-ui/core';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import UserGroup from '../../../../../../../Common/UserGroup';
import Requirement from "./Requirement";
import deleteIcon from "../../../../../../../../assets/images/delete.svg"
import classnames from "classnames";
import Image from 'Components/Common/image.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
// redux
import { showMessageDialog, hideMessageDialog, } from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { showCriteriaRuleDialog } from "redux/common/action";
import { showFeatureDialog } from "redux/evaluation/metadata/action";
import { showEvaluationRuleDialog } from "redux/evaluation/rule/action";
import { showEvaluationRequirementAddDialog, showEvaluationCriteriaEditDialog, deleteCriteria, editCriteria } from "redux/evaluation/criteria/action"
import { expandEvaluationCriteria, addCriteriaColloborator, editRequirement, expandEvaluationRequirement, updateCriteriaColloborator } from "redux/evaluation/criteria/action";
import { generate } from 'shortid';

const connectedProps = (state) => ({
    expandedCriteriaId: state.evaludationCriteria.expandedCriteriaId,
    expandedRequirementId: state.evaludationCriteria.expandedRequirementId,
    user: state.authUser.user,
    criterias: state.evaludationCriteria.criterias,
    evaluation: state.evaluationMetaData.evaluation,
    editCriteriaProgress: state.evaludationCriteria.editCriteriaProgress,
});

const connectionActions = {
    showEvaluationRequirementAddDialog: showEvaluationRequirementAddDialog,
    expandEvaluationCriteria: expandEvaluationCriteria,
    showEvaluationCriteriaEditDialog: showEvaluationCriteriaEditDialog,
    deleteCriteria: deleteCriteria,
    addCriteriaColloborator: addCriteriaColloborator,
    showFeatureDialog: showFeatureDialog,
    editCriteria: editCriteria,
    showEvaluationRuleDialog: showEvaluationRuleDialog,
    showCriteriaRuleDialog: showCriteriaRuleDialog,
    editRequirement: editRequirement,
    showMessageDialog: showMessageDialog,
    hideMessageDialog: hideMessageDialog,
    expandEvaluationRequirement: expandEvaluationRequirement,
    updateCriteriaColloborator: updateCriteriaColloborator
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        borderRadius: 5,
        marginBottom: 15,
        position: 'relative',
        backgroundColor: '#FFFFFF',
    },
    btns: {
        "& button": {
            boxShadow: '4px 0px 4px #2B2D3703',
            border: '1px solid #20253a33',
            background: 'transparent',
            borderRadius: '6px',
            opacity: 1,
            color: '#707070',
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'rgba(75, 134, 255, 0.04)'
            }
        }
    },
    criteriaHead: {
        padding: '11px 22px',
        cursor: "pointer",
        justifyContent: 'space-between',
    },
    criteriaExtended: {
        borderBottom: '1px solid #EEEEEE;'
    },
    expand: {
        transform: 'rotate(0deg)',
        padding: 0,
        borderRadius: 3,
        border: '1px solid #EDEDED',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    titleTxt: {
        width: 'max-content',
        padding: 2,
        borderRadius: 1,
        fontSize: theme.spacing(2),
        color: '#282D30',
        fontWeight: 600,
        '&:hover': {
            background: '#f7f7f7'
        }
    },
    OutlinedInput: {
        minHeight: '25px !important',
        padding: 5,
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        color: '#5F5F5F',
        fontSize: '1.1em',
    },
    priorityOutlinedInput: {
        minHeight: '25px !important',
        padding: 5,
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        color: '#5F5F5F',
        fontSize: '1.1em',
        width: 75,
        textAlign: 'center'
    },
    actionTxt: {
        color: '#4175DF',
        width: 'max-content',
        fontSize: theme.spacing(1.75),
    },
    menuSection: {
        display: 'flex',
        "& > [class*=MuiSvgIcon-root]": {
            fill: '#282D30',
            height: 20
        },
        marginLeft: 10,
    },
    checkboxSection: {
        paddingRight: 15,
    },
    checkbox: {
        border: 'none',
        margin: 0
    },
    saveLoader: {
        width: '15px!important',
        height: '15px!important',
    },
    loaderSpan: {
        margin: 17,
        fontSize: 25
    },
    divider: {
        width: '100%',
        textAlign: 'center',
        borderBottom: '1px solid #FAFAFA',
        lineHeight: '0.1em',
        margin: '10px 0 20px',
        '& span': {
            background: '#fff',
            color: '#A0BAEF',
            padding: '0 10px',
            fontSize: theme.spacing(1.75)
        }
    },
    editIcon: {
        color: "#999999"
    },
    hrLine: {
        width: '100%',
        textAlign: 'center',
        lineHeight: '0.1em',
        margin: '0 0 20px',
        borderBottom: '1px solid #49B8FA',
        paddingTop: 20,
        '& span': {
            padding: '3px 8px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxhadow: '4px 0px 4px #2B2D3703',
            border: '1px solid #A0BAEF',
            borderRadius: 4,
            opacity: 1,
            fontSize: theme.spacing(2),
            color: '#4175DF',
            fontWeight: 400,
            cursor: 'pointer'
        }
    },
    actionIcon: {
        width: 30,
        height: 30,
        borderRadius: 5,
        textAlign: 'center',
        marginLeft: 5,
        border: '1px solid #4b86f8'
    },
    micon: {
        fontSize: 16,
        marginTop: 6,
        color: '#4b86f8',
    },
    dragIconArea: {
        position: 'absolute',
        top: 15,
        left: '50%',
        color: '#D9D9D9',
        transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
    },
    requirementConainer: {
        padding: '18px 22px 0px 22px',
    },
    addRequirementAction: {
        padding: '0 22px',
    },
});

class Criteria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            name: props.criteria?.name || "",
            name_error: "",
            isEditName: false,
            deleteId: null,
            isActiveDialog: false,
            isUpdateCriteriaPriority: false,
            priority: props.criteria?.priority === 0 ? '' : props.criteria?.priority,
            priority_error: "",
            mandatoryLoader: false,
            editableLoader: false,
        }
        this.onDragEnd = this.onDragEnd.bind(this);
        this.deleteCriteriaItem = this.deleteCriteriaItem.bind(this);
        this.handleToggleRequirement = this.handleToggleRequirement.bind(this)
    }

    componentDidUpdate(prevProps) {
        const { editCriteriaProgress } = this.props;
        if (!editCriteriaProgress && prevProps.editCriteriaProgress) {
            this.stopLoaders();
        }
    }

    stopLoaders() {
        this.setState({
            mandatoryLoader: false,
            editableLoader: false,
        })
    }

    handleToggleRequirement = (id) => {
        if (id !== null || id !== undefined) {
            this.props.expandEvaluationRequirement(id)
        }
    }

    handleClick = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget || event.current });
    };

    handleClose = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: null });
    };

    toggleMandatory = (event) => {
        this.setState({
            mandatoryLoader: true
        }, () => {
            this.props.editCriteria(this.props.match.params?.evaluation_id, this.props.criteria?.id, {
                name: this.props.criteria?.name,
                schedule: this.props.criteria?.schedule,
                is_editable: this.props.criteria?.is_editable,
                is_mandatory: event.target.checked
            },this.props.match?.params?.component_id)
        })
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const { destination, draggableId, source } = result;
        if (draggableId && destination.index !== source.index) {
            this.props.editRequirement(
                this.props.match.params?.evaluation_id,
                this.props.criteria?.id,
                parseInt(draggableId),
                {
                    "seq": destination.index
                },
                this.props.match?.params?.component_id
            )
        }
    }

    toggleEditable = (event) => {
        this.setState({
            editableLoader: true
        }, () => {
            this.props.editCriteria(this.props.match.params?.evaluation_id, this.props.criteria?.id, {
                name: this.props.criteria?.name,
                schedule: this.props.criteria?.schedule,
                is_editable: event.target.checked,
                is_mandatory: this.props.criteria?.is_mandatory
            },this.props.match?.params?.component_id)
        })
    }

    cleanError() {
        this.setState({
            name_error: "",
            priority_error: ''
        })
    }

    saveName(name, evaluation_id, criteria_id) {
        this.cleanError();
        if (name.trim().length === 0) {
            this.setState({
                name_error: "Please enter Section Name."
            });
            return;
        }
        this.props.editCriteria(evaluation_id, criteria_id, {
            name: this.state.name,
        },this.props.match?.params?.component_id)
        this.setState({
            isEditName: false
        });
    }

    savePriority(priority, evaluation_id, criteria_id) {
        this.cleanError();
        this.props.editCriteria(evaluation_id, criteria_id, {
            priority: parseInt(this.state.priority)
        },this.props.match?.params?.component_id)
        this.setState({
            isUpdateCriteriaPriority: false
        });
    }

    deleteCriteriaItem(e, id) {
        e.stopPropagation();
        var me = this;
        this.setState({ deleteId: id, isActiveDialog: true }, () => {
            me.props.showMessageDialog();
        })
    }

    callBack = () => {
        if (this.state.deleteId) {
            this.props.hideMessageDialog();
            this.props.deleteCriteria(this.props.match.params.evaluation_id, this.state.deleteId);
            this.setState({
                isActiveDialog: false
            })
        }
    };

    updatePriority(criteriaId) {
        this.setState({
            priority_error: ''
        })
        if (this.state.priority <= 0 || this.state.priority > 100) {
            this.setState({
                priority_error: 'Please enter weightage between 1 to 100.'
            })
        } else {
            this.savePriority(
                this.state.priority,
                this.props.match.params?.evaluation_id,
                criteriaId,
            )
        }
    }

    render() {
        const classes = this.props.classes;
        if (this.props.criteria == null || this.props.criteria === undefined) {
            return null;
        }
        const criteria = this.props.criteria;

        const open = Boolean(this.state.anchorEl);
        var isEditable = false;
        var editable = true;

        if (criteria.is_mandatory || !criteria.is_editable) {
            editable = false;
        }

        if (this.props.user?.Role === "OrgAdmin" || this.props.evaluation?.UserID === this.props.user?.ID) {
            isEditable = true
            editable = true;
        }

        if (!this.props.isEditable) {
            isEditable = false
            editable = false;
        }

        var scheduleText = "+ Add a Schedule";
        if (criteria.schedule !== null) {
            scheduleText = moment(criteria.schedule).format("MMM Do YYYY")
        }

        var showRule = this.props.user.Role === "OrgAdmin";
        // var showRule = ['/app/evaluate/:evaluation_id/setup'].indexOf(this.props?.match?.path) > -1 && this.props.user.Role === "OrgAdmin";

        let messageData = {
            head: "Are you sure?",
            subHead: "Do you really want to delete? This process cannot be undone.",
            firstBtn: "Cancel",
            secondBtn: "Yes",
        };

        var priorityText = "Add Weightage";
        if (criteria.priority !== null && criteria.priority !== '' && criteria.priority > 0) {
            priorityText = 'Weightage: ' + criteria.priority + '%';
        }

        return (
            <div className={classes.root}>
                {this.props.expandedCriteriaId !== criteria.id &&
                    !this.state.isEditName && (
                        <DragIndicatorOutlinedIcon className={classes.dragIconArea} />
                    )}
                <Grid
                    container
                    alignItems="center"
                    onClick={() => {
                        if (this.props.expandedCriteriaId === criteria.id) {
                            this.props.expandEvaluationCriteria(null)
                        } else {
                            this.props.expandEvaluationCriteria(criteria.id)
                        }
                    }}
                    className={classnames(classes.criteriaHead, this.props.expandedCriteriaId === criteria.id ? classes.criteriaExtended : {})}
                >

                    <Grid item style={{ paddingRight: 20 }}>
                        {!this.state.isEditName
                            ? (
                                <Typography className={classes.titleTxt}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (editable) {
                                            this.setState({
                                                isEditName: true
                                            })
                                        }
                                    }}
                                >
                                    {this.state.name}
                                    {criteria.is_mandatory && <font style={{ color: "red" }}>*</font>}
                                </Typography>
                            ) : (
                                <Grid container style={{ alignItems: 'end' }}>
                                    <input
                                        value={this.state.name}
                                        onChange={(event) => {
                                            this.setState({ name: event.target.value });
                                        }}
                                        className={classes.OutlinedInput}
                                    />
                                    <Grid container style={{ width: 'max-content', alignItems: 'end' }}>
                                        <div
                                            className={classes.actionIcon}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                this.cleanError()
                                                this.setState({ name: criteria.name, isEditName: false })
                                            }}
                                        >
                                            <ClearIcon className={classes.micon} />
                                        </div>
                                        {this.state.name && (
                                            <div
                                                className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (this.state.name) {
                                                        this.saveName(
                                                            this.state.name,
                                                            this.props.match.params?.evaluation_id,
                                                            criteria.id,
                                                        )
                                                    }
                                                }}
                                            >
                                                <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            )
                        }
                        <Grid container alignItems="center" style={{ marginTop: 7 }}>
                            {!this.state.isUpdateCriteriaPriority && <Typography
                                className={classes.actionTxt}
                                onClick={(e) => {
                                    if (!editable) {
                                        return;
                                    }
                                    e.stopPropagation();
                                    this.props.showEvaluationCriteriaEditDialog(
                                        this.props.match.params.evaluation_id,
                                        criteria
                                    )
                                }}
                            >
                                {scheduleText}
                            </Typography>}

                            {false && !this.state.isUpdateCriteriaPriority && !this.state.isDefaultPage && showRule && (
                                <Divider orientation="vertical" flexItem style={{ margin: '0px 10px' }} />
                            )}

                            {false && !this.state.isUpdateCriteriaPriority && showRule && (
                                <Typography
                                    className={classes.actionTxt}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.showCriteriaRuleDialog({ 
                                            rules: criteria?.rules || [], 
                                            isEvaluation: true, 
                                            criterias: this.props.criterias, 
                                            criteria: criteria, 
                                            criteriaId: criteria.id, 
                                            id: this.props.match.params?.evaluation_id, 
                                            requirements: criteria.requirements 
                                        })
                                    }}
                                >
                                    {criteria?.rules?.length === 0 ? "Add Rule" : "Update Rule"}
                                </Typography>
                            )}

                            {this.props.enableScoring && !this.state.isUpdateCriteriaPriority && <Divider orientation="vertical" flexItem style={{ margin: '0px 10px' }} />}

                            {this.state.priority_error !== null && this.state.priority_error.length > 0 && <>
                                <span style={{ color: 'red', fontSize: 12 }}>{this.state.priority_error}</span>
                            </>}

                            {this.props.enableScoring && <>
                                {!this.state.isUpdateCriteriaPriority
                                    ? (
                                        <Typography
                                            className={classes.actionTxt}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({
                                                    isUpdateCriteriaPriority: true
                                                })
                                            }}>{priorityText}</Typography>
                                    ) : (
                                        <Grid container style={{ alignItems: 'end' }}>
                                            <input
                                                value={this.state.priority}
                                                pattern="[0-9]"
                                                className={classes.priorityOutlinedInput}
                                                onChange={(event) => {
                                                    var newValue = parseInt(event.target.value) || '';
                                                    this.setState({ priority: newValue });
                                                }}
                                            ></input>
                                            <Grid container style={{ width: 'max-content', alignItems: 'end' }}>
                                                <div
                                                    className={classes.actionIcon}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.cleanError()
                                                        this.setState({
                                                            priority_error: '',
                                                            priority: criteria.priority === 0 ? '' : criteria.priority,
                                                            isUpdateCriteriaPriority: false
                                                        })
                                                    }}
                                                >
                                                    <ClearIcon className={classes.micon} />
                                                </div>
                                                <div
                                                    className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.updatePriority(criteria?.id)

                                                    }}
                                                >
                                                    <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )}
                            </>}


                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            <UserGroup
                                evaluationId={this.props.match.params.evaluation_id}
                                criteriaId={criteria.id}
                                criterias={this.props.criterias}
                                editable={editable}
                                position={'CriteriaHeadSetup'}
                                isShowAddWeightage={this.props.enableScoring ? true : false}
                                showNotificationIcon={true}
                                notificationData={{
                                    type: 'notification.evaluation.criteria.colloborator.nudge',
                                    criteria: criteria
                                }}
                                onAdd={(user) => {
                                    this.props.addCriteriaColloborator(
                                        this.props.match.params.evaluation_id,
                                        criteria.id,
                                        user.ID,
                                        user?.isApprover)
                                }}
                                updatePriority={(user) => {
                                    this.props.updateCriteriaColloborator(
                                        this.props.match.params.evaluation_id,
                                        criteria.id,
                                        user.ID,
                                        user?.priority)
                                }}
                                users={criteria.colloborator}
                            />
                            {editable && (
                                <div
                                    className={classes.menuSection}
                                    onClick={(e) =>
                                        this.deleteCriteriaItem(e, criteria.id)
                                    }
                                >
                                    <Image alt="Delete" src={deleteIcon} />
                                </div>
                            )}
                            {isEditable && (
                                <div className={classes.menuSection} onClick={this.handleClick}><MoreVertIcon /></div>
                            )}
                            <Popover
                                id="criteriaPopover"
                                open={open}
                                anchorEl={this.state.anchorEl}
                                onClose={(event) => this.handleClose(event)}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <div onClick={(e) => e.stopPropagation()}>
                                    <div className={classes.checkboxSection}>
                                        {!this.state.mandatoryLoader && <Checkbox
                                            classes={{ root: classes.checkbox, checked: classes.checked }}
                                            checked={criteria?.is_mandatory}
                                            onChange={(event) => this.toggleMandatory(event)}
                                            disabled={!editable}
                                            name="Mandatory"
                                        />}
                                        {this.state.mandatoryLoader && <span className={classes.loaderSpan}><CircularProgress className={classes.saveLoader} /></span>}
                                        Required
                                    </div>
                                    <Divider />
                                    {criteria && !criteria?.is_mandatory && <div className={classes.checkboxSection}>
                                        {!this.state.editableLoader && <Checkbox
                                            classes={{ root: classes.checkbox, checked: classes.checked }}
                                            disabled={!editable || criteria.is_mandatory === true}
                                            checked={criteria?.is_editable}
                                            onChange={(event) => this.toggleEditable(event)}
                                            name="Editable"
                                        />}
                                        {this.state.editableLoader && <span className={classes.loaderSpan}><CircularProgress className={classes.saveLoader} /></span>}
                                        Editable
                                    </div>}
                                </div>
                            </Popover>
                            <IconButton
                                className={classnames(classes.menuSection, classes.expand, {
                                    [classes.expandOpen]: this.props.expandedCriteriaId === criteria.id,
                                })}
                                aria-expanded={this.props.expandedCriteriaId === criteria.id}
                                aria-label="show more"
                            >
                                <ArrowDropDownIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Collapse in={this.props.expandedCriteriaId === criteria.id} timeout="auto" unmountOnExit>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={classes.requirementConainer}
                                >
                                    {criteria.requirements !== null &&
                                        criteria.requirements.filter(o => o.hide ? false : true).map((requirement, index) => (
                                            <Draggable key={String(requirement?.id)} draggableId={String(requirement?.id)} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={classes.requirementCard}
                                                    >
                                                        <Requirement
                                                            criteria={criteria}
                                                            requirement={requirement}
                                                            index={generate()}
                                                            isOpen={`requirement_${requirement?.id}` === this.props.expandedRequirementId}
                                                            toggleRequirement={(id) => this.handleToggleRequirement(id)}
                                                            key={`requirement_${requirement?.id}`}
                                                            editable={editable}
                                                            weighting_mechanism={this.props.weighting_mechanism}
                                                            enableScoring={this.props.enableScoring}
                                                            maxScore={this.props.maxScore}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}

                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <div className={classnames(classes.addRequirementAction, 'evaluate-add-requirement')}>
                        {this.props.criteria.name === 'Product Features' && isEditable && (
                            <div className={classes.hrLine}>
                                <span
                                    onClick={() => {
                                        this.props.showFeatureDialog({ evaluationId: this.props.match.params.evaluation_id, criteriaId: criteria.id })
                                    }}
                                >+ Add new Feature</span>
                            </div>
                        )}
                        {this.props.criteria.name !== 'Product Features' && isEditable && (
                            <div className={classes.hrLine}>
                                <span
                                    onClick={() => {
                                        this.props.showEvaluationRequirementAddDialog(this.props.evaluationId, criteria.id)
                                    }}
                                >+ New Question</span>
                            </div>
                        )}
                    </div>
                </Collapse>
                {this.state.isActiveDialog && <MessageDialog messageData={messageData} callBack={this.callBack} />}
            </div>
        )
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Criteria));