import React from 'react'
import { Editor, Transforms } from "slate";
import ReactDOM from 'react-dom';
//import { VideoElement } from "./SlateEmbeds";
import { ImageElement } from "./SlateImage";

import {
  useSelected,
  useFocused,
} from 'slate-react'

import PieChartIcon from '@material-ui/icons/PieChart';

export const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code"
};
const LIST_TYPES = ["numbered-list", "bulleted-list"];

const ALIGNMENT_TYPES = ["alignLeft", "alignRight", "alignCenter"];
// const list_types = ["numbered-list", "bulleted-list"];

// const downloadFile = (url, name) => {
//   alert(url)
//   fetch(url)
//   .then(res => {
//       return res.blob();
//   })
//   .then(blob => {
//       const href = window.URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.download = name;
//       a.href = href;
//       console.log(a)
//       alert('d')
//       a.click();
//       a.href = "";
//     })
//     .catch(err => console.error(err));
// }

export const SlateElement = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "table":
      return (
        <table className={'slateEditorTable'}>
          <tbody {...attributes}>{children}</tbody>
        </table>
      );
    case "table-row":
      return <tr {...attributes}>{children}</tr>;
    case "table-cell":
      return <td {...attributes}>{children}</td>;
    case "image":
      return <ImageElement {...props} />;
    // case "video":
    //   return <VideoElement {...props} />;
    case "link":
      if (String(element.url).toLowerCase().indexOf("javascript:") !== -1) {
        console.log(element.children[0].text, '111-Texttttttttttttttt')
        return (
          <span {...attributes} style={{ color: '#4b86f8', textDecoration: 'underline' }} title={element.url}>
            {children}
          </span>
        );
      } else if (element.isFile || (element.children[0].text.indexOf('http') === -1 && element.children[0].text.indexOf('mailto') === -1)) {
        return (
          <span {...attributes}
            style={{ color: '#4b86f8', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              // console.log(element.children[0].text)
              // console.log(element.url)
              fetch(element.url)
                .then(res => {
                  return res.blob();
                }).then(blob => {
                  const href = window.URL.createObjectURL(blob);
                  const a = document.createElement("a");
                  a.download = element.children[0].text;
                  a.href = href;
                  a.click();
                  a.href = "";
                }).catch(err => {
                  const link = document.createElement('a');
                  const body = document.getElementsByTagName('body');
                  link.href = element.url;
                  link.setAttribute('download', element.children[0].text);
                  body[0].appendChild(link)
                  link.click();
                  body[0].removeChild(link);
                });
            }}
          >
            {children}
          </span>
        );
      } else if (element.children[0].text.indexOf('mailto') !== -1) {
        return (
          <a {...attributes}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#4b86f8', textDecoration: 'underline', cursor: 'pointer' }}
            href={element.url}>
            {children}
          </a>
        )
      } else {
        return (
          <span {...attributes}
            style={{ color: '#4b86f8', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={(e) => {
              console.log()
              e.stopPropagation();
              e.preventDefault()
            }}
          >
            {children}
          </span>
        );
      }
    case 'mention':
      return <Mention {...props} />
    case 'variable':
      return <Variable {...props} />
    case 'automationVariable':
      return <AutomationVariable {...props} />
    case 'visualization':
      return <Visualization {...props} />
    case "alignLeft":
      return (
        <div
          style={{ textAlign: "left", listStylePosition: "inside" }}
          {...attributes}
        >
          {children}
        </div>
      );
    case "alignCenter":
      return (
        <div
          style={{ textAlign: "center", listStylePosition: "inside" }}
          {...attributes}
        >
          {children}
        </div>
      );
    case "alignRight":
      return (
        <div
          style={{ textAlign: "right", listStylePosition: "inside" }}
          {...attributes}
        >
          {children}
        </div>
      );

    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const Portal = ({ children }) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null
}

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.character.replace(' ', '-')}`}
      style={{
        padding: '2px 4px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        backgroundColor: '#e0f3fa',
        color: '#398AF5',
        fontSize: '0.9em',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
      }}
    >
      {children}@{element.character}
    </span>
  )
}

const Variable = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`variable-${element?.placeholder.replace(' ', '-')}`}
      title={element?.code}
      style={{
        padding: '2px 4px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        backgroundColor: '#e0f3fa',
        color: '#398AF5',
        fontSize: '0.9em',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
      }}
    >
      {children}{element?.placeholder}
    </span>
  )
}

const AutomationVariable = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`variable-${element?.placeholder.replace(' ', '-')}`}
      title={element?.code}
      style={{
        padding: '2px 4px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        backgroundColor: '#e0f3fa',
        color: '#398AF5',
        fontSize: '0.9em',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
      }}
    >
      {children}{element?.placeholder}
    </span>
  )
}

const Visualization = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  console.log('===========')
  console.log(attributes)
  console.log(children)
  console.log(element)
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`visualization-${element?.placeholder.replace(' ', '-')}`}
      title={element?.code}
      style={{
        padding: '2px 4px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'flex',
        borderRadius: '4px',
        backgroundColor: '#e0f3fa',
        color: '#398AF5',
        fontSize: '0.9em',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <PieChartIcon size={28} style={{ marginRight: 10 }} /> {children}{element?.placeholder}
    </span>
  )
}

export const SlateLeaf = ({ attributes, children, leaf }) => {
  if (leaf.decoration === "link") {
    children = (
      <a
        style={{ cursor: "pointer", color: 'blue' }}
        href={leaf.text}
        target={"_blank"}
        rel={"noreferrer"}
        onClick={() => {
          let url = '';
          if (leaf.text.indexOf('http') === -1) {
            url = 'http://' + leaf.text;
          } else {
            url = leaf.text;
          }
          window.open(url, "_blank", "noopener,noreferrer");
        }}
      >
        {children}
      </a>
    );
  }

  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }
  if (leaf.strikethrough) {
    children = <span style={{ textDecoration: 'line-through' }}>{children}</span>
  }
  if (leaf.underline) {
    children = <u>{children}</u>
  }
  if (leaf.superscript) {
    children = <sup>{children}</sup>
  }
  if (leaf.subscript) {
    children = <sub>{children}</sub>
  }
  if (leaf.color) {
    children = <span style={{ color: leaf.color }}>{children}</span>
  }
  if (leaf.bgColor) {
    children = <span style={{ backgroundColor: leaf.bgColor }}>{children}</span>
  }
  return <span {...attributes}>{children}</span>
}

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format
  });
  return !!match;
};

export const toggleBlockBkp = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);
  const isIndent = ALIGNMENT_TYPES.includes(format);

  const isAligned = ALIGNMENT_TYPES.some((alignmentType) =>
    isBlockActive(editor, alignmentType)
  );

  /*If the node is already aligned and change in indent is called we should unwrap it first and split the node to prevent
    messy, nested DOM structure and bugs due to that.*/
  if (isAligned && isIndent) {
    Transforms.unwrapNodes(editor, {
      match: (n) => ALIGNMENT_TYPES.includes(n.type),
      split: true
    });
  }

  /* Wraping the nodes for alignment, to allow it to co-exist with other block level operations*/
  if (isIndent) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: []
    });
    return;
  }
  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format
  });

  if (isList && !isActive) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: []
    });
  }
};

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
