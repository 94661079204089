import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { Country } from "country-state-city";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeData } from "redux/companyProfile/action";
import SingleSelect from "./singleSelect";
const ProfileItemPopup = ({
  type,
  data,
  value,
  isEdit,
  onSubmit,
  section,
  isRequired,
  activeMenu,
  itemGroup,
}) => {
  const dispatch = useDispatch();
  const { storeDataSuccess } =
    useSelector((state) => state.companyProfile);

  const [open, setOpen] = useState(false);

  const [selectedType1, setSelectedType1] = useState(
    data?.additionalData?.popupData?.items[0].value
  ); // State for the first select input
  const [selectedType2, setSelectedType2] = useState(
    data?.additionalData?.popupData?.items[1].value
  ); // State for the second select input
  const [countryOptions, setCountryOptions] = useState(
    Country.getAllCountries().map((country, index) => {
      return { label: country.name, value: country.isoCode };
    })
  );

  const [error, setError] = useState({ selectedType1: "", selectedType2: "" });
  const [itemValue, setItemValue] = useState(
    data.value[0].value
      ? data.value
      : [
        { label: "", value: "" },
        { label: "", value: "" },
      ]
  );

  useEffect(() => {
    setItemValue(
      data.value[0].value
        ? data.value
        : [
          { label: "", value: "" },
          { label: "", value: "" },
        ]
    );
  }, [JSON.stringify(data)]);
  useEffect(() => {
    setSelectedType1(data?.additionalData?.popupData.items[0].value);
    setSelectedType2(data?.additionalData?.popupData.items[1].value);
  }, [JSON.stringify(itemGroup)]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedType1(data?.additionalData?.popupData?.items[0].value);
    setSelectedType2(data?.additionalData?.popupData?.items[1].value);
  };

  // update the values of the selectedType to the redux
  const updateValue = (item) => {
    let newStoreData = { ...storeDataSuccess };
    const sectionToUpdate = newStoreData[activeMenu]?.find(
      (s) => s.sectionKey === section.sectionKey
    );
    console.log(sectionToUpdate, section);
    if (sectionToUpdate) {
      if (sectionToUpdate.itemGroups) {
        const itemGroupToUpdate = sectionToUpdate.itemGroups?.find(
          (iG) => iG.itemGroupKey === itemGroup.itemGroupKey
        );
        console.log("itemGroupToUpdate", itemGroupToUpdate);

        const itemToUpdate = itemGroupToUpdate.items.find(
          (i) => i.itemKey === item.itemKey
        );
        if (itemToUpdate) {
          console.log("itemToUpdate 123", itemToUpdate);

          if (itemToUpdate.type === "POPUP") {
            itemToUpdate.value = itemValue;
            const item0 = itemToUpdate.additionalData.popupData.items[0];
            const item1 = itemToUpdate.additionalData.popupData.items[1];
            item0.value = selectedType1;
            item1.value = selectedType2;

            dispatch(storeData(newStoreData));
          }
        }
      }
    }
  };

  const validate = () => {
    let isValid = false;
    if (!selectedType1) {
      setError({ ...error, selectedType1: "This is a required field" });
    } else {
      setError({ ...error, selectedType1: "" });
    }
    if (!selectedType2) {
      setError({ ...error, selectedType2: "This is a required field" });
    } else {
      setError({ ...error, selectedType2: "" });
    }
    if (selectedType1 && selectedType2) {
      isValid = true;
    }
    return isValid;
  };

  const handleAdd = () => {
    // Handle the action when the 'Add' button is clicked
    // You can perform any necessary logic here based on the selectedType1 and selectedType2 values
    console.log("Selected Type 1:", selectedType1);
    console.log("Selected Type 2:", selectedType2);
    if (validate()) {
      updateValue(data);
      setOpen(false);
    }
    setSelectedType1("");
    setSelectedType2("");
  };

  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {data.value[0].value && (
          <Typography
            variant="body2"
            style={{ marginRight: "10px" }}
          >{`${data.value[0].label} - ${data.value[1].label}`}</Typography>
        )}
        {isEdit && (
          <Button
            variant="text"
            style={{ color: "#17BEBB" }}
            startIcon={data.value[0].value ? <Edit /> : <Add />}
            onClick={handleClickOpen}
          >
            {data.value[0].value ? "Edit" : "Add"}
          </Button>
        )}
      </div>

      <Dialog
        maxWidth="md"
        fullWidth
        style={{ fontFamily: "Manrope" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="profile-item-popup-title"
      >
        <DialogTitle id="profile-item-popup-title">
          <Typography
            variant="h6"
            style={{
              fontWeight: 500,
              fontFamily: "Manrope",
              color: "#003B4D",
            }}
          >
            Add Diversity Classification
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "10px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                style={{ marginBottom: "4px", fontFamily: "Manrope" }}
              >
                {data?.additionalData?.popupData?.items[0].label}
                {data?.additionalData?.popupData?.items[0].isRequired && (
                  <span
                    style={{
                      color: "red",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </span>
                )}
              </Typography>
              <SingleSelect
                editMode={true}
                selectedOption={{ value: selectedType1 }}
                options={countryOptions}
                //   isDisabled={isDisabled}
                isRequired={
                  data?.additionalData?.popupData?.items[0].isRequired
                }
                data={{ error: error.selectedType1 }}
                onSubmit={({ value }) => {
                  console.log(value, "Please select");
                  setItemValue((prevItemValue) => {
                    prevItemValue[0].label = countryOptions.find(
                      (c) => c.value === value
                    ).label;
                    prevItemValue[0].value = value;

                    return prevItemValue;
                  });
                  setSelectedType1(value);
                  // updateValue(value);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                style={{ marginBottom: "4px", fontFamily: "Manrope" }}
              >
                {data?.additionalData?.popupData?.items[1].label}
                {data?.additionalData?.popupData?.items[1].isRequired && (
                  <span
                    style={{
                      color: "red",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </span>
                )}
              </Typography>
              <SingleSelect
                editMode={true}
                selectedOption={{ value: selectedType2 }}
                options={
                  selectedType1 === "US"
                    ? data?.additionalData?.popupData?.items[1].additionalData
                      .optionsForUS
                    : data?.additionalData?.popupData?.items[1].additionalData
                      .optionsForOthers
                }
                isDisabled={!selectedType1}
                isRequired={
                  data?.additionalData?.popupData?.items[1].isRequired
                }
                data={{ error: error.selectedType2 }}
                onSubmit={({ value }) => {
                  console.log(value, "Please select 2");
                  setSelectedType2(value);
                  setItemValue((prevItemValue) => {
                    prevItemValue[1].label =
                      selectedType1 === "US"
                        ? data?.additionalData?.popupData?.items[1]
                          .additionalData.optionsForUS
                        : data?.additionalData?.popupData?.items[1].additionalData.optionsForOthers.find(
                          (c) => c.value === value
                        ).label;
                    prevItemValue[1].value = value;

                    return prevItemValue;
                  });
                  // updateValue(value);
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color="secondary"
            style={{ fontFamily: "Manrope" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAdd}
            style={{ color: "#17BEBB", fontFamily: "Manrope" }}
          >
            {data.value[0].value ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ProfileItemPopup;
