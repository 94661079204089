import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    tabSection: {
      marginBottom: 17,
    },
    orgProductTab: {
      borderBottom: "1px solid #d8d8d8",
      "&:hover": {
        // background: '#cadbff',
      },
    },
  });

class VendorTabs extends Component {
  state = {};

  handleTabRedirect = (slug) => {
    this.props.history.push(`${this.props.match.url}#${slug}`);
  };

  render() {
    const { classes, tabValue, tabs } = this.props;

    return (
      <div className={classes.tabSection}>
        <Tabs value={tabValue} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" className={classes.tabWidth}>
          {tabs &&
            tabs.length > 0 &&
            tabs.map((item, key) => {
              return <Tab className={classes.orgProductTab} label={item.name} key={key} onClick={() => this.handleTabRedirect(item.slug)} />;
            })}
        </Tabs>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(VendorTabs));
