import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import AppBar from "../Application/AppBar";
import SearchInput from "./SearchInput";
import SliderFilter from "./SliderFilter";
import { Button, Grid } from "@material-ui/core";
import CardAction from "./CardAction";

const styles = (theme) =>
  createStyles({
    filterSection: {
      border: "1px solid #3C3C3C",
      borderRadius: 5,
      minHeight: 32,
      minWidth: 120,
      color: "#20253A",
      fontSize: 15,
      padding: 0,
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
    },
    productSection: {
      marginTop: theme.spacing(6.2),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    searchSection: {
      minWidth: 400,
    },
    cardAction: {
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      position: "relative",

      "& [class*='CardAction-iconButton']": {
        color: "#74798C",
      },
    },

    primary: {
      textAlign: "center",
      fontSize: theme.spacing(2.4),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },

    secondary: {
      textAlign: "center",
    },

    content: {
      padding: theme.spacing(1, 2, 0, 2),
    },
    gridSection: {
      marginTop: 36,
    },
  });

class ListWithFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  navigate = (link) => {
    if (link) {
      this.props.history.push(link);
    }
  };

  toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ open: false });
  };

  cardClick = (event, index) => {
    if (this.props.isCardClickable) {
      this.props.onCardClicked(event, index);
    }
  };

  render() {
    const classes = this.props.classes;
    return (
      <div>
        <AppBar title={this.props.title} withBack={true} />
        <div className={classes.productSection}>
          <p className={classes.productCount}>
            {this.props.allProducts?.total} 120{" "}
            {120 > 1 ? this.props.pluralCountLabel : this.props.countLabel}{" "}
            found
          </p>
          <SearchInput
            placeholder={this.props.searchPlaceholder}
            searchClass={classes.searchSection}
          />
          <div>
            {this.props.isFilterRequired === true && (
              <Button
                className={classes.filterSection}
                onClick={() => this.setState({ open: true })}
              >
                Filters
              </Button>
            )}
          </div>
        </div>
        <div className={classes.gridSection}>
          <Grid container spacing={3}>
            {this.props.cardContent.map((e, index) => (
              <Grid
                item
                lg={this.props.selectedItems.length > 0 ? 3 : 2}
                md={3}
                key={index}
                zeroMinWidth={false}
              >
                <div onClick={(event) => this.cardClick(event, index)}>
                  <CardAction
                    link={e.link}
                    image={e.image}
                    primary={e.primary}
                    secondary={e.secondary}
                    activeImage={e.activeImage}
                    cardActionClass={classes.cardAction}
                    primaryClass={classes.primary}
                    secondaryClass={classes.secondary}
                    contentClass={classes.content}
                    checked={e.checked}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        <SliderFilter
          open={this.state.open}
          toggleDrawer={this.toggleDrawer}
          filters={this.props.filters}
        />
      </div>
    );
  }
}

ListWithFilter.defaultProps = {
  isFilterRequired: true,
  cardContent: [],
  isCardClickable: false,
  selectedItems: [],
};

ListWithFilter.propTypes = {};

export default compose(withRouter, withStyles(styles))(ListWithFilter);
