import { CircularProgress } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
// Asets
import classnames from "classnames";
import CommonCss from "commonCss";
import Image from "Components/Common/image.jsx";
// import LanguageIcon from "@material-ui/icons/Language";
// import EmailIcon from "@material-ui/icons/Email";
// import FacebookIcon from "@material-ui/icons/Facebook";
// import LinkedInIcon from "@material-ui/icons/LinkedIn";
// import PhoneIcon from "@material-ui/icons/Phone";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import Cart from "Components/Application/Components/Evaluate/Components/Common/Cart";
// import ReadMore from "Components/Common/ReadMore";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
// Redux
import { clearStoreState } from "redux/applications/action";
import { updateDrawerStyle } from "redux/drawer/action";
import { createEvaluationCart, removeProductFromCart } from "redux/evaluation/cart/action";
// import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import { showSnackBar } from "redux/snackbar/action";

import { fireEvent } from "redux/common/action";
import AppBarV2 from "Components/Application/AppBarV2";
import Breadcrumb from "Components/Common/Breadcrumb";
import { fetchServiceById } from "redux/vendor/service/action";
// import AddVendorOptionDialog from "Components/Common/Dialogs/AddVendorOptionDialog";
import { showAddVendorDialog } from "redux/vendor/vendorCatalog/action";

const connectedProps = (state) => ({
  evaluationCart: state.evaluationCart.evaluation,
  userRole: state.authUser.user?.Role,
  orgUser: state.orgUser,
  fetchServiceDetailsProgress: state.services.fetchServiceDetailsProgress,
  fetchServiceDetailsError: state.services.fetchServiceDetailsError,
  serviceDetails: state.services.serviceDetails,
  addVendorDialogOpen: state.vendorCatalog.addVendorDialogOpen,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  showSnackBar: showSnackBar,
  clearStoreState: clearStoreState,
  fireEvent: fireEvent,
  fetchServiceById: fetchServiceById,
  showAddVendorDialog: showAddVendorDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    ...CommonCss.ProductDetails,

    rootContainer: {
      ...CommonCss.rootContainer,
      marginTop: 15,
    },
    productLink: {
      color: "#0093E0 !important",
      marginBottom: 15,
      textDecoration: "underline",
      fontSize: 16,
    },
    linkItem: {
      cursor: "pointer !important",
    },
    cursorTag: {
      fontSize: "12px",
      borderRadius: "25px",
      padding: "10px 14px",
    },
    emptyText: {
      textAlign: "center",
      height: 50,
      paddingTop: 20,
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    mailTo: {
      color: "#4A87F8 !important",
      textDecoration: "underline",
    },
    favLiked: {
      zIndex: 11,
      color: "#f44336",
    },
    whislistedUsers: {
      marginLeft: 20,
      display: "flex",
      cursor: "pointer",
      background: "#f1f1f1",
      borderRadius: "25px",
      padding: "5px 10px",
    },
    productLeftSection: {
      maxWidth: 200,
      width: 200,
      minWidth: "auto",
      position: "sticky",
      top: 20,
      alignSelf: "flex-start",
    },
    list: {
      overflowY: "auto",
      maxHeight: "calc(100vh - 280px)",
      padding: "0px 30px 30px 0px",
      fontSize: "13px",
      listStyle: "none",
      marginTop: "30px",
      marginLeft: "0",
      paddingLeft: "0",
    },
    detailsLabel: {
      fontSize: "16px",
      fontWeight: "500",
      display: "inline",
    },
    detailsValue: {
      fontSize: "16px",
      marginBottom: 20,
      display: "inline",
    },
    subHead: {
      fontSize: 20,
      fontWeight: "500",
    },
    infoWrap: {
      display: "flex",
      gap: 15,
    },
    empCount: {
      fontSize: 16,
    },
    addServiceButton: {
      float: "right",
      marginRight: "30px",
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 175,
      marginTop: 10,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    sendBtn: {
      padding: "6px 20px",
      fontWeight: 500,
      marginLeft: 10,
    },
    headTop: {
      color: "#20253A",
      fontSize: 24,
      fontWeight: 500,
    },
  });

class serviceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is404: false,
      showMoreServices: false,
      showMoreSoftwareProducts: false,
      isCartShow: false,
      headerSectionWidth: null,
    };
    this.addToCart = this.addToCart.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);
    this.props.fetchServiceById(this.props.match?.params?.serviceId);
    setTimeout(() => {
      this.updateDimensions();
    }, 2000);
  }

  componentDidUpdate(prevProps) {
    this.cartDrawer(prevProps);

    if (!this.props.fetchServiceDetailsProgress && prevProps.fetchServiceDetailsProgress) {
      if (this.props.fetchServiceDetailsError?.message && this.props.fetchServiceDetailsError?.message !== null) {
        this.setState({ is404: true });
      }
    }
  }

  cartDrawer = (preProps) => {
    if (
      (this.props.evaluationCart && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length) !==
      (preProps.evaluationCart && preProps.evaluationCart.Products && preProps.evaluationCart.Products.length)
    ) {
      this.setState({ isCartShow: true });
      setTimeout(() => {
        this.setState({ isCartShow: false });
      }, 2000);
    }
  };

  scrollContent = (e) => {
    e.persist();
  };

  addToCart(item) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(item.id, null, "MASTER_VENDOR_SERVICE");
      this.props.showSnackBar("Product added to evaluation", "success", 3000);
    } else {
      var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart.Products.map((p) => p.id);
      if (addedToCart.indexOf(item.id) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.id, item.id);
      } else {
        this.props.createEvaluationCart(item.id, this.props.evaluationCart.ID, "MASTER_VENDOR_SERVICE");
        this.props.showSnackBar("Product added to evaluation", "success", 3000);
      }
    }
  }

  addServicesToCart() {
    const { serviceDetails } = this.props;
    let id = serviceDetails.about_vendor.master_vendor_id + "-" + serviceDetails.about_service.master_service_id;
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(id, null, "MASTER_VENDOR_SERVICE");
    } else {
      var addedToCart = this.props.evaluationCart === null ? [] : this.props.evaluationCart?.Products?.filter((p) => p.EntityID === id);
      if (addedToCart && addedToCart.length > 0) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, addedToCart[0].EntityObjectID);
      } else {
        this.props.createEvaluationCart(id, this.props.evaluationCart.ID, "MASTER_VENDOR_SERVICE");
      }
    }
  }

  cartOpen = () => {
    this.setState({ isCartShow: true });
  };

  cartClose = () => {
    this.setState({ isCartShow: false });
  };

  updateDimensions = () => {
    const headerSectionWidth = this.divElement?.clientWidth;
    if (this.state.headerSectionWidth !== headerSectionWidth) {
      this.setState({ headerSectionWidth });
    }
  };

  render() {
    const classes = this.props.classes;

    if (this.props.fetchServiceDetailsProgress || this.state.is404) {
      return (
        <div>
          <AppBarV2 title="Service Details" withBack={true} dropdown={true} />
          <div className={classes.productDetails}>
            {this.state.is404 && (
              <div style={{ width: "100%", textJustify: "center", textAlign: "center" }}>
                <p className={classes.productName}>404</p>
                <p className={classes.emptyText}>No service found</p>
              </div>
            )}
            {!this.state.is404 && (
              <div style={{ textAlign: "center", marginTop: 20, width: "100%" }}>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      );
    }

    const { serviceDetails } = this.props;
    // var isCart = this.props.evaluationCart !== null;
    // var addedToCart =
    //     this.props.evaluationCart == null
    //         ? []
    //         : this.props.evaluationCart.Products.map((p) => p.ID);

    // const { headerSectionWidth } = this.state;
    // const isSm = headerSectionWidth >= 768 && headerSectionWidth <= 1200 ? true : false;
    var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.map((p) => p.EntityID);
    let id = serviceDetails?.about_vendor?.master_vendor_id + "-" + serviceDetails?.about_service?.master_service_id;
    let icon = "+";
    if (id) {
      icon = addedToCart.indexOf(id) === -1 ? "+" : "-";
    }

    return (
      <div>
        <AppBarV2 title="Service Details" withBack={true} dropdown={true} />
        <Breadcrumb routeList={[{ name: "Vendors", path: "/app/vendors/all-vendors" }, { name: "Services", path: "/app/vendors/services" }, { name: "Details" }]} />

        <div className={classnames(classes.productDetails, classes.rootContainer)}>
          <div className={classes.productLeftSection}>
            <Image src={serviceDetails?.vendor_logo ? serviceDetails?.vendor_logo : ""} style={{ maxWidth: "90%" }} />

            <ul className={classes.list}>
              {serviceDetails?.about_service && (
                <li className={classes.item}>
                  <a href="#about" className={this.state.activeNumber === 0 ? "active" : ""} onClick={() => this.setState({ activeNumber: 0 })}>
                    About {serviceDetails?.about_service.name}
                  </a>
                </li>
              )}

              {serviceDetails?.subcategory?.length ? (
                <li className={classes.item}>
                  <a href="#subcategory" className={this.state.activeNumber === 1 ? "active" : ""} onClick={() => this.setState({ activeNumber: 1 })}>
                    Sub Categories
                  </a>
                </li>
              ) : null}

              {serviceDetails?.focus?.length ? (
                <li className={classes.item}>
                  <a href="#focus" className={this.state.activeNumber === 1 ? "active" : ""} onClick={() => this.setState({ activeNumber: 1 })}>
                    Focus
                  </a>
                </li>
              ) : null}

              {serviceDetails?.client?.length ? (
                <li className={classes.item}>
                  <a href="#client" className={this.state.activeNumber === 1 ? "active" : ""} onClick={() => this.setState({ activeNumber: 1 })}>
                    Clients
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
          <div className={classes.productRightSection}>
            <div className={classes.contentSection} onScroll={(e) => this.scrollContent(e)}>
              <div className={classes.itemSection} id="about">
                {serviceDetails?.about_service && (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className={classes.headTop}>{serviceDetails?.about_service.name}</p>
                      <Button
                        className={classes.filterSection}
                        style={{ minWidth: 175 }}
                        onClick={() => {
                          this.addServicesToCart();
                        }}
                      >
                        {icon} Evaluate
                      </Button>
                    </div>

                    {serviceDetails?.about_vendor?.name && <p className={classes.subHead}> By {serviceDetails?.about_vendor?.name}</p>}
                  </>
                )}

                {serviceDetails?.max_avg_hourly_rate && (
                  <div className={classes.infoWrap}>
                    <div className={classes.detailsLabel}>Maximum average hourly rate : </div>
                    <div className={classes.empCount}>{serviceDetails?.max_avghhourly_rate}</div>
                  </div>
                )}

                {serviceDetails?.min_avg_hourly_rate && (
                  <div className={classes.infoWrap}>
                    <div className={classes.detailsLabel}>Minimum average hourly rate : </div>
                    <div className={classes.empCount}>{serviceDetails?.min_avg_hourly_rate}</div>
                  </div>
                )}

                {serviceDetails?.max_number_of_clients && (
                  <div className={classes.infoWrap}>
                    <div className={classes.detailsLabel}>Maximum number of clients : </div>
                    <div className={classes.empCount}>{serviceDetails?.max_number_of_clients}</div>
                  </div>
                )}

                {serviceDetails?.min_number_of_clients && (
                  <div className={classes.infoWrap}>
                    <div className={classes.detailsLabel}>Minimum number of clients : </div>
                    <div className={classes.empCount}>{serviceDetails?.min_number_of_clients}</div>
                  </div>
                )}

                {serviceDetails?.number_of_projects && (
                  <div className={classes.infoWrap}>
                    <div className={classes.detailsLabel}>Number of projects : </div>
                    <div className={classes.empCount}>{serviceDetails?.number_of_projects}</div>
                  </div>
                )}
              </div>

              {serviceDetails?.subcategory?.length > 0 && (
                <div className={classes.itemSection} id="subcategory">
                  <p className={classes.head}>Sub Categories</p>
                  <p className={classes.description}>
                    <ul className={classes.UL}>
                      {serviceDetails?.subcategory.map((item, k) => {
                        return (
                          <li key={k} style={{ marginRight: "14px" }}>
                            <span className={classnames(classes.cursorTag, classes.inverse)}>{item.name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </p>
                </div>
              )}

              {serviceDetails?.focus?.length > 0 && (
                <div className={classes.itemSection} id="focus">
                  <p className={classes.head}>Focus</p>
                  <p className={classes.description}>
                    <ul className={classes.UL}>
                      {serviceDetails?.focus.map((item, k) => {
                        return (
                          <li key={k} style={{ marginRight: "14px" }}>
                            <span className={classnames(classes.cursorTag, classes.inverse)}>{item.name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </p>
                </div>
              )}

              {serviceDetails?.client?.length > 0 && (
                <div className={classes.itemSection} id="client">
                  <p className={classes.head}>Clients</p>
                  <p className={classes.description}>
                    <ul className={classes.UL}>
                      {serviceDetails?.client.map((item, k) => {
                        return (
                          <li key={k} style={{ marginRight: "14px" }}>
                            <span className={classnames(classes.cursorTag, classes.inverse)}>{item.name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </p>
                </div>
              )}

              <p className={classes.emptyText} style={{ alignItem: "center" }}>
                The information you see in this page is compiled from multiple sources. If you have any feedback, please contact us at{" "}
                <a className={classes.mailTo} href="mailto:sellers@expent.ai">
                  sellers@expent.ai
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(serviceDetails));
