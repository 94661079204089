import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Tooltip, Avatar, Chip } from "@material-ui/core";
import { getRequirementType } from "util/RequirementTypeOptions";
import classnames from "classnames";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      position: "relative",
      border: "1px solid #EBEBEB",
      borderRadius: 5,
    },

    requirementExpanded01: {
      border: "1px solid #8d7d7d",
      backgroundColor: "white",
      height: "100%",
    },
    requirementCollapse: {
      padding: "12px 16px 0 25px",
      cursor: "pointer",
    },
    requirementContainer: {
      padding: "20px",
      // padding: '30px 16px 0 25px',
      justifyContent: "space-between",
    },
    title: {
      fontSize: theme.spacing(2),
      color: "#333333",
      fontWeight: 600,
      "& > img": {
        display: "none",
      },
      "&:hover > img": {
        display: "initial",
      },
    },
    collapseTitle: {
      fontSize: theme.spacing(2),
      color: "#333333",
      fontWeight: 600,
    },
    collapseTxt: {
      fontSize: theme.spacing(2),
      color: "#707070",
      marginTop: theme.spacing(1),
    },
    bodyTxt: {
      borderRadius: 8,
      backgroundColor: "#FAFAFA",
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      fontSize: theme.spacing(2),
    },
    editIcon: {
      color: "#999999",
    },
    caretIcon: {
      color: "#999999",
    },
    descriptionContainer: {
      marginTop: 18,
    },
    description: {
      color: "#707070",
      fontSize: 13,
      minHeight: 50, // 106
    },
    "@global": {
      ".rootRequirementHight": {
        "& [class*='editor-formControl']": {
          maxHeight: 165,
        },
      },
    },
    label: {
      color: "#707070",
      fontSize: 13,
      fontWeight: 400,
    },
    rightAction: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 20,
    },
    RightPanel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
  });

class Requirement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      options: [],
      answertype: 1,
    };
  }

  componentDidMount() {
    try {
      this.setState({
        name: this.props.requirement?.question?.name || "",
        description: this.props.requirement?.question?.description || "",
        answertype: this.props.requirement?.answers?.answer_type || 1,
        options: this.props.requirement?.answers?.options || [],
      });
    } catch (e) {}
  }

  getType(req) {
    let type = "Text";
    if (req && req?.answer_type) {
      const answer_type = parseInt(req?.answer_type);
      if (2 === answer_type) type = "Multi Select";
      if (3 === answer_type) type = "Single Select";
      if (4 === answer_type) type = "Binary";
      if (5 === answer_type) type = "Score";
      if (6 === answer_type) type = "File upload";
    }
    return type;
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  render() {
    const classes = this.props.classes;
    const requirement = this.props.requirement;
    if (requirement == null || requirement === undefined) {
      return null;
    }

    return (
      <div className={classnames(classes.root, classes.requirementExpanded01)}>
        <Grid container className={classes.requirementContainer}>
          <Grid item xs={12}>
            <Typography component="p" className={classes.title}>
              {this.state.name || requirement.name}
            </Typography>
            <Typography component="p" className={classes.bodyTxt}>
              <SlateReadonlyField initValue={this.state.description || requirement.description} />
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.RightPanel}>
            <div>
              <Typography component="p" className={classes.collapseTxt}>
                Answer type - {getRequirementType([], this.state.answertype)}
              </Typography>
              {[2, 3, 4, 14, 15].includes(this.state.answertype) && (
                <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
                  {this.state.options?.map((option, i) => (
                    <Chip key={i} label={option.title} variant="outlined" color="primary" size="small" />
                  ))}
                </div>
              )}
            </div>
            {requirement?.created_by?.length > 0 && (
              <div className={classes.rightAction}>
                <label className={classes.label}>Created by</label>
                <div>
                  <Typography className={classes.userInfoItem}>
                    <Tooltip
                      placement="top"
                      title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{requirement?.created_by}</span>}
                      arrow
                    >
                      <Avatar className={classes.avatarImg}> {this.avatarName(requirement?.created_by || "")}</Avatar>
                    </Tooltip>
                    {/* <span>{requirement?.created_by}</span> */}
                  </Typography>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Requirement));
