import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Typography, Divider, Box, Tooltip, LinearProgress } from "@material-ui/core";
import DefaultImage from "assets/images/evaluation/default.svg";
import Image from "Components/Common/image.jsx";
// import moment from 'moment';
import { CommonFn } from "services/commonFn";

import { showSellerFinalizeReqDialog } from "redux/seller/action";

const connectedProps = (state) => ({
  sellerAssessmentDetails: state.sellerAssessment.sellerAssessmentDetails,
});

const connectionActions = {
  showSellerFinalizeReqDialog: showSellerFinalizeReqDialog,
};

var connector = connect(connectedProps, connectionActions);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#2EB77D",
  },
}))(LinearProgress);

const styles = (theme) =>
  createStyles({
    otherItmBox: {
      border: "1px solid #f1f1f1",
      padding: 0,
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(1),
      cursor: "pointer",
    },
    otherProductInfo: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1, 2),
    },
    productImg: {
      width: theme.spacing(5),
      marginRight: theme.spacing(0.6),
    },
    currProductTitle: {
      marginBottom: theme.spacing(2),
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    progressBox: {
      border: "1px solid #f1f1f1",
      padding: theme.spacing(2, 2),
      borderRadius: theme.spacing(1),
    },
    product_name: {
      fontWeight: 600,
      marginLeft: theme.spacing(1),
      wordBreak: "break-all",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    product_nameOri: {
      fontWeight: 600,
      marginLeft: theme.spacing(1),
      wordBreak: "break-all",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    dueDateTxt: {
      fontWeight: 500,
      fontSize: 14,
    },
    productCompletion: {
      fontWeight: 600,
      color: "#282D30",
      opacity: "0.6",
    },
    productCompleted: {
      color: "#2EB77D",
      opacity: "1",
    },
    currentProductInfo: {
      display: "flex",
      alignItems: "center",
    },
    dueInfo: {
      marginTop: 10,
    },
    progTextComp: {
      margin: theme.spacing(1, 0),
      fontSize: theme.spacing(1.6),
      cursor: "pointer",
    },
    totalAnswered: {
      flex: 1,
      textAlign: "right",
      margin: theme.spacing(1, 0),
      fontSize: theme.spacing(1.6),
      cursor: "pointer",
    },
    productPerCom: {
      display: "flex",
    },
    overAllText: {
      fontWeight: 600,
    },
    progText: {
      marginTop: 20,
    },
    progressBar: {
      cursor: "pointer",
    },
  });

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.showFinalizeDialog = this.showFinalizeDialog.bind(this);
  }

  componentDidUpdate(preProps) {}

  showFinalizeDialog(type) {
    this.props.showSellerFinalizeReqDialog(type);
  }

  render() {
    const classes = this.props.classes;
    const { sellerAssessmentDetails, completedQuestionsId, totalCount } = this.props;
    let assessments = sellerAssessmentDetails?.data || [];
    let assessment = assessments[0] || {};
    let totalDoneProgress = Math.round((completedQuestionsId?.length * 100) / totalCount);

    return (
      <div>
        <Typography variant={"h6"} className={classes.currProductTitle}>
          Progress
        </Typography>

        <Box component="span" display="block" className={classes.progressBox}>
          <div className={classes.currentProductInfo}>
            <Image alt="product" src={assessment.product_logo == null || assessment.product_logo.length === 0 ? DefaultImage : assessment.product_logo} className={classes.productImg} />
            <Tooltip
              className={"tooltip"}
              placement="top"
              title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{assessment.product_name}</span>}
              arrow
            >
              <span className={classes.product_nameOri}> {assessment.product_name} </span>
            </Tooltip>
          </div>

          <div onClick={() => {}}>
            <Typography className={classes.progText}>
              <div className={classes.productPerCom}>
                <p className={classes.progTextComp}>{totalDoneProgress}% Completed</p>
                <span className={classes.totalAnswered}>
                  {completedQuestionsId?.length}/{totalCount}
                </span>
              </div>
            </Typography>
            <BorderLinearProgress className={classes.progressBar} variant="determinate" value={totalDoneProgress} />
          </div>
        </Box>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Products));
