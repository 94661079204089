import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment"

export const START_GET_MASTER_WORKFLOWS = "START_GET_MASTER_WORKFLOWS";
export const END_GET_MASTER_WORKFLOWS = "END_GET_MASTER_WORKFLOWS";

export const START_GET_BY_ID_MASTER_WORKFLOW = "START_GET_BY_ID_MASTER_WORKFLOW";
export const END_GET_BY_ID_MASTER_WORKFLOW = "END_GET_BY_ID_MASTER_WORKFLOW";

export const START_FETCH_MASTER_WORKFLOW_ACTIONS = "START_FETCH_MASTER_WORKFLOW_ACTIONS";
export const END_FETCH_MASTER_WORKFLOW_ACTIONS = "END_FETCH_MASTER_WORKFLOW_ACTIONS";

export const START_FETCH_MASTER_WORKFLOW_COMPONENTS = "START_FETCH_MASTER_WORKFLOW_COMPONENTS";
export const END_FETCH_MASTER_WORKFLOW_COMPONENTS = "END_FETCH_MASTER_WORKFLOW_COMPONENTS";

export const START_COPY_MASTER_TO_ORG_WORKFLOW = "START_COPY_MASTER_TO_ORG_WORKFLOW";
export const END_COPY_MASTER_TO_ORG_WORKFLOW = "END_COPY_MASTER_TO_ORG_WORKFLOW";

export const START_FETCH_MASTER_WORKFLOW_COMPONENT_RULES = "START_FETCH_MASTER_WORKFLOW_COMPONENT_RULES";
export const END_FETCH_MASTER_WORKFLOW_COMPONENT_RULES = "END_FETCH_MASTER_WORKFLOW_COMPONENT_RULES";

function startGetMasterWorkflow() {
    return {
        type: START_GET_MASTER_WORKFLOWS,
        payload: {}
    };
}

function endGetMasterWorkflow(success, error) {
    return {
        type: END_GET_MASTER_WORKFLOWS,
        payload: {
            success, error
        }
    };
}

export function getMasterWorkflows(data) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/evaluation/workflows?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetMasterWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endGetMasterWorkflow(response, error));
        } catch (e) {
            dispatch(endGetMasterWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startGetByIdOrgWorkflow() {
    return {
        type: START_GET_BY_ID_MASTER_WORKFLOW,
        payload: {}
    };
}

function endGetByIdOrgWorkflow(success, error) {
    return {
        type: END_GET_BY_ID_MASTER_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function getMasterWorkflow(id) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/evaluation/workflow/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetByIdOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endGetByIdOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endGetByIdOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchMasterWorkflowActions() {
    return {
        type: START_FETCH_MASTER_WORKFLOW_ACTIONS,
        payload: {}
    };
}

function endFetchMasterWorkflowActions(success, error) {
    return {
        type: END_FETCH_MASTER_WORKFLOW_ACTIONS,
        payload: {
            success, error
        }
    };
}

export function fetchMasterWorkflowActions(id) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/evaluation/workflow/${id}/actions?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchMasterWorkflowActions());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchMasterWorkflowActions(response, error));
        } catch (e) {
            dispatch(endFetchMasterWorkflowActions(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startMasterWorkflowComponents() {
    return {
        type: START_FETCH_MASTER_WORKFLOW_COMPONENTS,
        payload: {}
    };
}

function endMasterWorkflowComponents(success, error) {
    return {
        type: END_FETCH_MASTER_WORKFLOW_COMPONENTS,
        payload: {
            success, error
        }
    };
}

export function fetchMasterWorkflowComponents(id) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/evaluation/workflow/${id}/components?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startMasterWorkflowComponents());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endMasterWorkflowComponents(response, error));
        } catch (e) {
            dispatch(endMasterWorkflowComponents(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startCopyWorkflowMasterToOrg() {
    return {
        type: START_COPY_MASTER_TO_ORG_WORKFLOW,
        payload: {}
    };
}

function endCopyWorkflowMasterToOrg(success, error) {
    return {
        type: END_COPY_MASTER_TO_ORG_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function copyWorkflowMasterToOrg(workflowId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/master-workflow/${workflowId}/master_to_org?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startCopyWorkflowMasterToOrg());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, {});
            dispatch(endCopyWorkflowMasterToOrg(response, error));
        } catch (e) {
            dispatch(endCopyWorkflowMasterToOrg(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startWorkflowRulesConditions() {
    return {
        type: START_FETCH_MASTER_WORKFLOW_COMPONENT_RULES,
        payload: {}
    };
}

function endWorkflowRulesConditions(success, error) {
    return {
        type: END_FETCH_MASTER_WORKFLOW_COMPONENT_RULES,
        payload: {
            success, error
        }
    };
}

export function fetchMasterWorkflowRulesConditions(id,component_id) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/workflow/${id}/rules-conditions/${component_id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startWorkflowRulesConditions());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endWorkflowRulesConditions(response, error));
        } catch (e) {
            dispatch(endWorkflowRulesConditions(null, {
                message: e.message
            }));
            return;
        }
    };
}