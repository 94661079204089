import { Avatar, Button, CircularProgress, Dialog, DialogContent, InputBase, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Slide, Typography } from "@material-ui/core";
import Menu from '@material-ui/core/Menu';
import { createStyles, withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import classnames from "classnames";
import AddUserDialog from "Components/Common/AddUserDialog.jsx";
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
// redux
import { clearStoreState } from "redux/applications/action";
import {
    fetchRemoveCriteriaCollaborator, fetchRemoveEavluationCollaborator, fetchRemoveRequirementCollaborator, hideEvaluationCollaboratorDialog
} from "redux/evaluate/action";
import { criteriaColloboratorRoleUpdate, fetchColloborator, fetchCriteria, removeCriteriaApprover } from "redux/evaluation/criteria/action";
import { deleteProductCollaboraor } from "redux/evaluation/metadata/action";
import { showAddTeamDialog } from "redux/okta/action";
import { showSnackBar } from "redux/snackbar/action";
import { removeTemplateCriteriaApprover, removeTemplateRequirementCollaborator, updateTemplateCriteriaColloborator } from "redux/templates/evaluation/org/action";
import { searchUser } from "redux/usersearch/action";
import _ from "underscore";
import PriorityInput from "./PriorityInput";

import { showNudgeNotificationDialog } from "redux/notification/action";
import { deleteRenewalColloborator } from "redux/renewal/action";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    progress: state.userSearch.searchProgress,
    users: state.userSearch.users,
    criteriaUserRoleInprogress: state.evaludationCriteria.criteriaUserRoleInprogress,
    orgTemplateCriteriaUserRoleInprogress: state.orgTemplateEvaluation.criteriaUserRoleInprogress,
    addTeamDialogOpen: state.okta.addTeamDialogOpen,
    open: state.evaluate.evaluationCollaboratorDialogOpen,
    criterias: state.evaludationCriteria.criterias,

    evColloborators: state.evaludationCriteria.colloborators,
    addNewEvaColloboratorProgress: state.evaludationCriteria.addNewEvaColloboratorProgress,

    templatesWithCriterias: state.orgTemplateEvaluation.template,

    deleteEvaluateCollaboratorInprogress: state.evaluate.deleteEvaluateCollaboratorInprogress,
    deleteEvaluateCollaboratorSuccess: state.evaluate.deleteEvaluateCollaboratorSuccess,
    deleteEvaluateCollaboratorError: state.evaluate.deleteEvaluateCollaboratorError,

    deleteCriteriaCollaboratorInprogress: state.evaluate.deleteCriteriaCollaboratorInprogress,
    deleteCriteriaCollaboratorSuccess: state.evaluate.deleteCriteriaCollaboratorSuccess,
    deleteCriteriaCollaboratorError: state.evaluate.deleteCriteriaCollaboratorError,

    deleteRequirementCollaboratorInprogress: state.evaluate.deleteRequirementCollaboratorInprogress,
    deleteRequirementCollaboratorSuccess: state.evaluate.deleteRequirementCollaboratorSuccess,
    deleteRequirementCollaboratorError: state.evaluate.deleteRequirementCollaboratorError,

    evaluation: state.evaluationMetaData.evaluation,
    deleteProductCollaboratorProgress: state.evaluationMetaData.deleteProductCollaboratorProgress,
    allProductCollaboratorProgress: state.evaluationMetaData.allProductCollaboratorProgress,

    updateRenewalColloboratorProg: state.renewal.updateRenewalColloboratorProg,
    updateRenewalColloboratorErr: state.renewal.updateRenewalColloboratorErr,
    deleteRenewalColloboratorProg: state.renewal.deleteRenewalColloboratorProg,
    deleteRenewalColloboratorErr: state.renewal.deleteRenewalColloboratorErr,
});

const connectionActions = {
    clearStoreState: clearStoreState,
    searchUser: searchUser,
    removeCriteriaApprover: removeCriteriaApprover,
    criteriaColloboratorRoleUpdate: criteriaColloboratorRoleUpdate,
    showSnackBar: showSnackBar,
    showAddTeamDialog: showAddTeamDialog,
    updateTemplateCriteriaColloborator: updateTemplateCriteriaColloborator,
    removeTemplateCriteriaApprover: removeTemplateCriteriaApprover,
    removeTemplateRequirementCollaborator: removeTemplateRequirementCollaborator,
    hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
    fetchRemoveEavluationCollaborator: fetchRemoveEavluationCollaborator,
    fetchRemoveCriteriaCollaborator: fetchRemoveCriteriaCollaborator,
    fetchRemoveRequirementCollaborator: fetchRemoveRequirementCollaborator,
    fetchColloborator: fetchColloborator,
    deleteProductCollaboraor: deleteProductCollaboraor,
    fetchCriteria: fetchCriteria,
    showNudgeNotificationDialog: showNudgeNotificationDialog,
    deleteRenewalColloborator: deleteRenewalColloborator
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    collaboratorContainer: {
        minWidth: '310px',
        // padding: "20px",
        '& ul': {
            padding: 0
        }
    },
    collaboratorHeader: {
        marginTop: 0,
        // padding: "20px",
        paddingBottom: 0
    },
    searchCol: {
        paddingTop: '16px',
        paddingBottom: '16px',
        '& span': {
            textAlign: 'left',
            fontSize: 12,
            color: '#202124',
            float: 'right'
        }
    },
    title: {
        textAlign: "left",
        fontWeight: 400,
        // color: '#20253A',
        // fontSize: '1.375rem',
        // marginBottom: '14px',
        color: '#20253A',
        display: 'flex',
        placeItems: 'center'
    },
    input: {
        // background:"#F9FAFC",
        padding: theme.spacing(0.5, 2),
        fontSize: '.875rem',
        marginRight: 15,
        // border: '0.5px solid #B6B6B6',
        // borderRadius: '3px',
        background: '#f8f9fa',
        width: '100%',
        border: '0px',
        borderBottom: '1px solid #4b86f7',
        outline: '1px solid transparent',
        borderRadius: '6px 6px 0 0',
        minHeight: 27
    },
    listItem: {
        borderBottom: '1px solid #EEEEEE',
        padding: '10px 0px',
        '&:last-child': {
            borderBottom: 'unset'
        }
    },
    avatar: {
        width: '2.25rem',
        height: '2.25rem',
        fontSize: '1.125rem'
    },
    userEmail: {
        fontSize: '13px',
        color: '#4B4B4B'
    },
    userEmailText: {
        cursor: 'pointer',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        flex: 0.8,
        placeItems: 'start',
        '& svg': {
            position: 'relative',
            left: '5px',
            marginRight: 10
        }
    },
    isMember: {
        //textDecoration: 'line-through'
    },
    userState: {
        position: 'relative',
        display: 'inline-block',
        width: '40px',
        height: '12px',
        background: '#DDDDDD',
        opacity: 1,
        borderRadius: '20px',
        cursor: 'pointer',
        '&::after': {
            content: "''",
            height: '20px',
            width: '20px',
            display: 'inline-block',
            position: 'absolute',
            background: ' #DDDDDD',
            border: ' 5px solid #A0A5BA',
            borderRadius: '50%',
            top: '-4px',
            left: '0px'
        },
        '&.active': {
            '&::after': {
                background: '#81A5F2',
                borderColor: '#4175DF',
                left: '20px'
            }
        },
        '&.inprogress': {
            cursor: 'not-allowed'
        }
    },
    newUserBtn: {
        background: "#3C3C3C",
        borderRadius: 5,
        color: '#ffffff !important',
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        minWidth: 140,
        marginLeft: '10px',
        "&:hover": {
            background: "#3C3C3C",
            color: '#ffffff !important',
        },
    },
    shareIcon: {
        background: '#4175DF',
        color: '#ffffff',
        width: '40px',
        height: '40px',
        display: 'inline-block',
        borderRadius: '50%',
        textAlign: 'center',
        lineHeight: '50px',
        marginRight: '10px',
        '& svg': {
            marginTop: '6px'
        }
    },
    footerAction: {
        padding: '0 20px 20px 20px',
        textAlign: 'right',
        '& button': {
            backgroundColor: '#4175DF',
            color: 'white'
        },
        '& button:hover': {
            backgroundColor: '#4175DF',
            color: 'white'
        }
    },
    criteriaPriority: {
        minHeight: '25px !important',
        padding: 3,
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        color: '#5F5F5F',
        fontSize: '1.1em',
        marginLeft: 4,
        width: 40,
        textAlign: 'center'
    },
    notificationIcon: {
        fontSize: 24,
        marginTop: 5,
        color: '#4b86f8',
        cursor: 'pointer'
    },
    nudgeBtn: {
        border: '1px solid #bda8a8',
        color: '#1f73b7',
        padding: 0,
        marginRight: '15px'
    },
    unverified: {
        fontWeight: 400,
        marginLeft: 30,
        border: '1px solid #4175df',
        borderRadius: 4,
        padding: '1px 10px'
    }
});

class SearchUserPoper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            query: "",
            user: null,
            editValue: {},
            showActionMenu: null,
            showActionId: null
        };
        this.avatarName = this.avatarName.bind(this);
    }
    componentDidMount() {
        this.setState({ query: '', user: null })
        if (this.props.position === 'RenewalBar') {
            this.props.fetchColloborator(this.props.evaluationId)
        }
    }

    componentDidUpdate(prevProps) {
        const { user } = this.state;
        if (((this.props.criteriaUserRoleInprogress === false && prevProps.criteriaUserRoleInprogress === true) || (this.props.orgTemplateCriteriaUserRoleInprogress === false && prevProps.orgTemplateCriteriaUserRoleInprogress === true)) && user) {
            if (user.addAprover) {
                this.props.showSnackBar(user.Email + " is now approver", "success", 1000)
            } else if (user.addMember) {
                this.props.showSnackBar(user.Email + " is now member", "success", 1000)
            } else if (user.updateUserRole) {
                this.props.showSnackBar("User role updated", "success", 1000)
            } else if (user.removeMember) {
                this.props.showSnackBar(user.Email + " is no longer an member", "success", 1000)
            } else if (user.removeApprover) {
                this.props.showSnackBar(user.Email + " is no longer an approver", "success", 1000)
            }
            this.setState({ user: null });
        }

        if (this.props.deleteEvaluateCollaboratorInprogress === false && (this.props.deleteEvaluateCollaboratorSuccess || this.props.deleteEvaluateCollaboratorError)) {
            if (this.props.deleteEvaluateCollaboratorSuccess) {
                this.props.showSnackBar("User removed successfully", "success", 1000)
            }
            if (this.props.deleteEvaluateCollaboratorError) {
                this.props.showSnackBar(this.props.deleteEvaluateCollaboratorError, "error", 1000)
            }
            this.props.clearStoreState({
                'evaluate.deleteEvaluateCollaboratorSuccess': null,
                'evaluate.deleteEvaluateCollaboratorError': null,
            })
            this.props.fetchColloborator(this.props.evaluationId)
            // this.props.fetchCriteria(this.props.evaluationId)
        }

        if (this.props.updateRenewalColloboratorProg === false && prevProps.updateRenewalColloboratorProg === true) {
            if (this.props.updateRenewalColloboratorErr === null) {
                this.props.fetchColloborator(this.props.evaluationId)
            } else {
                this.props.showSnackBar('Something Went wrong', "error", 1000)
            }
        }

        if (this.props.deleteRenewalColloboratorProg === false && prevProps.deleteRenewalColloboratorProg === true) {
            if (this.props.deleteRenewalColloboratorErr === null) {
                this.props.fetchColloborator(this.props.evaluationId)
            } else {
                this.props.showSnackBar('Something Went wrong', "error", 1000)
            }
        }

        if (this.props.deleteCriteriaCollaboratorInprogress === false && (this.props.deleteCriteriaCollaboratorSuccess || this.props.deleteCriteriaCollaboratorError)) {
            if (this.props.deleteCriteriaCollaboratorSuccess) {
                this.props.showSnackBar("User removed successfully", "success", 1000)
            }
            if (this.props.deleteCriteriaCollaboratorError) {
                this.props.showSnackBar(this.props.deleteCriteriaCollaboratorError, "error", 1000)
            }
            this.props.clearStoreState({
                'evaluate.deleteCriteriaCollaboratorSuccess': null,
                'evaluate.deleteCriteriaCollaboratorError': null,
            })
            // this.props.fetchColloborator(this.props.evaluationId)
            var componentId = this.props.match.params.component_id !== undefined ? this.props.match.params.component_id : 0;

            this.props.fetchCriteria(this.props.evaluationId, true, componentId)
        }

        if (this.props.deleteRequirementCollaboratorInprogress === false && (this.props.deleteRequirementCollaboratorSuccess || this.props.deleteRequirementCollaboratorError)) {
            if (this.props.deleteRequirementCollaboratorSuccess) {
                this.props.showSnackBar("User removed successfully", "success", 1000)
            }
            if (this.props.deleteRequirementCollaboratorError) {
                this.props.showSnackBar(this.props.deleteRequirementCollaboratorError, "error", 1000)
            }
            this.props.clearStoreState({
                'evaluate.deleteRequirementCollaboratorSuccess': null,
                'evaluate.deleteRequirementCollaboratorError': null,
            })
            // this.props.fetchColloborator(this.props.evaluationId)
            var componentIdNew = this.props.match.params.component_id !== undefined ? this.props.match.params.component_id : 0;
            this.props.fetchCriteria(this.props.requirement_info.evaluationId, true, componentIdNew)
        }

        if (this.props.deleteProductCollaboratorProgress === false && prevProps.deleteProductCollaboratorProgress === true) {
            this.props.showSnackBar("Collaborator removed successfully", "success", 1000)
        }

        if (this.props.allProductCollaboratorProgress === false && prevProps.allProductCollaboratorProgress === true) {
            this.props.showSnackBar("Collaborator added successfully", "success", 1000)
        }

    }

    checkUserState = (User) => {
        let criterias = this.props.criterias?.filter(item => item.id === parseInt(this.props.criteriaId)) || [];
        let result = [];

        if (this.props.isfromSettingsTemplate) {
            let templatesWithCriterias = this.props.templatesWithCriterias?.Criterias.map((item) => ({ ...item, id: item.ID, name: item.Name }));
            let templateCriterias = []
            if (templatesWithCriterias) templateCriterias = templatesWithCriterias.filter((row) => row.ID === this.props.criteriaId)
            result = templateCriterias.length > 0 ? templateCriterias[0].Collaborators.filter((item) => (item.ID === User.ID)) : [];
        } else {
            result = criterias.length > 0 ? criterias[0].colloborator.filter((item) => (item.ID === User.ID)) : [];
        }

        if (result.length) return result[0];
        return false;
    }

    addMember = (user) => {
        user.isApprover = false
        user.isWatcher = false;
        this.props.onClose(user)
    }

    addWatcher = (user) => {
        user.isApprover = false;
        user.isWatcher = true;
        this.props.onClose(user)
    }

    addApprover = (user) => {
        user.isApprover = true
        this.props.onClose(user)
    }

    removeUser = (user) => {
        const { evaluationId, criteriaId } = this.props;
        if (this.props.isNew) {
            this.props.updateNewCollaborator({ evaluationId: evaluationId, criteriaId: criteriaId, user: { ...user, isRemove: true } });
        } else if (this.props.isfromSettingsTemplate && this.props.showToggle) {
            this.props.removeTemplateCriteriaApprover(evaluationId, criteriaId, user.ID)
        } else if (this.props.isfromSettingsTemplate && !this.props.showToggle) {
            this.props.removeCollaborator(user)
        } else {
            this.props.removeCriteriaApprover(evaluationId, criteriaId, user.ID)
        }
    }

    updateCriteriaUserRole = (user, isApprover) => {
        const { evaluationId, criteriaId } = this.props;
        if (this.props.isNew) {
            this.props.updateNewCollaborator({ evaluationId: evaluationId, criteriaId: criteriaId, user: { ...user, isApprover: isApprover } });
        } else if (this.props.isfromSettingsTemplate) {
            this.props.updateTemplateCriteriaColloborator(evaluationId, criteriaId, user.ID, isApprover);
        } else {
            this.props.criteriaColloboratorRoleUpdate(evaluationId, criteriaId, user.ID, isApprover);
        }
    }

    setCriteriaUserRole = (user, addAsMember, addAsApprover) => {
        if (this.props.criteriaUserRoleInprogress) {
            return;
        }
        const userState = this.checkUserState(user)
        user.addMember = false;
        user.addAprover = false;
        user.removeApprover = false;
        user.updateUserRole = false;
        user.removeMember = false;

        // from approver
        if (userState && userState.IsApprover && addAsApprover) {
            user.removeApprover = true
            this.removeUser(user, true)
        }
        if (userState && userState.IsApprover && addAsMember) {
            user.updateUserRole = true
            this.updateCriteriaUserRole(user, false)
        }

        // from member
        if (userState && !userState.IsApprover && addAsMember) {
            user.removeMember = true
            this.removeUser(user)
        }
        if (userState && !userState.IsApprover && addAsApprover) {
            user.updateUserRole = true
            this.updateCriteriaUserRole(user, true)
        }

        // new member or approver
        if (!userState && addAsMember) {
            user.addMember = true;
            this.addMember(user)
        }

        if (!userState && addAsApprover) {
            user.addAprover = true;
            this.addApprover(user)
        }

        this.setState({ user: user })
    }



    avatarName(name) {
        return name.substring(0, 1);
    }

    CreateNewUser = () => {
        this.setState({
            editValue: { email: this.state.query },
        }, () => {
            this.props.showAddTeamDialog()
        })
    }

    handleActionClick = (event, id) => {
        this.setState({
            showActionMenu: event.currentTarget,
            showActionId: id
        })
    };

    updateUserPriority = (user, priority) => {
        let n_priority = parseInt(priority);
        if (n_priority <= 0 || n_priority > 100) {
            this.props.showSnackBar("Please enter weightage between 0 to 100.", "error", 1000)
            return;
        }
        user.priority = parseInt(n_priority);
        this.setState({
            query: ""
        }, () => {
            this.props.updatePriority(user);
            this.props.searchUser('')
        })
    }

    handleActionClose = (user, target) => {
        console.log(user, 'Addddd')
        console.log(target, 'target')
        const { evaluationId, criteriaId, requirement_info } = this.props;
        if (user && target) {
            if (target === 10) {
                this.setCriteriaUserRole(user, true, false)
            } else if (target === 20) {
                this.setCriteriaUserRole(user, false, true)
            } else if (target === 30) {
                if (this.props.position === 'SecondaryBar') {
                    let data = {}
                    data.evId = evaluationId;
                    data.collaboratorId = user.ID;
                    this.props.fetchRemoveEavluationCollaborator(data)
                } else if (this.props.position === 'EvaluationBar') {
                    let data = {}
                    data.evId = evaluationId;
                    data.collaboratorId = user.ID;
                    this.props.fetchRemoveEavluationCollaborator(data)
                } else if (this.props.position === 'RenewalBar') {
                    this.props.deleteRenewalColloborator(evaluationId, user.ID, false)
                } else if (this.props.position === 'CriteriaHead') {
                    let data = {}
                    data.evId = evaluationId;
                    data.criteriaId = criteriaId;
                    data.collaboratorId = user.ID;
                    this.props.fetchRemoveCriteriaCollaborator(data)
                } else if (this.props.position === 'CriteriaHeadSetup') {
                    let data = {}
                    data.evId = evaluationId;
                    data.criteriaId = criteriaId;
                    data.collaboratorId = user.ID;
                    this.props.fetchRemoveCriteriaCollaborator(data)
                } else if (this.props.position === 'Requirement') {
                    let data = {}
                    data.evId = requirement_info.evaluationId;
                    data.criteriaId = requirement_info.criteriaId;
                    data.requirementId = requirement_info.requirementId;
                    data.collaboratorId = user.ID;
                    this.props.fetchRemoveRequirementCollaborator(data)
                } else if (this.props.position === 'TemplateCriteriaHead') {
                    this.props.removeTemplateCriteriaApprover(evaluationId, criteriaId, user.ID)
                } else if (this.props.position === 'TemplateRequirement') {
                    this.props.removeCollaborator(user)
                } if (this.props.position === 'ProductSetupCart') {
                    this.props.deleteProductCollaboraor(evaluationId, this.props.productId, user.ID);
                }
            } else if (target === 40) {
                this.addWatcher(user)
            }
        }
        this.setState({
            showActionMenu: null,
            showActionId: null,
        })
    };

    render() {
        const classes = this.props.classes;

        const { addNewEvaColloboratorProgress, criteriaUserRoleInprogress, deleteEvaluateCollaboratorInprogress,
            deleteProductCollaboratorProgress, updateRenewalColloboratorProg, deleteRenewalColloboratorProg, deleteCriteriaCollaboratorInprogress,
            deleteRequirementCollaboratorInprogress, allProductCollaboratorProgress } = this.props;

        // users
        let criterias = this.props.criterias && this.props.criterias.length > 0 && this.props.criterias.filter(item => item.id === parseInt(this.props.criteriaId)) || [];
        let colloborators = [];

        if (this.props.isfromSettingsTemplate) {
            if (this.props.position === "TemplateCriteriaHead") {
                let templatesWithCriterias = this.props.templatesWithCriterias?.Criterias.map((item) => ({ ...item, id: item.ID, name: item.Name }));
                let templateCriterias = templatesWithCriterias.filter((row) => row.ID === this.props.criteriaId)
                colloborators = (templateCriterias.length > 0 && templateCriterias[0].Collaborators) || [];
            } else if (this.props.position === "TemplateRequirement") {
                if (this.props.templatesWithCriterias?.Criterias) {
                    this.props.templatesWithCriterias.Criterias.forEach((c) => {
                        c.Requirements.forEach((r) => {
                            if (r.ID === this.props.criteriaId) {
                                colloborators = r.Collaborators || [];
                            }
                        })
                    })
                }
            }
        } else {
            if (this.props.position === "Requirement") {
                if (this.props.isNew) {
                    colloborators = this.props.selectedUser
                } else {
                    this.props.criterias.forEach((c) => {
                        c.requirements.forEach((r) => {
                            if (r.id === this.props.requirement_info.requirementId) {
                                colloborators = r.colloborator || [];
                            }
                        })
                    })
                }
            } else if (this.props.position === "CriteriaHead") {
                if (this.props.isNew) {
                    colloborators = this.props.selectedUser
                } else {
                    colloborators = criterias.length > 0 && criterias[0].colloborator || [];
                }
            } else if (this.props.position === "CriteriaHeadSetup") {
                if (this.props.isNew) {
                    colloborators = this.props.selectedUser
                } else {
                    colloborators = (criterias.length > 0 && criterias[0].colloborator) || [];
                }
            } else if (this.props.position === "SecondaryBar") {
                colloborators = this.props.evColloborators || []
            } else if (this.props.position === "EvaluationBar") {
                colloborators = this.props.evColloborators || []
            } else if (this.props.position === "RenewalBar") {
                colloborators = this.props.evColloborators || []
            } else if (this.props.position === "ProductSetupCart") {
                const { evaluation } = this.props;
                var products = evaluation?.Products !== null ? evaluation?.Products : [];
                var product = products.filter(o => o.ID === this.props.productId)[0];
                colloborators = product.EvaluationProductCollaborators !== null ? product.EvaluationProductCollaborators : []
            } else if (this.props.position === "Renewal") {
                colloborators = this.props.selectedUser || []
            }
        }

        let search_users = this.props.users || [];
        let users = [...colloborators];
        if (this.props.isApproverPage) {
            users = users.filter(usr => usr.IsApprover)
        }
        let existUserIds = _.map(users, 'ID');
        let users_list = this.state.query === '' ? users : search_users;
        users_list = _.uniq(users_list, 'ID');

        let me = this;

        const checkIsExistUser = (id) => {
            if (existUserIds.filter(user => user === id).length > 0) {
                return true;
            } else {
                return false;
            }
        }

        const isApprover = (id) => {
            var currentUsers = users.filter(user => user.ID === id);
            if (currentUsers.length > 0) {
                return currentUsers[0].IsApprover
            } else {
                return false;
            }
        }

        const isWatcher = (id) => {
            var currentUsers = users.filter(user => user.ID === id);
            if (currentUsers.length > 0) {
                return currentUsers[0].IsWatcher !== undefined && currentUsers[0].IsWatcher === true ? true : false
            } else {
                return false;
            }
        }

        var isWorkflowApprovalPage = false;
        if (this.props.match.path === "/app/evaluate/:evaluation_id/workflow/:workflow_id/component/:component_id/approval/:product_id") {
            isWorkflowApprovalPage = true;
        }

        let showNotificationIcon = false;
        if (this.props.showNotificationIcon !== undefined && this.props.showNotificationIcon === true) {
            showNotificationIcon = true;
        }

        let isLoader = false;
        if (addNewEvaColloboratorProgress || criteriaUserRoleInprogress || deleteEvaluateCollaboratorInprogress || deleteProductCollaboratorProgress ||
            updateRenewalColloboratorProg || deleteRenewalColloboratorProg || deleteCriteriaCollaboratorInprogress || deleteRequirementCollaboratorInprogress || deleteProductCollaboratorProgress || allProductCollaboratorProgress) {
            isLoader = true;
        }

        return <>
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={this.props.open}
                // onClose={this.props.hideEvaluationCollaboratorDialog}
                onClose={(event, reason) => {
                    this.props.hideEvaluationCollaboratorDialog()
                }}
                aria-labelledby="add-collaborator-dialog"
                TransitionComponent={Transition}
                className={classnames(classes.dialog)}
            >
                <DialogContent >
                    <div className={classes.collaboratorContainer}>
                        <div className={classes.collaboratorHeader}>
                            {/* {this.props.showToggle && ' or Approver'} */}
                            <Typography className={classes.title}>
                                <span className={classes.shareIcon}><GroupAddOutlinedIcon /></span> Add users and teams
                            </Typography>

                            <div className={classes.searchCol}>
                                <InputBase
                                    className={classes.input}
                                    placeholder={"Search Email"}
                                    onChange={(event) => {
                                        me.setState({
                                            query: event.target.value
                                        }, () => {
                                            me.props.searchUser(me.state.query)
                                        })
                                    }}
                                />
                                {/* <span>{this.props.searchTitle}</span> */}
                            </div>
                        </div>

                        {isLoader && <div style={{ textAlign: 'center', marginTop: 10 }}>
                            <CircularProgress />
                        </div>}

                        {!isLoader && users_list !== null && users_list.length > 0 &&
                            <List dense={true}>
                                {
                                    users_list.map((user, k) => {
                                        return <ListItem key={k} className={classes.listItem}>
                                            <ListItemAvatar className={classes.listAvatar}>
                                                <Avatar className={classes.avatar}>
                                                    {this.avatarName(user.Name)}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={
                                                <div style={{ display: 'flex', placeContent: 'center space-between', alignItems: 'center' }}>
                                                    <span className={classes.userEmailText}>
                                                        <span style={{ fontWeight: 500, color: '#202124' }}>{user.Name} {user.EmailVerifiedOn === null || user.EmailVerifiedOn === '' ? <b className={classes.unverified}>Unverified</b> : ''}</span>
                                                        <span style={{ color: '#5f6368' }}>{user.Email}</span>
                                                        {/* { this.checkUserState(user).IsApprover == false && <DoneIcon color="primary"/> } */}
                                                    </span>

                                                    {this.props.isShowAddWeightage && checkIsExistUser(user.ID) === true && (this.props.position === "CriteriaHeadSetup" || this.props.position === "TemplateCriteriaHead") && <div>
                                                        <PriorityInput
                                                            value={user?.TemplateCriteriaPriority !== null && user?.TemplateCriteriaPriority !== '' && user?.TemplateCriteriaPriority > 0 ? user?.TemplateCriteriaPriority : ''}
                                                            user={user}
                                                            onSubmit={(value) => {
                                                                this.updateUserPriority(user, value);
                                                            }}
                                                        />

                                                    </div>}

                                                    {this.props.position !== 'EvaluationBar' && <Button
                                                        onClick={(e) => this.handleActionClick(e, user.ID)}
                                                        aria-controls="simple-menu" aria-haspopup="true">
                                                        {
                                                            checkIsExistUser(user.ID) === true &&
                                                            <>
                                                                <>{isApprover(user.ID) === true && <>Approver</>}</>
                                                                <>{isApprover(user.ID) === false && <>Collaborator</>}</>
                                                            </>
                                                        }
                                                        {
                                                            checkIsExistUser(user.ID) === false &&
                                                            <> Action</>
                                                        }
                                                        {/* { 
                                                                checkIsExistUser(user.ID) === true ? (user.isApprover === true ? 'Approver' : ' Collaborator') : 'Action'
                                                            }                                                         */}
                                                        <ArrowDropDownIcon />
                                                    </Button>}

                                                    {this.props.position === 'EvaluationBar' && <Button
                                                        onClick={(e) => this.handleActionClick(e, user.ID)}
                                                        aria-controls="simple-menu" aria-haspopup="true">
                                                        {
                                                            checkIsExistUser(user.ID) === true &&
                                                            <>
                                                                <>{isWatcher(user.ID) === true && <>Watcher</>}</>
                                                                <>{isWatcher(user.ID) === false && <>Collaborator</>}</>
                                                            </>
                                                        }

                                                        {
                                                            checkIsExistUser(user.ID) === false &&
                                                            <> Action</>
                                                        }
                                                        {/* { 
                                                                checkIsExistUser(user.ID) === true ? (user.isApprover === true ? 'Approver' : ' Collaborator') : 'Action'
                                                            }                                                         */}
                                                        <ArrowDropDownIcon />
                                                    </Button>}

                                                    {checkIsExistUser(user.ID) === true && showNotificationIcon && <div>
                                                        <Button
                                                            onClick={(event) => {
                                                                this.props.showNudgeNotificationDialog(user, this.props.notificationData);
                                                            }}
                                                            className={classes.nudgeBtn}
                                                            type='button'>
                                                            Nudge
                                                        </Button>
                                                        {/* <NotificationsIcon className={classes.notificationIcon}
                                                            onClick={(event) => {
                                                                this.props.showNudgeNotificationDialog(user, this.props.notificationData);
                                                            }}
                                                        /> */}
                                                    </div>}

                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={this.state.showActionMenu}
                                                        // anchorOrigin={{
                                                        //     vertical: 'bottom',
                                                        //     horizontal: 'center',
                                                        // }}
                                                        keepMounted
                                                        open={Boolean(this.state.showActionId === user.ID)}
                                                        onClose={() => { this.handleActionClose() }}
                                                    >
                                                        {!isWorkflowApprovalPage && <>
                                                            {!this.props.hideCollaborator && <MenuItem value={10} onClick={() => { this.handleActionClose(user, 10) }}>Collaborator</MenuItem>}
                                                            {this.props.showToggle && <MenuItem value={20} onClick={() => { this.handleActionClose(user, 20) }}>Approver</MenuItem>}
                                                            {this.props.position === 'EvaluationBar' && <MenuItem value={40} onClick={() => { this.handleActionClose(user, 40) }}>Watcher</MenuItem>}
                                                        </>}
                                                        {isWorkflowApprovalPage && <>
                                                            {<MenuItem value={20} onClick={() => { this.handleActionClose(user, 20) }}>Approver</MenuItem>}
                                                        </>}
                                                        {checkIsExistUser(user.ID) === true && (
                                                            <MenuItem
                                                                value={30}
                                                                onClick={() => {
                                                                    if (this.props.isNew) {
                                                                        this.props.removeCollaborator(user.ID)
                                                                        this.handleActionClose()
                                                                    } else {
                                                                        this.handleActionClose(user, 30)
                                                                    }
                                                                }}
                                                            >
                                                                Remove
                                                            </MenuItem>
                                                        )}
                                                    </Menu>
                                                </div>
                                            }
                                                // onClick={()=>{this.setCriteriaUserRole(user, true, false)}}
                                                className={classnames(classes.userEmail, this.checkUserState(user) ? classes.isMember : '')} />

                                            {/* {
                                                this.props.showToggle && 
                                                <span 
                                                onClick={()=>{this.setCriteriaUserRole(user, false, true)}}
                                                className={classnames(
                                                    classes.userState, 
                                                    this.checkUserState(user)?.IsApprover ? 'active' : '',
                                                    (this.props.criteriaUserRoleInprogress || this.props.orgTemplateCriteriaUserRoleInprogress) ? 'inprogress': ''
                                                )}></span>
                                            } */}
                                        </ListItem>
                                    })
                                }
                            </List>
                        }
                        {
                            (this.state.query !== '' && !this.props.progress) && this.props.users?.length === 0 &&
                            <span style={{ display: 'block', textAlign: 'center', padding: '50px 20px 10px', marginBottom: '12px' }}>User not found
                                {false && <Button className={classes.newUserBtn} onClick={() => { this.CreateNewUser() }}>
                                    + Add user
                                </Button>}
                                {/* <span >create <b>{this.state.query}</b></span> */}
                            </span>
                        }
                    </div>
                    {
                        this.props.addTeamDialogOpen &&
                        <AddUserDialog
                            isFromSettings={true}
                            isEdit={false}
                            editValue={this.state.editValue}
                        />
                    }
                </DialogContent>
                <div className={classes.footerAction}>
                    <Button className={classes.btn} onClick={() => { this.props.hideEvaluationCollaboratorDialog() }}>
                        Done
                    </Button>
                </div>
            </Dialog>
        </>
    }
}

SearchUserPoper.defaultProps = {
    searchTitle: '',
    selectedUser: [],
    showToggle: false,
    isfromSettingsTemplate: false,
    criterias: [],
    isNew: false,
    updateNewCollaborator: () => { },
    removeCollaborator: () => { },
    hideCollaborator: false,
    isApproverPage: false
}

export default connector(compose(
    withStyles(styles),
    withRouter
)(SearchUserPoper));