import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import Home from "./Components/Home";
import { updateDrawerStyle } from "../../../../redux/drawer/action";
// views
import VendorRenewalWorkflowCards from 'Components/Views/Buyer/VendorRenewalWorkflowCards';

const connectedProps = (state) => ({
  isShowUpdateSurveyDialog: state.survey.isShowUpdateSurveyDialog,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class RenewalWorkflows extends React.Component {

  componentDidMount() {
    this.props.updateDrawerStyle(true, true);
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={this.props.match.path} component={Home} />
          <Route
            exact
            path={`${this.props.match.path}/in-progress`}
            render={(props) => (
              <VendorRenewalWorkflowCards {...props} layout="inProgress" title="In-Progress Renewals" />
            )} />
          <Route
            exact
            path={`${this.props.match.path}/completed`}
            render={(props) => (
              <VendorRenewalWorkflowCards {...props} layout="completed" title="Completed Renewals" />
            )} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default connector(compose(withStyles(styles))(RenewalWorkflows));
