import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AppBarV2 from "../AppBarV2";
import Actions from "./Home/Components/Actions";
import CommonCss from 'commonCss';

const connectedProps = (state) => ({
    user:state.authUser.user,
});

const connectionActions = {
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    rootContainer:CommonCss.rootContainer
});

class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }


    render() {
        if(this.props.user == null){
            return null;
        }

        const classes = this.props.classes;
        return <div>
            <AppBarV2 title="My Tasks" dropdown={false} withBack={true} />
            <div className={classes.rootContainer}>
            <Actions title={""}/>
            </div>
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(Notifications));