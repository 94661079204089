import React, { useState } from 'react'
import {
  Transforms,
  Editor,
  Range,
  Element as SlateElement,
} from 'slate'
import { useEditor, useSlate } from 'slate-react';
import isUrl from 'is-url'

import {
  useSelected,
  useFocused,
} from 'slate-react'

import { IconButton, Dialog, DialogContent, Button, Typography } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { CommonFn } from "../../../services/commonFn";
import axios from 'axios'
import makeStyles from '@material-ui/styles/makeStyles';
import * as Environment from "util/Environment";
import Alert from '@material-ui/lab/Alert';
import * as Validator from "util/Validation";
import { Resizable } from 'react-resizable';
import "../../../../node_modules/react-resizable/css/styles.css"

export const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <img
          onClick={(e) => {
            e.preventDefault()
          }}
          alt={'Not Found'}
          src={element.url}
          style={{
            maxHeight: element?.height || 400,
            height: element?.height || 200,
            width: element?.width || 200,
            maxWidth: "100%",
            // display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
          }}
        />
      </div>
      {children}
    </div>
  );
};

const InsertImage = (editor, data) => {
  const image = {
    type: "image",
    url: data.url,
    height: data.height,
    width: data.width,
    children: [{ text: '' }]
  };
  Transforms.insertNodes(editor, image);
  Transforms.move(editor)
};

export const InsertImageButton = ({ icon, folderPath, external }) => {
  const editor = useEditor();

  const [isOpen, setIsOpen] = useState(false);

  const [imgHeight, setHeight] = useState(200);
  const [imgWidth, setWidth] = useState(200);

  const [fileURL, setFileURL] = useState('');

  const classes = useStyles();

  const imageSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var formData = new FormData();
      if (folderPath) {
        formData.append("folder", folderPath);
      }
      const timestamp = (new Date()).getTime();
      formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf('.') + 1)}`);
      const api_server = Environment.api_host("STORAGE");
      var response = null;
      if (!external) {
        const url = `${api_server}/add?t=${timestamp}`;
        response = await axios.post(url, formData, {
          headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
        });
      } else {
        const url = `${api_server}/external/add?t=${timestamp}`;
        response = await axios.post(url, formData, {
        });
      }

      if (response.data.filepath) {
        setFileURL(response.data.filepath)
        // setFileName(file.name)
        setIsOpen(true)
      }
    }
  }

  const uploadImageBtn = () => <IconButton component="span">{icon}</IconButton>;

  const submitImage = () => {
    InsertImage(editor, {
      url: fileURL,
      height: imgHeight,
      width: imgWidth,
    })
    clearImg()
  }

  const onResize = (event, { element, size, handle }) => {
    setHeight(size.height)
    setWidth(size.width)
  }

  // const linkImageIcon = (e) => {
  //   console.log(editor, 'editor')
  //   const [image] = Editor.nodes(editor, {
  //     match: n =>
  //       !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image',
  //   })
  //   e.preventDefault()
  // }

  const clearImg = (e) => {
    setIsOpen(false);
    setFileURL('')
  }

  return (
    <>
      <input
        style={{ display: "none" }}
        id="upload-image-file"
        type="file"
        onChange={imageSelectHandler}
        accept="image/x-png,image/gif,image/jpeg"
      //   ref={fileInputRef}
      />
      <label htmlFor="upload-image-file">{uploadImageBtn()}</label>

      <Dialog
        aria-labelledby="app-addVariableDialog"
        open={isOpen}
        maxWidth={"md"}
        scroll="body"
        className={classes.dialog}
        id={"addVariableMetaDialog"}
        fullWidth={true}
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Typography>Resize Image</Typography>
          <Resizable
            height={imgHeight}
            width={imgWidth}
            onResize={onResize}
            minConstraints={[100, 100]}
            // handle={(handleAxis, ref) => <MyHandle innerRef={ref} className={`foo handle-${handleAxis}`}/>}
            resizeHandles={['sw', 'se', 'nw', 'ne', 'w', 'e', 'n', 's']}
          >
            <div style={{ margin: '0 auto', border: '1px solid #f0f0f0', width: imgWidth + 'px', height: imgHeight + 'px' }}>
              <img style={{ padding: 20, width: imgWidth + 'px', height: imgHeight + 'px' }} alt='notFound' src={fileURL} />
            </div>
          </Resizable>

          <div className={classes.btnSection}>
            <Button variant="outlined" color="secondary" className={classes.button} onClick={(event) => {
              clearImg()
            }} >Cancel</Button>&nbsp;&nbsp;
            <Button variant="contained" color="secondary" className={classes.button}
              onClick={(event) => { submitImage() }}>Save
            </Button>
          </div>

        </DialogContent>
      </Dialog>
    </>
  );
};

const InsertAttachment = (editor, url, name) => {
  const link = { type: "link", isFile: true, url, children: [{ text: name }] };
  Transforms.insertNodes(editor, link);
};

export const InsertAttachmentButton = ({ icon, folderPath, external, id }) => {
  const editor = useEditor();
  const fileSelectHandler = async (event, nEditor) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var formData = new FormData();
      if (folderPath) {
        formData.append("folder", folderPath);
      }
      const timestamp = (new Date()).getTime();
      formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf('.') + 1)}`);
      const api_server = Environment.api_host("STORAGE");
      var response = null;
      if (!external) {
        const url = `${api_server}/add?t=${timestamp}`;
        response = await axios.post(url, formData, {
          headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
        });
      } else {
        const url = `${api_server}/external/add?t=${timestamp}`;
        response = await axios.post(url, formData, {
        });
      }

      if (response.data.filepath) {
        InsertAttachment(nEditor, response.data.filepath, file.name)
      }
    }
  }

  const uploadButtom = () => <IconButton component="span">{icon}</IconButton>;
  return (
    <>
      <input
        style={{ display: "none" }}
        id={"upload-image_" + id}
        type="file"
        onChange={(event) => {
          console.log(editor, 'InitialEditor')
          fileSelectHandler(event, editor)
        }}
      //   ref={fileInputRef}
      />
      <label htmlFor={"upload-image_" + id}>{uploadButtom()}</label>
    </>
  );
};

export const InsertLinkButton = ({ icon, folderPath }) => {
  const editor = useSlate();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [selection, setSelection] = useState(null);
  const [selectedOption, setSelectedOption] = useState('link');
  const [isUpdate, setIsUpdate] = useState(false);
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState('');

  const insertValue = (event) => {
    event.stopPropagation();
    setUrlError("")
    if (url.toLowerCase().indexOf("javascript:") !== -1) {
      setUrlError("Invalid URL")
      return;
    }
    if (url) {
      var setUrl = url;
      if (selectedOption === 'email') {
        if (Validator.validateEmail(url) === false) {
          setUrlError("Invalid Email")
          return;
        }
        setUrl = `mailto:${url}`
      }
      if (selectedOption === 'link') {
        if (Validator.validateURL(url) === false) {
          setUrlError("Invalid URL")
          return;
        }
      }
      editor.selection = selection
      insertLink(editor, setUrl);
    }
    clear();
  }

  const clear = (e) => {
    setIsOpen(false);
    setUrl('');
    setSelectedOption('link')
  }

  const unwrapText = () => {
    editor.selection = selection
    unwrapLink(editor);
    clear();
  }

  const linkIcon = (e) => {
    console.log(editor, 'editor')
    const [link] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    if (link && link[0]) {
      setUrl(link[0].url);
      setIsUpdate(true)
    } else {
      setIsUpdate(false)
    }
    e.preventDefault()
    setSelection(editor.selection)
    setIsOpen(true)
  }

  return (
    <span
    >
      <ToggleButton
        className={classes.insertLink}
        selected={(isLinkActive(editor))}
        onMouseDown={event => linkIcon(event)}> <IconButton >{icon}</IconButton></ToggleButton>
      <Dialog
        aria-labelledby="app-link-editor"
        open={isOpen}
        disableBackdropClick={true}
        maxWidth={"sm"}
        scroll="body"
        className={classes.dialog}
        id="link-editor"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.alert}>
            {urlError.length > 0 && <Alert variant="filled" severity="error">{urlError}</Alert>}
          </div>
          <div>
            <label><input type="radio" name='subCategory' checked={'link' === selectedOption} onChange={() => { setSelectedOption('link') }} className={classes.radioBox} /> <span className={classes.label}>Link</span></label>&nbsp;
            <label><input type="radio" name='subCategory' checked={'email' === selectedOption} onChange={() => { setSelectedOption('email') }} className={classes.radioBox} /><span className={classes.label}>Email</span></label>
          </div>
          <div>
            <input value={url} onChange={(e) => { setUrl(e.target.value) }} className={classes.inputBox} />
          </div>
          <div className={classes.btnSection}>
            <Button variant="outlined" color="secondary" className={classes.button} onClick={(event) => {
              clear()
            }} >Cancel</Button>&nbsp;&nbsp;
            {isUpdate && <Button variant="contained" color="secondary" className={classes.button} onClick={(event) => unwrapText(event)}>Unlink</Button>} &nbsp;&nbsp;
            <Button variant="contained" color="secondary" disabled={url.length < 1} className={classes.button} onClick={(event) => insertValue(event)}>{isUpdate ? 'Update' : 'Save'}</Button>
          </div>
        </DialogContent>
      </Dialog>
    </span>
  )
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

export const withLinks = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }
  return editor
}

export const withImage = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return element.type === 'image' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      withImage(editor, text)
    } else {
      insertData(data)
    }
  }
  return editor
}

const useStyles = makeStyles((theme) => ({
  insertLink: {
    padding: 0,
    minWidth: 41,
    margin: '0.25em',
    border: 'none'
  },
  radioBox: {
    width: 13,
    height: 13,
    maxWidth: 13,
    minWidth: 13
  },
  inputBox: {
    width: 350,
    height: 40,
    borderRadius: 3,
    border: '1px solid #ddd',
    margin: '20px 0',
    padding: 5
  },
  btnSection: {
    float: 'right',
    marginBottom: 10
  },
  label: {
    position: 'relative',
    top: -1
  },
  alert: {
    marginBottom: theme.spacing(1)
  }
}));
