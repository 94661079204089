import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import EvaluateMultiChoiceAnswer from 'Components/Common/EvaluateMultiChoiceAnswer'
import classnames from "classnames";
import UserGroup from '../../../../../../../Common/UserGroup';

import {
  Grid,
  Typography,
  Divider, Switch
} from '@material-ui/core';

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  user: state.authUser.user,
  report: state.evaluationReportData.report,
  isLoading: state.evaluationReportData.isLoading,
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  requirementExpanded01: {
    // borderTop: '1px solid #ccc',
    // borderLeft: '1px solid #ccc',
    // borderRight: '1px solid #ccc',
    height: '100%',
    // position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  requirementExpanded02: {
    // border: '1px solid #c1c1c1',
    // marginBottom: 23
  },
  requirementCollapsed: {
    paddingBottom: 12
  },
  requirementCollapse: {
    padding: '12px 16px 0 25px',
    cursor: 'pointer',
  },
  collapseTitle: {
    fontSize: theme.spacing(2),
    color: '#333333',
    fontWeight: 600,
  },
  collapseTxt: {
    fontSize: theme.spacing(2),
    color: '#707070',
    marginTop: theme.spacing(1),
  },
  descriptionContainer: {
    marginTop: 18
  },
  description: {
    color: "#707070",
    fontSize: 13,
    minHeight: 50 // 106
  },
  requirementContainer: {
    padding: '20px',
    // padding: '30px 16px 0 25px',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: theme.spacing(2),
    color: '#333333',
    fontWeight: 600,
    width: 'max-content',
    '& > img': {
      display: 'none'
    },
    '&:hover > img': {
      display: 'initial'
    }
  },
  leftPanel: {
  },
  rightPanel: {
    paddingTop: 25
  },
  actions: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    maxWidth: 80,
    marginLeft: 'auto'
  },
  actionItem: {
    textAlign: "center",
    cursor: "pointer",
    marginRight: 4
  },
  priority: {
    marginTop: theme.spacing(3),
    "& > div": {
      marginBottom: 0,
    },
    "&.evaluate-priority": {
      display: 'flex',
      marginLeft: 0,
    },
    "& [class*=MuiFormLabel-root]": {
      fontSize: '0.9em'
    },
    "& [class*=MuiInputBase-root]": {
      color: '#707070',
      fontSize: 13,
      textAlign: 'center'
    },
    "& [class*=MuiSelect-outlined]": {
      color: "#707070",
      fontSize: theme.spacing(1.8),
      "&:after": {
        content: "''",
        width: 7,
        height: 7,
        position: 'absolute',
        left: 9,
        borderRadius: '50%',
        top: '50%',
        transform: 'translate(0px, -50%)'
      },
    },
    "&.low [class*=MuiSelect-outlined]:after": {
      background: 'green'
    },
    "&.medium [class*=MuiSelect-outlined]:after": {
      background: 'orange'
    },
    "&.high [class*=MuiSelect-outlined]:after": {
      background: 'red'
    }
  },
  actionTxt: {
    color: '#4175DF',
    width: 'max-content',
    fontSize: theme.spacing(1.75),
    marginTop: 15
  },
});

class Requirement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  getType(req) {
    let type = 'Text'
    if (req && req?.answer_type) {
      const answer_type = parseInt(req?.answer_type)
      if (2 === answer_type) type = 'Multi Select'
      if (3 === answer_type) type = 'Single Select'
      if (4 === answer_type) type = 'Binary'
      if (5 === answer_type) type = 'Score'
      if (6 === answer_type) type = 'File upload'
    }
    return type
  }

  render() {
    const { classes, criteria, requirement } = this.props;

    let priorityText = '';
    if (requirement.priority !== undefined && requirement.priority !== null && requirement.priority === 0) {
      priorityText = 'No Weightage';
    }

    if (requirement.priority !== undefined && requirement.priority !== null && requirement.priority > 0) {
      priorityText = 'Weightage: ' + requirement.priority + '%';
    }

    var editable = false;
    var isOdd = true;
    return (
      <div id={`requirement_${this.props.criteria?.id}_${this.props.requirement?.id}`} className={classnames(classes.root, `requirement_${requirement.id}`, isOdd ? classes.requirementExpanded01 : classes.requirementExpanded02)}>
        <Grid container className={classes.requirementContainer}>
          <Grid item xs={12}>
            <Typography component="p" className={classes.title}>
              {this.state.name || requirement.name}
            </Typography>
            <Typography component="p" className={classes.bodyTxt}>
              <SlateReadonlyField initValue={this.state.description || requirement.description} />
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.leftPanel}>
            <EvaluateMultiChoiceAnswer
              evaluation_id={this.props.match.params.evaluation_id}
              requirement={requirement}
              criteria={criteria}
              editable={false}
              isTemplate={false}
              saveBtnId={''}
              isCriteriaRequirement
            />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={4} className={classes.RightPanel}>
            <div className={classes.rightAction}>
              <label className={classes.label} style={{ marginBottom: 10 }}>
                {editable ? "Add members" : "Members"}
              </label>
              <UserGroup
                position={'Requirement'}
                requirement_info={{
                  criteriaId: criteria.id,
                  requirementId: requirement.id,
                  evaluationId: this.props.match.params.evaluation_id
                }}
                editable={editable}
                onAdd={(user) => {

                }}
                users={requirement.colloborator}
                criterias={this.props.criterias} />
            </div>
            <Typography className={classes.actionTxt}>{priorityText}</Typography>
            <div className={classes.rightAction} style={{ marginTop: 10 }}>
              <Grid container alignItems="center">
                <Grid item>
                  <label className={classes.label} style={{ marginRight: 8 }}>
                    Required
                  </label>
                  <br />
                  <br />
                  <label className={classes.label} style={{ marginRight: 8 }}>
                    Editable
                  </label>
                </Grid>
                <Grid item>
                  <Switch
                    checked={requirement.is_mandatory}
                    disabled={!editable}
                    name="is_mandatory"
                    color="primary"
                    inputProps={{ 'aria-label': 'is_mandatory checkbox' }}
                  />
                  <br />
                  <Switch
                    checked={requirement.is_editable}
                    disabled={!editable}
                    color="primary"
                    name="is_editable"
                    inputProps={{ 'aria-label': 'is_editable checkbox' }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Requirement));
