import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Button, } from "@material-ui/core";
import CustomSection from "./CustomSection";
import { showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import AddSurveyDialog from "Components/Application/Components/Survey/Components/Common/AddSurveyDialog"
import { showSnackBar } from "redux/snackbar/action";
import CollaboratorPopper from './Common/CollaboratorPopper';

import { fetchProductSurveys, fetchCompletedProductSurveys, showAddSurveyDialog, hideAddSurveyDialog } from "redux/survey/action";
import SurveyData from "./Common/SurveyData";


const connectedProps = (state) => ({
  fetchProductSurveysProgress: state.survey.fetchProductSurveysProgress,
  productSurveys: state.survey.productSurveys,
  fetchCompletedProductSurveysProgress: state.survey.fetchCompletedProductSurveysProgress,
  productCompletedSurveys: state.survey.productCompletedSurveys,
  isShowAddSurveyDialog: state.survey.isShowAddSurveyDialog,
  addNewSurveyProgress: state.survey.addNewSurveyProgress,
  addNewSurveyError: state.survey.addNewSurveyError,
  surveyData: state.survey.surveyData,
  userType: state.authUser.user?.Role,
});


const connectionActions = {
  fetchProductSurveys: fetchProductSurveys,
  fetchCompletedProductSurveys: fetchCompletedProductSurveys,
  showAddSurveyDialog: showAddSurveyDialog,
  showSnackBar: showSnackBar,
  hideAddSurveyDialog: hideAddSurveyDialog,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  itemSection: {
    border: '1px solid #d8d8d8',
    padding: 17,
    borderRadius: 5,
    background: '#FFF',
    marginBottom: 17,
    position: 'relative',
  },
  positionRelative: {
    position: 'relative',
  },
  head: {
    color: '#6C6C6C',
    fontSize: 18,
    opacity: 1,
    fontWeight: 600,
    margin: 0,
    marginBottom: 11
  },
  threeDot: {
    position: "absolute",
    zIndex: 11,
    right: 5,
    top: 5,
    padding: 5
  },
  contactCardHeader: {
    flexDirection: "column",
    padding: "16px 4px !important",
    "& [class*='MuiCardHeader-avatar-']": {
      margin: "0px",
      marginBottom: 12,
    },
    "& [class*='MuiCardHeader-content']": {
      width: "100%",
    },
    "& [class*=MuiTypography-displayBlock]": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      margin: "0px -19px 0px 0px",
    },
  },
  listIcon: {
    minWidth: theme.spacing(5),
  },
  menuList: {
    padding: '0px',
    "& > hr": {
      "&:first-child": {
        display: 'none'
      },
    }
  },
  popover: {
    "& [class*='paper']": {
      boxShadow: "0px 8px 12px #0000001D",
      border: "1px solid #EEEEEE",
      borderRadius: "5px",
      overflow: "inherit",
      "&::before": {
        top: "50px",
        left: "-16px",
        width: "0",
        borderTop: "8px solid transparent",
        height: "0px",
        content: "''",
        display: "block",
        position: "absolute",
        borderBottom: "8px solid transparent",
        borderLeft: "8px solid transparent",
        borderRight: "8px solid #fff"
      },
    },
  },
  addReviewBtn: {
    position: 'absolute',
    right: 55,
    top: 20
  }
});

class SurveyTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 1000,
      cPage: 0,
      cPageSize: 1000,
      anchorEl: null,
    };
    this.btnRef = React.createRef();
  }

  componentDidMount() {
    console.log(this.props.productDetails, 'PDetails')
  }

  componentDidUpdate(prevProps) {
    if (this.props.addNewSurveyProgress === false && prevProps.addNewSurveyProgress === true) {
      if (this.props.addNewSurveyError === null) {
        this.props.showSnackBar("Survey successfully created", "success", 3000);
        this.props.hideAddSurveyDialog();
        let redirectPath = `/app/survey/${this.props.surveyData?.ID}`;
        this.props.history.push(redirectPath);
      } else {
        if (this.props.addNewSurveyError.message !== undefined) {
          this.props.showSnackBar("Failed to add survey", "error", 3000);
        } else {
          this.props.showSnackBar("Something went wrong", "error", 3000);
        }
      }
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const classes = this.props.classes;
    const { productDetails, userType } = this.props;

    return (
      <div>
        <div className={classes.contentSection} onScroll={(e) => this.scrollContent(e)}>
          {this.props.sections && this.props.sections.length > 0 && this.props.sections.map(section => {
            // return (<div className={classes.itemSection}>{section.SectionUID}</div>)
            if (section.IsDeletable && section.IsEditable) {
              return (
                <CustomSection
                  key={section.SectionUID}
                  section={section}
                  isEditable={(section.Permission === "write") || (section.Access === 2)}
                  productID={productDetails.productID}
                 
                />
              )
            } else if (section.SectionUID === "survey_section") {
              return (
                <div className={classes.itemSection}>
                  {userType === "OrgAdmin"  &&<CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers}/>}       
                  <p className={classes.head}>{section.SectionName}</p>
                  {this.props.userType === 'OrgAdmin' && <Button
                    variant="contained"
                    color="primary"
                    className={classes.addReviewBtn}
                    onClick={() => {
                      this.props.showAddSurveyDialog(true, {}, { value: productDetails.productID, label: productDetails.name });
                    }}>Add Survey
                  </Button>}

                  <SurveyData
                    productDetails={productDetails}
                    productId={productDetails.productID}
                    updateProducts={this.props.updateProducts}
                    hideFlagForUser={this.props.hideFlagForUser}
                    sections={this.props.sections}
                    adminFlag={this.props.adminFlag}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    cPage={this.state.cPage}
                    cPageSize={this.state.cPageSize}
                  />
                </div>)
            }
          })}
        </div>

        {this.props.isShowAddSurveyDialog && <AddSurveyDialog />}

      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(SurveyTab));
