import {
    START_EVALUATION_DOWNLOAD_REPORT,END_EVALUATION_DOWNLOAD_REPORT
} from "./action";


const initState   =     {
    downloadProg:false,
    report:null
}

export  function evaluationReportReducer(state=initState,action){
    switch(action.type){
        case START_EVALUATION_DOWNLOAD_REPORT:
            return {
                ...state,
                downloadProg:true,
                report:null
            };
        case END_EVALUATION_DOWNLOAD_REPORT:
            return {
                ...state,
                downloadProg:false,
                report:action.payload.success
            };
        default:
            return state;
    }
}




