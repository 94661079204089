import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import Home from "./Components/Home";
import SurveyView from "./Components/SurveyView/index";
import TakeSurvey from "./Components/TakeSurvey";
import Products from "./Components/Products/index";
import ProductSurvey from "./Components/Products/Components/ProductSurvey"
import { updateDrawerStyle } from "../../../../redux/drawer/action";
import UpdateSurveyDialog from "./Components/Common/UpdateSurveyDialog"
// views
import VendorSurveyCards from 'Components/Views/Buyer/VendorSurveyCards';
const connectedProps = (state) => ({
  isShowUpdateSurveyDialog: state.survey.isShowUpdateSurveyDialog,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class Survey extends React.Component {

  componentDidMount() {
    this.props.updateDrawerStyle(true, true);
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={this.props.match.path} component={Home} />
          <Route
            exact
            path={`${this.props.match.path}/in-progress`}
            render={(props) => (
              <VendorSurveyCards {...props} layout="inProgress" title="In-Progress Survey" />
            )} />
          <Route
            exact
            path={`${this.props.match.path}/completed`}
            render={(props) => (
              <VendorSurveyCards {...props} layout="completed" title="Completed Survey" />
            )} />
          <Route
            path={`${this.props.match.path}/org-survey-products`}
            component={Products}
          />
          <Route
            exact
            path={`${this.props.match.path}/product-survey/:productID`}
            component={ProductSurvey}
          />
           <Route
            path={`${this.props.match.path}/:survey_id/take-survey`}
            component={TakeSurvey}
          />
          <Route
            path={`${this.props.match.path}/:survey_id`}
            component={SurveyView}
          />
        </Switch>
        {this.props.isShowUpdateSurveyDialog &&
          <UpdateSurveyDialog />
        }
      </React.Fragment>
    );
  }
}

export default connector(compose(withStyles(styles))(Survey));
