import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { Typography, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import Checkbox from "../../assets/images/checkbox.svg";
import ToolTip from "Components/Common/ToolTip.jsx";

import Image from 'Components/Common/image.jsx'


const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      background: theme.palette.common.white,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(2),
    },
    imageContainer: {
      textAlign: "center",
      flex: 1,
    },
    image: {
      maxHeight: theme.spacing(10),
      paddingTop: theme.spacing(2),
      maxWidth: 168
    },
    content: {
      flex: 4,
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '90px'
    },
    primary: {
      fontSize: theme.spacing(2.2),
      marginBottom: theme.spacing(1),
      fontWeight: 400,
    },
    secondary: {
      fontSize: theme.spacing(1.8),
      color: "#74798C",
    },

    iconSection: {
      textAlign: "right",
      width: "100%",
    },
    iconButton: {
      color: "#000",
    },
    checkbox: {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(2),
      height: theme.spacing(2),
    },
    "@global": {
      '.PageSettings': {
        '& ._root':{
          minHeight: '180px'
        },
        '& .cardContent':{
          "@media (max-width: 575.98px)" :{
            height: '130px',
          }
        }
      }, 
    },
  });

class CardAction extends React.Component {
  constructor(props) {
    super(props);
    this.navigate = this.navigate.bind(this);
    this.state = {
      event: false
    }
  }

  navigate(link,isExternal) {
    if(isExternal){
      window.open(link, '_self');
      return;
    }
    if (link) {
      this.props.history.push(link);
    }
  }
  
  openToolTip = (value) => {
    if(value) {
      value.persist()
    }

    this.setState({
      event: value
    })
  }

  render() {
    const classes = this.props.classes;
    return (
      <div onMouseEnter={(event) => this.openToolTip(event)} onMouseLeave={() => this.openToolTip(null)}
        className={classnames(classes.root, this.props.cardActionClass, '_root')}
        onClick={() => this.navigate(this.props.link, this.props.isExternal)}
      >
        <div className={classes.imageContainer}>
          <Image
            src={this.props.image}
            alt="card layout"
            className={classes.image}
          />
        </div>
        <div className={classnames(classes.content, this.props.contentClass, 'cardContent')}>
          <Typography
            variant={"h2"}
            className={classnames(classes.primary, this.props.primaryClass)}
          >
            {this.props.primary}
          </Typography>
          <Typography
            variant={"subtitle1"}
            className={classnames(classes.secondary, this.props.secondaryClass)}
          >
            {this.props.secondary}
          </Typography>
        </div>
        {this.props.activeImage && (
          <div className={classes.iconSection}>
            <IconButton size={"small"} className={classes.iconButton}>
              <ArrowForwardIcon />
            </IconButton>
          </div>
        )}
        {this.props.checked && (
          <Image src={Checkbox} alt="chekcbox" className={classes.checkbox} />
        )}
        {this.props.toolTip >= 0 && <ToolTip toolTip={this.props.toolTip }/> }
      </div>
    );
  }
}

CardAction.defaultProps = {
  cardActionClass: "",
  contentClass: "",
  primaryClass: "",
  secondaryClass: "",
  activeImage: false,
  link: "",
  checked: false,
};

CardAction.propTypes = {
  image: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
};

export default compose(withRouter, withStyles(styles))(CardAction);
