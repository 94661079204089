import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
// import SearchUserPoper from "./SearchUserPoper";
import addUsers from "../../../assets/images/add_profile.svg";
import Image from "Components/Common/image.jsx";
import classnames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";

// redux
import { showSnackBar } from "redux/snackbar/action";
import { showEvaluationCollaboratorDialog, hideEvaluationCollaboratorDialog } from "redux/evaluate/action";

const connectedProps = (state) => ({
  criteriaUserRoleInprogress: state.evaludationCriteria.criteriaUserRoleInprogress,
  open: state.evaluate.evaluationCollaboratorDialogOpen,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItem: "center",
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
      },
      "& [class*=MuiAvatarGroup-root]": {
        alignItems: "center",
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    addUser: {
      width: 25,
      height: 25,
      cursor: "pointer",
      marginLeft: 2,
    },
    add_approvar: {
      cursor: "pointer",
      flexDirection: "row",
      boxSizing: " border-box",
      display: "flex",
      placeContent: "center space-around",
      alignItems: "center",
      fontSize: "12px",
      color: "#AFAFAF",
      maxWidth: "135px",
    },
    isApprover: {
      backgroundColor: "#8bc34a",
    },
    addUserLabel: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  });

class AddApprover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverAnchor: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.avatarName = this.avatarName.bind(this);
    this.updatePriority = this.updatePriority.bind(this);
  }

  componentDidMount() {}

  avatarName(name) {
    return name.substring(0, 1);
  }

  handleClose(user) {
    if (user !== null && this.props.onAdd !== undefined && this.props.onAdd !== null) {
      this.props.onAdd(user);
    } else {
      this.setState({ popoverAnchor: null });
    }
  }

  updatePriority(user) {
    this.props.updatePriority(user);
  }

  checkUserState = (User) => {
    let criterias = this.props.criterias.filter((item) => item.id === parseInt(this.props.criteriaId)) || [];
    let result = [];
    if (this.props.isfromSettingsTemplate) {
      result = criterias.length > 0 ? criterias[0].Collaborators.filter((item) => item.ID === User.ID) : [];
    } else {
      result = criterias.length > 0 ? criterias[0].colloborator.filter((item) => item.ID === User.ID) : [];
    }

    if (result.length) return result[0];
    return false;
  };

  render() {
    const classes = this.props.classes;
    var editable = true;
    if (this.props.editable !== undefined) {
      editable = this.props.editable;
    }
    return (
      <div className={classes.root}>
        {this.props.users !== undefined && this.props.users !== null && (
          <AvatarGroup max={window.innerWidth >= 1280 ? 3 : window.innerWidth < 1280 ? 2 : 1}>
            {this.props.users.map((user, k) => {
              let tooltipMsg = "";
              if (this.props.position === "CriteriaHeadSetup" || this.props.position === "TemplateCriteriaHead") {
                let value = user?.TemplateCriteriaPriority !== undefined && user?.TemplateCriteriaPriority !== null && user?.TemplateCriteriaPriority !== "" && user?.TemplateCriteriaPriority > 0 ? user?.TemplateCriteriaPriority : 50;
                tooltipMsg = user.Name + "\n" + user.Email + "\n Weightage: " + value + "%";
              } else {
                tooltipMsg = user.Name + "\n" + user.Email;
              }
              return (
                <Tooltip placement="top" title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{tooltipMsg}</span>} arrow>
                  <Avatar style={{zIndex:0}} key={k}> {this.avatarName(user.Name)}</Avatar>
                </Tooltip>
              );
            })}
            {/* // this.props.users.map((user,k)=>{
                            //     return <Avatar className={classnames(user.IsApprover ? classes.isApprover : '')} alt={user.Name} key={k}>{this.avatarName(user.Name)}</Avatar>
                            // }) */}
          </AvatarGroup>
        )}
        {editable && (
          <div
            className={classnames(classes.add_approvar, "evaluate-add-approver", this.props?.users && this.props.users.length > 0 ? classes.ml : {})}
            onClick={(event) => {
              event.stopPropagation();
              this.props.showEvaluationCollaboratorDialog({
                showToggle: true,
                onSubmit: this.props.onSubmit,
                isfromSettingsTemplate: this.props.isfromSettingsTemplate !== undefined ? this.props.isfromSettingsTemplate : false,
                isNew: this.props.isNew !== undefined ? this.props.isNew : false,
                //criterias: this.props.criterias !== undefined ? this.props.criterias : [],
                searchTitle: "Approver",
                btnLoading: this.props.btnLoading,
                evaluationId: this.props.evaluationId,
                criteriaId: this.props.criteriaId,
                updateNewCollaborator: this.props.updateNewCollaborator,
                selectedUser: this.props.users,
                position: this.props.position || "",
                onClose: this.handleClose,
                updatePriority: this.updatePriority,
                hideCollaborator: this.props.hideCollaborator,
                isApproverPage: this.props.isApproverPage,
                showNotificationIcon: this.props.showNotificationIcon || false,
                notificationData: this.props.notificationData || "",
              });
            }}
          >
            <Image className={classes.addUser} alt="add user" src={addUsers} />
          </div>
        )}
        {/* {
                        this.props.open &&
                        <SearchUserPoper 
                        showToggle={true} 
                        searchTitle="Approver" 
                        isfromSettingsTemplate={this.props.isfromSettingsTemplate}
                        evaluationId={this.props.evaluationId}
                        criteriaId={this.props.criteriaId}
                        criterias={this.props.criterias}
                        isNew={this.props.isNew}
                        updateNewCollaborator={this.props.updateNewCollaborator}
                        selectedUser={this.props.users} 
                        onClose={this.handleClose}
                        // anchorEl={this.state.popoverAnchor} 
                         />
                    } */}
      </div>
    );
  }
}

AddApprover.defaultProps = {
  hideCollaborator: false,
  isApproverPage: false,
};

export default connector(compose(withStyles(styles))(AddApprover));
