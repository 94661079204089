import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment"
import {EvaluationFactory} from "../../../services/evaluation";
import { END_EVALUATION_METADATA_UPDATE } from "../metadata/action";
import { UPDATE_CART_DRAWER_STYLE } from 'redux/drawer/action';

export const START_EVALUATION_CART_FETCH     =   "START_EVALUATION_CART_FETCH";
export const END_EVALUATION_CART_FETCH       =   "END_EVALUATION_CART_FETCH"

export const START_EVALUATION_CART_CREATE     =   "START_EVALUATION_CART_CREATE";
export const END_EVALUATION_CART_CREATE       =   "END_EVALUATION_CART_CREATE"

export const START_EVALUATION_CART_RM_PRODUCT     =   "START_EVALUATION_CART_RM_PRODUCT";
export const END_EVALUATION_CART_RM_PRODUCT       =   "END_EVALUATION_CART_RM_PRODUCT"

export const START_EVALUATION_PRODUCT_UPDATE     =   "START_EVALUATION_PRODUCT_UPDATE";
export const END_EVALUATION_PRODUCT_UPDATE       =   "END_EVALUATION_PRODUCT_UPDATE"

export const SHOW_EVALUATION_PRODUCT_UPDATE_DIALOG     =   "SHOW_EVALUATION_PRODUCT_UPDATE_DIALOG";
export const HIDE_EVALUATION_PRODUCT_UPDATE_DIALOG     =   "HIDE_EVALUATION_PRODUCT_UPDATE_DIALOG"

function startEvaluationCartFetch(){
    return {
        type:START_EVALUATION_CART_FETCH,
        payload:{}
    };
}

function endEvaluationCartFetch(success,error){
    return {
        type:END_EVALUATION_CART_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchEvaluationCart(evId){
    return async (dispatch) => {
        dispatch(startEvaluationCartFetch());
        try{
            if(evId === undefined){
                let [response,error]    =   await EvaluationFactory.instance().getDraftEvaluation();
                dispatch(endEvaluationCartFetch(response,error));
            } else {
                let [response,error]    =   await EvaluationFactory.instance().getEvaluation(evId);
                dispatch(endEvaluationCartFetch(response,error));
            }            
        } catch(e){
            dispatch(endEvaluationCartFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startEvaluationCartCreate(){
    return {
        type:START_EVALUATION_CART_CREATE,
        payload:{}
    };
}

function endEvaluationCartCreate(success,error){
    return {
        type:END_EVALUATION_CART_CREATE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function createEvaluationCart(productId,evId=null,type='PRODUCT'){
    return async (dispatch) => {
        dispatch({
            type:UPDATE_CART_DRAWER_STYLE,
            payload:{
                expanded: true, 
                show:true
            }
        });
        dispatch(startEvaluationCartCreate());
        try{
            if(evId === null){
                let [response,error]    =   await EvaluationFactory.instance().updateDraftEvaluation({
                    entity_id : String(productId),
                    type:type
                });
                dispatch(endEvaluationCartCreate(response,error));
            } else {
                await EvaluationFactory.instance().addProductToEvaluation(evId,{
                    entity_id : String(productId),
                    type:type
                })
                let [response,error]    =   await EvaluationFactory.instance().getEvaluation(evId);
                dispatch(endEvaluationCartCreate(response,error));
            }
        } catch(e){
            dispatch(endEvaluationCartCreate(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startEvaluationCartRmProduct(){
    return {
        type:START_EVALUATION_CART_RM_PRODUCT,
        payload:{}
    };
}

function endEvaluationCartRmProduct(success,error){
    return {
        type:END_EVALUATION_CART_RM_PRODUCT,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function removeProductFromCart(evId,productId){
    return async (dispatch) => {
        dispatch({
            type:UPDATE_CART_DRAWER_STYLE,
            payload:{
                expanded: true, show:true
            }
        });
        dispatch(startEvaluationCartRmProduct());
        try{
            let [response,error]    =   await EvaluationFactory.instance().removeProductFromEvaluation(evId,productId);
            if(error == null){
                let [fresponse,ferror]    =   await EvaluationFactory.instance().getEvaluation(evId);
                dispatch(endEvaluationCartRmProduct(fresponse,ferror));
                dispatch({
                    type:END_EVALUATION_METADATA_UPDATE,
                    payload:{
                        success:fresponse,error:ferror
                    }
                });
            }else {
                dispatch(endEvaluationCartRmProduct(response,error));
            }
        } catch(e){
            dispatch(endEvaluationCartRmProduct(null,{
                message:e.message
            }));
            return;
        }
    };
} 

export  function showEvaluationProductEditDialog(evaluationId,product,targetContainer = null,forSeller=null){
    return     {
        type:SHOW_EVALUATION_PRODUCT_UPDATE_DIALOG,
        payload:{evaluationId,product,targetContainer,forSeller}
    };
}


export  function hideEvaluationProductEditDialog(){
    return     {
        type:HIDE_EVALUATION_PRODUCT_UPDATE_DIALOG,
        payload:{}
    };
}



function startEvaluationCartProductUpdate(){
    return {
        type:START_EVALUATION_PRODUCT_UPDATE,
        payload:{}
    };
}

function endEvaluationCartProductUpdate(success,error){
    return {
        type:END_EVALUATION_PRODUCT_UPDATE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateProductFromCart(evId,productId,data){
    return async (dispatch) => {
        dispatch(startEvaluationCartProductUpdate());
        try{
            const api_server = Environment.api_host("EVALUATION");
            const timestamp     =   (new Date()).getTime();
            var url =   `${api_server}/${evId}/products/${productId}?t=${timestamp}`
            let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
            if(error == null){
                let [fresponse,ferror]    =   await EvaluationFactory.instance().getEvaluation(evId);
                dispatch(endEvaluationCartProductUpdate(fresponse,ferror));
                dispatch({
                    type:END_EVALUATION_METADATA_UPDATE,
                    payload:{
                        success:fresponse,error:ferror
                    }
                });
            }else {
                dispatch(endEvaluationCartProductUpdate(response,error));
            }
        } catch(e){
            dispatch(endEvaluationCartRmProduct(null,{
                message:e.message
            }));
            return;
        }
    };
} 