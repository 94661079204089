import {
    SHOW_ADD_UPDATE_TASK_DIALOG, HIDE_ADD_UPDATE_TASK_DIALOG,
    START_ADD_NEW_PRODUCT_TASK, END_ADD_NEW_PRODUCT_TASK,
    START_UPDATE_PRODUCT_TASK, END_UPDATE_PRODUCT_TASK,
    START_FETCH_PRODUCT_TASKS, END_FETCH_PRODUCT_TASKS,
    START_DELETE_PRODUCT_TASK, END_DELETE_PRODUCT_TASK,
    START_FETCH_PRODUCT_TASKS_ONLY, END_FETCH_PRODUCT_TASKS_ONLY,
    START_FETCH_PRODUCT_RISK_TASKS, END_FETCH_PRODUCT_RISK_TASKS,
    START_FETCH_PRODUCT_FOLLOW_UP_TASKS, END_FETCH_PRODUCT_FOLLOW_UP_TASKS
} from "./action";

const initState = {
    addUpdateTaskData: null,
    showAddTaskDialog: false,
    isNewTask: true,

    addNewTaskProgress: false,
    addNewTaskError: null,
    newTaskData: null,

    updateNewTaskProgress: false,
    updateNewTaskError: null,

    fetchProductTasksProgress: false,
    fetchProductTasksError: null,
    productTasks: [],

    deleteProductTaskProgress: false,
    deleteProductTaskError: null,

    fetchProductOnlyTasksProgress: false,
    fetchProductOnlyTasksError: null,

    fetchProductRiskTasksProgress: false,
    fetchProductRiskTasksError: null,

    fetchProductFollowUpTasksProgress: false,
    fetchProductFollowupTasksError: null,

    productOnlyTasks: {},
    productRiskTasks: {},
    productFollowupTasks: {},

    updatedTaskData:null
}

export function evaluationTaskReducer(state = initState, action) {
    switch (action.type) {

        case SHOW_ADD_UPDATE_TASK_DIALOG:
            return {
                ...state,
                addUpdateTaskData: action.payload.data,
                showAddTaskDialog: true,
                isNewTask: action.payload.isNew
            }

        case HIDE_ADD_UPDATE_TASK_DIALOG:
            return {
                ...state,
                addUpdateTaskData: null,
                showAddTaskDialog: false,
            }

        case START_ADD_NEW_PRODUCT_TASK:
            return {
                ...state,
                addNewTaskProgress: true,
                addNewTaskError: null,
                newTaskData: null
            }

        case END_ADD_NEW_PRODUCT_TASK:
            return {
                ...state,
                addNewTaskProgress: false,
                addNewTaskError: action.payload.error,
                newTaskData: action.payload.success
            }

        case START_UPDATE_PRODUCT_TASK:
            return {
                ...state,
                updateNewTaskProgress: true,
                updateNewTaskError: null,
                updatedTaskData:null,
            }

        case END_UPDATE_PRODUCT_TASK:
            var successData = action.payload.success;
            var errorData = action.payload.error;
            var fromUpdate = action.payload.from;
            let productOnlyTasks = state.productOnlyTasks;
            let productRiskTasks = state.productRiskTasks;
            let productFollowupTasks = state.productFollowupTasks;
            if(errorData === null && fromUpdate === 'ORG_PRODUCT_PAGE'){
                var taskType = successData?.Type;
                if(taskType === 1){
                    var taskDatas = productOnlyTasks.Data;
                    var taskIndex = taskDatas.findIndex(o=>o.ID === successData.ID);
                    productOnlyTasks.Data[taskIndex] = successData;
                }else if(taskType === 2){
                    var followTaskDatas = productFollowupTasks.Data;
                    var taskIndexF = followTaskDatas.findIndex(o=>o.ID === successData.ID);
                    productFollowupTasks.Data[taskIndexF] = successData;
                }else if(taskType === 3){
                    var riskTaskDatas = productRiskTasks.Data;
                    var rtaskIndex = riskTaskDatas.findIndex(o=>o.ID === successData.ID);
                    productRiskTasks.Data[rtaskIndex] = successData;
                }
            }

            return {
                ...state,
                updateNewTaskProgress: false,
                updateNewTaskError: action.payload.error,
                updatedTaskData: action.payload.success,
                productOnlyTasks:productOnlyTasks,
                productRiskTasks:productRiskTasks,
                productFollowupTasks:productFollowupTasks
            }

        case START_FETCH_PRODUCT_TASKS:
            return {
                ...state,
                fetchProductTasksProgress: true,
                fetchProductTasksError: null,
            }

        case END_FETCH_PRODUCT_TASKS:
            let productTasksDatas = state.productTasks;
            let successFetchData = action.payload.success;
            if(action.payload.error === null){
                if (successFetchData.page === 0) {
                    productTasksDatas = successFetchData
                } else {
                    productTasksDatas.Data = [...productTasksDatas.Data, ...successFetchData.Data]
                }
            }
           
            return {
                ...state,
                fetchProductTasksProgress: false,
                fetchProductTasksError: action.payload.error,
                productTasks: productTasksDatas
            }

        case START_DELETE_PRODUCT_TASK:
            return {
                ...state,
                deleteProductTaskProgress: true,
                deleteProductTaskError: null,
            }

        case END_DELETE_PRODUCT_TASK:
            const { type, from, taskId } = action.payload;

            var delErrorData = action.payload.error;

            let onlyTasks = state.productOnlyTasks;
            let riskTasks = state.productRiskTasks;
            let folloUpTasks = state.productFollowupTasks;
            let productTasks =state.productTasks;
            
            if(delErrorData === null){
                if(from === 'EVALUATION'){
                    var pDatas = productTasks.Data;
                    var ptaskIndex = pDatas.findIndex(o=>o.ID === taskId);
                    productTasks.Data.splice(ptaskIndex,1);
                }else{
                    if(type === 1){
                        var oDatas = onlyTasks.Data;
                        var otaskIndex = oDatas.findIndex(o=>o.ID === taskId);
                        onlyTasks.Data.splice(otaskIndex,1);
                    }else if(type === 2){
                        var fDatas = folloUpTasks.Data;
                        var ftaskIndex = fDatas.findIndex(o=>o.ID === taskId);
                        folloUpTasks.Data.splice(ftaskIndex,1);
                    }else if(type === 3){
                        var rDatas = riskTasks.Data;
                        var ritaskIndex = rDatas.findIndex(o=>o.ID === taskId);
                        riskTasks.Data.splice(ritaskIndex,1);
                    }
                }
            }
          
            return {
                ...state,
                deleteProductTaskProgress: false,
                deleteProductTaskError: action.payload.error,
                productOnlyTasks:onlyTasks,
                productRiskTasks:riskTasks,
                productFollowupTasks:folloUpTasks,
                productTasks:productTasks,
            }

        case START_FETCH_PRODUCT_TASKS_ONLY:
            return {
                ...state,
                fetchProductOnlyTasksProgress: true,
                fetchProductOnlyTasksError: null,
            }
        case END_FETCH_PRODUCT_TASKS_ONLY:
            let onlyTaskDatas = state.productOnlyTasks;
            if(action.payload.error === null){
                let successDatasO = action.payload.success;
                console.log(successDatasO,'successDatasO')
                if (successDatasO.page === 0) {
                    onlyTaskDatas = successDatasO
                } else {
                    onlyTaskDatas.Data = [...onlyTaskDatas.Data, ...successDatasO.Data];
                    onlyTaskDatas.total = successDatasO.total
                }
            }

            return {
                ...state,
                fetchProductOnlyTasksProgress: false,
                fetchProductOnlyTasksError: action.payload.error,
                productOnlyTasks: onlyTaskDatas
            }

        case START_FETCH_PRODUCT_RISK_TASKS:
            return {
                ...state,
                fetchProductRiskTasksProgress: true,
                fetchProductRiskTasksError: null,
            }
        case END_FETCH_PRODUCT_RISK_TASKS:
            let riskTaskDatasN = state.productRiskTasks;
            let successDatasR = action.payload.success;
            if(action.payload.error === null){
                if (successDatasR.page === 0) {
                    riskTaskDatasN = successDatasR
                } else {
                    riskTaskDatasN.Data = [...riskTaskDatasN.Data, ...successDatasR.Data]
                    riskTaskDatasN.total = successDatasR.total
                }
            }
            return {
                ...state,
                fetchProductRiskTasksProgress: false,
                fetchProductRiskTasksError: action.payload.error,
                productRiskTasks: riskTaskDatasN
            }

        case START_FETCH_PRODUCT_FOLLOW_UP_TASKS:
            return {
                ...state,
                fetchProductFollowUpTasksProgress: true,
                fetchProductFollowupTasksError: null,
            }
        case END_FETCH_PRODUCT_FOLLOW_UP_TASKS:
            let followUpTaskDatas = state.productOnlyTasks;
            let successDatasFo = action.payload.success;

            if(action.payload.error === null){
                if (successDatasFo.page === 0) {
                    followUpTaskDatas = successDatasFo
                } else {
                    followUpTaskDatas.Data = [...followUpTaskDatas.Data, ...successDatasFo.Data];
                    followUpTaskDatas.total = successDatasFo.total
                }
            }

            return {
                ...state,
                fetchProductFollowUpTasksProgress: false,
                fetchProductFollowupTasksError: action.payload.error,
                productFollowupTasks: followUpTaskDatas
            }


        default:
            return state;
    }
}