import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CommonCss from 'commonCss';

import EvaluationBar from "../../../Common/EvaluationBar";
import EvaluationEmptyState from "assets/images/evaluation_empty_state.svg";
import Image from 'Components/Common/image'
import { Button, CircularProgress } from '@material-ui/core';

import { getComponentPermission } from 'redux/workflow/action'

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  getComponentPermissionProgress: state.workflow.getComponentPermissionProgress,
  getComponentPermissionError: state.workflow.getComponentPermissionError,
  componentPermission: state.workflow.componentPermission,
  user: state.authUser.user,
});

const connectionActions = {
  getComponentPermission: getComponentPermission
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: {
    ...CommonCss.rootContainer,
    textAlign: 'center',
    marginTop: '5%'
  },
  loader: {
    textAlign: "center",
    padding: theme.spacing(10, 0)
  },
});

class ApprovalCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTakeMeToApprovalDirectly: true
    }
  }

  componentDidMount() {
    this.checkApprovalPermission()
  }

  componentDidUpdate(prevProps) {
    if (!this.props.getComponentPermissionProgress && prevProps.getComponentPermissionProgress) {
      if (this.props.getComponentPermissionError === null) {
        // console.log(this.props.componentPermission, 'componentPermission')
        if (this.props.componentPermission && this.props.componentPermission.permission) {
          this.takeToApproval()
        } else {
          this.setState({
            isTakeMeToApprovalDirectly: false
          })
        }
      }
    }
  }

  checkApprovalPermission() {
    const { evaluation } = this.props;
    if (evaluation && evaluation.ApprovalComponent !== undefined) {
      let approvalData = evaluation.ApprovalComponent;
      // console.log(approvalData, 'ApprovalDataaa')
      // console.log(this.props.user, 'user')
      this.props.getComponentPermission(approvalData?.WorkflowRunID, approvalData?.ComponentID, this.props.user?.ID)
      // this.props.history.push("/app/evaluate/" + approvalData?.WorkflowRunID + "/workflow/" + approvalData?.WorkflowID + "/component/" + approvalData?.ComponentID);
    }
  }

  takeToApproval() {
    const { evaluation } = this.props;
    if (evaluation && evaluation.ApprovalComponent !== undefined) {
      let approvalData = evaluation.ApprovalComponent;
      this.props.history.push("/app/evaluate/" + approvalData?.WorkflowRunID + "/workflow/" + approvalData?.WorkflowID + "/component/" + approvalData?.ComponentID);
    }
  }

  render() {
    const classes = this.props.classes;
    // const { evaluation } = this.props;
    const { isTakeMeToApprovalDirectly } = this.state;
    if (isTakeMeToApprovalDirectly) {
      return <div className={classes.loader}> <CircularProgress /> </div>
    }

    let isShowButton = false;
    // if (evaluation && evaluation.ApprovalComponent !== undefined && evaluation.ApprovalComponent.ComponentID !== 0) {
    //   isShowButton = true;
    // }

    return (
      <div>
        <EvaluationBar
          isShowCollabrations={false}
          isShowEvalDetails={false}
          isWorkFlow={this.props.isWorkFlow}
          isShowViewBtns={false}
        />
        <div className={classes.rootContainer}>
          <div style={{ height: 220 }}>
            <Image src={EvaluationEmptyState} />
          </div>
          <h2>Approval in progress</h2>

          <Button variant="outlined" color="secondary" className={classes.button}
            onClick={() => { this.props.history.push('/app/home') }}
          >Home</Button>

          {isShowButton && <Button variant="contained" color="secondary" className={classes.button} style={{ marginLeft: 10 }}
            onClick={() => { this.takeToApproval() }}
          >Take me to Approvals</Button>}
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ApprovalCheck));
