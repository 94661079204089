import {
  START_ADD_ORG_WORKFLOW, END_ADD_ORG_WORKFLOW,
  START_UPDATE_ORG_WORKFLOW, END_UPDATE_ORG_WORKFLOW,
  START_GET_ORG_WORKFLOW, END_GET_ORG_WORKFLOW,
  START_GET_BY_ID_ORG_WORKFLOW, END_GET_BY_ID_ORG_WORKFLOW,
  START_DELETE_ORG_WORKFLOW, END_DELETE_ORG_WORKFLOW,
  START_ADD_ORG_WORKFLOW_COMPONENT, END_ADD_ORG_WORKFLOW_COMPONENT,
  START_UPDATE_ORG_WORKFLOW_COMPONENT, END_UPDATE_ORG_WORKFLOW_COMPONENT,
  START_GET_ORG_WORKFLOW_COMPONENT, END_GET_ORG_WORKFLOW_COMPONENT,
  START_DELETE_ORG_WORKFLOW_COMPONENT, END_DELETE_ORG_WORKFLOW_COMPONENT,
  START_ADD_ORG_WORKFLOW_ACTION,
  END_ADD_ORG_WORKFLOW_ACTION,
  START_UPDATE_ORG_WORKFLOW_ACTION,
  END_UPDATE_ORG_WORKFLOW_ACTION,
  START_FETCH_ORG_WORKFLOW_ACTION,
  END_FETCH_ORG_WORKFLOW_ACTION,
  START_DELETE_ORG_WORKFLOW_ACTION,
  END_DELETE_ORG_WORKFLOW_ACTION,
  SHOW_MASTER_COMPONENT_DIALOG, HIDE_MASTER_COMPONENT_DIALOG,
  SHOW_REQUEST_DETAILS_CART, HIDE_REQUEST_DETAILS_CART,
  START_GET_ORG_WORKFLOW_COMPONENT_DETAILS, END_GET_ORG_WORKFLOW_COMPONENT_DETAILS,
  START_REQUEST_DETAILS, END_REQUEST_DETAILS,
  START_FETCH_WORKFLOW_CUSTOM_FORM_BY_ID, END_FETCH_WORKFLOW_CUSTOM_FORM_BY_ID,
  START_FETCH_WORKFLOW_CUSTOM_FORM, END_FETCH_WORKFLOW_CUSTOM_FORM,
  START_DELETE_WORKFLOW_CUSTOM_FORM_DETAILS, END_DELETE_WORKFLOW_CUSTOM_FORM_DETAILS,
  START_UPDATE_WORKFLOW_CUSTOM_FORM_DETAILS, END_UPDATE_WORKFLOW_CUSTOM_FORM_DETAILS,
  START_ADD_WORKFLOW_CUSTOM_FORM_DETAILS, END_ADD_WORKFLOW_CUSTOM_FORM_DETAILS,
  START_ADD_WORKFLOW_CUSTOM_FORM_SECTION, END_ADD_WORKFLOW_CUSTOM_FORM_SECTION,
  START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION, END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION,
  START_FETCH_WORKFLOW_CUSTOM_FORM_SECTIONS, END_FETCH_WORKFLOW_CUSTOM_FORM_SECTIONS,
  START_DELETE_WORKFLOW_CUSTOM_FORM_SECTION, END_DELETE_WORKFLOW_CUSTOM_FORM_SECTION,
  START_ADD_WORKFLOW_CUSTOM_FORM_SECTION_ITEM, END_ADD_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
  START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM, END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
  START_DELETE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM, END_DELETE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
  START_FETCH_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS, END_FETCH_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS,
  START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_SEQ, END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_SEQ,
  SHOW_WRAPPER_COMPONENT_DIALOG, HIDE_WRAPPER_COMPONENT_DIALOG,
  SHOW_WRAPPER_MASTER_COMPONENT_DIALOG, HIDE_WRAPPER_MASTER_COMPONENT_DIALOG,
  SHOW_USER_WISE_WORKFLOW_PROGRESS_DIALOG, HIDE_USER_WISE_WORKFLOW_PROGRESS_DIALOG,
  SHOW_ALL_USERS_WORKFLOW_PROGRESS_DIALOG, HIDE_ALL_USERS_WORKFLOW_PROGRESS_DIALOG,
  SHOW_WRAPPER_WORKFLOW_PROGRESS_DIALOG, HIDE_WRAPPER_WORKFLOW_PROGRESS_DIALOG,
  SHOW_WRAPPER_WORKFLOW_DETAILS_DIALOG, HIDE_WRAPPER_WORKFLOW_DETAILS_DIALOG,
  START_FETCH_WRAPPER_COMPONENT_DATAS, END_FETCH_WRAPPER_COMPONENT_DATAS,
  START_FETCH_WORKFLOW_USER_ACTIVITY, END_FETCH_WORKFLOW_USER_ACTIVITY,
  START_FETCH_EVALUATION_WORKFLOW_ID, END_FETCH_EVALUATION_WORKFLOW_ID,
  START_FETCH_WORKFLOW_CONDITION_RULES, END_FETCH_WORKFLOW_CONDITION_RULES,
  SHOW_WORKFLOW_DETAILS_UPDATE_DIALOG, HIDE_WORKFLOW_DETAILS_UPDATE_DIALOG,
  START_GET_MASTER_WORKFLOW, END_GET_MASTER_WORKFLOW,
  START_FETCH_CHILD_WORKFLOW_USER_ACTIVITY, END_FETCH_CHILD_WORKFLOW_USER_ACTIVITY,
  START_ADD_WORKFLOW_COMPONENT_TIMELINE, END_ADD_WORKFLOW_COMPONENT_TIMELINE,
  START_UPDATE_WORKFLOW_COMPONENT_TIMELINE, END_UPDATE_WORKFLOW_COMPONENT_TIMELINE,
  START_GET_WORKFLOW_COMPONENT_TIMELINE, END_GET_WORKFLOW_COMPONENT_TIMELINE,
  SHOW_WORKFLOW_COMPONENT_TIMELINE, HIDE_WORKFLOW_COMPONENT_TIMELINE,
  START_SET_COMPONENTS_FLOW_POSITION, END_SET_COMPONENTS_FLOW_POSITION,
  SET_WORKFLOW_ACTIVE_TAB,
  SHOW_CRITERIA_APPROVERS_SEARCH_DIALOG, HIDE_CRITERIA_APPROVERS_SEARCH_DIALOG,
  SHOW_REQUEST_SUMMARY_CART, HIDE_REQUEST_SUMMARY_CART,
  START_ADD_COPY_OF_ORG_WORKFLOW, END_ADD_COPY_OF_ORG_WORKFLOW
} from "./action";

const initState = {
  addOrgWorkFlowProgress: false,
  addOrgWorkFlowSuccess: null,
  addOrgWorkFlowError: null,
  updateOrgWorkFlowProgress: false,
  updateOrgWorkFlowSuccess: null,
  updateOrgWorkFlowError: null,
  orgWorkFlowProgress: false,
  orgWorkFlowList: null,
  orgWorkFlowError: null,
  masterWorkFlowProgress: false,
  masterWorkFlowList: null,
  masterWorkFlowError: null,
  orgWorkFlowByIdProgress: false,
  orgWorkFlowByIdList: null,
  orgWorkFlowByIdError: null,
  deleteOrgWorkFlowProgress: false,
  deleteOrgWorkFlowSuccess: null,
  deleteOrgWorkFlowError: null,

  addOrgWorkFlowCompProgress: false,
  addOrgWorkFlowCompSuccess: null,
  addOrgWorkFlowCompError: null,
  updateOrgWorkFlowCompProgress: false,
  updateOrgWorkFlowCompSuccess: null,
  updateOrgWorkFlowCompError: null,
  orgWorkFlowCompProgress: false,
  orgWorkFlowCompList: null,
  orgWorkFlowCompError: null,
  deleteOrgWorkFlowCompProgress: false,
  deleteOrgWorkFlowCompSuccess: null,
  deleteOrgWorkFlowCompError: null,

  addOrgWorkFlowActionProgress: false,
  addOrgWorkFlowActionSuccess: null,
  addOrgWorkFlowActionError: null,
  updateOrgWorkFlowActionProgress: false,
  updateOrgWorkFlowActionSuccess: null,
  updateOrgWorkFlowActionError: null,
  orgWorkFlowActionProgress: false,
  orgWorkFlowActionList: null,
  orgWorkFlowActionError: null,
  deleteOrgWorkFlowActionProgress: false,
  deleteOrgWorkFlowActionSuccess: null,
  deleteOrgWorkFlowActionError: null,

  isMasterComponentDialogOpen: false,
  isRequestDetailsCartOpen: false,
  isRequestDetailsCartData: null,

  isRequestSummaryCartOpen: false,
  isRequestSummaryCartData: null,

  requestDetailsProgress: false,
  requestDetails: null,
  requestDetailsError: null,

  orgWorkFlowCompDetailsProgress: false,
  orgWorkFlowCompDetails: null,
  orgWorkFlowCompDetailsError: null,

  addWorkflowCustomFormProgress: false,
  updateWorkflowCustomFormProgress: false,
  deleteWorkflowCustomFormProgress: false,
  startFetchCustomFormDataProgress: false,
  fetchCustomFormProgress: false,

  workflowCustomForm: null,
  workflowCustomFormData: null,

  addCustomFormSectionProgress: false,
  fetchCustomFormSectionsProgress: false,
  updateCustomFormSectionProgress: false,
  deletCustomFormSectionProgress: false,
  addCustomFormSectionItemProgress: false,
  updateCustomFormSectionItemProgress: false,
  deleteCustomFormSectionItemProgress: false,
  fetchCustomFormSectionItemsProgress: false,
  customFormSectionItems: null,
  customFormSections: null,
  newwlyAddedCustomSectionData: null,

  startCustomFormSectionSeqProgress: false,
  isWrapperrComponentDialogOpen: false,
  isWrapperMasterComponentDialogOpen: false,

  isUserWiseWorflowProgressDialogOpen: false,
  isAllUsersWorflowProgressDialogOpen: false,

  isWrapperWorflowProgressDialogOpen: false,

  isWrapperWorflowDetailsDialogOpen: false,
  fetchWrapperComponentDatasProgress: false,
  wrapperComponentDatas: null,

  selectedWrapperComponentId: null,

  fetchEvaluationWorkflowIdProgress: false,
  evaluationWorkflowDetails: {},

  fetchWorkflowConditionRulesProgress: false,
  workflowConditionRules: [],

  isWorkflowDetailsUpdateDialogOpen: false,

  workflowUserActivity: null,

  wrapperWorkflowComponentId: null,

  fetchChildWorkflowUserActivityProgress: false,
  childWorkflowUserActivity: null,

  showWorkflowComponentTimelineDialog: false,

  addWorkflowCompoenentTimelineProgress: false,
  updateWorkflowCompoenentTimelineProgress: false,
  fetchWorkflowCompoenentTimelineProgress: false,

  workflowComponentTimeline: null,
  workflowComponentTimelineError: null,
  setComponentFlowPostionProg: false,

  workflowActiveTab: 2,
  showCriteriaApprovalSearchDialog: false,

  addCopyOfOrgWorkFlowProgress: false,
  addCopyOfOrgWorkFlowSuccess: null,
  addCopyOfOrgWorkFlowError: null
}


export function evaluationWorkflowReducer(state = initState, action) {
  switch (action.type) {
    case START_ADD_ORG_WORKFLOW:
      return {
        ...state,
        addOrgWorkFlowProgress: true,
        addOrgWorkFlowSuccess: null,
        addOrgWorkFlowError: null
      }
    case END_ADD_ORG_WORKFLOW: {
      const { success, error } = action.payload
      return {
        ...state,
        addOrgWorkFlowProgress: false,
        addOrgWorkFlowSuccess: success,
        addOrgWorkFlowError: error
      }
    }
    case START_UPDATE_ORG_WORKFLOW:
      return {
        ...state,
        updateOrgWorkFlowProgress: true,
        updateOrgWorkFlowSuccess: null,
        updateOrgWorkFlowError: null,
      }
    case END_UPDATE_ORG_WORKFLOW:
      const { success, error } = action.payload
      return {
        ...state,
        updateOrgWorkFlowProgress: false,
        updateOrgWorkFlowSuccess: success,
        updateOrgWorkFlowError: error,
      }
    case START_GET_ORG_WORKFLOW:
      return {
        ...state,
        orgWorkFlowProgress: true,
        orgWorkFlowError: null
      }
    case END_GET_ORG_WORKFLOW: {
      const { success, error } = action.payload
      let orgWorkFlowList = state.orgWorkFlowList
      if (error == null) {
        if (success.page === 0) {
          orgWorkFlowList = success
        } else {
          orgWorkFlowList.Data = [...orgWorkFlowList.Data, ...success.Data]
        }
      }
      return {
        ...state,
        orgWorkFlowProgress: false,
        orgWorkFlowList,
        orgWorkFlowError: error,
      }
    }
    case START_GET_MASTER_WORKFLOW:
      return {
        ...state,
        masterWorkFlowProgress: true,
        masterWorkFlowList: null,
        masterWorkFlowError: null
      }
    case END_GET_MASTER_WORKFLOW: {
      const { success, error } = action.payload
      let masterWorkFlowList = state.masterWorkFlowList
      if (error == null) {
        if (success.page === 0) {
          masterWorkFlowList = success
        } else {
          masterWorkFlowList.Data = [...masterWorkFlowList.Data, ...success.Data]
        }
      }
      return {
        ...state,
        masterWorkFlowProgress: false,
        masterWorkFlowList,
        masterWorkFlowError: error
      }
    }
    case START_GET_BY_ID_ORG_WORKFLOW:
      return {
        ...state,
        orgWorkFlowByIdProgress: true,
        orgWorkFlowByIdList: null,
        orgWorkFlowByIdError: null
      }
    case END_GET_BY_ID_ORG_WORKFLOW: {
      const { success, error } = action.payload
      return {
        ...state,
        orgWorkFlowByIdProgress: false,
        orgWorkFlowByIdList: success,
        orgWorkFlowByIdError: error,
        isMasterComponentDialogOpen: false
      }
    }
    case START_DELETE_ORG_WORKFLOW:
      return {
        ...state,
        deleteOrgWorkFlowProgress: true,
        deleteOrgWorkFlowSuccess: null,
        deleteOrgWorkFlowError: null
      }
    case END_DELETE_ORG_WORKFLOW: {
      const { success, error } = action.payload
      return {
        ...state,
        deleteOrgWorkFlowProgress: false,
        deleteOrgWorkFlowSuccess: success,
        deleteOrgWorkFlowError: error
      }
    }
    case START_ADD_ORG_WORKFLOW_COMPONENT:
      return {
        ...state,
        addOrgWorkFlowCompProgress: true,
        addOrgWorkFlowCompSuccess: null,
        addOrgWorkFlowCompError: null
      }
    case END_ADD_ORG_WORKFLOW_COMPONENT: {
      const { success, error } = action.payload
      return {
        ...state,
        addOrgWorkFlowCompProgress: false,
        addOrgWorkFlowCompSuccess: success,
        addOrgWorkFlowCompError: error
      }
    }
    case START_UPDATE_ORG_WORKFLOW_COMPONENT:
      return {
        ...state,
        updateOrgWorkFlowCompProgress: true,
        updateOrgWorkFlowCompSuccess: null,
        updateOrgWorkFlowCompError: null,
      }
    case END_UPDATE_ORG_WORKFLOW_COMPONENT: {
      const { success, error } = action.payload
      return {
        ...state,
        updateOrgWorkFlowCompProgress: false,
        updateOrgWorkFlowCompSuccess: success,
        updateOrgWorkFlowCompError: error,
      }
    }
    case START_GET_ORG_WORKFLOW_COMPONENT:
      return {
        ...state,
        orgWorkFlowCompProgress: true,
        // orgWorkFlowCompList: null,
        orgWorkFlowCompError: null
      }
    case END_GET_ORG_WORKFLOW_COMPONENT: {
      const { success, error } = action.payload
      return {
        ...state,
        orgWorkFlowCompProgress: false,
        orgWorkFlowCompList: success,
        orgWorkFlowCompError: error
      }
    }
    case START_REQUEST_DETAILS:
      return {
        ...state,
        requestDetailsProgress: true,
        requestDetailsError: null
      }
    case END_REQUEST_DETAILS: {
      const { success, error } = action.payload
      return {
        ...state,
        requestDetailsProgress: false,
        requestDetails: success,
        requestDetailsError: error
      }
    }
    case START_GET_ORG_WORKFLOW_COMPONENT_DETAILS:
      return {
        ...state,
        orgWorkFlowCompDetailsProgress: true,
        orgWorkFlowCompDetails: null,
        orgWorkFlowCompDetailsError: null
      }
    case END_GET_ORG_WORKFLOW_COMPONENT_DETAILS: {
      const { success, error } = action.payload
      return {
        ...state,
        orgWorkFlowCompDetailsProgress: false,
        orgWorkFlowCompDetails: success,
        orgWorkFlowCompDetailsError: error
      }
    }
    case START_DELETE_ORG_WORKFLOW_COMPONENT:
      return {
        ...state,
        deleteOrgWorkFlowCompProgress: true,
        deleteOrgWorkFlowCompSuccess: null,
        deleteOrgWorkFlowCompError: null
      }
    case END_DELETE_ORG_WORKFLOW_COMPONENT: {
      const { success, error } = action.payload
      return {
        ...state,
        deleteOrgWorkFlowCompProgress: false,
        deleteOrgWorkFlowCompSuccess: success,
        deleteOrgWorkFlowCompError: error
      }
    }

    case START_ADD_ORG_WORKFLOW_ACTION: {
      return {
        ...state,
        addOrgWorkFlowActionProgress: true,
        addOrgWorkFlowActionSuccess: null,
        addOrgWorkFlowActionError: null,
      }
    }

    case END_ADD_ORG_WORKFLOW_ACTION: {
      const { success, error } = action.payload
      return {
        ...state,
        addOrgWorkFlowActionProgress: false,
        addOrgWorkFlowActionSuccess: success,
        addOrgWorkFlowActionError: error
      }
    }

    case START_UPDATE_ORG_WORKFLOW_ACTION: {
      return {
        ...state,
        updateOrgWorkFlowActionProgress: true,
        updateOrgWorkFlowActionSuccess: null,
        updateOrgWorkFlowActionError: null,
      }
    }

    case END_UPDATE_ORG_WORKFLOW_ACTION: {
      const { success, error } = action.payload
      return {
        ...state,
        updateOrgWorkFlowActionProgress: false,
        updateOrgWorkFlowActionSuccess: success,
        updateOrgWorkFlowActionError: error
      }
    }

    case START_FETCH_ORG_WORKFLOW_ACTION: {
      return {
        ...state,
        orgWorkFlowActionProgress: true,
        orgWorkFlowActionList: null,
        orgWorkFlowActionError: null
      }
    }

    case END_FETCH_ORG_WORKFLOW_ACTION: {
      const { success, error } = action.payload
      return {
        ...state,
        orgWorkFlowActionProgress: false,
        orgWorkFlowActionList: success,
        orgWorkFlowActionError: error
      }
    }

    case START_DELETE_ORG_WORKFLOW_ACTION: {
      return {
        ...state,
        deleteOrgWorkFlowActionProgress: true,
        deleteOrgWorkFlowActionSuccess: null,
        deleteOrgWorkFlowActionError: null
      }
    }

    case END_DELETE_ORG_WORKFLOW_ACTION: {
      const { success, error } = action.payload
      return {
        ...state,
        deleteOrgWorkFlowActionProgress: false,
        deleteOrgWorkFlowActionSuccess: success,
        deleteOrgWorkFlowActionError: error
      }
    }

    case SHOW_MASTER_COMPONENT_DIALOG:
      return {
        ...state,
        isMasterComponentDialogOpen: true
      }

    case HIDE_MASTER_COMPONENT_DIALOG:
      return {
        ...state,
        isMasterComponentDialogOpen: false
      }
    case SHOW_REQUEST_DETAILS_CART:
      return {
        ...state,
        isRequestDetailsCartOpen: true,
        isRequestDetailsCartData: action.payload.data
      }

    case HIDE_REQUEST_DETAILS_CART:
      return {
        ...state,
        isRequestDetailsCartOpen: false,
        isRequestDetailsCartData: null
      }

    case SHOW_REQUEST_SUMMARY_CART:
      return {
        ...state,
        isRequestSummaryCartOpen: true,
        isRequestSummaryCartData: action.payload.data
      }

    case HIDE_REQUEST_SUMMARY_CART:
      return {
        ...state,
        isRequestSummaryCartOpen: false,
        isRequestSummaryCartData: null
      }

    case START_ADD_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        addWorkflowCustomFormProgress: true
      }

    case END_ADD_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        addWorkflowCustomFormProgress: false,
        workflowCustomForm: action.payload.success
      }

    case START_UPDATE_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        updateWorkflowCustomFormProgress: true
      }

    case END_UPDATE_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        updateWorkflowCustomFormProgress: false
      }

    case START_DELETE_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        deleteWorkflowCustomFormProgress: true
      }

    case END_DELETE_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        deleteWorkflowCustomFormProgress: false
      }

    case START_FETCH_WORKFLOW_CUSTOM_FORM:
      return {
        ...state,
        fetchCustomFormProgress: true,
        workflowCustomForm: null
      }

    case END_FETCH_WORKFLOW_CUSTOM_FORM:
      console.log(action.payload, '000000000000000000000000000000000000000000000000000000000000000')
      return {
        ...state,
        fetchCustomFormProgress: false,
        workflowCustomForm: action.payload.success
      }

    case START_FETCH_WORKFLOW_CUSTOM_FORM_BY_ID:
      return {
        ...state,
        startFetchCustomFormDataProgress: true,
        workflowCustomFormData: null,
      }

    case END_FETCH_WORKFLOW_CUSTOM_FORM_BY_ID:
      return {
        ...state,
        startFetchCustomFormDataProgress: false,
        workflowCustomFormData: action.payload.success,
      }

    case START_ADD_WORKFLOW_CUSTOM_FORM_SECTION:
      return {
        ...state,
        addCustomFormSectionProgress: true,
        newwlyAddedCustomSectionData: null,
      }

    case END_ADD_WORKFLOW_CUSTOM_FORM_SECTION:
      let customFormSectionsDatas = [];
      if (state.customFormSections !== null) {
        customFormSectionsDatas = [...state.customFormSections]
      }
      if (action.payload.success !== null) {
        customFormSectionsDatas.push(action.payload.success)
      }
      return {
        ...state,
        addCustomFormSectionProgress: false,
        customFormSections: customFormSectionsDatas,
        newwlyAddedCustomSectionData: action.payload.success,
      }

    case START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION:
      return {
        ...state,
        updateCustomFormSectionProgress: true,
      }

    case END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION:
      return {
        ...state,
        updateCustomFormSectionProgress: false,
      }

    case START_FETCH_WORKFLOW_CUSTOM_FORM_SECTIONS:
      return {
        ...state,
        fetchCustomFormSectionsProgress: true,
        customFormSections: null
      }

    case END_FETCH_WORKFLOW_CUSTOM_FORM_SECTIONS:
      return {
        ...state,
        fetchCustomFormSectionsProgress: false,
        customFormSections: action.payload.success
      }

    case START_DELETE_WORKFLOW_CUSTOM_FORM_SECTION:
      return {
        ...state,
        deletCustomFormSectionProgress: true,
      }

    case END_DELETE_WORKFLOW_CUSTOM_FORM_SECTION:
      return {
        ...state,
        deletCustomFormSectionProgress: false,
      }

    case START_ADD_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        addCustomFormSectionItemProgress: true,
      }

    case END_ADD_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        addCustomFormSectionItemProgress: false
      }

    case START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        updateCustomFormSectionItemProgress: true
      }

    case END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        updateCustomFormSectionItemProgress: false
      }

    case START_DELETE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        deleteCustomFormSectionItemProgress: true,
      }

    case END_DELETE_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        deleteCustomFormSectionItemProgress: false
      }

    case START_FETCH_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS:
      return {
        ...state,
        fetchCustomFormSectionItemsProgress: true,
        customFormSectionItems: null
      }

    case END_FETCH_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS:
      return {
        ...state,
        fetchCustomFormSectionItemsProgress: false,
        customFormSectionItems: action.payload.success
      }

    case START_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_SEQ:
      // console.log(action.payload.data,'Oooooooooooooooooooooooooooooooooooooooo Data Change');
      // const {data} = action.payload;
      // let sectionItems = [];
      // if(state.customFormSections !== null){
      //   sectionItems = [...state.customFormSections];
      //   sectionItems.forEach(function(item){
      //     if(item.ID === data.sectionId){
      //       item.Seq = data.data.seq;
      //     }
      //   });
      // }
      return {
        ...state,
        startCustomFormSectionSeqProgress: true,
        // customFormSections: sectionItems
      }

    case END_UPDATE_WORKFLOW_CUSTOM_FORM_SECTION_SEQ:
      return {
        ...state,
        startCustomFormSectionSeqProgress: false,
      }

    case SHOW_WRAPPER_COMPONENT_DIALOG:
      return {
        ...state,
        isWrapperrComponentDialogOpen: true
      }

    case HIDE_WRAPPER_COMPONENT_DIALOG:
      return {
        ...state,
        isWrapperrComponentDialogOpen: false
      }

    case SHOW_WRAPPER_MASTER_COMPONENT_DIALOG:
      return {
        ...state,
        isWrapperMasterComponentDialogOpen: true
      }

    case HIDE_WRAPPER_MASTER_COMPONENT_DIALOG:
      return {
        ...state,
        isWrapperMasterComponentDialogOpen: false
      }

    case SHOW_USER_WISE_WORKFLOW_PROGRESS_DIALOG:
      return {
        ...state,
        isUserWiseWorflowProgressDialogOpen: true
      }

    case HIDE_USER_WISE_WORKFLOW_PROGRESS_DIALOG:
      return {
        ...state,
        isUserWiseWorflowProgressDialogOpen: false
      }

    case SHOW_ALL_USERS_WORKFLOW_PROGRESS_DIALOG:
      return {
        ...state,
        isAllUsersWorflowProgressDialogOpen: true
      }

    case HIDE_ALL_USERS_WORKFLOW_PROGRESS_DIALOG:
      return {
        ...state,
        isAllUsersWorflowProgressDialogOpen: false
      }


    case SHOW_WRAPPER_WORKFLOW_PROGRESS_DIALOG:
      return {
        ...state,
        isWrapperWorflowProgressDialogOpen: true,
        wrapperWorkflowComponentId: action.payload.component_id
      }

    case HIDE_WRAPPER_WORKFLOW_PROGRESS_DIALOG:
      return {
        ...state,
        isWrapperWorflowProgressDialogOpen: false,
        wrapperWorkflowComponentId: null
      }

    case SHOW_WRAPPER_WORKFLOW_DETAILS_DIALOG:
      return {
        ...state,
        isWrapperWorflowDetailsDialogOpen: true,
        selectedWrapperComponentId: action.payload.componentId,
      }

    case HIDE_WRAPPER_WORKFLOW_DETAILS_DIALOG:
      return {
        ...state,
        isWrapperWorflowDetailsDialogOpen: false,
        selectedWrapperComponentId: null,
      }

    case START_FETCH_WRAPPER_COMPONENT_DATAS:
      return {
        ...state,
        fetchWrapperComponentDatasProgress: true,
        wrapperComponentDatas: null
      }

    case END_FETCH_WRAPPER_COMPONENT_DATAS:
      return {
        ...state,
        fetchWrapperComponentDatasProgress: false,
        wrapperComponentDatas: action.payload.success
      }

    case START_FETCH_WORKFLOW_USER_ACTIVITY:
      return {
        ...state,
        fetchWorkflowUserActivityProgress: true,
        workflowUserActivity: null
      }

    case END_FETCH_WORKFLOW_USER_ACTIVITY:
      return {
        ...state,
        fetchWorkflowUserActivityProgress: false,
        workflowUserActivity: action.payload.success
      }

    case START_FETCH_CHILD_WORKFLOW_USER_ACTIVITY:
      return {
        ...state,
        fetchChildWorkflowUserActivityProgress: true,
        childWorkflowUserActivity: null
      }

    case END_FETCH_CHILD_WORKFLOW_USER_ACTIVITY:
      return {
        ...state,
        fetchChildWorkflowUserActivityProgress: false,
        childWorkflowUserActivity: action.payload.success
      }

    case START_FETCH_EVALUATION_WORKFLOW_ID:
      return {
        ...state,
        fetchEvaluationWorkflowIdProgress: true,
        evaluationWorkflowDetails: {}
      }

    case END_FETCH_EVALUATION_WORKFLOW_ID:
      return {
        ...state,
        fetchEvaluationWorkflowIdProgress: false,
        evaluationWorkflowDetails: action.payload.success
      }

    case START_FETCH_WORKFLOW_CONDITION_RULES:
      return {
        ...state,
        fetchWorkflowConditionRulesProgress: true,
        workflowConditionRules: []
      }

    case END_FETCH_WORKFLOW_CONDITION_RULES:
      return {
        ...state,
        fetchWorkflowConditionRulesProgress: false,
        workflowConditionRules: action.payload.success
      }

    case SHOW_WORKFLOW_DETAILS_UPDATE_DIALOG:
      return {
        ...state,
        isWorkflowDetailsUpdateDialogOpen: true,
      }

    case HIDE_WORKFLOW_DETAILS_UPDATE_DIALOG:
      return {
        ...state,
        isWorkflowDetailsUpdateDialogOpen: false,
      }

    case START_ADD_WORKFLOW_COMPONENT_TIMELINE:
      return {
        ...state,
        addWorkflowCompoenentTimelineProgress: true,
        workflowComponentTimelineError: null,
      }

    case END_ADD_WORKFLOW_COMPONENT_TIMELINE:
      return {
        ...state,
        addWorkflowCompoenentTimelineProgress: false,
        workflowComponentTimeline: action.payload.success,
        workflowComponentTimelineError: action.payload.error,
      }

    case START_UPDATE_WORKFLOW_COMPONENT_TIMELINE:
      return {
        ...state,
        updateWorkflowCompoenentTimelineProgress: true,
        workflowComponentTimelineError: null,
      }

    case END_UPDATE_WORKFLOW_COMPONENT_TIMELINE:
      return {
        ...state,
        updateWorkflowCompoenentTimelineProgress: false,
        workflowComponentTimeline: action.payload.success,
        workflowComponentTimelineError: action.payload.error,
      }

    case START_GET_WORKFLOW_COMPONENT_TIMELINE:
      return {
        ...state,
        fetchWorkflowCompoenentTimelineProgress: true,
        workflowComponentTimeline: null,
        workflowComponentTimelineError: null,
      }

    case END_GET_WORKFLOW_COMPONENT_TIMELINE:
      return {
        ...state,
        fetchWorkflowCompoenentTimelineProgress: false,
        workflowComponentTimeline: action.payload.success,
        workflowComponentTimelineError: action.payload.error,
      }

    case SHOW_WORKFLOW_COMPONENT_TIMELINE:
      return {
        ...state,
        showWorkflowComponentTimelineDialog: true
      }

    case HIDE_WORKFLOW_COMPONENT_TIMELINE:
      return {
        ...state,
        showWorkflowComponentTimelineDialog: false
      }

    case START_SET_COMPONENTS_FLOW_POSITION: {
      return {
        ...state,
        setComponentFlowPostionProg: true
      }
    }
    case END_SET_COMPONENTS_FLOW_POSITION: {
      return {
        ...state,
        setComponentFlowPostionProg: false,
      }
    }

    case SET_WORKFLOW_ACTIVE_TAB:
      return {
        ...state,
        workflowActiveTab: action.payload.id
      }

    case SHOW_CRITERIA_APPROVERS_SEARCH_DIALOG:
      return {
        ...state,
        showCriteriaApprovalSearchDialog: true
      }

    case HIDE_CRITERIA_APPROVERS_SEARCH_DIALOG:
      return {
        ...state,
        showCriteriaApprovalSearchDialog: false
      }
    case START_ADD_COPY_OF_ORG_WORKFLOW:
      return {
        ...state,
        addCopyOfOrgWorkFlowProgress: true,
        addCopyOfOrgWorkFlowSuccess: null,
        addCopyOfOrgWorkFlowError: null
      }
    case END_ADD_COPY_OF_ORG_WORKFLOW: {
      const { success, error } = action.payload
      return {
        ...state,
        addCopyOfOrgWorkFlowProgress: false,
        addCopyOfOrgWorkFlowSuccess: success,
        addCopyOfOrgWorkFlowError: error
      }
    }
    default:
      return state;
  }
}