import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { CircularProgress, Grid } from '@material-ui/core';
import SqureButton from 'Components/Common/SqureButton';
import Image from 'Components/Common/image.jsx'
import ArrowForward from "assets/images/arrow_forward_white.svg"

import classnames from "classnames";

import {
  setSelectedRequirement,
  setSelectedProduct,
  fetchLastEvaluationUserResponse,
  fetchQuestionaireRequiredStatus
} from "../../../../../../../../redux/evaluation/criteria/action"
import { showFinalizeReqDialog } from "redux/evaluation/feedback/action";
import { showFeedbackTakeDialog } from "redux/evaluation/feedback/action";
import MessageDialog from "Components/Common/MessageDialog";
import { showMessageDialog, hideMessageDialog, } from "redux/applications/action";

import { addWorkflowComponentUserResponse } from "redux/workflow/action";
import { getNextComponent } from "util/Common";
import {  showOverallReqDialog } from "redux/evaluation/feedback/action";

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  requirementTree: state.evaludationCriteria.requirementTree,
  fetchReqTreeProgress: state.evaludationCriteria.requirementTree,
  selectedRequirementId: state.evaludationCriteria.selectedRequirementId,
  selectedProductId: state.evaludationCriteria.selectedProductId,
  requirementUserResponsesStatus: state.evaludationCriteria.requirementUserResponsesStatus,
  userTimeline: state.workflow.userTimeline,
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  fetchQuestionaireStatusProgress: state.evaludationCriteria.fetchQuestionaireStatusProgress,
  fetchQuestionaireStatusError: state.evaludationCriteria.fetchQuestionaireStatusError,
  questionaireStatusData: state.evaludationCriteria.questionaireStatusData,
  user: state.authUser.user,
});

const connectionActions = {
  setSelectedRequirement: setSelectedRequirement,
  showFinalizeReqDialog: showFinalizeReqDialog,
  showFeedbackTakeDialog: showFeedbackTakeDialog,
  setSelectedProduct: setSelectedProduct,
  fetchLastEvaluationUserResponse: fetchLastEvaluationUserResponse,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  fetchQuestionaireRequiredStatus: fetchQuestionaireRequiredStatus,
  showOverallReqDialog:showOverallReqDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  bottomLinks: {
    position: "fixed",
    bottom: "0",
    padding: theme.spacing(1),
    left: 0,
    right: 0,
    paddingLeft: theme.spacing(24),
    paddingRight: theme.spacing(8),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px -1px 6px #00000010",
    zIndex: 1000
  }
});

class BottomActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reqTreeMap: [],
      isActiveDialog: false,
      saveType: 'NEXT'
    }

    this.getAllTreeLinks = this.getAllTreeLinks.bind(this);
    this.showFeedbackDialog = this.showFeedbackDialog.bind(this);
    this.showAlert = this.showAlert.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (!this.props.fetchQuestionaireStatusProgress && prevProps.fetchQuestionaireStatusProgress) {
      if (this.props.fetchQuestionaireStatusError === null) {
        this.checkForRequired()
      }
    }
  }

  callBack = () => {
    this.props.hideMessageDialog();
    this.setState({
      isActiveDialog: false
    })
  };

  showAlert(e) {
    e.stopPropagation();
    var me = this;
    this.setState({ isActiveDialog: true }, () => {
      me.props.showMessageDialog();
    })
  }


  checkForRequired() {
    const { questionaireStatusData } = this.props;
    // console.log(questionaireStatusData, 'questionaireStatusData')
    let totalReqRequirements = 0;
    let totalReqCompleted = 0;
    if (questionaireStatusData) {
      questionaireStatusData.forEach(function (itm) {
        if (itm.Stats && itm.Stats.OverallMandaoryProgress) {
          let data = itm.Stats.OverallMandaoryProgress[0];
          totalReqRequirements = totalReqRequirements + data.Total;
          totalReqCompleted = totalReqCompleted + data.Completed;
        }
      })
    }
    // console.log(totalReqRequirements, 'totalReqRequirements')
    // console.log(totalReqCompleted, 'totalReqCompleted')
    if (totalReqRequirements !== totalReqCompleted) {
      this.props.showOverallReqDialog(false,true);
      // alert('Alert: Still some users not answered to assigned requirements')
    } else {
      var responseDatas = [];
      this.props.addWorkflowComponentUserResponse(this.props.match.params.evaluation_id, this.props.workflowComponentId, {
        "data": responseDatas,
        "forward": this.state.saveType === 'NEXT' ? true : false
      });
    }
  }

  showFeedbackDialog() {
    if (this.props.isWorkFlow) {
      var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);
      if (nextComponent !== null) {
        let redirectPath = `/app/evaluate/${nextComponent.EvaluationId}/workflow/${nextComponent.WorkflowId}/component/${nextComponent.ComponentId}`;
        this.props.history.push(redirectPath);
        return;
      } else {
        this.props.fetchQuestionaireRequiredStatus(this.props.match.params.evaluation_id, {
          "with_stats": true,
          "user_id": this.props.user.ID
        })
        // var responseDatas = [];
        // this.props.addWorkflowComponentUserResponse(this.props.match.params.evaluation_id, this.props.workflowComponentId, {
        //   "data": responseDatas,
        //   "forward": this.state.saveType === 'NEXT' ? true : false
        // });
      }
    } else {
      this.props.showFeedbackTakeDialog();
    }
  }



  getAllTreeLinks(datas, allItems) {
    datas.forEach(function (item) {
      if (item.Type !== 1) {
        allItems.push(item.ID);
      }
      if (item.Childrens !== null) {
        this.getAllTreeLinks(item.Childrens, allItems);
      }
    });
    this.setState({
      reqTreeMap: allItems
    })
  }


  render() {
    const classes = this.props.classes;
    const { adminForward } = this.props;

    var reqResponcesStatus = this.props.requirementUserResponsesStatus === null ? [] : this.props.requirementUserResponsesStatus;
    // console.log(reqResponcesStatus, 'reqResponcesStatus')
    var isAllAnswered = true;
    // var productWiseStatus = {};
    reqResponcesStatus.forEach(function (item) {
      if (item.IsMandatory && !item.IsAnswered) {
        isAllAnswered = false;
      }
      // if (item.IsAnswered === false) {
      //   isAllAnswered = false;
      // }
      // if (productWiseStatus[item.ProductId] === undefined) {
      //   productWiseStatus[item.ProductId] = {
      //     totalQuestions: 1,
      //     totalAnswered: 0
      //   }
      //   if (item.IsAnswered === true) {
      //     productWiseStatus[item.ProductId].totalAnswered = 1;
      //   }
      // } else {
      //   productWiseStatus[item.ProductId].totalQuestions++;
      //   if (item.IsAnswered === true) {
      //     productWiseStatus[item.ProductId].totalAnswered++;
      //   }
      // }
    });

    let messageData = {
      // head: "Are you sure?",
      subHead: "Click Finalize to finish up the evaluation.",
      firstBtn: "Cancel",
      secondBtn: "",
    };

    var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);

    return (
      <div>
        <Grid container className={classnames(classes.bottomLinks)}>
          <Grid item xs={8}>
          </Grid>
          <Grid item xs={4} style={{ "textAlign": "right" }}>
            <SqureButton variant={"outlined"} onClick={() => {
              this.props.history.push("/app/evaluate")
            }}>Save and continue later</SqureButton>

            {!isAllAnswered && <SqureButton
              style={{ "marginLeft": "10px" }}
              variant={"contained"}
              disabled={((!adminForward || (adminForward && this.props.isCreatorOrAdmin)) || nextComponent !== null) ? false : true}
              endIcon={
                <Image src={ArrowForward} alt="arrow forward" />
              }
              onClick={() => {
                this.props.showFinalizeReqDialog(true)
              }}>
              {this.props.addComponentUserResponseProgress ? <CircularProgress style={{ width: 27, height: 27 }} /> : 'Next'}
            </SqureButton>}

            {isAllAnswered && <SqureButton
              style={{ "marginLeft": "10px" }}
              variant={"contained"}
              disabled={((!adminForward || (adminForward && this.props.isCreatorOrAdmin)) || nextComponent !== null) ? false : true}
              endIcon={
                <Image src={ArrowForward} alt="arrow forward" />
              }
              onClick={() => {
                this.showFeedbackDialog()
              }
              }>
              {this.props.addComponentUserResponseProgress || this.props.fetchQuestionaireStatusProgress ? <CircularProgress style={{ width: 27, height: 27 }} /> : 'Next'}
            </SqureButton>}

          </Grid>
        </Grid>
        {this.state.isActiveDialog && <MessageDialog messageData={messageData} callBack={this.callBack} />}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(BottomActions));
