import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AppBar from "../../AppBar";
import { Grid,Button,Card,CardActionArea,CardContent,Typography,CardActions } from "@material-ui/core";
import { showAddCompanyProfileDialog } from "../../../../redux/companyProfileSettings/action";
import {showEvaluationSelectDialog} from "redux/templates/evaluation/action"
import {fetchPlans,fetchPlan} from "redux/payments/action"
import ChooseTemplateModal from "./Templates/Common/ChooseTemplateModal";
import * as Enviorment from "../../../../util/Environment"
const connectedProps = (state) => ({
    user: state.authUser.user,
    plans :state.payment.plans,
    plan :state.payment.plan,
});

const connectionActions = {
    showAddCompanyProfileDialog: showAddCompanyProfileDialog,
    showEvaluationSelectDialog:showEvaluationSelectDialog,
    fetchPlans:fetchPlans,
    fetchPlan:fetchPlan
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    "actionContainer":{
        marginTop:theme.spacing(6),
        marginBottom:theme.spacing(6),

        "& [class*='MuiGrid-item']": {
            padding: theme.spacing(2)
        }
    },

    "cardAction": {
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        height: '100%'
    },

    "Primary": {
        textAlign: 'center',
        fontSize: theme.spacing(2.4),
        marginTop: theme.spacing(1),
        marginBottom:theme.spacing(0.5)
    },

    "Secondary": {
        textAlign: 'center',
    },

    "Content": {
        padding: theme.spacing(2, 2, 0, 2),
        flex: 'inherit'
    },
   "button":{
        padding: theme.spacing(0.8, 12.4),
        fontSize:theme.spacing(2.8),
        fontWeight:500,
        marginTop: theme.spacing(1.2)
   },
   btn:{
    color: '#fff',
    margin: '20px 0',
    background: '#3C3C3C',
    padding: '6px 20px',
    '&:hover':{
        color: '#fff',
        background: '#3C3C3C',
    }
   }
});

class Payments extends React.Component {

    constructor(props){
        super(props);
        this.fileRef = React.createRef();
        this.state = {
            error: ''
        }
    }

    componentDidMount(){
        this.props.fetchPlans();
        this.props.fetchPlan();
    }
  
    render() {
        const classes = this.props.classes;
        if(this.props.user == null){
            return null;
        }
        var userHost = Enviorment.api_host("USER");
        var priceId = Enviorment.get("STRIPE_PRICE_ID");
        const authToken = window.localStorage.getItem("authToken");
        var isPoral = this.props.user.Organization.StripeCustomerID !== null && String(this.props.user.Organization.StripeCustomerID).trim().length !== 0
        console.log(this.props.plans);
        return <div>
            <AppBar title="Payments" withBack={true}/>
            <div className={classes.actionContainer}>
            {isPoral && <Grid container spacing={3}>
                    {this.props.plan !== null &&  this.props.plan.map((p,k)=>{
                        return    <Grid item lg={4} key={p.id}>
                                 <Card>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2"  style={{textAlign: 'center'}}>
                                                {p.name}
                                            </Typography>
                                            <Typography gutterBottom variant="body2" color="primary"  style={{textAlign: 'center'}}>
                                                {p.prices[0].amount/100}  <span style={{ textTransform: 'uppercase'}}>{p.prices[0].currency}</span> / month
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p" style={{textAlign: 'center'}}>
                                                {p.description}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions style={{placeContent: 'center'}}>
                                        <Button size="small" color="primary" onClick={()=>{
                                            window.location = userHost+`/payment-portal?token=${authToken}`
                                        }} className={classes.btn}>
                                            Update
                                        </Button>
                                    </CardActions>
                                </Card>
                        </Grid>
                    })}
                </Grid>}
                {!isPoral && <Grid container spacing={3}>
                    {this.props.plans !== null &&  this.props.plans?.reverse().map((plan,k)=>{
                        return    <Grid item lg={4} key={plan.id}>
                                 <Card>
                                    <CardActionArea>
                                        <CardContent >
                                            <Typography gutterBottom variant="h5" component="h2" style={{textAlign: 'center'}}>
                                                {plan.name}
                                            </Typography>
                                            <Typography gutterBottom variant="body2" color="primary" style={{textAlign: 'center'}}>
                                                ${plan.prices[0].amount/100} <span style={{ textTransform: 'uppercase'}}>{plan.prices[0].currency}</span> / month
                                            </Typography>
                        
                                            <Typography variant="body2" color="textSecondary" component="p" style={{textAlign: 'center'}}>
                                                {plan.description}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions style={{placeContent: 'center'}}>
                                        <Button size="small" color="primary" className={classes.btn} onClick={()=>{
                                            window.location = userHost+`/subscribe?token=${authToken}&priceId=${plan.prices[0].id}`
                                        }} >
                                            Subscribe
                                        </Button>
                                    </CardActions>
                                </Card>
                        </Grid>
                    })}
                </Grid>}
            </div>
            <ChooseTemplateModal />
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(Payments));