import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";

export const START_FETCH_EMBEDABLE_LINK     =   "START_FETCH_EMBEDABLE_LINK";
export const END_FETCH_EMBEDABLE_LINK       =   "END_FETCH_EMBEDABLE_LINK";

export const START_FETCH_INTEGRATION_STATUS     =   "START_FETCH_INTEGRATION_STATUS";
export const END_FETCH_INTEGRATION_STATUS       =   "END_FETCH_INTEGRATION_STATUS";

export const START_ENABLE_QS_INTEGRATION     =   "START_ENABLE_QS_INTEGRATION";
export const END_ENABLE_QS_INTEGRATION       =   "END_ENABLE_QS_INTEGRATION";

export const START_USER_ROLE_ASSOCIATION     =   "START_USER_ROLE_ASSOCIATION";
export const END_USER_ROLE_ASSOCIATION       =   "END_USER_ROLE_ASSOCIATION";

export const START_FETCH_QS_USER_ROLES     =   "START_FETCH_QS_USER_ROLES";
export const END_FETCH_QS_USER_ROLES       =   "END_FETCH_QS_USER_ROLES";

export const START_REMOVE_QS_USER_ROLES     =   "START_REMOVE_QS_USER_ROLES";
export const END_REMOVE_QS_USER_ROLES       =   "END_REMOVE_QS_USER_ROLES";
/**
 *  Fetch the integration status with quicksight 
 */
 function startFetchQuickSightStatus(){
  return {
      type:START_FETCH_INTEGRATION_STATUS,
      payload:{}
  };
}

function endFetchQuickSightStatus(success,error){
  return {
      type:END_FETCH_INTEGRATION_STATUS,
      payload:{
          success,error
      }
  };
}

export  function fetchIntegrationStatus(data){
  const api_server = Environment.api_host("QUICKSIGHT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/quickSightStatus?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startFetchQuickSightStatus());
      try{
        let [response,error]    =   await HttpFactory.instance().getMethod(url,data);
       
        dispatch(endFetchQuickSightStatus(response,error));
      } catch(e){
        dispatch(endFetchQuickSightStatus(null,{
            message:e.message
        }));
        return;
      }
  };
}

/**
 *  enable the integration status with quicksight 
 */
 function startEnableUpdateQuickSight(){
  return {
      type:START_ENABLE_QS_INTEGRATION,
      payload:{}
  };
}

function endEnableUpdateQuickSight(success,error){
  return {
      type:END_ENABLE_QS_INTEGRATION,
      payload:{
          success,error
      }
  };
}

export  function updateQuickSightIntegration(data){
  const api_server = Environment.api_host("QUICKSIGHT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/enableQuickSight?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startEnableUpdateQuickSight());
      try{
        let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
       
        dispatch(endEnableUpdateQuickSight(response,error));
      } catch(e){
        dispatch(endEnableUpdateQuickSight(null,{
            message:e.message
        }));
        return;
      }
  };
}

/**
 *  add User Roles
 */
 function startUserRoleAssociation(){
  return {
      type:START_USER_ROLE_ASSOCIATION,
      payload:{}
  };
}

function endUserRoleAssociation(success,error){
  return {
      type:END_USER_ROLE_ASSOCIATION,
      payload:{
          success,error
      }
  };
}

export  function updateUserRoleAssociation(data){
  const api_server = Environment.api_host("QUICKSIGHT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/userRole?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startUserRoleAssociation());
      try{
        let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
       
        dispatch(endUserRoleAssociation(response,error));
      } catch(e){
        dispatch(endUserRoleAssociation(null,{
            message:e.message
        }));
        return;
      }
  };
}

export  function removeUserRoleAssociation(userId){
  const api_server = Environment.api_host("QUICKSIGHT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/userRole/${userId}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startUserRoleAssociation());
      try{
        let [response,error]    =   await HttpFactory.instance().deleteMethod(url,{});
       
        dispatch(endUserRoleAssociation(response,error));
      } catch(e){
        dispatch(endUserRoleAssociation(null,{
            message:e.message
        }));
        return;
      }
  };
}

/**
 *  fetch User Roles
 */
 function startQSUserRoleFetch(){
  return {
      type:START_FETCH_QS_USER_ROLES,
      payload:{}
  };
}

function endQSUserRoleFetch(success,error){
  return {
      type:END_FETCH_QS_USER_ROLES,
      payload:{
          success,error
      }
  };
}

export  function fetchQSUserRoleAssociation(){
  const api_server = Environment.api_host("QUICKSIGHT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/userRoles?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startQSUserRoleFetch());
      try{
        let [response,error]    =   await HttpFactory.instance().getMethod(url,{});
       
        dispatch(endQSUserRoleFetch(response,error));
      } catch(e){
        dispatch(endQSUserRoleFetch(null,{
            message:e.message
        }));
        return;
      }
  };
}


/**
 *  Fetch the integration status with quicksight 
 */
 function startFetchEmbedableLink(){
  return {
      type:START_FETCH_EMBEDABLE_LINK,
      payload:{}
  };
}

function endFetchEmbedableLink(success,error){
  return {
      type:END_FETCH_EMBEDABLE_LINK,
      payload:{
          success,error
      }
  };
}

export  function fetchEmbedableLinkStatus(data){
  const api_server = Environment.api_host("QUICKSIGHT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/getEmbedableURL?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startFetchEmbedableLink());
      try{
        let [response,error]    =   await HttpFactory.instance().getMethod(url,data);
       
        dispatch(endFetchEmbedableLink(response,error));
      } catch(e){
        dispatch(endFetchEmbedableLink(null,{
            message:e.message
        }));
        return;
      }
  };
}