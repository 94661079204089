import { Button, CircularProgress, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import Image from "Components/Common/image.jsx";
import { showSnackBar } from "redux/snackbar/action";
import { useEffect } from "react";

const connectedProps = () => ({});

const connectionActions = {};

const connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      paddingTop: "24px",
      paddingBottom: "24px",
    },
    heading: {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 600,
      color: "#003b4d99",
      textTransform: "uppercase",
    },
    imageContainer: {
      display: "flex",
      alignItems: "center",
    },
    image: {
      width: "64px",
      height: "64px",
      marginRight: "16px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
    },
    uploadButton: {
      marginRight: "8px",
    },
    productLogoContainer: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center flex-start",
      alignItems: "center",
    },
    productLogo: {
      float: "left",
      width: "100px",
      height: "100px",
      border: "1px solid #EEEEEE",
      borderRadius: "4px",
      marginRight: "10px",
      textAlign: "center",
      lineHeight: "33px",
      fontWeight: 600,
      fontSize: 11,
      color: "#A0A5BA",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "scale-down",
      },
    },
  });

const ProfileItemImageUpload = (props) => {
  const { data, isEdit, isRequired = false, value, onSubmit, onDelete, classes } = props;
  const [selectedFile, setSelectedFile] = useState(value || null);
  const dispatch = useDispatch();
  const [error, setError] = useState(data.error || false);

  useEffect(() => {
    setError(data.error || false);
    if (data.value) {
      setError(false);
    }
    setSelectedFile({ name: "Logo", url: data.value });
  }, [JSON.stringify(data)]);

  const fileRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setShowLoader(true);

      const file = event.target.files[0];
      // Check if the selected file is an image
      if (!file.type.startsWith("image/")) {
        dispatch(showSnackBar("Please select an image file.", "error", 3000));
        setShowLoader(false);
        return;
      }
      var formData = new FormData();
      formData.append("folder", `companyProfile/${data.itemKey}`);
      formData.append("fileLength", 1);
      const timestamp = new Date().getTime();
      let nameArr = [];

      formData.append(`file${0}`, file, `${timestamp}${file.name}`);
      setSelectedFile({ name: file.name, url: "" });
      console.log(file, file.name);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/multi-add?t=${timestamp}`;

      axios
        .post(url, formData, {
          headers: {
            Authorization:
              CommonFn.getStorage("authType") +
              " " +
              CommonFn.getStorage("authToken"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let resData = response.data?.filepath || {};
          let keyData = Object.keys(resData) || [];
          let data = keyData.map((key, i) => ({
            name: nameArr[i],
            url: resData[key],
          }));
          console.log("response: " + JSON.stringify(data), data);
          setError(false);
          setShowLoader(false);
          setSelectedFile({ name: file.name, url: data[0].url });
          console.log("data[0].url", data[0].url);
          onSubmit?.(data[0].url);
          //https://devattachments.expent.ai/3/companyProfile/…8d0-9d38-2c48253611b1/1689937587610jfrog_logo.jpg
          dispatch(showSnackBar("File uploaded", "success", 1500));
        })
        .catch((err) => {
          if (err.response) {
            dispatch(
              showSnackBar(
                `Failed , ${JSON.stringify(err.response.data.message)}`,
                "error",
                3000
              )
            );
          } else if (err.request) {
            dispatch(
              showSnackBar(
                "Something went wrong. Please try after sometime.",
                "error",
                3000
              )
            );
          } else {
            dispatch(
              showSnackBar(
                "Something went wrong. Please try after sometime.",
                "error",
                3000
              )
            );
          }
          setShowLoader(false);
        });
    }
  };

  const handleDeleteFile = (index) => {
    setSelectedFile(null);
    fileRef.current.value = null;
    onSubmit?.("");
  };

  const handleUploadClick = () => {
    // Click the hidden file input to trigger file selection
    fileRef.current.click();
  };
  return (
    <>
      {isEdit && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "16px",
            }}
          >
            {showLoader ? (
              <CircularProgress
                style={{ height: 30, width: 30, marginBottom: 10 }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "fit-content",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "100px",
                      width: "200px",
                      borderRadius: "8px",
                      padding: "5px",
                      border: `1px solid ${error ? "#FF8082" : "transparent"}`,
                    }}
                  >
                    <div className={classes.productLogoContainer}>
                      <div className={classes.productLogo}
                        title={selectedFile?.name}>
                        <Image
                          src={selectedFile?.url}
                          alt={selectedFile?.name}
                          title={selectedFile?.name}
                        />
                      </div>
                    </div>

                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0 10px",
                    }}
                  >
                    <input
                      ref={fileRef}
                      onChange={fileSelectHandler}
                      type="file"
                      hidden={true}
                      accept="image/*"
                    />
                    <Button
                      variant="filled"
                      color="primary"
                      onClick={handleUploadClick}
                    >
                      Upload Logo
                    </Button>
                    <Button
                      style={{ marginTop: "10px", color: "#FF8082" }}
                      onClick={() => handleDeleteFile()}
                    >
                      Delete Logo
                    </Button>
                  </div>
                </div>
                {error && (
                  <Typography
                    variant="caption"
                    style={{ color: "#FF8082", marginTop: "5px" }}
                  >
                    This field is required
                  </Typography>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {!isEdit && (
        <>
          {selectedFile?.url ? (
            <div className={classes.productLogoContainer}>
              <div className={classes.productLogo}
                title={selectedFile?.name}>
                <Image
                  src={selectedFile?.url}
                  alt={selectedFile?.name}
                  title={selectedFile?.name}
                />
              </div>
            </div>

          ) : (
            <Typography>{"-"}</Typography>
          )}
        </>
      )}
    </>
  );
};

export default connector(
  compose(withRouter, withStyles(styles))(ProfileItemImageUpload)
);
