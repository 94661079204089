import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";

import Image from "Components/Common/image.jsx";
// assets
import DefaultImage from "assets/images/evaluation/default.svg";
import Tooltip from "@material-ui/core/Tooltip";

const connectedProps = (state) => ({
  sellerAssessmentDetails: state.sellerAssessment.sellerAssessmentDetails,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {},
    productImageContainer: {
      textAlign: "center",
    },
    prodHeading: {
      background: "#fff",
      position: "relative",
    },
    criteria: {
      marginBottom: 15,
      background: "#fff",
      "&.options-hide": {
        pointerEvents: "none",
      },
    },
    productName: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    productImageContainer1: {
      width: 40,
      height: 40,
      padding: 4,
      margin: "0 auto",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 1px 6px #0000001A",
      borderRadius: 10,
    },
    productImage: {
      width: "100%",
      height: "auto",
    },
    overAllDiv: {
      background: "#f1f1f1",
      borderRadius: theme.spacing(1.25),
      padding: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
    },
    overAllText: {
      fontWeight: 600,
      fontSize: theme.spacing(1.8),
    },
    overAllPer: {
      float: "right",
      color: "green",
    },
  });

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      askSellerMulti: false,
    };
  }

  render() {
    const { classes, sellerAssessmentDetails, completedCount, totalCount } = this.props;

    let assessments = sellerAssessmentDetails?.data || [];
    let assessment = assessments[0] || {};
    let totalDoneProgress = Math.round((completedCount * 100) / totalCount);

    return (
      <div className={classes.root}>
        <div className={classes.prodHeading}>
          <Grid container alignItems={"center"}>
            <Grid item lg={12}>
              <div style={{ padding: 10, textAlign: "center" }}>
                <div className={classes.productImageContainer1}>
                  <Image alt="" src={assessment.product_logo == null || assessment.product_logo.length === 0 ? DefaultImage : assessment.product_logo} className={classes.productImage} />
                </div>
                <Tooltip
                  className={"tooltip"}
                  placement="top"
                  title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{assessment.product_name}</span>}
                  arrow
                >
                  <Typography className={classes.productName}>{assessment.product_name}</Typography>
                </Tooltip>
              </div>
            </Grid>
            <Grid item lg={12} style={{ padding: "0px 10px 10px 10px" }}>
              <div className={classes.overAllDiv}>
                <span className={classes.overAllText}>Overall Progress</span>
                <span className={classes.overAllPer}>
                  {completedCount}/{totalCount}
                </span>
                <span className={classes.overAllPer}>{totalDoneProgress}%</span>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Product));
