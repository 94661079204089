import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import SchemaNodes from "./SchemaNodes";
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Paper } from "@material-ui/core";
import { CommonFn } from "services/commonFn";
import Image from "Components/Common/image.jsx";
import editIcon from "assets/images/edit_icon.svg";
import deleteIcon from "assets/images/delete.svg";
import { fetchVisualization } from "redux/visualization/action";

const connectedProps = (state) => ({
  visualizations: state.visualization.visualizations,
  visualizationFetchProgress: state.visualization.visualizationFetchProgress,
  visualizationFetchError: state.visualization.visualizationFetchError,
});

const connectionActions = {
  fetchVisualization: fetchVisualization
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  schemaRoot: {
    marginTop: 20,
  },
  tagName: {
    cursor: 'pointer',
    border: '1px solid #c2c2c2',
    borderRadius: 4,
    background: '#fff',
    margin: 0,
    marginBottom: 5,
    padding: 5
  },
  childTagName: {
    cursor: 'pointer',
    border: '1px solid #c2c2c2',
    borderRadius: 4,
    background: '#fff',
    margin: 0,
    marginBottom: 5,
    marginLeft: 15,
    padding: 5
  },
  loader: {
    textAlign: 'center',
    marginTop: 10
  },
  subChild: {
    paddingLeft: '20px'
  },
  visRow: {
    cursor: 'pointer',
    '&:hover': {
      background: '#f7f7f7'
    }
  }
});

class Schema extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      openCloseMap: {}
    }
    this.getTagsWithId = this.getTagsWithId.bind(this)
  }

  componentDidMount() {
    if (this.props.loadEverytime) {
      this.props.fetchVisualization({
        query: ''
      });
    } else {
      if (this.props.visualizations === undefined || this.props.visualizations === null || this.props.visualizations === '') {
        this.props.fetchVisualization({
          query: ''
        });
      } else {
        this.setState({
          tags: this.props.visualizations
        })
      }
    }
  }

  getTagsWithId(tags, finalData) {
    let me = this;
    tags.forEach(function (tag) {
      if (tag.childrens) {
        finalData[tag.label] = false;
        me.getTagsWithId(tag.childrens, finalData);
      }
    })
    return finalData;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.visualizationFetchProgress && prevProps.visualizationFetchProgress) {
      if (this.props.visualizationFetchError === null) {
        this.setState({
          tags: this.props.visualizations
        })
      }
    }
  }

  handleClick(tag, additionalData) {
    this.props.handleClick(tag, additionalData)
  }

  openCloseTag(index) {
    let tags = this.state.tags;
    let tag = tags[index];
    tag.isOpen = tag.isOpen ? false : true;
    this.setState({ tags })
  }

  render() {
    const { tags } = this.state;
    const { classes } = this.props;

    const visualizations = tags;
    if (this.props.visualizationFetchProgress) {
      return <div className={classes.loader}>
        <CircularProgress />
      </div>
    }

    return (
      <div className={classes.schemaRoot}>

        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "65vh" }}>
            <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={"table_" + this.props.key}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.nameCol}>S. No.</TableCell>
                  <TableCell className={classes.statusCol}>Name</TableCell>
                  <TableCell className={classes.statusCol}>Created At</TableCell>
                  <TableCell className={classes.statusCol}>Updated At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visualizations?.data?.map((item, key) => {
                  return (
                    <TableRow key={key} className={classes.visRow} onClick={() => {
                      this.handleClick(item, {
                        name: ''
                      })
                    }}>
                      <TableCell className={classes.statusCol}>{key + 1}</TableCell>
                      <TableCell className={classes.statusCol}>{item.name}</TableCell>
                      <TableCell className={classes.statusCol}>{CommonFn.formateDate(item.createdAt, true)}</TableCell>
                      <TableCell className={classes.statusCol}>{CommonFn.formateDate(item.updatedAt, true)}</TableCell>
                    </TableRow>
                  );
                })}

                {!this.props.visualizationFetchProgress && visualizations?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }} className={classes.nameCol}>
                      No visualization
                    </TableCell>
                  </TableRow>
                )}

                {this.props.visualizationFetchProgress && (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }} className={classes.nameCol}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* {tags && tags.map((tag, index) => {
          return <SchemaNodes
            key={tag.label}
            index={index}
            tag={tag}
            classes={this.props.classes}
            handleClick={(data, additional_data) => {
              this.handleClick(data, additional_data)
            }}
          />
        })} */}
      </div>
    );
  }
}

Schema.defaultProps = {
  loadEverytime: true,
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Schema));
