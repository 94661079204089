import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import Forgot1 from "../../assets/images/forgot1.svg";
import Forgot2 from "../../assets/images/forgot2.svg";
import Image from 'Components/Common/image.jsx'

const connectedProps = (state) => ({

});

const connectionActions = {

}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        height: "100vh",
        position: "relative",
        boxSizing:"border-box"
    },
    forgotimage: {
        height: "100vh",
        position: "relative",
        flex:1
    },
    background: {
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        height: "100vh",
        zIndex: 1,
        display:"flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    background_image1: {
        height: '100%',
        position: "absolute",
        bottom: "0",
        right: 0
    },
    background_image2: {
        height: '100%',
        position: "absolute",
        bottom: "0",
        left: 0
    },
    content: {
        zIndex: 10,
        position: "relative",
        padding: theme.spacing(6),
        boxSizing:"border-box"
    }
});

class ForgotPasswordContainer extends React.Component {

    render() {
        const classes = this.props.classes;
        return <div className={classes.root}>
            <div className={classes.background}>
                <div className={classes.forgotimage}>
                    <Image src={Forgot1} alt="forgot_backimage" className={classes.background_image1} />
                </div>
                <div className={classes.forgotimage}>
                    <Image src={Forgot2} alt="forgot_backimage" className={classes.background_image2} />
                </div>
            </div>
            <div className={classes.content}>
                {this.props.children}
            </div>
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(ForgotPasswordContainer));