export const SHOW_SNACKBAR         =   "SHOW_SNACKBAR";
export const HIDE_SNACKBAR         =   "HIDE_SNACKBAR";

export  function showSnackBar(message,type,duration, showOnClickAway = false){
    return     {
        type:SHOW_SNACKBAR,
        payload:{
            message,type,duration, showOnClickAway
        }
    };
}

export  function hideSnackBar(){
    return     {
        type:HIDE_SNACKBAR,
        payload:{}
    };
}