import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TimerIcon from '@material-ui/icons/Timer';
import EventIcon from '@material-ui/icons/Event';
import FunctionsIcon from '@material-ui/icons/Functions';

let iconMap = {
  'Scheduled' : <TimerIcon size={40} />,
  'EventBased' : <TrendingDownIcon size={40} />,
  'EventFunction' : <EventIcon size={40} />,
  'Function' : <FunctionsIcon size={40} />,
}

export function getIconForAutomation(type) {
  let icon = <TimerIcon size={40} />;
  if(iconMap[type]){
    icon = iconMap[type]
  }
  return icon
}