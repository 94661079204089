import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, IconButton, Slide, Grid, CircularProgress, Tooltip } from '@material-ui/core';
import { withRouter } from 'react-router';
import CloseIcon from '@material-ui/icons/Close'
import ClearIcon from "@material-ui/icons/Clear";
import Image from 'Components/Common/image.jsx';
import editIcon from "assets/images/edit_icon.svg";
import CheckIcon from '@material-ui/icons/Check';

import classnames from "classnames";
import deleteIcon from "assets/images/delete.svg";
import WorkFlowConfiguration from '../WrapperComponents/WorkFlowConfiguration';
import { WorkFlowRule } from '../WrapperComponents/WorkFlowRule';
// import { WorkFlowRule } from '../WorkFlowRule';

import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    Background,
    Controls,
    getBezierPath,
    getMarkerEnd,
    getEdgeCenter,
} from 'react-flow-renderer';

import { showWrapperComponentDialog, hideWrapperComponentDialog } from "redux/evaluation/workflow/action";
import {
    getByIdOrgWorkflow,
    addWrapperWorkflowComponent,
    getWrapperWorkflowComponent,
    updateOrgWorkflowComponent,
    deleteOrgWorkflowComponent,
    addOrgWorkflowAction,
    updateOrgWorkflowAction,
    fetchOrgWorkflowAction,
    deleteOrgWorkflowAction,
    showWrapperMasterComponentDialog,
    hideWrapperMasterComponentDialog
} from "redux/evaluation/wrapperWorkflow/action";

import { showSnackBar } from "redux/snackbar/action";
import { workflowComponents, formComponent } from "redux/master/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpenDialog: state.evaluationWrapperWorkflow.isWrapperrComponentDialogOpen,
    fetchWorkflowComponentProgress: state.master.fetchWorkflowComponentProgress,
    wrapperWorkFlowCompList: state.evaluationWrapperWorkflow.orgWorkFlowCompList,
    // orgWorkFlowCompList: state.evaluationWorkflow.orgWorkFlowCompList,
    workflowComponent: state.master.workflowComponent,
    workflowFormCompList: state.master.workflowFormCompList,
    orgWorkFlowActionList: state.evaluationWrapperWorkflow.orgWorkFlowActionList,
    orgWorkFlowCompProgress: state.evaluationWrapperWorkflow.orgWorkFlowCompProgress,
    isWrapperMasterComponentDialogOpen: state.evaluationWrapperWorkflow.isWrapperMasterComponentDialogOpen,
    updateWrapperWorkFlowCompProgress: state.evaluationWrapperWorkflow.updateWrapperWorkFlowCompProgress,
    deleteWrapperWorkFlowCompProgress: state.evaluationWrapperWorkflow.deleteWrapperWorkFlowCompProgress,
    addWrapperWorkFlowCompProgress: state.evaluationWrapperWorkflow.addWrapperWorkFlowCompProgress,
    orgWorkFlowActionProgress: state.evaluationWrapperWorkflow.orgWorkFlowActionProgress,
    addOrgWorkFlowActionProgress: state.evaluationWrapperWorkflow.addOrgWorkFlowActionProgress,
    addOrgWorkFlowActionSuccess: state.evaluationWrapperWorkflow.addOrgWorkFlowActionSuccess,
    deleteOrgWorkFlowActionProgress: state.evaluationWrapperWorkflow.deleteOrgWorkFlowActionProgress,
    updateOrgWorkFlowActionProgress: state.evaluationWrapperWorkflow.updateOrgWorkFlowActionProgress,
});

const connectionActions = {
    showWrapperComponentDialog: showWrapperComponentDialog,
    hideWrapperComponentDialog: hideWrapperComponentDialog,
    updateOrgWorkflowComponent: updateOrgWorkflowComponent,
    workflowComponents: workflowComponents,
    formComponent: formComponent,
    getByIdOrgWorkflow: getByIdOrgWorkflow,
    getWrapperWorkflowComponent: getWrapperWorkflowComponent,
    fetchOrgWorkflowAction: fetchOrgWorkflowAction,
    addWrapperWorkflowComponent: addWrapperWorkflowComponent,
    showWrapperMasterComponentDialog: showWrapperMasterComponentDialog,
    hideWrapperMasterComponentDialog: hideWrapperMasterComponentDialog,
    updateOrgWorkflowAction: updateOrgWorkflowAction,
    deleteOrgWorkflowComponent: deleteOrgWorkflowComponent,
    addOrgWorkflowAction: addOrgWorkflowAction,
    deleteOrgWorkflowAction: deleteOrgWorkflowAction,
    showSnackBar: showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(0) + " !important"
        // padding: theme.spacing(6, 12) + " !important"
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    secondarySection: {
        boxShadow: '0px 1px 6px #0000000D',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '32px',
        justifyContent: 'space-between',
        paddingRight: 15
    },
    footerItems: {
        textAlign: 'right',
        padding: theme.spacing(2),
        borderTop: '1px solid #f2f2f2'
    },
    closeBtn: {
        background: "#3C3C3C",
        borderRadius: 5,
        color: "#fff",
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        minWidth: 140,
        border: "none",
        cursor: "pointer",
        "&:hover": {
            background: "#3C3C3C",
        },
    },
    formBuilderMain: {
        minHeight: '40vh'
    },
    editIconImage: {
        cursor: 'pointer',
        width: 13,
        marginLeft: 10,
        position: 'relative',
        top: 1
    },
    micon: {
        fontSize: 24,
        marginTop: 0,
        marginLeft: 10,
        position: 'absolute',
        cursor: 'pointer',
        color: '#4b86f8',
        "&:hover": {
            background: "#f8f7f7",
            padding: '2px',
            borderRadius: '4px'
        },
    },
    inputSection: {
        border: "1px solid #ddd",
        borderRadius: 6,
        minHeight: 30,
        paddingLeft: 5,
    },
    titleImage: {
        cursor: 'pointer',
        width: 13,
        marginLeft: 10,
        position: 'relative',
        top: 1
    },
    dragElement: {
        boxShadow: '0px 3px 20px #5555550d',
        padding: '24px 0px 24px 32px',
        transition: 'flex 0s, opacity 0.5s linear',
        "& .draggable": {
            border: '1px solid #EEEEEE',
            borderRadius: '5px',
            padding: '8px 20px',
            boxShadow: '0px 3px 6px #C1C1C129',
            marginBottom: 10,
            fontSize: 13,
        },
        "&.hide-drag-element": {
            padding: 0,
            opacity: 0,
            flex: 0,
        }
    },
    componentTextAndImg: {
        marginBottom: 26
    },
    componentText: {
        marginLeft: 10,
        fontSize: 14
    },
    workflowList: {
        maxHeight: "calc( 100vh - 200px)",
        overflowY: 'auto',
        paddingRight: '24px'
    },
    flowSection: {
        height: '87vh',
        maxHeight: "calc( 100vh - 200px)",
        "& .draggable": {
            display: 'none',
        },
        "& .decision-rectangle": {
            width: 66,
            height: 66,
            transform: 'rotate(45deg)',
            position: 'absolute',
            top: 11,
            left: 41,
            border: '1px solid #EEEEEE',
            background: '#fff',
            zIndex: -1
        },
        "& .decision-component": {
            position: 'relative',
            zIndex: 1,
            top: 34
        }
    },
    closeIconSection: {
    },
    elementDetails: {
        position: 'absolute',
        flex: 1,
        maxWidth: 900,
        minWidth: 700,
        top: 50,
        right: 0,
        zIndex: 20,
        background: '#fff'
    },
    componentDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    componentDetailsHeader: {
        display: 'flex',
        background: '#3f4e6b',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 24,
    },
    componentDetailsBody: {
        padding: '0px 24px 0px 24px',
    },
    componentDetailsBodyHeader: {
        padding: '20px 24px 0px 24px',
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    componentDetailsSection: {
        position: 'relative',
        overflowY: 'auto',
        height: 'calc(100vh - 162px)',
        marginBottom: 60
    },
    detailText: {
        fontSize: 18,
        fontWeight: 600,
        color: 'white'
    },
    deleteIcon: {
        cursor: 'pointer',
        width: 16
    },
    saveBtnSection: {
        position: 'absolute',
        bottom: 0,
        right: 12,
        left: 'auto',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        background: '#f1f1f1'
    },
    workActionHead: {
        fontSize: 19,
        fontWeight: 600,
        marginRight: 10
    },
    workActionSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    editImage: {
        width: 10,
        height: 10,
        color: "#4A87F8",
    },
});

class WrapperComponentDialog extends React.Component {
    constructor(props) {
        super(props);
        this.reactFlowWrapper = React.createRef(null);
        this.state = {
            wrapperData: {},
            isEdit: false,
            name: '',
            isShow: true,
            elements: [],
            isShowMasterComponent: true,
            componentDetails: {},
            edgeDetails: {},
            isComponentEdit: false,
            updateComponentName: '',
            formComponentList: [],
            defaultWorkflow: false,
            selectedStatus: 1,
            popoverState: null,
            reactFlowInstance: null,
            selectedComponentName: ''
        }
        this.updateWrapperName = this.updateWrapperName.bind(this);
        this.onConnect = this.onConnect.bind(this);
        this.onElementsRemove = this.onElementsRemove.bind(this);
        this.onElementClick = this.onElementClick.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onNodeDragStop = this.onNodeDragStop.bind(this);
        this.dragComponent = this.dragComponent.bind(this);
        this.drop = this.drop.bind(this);
        this.allowDrop = this.allowDrop.bind(this);
        this.closeRightModal = this.closeRightModal.bind(this);
        this.removeComponent = this.removeComponent.bind(this);
        this.submitConfiguration = this.submitConfiguration.bind(this);
        this.removeAction = this.removeAction.bind(this);
        this.submitRule = this.submitRule.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.resetView = this.resetView.bind(this);
    }

    componentWillMount() {
        // console.log(this.props.wrapperData, 'wrapperData----00001');
        // console.log(this.props.wrapperDetails, 'wrapperData----00001');

        this.setState({
            wrapperData: this.props.wrapperData,
            name: this.props.wrapperDetails?.Name
        }, () => {
            this.props.workflowComponents();
            this.props.formComponent();
            this.props.getByIdOrgWorkflow(this.props.match.params.id);
            this.props.getWrapperWorkflowComponent(this.props.match.params.id, this.props.wrapperId);
            this.props.fetchOrgWorkflowAction(this.props.match.params.id, this.props.wrapperId);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.orgWorkFlowCompProgress === false && prevProps.orgWorkFlowCompProgress === true) {
            this.setElementToFlow();
            this.setState({
                isShow: true,
                isShowMasterComponent: true
            })
        }

        if (this.props.orgWorkFlowActionProgress === false && prevProps.orgWorkFlowActionProgress === true) {
            this.setElementToFlow();
        }

        if (this.props.addWrapperWorkFlowCompProgress === false && prevProps.addWrapperWorkFlowCompProgress === true) {
            this.props.showSnackBar("Component updated successfully", "success", 3000);
            this.props.getWrapperWorkflowComponent(this.props.match.params.id, this.props.wrapperId);
        }

        if (this.props.updateWrapperWorkFlowCompProgress === false && prevProps.updateWrapperWorkFlowCompProgress === true) {
            this.props.showSnackBar("Component updated successfully", "success", 3000);
            this.setState({
                isComponentEdit: false,
                isEdit: false
            })
            this.props.getWrapperWorkflowComponent(this.props.match.params.id, this.props.wrapperId);
        }

        if (this.props.deleteWrapperWorkFlowCompProgress === false && prevProps.deleteWrapperWorkFlowCompProgress === true) {
            this.props.showSnackBar("Component deleted successfully", "success", 3000);
            this.props.getWrapperWorkflowComponent(this.props.match.params.id, this.props.wrapperId);
            this.props.hideWrapperMasterComponentDialog();
        }

        if (this.props.fetchAllOrgSettingsInprogress === false && prevProps.fetchAllOrgSettingsInprogress === true) {
            let type = this.state.workflowDetails.Type === 1 ? 'EVALUATION' : 'REVIEW';
            this.setState({
                defaultWorkflow: false,
                selectedStatus: this.state.workflowDetails.Status
            })
            const defaultObj = this.props.fetchAllOrgSettingsSuccess.find((item) => item.SettingName === "ORG_DEFAULT_" + type + "_WORKFLOW");

            if (defaultObj && defaultObj?.Value && defaultObj?.Value !== 'NULL') {
                try {
                    this.setState({
                        defaultWorkflow: JSON.parse(defaultObj?.Value).wid === this.props.match.params.id
                    })
                } catch (e) {
                    console.warn(e);
                }
            }
        }

        if (this.props.addOrgWorkFlowActionProgress === false && prevProps.addOrgWorkFlowActionProgress === true) {
            this.props.fetchOrgWorkflowAction(this.props.match.params.id, this.props.wrapperId);
            if (this.props.addOrgWorkFlowActionSuccess?.OrgWorkflowActionRuleElements?.length) {
                this.resetView();
            } else {
                this.setState({
                    edgeDetails: { ...this.state.edgeDetails, ...this.props.addOrgWorkFlowActionSuccess, id: this.props.addOrgWorkFlowActionSuccess.ID }
                    // edgeDetails: {
                    //     id: this.props.addOrgWorkFlowActionSuccess?.ID,
                    //     addOrgWorkFlowActionSuccess: this.props.addOrgWorkFlowActionSuccess,
                    //     edgeDetails: this.state.edgeDetails,
                    // }
                })
            }
        }

        if (this.props.deleteOrgWorkFlowActionProgress === false && prevProps.deleteOrgWorkFlowActionProgress === true) {
            this.props.fetchOrgWorkflowAction(this.props.match.params.id, this.props.wrapperId);
            this.props.showSnackBar("Action deleted successfully", "success", 3000);
            this.resetView();
        }

        if (this.props.updateOrgWorkFlowActionProgress === false && prevProps.updateOrgWorkFlowActionProgress === true) {
            this.props.fetchOrgWorkflowAction(this.props.match.params.id, this.props.wrapperId);
            this.props.hideWrapperMasterComponentDialog();
            this.props.showSnackBar("Action updates successfully", "success", 3000);
            this.resetView();
        }

    }

    setElementToFlow() {
        const { wrapperWorkFlowCompList, orgWorkFlowActionList } = this.props;
        const componentEdges = orgWorkFlowActionList ? orgWorkFlowActionList.map((item, index) => {
            return {
                id: item.ID.toString(),
                source: item.SourceComponentID.toString(),
                target: item.TargetComponentID.toString(),
                type: 'step',
                arrowHeadType: 'arrowclosed',
                style: { stroke: '#3f4e6b' }
                // style: { stroke: edgesColors[parseInt(index.toString().slice(-1))] },
                // animated: true,
            }
        }) : [];

        const elementList = wrapperWorkFlowCompList ? wrapperWorkFlowCompList.map((item, index) => {
            let flowData = {};
            try {
                if (item.FlowData) {
                    flowData = JSON.parse(item.FlowData);
                }
            } catch (e) {
                console.warn(e);
            }
            const actionIdData = componentEdges.filter(o => parseInt(o.target) === parseInt(item.ID))
            const typeOfComponent = {};
            const dataObject = {};
            const masterObject = this.getMasterObjectFromId(item.ComponentID);
            let label = item.Name
            let dataObjects = {
                id: item.ID.toString(),
                data: { label, componentId: item.ComponentID.toString(), flowData: item.FlowData, configurations: item.OrgWorkflowComponentConfigurations, object: item, ...dataObject },
                position: flowData?.position ? flowData?.position : { x: 40, y: (index + 1) * 40 },
                ...typeOfComponent
            }
            if (masterObject?.Slug === "START") {
                typeOfComponent['type'] = 'input';
                dataObject['label'] = (
                  <div className="node-container-start">
                    <div className="node-body">
                      <p
                        className="node-text"
                        onClick={(e) => {
                          return '';
                          // e.stopPropagation()
                          // this.onElementClick(dataObjects)
                          // return true;
                        }}
                      >
                        {item.Name}
                      </p>
                    </div>
                  </div>
                )
              } else if (masterObject?.Slug === 'FINISH') {
                typeOfComponent['type'] = 'output';
                typeOfComponent['style'] = {
                    borderRadius: 100,
                };
                dataObject['label'] = (
                    <div className="node-container-finish">
                      <div className="node-body">
                        <p
                          className="node-text"
                          onClick={(e) => {
                            return ''
                          }}
                        >
                          {item.Name}
                        </p>
                      </div>
                    </div>
                  )
            }else if (masterObject?.Slug === 'STOP') {
                typeOfComponent['type'] = 'output';
                typeOfComponent['style'] = {
                    borderRadius: 100,
                };
                dataObject['label'] = (
                    <div className="node-container-finish">
                      <div className="node-body">
                        <p
                          className="node-text"
                          onClick={(e) => {
                            return ''
                          }}
                        >
                          {item.Name}
                        </p>
                      </div>
                    </div>
                  )
            }  else if (masterObject?.Slug === 'DECISION_COMPONENT') {
                typeOfComponent['style'] = {
                    borderColor: 'transparent',
                    boxShadow: 'none',
                    background: 'transparent',
                    height: 92
                };
                dataObject['label'] = (
                    <>
                        <div className="decision-component">{item.Name}</div>
                        <div className="decision-rectangle"></div>
                    </>
                )
            } else {
                dataObject['label'] = (
                    <div className="node-container">
                        <div className="node-header"
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        >
                            {actionIdData && actionIdData.length > 0 && (
                                <Tooltip title="Edit Rules">
                                    <EditIcon
                                        style={{ color: 'white', fontSize: 14, cursor: 'pointer' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            this.onElementClick(actionIdData[0])
                                        }}
                                    />
                                </Tooltip>
                            )}
                            <Tooltip title="Delete Node">
                                <DeleteIcon
                                    style={{ color: 'white', fontSize: 14, cursor: 'pointer' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.removeComponent(item.ID)
                                    }}
                                />
                            </Tooltip>
                        </div>
                        <div className="node-body">
                            <p
                                className="node-text"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    this.onElementClick(dataObjects)
                                    return true;
                                }}
                            >
                                {item.Name}
                            </p>
                        </div>
                    </div>
                )
            }

            return {
                ...dataObjects,
                data: {
                    ...dataObjects.data,
                    ...dataObject
                },
                position: flowData?.position ? flowData?.position : { x: 40, y: (index + 1) * 40 },
                ...typeOfComponent
            }
        }) : []


        this.setState({
            elements: [...elementList, ...componentEdges]
        })
    }

    updateWrapperName() {
        if (this.state.name) {
            const update = {
                name: this.state.name,
                flow_data: this.props.wrapperDetails?.FlowData,
                configurations: this.props.wrapperDetails?.OrgWorkflowComponentConfigurations || []
            };
            this.props.updateOrgWorkflowComponent(this.props.match.params.id, this.props.wrapperDetails.ID, update)
        } else {
            alert('Please enter component name');
        }
    }

    onConnect(data) {
        const nonAnimated = [];
        const masterComponentId = this.getMasterId(data.source);
        var els = this.state.elements;
        els.forEach((item) => {
            if (!item.animated) {
                nonAnimated.push(item)
            }
        });
        const masterObject = this.getMasterObjectFromId(masterComponentId);
        const orgActionsList = this.getWorkflowActionListFromSourceId(data.source);
        let addedEdge = nonAnimated;
        if ((orgActionsList?.length < masterObject?.MaxAction) || masterObject?.MaxAction === -1) {
            this.props.showWrapperMasterComponentDialog();
            this.setState({
                componentDetails: {}
            })

            const sourceObject = this.getComponentObjectFromId(data.source);
            const targetObject = this.getComponentObjectFromId(data.target);

            const edgeDetails = { ...data, sourceName: sourceObject?.Name, targetName: targetObject?.Name }

            this.setState({
                edgeDetails: edgeDetails
            })

            if (masterObject.MaxAction === 1 || masterObject.MaxAction === 0) {
                this.addEdgeToWorkflow(edgeDetails, []);
            } else {
                const componentFormList = this.getMasterFormComponent(masterComponentId, false);
                if (componentFormList.length === 0) {
                    this.addEdgeToWorkflow(edgeDetails, []);
                } else {
                    addedEdge = addEdge({ ...data, type: 'smoothstep', animated: true }, nonAnimated);
                }
            }
        } else {
            alert(`Can't create action. already created ${masterObject?.MaxAction} action(s)`, 'error', true);
        }

        this.setState({
            elements: addedEdge
        })
    }

    addEdgeToWorkflow = (data, rule) => {
        const addEdge = {
            source_component_id: parseInt(data.source),
            target_component_id: parseInt(data.target),
            action_data: '',
            seq: this.props.orgWorkFlowActionList?.length > 0 ? this.props.orgWorkFlowActionList?.length + 1 : 1,
            rules: rule,
            parent_component_id: this.props.wrapperId
        }
        this.props.addOrgWorkflowAction(this.props.match.params.id, addEdge);
    }

    onElementsRemove() {
        alert('onElementsRemove')

    }

    onElementClick = (element) => {
        this.props.hideWrapperMasterComponentDialog();
        if (element.data) {
            this.setState({
                componentDetails: element,
                selectedComponentName: element.data?.object?.Name,
                edgeDetails: {}
            })
            const masterComponentId = this.getMasterId(element.id);
            this.getMasterFormComponent(masterComponentId, true);
        } else if (element?.source) {
            const masterComponentId = this.getMasterId(element.source);
            const actionObject = this.getActionObjectFromId(element.id);
            const sourceObject = this.getComponentObjectFromId(element.source);
            const targetObject = this.getComponentObjectFromId(element.target);
            this.getMasterFormComponent(masterComponentId, false);
            this.setState({
                componentDetails: {},
                edgeDetails: {
                    ...element, ...actionObject, sourceName: sourceObject?.Name, targetName: targetObject?.Name
                }
            })
        }

        this.setState({
            isComponentEdit: false
        }, () => {
            this.props.showWrapperMasterComponentDialog();
        })
    }

    onLoad = (reactFlowInstance) => {
        reactFlowInstance.fitView()
        this.setState({
            reactFlowInstance: reactFlowInstance
        })
    }

    getComponentObjectById = (id) => {
        return this.props.wrapperWorkFlowCompList?.find((item) => item.ID === parseInt(id))
    }

    getOrgComponentObjectById = (id) => {
        return this.props.wrapperWorkFlowCompList?.find((item) => item.ID === parseInt(id))
    }

    getMasterObjectFromId = (id) => {
        return this.props.workflowComponent && this.props.workflowComponent.find((item) => item.ID === parseInt(id));
    }

    getWorkflowCompListFromCompId = (compId) => {
        return this.props.wrapperWorkFlowCompList && this.props.wrapperWorkFlowCompList.filter((item) => item.ComponentID === parseInt(compId));
    }

    getComponentObjectFromId = (id) => {
        return this.props.wrapperWorkFlowCompList && this.props.wrapperWorkFlowCompList.find((item) => item.ID === parseInt(id));
    }

    getMasterId = (sourceId) => {
        return this.state.elements.find(element => element.id === sourceId.toString())?.data?.componentId || '';
    }

    getWorkflowActionListFromSourceId = (sourceId) => {
        return this.props.orgWorkFlowActionList && this.props.orgWorkFlowActionList.filter((item) => item.SourceComponentID === parseInt(sourceId));
    }

    getMasterFormComponent = (masterComponentId, isConfiguration) => {
        const formCompList = [];
        this.props.workflowFormCompList.forEach((item) => {
            if (masterComponentId && parseInt(masterComponentId) === item.ComponentID && item.IsConfiguration === isConfiguration) {
                formCompList.push(item);
            }
        });
        this.setState({
            formComponentList: formCompList
        })
        return formCompList;
    }

    getActionObjectFromId = (id) => {
        return this.props.orgWorkFlowActionList && this.props.orgWorkFlowActionList.find((item) => item.ID === parseInt(id));
    }

    dragComponent = (ev, data) => {
        // console.log(data, 'Dragged Data')
        ev.dataTransfer.setData("text", ev.target.id);
        ev.dataTransfer.setData("data", JSON.stringify(data));
    }

    onNodeDragStop = (event, node) => {
        const componentObj = this.getComponentObjectById(node.id);
        if (componentObj) {
            const update = {
                name: componentObj.Name,
                flow_data: JSON.stringify({ position: node.position }),
                description: '',
                configurations: componentObj.OrgWorkflowComponentConfigurations
            };
            this.props.updateOrgWorkflowComponent(this.props.match.params.id, node.id, update);
        }
    }

    drop = (ev, node) => {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();

        const position = this.state.reactFlowInstance.project({
            x: ev.clientX - (reactFlowBounds.left + 130),
            y: ev.clientY - reactFlowBounds.top,
        });
        // const position = {
        //     x: ev.clientX - (reactFlowBounds.left + 145),
        //     y: ev.clientY - reactFlowBounds.top,
        // }

        var componentId = data.split('_')[1];
        if (componentId) {
            const masterObject = this.getMasterObjectFromId(componentId);
            const orgWorkflowAddedList = this.getWorkflowCompListFromCompId(componentId);
            if ((orgWorkflowAddedList?.length < masterObject?.MaxOccurrence) || masterObject?.MaxOccurrence === -1) {
                this.props.addWrapperWorkflowComponent(this.props.match.params.id, componentId, {
                    flow_data: JSON.stringify({ position }),
                    parent_component_id: this.props.wrapperId
                });
            } else {
                this.setState({
                    isShow: true
                })
                alert('This component is already added');
            }
        }
    }

    allowDrop = (ev) => {
        ev.preventDefault();
    }

    closeRightModal = () => {
        // dispatch(getWrapperWorkflowComponent(params.id));
        // dispatch(fetchOrgWorkflowAction(params.id))
        // dispatch(hideWrapperMasterComponentDialog());
        this.props.hideWrapperMasterComponentDialog();
    }

    removeComponent = (id) => {
        this.setState({
            isShow: false
        })
        if (id) {
            this.props.deleteOrgWorkflowComponent(this.props.match.params.id, id);
            this.props.hideWrapperMasterComponentDialog();
        }
    }

    removeAction = (actionId) => {
        this.props.deleteOrgWorkflowAction(this.props.match.params.id, actionId);
    }

    submitConfiguration = (configurations) => {
        if (this.state.formComponentList.length === 0) {
            this.resetView();
            this.props.hideWrapperMasterComponentDialog();
        } else {
            const update = {
                name: this.state.componentDetails?.data?.label,
                flow_data: this.state.componentDetails?.data?.flowData,
                configurations: configurations
            };
            this.props.updateOrgWorkflowComponent(this.props.match.params.id, this.state.componentDetails.id, update);
            this.props.hideWrapperMasterComponentDialog();
        }
    }

    submitRule(requestRule) {
        console.log(requestRule, 'Submit Function Call')
        if (this.state.formComponentList.length === 0) {
            this.resetView();
        } else {
            if (this.state.edgeDetails.id) {
                this.updateEdgeToWorkflow(this.state.edgeDetails.id, this.state.edgeDetails, requestRule)
            } else {
                this.addEdgeToWorkflow(this.state.edgeDetails, requestRule);
            }
        }
        this.props.hideWrapperMasterComponentDialog();
    }

    resetView() {
        this.setState({
            componentDetails: {},
            edgeDetails: {},
            formComponentList: [],
        }, () => {
            this.props.hideWrapperMasterComponentDialog();
        })
    }

    updateEdgeToWorkflow = (actionId, data, rule) => {
        console.log(rule, 'Ruleeee-Data-02')
        const addEdge = {
            source_component_id: parseInt(data.source),
            target_component_id: parseInt(data.target),
            action_data: '',
            seq: this.props.orgWorkFlowActionList?.length > 0 ? this.props.orgWorkFlowActionList?.length + 1 : 1,
            rules: rule
        }
        this.props.updateOrgWorkflowAction(this.props.match.params.id, actionId, addEdge);
    }

    updateComponent() {
        if (this.state.selectedComponentName) {
            const update = {
                name: this.state.selectedComponentName,
                flow_data: this.state.componentDetails?.data?.flowData,
                configurations: this.state.componentDetails?.data?.configurations || []
            };
            this.props.updateOrgWorkflowComponent(this.props.match.params.id, this.state.componentDetails.id, update)

        } else {
            alert('Please enter component name');
        }
    }

    edgeTypes = {
        buttonedge: (props) => ButtonEdge({ ...props }),
    };

    render() {
        const classes = this.props.classes;

        var { name, isEdit, isShow, elements,
            componentDetails, edgeDetails, selectedStatus,
            isComponentEdit, formComponentList, selectedComponentName } = this.state;

        // console.log(selectedComponentName, 'selectedComponentName')
        // console.log(edgeDetails, 'edgeDetails---------111111111111111111')
        // console.log(componentDetails, 'componentDetails---------111111111111111111')

        console.log(edgeDetails, 'EdgeDetails-Render')
        console.log(componentDetails, 'ComponentDetails-Render')

        const { workflowComponent, fetchWorkflowComponentProgress, isWrapperMasterComponentDialogOpen } = this.props;

        return <Dialog
            onClose={() => {
                this.closeRightModal()
                this.props.hideWrapperComponentDialog()
            }}
            aria-labelledby="add-teamDialog"
            open={this.props.isOpenDialog}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"xl"}
            scroll={"body"}
            id={"WrapperComponentDialog"}
        >

            <DialogContent classes={{ root: classes.dialogContent }}>
                <div className={classes.close} onClick={() => {
                    this.closeRightModal()
                    this.props.hideWrapperComponentDialog()
                }}><CloseIcon /></div>
                <div className={classes.secondarySection}>
                    {!isEdit && <span>{name}
                        <span onClick={() => {
                            this.setState({
                                isEdit: true
                            })
                        }}>
                            <Image src={editIcon} alt="editIcon" className={classes.editIconImage} /></span>
                    </span>}
                    {isEdit && <span>
                        <input
                            defaultValue={name}
                            onChange={(e) => {
                                this.setState({
                                    name: e.target.value
                                })
                            }}
                            className={classes.inputSection} />
                        <span onClick={() => { this.updateWrapperName() }}>
                            <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                        </span>
                    </span>}
                </div>
                <div className={classes.formBuilderMain}>
                    <Grid container>
                        <Grid item lg={2}>
                            <div className={classnames(classes.dragElement)}>
                                <div className={classes.componentTextAndImg}>
                                    <span className={classes.componentText}>Components</span>
                                </div>
                                <div className={classes.workflowList}>
                                    {
                                        !fetchWorkflowComponentProgress && workflowComponent.map((item, index) => {
                                            if (item.IsWrapper === true) {
                                                return '';
                                            }
                                            return <div id={'div_' + item.ID}
                                                key={index}
                                                draggable="true"
                                                className="draggable"
                                                onDragStart={(event, node) => this.dragComponent(event, { id: item.ID, data: { label: item.Name }, position: { x: 0, y: 0 } })}>
                                                {item.Name}
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={10}>
                            {<div className={classes.flowSection} id="dropSection" onDragOver={(event) => this.allowDrop(event)}>
                                {isShow && (
                                    <ReactFlowProvider>
                                        <div style={{ width: '100%', height: '100%' }} ref={this.reactFlowWrapper}>
                                            <ReactFlow
                                                elements={elements}
                                                onConnect={this.onConnect}
                                                onElementsRemove={this.onElementsRemove}
                                                onElementClick={() => { }}
                                                onLoad={this.onLoad}
                                                snapToGrid={true}
                                                snapGrid={[15, 15]}
                                                style={{ background: '#F8F7F7' }}
                                                onNodeDragStop={this.onNodeDragStop}
                                                onDrop={(event) => this.drop(event)}
                                                defaultZoom={1.5}
                                            >

                                                <Controls
                                                    onInteractiveChange={e => console.log(e)}
                                                />

                                                <Background color="#000" gap={30} />
                                            </ReactFlow>
                                        </div>
                                    </ReactFlowProvider>
                                )}
                                {
                                    !isShow && <div className={classes.loader}><CircularProgress /></div>
                                }
                            </div>}
                        </Grid>
                    </Grid>
                </div>
                {isWrapperMasterComponentDialogOpen && (
                    <div className={classes.elementDetails}>
                        <div className={classes.componentDetailsSection}>
                            <div className={classes.componentDetailsHeader}>
                                {componentDetails?.data && (
                                    <div className={classes.detailText}>
                                        Component Details {selectedStatus === 2 && <span> {selectedComponentName}</span>}
                                    </div>
                                )}
                                {edgeDetails?.source && edgeDetails.sourceName && edgeDetails.targetName && (
                                    <div className={classes.detailText}>
                                        Line from {edgeDetails.sourceName} to {edgeDetails.targetName}
                                    </div>
                                )}
                                <IconButton aria-label="delete" className={classes.closeIconSection} onClick={() => this.closeRightModal(componentDetails)}>
                                    <ClearIcon style={{ color: 'white' }} />
                                </IconButton>
                            </div>
                            {selectedStatus === 1 && (
                                <div className={classes.componentDetailsBodyHeader} onClick={() => {
                                    if (componentDetails?.data) this.removeComponent(componentDetails.id)
                                    else this.removeAction(edgeDetails.id)
                                }}>
                                    <Image src={deleteIcon} alt="deleteIcon" className={classes.deleteIcon} />
                                </div>
                            )}
                            {componentDetails?.data && (
                                <div className={classes.componentDetailsBody}>
                                    <div>
                                        {selectedStatus === 1 && !isComponentEdit && <span> {selectedComponentName}
                                            <span onClick={() => {
                                                this.setState({
                                                    isComponentEdit: true
                                                })
                                            }}>
                                                <Image src={editIcon} alt="editIcon" className={classes.titleImage} />
                                            </span>
                                        </span>}
                                        {isComponentEdit && <span>
                                            <input defaultValue={selectedComponentName}
                                                onChange={(e) => {
                                                    this.setState({
                                                        selectedComponentName: e.target.value
                                                    })
                                                }}
                                                className={classes.inputSection} />
                                            <span onClick={() => {
                                                this.updateComponent();
                                            }}>
                                                <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                                            </span></span>}
                                    </div>
                                    <div>
                                        <WorkFlowConfiguration
                                            workflowStatus={selectedStatus}
                                            submitData={this.submitConfiguration}
                                            configurations={componentDetails?.data?.configurations}
                                            formComponentList={formComponentList}
                                            componentDetails={componentDetails}
                                            orgWorkFlowCompList={this.props.wrapperWorkFlowCompList}
                                            workflowComponents={this.props.workflowComponent}
                                            workflowId={this.props.match.params.id}
                                            closeConfiguration={this.resetView}
                                        />
                                    </div>
                                </div>
                            )}
                            {edgeDetails?.source && (
                                <div className={classes.componentDetailsBody}>
                                    <WorkFlowRule
                                        workflowStatus={selectedStatus}
                                        formComponentList={formComponentList}
                                        orgWorkflowActionRuleElements={edgeDetails.OrgWorkflowActionRuleElements || []}
                                        submitData={this.submitRule} />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    }
}



export default connector(compose(
    withRouter,
    withStyles(styles)
)(WrapperComponentDialog));


const foreignObjectSize = 25;
export function ButtonEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId,
    classes,
    onClick,
    selectedStatus,
}) {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX + 1 - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <div className="body">
                    <button
                        className="edgebutton"
                    >
                        <Image src={editIcon} alt="editIcon" style={{
                            width: 10,
                            height: 10,
                            color: "#4A87F8"
                        }} />
                    </button>
                </div>
            </foreignObject>
        </>
    );
}
