import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";

// redux
import { fetchVendorCatalogViews, fetchServiceCatalogViews } from "redux/vendor/action";
import { fetchVisualizationVendorCatalog } from "redux/vendor/vendorCatalog/action";
import { fetchVendorServiceCatalog } from "redux/vendor/vendorServiceCatalog/action";
// Dilogs
import TableContent from "./Common/TableContent";

const connectedProps = (state) => ({});

const connectionActions = {
  fetchVendorCatalogViews: fetchVendorCatalogViews,
  fetchServiceCatalogViews: fetchServiceCatalogViews,
  fetchVisualizationVendorCatalog: fetchVisualizationVendorCatalog,
  fetchVendorServiceCatalog: fetchVendorServiceCatalog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class Vendors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageType: 0,
      pageSize: 5,
      sortField: "",
      sortType: "asc",
      isTabular: true,
    };

    this.updatePageAndSize = this.updatePageAndSize.bind(this);
    this.selectNewTab = this.selectNewTab.bind(this);
    this.updateSearchString = this.updateSearchString.bind(this);
    this.updateSorting = this.updateSorting.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    if (this.props.type === "vendor") {
      this.props.fetchVendorCatalogViews();
    } else {
      this.props.fetchServiceCatalogViews();
    }
  }

  componentDidUpdate(prevProps) {}

  getPageSortType() {
    let sortStr;
    const { sortField, sortType } = this.state;
    if (sortField?.length === 0) {
      return false;
    }
    if (sortType === "asc") {
      sortStr = sortField;
    } else {
      sortStr = "-" + sortField;
    }
    return sortStr;
  }

  fetchData = () => {
    let queryParams = {
      query: this.state.query,
      page_size: this.state.pageSize,
      page: this.state.page,
      filters: { is_org: "1" },
      tab_fields: true,
    };
    this.props.fetchVisualizationVendorCatalog(queryParams, this.props.Vid);
  };

  updatePageAndSize = (page, pageSize) => {
    this.setState(
      {
        page: page,
        pageSize: pageSize,
      },
      () => {
        this.fetchData();
      }
    );
  };

  updateSearchString = (page, pageSize, searchString) => {
    this.setState(
      {
        query: searchString,
        page: page,
        pageSize: pageSize,
        sortField: "",
        sortType: "asc",
      },
      () => {
        this.fetchData();
      }
    );
  };

  updateSorting(type, field) {
    this.setState(
      {
        page: 0,
        sortField: field,
        sortType: type,
      },
      () => {
        this.fetchData();
      }
    );
  }

  selectNewTab(newPageType) {
    this.setState(
      {
        page: 0,
        pageType: newPageType,
      },
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    return (
      <TableContent
        Vid={this.props.Vid}
        type={"vendor"}
        page={this.state.page}
        pageSize={5}
        searchQuery={this.state.query}
        sortField={this.state.sortField}
        sortType={this.state.sortType}
        pageType={this.state.pageType}
        defaultSelectedView={this.props.defaultSelectedView}
        onChangePage={(page, pageSize) => {
          this.updatePageAndSize(page, pageSize);
        }}
        onChangeSearch={(page, pageSize, searchString) => {
          this.updateSearchString(page, pageSize, searchString);
        }}
        onChangeSort={(type, field) => {
          this.updateSorting(type, field);
        }}
        fetchData={this.fetchData}
      />
    );
  }
}

Vendors.defaultProps = {
  type: "vendor",
};

export default connector(compose(withStyles(styles), withLastLocation)(Vendors));
