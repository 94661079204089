import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import CommonCss from 'commonCss';
import AppBarV2 from "../../../../AppBarV2";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import VendorDocumentTypes from './VendorDocumentTypes'
import ProductDocumentTypes from './ProductDocumentTypes'
import { isEnabledEvaluate } from "util/Common";
const connectedProps = (state) => ({
  orgUser: state.orgUser,
});

const connectionActions = {

};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: {
    ...CommonCss.rootContainer,
    padding: '2rem 2rem'
  }

});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class DocumentTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabValue: 0
    };
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search)
    if(params && (params.activeTab === 1 || params.activeTab === "1")){
      this.setState({activeTabValue: 1});
    }
  }

  componentDidUpdate() {

  }

  handleChange = (event, value) => {
    this.setState({activeTabValue: value});
    this.props.history.push(`/app/settings/configurations/document-types?activeTab=${value}`)
  };

  render() {
    const { classes } = this.props;
    const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'DisableCatalogFeature')

    return (
      <div className={classes.root}>
        <AppBarV2 title="Document Types" withBack={true} />
        <div className={classes.rootContainer}>
          {!DisableVendor && <Grid container justifyContent="space-between">
            <Grid item ></Grid>
            <Grid item >
              <Grid container alignItems="flex-end">
                <Tabs
                  value={this.state.activeTabValue}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  fullWidth
                  centered
                >
                  <Tab  value={0} label="Vendor"  {...a11yProps(0)} />
                  <Tab  value={1} label="Product" {...a11yProps(1)} />
                </Tabs>
              </Grid>
            </Grid>
            <Grid item></Grid>
          </Grid>}
          
          {!DisableVendor && <TabPanel value={this.state.activeTabValue} index={0} >
            <VendorDocumentTypes/>
          </TabPanel>}
          {!DisableVendor ? (<TabPanel value={this.state.activeTabValue} index={1} >
            <ProductDocumentTypes/>
          </TabPanel>) : <ProductDocumentTypes/> }
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(DocumentTypes));
