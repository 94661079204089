import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";

import OrgVendorCatalog from "./Components/Catalog/OrgVendorCatalog";
import OrgServiceCatalog from "./Components/Catalog/OrgServiceCatalog";
import AllVendors from "./Components/AllVendors";
import AllServices from "./Components/AllServices";
import CommonCss from "commonCss";
// import AllSoftwareProducts from "./Components/AllSoftwareProducts";
import OrgTabDetails from "./Components/Catalog/OrgTabDetails";
import ServiceDetails from "./Components/AllServices/ServiceDetails";
import VendorDetails from "./Components/AllVendors/VendorDetails";

const connectedProps = (state) => ({
  addProductManuallyDialogOpen: state.productsSettings.addProductManuallyDialogOpen,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
  });

class Vendor extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={`${this.props.match.path}/all-vendors`} component={AllVendors} />
          <Route exact path={`${this.props.match.path}/all-vendors/:id`} component={VendorDetails} />
          <Route exact path={`${this.props.match.path}/services`} component={AllServices} />
          <Route exact path={`${this.props.match.path}/:vendorId/service/:serviceId`} component={ServiceDetails} />
          <Route exact path={`${this.props.match.path}/org-vendors`} component={OrgVendorCatalog} />
          <Route exact path={`${this.props.match.path}/org-services`} component={OrgServiceCatalog} />
          <Route exact path={`${this.props.match.path}/org-vendor-details/:id`} render={(props) => <OrgTabDetails {...props} type={'vendor'} />} />
          <Route exact path={`${this.props.match.path}/org-service-details/:id`} render={(props) => <OrgTabDetails {...props} type={'service'} />} />
          <Route exact path={`${this.props.match.path}/org-vendor-service-details/:id`} render={(props) => <OrgTabDetails {...props} type={'vendorservice'} />} />
          {/* <Route exact path={`${this.props.match.path}/softwares`} component={AllSoftwareProducts} /> */}
        </Switch>
      </React.Fragment>
    );
  }
}

export default connector(compose(withStyles(styles))(Vendor));
