import { Axios } from "./index";
import * as Environment from "./../util/Environment";

export class ApplicationFactory{
    static _instance;

    static instance() {
        if (ApplicationFactory._instance == null) {
            ApplicationFactory._instance = new ApplicationFactory();
        }
        return ApplicationFactory._instance;
    }

    async fetch(page,pageSize,query){
        const api_server = Environment.api_host("USER");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/apps?page=${page}&pageSize=${pageSize}&query=${query}&t=${timestamp}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async fetchFormInput(appUrl){
        const timestamp     =   (new Date()).getTime();
        const url   =   `${appUrl}/integrationInput?t=${timestamp}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async updateFormInput(appUrl,data){
        const timestamp     =   (new Date()).getTime();
        const url   =   `${appUrl}/updateConfig?t=${timestamp}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.post(url,data, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getAppConfig(app){
        const timestamp     =   (new Date()).getTime();
        const url   =   `${app.AppLink}/getConfig?t=${timestamp}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [{data:response.data,app:app},null];
    }

    async removeApplication(app){
        const timestamp     =   (new Date()).getTime();
        const url   =   `${app.AppLink}/removeConfig?t=${timestamp}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.delete(url, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [{data:response.data,app:app},null];
    }
}