import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { showConfirmCallbackDialog, hideConfirmCallbackDialog } from "redux/applications/action";
import { DialogContent, Typography, Dialog, Button, Slide } from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    messageDialogOpen: state.applications.confirmMessageDialogOpen
});

const connectionActions = {
    showMessageDialog: showConfirmCallbackDialog,
    hideMessageDialog: hideConfirmCallbackDialog
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    button: {
        textAlign: 'right',
        marginBottom: 10
    },
    title: {
    },
    subHead: {
        fontSize: 15,
        fontWeight: 300
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
});

class ConfirmCallbackDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type_a: false,
            type_b: false,
        }

    }

    handleChange = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    render() {
        const classes = this.props.classes;

        return <Dialog
            onClose={this.props.hideMessageDialog}
            open={this.props.messageDialogOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll={"body"}
        >
            <DialogContent classes={{ root: classes.dialogContent }}>
                <div className={classes.mainContainer}>

                    {/* <div className={classes.close} onClick={() => this.props.closeDialog()}><CloseIcon /></div> */}
                    
                    <Typography variant={"h6"} className={classes.title}> {this.props.messageData?.head}</Typography>
                    <p className={classes.subHead}>{this.props.messageData?.subHead}</p>
                    {this.props.messageData?.firstBtn !== '' && this.props.messageData?.secondBtn !== '' && <div className={classes.button}>
                        <Button variant="outlined" color="secondary" className={classes.button} onClick={() => this.props.closeCallBack(this.props.messageData)}>{this.props.messageData?.firstBtn}</Button>
                        &nbsp;
                        &nbsp;
                        <Button variant="contained" color="secondary" className={classes.button} onClick={() => this.props.callBack(this.props.messageData)}>{this.props.messageData?.secondBtn}</Button>
                    </div>
                    }

                    {this.props.messageData?.firstBtn !== '' && this.props.messageData?.secondBtn === '' && <div className={classes.button}>
                        <Button variant="outlined" color="secondary" className={classes.button} onClick={() => this.props.closeCallBack(this.props.messageData)}>{this.props.messageData?.firstBtn}</Button>
                    </div>
                    }

                </div>

            </DialogContent>
        </Dialog>
    }
}
ConfirmCallbackDialog.defaultProps = {
    messageData: {
        head: '',
        subHead: '',
        firstBtn: '',
        secondBtn: ''
    },
    layout: 'default',
};

export default connector(compose(
    withStyles(styles)
)(ConfirmCallbackDialog));