import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Typography, Slide, Grid, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { withRouter } from 'react-router-dom';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { hideSendNotificationDialog, sendEvaluationEmail } from "redux/evaluation/metadata/action"
import SqureButton from 'Components/Common/SqureButton';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import * as Validator from "util/Validation";
import { showSnackBar } from "redux/snackbar/action";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    notificationEmail: state.evaluationMetaData.sendNotificationEmail,
    isOpen: state.evaluationMetaData.isOpenSendNotificationDialog,
    sendEmailProgress: state.evaluationMetaData.sendEmailProgress,
    errorSendEmail: state.evaluationMetaData.errorSendEmail,
    evaluation: state.evaluationMetaData.evaluation,
});

const connectionActions = {
    hideDialog: hideSendNotificationDialog,
    sendEvaluationEmail: sendEvaluationEmail,
    showSnackBar: showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    modalTitle: {
        fontSize: theme.spacing(2.5),
        marginBottom: theme.spacing(4),
    },
    actions: {
        float: 'right',
        marginBottom: 20
    },
    alert: {
        marginBottom: theme.spacing(1.6),
    }
});

class EmailDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            to: "",
            to_error: "",
            cc: "",
            cc_error: "",
            subject: "",
            subject_error: "",
            body: "",
            body_error: "",
        }

        this.submitResponse = this.submitResponse.bind(this);
    }

    componentDidMount() {
        if (this.props.notificationEmail) {
            console.log(this.props.notificationEmail)
            this.setState({ to: this.props.notificationEmail })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.sendEmailProgress === false && prevProps.sendEmailProgress === true) {
            if (this.props.errorSendEmail !== null) {
                var error = this.props.error;
                if (error.status === 400) {
                    var errors = error.errors;
                    var stateError = {
                        'to_error': "",
                        "cc_error": "",
                        "subject_error": "",
                        "body_error": "",
                    };
                    for (var field in errors) {
                        switch (field) {
                            case "to":
                                stateError.to_error = errors[field][0];
                                break;
                            case "cc":
                                stateError.cc_error = errors[field][0];
                                break;
                            case "subject":
                                stateError.subject_error = errors[field][0];
                                break;
                            case "body":
                                stateError.body_error = errors[field][0];
                                break;
                            default:
                        }
                    }
                    this.setState(stateError)
                } else {
                    this.setState({ error: error.message });
                }
            } else {
                this.props.showSnackBar("Notification successfully sent.", "success", 3000)
                this.props.hideDialog()
            }
        }
    }

    submitResponse() {
        this.setState({
            error: "",
            to_error: "",
            cc_error: "",
            subject_error: "",
            body_error: "",
        })

        var to = this.state.to;
        if (String(to).trim().length === 0) {
            this.setState({
                to_error: "Please enter Recipient Address(es)."
            })
            return
        }

        var toArray = to.split(",")
        for (var i = 0; i < toArray.length; i++) {
            if (Validator.validateEmail(String(toArray[i]).trim()) === false) {
                this.setState({ to_error: "Please provide valid email." });
                return;
            }
        }

        if (String(this.state.cc).trim().length > 0) {
            var ccArray = this.state.cc.split(",")
            for (var j = 0; j < ccArray.length; j++) {
                if (Validator.validateEmail(String(ccArray[j]).trim()) === false) {
                    this.setState({ cc_error: "Please provide valid email." });
                    return;
                }
            }
        }

        var subject = this.state.subject;
        if (String(subject).trim().length === 0) {
            this.setState({
                subject_error: "Please enter Subject."
            })
            return
        }

        var body = this.state.body;

        var bodyText = document.querySelectorAll("#emailComMessage .editor-editableField")[document.querySelectorAll("#emailComMessage .editor-editableField").length - 1].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")

        if (String(body).trim().length === 0 || String(bodyText).trim().length === 0) {
            this.setState({
                body_error: "Message should not be empty."
            })
            return
        }

        this.props.sendEvaluationEmail(0, {
            "to": to,
            "cc": this.state.cc,
            "subject": subject,
            "body": body
        })
    }

    render() {
        const classes = this.props.classes;
        console.log(this.props.isOpen, 'isOpen')
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-emailComponent"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
        >
            <DialogContent classes={{ root: classes.dialogAction }} >
                <div id="evSendNotificationDialog">
                    <Typography variant={"h2"} className={classes.modalTitle}>Send Notification</Typography>
                    <div className={classes.alert}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                    </div>
                    <Grid container>
                        <Grid item lg={12}>
                            <div className={classes.nameYour}>
                                <OutlinedInput
                                    label="To"
                                    error={this.state.to_error.length > 0}
                                    helperText={this.state.to_error}
                                    value={this.state.to}
                                    required={true}
                                    onChange={(event) => {
                                        this.setState({
                                            'to': event.target.value
                                        })
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item lg={12}>
                            <div className={classes.nameYour}>
                                <OutlinedInput
                                    label="CC"
                                    error={this.state.cc_error.length > 0}
                                    helperText={this.state.cc_error}
                                    value={this.state.cc}
                                    onChange={(event) => {
                                        this.setState({
                                            'cc': event.target.value
                                        })
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item lg={12}>
                            <div className={classes.nameYour}>
                                <OutlinedInput
                                    label="Subject"
                                    error={this.state.subject_error.length > 0}
                                    helperText={this.state.subject_error}
                                    value={this.state.subject}
                                    required={true}
                                    onChange={(event) => {
                                        this.setState({
                                            'subject': event.target.value
                                        })
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item lg={12} id='emailComMessage'>
                            <SlateInputField
                                label="Message"
                                required={true}
                                error={this.state.body_error.length > 0}
                                helperText={this.state.body_error}
                                folderPath={`/evaluation/${this.props?.evaluationId}/email`}
                                as={SlateInputField}
                                onChangeEvent={(value) => {
                                    this.setState({
                                        'body': value
                                    })
                                }}
                                initValue={this.state.body}
                                textContainerStyle={{ color: "#707070", }} />
                        </Grid>
                    </Grid>
                    {!this.props.sendEmailProgress && <div className={classes.actions}>
                        <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                        <SqureButton cls={classes.actionBtn} variant={"contained"}
                            onClick={this.submitResponse}>Send</SqureButton>
                    </div>}
                    {this.props.sendEmailProgress && <div className={classes.actions}> <CircularProgress /></div>}
                </div>
            </DialogContent>
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(EmailDialog));