import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { Search as SearchIcon } from "@material-ui/icons";

const styles = (theme) =>
  createStyles({
    searchInput: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 21,
      minHeight: 40,
      color: "#5F5F5F",
      width: 343,
      maxWidth: 343,
      paddingLeft: 43,
      fontSize: 13,
      "&:focus": {
        borderRadius: 21,
        outline: "none",
        border: "2px solid #4a88ff",
      },
    },
    search: {
      position: "relative",
      width: 300,
      "& [class*='MuiSvgIcon-root']": {
        position: "absolute",
        top: 8,
        left: 13,
        width: theme.spacing(2),
        height: theme.spacing(2),
        color: "#707070",
      },
    },
    searchBar: {
      position: 'relative',
      textAlign: 'left'
    }
  });

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
    };
  }
  searchData = (event) => {
    if(this.props.searchData) {
      this.props.searchData(event);
    }
  }

  render() {
    const classes = this.props.classes;
    return (
      <div className={classnames(classes.search, this.props.searchClass)}>
        <div className={classes.searchBar}>
          <SearchIcon className={classes.searchIcon} />
          <input
            className={classes.searchInput}
            id={"searchApplicationInput"}
            placeholder={this.props.placeholder}
            onChange={(event) => {
              this.setState({ query: event.target.value, page: 0 });
            }}
            value={this.state.query}
            onKeyUp={(event) => {
               this.searchData(event);
            }}
          />
        </div>
      </div>
    );
  }
}

SearchInput.defaultProps = {
  placeholder: "",
  searchClass: "",
};

SearchInput.propTypes = {};

export default compose(withRouter, withStyles(styles))(SearchInput);