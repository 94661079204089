import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Grid,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import SqureButton from "Components/Common/SqureButton";
import { withRouter } from "react-router-dom";
import ReactSelect from "react-select";
import classNames from "classnames";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CommonCss from "commonCss";

import {
  addTemplateWithCriteriaSelect,
  addCriteriaSelection,
} from "redux/templates/evaluation/action";
import { fetchSelectedTemplateCriteria } from "redux/templates/evaluation/org/action";
import { showSnackBar } from "redux/snackbar/action";
import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/delete.svg";

const connectedProps = (state) => ({
  criteriaSelection: state.templateEvaluation.criteriaSelection,
  addTemplateWithCriteriaSelectProgress:
    state.templateEvaluation.addTemplateWithCriteriaSelectProgress,
  orgTemplates: state.orgTemplateEvaluation.templates,
  selectedTemplate: state.orgTemplateEvaluation.selectedTemplate,
  selectedTemplateProg: state.orgTemplateEvaluation?.selectedTemplateProg,
  addTemplateWithCriteriaSelectError:
    state.templateEvaluation.addTemplateWithCriteriaSelectError,
});

const connectionActions = {
  addTemplateWithCriteriaSelect: addTemplateWithCriteriaSelect,
  showSnackBar: showSnackBar,
  addCriteriaSelection: addCriteriaSelection,
  fetchSelectedTemplateCriteria: fetchSelectedTemplateCriteria,
};

var connector = connect(connectedProps, connectionActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
      },
    },
    formGroup: {
      marginTop: 5,
      marginBottom: 16,
    },
    criteriContaier: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gridColumnGap: 10,
      marginTop: 20,
      gridAutoRows: "1fr",
      minHeight: "70vh",
      maxHeight: "70vh",
    },
    criteriaLeft: {
      padding: "0px 15px 5px 5px",
      borderRight: "1px solid lightgray",
      overflow: "auto",
    },
    criteriaRight: {
      padding: "0px 5px 5px 5px",
      overflow: "auto",
    },
    dflex: {
      margin: "10px 0px",
      border: "1px solid gray",
      width: "100%",
      display: "flex",
      alignItems: "center",
      borderRadius: 5,
      padding: 15,
      "& p": {
        margin: 0,
        padding: 0,
        lineHeight: 1,
      },
    },
    borderNone: {
      border: "none",
    },
    selecteAll: {
      border: "1px solid gray",
      borderRadius: 5,
      background: "#FFF",
    },
    draggable: {
      cursor: "grab",
      background: "#FFF",
    },
  });
class TemplateCreateMergeDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      showFullDialog: false,
      templatedata: null,
      draggedRequirement: {},
      listOfCriteria: [],
      draggingRequirement: null,
      dragedRequirementToCriteria: {},
      selecteAll: false,
    };
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTemplateProg && !this.props.selectedTemplateProg) {
      if (
        this.props?.selectedTemplate &&
        this.props.selectedTemplate?.Criterias
      ) {
        this.setState({
          draggedRequirement: {},
          listOfCriteria: this.props.selectedTemplate?.Criterias,
        });
      }
    }
    if (
      prevProps.addTemplateWithCriteriaSelectProgress &&
      !this.props.addTemplateWithCriteriaSelectProgress
    ) {
      if (this.props.addTemplateWithCriteriaSelectError == null) {
        this.props.handleOnClose();
        this.props.showSnackBar(
          `successfully ${
            this.props.isCreateNew ? "Created new" : "merged into"
          } template`,
          "success",
          3000
        );
        this.props.addCriteriaSelection(null, null, null, null, true);
        this.clearState();
      } else {
        let msg = "Something went wrong.";
        if (
          this.props.addTemplateWithCriteriaSelectError.message !== undefined
        ) {
          msg = `Failed to ${
            this.props.isCreateNew ? "Create new" : "merge into"
          } template.`;
        }
        this.props.showSnackBar(msg, "error", 3000);
      }
    }
  }

  onDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  onDrop = (criteriaID) => {
    const dragedRequirementToCriteria = this.state.dragedRequirementToCriteria;
    let criteria = dragedRequirementToCriteria[criteriaID];
    const reqIds = this.state.draggingRequirement
    console.log(reqIds);
    if (criteria) {
      criteria = criteria.concat(reqIds);
    } else {
      criteria = reqIds;
    }
    dragedRequirementToCriteria[criteriaID] = criteria;
    this.setState({
      dragedRequirementToCriteria,
      draggingRequirement: null,
    });
  };

  handleDeleteRequirement = (criteriaID, reqID) => {
    const dragedRequirementToCriteria = this.state.dragedRequirementToCriteria;
    dragedRequirementToCriteria[criteriaID] = dragedRequirementToCriteria[
      criteriaID
    ].filter((req) => req.id !== reqID);
    this.setState({
      dragedRequirementToCriteria,
    });
  };

  handleSubmit = () => {
    const {
      match,
      criteriaSelection,
      addTemplateWithCriteriaSelect,
      isCreateNew,
    } = this.props;
    const template =
      match.params.template === "custom" ? "custom_req" : "master_req";
    const data = {
      is_existing: !isCreateNew,
      [template]: [],
    };
    if (isCreateNew) {
      data.name = this.state.name;
      const keys = Object.keys(criteriaSelection);
      data[template] = keys.map((key) => {
        return {
          criteria_id: parseInt(key),
          requirement_ids: criteriaSelection[key].map((o) => o.id),
        };
      });
    } else {
      data.template_id = parseInt(this.state.templatedata?.value);
      var status = false;
      const keys = Object.keys(this.state.dragedRequirementToCriteria);
      if (keys.length > 0) {
        keys.every((element) => {
          if (
            this.state.dragedRequirementToCriteria[element] &&
            this.state.dragedRequirementToCriteria[element].length > 0
          ) {
            status = true;
            return false;
          } else return true;
        });
      }
      if (!status) {
        this.props.showSnackBar(
          "Atleast select single requirement",
          "error",
          3000
        );
        return true;
      }
      data[template] = [];
      keys.every((element) => {
        data[template].push({
          criteria_id: parseInt(element),
          requirement_ids: this.state.dragedRequirementToCriteria[element].map(
            (o) => parseInt(o.id)
          ),
        });
        return true;
      });
    }
    addTemplateWithCriteriaSelect(data);
  };

  fetchCriterias = () => {
    if (this.state.templatedata && this.state.templatedata?.value) {
      this.props.fetchSelectedTemplateCriteria(this.state.templatedata?.value);
    }
  };

  getOption = () => {
    const option = [];
    this.props.orgTemplates.every((o) => {
      if (parseInt(o.ID) !== parseInt(this.props.match.params.id)) {
        option.push({
          label: o.Name,
          value: o.ID,
        });
      }
      return true;
    });
    return option;
  };

  clearState = () => {
    this.setState({
      name: "",
      showFullDialog: false,
      templatedata: null,
      draggedRequirement: {},
      listOfCriteria: [],
      draggingRequirement: null,
      dragedRequirementToCriteria: {},
    });
  };

  getAllRequirementId = (crt) => {
    const rt = []
    Object.keys(crt).forEach((o) => crt[o].forEach((p) => rt.push(p)))
    return rt;
  };

  render() {
    const {
      isOpen,
      isCreateNew,
      handleOnClose,
      classes,
      criteriaSelection,
      addTemplateWithCriteriaSelectProgress,
    } = this.props;
    const { name, templatedata, showFullDialog } = this.state;
    return (
      <Dialog
        onClose={() => {
          this.clearState();
          handleOnClose();
        }}
        aria-labelledby="addtemplate"
        open={isOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={showFullDialog ? "lg" : "sm"}
        scroll="body"
        id="addtemplateDialog"
      >
        <DialogContent
          style={
            showFullDialog
              ? { minHeight: "80vh", overflow: "hidden !important" }
              : {}
          }
        >
          {isCreateNew ? (
            <div className={classes.formGroup}>
              <input
                className={classes.formControl}
                onChange={(e) => this.setState({ name: e.target?.value })}
                value={name}
                placeholder="Enter Template Name"
              />
            </div>
          ) : (
            <div>
              <Grid container direction="row-reverse">
                <Grid
                  item
                  xs={12}
                  sm={showFullDialog ? 6 : 12}
                  md={showFullDialog ? 4 : 12}
                  lg={showFullDialog ? 3 : 12}
                >
                  <ReactSelect
                    isSearchable={true}
                    menuPosition="fixed"
                    options={this.getOption()}
                    classNamePrefix="select"
                    placeholder="Search template"
                    onChange={(e) => {
                      this.setState(
                        {
                          templatedata: e,
                          listOfCriteria: [],
                          showFullDialog: true,
                        },
                        () => {
                          this.fetchCriterias();
                        }
                      );
                    }}
                    value={templatedata}
                  />
                </Grid>
              </Grid>
              {this.props.selectedTemplateProg && (
                <Grid container justifyContent="center" style={{ padding: 30 }}>
                  <CircularProgress />
                </Grid>
              )}
              {showFullDialog && !this.props.selectedTemplateProg && (
                <div className={classes.criteriContaier}>
                  <div className={classes.criteriaLeft}>
                    <Grid container alignItems="center" justifyContent="space-between">
                      <p>Selected Requirement</p>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.selecteAll}
                            onChange={(e) =>
                              this.setState({ selecteAll: e.target.checked })
                            }
                            name="selectAll"
                          />
                        }
                        label="Select All"
                      />
                    </Grid>
                    <div
                      className={
                        this.state.selecteAll ? classes.selecteAll : {}
                      }
                      id="all-requirement"
                      draggable={this.state.selecteAll}
                      onDragStart={() => {
                        if (this.state.selecteAll) {
                          this.setState({
                            draggingRequirement:
                              this.getAllRequirementId(criteriaSelection),
                          });
                        }
                      }}
                    >
                      {Object.keys(criteriaSelection).map((o) => (
                        <>
                          {criteriaSelection[o].map((p, index) => (
                            <div
                              className={classNames(
                                classes.dflex,
                                classes.draggable,
                                this.state.selecteAll ? classes.borderNone : {}
                              )}
                              draggable={!this.state.selecteAll}
                              onDragStart={() => {
                                if (!this.state.selecteAll) {
                                  this.setState({ draggingRequirement: [p] });
                                }
                              }}
                              id={p.id}
                              key={`requirement-${p.id}-${index}`}
                            >
                              <DragIndicatorIcon style={{ color: "gray" }} />
                              <p>{p.name}</p>
                            </div>
                          ))}
                        </>
                      ))}
                    </div>
                  </div>
                  <div className={classes.criteriaRight}>
                    <p style={{ marginBottom: 25 }}>
                      Selected Template Criterias
                    </p>
                    {this.state.listOfCriteria.map((o, index) => (
                      <div
                        className={classes.dflex}
                        id={o.ID}
                        key={`criteria-${o.ID}-${o.Name}-${index}`}
                        onDrop={() => this.onDrop(o.ID)}
                        onDragOver={this.onDragOver}
                      >
                        <div style={{ flex: 1 }}>
                          <p>{o.Name}</p>
                          {this.state.dragedRequirementToCriteria[o.ID] !==
                            undefined &&
                            this.state.dragedRequirementToCriteria[o.ID].map(
                              (req, index) => (
                                <div
                                  className={classNames(classes.dflex)}
                                  style={{ justifyContent: "space-between" }}
                                  id={`req_${req.id}`}
                                  key={`dragged-requirement-${req.id}-${index}`}
                                >
                                  <div>
                                    <p>{req.name}</p>
                                  </div>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.handleDeleteRequirement(o.ID, req.id)
                                    }
                                  >
                                    <Image alt="Delete" src={deleteIcon} />
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {addTemplateWithCriteriaSelectProgress ? (
            <CircularProgress />
          ) : (
            <>
              <SqureButton
                variant={"outlined"}
                onClick={this.handleSubmit}
                disabled={isCreateNew ? !name : false}
              >
                {isCreateNew ? "Create New" : "Merge"}
              </SqureButton>
              &nbsp;&nbsp;
              <SqureButton
                variant={"contained"}
                onClick={() => {
                  this.clearState();
                  handleOnClose();
                }}
              >
                Cancel
              </SqureButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(
  compose(withRouter, withStyles(styles))(TemplateCreateMergeDialog)
);
