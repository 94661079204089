import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from "react-router-dom";
// import Metrics from "./Metrics";
import MetricsV2 from "./Metricsv2"
import MetricVendor from "./MetricVendor";
import {updateDrawerStyle} from "../../../../redux/drawer/action";

const connectedProps = (state) => ({

});

const connectionActions = {
    updateDrawerStyle:updateDrawerStyle
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
});

class MetricsIndex extends React.Component {
    componentDidMount(){
        this.props.updateDrawerStyle(true,true)
    }
    render() {
        return <Switch>
            <Route exact path={this.props.match.path} component={MetricsV2} />
            <Route exact path={`${this.props.match.path}/vendor/:vendor_id`} component={MetricVendor} />
        </Switch>
    }
}

export default connector(compose(
    withStyles(styles)
)(MetricsIndex));