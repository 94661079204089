import {
    START_AUTH, END_AUTH,
    START_ENABLE_OKTA_SSO, END_ENABLE_OKTA_SSO,
    START_UPDATE_OKTA_SSO_IDP, END_UPDATE_OKTA_SSO_IDP,
    START_DISABLE_OKTA_SSO, END_DISABLE_OKTA_SSO,
    START_ENABLE_AZURE_SSO, END_ENABLE_AZURE_SSO,
    START_UPDATE_AZURE_SSO_IDP, END_UPDATE_AZURE_SSO_IDP,
    START_DISABLE_AZURE_SSO, END_DISABLE_AZURE_SSO,
    START_ENABLE_SLACK_NOTIFICATION, END_ENABLE_SLACK_NOTIFICATION,
    START_GET_ENABLED_COGNITO_SSO_APPS, END_GET_ENABLED_COGNITO_SSO_APPS,
    START_SET_CURRENT_ORG, END_SET_CURRENT_ORG,
    START_GET_SLACK_CHANNELS, END_GET_SLACK_CHANNELS,
    START_GET_SLACK_STATE_CODE, END_GET_SLACK_STATE_CODE,
    START_SET_SLACK_NOTIFICATION_CHANNELS, END_SET_SLACK_NOTIFICATION_CHANNELS,
    START_UNINSTALL_SLACK, END_UNINSTALL_SLACK,
    START_GET_NOTIFICATION_TYPE, END_GET_NOTIFICATION_TYPE,
    START_GET_NOTIFICATION_SETTINGS, END_GET_NOTIFICATION_SETTINGS,
    END_DELETE_NOTIFICATION_SETTINGS, START_DELETE_NOTIFICATION_SETTINGS,
    START_USER_SSO_LOGIN, END_USER_SSO_LOGIN,
    SHOW_ASK_LOGIN_PLATFORM_DIALOG, HIDE_ASK_LOGIN_PLATFORM_DIALOG, START_INSTALL_SLACK, END_INSTALL_SLACK,
    SET_LOGOUT_FROM_FLAG
} from "./action";

const initState = {
    authProgress: false,
    authError: null,
    user: null,
    oktaSSOEnableProgress: false,
    oktaSSOEnableError: null,
    oktaSSODisableProgress: false,
    oktaSSODisableError: null,
    azureSSOEnableProgress: false,
    azureSSOEnableError: null,
    azureSSODisableProgress: false,
    azureSSODisableError: null,
    slackNotificationEnableData: null,
    slackNotificationEnableProgress: false,
    slackNotificationEnableError: null,
    enabledCognitoSSOAppsData: [],
    enabledCognitoSSOAppsProgress: true,
    setCurrentOrgData: null,
    setCurrentOrgProgress: false,
    setCurrentOrgError: null,
    slackChannels: {},
    slackChannelsProgress: false,
    slackChannelsError: null,
    slackStateCode: false,
    slackStateCodeProgress: false,
    slackStateCodeError: null,
    slackSetNotificationChannelsProgress: false,
    slackSetNotificationChannelsError: null,
    unInstallSlackProgress: false,
    unInstallSlackError: false,
    installSlackProgress: false,
    installSlackError: null,
    installedData: false,
    getNotificationType: [],
    getNotificationProgress: false,
    getNotificationSettings: [],
    getNotificationSettingsProgress: false,
    deleteNotificationProgress: false,
    userSSOLoginProgress: false,
    userSSOLoginData: null,
    askLoginPlatformDialogOpen: false,
    logoutFromPage:null
}

export function authUserReducer(state = initState, action) {
    switch (action.type) {
        case START_AUTH:
            return {
                ...state,
                authProgress: true,
                authError: null
            };
        case END_AUTH:
            return {
                ...state,
                authProgress: false,
                authError: action.payload.error,
                user: action.payload.success
            }
        case START_ENABLE_OKTA_SSO:
        case START_UPDATE_OKTA_SSO_IDP:
            return {
                ...state,
                oktaSSOEnableProgress: true,
            }
        case END_ENABLE_OKTA_SSO:
        case END_UPDATE_OKTA_SSO_IDP:
            var _user = state.user
            if (action.payload.success !== null) {
                _user.Organization = action.payload.success
            }
            return {
                ...state,
                oktaSSOEnableProgress: false,
                oktaSSOEnableError: action.payload.error,
                user: _user
            }
        case START_DISABLE_OKTA_SSO:
            return {
                ...state,
                oktaSSODisableProgress: true,
            }
        case END_DISABLE_OKTA_SSO:
            var _user = state.user
            if (action.payload.success !== null) {
                _user.Organization = action.payload.success
            }
            return {
                ...state,
                oktaSSODisableProgress: false,
                oktaSSODisableError: action.payload.error,
                user: _user
            }
        case START_ENABLE_AZURE_SSO:
        case START_UPDATE_AZURE_SSO_IDP:
            return {
                ...state,
                azureSSOEnableProgress: true,
            }
        case END_ENABLE_AZURE_SSO:
        case END_UPDATE_AZURE_SSO_IDP:
            var _user = state.user
            if (action.payload.success !== null) {
                _user.Organization = action.payload.success
            }
            return {
                ...state,
                azureSSOEnableProgress: false,
                azureSSOEnableError: action.payload.error,
                user: _user
            }
        case START_DISABLE_AZURE_SSO:
            return {
                ...state,
                azureSSODisableProgress: true,
            }
        case END_DISABLE_AZURE_SSO:
            var _user = state.user
            if (action.payload.success !== null) {
                _user.Organization = action.payload.success
            }
            return {
                ...state,
                azureSSODisableProgress: false,
                azureSSODisableError: action.payload.error,
                user: _user
            }
        case START_ENABLE_SLACK_NOTIFICATION:
            return {
                ...state,
                slackNotificationEnableData: null,
                slackNotificationEnableProgress: true,
                slackNotificationEnableError: null
            }
        case END_ENABLE_SLACK_NOTIFICATION:
            const { success, error } = action.payload
            let slackNotificationEnableData = null
            if (success !== null) {
                slackNotificationEnableData = success
            }
            return {
                ...state,
                slackNotificationEnableData,
                slackNotificationEnableProgress: false,
                slackNotificationEnableError: error
            }

        case START_GET_ENABLED_COGNITO_SSO_APPS:
            return {
                ...state,
                enabledCognitoSSOAppsData: [],
                enabledCognitoSSOAppsProgress: true,
            }
        case END_GET_ENABLED_COGNITO_SSO_APPS:
            let enabledCognitoSSOAppsData = []
            if (action.payload.success !== null) {
                enabledCognitoSSOAppsData = action.payload.success
            }
            return {
                ...state,
                enabledCognitoSSOAppsData,
                enabledCognitoSSOAppsProgress: false,
            }
        case START_SET_CURRENT_ORG:
            return {
                ...state,
                setCurrentOrgData: null,
                setCurrentOrgProgress: true,
                setCurrentOrgError: null
            }
        case END_SET_CURRENT_ORG:
            let setCurrentOrgData = null
            if (action.payload?.success !== null) {
                setCurrentOrgData = action.payload.success
            }
            return {
                ...state,
                setCurrentOrgData,
                setCurrentOrgProgress: false,
                setCurrentOrgError: action.payload.error
            }
            
        case START_GET_SLACK_CHANNELS:
            return {
                ...state,
                slackChannels: {},
                slackChannelsProgress: true,
                slackChannelsError: null,
            }

        case END_GET_SLACK_CHANNELS: {
            const { success, error } = action.payload
            let slackChannels = {}
            if (error === null && success?.data) {
                slackChannels = success?.data
            }
            return {
                ...state,
                slackChannels,
                slackChannelsProgress: false,
                slackChannelsError: error
            }
        }

        case START_GET_SLACK_STATE_CODE:
            return {
                ...state,
                slackStateCode: state.slackStateCode,
                slackStateCodeProgress: true,
                slackStateCodeError: null,
            }

        case END_GET_SLACK_STATE_CODE: {
            const { success, error } = action.payload
            let slackStateCode = state.slackStateCode
            if (error === null) {
                slackStateCode = success.data
            }
            return {
                ...state,
                slackStateCode,
                slackStateCodeProgress: false,
                slackStateCodeError: error
            }
        }

        case START_SET_SLACK_NOTIFICATION_CHANNELS:
            return {
                ...state,
                slackSetNotificationChannelsProgress: true,
                slackSetNotificationChannelsError: null
            }

        case END_SET_SLACK_NOTIFICATION_CHANNELS: {
            const { error } = action.payload
            return {
                ...state,
                slackSetNotificationChannelsProgress: false,
                slackSetNotificationChannelsError: error
            }
        }

        case START_UNINSTALL_SLACK:
            return {
                ...state,
                unInstallSlackProgress: true,
                unInstallSlackError: null
            }

        case END_UNINSTALL_SLACK: {
            let slackStateCode = state.slackStateCode
            if(action.payload.error === null) {
                slackStateCode = false
            }
            return {
                ...state,
                slackStateCode,
                unInstallSlackProgress: false,
                unInstallSlackError: action.payload.error
            }
        }

        case START_INSTALL_SLACK:
            return {
                ...state,
                installSlackProgress: true,
                installSlackError: null
            }

        case END_INSTALL_SLACK: {
            let installedData = state.installedData
            if(action.payload.error === null) {
                installedData = action.payload.success
            }
            return {
                ...state,
                installedData,
                installSlackProgress: false,
                installSlackError: action.payload.error
            }
        }

        case START_GET_NOTIFICATION_TYPE:
            const getNotificationType = state.getNotificationType
            return {
                ...state,
                getNotificationType,
                getNotificationProgress: true
            }

        case END_GET_NOTIFICATION_TYPE: {
            const { error, success } = action.payload
            let getNotificationType = state.getNotificationType
            if (error === null) {
                getNotificationType = success.data
            }
            return {
                ...state,
                getNotificationType,
                getNotificationProgress: false
            }
        }

        case START_GET_NOTIFICATION_SETTINGS:
            return {
                ...state,
                getNotificationSettings: [],
                getNotificationSettingsProgress: true
            }

        case END_GET_NOTIFICATION_SETTINGS: {
            const { error, success } = action.payload
            let getNotificationSettings = state.getNotificationSettings
            if (error === null) {
                getNotificationSettings = success.data
            }
            return {
                ...state,
                getNotificationSettings,
                getNotificationSettingsProgress: false
            }
        }

        case START_DELETE_NOTIFICATION_SETTINGS:
            return {
                ...state,
                deleteNotificationProgress: true,
            }

        case END_DELETE_NOTIFICATION_SETTINGS: {
            return {
                ...state,
                deleteNotificationProgress: false
            }
        }

        case START_USER_SSO_LOGIN:
            return {
                ...state,
                userSSOLoginProgress: true,
                userSSOLoginData: null
            }

        case END_USER_SSO_LOGIN: {
            return {
                ...state,
                userSSOLoginProgress: false,
                userSSOLoginData: action.payload.success
            }
        }

        case SHOW_ASK_LOGIN_PLATFORM_DIALOG:
            return {
                ...state,
                askLoginPlatformDialogOpen: true,
            }

        case HIDE_ASK_LOGIN_PLATFORM_DIALOG:
            return {
                ...state,
                askLoginPlatformDialogOpen: false,
            }
        
        case SET_LOGOUT_FROM_FLAG:
            return{
                ...state,
                logoutFromPage:true
            }

        default:
            return state;
    }
}