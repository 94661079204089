import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import TopBar from "./topBar";
import { profileMenuNameMap } from "util/CompanyProfile";
import Section from "./common/section";
import { Divider } from "@material-ui/core";

const connectedProps = () => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: "24px 28px 60px",
      maxHeight: "85vh",
      overflow: "auto",
    },
  });

function CompanyProfileRenderer(props) {
  const { storeDataSuccess } = useSelector((state) => state.companyProfile);
  const { classes, isEdit, activeMenu, error } = props;
  const [data, setData] = useState(
    storeDataSuccess?.[activeMenu] ? storeDataSuccess?.[activeMenu] : []
  );

  useEffect(() => {
    setData(storeDataSuccess?.[activeMenu] ? storeDataSuccess?.[activeMenu] : []);
  }, [storeDataSuccess, activeMenu]);

  return (
    <div className={classes.root}>
      <TopBar
        label={
          profileMenuNameMap[activeMenu]
            ? profileMenuNameMap[activeMenu]
            : "Company Profile"
        }
        activeMenu={activeMenu}
        error={error}
        isEdit={isEdit}
        handleClick={(type) => {
          props.handleClickActionButton(type);
        }}
      />
      {data &&
        data.map((section, index) => (
          <React.Fragment key={'sectionn_' + index}>
            <Section
              activeMenu={activeMenu}
              section={section}
              isEdit={isEdit}
            />
            <Divider
              orientation="horizontal"
              style={{
                marginTop: "24px",
                marginBottom: "24px",
              }}
            />
          </React.Fragment>
        ))}
    </div>
  );
}

export default connector(
  compose(withRouter, withStyles(styles))(CompanyProfileRenderer)
);
