import { Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import DefaultImage from "assets/images/evaluation/default.svg";
import classnames from "classnames";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { updateDrawerStyle } from "redux/drawer/action";
import claimProductImg from "assets/images/product_add.svg";

// Redux
import { fetchSellerProducts } from "redux/seller/product/action";

import AppBarV2 from "../../../../../Application/AppBarV2";
import CommonCss from "commonCss";
import { showClaimProductDialog } from "redux/seller/product/action";
import EntityCard from "./EntityCard";

const connectedProps = (state) => ({
  categoryFetchProgress: state.productCategory.fetchCategoryProgress,
  categories: state.productCategory.categories,
  totalCategories: state.productCategory.totalCategories,
  user: state.authUser.user,
  orgUser: state.orgUser,
  userType: state.authUser.user?.Role,
  sellerProductProg: state.sellerProduct.sellerProductProg,
  sellerProducts: state.sellerProduct.sellerProducts,
  expentDirectoryProducts: state.expentDirectory.products,
  claimProductProg: state.sellerProduct.claimProductProg,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  showClaimProductDialog: showClaimProductDialog,
  fetchSellerProducts: fetchSellerProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    head: {
      position: 'relative'
    },
    topContainer: {
      marginTop: theme.spacing(0),
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
    },
    title: {
      fontSize: theme.spacing(2.4),
    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 18,
      margin: "0px !important",
    },

    productTitleSection: {
      ...CommonCss.productTitleSection,
      marginTop: 30,
      marginBottom: 20,
    },
    cartSection: {
      "& [class*=Cart-root], #cartSection": {
        transition: "all 400ms linear",
        padding: 21,
        right: -300,
        "& *": {
          opacity: 0,
          transition: "all 600ms ease-in",
          // whiteSpace: 'nowrap'
        },
      },
      "&.showCart": {
        "& [class*=Cart-root], #cartSection": {
          right: "0 !important",
          "& *": {
            opacity: 1,
          },
        },
      },
    },
    skeleton: {
      height: 215,
      width: "100%",
      borderRadius: 10,
    },
    LinkTag: {
      textDecoration: "none",
      color: "unset",
    },
    topHead: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 40,
      marginBottom: 8,
      "& > .appBar": {
        flex: 1,
      },
    },
    actionContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 175,
      marginTop: 10,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    newProduct: {
      fontWeight: 600,
      color: '#282D30',
      fontSize: '1.45em',
      marginBottom: 0
    },
    productSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // position: 'absolute',
      width: '100%',
      margin: '20px 0px'
    },
    recommendProduct: {
      position: 'relative',
      "& .layer-card-title": {
        padding: 0,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
      }
    },
    myProductCard: {
      "& .layer-card-title": {
        padding: 0,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
      }
    },
    beta: {
      position: 'absolute',
      // top: 46,
      right: 16,
      zIndex: 30
    },
    rootContainer: CommonCss.rootContainer
  });

class ProductsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCartShow: false,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(preProps) {
    if (!this.props.claimProductProg && preProps.claimProductProg) {
      this.props.fetchSellerProducts([1, 2], { page: 0, pageSize: 20, query: "" });
    }
  }

  fetchData() {
    this.props.fetchSellerProducts([1, 2], { page: 0, pageSize: 20, query: "" });
  }

  renderSkeleton = () => {
    return (
      <>
        {[1, 2, 3, 4, 5, 6].map((value, k) => (
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2} key={k}>
            <Skeleton variant="rect" className={this.props.classes.skeleton} />
          </Grid>
        ))};
      </>
    )
  }

  render() {
    const classes = this.props.classes;


    // let otherProducts = this.props.expentDirectoryProducts?.others?.items || [];
    // let otherProductsCount = this.props.expentDirectoryProducts?.others?.total || 0;
    // if (this.props.sellerProductProg) {
    //   return <div style={{ textAlign: 'center', marginTop: 20 }}>
    //     <CircularProgress />
    //   </div>
    // }

    return (
      <div className={classes.head}>
        <AppBarV2 title="Products" />
        <div className={classes.rootContainer}>
          <div className={classes.bottomContainer}>
            <div className={classes.productTitleSection}>
              <Link
                to="/sellerapp/products/my-products"
                className={classnames(classes.viewAll, classes.productHead)}
                
              >
                <Typography variant="h6" style={{color: "#003b4d99",textTransform: "uppercase",  fontWeight: "500"}}>My Products</Typography>
              </Link>
              {!this.props.sellerProductProg && this.props.sellerProducts?.total > 5 && (
                <Link
                  to="/sellerapp/products/my-products"
                  className={classes.viewAll}
                >
                  View all ({this.props.sellerProducts?.total.toLocaleString("en-US")})
                </Link>
              )}
            </div>

            <div className={classes.cardSection}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2} key={'viewMoreProduct'}>
                  <LayeredCard
                    title={"+ Claim Product"}
                    image={claimProductImg}
                    subTitle=""
                    color="red"
                    onClick={() => {
                      this.props.showClaimProductDialog()
                    }}
                  />
                  
                </Grid>
                {!this.props.sellerProductProg && this.props.sellerProducts?.Data && this.props.sellerProducts?.Data.slice(0, 17).map((product, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={2} xl={2} key={index}>
                    {/* <LayeredCard
                      title={product.Name}
                      subTitle={product.CompanyName}
                      description={product.Description}
                      image={
                        product.ProductLogo == null ||
                          product.ProductLogo.length === 0
                          ? DefaultImage
                          : product.ProductLogo
                      }
                      toogleCheck={() => { }}
                      onClick={() => { }}
                      numLayer={0}
                      id={product.ID}
                      uniqueId={product.ID + "_my_product_" + index}
                      type={"product"}
                      source={"SELLER"}
                      customRoute={"/sellerapp/products/product-details/"}
                      productStates={product?.ClaimStatus && product?.ClaimStatus === 1 ? [{ StateName: 'pending', StateID: product.ID }] : ''}
                    /> */}

                    <EntityCard  handleClick={()=>{window.location.href = "/sellerapp/products/product-details/" + product.ID;}}
                          logo={product.ProductLogo == null ||
                          product.ProductLogo.length === 0
                          ? DefaultImage
                          : product.ProductLogo}
                          handleClickInfo={()=>{}}
                          title={product.Name}
                          subTitle={product.CompanyName}
                          description={product.Description}
                          numLayer={0}
                          id={product.ID}
                          uniqueId={product.ID + "_my_product_" + index}
                          type={"product"}
                          source={"SELLER"}
                          customRoute={"/sellerapp/products/product-details/"}
                          productStates={product?.ClaimStatus && product?.ClaimStatus === 1 ? [{ StateName: 'pending', StateID: product.ID }] : ''}
                          />
                  </Grid>
                ))}

                {this.props.sellerProductProg && <>
                  {[1, 2, 3, 4, 5, 6,7,8,9,10,11,12,13,14,15,16,17,18].map((value, k) => (
                    <Grid item xs={12} sm={6} md={3} lg={2} xl={2} key={k}>
                      <Skeleton variant="rect" className={classes.skeleton} />
                    </Grid>
                  ))};
                </>}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(ProductsHome));
