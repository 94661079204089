import {
  START_NOTIFICATION_N_FETCH, END_NOTIFICATION_N_FETCH,
  START_NOTIFICATION_N_MARKREAD, END_NOTIFICATION_N_MARKREAD, END_NOTIFICATION_N_DELETE,
  START_NOTIFICATION_All_MARKREAD, END_NOTIFICATION_All_MARKREAD,
  ADD_NEW_NOTIFICATION,
  SHOW_NUDGE_NOTIFICATION_DIALOG, HIDE_NUDGE_NOTIFICATION_DIALOG,
  START_NOTIFICATION_ADD, END_NOTIFICATION_ADD
} from "./action";

const initState = {
  fetchNotificationProgress: false,
  fetchNotificationError: null,
  notifications: [],
  totalNotifications: 0,
  markAsReadProgress: false,
  allMarkAsReadProgress: false,

  toastNotificationCount: 0,
  toastNotification: {},

  forUser: null,
  showNudgeDialog: false,
  nudgeData: null,

  addNotificationProgress: false,
  addNotificationError: null,
}

export function notificationsReducers(state = initState, action) {
  switch (action.type) {
    case SHOW_NUDGE_NOTIFICATION_DIALOG:
      return {
        ...state,
        forUser: action.payload.user,
        showNudgeDialog: true,
        nudgeData: action.payload.data,
      }

    case HIDE_NUDGE_NOTIFICATION_DIALOG:
      return {
        ...state,
        forUser: null,
        showNudgeDialog: false,
        nudgeData: null,
      }

    case START_NOTIFICATION_N_FETCH:
      var notifications = state.notifications;
      var totalNotifications = state.totalNotifications;
      if (action.payload === 0) {
        notifications = [];
        totalNotifications = 0;
      }
      return {
        ...state,
        fetchNotificationProgress: true,
        notifications: notifications,
        totalNotifications: totalNotifications,
        fetchNotificationError: null
      };

    case END_NOTIFICATION_N_FETCH:
      var success = action.payload.success;
      var notifications_Data = state.notifications;
      var totalNotificationsNew = state.totalNotifications;
      if (success !== null) {
        var Page = success.page;
        var Data = success.Data;
        totalNotificationsNew = success.total;
        if (Page === 0) {
          notifications_Data = Data;
        } else {
          notifications_Data = notifications_Data.concat(Data)
        }
      }
      return {
        ...state,
        fetchNotificationProgress: false,
        notifications: notifications_Data,
        totalNotifications: totalNotificationsNew,
        fetchNotificationError: action.payload.error
      }

    case END_NOTIFICATION_N_DELETE:
      var notificationId = action.payload.success;
      var _notifications = [];
      state.notifications.forEach((n) => {
        if (n.ID !== notificationId) {
          _notifications.push(n)
        }
      });
      return {
        notifications: _notifications,
        totalNotifications: (state.totalNotifications - 1)
      }

    case START_NOTIFICATION_N_MARKREAD:
      return {
        ...state,
        markAsReadProgress: true,
        markAsReadError: null
      }

    case END_NOTIFICATION_N_MARKREAD:
      // var notificationsDatas = state.notifications;
      var errorMarkRead = action.payload.error;
      // var notificationIdRead = action.payload.notificationId;
      // if(errorMarkRead){
      //   var notification = notificationsDatas.filter(o=>o.id === notificationIdRead)[0];
      //   notification.readAt = new Date()
      // }
      return {
        ...state,
        markAsReadError: errorMarkRead,
        // notifications: notificationsDatas,
        markAsReadProgress: false,
      }

    case START_NOTIFICATION_All_MARKREAD:
      return {
        ...state,
        allMarkAsReadProgress: true
      }

    case END_NOTIFICATION_All_MARKREAD:
      return {
        ...state,
        allMarkAsReadProgress: false
      }

    case ADD_NEW_NOTIFICATION:
      var countN = state.toastNotificationCount;
      var allNotifications = state.notifications;
      allNotifications.push(action.payload.toastNotification);
      countN++;
      return {
        ...state,
        toastNotification: action.payload.toastNotification,
        toastNotificationCount: countN,
        notifications: allNotifications
      }

    case START_NOTIFICATION_ADD:
      return {
        ...state,
        addNotificationProgress: true,
        addNotificationError: null,
      }

    case END_NOTIFICATION_ADD:
      return {
        ...state,
        addNotificationProgress: false,
        addNotificationError: action.payload.error,
      }

    default:
      return state;
  }
}