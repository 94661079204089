import React, { useState } from 'react';
// import _ from "underscore";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Avatar } from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';

// icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import FlagIcon from '@material-ui/icons/Flag';
import { Send as SendIcon } from '@material-ui/icons';
import editIcon from "assets/images/edit_icon.svg";

// Redux
import { addRequirementResponse, fetchResponseForThread, addReplyForResponseThread, updateResponseThread, deletResponseThread, clearState } from "redux/evaluation/criteria/action";
import { fetchUsers } from "redux/usersSettings/action";

// Components
import Image from 'Components/Common/image.jsx';
import OutlinedSelectInput from 'Components/Common/Input/OutlinedSelectInput';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import AnswerReply from "Components/Common/EvaluateMultiChoiceAnswer/AnswerReply"
// import RatingPoper from "Components/Application/Components/Evaluate/Components/Evaluation/InProgress/RatingPoper.jsx";

// assets
import Messages from "assets/images/add_message.svg"

const connectedProps = (state) => ({
  authUser: state.authUser.user,
  usersSettings: state.usersSettings,
  fetchResponseThreadProgress: state.evaludationCriteria.fetchResponseThreadProgress,
  dataResponseThread: state.evaludationCriteria.dataResponseThread,
  deleteResponseThread: state.evaludationCriteria.deleteResponseThread,
  addReplyResponseThread: state.evaludationCriteria.addReplyResponseThread,
  updateResponseThread: state.evaludationCriteria.updateResponseThread,
});

const connectionActions = {
  _addRequirementResponse: addRequirementResponse,
  fetchResponseForThread: fetchResponseForThread,
  addReplyForResponseThread: addReplyForResponseThread,
  _updateResponseThread: updateResponseThread,
  deletResponseThread: deletResponseThread,
  _fetchUsers: fetchUsers,
  clearState: clearState
}
var connector = connect(connectedProps, connectionActions);


const useMessageStyles = createStyles((theme) => ({
  root: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'flex-start space-between',
    alignItems: 'flex-start',
    borderBottom: '1px solid #EEEEEE'
  },
  userAvatar: {
    width: '60px',
    margin: '15px'

  },
  avatar: {
    width: '60px',
    height: '60px',
    margin: '0px auto',
    borderRadius: '50%',
  },
  container: {
    width: 'calc(100% - 60px)',
    margin: '15px'
  },
  userName: {
    fontWeight: 700,
    color: '#000000',
    margin: '0px',
    fontSize: '15px',
    marginBottom: '5px',
  },
  rowFlex: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
  },
  rowSpaceBtw: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'center space-between',
    alignItems: 'center'
  },
  messageBody: {
    fontSize: '12px',
    marginBottom: '8px',
  },
  mentionedUser: {
    fontWeight: 500,
    color: '#4A87F8'
  },
  footer: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex'
  },
  actionCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: "100%",
    marginBottom: 10,
    marginTop: 5
  },
  leftColSpan: {
    fontWeight: 'normal',
    letterSpacing: '0px',
    color: '#000000',
    // opacity: 0.3,
    fontSize: '12px',
    marginRight: '25px',
    fontSize: '1rem',
    textDecoration: 'underline',
    color: '#4A87F8'
  },
  rightCol: {
    width: '50%'
  },
  askSeller: {
    textAlign: 'left',
    textDecoration: 'underline',
    fontWeight: 500,
    color: '#4A87F8',
    fontSize: '14px',
    display: 'block',
    textAlign: 'right'
  },
  messageReply: {
    fontSize: '12px',
    background: '#F4F6F8 0% 0% no-repeat padding-box',
    borderRadius: '7px',
    padding: '10px',
    marginTop: '20px',
    display: 'block',
    width: '100%',
    border: ' 0px none',
    outline: 'none',
    padding: '10px',
    letterSpacing: '0px',
    color: '#B2B2B2',
    fontFamily: 'Poppins'
  },
  mentionUser: {
    fontWeight: 500,
    color: '#4A87F8'
  },
  flagSection: {
    display: 'flex'
  },
  correctFlag: {
    padding: '0px 2px',
    cursor: 'pointer',
    color: '#4b86f8'
  },
  wrongFlag: {
    padding: '0px 2px',
    cursor: 'pointer',
    color: 'red'
  },
  responseNote: {
    "& [class*='editableField']": {
      minHeight: 'auto !important',
      margin: '0px 10px !important'
    }
  },
  messageBody: {
    paddingRight: '20px'
  },
  responseActionContainer: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'center flex-start',
    alignItems: 'center',
    marginTop: 13
  },
  responseAction: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: ' center space-between',
    alignItems: 'center',
    background: '#f9f9f9',
    borderRadius: '8px',
    padding: '4px 21px',
  },
  selectResult: {
    "& > div": {
      marginBottom: 0
    },
    display: "flex",
    justifyContent: 'flex-end',
    position: 'relative',
    marginRight: 10,
    height: '100%',
    alignItems: 'center',
    minWidth: 80,

    "& button": {
      color: '#707070',
      background: '#fff',
      padding: '1.5px 9px',
      minWidth: 80
    },
    "& [class*=MuiFormControl-root]": {
      minWidth: 82,
      width: 'inherit',
    },
    "& [class*=MuiSelect-root]": {
      fontSize: 13,
      color: '#707070',
      padding: '0.29em 0.75em'

    },
    "&.pass": {
      "& [class*=MuiSelect-root], svg": {
        color: '#2EB77D',
      },
      "& [class*=MuiOutlinedInput-notchedOutline]": {
        border: '1px solid #2EB77D'
      },

    },
    "&.fail": {
      "& [class*=MuiSelect-root], svg": {
        color: '#b94141',
      },
      "& [class*=MuiOutlinedInput-notchedOutline]": {
        border: '1px solid #b94141'
      }
    }
  },
  reqMatch: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center flex-start',
    alignItems: 'center',
    background: '#f9f9f9',
    padding: '12px 2px',
    borderRadius: '6px',
    '& span': {
      marginRight: 10,
      marginLeft: 10
    }
  },
  responseComment: {
    display: 'flex',
    placeItems: 'center'
  },
  addText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80px',
    fontSize: 13,
    color: '#707070',
    cursor: 'pointer',
    transition: 'all linear .5s',
  },
  addTextimage: {
    marginRight: 10,
    width: 21,
    position: 'relative',
    top: 2
  },
}))

class UserMessage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      note: '',
      editNote: false,
      comment: '',
      editComment: false,
      editThread: 0,
      showMainReply: false,
      replyMessage: '',
      showThread: false
    }
  }

  componentDidMount() {
    this.props._fetchUsers(0, 100, '')
    this.props.fetchResponseForThread(this.props.evaluation_id, this.props.response.ID)
  }

  componentDidUpdate() {
    if (this.props.deleteResponseThread || this.props.updateResponseThread || this.props.addReplyResponseThread) {
      this.setState({
        replyMessage: '',
        showMainReply: false
      })
      this.props.clearState('delete_thread')
      this.props.clearState('reply_response')
      this.props.clearState('update_response')
      this.props.fetchResponseForThread(this.props.evaluation_id, this.props.response.ID)
    }
  }

  AddReplyResponse = () => {
    const { response } = this.props;
    this.props.addReplyForResponseThread(this.props.evaluation_id, response.ID, this.state.replyMessage)
  }

  AddResponse = () => {
    const { evaluation_id, product, requirement } = this.props;
    this.props._addRequirementResponse(
      evaluation_id,
      product.ID,
      requirement.id, {
      note: this.state.note,
      value: 1
    }
    );
    // this.onBlurInput(`requirement_${criteria.id}_${requirement.id}`)
  }

  onBlurInput = (className) => {
    this.setState({
      editNote: false
    }, () => {
      try {
        const responseNote = document.querySelectorAll(`.response_note .${className}`)
        const rootRequirementHight = document.querySelectorAll(`.${className}`);
        if (responseNote.length === 0 && rootRequirementHight && rootRequirementHight.length) {
          for (var i = 0; i < rootRequirementHight.length; i++) {
            rootRequirementHight[i].classList.remove('rootRequirementHight');
          }
        }
      } catch (e) {

      }
    });
  }

  render() {

    const avatarName = (name) => {
      if (name) {
        return name.substring(0, 1);
      }
    }

    const { classes, response, replyResponse, flag, dataResponseThread } = this.props;

    let responseThread = dataResponseThread ? dataResponseThread[`${this.props.evaluation_id}_${response.ID}`] : [];
    responseThread = responseThread?.sort(function (a, b) {
      return new Date(b.CreatedAt) - new Date(a.CreatedAt);
    })

    responseThread = responseThread && responseThread.reverse() || []

    let usersList = this.props.usersSettings?.users || [];

    const { evaluation_id, product, requirement } = this.props;

    if (requirement == undefined) {
      return null;
    }

    let selectOptions = [
      {
        value: "1",
        label: "Yes"
      },
      {
        value: "2",
        label: "Partial"
      },
      {
        value: "3",
        label: "No"
      }
    ]

    return (
      <div className={classes.root}>
        <div className={classes.userAvatar}>
          {/* <Image className={classes.avatar} src={'https://gravatar.com/avatar/e7340866aaef964136051ca9f10c1cb8?s=200&d=mp&r=x'} /> */}
          <Avatar style={{ width: '55px', height: '55px', fontSize: '25px' }} alt={response?.User?.Name} >{avatarName(response?.User?.Name)}</Avatar>
        </div>
        <div className={classes.container}>
          <div className={classes.rowSpaceBtw}>
            <h5 className={classes.userName}>{response?.User?.Name || 'User'}</h5>
          </div>

          {/* Multi Choice Questions */}
          {response && response !== null &&
            <div>
              <AnswerReply
                requirement={requirement}
                userOptions={response === null || response.UserOptions === null ? [] : response.UserOptions}
                onChange={(options, add_other = null) => {
                  let addOther = response?.UserOptions.filter((item) => item.OptionID === 0).sort((a, b) => b.ID - a.ID);
                  if (requirement.answer_type === 5 || requirement.answer_type === 6) {
                    this.props._addRequirementResponse(
                      evaluation_id,
                      product.ID,
                      requirement.id, {
                      value: response == null ? null : response.Response,
                      note: response == null ? null : response.Note,
                      response_options: {
                        answer: add_other !== null ? add_other :
                          ((requirement.answer_type === 2 || requirement.answer_type === 3) ? (addOther[0]?.Answer) : (response?.UserOptions[0]?.Answer || ''))
                      }
                    }
                    );
                  } else if (requirement.answer_type === 4) {
                    this.props._addRequirementResponse(
                      evaluation_id,
                      product.ID,
                      requirement.id, {
                      note: response == null ? null : response.Note,
                      value: response == null ? null : response.Response,
                      response_options: {
                        answer: options
                      }
                    }
                    );
                  } else {
                    this.props._addRequirementResponse(
                      evaluation_id,
                      product.ID,
                      requirement.id, {
                      value: response == null ? null : response.Response,
                      note: response == null ? null : response.Note,
                      response_options: {
                        option_ids: options,
                        answer: add_other !== null ? add_other :
                          ((requirement.answer_type === 2 || requirement.answer_type === 3) ? (addOther[0]?.Answer) : (response?.UserOptions[0]?.Answer || ''))
                      }
                    }
                    );
                  }
                }}
              />

              {/* Text section */}
              {
                (requirement?.answer_type === 1 || this.props.communityTab === true) &&
                <>
                  <div className={classes.messageBody + ' ' + classes.rowSpaceBtw}>
                    <div style={{ width: 'calc( 100% - 0px )', padding: '3px' }}>
                      {/* <p style={{ margin: '0px' }}>{ response?.Note }</p> */}
                      <div className={classnames(classes.responseNote, classes.responseComment)}>
                        <SlateInputField
                          placeholder=""
                          isToolBar={this.state.editNote === true ? true : false}
                          readOnly={this.state.editNote === true ? false : true}
                          as={SlateInputField}
                          initValue={response?.Note}
                          textContainerStyle={{ color: '#282D30', }}
                          paperStyle={{ fontWeight: 400 }}
                          style={{ marginBottom: 0, width: '96%', padding: '5px' }}
                          formControlStyle={
                            this.state.editNote ? { maxHeight: 130, overflow: 'overlay' } : { border: 'none', maxHeight: 130, overflow: 'overlay' }
                          }
                          onChangeEvent={(value) => {
                            this.setState({
                              note: value
                            })
                          }}
                        />
                        {!this.state.editNote && response !== null && response.Note !== null && response.Note !== '' && <div>
                          <Image alt="Edit" src={editIcon} onClick={() => {
                            this.setState({
                              editNote: true
                            })
                          }} />
                        </div>}
                      </div>

                    </div>
                    {flag === true &&
                      <div className={classnames(classes.flagSection, 'evaluate-flag-section')}>
                        <div className={classes.correctFlag} title={'Predicted correctly?'}><FlagIcon /></div>
                        <div className={classes.wrongFlag} title={'Have to improve the predictions?'}><FlagIcon /></div>
                      </div>
                    }
                    {this.state.editNote === true &&
                      <>
                        <div className={classes.saveIcon}
                          onClick={() => {
                            // check add other exist
                            let addOther = response?.UserOptions.filter((item) => item.OptionID === 0).sort((a, b) => b.ID - a.ID);
                            if (requirement.answer_type === 5 || requirement.answer_type === 6) {
                              this.props._addRequirementResponse(
                                this.props.match.params.evaluation_id,
                                product.ID,
                                requirement.id, {
                                note: String(this.state.note).trim().length == 0 ? (response?.Note) : this.state.note,
                                value: response == null ? null : response.Response,
                                response_options: {
                                  answer: (requirement.answer_type === 2 || requirement.answer_type === 3) ? (addOther[0]?.Answer) : (response?.UserOptions[0]?.Answer || '')
                                },
                              }
                              );
                            } else if (requirement.answer_type === 4 || requirement.answer_type === 1) {
                              this.props._addRequirementResponse(
                                this.props.match.params.evaluation_id,
                                product.ID,
                                requirement.id, {
                                note: this.state.note,
                                value: response == null ? null : response.Response,
                                response_options: {
                                  answer: requirement.answer_type === 1 ? 'Done' : (response?.UserOptions[0]?.Answer || '')
                                },
                              }
                              );
                            } else {
                              this.props._addRequirementResponse(
                                this.props.match.params.evaluation_id,
                                product.ID,
                                requirement.id, {
                                note: String(this.state.note).trim().length == 0 ? (response?.Note) : this.state.note,
                                value: response == null ? null : response.Response,
                                response_options: {
                                  option_ids: (response == null || response.UserOptions == null ? [] : response.UserOptions.map((i) => {
                                    return i.OptionID
                                  })),
                                  answer: (requirement.answer_type === 2 || requirement.answer_type === 3) ? (addOther[0]?.Answer) : (response?.UserOptions[0]?.Answer || '')
                                },
                              }
                              );
                            }
                          }
                          } title="Save" ><DoneIcon />
                        </div>
                      </>
                    }
                  </div>
                </>
              }


              {/* Comment Section */}
              {
                requirement.answer_type !== 1 &&
                <Grid item xs={12}>
                  {((response !== null && response.Note !== null && response.Note !== '') || this.state.editComment) ? (
                    <>
                      <div className={classnames(classes.responseNote, classes.responseComment)}>
                        <SlateInputField
                          placeholder=""
                          isToolBar={this.state.editComment === true ? true : false}
                          readOnly={this.state.editComment === true ? false : true}
                          as={SlateInputField}
                          formControlStyle={
                            this.state.editComment ? { maxHeight: 130, overflow: 'overlay' } : { border: 'none', maxHeight: 130, overflow: 'overlay' }
                          }
                          initValue={response?.Note}
                          textContainerStyle={{ color: '#282D30', }}
                          paperStyle={{ fontWeight: 400 }}
                          style={{ marginBottom: 0, width: '96%', padding: '5px' }}
                          onChangeEvent={(value) => {
                            this.setState({
                              comment: value
                            })
                          }}
                        />
                        {!this.state.editComment && <div>
                          <Image alt="Edit" src={editIcon} onClick={() => {
                            this.setState({
                              editComment: true
                            })
                          }} />
                        </div>}
                        {this.state.editComment === true &&
                          <>
                            <div className={classes.saveIcon}
                              onClick={() => {
                                // check add other exist
                                let addOther = response?.UserOptions.filter((item) => item.OptionID === 0).sort((a, b) => b.ID - a.ID);
                                if (requirement.answer_type === 5 || requirement.answer_type === 6) {
                                  this.props._addRequirementResponse(
                                    this.props.match.params.evaluation_id,
                                    product.ID,
                                    requirement.id, {
                                    note: this.state.comment,
                                    value: response == null ? null : response.Response,
                                    response_options: {
                                      answer: (requirement.answer_type === 2 || requirement.answer_type === 3) ? (addOther[0]?.Answer) : (response?.UserOptions[0]?.Answer || '')
                                    },
                                  }
                                  );
                                } else if (requirement.answer_type === 4) {
                                  this.props._addRequirementResponse(
                                    this.props.match.params.evaluation_id,
                                    product.ID,
                                    requirement.id, {
                                    note: this.state.comment,
                                    value: response == null ? null : response.Response,
                                    response_options: {
                                      answer: response?.UserOptions[0]?.Answer || ''
                                    },
                                  }
                                  );
                                } else {
                                  this.props._addRequirementResponse(
                                    this.props.match.params.evaluation_id,
                                    product.ID,
                                    requirement.id, {
                                    note: this.state.comment,
                                    value: response == null ? null : response.Response,
                                    response_options: {
                                      option_ids: (response == null || response.UserOptions == null ? [] : response.UserOptions.map((i) => {
                                        return i.OptionID
                                      })),
                                      answer: (requirement.answer_type === 2 || requirement.answer_type === 3) ? (addOther[0]?.Answer) : (response?.UserOptions[0]?.Answer || '')
                                    },
                                  }
                                  );
                                }
                              }
                              } title="Save" ><DoneIcon />
                            </div>
                          </>
                        }
                      </div>
                    </>) : (<div className={classnames(classes.addText, 'response-addText', 'evaluate-add-text')} onClick={() => {
                      this.setState({
                        editComment: true
                      })
                    }}>
                      <img className={classes.addTextimage} alt="" src={Messages} />
                      <span>Add Comment</span>
                    </div>
                  )}

                </Grid>
              }


              <Grid item xs={12} className={classes.responseActionContainer}>
                <div className={classes.reqMatch}>
                  <span>Score</span>
                  <div className={classnames('evaluate-select-result', classes.selectResult, (response && response.Response + "") === '1' ? 'pass' : (response && response.Response + "") === '2' ? 'fail' : '')}>
                    <OutlinedSelectInput
                      value={response !== null && response.Response !== null ? response.Response + "" : "-1"}
                      compact={true}
                      options={selectOptions}
                      onChange={(event) => {
                        // check add other exist
                        let addOther = response?.UserOptions.filter((item) => item.OptionID === 0).sort((a, b) => b.ID - a.ID);
                        let answer = {};
                        if (requirement.answer_type === 5 || requirement.answer_type === 6) {
                          answer = {
                            value: parseFloat(event.target.value),
                            note: response == null ? null : response.Note,
                            response_options: {
                              answer: (requirement.answer_type === 2 || requirement.answer_type === 3) ? (addOther[0]?.Answer) : (response?.UserOptions[0]?.Answer || '')
                            }
                          }
                        } else if (requirement.answer_type === 4) {
                          answer = {
                            note: response == null ? null : response.Note,
                            value: parseFloat(event.target.value),
                            response_options: {
                              answer: response?.UserOptions[0]?.Answer || ''
                            }
                          }
                        } else {
                          answer = {
                            value: parseFloat(event.target.value),
                            note: response == null ? null : response.Note,
                            response_options: {
                              option_ids: (response == null || response.UserOptions == null ? [] : response.UserOptions.map((i) => {
                                return i.OptionID
                              })),
                              answer: (requirement.answer_type === 2 || requirement.answer_type === 3) ? (addOther[0]?.Answer) : (response?.UserOptions[0]?.Answer || '')
                            }
                          }
                        }
                        this.props._addRequirementResponse(
                          this.props.match.params.evaluation_id,
                          product.ID,
                          requirement.id, {
                          ...answer
                        }
                        )
                      }}
                    />
                  </div>
                </div>
              </Grid>

            </div>
          }


          {this.state.showThread === true &&
            <>
              <div className={classes.messageBody + ' ' + classes.rowSpaceBtw}>
                <div style={{ width: '100%', paddingLeft: '' }}>
                  {
                    responseThread?.length > 0 && responseThread.map((response, k) => {
                      return <>
                        <ReplySection key={k} thread={response} {...this.props} users={usersList} />
                      </>
                    })
                  }
                </div>
              </div>
              <div className={classes.footer}>
                <div className={classes.actionCol}>
                  {replyResponse === true && <span className={classnames(classes.leftColSpan, 'evaluate-reply')} onClick={() => {
                    this.setState({ showMainReply: true })
                  }
                  } style={{ cursor: 'pointer' }}>Reply</span>}
                  {
                    this.state.showThread === false && responseThread?.length > 0 &&
                    <span style={{ cursor: 'pointer' }} onClick={() => { this.setState({ showThread: true }) }} className={classnames(classes.leftColSpan)}>{responseThread?.length || 0} Replies</span>
                  }
                </div>
              </div>
            </>
          }
          {this.state.showThread === false &&
            <div className={classes.footer}>
              <div className={classes.actionCol}>
                {
                  this.state.showThread === false && responseThread?.length > 0 &&
                  <span style={{ cursor: 'pointer' }} onClick={() => { this.setState({ showThread: true }) }} className={classnames(classes.leftColSpan)}>{responseThread?.length || 0} Replies</span>
                }
              </div>
            </div>
          }
          {replyResponse === true && this.props.fetchResponseThreadProgress === false && responseThread?.length === 0 &&
            <span className={classnames(classes.leftColSpan, 'evaluate-reply')} onClick={() => { this.setState({ showMainReply: true }) }} style={{ cursor: 'pointer' }}>Reply</span>
          }

          {this.props.fetchResponseThreadProgress === false && this.state.showMainReply === true && <>
            <SlateInputField
              style={{ width: '100%', marginTop: '12px' }}
              as={SlateInputField}
              onChangeEvent={(value) => {
                this.setState({ replyMessage: value })
              }}
              placeholder={"Type your response here."}
              initValue={''}
              formControlStyle={{ overflow: 'overlay' }}
              textContainerStyle={{ color: "#707070" }} />
            <div style={{ textAlign: 'right' }}>
              <Button style={{ marginRight: 20 }} variant="contained" color="primary" endIcon={<SendIcon />} onClick={() => {
                this.AddReplyResponse()
              }}>
                Save
              </Button>
              <Button onClick={() => { this.setState({ showMainReply: false }) }} >
                Cancel
              </Button>
            </div>
          </>}
        </div>
      </div>
    )
  }
}


// Component styles
const useStyles = makeStyles(theme => ({
  responseNote: {
    marginRight: 15,
    flex: 10,
    "& [class*='editableField']": {
      minHeight: 'auto !important',
      margin: '0px !important',
      padding: '5px'
    },
    background: '#f0f0f08c',
    padding: '10px',
    borderRadius: '8px',
    paddingBottom: 0,
    fontSize: '13px'
  },
  replyActionContainer: {
    marginTop: '14px',
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'space-between',
    alignItems: 'flex-start'
  },
  userAvatar: {
    // marginRight: 15,
    flex: 1
  },
  replyAction: {
    // display: 'block',
    // paddingLeft: '50px',
    // textAlign: 'right',
    // paddingRight: '12px',
    '& svg': {
      width: '16px',
      color: '#bdbdbd',
      marginRight: '10px',
      cursor: 'pointer'
    }
  }
}));

function ReplySection(props) {
  const { thread } = props;

  const [edit, setEdit] = useState(0);

  const [reply, setSetReply] = useState('');

  const classes = useStyles();

  const avatarName = (name) => {
    if (name) {
      return name.substring(0, 1);
    }
  }

  const user = props.users.filter(user => user.ID === thread?.UserID)

  return (
    <div>
      <div className={classes.replyActionContainer}>
        <span className={classes.userAvatar}>
          <Avatar style={{ width: '25px', height: '25px', fontSize: '15px' }}  >{avatarName(user[0]?.Name || 'User')}</Avatar>
        </span>
        <span className={classes.responseNote}>
          <div style={{ display: 'flex', placeContent: 'space-between' }}>
            <span style={{ fontWeight: 600 }}>{user[0]?.Name || '...'}</span>
            <span className={classes.replyAction}>
              {
                edit > 0 &&
                <>
                  <SaveIcon onClick={() => { props._updateResponseThread(props.evaluation_id, thread?.ResponseID, thread?.ID, reply) }} />
                  <CancelIcon onClick={() => { setEdit(0) }} />
                </>
              }
              {
                props.authUser.ID === thread?.UserID && edit === 0 &&
                <>
                  <EditIcon onClick={() => { setEdit(thread?.ID) }} />
                  <DeleteIcon style={{ marginRight: 0 }} onClick={() => { props.deletResponseThread(props.evaluation_id, thread?.ResponseID, thread?.ID) }} />
                </>
              }
            </span>
          </div>
          <SlateInputField
            placeholder=""
            isToolBar={false}
            readOnly={true}
            as={SlateInputField}
            formControlStyle={{ border: 'none', background: 'transparent' }}
            initValue={thread?.Response}
            textContainerStyle={{ color: '#282D30', }}
            paperStyle={{ fontWeight: 400, background: 'transparent' }}
            style={{ width: '100%',marginBottom: 0, background: 'transparent' }}
          />
        </span>
      </div>
      <span style={{ paddingLeft: '42px', fontSize: '12px' }}>{timeSince(thread?.CreatedAt)} ago</span>

      {
        edit === thread?.ID &&
        <>
          <SlateInputField
            style={{ width: '100%', height: 150,marginBottom: 0 }}
            placeholder=""
            as={SlateInputField}
            initValue={thread?.Response}
            textContainerStyle={{ color: '#282D30', }}
            paperStyle={{ fontWeight: 400 }}
            onChangeEvent={(value) => {
              setSetReply(value)
            }}
          />
        </>
      }
    </div>
  );
}


function timeSince(date) {

  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    if (interval === 1) {
      return Math.floor(interval) + " year";
    } else {
      return Math.floor(interval) + " years";
    }
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " month";
    } else {
      return Math.floor(interval) + " months";
    }
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " day";
    } else {
      return Math.floor(interval) + " days";
    }
  }
  interval = seconds / 3600;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " hour";
    } else {
      return Math.floor(interval) + " hours";
    }
  }
  interval = seconds / 60;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " minute";
    } else {
      return Math.floor(interval) + " minutes";
    }
  }
  return Math.floor(seconds) + " seconds";
}


export default connector(compose(withRouter, withStyles(useMessageStyles))(UserMessage));