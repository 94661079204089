import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, Typography, Button, Grid, CircularProgress, Checkbox} from '@material-ui/core';
import {hideVendorProductsAndServicesDialog} from "redux/vendor/action";
import {fetchVendorCatalogTabData} from "redux/vendor/vendorCatalog/action";
import { withRouter } from 'react-router-dom';
import Image from 'Components/Common/image.jsx';
import { showSnackBar } from "redux/snackbar/action";
import DefaultImage from "assets/images/evaluation/default.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    openVendorProductsAndServiceDialog: state.vendors.openVendorProductsAndServiceDialog,
    openVendorData: state.vendors.openVendorData,
    vendorCatalogTabData: state.vendorCatalog.vendorCatalogTabData,
    fetchVendorCatalogTabDataProgress: state.vendorCatalog.fetchVendorCatalogTabDataProgress,
    fetchVendorCatalogTabDataError: state.vendorCatalog.fetchVendorCatalogTabDataError,
});

const connectionActions = {
    hideVendorProductsAndServicesDialog: hideVendorProductsAndServicesDialog,
    fetchVendorCatalogTabData: fetchVendorCatalogTabData,
    showSnackBar: showSnackBar,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent:{
        padding: theme.spacing(6, 8) +" !important"
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(2),
        display:'block',
        fontWeight: '500'
    },
    tableSection: {
        maxHeight: "calc(90vh - 200px)",
        minHeight: "170px",
        overflowY: "auto",
        padding: "5px 20px",
        overflowX: "hidden",
      },

    productItem: {
      minWidth: "107px",
      maxWidth: "107px",
      borderRadius: "10px",
      border: "2px solid #eeeeee",
      margin: '0.5rem',
      '& > img': {
        width: "auto",
        height: "55px",
        maxWidth: "90%",
        objectFit: "contain",
        margin: "0 auto",
        display: "block",
      }
    },
    productHeader:{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    center: {
        textAlign: "center",
        marginTop: "1rem",
    },
    productName: {
      fontSize: '0.83em',
      fontWeight: '600',
      textAlign: 'center'
    },
    noVendor: {
        fontSize: '0.9rem',
        margin: '1rem 0rem 1.3rem 2rem',
    },
    arrow: {
        paddingLeft: 5
    },
    progress: {
        textAlign: 'center',
        paddingTop: 15
    },
    buttonSection: {
        display: 'flex',
        justifyContent: "end",
        marginTop: 40
    },
    productCard: {
        position: "relative",
        textAlign: "center",
        paddingTop: 10,
    },
    selectIcon: {
        position: "absolute",
        zIndex: 22,
        right: -12,
        top: -12,
        color: "#dcd7d7",
        cursor: "pointer",
    },
    sectionTitle:{
        fontSize: 15,
        fontWeight: 500,
        margin: '15px 0px'
    },
});

class VendorProductsAndServicesDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selectedProductIds: [],
            selectedServiceIds: [],
        }
    }

    componentDidMount(){
        if(this.props.openVendorData?.org_vendor_id){
            this.props.fetchVendorCatalogTabData(this.props.openVendorData.org_vendor_id);
        }else {
            this.props.showSnackBar("Failed to fetch vendor details", "error", 3000);
            this.props.hideVendorProductsAndServicesDialog();
        }
    }

    componentDidUpdate(prevProps){
        if(!this.props.fetchVendorCatalogTabDataProgress && prevProps.fetchVendorCatalogTabDataProgress) {
            if(this.props.fetchVendorCatalogTabDataError){
                this.props.showSnackBar('Failed to fetch vendors', "error", 3000);
            }else{
                let data = this.props.selectedData[this.props.openVendorData?.org_vendor_id]
                if(data){
                    this.setState({selectedProductIds: data.productIds ||[], selectedServiceIds: data.serviceIds || []});
                }
            }
        }
    }

    handleSelectProduct = (id) => {
        let products = [...this.state.selectedProductIds];
        if (products.includes(id)) {
          products = products.filter((o) => o !== id);
        } else {
          products.push(id);
        }
        this.setState({selectedProductIds: products});
    };

    handleSelectService = (id) => {
        let services = [...this.state.selectedServiceIds];
        if (services.includes(id)) {
          services = services.filter((o) => o !== id);
        } else {
          services.push(id);
        }
        this.setState({selectedServiceIds: services});
    };

    render() {
        const classes = this.props.classes;
        let vendors = this.props.vendorCatalogTabData || [];

        return <Dialog 
                    onClose={this.props.hideVendorProductsAndServicesDialog} 
                    aria-labelledby="add-upload-dialog" 
                    open={this.props.openVendorProductsAndServiceDialog}
                    TransitionComponent={Transition}
                    disablecenterClick={true}
                    fullWidth={true}
                    maxWidth={"lg"}
                    scroll={"body"}
                    >
                    <DialogContent classes={{root:classes.dialogContent}}>
                      <div className={classes.productHeader}>
                        <Typography variant={"h6"} className={classes.title}>Select products and services offered by {this.props.openVendorData?.name}</Typography>
                      </div>
                      <div className={classes.tableSection} id="scrollableDiv12">
                            {this.props.fetchVendorCatalogTabDataProgress && (
                                <div className={classes.center}>
                                    <CircularProgress />
                                </div>
                            )}
                            {!this.props.fetchVendorCatalogTabDataProgress && <div>
                                <div className={classes.sectionTitle}>Products</div>
                                {vendors?.existing_products?.length === 0 ? <div className={classes.noVendor}>No product found</div> : null}
                                <Grid container spacing={3}>
                                    {vendors?.existing_products?.map((product, k) => {
                                        return (
                                            <Grid item key={"existing_" + product.master_product_id} className={classes.productItem} >
                                                <div className={classes.productCard} >  
                                                    <div className={classes.selectIcon} onClick={() => {
                                                        this.handleSelectProduct(product.master_product_id);
                                                    }}>
                                                        <Checkbox style={{width: 35, height: 35 }} checked={this.state.selectedProductIds?.includes(product.master_product_id)} />
                                                    </div>
                                                    <Image src={product.product_logo && product.product_logo.length > 0 ? product.product_logo : DefaultImage} alt={product.name} width="50px" />
                                                    <p className={classes.productName}>
                                                        {product.name}
                                                    </p>
                                                </div>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>}
                            {!this.props.fetchVendorCatalogTabDataProgress && <div>
                                <div className={classes.sectionTitle}>Services</div>
                                {vendors?.existing_services?.length === 0 ? <div className={classes.noVendor}>No service found</div> : null}
                                <Grid container spacing={3}>
                                    {vendors?.existing_services?.map((service, k) => {
                                        return (
                                            <Grid item key={"existing_" + service.id} className={classes.productItem} >
                                                <div className={classes.productCard} >  
                                                    <div className={classes.selectIcon} onClick={() => {
                                                        this.handleSelectService(service.id);
                                                    }}>
                                                        <Checkbox style={{width: 35, height: 35 }} checked={this.state.selectedServiceIds?.includes(service.id)} />
                                                    </div>
                                                    <Image src={service.logo && service.logo.length > 0 ? service.logo : DefaultImage} alt={service?.service?.name} width="50px" />
                                                    <p className={classes.productName}>
                                                        {service?.service?.name}
                                                    </p>
                                                </div>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>}
                      </div> 
                      <div className={classes.buttonSection}>
                        <Button variant="outlined" color="secondary" className={classes.button} onClick={()=>{
                            this.props.hideVendorProductsAndServicesDialog();
                        }}>Cancel</Button>
                          &nbsp;
                          &nbsp;
                        <Button variant="contained" color="secondary" onClick={()=>{
                            this.props.updateVendorProductsAndServices(this.props.openVendorData?.org_vendor_id,this.state.selectedProductIds, this.state.selectedServiceIds);
                            this.props.hideVendorProductsAndServicesDialog();
                            }}>
                            Save
                        </Button>
                        </div>    
                    </DialogContent>
                </Dialog>
    }
}

export default connector(compose(
    withRouter,withStyles(styles)
)(VendorProductsAndServicesDialog));