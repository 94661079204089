import React, { Component, useState, useEffect } from 'react';
import uniqid from 'uniqid';
import Select from 'react-select';
import classnames from 'classnames';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Typography, TextField, Divider, Switch, IconButton, ListItem, List, Popover, ListItemIcon, FormControlLabel, Checkbox
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import CodeIcon from '@material-ui/icons/Code';

import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import SqureButton from 'Components/Common/SqureButton';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import CommonCss from 'commonCss';
import { itemTypes, binaryOptions, webHookOptions, webHookFormsTypeOptions, tableItemTypes } from 'util/FormTypeOptions'
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import * as Validator from "util/Validation";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { getNextSectionsForConditions } from "util/FormTypeOptions";
import SchemaTagSelectionDialog from '../../../WorkflowBuilder/Dialogs/SchemaTagSelectionDialog.jsx';

const operand = [
  {
    value: 1,
    label: "===",
  },
  {
    value: 2,
    label: ">",
  },
  {
    value: 3,
    label: ">=",
  },
  {
    value: 4,
    label: "<",
  },
  {
    value: 5,
    label: "<=",
  },
];

const styles = (theme) => createStyles({
  root: {
    position: 'relative',
    border: '1px solid #dadce0',
    padding: 30,
    borderRadius: 5,
    background: '#FFF',
    marginBottom: 17,
    '& [class*=MuiInputBase-input]': {
      fontSize: theme.spacing(1.8),
      color: '#282D30',
      '&::-webkit-input-placeholder': {
        color: "#707070",
        fontSize: theme.spacing(1.8)
      }
    },

  },
  checkbox: {
    padding: '0px !important'
    // '& [class*=MuiCheckbox-root]': {
    // }
  },
  formControl: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  formGroup: {
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  dialogContent: {
    minHeight: 170,
    overflowY: 'hidden',
    "& [class*=select__menu]": {
      maxHeight: 100
    }
  },
  character: {
    fontSize: 14,
    color: '#6C6C6C',
    opacity: 1,
    marginTop: 10,
  },
  type: {
    fontSize: 14,
    color: '#6C6C6C',
    opacity: 1
  },
  head: {
    fontSize: 16,
    color: '#6C6C6C',
    opacity: 1,
    margin: 0,
    fontWeight: 500,
    marginBottom: 15
  },
  itemHead: {
    fontSize: 14,
    color: '#6C6C6C',
    opacity: 1
  },
  fieldContainer: {
    marginTop: 30,
    marginBottom: 30
  },
  inputContainer: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'center flex-start',
    alignItems: 'center',
    marginBottom: 5
  },
  inputContainer1: {
    flex: 1,
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'center flex-start',
    alignItems: 'center',
    marginBottom: 5
  },
  conditionBox: {
    flex: 1
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    padding: '5px',
    width: '50%',
    // marginTop: '10px'
  },
  inputRow1: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    padding: '5px',
    width: '80%',
    marginTop: '10px'
  },
  inputRow1W: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    padding: '5px',
    width: '50%',
    marginTop: '10px'
  },
  inputRowBoolean: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    padding: '5px',
    width: 'max-content',
    marginRight: 10
  },
  count: {
    display: 'inline-block',
    textAlign: 'center',
    color: '#6D7278',
    fontSize: '13px',
    marginRight: 5
  },
  input: {
    border: '0px',
    height: '30px',
    width: 'calc(100% - 20px)',
    outline: 0,
    color: '#6D7278',
    fontSize: '13px'
  },
  dragIndicator: {
    color: '#D9D9D9',
    position: 'absolute',
    top: 15,
    left: '50%',
    transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
  },
  deleteIcon: {
    position: "absolute",
    right: 16,
    top: 13,
    cursor: "pointer",
  },
  borderNone: {
    border: 'none',
    padding: 0,
    minHeight: 0,
    '& [class*="MuiInputBase-root"]': {
      color: '#6D7278',
      fontSize: 13
    }
  },
  deleteImage: {
    cursor: "pointer",
  },
  label: {
    color: '#000000de',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.6px',
  },
  value: {
    color: '#000000de',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.65x',
  },
  card: {
    boxShadow: "none",
    '&.details-border': {
      border: '1px solid #ddd'
    }
  },
  active: {
    display: "inline-block",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: "#33C162",
  },
  meta: {},
  requiredItem: {
  },
  activeSwitch: {
    '& .Mui-disabled': {
      color: '#4b86ff!important'
    }
  },
  leftBar: {
    display: 'flex',
    height: 'calc(100% + 2px)',
    left: '-1px',
    paddingRight: '5px',
    position: 'absolute',
    bottom: '-1px',
    width: '1px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    background: '#4285f4'
  },
  greenLeftBar: {
    display: 'flex',
    height: 'calc(100% + 2px)',
    left: '-1px',
    paddingRight: '5px',
    position: 'absolute',
    bottom: '-1px',
    width: '1px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    background: 'green'
  },
  editItem: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '& .MuiFilledInput-multiline': {
      padding: '15px 15px 10px'
    },
  },
  bottomActions: {
    borderTop: '1px solid #dadce0',
    padding: '17px 0px 0px 17px',
    marginTop: 17,
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  bottomItem: {
    // marginRight: 20
  },
  dividerBtm: {
    margin: '0px 10px'
  },
  additionalDataContainer: {

  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5
  },
  threeDot: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  threeDotForConditional: {
    position: 'absolute',
    right: 45,
    top: 0
  },
  numberOptionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    justifyContent: 'space-between'
  },
  numberInputRow1: {
    marginLeft: 10,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #9f9f9f87',
    borderRadius: '4px',
    padding: '5px',
  },
  numberInputCol2: {
    '& [class*=MuiInputBase-input]': {
      fontSize: theme.spacing(1.8),
      color: '#282D30',
      padding: 9,
      '&::-webkit-input-placeholder': {
        color: "#707070",
        fontSize: theme.spacing(1.8)
      }
    },
  },
  description: {
    fontSize: 14,
    color: '#B5BCC2',
    opacity: 1
  },
  rules: {
    marginTop: 20
  },
  ruleTable: {
    width: "100%",
    '& [class*=MuiSelect-root]': {
      minHeight: 22
    }
  },
  copiedText: {
    textAlign: 'left',
    fontSize: '14px',
    color: '#2EB77D',
  },
  copyText: {
    fontWeight: 400,
    fontSize: theme.spacing(2)
  },
  codeIcon: {
    cursor: 'pointer'
  },
  controlLabel: {
    color: ' #6C6C6C',
    opacity: 1,
    fontSize: 14,
  }
});

class FormItem extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      sectionUid: null,
      visibility: 3,
      items: [{
        isNew: true,
        name: "",
        type: 1,
        additional_data: { type: 0 },
        desciption: null,
        visibility: 3,
        is_multiselect: false,
        is_required: false,
        itemUid: uniqid("item-", `-${Number(new Date())}`),
        is_other_option: false
      }],
      isDeletable: true,
      isEditable: true,
      activeItem: null,
      activeThead: false,
      open: false,
      anchorEl: null,
      editName: null,
      item: {
        isNew: true,
        name: "",
        slug: "",
        type: 1,
        additional_data: { type: 0 },
        description: null,
        visibility: 3,
        is_multiselect: false,
        is_required: false,
        itemUid: uniqid("item-", `-${Number(new Date())}`),
        condition: null,
        is_other_option: false
      },
      type: 1,
      additional_data: '',
      desciption: '',
      itemId: '',
      itemUid: '',
      is_multiselect: '',
      is_required: '',
      orgForms: [],
      masterForms: [],
      orgFormsFieldMap: {},
      masterFormsFieldMap: {},
      isCreateOnce: true,
      copy: false,
      showProjectSchema: false
    }
  }

  componentDidMount() {
    const { config, isNew } = this.props;

    if (!isNew) {
      var additionalData = '';
      try {
        additionalData = this.props.additional_data;
      } catch (e) {
      }
      var obj = {
        name: this.props?.name,
        slug: this.props?.slug,
        // description: this.props?.Description,
        sectionId: config?.sectionId,
        type: this.props.type || 1,
        additional_data: additionalData,
        description: this.props.description,
        visibility: this.props?.visibility,
        itemId: this.props.itemId,
        itemUid: this.props?.itemUid,
        is_multiselect: this.props?.is_multiselect,
        is_required: this.props?.is_required,
        isNew: this.props.isNew,
        isTakeConditions: this.props.isTakeConditions,
        condition: this.props.condition,
        is_other_option: this.props?.is_other_option
      }

      var data = obj;
      data.item = obj;
      this.setState(data)
    }

  }

  createFormsMaps() {
    const { masterForms, orgForms } = this.props;

    let orgFrms = [];
    let orgFrmsFieldMap = {};
    let masterFrms = [];
    let masterFrmsFieldMap = {};
    if (orgForms !== undefined && orgForms !== null && orgForms.length > 0) {
      orgFrms = orgForms.map(o => {
        return {
          label: o.FormTitle,
          value: o.ID
        }
      })
      orgForms.forEach(function (frm) {
        orgFrmsFieldMap[frm.ID] = [];
        frm.OrgWorkflowTemplateFormsSection.forEach(function (si) {
          si.OrgWorkflowTemplateFormSectionItems.forEach(function (ssi) {
            if (ssi.ItemName !== '') {
              orgFrmsFieldMap[frm.ID].push({
                label: ssi.ItemName,
                value: ssi.ID
              })
            }
          })
        })
      })
    }

    if (masterForms !== undefined && masterForms !== null && masterForms.length > 0) {
      masterFrms = masterForms.map(o => {
        return {
          label: o.FormTitle,
          value: o.ID
        }
      })
      masterForms.forEach(function (frm) {
        masterFrmsFieldMap[frm.ID] = [];
        frm?.MasterWorkflowTemplateFormsSection.forEach(function (si) {
          si?.MasterWorkflowTemplateFormsSectionItems.forEach(function (ssi) {
            if (ssi.ItemName !== '') {
              masterFrmsFieldMap[frm.ID].push({
                label: ssi.ItemName,
                value: ssi.ID
              })
            }
          })
        })
      })
    }

    this.setState({
      isCreateOnce: false,
      orgForms: orgFrms,
      masterForms: masterFrms,
      orgFormsFieldMap: orgFrmsFieldMap,
      masterFormsFieldMap: masterFrmsFieldMap,
    })

  }

  handleSave = () => {
    const { name, visibility, sectionUid, items, data, item } = this.state;

    if (false && item.type === 21) {
      if (item.additional_data?.triggerType?.value === 'workflow_run.other') {
        let addiData = item.additional_data;
        let targetURL = addiData.triggerURL;
        if (targetURL === '') {
          this.props.showSnackBar("Please enter URL.", "error");
          return;
        }

        if (Validator.validateURL(targetURL) === false) {
          this.props.showSnackBar("Please enter valid URL.", "error");
          return;
        }
      }

      if (item.additional_data.dataFields !== null && item.additional_data.dataFields.length > 0) {
        let dataFields = item.additional_data.dataFields;
        let count = 0;
        let isError = false;
        let errorMsg = '';
        dataFields.forEach(function (itm) {
          if (isError) {
            return;
          }
          count++;
          if (itm.form_type === '') {
            isError = true;
            errorMsg = 'Please select form type in row ' + count;
          } else if (itm.form_id === '') {
            isError = true;
            errorMsg = 'Please select form in row ' + count;
          } else if (itm.field_id === '') {
            isError = true;
            errorMsg = 'Please select field in row ' + count;
          }
        })

        if (isError) {
          this.props.showSnackBar(errorMsg, "error");
          return;
        }
      }
    }

    if (this.props.isNew) {
      this.props.onSave({ name, visibility, sectionUid, item })
    } else {
      this.props.onUpdate({ name, visibility, items, data, item })
    }
  }

  handleUpdateItemValue = (name, key, index) => {
    const item = this.state.item;
    item[key] = name;
    this.setState({ item })
  }

  addTagValue = (value) => {
    const item = this.state.item;
    let name = item.name;
    let newName = name + ' {{ ' + value + ' }} ';
    item['name'] = newName;
    this.setState({ item })
  }

  handleUpdateTakeCondition = (name, key, index) => {
    const item = this.state.item;
    item[key] = name;
    if (item.additional_data !== undefined && item.additional_data !== null && item.additional_data.length > 0) {
      item.additional_data.forEach(function (ad) {
        ad.condition = null
      })
    }
    this.setState({ item })
  }

  handleChangeFile = (index, type, event, current) => {
    const checked = event.target.checked
    let currentCheck = current
    if (type === 'link') {
      if (currentCheck === 3 && !checked) {
        currentCheck = 2
      } else if (currentCheck === 2 && checked) {
        currentCheck = 3
      } else if (currentCheck === 1 && !checked) {
        currentCheck = 0
      } else if (currentCheck === 0 && checked) {
        currentCheck = 1
      }
    } else {
      if (currentCheck === 3 && !checked) {
        currentCheck = 1
      } else if (currentCheck === 1 && checked) {
        currentCheck = 3
      } else if (currentCheck === 2 && !checked) {
        currentCheck = 0
      } else if (currentCheck === 0 && checked) {
        currentCheck = 2
      }
    }
    const items = this.state.items
    items[index].additional_data.file = currentCheck
    this.setState({ items })
  }

  handleChangeBinaryOptionLabel = (index, optionIndex, label) => {
    const item = this.state.item
    item.additional_data[optionIndex].label = label
    this.setState({ item })
  }

  handleChangeType = (e, index) => {
    const item = this.state.item
    item.type = e?.value || 1
    const activeThead = false
    item.description = null
    item.is_multiselect = e && e?.value === 5 ? true : false;

    item.additional_data = e && e?.value === 3 ? binaryOptions : [];

    if (e && e?.value && e?.value === 1) {
      item.additional_data = { type: 0 }
    }

    if (e && e?.value && e?.value === 7) {
      item.additional_data = { userProfile: false }
    }

    if (e && e?.value === 9) {
      item.additional_data = { file: 3 }
    }

    if (e && e?.value === 21) {
      item.additional_data = {
        triggerType: {
          label: "Workflow Run Finish",
          value: "workflow_run.finish"
        },
        triggerURL: "",
        dataFields: []
      }
    }

    if (e && e?.value === 22) {
      item.additional_data = {
        message: "",
      }
    }


    this.setState({
      item,
      activeThead
    })
  }

  handleEditOption = (label, index, i) => {
    const item = this.state.item;
    item.additional_data[i].label = label;
    this.setState({ item })
  }

  handleEditOperand = (e, index, i) => {
    const item = this.state.item;
    item.additional_data[i].operation = e.target.value;
    this.setState({ item })
  }

  handleUpdateCondition = (value, index, i) => {
    const item = this.state.item;
    item.additional_data[i].condition = value;
    this.setState({ item })
  }

  handleWebHookOption = (index, value) => {
    const item = this.state.item;
    item.additional_data.triggerType = value;
    this.setState({ item })
  }

  handleWebHookTargetURL = (index, value) => {
    const item = this.state.item;
    item.additional_data.triggerURL = value;
    this.setState({ item })
  }

  handleWebHookDataFields = (index, newValue, fieldName) => {
    const item = this.state.item;
    item.additional_data.dataFields[index][fieldName] = newValue;
    if (fieldName === 'form_type') {
      item.additional_data.dataFields[index]['form_id'] = '';
      item.additional_data.dataFields[index]['field_id'] = '';
    } else if (fieldName === 'form_id') {
      item.additional_data.dataFields[index]['field_id'] = '';
    }
    this.setState({ item })
  }

  handleChangeShowMessage = (index, value) => {
    const item = this.state.item;
    item.additional_data.message = value;
    this.setState({ item })
  }

  handleUserProfileCheck = (e) => {
    const item = this.state.item;
    console.log(e.target.value, 'UserProfileValue')
    if (item.additional_data.userProfile) {
      item.additional_data.userProfile = item.additional_data.userProfile ? false : true
    } else {
      item.additional_data['userProfile'] = true;
    }
    this.setState({ item })
  }

  addNewWebHookFiels = () => {
    const item = this.state.item;
    item.additional_data.dataFields.push({
      form_id: "",
      form_type: "",
      field_id: ""
    });
    this.setState({ item })
  }

  deleteWebHookFields(rule, index) {
    const item = this.state.item;
    item.additional_data.dataFields.splice(index, 1);
    this.setState({ item })
  };

  handleAddOption = (index) => {
    const item = this.state.item;
    var newOption = {
      label: " ",
      value: Number(new Date()),
      id: Number(new Date()),
      condition: null,
      type: 1,
      isOtherOption: false
    }
    var additionsDatas = item.additional_data;
    additionsDatas.push(newOption);
    item.additional_data = additionsDatas;
    this.setState({ item })
  }

  handleAddOptionNumber = (index) => {
    const item = this.state.item;
    var newOption = {
      label: " ",
      value: Number(new Date()),
      operation: 1,
      id: Number(new Date()),
      condition: null,
      isOtherOption: false
    }
    var additionsDatas = item.additional_data;
    additionsDatas.push(newOption);
    item.additional_data = additionsDatas;
    this.setState({ item })
  }

  handleRemoveOption = (index, i) => {
    const item = this.state.item;
    if (item.additional_data[i].isOtherOption) {
      item.is_other_option = false;
    }
    item.additional_data.splice(i, 1);
    this.setState({ item })
  }

  handleAddOtherOption = (index) => {
    const item = this.state.item;
    var newOption = {
      label: "Other",
      value: Number(new Date()),
      id: Number(new Date()),
      condition: null,
      isOtherOption: true
    }
    var additionsDatas = item.additional_data;
    additionsDatas.push(newOption);
    item.additional_data = additionsDatas;
    item.is_other_option = true;
    this.setState({ item })
  }

  handleAddOtherOptionMultiSelect = (index) => {
    const item = this.state.item;
    item.is_other_option = true;
    this.setState({ item })
  }

  handleRemoveOtherOption = (index) => {
    const item = this.state.item;
    item.is_other_option = false;
    this.setState({ item })
  }

  getTypeText(type) {
    if (type === undefined || type === null || type === '') {
      return ''
    }
    var data = itemTypes.filter(it => it.value === type);
    return data[0]?.label;
  }

  copyLink(slug) {
    navigator.clipboard.writeText(`${slug}`)
  }


  handleChangeTableColumnType = (value, i) => {
    const item = this.state.item;
    item.additional_data[i].type = value
    if (value === 20) {
      item.additional_data[i].value = {
        type: {
          label: 'USD',
          value: 'USD'
        },
        value: ''
      }
    }
    this.setState({ item })
  }

  render() {
    const { classes, isActive, isNew, allSections, sectionId, itemIndex } = this.props;
    const { item, type, itemId, copy } = this.state
    const typeText = this.getTypeText(item?.type);

    var nextItemOptions = [];
    if (isActive && [3, 4, 15, 16, 18, 19].includes(type)) {
      nextItemOptions = getNextSectionsForConditions(allSections, sectionId, itemIndex);
    }

    console.log(itemId, 'Itemmmmmmmmm55555555555555555555')
    return (
      <div key={'SectionItem_' + item.ID} className={classes.root} id={'SectionItem_Box_' + itemId}
        onClick={(e) => {
          if (copy === false) {
            this.props.activeItem(true);
          }
        }}
      >
        {!isActive && <div className={classes.activeBox}>
          {<DragIndicatorOutlinedIcon className={classes.dragIndicator} />}
          <p className={classes.head}>{item.name}</p>
          <p className={classes.description}>{item.description}</p>
          <Typography className={classes.type}>{typeText}</Typography>
          {item.type === 1 && <Typography className={classes.character}>Character limit {item.additional_data?.character_limit || "-"}</Typography>}
          <div className={classes.additionalDataContainer}>
            {[3, 4, 5, 19, 20].includes(item.type) && (
              <>
                {item.additional_data.map((data, i) => (
                  <div className={classes.inputRow}>
                    <span className={classes.count}>{i + 1}.</span>
                    <InputField
                      classes={classes}
                      disabled={true}
                      val={data.label}
                      border={false}
                      placeholder={'Enter Option'}
                    />
                  </div>
                ))}
              </>
            )}

            {/* {item.is_other_option && <div className={classes.inputRow}>
              <span className={classes.count}>{item.additional_data.length + 1}.</span>
              <InputField
                classes={classes}
                disabled={true}
                val={'Other'}
                border={false}
              />
            </div>} */}

          </div>

        </div>}

        {isActive && <div className={classes.editItemBox} key={itemId + '_item'}>
          {isNew && <div className={classes.greenLeftBar} id='goToNewSecItem'></div>}
          {!isNew && <div className={classes.leftBar}></div>}
          {!isNew && this.props.type === 'custom' && <DragIndicatorOutlinedIcon className={classes.dragIndicator} />}

          {!isNew && [3, 4, 15, 16, 18, 19].includes(item.type) && nextItemOptions.length > 0 && <IconButton
            className={classes.threeDot}
            onClick={(event) => {
              event.stopPropagation()
              this.setState({ open: true, anchorEl: event.currentTarget || event.current })
            }}
          >
            <MoreVertIcon />
          </IconButton>}

          {<IconButton
            className={([3, 4, 15, 16, 18, 19].includes(item.type) && nextItemOptions.length > 0) ? classes.threeDotForConditional : classes.threeDot}
            onClick={(event) => {
              event.stopPropagation()
              this.props.activeItem(false);
            }}
          >
            <CloseIcon />
          </IconButton>}

          <div className={classes.editItem}>
            <CodeIcon
              onClick={() => {
                this.setState({
                  showProjectSchema: true,
                })
              }}
            />
            <TextField
              id="standard-multiline-flexible"
              multiline
              fullWidth
              maxRows={4}
              value={item.name}
              style={{ fontSize: 16 }}
              // variant="filled"
              placeholder='Question'
              onChange={e => {
                this.handleUpdateItemValue(e.target.value, 'name', 0)
              }}
            />

            <TextField
              id="standard-multiline-flexible"
              multiline
              fullWidth
              maxRows={4}
              value={item.description}
              style={{ fontSize: 16, marginTop: 30 }}
              // variant="filled"
              placeholder='Description (Optional)'
              onChange={e => {
                this.handleUpdateItemValue(e.target.value, 'description', 0)
              }}
            />

            <div className={classes.fieldContainer}>
              <Select
                options={itemTypes}
                isDisabled={false}
                // isDisabled={!(item.isNew === true)}
                defaultValue={itemTypes.filter(it => it.value === type)[0]}
                classNamePrefix="select"
                placeholder={"Select Type"}
                onChange={(e) => this.handleChangeType(e, 0)}
              />
            </div>

            <div className={classes.additionalDataContainer}>
              {item.type === 1 && (
                <TextField
                  id="standard-multiline-flexible"
                  fullWidth
                  value={item.additional_data?.character_limit || ''}
                  style={{ fontSize: 14 }}
                  // variant="filled"
                  placeholder='Character limit'
                  type="number"
                  onChange={e => {
                    this.setState({ item: { ...item, additional_data: { character_limit: e.target.value, type: 0 } } })
                  }}
                />
              )}
              {item.type === 3 && (
                <>
                  {item.additional_data.map((obj, i) => {
                    return <div className={classes.optionContainer}>
                      <div className={classes.inputRowBoolean}>
                        <span className={classes.count}>{i + 1}</span>
                        <InputField
                          disabled={!isActive}
                          classes={{ formControl: classes.input }}
                          val={obj.label}
                          onChange={value => {
                            this.handleChangeBinaryOptionLabel(0, i, value)
                          }}
                        />
                      </div>
                      {item.isTakeConditions && <div className={classes.conditionBox}>
                        <Select
                          isClearable
                          value={obj.condition}
                          options={nextItemOptions}
                          className={classnames(classes.creatable)}
                          classNamePrefix="select"
                          placeholder={"Select Section"}
                          onChange={(e) => {
                            this.handleUpdateCondition(e, 0, i)
                          }}
                        />
                      </div>}
                    </div>
                  })}
                </>
              )}
              {(item.type === 4 || item.type === 19) && (
                <>
                  {item.additional_data.map((data, i) => {
                    return <div className={classes.optionContainer}>
                      <div className={classes.inputContainer1}>
                        <div className={classes.inputRow1}>
                          <span className={classes.count}>{i + 1}.</span>
                          <InputField
                            classes={classes}
                            disabled={!isActive || data.isOtherOption}
                            val={data.label}
                            border={false}
                            placeholder={'Enter Option'}
                            onChange={value => {
                              this.handleEditOption(value, 0, i)
                            }}
                          />
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        {isActive && (
                          <Image
                            src={deleteIcon}
                            className={classes.deleteImage}
                            style={{ top: 15 }}
                            onClick={() => {
                              setTimeout(() => {
                                this.handleRemoveOption(0, i)
                              }, 100)
                            }}
                          />
                        )}
                      </div>
                      {item.isTakeConditions && <div className={classes.conditionBox}>
                        <Select
                          isClearable
                          value={data.condition}
                          options={nextItemOptions}
                          className={classnames(classes.creatable)}
                          classNamePrefix="select"
                          placeholder={"Select Section"}
                          onChange={(e) => {
                            this.handleUpdateCondition(e, 0, i)
                          }}
                        />
                      </div>}
                    </div>
                  })}

                  <div>
                    {isActive && (
                      <div>
                        <span
                          onClick={() => {
                            setTimeout(() => {
                              this.handleAddOption(0)
                            }, 300)
                          }}
                          style={{
                            fontSize: "12px",
                            color: "#4175DF",
                            cursor: "pointer",
                          }}
                        >
                          + Add option
                        </span>
                        {item.type === 19 && !item.is_other_option && <span> or </span>}
                        {item.type === 19 && !item.is_other_option && <span
                          onClick={() => {
                            setTimeout(() => {
                              this.handleAddOtherOption(0)
                            }, 300)
                          }}
                          style={{
                            fontSize: "12px",
                            color: "#4175DF",
                            cursor: "pointer",
                          }}
                        >
                          + Add other
                        </span>}
                      </div>
                    )}
                  </div>
                </>
              )}

              {(item.type === 15 || item.type === 16 || item.type === 18) && item.isTakeConditions && (
                <>
                  {item.additional_data.map((data, i) => {
                    return <div className={classes.numberOptionContainer}>
                      <div className={classes.numberInputCol2}>
                        <OutlinedSelectInput
                          style={{ width: '100%' }}
                          placeholder='Operand'
                          value={data.operation}
                          options={operand}
                          noMargin={true}
                          compact={true}
                          onChange={(e) => {
                            this.handleEditOperand(e, 0, i)
                          }}
                        />
                      </div>
                      <div className={classes.numberInputRow1}>
                        <InputField
                          classes={classes}
                          disabled={!isActive}
                          val={data.label}
                          type={'number'}
                          border={false}
                          placeholder={'Enter Value'}
                          onChange={value => {
                            this.handleEditOption(value, 0, i)
                          }}
                        />
                      </div>
                      {item.isTakeConditions && <div className={classes.conditionBox}>
                        <Select
                          isClearable
                          value={data.condition}
                          options={nextItemOptions}
                          className={classnames(classes.creatable)}
                          classNamePrefix="select"
                          placeholder={"Select Section"}
                          onChange={(e) => {
                            this.handleUpdateCondition(e, 0, i)
                          }}
                        />
                      </div>}


                      {isActive && (
                        <Image
                          src={deleteIcon}
                          className={classes.deleteImage}
                          style={{ top: 15, marginLeft: 10 }}
                          onClick={() => {
                            setTimeout(() => {
                              this.handleRemoveOption(0, i)
                            }, 100)
                          }}
                        />
                      )}
                    </div>
                  })}
                  <div>
                    {isActive && (
                      <div>
                        <span
                          onClick={() => {
                            setTimeout(() => {
                              this.handleAddOptionNumber(0)
                            }, 300)
                          }}
                          style={{
                            fontSize: "12px",
                            color: "#4175DF",
                            cursor: "pointer",
                          }}
                        >
                          + Add Rule
                        </span>
                      </div>
                    )}
                  </div>
                </>
              )}

              {[5, 6, 20].includes(item.type) && (
                <>
                  {item.additional_data.map((data, i) => (
                    <div className={classes.inputContainer} key={'col_' + i}>
                      <div className={classes.inputRow}>
                        <span className={classes.count}>{i + 1}.</span>
                        <InputField
                          classes={classes}
                          disabled={!isActive}
                          val={data.label}
                          border={false}
                          placeholder={`${item.type === 6 ? 'Enter Column' : 'Enter Option'}`}
                          onChange={value => {
                            this.handleEditOption(value, 0, i)
                          }}
                        />
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      {item.type === 6 &&
                        <div style={{ width: 200 }}>
                          <Select
                            options={tableItemTypes}
                            isDisabled={!isActive}
                            value={tableItemTypes.find(type => type.value === data.type) || tableItemTypes[0].value}
                            classNamePrefix="select"
                            placeholder={"Select Type"}
                            onChange={(e) => this.handleChangeTableColumnType(e.value, i)}
                          />
                        </div>}
                      &nbsp;&nbsp;&nbsp;
                      {isActive && (
                        <Image
                          src={deleteIcon}
                          className={classes.deleteImage}
                          style={{ top: 15 }}
                          onClick={() => {
                            setTimeout(() => {
                              this.handleRemoveOption(0, i)
                            }, 100)
                          }}
                        />
                      )}
                    </div>
                  ))}

                  {item.is_other_option && <div className={classes.optionContainer}>
                    <div className={classes.inputContainer1}>
                      <div className={classes.inputRow1W}>
                        <span className={classes.count}>{item.additional_data.length + 1}.</span>
                        <InputField
                          classes={classes}
                          disabled={true}
                          val={'Other'}
                          border={false}
                          placeholder={'Enter Option'}
                          onChange={value => {
                          }}
                        />
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      {isActive && (
                        <Image
                          src={deleteIcon}
                          className={classes.deleteImage}
                          style={{ top: 15 }}
                          onClick={() => {
                            setTimeout(() => {
                              this.handleRemoveOtherOption(0)
                            }, 100)
                          }}
                        />
                      )}
                    </div>
                  </div>}

                  <div>
                    {isActive && (
                      <div>
                        <span
                          onClick={() => {
                            setTimeout(() => {
                              this.handleAddOption(0)
                            }, 300)
                          }}
                          style={{
                            fontSize: "12px",
                            color: "#4175DF",
                            cursor: "pointer",
                          }}
                        >
                          + Add Option
                        </span>
                        {item.type === 20 && !item.is_other_option && <span> or </span>}
                        {item.type === 20 && !item.is_other_option && <span
                          onClick={() => {
                            setTimeout(() => {
                              this.handleAddOtherOptionMultiSelect(0)
                            }, 300)
                          }}
                          style={{
                            fontSize: "12px",
                            color: "#4175DF",
                            cursor: "pointer",
                          }}
                        >
                          + Add other
                        </span>}
                      </div>
                    )}
                  </div>
                </>
              )}

              {item.type === 7 && <div className={classes.inputContainer}>
                <FormControlLabel
                  classes={{
                    root: classes.formConRoot,
                    label: classes.controlLabel
                  }}
                  control={
                    <Checkbox
                      checked={item.additional_data.userProfile || false}
                      onChange={(e) => {
                        this.handleUserProfileCheck(e)
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Include User contact information (Example - Email)"
                />
              </div>}

              {false && item.type === 21 && <div>
                <Select
                  value={item.additional_data.triggerType}
                  options={webHookOptions}
                  className={classnames(classes.creatable)}
                  classNamePrefix="select"
                  placeholder={"Select Trigger Type"}
                  onChange={(e) => {
                    this.handleWebHookOption(0, e)
                  }}
                />

                {item.additional_data.triggerType.value === 'workflow_run.other' && <div>
                  <TextField
                    id="standard-multiline-flexible"
                    multiline
                    fullWidth
                    maxRows={4}
                    value={item.additional_data.triggerURL}
                    style={{ fontSize: 16, marginTop: 30 }}
                    // variant="filled"
                    placeholder='Enter URL'
                    onChange={e => {
                      this.handleWebHookTargetURL(0, e.target.value)
                    }}
                  />
                </div>}

                {item.additional_data.dataFields.length > 0 && <div className={classes.rules}>
                  <table className={classes.ruleTable}>
                    <tbody>
                      {item.additional_data.dataFields.map((rule, k) => {
                        return (
                          <React.Fragment key={k}>
                            <tr className={classes.inputSection}>
                              <td className={classnames(classes.inputController, classes.leftOp)}>
                                <Select
                                  value={rule.form_type}
                                  options={webHookFormsTypeOptions}
                                  className={classnames(classes.creatable)}
                                  classNamePrefix="select"
                                  placeholder={"Select Form Type"}
                                  onChange={(e) => {
                                    this.handleWebHookDataFields(k, e, 'form_type');
                                  }}
                                />
                              </td>
                              <td className={classnames(classes.inputController, classes.leftOp)}>
                                <Select
                                  value={rule.form_id}
                                  options={rule.form_type.value === 'Master' ? this.state.masterForms : this.state.orgForms}
                                  className={classnames(classes.creatable)}
                                  classNamePrefix="select"
                                  placeholder={"Select Form"}
                                  onChange={(e) => {
                                    this.handleWebHookDataFields(k, e, 'form_id');
                                  }}
                                />
                              </td>
                              <td className={classnames(classes.inputController, classes.leftOp)}>
                                <Select
                                  value={rule.field_id}
                                  options={rule.form_id === null || rule.form_id === '' || rule.form_id.value === '' ? [] : rule.form_type.value === 'Master' ? this.state.masterFormsFieldMap[rule.form_id.value] : this.state.orgFormsFieldMap[rule.form_id.value]}
                                  className={classnames(classes.creatable)}
                                  classNamePrefix="select"
                                  placeholder={"Select Field"}
                                  onChange={(e) => {
                                    this.handleWebHookDataFields(k, e, 'field_id');
                                  }}
                                />
                              </td>
                              <td className={classes.action} style={{ width: 20 }}>
                                <IconButton size="small" onClick={() => { this.deleteWebHookFields(rule, k) }}>
                                  <Image alt="Delete" src={deleteIcon} />
                                </IconButton>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>}

                <div style={{ textAlign: 'center', marginTop: 20 }}>
                  <SqureButton
                    cls={classes.actionBtn}
                    variant={"contained"}
                    onClick={() => {
                      this.addNewWebHookFiels();
                    }}>
                    + Add
                  </SqureButton>
                </div>
              </div>}

              {item.type === 22 && <div>
                <SlateInputField
                  folderPath={`/Form/${this.props.formId}/show-message`}
                  placeholder={"Add Message Here"}
                  style={{ margin: 0 }}
                  as={SlateInputField}
                  onChangeEvent={(value) => {
                    this.handleChangeShowMessage(0, value)
                  }}
                  maxLength={10}
                  initValue={item.additional_data.message}
                  actionButtonsRequired={false}
                  formControlStyle={{ overflow: 'overlay' }}
                  textContainerStyle={{ color: "#707070", fontSize: 14 }}
                  isShowAddVariableOption={true}
                />
              </div>}
            </div>
            <div className={classes.bottomActions}>
              {!isNew && <div className={classes.bottomItem}>
                <Image
                  src={deleteIcon}
                  className={classes.deleteImage}
                  onClick={() => {
                    this.props.onDelete()
                  }}
                />
              </div>}

              {!isNew && item.type !== 22 && <Divider orientation="vertical" flexItem className={classes.dividerBtm} />}

              {item.type !== 22 && <div className={classnames(classes.requiredItem, classes.bottomItem)}>
                <span>Required: </span>
                <Switch
                  color="primary"
                  key={item.itemId}
                  value={item.is_required}
                  checked={item.is_required}
                  onChange={() => {
                    this.handleUpdateItemValue(item.is_required ? false : true, 'is_required', 0)
                  }}
                  name="itemRequired" />
              </div>}

              <Divider orientation="vertical" flexItem className={classes.dividerBtm} />

              <div className={classes.bottomItem}>
                <SqureButton
                  variant={"contained"}
                  disabled={false}
                  onClick={this.handleSave}
                >
                  Save
                </SqureButton>
              </div>

              <Popover
                id={item.itemId + '_popover'}
                open={this.state.open}
                anchorEl={this.state.anchorEl}
                onClose={() => this.setState({ anchorEl: null, open: null })}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <List>
                  <ListItem
                    classes={{
                      button: classes.listItem,
                    }}
                    button
                    onClick={e => {
                      e.stopPropagation();
                      this.handleUpdateTakeCondition(item.isTakeConditions ? false : true, 'isTakeConditions', 0);
                      this.setState({ anchorEl: null, open: null })
                    }}
                  >
                    <ListItemIcon classes={{ root: classes.listIcon }}>
                      {!item.isTakeConditions ? (<DoneIcon
                        fontSize={"small"}
                        className={classes.editIcon}
                      />) : (
                        <CloseIcon
                          fontSize={"small"}
                          className={classes.editIcon}
                        />)}

                    </ListItemIcon>
                    <Typography variant="inherit">
                      Go to section based on answer
                    </Typography>
                  </ListItem>
                </List>
              </Popover>

            </div>
          </div>
        </div>}

        {this.state.showProjectSchema && <SchemaTagSelectionDialog
          isOpen={this.state.showProjectSchema}
          loadEverytime={false}
          workflowId={0}
          hideDialog={() => {
            this.setState({
              showProjectSchema: false
            })
          }}
          addTagValue={(value) => {
            this.addTagValue(value)
            this.setState({
              showProjectSchema: false,
            })
          }}
        />
        }
      </div >
    )
  }
}

export default React.memo(withStyles(styles)(FormItem));

const InputField = ({ classes, onChange, val, placeholder, disabled = false, border = true }) => {
  const [value, setValue] = useState("")
  useEffect(() => {
    setValue(val)
  }, [val])
  return (
    <TextField
      fullWidth
      className={classnames(classes.formControl, border ? {} : classes.borderNone)}
      value={value}
      // key={key}
      disabled={disabled}
      InputProps={{ disableUnderline: true }}
      placeholder={placeholder}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    // onBlur={() => {
    //   onChange(value)
    // }}
    />
  )
}