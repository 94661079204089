import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { List, ListItem, Grid, FormControlLabel, Checkbox, } from '@material-ui/core';
import RatingPoper from "./RatingPoper";
import { addRequirementResponse } from "redux/evaluation/criteria/action"
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = () => ({});

const connectionActions = {
  addRequirementResponse: addRequirementResponse,
  showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  responseType: {
    background: '#f1f1f1',
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(1, 1),
    marginTop: theme.spacing(2),
  },
  sliderCount1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    borderRadius: '10px',
    cursor: 'pointer'
  },
  sliderCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    borderRadius: '10px',
    cursor: 'pointer'
  },
  overAllText: {
    marginRight: theme.spacing(2),
    color: '#282D30',
    fontWeight: 400,
    fontSize: theme.spacing(1.8)
  },
  resListItem: {
    marginRight: '5px',
    padding: 0,
    borderRadius: '4px',
    border: '1px solid #707070',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    width: theme.spacing(20),
    fontSize: theme.spacing(1.8)
  },
  resListItemActive: {
    marginRight: '5px',
    padding: 0,
    border: '1px solid #007BF7',
    background: '#007BF7',
    justifyContent: 'center',
    color: ' #fff',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    width: theme.spacing(20),
    fontSize: theme.spacing(1.8)
  },
  resList: {
    display: 'flex',
    flexGrow: 1
  },
  dataCenter: {
    display: 'flex',
    alignItems: 'center',
  }
});

class ProductReqMeet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMoveToNextReq: false
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  render() {
    const classes = this.props.classes;
    const { isShowReqMeet, requirement, response, product, evaluation_id, scoring_mehanism } = this.props;

    let selectOptions = [
      {
        value: 10,
        label: "Yes"
      },
      {
        value: 5,
        label: "Partial"
      },
      {
        value: 1,
        label: "No"
      },
    ];

    return (
      <div>
        {<div className={classes.responseType}>
          <Grid container>
            <Grid item lg={8} className={classes.dataCenter}>
              <div className={classes.sliderCount}>
                <span className={classes.overAllText}>Score </span>
              </div>
            </Grid>
            <Grid item lg={4} >
              <div className={classes.sliderCount1}>
                <FormControlLabel control={
                  <Checkbox
                    className={classes.checkBox}
                    checked={!isShowReqMeet}
                    color="default"
                    onChange={(event) => {
                      var value = 1;
                      var status = isShowReqMeet ? false : true;
                      if (!status) {
                        value = -1;
                      }
                      this.setState({
                        isShowReqMeet: status
                      })
                      let answer = {};
                      if (requirement?.answer_type === 6 || requirement?.answer_type === 5 || requirement?.answer_type === 4) {
                        answer = {
                          note: response == null ? null : response.Note,
                          value: value,
                          response_options: {
                            answer: response?.UserOptions[0]?.Answer
                          }
                        }
                      } else {
                        answer = {
                          note: response == null ? null : response.Note,
                          value: value,
                          response_options: {
                            option_ids: (response == null || response.UserOptions == null ? [] : response.UserOptions.map((i) => {
                              return i.OptionID
                            }))
                          }
                        }
                      }
                      this.props.addRequirementResponse(
                        evaluation_id,
                        product.ID,
                        requirement.id, {
                        ...answer
                      }
                      );
                    }}
                    name="not_applicable"
                  />
                }
                  label="Not Applicable"
                />
              </div>
            </Grid>
          </Grid>

          {isShowReqMeet === true && scoring_mehanism === 'Textual' && <List className={classes.resList} disablePadding={false}>
            {selectOptions.map((item, index) => {
              var clsName = classes.resListItem;
              var isActiveItem = false;
              if (item.value === response?.Response) {
                clsName = classes.resListItemActive;
                isActiveItem = true;
              }
              return (
                <ListItem key={index} className={clsName} 
                onClick={() => {
                  if ((requirement?.answer_type === 4 || requirement?.answer_type === 5) && (response === null || response.UserOptions === null || response.UserOptions.length <= 0 || response.UserOptions[0]?.Answer === undefined || response.UserOptions[0]?.Answer === "")) {
                    var msg = '';
                    var answerType = requirement?.answer_type;
                    if (answerType === 4) {
                      msg = 'Please select Yes or No.';
                    } else {
                      msg = 'Please select a score.';
                    }
                    this.props.showSnackBar(msg, "error", 3000)
                    return;
                  }

                  var reqValue = isActiveItem ? 0 : item.value;

                  let answer = {
                    value: reqValue
                  };
                  this.props.moveToNextReq();
                  this.props.addRequirementResponse(
                    evaluation_id,
                    product.ID,
                    requirement.id, {
                    ...answer
                  }
                  );
                }}>
                  {item.label}
                  </ListItem>)
            }
            )}
          </List>}

          {isShowReqMeet === true && scoring_mehanism === 'Numerical' &&
            <RatingPoper
              step={1}
              maxValue={this.props.maxScore}
              value={response !== null && response?.Response !== null ? response?.Response : 0}
              anchorEl={true}
              onClose={() => {
              }}
              onSave={(value) => {
                let answer = {
                  value: value
                };
                this.props.addRequirementResponse(
                  evaluation_id,
                  product.ID,
                  requirement.id, {
                  ...answer
                }
                );
              }}
            />}
        </div>}
      </div>
    );
  }
}


ProductReqMeet.defaultProps = {
  maxScore : 5,
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ProductReqMeet));
