
import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";
import DefaultImage from "assets/images/evaluation/default.svg";
import { Grid, Typography } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import LayeredCard from "../../../Evaluate/Components/Common/LayeredCard";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class ProductsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { activeProductsTabs, data, label, classes,isCreateGroup, allProductsLength, isOrg } = this.props;

    return (
      <>
        {data && data.length > 0 ? (
          <>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography className={classes.label}>{label}</Typography>
              {!activeProductsTabs && Number(allProductsLength) > 6 && (
                <div onClick={this.props.setActiveProductsTab} className={classes.viewAll}>
                  View All ({allProductsLength})
                </div>
              )}
            </Grid>
            <Grid container spacing={3} style={{ marginBottom: 10 }}>
              {data.map((product, index) => (
                <Grid item lg={2} md={3} sm={4} xs={6} key={"product_" + parseInt(product.id)}>
                  {isCreateGroup ? (
                  <div className={classes.productCard} >  
                    <div className={classes.selectIcon} onClick={(event) => {
                      this.props.handleCusterProduct(product);
                    }}>
                      <Checkbox checked={this.props.isSelectedGroupProduct(parseInt(product.id))} />
                    </div>
                    <LayeredCard
                      title={product.name || product.company_name || ""}
                      subTitle={product.company_name || product.name || ""}
                      description={product.description || ""}
                      image={product.logo == null || product.logo.length === 0 ? DefaultImage:product.logo}
                      onClick={()=>{}}
                      numLayer={0}
                      showCheckbox={false}
                      id={Number(product.id)}
                      uniqueId={product.id+'_x_'+index}
                      IsExisting={isOrg}
                    />
                    </div>) : 
                    <LayeredCard
                      isActionButtons
                      title={product.name || product.company_name || ""}
                      subTitle={product.company_name || product.name || ""}
                      requestLink={product.access_link}
                      description={product.description || ""}
                      image={product.logo || ""}
                      toogleCheck={(e) => {
                        this.props.reviewProduct(product.id);
                      }}
                      onClick={() => {}}
                      numLayer={0}
                      IsExisting={isOrg}
                      showCheckbox={true}
                      id={parseInt(product.id)}
                      uniqueId={product.id + "_" + index}
                      type={"product"}
                      customRoute={"/app/products/org-product-details/"}
                      productStates={this.props.productStates(product.states)}
                      onClickState={(state) => this.props.handleOnClickState(state)}
                      isOrgProduct
                      showReactionResponses
                      typeOfBlock="all_product_blocking"
                      typeOfFeatured="featured"
                      isShowGroupName={this.props.isShowGroupName || false}
                      grpTitleLines={1}
                      groupName={product.groups !== undefined && product.groups !== null && product.groups !== '' && product.groups.length > 0 ? product.groups[0][1] : ''}
                  />
                  }
                </Grid>
              ))}
            </Grid>
          </>
        ):null}
        </>
    );
  }
}

export default connector(compose(withStyles(styles), withLastLocation)(ProductsCard));
