import React from "react";
import classnames from "classnames";
import { createStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox, Typography } from "@material-ui/core";
import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/delete.svg";

const styles = (theme) =>
  createStyles({
    templateCard: {
      border: "2px solid #EEEEEE",
      borderRadius: "10px",
      background: "#fff",
      padding: theme.spacing(2),
      cursor: "pointer",
      minHeight: "78.48px",
      display: "flex",
      flexDirection: "column",
      placeContent: "space-around",
      position: "relative",
    },
    activeTemplate: {
      border: "2px solid #4A87F8",
    },
    templateCardAlign: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // marginBottom: '10px',
      width: "100%",
      "& .checkboxUi": {
        minWidth: "45px",
      },
    },
    templateName: {
      fontSize: theme.spacing(2),
      fontWeight: 400,
    },
    templateSubTitle: {
      fontSize: theme.spacing(1.8),
      fontWeight: 400,
      color: "#4b86ff",
      textDecoration: 'underline',
      marginTop: 3,
    },
    deleteIcon: {
      padding: 4,
      marginLeft: "auto",
      minWidth: "40px",
      textAlign: "right",
      lineHeight: "10px",
      paddingRight: 0,
    },
    templateFlags: {
      display: "flex",
      marginTop: "-12px",
      marginLeft: "-7px",
      "& span": {
        padding: "3px 7px",
        fontSize: "11px",
        textAlign: "left",
        borderRadius: "5px",
        marginRight: "7px",
        opacity: ".8",
        // marginTop: '10px',
        display: "inline-block",
        // position: 'absolute',
        // left: '11px',
        // top: '11px'
      },
      "& .typeE": {
        color: "#155724",
        backgroundColor: "#d4edda",
        borderColor: "#c3e6cb",
      },
      "& .typeR": {
        color: "#721c24",
        backgroundColor: "#f8d7da",
        borderColor: "#f5c6cb",
      },
    },
  });

const TemplateCard = ({
  classes,
  isCheckBox,
  template,
  templateId,
  templateType,
  dflags,
  selectedTemplate,
  selectTemplateForImport,
  deleteFromOrgEvaluationTemplate,
  showUsedPlacesDialog,
  type,
  onClick,
  isChooseTemplate=false
}) => (
  <div
    className={classnames(classes.templateCard, {
      [classes.activeTemplate]: templateId === template.ID,
    })}
    onClick={() => {
      if(isChooseTemplate){
        onClick(type,template.ID)
        return;
      }
      if (!isCheckBox) {
        onClick(
          `/app/settings/templates/${templateType}/${type === 'ORG'? 'custom' : 'default'}/${template.ID}`
        )
      }
    }}
  >
    {false && <div className={classes.templateFlags}>
      {dflags.evaluation && (
        <span className="typeE">Default for Evaluation</span>
      )}
      {dflags.review && <span className="typeR">Default for Review</span>}
    </div>}
    <div className={classnames(classes.templateCardAlign)}>
      {isCheckBox && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="checkboxUi"
        >
          <Checkbox
            checked={
              selectedTemplate !== null &&
              selectedTemplate.templateId === template.ID &&
              selectedTemplate.type === type
            }
            onChange={(event) => {
              selectTemplateForImport(event.target.checked, template.ID, type);
            }}
          />
        </div>
      )}
      <div>
        <Typography variant={"h5"} className={classes.templateName}>
          {template.Name}
        </Typography>
        {template.UsedInWorkflowCount && template.UsedInWorkflowCount > 0 ? <Typography variant={"h5"} className={classes.templateSubTitle} onClick={(e)=>{
          e.stopPropagation();
          showUsedPlacesDialog({...template, dialogType: "EvaluationTemplate"})
        }}>
          Used in {template.UsedInWorkflowCount} workflows
        </Typography> : null }
      </div>
      {!isChooseTemplate && type !== 'MASTER' && (
        <div
          className={classes.deleteIcon}
          onClick={(e) => {
            e.stopPropagation();
            deleteFromOrgEvaluationTemplate(template.ID);
          }}
        >
          <Image alt="Delete" src={deleteIcon} />
        </div>
      )}
    </div>
  </div>
);

export default React.memo(withStyles(styles)(TemplateCard));
