import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import CommonCss from 'commonCss';
import { withRouter } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';

import {
  TextField, Dialog, DialogContent, DialogActions, Slide,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import SqureButton from 'Components/Common/SqureButton';
import * as Validator from "util/Validation";

const connectedProps = (state) => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => createStyles({
  ...CommonCss.ProductDetails,
  formControlDialog: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  formControl: {
    ...CommonCss.formControl,
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 80
  },
  formGroupDialog: {
    marginTop: 5,
    marginBottom: 16,
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  creatable: {
    padding: 0
  },
  searchAndSelect: {
    padding: 0,
    "& .select__control, & .select__control:hover": {
      border: 'none',
      height: 'inherit',
      boxShadow: 'none',
      minHeight: 44
    },
    "& .select__multi-value, & .select__multi-value__label": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20,
      padding: theme.spacing(.3, .6),
      fontSize: 13
    },
    "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20
    },
  },
  error: {
    color: '#f44336'
  },
  alerts: {
    marginBottom: theme.spacing(1)
  },
});

const AddFileDialog = ({
  classes,
  isOpen,
  onSubmit,
  onClose,
  fileName = "",
  fileLink = "",
}) => {
  const [name, setname] = useState(fileName)
  const [link, setlink] = useState(fileLink)
  const [error, setError] = useState('');

  useEffect(() => {
    setlink(fileLink)
    setname(fileName)
  }, [fileLink, fileName])

  const handleSubmit = () => {
    if (name && name.length === 0) {
      setError('Please enter file name');
      return;
    }
    if (link && link.length === 0) {
      setError('Please enter file link');
      return;
    }
    onSubmit({ name, link })
    setname("")
    setlink("")
    onClose()
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="AddUserDialog"
      open={isOpen}
      TransitionComponent={Transition}
      scroll={"body"}
      fullWidth={true}
      className={classes.dialog}
      id={`productBLockDialog-add-file`}
    >
      <DialogContent>
        <div className={classes.formContainer}>
          <div className={classes.alerts}>
            {error.length > 0 && <Alert variant="filled" severity="error">{error}</Alert>}
          </div>
          <label>File Name</label>
          <div className={classes.formGroupDialog}>
            <TextField
              fullWidth
              InputProps={{ disableUnderline: true }}
              className={classes.formControlDialog}
              value={name}
              placeholder="Enter File Name"
              onChange={(e) => {
                setError("");
                setname(e.target?.value)
              }}
            />
          </div>
          <label>File Link</label>
          <div className={classes.formGroupDialog}>
            <TextField
              fullWidth
              disabled={fileLink.length > 0}
              InputProps={{ disableUnderline: true }}
              className={classes.formControlDialog}
              value={link}
              placeholder="Enter File Link"
              onChange={(e) => {
                let error = "";
                if (e.target?.value && !Validator.validateURL(e.target?.value)) {
                  error = "Please enter valid url."
                }
                setlink(e.target?.value)
                setError(error);
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ padding: '10px 24px' }}>
          <SqureButton
            variant={"outlined"}
            onClick={() => {
              setError('')
              onClose()
            }}
          >
            Cancel
          </SqureButton>
          &nbsp; &nbsp; &nbsp;
          <SqureButton
            variant={"contained"}
            onClick={() => {
              handleSubmit()
            }}
          >
            Save
          </SqureButton>
        </div>
      </DialogActions>
    </Dialog >
  )
}

export default connector(
  compose(withRouter, withStyles(styles))(AddFileDialog)
);
