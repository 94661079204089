import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import CustomImage from "./CustomImage";
import AddComponentDialog from "./AddComponentDialog";

const connectedProps = (state) => ({
  workflowComponent: state.master.workflowComponent,
  orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
  masterWorkFlowList: state.masterWorkflow.masterWorkFlowList,
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  addBtn: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  leftAdd: {
    position: 'absolute',
    left: -50,
  },
  rightAdd: {
    position: 'absolute',
    right: -50,
  },
  parallelLine: {
    width: 50,
    height: 1,
    backgroundColor: '#d8d8d8',
    marginLeft: -1,
  },
  add: {
    background: '#fff 0 0 no-repeat padding-box',
    border: '1px solid #d8d8d8',
    borderRadius: '3px',
    color: '#222',
    cursor: 'pointer',
    fontSize: 18,
    opacity: 1,
    padding: 2,
    zIndex: 20,
  },
  actionItem: {
    background: '#fff',
    border: '1px solid #d8d8d8',
    padding: 4,
    borderRadius: 4,
  },
  actionIcon: {
    cursor: 'pointer',
    zIndex: 20
  },
  fromTop1: {
    top: '25%',
  },
  fromTop2: {
    top: '45%',
  }
});

class ParallalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
      targetAction: '',
      isOpenAddComponent: false,
      searchquery: ''
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  render() {

    const { classes, position, workflowComponent, orgWorkFlowList, masterWorkFlowList } = this.props;

    // console.log(workflowComponent,'workflowComponent')
    // console.log(orgWorkFlowList,'orgWorkFlowList')
    // console.log(masterWorkFlowList,'masterWorkFlowList')

    const { showActions, isOpenAddComponent, targetAction, searchquery } = this.state;
    return <div>
      {position === 'left' && <div className={classnames(classes.addBtn, classes.leftAdd, showActions ? classes.fromTop1 : classes.fromTop2)}>
        {!showActions && <AddIcon
          className={classes.add}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              showActions: true
            })
          }}
        />}

        {showActions && <div className={classes.actionItem}>
          <div className={classes.actionIcon}>
            <CustomImage
              slug="WORKFLOW_DARK"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  targetAction: 'workflow',
                  isOpenAddComponent: true,
                })
              }}
            />
          </div>
          &nbsp;
          <div className={classes.actionIcon}>
            <CustomImage
              slug="COMPONENT"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  targetAction: 'component',
                  isOpenAddComponent: true,
                })
              }}
            />
          </div>
        </div>}
        <div className={classes.parallelLine}></div>
      </div>}

      {position === 'right' && <div className={classnames(classes.addBtn, classes.rightAdd, showActions ? classes.fromTop1 : classes.fromTop2)}>
        <div className={classes.parallelLine}></div>
        {!showActions && <AddIcon
          className={classes.add}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              showActions: true
            })
          }}
        />}

        {showActions && <div className={classes.actionItem}>
          <div className={classes.actionIcon}>
            <CustomImage
              slug="WORKFLOW_DARK"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  targetAction: 'workflow',
                  isOpenAddComponent: true,
                })
              }}
            />
          </div>
          &nbsp;
          <div className={classes.actionIcon}>
            <CustomImage
              slug="COMPONENT"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  targetAction: 'component',
                  isOpenAddComponent: true,
                })
              }}
            />
          </div>
        </div>}
      </div>}

      {isOpenAddComponent && <AddComponentDialog
        isOpen={isOpenAddComponent}
        targetAction={targetAction}
        showActions={showActions}
        searchquery={searchquery}
        workflows={workflowComponent}
        orgWorkflows={orgWorkFlowList?.Data?.length > 0 ? orgWorkFlowList?.Data : []}
        masterWorkflows={masterWorkFlowList?.Data?.length > 0 ? masterWorkFlowList?.Data : []}
        workflowComponent={workflowComponent}
        orgWorkFlowList={orgWorkFlowList}
        masterWorkFlowList={masterWorkFlowList}
        setTargetAction={(type) => {
          this.setState({
            targetAction: type
          })
        }}
        onSelectCompoent={(e, componentId) => {
          this.setState({
            isOpenAddComponent: false
          }, () => {
            this.props.onSelectCompoent(e, componentId);
          })
        }}
        addWorkFlowComponent={(data) => {
          this.setState({
            isOpenAddComponent: false
          }, () => {
            this.props.onClickWorkFlowComponent(data.event, data.type, data.id, data.name, data.description)
          })
        }}
        hideDialog={() => {
          this.setState({
            isOpenAddComponent: false
          })
        }}
      />
      }
    </div>
  }
}

export default connector(compose(withRouter, withStyles(styles))(ParallalComponent));
