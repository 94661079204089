import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { showSnackBar } from "redux/snackbar/action";
import { CircularProgress } from "@material-ui/core";
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Paper, Radio } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
import { CommonFn } from "services/commonFn";
import MessageDialog from "Components/Common/MessageDialog";
import { showMessageDialog, hideMessageDialog } from "redux/applications/action";
import Image from "Components/Common/image.jsx";
import editIcon from "assets/images/edit_icon.svg";
import deleteIcon from "assets/images/delete.svg";
import { fetchVendorCatalogViews,updateCatalogViews, fetchServiceCatalogViews, deleteCatalogViews } from "redux/vendor/action";
import { addOrgSettings, fetchAllOrgSettings } from "redux/org/user/action";
import UpdateFieldsDialog from "../../Dialog/UpdateViewDialog";
import { getConfigValue } from "util/Common";

const connectedProps = (state) => ({
  orgUser: state.orgUser,

  vendorCatalogViews: state.vendors.vendorCatalogViews,
  fetchVendorCatalogViewsProgress: state.vendors.fetchVendorCatalogViewsProgress,
  fetchVendorCatalogViewsError: state.vendors.fetchVendorCatalogViewsError,

  serviceCatalogViews: state.vendors.serviceCatalogViews,
  fetchServiceCatalogViewsProgress: state.vendors.fetchServiceCatalogViewsProgress,
  fetchServiceCatalogViewsError: state.vendors.fetchServiceCatalogViewsError,

  updateCatalogViewsProgress: state.vendors.updateCatalogViewsProgress,
  updateCatalogViewsError: state.vendors.updateCatalogViewsError,

  deleteCatalogViewsProgress: state.vendors.deleteCatalogViewsProgress,
  deleteCatalogViewsError: state.vendors.deleteCatalogViewsError,
});

const connectionActions = {
  fetchVendorCatalogViews: fetchVendorCatalogViews,
  fetchServiceCatalogViews: fetchServiceCatalogViews,
  updateCatalogViews: updateCatalogViews,
  deleteCatalogViews: deleteCatalogViews,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  showSnackBar: showSnackBar,
  addOrgSettings: addOrgSettings,
  fetchAllOrgSettings: fetchAllOrgSettings,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      "& thead": {
        "& span": {
          fontWeight: 600,
        },
      },
    },
    searchSection: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      // marginRight: 40,
      '& [class*="MuiOutlinedInput-root"]': {
        maxHeight: 40,
        minHeight: 40,
        maxWidth: 343,
      },
      "& [class*='MuiInputBase-input']": {
        fontSize: 13,
        width: 300,
      },
      "& [class*='MuiSvgIcon-root']": {
        fill: "#707070",
      },
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
      "&>div ": {
        marginBottom: 0,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "16px",
      },
    },
    thead: {
      width: "20%",
    },
    action: {
      width: "15%",
      minWidth: "80px",
    },
    userCount: {
      color: "#282D30",
      fontSize: theme.spacing(2.5),
    },
    userSection: {
      margin: theme.spacing(3, 0),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    table: {},
    tbodyCell: {
      padding: theme.spacing(1.5, 0),
      height: 50,
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    menuItem: {
      color: "#74798C",
      fontSize: theme.spacing(2),
      padding: theme.spacing(2.2),
      minWidth: 140,
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    edit: {
      width: 18,
      cursor: "pointer",
      padding: theme.spacing(0.1),
      marginRight: theme.spacing(2.5),
      fill: "#4a88ff",
    },
    delete: {
      fill: "#dc2f3e",
    },

    searchInput: {
      border: "1px solid #EEEEEE",
      borderRadius: 5,
      height: 32,
      minWidth: 300,
      paddingLeft: 28,
    },
    tableSection: {
      marginTop: theme.spacing(6.4),
      minHeight: 200,
      maxHeight: "calc(100vh - 250px)",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    searchIconPop: {
      cursor: "pointer",
      fontSize: theme.spacing(3),
      marginRight: "10px",
      "&:hover": {
        color: "#fff !important",
        fill: "#fff !important",
        background: "#707070",
        borderColor: "#707070",
        padding: "3px",
        borderRadius: theme.spacing(20),
        fontSize: theme.spacing(3),
      },
    },
    fullSceen: CommonCss.backdrop,
    actionCol: {
      textAlign: "center",
      width: "20%",
    },
  });

class ViewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      deleteId: "",
      openUpdateViewDialog: false,
      updateViewData: null,
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.deleteCatalogViewsProgress && prevProps.deleteCatalogViewsProgress) {
      if (this.props.deleteCatalogViewsError) {
        this.props.showSnackBar(this.props.deleteCatalogViewsError?.message, "error", 3000);
      } else {
        this.setState({ page: 0 }, () => {
          this.fetchData();
          this.props.showSnackBar("Dashboard successfully deleted", "success", 3000);
        });
      }
    }

    if (!this.props.updateCatalogViewsProgress && prevProps.updateCatalogViewsProgress) {
      if (this.props.updateCatalogViewsError) {
        this.props.showSnackBar("Failed to successfully", "error", 3000)
      } else {
        this.fetchData();
        this.setState({openUpdateViewDialog: false, updateViewData: null})
        this.props.showSnackBar("Updated Successfully", "success", 3000);
      }
    }

    if (this.props.orgUser.addOrgSettingsInprogress === false && prevProps.orgUser.addOrgSettingsInprogress === true ) {
      if (this.props.orgUser.addOrgSettingsFailure == null) {
        this.props.fetchAllOrgSettings();
        this.props.showSnackBar("Updated Successfully.", "success", 3000)
      } else {
        this.props.showSnackBar("Failed to update.", "error", 3000)
      }
    }
  }

  fetchData = () => {
    if(this.props.type === 'vendor'){
      this.props.fetchVendorCatalogViews();
    }else {
      this.props.fetchServiceCatalogViews();
    }
  };

  handleClear(event) {
    event.stopPropagation();
    var me = this;
    this.setState({ query: "" }, () => {
      me.fetchData();
    });
  }

  handleDelete(data) {
    this.setState({ deleteId: data.id }, () => {
      this.props.showMessageDialog();
    });
  }

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteCatalogViews(this.state.deleteId);
    }
  };

  render() {
    const { classes, vendorCatalogViews, serviceCatalogViews,fetchVendorCatalogViewsProgress } = this.props;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };
    let allViews = [];
    let defaultViewId = '';
    let defaultKey = ""
    if(this.props.type === 'vendor'){
      defaultViewId = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "VendorTabularView");
      allViews = vendorCatalogViews;
      defaultKey = "VendorTabularView"
    }else {
      defaultViewId = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "ServiceTabularView");
      allViews = serviceCatalogViews;
      defaultKey = "ServiceTabularView"
    }

    const loading = this.props.orgUser?.addOrgSettingsInprogress || fetchVendorCatalogViewsProgress || this.props.orgUser?.fetchAllOrgSettingsInprogress

    return (
      <div className={classes.root}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "65vh" }}>
            <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={"table_" + this.props.key}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.nameCol}>Id</TableCell>
                  <TableCell className={classes.statusCol}>Name</TableCell>
                  <TableCell className={classes.statusCol}>Created</TableCell>
                  <TableCell className={classes.actionCol}>Action</TableCell>
                  <TableCell className={classes.statusCol}>Default View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && allViews?.map((item,key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell className={classes.statusCol}>{key+1}</TableCell>
                      <TableCell className={classes.statusCol}>{item.name}</TableCell>
                      <TableCell className={classes.statusCol}>{CommonFn.formateDate(item.created_at, true)}</TableCell>
                      <TableCell className={classes.actionCol}>
                        <IconButton onClick={() => this.setState({openUpdateViewDialog: true, updateViewData: item})}>
                          <Image src={editIcon} />
                        </IconButton>
                        <IconButton onClick={() => this.handleDelete(item)}>
                          <Image src={deleteIcon} />
                        </IconButton>
                      </TableCell>
                      <TableCell className={classes.statusCol}>
                        <Radio value={item.id} checked={defaultViewId === item.id} onChange={(event) => {
                          if(event.target.value !== defaultViewId) {
                            let data = [{
                              name: defaultKey,
                              value: item.id,
                            }];
                            this.props.addOrgSettings(data);
                          }
                        }} />
                      </TableCell>
                    </TableRow>
                  );
                })}

                {!loading && allViews?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }} className={classes.nameCol}>
                      No Views
                    </TableCell>
                  </TableRow>
                )}

                {loading && (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }} className={classes.nameCol}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <MessageDialog messageData={messageData} callBack={this.callBack} />
        {this.state.openUpdateViewDialog && <UpdateFieldsDialog 
          data={this.state.updateViewData} 
          onClose={() => this.setState({openUpdateViewDialog: false, updateViewData: null})}
          onSubmit={(name, itemIds) => {
            this.props.updateCatalogViews(this.state.updateViewData?.id, {name: name, item_slugs: itemIds})
          }}
        />}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(ViewList));
