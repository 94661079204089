import { Dialog, DialogContent, Slide, Typography, CircularProgress } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Alert from "@material-ui/lab/Alert";
import CommonCss from "commonCss";
import SqureButton from "Components/Common/SqureButton";
import * as Validator from "util/Validation";

import { hideAddOauthDialog, addNewOauth } from "redux/oauth/action";
import { showSnackBar } from "redux/snackbar/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.oauth.dialogAddOauthOpen,
  addNewOauthProgress: state.oauth.addNewOauthProgress,
});

const connectionActions = {
  hideDialog: hideAddOauthDialog,
  addNewOauth: addNewOauth,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
      marginBottom: 15,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    oauth: {
      marginBottom: 10,
      marginTop: 25,
    },
    body: {
      paddingTop: 20,
      "& [class*='editableField']": {
        maxHeight: "105px !important;",
        minHeight: "50px !important",
      },
    },
    formControl: {
      width: "100%",
      "& > div": {
        color: "#282D30",
        fontSize: theme.spacing(2.2),
      },
    },
    appTitle: {
      marginBottom: theme.spacing(1),
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    inputItem: {
      '& [class*="MuiInputBase-input"]': {
        padding: 12,
      },
    },
    inputFormControl: {
      ...CommonCss.formControl,
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
      },
      padding: "10.5px",
    },
    bottomActions: {
      textAlign: "right",
    },
    actionBtn: {
      marginLeft: 10,
      marginTop: 20,  
    },
    required: {
      color: "red",
    },
    errorMessage: {
      color: "red",
      fontWeight: "500",
      fontSize: "12px",
      marginLeft: "8px",
    },
    formItem: {
      marginBottom: 20
    }
  });

class AddOauthDialog extends React.Component {
  state = {
    error: "",
    name: "",
    nameError: "",
    email: "",
    emailError: "",
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  clearError = () => {
    this.setState({
      error: "",
      nameError: "",
      emailError: "",
    });
  };

  addOauth = () => {
    this.clearError();
    const { name, email} = this.state;
    if (name === "" || name.length <= 0) {
      this.setState({
        nameError: "Please enter application name",
      });
      return;
    }

    if(email == null || String(email).trim().length === 0){
      this.setState({emailError:"Please provide integration email."});
      return;
    }

    if(Validator.validateEmail(email) === false){
        this.setState({emailError:"Please provide valid email."});
        return;
    }

    this.props.addNewOauth({name,email});
  };

  render() {
    const classes = this.props.classes;
    const { name, email } = this.state;

    return (
      <Dialog
        onClose={this.props.hideDialog}
        aria-labelledby="app-applicationDialog"
        open={this.props.isOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"sm"}
        scroll="body"
        id="evMetaDialog"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.hideDialog()}>
            <CloseIcon />
          </div>
          <Typography variant={"h4"} className={classes.title}>
            Add Application
          </Typography>
          <div className={classes.body}>
            {this.state.error.length > 0 && (
              <div className={classes.oauth}>
                <Alert variant="filled" severity="error">
                  {this.state.error}
                </Alert>
              </div>
            )}
            <div className={classes.formItem}>
              <Typography variant={"h4"} className={classes.appTitle}>
                Application Name<span className={classes.required}>*</span>
              </Typography>
              <input
                className={classes.inputFormControl}
                placeholder="Enter application name"
                name="name"
                value={name}
                onChange={(e) => {
                  this.setState(
                    {
                      name: e.target.value,
                    },
                    () => {
                      this.clearError();
                    }
                  );
                }}
              />
              {this.state.nameError.length > 0 && <div className={classes.errorMessage}>{this.state.nameError}</div>}
            </div>
            
            <div className={classes.formItem}>
              <Typography variant={"h4"} className={classes.appTitle}>
                Integration Email<span className={classes.required}>*</span>
              </Typography>
              <input
                className={classes.inputFormControl}
                placeholder="Enter integration email"
                name="email"
                value={email}
                onChange={(e) => {
                  this.setState(
                    {
                      email: e.target.value,
                    },
                    () => {
                      this.clearError();
                    }
                  );
                }}
              />
              {this.state.emailError.length > 0 && <div className={classes.errorMessage}>{this.state.emailError}</div>}
            </div>
            
            <div className={classes.bottomActions}>
              <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>
                Cancel
              </SqureButton>
              {this.props.addNewOauthProgress ? (
                <SqureButton cls={classes.actionBtn} variant={"contained"}>
                  <CircularProgress style={{ width: 24, height: 24 }} />
                </SqureButton>
              ) : (
                <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.addOauth}>
                  Add
                </SqureButton>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

AddOauthDialog.defaultProps = {
  isWorkFlow: false,
};

export default connector(compose(withRouter, withStyles(styles))(AddOauthDialog));
