import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import Home from "./Components/Home";
import { updateDrawerStyle } from "redux/drawer/action";
import Summary from "./Components/Summary";

// views
const connectedProps = (state) => ({
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class Survey extends React.Component {

  componentDidMount() {
    this.props.updateDrawerStyle(true, true);
  }

  render() {

    console.log(this.props.match.path,'aaaaaaaaaaaaaaaa')
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={this.props.match.path} component={Home} />
          <Route
            path={`${this.props.match.path}/:alert_id`}
            component={Summary}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default connector(compose(withStyles(styles))(Survey));
