import { 
    SHOW_ADD_NEW_EVALUATION_DIALOG, HIDE_ADD_NEW_EVALUATION_DIALOG,
    SHOW_PRODUCT_RANKING_DIALOG, HIDE_PRODUCT_RANKING_DIALOG,
    SHOW_PRODUCTS_REVIEW_FILTER_DIALOG, HIDE_PRODUCTS_REVIEW_FILTER_DIALOG,
    START_VENDORD_EVALUATION_PROGRESS,END_VENDORD_EVALUATION_PROGRESS, 
    START_EVALUATE_STATUS_COUNT, END_EVALUATE_STATUS_COUNT, 
    START_INPROGESS_EVALUATION , END_INPROGESS_EVALUATION, 
    START_COMPLETED_EVALUATION, END_COMPLETED_EVALUATION,
    SHOW_EVALUATION_COLLABORATOR_DIALOG, HIDE_EVALUATION_COLLABORATOR_DIALOG,
    START_DELETE_EVALUATE_COLLABORATOR, END_DELETE_EVALUATE_COLLABORATOR,
    START_DELETE_CRITERIA_COLLABORATOR, END_DELETE_CRITERIA_COLLABORATOR,
    START_DELETE_REQUIREMENT_COLLABORATOR, END_DELETE_REQUIREMENT_COLLABORATOR,
    START_FETCH_SIMILAR_QUESTION, END_FETCH_SIMILAR_QUESTION
} from "./action";

const initState  =  {
    dataProductRanking: null, 

    // Dialogs
    addNewEvaluationDialogOpen: false,
    stepTwo: false,
    productRankingDialogOpen: false,
    collectiveResponseDialogOpen: false,
    productsReviewFilterDialog: false,
    productsReviewFilterData: null,

    // API's
    vendorEvaluationInProgress: false,
    vendorEvaluations: {},
    evaluateStatusCountProgress : false,
    statusCount: [],
    inProgressEvaluationProgress: false,
    inProgress: [],
    inProgressTotal: 0,
    completedEvaluationProgress:false,
    completed: [],
    completedTotal: 0,

    evaluationCollaboratorDialogOpen: false,
    evaluationCollaboratorDialogData: null,

    deleteEvaluateCollaboratorInprogress: false,
    deleteEvaluateCollaboratorSuccess: null,
    deleteEvaluateCollaboratorError: null,

    deleteCriteriaCollaboratorInprogress: false,
    deleteCriteriaCollaboratorSuccess: null,
    deleteCriteriaCollaboratorError: null,

    deleteRequirementCollaboratorInprogress: false,
    deleteRequirementCollaboratorSuccess: null,
    deleteRequirementCollaboratorError: null,
    similarQuestion: [],
    similarQuestionProg:false
}

export  function evaluateReducer(state=initState, action){
    switch(action.type){
        case START_FETCH_SIMILAR_QUESTION:
            return {
                ...state,
                similarQuestion: [],
                similarQuestionProg: true
            };
        case END_FETCH_SIMILAR_QUESTION:
            let similarQuestion = state.similarQuestion;
            if(action.payload.success !== null && action.payload.success?.data && Array.isArray(action.payload.success?.data))  {
                similarQuestion = action.payload.success.data
            }
            return {
                ...state,
                similarQuestion,
                similarQuestionProg: false
            };
        case START_EVALUATE_STATUS_COUNT:
            return {
                ...state,
                evaluateStatusCountProgress: true
            };

        case END_EVALUATE_STATUS_COUNT:
            var countData     =   action?.payload?.success || [];
            return {
                ...state,
                evaluateStatusCountProgress: false,
                statusCount: countData
            };

        case SHOW_ADD_NEW_EVALUATION_DIALOG:
            return {
                ...state,
                addNewEvaluationDialogOpen: true,
                stepTwo: action?.payload?.stepTwo || false
            };

        case HIDE_ADD_NEW_EVALUATION_DIALOG:
            return {
                ...state,
                addNewEvaluationDialogOpen: false,
                stepTwo: false
            };

        case SHOW_PRODUCT_RANKING_DIALOG:
            var data     =   action.payload.data;
            var dataProductRanking =  state.dataProductRanking

            if(data !== null){
                dataProductRanking = data
            }
            return {
                ...state,
                productRankingDialogOpen: true,
                dataProductRanking: dataProductRanking
            };

        case HIDE_PRODUCT_RANKING_DIALOG:
            return {
                ...state,
                productRankingDialogOpen: false
            };

        case SHOW_PRODUCTS_REVIEW_FILTER_DIALOG:
            var selectedReview     =   action.payload.selectedReview;
            var daterange = 0;
            return {
                ...state,
                productsReviewFilterData: {
                    selectedReview,
                    daterange
                },
                productsReviewFilterDialog: true
            };

        case HIDE_PRODUCTS_REVIEW_FILTER_DIALOG:
            var selectedReview     =   state.productsReviewFilterData.selectedReview;
            var daterange = action.payload.daterange;
            return {
                ...state,
                productsReviewFilterData: {
                    selectedReview,
                    daterange
                },
                productsReviewFilterDialog: false
            };

        // Vendor Evaluation
        case START_VENDORD_EVALUATION_PROGRESS:
            var response     =   action.payload;
            if(response.onInit){
                return {
                    ...state,
                    vendorEvaluationInProgress:true,
                    vendorEvaluations: {}
                }
            }
            return {
                ...state,
                vendorEvaluationInProgress:true,
            }
        case END_VENDORD_EVALUATION_PROGRESS:
            var evaluation  =   {
                Data:[],
                total:0
            };
            var response     =   action.payload.success;
            if(response !== null){
                // component init
                if(response.onInit){
                    evaluation.total = response.total;
                    evaluation.Data = response.Data
                }else{
                    // on pagination
                    evaluation.total = response.total;
                    if(state.vendorEvaluations?.Data?.length > 0){
                        evaluation.Data = [...state.vendorEvaluations.Data, ...response.Data];
                    }else{
                        evaluation.Data = response.Data
                    }
                }
            }
            return {
                ...state,
                vendorEvaluationInProgress:false,
                vendorEvaluations:evaluation
            }
        case START_COMPLETED_EVALUATION:
            return {
                ...state,
                completedEvaluationProgress:true,
                completed: [],
                completedTotal: 0
            }
        case END_COMPLETED_EVALUATION:
            var evaluation  =   {
                Data:[],
                total:0
            };
            var response     =   action.payload.success;
            if(response !== null){
                evaluation.total = response.total;
                evaluation.Data = response.Data
            }
            return {
                ...state,
                completedEvaluationProgress:false,
                completed:evaluation.Data,
                completedTotal: evaluation.total
            }
        case START_INPROGESS_EVALUATION:
                return {
                    ...state,
                    inProgressEvaluationProgress:true,
                    inProgress: [],
                    inProgressTotal: 0
            }
        case END_INPROGESS_EVALUATION:
            var evaluation  =   {
                Data:[],
                total:0
            };
            var response     =   action.payload.success;
            if(response !== null){
                evaluation.total = response.total;
                evaluation.Data = response.Data
            }
            return {
                ...state,
                inProgressEvaluationProgress:false,
                inProgress:evaluation.Data,
                inProgressTotal: evaluation.total
            }

        case SHOW_EVALUATION_COLLABORATOR_DIALOG:
            return {
                ...state,
                evaluationCollaboratorDialogOpen: true,
                evaluationCollaboratorDialogData: action.payload.target || null
            };

        case HIDE_EVALUATION_COLLABORATOR_DIALOG:
            return {
                ...state,
                evaluationCollaboratorDialogOpen: false,
                evaluationCollaboratorDialogData: null
            };
            

        case START_DELETE_EVALUATE_COLLABORATOR:{
            let result = {};
            result.deleteEvaluateCollaboratorInprogress = true;
            result.deleteEvaluateCollaboratorSuccess = null;
            result.deleteEvaluateCollaboratorError = null;
            return {
                ...state,
                ...result,
            };
        }
        
        case END_DELETE_EVALUATE_COLLABORATOR: {
            let success = action.payload.success;
            let error = action.payload.error;
            let result = {};
            result.deleteEvaluateCollaboratorInprogress = false;
            if(error){
                result.deleteEvaluateCollaboratorSuccess = null;
                result.deleteEvaluateCollaboratorError = error;
            }else{
                result.deleteEvaluateCollaboratorSuccess = success;
                result.deleteEvaluateCollaboratorError = null;
            }
            return {
                ...state,
                ...result,
            };
        }


        case START_DELETE_CRITERIA_COLLABORATOR:{
            let result = {};
            result.deleteCriteriaCollaboratorInprogress = true;
            result.deleteCriteriaCollaboratorSuccess = null;
            result.deleteCriteriaCollaboratorError = null;
            return {
                ...state,
                ...result,
            };
        }

        case END_DELETE_CRITERIA_COLLABORATOR: {
            let success = action.payload.success;
            let error = action.payload.error;
            let result = {};
            result.deleteCriteriaCollaboratorInprogress = false;
            if(error){
                result.deleteCriteriaCollaboratorSuccess = null;
                result.deleteCriteriaCollaboratorError = error;
            }else{
                result.deleteCriteriaCollaboratorSuccess = success;
                result.deleteCriteriaCollaboratorError = null;
            }
            return {
                ...state,
                ...result,
            };
        }

        case START_DELETE_REQUIREMENT_COLLABORATOR:{
            let result = {};
            result.deleteRequirementCollaboratorInprogress = true;
            result.deleteRequirementCollaboratorSuccess = null;
            result.deleteRequirementCollaboratorError = null;
            return {
                ...state,
                ...result,
            };
        }

        case END_DELETE_REQUIREMENT_COLLABORATOR: {
            let success = action.payload.success;
            let error = action.payload.error;
            let result = {};
            result.deleteRequirementCollaboratorInprogress = false;
            if(error){
                result.deleteRequirementCollaboratorSuccess = null;
                result.deleteRequirementCollaboratorError = error;
            }else{
                result.deleteRequirementCollaboratorSuccess = success;
                result.deleteRequirementCollaboratorError = null;
            }
            return {
                ...state,
                ...result,
            };
        }

      

        default:
            return state;
    }
}  
