
/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {HttpFactory} from "../../services/httpService";
import * as Environment from "../../util/Environment";

export const SHOW_ADD_COMPANY_PROFILE_DIALOG = "SHOW_ADD_COMPANY_PROFILE_DIALOG";
export const HIDE_ADD_COMPANY_PROFILE_DIALOG = "HIDE_ADD_COMPANY_PROFILE_DIALOG";
export const START_COMPANY_PROFILE_FETCH = "START_COMPANY_PROFILE_FETCH";
export const END_COMPANY_PROFILE_FETCH = "END_COMPANY_PROFILE_FETCH";
export const START_COMPANY_PROFILE_UPDATE = "START_COMPANY_PROFILE_UPDATE";
export const END_COMPANY_PROFILE_UPDATE = "END_COMPANY_PROFILE_UPDATE";


export function showAddCompanyProfileDialog(){
    return {
        type: SHOW_ADD_COMPANY_PROFILE_DIALOG,
        payload: null
    };
}

export function hideAddCompanyProfileDialog(){
    return {
        type: HIDE_ADD_COMPANY_PROFILE_DIALOG,
        payload:null
    };
}


function startCompanyProfileFetch(){
    return {
        type:START_COMPANY_PROFILE_FETCH,
        payload:null
    };
}

function endCompanyProfileFetch(success,error){
    return {
        type:END_COMPANY_PROFILE_FETCH,
        payload:{
            success,error
        }
    };
}

export  function fetchCompanyProfile(){
    const api_server = Environment.api_host("USER");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/org-profile?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startCompanyProfileFetch());
        try{
            let [response,error] = await HttpFactory.instance().getMethod(url);
            dispatch(endCompanyProfileFetch(response,error));
        } catch(e){
            dispatch(endCompanyProfileFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startCompanyProfileUpdate(){
    return {
        type:START_COMPANY_PROFILE_UPDATE,
        payload:null
    };
}

function endCompanyProfileUpdate(success,error){
    return {
        type:END_COMPANY_PROFILE_UPDATE,
        payload:{
            success,error
        }
    };
}

export  function updateCompanyProfile(params){
    const api_server = Environment.api_host("USER");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/org-profile?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startCompanyProfileUpdate());
        try{
            let [response,error] = await HttpFactory.instance().putMethod(url, params);
            dispatch(endCompanyProfileUpdate(response,error));
        } catch(e){
            dispatch(endCompanyProfileFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 
