import {
  START_SERVICE_CATALOG_FETCH,
  END_SERVICE_CATALOG_FETCH,
  START_SERVICE_CATALOG_TAB_DATA_FETCH,
  END_SERVICE_CATALOG_TAB_DATA_FETCH,
  START_ORG_SERVICE_ADD,
  END_ORG_SERVICE_ADD,
  START_DELETE_ORG_SERVICE,
  END_DELETE_ORG_SERVICE,
  SHOW_ADD_SERVICE_DIALOG,
  HIDE_ADD_SERVICE_DIALOG,
  START_UPDATE_ORG_SERVICE_TAB_SECTION_ITEM_DATA,
  END_UPDATE_ORG_SERVICE_TAB_SECTION_ITEM_DATA,
} from "./action";

const initState = {
  fetchServiceCatalogProgress: false,
  fetchServiceCatalogError: null,
  ServiceCatalog: null,

  fetchServiceCatalogTabDataProgress: false,
  fetchServiceCatalogTabDataError: null,
  serviceCatalogTabData: {},

  orgServiceAddProgress: false,
  orgServiceAddError: null,

  deleteOrgServiceProgress: false,
  deleteOrgServiceError: null,

  showAddServiceDialog: false,

  orgServiceTabSectionItemDataUpdateProgress: false,
  orgServiceTabSectionItemDataUpdateError: null,
};

export function serviceCatalogReducer(state = initState, action) {
  switch (action.type) {
    case START_SERVICE_CATALOG_FETCH:
      return {
        ...state,
        fetchServiceCatalogProgress: true,
        fetchServiceCatalogError: null,
      };

    case END_SERVICE_CATALOG_FETCH: {
      let currentData = { ...state.ServiceCatalog };
      if (action.payload?.searchQuery?.page > 0) {
        currentData?.items?.push(...action.payload?.success?.items);
      } else {
        currentData = action.payload.success;
      }
      return {
        ...state,
        fetchServiceCatalogProgress: false,
        fetchServiceCatalogError: action.payload.error,
        ServiceCatalog: currentData,
      };
    }
    case START_SERVICE_CATALOG_TAB_DATA_FETCH:
      return {
        ...state,
        fetchServiceCatalogTabDataProgress: true,
        fetchServiceCatalogTabDataError: null,
      };

    case END_SERVICE_CATALOG_TAB_DATA_FETCH: {
      return {
        ...state,
        fetchServiceCatalogTabDataProgress: false,
        fetchServiceCatalogTabDataError: action.payload.error,
        serviceCatalogTabData: action.payload.success,
      };
    }
    case SHOW_ADD_SERVICE_DIALOG:
      return {
        ...state,
        addServiceDialogOpen: true,
      };

    case HIDE_ADD_SERVICE_DIALOG:
      return {
        ...state,
        addServiceDialogOpen: false,
      };
    case START_DELETE_ORG_SERVICE:
      return {
        ...state,
        deleteOrgServiceProgress: true,
        deleteOrgServiceError: null,
      };
    case END_DELETE_ORG_SERVICE:
      return {
        ...state,
        deleteOrgServiceProgress: false,
        deleteOrgServiceError: action.payload.error,
      };
    case START_UPDATE_ORG_SERVICE_TAB_SECTION_ITEM_DATA:
      return {
        ...state,
        orgServiceTabSectionItemDataUpdateProgress: true,
        orgServiceTabSectionItemDataUpdateError: null,
      };
    case END_UPDATE_ORG_SERVICE_TAB_SECTION_ITEM_DATA:
      let { item_slug, success } = action.payload;

      let newServiceCatalogTabData = { ...state.serviceCatalogTabData };
      if (success && item_slug) {
        newServiceCatalogTabData[item_slug] = success;
      }
      return {
        ...state,
        serviceCatalogTabData: newServiceCatalogTabData,
        orgServiceTabSectionItemDataUpdateProgress: false,
        orgServiceTabSectionItemDataUpdateError: action.payload.error,
      };

    case START_ORG_SERVICE_ADD:
      return {
        ...state,
        orgServiceAddProgress: true,
        orgServiceAddError: null,
      };
    case END_ORG_SERVICE_ADD:
      return {
        ...state,
        orgServiceAddProgress: false,
        orgServiceAddError: action.payload.error,
      };
    default:
      return state;
  }
}
