import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { Grid } from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';

import SqureButton from 'Components/Common/SqureButton';
import Image from 'Components/Common/image.jsx'
import ArrowBackIcon from "assets/images/backArrowIcon.svg"
import ArrowForwardIcon from "assets/images/nextArrowIcon.svg"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { showSubmitAllDraftResponcesDialog, hideSubmitAllDraftResponcesDialog, setSelectedRequirement } from "redux/seller/action";
import SubmitAllResponsesDialog from "../Common/SubmitAllResponsesDialog";

const connectedProps = (state) => ({
  evaluation: state.seller.evaluation,
  isOpenSubmitAllDialog: state.seller.submitAllSellerResponseDialogOpen,
  requirementTree: state.seller.requirementTree,
  selectedRequirementId: state.seller.selectedRequirementId,
});

const connectionActions = {
  setSelectedRequirement: setSelectedRequirement,
  showSubmitAllDraftResponcesDialog: showSubmitAllDraftResponcesDialog,
  hideSubmitAllDraftResponcesDialog: hideSubmitAllDraftResponcesDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  draftBtn: {
    marginRight: 10,
    color: '#707070',
    borderColor: '#707070',
  },
  bottomLinks: {
    position: "fixed",
    bottom: "0",
    padding: theme.spacing(1),
    left: 0,
    right: 0,
    paddingLeft: theme.spacing(24),
    paddingRight: theme.spacing(4),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px -1px 6px #00000010",
    zIndex: 1000
  }
});

class BottomActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.saveAsDraft = this.saveAsDraft.bind(this);
    this.submit = this.submit.bind(this);
    this.submitAll = this.submitAll.bind(this);
  }

  submit() {
    this.props.submit();
  }

  submitAll() {
    // this.props.showSubmitAllDraftResponcesDialog();
    this.props.submitQuesnnaire()
  }

  saveAsDraft() {
    this.props.saveAsDraft();
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  render() {
    const classes = this.props.classes;

    var nextReqId = null;
    var previousReqId = null;

    var allRequirements = (this.props.requirementTree !== undefined && this.props.requirementTree !== null) ? [...this.props.requirementTree] : [];

    var requirementIds = [];
    var allItems = [];
    if (allRequirements !== null && allRequirements.length > 0) {
      requirementIds = getAllItems(this.props.requirementTree, allItems);
    }

    function getAllItems(datas, allItems) {
      datas.forEach(function (item) {
        if (item.Type !== 1) {
          allItems.push(item.ID);
        }

        if (item.Childrens != null) {
          getAllItems(item.Childrens, allItems);
        }
      });
      return allItems;
    }

    var currentReq = this.props.selectedRequirementId;

    var indexOfCurrent = requirementIds.indexOf(currentReq);
    var indexOfNext = null;
    var indexOfPrevious = null;

    if (indexOfCurrent !== (requirementIds.length - 1)) {
      indexOfNext = indexOfCurrent + 1;
      nextReqId = requirementIds[indexOfNext];
    }

    if (indexOfCurrent !== 0) {
      indexOfPrevious = indexOfCurrent - 1;
      previousReqId = requirementIds[indexOfPrevious];
    }

    return (
      <div>
        <Grid container spacing={2} className={classes.bottomLinks}>
          <Grid item xs={8}>

          </Grid>
          {false && <Grid item xs={2} style={{ "textAlign": "left" }}>
            <SqureButton
              variant={"outlined"}
              disabled={previousReqId === null ? true : false}
              startIcon={<Image src={ArrowBackIcon} alt="arrow backword" />}
              onClick={() => {
                this.props.setSelectedRequirement(previousReqId);
                // this.props.history.push(`/seller/evaluate/${this.props.match.params.uuid}/${this.props.match.params.org_id}/${previousReqId}`)
              }}>
              Previous
            </SqureButton>
          </Grid>}
          {false && <Grid item xs={2} style={{ "textAlign": "right" }}>
            <SqureButton
              variant={"contained"}
              disabled={nextReqId === null ? true : false}
              endIcon={<Image src={ArrowForwardIcon} alt="arrow forward" />}
              onClick={(e) => {
                this.props.setSelectedRequirement(nextReqId);
              }}>
              Next
            </SqureButton>

          </Grid>}
          <Grid item xs={4} lg={4} md={4} sm={4} style={{ textAlign: 'right' }}>
            {this.props.isBackBtnRequired && <SqureButton variant={"outlined"} startIcon={<ArrowBackIosIcon />} onClick={this.props.goToInstruction}>Go to Instructions</SqureButton>}
            <SqureButton
              style={{ "marginLeft": "10px" }}
              disabled={this.props.isDisable}
              variant={"contained"}
              endIcon={<SendIcon />}
              onClick={this.submitAll}>Submit Questionnaire</SqureButton>
          </Grid>
        </Grid>

        {this.props.isOpenSubmitAllDialog && <SubmitAllResponsesDialog />}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(BottomActions));
