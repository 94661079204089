import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import * as Validator from "util/Validation";
import { Grid, InputAdornment, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import SqureButton from 'Components/Common/SqureButton';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "assets/images/calendar.svg"
import Image from 'Components/Common/image.jsx';

import { updateProductFromCart } from "redux/evaluation/cart/action";
import { fetchEvaluationMetaDataForSummary } from "redux/workflow/summary/action";
import { showSnackBar } from "redux/snackbar/action";
import ChooseAsksellerCriterias from "./ChooseAsksellerCriterias";
import { fetchEvaluationMeta } from "redux/seller/action";
import { fetchVendorCatalogTabData } from "redux/vendor/vendorCatalog/action";
import moment from 'moment'

const connectedProps = (state) => ({
  evaluationData: state.workflowSummary.evaluation,
  fetchProgress: state.workflowSummary.fetchProgress,
  saveProgress: state.evaluationCart.updateProductInProgress,
  user: state.authUser.user,
  updateSellerStateProgress: state.seller.updateSellerStateProgress,

  fetchVendorCatalogTabDataProgress: state.vendorCatalog.fetchVendorCatalogTabDataProgress,
  fetchVendorCatalogTabDataError: state.vendorCatalog.fetchVendorCatalogTabDataError,
  vendorCatalogTabData: state.vendorCatalog.vendorCatalogTabData,
});

const connectionActions = {
  updateProductFromCart: updateProductFromCart,
  fetchEvaluationMetaDataForSummary: fetchEvaluationMetaDataForSummary,
  showSnackBar: showSnackBar,
  fetchEvaluationMeta: fetchEvaluationMeta,
  fetchVendorCatalogTabData: fetchVendorCatalogTabData
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  sellerUpdateRoot: {
    maxHeight: '80vh',
    overflow: 'auto',
    padding: 8,
    marginBottom: 20,
    '& .react-datepicker-wrapper': {
      width: '100%'
    }
  },
  actions: {
    textAlign: "right",
  },
  updateDataSell: {
    border: '1px solid #87929b8a',
    borderRadius: 4,
    padding: 20
  },
  tickMark: {
    color: "#4b86f8",
    marginLeft: 10,
    border: "1px solid #4b86f8",
    // marginRight: 10,
    padding: 6,
    minWidth: "50px !important",
    "&:hover": {
      color: "#4b86f8",
    },
  },
  bottomLinks: {
    position: "fixed",
    bottom: "0",
    padding: theme.spacing(1),
    left: 0,
    right: 0,
    paddingLeft: theme.spacing(24),
    paddingRight: theme.spacing(8),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px -1px 6px #00000010",
    zIndex: 1001
  }
});

class SellerDetailsUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.product.VendorName || '',
      name_error: '',
      email: this.props.product.VendorEmail || '',
      email_error: '',
      deadline: this.props.product !== undefined && this.props.product !== null && this.props.product !== '' && this.props.product.Deadline !== null && this.props.product.Deadline !== '' ? new Date(this.props.product.Deadline) : '',
      deadline_error: '',
      error: "",
      success: "",
      product: null,
      isShowChooseAskSeller: false,
      showSellerEdit: true
    }
    this.submitResponse = this.submitResponse.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.product, 'SelectedProductttttttttttt')
    if (this.props.product) {
      this.checkProductInfo()

      // this.props.fetchEvaluationMetaDataForSummary(this.props.evaluation?.ID)
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedProductIndex !== prevProps.selectedProductIndex) {
      this.checkProductInfo()
    }

    if (this.props.fetchProgress === false && prevProps.fetchProgress === true) {
      this.initDatas()
    }

    if (this.props.saveProgress === false && prevProps.saveProgress === true) {
      this.props.showSnackBar("Seller detail updated", "success", 3000);
      setTimeout(() => {
        this.checkNext()
      }, 100);
    }

    if (!this.props.fetchVendorCatalogTabDataProgress && prevProps.fetchVendorCatalogTabDataProgress) {
      this.initializeVendorDetails()
    }
  }

  checkProductInfo() {
    const { product, vendorNameField, vendorEmailField } = this.props;


    if (product && product.Type === "CUSTOM_VENDOR" && (vendorNameField !== '' || vendorEmailField !== '')) {
      this.props.fetchVendorCatalogTabData(product?.EntityID)
      
    }

   

    this.setState({
      isShowChooseAskSeller: false,
      showSellerEdit: true
    }, () => {
      this.initDatas();
    })
  }

  checkNext() {
    let me = this;
    let product = this.props.evaluation.Products.filter(o => o.ID === this.props.product.ID)[0]
    if (product.VendorEmail === null || product.VendorEmail === '') {
      product.VendorEmail = me.state.email;
    }
    if (product.VendorName === null || product.VendorName === '') {
      product.VendorName = me.state.name;
    }
    this.setState({
      isShowChooseAskSeller: true,
      showSellerEdit: false,
      product: product
    });
  }

  initDatas() {
    // console.log(this.props.evaluation, 'evaluation')
    let product = this.props.evaluation.Products.filter(o => o.ID === this.props.product?.ID)[0]
    // let isShowChooseAskSeller = false;
    // let showSellerEdit = true;
    // if (product?.VendorEmail && product?.VendorEmail !== '') {
    //   isShowChooseAskSeller = true;
    //   showSellerEdit = false;
    // }

    let deadlineVal = product !== undefined && product !== null && product !== '' && product.Deadline !== null && product.Deadline !== '' ? new Date(product.Deadline) : '';

    const { vendorDeadline } = this.props;
    if (product && vendorDeadline && vendorDeadline !== '' && deadlineVal === '') {
      console.log(product,'product55555555555555555555555555')
      deadlineVal = new Date(moment().add(vendorDeadline, "days").toISOString());
      console.log(deadlineVal,'deadline_val')
    }

    this.setState({
      name: product?.VendorName || '',
      name_error: '',
      email: product?.VendorEmail || '',
      email_error: '',
      deadline: deadlineVal,
      deadline_error: '',
      error: "",
      success: "",
      product: product,
      // isShowChooseAskSeller: isShowChooseAskSeller,
      // showSellerEdit: showSellerEdit
    });
  }

  initializeVendorDetails() {
    let product = this.props.evaluation.Products.filter(o => o.ID === this.props.product?.ID)[0]
    const { vendorCatalogTabData, vendorNameField, vendorEmailField } = this.props;
    if (product && (product.VendorName === null || product?.VendorName === '')) {
      if (vendorNameField && vendorNameField.value && vendorCatalogTabData[vendorNameField.value]) {
        let vendorName = vendorCatalogTabData[vendorNameField.value]
        if (vendorName) {
          this.setState({
            name: vendorName,
            name_error: ''
          })
        }
      }
    }

    if (product && (product.VendorEmail === null || product?.VendorEmail === '')) {
      if (vendorEmailField && vendorEmailField.value && vendorCatalogTabData[vendorEmailField.value]) {
        let vendorEmail = vendorCatalogTabData[vendorEmailField.value]
        if (vendorEmail) {
          this.setState({
            email: vendorEmail,
            email_error: ''
          })
        }
      }
    }

  }

  clearError() {
    this.setState({
      'error': "",
      "success": "",
      "email_error": "",
      "name_error": "",
      "deadline_error": "",
    });
  }

  submitResponse() {
    this.clearError()
    var me = this;
    var name = me.state.name;
    var email = me.state.email;
    var deadline = me.state.deadline;

    //validation
    if (name === '' && String(name).trim().length === 0) {
      me.setState({ name_error: "Please provide your name." });
      return;
    }

    if (email === '' && String(email).trim().length === 0) {
      me.setState({ email_error: "Please provide your email." });
      return;
    }

    if (Validator.validateEmail(email) === false) {
      me.setState({ email_error: "Please provide valid email." });
      return;
    }

    if (deadline === null || deadline === '' || String(deadline).trim().length === 0) {
      me.setState({ deadline_error: "Please provide deadline date." });
      return;
    }

    this.props.updateProductFromCart(this.props.match.params.evaluation_id, this.props.product.EntityObjectID, {
      contact_name: name || '',
      contact_email: email,
      deadline: deadline.toISOString(),
    })
  }

  setInputValue = (field, value) => {
    this.setState({
      [field]: value
    }, () => {
      this.clearError()
    })
  }

  render() {
    const { classes } = this.props;
    const { product, name, name_error, email, email_error, error, success, isShowChooseAskSeller, showSellerEdit, deadline, deadline_error } = this.state;
    if (product === undefined || product === null) {
      return null
    }
console.log(deadline,'deadline1111111111111111')

    return (
      <div className={classes.sellerUpdateRoot}>
        {showSellerEdit && <div className={classes.updateDataSell}>
          <div style={{ display: 'flex' }}>
            {/* <Image className={classes.productLogo} alt="product" src={product?.ProductLogo} />
            {product !== null && <Typography className={classes.productName}>{product.Name}</Typography>} */}
          </div>
          <div className={classes.alerts}>
            {error.length > 0 && <Alert variant="filled" severity="error">{error}</Alert>}
            {success.length > 0 && <Alert variant="filled" severity="success">{success}</Alert>}
          </div>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div>
                <OutlinedInput
                  label="Contact Name"
                  error={name_error.length > 0}
                  helperText={name_error}
                  value={name}
                  onChange={(event) => {
                    this.setInputValue('name', event.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div>
                <OutlinedInput
                  label="Contact Email"
                  error={email_error.length > 0}
                  helperText={email_error}
                  value={email}
                  onChange={(event) => {
                    this.setInputValue('email', event.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div>
                <DatePicker
                  className={classes.datePicker}
                  selected={deadline}
                  onChange={date => {
                    this.setInputValue('deadline', date);
                  }}
                  portalId="evMetaDialog"
                  dateFormat="MMM do yyyy"
                  minDate={new Date()}
                  popperClassName={classes.date_popper}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={
                    <OutlinedInput
                      label="Deadline"
                      readOnlyInput={true}
                      placeholder="Deadline"
                      error={deadline_error.length > 0}
                      helperText={deadline_error}
                      startAdornment={
                        <InputAdornment>
                          <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                        </InputAdornment>
                      }
                    />
                  }
                />
              </div>
            </Grid>
          </Grid>
        </div>}

        {isShowChooseAskSeller && <ChooseAsksellerCriterias
          isOpen={isShowChooseAskSeller}
          product={product}
          editSellerDetails={() => {
            this.setState({
              showSellerEdit: this.state.showSellerEdit ? false : true
            })
          }}
          showSellerEdit={this.state.showSellerEdit}
          updateSellerDetails={() => {
            this.submitResponse()
          }}
          sellerDetailsUpdateProgress={this.props.saveProgress}
          onClose={() => {
            this.props.onClose()
          }}
        />}

        {!isShowChooseAskSeller && <div className={classes.bottomLinks} style={{ "textAlign": "right" }}>
          {<SqureButton
            style={{ "marginLeft": "10px" }}
            variant={"outlined"}
            disabled={false}
            onClick={() => { this.props.onClose() }}>
            {this.props.updateSellerStateProgress ? <CircularProgress style={{ width: 23, height: 23 }} /> : 'Skip'}

          </SqureButton>}


          <SqureButton
            style={{ "marginLeft": "10px" }}
            disabled={this.state.isSendDisable}
            variant={"contained"}
            onClick={() => {
              this.submitResponse()
            }}>
            {this.props.saveProgress ? <CircularProgress style={{ width: 23, height: 23 }} /> : 'Next'}
          </SqureButton>
        </div>}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(SellerDetailsUpdate));
