import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { generate } from 'shortid';
import { withRouter } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Paper, Tab, Tabs, Grid, CircularProgress } from '@material-ui/core';
import AppBar from "Components/Application/AppBar";
import SqureButton from 'Components/Common/SqureButton';
import {
    hideEvaluationSelectDialog, 
    selectEvaluationTemplateType, 
    selectEvaluationTemplateID, 
} from "redux/templates/evaluation/action";
import { fetchMasterTemplates, fetchMasterTemplate }
    from "redux/templates/evaluation/master/action";
import { fetchOrgTemplate, fetchOrgTemplates }
    from "redux/templates/evaluation/org/action";

import CriteriaRequirement from "./index"

import TemplateCard from './Card'


const CustomTabs = withStyles({
    root: {
        backgroundColor: 'tarsparent !imporant',
    },
    indicator: {
        backgroundColor: '#4A87F8',
    },
})(Tabs);

const CustomTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        '&:hover': {
            color: '#4A87F8',
            opacity: 1,
        },
        '&:focus': {
            color: '#4A87F8',
        },
    },
    selected: {},
}))((props) => <Tab {...props} />);

const connectedProps = (state) => ({
    isOpen: state.templateEvaluation.show,
    evaluationId: state.templateEvaluation.evaluationId,
    templateType: state.templateEvaluation.templateType,
    templateId: state.templateEvaluation.templateId,
    templates: state.masterTemplateEvaluation.templates,
    template: state.masterTemplateEvaluation.template,
    fetchTemplateProg: state.masterTemplateEvaluation.fetchTemplateProg,
    orgTemplates: state.orgTemplateEvaluation.templates,
    orgTemplate: state.orgTemplateEvaluation.template,
    fetchOrgTemplateProg: state.orgTemplateEvaluation.fetchTemplateProg,
    configurations: state.orgUser.fetchAllOrgSettingsSuccess,
    importCriteriaIds: state.templateEvaluation.criteriaIds,
});


const connectionActions = {
    hideDialog: hideEvaluationSelectDialog,
    selectEvaluationTemplateType: selectEvaluationTemplateType,
    selectEvaluationTemplateID: selectEvaluationTemplateID,
    fetchMasterTemplates: fetchMasterTemplates,
    fetchMasterTemplate: fetchMasterTemplate,
    fetchOrgTemplate: fetchOrgTemplate,
    fetchOrgTemplates: fetchOrgTemplates
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    headSection: {
        marginTop: theme.spacing(2),
        '& > div' : {
            boxShadow: 'none',
            background: 'none',
        }
    },
    dialogContent: {
        // padding: theme.spacing(6, 12) + " !important",
        backgroundColor: "#F7F7F7",
        maxHeight: "100%",
        height: "100%",
        position: "relative",
        overflow: "auto"
    },
    title: {
        color: "#282D30",
        fontSize: theme.spacing(2.5),
        marginBottom: 0
    },
    actions: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(2),
        background: "#fff",
        textAlign: "right"
    },
    templates: {
        padding: theme.spacing(2, 0),
        marginBottom: 35
    },
    iconContainer: {
        marginRight: theme.spacing(3),
        cursor: "pointer",
        display: "flex",
        marginBottom: theme.spacing(2)
    },
    image: {
        width: theme.spacing(3.5),
    },
    modalappBar: {
        display: "flex"
    },
    criteriaRequirement: {
        width: "90%",
        margin: '10px auto',
        paddingBottom: 50
    },
});


const ChooseTemplateModal = ({
    match,
    classes,
    importCriteriaIds,
    fetchTemplateProg,
    templates,
    fetchOrgTemplateProg,
    fetchMasterTemplates,
    fetchOrgTemplates,
    orgTemplates,
    hideDialog,
    isOpen,
    configurations,
    selectEvaluationTemplateType, 
    selectEvaluationTemplateID, 
    templateId,
}) => {
    const [state, setState] = useState({
        page: 0,
        pageSize: 1000,
        tab: 0,
        type: 'MASTER',
        templateID: null,
        openCriteria: false,
    })

    useEffect(() => {
        fetchMasterTemplates(state.page, state.pageSize);
        fetchOrgTemplates(state.page, state.pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleChange = (event, value) => {
        event.preventDefault()
        setState(prev => ({
            ...prev,
            tab: value,
            type: value === 0 ? 'MASTER': 'ORG'
        }))
    }

    const handleSelectTemplate = (type, templateID) => {
        selectEvaluationTemplateID(templateID)
        selectEvaluationTemplateType(type)
        console.log(type, templateID)
        setState(prev => ({
            ...prev,
            type,
            templateID,
            openCriteria: true,
        }))
    }

    const handleBack = (event, value) => {
        setState(prev => ({
            ...prev, 
            templateID: null, 
            openCriteria: false,
        }))
    }

    const clear = () => {
        setState({
            page: 0,
            pageSize: 1000,
            tab: 0,
            type: 'MASTER',
            templateID: null,
            openCriteria: false,
        })
    }

    const defaultFlags = (templateId, templateType) => {
        var isReviewDefaultChecked = false;
        var isEvaluationDefaultChecked = false;
        if (configurations !== null) {
            var default_evaluation_setting = configurations.filter((i) => {
                return i.SettingName === "ORG_DEFAULT_EVALUATION_TEMPLATE";
            })
            var default_review_setting = configurations.filter((i) => {
                return i.SettingName === "ORG_DEFAULT_REVIEW_TEMPLATE";
            })
            if (default_review_setting.length > 0) {
                const settingValue = default_review_setting[0].Value
                if (settingValue !== "NULL") {
                    try {
                        const parsed = JSON.parse(settingValue)
                        if (parsed.type === "ORG" && templateType === "ORG" && parseInt(parsed.tid) === templateId) {
                            isReviewDefaultChecked = true;
                        } else if (parsed.type === "MASTER" && templateType === "MASTER" && parseInt(parsed.tid) === templateId) {
                            isReviewDefaultChecked = true;
                        }
                    } catch (e) { }
                }
            }
            if (default_evaluation_setting.length > 0) {
                const settingValue = default_evaluation_setting[0].Value
                if (settingValue !== "NULL") {
                    try {
                        const parsed = JSON.parse(settingValue)
                        if (parsed.type === "ORG" && templateType === "ORG" && parseInt(parsed.tid) === templateId) {
                            isEvaluationDefaultChecked = true;
                        } else if (parsed.type === "MASTER" && templateType === "MASTER" && parseInt(parsed.tid) === templateId) {
                            isEvaluationDefaultChecked = true;
                        }
                    } catch (e) { }
                }
            }
        }
        return {
            evaluation: isEvaluationDefaultChecked,
            review: isReviewDefaultChecked
        }
    }

    return (
        <Dialog
            onClose={hideDialog}
            aria-labelledby="app-integrationDialog"
            open={isOpen}
            disableBackdropClick={true}
            fullWidth={true}
            fullScreen
            maxWidth={"md"}
            scroll="body"
            className={classes.dialog}
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                {!state.openCriteria && state.templateId == null && (
                    <>
                        <AppBar
                            title="Templates"
                            withBack={true}
                            dispatchEvent={true}
                            targetEvent={() => {
                                selectEvaluationTemplateID(null)
                                hideDialog()
                            }}
                        />
                        <div className={classes.headSection}>
                            <Paper className={classes.rootPaper}>
                                <CustomTabs
                                    value={state.tab}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <CustomTab label="Expent's Template Library" />
                                    <CustomTab label="Custom" />
                                </CustomTabs>
                            </Paper>
                        </div>
                        {state.tab === 0 && (
                            <div className={classes.templates}>
                                {fetchTemplateProg === true && <div className={classes.loader}>
                                    <CircularProgress />
                                </div>}
                                {templates !== null && (
                                    <Grid container spacing={3}>
                                        {templates.map(template => {
                                            var dflags = defaultFlags(template.ID)
                                            return (
                                                <Grid item xs={12} sm={6} md={4} lg={3} key={generate()}>
                                                    <TemplateCard
                                                        isChooseTemplate={true}
                                                        template={template}
                                                        dflags={dflags}
                                                        type="MASTER"
                                                        onClick={(type, templateID) => handleSelectTemplate(type, templateID)}
                                                    />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )}
                            </div>
                        )}
                        {state.tab === 1 && (
                            <div className={classes.templates}>
                                {fetchOrgTemplateProg === true && <div className={classes.loader}>
                                    <CircularProgress />
                                </div>}
                                {orgTemplates !== null && (
                                    <Grid container spacing={3}>
                                        {orgTemplates.map((template) => {
                                            var dflags = defaultFlags(template.ID)
                                            return (
                                                <Grid item xs={12} sm={6} md={4} lg={3} key={generate()}>
                                                    <TemplateCard
                                                        isChooseTemplate={true}
                                                        template={template}
                                                        dflags={dflags}
                                                        type="ORG"
                                                        onClick={(type, templateID) => handleSelectTemplate(type, templateID)}
                                                    />
                                                </Grid>
                                            )
                                        })
                                        }
                                    </Grid>
                                )}
                            </div>
                        )}
                    </>
                )}
                {state.openCriteria && state.templateID !== null && (
                    <CriteriaRequirement 
                        isChooseTemplate={true}
                        chooseTemplateId={state.templateID}
                        chooseTemplateType={state.type}
                        handleBack={handleBack}
                    />
                )}
                <div className={classes.actions}>
                    <SqureButton variant={"outlined"} onClick={() => {
                        selectEvaluationTemplateID(null)
                        hideDialog()
                        clear()
                    }}>Cancel</SqureButton>&nbsp;&nbsp;
                    <SqureButton variant={"contained"}
                        disabled={(templateId === null) || importCriteriaIds.length === 0}
                        onClick={() => {
                            if (templateId !== null) {
                                hideDialog()
                                clear()
                            }
                        }}>Use This Template</SqureButton>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ChooseTemplateModal));