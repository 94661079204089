import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import { createStyles, withStyles } from "@material-ui/styles";
import CalendarImage from "assets/images/calendar.svg";
import deleteIcon from "assets/images/delete.svg";
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";
import axios from "axios";
import classnames from "classnames";
import CommonCss from "commonCss";
import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { compose } from "recompose";
// import { sendAddMentionNotification } from "services/addMentionNotification";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { currencyOptions, currencyCodeMap } from "util/Currency";
import PublishIcon from '@material-ui/icons/Publish';
// import addUsers from "assets/images/add_profile.svg";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import Image from "Components/Common/image.jsx";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import SqureButton from "Components/Common/SqureButton";
import AddUserDialog from "../../../Dialog/AddUserDialog";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
import { fetchOrgGroup } from "redux/org/group/action";
import { fetchRecommendedProducts } from "redux/product/action";
import { showSnackBar } from "redux/snackbar/action";

import { withAsyncPaginate } from "react-select-async-paginate";
import FileAncher from "Components/Common/FileAncher";

const SelectAsyncPaginate = withAsyncPaginate(Select);

var currencyStyles = {
  control: (styles) => ({ ...styles, height: 46 }),
  menuPortal: (base) => ({ ...base, zIndex: 9999, maxHeight: 200 }),
  option: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  }),
};

const typeMap = {
  "license": 1,
  "start_date": 2,
  "end_date": 2,
  "quantity": 19,
  "unit_price": 20,
  "price": 20,
}

const connectedProps = (state) => ({
  products: state.products.recommendedProducts,
  groups: state.orgGroup.groups,

});

const connectionActions = {
  fetchRecommendedProducts: fetchRecommendedProducts,
  fetchOrgGroup: fetchOrgGroup,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      marginBottom: 15,
      "& [class*=product_container]": {
        marginTop: 0,
      },
      "& [class*=edit-btn]": {
        visibility: "hidden",
      },
      "&:hover [class*=edit-btn]": {
        visibility: "visible",
      },
    },
    ...CommonCss.ProductDetails,
    formControlDialog: {
      ...CommonCss.formControl,
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
        width: "100%",
      },
      padding: ".375rem",
    },
    formControl2: {
      ...CommonCss.formControl,
    },
    formGroup: {
      marginTop: 5,
      marginBottom: 80,
    },
    formGroupDialog: {
      "& input": {
        color: "#6C6C6C !important",
      },
    },
    formControl: {
      ...CommonCss.formControl,
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
      },
      padding: ".375rem",
    },
    mb16: {
      marginBottom: 16,
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      cursor: "pointer",
    },
    subSection: {},
    productList: {
      position: "relative",
      "& [class*=LayeredCard-root]": {
        marginTop: 0,
      },
    },
    deleteImage: {
      position: "absolute",
      top: 10,
      zIndex: 20,
      right: 10,
      padding: 4,
      cursor: "pointer",
    },
    saveBtn: {
      color: "#fff",
      padding: "6px 26px",
      backgroundColor: "#4b86f8",
      "&:hover": {
        backgroundColor: "#4b86f8",
      },
    },
    datePicker: {
      "& .react-datepicker-wrapper": {
        width: "100%",
      },
      "& .react-datepicker__input-container > div": {
        display: "flex",
        marginBottom: 0,
        alignItems: "center",
        paddingLeft: "8px !important",
        "& [class*=MuiInputBase-root]": {
          maxHeight: 33,
          minHeight: 33,
          width: "inherit",
        },
        "& input": {
          fontSize: theme.spacing(1.8),
          padding: "14px 5px 14px 2px !important",
          textAlign: "center",
        },
        "& [class*=MuiFormLabel-root]": {
          marginBottom: 0,
          marginRight: 29,
        },
      },
      "[class*=MuiMenuItem-root]": {
        color: "#707070",
        fontSize: theme.spacing(1.8),
      },
    },
    tickMark: {
      color: "#4b86f8",
      marginLeft: 10,
      border: "1px solid #4b86f8",
      // marginRight: 10,
      padding: 9,
      minWidth: "50px !important",
      "&:hover": {
        color: "#4b86f8",
      },
    },
    tableColumnLinkText: {
      position: "relative",
      cursor: "pointer",
      width: "100%",
      height: "100%",
      display: 'flex',
      alignItems: 'center',
      gap: 5
    },
    title: {
      marginBottom: "8px",
    },
    flexWrap: {
      display: "flex",
      alignItem: "center",
      justifyContent: "space-between",
    },
    chipContainer: {
      padding: theme.spacing(1, 0),
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        marginRight: theme.spacing(0.5),
      },
    },
    contactCardHeader: {
      flexDirection: "column",
      padding: "16px 4px !important",
      "& [class*='MuiCardHeader-avatar-']": {
        margin: "0px",
        marginBottom: 12,
      },
      "& [class*='MuiCardHeader-content']": {
        width: "100%",
      },
      "& [class*=MuiTypography-displayBlock]": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        margin: "0px -19px 0px 0px",
      },
    },
    contactCardRoot: {
      cursor: "pointer",
    },
    addNewChamp: {
      display: "block",
      textAlign: "center",
      color: "#bdbdbd",
      "& span": {
        display: "block",
        cursor: "pointer",
        color: "#000000",
      },
    },
    deleteIcon: {
      position: "absolute",
      right: 16,
      top: 13,
      cursor: "pointer",
    },
    cardSection: {
      position: "relative",
      // flex: 1,
    },
    contractHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      marginBottom: 10,
    },
    layeredCardHead: {
      marginBottom: 0,
    },
    linkText: {
      position: "relative",
      cursor: "pointer",
      width: "100%",
      height: "100%",
    },
    resourceLink: {
      display: "flex",
      alignItems: "center",
    },
    resourceLinkText: {
      color: "#4b86f8 !important",
      cursor: "pointer",
      padding: 10,
    },
    tableCell: {
      width: "60%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "calc(100vw - 99vw)",
    },
    tabledeleteCell: {
      width: "10%",
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 35,
      padding: 0,
      marginLeft: 15,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    paper: {
      boxShadow: "none",
      border: "1px solid #ddd",
    },
    resourceHead: {
      padding: 10,
    },
    uploadImage: {
      overflow: "hidden",
      width: 0,
      position: "absolute",
      top: 10,
      left: 0,
      opacity: 0,
    },
    editIcon1: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 6,
      marginBottom: 5,
      cursor: "pointer",
    },
    itemHead: {
      color: "#6C6C6C",
      fontSize: 14,
      opacity: 0.9,
    },
    data: {
      fontSize: 16,
      fontWeight: 400,
      marginTop: 8,
    },
    tableColumnData: {
      fontSize: 14,
      fontWeight: 400,
      margin: 0
    },
    productLink: {
      ...CommonCss.ProductDetails.productLink,
      fontSize: 14,
      marginBottom: 0,
    },
    error: {
      textAlign: "center",
      lineHeight: 0,
      color: "red",
    },
    strikeThrough: {
      textDecoration: "line-through",
    },
    checkListroot: {
      color: "red",
      '& [class*="Mui-checked"]': {
        color: "green",
      },
    },
    slateWrap: {
      "& [class*='editableField']": {
        minHeight: "100px !important",
      },
    },
    slateWrap2: {
      "& [class*='editableField']": {
        minHeight: "50px !important",
        margin: "0px !important",
      },
      "& [data-slate-node='element']": {
        margin: 0,
        marginLeft: 5,
        marginTop: 5,
      }
    },
    formSelect: {
      width: 200,
    },
  });

class DocumentFileFields extends Component {
  constructor(props) {
    super();
    this.state = {
      data: null,
      openAddContactDialog: false,
      uploadingFile: false,
      edit: false,
      selectedOption: null,
      error: "",
      showEdit: false,
      itemId: "",
      selectedFiles: [],
      fileNames: [],
      uploadingTableColumnFileLoading: null,
    };
  }

  componentDidMount() {
    if (this.props.type === 10) this.fetchProduct();
    this.initializeState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.initializeState();
    }
  }

  initializeState = () => {
    const { value, slug } = this.props;
    let data = "";
    if (value) {
      data = value;
      if ([4, 5, 6].includes(this.props.type) && ![null, NaN, undefined].includes(data) && data.length > 0 && this.props.additionalData.length > 0) {
        
        if ([4, 5].includes(this.props.type)) {
          const ids = data?.map((o) => Number(o.id));
          data = this.props.additionalData.filter((o) => ids.includes(o.id));
        } else {
          const ids = this.props.additionalData.map((o) => o.value);
          data = data.map((row) => {
            const newRow = row.filter((o) => ids.includes(o.value));
            return newRow;
          });
        }
      }
      if(this.props.type === 9){
        this.setState({
          selectedFiles: value,
          fileNames: value?.map(file=> file.name)
        });
      }

      if (slug === "total_cost") {
        data = {
          type: {
            label: value.currency,
            value: value.currency,
          },
          value: value.value,
        };
      }

      this.setState({
        data,
      });
    } else {
      let data = null;
      if (this.props.type === 20) {
        data = {
          type: {
            label: "USD",
            value: "USD",
          },
          value: "",
        };
      }
      if (this.props.type === 18) {
        data = [];
      }
      this.setState({
        data: data,
        openAddContactDialog: false,
        uploadingFile: false,
        edit: false,
        selectedOption: null,
        error: "",
        showEdit: false,
      });
    }
  };

  fetchProduct = (value = "") => {
    this.props.fetchRecommendedProducts(0, {
      feature_ids: [],
      num_users: "",
      page: 0,
      pageSize: 10,
      query: value,
      type: 2,
    });
  };

  addRow = () => {
    const newRow = new Array(this.props.additionalData.length).fill().map((o, index) => {
      const d = this.props.additionalData[index];
      return {
        label: d.type === 20 ? d.value : "",
        value: d.value,
        id: d.value,
        type: d.type
      };
    });
    let data = this.state.data;
    if (data === null) {
      data = [newRow];
    } else {
      data.push(newRow);
    }
    this.setState({ data }, () => {
      this.props.onUpdateValue(data);
    });
  };

  handleRemoveOption = (index) => {
    let data = [...this.state.data];
    data.splice(index, 1);
    this.setState({ data }, () => {
      this.props.onUpdateValue(data);
    });
  };

  handleOnOptionSelect = (value) => {
    let data = this.state.data;
    if (data === null || data.length === 0) {
      data = [value];
    } else if (this.props.type === 5 || this.props.type === 8 || this.props.type === 10 || this.props.type === 9) {
      data.push(value);
    }
    this.setState({ data, selectedOption: null }, () => {
      this.props.onUpdateValue(data);
    });
  };

  handleOnChangeValue = (value, row, column) => {
    const data = this.state.data;
    const erow = data[row];
    erow[column].label = value;
    data[row] = erow;
    this.setState({ data }, () => {
      this.props.onUpdateValue(data);
    });
  };

  handleRemoveRow = (index) => {
    let data = this.state.data;
    data.splice(index, 1);
    this.setState({ data }, () => {
      this.props.onUpdateValue(data);
    });
  };

  handleAddUser = (user) => {
    if (user !== null) {
      let data = this.state.data;
      if (data === null || data.length === 0) {
        data = [user];
      } else {
        data.push(user);
      }
      this.setState({ data }, () => {
        this.props.onUpdateValue(data);
      });
    }
  };

  handleDeleteUser = (userIndex) => {
    let data = this.state.data;
    data.splice(userIndex, 1);
    this.setState({ data }, () => {
      this.props.onUpdateValue(data);
    });
  };

  handleOnChangeTextField = (value, type) => {
    let error = "";
    // if(value.length > 0){
    //   if (type === 12) {
    //     const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     if (value && value.length && !re.test(String(value).toLowerCase())) {
    //       error = "Please enter valid email address.";
    //     }
    //   }
    //   if (type === 13) {
    //     var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    //     if (phoneno && phoneno.length && !value.match(phoneno)) {
    //       error = "Please enter valid phone number.";
    //     }
    //   }

    //   if (type === 14) {
    //     var url =
    //       /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    //     if (value && value.length && !url.test(value)) {
    //       error = "Please enter valid url.";
    //     }
    //   }
    // }

    this.setState({ data: value, error }, () => {
      this.props.onUpdateValue(value);
    });
  };

  getPlaceHolder(type, title) {
    let placeholder = "Enter Description";
    if (type === 1) placeholder = title;
    else if (type === 19) placeholder = title;
    else if (type === 12) placeholder = "Enter Email";
    else if (type === 13) placeholder = "Enter Phone";
    else if (type === 14) placeholder = "Enter Url";
    return placeholder;
  }

  printTableColumnData = (data, type, index, i, classes) => {
    let key = this.props.additionalData[i]?.value;
    let temp_type = typeMap[key]

    if (!data) {
      return "";
    }
    else if (type === 2 || temp_type === 2) {
      return CommonFn.formateDate(data, true);
    }
    else if (type === 7 || temp_type === 7 || temp_type === 8 || type === 8) {
      return data.label
    } else if (type === 9 || temp_type === 9) {
      return <FileAncher
        fileName={data.name}
        fileURL={data.link}
      />
    } else if (type === 11 || temp_type === 11) {
      return (
        <div className={classes.slateWrap2}>
          <SlateInputFieldV2
            readOnly={true}
            isToolBar={false}
            placeholder=""
            style={{ margin: 0 }}
            as={SlateInputFieldV2}
            initValue={data}
            formControlStyle={{ border: "none", borderColor: "#fff", overflow: "hidden", margin: "0px !important" }}
            textContainerStyle={{ minHeight: "10px !important", margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }}
          />
        </div>
      );
    } else if (type === 20 || temp_type === 20) {
      return <div>
        {data && data.type && data.value && data.value !== '' && <p className={classes.data}>{currencyCodeMap[data.type.value] + '' + data?.value}</p>}
        {data && data.type && data.value === '' && <p className={classes.data}>-</p>}
      </div>
    } else {
      return data;
    }
  };

  showTableColumnFields = (column, index, i, classes) => {
    const { label, type } = column;
    const value = label;

    let key = this.props.additionalData[i]?.value;
    let temp_type = typeMap[key]

    if (type === 2 || temp_type === 2) {
      return <div className={classes.datePicker}>
        <DatePicker
          readOnly={false}
          selected={value ? new Date(value) : ''}
          onChange={(date) => this.handleOnChangeValue(date, index, i)}
          dateFormat="MMM do yyyy"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          customInput={
            <OutlinedInput
              value={value}
              readOnlyInput={true}
              placeholder="Select Date"
              labelStyle={"inline"}
              startAdornment={
                <InputAdornment>
                  <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                </InputAdornment>
              }
            />
          }
        />
      </div>
    } else if (type === 7 || temp_type === 7) {
      return <SelectAsyncPaginate
        isClearable={false}
        isSearchable={true}
        menuPosition='fixed'
        classNamePrefix="select"
        isLoading={false}
        placeholder="Search user"
        value={value}
        additional={{
          page: 0,
        }}
        loadOptions={this.getUserOptions}
        onChange={(e) => {
          this.handleOnChangeValue(e, index, i);
        }}
        styles={{ control: base => ({ ...base, height: 46 }) }}
      />
    } else if (type === 8 || temp_type === 8) {
      return <SelectAsyncPaginate
        isClearable={false}
        isSearchable={true}
        menuPosition='fixed'
        classNamePrefix="select"
        isLoading={false}
        placeholder="Search group"
        value={value}
        additional={{
          page: 0,
        }}
        loadOptions={this.getTeamOptions}
        onChange={(e) => {
          this.handleOnChangeValue(e, index, i);
        }}
        styles={{ control: base => ({ ...base, height: 46 }) }}
      />
    } else if (type === 9 || temp_type === 9) {
      return <label htmlFor={`UploadTableColumnFile_${index}_${i}`} className={classnames(classes.tableColumnLinkText)}>
        {value?.name} {(this.state.uploadingTableColumnFileLoading?.index === index) && (this.state.uploadingTableColumnFileLoading?.i === i) ? <CircularProgress style={{ width: 30, height: 30 }} /> : <PublishIcon />}
        <input id={`UploadTableColumnFile_${index}_${i}`} className={classes.uploadImage} onChange={(e) => this.onTableColumnFileChange(e, index, i)} type="file" />
      </label>
    }
    else if (type === 11 || temp_type === 11) {
      return <div className={classes.slateWrap2} id="userReplyAdd">
        <SlateInputField
          enableMention={true}
          folderPath={`/org_product/details/${this.props.match?.params?.id}`}
          placeholder={"Enter value"}
          style={{ margin: 0 }}
          as={SlateInputField}
          onChangeEvent={value => this.handleOnChangeValue(value, index, i)}
          maxLength={10}
          initValue={(value !== null && value) || ""}
          actionButtonsRequired={false}
          formControlStyle={{ overflow: 'overlay' }}
          textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', color: "#707070", fontSize: 14 }}
        />
      </div>
    } else if (type === 20 || temp_type === 20) {
      return <div className={classes.currencyTypeFields} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div style={{ width: 100, marginRight: 5, zIndex: 1 }}>
          <Select
            isMulti={false}
            labelId="singleSelect-select-label"
            id="singleSelect-select"
            value={value.type}
            defaultValue={{ label: "USD", value: "USD" }}
            onChange={(e) => {
              let newValue = {
                type: e,
                value: value?.value
              }
              this.handleOnChangeValue(newValue, index, i);
            }}
            options={currencyOptions}
            placeholder={"Select a Option"}
            styles={currencyStyles}
          >
          </Select>
        </div>
        <CurrencyTextField
          variant="outlined"
          value={value.value}
          currencySymbol={currencyCodeMap[value?.type?.value]}
          style={{ textAlign: 'left', flex: 1, height: 46 }}
          outputFormat="string"
          decimalCharacter="."
          digitGroupSeparator=","
          required={false}
          onChange={(e, v) => {
            let newValue = {
              type: value?.type || { label: "USD", value: "USD" },
              value: v
            }
            this.handleOnChangeValue(newValue, index, i);
          }}
        />
      </div>
    } else {
      return <InputField
        classes={classes}
        val={value}
        placeholder="Enter value"
        onChange={(value) => {
          this.handleOnChangeValue(value, index, i);
        }}
      />
    }
  }

  getUserOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      page: 0,
      pageSize: 20,
      query: inputValue
    };
    const url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    // console.log(response)

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  getTeamOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    const url = `${api_server}/groups?page=0&pageSize=20&query=${inputValue}&is_suppressed=no&t=${timestamp}`;
    const response = await axios.get(url, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  onTableColumnFileChange = async (event, index, i) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var formData = new FormData();
      formData.append("folder", `/orgProduct/TableTypeFiles/${this.props.match?.params?.id}`);
      const timestamp = new Date().getTime();
      formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf(".") + 1)}`);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;
      this.setState({ uploadingTableColumnFileLoading: { index: index, i: i } });

      axios.post(url, formData, {
        headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          let data = {
            name: file.name,
            link: response?.data?.filepath,
            createdAt: new Date(),
          };
          this.handleOnChangeValue(data, index, i);
          this.setState({ uploadingTableColumnFileLoading: null });
        })
    }
  };

  fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ isShowLoader: true })
      const { orgId, evaluationId, workflowId } = this.props;
      const files = event.target.files;
      var formData = new FormData();
      formData.append(
        "folder",
        `${orgId}/evaluation/${evaluationId}/workflow/${workflowId}`
      );
      formData.append("fileLength", files.length);
      const timestamp = new Date().getTime();
      let nameArr = [];
      for (const key of Object.keys(files)) {
        nameArr.push(files[key].name)
        formData.append(
          `file${key}`,
          files[key],
          `${timestamp}${files[key].name}`
        );
      }
      nameArr.push(...this.state.fileNames);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/multi-add?t=${timestamp}`;

      axios.post(url, formData, {
        headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        let resData = response.data?.filepath || {};
        let keyData = Object.keys(resData) || [];
        let data = keyData.map((key, i) => ({
          name: nameArr[i],
          url: resData[key]
        }))
        this.setState({
          selectedFiles: [...data, ...this.state.selectedFiles],
          fileNames: nameArr,
          isShowLoader: false
        }, () => {
          this.props.onUpdateValue(data);
        })
        this.props.showSnackBar("File uploaded", "success", 1500);
      })
        .catch(err => {
          if (err.response) {
            this.props.showSnackBar(err.response.data.error, "error", 3000);
          } else if (err.request) {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          } else {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          }
          this.setState({ isShowLoader: false });
        })
    }
  };

  handleDeleteFile = (index) => {
    let files = [...this.state.selectedFiles];
    files?.splice(index,1);
    this.setState({ selectedFiles: files });
  }

  render() {
    const { classes, additionalData, title, edit, type } = this.props;
    const { data, error, selectedFiles, fileNames } = this.state;
    let productList = [];
    if (type === 10) {
      productList = this.props.products.map((m) => {
        m.value = m.ID;
        m.label = m.Name;
        return m;
      });
    }
    let options = [];
    if ([4, 5].includes(type)) {
      options = additionalData.map((obj) => {
        let newData = data === null || data === "" ? [] : data.filter((d) => d.id === obj.id);
        if (newData.length) {
          return { ...obj, isDisabled: true };
        } else {
          return obj;
        }
      });
    }

    return (
      <div className={classes.root}>
        <div className={classes.itemHead}>
          <div className={classes.flexWrap}>
            <div className={classes.title}>{title}</div>
          </div>
        </div>
        {type === 11 && (
          <>
            <Grid alignItems="center">
              {edit && (
                <>
                  <Grid item xl={12} className={classes.slateWrap}>
                    <SlateInputField
                      enableMention={true}
                      folderPath={`/org_product/details/${this.props.match?.params?.id}`}
                      placeholder={`Enter ${title ? title : "note"}`}
                      style={{ margin: 0 }}
                      as={SlateInputField}
                      onChangeEvent={(value) => this.setState({ data: value }, () => {
                        this.props.onUpdateValue(value);
                      })}
                      maxLength={10}
                      initValue={(data !== null && data) || ""}
                      actionButtonsRequired={false}
                      formControlStyle={{ overflow: "overlay" }}
                      textContainerStyle={{ minHeight: "100px !important", maxHeight: "100px !important", color: "#707070", fontSize: 14 }}
                    />
                  </Grid>
                </>
              )}
              {!edit && <SlateReadonlyField initValue={(data !== null && data) || ""} />}
            </Grid>
          </>
        )}

        {[1, 14, 12, 13, 19].includes(type) && (
          <Grid container>
            {edit && (
              <>
                <Grid item xl={12} md={12} sm={12}>
                  <label className={classes.error}>{error}</label>
                </Grid>
                <Grid item xl={10} md={10} sm={10}>
                  <Grid container alignItems="center">
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      className={classes.formControlDialog}
                      placeholder={this.getPlaceHolder(type, title)}
                      onChange={(e) => this.handleOnChangeTextField(e.target.value, type)}
                      value={(data !== null && data) || ""}
                      type={type === 19 ? "number" : "text"}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {!edit && (
              <div>
                {type === 14 && (
                  <p className={classes.data}>
                    {data !== null && (
                      <a
                        href={data || ""}
                        className={classes.productLink}
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data || ""}
                      </a>
                    )}
                  </p>
                )}
                {type !== 14 && <p className={classes.data}>{(data !== null && data) || ""}</p>}
              </div>
            )}
          </Grid>
        )}
        {type === 2 && (
          <Grid container alignItems="center" className={classes.subSection}>
            {edit && (
              <div className={classes.datePicker}>
                <DatePicker
                  readOnly={false}
                  selected={data !== null ? new Date(data) : ""}
                  onChange={(date) => this.setState({ data: date }, () => {
                    this.props.onUpdateValue(date);
                  })}
                  dateFormat="MMM do yyyy"
                  customInput={
                    <OutlinedInput
                      value={data}
                      readOnlyInput={true}
                      placeholder="Select Date"
                      labelStyle={"inline"}
                      startAdornment={
                        <InputAdornment>
                          <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                        </InputAdornment>
                      }
                    />
                  }
                />
              </div>
            )}
            {!edit && (
              <div>
                <p className={classes.data}>{data ? moment(data).format("DD MMM YYYY") : ""}</p>
              </div>
            )}
          </Grid>
        )}
        {type === 3 && (
          <Grid container alignItems="center">
            {edit && additionalData && additionalData.length > 0 && (
              <RadioGroup
                aria-label="binary"
                name="binary"
                value={data}
                onChange={(event) => {
                  this.setState({ data: event.target.value },
                    () => {
                      this.props.onUpdateValue(event.target.value);
                    }
                  );
                }}
              >
                <Grid container>
                  {additionalData.map((item) => (
                    <FormControlLabel value={item.value} control={<Radio />} label={item.label} />
                  ))}
                </Grid>
              </RadioGroup>)}
            {!edit && <div>
              <p style={{ margin: 0 }} className={classes.data}>
                {data === undefined || data === null || data === "null" || data === "" ? "" : this.props.additionalData.filter((item) => item.value === data)[0].label}
              </p>
            </div>}
          </Grid>
        )}
        {[4, 5].includes(type) && (
          <>
            <div className={classes.chipContainer}>
              {data !== null &&
                data !== "" &&
                data.length > 0 &&
                data.map((obj, i) => <Chip variant="outlined" label={obj.label} onDelete={edit ? () => this.handleRemoveOption(i) : false } color="primary" />)}

            </div>
            {edit && ((type === 4 && (data === null || data.length === 0)) || type === 5) && (
              <Grid container alignItems="center">
                <Grid item xs={6} sm={5} md={4}>
                  <Select
                    menuPortalTarget={document.body}
                    options={options}
                    value={this.state.selectedOption}
                    classNamePrefix="select"
                    placeholder={"Select Option"}
                    onChange={(e) => this.setState({ selectedOption: e })}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                </Grid>
                &nbsp;
                <ActionButtom
                  classes={classes}
                  onSave={() => {
                    if (this.state.selectedOption !== null) {
                      this.handleOnOptionSelect(this.state.selectedOption);
                    }
                  }}
                />
              </Grid>
            )}
          </>
        )}
        {type === 6 && (
          <>
            <Paper className={classnames(classes.card, classes.paper, this.props.params?.id ? "details-border" : "")}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {additionalData.map((item) => (
                      <TableCell>{item.label}</TableCell>
                    ))}
                    {edit && <TableCell></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {additionalData &&
                    additionalData.length > 0 &&
                    data !== null &&
                    data.map((row, index) => (
                      <TableRow key={`${this.props.itemId}_table_row_${index}`}>
                        {row.map((column, i) => (
                          <TableCell key={`${this.props.itemId}_table_row_${index}_cell_${i}`}>
                            {edit ? (
                              this.showTableColumnFields(column, index, i, classes)
                            ) : (
                              <p className={classes.tableColumnData}>{this.printTableColumnData(column.label, column.type, index, i, classes)}</p>
                            )}
                          </TableCell>
                        ))}
                        {edit && (
                          <TableCell key={`${this.props.itemId}_table_row_${index}_cell`}>
                            <DeleteIcon onClick={() => this.handleRemoveRow(index)} style={{ color: "#cc0d0dd9", cursor: "pointer" }} />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
            {edit && (
              <Grid container justifyContent="space-between" style={{ padding: "10px 24px", marginTop: 20 }}>
                <SqureButton onClick={() => this.addRow()}>Add Row</SqureButton>
              </Grid>
            )}
          </>
        )}
        {(type === 7 || type === 8) && (
          <>
            <Grid container spacing={2}>
              {data !== null &&
                data.length > 0 &&
                data.map((user, userIndex) => (
                  <Grid item xs={12} sm={4} md={3} lg={2} key={userIndex} alignItems="center" className={classes.cardSection}>
                    {edit && <Image src={deleteIcon} className={classes.deleteImage} style={{ top: 15 }} onClick={() => this.handleDeleteUser(userIndex)} />}
                    {type === 7 ? (
                      <Tooltip
                        className={"tooltip"}
                        placement="top"
                        title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{user?.email}</span>}
                        arrow
                      >
                        <Card variant="outlined" style={{ height: "100%" }}>
                          <CardHeader
                            className={classes.contactCardHeader}
                            style={{ textAlign: "center" }}
                            avatar={
                              <Avatar aria-label="recipe" className={classes.contactCardAvatar}>
                                {user?.name?.slice(0, 1)}
                              </Avatar>
                            }
                            title={user?.name}
                            subheader={user?.email}
                          />
                        </Card>
                      </Tooltip>
                    ) : (
                      <Card variant="outlined" style={{ height: "100%" }}>
                        <CardHeader
                          className={classes.contactCardHeader}
                          style={{ textAlign: "center" }}
                          avatar={
                            <Avatar aria-label="recipe" className={classes.contactCardAvatar}>
                              {user?.name?.slice(0, 1)}
                            </Avatar>
                          }
                          title={user?.name}
                          subheader={user?.email}
                        />
                      </Card>
                    )}
                  </Grid>
                ))}
              {edit && <Grid item xs={12} sm={4} md={3} lg={2}>
                <Card
                  className={classes.contactCardRoot}
                  variant="outlined"
                  onClick={() => {
                    this.setState({
                      openAddContactDialog: true,
                    });
                  }}
                >
                  <CardContent>
                    <span className={classes.addNewChamp}>
                      <Image src={AddEvaluationImage} style={{ maxWidth: "58px", cursor: "pointer" }} />
                      <span className={classes.addContact}>{type === 7 ? "Add User" : "Add Group"}</span>
                    </span>
                  </CardContent>
                </Card>
              </Grid>}
            </Grid>
          </>
        )}
        {type === 9 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "16px",
              }}
            >
              {this.state.isShowLoader ? <CircularProgress style={{ height: 30, width: 30, marginBottom: 10 }} /> : <>
                {selectedFiles && selectedFiles.map((file, key) => (
                  <div>
                    {/* <a
                      href={file.url}
                      target={"_blank"}
                      key={file.url}
                      rel="noopener noreferrer"
                      style={{
                        marginBottom: "25px",
                        display: "inline-block",
                        textDecoration: "underline",
                        color: "#4A87F8",
                      }}
                    >
                      {fileNames[key]}
                    </a> */}
                    <FileAncher
                      fileName={fileNames[key]}
                      fileURL={file.url}
                    />
                    &nbsp;&nbsp;&nbsp;
                    {edit && <Image
                      src={deleteIcon}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.handleDeleteFile(key)}
                    />}
                  </div>
                ))}</>}
              {edit && <input
                ref={this.fileRef}
                id="uploadRequirementResponse"
                onChange={this.fileSelectHandler}
                type="file"
                multiple
              />}
            </div>
          </>
        )}
        {type === 10 && (
          <>
            <Grid container spacing={2}>
              {data !== null &&
                data.map((product, k) => {
                  return (
                    <Grid item key={"similar_" + product.product_id} className={classes.productList} xs={12} sm={4} md={3} lg={2}>
                      <div style={{ position: "relative" }}>
                       {edit && <Image src={deleteIcon} className={classes.deleteImage} onClick={() => this.handleRemoveOption(k)} />}
                        <LayeredCard
                          title={product.name}
                          // subTitle={product.CompanyName}
                          description={product.description}
                          customRoute={""}
                          image={product.product_logo}
                          onClick={() => { }}
                          numLayer={0}
                          checked={false}
                          showCheckbox={false}
                          id={product.product_id}
                          uniqueId={product.product_id + "_q_" + k}
                          type={"product"}
                          IsExisting={product.IsExisting}
                        />
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
            {edit && (
              <Grid container alignItems="center" style={{ marginTop: "15px" }}>
                <Grid item xs={6} sm={5} md={4}>
                  <Select
                    isClearable
                    menuPortalTarget={document.body}
                    isSearchable={true}
                    options={productList.filter((product) => {
                      let dataArray = data || [];
                      return !dataArray.some((item) => product.ID === item.ID);
                    })}
                    classNamePrefix="select"
                    placeholder={"Select Product"}
                    value={this.state.selectedOption}
                    onInputChange={(e) => {
                      this.fetchProduct(e);
                    }}
                    onChange={(e) => this.setState({ selectedOption: e })}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                </Grid>
                &nbsp;
                <ActionButtom
                  classes={classes}
                  onSave={() => {
                    this.setState({ edit: false });
                    if (this.state.selectedOption !== null) {
                      let selectedOption = {
                        product_id: this.state.selectedOption.ID,
                        name: this.state.selectedOption.Name,
                        product_logo: this.state.selectedOption.ProductLogo,
                        description: this.state.selectedOption.Description,
                      };
                      this.handleOnOptionSelect(selectedOption);
                    }
                  }}
                />
              </Grid>
            )}
          </>
        )}

        {type === 20 && (
          <Grid container>
            {edit && (
              <div className={classes.currencyTypeFields} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <div style={{ width: 100, marginRight: 5, zIndex: 1 }}>
                  <Select
                    isMulti={false}
                    labelId="singleSelect-select-label"
                    id="singleSelect-select"
                    value={data?.type || {
                      label: "USD",
                      value: "USD",
                    }}
                    onChange={(e) => {
                      let newValue = {
                        type: e,
                        value: data?.value,
                      };
                      this.handleOnChangeTextField(newValue, type);
                    }}
                    options={currencyOptions}
                    placeholder={"Select a Option"}
                    styles={currencyStyles}
                  ></Select>
                </div>

                <CurrencyTextField
                  variant="outlined"
                  value={data?.value}
                  currencySymbol={currencyCodeMap[data?.type?.value]}
                  style={{ textAlign: "left", flex: 1, height: 46, maxWidth: 150 }}
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  required={false}
                  onChange={(e, v) => {
                    let newValue = {
                      type: data?.type,
                      value: v,
                    };
                    this.handleOnChangeTextField(newValue, type);
                  }}
                />
              </div>
            )}
            {!edit && (
              <div>
                {data && data.value && data.value !== "" && <p className={classes.data}>{currencyCodeMap[data.type.value] + "" + data?.value}</p>}
                {data && data.value === "" && <p className={classes.data}>-</p>}
              </div>
            )}
          </Grid>
        )}

        {[7, 8].includes(type) && <AddUserDialog isOpen={this.state.openAddContactDialog} type={type} onSubmit={this.handleAddUser} onClose={() => this.setState({ openAddContactDialog: false })} />}

      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(DocumentFileFields));

const InputField = ({ classes, onChange, val, placeholder, isFocused = false }) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(val);
  }, [val]);
  return (
    <TextField
      fullWidth
      autoFocus={isFocused}
      className={classes.formControl}
      value={value}
      InputProps={{ disableUnderline: true }}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

const ActionButtom = ({ classes, onSave }) => (
  <div>
    <Button className={classes.tickMark} onClick={() => (onSave())}>
      <DoneIcon />
    </Button>
  </div>
);
