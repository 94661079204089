import React from "react";
import CustomImage from "./CustomImage";
import Image from "Components/Common/image.jsx";
import Actions from "./actions";
import "./style.css";
import Settings from "assets/images/nav/settings.svg";
import EditIcon from "assets/images/edit_icon.svg";
import { Avatar, IconButton, Typography, FormControl, MenuItem, Select, } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Tooltip from '@material-ui/core/Tooltip';
import deleteIcon from "assets/images/delete.svg";
import classnames from "classnames";
import ParallalComponent from "./ParallalComponent";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const nodeLabel = (
  isShowConfigIcon,
  slug,
  id,
  title,
  x,
  y,
  onSelectCompoent,
  onClickDecisionComponent,
  onClickWorkFlowComponent = () => { },
  isEdit,
  currentActiveUsers = [],
  removeComponent,
  isShowPermissionDropDown,
  permission,
  updateComponentPermission,
  builderFor,
  isShowDeleteIcon
) => {

  const avatarName = name => {
    return name.substring(0, 1);
  };

  return (
    <div className={`component-node ${["START", "FINISH", "STOP"].includes(slug) ? `${slug.toLowerCase()}-node` : ""}`}>
      <div className="component-node-body-container">
        {slug === "WORKFLOW" && (
          <>
            <div className="layer-one workflow"></div>
            <div className="layer-two workflow"></div>
          </>
        )}
        <div className={classnames(`component-node-body ${slug === "WORKFLOW" ? "workflow-component" : ""}`, isShowPermissionDropDown ? `hideBottomBorder` : '')}>
          <div className="img">
            <CustomImage slug={slug} builderFor={builderFor} />
          </div>
          <p className={builderFor === 'PROJECT' ? "labelProject" : "label"} title={title}>
            {title}
            {!isEdit && <CheckCircleOutlineOutlinedIcon className="checkIconcustomimg" />}
          </p>
          <div className="component-settings">
            {isShowConfigIcon && <IconButton
              className="settingIcon show-config">
              <Image className="show-config" src={slug === "WORKFLOW" && builderFor === 'PROJECT' ? EditIcon : Settings} />
            </IconButton>}

            {isShowConfigIcon && slug === "WORKFLOW" && builderFor === 'PROJECT' && isShowDeleteIcon && <IconButton
              className="settingIcon wrkFlowComponent-Delete">
              <Image src={deleteIcon} onClick={() => {
                removeComponent(id)
              }} />
            </IconButton>}
          </div>

          {slug !== 'START' && !isShowConfigIcon && isEdit && isShowDeleteIcon &&
            <IconButton className={isEdit ? 'componentDeleteIcon' : 'hideDeleteIcon'}><Image className="componentDeleteIcon" src={deleteIcon} onClick={() => {
              removeComponent(id)
            }} /></IconButton>
          }
          {currentActiveUsers !== null && currentActiveUsers !== undefined && currentActiveUsers.length > 0 && <AvatarGroup max={3} className="user_wrk_avatar">
            {currentActiveUsers.map((user, k) => {
              if (user === undefined) {
                return '';
              }
              var userName = user.Name !== undefined ? user?.Name : 'Admin'
              return <Tooltip key={user.ID} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{user?.Name + '\n' + user?.Email}</span>} arrow>
                <Avatar key={k}> {avatarName(userName)} </Avatar>
              </Tooltip>
            })}
          </AvatarGroup>}
        </div>
        {/* {!isEdit && isShowPermissionDropDown && <div className={`component-node-body-p2`}>
          <Typography variant={"h4"} className={'roleTextTitle'}>Permission: {permission === '' || permission === null ? '-'  : permission === 2 ? 'Admin' : 'Everyone' }</Typography>
        </div>} */}

        {isShowPermissionDropDown && <div className={slug === "WORKFLOW" ? `component-node-body-workflow` : `component-node-body-p2`}>
          <Typography variant={"h4"} className={'roleTextTitle'}>Permission</Typography>
          <FormControl variant="outlined" className={'roleFormControl'} size="small">
            <Select
              disabled={isEdit ? false : true}
              value={permission}
              onChange={(e) => {
                updateComponentPermission({
                  componentId: id,
                  permission: e.target.value
                })
              }}>
              <MenuItem value={2} style={{ fontSize: 13 }}>Admin</MenuItem>
              <MenuItem value={3} style={{ fontSize: 13 }}>Everyone</MenuItem>
            </Select>
          </FormControl>
        </div>}

        {(process.env.REACT_APP_ENV === 'dev') && slug !== "FINISH" && slug !== "START" && slug !== "DECISION_COMPONENT" && isEdit === true &&
          <ParallalComponent
            position={'left'}
            onSelectCompoent={(e, cid) => {
              onSelectCompoent(e, cid, id, x, y, {
                isParallel: true,
                position: 'left'
              })
            }}
            onClickWorkFlowComponent={(e, type, wrkflowID, name, description) => {
              onClickWorkFlowComponent(e, id, x, y, wrkflowID, type, name, description, {
                isParallel: true,
                position: 'left'
              });
            }}
          />
        }

        {(process.env.REACT_APP_ENV === 'dev') && slug !== "FINISH" && slug !== "START" && slug !== "DECISION_COMPONENT" && isEdit === true &&
          <ParallalComponent
            position={'right'}
            onSelectCompoent={(e, cid) => {
              onSelectCompoent(e, cid, id, x, y, {
                isParallel: true,
                position: 'right'
              })
            }}
            onClickWorkFlowComponent={(e, type, wrkflowID, name, description) => {
              onClickWorkFlowComponent(e, id, x, y, wrkflowID, type, name, description, {
                isParallel: true,
                position: 'left'
              });
            }}
          />
        }

      </div>
      {slug !== "FINISH" && isEdit === true && (
        <>
          <div className="line"></div>
          <Actions
            onSelectCompoent={(e, cid) => onSelectCompoent(e, cid, id, x, y)}
            onClickDecisionComponent={(e) => {
              onClickDecisionComponent(e, id, x, y);
            }}
            onClickWorkFlowComponent={(e, type, wrkflowID, name, description) => {
              onClickWorkFlowComponent(e, id, x, y, wrkflowID, type, name, description);
            }}
          />
        </>
      )}
    </div>
  );
};

export { nodeLabel };
