import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, OutlinedInput, FormHelperText } from "@material-ui/core"
import PropTypes from 'prop-types';
import PasswordStrenght from "../PasswordStrenght";
import classnames from "classnames";
import uniqid from "uniqid";
import ToolTip from "Components/Common/ToolTip.jsx";

const styles = (theme) => createStyles({
    "input": {
        textAlign: "left",
        marginBottom: theme.spacing(3.5),
        background: "#FFF"
    },
    rounded: {
        borderRadius: theme.spacing(3),
    },
    "label": {
        marginBottom: theme.spacing(1.6),
        fontSize: theme.spacing(2),
        color: "#6C6C6C"
    },
    outlinedInput: {
        fontSize: theme.spacing(2.2),
        color: "#5F5F5F",
        padding: theme.spacing(1.5),
    },
    helperText: {
        fontSize: theme.spacing(1.5),
    },
    nobg: {
        background: "none"
    },
    inputRoot: {
        background: "#fff",
        minHeight: "50px",
        width: "100%",
        // borderRadius: '1.5em'
    },
    searchIconPop: {
        cursor: 'pointer',
        fontSize: theme.spacing(3),
        marginRight: '10px',
        "&:hover": {
            color: "#fff !important",
            background: "#707070",
            borderColor: "#707070",
            padding: '3px',
            borderRadius: theme.spacing(20),
            fontSize: theme.spacing(4)
        }
    },
});

class Outlined extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.btnRef = React.createRef();
    }

    render() {
        var classes = this.props.classes;
        const inputPropsObj = {
            autoComplete: "off",
            autoCorrect: "off",
            autoCapitalize: "off",
            spellCheck: "false"
        }
        if (this.props.maxLength && this.props.maxLength > 0) {
            inputPropsObj.maxLength = this.props.maxLength
        }
        return <div style={this.props.style} className={classnames(classes.input, { [classes.nobg]: this.props.roundedCorner, [classes.inline]: this.props.labelStyle === "inline" })}>
            {this.props.label !== null && <InputLabel
                className={classes.label}
                error={this.props.error}
                htmlFor={this.props.id}>{this.props.label}{this.props.required && <span style={{ 'color': 'red' }}>&nbsp;*</span>} {this.props.toolTip && <ToolTip toolTip={this.props.toolTip} />}</InputLabel>}
            <FormControl variant="outlined" fullWidth={this.props.fullWidth || this.props.labelStyle !== "inline"} onClick={this.props.onClick} className={classes.formControl}>
                <OutlinedInput
                    id={this.props.id === "input" ? "input_" + uniqid() : this.props.id}
                    name={this.props.name}
                    type={this.props.type}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    onKeyDown={this.props.onKeyDown}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    error={this.props.error}
                    disabled={this.props.disabled}
                    startAdornment={this.props.startAdornment}
                    endAdornment={this.props.endAdornment}
                    labelWidth={0}
                    placeholder={this.props.placeholder}
                    multiline={this.props.multiline}
                    fullWidth={this.props.fullWidth || this.props.labelStyle !== "inline"}
                    rows={this.props.rows}
                    classes={{
                        root: classnames(classes.inputRoot, { [classes.rounded]: this.props.roundedCorner }),
                        input: classnames(classes.outlinedInput, {
                            [classes.rounded]: this.props.roundedCorner,
                            [classes.compact]: this.props.compact || this.props.labelStyle === "inline"
                        })
                    }}
                    readOnly={this.props.readOnlyInput}
                    autoFocus={this.props.autoFocus}
                    inputProps={inputPropsObj}
                />
                {this.props.helperText.trim().length > 0 && <FormHelperText
                    className={classes.helperText}
                    error={this.props.error}
                >{this.props.helperText}</FormHelperText>}
                {this.props.passwordStenghtMeter && <PasswordStrenght value={this.props.value} />}
            </FormControl>
        </div>
    }
}

Outlined.defaultProps = {
    labelWidth: 70,
    type: 'text',
    name: '',
    value: null,
    onChange: null,
    onKeyDown: null,
    icon: null,
    icon_position: 'end',
    onIconClick: null,
    placeholder: "",
    required: false,
    error: false,
    disabled: false,
    helperText: '',
    passwordStenghtMeter: false,
    label: null,
    multiline: false,
    rows: 1,
    roundedCorner: false,
    'id': 'input',
    readOnlyInput: false,
    labelStyle: "default",
    compact: false,
    fullWidth: false,
    maxLength: 0,
}

Outlined.propTypes = {
    id: PropTypes.string,
    maxLength: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    icon: PropTypes.element,
    icon_position: PropTypes.oneOf(['end', 'start']),
    onIconClick: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    passwordStenghtMeter: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    roundedCorner: PropTypes.bool,
    onClick: PropTypes.func,
    readOnlyInput: PropTypes.bool,
    labelStyle: PropTypes.oneOf(['default', 'inline']),
    compact: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

export default withStyles(styles)(Outlined)