import React from 'react';
import Select from 'react-select';
import classnames from "classnames";
import CommonCss from 'commonCss';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  Typography, CircularProgress, Grid, Card, CardContent, CardHeader, Avatar,
  FormControlLabel, Checkbox, InputAdornment, Slider, Button, FormControl
} from '@material-ui/core';

import OutlinedInput from "../Input/OutlinedInput";
import _ from "underscore";
import Radio from "@material-ui/core/Radio";
import RadioGroup from '@material-ui/core/RadioGroup';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "assets/images/calendar.svg";
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";
import Image from 'Components/Common/image.jsx';

import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import SqureButton from 'Components/Common/SqureButton';

import * as Environment from "util/Environment";
import axios from "axios";
import { CommonFn } from "services/commonFn";
import { showSnackBar } from "redux/snackbar/action";
import { fetchOrgGroup } from "redux/org/group/action";
import { searchUsers } from "redux/usersSettings/action";

import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import DefaultImage from "assets/images/evaluation/default.svg";
import TextField from '@material-ui/core/TextField';
import deleteIcon from "assets/images/delete.svg";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import * as Validator from "util/Validation";
import { webHookTriggerRun } from "redux/workflow/action";
import { currencyOptions, currencyCodeMap } from "util/Currency";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";

import CreatableSelect from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import { IsExistingProduct } from "Components/Common/SelectProductOption";
import { fetchAddOrgProduct } from "redux/product/orgProduct/action";
import TableTypeReply from './TableTypeReply';
import FileAncher from '../FileAncher';

const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

const SelectAsyncPaginate = withAsyncPaginate(Select);

const connectedProps = (state) => ({
  groups: state.orgGroup.groups,
  users: state.usersSettings.users,
  similarExistingProductProgress: state.orgProduct.similarExistingProductProgress,
  similarExistingProducts: state.orgProduct.similarExistingProducts,
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  products: state.products.recommendedProducts,
  wekHookRunProgress: state.workflow.wekHookRunProgress,
  wekHookRunError: state.workflow.wekHookRunError,
  wekHookRunSuccess: state.workflow.wekHookRunSuccess,
  recommendedProducts: state.products.recommendedProducts,
  addOrgProductProgress: state.orgProduct.addOrgProductProgress,
  addorgProductStatus: state.orgProduct.addorgProductStatus,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchUsers: searchUsers,
  fetchOrgGroup: fetchOrgGroup,
  webHookTriggerRun: webHookTriggerRun,
  fetchAddOrgProduct: fetchAddOrgProduct
};

export const itemTypes = [
  { label: 'Simple Text', value: 1 },
  { label: 'Date', value: 2 },
  { label: 'Binary', value: 3 },
  { label: 'Single Select', value: 4 },
  { label: 'Multi Select', value: 5 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8 },
  { label: 'File', value: 9 },
  { label: 'Products', value: 10 }, //Pending
  { label: 'Rich Text', value: 11 },
  { label: 'Email', value: 12 },
  { label: 'Phone', value: 13 },
  { label: 'Number', value: 15 },
  { label: 'URL', value: 14 },
  { label: 'Currency', value: 16 },
  { label: 'Existing Products', value: 17 },
  { label: 'Score', value: 18 },
  { label: 'Radio', value: 19 },
  { label: 'Checkbox', value: 20 },
]

var connector = connect(connectedProps, connectionActions);

var currencyStyles = {
  control: styles => ({ ...styles, height: 56 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  })
};

const styles = (theme) => createStyles({
  root: {
    // marginBottom: '16px',
    width: "100%",
    "& ul.answers": {
      listStyle: "none",
      paddingLeft: 0,
      "& li": {
        border: "1px solid #EBEBEB",
        bordeRadius: "4px",
        paddingLeft: "8px",
        marginBottom: "8px",
        "& span": {
          fontSize: 13,
          color: "#6D7278",
        },
      },
    },
    "& [class*='editableField']": {
      background: '#fff',
      maxHeight: 'auto !important',
      minHeight: 'auto !important'
    },
    // "& .react-datepicker-popper":{
    //   zIndex:'100 !important'
    // },
    // "& .react-datepicker__input-container > div": {
    //   marginTop: 10
    // }
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  calendarPopout: {
    zIndex: '100 !important'
  },
  itemTitle: {
    fontWeight: 400,
    color: '#282D30',
    fontSize: 14,
    margin: '30px 0px 0px'
  },
  formControl: {
    ...CommonCss.formControl,
  },
  creatable: {
    padding: 0
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 16
  },
  searchAndSelect: {
    padding: 0,
    "& .select__control, & .select__control:hover": {
      border: 'none',
      height: 'inherit',
      boxShadow: 'none',
      minHeight: 44
    },
    "& .select__multi-value, & .select__multi-value__label": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20,
      padding: theme.spacing(.3, .6),
      fontSize: 13
    },
    "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20
    },
  },
  deleteIcon: {
    position: "absolute",
    right: 16,
    top: 13,
    cursor: "pointer",
  },
  counter: {
    color: 'rgb(112, 112, 112)',
    fontSize: 10,
    margin: 0,
    marginTop: 2,
    padding: 3,
    backgroundColor: '#f1f1f1',
    width: 'max-content',
    borderRadius: 3,
  },
  cardSection: {
    position: "relative",
  },
  contactCardHeader: {
    flexDirection: "column",
    padding: "16px 4px !important",
    "& [class*='MuiCardHeader-avatar-']": {
      margin: "0px",
      marginBottom: 12,
    },
    "& [class*='MuiCardHeader-content']": {
      width: "100%",
    },
    "& [class*=MuiTypography-displayBlock]": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      margin: "0px -19px 0px 0px",
    },
  },
  addNewChamp: {
    display: "block",
    textAlign: "center",
    color: "#bdbdbd",
    "& span": {
      display: "block",
      cursor: "pointer",
      color: "#000000",
    },
  },
  addContact: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    margin: "0px -19px 0px 0px",
  },
  dialogContent: {
    minHeight: 170,
    overflowY: 'hidden',
    "& [class*=select__menu]": {
      maxHeight: 100
    }
  },
  customTypeFields: {
    marginBottom: 15,
    marginTop: 15,
    '& [class*="MuiFormControl-root"]': {
      width: '100%'
    },
    '& [class*="MuiInputBase-input"]': {
      textAlign: 'left'
    }
  },
  currencyTypeFields: {
    marginBottom: 15,
    marginTop: 15,
    '& [class*="MuiFormControl-root"]': {
      width: '100%'
    },
    '& [class*="MuiInputBase-input"]': {
      textAlign: 'left'
    },
    '& [class*="MuiSelect-root"]': {
      height: 56
    }
  },
  2: {
    cursor: 'pointer'
  },
  requiredField: {
    // color: 'red'
  },
  ratingRoot: {
    // background: '#f1f1f1',
    color: "#fff",
    padding: '1px',
    width: '100%'
  },
  rating: {
    marginTop: '10px',
    marginBottom: '5px',
    '& [class*="MuiSlider-markActive"]': {
      background: '#bfb4b4'
    }
  },
  slider: {
    background: '#f7f7f7',
    display: 'flex',
    alignItems: 'center',
    padding: '2px 10px',
    borderRadius: '10px',
  },
  sliderCount: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
  },
  thumb: {
    color: "#1f73b7"
  },
  track: {
    backgroundColor: "#1f73b7"
  },
  rail: {
    backgroundColor: "#1f73b7"
  },
  resListItem: {
    marginRight: '5px',
    marginLeft: '5px',
    borderRadius: '4px',
    border: '1px solid #707070',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: theme.spacing(1.8),
    color: '#707070',
    padding: '3px',
    width: '100%'
  },
  counter1: {
    textAlign: 'left',
    paddingLeft: 6
  },
  counter2: {
    textAlign: 'right',
    paddingRight: 6
  },
  counterItem: {
    color: '#1f73b7'
  },
  product_list: {
    marginBottom: 10
  },
  productList: {
    position: 'relative',
    '& [class*=LayeredCard-root]': {
      marginTop: 0,
    },
  },
  deleteImage: {
    position: "absolute",
    top: 10,
    zIndex: 20,
    right: 10,
    padding: 4,
    cursor: "pointer",
  },
  tickMark: {
    color: "#4b86f8",
    marginLeft: 10,
    border: "1px solid #4b86f8",
    // marginRight: 10,
    padding: 6,
    minWidth: "50px !important",
    "&:hover": {
      color: "#4b86f8",
    },
  },
  noMarginTop: {
    marginTop: 0
  },
  description: {
    color: '#4B4B4B',
    fontSize: 12,
    margin: 0,
    paddingTop: 6
  },
  textfieldClass: {
    '& [class*=MuiOutlinedInput-input]': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    }
  }
});

class CustomReply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      text_value: '',
      selectedOption: '',
      selectedOptions: [],
      selectedFiles: [],
      fileNames: [],
      selectedUsers: [],
      selectedTeams: [],
      dialogTitle: '',
      isUser: false,
      isOpenDialog: false,
      similarProducts: {},
      isValidationError: false,
      isEditProducts: false,
      productList: [],
      selectedProducts: [],
      otherValue: '',
      isShowLoader: false,
      addProductForMe: false,
      currencyType: {
        label: 'USD',
        value: 'USD'
      }
    }
    this.fileRef = React.createRef();
    this.updateDate = this.updateDate.bind(this);
    this.updateTextValue = this.updateTextValue.bind(this);
    this.setSelectedOption = this.setSelectedOption.bind(this);
    this.setSelectedOptions = this.setSelectedOptions.bind(this);
    this.fileSelectHandler = this.fileSelectHandler.bind(this);
    this.producDetail = this.producDetail.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateURL = this.validateURL.bind(this);
    this.setSingleSelectOption = this.setSingleSelectOption.bind(this);
    this.setMultiSelectOptions = this.setMultiSelectOptions.bind(this);
    this.setOtherOption = this.setOtherOption.bind(this);
    this.updateOtherValue = this.updateOtherValue.bind(this);
  }

  componentDidMount() {
    this.initValues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.initValues();
    }

    if (this.props.wekHookRunProgress === false && prevProps.wekHookRunProgress === true) {
      if (this.props.wekHookRunError === null) {
        this.props.showSnackBar("Web Hook Run Successfully", "success", 1500);
      } else {
        this.props.showSnackBar("Something went wrong", "error", 1500);
      }
    }



    if (this.props.addOrgProductProgress === false && prevProps.addOrgProductProgress === true && this.state.addProductForMe) {
      // console.log(this.props.addorgProductStatus, 'addorgProductStatus')
      this.setState({
        selectedOption: {
          name: this.props.addorgProductStatus?.orgProduct?.name,
          value: this.props.addorgProductStatus?.orgProduct?.productID,
          id: this.props.addorgProductStatus?.orgProduct?.productID,
          logo: '',
          description: '',
          company_name: '',
          states: null
        }
      }, () => {
        this.setSelectedProducts(this.state.selectedOption, true)
      })
    }

  }

  async getOptions(inputValue, loadedOptions, additional) {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("SEARCH");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      field_setname: "all",
      text: inputValue,
      page,
      page_size: 150,
      sort_fields: [],
      filter_fields: {
        // verified:[1]
      },
      merge_existing_others: true,
    };
    let url = `${api_server}/v2/dsl/expent_catalogue?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let products = response?.data?.body?.items || [];
    let total = response?.data?.body?.total || 0;

    products = products.map((m) => {
      m.value = parseInt(m.id);
      m.label = m.name;
      m.isProduct = true;
      m.productStates = m.states;
      m.isDisabled = m.states?.blocked !== undefined;
      return m;
    });
    return {
      options: products,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  }

  initValues() {
    let { type, value, additional_data } = this.props;
    if (type === 1 || type === 11 || type === 12 || type === 13 || type === 14 || type === 15 || type === 18) {
      this.updateTextValue(value, false);
    } else if (type === 2) {
      this.updateDate(value, false);
    } else if (type === 3) {
      this.setSelectedOption(value, false);
    } else if (type === 6) {
      this.updateTableValue(value, false);
    } else if (type === 16) {
      if (value !== undefined && value !== null && value !== '') {
        let newValue;
        try {
          newValue = JSON.parse(value);
        } catch { }
        if (newValue !== undefined && newValue !== null && newValue !== '' && newValue.value !== undefined && newValue.value !== null && newValue.value !== '') {
          this.setState({
            currencyType: newValue.type,
            text_value: newValue.value
          })
        } else if (value.type !== undefined && value.type !== null && value.type !== '') {
          this.setState({
            currencyType: value.type,
            text_value: value.value
          })
        } else {
          this.updateCurrencyValue(value, false);
        }
      } else {
        this.updateCurrencyValue(value, false);
      }
    } else if (type === 19) {
      if (value !== undefined && value !== null && value !== '') {
        // console.log(value)
        if (value.indexOf('Other-') !== -1) {
          let newValue = value.replace('Other-', '');
          this.setOtherOption('9999999', false);
          this.updateTextValue(newValue, false);
        } else {
          this.setSelectedOption(value, false);
        }
      }
    } else if (type === 20) {
      if (value !== undefined && value !== null && value !== '') {
        console.log(value, 'Checkkkkkkkkkkkkkkkkkkkkk')
        let options = [];
        let otherValue = '';
        value.forEach((itm) => {
          if (String(itm).indexOf('Other-') !== -1) {
            otherValue = itm;
          }
          options.push(itm)
        });
        this.setSelectedOptions(options, false);
        if (otherValue !== undefined && otherValue !== null && otherValue !== '') {
          if (otherValue.indexOf('Other-') !== -1) {
            let newValue = otherValue.replace('Other-', '');
            this.setOtherOption('9999999', false);
            this.updateTextValue(newValue, false);
          }
        }
      } else {
        this.setSelectedOptions(value, false);
      }
    } else if (type === 4) {
      // console.log(value, 'value')
      let option;
      additional_data.forEach(function (itm) {
        if (parseInt(value) === itm.value) {
          option = itm;
        }
      });
      this.setSingleSelectOption(option, false);
    } else if (type === 5) {
      let options = [];
      additional_data.forEach(function (itm) {
        if (value.indexOf(itm.value) !== -1) {
          options.push(itm);
        }
      });
      this.setMultiSelectOptions(options, false);
    } else if (type === 7) {
      this.setSelectedUsersOnLoad(value, false);
    } else if (type === 8) {
      this.setSelectedTeamsOnLoad(value, false);
    } else if (type === 9) {
      if (value !== undefined && value !== null && value !== '') {
        let newValue;
        try {
          newValue = JSON.parse(value);
        } catch { }
        if (newValue !== undefined && newValue !== null && newValue !== '') {
          if (Array.isArray(newValue)) {
            this.setState({
              selectedFiles: newValue,
              fileNames: newValue.map(file => file.name)
            })
          } else {
            this.setState({
              selectedFiles: [{ name: newValue.name, url: newValue.url }],
              fileNames: [newValue.name]
            })
          }
        } else if (value !== undefined && value !== null && value !== '') {
          if (Array.isArray(value)) {
            this.setState({
              selectedFiles: value,
              fileNames: value.map(file => file.name)
            })
          } else {
            this.setState({
              selectedFiles: [{ name: value.name, url: value.url }],
              fileNames: [value.name]
            })
          }
        }
      }
    } else if (type === 10) {
      this.setSelectedProducts(value, false);
    }
  }

  updateDate(date, isUpdate) {
    this.setState({
      selectedDate: (date !== undefined && date !== null && date !== '') ? new Date(date) : null
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedDate)
      }
    })
  }

  updateTableValue(value, isUpdate) {
    this.setState({
      text_value: (value !== undefined && value !== null && value !== '') ? value : []
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.text_value)
      }
    })
  }

  updateTextValue(value, isUpdate) {
    this.setState({
      text_value: (value !== undefined && value !== null && value !== '') ? value : ''
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.text_value)
      }
    })
  }

  updateCurrencyValue(value, isUpdate) {
    this.setState({
      text_value: (value !== undefined && value !== null && value !== '') ? value : ''
    }, () => {
      if (isUpdate) {
        this.props.onChange({
          type: this.state.currencyType,
          value: this.state.text_value
        })
      }
    })
  }

  setSelectedOption(value, isUpdate) {
    this.setState({
      selectedOption: (value !== undefined && value !== null && value !== '') ? value : '',
      otherValue: false
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedOption)
      }
    })
  }

  setSelectedOptions(options, isUpdate) {
    this.setState({
      selectedOptions: (options !== undefined && options !== null && options !== '') ? options : [],
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedOptions)
      }
    })
  }

  setSingleSelectOption(option, isUpdate) {
    // console.log(option)
    this.setState({
      selectedOption: (option !== undefined && option !== null && option !== '') ? option : []
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedOption.value)
      }
    })
  }

  setMultiSelectOptions(options, isUpdate) {
    this.setState({
      selectedOptions: (options !== undefined && options !== null && options !== '') ? options : []
    }, () => {
      if (isUpdate) {
        let options = [];
        this.state.selectedOptions.forEach(function (itm) {
          options.push(itm.value);
        })
        this.props.onChange(options)
      }
    })
  }

  setOtherOption(value, isUpdate) {
    // console.log(value, 'Newww Value Other')
    this.setState({
      selectedOption: '',
      otherValue: value
    }, () => {
      if (isUpdate) {
        let newText = 'Other-' + this.state.text_value;
        this.props.onChange(newText, true)
      }
    })
  }

  setOtherOptionCheckbox(value, isUpdate) {
    console.log(value, 'NewwwValueOther')
    this.setState({
      selectedOption: '',
      otherValue: value
    }, () => {
      if (isUpdate) {
        let options = [...this.state.selectedOptions];
        let newOptions = [];
        options.forEach((itm) => {
          if (String(itm).indexOf('Other-') === -1) {
            newOptions.push(itm);
          }
        });
        this.props.onChange(newOptions, true)
      }
    })
  }

  updateOtherValue(value, isUpdate) {
    this.setState({
      text_value: (value !== undefined && value !== null && value !== '') ? value : '',
      formError: '',
    }, () => {
      if (isUpdate) {
        if (this.props.type === 19) {
          let newText = 'Other-' + this.state.text_value;
          this.props.onChange(newText, true)
        } else {
          let options = [...this.state.selectedOptions];
          let newText = 'Other-' + this.state.text_value;
          let newOptions = [];
          options.forEach((itm) => {
            if (String(itm).indexOf('Other-') === -1) {
              newOptions.push(itm);
            }
          });
          newOptions.push(newText);
          // console.log(newOptions)
          this.props.onChange(newOptions, true)
        }
      }
    })
  }

  setSelectedUsersOnLoad(options, isUpdate) {
    this.setState({
      selectedUsers: (options !== undefined && options !== null && options !== '') ? options : []
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedUsers)
      }
    })
  }

  setSelectedTeamsOnLoad(options, isUpdate) {
    this.setState({
      selectedTeams: (options !== undefined && options !== null && options !== '') ? options : []
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedTeams)
      }
    })
  }

  setSelectedUsers(user, isUpdate) {
    var users = this.state.selectedUsers;
    var index = users.findIndex(t => t.ID === user.ID)
    if (index === -1) {
      users.push(user);
    } else {
      users.splice(index, 1);
    }
    this.setState({
      selectedUsers: users
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedUsers)
      }
    })
  }

  setSelectedTeams(team, isUpdate) {
    var teams = this.state.selectedTeams;
    var index = teams.findIndex(t => t.ID === team.ID)
    if (index === -1) {
      teams.push(team);
    } else {
      teams.splice(index, 1);
    }
    this.setState({
      selectedTeams: teams
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedTeams)
      }
    })
  }

  setSelectedProducts(product, isUpdate) {
    if (product !== undefined && product !== null && product !== '') {
      if (isUpdate) {
        if (product?.__isNew__ !== undefined && product?.__isNew__ === true) {
          let new_product = {
            name: product.label,
            companyName: '',
            productURL: '',
            productId: null,
            productLogo: '',
            description: '',
            deploymentType: '',
            phone: '',
            email: '',
            isInternal: false,
            isExsting: false,
          };
          new_product.financialDetails = {
            numberOfLicenseProcured: 0,
            paymentTerm: 0,
            costPerLicense: 0,
            totalCost: '0',
            invocieDiscount: 0,
          };
          new_product.cat = [];
          new_product.champ = [];
          this.setState({
            addProductForMe: true
          })
          this.props.fetchAddOrgProduct(new_product);
        } else {
          let IsExisting = false;
          if (product.states !== undefined && product.states !== null) {
            if (Object.keys(product.states).indexOf('existing') !== -1) {
              IsExisting = true;
            }
          }
          let productData = {
            ID: product?.id,
            Name: product?.name,
            CompanyName: product?.company_name,
            Description: product.description || '',
            ProductLogo: product.logo || '',
            IsExisting: IsExisting,
          }
          var products = this.state.selectedProducts !== null ? this.state.selectedProducts : [];
          if (product !== undefined && product !== null && product !== '') {
            products.push(productData)
          }
          this.setState({
            selectedProducts: products,
            selectedOption: null
          }, () => {
            this.props.onChange(this.state.selectedProducts)
          })
        }
      } else {
        this.setState({
          addProductForMe: false,
          selectedProducts: product
        })
      }
    }
  }

  handleRemoveProduct = (index) => {
    let data = this.state.selectedProducts;
    data.splice(index, 1)
    this.setState({
      selectedProducts: data
    }, () => {
      this.props.onChange(this.state.selectedProducts)
    })
  }

  handleDeleteFile = (index) => {
    let newFileNames = [...this.state.fileNames]
    let newSelectedFiles = [...this.state.selectedFiles]
    newFileNames.splice(index, 1);
    newSelectedFiles.splice(index, 1);
    this.setState({
      selectedFiles: newSelectedFiles,
      fileNames: newFileNames
    })
    this.props.onChange(newSelectedFiles);
    this.fileRef.current.value = '';
  }

  fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ isShowLoader: true })
      const { orgId, evaluationId, workflowId } = this.props;
      const files = event.target.files;
      var formData = new FormData();
      formData.append(
        "folder",
        `${orgId}/evaluation/${evaluationId}/workflow/${workflowId}`
      );
      formData.append("fileLength", files.length);
      const timestamp = new Date().getTime();
      let nameArr = [];
      for (const key of Object.keys(files)) {
        nameArr.push(files[key].name)
        formData.append(
          `file${key}`,
          files[key],
          `${timestamp}${files[key].name}`
        );
      }
      nameArr.push(...this.state.fileNames);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/external/multi-add?t=${timestamp}`;

      axios.post(url, formData, {
        headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        let resData = response.data?.filepath || {};
        let keyData = Object.keys(resData) || [];
        let data = keyData.map((key, i) => ({
          name: nameArr[i],
          url: resData[key]
        }))
        this.setState({
          selectedFiles: [...data, ...this.state.selectedFiles],
          fileNames: nameArr,
          isShowLoader: false
        }, () => {
          this.props.onChange(this.state.selectedFiles);
        })
        this.props.showSnackBar("File uploaded", "success", 1500);
      })
        .catch(err => {
          if (err.response) {
            this.props.showSnackBar(err.response.data.error, "error", 3000);
          } else if (err.request) {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          } else {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          }
          this.setState({ isShowLoader: false });
        })
    }
  };

  producDetail = (e, product) => {
    var url = `/app/products/org-product-details/${product.ID}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null;
  };

  validateEmail(value) {
    if (value !== '') {
      if (Validator.validateEmail(value) === false) {
        this.setState({
          isValidationError: true
        })
      } else {
        this.setState({
          isValidationError: false
        })
      }
    }
  }

  validateURL(value) {
    if (value !== '') {
      if (Validator.validateURL(value) === false) {
        this.setState({
          isValidationError: true
        })
      } else {
        this.setState({
          isValidationError: false
        })
      }
    }
  }

  webHookRun() {
    let { itemId, webHookItemIdMap, evaluationId } = this.props;
    // console.log(additional_data,'additional_data')
    // console.log(item,'item')
    // console.log(evaluationId,'evaluationId')
    if (webHookItemIdMap !== undefined && webHookItemIdMap !== null && webHookItemIdMap[itemId] !== undefined) {
      let data = webHookItemIdMap[itemId];
      let dataFields = [];
      if (data) {
        dataFields = data.dataFields.map((itm) => {
          return {
            form_type: itm?.form_type?.value,
            form_id: itm?.form_id?.value,
            field_id: itm?.field_id?.value,
          }
        })
      }

      let params = {
        triggerType: data?.triggerType?.value,
        triggerURL: data?.triggerURL,
        dataFields: dataFields
      }
      this.props.webHookTriggerRun(evaluationId, params);
    }
  }

  getUsersOption = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      let appList = [];
      this.props.users?.forEach((item, index) => {
        appList.push({
          label: item.Name,
          value: item.ID,
          id: item.ID,
          ID: item.ID,
          Name: item.Name,
          Email: item.Email,
        })
      })
      return {
        options: appList,
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      page: 0,
      pageSize: 10,
      query: inputValue
    };
    let url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
      id: m.ID,
      ID: m.ID,
      Name: m?.Name,
      Email: m?.Email,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  getTeamsOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      let appList = [];
      this.props.groups?.forEach((item, index) => {
        appList.push({
          label: item.Name,
          value: item.ID,
          id: item.ID,
          ID: item.ID,
          Name: item.Name,
          Email: item.Email,
        })
      })
      return {
        options: appList,
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    const url = `${api_server}/groups?page=0&pageSize=20&query=${inputValue}&is_suppressed=no&t=${timestamp}`;
    const response = await axios.get(url, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
      id: m.ID,
      ID: m.ID,
      Name: m.Name,
      Email: m.Email,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  getTeamsOptions1 = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    const url = `${api_server}/groups?page=0&pageSize=20&query=${inputValue}&is_suppressed=no&t=${timestamp}`;
    const response = await axios.get(url, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
      id: m.ID,
      ID: m.ID,
      Name: m.Name,
      Email: m.Email,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  render() {
    let { additional_data, type, title, description, key, index, isRequired, isFormEditable, item, error, decisionItemMap, itemId, sectionId, zIndexForItem } = this.props;
    const classes = this.props.classes;
    let { selectedDate, selectedOption, text_value, selectedOptions, selectedFiles, fileNames, selectedUsers, selectedTeams, selectedProducts, isEditProducts, otherValue, currencyType } = this.state;

    let similarProducts = this.props.similarExistingProducts;

    let isFieldDisabled = false;
    if (decisionItemMap && decisionItemMap !== undefined && decisionItemMap !== null) {
      if (decisionItemMap[sectionId] !== undefined && decisionItemMap[sectionId].indexOf(itemId) !== -1) {
        isFieldDisabled = true;
      }
    }
    if (isFormEditable === false) {
      isFieldDisabled = true;
    }

    if (type === 6) {
      console.log(item, 'item------------------');
      console.log(text_value, 'text_value_________item');
    }

    let zIndexItm = 1;
    if (zIndexForItem !== undefined && zIndexForItem !== null && zIndexForItem !== '') {
      zIndexItm = zIndexForItem;
    }

    let maxLength = false;
    if (type === 1 && additional_data?.character_limit > 0) {
      maxLength = additional_data?.character_limit;
    }

    let userProfile = false;
    if (type === 7 && additional_data.userProfile !== undefined && additional_data.userProfile !== null && additional_data.userProfile !== '') {
      userProfile = additional_data.userProfile
    }

    return (
      <div key={key} className={classes.root} >
        <p style={{ color: 'red' }}>{error}</p>

        {/* <Typography variant={"h5"} className={classes.itemTitle} >{title}</Typography> */}
        {(type !== 16 && type !== 17 && type !== 1 && type !== 12 && type !== 13 && type !== 14 && type !== 15 && type !== 21) && <Typography variant={"h5"} className={classes.itemTitle} >{title} {isRequired ? <span className={classes.requiredField}>*</span> : ''}</Typography>}
        {(type !== 16 && type !== 17 && type !== 1 && type !== 12 && type !== 13 && type !== 14 && type !== 15 && type !== 21 && description && description.length) && <p className={classes.description}>{description}</p>}

        <div className={classes.fieldContainer} >
          {type === 1 &&
            <>
              <TextField
                id="outlined-full-width"
                required={isRequired ? true : false}
                disabled={isFieldDisabled}
                label={title}
                // style={{ margin: 8 }}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={text_value}
                onChange={(e) => {
                  if (maxLength) {
                    if (e.target.value?.length <= maxLength) {
                      this.updateTextValue(e.target.value, true);
                    }
                  } else {
                    this.updateTextValue(e.target.value, true);
                  }
                }}
              />
              {maxLength && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <p className={classes.counter}>Character Limit {maxLength}&nbsp; &nbsp;{text_value.length}/{maxLength}</p>
                </div>
              )}
            </>
          }

          {type === 2 &&
            <div>
              <DatePicker
                className={classes.datePicker}
                selected={selectedDate}
                popperClassName={classes.calendarPopout}
                disabled={isFieldDisabled}
                onChange={date => {
                  this.updateDate(date, true);
                }}
                dateFormat="MMM do yyyy"
                portalId="evMetaDialog"
                minDate={null}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={
                  <OutlinedInput
                    readOnlyInput={true}
                    startAdornment={
                      <InputAdornment>
                        <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                      </InputAdornment>
                    }
                  />
                }
              />
            </div>
          }

          {type === 3 &&
            <>
              <RadioGroup name={`option_${index}`} value={String(selectedOption)} onChange={(e) => {
                this.setSelectedOption(e.target.value, true)
              }}>
                <ul className={"answers"}>
                  {additional_data.map((item, i) => {
                    return <li key={i}><FormControlLabel value={item.value}
                      control={<Radio
                        disabled={isFieldDisabled}
                      />}
                      label={item.label} /></li>
                  })}
                </ul>
              </RadioGroup>
            </>
          }

          {(type === 4 || type === 5) &&
            <div className={classes.customTypeFields} >
              <FormControl className={classes.formSelect} style={{ zIndex: zIndexItm }}>
                <Select
                  isClearable
                  isMulti={type === 5 ? true : false}
                  labelId="singleSelect-select-label"
                  id="singleSelect-select"
                  isDisabled={isFieldDisabled}
                  value={type === 5 ? selectedOptions : selectedOption}
                  onChange={(e) => {
                    // console.log(e,'e.target.value')
                    if (type === 4) {
                      this.setSingleSelectOption(e, true);
                    } else {
                      this.setMultiSelectOptions(e, true);
                    }
                  }}
                  fullWidth
                  options={additional_data}
                  menuPortalTarget={document.body}
                  // menuPosition={'fixed'}
                  menuPlacement={'auto'}
                  placeholder={"Select a Option"}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                >
                </Select>
              </FormControl>
            </div>
          }

          {type === 6 &&
            <div className={classes.customTypeFields}>
              <TableTypeReply
                isEdit={true}
                tableDatas={text_value}
                additionalData={additional_data}
                updateData={(data) => {
                  this.props.onChange(data)
                }}
              />
            </div>
          }

          {(type === 7 || type === 8) && <div className={classes.customTypeFields}>
            <SelectAsyncPaginate
              isClearable
              isMulti={true}
              isSearchable={true}
              classNamePrefix="select"
              debounceTimeout={200}
              placeholder={type === 7 ? "Select Users" : "Select Teams"}
              onChange={(e) => {
                if (type === 7) {
                  this.setSelectedUsersOnLoad(e, true)
                } else {
                  this.setSelectedTeamsOnLoad(e, true)
                }
              }}
              formatOptionLabel={IsExistingProduct}
              value={type === 7 ? selectedUsers : selectedTeams}
              defaultValue={type === 7 ? selectedUsers : selectedTeams}
              loadOptions={type === 7 ? this.getUsersOption : this.getTeamsOptions}
              menuPortalTarget={document.body}
              menuPlacement={'auto'}
              styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
            />
          </div>}

          {userProfile && type === 7 &&
            <Grid container spacing={2} className={classes.noMarginTop}>
              {selectedUsers.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={2}
                  key={index}
                  className={classes.cardSection}
                >
                  <Card variant="outlined" style={{ height: '100%' }}>
                    <CardHeader
                      className={classes.contactCardHeader}
                      style={{ textAlign: "center" }}
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          className={classes.contactCardAvatar}
                        >
                          {item.label.slice(0, 1)}
                        </Avatar>
                      }
                      title={item.label}
                      subheader={item?.Email}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          }

          {false && type === 8 &&
            <Grid container spacing={2} className={classes.noMarginTop}>
              {selectedTeams.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={2}
                  key={index}
                  className={classes.cardSection}
                >
                  {!isFieldDisabled && <Image
                    src={deleteIcon}
                    alt="iamge"
                    className={classes.deleteIcon}
                    onClick={() => {
                      let finalSelectedTeams = this.state.selectedTeams
                      finalSelectedTeams.splice(index, 1)
                      this.setSelectedTeamsOnLoad(finalSelectedTeams, true)
                    }}
                  />}
                  <Card variant="outlined" style={{ height: '100%' }}>
                    <CardHeader
                      className={classes.contactCardHeader}
                      style={{ textAlign: "center" }}
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          className={classes.contactCardAvatar}
                        >
                          {item.label.slice(0, 1)}
                        </Avatar>
                      }
                      title={item.label}
                      subheader={item?.Email}
                    />
                  </Card>
                </Grid>
              ))}
              {!isFieldDisabled && <Grid item xs={12} sm={6} md={3} lg={2}>
                <Card
                  className={classes.contactCardRoot}
                  variant="outlined"
                  onClick={() => {
                    this.props.showTeamAddDialog(item)
                    // this.setState({ isOpenDialog: true, dialogTitle: 'Add Teams', isUser: false })
                  }
                  }
                >
                  <CardContent>
                    <span className={classes.addNewChamp}>
                      <Image
                        src={AddEvaluationImage}
                        style={{ maxWidth: "58px", cursor: "pointer" }}
                      />
                      <span className={classes.addContact}>
                        Add Teams
                      </span>
                    </span>
                  </CardContent>
                </Card>
              </Grid>}
            </Grid>
          }

          {type === 9 &&
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "16px",
                }}
              >
                {this.state.isShowLoader ? <CircularProgress style={{ height: 30, width: 30, marginBottom: 10 }} /> : <>
                  {selectedFiles && selectedFiles.map((file, key) => (
                    <div>
                      <FileAncher
                        fileName={fileNames[key]}
                        fileURL={file.url}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Image
                        src={deleteIcon}
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.handleDeleteFile(key)}
                      />
                    </div>
                  ))}</>}
                {!isFieldDisabled && <input
                  ref={this.fileRef}
                  id="uploadRequirementResponse"
                  onChange={this.fileSelectHandler}
                  type="file"
                  multiple
                />}
              </div>
            </>
          }

          {type === 10 && (
            <>
              <Grid container spacing={2} className={classes.noMarginTop}>
                {selectedProducts !== undefined && selectedProducts !== null && selectedProducts.length > 0 && selectedProducts.map((product, k) => {
                  return (
                    <Grid
                      item
                      key={"similar_" + product.ID}
                      className={classes.productList}
                      xs={12}
                      sm={4}
                      md={3}
                      lg={2}
                    >
                      <div style={{ position: 'relative' }}>
                        {isFormEditable && <Image
                          src={deleteIcon}
                          className={classes.deleteImage}
                          onClick={() => {
                            this.handleRemoveProduct(k)
                          }}
                        />}
                        <LayeredCard
                          title={product.Name}
                          subTitle={product.CompanyName}
                          description={product.Description}
                          customRoute={""}
                          image={product.ProductLogo}
                          onClick={() => { }}
                          numLayer={0}
                          checked={false}
                          showCheckbox={false}
                          id={product.ID}
                          uniqueId={product.ID + "_q_" + k}
                          type={"product"}
                          IsExisting={product.IsExisting}
                          fireEvent
                          eventPage={'orgProductDetails'}
                          eventData={{
                            type: 'event.click.org-product.section',
                            data: {
                              url: this.props.location.pathname + this.props?.location?.search,
                              section: {
                                id: 'customtab',
                                name: 'customtab'
                              },
                              product: {
                                id: product.ID,
                                name: product.Name,
                                position: k + 1
                              }
                            }
                          }}
                        />
                      </div>
                    </Grid>
                  );
                })}
                {!isFieldDisabled && <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                  onClick={() => {
                    this.setState({ isEditProducts: true });
                  }}
                >
                  <LayeredCard
                    title={"+Add Vendor"}
                    onClick={() => { }}
                    numLayer={0}
                    image={AddEvaluationImage}
                    showCheckbox={false}
                  />
                </Grid>}
              </Grid>
              {isEditProducts && (
                <Grid container alignItems="center" style={{ marginTop: 20 }}>
                  <Grid item xs={6} sm={5} md={4}>
                    <CreatableAsyncPaginate
                      isClearable
                      isOpen={true}
                      formatCreateLabel={(userInput) => `Create '${userInput}'`}
                      noOptionsMessage={() => "Start typing to select or create vendor"}
                      isSearchable={true}
                      createOptionPosition={"first"}
                      classNamePrefix="select"
                      placeholder="Name of the vendor"
                      onChange={(data) => {
                        this.setState({
                          selectedOption: data
                        })
                      }}
                      additional={{
                        page: 0,
                      }}
                      formatOptionLabel={IsExistingProduct}
                      isValidNewOption={(input) => input.length > 0}
                      value={selectedOption}
                      defaultValue={selectedOption}
                      loadOptions={this.getOptions}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </Grid>
                  &nbsp;
                  <ActionButtom
                    classes={classes}
                    onCancel={() => this.setState({ isEditProducts: false })}
                    onSave={() => {
                      if (!this.state.selectedOption) {
                        this.props.showSnackBar('Please select product', 'error', 3000);
                        return;
                      }
                      this.setState({ isEditProducts: false })
                      this.setSelectedProducts(this.state.selectedOption, true)
                    }}
                  />
                </Grid>
              )}
            </>
          )}

          {type === 11 &&
            <>
              <SlateInputField
                style={{ width: '100%' }}
                as={SlateInputField}
                autoFocus={false}
                onChangeEvent={(value) => {
                  this.updateTextValue(value, true);
                }}
                placeholder={"Type your message here."}
                initValue={text_value}
                formControlStyle={{ overflow: 'overlay' }}
                disabled={isFieldDisabled}
                textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', margin: "0px", color: "#707070", fontSize: 16 }}
              />
            </>
          }

          {type === 12 &&
            <>
              <TextField
                id="outlined-full-width"
                required={isRequired ? true : false}
                disabled={isFieldDisabled}
                label={title}
                // style={{ margin: 8 }}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={text_value}
                onChange={(e) => {
                  this.validateEmail(e.target.value);
                  this.updateTextValue(e.target.value, true);
                }}
                error={this.state.isValidationError}
              />
            </>
          }

          {type === 13 &&
            <>
              <div className={classes.customTypeFields}>

                <PhoneInput
                  country={'us'}
                  value={text_value}
                  onChange={(value) => {
                    this.updateTextValue(value, true);
                  }}
                  required
                  disabled={isFieldDisabled}
                  inputProps={{
                    name: text_value,
                    autoFocus: false,
                    id: "outlined-full-width",
                    required: isRequired ? true : false,
                    label: title,
                    style: { width: '100%' },
                    fullWidth: true,
                    margin: "normal",
                    variant: "outlined",
                    InputLabelProps: {
                      shrink: true
                    }
                  }}
                />
              </div>
            </>
          }

          {type === 14 &&
            <>
              <div className={classes.customTypeFields}>
                <TextField
                  id="outlined-full-width"
                  required={isRequired ? true : false}
                  disabled={isFieldDisabled}
                  label={title}
                  // style={{ margin: 8 }}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={text_value}
                  onChange={(e) => {
                    this.validateURL(e.target.value);
                    this.updateTextValue(e.target.value, true);
                  }}
                  error={this.state.isValidationError}
                />
              </div>
            </>
          }

          {type === 15 &&
            <>
              <TextField
                id="outlined-full-width"
                className={classes.textfieldClass}
                required={isRequired ? true : false}
                disabled={isFieldDisabled}
                label={title}
                type={"number"}
                // style={{ margin: 8 }}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={text_value}
                onChange={(e) => {
                  this.updateTextValue(e.target.value, true);
                }}
                onWheel={(e) => {
                  e.target.blur()
                }}
              />
            </>
          }

          {type === 16 &&
            <div className={classes.currencyTypeFields} style={{ display: 'flex' }}>
              <div style={{ width: 100, marginRight: 5, zIndex: zIndexItm }}>
                <Select
                  isMulti={false}
                  labelId="singleSelect-select-label"
                  id="singleSelect-select"
                  value={currencyType}
                  disabled={isFieldDisabled}
                  onChange={(e) => {
                    this.setState({
                      currencyType: e
                    })
                  }}
                  options={currencyOptions}
                  placeholder={"Select a Option"}
                  styles={currencyStyles}
                >
                </Select>
              </div>

              <CurrencyTextField
                label={title}
                variant="outlined"
                value={text_value}
                disabled={isFieldDisabled}
                currencySymbol={currencyCodeMap[currencyType.value]}
                //minimumValue="0"
                style={{ textAlign: 'left' }}
                outputFormat="string"
                decimalCharacter="."
                digitGroupSeparator=","
                required={isRequired ? true : false}
                onChange={(e, v) => {
                  this.updateCurrencyValue(v, true);
                }}
              />
            </div>
          }

          {type === 17 &&
            <div className={classnames(classes.customTypeFields)}>
              {this.props.similarExistingProductProgress === false && this.props.evaluation && this.props.evaluation?.Products.map((product, k) => {
                if (similarProducts === undefined && similarProducts === null && similarProducts === '' && similarProducts[product.ID] === undefined && similarProducts[product.ID] === null) {
                  return null
                }
                var simProducts = similarProducts[product.ID] || [];
                return <div className={classes.product_list}>
                  <Typography className={classes.label}>Similar product{simProducts.length > 1 ? "s" : ""} for {product.Name}</Typography>
                  <Grid container spacing={3} className={classes.noMarginTop}>
                    {simProducts.map((product, index) => (
                      <Grid item lg={2} md={3} key={index}>
                        <div onClick={(e) => {
                          this.producDetail(e, product)
                        }}>
                          <LayeredCard
                            title={product.Name}
                            subTitle={product.CompanyName}
                            description={product.Description}
                            image={
                              product.ProductLogo === null ||
                                product.ProductLogo.length === 0
                                ? DefaultImage
                                : product.ProductLogo
                            }
                            toogleCheck={(e) => {
                              this.reviewProduct(e, product.ID);
                            }}
                            onClick={() => {

                            }}
                            numLayer={0}
                            //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                            // showCheckbox={true}
                            id={product.ID}
                            uniqueId={product.ID + "_c_" + index}
                            //  type={'product'}
                            IsExisting={product.IsExisting}
                          />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              })}
            </div>
          }

          {type === 18 &&
            <div className={classes.customTypeFields}>
              <div className={classes.ratingRoot}>
                <Grid container className={classes.rating}>
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10} className={classes.slider}>
                    {<Slider
                      // defaultValue={text_value}
                      value={text_value}
                      valueLabelDisplay="auto"
                      disabled={isFieldDisabled}
                      step={0.50}
                      marks={false}
                      min={1}
                      max={10}
                      onChange={(event, value) => {
                        this.updateTextValue(value, true);
                      }}
                      classes={{
                        thumb: classes.thumb,
                        track: classes.track,
                        rail: classes.rail
                      }}
                    />}
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={classes.sliderCount}>
                    <Typography className={classes.resListItem}>{text_value === '' ? 0 : text_value}</Typography>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className={classes.counter1}>
                    <Typography className={classes.counterItem}>1</Typography>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className={classes.counter2}>
                    <Typography className={classes.counterItem}>10</Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          }

          {(type === 19) &&
            <>
              <ul className={"answers"}>
                {additional_data.map((item, i) => {
                  return <>
                    {(item.isOtherOption === undefined || !item.isOtherOption) && <li key={i}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          boxSizing: "border-box",
                          placeContent: "center flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          value={String(item.value)}
                          checked={selectedOption === String(item.value) ? true : false}
                          disabled={isFieldDisabled}
                          inputProps={{
                            name: "RadioData",
                            "data-sequence": item.Sequence,
                          }}
                          onChange={(e) => {
                            this.setSelectedOption((e.target.value), true);
                          }}
                        />
                        <label htmlFor={item.value}>{item.label}</label>
                      </div>
                    </li>}
                    {item.isOtherOption && <li key={'other_option_single'}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          boxSizing: "border-box",
                          placeContent: "center flex-start",
                          alignItems: "center",
                        }}
                      >
                        {type === 19 && <Radio
                          value={'99999999'}
                          // value={String(item.id)}
                          // checked={selectedOption === String(item.id) ? true : false}
                          checked={otherValue}
                          disabled={isFieldDisabled}
                          inputProps={{
                            name: "OtherData",
                          }}
                          onChange={(e) => {
                            // this.setSelectedOption((e.target.value), true);
                            this.setOtherOption('99999999', true);
                          }}
                        />}

                        {type === 20 && <Checkbox
                          id="other"
                          checked={otherValue}
                          name="other"
                          color="primary"
                          disabled={isFieldDisabled}
                          onChange={(e) => {
                            if (otherValue.length <= 0) {
                              this.setOtherOption('99999999', false);
                            } else {
                              this.setOtherOption('', true);
                            }
                          }}
                        />}

                        <TextField
                          style={{
                            width: "85%",
                            marginLeft: 10,
                            color: "#04363D",
                            fontSize: 13,
                          }}
                          value={text_value}
                          disabled={isFieldDisabled}
                          placeholder={'Other'}
                          onChange={(e) => {
                            this.updateOtherValue(e.target.value, true);
                          }}
                        />

                      </div>
                    </li>}
                  </>
                })}
              </ul>
            </>
          }

          {type === 20 &&
            <>
              <ul className={"answers"}>
                {additional_data.map((item, i) => {
                  return <>
                    <li key={i}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          boxSizing: "border-box",
                          placeContent: "center flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          id={item.value}
                          checked={selectedOptions.indexOf(item.value) !== -1}
                          name={item.value}
                          disabled={isFieldDisabled}
                          color="primary"
                          onChange={(e) => {
                            let options = [];
                            let itemId = item.value;
                            if (selectedOptions.indexOf(itemId) !== -1) {
                              selectedOptions.splice(
                                selectedOptions.indexOf(itemId),
                                1
                              );
                            } else {
                              selectedOptions.push(itemId);
                            }
                            options = _.uniq(selectedOptions);
                            this.setSelectedOptions(options, true);
                          }}
                        />
                        <label htmlFor={item.value}>{item.label}</label>
                      </div>
                    </li>
                  </>
                })}
              </ul>
            </>
          }

          {type === 20 && item.IsOtherOption !== undefined && item.IsOtherOption && <ul className={"answers"}>
            <li key={'other_option_single'}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  boxSizing: "border-box",
                  placeContent: "center flex-start",
                  alignItems: "center",
                }}
              >

                <Checkbox
                  id="other"
                  checked={otherValue}
                  name="other"
                  color="primary"
                  onChange={(e) => {
                    if (otherValue.length <= 0) {
                      this.setOtherOptionCheckbox('99999999', false);
                    } else {
                      this.setOtherOptionCheckbox('', true);
                    }
                  }}
                />

                <TextField
                  style={{
                    width: "85%",
                    marginLeft: 10,
                    color: "#04363D",
                    fontSize: 13,
                  }}
                  value={text_value}
                  placeholder={'Other'}
                  onChange={(e) => {
                    this.updateOtherValue(e.target.value, true);
                  }}
                />

              </div>
            </li></ul>
          }

          {type === 21 &&
            <>
              <SqureButton
                style={{ "marginLeft": "10px" }}
                variant={"contained"}
                disabled={false}
                onClick={() => { this.webHookRun() }}>
                {title}
              </SqureButton>
            </>
          }

          {type === 22 &&
            <>
              <SlateInputFieldV2
                readOnly={true}
                isToolBar={false}
                placeholder=""
                style={{
                  margin: 0,
                }}
                as={SlateInputField}
                initValue={additional_data?.message}
                formControlStyle={{ border: 'none', overflow: 'hidden' }}
                textContainerStyle={{ minHeight: '100px !important', margin: "0px", background: "#f7f7f7", padding: 10, color: "#707070", fontSize: 16 }} />
            </>
          }

        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CustomReply));


const ActionButtom = ({ classes, onSave, onCancel, isDisabled = false }) => (
  <div>
    <Button
      className={classes.tickMark}
      onClick={() => isDisabled ? {} : onSave()}
    >
      <DoneIcon />
    </Button>
    <Button
      className={classes.tickMark}
      onClick={onCancel}
    >
      <CloseIcon />
    </Button>
  </div>
)