import {
    START_ORG_CATEGORIES_FETCH, END_ORG_CATEGORIES_FETCH,
    START_ORG_CATEGORY_FETCH, END_ORG_CATEGORY_FETCH,
    SHOW_ORG_CATEGORY_ADD_DIALOG, HIDE_ORG_CATEGORY_ADD_DIALOG,
    START_ADD_NEW_ORG_CATEGORY, END_ADD_NEW_ORG_CATEGORY,
    START_ORG_CATEGORY_UPDATE, END_ORG_CATEGORY_UPDATE,
    START_ORG_CATEGORY_DELETE, END_ORG_CATEGORY_DELETE,
    START_ADD_ORG_CATEGORY_TAB, END_ADD_ORG_CATEGORY_TAB,
    START_DELETE_ORG_CATEGORY_TAB, END_DELETE_ORG_CATEGORY_TAB,
    START_FETCH_ORG_CATEGORY_TABS, END_FETCH_ORG_CATEGORY_TABS,
    START_UPDATE_ORG_CATEGORY_TAB, END_UPDATE_ORG_CATEGORY_TAB,
    START_ADD_ORG_CATEGORY_TAB_SECTION, END_ADD_ORG_CATEGORY_TAB_SECTION,
    START_DELETE_ORG_CATEGORY_TAB_SECTION, END_DELETE_ORG_CATEGORY_TAB_SECTION,
    START_UPDATE_ORG_CATEGORY_TAB_SECTION, END_UPDATE_ORG_CATEGORY_TAB_SECTION,
    START_ADD_ORG_CATEGORY_TAB_SECTION_ITEM, END_ADD_ORG_CATEGORY_TAB_SECTION_ITEM,
    START_UPDATE_ORG_CATEGORY_TAB_SECTION_ITEM, END_UPDATE_ORG_CATEGORY_TAB_SECTION_ITEM,
    START_DELETE_ORG_CATEGORY_TAB_SECTION_ITEM, END_DELETE_ORG_CATEGORY_TAB_SECTION_ITEM,

    START_UPDATE_ORG_CATEGORY_TAB_SECTION_SEQ, END_UPDATE_ORG_CATEGORY_TAB_SECTION_SEQ,
    START_UPDATE_ORG_CATEGORY_TAB_SEQ, END_UPDATE_ORG_CATEGORY_TAB_SEQ
} from "./action";

const initState = {
    fetchCategoriesProgress: false,
    fetchCategoriesError: null,
    categories: [],
    totalCategories: 0,

    fetchCategoryProgress: false,
    fetchCategoryError: null,
    categoryData: {},

    showAddCategoryDialog: false,

    addCategoryProgress: false,
    addCategoryError: null,
    updateCategoryProgress: false,
    updateCategoryError: null,
    deleteCategoryProgress: false,
    deleteCategoryError: null,

    orgCategoryTabs: [],
    addTabProgress: false,
    addTabError: null,

    deleteTabProgress: false,
    deleteTabError: null,

    fetchTabProgress: false,
    fetchTabError: null,

    updateTabProgress: false,
    updateTabError: null,

    newlyAddedSectionData:null,
    addOrgCategoryTabSectionProgress: false,
    addOrgCategoryTabSectionError: null,
    updateOrgCategoryTabSectionProgress: false,
    updateOrgCategoryTabSectionError: null,
    deleteOrgCategoryTabSectionProgress: false,
    deleteOrgCategoryTabSectionError: null,
    addOrgCategoryTabSectionItemProgress: false,
    addOrgCategoryTabSectionItemError: null,
    updateOrgCategoryTabSectionItemProgress: false,
    updateOrgCategoryTabSectionItemError: null,
    deleteOrgCategoryTabSectionItemProgress: false,
    deleteOrgCategoryTabSectionItemError: null,

    updateOrgCategoryTabSectionSequenceProg: false,
    updateOrgCategoryTabSequenceProg: false,
}

export function orgCategoriesReducers(state = initState, action) {
    switch (action.type) {
        case START_UPDATE_ORG_CATEGORY_TAB_SEQ:
            return {
                ...state,
                updateOrgCategoryTabSequenceProg: true
            }

        case END_UPDATE_ORG_CATEGORY_TAB_SEQ:
            return {
                ...state,
                updateOrgCategoryTabSequenceProg: false
            }
        case START_UPDATE_ORG_CATEGORY_TAB_SECTION_SEQ:
            return {
                ...state,
                updateOrgCategoryTabSectionSequenceProg: true
            }

        case END_UPDATE_ORG_CATEGORY_TAB_SECTION_SEQ:
            return {
                ...state,
                updateOrgCategoryTabSectionSequenceProg: false
            }
        case SHOW_ORG_CATEGORY_ADD_DIALOG:
            return {
                ...state,
                showAddCategoryDialog: true
            }

        case HIDE_ORG_CATEGORY_ADD_DIALOG:
            return {
                ...state,
                showAddCategoryDialog: false
            }

        case START_ORG_CATEGORIES_FETCH:
            return {
                ...state,
                fetchCategoriesProgress: true,
                fetchCategoriesError: null,
            };

        case END_ORG_CATEGORIES_FETCH: {
            var success = action.payload.success;
            var allCategories = state.categories;

            if (success !== null) {
                var Page = success.page;
                var Data = success.Data;
                if (Page === 0) {
                    allCategories = Data;
                } else {
                    allCategories = allCategories.concat(Data)
                }
            }

            return {
                ...state,
                fetchCategoriesProgress: false,
                fetchCategoriesError: action.payload.error,
                categories: allCategories,
                totalCategories: allCategories.length
            }
        }

        case START_ORG_CATEGORY_FETCH:
            return {
                ...state,
                fetchCategoryProgress: true,
                fetchCategoryError: null,
                categoryData: {}
            };

        case END_ORG_CATEGORY_FETCH: {
            return {
                ...state,
                fetchCategoryProgress: false,
                fetchCategoryError: action.payload.error,
                categoryData: action.payload.success,
            }
        }

        case START_ADD_NEW_ORG_CATEGORY:
            return {
                ...state,
                addCategoryProgress: true,
                addCategoryError: null
            }


        case END_ADD_NEW_ORG_CATEGORY:
            let oCategories = state.categories;
            if (action.payload.success !== null) {
                oCategories.push(action.payload.success)
            }
            return {
                ...state,
                addCategoryProgress: false,
                addCategoryError: action.payload.error,
                categories: oCategories
            }

        case START_ORG_CATEGORY_UPDATE:
            return {
                ...state,
                updateCategoryProgress: true,
                updateCategoryError: null
            }


        case END_ORG_CATEGORY_UPDATE:
            return {
                ...state,
                updateCategoryProgress: false,
                updateCategoryError: action.payload.error,
            }

        case START_ORG_CATEGORY_DELETE:
            return {
                ...state,
                deleteCategoryProgress: true,
                deleteCategoryError: null
            }


        case END_ORG_CATEGORY_DELETE:
            return {
                ...state,
                deleteCategoryProgress: false,
                deleteCategoryError: action.payload.error,
            }

        case START_ADD_ORG_CATEGORY_TAB:
            return {
                ...state,
                addTabProgress: true,
                addTabError: null
            }
        case END_ADD_ORG_CATEGORY_TAB:
            let addSuccess = action.payload.success;
            let orgCategoryTabs = state.orgCategoryTabs;
            if (addSuccess !== null) {
                orgCategoryTabs.push(addSuccess);
            }
            return {
                ...state,
                orgCategoryTabs,
                addTabProgress: false,
                addTabError: action.payload.error
            };

        case START_DELETE_ORG_CATEGORY_TAB:
            return {
                ...state,
                deleteTabProgress: true,
                deleteTabError: null
            }

        case END_DELETE_ORG_CATEGORY_TAB:
            let delSuccess = action.payload.success;
            let tabID = action.payload.tabId;
            let orgCategoryTabsD = state.orgCategoryTabs;
            if (delSuccess !== null) {
                const tabIndex = orgCategoryTabsD.findIndex(
                    (obj) => obj.ID === tabID
                );
                orgCategoryTabsD.splice(tabIndex, 1);
            }

            return {
                ...state,
                orgCategoryTabs: orgCategoryTabsD,
                deleteTabProgress: false,
                deleteTabError: action.payload.error
            }

        case START_FETCH_ORG_CATEGORY_TABS:
            return {
                ...state,
                fetchTabProgress: true,
                fetchTabError: null
            }
        case END_FETCH_ORG_CATEGORY_TABS:
            return {
                ...state,
                fetchTabProgress: false,
                fetchTabError: action.payload.error,
                orgCategoryTabs: action.payload.success
            }

        case START_UPDATE_ORG_CATEGORY_TAB:
            return {
                ...state,
                updateTabProgress: true,
                updateTabError: null,
            }

        case END_UPDATE_ORG_CATEGORY_TAB:
            let upSuccess = action.payload.success;
            let tabIDU = action.payload.tabId;
            let orgCategoryTabsU = state.orgCategoryTabs;
            if (upSuccess !== null) {
                const tabIndex = orgCategoryTabsU.findIndex(
                    (obj) => obj.ID === tabIDU
                );
                orgCategoryTabsU[tabIndex] = upSuccess || orgCategoryTabsU;
            }
            return {
                ...state,
                orgCategoryTabs: orgCategoryTabsU,
                updateTabProgress: false,
                updateTabError: action.payload.error,
            }

        case START_ADD_ORG_CATEGORY_TAB_SECTION:
            return {
                ...state,
                addOrgCategoryTabSectionProgress: true,
                addOrgCategoryTabSectionError: null,
                newlyAddedSectionData:null,
            }

        case END_ADD_ORG_CATEGORY_TAB_SECTION:
            let upTabSuccess = action.payload.success;
            let tabID_U = action.payload.tabId;
            let orgCategoryTabsUS = state.orgCategoryTabs;
            if (upTabSuccess !== null && tabID_U !== null) {
              const tabIndex = orgCategoryTabsUS.findIndex(
                (obj) => obj.ID === tabID_U
              );
              if (
                Array.isArray(orgCategoryTabsUS[tabIndex].Sections)
              ) {
                orgCategoryTabsUS[tabIndex].Sections.push(upTabSuccess);
              } else {
                orgCategoryTabsUS[tabIndex].Sections = [upTabSuccess];
              }
            }
            return {
                ...state,
                newlyAddedSectionData : action.payload.success,
                orgCategoryTabs:orgCategoryTabsUS,
                addOrgCategoryTabSectionProgress: false,
                addOrgCategoryTabSectionError: action.payload.error,
            }

        case START_UPDATE_ORG_CATEGORY_TAB_SECTION:
            return {
                ...state,
                updateOrgCategoryTabSectionProgress: true,
                updateOrgCategoryTabSectionError: null,
            }
        case END_UPDATE_ORG_CATEGORY_TAB_SECTION:
            return {
                ...state,
                updateOrgCategoryTabSectionProgress: false,
                updateOrgCategoryTabSectionError: action.payload.error,
            }

        case START_DELETE_ORG_CATEGORY_TAB_SECTION:
            return {
                ...state,
                deleteOrgCategoryTabSectionProgress: true,
                deleteOrgCategoryTabSectionError: null,
            }
        case END_DELETE_ORG_CATEGORY_TAB_SECTION:
            return {
                ...state,
                deleteOrgCategoryTabSectionProgress: false,
                deleteOrgCategoryTabSectionError: action.payload.error,
            }

        case START_ADD_ORG_CATEGORY_TAB_SECTION_ITEM:
            return {
                ...state,
                addOrgCategoryTabSectionItemProgress: true,
                addOrgCategoryTabSectionItemError: null,
            }

        case END_ADD_ORG_CATEGORY_TAB_SECTION_ITEM:
            let upTabSecSuccess = action.payload.success;
            let tabID_USI = action.payload.tabId;
            let sectionId_USI = action.payload.sectionId;
            let orgCategoryTabsUSI = state.orgCategoryTabs;

            if (upTabSecSuccess !== null && tabID_USI !== null) {
              const tabIndex = orgCategoryTabsUSI.findIndex(
                (obj) => obj.ID === tabID_USI
              );
              const OrgProductTabSections =
                orgCategoryTabsUSI[tabIndex].Sections;
              if (OrgProductTabSections) {
                const sectionIndex = OrgProductTabSections.findIndex(
                  (obj) => obj.ID === sectionId_USI
                );
                if (
                  Array.isArray(
                    OrgProductTabSections[sectionIndex].SubSections
                  )
                ) {
                  OrgProductTabSections[sectionIndex].SubSections.push(
                    upTabSecSuccess
                  );
                } else {
                  OrgProductTabSections[sectionIndex].SubSections = [upTabSecSuccess];
                }
                orgCategoryTabsUSI[tabIndex].Sections =
                  OrgProductTabSections;
              }
            }
            return {
                ...state,
                orgCategoryTabs:orgCategoryTabsUSI,
                addOrgCategoryTabSectionItemProgress: false,
                addOrgCategoryTabSectionItemError: action.payload.error,
            }

        case START_UPDATE_ORG_CATEGORY_TAB_SECTION_ITEM:
            return {
                ...state,
                updateOrgCategoryTabSectionItemProgress: true,
                updateOrgCategoryTabSectionItemError: null,
            }

        case END_UPDATE_ORG_CATEGORY_TAB_SECTION_ITEM:
            return {
                ...state,
                updateOrgCategoryTabSectionItemProgress: false,
                updateOrgCategoryTabSectionItemError: action.payload.error,
            }

        case START_DELETE_ORG_CATEGORY_TAB_SECTION_ITEM:
            return {
                ...state,
                deleteOrgCategoryTabSectionItemProgress: true,
                deleteOrgCategoryTabSectionItemError: null,
            }

        case END_DELETE_ORG_CATEGORY_TAB_SECTION_ITEM:
            return {
                ...state,
                deleteOrgCategoryTabSectionItemProgress: false,
                deleteOrgCategoryTabSectionItemError: action.payload.error,
            }


        default:
            return state;
    }
}