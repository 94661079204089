import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
 
const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    errorMsg:{
        textAlign : 'center',
        marginTop : 50
    }
});

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
   
  }
  
  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.errorMsg}>
          <Typography variant={"h6"} className={classes.title}> Internal Server Error </Typography>
          </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ErrorPage));
