/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
 import {HttpFactory} from "services/httpService";
 import * as Environment from "../../util/Environment";
import {ApplicationFactory} from "../../services/application";

export const RESET_STATE         =   "RESET_STATE";

export const STORE_SEARCH_STRING = "STORE_SEARCH_STRING";

export const UPDATE_CRITERIA_DYNAMIC_HEIGHT = "UPDATE_CRITERIA_DYNAMIC_HEIGHT";

export const START_APPLICATION_FETCH     =   "START_APPLICATION_FETCH";
export const END_APPLICATION_FETCH       =   "END_APPLICATION_FETCH";

export const SHOW_APPLICATION_FETCHINPUT_DIALOG         =   "SHOW_APPLICATION_FETCHINPUT_DIALOG";
export const HIDE_APPLICATION_FETCHINPUT_DIALOG         =   "HIDE_APPLICATION_FETCHINPUT_DIALOG";

export const START_APPLICATION_FETCHINPUT     =   "START_APPLICATIONFETCHINPUT";
export const END_APPLICATION_FETCHINPUT       =   "END_APPLICATIONFETCHINPUT";

export const START_APPLICATION_UPDATEINPUT     =   "START_APPLICATION_UPDATEINPUT";
export const END_APPLICATION_UPDATEINPUT       =   "END_APPLICATION_UPDATEINPUT";

export const START_APPLICATION_FETCHCONFIG    =   "START_APPLICATION_FETCHCONFIG";
export const END_APPLICATION_FETCHCONFIG       =   "END_APPLICATION_FETCHCONFIG";

export const START_APPLICATION_FETCHCONFIG_DETAILS    =   "START_APPLICATION_FETCHCONFIG_DETAILS";
export const END_APPLICATION_FETCHCONFIG_DETAILS       =   "END_APPLICATION_FETCHCONFIG_DETAILS";

export const START_APPLICATIONS_FETCH    =   "START_APPLICATIONS_FETCH";
export const END_APPLICATIONS_FETCH       =   "END_APPLICATIONS_FETCH";

export const START_GROUPS_FETCH    =   "START_GROUPS_FETCH";
export const END_GROUPS_FETCH    =   "END_GROUPS_FETCH";

export const START_APPLICATION_FETCH_SYNC_STATUS    =   "START_APPLICATION_FETCH_SYNC_STATUS";
export const END_APPLICATION_FETCH_SYNC_STATUS       =   "END_APPLICATION_FETCH_SYNC_STATUS";

export const START_SYNC_APPLICATION    =   "START_SYNC_APPLICATION";
export const END_SYNC_APPLICATION       =   "END_SYNC_APPLICATION";

export const START_REMOVE_APPLICATION    =   "START_REMOVE_APPLICATION";
export const END_REMOVE_APPLICATION       =   "END_REMOVE_APPLICATION";

export const START_SYNC_HISTORY_FETCH    =   "START_SYNC_HISTORY_FETCH";
export const END_SYNC_HISTORY_FETCH       =   "END_SYNC_HISTORY_FETCH";

export const SHOW_APPLICATION_GROUP_DIALOG   =   "SHOW_APPLICATION_GROUP_DIALOG";
export const HIDE_APPLICATION_GROUP_DIALOG       =   "HIDE_APPLICATION_GROUP_DIALOG";

export const SHOW_APPLICATION_USER_DIALOG   =   "SHOW_APPLICATION_USER_DIALOG";
export const HIDE_APPLICATION_USER_DIALOG       =   "HIDE_APPLICATION_USER_DIALOG";

export const SHOW_GROUP_USER_DIALOG   =   "SHOW_GROUP_USER_DIALOG";
export const HIDE_GROUP_USER_DIALOG       =   "HIDE_GROUP_USER_DIALOG";

export const SHOW_SYNC_HISTORY_DIALOG   =   "SHOW_SYNC_HISTORY_DIALOG";
export const HIDE_SYNC_HISTORY_DIALOG       =   "HIDE_SYNC_HISTORY_DIALOG";

export const SHOW_MESSAGE_DIALOG   =   "SHOW_MESSAGE_DIALOG";
export const HIDE_MESSAGE_DIALOG       =   "HIDE_MESSAGE_DIALOG";

export const TOGGLE_EVALUATE_VIEW = 'TOGGLE_EVALUATE_VIEW';

export const TOGGLE_EVALUATE_NEVER_REVIEW = 'TOGGLE_EVALUATE_NEVER_REVIEW';

export const SHOW_ADD_PRODUCT_SELLER_EMAIL_DIALOG         =   "SHOW_ADD_PRODUCT_SELLER_EMAIL_DIALOG";
export const HIDE_ADD_PRODUCT_SELLER_EMAIL_DIALOG         =   "HIDE_ADD_PRODUCT_SELLER_EMAIL_DIALOG";

export const SHOW_RES_MESSAGE_DIALOG = "SHOW_RES_MESSAGE_DIALOG";
export const HIDE_RES_MESSAGE_DIALOG = "HIDE_RES_MESSAGE_DIALOG";

export const SHOW_CONFIRM_MESSAGE_DIALOG = "SHOW_CONFIRM_MESSAGE_DIALOG";
export const HIDE_CONFIRM_MESSAGE_DIALOG = "HIDE_CONFIRM_MESSAGE_DIALOG";

export const SHOW_APPLICATION_EDIT_PREFERENCE_SYNC_DIALOG = "SHOW_APPLICATION_EDIT_PREFERENCE_SYNC_DIALOG";
export const HIDE_APPLICATION_EDIT_PREFERENCE_SYNC_DIALOG = "HIDE_APPLICATION_EDIT_PREFERENCE_SYNC_DIALOG";

export const START_SYNC_PREFERENCE_UPDATE = "START_SYNC_PREFERENCE_UPDATE";
export const END_SYNC_PREFERENCE_UPDATE = "END_SYNC_PREFERENCE_UPDATE";


export function clearStoreState(states){
    return     {
        type:RESET_STATE,
        payload:{
            states
        }
    };
}

//Action to start the registration
function startApplicationFetch(){
    return {
        type:START_APPLICATION_FETCH,
        payload:null
    };
}

function endApplicationFetch(success,error){
    return {
        type:END_APPLICATION_FETCH,
        payload:{
            success,error
        }
    };
}


function startSyncHistoryFetch(){
    return {
        type:START_SYNC_HISTORY_FETCH,
        payload: {}
    };
}

function endSyncHistoryFetch(success,error){
    return {
        type:END_SYNC_HISTORY_FETCH,
        payload:{
            success, error
        }
    };
}


export function fetchSyncHistory(page,pageSize){
    const api_server = Environment.api_host("USER");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/integration-data-timeline?page=${page}&pageSize=${pageSize}&t=${timestamp}`;
    
    return async (dispatch) => {
        dispatch(startSyncHistoryFetch());
       
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endSyncHistoryFetch(response,error));
        } catch(e){
            dispatch(endSyncHistoryFetch(null,{
                message:e.message
            }));
            return;
        }
    };
}  


//Thunk to perform the register
export  function fetch(page,pageSize,query){
    return async (dispatch) => {
        dispatch(startApplicationFetch());
        try{
            let [response,error]    =   await ApplicationFactory.instance().fetch(page,pageSize,query);
            dispatch(endApplicationFetch(response,error));
        } catch(e){
            dispatch(endApplicationFetch(null,{
                message:e.message
            }));
            return;
        }
    };
}   

//Action to start the registration
function startApplicationFetchInput(){
    return {
        type:START_APPLICATION_FETCHINPUT,
        payload:null
    };
}

function endApplicationFetchInput(success,error){
    return {
        type:END_APPLICATION_FETCHINPUT,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the register
export  function fetchFormInput(appUrl){
    return async (dispatch) => {
        dispatch(startApplicationFetchInput());
        try{
            let [response,error]    =   await ApplicationFactory.instance().fetchFormInput(appUrl);
            dispatch(endApplicationFetchInput(response,error));
        } catch(e){
            dispatch(endApplicationFetchInput(null,{
                message:e.message
            }));
            return;
        }
    };
}   

export function showApplicationGroupDialog(){
    return {
        type:SHOW_APPLICATION_GROUP_DIALOG,
        payload:{}
    };
}

export function hideApplicationGroupDialog(){
    return {
        type:HIDE_APPLICATION_GROUP_DIALOG,
        payload:{
        }
    };
}

export function showApplicationUserDialog(){
    return {
        type:SHOW_APPLICATION_USER_DIALOG,
        payload:{}
    };
}

export function hideApplicationUserDialog(){
    return {
        type:HIDE_APPLICATION_USER_DIALOG,
        payload:{
        }
    };
}

export function showGroupUserDialog(){
    return {
        type:SHOW_GROUP_USER_DIALOG,
        payload:{}
    };
}

export function hideGroupUserDialog(){
    return {
        type:HIDE_GROUP_USER_DIALOG,
        payload:{
        }
    };
}

export function showSyncHistoryDialog(){
    return {
        type:SHOW_SYNC_HISTORY_DIALOG,
        payload:{}
    };
}

export function hideSyncHistoryDialog(){
    return {
        type:HIDE_SYNC_HISTORY_DIALOG,
        payload:{
        }
    };
}

export function showApplicationFetchInput(app){
    return {
        type:SHOW_APPLICATION_FETCHINPUT_DIALOG,
        payload:{app}
    };
}

export function hideApplicationFetchInput(){
    return {
        type:HIDE_APPLICATION_FETCHINPUT_DIALOG,
        payload:{
        }
    };
}

export function showEditSyncPreferenceDialog(app){
    return {
        type:SHOW_APPLICATION_EDIT_PREFERENCE_SYNC_DIALOG,
        payload:{app}
    };
}

export function hideEditSyncPreferenceDialog(){
    return {
        type:HIDE_APPLICATION_EDIT_PREFERENCE_SYNC_DIALOG,
        payload:{
        }
    };
}

//Action to start the registration
function startApplicationUpdateInput(){
    return {
        type:START_APPLICATION_UPDATEINPUT,
        payload:null
    };
}

function endApplicationUpdateInput(success,error){
    return {
        type:END_APPLICATION_UPDATEINPUT,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the register
export  function updateInput(appUrl,data){
    return async (dispatch) => {
        dispatch(startApplicationUpdateInput());
        try{
            let [response,error]    =   await ApplicationFactory.instance().updateFormInput(appUrl,data);
            dispatch(endApplicationUpdateInput(response,error));
        } catch(e){
            dispatch(endApplicationUpdateInput(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//Action to start the registration
function startApplicationFetchConfig(app){
    return {
        type:START_APPLICATION_FETCHCONFIG,
        payload:{
            app
        }
    };
}

function endApplicationFetchConfig(success,error){
    return {
        type:END_APPLICATION_FETCHCONFIG,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the register
export  function fetchAppConfig(app){
    return async (dispatch) => {
        dispatch(startApplicationFetchConfig());
        try{
            let [response,error]    =   await ApplicationFactory.instance().getAppConfig(app);
            dispatch(endApplicationFetchConfig(response,error));
        } catch(e){
            dispatch(endApplicationFetchConfig(null,{
                message:e.message
            }));
            return;
        }
    };
} 



//Action to start the registration
function startApplicationFetchConfigById(){
    return {
        type:START_APPLICATION_FETCHCONFIG_DETAILS,
        payload:{}
    };
}

function endApplicationFetchConfigById(success,error){
    return {
        type:END_APPLICATION_FETCHCONFIG_DETAILS,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the register
export  function fetchAppConfigById(id){
    return async (dispatch) => {
        dispatch(startApplicationFetchConfigById());
        try{
            const api_server = Environment.api_host("USER");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/app/${id}?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().getMethod(url,false);
            dispatch(endApplicationFetchConfigById(response,error));
        } catch(e){
            dispatch(endApplicationFetchConfigById(null,{
                message:e.message
            }));
            return;
        }
    };
} 



//Action to start the registration
function startApplicationsFetch(){
    return {
        type:START_APPLICATIONS_FETCH,
        payload:{}
    };
}

function endApplicationsFetch(success,error){
    return {
        type:END_APPLICATIONS_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the register
export  function fetchApps(data){
    return async (dispatch) => {
        dispatch(startApplicationsFetch());
        try{
            const api_server = Environment.api_host("USER");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/apps?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endApplicationsFetch(response,error));
        } catch(e){
            dispatch(endApplicationsFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 


//Action to start the registration
function startGroupsFetch(){
    return {
        type:START_GROUPS_FETCH,
        payload:{}
    };
}

function endGroupsFetch(success,error){
    return {
        type:END_GROUPS_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the register
export  function fetchGroups(data){
    return async (dispatch) => {
        dispatch(startGroupsFetch());
        try{
            const api_server = Environment.api_host("USER");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/groups?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endGroupsFetch(response,error));
        } catch(e){
            dispatch(endGroupsFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 




//Action to start the registration
function startApplicationSyncStatus(){
    return {
        type:START_APPLICATION_FETCH_SYNC_STATUS,
        payload:{}
    };
}

function endApplicationSyncStatus(success,error){
    return {
        type:END_APPLICATION_FETCH_SYNC_STATUS,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the register
export  function fetchApplicationSyncStatus(){
    return async (dispatch) => {
        dispatch(startApplicationSyncStatus());
        try{
            const api_server = Environment.api_host("USER");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/integration-data-sync?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().getMethod(url,false);
            dispatch(endApplicationSyncStatus(response,error));
        } catch(e){
            dispatch(endApplicationSyncStatus(null,{
                message:e.message
            }));
            return;
        }
    };
} 



function startSyncApplication(){
    return {
        type:START_SYNC_APPLICATION,
        payload:{}
    };
}

function endSyncApplication(success,error){
    return {
        type:END_SYNC_APPLICATION,
        payload:{
            success,error
        }
    };
}


//Thunk to perform the register
export  function SyncApplication(data){
    return async (dispatch) => {
        dispatch(startSyncApplication());
        try{
            const api_server = Environment.api_host("USER");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/integration-data-sync?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endSyncApplication(response,error));
        } catch(e){
            dispatch(endSyncApplication(null,{
                message:e.message
            }));
            return;
        }
    };
} 



//Action to start the registration
function startRemoveApplication(app){
    return {
        type:START_REMOVE_APPLICATION,
        payload:{
            app
        }
    };
}

function endRemoveApplication(success,error){
    return {
        type:END_REMOVE_APPLICATION,
        payload:{
            success,error
        }
    };
}


//Thunk to perform the register
export  function removeApplication(app){
    return async (dispatch) => {
        dispatch(startRemoveApplication());
        try{
            let [response,error]    =   await ApplicationFactory.instance().removeApplication(app);
            console.log(response);
            dispatch(endRemoveApplication(response,error));
        } catch(e){
            dispatch(endRemoveApplication(null,{
                message:e.message
            }));
            return;
        }
    };
} 


export function showMessageDialog(){
    return {
        type:SHOW_MESSAGE_DIALOG,
        payload:null
    };
}

export function hideMessageDialog(){
    return {
        type:HIDE_MESSAGE_DIALOG,
        payload:null
    };
}

export function showResponseMessageDialog(){
    return {
        type:SHOW_RES_MESSAGE_DIALOG,
        payload:null
    };
}

export function hideResponseMessageDialog(){
    return {
        type:HIDE_RES_MESSAGE_DIALOG,
        payload:null
    };
}

export function showConfirmCallbackDialog(){
    return {
        type:SHOW_CONFIRM_MESSAGE_DIALOG,
        payload:null
    };
}

export function hideConfirmCallbackDialog(){
    return {
        type:HIDE_CONFIRM_MESSAGE_DIALOG,
        payload:null
    };
}

export function storeSearchString(str,searchType) {
    return {
        type:STORE_SEARCH_STRING,
        payload:{
            str, searchType
        }
    };
}

export function updateCriteriaRequirementHeight(isExpanded, id, height) {
    return {
        type: UPDATE_CRITERIA_DYNAMIC_HEIGHT,
        payload:{
            isExpanded, id, height
        }
    };
}

export function toggleEvaluateView(isView, viewId) {
    return {
        type: TOGGLE_EVALUATE_VIEW,
        payload:{
            isView, viewId
        }
    };
}

export function toggleEvaluateNeverReviewed(isNeverReview, neverReviewId) {
    return {
        type: TOGGLE_EVALUATE_NEVER_REVIEW,
        payload:{
            isNeverReview, neverReviewId
        }
    };
}

/**
 * Evaluation view Product filter dialog
 */
 export function showProductSellerEmailDialog(){
    return {
        type:SHOW_ADD_PRODUCT_SELLER_EMAIL_DIALOG,
        payload: null
    };
}

export function hideProductSellerEmailDialog(){
    return {
        type:HIDE_ADD_PRODUCT_SELLER_EMAIL_DIALOG,
        payload: null
    };
}

//Action to start the registration
function startUpdateSyncPreferences(){
    return {
        type:START_SYNC_PREFERENCE_UPDATE,
        payload:{}
    };
}

function endUpdateSyncPreferences(success,error){
    return {
        type:END_SYNC_PREFERENCE_UPDATE,
        payload:{
            success,error
        }
    };
}

export  function updateSyncPreferences(appUrl,data){
    return async (dispatch) => {
        dispatch(startUpdateSyncPreferences());
        const timestamp     =   (new Date()).getTime();
        const url = `${appUrl}/updatePreferences?t=${timestamp}`;
        try{
            let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
            dispatch(endUpdateSyncPreferences(response,error));
        } catch(e){
            dispatch(endUpdateSyncPreferences(null,{
                message:e.message
            }));
            return;
        }
    };
} 