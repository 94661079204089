import {
  START_DOWNLOAD_REPORT,
  END_DOWNLOAD_REPORT,
  RESET_DOWNLOAD_REPORT,
  START_UPDATE_REPORT_DATA,
  END_UPDATE_REPORT_DATA,
  RESET_UPDATE_REPORT_DATA,
  START_UPDATE_SEARCH,
  END_UPDATE_SEARCH,
  RESET_UPDATE_SEARCH,
} from "./action";

const initState = {
  downloadReportInProgress: false,
  downloadReportResponse: null,
  updateReportDataInProgress: false,
  updateReportDataResponse: null,
  updateSearchInProgress: false,
  searchData: null,
};

export function reportsReducer(state = initState, action) {
  switch (action.type) {
    case START_DOWNLOAD_REPORT:
      return {
        ...state,
        downloadReportInProgress: true,
      };

    case END_DOWNLOAD_REPORT:
      return {
        ...state,
        downloadReportInProgress: false,
        downloadReportResponse: {
          success: action.payload.success,
          error: action.payload.error,
        },
      };

    case RESET_DOWNLOAD_REPORT:
      return {
        ...state,
        downloadReportInProgress: false,
        downloadReportResponse: null,
      };

    case START_UPDATE_REPORT_DATA:
      return {
        ...state,
        updateReportDataInProgress: true,
      };

    case END_UPDATE_REPORT_DATA:
      return {
        ...state,
        updateReportDataInProgress: false,
        updateReportDataResponse: {
          success: action.payload.success,
          error: action.payload.error,
        },
      };

    case RESET_UPDATE_REPORT_DATA:
      return {
        ...state,
        updateReportDataInProgress: false,
        updateReportDataResponse: null,
      };
    
    case START_UPDATE_SEARCH:
        return {
            ...state,
            updateSearchInProgress: true,
        };
    
    case END_UPDATE_SEARCH:
        return {
            ...state,
            updateSearchInProgress: false,
            searchData: {
                success: action.payload.success,
                error: action.payload.error,
            },
        };

        
    default:
      return state;
  }
}
