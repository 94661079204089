import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import queryString from "query-string";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, ButtonGroup, Button, Tooltip } from "@material-ui/core";
import AppBarV2 from "Components/Application/AppBarV2";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import SearchInput from "Components/Common/Input/SearchInput";
import ProductTypeFilter from "Components/Common/Filters/ProductTypeFilter";
import PostAddIcon from "@material-ui/icons/PostAdd";
// redux
import { updateDrawerStyle } from "redux/drawer/action";
import { showAddVendorDialog } from "redux/vendor/vendorCatalog/action";
import { createEvaluationCart, removeProductFromCart, fetchEvaluationCart } from "redux/evaluation/cart/action";
import { showSnackBar } from "redux/snackbar/action";
import { hideClusterProductsDialog } from "redux/product/cluster/action";

import CommonCss from "commonCss";
import ListIcon from "@material-ui/icons/List";
import AppsIcon from "@material-ui/icons/Apps";
import { fetchAllVendorServices } from "redux/vendor/service/action";
import { matchPath } from "react-router-dom";
import { isEnabledReview } from "util/Common";

import Breadcrumb from "Components/Common/Breadcrumb";
import { storeSearchString } from "redux/applications/action";
import { fetchVendorServiceCatalog } from "redux/vendor/vendorServiceCatalog/action";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import AddVendorOptionDialog from "Components/Common/Dialogs/AddVendorOptionDialog";
import VendorServiceTabular from "./VendorServiceTabular";
import { fetchOrgCatalogDetailsTabs } from "redux/vendor/vendorCatalog/action";
import { fetchAppCatlogProducts } from "redux/product/appCatalog/action";
import { fetchVendorCatalog } from "redux/vendor/vendorCatalog/action";

const connectedProps = (state) => ({
  addVendorDialogOpen: state.vendorCatalog.addVendorDialogOpen,
  evaluationCart: state.evaluationCart.evaluation,
  userType: state.authUser.user?.Role,
  orgUser: state.orgUser,
  orgName: state.authUser.user?.Organization?.Name,
  searchString: state.applications.searchString,
  searchType: state.applications.searchType,
  fetchAllVendorServiceProgress: state.services.fetchAllVendorServiceProgress,
  fetchAllVendorServiceError: state.services.fetchAllVendorServiceError,
  allVendorServices: state.services.allVendorServices,
  fetchVendorServiceCatalogProgress: state.vendorServiceCatalog.fetchVendorServiceCatalogProgress,
  fetchVendorServiceCatalogError: state.vendorServiceCatalog.fetchVendorServiceCatalogError,
  VendorServiceCatalog: state.vendorServiceCatalog.VendorServiceCatalog,
  orgServiceAddProgress: state.serviceCatalog.orgServiceAddProgress,
  updateOrgCatalogStateProgress: state.vendorCatalog.updateOrgCatalogStateProgress,
  updateOrgCatalogStateError: state.vendorCatalog.updateOrgCatalogStateError,
  orgVendorAddProgress: state.vendorCatalog.orgVendorAddProgress,

  orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,
  orgCatalogDetailTabsProgress: state.vendorCatalog.orgCatalogDetailTabsProgress,

  fetchAppCatalogProductsProgress: state.appCatalog.fetchAppCatalogProductsProgress,
  fetchAppCatalogProductsError: state.appCatalog.fetchAppCatalogProductsError,
  appCatalogProducts: state.appCatalog.products,

  fetchVendorCatalogProgress: state.vendorCatalog.fetchVendorCatalogProgress,
  fetchVendorCatalogError: state.vendorCatalog.fetchVendorCatalogError,
  VendorCatalog: state.vendorCatalog.VendorCatalog,
});

const connectionActions = {
  hideDialog: hideClusterProductsDialog,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  showAddVendorDialog: showAddVendorDialog,
  updateDrawerStyle: updateDrawerStyle,
  fetchEvaluationCart: fetchEvaluationCart,
  showSnackBar: showSnackBar,
  fetchAllVendorServices: fetchAllVendorServices,
  fetchVendorServiceCatalog: fetchVendorServiceCatalog,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  storeSearchString: storeSearchString,
  fetchOrgCatalogDetailsTabs: fetchOrgCatalogDetailsTabs,
  fetchAppCatlogProducts: fetchAppCatlogProducts,
  fetchVendorCatalog: fetchVendorCatalog,

};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 2),
    },
    rootTable: {
      padding: theme.spacing(2, 2, 0, 2),
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
      textAlign: "center",
    },
    emptyText: {
      cursor: "pointer",
      textAlign: "center",
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
      paddingLeft: 20,
    },
    productContainer: {
      maxHeight: "calc(100vh - 250px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 250px)",
      padding: theme.spacing(4, 0),
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    label: {
      fontSize: "1.4em",
      fontWeight: 600,
      lineHeight: "1.6",
      marginBottom: 10,
    },
    productHead: {
      ...CommonCss.productHead,
      fontSize: 18,
      margin: "0px !important",
    },
    productMenu: {
      background: "#FFFFFF",
      boxShadow: "0px 2px 10px #0000000B",
      padding: theme.spacing(4, 0, 4, 2.8),
      width: 270,
      minHeight: "100vh",
      position: "fixed",
    },
    menuHead: {
      display: "flex",
      marginBottom: 18,
    },
    container: {
      // display: "flex",
    },
    recommendedText: {
      fontSize: 12,
      fontWeight: 500,
      color: "#2EB77D",
      textDecoration: "underline",
      marginTop: 10,
      width: "max-content",
    },
    topContainer: {
      marginTop: theme.spacing(3),
    },
    headSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterSection: {
      ...CommonCss.filterSection,
      marginRight: 20,
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
    },
    title: {
      fontSize: theme.spacing(2.4),
      flex: 1,
    },
    categoriesText: {
      color: "#000000",
      fontSize: 18,
      marginLeft: 16,
      fontWeight: 500,
    },

    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    main: {
      maxWidth: "calc(100% - 280px)",
      flexBasis: "calc(100% - 280px)",
    },

    innerText: {
      color: "#696B6E",
      padding: theme.spacing(1, 1.5, 1, 1),
      cursor: "pointer",
      display: "block",
      margin: 0,
    },
    "@global": {
      ".applicationContent": {
        padding: "0 !important",
      },
    },
    products: {
      display: "flex",
    },

    filter: {
      // flex: 1,
      textAlign: "right",
    },
    txtCenter: {
      textAlign: "center",
      marginBottom: 16,
    },

    productsSection: {
      padding: "1em",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },

    isAllProducts: {
      background: "#ff0000a3",
    },
    isExistingProducts: {
      color: "white",
      background: "#2EB77D",
    },
    isFeaturedProducts: {
      color: "white",
      background: "#ff9800",
    },
    isGroupProducts: {
      width: "18px",
      marginRight: "10px",
      marginLeft: "2px",
    },
    isOtherProducts: {
      color: "white",
      background: "#2e81da",
    },
    productCounts: {
      fontSize: "12px",
      marginLeft: "auto",
      fontWeight: "600",
      color: "#4a87f8",
    },
    viewAll: {
      color: "#4A87F8",
      fontSize: "14px",
      paddingRight: "10px",
      cursor: "pointer",
    },
    flexWrapIcons: {
      display: "flex",
      gap: "20px",
      marginTop: 40,
      justifyContent: "center",
    },
    productCard: {
      position: "relative",
    },
    selectIcon: {
      position: "absolute",
      zIndex: 22,
      right: 5,
      top: 5,
      color: "#dcd7d7",
      cursor: "pointer",
    },
    disabledDiv: {
      pointerEvents: "none",
    },
    serviceSidebar: {
      minWidth: "270px",
      maxWidth: "270px",
      width: "100%",
      padding: "2em 0em 2em 1.4em",
      background: "#FFFFFF",
      boxShadow: "0px 2px 10px #0000000b",
    },
    allVendorService: {
      height: "calc(100vh - 50px)",
      overflowY: "overlay",
    },
    activeService: {
      color: "#4A87F8 !important",
      background: "#F2F7FF",
      fontSize: 13,
      marginRight: "1.6em !important",
    },
  });

class OrgServiceCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerSectionWidth: null,
      selectedProduct: null,
      isProduct: false,
      queryParams: queryString.parse(this.props.location.search),
      filters: {
        sortby: "relevance",
        order: "",
      },
      isCreateGroup: false,
      selectedGroupProduct: [],
      selectedState: [],
      searchObj: {
        text: "",
        page: 0,
        page_size: 60,
        filters: { is_org: "1" },
      },
      showActionMenu: null,
      lastLocation: this.props.lastLocation,
      productType: "SERVICE",
      filterServiceId: "",
      searchRestored: false,
      selectedView: "grid",
      searchFilterOptions: [],
      catalogFieldValue: '',
      isFetchProductCatalog: false,
      isFetchVendorCatalog: false,
      orgExistingProductCount: 0,
      orgExistingVendorCount: 0,
    };
  }

  componentDidMount() {
    this.checkDataOnLoad()

    this.props.fetchOrgCatalogDetailsTabs("vendorservice");
    this.props.fetchAllVendorServices({ query: "", page: 0, page_size: 1000, filters: { is_org: "1" } });
    const urlPrams = new URLSearchParams(this.props.location.search);
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);

    var evId = urlPrams.get("evaluationId");
    if (evId !== null) {
      setTimeout(() => {
        this.props.fetchEvaluationCart(evId);
      }, 1000);
    }
    if(urlPrams.get("activeView") === "table"){
      this.setState({selectedView: "table"})
    }else {
      if (urlPrams.get("s") !== null) {
        this.setState({ searchObj: { ...this.state.searchObj, text: urlPrams.get("s") }},
          () => this.fetchData()
        );
      } else {
        this.fetchData();
      }
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { lastLocation } = this.state;
    var pathName = lastLocation?.pathname;
    var isMatched = false;
    if (pathName !== undefined) {
      if (
        matchPath(pathName, {
          path: "/app/products/org-product-details/:id",
          exact: true,
          strict: false,
        })
      ) {
        isMatched = true;
      }
    }

    if (isMatched && pathName && this.props.searchString !== "" && this.state.searchRestored === false) {
      this.setState(
        {
          searchObj: { ...this.state.searchObj, field_setname: this.props.searchType, text: prevProps.searchString },
          searchRestored: true,
        },
        () => {
          this.fetchData();
        }
      );
    }

    if (prevProps.orgServiceAddProgress && !this.props.orgServiceAddProgress) {
      this.fetchData();
    }

    if (prevProps.orgVendorAddProgress && !this.props.orgVendorAddProgress) {
      this.fetchData();
    }

    if (!this.props.updateOrgCatalogStateProgress && prevProps.updateOrgCatalogStateProgress) {
      if (this.props.updateOrgCatalogStateError) {
        this.props.showSnackBar("Failed to update", "error", 3000);
      } else {
        this.props.showSnackBar("Updated successfully", "success", 3000);
        this.setState(
          {
            searchObj: { ...this.state.searchObj, page_size: 60, page: 0 },
          },
          () => {
            this.fetchData();
          }
        );
      }
    }
    
    if (!this.props.orgCatalogDetailTabsProgress && prevProps.orgCatalogDetailTabsProgress) {
      this.initialiseFilterData();
    }

    if (!this.props.fetchAppCatalogProductsProgress && prevProps.fetchAppCatalogProductsProgress) {
      this.setState({
        isFetchProductCatalog: true
      }, () => {
        this.checkTotalProducts()
      })
    }

    if (!this.props.fetchVendorCatalogProgress && prevProps.fetchVendorCatalogProgress) {
      this.setState({
        isFetchVendorCatalog: true
      }, () => {
        this.checkTotalProducts()
      })
    }

  }

  checkDataOnLoad() {
    this.props.fetchAppCatlogProducts(this.state.appCatalogObj);
    this.props.fetchVendorCatalog({ query: "", page: 0, page_size: 6, filters: { is_org: "1" } });
  }

  checkTotalProducts() {
    const { isFetchProductCatalog, isFetchVendorCatalog } = this.state;
    const { appCatalogProducts, VendorCatalog } = this.props;
    if (isFetchProductCatalog && isFetchVendorCatalog && appCatalogProducts && VendorCatalog) {
      let orgExistingProductCount = this.props.appCatalogProducts?.existing?.total || 0;
      let orgExistingVendorCount = this.props.VendorCatalog?.total || 0;
      this.setState({
        orgExistingProductCount: orgExistingProductCount,
        orgExistingVendorCount: orgExistingVendorCount,
      })
    }
  }

  initialiseFilterData = () => {
    if (!this.props.orgCatalogDetailTabs.length) return;
    let tabs = this.props.orgCatalogDetailTabs;
    let fields = [];
    tabs.forEach((tab) => {
      tab.sections.forEach((section) => {
        section.items.forEach((item) => {
          if([1,4,5,6].includes(item.type)){
            let itemName = item.name;
            if(!itemName || itemName.length === 0){
              itemName = section.name
            }
            fields.push({
              id: item.id,
              slug: item.slug,
              name: itemName,
              type: item.type,
              is_default: item.is_default,
            });
          }
        });
      });
    });

    this.setState({
      searchFilterOptions: fields,
    });
  }

  fetchData() {
    const { searchObj } = this.state;
    let queryParams = {
      query: searchObj.text,
      page_size: searchObj.page_size,
      page: searchObj.page,
      filters: searchObj.filters || {},
    };
    queryParams.filters.states = this.state.selectedState;
    if(this.state.catalogFieldValue && this.state.catalogFieldValue !== ""){
      
    }
    this.props.fetchVendorServiceCatalog({ ...queryParams, filters: { ...queryParams.filters, is_org: "1" } });
  }

  setSearchType = (type) => {
    const searchObj = this.state.searchObj;
    if (type === "features") {
      searchObj.field_setname = "features";
    } else if (type === "subcategories") {
      searchObj.field_setname = "subcategories";
    }

    searchObj.qtype_page = null;
    searchObj.text = "";
    this.setState({ searchObj }, () => {
      this.fetchData();
    });
  };

  handleActionClick = (event) => {
    this.setState({ showActionMenu: event.currentTarget });
  };

  handleActionClose = () => {
    this.setState({ showActionMenu: null });
  };

  handleRedirect = (type) => {
    if (type === "VENDOR") {
      this.props.history.push(`/app/vendors/org-vendors`);
    } else if (type === "SOFTWARE") {
      this.props.history.push(`/app/products/org-products`);
    } else if (type === "HARDWARE") {
      this.props.history.push(`/app/vendors/org-hardwares`);
    } else if (type === "SERVICE") {
      this.props.history.push(`/app/vendors/org-services`);
    }
  };

  handleOnClickState = (newState) => {
    let selectedState = [...this.state.selectedState];
    if (selectedState.includes(newState)) {
      selectedState = selectedState.filter((state) => state !== newState);
    } else {
      selectedState.push(newState);
    }
    this.setState(
      {
        selectedState,
        searchObj: { ...this.state.searchObj, page_size: 60, page: 0 },
      },
      () => {
        this.fetchData();
      }
    );
  };

  productStates = (arr) => {
    if (arr) {
      const data = arr.map((state) => ({
        StateName: state.name,
        StateID: state.name,
      }));
      return data;
    } else {
      return [];
    }
  };
  
  addToCart(id, type = "MASTER_VENDOR_SERVICE") {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(id, null, type);
    } else {
      var addedToCart = this.props.evaluationCart === null ? [] : this.props.evaluationCart?.Products?.filter((p) => p.EntityID === id);
      if (addedToCart && addedToCart.length > 0) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, addedToCart[0].EntityObjectID);
      } else {
        this.props.createEvaluationCart(id, this.props.evaluationCart.ID, type);
      }
    }
  }

  render() {
    const { classes, VendorServiceCatalog, fetchVendorServiceCatalogProgress } = this.props;
    let userRole = ["OrgAdmin","OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;
    var showAddProduct = false;
    const EnableAddProductForUserCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableAddProductForUserCheck");
    if (this.props.userType === "OrgUser") {
      if (EnableAddProductForUserCheck) {
        showAddProduct = true;
      }
    } else {
      showAddProduct = true;
    }
    const { searchObj } = this.state;

    // var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.map((p) => p.EntityID);

    return (
      <div className={classes.container}>
        <AppBarV2 title={this.props.orgName ? this.props.orgName + "'s Service Catalog" : "Service Catalog"} withBack={true} dropdown={true} />
        <div style={{ display: "flex" }}>
          {this.state.selectedView === "grid" && (
            <div className={classes.serviceSidebar}>
              <div className={classes.allVendorService}>
                <p
                  className={classnames(classes.innerText, this.state.filterServiceId === "" ? classes.activeService : "")}
                  onClick={() => {
                    this.setState({ searchObj: { page: 0, page_size: 60, text: "", filters: {} }, filterServiceId: "" }, () => this.fetchData());
                  }}
                >
                  <b>All</b>
                </p>
                {this.props.allVendorServices &&
                  this.props.allVendorServices?.items?.map((service, index) => (
                    <p
                      key={index}
                      className={classnames(classes.innerText, this.state.filterServiceId === service.id ? classes.activeService : "")}
                      onClick={() => {
                        if (service.is_existing) {
                          this.setState({ filterServiceId: service.id, searchObj: { page: 0, page_size: 60, text: "", filters: { org_service_id: service.org_service_id } } }, () => this.fetchData());
                        } else {
                          this.setState({ filterServiceId: service.id, searchObj: { page: 0, page_size: 60, text: "", filters: { master_service_id: service.master_service_id } } }, () =>
                            this.fetchData()
                          );
                        }
                      }}
                    >
                      {service.name}
                    </p>
                  ))}
              </div>
            </div>
          )}
          <div style={{ width: "100%" }}>
            <Breadcrumb routeList={[{ name: "Vendors", path: "/app/products" }, { name: "Service Catalog" }]} />
            <div className={classes.productMain}>
              {this.state.selectedView === "grid" && (
                <div className={classes.stickyBar}>
                  <ProductTypeFilter
                    type={this.state.productType}
                    hideHardware
                    onChange={(type) => {
                      this.handleRedirect(type);
                    }}
                    isShowServiceTab={this.state.orgExistingProductCount > 0 || this.state.orgExistingVendorCount > 0 ? true : false}
                    isShowProductTab={this.state.orgExistingProductCount > 0 ? true : false}
                    isShowVendorTab={this.state.orgExistingVendorCount > 0 ? true : false}
                  />
                  <Grid
                    container
                    justifyContent="space-between"
                    ref={(divElement) => {
                      this.divElement = divElement;
                    }}
                    className={classes.productSectionNew}
                  >
                    <Grid item>
                      <p className={classes.productCount}>{VendorServiceCatalog?.total} Service's found</p>
                    </Grid>

                    <Grid item>
                      <SearchInput
                        roundedCorner
                        showTypes={false}
                        isFilter={false}
                        selectedState={this.state.selectedState}
                        searchObj={this.state.searchObj}
                        placeholder="Search Services"
                        onChange={(searchObj) => {
                          this.setState({ searchObj: { ...this.state.searchObj, page_size: 20, text: searchObj.text, page: 0 } }, () => {
                            this.props.storeSearchString(searchObj.text);
                            this.fetchData();
                          });
                        }}
                        onChangeState={(newState) => {
                          this.handleOnClickState(newState);
                        }}
                        onCatalogFieldFilter={(value)=>{
                          this.setState({catalogFieldValue: value || ''},()=>{
                            this.fetchData();
                          })
                        }}
                        showState
                        type="vendor"
                      />
                    </Grid>
                    <Grid item style={{ minWidth: 160 }}>
                      {(adminFlag || showAddProduct) && (
                        <div className={classes.flexWrapIcons}>
                          <Tooltip
                            title={<span style={{ fontSize: "13px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>Add Service</span>}
                            placement="top"
                            arrow
                          >
                            <Button
                              color="primary"
                              variant={"contained"}
                              onClick={() => {
                                this.props.showAddVendorDialog();
                              }}
                            >
                              <PostAddIcon />
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}
              {adminFlag && (
                <div style={{ textAlign: "right", marginBottom: 10, padding: "5px 30px" }}>
                  <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Tooltip title="Grid View">
                      <Button
                        variant={this.state.selectedView === "grid" ? "contained" : "outlined"}
                        onClick={() => {
                          this.setState({
                            selectedView: "grid",
                          },()=>{
                            this.props.history.push("/app/vendors/org-services")
                          });
                          this.fetchData();
                        }}
                      >
                        <AppsIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Tabular View">
                      <Button
                        variant={this.state.selectedView === "table" ? "contained" : "outlined"}
                        onClick={() => {
                          this.setState({
                            selectedView: "table",
                          },()=>{
                            this.props.history.push("/app/vendors/org-services?activeView=table")
                          });
                        }}
                      >
                        <ListIcon />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </div>
              )}

              {this.state.selectedView === "grid" && (
                <div className={classes.productContainer} id="productContainer">
                  <InfiniteScroll
                    scrollableTarget="productContainer"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                      marginRight: 20,
                      paddingLeft: 20,
                    }}
                    dataLength={VendorServiceCatalog?.items?.length || 0}
                    next={() => {
                      this.setState({ searchObj: { ...searchObj, page: searchObj.page + 1 } }, () => {
                        this.fetchData();
                      });
                    }}
                    hasMore={VendorServiceCatalog?.total > VendorServiceCatalog?.items?.length}
                  >
                    <Grid container spacing={3} style={{ marginBottom: 10 }}>
                      {VendorServiceCatalog?.items?.map((service, index) => (
                        <Grid item lg={2} md={3} sm={4} xs={6} key={index}>
                          <LayeredCard
                            title={this.state.filterServiceId === "" ? service.service_name : service.vendor_name}
                            subTitle={this.state.filterServiceId === "" ? "By " + service.vendor_name : ""}
                            description={service.description || ""}
                            image={service.logo || ""}
                            cardType={"vendorservice"}
                            isActionButtons
                            toogleCheck={(e) => {}}
                            onClick={() => {
                              this.props.history.push("/app/vendors/org-service-details/" + service.org_vendorservice_id);
                            }}
                            numLayer={0}
                            isExisting={true}
                            id={parseInt(service.org_service_id)}
                            org_vendorservice_id={service.org_vendorservice_id}
                            uniqueId={service.org_service_id + "_" + index}
                            type={"service"}
                            productStates={this.productStates(service.states)}
                            onClickState={(state) => this.handleOnClickState(state)}
                            typeOfBlock="all_vendor_blocking"
                            typeOfFeatured="featured"
                          />
                        </Grid>
                      ))}
                    </Grid>
                    {fetchVendorServiceCatalogProgress && (
                      <Grid container justify="center" style={{ marginTop: 30 }}>
                        <CircularProgress />
                      </Grid>
                    )}
                  </InfiniteScroll>
                </div>
              )}

              {this.state.selectedView === "table" && (
                <div style={{ padding: "0rem 2rem" }}>
                  <VendorServiceTabular type="vendorservice" />
                </div>
              )}
            </div>
          </div>
          {this.props.addVendorDialogOpen && <AddVendorOptionDialog type={"service"} />}
        </div>
      </div>
    );
  }
}

export default connector(compose(withStyles(styles), withLastLocation)(OrgServiceCatalog));
