import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Button } from '@material-ui/core';
import { addRequirementResponse } from "redux/evaluation/criteria/action"

const connectedProps = () => ({});

const connectionActions = {
  addRequirementResponse: addRequirementResponse
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  buyerBtn: {
    color: '#4b86ff',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#4b86ff',
    }
  },
});

class ProductReqMeet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMoveToNextReq: false
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  render() {
    const classes = this.props.classes;
    const { requirement, response, evaluation_id, productId } = this.props;
    // console.log(enableScoring,'enableScoring')
    return (
      <div style={{ textAlign: 'center' }}>
        <Button className={classes.buyerBtn} variant="contained" color="primary" onClick={() => {
          // console.log('User Seller Response')
          // console.log(response, 'sellerResponse')
          // console.log(requirement, 'sellerRequirement')

          let answer = {};
          if ([1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].includes(requirement?.AnswerType)) {
            answer = {
              note: response === null ? null : response.Note,
              value: response === null ? null : this.props.enableScoring ? response.Response : 10,
              response_options: {
                answer: response?.SellerOptions[0]?.Answer
              }
            }
          } else {
            answer = {
              note: response === null ? null : response.Note,
              value: response === null ? null : this.props.enableScoring ? response.Response : 10,
              response_options: {
                option_ids: (response == null || response.SellerOptions == null ? [] : response.SellerOptions.map((i) => {
                  return i.OptionID
                }))
              }
            }
          }

          // console.log(response,'response')

          this.props.addRequirementResponse(
            evaluation_id,
            productId,
            requirement.RequirementID, {
            ...answer
          }
          );

        }}>
          Use as my response
        </Button>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ProductReqMeet));
