import { uniq } from "underscore";
import {
  SHOW_EVALUATION_TEMPLATE_CHOOSE_DIALOG,
  HIDE_EVALUATION_TEMPLATE_CHOOSE_DIALOG,
  SHOW_EVALUATION_ASKSELLER_CHOOSE_DIALOG,
  HIDE_EVALUATION_ASKSELLER_CHOOSE_DIALOG,
  SELECT_EVALUATION_TEMPLATE_TYPE,
  SELECT_EVALUATION_TEMPLATE,
  SELECT_EVALUATION_TEMPLATE_CRITERIA,
  UNSELECT_EVALUATION_TEMPLATE_CRITERIA,
  SHOW_ADD_TEMPLATE_DIALOG,
  HIDE_ADD_TEMPLATE_DIALOG,
  UNSELECT_ALL_TEMPLATE,
  SELECT_ORG_TEMPLATE,
  UNSELECT_ORG_TEMPLATE,
  SELECT_MASTER_TEMPLATE,
  UNSELECT_MASTER_TEMPLATE,
  START_ASKSELLER_MULTISELECT_REQUIREMENT,
  END_ASKSELLER_MULTISELECT_REQUIREMENT,
  ADD_CRITERIA_SELECTION,
  START_ADD_TEMPLATE_WITH_CRITERIA_SELECT,
  END_ADD_TEMPLATE_WITH_CRITERIA_SELECT,
  START_ASK_SELLER_WITH_SELLER_RESPONSE,
  END_ASK_SELLER_WITH_SELLER_RESPONSE,
  START_RE_SEND_ASKSELLER_MULTISELECT_REQUIREMENT, END_RE_SEND_ASKSELLER_MULTISELECT_REQUIREMENT,
  START_ASKSELLER_SEND_REQUIREMENT, END_ASKSELLER_SEND_REQUIREMENT
} from "./action";

const initState = {
  show: false,
  evaluationId: null,
  templateType: "MASTER",
  templateId: null,
  criteriaIds: [],
  showAddTemplate: false,
  isRequiredTemplateSection: true,
  isRequiredCriteriaSection: true,
  isRequiredRequirementSection: true,
  isRequiredAddRequirement: true,
  saveButtonText: "Save",
  settingsCriteriaId: null,
  settingsTemplateId: null,
  criteria: {},
  requirement: {},
  templateMasterIds: [],
  templateOrgIds: [],
  dialogEvaluationAskseller: false,
  multiSelectAskEvaluationId: null,
  multiSelectAskProduct: null,
  askSellerMultiProgress: false,
  askSellerMultiError: null,
  criteriaSelection: {},
  addTemplateWithCriteriaSelectProgress: false,
  addTemplateWithCriteriaSelectError: null,
  askSellerWithSellerResponseProgress: false,
  askSellerWithSellerResponseError: null,
  reSendAskSellerWithSellerResponseProgress: false,
  criteriaSelectionProgress: false,

  askSellerSendProgress: false,
  askSellerSendError: null,
};

export function templateEvaluationReducer(state = initState, action) {
  switch (action.type) {
    case START_RE_SEND_ASKSELLER_MULTISELECT_REQUIREMENT:
      return { ...state, reSendAskSellerWithSellerResponseProgress: true }
    case END_RE_SEND_ASKSELLER_MULTISELECT_REQUIREMENT:
      return { ...state, reSendAskSellerWithSellerResponseProgress: false }
    case START_ASK_SELLER_WITH_SELLER_RESPONSE:
      return {
        ...state,
        askSellerWithSellerResponseProgress: true,
        askSellerWithSellerResponseError: null,
      };
    case END_ASK_SELLER_WITH_SELLER_RESPONSE:
      return {
        ...state,
        askSellerWithSellerResponseProgress: false,
        askSellerWithSellerResponseError: action.payload.error,
      };
    case START_ADD_TEMPLATE_WITH_CRITERIA_SELECT:
      return {
        ...state,
        addTemplateWithCriteriaSelectProgress: true,
        addTemplateWithCriteriaSelectError: null,
      };
    case END_ADD_TEMPLATE_WITH_CRITERIA_SELECT:
      return {
        ...state,
        addTemplateWithCriteriaSelectProgress: false,
        addTemplateWithCriteriaSelectError: action.payload.error,
      };
    case ADD_CRITERIA_SELECTION:
      const { isCriteria, criteriaID, requirementIds, checked, reset } =
        action.payload;
      let newCriteriaSelection = { ...state.criteriaSelection };
      if (reset) {
        newCriteriaSelection = {};
      } else {
        if (newCriteriaSelection[criteriaID]) {
          if (checked) {
            if (isCriteria) {
              const crt = uniq([
                ...newCriteriaSelection[criteriaID],
                ...requirementIds,
              ]);
              newCriteriaSelection[criteriaID] = crt;
            } else {
              newCriteriaSelection[criteriaID] = [
                ...newCriteriaSelection[criteriaID],
                ...requirementIds,
              ];
            }
          } else {
            if (isCriteria) {
              delete newCriteriaSelection[criteriaID];
            } else {
              const crt = newCriteriaSelection[criteriaID];
              const index = crt.findIndex((rqid) => rqid.id === requirementIds[0].id);
              crt.splice(index, 1);
              newCriteriaSelection[criteriaID] = crt;
            }
          }
        } else {
          newCriteriaSelection[criteriaID] = requirementIds;
        }
      }

      let newStatus = state.criteriaSelectionProgress ? false : true;

      return {
        ...state,
        criteriaSelection: newCriteriaSelection,
        criteriaSelectionProgress: newStatus
      };

    case SHOW_EVALUATION_TEMPLATE_CHOOSE_DIALOG:
      return {
        ...state,
        show: true,
        evaluationId: action.payload,
      };
    case HIDE_EVALUATION_TEMPLATE_CHOOSE_DIALOG:
      return {
        ...state,
        show: false,
        evaluationId: null,
      };

    case SHOW_EVALUATION_ASKSELLER_CHOOSE_DIALOG:
      return {
        ...state,
        dialogEvaluationAskseller: true,
        multiSelectAskEvaluationId: action.payload.evaluationId,
        multiSelectAskProduct: action.payload.product,
      };
    case HIDE_EVALUATION_ASKSELLER_CHOOSE_DIALOG:
      return {
        ...state,
        dialogEvaluationAskseller: false,
      };

    case SHOW_ADD_TEMPLATE_DIALOG:
      return {
        ...state,
        showAddTemplate: true,
        isRequiredTemplateSection: action.payload.isRequiredTemplateSection,
        isRequiredCriteriaSection: action.payload.isRequiredCriteriaSection,
        isRequiredRequirementSection:
          action.payload.isRequiredRequirementSection,
        isRequiredAddRequirement: action.payload.isRequiredAddRequirement,
        saveButtonText: action.payload.saveButtonText,
        settingsTemplateId: action.payload.templateId,
        settingsCriteriaId: action.payload.criteriaId,
        criteria: action.payload.criteria,
        requirement: action.payload.requirement,
      };
    case HIDE_ADD_TEMPLATE_DIALOG:
      return {
        ...state,
        showAddTemplate: false,
        isRequiredTemplateSection: true,
        isRequiredCriteriaSection: true,
        isRequiredRequirementSection: true,
        isRequiredAddRequirement: true,
        saveButtonText: "Save",
        criteria: {},
        requirement: {},
      };
    case SELECT_EVALUATION_TEMPLATE_TYPE:
      return {
        ...state,
        templateType: action.payload,
      };
    case SELECT_EVALUATION_TEMPLATE:
      return {
        ...state,
        templateId: action.payload,
        criteriaIds: [],
      };
    case SELECT_EVALUATION_TEMPLATE_CRITERIA:
      var criterias = [...state.criteriaIds];
      if (action.payload !== null) {
        var nCriteriaId = action.payload;
        if (criterias.indexOf(nCriteriaId) == -1) {
          criterias.push(nCriteriaId);
        }
      }
      return {
        ...state,
        criteriaIds: criterias,
      };
    case UNSELECT_EVALUATION_TEMPLATE_CRITERIA:
      var criterias = [...state.criteriaIds];
      if (action.payload !== null) {
        var nCriteriaId = action.payload;
        if (criterias.indexOf(nCriteriaId) !== -1) {
          criterias.splice(criterias.indexOf(nCriteriaId), 1);
        }
      }
      return {
        ...state,
        criteriaIds: criterias,
      };
    case SELECT_MASTER_TEMPLATE:
      var templates = [...state.templateMasterIds];
      if (action.payload !== null) {
        var nTemplateId = action.payload;
        if (templates.indexOf(nTemplateId) == -1) {
          templates.push(nTemplateId);
        }
      }
      return {
        ...state,
        templateMasterIds: templates,
      };
    case UNSELECT_MASTER_TEMPLATE:
      var templates = [...state.templateMasterIds];
      if (action.payload !== null) {
        var nTemplateId = action.payload;
        if (templates.indexOf(nTemplateId) !== -1) {
          templates.splice(templates.indexOf(nTemplateId), 1);
        }
      }
      return {
        ...state,
        templateMasterIds: templates,
      };
    case SELECT_ORG_TEMPLATE:
      var templates = [...state.templateOrgIds];
      if (action.payload !== null) {
        var nTemplateId = action.payload;
        if (templates.indexOf(nTemplateId) == -1) {
          templates.push(nTemplateId);
        }
      }
      return {
        ...state,
        templateOrgIds: templates,
      };
    case UNSELECT_ORG_TEMPLATE:
      var templates = [...state.templateOrgIds];
      if (action.payload !== null) {
        var nTemplateId = action.payload;
        if (templates.indexOf(nTemplateId) !== -1) {
          templates.splice(templates.indexOf(nTemplateId), 1);
        }
      }
      return {
        ...state,
        templateOrgIds: templates,
      };
    case UNSELECT_ALL_TEMPLATE:
      return {
        ...state,
        templateOrgIds: [],
        templateMasterIds: [],
      };
    case START_ASKSELLER_MULTISELECT_REQUIREMENT:
      return {
        ...state,
        askSellerMultiProgress: true,
        askSellerMultiError: null,
      };
    case END_ASKSELLER_MULTISELECT_REQUIREMENT:
      return {
        ...state,
        askSellerMultiProgress: false,
        askSellerMultiError: action.payload.error,
      };

    case START_ASKSELLER_SEND_REQUIREMENT:
      return {
        ...state,
        askSellerSendProgress: true,
        askSellerSendError: null,
      };
    case END_ASKSELLER_SEND_REQUIREMENT:
      return {
        ...state,
        askSellerSendProgress: false,
        askSellerSendError: action.payload.error,
      };
    default:
      return state;
  }
}
