import { Dialog, DialogContent, Slide, TextField, Typography, FormControl, MenuItem, Select, CircularProgress, Button, Grid, InputAdornment } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { connect } from "react-redux";
import CommonCss from "commonCss";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import SqureButton from "Components/Common/SqureButton";
import Alert from "@material-ui/lab/Alert";
import { hideAddUpdateTaskDialog, addNewCatalogTask, updateCatalogTask, fetchCatalogTasks } from "redux/vendor/task/action";
import { showSnackBar } from "redux/snackbar/action";
import VendorAndServiceTasks from "../VendorAndServiceTasks";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "assets/images/calendar.svg"
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import Image from 'Components/Common/image.jsx';
import { vendorTaskTypes } from "util/AppCatalogTypeOptions"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  user: state.authUser.user,
  isOpen: state.catalogTasks.showAddTaskDialog,
  addUpdateTaskData: state.catalogTasks.addUpdateTaskData,
  isNewTask: state.catalogTasks.isNewTask,
  addNewTaskProgress: state.catalogTasks.addNewTaskProgress,
  addNewTaskError: state.catalogTasks.addNewTaskError,
  newTaskData: state.catalogTasks.newTaskData,
  updateNewTaskProgress: state.catalogTasks.updateNewTaskProgress,
  updateNewTaskError: state.catalogTasks.updateNewTaskError,
  fetchCatalogTasksProgress: state.catalogTasks.fetchCatalogTasksProgress,
  fetchCatalogTasksError: state.catalogTasks.fetchCatalogTasksError,
  catalogTasks: state.catalogTasks.catalogTasks,
  deleteCatalogTaskProgress: state.catalogTasks.deleteCatalogTaskProgress,
  deleteCatalogTaskError: state.catalogTasks.deleteCatalogTaskError,
});

const connectionActions = {
  hideDialog: hideAddUpdateTaskDialog,
  addNewCatalogTask: addNewCatalogTask,
  updateCatalogTask: updateCatalogTask,
  fetchCatalogTasks: fetchCatalogTasks,
  showSnackBar: showSnackBar,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
      marginBottom: 60,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    formControlDialog: {
      ...CommonCss.formControl,
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
        width: "100%",
      },
      padding: ".36rem",
    },
    title: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    body: {
      paddingTop: 20,
      "& [class*='editableField']": {
        maxHeight: "105px !important;",
        minHeight: "50px !important",
      },
      "& [class*='MuiFormHelperText-root']": {
        color: "red !important;",
        fontSize: 14,
      },
    },
    formControl: {
      width: "100%",
      "& > div": {
        color: "#282D30",
        fontSize: theme.spacing(2.2),
      },
    },
    filterTitle: {
      marginBottom: theme.spacing(1),
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    formItem: {
      marginBottom: 20,
    },
    formItem_p1: {
      marginLeft: 10,
      "& [class*='MuiFormLabel-root']": {
        color: '#475867'
      },
      "& [class*=MuiOutlinedInput-root]": {
        minHeight: 40,
        height: 40,
        color: '#282D30'
      },
      "& [class*=react-datepicker-wrapper]": {
        display:'block'
      }
    },
    bottomActions: {
      textAlign: "right",
    },
    actionBtn: {
      marginLeft: 10,
    },
    alert: {
      marginBottom: 10,
      marginTop: 25,
    },
    // viewBtn: {
    //   cursor: 'pointer',
    //   color: '#1f73b7',
    //   fontSize: 12
    // }
  });

class AddUpdateVendorServiceTaskDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      note: "",
      title: "",
      taskType: "task",
      priorityType: "low",
      taskStatus: "new",
      types: vendorTaskTypes,
      priorityTypes: [
        {
          name: "Low",
          value: "low",
        },
        {
          name: "Medium",
          value: "medium",
        },
        {
          name: "High",
          value: "high",
        },
      ],
      statusMap: [
        {
          name: "New",
          value: "new",
        },
        {
          name: "In Progress",
          value: "in_progress",
        },
        {
          name: "Completed",
          value: "completed",
        },
      ],
      showView: false,
      updateTaskData: null,
      isNewTask: true,
      entityId: null,
      dueDate: new Date()
    };

    this.addTask = this.addTask.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.editSelectedTask = this.editSelectedTask.bind(this);
  }

  componentDidMount() {
    this.setState({
      isNewTask: this.props.isNewTask,
      taskType: this.props.addUpdateTaskData.type || 1,
    });
    let entityId = this.props.addUpdateTaskData?.id;
      this.setState({ entityId: entityId });
    if (this.props.isNewTask) {
      this.fetchTasks();
    } else {
      this.updateFormDetails();
    }
  }

  componentDidUpdate(prevProps) {
    const { addNewTaskProgress, addNewTaskError, updateNewTaskProgress, updateNewTaskError } = this.props;
    if (!addNewTaskProgress && prevProps.addNewTaskProgress) {
      if (addNewTaskError === null) {
        this.props.showSnackBar("Added successfully.", "success", 3000);
        this.props.hideDialog();
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }

    if (!updateNewTaskProgress && prevProps.updateNewTaskProgress) {
      if (updateNewTaskError === null) {
        this.props.showSnackBar("Updated successfully.", "success", 3000);
        this.props.hideDialog();
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }
  }

  fetchTasks() {
    const { addUpdateTaskData } = this.props;
    let {entityId, entityType} = addUpdateTaskData;

    var queryParams = {
      // page: 0,
      // page_size: 100,
      // requirement_ids: [requirement?.id],
      item_slug: "all_types",
      // priorities: "all",
      // status: "all",
      entity_id: entityId,
      entity_type: entityType === "vendor" ? "vendor" : "vendorservice",
    };
    this.props.fetchCatalogTasks(queryParams);
  }

  clearError() {
    this.setState({
      error: "",
      success: "",
    });
  }

  addTask() {
    this.clearError();
    const { title, note, taskType, priorityType, dueDate } = this.state;
    const { addUpdateTaskData } = this.props;
    let {entityId, entityType} = addUpdateTaskData;

    if (String(title).trim().length === 0) {
      this.props.showSnackBar("Please enter task title", "error", 3000);
      return;
    }

    var params = {
      // requirement_id: requirement.id,
      description: note,
      item_slug: taskType,
      entity_id: entityId,
      entity_type: entityType === "vendor" ? "vendor" : "vendorservice",
      priority: priorityType,
      due_date: dueDate.toISOString(),
      user_id: String(this.props.user?.ID),
      title: title,
      status: 'new'
    };

    this.props.addNewCatalogTask(params);
  }

  updateTask() {
    this.clearError();
    const { title, note, priorityType, updateTaskData, taskStatus,dueDate } = this.state;
    const { addUpdateTaskData } = this.props;
    let {requirement} = addUpdateTaskData;

    if (String(title).trim().length === 0) {
      this.props.showSnackBar("Please enter task title", "error", 3000);
      return;
    }

    var params = {      
      title: title,
      description: note,
      priority: priorityType,
      due_date: dueDate.toISOString(),
      user_id: String(this.props.user?.ID),
      status: taskStatus,
    };

    this.props.updateCatalogTask(updateTaskData?.id, params, this.props.addUpdateTaskData?.from);
  }

  updateFormDetails() {
    const { addUpdateTaskData } = this.props;
    let task = addUpdateTaskData.task;
    console.log(addUpdateTaskData, "addUpdateTaskData");
    this.setState({
      updateTaskData: task,
      isNewTask: false,
      showView: false,
      taskType: task?.item_slug,
      priorityType: task?.priority,
      taskStatus: task?.status,
      note: task?.description,
      title: task?.title,
      dueDate: task?.due_date === null || task?.due_date === '' ? null : new Date(task?.due_date),
    });
  }

  editSelectedTask(task) {
    console.log(task, "TaskDataaaaaaaaaaa");
    this.setState({
      updateTaskData: task,
      isNewTask: false,
      showView: false,
      taskType: task?.item_slug,
      priorityType: task?.priority,
      taskStatus: task?.status,
      note: task?.description,
      title: task?.title,
      dueDate: task?.due_date === null || task?.due_date === '' ? null : new Date(task?.due_date),
    });
  }

  render() {
    const classes = this.props.classes;
    const { addUpdateTaskData } = this.props;
    const { showView, isNewTask } = this.state;
    let product = addUpdateTaskData?.product;
    let requirement = addUpdateTaskData?.requirement;

    return (
      <Dialog
        onClose={this.props.hideDialog}
        aria-labelledby="app-integrationDialog"
        open={this.props.isOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        id="productTaskDetails"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.hideDialog()}>
            <CloseIcon />
          </div>

          {isNewTask && !showView && (
            <Typography variant={"h4"} className={classes.title}>
              Add Tasks &nbsp;&nbsp;
              {!this.props.addUpdateTaskData.addOnly && (
                <Button
                  className={classes.viewBtn}
                  variant={"contained"}
                  color="primary"
                  size="small"
                  onClick={() => {
                    this.setState({
                      showView: true,
                    });
                  }}
                >
                  View All Tasks
                </Button>
              )}
            </Typography>
          )}

          {isNewTask && showView && (
            <Typography variant={"h4"} className={classes.title}>
              Tasks for {addUpdateTaskData?.product?.Name} &nbsp;&nbsp;
              <Button
                className={classes.viewBtn}
                variant={"contained"}
                color="primary"
                size="small"
                onClick={() => {
                  this.setState({
                    showView: false,
                  });
                }}
              >
                Add Task
              </Button>
            </Typography>
          )}

          {!isNewTask && (
            <Typography variant={"h4"} className={classes.title}>
              Update Task
            </Typography>
          )}

          {!showView && (
            <div className={classes.body}>
              {false && (
                <div className={classes.alert}>
                  {this.state.error.length > 0 && (
                    <Alert variant="filled" severity="error">
                      {this.state.error}
                    </Alert>
                  )}
                </div>
              )}

                <div className={classes.formItem}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Task Type
                  </Typography>
                  <FormControl variant="outlined" className={classes.formControl} size="small">
                    <Select
                      disabled={this.props.addUpdateTaskData.addOnly || !isNewTask ? true : false}
                      value={this.state.taskType}
                      onChange={(e) => {
                        this.setState({ taskType: e.target.value });
                      }}
                    >
                      {this.state.types.map((item, k) => (
                        <MenuItem value={item.value} style={{ fontSize: 13 }}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.formItem}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Title <span style={{color: 'red'}}>*</span>
                  </Typography>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    className={classes.formControlDialog}
                    placeholder={"Enter task title"}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    value={this.state.title}
                    type={"text"}
                    />
                </div>
              <div className={classes.formItem}>
                <Typography variant={"h4"} className={classes.filterTitle}>
                  Description
                </Typography>
                <SlateInputField
                  folderPath={`/evaluation/${this.props.match?.params?.evaluation_id}`}
                  style={{ margin: 0 }}
                  as={SlateInputField}
                  onChangeEvent={(value) => {
                    this.setState({
                      note: value,
                      error: "",
                    });
                  }}
                  maxLength={5}
                  initValue={this.state.note}
                  // formControlStyle={{ overflow: "overlay" }}
                  textContainerStyle={{ color: "#707070", fontSize: 14 }}
                />
              </div>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xl={6} lg={6} md={6} sm={6} className={classes.headSection}>
                  <div className={classes.formItem}>
                    <Typography variant={"h4"} className={classes.filterTitle}>
                      Priority
                    </Typography>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                      <Select
                        value={this.state.priorityType}
                        onChange={(e) => {
                          this.setState({ priorityType: e.target.value });
                        }}
                      >
                        {this.state.priorityTypes.map((item, k) => (
                          <MenuItem value={item.value} style={{ fontSize: 13 }}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} className={classes.headSection}>
                  <div className={classes.formItem_p1}>
                    <Typography variant={"h4"} className={classes.filterTitle}>
                      Due Date
                    </Typography>
                    <DatePicker
                      selected={this.state.dueDate}
                      onChange={date => {
                        this.setState({ dueDate: date });
                      }}
                      portalId="productTaskDetails"
                      dateFormat="MMM do yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <OutlinedInput
                          readOnlyInput={true}
                          error={''}
                          helperText={''}
                          startAdornment={
                            <InputAdornment>
                              <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                            </InputAdornment>
                          }
                        />
                      }
                    />
                  </div>
                </Grid>
              </Grid>

              {!isNewTask && (
                <div className={classes.formItem}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Status
                  </Typography>
                  <FormControl variant="outlined" className={classes.formControl} size="small">
                    <Select
                      value={this.state.taskStatus}
                      onChange={(e) => {
                        this.setState({ taskStatus: e.target.value });
                      }}
                    >
                      {this.state.statusMap.map((item, k) => (
                        <MenuItem value={item.value} style={{ fontSize: 13 }}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              <div className={classes.bottomActions}>
                <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>
                  Cancel
                </SqureButton>

                {isNewTask && (
                  <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.addTask}>
                    {this.props.addNewTaskProgress ? <CircularProgress style={{ width: 24, height: 24 }} /> : "Add"}
                  </SqureButton>
                )}

                {!isNewTask && (
                  <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.updateTask}>
                    {this.props.updateNewTaskProgress ? <CircularProgress style={{ width: 24, height: 24 }} /> : "Update"}
                  </SqureButton>
                )}
              </div>
            </div>
          )}

          {showView && (
            <div className={classes.body}>
              <VendorAndServiceTasks
                key={"tasks_for_" + requirement?.id}
                from={"EVALUATION"}
                showTypeFilter={true}
                showPriorityFilter={true}
                showStatusFilter={true}
                filterTypes={0}
                type={123}
                product={product}
                entityId={product?.id}
                requirementIds={[requirement?.id]}
                updateAccess={true}
                onDelete={(task) => { }}
                onEdit={(task) => {
                  this.editSelectedTask(task);
                }}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

AddUpdateVendorServiceTaskDialog.defaultProps = {
  isWorkFlow: false,
  addForTaskType: 1,
};

export default connector(compose(withRouter, withStyles(styles))(AddUpdateVendorServiceTaskDialog));
