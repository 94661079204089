import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, Typography, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

// Redux
import { hideCollaboratorDetailsDialog } from "redux/survey/action";
import { showSnackBar } from "redux/snackbar/action";

import { getOrgWorkflowComponent } from "redux/evaluation/workflow/action";
import { workflowComponents } from "redux/master/action";
import { fetchOrgFormUsingId } from "redux/forms/org/action";
import CustomForm from "./CustomForm/index";
import { fetchWorkflowComponentsForms, fetchWorkflowComponentDetails, fetchComponentDetails } from "redux/workflow/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    user: state.authUser.user,
    isShowCollaboratorDetailsDialog: state.survey.isShowCollaboratorDetailsDialog,
    selectedCollaboratorId: state.survey.selectedCollaboratorId,
    selectedCollaboratorName: state.survey.selectedCollaboratorName,
    evaluation: state.evaluationMetaData.evaluation,
    orgWorkFlowCompProgress: state.evaluationWorkflow.orgWorkFlowCompProgress,
    orgWorkFlowCompList: state.evaluationWorkflow.orgWorkFlowCompList,
    orgWorkFlowCompError: state.evaluationWorkflow.orgWorkFlowCompError,
    workflowComponent: state.master.workflowComponent,
    orgFormData: state.orgForms.orgFormData,
    fetchOrgFormsByIdProgress: state.orgForms.fetchOrgFormsByIdProgress,
    workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
    fetchworkflowComponentDetailProgress: state.workflow.fetchworkflowComponentDetailProgress,
    workflowComponentDetail: state.workflow.workflowComponentDetail,
});

const connectionActions = {
    showSnackBar: showSnackBar,
    hideCollaboratorDetailsDialog: hideCollaboratorDetailsDialog,
    getOrgWorkflowComponent: getOrgWorkflowComponent,
    workflowComponents: workflowComponents,
    fetchOrgFormUsingId: fetchOrgFormUsingId,
    fetchWorkflowComponentsForms: fetchWorkflowComponentsForms,
    fetchWorkflowComponentDetails: fetchWorkflowComponentDetails,
    fetchComponentDetails: fetchComponentDetails
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    header: {
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: ' center space-between',
        alignItems: 'center'
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(2.5),
    },
    close: {
        position: 'absolute',
        right: 15,
        top: 15,
        cursor: 'pointer',
        color: '#6F6F6F',
        '& svg': {
            stroke: 'white',
            fontSize: '24px'
        }
    },
    headingForm: {
        marginBottom: theme.spacing(1.6),
        fontSize: theme.spacing(2),
        color: "#6C6C6C"
    },
    nameBox: {
        marginTop: 20,
        '& [class*="inputRoot"]': {
            minHeight: '40px !important'
        },
        '& [class*="outlinedInput"]': {
            padding: '10px !important'
        }
    },
    surveyBody: {
        padding: '15px 50px',
        border: '1px solid #f1f1f1'
    },
    bottomActions: {
        marginTop: 20,
        marginBottom: 20,
        textAlign: 'center'
    },
    required: {
        color: 'red'
    },
    alert: {
        marginBottom: 10
    },
    forms: {

    },
    formList: {
        border: '1px solid #f1f1f1',
        padding: 20,
        borderRadius: 4
    },
    formDetails: {
        // border: '1px solid #f1f1f1',
        padding: '0 10px 10px 0',
        borderRadius: 4,
        background:'#f7f7f7',
        height:'80vh',
        overflow:'scroll'
    },
    formNames: {
        border: '1px solid #f1f1f1',
        padding: 5,
        marginBottom: 10,
        borderRadius: 10,
        cursor: 'pointer'
    },
    active:{
        background: '#1f73b7',
        color:'#fff',
    },
    loader:{
        textAlign:'center',
        marginTop:20
    },
    nameForm:{
        paddingLeft: 20
    }
});

class ShowCollaboratorDetailsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.state = {
            error: '',
            forms: [],
            selectedComponent: {},
            selectedComponentID: null,
            isShowNow: true
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {


    }

    render() {
        const classes = this.props.classes;
        return <Dialog
            onClose={this.props.hideCollaboratorDetailsDialog}
            aria-labelledby="add-upload-dialog"
            open={this.props.isShowCollaboratorDetailsDialog}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll={"body"}
            id="addProductDialog"
            PaperProps={{ style: { overflowY: 'visible', backgroundColor: '#f7f7f7' } }}
        >
            <DialogContent classes={{ root: classes.dialogContent }} style={{ overflowY: 'visible' }}>
                <span className={classes.close} onClick={() => this.props.hideCollaboratorDetailsDialog()}>
                    <CloseIcon className={classes.close} />
                </span>
                <Typography variant={"h6"} className={classes.title}>Response from {this.props.selectedCollaboratorName}</Typography>
                {!this.state.isShowNow && <div className={classes.loader}>
                    <CircularProgress/>
                </div>}
                {this.state.isShowNow && <div>
                    <div className={classes.formDetails}>
                        {<CustomForm
                            title={'Custom Form'}
                            component={''}
                            componentId={''}
                            evaluation_id={this.props.match.params.survey_id}
                            workflow_id={this.props.evaluation.WorkflowID}
                            workflowComponentId={''}
                            isOpen={true}
                            isWorkFlow={true}
                            userId={this.props.selectedCollaboratorId}
                        />}
                    </div>
                </div>}
            </DialogContent>
        </Dialog>
    }
}


export default connector(compose(
    withRouter,
    withStyles(styles)
)(ShowCollaboratorDetailsDialog));