import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, IconButton, Typography, Slide } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import SqureButton from "Components/Common/SqureButton";
import { showSnackBar } from "redux/snackbar/action";
import { showUserDetailsPoperDialog, hideUserDetailsPoperDialog } from "redux/dialogs/action";
import CloseIcon from "@material-ui/icons/Close";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.dialogs.isUserDetailsPoperDialog,
  data: state.dialogs.isUserDetailsPoperData,
});

const connectionActions = {
  showUserDetailsPoperDialog: showUserDetailsPoperDialog,
  hideUserDetailsPoperDialog: hideUserDetailsPoperDialog,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    modalTitle: {
      fontSize: theme.spacing(2.5),
      marginBottom: theme.spacing(4),
    },
    actions: {
      float: "right",
      marginBottom: 20,
    },
    alert: {
      marginBottom: theme.spacing(1.6),
    },
    infoContainer: {
      marginBottom: 10,
    },
    filterTitle: {
      fontWeight: 400,
      fontSize: 15,
      marginBottom: 5,
    },
    closeIcon: {
      position: "absolute",
      zIndex: 11,
      right: 15,
      top: 15,
      padding: 5,
    },
  });

class TargetAudienceDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const classes = this.props.classes;
    return (
      <Dialog
        onClose={this.props.hideUserDetailsPoperDialog}
        aria-labelledby="app-emailComponent"
        open={this.props.isOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"sm"}
        scroll="body"
      >
        <DialogContent classes={{ root: classes.dialogAction }}>
          <IconButton className={classes.closeIcon} onClick={this.props.hideUserDetailsPoperDialog}>
            <CloseIcon />
          </IconButton>
          <div id="TargetAudienceDialog">
            <Typography variant={"h2"} className={classes.modalTitle}>
              Target Audience Details
            </Typography>
            <div className={classes.infoContainer}>
              <Typography variant={"h6"} className={classes.filterTitle}>
                Type : {this.props.data.type}
              </Typography>
              {this.props.data?.Name && (
                <Typography variant={"h6"} className={classes.filterTitle}>
                  Name : {this.props.data.Name}
                </Typography>
              )}
              {this.props.data?.Email && (
                <Typography variant={"h6"} className={classes.filterTitle}>
                  Email : {this.props.data.Email}
                </Typography>
              )}
            </div>
            <div className={classes.actions}>
              <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideUserDetailsPoperDialog}>
                Close
              </SqureButton>
              &nbsp;&nbsp;&nbsp;
              <SqureButton
                cls={classes.actionBtn}
                variant={"contained"}
                onClick={() => {
                  this.props.onRemove(this.props.data);
                  this.props.hideUserDetailsPoperDialog();
                }}
              >
                Remove
              </SqureButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(TargetAudienceDialog));
