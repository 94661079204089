import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import classnames from "classnames"
import LinesEllipsis from 'react-lines-ellipsis'
import Image from 'Components/Common/image.jsx'
import { withRouter } from 'react-router-dom';

const connectedProps = (state) => ({
    user:state.authUser.user,
    enabledCognitoSSOAppsData: state.authUser.enabledCognitoSSOAppsData,
});

const connectionActions = {
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    'root': {
        textAlign: "center",
        borderRadius: theme.spacing(0.55),
        border: "solid 1px #CFD7DF",
        textAlign: "center",
        padding: theme.spacing(3),
        position:"relative",

        "&.SettingsIntegrations": {
            background: "#FFFFFF",
            border: "1px solid #CFD7DF",
            borderRadius: "4px",

            "& $activeCheckmark": {
                display: "none"
            }
        }
    },
    "app_image": {
        height: "40px",
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1.9),
    },
    "action": {
        marginTop: theme.spacing(3),
        display: "flex",
        textAlign: "center",
        alignContent: "center",
        justifyContent: "center",
        cursor:"pointer"
    },
    "label": {
        color: "#2C5CC5",
        fontSize: theme.spacing(2)
    },
    "labelIcon": {
        color: "#2C5CC5",
        width: '20px',
        marginLeft: '4px',
        position: 'relative',
        top: '-1px'
    },
    "appDetails":{
        cursor:"pointer"
    },
    "active":{
        border: "solid 1px #2EB77D",
    },
    "checkMark":{
        position:"absolute",
        right: "10px",
        top: "10px",
        width:"20px",
        display:"none"
    },
    "activeCheckmark":{
        display:"block"
    },
    "progressMark":{
        position:"absolute",
        right: "10px",
        top: "10px",
        width:"20px",
    },

    "description":{
        color:"#475867",
        marginTop:theme.spacing(2),
        fontSize: theme.spacing(2.2),
        minHeight:65
    },

    "category":{
        color: "#aaacb4",
        fontSize: theme.spacing(2),
    },

    "appname":{
        fontSize: theme.spacing(2.4),
    },

    "popoverBtn": {
        width: "100%",
        padding: theme.spacing(0.5, 8, 0.5, 2),
        minHeight: "30px",
        lineHeight: "13px",

        "& > span": {
            textAlign: "left",
            display: "block"
        }
    },

    "threeDot": {
        position: "absolute",
        zIndex: 1,
        right: 0,
        top: 0
    },

    "popover": {
        "& [class*='paper']": {
            boxShadow: "0px 8px 12px #0000001D",
            border: "1px solid #EEEEEE",
            borderRadius: "5px",
            overflow: "inherit",

            "&::before": {
                top: "10px",
                left: "-16px",
                width: "0",
                borderTop: "8px solid transparent",
                height: "0px",
                content: "''",
                display: "block",
                position: "absolute",
                borderBottom: "8px solid transparent",
                borderLeft: "8px solid transparent",
                borderRight: "8px solid #fff" 
            },
        },

    },

    "popoverText": {
        color: "#74798C"
    }
});

class OktaIntegrationCard extends React.Component {

    constructor(props){
        super(props);
        this.btnRef = React.createRef();
        this.state = {
            anchorEl: null
        }

    }

    render() {
        const classes = this.props.classes;
        if(this.props.user == null){
            return null;
        }
        var org = this.props.user.Organization
        return <div className={classnames(this.props.root,classes.root)} onClick={()=>{
            this.props.history.push("/app/settings/okta-sso-intergration")
        }}>
            <div className={classes.appDetails} >
                <Image src={"/images/applications/okta.png"} alt="app_name" className={classes.app_image} />
                <Typography variant={"h6"} className={classes.appname}>{"Okta"}</Typography>
                <Typography variant={"subtitle1"} className={classes.category}>{"SSO"}</Typography>
                <div className={classes.description}>
                    <LinesEllipsis
                            text={"The Okta Identity Cloud provides secure identity management with Single Sign-On, Multi-factor Authentication, Lifecycle Management"}
                            maxLine='3'
                            ellipsis='...'
                            basedOn='letters'
                    />
                </div>
            </div>
            {  this.props.isFromSetting && !this.props.enabledCognitoSSOAppsData?.find(item=> item.Driver === 'okta') && 
            <div className={classes.action}>
                <Typography className={classes.label}>  
                Add to Expent
                </Typography><ArrowForwardIcon className={classes.labelIcon} />
            </div>}
        </div>
    }
}

OktaIntegrationCard.defaultProps = {
    isFromSetting: false,
    root: '',
};

export default connector(compose(
    withStyles(styles),
    withRouter
)(OktaIntegrationCard));