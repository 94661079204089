import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import CommonCss from 'commonCss';
import { withRouter } from "react-router-dom";
import {
  TextField, Dialog, DialogContent, DialogActions, Slide, DialogTitle,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import SqureButton from 'Components/Common/SqureButton';

import uniqid from "uniqid";
import FoldersTreeSelect from "./FoldersTreeSelect";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
  contractFoldersData: state.orgProduct.contractFoldersData,
});

const connectionActions = {
  showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => createStyles({
  ...CommonCss.ProductDetails,
  formControlDialog: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  formControl: {
    ...CommonCss.formControl,
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 80
  },
  formGroupDialog: {
    marginTop: 5,
    marginBottom: 16,
    '& input': {
      color: '#6C6C6C !important'
    },
    "& .p-treeselect": {
      fontFamily: 'Poppins',
      width: '100%',
    }
  },
  filterBtn: {
    background: '#fff',
    border: '1px solid #888',
    borderRadius: 4,
    minWidth: 200,
    color: '#222',
    boxShadow: 'none',
    padding: '4px 20px',
    fontSize: 16,
    '&:hover': {
      background: '#fff',
      color: '#222'
    }
  },
});

const AddFolderDialog = (props) => {
  const [name, setName] = useState("")
  const [parentfolder, setParentFolder] = useState(null)
  const { classes, isOpen, onSubmit, onClose } = props;
  let newFolder;

  useEffect(() =>{
    if(props.data){
      setName(props.data.label);
      if(props.data.parentNode?.id){
        setParentFolder({id: props.data.parentNode?.id, label: props.data.parentNode?.label});
      }
    }
  },[])

  const handleSubmit = () => {
    if(props.data?.id && parentfolder && (props.data.id === parentfolder.id)){
      props.showSnackBar("Invalid Operation", "error", 3000);
      return;
    }
      
    let id = uniqid();
    let folders = props.contractFoldersData?.meta || []
    newFolder = { label: name, key: id, id: id, nodes: [], CreatedAt: new Date() }
    if(props.data?.id){
      updateFoldersData(folders,props.data?.id);
    }
     
    if(parentfolder && parentfolder.id){
      addToFolder(folders,parentfolder.id,newFolder)
    } else {
      folders.push(newFolder)
    }

    onSubmit({meta: folders})
    setName('')
    setParentFolder(null)
    onClose()
  }

  const updateFoldersData = (folders,id) => {
    folders.forEach((item,i) => { 
      if (id === item.id) {
        newFolder = {...item, label: name};
        folders.splice(i, 1);
      } else {
        updateFoldersData(item.nodes || [], id);
      }
    });
  }

  const addToFolder = (folders,parentfolder,newFolder) => {
    folders.forEach((item) => { 
      if (parentfolder === item.id) {
        item.nodes = [
          ...item.nodes, 
          newFolder
        ]
        return;
      } else {
        addToFolder(item.nodes || [], parentfolder, newFolder);
      }
    });
  }

  return (
    <Dialog
      onClose={()=>{
        onClose();
      }}
      aria-labelledby="AddUserDialog"
      open={isOpen}
      TransitionComponent={Transition}
      scroll={"body"}
      fullWidth={true}
      className={classes.dialog}
      id={`add-update-folders`}
    >
      <DialogTitle id={`form-dialog-title-add-file`}>
        {props.data ? "Update Folder" : "Add Folder" }
      </DialogTitle>
      <DialogContent>
        <div className={classes.formContainer}>
          <div className={classes.formGroupDialog}>
            <TextField
              fullWidth
              InputProps={{ disableUnderline: true }}
              className={classes.formControlDialog}
              value={name}
              placeholder="Folder Name*"
              onChange={(e) => {
                setName(prev => (e.target?.value ))
              }}
            />
          </div>
          {props.contractFoldersData?.meta?.length > 0 && <div className={classes.formGroupDialog}>
            <FoldersTreeSelect handleChange={(value)=>setParentFolder(value)} value={parentfolder}/>
          </div>}
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ padding: '10px 24px' }}>
          <SqureButton
            variant={"outlined"}
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </SqureButton>
          &nbsp; &nbsp; &nbsp;
          <SqureButton
            variant={"contained"}
            disabled={name.length === 0}
            onClick={() => {
              handleSubmit()
            }}
          >
            Save
          </SqureButton>
        </div>
      </DialogActions>
    </Dialog >
  )
}

export default connector(
  compose(withRouter, withStyles(styles))(AddFolderDialog)
);
