import React, { useState } from 'react'
import {
    Transforms,
    Editor,
    Range,
    Element as SlateElement,
} from 'slate'
import { useSlate } from 'slate-react';

import { IconButton } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import makeStyles from '@material-ui/styles/makeStyles';
import Variables from 'Components/Application/Components/Settings/Automations/FlowBuilder/Configurations/Components/Variables';

export const InsertAddAutomationVariableButton = ({ icon, automationVariables }) => {
    const editor = useSlate();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [placeholder, setPlaceholder] = useState('');
    // const [placeholderError, setVariableError] = useState('');
    const [code, setCodeValue] = useState('');
    const [selection, setSelection] = useState(null);

    const insertValue = (tagData, additional_data) => {
        editor.selection = selection
        insertAMVariable(editor, tagData, additional_data);
        clear();
    }

    const clear = (e) => {
        setIsOpen(false);
        setPlaceholder('');
        setCodeValue('');
    }

    const addAMVariableIcon = (e) => {
        console.log(editor, 'editor')
        const [add_variable] = Editor.nodes(editor, {
            match: n =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'automationVariable',
        })
        if (add_variable && add_variable[0]) {
            setPlaceholder(add_variable[0].placeholder);
            setCodeValue(add_variable[0].code);
        } else {
        }
        e.preventDefault()
        setIsOpen(true)
        setSelection(editor.selection)
    }

    return (
        <span>
            <ToggleButton
                className={classes.insertAMVariable}
                selected={(isAddAMVariableActive(editor))}
                onMouseDown={event => addAMVariableIcon(event)}>
                <IconButton >{icon}
                </IconButton>
            </ToggleButton>

            {isOpen && <Variables
                componentVariables={automationVariables}
                handleSelectVariable={(component, variable) => {
                    insertValue(component, variable)
                }}
            />}

        </span>
    )
}

const unwrap_AM_Variable = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'automationVariable',
    })
}

const wrap_AM_Variable = (editor, component, variable) => {
    if (is_AM_VariableActive(editor)) {
        unwrap_AM_Variable(editor)
    }
    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)

    let newValue = '{{ component_' + component.componentID + '.' + variable?.name + ' }}'

    const link = {
        type: 'automationVariable',
        placeholder: variable?.name !== '' ? variable?.name : variable?.label,
        code: newValue,
        children: isCollapsed ? [{ text: '' }] : [],
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
    }
}

const is_AM_VariableActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
}

const insertAMVariable = (editor, tagData, additionalData) => {
    if (editor.selection) {
        wrap_AM_Variable(editor, tagData, additionalData)
    }
}

export const withVariables = editor => {
    const { insertData, insertText, isInline } = editor

    editor.isInline = element => {
        return element.type === 'variaautomationVariableble' ? true : isInline(element)
    }

    editor.insertText = text => {
        if (text) {
            wrap_AM_Variable(editor, text)
        } else {
            insertText(text)
        }
    }

    editor.insertData = data => {
        const text = data.getData('text/plain')

        if (text) {
            wrap_AM_Variable(editor, text)
        } else {
            insertData(data)
        }
    }
    return editor
}

const isAddAMVariableActive = editor => {
    const [add_variable] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'automationVariable',
    })
    return !!add_variable
}

const useStyles = makeStyles((theme) => ({
    insertAMVariable: {
        padding: 0,
        minWidth: 41,
        margin: '0.25em',
        border: 'none'
    },
    radioBox: {
        width: 13,
        height: 13,
        maxWidth: 13,
        minWidth: 13
    },
    inputBox: {
        width: '100%',
        borderRadius: 3,
        minHeight: 40,
        border: '1px solid #ddd',
        padding: 5
    },
    btnSection: {
        float: 'right',
        marginBottom: 10
    },
    label: {
        position: 'relative',
        top: -1
    },
    alert: {
        marginBottom: theme.spacing(1)
    },
    items: {
        marginBottom: 20
    },
    itemTitle: {
        fontSize: 14,
        marginBottom: 5
    }
}));
