import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slide, Typography, Button, FormControl, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import CommonCss from "commonCss";
import { withRouter } from "react-router-dom";
import Select from 'react-select';

// Redux
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import { fetchAddOrgProduct } from "redux/product/orgProduct/action";
import { fetchProductSubCategory } from "redux/product/subcategory/action";
import { searchUser } from "redux/usersearch/action";
import { fetchRecommendedProductsToAdd } from "redux/product/action";
import { showSnackBar } from "redux/snackbar/action";
import { resetAddOrgProductData } from "redux/product/orgProduct/action";

import { hideEvaluationProductSelectionDialog } from "redux/evaluation/metadata/action";

import { createEvaluationCart } from "redux/evaluation/cart/action";
import { updateEvaluationMetaData } from "redux/evaluation/metadata/action";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import { startNewWorkflow } from 'redux/workflow/action';
import CloseIcon from '@material-ui/icons/Close';
import Image from 'Components/Common/image';
// env


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  user: state.authUser.user,
  isOpen: state.evaluationMetaData.showEvaluationProductSelectionDialog,
  addProductInitQuery: state.productsSettings.addProductInitQuery,

  subCategories: state.productSubCategory.subCategories,
  subCategoriesFetchProgress: state.productSubCategory.fetchSubCategoryProgress,

  ProductChampUserProgress: state.userSearch.searchProgress,
  ProductChampUsers: state.userSearch.users,

  products: state.products.recommendedProductsToAdd,
  addOrgProductProgress: state.orgProduct.addOrgProductProgress,
  addorgProductStatus: state.orgProduct.addorgProductStatus,
  addorgProductCatsStatus: state.orgProduct.addorgProductCatsStatus,
  addorgProductChampStatus: state.orgProduct.addorgProductChampStatus,
  addorgProductStatusError: state.orgProduct.addorgProductStatusError,
  fetchRecommendedProductsToAddProgress: state.products.fetchRecommendedProductsToAddProgress,
  cartCreationProgress: state.evaluationCart.cartCreationProgress,
  evaluationCart: state.evaluationCart.evaluation,
  updateProgress: state.evaluationMetaData.updateProgress,
  updateError: state.evaluationMetaData.errorUpdate,
  fetchExpentDirectoryProductsProgress: state.expentDirectory.fetchExpentDirectoryProductsProgress,
  fetchExpentDirectoryProductsError: state.expentDirectory.fetchExpentDirectoryProductsError,
  expentDirectoryProducts: state.expentDirectory.products,
  startNewWorkflowProgress: state.workflow.startNewWorkflowProgress,
  startNewWorkflowError: state.workflow.startNewWorkflowError,
  workflowData: state.workflow.workflowData,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  hideEvaluationProductSelectionDialog: hideEvaluationProductSelectionDialog,
  fetchAddOrgProduct: fetchAddOrgProduct,
  fetchProductSubCategory: fetchProductSubCategory,
  searchProductChampUser: searchUser,
  fetchRecommendedProducts: fetchRecommendedProductsToAdd,
  resetAddOrgProductData: resetAddOrgProductData,
  createEvaluationCart: createEvaluationCart,
  updateDetails: updateEvaluationMetaData,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  startNewWorkflow: startNewWorkflow
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    header: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: " center space-between",
      alignItems: "center",
    },
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginBottom: theme.spacing(2.5),
      display: 'flex',
      alignItems: 'center',
      gap: 15
    },
    mainForm: {
      border: "1px solid #F2F2F2",
      padding: 50,
      margin: 20,
    },
    productImage: {
      width: 200,
      height: 200,
      border: "1px solid #AAAAAA",
      borderRadius: 11,
    },
    subTitle: {
      color: "#282D30",
      fontSize: 16,
    },
    formControl: CommonCss.formControl,
    label: CommonCss.label,
    formGroup: CommonCss.formGroup,

    buttonSection: {
      textAlign: "center",
      margin: 20,
    },
    button: {},
    arrow: {
      paddingLeft: 5,
    },
    mandatory: CommonCss.mandatory,
    close: {
      position: "absolute",
      right: 15,
      top: 15,
      cursor: "pointer",
      color: "#6F6F6F",
      "& svg": {
        stroke: "white",
        fontSize: "24px",
      },
    },
    divider: {
      color: "#4b86ff",
    },
    customTypeFields: {
      // marginBottom: 15,
      // marginTop: 15,
      '& [class*="MuiFormControl-root"]': {
        width: '100%'
      },
      '& [class*="MuiInputBase-input"]': {
        textAlign: 'left'
      }
    },
  });

class StartRenewalDialog extends React.Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      error: "",
      isWorkflowEvaluation: false,
      isRequestType: true,
      requestTypes: [],
      requestTypeValue: null,
      requestTypeError: '',
      selectedProduct: [],
      selectedProductError: '',
      evaluationProducts: [],
      totalCreateProducts: 0,
      totalProductAdded: 0,
      newProducts: [],
      isAddingProducts: false
    };
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    this.setState({
      isRequestType: this.props.isRequestType,
      requestTypes: this.props.requestTypes,
      selectedProduct: [],
      requestTypeValue: null,
      isAddingProducts: false
    })
    this.clearError();
  }

  componentDidUpdate(prevProps) {
    if (this.props.cartCreationProgress === false && prevProps.cartCreationProgress === true) {
      if (this.props.evaluationCart !== null) {
        this.setState(
          {
            isWorkflowEvaluation: true,
          },
          () => {
            this.updateDetails(this.props.evaluationCart.ID);
          }
        );
      }
    }

    if (this.props.updateProgress === false && prevProps.updateProgress === true) {
      if (this.props.updateError === null && this.state.isWorkflowEvaluation === true) {
        this.props.hideEvaluationProductSelectionDialog();
        this.props.history.push(`/app/evaluate/${this.props.evaluationCart.ID}/workflow/interim`);
      }
    }

    if (this.props.startNewWorkflowProgress === false && prevProps.startNewWorkflowProgress === true) {
      if (this.props.startNewWorkflowError === null) {
        this.props.hideEvaluationProductSelectionDialog();
        this.props.history.push(`/app/evaluate/${this.props.workflowData.ID}/workflow/interim`);
      } else {
        this.setState({
          error: 'Something went wrong'
        })
      }
    }
  }

  updateDetails = (evaluationId) => {
    console.log(this.props.evaluationCart.Products);
    if (this.state.isWorkflowEvaluation === false) {
      return;
    }
    var name = "Request for ";
    this.props.evaluationCart.Products.forEach(function (product, k) {
      if (k > 0) {
        name += " & ";
      }
      name += product.Name;
    });

    this.props.updateDetails(evaluationId, {
      name: name,
      status: 2,
    });
  };

  clearError() {
    this.setState({
      requestTypeError: '',
      selectedProductError: ''
    })
  }

  submitForm() {
    this.clearError();
    const { selectedProduct, requestTypeValue } = this.state;
    let evaluationProducts = [];
    if (requestTypeValue === null || requestTypeValue === '') {
      this.setState({
        requestTypeError: 'Please select Project Type'
      })
      return;
    }

    selectedProduct.forEach(function (prjt) {
      evaluationProducts.push(prjt)
    })

    this.setState({
      evaluationProducts: evaluationProducts
    }, () => {
      this.startWorkflow();
    })
  }

  startWorkflow() {
    const { requestTypeValue } = this.state;
    var name = 'Request for ' + this.props.selectedRenewalProduct.name;

    var data = {
      product_ids: [{
        entity_id: String(this.props.selectedRenewalProduct.id),
        type: this.props.selectedRenewalProduct.type === 'PRODUCT' ? 'PRODUCT' : 'CUSTOM_VENDOR'
      }],
      title: name,
      description: '',
      workflow_id: requestTypeValue?.value,
      type: 10,
      request_type: requestTypeValue?.label,
      renewalDate: this.props.selectedRenewalDate,
      contractID: String(this.props.selectedRenewalContract.id)
    }
    this.props.startNewWorkflow(data);
  }

  render() {
    const classes = this.props.classes;
    const { isRequestType, requestTypes, requestTypeValue, requestTypeError } = this.state;
    return (
      <Dialog
        onClose={this.props.hideEvaluationProductSelectionDialog}
        aria-labelledby="add-upload-dialog"
        open={this.props.isOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll={"body"}
        id="addProductDialog"
        PaperProps={{ style: { overflowY: "visible" } }}
      >
        <DialogContent classes={{ root: classes.dialogContent }} style={{ overflowY: "visible" }}>
          <div className={classes.close} onClick={() => this.props.hideEvaluationProductSelectionDialog()}><CloseIcon /></div>

          <Typography variant={"h6"} className={classes.title}>
            Start Renewal for: <Image src={this.props.selectedRenewalProduct?.productLogo} alt={this.props.selectedRenewalProduct?.name} title={this.props.selectedRenewalProduct?.name} style={{ display: 'block', width: 35 }} />
            {this.props.selectedRenewalProduct?.name}
          </Typography>
          <div className={classes.alert}>
            {this.state.error.length > 0 && (
              <Alert variant="filled" severity="error">
                {this.state.error}
              </Alert>
            )}
          </div>
          {!isRequestType && <div style={{ textAlign: 'center', marginBottom: 50 }}>
            <Typography>Contact your administrator to get Project Types configured.</Typography>
          </div>}
          {isRequestType && <div className={classes.mainForm}>
            <div className={classes.formGroup}>
              <label className={classes.label}>
                Project Type <span className={classes.mandatory}>*</span>
              </label>
              <div className={classes.customTypeFields}>
                <FormControl className={classes.formSelect}>
                  <Select
                    isClearable
                    isMulti={false}
                    labelId="singleSelect-select-label"
                    id="singleSelect-select"
                    fullWidth
                    defaultValue={requestTypeValue}
                    value={requestTypeValue}
                    onChange={(e) => {
                      this.setState({
                        requestTypeValue: e
                      }, () => {
                        this.clearError()
                      })
                    }}
                    options={requestTypes.map((o => {
                      return {
                        label: o.requestType,
                        value: o.workflow?.value
                      }
                    }))}
                    placeholder={"Select a Project Type"}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  >
                  </Select>
                </FormControl>
              </div>
              {requestTypeError.length > 0 && <span className={classes.mandatory}>{requestTypeError}</span>}
            </div>

            <div className={classes.buttonSection}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={this.props.hideEvaluationProductSelectionDialog}>
                Cancel
              </Button>
              &nbsp; &nbsp;
              <Button
                variant="contained"
                color="secondary"
                onClick={() => { this.submitForm() }}
                className={classes.button}>
                {this.state.isAddingProducts ? <CircularProgress style={{ width: 25, height: 25 }} /> : <span style={{ display: 'flex' }}>Next  <ArrowForwardIcon className={classes.arrow} /></span>}
              </Button>
            </div>
          </div>}
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(StartRenewalDialog));
