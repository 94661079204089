import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Popover, Divider } from '@material-ui/core';
import AddApprover from '../../../../../../../Common/UserGroup/AddApprover';
import Requirement from "./Requirement";
import editIcon from "../../../../../../../../assets/images/edit_icon.svg"
import deleteIcon from "../../../../../../../../assets/images/delete.svg"
import OutlinedInput from "../../../../../../../Common/Input/OutlinedInput";
import classnames from "classnames";
import Image from 'Components/Common/image.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
// redux
import { showMessageDialog, hideMessageDialog, } from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { showCriteriaRuleDialog } from "redux/common/action";
import { showFeatureDialog } from "redux/evaluation/metadata/action";
import { showEvaluationRuleDialog } from "redux/evaluation/rule/action";
import { showEvaluationRequirementAddDialog, showEvaluationCriteriaEditDialog, deleteCriteria, editCriteria } from "redux/evaluation/criteria/action"
import { expandEvaluationCriteria, addCriteriaColloborator, editRequirement, expandEvaluationRequirement } from "redux/evaluation/criteria/action";
import { generate } from 'shortid';

const connectedProps = (state) => ({
    expandedCriteriaId: state.evaludationCriteria.expandedCriteriaId,
    expandedRequirementId: state.evaludationCriteria.expandedRequirementId,
    user: state.authUser.user,
    criterias: state.evaludationCriteria.criterias,
    evaluation: state.evaluationMetaData.evaluation,
});

const connectionActions = {
    showEvaluationRequirementAddDialog: showEvaluationRequirementAddDialog,
    expandEvaluationCriteria: expandEvaluationCriteria,
    showEvaluationCriteriaEditDialog: showEvaluationCriteriaEditDialog,
    deleteCriteria: deleteCriteria,
    addCriteriaColloborator: addCriteriaColloborator,
    showFeatureDialog: showFeatureDialog,
    editCriteria: editCriteria,
    showEvaluationRuleDialog: showEvaluationRuleDialog,
    showCriteriaRuleDialog: showCriteriaRuleDialog,
    editRequirement: editRequirement,
    showMessageDialog: showMessageDialog,
    hideMessageDialog: hideMessageDialog,
    expandEvaluationRequirement: expandEvaluationRequirement
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        borderRadius: 5,
        marginBottom: 26,
        position: 'relative',
        backgroundColor: '#FFFFFF',
    },
    rootReport: {
        borderRadius: 5,
        position: 'relative',
        backgroundColor: '#FFFFFF',
    },
    btns: {
        "& button": {
            boxShadow: '4px 0px 4px #2B2D3703',
            border: '1px solid #20253a33',
            background: 'transparent',
            borderRadius: '6px',
            opacity: 1,
            color: '#707070',
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'rgba(75, 134, 255, 0.04)'
            }
        }
    },
    criteriaHead: {
        padding: '10px',
        justifyContent: 'space-between',
    },
    criteriaExtended: {
        borderBottom: '1px solid #EEEEEE;'
    },
    expand: {
        transform: 'rotate(0deg)',
        padding: 0,
        borderRadius: 3,
        border: '1px solid #EDEDED',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    titleTxt: {
        fontSize: theme.spacing(2),
        color: '#282D30',
        fontWeight: 600,
        '& > img': {
            display: 'none'
        },
        '&:hover > img': {
            display: 'initial'
        }
    },
    actionTxt: {
        color: '#4175DF',
        width: 'max-content',
        fontSize: theme.spacing(1.75),
    },
    menuSection: {
        display: 'flex',
        "& > [class*=MuiSvgIcon-root]": {
            fill: '#282D30',
            height: 20
        },
        marginLeft: 10,
    },
    checkboxSection: {
        paddingRight: 15,
    },
    checkbox: {
        border: 'none',
        margin: 0
    },
    divider: {
        width: '100%',
        textAlign: 'center',
        borderBottom: '1px solid #FAFAFA',
        lineHeight: '0.1em',
        margin: '10px 0 20px',
        '& span': {
            background: '#fff',
            color: '#A0BAEF',
            padding: '0 10px',
            fontSize: theme.spacing(1.75)
        }
    },
    editIcon: {
        color: "#999999"
    },
    hrLine: {
        width: '100%',
        textAlign: 'center',
        lineHeight: '0.1em',
        margin: '0 0 20px',
        borderBottom: '1px solid #49B8FA',
        paddingTop: 20,
        '& span': {
            padding: '3px 8px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxhadow: '4px 0px 4px #2B2D3703',
            border: '1px solid #A0BAEF',
            borderRadius: 4,
            opacity: 1,
            fontSize: theme.spacing(2),
            color: '#4175DF',
            fontWeight: 400,
            // cursor: 'pointer'
        }
    },
    nameEditCotnainer: {
        position: 'relative',
    },
    saveNameAction: {
        position: 'absolute',
        bottom: -36,
        right: -1,
        margin: 'auto',
    },
    actionIcon: {
        width: 30,
        height: 30,
        borderRadius: 5,
        textAlign: 'center',
        marginLeft: 5,
        border: '1px solid #4b86f8'
    },
    micon: {
        fontSize: 16,
        marginTop: 6,
        color: '#4b86f8',
    },
    dragIconArea: {
        position: 'absolute',
        top: 15,
        left: '50%',
        color: '#D9D9D9',
        transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
    },
    requirementConainer: {
        padding: '18px 22px 0px 22px',
    },
    addRequirementAction: {
        padding: '0 22px',
    },
});

class Criteria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            name: '',
            name_error: "",
            isEditName: false,
            deleteId: null,
            isActiveDialog: false,
        }
        this.onDragEnd = this.onDragEnd.bind(this);
        this.deleteCriteriaItem = this.deleteCriteriaItem.bind(this);
        this.handleToggleRequirement = this.handleToggleRequirement.bind(this)
    }

    handleToggleRequirement = (id) => {
        if (id !== null || id !== undefined) {
            this.props.expandEvaluationRequirement(id)
        }
    }

    handleClick = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget || event.current });
    };

    handleClose = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: null });
    };

    toggleMandatory = (event) => {
        this.props.editCriteria(this.props.match.params?.evaluation_id, this.props.criteria?.id, {
            name: this.props.criteria?.name,
            schedule: this.props.criteria?.schedule,
            is_editable: this.props.criteria?.is_editable,
            is_mandatory: event.target.checked
        },this.props.match?.params?.component_id)
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const { destination, draggableId, source } = result;
        if (draggableId && destination.index !== source.index) {
            this.props.editRequirement(
                this.props.match.params?.evaluation_id,
                this.props.criteria?.id,
                parseInt(draggableId),
                {
                    "seq": destination.index
                },
                this.props.match?.params?.component_id
            )
        }
    }

    toggleEditable = (event) => {
        this.props.editCriteria(this.props.match.params?.evaluation_id, this.props.criteria?.id, {
            name: this.props.criteria?.name,
            schedule: this.props.criteria?.schedule,
            is_editable: event.target.checked,
            is_mandatory: this.props.criteria?.is_mandatory
        },this.props.match?.params?.component_id)
    }

    cleanError() {
        this.setState({
            name_error: "",
        })
    }

    saveName(name, evaluation_id, criteria_id) {
        this.cleanError();
        if (name.trim().length === 0) {
            this.setState({
                name_error: "Please enter Section Name."
            });
            return;
        }
        this.props.editCriteria(evaluation_id, criteria_id, {
            name: this.state.name,
        },this.props.match?.params?.component_id)
        this.setState({
            isEditName: false
        });
    }

    deleteCriteriaItem(e, id) {
        e.stopPropagation();
        var me = this;
        this.setState({ deleteId: id, isActiveDialog: true }, () => {
            me.props.showMessageDialog();
        })
    }

    callBack = () => {
        if (this.state.deleteId) {
            this.props.hideMessageDialog();
            this.props.deleteCriteria(this.props.match.params.evaluation_id, this.state.deleteId);
            this.setState({
                isActiveDialog: false
            })
        }
    };

    render() {
        const classes = this.props.classes;
        if (this.props.criteria == null || this.props.criteria === undefined) {
            return null;
        }
        const criteria = this.props.criteria;

        // console.log(criteria,'Criteria01')
        const open = Boolean(this.state.anchorEl);
        var isEditable = false;
        var editable = true;

        if (criteria.is_mandatory || !criteria.is_editable) {
            editable = false;
        }

        if (this.props.user?.Role === "OrgAdmin" || this.props.evaluation?.UserID === this.props.user?.ID) {
            isEditable = true
            editable = true;
        }

        if (!this.props.isEditable) {
            isEditable = false
            editable = false;
        }

        var scheduleText = "No Schedule";
        if (criteria.schedule !== null) {
            scheduleText = moment(criteria.schedule).format("MMM Do YYYY")
        }

        var showRule = ['/app/evaluate/:evaluation_id/setup'].indexOf(this.props?.match?.path) > -1 && this.props.user.Role === "OrgAdmin";

        let messageData = {
            head: "Are you sure?",
            subHead: "Do you really want to delete? This process cannot be undone.",
            firstBtn: "Cancel",
            secondBtn: "Yes",
        };

        let priorityText = '';

        if (criteria.priority !== undefined && criteria.priority !== null && criteria.priority === 0) {
            priorityText = 'No Weightage';
        }

        if (criteria.priority !== undefined && criteria.priority !== null && criteria.priority > 0) {
            priorityText = 'Weightage: ' + criteria.priority + '%';
        }

        return (
            <div className={!this.props.isPDfReport ? classes.root : classes.rootReport}>
                <Grid
                    container
                    alignItems="center"
                    onClick={() => {
                        if (this.props.expandedCriteriaId === criteria.id) {
                            this.props.expandEvaluationCriteria(null)
                        } else {
                            this.props.expandEvaluationCriteria(criteria.id)
                        }
                    }}
                    className={classnames(classes.criteriaHead, classes.criteriaExtended)}
                >

                    <Grid item style={{ paddingRight: 20 }}>
                        {!this.state.isEditName
                            ? (
                                <Typography className={classes.titleTxt}>
                                    {this.state.name || criteria.name}
                                    {criteria.is_mandatory && <font style={{ color: "red" }}>*</font>}
                                    {editable && (
                                        <Image style={{ marginLeft: 8, marginBottom: -4 }} onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                                isEditName: true
                                            })
                                        }} alt="Edit" src={editIcon} />
                                    )}
                                </Typography>
                            ) : (
                                <div className={classes.nameEditCotnainer}>
                                    <OutlinedInput
                                        fullWidth
                                        error={this.state.name_error.length > 0}
                                        helperText={this.state.name_error}
                                        value={this.state.name || criteria.name}
                                        onChange={(event) => {
                                            this.setState({ name: event.target.value });
                                        }}
                                    />
                                    <Grid container style={{ width: 'max-content' }} alignItems="center" className={classes.saveNameAction}>
                                        <div
                                            className={classes.actionIcon}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                this.cleanError()
                                                this.setState({ name: criteria.name, isEditName: false })
                                            }}
                                        >
                                            <ClearIcon className={classes.micon} />
                                        </div>
                                        <div
                                            className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                this.saveName(
                                                    this.state.name || criteria.name,
                                                    this.props.match.params?.evaluation_id,
                                                    criteria.id,
                                                )
                                            }}
                                        >
                                            <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                                        </div>
                                    </Grid>
                                </div>
                            )
                        }
                        <Grid container alignItems="center" style={{ marginTop: 7 }}>
                            <Typography className={classes.actionTxt}>{scheduleText}</Typography>
                            {!this.state.isDefaultPage && showRule && (
                                <Divider orientation="vertical" flexItem style={{ margin: '0px 10px' }} />
                            )}
                            {showRule && (
                                <Typography
                                    className={classes.actionTxt}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.showCriteriaRuleDialog({ rules: criteria?.rules || [], isEvaluation: true, criterias: this.props.criterias, criteria: criteria, criteriaId: criteria.id, id: this.props.match.params?.evaluation_id, requirements: criteria.requirements })
                                    }}
                                >
                                    {criteria?.rules?.length === 0 ? "Add Rule" : "Update Rule"}
                                </Typography>
                            )}
                            {this.props.enableScoring && <Divider orientation="vertical" flexItem style={{ margin: '0px 10px' }} />}
                            {this.props.enableScoring && <Typography className={classes.actionTxt}>{priorityText}</Typography>}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            <AddApprover
                                evaluationId={this.props.match.params.evaluation_id}
                                criteriaId={criteria.id}
                                criterias={this.props.criterias}
                                editable={editable}
                                position={'CriteriaHeadSetup'}
                                onAdd={(user) => {
                                    this.props.addCriteriaColloborator(
                                        this.props.match.params.evaluation_id,
                                        criteria.id,
                                        user.ID,
                                        user.isApprover)
                                }}
                                users={criteria.colloborator}
                            />
                            {editable && (
                                <div
                                    className={classes.menuSection}
                                    onClick={(e) =>
                                        this.deleteCriteriaItem(e, criteria.id)
                                    }
                                >
                                    <Image alt="Delete" src={deleteIcon} />
                                </div>
                            )}
                            {isEditable && (
                                <div className={classes.menuSection} onClick={this.handleClick}><MoreVertIcon /></div>
                            )}
                            <Popover
                                id="criteriaPopover"
                                open={open}
                                anchorEl={this.state.anchorEl}
                                onClose={(event) => this.handleClose(event)}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <div onClick={(e) => e.stopPropagation()}>
                                    <div className={classes.checkboxSection}>
                                        <Checkbox
                                            classes={{ root: classes.checkbox, checked: classes.checked }}
                                            checked={criteria?.is_mandatory}
                                            onChange={(event) => this.toggleMandatory(event)}
                                            disabled={!editable}
                                            name="Mandatory"
                                        />Required
                                    </div>
                                    <Divider />
                                    {criteria && !criteria?.is_mandatory && <div className={classes.checkboxSection}>
                                        <Checkbox
                                            classes={{ root: classes.checkbox, checked: classes.checked }}
                                            disabled={!editable || criteria.is_mandatory === true}
                                            checked={criteria?.is_editable}
                                            onChange={(event) => this.toggleEditable(event)}
                                            name="Editable"
                                        /> Editable
                                    </div>}
                                </div>
                            </Popover>
                        </Grid>
                    </Grid>
                </Grid>
                {criteria.requirements !== null &&
                    criteria.requirements.filter(o => o.hide ? false : true).map((requirement, index) => (
                        <Requirement
                            criteria={criteria}
                            requirement={requirement}
                            index={generate()}
                            reqIndex={index}
                            isOpen={true}
                            toggleRequirement={(id) => this.handleToggleRequirement(id)}
                            key={`requirement_${requirement?.id}`}
                            // key={`requirement_${criteria.id}_${requirement?.id}`}
                            editable={editable}
                            weighting_mechanism={this.props.weighting_mechanism}
                            isReport={this.props.isReport}
                            isPDfReport={this.props.isPDfReport}
                            enableScoring={this.props.enableScoring}
                        />
                    ))}
                <div className={classnames(classes.addRequirementAction, 'evaluate-add-requirement')}>
                    {this.props.criteria.name === 'Product Features' && isEditable && (
                        <div className={classes.hrLine}>
                            <span
                                onClick={() => {
                                    this.props.showFeatureDialog({ evaluationId: this.props.evaluationId, criteriaId: criteria.id })
                                }}
                            >+ Add new Feature</span>
                        </div>
                    )}
                    {this.props.criteria.name !== 'Product Features' && isEditable && (
                        <div className={classes.hrLine}>
                            <span
                                onClick={() => {
                                    this.props.showEvaluationRequirementAddDialog(this.props.evaluationId, criteria.id)
                                }}
                            >+ New Question</span>
                        </div>
                    )}
                </div>
                {this.state.isActiveDialog && <MessageDialog messageData={messageData} callBack={this.callBack} />}
            </div>
        )
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Criteria));