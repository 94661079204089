import {
    SHOW_ADD_SURVEY_DIALOG, HIDE_ADD_SURVEY_DIALOG,
    START_ADD_NEW_SURVEY, END_ADD_NEW_SURVEY,
    START_UPDATE_NEW_SURVEY, END_UPDATE_NEW_SURVEY,
    START_FETCH_COMPLETED_SURVEY, END_FETCH_COMPLETED_SURVEY,
    START_FETCH_INPROGESS_SURVEY, END_FETCH_INPROGESS_SURVEY,
    START_DELETE_SURVEY, END_DELETE_SURVEY,
    START_FETCH_SURVEY, END_FETCH_SURVEY,
    SHOW_UPDATE_SURVEY_DIALOG, HIDE_UPDATE_SURVEY_DIALOG,
    START_FETCH_COLLOBORATOR_STATUS, END_FETCH_COLLOBORATOR_STATUS,
    SHOW_COLLABORATOR_DETAILS_DIALOG, HIDE_COLLABORATOR_DETAILS_DIALOG,
    START_FETCH_PRODUCT_SURVEYS, END_FETCH_PRODUCT_SURVEYS,
    START_FETCH_COMPLETED_PRODUCT_SURVEYS, END_FETCH_COMPLETED_PRODUCT_SURVEYS
} from "./action";

const initState = {
    isShowAddSurveyDialog: false,
    addNewSurveyProgress: false,
    addNewSurveyError: null,
    updateSurveyProgress: false,
    updateSurveyError: null,
    surveyData: {},
    isNewSurvey: true,

    fetchInProgressSurveyProgress: false,
    fetchInProgressSurveyError: null,
    inProgressSurveys: [],
    inProgressSurveysTotal: 0,

    fetchCompletedSurveyProgress: false,
    fetchCompletedSurveyError: null,
    completedSurveys: [],
    completedSurveysTotal: 0,

    deleteSurveyProgress: true,
    deleteSurveyError: null,

    fetchSurveysProgress: false,
    fetchSurveysError: null,
    surveyDatas: [],

    isShowUpdateSurveyDialog: false,
    surveyId: null,

    fetchColloboratorStatusProgress: false,
    colloboratorStatus: [],

    isShowCollaboratorDetailsDialog: false,
    selectedCollaboratorId: null,
    selectedCollaboratorName: null,

    fetchProductSurveysProgress: false,
    productSurveys: [],

    fetchCompletedProductSurveysProgress: false,
    productCompletedSurveys : [],

    surveyForProduct:null
}

export function surveyReducer(state = initState, action) {
    switch (action.type) {
        case SHOW_ADD_SURVEY_DIALOG:
            console.log(action.payload)
            return {
                ...state,
                isShowAddSurveyDialog: true,
                isNewSurvey: action.payload.isNew,
                surveyData: action.payload.surveyData,
                surveyForProduct:action.payload.productId
            };

        case HIDE_ADD_SURVEY_DIALOG:
            return {
                ...state,
                isNewSurvey: true,
                surveyData: {},
                surveyForProduct:null,
                isShowAddSurveyDialog: false,
            };

        case SHOW_UPDATE_SURVEY_DIALOG:
            return {
                ...state,
                isShowUpdateSurveyDialog: true,
                surveyId: action.payload.surveyId
            };

        case HIDE_UPDATE_SURVEY_DIALOG:
            return {
                ...state,
                isShowUpdateSurveyDialog: false,
                surveyId: null
            };


        case SHOW_COLLABORATOR_DETAILS_DIALOG:
            return {
                ...state,
                isShowCollaboratorDetailsDialog: true,
                selectedCollaboratorId: action.payload.userId,
                selectedCollaboratorName: action.payload.userName
            };

        case HIDE_COLLABORATOR_DETAILS_DIALOG:
            return {
                ...state,
                isShowCollaboratorDetailsDialog: false,
                selectedCollaboratorId: null,
                selectedCollaboratorName: null
            };

        case START_FETCH_SURVEY:
            return {
                ...state,
                fetchSurveysProgress: true,
                fetchSurveysError: null,
                surveyDatas: []
            };

        case END_FETCH_SURVEY:
            return {
                ...state,
                fetchSurveysProgress: false,
                fetchSurveysError: action.payload.error,
                surveyDatas: action.payload.success
            };

        case START_ADD_NEW_SURVEY:
            return {
                ...state,
                addNewSurveyProgress: true,
                addNewSurveyError: null,
                surveyData: {}
            };

        case END_ADD_NEW_SURVEY:
            return {
                ...state,
                addNewSurveyProgress: false,
                addNewSurveyError: action.payload.error,
                surveyData: action.payload.success
            };

        case START_UPDATE_NEW_SURVEY:
            return {
                ...state,
                updateSurveyProgress: true,
                updateSurveyError: null,
                surveyData: {}
            };

        case END_UPDATE_NEW_SURVEY:
            return {
                ...state,
                updateSurveyProgress: false,
                updateSurveyError: action.payload.error,
                surveyData: action.payload.success
            };

        case START_DELETE_SURVEY:
            return {
                ...state,
                deleteSurveyProgress: true,
                deleteSurveyError: null,
            };

        case END_DELETE_SURVEY:
            return {
                ...state,
                deleteSurveyProgress: false,
                deleteSurveyError: action.payload.error,
            };

        case START_FETCH_INPROGESS_SURVEY:
            return {
                ...state,
                fetchInProgressSurveyProgress: true,
                fetchInProgressSurveyError: null,
                inProgressSurveys: []
            };

        case END_FETCH_INPROGESS_SURVEY:
            var surveys_inprogress = {
                Data: [],
                total: 0
            };
            var response = action.payload.success;
            if (response !== null) {
                surveys_inprogress.total = response.total;
                surveys_inprogress.Data = response.Data
            }
            return {
                ...state,
                fetchInProgressSurveyProgress: false,
                fetchInProgressSurveyError: action.payload.error,
                inProgressSurveys: surveys_inprogress.Data,
                inProgressSurveysTotal: surveys_inprogress.total
            };

        case START_FETCH_COMPLETED_SURVEY:
            return {
                ...state,
                fetchCompletedSurveyProgress: true,
                fetchCompletedSurveyError: null,
                completedSurveys: []
            };

        case END_FETCH_COMPLETED_SURVEY:
            var surveys_completed = {
                Data: [],
                total: 0
            };
            var response_completed = action.payload.success;
            if (response_completed !== null) {
                surveys_completed.total = response_completed.total;
                surveys_completed.Data = response_completed.Data;
            }
            return {
                ...state,
                fetchCompletedSurveyProgress: false,
                fetchCompletedSurveyError: action.payload.error,
                completedSurveys: surveys_completed.Data,
                completedSurveysTotal: surveys_completed.total
            };

        case START_FETCH_COLLOBORATOR_STATUS:
            return {
                ...state,
                fetchColloboratorStatusProgress: true,
                colloboratorStatus: []
            }

        case END_FETCH_COLLOBORATOR_STATUS:
            return {
                ...state,
                fetchColloboratorStatusProgress: false,
                colloboratorStatus: action.payload.success
            }

        case START_FETCH_PRODUCT_SURVEYS:
            return {
                ...state,
                fetchProductSurveysProgress: true,
            }

        case END_FETCH_PRODUCT_SURVEYS:
            let productSurveys = state.productSurveys;
            console.log(action.payload.success,'Successsssss-1')
            if (action.payload.error === null) {
                if (action.payload.success.page === 0) {
                    productSurveys = action.payload.success
                } else {
                    productSurveys.Data = [...productSurveys.Data, ...action.payload.success.Data]
                }
            }
            return {
                ...state,
                fetchProductSurveysProgress: false,
                productSurveys: action.payload.success
            }
            
        case START_FETCH_COMPLETED_PRODUCT_SURVEYS:
            return {
                ...state,
                fetchCompletedProductSurveysProgress: true,
            }

        case END_FETCH_COMPLETED_PRODUCT_SURVEYS:
            let productCompletedSurveys = state.productCompletedSurveys;
            console.log(action.payload.success,'Successsssss-2')
            if (action.payload.error === null) {
                if (action.payload.success.page === 0) {
                    productCompletedSurveys = action.payload.success
                } else {
                    productCompletedSurveys.Data = [...productCompletedSurveys.Data, ...action.payload.success.Data]
                }
            }
            return {
                ...state,
                fetchCompletedProductSurveysProgress: false,
                productCompletedSurveys: productCompletedSurveys
            }

        default:
            return state;
    }
}