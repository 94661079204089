import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import AppBarV2 from "Components/Application/AppBarV2";
import DefaultImage from "assets/images/evaluation/default.svg";
import AddVendorButton from "Components/Common/AddVendorButton";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import SearchInput from "Components/Common/Input/SearchInput";
import classnames from "classnames";
import { matchPath } from "react-router-dom";
import { storeSearchString } from "redux/applications/action";
// redux
import { showAddProductManuallyDialog } from "redux/productsSettings/action";

import { updateDrawerStyle } from "redux/drawer/action";
import { fetchServices, fetchAllVendorServices } from "redux/vendor/service/action";
import { showSnackBar } from "redux/snackbar/action";
import viewMoreImage from "assets/images/icons/viewMore.svg";
// Dilogs
import Breadcrumb from "Components/Common/Breadcrumb";
import ProductTypeFilter from "Components/Common/Filters/ProductTypeFilter";
import AddVendorOptionDialog from "Components/Common/Dialogs/AddVendorOptionDialog";
import { createEvaluationCart, removeProductFromCart, fetchEvaluationCart } from "redux/evaluation/cart/action";
import { fetchVendorServiceCatalog } from "redux/vendor/vendorServiceCatalog/action";

const connectedProps = (state) => ({
  userRole: state.authUser.user?.Role,
  searchString: state.applications.searchString,
  searchType: state.applications.searchType,
  fetchServiceProgress: state.services.fetchServiceProgress,
  fetchServiceError: state.services.fetchServiceError,
  services: state.services.servicesData,
  fetchAllVendorServiceProgress: state.services.fetchAllVendorServiceProgress,
  fetchAllVendorServiceError: state.services.fetchAllVendorServiceError,
  allVendorServices: state.services.allVendorServices,
  VendorServiceCatalog: state.vendorServiceCatalog.VendorServiceCatalog,
  addVendorDialogOpen: state.vendorCatalog.addVendorDialogOpen,
  updateCatalogStateProgress: state.vendors.updateCatalogStateProgress,
  updateCatalogStateError: state.vendors.updateCatalogStateError,
  updateOrgCatalogStateProgress: state.vendorCatalog.updateOrgCatalogStateProgress,
  updateOrgCatalogStateError: state.vendorCatalog.updateOrgCatalogStateError,
  evaluationCart: state.evaluationCart.evaluation,
});

const connectionActions = {
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  updateDrawerStyle: updateDrawerStyle,
  showSnackBar: showSnackBar,
  storeSearchString: storeSearchString,
  fetchServices: fetchServices,
  fetchVendorServiceCatalog: fetchVendorServiceCatalog,
  fetchAllVendorServices: fetchAllVendorServices,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      paddingTop: 0,
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
    },
    productContainer: {
      maxHeight: "calc(100vh - 230px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 230px)",
      padding: theme.spacing(4, 0),
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    label: {
      fontSize: "1.4em",
      fontWeight: 600,
      lineHeight: "1.6",
      marginBottom: 10,
    },
    serviceSidebar: {
      minWidth: "270px",
      maxWidth: "270px",
      width: "100%",
      padding: "2em 0em 2em 1.4em",
      background: "#FFFFFF",
      boxShadow: "0px 2px 10px #0000000b",
    },
    allService: {
      height: "calc(100vh - 50px)",
      overflowY: "overlay",
    },
    innerText: {
      color: "#696B6E",
      padding: theme.spacing(1, 1.5, 1, 1),
      cursor: "pointer",
      display: "block",
      margin: 0,
    },
    activeService: {
      color: "#4A87F8 !important",
      background: "#F2F7FF",
      fontSize: 13,
      marginRight: "1.6em !important",
    },
  });

class AllServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchObj: {
        text: "",
        page: 0,
        page_size: 60,
        filters: {},
      },
      filterServiceId: "",
      productType: "SERVICE",
      lastLocation: this.props.lastLocation,
      searchRestored: false,
      headerSectionWidth: null,
      selectedState: [],
    };
  }

  componentDidMount() {
    this.fetchData();
    this.props.fetchAllVendorServices({query: '',page: 0, page_size: 1000,filters: {}});
  }

  componentDidUpdate(prevProps) {
    const { lastLocation } = this.state;
    var pathName = lastLocation?.pathname;
    var isMatched = false;
    if (pathName !== undefined) {
      if (
        matchPath(pathName, {
          path: "/app/vendor/vendor-details/:id",
          exact: true,
          strict: false,
        })
      ) {
        isMatched = true;
      }
    }

    if (isMatched && pathName && this.props.searchString !== "" && this.state.searchRestored === false) {
      this.setState(
        {
          searchObj: { ...this.state.searchObj, text: prevProps.searchString },
          searchRestored: true,
        },
        () => {
          this.fetchData();
        }
      );
    }

    if (!this.props.updateCatalogStateProgress && prevProps.updateCatalogStateProgress) {
      if (this.props.updateCatalogStateError) {
        this.props.showSnackBar("Failed to update", "error", 3000);
      } else {
        this.props.showSnackBar("Updated successfully", "success", 3000);
        this.setState(
          {
            searchObj: { ...this.state.searchObj, page_size: 60, page: 0 },
          },
          () => {
            this.fetchData();
          }
        );
      }
    }

    if (!this.props.updateOrgCatalogStateProgress && prevProps.updateOrgCatalogStateProgress) {
      if (this.props.updateOrgCatalogStateError) {
        this.props.showSnackBar("Failed to update", "error", 3000);
      } else {
        this.props.showSnackBar("Updated successfully", "success", 3000);
        this.setState(
          {
            searchObj: { ...this.state.searchObj, page_size: 60, page: 0 },
          },
          () => {
            this.fetchData();
          }
        );
      }
    }

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    const headerSectionWidth = this.divElement.clientWidth;
    if (this.state.headerSectionWidth !== headerSectionWidth) {
      this.setState({ headerSectionWidth });
    }
  };

  handleRedirect = (type) => {
    if (type === "VENDOR") {
      this.props.history.push(`/app/vendors/all-vendors`);
    } else if (type === "SOFTWARE") {
      this.props.history.push(`/app/products/all-products`);
    } else if (type === "HARDWARE") {
      this.props.history.push(`/app/vendors/hardwares`);
    } else if (type === "SERVICE") {
      this.props.history.push(`/app/vendors/services`);
    }
  };

  fetchData() {
    const { searchObj } = this.state;
    let queryParams = {
      query: searchObj.text,
      page_size: searchObj.page_size,
      page: searchObj.page,
      filters: searchObj.filters || {},
    };
    queryParams.filters.states = this.state.selectedState;
    
    this.props.fetchVendorServiceCatalog({...queryParams,filters: {...queryParams.filters,is_org: '1'}})
    this.props.fetchServices({...queryParams,filters: {...queryParams.filters,is_master: '1'}});
  }

  productStates = (arr) => {
    if (arr) {
      const data = arr.map((state) => ({
        StateName: state.name,
        StateID: state.name,
      }));
      return data;
    } else {
      return [];
    }
  };

  handleOnClickState = (newState) => {
    let selectedState = [...this.state.selectedState];
    if (selectedState.includes(newState)) {
      selectedState = selectedState.filter((state) => state !== newState);
    } else {
      selectedState.push(newState);
    }
    this.setState(
      {
        selectedState,
        searchObj: { ...this.state.searchObj, page_size: 60, page: 0 },
      },
      () => {
        this.fetchData();
      }
    );
  };

  addToCart(id , type='MASTER_VENDOR_SERVICE') {
    console.log(id, "ServiceId");
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(id, null, type);
    } else {
      var addedToCart = this.props.evaluationCart === null ? [] : this.props.evaluationCart?.Products?.filter((p) => p.EntityID === id);
      if (addedToCart && addedToCart.length > 0) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, addedToCart[0].EntityObjectID);
      } else {
        this.props.createEvaluationCart(id, this.props.evaluationCart.ID, type);
      }
    }
  }

  render() {
    const { classes, services, VendorServiceCatalog, fetchServiceProgress } = this.props;
    const { searchObj, headerSectionWidth } = this.state;
    const totalCount = this.props.services?.total || 0;

    let spliter = 5;
    if (headerSectionWidth < 768) spliter = 2;
    if (headerSectionWidth >= 768 && headerSectionWidth < 1280) spliter = 5;

    var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.map((p) => p.EntityID);

    return (
      <>
        <AppBarV2 title={"Expent Service Directory"} withBack={true} dropdown={true} />
        <div style={{ display: "flex" }}>
          <div className={classes.serviceSidebar}>
            <div className={classes.allService}>
              <p
                className={classnames(classes.innerText, this.state.filterServiceId === "" ? classes.activeService : "")}
                onClick={() => {
                  this.setState({ searchObj: { page: 0, page_size: 60, text: "", filters: {} }, filterServiceId: "" }, () => this.fetchData());
                }}
              >
                <b>All</b>
              </p>
              {this.props.allVendorServices &&
                this.props.allVendorServices?.items?.map((service, index) => (
                  <p
                    key={index}
                    className={classnames(classes.innerText, this.state.filterServiceId === service.id ? classes.activeService : "")}
                    onClick={() => {
                      if (service.is_existing) {
                        this.setState({ filterServiceId: service.id, searchObj: { page: 0, page_size: 60, text: "", filters: { org_service_id: service.org_service_id } } }, () => this.fetchData());
                      } else {
                        this.setState({ filterServiceId: service.id, searchObj: { page: 0, page_size: 60, text: "", filters: { master_service_id: service.master_service_id } } }, () =>
                          this.fetchData()
                        );
                      }
                    }}
                  >
                    {service.name}
                  </p>
                ))}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Breadcrumb routeList={[{ name: "Services" }]} />
            <div className={classes.root}>
              <div className={classes.stickyBar}>
                <Grid
                  container
                  justifyContent="space-between"
                  ref={(divElement) => {
                    this.divElement = divElement;
                  }}
                  className={classes.productSectionNew}
                >
                  <Grid item>
                    <p className={classes.productCount}>
                      {totalCount.toLocaleString("en-US")}
                      {totalCount > 1 ? " Services" : " Service"} found
                    </p>
                  </Grid>
                  <Grid item>
                    <ProductTypeFilter
                      type={this.state.productType}
                      hideHardware
                      onChange={(type) => {
                        this.handleRedirect(type);
                      }}
                    />
                    <SearchInput
                      roundedCorner
                      showTypes={false}
                      isFilter={false}
                      placeholder="Search Services"
                      selectedState={this.state.selectedState}
                      searchObj={this.state.searchObj}
                      onChange={(searchObj) => {
                        this.setState({ searchObj: { ...this.state.searchObj, page_size: 20, text: searchObj.text, page: 0 } }, () => {
                          this.props.storeSearchString(searchObj.text);
                          this.fetchData();
                        });
                      }}
                      onChangeState={(newState) => {
                        this.handleOnClickState(newState);
                      }}
                      showState
                      type="vendor"
                    />
                  </Grid>
                  <Grid item>
                    <AddVendorButton title={"Add Service"} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.productContainer} id="productContainer">
                {services?.data?.length === 0 && !fetchServiceProgress && (
                  <Typography align="center">
                    <span>Not Found</span>
                  </Typography>
                )}

                {this.state.searchObj.page === 0 && fetchServiceProgress ? (
                  <Grid container justify="center" style={{ marginTop: 30 }}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <InfiniteScroll
                    scrollableTarget="productContainer"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                      marginRight: 20,
                    }}
                    dataLength={services?.items?.length || 0}
                    next={() => {
                      this.setState(
                        {
                          searchObj: { ...searchObj, page: searchObj.page + 1 },
                          isScroll: true,
                        },
                        () => {
                          this.fetchData();
                        }
                      );
                    }}
                    hasMore={fetchServiceProgress ? false : totalCount > services?.items?.length}
                    endMessage={""}
                  >
                    {VendorServiceCatalog?.items && VendorServiceCatalog?.items.length > 0 && (
                      <>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Typography className={classes.label}>Existing services</Typography>
                        </Grid>
                        <Grid container spacing={3} style={{ marginBottom: 10 }}>
                          {VendorServiceCatalog?.items.slice(0, 5).map((service, index) => (
                            <Grid item lg={2} md={3} sm={4} xs={6} key={"orgservice" + index}>
                              <LayeredCard
                                title={this.state.filterServiceId === "" ? service.service_name : service.vendor_name}
                                numLayer={0}
                                subTitle={this.state.filterServiceId === "" ? "By " + service.vendor_name : ""}
                                cardType={"vendorservice"}
                                isActionButtons
                                productStates={this.productStates(service.states)}
                                onClickState={(state) => this.handleOnClickState(state)}
                                image={service.vendor_logo == null || service.vendor_logo.length === 0 ? DefaultImage : service.vendor_logo}
                                onClick={() => {
                                  // this.props.history.push(`/app/vendors/org-service-details/${service.id}`);
                                  if (service.master_vendor_id === "None" || service.master_service_id === "None" || !service.master_vendor_id || !service.master_service_id) return;
                                  this.props.history.push(`/app/vendors/${service.master_vendor_id}/service/${service.id}`);
                                }}
                                isExisting={true}
                                id={parseInt(service.id)}
                                org_vendorservice_id={service.org_vendorservice_id}
                                master_vendor_id={service.master_vendor_id}
                                master_service_id={service.master_service_id}
                                customRoute={`/app/vendors/org-service-details/${service.id}`}
                                showCheckbox
                                checked={addedToCart.indexOf(service.org_vendorservice_id + "-" + service.org_service_id) !== -1}
                                toogleCheck={(e) => {
                                  let serviceId = service.org_vendorservice_id + "-" + service.org_service_id;
                                  this.addToCart(serviceId,'CUSTOM_VENDOR_SERVICE');
                                }}
                              />
                            </Grid>
                          ))}
                          {VendorServiceCatalog?.items && VendorServiceCatalog?.items.length > 5 && (
                            <Grid item lg={2} md={3} sm={4} xs={6} key={"org_product"}>
                              <LayeredCard
                                title={"View More"}
                                image={viewMoreImage}
                                subTitle=""
                                color="BLUE"
                                onClick={() => {
                                  this.props.history.push("/app/vendors/org-services");
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}

                    {services?.items && services?.items.length > 0 && (
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Typography className={classes.label}>Other services </Typography>
                      </Grid>
                    )}
                    <Grid container spacing={3}>
                      {services?.items &&
                        services?.items.map((service, index) => (
                          <Grid item lg={2} md={3} sm={4} xs={6} key={"otherservice" + index}>
                            <LayeredCard
                              title={this.state.filterServiceId === "" ? service.name : service.vendor_name}
                              numLayer={0}
                              subTitle={this.state.filterServiceId === "" ? "By " + service.vendor_name : ""}
                              cardType={"vendorservice"}
                              isActionButtons
                              productStates={this.productStates(service.states)}
                              onClickState={(state) => this.handleOnClickState(state)}
                              image={service.vendor_logo == null || service.vendor_logo.length === 0 ? DefaultImage : service.vendor_logo}
                              onClick={() => {
                                if (service.master_vendor_id === "None" || service.master_service_id === "None" || !service.master_vendor_id || !service.master_service_id) return;
                                this.props.history.push(`/app/vendors/${service.master_vendor_id}/service/${service.id}`);
                              }}
                              showCheckbox
                              isExisting={false}
                              id={parseInt(service.id)}
                              org_vendorservice_id={service.org_vendorservice_id}
                              master_vendor_id={service.master_vendor_id}
                              master_service_id={service.master_service_id}
                              customRoute={`/app/vendors/org-service-details/${service.id}`}
                              checked={addedToCart.indexOf(service.master_vendor_id + "-" + service.master_service_id) !== -1}
                              toogleCheck={(e) => {
                                let serviceId = service.master_vendor_id + "-" + service.master_service_id;
                                this.addToCart(serviceId);
                              }}
                            />
                          </Grid>
                        ))}
                    </Grid>
                    {fetchServiceProgress && (
                      <Grid container justify="center" style={{ marginTop: 30 }}>
                        <CircularProgress />
                      </Grid>
                    )}
                  </InfiniteScroll>
                )}
              </div>
            </div>
          </div>
          {this.props.addVendorDialogOpen && <AddVendorOptionDialog type={"service"} />}
        </div>
      </>
    );
  }
}

AllServices.defaultProps = {
  isSeller: false,
};

export default connector(compose(withStyles(styles), withLastLocation)(AllServices));
