import {
  START_FETCH_INTEGRATION_STATUS,END_FETCH_INTEGRATION_STATUS,
  START_ENABLE_QS_INTEGRATION,END_ENABLE_QS_INTEGRATION,
  START_FETCH_QS_USER_ROLES,END_FETCH_QS_USER_ROLES,
  START_USER_ROLE_ASSOCIATION,END_USER_ROLE_ASSOCIATION,
  START_FETCH_EMBEDABLE_LINK,END_FETCH_EMBEDABLE_LINK
} from "./action";

const initState   =     {
  fetchStatusProg:false,
  integrationStatus:null,
  updateIntegrationProg:false,
  updateIntegrationError:null,
  userRoles:[],
  roleAssociationProgress:false,
  embedProgress:false,
  embedURL:""
}

export  function quicksightReducer(state=initState,action){
  switch(action.type){
      case START_FETCH_INTEGRATION_STATUS:
          return {
              ...state,
              fetchStatusProg:true,
              integrationStatus:null
          };
      case END_FETCH_INTEGRATION_STATUS:
          return {
              ...state,
              fetchStatusProg:false,
              integrationStatus:action.payload.success
          }
      case START_ENABLE_QS_INTEGRATION:
        return {
            ...state,
            updateIntegrationProg:true,
            updateIntegrationError:null
        };
      case END_ENABLE_QS_INTEGRATION:
        return {
            ...state,
            updateIntegrationProg:false,
            updateIntegrationError:action.payload.error,
            integrationStatus:action.payload.success,
        };
      case START_FETCH_QS_USER_ROLES:
        return {
            ...state,
            userRoles:[],
        };
      case END_FETCH_QS_USER_ROLES:
        return {
            ...state,
            userRoles:action.payload.success,
        };
      case START_USER_ROLE_ASSOCIATION:
        return {
            ...state,
            roleAssociationProgress:true,
        };
      case END_USER_ROLE_ASSOCIATION:
        return {
            ...state,
            roleAssociationProgress:false,
        };
      case START_FETCH_EMBEDABLE_LINK:
        return {
          ...state,
          embedProgress:true,
          embedURL:""
        };
      case END_FETCH_EMBEDABLE_LINK:
        return {
          ...state,
          embedProgress:false,
          embedURL:action.payload.success.message
        };
      default:
          return state;
  }
}