export const approvalStages = [{
  name: 'Approve',
  isDefault: true,
  isChecked: true,
  id: 2,
}, {
  name: 'Reject',
  isDefault: true,
  isChecked: true,
  id: 4,
}, {
  name: 'Preliminary Approval',
  isDefault: false,
  isChecked: false,
  id: 1,
}, {
  name: 'Approve with exceptions',
  isDefault: false,
  isChecked: false,
  id: 3,
}]