import React from 'react';
import { connect } from "react-redux";
import classnames from "classnames";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { Node } from 'slate';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Button, FormControlLabel, Checkbox, Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import { submitEvaluationResponse, submitEvaluationDraftResponse } from "redux/seller/action"
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate";
import { CommonFn } from 'services/commonFn';

import UserGroup from "../Common/UserGroup"
import { showSnackBar } from "redux/snackbar/action";
import BottomActions from './BottomActions';

import Messages from "assets/images/add_message.svg"
import editIcon from "assets/images/edit_icon.svg";
import Image from 'Components/Common/image.jsx';

import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

import OutlinedActionInput from "Components/Common/Input/OutlinedActionInput";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";

import {
    fetchSellerRequirementColloborator,
    fetchEvaluationThread,
    addSellerRequirementResponse,
    setSelectedRequirement,
    fetchSellerRequirementTree,
    fetchEvaluationMeta,
    updateSellerDraftResponsesStatus,
    addBulkRequirementResponse
} from "redux/seller/action";

import CachedIcon from '@material-ui/icons/Cached';
import SqureButton from 'Components/Common/SqureButton';
import {
    ArrowForwardIos as ArrowForwardIcon,
    ArrowBackIos as ArrowBackIcon,
    Send as SendIcon
} from '@material-ui/icons';
import { Chat as ChatIcon } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import FinalizeRequiremntViewDialog from "../Common/FinalizeRequiremntViewDialog";

import { showMessageDialog, hideMessageDialog } from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";

import RequirementReply from 'Components/Common/FormReply/RequirementReply';
import * as Validator from "util/Validation";
import {
    showConfirmCallbackDialog,
    hideConfirmCallbackDialog
} from "redux/applications/action";

import ConfirmCallbackDialog from "Components/Common/ConfirmCallbackDialog";
import { addReqToSellerLibrary, removeReqFromSellerLibrary, checkRequirementInLibrary } from "redux/seller/evaluate/action"
import { getAllRequirements } from "util/Common"
const connectedProps = (state) => ({
    thread: state.seller.thread,
    requirement: state.seller.requirement,
    response: state.seller.response,
    threadFetchProgress: state.seller.threadFetchProgress,
    evaluation: state.seller.evaluation,
    fetchSellerReqColloboratorProgress: state.seller.fetchSellerReqColloboratorProgress,
    sellerRequirementColloborators: state.seller.sellerRequirementColloborators,
    addSellerReqColloboratorProgress: state.seller.addSellerReqColloboratorProgress,
    submitDraftResponseProgress: state.seller.submitDraftResponseProgress,
    updateSellerDraftResponsesProgress: state.seller.updateSellerDraftResponsesProgress,
    fetchLastUserResponseProgress: state.seller.fetchLastUserResponseProgress,
    requirementTree: state.seller.requirementTree,
    fetchRequirementTreeProgress: state.seller.fetchRequirementTreeProgress,
    selectedRequirementId: state.seller.selectedRequirementId,
    addSellerRequirementResponseProgress: state.seller.addSellerRequirementResponseProgress,

    addReqToSellerLibraryProgress: state.sellerEvaluate.addReqToSellerLibraryProgress,
    addReqToSellerLibraryError: state.sellerEvaluate.addReqToSellerLibraryError,
    sellerLibraryAddData: state.sellerEvaluate.sellerLibraryAddData,

    removeReqFromSellerLibraryProgress: state.sellerEvaluate.removeReqFromSellerLibraryProgress,
    removeReqFromSellerLibraryError: state.sellerEvaluate.removeReqFromSellerLibraryError,
    sellerLibraryRemoveData: state.sellerEvaluate.sellerLibraryRemoveData,

    checkReqInSellerLibraryProgress: state.sellerEvaluate.checkReqInSellerLibraryProgress,
    checkReqInSellerLibraryError: state.sellerEvaluate.checkReqInSellerLibraryError,
    checkReqData: state.sellerEvaluate.checkReqData,

    questionSearchProgress: state.sellerEvaluate.questionSearchProgress,
    questionSearchError: state.sellerEvaluate.questionSearchError,
    questionSearchData: state.sellerEvaluate.questionSearchData,

});

const connectionActions = {
    submitEvaluationResponse: submitEvaluationResponse,
    submitEvaluationDraftResponse: submitEvaluationDraftResponse,
    fetchSellerRequirementColloborator: fetchSellerRequirementColloborator,
    fetchEvaluationThread: fetchEvaluationThread,
    addSellerRequirementResponse: addSellerRequirementResponse,
    setSelectedRequirement: setSelectedRequirement,
    fetchSellerRequirementTree: fetchSellerRequirementTree,
    fetchEvaluationMeta: fetchEvaluationMeta,
    showMessageDialog: showMessageDialog,
    hideMessageDialog: hideMessageDialog,
    showSnackBar: showSnackBar,
    showConfirmCallbackDialog: showConfirmCallbackDialog,
    hideConfirmCallbackDialog: hideConfirmCallbackDialog,
    addReqToSellerLibrary: addReqToSellerLibrary,
    removeReqFromSellerLibrary: removeReqFromSellerLibrary,
    checkRequirementInLibrary: checkRequirementInLibrary,
    updateSellerDraftResponsesStatus: updateSellerDraftResponsesStatus,
    addBulkRequirementResponse: addBulkRequirementResponse
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        padding: '20px'
    },
    form: {
        alignItems: "center",
        paddingTop: theme.spacing(1),
        flexDirection: 'column',
        "& [class*='editableField']": {
            minHeight: 'auto !important',
        },
    },
    thread: {
        // minHeight: "10vh"
    },
    response: {
        // background:"#f7f7f7",
        // borderRadius:theme.spacing(1),
        // padding:theme.spacing(1),
        // marginBottom:theme.spacing(1),
    },
    username: {
        fontWeight: "600",
        display: 'flex',
        placeContent: 'center space-between',
        alignItems: 'center',
        fontSize: 14,
    },
    username1: {
        fontWeight: "600",
        display: 'flex',
        placeContent: 'center space-between',
        alignItems: 'center',
        fontSize: 16,
        marginBottom: 10
    },
    responseTime: {
        fontSize: 14,
        marginTop: 1
    },
    sender: {
        width: '100%',
        marginBottom: 20,
        '& .messageHeader': {
            display: 'flex',
            fontSize: 14,
            // textTransform: 'capitalize',
            '& span': {
                marginLeft: '10px'
            }
        },
        '& .messageContainer': {
            background: '#F3F4F5 0% 0% no-repeat padding-box',
            border: '1px solid #D5D8DC',
            maxWidth: '60%',
            textAlign: 'left',
            fontSize: '14px',
            letterSpacing: '0px',
            color: '#1C2833',
            borderRadius: '18px !important',
            'border-top-left-radius': '0 !important',
            padding: '0px 16px',
            minWidth: 320
        }
    },
    refreshIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: 5,
        top: 10,
        color: '#999999',
        transitionDuration: '5s',
        transitionProperty: 'transform',
        zIndex: 1000
    },
    underprogress: {
        transform: 'rotate(360deg)',
        '-webkit-transform': 'rotate(360deg) infinite linear',
    },
    receiver: {
        width: '100%',
        marginBottom: 20,
        height: '100%',
        // flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        placeContent: 'flex-end space-between',
        alignItems: 'flex-end',
        '& .messageHeader': {
            display: 'flex',
            // textTransform: 'capitalize',
            '& span': {
                marginLeft: '10px'
            }
        },
        '& .messageContainer': {
            background: '#4b86f7 0% 0% no-repeat padding-box',
            // border: '1px solid #1C2833',
            maxWidth: '60%',
            textAlign: 'left',
            fontSize: '14px',
            letterSpacing: '0px',
            color: '#ffffff',
            // float: 'right',
            borderRadius: '18px !important',
            'border-top-right-radius': '0 !important',
            padding: '0px 16px',
            minWidth: 320,
            "&  *": {
                color: '#ffffff !important',
            }
        }
    },
    chatStart: {
        // marginTop: '70px'
    },
    mainLoader: {
        position: 'fixed',
        left: 'calc(50vw - 34px)',
        top: 'calc(50vh - 36px)'
    },
    appBar: {
        background: '#fff',
        padding: theme.spacing(1, 2),
        boxShadow: 'none',
        borderBottom: '2px solid #f1f1f1',
        marginBottom: 20
    },
    draftBtn: {
        marginRight: 10,
        color: '#707070',
        borderColor: '#707070',
    },
    informationBox: {
        backgroundColor: 'hsl(196deg 72% 93%)',
        border: '2px solid hsl(187deg 55% 82%)',
        padding: theme.spacing(1, 1),
        borderRadius: theme.spacing(1.25),
        marginBottom: theme.spacing(2)
    },
    answerBox: {
        backgroundColor: '#fff',
        marginTop: theme.spacing(2),
        borderBottom: '1px solid #f1f1f1',
    },
    tabPanelRes: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
        marginBottom: 20,
        position: 'relative',
        // minHeight: theme.spacing(34),
        '& [class*=MuiBox-root]': {
            padding: '1.5em 1.5em 1.5em 1.5em'
        },
        '& [class*=makeStyles-label]': {
            display: 'none'
        }
    },
    addText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80px',
        fontSize: 13,
        color: '#707070',
        cursor: 'pointer',
        transition: 'all linear .5s',
    },
    addTextimage: {
        marginRight: 10,
        width: 21,
        position: 'relative',
        top: 2
    },
    noteSection: {
        width: 'calc(100% - 7px)',
        "& [class*='editableField']": {
            maxHeight: '105px !important;',
            minHeight: '105px !important',
        },
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    respoRoot: {
        // background: '#fff',
        paddingBottom: theme.spacing(2),
        position: 'relative',
        // padding: theme.spacing(2),
    },
    collUsers: {
        position: 'relative'
    },
    editIcon: {
        color: "#999999",
        marginTop: 6,
        marginLeft: 17,
        marginRight: 15,
        cursor: 'pointer',
        position: 'absolute',
        right: '-10px',
        top: '10px',
    },
    showNoteSection: {
        width: 'calc(100% - 7px)',
        marginTop: 20,
        "& [class*='editableField']": {
            margin: '0px !important',
            minHeight: 'auto !important',
            maxHeight: 'auto !important',
        }
    },
    rNote: {
        height: '100%',
        minHeight: '25px',
        fontSize: 13,
        color: '#707070',
        width: '100%',
        // margin: '0px auto',
        overflow: 'overlay'
        // textAlign:"center",
        // padding:theme.spacing(4)
    },
    requirementResponseDiv: {
        // marginBottom: 25,
        // border: '1px solid #f1f1f1',
        // padding: 10
    },
    actionBtns: {
        textAlign: 'right',
    },
    resActionBtns: {
        marginTop: 20
    },
    checkBox: {
        // color : 'red'
    },
    chatWithBuyerDiv: {
        textAlign: 'center'
    },
    buyerBtn: {
        color: '#4b86ff',
        backgroundColor: '#fff',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#4b86ff',
        }
    },
    currProductTitle: {
        marginBottom: theme.spacing(2),
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    alertBox: {
        marginBottom: 10
    },
    nextPrevbtns: {
        marginTop: 5,
        textAlign: 'center'
    },
    nextBtn: {
        marginLeft: 10
    },
    arrowIcons: {
        '&:hover': {
            background: '#f1f1f1',
            cursor: 'pointer',
            padding: '2px',
            borderRadius: '4px'
        }
    }
});

class Chat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "",
            selectedRequirement: '',
            selectedTab: 0,
            editNote: false,
            note: '',
            response: null,
            responseError: '',
            checkOnce: true,
            hideChatScreen: true,
            markAsDone: false,
            markAsDoneResponse: false,
            isCheckForNextInNewTree: false,
            isAddedinLibrary: false,
            quesLibraryState: false,
            questionId: null
        }
        this.submit = this.submit.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.onBlurInput = this.onBlurInput.bind(this);
        this.changeNote = this.changeNote.bind(this);

        this.saveAsDraftReqResponse = this.saveAsDraftReqResponse.bind(this);
        this.submitReqResponse = this.submitReqResponse.bind(this);
        this.submitReqResponseChk = this.submitReqResponseChk.bind(this);
        this.callBack = this.callBack.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.callBackQuesnnaire = this.callBackQuesnnaire.bind(this);
        this.submitQuesnnaire = this.submitQuesnnaire.bind(this);

    }

    componentDidMount() {
        this.props.checkRequirementInLibrary("internal",this.props.selectedRequirementId,this.props.match?.params?.productId);
        this.getCurrentRequirtmentName();
        this.checkForDraftMessages();
        this.props.fetchSellerRequirementColloborator(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId);
        this.setState({
            markAsDone: this.props.isCompletedQuestionaire
        })
    }

    componentDidUpdate(preProps) {
        if (this.props.selectedRequirementId !== preProps.selectedRequirementId) {
            this.setState({
                message: "",
                editNote: false,
                note: '',
                response: null,
                responseError: "",
                hideChatScreen: true
            }, () => {
                this.getCurrentRequirtmentName();
                this.props.fetchSellerRequirementColloborator(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId);
                this.props.checkRequirementInLibrary("internal",this.props.selectedRequirementId,this.props.match?.params?.productId);
            })
        }

        if (this.props.addSellerReqColloboratorProgress === false && preProps.addSellerReqColloboratorProgress === true) {
            this.props.fetchSellerRequirementColloborator(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId);
            this.props.showSnackBar("Collaborator added successfully", "success", 3000);
        }

        if (this.props.threadFetchProgress === false && preProps.threadFetchProgress === true) {
            this.setState({
                response: this.props.response,
                // markAsDone: this.props.response !== null ? this.props.response?.IsDone : false,
                markAsDoneResponse: this.props.response !== null ? this.props.response?.IsDone : false,
            })
        }

        if (this.state.checkOnce && this.props.fetchSellerReqColloboratorProgress === false && preProps.fetchSellerReqColloboratorProgress === true) {
            this.setState({
                response: this.props.response,
                // markAsDone: this.props.response !== null ? this.props.response?.IsDone : false,
                markAsDoneResponse: this.props.response !== null ? this.props.response?.IsDone : false,
                checkOnce: false
            })
        }

        if (this.props.submitDraftResponseProgress === false && preProps.submitDraftResponseProgress === true) {
            this.props.showSnackBar("Save as draft successfully", "success", 3000);
        }

        if (this.props.updateSellerDraftResponsesProgress === false && preProps.updateSellerDraftResponsesProgress === true) {
            this.setState({
                message: ""
            })
        }

        if (this.props.addSellerRequirementResponseProgress === false && preProps.addSellerRequirementResponseProgress === true) {
            this.props.showSnackBar("Response submited successfully", "success", 3000);
            // var nextPrevData = this.getNextAndPreviousIds();
            // var nextReqId = nextPrevData.nextReqId;
            // if (nextReqId !== null) {
            //     this.props.setSelectedRequirement(nextReqId);
            // } else {
            this.setState({
                isCheckForNextInNewTree: true
            }, () => {
                this.props.fetchEvaluationMeta(this.props.match.params.uuid, this.props.match.params.org_id);
                this.props.fetchSellerRequirementTree(this.props.match.params.uuid, this.props.match.params.org_id);
            })
            // }
        }

        if (this.props.fetchRequirementTreeProgress === false && preProps.fetchRequirementTreeProgress === true) {
            if (this.state.isCheckForNextInNewTree) {
                var nextPreData = this.getNextAndPreviousIds();
                var nextId = nextPreData.nextReqId;
                if (nextId !== null) {
                    this.props.setSelectedRequirement(nextId);
                }
                this.setState({
                    isCheckForNextInNewTree: false
                })
            }
        }

        if (!this.props.checkReqInSellerLibraryProgress && preProps.checkReqInSellerLibraryProgress) {
            if (this.props.checkReqInSellerLibraryError === null) {
                // this.setState({
                //     isAddedinLibrary: this.props.checkReqData.is_available,
                //     quesLibraryState: this.props.checkReqData.is_available,
                //     questionId: this.props.checkReqData.question_id
                // })
            } else {
                console.warn('Something went wrong')
            }
        }

        if (!this.props.addReqToSellerLibraryProgress && preProps.addReqToSellerLibraryProgress) {
            if (this.props.addReqToSellerLibraryError === null) {
                this.props.checkRequirementInLibrary("internal",this.props.selectedRequirementId,this.props.match?.params?.productId);
                this.props.showSnackBar("Seller library updated successfully", "success", 3000);
            } else {
                console.warn('Something went wrong')
            }
        }
    }

    onBlurInput = (className) => {
        this.setState({
            editNote: false
        }, () => {
            try {
                const responseNote = document.querySelectorAll(`.response_note .${className}`)
                const rootRequirementHight = document.querySelectorAll(`.${className}`);
                if (responseNote.length === 0 && rootRequirementHight && rootRequirementHight.length) {
                    for (var i = 0; i < rootRequirementHight.length; i++) {
                        rootRequirementHight[i].classList.remove('rootRequirementHight');
                    }
                }
            } catch (e) {

            }
        });
    }

    changeNote = (e, response, className) => {
        if (this.props.isCompletedQuestionaire) {
            return;
        }
        //this.changeCardPosition(e);
        if (!this.state.editNote) {
            this.setState({ editNote: true, note: ((response !== null && response.Note) || '') }, () => {
                try {
                    const editHight = document.querySelectorAll(`.${className}`);
                    if (editHight && editHight.length) {
                        for (var i = 0; i < editHight.length; i++) {
                            editHight[i].classList.add('rootRequirementHight');
                        }
                    }
                } catch (e) {

                }
            });
        }
    }

    checkForDraftMessages() {
        var threadData = this.props.thread.filter(o => o.IsDraft === true);
        if (threadData !== null && threadData.length > 0) {
            this.setState({
                message: threadData[0].Response
            })
        }
    }

    getCurrentRequirtmentName() {
        this.props.evaluation.criterias.forEach((criteria, key) => {
            criteria.requirements.forEach((requirement) => {
                if (requirement.id === parseInt(this.props.selectedRequirementId)) {
                    this.setState({
                        selectedRequirement: requirement.name
                    })
                }
            })
        })
    }

    submit() {
        var body = this.state.message;
        var bodyText = document.querySelectorAll("#chatWithBuyerScreenMsg .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
        // console.log(bodyText,'bodyText1')
        if (String(body).trim().length === 0 || String(bodyText).trim().length === 0) {
            this.props.showSnackBar("Message should not be empty.", "error", 3000)
            return
        }

        this.props.submitEvaluationResponse(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId, this.state.message.trim());
        this.setState({ message: 0 })
    }

    saveAsDraft() {
        var body = this.state.note;
        var bodyText = document.querySelectorAll("#sellerSideResponseText .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
        // console.log(bodyText,'bodyText2')
        if (String(body).trim().length === 0 || String(bodyText).trim().length === 0) {
            this.props.showSnackBar("Message should not be empty.", "error", 3000)
            return
        }
        this.props.submitEvaluationDraftResponse(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId, this.state.message.trim());
        this.props.showSnackBar("Saved in draft successfully", "success", 3000);
    }

    saveAsDraftReqResponse() {
        const { requirement } = this.props;
        const { response } = this.state;

        this.setState({
            "responseError": ""
        });

        if (response === null) {
            this.setState({
                responseError: 'Add response before submit.'
            }, () => {
                setTimeout(() => {
                    this.setState({
                        responseError: ''
                    });
                }, 5000);
            })
            return;
        }

        var options = {};
        if (requirement?.AnswerType === 4 || requirement?.AnswerType === 5 || requirement?.AnswerType === 6) {
            options.answer = (response === null || response.SellerOptions === null || response.SellerOptions.length <= 0) ? '' : response.SellerOptions[0].Answer;
        } else if (requirement?.AnswerType === 2) {
            let addOther = (response === null || response.SellerOptions == null) ? [] : response?.SellerOptions.filter((item) => item?.Answer !== "");
            options.option_ids = (response === null || response.SellerOptions == null ? [] : response.SellerOptions.filter((item) => item.Answer === "").map((i) => {
                return i.OptionID
            }))
            if (addOther !== null && addOther.length > 0) {
                options.answer = addOther[0]?.Answer;
            }
        } else if (requirement?.AnswerType === 3) {
            let addOther = (response === null || response.SellerOptions == null) ? [] : response?.SellerOptions.filter((item) => item.Answer !== "");
            options.option_ids = (response === null || response.SellerOptions == null ? [] : response.SellerOptions.filter((item) => item.OptionID !== "").map((i) => {
                return i.OptionID
            }))
            if (addOther !== null && addOther.length > 0) {
                options.answer = addOther[0]?.Answer;
            }
        } else {
            options.option_ids = (response === null || response.SellerOptions == null ? [] : response.SellerOptions.map((i) => {
                return i.OptionID
            }))
        }

        this.props.addSellerRequirementResponse(
            this.props.match.params.uuid,
            this.props.match.params.org_id,
            requirement.RequirementID,
            {
                note: response === null ? null : response.Note,
                value: response === null ? null : response.Response,
                response_options: options,
                is_draft: true,
                is_done: this.state.markAsDone
            })

        this.setState({
            editNote: false
        })
    }

    submitReqResponseChk(e) {
        const { requirement } = this.props;
        const { response, markAsDone, editNote } = this.state;
        this.setState({
            "responseError": ""
        });

        if (editNote) {
            var body = this.state.note;
            var bodyText = document.querySelectorAll("#sellerSideResponseText .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
            if (String(body).trim().length === 0 || String(bodyText).trim().length === 0 || String(bodyText).indexOf('Add response here') !== -1) {
                this.props.showSnackBar("Message should not be empty.", "error", 3000)
                return
            }
        }

        if (response === null) {
            this.setState({
                responseError: 'Add response before submit.'
            }, () => {
                setTimeout(() => {
                    this.setState({
                        responseError: ''
                    });
                }, 5000);
            })
            return;
        }

        var checkAnswerType = [2, 3, 4, 5, 6];
        if (markAsDone && checkAnswerType.indexOf(requirement?.AnswerType) !== -1 && (response.SellerOptions === null || response.SellerOptions.length <= 0)) {
            this.setState({
                responseError: 'Add response before submit.'
            })
            return;
        }

        var msg = '';
        if ((requirement?.AnswerType === 4 || requirement?.AnswerType === 5) && (response === null || response.SellerOptions === null || response.SellerOptions.length <= 0 || response.SellerOptions[0]?.Answer === undefined || response.SellerOptions[0]?.Answer === "")) {
            var answerType = requirement?.AnswerType;
            if (answerType === 4) {
                msg = 'Please select Yes or No';
            } else {
                msg = 'Please select a score';
            }
            this.setState({
                responseError: msg
            })
            return;
        }

        let isValidationError = false;
        if ((requirement?.AnswerType === 8 || requirement?.AnswerType === 10) &&
            response !== null &&
            response.SellerOptions !== null &&
            response.SellerOptions.length > 0 &&
            response.SellerOptions[0]?.Answer !== undefined &&
            response.SellerOptions[0]?.Answer !== "") {
            var type = requirement?.AnswerType;
            let text_value = response.SellerOptions[0]?.Answer;
            if (type === 8 && Validator.validateEmail(text_value) === false) {
                isValidationError = true;
                msg = 'Please enter a valid Email.'
            } else if (type === 10 && Validator.validateURL(text_value) === false) {
                isValidationError = true;
                msg = 'Please enter a valid URL.'
            }
        }

        if (isValidationError) {
            this.setState({
                responseError: msg
            })
            return;
        }
        this.submitReqResponse();
    }

    submitReview(e) {
        e.stopPropagation();
        var me = this;
        me.props.showMessageDialog();
    }

    callBack = () => {
        this.props.hideMessageDialog();
    };

    submitReqResponse() {
        const { response, markAsDone } = this.state;
        const { requirement } = this.props;

        // console.log(response, 'response6666');

        this.setState({
            "responseError": ""
        });

        if (response === null) {
            this.setState({
                responseError: 'Add response before submit.'
            }, () => {
                setTimeout(() => {
                    this.setState({
                        responseError: ''
                    });
                }, 5000);
            })
            return;
        }
        var checkAnswerType = [2, 3, 4, 5, 6];
        if (markAsDone && checkAnswerType.indexOf(requirement?.AnswerType) !== -1 && (response.SellerOptions === null || response.SellerOptions.length <= 0)) {
            this.setState({
                responseError: 'Add response before submit.'
            })
            return;
        }

        var options = {};
        if ([4, 5, 6, 8, 9, 10, 11, 12].includes(requirement.AnswerType)) {
            options.answer = (response === null || response.SellerOptions === null || response.SellerOptions.length <= 0) ? '' : response.SellerOptions[0].Answer;
        } else if ([13].includes(requirement.AnswerType)) {
            options.answer = (response === null || response.SellerOptions === null || response.SellerOptions.length <= 0) ? '' : JSON.stringify(response.SellerOptions[0].Answer);
        } else if ([2, 3].includes(requirement.AnswerType)) {
            options.option_ids = (response === null || response.SellerOptions == null ? [] : response.SellerOptions.map((i) => {
                return i.OptionID
            }))
        } else if ([14, 15].includes(requirement.AnswerType)) {
            options.option_ids = (response === null || response.SellerOptions == null ? [] : response.SellerOptions.map((i) => {
                return i.OptionID
            }))
            if (response !== null && response.SellerOptions !== null) {
                let isOther = response?.SellerOptions.filter(o => o.Answer.indexOf('Other-') !== -1)[0];
                if (isOther !== undefined && isOther !== null && isOther !== '') {
                    options.answer = isOther.Answer;
                }
            }
        } else {
            options.option_ids = (response === null || response.SellerOptions == null ? [] : response.SellerOptions.map((i) => {
                return i.OptionID
            }))
        }


        console.log(this.state.isAddedinLibrary, 'this.state.isAddedinLibrary')
        console.log(this.state.quesLibraryState, 'this.state.quesLibraryState')

        let params = {
            note: response === null ? null : response.Note,
            value: response === null ? null : response.Response,
            response_options: options,
            is_draft: true,
            is_done: false,
        }

        if (this.state.isAddedinLibrary !== this.state.quesLibraryState) {
            this.addToLibrary(this.props.match.params.uuid, this.props.match.params.org_id, requirement.RequirementID, params);
        } else {
            this.props.addSellerRequirementResponse(
                this.props.match.params.uuid,
                this.props.match.params.org_id,
                requirement.RequirementID,
                params
            )
        }

        this.setState({
            editNote: false
        })
    }

    getNextAndPreviousIds() {
        var nextReqId = null;
        var previousReqId = null;

        var allRequirements = (this.props.requirementTree !== undefined && this.props.requirementTree !== null) ? [...this.props.requirementTree] : [];
        var requirementIds = [];
        var allItems = [];
        if (allRequirements !== null && allRequirements.length > 0) {
            requirementIds = getAllRequirements(this.props.requirementTree, allItems);
        }

        var currentReq = this.props.selectedRequirementId;

        var indexOfCurrent = requirementIds.indexOf(currentReq);
        var indexOfNext = null;
        var indexOfPrevious = null;

        if (indexOfCurrent !== (requirementIds.length - 1)) {
            indexOfNext = indexOfCurrent + 1;
            nextReqId = requirementIds[indexOfNext];
        }

        if (indexOfCurrent !== 0) {
            indexOfPrevious = indexOfCurrent - 1;
            previousReqId = requirementIds[indexOfPrevious];
        }

        return {
            nextReqId: nextReqId,
            previousReqId: previousReqId,
        }
    }

    getMaxLimit = (req) => {
        return parseInt(req?.character_limit || 100)
    }

    getNote = (req, note) => {
        let rt = note
        try {
            let newNote = JSON.parse(note)
            newNote = newNote.map(n => Node.string(n)).join(' ')
            rt = newNote
            try {
                const limit = parseInt(req?.character_limit || 0)
                if (limit > 0) {
                    rt = rt.substring(0, limit)
                }
            } catch (e) { }
        } catch (e) { }
        return rt
    }

    currentRequirement = () => {
        let requirement = {}
        if (this.props.evaluation?.criterias && Array.isArray(this.props.evaluation.criterias)) {
            this.props.evaluation.criterias[0].requirements.forEach((req) => {
                if (req.id === this.props.selectedRequirementId) {
                    requirement = req
                    return false
                } else {
                    return true
                }
            })
        } else {
            requirement = this.props.requirement
        }
        return requirement
    }

    submitQuesnnaire() {
        var numQuestions = 0;
        let requirementIds = getAllRequirements(this.props.requirementTree, []);
        numQuestions = requirementIds.length;
        console.log(this.props.draftRequirements, 'draftRequirements')
        if (numQuestions !== this.props.draftRequirements.length) {
            this.props.showMessageDialog();
            return;
        }

        this.props.showConfirmCallbackDialog();
    }

    onCloseDialog() {
        this.props.hideConfirmCallbackDialog();
    }

    callBackQuesnnaire() {
        console.log(this.props.draftRequirements, 'draftRequirements')
        if (this.props.draftRequirements && this.props.draftRequirements.length > 0) {
            this.props.updateSellerDraftResponsesStatus(this.props.match.params.uuid, this.props.match.params.org_id, {
                response_ids: this.props.draftRequirements,
                is_done: true
            })
        }
        // let requirementIds = getAllRequirements(this.props.requirementTree, []);
        // // console.log(requirementIds, 'requirementIds')
        // this.props.updateSellerDraftResponsesStatus(this.props.match.params.uuid, this.props.match.params.org_id, {
        //     response_ids: requirementIds,
        //     is_done: true
        // })
        this.props.hideConfirmCallbackDialog();
        this.props.showSnackBar("Questionnaire submited successfully", "success", 3000);
    }

    addToLibrary(uuid, org_id, req_id, res_params) {
        const { requirement } = this.props;
        var { response } = this.state;
        console.log(requirement, 'Requirementtttttttt')
        console.log(response, 'responsettttttttt')
        let actionType = this.state.isAddedinLibrary ? 'add' : 'delete'

        if (actionType === 'add') {
            this.props.addReqToSellerLibrary({
                "source": "internal",
                "action": "add",
                "buyer_org_id": this.props.match.params.org_id,
                "requirement_id": requirement?.RequirementID,
                "data": [{
                    "requirement_id":requirement?.RequirementID,
                    "product_id":this.props.match.params.productId,
                }]
            })
        } 

        this.props.addSellerRequirementResponse(
            uuid,
            org_id,
            req_id,
            res_params
        )
    }

    render() {
        const classes = this.props.classes;

        if (this.props.threadFetchProgress || this.props.fetchLastUserResponseProgress || this.props.fetchRequirementTreeProgress) {
            return <div className={classes.mainLoader}> <CircularProgress /> </div>
        }

        const { requirement } = this.props;
        const currentReq = this.currentRequirement()
        var { response, markAsDoneResponse } = this.state;
        var nextPrevData = this.getNextAndPreviousIds();
        var nextReqId = nextPrevData.nextReqId;
        var previousReqId = nextPrevData.previousReqId;

        // console.log(requirement, 'requirement555')

        let messageData = {
            head: "Stop!",
            subHead: "You have some unanswered questions. Please answer them to submit questionnaire.",
            firstBtn: "Close",
            secondBtn: "",
        };

        let messageDataQuestionaire = {
            head: "Are you sure?",
            subHead: "Do you really want to submit Questionnaire? This process cannot be undone.",
            firstBtn: "Cancel",
            secondBtn: "Yes",
        };


        let reqOptions = requirement?.Options;
        if (reqOptions !== undefined && reqOptions !== null && reqOptions !== '') {
            if (requirement.AnswerType === 2 || requirement.AnswerType === 3) {
                reqOptions = requirement?.Options.map((o) => {
                    return {
                        Description: o.Description,
                        ID: o.RefOptionId,
                        OrgID: o.OrgID,
                        RefOptionId: o.RefOptionId,
                        SellerRequirementID: o.SellerRequirementID,
                        Sequence: o.Sequence,
                        Title: o.Title,
                    }
                })
            }
        }

        // console.log(requirement, 'requirement_data')
        // console.log(response, 'response_data')

        return <div className={classes.root}>
            <div>
                <Box component="span" display="block" className={classes.informationBox}>
                    {true && <Typography className={classes.infoText}>
                        <SlateInputFieldV2
                            readOnly={true}
                            isToolBar={false}
                            placeholder=""
                            style={{
                                margin: 0,
                            }}
                            as={SlateInputField}
                            initValue={requirement?.Description}
                            formControlStyle={{ border: 'none', borderColor: 'hsl(196deg 72% 93%)', overflow: 'hidden' }}
                            textContainerStyle={{ minHeight: '100px !important', margin: "0px", background: "hsl(196deg 72% 93%)", color: "#707070", fontSize: 16 }} />
                    </Typography>}

                    <div className={classes.collUsers}>
                        <label>{(this.props.sellerRequirementColloborators !== null && this.props.sellerRequirementColloborators !== undefined && this.props.sellerRequirementColloborators.length > 0) ? "Members" : "Add members"}</label>
                        <UserGroup
                            editable={true}
                            selectedUser={[]}
                            existingUsers={this.props.sellerRequirementColloborators}
                            evaluationId={this.props.match.params.uuid}
                            position={'SecondaryBar'}
                            onAdd={(user) => {
                                console.log('Adding new ')
                            }}
                            users={this.props.sellerRequirementColloborators}
                            addUserType={'SELLER_REQ_COLLOBORATOR'}
                        />

                    </div>
                </Box>
            </div>

            <div className={classes.respoRoot}>
                <div className={classnames(classes.tabPanelRes)}>
                    <CachedIcon className={classnames(classes.refreshIcon, this.props.threadFetchProgress === true ? classes.underprogress : null)} onClick={() => {
                        this.props.fetchEvaluationThread(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId)
                    }} />
                    {requirement !== undefined && requirement !== null && <div className={classes.requirementResponseDiv}>
                        <Typography variant={"h6"} className={classes.currProductTitle}>
                            Your Response
                        </Typography>
                        {this.state.responseError && <Alert className={classes.alertBox} severity="error">{this.state.responseError}</Alert>}
                        {[2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].includes(requirement.AnswerType) && <RequirementReply
                            additional_data={reqOptions}
                            options={reqOptions}
                            type={requirement?.AnswerType}
                            title={requirement?.Name}
                            description={requirement?.Description}
                            isRequired={false}
                            key={requirement.ID + '_sectionrequirement'}
                            index={requirement.ID}
                            value={response !== undefined && response !== null && response.SellerOptions !== undefined && response.SellerOptions !== null ? response?.SellerOptions[0]?.Answer : ''}
                            selectedOptions={response !== undefined && response !== null && response.SellerOptions !== undefined && response.SellerOptions !== null ? response.SellerOptions : []}
                            evaluationId={this.props.match.params.uuid}
                            orgId={this.props.match.params.org_id}
                            workflowId={this.props.match.params.org_id}
                            isFormEditable={!this.props.isCompletedQuestionaire}
                            itemId={requirement.ID}
                            sectionId={requirement.ID}
                            item={requirement}
                            error={requirement.error || ''}
                            isShowSaveButton={false}
                            isUpdateOnChange={true}
                            from={'SELLER'}
                            isSummaryPage={false}
                            isOtherOption={requirement?.IsOtherOption}
                            onChange={(output, isOther = false) => {
                                console.log(response, 'Response')
                                console.log(output, 'output')
                                let parameters = {};
                                if ([1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(requirement.AnswerType)) {
                                    let answer;
                                    if (requirement.AnswerType === 5) {
                                        answer = String(output);
                                    } else if (requirement.AnswerType === 6) {
                                        answer = JSON.stringify(output);
                                    } else {
                                        answer = output;
                                    }
                                    parameters = {
                                        Note: response == null ? null : response.Note,
                                        Response: response == null ? null : response.Response,
                                        SellerOptions: [{
                                            Answer: answer
                                        }]
                                    }
                                }


                                if ([2, 3].includes(requirement.AnswerType)) {
                                    parameters = {
                                        Note: response == null ? null : response.Note,
                                        Response: response == null ? null : response.Response,
                                        SellerOptions: output.map((op) => { return { OptionID: op, Answer: '' } })
                                    }
                                }

                                if ([14].includes(requirement.AnswerType)) {
                                    console.log(isOther, 'Issother-----------------------')
                                    console.log(output, 'output')
                                    if (isOther) {
                                        parameters = {
                                            Note: response == null ? null : response.Note,
                                            Response: response == null ? null : response.Response,
                                            SellerOptions: [{
                                                Answer: output,
                                            }]
                                        };
                                    } else {
                                        let options = [{ OptionID: output, Answer: '' }]
                                        parameters = {
                                            Note: response == null ? null : response.Note,
                                            Response: response == null ? null : response.Response,
                                            SellerOptions: options
                                        };
                                    }
                                }

                                if ([15].includes(requirement.AnswerType)) {
                                    console.log(isOther, 'Issother-----------------------')
                                    console.log(output, 'output')
                                    if (isOther) {
                                        let otherOp = {
                                            Answer: output
                                        }
                                        let options = response == null ? [] : response.SellerOptions.filter(o => o.Answer === '' && o.OptionID !== 0);
                                        console.log(options, 'options')
                                        console.log(otherOp, 'otherOp')
                                        options.push(otherOp)
                                        console.log(options, 'Allllllllllll options')
                                        parameters = {
                                            Note: response == null ? null : response.Note,
                                            Response: response == null ? null : response.Response,
                                            SellerOptions: options
                                        };
                                    } else {
                                        let options = output.map((op) => { return { OptionID: op, Answer: '' } });
                                        if (response !== null && response.SellerOptions !== undefined && response.SellerOptions !== null) {
                                            let other = response?.SellerOptions.filter(o => o.Answer.indexOf('Other-') !== -1)[0];
                                            if (other !== undefined && other !== null && other !== '') {
                                                options.push(other);
                                            }
                                        }
                                        parameters = {
                                            Note: response == null ? null : response.Note,
                                            Response: response == null ? null : response.Response,
                                            SellerOptions: options
                                        };
                                    }
                                }

                                this.setState({
                                    response: parameters,
                                    responseError: ''
                                })

                            }}
                            updateLocalOnChange={(output) => {
                            }}
                            showUserAddDialog={(item) => {

                            }}
                            showTeamAddDialog={(data) => {

                            }}
                        />}

                        {this.state.editNote === false &&
                            <div style={{ position: 'relative', width: '100%', marginTop: 20, marginBottom: 20 }}>
                                <div className={classes.rNote} >
                                    {response && response !== null && response.Note !== null && response.Note !== '' ? (
                                        <>
                                            {requirement?.AnswerType === 7 ? (
                                                <p className={classes.note} onClick={(e) => {

                                                    e.stopPropagation()
                                                    this.changeNote(e, response, `requirement_${requirement?.ID}`)
                                                }}>{this.getNote(currentReq, response.Note)}</p>
                                            ) : (
                                                <div className={classnames(classes.showNoteSection)}>
                                                    <SlateInputField
                                                        readOnly={true}
                                                        isToolBar={false}
                                                        placeholder=""
                                                        style={{ margin: 0 }}
                                                        as={SlateInputField}
                                                        onChangeEvent={(value) => {
                                                            this.setState({
                                                                note: value
                                                            })
                                                        }}
                                                        initValue={response.Note}
                                                        formControlStyle={{ border: 'none' }}
                                                        textContainerStyle={{ color: "#707070", fontSize: 14 }} />
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {!markAsDoneResponse && <div className={classnames(classes.addText, 'response-addText', 'evaluate-add-text')} onClick={(e) => this.changeNote(e, response, `requirement_${requirement?.ID}`)}><img className={classes.addTextimage} alt="" src={Messages} />
                                                <span>{requirement?.AnswerType === 1 || requirement.AnswerType === 7 ? 'Add Text' : 'Add Comment'}</span>
                                            </div>}
                                        </>
                                    )}
                                </div>
                                {!markAsDoneResponse && response && response !== null && response.Note !== null && response.Note !== '' && <div className={classnames(classes.editIcon, 'evaluate-response-edit')} onClick={(e) => this.changeNote(e, response, `requirement_${this.props.selectedProductId}`)} title="Edit"><Image alt="Edit" src={editIcon} /></div>}
                            </div>
                        }

                        {this.state.editNote && <div className={classnames(classes.noteSection)} id="sellerSideResponseText">
                            {requirement.AnswerType === 7 ? (
                                <OutlinedActionInput
                                    type="text"
                                    showCount
                                    value={this.getNote(currentReq, this.state.note)}
                                    maxLength={this.getMaxLimit(requirement)}
                                    onSave={(value) => {
                                        var newResponse = response === null ? {} : response;
                                        newResponse.Note = value;
                                        if (newResponse.SellerOptions === undefined) {
                                            newResponse.SellerOptions = null
                                        }

                                        this.setState({
                                            response: newResponse
                                        })
                                        this.onBlurInput(`requirement_${requirement?.ID}`)
                                    }}
                                    onCancel={() => {
                                        this.setState({ editNote: false })
                                        this.onBlurInput(`requirement_${requirement?.ID}`)
                                    }}
                                />
                            ) : (
                                <SlateInputField
                                    placeholder="Add response here"
                                    style={{ margin: 0 }}
                                    as={SlateInputField}
                                    onChangeEvent={(value) => {
                                        this.setState({
                                            note: value
                                        })
                                        var newResponse = response === null ? {} : response;
                                        newResponse.Note = value;
                                        if (newResponse.SellerOptions === undefined) {
                                            newResponse.SellerOptions = null
                                        }
                                        this.setState({
                                            response: newResponse
                                        })
                                    }}
                                    initValue={this.state.note}
                                    saveData={() => {
                                        var newResponse = response === null ? {} : response;
                                        newResponse.Note = this.state.note;
                                        if (newResponse.SellerOptions === undefined) {
                                            newResponse.SellerOptions = null
                                        }

                                        this.setState({
                                            response: newResponse
                                        })
                                        this.onBlurInput(`requirement_${requirement?.ID}`)
                                    }}
                                    cancelSave={() => {
                                        this.setState({
                                            editNote: false
                                        })
                                        this.onBlurInput(`requirement_${requirement?.ID}`)
                                    }}
                                    // actionButtonsRequired={true}
                                    formControlStyle={{ overflow: 'overlay' }}
                                    textContainerStyle={{ color: "#707070", fontSize: 14 }}
                                />
                            )}
                        </div>
                        }

                        <Grid container className={classes.resActionBtns}>
                            <Grid item xs={5} lg={5}>
                                {(!this.props.checkReqInSellerLibraryProgress && [0,1].includes(this.props.checkReqData?.similarity_score)) && <FormControlLabel control={
                                    <Checkbox
                                        disabled={false}
                                        className={classes.checkBox}
                                        checked={this.state.isAddedinLibrary}
                                        color="default"
                                        onChange={(event) => {
                                            // this.setState({
                                            //     markAsDone: event.target.checked
                                            // })
                                            this.setState({
                                                isAddedinLibrary: this.state.isAddedinLibrary ? false : true
                                            })
                                        }}
                                        name="add_to_library"
                                    />
                                }
                                    label="Add to Library"
                                />}
                            </Grid>

                            <Grid item xs={1} lg={1}>
                                <div className={classes.nextPrevbtns}>
                                    <Tooltip className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Previous</span>} arrow>
                                        <ArrowBackIcon
                                            className={classes.arrowIcons}
                                            onClick={() => {
                                                if (previousReqId !== null) {
                                                    this.props.setSelectedRequirement(previousReqId);
                                                }
                                            }}
                                        />
                                    </Tooltip>

                                    <Tooltip className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Next</span>} arrow>
                                        <ArrowForwardIcon
                                            className={classes.arrowIcons}
                                            onClick={(e) => {
                                                if (nextReqId !== null) {
                                                    this.props.setSelectedRequirement(nextReqId);
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <div className={classes.actionBtns}>
                                    <SqureButton
                                        disabled={this.props.isCompletedQuestionaire}
                                        variant={"contained"} endIcon={<SendIcon />} onClick={this.submitReqResponseChk}>Next</SqureButton>

                                    {/* <SqureButton
                                        disabled={this.props.isCompletedQuestionaire}
                                        variant={"contained"}
                                        endIcon={<SaveIcon />}
                                        style={{ background: '#398af5' }}
                                        onClick={this.saveAsDraftReqResponse}>Save</SqureButton> */}
                                </div>
                            </Grid>
                        </Grid>
                    </div>}
                </div>
                <div className={classnames(classes.tabPanelRes)}>
                    <CachedIcon className={classnames(classes.refreshIcon, this.props.threadFetchProgress === true ? classes.underprogress : null)} onClick={() => {
                        this.props.fetchEvaluationThread(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId)
                    }} />
                    <Typography variant={"h6"} className={classes.currProductTitle}>
                        Chat with {this.props.evaluation.orgName}
                    </Typography>
                    <div className={classnames(classes.thread)}>
                        {this.props.thread.map((response, k) => {
                            let $previousValue = this.props.thread[k - 1];
                            if (response.IsDraft || k === 0) {
                                return '';
                            }
                            return <div className={classnames(
                                classes.response,
                                response.Type === 1 ? classes.sender : classes.receiver,
                                $previousValue?.Type === 1 && response?.Type === 2 ? classes.chatStart : ''
                            )} key={response.id}>
                                <div className={'messageHeader'}>
                                    {
                                        response.Type === 1 ?
                                            <Typography className={classes.username}>{response.UserName}</Typography>
                                            : <Typography className={classes.username}>You</Typography>
                                    }
                                    &nbsp;<span className={classes.responseTime}>{CommonFn.formateDateTime(response.UpdatedAt)}</span>
                                </div>
                                <div className={'messageContainer'}>
                                    <SlateReadonlyField initValue={response.Response} />
                                </div>
                            </div>
                        })
                        }
                    </div>
                    <div className={classes.form}>
                        {this.state.hideChatScreen && <div className={classes.chatWithBuyerDiv}>
                            <Button className={classes.buyerBtn} variant="contained" color="primary" endIcon={<ChatIcon />} onClick={() => {
                                this.setState({
                                    hideChatScreen: false
                                })
                            }}>
                                Chat with Buyer
                            </Button>
                        </div>}
                        {!this.state.hideChatScreen && <div className={classes.chatWithBuyerScreen} id="chatWithBuyerScreenMsg">
                            <SlateInputField
                                style={{ width: '100%' }}
                                as={SlateInputField}
                                onChangeEvent={(value) => {
                                    this.setState({ message: value });
                                }}
                                placeholder=""
                                initValue={this.state.message}
                                formControlStyle={{ overflow: 'overlay' }}
                                textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', margin: "0px", color: "#707070", fontSize: 16 }}
                                external={true}
                                folderPath={this.props.match.params.uuid + "/" + this.props.selectedRequirementId}
                            />

                            <div style={{ textAlign: 'right' }}>
                                <SqureButton style={{ marginRight: 10 }} variant={"outlined"} onClick={() => {
                                    this.setState({
                                        hideChatScreen: true,
                                        message: ''
                                    })
                                }}>Cancel</SqureButton>
                                <SqureButton style={{ "marginLeft": "10px", background: '#398af5' }} variant={"contained"} endIcon={<SendIcon />} onClick={this.submit}>Send</SqureButton>
                            </div>
                        </div>}

                        <FinalizeRequiremntViewDialog />

                        <BottomActions
                            saveAsDraft={this.saveAsDraft}
                            submit={this.submit}
                            submitQuesnnaire={this.submitQuesnnaire}
                            isDisable={this.props.isCompletedQuestionaire}
                            isBackBtnRequired={this.props.isInstructionAvailable}
                            goToInstruction={() => {
                                this.props.showInstruction()
                            }}
                        />

                        <MessageDialog messageData={messageData} callBack={this.callBack} />

                        <ConfirmCallbackDialog
                            messageData={messageDataQuestionaire}
                            callBack={this.callBackQuesnnaire}
                            closeCallBack={this.onCloseDialog}
                            closeDialog={this.onCloseDialog}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Chat));