import React from 'react';
import { connect } from "react-redux";
import { dataKeys, defaultLicense } from 'services/invoiseParser';
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
import "react-datepicker/dist/react-datepicker.css";
import { createStyles, withStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';
import SqureButton from 'Components/Common/SqureButton';
import CollaboratorPopper from './Common/CollaboratorPopper';
import { addContractDetails, addOrgDocument } from "redux/product/orgProduct/action.js";

import { showSnackBar } from "redux/snackbar/action";
const connectedProps = (state) => ({
    orgFetchDocuments: state.orgProduct.orgFetchDocuments,
    orgFetchDocumentsProgress: state.orgProduct.orgFetchDocumentsProgress,
    orgFinanceDetailsProgress: state.orgProduct.orgFinanceDetailsProgress,
    addOrgDocumentProgress: state.orgProduct.addOrgDocumentProgress,
    addOrgDocumentError: state.orgProduct.addOrgDocumentError
});
const connectionActions = {
    addContractDetails: addContractDetails,
    addOrgDocument: addOrgDocument,
    showSnackBar: showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        "& [class*=MuiInputBase-root]": {
            fontSize: '14px !important'
        },
        border: '1px solid #d8d8d8',
        padding: 17,
        borderRadius: 5,
        background: '#FFF',
        marginBottom: 17,
    },
    head: {
        ...CommonCss.ProductDetails.head,
        marginBottom: 0
    },
});


class AddFinancialDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fileName: null
        }
    }

    componentDidUpdate(prevProps) {
        // if (!this.props.addOrgDocumentProgress && prevProps.addOrgDocumentProgress) {
        //     if (this.props.addOrgDocumentError === null) {
        //         this.handleAddDetails()
        //     } else {
        //         this.props.showSnackBar("Something went wrong.", "error", 3000);
        //     }
        // }
    }

    handleAddDocument = () => {
        const fileName = String(`default-${new Date().getTime()}`)
        this.setState(
            {
                fileName
            }, () => {
                this.props.addOrgDocument(
                    this.props.match?.params?.id,
                    {
                        documents: [{
                            description: 'Default',
                            title: 'Default',
                            url: fileName,
                            type: 'default'
                        }]
                    }
                );
                this.handleAddDetails()
            })
    }

    handleAddDetails = () => {
        const data = {
            name: 'default',
            path: this.state.fileName,
            type: 'default',
            lineItems: [defaultLicense],
            attributes: []
        }
        Object.keys(dataKeys).forEach(key => {
            if (key !== 'license') {
                const attribute = { ...dataKeys[key], attribute: key }
                if (attribute.type === 'price') {
                    attribute.value = `USD${0}`
                }
                attribute.value = String(attribute.value)
                if (attribute.type === 'date') {
                    attribute.value = null
                }
                attribute.confidence = 1
                data.attributes.push(attribute)
            }
        })
        this.props.addContractDetails(this.props.match?.params?.id, data)
    }

    render() {
        const { classes, userType, section } = this.props;
        const isAdd = this.props.orgFetchDocuments && this.props.orgFetchDocuments.length > 0 && this.props.orgFetchDocuments.filter(obj => obj.Type === "default").length > 0 ? false : true
        if (userType !== 'OrgAdmin' || !isAdd) {
            return null
        }
        return (
            <div className={classes.root} id="default-seaction">
                {userType === "OrgAdmin" && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                {isAdd && (
                    <Grid container alignItems="center" justifyContent="space-between">
                        <p className={classes.head}>{section.SectionName}</p>
                        <SqureButton
                            cls={classes.actionBtn}
                            onClick={() => this.handleAddDocument()}
                            style={{ marginRight: '40px' }}
                        >
                            Add Details
                        </SqureButton>
                        {this.props.addContractDetailsProg && (<CircularProgress />)}
                    </Grid>
                )}
            </div>
        )
    }
}

export default connector(compose(withRouter, withStyles(styles))(AddFinancialDetails));