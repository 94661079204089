import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import Image from "Components/Common/image.jsx";
import { deleteProductState } from "redux/product/action";
import { hideMessageDialog, showMessageDialog } from "redux/applications/action";
import { deleteProduct } from "redux/product/orgProduct/action";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import CommonCss from "commonCss";
import SectionsDrawer from "./SectionsDrawer";
import MessageDialog from "Components/Common/MessageDialog";
import { showSnackBar } from "redux/snackbar/action";
import * as Environment from "util/Environment";
import { CommonFn } from "services/commonFn";
import axios from "axios";

import { updateOrgVendorTabSectionItemData, deleteOrgVendor } from "redux/vendor/vendorCatalog/action";
import { updateOrgServiceTabSectionItemData, deleteOrgService } from "redux/vendor/serviceCatalog/action";
import { updateOrgVendorServiceTabSectionItemData, deleteOrgVendorService } from "redux/vendor/vendorServiceCatalog/action";

const connectedProps = (state) => ({
  deleteOrgVendorProgress: state.vendorCatalog.deleteOrgVendorProgress,
  deleteOrgVendorError: state.vendorCatalog.deleteOrgVendorError,
  deleteOrgServiceProgress: state.serviceCatalog.deleteOrgServiceProgress,
  deleteOrgServiceError: state.serviceCatalog.deleteOrgServiceError,
  deleteOrgVendorServiceProgress: state.vendorServiceCatalog.deleteOrgVendorServiceProgress,
  deleteOrgVendorServiceError: state.vendorServiceCatalog.deleteOrgVendorServiceError,
});

const connectionActions = {
  deleteProductState,
  hideMessageDialog,
  showMessageDialog,
  deleteProduct,
  showSnackBar,
  updateOrgVendorTabSectionItemData,
  updateOrgServiceTabSectionItemData,
  updateOrgVendorServiceTabSectionItemData,
  deleteOrgVendor,
  deleteOrgService,
  deleteOrgVendorService,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    ...CommonCss.ProductDetails,
    imageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    updateSection: {
      position: "absolute",
      width: "90%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      fontSize: "17px",
      color: "transparent",
      "& :hover": {
        opacity: "0.8",
        background: "#262626",
        color: "white",
      },
    },
    uploadImageLabel: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 6,
      marginBottom: 5,
      cursor: "pointer",
    },
  });

class Sidebar extends Component {
  state = {
    deleteProductId: null,
  };

  componentDidUpdate(prevProps) {
    if (!this.props.deleteOrgServiceProgress && prevProps.deleteOrgServiceProgress) {
      if (this.props.deleteOrgServiceError) {
        this.props.showSnackBar("Failed to delete service", "error", 3000);
      } else {
        this.props.showSnackBar("Service deleted successfully", "success", 3000);
        this.props.history.push("/app/vendors/org-services");
      }
    }

    if (!this.props.deleteOrgVendorProgress && prevProps.deleteOrgVendorProgress) {
      if (this.props.deleteOrgVendorError) {
        this.props.showSnackBar("Failed to delete vendor", "error", 3000);
      } else {
        this.props.showSnackBar("Vendor deleted successfully", "success", 3000);
        this.props.history.push("/app/vendors/org-vendors");
      }
    }

    if (!this.props.deleteOrgVendorServiceProgress && prevProps.deleteOrgVendorServiceProgress) {
      if (this.props.deleteOrgVendorServiceError) {
        this.props.showSnackBar("Failed to delete service", "error", 3000);
      } else {
        this.props.showSnackBar("Service deleted successfully", "success", 3000);
        this.props.history.push("/app/vendors/org-services");
      }
    }
  }

  onDelelte = (id) => {
    this.setState({ deleteProductId: id }, () => {
      this.props.showMessageDialog();
    });
  };

  callBack = () => {
    if (this.state.deleteProductId) {
      this.props.hideMessageDialog();
      if (this.props.catalogType === "service") {
        this.props.deleteOrgService(this.state.deleteProductId);
      } else if (this.props.catalogType === "vendor") {
        this.props.deleteOrgVendor(this.state.deleteProductId);
      } else if (this.props.catalogType === "vendorservice") {
        this.props.deleteOrgVendorService(this.state.deleteProductId);
      }
    }
  };

  fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var formData = new FormData();
      formData.append("folder", "vendorServiceDetails");
      const timestamp = new Date().getTime();
      formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf(".") + 1)}`);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;

      axios
        .post(url, formData, {
          headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data.filepath) {
            if (this.props.catalogType === "service") {
              this.props.updateOrgServiceTabSectionItemData(this.props.appDetails?.id, "logo", {
                value: response.data.filepath,
                section_id: this.props.section?.id,
                is_delete: null,
              });
            } else if (this.props.catalogType === "vendor") {
              this.props.updateOrgVendorTabSectionItemData(this.props.appDetails?.id, "logo", {
                value: response.data.filepath,
                section_id: this.props.section?.id,
                is_delete: null,
              });
            } else if (this.props.catalogType === "vendorservice") {
              this.props.updateOrgVendorServiceTabSectionItemData(this.props.appDetails?.id, "logo", {
                value: response.data.filepath,
                section_id: this.props.section?.id,
                is_delete: null,
              });
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            this.props.showSnackBar(err.response.data.error, "error", 3000);
          } else if (err.request) {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          } else {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          }
        });
    }
  };

  render() {
    const { classes, role, appDetails, catalogType, sections } = this.props;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let deleteTitle = "Delete Vendor";
    if (catalogType === "service") {
      deleteTitle = "Delete Service";
    } else if (catalogType === "vendorservice") {
      deleteTitle = "Delete Service";
    }

    return (
      <>
        <div style={{ textAlign: "center" }}>
          <div className={classes.imageContainer}>
            <Image src={appDetails?.logo ? appDetails?.logo : ""} style={{ maxWidth: "90%" }} />
            {role === "OrgAdmin" && <div className={classes.updateSection}>
              <label htmlFor="imageLogo" className={classes.uploadImageLabel}>
                Update Logo
              </label>
              <input id="imageLogo" type="file" onChange={this.fileSelectHandler} accept="image/png, image/gif, image/jpeg" hidden />
            </div>}
          </div>

          {role === "OrgAdmin" && (
            <Button
              className={classes.btnDeleteProduct}
              onClick={() => {
                this.onDelelte(appDetails.id);
              }}
            >
              {deleteTitle}
            </Button>
          )}
        </div>

        <SectionsDrawer sections={sections} tabValue={this.props.tabValue} role={role} />

        <MessageDialog messageData={messageData} callBack={this.callBack} />
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Sidebar));
