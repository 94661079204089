import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import * as Validator from "util/Validation";
import AppBarV2 from "Components/Application/AppBarV2";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import { Grid } from "@material-ui/core";
import ExistingProductImage from "assets/images/evaluation/existing_product.svg";
import CommonCss from "commonCss";

import ERPImage from "assets/images/evaluation/ERP.svg";
import NewPurchaseImage from "assets/images/evaluation/NewPurchase.svg";
import Products from "assets/images/home/products.svg";

import { fetchVendors } from "redux/vendor/action";
import { fetchProducts } from "redux/product/action";
import { authorizedUser } from "redux/authuser/action";
import { fetchAddOrgProductCountStats } from "redux/product/orgProduct/action";
import { updateDrawerStyle } from "redux/drawer/action";

import { fetchVendorCatalog } from "redux/vendor/vendorCatalog/action";
import ProductSelectionDialog from "../../Evaluate/Components/Evaluation/Common/ProductSelectionDialog";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import { showEvaluationProductSelectionDialog } from "redux/evaluation/metadata/action";
import { isEnabledEvaluate } from "util/Common";
import WelcomeBack from "Components/Common/WelcomePages/WelcomeBack";

const connectedProps = (state) => ({
  products: state.products.products,
  totalProduct: state.products.totalProduct,
  fetchProductsProgress: state.products.fetchProductsProgress,
  orgProductCountStats: state.orgProduct.orgProductCountStats,
  userRole: state.authUser.user?.Role,
  orgProduct: state.orgProduct?.orgProducts,
  user: state.authUser.user,
  orgName: state.authUser.user?.Organization?.Name,
  expentDirectoryProducts: state.expentDirectory.products,
  orgUser: state.orgUser,
  vendors: state.vendors.vendorsData,
  VendorCatalog: state.vendorCatalog.VendorCatalog,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  fetchAddOrgProductCountStats: fetchAddOrgProductCountStats,
  fetchProducts: fetchProducts,
  fetchVendors: fetchVendors,
  fetchVendorCatalog: fetchVendorCatalog,
  authorizedUser: authorizedUser,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  showEvaluationProductSelectionDialog: showEvaluationProductSelectionDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    head: {
      position: "relative",
    },
    topContainer: {
      marginTop: theme.spacing(6),
    },
    myProductCard: {
      padding: "50px 50px 14px 0px!important",
      "& .layer-card-title": {
        padding: 0,
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      },
    },
    rootContainer: CommonCss.rootContainer,
  });

class ProductsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowWelcome: true
    }
  }

  componentDidMount() {
    this.fetchData();
    this.props.fetchExpentDirectoryProducts({
      field_setname: "all",
      text: "",
      filter_fields: {},
      page: 0,
      page_size: 6,
      sort_fields: [],
    });
    this.props.fetchVendors({
      query: "",
      page_size: 6,
      page: 0,
    });
    this.props.fetchVendorCatalog({ query: "", page: 0, page_size: 6, filters: { is_org: "1" } });

    // setTimeout(() => {
    //   this.setState({
    //     isShowWelcome: false
    //   })
    // }, 5000);
  }

  fetchData() {
    this.props.fetchAddOrgProductCountStats();
    this.props.fetchProducts(0, 0, 6, "", undefined, undefined, undefined, undefined, { cancelToken: false });
  }

  routeChange = (path) => {
    this.props.history.push(`${this.props.match.path}/${path}`);
  };

  getConfigValue(array, key, value) {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  }

  checkPermissionForQuickLink(type) {
    // console.log(this.props.userRole, 'userRole')
    const { userRole } = this.props;
    let permission = true;
    let QuickLinkOptions = {};
    if (userRole === "OrgAdmin") {
      QuickLinkOptions = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "QuickLinkOptionsAdmin");
    } else {
      QuickLinkOptions = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "QuickLinkOptionsUser");
    }

    if (QuickLinkOptions === undefined || QuickLinkOptions === null || QuickLinkOptions === "NULL" || QuickLinkOptions === "") {
      QuickLinkOptions = {};
    } else {
      QuickLinkOptions = Validator.isJson(QuickLinkOptions) ? JSON.parse(QuickLinkOptions) : {};
    }
    // console.log(QuickLinkOptions, 'QuickLinkOptions')
    if (QuickLinkOptions[type] !== undefined) {
      permission = QuickLinkOptions[type];
    }
    return permission;
  }

  render() {
    const classes = this.props.classes;
    // let otherProductCount = this.props.expentDirectoryProducts?.others?.total || 0;
    let existingProductCount = this.props.expentDirectoryProducts?.existing?.total || 0;
    let otherVendorCount = this.props.vendors?.total || 0;
    // const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForUsersCheck')
    let isRequestType = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "REQUEST_TYPES");
    let requestTypesData = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "REQUEST_TYPES") || [];
    let requestTypes = [];
    if (
      isRequestType &&
      Validator.isJson(requestTypesData) &&
      requestTypesData !== undefined &&
      requestTypesData !== "NULL" &&
      requestTypesData !== null &&
      requestTypesData !== "null" &&
      requestTypesData.length > 0
    ) {
      requestTypes = JSON.parse(requestTypesData);
    }

    const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "DisableCatalogFeature");
    let otherProductCount = this.props.expentDirectoryProducts?.others?.total || 0;
    let isShowNewRequestCard = this.checkPermissionForQuickLink("EnableSubmitIntakeForm");
    let isShowApplicationCatalog = this.checkPermissionForQuickLink("EnableApplicationCatalog");
    let isShowExpentPDirectory = this.checkPermissionForQuickLink("EnableExpentProductDirectory");
    let isShowActivity = this.checkPermissionForQuickLink("EnableActivity");

    return (
      <div className={classes.head}>
        <AppBarV2 title={"Home"} />
        <div id="topContainer" className={classes.rootContainer}>
          {this.state.isShowWelcome && <div>
            <WelcomeBack />
          </div>}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={3}>
              
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.myProductCard}>
                <LayeredCard
                  title="Start a new project"
                  color="GREY"
                  image={NewPurchaseImage}
                  onClick={() => {
                    this.props.showEvaluationProductSelectionDialog();
                  }}
                />
              </Grid>

              {isShowApplicationCatalog && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.myProductCard}>
                  <LayeredCard
                    title={this.props.orgName ? this.props.orgName + "'s Vendor Catalog" : "Vendor Catalog"}
                    subTitle={DisableVendor ? existingProductCount.toLocaleString("en-US") + " Vendors" : this.props.VendorCatalog?.total + " Vendors"}
                    color="GREY"
                    image={ExistingProductImage}
                    onClick={() => {
                      if (DisableVendor) {
                        this.props.history.push("/app/products/org-products");
                      } else {
                        this.props.history.push("/app/vendors/org-vendors");
                      }
                    }}
                  />
                </Grid>
              )}

              {isShowExpentPDirectory && otherProductCount > 0 && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.myProductCard}>
                  <LayeredCard
                    title="Expent Vendor Directory"
                    subTitle={DisableVendor ? otherProductCount.toLocaleString("en-US") + " Vendors" : otherVendorCount.toLocaleString("en-US") + " Vendors"}
                    color="GREY"
                    image={Products}
                    onClick={() => {
                      if (DisableVendor) {
                        this.props.history.push("/app/products/all-products");
                      } else {
                        this.props.history.push("/app/vendors/all-vendors");
                      }
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.myProductCard}>
                <LayeredCard
                  title="Activity"
                  color="GREY"
                  image={ERPImage}
                  onClick={() => {
                    this.props.history.push("/app/workflows_listing");
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <ProductSelectionDialog isRequestType={isRequestType && requestTypes.length > 0 ? true : false} requestTypes={requestTypes} />
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(ProductsHome));
