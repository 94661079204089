import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment";

export const START_VENDOR_SERVICE_CATALOG_TAB_DATA_FETCH = "START_VENDOR_SERVICE_CATALOG_TAB_DATA_FETCH";
export const END_VENDOR_SERVICE_CATALOG_TAB_DATA_FETCH = "END_VENDOR_SERVICE_CATALOG_TAB_DATA_FETCH";

export const START_DELETE_ORG_VENDOR_SERVICE = "START_DELETE_ORG_VENDOR_SERVICE";
export const END_DELETE_ORG_VENDOR_SERVICE = "END_DELETE_ORG_VENDOR_SERVICE";

export const START_VENDOR_SERVICE_CATALOG_FETCH = "START_VENDOR_SERVICE_CATALOG_FETCH";
export const END_VENDOR_SERVICE_CATALOG_FETCH = "END_VENDOR_SERVICE_CATALOG_FETCH";

export const START_UPDATE_ORG_VENDOR_SERVICE_TAB_SECTION_ITEM_DATA = "START_UPDATE_ORG_VENDOR_SERVICE_TAB_SECTION_ITEM_DATA";
export const END_UPDATE_ORG_VENDOR_SERVICE_TAB_SECTION_ITEM_DATA = "END_UPDATE_ORG_VENDOR_SERVICE_TAB_SECTION_ITEM_DATA";

function startVendorServiceCatalogFetch(searchQuery) {
  return {
    type: START_VENDOR_SERVICE_CATALOG_FETCH,
    payload: {
      searchQuery,
    },
  };
}

function endVendorServiceCatalogFetch(searchQuery, success, error) {
  return {
    type: END_VENDOR_SERVICE_CATALOG_FETCH,
    payload: {
      searchQuery,
      success,
      error,
    },
  };
}

export function fetchVendorServiceCatalog(data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendorservice/search?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startVendorServiceCatalogFetch(data));
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data,false);
      dispatch(endVendorServiceCatalogFetch(data, response, error));
    } catch (e) {
      dispatch(
        endVendorServiceCatalogFetch(data, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}


function startDeleteOrgVendorService() {
  return {
    type: START_DELETE_ORG_VENDOR_SERVICE,
    payload: null,
  };
}

function endDeleteOrgVendorService(success, error) {
  return {
    type: END_DELETE_ORG_VENDOR_SERVICE,
    payload: {
      success,
      error,
    },
  };
}

export function deleteOrgVendorService(id) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendorservice/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startDeleteOrgVendorService());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endDeleteOrgVendorService(response, error));
    } catch (e) {
      dispatch(
        endDeleteOrgVendorService(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startVendorServiceCatalogTabDataFetch() {
  return {
    type: START_VENDOR_SERVICE_CATALOG_TAB_DATA_FETCH,
    payload: {},
  };
}

function endVendorServiceCatalogTabDataFetch(success, error) {
  return {
    type: END_VENDOR_SERVICE_CATALOG_TAB_DATA_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchVendorServiceCatalogTabData(serviceId) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendorservice/org/${serviceId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startVendorServiceCatalogTabDataFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, true);
      dispatch(endVendorServiceCatalogTabDataFetch(response, error));
    } catch (e) {
      dispatch(
        endVendorServiceCatalogTabDataFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startUpdateOrgVendorServiceTabSectionItemData() {
  return {
    type: START_UPDATE_ORG_VENDOR_SERVICE_TAB_SECTION_ITEM_DATA,
    payload: null,
  };
}

function endUpdateOrgVendorServiceTabSectionItemData(item_slug, success, error) {
  return {
    type: END_UPDATE_ORG_VENDOR_SERVICE_TAB_SECTION_ITEM_DATA,
    payload: {
      success,
      error,
      item_slug,
    },
  };
}

export function updateOrgVendorServiceTabSectionItemData(id, slug, data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  let url = `${api_server}/entity/vendorservice/${id}/${slug}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startUpdateOrgVendorServiceTabSectionItemData(id));
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data);
      dispatch(endUpdateOrgVendorServiceTabSectionItemData(slug, response, error));
    } catch (e) {
      dispatch(
        endUpdateOrgVendorServiceTabSectionItemData(slug, null, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
