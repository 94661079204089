import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { MenuItem, IconButton, FormControl, Button, Popover, Tooltip, AppBar, List, Paper, Checkbox, FormControlLabel, ListItem, Typography, TextField, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import SqureButton from "Components/Common/SqureButton";
import { CommonFn } from "services/commonFn";
import DateFilter from "Components/Common/Filters/DateFilter";
import * as Environment from "util/Environment";
import { Select as MaterialSelect } from "@material-ui/core";
import ReactSelect from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";

const SelectAsyncPaginate = withAsyncPaginate(ReactSelect);

const connectedProps = (state) => ({
  users: state.usersSettings.users,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const ProjectStatusArray = [
  { label: "Draft", value: "Draft" },
  { label: "Pending Approval", value: "PendingApproval" },
  { label: "Approved", value: "Approved" },
  { label: "Rejected", value: "Rejected" },
  { label: "Closed", value: "CLOSED" },
  { label: "Finished", value: "FINISHED" },
];

const WorkflowStatusArray = [
  { label: "In Progress", value: "in_progress" },
  { label: "Completed", value: "completed" },
  { label: "Closed", value: "closed" },
];

const styles = (theme) =>
  createStyles({
    formControl: {
      width: 150,
    },
    columnDrawer: {
      width: 400,
      right: "0",
      padding: "15px",
      zIndex: "9",
    },
    bottomActions: {
      marginTop: 20,
      textAlign: "right",
    },
    filterTitle: {
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    selectComp: {
      width: 250,
      marginTop: 10,
    },
    autoSelectInput: {
      width: "100%",
      background: "white",
      marginTop: 10,
      "& [class*=MuiInputBase-input]": {
        padding: "10px !important",
      },
    },
    searchBox: {
      maxWidth: "500px",
      minWidth: "250px",
    },
    statusfilterBtn: {
      background: "#fff",
      border: "1px solid #888",
      borderRadius: 4,
      width: "100%",
      color: "#222",
      boxShadow: "none",
      padding: "4px 20px",
      fontSize: 16,
      "&:hover": {
        background: "#fff",
        color: "#222",
      },
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    showBtnSection: {
      flex: 1,
      textAlign: "right",
      paddingTop: 3,
      paddingRight: 10,
    },
    filterWrap: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      margin: "20px 0px",
    },
  });

class ProjectFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "name",
      selected: [],
      dense: false,
      page: 0,
      duration: 0,
      startDate: CommonFn.getPreviousDate(90),
      endDate: new Date(),
      date: null,
      query: "",
      statusFilter: [],
      projectStatusFilter: [],
      workflowNameFilter: "0",
      requestTypeFilter: "0",
      projectOwnerIDs: [],
      requestorIds: [],
      anchorEl: null,
      workflowStatusfilterPopover: null,
      workflowProjectStatusfilterPopover: null,
      procurementOwnerText: "",
      isShowFilters: false,
      filterEl: null,
    };
  }

  componentDidMount() {
    this.setState({
      query: this.props.filterData?.query || "",
      duration: this.props.filterData?.duration || 0,
      startDate: this.props.filterData?.startDate || CommonFn.getPreviousDate(90),
      endDate: this.props.filterData?.endDate || new Date(),
      projectOwnerIDs: this.props.filterData?.projectOwnerIDs || [],
      contractOwnerIDs: this.props.filterData?.contractOwnerIDs || [],
      dates: this.props.filterData?.dates || null,
      statusFilter: this.props.filterData?.statusFilter || [],
      requestorIds: this.props.filterData?.requestorIds || [],
      projectStatusFilter: this.props.filterData?.projectStatusFilter || [],
      workflowNameFilter: this.props.filterData?.workflowNameFilter || "0",
      requestTypeFilter: this.props.filterData?.requestTypeFilter || "0",
    });
  }

  handleCloseFilter = () => {
    this.setState({
      isShowFilters: false,
      filterEl: null,
    });
  };

  showFiltersPoper = (event) => {
    this.setState({
      filterEl: event.currentTarget || event.current,
      isShowFilters: true,
    });
  };

  selectProjectOwers = (value) => {
    this.setState({
      projectOwnerIDs: value,
    });
  };

  selectRequester = (value) => {
    this.setState({
      requestorIds: value,
    });
  };

  updateStatusFilter(key) {
    let newStatusFilter = [...this.state.statusFilter];
    let index = newStatusFilter.indexOf(key);
    if (index > -1) {
      newStatusFilter.splice(index, 1);
    } else {
      newStatusFilter.push(key);
    }
    this.setState({
      statusFilter: newStatusFilter,
    });
  }

  updateProjectStatusFilter(key) {
    let newProjectStatusFilter = [...this.state.projectStatusFilter];
    let index = newProjectStatusFilter.indexOf(key);
    if (index > -1) {
      newProjectStatusFilter.splice(index, 1);
    } else {
      newProjectStatusFilter.push(key);
    }
    this.setState({
      projectStatusFilter: newProjectStatusFilter,
    });
  }

  updateWorkflowNameFilter(newValue) {
    this.setState({
      workflowNameFilter: newValue,
    });
  }

  updateRequestTypeFilter(newValue) {
    this.setState({
      requestTypeFilter: newValue,
    });
  }

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      let appList = [];
      this.props.users?.forEach((item, index) => {
        appList.push({
          label: item.Name,
          value: item.ID,
        });
      });
      return {
        options: appList,
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      page: 0,
      pageSize: 10,
      query: inputValue,
    };
    let url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  searchQuery = (text) => {
    this.setState({
      query: text,
    });
  };

  clearFilter = () => {
    this.setState({
      query: "",
      duration: 0,
      startDate: CommonFn.getPreviousDate(90),
      endDate: new Date(),
      projectStatus: "",
      projectOwnerIDs: [],
      contractOwnerIDs: [],
      dates: null,
      statusFilter: [1, 2],
    });
  };

  render() {
    const classes = this.props.classes;
    const { query } = this.state;
    const ownerData = this.props.projectOwnerIds || [];
    const requesterData = this.props.requestorIds || [];

    return (
      <>
        <AppBar position="static" color="default" className={classes.answerBox}>
          <div className={classes.showBtnSection}>
            <Tooltip title="Apply Filters">
              <IconButton
                aria-label="filter list"
                onClick={(event) => {
                  this.showFiltersPoper(event);
                }}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </AppBar>
        {this.state.isShowFilters && (
          <Popover
            open={this.state.isShowFilters}
            anchorEl={this.state.filterEl}
            onClose={this.handleCloseFilter}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={classes.columnDrawer}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ marginTop: 10 }} variant="h6">
                  Filters
                </Typography>
                <SqureButton style={{ marginTop: 10 }} variant={"outlined"} onClick={this.clearFilter}>
                  Clear
                </SqureButton>
              </div>
              <div className={classes.filterWrap}>
                <div className={classes.searchBox}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Project Name
                  </Typography>
                  <TextField
                    className={classes.autoSelectInput}
                    value={query}
                    variant="outlined"
                    placeholder={"Project name"}
                    type="search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onInput={(e) => {
                      this.setState({
                        query: e.target.value,
                      });
                    }}
                  />
                </div>

                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Workflow Status
                  </Typography>
                  <div className={classes.selectComp} style={{ width: "200px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.statusfilterBtn}
                      onClick={(event) => {
                        this.setState({
                          workflowStatusfilterPopover: event.currentTarget,
                        });
                      }}
                    >
                      Status
                    </Button>
                    <Popover
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      id="workflowStatusfilterPopover"
                      className={classes.popover}
                      anchorEl={this.state.workflowStatusfilterPopover}
                      keepMounted
                      open={Boolean(this.state.workflowStatusfilterPopover)}
                      onClose={() => {
                        this.setState({ workflowStatusfilterPopover: null });
                      }}
                    >
                      <List>
                        {WorkflowStatusArray.map((obj, i) => (
                          <ListItem key={i} classes={{ button: classes.listItem }} button onClick={() => {}}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  onChange={(e) => {
                                    this.updateStatusFilter(obj.value);
                                  }}
                                  checked={this.state.statusFilter?.includes(obj.value)}
                                />
                              }
                              name={obj.value}
                              label={obj.label}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Popover>
                  </div>
                </div>

                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Project Status
                  </Typography>
                  <div className={classes.selectComp} style={{ width: "200px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.statusfilterBtn}
                      onClick={(event) => {
                        this.setState({
                          workflowProjectStatusfilterPopover: event.currentTarget,
                        });
                      }}
                    >
                      Status
                    </Button>
                    <Popover
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      id="workflowProjectStatusfilterPopover"
                      className={classes.popover}
                      anchorEl={this.state.workflowProjectStatusfilterPopover}
                      keepMounted
                      open={Boolean(this.state.workflowProjectStatusfilterPopover)}
                      onClose={() => {
                        this.setState({ workflowProjectStatusfilterPopover: null });
                      }}
                    >
                      <List>
                        {ProjectStatusArray.map((obj, i) => (
                          <ListItem key={i} classes={{ button: classes.listItem }} button onClick={() => {}}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  onChange={(e) => {
                                    this.updateProjectStatusFilter(obj.value);
                                  }}
                                  checked={this.state.projectStatusFilter?.includes(obj.value)}
                                />
                              }
                              name={obj.value}
                              label={obj.label}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Popover>
                  </div>
                </div>

                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Workflow Name
                  </Typography>
                  <FormControl variant="outlined" className={classes.formControl} size="small">
                    <MaterialSelect
                      value={this.state.workflowNameFilter}
                      onChange={(e) => {
                        this.updateWorkflowNameFilter(e.target.value);
                      }}
                    >
                      {this.props.workflowFilterOptions?.map((op) => {
                        return (
                          <MenuItem key={"wrkFilter_" + op.value} value={op.value} style={{ fontSize: 13 }}>
                            {op.label}
                          </MenuItem>
                        );
                      })}
                    </MaterialSelect>
                  </FormControl>
                </div>

                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Project Type
                  </Typography>
                  <FormControl variant="outlined" className={classes.formControl} size="small">
                    <MaterialSelect
                      value={this.state.requestTypeFilter}
                      onChange={(e) => {
                        this.updateRequestTypeFilter(e.target.value);
                      }}
                    >
                      {this.props.requestTypeOptions?.map((op) => {
                        return (
                          <MenuItem key={"reqTypeFilter_" + op.value} value={op.value} style={{ fontSize: 13 }}>
                            {op.label}
                          </MenuItem>
                        );
                      })}
                    </MaterialSelect>
                  </FormControl>
                </div>

                <div className={classes.selectComp}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Business owner
                  </Typography>
                  <SelectAsyncPaginate
                    isClearable={false}
                    isMulti
                    isSearchable={true}
                    value={ownerData}
                    menuPosition="fixed"
                    classNamePrefix="select"
                    isLoading={false}
                    placeholder="Search business owner"
                    additional={{
                      page: 0,
                    }}
                    loadOptions={this.getOptions}
                    onChange={(e) => {
                      this.selectProjectOwers(e);
                    }}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                </div>

                <div className={classes.selectComp}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Requestor
                  </Typography>
                  <SelectAsyncPaginate
                    isClearable={false}
                    isMulti
                    isSearchable={true}
                    value={requesterData}
                    menuPosition="fixed"
                    classNamePrefix="select"
                    isLoading={false}
                    placeholder="Search requestor"
                    additional={{
                      page: 0,
                    }}
                    loadOptions={this.getOptions}
                    onChange={(e) => {
                      this.selectRequester(e);
                    }}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                </div>

                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Date
                  </Typography>
                  <DateFilter
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    duration={this.state.duration}
                    btnClass={classes.filterBtn}
                    onChangeDates={(dates) => {
                      this.setState({ dates: dates });
                    }}
                  />
                </div>
              </div>
              <div className={classes.bottomActions}>
                <SqureButton
                  style={{ marginLeft: "10px" }}
                  variant={"outlined"}
                  onClick={() => {
                    this.handleCloseFilter();
                  }}
                >
                  Cancel
                </SqureButton>

                <SqureButton
                  style={{ marginLeft: "10px" }}
                  variant={"contained"}
                  onClick={() => {
                    this.props.applyFilter({
                      query: this.state.query || "",
                      duration: this.state.duration || 0,
                      startDate: this.state.startDate || CommonFn.getPreviousDate(90),
                      endDate: this.state.endDate || new Date(),
                      projectOwnerIDs: this.state.projectOwnerIDs || [],
                      contractOwnerIDs: this.state.contractOwnerIDs || [],
                      dates: this.state.dates || null,
                      statusFilter: this.state.statusFilter || [],
                      requestorIds: this.state.requestorIds || [],
                      projectStatusFilter: this.state.projectStatusFilter || [],
                      workflowNameFilter: this.state.workflowNameFilter || "0",
                      requestTypeFilter: this.state.requestTypeFilter || "0",
                    });
                    this.handleCloseFilter();
                  }}
                >
                  Confirm
                </SqureButton>
              </div>
            </Paper>
          </Popover>
        )}
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(ProjectFilter));
