import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import BottomActions from "./BottomActions";
// import 'react-cron-generator/dist/cron-builder.css'

import {
  updateAutomation
} from 'redux/automations/action';
import { CircularProgress } from "@material-ui/core";
import JobResult from "./JobResult";



const connectedProps = () => ({});

const connectionActions = {
  updateAutomation: updateAutomation
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    marginTop: 10,
    '& .cron_builder': {
      width: '100%',
      maxWidth: '100%',
    }
  },
  loader: {
    textAlign: 'center',
    marginTop: 10
  },
  rootDisable: {
    marginTop: 10,
    cursor: 'not-allowed',
    '& .cron_builder': {
      width: '100%',
      maxWidth: '100%',
      pointerEvents: 'none',
      cursor: 'not-allowed',
    }
  }
});

class Schedular extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isLoading: true
    }

    this.initialValue = this.initialValue.bind(this)
  }

  componentDidMount() {
    this.initialValue()
  }

  componentDidUpdate(prevProps) {

  }

  initialValue() {
    const data = this.props.data;
    if (data && data.responseJson) {
      const responseDatas = JSON.parse(data.responseJson);
      console.log(responseDatas, 'responseDatas')
      let cronExp = "cron_expression"
      let res = responseDatas.filter(o => o.name === cronExp)[0].value
      if (res !== undefined && res !== null && res !== '') {
        console.log(res, 'res')
        this.setState({
          value: res,
          isLoading: false
        })
      } else {
        this.setState({
          value: '0 0 00 1 1/1 ? *',
          isLoading: false
        })
      }
    }
  }

  submitResponse() {
    const { value } = this.state;
    const { data } = this.props;
    if (data) {
      let cronExp = "cron_expression"
      let inputData = data.inputJson;
      inputData.forEach((item, mapIndex) => {
        if (item.name === cronExp) {
          item.value = value;
        }
      });
      let params = {
        "trigger": {
          "InputJson": JSON.stringify(inputData)
        }
      }
      this.props.updateAutomation(data.id, params)
    }
  }

  render() {
    const { classes, isForSummary, isForTestJobs } = this.props;
    const { isLoading, value } = this.state;

    if (isLoading) {
      return <div className={classes.loader}>
        <CircularProgress />
      </div>
    }

    console.log(value, 'FinalValue')

    return (
      <div className={!isForSummary ? classes.root : classes.rootDisable}>
        {!isForTestJobs && <div>
          {!isForSummary && <BottomActions
            submit={() => {
              this.submitResponse()
            }}
          />}
        </div>}

        {isForTestJobs && <div>
          <JobResult
            data={this.props.data}
          />
        </div>}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Schedular));
