const isClientTokenPresent = () => {
  const clientToken = localStorage.getItem('clientToken')
  if([null, undefined, NaN].includes(clientToken)) {
    return false
  } else {
    return true;
  }
}

const clientTokenHeader = () => {
  if (isClientTokenPresent()) {
  return  { 
      ClientToken: localStorage.getItem('clientToken')
    }
  } else {
    return { }
  }
}

const findRequirementPreviousInteraction = (rqid, data, type = "seller") => {
  let rt = (data && Array.isArray(data) && data.length > 0 && data) || []
  let rtdata = { has: false, current_requirement: {}, previous_requirements: [] }
  rt = rt.filter(rtObj => (parseInt(rtObj.current_requirement.evaluation_requirement_id) === parseInt(rqid)))
  if (rt.length > 0) {
    const rtData = []
    rt.forEach(rtObj => {
      const data = {}
      rtObj.previous_requirements.forEach(element => {
        element.json.forEach(jsonEle => {
          if (type === jsonEle.response_type) {
            if (data[jsonEle.evaluation_id] === undefined) {
              data[jsonEle.evaluation_id] = {
                evaluation_id: jsonEle.evaluation_id,
                evaluation_name: jsonEle.evaluation_name,
                evaluation_date: jsonEle.evaluation_created_date,
                requirements: []
              }
            }
            data[jsonEle.evaluation_id].requirements = [...data[jsonEle.evaluation_id].requirements, jsonEle] 
          }
        })
      });
      Object.keys(data).forEach(key => {
        return rtData.push(data[key])
      });
    })
    rtdata = { has: rtData.length > 0 , current_requirement: rt[0].current_requirement.json, previous_requirements: rtData }
  }
  return rtdata
}

export { clientTokenHeader, isClientTokenPresent, findRequirementPreviousInteraction }