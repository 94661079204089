export const Types = {
  START_ADD_COMPANY_PROFILE_SETTINGS: "START_ADD_COMPANY_PROFILE_SETTINGS",
  END_ADD_COMPANY_PROFILE_SETTINGS: "END_ADD_COMPANY_PROFILE_SETTINGS",

  START_FETCH_COMPANY_PROFILE_SETTINGS: "START_FETCH_COMPANY_PROFILE_SETTINGS",
  END_FETCH_COMPANY_PROFILE_SETTINGS: "END_FETCH_COMPANY_PROFILE_SETTINGS",

  START_FETCH_ALL_COMPANY_PROFILE_SETTINGS: "START_FETCH_ALL_COMPANY_PROFILE_SETTINGS",
  END_FETCH_ALL_COMPANY_PROFILE_SETTINGS: "END_FETCH_ALL_COMPANY_PROFILE_SETTINGS",

  START_STORE_DATA: "START_STORE_DATA",
  END_STORE_DATA: "END_STORE_DATA",
  RESET_STORE_DATA: "RESET_STORE_DATA",

  START_FETCH_ORG_META_REQUESTS: "START_FETCH_ORG_META_REQUESTS",
  END_FETCH_ORG_META_REQUESTS: "END_FETCH_ORG_META_REQUESTS",

  START_UPDATE_ORG_META_REQUEST_STATUS: "START_UPDATE_ORG_META_REQUEST_STATUS",
  END_UPDATE_ORG_META_REQUEST_STATUS: "END_UPDATE_ORG_META_REQUEST_STATUS",
  START_FETCH_ORG_META_REQUEST_DATA: "START_FETCH_ORG_META_REQUEST_DATA",
  END_FETCH_ORG_META_REQUEST_DATA: "END_FETCH_ORG_META_REQUEST_DATA",
};
