import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { showMessageDialog, hideMessageDialog } from "../../redux/applications/action";
import { DialogContent, Typography, Dialog, Button, Slide } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    messageDialogOpen: state.applications.messageDialogOpen
});

const connectionActions = {
    showMessageDialog: showMessageDialog,
    hideMessageDialog: hideMessageDialog
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    button: {
        textAlign: 'right',
        marginBottom: 10
    },
    title: {
    },
    subHead: {
        fontSize: 15,
        fontWeight: 300
    }
});

class MessageDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            type_a: false,
            type_b: false,
        }
       
    }

    handleChange = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    render() {
        const classes = this.props.classes;
       
        return <Dialog 
                    onClose={this.props.hideMessageDialog} 
                    open={this.props.messageDialogOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"sm"}
                    scroll={"body"}
                    >
                        <DialogContent classes={{root:classes.dialogContent}}>
                            {/* Default layout */}
                            {
                                this.props.layout === 'default' &&
                                <>
                                    <Typography variant={"h6"} className={classes.title}> {this.props.messageData?.head}</Typography>
                                    <p className={classes.subHead}>{this.props.messageData?.subHead}</p>
                                    {  this.props.messageData?.firstBtn !== '' &&  this.props.messageData?.secondBtn !== '' && <div className={classes.button}>
                                            <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideMessageDialog}>{this.props.messageData?.firstBtn}</Button>
                                                &nbsp;
                                                &nbsp;
                                            <Button variant="contained" color="secondary" className={classes.button} onClick={() => this.props.callBack(this.props.messageData)}>{this.props.messageData?.secondBtn}</Button>
                                        </div> 
                                    }

                                        {  this.props.messageData?.firstBtn !== '' &&  this.props.messageData?.secondBtn === '' && <div className={classes.button}>
                                            <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideMessageDialog}>{this.props.messageData?.firstBtn}</Button>
                                        </div> 
                                    }
                                </>
                            }

                            {/* Cluster */}
                            {
                                this.props.layout === 'cluster_products' &&
                                <>
                                    <Typography variant={"h6"} className={classes.title}> {this.props.messageData?.head}</Typography>
                                    <p className={classes.subHead}>{this.props.messageData?.subHead}</p>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox name="type_a" />} label="Remove product from group" onChange={this.handleChange}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox name="type_b" />} label="Delete product" onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    {  this.props.messageData?.firstBtn !== '' &&  this.props.messageData?.secondBtn !== '' && <div className={classes.button}>
                                            <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideMessageDialog}>{this.props.messageData?.firstBtn}</Button>
                                                &nbsp;
                                                &nbsp;
                                            <Button variant="contained" color="secondary" className={classes.button} 
                                            disabled={!(this.state.type_a || this.state.type_b)}
                                            onClick={() => this.props.callBack(this.state)}>{this.props.messageData?.secondBtn}</Button>
                                        </div> 
                                    }
                                </>
                            }
                        </DialogContent>
                </Dialog>
    }
}
MessageDialog.defaultProps = {
    messageData: {
        head: '',
        subHead: '',
        firstBtn: '',
        secondBtn: ''
    },
    layout: 'default',
};

export default connector(compose(
    withStyles(styles)
)(MessageDialog));