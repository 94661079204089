import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AuthImage from "../../assets/images/auth_image.svg";
import AuthBackgorundImage from "../../assets/images/auth_background.svg";
import Image from 'Components/Common/image.jsx'
import MediaQuery from 'react-responsive'

const connectedProps = (state) => ({

});

const connectionActions = {

}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        backgroundImage: `url(${AuthBackgorundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition:"-200px 62px",

        [theme.breakpoints.down('xs')]: {
            backgroundImage:"none",
        },

        [theme.breakpoints.down('md')]: {
            backgroundSize: "cover",
        },
    },

    container: {
        maxWidth: theme.appMaxWidth,
        margin: 'auto',
        minHeight: "100vh",
        display:"flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        paddingRight: theme.spacing(12),

        [theme.breakpoints.down('md')]: {
            paddingRight: 0,
        },
    },

    imageContainer:{
        flex: 6,
        [theme.breakpoints.down('xs')]: {
            display:"none"
        },
    },

    content:{
        width: "454px",
        paddingBottom:  theme.spacing(8.4),
        margin: "auto"
    },

    authimage: {
        // height: "100vh",
        textAlign:"center",
        display: "flex",
        justifyContent: "center",
        paddingTop: theme.spacing(6),
        margin: theme.spacing(0, 3),
        [theme.breakpoints.down('xs')]: {
            height: "inherit",
        },
    },

    image: {
        width: "583px",
        [theme.breakpoints.down('md')]: {
            width: "400px",
        },
    },

    background_image: {
        height: '100%',
        position: "absolute",
        bottom: "0",
        right: 0,
        top: "30px",
    }
});

class AuthContainer extends React.Component {

    render() {
        const classes = this.props.classes;
        return <div className={classes.root}>
                <div className={classes.container}>
                    <MediaQuery minWidth={992}>
                        <div className={classes.imageContainer}>
                            <div className={classes.authimage}>
                                <Image src={AuthImage} alt="auth_image" className={classes.image} />
                                {/* <Image src={AuthBackgorundImage} alt="auth_backimage" className={classes.background_image} /> */}
                            </div>
                        </div>
                    </MediaQuery>
                    <div className={classes.content}>
                        {this.props.children}
                    </div>
            </div>
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(AuthContainer));