import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// components
import AppBarV2 from "../../../AppBarV2";
import Users from './Users';
import UsersGroups from './UsersGroups';

// assets
import CommonCss from "commonCss";
import { withRouter } from "react-router";

import { setActiveUserTab } from "redux/org/group/action";

const connectedProps = (state) => ({
  userActiveTab: state.orgGroup.userActiveTab
});
const connectionActions = {
  setActiveUserTab: setActiveUserTab,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  fullSceen: CommonCss.backdrop,
  rootContainer: {
    ...CommonCss.rootContainer,
    padding: '2rem 2rem'
  }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

class UsersSettings extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        activeTabValue: 0
      };
    }
  
    handleChange = (event, value) => {
      this.props.setActiveUserTab(value);
    };
    
    componentDidUpdate(prevProps){
      if(this.props.location.search.indexOf("team=1") !== -1 && prevProps.location.search.indexOf("team=1") === -1){
        this.props.setActiveUserTab(1);
      }
    }

    componentDidMount(){
      if(this.props.location.search.indexOf("team=1") !== -1){
        this.props.setActiveUserTab(1);
      }
    }

    render() {
      const classes = this.props.classes;
      return<div>
        <AppBarV2 title="Users & Teams" withBack={true} />
        <div className={classes.rootContainer}>
          <Grid container justifyContent="space-between">
              <Grid item ></Grid>
              <Grid item >
                <Grid container alignItems="flex-end">
                  <Tabs
                    value={this.props.userActiveTab}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    fullWidth
                    centered
                  >
                    <Tab  value={0} label="Users"  {...a11yProps(0)} />
                    <Tab  value={1} label="Teams" {...a11yProps(1)} />
                  </Tabs>
                </Grid>
              </Grid>
              <Grid item></Grid>
          </Grid>
          
          <TabPanel value={this.props.userActiveTab} index={0} >
              <Users />
          </TabPanel>
          <TabPanel value={this.props.userActiveTab} index={1} >
              <UsersGroups />
          </TabPanel>
        </div>
      </div>
    }
  }

export default connector(compose(
  withRouter,
  withStyles(styles))(UsersSettings));