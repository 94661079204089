/*
 |  File contains the methods to validate the User
 |  Data
 |
 */

export function validateEmail(email) {
  var emailrx = /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)@([\w-]+\.)+([\w]{2,})$/i;
  if (!emailrx.test(email)) return false;
  else return true;
  //  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
}

export function validateURL(url) {
  var urlrx =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  if (!urlrx.test(url)) return false;
  else return true;
}

export function isJson(str) {
  try {
    if(str === undefined || str === null){
      return false;
    } 
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
