import React from 'react';
import { connect } from "react-redux";
import _ from 'underscore'
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import classnames from "classnames";

// components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// import SaveIcon from '@material-ui/icons/Save';

// redux
import { fetchProductDetails } from "redux/product/action";
import { addRequirementResponse, fetchUserRequirementResponse, showCollectiveResponseDialog, showAskSellerDialog, fetchCollectiveResponses, fetchAskSellerThread, addAskSellerRequest } from "redux/evaluation/criteria/action"
import { showEvaluationProductEditDialog } from "redux/evaluation/cart/action"
import { showProductRankingDialog } from "redux/evaluate/action";

import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

import Box from '@material-ui/core/Box';
import PropTypes from "prop-types";
import Tooltip from '@material-ui/core/Tooltip';
import { Avatar } from "@material-ui/core";

import AnswerReplyReadOnly from "Components/Common/EvaluateMultiChoiceAnswer/AnswerReplyReadOnly";
import { fetchEvaluationReportData } from "redux/evaluation/reportdata/action"
import AppBar from '@material-ui/core/AppBar';
import RequirementReply from 'Components/Common/FormReply/RequirementReply';

const connectedProps = (state) => ({
    OrgID: state.authUser.user.OrgID,
    user: state.authUser.user,
    expandedCriteriaId: state.evaludationCriteria.expandedCriteriaId,
    userReponses: state.evaludationCriteria.userReponses,
    addResponseProgress: state.evaludationCriteria.addResponseProgress,
    errorResponseAdd: state.evaludationCriteria.errorResponseAdd,
    collectiveResponseDialogOpen: state.evaluate.collectiveResponseDialogOpen,
    evaluationProductsList: state.evaluationMetaData.evaluationProductsList,
    threadFetchProgress: state.evaludationCriteria.fetchAskSellerResponseInprogress,
    thread: state.evaludationCriteria.fetchAskSelerResponse,
    saveProgress: state.evaludationCriteria.askSellerSubmitProgress,
    productRequirement: state.products.productRequirement,
    evaluateView: state.applications.evaluateView,
    criteria_requrement_height: state.applications.criteria_requrement_height,
    expandedRequirementId: state.evaludationCriteria.expandedRequirementId,
    fetchCollectiveResponsesLoading: state.evaludationCriteria.fetchCollectiveResponsesLoading,
    collectiveResponses: state.evaludationCriteria.collectiveResponses,
    summaryViewId: state.evaludationCriteria.summaryViewId,
    report: state.evaluationReportData.report,
    isLoadingReport: state.evaluationReportData.isLoading,
    evaluation: state.evaluationMetaData.evaluation,
});

const connectionActions = {
    addRequirementResponse: addRequirementResponse,
    fetchUserRequirementResponse: fetchUserRequirementResponse,
    showProductRankingDialog: showProductRankingDialog,
    showCollectiveResponseDialog: showCollectiveResponseDialog,
    fetchProductDetails: fetchProductDetails,
    showEvaluationProductEditDialog: showEvaluationProductEditDialog,
    showAskSellerDialog: showAskSellerDialog,
    fetchAskSellerThread: fetchAskSellerThread,
    fetchCollectiveResponses: fetchCollectiveResponses,
    addAskSellerRequest: addAskSellerRequest,
    fetchEvaluationReportData: fetchEvaluationReportData
}

var connector = connect(connectedProps, connectionActions);

const communityDot = {
    width: 5,
    height: 5,
    background: 'red',
    position: 'absolute',
    left: '0px',
    // top: 14,
    // left: 144,
    borderRadius: '50%'
}

const styles = (theme) => createStyles({
    requirement: {
        "& [class*=MuiAvatar-root]": {
            width: 30,
            height: 30,
            fontSize: 14
        },
        // height: 220,
        cursor: 'pointer',
        // padding: '0 .5em',
        // marginTop: 18,
        borderTop: "solid 1px #EEEEEE",
        display: 'flex',
        overflow: 'hidden',
        // alignItems: 'center',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        // maxHeight: 195,
        position: 'relative',
        transition: 'all linear .5s',
        "&.rootRequirementHight": {
            height: 300,
        },

        "&.options-hide": {
            opacity: 0.2,
            pointerEvents: 'none'
        }
    },
    responseActionContainer: {
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: 'center flex-start',
        alignItems: 'center',
        marginTop: 13
    },
    responseAction: {
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: ' center space-between',
        alignItems: 'center',
        background: '#f9f9f9',
        borderRadius: '8px',
        padding: '4px 21px',
    },
    rNote: {
        // height: '100%',
        minHeight: '25px',
        fontSize: 13,
        color: '#707070',
        width: '100%',
        position: 'relative',
        overflow: 'overlay'
        // textAlign:"center",
        // padding:theme.spacing(4)
    },
    sellerNote: {
        height: '100%',
        minHeight: '25px',
        fontSize: 13,
        color: '#707070',
        width: '100%',
        // margin: '0px auto',
        overflow: 'overlay'
        // textAlign:"center",
        // padding:theme.spacing(4)
    },
    selectResult: {
        "& > div": {
            marginBottom: 0
        },
        display: "flex",
        justifyContent: 'flex-end',
        position: 'relative',
        marginRight: 10,
        height: '100%',
        alignItems: 'center',
        minWidth: 80,

        "& button": {
            color: '#707070',
            background: '#fff',
            padding: '1.5px 9px',
            minWidth: 80
        },
        "& [class*=MuiFormControl-root]": {
            minWidth: 90,
            width: 'inherit',
        },
        "& [class*=MuiSelect-root]": {
            fontSize: 13,
            color: '#707070',
            padding: '0.29em 0.75em'

        },
        "&.pass": {
            "& [class*=MuiSelect-root], svg": {
                color: '#2EB77D',
            },
            "& [class*=MuiOutlinedInput-notchedOutline]": {
                border: '1px solid #2EB77D'
            },

        },
        "&.fail": {
            "& [class*=MuiSelect-root], svg": {
                color: '#b94141',
            },
            "& [class*=MuiOutlinedInput-notchedOutline]": {
                border: '1px solid #b94141'
            }
        }
    },
    iconSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    thread: {
        cursor: 'pointer',
        padding: 4,
        marginBottom: 10,
        width: 27,
        marginTop: 5
    },
    addText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80px',
        fontSize: 13,
        color: '#707070',
        cursor: 'pointer',
        transition: 'all linear .5s',
    },
    addTextimage: {
        marginRight: 10,
        width: 21,
        position: 'relative',
        top: 2
    },
    responseContent: {
        color: '#707070',
        fontSize: 13,
        padding: '5px 0px',
        textAlign: 'center',
        cursor: 'pointer',
        borderRight: '1px solid #EEEEEE',

        '& p': {
            marginTop: 0,
            marginBottom: 0,
        }
    },

    askSeller: {
        borderRight: 'none'
    },
    bottomContent: {
        paddingTop: 15
    },
    requirementName: {
        margin: '0px 0px 8px 0px',
        alignSelf: 'flex-start',
        fontSize: 12,
        color: '#04363D',
        fontWeight: 500
    },
    saveText: {
        color: '#4b86f8'
    },
    saveHighlight: {
        border: '1px solid #4b86f8',
        borderRadius: 5
    },
    yourResponse: {
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#707070',
        opacity: 1,
        fontSize: '13px',
        transition: 'height 0.3s ease-in'
    },
    response: {
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#04363D',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        placeContent: 'center space-between',
        alignItems: 'center',
        width: '100%',
        transition: "all linear 1s",
        position: 'relative',
        // marginTop: 16,
        // marginBottom: 6,
        "& [class*='MuiInputBase-inputMultiline']": {
            fontSize: 13,
            height: 70,
            color: '#707070'
        }
    },
    viewAllResponse: {
        cursor: 'pointer',
        textDecoration: 'underline',
        letterSpacing: '0px',
        color: '#6D7278',
        fontSize: '13px',
        // marginTop: 10,
        marginBottom: 0,
        width: 140,
        textAlign: 'right',
        margin: '0px auto'
    },
    addComment: {
        cursor: 'pointer',
        textDecoration: 'underline',
        letterSpacing: '0px',
        color: '#6D7278',
        fontSize: '13px',
        width: 140,
        textAlign: 'left',
        margin: '0px auto',
        '& span': {
            marginBottom: 6,
        }
    },
    viewAllResponseEdit: {
        cursor: 'pointer',
        textDecoration: 'underline',
        letterSpacing: '0px',
        color: '#6D7278',
        fontSize: '13px',
        lineHeight: '16px',
        display: 'block',
        textAlign: 'right',
        padding: '10px'
    },
    askSellerBtn: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: ' 1px solid #398AF5',
        borderRadius: '4px',
        letterSpacing: '0px',
        color: '#398AF5',
        padding: '3px 10px',
        cursor: 'pointer',
        alignItems: 'center',
        display: 'flex',
        marginLeft: '6px'
    },
    replySellerBtn: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: ' 1px solid #398AF5',
        borderRadius: '4px',
        letterSpacing: '0px',
        color: '#398AF5',
        padding: '3px 10px',
        cursor: 'pointer',
        alignItems: 'center',
        display: 'flex',
    },
    editIcon: {
        color: "#999999",
        marginTop: 6,
        marginLeft: 17,
        marginRight: 15,
        cursor: 'pointer',
        position: 'absolute',
        right: '-20px',
        top: '20px',
    },
    editSellerIcon: {
        color: "#999999",
        cursor: 'pointer'
    },
    saveIcon: {
        marginTop: 0,
        marginLeft: 18,
        marginRight: 15,
        cursor: 'pointer',
        position: 'absolute',
        right: '-38px',
        top: '6px',
        "& svg": {
            width: 18,
            fill: '#4b86f8'
        }
    },
    closeIcon: {
        marginTop: 0,
        marginLeft: 18,
        marginRight: 15,
        cursor: 'pointer',
        position: 'absolute',
        right: '-38px',
        top: '30px',
        "& svg": {
            width: 18,
            fill: '#4b86f8'
        }
    },
    sellerSaveIcon: {
        position: 'absolute',
        right: '-33px',
        top: 0,
        cursor: 'pointer',
        "& svg": {
            width: 18,
            fill: '#4b86f8'
        }
    },
    cancelEdit: {
        position: 'absolute',
        right: '-33px',
        top: 30,
        cursor: 'pointer',
        "& svg": {
            width: 18,
            fill: '#4b86f8'
        }
    },
    editSection: {
        position: 'absolute',
        right: -36,
        top: 0
    },
    askImage: {
        marginRight: 5
    },
    noteSection: {
        position: 'relative',
        width: 'calc(100% - 7px)',
        "& [class*='editableField']": {
            maxHeight: '50px !important;',
            minHeight: '50px !important',
        }
    },
    showNoteSection: {
        // width: 'calc(100% - 7px)',
        width: 'calc(100% - 30px)',
        "& [class*='editableField']": {
            margin: '0px !important',
            minHeight: 'auto !important',
            maxHeight: 'auto !important',
        }
    },
    showSellerNoteSection: {
        width: 'calc(100% - 7px)',
        "& [class*='editableField']": {
            margin: '0px !important',
            minHeight: 'auto !important',
            maxHeight: 'auto !important',
        },
    },
    '@global': {
        '.rootRequirementHight': {
            "& [class*='response-addText']": {
                height: '150px'
            },
            "& [class*='editor-formControl']": {
                maxHeight: '150px'
            }
        }
    },
    expandDiv: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: 13,
        top: 13,
    },
    refreshIcon: {
        cursor: 'pointer',
        color: '#999999',
        transitionDuration: '5s',
        transitionProperty: 'transform',
        marginRight: 10,
    },
    underprogress: {
        transform: 'rotate(360deg)',
        '-webkit-transform': 'rotate(360deg) infinite linear',
    },
    gridViewConversation: {
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: 'center',
        alignItems: 'center'
    },
    reqMatch: {
        display: 'flex',
        flexDirection: 'row',
        placeContent: 'center flex-start',
        alignItems: 'center',
        background: '#f9f9f9',
        padding: '12px 2px',
        borderRadius: '6px',
        '& span': {
            marginRight: 10,
            marginLeft: 10
        }
    },
    expand: {
        transform: 'rotate(0deg)',
        padding: 0,
        borderRadius: 3,
        border: '1px solid #EDEDED',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tabPanel: {
        backgroundColor: '#fff',
        minHeight: theme.spacing(40),
    },
    tabPanelRes: {
        backgroundColor: '#fff',
        '& [class*=MuiBox-root]': {
            padding: '10px'
        },
        '& [class*=makeStyles-label]': {
            display: 'none'
        }
    },
    responseItems: {
        '& [class*=MuiTabs-root]': {
            minWidth: '100px !important'
        },
        '& [class*=MuiTab-root]': {
            minWidth: '100px !important'
        },
    },
    reqMatchDiv: {
        background: '#f9f9f9',
        borderRadius: theme.spacing(1.25),
        padding: theme.spacing(1, 1),
        color: '#282D30',
        fontWeight: 400,
        fontSize: theme.spacing(2.2),
        marginTop: 20,
    },
    selectedReqOption: {
        border: '1px solid #707070',
        color: '#282D30',
        borderRadius: theme.spacing(1.25),
        padding: theme.spacing(0, 4),
        fontSize: theme.spacing(1.8)
    },
    userResponder: {
        border: '1px solid #f2f2f2',
        boxShadow: '0px 1px 10px -5px rgb(157 133 133 / 77%)',
        padding: 10,
        marginBottom: 10,
        borderRadius: theme.spacing(1.25),
    },
    userInfoItem: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            marginLeft: 10
        }
    },
    requirementExpanded01: {
        border: '1px solid #8d7d7d',
        marginBottom: 19
    },
    requirementExpanded02: {
        border: '1px solid #c1c1c1',
        marginBottom: 19
    },
    answerBox: {
        backgroundColor: '#fff',
        borderBottom: '1px solid #f1f1f1',
    },
    reqScorePer: {
        position: 'absolute',
        top: 4,
        right: 10,
        background: '#EBEFF0',
        borderRadius: 15,
        color: '#04363D',
        fontSize: 12,
        padding: '10px 20px',
        fontWeight: 500
    },
    disableForm: {
        pointerEvents: 'none'
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

class Response extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ratingPoperEl: null,
            editNote: false,
            editSellerNote: false,
            sellerNoteUpdated: false,
            fetchSellerThread: '',
            note: "",
            response: "",
            value: 0,
            openDownloadModal: false,
            isDotHidden: false,
            selectedTab: null,
            requirementHight: null,
            addComment: false
        }
        this.avatarName = this.avatarName.bind(this);
    }

    avatarName(name) {
        return name.substring(0, 1);
    }

    componentDidMount() {
        this.requirementHight()
        window.addEventListener('resize', this.requirementHight);

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.requirementHight);
    }

    requirementHight = () => {
        var requirement = this.props.requirement;
        let reqEle = document.getElementById(`requirement_${this.props.criteria.id}_${requirement.id}`);
        let reqEleTab = document.getElementById(`requirementTab_${requirement.id}`);

        var tabHeight = 0;
        if (reqEleTab !== null) {
            tabHeight = reqEleTab.offsetHeight;
        }

        if (reqEle && this.state.requirementHight !== (reqEle.offsetHeight - tabHeight)) {
            this.setState({
                requirementHight: (reqEle.offsetHeight - tabHeight)
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.saveProgress === false && prevProps.saveProgress === true) {
            this.setState({ editSellerNote: false }, () => {
                this.fetchSelerThread()
            })

        }
        _.debounce(() => {
            this.requirementHight()
        }, 500)();

        if (this.props.isLoadingReport === false && prevProps.isLoadingReport === true) {
            alert('report data got')
        }
    }

    fetchSelerThread = () => {
        var me = this;
        if (this.state.sellerNoteUpdated === true) {
            this.setState({
                sellerNoteUpdated: false,
                editSellerNote: false,
            }, () => {
                var product = me.props.product;
                var requirement = me.props.requirement;
                me.props.fetchAskSellerThread(product.VendorUUID, requirement.id)
            })
        }
    }

    render() {
        const classes = this.props.classes;
        const { report } = this.props;

        var requirement = this.props.requirement;
        var criteria = this.props.criteria;
        var product = this.props.product;
        let _heightTabPanel = this.state.requirementHight;
        var requirementId = requirement.id;
        var refReqId = requirement.ref_requirement_id;
        var productId = product.ID;

        var responses = [];
        if (report !== null && report.Responses !== undefined && report.Responses !== null && report.Responses[requirementId] !== undefined && report.Responses[requirementId] !== null && report.Responses[requirementId][productId] !== undefined && report.Responses[requirementId][productId].length > 0) {
            responses = report.Responses[requirementId][productId];
        }

        var sellerResponse = null;
        if (report !== null && report.SellerResponses !== undefined && report.SellerResponses !== null && report.SellerResponses[requirementId] !== undefined && report.SellerResponses[requirementId][productId] !== undefined) {
            sellerResponse = report.SellerResponses[requirementId][productId];
        }

        let communityMessage = 'No response yet';
        let matchFeature = '';
        let show_community_message = true;
        let backendMessage = "No data";
        let isDotHidden = true;

        if (criteria.name === "Product Features") {
            show_community_message = true;
            if (requirement.name) {
                this.props.evaluationProductsList.map((productDetail) => {
                    if (productDetail.ID === this.props.product.ID) {
                        matchFeature = productDetail.Features.find((feature) => {
                            return feature.Name.toLowerCase() === requirement.name.toLowerCase();
                        });
                    }

                    if (matchFeature) {
                        communityMessage = `${this.props.product.Name} supports ${matchFeature?.Name}`;
                        isDotHidden = false;
                    }
                });
            }
        } else {
            var communityResponses = (report !== null && report.CommunityResponses !== null) ? report.CommunityResponses : [];
            var isCommunityRes = communityResponses.filter(o => o.RequirementID === refReqId);
            if (isCommunityRes.length > 0) {
                communityMessage = isCommunityRes[isCommunityRes.length - 1].Description;
                isDotHidden = false;
            }
        }

        // Vendor response props start
        const { summaryViewId } = this.props;
        let selectOptionsMap = {
            10: 'Yes',
            5: 'Partial',
            1: 'No',
            '-1': 'Not Applicable'
        }

        let communityTabHeader = 'Community';

        var isOdd = true;
        if (this.props.reqIndex % 2) {
            isOdd = false;
        }

        let styleItems = {};
        styleItems = { height: _heightTabPanel, overflow: 'hidden scroll' }

        var maxScore = this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation?.MaxScore && this.props.evaluation?.MaxScore !== null && this.props.evaluation?.MaxScore !== '' ? this.props.evaluation.MaxScore : 5;
        var reqScore = 0;
        // console.log('==================='+requirement.name+'=======================')
        // console.log(maxScore,'maxScore')
        // console.log(this.props.productScoreMap[product.ID],'productScoreMap')
        if (this.props.productScoreMap[product.ID] !== undefined) {
            var requirementWiseScore = this.props.productScoreMap[product.ID]?.RequirementWiseScore;
            if (requirementWiseScore[requirement.name] !== undefined) {
                var requirementScore = requirementWiseScore[requirement.name];
                var overAllScoreOpted = requirementScore?.TotalOpted === null ? 0 : requirementScore?.TotalOpted;
                var overAllScoreMax = requirementScore?.TotalMax === null ? 0 : requirementScore?.TotalMax;
                reqScore = (overAllScoreOpted / overAllScoreMax * maxScore).toFixed(1)
                if (isNaN(reqScore)) {
                    reqScore = 0;
                }
            }
        }

        // if(requirement?.answer_type === 15){
        //     console.log(sellerResponse,'sellerResponse888')
        //     console.log(requirement,'requirement')
        // }

        // console.log(requirement,'requirement-seeler')
        return (
            <div className={isOdd ? classes.requirementExpanded01 : classes.requirementExpanded02}>
                <div className={classnames(classes.requirement, `requirement_${criteria.id}_${requirement.id}`, this.props.respondable ? 'options-show' : 'options-hide')}>
                    <div className={classes.responseItems}>
                        <AppBar position="static" color="default" className={classes.answerBox}>
                            <Tabs value={summaryViewId}
                                indicatorColor="primary"
                                textColor="primary"
                                id={`requirementTab_${requirement.id}`}
                            >
                                <Tab value={1} label="Your Response" {...a11yProps(0)} />
                                {this.props.isHideCommunityResponse === false && isDotHidden && <Tab value={2} label={communityTabHeader} {...a11yProps(1)} ></Tab>}
                                {this.props.isHideCommunityResponse === false && !isDotHidden && <Tab value={2} label={<span><span style={communityDot}></span> {communityTabHeader}</span>} {...a11yProps(1)} ></Tab>}
                                {this.props.isHideAskSeller === false && <Tab value={3} label="Vendor" {...a11yProps(2)} />}
                            </Tabs>
                        </AppBar>

                        {this.props.enableScoring && (this.props.user?.Organization?.ID === 1 || this.props.user?.Organization?.ID === 3 || this.props.user?.Organization?.ID === 67) && <span className={classes.reqScorePer}>{reqScore}/{maxScore}</span>}

                        <TabPanel style={styleItems} className={classes.tabPanelRes} value={summaryViewId} index={1}>
                            <div className={classes.yourResponse}>
                                {(responses === null || responses.length <= 0) && <div style={{ textAlign: 'center' }}>
                                    No Response
                                </div>}

                                {responses.map((response) => {
                                    return <div className={classes.userResponder}>
                                        <div>
                                            <Typography className={classes.userInfoItem}>
                                                <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{response?.User?.Name + "\n" + response?.User?.Email}</span>} arrow>
                                                    <Avatar className={classes.avatarImg}> {this.avatarName(response?.User?.Name)}</Avatar>
                                                </Tooltip>
                                                <span>{response?.User?.Name}</span>
                                            </Typography>
                                        </div>
                                        {false && response !== null && ([6].includes(requirement.answer_type)) && <AnswerReplyReadOnly
                                            menuPortalTarget={document.getElementById('root')}
                                            requirement={requirement}
                                            disableResponse={true}
                                            response={response}
                                            evaluation_id={this.props.match.params.evaluation_id}
                                            OrgID={this.props.OrgID}
                                            product={product}
                                            userOptions={response == null || response.UserOptions == null ? [] : response.UserOptions}
                                        />}

                                        {response !== null && <div className={requirement.answer_type !== 6 && classes.disableForm}>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].includes(requirement.answer_type) && <RequirementReply
                                                additional_data={requirement?.options}
                                                options={requirement?.options}
                                                type={requirement?.answer_type}
                                                title={requirement?.name}
                                                description={requirement?.description}
                                                isRequired={requirement?.is_mandatory}
                                                key={requirement.id + '_sectionrequirement'}
                                                index={requirement.id}
                                                value={response !== undefined && response !== null && response.UserOptions !== null ? response?.UserOptions[0]?.Answer : ''}
                                                selectedOptions={response !== undefined && response !== null && response.UserOptions !== null ? response?.UserOptions : []}
                                                evaluationId={this.props.match.params.evaluation_id}
                                                orgId={this.props.OrgID}
                                                workflowId={this.props.workflow_id}
                                                isFormEditable={false}
                                                itemId={requirement.id}
                                                sectionId={requirement.id}
                                                maxScore={maxScore}
                                                item={requirement}
                                                error={requirement.error || ''}
                                                isShowSaveButton={false}
                                                isUpdateOnChange={false}
                                                from={'BUYER'}
                                                isSummaryPage={true}
                                                isOtherOption={requirement?.is_other_option}
                                                onChange={(output) => {

                                                }}

                                                showUserAddDialog={(item) => {

                                                }}
                                                showTeamAddDialog={(data) => {

                                                }}
                                            />}
                                        </div>}

                                        {response !== null && Object.keys(response).length > 0 && <div style={{ position: 'relative', width: '100%' }}>
                                            <div className={classes.rNote} >
                                                {response !== null && response.Note !== null && response.Note !== '' &&
                                                    <>
                                                        <div className={classnames(classes.showNoteSection)}>
                                                            <SlateInputField
                                                                readOnly={true}
                                                                isToolBar={false}
                                                                placeholder=""
                                                                style={{ margin: 0 }}
                                                                as={SlateInputField}
                                                                initValue={response.Note}
                                                                formControlStyle={{ border: 'none' }}
                                                                textContainerStyle={{ color: "#707070", fontSize: 14 }} />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        }

                                        {false && this.props.enableScoring && requirement.answer_type !== 5 && response !== null && this.props.scoring_mechanism.id === 'Textual' && <div className={classes.reqMatchDiv}>
                                            Score: <span className={classes.selectedReqOption}>{response.Response === 0 ? '-' : selectOptionsMap[response.Response]}</span>
                                        </div>}


                                        {this.props.enableScoring && requirement.answer_type !== 5 && response !== null && <div className={classes.reqMatchDiv}>
                                            Score: <span className={classes.selectedReqOption}>{response.Response === -1 ? 'Not Applicable' : response.Response}</span>
                                        </div>}

                                    </div>
                                })}
                            </div>
                        </TabPanel>
                        <TabPanel style={{ height: _heightTabPanel, overflow: 'hidden scroll' }} className={classes.tabPanelRes} value={summaryViewId} index={2}>
                            <div className={classes.communityTab}>
                                {show_community_message && <p style={{ padding: '6px', textAlign: 'center', letterSpacing: '0px', color: '#707070', marginTop: 0, fontSize: 14 }}>{communityMessage}</p>}
                                {!show_community_message && <div style={{ padding: '6px', textAlign: 'left', letterSpacing: '0px', color: '#707070', marginTop: 0, fontSize: 14 }} dangerouslySetInnerHTML={{ __html: backendMessage }}></div>}
                            </div>
                        </TabPanel>
                        <TabPanel style={{ height: _heightTabPanel, overflow: 'hidden scroll' }} className={classes.tabPanelRes} value={summaryViewId} index={3}>
                            <div className={classes.sellerResponse}>

                                {sellerResponse === null && <div style={{ textAlign: 'center' }}>
                                    No Response
                                </div>}

                                {false && sellerResponse !== null && ([6].includes(requirement.answer_type)) && <AnswerReplyReadOnly
                                    menuPortalTarget={document.getElementById('root')}
                                    requirement={requirement}
                                    disableResponse={true}
                                    response={sellerResponse}
                                    evaluation_id={this.props.match.params.evaluation_id}
                                    OrgID={this.props.OrgID}
                                    product={product}
                                    userOptions={sellerResponse == null || sellerResponse.SellerOptions == null ? [] : sellerResponse.SellerOptions}
                                />}

                                {sellerResponse !== null && <div className={requirement.answer_type !== 6 && classes.disableForm}>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].includes(requirement.answer_type) && <RequirementReply
                                        additional_data={requirement?.options}
                                        options={requirement?.options}
                                        type={requirement?.answer_type}
                                        title={requirement?.name}
                                        description={requirement?.description}
                                        isRequired={requirement?.is_mandatory}
                                        key={requirement.id + '_sectionrequirement'}
                                        index={requirement.id}
                                        value={sellerResponse !== undefined && sellerResponse !== null && sellerResponse.SellerOptions !== undefined && sellerResponse.SellerOptions !== null ? sellerResponse?.SellerOptions[0]?.Answer : ''}
                                        selectedOptions={sellerResponse !== undefined && sellerResponse !== null && sellerResponse.SellerOptions !== undefined && sellerResponse.SellerOptions !== null ? sellerResponse.SellerOptions : []}
                                        evaluationId={this.props.match.params.uuid}
                                        orgId={this.props.match.params.org_id}
                                        workflowId={this.props.match.params.org_id}
                                        isFormEditable={false}
                                        itemId={requirement.id}
                                        sectionId={requirement.id}
                                        item={requirement}
                                        error={requirement.error || ''}
                                        isShowSaveButton={false}
                                        isUpdateOnChange={true}
                                        from={'SELLER_SUMMARY'}
                                        isSummaryPage={true}
                                        isOtherOption={requirement?.is_other_option}
                                        onChange={(output) => {
                                        }}
                                        showUserAddDialog={(item) => {

                                        }}
                                        showTeamAddDialog={(data) => {

                                        }}
                                    />}
                                </div>}



                                {sellerResponse !== undefined && sellerResponse !== null && Object.keys(sellerResponse).length > 0 && <div style={{ position: 'relative', width: '100%' }}>
                                    <div className={classes.rNote} >
                                        {sellerResponse !== null && sellerResponse.Note !== null && sellerResponse.Note !== '' &&
                                            <>
                                                <div className={classnames(classes.showNoteSection)}>
                                                    <SlateInputField
                                                        readOnly={true}
                                                        isToolBar={false}
                                                        placeholder=""
                                                        style={{ margin: 0 }}
                                                        as={SlateInputField}
                                                        initValue={sellerResponse.Note}
                                                        formControlStyle={{ border: 'none' }}
                                                        textContainerStyle={{ color: "#707070", fontSize: 14 }} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                        </TabPanel>
                    </div>
                </div >
            </div >
        )
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Response));