import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CommonCss from "commonCss";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { Responsive, WidthProvider } from "react-grid-layout";
import ChartsRenderer from "./ChartsRenderer.jsx";
import "./react-grid-layout.css";
import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";
import DragIndicatorOutlinedIcon from "@material-ui/icons/DragIndicatorOutlined";
import Measure from "react-measure";
// import produce from 'immer'
import { withSize } from "react-sizeme";
const ResponsiveGridLayout = WidthProvider(Responsive);

const borderCards = ["LINE", "BAR", "PIE", "DOUGHNUT"];

const connectedProps = (state) => ({
  expanded: state.drawer.expanded,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFF",
      minHeight: "100vh",
    },
    formControl: {
      ...CommonCss.formControl,
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
      },
      padding: ".375rem",
    },
    ...CommonCss.ProductDetails,
    tabs: {
      "& [class*='MuiTab-root']": {
        padding: "0 6px",
        maxWidth: "max-content",
        width: "max-content",
      },
      "border-bottom": "1px solid #d8d8d8",
    },
    container: {
      marginTop: 81,
    },
    DashboardNoDataLayout: {
      width: "calc(100% - 10px)",
      margin: "120px 0px 0px 0px",
      minHeight: 450,
      textAlign: "center",
      background: "#ffffff",
      padding: "23px",
      borderRadius: "6px",
      "& button": {
        margin: "20px 0",
      },
    },
    rootContainer: CommonCss.rootContainer,
    chartWrap: {
      display: "flex",
      alignItems: "start",
    },
    contentRoot2: {
      overflow: "auto",
      height: "100%",
    },
    contentRoot3: {
      overflow: "auto",
      height: "100%",
      padding: 3,
    },
    contentRoot: {
      border: "1px solid #d8d8d8",
      padding: 10,
      borderRadius: 5,
      background: "#FFF",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    },
    inputLabel: {
      color: "#2e2e2e",
      fontSize: 18,
      opacity: 0.9,
      marginBottom: 5,
    },
    inputSubLabel: {
      color: "#6C6C6C",
      fontSize: 13,
      opacity: 0.9,
      marginBottom: 11,
    },
    button: {
      padding: "6px 8px",
      fontSize: 13,
      fontWeight: 400,
      width: theme.spacing(30),
      marginRight: 15,
    },
    dashboardWrap: {
      width: "100%",
      marginTop: "1rem",
    },
    editCardWrap: {
      position: "relative",
      borderRadius: "5px",
      padding: "0px",
      boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);",
      "&:hover >p": {
        display: "flex",
        background: "#e5e5e5",
      },
    },
    cardWrap: {},
    visualizationWrap: {
      padding: "1rem",
      paddingTop: "2rem",
    },
    label: {
      color: "#282D30",
      fontSize: theme.spacing(1.6),
    },
    widthFields: {
      height: 30,
    },
    textFields: {
      width: "90%",
      marginBottom: 10,
      "& [class*='MuiInputBase']": {
        fontSize: 14,
      },
    },
    dragIconArea: {
      color: "#b0b0b0",
      transform: "rotate(90deg)",
      zIndex: 1000,
      cursor: "move",
    },
    actionButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      zIndex: 1000,
      gap: 15,
    },
    actions: {
      display: "none",
      margin: "0px",
      alignItems: "center",
      justifyContent: "space-between",
      position: "absolute",
      width: "100%",
      top: 0,
      left: 0,
      zIndex: 999,
      padding: 10,
      paddingRight: 40,
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    },
  });

class DashboardRender extends Component {
  constructor(props) {
    super();
    this.state = {};
    this.gridRef = React.createRef();
  }
  componentDidMount() {}

  handleItemSizeChange = (size, index) => {
    // if(this.props.editMode) return;
    // From formula pixelHeight = (rowHeight * h) + (marginH * (h - 1))
    // h = (pixelHeight + marginH) / (marginH + rowHeight)
    // const newH = Math.ceil((size.height + 20) / (20 + 5))
    // const nlayout = this.props.layout.map(item => ({ ...item.dataGrid }));
    // nlayout[index] = {...nlayout[index], h: newH, minH: newH, maxH: newH};
    // console.log(nlayout,newH,size,index, "layoutsss1")
    // this.props.onLayoutChange(nlayout)
  };

  render() {
    const { classes, layout, editMode } = this.props;
    console.log(layout, "layoutsss2");

    return (
      <div className={classes.dashboardWrap} ref={this.gridRef}>
        <ResponsiveGridLayout
          rowHeight={5}
          verticalCompact={true}
          compactType={"vertical"}
          className="layout"
          width={this.props.size?.width}
          onLayoutChange={this.props.onLayoutChange}
          margin={[15, 15]}
          isDraggable={editMode}
          isRearrangeable={editMode}
          isResizable={editMode}
          draggableHandle=".draggableIcon"
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 12 }}
        >
          {layout?.map((card, i) => (
            <div key={"card-" + i + this.props.match.params.id} data-grid={card.dataGrid} className={editMode ? classes.editCardWrap : classes.cardWrap}>
              {editMode && (
                <p className={classes.actions}>
                  <div style={{ width: 50 }}></div>
                  <DragIndicatorOutlinedIcon className={classnames(classes.dragIconArea, "draggableIcon")} />
                  <div className={classes.actionButton}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.onEdit(i);
                      }}
                    >
                      <Image src={editIcon} />
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.RemoveWidget(i);
                      }}
                    >
                      <Image src={deleteIcon} />
                    </div>
                  </div>
                </p>
              )}
              <MeasuredImage editMode={editMode} classes={classes} card={card} onSizeChange={(size) => this.handleItemSizeChange(size, i)} />
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    );
  }
}

const MeasuredImage = ({ card, classes, onSizeChange, editMode }) => {
  function handleResize(contentRect) {
    onSizeChange({
      width: contentRect.bounds.width,
      height: contentRect.bounds.height,
    });
  }

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <div ref={measureRef} style={{ height: "100%" }}>
          <div
            style={editMode ? { pointerEvents: "none" } : {}}
            className={
              borderCards.includes(card.visualization?.type)
                ? classes.contentRoot
                : ["catalog", "projects", "renewal"].includes(card.visualization?.value)
                ? classes.contentRoot3
                : classes.contentRoot2
            }
          >
            {card.title && card.title.length > 0 && (
              <p className={classes.inputLabel} style={{ marginTop: 0 }}>
                {card.title}
              </p>
            )}
            {card.subTitle && card.subTitle.length > 0 && (
              <p className={classes.inputSubLabel} style={{ marginTop: "5px" }}>
                {card.subTitle}
              </p>
            )}
            <ChartsRenderer visualizationId={card.visualization?.value} card={card} />
          </div>
        </div>
      )}
    </Measure>
  );
};

DashboardRender.defaultProps = {
  editMode: false,
  layout: [],
  onLayoutChange: () => {},
  RemoveWidget: () => {},
};

export default connector(compose(withSize({ refreshMode: "debounce", refreshRate: 60 }), withRouter, withStyles(styles))(DashboardRender));
