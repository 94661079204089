import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { IconButton, Button, Popover, Tooltip, AppBar, List, Paper, Checkbox, FormControlLabel, ListItem, Typography, TextField, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import SqureButton from "Components/Common/SqureButton";
import { CommonFn } from "services/commonFn";
import { fetchUsers } from "redux/usersSettings/action";
import DateFilter from "Components/Common/Filters/DateFilter";
import * as Environment from "util/Environment";
import ReactSelect from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";

const SelectAsyncPaginate = withAsyncPaginate(ReactSelect);

const connectedProps = (state) => ({
  users: state.usersSettings.users,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: "0em 2em",
      marginTop: "0px !important",
      paddingBottom: "2rem",
    },
    columnDrawer: {
      width: 400,
      right: "0",
      padding: "15px",
      zIndex: "9",
    },
    bottomActions: {
      marginTop: 20,
      textAlign: "right",
    },
    filterTitle: {
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    selectComp: {
      width: 250,
      marginTop: 10,
    },
    autoSelectInput: {
      width: "100%",
      background: "white",
      marginTop: 10,
      "& [class*=MuiInputBase-input]": {
        padding: "10px !important",
      },
      // width: '30%'
    },
    searchBox: {
      maxWidth: "500px",
      minWidth: "250px",
    },
    statusfilterBtn: {
      background: "#fff",
      border: "1px solid #888",
      borderRadius: 4,
      width: "100%",
      color: "#222",
      boxShadow: "none",
      padding: "4px 20px",
      fontSize: 16,
      "&:hover": {
        background: "#fff",
        color: "#222",
      },
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    showBtnSection: {
      flex: 1,
      textAlign: "right",
      paddingTop: 3,
      paddingRight: 10,
    },
    filterWrap: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      margin: "20px 0px",
    },
  });

const stateArr = [
  { label: "Approved", value: "Approved" },
  { label: "Rejected", value: "Rejected" },
  { label: "Draft", value: "Draft" },
  { label: "Pending Approval", value: "PendingApproval" },
];

const statusFilterOptions = [
  // { label: "All", shortName: "NS", value: 0, classname: 'isFeatured' },
  { label: "Not Started", shortName: "NS", value: 1, classname: "isFeatured" },
  { label: "In Progress", shortName: "IP", value: 2, classname: "isExisting" },
  { label: "Renewed", shortName: "R", value: 3, classname: "isInReview" },
  { label: "Not going to renew", shortName: "NR", value: 4, classname: "isLimited" },
];

class ContractFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      duration: 0,
      startDate: new Date(),
      endDate: new Date(),
      projectStatus: "",
      projectOwnerIDs: [],
      contractOwnerIDs: [],
      anchorEl: null,
      dates: null,
      statusFilter: [1, 2],
      renewalStatusfilterPopover: null,
      isShowFilters: false,
      filterEl: null,
    };
  }

  componentDidMount() {
    this.setState({
      query: this.props.filterData?.query || "",
      duration: this.props.filterData?.duration || 0,
      startDate: this.props.filterData?.startDate || new Date(),
      endDate: this.props.filterData?.endDate || new Date(),
      projectStatus: this.props.filterData?.projectStatus || "",
      projectOwnerIDs: this.props.filterData?.projectOwnerIDs || [],
      contractOwnerIDs: this.props.filterData?.contractOwnerIDs || [],
      dates: this.props.filterData?.dates || null,
      statusFilter: this.props.filterData?.statusFilter || [1, 2],
    });
  }

  handleCloseFilter = () => {
    this.setState({
      isShowFilters: false,
      filterEl: null,
    });
  };

  showFiltersPoper = (event) => {
    this.setState({
      filterEl: event.currentTarget || event.current,
      isShowFilters: true,
    });
  };

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      let appList = [];
      this.props.users?.forEach((item, index) => {
        appList.push({
          label: item.Name,
          value: item.ID,
        });
      });
      return {
        options: appList,
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      page: 0,
      pageSize: 10,
      query: inputValue,
    };
    let url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  searchQuery = (text) => {
    this.setState({
      query: text,
    });
  };

  clearFilter = () => {
    this.setState({
      query: "",
      duration: 0,
      startDate: new Date(),
      endDate: new Date(),
      projectStatus: "",
      projectOwnerIDs: [],
      contractOwnerIDs: [],
      dates: null,
      statusFilter: [1, 2],
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar position="static" color="default" className={classes.answerBox}>
          <div className={classes.showBtnSection}>
            <Tooltip title="Apply Filters">
              <IconButton
                aria-label="filter list"
                onClick={(event) => {
                  this.showFiltersPoper(event);
                }}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </AppBar>
        {this.state.isShowFilters && (
          <Popover
            open={this.state.isShowFilters}
            anchorEl={this.state.filterEl}
            onClose={this.handleCloseFilter}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={classes.columnDrawer}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ marginTop: 10 }} variant="h6">
                  Filters
                </Typography>
                <SqureButton style={{ marginTop: 10 }} variant={"outlined"} onClick={this.clearFilter}>
                  Clear
                </SqureButton>
              </div>
              <div className={classes.filterWrap}>
                <div className={classes.searchBox}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Search Renewal
                  </Typography>
                  <TextField
                    className={classes.autoSelectInput}
                    value={this.state.query}
                    variant="outlined"
                    placeholder={"Search Renewal"}
                    type="search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onInput={(e) => {
                      this.searchQuery(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Renewal Status
                  </Typography>
                  <div className={classes.selectComp} style={{ width: "200px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.statusfilterBtn}
                      onClick={(event) => {
                        this.setState({
                          renewalStatusfilterPopover: event.currentTarget,
                        });
                      }}
                    >
                      Status
                    </Button>
                    <Popover
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      id="renewalStatusfilterPopover"
                      className={classes.popover}
                      anchorEl={this.state.renewalStatusfilterPopover}
                      keepMounted
                      open={Boolean(this.state.renewalStatusfilterPopover)}
                      onClose={() => {
                        this.setState({ renewalStatusfilterPopover: null });
                      }}
                    >
                      <List>
                        {statusFilterOptions.map((obj, i) => (
                          <ListItem key={i} classes={{ button: classes.listItem }} button onClick={() => {}}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  onChange={(e) => {
                                    let newStatusFilter = [...this.state.statusFilter];
                                    let index = newStatusFilter.indexOf(obj.value);
                                    if (index > -1) {
                                      newStatusFilter.splice(index, 1);
                                    } else {
                                      newStatusFilter.push(obj.value);
                                    }
                                    this.setState({ statusFilter: newStatusFilter });
                                  }}
                                  checked={this.state.statusFilter?.includes(obj.value)}
                                />
                              }
                              name={obj.value}
                              label={obj.label}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Popover>
                  </div>
                </div>
                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Date
                  </Typography>
                  <DateFilter
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    duration={this.state.duration}
                    showPastDue={true}
                    onChangeDates={(dates) => {
                      this.setState({ dates: dates });
                    }}
                  />
                </div>
                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Project Status
                  </Typography>
                  <div className={classes.selectComp}>
                    <ReactSelect
                      isClearable
                      menuPosition="fixed"
                      isSearchable={false}
                      options={stateArr}
                      classNamePrefix="select"
                      placeholder="Select Project Status"
                      onChange={(e) => {
                        this.setState({ projectStatus: e });
                      }}
                      value={this.state.projectStatus}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </div>
                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Project Owner
                  </Typography>
                  <div className={classes.selectComp}>
                    <SelectAsyncPaginate
                      isClearable={false}
                      isMulti
                      isSearchable={true}
                      value={this.state.projectOwnerIDs}
                      menuPosition="fixed"
                      classNamePrefix="select"
                      isLoading={false}
                      placeholder="Select project owner"
                      additional={{
                        page: 0,
                      }}
                      loadOptions={this.getOptions}
                      onChange={(e) => {
                        this.setState({
                          projectOwnerIDs: e,
                        });
                      }}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </div>
                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Contract Owner
                  </Typography>
                  <div className={classes.selectComp}>
                    <SelectAsyncPaginate
                      isClearable={false}
                      isMulti
                      isSearchable={true}
                      value={this.state.contractOwnerIDs}
                      menuPosition="fixed"
                      classNamePrefix="select"
                      isLoading={false}
                      placeholder="Select contract owner"
                      additional={{
                        page: 0,
                      }}
                      loadOptions={this.getOptions}
                      onChange={(e) => {
                        this.setState({
                          contractOwnerIDs: e,
                        });
                      }}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.bottomActions}>
                <SqureButton
                  style={{ marginLeft: "10px" }}
                  variant={"outlined"}
                  onClick={() => {
                    this.handleCloseFilter();
                  }}
                >
                  Cancel
                </SqureButton>

                <SqureButton
                  style={{ marginLeft: "10px" }}
                  variant={"contained"}
                  onClick={() => {
                    this.props.applyFilter({
                      query: this.state.query || "",
                      duration: this.state.duration || 0,
                      startDate: this.state.startDate || new Date(),
                      endDate: this.state.endDate || new Date(),
                      projectStatus: this.state.projectStatus || "",
                      projectOwnerIDs: this.state.projectOwnerIDs || [],
                      contractOwnerIDs: this.state.contractOwnerIDs || [],
                      dates: this.state.dates || null,
                      statusFilter: this.state.statusFilter || [1, 2],
                    });
                    this.handleCloseFilter();
                  }}
                >
                  Confirm
                </SqureButton>
              </div>
            </Paper>
          </Popover>
        )}
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(ContractFilter));
