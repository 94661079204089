import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment"

export const START_EXPENT_DIRECTORY_PRODUCT_SEARCH     =   "START_EXPENT_DIRECTORY_PRODUCT_SEARCH";
export const END_EXPENT_DIRECTORY_PRODUCT_SEARCH       =   "END_EXPENT_DIRECTORY_PRODUCT_SEARCH";

function startExpentDirectoryProductSearch(searchQuery){
    
  return {
      type: START_EXPENT_DIRECTORY_PRODUCT_SEARCH,
      payload: {
          searchQuery
      }
  }
}

function endExpentDirectoryProductSearch(searchQuery, success, error,isTabular){
  return {
      type: END_EXPENT_DIRECTORY_PRODUCT_SEARCH,
      payload:{
          searchQuery,success,error,isTabular
      }
  }
}

export function fetchExpentDirectoryProducts(searchQuery,isTabular) {
  const api_server = Environment.api_host("SEARCH");
  const timestamp  = (new Date()).getTime();
  const url        = `${api_server}/v2/dsl/expent_catalogue?t=${timestamp}`;
  return async (dispatch,getState) => {
      dispatch(startExpentDirectoryProductSearch(searchQuery));
      try{
          let [response,error] =  await HttpFactory.instance().postMethod(url, searchQuery, true);
          dispatch(endExpentDirectoryProductSearch(searchQuery, response,error,isTabular));
      } catch(e){
          dispatch(endExpentDirectoryProductSearch(searchQuery, null,{
              message:e.message
          },isTabular));
          return;
      }
  };
} 


