import {HttpFactory} from "../../services/httpService";
import * as Environment from "../../util/Environment";

export const START_DELETE_PRODUCT_RISK     =   "START_DELETE_PRODUCT_RISK";
export const END_DELETE_PRODUCT_RISK       =   "END_DELETE_PRODUCT_RISK";

export const START_FETCH_PRODUCT_RISKS = "START_FETCH_PRODUCT_RISKS"
export const END_FETCH_PRODUCT_RISKS = "END_FETCH_PRODUCT_RISKS"

export const SHOW_RISK_UPDATE_SCORE_DIALOG = "SHOW_RISK_UPDATE_SCORE_DIALOG"
export const HIDE_RISK_UPDATE_SCORE_DIALOG = "HIDE_RISK_UPDATE_SCORE_DIALOG"

export const START_FETCH_PRODUCT_RISK_SCORE_UPDATE = "START_FETCH_PRODUCT_RISK_SCORE_UPDATE"
export const END_FETCH_PRODUCT_RISK_SCORE_UPDATE = "END_FETCH_PRODUCT_RISK_SCORE_UPDATE"

export const START_FETCH_PRODUCT_RISK_UPDATE = "START_FETCH_PRODUCT_RISK_UPDATE"
export const END_FETCH_PRODUCT_RISK_UPDATE = "END_FETCH_PRODUCT_RISK_UPDATE"

export const START_FETCH_PRODUCT_RISK_ADD = "START_FETCH_PRODUCT_RISK_ADD"
export const END_FETCH_PRODUCT_RISK_ADD = "END_FETCH_PRODUCT_RISK_ADD"

export const START_FETCH_PRODUCT_RISK_BY_ID = "START_FETCH_PRODUCT_RISK_BY_ID"
export const END_FETCH_PRODUCT_RISK_BY_ID = "END_FETCH_PRODUCT_RISK_BY_ID"



export function showUpdateRiskScoreDialog(risk) {
    return {
        type: SHOW_RISK_UPDATE_SCORE_DIALOG,
        payload: {risk}
    };
}

export function hideUpdateRiskScoreDialog() {
    return {
        type: HIDE_RISK_UPDATE_SCORE_DIALOG,
        payload: {}
    };
}



function startFetchProductRisk(){
    return {
        type:START_FETCH_PRODUCT_RISKS,
        payload:{}
    };
}

function endFetchProductRisk(success,error){
    return {
        type:END_FETCH_PRODUCT_RISKS,
        payload:{
            success,error
        }
    };
}

export  function fetchProductRisk(queryParam){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/product/risk/register?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductRisk());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endFetchProductRisk(response,error));
        } catch(e){
            dispatch(endFetchProductRisk(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startAddProductRisk(){
    return {
        type:START_FETCH_PRODUCT_RISK_ADD,
        payload:{}
    };
}

function endAddProductRisk(success,error){
    return {
        type:END_FETCH_PRODUCT_RISK_ADD,
        payload:{
            success,error
        }
    };
}

export  function addProductRisk(queryParam){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/product/risk?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddProductRisk());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endAddProductRisk(response,error));
        } catch(e){
            dispatch(endAddProductRisk(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startUpdateProductRisk(){
    return {
        type:START_FETCH_PRODUCT_RISK_UPDATE,
        payload:{}
    };
}

function endUpdateProductRisk(success,error){
    return {
        type:END_FETCH_PRODUCT_RISK_UPDATE,
        payload:{
            success,error
        }
    };
}

export  function updateProductRisk(riskId,queryParam){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/product/risk/${riskId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateProductRisk());
        try{
            let [response,error]    =   await await HttpFactory.instance().putMethod(url,queryParam,false);
            dispatch(endUpdateProductRisk(response,error));
        } catch(e){
            dispatch(endUpdateProductRisk(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startDeleteProductRisk() {
    return {
        type: START_DELETE_PRODUCT_RISK,
        payload: null
    };
}

function endDeleteProductRisk(success, error) {
    return {
        type: END_DELETE_PRODUCT_RISK,
        payload: {
            success, error
        }
    };
}

export function deleteProductRisk(riskId) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/product/risk/${riskId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteProductRisk());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteProductRisk(response, error));
        } catch (e) {
            dispatch(endDeleteProductRisk(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchRiskById(){
    return {
        type:START_FETCH_PRODUCT_RISK_BY_ID,
        payload:{}
    };
}

function endFetchRiskById(success,error){
    return {
        type:END_FETCH_PRODUCT_RISK_BY_ID,
        payload:{
            success,error
        }
    };
}

export  function fetchRiskById(riskId){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/product/risk/${riskId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchRiskById());
        try{
            let [response,error]    =   await await HttpFactory.instance().getMethod(url,false);
            dispatch(endFetchRiskById(response,error));
        } catch(e){
            dispatch(endFetchRiskById(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startUpdateProductRiskScore(){
    return {
        type:START_FETCH_PRODUCT_RISK_SCORE_UPDATE,
        payload:{}
    };
}

function endUpdateProductRiskScore(success,error){
    return {
        type:END_FETCH_PRODUCT_RISK_SCORE_UPDATE,
        payload:{
            success,error
        }
    };
}

export  function updateProductRiskScore(productId,riskId, queryParam){
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org/product/${productId}/risk/${riskId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateProductRiskScore());
        try{
            let [response,error]    =   await await HttpFactory.instance().putMethod(url,queryParam,false);
            dispatch(endUpdateProductRiskScore(response,error));
        } catch(e){
            dispatch(endUpdateProductRiskScore(null,{
                message:e.message
            }));
            return;
        }
    };
} 