import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide,CircularProgress     } from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import { hideEvaluationFinishDialog,showSendNotificationDialog } from "redux/evaluation/metadata/action"
import SqureButton from 'Components/Common/SqureButton';
import {fetchChildEvaluation} from "redux/evaluation/link/action"
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen:state.evaluationMetaData.isOpenFinishDialog,
    orguser:state.orgUser,
    childEvaluationFetchProg:state.evaluationLink.childEvaluationFetchProg,
    childEvaluation:state.evaluationLink.childEvaluation,
});

const connectionActions = {
    hideDialog:hideEvaluationFinishDialog,
    fetchChildEvaluation:fetchChildEvaluation,
    showSendNotificationDialog: showSendNotificationDialog,
    
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    actions:{
        width:"80%",
        margin:"0 auto",
        textAlign:"center"
    },
    action:{
        marginBottom:theme.spacing(2)
    }
});

class FinishDialog extends React.Component {

    constructor(props){
        super(props);
        this.nextPage = this.nextPage.bind()
       
    }

    componentDidUpdate(prevProps) {
        if(this.props.isOpen && prevProps.isOpen === false){
            this.props.fetchChildEvaluation(this.props.match?.params?.evaluation_id);
        }
        if(this.props.childEvaluationFetchProg === false && prevProps.childEvaluationFetchProg === true){
            if(this.props.childEvaluation.length === 0){
                this.nextPage();
                this.props.hideDialog();
            }
        }
    }

    getConfigValue = (array, key, value) => {
        if(value) {
            let result = '';
            try {
                result = array.find(element => element[key] === value)['Value'];
            } catch(e) {

            }
            return result || '';
        }
    }
    validateType =(value)=> {
        try{
             var re = /\S+@\S+\.\S+/;
 
             if(re.test(value)){
                 return 1;
             }else if(this.isValidHttpUrl(value)){
                 return 2;
             }else{
                 return 0;
             }
        }catch(e){
            return 0
        }
     }

     isValidHttpUrl =(string)=> {
        let url;
        
        try {
          url = new URL(string);
        } catch (_) {
          return false;  
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
   }

    nextPage = () => {
        const CompletionNotificationValue = this.getConfigValue(this.props?.orguser?.fetchAllOrgSettingsSuccess, 'SettingName', 'CompletionNotification' )
        let type = this.validateType(CompletionNotificationValue)
        if(type === 1){
            this.props.showSendNotificationDialog(CompletionNotificationValue);
        }else if(type === 2){
            window.open(CompletionNotificationValue, '_blank');
        }else{
            this.props.history.push("/app/evaluate")
        }
    }

    render() {
        const classes = this.props.classes;
        return <Dialog 
                    onClose={this.props.hideDialog} 
                    aria-labelledby="app-evFinishDialog" 
                    open={this.props.isOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"sm"}
                    scroll="body"
                    id="evFinishDialog"
                    >
                        <DialogContent classes={{root:classes.dialogAction}} >
                            {!this.props.childEvaluationFetchProg && <div className={classes.actions}>
                                {this.props.childEvaluation !== null && this.props.childEvaluation.map((e)=>{
                                    return <div key={"finish"+e.ID} className={classes.action}>
                                        <SqureButton  fullWidth variant={"outlined"} onClick={()=> {
                                            this.props.history.push("/app/evaluate/"+e.ID)
                                            this.props.hideDialog();
                                        }}> Move to {e.Type === 1?"Evaluation":"Review"} [{e.Name}]</SqureButton>
                                    </div>
                                })}
                                <div className={classes.action}>
                                    <SqureButton  fullWidth variant={"contained"} onClick={()=> {
                                        this.nextPage();
                                        this.props.hideDialog();
                                    }}> Move Next</SqureButton>
                                </div>
                            </div>}
                            {this.props.childEvaluationFetchProg && <div className={classes.actions}>
                                <CircularProgress />
                            </div>}
                        </DialogContent>

                </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(FinishDialog));