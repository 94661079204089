import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import classnames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import { createStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, Avatar } from '@material-ui/core';
import Image from 'Components/Common/image'
import Tooltip from "@material-ui/core/Tooltip";


const connectedProps = (state) => ({
  userType: state.authUser.user?.Role,
});

const connectionActions = {

}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  container: {
    position: "relative",
    "& [class*=MuiAvatar-root]": {
      width: 27,
      height: 27,
      fontSize: 13,
      marginRight: 5
    },
    "& [class*=MuiAvatarGroup-avatar]": {
      margin: 0,
    },
  },
  root: {
    position: "relative",
    // height:"150px",
    border: "solid 3px",
    marginTop: theme.spacing(5),
    background: theme.palette.common.white,
    borderRadius: theme.spacing(2),
    zIndex: 10,
    padding: theme.spacing(0, 0.5),
    paddingBottom: theme.spacing(2),
    cursor: "pointer"
  },
  layer1: {
    width: "90%",
    position: "absolute",
    border: "solid 2px",
    borderRadius: theme.spacing(2),
    height: "100px",
    left: 0,
    right: 0,
    margin: '0 auto',
    zIndex: "9",
    top: theme.spacing(-1),

  },
  cardTitle: {
    fontSize: theme.spacing(2),
    textAlign: "center",
    marginBottom: '10px',
    marginTop: '37px',
    minHeight: '36px',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 15px 0'
  },
  cardSubTitle: {
    color: "#282D30",
    opacity: 0.3,
    textAlign: "center",
    fontSize: theme.spacing(1.5),
    marginBottom: '20px',
    fontWeight: 500
  },
  cardUpdatedAt: {
    color: "#282D30",
    opacity: 0.3,
    textAlign: "left",
    fontSize: theme.spacing(1.5),
    // marginBottom: '20px',
    fontWeight: 500
  },
  cardYellow: {
    '& $root': {
      borderColor: "#F8B53B"
    },
    '& $layer1': {
      borderColor: "#F8B53B",
      opacity: 0.4,
      background: "#FFF"
    }
  },
  cardGreen: {
    '& $root': {
      borderColor: "#37C93D"
    },
    '& $layer1': {
      borderColor: "#37C93D",
      opacity: 0.4,
      background: "#FFF"
    }
  },
  "iconSection": {
    textAlign: "right",
    width: "100%",
    paddingRight: '15px'
  },
  "iconButton": {
    color: "#BEC0C0"
  },
  cardAction: {
    paddingLeft: '15px',
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'center flex-start',
    alignItems: 'center'
  },
  assignedUsers: {
    margin: '0px',
    listStyle: 'none',
    padding: '0px',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
  },
  userCard: {
    display: 'inline-block',
    width: '25px',
    height: '25px',
    textAlign: 'center',
    textTransform: 'uppercase',
    background: '#f4f4f4',
    marginRight: '5px',
    borderRadius: '50%',
    lineHeight: '25px',
    fontSize: '12px'
  },
  userTotal: {
    display: 'inline-block',
    width: '25px',
    height: '25px',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginRight: '5px',
    borderRadius: '50%',
    lineHeight: '25px',
    fontSize: '8px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #008BE4',
    font: 'normal normal medium 12px/24px Inter',
    letterSpacing: '0px',
    color: '#282D30',
    opacity: 0.3
  },
  product_logo_container: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'center flex-start',
    alignItems: 'center',
    paddingLeft: '10px',
    marginBottom: 8
  },
  product_logo: {
    float: 'left',
    width: '35px',
    height: '35px',
    border: '1px solid #e8e7e7',
    borderRadius: '4px',
    marginRight: '10px',
    textAlign: 'center',
    lineHeight: '33px',
    fontWeight: 600,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'scale-down'
    }
  },
  evaluateType: {
    borderRadius: '5px',
    position: 'absolute',
    textAlign: 'left',
    fontSize: '11px',
    padding: '3px 10px',
    right: '10px',
    top: '10px'
  },
  isEvaluate: {
    background: '#E6FFF3',
    color: '#2EB77D',
  },
  isReview: {
    background: '#cce5ff',
    color: '#004085',
  },
  pDetailsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  usersIcons: {
    textAlign: 'right'
  }
});

class VendorEvaluationCard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {


    }

    this.avatarName = this.avatarName.bind(this);

  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  render() {
    const classes = this.props.classes;

    const formateDate = (createdAt) => {
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const date = new Date(createdAt);
      return date.getDate() + 'th ' + months[date.getMonth()];
    }

    const query_type = this.props.queryParams?.type?.split(',').map(Number) || [];


    return <div className={classes.container + ' ' + (this.props.layout === 'inProgress' ? classes.cardYellow : classes.cardGreen)} onClick={this.props.onClick}>
      <div className={classes.layer1} />
      <div className={classes.root}>
        {
          query_type.length > 0 &&
          <span className={classnames(classes.evaluateType, this.props.Type === 1 ? classes.isEvaluate : classes.isReview)}>{this.props.Type === 1 ? 'Evaluation' : 'Review'}</span>
        }

        <Typography variant={"h5"} className={classes.cardTitle}>{this.props.Name}</Typography>

        <Typography variant={"subtitle1"} className={classes.cardSubTitle}>{formateDate(this.props.CreatedAt)}</Typography>

        <div className={classes.pDetailsBox}>
          {this.props.sellerPage ? (
            <div className={classes.product_logo_container}>
              <div className={classes.product_logo} title={this.props.Name}><Image src={this.props.ProductLogo} alt={this.props.Name} title={this.props.Name} /></div>
            </div>
          ) : (
            <div className={classes.product_logo_container}>
              {
                this.props.Products?.slice(0, this.props.Products.length < 5 ? this.props.Products.length : 3).map((item, i) => {
                  return (<div className={classes.product_logo} title={item.Name}><Image src={item.ProductLogo} alt={item.Name} title={item.Name} /></div>)
                })
              }
              {
                this.props.Products.length > 4 &&
                <div className={classes.product_logo} title={this.props.Products.length - 3 + 'more product'}> + {this.props.Products.length - 3}</div>
              }
            </div>
          )}
          

          {this.props.isSurveyCard && this.props.users !== undefined && this.props.users.length > 0 &&
            <div className={classes.usersIcons}>
              {this.props.userType === 'OrgAdmin' && <div className={classes.product_logo_container}>
                {
                  this.props.users?.slice(0, this.props.users.length < 3 ? this.props.users.length : 2).map((item, i) => {
                    return (
                      <Tooltip
                        placement="top"
                        title={
                          <span
                            style={{
                              fontSize: "14px",
                              whiteSpace: "pre-line",
                              display: "inline-block",
                              height: "25px",
                              lineHeight: "25px",
                            }}
                          >
                            {item.Name + "\n" + item.Email}
                          </span>
                        }
                        arrow
                      >
                        <Avatar key={i}>
                          {" "}
                          {this.avatarName(item.Name)}
                        </Avatar>
                      </Tooltip>
                    )
                  })
                }
                {
                  this.props.users.length > 2 &&
                  <div className={classes.productLogo} title={this.props.users.length - 2 + ' more collaborators'}> + {this.props.users.length - 2}</div>
                }
              </div>}
            </div>}
        </div>
        <Grid container spacing={0}>
          <Grid item xs={9} className={classes.cardAction}>
            <Typography variant={"subtitle1"} className={classes.cardUpdatedAt}>Updated: {this.timeSince(this.props.UpdatedAt)} ago</Typography>
          </Grid>
          <Grid item xs={3} className={classes.iconSection}>
            <IconButton size={"small"} className={classes.iconButton}>
              <ArrowForwardIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </div>
  }
}



VendorEvaluationCard.prototype.timeSince = function (date) {

  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}


VendorEvaluationCard.prototype.getInitials = function (glue) {
  if (typeof glue == "undefined") {
    var glue = true;
  }
  var initials = glue.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
  if (glue) {
    return initials.join('');
  }
  return initials;
};

export default connector(compose(
  withStyles(styles)
)(VendorEvaluationCard));