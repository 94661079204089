import React, { useState } from 'react';
// import _ from "underscore";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Avatar } from "@material-ui/core";
import * as Validator from "util/Validation";
import { getMentionedUsersIds } from "util/Common";
import _ from "underscore";
// icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import FlagIcon from '@material-ui/icons/Flag';
import { Send as SendIcon } from '@material-ui/icons';

// Redux
import { addRequirementResponse, fetchResponseForThread, addReplyForResponseThread, updateResponseThread, deletResponseThread, clearState } from "redux/evaluation/criteria/action";
import { fetchUsers } from "redux/usersSettings/action";
import { showSnackBar } from "redux/snackbar/action";

import {
  showConfirmCallbackDialog,
  hideConfirmCallbackDialog
} from "redux/applications/action";
// Components
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import RequirementReply from 'Components/Common/FormReply/RequirementReply';

import { fetchRemoveRequirementCollaborator } from "redux/evaluate/action";
import { addRequirementColloborator,fetchRequirementResponse } from "redux/evaluation/criteria/action"

const connectedProps = (state) => ({
  authUser: state.authUser.user,
  usersSettings: state.usersSettings,
  requirementResponses: state.evaludationCriteria.requirementResponses,
  fetchResponseThreadProgress: state.evaludationCriteria.fetchResponseThreadProgress,
  dataResponseThread: state.evaludationCriteria.dataResponseThread,
  deleteResponseThread: state.evaludationCriteria.deleteResponseThread,
  addReplyResponseThread: state.evaludationCriteria.addReplyResponseThread,
  addReplyResponseThreadInprogress: state.evaludationCriteria.addReplyResponseThreadInprogress,
  updateResponseThread: state.evaludationCriteria.updateResponseThread,
  updateResponseThreadInprogress: state.evaludationCriteria.updateResponseThreadInprogress,
  criteriaUserRoleInprogress: state.evaludationCriteria.criteriaUserRoleInprogress,
  deleteRequirementCollaboratorInprogress: state.evaluate.deleteRequirementCollaboratorInprogress,
});

const connectionActions = {
  _addRequirementResponse: addRequirementResponse,
  fetchResponseForThread: fetchResponseForThread,
  addReplyForResponseThread: addReplyForResponseThread,
  _updateResponseThread: updateResponseThread,
  deletResponseThread: deletResponseThread,
  fetchRequirementResponse: fetchRequirementResponse,
  removeRequirementCollaborator: fetchRemoveRequirementCollaborator,
  _fetchUsers: fetchUsers,
  clearState: clearState,
  showSnackBar: showSnackBar,
  addRequirementColloborator: addRequirementColloborator,
  showConfirmCallbackDialog: showConfirmCallbackDialog,
  hideConfirmCallbackDialog: hideConfirmCallbackDialog,
}
var connector = connect(connectedProps, connectionActions);


const useMessageStyles = createStyles((theme) => ({
  root: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'flex-start space-between',
    alignItems: 'flex-start',
    // borderBottom: '1px solid #EEEEEE'
  },
  userAvatar: {
    width: '20px',
    margin: '15px'

  },
  avatar: {
    width: '60px',
    height: '60px',
    margin: '0px auto',
    borderRadius: '50%',
  },
  container: {
    width: 'calc(100% - 60px)',
    margin: '15px'
  },
  userName: {
    fontWeight: 700,
    color: '#000000',
    margin: '0px',
    fontSize: '15px',
    marginBottom: '5px',
  },
  rowFlex: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
  },
  rowSpaceBtw: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'center space-between',
    alignItems: 'center'
  },
  messageBody: {
    fontSize: '12px',
    marginBottom: '8px',
    paddingRight: '20px'
  },
  mentionedUser: {
    fontWeight: 500,
    color: '#4A87F8'
  },
  footer: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  actionCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: "100%",
    marginBottom: 10,
  },
  leftColSpan: {
    fontWeight: 'normal',
    letterSpacing: '0px',
    // opacity: 0.3,
    marginRight: '25px',
    fontSize: '1rem',
    textDecoration: 'underline',
    color: '#4A87F8'
  },
  rightCol: {
    width: '50%'
  },
  askSeller: {
    textDecoration: 'underline',
    fontWeight: 500,
    color: '#4A87F8',
    fontSize: '14px',
    display: 'block',
    textAlign: 'right'
  },
  messageReply: {
    fontSize: '12px',
    background: '#F4F6F8 0% 0% no-repeat padding-box',
    borderRadius: '7px',
    marginTop: '20px',
    display: 'block',
    width: '100%',
    border: ' 0px none',
    outline: 'none',
    padding: '10px',
    letterSpacing: '0px',
    color: '#B2B2B2',
    fontFamily: 'Poppins'
  },
  mentionUser: {
    fontWeight: 500,
    color: '#4A87F8'
  },
  flagSection: {
    display: 'flex'
  },
  correctFlag: {
    padding: '0px 2px',
    cursor: 'pointer',
    color: '#4b86f8'
  },
  wrongFlag: {
    padding: '0px 2px',
    cursor: 'pointer',
    color: 'red'
  },
  responseNote: {
    "& [class*='editableField']": {
      minHeight: 'auto !important',
      margin: '0px !important'
    }
  },

  responseActionContainer: {
    marginTop: theme.spacing(2)
  },
  resList: {
    display: 'flex',
    flexGrow: 1
  },
  resListItem: {
    marginRight: '5px',
    padding: 0,
    borderRadius: '4px',
    border: '1px solid #707070',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    width: theme.spacing(20),
    height: theme.spacing(3.2)
  },
  resListItemActive: {
    marginRight: '5px',
    padding: 0,
    border: '1px solid #007BF7',
    justifyContent: 'center',
    color: ' #007BF7',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    width: theme.spacing(20),
    height: theme.spacing(3.2)
  },
  reqMatchDiv: {
    background: '#f9f9f9',
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(1, 1),
    color: '#282D30',
    fontWeight: 400,
    fontSize: theme.spacing(1.8)
  },
  selectedReqOption: {
    border: '1px solid #707070',
    color: '#282D30',
    borderRadius: '4px',
    padding: theme.spacing(0, 4),
    fontSize: theme.spacing(1.8)
  },
  disableForm: {
    pointerEvents: 'none'
  },
  slateWrap: {
    "& [class*='editableField']": {
      minHeight: "70px !important",
    },
  }
}))

class UserMessages extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      note: '',
      editThread: 0,
      showMainReply: false,
      isShowReplyBtn: true,
      replyMessage: '',
      showThread: false,
      addingCollaborator: false,
      removingCollaborator: false,
      removableCollaboratorIds: [],
      removableCollaboratorNames: [],
    }
  }

  componentDidMount() {
    this.props._fetchUsers(0, 100, '')
    this.props.fetchResponseForThread(this.props.evaluation_id, this.props.response.ID)
  }

  componentDidUpdate(prevProps) {
    if (this.props.deleteResponseThread) {
      this.setState({
        replyMessage: '',
        showMainReply: false,
        isShowReplyBtn: true
      })
      this.props.clearState('delete_thread')
      this.props.clearState('reply_response')
      this.props.clearState('update_response')
      this.props.fetchResponseForThread(this.props.evaluation_id, this.props.response.ID)
    }

    if (!this.props.addReplyResponseThreadInprogress && prevProps.addReplyResponseThreadInprogress) {
      let note = this.props.addReplyResponseThread.Response;
      this.handleUpdateMentionedCollaborators(note);
    }

    if (!this.props.updateResponseThreadInprogress && prevProps.updateResponseThreadInprogress) {
      let note = this.props.updateResponseThread.Response;
      this.handleUpdateMentionedCollaborators(note);
    }
  }

  handleUpdateMentionedCollaborators = (note) => {
    this.setState({ removableCollaboratorIds: [] });
    let collaboratorIds = [];
    if (Validator.isJson(note) && Array.isArray(JSON.parse(note))) {
      collaboratorIds = getMentionedUsersIds(JSON.parse(note));
    }
    
    let prevNote = this.props.response?.Note || "[]";
    let prevCollaboratorIds = [];
    if (Validator.isJson(prevNote) && Array.isArray(JSON.parse(prevNote))) {
      prevCollaboratorIds = getMentionedUsersIds(JSON.parse(prevNote));
    }
    
    let UniqeCollaboratorIds = _.uniq(collaboratorIds);

    let existingCollaborators = this.props.requirementResponses?.colloborator || [];
    
    if(existingCollaborators.length>0){
      let existingIds = existingCollaborators.map(user=>user.ID);
      let removableCollaboratorIds = prevCollaboratorIds.filter(id=> (!UniqeCollaboratorIds.includes(id) && existingIds.includes(id)));
      
      if(removableCollaboratorIds.length > 0){
        let removableCollaboratorNames = existingCollaborators.filter(user=> removableCollaboratorIds.includes(user.ID)).map(O=>O.Name).join(', ');
        
        this.setState({
          removableCollaboratorNames: removableCollaboratorNames,
          removableCollaboratorIds: removableCollaboratorIds,
          removingCollaborator: true,
        },()=>{
          // this.props.showConfirmCallbackDialog();
        }) 
      }
      UniqeCollaboratorIds = UniqeCollaboratorIds.filter(id=> !existingIds.includes(id))
    }

    if(UniqeCollaboratorIds.length>0){
      this.setState({
        addingCollaborator: true,
      },()=>{
        UniqeCollaboratorIds.forEach(userId=>{
          this.props.addRequirementColloborator(
            this.props.match.params.evaluation_id,
            this.props.requirementResponses.criteriaId,
            this.props.requirementResponses.id,
            userId)
        })
      }) 
    }
    this.setState({
      replyMessage: '',
      showMainReply: false,
      isShowReplyBtn: true
    })
    this.props.clearState('delete_thread')
    this.props.clearState('reply_response')
    this.props.clearState('update_response')
    this.props.fetchResponseForThread(this.props.evaluation_id, this.props.response.ID)
  }

  // removeCollabCallBack = () => {
  //   this.props.hideConfirmCallbackDialog();
  //   this.state.removableCollaboratorIds.forEach(userId=>{
  //     let data = {}
  //     data.evId = this.props.match.params.evaluation_id;
  //     data.criteriaId = this.props.requirementResponses.criteriaId;
  //     data.requirementId = this.props.requirementResponses.id;
  //     data.collaboratorId = userId;
  //     this.props.removeRequirementCollaborator(data);
  //   });
  //   this.setState({ 
  //     removableCollaboratorIds: [], 
  //     removableCollaboratorName: [] 
  //   })
  // };

  AddReplyResponse = () => {
    const { response } = this.props;
    this.props.addReplyForResponseThread(this.props.evaluation_id, response.ID, this.state.replyMessage)
  }

  AddResponse = () => {
    const { evaluation_id, product, requirement } = this.props;
    this.props._addRequirementResponse(
      evaluation_id,
      product.ID,
      requirement.id, {
      note: this.state.note,
      value: 1
    }
    );
    // this.onBlurInput(`requirement_${criteria.id}_${requirement.id}`)
  }

  onBlurInput = (className) => {
    this.setState({
      editNote: false
    }, () => {
      try {
        const responseNote = document.querySelectorAll(`.response_note .${className}`)
        const rootRequirementHight = document.querySelectorAll(`.${className}`);
        if (responseNote.length === 0 && rootRequirementHight && rootRequirementHight.length) {
          for (var i = 0; i < rootRequirementHight.length; i++) {
            rootRequirementHight[i].classList.remove('rootRequirementHight');
          }
        }
      } catch (e) {

      }
    });
  }

  render() {

    const avatarName = (name) => {
      if (name) {
        return name.substring(0, 1);
      }
    }

    const { classes, response, replyResponse, flag, dataResponseThread } = this.props;

    let responseThread = dataResponseThread ? dataResponseThread[`${this.props.evaluation_id}_${response.ID}`] : [];
    responseThread = responseThread?.sort(function (a, b) {
      return new Date(b.CreatedAt) - new Date(a.CreatedAt);
    })

    responseThread = ((responseThread && responseThread.reverse()) || []);

    let usersList = (this.props.usersSettings?.users || []);

    const { product, requirement } = this.props;

    let selectOptionsMap = {
      10: 'Yes',
      5: 'Partial',
      1: 'No',
      '-1': 'Not Applicable',
    }

    if (response.length <= 0) {
      return '';
    }

    return (
      <div className={classes.root}>
        <div className={classes.userAvatar}>
          {/* <Image className={classes.avatar} src={'https://gravatar.com/avatar/e7340866aaef964136051ca9f10c1cb8?s=200&d=mp&r=x'} /> */}
          <Avatar style={{ width: '30px', height: '30px', fontSize: '16px' }} alt={response?.User?.Name} >{avatarName(response?.User?.Name)}</Avatar>
        </div>
        <div className={classes.container}>
          <div className={classes.rowSpaceBtw}>
            <h5 className={classes.userName}>{response?.User?.Name || 'User'}</h5>
          </div>

          {/* Multi Choice Questions */}

          {
            (requirement?.answer_type === 1 || this.props.communityTab === true) &&
            <>
              {response && response.Note && response?.Note.length > 0 && <div className={classes.messageBody + ' ' + classes.rowSpaceBtw}>
                <div style={{ width: 'calc( 100% - 0px )', padding: '3px' }}>
                  <div className={classes.responseNote}>
                    <SlateInputField
                      placeholder=""
                      enableMention={true}
                      isToolBar={false}
                      readOnly={true}
                      as={SlateInputField}
                      formControlStyle={{ border: 'none', maxHeight: 130 }}
                      initValue={response?.Note}
                      textContainerStyle={{ color: "#707070", fontSize: 14 }}
                      paperStyle={{ fontWeight: 400 }}
                      style={{ marginBottom: 0 }}
                    />
                  </div>
                </div>
                {/* <span className={classes.mentionedUser}>@Tony</span> */}
                {flag === true &&
                  <div className={classnames(classes.flagSection, 'evaluate-flag-section')}>
                    <div className={classes.correctFlag} title={'Predicted correctly?'}><FlagIcon /></div>
                    <div className={classes.wrongFlag} title={'Have to improve the predictions?'}><FlagIcon /></div>
                  </div>
                }
              </div>}
            </>
          }

          {true &&
            <div>
              <div className={classes.disableForm}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].includes(requirement.answer_type) && <RequirementReply
                  additional_data={requirement?.options}
                  options={requirement?.options}
                  type={requirement?.answer_type}
                  title={requirement?.name}
                  description={requirement?.description}
                  isRequired={requirement?.is_mandatory}
                  key={requirement.id + '_sectionrequirement'}
                  index={requirement.id}
                  value={response !== undefined && response !== null && response.UserOptions !== null ? response?.UserOptions[0]?.Answer : ''}
                  selectedOptions={response !== undefined && response !== null && response.UserOptions !== null ? response?.UserOptions : []}
                  evaluationId={this.props.match.params.evaluation_id}
                  orgId={this.props.OrgID}
                  workflowId={this.props.workflow_id}
                  isFormEditable={false}
                  itemId={requirement.id}
                  sectionId={requirement.id}
                  maxScore={100}
                  item={requirement}
                  error={requirement.error || ''}
                  isShowSaveButton={false}
                  isUpdateOnChange={false}
                  from={'BUYER'}
                  isSummaryPage={true}
                  isOtherOption={requirement?.is_other_option}
                  onChange={(output) => {
                    // console.log(response, 'Response')
                    // console.log(output, 'output')
                    let parameters = {};
                    if ([1, 5, 7, 8, 9, 10, 11, 12, 13, 14].includes(requirement.answer_type)) {
                      parameters = {
                        note: response === null ? null : response.Note,
                        value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
                        response_options: {
                          answer: output
                        }
                      };
                    }

                    if ([2, 3].includes(requirement.answer_type)) {
                      parameters = {
                        note: response === null ? null : response.Note,
                        value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
                        response_options: {
                          option_ids: output,
                        }
                      };
                    }

                    if ([15].includes(requirement.answer_type)) {
                      parameters = {
                        note: response === null ? null : response.Note,
                        value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
                        response_options: {
                          option_ids: output,
                        }
                      };
                    }

                    this.props._addRequirementResponse(
                      this.props.match.params.evaluation_id,
                      product.ID,
                      requirement.id,
                      parameters);
                  }}

                  showUserAddDialog={(item) => {

                  }}
                  showTeamAddDialog={(data) => {

                  }}
                />}
              </div>



              {requirement.answer_type !== 1 && response && response.Note && response?.Note.length > 0 &&
                <Grid item xs={12}>
                  <div className={classes.responseNote}>
                    <SlateInputField
                      placeholder=""
                      enableMention={true}
                      isToolBar={false}
                      readOnly={true}
                      as={SlateInputField}
                      formControlStyle={{ border: 'none', maxHeight: 130 }}
                      initValue={response?.Note}
                      textContainerStyle={{ color: "#707070", fontSize: 14 }}
                      paperStyle={{ fontWeight: 400 }}
                      style={{ marginBottom: 0 }}
                    />
                  </div>
                </Grid>
              }

              <Grid item xs={12} className={response && response.Note && response?.Note.length > 0 ? '' : classes.responseActionContainer}>
                {this.props.enableScoring && requirement.answer_type !== 5 && this.props.scoring_mehanism === 'Textual' && <Grid item lg={8} className={classes.reqMatchDiv}>
                  Score <span className={classes.selectedReqOption}>{response.Response === 0 ? '-' : selectOptionsMap[response.Response]}</span>
                </Grid>}

                {this.props.enableScoring && requirement.answer_type !== 5 && this.props.scoring_mehanism === 'Numerical' && <Grid item lg={8} className={classes.reqMatchDiv}>
                  Score <span className={classes.selectedReqOption}>{response.Response === -1 ? 'Not Applicable' : response.Response}</span>
                </Grid>}
             
              </Grid>
            </div>
          }

          {this.state.showThread === true &&
            <>
              <div className={classes.messageBody + ' ' + classes.rowSpaceBtw}>
                <div style={{ width: '100%', paddingLeft: '' }}>
                  {responseThread && responseThread?.length > 0 && responseThread.map((response, k) => {
                      return <>
                        <ReplySection key={k} thread={response} {...this.props} users={usersList} />
                      </>
                    })
                  }
                </div>
              </div>
              <div className={classes.footer}>
                <div className={classes.actionCol}>
                  {this.state.isShowReplyBtn === true && replyResponse === true && <span className={classnames(classes.leftColSpan, 'evaluate-reply')} onClick={() => {
                    this.setState({ showMainReply: true, isShowReplyBtn: false })
                  }
                  } style={{ cursor: 'pointer' }}>Reply</span>}
                  {
                    this.state.showThread === false && responseThread && responseThread?.length > 0 &&
                    <span style={{ cursor: 'pointer' }} onClick={() => { this.setState({ showThread: true }) }} className={classnames(classes.leftColSpan)}>{responseThread?.length || 0} {responseThread && responseThread?.length === 1 ? 'Reply' : 'Replies'}</span>
                  }
                </div>
              </div>
            </>
          }
          {this.state.showThread === false &&
            <div className={classes.footer}>
              <div className={classes.actionCol}>
                {
                  this.state.showThread === false && responseThread && responseThread?.length > 0 &&
                  <span style={{ cursor: 'pointer' }} onClick={() => { this.setState({ showThread: true }) }} className={classnames(classes.leftColSpan)}>{responseThread?.length || 0} {responseThread && responseThread?.length === 1 ? 'Reply' : 'Replies'}</span>
                }
              </div>
            </div>
          }
          {this.state.isShowReplyBtn === true && replyResponse === true && this.props.fetchResponseThreadProgress === false && responseThread && responseThread?.length === 0 &&
            <span className={classnames(classes.leftColSpan, 'evaluate-reply')} onClick={() => { this.setState({ showMainReply: true, isShowReplyBtn: false }) }} style={{ cursor: 'pointer' }}>Reply</span>
          }

          {this.props.fetchResponseThreadProgress === false && this.state.showMainReply === true && <div className={classes.slateWrap}>
            <SlateInputField
              enableMention={true}
              style={{ width: '100%', marginTop: '12px' }}
              as={SlateInputField}
              isToolBar={true}
              onChangeEvent={(value) => {
                this.setState({ replyMessage: value })
              }}
              placeholder={"Type your response here."}
              initValue={''}
              formControlStyle={{  }}
              textContainerStyle={{ color: "#707070", fontSize: 14 }}
            />
            <div>
              <Button style={{ marginRight: 20 }} variant="contained" color="primary" endIcon={<SendIcon />} onClick={() => {
                this.AddReplyResponse()

              }}>
                Save
              </Button>
              <Button onClick={() => { this.setState({ showMainReply: false, isShowReplyBtn: true }) }} >
                Cancel
              </Button>
            </div>
          </div>}
        </div>
      </div>
    )
  }
}


// Component styles
const useStyles = makeStyles(theme => ({
  responseNote: {
    marginRight: 15,
    flex: 10,
    "& [class*='editableField']": {
      minHeight: 'auto !important',
      margin: '0px !important'
    },
    background: '#f0f0f08c',
    padding: '10px',
    borderRadius: '8px',
    paddingBottom: 0,
    fontSize: '13px'
  },
  replyActionContainer: {
    marginTop: '14px',
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'space-between',
    alignItems: 'flex-start'
  },
  userAvatar: {
    // marginRight: 15,
    flex: 1
  },
  replyAction: {
    // display: 'block',
    // paddingLeft: '50px',
    // textAlign: 'right',
    // paddingRight: '12px',
    '& svg': {
      width: '16px',
      color: '#bdbdbd',
      marginRight: '10px',
      cursor: 'pointer'
    }
  }
}));

function ReplySection(props) {
  const { thread } = props;

  const [edit, setEdit] = useState(0);

  const [reply, setSetReply] = useState('');

  const classes = useStyles();

  const avatarName = (name) => {
    if (name) {
      return name.substring(0, 1);
    }
  }

  const user = props.users.filter(user => user.ID === thread?.UserID)

  return (
    <div>
      <div className={classes.replyActionContainer} >
        <span className={classes.userAvatar}>
          <Avatar style={{ width: '25px', height: '25px', fontSize: '15px' }}  >{avatarName(user[0]?.Name || 'User')}</Avatar>
        </span>
        <span className={classes.responseNote}>
          <div style={{ display: 'flex', placeContent: 'space-between' }}>
            <span style={{ fontWeight: 600 }}>{user[0]?.Name || '...'}</span>
            <span className={classes.replyAction}>
              {
                edit > 0 &&
                <>
                  <SaveIcon onClick={() => {
                    var bodyText = document.querySelectorAll("#userMessageEdit1 .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
                    if (String(reply).trim().length === 0 || String(bodyText).trim().length === 0) {
                      props.showSnackBar("Please enter your response.", "error", 3000)
                      return
                    }
                    props._updateResponseThread(props.evaluation_id, thread?.ResponseID, thread?.ID, reply)
                  }} />
                  <CancelIcon onClick={() => { setEdit(0) }} />
                </>
              }
              {
                props.authUser.ID === thread?.UserID && edit === 0 &&
                <>
                  <EditIcon onClick={() => { setEdit(thread?.ID) }} />
                  <DeleteIcon style={{ marginRight: 0 }} onClick={() => { props.deletResponseThread(props.evaluation_id, thread?.ResponseID, thread?.ID) }} />
                </>
              }
            </span>
          </div>
          <SlateInputField
            placeholder=""
            enableMention={true}
            isToolBar={false}
            readOnly={true}
            as={SlateInputField}
            formControlStyle={{ border: 'none', background: 'transparent' }}
            initValue={thread?.Response}
            textContainerStyle={{ color: "#707070", fontSize: 14 }}
            paperStyle={{ fontWeight: 400, background: 'transparent' }}
            style={{ width: '100%', marginBottom: 0, background: 'transparent' }}
          />
        </span>
      </div>
      <span style={{ paddingLeft: '42px', fontSize: '12px' }}>{timeSince(thread?.CreatedAt)} ago</span>

      {
        edit === thread?.ID &&
        <div id='userMessageEdit1'>
          <SlateInputField
            style={{ width: '100%', height: 150, marginBottom: 0 }}
            placeholder=""
            enableMention={true}
            as={SlateInputField}
            initValue={thread?.Response}
            textContainerStyle={{ color: "#707070", fontSize: 14 }}
            paperStyle={{ fontWeight: 400 }}
            onChangeEvent={(value) => {
              setSetReply(value)
            }}
          />
        </div>
      }
    </div>
  );
}


function timeSince(date) {

  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    if (interval === 1) {
      return Math.floor(interval) + " year";
    } else {
      return Math.floor(interval) + " years";
    }
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " month";
    } else {
      return Math.floor(interval) + " months";
    }
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " day";
    } else {
      return Math.floor(interval) + " days";
    }
  }
  interval = seconds / 3600;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " hour";
    } else {
      return Math.floor(interval) + " hours";
    }
  }
  interval = seconds / 60;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " minute";
    } else {
      return Math.floor(interval) + " minutes";
    }
  }
  return Math.floor(seconds) + " seconds";
}


export default connector(compose(withRouter, withStyles(useMessageStyles))(UserMessages));