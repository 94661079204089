import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Button, Switch, Typography, Tooltip } from "@material-ui/core";
import classnames from "classnames";
import Alert from '@material-ui/lab/Alert';
import ReactSelect from "react-select";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
// import SlateEditorNew  from "Components/Common/SlateEditorNew";
import CodeIcon from '@material-ui/icons/Code';

import * as Validator from "util/Validation";

import { showSnackBar } from "redux/snackbar/action";

import CustomForm from "./CustomComponent/CustomForm";
import DecisionComponent from "./CustomComponent/DecisionComponent";
import ApprovalComponent from "./CustomComponent/ApprovalComponent";
import WrapperForm from "./CustomComponent/WrapperForm";
import MessageDialog from "Components/Common/MessageDialog";
import { fetchOrgTemplate } from "redux/templates/evaluation/org/action"

import {
  getOrgWorkflow, updateOrgWorkflowComponent,
} from "redux/evaluation/workflow/action";

import { copyWorkflowMasterToOrg } from "redux/master/workflow/action";

import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import IntegrationFormData from "./CustomComponent/IntegrationFormData";
import { getFormItemType } from "util/FormTypeOptions";
import SchemaTagSelectionDialog from "./Dialogs/SchemaTagSelectionDialog.jsx";

import deleteIcon from "assets/images/delete.svg";
import Image from "Components/Common/image.jsx";
import { Avatar } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import addUsers from "assets/images/add_profile.svg";
import SearchUserPoper from "Components/Common/TeamsAndUser/SearchUserPoper";
import { showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";

const connectedProps = (state) => ({
  masterTemplates: state.masterTemplateEvaluation.templates,
  fetchProg: state.masterTemplateEvaluation.fetchProg,
  orgTemplates: state.orgTemplateEvaluation.templates,
  masterForms: state.masterForms.forms,
  orgForms: state.orgForms.forms,
  fetchOrgFormsProgress: state.orgForms.fetchOrgFormsProgress,
  orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
  // masterWorkFlowList: state.evaluationWorkflow.masterWorkFlowList,
  orgWorkFlowProgress: state.evaluationWorkflow.orgWorkFlowProgress,
  masterWorkFlowList: state.masterWorkflow.masterWorkFlowList,
  components: state.masterWorkflow.components,
  copyMasterToOrgError: state.masterWorkflow.copyMasterToOrgError,
  copyMasterToOrgProgress: state.masterWorkflow.copyMasterToOrgProgress,
  copyMasterToOrgData: state.masterWorkflow.copyMasterToOrgData,

  workflowFormCompList: state.master.workflowFormCompList,
  orgWorkFlowCompList: state.evaluationWorkflow.orgWorkFlowCompList,
  updateOrgWorkFlowCompProgress: state.evaluationWorkflow.updateOrgWorkFlowCompProgress,

  orgTemplate: state.orgTemplateEvaluation.template,
  fetchTemplateProg: state.orgTemplateEvaluation.fetchTemplateProg,

  isOpenUserDialog: state.dialogs.isShowSearchUserPoperDialog,

  orgCatalogAllTabs: state.vendorCatalog.orgCatalogAllTabs,

});

const connectionActions = {
  showSnackBar: showSnackBar,
  getOrgWorkflow: getOrgWorkflow,
  copyWorkflowMasterToOrg: copyWorkflowMasterToOrg,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  updateOrgWorkflowComponent: updateOrgWorkflowComponent,
  fetchOrgTemplate: fetchOrgTemplate,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  answerType: {
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    marginTop: 30,
    placeContent: "start",
    alignItems: "center",
    maxWidth: "250px",
    "& label": {
      color: "#707070",
      marginRight: 20,
      fontSize: "14px",
      fontWeight: 500,
    },
    "& [class*='MuiSelect-root-']": {
      padding: "0.5em 0.75em",
      fontSize: "14px",
      color: "#707070",
      paddingRight: "32px",
    },
  },
  input: {
    minHeight: 38,
    padding: "2px 8px",
    border: "1px solid #cccccc",
    borderRadius: 3,
    fontSize: 15,
    color: "rgb(51, 51, 51)",
    width: '100%'
  },
  indexNumber: {
    width: 10,
    marginTop: 5,
  },
  inputSection: {
    display: "flex",
    marginTop: 10,
    justifyContent: "space-between",
    "& [class*=MuiAvatar-root]": {
      width: 30,
      height: 30,
      fontSize: 14
    },
  },
  requiredField: {
    color: "red",
    marginLeft: 5,
  },
  inputController: {
    marginLeft: 5,
    flex: 1,
  },
  image: {
    marginTop: 5,
    minWidth: 10,
    cursor: "pointer",
  },
  saveBtnSection: {
    position: "absolute",
    bottom: 10,
    right: 12,
    left: "auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: '#f1f1f1',
    padding: '10px'
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  newUserBtnDisabled: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    border: "none",
    cursor: "no-drop",
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  addMore: {
    fontSize: "12px",
    color: "#4175DF",
    cursor: "pointer",
    display: "inline-block",
    marginTop: 10,
  },
  label: {
    fontSize: 15,
    fontWeight: 500,
  },
  radioBox: {
    '& [class*=MuiFormControlLabel-label]': {
      fontSize: 15
    }
  },
  alerts: {
    marginTop: 10
  },
  tabs: {
    listStyle: "none",
    display: "flex",
    marginBottom: 10,
    padding: 0,
    marginTop: 0

  },
  tab: {
    borderBottom: "solid 1px #e0e0e0",
  },
  tabBtn: {
    padding: '5px 40px',
    borderRadius: 0
  },
  activeBtn: {
    color: "#4A87F8",
    borderBottom: "solid 2px #4A87F8",
  },
  customSection: {
    marginTop: theme.spacing(6)
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  inputBoxes: {
    marignTop: 21,
    width: '100%',
    '& input': {
      width: '100%'
    }
  },
  approvelCom: {
    display: 'flex',
    alignItems: 'center'
  },
  disabledDiv: {
    pointerEvents: 'none',
    opacity: 0.6,
  },
  diableDiv: {
    pointerEvents: 'none',
    opacity: 0.6,
  },
  documentTextArea: {
    "& [class*='editableField']": {
      maxHeight: '47vh !important;',
      minHeight: '47vh !important',
    }
  },
  richTextArea: {
    "& [class*='editableField']": {
      maxHeight: '20vh !important;',
      minHeight: '20vh !important',
    }
  },
  codeIcon: {
    cursor: 'pointer'
  },
  hint: {
    margin: 0,
    flex: 1,
    textAlign: 'right',
    fontSize: 12,
    color: '#b3a7a7'
  }
});

class WorkFlowConfigurationV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputField: [],
      error: '',
      success: '',
      active: 2,
      selectedComponentData: null,
      selectedComponent: null,
      isWrapperComponent: false,
      isShowURLConfig: false,
      singleSelectConfigOptions: [],
      criteriaName: [],
      approvalOverRide: false,
      selectedMasterWorkflow: null,
      formComponentId: '',
      integrationFormData: null,
      isDisableTemplateSelection: false,
      prevSelectedTemplate: '',
      showProjectSchema: false,
      selectedFieldIndex: null,
      selectedUsers: [],
      catalogFieldOptions: []
    }

    this.saveRule = this.saveRule.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMasterWorkflow = this.handleChangeMasterWorkflow.bind(this);
    this.addTagValue = this.addTagValue.bind(this);
    this.avatarName = this.avatarName.bind(this);

  }

  componentDidMount() {
    this.props.getOrgWorkflow({ types: [1], page: 0, pageSize: 1000 })
    // this.initSettings();
    console.log(this.props.componentDetails, 'componentDetails')
    let isDisableTemplateSelection = false;
    if (this.props.startedComponents.indexOf(parseInt(this.props.componentDetails.id)) !== -1) {
      isDisableTemplateSelection = true;
    }
    this.setState({
      approvalOverRide: this.props.componentDetails?.data?.object?.OverrideReponse,
      isDisableTemplateSelection: isDisableTemplateSelection
    })
  }

  componentDidUpdate(prevProps) {
    const { copyMasterToOrgProgress, copyMasterToOrgError } = this.props;
    if (this.props.builderFor === 'PROJECT' && this.props.sellerInstruction !== prevProps.sellerInstruction) {
      this.initSettings()
    }

    if (this.props.fetchOrgFormsProgress === false && prevProps.fetchOrgFormsProgress === true) {
      setTimeout(() => {
        this.initSettings();
      }, 2000);
    }

    if (this.props.orgTemplates?.length !== prevProps.orgTemplates?.length) {
      this.initSettings();
    }

    if (this.props.orgWorkFlowProgress === false && prevProps.orgWorkFlowProgress === true) {
      this.initSettings();
    }

    if (copyMasterToOrgProgress === false && prevProps.copyMasterToOrgProgress === true) {
      if (copyMasterToOrgError === null) {
        // console.log(this.props.copyMasterToOrgData, 'copyMasterToOrgData')
        // console.log(this.state.inputField, 'inputField')
        var me = this;
        var inputFields = this.state.inputField;
        inputFields.forEach(function (item) {
          if (item.Type === "WORKFLOW_PICKER") {
            item.value = {
              label: me.props.copyMasterToOrgData?.Name,
              value: me.props.copyMasterToOrgData?.ID
            }
          }
        })
        this.setState({
          active: 2,
          inputField: inputFields
        })
      } else {
        this.props.showSnackBar("Failed to copy master to org", "error", 3000);
      }
    }

  }

  initSettings() {
    let integrationFormData = null;
    if (this.props.formComponentList.length > 0) {
      const formData = this.props.formComponentList.map((item) => {
        var resValue = '';
        if (item.Type === 'Boolean') {
          resValue = 'false';
        }

        if (item.Type === "SINGLE_SELECT") {
          if (item.Data !== null && Validator.isJson(item.Data)) {
            var Datas = JSON.parse(item.Data);
            this.setState({
              singleSelectConfigOptions: Datas
            })
          }
        }

        if (item.Type === "catalog_field") {
          if (this.props.orgCatalogAllTabs) {
            let options = []
            this.props.orgCatalogAllTabs.forEach((itm) => {
              if (itm.sections) {
                itm.sections.forEach(function (section) {
                  if (section.items) {
                    section.items.forEach(function (secItem) {
                      if ((secItem.type === 1 || secItem.type === 12) && secItem.name !== '') {
                        options.push({
                          label: secItem.name,
                          value: secItem.slug,
                        })
                      }
                    })
                  }
                })
              }
            })
            // var Datas = JSON.parse(item.Data);
            this.setState({
              catalogFieldOptions: options
            })
          }
        }

        return {
          ...item, value: resValue
        }
      })

      const { masterTemplates, frameworksList, orgTemplates, masterForms, orgForms, orgWorkFlowList, masterWorkFlowList } = this.props;

      // console.log(this.props.configurations, 'configurations');
      let prevSelectedTemplate = '';

      this.props.configurations.forEach((config) => {
        if (config.Value === '' || config.Value === null) {
          return;
        }

        formData.forEach((item, index) => {
          if (item.Type === 'SINGLE_SELECT' && item.ID === config.FormID) {
            try {
              let valueObj = '';
              var Datas = JSON.parse(item.Data);
              const value = JSON.parse(config.Value);
              Datas.forEach((item) => {
                if (value?.id === item.value) {
                  valueObj = { label: item.value, value: item.value }
                }
              })
              formData[index].value = valueObj;
            } catch (e) { }
          } else if (item.Type === 'TEMPLATE_PICKER' && item.ID === config.FormID) {
            try {
              const value = JSON.parse(config.Value);
              let valueObj = '';
              if (value?.type) {
                this.setState({
                  active: value?.type === 'FRAMEWORK' ? 2 : 1
                })
              }
              if (value?.type === 'FRAMEWORK' && frameworksList?.length) {
                frameworksList.forEach((item) => {
                  if (value?.framework_id === item.id) {
                    valueObj = { label: item.name, value: item.id }
                    prevSelectedTemplate = item.id
                  }
                })
              } else if (value?.type === 'ORG' && orgTemplates?.length) {
                orgTemplates.forEach((item) => {
                  if (value?.id === item.ID) {
                    valueObj = { label: item.Name, value: item.ID }
                    prevSelectedTemplate = item.ID
                  }
                })
              }
              formData[index].value = valueObj;
            } catch (e) {
              console.warn(e);
            }
          } else if (item.Type === 'USER_PICKER' && item.ID === config.FormID) {
            const value = JSON.parse(config.Value);
            if (value) {
              formData[index].value = value;
            }
          } else if (item.Type === 'FORM_PICKER' && item.ID === config.FormID) {
            try {
              const value = JSON.parse(config.Value);
              let valueObj = '';
              if (value?.type) {
                this.setState({
                  active: value?.type === 'MASTER' ? 1 : 2
                })
              }
              if (value?.type === 'MASTER' && masterForms?.length) {
                masterForms.forEach((item) => {
                  if (value?.id === item.ID) {
                    valueObj = { label: item.FormTitle, value: item.ID }
                  }
                })
              } else if (value?.type === 'ORG' && orgForms?.length) {
                orgForms.forEach((item) => {
                  if (value?.id === item.ID) {
                    valueObj = { label: item.FormTitle, value: item.ID }
                  }
                })
              }
              formData[index].value = valueObj;
            } catch (e) {
              console.warn(e);
            }
          } else if (item.Type === 'INTEGRATION_FORM_DATA' && item.ID === config.FormID) {
            try {
              const value = JSON.parse(config.Value);
              integrationFormData = value
              formData[index].value = value;
            } catch (e) {
              console.warn(e);
            }
          } else if (item.Type === 'WORKFLOW_PICKER' && item.ID === config.FormID) {
            try {
              const value = JSON.parse(config.Value);
              let valueObj = '';
              if (value?.type) {
                this.setState({
                  active: value?.type === 'MASTER' ? 1 : 2
                })
              }

              if (value?.type === 'MASTER' && masterWorkFlowList?.Data?.length) {
                masterWorkFlowList.Data.forEach((item) => {
                  if (value?.id === item.ID) {
                    valueObj = { label: item.Name, value: item.ID }
                  }
                })
              } else if (value?.type === 'ORG' && orgWorkFlowList?.Data?.length) {
                orgWorkFlowList.Data.forEach((item) => {
                  if (value?.id === item.ID) {
                    valueObj = { label: item.Name, value: item.ID }
                  }
                })
              }
              formData[index].value = valueObj;
            } catch (e) {
              console.warn(e);
            }

          } else if (item.Name === "finish_redirect" && item.ID === config.FormID) {
            if (config.Value === 'true') {
              this.setState({
                isShowURLConfig: true
              })
            }
            formData[index].value = config.Value;
          } else if (item.Type === 'Text' && item.ID === config.FormID) {
            if (Validator.isJson(config.Value)) {
              let newData = JSON.parse(config.Value);
              formData[index].value = newData.value;
              formData[index]['hint'] = newData.hint ? newData.hint : '';
              formData[index]['hintMap'] = newData.hintMap ? newData.hintMap : {};
            } else {
              formData[index].value = config.Value;
            }
          } else if (item.Type === 'catalog_field' && item.ID === config.FormID) {
            const value = JSON.parse(config.Value);
            if (value) {
              formData[index].value = value;
            }
          } else if (item.ID === config.FormID) {
            if (this.props.builderFor === 'PROJECT' && this.props.isEvalStarted && this.props.selectedComponentSLUG === 'SELLER_INSTRUCTION') {
              formData[index].value = this.props.sellerInstruction;
            } else {
              formData[index].value = config.Value;
              formData[index]['hint'] = config?.Hint || config.Value;
            }
          }
        })
      });

      // console.log(formData, 'formData')
      this.setState({
        inputField: formData,
        integrationFormData: integrationFormData,
        prevSelectedTemplate: prevSelectedTemplate
      })
    } else if (this.props.formComponentList.length === 0) {
      this.setState({
        inputField: []
      })
    }

    // console.log(this.props.componentDetails, 'componentDetails')

    var item;
    const { isMasterWorkflow } = this.props;
    if (isMasterWorkflow) {
      item = this.getMasterWorkflowComponentUsingId(this.props.componentDetails?.id);
    } else {
      item = this.getComponentUsingId(this.props.componentDetails?.id);
    }

    var masterComponentData = this.getMasterComponentUsingId(item?.ComponentID);
    var componentId = parseInt(this.props.componentDetails.id);
    // console.log(masterComponentData, 'masterComponentData')

    var isWrapper = false;
    if (item?.IsWrapper) {
      isWrapper = true;
    }

    this.setState({
      selectedComponentDetails: item,
      selectedComponentData: masterComponentData,
      selectedComponent: masterComponentData?.Slug,
      selectedComponentId: componentId,
      selectedComponentIdNew: item?.ID,
      isWrapperComponent: isWrapper
    }, () => {
      if (this.state.selectedComponent === 'INTEGRATION_FORM') {

        let form = this.state.inputField.filter(o => o.Type === "FORM_PICKER")[0]?.value;
        if (form !== undefined && form !== null && form !== '' && form.value !== undefined && form.value !== null) {
          this.updateIntegrationTable(form.value, integrationFormData);
        } else {
          this.updateIntegrationTable(null, null);
        }
      }
    })
  }

  saveRule() {
    this.setState({
      error: '',
      success: '',
    })

    const request = [];
    let errorMessage = '';
    let templateId = null;
    let templateType = null;

    if (this.state.inputField.length) {
      this.state.inputField.forEach((item, k) => {
        if (errorMessage.length > 0) {
          return;
        }

        // console.log(item)
        if (this.state.isShowURLConfig === true && item.Name === "finish_redirect_url" && Validator.validateURL(item.value) === false) {
          errorMessage = 'Please enter valid URL'
          return;
        }

        // if (item.Type === 'TEMPLATE_PICKER' && this.state.prevSelectedTemplate !== item?.value?.value) {
        //   templateId = item?.value?.value;
        //   templateType = item?.template_type;
        // }



        if (item.Type === 'WORKFLOW_PICKER' || item.Type === 'FORM_PICKER') {
          item = {
            ...item,
            value: item?.value?.value ? JSON.stringify({
              type: this.state.active === 1 ? 'MASTER' : 'ORG',
              id: item?.value?.value || '',
              label: item?.value?.label || '',
            }) : ''
          }
        }

        if (item.Type === 'TEMPLATE_PICKER' && this.state.active === 1) {
          item = {
            ...item,
            value: item?.value?.value ? JSON.stringify({
              type: 'ORG',
              id: item?.value?.value || '',
              label: item?.value?.label || '',
            }) : ''
          }
        }

        if (item.Type === 'TEMPLATE_PICKER' && this.state.active === 2) {
          item = {
            ...item,
            value: item?.value?.value ? JSON.stringify({
              type: 'FRAMEWORK',
              framework_id: item?.value?.value || '',
              label: item?.value?.label || '',
            }) : ''
          }
        }

        if (item.Type === "SINGLE_SELECT") {
          // console.log(item, 'Item1122222222222222222')
          item = {
            ...item,
            value: item?.value?.value ? JSON.stringify({
              type: item?.value?.value,
              id: item?.value?.value || ''
            }) : ''
          }
        }

        if (item.Type === "catalog_field") {
          console.log(item, 'Item1122222222222222222')
          item = {
            ...item,
            value: item.value ? JSON.stringify(item.value) : ''
          }
        }



        if (item.Type === "INTEGRATION_FORM_DATA") {
          item = {
            ...item,
            value: this.state.integrationFormData ? JSON.stringify(this.state.integrationFormData) : ''
          }
        }

        if (item.Type === 'USER_PICKER') {
          item = {
            ...item,
            value: JSON.stringify(item.value)
          }
        }

        if (item.Type === 'Text' && this.state.selectedComponent === 'EMAIL') {
          item = {
            ...item,
            value: JSON.stringify({
              value: item.value,
              hint: item.hint || '',
              hintMap: item.hintMap || {},
            })
          }
        }

        // if (!item.value) {
        //   errorMessage = `Please choose/enter ${item.Label}`;
        //   return;
        // }

        request.push({ form_id: item.ID, value: item.value })
      });
    }

    this.setState({
      error: errorMessage
    })

    if (!errorMessage) {
      this.props.submitData(request, this.state.approvalOverRide, templateId, templateType);
    }
  }

  getFinalHintValue(string, map) {
    let newStr = string;
    Object.keys(map).forEach(function (key) {
      let label = map[key]
      if (newStr.indexOf(key) !== -1) {
        newStr = newStr.replace(key, label);
      }
    })
    return newStr;
  }

  handleChange = (value, index, type) => {
    var inputData = [...this.state.inputField];
    let me = this;
    inputData.forEach((item, mapIndex) => {
      if (index === mapIndex) {
        if (item.Name === "finish_redirect") {
          if (value === 'true') {
            this.setState({
              isShowURLConfig: true
            })
          } else {
            this.setState({
              isShowURLConfig: false,
            })
          }
        }
        item.value = value;

        if (item.Type === 'Text') {
          if (value === '') {
            item.hint = ''
          } else if (item.hintMap) {
            let newVal = me.getFinalHintValue(value, item.hintMap);
            item.hint = newVal;
          }
        }

        if (type) {
          item.template_type = type;
        }
      }
    });
    this.setState({
      inputField: inputData,
      error: ''
    })

    // console.log(this.state.selectedComponent, 'selectedComponent-----------------');
    // console.log(inputData, 'inputData');
    if (this.state.selectedComponent === 'INTEGRATION_FORM' && inputData !== undefined && inputData !== null && inputData !== '') {
      let form = inputData.filter(o => o.Type === "FORM_PICKER")[0]?.value;
      if (form !== undefined && form !== null && form !== '' && form.value !== undefined && form.value !== null) {
        this.updateIntegrationTable(form.value, null);
      } else {
        this.updateIntegrationTable(null, null);
      }
    }
  }

  updateIntegrationTable(formId, formData = null) {
    if (formId === null) {
      this.setState({
        integrationFormData: null
      })
    } else {
      let formAdditionalDataMap = {};
      if (formData !== undefined && formData !== null && formData !== '' && formData.items !== undefined && formData.items.length > 0) {
        formData.items.forEach(function (itm) {
          formAdditionalDataMap[itm.ID] = itm.additional_data;
        })
      }
      const { orgForms } = this.props;
      let itemData = {
        formName: '',
        formSlug: '',
        items: []
      }
      let form = orgForms.filter(o => o.ID === formId)[0];
      if (form !== undefined) {
        itemData.formName = form?.FormTitle;
        itemData.formSlug = form?.FormSlug;
        form.OrgWorkflowTemplateFormsSection.forEach(function (section) {
          section.OrgWorkflowTemplateFormSectionItems.forEach(function (item) {
            itemData.items.push({
              ItemName: item.ItemName,
              Slug: item.Slug,
              Type: item.Type,
              TypeName: getFormItemType(item.Type),
              ID: item.ID,
              isConfigurationsAvailable: formAdditionalDataMap[item.ID] !== undefined ? false : true,
              additional_data: formAdditionalDataMap[item.ID] !== undefined ? formAdditionalDataMap[item.ID] : {
                triggerType: '',
                triggerURL: '',
                dataFields: []
              }
            });
          })
        })
      }
      this.setState({
        integrationFormData: itemData,
        active: 2
      })
    }
  }

  callBack = () => {
    if (this.state.selectedMasterWorkflow) {
      this.props.hideMessageDialog();
      this.props.copyWorkflowMasterToOrg(this.state.selectedMasterWorkflow.value);
    }
  };

  handleChangeMasterWorkflow = (value, index) => {
    var me = this;
    this.setState({ selectedMasterWorkflow: value }, () => {
      me.props.showMessageDialog();
    })
  }

  getComponentUsingId = (id) => {
    return this.props.orgWorkFlowCompList && this.props.orgWorkFlowCompList.find((item) => item.ID === parseInt(id));
  }

  getMasterWorkflowComponentUsingId = (id) => {
    // console.log(this.props.components, 'masterWorkFlowList');
    return this.props.components && this.props.components.find((item) => item.ID === parseInt(id));
  }

  getMasterComponentUsingId = (id) => {
    // console.log(this.props.workflowComponents, 'workflowComponents');
    return this.props.workflowComponents && this.props.workflowComponents.find((item) => item.ID === parseInt(id));
  }

  saveWebHookConfig = (data) => {
    let integrationFormData = this.state.integrationFormData;
    // console.log(integrationFormData, 'integrationFormData')
    // console.log(data.config, 'config')
    // console.log(data.itemIndex, 'itemIndex')
    integrationFormData.items[data.itemIndex].additional_data = data.config;

    this.setState({
      integrationFormData: integrationFormData
    })
    // let formData = this.state.inputField;
    // let formIndx = this.state.inputField.findIndex(o => o.Type === "INTEGRATION_FORM_DATA");
    // formData[formIndx]['value'] = config;
    // this.setState({
    //   inputField : formData
    // },()=>{
    //   this.saveRule()
    // })
  }

  addTagValue = (tagData, additional_data, index) => {
    var inputData = [...this.state.inputField];
    console.log(additional_data, 'additional_data')
    let me = this;
    inputData.forEach((item, mapIndex) => {
      if (index === mapIndex) {
        let oldValue = item.value;
        let newValue = oldValue + ' {{ ' + tagData?.value + ' }} ';
        item.value = newValue;
        if (item.hintMap) {
          if (item.hintMap['{{ ' + tagData.value + ' }}'] === undefined) {
            item.hintMap['{{ ' + tagData.value + ' }}'] = additional_data.name !== '' ? '[' + additional_data.name + ']' : '[' + tagData?.label + ']';
          }
        } else {
          item['hintMap'] = {}
          item.hintMap['{{ ' + tagData.value + ' }}'] = additional_data.name !== '' ? '[' + additional_data.name : '[' + tagData?.label + ']';
        }
        item['hint'] = me.getFinalHintValue(newValue, item.hintMap);
      }
    });
    this.setState({
      inputField: inputData,
      error: ''
    })
  }

  handleChangeUser = (value, index) => {
    var inputData = [...this.state.inputField];
    inputData.forEach((item, mapIndex) => {
      if (index === mapIndex) {
        item.value = value;
      }
    });
    console.log(inputData, 'inputData')
    this.setState({
      inputField: inputData,
      selectedFieldIndex: null,
      error: ''
    }, () => {
      this.props.hideSearchUsersPoperDialog()
    })
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  render() {
    const classes = this.props.classes;
    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to copy workflow in Org Workflows.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    const { integrationFormData } = this.state;
    console.log(this.state.inputField, 'inputField')
    return (
      <div className={classes.configRoot}>
        <div className={this.props.isMasterWorkflow || this.props.workflowStatus === 2 ? classes.disabledDiv : ''}>
          <div className={classes.alerts}>
            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
            {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
          </div>
          <div>

            {this.state.inputField.length > 0 &&
              this.state.inputField.map((item, index) => {
                var selectPlaceHolder = "Select " + item.Label;
                return <div key={index} className={classes.inputSection}>
                  {item.Type === 'Text' &&
                    <div className={classes.inputBoxes}>
                      <Typography style={{ display: 'flex' }}> {item.Label} &nbsp;&nbsp;
                        {this.state.selectedComponent === 'EMAIL' &&
                          <>
                            <CodeIcon onClick={() => {
                              this.setState({
                                showProjectSchema: true,
                                selectedFieldIndex: index
                              })
                            }} />
                            {item.hint && <p className={classes.hint}>{item.hint} </p>}
                          </>
                        }
                      </Typography>

                      <input
                        className={classes.input}
                        disabled={this.props.workflowStatus === 2 ? true : false}
                        placeholder='Type Here'
                        onChange={(e) => {
                          this.handleChange(e.target?.value || '', index)
                        }}
                        value={item.value}
                      />

                    </div>
                  }
                  {item.Type === 'Number' &&
                    <div className={classes.inputBoxes}>
                      <Typography style={{ display: 'flex' }}> {item.Label}</Typography>
                      <input
                        type={'number'}
                        className={classes.input}
                        disabled={this.props.workflowStatus === 2 ? true : false}
                        placeholder='Type Here'
                        onChange={(e) => {
                          this.handleChange(e.target?.value || '', index)
                        }}
                        value={item.value}
                      />
                    </div>
                  }
                  {item.Type === 'RichText' &&
                    <div className={classnames(classes.inputBoxes, classes.richTextArea)}>
                      <Typography>{item.Label}</Typography>
                      <SlateInputField
                        folderPath={`/workflow/${this.props.match.params.id}/component/${this.state.selectedComponentId}`}
                        placeholder=""
                        style={{ margin: 0 }}
                        as={SlateInputField}
                        onChangeEvent={(value) => {
                          this.handleChange(value || '', index)
                        }}
                        maxLength={10}
                        initValue={item.value}
                        actionButtonsRequired={false}
                        textContainerStyle={{ color: "#707070", fontSize: 14 }}
                        isShowAddVariableOption={this.state.selectedComponent === 'EMAIL' ? true : false}
                        isShowTableOption={this.state.selectedComponent === 'EMAIL' ? true : false}
                        isShowImageOption={this.state.selectedComponent === 'EMAIL' ? true : false}
                        isShowAlignmentOption={this.state.selectedComponent === 'EMAIL' ? true : false}
                        isShowHeadingOption={this.state.selectedComponent === 'EMAIL' ? true : false}
                        workflowId={this.props.workflowId}
                      />
                    </div>
                  }
                  {item.Type === 'Textarea' &&
                    <div className={classes.inputBoxes}>
                      <Typography style={{ display: 'flex' }}>{item.Label}</Typography>
                      <textarea
                        className={classes.input}
                        rows="5"
                        disabled={this.props.workflowStatus === 2 ? true : false}
                        placeholder='Type Here'
                        onChange={(e) => {
                          this.handleChange(e.target?.value || '', index)
                        }}
                        value={item.value}
                      />
                    </div>
                  }
                  {
                    item.Type === 'DOCUMENT_TEXTAREA' &&
                    <div className={classnames(classes.inputBoxes, classes.documentTextArea)}>
                      <Typography>{item.Label}</Typography>
                      <SlateInputField
                        folderPath={`/workflow/${this.props.match.params.id}/component/${this.state.selectedComponentId}`}
                        placeholder=""
                        style={{ margin: 0 }}
                        as={SlateInputField}
                        onChangeEvent={(value) => {
                          this.handleChange(value || '', index)
                        }}
                        maxLength={10}
                        initValue={item.value}
                        actionButtonsRequired={false}
                        textContainerStyle={{ color: "#707070", fontSize: 14 }}
                        isShowTableOption={true}
                        isShowImageOption={true}
                        isShowAlignmentOption={true}
                        isShowHeadingOption={true}
                        isShowAddVariableOption={true}
                        workflowId={this.props.workflowId}
                      />
                    </div>
                  }
                  {
                    item.Type === "SINGLE_SELECT" &&
                    <div className={classes.inputBoxes}>
                      <Typography>{item.Label}</Typography>
                      <ReactSelect
                        isClearable
                        disabled={this.props.workflowStatus === 2 ? true : false}
                        isSearchable={true}
                        menuPosition='fixed'
                        options={this.state.singleSelectConfigOptions}
                        classNamePrefix="select"
                        placeholder={selectPlaceHolder}
                        onChange={(e) => {
                          this.handleChange(e, index)
                        }}
                        value={item.value}
                      />
                    </div>
                  }

                  {
                    item.Type === "catalog_field" &&
                    <div className={classes.inputBoxes}>
                      <Typography>{item.Label}</Typography>
                      <ReactSelect
                        isClearable
                        disabled={this.props.workflowStatus === 2 ? true : false}
                        isSearchable={true}
                        menuPosition='fixed'
                        options={this.state.catalogFieldOptions}
                        classNamePrefix="select"
                        placeholder={selectPlaceHolder}
                        onChange={(e) => {
                          this.handleChange(e, index)
                        }}
                        value={item.value}
                      />
                    </div>
                  }

                  {
                    this.state.isShowURLConfig === true && item.Type === 'URL' &&
                    <div className={classes.inputBoxes}>
                      <Typography>Redirect URL</Typography>
                      <input
                        className={classes.input}
                        disabled={this.props.workflowStatus === 2 ? true : false}
                        placeholder='Enter redirect URL'
                        onChange={(e) => {
                          this.handleChange(e.target?.value || '', index)
                        }}
                        value={item.value}
                      />
                    </div>
                  }
                  {
                    this.state.selectedComponent !== 'APPROVAL_WORKFLOW' && item.Type === "Boolean" && <div style={{ marignTop: 21 }}>
                      <label className={classes.label}>{item.Label}</label>
                      <Switch
                        color="primary"
                        disabled={this.props.workflowStatus === 2 ? true : false}
                        checked={item.value === "true" || item.value === true ? true : false}
                        onChange={(e, v) => {
                          var newValue = item.value === '' || item.value === 'false' ? 'true' : 'false';
                          item.value = newValue;
                          var items = this.state.inputField;
                          items[index].value = newValue;
                          this.setState({
                            inputField: items
                          }, () => {
                            this.handleChange(newValue, index)
                          })
                        }}
                        name="gilad"
                      />

                      {/* <RadioGroup row
                      disabled={this.props.workflowStatus === 2 ? true : false}
                      name={item.Name}
                      value={item.value} onChange={(e, v) => this.handleChange(v, index)}>
                      <FormControlLabel value="true" control={<Radio color="primary" />} label="True" />
                      <FormControlLabel value="false" control={<Radio color="primary" />} label="False" />
                    </RadioGroup> */}

                    </div>
                  }
                  {item.Type === "TEMPLATE_PICKER" && this.props.isShowChooseTemplateOption && <div
                    style={{ marignTop: 21, flex: 1 }}>
                    <label className={classes.label}>{item.Label}</label>
                    {<ul className={classes.tabs}>

                      <li className={classes.tab}>
                        <Button className={classnames(classes.tabBtn, {
                          [classes.activeBtn]: this.state.active === 1
                        })}
                          onClick={() => {
                            this.setState({
                              active: 1
                            })
                            this.handleChange('', index);
                          }}> Questionnaires</Button>
                      </li>
                      <li className={classes.tab}>
                        <Button className={classnames(classes.tabBtn, {
                          [classes.activeBtn]: this.state.active === 2
                        })}
                          onClick={() => {
                            this.setState({
                              active: 2
                            })
                            this.handleChange('', index);
                          }}>Frameworks </Button>
                      </li>
                    </ul>}

                    {this.state.active === 1 &&
                      <ReactSelect
                        isClearable
                        isDisabled={this.state.isDisableTemplateSelection}
                        disabled={this.props.workflowStatus === 2 ? true : false}
                        isSearchable={true}
                        menuPosition='fixed'
                        options={this.props.orgTemplates && this.props.orgTemplates.map((item) => ({ label: item.Name, value: item.ID }))}
                        classNamePrefix="select"
                        placeholder="Search template"
                        onChange={(e) => {
                          this.handleChange(e, index, 'org')
                        }}
                        value={item.value}
                      />
                    }
                    {this.state.active === 2 &&
                      <ReactSelect
                        isClearable
                        isDisabled={this.state.isDisableTemplateSelection}
                        disabled={this.props.workflowStatus === 2 ? true : false}
                        isSearchable={true}
                        menuPosition='fixed'
                        options={this.props.frameworksList && this.props.frameworksList.map((item) => ({ label: item.name, value: item.id }))}
                        classNamePrefix="select"
                        placeholder="Search framework"
                        onChange={(e) => {
                          this.handleChange(e, index, 'master')
                        }}
                        value={item.value}
                      />
                    }
                  </div>
                  }

                  {
                    item.Type === "FORM_PICKER" && this.state.selectedComponent !== 'INTEGRATION_FORM' && <div
                      title={this.state.isDisableTemplateSelection ? 'Component Already started.' : ''} style={{ flex: 1 }}
                    >
                      <label className={classes.label}>{item.Label}</label>
                      {false && <ul className={classes.tabs}>
                        <li className={classes.tab}>
                          <Button className={classnames(classes.tabBtn, {
                            [classes.activeBtn]: this.state.active === 1
                          })}
                            onClick={() => {
                              this.setState({
                                active: 1
                              })
                              this.handleChange('', index);
                            }}>Expent's Form </Button>
                        </li>
                        <li className={classes.tab}>
                          <Button className={classnames(classes.tabBtn, {
                            [classes.activeBtn]: this.state.active === 2
                          })}
                            onClick={() => {
                              this.setState({
                                active: 2
                              })
                              this.handleChange('', index);
                            }}> Custom</Button>
                        </li>
                      </ul>}
                      {this.state.active === 1 &&
                        <ReactSelect
                          isClearable
                          isDisabled={this.state.isDisableTemplateSelection}
                          disabled={this.props.workflowStatus === 2 ? true : false}
                          isSearchable={true}
                          menuPosition='fixed'
                          options={this.props.masterForms && this.props.masterForms.map((item) => ({ label: item.FormTitle, value: item.ID }))}
                          classNamePrefix="select"
                          placeholder="Search Form"
                          onChange={(e) => {
                            this.handleChange(e, index)
                          }}
                          value={item.value}
                        />
                      }
                      {this.state.active === 2 &&
                        <ReactSelect
                          isClearable
                          isDisabled={this.state.isDisableTemplateSelection}
                          disabled={this.props.workflowStatus === 2 ? true : false}
                          isSearchable={true}
                          menuPosition='fixed'
                          options={this.props.orgForms && this.props.orgForms.map((item) => ({ label: item.FormTitle, value: item.ID }))}
                          classNamePrefix="select"
                          placeholder="Search Form"
                          onChange={(e) => {
                            this.handleChange(e, index)
                          }}
                          value={item.value}
                        />
                      }
                    </div>
                  }

                  {item.Type === "USER_PICKER" && <div>
                    <label className={classes.label}>{item.Label}</label>
                    {item.value !== undefined && item.value !== null && item.value !== '' && <div>
                      <AvatarGroup max={3}>
                        {item.value.map((user, k) => {
                          return <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>
                            {user.Name + "\n" + user.Email}
                          </span>} arrow>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar style={{ zIndex: 0, backgroundColor: user.type === "GROUP" ? "#62a00b" : "#bdbdbd" }} key={k}> {this.avatarName(user.Name)}</Avatar>
                              &nbsp;&nbsp;<Image
                                className={classnames(classes.addUser, 'evaluate-add-user')}
                                alt="add user"
                                src={deleteIcon}
                                onClick={(event) => {
                                  this.handleChangeUser([], index)
                                }} />
                            </span>
                          </Tooltip>
                        })}
                      </AvatarGroup>
                    </div>}

                    {(item.value.length <= 0 || item.value === '') && <div>
                      <Image className={classnames(classes.addUser, 'evaluate-add-user')} alt="add user" src={addUsers} onClick={(event) => {
                        this.setState({
                          selectedUsers: item.value,
                          selectedFieldIndex: index
                        }, () => {
                          this.props.showSearchUsersPoperDialog();
                        })
                      }} />
                    </div>}


                  </div>
                  }

                  {
                    item.Type === "WORKFLOW_PICKER" && <div style={{ marignTop: 21 }}>
                      <label className={classes.label}>{item.Label}</label>
                      <ul className={classes.tabs}>
                        <li className={classes.tab}>
                          <Button className={classnames(classes.tabBtn, {
                            [classes.activeBtn]: this.state.active === 1
                          })}
                            onClick={() => {
                              this.setState({
                                active: 1
                              })
                              this.handleChange('', index);
                            }}>Expent's Workflow </Button>
                        </li>
                        <li className={classes.tab}>
                          <Button className={classnames(classes.tabBtn, {
                            [classes.activeBtn]: this.state.active === 2
                          })}
                            onClick={() => {
                              this.setState({
                                active: 2
                              })
                              this.handleChange('', index);
                            }}> Custom</Button>
                        </li>
                      </ul>
                      {this.state.active === 1 &&
                        <ReactSelect
                          isClearable
                          disabled={this.props.workflowStatus === 2 ? true : false}
                          isSearchable={true}
                          menuPosition='fixed'
                          options={this.props.masterWorkFlowList && this.props.masterWorkFlowList.Data.map((item) => ({ label: item.Name, value: item.ID }))}
                          classNamePrefix="select"
                          placeholder="Search Workflow"
                          onChange={(e) => {
                            this.handleChangeMasterWorkflow(e, index)
                          }}
                          value={item.value}
                        />
                      }
                      {this.state.active === 2 &&
                        <ReactSelect
                          isClearable
                          disabled={this.props.workflowStatus === 2 ? true : false}
                          isSearchable={true}
                          menuPosition='fixed'
                          options={this.props.orgWorkFlowList && this.props.orgWorkFlowList.Data.filter(o => o.ID !== parseInt(this.props.workflowId)).map((item) => ({ label: item.Name, value: item.ID }))}
                          classNamePrefix="select"
                          placeholder="Search Workflow"
                          onChange={(e) => {
                            this.handleChange(e, index)
                          }}
                          value={item.value}
                        />
                      }
                    </div>
                  }

                  {
                    item.Type === "FORM_PICKER" && this.state.selectedComponent === 'INTEGRATION_FORM' && <div style={{ width: 400, marignTop: 21 }}>
                      <label className={classes.label}>{item.Label}</label>
                      <ReactSelect
                        isClearable
                        isDisabled={this.state.isDisableTemplateSelection}
                        disabled={this.props.workflowStatus === 2 ? true : false}
                        isSearchable={true}
                        menuPosition='fixed'
                        options={this.props.orgForms && this.props.orgForms.map((item) => ({ label: item.FormTitle, value: item.ID }))}
                        classNamePrefix="select"
                        placeholder="Search Form"
                        onChange={(e) => {
                          this.handleChange(e, index)
                        }}
                        value={item.value}
                      />
                    </div>
                  }
                </div>
              })}
          </div>

          {this.state.selectedComponent === 'INTEGRATION_FORM' && <div>
            <IntegrationFormData
              integrationFormData={integrationFormData}
              saveWebHookConfig={(config) => {
                this.saveWebHookConfig(config);
              }}
            />
          </div>}

          {false && this.state.selectedComponent === 'EMAIL' &&
            <p>Tip: Use variables to dynamically populate product names <br></br>
              (all.products) - Populates all products considered for evaluation<br></br>
              (approved.product) - Populates the approved product [Use only after approval component]</p>}

          {false && this.state.selectedComponent === 'EMAIL' &&
            <div>
              <button
                type="button"
                className={classes.newUserBtn}
                onClick={() =>
                  this.setState({
                    showProjectSchema: true
                  })}>
                View Project Schema
              </button>
            </div>}

          {this.state.selectedComponent === 'CUSTOM_FORM' && <CustomForm
            workflowId={this.props.workflowId}
            componentId={this.state.selectedComponentIdNew}
            componentDetails={this.props.componentDetails}
            closeConfiguration={this.props.closeConfiguration}
          />}

          {this.state.isWrapperComponent === true && <WrapperForm
            wrapperData={this.state.selectedComponentData}
            wrapperDetails={this.state.selectedComponentDetails}
            wrapperId={this.state.selectedComponentIdNew}
            componentDetails={this.props.componentDetails}
          />}

          {/* {this.state.selectedComponent === 'PRODUCT_SHORTLIST' && <ProductShortlisted
            workflowId={this.props.workflowId}
            componentId={this.state.selectedComponentIdNew}
            componentDetails={this.props.componentDetails}
            closeConfiguration={this.props.closeConfiguration}
          />} */}

          {this.state.selectedComponent === 'DECISION_COMPONENT' && <DecisionComponent
            workflowId={this.props.workflowId}
            componentId={this.state.selectedComponentIdNew}
            componentDetails={this.props.componentDetails}
            closeConfiguration={this.props.closeConfiguration}
            isMasterWorkflow={this.props.isMasterWorkflow}
          />}

          {this.state.selectedComponent === 'APPROVAL_WORKFLOW' && <ApprovalComponent
            workflowId={this.props.workflowId}
            componentId={this.state.selectedComponentIdNew}
            componentDetails={this.props.componentDetails}
            closeConfiguration={this.props.closeConfiguration}
            workflowStatus={this.props.workflowStatus}
            selectedComponentData={this.props.selectedComponentData}
            builderFor={this.props.builderFor}
            isEvalStarted={this.props.isEvalStarted}
            approvedCriteriesIds={this.props.approvedCriteriesIds}
            projectApprovalCriterias={this.props.projectApprovalCriterias}
            addUpdateApprovalCriterias={(component, data, type) => {
              this.props.addUpdateApprovalCriterias(component, data, type)
            }}
          />}


          {this.props.workflowStatus !== 2 && this.props.isMasterWorkflow === false && this.state.selectedComponent !== 'APPROVAL_WORKFLOW' && this.state.selectedComponent !== 'CUSTOM_FORM' && this.state.selectedComponent !== 'DECISION_COMPONENT' && <div className={classes.saveBtnSection}>
            <button type="button" disabled={this.props.workflowStatus === 2 && this.props.formComponentList.length > 0 ? true : false}
              className={this.props.workflowStatus === 2 && this.props.formComponentList.length > 0 ? classes.newUserBtnDisabled : classes.newUserBtn}
              onClick={() => this.saveRule()}> {this.props.formComponentList.length > 0 ? 'Save' : 'Close'} </button>
          </div>}

        </div>

        <MessageDialog messageData={messageData} callBack={this.callBack} />

        {/* {this.state.showProjectSchema && <ProjectSchemaDialog
          isOpen={this.state.showProjectSchema}
          hideDialog={() => {
            this.setState({
              showProjectSchema: false
            })
          }}
        />} */}

        {
          this.state.showProjectSchema && <SchemaTagSelectionDialog
            isOpen={this.state.showProjectSchema}
            workflowId={this.props.workflowId}
            hideDialog={() => {
              this.setState({
                showProjectSchema: false
              })
            }}
            addTagValue={(tag, additional_data) => {
              this.addTagValue(tag, additional_data, this.state.selectedFieldIndex)
              this.setState({
                showProjectSchema: false,
                selectedFieldIndex: null
              })
            }}
          />
        }

        {this.props.isOpenUserDialog && <SearchUserPoper
          selectedUsers={this.state.selectedUsers || []}
          onAdd={(user) => {
            this.handleChangeUser([{
              id: user.ID,
              ID: user.ID,
              value: user.ID,
              label: user.Name,
              Name: user.Name,
              Email: user.Email,
            }], this.state.selectedFieldIndex)
            // this.setSelectedUsers({
            //   id: user.ID,
            //   ID: user.ID,
            //   value: user.ID,
            //   label: user.Name,
            //   Name: user.Name,
            //   Email: user.Email,
            // }, true)
          }}
          onRemove={(user) => {
            this.handleChangeUser([], this.state.selectedFieldIndex)
            // this.setSelectedUsers({
            //   id: user.ID,
            //   ID: user.ID,
            //   value: user.ID,
            //   label: user.Name,
            //   Name: user.Name,
            //   Email: user.Email,
            // }, true)
          }}
          onClose={() => {
            this.props.hideSearchUsersPoperDialog();
          }}
        />}

      </div >
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(WorkFlowConfigurationV2));
