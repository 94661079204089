import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import SqureButton from 'Components/Common/SqureButton';
import { showEvaluationMetaDialog, fetchPreEvaluationFeedbackData } from "redux/evaluation/metadata/action"
import { fetchColloborator, addColloborator } from "redux/evaluation/criteria/action"
import UserGroup from 'Components/Common/UserGroup';
import { showEvaluationLinkDialog } from "redux/evaluation/link/action";
import { showPreFeedbackAdminDialog } from "redux/evaluation/feedback/action";

import Image from 'Components/Common/image.jsx';
import EditDarkImg from 'assets/images/edit_dark.svg';

import { showUserWiseWorkflowProgressDialog, showAllUsersWorkflowProgressDialog, showWorkflowComponentTimelineDialog, hideWorkflowComponentTimelineDialog, fetchWorkflowComponentTimeline } from "redux/evaluation/workflow/action";

import { showSnackBar } from "redux/snackbar/action";
import { showUpdateSurveyDialog } from "redux/survey/action";

const connectedProps = (state) => ({
    evaluation: state.evaluationMetaData.evaluation,
    fetchProgress: state.evaluationMetaData.fetchProgress,
    colloborators: state.evaludationCriteria.colloborators,
    isOpen: state.evaluationFeedback.showPreFeedbackAdminDialog,
    userRole: state.authUser.user?.Role,
    user: state.authUser.user,
    preFeedbackData: state.evaluationMetaData.preFeedbackData,
    isUserWiseWorflowProgressDialogOpen: state.evaluationWorkflow.isUserWiseWorflowProgressDialogOpen,
    isAllUsersWorflowProgressDialogOpen: state.evaluationWorkflow.isAllUsersWorflowProgressDialogOpen,
    isOpenComponentTimelineDialog: state.evaluationWorkflow.showWorkflowComponentTimelineDialog,
    userTimeline: state.workflow.userTimeline,
    workflowComponentTimeline: state.evaluationWorkflow.workflowComponentTimeline,
    fetchWorkflowCompoenentTimelineProgress: state.evaluationWorkflow.fetchWorkflowCompoenentTimelineProgress,
    addWorkflowCompoenentTimelineProgress: state.evaluationWorkflow.addWorkflowCompoenentTimelineProgress,
    updateWorkflowCompoenentTimelineProgress: state.evaluationWorkflow.updateWorkflowCompoenentTimelineProgress,
    workflowComponentTimelineError: state.evaluationWorkflow.workflowComponentTimelineError,
});

const connectionActions = {
    showEvaluationMetaDialog: showEvaluationMetaDialog,
    fetchColloborator: fetchColloborator,
    addColloborator: addColloborator,
    showEvaluationLinkDialog: showEvaluationLinkDialog,
    showPreFeedbackAdminDialog: showPreFeedbackAdminDialog,
    fetchPreEvaluationFeedbackData: fetchPreEvaluationFeedbackData,
    showUserWiseWorkflowProgressDialog: showUserWiseWorkflowProgressDialog,
    showAllUsersWorkflowProgressDialog: showAllUsersWorkflowProgressDialog,
    showWorkflowComponentTimelineDialog: showWorkflowComponentTimelineDialog,
    fetchWorkflowComponentTimeline: fetchWorkflowComponentTimeline,
    hideWorkflowComponentTimelineDialog: hideWorkflowComponentTimelineDialog,
    showSnackBar: showSnackBar,
    showUpdateSurveyDialog:showUpdateSurveyDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        // paddingBottom: theme.spacing(2),
    },
    appBar: {
        background: '#fff',
        padding: theme.spacing(0, 2),
        boxShadow: 'none',
        borderBottom: '2px solid #f1f1f1'
    },
    subTitle: {
        marginTop: 5
    },
    actions: {
        paddingTop: theme.spacing(2)
    },
    evaTabsDiv: {
        width: '50%',
        // width: 'max-content',
    },
    evalBtn: {
        border: '1px solid #20253A !important'
    },
    workflowProgressBtn: {
        marginRight: 10,
        border: '1px solid #20253A !important'
    },
    preEvalBtn: {
        border: '1px solid red !important',
        marginRight: '10px !important'
    },
    evaTabs: {
        fontSize: theme.spacing(1.7),
        padding: theme.spacing(1, 0),
        // '& :hover':{
        //     color:'#222'
        // }
    },
    evaDetails: {
        textAlign: 'right',
        paddingRight: theme.spacing(1)
    },
    colbTitle: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
        marginRight: 10,
    },
    tabRoot: {
        flexGrow: 1,
    },
    'MuiTab-root': {
        fontSize: theme.spacing(1),
    },
    activeBlock: {
        borderColor: '#398AF5'
    },
    viewButtons: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'right',
        minHeight: '45px',
        float: 'right'
    },
    colloborator: {
        padding: '10px 0'
    },
    tabContainer: {
        height: '100%',
    },
    workflowTabs: {
        '& [class*="MuiTab-root"]': {
        }
    },
    activeSubMenu: {
        background: '#1f73b7',
        color: '#fff!important',
        '&:hover': {
            color: '#222!important',
        }
    },
    arrowImg: {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        top: 10
    },
    stepperName: {
        width: '135px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    scheduleTimeline: {
        position: 'absolute',
        left: 0,
        right: 0,
        margin: '0 auto',
        top: 15,
        textAlign: 'center',
        cursor: 'pointer',
        color: '#1f73b7',
        width: 250
    }
});

class SurveyBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDownloadModal: false,
            evaluationStep: 2,
            showCollabrations: false,
            showEvalDetails: false,
            showViewToggleBtn: false,
            fourBlockViewActive: false,
            twoBlockViewActive: false,
            isShowSubMenu: false,
            subMenuEl: null,
            subMenuData: [],
            activeSubMenuItem: '',
            isAddSchedule: true
        }

        this.avatarName = this.avatarName.bind(this);
    }

    componentDidMount() {
        this.props.fetchColloborator(this.props.match.params.survey_id);
    }

    componentDidUpdate(prevProps) {

    }

    avatarName(name) {
        return name.substring(0, 1);
    }

    render() {
        const classes = this.props.classes;
        return <div className={classes.root}>
            <AppBar className={classes.appBar} position="static" color="default">
                <Grid container justifyContent="space-between">
                    <Grid item >
                        {<>
                            {<Grid
                                container
                                className={classes.colloborator}
                                alignItems={this.props.colloborators && this.props.colloborators.length ? 'end' : 'center'}
                                direction={this.props.colloborators && this.props.colloborators.length ? 'column' : 'row'}
                            >
                                <Typography variant={"h6"} className={classes.colbTitle}>Collaborators</Typography>

                                <UserGroup
                                    selectedUser={this.props.colloborators}
                                    existingUsers={this.props.colloborators}
                                    evaluationId={this.props.match.params.survey_id}
                                    position={'SecondaryBar'}
                                    onAdd={(user) => {
                                        this.props.addColloborator(this.props.match.params.survey_id, user.ID)
                                    }} 
                                    users={this.props.colloborators} 
                                    />
                            </Grid>}
                        </>}
                    </Grid>
                    <Grid item className={classes.evaDetails}>
                        <Grid container alignItems="center" justify="flex-end" className={classes.tabContainer}>
                            {<SqureButton className={classes.evalBtn} variant="outlined" startIcon={
                                !this.props.evaluateView ? <Image src={EditDarkImg} alt="Edit Dark" /> : ''
                            } onClick={() => {
                                this.props.showUpdateSurveyDialog(this.props.evaluation.ID, true)
                            }}> Survey details</SqureButton>}
                        </Grid>
                    </Grid>
                </Grid>
            </AppBar>
        </div>
    }
}

SurveyBar.defaultProps = {
    isSummaryView: false,
};

export default connector(compose(
    withRouter,
    withStyles(styles)
)(SurveyBar));