import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment"

export const START_ADD_WRAPPER_WORKFLOW = "START_ADD_WRAPPER_WORKFLOW";
export const END_ADD_WRAPPER_WORKFLOW = "END_ADD_WRAPPER_WORKFLOW";

export const START_UPDATE_WRAPPER_WORKFLOW = "START_UPDATE_WRAPPER_WORKFLOW";
export const END_UPDATE_WRAPPER_WORKFLOW = "END_UPDATE_WRAPPER_WORKFLOW";

export const START_GET_WRAPPER_WORKFLOW = "START_GET_WRAPPER_WORKFLOW";
export const END_GET_WRAPPER_WORKFLOW = "END_GET_WRAPPER_WORKFLOW";

export const START_GET_BY_ID_WRAPPER_WORKFLOW = "START_GET_BY_ID_WRAPPER_WORKFLOW";
export const END_GET_BY_ID_WRAPPER_WORKFLOW = "END_GET_BY_ID_WRAPPER_WORKFLOW";

export const START_DELETE_WRAPPER_WORKFLOW = "START_DELETE_WRAPPER_WORKFLOW";
export const END_DELETE_WRAPPER_WORKFLOW = "END_DELETE_WRAPPER_WORKFLOW";


export const START_ADD_WRAPPER_WORKFLOW_COMPONENT = "START_ADD_WRAPPER_WORKFLOW_COMPONENT";
export const END_ADD_WRAPPER_WORKFLOW_COMPONENT = "END_ADD_WRAPPER_WORKFLOW_COMPONENT";

export const START_UPDATE_WRAPPER_WORKFLOW_COMPONENT = "START_UPDATE_WRAPPER_WORKFLOW_COMPONENT";
export const END_UPDATE_WRAPPER_WORKFLOW_COMPONENT = "END_UPDATE_WRAPPER_WORKFLOW_COMPONENT";

export const START_GET_WRAPPER_WORKFLOW_COMPONENT = "START_GET_WRAPPER_WORKFLOW_COMPONENT";
export const END_GET_WRAPPER_WORKFLOW_COMPONENT = "END_GET_WRAPPER_WORKFLOW_COMPONENT";

export const START_DELETE_WRAPPER_WORKFLOW_COMPONENT = "START_DELETE_WRAPPER_WORKFLOW_COMPONENT";
export const END_DELETE_WRAPPER_WORKFLOW_COMPONENT = "END_DELETE_WRAPPER_WORKFLOW_COMPONENT";

export const START_ADD_WRAPPER_WORKFLOW_ACTION = "START_ADD_WRAPPER_WORKFLOW_ACTION";
export const END_ADD_WRAPPER_WORKFLOW_ACTION = "END_ADD_WRAPPER_WORKFLOW_ACTION";

export const START_UPDATE_WRAPPER_WORKFLOW_ACTION = "START_UPDATE_WRAPPER_WORKFLOW_ACTION";
export const END_UPDATE_WRAPPER_WORKFLOW_ACTION = "END_UPDATE_WRAPPER_WORKFLOW_ACTION";

export const START_FETCH_WRAPPER_WORKFLOW_ACTION = "START_FETCH_WRAPPER_WORKFLOW_ACTION";
export const END_FETCH_WRAPPER_WORKFLOW_ACTION = "END_FETCH_WRAPPER_WORKFLOW_ACTION";

export const START_DELETE_WRAPPER_WORKFLOW_ACTION = "START_DELETE_WRAPPER_WORKFLOW_ACTION";
export const END_DELETE_WRAPPER_WORKFLOW_ACTION = "END_DELETE_WRAPPER_WORKFLOW_ACTION";

export const SHOW_MASTER_COMPONENT_DIALOG = "SHOW_MASTER_COMPONENT_DIALOG";
export const HIDE_MASTER_COMPONENT_DIALOG = "HIDE_MASTER_COMPONENT_DIALOG";

export const START_GET_WRAPPER_WORKFLOW_COMPONENT_DETAILS = "START_GET_WRAPPER_WORKFLOW_COMPONENT_DETAILS";
export const END_GET_WRAPPER_WORKFLOW_COMPONENT_DETAILS = "END_GET_WRAPPER_WORKFLOW_COMPONENT_DETAILS";

export const START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_BY_ID = "START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_BY_ID";
export const END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_BY_ID = "END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_BY_ID";

export const START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM = "START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM";
export const END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM = "END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM";

export const START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS = "START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS";
export const END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS = "END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS";

export const START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS = "START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS";
export const END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS = "END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS";

export const START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS = "START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS";
export const END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS = "END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS";

export const START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION = "START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION";
export const END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION = "END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION";

export const START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION = "START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION";
export const END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION = "END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION";

export const START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTIONS = "START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTIONS";
export const END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTIONS = "END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTIONS";

export const START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION = "START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION";
export const END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION = "END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION";

export const START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";
export const END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";

export const START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";
export const END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";

export const START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";
export const END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM = "END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM";

export const START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS = "START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS";
export const END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS = "END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS";

export const START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_SEQ = "START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_SEQ";
export const END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_SEQ = "END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_SEQ";

export const SHOW_WRAPPER_COMPONENT_DIALOG = "SHOW_WRAPPER_COMPONENT_DIALOG";
export const HIDE_WRAPPER_COMPONENT_DIALOG = "HIDE_WRAPPER_COMPONENT_DIALOG";

export const SHOW_WRAPPER_MASTER_COMPONENT_DIALOG = "SHOW_WRAPPER_MASTER_COMPONENT_DIALOG";
export const HIDE_WRAPPER_MASTER_COMPONENT_DIALOG = "HIDE_WRAPPER_MASTER_COMPONENT_DIALOG";


function startAddOrgWorkflow() {
    return {
        type: START_ADD_WRAPPER_WORKFLOW,
        payload: {}
    };
}

function endAddOrgWorkflow(success, error) {
    return {
        type: END_ADD_WRAPPER_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function addOrgWorkflow(data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endAddOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateOrgWorkflow() {
    return {
        type: START_UPDATE_WRAPPER_WORKFLOW,
        payload: {}
    };
}

function endUpdateOrgWorkflow(success, error) {
    return {
        type: END_UPDATE_WRAPPER_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function updateOrgWorkflow(id, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endUpdateOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startGetOrgWorkflow() {
    return {
        type: START_GET_WRAPPER_WORKFLOW,
        payload: {}
    };
}

function endGetOrgWorkflow(success, error) {
    return {
        type: END_GET_WRAPPER_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function getOrgWorkflow(data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/all-org-workflows?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endGetOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endGetOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startGetByIdOrgWorkflow() {
    return {
        type: START_GET_BY_ID_WRAPPER_WORKFLOW,
        payload: {}
    };
}

function endGetByIdOrgWorkflow(success, error) {
    return {
        type: END_GET_BY_ID_WRAPPER_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function getByIdOrgWorkflow(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetByIdOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endGetByIdOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endGetByIdOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startDeleteOrgWorkflow() {
    return {
        type: START_DELETE_WRAPPER_WORKFLOW,
        payload: {}
    };
}

function endDeleteOrgWorkflow(success, error) {
    return {
        type: END_DELETE_WRAPPER_WORKFLOW,
        payload: {
            success, error
        }
    };
}

export function deleteOrgWorkflow(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteOrgWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgWorkflow(response, error));
        } catch (e) {
            dispatch(endDeleteOrgWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAddOrgWorkflowComponent() {
    return {
        type: START_ADD_WRAPPER_WORKFLOW_COMPONENT,
        payload: {}
    };
}

function endAddOrgWorkflowComponent(success, error) {
    return {
        type: END_ADD_WRAPPER_WORKFLOW_COMPONENT,
        payload: {
            success, error
        }
    };
}

export function addWrapperWorkflowComponent(id, componentId, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgWorkflowComponent());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddOrgWorkflowComponent(response, error));
        } catch (e) {
            dispatch(endAddOrgWorkflowComponent(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateOrgWorkflowComponent() {
    return {
        type: START_UPDATE_WRAPPER_WORKFLOW_COMPONENT,
        payload: {}
    };
}

function endUpdateOrgWorkflowComponent(success, error) {
    return {
        type: END_UPDATE_WRAPPER_WORKFLOW_COMPONENT,
        payload: {
            success, error
        }
    };
}

export function updateOrgWorkflowComponent(id, componentId, data) {
    console.log(data,'Update ')
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgWorkflowComponent());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgWorkflowComponent(response, error));
        } catch (e) {
            dispatch(endUpdateOrgWorkflowComponent(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startGetOrgWorkflowComponent() {
    return {
        type: START_GET_WRAPPER_WORKFLOW_COMPONENT,
        payload: {}
    };
}

function endGetOrgWorkflowComponent(success, error) {
    return {
        type: END_GET_WRAPPER_WORKFLOW_COMPONENT,
        payload: {
            success, error
        }
    };
}

export function getWrapperWorkflowComponent(id,parent_component_id=null) {
    console.log('Wrapper Function');
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/components?t=${timestamp}&parent_component_id=${parent_component_id}`;
    return async (dispatch) => {
        dispatch(startGetOrgWorkflowComponent());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endGetOrgWorkflowComponent(response, error));
        } catch (e) {
            dispatch(endGetOrgWorkflowComponent(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOrgWorkflowComponent() {
    return {
        type: START_DELETE_WRAPPER_WORKFLOW_COMPONENT,
        payload: {}
    };
}

function endDeleteOrgWorkflowComponent(success, error) {
    return {
        type: END_DELETE_WRAPPER_WORKFLOW_COMPONENT,
        payload: {
            success, error
        }
    };
}

export function deleteOrgWorkflowComponent(id, componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteOrgWorkflowComponent());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgWorkflowComponent(response, error));
        } catch (e) {
            dispatch(endDeleteOrgWorkflowComponent(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAddOrgWorkflowAction() {
    return {
        type: START_ADD_WRAPPER_WORKFLOW_ACTION,
        payload: {}
    };
}

function endAddOrgWorkflowAction(success, error) {
    return {
        type: END_ADD_WRAPPER_WORKFLOW_ACTION,
        payload: {
            success, error
        }
    };
}

export function addOrgWorkflowAction(id, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/action?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOrgWorkflowAction());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddOrgWorkflowAction(response, error));
        } catch (e) {
            dispatch(endAddOrgWorkflowAction(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateOrgWorkflowAction() {
    return {
        type: START_UPDATE_WRAPPER_WORKFLOW_ACTION,
        payload: {}
    };
}

function endUpdateOrgWorkflowAction(success, error) {
    return {
        type: END_UPDATE_WRAPPER_WORKFLOW_ACTION,
        payload: {
            success, error
        }
    };
}

export function updateOrgWorkflowAction(id, actionId, data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/action/${actionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOrgWorkflowAction());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateOrgWorkflowAction(response, error));
        } catch (e) {
            dispatch(endUpdateOrgWorkflowAction(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgWorkflowAction() {
    return {
        type: START_FETCH_WRAPPER_WORKFLOW_ACTION,
        payload: {}
    };
}

function endFetchOrgWorkflowAction(success, error) {
    return {
        type: END_FETCH_WRAPPER_WORKFLOW_ACTION,
        payload: {
            success, error
        }
    };
}

export function fetchOrgWorkflowAction(id,parent_component_id=null) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/actions?t=${timestamp}&parent_component_id=${parent_component_id}`;
    return async (dispatch) => {
        dispatch(startFetchOrgWorkflowAction());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgWorkflowAction(response, error));
        } catch (e) {
            dispatch(endFetchOrgWorkflowAction(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startDeleteOrgWorkflowAction() {
    return {
        type: START_DELETE_WRAPPER_WORKFLOW_ACTION,
        payload: {}
    };
}

function endDeleteOrgWorkflowAction(success, error) {
    return {
        type: END_DELETE_WRAPPER_WORKFLOW_ACTION,
        payload: {
            success, error
        }
    };
}

export function deleteOrgWorkflowAction(id, actionId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/action/${actionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteOrgWorkflowAction());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgWorkflowAction(response, error));
        } catch (e) {
            dispatch(endDeleteOrgWorkflowAction(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function showMasterComponentDialog() {
    return {
        type: SHOW_MASTER_COMPONENT_DIALOG,
        payload: {}
    };
}


export function hideMasterComponentDialog() {
    return {
        type: HIDE_MASTER_COMPONENT_DIALOG,
        payload: {}
    };
}


function startFetchOrgWorkflowComponentDetails() {
    return {
        type: START_GET_WRAPPER_WORKFLOW_COMPONENT_DETAILS,
        payload: {}
    };
}

function endFetchOrgWorkflowComponentDetails(success, error) {
    return {
        type: END_GET_WRAPPER_WORKFLOW_COMPONENT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function fetchOrgWorkflowComponentDetails(id, componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOrgWorkflowComponentDetails());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgWorkflowComponentDetails(response, error));
        } catch (e) {
            dispatch(endFetchOrgWorkflowComponentDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}


// Workflow custom form actions start
function startAddWorkFlowCustomFormData() { 
    return {
        type: START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {}
    };
}

function endAddWorkFlowCustomFormData(success, error) {
    return {
        type: END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {
            success, error
        }
    };
}

export function addWorkFlowCustomFormData(id, componentId,data={}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddWorkFlowCustomFormData());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddWorkFlowCustomFormData(response, error));
        } catch (e) {
            dispatch(endAddWorkFlowCustomFormData(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateWorkFlowCustomFormData() {
    return {
        type: START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {}
    };
}

function endUpdateWorkFlowCustomFormData(success, error) {
    return {
        type: END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {
            success, error
        }
    };
}

export function updateWorkFlowCustomFormData(id, componentId, formId, data={}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateWorkFlowCustomFormData());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateWorkFlowCustomFormData(response, error));
        } catch (e) {
            dispatch(endUpdateWorkFlowCustomFormData(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteWorkFlowCustomFormData() {
    return {
        type: START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {}
    };
}

function endDeleteWorkFlowCustomFormData(success, error) {
    return {
        type: END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS,
        payload: {
            success, error
        }
    };
}

export function deleteWorkFlowCustomFormData(id, componentId, formId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteWorkFlowCustomFormData());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteWorkFlowCustomFormData(response, error));
        } catch (e) {
            dispatch(endDeleteWorkFlowCustomFormData(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchWorkFlowAllCustomForms() {
    return {
        type: START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM,
        payload: {}
    };
}

function endFetchWorkFlowAllCustomForms(success, error) {
    return {
        type: END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM,
        payload: {
            success, error
        }
    };
}

export function fetchWorkFlowCustomForm(id, componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkFlowAllCustomForms());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkFlowAllCustomForms(response, error));
        } catch (e) {
            dispatch(endFetchWorkFlowAllCustomForms(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchWorkFlowCustomFormUsingId() {
    return {
        type: START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_BY_ID,
        payload: {}
    };
}

function endFetchWorkFlowCustomFormUsingId(success, error) {
    return {
        type: END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_BY_ID,
        payload: {
            success, error
        }
    };
}

export function fetchWorkFlowCustomFormUsingId(id, componentId, formId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkFlowCustomFormUsingId());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkFlowCustomFormUsingId(response, error));
        } catch (e) {
            dispatch(endFetchWorkFlowCustomFormUsingId(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddWorkFlowCustomFormSection() { 
    return {
        type: START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {}
    };
}

function endAddWorkFlowCustomFormSection(success, error) {
    return {
        type: END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {
            success, error
        }
    };
}

export function addWorkFlowCustomFormSection(id, componentId, formId, data={}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddWorkFlowCustomFormSection());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddWorkFlowCustomFormSection(response, error));
        } catch (e) {
            dispatch(endAddWorkFlowCustomFormSection(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateWorkFlowCustomFormSection() { 
    return {
        type: START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {}
    };
}

function endUpdateWorkFlowCustomFormSection(success, error) {
    return {
        type: END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {
            success, error
        }
    };
}

export function updateWorkFlowCustomFormSection(id, componentId, formId,sectionId, data={}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateWorkFlowCustomFormSection());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateWorkFlowCustomFormSection(response, error));
        } catch (e) {
            dispatch(endUpdateWorkFlowCustomFormSection(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchWorkFlowCustomFormSections() {
    return {
        type: START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTIONS,
        payload: {}
    };
}

function endFetchWorkFlowCustomFormSections(success, error) {
    return {
        type: END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTIONS,
        payload: {
            success, error
        }
    };
}

export function fetchWorkFlowCustomFormSections(id, componentId, formId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/sections?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkFlowCustomFormSections());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkFlowCustomFormSections(response, error));
        } catch (e) {
            dispatch(endFetchWorkFlowCustomFormSections(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteWorkFlowCustomFormSection() {
    return {
        type: START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {}
    };
}

function endDeleteWorkFlowCustomFormSection(success, error) {
    return {
        type: END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION,
        payload: {
            success, error
        }
    };
}

export function deleteWorkFlowCustomFormSection(id, componentId, formId, sectionId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteWorkFlowCustomFormSection());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteWorkFlowCustomFormSection(response, error));
        } catch (e) {
            dispatch(endDeleteWorkFlowCustomFormSection(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddWorkFlowCustomFormSectionItem() { 
    return {
        type: START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {}
    };
}

function endAddWorkFlowCustomFormSectionItem(success, error) {
    return {
        type: END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {
            success, error
        }
    };
}

export function addWorkFlowCustomFormSectionItem(id, componentId, formId, sectionId, data={}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}/item?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddWorkFlowCustomFormSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
            dispatch(endAddWorkFlowCustomFormSectionItem(response, error));
        } catch (e) {
            dispatch(endAddWorkFlowCustomFormSectionItem(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateWorkFlowCustomFormSectionItem() { 
    return {
        type: START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {}
    };
}

function endUpdateWorkFlowCustomFormSectionItem(success, error) {
    return {
        type: END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {
            success, error
        }
    };
}

export function updateWorkFlowCustomFormSectionItem(id, componentId, formId, sectionId, itemId, data={}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}/item/${itemId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateWorkFlowCustomFormSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateWorkFlowCustomFormSectionItem(response, error));
        } catch (e) {
            dispatch(endUpdateWorkFlowCustomFormSectionItem(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchWorkFlowCustomFormSectionItems() {
    return {
        type: START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS,
        payload: {}
    };
}

function endFetchWorkFlowCustomFormSectionItems(success, error) {
    return {
        type: END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS,
        payload: {
            success, error
        }
    };
}

export function fetchWorkFlowCustomFormSectionItems(id, componentId, formId,sectionId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}/items?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkFlowCustomFormSectionItems());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkFlowCustomFormSectionItems(response, error));
        } catch (e) {
            dispatch(endFetchWorkFlowCustomFormSectionItems(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteWorkFlowCustomFormSectionItem() {
    return {
        type: START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {}
    };
}

function endDeleteWorkFlowCustomFormSectionItem(success, error) {
    return {
        type: END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
        payload: {
            success, error
        }
    };
}

export function deleteWorkFlowCustomFormSectionItem(id, componentId, formId, sectionId, itemId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}/item/${itemId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteWorkFlowCustomFormSectionItem());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteWorkFlowCustomFormSectionItem(response, error));
        } catch (e) {
            dispatch(endDeleteWorkFlowCustomFormSectionItem(null, {
                message: e.message
            }));
            return;
        }
    };
}

// function startUpdateWorkFlowCustomFormSectionSequence(data) {
//     return {
//         type: START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_SEQ,
//         payload: {data}
//     };
// }

// function endUpdateWorkFlowCustomFormSectionSequence(success, error) {
//     return {
//         type: END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_SEQ,
//         payload: {
//             success, error
//         }
//     };
// }

export function updateWorkFlowCustomFormSectionSequence(id, componentId, formId, sectionId,data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/${id}/component/${componentId}/form/${formId}/section/${sectionId}/seq?t=${timestamp}`;
    // return async (dispatch) => {
    //     dispatch(startUpdateWorkFlowCustomFormSectionSequence({id, componentId, formId, sectionId,data}));
    //     try {
    //         let [response, error] = await HttpFactory.instance().putMethod(url, data);
    //         dispatch(endUpdateWorkFlowCustomFormSectionSequence(response, error));
    //     } catch (e) {
    //         dispatch(endUpdateWorkFlowCustomFormSectionSequence(null, {
    //             message: e.message
    //         }));
    //         return;
    //     }
    // };
    return async (dispatch) => {
        try{
            await HttpFactory.instance().putMethod(url, data);
            dispatch(fetchWorkFlowCustomFormSections(id, componentId, formId));
        } catch(e){
            return;
        }
    };
}

export function showWrapperComponentDialog() {
    return {
        type: SHOW_WRAPPER_COMPONENT_DIALOG,
        payload: {}
    };
}


export function hideWrapperComponentDialog() {
    return {
        type: HIDE_WRAPPER_COMPONENT_DIALOG,
        payload: {}
    };
}

export function showWrapperMasterComponentDialog() {
    return {
        type: SHOW_WRAPPER_MASTER_COMPONENT_DIALOG,
        payload: {}
    };
}


export function hideWrapperMasterComponentDialog() {
    return {
        type: HIDE_WRAPPER_MASTER_COMPONENT_DIALOG,
        payload: {}
    };
}
// Workflow custom form actions end
