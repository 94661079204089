import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
// import Evaluation from "./Components/Evaluation/index";
import { fetchEvaluationCart } from "../../../../redux/evaluation/cart/action";
import EvaluationMetaDataUpdateDialog from "Components/Application/Components/Evaluate/Components/Common/EvaluationMetaDataUpdateDialog.jsx";
// import FeatureDialog from './Components/Common/FeatureDialog';
import { updateDrawerStyle } from "../../../../redux/drawer/action";
 
import AddProductManuallyDialog from "Components/Application/Components/Settings/AddProductManuallyDialog";
import MyProducts from "./Components/MyProducts";


const connectedProps = (state) => ({
  addProductManuallyDialogOpen: state.productsSettings.addProductManuallyDialogOpen,
});

const connectionActions = {
  fetchEvaluationCart: fetchEvaluationCart,
  updateDrawerStyle: updateDrawerStyle,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class Products extends React.Component {
  componentDidMount() {
    this.props.fetchEvaluationCart();
    this.props.updateDrawerStyle(true, true);
  }
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={this.props.match.path} render={(props) => (
              <MyProducts {...props} title="My Products" />
            )} />

        </Switch>
        <EvaluationMetaDataUpdateDialog />
        {
            this.props.addProductManuallyDialogOpen &&
            <AddProductManuallyDialog />
        }
      </React.Fragment>
    );
  }
}

export default connector(compose(withStyles(styles))(Products));
