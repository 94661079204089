import { Axios } from "./index";
import * as Environment from "./../util/Environment";
import {HttpFactory} from "./../services/httpService";

export class EvaluationFactory{
    static _instance;
    
    static instance() {
        if (EvaluationFactory._instance == null) {
            EvaluationFactory._instance = new EvaluationFactory();
        }
        return EvaluationFactory._instance;
    }

   
    async getDraftEvaluation(){
        const api_server = Environment.api_host("EVALUATION");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/draft-evaluation?t=${timestamp}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async updateDraftEvaluation(parameters){
        const api_server = Environment.api_host("EVALUATION");
        // const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/draft-evaluation`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.post(url, parameters, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }  

    async getEvaluationProducts(evId){
        const api_server = Environment.api_host("EVALUATION");
        // const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/${evId}/products`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.get(url,{
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async addProductToEvaluation(evId,params){
        const api_server = Environment.api_host("EVALUATION");
        // const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/${evId}/products`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.post(url,params,{
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async removeProductFromEvaluation(evId,productId){
        const api_server = Environment.api_host("EVALUATION");
        // const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/${evId}/products/${productId}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.delete(url,{
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getEvaluation(evaluationId){
        const api_server = Environment.api_host("EVALUATION");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/${evaluationId}?t=${timestamp}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async updateEvaluation(evaluationId,data){
        const api_server = Environment.api_host("EVALUATION");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/${evaluationId}?t=${timestamp}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.put(url,data, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getVendorEvaluation(queryParam,duplicateCall=false){
        const { page = 0, pageSize = 10, status = [], type = [1],query=""} = queryParam;
        const api_server = Environment.api_host("EVALUATION");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/fetch?t=${timestamp}`;


        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url, {
                page,pageSize,status,type,query
            },duplicateCall);
            return [response,error]
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
    }

    async removeCollaboratorFromEvaluation(props){
        const {evId, collaboratorId} = props;
        const api_server = Environment.api_host("EVALUATION");
        // const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/${evId}/colloborator/${collaboratorId}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.delete(url,{
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async removeCollaboratorFromCriteria(props){
        const {evId, criteriaId, collaboratorId} = props;
        const api_server = Environment.api_host("EVALUATION");
        // const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/${evId}/criteria/${criteriaId}/colloborator/${collaboratorId}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.delete(url,{
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async removeCollaboratorFromRequirement(props){
        const {evId, criteriaId, requirementId, collaboratorId} = props;
        const api_server = Environment.api_host("EVALUATION");
        // const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/${evId}/criteria/${criteriaId}/requirement/${requirementId}/colloborator/${collaboratorId}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.delete(url,{
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }
}