import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slider, Button, Slide, Typography, Grid } from "@material-ui/core";
import { withRouter } from "react-router";
import { updateSearchSettings, fetchSearchSettings } from "redux/seller/assessment/action";
import { showSnackBar } from "redux/snackbar/action";
import { sellerQuestionSearch } from "redux/seller/library/action";

//redux

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  updateSearchSettingsProg: state.sellerAssessment.updateSearchSettingsProg,
  updateSearchSettingsError: state.sellerAssessment.updateSearchSettingsError,
  searchSettings: state.sellerAssessment.searchSettings,
  searchSettingsProg: state.sellerAssessment.searchSettingsProg,
});

const connectionActions = {
  updateSearchSettings: updateSearchSettings,
  fetchSearchSettings: fetchSearchSettings,
  showSnackBar: showSnackBar,
  sellerQuestionSearch: sellerQuestionSearch,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(4, 8) + " !important",
    },
    formControl: {
      marginBottom: theme.spacing(2),
      "& [class*='MuiSelect-outlined-']": {
        color: "#5F5F5F !important",
      },
      "& .select__value-container": {
        padding: "12px",
        "& .select__single-value, .select__placeholder": {
          color: "#5F5F5F",
          fontSize: "17px",
        },
      },
    },
    formAction: {
      paddingTop: theme.spacing(3),
      textAlign: "center",
    },
    title: {
      fontSize: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
    },
  });

class UpdateSearchSettingsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      in_review_confidence_score: 100,
      auto_populate_confidence_score: 100,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchSettingsProg === false && prevProps.searchSettingsProg === true) {
      this.setState({
        in_review_confidence_score: this.props.searchSettings?.in_review_confidence_score * 100 || 100,
        auto_populate_confidence_score: this.props.searchSettings?.auto_populate_confidence_score * 100 || 100,
      });
    }
    if (this.props.updateSearchSettingsProg === false && prevProps.updateSearchSettingsProg === true) {
      if (this.props.updateSearchSettingsError) {
        this.props.showSnackBar("Failed to update", "error", 3000);
      } else {
        this.props.fetchSearchSettings(this.props.match?.params?.uuid);
        this.applyGenie();
        this.props.onClose();
        this.props.showSnackBar("Updated Successfully", "success", 3000);
      }
    }
  }

  componentDidMount() {
    this.setState({
      in_review_confidence_score: this.props.searchSettings?.in_review_confidence_score * 100 || 100,
      auto_populate_confidence_score: this.props.searchSettings?.auto_populate_confidence_score * 100 || 100,
    });
  }

  applyGenie = () => {
    let is_global;
    if (this.props.sellerAssessmentDetails?.data?.length > 0) {
      let data = this.props.sellerAssessmentDetails?.data[0] || {};
      if (data?.assessment?.name) {
        is_global = data?.assessment?.is_global;
      }
    }
    this.props.sellerQuestionSearch({
      search_type: "embeddings",
      confidence: "high",
      assessment: { id: this.props.match.params.uuid, type: "imported" },
      filters: { is_global: is_global, different_answer_type: true },
    });
  };

  onSubmit = () => {
    this.props.updateSearchSettings(this.props.match?.params?.uuid, {
      in_review_confidence_score: this.state.in_review_confidence_score / 100,
      auto_populate_confidence_score: this.state.auto_populate_confidence_score / 100,
    });
  };

  render() {
    const classes = this.props.classes;

    return (
      <Dialog
        onClose={this.props.onClose}
        aria-labelledby="add-teamDialog"
        open={true}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"sm"}
        scroll={"body"}
        id={"UpdateSearchSettingsDialog"}
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Typography variant={"h6"} className={classes.title}>
            Genie Configuration
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Typography id="discrete-slider-always" gutterBottom style={{ marginBottom: 35 }}>
                Genie response threshold
              </Typography>
              <Slider
                value={this.state.in_review_confidence_score}
                aria-labelledby="discrete-slider-always"
                step={10}
                valueLabelDisplay="on"
                scale={(value) => {
                  return `${value}%`;
                }}
                onChange={(e, newValue) => {
                  this.setState({
                    in_review_confidence_score: newValue,
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Typography id="discrete-slider-always" gutterBottom style={{ marginBottom: 35 }}>
                Genie auto populate threshold
              </Typography>

              <Slider
                value={this.state.auto_populate_confidence_score}
                aria-labelledby="discrete-slider-always"
                step={10}
                valueLabelDisplay="on"
                scale={(value) => {
                  return `${value}%`;
                }}
                onChange={(e, newValue) => {
                  this.setState({
                    auto_populate_confidence_score: newValue,
                  });
                }}
              />
            </Grid>
          </Grid>

          <div className={classes.formAction}>
            <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.onClose}>
              Close
            </Button>
            &nbsp; &nbsp;
            <Button variant="contained" color="secondary" className={classes.button} onClick={this.onSubmit}>
              Update & Run Genie
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(UpdateSearchSettingsDialog));
