

export const SHOW_BUILDER_CONFIGURATION_DIALOG = "SHOW_BUILDER_CONFIGURATION_DIALOG";
export const HIDE_BUILDER_CONFIGURATION_DIALOG = "HIDE_BUILDER_CONFIGURATION_DIALOG";

export function showBuilderConfigDrawer(data) {
    return {
        type: SHOW_BUILDER_CONFIGURATION_DIALOG,
        payload: {
            data
        }
    };
}

export function hideBuilderConfigDrawer() {
    return {
        type: HIDE_BUILDER_CONFIGURATION_DIALOG,
        payload: {}
    };
}
