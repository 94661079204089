import { Button, CircularProgress, Dialog, DialogContent, Divider, Grid, InputAdornment, Slide, Switch, TextField, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import deleteIcon from "assets/images/delete.svg";
import classnames from "classnames";
import EvaluateMultiChoiceAnswer from "Components/Common/EvaluateMultiChoiceAnswer";
import Image from "Components/Common/image.jsx";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import ToolTip from "Components/Common/ToolTip.jsx";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { fetchSimilarQuestion, showEvaluationCollaboratorDialog } from "redux/evaluate/action";
import { addFromOrgEvaluationCriteriaTemplate } from "redux/templates/evaluation/org/action";
import _ from "underscore";
import { hideEvaluationCollaboratorDialog } from "redux/evaluate/action";
import CodeIcon from '@material-ui/icons/Code';

import { addApprovalCriteria, fetchApprovalCriteria } from "redux/evaluation/approval/action";
import { addCriteria, hideEvaluationCriteriaAddDialog } from "../../../../../../redux/evaluation/criteria/action";
import { showSnackBar } from "../../../../../../redux/snackbar/action";
import OutlinedInput from "../../../../../Common/Input/OutlinedInput";
import OutlinedSelectInput from "../../../../../Common/Input/OutlinedSelectInput";
import SqureButton from "../../../../../Common/SqureButton";
import UserGroup from "../../../../../Common/UserGroup/index";
import RequirementSimilarQuestionDialog from "./RequirementSimilarQuestionDialog";
import SchemaTagSelectionDialog from "Components/Application/Components/Settings/WorkflowBuilder/Dialogs/SchemaTagSelectionDialog.jsx";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.evaludationCriteria.showAddDialog,
  evaluationId: state.evaludationCriteria.evaluationId,
  componentId: state.evaludationCriteria.componentId,
  productId: state.evaludationCriteria.productId,
  addDialogForApproval: state.evaludationCriteria.addDialogForApproval,
  addCriteriaProgress: state.evaludationCriteria.addCriteriaProgress,
  addApprovalCriteriaProgress: state.evaluationApproval.addApprovalCriteriaProgress,
  addApprovalCriteriaError: state.evaluationApproval.addApprovalCriteriaError,
  errorCriteriaAdd: state.evaludationCriteria.errorCriteriaAdd,
  addCriteriaTemplateProg: state.orgTemplateEvaluation.addFromOrgEvaluationCriteriaTemplateProg,
  addCriteriaTemplateError: state.orgTemplateEvaluation.addFromOrgEvaluationCriteriaTemplateError,
  collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
  similarQuestion: state.evaluate.similarQuestion,
  similarQuestionProg: state.evaluate.similarQuestionProg,
});

const connectionActions = {
  hideDialog: hideEvaluationCriteriaAddDialog,
  addCriteria: addCriteria,
  addApprovalCriteria: addApprovalCriteria,
  fetchApprovalCriteria: fetchApprovalCriteria,
  showSnackBar: showSnackBar,
  addFromOrgEvaluationCriteriaTemplate: addFromOrgEvaluationCriteriaTemplate,
  showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog,
  fetchSimilarQuestion: fetchSimilarQuestion,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(6, 12) + " !important",
    },
    modalTitle: {
      fontSize: theme.spacing(2.4),
      marginBottom: theme.spacing(3),
      textAlign: "center",
    },
    helptext: {
      fontSize: theme.spacing(1.8),
    },
    alerts: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    form: {
      marginTop: theme.spacing(2),
      width: "60%",
    },
    input: {
      marginBottom: theme.spacing(3),
    },
    scheduleTitle: {
      display: "block",
      marginBottom: theme.spacing(3),
      fontWeight: 600,
      fontSize: theme.spacing(2),
    },
    scheduleTitle1: {
      display: "block",
      marginBottom: theme.spacing(3),
      fontWeight: 600,
      fontSize: theme.spacing(1.8),
    },
    actions: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      textAlign: "center",
    },
    loader: {
      textAlign: "center",
      padding: theme.spacing(10, 0),
    },
    dialogAction: {
      textAlign: "center",
    },
    addNewButton: {
      fontWeight: 600,
      fontSize: theme.spacing(2),
    },
    actionBtn: {
      minWidth: 110,
    },
    nameYour: {
      "& [class*=MuiInputBase-root]": {
        maxHeight: 40,
        minHeight: 40,
        "& input": {
          fontSize: theme.spacing(1.8),
        },
      },
    },
    nameOfCriteria: {
      "& > div": {
        marginBottom: 0,
      },
    },
    priority: {
      "& > div": {
        marginBottom: 0,
        justifyContent: "flex-start",
      },
      "& [class*=MuiInputBase-root]": {
        width: 100,
        color: "#707070",
        fontSize: 13,
        textAlign: "center",
      },
      "& [class*=MuiSelect-outlined]": {
        color: "#707070",
        fontSize: theme.spacing(1.8),
        "&:after": {
          content: "''",
          width: 7,
          height: 7,
          position: "absolute",
          left: 9,
          borderRadius: "50%",
          top: "50%",
          transform: "translate(0px, -50%)",
        },
      },
      "&.low [class*=MuiSelect-outlined]:after": {
        background: "green",
      },
      "&.medium [class*=MuiSelect-outlined]:after": {
        background: "orange",
      },
      "&.high [class*=MuiSelect-outlined]:after": {
        background: "red",
      },
    },
    scoring: {
      "& > div": {
        alignItems: "baseline",
        marginBottom: 0,
      },
      "& [class*=MuiSelect-outlined]": {
        paddingLeft: 10,
      },
    },
    yourQuestion: {
      "& [class*=MuiInputBase-root]": {
        padding: 0,
        fontSize: theme.spacing(1.8),
        minHeight: 120,
        color: "#707070",
        lineHeight: 1.2,
        "& textarea": {
          minHeight: 100,
        },
      },
    },
    addCriteriaDatePicker: {
      "& .react-datepicker__input-container > div": {
        display: "flex",
        minWidth: 300,
        marginBottom: 0,
        alignItems: "baseline",
        "& [class*=MuiInputBase-root]": {
          maxHeight: 33,
          minHeight: 33,
          width: 166,
          "& input": {
            fontSize: theme.spacing(1.8),
            // width: 107,
            textAlign: "center",
          },
        },
        "& [class*=MuiFormLabel-root]": {
          marginBottom: 0,
          marginRight: 29,
        },
      },
      "[class*=MuiMenuItem-root]": {
        color: "#707070",
        fontSize: theme.spacing(1.8),
      },
    },
    titleHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    titleImage: {
      cursor: "pointer",
    },
    marginBottom: {
      marginBottom: 10,
    },
    option: {
      padding: "5px 10px",
      cursor: "pointer",
      "&:hover": {
        background: "lightGray",
      },
    },
    optionLabel: {
      fontWeight: "bold",
      fontSize: theme.spacing(2),
      padding: 0,
    },
    optionDescription: {
      fontWeight: 500,
      padding: 0,
      fontSize: theme.spacing(1.5),
    },
    label: {
      color: "#6C6C6C",
      fontSize: "1em",
      margin: 0,
      padding: 0,
      marginBottom: "0.8em",
    },
    errorLabel: {
      color: "#f44336",
      fontSize: "0.75em",
      margin: "3px 14px 0px 14px",
    },
    autoSelectInput: {
      "& [class*=MuiInputBase-input]": {
        padding: "0px !important",
      },
    },
    requirementCard: {
      position: "relative",
      border: "1px solid #EBEBEB",
      borderRadius: 5,
      marginBottom: 15,
      cursor: "pointer",
      padding: "0px 15px",
    },
    selected: {
      border: "1px solid green",
      boxShadow: "0 2.8px 2.2px rgba(0, 0, 0, 0.034)",
    },
    requirementContainer: {
      maxheight: "40vh",
    },
    priorityC: {},
    criteriaPriority: {
      minHeight: "25px !important",
      padding: 6,
      border: "1px solid #c4c4c4",
      borderRadius: 4,
      color: "#5F5F5F",
      fontSize: "1.1em",
      marginLeft: 20,
      width: 75,
      textAlign: "center",
    },
    codeIcon: {
      cursor: 'pointer'
    }
  });

class EvaluationCriteriaAddDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      openedRequirement: false,
      error: "",
      success: "",
      activeMultiSelectId: false,
      name: "",
      name_error: "",
      is_mandatory: false,
      is_noneditable: false,
      criteria_schedule: null,
      criteria_schedule_error: "",
      colloborators: [],
      activeAnswerType: 1,
      priority: "",
      requirements: [
        {
          name: "",
          name_error: "",
          priority: "",
          scoring_type: 1,
          description: "",
          description_error: "",
          colloborators: [],
          answer_type: 1,
          options: [],
          is_mandatory: false,
          is_editable: false,
          is_other_option: false,
        },
      ],
    };

    this.addExtraRequirement = this.addExtraRequirement.bind(this);
    this.deleteRequirement = this.deleteRequirement.bind(this);
    this.addCriteriaColloborator = this.addCriteriaColloborator.bind(this);
    this.addRequirementColloborator = this.addRequirementColloborator.bind(this);
    this.submitResponse = this.submitResponse.bind(this);
    this.cleanError = this.cleanError.bind(this);
    this.cleanState = this.cleanState.bind(this);
    this.removeCriteriaCollaborator = this.removeCriteriaCollaborator.bind(this);
    this.removeRequirementColloborator = this.removeRequirementColloborator.bind(this);
    this.updateCriteriaColloborator = this.updateCriteriaColloborator.bind(this);
  }

  cleanState() {
    this.setState({
      error: "",
      success: "",
      name: "",
      name_error: "",
      is_mandatory: false,
      is_noneditable: false,
      criteria_schedule: null,
      criteria_schedule_error: "",
      colloborators: [],
      requirements: [
        {
          name: "",
          name_error: "",
          priority: "",
          scoring_type: 1,
          answer_type: 1,
          options: [],
          description: "",
          description_error: "",
          is_mandatory: false,
          is_editable: false,
          is_other_option: false,
          colloborators: [],
        },
      ],
      priority: "",
      priority_error: "",
      showProjectSchema: false,
      selectedVariableIndex: null,
      variableForCriteria: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isTemplate && !this.props.addCriteriaTemplateProg && prevProps.addCriteriaTemplateProg) {
      if (this.props.addCriteriaTemplateError == null) {
        this.props.hideDialog();
        this.cleanState();
        this.props.showSnackBar("criteria added successfully", "success", 3000);
      } else {
        if (this.props.addCriteriaTemplateError.message !== undefined) {
          this.setState({
            error: "Failed to add criteria.",
          });
        } else {
          this.setState({
            error: "Something went wrong.",
          });
        }
      }
    }
    if (!this.props.isTemplate && this.props.addApprovalCriteriaProgress === false && prevProps.addApprovalCriteriaProgress === true && this.props.isOpen) {
      if (this.props.addApprovalCriteriaError == null) {
        //Migrate to different page
        this.props.hideDialog();
        this.cleanState();
        this.props.showSnackBar("Section Added", "success", 3000);
        this.props.fetchApprovalCriteria(this.props.evaluationId, this.props.componentId);
      } else {
        if (this.props.addApprovalCriteriaError.message !== undefined) {
          this.setState({
            error: "Failed to add Section Details.",
          });
        } else {
          this.setState({
            error: "Something went wrong.",
          });
        }
      }
    }

    if (!this.props.isTemplate && this.props.addCriteriaProgress === false && prevProps.addCriteriaProgress === true) {
      if (this.props.errorCriteriaAdd == null) {
        //Migrate to different page
        this.props.hideDialog();
        this.cleanState();
        this.props.showSnackBar("Section Added", "success", 3000);
      } else {
        if (this.props.errorCriteriaAdd.message !== undefined) {
          this.setState({
            error: "Failed to add Section Details.",
          });
        } else {
          this.setState({
            error: "Something went wrong.",
          });
        }
      }
    }
  }

  cleanError() {
    var requirements = this.state.requirements;
    requirements.forEach((r) => {
      r.name_error = "";
      r.description_error = "";
    });
    this.setState({
      name_error: "",
      criteria_schedule_error: "",
      requirements: requirements,
    });
  }

  submitResponse() {
    this.cleanError();

    var name = this.state.name;
    if (name.trim().length === 0) {
      this.setState({
        name_error: "Please enter Section Name.",
      });
      return;
    }

    var isWorkflowApprovalPage = this.props.addDialogForApproval;
    if (!isWorkflowApprovalPage) {
      var criteria_schedule = this.state.criteria_schedule;

      // if (!this.props.isTemplate && criteria_schedule == null) {
      //     this.setState({
      //         criteria_schedule_error: "Please enter Section Schedule."
      //     });
      //     return;
      // }

      var requirements = this.state.requirements;
      for (var i = 0; i < requirements.length; i++) {
        var requirement = requirements[i];
        var rName = requirement.name;
        if (rName.trim().length === 0) {
          requirement.name_error = "Please enter name.";
          this.setState({
            requirements: requirements,
          });
          return;
        }

        var rDesc = requirement.description;
        if (rDesc.trim().length === 0) {
          requirement.description_error = "Please enter question.";
          this.setState({
            requirements: requirements,
          });
          return;
        }
      }
      const data = {
        name: name,
        is_editable: !this.state.is_noneditable,
        is_mandatory: this.state.is_mandatory,
        priority: parseInt(this.state.priority),
        colloborators: this.state.colloborators.map((c) => {
          return {
            userId: c.ID,
            priority: 50,
          };
        }),
        requirements: this.state.requirements.map((r) => {
          return {
            name: r.name,
            description: r.description,
            priority: parseInt(r.priority),
            scoring_type: parseInt(r.scoring_type),
            answer_type: parseInt(r.answer_type),
            options: r.options,
            is_mandatory: r.is_mandatory,
            is_editable: r.is_editable,
            is_other_option: r.is_other_option,
            character_limit: r.character_limit,
            colloborators: r.colloborators
              ? r.colloborators?.map((c) => {
                return c.ID;
              })
              : [],
          };
        }),
      };

      if (this.props.isTemplate) {
        this.props.addFromOrgEvaluationCriteriaTemplate(this.props.evaluationId, data);
      } else {
        if (criteria_schedule !== null && criteria_schedule !== "") {
          data.schedule = criteria_schedule.toISOString();
        }
        // data.schedule = criteria_schedule !== null && criteria_schedule !== "" ? criteria_schedule.toISOString() : '';
        // data.schedule = criteria_schedule !== null && criteria_schedule !== "" ? criteria_schedule.toISOString() : new Date().toISOString();
        this.props.addCriteria(this.props.evaluationId, data);
      }
    } else {
      let approver_ids = [];
      let team_ids = [];
      if (this.state.colloborators) {
        this.state.colloborators.forEach((data) => {
          if (data.hasOwnProperty("GroupId")) {
            team_ids.push(data.ID);
          } else {
            approver_ids.push(data.ID);
          }
        });
      }

      const data = {
        criterias: [
          {
            stakeHolder: name,
            criteriaID: 0,
            configID: "",
            criteriaType: "",
            rules: [],
            componentId: Number(this.props.componentId || "0"),
            approvers: {
              approver_ids,
              team_ids,
              productID: Number(this.props.productId),
            },
          },
        ],
      };
      this.props.addApprovalCriteria(this.props.evaluationId, data);
    }
  }

  removeRequirementColloborator(index, userId) {
    const requirements = this.state.requirements;
    const requirement = requirements[index];
    let colloborators = requirement.colloborators;
    colloborators = colloborators.filter((u) => {
      if (u.ID === userId) {
        return false;
      }
      return true;
    });

    requirement.colloborators = colloborators;
    requirements[index] = requirement;
    this.setState({ requirements }, () => {
      this.props.showEvaluationCollaboratorDialog({
        ...this.props.collaboratorDialogData,
        existingUsers: colloborators,
        selectedUser: colloborators,
      });
    });
  }

  removeCriteriaCollaborator(userId) {
    var colloborators = this.state.colloborators;
    colloborators = colloborators.filter((u) => {
      if (u.ID === userId) {
        return false;
      }
      return true;
    });
    this.setState({ colloborators }, () => {
      this.props.showEvaluationCollaboratorDialog({
        ...this.props.collaboratorDialogData,
        existingUsers: colloborators,
        selectedUser: colloborators,
      });
    });
  }

  addCriteriaColloborator(user, flag) {
    var colloborators = this.state.colloborators;
    var existing = colloborators.filter((u) => {
      if (u.ID === user.ID) {
        return true;
      }
      return false;
    });
    if (existing.length > 0) {
      return;
    }
    if (flag) {
      colloborators = user;
    } else {
      colloborators.push(user);
    }
    this.setState({ colloborators: colloborators });
    this.props.hideEvaluationCollaboratorDialog();
  }

  updateCriteriaColloborator(user) {
    var colloborators = [...this.state.colloborators];
    var collaborator = colloborators.filter((o) => o.ID === user.ID)[0];
    collaborator.TemplateCriteriaPriority = user.priority;
    this.setState({ colloborators: colloborators });
  }

  addRequirementColloborator(index, user) {
    const requirements = this.state.requirements;
    const requirement = requirements[index];
    const colloborators = requirement.colloborators;
    var existing = colloborators.filter((u) => {
      if (u.ID === user.ID) {
        return true;
      }
      return false;
    });
    if (existing.length > 0) {
      return;
    }
    colloborators.push(user);
    requirement.colloborators = colloborators;
    requirements[index] = requirement;
    this.setState({ requirements });
  }

  addExtraRequirement(e) {
    var requirements = this.state.requirements;
    for (var i = 0; i < requirements.length; i++) {
      var requirement = requirements[0];
      var rName = requirement.name;
      if (rName?.trim()?.length === 0) {
        requirement.name_error = "Please enter name.";
        this.setState({
          requirements: requirements,
        });
        return;
      }

      var rDesc = requirement.description;
      if (rDesc.trim().length === 0) {
        requirement.description_error = "Please enter question.";
        this.setState({
          requirements: requirements,
        });
        return;
      }
    }
    requirements.push({
      name: "",
      name_error: "",
      priority: "",
      scoring_type: 1,
      description: "",
      description_error: "",
      colloborators: [],
      is_mandatory: false,
      is_editable: false,
      is_other_option: false,
    });
    this.setState({
      requirements: requirements,
    });
  }

  deleteRequirement(index) {
    if (this.state.requirements.length === 1) {
      return;
    }
    var requirements = this.state.requirements;
    requirements.splice(index, 1);
    this.setState({
      requirements: requirements,
    });
  }

  handleOptions = (id, answerType) => {
    let activeMultiSelectId = id;
    if ([2, 3, 4, 14, 15].includes(answerType)) {
      const element = document.getElementById(activeMultiSelectId);
      if (element && element.click()) {
        element.click();
      }
      if (activeMultiSelectId !== this.state.activeMultiSelectId) {
        this.setState({ activeMultiSelectId, activeAnswerType: answerType });
      }
    }
  };

  handleUpdateRequirement = (index, requirement, key, value, options = false, is_other_option = false, character_limit = 0) => {
    const newRequirement = requirement;
    newRequirement[key] = value;
    newRequirement.name_error = "";
    newRequirement.description_error = "";
    if (key === "answer_type" && value === 7) {
      newRequirement.character_limit = character_limit;
    }
    if (options) {
      newRequirement.options = options;
      newRequirement.is_other_option = is_other_option;
    }
    const requirements = this.state.requirements;
    requirements[index] = newRequirement;
    if (key !== "answer_type") this.handleOptions(`evalution_criteria_requiment-${index}`, requirement.answer_type);
    this.setState({ requirements });
  };

  addTagValue(value, index) {
    if (this.state.variableForCriteria) {
      let newName = this.state.name + ' {{ ' + value + ' }} ';
      this.setState({
        name: newName
      })
    } else {
      let requirements = this.state.requirements
      let req = requirements[index];
      req.name = req.name + ' {{ ' + value + ' }} ';
      this.setState({ requirements })
    }
  }

  fetchSimilarQuestionList = (query, desc = "") => {
    this.props.fetchSimilarQuestion(query, desc);
  };

  replaceRequirement = (req, k) => {
    const requirements = this.state.requirements;
    const requirement = requirements[k];
    this.handleUpdateRequirement(k, requirement, "description", req.description);
    this.handleUpdateRequirement(k, requirement, "name", req.name);
    this.setState({ open: false });
  };

  render() {
    const classes = this.props.classes;
    return (
      <>
        <Dialog
          onClose={this.props.hideDialog}
          aria-labelledby="app-integrationDialog"
          open={this.props.isOpen}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth={"md"}
          scroll="body"
          id="evMetaDialog"
        >
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Typography variant={"h2"} className={classes.modalTitle}>
              {this.props.addDialogForApproval ? "Add new Approval Step" : "Add new Section"}
            </Typography>
            <div className={classes.alerts}>
              {this.state.error.length > 0 && (
                <Alert variant="filled" severity="error">
                  {this.state.error}
                </Alert>
              )}
              {this.state.success.length > 0 && (
                <Alert variant="filled" severity="success">
                  {this.state.success}
                </Alert>
              )}
            </div>
            <div className={classes.criteriaDetail}>
              <div className={classnames(classes.marginBottom, classes.nameOfCriteria, classes.nameYour)}>
                <Typography style={{ display: 'flex' }}>{this.props.addDialogForApproval ? "Name your Approval" : "Name your Section"} &nbsp;&nbsp;
                  {!this.props.addDialogForApproval && <CodeIcon
                    onClick={() => {
                      this.setState({
                        showProjectSchema: true,
                        selectedVariableIndex: null,
                        variableForCriteria: true
                      })
                    }}
                  />}
                </Typography>

                <OutlinedInput
                  // label={this.props.addDialogForApproval ? "Name your Approval" : "Name your Section"}
                  error={this.state.name_error.length > 0}
                  helperText={this.state.name_error}
                  value={this.state.name}
                  required={true}
                  autoFocus={true}
                  onChange={(event) => {
                    this.setState({ name: event.target.value });
                  }}
                />
              </div>
              {this.props.addDialogForApproval === false && (
                <Grid container>
                  {!this.props.isTemplate && (
                    <Grid item sm={6}>
                      <div className={classes.addCriteriaDatePicker}>
                        <DatePicker
                          selected={this.state.criteria_schedule}
                          onChange={(date) => {
                            this.setState({
                              criteria_schedule: date,
                            });
                          }}
                          dateFormat="MMM do yyyy"
                          portalId="evMetaDialog"
                          className={classes.datePicker}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          customInput={
                            <OutlinedInput
                              label="Schedule"
                              readOnlyInput={true}
                              placeholder="Schedule"
                              labelStyle={"inline"}
                              error={this.state.criteria_schedule_error.length > 0}
                              helperText={this.state.criteria_schedule_error}
                              endAdornment={
                                <InputAdornment>
                                  <ArrowDropDownIcon />
                                </InputAdornment>
                              }
                            />
                          }
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item sm={6}>
                    <div className={classes.priorityC}>
                      <span className={classes.label}>Weightage</span>
                      <input
                        value={this.state.priority}
                        className={classes.criteriaPriority}
                        onChange={(event) => {
                          var priority = parseInt(event.target.value) || "";
                          if (priority < 0 || priority > 100) {
                            alert("Please enter weightage between 1 to 100.");
                            return;
                          } else {
                            this.setState({ priority: priority });
                          }
                        }}
                      ></input>
                      &nbsp;%
                    </div>
                  </Grid>
                </Grid>
              )}
              <div className={classes.UserGroupSection}>
                {this.state.colloborators && (
                  <UserGroup
                    position={"CriteriaHead"}
                    isNew={true}
                    onAdd={(user, flag) => {
                      this.addCriteriaColloborator(user, flag);
                    }}
                    updatePriority={(user) => {
                      this.updateCriteriaColloborator(user);
                    }}
                    removeCollaborator={this.removeCriteriaCollaborator}
                    criterias={[]}
                    users={this.state.colloborators}
                  />
                )}
              </div>
            </div>
          </DialogContent>
          <Divider />
          {!this.state.isOpen &&
            this.props.addDialogForApproval === false &&
            this.state.requirements.map((requirement, k) => {
              return (
                <React.Fragment key={k}>
                  <DialogContent classes={{ root: classes.dialogContent }}>
                    <div className={classes.titleHead}>
                      <span></span>
                      <Typography variant={"h2"} className={classes.modalTitle}>
                        Add new Question <ToolTip toolTip="23" />
                      </Typography>
                      <div>
                        {k !== 0 && (
                          <div onClick={() => this.deleteRequirement(k)}>
                            <Image src={deleteIcon} alt="forgotpassword_image" className={classes.titleImage} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <Grid container>
                        <Grid item xs={12} lg={12}>
                          <div className={classes.nameYour}>
                            <p className={classes.label} style={requirement.name_error ? { color: "#f44336" } : {}}>
                              Name Your Question{<span style={{ color: "red" }}>&nbsp;*</span>}
                              {false && <CodeIcon
                                onClick={() => {
                                  this.setState({
                                    showProjectSchema: true,
                                    selectedVariableIndex: k,
                                    variableForCriteria: false
                                  })
                                }}
                              />}
                            </p>
                            <Autocomplete
                              id="asynchronous-demo"
                              open={this.state?.open === k}
                              onOpen={() => {
                                this.setState({ open: k });
                              }}
                              onClose={() => {
                                this.setState({ open: false });
                              }}
                              value={{ label: requirement.name }}
                              getOptionSelected={(option, value) => option.name === value.name}
                              getOptionLabel={(option) => option.label}
                              options={Array.isArray(this.props?.similarQuestion) ? this.props.similarQuestion.map((sqitem) => ({ label: sqitem.name, value: sqitem })) : []}
                              renderOption={(option) => (
                                <div>
                                  <p className={classes.optionlabel}>{option.label}</p>
                                  <p className={classes.optionDescription}>{option?.value?.description}</p>
                                </div>
                              )}
                              loading={this.props.similarQuestionProg}
                              onInputChange={(e) => {
                                // if (this.state.open === k) {
                                this.handleUpdateRequirement(k, requirement, "name", e?.target?.value);
                                if (e?.target?.value && e?.target?.value.length > 0) this.fetchSimilarQuestionList(e.target.value, "");
                                // }
                              }}
                              onChange={(event, value) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.setState({ open: false }, () => {
                                  this.handleUpdateRequirement(k, requirement, "description", value?.value?.description);
                                  this.handleUpdateRequirement(k, requirement, "name", value?.label);
                                  this.fetchSimilarQuestionList("");
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  className={classes.autoSelectInput}
                                  error={requirement.name_error.length > 0}
                                  helperText={requirement.name_error}
                                  value={requirement.name ? { label: requirement.name } : ""}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {this.props.similarQuestionProg ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                            <br />
                          </div>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <div className={classes.yourQuestion}>
                            <SlateInputField
                              label="Explain Your Question"
                              error={!!requirement.description_error}
                              helperText={requirement.description_error}
                              folderPath={`/evaluation/${this.props?.evaluationId}/newcriteria`}
                              as={SlateInputField}
                              onChangeEvent={(value) => {
                                this.handleUpdateRequirement(k, requirement, "description", value);
                              }}
                              customBtn={requirement?.name?.length > 0}
                              customBtnHTML={
                                <SqureButton
                                  cls={classes.actionBtn}
                                  variant={"contained"}
                                  onClick={(e) => {
                                    this.setState({ isOpen: true, openedRequirement: k }, () => {
                                      this.fetchSimilarQuestionList(requirement.name, requirement.description);
                                    });
                                  }}
                                >
                                  Check for similar requirements
                                </SqureButton>
                              }
                              initValue={requirement.description}
                              textContainerStyle={{ color: "#707070" }}
                              isShowAddVariableOption={true}
                              workflowId={0}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between" spacing={3}>
                        <Grid item xs={6}>
                          {requirement && (
                            <EvaluateMultiChoiceAnswer
                              editable={true}
                              isCriteriaRequirement
                              requirement={{
                                answer_type: requirement.answer_type,
                                options: requirement.options,
                              }}
                              saveBtnId={`evalution_criteria_requiment-${k}`}
                              isNew={true}
                              onChange={(answerType, options, is_other_option, character_limit) => {
                                this.handleUpdateRequirement(k, requirement, "answer_type", answerType, options, is_other_option, character_limit);
                              }}
                            />
                          )}
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={4}>
                          {requirement.colloborators && (
                            <UserGroup
                              position={"Requirement"}
                              isNew={true}
                              onAdd={(user) => {
                                this.addRequirementColloborator(k, user);
                              }}
                              users={requirement.colloborators}
                              removeCollaborator={(id) => this.removeRequirementColloborator(k, id)}
                            />
                          )}

                          <br />
                          <div className={classes.priorityC}>
                            <span className={classes.label}>Weightage</span>
                            <input
                              value={requirement.priority}
                              className={classes.criteriaPriority}
                              onChange={(event) => {
                                var priority = parseInt(event.target.value) || "";
                                if (priority < 0 || priority > 100) {
                                  alert("Please enter weightage between 1 to 100.");
                                  return;
                                } else {
                                  this.handleUpdateRequirement(k, requirement, "priority", event.target.value);
                                }
                              }}
                            ></input>
                            &nbsp;%
                          </div>

                          {false && (
                            <div
                              className={classnames(
                                classes.priority,
                                requirement.priority && requirement.priority === "1"
                                  ? "low"
                                  : requirement.priority && requirement.priority === "2"
                                    ? "medium"
                                    : requirement.priority && requirement.priority === "3"
                                      ? "high"
                                      : ""
                              )}
                            >
                              <OutlinedSelectInput
                                label="Priority"
                                labelStyle={"inline"}
                                id="priority"
                                value={requirement.priority}
                                options={[
                                  {
                                    value: "1",
                                    label: "Low",
                                  },
                                  {
                                    value: "2",
                                    label: "Medium",
                                  },
                                  {
                                    value: "3",
                                    label: "High",
                                  },
                                ]}
                                onChange={(event) => {
                                  this.handleUpdateRequirement(k, requirement, "priority", event.target.value);
                                }}
                              />
                            </div>
                          )}
                          <div className={classes.rightAction} style={{ marginTop: 10 }}>
                            <Grid container alignItems="center">
                              <Grid item>
                                <label className={classes.label} style={{ marginRight: 8 }}>
                                  Required
                                </label>
                                <br />
                                <br />
                                <label className={classes.label} style={{ marginRight: 8 }}>
                                  Editable
                                </label>
                              </Grid>
                              <Grid item>
                                <Switch
                                  checked={requirement.is_mandatory}
                                  onChange={(event) => {
                                    event.stopPropagation();
                                    this.handleUpdateRequirement(k, requirement, "is_mandatory", event.target.checked);
                                  }}
                                  name="is_mandatory"
                                  color="primary"
                                  inputProps={{ "aria-label": "is_mandatory checkbox" }}
                                />
                                <br />
                                <Switch
                                  checked={requirement.is_editable}
                                  onChange={(event) => {
                                    event.stopPropagation();
                                    this.handleUpdateRequirement(k, requirement, "is_editable", event.target.checked);
                                  }}
                                  color="primary"
                                  name="is_editable"
                                  inputProps={{ "aria-label": "is_editable checkbox" }}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </DialogContent>
                  <Divider />
                </React.Fragment>
              );
            })}
          <DialogContent classes={{ root: classes.dialogAction }}>
            {this.props.addDialogForApproval === false && (
              <Button
                className={classes.addNewButton}
                // disabled
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleOptions(this.state.activeMultiSelectId, this.state.activeAnswerType);
                  _.debounce(() => {
                    this.addExtraRequirement(e);
                  }, 1000)();
                }}
              >
                + Add new Question
              </Button>
            )}
            <div className={classes.actions}>
              <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>
                Cancel
              </SqureButton>
              &nbsp;&nbsp;&nbsp;
              {this.props.addApprovalCriteriaProgress ? (
                <SqureButton cls={classes.actionBtn} variant={"contained"}>
                  Loading...
                </SqureButton>
              ) : (
                <SqureButton
                  cls={classes.actionBtn}
                  variant={"contained"}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleOptions(this.state.activeMultiSelectId, this.state.activeAnswerType);
                    _.debounce(() => {
                      console.log("I am here");
                      this.submitResponse();
                    }, 1000)();
                  }}
                >
                  {this.props.addCriteriaProgress ? <CircularProgress style={{ width: 27, height: 27 }} /> : "Save"}
                </SqureButton>
              )}
            </div>
          </DialogContent>
        </Dialog>
        <RequirementSimilarQuestionDialog
          isOpen={this.state.isOpen}
          hideDialog={() => {
            this.setState({ isOpen: false, openedRequirement: false }, () => {
              this.fetchSimilarQuestionList("");
            });
          }}
          onSubmit={(req, currentReq) => this.replaceRequirement(req, this.state.openedRequirement)}
        />

        {this.state.showProjectSchema && <SchemaTagSelectionDialog
          isOpen={this.state.showProjectSchema}
          loadEverytime={false}
          workflowId={0}
          hideDialog={() => {
            this.setState({
              showProjectSchema: false,
              selectedVariableIndex: null
            })
          }}
          addTagValue={(value) => {
            this.addTagValue(value, this.state.selectedVariableIndex)
            this.setState({
              showProjectSchema: false,
              selectedVariableIndex: null
            })
          }}
        />
        }
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(EvaluationCriteriaAddDialog));
