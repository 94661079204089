import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogContent, Slide, Typography, Grid,
} from '@material-ui/core';
import * as Validator from "util/Validation";
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import ChildWorkflowProgress from "./ChildWorkflowProgress";

import { hideUserWiseWorkflowProgressDialog, showWrapperWorkflowProgressDialog, fetchWorkflowUserActivity, fetchEvaluationworkflowId } from "redux/evaluation/workflow/action";
import { workflowComponents } from "redux/master/action";
import { fetchWorkflowProgressNew, fetchWorkflowProgressStatus } from "redux/workflow/action";
import CustomImage from 'Components/Application/Components/Settings/WorkflowBuilder/WorkFlowV2Components/CustomImage';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import classnames from "classnames";
// import AnsweredIcon from 'assets/images/evaluation/reqAnswered.svg';
// import Image from 'Components/Common/image.jsx';
import { Avatar } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';

import {
  getOrgWorkflowComponent,
  fetchOrgWorkflowAction,
} from "redux/evaluation/workflow/action";

import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
} from 'react-flow-renderer';
import dagre from 'dagre';
import { getNewActionsList } from "util/Common";
import { CommonFn } from "services/commonFn";

const connectedProps = (state) => ({
  isOpen: state.evaluationWorkflow.isUserWiseWorflowProgressDialogOpen,
  fetchWorkflowUserActivityProgress: state.evaluationWorkflow.fetchWorkflowUserActivityProgress,
  workflowUserActivity: state.evaluationWorkflow.workflowUserActivity,
  fetchEvaluationWorkflowIdProgress: state.evaluationWorkflow.fetchEvaluationWorkflowIdProgress,
  evaluationWorkflowDetails: state.evaluationWorkflow.evaluationWorkflowDetails,
  user: state.authUser.user,
  isWrapperOpen: state.evaluationWorkflow.isWrapperWorflowProgressDialogOpen,
  userTimeline: state.workflow.userTimeline,
  fetchWorkflowComponentProgress: state.master.fetchWorkflowComponentProgress,
  workflowComponent: state.master.workflowComponent,
  orgWorkFlowCompProgress: state.evaluationWorkflow.orgWorkFlowCompProgress,
  orgWorkFlowCompList: state.evaluationWorkflow.orgWorkFlowCompList,
  orgWorkFlowCompError: state.evaluationWorkflow.orgWorkFlowCompError,
  orgWorkFlowActionProgress: state.evaluationWorkflow.orgWorkFlowActionProgress,
  orgWorkFlowActionList: state.evaluationWorkflow.orgWorkFlowActionList,
  orgWorkFlowActionError: state.evaluationWorkflow.orgWorkFlowActionError,
  fetchWorkflowProgress: state.workflow.fetchWorkflowProgress,
  fetchWorkflowError: state.workflow.fetchWorkflowError,
  workflowProgressData: state.workflow.workflowProgressData,
  fetchWorkflowStatusProgress: state.workflow.fetchWorkflowStatusProgress,
  fetchWorkflowStatusError: state.workflow.fetchWorkflowStatusError,
  workflowProgressStatusData: state.workflow.workflowProgressStatusData,
  evaluation: state.evaluationMetaData.evaluation,
});

const connectionActions = {
  hideDialog: hideUserWiseWorkflowProgressDialog,
  showWrapperWorkflowProgressDialog: showWrapperWorkflowProgressDialog,
  fetchWorkflowUserActivity: fetchWorkflowUserActivity,
  fetchEvaluationworkflowId: fetchEvaluationworkflowId,
  workflowComponents: workflowComponents,
  getOrgWorkflowComponent: getOrgWorkflowComponent,
  fetchOrgWorkflowAction: fetchOrgWorkflowAction,
  fetchWorkflowProgressNew: fetchWorkflowProgressNew,
  fetchWorkflowProgressStatus: fetchWorkflowProgressStatus
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  title: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
    margin: '5px 0px'
  },
  dialogContent: {
    margin: 'auto',
    marginBottom: 10,
    "& [class*=MuiAvatar-root]": {
      width: 20,
      height: 20,
      fontSize: 14,
    },
    "& [class*=MuiAvatarGroup-avatar]": {
      margin: 0,
    },
    '& [class*="react-flow__handle"]': {
      width: 1,
      height: 1
    },
    '& [class*="react-flow__edge-path"]': {
      stroke: '#222 !important',
    },
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  flowSection: {
    height: 'calc(87vh - 100px)',
    width: 'calc(100vw - 100px)',
    maxHeight: "calc( 87vh - 200px)",
    "& .draggable": {
      display: 'none',
    },
    "& .decision-rectangle": {
      width: 66,
      height: 66,
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: 11,
      left: 58,
      border: '1px solid #EEEEEE',
      background: '#fff',
      zIndex: -1
    },
    "& .decision-component": {
      position: 'relative',
      zIndex: 1,
      top: 40
    }
  },
  "@global": {
    '.applicationContent': {
      background: '#fff !important',
      padding: "0 !important"
    },
    ".react-flow__node": {
      padding: '0px !important',
      boxShadow: 'none'
    },
    ".react-flow__node-default, .react-flow__node-input, .react-flow__node-output": {
      padding: '5px 10px',
      fontSize: '7.5px',
      border: 'none'
    },
    '.react-flow__edge-path': {
      strokeWidth: 1.5
    },
  },
  // formBuilderMain: {
  //   display: 'flex',
  //   paddingTop: 100,
  //   paddingBottom: 100,
  //   width: 'max-content'
  // },
  wrk_item: {
    width: 250,
    background: '#fff',
    border: '1px solid #969f9b',
    display: 'flex',
    alignItems: 'center',
    padding: 20,
    marginLeft: 50,
    borderRadius: 4
  },
  leftBorder: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      left: '-50px',
      top: '50%',
      height: 1,
      display: 'block',
      borderRight: '50px solid #969f9b',
      content: "''",
    }
  },
  activeComponent: {
    // border: '1px solid green',
    boxShadow: '-7px 9px 13px -2px rgb(113 125 119 / 71%)'
  },
  com_name: {
    fontSize: 15,
    paddingLeft: 10,
    flex: 0.8
  },
  completedImg: {
    width: 30,
  },
  imgSection: {
    flex: 0.2,
    display: 'flex',
    alignItems: 'center',
  },
  formBuilderMain: {
    width: '100%',
    height: '100%'
  },
  nodeContainer: {
    border: '1px solid #fff',
    borderRadius: 4,
    background: '#fff',
    padding: '10px 10px',
    // height:100,
    textAlign: 'left',
    display: 'flex',
    height: 75
  },
  nodeContainer1: {
    border: '1px solid #fff',
    borderRadius: 4,
    background: '#fff',
    padding: '10px 10px',
    // height:100,
    textAlign: 'left',
    height: 75
  },
  comTitle: {
    fontWeight: 600,
    fontSize: 10
  },
  datePrint:{
    fontSize: 7
  },
  topSection: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  sec01: {
  },
  sec02: {
    marginLeft: 10
  },
  users: {
    marginTop: 10,
    display: 'flex',
    gap: '5px'
  },
  threeDot: {
    padding: 5
  },
  movert: {
    width: 15,
    height: 15,
    color: '#1f73b7'
  },
  userName: {
    fontSize: 7
  },
  arrow: {
    position: 'absolute',
    top: 34.4,
    bottom: 0,
    left: -8,
    fontSize: 7,
    color: '#222'
  },
  arrow1: {
    position: 'absolute',
    top: 34.4,
    bottom: 0,
    left: -8,
    fontSize: 7,
    color: '#c3c3c3'
  },
  watchIcon: {
    fontSize: "1.7rem",
    backgroundColor: "#fbbc05",
    color: 'white',
    padding: '5px',
    borderRadius: '20px'
  },
  watchIconRed: {
    fontSize: "1.7rem",
    backgroundColor: "red",
    color: 'white',
    padding: '5px',
    borderRadius: '20px'
  },
  checkIcon: {
    fontSize: "1.7rem",
    backgroundColor: "#2eb77d",
    color: 'white',
    padding: '5px',
    borderRadius: '20px'
  }
});

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 180;
const nodeHeight = 150;

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  if (nodes !== undefined && nodes !== null) {
    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });
  }

  if (edges !== undefined && edges !== null) {
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
  }

  dagre.layout(dagreGraph);

  if (nodes !== undefined && nodes !== null) {
    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? 'left' : 'top';
      node.sourcePosition = isHorizontal ? 'right' : 'bottom';

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });
  }

  return { nodes, edges };
};


class UserWiseProgress extends React.Component {
  constructor(props) {
    super(props);
    this.reactFlowWrapper = React.createRef(null);
    this.state = {
      reactFlowInstance: null,
      elements: [],
      isActiveWorkflow: true,
    }
    this.avatarName = this.avatarName.bind(this);

  }

  componentDidMount() {
    // this.props.workflowComponents();
    // this.init();
    // this.props.fetchEvaluationworkflowId(this.props.match.params.workflow_id);
    console.log(this.props.evaluationWorkflowDetails, 'evaluationWorkflowDetails')
    this.props.fetchWorkflowProgressStatus(this.props.evaluation?.ID);
    this.props.getOrgWorkflowComponent(this.props.evaluation?.WorkflowID);
    this.props.fetchOrgWorkflowAction(this.props.evaluation?.WorkflowID);
    this.props.fetchWorkflowProgressNew(this.props.evaluation?.ID);
    // this.props.fetchWorkflowUserActivity(this.props.match.params.evaluation_id, this.props.match.params.workflow_id);
  }

  init() {
    const { userTimeline } = this.props;
    console.log(userTimeline, 'UserTimelineee')
    let elements = [];
    elements.push({
      ID: 1,
      Label: 'Start',
      Status: 1
    })
    userTimeline.forEach(function (item) {
      // if (item.Type === 1) {
      elements.push({
        ID: item.ComponentId,
        Label: item.Label,
        Status: item.Status
      })
      // }
    })
    this.setState({
      elements: elements
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.orgWorkFlowCompProgress === false && prevProps.orgWorkFlowCompProgress === true) {
      console.log(this.props.orgWorkFlowCompList, 'orgWorkFlowCompList')
      this.setElementToFlow()
    }

    if (this.props.orgWorkFlowActionProgress === false && prevProps.orgWorkFlowActionProgress === true) {
      console.log(this.props.orgWorkFlowActionList, 'orgWorkFlowActionList')
      this.setElementToFlow()
    }

    if (this.props.fetchWorkflowUserActivityProgress === false && prevProps.fetchWorkflowUserActivityProgress === true) {
      console.log(this.props.workflowUserActivity, 'workflowUserActivity')
    }

    if (this.props.fetchWorkflowProgress === false && prevProps.fetchWorkflowProgress === true) {
      if (this.props.fetchWorkflowError === null) {
        console.log(this.props.workflowProgressData, 'workflowProgressData')
        this.setElementToFlow()
      }
    }

    if (this.props.fetchWorkflowStatusProgress === false && prevProps.fetchWorkflowStatusProgress === true) {
      if (this.props.fetchWorkflowStatusError === null) {
        console.log(this.props.workflowProgressStatusData, 'workflowProgressStatusData')
        this.setElementToFlow()
      }
    }

  }

  getMasterObjectFromId = (id) => {
    return this.props.workflowComponent && this.props.workflowComponent.find((item) => item?.ID === parseInt(id));
  }

  setElementToFlow() {
    const { orgWorkFlowCompList, orgWorkFlowActionList, workflowProgressData, workflowProgressStatusData, classes } = this.props;
    console.log({ orgWorkFlowCompList, orgWorkFlowActionList, workflowProgressData, workflowProgressStatusData }, 'statusData');
    if (workflowProgressData === undefined || workflowProgressData === null || workflowProgressData === '' || orgWorkFlowCompList === undefined || orgWorkFlowCompList === null || orgWorkFlowCompList === '' || orgWorkFlowActionList === undefined || orgWorkFlowActionList === null || orgWorkFlowActionList === '' || !workflowProgressStatusData) {
      return;
    }

    var currentActiveComponent = 0;

    let workflowComponentMap = {};
    orgWorkFlowCompList.forEach(function (component) {
      workflowComponentMap[component.ID] = component;
    });

    let workflowComponentStatusMap = {};
    workflowProgressStatusData.forEach(function (component) {
      workflowComponentStatusMap[component.ComponentID] = component;
    });

    let elementList = [];
    let totalAvailable = 0;
    let summaryComponentIds = [];
    let me = this;
    orgWorkFlowCompList.forEach((item, index) => {
      let flowData = {};
      if (Validator.isJson(item.FlowData)) {
        flowData = JSON.parse(item.FlowData);
      }
      let label = item.Name;
      let slug = item?.MasterComponent?.Slug;
      if (slug === 'SUMMARY') {
        summaryComponentIds.push(item.ID)
      }
      if (slug === 'START' || slug === 'FINISH' || slug === 'SUMMARY') {
        return;
      }
      totalAvailable++;
      // let desc = item?.MasterComponent?.Description;
      const dataObjects = {
        id: item.ID.toString(),
        data: {
          label, componentId: item.ComponentID.toString(),
          flowData: item.FlowData,
          configurations: item.MasterWorkflowComponentConfigurations,
          object: item
        },
        position: flowData?.position ? flowData?.position : { x: 40, y: (index + 1) * 40 },
        style: {
          background: "transparent",
          border: "none",
          borderRadius: "0",
          width: "max-content",
          "&:hove": {
            boxShadow: "none",
          },
        },
        cuurrentActiveUsers: []
      }

      if (currentActiveComponent === item.ID) {
        dataObjects.cuurrentActiveUsers.push(this.props.user);
      }
      let users = [];

      if (workflowProgressData[item.ID] !== undefined) {
        users = workflowProgressData[item.ID];
      }

      let Icon = <CustomImage slug={slug} />;
      if (workflowComponentStatusMap[item.ID]) {
        if (workflowComponentStatusMap[item.ID].Status === 1) {
          if (me.props.evaluation?.Status === 5) {
            Icon = <WatchLaterOutlinedIcon className={classes.watchIconRed} />
          } else {
            Icon = <WatchLaterOutlinedIcon className={classes.watchIcon} />
          }
        } else if (workflowComponentStatusMap[item.ID].Status === 2) {
          Icon = <CheckCircleOutlineOutlinedIcon className={classes.checkIcon} />
        }
      }

      let runInfo = '';
      let isCompleted = false;
      if (workflowComponentStatusMap[item.ID]) {
        if (workflowComponentStatusMap[item.ID].StartDate && workflowComponentStatusMap[item.ID].EndDate) {
          let start = workflowComponentStatusMap[item.ID].StartDate;
          let end = workflowComponentStatusMap[item.ID].EndDate;
          isCompleted = true
          runInfo = <div style={{marginTop:10}}>
            <Typography className={classes.datePrint}><b>Start:</b> {CommonFn.formateDate(start, true)} at {CommonFn.formatTime(start, true)}</Typography>
            <Typography className={classes.datePrint}><b>End:</b> {CommonFn.formateDate(end, true)} at {CommonFn.formatTime(end, true)}</Typography>
          </div>
        }
      }

      dataObjects.data.label = (
        <>
          <div className={users.length > 0 ? classes.nodeContainer1 : classes.nodeContainer1}>
            {(totalAvailable !== 1 && slug !== 'START') && <ArrowForwardIosIcon className={users.length > 0 ? classes.arrow1 : classes.arrow} />}
            <div className={classes.topSection}>
              <div className={classes.sec01}>
                {/* <CustomImage slug={slug} /> */}
                {Icon}
              </div>
              <div className={classes.sec02}>
                <Typography className={classes.comTitle}>{item.Name}</Typography>
              </div>
            </div>
            {users.length > 0 && <div className={classes.users}>
              {users.length > 0 && users.map((user, k) => {
                return <Tooltip key={user.ID} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{user?.Name + '\n' + user?.Email}</span>} arrow>
                  <Avatar key={k}> {this.avatarName(user.Name)} </Avatar>
                </Tooltip>
              })}
            </div>}
            {!isCompleted && slug !== 'START' && users.length <= 0 && <div className={classes.users}>
              No User
            </div>}
            {runInfo}
          </div>
        </>
      )
      elementList.push(dataObjects)
    });

    // console.log(summaryComponentIds,'summaryComponentIds')
    // console.log(orgWorkFlowActionList,'orgWorkFlowActionList')
    getNewActionsList(orgWorkFlowActionList, summaryComponentIds);
    // console.log(newEdges,'FinalDatasssss')

    const componentEdges = orgWorkFlowActionList ? orgWorkFlowActionList.map((item, index) => {
      let animated = false;
      return {
        id: item.ID.toString(),
        source: item.SourceComponentID.toString(),
        target: item.TargetComponentID.toString(),
        type: "smoothstep",
        label: item?.Name,
        style: { stroke: '#d8d8d8', strokeWidth: '1px' },
        animated: animated
      }
    }) : [];

    if (elementList !== undefined && elementList !== null && componentEdges !== undefined && componentEdges !== null && elementList.length > 0 && componentEdges.length > 0) {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(elementList, componentEdges, 'LR');
      this.setState({
        elements: [...layoutedNodes, ...layoutedEdges],
        workflowNodes: elementList,
        workflowEdges: componentEdges,
      })
    } else {
      this.setState({
        elements: [...elementList, ...componentEdges],
        workflowNodes: elementList,
        workflowEdges: componentEdges,
      })
    }
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView()
    this.setState({
      reactFlowInstance: reactFlowInstance
    }, () => {
      const element = document.getElementsByClassName("react-flow__controls-fitview")
      console.log(element, 'element5656565')
      if (element && element.length > 0) {
        setTimeout(() => {
          element[element.length - 1].click()
        }, 1000)
      }
    })
  }

  onElementClick = (event, element) => {
    const { workflowUserActivity } = this.props;
    const userActivities = workflowUserActivity.user_activity;
    let userActivityMap = {};
    userActivities.forEach(function (item) {
      userActivityMap[item.component_id] = item.status;
    });

    const masterObject = this.getMasterObjectFromId(element?.data?.object?.ComponentID);
    var componentId = parseInt(element.id);
    if (masterObject?.Slug === 'WORKFLOW') {
      if (userActivityMap[componentId] === undefined) {
        return;
      } else {
        this.setState({
          isActiveWorkflow: false
        }, () => {
          this.props.showWrapperWorkflowProgressDialog(componentId)
        })
      }
    }
  }

  render() {
    const classes = this.props.classes;

    const { elements } = this.state;
    return <Dialog
      onClose={this.props.hideDialog}
      aria-labelledby="app-integrationDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"xl"}
      scroll="body"
      id="evMetaDialog"
    >
      <DialogContent classes={{ root: classes.dialogContent }} >
        <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
        <Typography variant={"h4"} className={classes.title}>Project Progress</Typography>
        <div className={classes.formBuilderMain}>
          <Grid container>
            <Grid item lg={12}>
              <div className={classes.flowSection} id="dropSection">
                <ReactFlowProvider>
                  <div style={{ width: '100%', height: '100%' }} ref={this.reactFlowWrapper}>
                    <ReactFlow
                      elements={elements}
                      onLoad={this.onLoad}
                      snapToGrid={true}
                      snapGrid={[15, 15]}
                      style={{ background: '#F8F7F7' }}
                      key="edge-with-button"
                      defaultZoom={1.5}
                    >
                      <Controls
                        onInteractiveChange={e => console.log(e)}
                      />
                      <Background color="#000" gap={30} />
                    </ReactFlow>
                  </div>
                </ReactFlowProvider>
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      {this.props.isWrapperOpen && <ChildWorkflowProgress />}
    </Dialog>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(UserWiseProgress));