
/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment";

export const START_SETTINGS_USERS_FETCH = "START_SETTINGS_USERS_FETCH";
export const END_SETTINGS_USERS_FETCH = "END_SETTINGS_USERS_FETCH";
export const START_SETTINGS_USER_UPDATE = "START_SETTINGS_USER_UPDATE";
export const END_SETTINGS_USER_UPDATE = "END_SETTINGS_USER_UPDATE";
export const REMOVE_ADD_TEAM_DIALOG_ERROR = "REMOVE_ADD_TEAM_DIALOG_ERROR";
export const SHOW_VIA_INTEGRATION_DIALOG = "SHOW_VIA_INTEGRATION_DIALOG";
export const HIDE_VIA_INTEGRATION_DIALOG = "HIDE_VIA_INTEGRATION_DIALOG";
export const SHOW_USER_UPLOAD_DIALOG = "SHOW_USER_UPLOAD_DIALOG";
export const HIDE_USER_UPLOAD_DIALOG = "HIDE_USER_UPLOAD_DIALOG";
export const START_SETTINGS_USER_DELETE = "START_SETTINGS_USER_DELETE";
export const END_SETTINGS_USER_DELETE = "END_SETTINGS_USER_DELETE";
export const START_UPLOAD_USER_FILE = "START_UPLOAD_USER_FILE";
export const END_UPLOAD_USER_FILE = "END_UPLOAD_USER_FILE";

export const SHOW_USER_LOGGIN_ACTIVITY_DIALOG = "SHOW_USER_LOGGIN_ACTIVITY_DIALOG";
export const HIDE_USER_LOGGIN_ACTIVITY_DIALOG = "HIDE_USER_LOGGIN_ACTIVITY_DIALOG";

export const START_DOWNLOAD_LOGGING_ACTIVITY = "START_DOWNLOAD_LOGGING_ACTIVITY";
export const END_DOWNLOAD_LOGGING_ACTIVITY = "END_DOWNLOAD_LOGGING_ACTIVITY";

export const START_USER_CONFIG_FETCH = "START_USER_CONFIG_FETCH";
export const END_USER_CONFIG_FETCH = "END_USER_CONFIG_FETCH";

export const START_USER_CONFIG_ADD = "START_USER_CONFIG_ADD";
export const END_USER_CONFIG_ADD = "END_USER_CONFIG_ADD";

export const START_USER_CONFIG_UPDATE = "START_USER_CONFIG_UPDATE";
export const END_USER_CONFIG_UPDATE = "END_USER_CONFIG_UPDATE";

//Action to start the registration

function startUserConfigFetch() {
    return {
        type: START_USER_CONFIG_FETCH,
        payload: {}
    };
}

function endUserConfigFetch(success, error) {
    return {
        type: END_USER_CONFIG_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchUserConfig() {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/configs?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startUserConfigFetch());

        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endUserConfigFetch(response, error));
        } catch (e) {
            dispatch(endUserConfigFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUserConfigAdd() {
    return {
        type: START_USER_CONFIG_ADD,
        payload: null
    };
}

function endUserConfigAdd(success, error) {
    return {
        type: END_USER_CONFIG_ADD,
        payload: {
            success, error
        }
    };
}

export function addUserConfig(data) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/config?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUserConfigAdd());

        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endUserConfigAdd(response, error));
        } catch (e) {
            dispatch(endUserConfigAdd(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUserConfigUpdate() {
    return {
        type: START_USER_CONFIG_UPDATE,
        payload: null
    };
}

function endUserConfigUpdate(success, error) {
    return {
        type: END_USER_CONFIG_UPDATE,
        payload: {
            success, error
        }
    };
}

export function updateUserConfig(id,data) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/config/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUserConfigUpdate());

        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUserConfigUpdate(response, error));
        } catch (e) {
            dispatch(endUserConfigUpdate(null, {
                message: e.message
            }));
            return;
        }
    };
}



function startSettingsUsersFetch(page) {
    return {
        type: START_SETTINGS_USERS_FETCH,
        payload: page
    };
}

function endSettingsUsersFetch(success, error) {
    return {
        type: END_SETTINGS_USERS_FETCH,
        payload: {
            success, error
        }
    };
}

function startSettingsUserUpdate() {
    return {
        type: START_SETTINGS_USER_UPDATE,
        payload: null
    };
}

function endSettingsUserUpdate(success, error) {
    return {
        type: END_SETTINGS_USER_UPDATE,
        payload: {
            success, error
        }
    };
}

export function showUserUploadDialog() {
    return {
        type: SHOW_USER_UPLOAD_DIALOG,
        payload: null
    };
}

export function hideUserUploadDialog() {
    return {
        type: HIDE_USER_UPLOAD_DIALOG,
        payload: null
    };
}

export function showUserLogginActivityDialog() {
    return {
        type: SHOW_USER_LOGGIN_ACTIVITY_DIALOG,
        payload: null
    };
}

export function hideUserLogginActivityDialog() {
    return {
        type: HIDE_USER_LOGGIN_ACTIVITY_DIALOG,
        payload: null
    };
}

export function showViaIntegrationDialog() {
    return {
        type: SHOW_VIA_INTEGRATION_DIALOG,
        payload: null
    };
}

export function hideViaIntegrationDialog() {
    return {
        type: HIDE_VIA_INTEGRATION_DIALOG,
        payload: null
    };
}

function startSettingsUserDelete() {
    return {
        type: START_SETTINGS_USER_DELETE,
        payload: null
    };
}

function endSettingsUserDelete(success, error) {
    return {
        type: END_SETTINGS_USER_DELETE,
        payload: {
            success, error
        }
    };
}


function startUploadUserFile() {
    return {
        type: START_UPLOAD_USER_FILE,
        payload: null
    };
}

function endUploadUserFile(success, error) {
    return {
        type: END_UPLOAD_USER_FILE,
        payload: {
            success, error
        }
    };
}

export function fetchUsers(page, pageSize, query, roles = [], all = false) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/fetch?t=${timestamp}`;

    var payload = {
        query: query,
        page: page !== undefined ? page : 0,
        pageSize: pageSize !== undefined ? pageSize : 10,
        all: all,
    }

    if (roles.length) {
        payload['roles'] = roles;
    }

    return async (dispatch) => {
        dispatch(startSettingsUsersFetch(page));

        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, payload);
            dispatch(endSettingsUsersFetch(response, error));
        } catch (e) {
            dispatch(endSettingsUsersFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function searchUsers(page, pageSize, query) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/search?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`;
    return async (dispatch) => {
        dispatch(startSettingsUsersFetch(page));

        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endSettingsUsersFetch(response, error));
        } catch (e) {
            dispatch(endSettingsUsersFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}


export function updateUser(user, id) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startSettingsUserUpdate());

        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, user);
            dispatch(endSettingsUserUpdate(response, error));
        } catch (e) {
            dispatch(endSettingsUserUpdate(null, {
                message: e.message
            }));
            return;
        }
    };
}


export function deleteUser(id) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startSettingsUserDelete());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endSettingsUserDelete(response, error));
        } catch (e) {
            dispatch(endSettingsUserDelete(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function uploadFile(formData) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/user/bulk-upload?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUploadUserFile());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, formData);
            dispatch(endUploadUserFile(response, error));
        } catch (e) {
            dispatch(endUploadUserFile(null, {
                message: e.message
            }));
            return;
        }
    };
}



function startDownloadLogginActivity() {
    return {
        type: START_DOWNLOAD_LOGGING_ACTIVITY,
        payload: null
    };
}

function endDownloadLogginActivity(success, error) {
    return {
        type: END_DOWNLOAD_LOGGING_ACTIVITY,
        payload: {
            success, error
        }
    };
}

export function downloadLoggingActivity(params) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/login/activity?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDownloadLogginActivity());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url, params);
            dispatch(endDownloadLogginActivity(response, error));
        } catch (e) {
            dispatch(endDownloadLogginActivity(null, {
                message: e.message
            }));
            return;
        }
    };
} 