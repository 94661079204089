import {
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import SqureButton from 'Components/Common/SqureButton';
import { hideAddAutomationDialog } from 'redux/automations/action';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import { addNewAutomation, fetchMasterTriggers } from 'redux/automations/action';
import {getIconForAutomation} from "../Common/customIcons"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.automation.dialogAddAutomationOpen,
    addNewAutomationProgress: state.automation.addNewAutomationProgress,
    addNewAutomationError: state.automation.addNewAutomationError,
    newAutomationData: state.automation.newAutomationData,

    fetchMasterTriggerProgress: state.automation.fetchMasterTriggerProgress,
    fetchMasterTriggerError: state.automation.fetchMasterTriggerError,
    masterTriggers: state.automation.masterTriggers,
});

const connectionActions = {
    hideDialog: hideAddAutomationDialog,
    addNewAutomation: addNewAutomation,
    fetchMasterTriggers: fetchMasterTriggers
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: '0px !important'
    },
    close: {
        position: 'absolute',
        right: 30,
        top: 10,
        cursor: 'pointer',
        color: '#fff'
    },
    actions: {
        marginTop: 30,
    },
    categories: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        flexWrap: 'wrap'
    },
    category: {
        width: 110,
        border: '1px solid #c2c2c2',
        borderRadius: 4,
        padding: 20,
        height: 110,
        marginRight: 20,
        marginTop: 20
    },
    catName: {
        fontSize: 14
    },
    formControl: {
        width: '100%'
    },
    alerts: {
        marginBottom: 10
    },
    header: {
        padding: 10,
        paddingLeft: 30,
        background: '#20253A',
        color: '#fff'
    },
    headTitle: {
        fontWeight: 500,
        color: '#fff',
        fontSize: 15,
    },
    body: {
        padding: 30
    },
    title: {
        fontWeight: 600,
        color: '#282D30',
        fontSize: 25,
        borderBottom: '1px solid #c3bfbf',
        paddingBottom: 30
    },
    automationItems: {
        marginTop: 30
    },
    listOptions: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 15
    },
    triggerOption: {
        marginRight: 15,
        textAlign: 'center',
        height: 100,
        position: 'relative'
    },
    triggerIcon: {
        textAlign: 'center',
        width: 50,
        height: 50,
        margin: '0px auto',
        background: '#1f75d7cc',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        borderRadius: 4
    },
    triggerName: {
        lineHeight: '20px',
        marginTop: 8,
        textAlign: 'center',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        width: 140
    },
    check: {
        background: '#5aa028',
        border: '2px solid #f3f5f6',
        borderRadius: '50%',
        color: '#fff',
        left: '30px',
        position: 'absolute',
        top: '-10px',
    }
});

class ExpentDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            automationName: '',
            automationDesc: '',
            startPointer: 'Scheduled',
            triggerOptions: [{
                name: 'Run on a schedule',
                type: 'Scheduled',
            }, {
                name: 'Trigger from an event',
                type: 'EventBased',
            }, 
            // {
            //     name: 'Build event based function',
            //     type: 'EventFunction',
            // }, {
            //     name: 'Build function',
            //     type: 'Function',
            // }
        ],
            // Scheduled EventBased EventFunction Function
            triggerIdMap: {}
        }
    }

    componentDidMount() {
        // this.props.fetchMasterTriggers()
    }

    componentDidUpdate(prevProps) {
        // if(!this.props.addNewAutomationProgress && prevProps.addNewAutomationProgress){
        //     if(this.props.addNewAutomationError === null){
        //         this.props.showSnackbar('Automation added successfully' , 'success' , 3000)
        //         this.props.hideDialog()
        //     }else{
        //         this.props.showSnackbar('Something went wrong' , 'error' , 3000)
        //     }
        // }

        if (!this.props.fetchMasterTriggerProgress && prevProps.fetchMasterTriggerProgress) {
            if (this.props.fetchMasterTriggerError === null) {
                this.initTriggerOptions()
            }
        }
    }

    initTriggerOptions() {
        const { masterTriggers } = this.props;
        if (masterTriggers) {
            let options = [];
            let map = {}
            masterTriggers.forEach(function (trigger) {
                options.push({
                    label: trigger?.name,
                    value: trigger?.id
                })
                map[trigger.id] = trigger;
            })
            this.setState({
                triggerOptions: options,
                triggerIdMap: map
            })
        }

    }


    clearError() {
        this.setState({
            error: '',
            success: ''
        })
    }
    addAutomation() {
        const { automationName, automationDesc, startPointer } = this.state;
        this.clearError();

        if (automationName === '') {
            this.setState({
                error: 'Please enter name'
            })
            return
        }

        let params = {
            "name": automationName,
            "description": automationDesc,
            "type": startPointer,
        }
        this.props.addNewAutomation(params);
    }

    render() {
        const classes = this.props.classes;
        const { triggerOptions } = this.state;

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-automationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            fullScreen
            maxWidth={"md"}
            scroll="body"
            id="automationDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.header}>
                    <Typography variant={"h4"} className={classes.headTitle}>My new automation</Typography>
                    <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                </div>
                <div className={classes.body}>
                    <Typography variant={"h4"} className={classes.title}>Set up your automation</Typography>
                    <div className={classes.automationItems}>
                        <div className={classes.alerts}>
                            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                            {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                        </div>
                        <div className={classes.takeDetails}>
                            <Typography>Name</Typography>
                            <OutlinedInput
                                id={"formTitleInput"}
                                placeholder={"Enter your automation name"}
                                autoFocus={true}
                                value={this.state.automationName}
                                onChange={(event) => {
                                    this.setState({
                                        automationName: event.target.value
                                    }, () => {
                                        this.clearError();
                                    })
                                }}
                            />
                        </div>
                        <div className={classes.takeDetails}>
                            <Typography>Description</Typography>
                            <OutlinedInput
                                id={"formTitleInput"}
                                placeholder={"Enter your automation description"}
                                autoFocus={false}
                                value={this.state.automationDesc}
                                onChange={(event) => {
                                    this.setState({
                                        automationDesc: event.target.value
                                    }, () => {
                                        this.clearError();
                                    })
                                }}
                            />
                        </div>
                        <div className={classes.options}>
                            <Typography>Pick a Starting Point</Typography>
                            <div className={classes.listOptions}>
                                {triggerOptions.map((tri) => {
                                    return <div className={classes.triggerOption} onClick={() => {
                                        this.setState({
                                            startPointer: tri.type
                                        })
                                    }}>
                                        {tri.type === this.state.startPointer && <CheckIcon className={classes.check} />}

                                        <div className={classes.triggerIcon}>
                                            {getIconForAutomation(tri.type)}
                                        </div>
                                        <Typography className={classes.triggerName}>{tri.name}</Typography>
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className={classes.actions}>
                            <SqureButton
                                cls={classes.actionBtn}
                                variant={"contained"}
                                onClick={(e) => {
                                    this.addAutomation()
                                }}
                            >
                                Start Building
                            </SqureButton>
                        </div>
                    </div>
                </div>

            </DialogContent>
        </Dialog>
    }
}

ExpentDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ExpentDialog));