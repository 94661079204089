import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Criteria from "./Criteria";

import { fetchCriteria, editCriteria } from "../../../../../../../../redux/evaluation/criteria/action"
import { CommonFn } from "services/commonFn";

const connectedProps = (state) => ({
    fetchProgress: state.evaludationCriteria.fetchProgress,
    // criterias: state.evaludationCriteria.criterias,
    ruleSaveProgress: state.evaluationRule.ruleSaveProgress,
    evaluation: state.evaluationMetaData.evaluation,
    userReponses: state.evaludationCriteria.userReponses,
    user: state.authUser.user,
    editCriteriaProgress: state.evaludationCriteria.editCriteriaProgress,
    report: state.evaluationReportData.report,
});

const connectionActions = {
    fetchCriteria: fetchCriteria,
    editCriteria: editCriteria,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class CriteriaRequirement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            dragStart: false,
        }
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        var componentId = this.props.match.params.component_id !== undefined ? this.props.match.params.component_id : 0;
        
        this.props.fetchCriteria(this.props.evaluationId,true,componentId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.ruleSaveProgress === false && prevProps.ruleSaveProgress === true) {
            if (this.props.errorRuleSave == null) {
        var componentId = this.props.match.params.component_id !== undefined ? this.props.match.params.component_id : 0;

                this.props.fetchCriteria(this.props.evaluationId,true,componentId);
            }
        }

        if (!this.props.fetchProgress && prevProps.fetchProgress) {
            setTimeout(() => {
                this.setState({
                    loader: false,
                    dragStart: false,
                })
            }, 500);
        }

        if (this.props.editCriteriaProgress && !prevProps.editCriteriaProgress && this.state.dragStart) {
            this.setState({
                loader: true,
            })
        }
        if (!this.props.editCriteriaProgress && this.state.dragStart) {
            this.setState({
                loader: false,
                dragStart: false,
            })
        }
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const { destination, draggableId, source } = result;
        if (draggableId && destination.index !== source.index) {
            this.props.editCriteria(
                this.props.match.params?.evaluation_id,
                parseInt(draggableId),
                {
                    "seq": destination.index
                },this.props.match?.params?.component_id
            )
        }
    }


    render() {
        // if (this.state.loader) {
        //     return <CircularProgress />
        // }
        const { report } = this.props;
        const allCriterias = report?.Criterias;

        var ruleFailRequirementIds = [];
        var ruleFailCriteriaIds = [];
        const rulePassRequirementIds = [];
        const rulePassCriteriaIds = [];

        if (this.props.isRespond) {
            allCriterias && this.props.userReponses && allCriterias.forEach((criteria) => {
                if (criteria?.rules?.length) {
                    criteria.rules.forEach((rule) => {
                        if (rule?.ActionType === 2) {
                            let pushToRequirementArray = CommonFn.findAnswer(this.props.userReponses, rule, criteria.requirements);
                            if (pushToRequirementArray && ruleFailRequirementIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                ruleFailRequirementIds.push(parseInt(rule?.ActionData));
                            } else if (!pushToRequirementArray && rulePassRequirementIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                rulePassRequirementIds.push(parseInt(rule?.ActionData))
                            }
                        }
                        if (rule?.ActionType === 1) {
                            let pushToCrtieriaArray = CommonFn.findAnswer(this.props.userReponses, rule, criteria.requirements);
                            if (pushToCrtieriaArray && ruleFailCriteriaIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                ruleFailCriteriaIds.push(parseInt(rule?.ActionData));
                            } else if (!pushToCrtieriaArray && rulePassCriteriaIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                rulePassCriteriaIds.push(parseInt(rule?.ActionData))
                            }
                        }
                    });
                }
            });
        }

        ruleFailRequirementIds = ruleFailRequirementIds.filter((item) => !(rulePassRequirementIds.indexOf(item) > -1));
        ruleFailCriteriaIds = ruleFailCriteriaIds.filter((item) => !(rulePassCriteriaIds.indexOf(item) > -1))
        const criterias = allCriterias && allCriterias.map((criteria) => {
            criteria['hide'] = false;
            if (ruleFailCriteriaIds.indexOf(criteria.id.toString()) > -1) {
                criteria['hide'] = true;
            }
            criteria.requirements.map((requirement) => {
                requirement['hide'] = false;
                if (ruleFailRequirementIds.indexOf(requirement.id.toString()) > -1) {
                    requirement['hide'] = true;
                }
            });
            return criteria;
        });
        return (
            <>
                {criterias !== undefined && criterias !== null &&
                    criterias.filter(o => !o.hide).map((criteria, index) => (
                        <div>
                            <Criteria
                                key={"criteria_" + criteria.id}
                                evaluationId={this.props.evaluationId}
                                criteria={{ ...criteria }}
                                isEditable={this.props.isEditable}
                                weighting_mechanism={this.props.weighting_mechanism}
                                isReport={this.props.isReport}
                                isPDfReport={this.props.isPDfReport}
                            />
                        </div>
                    ))
                }


            </>
        )
    }
}

CriteriaRequirement.defaultProps = {
    isEditable: true,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(CriteriaRequirement));