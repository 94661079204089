import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import classnames from "classnames";

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getImageUsingType } from "../../Components/FlowData";

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import VariableTree from "./VariableTree";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const connectedProps = (state) => ({

});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  variableDiv: {
    width: '25%',
    position: 'fixed',
    right: '41%',
    bottom: 20,
    transition: 'width .2s ease,height .2s ease',
    border: '1px solid #ced5db',
    borderTop: '6px solid #133440',
    background: '#f7f7f7'
  },
  minVar: {
    height: 60,
  },
  maxVar: {
    height: '60vh',
  },
  heading: {
    borderBottom: '1px solid #ced5db',
    background: '#fff',
    padding: 10,
    display: 'flex',
    width: '100%'
  },
  title: {
    fontSize: 16,
    fontWeight: 600
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: '#919ba5'
  },
  list: {
    padding: 10,
    height: '50vh',
    overflow: 'auto'
  },
  // listItem: {
  //   background: '#fff',
  //   border: '1px solid #ced5db',
  //   color: '#24434f',
  //   padding: '4px 8px',
  //   marginBottom: 10,
  //   cursor: 'pointer'
  // },
  titleSec: {
    flex: '1'
  },
  actionSec: {
    textAlign: 'right'
  },
  expandIcon: {
    cursor: 'pointer'
  },
  iconType: {
    width: 18,
    height: 18
  },
  accRoot: {
    background: 'transparent',
    boxShadow: 'none'
  },
  accDetailRoot: {
    padding: '0px 5px',
    display: 'block'
  }
});

class Variables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      inputField: '',
      variableList: [
        { label: 'User Id', value: 'user_id', type: 'text' },
        { label: 'User Name', value: 'user_name', type: 'number' },
        { label: 'User Email', value: 'user_email', type: 'date' },
        { label: 'Automation Id', value: 'automation_id', type: 'boolean' },
        { label: 'Job Id', value: 'job_id', type: 'number' },
      ],
      isMinimize: false,
      expanded: ''
    }
  }

  componentDidMount() {
    console.log(this.props.componentVariables, 'componentVariables')
  }

  componentDidUpdate(prevProps) {

  }

  submitResponse() {

  }

  updateActionComponent() {

  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false
    })
  };


  render() {
    const { classes } = this.props;
    const { variableList, isMinimize, expanded } = this.state;

    return (
      <div className={classnames(classes.variableDiv, isMinimize ? classes.minVar : classes.maxVar)}>
        <div className={classes.heading}>
          <div className={classes.titleSec}>
            <Typography className={classes.title} variant={'h2'}>Automation Data</Typography>
            <Typography className={classes.subTitle} variant={'h5'}>To use data from a previous step</Typography>
          </div>
          <div className={classes.actionSec}>
            {isMinimize && <ExpandLessIcon className={classes.expandIcon} onClick={() => {
              this.setState({
                isMinimize: false
              })
            }} />}
            {!isMinimize && <ExpandMoreIcon className={classes.expandIcon} onClick={() => {
              this.setState({
                isMinimize: true
              })
            }} />}
          </div>
        </div>
        {!isMinimize && <div className={classes.list}>
          {this.props.componentVariables && this.props.componentVariables.map((acc) => {
            return <Accordion
              expanded={expanded === acc.componentID}
              onChange={this.handleChange(acc.componentID)}
              classes={{
                root: classes.accRoot
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={acc.componentID + "_panel1bh-content"}
                id={acc.componentID + "_panel1bh-header"}
              >
                <Typography className={classes.accHead}>{acc.componentName}</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{
                root: classes.accDetailRoot
              }}>

                {/* <VariableTree 
                  variables={acc.variables}
                /> */}
                <List >
                  {acc.variables && acc.variables.map((vari) => {
                    return <ListItem className={classes.listItem} onClick={() => {
                      this.props.handleSelectVariable(acc, vari)
                    }}>
                      <div class="data-tree-item">
                        <span class="data-tree-item__icon">
                          {getImageUsingType(vari.type, classes.iconType)}
                        </span>
                        <div class="data-tree-item__pill">
                          <span class="data-tree-pill data-tree-pill_draggable">
                            <span>{vari.label}</span>&nbsp;
                          </span>
                        </div>
                        {/* <span class="data-tree-item__sample">{vari.name}</span> */}
                      </div>
                    </ListItem>
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          })}



          {/* <List >
            {variableList.map((vari) => {
              return <ListItem className={classes.listItem} onClick={() => {
                this.props.handleSelectVariable(vari)
              }}>
                <div class="data-tree-item">
                  <span class="data-tree-item__icon">
                    {getImageUsingType(vari.type,classes.iconType)}
                  </span>
                  <div class="data-tree-item__pill">
                    <span class="data-tree-pill data-tree-pill_draggable">
                      <span>{vari.label}</span>&nbsp;
                    </span>
                  </div>
                  <span class="data-tree-item__sample" title="651544">{vari.value}</span>
                </div>
              </ListItem>
            })}
          </List> */}
        </div>}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Variables));
