import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { debounce } from "underscore";
import classNames from "classnames";
import Select from "react-select";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Popover, List, ListItem, ListItemText, Button, OutlinedInput, InputAdornment, Typography, Grid, Badge } from "@material-ui/core";
import { Search as SearchIcon, ArrowDropDown as ArrowDropDownIcon, Tune as TuneIcon, Clear as ClearIcon } from "@material-ui/icons";
import { isEnabledEvaluate } from "util/Common";
import AllProductsFilter from "./SearchInputFilters/AllProductsFilter";
import OrgVendorsFilter from "./SearchInputFilters/OrgVendorsFilter";
import OrgProductsFilter from "./SearchInputFilters/OrgProductsFilter";
import CommonCss from 'commonCss';
import { updateSearch } from "redux/reports/action";
const styles = (theme) =>
  createStyles({
    container: {
      flex: 1,
      minWidth: 500,
    },
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px",
      "& [class*='MuiInputBase-input']": {
        fontSize: 13,
        paddingLeft: theme.spacing(2),
      },
    },
    mb0: {
      marginBottom: 0,
    },
    inputField: {
      maxHeight: 40,
      minHeight: 40,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      background: "#fff",
    },
    inputFieldRounded: {
      maxHeight: 40,
      minHeight: 40,
      borderRadius: 20,
      background: "#fff",
    },
    inputDiv: {
      flex: 1,
      maxWidth: 400,
    },
    typeButton: {
      background: "#3c3c3c",
      color: "#fff",
      border: "solid 1px #3c3c3c !important",
      boxShadow: "none",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: 40,
      maxHeight: 40,
      width: 145,
      "&:hover": {
        background: "#3c3c3c",
        color: "#fff",
        border: "none",
        boxShadow: "none",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& [class*="MuiButton-endIcon"]': {
        background: "#3c3c3c",
        color: "#fff",
        border: "none",
        boxShadow: "none",
      },
    },
    iconPop: {
      cursor: "pointer",
      fontSize: theme.spacing(3),
      marginRight: "10px",
      "&:hover": {
        color: "#fff !important",
        background: "#707070",
        borderColor: "#707070",
        padding: "3px",
        borderRadius: theme.spacing(20),
        fontSize: theme.spacing(3),
      },
    },
    filterLabel: {
      color: "#282D30",
      fontSize: 18,
    },
    filterBdg: {
      cursor: "pointer",
      height: "100%",
      widht: "inherit",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& div:nth-child(2)": {
        display: "none",
      },
      "&:hover > div:nth-child(2)": {
        display: "block",
      },
      "&:hover > p:nth-child(1)": {
        display: "none",
      },
    },
    stateLabel: {
      cursor: "pointer",
      backgroundColor: "#fff",
      border: "1px #dadce0 solid",
      borderRadius: 8,
      height: 32,
      padding: "0 10px",
      margin: "0px 3px",
      display: "flex",
      alignItems: "center",
    },
    selected: {
      backgroundColor: "#a3dcfc33",
    },
    stateLabelText: {
      color: "#5f6368",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "1px",
    },
    labelInit: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 16,
      height: 16,
      marginRight: 8,
      color: "#fff",
      borderRadius: 8,
      fontSize: 10,
      background: "#4a87f8",
    },
    isPreferred: {
      background: "#2EB77D",
      color: "#fff",
    },
    isExisting: {
      background: "#E6FFF3",
      color: "#2EB77D",
    },
    isDeprecated: {
      background: "#ff958d",
      color: "#fff",
    },
    isBlocked: {
      background: "#f44336",
      color: "#fff",
    },
    isFeatured: {
      background: "#ff9800",
      color: "#fff",
    },
    isInReview: {
      background: "#bdbdbd",
      color: "#fff",
    },
    isLimited: {
      background: "#2e81da",
      color: "#fff",
    },
    isEvaluated: {
      background: "#bdbdbd",
      color: "#fff",
      width: 20,
    },
    selectField: {
      ...CommonCss.selectField,
      "& [class*=MuiAutocomplete-inputRoot]": {
        minHeight: 40,
        padding: "0 8px",
        fontSize: 14,
        borderRadius: 4,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        "&::placeholder": {
          fontStyle: "italic",
        },
      },
      "& [class*=MuiSvgIcon-root-]": {
        opacity: 0,
      },
      "& [class*=MuiAutocomplete-clearIndicator-]": {
        "& [class*=MuiSvgIcon-root-]": {
          opacity: 1,
        },
      },
    },
    optionLabel: {
      fontSize: 14,
      padding: 0,
    },
  });

const connectedProps = (state) => ({
  user: state.authUser.user,
  orgUser: state.orgUser,
  orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,
  orgCatalogDetailTabsProgress: state.vendorCatalog.orgCatalogDetailTabsProgress,
});

const connectionActions = {
  updateSearch: updateSearch,
};

var connector = connect(connectedProps, connectionActions);

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterAnchor: null,
      selectedType: "All",
      typeAnchor: null,
      text: "",
      selectText: "",
      types: [
        {
          name: "All",
          value: "all",
        },
        {
          name: "Products",
          value: "products",
        },
        {
          name: "Features",
          value: "features",
        },
      ],
      stateFilter: [
        { label: "Featured", shortName: "F", value: "featured", classname: props.classes.isFeatured },
        { label: "Limited Use", shortName: "L", value: "limited", classname: props.classes.isLimited },
        { label: "In Review", shortName: "I", value: "in_review", classname: props.classes.isInReview },
        { label: "Blocked", shortName: "B", value: "blocked", classname: props.classes.isBlocked },
        { label: "Evaluated", shortName: "Ev", value: "evaluated", classname: props.classes.isEvaluated },
        { label: "Deprecated", shortName: "D", value: "deprecated", classname: props.classes.isDeprecated },
      ],
      filterCatalogCount: 0,
      hardFilter: {},
    };
  }

  componentDidMount() {
    const types = this.state.types;
    if (this.props.type === "vendor") {
      this.setState({ stateFilter: [...this.state.stateFilter, { label: "Preferred", shortName: "P", value: "preferred", classname: this.props.classes.isPreferred }] });
    }

    if (this.props.page === "org_vendor") {
      this.setState({ hardFilter: this.props.hardFilter });
    }

    if (this.props.page === "app_catalog") {
      types.push({
        name: "Department",
        value: "departments",
      });
    }
    if (this.props.page === "all_products" || this.props.page === "app_catalog") {
      types.push({
        name: "Sub-Category",
        value: "subcategories",
      });
    }
    this.setState({ types });
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchObj.field_setname !== prevProps.searchObj.field_setname) {
      let type = "All";
      const finedtype = this.state.types.filter((obj) => obj.value === this.props.searchObj?.field_setname);
      if (finedtype && finedtype[0]) {
        type = finedtype[0].name;
      }
      this.setState({
        selectedType: type,
      });
    }
    if (this.props.searchObj.text !== prevProps.searchObj.text) {
      this.setState({
        text: this.props.searchObj.text,
      });
    }
    if (this.props.hardFilter?.selectedFilter !== prevProps.hardFilter.selectedFilter) {
      this.setState({ hardFilter: this.props.hardFilter });
    }
  }

  handleOnChange(key, value) {
    const data = this.props.searchObj;

    if (key === "input") {
      data.text = value;
    } else if (key === "type") {
      if (this.props.page === "app_catalog") {
        // data.text = "";
        // this.setState({ selectText: "", text: "" });
      }
      data.type = value;
      data.field_setname = value;
    } else if (key === "clear") {
      data.text = "";
      this.setState({ selectText: "", text: "" });
    }
    this.props.updateSearch(data);
    this.props.onChange(data);
  }

  handleOnClickState(newState) {
    this.props.onChangeState(newState);
  }

  getFilterCount(fltr) {
    let cnt = 0;
    if (fltr?.popularFilters) cnt += 1;
    if (fltr?.feature_ids && fltr?.feature_ids.length > 0) cnt += 1;
    if (fltr?.platform_ids && fltr?.platform_ids.length > 0) cnt += 1;
    if (this.props.page === "app_catalog") {
      if (fltr?.sortby && fltr?.sortby !== "relevance" && fltr?.sortby.length > 0) cnt += 1;
    }
    return cnt;
  }

  handleClearFilter() {
    let filter = {};
    if (this.props.page === "app_catalog") {
      filter = {
        sortby: "relevance",
        order: "",
      };
    }
    this.setState({ hardFilter: {} });
    this.props.onChangeFilter(filter);
  }

  customLabel = (values) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <span style={{ color: "#000", fontSize: "16px" }}>{values.label}</span>&nbsp;
          {values.company_name && <span style={{ color: "#6C6C6C", fontSize: "12px" }}>By {values.company_name}</span>}
        </div>
        {values.isExisting && (
          <div
            style={{
              color: "#2EB77D",
              padding: "3px 10px",
              fontSize: 11,
              background: "#E6FFF3",
              borderRadius: 5,
              marginLeft: 5,
            }}
          >
            Existing
          </div>
        )}
      </div>
    );
  };

  debouncefunc = debounce((value) => this.handleOnChange("input", value), 100);

  render() {
    const { classes, roundedCorner, searchObj, showTypes, page, placeholder, isFilter, searchIcon, filters, showState, loadingOption, isSelectInput, dropdownOptions, searchFilterOptions } =
      this.props;
    const filterCount = this.getFilterCount(filters);

    let role = this.props.user?.Role;
    const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForUsersCheck");
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForAdminsCheck");

    return (
      <div className={classes.container}>
        <div className={classNames(classes.root, roundedCorner ? classes.mb0 : {})} id="outlinedSearchBar">
          {/* {catalogFieldFilters && <div style={{background: 'white'}}>
            <Autocomplete
              className={classes.selectField}
              options={searchFilterOptions}
              size="small"
              getOptionLabel={(option) => option.name}
              defaultValue={this.state.autoSelect}
              value={this.props.catalogFieldValue}
              onChange={(e, value) => {
                this.props.onCatalogFieldFilter(value)
              }}
              renderOption={(option) => (
                <div style={{fontSize: '0.8rem'}}>{option.name}</div>
              )}
              renderInput={(params) => <TextField {...params} variant="outlined" size="small" placeholder="All" style={{minWidth: 150, height:40}} />}
            />
          </div>} */}

          {showTypes && (
            <Button
              className={classes.typeButton}
              variant="contained"
              color="primary"
              fullWidth
              endIcon={<ArrowDropDownIcon style={{ color: "#FFF" }} fontSize={"large"} />}
              onClick={(event) => {
                event.stopPropagation();
                this.setState({
                  typeAnchor: event.currentTarget,
                });
              }}
            >
              {this.state.selectedType}
            </Button>
          )}
          <div className={classes.inputDiv}>
            {!isSelectInput && (
              <OutlinedInput
                fullWidth
                id={"input_" + page}
                placeholder={placeholder}
                className={roundedCorner ? classes.inputFieldRounded : classes.inputField}
                onChange={(e) => {
                  this.setState({ text: e.target.value });
                  this.debouncefunc(e.target.value);
                }}
                style={roundedCorner && this.props.type === "vendor" ? { marginBottom: "1rem" } : {}}
                value={this.state.text}
                inputProps={{
                  autoComplete: "off",
                  autoCorrect: "off",
                  autoCapitalize: "off",
                  spellCheck: "false",
                }}
                endAdornment={
                  <InputAdornment>
                    {this.state.text?.length > 0 && (
                      <ClearIcon
                        className={classes.iconPop}
                        onClick={(e) => {
                          this.handleOnChange("clear", "");
                        }}
                      />
                    )}
                    {isFilter && (
                      <>
                        {this.state.hardFilter.selectedFilter || filterCount > 0 ? (
                          <Badge
                            badgeContent={
                              <div className={classes.filterBdg}>
                                <p>{this.props.page === "vendor_catalog" ? 1 : filterCount}</p>
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleClearFilter();
                                  }}
                                >
                                  <p>X</p>
                                </div>
                              </div>
                            }
                            color="error"
                          >
                            <TuneIcon
                              className={classes.iconPop}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.setState({
                                  filterAnchor: e.currentTarget,
                                });
                              }}
                            />
                          </Badge>
                        ) : (
                          <TuneIcon
                            className={classes.iconPop}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.setState({
                                filterAnchor: e.currentTarget,
                              });
                            }}
                          />
                        )}
                      </>
                    )}
                  </InputAdornment>
                }
                startAdornment={
                  searchIcon ? (
                    <InputAdornment>
                      <SearchIcon className={classes.iconPop} />
                    </InputAdornment>
                  ) : null
                }
                roundedCorner={false}
              />
            )}
            {isSelectInput && (
              <Select
                isClearable={true}
                defaultInputValue={this.state.text}
                defaultMenuIsOpen={this.state.text && this.state.text.length ? true : false}
                autoFocus
                value={this.state.selectText ? { label: this.state.selectText } : ""}
                formatOptionLabel={this.customLabel}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    fontSize: 13,
                    height: 40,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    position: "relative",
                    border: state.isFocused || state.isSelected ? "solid 1px #3c3c3c !important" : "solid 1px #c4c4c4 !important",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    borderRadius: 0,
                    zIndex: 999,
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: (state.isFocused && "lightgray") || "transparent",
                    color: (state.isFocused && "white") || "black",
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                noOptionsMessage={() => (loadingOption ? "Loading..." : "No options")}
                isSearchable={true}
                options={dropdownOptions}
                classNamePrefix="select"
                placeholder={placeholder}
                onInputChange={(e) => {
                  this.setState({ selectText: e });
                  if (e) {
                    this.props.onChangeSelectInput(e);
                  }
                }}
                onChange={(e) => {
                  console.log(e);
                  if (e && e?.label) {
                    this.setState(
                      {
                        selectText: e.label,
                      },
                      () => {
                        this.props.onClickOption(e);
                      }
                    );
                  }
                }}
              />
            )}
          </div>
          {this.state.typeAnchor && (
            <Popover
              open={this.state.typeAnchor != null}
              anchorEl={this.state.typeAnchor}
              onClose={() => {
                this.setState({
                  typeAnchor: null,
                });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <List component="nav">
                {this.state.types.map((item, k) => (
                  <ListItem
                    key={k}
                    button
                    selected={item.value === searchObj?.type}
                    onClick={() => {
                      this.setState({
                        typeAnchor: null,
                        selectedType: item.name,
                      });
                      this.handleOnChange("type", item.value);
                    }}
                  >
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
              </List>
            </Popover>
          )}
          {/* filter popover section */}
          <>
            {this.state.filterAnchor && (
              <Popover
                open={this.state.filterAnchor != null}
                anchorEl={this.state.filterAnchor}
                onClose={() => {
                  this.setState({
                    filterAnchor: null,
                  });
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{ maxWidth: "500px" }}
              >
                {(this.props.page === "all_products" || this.props.page === "category_wise_product") && (
                  <AllProductsFilter
                    user={this.props.user}
                    appliedfilters={filters}
                    onApplyFilter={(data) => {
                      this.setState({ filterAnchor: false });
                      this.props.onChangeFilter(data);
                    }}
                  />
                )}
                {this.props.page === "app_catalog" && (
                  <>
                    <OrgProductsFilter
                      user={this.props.user}
                      appliedfilters={filters}
                      onApplyFilter={(data) => {
                        this.setState({ filterAnchor: false });
                        this.props.onChangeFilter(data);
                      }}
                    />
                  </>
                )}
                {this.props.page === "vendor_catalog" && (
                  <>
                    <OrgVendorsFilter
                      searchFilterOptions={searchFilterOptions}
                      hardFilter={this.state.hardFilter}
                      onApplyFilter={(data) => {
                        this.setState({ filterAnchor: false, filterCatalogCount: data.item_slug ? 1 : 0 });
                        this.props.onChangeFilter(data);
                      }}
                    />
                  </>
                )}
              </Popover>
            )}
          </>
        </div>
        <Grid container justify="center" style={{ marginBottom: 5 }}>
          {showState &&
            this.state.stateFilter.map((objState, index) => {
              if (objState.label === "Featured" && this.props.hideFeatured) {
                return null;
              }

              if (objState.label === "Evaluated" && ((!EnableEvaluateForAdminsCheck && role !== "OrgUser") || (!EnableEvaluateForUsersCheck && role === "OrgUser"))) {
                return null;
              }

              return (
                <Grid
                  item
                  key={index}
                  className={classNames(classes.stateLabel, this.props.selectedState.includes(objState.value) ? classes.selected : {})}
                  onClick={() => this.handleOnClickState(objState.value)}
                >
                  <div className={classNames(objState.classname, classes.labelInit)}>{objState.shortName}</div>
                  <Typography className={classes.stateLabelText}>{objState.label}</Typography>
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
  }
}

SearchInput.defaultProps = {
  onChange: () => {},
  onChangeFilter: () => {},
  onChangeState: () => {},
  filters: {},
  searchObj: {},
  hardFilter: {},
  isFilter: true,
  roundedCorner: false,
  searchIcon: true,
  page: "product-search",
  showTypes: true,
  placeholder: "Search Products",
  catalogFieldValue: '',
  showState: false,
  isSelectInput: false,
  dropdownOptions: [],
  selectedState: [],
  loadingOption: false,
  catalogFieldFilters: false,
  onChangeSelectInput: () => {},
  onClickOption: () => {},
};

SearchInput.propTypes = {
  isFilter: PropTypes.bool,
  onSearchTypeChange: PropTypes.func,
  onClearClick: PropTypes.func,
  onFilterClick: PropTypes.func,
  searchObj: PropTypes.object,
  hardFilter: PropTypes.object,
  searchIcon: PropTypes.bool,
  page: PropTypes.string,
  showTypes: PropTypes.bool,
  placeholder: PropTypes.string,
  catalogFieldValue: PropTypes.string,
  filters: PropTypes.object,
  showState: PropTypes.bool,
  isSelectInput: PropTypes.bool,
  dropdownOptions: PropTypes.array,
  searchOrgProductsProgress: PropTypes.bool,
  onChangeSelectInput: PropTypes.func,
  onClickOption: PropTypes.func,
  roundedCorner: PropTypes.bool,
  catalogFieldFilters: PropTypes.bool,
  selectedState: PropTypes.array,
  searchFilterOptions: PropTypes.array,
};

export default connector(compose(withStyles(styles))(SearchInput));
