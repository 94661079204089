import {
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Schema from 'Components/Common/ProjectSchema/Schema.jsx';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({

});

const connectionActions = {
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
});

class SchemaTagSelectionDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            projectSchema: {}
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {

    }

    render() {
        const classes = this.props.classes;
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Project Schema</Typography>
                <div className={classes.body}>
                    <Schema
                        workflowId={this.props.workflowId}
                        handleClick={(tagData, additional_data) => {
                            console.log(tagData,'TagData')
                            this.props.addTagValue(tagData,additional_data)
                        }}
                        loadEverytime={this.props.loadEverytime}
                    />
                </div>
            </DialogContent>
        </Dialog>
    }
}

SchemaTagSelectionDialog.defaultProps = {
    loadEverytime: true,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(SchemaTagSelectionDialog));