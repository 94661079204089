import {
  SHOW_EVALUATION_LINK_DIALOG,HIDE_EVALUATION_LINK_DIALOG,
  START_PARENT_EVALUATION_FETCH,END_PARENT_EVALUATION_FETCH,
  START_CHILD_EVALUATION_SAVE,END_CHILD_EVALUATION_SAVE,
  START_CHILD_EVALUATION_FETCH,END_CHILD_EVALUATION_FETCH,
  START_CHILD_EVALUATION_CREATE,END_CHILD_EVALUATION_CREATE
} from "./action";

const initState   =     {
  showDialog:false,
  parentfetchProgress:false,
  errorParentFetch:false,
  parentEvaluation:null,
  childSaveProgress:false,
  errorChildSave:false,
  childCreateProgress:false,
  errorChildCreate:false,
  childEvaluation:[],
  childEvaluationFetchProg:false,
}

export  function evaluationLinkReducer(state=initState,action){
  switch(action.type){
      case SHOW_EVALUATION_LINK_DIALOG:
          return {
              ...state,
              showDialog:true
          }
      case HIDE_EVALUATION_LINK_DIALOG:
          return {
              ...state,
              showDialog:false
          }
      case START_PARENT_EVALUATION_FETCH:
          return {
              ...state,
              parentfetchProgress:true,
              errorParentFetch:null,
              parentEvaluation:null,
          }
      case END_PARENT_EVALUATION_FETCH:
          return {
              ...state,
              parentfetchProgress:false,
              errorParentFetch:action.payload.error,
              parentEvaluation:action.payload.success,
          }
      case START_CHILD_EVALUATION_SAVE:
        return {
            ...state,
            childSaveProgress:true,
            errorChildSave:null,
            childEvaluation:[],
        }
      case END_CHILD_EVALUATION_SAVE:
        return {
            ...state,
            childSaveProgress:false,
            errorChildSave:action.payload.error,
            childEvaluation:action.payload.success,
        }
        case START_CHILD_EVALUATION_FETCH:
            return {
                ...state,
                childEvaluationFetchProg:true,
                childEvaluation:[],
            }
        case END_CHILD_EVALUATION_FETCH:
            return {
                ...state,
                childEvaluationFetchProg:false,
                childEvaluation:action.payload.success,
            }

        case START_CHILD_EVALUATION_CREATE:
            return {
                ...state,
                childCreateProgress:true,
                errorChildCreate:null,
                childEvaluation:[],
            }
        case END_CHILD_EVALUATION_CREATE:
            return {
                ...state,
                childCreateProgress:false,
                errorChildCreate:action.payload.error,
                childEvaluation:action.payload.success,
            }
      default:
          return state;
  }
}