import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AppBarV2 from "Components/Application/AppBarV2";
import { Button, CircularProgress, Grid, Popover, Divider, MenuItem, Checkbox } from '@material-ui/core';
import * as classnames from "classnames";
import {
  getOrgWorkflow,
  addOrgWorkflow,
  deleteOrgWorkflow,
  showWorkflowDetailUpdateDialog,
  hideWorkflowDetailUpdateDialog,
  setWorkflowActiveTab,
  addCopyOfOrgWorkflow
} from "redux/evaluation/workflow/action";
import { showSnackBar } from "redux/snackbar/action";
import deleteIcon from "assets/images/delete.svg";
import Image from 'Components/Common/image.jsx';
import { updateDrawerStyle } from "redux/drawer/action";

import WorkflowDetailsUpdateDialog from "./Dialogs/WorkflowDetailsUpdateDialog"

import { getMasterWorkflows } from "redux/master/workflow/action";

import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";

import BottomActions from './Common/BottomActions';

const connectedProps = (state) => ({
  authUser: state.authUser,
  orgWorkFlowProgress: state.evaluationWorkflow.orgWorkFlowProgress,
  orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
  orgWorkFlowError: state.evaluationWorkflow.orgWorkFlowError,
  addOrgWorkFlowProgress: state.evaluationWorkflow.addOrgWorkFlowProgress,
  addOrgWorkFlowSuccess: state.evaluationWorkflow.addOrgWorkFlowSuccess,
  addOrgWorkFlowError: state.evaluationWorkflow.addOrgWorkFlowError,
  deleteOrgWorkFlowProgress: state.evaluationWorkflow.deleteOrgWorkFlowProgress,
  deleteOrgWorkFlowSuccess: state.evaluationWorkflow.deleteOrgWorkFlowSuccess,
  deleteOrgWorkFlowError: state.evaluationWorkflow.deleteOrgWorkFlowError,
  fetchAllOrgSettingsSuccess: state.orgUser.fetchAllOrgSettingsSuccess,
  fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
  fetchAllOrgSettingsFailure: state.orgUser.fetchAllOrgSettingsFailure,
  workflowActiveTab: state.evaluationWorkflow.workflowActiveTab,
  masterWorkFlowProgress: state.masterWorkflow.masterWorkFlowProgress,
  masterWorkFlowList: state.masterWorkflow.masterWorkFlowList,
  masterWorkFlowError: state.masterWorkflow.masterWorkFlowError,
  addCopyOfOrgWorkFlowProgress: state.evaluationWorkflow.addCopyOfOrgWorkFlowProgress,
  addCopyOfOrgWorkFlowSuccess: state.evaluationWorkflow.addCopyOfOrgWorkFlowSuccess,
  addCopyOfOrgWorkFlowError: state.evaluationWorkflow.addCopyOfOrgWorkFlowError,
});

const connectionActions = {
  getOrgWorkflow: getOrgWorkflow,
  addOrgWorkflow: addOrgWorkflow,
  showSnackBar: showSnackBar,
  deleteOrgWorkflow: deleteOrgWorkflow,
  updateDrawerStyle: updateDrawerStyle,
  showWorkflowDetailUpdateDialog: showWorkflowDetailUpdateDialog,
  hideWorkflowDetailUpdateDialog: hideWorkflowDetailUpdateDialog,
  setWorkflowActiveTab: setWorkflowActiveTab,
  getMasterWorkflows: getMasterWorkflows,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  addCopyOfOrgWorkflow: addCopyOfOrgWorkflow
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  "@global": {
    '.applicationContent': {
      background: '#fff !important',
      padding: '0px !important'
    },
  },
  tabs: {
    listStyle: "none",
    display: "flex",
    margin: 0,
    padding: 0,

  },
  tab: {
    borderBottom: "solid 1px #e0e0e0",
  },
  tabBtn: {
    padding: '1em',
    borderRadius: 0
  },
  activeBtn: {
    color: "#4A87F8",
    borderBottom: "solid 2px #4A87F8",
  },
  card: {
    border: "2px solid #EEEEEE",
    borderRadius: '10px',
    background: "#fff",
    paddingLeft: theme.spacing(2),
    cursor: "pointer",
    display: 'flex',
    gap: '7px',
    placeContent: 'center',
    width: '100%',
    padding: '14px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 80,
  },
  templateFlags: {
    display: "flex",
    marginTop: "-12px",
    marginBottom: 10,
    "& span": {
      padding: "3px 7px",
      fontSize: "11px",
      textAlign: "left",
      borderRadius: "5px",
      marginRight: "7px",
      opacity: ".8",
      display: "inline-block",
    },
    "& .typeE": {
      color: "#155724",
      backgroundColor: "#d4edda",
      borderColor: "#c3e6cb",
    },
    "& .typeR": {
      color: "#721c24",
      backgroundColor: "#f8d7da",
      borderColor: "#f5c6cb",
    },
    "& .typeRn": {
      color: "#222",
      backgroundColor: "#c9ef6f",
      borderColor: "#f5c6cb",
    },
  },
  cardText: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  cardCheckbox: {
    flex: 1,
    maxWidth: 50
  },
  cardSection: {
    display: 'flex',
    marginTop: 30,
    flexWrap: 'wrap',
    paddingBottom: 100
  },
  mainSection: {
    background: '#F7F7F7',
    padding: '0 32px',
    height: 'calc(100vh - 50px)'
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  secondarySection: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  loader: {
    marginTop: 20,
  },
  titleImage: {
    cursor: 'pointer',
    width: 16
  },
  menuItem: {
    color: "#74798C",
    fontSize: theme.spacing(2),
    padding: theme.spacing(2.2),
    minWidth: 140,
  },
})

class ComponentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 2,
      page: 0,
      pageSize: 500,
      defaultRenewalId: null,
      defaultEvalutionId: null,
      defaultReviewId: null,
      deleteId: null,
      isShowAddPopover: null,
      addOptions: [
        { url: "", label: "Add Manually", key: "addManually", addManually: true },
        { url: "", label: "Choose from existing workflow", key: "chooseViaExisting", isCheckBox: true },
      ],
      isCheckBox: false,
      selectedWorkflowForCopyId: null,
      selectedWorkflowForCopyItem: null,
    }
    this.addNewFlow = this.addNewFlow.bind(this)
    this.addWorkflowCopy = this.addWorkflowCopy.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.deleteOrgWorkFlowProgress && prevProps.deleteOrgWorkFlowProgress) {
      if (this.props.deleteOrgWorkFlowSuccess) {
        this.props.showSnackBar(
          'Workflow is deleted successfully',
          "success",
          3000
        );
        let type = this.props.workflowActiveTab === 1 ? 2 : 1;
        this.fetchOrgWorkFlow([type])
      } else if (this.props.deleteOrgWorkFlowError) {
        this.props.showSnackBar(
          'Workflow is not deleted',
          "error",
          3000
        );
      }
    }

    if (this.props.addCopyOfOrgWorkFlowProgress === false && prevProps.addCopyOfOrgWorkFlowProgress) {
      if (this.props.addCopyOfOrgWorkFlowError === null) {
        this.props.showSnackBar(
          'Workflow Copy Created Successfully',
          "success",
          3000
        );
        this.goToWorkFlow(this.props.addCopyOfOrgWorkFlowSuccess)
      } else {
        this.props.showSnackBar(
          'Something went wrong',
          "error",
          3000
        );
      }
    }

    // fetchAllOrgSettingsSuccess:  status.orgUser.fetchAllOrgSettingsSuccess,
    // fetchAllOrgSettingsInprogress: status.orgUser.fetchAllOrgSettingsInprogress,
    // fetchAllOrgSettingsFailure: status.orgUser.fetchAllOrgSettingsFailure

    if (!this.props.fetchAllOrgSettingsInprogress && prevProps.fetchAllOrgSettingsInprogress) {
      this.setDefaultSetting();
    }
  }

  setDefaultSetting() {
    if (this.props.fetchAllOrgSettingsSuccess?.length) {

      const defaultEvaluationObj = this.props.fetchAllOrgSettingsSuccess.find((item) => item.SettingName === "ORG_DEFAULT_EVALUATION_WORKFLOW");
      const defaultReviewObj = this.props.fetchAllOrgSettingsSuccess.find((item) => item.SettingName === "ORG_DEFAULT_REVIEW_WORKFLOW");
      const defaultObjRenew = this.props.fetchAllOrgSettingsSuccess.find((item) => item.SettingName === "ORG_DEFAULT_RENEWAL_WORKFLOW");
      // console.log(defaultObjRenew,'defaultObjRenew')

      try {
        if ((defaultEvaluationObj && defaultEvaluationObj?.Value && defaultEvaluationObj?.Value !== 'NULL')) {
          this.setState({ defaultEvalutionId: JSON.parse(defaultEvaluationObj?.Value).wid })
        }

        if ((defaultReviewObj && defaultReviewObj?.Value && defaultReviewObj?.Value !== 'NULL')) {
          this.setState({ defaultReviewId: JSON.parse(defaultReviewObj?.Value).wid })
        }
        if ((defaultObjRenew && defaultObjRenew?.Value && defaultObjRenew?.Value !== 'NULL')) {
          this.setState({ defaultRenewalId: JSON.parse(defaultObjRenew?.Value).wid })
        }

      } catch (e) {
        console.warn(e);
      }
    }
  }

  componentDidMount() {
    if (this.props.workflowActiveTab === 1) {
      this.fetchMasterWorkflows();
    } else {
      this.fetchOrgWorkFlow([1]);
    }
    this.props.updateDrawerStyle(true, true);
    this.setDefaultSetting();
  }

  fetchOrgWorkFlow = (types) => {
    this.props.getOrgWorkflow({ types: types, page: this.state.page, pageSize: this.state.pageSize })
  }

  fetchMasterWorkflows = () => {
    this.props.getMasterWorkflows({ page: this.state.page, pageSize: this.state.pageSize });
  }

  removeWorkflow = (e, item) => {
    e.stopPropagation();
    var me = this;
    this.setState({ deleteId: item.ID }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteOrgWorkflow(this.state.deleteId);
    }
  };

  navigateTo = () => {
    const data = { name: this.props.workflowActiveTab === 1 ? `Evaluation ${this.props.orgWorkFlowList?.Data.length + 1}` : `Review ${this.props.orgWorkFlowList?.Data.length + 1}`, description: '', type: this.props.workflowActiveTab, status: 1 };
    this.props.addOrgWorkflow(data);
  }

  addNewFlow() {
    this.props.showWorkflowDetailUpdateDialog();

  }

  handleClickNewBtn = (event) => {
    this.setState({ isShowAddPopover: event.currentTarget || event.current });
  };

  handleCloseAddPopover = () => {
    this.setState({ isShowAddPopover: null });
  };

  handleClickMenuItem = (menu) => {
    if (menu.isCheckBox) {
      this.setState({
        isCheckBox: true,
        selectedWorkflowForCopyId: null,
        selectedWorkflowForCopyItem: null
      })
    } else if (menu.addManually) {
      this.addNewFlow()
    }
    this.handleCloseAddPopover();
  };

  addWorkflowCopy() {
    const { selectedWorkflowForCopyId, selectedWorkflowForCopyItem } = this.state;
    console.log(selectedWorkflowForCopyId, 'selectedWorkflowForCopyId')
    console.log(selectedWorkflowForCopyItem, 'selectedWorkflowForCopyItem')
    const params = {
      "workflow_id": selectedWorkflowForCopyId,
      "name": "Copy of " + selectedWorkflowForCopyItem?.Name,
      "description": selectedWorkflowForCopyItem?.Description
    }
    this.props.addCopyOfOrgWorkflow(params);
  }

  goToWorkFlow = (item) => {
    if (this.props.workflowActiveTab === 1) {
      this.props.history.push(`/app/settings/form-builder/master/${item.ID}`)
    } else {
      this.props.history.push(`/app/settings/form-builder/default/${item.ID}`)
    }
  }

  render() {
    const classes = this.props.classes;
    const workFlowList = [];
    const { selectedWorkflowForCopyId } = this.state;

    // console.log(this.state.defaultRenewalId,'defaultRenewalId-01')

    if (this.props.workflowActiveTab === 1 && this.props.masterWorkFlowList?.Data?.length > 0) {
      this.props.masterWorkFlowList.Data.forEach((item, index) => {
        if (this.props.workflowActiveTab === 1 && this.state.defaultEvalutionId && item.ID === parseInt(this.state.defaultEvalutionId)) {
          item['defaultWorkflow'] = true;
        }

        if (this.props.workflowActiveTab === 1 && this.state.defaultReviewId && item.ID === parseInt(this.state.defaultReviewId)) {
          item['defaultForReview'] = true;
        }

        if (this.props.workflowActiveTab === 1 && this.state.defaultRenewalId && item.ID === parseInt(this.state.defaultRenewalId)) {
          item['defaultForRenewal'] = true;
        }

        workFlowList.push(item);
      })
    }

    if (this.props.workflowActiveTab === 2 && this.props.orgWorkFlowList?.Data?.length > 0) {
      this.props.orgWorkFlowList.Data.forEach((item, index) => {
        if (this.props.workflowActiveTab === 2 && this.state.defaultEvalutionId && item.ID === parseInt(this.state.defaultEvalutionId)) {
          item['defaultWorkflow'] = true;
        }
        if (this.props.workflowActiveTab === 2 && this.state.defaultReviewId && item.ID === parseInt(this.state.defaultReviewId)) {
          item['defaultForReview'] = true;
        }

        if (this.props.workflowActiveTab === 2 && this.state.defaultRenewalId && item.ID === parseInt(this.state.defaultRenewalId)) {
          item['defaultForRenewal'] = true;
        }
        workFlowList.push(item);
      })
    }


    // console.log(workFlowList, 'workFlowList');
    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    const open = Boolean(this.state.isShowAddPopover);
    const id = open ? "newWorkflow" : undefined;

    return <>
      <div>
        <AppBarV2 title="Workflow builder" newHeader={true} withBack />
        <div className={classes.mainSection}>
          <div className={classes.secondarySection}>
            {false && <ul className={classes.tabs}>
              <li className={classes.tab}>
                <Button className={classnames(classes.tabBtn, {
                  [classes.activeBtn]: this.props.workflowActiveTab === 1
                })} onClick={() => {
                  this.setState({ active: 1, isCheckBox: false, selectedWorkflowForCopyId: null, selectedWorkflowForCopyItem: null })
                  this.props.setWorkflowActiveTab(1)
                  this.fetchMasterWorkflows()
                }}>Expent Workflow Library </Button>
              </li>
              <li className={classes.tab}>
                <Button className={classnames(classes.tabBtn, {
                  [classes.activeBtn]: this.props.workflowActiveTab === 2
                })} onClick={() => {
                  this.setState({ active: 2, isCheckBox: false, selectedWorkflowForCopyId: null, selectedWorkflowForCopyItem: null })
                  this.props.setWorkflowActiveTab(2)
                  this.fetchOrgWorkFlow([1])
                }}>Custom Workflows </Button>
              </li>
            </ul>}
            <div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginTop: 20 }}>
              {this.props.workflowActiveTab === 2 && <Button className={classes.newUserBtn} onClick={this.addNewFlow}> + New Workflow </Button>}
            </div>
          </div>

          {this.props.orgWorkFlowProgress &&
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          }

          {!this.props.orgWorkFlowProgress && !this.props.masterWorkFlowProgress && workFlowList.length === 0 &&
            <div className={classes.loader}>
              Data not found
            </div>
          }

          <div className={classes.cardSection}>
            {!this.props.orgWorkFlowProgress && !this.props.masterWorkFlowProgress && workFlowList.length !== 0 &&
              <Grid container spacing={3}>
                {workFlowList.map((item, index) => {
                  return <Grid item lg={3} md={3} key={index}>
                    <div key={index} className={classes.card} >
                      {this.state.isCheckBox && <div className={classes.cardCheckbox}>
                        <Checkbox
                          checked={selectedWorkflowForCopyId !== null && selectedWorkflowForCopyId === item.ID}
                          onChange={(event) => {
                            this.setState({
                              selectedWorkflowForCopyId: item.ID,
                              selectedWorkflowForCopyItem: item
                            })
                          }}
                        />
                      </div>}
                      <div className={classes.cardText} onClick={() => {
                        if (!this.state.isCheckBox) {
                          this.goToWorkFlow(item)
                        }
                      }}>
                        {!this.state.isCheckBox && <div className={classes.templateFlags}>
                          {item.defaultWorkflow && <span className={"typeE"}>Default for evaluation</span>}
                          {item.defaultForReview && <span className={"typeR"}>Default for review</span>}
                          {item.defaultForRenewal && <span className={"typeRn"}>Default for renewal</span>}
                        </div>}
                        <span>{item.Name}</span>
                      </div>
                      {!this.state.isCheckBox && this.props.workflowActiveTab === 2 && <div onClick={(event) => this.removeWorkflow(event, item)}><Image src={deleteIcon} alt="delete" className={classes.titleImage} /></div>}
                    </div>
                  </Grid>
                })}
              </Grid>
            }

          </div>
        </div>
        <WorkflowDetailsUpdateDialog
          isNew={true}
          active={this.props.workflowActiveTab}
        />

        <MessageDialog messageData={messageData} callBack={this.callBack} />

        {this.state.isCheckBox && <BottomActions
          isDisabled={selectedWorkflowForCopyId === null || selectedWorkflowForCopyId === '' ? true : false}
          submitResponse={this.addWorkflowCopy}
          nextClickLoader={this.state.nextClickLoader}
          cancel={() => {
            this.setState({
              isCheckBox: false,
              selectedWorkflowForCopyId: null
            })
          }}
        />}
        <Popover
          className={classes.popover}
          id={id}
          open={open}
          anchorEl={this.state.isShowAddPopover}
          onClose={() => this.handleCloseAddPopover()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {this.state.addOptions?.map((item, childIndex) => {
            return (
              <div key={childIndex}>
                {" "}
                <MenuItem
                  value={item.label}
                  className={classes.menuItem}
                  onClick={() => this.handleClickMenuItem(item)}
                >
                  {item.label}
                </MenuItem>{" "}
                <Divider />
              </div>
            );
          })}
        </Popover>


      </div>
    </>
  }
}

export default connector(compose(
  withStyles(styles)
)(ComponentList));