import { CircularProgress, Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import CommonCss from "commonCss";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import AppBarV2 from "Components/Application/AppBarV2";

import { updateDrawerStyle } from "redux/drawer/action";
import { fetchAllOrgSettings } from "redux/org/user/action";
// Redux

import { fetchOrgCatalogDetailsTabs, fetchOrgExtUserCatalogDetailsTabs, fetchVendorCatalogTabData } from "redux/vendor/vendorCatalog/action";
import { fetchServiceCatalogTabData } from "redux/vendor/serviceCatalog/action";
import { fetchVendorServiceCatalogTabData } from "redux/vendor/vendorServiceCatalog/action";
import { showSnackBar } from "redux/snackbar/action";
//shared
import Header from "./Header";
import Sidebar from "./Sidebar";
import VendorInfo from "./VendorInfo";
import StartVendorAndServiceWorkflowDialog from "Components/Common/Dialogs/StartVendorAndServiceWorkflowDialog";
import { hideStartNewWorkflowDialg } from 'redux/workflow/action';
import AddUpdateVendorServiceTaskDialog from "Components/Common/Dialogs/AddUpdateVendorServiceTaskDialog";
import SearchVendorExternalUserPoper from "Components/Common/TeamsAndUser/SearchVendorExternalUserPoper";
import { hideSearchVendorExternalUsersPoperDialog } from "redux/dialogs/action";
import { addVendorExternalCollaborator, removeVendorExternalCollaborator } from "redux/vendor/action";
import { isEnabledProjectTracking } from "util/Common";
import { getNewPlatform } from "util/Environment";

const connectedProps = (state) => ({
  user: state.authUser.user,
  evaluationCart: state.evaluationCart.evaluation,
  userType: state.authUser.user?.Role,
  orgUser: state.orgUser,
  isLoadingTab: state.vendorCatalog.orgCatalogDetailTabsProgress,
  orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,

  orgExtUserCatalogDetailTabs: state.vendorCatalog.orgExtUserCatalogDetailTabs,
  orgExtUserCatalogDetailTabsProgress: state.vendorCatalog.orgExtUserCatalogDetailTabsProgress,

  fetchVendorCatalogTabDataProgress: state.vendorCatalog.fetchVendorCatalogTabDataProgress,
  fetchVendorCatalogTabDataError: state.vendorCatalog.fetchVendorCatalogTabDataError,
  vendorCatalogTabData: state.vendorCatalog.vendorCatalogTabData,
  orgVendorTabSectionItemDataUpdateProgress: state.vendorCatalog.orgVendorTabSectionItemDataUpdateProgress,
  orgVendorTabSectionItemDataUpdateError: state.vendorCatalog.orgVendorTabSectionItemDataUpdateError,

  fetchServiceCatalogTabDataProgress: state.serviceCatalog.fetchServiceCatalogTabDataProgress,
  fetchServiceCatalogTabDataError: state.serviceCatalog.fetchServiceCatalogTabDataError,
  serviceCatalogTabData: state.serviceCatalog.serviceCatalogTabData,
  orgServiceTabSectionItemDataUpdateProgress: state.serviceCatalog.orgServiceTabSectionItemDataUpdateProgress,
  orgServiceTabSectionItemDataUpdateError: state.serviceCatalog.orgServiceTabSectionItemDataUpdateError,

  fetchVendorServiceCatalogTabDataProgress: state.vendorServiceCatalog.fetchVendorServiceCatalogTabDataProgress,
  fetchVendorServiceCatalogTabDataError: state.vendorServiceCatalog.fetchVendorServiceCatalogTabDataError,
  vendorServiceCatalogTabData: state.vendorServiceCatalog.vendorServiceCatalogTabData,
  orgVendorServiceTabSectionItemDataUpdateProgress: state.vendorServiceCatalog.orgVendorServiceTabSectionItemDataUpdateProgress,
  orgVendorServiceTabSectionItemDataUpdateError: state.vendorServiceCatalog.orgVendorServiceTabSectionItemDataUpdateError,
  orgVendorAddProgress: state.vendorCatalog.orgVendorAddProgress,
  orgVendorAddError: state.vendorCatalog.orgVendorAddError,
  deleteOrgVendorServiceProgress: state.vendorServiceCatalog.deleteOrgVendorServiceProgress,
  deleteOrgVendorServiceError: state.vendorServiceCatalog.deleteOrgVendorServiceError,
  deleteProductProgress: state.orgProduct.deleteProductProgress,
  
  showNewWorkflowStartDialog: state.workflow.showNewWorkflowStartDialog,
  startNewWorkflowProgress: state.workflow.startNewWorkflowProgress,
  startNewWorkflowError: state.workflow.startNewWorkflowError,
  workflowData: state.workflow.workflowData,
  showAddTaskDialog: state.vendors.showAddTaskDialog,
  isOpenUserDialog: state.dialogs.isShowSearchVendorExternalUserPoperDialog,
  additionalDataForExternalUser: state.dialogs.searchVendorExternalUserPoperAdditionData,

  externalCollaboratorAddProgress: state.vendors.externalCollaboratorAddProgress,
  externalCollaboratorAddError: state.vendors.externalCollaboratorAddError,
  externalCollaboratorAddSuccess: state.vendors.externalCollaboratorAddSuccess,

  sectionExternalUsersProgress: state.vendorCatalog.sectionExternalUsersProgress,
  fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
});

const connectionActions = {
  fetchAllOrgSettings: fetchAllOrgSettings,
  updateDrawerStyle: updateDrawerStyle,
  showSnackBar: showSnackBar,
  hideStartNewWorkflowDialg: hideStartNewWorkflowDialg,
  fetchVendorCatalogTabData: fetchVendorCatalogTabData,
  fetchServiceCatalogTabData: fetchServiceCatalogTabData,
  fetchOrgCatalogDetailsTabs: fetchOrgCatalogDetailsTabs,
  fetchVendorServiceCatalogTabData: fetchVendorServiceCatalogTabData,
  hideSearchVendorExternalUsersPoperDialog: hideSearchVendorExternalUsersPoperDialog,
  addVendorExternalCollaborator: addVendorExternalCollaborator,
  removeVendorExternalCollaborator: removeVendorExternalCollaborator,
  fetchOrgExtUserCatalogDetailsTabs: fetchOrgExtUserCatalogDetailsTabs,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
    },
    ...CommonCss.ProductDetails,
    contentSection: {
      ...CommonCss.ProductDetails.contentSection,
      overflow: 'hidden',
      height: "calc(100vh - 314px)",
    },
    ProductNameSection: {
      ...CommonCss.ProductDetails.ProductNameSection,
      justifyContent: "space-between",
      maxWidth: "100%",
    },
    tabSection: {
      marginBottom: 17,
    },
    formGroup: {
      marginTop: 5,
    },
    label: {
      marginBottom: 5,
      display: "block",
    },
    creatable: {
      padding: 0,
    },
    searchAndSelect: {
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
        minHeight: 44,
      },
      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    uploadImageLabel: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    uploadImage: {
      width: 0,
      overflow: "hidden",
    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 19,
      margin: "0px !important",
    },

    productTitleSection: {
      ...CommonCss.productTitleSection,
      marginTop: 40,
      marginBottom: 10,
    },
    ...CommonCss.Home,
    evaluateCard: {
      ...CommonCss.Home.evaluateCard,
      padding: "24px 24px",
      border: "1px solid #ddd",
      position: "relative",
    },
    evaluateType: {
      borderRadius: "5px",
      position: "absolute",
      textAlign: "left",
      fontSize: "11px",
      padding: "3px 10px",
      right: "0px",
      top: "0px",
    },
    isEvaluate: {
      background: "#E6FFF3",
      color: "#2EB77D",
    },
    isReview: {
      background: "#cce5ff",
      color: "#004085",
    },
    showMore: {
      display: "inline-block",
      border: "1px solid #FFF",
      cursor: "pointer",
      padding: "10px 14px",
      fontSize: "12px",
      background: "#4A87F8",
      borderRadius: "20px",
      color: "white",
    },
    DashboardNoDataLayout: {
      width: "calc(100% - 10px)",
      margin: "22px 0px 0px 0px",
      minHeight: 450,
      textAlign: "center",
      background: "#ffffff",
      padding: "23px",
      borderRadius: "6px",
      "& button": {
        margin: "20px 0",
      },
    },
    bottomHead: {
      ...CommonCss.ProductDetails.bottomHead,
      marginBottom: 0,
    },
    tickMark: {
      color: "#4b86f8",
      border: "1px solid #4b86f8",
      marginRight: 10,
      minWidth: "50px !important",
      "&:hover": {
        color: "#4b86f8",
      },
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 16,
      marginBottom: 5,
      cursor: "pointer",
    },
    reviewFrequency: {
      alignItems: "center",
      marginBottom: "5px",
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
        minHeight: 44,
      },
      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    productSection: {
      display: "flex",
      alignItems: "center",
    },
    itemSection: {
      border: "1px solid #d8d8d8",
      padding: 17,
      borderRadius: 5,
      background: "#FFF",
      marginBottom: 17,
      "& [class*=PricingSection-head]": {
        color: "#6C6C6C",
        fontSize: 18,
        opacity: 1,
        fontWeight: 600,
        margin: 0,
        marginBottom: 11,
      },
    },
    head: {
      color: "#6C6C6C",
      fontSize: 18,
      opacity: 1,
      fontWeight: 600,
      margin: 0,
      marginBottom: 11,
    },
    productLeftSection: {
      maxWidth: 200,
      width: 200,
      minWidth: "auto",
      top: '20px',
      position: 'sticky',
      alignSelf: 'flex-start',
    },

    rootContainer: CommonCss.rootContainer,
    productDetailsData: {
      marginTop: "0px !important",
    },
    addReviewBtn: {
      textAlign: "right",
    },
  });

class OrgTabDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is404: false,
      isCartShow: false,
      tabValue: 0,
      activeNumber: 0,
      reviewInprogress: {
        inPorgressStatus: [2, 3],
        page: 0,
        pageSize: 5,
      },
      reviewCompleted: {
        completedStatus: [],
        page: 0,
        pageSize: 5,
      },
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchCatalogData();
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);
  }

  checkNewVendorUi = () => {
    const EnableVendorCatalogV2 = isEnabledProjectTracking(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableVendorCatalogV2");
    if(EnableVendorCatalogV2 && this.props.vendorCatalogTabData){
      let link = `${getNewPlatform()}/vendor-catalog/${this.props.vendorCatalogTabData.entity_slug}/-`
      window.open(link, "_self");
    }
    this.setState({isLoading: false})
  }

  componentDidUpdate(prevProps) {
    if (this.props.orgVendorAddProgress === false && prevProps.orgVendorAddProgress === true) {
      if (this.props.orgVendorAddError === null) {
        this.fetchCatalogData();
        this.props.showSnackBar("Detail updated Successfully", "success", 3000);
      } else {
        this.props.showSnackBar("Failed to update", "error", 3000);
      }
    }
    if (this.props.deleteOrgVendorServiceProgress === false && prevProps.deleteOrgVendorServiceProgress === true) {
      if (this.props.deleteOrgVendorServiceError === null) {
        this.fetchCatalogData();
        this.props.showSnackBar("Detail updated Successfully", "success", 3000);
      } else {
        this.props.showSnackBar("Failed to update", "error", 3000);
      }
    }
    if (this.props.deleteProductProgress === false && prevProps.deleteProductProgress === true) {
      this.fetchCatalogData();
      this.props.showSnackBar("Detail updated Successfully", "success", 3000);
    }
    if (!this.props.orgVendorTabSectionItemDataUpdateProgress && prevProps.orgVendorTabSectionItemDataUpdateProgress) {
      if (!this.props.orgVendorTabSectionItemDataUpdateError) {
        this.props.showSnackBar("Success", "success", 3000);
      } else {
        this.props.showSnackBar("Failed", "error", 3000);
      }
    }
    if (!this.props.orgServiceTabSectionItemDataUpdateProgress && prevProps.orgServiceTabSectionItemDataUpdateProgress) {
      if (!this.props.orgServiceTabSectionItemDataUpdateError) {
        this.props.showSnackBar("Success", "success", 3000);
      } else {
        this.props.showSnackBar("Failed", "error", 3000);
      }
    }
    if (!this.props.orgVendorServiceTabSectionItemDataUpdateProgress && prevProps.orgVendorServiceTabSectionItemDataUpdateProgress) {
      if (!this.props.orgVendorServiceTabSectionItemDataUpdateError) {
        this.props.showSnackBar("Success", "success", 3000);
      } else {
        this.props.showSnackBar("Failed", "error", 3000);
      }
    }

    if (!this.props.fetchVendorCatalogTabDataProgress && prevProps.fetchVendorCatalogTabDataProgress) {
      if (this.props.fetchVendorCatalogTabDataError?.message && this.props.fetchVendorCatalogTabDataError?.message !== null) {
        this.setState({ is404: true });
      }else{
        this.checkNewVendorUi();
      }
    }

    if (!this.props.fetchServiceCatalogTabDataProgress && prevProps.fetchServiceCatalogTabDataProgress) {
      if (this.props.fetchServiceCatalogTabDataError?.message && this.props.fetchServiceCatalogTabDataError?.message !== null) {
        this.setState({ is404: true });
      }
    }

    if (!this.props.fetchVendorServiceCatalogTabDataProgress && prevProps.fetchVendorServiceCatalogTabDataProgress) {
      if (this.props.fetchVendorServiceCatalogTabDataError?.message && this.props.fetchVendorServiceCatalogTabDataError?.message !== null) {
        this.setState({ is404: true });
      }
    }

    if (this.props?.location?.hash !== prevProps.location?.hash || (!this.props.isLoadingTab && prevProps.isLoadingTab)) {
      let tabs = [];
      let all_tabs = this.getOrgRoleTabs();
      all_tabs.forEach(function (itm) {
        tabs.push(itm);
      });

      if (tabs && tabs.length > 0) {
        let tabfiltered = tabs.filter((tab) => `#${tab.slug}` === this.props?.location?.hash);
        if (tabfiltered.length) {
          const tabValue = tabs.findIndex((tab) => `#${tab.slug}`=== this.props?.location?.hash);
          this.setState({ tabValue });
        }
        
      }
    }

    if (this.props.type !== prevProps.type || this.props.match?.params?.id !== prevProps.match?.params?.id) {
      this.fetchCatalogData();
    }

    if (this.props.startNewWorkflowProgress === false && prevProps.startNewWorkflowProgress === true) {
      if (this.props.startNewWorkflowError === null) {
          this.props.hideStartNewWorkflowDialg();
          this.props.history.push(`/app/evaluate/${this.props.workflowData.ID}/workflow/interim`);
      }else {
        this.props.showSnackBar("Failed to start workflow", "error", 3000);
      }
    }

    if (prevProps.externalCollaboratorAddProgress && !this.props.externalCollaboratorAddProgress) {
      if (this.props.externalCollaboratorAddError) {
        this.props.showSnackBar('failed to add', "error", 3000);
      } else if (this.props.externalCollaboratorAddSuccess) {
        this.props.showSnackBar('User added successfully', "success", 3000);
      } else {
        this.props.showSnackBar('something went wrong', "error", 3000);
      }
    }
  }

  fetchCatalogData = () => {
    if(this.props.user.Role === "OrgExternal"){
      this.props.fetchOrgExtUserCatalogDetailsTabs(this.props.match?.params?.id);
    }else{
      this.props.fetchOrgCatalogDetailsTabs(this.props.type === "vendor"? "vendor" : "vendorservice");
    }
    if (this.props.type === "service") {
      this.props.fetchServiceCatalogTabData(this.props.match?.params?.id);
    } else if (this.props.type === "vendorservice") {
      this.props.fetchVendorServiceCatalogTabData(this.props.match?.params?.id);
    } else if (this.props.type === "vendor") {
      this.props.fetchVendorCatalogTabData(this.props.match?.params?.id);
    } else {
      console.log("invalid type");
    }
  };

  getOrgRoleTabs = () => {
    const { orgCatalogDetailTabs, orgExtUserCatalogDetailTabs } = this.props;
    let tabs = orgCatalogDetailTabs;
    if(this.props.user.Role === "OrgExternal"){
      tabs = orgExtUserCatalogDetailTabs;
    }
    if (tabs) {
      return tabs.length ? tabs : [];
    } else {
      return [];
    }
  };

  componentWillUnmount() {
    this.props.updateDrawerStyle(true, true);
  }

  render() {
    if (this.props.isLoadingTab) {
      return (
        <Grid container alignItems="center" justify="center" style={{ height: "50vh" }}>
          <CircularProgress />
        </Grid>
      );
    }

    const classes = this.props.classes;
    const { vendorCatalogTabData, serviceCatalogTabData, vendorServiceCatalogTabData } = this.props;

    let tabDetailsData = vendorCatalogTabData;
    if (this.props.type === "service") {
      tabDetailsData = serviceCatalogTabData;
    } else if (this.props.type === "vendorservice") {
      tabDetailsData = vendorServiceCatalogTabData;
    }

    let title = "Organization Vendor Details";
    if (this.props.type === "service") {
      title = "Organization Service Details";
    } else if (this.props.type === "vendorservice") {
      title = "Organization Vendor Service Details";
    }

    if (this.props.fetchVendorCatalogTabDataProgress || this.props.fetchServiceCatalogTabDataProgress || this.props.fetchVendorServiceCatalogTabDataProgress || this.state.is404 || this.state.isLoading) {
      return (
        <div>
          <AppBarV2 title={title} withBack={true} dropdown={true} />
          <div className={classes.productDetails}>
            {this.state.is404 && (
              <div style={{ width: "100%", textJustify: "center", textAlign: "center" }}>
                <p className={classes.productName}>404</p>
                <p className={classes.emptyText}>{this.props.type === "vendor" ? "Vendor" : "Service"} does not exists</p>
              </div>
            )}
            {!this.state.is404 && (
              <div style={{ textAlign: "center", marginTop: 20, width: "100%" }}>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      );
    }

    var tabValue = this.state.tabValue;

    let role = this.props.user?.Role;

    let currentTab = null;
    let sections = [];
    let tabs = [];
    let all_tabs = this.getOrgRoleTabs();
    all_tabs.forEach(function (itm) {
      tabs.push(itm);
    });

    if (tabs.length > 0) {
      currentTab = tabs[tabValue];
      if (currentTab?.sections && currentTab?.sections.length > 0) {
        sections = currentTab?.sections;
      }
    }

    return (
      <div className={classes.root}>
        <div>
          <Header title={title} location={this.props.location} />

          <div className={classes.rootContainer}>
            <div className={classnames(classes.productDetails, classes.productDetailsData)}>
              <div className={classes.productLeftSection}>
                <Sidebar role={role} appDetails={tabDetailsData} sections={sections} tabValue={this.state.tabValue} catalogType={this.props.type} /> 
              </div>
              <div className={classes.productRightSection}>
                <VendorInfo role={role} appDetails={tabDetailsData} sections={sections} tabValue={tabValue} tabs={tabs} currentTab={currentTab} catalogType={this.props.type} />
              </div>
            </div>
          </div>
        </div>
        
        {this.props.showNewWorkflowStartDialog && <StartVendorAndServiceWorkflowDialog catalogType={this.props.type} appDetails={tabDetailsData}/>}
        
        {this.props.showAddTaskDialog && <AddUpdateVendorServiceTaskDialog/>}
        
        {this.props.isOpenUserDialog && <SearchVendorExternalUserPoper
          sectionId={this.props.additionalDataForExternalUser?.sectionId}
          entityId={this.props.additionalDataForExternalUser?.entityId}
          onSubmit={(user, access) => {
            let data = {
              user_id: String(user.ID),
              entity_id: this.props.match?.params?.id,
              entity_type: this.props.type === "vendor" ? "vendor" : "vendorservice",
              permissions: [{
                section_id: this.props.additionalDataForExternalUser?.sectionId,
                mode: access
              }]
            }
            this.props.addVendorExternalCollaborator(data);
          }}
          isLoading={this.props.externalCollaboratorAddProgress || this.props.sectionExternalUsersProgress}
          onClose={() => {
            this.props.hideSearchVendorExternalUsersPoperDialog();
          }}
        />}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(OrgTabDetails));
