import {HttpFactory} from "../../services/httpService";
import * as Environment from "../../util/Environment"

export const START_USER_SEARCH     =   "START_USER_SEARCH";
export const END_USER_SEARCH       =   "END_USER_SEARCH";

export const START_USER_TEAM_SEARCH     =   "START_USER_TEAM_SEARCH";
export const END_USER_TEAM_SEARCH       =   "END_USER_TEAM_SEARCH";


//Action to start the registration
function startUserSearch(){
    return {
        type:START_USER_SEARCH,
        payload:null
    };
}

function endUserSearch(success,error){
    return {
        type:END_USER_SEARCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function searchUser(query,params={}){
    const api_server = Environment.api_host("USER");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/fetch?t=${timestamp}`;
    var payload = {
        query : query,
        page : params.page !== undefined ? params.page : 0,
        pageSize : params.pageSize !== undefined ? params.pageSize : 10,
    }
    if(params.roles !== undefined){
        payload['roles'] = params.roles;
    }
    
    return async (dispatch) => {
        dispatch(startUserSearch());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,payload);
            dispatch(endUserSearch(response.Data,error));
        } catch(e){
            dispatch(endUserSearch(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startUserTeamSearch(){
    return {
        type:START_USER_TEAM_SEARCH,
        payload:null
    };
}

function endUserTeamSearch(success,error){
    return {
        type:END_USER_TEAM_SEARCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function searchUserTeam(query,params={}){
    const api_server = Environment.api_host("USER");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/fetch/users_and_teams?t=${timestamp}`;
    var payload = {
        query : query,
        page : params.page !== undefined ? params.page : 0,
        pageSize : params.pageSize !== undefined ? params.pageSize : 10,
    }
    if(params.roles !== undefined){
        payload['roles'] = params.roles;
    }
    
    return async (dispatch) => {
        dispatch(startUserTeamSearch());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,payload);
            dispatch(endUserTeamSearch(response,error));
        } catch(e){
            dispatch(endUserTeamSearch(null,{
                message:e.message
            }));
            return;
        }
    };
} 