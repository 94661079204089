import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';
import { createStyles, withStyles } from "@material-ui/core/styles"
import queryString from 'query-string';
import { authorizedUser } from "../../redux/authuser/action";
import { fetchSellerInstruction } from "redux/workflow/action";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
const connectedProps = (state) => ({
  downloadxlsProg: state.evaluationReportData.downloadxlsProg,
  downloadxlsError: state.evaluationReportData.downloadxlsError,
  downloadxlsData: state.evaluationReportData.downloadxlsData,
  authProgress: state.authUser.authProgress,
  authError: state.authUser.authError,
  user: state.authUser.user,

  fetchSellerInstructionProgress: state.workflow.fetchSellerInstructionProgress,
  fetchSellerInstructionError: state.workflow.fetchSellerInstructionError,
  sellerInstruction: state.workflow.sellerInstruction,
});

const connectionActions = {
  authorizedUser: authorizedUser,
  fetchSellerInstruction: fetchSellerInstruction
}
var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      "& [class*='MuiFormLabel-root']": {
        display: 'none'
      },
      "& [class*='MuiInputLabel']": {
        display: 'none',
        marginBottom: '0px'
      },
      "& [data-slate-node='element']": {
        marginTop: 5,
        marginLeft: 8,
        marginBottom: 8,
      }
    },

  });

class InstructionDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: queryString.parse(this.props.location.search),
      instructionData: "",
      fetchDone: false
    };
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentDidMount() {
    var isToken = (this.getParameterByName("token") !== null);
    if (isToken) {
      var token = this.state.queryParams.token;
      if (token !== null) {
        window.localStorage.setItem("authToken", token);
        window.localStorage.setItem("authType", 'JWT');
      }
      this.callInitFunctions();
    }

  }

  callInitFunctions() {
    this.props.authorizedUser();
  }

  componentDidUpdate(prevProps) {
    if (this.props.authProgress === false && prevProps.authProgress === true) {
      console.log(this.props.user, 'Userrr')
      if (this.props.authError !== null) {
        console.log('Not able to Autherized');
      } else {
        if (this.props.user === null) {
          console.log('Not able to find valid user');
        } else {
          console.log('Authentication Done');
          this.props.fetchSellerInstruction(this.props.match.params.evaluation_id, this.props.match.params.component_id)
        }
      }
    }

    if (!this.props.fetchSellerInstructionProgress && prevProps.fetchSellerInstructionProgress) {
      if (this.props.fetchSellerInstructionError === null) {
        this.checkInstruction();
      } else {
        this.checkInstruction();
      }
    }
  }

  checkInstruction() {
    const launcher = document.getElementById('launcher')
    if (launcher) {
      launcher.style.display = 'none'
    }
    const launcherEmbed = document.getElementById('Embed')
    if (launcherEmbed) {
      launcherEmbed.style.display = 'none'
    }
    this.setState({
      instructionData: this.props.sellerInstruction?.Instruction || '',
      fetchDone: true,
    })
    let helpBtn = document.getElementById('Embed');
    if (helpBtn) {
      helpBtn.style.display = 'none'
    }
  }

  render() {
    const { classes } = this.props;
    if (this.state.fetchDone === false) {
      return 'Loading...'
    }

    return (
      <div className={classes.root}>

        <SlateInputField
          readOnly={true}
          isToolBar={false}
          placeholder=""
          style={{
            margin: 0,
          }}
          as={SlateInputField}
          initValue={this.state.instructionData}
          formControlStyle={{ border: 'none', overflow: 'hidden' }}
          textContainerStyle={{ minHeight: '100px !important', margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }}
        />
      </div>

    );
  }
}


export default connector(compose(
  withRouter,
  withStyles(styles)
)(InstructionDownload));