import {
    Dialog, DialogContent, Slide, Grid, Typography, CircularProgress
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";

import OutlinedInput from "Components/Common/Input/OutlinedInput";
import SqureButton from 'Components/Common/SqureButton';
import { showSnackBar } from "redux/snackbar/action";
import Alert from '@material-ui/lab/Alert';

import { hideWorkflowDetailUpdateDialog, updateOrgWorkflow, addOrgWorkflow } from "redux/evaluation/workflow/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.evaluationWorkflow.isWorkflowDetailsUpdateDialogOpen,
    updateOrgWorkFlowProgress: state.evaluationWorkflow.updateOrgWorkFlowProgress,
    addOrgWorkFlowProgress: state.evaluationWorkflow.addOrgWorkFlowProgress,
    addOrgWorkFlowSuccess: state.evaluationWorkflow.addOrgWorkFlowSuccess,
    addOrgWorkFlowError: state.evaluationWorkflow.addOrgWorkFlowError,
});

const connectionActions = {
    hideDialog: hideWorkflowDetailUpdateDialog,
    updateOrgWorkflow: updateOrgWorkflow,
    addOrgWorkflow: addOrgWorkflow,
    showSnackBar: showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    actions: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: "center",
    },
    actionBtn: {
        minWidth: '200px'
    },
    actionBtnDisabled: {
        minWidth: '200px',
    },
    dialogContent: {
        margin: 'auto',
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    workflowForm: {
        padding: 20
    },
    title: {
        textAlign: "center",
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
        margin: '20px 0px'
    },
    alerts:{
        marginBottom:theme.spacing(2),
        width:'100%'
    },
});

class WorkflowDetailsUpdateDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            feedback: "",
            feedback_error: "",
            products: [],
            workflowName: '',
            description: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen === true && prevProps.isOpen === false) {
            if (!this.props.isNew) {
                this.initWorkflowDetails();
            }
        }

        if (this.props.updateOrgWorkFlowProgress === false && prevProps.updateOrgWorkFlowProgress === true) {
            this.props.hideDialog();
        }

        if (!this.props.addOrgWorkFlowProgress && prevProps.addOrgWorkFlowProgress) {
            if (this.props.addOrgWorkFlowSuccess && this.props.addOrgWorkFlowSuccess?.ID) {
                this.props.hideDialog();
                this.props.history.push(`/app/settings/form-builder/default/${this.props.addOrgWorkFlowSuccess?.ID}`)
            } else if (this.props.addOrgWorkFlowError) {
                this.props.showSnackBar(
                    'Workflow is not created',
                    "error",
                    3000
                );
            }
        }
    }

    initWorkflowDetails() {
        this.setState({
            workflowName: this.props.workflowDetails.Name,
            description: this.props.workflowDetails.Description
        })
    }

    updateWorkflow = () => {
        const { workflowDetails } = this.props;
        const { workflowName, description } = this.state;

        var wName = workflowName || "";
        var workflowDescription = description || "";

        if (wName.trim().length === 0) {
            this.setState({
                error: "Please enter workflow name."
            });
            return;
        }
        const update = {
            name: wName,
            description: workflowDescription,
            status: workflowDetails.Status
        };
        this.props.updateOrgWorkflow(workflowDetails.ID, update);
    }

    addWorkflow() {
        const { workflowName, description } = this.state;
        var wName = workflowName || "";
        var workflowDescription = description || "";

        if (wName.trim().length === 0) {
            this.setState({
                error: "Please enter workflow name."
            });
            return;
        }

        const data = {
            name: wName,
            description: workflowDescription,
            type: 1,
            status: 1
        };

        this.props.addOrgWorkflow(data);
    }

    render() {
        const classes = this.props.classes;

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>

                <div className={classes.workflowForm}>
                    {this.props.isNew && <Typography variant={"h4"} className={classes.title}>Add Workflow</Typography>}
                    {!this.props.isNew && <Typography variant={"h4"} className={classes.title}>Update Workflow</Typography>}
                    <Grid container>
                        <div className={classes.alerts}>
                            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                            {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                        </div>
                        <Grid lg={12} md={12} sm={12} xs={12} >
                            <OutlinedInput
                                id={"formTitleInput"}
                                label="Workflow Name"
                                placeholder={"Enter your workflow name"}
                                autoFocus={true}
                                value={this.state.workflowName}
                                onChange={(event) => {
                                    this.setState({
                                        workflowName: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid lg={12} md={12} sm={12} xs={12} >
                            <OutlinedInput
                                id={"formDescInput"}
                                label="Workflow Description"
                                placeholder={"Enter your description here"}
                                value={this.state.description}
                                onChange={(event) => {
                                    this.setState({
                                        description: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div className={classes.actions}>
                        {!this.props.updateOrgWorkFlowProgress && !this.props.addOrgWorkFlowProgress && <div>
                            <SqureButton
                                cls={classes.actionBtn}
                                variant={"outlined"}
                                onClick={() => {
                                    this.props.hideDialog()
                                }}
                            >
                                Cancel
                            </SqureButton>&nbsp;&nbsp;&nbsp;
                            {!this.props.isNew && <SqureButton
                                cls={classes.actionBtn}
                                variant={"contained"}
                                onClick={(e) => {
                                    this.updateWorkflow()
                                }}
                            >
                                Update
                            </SqureButton>}
                            {this.props.isNew && <SqureButton
                                cls={classes.actionBtn}
                                variant={"contained"}
                                onClick={(e) => {
                                    this.addWorkflow()
                                }}
                            >
                                Add
                            </SqureButton>}
                        </div>}

                        {(this.props.updateOrgWorkFlowProgress === true || this.props.addOrgWorkFlowProgress === true) && <div style={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </div>}
                    </div>
                </div>

            </DialogContent>
        </Dialog>
    }
}

WorkflowDetailsUpdateDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(WorkflowDetailsUpdateDialog));