import {
    START_METRIC_VENDOR_FETCH,END_METRIC_VENDOR_FETCH,
    START_VENDOR_REFACTOR_UPDATE,END_VENDOR_REFACTOR_UPDATE,
    START_METRIC_CATEGORY_VENDOR_FETCH,END_METRIC_CATEGORY_VENDOR_FETCH,
} from "./action";

const initState   =     {
    fetchVendorProgress:false,
    vendors:[],
    vendorsTotal: 0,

    fetchMetricCategoryVendorProgress:false,
    metricCategoryVendors:[],
    metricCategoryVendorsTotal: 0,

    updateVendorRefactorError: null,
    updateVendorRefactorProgress: false,
}

export  function metricReducer(state=initState,action){
    switch(action.type){

        case START_METRIC_VENDOR_FETCH: {
            let vendors = state.vendors;
            let vendorsTotal = state.vendorsTotal;
            if(action.payload === 0){
                vendors = [];
                vendorsTotal = 0;
            }
            return {
                ...state,
                fetchVendorProgress:true,
                vendors:vendors,
                vendorsTotal: vendorsTotal
            };
        }
            
        case END_METRIC_VENDOR_FETCH: {
            let success     =   action.payload.success;
            let vendors     =   state.vendors;
            let vendorsTotal = state.vendorsTotal;
            if(success !== null){
                let Page    =   success?.page;
                let Data    =   success?.Data;
                if(Page === 0){
                    vendors     =  Data; 
                } else {
                    vendors     = vendors.concat(Data)
                }
                vendorsTotal = success.total
            }
            return {
                ...state,
                fetchVendorProgress:false,
                vendors:vendors,
                vendorsTotal: vendorsTotal
            }
        }
            

        case START_METRIC_CATEGORY_VENDOR_FETCH: {
            let vendors = state.vendors;
            let vendorsTotal = state.vendorsTotal;
            if(action.payload === 0){
                vendors = [];
                vendorsTotal = 0;
            }
            return {
                ...state,
                fetchMetricCategoryVendorProgress:true,
                metricCategoryVendors:vendors,
                metricCategoryVendorsTotal: vendorsTotal
            };
        }
            

        case END_METRIC_CATEGORY_VENDOR_FETCH: {
            let success     =   action.payload.success;
            let vendors     =   state.vendors;
            let vendorsTotal = success.total;

            if(success !== null){
                let Page    =   success.page;
                let Data    =   success.Data;
                if(Page === 0){
                    vendors     =  Data; 
                } else {
                    vendors     = vendors.concat(Data)
                }
            }
            return {
                ...state,
                fetchMetricCategoryVendorProgress:false,
                metricCategoryVendors:vendors,
                metricCategoryVendorsTotal: vendorsTotal
            }
        }

        case START_VENDOR_REFACTOR_UPDATE:
            return {
                ...state,
                updateVendorRefactorError: null,
                updateVendorRefactorProgress: true,
            };
        case END_VENDOR_REFACTOR_UPDATE:
            return {
                ...state,
                updateVendorRefactorError: action.payload.error,
                updateVendorRefactorProgress: false,
            };
            
        default:
            return state;
    }
}