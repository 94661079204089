import { Button, Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import deleteIcon from "assets/images/delete.svg";
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";
import classnames from "classnames";
import CommonCss from "commonCss";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import Image from "Components/Common/image.jsx";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
// import CreatableSelect from "react-select/creatable";
import { compose } from "recompose";
import axios from "axios";
import * as Environment from "util/Environment";
import { CommonFn } from "services/commonFn";
import { authorizedUser } from "redux/authuser/action";
// Redux
import { hideProductDetailsDialog } from "redux/dialogs/action";
import { updateDrawerStyle } from "redux/drawer/action";
import { fetchCompletedEvaluation, fetchInProgressEvaluation } from "redux/evaluate/action";
import { createEvaluationCart, fetchEvaluationCart, removeProductFromCart } from "redux/evaluation/cart/action";
import { fetchPlatform } from "redux/master/action";
import { addOrgGroup, fetchOrgGroup } from "redux/org/group/action";
import { fetchProducts, fetchRecommendedProducts } from "redux/product/action";
import { fetchProductCategory } from "redux/product/category/action";
import {
  addNewOrgCategories,
  addOrgCategories,
  addOrgFeatures,
  addOrgPlatform,
  addOrgProductGroup,
  addOrgSubCategories,
  deleteOrgCategories,
  deleteOrgFeatures,
  deleteOrgPlatform,
  deleteOrgProductGroup,
  deleteOrgRelatedProducts,
  deleteOrgSubCategories,
  fetchAlternativeProducts,
  fetchCompanyProductDetails,
  fetchComplementaryProducts,
  fetchOrgFeatures,
  fetchOrgPlatform,
  fetchOrgProductAlternatives,
  fetchOrgProductDepartment,
  fetchOrgRelatedProducts,
  fetchOrgSubCategories,
} from "redux/product/orgProduct/action";
import { showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import { fetchProductSubCategory, fetchSubCategoryFeature } from "redux/product/subcategory/action";
import { fetchProductPrice } from "redux/product/orgProduct/action";
import { showSnackBar } from "redux/snackbar/action";
import { searchUser } from "redux/usersearch/action";
import Input from "./Common/Input";
import Email from "./Common/Email";
import Phone from "./Common/Phone";
import Url from "./Common/Url";
import RichText from "./Common/RichText";
import DefaultImage from "assets/images/evaluation/default.svg";
import Number from "./Common/Number";
import { updateOrgVendorTabSectionItemData } from "redux/vendor/vendorCatalog/action";
import { updateOrgServiceTabSectionItemData } from "redux/vendor/serviceCatalog/action";
import { updateOrgVendorServiceTabSectionItemData, deleteOrgVendorService } from "redux/vendor/vendorServiceCatalog/action";
import { addOrgVendor } from "redux/vendor/vendorCatalog/action";
import { deleteProduct } from "redux/product/orgProduct/action";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import CreatableSelect from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

const connectedProps = (state) => ({
  fetchProductAlternativesUnderProgress: state.orgProduct.fetchProductAlternativesUnderProgress,
  productAlternatives: state.orgProduct.productAlternatives,
  fetchCompanyProductsUnderProgress: state.orgProduct.fetchCompanyProductsUnderProgress,
  companyProducts: state.orgProduct.companyProducts,
  evaluationCart: state.evaluationCart.evaluation,
  fetchComplementaryProductsUnderProgress: state.orgProduct.fetchComplementaryProductsUnderProgress,
  complementaryProducts: state.orgProduct.complementaryProducts,
  subCategories: state.productSubCategory.subCategories,
  subCategoriesFetchProgress: state.productSubCategory.fetchSubCategoryProgress,
  categoryFetchProgress: state.productCategory.fetchCategoryProgress,
  categories: state.productCategory.categories,
  subCategoryFeatures: state.productSubCategory.subCategoryFeatures,
  fetchSubCategoryFeaturesProgress: state.productSubCategory.fetchSubCategoryFeaturesProgress,
  products: state.products.recommendedProducts,
  fetchRecommendedProductsProgress: state.products.fetchRecommendedProductsProgress,
  orgProductDetailsUpdateProgress: state.orgProduct.orgProductDetailsUpdateProgress,
  orgProductDetailsFetchProgress: state.orgProduct.orgProductDetailsFetchProgress,
  groups: state.orgGroup.groups,
  fetchGroupProg: state.orgGroup.fetchGroupProg,
  orgProductDepartment: state.orgProduct.orgProductDepartment,
  orgCategories: state.orgProduct.orgCategories,
  orgProductGroupAddProgress: state.orgProduct.orgProductGroupAddProgress,
  orgProductGroupDeleteProgress: state.orgProduct.orgProductGroupDeleteProgress,
  orgFeatures: state.orgProduct.orgFeatures,
  orgFeaturesProgress: state.orgProduct.orgFeaturesProgress,
  orgSubCategories: state.orgProduct.orgSubCategories,
  orgSubCategoriesProgress: state.orgProduct.orgSubCategoriesProgress,
  addOrgSubCategoriesProgress: state.orgProduct.addOrgSubCategoriesProgress,
  deleteOrgSubCategoriesProgress: state.orgProduct.deleteOrgSubCategoriesProgress,
  deleteOrgCategoriesProgress: state.orgProduct.deleteOrgCategoriesProgress,
  addOrgFeaturesProgress: state.orgProduct.addOrgFeaturesProgress,
  deleteOrgFeaturesProgress: state.orgProduct.deleteOrgFeaturesProgress,
  platform: state.master.platform,
  orgPlatform: state.orgProduct.orgPlatform,
  addOrgPlatformProgress: state.orgProduct.addOrgPlatformProgress,
  orgIntegration: state.orgProduct.orgIntegration,
  rejectedIntegrations: state.orgProduct.rejectedIntegrations,
  orgSimilarProduct: state.orgProduct.orgSimilarProduct,
  orgSameProduct: state.orgProduct.orgSameProduct,
  orgComplementaryProduct: state.orgProduct.orgComplementaryProduct,
  orgIntegrationTotal: state.orgProduct.orgIntegrationTotal,
  orgSimilarProductTotal: state.orgProduct.orgSimilarProductTotal,
  orgSameProductTotal: state.orgProduct.orgSameProductTotal,
  orgComplementaryProductTotal: state.orgProduct.orgComplementaryProductTotal,
  orgIntegrationProgress: state.orgProduct.orgIntegrationProgress,
  orgSimilarProductProgress: state.orgProduct.orgSimilarProductProgress,
  orgSameProductProgress: state.orgProduct.orgSameProductProgress,
  orgComplementaryProductProgress: state.orgProduct.orgComplementaryProductProgress,
  addOrgIntegrationProgress: state.orgProduct.addOrgIntegrationProgress,
  addOrgSimilarProductProgress: state.orgProduct.addOrgSimilarProductProgress,
  addOrgSameProductProgress: state.orgProduct.addOrgSameProductProgress,
  addOrgComplementaryProductProgress: state.orgProduct.addOrgComplementaryProductProgress,
  deleteOrgIntegrationProgress: state.orgProduct.deleteOrgIntegrationProgress,
  deleteOrgSimilarProductProgress: state.orgProduct.deleteOrgSimilarProductProgress,
  deleteOrgSameProductProgress: state.orgProduct.deleteOrgSameProductProgress,
  deleteOrgComplementaryProductProgress: state.orgProduct.deleteOrgComplementaryProductProgress,
  userType: state.authUser.user?.Role,
  currentUser: state.authUser.user,
  orgExisingSimilarProducts: state.orgProduct.orgExisingSimilarProducts,
  fetchExistingSimilarProductsProgress: state.orgProduct.fetchExistingSimilarProductsProgress,
  orgAlternativeProducts: state.orgProduct.orgAlternativeProducts,
  fetchAlternativeProductsProgress: state.orgProduct.fetchAlternativeProductsProgress,
  addNewOrgCategoriesProgress: state.orgProduct.addNewOrgCategoriesProgress,
  productPrice: state.orgProduct.productPrice,
  expentDirectoryProducts: state.expentDirectory.products,
  orgServices: state.services.servicesData,
  allServices: state.services.allServices,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  fetchOrgProductAlternatives: fetchOrgProductAlternatives,
  fetchComplementaryProducts: fetchComplementaryProducts,
  fetchCompanyProductDetails: fetchCompanyProductDetails,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
  showSnackBar: showSnackBar,
  hideProductDetailsDialog: hideProductDetailsDialog,
  searchProductChampUser: searchUser,
  fetchCompletedEvaluation: fetchCompletedEvaluation,
  fetchInProgressEvaluation: fetchInProgressEvaluation,
  fetchProductSubCategory: fetchProductSubCategory,
  fetchProductCategory: fetchProductCategory,
  fetchSubCategoryFeature: fetchSubCategoryFeature,
  fetchProducts: fetchProducts,
  fetchRecommendedProducts: fetchRecommendedProducts,
  fetchOrgGroup: fetchOrgGroup,
  addOrgGroup: addOrgGroup,
  addOrgProductGroup: addOrgProductGroup,
  deleteOrgProductGroup: deleteOrgProductGroup,
  fetchOrgProductDepartment: fetchOrgProductDepartment,
  fetchOrgSubCategories: fetchOrgSubCategories,
  fetchOrgFeatures: fetchOrgFeatures,
  addOrgSubCategories: addOrgSubCategories,
  deleteOrgSubCategories: deleteOrgSubCategories,
  addOrgFeatures: addOrgFeatures,
  deleteOrgFeatures: deleteOrgFeatures,
  fetchOrgPlatform: fetchOrgPlatform,
  addOrgPlatform: addOrgPlatform,
  fetchPlatform: fetchPlatform,
  fetchOrgRelatedProducts: fetchOrgRelatedProducts,
  deleteOrgRelatedProducts: deleteOrgRelatedProducts,
  authorizedUser: authorizedUser,
  deleteOrgPlatform: deleteOrgPlatform,
  fetchAlternativeProducts: fetchAlternativeProducts,
  deleteOrgCategories: deleteOrgCategories,
  addOrgCategories: addOrgCategories,
  addNewOrgCategories: addNewOrgCategories,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
  fetchProductPrice: fetchProductPrice,
  updateOrgVendorTabSectionItemData: updateOrgVendorTabSectionItemData,
  updateOrgServiceTabSectionItemData: updateOrgServiceTabSectionItemData,
  updateOrgVendorServiceTabSectionItemData: updateOrgVendorServiceTabSectionItemData,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  addOrgVendor: addOrgVendor,
  deleteOrgVendorService: deleteOrgVendorService,
  deleteProduct: deleteProduct,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
    },
    ...CommonCss.ProductDetails,
    description: {
      ...CommonCss.ProductDetails.description,
      "& p": {
        margin: "0px",
      },
      "& [class*=editor-editableField]": {
        minHeight: "auto !important",
        margin: "0px !important",
      },
      "& [class*=editor-formControl]": {
        border: "none !important",
      },
    },
    tabSection: {},
    formGroup: {
      display: "flex",
      alignItems: "center",
    },
    label: {
      marginBottom: 5,
      display: "block",
    },
    creatable: {
      padding: 0,
    },
    searchAndSelect: {
      marginBottom: "5px",
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
        minHeight: 44,
      },
      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    uploadImageLabel: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    uploadImage: {
      width: 0,
      overflow: "hidden",
    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 19,
      margin: "0px !important",
    },

    productTitleSection: {
      ...CommonCss.productTitleSection,
      marginTop: 40,
      marginBottom: 10,
    },
    ...CommonCss.Home,
    evaluateCard: {
      ...CommonCss.Home.evaluateCard,
      border: "1px solid #ddd",
    },
    iconEle: {
      width: 18,
      marginLeft: 8,
      cursor: "pointer",
      "&:hover": {
        background: "#4a87f8",
        borderRadius: "25px",
        color: "#fff",
      },
    },
    tagClose: {
      display: "flex",
      alignItems: "center",
      padding: "6px 13px",
    },
    addNew: {
      cursor: "pointer",
    },
    contained: {
      color: "#fff",
      padding: "6px 26px",
      backgroundColor: "#4b86f8",
      "&:hover": {
        backgroundColor: "#4b86f8",
      },
    },
    containedCancel: {
      color: "#3C3C3C",
      padding: "5px 26px",
      backgroundColor: "#fff",
      border: "1px solid #3C3C3C",
      marginLeft: 20,
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    tickMark: {
      color: "#4b86f8",
      border: "1px solid #4b86f8",
      marginRight: 10,
      minWidth: "50px !important",
      "&:hover": {
        color: "#4b86f8",
      },
    },
    colorBlue: {
      color: "#4b86f8",
    },
    buttonGroup: {
      alignItems: "center",
      position: "relative",
      zIndex: 1000,
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 6,
      marginBottom: 5,
      cursor: "pointer",
    },
    descriptionText: {
      float: "right",
    },
    supportLabel: {
      fontWeight: 600,
      marginTop: 3,
      marginBottom: 3,
      // minWidth: 129,
      display: "inline-block",
      paddingRight: 10,
    },
    productLink: {
      ...CommonCss.ProductDetails.productLink,
      fontSize: 14,
      marginBottom: 0,
    },
    itemAboutSection: {
      "& p": {
        margin: 0,
      },
    },
    itemSection: {
      border: "1px solid #d8d8d8",
      padding: 17,
      borderRadius: 5,
      background: "#FFF",
      marginBottom: 17,
      position: "relative",
      "& [class*=PricingSection-head]": {
        color: "#6C6C6C",
        fontSize: 18,
        opacity: 1,
        fontWeight: 600,
        margin: 0,
        marginBottom: 11,
      },
    },
    platformEdit: {
      alignItems: "center",
      marginBottom: 20,
    },
    complementary: {
      "& [class*=select__menu]": {
        maxHeight: 150,
      },
    },
    deleteImage: {
      position: "absolute",
      top: 24,
      zIndex: 20,
      right: 23,
      padding: 4,
      cursor: "pointer",
    },
    positionRelative: {
      position: "relative",
    },
    productList: {
      position: "relative",
    },
    slateInputField: {
      "& > div": {
        paddingTop: "0px !important",
        paddingBottom: "30px !important",
      },
    },
    platformTitle: {
      marginTop: 0,
    },
    contentSection: {
      overflow: "unset",
    },
    bottomSpace: {},
    topHead: {
      display: "flex",
      justifyContent: "space-between",
    },
    head: {
      color: "#6C6C6C",
      fontSize: 18,
      opacity: 1,
      fontWeight: 600,
      margin: 0,
      marginBottom: 11,
    },
    nomargin: {
      margin: "0px !important",
    },
    marginTop: {
      marginTop: 10,
    },
    notAvailable: {
      marginTop: 0,
      marginBottom: 28,
    },
    // platformItem:{
    //   // position:"relative",

    // },
    closeIcon: {
      position: "absolute",
      right: "-20px",
      top: 0,
      cursor: "pointer",
    },
    linkItem: {
      cursor: "pointer !important",
    },
    cursorTag: {
      fontSize: "12px",
      borderRadius: "25px",
    },
    // platformName:{
    //   display:"block",
    //   textAlign:"center"
    // },
    pd_root: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 10px 20px #00000036",
      borderRadius: "10px",
      opacity: "1",
      width: "350px",
      minHeight: "150px",
      zIndex: 9999,
      padding: "30px",
      position: "fixed",
      marginTop: "30px",
    },
    pd_headerContainer: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center flex-start",
      alignItems: "center",
    },
    pd_title: {
      textAlign: "left",
      wordBreak: "break-word",
      fontWeight: 500,
      letterSpacing: "0px",
      color: "#20253A",
      opacity: 1,
      margin: "0px",
      marginBottom: "5px",
    },
    pd_subTitle: {
      textAlign: "left",
      fontWeight: 400,
      letterSpacing: "0px",
      color: "#20253A",
      opacity: 0.5,
      margin: "0px",
    },
    pd_descriptionContainer: {
      textAlign: "left",
      fontWeight: 500,
      letterSpacing: "0px",
      color: "#20253A",
      opacity: 0.5,
    },
    pd_action: {
      textAlign: "left",
      letterSpacing: "0px",
      color: "#0093E0 !important",
      opacity: 1,
      cursor: "pointer",
      fontSize: theme.spacing(2.4),
    },
    ellipsis_3: {
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
    },
    close: {
      position: "absolute",
      right: 8,
      top: 8,
      cursor: "pointer",
      color: "#6F6F6F",
      "& svg": {
        stroke: "white",
        fontSize: "24px",
      },
    },
    topIndex: {
      zIndex: 1,
    },
    popover: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        "&::before": {
          top: "50px",
          left: "-16px",
          width: "0",
          borderTop: "8px solid transparent",
          height: "0px",
          content: "''",
          display: "block",
          position: "absolute",
          borderBottom: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid #fff",
        },
      },
    },
    threeDot: {
      position: "absolute",
      zIndex: 11,
      right: 5,
      top: 5,
      padding: 5,
    },
    // listItem: {
    //   paddingTop: theme.spacing(2),
    // },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    menuList: {
      padding: "0px",
      "& > hr": {
        "&:first-child": {
          display: "none",
        },
      },
    },
    proNotBlocked: {
      zIndex: 11,
      color: "#c1c1c1",
    },
  });

class DefaultSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreProducts: false,
      showMoreVendors: false,
      showMoreServices: false,
      showMoreCompanyProducts: false,
      showMoreComplementaryProducts: false,
      showMoreAlternativeProducts: false,
      viewMore: [],
      isCartShow: false,
      champ: [],
      query: "",
      page: 0,
      pageSize: 5,
      inPorgressStatus: [2, 3],
      completedStatus: [4],
      items: [],
      isLogoEdit: false,
      logoText: "",
      isDescription: false,
      description: "",
      isWebsite: false,
      website: "",
      isFacebook: false,
      facebook: "",
      isEmail: false,
      email: "",
      isPhone: false,
      phone: "",
      isLinkedIn: false,
      linkedIn: "",
      isTwitter: false,
      twitter: "",
      isEmpMin: false,
      empMin: "",
      isEmpMax: false,
      empMax: "",
      isNewSubCategory: false,
      isFeaturesOffered: false,
      isBusiness: false,
      isDepartment: false,
      businessText: 0,
      departmentQuery: "",
      departmentText: "",
      integrationText: "",
      vendorNameText: "",
      isVendorNameEdit: false,
      subCategories: [],
      features: [],
      isPlatformEdit: false,
      platform: [],
      isExistingProduct: false,
      isExistingVendor: false,
      isExistingService: false,
      isSameProduct: false,
      isComplementaryProduct: false,
      isAlternativeProduct: false,
      integration: "",
      sameProduct: "",
      existingProduct: "",
      existingService: "",
      complementaryProduct: "",
      isShowSimilarExisting: true,
      alternativeProduct: "",
      rejectedIntegration: false,
      isNewFocus: false,
      isNewClient: false,
      category: "",
      reasonDialogOpenEvent: null,
      reasonDialogOpen: false,
      rejectedProduct: {},
      anchorEl: null,
    };
    this.btnRef = React.createRef();
    this.dialogDetailsRef = React.createRef();
  }

  componentDidMount() {
    this.props.hideProductDetailsDialog();
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if ((!this.props.addOrgFeaturesProgress && prevProps.addOrgFeaturesProgress) || (!this.props.deleteOrgFeaturesProgress && prevProps.deleteOrgFeaturesProgress)) {
      this.fetchOrgFeatures();
    }

    if ((!this.props.addOrgFeaturesProgress && prevProps.addOrgFeaturesProgress) || (!this.props.deleteOrgFeaturesProgress && prevProps.deleteOrgFeaturesProgress)) {
      this.fetchOrgFeatures();
    }

    if (this.props.fetchExistingSimilarProductsProgress === false && prevProps.fetchExistingSimilarProductsProgress === true) {
      if (this.props.orgExisingSimilarProducts === null || this.props.orgExisingSimilarProducts.status === undefined || this.props.orgExisingSimilarProducts.status !== 200) {
        this.setState({
          isShowSimilarExisting: false,
        });
      }
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  fetchOrgFeatures = () => {
    this.props.fetchOrgFeatures(this.props.match?.params?.id);
  };

  fetchOrgGroups = () => {
    this.props.fetchOrgGroup(0, 50, this.state.departmentQuery);
  };

  fetchSubCategoryData = (value = "") => {
    this.props.fetchProductSubCategory(0, 0, 100, value);
  };

  fetchCategoryData = (value = "") => {
    this.props.fetchProductCategory(0, 60, value);
  };

  fetchFeatureData = (value = "") => {
    //source=evaluation-home
    this.props.fetchSubCategoryFeature(0, 0, 100, value);
  };

  componentWillUnmount() {
    // this.props.updateDrawerStyle(true, true);
  }

  getProductsOptions = async (inputValue, loadedOptions, additional) => {
    // console.log(inputValue,loadedOptions,additional);
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("SEARCH");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      field_setname: "all",
      text: inputValue,
      page,
      page_size: 150,
      sort_fields: [],
      filter_fields: {},
      merge_existing_others: true,
    };
    // setPage(page+1)
    let url = `${api_server}/v2/dsl/expent_catalogue?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let products = response?.data?.body?.items || [];
    let total = response?.data?.body?.total || 0;
    products = products.map((m) => {
      m.value = parseInt(m.id);
      m.label = m.name;
      m.isProduct = true;
      m.productStates = m.states;
      m.isDisabled = m.states?.blocked !== undefined || m.states?.existing !== undefined;
      return m;
    });
    return {
      options: products,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  scrollContent = (e) => {
    e.persist();
  };

  viewMore = (value, state) => {
    const { viewMore } = this.state;
    let values = [...viewMore];
    if (state) {
      const index = values.indexOf(value);
      if (index > -1) {
        values.splice(index, 1);
      }
    } else {
      values.push(value);
    }
    this.setState({
      viewMore: values,
    });
  };

  routeChange = (id) => {
    let redirectPath = `/app/evaluate/${id}/`;
    this.props.history.push(redirectPath);
  };

  saveFeaturesOffered = () => {
    const feature_ids = [];
    const feature_names = [];
    this.state.features.map((e) => {
      if (e.__isNew__) {
        feature_names.push(e.value);
      } else {
        feature_ids.push(e.value);
      }
      return e;
    });
    if (this.state.features?.length) {
      this.props.addOrgFeatures(this.props.match?.params?.id, {
        feature_ids: feature_ids,
        feature_names: feature_names,
      });
      this.setState({ isFeaturesOffered: false });
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  };

  deleteOrgCategories = (item) => {
    this.props.deleteOrgCategories(this.props.match?.params?.id, item.ID);
  };

  saveCategory = (productID) => {
    if (this.state.category?.value) {
      // this.props.addOrgCategories(this.props.match?.params?.id,
      //   { category_ids: [this.state.category?.value] }
      // );
      this.props.addNewOrgCategories(this.props.match?.params?.id, { subcategory_id: this.state.category?.value, productID });
      this.setState({ isNewFocus: false, category: "" });
    } else {
      this.props.showSnackBar("Please choose category", "error", 3000);
    }
  };

  deleteOrgFeatures = (item) => {
    this.props.deleteOrgFeatures(this.props.match?.params?.id, item.ID);
  };

  updateStateForItem(item, value) {
    var data = {
      isPlatformEdit: false,
      isNewFocus: false,
      isNewSubCategory: false,
      isNewClient: false,
      isIntegration: false,
      isRejectedIntegration: false,
      isFeaturesOffered: false,
      isExistingProduct: false,
      isExistingVendor: false,
      isExistingService: false,
      isSameProduct: false,
      isComplementaryProduct: false,
      isVendorNameEdit: false,
      isLogoEdit: false,
      isDescription: false,
      isWebsite: false,
      isFacebook: false,
      isEmail: false,
      isPhone: false,
      isLinkedIn: false,
      isTwitter: false,
      isEmpMin: false,
      isEmpMax: false,
    };
    data[item] = value;
    this.setState(data);
  }

  handleSave = (slug, value, is_delete = null) => {
    if (this.props.catalogType === "service") {
      this.props.updateOrgServiceTabSectionItemData(this.props.appDetails?.id, slug, {
        value: value,
        section_id: this.props.section?.id,
        is_delete: is_delete,
      });
    } else if (this.props.catalogType === "vendor") {
      this.props.updateOrgVendorTabSectionItemData(this.props.appDetails?.id, slug, {
        value: value,
        section_id: this.props.section?.id,
        is_delete: is_delete,
      });
    } else if (this.props.catalogType === "vendorservice") {
      this.props.updateOrgVendorServiceTabSectionItemData(this.props.appDetails?.id, slug, {
        value: value,
        section_id: this.props.section?.id,
        is_delete: is_delete,
      });
    }
  };

  render() {
    const classes = this.props.classes;
    const { appDetails, hasUpdateAccess, section } = this.props;
    const { viewMore } = this.state;

    var isCart = this.props.evaluationCart !== null;
    // var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart.Products?.map((p) => p.ID);
    // const subCategoriesName = (this.props.orgCategories && this.props.orgCategories.map((m) => m.Name)) || [];
    // let subCategories = this.props.subCategories.map((m) => ({ value: m.ID, label: m.Name }));
    // let categories = subCategories.filter((d) => !subCategoriesName.includes(d.label));

    let orgProductList = this.props.expentDirectoryProducts?.others?.items?.map((m) => ({
      ...m,
      value: m.id,
      label: m.name,
      isProduct: true,
    }));

    let orgServiceList = this.props.allServices?.items?.map((item) => ({
      ...item,
      label: item.name,
      value: item.master_service_id || item.org_service_id,
    }));

    return (
      <div className={classes.contentSection} onScroll={(e) => this.scrollContent(e)}>
        {section.slug === "about" && (
          <Grid container spacing={3} style={{ padding: "10px 0px" }}>
            {section.items.map((item) => {
              if (item.slug === "name") {
                return (
                  <Grid item sm={12} key={item.id}>
                    <Input
                      label={item.name}
                      placeholder="Enter name"
                      value={appDetails.name || ""}
                      required={true}
                      editAccess={hasUpdateAccess}
                      requiredMessage="Please enter name"
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              }
              // else if(item.slug === "logo"){
              //   return <Grid item sm={6} key={item.id}>
              //   <Url
              //      label="Logo"
              //      placeholder="Enter logo link"
              //      value={appDetails.logo || ''}
              //      editAccess={hasUpdateAccess}
              //      updateValue={(value)=>{
              //       this.handleSave(item.slug, value);
              //      }}
              //   />
              // </Grid>
              // }
              else if (item.slug === "number_emp_min") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Number
                      label={item.name}
                      placeholder="Enter minimum no. of employee"
                      value={appDetails.number_emp_min || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else if (item.slug === "number_emp_max") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Number
                      label={item.name}
                      placeholder="Enter maximum no. of employee"
                      value={appDetails.number_emp_max || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else if (item.slug === "description") {
                return (
                  <Grid item sm={12} key={item.id}>
                    <RichText
                      label={item.name}
                      placeholder="Enter description"
                      value={appDetails.description || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })}
          </Grid>
        )}
        {section.slug === "contact_information" && (
          <Grid container spacing={3} style={{ padding: "10px 0px" }}>
            {section.items.map((item) => {
              if (item.slug === "email") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Email
                      label={item.name}
                      placeholder="Enter Email"
                      value={appDetails.email || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else if (item.slug === "phone_number") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Phone
                      label={item.name}
                      placeholder="Enter phone number"
                      value={appDetails.phone_number || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else if (item.slug === "website") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Url
                      label={item.name}
                      placeholder="Enter website"
                      value={appDetails.website || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })}
          </Grid>
        )}
        {section.slug === "social_handles" && (
          <Grid container spacing={3} style={{ padding: "10px 0px" }}>
            {section.items.map((item) => {
              if (item.slug === "facebook_page") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Url
                      label={item.name}
                      placeholder="Enter facebook page url"
                      value={appDetails.facebook_page || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else if (item.slug === "linkedin_page") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Url
                      label={item.name}
                      placeholder="Enter linkedin page url"
                      value={appDetails.linkedin_page || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else if (item.slug === "twitter_page") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Url
                      label={item.name}
                      placeholder="Enter twitter page url"
                      value={appDetails.twitter_page || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })}
          </Grid>
        )}
        {section.slug === "existing_products" && (
          <>
            <Grid container spacing={3} className={classes.bottomSpace}>
              {appDetails?.existing_products &&
                appDetails?.existing_products?.slice(0, !this.state.showMoreProducts ? 5 : appDetails?.existing_products?.length).map((product, k) => {
                  return (
                    <Grid item key={"existing_" + product.org_product_id} className={classes.productList} lg={isCart ? 3 : 2} md={isCart ? 4 : 3} sm={isCart ? 12 : 6} xs={12}>
                      {hasUpdateAccess && (
                        <Image
                          src={deleteIcon}
                          alt={product.name}
                          className={classes.deleteImage}
                          onClick={() => {
                            this.props.deleteProduct(product.master_product_id);
                          }}
                        />
                      )}
                      <LayeredCard
                        title={product.name}
                        description={product.description}
                        customRoute={"/app/products/org-product-details/"}
                        image={product.product_logo}
                        onClick={() => {}}
                        numLayer={0}
                        showCheckbox={false}
                        id={product.master_product_id}
                        uniqueId={product.master_product_id}
                        type={"product"}
                      />
                    </Grid>
                  );
                })}
              {!this.state.isExistingProduct && (
                <Grid
                  item
                  lg={isCart ? 3 : 2}
                  md={isCart ? 4 : 3}
                  sm={isCart ? 12 : 6}
                  xs={12}
                  onClick={() => {
                    this.updateStateForItem("isExistingProduct", true);
                  }}
                >
                  {hasUpdateAccess && <LayeredCard title={"+Add Product"} onClick={() => {}} numLayer={0} image={AddEvaluationImage} showCheckbox={false} />}
                </Grid>
              )}
            </Grid>
            {appDetails?.existing_products && appDetails?.existing_products.length > 5 && (
              <div style={{ textAlign: "center", padding: "35px 15px" }}>
                <span className={classes.show_more} onClick={() => this.setState({ showMoreProducts: !this.state.showMoreProducts })}>
                  {this.state.showMoreProducts === false ? "Show more" : "Show less"}
                </span>
              </div>
            )}
            {this.state.isExistingProduct && (
              <Grid container spacing={3} className={classnames(classes.buttonGroup, classes.marginTop)}>
                <Grid item sm={6}>
                  <div className={classnames(classes.nomargin)}>
                    <CreatableAsyncPaginate
                      isClearable={false}
                      // formatCreateLabel={(userInput) => `Create '${userInput}'`}
                      isSearchable={true}
                      isMulti={false}
                      createOptionPosition={"first"}
                      classNamePrefix="select"
                      placeholder="Select product"
                      additional={{
                        page: 0,
                      }}
                      isValidNewOption={(input) => input.length > 0}
                      loadOptions={this.getProductsOptions}
                      onChange={(data) => {
                        if (data) {
                          this.setState({ existingProduct: data });
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    className={classes.tickMark}
                    onClick={() => {
                      if (this.state.existingProduct?.value) {
                        let data = {
                          org_vendor_id: appDetails.id,
                          states: appDetails.states,
                          entities: [
                            {
                              master_id: this.state.existingProduct?.value,
                              type: "product",
                              name: this.state.existingProduct?.label,
                              // states: []
                            },
                          ],
                        };
                        this.props.addOrgVendor(data);
                        this.setState({ isExistingProduct: false, existingProduct: "" });
                      } else {
                        this.props.showSnackBar("Please choose product", "error", 3000);
                      }
                    }}
                  >
                    <DoneIcon />
                  </Button>
                  <Button className={classes.tickMark} onClick={() => this.setState({ isExistingProduct: false })}>
                    <CloseIcon />
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {section.slug === "existing_vendors" && (
          <>
            <Grid container spacing={3} className={classes.bottomSpace}>
              {appDetails?.existing_vendors &&
                appDetails?.existing_vendors.slice(0, !this.state.showMoreVendors ? 5 : appDetails?.existing_vendors.length).map((vendor, k) => {
                  return (
                    <Grid item key={"existing_vendor" + vendor.org_vendor_id} className={classes.productList} lg={isCart ? 3 : 2} md={isCart ? 4 : 3} sm={isCart ? 12 : 6} xs={12}>
                      {hasUpdateAccess && (
                        <Image
                          src={deleteIcon}
                          alt={vendor.name}
                          className={classes.deleteImage}
                          onClick={() => {
                            this.props.updateOrgVendorTabSectionItemData(appDetails.id, "existing_vendors", {
                              value: vendor.org_vendor_id,
                              section_id: this.props.section?.id,
                              is_delete: true,
                            });
                          }}
                        />
                      )}
                      <LayeredCard
                        title={vendor.name}
                        customRoute={""}
                        image={vendor.logo?.length > 0 ? vendor.logo : DefaultImage}
                        onClick={() => {
                          this.props.history.push(`/app/vendors/org-vendor-service-details/${vendor.org_vendorservice_id}`);
                        }}
                        numLayer={0}
                        showCheckbox={false}
                        id={vendor.org_vendorservice_id}
                        uniqueId={vendor.org_vendorservice_id}
                        type={"vendor"}
                      />
                    </Grid>
                  );
                })}
              {!this.state.isExistingVendor && (
                <Grid
                  item
                  lg={isCart ? 3 : 2}
                  md={isCart ? 4 : 3}
                  sm={isCart ? 12 : 6}
                  xs={12}
                  onClick={() => {
                    this.updateStateForItem("isExistingVendor", true);
                  }}
                >
                  {hasUpdateAccess && <LayeredCard title={"+Add Vendor"} onClick={() => {}} numLayer={0} image={AddEvaluationImage} showCheckbox={false} />}
                </Grid>
              )}
            </Grid>
            {appDetails?.existing_vendors && appDetails?.existing_vendors.length > 5 && (
              <div style={{ textAlign: "center", padding: "35px 15px" }}>
                <span className={classes.show_more} onClick={() => this.setState({ showMoreVendors: !this.state.showMoreVendors })}>
                  {this.state.showMoreVendors === false ? "Show more" : "Show less"}
                </span>
              </div>
            )}
            {this.state.isExistingVendor && (
              <Grid container spacing={3} className={classnames(classes.buttonGroup, classes.marginTop)}>
                <Grid item sm={6}>
                  <div className={classnames(classes.searchAndSelect, classes.nomargin)}>
                    <Select
                      isClearable
                      className={classnames(classes.formControl, classes.creatable)}
                      isSearchable={true}
                      options={orgProductList}
                      classNamePrefix="select"
                      placeholder="Choose vendor"
                      onChange={(e) => {
                        this.setState({ existingVendor: e });
                      }}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9996 }) }}
                    />
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    className={classes.tickMark}
                    onClick={() => {
                      if (this.state.existingVendor?.value) {
                        let data = {
                          org_vendor_id: appDetails.id,
                          states: appDetails.states,
                          entities: [
                            {
                              master_id: this.state.existingVendor?.value,
                              type: "service",
                              states: [],
                            },
                          ],
                        };
                        this.props.addOrgVendor(data);
                        this.setState({ isExistingVendor: false, existingVendor: "" });
                      } else {
                        this.props.showSnackBar("Please choose product", "error", 3000);
                      }
                    }}
                  >
                    <DoneIcon />
                  </Button>
                  <Button className={classes.tickMark} onClick={() => this.setState({ isExistingVendor: false })}>
                    <CloseIcon />
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {section.slug === "existing_services" && (
          <>
            <Grid container spacing={3} className={classes.bottomSpace}>
              {appDetails?.existing_services &&
                appDetails?.existing_services.slice(0, !this.state.showMoreServices ? 5 : appDetails?.existing_services.length).map((service, k) => {
                  return (
                    <Grid item key={"existing_service" + service.org_vendorservice_id} className={classes.productList} lg={isCart ? 3 : 2} md={isCart ? 4 : 3} sm={isCart ? 12 : 6} xs={12}>
                      {hasUpdateAccess && (
                        <Image
                          src={deleteIcon}
                          alt={service.name}
                          className={classes.deleteImage}
                          onClick={() => {
                            this.props.deleteOrgVendorService(service.id);
                          }}
                        />
                      )}
                      <LayeredCard
                        title={service?.service?.name}
                        customRoute={""}
                        image={service.logo?.length > 0 ? service.logo : DefaultImage}
                        onClick={() => {
                          // this.props.history.push(`/app/vendors/org-vendor-service-details/${service.org_vendorservice_id}`);
                        }}
                        numLayer={0}
                        showCheckbox={false}
                        id={service.org_vendorservice_id}
                        uniqueId={service.org_vendorservice_id}
                        type={"service"}
                      />
                    </Grid>
                  );
                })}
              {!this.state.isExistingService && (
                <Grid
                  item
                  lg={isCart ? 3 : 2}
                  md={isCart ? 4 : 3}
                  sm={isCart ? 12 : 6}
                  xs={12}
                  onClick={() => {
                    this.updateStateForItem("isExistingService", true);
                  }}
                >
                  {hasUpdateAccess && <LayeredCard title={"+Add Service"} onClick={() => {}} numLayer={0} image={AddEvaluationImage} showCheckbox={false} />}
                </Grid>
              )}
            </Grid>
            {appDetails?.existing_services && appDetails?.existing_services.length > 5 && (
              <div style={{ textAlign: "center", padding: "35px 15px" }}>
                <span className={classes.show_more} onClick={() => this.setState({ showMoreServices: !this.state.showMoreServices })}>
                  {this.state.showMoreServices === false ? "Show more" : "Show less"}
                </span>
              </div>
            )}
            {this.state.isExistingService && (
              <Grid container spacing={3} className={classnames(classes.buttonGroup, classes.marginTop)}>
                <Grid item sm={6}>
                  <div className={classnames(classes.searchAndSelect, classes.nomargin)}>
                    <Select
                      isClearable
                      className={classnames(classes.formControl, classes.creatable)}
                      isSearchable={true}
                      options={orgServiceList}
                      classNamePrefix="select"
                      placeholder="Choose service"
                      onChange={(e) => {
                        this.setState({ existingService: e });
                      }}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9996 }) }}
                    />
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    className={classes.tickMark}
                    onClick={() => {
                      if (this.state.existingService?.value) {
                        let data = {
                          org_vendor_id: appDetails.id,
                          states: appDetails.states,
                          entities: [
                            {
                              master_id: this.state.existingService?.value,
                              type: "service",
                              name: this.state.existingService?.label,
                              states: [],
                            },
                          ],
                        };
                        this.props.addOrgVendor(data);
                        this.setState({ isExistingService: false, existingService: "" });
                      } else {
                        this.props.showSnackBar("Please choose service", "error", 3000);
                      }
                    }}
                  >
                    <DoneIcon />
                  </Button>
                  <Button className={classes.tickMark} onClick={() => this.setState({ isExistingService: false })}>
                    <CloseIcon />
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {section.slug === "number_of_projects" && (
          <Grid container spacing={3} style={{ padding: "10px 0px" }}>
            {section.items.map((item) => {
              if (item.slug === "number_of_projects") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Number
                      label={item.name}
                      placeholder="Enter nubmer of projects"
                      value={appDetails.number_of_projects || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })}
          </Grid>
        )}
        {section.slug === "avg_hourly_rate" && (
          <Grid container spacing={3} style={{ padding: "10px 0px" }}>
            {section.items.map((item) => {
              if (item.slug === "min_avg_hourly_rate") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Number
                      label={item.name}
                      placeholder="Enter minimum average hourly rate"
                      value={appDetails.min_avg_hourly_rate || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else if (item.slug === "max_avg_hourly_rate") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Number
                      label={item.name}
                      placeholder="Enter maximum average hourly rate"
                      value={appDetails.max_avg_hourly_rate || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })}
          </Grid>
        )}
        {section.slug === "number_of_clients" && (
          <Grid container spacing={3} style={{ padding: "10px 0px" }}>
            {section.items.map((item) => {
              if (item.slug === "min_number_of_clients") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Number
                      label={item.name}
                      placeholder="Enter minimum number of clients"
                      value={appDetails.min_number_of_clients || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else if (item.slug === "max_number_of_clients") {
                return (
                  <Grid item sm={6} key={item.id}>
                    <Number
                      label={item.name}
                      placeholder="Enter maximum number of clients"
                      value={appDetails.max_number_of_clients || ""}
                      editAccess={hasUpdateAccess}
                      updateValue={(value) => {
                        this.handleSave(item.slug, value);
                      }}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })}
          </Grid>
        )}
        {section.slug === "focus" && (
          <div>
            <ul className={classes.UL}>
              {appDetails?.focus &&
                appDetails?.focus?.slice(0, viewMore.indexOf("Focuses") > -1 ? appDetails?.focus?.length : 5).map((item, k) => {
                  return (
                    <li key={k} style={{ marginRight: "14px" }}>
                      <span className={classnames(classes.tag, classes.tagClose, classes.inverse)}>
                        <span className={classes.colorBlue}>{item?.name} </span>

                        {hasUpdateAccess && (
                          <CloseIcon
                            className={classes.iconEle}
                            onClick={() => {
                              this.handleSave("focus", item.name, true);
                            }}
                          />
                        )}
                      </span>
                    </li>
                  );
                })}
              {appDetails?.focus && appDetails?.focus?.length > 5 && (
                <li key={"viewMore"} style={{ marginRight: "14px" }}>
                  <span
                    className={classnames(classes.tag, classes.view_more)}
                    onClick={() => {
                      this.viewMore("Focuses", viewMore.indexOf("Focuses") > -1);
                    }}
                  >
                    {viewMore.indexOf("Focuses") > -1 ? "- View less" : "+ View more"}
                  </span>
                </li>
              )}
              {!this.state.isNewFocus && hasUpdateAccess && (
                <li
                  onClick={() => {
                    this.updateStateForItem("isNewFocus", true);
                  }}
                >
                  <span className={classnames(classes.tag, classes.tagClose, classes.addNew)}>
                    <span> Add new</span>
                    <CheckIcon className={classes.iconEle} />
                  </span>
                </li>
              )}
            </ul>
            {this.state.isNewFocus && (
              <Grid container spacing={3} className={classes.buttonGroup}>
                <Grid item sm={6}>
                  <div className={classes.searchAndSelect}>
                    <input
                      style={{ padding: 10 }}
                      className={classes.formControl}
                      placeholder={"Enter focus"}
                      onChange={(event) => {
                        this.setState({
                          focus: event.target.value,
                        });
                      }}
                    />
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    className={classes.tickMark}
                    onClick={() => {
                      if (this.state.focus && this.state.focus.length) {
                        this.handleSave("focus", { name: this.state.focus });
                        this.setState({ isNewFocus: false });
                      } else {
                        this.props.showSnackBar("Please enter focus", "error", 3000);
                      }
                    }}
                  >
                    <DoneIcon />
                  </Button>
                  <Button className={classes.tickMark} onClick={() => this.setState({ isNewFocus: false })}>
                    <CloseIcon />
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        )}
        {section.slug === "subcategory" && (
          <div>
            <ul className={classes.UL}>
              {appDetails?.subcategory &&
                appDetails?.subcategory?.slice(0, viewMore.indexOf("SubCategories") > -1 ? appDetails?.subcategory?.length : 5).map((item, k) => {
                  return (
                    <li key={k} style={{ marginRight: "14px" }}>
                      <span className={classnames(classes.tag, classes.tagClose, classes.inverse)}>
                        <span className={classes.colorBlue}>{item?.name} </span>

                        {hasUpdateAccess && (
                          <CloseIcon
                            className={classes.iconEle}
                            onClick={() => {
                              this.handleSave("subcategory", item?.name, true);
                            }}
                          />
                        )}
                      </span>
                    </li>
                  );
                })}
              {appDetails?.subcategory && appDetails?.subcategory?.length > 5 && (
                <li key={"viewMore"} style={{ marginRight: "14px" }}>
                  <span
                    className={classnames(classes.tag, classes.view_more)}
                    onClick={() => {
                      this.viewMore("SubCategories", viewMore.indexOf("SubCategories") > -1);
                    }}
                  >
                    {viewMore.indexOf("SubCategories") > -1 ? "- View less" : "+ View more"}
                  </span>
                </li>
              )}
              {!this.state.isNewSubCategory && hasUpdateAccess && (
                <li
                  onClick={() => {
                    this.updateStateForItem("isNewSubCategory", true);
                  }}
                >
                  <span className={classnames(classes.tag, classes.tagClose, classes.addNew)}>
                    <span> Add new</span>
                    <CheckIcon className={classes.iconEle} />
                  </span>
                </li>
              )}
            </ul>
            {this.state.isNewSubCategory && (
              <Grid container spacing={3} className={classes.buttonGroup}>
                <Grid item sm={6}>
                  <div className={classes.searchAndSelect}>
                    <input
                      style={{ padding: 10 }}
                      className={classes.formControl}
                      placeholder={"Enter subcategory"}
                      onChange={(event) => {
                        this.setState({
                          subcategory: event.target.value,
                        });
                      }}
                    />
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    className={classes.tickMark}
                    onClick={() => {
                      if (this.state.subcategory && this.state.subcategory.length) {
                        this.handleSave("subcategory", { name: this.state.subcategory });
                        this.setState({ isNewSubCategory: false });
                      } else {
                        this.props.showSnackBar("Please enter subcategory", "error", 3000);
                      }
                    }}
                  >
                    <DoneIcon />
                  </Button>
                  <Button className={classes.tickMark} onClick={() => this.setState({ isNewSubCategory: false })}>
                    <CloseIcon />
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        )}
        {section.slug === "client" && (
          <div>
            <ul className={classes.UL}>
              {appDetails?.client &&
                appDetails?.client?.slice(0, viewMore.indexOf("Clients") > -1 ? appDetails?.client?.length : 5).map((item, k) => {
                  return (
                    <li key={k} style={{ marginRight: "14px" }}>
                      <span className={classnames(classes.tag, classes.tagClose, classes.inverse)}>
                        <span className={classes.colorBlue}>{item?.name} </span>

                        {hasUpdateAccess && (
                          <CloseIcon
                            className={classes.iconEle}
                            onClick={() => {
                              this.handleSave("client", { name: item?.name }, true);
                            }}
                          />
                        )}
                      </span>
                    </li>
                  );
                })}
              {appDetails?.client && appDetails?.client?.length > 5 && (
                <li key={"viewMore"} style={{ marginRight: "14px" }}>
                  <span
                    className={classnames(classes.tag, classes.view_more)}
                    onClick={() => {
                      this.viewMore("Clients", viewMore.indexOf("Clients") > -1);
                    }}
                  >
                    {viewMore.indexOf("Clients") > -1 ? "- View less" : "+ View more"}
                  </span>
                </li>
              )}
              {!this.state.isNewClient && hasUpdateAccess && (
                <li
                  onClick={() => {
                    this.updateStateForItem("isNewClient", true);
                  }}
                >
                  <span className={classnames(classes.tag, classes.tagClose, classes.addNew)}>
                    <span> Add new</span>
                    <CheckIcon className={classes.iconEle} />
                  </span>
                </li>
              )}
            </ul>
            {this.state.isNewClient && (
              <Grid container spacing={3} className={classes.buttonGroup}>
                <Grid item sm={6}>
                  <div className={classes.searchAndSelect}>
                    <input
                      style={{ padding: 10 }}
                      className={classes.formControl}
                      placeholder={"Enter client"}
                      onChange={(event) => {
                        this.setState({
                          client: event.target.value,
                        });
                      }}
                    />
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    className={classes.tickMark}
                    onClick={() => {
                      if (this.state.client && this.state.client.length) {
                        this.handleSave("client", { name: this.state.client });
                        this.setState({ isNewClient: false });
                      } else {
                        this.props.showSnackBar("Please enter client", "error", 3000);
                      }
                    }}
                  >
                    <DoneIcon />
                  </Button>
                  <Button className={classes.tickMark} onClick={() => this.setState({ isNewClient: false })}>
                    <CloseIcon />
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(DefaultSection));
