import {EvaluationFactory} from "../../../services/evaluation";
import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment"


export const SHOW_EVALUATION_LINK_DIALOG         =   "SHOW_EVALUATION_LINK_DIALOG";
export const HIDE_EVALUATION_LINK_DIALOG         =   "HIDE_EVALUATION_LINK_DIALOG";

export const START_PARENT_EVALUATION_FETCH         =   "START_PARENT_EVALUATION_FETCH";
export const END_PARENT_EVALUATION_FETCH         =   "END_PARENT_EVALUATION_FETCH";

export const START_CHILD_EVALUATION_FETCH         =   "START_CHILD_EVALUATION_FETCH";
export const END_CHILD_EVALUATION_FETCH         =   "END_CHILD_EVALUATION_FETCH";

export const START_CHILD_EVALUATION_SAVE         =   "START_CHILD_EVALUATION_SAVE";
export const END_CHILD_EVALUATION_SAVE         =   "END_CHILD_EVALUATION_SAVE";

export const START_CHILD_EVALUATION_CREATE         =   "START_CHILD_EVALUATION_CREATE";
export const END_CHILD_EVALUATION_CREATE         =   "END_CHILD_EVALUATION_CREATE";

export  function showEvaluationLinkDialog(){
  return     {
      type:SHOW_EVALUATION_LINK_DIALOG,
      payload:{}
  };
}


export  function hideEvaluationLinkDialog(){
  return     {
      type:HIDE_EVALUATION_LINK_DIALOG,
      payload:{}
  };
}


function startFetchParentEvaluation(){
  return     {
    type:START_PARENT_EVALUATION_FETCH,
    payload:{}
};
}


function endFetchParentEvaluation(success,error){
  return {
      type:END_PARENT_EVALUATION_FETCH,
      payload:{
          success,error
      }
  };
}

export  function fetchParentEvaluation(evId){
  return async (dispatch) => {
      dispatch(startFetchParentEvaluation());
      try{
          let [response,error]    =   await EvaluationFactory.instance().getEvaluation(evId);
          dispatch(endFetchParentEvaluation(response,error));
      } catch(e){
          dispatch(endFetchParentEvaluation(null,{
              message:e.message
          }));
          return;
      }
  };
} 


function startSaveChildEvaluations(){
  return {
      type:START_CHILD_EVALUATION_SAVE,
      payload:{}
  };
}

function endSaveChildEvaluations(success,error){
  return {
      type:END_CHILD_EVALUATION_SAVE,
      payload:{
          success,error
      }
  };
}

export  function saveChildEvaluation(evaluation_id,childEvalutions){
  const api_server = Environment.api_host("EVALUATION");
  const url   = `${api_server}/${evaluation_id}/child_evaluations`;
  return async (dispatch) => {
      dispatch(startSaveChildEvaluations());
      try{
          let [response,error]    =   await HttpFactory.instance().putMethod(url,{
            evaluation_ids:childEvalutions
          });
          dispatch(endSaveChildEvaluations(response,error));
      } catch(e){
          dispatch(endSaveChildEvaluations(null,{
              message:e.message
          }));
          return;
      }
  };
}

function startFetchChildEvaluations(){
  return {
      type:START_CHILD_EVALUATION_FETCH,
      payload:{}
  };
}

function endFetchChildEvaluations(success,error){
  return {
      type:END_CHILD_EVALUATION_FETCH,
      payload:{
          success,error
      }
  };
}

export  function fetchChildEvaluation(evaluation_id){
  const api_server = Environment.api_host("EVALUATION");
  const url   = `${api_server}/${evaluation_id}/child_evaluations`;
  return async (dispatch) => {
      dispatch(startFetchChildEvaluations());
      try{
          let [response,error]    =   await HttpFactory.instance().getMethodV2(url);
          dispatch(endFetchChildEvaluations(response,error));
      } catch(e){
          dispatch(endFetchChildEvaluations(null,{
              message:e.message
          }));
          return;
      }
  };
}

function startCreateChildEvaluations(){
  return {
      type:START_CHILD_EVALUATION_CREATE,
      payload:{}
  };
}

function endCreateChildEvaluations(success,error){
  return {
      type:END_CHILD_EVALUATION_CREATE,
      payload:{
          success,error
      }
  };
}

export  function createChildEvaluation(evaluation_id,param){
  const api_server = Environment.api_host("EVALUATION");
  const url   = `${api_server}/${evaluation_id}/child_evaluations`;
  // const url   = `http://localhost:3005/evaluation/${evaluation_id}/child_evaluations`;
  return async (dispatch) => {
      dispatch(startCreateChildEvaluations());
      try{
          let [response,error]    =   await HttpFactory.instance().postMethod(url,param);
          dispatch(endCreateChildEvaluations(response,error));
      } catch(e){
          dispatch(endCreateChildEvaluations(null,{
              message:e.message
          }));
          return;
      }
  };
}