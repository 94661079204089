import React from "react";

import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter, Link } from "react-router-dom";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { getRequirementType } from "util/RequirementTypeOptions";
import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";
import { CommonFn } from "services/commonFn";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      "& [class*='editableField']": {
        minHeight: "auto !important",
      },
    },
    contactCardRoot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "100%",
      transitionDuration: "0ms",
      "&:hover": {
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
    },
    cardInfo: {
      display: "flex",
      alignItems: "center",
      gap: 15,
    },
    headerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "10px",
    },
    date: {
      color: "rgba(0, 0, 0, 0.54)",
      fontStyle: "italic",
      fontSize: 12,
    },
    user: {
      fontSize: 13,
      color: "rgba(0, 0, 0, 0.54)",
    },
    actionButton: {
      display: "flex",
      alignItems: "center",
      gap: 15,
      marginLeft: 25,
      cursor: "pointer",
      justifyContent: "space-between",
    },
    title: {
      fontSize: 13,
      fontWeight: "500",
      marginTop: 5,
      marginBottom: 5,
      color: "#000000d1",
    },
    textWrap: {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "13px",
      "& [class*='editableField']": {
        "white-space": "normal !important",
      },
      "& [class*='editor-editableField']": {
        // overflow: "hidden",
        // display: "-webkit-box",
        // "-webkit-line-clamp": "4",
        // "-webkit-box-orient": "vertical",
        // minHeight: 'unset !important',
        "& p": {
          marginTop: 0,
          fontSize: 13,
          color: "rgba(0, 0, 0, 0.54)",
        },
      },
    },
    content: {
      paddingBottom: "10px !important",
      width: "100%",
      height: "100%",
      cursor: "pointer",
      fontFamily: "Poppins",
    },
    loader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    link: {
      color: "#4a87f8 !important",
      textDecoration: "underline",
      display: "inline",
    },
  });

class Notes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: timeSince(this.props.response?.updated_at + "Z") + " ago",
    };
  }

  render() {
    const { classes, response, printableAnswer, note, showDelete } = this.props;

    return (
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 15 }}>
        <Card
          variant="outlined"
          className={classes.contactCardRoot}
          onMouseEnter={() => {
            this.setState({ time: CommonFn.formateDateTime(response.updated_at + "Z") });
          }}
          onMouseLeave={() => {
            this.setState({ time: timeSince(response.updated_at + "Z") + " ago" });
          }}
        >
          <CardContent className={classes.content}>
            <div className={classes.headerWrapper}>
              <Typography variant="h6" component="h6" className={classes.title}>
                Answer Type: {getRequirementType([], response?.answer_type)}
              </Typography>
              <div className={classes.cardInfo}>
                <div className={classes.user}>{this.state.time}</div>
                {response.created_by && <div className={classes.user}>by {response.created_by}</div>}
                {response.assessment?.name && (
                  <div className={classes.user}>
                    from{" "}
                    <Link to={`/sellerapp/assessment/${response.assessment?._id}/questions`} className={classes.link}>
                      {" " + response.assessment?.name}
                    </Link>
                  </div>
                )}

                <div className={classes.actionButton}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.onEdit();
                    }}
                  >
                    <Image src={editIcon} />
                  </div>
                  {showDelete && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.onDelete();
                      }}
                    >
                      <Image src={deleteIcon} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Typography variant="h6" component="h6" className={classes.title}>
              Response:
            </Typography>
            <div className={classes.textWrap}>{printableAnswer}</div>
            {response.answer_type !== 1 && response.answer_type !== 7 && (
              <>
                <Typography variant="h6" component="h6" className={classes.title}>
                  Comments:
                </Typography>
                <div className={classes.textWrap}>
                  <SlateReadonlyField initValue={note} />
                </div>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Notes));

function timeSince(date) {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " year";
    } else {
      return Math.floor(interval) + " years";
    }
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " month";
    } else {
      return Math.floor(interval) + " months";
    }
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " day";
    } else {
      return Math.floor(interval) + " days";
    }
  }
  interval = seconds / 3600;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " hour";
    } else {
      return Math.floor(interval) + " hours";
    }
  }
  interval = seconds / 60;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " minute";
    } else {
      return Math.floor(interval) + " minutes";
    }
  }
  return Math.floor(seconds) + " seconds";
}
