import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import * as Validator from "util/Validation";
import {
  Grid, InputAdornment, CircularProgress, IconButton
} from '@material-ui/core';
import classnames from 'classnames';
import Image from "Components/Common/image.jsx";
import ReactSelect, { components } from "react-select";
import OutlinedInput from "../../../../../Common/Input/OutlinedInput";
import SqureButton from 'Components/Common/SqureButton';
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import deleteIcon from "assets/images/delete.svg";
import DatePicker from "react-datepicker";
import CalendarImage from "assets/images/calendar.svg";
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

import {
  fetchWorkflowConditionRules,
  addOrgWorkflowAction,
  updateOrgWorkflowAction,
} from "redux/evaluation/workflow/action";

import { fetchMasterWorkflowRulesConditions } from "redux/master/workflow/action";
import { currencyOptions, currencyCodeMap } from "util/Currency";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const connectedProps = (state) => ({
  fetchWorkflowConditionRulesProgress: state.evaluationWorkflow.fetchWorkflowConditionRulesProgress,
  workflowConditionRules: state.evaluationWorkflow.workflowConditionRules,
  orgWorkFlowCompList: state.evaluationWorkflow.orgWorkFlowCompList,
  orgWorkFlowActionList: state.evaluationWorkflow.orgWorkFlowActionList,
  fetchWorkflowConditionRulesError: state.masterWorkflow.fetchWorkflowConditionRulesError,
  fetchMasterWorkflowConditionRulesProgress: state.masterWorkflow.fetchMasterWorkflowConditionRulesProgress,
  masterWorkflowConditionRules: state.masterWorkflow.masterWorkflowConditionRules,
  masterWorkFlowActionList: state.masterWorkflow.actions,
  masterWorkFlowCompList: state.masterWorkflow.components,
});

const connectionActions = {
  fetchWorkflowConditionRules: fetchWorkflowConditionRules,
  addOrgWorkflowAction: addOrgWorkflowAction,
  updateOrgWorkflowAction: updateOrgWorkflowAction,
  fetchMasterWorkflowRulesConditions: fetchMasterWorkflowRulesConditions
};

var connector = connect(connectedProps, connectionActions);

var currencyStyles = {
  control: styles => ({ ...styles, height: 40 }),
  menuPortal: base => ({ ...base, zIndex: 9998 }),
  option: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  })
};

const styles = (theme) => createStyles({
  decisionRoot: {
    "& ul.answers": {
      listStyle: "none",
      paddingLeft: 0,
      "& li": {
        border: "1px solid #EBEBEB",
        bordeRadius: "4px",
        paddingLeft: "8px",
        marginBottom: "8px",
        "& span": {
          fontSize: 13,
          color: "#6D7278",
        },
      },
    },
    "& [class*='editableField']": {
      background: '#fff',
      maxHeight: 'auto !important',
      minHeight: 'auto !important'
    }
  },
  input: {
    minHeight: 38,
    padding: "2px 8px",
    border: "1px solid #cccccc",
    borderRadius: 3,
    fontSize: 15,
    color: "rgb(51, 51, 51)",
    width: '100%'
  },
  loader: {
    padding: theme.spacing(4),
    textAlign: "center"
  },
  addRuleBtn: {
    marginTop: 20
  },
  ruleElements: {
    marginTop: 20,
    marginBottom: 20,
    // border: '1px solid #3f4e6b',
    // borderRadius: '4px',
    // padding: 10
  },
  indexNumber: {
    width: 10,
    marginTop: 5,
  },
  inputSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15
  },
  requiredField: {
    color: "red",
    marginLeft: 5,
  },
  inputController: {
    flex: 1,
    marginRight: 5,
  },
  formTypeController: {
    marginLeft: 5,
    flex: 1,
    marginRight: 5,
  },
  image: {
    marginTop: 5,
    minWidth: 10,
    cursor: "pointer",
  },
  saveBtnSection: {
    position: "absolute",
    bottom: 0,
    right: 12,
    left: "auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: '#f1f1f1',
    padding: '10px'
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  addMore: {
    fontSize: "12px",
    color: "#4175DF",
    cursor: "pointer",
    display: "inline-block",
    marginTop: 10,
  },
  operand: {
    minWidth: 87,
  },
  ruleTable: {
    width: "100%",
    '& [class*=MuiSelect-root]': {
      minHeight: 22
    }
  },
  ruleRightElement: {
  },
  ruleLeftElement: {
  },
  condition: {
    marginTop: 8,
    fontSize: 14,
    fontWeight: 500
  },
  op2: {
    width: "12%",
    '& [class*=MuiInputBase-formControl]': {
      width: 90,
      marginRight: 10,
    },
  },
  op1: {
    width: "12%",
    marginRight: 5,
    // '& [class*=MuiInputBase-formControl]': {
    //   width: 90,
    //   marginRight: 10,
    // }
    '& [class*=Outlined-formControl]': {
      minWidth: '100%',
      marginRight: 10,
    }
  },
  leftFormOp: {
    width: "22%",
  },
  leftOp: {
    width: "22%",
  },
  rightOp: {
    width: "22%",
    marginLeft: 5,
    "& [class*=select__menu]": {
      maxHeight: 100,
    },
  },
  action: {
    width: '10%',
    textAlign: 'right'
  },
  alerts: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  ruleHead: {
    fontSize: 16,
    fontWeight: 500
  },
  "@global": {
    '.react-datepicker__input-container > div': {
      margin: 0,
    },
    '.react-datepicker__input-container [class*=MuiInputBase-formControl]': {
      minHeight: 39,
      margin: 0
    },
    '.react-datepicker__input-container [class*=MuiOutlinedInput-input]': {
      padding: 0,
      fontSize: 14,
      paddingLeft: 10,
      color: 'rgb(51, 51, 51)'
    }
  },
  decisionSection: {
    height: '68vh',
    overflow: 'auto'
  },
  itemTitle: {
    fontWeight: 400,
    color: '#282D30',
    fontSize: 14,
    margin: '30px 0px 0px'
  },
  creatable: {
    padding: 0
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 16
  },
  searchAndSelect: {
    padding: 0,
    "& .select__control, & .select__control:hover": {
      border: 'none',
      height: 'inherit',
      boxShadow: 'none',
      minHeight: 44
    },
    "& .select__multi-value, & .select__multi-value__label": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20,
      padding: theme.spacing(.3, .6),
      fontSize: 13
    },
    "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20
    },
  },
});

class DecisionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowRules: [],
      connectedEdges: [],
      ruleElements: [],
      ruleElementMap: {},
      typeOptions: [],
      typeOptionMap: {},
      success: '',
      error: '',
      isShowLoader: true,
      alreadyAddedEdges: [],
      selectedEdge: '',
      isShowAddRuleButton: false,
    }
    this.addNewEdgeRules = this.addNewEdgeRules.bind(this);
    this.handleChangeEdge = this.handleChangeEdge.bind(this);
    this.addNewRuleForEdge = this.addNewRuleForEdge.bind(this);
    this.deleteRuleForEdge = this.deleteRuleForEdge.bind(this);
    this.onRightChange = this.onRightChange.bind(this);
    this.operad1Change = this.operad1Change.bind(this);
    this.onLeftChange = this.onLeftChange.bind(this);
    this.operand2Change = this.operand2Change.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.saveDecisionRules = this.saveDecisionRules.bind(this);
    this.getLeftExpression = this.getLeftExpression.bind(this)
    // this.handleHeaderClick = this.handleHeaderClick.bind(this);
  }

  componentDidMount() {
    this.getTargetComponents();
    // alert(1)
    if (this.props.isMasterWorkflow) {
      this.props.fetchMasterWorkflowRulesConditions(this.props.workflowId, this.props.componentId);
    } else {
      this.props.fetchWorkflowConditionRules(this.props.workflowId, this.props.componentId);
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchWorkflowConditionRulesProgress === false && prevProps.fetchWorkflowConditionRulesProgress === true) {
      this.initConditions();
    }

    if (this.props.fetchMasterWorkflowConditionRulesProgress === false && prevProps.fetchMasterWorkflowConditionRulesProgress === true) {
      this.initConditions();
    }
  }

  initConditions() {
    var formOptions = [];
    var objectOptions = [];
    var typeOptionMap = {};

    let conditionRules;
    const { isMasterWorkflow } = this.props;
    if (isMasterWorkflow) {
      conditionRules = this.props.masterWorkflowConditionRules;
    } else {
      conditionRules = this.props.workflowConditionRules;
    }
    conditionRules?.forEach(function (item) {
      if (item.is_form) {
        formOptions.push({
          label: item.form_name,
          value: item.form_name,
        })
      } else {
        objectOptions.push({
          label: item.form_name,
          value: item.form_name,
        })
      }
      typeOptionMap[item.form_name] = [];
      item.elements.forEach(function (elem) {
        typeOptionMap[item.form_name].push(elem);
      });
    });

    var groupedOptions = [
      {
        // label: "Object",
        options: objectOptions
      },
      {
        label: "Forms",
        options: formOptions
      }
    ]
    this.setState({
      workflowRules: isMasterWorkflow ? this.props.masterWorkflowConditionRules : this.props.workflowConditionRules,
      typeOptions: groupedOptions,
      typeOptionMap: typeOptionMap
    }, () => {
      this.checkExistingActions();
    })
  }

  getTargetComponents() {
    var selectedComponenentId = parseInt(this.props.componentId);
    const { isMasterWorkflow } = this.props;

    var connectedEdges;
    if (isMasterWorkflow) {
      connectedEdges = this.props.masterWorkFlowActionList.filter(o => o.SourceComponentID === selectedComponenentId);
    } else {
      connectedEdges = this.props.orgWorkFlowActionList.filter(o => o.SourceComponentID === selectedComponenentId);
    }

    var connectEdgeData = connectedEdges.map((item) => {

      let component;
      if (isMasterWorkflow) {
        component = this.getMasterComponentObjectFromId(item.TargetComponentID);
      } else {
        component = this.getComponentObjectFromId(item.TargetComponentID);
      }

      var obj = {
        id: item.ID,
        componentName: component.Name,
        label: component.Name,
        value: item.ID,
        componentData: component,
        ruleElements: isMasterWorkflow ? item.MasterWorkflowActionRuleElements : item.OrgWorkflowActionRuleElements,
        source: item.SourceComponentID,
        target: item.TargetComponentID,
        actinData: item.ActionData,
        OrgWorkflowActionRuleElements: isMasterWorkflow ? item.MasterWorkflowActionRuleElements : item.OrgWorkflowActionRuleElements
      }
      return obj;
    })
    var selectedEdge = connectEdgeData[0];
    this.setState({
      connectedEdges: connectEdgeData,
      selectedEdge: selectedEdge
    }, () => {
    })
  }

  getLeftExpression(data) {
    var value = data.value;
    var array = data.formData;
    const leftObj = array.find((item) => item.condition === value)
    if (leftObj) {
      return {
        value: leftObj.condition,
        label: leftObj.name,
        type: leftObj.type,
        data: Validator.isJson(leftObj.additional_data) && JSON.parse(leftObj.additional_data)
      }
    }
    return null;
  }

  getRightExpression(data) {
    var value = data.value;
    var leftObj = data.leftData;
    if (leftObj.type === 1) {
      return value;
    } else if (leftObj.type === 2) {
      return new Date(value);
    } else if (leftObj.type === 3) {
      if (leftObj.data) {
        return leftObj.data.find((item) => item.value === value)
      }
    } else if (leftObj.type === 4) {
      if (leftObj.data) {

        if (value.includes('[')) {
          const convertedValue = Validator.isJson(value) ? JSON.parse(value) : []
          return leftObj.data.filter((item) => (Array.isArray(convertedValue) && convertedValue.indexOf(item.value) !== -1));
        }
        return leftObj.data.find((item) => parseInt(item.value) === parseInt(value))
      }
    } else if (leftObj.type === 5) {
      if (leftObj.data) {
        const convertedValue = Validator.isJson(value) ? JSON.parse(value) : []
        return leftObj.data.filter((item) => (Array.isArray(convertedValue) && convertedValue.indexOf(item.value) !== -1));
      }
    } else if (leftObj.type === 16) {
      const convertedValue = Validator.isJson(value) ? JSON.parse(value) : {}
      return convertedValue
    } else {
      return value;
    }
    return null;
  }

  checkExistingActions() {
    var me = this;
    const { connectedEdges, workflowRules, typeOptionMap } = this.state;
    const { isMasterWorkflow } = this.props;
    var formComponentList = [];
    var componentMap = {};
    workflowRules.forEach(function (wr) {
      wr.elements.forEach(function (ele) {
        formComponentList.push(ele);
        componentMap[ele.condition] = wr.form_name;
      });
    });

    var newRuleElementMap = {};
    var alreadyAddedEdges = [];
    connectedEdges.forEach(function (edge) {
      var ruleElements = isMasterWorkflow ? edge.OrgWorkflowActionRuleElements : edge.OrgWorkflowActionRuleElements;
      if (ruleElements !== undefined && ruleElements !== null && ruleElements.length > 0) {
        const rules = [];
        ruleElements.forEach((element) => {
          if (element.OpID === 1 || element.OpID === 2) {
            rules[rules.length - 1].op2 = element.OpID;
          } else {
            let leftData = me.getLeftExpression({ value: element.LeftExpression, formData: formComponentList });
            if (leftData !== null) {
              var rulee = leftData.value;
              var ruleSelectedType = componentMap[rulee];
              rules.push({
                leftOp: leftData,
                op1: element.OpID,
                op2: '',
                rightOP: me.getRightExpression({ value: element.RightExpression, leftData: leftData }),
                selectedType: {
                  label: ruleSelectedType,
                  value: ruleSelectedType
                }
              })
            }
          }
        });
        var rulee = rules.length > 0 ? rules[0].leftOp.value : '';
        var type = componentMap[rulee];
        var options = typeOptionMap[type];
        var reqOptions = {};

        if (options !== undefined) {
          reqOptions = options.map((itm) => {
            var obj = {
              value: itm.condition,
              label: itm.name,
              type: itm.type,
              data: Validator.isJson(itm.additional_data) && JSON.parse(itm.additional_data),
              operations: itm.operations,
            }
            return obj;
          });
        }
        alreadyAddedEdges.push(edge.id);

        var newItem = {
          connectedEdges: connectedEdges,
          selectedEdge: {
            label: edge.componentName,
            value: edge.id
          },
          selectedType: {
            label: type,
            value: type
          },
          rules: rules,
          isShowTypeDrpDown: true,
          isShowRules: true,
          requirementOptions: reqOptions
        }
        newRuleElementMap[edge.id] = newItem;
      }
    });
    this.setState({
      // ruleElements: newRuleElements,
      ruleElementMap: newRuleElementMap,
      alreadyAddedEdges: alreadyAddedEdges,
      isShowLoader: false
    }, () => {
      this.checkSelectedEdgeActions();
    })
  }

  checkSelectedEdgeActions() {
    const { selectedEdge, ruleElementMap } = this.state;
    var ruleElements = [];
    if (selectedEdge !== undefined && selectedEdge !== null && selectedEdge !== '' && ruleElementMap[selectedEdge.id] !== undefined) {
      ruleElements.push(ruleElementMap[selectedEdge.id]);
      this.setState({
        ruleElements: ruleElements
      })
    } else {
      this.setState({
        isShowAddRuleButton: true,
        ruleElements: ruleElements
      })
    }
  }

  addNewEdgeRules() {
    var ruleElements = [...this.state.ruleElements];
    var newItem = {
      connectedEdges: this.state.connectedEdges,
      selectedEdge: this.state.selectedEdge,
      selectedType: '',
      rules: [{
        "op2": '1',
        "leftOp": null,
        "op1": '3',
        "rightOP": null,
        "selectedType": '',
      }],
      isShowTypeDrpDown: false,
      isShowRules: false,
      requirementOptions: []
    }
    ruleElements.push(newItem);
    this.setState({
      ruleElements: ruleElements
    })
  }

  handleChangeEdge(selectedEdge, index) {
    this.setState({
      selectedEdge: selectedEdge
    }, () => {
      this.checkSelectedEdgeActions();
    })
  }

  customGroupHeading(props) {
    function handleHeaderClick(id) {
      const node = document.querySelector(`#${id}`).parentElement
        .nextElementSibling;
      const classes = node.classList;
      if (classes.contains("collapsed")) {
        node.classList.remove("collapsed");
      } else {
        node.classList.add("collapsed");
      }
    };
    return (
      <div
        className="group-heading-wrapper"
        onClick={() => {
          handleHeaderClick(props.id)
        }}
      >
        <components.GroupHeading {...props} />
      </div>
    );
  };

  getComponentObjectFromId = (id) => {
    return this.props.orgWorkFlowCompList && this.props.orgWorkFlowCompList.find((item) => item.ID === parseInt(id));
  }

  getMasterComponentObjectFromId = (id) => {
    return this.props.masterWorkFlowCompList && this.props.masterWorkFlowCompList.find((item) => item.ID === parseInt(id));
  }

  addNewRuleForEdge(index) {
    var ruleElements = [...this.state.ruleElements];
    var rules = ruleElements[index].rules;
    var newRule = {
      "op2": '1',
      "leftOp": null,
      "op1": '3',
      "rightOP": null,
      'selectedType': '',
      "requirementOptions": []
    }
    rules.push(newRule);
    this.setState({
      ruleElements: ruleElements
    })
  }

  deleteRuleForEdge(index, ruleIndex) {
    var ruleElements = [...this.state.ruleElements];
    var rules = ruleElements[index].rules;
    rules.splice(ruleIndex, 1);
    ruleElements[index].rules = rules;
    this.setState({
      ruleElements: ruleElements
    })
  };

  handleChangeType(selectedType, index, k) {
    var ruleElements = [...this.state.ruleElements];
    var rules = ruleElements[index].rules;
    rules[k].selectedType = selectedType;
    rules[k].leftOp = null;
    console.log(rules[k], 'Rules[k]')
    rules[k].rightOP = null;
    var options = this.state.typeOptionMap[selectedType.label];
    var reqOptions = options.map((itm) => {
      var obj = {
        value: itm.condition,
        label: itm.name,
        type: itm.type,
        data: Validator.isJson(itm.additional_data) && JSON.parse(itm.additional_data),
        operations: itm.operations,
      }
      return obj;
    });
    ruleElements[index].requirementOptions = reqOptions;
    this.setState({
      ruleElements: ruleElements
    })
  }

  onLeftChange(index, k, value) {
    var ruleElements = [...this.state.ruleElements];
    var rule = ruleElements[index].rules[k];
    rule.leftOp = value;
    console.log(rule, 'Rule')
    if (value && value.type === 16) {
      rule.rightOP = {
        type: {
          "label": "USD",
          "value": "USD"
        }, value: 0
      };
    } else {
      rule.rightOP = null;
    }
    rule.op1 = value?.operations?.length > 0 ? value.operations[0] : null;
    this.setState({
      ruleElements: ruleElements
    })
  }

  onRightChange(index, k, value) {
    var ruleElements = [...this.state.ruleElements];
    var rule = ruleElements[index].rules[k];
    rule.rightOP = value;
    this.setState({
      ruleElements: ruleElements
    })
  }

  handleChangeCurrencyValue(field, value, index, k) {
    var ruleElements = [...this.state.ruleElements];
    var rule = ruleElements[index].rules[k];
    rule.rightOP[field] = value;
    this.setState({
      ruleElements: ruleElements
    })
  }

  operad1Change(index, k, e) {
    var value = '';
    if (e.target?.value) {
      value = e.target.value.toString();
    } else if (!e.target?.value) {
      value = '';
    }
    var ruleElements = [...this.state.ruleElements];
    var rule = ruleElements[index].rules[k];
    rule.op1 = value;
    if (rule.leftOp && rule.leftOp.type === 16) {
      rule.rightOP = {
        type: {
          "label": "USD",
          "value": "USD"
        }, value: 0
      }
    } else {
      rule.rightOP = null;
    }
    this.setState({
      ruleElements: ruleElements
    })
  }

  operand2Change(index, k, e) {
    var value = '';
    if (e.target?.value) {
      value = e.target.value.toString();
    } else if (!e.target?.value) {
      value = '';
    }
    var ruleElements = [...this.state.ruleElements];
    var rule = ruleElements[index].rules[k];
    rule.op2 = value;
    this.setState({
      ruleElements: ruleElements
    })
  }

  saveDecisionRules() {
    this.setState({
      success: '',
      error: ''
    })

    const { ruleElements } = this.state;

    ruleElements.forEach((ruleElem, index) => {
      const request = [];
      let errorMessage = '';
      let seq = 1;

      var targetComponent = ruleElem.connectedEdges.filter(o => o.id === ruleElem.selectedEdge.value)[0];
      var targetComponentId = targetComponent.target;


      ruleElem.rules.forEach((ruleElem, k) => {
        if (errorMessage.length > 0) {
          return;
        }

        if (!ruleElem.leftOp) {
          errorMessage = `Error Row ${k + 1}: please select rule.`;
          return;
        }

        if (!ruleElem?.op2 && k > 2) {
          errorMessage = `Error Row ${k + 1}: please select oprand`;
          return;
        }

        if (!ruleElem.op1) {
          errorMessage = `Error Row ${k + 1}: please select oprand`;
          return;
        }

        if (!ruleElem.rightOP || ruleElem.rightOP?.length === 0) {
          errorMessage = `Error Row ${k + 1}: please enter/select option.`;
          return;
        }


        let rightExp;
        if (ruleElem.leftOp.type === 16) {
          rightExp = JSON.stringify(ruleElem.rightOP)
        } else {
          rightExp = !Array.isArray(ruleElem.rightOP) ? (ruleElem.rightOP?.value ? ruleElem.rightOP?.value : ruleElem.rightOP) : JSON.stringify(ruleElem.rightOP.map((i) => {
            return i.value
          }));
          if (ruleElem.leftOp.type === 4 || ruleElem.leftOp.type === 19 || ruleElem.leftOp.type === 16) {
            rightExp = rightExp.toString();
          }
        }

        request.push({
          left_expression: ruleElem.leftOp.value,
          op_id: parseInt(ruleElem.op1),
          right_expression: rightExp,
          seq: seq
        })
        seq++;

        if (k !== 0) {
          request.push({
            left_expression: 'E=' + (seq - 2),
            op_id: parseInt(ruleElem?.op2),
            right_expression: 'E=' + (seq - 1),
            seq: seq
          })
          seq++;
        }
      })

      this.setState({
        error: errorMessage
      });
      if (!errorMessage) {
        console.log(request, 'Requestttt')
        // submitData(request);
        var data = {
          source: this.props.componentId,
          target: targetComponentId,
        }
        this.updateEdgeToWorkflow(ruleElem.selectedEdge.value, data, request);
      }
    })
  }

  updateEdgeToWorkflow = (actionId, data, rule) => {
    const addEdge = {
      source_component_id: parseInt(data.source),
      target_component_id: parseInt(data.target),
      action_data: '',
      seq: this.props.orgWorkFlowActionList?.length > 0 ? this.props.orgWorkFlowActionList?.length + 1 : 1,
      rules: rule
    }
    this.props.updateOrgWorkflowAction(this.props.workflowId, actionId, addEdge);
  }

  render() {
    const classes = this.props.classes;
    let {
      ruleElements, connectedEdges,
      typeOptions, error, success,
      isShowLoader,
      selectedEdge, isShowAddRuleButton } = this.state;

    const operand = [
      {
        value: 3,
        label: "===",
      },
      {
        value: 4,
        label: "in",
      },
      {
        value: 5,
        label: ">",
      },
      {
        value: 6,
        label: ">=",
      },
      {
        value: 7,
        label: "<",
      },
      {
        value: 8,
        label: "<=",
      },
      {
        value: 9,
        label: "!in",
      },
      {
        value: 10,
        label: "!=",
      },
    ];

    if (isShowLoader) {
      return <div className={classes.loader}>
        <CircularProgress />
      </div>
    }
    console.log(ruleElements, 'RuleElementsFinal')
    return (
      <div className={classes.decisionSection}>
        <div className={classes.decisionRoot}>
          <div className={classes.alerts}>
            {error.length > 0 && <Alert variant="filled" severity="error">{error}</Alert>}
            {success.length > 0 && <Alert variant="filled" severity="success">{success}</Alert>}
          </div>

          <ReactSelect
            isSearchable={true}
            menuPosition='fixed'
            options={connectedEdges}
            classNamePrefix="select"
            placeholder="Select Edge"
            onChange={(value) => {
              this.handleChangeEdge(value);
            }}
            value={selectedEdge}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),

              container: (baseStyles, state) => ({
                ...baseStyles,
                boxShadow: 'none',
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '44px',
              }),
            }}
          />

          {ruleElements.map((item, index) => {
            return <div key={index} className={classes.ruleElements}>
              {<table className={classes.ruleTable}>
                <tbody>
                  {item.rules.map((rule, k) => {
                    return (
                      <React.Fragment key={k}>
                        <tr className={classes.inputSection}>
                          <td className={classes.op2}>
                            {k !== 0 && (
                              <div>
                                <OutlinedSelectInput
                                  value={rule?.op2}
                                  options={[
                                    {
                                      value: 1,
                                      label: "And",
                                    },
                                    {
                                      value: 2,
                                      label: "Or",
                                    },
                                  ]}
                                  noMargin={true}
                                  compact={true}
                                  onChange={(e) => {
                                    this.operand2Change(index, k, e)
                                  }}
                                />
                              </div>
                            )}
                            {k === 0 && (
                              <div className={classes.condition}>Condition</div>
                            )}
                          </td>
                          <td className={classnames(classes.formTypeController, classes.leftFormOp)}>
                            <ReactSelect
                              options={typeOptions}
                              blurInputOnSelect={false}
                              closeMenuOnSelect={true}
                              placeholder="Select Type"
                              components={{ GroupHeading: this.customGroupHeading }}
                              value={rule.selectedType}
                              onChange={(value) => {
                                this.handleChangeType(value, index, k);
                              }}
                            />
                          </td>
                          <td className={classnames(classes.inputController, classes.leftOp)}>
                            <ReactSelect
                              isClearable
                              menuPosition='fixed'
                              isSearchable={true}
                              options={item.requirementOptions}
                              classNamePrefix="select"
                              placeholder="Search rule"
                              onChange={(e) => {
                                this.onLeftChange(index, k, e);
                              }}
                              value={rule.leftOp}
                            />
                          </td>
                          <td className={classes.op1}>
                            <OutlinedSelectInput
                              placeholder='Operand'
                              value={rule.op1}
                              // options={operand}
                              options={item.requirementOptions !== undefined && rule.leftOp !== null ? operand.filter(o => item.requirementOptions.filter(j => j.label === rule.leftOp.label)[0]?.operations.indexOf(o.value) !== -1) : operand}
                              noMargin={true}
                              compact={true}
                              onChange={(e) => {
                                this.operad1Change(index, k, e)
                              }}
                            />
                          </td>
                          <td className={classnames(classes.ruleRightElement, classes.rightOp)}>
                            {!rule?.leftOp?.type && (
                              <div>
                                <input
                                  placeholder='Enter Option'
                                  className={classes.input}
                                />
                              </div>
                            )}
                            {(rule?.leftOp?.type === 1 || rule?.leftOp?.type === 18 || rule?.leftOp?.type === 12 || rule?.leftOp?.type === 13 || rule?.leftOp?.type === 12 || rule?.leftOp?.type === 15) && <div>
                              <input
                                className={classes.input}
                                placeholder='Enter Option'
                                onChange={(e) => {
                                  this.onRightChange(index, k, e.target?.value || '')
                                }}
                                value={rule.rightOP}
                              />
                            </div>}

                            {rule?.leftOp?.type === 2 &&
                              <>
                                <DatePicker
                                  selected={rule.rightOP}
                                  onChange={date => {
                                    this.onRightChange(index, k, new Date(date))
                                  }}
                                  dateFormat="MMM do yyyy"
                                  portalId="evMetaDialog"
                                  minDate={null}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={
                                    <OutlinedInput
                                      readOnlyInput={true}
                                      startAdornment={
                                        <InputAdornment>
                                          <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                        </InputAdornment>
                                      }
                                    />
                                  }
                                />
                              </>
                            }

                            {rule?.leftOp?.type === 16 &&
                              <div className={classes.currencyTypeFields} style={{ display: 'flex' }}>
                                <div style={{ width: 200, marginRight: 5, zIndex: 6565 }}>
                                  <ReactSelect
                                    isMulti={false}
                                    labelId="singleSelect-select-label"
                                    id="singleSelect-select"
                                    value={rule.rightOP && rule.rightOP.type ? rule.rightOP.type : {
                                      "label": "USD",
                                      "value": "USD"
                                    }}
                                    onChange={(e) => {
                                      this.handleChangeCurrencyValue('type', e, index, k)
                                    }}
                                    options={currencyOptions}
                                    placeholder={"Select a Option"}
                                    styles={currencyStyles}
                                  >
                                  </ReactSelect>
                                </div>

                                <CurrencyTextField
                                  variant="outlined"
                                  value={rule.rightOP && rule.rightOP.value ? rule.rightOP.value : '0'}
                                  currencySymbol={currencyCodeMap[rule?.rightOP?.value]}
                                  style={{ textAlign: 'left', height: 40 }}
                                  outputFormat="string"
                                  decimalCharacter="."
                                  digitGroupSeparator=","
                                  onChange={(e, v) => {
                                    this.handleChangeCurrencyValue('value', v, index, k)
                                  }}
                                />
                              </div>
                            }

                            {(rule?.leftOp?.type === 3 || rule?.leftOp?.type === 4 || rule?.leftOp?.type === 5 || rule?.leftOp?.type === 19 || rule?.leftOp?.type === 20) && <div>
                              <ReactSelect
                                isClearable
                                isSearchable={true}
                                isMulti={rule?.leftOp?.type === 5 || rule?.leftOp?.type === 20 ? true : (rule?.leftOp?.type === 4 && (parseInt(rule?.op1) === 4 || parseInt(rule?.op1) === 9) ? true : false)}
                                menuPosition='fixed'
                                options={rule?.leftOp?.data}
                                classNamePrefix="select"
                                placeholder="Search Option"
                                onChange={(e) => {
                                  this.onRightChange(index, k, e)
                                }}
                                value={rule.rightOP}
                              />
                            </div>}

                          </td>
                          <td className={classes.action}>
                            {<span hidden={item.rules.length === 1}> <IconButton size="small" onClick={() => { this.deleteRuleForEdge(index, k) }}>
                              <Image alt="Delete" src={deleteIcon} />
                            </IconButton> </span>}
                            {<span hidden={k !== item.rules.length - 1}><IconButton size="small" onClick={() => { this.addNewRuleForEdge(index) }}>
                              <AddCircleIcon />
                            </IconButton>  </span>}
                          </td>
                        </tr>
                        {rule?.leftOp?.type === "TextArea" && <tr>
                          <div>
                            <SlateInputField
                              // folderPath={`/workflow/${params.id}/`}
                              as={SlateInputField}
                              onChangeEvent={(value) => {
                                // onRightChange(k, value)
                              }}
                              initValue={rule.rightOP}
                              textContainerStyle={{ color: "rgb(51, 51, 51)" }} />
                          </div>
                        </tr>}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>}
            </div>
          })}

          {this.props.isMasterWorkflow === false && isShowAddRuleButton && ruleElements.length <= 0 && connectedEdges.length > 0 && <Grid container justify="center" className={classes.addRuleBtn}>
            <SqureButton
              variant={"contained"}
              onClick={() => {
                this.addNewEdgeRules();
              }}
            >
              + Add Rule
            </SqureButton>
          </Grid>}

        </div>
        {this.props.isMasterWorkflow === false && <div className={classes.saveBtnSection}>
          <button
            type="button"
            className={classes.newUserBtn}
            onClick={() => this.saveDecisionRules()}> Save</button>
        </div>}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(DecisionComponent));