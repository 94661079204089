export const visibilityType = [
  { label: "None", value: 0 },
  { label: "Admin", value: 1 },
  { label: "End User", value: 2 },
  { label: "Both", value: 3 },
];

// items types
export const itemTypes = [
  { label: "Simple Text", value: 1 },
  { label: "Rich Text", value: 11 },
  { label: "Number", value: 19 },
  { label: "Date", value: 2 },
  { label: "Binary", value: 3 },
  { label: "Email", value: 12 },
  { label: "Send Email", value: 16 },
  { label: "Phone", value: 13 },
  { label: "URL", value: 14 },
  { label: "Single Select", value: 4 },
  { label: "Multi Select", value: 5 },
  { label: "Checklist", value: 15 },
  { label: "Table ", value: 6 },
  { label: "Users", value: 7 },
  { label: "Teams", value: 8 },
  { label: "File", value: 9 },
  { label: "Products", value: 10 },
  { label: "Workflow", value: 17 },
  { label: "Task", value: 18 },
];

// ID = 1 String -  ( =, != , in , !in )
// ID = 19 Number -  ( =, != , in , !in , > , < , >= , =< )
// Currency -  ( =, != , in , !in , > , < , >= , =< )
// ID = 2 Date -  ( =, != , > , < , >= , =< )
// ID = 3 Binary - ( = )
// Radio -  ( in , !in )
// ID = 4 Single Select -  ( in , !in )
// Check Box -  ( in , !in )
// ID = 5 Multi Select -  ( in , !in )

export const typeWithConditionsMap = {
  1: [
    {
      value: 3,
      label: "===",
    },
    {
      value: 10,
      label: "!=",
    },
  ],
  19: [
    {
      value: 3,
      label: "===",
    },
    {
      value: 5,
      label: ">",
    },
    {
      value: 6,
      label: ">=",
    },
    {
      value: 7,
      label: "<",
    },
    {
      value: 8,
      label: "<=",
    },
    {
      value: 10,
      label: "!=",
    },
  ],
  2: [
    {
      value: 3,
      label: "===",
    },
    {
      value: 5,
      label: ">",
    },
    {
      value: 6,
      label: ">=",
    },
    {
      value: 7,
      label: "<",
    },
    {
      value: 8,
      label: "<=",
    },
    {
      value: 10,
      label: "!=",
    },
  ],
  3: [
    {
      value: 3,
      label: "===",
    },
  ],
  4: [
    {
      value: 4,
      label: "in",
    },
    {
      value: 9,
      label: "!in",
    },
  ],
  5: [
    {
      value: 4,
      label: "in",
    },
    {
      value: 9,
      label: "!in",
    },
  ],
};

export const binaryOptions = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

export const taskTypes = [
  { label: "Task", value: 1 },
  { label: "Risk", value: 3 },
  { label: "Follow Up", value: 2 },
];
