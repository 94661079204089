import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";
import * as Validator from "util/Validation";

export const START_NOTIFICATION_N_FETCH     =   "START_NOTIFICATION_N_FETCH";
export const END_NOTIFICATION_N_FETCH       =   "END_NOTIFICATION_N_FETCH";

export const START_NOTIFICATION_N_MARKREAD     =   "START_NOTIFICATION_N_MARKREAD";
export const END_NOTIFICATION_N_MARKREAD       =   "END_NOTIFICATION_N_MARKREAD";

export const START_NOTIFICATION_N_DELETE     =   "START_NOTIFICATION_N_DELETE";
export const END_NOTIFICATION_N_DELETE       =   "END_NOTIFICATION_N_DELETE";

export const START_NOTIFICATION_All_MARKREAD     =   "START_NOTIFICATION_All_MARKREAD";
export const END_NOTIFICATION_All_MARKREAD       =   "START_NOTIFICATION_All_MARKREAD";

export const ADD_NEW_NOTIFICATION     =   "ADD_NEW_NOTIFICATION";

export const SHOW_NUDGE_NOTIFICATION_DIALOG = "SHOW_NUDGE_NOTIFICATION_DIALOG";
export const HIDE_NUDGE_NOTIFICATION_DIALOG = "HIDE_NUDGE_NOTIFICATION_DIALOG";

export const START_NOTIFICATION_ADD = "START_NOTIFICATION_ADD";
export const END_NOTIFICATION_ADD = "END_NOTIFICATION_ADD";

export function showNudgeNotificationDialog(user,data){
    return {
        type:SHOW_NUDGE_NOTIFICATION_DIALOG, 
        payload:{user,data}
    };
  }
  
export function hideNudgeNotificationDialog(success,error){
    return {
        type:HIDE_NUDGE_NOTIFICATION_DIALOG,
        payload:{
        }
    };
  }


function startNotificationFetch(page=0){
  return {
      type:START_NOTIFICATION_N_FETCH, 
      payload:page
  };
}

function endNotificationFetch(success,error){
  return {
      type:END_NOTIFICATION_N_FETCH,
      payload:{
          success,error
      }
  };
}

export  function fetchNotifications(params={}){
  const api_server = Environment.api_host("NOTIFICATION");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/fetch?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startNotificationFetch());
      try{
          let [response,error]    =   await HttpFactory.instance().postMethod(url,params,false);
          dispatch(endNotificationFetch(response,error));
      } catch(e){
          dispatch(endNotificationFetch(null,{
              message:e.message
          }));
          return;
      }
  };
} 

function startNotificationMarkAsRead(){
  return {
      type:START_NOTIFICATION_N_MARKREAD,
      payload:{}
  };
}

function endNotificationMarkAsRead(success,error,notificationId){
  return {
      type:END_NOTIFICATION_N_MARKREAD,
      payload:{
          success,error,notificationId
      }
  };
}

export  function markAsRead(notificationId){
  const api_server = Environment.api_host("NOTIFICATION");
  const timestamp     =   (new Date()).getTime();

  const url   =   `${api_server}/read/${notificationId}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startNotificationMarkAsRead());
      try{
          let [response,error]    =   await HttpFactory.instance().putMethod(url,{});
          dispatch(endNotificationMarkAsRead(response,error,notificationId));
      } catch(e){
          dispatch(endNotificationMarkAsRead(null,{
              message:e.message
          }));
          return;
      }
  };
} 

function startNotificationMarkAllAsRead(){
    return {
        type:START_NOTIFICATION_All_MARKREAD,
        payload:{}
    };
  }
  
  function endNotificationMarkAllAsRead(success,error){
    return {
        type:END_NOTIFICATION_All_MARKREAD,
        payload:{
            success,error
        }
    };
  }
  
  export  function markAllAsRead(notificationId){
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp     =   (new Date()).getTime();
  
    const url   =   `${api_server}/notifications/${notificationId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startNotificationMarkAllAsRead());
        try{
            let [response,error]    =   await HttpFactory.instance().putMethod(url,{});
            dispatch(endNotificationMarkAllAsRead(response,error,notificationId));
        } catch(e){
            dispatch(endNotificationMarkAllAsRead(null,{
                message:e.message
            },notificationId));
            return;
        }
    };
  } 

function startNotificationDelete(){
  return {
      type:START_NOTIFICATION_N_DELETE,
      payload:{}
  };
}

function endNotificationDelete(success,error){
  return {
      type:END_NOTIFICATION_N_DELETE,
      payload:{
          success,error
      }
  };
}

export  function deleteNotification(notificationId){
  const api_server = Environment.api_host("NOTIFICATION");
  const timestamp     =   (new Date()).getTime();

  const url   =   `${api_server}/notifications/${notificationId}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startNotificationDelete());
      try{
          let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
          dispatch(endNotificationDelete(response,error));
      } catch(e){
          dispatch(endNotificationDelete(null,{
              message:e.message
          }));
          return;
      }
  };
} 


export function addNewNotification(toastNotification){
    return {
        type:ADD_NEW_NOTIFICATION,
        payload:{
            toastNotification : Validator.isJson(toastNotification) && JSON.parse(toastNotification)
        }
    };
  }
  

  function startNotificationAdd(page=0){
    return {
        type:START_NOTIFICATION_ADD, 
        payload:page
    };
  }
  
  function endNotificationAdd(success,error){
    return {
        type:END_NOTIFICATION_ADD,
        payload:{
            success,error
        }
    };
  }
  
  export  function addNotification(params={}){
    const api_server = Environment.api_host("NOTIFICATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/add?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startNotificationAdd());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,params,false);
            dispatch(endNotificationAdd(response,error));
        } catch(e){
            dispatch(endNotificationAdd(null,{
                message:e.message
            }));
            return;
        }
    };
  } 