import {
    START_FETCH_DOCUMENT_TYPE, END_FETCH_DOCUMENT_TYPE,
    START_ADD_DOCUMENT_TYPE, END_ADD_DOCUMENT_TYPE,
    START_UPDATE_DOCUMENT_TYPE, END_UPDATE_DOCUMENT_TYPE,
    START_DELETE_DOCUMENT_TYPE, END_DELETE_DOCUMENT_TYPE,
    START_ADD_DOCUMENT_TYPE_COLLABORATORS, END_ADD_DOCUMENT_TYPE_COLLABORATORS,
} from "./action";

const initState = {
    fetchDocTypesProgress: false,
    fetchDocTypesError: null,
    types: [],

    addDocTypeProgress: false,
    addDocTypeError: null,
    addDocTypeSuccess: null,

    updateDocTypeProgress: false,
    updateDocTypeError: null,
    updateDocTypeSuccess: null,

    deleteDocTypeProgress: false,
    deleteDocTypeError: null,
    deleteDocTypeSuccess: null,

    addDocTypeColloboratorsProgress: false,
    addDocTypeColloboratorsError: null,
    addDocTypeColloboratorsSuccess: null,

}

export function documentTypeReducer(state = initState, action) {
    switch (action.type) {
        case START_FETCH_DOCUMENT_TYPE:
            return {
                ...state,
                fetchDocTypesProgress: true,
                fetchDocTypesError: null,
            }
        case END_FETCH_DOCUMENT_TYPE:
            return {
                ...state,
                fetchDocTypesProgress: false,
                fetchDocTypesError: action.payload.error,
                types: action.payload.success,
            }

        case START_ADD_DOCUMENT_TYPE:
            return {
                ...state,
                addDocTypeProgress: true,
                addDocTypeError: null,
                addDocTypeSuccess: null,
            }
        case END_ADD_DOCUMENT_TYPE:
            return {
                ...state,
                addDocTypeProgress: false,
                addDocTypeError: action.payload.error,
                addDocTypeSuccess: action.payload.success,
            }

        case START_UPDATE_DOCUMENT_TYPE:
            return {
                ...state,
                updateDocTypeProgress: true,
                updateDocTypeError: null,
                updateDocTypeSuccess: null,
            }
        case END_UPDATE_DOCUMENT_TYPE:
            return {
                ...state,
                updateDocTypeProgress: false,
                updateDocTypeError: action.payload.error,
                updateDocTypeSuccess: action.payload.success,
            }

        case START_DELETE_DOCUMENT_TYPE:
            return {
                ...state,
                deleteDocTypeProgress: true,
                deleteDocTypeError: null,
                deleteDocTypeSuccess: null,
            }
        case END_DELETE_DOCUMENT_TYPE:
            return {
                ...state,
                deleteDocTypeProgress: false,
                deleteDocTypeError: action.payload.error,
                deleteDocTypeSuccess: action.payload.success,
            }

        case START_ADD_DOCUMENT_TYPE_COLLABORATORS:
            return {
                ...state,
                addDocTypeColloboratorsProgress: true,
                addDocTypeColloboratorsError: null,
                addDocTypeColloboratorsSuccess: null,
            }

        case END_ADD_DOCUMENT_TYPE_COLLABORATORS:
            return {
                ...state,
                addDocTypeColloboratorsProgress: false,
                addDocTypeColloboratorsError: action.payload.error,
                addDocTypeColloboratorsSuccess: action.payload.success,
            }
        default:
            return state;
    }
}




