import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import AppBarV2 from "Components/Application/AppBarV2";
import { Grid, CircularProgress, Typography, FormControl, Select, MenuItem } from "@material-ui/core";

import classnames from "classnames";
import CommonCss from "commonCss";
import { CommonFn } from 'services/commonFn';

import deleteIcon from "assets/images/delete.svg";

// Redux
import { fetchCompletedEvaluation, fetchInProgressEvaluation } from "redux/seller/evaluate/action";

import Image from 'Components/Common/image';
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { deleteProductReview } from "redux/product/orgProduct/action";
import { showSnackBar } from "redux/snackbar/action";
import { fetchProductDetailsPage } from "redux/product/action";

import { setSelectedRequirement} from "redux/seller/action";

const connectedProps = (state) => ({
  inProgressEvaluationProgress: state.sellerEvaluate.inProgressEvaluationProgress,
  inProgressEvaluationError: state.sellerEvaluate.inProgressEvaluationError,
  inProgress: state.sellerEvaluate.inProgress,
  inProgressTotal: state.sellerEvaluate.inProgressTotal,
  completedEvaluationProgress: state.sellerEvaluate.completedEvaluationProgress,
  completed: state.sellerEvaluate.completed,
  completedTotal: state.sellerEvaluate.completedTotal,
  deleteProductReviewProgress: state.orgProduct.deleteProductReviewProgress,
  deleteProductReviewError: state.orgProduct.deleteProductReviewError,
  userType: state.authUser.user?.Role,
  fetchProductDetailsUnderProgress: state.products.fetchProductDetailsUnderProgress,
  fetchProductDetailsUnderError: state.products.fetchProductDetailsUnderError,
  productDetails: state.products.productDetails,
});

const connectionActions = {
  fetchCompletedEvaluation: fetchCompletedEvaluation,
  fetchInProgressEvaluation: fetchInProgressEvaluation,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  deleteProductReview: deleteProductReview,
  showSnackBar: showSnackBar,
  fetchProductDetailsPage: fetchProductDetailsPage,
  setSelectedRequirement:setSelectedRequirement
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    topContainer: {
    },
    bottomContainer: {
      // marginTop: theme.spacing(5),
    },
    title: {
      fontSize: theme.spacing(2.4),
      fontWeight: 500
    },
    mainLoader: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 19,
      margin: "0px !important",
    },

    productTitleSection: {
      ...CommonCss.productTitleSection,
      marginTop: 40,
      marginBottom: 10,
    },
    ...CommonCss.Home,
    btnReview: {
      width: '38px',
      height: '32px',
      borderRadius: '5px',
      cursor: 'pointer',
      textAlign: 'center',
      fontSize: '13px',
      color: '#626675',
      paddingTop: 2,
      zIndex: 12,
      position: 'relative',
      border: '1px solid #EEEEEE',
      background: '#ffffff'
    },
    evaluateCard: {
      ...CommonCss.Home.evaluateCard,
      alignItems: 'center'
    },
    content: {
      background: "#F7F7F7",
    },
    rootContainer: CommonCss.rootContainer,
    mainData: {
      minHeight: '100vh'
    },
    topSection: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    namePart: {
      flex: 1
    },
    filterPart: {
      textAlign: 'right'
    }
  });

class Evaluations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteId: null,
      query: "",
      page: 0,
      pageSize: 1000,
      inPorgressStatus: [1, 2, 3],
      completedStatus: [4],
      items: [],
      isCartShow: false,
      productId: parseInt(this.props.match.params.productId),
      total: 0,
      evaDatas: [],
      selectedType: 1
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.props.setSelectedRequirement(null);
    this.props.fetchProductDetailsPage(this.props.match.params.productId)
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.deleteProductReviewProgress && prevProps.deleteProductReviewProgress) {
      if (!this.props.deleteProductReviewError) {
        this.fetchData();
      } else {
        this.props.showSnackBar(this.props.deleteProductReviewError, "error", 3000);
      }
    }

    if (!this.props.inProgressEvaluationProgress && prevProps.inProgressEvaluationProgress) {
      if (this.props.inProgressEvaluationError === null) {
        this.setState({
          total: this.props.inProgressTotal,
          evaDatas: this.props.inProgress
        })
      } else {
        alert('Something went wrong')
      }
    }

  }

  fetchData() {
    let params = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      status: this.state.selectedType === 1 ? this.state.inPorgressStatus : this.state.completedStatus,
      type: [1],
      productID: parseInt(this.props.match.params.productId)
    }
    console.log(params,'fetchInProgressEvaluation')
    // this.props.fetchCompletedEvaluation({ page: this.state.page, pageSize: this.state.pageSize, status: this.state.completedStatus, type: [1] });
    this.props.fetchInProgressEvaluation(params);
  }

  routeChange = (item) => {
    let redirectPath = `/sellerapp/assessment/${item.UUID}/${item.BuyerOrgID}/${parseInt(this.props.match.params.productId)}`;
    this.props.history.push(redirectPath);
  }

  deleteReview(e, id) {
    e.stopPropagation();
    var me = this;
    this.setState({ deleteId: id }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteProductReview(this.state.deleteId);
    }
  };


  render() {
    const classes = this.props.classes;

    // if (this.props.inProgressEvaluationProgress || this.props.completedEvaluationProgress) {
    //   return (<div className={classes.mainLoader}><CircularProgress /></div>)
    // }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let userRole = ["OrgAdmin","OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;

    console.log(this.props.inProgress, 'InProgressData')
    console.log(this.state.productId, 'this.state.productId')

    const { productDetails } = this.props;

    return (
      <div>
        <AppBarV2
          withBack={true}
          title={productDetails !== undefined && productDetails !== null ? productDetails?.Name : "Evaluate"}
        />
        <div className={classes.rootContainer}>
          <div className={classes.mainData}>
            <div className={classes.topSection}>
              <div className={classes.namePart}>
                <Typography className={classes.title}>{this.state.selectedType === 1 ? 'In Progress Evaluations' : 'Completed Evaluations'} ({this.props.inProgressTotal.toLocaleString("en-US")})</Typography> 
              </div>
              <div className={classes.filterPart}>
                {/* <Typography className={classes.title}>Filters</Typography> */}
                <FormControl variant="outlined" className={classes.formControl} size="small">
                  <Select
                    value={this.state.selectedType}
                    onChange={(e) => {
                      this.setState({ 
                        selectedType: e.target.value 
                      },()=>{
                        this.fetchData()
                      });
                    }}>
                    <MenuItem value={1} style={{ fontSize: 13 }}>In Progress</MenuItem>
                    <MenuItem value={2} style={{ fontSize: 13 }}>Completed</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            {this.props.inProgressEvaluationProgress && <div className={classes.mainLoader}><CircularProgress /></div>}

            {!this.props.inProgressEvaluationProgress && this.props.inProgress && <Grid container className={classnames(classes.content)}>
              {this.props.inProgress.map((item, index) => {
                return <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                  <div className={classes.evaluateCard} key={index} onClick={() => this.routeChange(item)}>
                    <div className={classes.evaluateText}>
                      <p className={classes.evaluateCardTitle}>{item.EvaluationName}</p>
                      <p className={classes.evaluateCardSubTitle}>Updated At: {CommonFn.formateDate(item.UpdatedAt)}</p>
                    </div>
                    <div className={classes.evaluateLogo}>
                      <div className={classes.productLogoContainer}>
                        <div className={classes.productLogo} title={item.EvaluationName}>
                          <Image src={item.ProductLogo} alt={item.EvaluationName} title={item.EvaluationName} />
                        </div>
                      </div>
                      {/* {adminFlag && <button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={(e) => this.deleteReview(e, item.ID)}><Image
                        src={deleteIcon}
                        alt="forgotpassword_image"
                        className={classes.titleImage}
                      /></button>} */}
                    </div>
                  </div>
                </Grid>
              })}
            </Grid>}

            {!this.props.inProgressEvaluationProgress && this.props.inProgress?.length === 0 && <div style={{textAlign:'center'}}>
              <span className={classes.noMoreData}>No more data </span>
            </div>
            }
          </div>
        </div>

        <MessageDialog messageData={messageData} callBack={this.callBack} />
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(Evaluations));
