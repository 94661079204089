import {
  SHOW_ADD_ALERT_DIALOG, HIDE_ADD_ALERT_DIALOG,
  START_ADD_NEW_ALERT, END_ADD_NEW_ALERT,
  START_UPDATE_ALERT, END_UPDATE_ALERT,
  START_FETCH_ALERTS, END_FETCH_ALERTS,
  START_DELETE_ALERT, END_DELETE_ALERT,
  START_FETCH_ALERT_DATA, END_FETCH_ALERT_DATA,
  START_ADD_NEW_ALERT_TEAM, END_ADD_NEW_ALERT_TEAM,
  START_FETCH_ALERT_TEAMS, END_FETCH_ALERT_TEAMS,
  START_DELETE_ALERT_TEAM, END_DELETE_ALERT_TEAM,
  START_ADD_NEW_ALERT_USER, END_ADD_NEW_ALERT_USER,
  START_FETCH_ALERT_USERS, END_FETCH_ALERT_USERS,
  START_DELETE_ALERT_USER, END_DELETE_ALERT_USER,
  START_ADD_NEW_ALERT_EMAIL, END_ADD_NEW_ALERT_EMAIL,
  START_FETCH_ALERT_EMAILS, END_FETCH_ALERT_EMAILS,
  START_DELETE_ALERT_EMAIL, END_DELETE_ALERT_EMAIL,
  START_ADD_NEW_ALERT_RULE, END_ADD_NEW_ALERT_RULE,
  START_UPDATE_ALERT_RULE, END_UPDATE_ALERT_RULE,
  START_FETCH_ALERT_RULES, END_FETCH_ALERT_RULES,
  START_DELETE_ALERT_RULE, END_DELETE_ALERT_RULE,
} from "./action";


// Initial State
const initState = {
  dialogAddAlertOpen: false,

  addNewAlertProgress: false,
  addNewAlertError: null,
  newTaskData: null,

  updateAlertProgress: false,
  updateAlertError: null,
  updatedTaskData: null,

  fetchAlertsProgress: false,
  fetchAlertsError: null,
  alertDatas: [],

  deleteAlertProgress: false,
  deleteAlertError: null,

  fetchAlertProgress: false,
  fetchAlertError: null,
  alertDetails: {},

  addAlertTeamProgress: false,
  addAlertTeamError: null,

  fetchAlertTeamProgress: false,
  fetchAlertTeamError: null,
  alertTeams: [],

  deleteAlertTeamProgress: false,
  deleteAlertTeamError: null,

  addAlertUserProgress: false,
  addAlertUserError: null,

  fetchAlertUserProgress: false,
  fetchAlertUserError: null,
  alertUsers: [],

  deleteAlertUserProgress: false,
  deleteAlertUserError: null,

  addAlertEmailProgress: false,
  addAlertEmailError: null,

  fetchAlertEmailProgress: false,
  fetchAlertEmailError: null,
  alertEmails: [],

  deleteAlertEmailProgress: false,
  deleteAlertEmailError: null,

  addAlertRuleProgress: false,
  addAlertRuleError: null,

  deleteAlertRuleProgress: false,
  deleteAlertRuleError: null,

  updateAlertRuleProgress: false,
  updateAlertRuleError: null,

  fetchAlertRuleProgress: false,
  fetchAlertRuleError: null,

  alertRules:null
}


export function alertReducers(state = initState, action) {
  switch (action.type) {

    case SHOW_ADD_ALERT_DIALOG:
      return {
        ...state,
        dialogAddAlertOpen: true,
      };

    case HIDE_ADD_ALERT_DIALOG:
      return {
        ...state,
        dialogAddAlertOpen: false,
      };

    case START_ADD_NEW_ALERT:
      return {
        ...state,
        addNewAlertProgress: true,
        addNewAlertError: null,
        newTaskData: null
      }

    case END_ADD_NEW_ALERT:
      return {
        ...state,
        addNewAlertProgress: false,
        addNewAlertError: action.payload.error,
        newTaskData: action.payload.success
      }

    case START_UPDATE_ALERT:
      return {
        ...state,
        updateAlertProgress: true,
        updateAlertError: null,
        updatedTaskData: null,
      }

    case END_UPDATE_ALERT:
      return {
        ...state,
        updateAlertProgress: false,
        updateAlertError: action.payload.error,
        updatedTaskData: action.payload.success,
      }

    case START_FETCH_ALERTS:
      return {
        ...state,
        fetchAlertsProgress: true,
        fetchAlertsError: null,
      }

    case END_FETCH_ALERTS:
      let AlertsDatas = state.alertDatas;
      let successFetchData = action.payload.success;
      if (action.payload.error === null) {
        if (successFetchData.page === 0) {
          AlertsDatas = successFetchData
        } else {
          AlertsDatas.Data = [...AlertsDatas.Data, ...successFetchData.Data]
        }
      }

      return {
        ...state,
        fetchAlertsProgress: false,
        fetchAlertsError: action.payload.error,
        alertDatas: AlertsDatas
      }

    case START_DELETE_ALERT:
      return {
        ...state,
        deleteAlertProgress: true,
        deleteAlertError: null,
      }

    case END_DELETE_ALERT:
      const { alertId } = action.payload;
      var delErrorData = action.payload.error;
      let alertDatas = state.alertDatas;

      if (delErrorData === null) {
        var pDatas = alertDatas.Data;
        var ptaskIndex = pDatas.findIndex(o => o.ID === alertId);
        alertDatas.Data.splice(ptaskIndex, 1);
      }

      return {
        ...state,
        deleteAlertProgress: false,
        deleteAlertError: action.payload.error,
        alertDatas: alertDatas,
      }

    case START_FETCH_ALERT_DATA:
      return {
        ...state,
        fetchAlertProgress: true,
        fetchAlertError: null,
      }

    case END_FETCH_ALERT_DATA:
      return {
        ...state,
        fetchAlertProgress: false,
        fetchAlertError: action.payload.error,
        alertDetails: action.payload.success
      }

    case START_ADD_NEW_ALERT_TEAM:
      return {
        ...state,
        addAlertTeamProgress: true,
        addAlertTeamError: null,
      }

    case END_ADD_NEW_ALERT_TEAM:
      return {
        ...state,
        addAlertTeamProgress: false,
        addAlertTeamError: action.payload.error,
      }

    case START_FETCH_ALERT_TEAMS:
      return {
        ...state,
        fetchAlertTeamProgress: true,
        fetchAlertTeamError: null,
        alertTeams: []
      }

    case END_FETCH_ALERT_TEAMS:
      return {
        ...state,
        fetchAlertTeamProgress: false,
        fetchAlertTeamError: action.payload.error,
        alertTeams: action.payload.success
      }

    case START_DELETE_ALERT_TEAM:
      return {
        ...state,
        deleteAlertTeamProgress: true,
        deleteAlertTeamError: null,
      }

    case END_DELETE_ALERT_TEAM:
      return {
        ...state,
        deleteAlertTeamProgress: false,
        deleteAlertTeamError: action.payload.error,
      }

    case START_ADD_NEW_ALERT_USER:
      return {
        ...state,
        addAlertUserProgress: true,
        addAlertUserError: null,
      }

    case END_ADD_NEW_ALERT_USER:
      return {
        ...state,
        addAlertUserProgress: false,
        addAlertUserError: action.payload.error,
      }

    case START_FETCH_ALERT_USERS:
      return {
        ...state,
        fetchAlertUserProgress: true,
        fetchAlertUserError: null,
        alertUsers: []
      }

    case END_FETCH_ALERT_USERS:
      return {
        ...state,
        fetchAlertUserProgress: false,
        fetchAlertUserError: action.payload.error,
        alertUsers: action.payload.success
      }

    case START_DELETE_ALERT_USER:
      return {
        ...state,
        deleteAlertUserProgress: true,
        deleteAlertUserError: null,
      }

    case END_DELETE_ALERT_USER:
      return {
        ...state,
        deleteAlertUserProgress: false,
        deleteAlertUserError: action.payload.error,
      }

    case START_ADD_NEW_ALERT_EMAIL:
      return {
        ...state,
        addAlertEmailProgress: true,
        addAlertEmailError: null,
      }

    case END_ADD_NEW_ALERT_EMAIL:
      return {
        ...state,
        addAlertEmailProgress: false,
        addAlertEmailError: action.payload.error,
      }
  
    case START_FETCH_ALERT_EMAILS:
      return {
        ...state,
        fetchAlertEmailProgress: true,
        fetchAlertEmailError: null,
        alertEmails: []
      }

    case END_FETCH_ALERT_EMAILS:
      return {
        ...state,
        fetchAlertEmailProgress: false,
        fetchAlertEmailError: action.payload.error,
        alertEmails: action.payload.success
      }
  
    case START_DELETE_ALERT_EMAIL:
      return {
        ...state,
        deleteAlertEmailProgress: true,
        deleteAlertEmailError: null,
      }

    case END_DELETE_ALERT_EMAIL:
      return {
        ...state,
        deleteAlertEmailProgress: false,
        deleteAlertEmailError: action.payload.error,
      }

    case START_ADD_NEW_ALERT_RULE:
      return {
        ...state,
        addAlertRuleProgress: true,
        addAlertRuleError: null,
      }

    case END_ADD_NEW_ALERT_RULE:
      return {
        ...state,
        addAlertRuleProgress: false,
        addAlertRuleError: action.payload.error,
      }

    case START_UPDATE_ALERT_RULE:
      return {
        ...state,
        updateAlertRuleProgress: true,
        updateAlertRuleError: null,
      }

    case END_UPDATE_ALERT_RULE:
      return {
        ...state,
        updateAlertRuleProgress: false,
        updateAlertRuleError: action.payload.error,
      }

    case START_FETCH_ALERT_RULES:
      return {
        ...state,
        fetchAlertRuleProgress: true,
        fetchAlertRuleError: null,
      }

    case END_FETCH_ALERT_RULES:
      return {
        ...state,
        fetchAlertRuleProgress: false,
        fetchAlertRuleError: action.payload.error,
        alertRules:action.payload.success
      }

    case START_DELETE_ALERT_RULE:
      return {
        ...state,
        deleteAlertRuleProgress: true,
        deleteAlertRuleError: null,
      }

    case END_DELETE_ALERT_RULE:
      return {
        ...state,
        deleteAlertRuleProgress: false,
        deleteAlertRuleError: action.payload.error,
      }

    default:
      return state;
  }

}