import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppBarV2 from "Components/Application/AppBarV2";
import CommonCss from 'commonCss';
import {
  IconButton,
  Button, Card, CardContent, Typography, List,
  ListItem, Popover, ListItemIcon, MenuItem, Divider
} from '@material-ui/core';
import { showAddAutomationDialog, hideAddAutomationDialog, deleteAutomation, fetchAutomations } from 'redux/automations/action';
import AddAutomationDialog from "../Dialogs/AddAutomationDialog";
import { showSnackBar } from "redux/snackbar/action";
import { CommonFn } from "services/commonFn";
import { getIconForAutomation } from "../Common/customIcons"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '@material-ui/lab/Pagination';
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { automationOptions } from "../../Templates/Constant";
import AddExistingAutomationDialog from "../Dialogs/AddExistingAutomationDialog";

const connectedProps = (state) => ({
  isOpenAddDialog: state.automation.dialogAddAutomationOpen,
  addNewAutomationProgress: state.automation.addNewAutomationProgress,
  addNewAutomationError: state.automation.addNewAutomationError,
  newAutomationData: state.automation.newAutomationData,
  deleteAutomationProgress: state.automation.deleteAutomationProgress,
  deleteAutomationError: state.automation.deleteAutomationError,
  fetchAutomationsProgress: state.automation.fetchAutomationsProgress,
  fetchAutomationsError: state.automation.fetchAutomationsError,
  automationDatas: state.automation.automationDatas,
});

const connectionActions = {
  showAddAutomationDialog: showAddAutomationDialog,
  hideAddAutomationDialog: hideAddAutomationDialog,
  showSnackBar: showSnackBar,
  deleteAutomation: deleteAutomation,
  fetchAutomations: fetchAutomations,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  topActions: {
    textAlign: 'right',
    marginBottom: 20
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  nameCol: {
    width: '30%'
  },
  statusCol: {
    width: '15%'
  },
  actionCol: {
    textAlign: 'center',
    width: '10%'
  },
  automationContent: {
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    background: '#e0f5cf',
    color: '#44791e',
    borderRadius: 8,
    padding: '2px 10px',
    border: '1px solid #9ad86a',
    margin: 0,
    width: 'max-content',
    marginBottom: 10
  },
  inactive: {
    background: '#ced5db80',
    border: '1px solid #bac4cc',
    color: '#24434f',
    borderRadius: 8,
    padding: '2px 10px',
    margin: 0,
    width: 'max-content',
    marginBottom: 10
  },
  iconDiv: {
    color: '#fff',
    padding: '5px',
    borderRadius: '4px',
    background: '#1f75d7cc',
    width: 'max-content',
    display: 'flex',
    marginBottom: 10
  },
  createdDate: {
    color: '#637a87'
  },
  cardRoot: {
    marginBottom: 15,
    cursor: 'pointer'
  },
  cardContentRoot: {
    padding: '10px 20px'
  },
  detailCard: {
    flex: 2
  },
  statusCard: {
    flex: 2
  },
  actionCard: {
    flex: 1,
    textAlign: 'right'
  }
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoading: true,
      automations: [],
      page: 0,
      pageSize: 10,
      isLoading: true,
      totalAutomations: 0,
      deleteId: null,
      anchorEl: null,
      anchorElAddMenu: null,
      currentAutomation: null,
      isShowAddExisting: null,
    }
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.callBack = this.callBack.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.props.fetchAutomations({
      page: this.state.page,
      pageSize: this.state.pageSize
    })
  }

  componentDidUpdate(prevProps) {
    const { deleteAutomationProgress, deleteAutomationError, fetchAutomationsProgress, fetchAutomationsError,
      automationDatas, newAutomationData } = this.props;


    if (!this.props.addNewAutomationProgress && prevProps.addNewAutomationProgress) {
      if (this.props.addNewAutomationError === null) {
        this.props.showSnackBar('Automation added successfully', 'success', 3000)
        this.props.hideAddAutomationDialog()
        this.handleEdit(newAutomationData?.ID)
        this.setState({
          page: 0,
          pageSize: 10,
        }, () => {
          this.fetchData();
        })
      } else {
        this.props.showSnackBar('Something went wrong', 'error', 3000)
      }
    }

    if (!deleteAutomationProgress && prevProps.deleteAutomationProgress) {
      if (deleteAutomationError === null) {
        this.props.showSnackBar("Automation deleted successfully.", "success", 3000);
        this.setState({
          page: 0,
          pageSize: 10,
        }, () => {
          this.fetchData();
        })
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!fetchAutomationsProgress && prevProps.fetchAutomationsProgress) {
      if (fetchAutomationsError === null) {
        this.setState({
          isDataLoading: false,
          automations: automationDatas?.Data,
          totalAutomations: automationDatas?.total
        })
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

  }

  handleClick = (event, row) => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget || event.current,
      currentAutomation: row,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      currentAutomation: null,
    });
  };

  openSummary(id) {
    let redirectPath = `/app/settings/automations/${id}`;
    this.props.history.push(redirectPath);
  }

  handleEdit(id) {
    let redirectPath = `/app/settings/automations/builder/${id}`;
    this.props.history.push(redirectPath);
  }

  handleDelete(id) {
    var me = this;
    this.setState({ deleteId: id }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack() {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteAutomation(this.state.deleteId);
    }
  }

  handleChangePage(e, newPage) {
    this.setState({
      page: newPage,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleChangeRowsPerPage(e) {
    this.setState({
      page: 0,
      pageSize: e.target.value,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleChangePagination = (e, value) => {
    console.log(value)
    this.setState({
      page: value - 1
    }, () => {
      this.fetchData();
    })
  }

  showAddMenu = (event, row) => {
    event.stopPropagation();
    this.setState({
      anchorElAddMenu: event.currentTarget || event.current,
    });
  };

  handleCloseAdd = () => {
    this.setState({
      anchorElAddMenu: null,
    });
  };

  navigate = (menu) => {
    if (menu.addManually) {
      this.props.showAddAutomationDialog()
    } else if (menu.key === "chooseViaExisting") {
      this.setState({
        isShowAddExisting: true
      })
    }
    this.handleCloseAdd();
  };

  render() {
    const classes = this.props.classes;
    const { automations, pageSize, totalAutomations, isShowAddExisting } = this.state;
    const open = Boolean(this.state.anchorElAddMenu);
    const id = open ? "newTemplatePopover" : undefined;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    const openAction = Boolean(this.state.anchorEl);

    let totalPages = Math.ceil(totalAutomations / pageSize) || 0

    return (
      <div>
        <AppBarV2 title="Automations" withBack={true} />
        <div className={classes.rootContainer}>
          <div className={classes.topActions}>
            <Button className={classes.newUserBtn} onClick={(e) => {
              this.showAddMenu(e)
            }}>
              + New Automation
            </Button>
          </div>
          <div className={classes.body}>
            {automations && automations.map((itm, index) => {
              return <Card className={classes.cardRoot} variant="outlined" key={index} onClick={() => this.openSummary(itm?.ID)}>
                <CardContent className={classes.cardContentRoot}>
                  <div className={classes.automationContent}>
                    <div className={classes.detailCard}>
                      <div className={classes.iconDiv}>
                        {getIconForAutomation(itm.Type)}
                      </div>
                      <Typography>{itm?.Name}</Typography>
                    </div>
                    <div className={classes.statusCard}>
                      {itm?.Status === 0 || itm.Status === null ? <p className={classes.inactive}>Inactive</p> : <p className={classes.active}>Active</p>}
                      <Typography className={classes.createdDate}>Created on {CommonFn.formateDateTime(itm?.CreatedAt, true)}</Typography>
                    </div>
                    <div className={classes.actionCard}>
                      <IconButton ref={this.btnRef}
                        className={classes.threeDot}
                        onClick={(e) => this.handleClick(e, itm)}><MoreVertIcon />
                      </IconButton>
                    </div>
                  </div>
                </CardContent>
              </Card>
            })}
            <Pagination count={totalPages} page={this.state.page + 1} onChange={this.handleChangePagination} />
          </div>
        </div>

        {this.state.currentAutomation && <Popover
          className={classes.popover}
          id={'add-vendor'}
          open={openAction}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handleCloseAdd()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}>

          <List>
            {(this.state.currentAutomation.Status === 0 || this.state.currentAutomation.Status === null) && <ListItem
              button
              onClick={() => {
                this.handleEdit(this.state.currentAutomation?.ID)
                this.handleClose();
              }}
              className={this.state.currentColumn?.requestStatus !== "Draft" ? classes.disabled : ''}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Edit
              </Typography>
            </ListItem>}

            <ListItem
              button
              onClick={() => {
                this.handleDelete(this.state.currentAutomation?.ID)
                this.handleClose();
              }}
              className={this.state.currentColumn?.requestStatus !== "Draft" ? classes.disabled : ''}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {/* <Image src={deleteIcon} alt="delete Image" className={classes.deleteImage} /> */}
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Delete
              </Typography>
            </ListItem>
          </List>
        </Popover>}

        {this.props.isOpenAddDialog && <AddAutomationDialog />}
        <MessageDialog messageData={messageData} callBack={this.callBack} />


        <Popover
          className={classes.popover}
          id={id}
          open={open}
          anchorEl={this.state.anchorElAddMenu}
          onClose={() => this.handleClose()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {automationOptions?.map((item, childIndex) => {
            return (
              <div key={childIndex}>
                {" "}
                <MenuItem
                  value={item.label}
                  className={classes.menuItem}
                  onClick={() => this.navigate(item)}
                >
                  {item.label}
                </MenuItem>{" "}
                <Divider />
              </div>
            );
          })}
        </Popover>

        {isShowAddExisting && <AddExistingAutomationDialog hideDialog={() => {
          this.setState({
            isShowAddExisting: false
          })
        }} />}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Home));
