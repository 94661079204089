import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import classnames from "classnames";
import { compose } from "recompose";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withRouter } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// assets
import AddProductImage from "assets/images/add_product.svg"

// redux
import {showProductSellerEmailDialog,hideProductSellerEmailDialog} from "redux/applications/action";

// components
import Image from 'Components/Common/image.jsx';


const connectedProps = (state) => ({
    evaluateView: state.applications.evaluateView,
    evaluateViewId: state.applications.evaluateViewId,
    evaluateNeverReview: state.applications.evaluateNeverReview,
    evaluateNeverReviewId: state.applications.evaluateNeverReviewId,
    dialogProductSellerEmail:state.applications.dialogProductSellerEmail,
});

const connectionActions = {
    showProductSellerEmailDialog: showProductSellerEmailDialog,
    hideProductSellerEmailDialog: hideProductSellerEmailDialog,
}


var connector = connect(connectedProps, connectionActions);

// Styles
const styles = createStyles({
    productSliderContainer:{
        width: '100%',
        "& .evaluate-slider-one": {
            visibility: 'hidden'
         }
    },
    headerWrapper: {
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: 'center space-between',
        alignItems: 'center',
    },
    buttonsWrapper: {
        textAlign: 'right'
    },
    prevButton: {
        color: '#707070',
        outline: '0px',
        border: '1px solid #707070',
        padding: '5px 10px',
        borderRadius: '4px',
        cursor: 'pointer'
    },
    nextButton: {
        color: '#707070',
        outline: '0px',
        border: '1px solid #707070',
        padding: '5px 10px',
        borderRadius: '4px',
        cursor: 'pointer',
        marginLeft: '10px'
    },
    sliderWrapper: {
        width: '100%',
        // border: '1px solid #333',
        overflow: 'hidden'
    },
    slider: {
        // width: '4000px',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'overlay',
        scrollBehavior: 'smooth'
    },
    sliderActive: {
        marginRight: 30,
        minWidth: 450,
        width: 450,
        marginTop: 11
    },

    sliderInActive: {
        marginRight: 30,
        minWidth: 450,
        width: 450,
        marginTop: 11
    },

    indicatorsWrapper: {
        position: 'fixed',
        bottom: 76,
        right: 62,
        zIndex: 10
    },

    // Indicators
    indicators: {
        color: '#fff !important',
        width: 'fit-content',
        margin: '0px auto',
        background: '#EBEFF0 0% 0% no-repeat padding-box',
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: 'center space-evenly',
        alignItems: 'center',
        padding: '5px 20px',
        borderRadius: '10px',
        minWidth: '80px',

        '& li': {
            display: 'inline-block',
            marginRight: '5px',
            width: '12px',
            height: '12px',
            fontSize: '16px',
            lineHeight: '30px',
            borderRadius: '100%',
            textAlign: 'center',
            backgroundColor: '#DBDBDB',
            color: '#333',
            textIndent: '-9999px',
            cursor: 'pointer',
            transition: 'all 0.2s linear',
            margin: '0px 10px',
            '& :last-child':{
                marginRight: 0
            }
        }
    },

    activeIndicator: {
        background: '#49B8FA !important',
        color: '#fff !important'
    },

    dropdownButton: {
        position: 'absolute',
        // top: '-53px',
        right: '200px',
       '& button':{
        color: '#707070',
        border: '1px solid #707070',
        background: 'transparent',
        borderRadius: '4px',
        textAlign: 'center',
        fontFamily: 'Poppins',
        padding: '0px 15px',
        borderRadius: '0.25em',
        width: '175px',
        height: '40px',
        cursor:'pointer',
        display: 'flex',
        alignItems: 'center',
        placeContent: 'space-evenly'
       },
    },
    customDropdown:{
        position: 'relative',
        '& ul':{
            padding: 0,
            listStyle: 'none'
        }
    },
    dropdownItems: {
        position: 'absolute',
        zIndex: 999,
        '& ul':{
            boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',

            '& li':{
                backgroundColor: 'white',
                color: 'rgb(0, 0, 0)',
                cursor: 'default',
                display: 'block',
                padding: '8px 12px',
                width: '100%',
                userSelect: 'none',
                boxSizing: 'border-box',
                fontWeight: 400,
                minWidth: '240px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                cursor: 'pointer',
                '& label':{
                    display: 'inline-block',
                    width: "85%",
                    cursor: 'pointer',
                }
            }
        }
    },
    newProduct:{
        position: 'relative',
        '& .hide':{
            display: 'block',
            position: 'absolute',
            background: 'white',
            width: '20px',
            height: '75%',
            '& img':{
                height: '16px',
                width: 'auto',
                marginTop: '3px',
                marginLeft: '1px'
            }
        }
    }
});

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
    }),

    option: (provided, state) => (
        {
      ...provided,
    //   color: state.isSelected ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.54)',
      backgroundColor: 'white',
      fontFamily: 'Poppins',
      fontWeight: 400,
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      '& :last-child':{
        borderBottom: '0px'
      }
    }),

    control: () => ({
      width: 200
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    }
  }

class ProductSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slider: [],
            activeIndex: 0,
            selectedProducts: [],
            showDropdown: false
        }
    }
    componentDidMount () {
        const slider = document.querySelector('.items');
        let startX;
        let scrollLeft;

        if(slider){

                slider.addEventListener('mousedown', (e) => {
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mousedown', (e) => {
                    let scope = this;
                    slider.onmousemove = function(e) {
                        const x = e.pageX - slider.offsetLeft;
                        const walk = (x - startX) * 3; //scroll-fast
                        slider.scrollLeft = scrollLeft - walk;
                        if(walk > 0){
                            scope.prevSlide()
                        }else{
                            scope.nextSlide()
                        }
                        slider.onmousemove = null
                    }
                    
                });
                slider.addEventListener("mouseup", (e) => {
                    slider.onmousemove = null
                })
        }

        const products = this.props.products.map(m => {
            return {
                value: m.ID,
                label: m.Name,
                checked: true
            }
        })
        this.setState({
            selectedProducts: products
        })
        
    }

    prevSlide =()=> {
        if (this.state.activeIndex !== 0) {
            const slider = document.querySelector('.sliderItems');
            slider.scrollTo((this.state.activeIndex - 1) * (slider.scrollWidth/ this.props.children.length), 0)
            this.setState({
                activeIndex: parseInt(this.state.activeIndex - 1)
            })
        }
    }

    nextSlide =()=> {
        if (this.state.activeIndex < this.props.children.length - 1) {
            const slider = document.querySelector('.sliderItems');
            slider.scrollTo((this.state.activeIndex + 1) * (slider.scrollWidth/ this.props.children.length), 0)
            this.setState({
                activeIndex: parseInt(this.state.activeIndex + 1)
            })
        }
    }

    clickIndicator =(e, index, children)=> {
        const slider = document.querySelector('.sliderItems');
        slider.scrollTo((index) * (slider.scrollWidth/ children.length), 0)
        this.setState({
            activeIndex: parseInt(index)
        })
    }

    scrollSlider = (children) => {
       // const slider = document.querySelector('.sliderItems');
        //const rect = slider.getBoundingClientRect()
      //  console.log(rect, slider.scrollLeft + slider.clientWidth, Math.floor((slider.scrollLeft + slider.clientWidth + 30) /  ((slider.scrollWidth)/ children.length)), slider.scrollWidth)
    }

    getWidth = () => {
        return this.childNode.offsetWidth();
    } 


    handleChange = (e) => {
        const { selectedProducts } = this.state;
        let value = parseInt(e.target.value) || 0;
        let checked = e.target.checked;

        if(value === 0){
            this.props.history.push(`/app/products/all-products?evaluationId=${this.props?.match?.params?.evaluation_id}`)
        }

        let products = selectedProducts.map((m)=>{
            if(m.value === value){
                return { value: m.value, label: m.label, checked: checked}
            }else{
                return { value: m.value, label: m.label, checked: m.checked}
            }
        })

        this.setState({ selectedProducts: products},()=>{
            console.log('selectedProducts',products)
        });
    };


    handleClickAway = () => {
        this.setState({showDropdown: false})
    };


    render() {
        const classes = this.props.classes;

        const products = this.props.products.map(m => {
            return {
                value: m.ID,
                label: m.Name,
                checked: true
            }
        })

        // let transform = this.state.activeIndex === this.props.children.length ? (100*(this.state.activeIndex - 1)) - 65 : (100*(this.state.activeIndex - 1));
        return (
            <>
                <div className={classnames(classes.productSliderContainer)}>
                    {/* Prev, Next button */}
                    <div className={classnames(classes.headerWrapper, this.props.children.length === 1 ? 'evaluate-slider-one': '', 'evaluate-product-slider')}>
                        <div className={classes.dropdownButton}>
                            {/* <ReactMultiSelectCheckboxes
                                placeholderButtonLabel={'Evaluation View'}
                                hideSearch={true}
                                options={newProducts}
                                value={this.state.selectedProducts}
                                width={220}
                                onChange={this.handleChange}
                                getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                                    return 'Evaluation View';
                                }}
                                styles={customStyles}
                            />  */}
                            <ClickAwayListener  onClickAway={this.handleClickAway}>
                            <div className={classes.customDropdown}>
                                <button onClick={()=>{this.setState({showDropdown: !this.state.showDropdown})}}>Evaluation View <ArrowDropDownIcon /></button>
                                {
                                    this.state.showDropdown === true &&
                                    <div className={classes.dropdownItems}>
                                        <ul>
                                            { this.state.selectedProducts.map((item,i)=>{
                                                return(<>
                                                <li>
                                                    <input onChange={this.handleChange} type="checkbox" id={`product_${item.value}`} name={item.label} value={item.value} checked={item.checked} />
                                                    &nbsp;<label for={`product_${item.value}`}>{item.label}</label>
                                                </li>
                                                </>)
                                            })}
                                             <li className={classes.newProduct}>
                                                 <span className="hide">
                                                    <Image alt="add vendor" src={AddProductImage} className={classes.productImage}/>
                                                 </span>
                                                <input onChange={this.handleChange} type="checkbox" id={`product_0`} name={'+ Add Vendor'} value={0} checked={false} />
                                                &nbsp;<label for={`product_0`}>Add Vendor</label>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                            </ClickAwayListener>
                        </div>
                        <div className={classes.buttonsWrapper} style={{width: '100%'}}>
                            <button className={classes.prevButton} onClick={this.prevSlide}>&lt;</button>
                            <button className={classes.nextButton} onClick={this.nextSlide}>&gt;</button>
                        </div>
                    </div> 

                    {/* product Slider */}
                    <div className={classes.sliderWrapper}>
                        <div className={classes.slider+ ' ' +'sliderItems'} onScroll={() => this.scrollSlider(this.props.children )}>
                            {this.props.children && this.props.children.map(function (item, index) {
                                const selproduct = this.state.selectedProducts.some(product => {
                                    if(!this.props.evaluateViewId && product.checked === true) {
                                        return product.value === item.props.children.props.product.ID
                                    } else {
                                        return this.props.evaluateViewId == item.props.children.props.product.ID
                                    }
                                });
                                if(selproduct){
                                    return (
                                        <div 
                                            ref={(r) => {this.childNode = r}}  data-index={index}  data-length={this.props.children.length}
                                            className={classnames(index + 1 === this.state.activeIndex ? classes.sliderActive : classes.sliderInActive,'sliderContent')} >
                                            {item } 
                                        </div>
                                    )
                                }
                            }, this)
                            }
                        </div>
                    </div>
                    {/* Pagination indicator */}
                    <div className={classnames("indicatorsWrapper", classes.indicatorsWrapper, this.props.children.length === 1 ? 'evaluate-slider-one': '', 'evaluate-pagination')}>
                       {this.state.selectedProducts.length > 0 && this.state.selectedProducts && <ul className={classes.indicators}>
                            {this.state.selectedProducts.map(function (item, index) {
                                return (
                                    <li className={index === this.state.activeIndex ? classes.activeIndicator : ''} onClick={(e) => this.clickIndicator(e, index, this.state.selectedProducts)}>{index + 1}</li>
                                )
                            }, this)
                            }
                        </ul>}
                    </div> 
                    {/* {
                        this.props.dialogProductSellerEmail === true &&
                        <>
                            <ProductSellerEmailDialog />
                        </>
                    } */}
                </div>
            </>
        );
    }
}
export default connector(compose(
    withRouter,
    withStyles(styles)
)(ProductSlider));