import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { TextField } from "@material-ui/core";
import Select from "react-select";

const connectedProps = (state) => ({
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      minWidth: "45%",
      maxWidth: "400px",
    },
    formControl: {
      minWidth: 150,
      "& > div": {
        color: "hsl(0,0%,50%)",
        fontSize: 14,
        background: "#fff",
        border: "1px solid #bebebe",
        borderRadius: 4,
        padding: 5,
      },
    },
    calendarPopout: {
      zIndex: "100 !important",
    },
    datePicker: {
      display: "flex",
      alignItems: "center",
      gap: 5,
      "& .react-datepicker-wrapper": {
        width: "100%",
        backgroundColor: "white",
        minWidth: "170px",
      },
      "& .react-datepicker__input-container > div": {
        display: "flex",
        marginBottom: 0,
        alignItems: "center",
        paddingLeft: "8px !important",
        "& [class*=MuiInputBase-root]": {
          maxHeight: 39,
          minHeight: 39,
          width: "inherit",
        },
        "& input": {
          fontSize: theme.spacing(1.8),
          padding: "12px 5px 12px 2px !important",
          textAlign: "center",
        },
        "& [class*=MuiFormLabel-root]": {
          marginBottom: 0,
          marginRight: 29,
        },
      },
      "[class*=MuiMenuItem-root]": {
        color: "#707070",
        fontSize: theme.spacing(1.8),
      },
    },
    flexWrap: {
      display: "flex",
      alignItems: "center",
      gap: 5,
    },
    label: {
      fontWeight: 500,
      fontSize: 14,
      marginBottom: 8,
    }
  });

class FilterSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 5,
      datas: [],
      value: null,
      selectOperand: {
        value: "eq",
        label: "==",
      },
      operand: [
        {
          value: "eq",
          label: "==",
        },
        {
          value: "gt",
          label: ">",
        },
        {
          value: "lt",
          label: "<",
        },
        {
          value: "gte",
          label: ">=",
        },
        {
          value: "lte",
          label: "<=",
        },
      ],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    
  }

  handleOnChangeOperand = (operand) => {
    this.setState({ selectOperand: operand }, () => {
      if (this.state.value) {
        this.props.applyFilters(this.state.value, operand);
      }
    });
  };
  handleOnChangeValue = (value) => {
    this.setState({ value }, () => {
      this.props.applyFilters(value, this.state.selectOperand);
    });
  };

  render() {
    const { classes, type, title } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root} key={this.props.key}>
        <div className={classes.label}>{title}</div>
        {["NUMBER","STRING","CURRENCY"].includes(type) && (
          <div className={classes.flexWrap}>
            {type !== "STRING" && (
              <div style={{ minWidth: 90 }}>
                <Select
                  menuPortalTarget={document.body}
                  value={this.state.selectOperand}
                  options={this.state.operand}
                  classNamePrefix="select"
                  placeholder="Type"
                  onChange={(event) => {
                    this.handleOnChangeOperand(event);
                  }}
                  styles={{ control: (base) => ({ ...base, height: 41 }), menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
            )}
            <InputField
              classes={classes}
              val={value}
              type={type !== "STRING" ? "number" : "text"}
              placeholder={`Search ${title}`}
              onChange={(value) => {
                this.handleOnChangeValue(value);
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(FilterSearch));

const InputField = ({ classes, onChange, val, type, placeholder, isFocused = false }) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(val);
  }, [val]);
  return (
    <TextField
      fullWidth
      autoFocus={isFocused}
      className={classes.formControl}
      value={value}
      type={type ? type : "text"}
      InputProps={{ disableUnderline: true }}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};