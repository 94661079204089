import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { TableContainer, TablePagination, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, MenuItem, FormControl, Select } from "@material-ui/core";
import { CommonFn } from "services/commonFn";
import { preciseDiffMoments } from "util/Common";
import { fetchOrgCatalogDetailsTabs } from "redux/vendor/vendorCatalog/action";
import { renewalValidColumnTypes } from "util/AppCatalogTypeOptions";
import { currencyCodeMap } from "util/Currency";

const renewalTableColumns = [
  { id: "contractName", type: 1, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Contract" },
  { id: "renewalDate", type: 2, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Renewal Date" },
  { id: "renewalInDays", type: "Number", align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Renewal In Days" },
  { id: "ownerName", type: 1, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Contract Owner" },
  { id: "requestStatus", type: 1, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Request Status" },
  { id: "currentStep", type: 1, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Current Step" },
  { id: "projectOwnerName", type: 1, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Project Owner" },
  { id: "status", type: 100, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Status" },
  // { id: 'entity', type: 1, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Entity' },
];

const connectedProps = (state) => ({
  orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,
  orgCatalogDetailTabsProgress: state.vendorCatalog.orgCatalogDetailTabsProgress,
  orgUser: state.orgUser,
});

const connectionActions = {
  fetchOrgCatalogDetailsTabs: fetchOrgCatalogDetailsTabs,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    columnDrawer: {
      width: 500,
      right: "0",
      padding: "15px",
      zIndex: "9",
    },
    bottomActions: {
      textAlign: "right",
    },
  });

class ContractList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renewalColumns: [],
      renewals: [],
      columnInitialized: false,
      columnsMap: {},
      isDataLoaded: false,
      configSuccessMsg: "",
      page: 0,
    };
  }

  componentDidMount() {
    this.props.fetchOrgCatalogDetailsTabs("vendor");
  }

  componentDidUpdate(prevProps) {
    if (!this.props.orgCatalogDetailTabsProgress && prevProps.orgCatalogDetailTabsProgress) {
      this.initializeColumns();
    }

    if (this.props.contractData && this.props.contractData?.length !== prevProps.contractData?.length) {
      this.setState(
        {
          isDataLoaded: false,
        },
        () => {
          this.initializeData();
        }
      );
    }

    if (this.props.fetchProgress === false && prevProps.fetchProgress) {
      this.setState(
        {
          isDataLoaded: false,
        },
        () => {
          this.initializeData();
        }
      );
    }
  }

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) {}
      return result.trim() || "";
    }
  };

  getRenewalColumns = (renewalColumns) => {
    let columns = [];
    // const localColumns = window.localStorage.getItem("renewalListingColumns");
    const localColumns = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "CONTRACT_TABLE_COLUMN") || "";
    if (localColumns !== undefined && localColumns !== null && localColumns !== "null" && localColumns !== "") {
      let localStorageCols = JSON.parse(localColumns);
      if (localStorageCols?.length > 0) {
        let availColumns = [];
        let availColumnsMap = {};
        renewalColumns.forEach(function (col) {
          availColumns.push(col.id);
          availColumnsMap[col.id] = col;
        });
        let addedFromColumns = [];
        localStorageCols?.forEach(function (col) {
          if (availColumns.indexOf(col.id) !== -1) {
            addedFromColumns.push(String(col.id));
            col.label = availColumnsMap[col.id].label;
            columns.push(col);
          }
        });
        Object.keys(availColumnsMap).forEach(function (k) {
          let col = availColumnsMap[k];
          if (addedFromColumns.indexOf(k) === -1) {
            columns.push(col);
          }
        });
      } else {
        columns = renewalColumns;
      }
    } else {
      columns = renewalColumns;
    }
    return columns;
  };

  initializeColumns() {
    let renewalColumns = renewalTableColumns;
    let tabs = this.props.orgCatalogDetailTabs;
    let validTypes = renewalValidColumnTypes();
    let columnsMap = {};
    tabs.forEach((tab) => {
      tab.sections.forEach((section) => {
        section.items.forEach((item) => {
          if (validTypes.indexOf(item.type) === -1) {
            return;
          }
          if (item.type === 6) {
            const datas = item.additional_data ? JSON.parse(item.additional_data) : [];
            if (datas && datas.length > 0) {
              datas.forEach(function (ad) {
                if ([1, 2, 12, 13, 14, 19].includes(ad.type)) {
                  let arr = [section.name];
                  if (item.name && item.name !== "") {
                    arr.push(item.name);
                  }
                  if (ad.label && ad.label !== "") {
                    arr.push(ad.label);
                  }
                  let name = arr.join(" -> ");
                  let obj = {
                    id: item.slug + ":tableColumn" + ad.id,
                    type: item.type,
                    align: "left",
                    isVisible: false,
                    isDefault: false,
                    isSortRequire: false,
                    disablePadding: false,
                    tableColumnId: ad.id,
                    label: name,
                  };
                  renewalColumns.push(obj);
                }
              });
            }
          } else {
            let arr = [section.name];
            if (item.name && item.name !== "") {
              arr.push(item.name);
            }
            let name = arr.join(" -> ");
            let obj = {
              id: item.slug,
              type: item.type,
              align: "left",
              isVisible: false,
              isDefault: false,
              isSortRequire: false,
              disablePadding: false,
              label: name,
            };
            renewalColumns.push(obj);
          }
          columnsMap[item.slug] = item.type;
        });
      });
    });

    let finalColumns = this.getRenewalColumns(renewalColumns);
    this.setState(
      {
        columnInitialized: true,
        columnsMap: columnsMap,
      },
      () => {
        this.setColumns(finalColumns);
        this.initializeData();
      }
    );
  }

  initializeData() {
    const { contractData } = this.props;
    const { columnsMap } = this.state;
    let renewals = [];
    if (contractData) {
      renewals = contractData.map((o) => {
        let renewalInDays = preciseDiffMoments(new Date(), new Date(o.contract?.renewalDate));
        let obj = {
          contractId: o.contract?.id,
          contractName: o.contract?.name,
          renewalDate: o.contract?.renewalDate,
          status: o.contract?.status,
          ownerName: o?.contract?.owner?.name,
          ownerId: o?.contract?.owner?.id,
          ownerEmail: o.contract?.owner?.email,
          entity: o.entity,
          requestStatus: o.project?.requestStatus || "",
          currentStep: o.project?.currentStep || "",
          projectId: o.project?.projectId || "",
          projectOwnerName: o.project?.owner?.id || "",
          projectOwnerId: o.project?.owner?.name || "",
          projectOwnerEmail: o.project?.owner?.email || "",
          renewalInDays: renewalInDays,
          product: o.product,
          project: o.project,
          vendor: o.vendor,
          contract: o.contract,
        };
        obj["name"] = o?.vendor?.name;
        obj["contractName"] = o?.vendor?.name + " - " + o.contract?.name;
        obj["product_id"] = o?.vendor?.id;
        obj["product_logo"] = o?.vendor?.logo;
        if (o.vendor && o.vendor.items) {
          Object.keys(o.vendor.items).forEach(function (itm) {
            if (columnsMap[itm] && (columnsMap[itm] === 4 || columnsMap[itm] === 5 || columnsMap[itm] === 7 || columnsMap[itm] === 8)) {
              let str = o.vendor.items[itm] ? o.vendor.items[itm].map((o) => o.label).join(", ") : "";
              if (str) {
                obj[itm] = str;
              }
            } else if (columnsMap[itm] && columnsMap[itm] === 20) {
              let str = "";
              if (o.vendor.items[itm]) {
                str = currencyCodeMap[o.vendor.items[itm].type.value] + "" + o.vendor.items[itm]?.value;
              }
              if (str) {
                obj[itm] = str;
              }
            } else if (columnsMap[itm] && columnsMap[itm] === 6) {
              if (o.vendor.items[itm]) {
                let data_map = {};
                o.vendor.items[itm].forEach(function (itm) {
                  itm.forEach(function (dt) {
                    if (data_map[dt.Label] === undefined) {
                      data_map[dt.Label] = [];
                      data_map[dt.Label].push(dt.Value);
                    } else {
                      data_map[dt.Label].push(dt.Value);
                    }
                  });
                });
                if (data_map) {
                  Object.keys(data_map).forEach(function (row_dt) {
                    let str = data_map[row_dt].join(", ");
                    if (str) {
                      obj[parseInt(row_dt)] = str;
                    }
                  });
                }
              }
            } else {
              obj[itm] = o.vendor.items[itm] ? o.vendor.items[itm] : "";
            }
          });
        }
        return obj;
      });
    }
    this.setState({
      renewals: renewals,
      isDataLoaded: true,
    });
  }

  setColumns(columns) {
    this.setState(
      {
        renewalColumns: columns,
      },
      () => {
        let customColumns = columns.filter((o) => o.isVisible && !o.isDefault);
        if (customColumns && customColumns.length > 0) {
          let columns = customColumns.map((o) => {
            return {
              id: o.id.split(":tableColumn")[0],
            };
          });
          this.props.fetchRenewals(columns);
        }
      }
    );
  }

  render() {
    const { renewalColumns, renewals, columnInitialized, isDataLoaded, page } = this.state;
    const { classes, fetchProgress } = this.props;

    if (!columnInitialized || !isDataLoaded) {
      return null;
    }

    return (
      <>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" key={"table_" + this.props.key}>
            <TableHead>
              <TableRow>
                {renewalColumns &&
                  renewalColumns.map((col) => {
                    if (col.isVisible) {
                      return (
                        <TableCell key={col?.id} align={"left"} style={{ whiteSpace: "nowrap" }} padding={col?.disablePadding ? "none" : "normal"}>
                          {col.label}
                        </TableCell>
                      );
                    } else {
                      return null;
                    }
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!fetchProgress &&
                renewals.slice(page * 5, page * 5 + 5).map((renewal) => {
                  return (
                    <TableRow hover tabIndex={-1} style={{ cursor: "pointer" }} key={"renwal_" + renewal.contractId}>
                      {renewalColumns &&
                        renewalColumns.map((col) => {
                          if (col.isVisible) {
                            if (col.type === 2) {
                              return (
                                <TableCell key={col?.id} align={"left"} style={{ whiteSpace: "nowrap" }} padding={col?.disablePadding ? "none" : "normal"}>
                                  {renewal[col.id] ? CommonFn.formateDate(renewal[col.id], true) : ""}
                                </TableCell>
                              );
                            } else if (col.type === 6) {
                              return (
                                <TableCell key={col?.id} align={"left"} style={{ whiteSpace: "nowrap" }} padding={col?.disablePadding ? "none" : "normal"}>
                                  {renewal[col.tableColumnId] ? renewal[col.tableColumnId] : ""}
                                </TableCell>
                              );
                            } else if (col.type === 20) {
                              return (
                                <TableCell key={col?.id} align={"left"} style={{ whiteSpace: "nowrap" }} padding={col?.disablePadding ? "none" : "normal"}>
                                  {renewal[col.id] && renewal[col.id].value !== undefined ? renewal[col.id].value : renewal[col.id]}
                                </TableCell>
                              );
                            } else if (col.type === 100) {
                              return (
                                <TableCell key={col?.id} align={"left"} style={{ whiteSpace: "nowrap" }} padding={col.disablePadding ? "none" : "normal"}>
                                  <FormControl variant="outlined" className={classes.select} margin="dense">
                                    <Select
                                      value={renewal[col.id]}
                                      onClick={() => {}}
                                      onChange={(event) => {
                                        if (event.target.value === 3) {
                                          this.props.showConfirmCallbackDialog(renewal.vendor, renewal.contract);
                                        }
                                        this.props.updateEntityRenewal(renewal.contract?.id, { date: renewal.contract?.renewalDate, status: event.target.value });
                                      }}
                                    >
                                      <MenuItem value={1}>Not started</MenuItem>
                                      <MenuItem value={2}>In progress</MenuItem>
                                      <MenuItem value={3}>Renewed</MenuItem>
                                      <MenuItem value={4}>Not going to renew</MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={col.id} align={"left"} style={{ whiteSpace: "nowrap" }} padding={col?.disablePadding ? "none" : "normal"}>
                                  {renewal[col.id] ? renewal[col.id] : ""}
                                </TableCell>
                              );
                            }
                          } else {
                            return null;
                          }
                        })}
                    </TableRow>
                  );
                })}

              {!fetchProgress && (renewals === undefined || renewals === null || renewals.length <= 0) && (
                <TableRow>
                  <TableCell colSpan={renewalColumns.length + 1} style={{ textAlign: "center" }} className={classes.nameCol}>
                    {"No Renewals"}
                  </TableCell>
                </TableRow>
              )}

              {fetchProgress && (
                <TableRow>
                  <TableCell colSpan={renewalColumns.length + 1} style={{ textAlign: "center" }} className={classes.nameCol}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={renewals?.length || 0}
          rowsPerPage={5}
          page={page}
          onPageChange={(e, newPage) => {
            this.setState({ page: newPage });
          }}
          onRowsPerPageChange={() => {}}
        />
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(ContractList));
