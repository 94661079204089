import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';
import { createStyles, withStyles } from "@material-ui/core/styles"
import Summary from "Components/Application/Components/Evaluate/Components/Evaluation/Summary/index";
import ProgressSummary from "Components/Application/Components/Evaluate/Components/Evaluation/ProgressSummary/Report";

import { fetchEvaluationMetaData } from "redux/evaluation/metadata/action";
import queryString from 'query-string';
import { authorizedUser } from "../../redux/authuser/action";
import Logo from "assets/images/ExpentLogo.png";
import { fetchEvaluationReportData } from "redux/evaluation/reportdata/action";
import {
  fetchUserRequirementResponse, fetchScores
} from "redux/evaluation/criteria/action";

import { Typography, Grid } from '@material-ui/core';

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  downloadxlsProg: state.evaluationReportData.downloadxlsProg,
  downloadxlsError: state.evaluationReportData.downloadxlsError,
  downloadxlsData: state.evaluationReportData.downloadxlsData,
  authProgress: state.authUser.authProgress,
  authError: state.authUser.authError,
  user: state.authUser.user,
  report: state.evaluationReportData.report,
});

const connectionActions = {
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  authorizedUser: authorizedUser,
  fetchEvaluationReportData: fetchEvaluationReportData,
  fetchUserRequirementResponse: fetchUserRequirementResponse,
  fetchScores: fetchScores
}
var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 4),
    },
    filterTitle: {
      marginBottom: theme.spacing(2),
      color: '#282D30',
      fontSize: theme.spacing(2.2)
    },
    formControl: {
      '& > div': {
        color: '#282D30',
        fontSize: theme.spacing(2.2),
      },
    },
    dwnldbtn: {
      color: '#282D30',
      fontSize: theme.spacing(2.2),
      marginLeft: 10
    },
    logoImage: {
      height: '40px',
      position:'absolute',
      top:20,
      left:25
    },
    topBar: {
      padding: 20,
      // display: 'flex',
      // alignItems: 'center',
      background: '#fff'
    },
    reportRoot: {
      background: '#f7f7f7',
    },
    evName: {
      flex: 1,
      textAlign: 'right',
    },
    ename: {
      fontSize: 24,
      fontWeight: 500
    }
  });

class EvaluationSummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: queryString.parse(this.props.location.search),
      value: 0,
      isDownloading: false,
      isLoading: true
    };
    this.divRef = React.createRef();
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentDidMount() {
    var isToken = (this.getParameterByName("token") !== null);
    if (isToken) {
      var token = this.state.queryParams.token;
      if (token !== null) {
        window.localStorage.setItem("authToken", token);
        window.localStorage.setItem("authType", 'JWT');
      }
      this.callInitFunctions();
    }
  }

  callInitFunctions() {
    this.props.authorizedUser();
  }

  componentDidUpdate(prevProps) {
    if (this.props.authProgress === false && prevProps.authProgress === true) {
      console.log(this.props.user, 'Userrr')
      if (this.props.authError !== null) {
        console.log('Not able to Autherized');
      } else {
        if (this.props.user === null) {
          console.log('Not able to find valid user');
        } else {
          console.log('Authentication Done');
          this.props.fetchEvaluationMetaData(this.props.match.params.evaluation_id);
          this.props.fetchUserRequirementResponse(
            this.props.match.params.evaluation_id
          );
          this.props.fetchScores(this.props.match.params.evaluation_id);


          var isProducts = (this.getParameterByName("products") !== null);
          var isCriterias = (this.getParameterByName("criterias") !== null);

          var params = {
            approval: true,
            colloborator: true,
            feedback: true,
            seller_response: true,
          }

          if (isProducts) {
            var products = this.state.queryParams.products;
            var newProducts = products.split(',');
            var prds = []
            newProducts.forEach(function (cri) {
              prds.push(parseInt(cri))
            })
            params['all_products'] = false;
            params['products'] = prds;
          } else {
            params['all_products'] = true;
          }

          if (isCriterias) {
            var criterias = this.state.queryParams.criterias;
            var newCriterias = criterias.split(',');
            var crds = []
            newCriterias.forEach(function (cri) {
              crds.push(parseInt(cri))
            })
            params['all_criterias'] = false;
            params['criterias'] = crds;
          } else {
            params['all_criterias'] = true;
          }

          this.props.fetchEvaluationReportData(
            this.props.match.params.evaluation_id,
            params
          );

          this.setState({
            isLoading: false
          })
        }
      }
    }
  }

  render() {
    const { classes, report, evaluation } = this.props;

    if (this.state.isLoading === true || report === null) {
      return 'Loading...'
    }

    return (
      <>
        <div className={classes.topBar}>
          <img src={Logo} alt="logo" className={classes.logoImage} />
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xl={12} lg={12} md={12} sm={12} style={{textAlign:'center'}}>
              <Typography className={classes.ename}>{evaluation?.Name}</Typography>
            </Grid>
          </Grid>
        </div>

        <div id="report_new" className={classes.reportRoot} style={{ width: 'max-content' }}>
          {this.state.value === 0 && (
            <>
              <ProgressSummary
                isReport={true}
                isPDfReport={true}
              />
              <Summary
                isReport={true}
                isPDfReport={true}
                onlyShowFeedBack />
            </>
          )}
          {this.state.value === 1 &&
            <Summary
              isReport={true}
              isPDfReport={true}
            />}
        </div>
        <div ref={this.divRef} id="downloadlink"></div>
      </>
    );
  }
}


export default connector(compose(
  withRouter,
  withStyles(styles)
)(EvaluationSummaryReport));