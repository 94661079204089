import React from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
    Button, CircularProgress, Paper, Grid,
    Table, TableHead, TableBody, TableRow, TableCell, Chip
} from '@material-ui/core';
import CommonCss from 'commonCss';
import {
    getSlackChannels, setSlackNotificationChannels,
    uninstallSlack, getNotificationTypes, getNotificationSettings, deleteNotificationSettings
} from "redux/authuser/action";
import { fetchUsers } from "redux/usersSettings/action";
import { showSnackBar } from "redux/snackbar/action";
import AppBar from "../../AppBar";

const connectedProps = (state) => ({
    user: state.authUser.user,
    usersSettings: state.usersSettings,
    slackNotificationEnableProgress: state.authUser.slackNotificationEnableProgress,
    slackNotificationEnableError: state.authUser.slackNotificationEnableError,
    slackNotificationEnableData: state.authUser.slackNotificationEnableData,
    slackStateCodeProgress: state.authUser.slackStateCodeProgress,
    slackStateCodeError: state.authUser.slackStateCodeError,
    slackStateCode: state.authUser.slackStateCode,
    slackChannels: state.authUser.slackChannels,
    slackChannelsProgress: state.authUser.slackChannelsProgress,
    slackChannelsError: state.authUser.slackChannelsError,
    slackSetNotificationChannelsProgress: state.authUser.slackSetNotificationChannelsProgress,
    unInstallSlackProgress: state.authUser.unInstallSlackProgress,
    unInstallSlackError: state.authUser.unInstallSlackError,
    getNotificationTypeData: state.authUser.getNotificationType,
    getNotificationProgress: state.authUser.getNotificationProgress,
    deleteNotificationProgress: state.authUser.deleteNotificationProgress,
    getNotificationSettingsProgress: state.authUser.getNotificationSettingsProgress,
    getNotificationSettingsData: state.authUser.getNotificationSettings
});

const connectionActions = {
    showSnackBar: showSnackBar,
    getSlackChannels: getSlackChannels,
    setSlackNotificationChannels: setSlackNotificationChannels,
    uninstallSlack: uninstallSlack,
    fetchUsers: fetchUsers,
    getNotificationTypes: getNotificationTypes,
    getNotificationSettings: getNotificationSettings,
    deleteNotificationSettings: deleteNotificationSettings
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        padding: theme.spacing(4)
    },
    formControl: {
        ...CommonCss.formControl,
    },
    creatable: {
        padding: 0,
    },
    companySection: {
        marginTop: theme.spacing(4.2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 30
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(2.5),
        marginLeft: 20
    },
    steperContent: {
        padding: theme.spacing(4)
    },
    "button": {
        background: "#3C3C3C",
        borderRadius: 5,
        color: "#fff",
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        padding: theme.spacing(1, 3),
        marginTop: 10,
        "&:hover": {
            background: "#3C3C3C",
        },
    },
    enableSSoContainer: {
        width: "30%",
        margin: '30px auto',
        textAlign: "center"
    },
    idpSSoContainer: {
        width: "90%",
        margin: '0px auto',
        marginBottom: theme.spacing(2),
        "& li": {
            marginBottom: theme.spacing(2),
        }
    },
    idpForm: {
        width: "45%",
        // margin:'0px auto',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        border: "solid 1px #ccc",
        padding: theme.spacing(2, 6),
        borderRadius: theme.spacing(1)
    },
    alert: {
        margin: '30px auto',
    },
    formAction: {
        textAlign: "right"
    },
    disableAction: {
        textAlign: "right"
    },
    copy: {
        cursor: "pointer",
        color: "#4A87F8",
        fontSize: "12px",
        marginLeft: theme.spacing(0.5)
    },
    searchAndSelect: {
        marginBottom: "5px",
        "& .select__control, & .select__control:hover": {
            border: "none",
            height: "inherit",
            boxShadow: "none",
            minHeight: 44,
        },
        "& .select__multi-value, & .select__multi-value__label": {
            background: "#4A87F8",
            color: "#fff",
            borderRadius: 20,
            padding: theme.spacing(0.3, 0.6),
            fontSize: 13,
        },
        "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
            background: "#4A87F8",
            color: "#fff",
            borderRadius: 20,
        },
    },
    chipRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
});


class SlackIntegration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedChannels: {},
            slackChannelsList: [],
            userOptions: []
        }
        this.handleSelectedChannelsAndUser = this.handleSelectedChannelsAndUser.bind(this)
    }

    componentDidMount() {
        this.props.getNotificationTypes()
        this.props.getSlackChannels()
        this.props.fetchUsers(0, 100, "");
    }

    componentDidUpdate(prevProps) {
        if (!this.props.unInstallSlackProgress && prevProps.unInstallSlackProgress) {
            if (this.props.unInstallSlackError === null) {
                this.props.history.goBack();
            }
        }

        if (!this.props.getNotificationProgress && prevProps.getNotificationProgress) {
            if (this.props.getNotificationTypeData) {
                const selectedChannels = {}
                this.props.getNotificationTypeData.forEach((obj) => {
                    try {
                        const slackChannelsList = JSON.parse(obj.value)
                        if (slackChannelsList && Array.isArray(slackChannelsList) && slackChannelsList.length > 0) {
                            selectedChannels[obj.setting_name] = slackChannelsList
                        }
                    } catch (e) { }
                })
                this.setState({ selectedChannels })
            }
        }

        if (!this.props.slackSetNotificationChannelsProgress && prevProps.slackSetNotificationChannelsProgress) {
            this.props.showSnackBar("Updated successfully", "success", 3000);
            this.props.getNotificationTypes()
        }

        if (!this.props.slackChannelsProgress && prevProps.slackChannelsProgress) {

            if (this.props.slackChannelsError === null) {
                const slackChannelsList = []
                const slackChannels = this.props.slackChannels

                if (slackChannels && slackChannels?.private) {
                    if (slackChannels?.private && slackChannels?.private.length > 0) {
                        slackChannelsList.push({
                            label: "Private channels",
                            options: slackChannels.private.map(channel => ({
                                ...channel,
                                label: channel.name.split(/(?:-|_)+/).join(" "),
                                is_channel: true,
                            }))
                        })
                    }
                }
                if (slackChannels && slackChannels?.public) {
                    if (slackChannels?.public && slackChannels?.public.length > 0) {
                        slackChannelsList.push({
                            label: "Public channels",
                            options: slackChannels.public.map(channel => ({
                                ...channel,
                                label: channel.name.split(/(?:-|_)+/).join(" "),
                                is_channel: true,
                            }))
                        })
                    }
                }
                this.setState({ slackChannelsList })
            }
        }

        if (!this.props.usersSettings.isUserProgress && prevProps.usersSettings.isUserProgress) {
            let userOptions = []
            if (
                ![null, NaN, undefined, []].includes(this.props.usersSettings) &&
                this.props.usersSettings.users &&
                this.props.usersSettings.users.length > 0
            ) {
                userOptions = this.props.usersSettings.users.map(user => ({
                    label: user.Name,
                    name: user.Name,
                    id: user.ID,
                    is_channel: false,
                }))
            }
            this.setState({ userOptions })
        }
    }

    handleSetChannels = () => {
        const data = []
        Object.keys(this.state.selectedChannels).forEach((key, index) => {
            if (this.state.selectedChannels[key].length > 0) {
                data.push({ setting_name: key, setting_value: this.state.selectedChannels[key] })
            }
        });
        this.props.setSlackNotificationChannels(data)
    }

    handleSelectedChannelsAndUser = (notificationType, channel) => {
        let selectedChannels = this.state.selectedChannels
        if (selectedChannels[notificationType] === undefined) {
            selectedChannels[notificationType] = [channel]
        } else {
            selectedChannels[notificationType].push(channel)
        }
        this.setState({ selectedChannels })
    }

    handleDeleteChannelAndUser = (notificationType, index) => {
        const selectedChannels = this.state.selectedChannels
        selectedChannels[notificationType].splice(index, 1)
        this.setState({ selectedChannels })
    }

    getOptions = (type) => {
        let { slackChannelsList, userOptions, selectedChannels } = this.state
        let channelOptions = [...slackChannelsList]
        let userOptionsList = [...userOptions]
        selectedChannels = selectedChannels[type]
        if (selectedChannels !== undefined) {
            if (selectedChannels.length > 0) {
                let channels = selectedChannels.filter(o => o.is_channel).map(i => i.id)
                let users = selectedChannels.filter(o => !o.is_channel).map(i => i.id)
                if (channels.length > 0 && channelOptions.length > 0) {
                    console.log('channels', channels, channelOptions)
                    if (channelOptions[0] && channelOptions[0]?.options) {
                        channelOptions[0].options = channelOptions[0].options.filter(i => !channels.includes(i.id))
                    }
                    if (channelOptions[1] && channelOptions[1]?.options) {
                        channelOptions[1].options = channelOptions[1].options.filter(i => !channels.includes(i.id))
                    }
                }
                if (users.length > 0) {
                    userOptionsList = userOptionsList.filter(i => !users.includes(i.id))
                }
            }
        }
        return { channelOptions, userOptions: userOptionsList }
    }

    render() {
        const {
            classes, unstallSlackProgress, getNotificationTypeData, slackChannelsProgress,
            getNotificationSettingsProgress, slackSetNotificationChannelsProgress
        } = this.props;
        const { selectedChannels } = this.state;
        return (
            <div className={classes.root}>
                <AppBar title="Slack Integration" withBack={true} />
                <div className={classes.companySection}>
                    <p className={classes.subTitle}></p>
                </div>
                <Paper className={classes.steperContent}>
                    <Grid container justify='flex-end'>
                        <Button
                            disabled={(slackChannelsProgress || unstallSlackProgress || getNotificationSettingsProgress || slackSetNotificationChannelsProgress)}
                            type="button" variant="contained"
                            color="secondary" className={classes.button}
                            onClick={() => this.props.uninstallSlack()}
                        >
                            {this.props.unInstallSlackProgress ? 'Uninstalling...' : 'Uninstall'}
                        </Button>
                    </Grid>
                    {getNotificationTypeData && getNotificationTypeData.length > 0 && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '20%', verticalAlign: 'top' }}>Notifications</TableCell>
                                    <TableCell style={{ width: '40%', verticalAlign: 'top' }}>Slack Channels</TableCell>
                                    <TableCell style={{ width: '40%', verticalAlign: 'top' }}>Users</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getNotificationTypeData.map((notification, index) => {
                                    const { userOptions, channelOptions } = this.getOptions(notification.setting_name)
                                    return (
                                        <TableRow key={index}>
                                            <TableCell style={{ width: '20%', verticalAlign: 'top' }}>{notification.title}</TableCell>
                                            <TableCell style={{ width: '40%', verticalAlign: 'top' }}>
                                                <div className={classes.searchAndSelect}>
                                                    <Select
                                                        isClearable
                                                        disabled={(slackChannelsProgress || unstallSlackProgress || getNotificationSettingsProgress || slackSetNotificationChannelsProgress)}
                                                        className={classnames(
                                                            classes.formControl,
                                                            classes.creatable
                                                        )}
                                                        value={[]}
                                                        noOptionsMessage={() => ("No options")}
                                                        isSearchable={true}
                                                        options={channelOptions}
                                                        classNamePrefix="select"
                                                        placeholder="Choose channels"
                                                        onChange={(e) => {
                                                            this.handleSelectedChannelsAndUser(notification.setting_name, e)
                                                        }}
                                                    />
                                                </div>
                                                <div className={classes.chipRoot}>
                                                    {selectedChannels[notification.setting_name] !== undefined && selectedChannels[notification.type].map((channel, index) => {
                                                        if (!channel.is_channel) return null
                                                        return (
                                                            <Chip
                                                                key={`slack-channel-chip-${index + 1}`}
                                                                variant="outlined"
                                                                label={channel.label}
                                                                onDelete={() => this.handleDeleteChannelAndUser(notification.setting_name, index)}
                                                                color="primary"
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ width: '40%', verticalAlign: 'top' }}>
                                                <div className={classes.searchAndSelect}>
                                                    <Select
                                                        isClearable
                                                        disabled={(slackChannelsProgress || unstallSlackProgress || getNotificationSettingsProgress || slackSetNotificationChannelsProgress)}
                                                        className={classnames(
                                                            classes.formControl,
                                                            classes.creatable
                                                        )}
                                                        value={[]}
                                                        noOptionsMessage={() => ("No options")}
                                                        isSearchable={true}
                                                        options={userOptions}
                                                        classNamePrefix="select"
                                                        placeholder="Choose users"
                                                        onChange={(e) => {
                                                            this.handleSelectedChannelsAndUser(notification.setting_name, e)
                                                        }}
                                                    />
                                                </div>
                                                <div className={classes.chipRoot}>
                                                    {selectedChannels[notification.setting_name] !== undefined && selectedChannels[notification.type].map((channel, index) => {
                                                        if (channel.is_channel) return null
                                                        return (
                                                            <Chip
                                                                key={`user-chip-${index + 1}`}
                                                                variant="outlined"
                                                                label={channel.label}
                                                                onDelete={() => this.handleDeleteChannelAndUser(notification.setting_name, index)}
                                                                color="primary"
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    )}
                    <Grid container justifyContent='space-between' alignItems="center">
                        <div></div>
                        {(slackChannelsProgress || unstallSlackProgress || getNotificationSettingsProgress || slackSetNotificationChannelsProgress) && (
                            <CircularProgress />
                        )}
                        <Button
                            disabled={(slackChannelsProgress || unstallSlackProgress || getNotificationSettingsProgress || slackSetNotificationChannelsProgress)}
                            type="button" variant="contained"
                            color="secondary" className={classes.button}
                            onClick={() => this.handleSetChannels()}
                        >
                            Save
                        </Button>
                    </Grid>
                </Paper>
            </div>
        )
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(SlackIntegration));