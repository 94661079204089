import React from 'react';
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import classnames from 'classnames';
import PropTypes from 'prop-types';
import uniqid from "uniqid";
import Select from "react-select";
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Popover, List, ListItem, ListItemText, Button, OutlinedInput, InputAdornment, Grid } from "@material-ui/core"
import Tooltip from '@material-ui/core/Tooltip';
import { Search as SearchIcon, ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import Tune from "@material-ui/icons/Tune";
import Clear from "@material-ui/icons/Clear";
import { connect } from "react-redux";
import { setSortFilterOptions } from "redux/product/action";
import { searchOrgProducts } from "redux/product/orgProduct/action"
import AllProductsFilter from "../Filters/AllProductsFilter";
import OrgProductsFilter from "../Filters/OrgProductsFilter";
import CategoryWiseFilter from "../Filters/CategoryWiseFilter";

const connectedProps = (state) => ({
    productSortType: state.products.productSortType,
    productSortField: state.products.productSortField,
    searchOrgProductsProgress: state.orgProduct.searchOrgProductsProgress,
    searchOrgProductsData: state.orgProduct.searchOrgProductsData
});

const connectionActions = {
    setSortFilterOptions: setSortFilterOptions,
    searchOrgProducts: searchOrgProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(1.25),
        '& [class*="MuiOutlinedInput-root"]': {
            maxHeight: 40,
            minHeight: 40,
            maxWidth: 500,
            width: 500,
        },
        "& [class*='MuiInputBase-input']": {
            fontSize: 13,
            paddingLeft: theme.spacing(2)
        },
        "& [class*='MuiOutlinedInput-adornedStart']": {
            paddingLeft: "0px",
        },
        "& [class*='.MuiOutlinedInput-inputAdornedStart']": {
            paddingLeft: "10px",
        },
        "& [class*=MuiGrid-container]": {
            alignItems: "center",
        },
        "& [class*=MuiInputAdornment-root]": {
            marginRight: 10,
        },
        "&>div ": {
            marginBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
    root1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(1.25),
        '& [class*="MuiOutlinedInput-root"]': {
            maxHeight: 40,
            minHeight: 40,
            maxWidth: 500,
            width:500,
        },
        "& [class*='MuiInputBase-input']": {
            fontSize: 13,
            paddingLeft: theme.spacing(2)
        },
        "& [class*='MuiOutlinedInput-adornedStart']": {
            paddingLeft: "0px",
        },
        "& [class*='.MuiOutlinedInput-inputAdornedStart']": {
            paddingLeft: "10px",
        },
        "& [class*=MuiGrid-container]": {
            alignItems: "center",
        },
        "&>div ": {
            marginBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
    searchButtonDiv: {
        background: '#3c3c3c',
    },
    searchButton: {
        background: '#3c3c3c',
        color: '#fff',
        border: 'solid 1px #3c3c3c !important',
        boxShadow: 'none',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        '&:hover': {
            background: '#3c3c3c',
            color: '#fff',
            border: 'none',
            boxShadow: 'none',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        '& [class*="MuiButton-endIcon"]': {
            background: '#3c3c3c',
            color: '#fff',
            border: 'none',
            boxShadow: 'none',
            marginRight: theme.spacing(2)
        }
    },
    searchButtonIcon: {
        color: '#fff',
    },
    inputBox: {
        paddingLeft: theme.spacing(2),
        background: '#fff'
    },
    searchIconPop: {
        cursor: 'pointer',
        fontSize: theme.spacing(3),
        marginRight: '10px',
        "&:hover": {
            color: "#fff !important",
            background: "#707070",
            borderColor: "#707070",
            padding: '3px',
            borderRadius: theme.spacing(20),
            fontSize: theme.spacing(3)
        }
    },
    popover: {
        "& [class*=MuiPaper-root]": {
            marginTop: 8,
            marginLeft: 28
        }
    },
    popover1: {
        "& [class*=MuiPaper-root]": {
            marginTop: 8,
            marginLeft: 7
        }
    },
    popover2: {
        "& [class*=MuiPaper-root]": {
            marginTop: 5,
            marginLeft: 15
        }
    },
    popover3: {
        "& [class*=MuiPaper-root]": {
            marginTop: 10,
            marginLeft: 10
        }
    },
    popItems: {
        width: 495,
        padding: theme.spacing(4)
    },
    popItems1: {
        width: 495,
        padding: theme.spacing(4),
        "& [class*=MuiPaper-root]": {
            marginTop: 0,
            marginLeft: 0
        }
    },
    popItems2: {
        padding: theme.spacing(4),
        "& [class*=MuiPaper-root]": {
            marginTop: 0,
            marginLeft: 0
        }
    },
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    reqTitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    actionButtons: {
        textAlign: 'right',
        marginTop: theme.spacing(2)
    },
    inputSelectBox: {
        width: '300px',
        marginLeft: -5,
        maxHeight: 39,
        minHeight: 39,
    },
    inputSelectBoxActive: {
        width: '500px',
        maxHeight: 39,
        minHeight: 39,
    },
});


class OutlinedV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchAnchor: null,
            searchType: 'All',
            anchorEl: null,
            countRen: true,
            searchSelecctAnchor: null,
            selectSearch: "",
            popWidth:416,
        };
        this.btnRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSortSubmit = this.handleSortSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.getPopupWidth = this.getPopupWidth.bind(this); 
    }

    componentDidMount() {
        window.addEventListener('resize', this.getPopupWidth);
        setTimeout(() => {
            this.getPopupWidth()
        }, 2000);
    }

    handleClick(event) {
        event.stopPropagation()
        this.setState({ countRen: true, anchorEl: event.currentTarget || event.current });
    };

    handleClear(event){
        event.stopPropagation()
        this.props.onClearSearch();
    }

    handleClose() {
        this.setState({ anchorEl: null, countRen: true });
    };

    handleSortSubmit() {
        this.setState({ anchorEl: null, countRen: true });
        this.props.handleSortSubmit();
    }

    handleOnClickApply = () => {
        this.setState({ anchorEl: null, countRen: true });
        this.props.onClickApplyFilter();
    }

    fetchOrgProductsSimilarExisting(value) {
        if (value) {
            this.props.searchOrgProducts({
                "page":0,
                "pageSize":50,
                "query": value,
                "filters":{
                    "companyName":[],
                    "featureIds":[],
                    "subcategoryIds":[],
                    "categoryIds":[],
                    "platformIds": []
                },
                "agg": false,
                "semantic": false,
            })
        }
    }

    getPopupWidth(){
        var popWidth = document.getElementById("searchProducts")?.offsetWidth + 40;
        this.setState({
            popWidth : popWidth
        })
    }

    render() {
        var classes = this.props.classes;

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'popover' : undefined;

        var orderDropDownMap = {};
        if (this.props.sortFilterOptions !== undefined) {
            this.props.sortFilterOptions.map((dta) => {
                orderDropDownMap[dta.key] = dta.orders;
            })
        }
        const isSelect= this.props.fromPage === 'org-products' && this.props.searchType && this.props.searchType?.value && this.props.searchType?.value === 'products'
        let selectedOptions=[]

        if (this.props.searchOrgProductsData) {

            const existing = this.props.searchOrgProductsData.filter(o => o.IsExisting)
            const nonExisting = this.props.searchOrgProductsData.filter(o => !o.IsExisting)
            selectedOptions = [...existing.slice(0,5), ...nonExisting.slice(0,5)]
            selectedOptions = selectedOptions.map(obj => ({label: `${obj.Name} by ${ obj.CompanyName}`, value: obj.Name, id: obj.ID}))
        }

        return <div className={classes.root} id="outlinedSearchBar">
            {this.props.isFilterPopover !== undefined && this.props.isFilterPopover === true && <Popover
                id={id}
                open={open}
                className= {classes.popover}
                // className= {(this.props.fromPage !== undefined && (this.props.fromPage === 'all-products' ||  this.props.fromPage === 'category-wise-product')) ? this.state.countRen === true ? classes.popover2 : classes.popover3 : this.state.countRen === true ? classes.popover : classes.popover1}
                anchorEl={this.state.anchorEl}
                onClose={() => this.handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>

                {this.props.fromPage !== undefined && this.props.fromPage === 'org-products' && <div className={classes.popItems}>
                    {true && <OrgProductsFilter
                        sortFilterOptions={this.props.sortFilterOptions}
                        handleSortSubmit={this.handleSortSubmit}
                        handleClose={this.handleClose}
                        setOptions={()=>{
                            this.setState({
                                countRen : false
                            })
                        }}
                    />}
                </div>}

                {this.props.fromPage !== undefined && this.props.fromPage === 'all-products' && <div className={classes.popItems1}>
                    <AllProductsFilter
                        filters={this.props.filters}
                        handleOnClickApply={this.handleOnClickApply}
                        setOptions={()=>{
                            this.setState({
                                countRen : false
                            })
                        }}
                    />
                </div>}

                {this.props.fromPage !== undefined && this.props.fromPage === 'category-wise-product' && <div style={{width:this.state.popWidth}} className={classes.popItems2}>
                    <CategoryWiseFilter
                        filters={this.props.filters}
                        handleOnClickApply={this.handleOnClickApply}
                        setOptions={()=>{
                            this.setState({
                                countRen : false
                            })
                        }}
                    />
                </div>}

            </Popover>}
            {isSelect ? (
                <Grid container alignItems="center">
                    <div style={{ zIndex: 1 }}>
                        {this.state.anchorEl === null && <Button className={classes.searchButton} variant="contained" 
                        color="primary" endIcon={<ArrowDropDownIcon style={{ "color": "#FFF" }} fontSize={"large"} />} onClick={(event) => {
                            event.stopPropagation()
                            this.setState({
                                searchAnchor: event.currentTarget
                            })
                        }}>{this.props.searchType.name}</Button>}
                    </div>
                    <Select
                        isClearable={false}
                        className={classnames(open ? classes.inputSelectBoxActive : classes.inputSelectBox)}
                        value={this.state.selectSearch  ? {label : this.state.selectSearch} : ""}
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                fontSize: 13,
                                flexDirection: 'row-reverse',
                                position: 'relative',
                                border: state.isFocused || state.isSelected  ? "solid 1px #3c3c3c !important" : "solid 1px #c4c4c4 !important",
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                // border: "none",
                                // boxShadow: "none",
                                borderRadius: 0,
                                zIndex: 999,
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: (state.isFocused && "lightgray") || "transparent",
                                color: (state.isFocused && "white") || "black"
                            }),
                        }}
                        components={{ 
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                        }}
                        noOptionsMessage={() =>
                            this.props?.searchOrgProductsProgress
                                ? "Loading..."
                                : "No options"
                        }
                        isSearchable={true}
                        options={selectedOptions}
                        classNamePrefix="select"
                        placeholder={this.state.selectSearch || "Search Product"}
                        onInputChange={(e) => {
                            if ( e) {
                                this.setState({
                                    selectSearch: e
                                }, () => {
                                    this.fetchOrgProductsSimilarExisting(e)
                                })
                            }
                        }}
                        onChange={(e) => {
                            if (e && e?.label) {
                                this.setState({
                                    selectSearch: e.label
                                }, () => {
                                    this.props.onClickOption(e)
                                })
                            }
                        }}
                    />
                </Grid>
            ) : (
                <OutlinedInput
                    id={this.props.id === "input" ? "input_" + uniqid() : this.props.id}
                    placeholder={this.props.placeholder}
                    className={classes.inputBox}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    inputProps={{
                        autoComplete: "off",
                        autoCorrect: "off",
                        autoCapitalize: "off",
                        spellCheck: "false"
                    }}
                    endAdornment={
                        <InputAdornment>
                            {this.props.onClearSearch !== undefined && this.props.value.length > 0 && this.props.value !== '' && this.props.value !== null && <Tooltip placement="top" title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Clear</span>} arrow>
                              <Clear className={classes.searchIconPop} ref={this.btnRef} onClick={this.handleClear} />
                            </Tooltip>}

                            {this.props.isFilterPopover === undefined && <SearchIcon className={classes.searchIcon} />}
                            {this.props.isFilterPopover !== undefined && this.props.isFilterPopover === true && <Tooltip placement="top" title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{this.props.showTooltipMsg}</span>} arrow>
                            <Tune className={classes.searchIconPop} ref={this.btnRef} onClick={this.handleClick} />
                            </Tooltip>}
                        </InputAdornment>
                    }
                    startAdornment={
                        <InputAdornment>
                            {this.props.searchType !== undefined && this.state.anchorEl === null && <Button className={classes.searchButton} variant="contained"
                                color="primary" endIcon={<ArrowDropDownIcon style={{ "color": "#FFF" }} fontSize={"large"} />} onClick={(event) => {
                                    event.stopPropagation()
                                    this.setState({
                                        searchAnchor: event.currentTarget
                                    })
                                }}>{this.props.searchType.name}</Button>}
                        </InputAdornment>
                    }
                    roundedCorner={false}
                />
            )}
            {this.state.searchAnchor && (
                <Popover
                    open={this.state.searchAnchor != null}
                    anchorEl={this.state.searchAnchor}
                    onClose={() => {
                        this.setState({
                            searchAnchor: null
                        })
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <List component="nav">
                        {this.props.filterOptions.map((item, k) => {
                            return <ListItem key={k} button onClick={() => {
                                this.setState({
                                    searchAnchor: null
                                })
                                this.props.onSearchTypeChange(item)
                            }}>
                                <ListItemText primary={item.name} />
                            </ListItem>
                        })}
                    </List>
                </Popover>
            )}
        </div>
    }
}

OutlinedV2.defaultProps = {
    labelWidth: 70,
    type: 'text',
    name: '',
    value: null,
    onChange: null,
    onKeyDown: null,
    icon: null,
    icon_position: 'end',
    onIconClick: null,
    placeholder: "",
    required: false,
    error: false,
    disabled: false,
    helperText: '',
    passwordStenghtMeter: false,
    label: null,
    multiline: false,
    rows: 1,
    roundedCorner: false,
    'id': 'input',
    readOnlyInput: false,
    labelStyle: "default",
    compact: false,
    fullWidth: false,
    isOrgProduct: false,
}

OutlinedV2.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    icon: PropTypes.element,
    icon_position: PropTypes.oneOf(['end', 'start']),
    onIconClick: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    passwordStenghtMeter: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    roundedCorner: PropTypes.bool,
    onClick: PropTypes.func,
    readOnlyInput: PropTypes.bool,
    labelStyle: PropTypes.oneOf(['default', 'inline']),
    compact: PropTypes.bool,
    fullWidth: PropTypes.bool,
    isOrgProduct: PropTypes.bool
};

// export default withStyles(styles)(OutlinedV2)
export default connector(compose(withRouter, withStyles(styles))(OutlinedV2));
