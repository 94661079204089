import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import AppBarV2 from "../../../../AppBarV2";
import { Grid, CircularProgress, Avatar } from "@material-ui/core";
import classnames from "classnames";
import { Link } from "react-router-dom";
import CommonCss from "commonCss";
import { CommonFn } from 'services/commonFn';
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";

//assets
import deleteIcon from "assets/images/delete.svg";

import { updateDrawerStyle } from "redux/drawer/action";
import Image from 'Components/Common/image';
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { showSnackBar } from "redux/snackbar/action";
import Tooltip from "@material-ui/core/Tooltip";

import { fetchCompletedSurvey, fetchInProgressSurvey, showAddSurveyDialog, deleteSurvey, hideAddSurveyDialog } from "redux/survey/action";

const connectedProps = (state) => ({
  userType: state.authUser.user?.Role,
  user: state.authUser.user,
  isShowAddSurveyDialog: state.survey.isShowAddSurveyDialog,
  fetchInProgressSurvey: state.survey.fetchInProgressSurvey,
  fetchInProgressSurveyError: state.survey.fetchInProgressSurveyError,
  inProgressSurveys: state.survey.inProgressSurveys,
  inProgressSurveysTotal: state.survey.inProgressSurveysTotal,
  fetchCompletedSurveyProgress: state.survey.fetchCompletedSurveyProgress,
  fetchCompletedSurveyError: state.survey.fetchCompletedSurveyError,
  completedSurveys: state.survey.completedSurveys,
  completedSurveysTotal: state.survey.completedSurveysTotal,
  deleteSurveyProgress: state.survey.deleteSurveyProgress,
  deleteSurveyError: state.survey.deleteSurveyError,
  addNewSurveyProgress: state.survey.addNewSurveyProgress,
  addNewSurveyError: state.survey.addNewSurveyError,
  updateSurveyProgress: state.survey.updateSurveyProgress,
  updateSurveyError: state.survey.updateSurveyError,
  surveyData: state.survey.surveyData,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  deleteSurvey: deleteSurvey,
  showSnackBar: showSnackBar,
  showAddSurveyDialog: showAddSurveyDialog,
  fetchCompletedSurvey: fetchCompletedSurvey,
  fetchInProgressSurvey: fetchInProgressSurvey,
  hideAddSurveyDialog: hideAddSurveyDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    topContainer: {
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      "& [class*=MuiAvatar-root]": {
        width: 25,
        height: 25,
        fontSize: 14,
        marginRight: 2
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    title: {
      fontSize: theme.spacing(2.4),

    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 19,
      margin: "0px !important",
    },

    productTitleSection: {
      ...CommonCss.productTitleSection,
      marginTop: 40,
      marginBottom: 10,
    },
    ...CommonCss.Home,
    btnReview: {
      width: '38px',
      height: '32px',
      borderRadius: '5px',
      cursor: 'pointer',
      textAlign: 'center',
      fontSize: '13px',
      color: '#626675',
      paddingTop: 2,
      zIndex: 12,
      position: 'relative',
      border: '1px solid #EEEEEE',
      background: '#ffffff'
    },
    evaluateCard: {
      ...CommonCss.Home.evaluateCard,
      alignItems: 'center'
    },
    content: {
      padding: theme.spacing(4, 10),
      background: "#F7F7F7",
      // minHeight: "100vh"
    },
    productImageContainer: {
      width: 67,
      height: 57,
      padding: 13,
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 1px 6px #0000001A',
      borderRadius: 10,
      marginRight: 10
    },
    productImage: {
      width: '100%',
      height: 'auto',
      marginRight: theme.spacing(2)
    },
    evaluateCardLabel: {
      background: '#1f73b7',
      width: 'max-content',
      margin: 0,
      borderRadius: '4px',
      color: '#fff',
      padding: '2px 10px',
      fontSize: 10
    }
  });

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteId: null,
      query: "",
      page: 0,
      pageSize: 5,
      inPorgressStatus: [1, 2, 3],
      completedStatus: [4],
      items: [],
      isCartShow: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.avatarName = this.avatarName.bind(this);

  }
  avatarName(name) {
    return name.substring(0, 1);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.deleteSurveyProgress && prevProps.deleteSurveyProgress) {
      if (this.props.deleteSurveyError === null) {
        this.fetchData();
      } else {
        if (this.props.deleteSurveyError.message !== undefined) {
          this.props.showSnackBar("Failed to delete workflow", "error", 3000);
        } else {
          this.props.showSnackBar("Something went wrong", "error", 3000);
        }
      }
    }

  }

  fetchData() {
    this.props.fetchCompletedSurvey({ query: '', page: this.state.page, pageSize: this.state.pageSize, status: this.state.completedStatus, type: [4] });
    this.props.fetchInProgressSurvey({ query: '', page: this.state.page, pageSize: this.state.pageSize, status: this.state.inPorgressStatus, type: [4] });
  }

  routeChange = (item, id) => {
    let redirectPath = `/app/evaluate/${id}/workflow/interim`;
    this.props.history.push(redirectPath);
  }

  deleteReview(e, id) {
    e.stopPropagation();
    var me = this;
    this.setState({ deleteId: id }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteSurvey(this.state.deleteId);
    }
  };

  render() {
    const classes = this.props.classes;
    if (this.props.fetchInProgressSurveyProgress || this.props.fetchCompletedSurveyProgress) {
      return <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let userRole = ["OrgAdmin","OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;

    return (
      <div>
        <AppBarV2 title="Renewal Workflows" />
        <Grid container className={classnames(classes.content)}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="topContainer" className={classes.topContainer}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} className={classes.newEvaluation}>
                <LayeredCard
                  title="+ New Renewal"
                  color="CYAN"
                  numLayer={1}
                  image={AddEvaluationImage}
                  subTitle={'Tap to start a new renewal'}
                  onClick={() => {
                    let redirectPath = `/app/renewal`;
                    this.props.history.push(redirectPath);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.bottomContainer}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                <div className={classes.bottomHead}>
                  <p className={classnames(classes.productHead)}>In Progress</p>
                  {this.props.inProgressSurveysTotal > 5 &&
                    <Link
                      to="/app/renewal_workflows/in-progress"
                      className={classes.viewAll}
                    >
                      View all ({this.props.inProgressSurveysTotal.toLocaleString("en-US")})
                    </Link>
                  }
                </div>
                <div>
                  {this.props.inProgressSurveys && this.props.inProgressSurveys.map((item, index) =>
                    <div className={classes.evaluateCard} key={index} onClick={() => {
                      this.routeChange(item, item.ID)
                    }}>
                      <div>
                        <div className={classes.productImageContainer}>
                          {
                            item.Products?.slice(0, item.Products.length < 5 ? item.Products.length : 3).map((item, i) => {
                              return (<Image className={classes.productImage} src={item.ProductLogo} alt={item.Name} title={item.Name} />)
                            })
                          }
                        </div>
                      </div>
                      <div className={classes.evaluateTextSurvey}>
                        <p className={classes.evaluateCardTitle}>{item.Name}</p>
                        {/* <p className={classes.evaluateCardLabel}>{item?.Workflow?.Name}</p> */}
                        <p className={classes.evaluateCardSubTitle}>Updated At: {CommonFn.formateDate(item.UpdatedAt)}</p>
                      </div>
                      <div className={classes.evaluateLogo}>
                        {this.props.userType === 'OrgAdmin' && <div className={classes.productLogoContainer}>
                          {
                            item.Collaborators?.slice(0, item.Collaborators.length < 5 ? item.Collaborators.length : 3).map((item, i) => {
                              return (
                                <Tooltip
                                  placement="top"
                                  title={
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        whiteSpace: "pre-line",
                                        display: "inline-block",
                                        height: "25px",
                                        lineHeight: "25px",
                                      }}
                                    >
                                      {item.Name + "\n" + item.Email}
                                    </span>
                                  }
                                  arrow
                                >
                                  <Avatar key={i}>
                                    {" "}
                                    {this.avatarName(item.Name)}
                                  </Avatar>
                                </Tooltip>
                              )
                            })
                          }
                          {
                            item.Collaborators.length > 4 &&
                            <div className={classes.productLogo} title={item.Collaborators.length - 3 + ' more collaborators'}> + {item.Collaborators.length - 3}</div>
                          }
                        </div>}
                        {adminFlag && <button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={(e) => this.deleteReview(e, item.ID)}><Image
                          src={deleteIcon}
                          alt="forgotpassword_image"
                          className={classes.titleImage}
                        /></button>}
                      </div>
                    </div>)}
                  {this.props.fetchInProgressSurveyProgress &&
                    <span className={classes.loader}><CircularProgress /></span>
                  }
                  {!this.props.fetchInProgressSurveyProgress && this.props.inProgressSurveys?.length === 0 &&
                    <span className={classes.noMoreData}>No more data </span>
                  }
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                <div className={classes.bottomHead}>
                  <p className={classnames(classes.productHead)}>Completed</p>
                  {this.props.completedSurveysTotal > 5 &&
                    <Link
                      to="/app/renewal_workflows/completed"
                      className={classes.viewAll}
                    >
                      View all ({this.props.completedSurveysTotal.toLocaleString("en-US")})
                    </Link>
                  }
                </div>
                <div>
                  {this.props.completedSurveys && this.props.completedSurveys.map((item, index) => <div className={classes.evaluateCard} key={index} onClick={() => this.routeChange(item, item.ID)}>
                    <div>
                      <div className={classes.productImageContainer}>
                        {
                          item.Products?.slice(0, item.Products.length < 5 ? item.Products.length : 3).map((item, i) => {
                            return (<Image className={classes.productImage} src={item.ProductLogo} alt={item.Name} title={item.Name} />)
                          })
                        }
                      </div>
                    </div>
                    <div className={classes.evaluateTextSurvey}>
                      <p className={classes.evaluateCardTitle}>{item.Name}</p>
                      {/* <p className={classes.evaluateCardLabel}>{item?.Workflow?.Name}</p> */}
                      <p className={classes.evaluateCardSubTitle}>
                        Updated At: {CommonFn.formateDate(item.UpdatedAt)}</p>
                    </div>
                    <div className={classes.evaluateLogo}>
                      {this.props.userType === 'OrgAdmin' && <div className={classes.productLogoContainer}>
                        {
                          item.Collaborators?.slice(0, item.Collaborators.length < 5 ? item.Collaborators.length : 3).map((item, i) => {
                            return (
                              <Tooltip
                                placement="top"
                                title={
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      whiteSpace: "pre-line",
                                      display: "inline-block",
                                      height: "25px",
                                      lineHeight: "25px",
                                    }}
                                  >
                                    {item.Name + "\n" + item.Email}
                                  </span>
                                }
                                arrow
                              >
                                <Avatar key={i}>
                                  {" "}
                                  {this.avatarName(item.Name)}
                                </Avatar>
                              </Tooltip>
                            )
                          })
                        }
                        {
                          item.Collaborators.length > 4 &&
                          <div className={classes.productLogo} title={item.Collaborators.length - 3 + 'more collaborators'}> + {item.Collaborators.length - 3}</div>
                        }
                      </div>}
                      {adminFlag && <button type="button" className={classnames(classes.btnReview, classes.deleteIcon)} onClick={(e) => this.deleteReview(e, item.ID)}><Image
                        src={deleteIcon}
                        alt="forgotpassword_image"
                        className={classes.titleImage}
                      /></button>}
                    </div>
                  </div>)}
                  {this.props.fetchCompletedSurveyProgress &&
                    <span className={classes.loader}><CircularProgress /></span>
                  }
                  {!this.props.fetchCompletedSurveyProgress && this.props.completedSurveys?.length === 0 &&
                    <span className={classes.noMoreData}>No more data </span>
                  }
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MessageDialog messageData={messageData} callBack={this.callBack} />
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(Home));
