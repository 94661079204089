import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Tab,
  Tabs,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { createStyles, withStyles } from "@material-ui/core/styles";
import FavoriteOutlined from "@material-ui/icons/FavoriteOutlined";
// Asets
import classnames from "classnames";
import CommonCss from "commonCss";
import { CommonFn } from "services/commonFn";
import Image from "Components/Common/image.jsx";
import ReadMore from "Components/Common/ReadMore";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import AppBarV2 from "Components/Application/AppBarV2";
import InventoryTab from "./Components/InventoryTab";

const connectedProps = (state) => ({
  userRole: state.authUser.user?.Role,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dateTag: {
      color: "#2EB77D",
      fontSize: 12,
      marginTop: 3,
      marginBottom: 0,
    },
    ...CommonCss.ProductDetails,
    rootContainer: CommonCss.rootContainer,
    linkItem: {
      cursor: "pointer !important",
    },
    cursorTag: {
      fontSize: "12px",
      borderRadius: "25px",
      padding: "10px 14px",
    },
    emptyText: {
      textAlign: "center",
      height: 50,
      paddingTop: 20,
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    mailTo: {
      color: "#4A87F8 !important",
      textDecoration: "underline",
    },
    favLiked: {
      zIndex: 11,
      color: "#f44336",
    },
    whislistedUsers: {
      marginLeft: 20,
      display: "flex",
      cursor: "pointer",
      background: "#f1f1f1",
      borderRadius: "25px",
      padding: "5px 10px",
    },
    productLink: {
      ...CommonCss.productLink,
      marginBottom: 0,
    },
    contentSection: {
      marginTop: 20,
    },
    tabSection: {
      marginTop: 15,
      marginBottom: 15,
    },
    orgProductTab: {
      borderBottom: "1px solid #d8d8d8",
    },
    numberSection: {
      padding: 10,
      borderRadius: 10,
      border: "2px solid #CECECE",
      width: "100",
      height: "inherit",
    },
    nSTitle: {
      wordBreak: "break-word",
      fontWeight: 500,
      letterSpacing: "0px",
      color: "#20253A",
      opacity: 1,
      margin: "0px",
      marginBottom: "5px",
    },
    nSSubTitle: {
      fontWeight: 400,
      letterSpacing: "0px",
      color: "#20253A",
      opacity: 0.5,
      margin: "0px",
    },
    paper: {
      marginTop: 15,
      boxShadow: "none",
      border: "1px solid #ddd",
    },
    btn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 35,
      padding: 0,
      marginLeft: 15,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    }
  });

class HardwareProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      is404: false,
      showMoreProducts: false,
      showMoreCompanyProducts: false,
      showMoreComplementaryProducts: false,
      allFetchDoneCount: 0,
      viewMore: [],
      isCartShow: false,
      headerSectionWidth: null,
      sectionDatas: [
        {
          FolderName: "Header",
          Attributes: [
            {
              Key: "Manufacturer",
              Value: "Apple Notebook Systems",
            },
            {
              Key: "Brand",
              Value: "Apple",
            },
            {
              Key: "Product Line",
              Value: "Apple MacBook Pro",
            },
            {
              Key: "Localization",
              Value: "English",
            },
            {
              Key: "Country Kits",
              Value: "United States",
            },
            {
              Key: "Packaged Quantity",
              Value: "1",
            },
          ],
        },
        {
          FolderName: "System",
          Attributes: [
            {
              Key: "Notebook Type",
              Value: "Notebook",
            },
            {
              Key: "Platform",
              Value: "Apple Mac OS",
            },
            {
              Key: "Hard Drive Capacity",
              Value: "1 TB",
            },
            {
              Key: "Introduced",
              Value: "Late 2021",
            },
            {
              Key: "Security Devices",
              Value: "Fingerprint reader",
            },
          ],
        },
        {
          FolderName: "Chassis",
          Attributes: [
            {
              Key: "Sensors",
              Value: "Ambient light sensor",
            },
          ],
        },
        {
          FolderName: "Processor",
          Attributes: [
            {
              Key: "Type",
              Value: "M1 Pro",
            },
            {
              Key: "Number of Cores",
              Value: "10-core",
            },
            {
              Key: "Manufacturer",
              Value: "Apple",
            },
            {
              Key: "Features",
              Value: "16-core Neural Engine",
            },
          ],
        },
        {
          FolderName: "RAM",
          Attributes: [
            {
              Key: "Installed Size",
              Value: "16 GB",
            },
            {
              Key: "Configuration Features",
              Value: "Unified memory",
            },
          ],
        },
        {
          FolderName: "Hard Drive",
          Attributes: [
            {
              Key: "Type",
              Value: "SSD",
            },
            {
              Key: "SSD Form Factor",
              Value: "Soldered",
            },
            {
              Key: "Capacity",
              Value: "1 TB",
            },
          ],
        },
        {
          FolderName: "Optical Storage",
          Attributes: [
            {
              Key: "Drive Type",
              Value: "No optical drive",
            },
            {
              Key: "Type",
              Value: "None",
            },
          ],
        },
        {
          FolderName: "Card Reader",
          Attributes: [
            {
              Key: "Type",
              Value: "Card reader",
            },
            {
              Key: "Supported Flash Memory",
              Value: "SDXC Memory Card",
            },
          ],
        },
        {
          FolderName: "Display",
          Attributes: [
            {
              Key: "Type",
              Value: "LED",
            },
            {
              Key: "Touchscreen",
              Value: "No",
            },
            {
              Key: "LCD Backlight Technology",
              Value: "Mini LED backlight",
            },
            {
              Key: "Diagonal Size",
              Value: "14.2 inch",
            },
            {
              Key: "Diagonal Size (metric)",
              Value: "36.1 centimetre",
            },
            {
              Key: "Color Support",
              Value: "1 billion colors",
            },
            {
              Key: "Native Resolution",
              Value: "3024 x 1964",
            },
            {
              Key: "Widescreen Display",
              Value: "Yes",
            },
            {
              Key: "Pixel Density (ppi)",
              Value: "254",
            },
            {
              Key: "V-Sync Rate at Max Res.",
              Value: "120 hertz",
            },
            {
              Key: "Image Brightness",
              Value: "1000 candela per square metre",
            },
            {
              Key: "Bezel Type",
              Value: "Flat front",
            },
            {
              Key: "Monitor Features",
              Value:
                "1600 cd/m² peak brightness (HDR), Arsenic free, Beryllium free, BFR/PVC-free, Extreme Dynamic Range (XDR), Liquid Retina display, Mercury free, P3 Wide Color Gamut, ProMotion technology, True Tone technology",
            },
          ],
        },
        {
          FolderName: "Video Output",
          Attributes: [
            {
              Key: "Graphics Processor",
              Value: "Apple M1 Pro 16-core",
            },
            {
              Key: "Graphics Processor Series",
              Value: "Apple M1 Pro",
            },
            {
              Key: "Max Monitors Supported",
              Value: "2",
            },
            {
              Key: "Video System Features",
              Value:
                "Hardware-accelerated H.264, HEVC, ProRes and ProRes RAW, ProRes encode and decode engine, Video decode engine, Video encode engine",
            },
          ],
        },
        {
          FolderName: "Digital Camera",
          Attributes: [
            {
              Key: "Webcam Capability",
              Value: "Yes",
            },
            {
              Key: "Resolution",
              Value: "1080p",
            },
            {
              Key: "Features",
              Value:
                "Advanced image signal processor (ISP), FaceTime 1080p HD camera",
            },
          ],
        },
        {
          FolderName: "Audio Output",
          Attributes: [
            {
              Key: "Type",
              Value: "Six speakers",
            },
            {
              Key: "Compliant Standards",
              Value: "Dolby Atmos",
            },
          ],
        },
        {
          FolderName: "Audio Input",
          Attributes: [
            {
              Key: "Type",
              Value: "Three microphones",
            },
          ],
        },
        {
          FolderName: "Input Device",
          Attributes: [
            {
              Key: "Type",
              Value: "Force Touch trackpad, Keyboard",
            },
            {
              Key: "Backlight",
              Value: "Yes",
            },
            {
              Key: "Features",
              Value:
                "Ambient light sensor, Force click, Multi-Touch Gesture Recognition, Multi-touch touchpad, Pressure sensitivity, Touch ID sensor",
            },
          ],
        },
        {
          FolderName: "Keyboard",
          Attributes: [
            {
              Key: "Keyboard Name",
              Value: "Magic Keyboard",
            },
            {
              Key: "Keyboard Localization",
              Value: "US",
            },
            {
              Key: "Keyboard Layout",
              Value: "QWERTY",
            },
          ],
        },
        {
          FolderName: "Networking",
          Attributes: [
            {
              Key: "Data Link Protocol",
              Value:
                "Bluetooth 5.0, IEEE 802.11a, IEEE 802.11ac, IEEE 802.11ax (Wi-Fi 6), IEEE 802.11b, IEEE 802.11g, IEEE 802.11n",
            },
            {
              Key: "Wireless Protocol",
              Value: "802.11a/b/g/n/ac/ax, Bluetooth 5.0",
            },
          ],
        },
        {
          FolderName: "Interfaces",
          Attributes: [
            {
              Key: "USB-C Ports Qty",
              Value: "3",
            },
            {
              Key: "HDMI Ports Qty",
              Value: "1",
            },
            {
              Key: "Interfaces",
              Value:
                "3 x USB4 (supports Thunderbolt 4, DisplayPort Alt Mode), HDMI, Headphone output",
            },
          ],
        },
        {
          FolderName: "OS Provided",
          Attributes: [
            {
              Key: "Family",
              Value: "macOS",
            },
            {
              Key: "Edition",
              Value: "macOS Monterey 12.0",
            },
            {
              Key: "Type",
              Value: "Apple macOS Monterey 12.0",
            },
          ],
        },
        {
          FolderName: "Software",
          Attributes: [
            {
              Key: "Type",
              Value:
                "App Store, Apple FaceTime, Apple GarageBand, Apple iBooks, Apple iMovie, Apple Keynote, Apple Numbers, Apple Pages, Apple Pay, Apple Preview, Apple QuickTime, Apple Safari, Apple Time Machine, Apple TV, Calendar, Contacts, Dictation, Find My, Home, Mail, Maps, Messages, Music, News, Notes, Photo Booth, Photos, Podcasts, Reminders, Siri, Stocks, Voice Memos",
            },
          ],
        },
        {
          FolderName: "Power Device",
          Attributes: [
            {
              Key: "Power Provided",
              Value: "96 watt",
            },
            {
              Key: "Nominal Voltage",
              Value: "AC 120/230 V",
            },
            {
              Key: "Voltage Required",
              Value: "AC 100-240 volt",
            },
            {
              Key: "Frequency Required",
              Value: "50/60 hertz",
            },
          ],
        },
        {
          FolderName: "Battery",
          Attributes: [
            {
              Key: "Technology",
              Value: "Lithium polymer",
            },
            {
              Key: "Capacity",
              Value: "70 watt hour(s)",
            },
            {
              Key: "Run Time (Up To)",
              Value: "17 Hours",
            },
          ],
        },
        {
          FolderName: "Battery Life Details",
          Attributes: [
            {
              Key: "Battery Run Time",
              Value:
                "Video playback: up to 17 hours, Web browsing: up to 11 hours",
            },
          ],
        },
        {
          FolderName: "Miscellaneous",
          Attributes: [
            {
              Key: "Color",
              Value: "Space gray",
            },
            {
              Key: "Color Category",
              Value: "Gray",
            },
            {
              Key: "Case Material",
              Value: "Aluminum",
            },
            {
              Key: "Included Accessories",
              Value: "USB-C power adapter",
            },
            {
              Key: "Features",
              Value: "Voice Control, VoiceOver screen reader",
            },
          ],
        },
        {
          FolderName: "Environmental Standards",
          Attributes: [
            {
              Key: "ENERGY STAR Certified",
              Value: "Yes",
            },
          ],
        },
        {
          FolderName: "Dimensions & Weight",
          Attributes: [
            {
              Key: "Width",
              Value: "12.3 inch",
            },
            {
              Key: "Depth",
              Value: "8.7 inch",
            },
            {
              Key: "Height",
              Value: "0.6 inch",
            },
            {
              Key: "Weight",
              Value: "3.53 lbs",
            },
          ],
        },
        {
          FolderName: "Service & Support",
          Attributes: [
            {
              Key: "Type",
              Value: "1-year warranty",
            },
          ],
        },
        {
          FolderName: "Service & Support Details",
          Attributes: [
            {
              Key: "Service & Support",
              Value:
                "Limited warranty - 1 year, Technical support - phone consulting - 90 days",
            },
          ],
        },
        {
          FolderName: "Environmental Parameters",
          Attributes: [
            {
              Key: "Min Operating Temperature",
              Value: "50 degree Fahrenheit",
            },
            {
              Key: "Max Operating Temperature",
              Value: "95 degree Fahrenheit",
            },
            {
              Key: "Humidity Range Operating",
              Value: "0 - 90% (non-condensing)",
            },
          ],
        },
      ],
      row: [
        {
          under_warranty: "YES",
          purchage_on: "2022-01-11T11:26:02+0000",
          warronty_till: "2022-09-11T11:26:02+0000",
          allocated_to: "tonyStark@xyz.com",
        }, {
          under_warranty: "No",
          purchage_on: "2021-01-11T11:26:02+0000",
          warronty_till: "",
          allocated_to: "michaelJackson@xyz.com",
        }, {
          under_warranty: "No",
          purchage_on: "2020-01-11T11:26:02+0000",
          warronty_till: "",
          allocated_to: "",
        }, {
          under_warranty: "YES",
          purchage_on: "2019-01-11T11:26:02+0000",
          warronty_till: "2019-09-11T11:26:02+0000",
          allocated_to: "vinDiesel@xyz.com",
        }, {
          under_warranty: "YES",
          purchage_on: "2018-01-11T11:26:02+0000",
          warronty_till: "2018-09-11T11:26:02+0000",
          allocated_to: "",
        },
      ]
    };
  }

  redirectTo = (to) => {
    if (to) {
      this.props.history.push(`/app/products/all-products?filter=${to}`);
    } else {
      this.props.history.push(`/app/products/all-products`);
    }
  };

  render() {
    const classes = this.props.classes;

    const { productDetails } = this.props;
 
    let allocatedAt = false;
    const params = new URLSearchParams(this.props.location.search);
    if (params.get("allocated_to")) {
      allocatedAt = params.get("allocated_to");
    }
    return (
      <div>
        <AppBarV2 title="Hardware Product Details" withBack={true} dropdown={true} />
        <div
          className={classnames(classes.productDetails, classes.rootContainer)}
        >
          <div className={classes.productLeftSection}>
            <Image
              src={
                "https://webobjects2.cdw.com/is/image/CDW/6738975a?$product-detail$"
              }
              style={{ maxWidth: "90%" }}
            />
            <Grid container justify="center">
            </Grid>
            <ul className={classes.list}>
              <li className={classes.item}>
                <a
                  href="#about"
                  className={this.state.activeNumber === 0 ? "active" : ""}
                  onClick={() => this.setState({ activeNumber: 0 })}
                >
                  About Apple MacBook Pro
                </a>
              </li>
              {this.state.sectionDatas.map((item, k) => {
                return (
                  <li className={classes.item}>
                    <a
                      href={"#" + item.FolderName}
                      className={
                        this.state.activeNumber === k + 1 ? "active" : ""
                      }
                      onClick={() => this.setState({ activeNumber: k + 1 })}
                    >
                      {item.FolderName}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={classes.productRightSection}>
            <div className={classes.ProductNameSection}>
              <p className={classes.productName}>Apple MacBook Pro - 14.2</p>

              <Button
                onClick={(e) => {
                  // this.addToCart(productDetails);
                }}
                className={classes.filterSection}
              >
                + Review
              </Button>

              {this.props.userRole !== undefined && this.props.userRole !== null && this.props.userRole === "OrgUser" && <Button
                onClick={(e) => {
                  // this.addToCart(productDetails);
                }}
                className={classes.filterSection}
              >
                Request Asset
              </Button>}

              {false && this.props.userRole === "OrgAdmin" &&
                Object.keys(this.props.productWishlistedUsers).length > 0 &&
                this.props.productWishlistedUsers.Data.length > 0 && (
                  <Typography
                    className={classes.whislistedUsers}
                    onClick={() => {
                      this.props.showProductWhistedUserDialog(
                        productDetails.ID
                      );
                    }}
                  >
                    <FavoriteOutlined className={classes.favLiked} /> &nbsp;{" "}
                    {this.props.productWishlistedUsers.Data.length}
                  </Typography>
                )}
            </div>

            <Grid container alignItems="center">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={
                  "https://webobjects2.cdw.com/is/image/CDW/6738975a?$product-detail$"
                }
                className={classes.productLink}
              >
                By Apple
              </a>
              &nbsp;&nbsp;
              {allocatedAt && (
                <Alert severity="success">
                  This is allocated to you at{" "}
                  {CommonFn.formateDate(allocatedAt)}
                </Alert>
              )}
            </Grid>

            <div className={classes.tabSection}>
              <Tabs
                value={this.state.tabValue}
                onChange={(event, newValue) => {
                  this.setState({ tabValue: newValue });
                }}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabWidth}
              >
                <Tab
                  className={classes.orgProductTab}
                  label="Basic Details"
                  key="basic-details"
                />
                {this.props.userRole !== undefined && this.props.userRole !== null && this.props.userRole === "OrgAdmin" && <Tab
                  className={classes.orgProductTab}
                  label="Inventory"
                  key="inventory"
                />}
              </Tabs>
            </div>
            {this.state.tabValue === 0 && (
              <div
                className={classes.contentSection}
                onScroll={(e) => this.scrollContent(e)}
              >
                <div className={classes.itemSection} id="about">
                  <p className={classes.head}>About Apple MacBook Pro</p>
                  <p className={classes.description}>
                    <ReadMore charLimit={1500}>
                      MacBook Pro delivers groundbreaking processing, graphics,
                      and machine learning (ML) performance whether running on
                      battery or plugged in, as well as amazing battery life -
                      enabling workflows previously unimaginable on a notebook.
                      It also features a stunning Liquid Retina XDR display, a
                      wide range of ports for advanced connectivity, a 1080p
                      FaceTime HD camera, and great audio system. Shattering the
                      limits of what a notebook can do, MacBook Pro is designed
                      for developers, photographers, filmmakers, 3D artists,
                      scientists, music producers, and anyone who wants a
                      powerful notebook.
                    </ReadMore>
                  </p>
                </div>

                {this.state.sectionDatas.map((item, k) => {
                  return (
                    <div className={classes.itemSection} id={item.FolderName}>
                      <p className={classes.head}>{item.FolderName}</p>
                      <p className={classes.description}>
                        <div style={{ width: "50%" }}>
                          {item.Attributes.map((att, k) => {
                            return (
                              <div style={{ display: "flex" }}>
                                <div style={{ flex: 1 }}>
                                  <Typography style={{ fontWeight: 400 }}>
                                    {att.Key}
                                  </Typography>
                                </div>
                                <div style={{ flex: 1 }}>
                                  <Typography>{att.Value}</Typography>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
            {this.state.tabValue === 1 && (
              <InventoryTab />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(HardwareProductDetails));
