import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";
import ToolTip from "Components/Common/ToolTip.jsx";
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, LinearProgress, CircularProgress, Paper } from "@material-ui/core";
import { showSnackBar } from "redux/snackbar/action";
import { fetchOauthScopes, fetchMasterOauthScopes, addOauthScope, removeOauthScope, showScopeAccessDialog } from "redux/oauth/action";

const connectedProps = (state) => ({
  fetchOauthScopeProgress: state.oauth.fetchOauthScopeProgress,
  fetchOauthScopeError: state.oauth.fetchOauthScopeError,
  oauthScopes: state.oauth.oauthScopes,
  fetchMasterOauthScopeProgress: state.oauth.fetchMasterOauthScopeProgress,
  fetchMasterOauthScopeError: state.oauth.fetchMasterOauthScopeError,
  oauthMasterScopes: state.oauth.oauthMasterScopes,
  removeOauthScopeProgress: state.oauth.removeOauthScopeProgress,
  removeOauthScopeError: state.oauth.removeOauthScopeError,
  removeOauthScopeSuccess: state.oauth.removeOauthScopeSuccess,
  addOauthScopeProgress: state.oauth.addOauthScopeProgress,
  addOauthScopeError: state.oauth.addOauthScopeError,
  addOauthScopeSuccess: state.oauth.addOauthScopeSuccess,
});

const connectionActions = {
  showScopeAccessDialog: showScopeAccessDialog,
  fetchOauthScopes: fetchOauthScopes,
  fetchMasterOauthScopes: fetchMasterOauthScopes,
  removeOauthScope: removeOauthScope,
  addOauthScope: addOauthScope,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    tickBar: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#4b86ff",
      fontWeight: "500",
      gap: "8px",
      fontSize: "14px",
      "& :hover": {
        // background: "#f7f7f7",
        borderRadius: 10,
      },
    },
  });

class Scopes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tabValue: 0,
      grantedAccessIds: [],
      masterScopes: [],
    };
  }

  componentDidMount() {
    this.fetchScopesData();
    this.props.fetchMasterOauthScopes();
  }

  fetchScopesData = () => {
    this.props.fetchOauthScopes(this.props.oauth?.ClientID);
  };

  componentDidUpdate(prevProps) {
    const {
      fetchOauthScopeProgress,
      fetchOauthScopeError,
      fetchMasterOauthScopeProgress,
      fetchMasterOauthScopeError,
      removeOauthScopeProgress,
      removeOauthScopeError,
      addOauthScopeProgress,
      addOauthScopeError,
    } = this.props;

    if (!removeOauthScopeProgress && prevProps.removeOauthScopeProgress) {
      if (removeOauthScopeError === null) {
        this.props.showSnackBar("Access revoked successfully.", "success", 3000);
        this.setState({ masterScopes: this.props.oauthMasterScopes });
      } else {
        this.props.showSnackBar("Failed to revoke access.", "error", 3000);
        this.props.fetchOauthScopes(this.props.oauth?.ID);
      }
    }

    if (!addOauthScopeProgress && prevProps.addOauthScopeProgress) {
      if (addOauthScopeError === null) {
        this.props.showSnackBar("Access granted successfully.", "success", 3000);
        this.setState({ masterScopes: this.props.oauthMasterScopes });
      } else {
        this.props.fetchOauthScopes(this.props.oauth?.ID);
        this.props.showSnackBar("Failed to grant access.", "error", 3000);
      }
    }

    if (!fetchMasterOauthScopeProgress && prevProps.fetchMasterOauthScopeProgress) {
      if (fetchMasterOauthScopeError) {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      } else {
        this.setState({ masterScopes: this.props.oauthMasterScopes });
      }
    }

    if (!fetchOauthScopeProgress && prevProps.fetchOauthScopeProgress) {
      if (fetchOauthScopeError) {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      } else {
        let grantedIds = this.props.oauthScopes.map((o) => o.ID);
        this.setState({ grantedAccessIds: grantedIds });
      }
    }
  }

  setLoadingState = (itemId) => {
    let masterScopes = [...this.state.masterScopes];
    let index = masterScopes.findIndex((o) => o.ID === itemId);
    let item = masterScopes[index];
    item = { ...item, showLoader: true };
    masterScopes[index] = item;
    return masterScopes;
  };

  removeScope = (clientId, itemId) => {
    let data = this.setLoadingState(itemId);
    let grantedIds = [...this.state.grantedAccessIds];
    let index = grantedIds.indexOf(itemId);
    if (index > -1) {
      grantedIds.splice(index, 1);
    }
    this.setState(
      {
        masterScopes: data,
        grantedAccessIds: grantedIds,
      },
      () => {
        this.props.removeOauthScope(clientId, itemId);
      }
    );
  };

  addScope = (clientId, itemId) => {
    let data = this.setLoadingState(itemId);
    let grantedIds = [...this.state.grantedAccessIds];
    grantedIds.push(itemId);
    this.setState(
      {
        masterScopes: data,
        grantedAccessIds: grantedIds,
      },
      () => {
        this.props.addOauthScope(clientId, itemId);
      }
    );
  };

  printAccess = (id, loading) => {
    let exist = this.state.grantedAccessIds.find((ID) => ID === id);

    if (exist) {
      return loading ? (
        <div className={this.props.classes.tickBar}>
          <CircularProgress style={{ width: 20, height: 20 }} />
        </div>
      ) : (
        <div
          className={this.props.classes.tickBar}
          onClick={() => {
            this.removeScope(this.props.oauth?.ClientID, id);
          }}
        >
          <BlockIcon fontSize="small" /> Revoke{" "}
        </div>
      );
    } else {
      return loading ? (
        <div className={this.props.classes.tickBar}>
          <CircularProgress style={{ width: 20, height: 20 }} />{" "}
        </div>
      ) : (
        <div
          className={this.props.classes.tickBar}
          onClick={() => {
            this.addScope(this.props.oauth?.ClientID, id);
          }}
        >
          <CheckIcon fontSize="small" /> Grant{" "}
        </div>
      );
    }
  };

  render() {
    const { classes, fetchOauthScopeProgress, fetchMasterOauthScopeProgress } = this.props;

    return (
      <Paper style={{ width: "100%", marginTop: 30, overflow: "hidden" }}>
        <TableContainer style={{ maxHeight: 650 }}>
          <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={"table_" + this.props.key}>
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>Scope</TableCell>
                <TableCell align="center" style={{ width: "20%" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchMasterOauthScopeProgress || fetchOauthScopeProgress ? (
                <TableRow>
                  <TableCell colSpan={3} style={{ textAlign: "center", padding: 0 }}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              ) : (
                this.state.masterScopes.map((row, key) => (
                  <TableRow id={key}>
                    <TableCell>{key + 1}</TableCell>
                    <TableCell className={classes.nameCol}>
                      {row.Name} <ToolTip showCustom={true} toolTip={row.Description} />
                    </TableCell>
                    <TableCell className={classes.statusCol}>{this.printAccess(row.ID, row.showLoader)}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Scopes));
