import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment";
import { Types } from "./types";

function startAddCompanySettings() {
  return {
    type: Types.START_ADD_COMPANY_PROFILE_SETTINGS,
    payload: null,
  };
}

function endAddCompanySettings(success, error) {
  return {
    type: Types.END_ADD_COMPANY_PROFILE_SETTINGS,
    payload: {
      success,
      error,
    },
  };
}

function preparePayloadFormat(data) {
  const payloadList = [];

  function addItemToPayload(item, key) {
    payloadList.push({ name: key, value: JSON.stringify(item) });
  }

  for (const section of data) {
    const sectionNameSlug = section.sectionName.replace(/\s+/g, "-").toLowerCase();

    if (section.items) {
      for (let i = 0; i < section.items.length; i++) {
        const item = section.items[i];
        const indexSlug = (i + 1).toString().padStart(2, "0");
        const labelSlug = item.label.replace(/\s+/g, "-").toLowerCase();
        const itemKey = `${sectionNameSlug}-${indexSlug}-${labelSlug}`;
        addItemToPayload(item, itemKey);
      }
    }

    if (section.itemGroups) {
      addItemToPayload(section.itemGroups, section.sectionKey);
    }
  }

  return payloadList;
}

function convertResponse(payloadList) {
  const responseList = [];

  for (const payload of payloadList) {
    const { name, value } = payload;
    const parsedValue = JSON.parse(value);

    if (name.includes("-group-")) {
      // For itemGroups
      responseList.push({ name: name, value: { items: parsedValue } });
    } else {
      // For items
      responseList.push({ name: name, value: parsedValue });
    }
  }

  return responseList;
}

export function addCompanySettings(settings = []) {
  return async (dispatch) => {
    // const payloadList = preparePayloadFormat(settings);
    // const responseList = convertResponse(payloadList);
    // console.log(payloadList);
    // console.log(responseList);
    dispatch(startAddCompanySettings());
    try {
      const api_server = Environment.api_host("USER");
      const timestamp = new Date().getTime();
      const url = `${api_server}/org-profile-meta?t=${timestamp}`;
      let [response, error] = await HttpFactory.instance().postMethod(url, {
        meta: settings,
      });
      dispatch(endAddCompanySettings(response, error));
    } catch (e) {
      dispatch(
        endAddCompanySettings(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchAllCompanySettings() {
  return {
    type: Types.START_FETCH_ALL_COMPANY_PROFILE_SETTINGS,
    payload: null,
  };
}

function endFetchAllCompanySettings(success, error) {
  return {
    type: Types.END_FETCH_ALL_COMPANY_PROFILE_SETTINGS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchAllCompanySettings() {
  return async (dispatch) => {
    dispatch(startFetchAllCompanySettings());
    try {
      const api_server = Environment.api_host("USER");
      const timestamp = new Date().getTime();
      const url = `${api_server}/all-org-profile-meta?t=${timestamp}`;
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endFetchAllCompanySettings(response, error));
    } catch (e) {
      dispatch(
        endFetchAllCompanySettings(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchOrgMetaRequests() {
  return {
    type: Types.START_FETCH_ORG_META_REQUESTS,
    payload: null,
  };
}

function endFetchOrgMetaRequests(success, error) {
  return {
    type: Types.END_FETCH_ORG_META_REQUESTS,
    payload: {
      success,
      error,
    },
  };
}
export function fetchOrgMetaRequests(params) {
  return async (dispatch) => {
    dispatch(startFetchOrgMetaRequests());
    try {
      const api_server = Environment.api_host("PRODUCT");
      const timestamp = new Date().getTime();
      const url = `${api_server}/request/org_meta_requests?t=${timestamp}`;
      let [response, error] = await HttpFactory.instance().postMethod(url, params);
      dispatch(endFetchOrgMetaRequests(response, error));
    } catch (e) {
      dispatch(
        endFetchOrgMetaRequests(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startUpdateOrgMetaRequestStatus() {
  return {
    type: Types.START_UPDATE_ORG_META_REQUEST_STATUS,
    payload: null,
  };
}

function endUpdateOrgMetaRequestStatus(success, error) {
  return {
    type: Types.END_UPDATE_ORG_META_REQUEST_STATUS,
    payload: {
      success,
      error,
    },
  };
}

export function updateOrgMetaRequestStatus(requestId, params) {
  return async (dispatch) => {
    dispatch(startUpdateOrgMetaRequestStatus());
    try {
      const api_server = Environment.api_host("PRODUCT");
      const timestamp = new Date().getTime();
      const url = `${api_server}/request/org_meta_request/${requestId}?t=${timestamp}`;
      let [response, error] = await HttpFactory.instance().putMethod(url, params);
      dispatch(endUpdateOrgMetaRequestStatus(response, error));
    } catch (e) {
      dispatch(
        endUpdateOrgMetaRequestStatus(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchOrgMetaRequestData() {
  return {
    type: Types.START_FETCH_ORG_META_REQUEST_DATA,
    payload: null,
  };
}

function endFetchOrgMetaRequestData(success, error) {
  return {
    type: Types.END_FETCH_ORG_META_REQUEST_DATA,
    payload: {
      success,
      error,
    },
  };
}

export function fetchOrgMetaRequestData(orgId, requestId) {
  return async (dispatch) => {
    dispatch(startFetchOrgMetaRequestData());
    try {
      const api_server = Environment.api_host("PRODUCT");
      const timestamp = new Date().getTime();
      const url = `${api_server}/request/${orgId}/${requestId}?t=${timestamp}`;
      let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
      dispatch(endFetchOrgMetaRequestData(response, error));
    } catch (e) {
      dispatch(
        endFetchOrgMetaRequestData(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

export const START_STORE_DATA = "START_STORE_DATA";
export const END_STORE_DATA = "END_STORE_DATA";
export const RESET_STORE_DATA = "RESET_STORE_DATA";

export function startStoreData() {
  return {
    type: START_STORE_DATA,
    payload: {},
  };
}

export function endStoreData(data, error) {
  return {
    type: END_STORE_DATA,
    payload: {
      data,
      error,
    },
  };
}

export function resetStoreData() {
  return {
    type: RESET_STORE_DATA,
    payload: null,
  };
}

export function storeData(data) {
  // Perform any necessary logic with the data before storing it in the Redux state
  // You can also use an async operation here if needed.

  return async (dispatch) => {
    dispatch(startStoreData());

    try {
      // Perform any data storage logic here
      // For example, you can use an API call or update the state directly.
      // For simplicity, let's just store the data in the payload for this example.
      dispatch(endStoreData(data, null));
    } catch (e) {
      dispatch(
        endStoreData(null, {
          message: e.message,
        })
      );
    }
  };
}
