import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import queryString from "query-string";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import AppBarV2 from "../../../AppBarV2";
import AddProductButton from "../../../../Common/AddProductButton";
import LayeredCard from "../../Evaluate/Components/Common/LayeredCard";
import SearchInput from "../../../../Common/Input/SearchInput";
// redux
import {
  productSearch,
  fetchProductReaction,
  fetchSubCategoryWiseProducts,
  fetchCategoryWiseProducts,
  fetchWishListedProducts,
} from "../../../../../redux/product/action";
import { updateDrawerStyle } from "../../../../../redux/drawer/action";
import { showAddProductManuallyDialog } from "../../../../../redux/productsSettings/action";
import {
  createEvaluationCart,
  removeProductFromCart,
  fetchEvaluationCart,
} from "../../../../../redux/evaluation/cart/action";
import { fetchCategorySubCategory } from "../../../../../redux/product/subcategory/action";
// Dilogs
import Image from "../../../../../Components/Common/image.jsx";
import CommonCss from "../../../../../commonCss";
import Category from "../../../../../assets/images/category.svg";
import viewMoreImage from "assets/images/icons/viewMore.svg"
import ProductTypeFilter from "Components/Common/Filters/ProductTypeFilter";
import { isEnabledEvaluate } from "util/Common";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action"


const connectedProps = (state) => ({
  productSearchError: state.products.productSearchError,
  evaluationCart: state.evaluationCart.evaluation,
  productCategory: state.products.productCategory,
  subCategories: state.productSubCategory.catSubCategories,
  subCategoryTotal: state.productSubCategory.catSubCategoryTotal,
  catSubCategoryProgress: state.productSubCategory.catSubCategoryProgress,
  productFilters: state.products.productFilters,
  productsRec: state.products,
  fetchProductReactionByTypeProgress:
    state.products.fetchProductReactionByTypeProgress,
  deleteProductStateProg: state.products.deleteProductStateProg,
  addProductStateProg: state.products.addProductStateProg,
  allProductfilterData: state.products.allProductfilterData,
  orgUser: state.orgUser,
  userRole: state.authUser.user?.Role,

  fetchExpentDirectoryProductsProgress: state.expentDirectory.fetchExpentDirectoryProductsProgress,
  fetchExpentDirectoryProductsError: state.expentDirectory.fetchExpentDirectoryProductsError,
  expentDirectoryProducts: state.expentDirectory.products,
});

const connectionActions = {
  productSearch: productSearch,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  fetchProductReaction: fetchProductReaction,
  updateDrawerStyle: updateDrawerStyle,
  fetchCategorySubCategory: fetchCategorySubCategory,
  fetchEvaluationCart: fetchEvaluationCart,
  fetchProducts: fetchSubCategoryWiseProducts,
  fetchCategoryWiseProducts: fetchCategoryWiseProducts,
  fetchWishListedProducts: fetchWishListedProducts,
  fetchExpentDirectoryProducts:fetchExpentDirectoryProducts
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 2),
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
    },
    productContainer: {
      maxHeight: "calc(100vh - 230px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 230px)",
      padding: theme.spacing(4, 0),
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    label: {
      fontSize: "1.4em",
      fontWeight: 600,
      lineHeight: "1.6",
      marginBottom: 10,
    },
    productMain: {
      flex: 1,
    },
    productHead: {
      ...CommonCss.productHead,
      fontSize: 18,
      margin: "0px !important",
    },
    productMenu: {
      background: "#FFFFFF",
      boxShadow: "0px 2px 10px #0000000B",
      padding: theme.spacing(4, 0, 4, 2.8),
      width: 270,
      minHeight: "100vh",
      position: "fixed",
    },
    menuHead: {
      display: "flex",
      marginBottom: 18,
    },
    container: {
      display: "flex",
    },
    topContainer: {
      marginTop: theme.spacing(3),
    },
    headSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterSection: {
      ...CommonCss.filterSection,
      marginRight: 20,
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
    },
    title: {
      fontSize: theme.spacing(2.4),
      flex: 1,
    },
    categoriesText: {
      color: "#000000",
      fontSize: 18,
      marginLeft: 16,
      fontWeight: 500,
    },
    categoriesList: {
      marginTop: theme.spacing(6.4),
      minHeight: 200,
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    main: {
      maxWidth: "calc(100% - 280px)",
      flexBasis: "calc(100% - 280px)",
    },
    subCategory: {
      height: "calc(100vh - 120px)",
      overflowY: "overlay",
    },
    innerText: {
      color: "#696B6E",
      padding: theme.spacing(1, 1.5, 1, 1),
      cursor: "pointer",
      display: "block",
      margin: 0,
    },
    "@global": {
      ".applicationContent": {
        padding: "0 !important",
      },
    },
    products: {
      display: "flex",
    },
    activeSubCategory: {
      color: "#4A87F8",
      background: "#F2F7FF",
      fontSize: 13,
      marginRight: theme.spacing(3.2),
    },
    emptyMessage: {
      textAlign: "center",
      flex: 1,
      "& p": {
        margin: 5,
      },
    },
    suggestText: {
      fontWeight: 600,
    },
    filter: {
      // flex: 1,
      textAlign: "right",
    },
    txtCenter: {
      textAlign: "center",
      marginBottom: 16,
    },
    subCategories: {
      background: '#fff',
      maxWidth: 250,
      padding: theme.spacing(1, 0, 1, 2)
    }
  });

class CategoryWiseProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      items: [],
      page: 0,
      pageSize: 60,
      subCategoryPageSize: 500,
      subCategoryPage: 0,
      activeSubCategoryId: null,
      activeSubCategory: {},
      subCatsClicked: false,
      headerSectionWidth: null,
      queryParams: queryString.parse(this.props.location.search),
      checkBoxFilterStatus: {
        featured: false,
        wishlisted: false,
        org_wishlisted: false,
        all_product_blocking: false,
      },
      isCheckBoxFilter: false,
      selectedCheckBoxFilter: "",
      filters: {},
      selectedState: [],
      scrollToSubcat: true,
      searchObj: {
        field_setname: 'all',
        text: '',
        filter_fields: {},
        page: 0,
        page_size: 100,
        sort_fields: []
      },
      productType: 'SOFTWARE',
    };
  }

  componentDidMount() {
    this.props.fetchWishListedProducts();
    this.props.fetchCategorySubCategory(
      this.props.match.params.category_id,
      this.state.page,
      this.state.subCategoryPageSize,
      this.state.query
    );
    const urlPrams = new URLSearchParams(this.props.location.search);
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);

    var evId = urlPrams.get("evaluationId");
    if (evId !== null) {
      setTimeout(() => {
        this.props.fetchEvaluationCart(evId);
      }, 1000);
    }
    if (urlPrams.get("subcategory") !== null) {
      this.setState(
        {
          activeSubCategoryId: parseInt(urlPrams.get("subcategory")),
        },
        () => {
          this.props.fetchProducts(
            this.props.match.params.category_id,
            this.state.activeSubCategoryId,
            this.state.page,
            this.state.pageSize,
            "",
            {
              agg: true,
              filters: this.props?.allProductfilterData?.filters,
            }
          );
        }
      );
    } else {
      this.props.fetchCategoryWiseProducts(
        this.props.match.params.category_id,
        this.state.page,
        this.state.pageSize,
        "",
        false,
        {
          agg: true,
          filters: this.props?.allProductfilterData?.filters,
        }
      );
    }
    if (urlPrams.get("s") !== null) {
      this.setState(
        {
          searchObj: { ...this.state.searchObj, text: urlPrams.get("s") },
        },
        () => this.fetchData()
      );
    } else {
      this.fetchData();
    }
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isSeller && !this.props.fetchExpentDirectoryProductsProgress && prevProps.fetchExpentDirectoryProductsProgress) {
      let prodIDs = [];
      let orgIDs = [];
      if (
        this.props.expentDirectoryProducts?.others?.items &&
        this.props.expentDirectoryProducts?.others?.items.length > 0
      ) {
        prodIDs = this.props.expentDirectoryProducts?.others?.items.map((p) =>
          parseInt(p.id)
        );
      }
      if (
        this.props.expentDirectoryProducts?.existing?.items &&
        this.props.expentDirectoryProducts?.existing?.items.length > 0
      ) {
        orgIDs = this.props.expentDirectoryProducts?.existing?.items.map((p) =>
          parseInt(p.id)
        );
      }
      this.getProductReactions([...orgIDs, ...prodIDs]);
    }

    if (
      !this.props.isSeller &&
      !this.props.fetchProductReactionByTypeProgress &&
      prevProps.fetchProductReactionByTypeProgress
    ) {
      var reactionProducts = this.props.productsRec?.wishlistProduct.Data || [];
      if (reactionProducts.length > 0) {
        const productIDs = reactionProducts.map((o) => o.ID);
        this.getProductReactions(productIDs);
      }
    }

    const urlPrams = new URLSearchParams(this.props.location.search);
    if (urlPrams.get("subcategory") !== null && this.state.scrollToSubcat) {
      const target = document.getElementById(urlPrams.get("subcategory"));
      const parent = document.getElementById("subcat-container");
      if (target && parent) {
        parent.scrollTop = target.offsetTop - parent.offsetTop;
        this.setState({ scrollToSubcat: false });
      }
    }
    this.updateDimensions();
  }

  fetchProductsData = () => {
    this.props.fetchProducts(
      this.props.match.params.category_id,
      this.state.activeSubCategoryId,
      this.state.page,
      this.state.pageSize,
      this.state.query,
      {
        agg: true,
        filters: this.props.allProductfilterData.filters,
      }
    );
    this.props.fetchCategoryWiseProducts(
      this.props.match.params.category_id,
      this.state.page,
      this.state.pageSize,
      this.state.query,
      false,
      {
        agg: true,
        filters: this.props.allProductfilterData.filters,
      }
    );
  };

  getProductReactions(productIDs) {
    this.props.fetchProductReaction("all_product_blocking", {
      productIDs,
      source: "all_product_blocking",
      sourceData: "all_product_blocking",
    });
  }

  updateDimensions = () => {
    const headerSectionWidth = this.divElement.clientWidth;
    if (this.state.headerSectionWidth !== headerSectionWidth) {
      this.setState({ headerSectionWidth });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  fetchData() {
    const { searchObj, filters, selectedState } = this.state;
    searchObj.filter_fields = {};
    if (filters?.feature_ids && filters?.feature_ids.length > 0) {
      searchObj.filter_fields["feature_ids"] = filters?.feature_ids;
    }
    if (filters?.platform_ids && filters?.platform_ids.length > 0) {
      searchObj.filter_fields["platform_ids"] = filters?.platform_ids;
    }
    if (selectedState && selectedState.length > 0) {
      searchObj.filter_fields["state"] = selectedState;
    }
    if (this.props.match.params.category_id) {
      searchObj.filter_fields["category_ids"] = [
        parseInt(this.props.match.params.category_id),
      ];
    }
    const urlParams = new URLSearchParams(this.props.location.search);
    if (urlParams.get("subcategory") !== null) {
      searchObj.filter_fields["subcategory_ids"] = [
        parseInt(urlParams.get("subcategory")),
      ];
    }

    var searchText = '';
    if (searchObj?.text.length > 2) {
      searchText = searchObj?.text
    }
    searchObj.text = searchText;
    this.props.fetchExpentDirectoryProducts(searchObj);
  }

  addToCart(item) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(parseInt(item.id));
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(parseInt(item.id)) !== -1) {
        this.props.removeProductFromCart(
          this.props.evaluationCart.ID,
          parseInt(item.id)
        );
      } else {
        this.props.createEvaluationCart(
          parseInt(item.id),
          this.props.evaluationCart.ID
        );
      }
    }
  }

  productStates = (obj) => {
    if (obj) {
      const data = Object.keys(obj).map((key) => ({
        StateName: key,
        StateID: obj[key],
      }));
      return data;
    } else {
      return [];
    }
  };

  setSearchType = (type) => {
    const searchObj = this.state.searchObj;
    if (type === "features") {
      searchObj.type = "features";
    } else if (type === "subcategories") {
      searchObj.type = "subcategories";
    }
    searchObj.page = 0;
    searchObj.text = "";
    this.setState({ searchObj }, () => {
      this.fetchData();
    });
  };

  handleOnClickState(newState) {
    let selectedState = this.state.selectedState;
    if (selectedState.includes(newState)) {
      selectedState = selectedState.filter((o) => o !== newState);
    } else {
      selectedState.push(newState);
    }
    this.setState(
      { selectedState, searchObj: { ...this.state.searchObj, page: 0 } },
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    const {
      classes,
      expentDirectoryProducts,
      fetchExpentDirectoryProductsProgress,
      deleteProductStateProg,
      addProductStateProg,
      isSeller,
    } = this.props;
    const { searchObj, headerSectionWidth } = this.state;
    var addedToCart =
      this.props.evaluationCart == null
        ? []
        : this.props.evaluationCart.Products.map((p) => p.ID);
    let spliter = 5;
    if (headerSectionWidth < 768) spliter = 1;
    if (headerSectionWidth >= 768 && headerSectionWidth < 1280) spliter = 3;
    if (headerSectionWidth >= 1280 && headerSectionWidth <= 1365) spliter = 4;

    const totalCount = this.props.expentDirectoryProducts?.others?.total || 0;
    const activeSubcategory = this.props.subCategories.filter((s) => {
      return s.ID === this.state.activeSubCategoryId;
    });
    let titleString = "All";
    // if (activeSubcategory.length > 0) {
    //   titleString = activeSubcategory[0].Name;
    // }
    if (activeSubcategory.length > 0) {
      if (this.props.productCategory != null) {
        titleString =
          this.props.productCategory?.Name + " > " + activeSubcategory[0].Name;
      }
    } else {
      if (this.props.productCategory != null) {
        titleString = this.props.productCategory?.Name;
      }
    }

    const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForUsersCheck')
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForAdminsCheck')
    let isShowCheckBox = true;
    if (((!EnableEvaluateForAdminsCheck && this.props.userRole !== 'OrgUser') || (!EnableEvaluateForUsersCheck && this.props.userRole === 'OrgUser'))) {
        isShowCheckBox = false;
    }
    return (
      <>
        <div className={classes.container}>
          <div className={classes.subCategories}>
            <div className={classes.menuHead}>
              <Image src={Category} alt="Categories" />
              <p className={classes.categoriesText}>
                Sub Categories{" "}
                {this.props.subCategoryTotal > 0
                  ? `(${this.props.subCategoryTotal})`
                  : ""}
              </p>
            </div>
            <div className={classes.subCategory} id="subcat-container">
              <p
                className={classnames(
                  classes.innerText,
                  this.state.activeSubCategoryId === null
                    ? classes.activeSubCategory
                    : ""
                )}
                onClick={() => {
                  this.props.history.push(
                    "/app/products/category-wise-product/" +
                    this.props.match.params.category_id
                  );
                  setTimeout(() => {
                    this.fetchData();
                  }, 200);
                }}
              >
                <b>All</b>
              </p>
              {this.props.subCategories?.map((subCategory, index) => (
                <p
                  id={subCategory.ID}
                  key={index}
                  className={classnames(
                    classes.innerText,
                    this.state.activeSubCategoryId === subCategory.ID
                      ? classes.activeSubCategory
                      : ""
                  )}
                  onClick={() => {
                    this.setState({ activeSubCategoryId: subCategory.ID });
                    this.props.history.push(
                      "/app/products/category-wise-product/" +
                      this.props.match.params.category_id +
                      "?subcategory=" +
                      subCategory.ID
                    );
                    setTimeout(() => {
                      this.fetchData();
                    }, 200);
                  }}
                >
                  {subCategory.Name}
                </p>
              ))}
            </div>
          </div>
          <div className={classes.productMain}>
            <AppBarV2 title={titleString} withBack={true} dropdown={true} />
            <div className={classes.root}>
              <div className={classes.stickyBar}>
                <Grid
                  container
                  justifyContent="space-between"
                  ref={(divElement) => {
                    this.divElement = divElement;
                  }}
                  className={classes.productSectionNew}
                >
                  <Grid item>
                    <p className={classes.productCount}>
                      {totalCount.toLocaleString("en-US")}
                      {totalCount > 1 ? " Products" : " Product"} found
                    </p>
                  </Grid>
                  <Grid item>
                    {process.env.REACT_APP_ENV === 'dev' && <ProductTypeFilter
                      type={this.state.productType}
                      onChange={() => {
                        let redirectPath = `/app/products/category-wise-hardware-product/1`;
                        this.props.history.push(redirectPath);
                      }}
                    />}
                    <SearchInput
                      page="category_wise_product"
                      searchIcon={false}
                      filters={this.state.filters}
                      showState={!isSeller}
                      selectedState={this.state.selectedState}
                      onChangeState={(newState) =>
                        this.handleOnClickState(newState)
                      }
                      onChangeFilter={(filters) => {
                        this.setState(
                          {
                            filters,
                            searchObj: { ...searchObj, text: '', page: 0 },
                          },
                          () => {
                            this.fetchData();
                          }
                        );
                      }}
                      searchObj={this.state.searchObj}
                      onChange={(searchObj) => {
                        delete searchObj.type;
                        this.setState(
                          {
                            searchObj: {...searchObj},
                          },
                          () => {
                            this.fetchData();
                          }
                        );
                      }}
                    />

                  </Grid>
                  <Grid item>
                    <AddProductButton title={'Add Product'}/>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.productContainer} id="productContainer">
                {expentDirectoryProducts?.existing?.items?.length === 0 && expentDirectoryProducts?.others?.items?.length === 0 &&
                  !fetchExpentDirectoryProductsProgress && (
                    <div className={classes.emptyMessage}>
                      <p>
                        {" "}
                        Your search -{" "}
                        <span className={classes.suggestText}>
                          {this.state.searchObj.text}
                        </span>{" "}
                        - did not match any products.
                      </p>
                      <p className={classes.suggestText}> Suggestions:</p>
                      <p>Make sure all words are spelled correctly.</p>
                      <p> Try in a different Category</p>
                      <p>
                        If you think the product belongs here - help us serve
                        you better by adding{" "}
                        <span
                          className={classes.addProductLink}
                          onClick={() =>
                            this.props.showAddProductManuallyDialog({
                              name: this.state.query,
                            })
                          }
                        >
                          {this.state.query}
                        </span>
                      </p>
                    </div>
                  )}
                <InfiniteScroll
                  scrollableTarget="productContainer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    marginRight: 20,
                  }}
                  dataLength={expentDirectoryProducts?.others?.items?.length || 0}
                  next={() => {
                    this.setState(
                      {
                        searchObj: { ...searchObj, page: searchObj.page + 1 },
                      },
                      () => {
                        this.fetchData();
                      }
                    );
                  }}
                  hasMore={
                    fetchExpentDirectoryProductsProgress
                      ? false
                      : totalCount > expentDirectoryProducts?.others?.items?.length
                  }
                  endMessage={""}
                >
                  {!isSeller &&
                    expentDirectoryProducts?.existing?.items &&
                    expentDirectoryProducts?.existing?.items.length > 0 && (
                      <>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography className={classes.label}>
                            Existing Products
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          style={{ marginBottom: 10 }}
                        >
                          {expentDirectoryProducts?.existing?.items
                            .slice(0, spliter)
                            .map((product, index) => (
                              <Grid
                                item
                                lg={2}
                                md={3}
                                sm={4}
                                xs={6}
                                key={"product_" + parseInt(product.id)}
                              >
                                <LayeredCard
                                  isActionButtons
                                  title={
                                    product.name || product.company_name || ""
                                  }
                                  subTitle={
                                    product.company_name || product.name || ""
                                  }
                                  description={product.description || ""}
                                  image={product.logo || ""}
                                  toogleCheck={() => {
                                    this.addToCart(product);
                                  }}
                                  IsExisting
                                  onClick={() => { }}
                                  numLayer={0}
                                  checked={addedToCart.indexOf(index) !== -1}
                                  showCheckbox={isShowCheckBox}
                                  id={parseInt(product.id)}
                                  uniqueId={"_orgj_" + index}
                                  type={"product"}
                                  productStates={this.productStates(
                                    product.states
                                  )}
                                  onClickState={(state) =>
                                    this.handleOnClickState(state)
                                  }
                                  source={"BROWSE"}
                                  showReactionResponses
                                  typeOfBlock="all_product_blocking"
                                  typeOfFeatured="featured"
                                />
                              </Grid>
                            ))}
                          <Grid
                            item
                            lg={2}
                            md={3}
                            sm={4}
                            xs={6}
                            key={"org_product"}
                          >
                            <LayeredCard
                              title={"View More"}
                              image={viewMoreImage}
                              subTitle=""
                              color="BLUE"
                              onClick={() => {
                                this.props.history.push(
                                  "/app/products/org-products"
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  {expentDirectoryProducts?.others?.items &&
                    expentDirectoryProducts?.others?.items.length > 0 && (
                      <>
                        {!isSeller && (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography className={classes.label}>
                              Other Products
                            </Typography>
                          </Grid>
                        )}
                        <Grid container spacing={3}>
                          {expentDirectoryProducts?.others?.items.map((product, index) => (
                            <Grid
                              item
                              lg={2}
                              md={3}
                              sm={4}
                              xs={6}
                              key={"product_" + parseInt(product.id)}
                            >
                              <LayeredCard
                                isActionButtons={!isSeller}
                                title={
                                  product.name || product.company_name || ""
                                }
                                subTitle={
                                  product.company_name || product.name || ""
                                }
                                description={product.description || ""}
                                image={product.logo || ""}
                                toogleCheck={() => {
                                  this.addToCart(product);
                                }}
                                onClick={() => { }}
                                numLayer={0}
                                checked={addedToCart.indexOf(index) !== -1}
                                showCheckbox={isShowCheckBox}
                                id={parseInt(product.id)}
                                uniqueId={"_j_" + index}
                                type={"product"}
                                customRoute={`/${isSeller ? "sellerapp" : "app"
                                  }/products/product-details/`}
                                productStates={this.productStates(
                                  product.states
                                )}
                                onClickState={(state) =>
                                  this.handleOnClickState(state)
                                }
                                source={isSeller ? "SELLER" : "BROWSE"}
                                showReactionResponses
                                typeOfBlock="all_product_blocking"
                                typeOfFeatured="featured"
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                  {fetchExpentDirectoryProductsProgress && (
                    <Grid container justify="center" style={{ marginTop: 30 }}>
                      <CircularProgress />
                    </Grid>
                  )}
                </InfiniteScroll>
              </div>
              {(deleteProductStateProg || addProductStateProg) && (
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

CategoryWiseProducts.defaultProps = {
  isSeller: false,
};

export default connector(
  compose(withStyles(styles), withLastLocation)(CategoryWiseProducts)
);
