import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment"

export const START_ADD_NEW_ROW = "START_ADD_NEW_ROW";
export const END_ADD_NEW_ROW = "END_ADD_NEW_ROW";

export const START_UPDATE_ROW = "START_UPDATE_ROW";
export const END_UPDATE_ROW = "END_UPDATE_ROW";

export const START_FETCH_ROWS = "START_FETCH_ROWS";
export const END_FETCH_ROWS = "END_FETCH_ROWS";

export const START_DELETE_ROW = "START_DELETE_ROW";
export const END_DELETE_ROW = "END_DELETE_ROW";

function startAddNewRow() {
    return {
        type: START_ADD_NEW_ROW,
        payload: {}
    };
}

function endAddNewRow(success, error) {
    return {
        type: END_ADD_NEW_ROW,
        payload: {
            success, error
        }
    };
}

export function addNewRow(data = {}) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/catalog/table?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewRow());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewRow(response, error));
        } catch (e) {
            dispatch(endAddNewRow(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateRow() {
    return {
        type: START_UPDATE_ROW,
        payload: {}
    };
}

function endUpdateRow(success, error) {
    return {
        type: END_UPDATE_ROW,
        payload: {
            success, error
        }
    };
}

export function updateRow(id,data) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/catalog/table/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateRow());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateRow(response, error));
        } catch (e) {
            dispatch(endUpdateRow(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchRows(data) {
    return {
        type: START_FETCH_ROWS,
        payload: {data}
    };
}

function endFetchRows(success, error, data) {
    return {
        type: END_FETCH_ROWS,
        payload: {
            success, error, data
        }
    };
}

export function fetchTableDatas(queryParam) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/catalog/table?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchRows(queryParam));
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url, queryParam, false);
            dispatch(endFetchRows(response, error,queryParam));
        } catch (e) {
            dispatch(endFetchRows(null, {
                message: e.message
            },queryParam));
            return;
        }
    };
}

function startDeleteRow() {
    return {
        type: START_DELETE_ROW,
        payload: null
    };
}

function endDeleteRow(id, success, error) {
    return {
        type: END_DELETE_ROW,
        payload: {
            success, error, id
        }
    };
}

export function deleteRow(id,params) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/catalog/table/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteRow());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false, params);
            dispatch(endDeleteRow(id,response, error));
        } catch (e) {
            dispatch(endDeleteRow(id,null, null, {
                message: e.message
            }));
            return;
        }
    };
}

