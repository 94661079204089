import { 
    START_APPROVAL_CRITERIA_ADD, END_APPROVAL_CRITERIA_ADD,
    START_APPROVAL_CRITERIA_UPDATE, END_APPROVAL_CRITERIA_UPDATE,
    START_APPROVAL_CRITERIA_SKIP, END_APPROVAL_CRITERIA_SKIP,
    START_APPROVAL_CRITERIA_DELETE, END_APPROVAL_CRITERIA_DELETE,
    START_APPROVAL_CRITERIA_FETCH, END_APPROVAL_CRITERIA_FETCH,
    START_APPROVAL_CRITERIA_APPROVER_ADD, END_APPROVAL_CRITERIA_APPROVER_ADD,
    START_APPROVAL_CRITERIA_APPROVER_REMOVE, END_APPROVAL_CRITERIA_APPROVER_REMOVE,
    START_FETCH_PRODUCT_APPROVAL_DETAILS, END_FETCH_PRODUCT_APPROVAL_DETAILS,
    START_FETCH_EVALUATION_APPROVAL_DETAILS, END_FETCH_EVALUATION_APPROVAL_DETAILS,
    START_ADD_APPROVAL_FOR_CRITERIA, END_ADD_APPROVAL_FOR_CRITERIA, RESET_ADD_APPROVAL_FOR_CRITERIA,
    SHOW_EVALUATION_APPROVAL_DIALOG, HIDE_EVALUATION_APPROVAL_DIALOG,
    START_ADD_APPROVAL_CRITERIA_THREAD, END_ADD_APPROVAL_CRITERIA_THREAD,
    START_FETCH_APPROVAL_CRITERIA_THREAD, END_FETCH_APPROVAL_CRITERIA_THREAD,
    START_FETCH_THREAD_REPLIES, END_FETCH_THREAD_REPLIES,
    START_UPDATE_APPROVAL_CRITERIA_THREAD, END_UPDATE_APPROVAL_CRITERIA_THREAD,
    START_DELETE_APPROVAL_CRITERIA_THREAD, END_DELETE_APPROVAL_CRITERIA_THREAD,
    START_APPROVAL_CRITERIA_UPDATE_SEQUENCE, END_APPROVAL_CRITERIA_UPDATE_SEQUENCE,
    RESET_APPROVAL_CRITERIAS
} from "./action";

const initState  =  {
    productApprovalDetails: null,
    productApprovalDetailsInProgress: false,

    evaluationApprovalDetails: null,
    evaluationApprovalDetailsInProgress: false,

    addApprovalForCriteria: null,
    addApprovalForCriteriaSuccess: false,
    addApprovalForCriteriaInProgress: false,

    approvalMessage: {},
    isOpneApprovalMessage: false,

    approvalCriterias: null,
    fetchApprovalCriteriasProgress: false,
    fetchApprovalCriteriasError: null,
    
    addApprovalCriteriaProgress: false,
    addApprovalCriteriaSuccess: null,
    addApprovalCriteriaError: null,

    updateApprovalCriteriaProgress: false,
    updateApprovalCriteriaError: null,

    skipApprovalCriteriaProgress: false,
    skipApprovalCriteriaError: null,

    deleteApprovalCriteriaProgress: false,
    deleteApprovalCriteriaError: null,

    updateSequenceApprovalCriteriaProgress: false,
    updateSequenceApprovalCriteriaError: null,

    removeApprovalCriteriaApproverProgress: false,
    removeApprovalCriteriaApproverError: null,

    addApprovalCriteriaApproverProgress: false,
    addApprovalCriteriaApproverError: null,

    threadReplies: [],
    fetchThreadRepliesProgress: false,
    fetchThreadRepliesError: null,

    approvalCriteriaThreads: [],
    fetchApprovalCriteriaThreadProgress: false,
    fetchApprovalCriteriaThreadError: null,

    deleteApprovalCriteriaThreadProgress: false,
    deleteApprovalCriteriaThreadSuccess:  null,
    deleteApprovalCriteriaThreadError: null,

    updateApprovalCriteriaThreadProgress: false,
    updateApprovalCriteriaThreadSuccess: null,
    updateApprovalCriteriaThreadError: null,

    addApprovalCriteriaThreadProgress: false,
    addApprovalCriteriaThreadSuccess: null,
    addApprovalCriteriaThreadError: null,
}

export  function evaluationApprovalReducer(state=initState, action){
    switch(action.type){
        case START_APPROVAL_CRITERIA_ADD:
            return {
                ...state,
                addApprovalCriteriaProgress: true,
                addApprovalCriteriaSuccess: null,
                addApprovalCriteriaError: null,
            };
        case END_APPROVAL_CRITERIA_ADD:
            return {
                ...state,
                addApprovalCriteriaProgress: false,
                addApprovalCriteriaError: action.payload.error,
                addApprovalCriteriaSuccess: action.payload.success
            };
        case START_APPROVAL_CRITERIA_UPDATE:
            return {
                ...state,
                updateApprovalCriteriaProgress: true,
                updateApprovalCriteriaError: null,
            };
        case END_APPROVAL_CRITERIA_UPDATE:
            return {
                ...state,
                updateApprovalCriteriaProgress: false,
                updateApprovalCriteriaError: action.payload.error
            };
        case START_APPROVAL_CRITERIA_SKIP:
            return {
                ...state,
                skipApprovalCriteriaProgress: true,
                skipApprovalCriteriaError: null,
            };
        case END_APPROVAL_CRITERIA_SKIP:
            return {
                ...state,
                skipApprovalCriteriaProgress: false,
                skipApprovalCriteriaError: action.payload.error
            };
        case START_APPROVAL_CRITERIA_DELETE:
            return {
                ...state,
                deleteApprovalCriteriaProgress: true,
                deleteApprovalCriteriaError: null,
            };
        case END_APPROVAL_CRITERIA_DELETE:
            return {
                ...state,
                deleteApprovalCriteriaProgress: false,
                deleteApprovalCriteriaError: action.payload.error
            };
        case START_APPROVAL_CRITERIA_UPDATE_SEQUENCE:
            return {
                ...state,
                updateSequenceApprovalCriteriaProgress: true,
                updateSequenceApprovalCriteriaError: null,
            };
        case END_APPROVAL_CRITERIA_UPDATE_SEQUENCE:
            return {
                ...state,
                updateSequenceApprovalCriteriaProgress: false,
                updateSequenceApprovalCriteriaError: action.payload.error
            };

        case START_ADD_APPROVAL_CRITERIA_THREAD:
            return {
                ...state,
                addApprovalCriteriaThreadProgress: true,
                addApprovalCriteriaThreadSuccess: null,
                addApprovalCriteriaThreadError: null,
            };
        case END_ADD_APPROVAL_CRITERIA_THREAD:
            return {
                ...state,
                addApprovalCriteriaThreadProgress: false,
                addApprovalCriteriaThreadSuccess: action.payload.success,
                addApprovalCriteriaThreadError: action.payload.error
            };
        case START_UPDATE_APPROVAL_CRITERIA_THREAD:
            return {
                ...state,
                updateApprovalCriteriaThreadProgress: true,
                updateApprovalCriteriaThreadSuccess: null,
                updateApprovalCriteriaThreadError: null,
            };
        case END_UPDATE_APPROVAL_CRITERIA_THREAD:
            return {
                ...state,
                updateApprovalCriteriaThreadProgress: false,
                updateApprovalCriteriaThreadSuccess: action.payload.success,
                updateApprovalCriteriaThreadError: action.payload.error
            };
        case START_DELETE_APPROVAL_CRITERIA_THREAD:
            return {
                ...state,
                deleteApprovalCriteriaThreadProgress: true,
                deleteApprovalCriteriaThreadSuccess:  null,
                deleteApprovalCriteriaThreadError: null,
            };
        case END_DELETE_APPROVAL_CRITERIA_THREAD:
            return {
                ...state,
                deleteApprovalCriteriaThreadProgress: false,
                deleteApprovalCriteriaThreadSuccess: action.payload.success,
                deleteApprovalCriteriaThreadError: action.payload.error
            };
            
        case START_FETCH_THREAD_REPLIES:
            return {
                ...state,
                threadReplies: [],
                fetchThreadRepliesProgress: true,
                fetchThreadRepliesError: null
            };
        case END_FETCH_THREAD_REPLIES:
            var {success, error, threadId} = action.payload;
            let newThreads = [...state.approvalCriteriaThreads]
            if(!error){
                let indexItm = newThreads.findIndex(o => o.ID === threadId);
                newThreads[indexItm] = {...newThreads[indexItm],ChildThreads: success};
            }
            return {
                ...state,
                approvalCriteriaThreads: newThreads,
                fetchThreadRepliesProgress: false,
                fetchThreadRepliesError: error,
                threadRepliess: success
            };
        case START_FETCH_APPROVAL_CRITERIA_THREAD:
            return {
                ...state,
                approvalCriteriaThreads: [],
                fetchApprovalCriteriaThreadProgress: true,
                fetchApprovalCriteriaThreadError: null
            };
        case END_FETCH_APPROVAL_CRITERIA_THREAD:
            var threads = action.payload.success
            
            return {
                ...state,
                fetchApprovalCriteriaThreadProgress: false,
                fetchApprovalCriteriaThreadError: action.payload.error,
                approvalCriteriaThreads: threads
            };
        case START_APPROVAL_CRITERIA_FETCH:
            return {
                ...state,
                approvalCriterias: state.approvalCriterias,
                fetchApprovalCriteriasProgress: true,
                fetchApprovalCriteriasError: null
            };
        case RESET_APPROVAL_CRITERIAS:
            return {
                ...state,
                approvalCriterias: null,
            };
        case END_APPROVAL_CRITERIA_FETCH:
            
            return {
                ...state,
                fetchApprovalCriteriasProgress: false,
                fetchApprovalCriteriasError: action.payload.error,
                approvalCriterias: action.payload.success
            };
        case START_APPROVAL_CRITERIA_APPROVER_ADD:
            return {
                ...state,
                addApprovalCriteriaApproverProgress: true,
                addApprovalCriteriaApproverError: null,
            };
        case END_APPROVAL_CRITERIA_APPROVER_ADD:
            return {
                ...state,
                addApprovalCriteriaApproverProgress: false,
                addApprovalCriteriaApproverError: action.payload.error
            };
    
        case START_APPROVAL_CRITERIA_APPROVER_REMOVE:
            return {
                ...state,
                removeApprovalCriteriaApproverProgress: true,
                removeApprovalCriteriaApproverError: null,
            };
        case END_APPROVAL_CRITERIA_APPROVER_REMOVE:
            return {
                ...state,
                removeApprovalCriteriaApproverProgress: false,
                removeApprovalCriteriaApproverError: action.payload.error
            };
    
        case START_FETCH_PRODUCT_APPROVAL_DETAILS:
            return {
                ...state,
                productApprovalDetailsInProgress: true
            };
        case END_FETCH_PRODUCT_APPROVAL_DETAILS:
            var details     =   action?.payload?.success || [];
            return {
                ...state,
                productApprovalDetailsInProgress: false,
                productApprovalDetails: details
            };

        case START_FETCH_EVALUATION_APPROVAL_DETAILS:
            return {
                ...state,
                evaluationApprovalDetailsInProgress: true
            };
        case END_FETCH_EVALUATION_APPROVAL_DETAILS:
            details     =   action?.payload?.success || [];
            return {
                ...state,
                evaluationApprovalDetailsInProgress: false,
                evaluationApprovalDetails: details
            };

        case START_ADD_APPROVAL_FOR_CRITERIA:
            return {
                ...state,
                addApprovalForCriteriaSuccess: false,
                addApprovalForCriteriaInProgress: true
            };
        case END_ADD_APPROVAL_FOR_CRITERIA:
            details     =   action?.payload?.success || [];
            return {
                ...state,
                addApprovalForCriteriaInProgress: false,
                addApprovalForCriteriaSuccess: true,
                addApprovalForCriteria: details
            };
        case RESET_ADD_APPROVAL_FOR_CRITERIA:
            return {
                ...state,
                addApprovalForCriteriaSuccess: false,
            };
        
        case SHOW_EVALUATION_APPROVAL_DIALOG: 
            return {
                ...state,
                approvalMessage: action?.payload,
                isOpneApprovalMessage: true,
            };
        case HIDE_EVALUATION_APPROVAL_DIALOG: 
            return {
                ...state,
                approvalMessage: {},
                isOpneApprovalMessage: false,
            };
        default:
            return state;
    }
}  
