import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Typography, FormControl, IconButton, MenuItem, Grid, CircularProgress, Button, Select } from "@material-ui/core";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FlashAutoIcon from "@material-ui/icons/FlashAuto";
import { withRouter } from "react-router";
import { showSnackBar } from "redux/snackbar/action";
import { CommonFn } from "services/commonFn";
import AppBarV2 from "Components/Application/AppBarV2";
import { updateDrawerStyle } from "redux/drawer/action";
import UserGroup from "./Common/UserGroup";
import Products from "./Sections/Products";
import Chat from "./Sections/Chat";
import { sellerQuestionSearch, sellerCheckGenieSummary } from "redux/seller/library/action";
import { fetchSellerAssessmentDetails, exportSellerAssessment } from "redux/seller/assessment/action";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
import InstructionBottomActions from "./Common/InstructionBottomActions";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";
import classnames from "classnames";
import GenieResponseDialog from "./Common/GenieResponseDialog";
import GenieSummaryDialog from "./Common/GenieSummaryDialog";
import GenieChooseResponseDialog from "./Common/GenieChooseResponseDialog";
import UpdateSearchSettingsDialog from "./Common/UpdateSearchSettingsDialog";
import { addSellerAssessmentQuesionResponse } from "redux/seller/assessment/action";
import { fetchSearchSettings } from "redux/seller/assessment/action";
import { SettingsOutlined } from "@material-ui/icons";

const connectedProps = (state) => ({
  sellerColloborators: state.seller.sellerColloborators,
  addSellerColloboratorProgress: state.seller.addSellerColloboratorProgress,

  questionSearchProgress: state.sellerLibrary.questionSearchProgress,
  questionSearchError: state.sellerLibrary.questionSearchError,
  questionSearchData: state.sellerLibrary.questionSearchData,

  genieSummaryProgress: state.sellerLibrary.genieSummaryProgress,
  genieSummaryError: state.sellerLibrary.genieSummaryError,
  genieSummaryData: state.sellerLibrary.genieSummaryData,

  addBulkResponseProgress: state.seller.addBulkResponseProgress,
  addBulkResponseError: state.seller.addBulkResponseError,
  addBulkResponseSuccess: state.seller.addBulkResponseSuccess,

  sellerAssessmentDetailsProg: state.sellerAssessment.sellerAssessmentDetailsProg,
  sellerAssessmentDetailsError: state.sellerAssessment.sellerAssessmentDetailsError,
  sellerAssessmentDetails: state.sellerAssessment.sellerAssessmentDetails,

  exportSellerAssessmentProgress: state.sellerAssessment.exportSellerAssessmentProgress,
  exportSellerAssessmentError: state.sellerAssessment.exportSellerAssessmentError,
  sellerAssessmentExportSuccess: state.sellerAssessment.sellerAssessmentExportSuccess,

  addSellerAssessmentQuesionResponseProgress: state.sellerAssessment.addSellerAssessmentQuesionResponseProgress,
  addSellerAssessmentQuesionResponseSuccess: state.sellerAssessment.addSellerAssessmentQuesionResponseSuccess,

  genieQuestionSearchProgress: state.sellerLibrary.genieQuestionSearchProgress,
  genieQuestionSearchError: state.sellerLibrary.genieQuestionSearchError,
  genieQuestionSearchData: state.sellerLibrary.genieQuestionSearchData,
  genieQuestionSearchIndex: state.sellerLibrary.genieQuestionSearchIndex,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  updateDrawerStyle: updateDrawerStyle,
  sellerQuestionSearch: sellerQuestionSearch,
  sellerCheckGenieSummary: sellerCheckGenieSummary,
  fetchSellerAssessmentDetails: fetchSellerAssessmentDetails,
  addSellerAssessmentQuesionResponse: addSellerAssessmentQuesionResponse,
  exportSellerAssessment: exportSellerAssessment,
  fetchSearchSettings: fetchSearchSettings,
};

var connector = connect(connectedProps, connectionActions);

const Filters = [
  { label: "All", value: "all" },
  { label: "Responded", value: "responded" },
  { label: "Not Responded", value: "not_responded" },
  { label: "Auto Populated", value: "auto_populate" },
  { label: "Needs Review", value: "needs_review" },
  { label: "Genie Powered", value: "genie_powered" },
];

const styles = (theme) =>
  createStyles({
    root: {
      background: "#fff",
    },
    mainLoader: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
    subtitle: {
      fontSize: theme.spacing(2),
      color: "#ccc",
    },
    content: {
      display: "flex",
    },
    nav: {
      flex: 1,
      minHeight: "700px",
      borderRight: "solid 2px #f1f1f1",
      minWidth: "300px",
    },
    chatArea: {
      flex: 4,
    },
    topBar: {
      display: "flex",
      borderBottom: "solid 2px #f1f1f1",
      padding: theme.spacing(2),
    },
    titleSection: {
      borderBottom: "solid 2px #f1f1f1",
      padding: theme.spacing(2),
    },
    collabSection: {
      borderBottom: "solid 2px #f1f1f1",
      padding: theme.spacing(2),
    },
    mainTitle: {
      fontSize: theme.spacing(2.8),
      fontWeight: 500,
      marginRight: 10,
    },
    title: {
      fontSize: theme.spacing(2),
      fontWeight: 500,
      marginRight: 10,
    },
    reqContainer: {
      background: "#fff",
      maxHeight: "80vh",
      minHeight: "80vh",
      padding: theme.spacing(4),
      overflowY: "auto",
    },
    productContainer: {
      background: "#fff",
      maxHeight: "78vh",
      minHeight: "78vh",
      padding: theme.spacing(4, 4),
      overflowY: "auto",
    },
    mainContainer: {
      padding: theme.spacing(2, 2),
      overflow: "hidden scroll",
      height: "78vh",
      background: "#f7f7f7",
    },
    reqTitle: {
      marginBottom: theme.spacing(2),
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    NoDataLayout: {
      width: "calc(100% - 10px)",
      textAlign: "center",
      background: "#f7f7f7",
      padding: "100px",
      borderRadius: "6px",
      "& button": {
        margin: "20px 0",
      },
    },
    antTree: {
      cursor: "pointer",
      border: "2px solid #f1f1f1",
      borderRadius: theme.spacing(1.25),
      fontSize: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    statusFilter: {
      marginBottom: 20,
    },
    filterTitle: {
      color: "#282D30",
      fontWeight: 400,
      fontSize: theme.spacing(2.2),
    },
    formControl: {
      width: "100%",
    },
    reqBox: {
      marginBottom: theme.spacing(2),
    },
    arrowIcon: {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(2),
      zIndex: 999,
    },
    titleReq: {
      margin: 0,
      fontWeight: "600",
      fontSize: theme.spacing(2),
      padding: theme.spacing(2),
      width: "70%",
    },
    arrowImg: {
      cursor: "pointer",
    },
    mainRootItem: {},
    subChild: {
      paddingLeft: "20px",
    },
    parentItem: {
      margin: 0,
      fontWeight: "bold",
      fontSize: theme.spacing(2),
    },
    rootItem: {
      position: "relative",
      padding: "10px",
      "&:before": {
        position: "absolute",
        left: "15px",
        top: "40px",
        display: "block",
        width: "3px",
        bottom: "30px",
        borderRight: "1px solid hsl(206deg 5% 74%)",
        content: '""',
      },
    },
    childItem: {
      position: "relative",
      margin: 0,
      padding: theme.spacing(0.2, 1),
      cursor: "pointer",
      "&:after": {
        position: "absolute",
        left: "-23px",
        top: "14px",
        display: "block",
        width: "21px",
        height: "1px",
        /* bottom: 25px, */
        background: "hsl(206deg 5% 74%)",
        content: '""',
      },
    },
    selectedItem: {
      backgroundColor: "#398AF5",
      border: "1px solid #398AF5",
      color: "#fff",
      padding: theme.spacing(0.2, 2),
      borderRadius: theme.spacing(1.25),
    },
    rootItemNoBefore: {
      padding: "10px",
    },
    activeTree: {
      background: "#f7f7f7",
      borderColor: "#4b86f8",
    },
    contentContainer: {
      background: "#f7f7f7",
    },
    logoImage: {
      height: "40px",
      position: "absolute",
      top: 20,
      right: 0,
      left: 0,
      margin: "0 auto",
    },
    logo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    topRightMenu: {
      textAlign: "right",
      paddingRight: theme.spacing(2),
    },
    magicContainer: {
      textAlign: "right",
    },
    instruction: {
      margin: "0 auto",
      width: "80%",
    },
    noData: {
      textAlign: "center",
      marginTop: 40,
      fontWeight: 500,
    },
    greenIcon: {
      margin: "1rem",
      color: "#2eb77d",
      marginLeft: 6,
      fontSize: 18,
    },
    blueIcon: {
      color: "#1f73b7",
      fontSize: 18,
    },
    blackIcon: {
      fontSize: 18,
    },
  });

class AssessmentRespond extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draftRequirements: [],
      isCompletedQuestionaire: false,
      instructionMessage: "",
      isShowInstruction: true,
      isInstructionAvailable: false,
      answer: "",
      note: "",
      answer_type: 1,
      options: [],
      selectedOptions: [],
      genieResponseDialog: false,
      showGenieResponsePage: false,
      selectedQuestion: null,
      completedQuestionsId: [],
      genieQuestionMap: {},
      genieQuestionResponseMap: {},
      genieResponseCategoryMap: {
        auto_populate: [],
        needs_review: [],
        no_match: [],
      },
      openChooseResponseDialog: false,
      openSearchSettingsDialog: false,
      chooseResponseDialogData: [],
      indexOfCurrentReq: -1,
      genieSummaryExist: false,
      genieSummaryDialog: false,
      selectedFilter: "all",
      allFilterQuestions: [],
    };
  }

  componentWillUnmount() {
    this.props.updateDrawerStyle(false, true);
  }

  componentDidMount() {
    this.props.fetchSellerAssessmentDetails({ filters: { assessment_id: this.props.match.params.uuid, fetch_assessment: true }, page: 0, page_size: 1000 });
    this.props.updateDrawerStyle(false, true);
    this.props.fetchSearchSettings(this.props.match?.params?.uuid);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.questionSearchProgress && prevProps.questionSearchProgress) {
      if (this.props.questionSearchData && this.props.questionSearchData.Data?.length > 0) {
        this.initGenie();
      } else {
        this.props.showSnackBar("Questions not found in library. Please update your library.", "success", 3000);
      }
    }

    if (this.props.genieSummaryProgress === false && prevProps.genieSummaryProgress === true) {
      if (this.props.genieSummaryData?.Data?.length > 0) {
        this.setState({ genieSummaryExist: true });
        this.initGenieSummary();
      }
    }

    if (this.props.genieQuestionSearchProgress === false && prevProps.genieQuestionSearchProgress === true) {
      if (this.props.genieQuestionSearchData?.Data?.length > 0) {
        this.initGenieQuestionSearch();
      } else {
        this.props.showSnackBar("Question not found in library. Please update your library.", "success", 3000);
      }
    }

    if (!this.props.addSellerAssessmentQuesionResponseProgress && prevProps.addSellerAssessmentQuesionResponseProgress) {
      this.calculateProgress();
      this.updateFilter(this.state.selectedFilter);
      if (this.props.addSellerAssessmentQuesionResponseError) {
        this.props.showSnackBar("Something went wrong", "error", 3000);
      } else if (this.props.addSellerAssessmentQuesionResponseSuccess) {
        let datas = this.props.addSellerAssessmentQuesionResponseSuccess.Data || [];
        let genieQuestionResponseMap = { ...this.state.genieQuestionResponseMap };
        datas?.forEach((data) => {
          delete genieQuestionResponseMap[data.ID];
        });
        this.setState({ genieQuestionResponseMap: genieQuestionResponseMap });
      }
    }

    if (this.props.exportSellerAssessmentProgress === false && prevProps.exportSellerAssessmentProgress === true) {
      const { sellerAssessmentExportSuccess } = this.props;
      if (sellerAssessmentExportSuccess?.URL) {
        let link = document.createElement("a");
        const body = document.getElementsByTagName("body");
        link.href = sellerAssessmentExportSuccess?.URL;
        link.setAttribute("download", "assessment_data.xlsx");
        body[0].appendChild(link);
        link.click();
        body[0].removeChild(link);
      } else {
        this.props.showSnackBar("Failed to export assessment", "error", 3000);
      }
    }

    if (this.props.addSellerColloboratorProgress === false && prevProps.addSellerColloboratorProgress === true) {
      this.props.showSnackBar("Collaborator added successfully", "success", 3000);
    }

    if (!this.props.addBulkResponseProgress && prevProps.addBulkResponseProgress) {
      if (this.props.addBulkResponseError === null) {
        this.props.showSnackBar("Reponses updated successfully", "success", 3000);
      }
    }
    if (!this.props.sellerAssessmentDetailsProg && prevProps.sellerAssessmentDetailsProg) {
      if (this.props.sellerAssessmentDetailsError) {
        this.props.showSnackBar("Failed to fetch assessment details", "error", 3000);
      } else {
        this.props.sellerCheckGenieSummary(this.props.match.params.uuid);
        if (this.props.sellerAssessmentDetails?.data?.length > 0) {
          let genieQuestionMap = {};
          this.props.sellerAssessmentDetails?.data?.forEach(function (item) {
            let desc = item?.question?.description || "";
            if (desc === "") {
              return;
            }
            genieQuestionMap[item?.question?.id] = item;
          });
          this.setState({ genieQuestionMap: genieQuestionMap, allFilterQuestions: this.props.sellerAssessmentDetails?.data });
          let data = this.props.sellerAssessmentDetails?.data[0] || {};
          this.calculateProgress();
          this.setCurrentQuestion(data);
        }
      }
    }
  }

  initGenieQuestionSearch = () => {
    let data = this.props.genieQuestionSearchData?.Data || [];
    const { genieQuestionMap } = this.state;
    let autoPopulateResponse = [];
    data.forEach((item) => {
      if (item.match_objects?.length === 0) {
        this.props.showSnackBar("Question not found in library. Please update your library.", "success", 3000);
      } else {
        let index = item.match_objects?.findIndex((O) => O.match_type === "exact_text_exact_type");
        if (index > -1) {
          let answer = item.match_objects[index];
          if (
            (![2, 3, 14, 15].includes(parseInt(answer?.match_object?.answer_type)) && answer?.match_object?.answer?.length > 0) ||
            ([2, 3, 14, 15].includes(parseInt(answer?.match_object?.answer_type)) && answer?.match_object?.options_answer?.length > 0)
          ) {
            autoPopulateResponse.push({
              question_id: item.question_id,
              answer_id: genieQuestionMap[item.question_id]?.answers?.id,
              entity_type: "answer",
              answer: {
                created_from: "genie",
                note: answer?.match_object?.note || "",
                answer_type: parseInt(answer?.match_object?.answer_type),
                options: answer?.match_object?.options,
                answer: answer?.match_object?.answer,
                options_answer: answer?.match_object?.options_answer,
              },
            });
          } else {
            this.props.showSnackBar("Question not found in library. Please update your library.", "success", 3000);
          }
        } else {
          let needsReviewData = item.match_objects?.map((O) => ({ ...O, type: "needs_review" }));
          this.setState({ openChooseResponseDialog: true, chooseResponseDialogData: needsReviewData, indexOfCurrentReq: this.props.genieQuestionSearchIndex });
        }
      }
    });
    if (autoPopulateResponse?.length > 0) {
      this.props.addSellerAssessmentQuesionResponse({
        action: "update",
        source: { id: this.props.match.params.uuid, name: "assessment" },
        data: autoPopulateResponse,
      });
    }
  };

  initGenieSummary = () => {
    let data = this.props.genieSummaryData?.Data || [];
    const { genieQuestionMap } = this.state;
    let genieQuestionResponseMap = {};
    let genieResponseCategoryMap = {
      auto_populate: [],
      needs_review: [],
      no_match: [],
    };
    data.forEach((item) => {
      let question = genieQuestionMap[item.question_id] || {};

      if (item.match_objects?.length === 0) {
        genieResponseCategoryMap["no_match"]?.push({
          question: question,
        });
        genieQuestionResponseMap[item.question_id] = item.match_objects?.map((O) => ({ ...O, type: "no_match" }));
      } else {
        let index = item.match_objects?.findIndex((O) => O.match_type === "exact_text_exact_type");
        if (index > -1) {
          let answer = item.match_objects[index];
          if (
            (![2, 3, 14, 15].includes(parseInt(answer?.match_object?.answer_type)) && answer?.match_object?.answer?.length > 0) ||
            ([2, 3, 14, 15].includes(parseInt(answer?.match_object?.answer_type)) && answer?.match_object?.options_answer?.length > 0)
          ) {
            genieResponseCategoryMap["auto_populate"]?.push({
              question: question,
              answer: answer,
            });
            genieQuestionResponseMap[item.question_id] = item.match_objects?.map((O) => ({ ...O, type: "auto_populate" }));
          } else {
            genieResponseCategoryMap["no_match"]?.push({
              question: question,
            });
            genieQuestionResponseMap[item.question_id] = item.match_objects?.map((O) => ({ ...O, type: "no_match" }));
          }
        } else {
          genieResponseCategoryMap["needs_review"]?.push({
            question: question,
            answers: item.match_objects?.filter((O) => O.match_type !== "exact_text_exact_type"),
          });
          genieQuestionResponseMap[item.question_id] = item.match_objects?.map((O) => ({ ...O, type: "needs_review" }));
        }
      }
    });
    this.setState({ genieResponseCategoryMap: genieResponseCategoryMap, genieQuestionResponseMap: genieQuestionResponseMap });
  };

  initGenie = () => {
    let data = this.props.questionSearchData?.Data || [];
    const { genieQuestionMap } = this.state;
    let genieQuestionResponseMap = {};
    let genieResponseCategoryMap = {
      auto_populate: [],
      needs_review: [],
      no_match: [],
    };
    let autoPopulateResponse = [];
    data.forEach((item) => {
      let question = genieQuestionMap[item.question_id] || {};
      if (
        (![2, 3, 14, 15].includes(parseInt(question?.answers?.answer_type)) && question?.answers?.answer?.length === 0) ||
        ([2, 3, 14, 15].includes(parseInt(question?.answers?.answer_type)) && question?.answers?.options_answer?.length === 0)
      ) {
        if (item.match_objects?.length === 0) {
          genieResponseCategoryMap["no_match"]?.push({
            question: question,
          });
          genieQuestionResponseMap[item.question_id] = item.match_objects?.map((O) => ({ ...O, type: "no_match" }));
        } else {
          let index = item.match_objects?.findIndex((O) => O.match_type === "exact_text_exact_type");
          if (index > -1) {
            let answer = item.match_objects[index];

            if (
              (![2, 3, 14, 15].includes(parseInt(answer?.match_object?.answer_type)) && answer?.match_object?.answer?.length > 0) ||
              ([2, 3, 14, 15].includes(parseInt(answer?.match_object?.answer_type)) && answer?.match_object?.options_answer?.length > 0)
            ) {
              genieResponseCategoryMap["auto_populate"]?.push({
                question: question,
                answer: answer,
              });
              genieQuestionResponseMap[item.question_id] = item.match_objects?.map((O) => ({ ...O, type: "auto_populate" }));
              autoPopulateResponse.push({
                question_id: item.question_id,
                answer_id: genieQuestionMap[item.question_id]?.answers?.id,
                entity_type: "answer",
                answer: {
                  created_from: "genie",
                  note: answer?.match_object?.note || "",
                  answer_type: parseInt(answer?.match_object?.answer_type),
                  options: answer?.match_object?.options,
                  answer: answer?.match_object?.answer,
                  options_answer: answer?.match_object?.options_answer,
                },
              });
            } else {
              genieResponseCategoryMap["no_match"]?.push({
                question: question,
              });
              genieQuestionResponseMap[item.question_id] = item.match_objects?.map((O) => ({ ...O, type: "no_match" }));
            }
          } else {
            genieResponseCategoryMap["needs_review"]?.push({
              question: question,
              answers: item.match_objects?.filter((O) => O.match_type !== "exact_text_exact_type"),
            });
            genieQuestionResponseMap[item.question_id] = item.match_objects?.map((O) => ({ ...O, type: "needs_review" }));
          }
        }
      }
    });
    if (autoPopulateResponse?.length > 0) {
      this.props.addSellerAssessmentQuesionResponse({
        action: "update",
        source: { id: this.props.match.params.uuid, name: "assessment" },
        data: autoPopulateResponse,
      });
    }
    this.setState({ genieSummaryDialog: false, genieResponseDialog: true, genieResponseCategoryMap: genieResponseCategoryMap, genieQuestionResponseMap: genieQuestionResponseMap });
  };

  calculateProgress = () => {
    let data = this.props.sellerAssessmentDetails?.data || [];
    let completedQuestionsId = [];

    data.forEach((item) => {
      let answerType = item?.answers?.answer_type;
      let resOptions = item?.answers?.options_answer;
      let answer = item?.answers?.answer;

      if (![2, 3, 14, 15].includes(answerType) && answer?.length > 0) {
        completedQuestionsId.push(item.ID);
      } else if ([2, 3, 14, 15].includes(answerType) && resOptions?.length > 0) {
        completedQuestionsId.push(item.ID);
      }
    });
    this.setState({ completedQuestionsId: completedQuestionsId });
  };

  setCurrentQuestion = (data, scroll = false) => {
    let response = data.answers || {};

    let answerType = response.answer_type;
    let options = response.options;
    let note = response?.note || "";
    let answerData = "";

    let resOptions = [];
    try {
      if (![2, 3, 14, 15].includes(answerType)) {
        let answer = response.answer;
        if (answerType === 11) {
          answerData = CommonFn.formateDate(answer, true);
        } else if (answerType === 6) {
          answerData = answer;
        } else if (answerType === 13) {
          answerData = JSON.parse(answer);
        } else if (answerType === 1 || answerType === 7) {
          note = answer;
          answerData = answer;
        } else {
          answerData = answer;
        }
      } else if ([2, 3, 14, 15].includes(answerType) && options) {
        resOptions = response.options_answer;
      }
    } catch (e) {}
    this.setState(
      {
        selectedQuestion: data,
        answer: answerData,
        note: note,
        answer_type: answerType,
        options: response.options || [],
        selectedOptions: [2, 3, 14, 15].includes(answerType) ? resOptions : [],
      },
      () => {
        if (scroll) {
          this.handleClickScroll(data.ID);
        }
      }
    );
  };

  handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };

  applyGenie = () => {
    let is_global;
    if (this.props.sellerAssessmentDetails?.data?.length > 0) {
      let data = this.props.sellerAssessmentDetails?.data[0] || {};
      if (data?.assessment?.name) {
        is_global = data?.assessment?.is_global;
      }
    }
    this.props.sellerQuestionSearch({
      search_type: "embeddings",
      confidence: "high",
      assessment: { id: this.props.match.params.uuid, type: "imported" },
      filters: { is_global: is_global, different_answer_type: true },
    });
  };

  updateResponse = (updatedData) => {
    console.log(updatedData, "==============================Update Locally");

    let selectedOptions = [];

    if (updatedData.selectedOptions && updatedData.selectedOptions.length > 0 && updatedData.type === 2) {
      let sOptions = updatedData.selectedOptions.map((o) => o.value);

      let sellOps = [];
      updatedData.options.forEach(function (itm) {
        if (sOptions.indexOf(itm.description) !== -1) {
          sellOps.push(itm);
        }
      });
      selectedOptions = sellOps;
    } else if (updatedData.selectedOptions && updatedData.type === 3) {
      let index = updatedData.options.findIndex((o) => o.description === updatedData.selectedOptions.value);
      selectedOptions = [updatedData.options[index]];
    } else if (updatedData.selectedOptions && (updatedData.type === 4 || updatedData.type === 14)) {
      selectedOptions = [updatedData.selectedOptions];
    } else if (updatedData.selectedOptions && updatedData.type === 15) {
      let sellOps = [];
      updatedData.options.forEach(function (itm) {
        if (updatedData.selectedOptions.indexOf(itm.description) !== -1) {
          sellOps.push(itm);
        }
      });
      selectedOptions = sellOps;
    } else {
      selectedOptions = updatedData.selectedOptions;
    }

    let answer_type = updatedData.type;
    let note = updatedData.note;
    let answer = "";
    if (![2, 3, 14, 15].includes(answer_type)) {
      answer = updatedData.text_value;
    }

    this.setState({
      selectedOptions: selectedOptions,
      note: note,
      answer: answer,
    });
  };

  getToolTipData = (item, index, classes) => {
    let icon = null;
    let tooltipMessage = null;
    let qRes = this.state.genieQuestionResponseMap[item.ID] || {};
    let answerType = item?.answers?.answer_type;
    let resOptions = item?.answers?.options_answer;
    let answer = item?.answers?.answer;

    if (item?.answers?.created_from === "genie") {
      tooltipMessage = "Auto populated by Genie";
      icon = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <FlashAutoIcon className={classes.blueIcon} />
          <CheckCircleOutlineIcon className={classes.greenIcon} />
        </div>
      );
    } else if (qRes[0]?.type === "needs_review" && this.state.genieSummaryExist && !this.state.completedQuestionsId?.includes(item.ID)) {
      tooltipMessage = "Needs Review";
      icon = <FlashOnIcon style={{ margin: "1rem" }} />;
    } else if (item?.answers?.created_from === "needs_review") {
      tooltipMessage = "Genie powered";
      icon = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <FlashOnIcon className={classes.blackIcon} />
          <CheckCircleOutlineIcon className={classes.greenIcon} />
        </div>
      );
    } else if (![2, 3, 14, 15].includes(answerType) && answer?.length > 0) {
      tooltipMessage = "Responded";
      icon = <CheckCircleOutlineIcon className={classes.greenIcon} />;
    } else if ([2, 3, 14, 15].includes(answerType) && resOptions?.length > 0) {
      tooltipMessage = "Responded";
      icon = <CheckCircleOutlineIcon className={classes.greenIcon} />;
    }

    if (icon) {
      return (
        <Tooltip
          className={"tooltip"}
          placement="top"
          title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{tooltipMessage}</span>}
          arrow
        >
          {icon}
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  updateFilter = (value) => {
    let allQuestions = this.props.sellerAssessmentDetails?.data || [];
    let filteredData = [];
    allQuestions.forEach((item) => {
      let qRes = this.state.genieQuestionResponseMap[item.ID] || {};
      let answerType = item?.answers?.answer_type;
      let resOptions = item?.answers?.options_answer;
      let answer = item?.answers?.answer;

      if (value === "auto_populate") {
        if (item?.answers?.created_from === "genie") {
          filteredData.push(item);
        }
      } else if (value === "needs_review") {
        if (qRes[0]?.type === "needs_review" && this.state.genieSummaryExist && !this.state.completedQuestionsId?.includes(item.ID)) {
          filteredData.push(item);
        }
      } else if (value === "genie_powered") {
        if (item?.answers?.created_from === "needs_review") {
          filteredData.push(item);
        }
      } else if (value === "responded") {
        if (![2, 3, 14, 15].includes(answerType) && answer?.length > 0) {
          filteredData.push(item);
        } else if ([2, 3, 14, 15].includes(answerType) && resOptions?.length > 0) {
          filteredData.push(item);
        }
      } else if (value === "not_responded") {
        if (![2, 3, 14, 15].includes(answerType) && answer?.length === 0) {
          filteredData.push(item);
        } else if ([2, 3, 14, 15].includes(answerType) && resOptions?.length === 0) {
          filteredData.push(item);
        }
      } else {
        filteredData.push(item);
      }
    });
    this.setState({ allFilterQuestions: filteredData, selectedQuestion: null, selectedFilter: value });
    if (filteredData.length > 0) {
      this.setCurrentQuestion(filteredData[0]);
    }
  };

  render() {
    const classes = this.props.classes;

    if (this.props.sellerAssessmentDetailsProg) {
      return (
        <span className={classes.mainLoader}>
          <CircularProgress />
        </span>
      );
    }

    if (this.props.addBulkResponseProgress) {
      return (
        <span className={classes.mainLoader}>
          <CircularProgress />
        </span>
      );
    }

    if (!this.props.sellerAssessmentDetailsProg && this.props.sellerAssessmentDetails?.data?.length === 0) {
      return (
        <div className={classes.root}>
          <AppBarV2 title="Assessment" withBack={true} dropdown={true} />
          <div className={classes.NoDataLayout}>
            <h2>No data</h2>
          </div>
        </div>
      );
    }
    let assessmentName = "Assessment";
    if (this.props.sellerAssessmentDetails?.data?.length > 0) {
      let data = this.props.sellerAssessmentDetails?.data[0] || {};
      if (data?.assessment?.name) {
        assessmentName = data?.assessment?.name;
      }
    }

    return (
      <div className={classes.root}>
        <AppBarV2 withBack={true} title={assessmentName} />
        <Grid container alignItems="center" className={classnames(classes.collabSection)}>
          <Grid item lg={6} md={6} sm={6} xs={12} className={classes.topContainer}>
            <Typography variant={"h5"} className={classes.title}>
              Collaborators
            </Typography>
            <UserGroup
              editable={true}
              selectedUser={[]}
              existingUsers={this.props.sellerColloborators}
              evaluationId={this.props.match.params.uuid}
              position={"SecondaryBar"}
              onAdd={(user) => {
                console.log("Adding new ");
              }}
              users={this.props.sellerColloborators}
              addUserType={"SELLER_COLLOBORATOR"}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} className={classes.magicContainer}>
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginRight: 18 }}
              className={classes.button}
              onClick={() => {
                this.props.history.push(`/sellerapp/assessment/${this.props.match?.params?.uuid}/respond/detailed`);
              }}
            >
              Detailed View
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginRight: 18 }}
              className={classes.button}
              startIcon={<GetAppIcon />}
              onClick={() => {
                this.props.exportSellerAssessment(this.props.match.params.uuid);
              }}
            >
              {this.props.exportSellerAssessmentProgress ? <CircularProgress style={{ width: 25, height: 25 }} /> : "Export"}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              startIcon={<FlashAutoIcon />}
              onClick={() => {
                if (this.state.genieSummaryExist) {
                  this.setState({ genieSummaryDialog: true });
                } else {
                  this.applyGenie();
                }
              }}
            >
              {this.props.questionSearchProgress || this.props.genieSummaryProgress ? <CircularProgress style={{ width: 25, height: 25 }} /> : this.state.genieSummaryExist ? "Genie Summary" : "Genie"}
            </Button>
            <Tooltip
              className={"tooltip"}
              placement="top"
              title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>Update Genie Configuration</span>}
              arrow
            >
              <IconButton
                aria-label="filter list"
                size="small"
                style={{ marginLeft: 15 }}
                onClick={() => {
                  this.setState({ openSearchSettingsDialog: true });
                }}
              >
                <SettingsOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container className={classnames(classes.contentContainer)}>
          <Grid item lg={3} md={3} sm={3} xs={12} className={classnames(classes.reqContainer)}>
            <div className={classes.statusFilter}>
              <Typography variant={"h6"} className={classes.filterTitle}>
                Filter
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <Select
                  value={this.state.selectedFilter}
                  onChange={(e) => {
                    this.updateFilter(e.target.value);
                  }}
                >
                  {Filters.map((op) => {
                    return (
                      <MenuItem key={"Filter_" + op.value} value={op.value} style={{ fontSize: 13 }}>
                        {op.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {this.state.allFilterQuestions?.map((item, index) => {
              let activeCls = "";
              if (this.state.selectedQuestion?.ID === item.ID) {
                activeCls = classes.activeTree;
              }

              return (
                <div
                  id={item.ID}
                  className={classnames(classes.antTree, activeCls)}
                  key={index + item.ID}
                  onClick={() => {
                    this.setCurrentQuestion(item);
                  }}
                >
                  <div className={classes.titleReq}>{item?.question?.name || "Question " + index + 1}</div>
                  {this.getToolTipData(item, index, classes)}
                </div>
              );
            })}
            {this.state.allFilterQuestions?.length === 0 && <div className={classes.noData}>No Questions</div>}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} className={classnames(classes.mainContainer)}>
            {this.state.selectedQuestion && (
              <Chat
                isInstructionAvailable={false}
                showInstruction={() => {}}
                genieQuestionResponseMap={this.state.genieQuestionResponseMap}
                answer={this.state.answer}
                note={this.state.note}
                selectedOptions={this.state.selectedOptions}
                questionID={this.state.selectedQuestion?.ID}
                options={this.state.options}
                completedQuestionsId={this.state.completedQuestionsId}
                genieSummaryExist={this.state.genieSummaryExist}
                answer_type={this.state.answer_type}
                requirement={this.state.selectedQuestion}
                draftRequirements={[]}
                isCompletedQuestionaire={false}
                setSelectedQuestion={(item) => {
                  if (item) {
                    this.setCurrentQuestion(item);
                  }
                }}
                openChooseResponseDialog={(data, indexOfCurrentReq) => {
                  this.setState({ openChooseResponseDialog: true, chooseResponseDialogData: data, indexOfCurrentReq: indexOfCurrentReq });
                }}
                updateResponse={(item) => {
                  this.updateResponse(item);
                }}
                updateNote={(note) => {
                  this.setState({ note: note });
                }}
              />
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12} className={classnames(classes.productContainer)}>
            <Products completedQuestionsId={this.state.completedQuestionsId} totalCount={this.props.sellerAssessmentDetails?.total || 0} />
          </Grid>
        </Grid>

        {this.state.isShowInstruction && (
          <div className={classes.instruction}>
            <SlateInputFieldV2
              // label="Instruction"
              readOnly={true}
              isToolBar={false}
              placeholder=""
              style={{
                margin: 0,
              }}
              as={SlateInputFieldV2}
              initValue={this.state.instructionMessage}
              formControlStyle={{ border: "none", overflow: "hidden" }}
              textContainerStyle={{ minHeight: "100px !important", margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }}
            />

            <InstructionBottomActions submitInstruction={this.submitInstruction} />
          </div>
        )}

        {this.state.genieSummaryDialog && (
          <GenieSummaryDialog
            onClose={() => {
              this.setState({ genieSummaryDialog: false });
            }}
            chooseCurrentQuestion={(data) => {
              this.setCurrentQuestion(data, true);
              this.setState({ genieSummaryDialog: false });
            }}
            genieResponseCategoryMap={this.state.genieResponseCategoryMap}
          />
        )}

        {this.state.genieResponseDialog && (
          <GenieResponseDialog
            onClose={() => {
              this.setState({ genieResponseDialog: false, genieSummaryExist: true });
            }}
            chooseCurrentQuestion={(data) => {
              this.setState({ genieResponseDialog: false, genieSummaryExist: true });
              this.setCurrentQuestion(data, true);
            }}
            genieResponseCategoryMap={this.state.genieResponseCategoryMap}
          />
        )}
        {this.state.openChooseResponseDialog && (
          <GenieChooseResponseDialog
            onClose={() => {
              const sellerAssessmentDetails = this.props.sellerAssessmentDetails?.data || [];
              this.setState({ openChooseResponseDialog: false, chooseResponseDialogData: [] });
              this.setCurrentQuestion(sellerAssessmentDetails[this.state.indexOfCurrentReq]);
            }}
            chooseResponseDialogData={this.state.chooseResponseDialogData}
            requirement={this.state.selectedQuestion}
          />
        )}
        {this.state.openSearchSettingsDialog && (
          <UpdateSearchSettingsDialog
            onClose={() => {
              this.setState({ openSearchSettingsDialog: false });
            }}
          />
        )}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(AssessmentRespond));
