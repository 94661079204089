import { createStyles, withStyles } from '@material-ui/styles';
import CommonCss from "commonCss";
import { connect } from "react-redux";
import { ListItemIcon,ListItemText,Checkbox,Button, List, ListItem, Paper, Popover } from "@material-ui/core";
import React, { Component } from 'react';

const connectedProps = (state) => ({
});


const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);


const styles = (theme) => createStyles({
  ...CommonCss.ProductDetails,
  root: {
    position: 'relative',
    width: '100%'
  },
  threeDot: {
    position: "absolute",
    zIndex: 0,
    right: -10,
    top: -10,
    padding: 5
  },
  listIcon: {
    minWidth: theme.spacing(5),
  },
  menuList: {
    padding: '0px',
    "& > hr": {
      "&:first-child": {
        display: 'none'
      },
    }
  },
  columnDrawer: {
    minWidth: 150,
    maxWidth: 300,
    right: "0",
    zIndex: "9",
  },
  popover: {
    "& [class*='paper']": {
      boxShadow: "0px 8px 12px #0000001D",
      border: "1px solid #EEEEEE",
      borderRadius: "5px",
      overflow: "inherit",
    }
  },
  listItem: {
    cursor: 'pointer',
    padding: 0
  },
  label:{
    paddingRight: 5,
    '& [class*="MuiListItemText"]': {
      fontSize: "12px",
    },
  }
  
})

class AnalysisFilter extends Component {
  constructor(props) {
    super()
    this.state = {
      anchorEl: null,
    };
    this.btnRef = React.createRef();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes,options } = this.props;
    
    const open = Boolean(this.state.anchorEl);
    if(!options || options.length === 0){
      return null;
    }
    
    return (
      <>
        <Popover
            open={open}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={classes.columnDrawer}>
              <List>
                {options.map((item,i)=>(
                  <ListItem key={i} className={classes.listItem}
                    onClick={()=>{
                      let newOptions = [...options];
                      if(item.isChecked){
                        newOptions[i]['isChecked'] = false;
                      }else{
                        newOptions[i]['isChecked'] = true;
                      }
                      this.props.handleSelect(newOptions)
                    }}>
                    <ListItemIcon style={{minWidth: 42}}>
                      <Checkbox
                        style={{color: "#007BFF"}}
                        size="small"
                        checked={item.isChecked}
                        name={item.name}
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.name} className={classes.label}/>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Popover>
        <Button variant="outlined" color="secondary" className={classes.button}
          onClick={(event) => {
            this.handleClick(event);
          }}
        >Select Reviews
        </Button>
      </>
    )
  }
}

export default connector(withStyles(styles)(AnalysisFilter))
