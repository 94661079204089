import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { DialogContent, Typography, CircularProgress, Divider, Grid, IconButton } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { withRouter } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//assets
import Image from 'Components/Common/image.jsx'
import editIcon from "assets/images/edit_icon.svg";

// components
import SqureButton from 'Components/Common/SqureButton';

// redux
import { hideEvaluationAsksellerSelectDialog } from "redux/templates/evaluation/action";
import { askSellerSendRequirments, reSendAskSellerMultiRequirement } from "redux/templates/evaluation/action";
import { showSnackBar } from "redux/snackbar/action";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import { showSellerFinalizeReqDialog, fetchEvaluationMeta, fetchSellerActivity, productRFPMapFetch } from "redux/seller/action";
import { setSelectedRequirement, tooglePreviousInteractionDialog, setResponseTab } from "redux/evaluation/criteria/action";
import { findRequirementPreviousInteraction } from 'util/index'

const connectedProps = (state) => ({
    user: state.authUser.user,
    isOpenPreviousInteractionDialog: state.evaludationCriteria.showPreviousInteractionDialog,
    criterias: state.evaludationCriteria.criterias,
    evaluationId: state.templateEvaluation.multiSelectAskEvaluationId,
    submitProgress: state.templateEvaluation.askSellerSendProgress,
    submitError: state.templateEvaluation.askSellerSendError,
    sellerEvaluationMeta: state.seller.evaluation,
    metaFetchProgress: state.seller.metaFetchProgress,
    sellerActivity: state.seller.sellerActivity,
    sellerActivityProg: state.seller.sellerActivityProg,
    selectedRequirementId: state.evaludationCriteria.selectedRequirementId,
    previousInteractions: state.evaludationCriteria.previousInteractions,
    reSendAskSellerWithSellerResponseProgress: state.templateEvaluation.reSendAskSellerWithSellerResponseProgress,
    productRFPMapProgress: state.seller.productRFPMapProgress,
    productRFPMapError: state.seller.productRFPMapError,
    productRFPMapSuccess: state.seller.productRFPMapSuccess,
    updateSellerStateProgress: state.seller.updateSellerStateProgress,
});

const connectionActions = {
    hideDialog: hideEvaluationAsksellerSelectDialog,
    askSellerSendRequirments: askSellerSendRequirments,
    showSellerFinalizeReqDialog: showSellerFinalizeReqDialog,
    fetchEvaluationMeta: fetchEvaluationMeta,
    showSnackBar: showSnackBar,
    setSelectedRequirement: setSelectedRequirement,
    tooglePreviousInteractionDialog: tooglePreviousInteractionDialog,
    setResponseTab: setResponseTab,
    reSendAskSellerMultiRequirement: reSendAskSellerMultiRequirement,
    fetchSellerActivity: fetchSellerActivity,
    productRFPMapFetch: productRFPMapFetch
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: "0px 20px !important",
        backgroundColor: "#F7F7F7",
        // paddingBottom: 45,
        maxHeight: "100%",
        height: "100%",
        position: "relative",
        overflow: "auto"
    },
    title: {
        color: "#282D30",
        fontSize: theme.spacing(2.5),
        marginBottom: 0
    },
    tabs: {
        listStyle: "none",
        width: "300px",
        display: "flex",
        margin: 0,
        padding: 0,
        borderBottom: "solid 1px #e0e0e0"
    },
    tab: {

    },
    linkIcon: {
        padding: theme.spacing(.5, 1),
        backgroundColor: '#528bff',
        display: 'flex',
        borderRadius: '50%',
        widht: 36,
        height: 36,
        justifyContent: 'center',
        alignItems: 'center'
    },
    tabBtn: {
        padding: theme.spacing(2),
        borderRadius: 0
    },
    activeBtn: {
        color: "#4A87F8",
        borderBottom: "solid 2px #4A87F8",
    },
    actions: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(2),
        background: "#fff",
        textAlign: "right"
    },
    templates: {
        padding: theme.spacing(2, 0),
        marginBottom: 35
    },
    templateCard: {
        border: "2px solid #EEEEEE",
        borderRadius: '10px',
        background: "#fff",
        padding: theme.spacing(2),
        cursor: "pointer"
    },
    activeTemplate: {
        border: "2px solid #4A87F8",
    },
    templateName: {
        fontSize: theme.spacing(2),
        fontWeight: 400
    },
    iconContainer: {
        marginRight: theme.spacing(3),
        cursor: "pointer",
        display: "flex",
        marginBottom: theme.spacing(2)
    },
    image: {
        width: theme.spacing(3.5),
    },
    modalappBar: {
        display: "flex"
    },
    criteriaRequirement: {
        width: "90%",
        margin: '10px auto',
        paddingBottom: 50
    },
    container: {
        margin: '10px 0 50px'
    },
    productDetail: {
        marginTop: '25px'
    },
    UL: {
        width: '100%',
        //   padding: '0px',
        //   position: 'relative',
        '& li': {
            listStyle: 'none',
            marginBottom: '30px',
            borderBottom: ' 1px solid #aeaeae',
            paddingBottom: '30px',
            '& p': {
                margin: '0px'
            }
        }
    },
    multiSelect: {
        position: 'absolute',
        top: '-8px'
    },
    criteriaAllSelect: {
        marginRight: 10,
        padding: 0
    },
    AccordionHeader: {
        '& [class*=MuiAccordionSummary-content-]': {
            padding: 12
        },
        '& [class*=MuiBadge-badge-]': {
            right: '-8px'
        }
    },
    productName: {
        fontSize: theme.spacing(2.5),
        // marginBottom:theme.spacing(4),
        textAlign: "center",
        fontWeight: 500
    },
    productLogo: {
        height: '30px',
        width: '80px',
        objectFit: 'contain',
        marginRight: '10px'
    },
    isExisting: {
        background: '#E6FFF3',
        borderRadius: '5px',
        textAlign: 'left',
        fontSize: '11px',
        color: '#2EB77D',
        padding: '3px 10px',
        marginLeft: '10px'
    },
    copiedText: {
        textAlign: 'left',
        fontSize: '14px',
        color: '#2EB77D',
    },
    copyText: {
        fontWeight: 400,
        fontSize: theme.spacing(2)
    },
    viewActivity: {
        fontWeight: 400,
        fontSize: theme.spacing(2)
    },
    cartItem: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        padding: 12,
        border: '1px solid #EEEEEE',
        borderRadius: 10,
        justifyContent: 'space-between'
    },
    productNameContainer: {
        flex: 0.99,
        position: 'relative'
    },
    table: {
        borderCollapse: 'separate !important',
        width: '100%',
        borderSpacing: 0,
        borderRadius: '4px',
        border: '1px solid #DEDEDE',
    },
    productImage: {
        width: '100%',
        height: 'auto',
        marginRight: theme.spacing(2)
    },
    productImageContainer: {
        width: 67,
        height: 57,
        padding: 8,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 10px 5px #0000001A',
        borderRadius: 10
    },
    productNameTitle: {
        fontSize: theme.spacing(2),
    },
    productPerCom: {
        display: 'flex'
    },
    totalAnswered: {
        flex: 1,
        textAlign: 'right',
        margin: theme.spacing(1, 0),
        fontSize: theme.spacing(1.6)
    },
    progTextComp: {
        margin: theme.spacing(1, 0),
        fontSize: theme.spacing(1.6)
    },
    progressBar: {
        cursor: 'pointer'
    },
    refreshIcon: {
        cursor: 'pointer',
        color: '#999999',
        transitionDuration: '5s',
        transitionProperty: 'transform',
        zIndex: 1000,
        marginTop: 5,
    },
    underprogress: {
        transform: 'rotate(360deg)',
        '-webkit-transform': 'rotate(360deg) infinite linear',
    },
    viewAllResponse: {
        cursor: 'pointer',
        textDecoration: 'underline',
        letterSpacing: '0px',
        color: '#398AF5',
        fontSize: theme.spacing(2),
    },
    bottomLinks: {
        position: "fixed",
        bottom: "0",
        padding: theme.spacing(1),
        left: 0,
        right: 0,
        paddingLeft: theme.spacing(24),
        paddingRight: theme.spacing(8),
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px -1px 6px #00000010",
        zIndex: 1000
    },
    updateBtn: {
        "marginLeft": "10px",
        background: '#4b86ff'
    },
    loader:{
        marginTop:20,
        textAlign:'center'
    }
});

// const BorderLinearProgress = withStyles((theme) => ({
//     root: {
//         height: 10,
//         borderRadius: 5,
//     },
//     colorPrimary: {
//         backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
//     },
//     bar: {
//         borderRadius: 5,
//         backgroundColor: '#2EB77D',
//     },

// }))(LinearProgress);

class ChooseAsksellerCriterias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            copiedLink: false,
            pageSize: 1000,
            sellerActivityDialog: false,
            selectedIds: [],
            requirementIds: [],
            alreadySelectedReqIds: [],
            criteriaReqMap: {},
            criteriaShowAllMap: {},
            alreadySelectedCriteriaMap: {},
            criteriaWiseTotalSelected: {},
            totalRequirementsCount: null,
            isSendDisable: false,
            isRuleUsedForEvaluation: false,
            mainRequirementId: false,
            isOpenShareDialog: false,
            checkAllCriterias: false,
            isLoading:true
        }
        this.toggleRequirement = this.toggleRequirement.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
        this.selectAllRequirtments = this.selectAllRequirtments.bind(this);
        this.showFinalizeDialog = this.showFinalizeDialog.bind(this);
    }

    showFinalizeDialog(type) {
        this.props.showSellerFinalizeReqDialog(type, false);
    }

    componentDidMount() {
        this.props.fetchEvaluationMeta(this.props.product?.VendorUUID, this.props.user.Organization.ID)
    }

    componentDidUpdate(preProps) {
        if (this.props.metaFetchProgress === false && preProps.metaFetchProgress === true) {
            var criteriaReqMap = {};
            var totalReqLegth = 0;
            var isRuleUsed = false;
            if (this.props.criterias !== null && this.props.criterias !== undefined) {
                this.props.criterias.forEach(function (item) {
                    if (item?.rules.length > 0) {
                        isRuleUsed = true;
                    }
                    if (item.requirements) {
                        item.requirements.forEach(function (req) {
                            if (req?.rules.length > 0) {
                                isRuleUsed = true;
                            }
                        })
                    }
                    if (criteriaReqMap[item.id] === undefined) {
                        criteriaReqMap[item.id] = [];
                        var requirements = item.requirements.map(o => o.id);
                        totalReqLegth += requirements.length;
                        criteriaReqMap[item.id] = requirements;
                    }
                })
            }

            // console.log(this.props.criterias, 'this.props.criterias-0001')
            // console.log(criteriaReqMap, 'criteriaReqMap00000000001')
            // console.log(isRuleUsed, 'isRuleUsed')

            this.setState({
                criteriaReqMap: criteriaReqMap,
                criteriaShowAllMap: {},
                criteriaWiseTotalSelected: {},
                totalRequirementsCount: totalReqLegth,
                isRuleUsedForEvaluation: isRuleUsed,
                mainRequirementId: this.props.selectedRequirementId,
            })

            var selectAllIds = [];
            if (isRuleUsed) {
                Object.keys(criteriaReqMap).forEach(function (criteria) {
                    criteriaReqMap[criteria].forEach(function (req) {
                        selectAllIds.push(req);
                    });
                })
            }

            const { sellerEvaluationMeta } = this.props;
            var criterias = (sellerEvaluationMeta !== undefined && sellerEvaluationMeta !== null) ? sellerEvaluationMeta?.criterias : [];
            var selectedIds = [];
            criterias.forEach(function (criteria) {
                criteria.requirements.forEach(function (req) {
                    selectedIds.push(req.id)
                })
            });

            if (selectedIds.length > 0) {
                var isSendDisable = false;
                if (totalReqLegth === selectedIds.length) {
                    isSendDisable = true;
                }
                this.setState({
                    requirementIds: selectedIds,
                    alreadySelectedReqIds: selectedIds,
                    isSendDisable: isSendDisable,
                    selectedIds,
                }, () => {
                    this.updateSelectAlloption();
                    this.checkSelectAllOptionOnLoad();
                })
            } else {
                this.setState({
                    requirementIds: selectAllIds,
                    selectedIds,
                }, () => {
                    if(this.state.isRuleUsedForEvaluation){
                        this.submitRequest()
                    }else{
                        this.updateSelectAlloption();
                    }
                })
            }
        }

        if (this.props.reSendAskSellerWithSellerResponseProgress === false && preProps.reSendAskSellerWithSellerResponseProgress === true) {
            this.props.showSnackBar("Notification sent to seller", "success", 3000)
        }

        if (this.props.submitProgress === false && preProps.submitProgress === true) {
            if (this.props.submitError !== null) {
                this.props.showSnackBar("Request failed to sent to seller", "error", 3000)
            } else {
                this.props.fetchEvaluationMeta(this.props.product?.VendorUUID, this.props.user.Organization.ID);
                this.props.showSnackBar("Request sent to seller", "success", 3000)
                this.props.productRFPMapFetch(this.props.match.params.evaluation_id)
            }

        }

    }

    toggleRequirement(requirementId) {
        var selectRequirementIds = [...this.state.requirementIds];
        if (selectRequirementIds.indexOf(requirementId) === -1) {
            selectRequirementIds.push(requirementId)
        } else {
            selectRequirementIds.splice(selectRequirementIds.indexOf(requirementId), 1)
        }
        this.setState({
            requirementIds: selectRequirementIds
        }, () => {
            this.updateSelectAlloption();
        })
    }

    submitRequest() {
        var asksellerMap = [];
        var selectRequirementIds = this.state.requirementIds;
        var alreadySelectedReqIds = this.state.alreadySelectedReqIds;
        // console.log(selectRequirementIds, 'selectRequirementIds')
        // console.log(alreadySelectedReqIds, 'alreadySelectedReqIds')

        this.props.criterias.forEach((criteria) => {
            criteria.requirements.forEach((requirement) => {
                if (alreadySelectedReqIds.indexOf(requirement.id) !== -1) {
                    return;
                } else if (selectRequirementIds.indexOf(requirement.id) !== -1) {
                    var description = requirement.description
                    if (requirement.description === null || String(requirement.description).trim().length === 0) {
                        description = requirement.name
                    }
                    asksellerMap.push({
                        'requirementId': requirement.id,
                        'response': description
                    })
                }
            })
        });

        if (asksellerMap.length === 0) {
            this.props.showSnackBar("Please select atleast one requirement", "error", 3000)
            return;
        }

        this.props.askSellerSendRequirments(this.props.product.VendorUUID, asksellerMap)
    }

    updateSelectAlloption() {
        var criteriaShowAllMap = {};
        var criteriaWiseTotalSelected = {};
        // console.log(this.state.requirementIds, 'requirementIds')
        // console.log(this.state.criteriaReqMap, 'criteriaReqMap')
        if (this.state.requirementIds !== undefined && this.state.criteriaReqMap !== undefined && this.state.criteriaReqMap !== null && Object.keys(this.state.criteriaReqMap).length > 0) {
            var allCriterias = this.state.criteriaReqMap;
            var allSelReqs = [...this.state.requirementIds];
            Object.keys(allCriterias).forEach(function (criteria) {
                var totalReqLength = allCriterias[criteria].length;
                var totalSelected = 0;
                allCriterias[criteria].forEach(function (req) {
                    if (allSelReqs.indexOf(req) !== -1) {
                        totalSelected++;
                    }
                })
                criteriaWiseTotalSelected[criteria] = totalSelected;
                if (totalSelected === totalReqLength) {
                    criteriaShowAllMap[criteria] = true;
                } else {
                    criteriaShowAllMap[criteria] = false;
                }
            });
        }

        this.setState({
            criteriaShowAllMap: criteriaShowAllMap,
            criteriaWiseTotalSelected: criteriaWiseTotalSelected
        }, () => {
            setTimeout(()=>{
                this.checkSelectAllOption()
            },200)
        })
    }

    checkSelectAllOptionOnLoad() {
        var alreadySelectedCriteriaMap = {};
        var criteriaWiseTotalSelected = {};
        console.log(this.state.alreadySelectedReqIds, 'alreadySelectedReqIds')
        console.log(this.state.criteriaReqMap, 'criteriaReqMap')
        if (this.state.alreadySelectedReqIds !== undefined && this.state.criteriaReqMap !== undefined && this.state.criteriaReqMap !== null && Object.keys(this.state.criteriaReqMap).length > 0) {
            var allCriterias = this.state.criteriaReqMap;
            var alreadyAllSelReqs = this.state.alreadySelectedReqIds;
            Object.keys(allCriterias).forEach(function (criteria) {
                var totalReqLength = allCriterias[criteria].length;
                var totalSelected = 0;
                allCriterias[criteria].forEach(function (req) {
                    if (alreadyAllSelReqs.indexOf(req) !== -1) {
                        totalSelected++;
                    }
                })
                criteriaWiseTotalSelected[criteria] = totalSelected;
                if (totalSelected === totalReqLength) {
                    alreadySelectedCriteriaMap[criteria] = true;
                } else {
                    alreadySelectedCriteriaMap[criteria] = false;
                }
            });
        }
        this.setState({
            alreadySelectedCriteriaMap: alreadySelectedCriteriaMap,
            criteriaWiseTotalSelected: criteriaWiseTotalSelected
        })
    }

    selectAllRequirtments(criteriaId) {
        var selectRequirementIds = [...this.state.requirementIds];
        var allCriteriaIdMap = this.state.criteriaReqMap;
        if (allCriteriaIdMap[criteriaId] !== undefined) {
            if (this.state.criteriaShowAllMap[criteriaId]) {
                allCriteriaIdMap[criteriaId].forEach(function (requirementId) {
                    if (selectRequirementIds.indexOf(requirementId) !== -1) {
                        selectRequirementIds.splice(selectRequirementIds.indexOf(requirementId), 1)
                    }
                });
            } else {
                allCriteriaIdMap[criteriaId].forEach(function (requirementId) {
                    if (selectRequirementIds.indexOf(requirementId) === -1) {
                        selectRequirementIds.push(requirementId)
                    }
                });
            }
        }
        this.setState({
            requirementIds: selectRequirementIds
        }, () => {
            this.updateSelectAlloption();
        })
    }

    handleCloseDialog = () => {
        this.props.setSelectedRequirement(this.state.mainRequirementId);
        this.props.hideDialog()
    }

    selectAllCriterias() {
        // console.log('Calling')
        const state = this.state;
        var selectRequirementIds = [...this.state.requirementIds];
        var allCriteriaIdMap = this.state.criteriaReqMap;
        let isAllSelected = Object.values(state.criteriaShowAllMap).find(flag => flag === false);
        var criterias = this.props.criterias !== undefined && this.props.criterias !== null ? this.props.criterias : [];
        criterias.forEach(function (cri) {
            // console.log(cri, 'Criteriaaaaa')
            let criteriaId = cri.id;
            if (allCriteriaIdMap[criteriaId] !== undefined) {
                if (isAllSelected || isAllSelected === undefined) {
                    allCriteriaIdMap[criteriaId].forEach(function (requirementId) {
                        if (selectRequirementIds.indexOf(requirementId) !== -1) {
                            selectRequirementIds.splice(selectRequirementIds.indexOf(requirementId), 1)
                        }
                    });
                } else {
                    allCriteriaIdMap[criteriaId].forEach(function (requirementId) {
                        if (selectRequirementIds.indexOf(requirementId) === -1) {
                            selectRequirementIds.push(requirementId)
                        }
                    });
                }
            }
        })

        // console.log(selectRequirementIds, 'selectRequirementIds5555')

        this.setState({
            requirementIds: selectRequirementIds
        }, () => {
            this.updateSelectAlloption();
        })
    }

    checkSelectAllOption() {
        var me = this;
        var criterias = this.props.criterias !== undefined && this.props.criterias !== null ? this.props.criterias : [];
        // console.log(criterias, 'criterias01')
        var totalCriterias = criterias.length;
        // console.log(this.state.criteriaShowAllMap, 'criteriaShowAllMap');
        var totalChecked = 0;
        Object.keys(me.state.criteriaShowAllMap).forEach(function (itm) {
            if (me.state.criteriaShowAllMap[itm]) {
                totalChecked++;
            }
        })
        var isChecked = false;
        if (totalChecked === totalCriterias) {
            isChecked = true;
        }
        this.setState({
            checkAllCriterias: isChecked,
            isLoading:!this.state.isRuleUsedForEvaluation ? false : true
        })
    }

    render() {
        const classes = this.props.classes;
        const { sellerEvaluationMeta } = this.props;

        const { isLoading } = this.state;

        if (isLoading) {
            return <div className={classes.loader}>
                <CircularProgress />
            </div>
        }
        var progress = (sellerEvaluationMeta !== undefined && sellerEvaluationMeta !== null) ? sellerEvaluationMeta.progress : {};
        var totalReq = progress?.Total;
        var totalDone = progress?.Completed;
        var totalDoneProgress = 0;

        totalDoneProgress = Math.round(((totalDone * 100) / totalReq));

        if (isNaN(totalDoneProgress)) {
            totalDoneProgress = 0;
        }

        return <DialogContent classes={{ root: classes.dialogContent }} >
            {this.props.product && <div className={classes.productDetail} style={{ display: 'flex' }}>
                <Grid container alignItems="center" className={classes.cartItem}>
                    <Grid item className={classes.productImageContainer}>
                        <Image alt="product" src={this.props.product?.ProductLogo} className={classes.productImage} />
                    </Grid>
                    <Grid item className={classes.productNameContainer}>
                        <Grid container alignItems='center' justifyContent="space-between">
                            <Typography variant={"h5"} className={classes.productNameTitle}>{this.props.product.Name}</Typography>
                        </Grid>
                        <Divider style={{ margin: '8px 0px' }} />
                        <div className={classes.productCompBox}>
                            <span>{this.props.product.VendorEmail}</span>
                            <IconButton onClick={() => this.props.editSellerDetails()}>
                                <Image src={editIcon} />
                            </IconButton>
                        </div>
                        {/* <Divider style={{ margin: '8px 0px' }} />
                        <div className={classes.progressBar} onClick={() => { this.showFinalizeDialog('DONE') }}>
                            <Typography className={classes.progText}>
                                <div className={classes.productPerCom}>
                                    <span className={classes.progTextComp}>{totalDoneProgress}% Completed</span>
                                    <span className={classes.totalAnswered}>{totalDone}/{totalReq}</span>
                                </div>
                            </Typography>
                            <BorderLinearProgress variant="determinate" value={totalDoneProgress} />
                        </div> */}
                    </Grid>
                </Grid>

                {/* 
                    <Image className={classes.productLogo} alt="product" src={this.props.product?.ProductLogo} />
                    <Typography className={classes.productName}>{this.props.product.Name} </Typography>
                    <span style={{ marginLeft: 10, marginTop: 5 }}>({this.props.product.VendorEmail})</span> */}


            </div>}

            <div style={{ display: 'flex', alignItems: 'center', marginTop: 25 }}>
                <Checkbox
                    label={'Select All'}
                    className={classes.criteriaAllSelect}
                    disabled={this.state.isRuleUsedForEvaluation}
                    color="primary"
                    checked={this.state.checkAllCriterias}
                    onChange={() => {
                        this.selectAllCriterias()
                    }}
                />
                <Typography>Select All</Typography>
            </div>

            <div className={classes.container}>
                {
                    this.props.criterias && this.props.criterias?.map((item, index) => {
                        if (item?.requirements.length <= 0) {
                            return '';
                        }
                        return <Accordion key={index}>
                            <AccordionSummary className={classes.AccordionHeader}
                                expandIcon={<ExpandMoreIcon />}
                                aria-label={item.name}
                                aria-controls={`additional-actions${index + 1}-content`}
                                id={`additional-actions${index + 1}-header`}
                            >
                                <Checkbox
                                    className={classes.criteriaAllSelect}
                                    color="primary"
                                    disabled={(this.state.alreadySelectedCriteriaMap[item.id] !== undefined && this.state.alreadySelectedCriteriaMap[item.id] === true) || this.state.isRuleUsedForEvaluation ? true : false}
                                    checked={this.state.criteriaShowAllMap[item.id] !== undefined ? this.state.criteriaShowAllMap[item.id] : false}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        this.selectAllRequirtments(item.id)
                                    }}
                                />

                                <Typography className={classes.heading}>{item.name}</Typography>

                                {this.state.criteriaWiseTotalSelected[item.id] !== undefined && this.state.criteriaWiseTotalSelected[item.id] > 0 && <span className={classes.isExisting}>{this.state.criteriaWiseTotalSelected[item.id] === 1 ? this.state.criteriaWiseTotalSelected[item.id] + ' Question Selected' : this.state.criteriaWiseTotalSelected[item.id] + ' Requirements Selected'}</span>}

                            </AccordionSummary>
                            <AccordionDetails>
                                <ul className={classes.UL}>
                                    {
                                        item?.requirements?.map((list, index) =>
                                            <li style={{ position: 'relative' }} key={index + 'ask_requirement'}>
                                                <Checkbox
                                                    className={classes.multiSelect}
                                                    color="primary"
                                                    disabled={(this.state.alreadySelectedReqIds.indexOf(list.id) !== -1 || this.state.isRuleUsedForEvaluation) ? true : false}
                                                    checked={(this.state.requirementIds.indexOf(list.id) === -1) ? this.state.alreadySelectedReqIds.indexOf(list.id) !== -1 ? true : false : true}
                                                    onChange={() => {
                                                        this.toggleRequirement(list.id)
                                                    }}
                                                />
                                                <p style={{ paddingLeft: 50, marginBottom: 15 }}>
                                                    <b>{list.name}</b>
                                                    &nbsp;&nbsp;
                                                    {this.props.selectedRequirementId && findRequirementPreviousInteraction(this.props.selectedRequirementId, this.props.previousInteractions, 'seller').has && (
                                                        <span
                                                            onClick={() => {
                                                                this.props.setSelectedRequirement(list.id)
                                                                setTimeout(() => {
                                                                    this.props.setResponseTab(2);
                                                                    this.props.tooglePreviousInteractionDialog()
                                                                }, 100)
                                                            }}
                                                            className={classes.viewAllResponse}
                                                        >View previous interaction</span>
                                                    )}
                                                </p>
                                                <p style={{ paddingLeft: 50 }}>
                                                    <SlateReadonlyField
                                                        initValue={list.description} /></p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    })
                }
            </div>



            <div className={classes.bottomLinks} style={{ "textAlign": "right" }}>
                {<SqureButton
                    variant={"outlined"}
                    disabled={false}
                    onClick={() => { this.props.onClose() }}>

                    {this.props.updateSellerStateProgress ? <CircularProgress style={{ width: 23, height: 23 }} /> : 'Skip'}


                </SqureButton>}

                {this.props.showSellerEdit && <SqureButton
                    cls={classes.updateBtn}
                    disabled={false}
                    variant={"contained"}
                    onClick={() => {
                        this.props.updateSellerDetails()
                    }}>
                    {this.props.sellerDetailsUpdateProgress ? <CircularProgress style={{ width: 23, height: 23 }} /> : 'Update'}
                </SqureButton>}

                <SqureButton
                    style={{ "marginLeft": "10px" }}
                    disabled={this.state.isSendDisable}
                    variant={"contained"}
                    onClick={() => {
                        this.submitRequest()
                    }}>
                    {this.props.submitProgress ? <CircularProgress style={{ width: 23, height: 23 }} /> : 'Next'}
                </SqureButton>
            </div>

            {/* <div className={classes.actions}>
                {!this.props.submitProgress && <React.Fragment>
                    <SqureButton
                        disabled={this.state.isSendDisable}
                        variant={"contained"}
                        onClick={() => {
                            this.submitRequest()
                        }}>Send</SqureButton>
                </React.Fragment>}
                {this.props.submitProgress && <CircularProgress />}
            </div> */}

        </DialogContent>

    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ChooseAsksellerCriterias));
