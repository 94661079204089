import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Grid, InputAdornment, CircularProgress, Typography } from '@material-ui/core';
import { Search as SearchIcon } from "@material-ui/icons";
import AppBarV2 from "Components/Application/AppBarV2";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import DefaultImage from "assets/images/evaluation/default.svg";
import CommonCss from 'commonCss';

import {
  createEvaluationCart,
  removeProductFromCart,
  fetchEvaluationCart,
} from "redux/evaluation/cart/action";
// Redux Actions
import { fetchCompanyRecommendationsProducts, fetchProductReaction, checkWishlistedProducts } from "redux/product/action";

import Clear from "@material-ui/icons/Clear";
import TooltipMsg from '@material-ui/core/Tooltip';

import { isEnabledEvaluate } from "util/Common";

const connectedProps = (state) => ({
  companyRecommendationsProducts: state.products.companyRecommendationsProducts,
  fetchCompanyRecommendationsProductsProgress: state.products.fetchCompanyRecommendationsProductsProgress,
  fetchProductsReactionProgress: state.products.fetchProductsReactionProgress,
  orgUser: state.orgUser,
  userRole: state.authUser.user?.Role,
});

const connectionActions = {
  fetchCompanyRecommendationsProducts: fetchCompanyRecommendationsProducts,
  fetchProductReaction: fetchProductReaction,
  checkWishlistedProducts: checkWishlistedProducts,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {},
  searchIcon: {
    marginRight: theme.spacing(2)
  },
  searchSection: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    '& [class*="MuiOutlinedInput-root"]': {
      maxHeight: 40,
      minHeight: 40,
      maxWidth: 343,
    },
    "& [class*='MuiInputBase-input']": {
      fontSize: 13,
      width: 300,
    },
    "& [class*='MuiSvgIcon-root']": {
      fill: "#707070",
    },
    "& [class*=MuiGrid-container]": {
      alignItems: "center",
      marginBottom: 20,
    },
    "&>div ": {
      marginBottom: 0,
    },
  },
  headSection: {
    marginTop: '2.5em',
    marginBottom: '2.5em'
  },
  searchIconPop: {
    cursor: 'pointer',
    fontSize: theme.spacing(3),
    marginRight: '10px',
    "&:hover": {
      color: "#fff !important",
      fill: "#fff !important",
      background: "#707070",
      borderColor: "#707070",
      padding: '3px',
      borderRadius: theme.spacing(20),
      fontSize: theme.spacing(3)
    }
  },
  label: {
    fontSize: "1.4em",
    fontWeight: 600,
    lineHeight: "1.6",
    marginBottom: 15,
    marginTop: 15
  },
  rootContainer: CommonCss.rootContainer
});

class PersonalizedProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      categoryProducts: [],
      otherProducts: []
    }
  }

  componentDidMount() {
    const urlPrams = new URLSearchParams(this.props.location.search);
    var evId = urlPrams.get("evaluationId");
    if (evId !== null) {
      setTimeout(() => {
        this.props.fetchEvaluationCart(evId);
      }, 1000);
    }
    this.props.fetchCompanyRecommendationsProducts();
  }

  componentDidUpdate(prevProps) {
    const { companyRecommendationsProducts, fetchCompanyRecommendationsProductsProgress } = this.props;
    if (!fetchCompanyRecommendationsProductsProgress && prevProps.fetchCompanyRecommendationsProductsProgress && companyRecommendationsProducts !== null) {
      this.fetchData(true)
    }
  }

  fetchData = (isReaction) => {
    let categoryProducts = []
    let otherProducts = []
    if (
      this.props.companyRecommendationsProducts?.CategoryProducts &&
      Array.isArray(this.props.companyRecommendationsProducts?.CategoryProducts) &&
      this.props.companyRecommendationsProducts?.CategoryProducts.length > 0
    ) {
      categoryProducts = this.props.companyRecommendationsProducts?.CategoryProducts.map((element) => {
        let elementData = { ...element };
        if (this.state.query.length === 0) return elementData
        elementData.category_products = elementData.category_products.filter(o => o.ProductDetails.Name.toLowerCase().indexOf(this.state.query.toLowerCase()) > -1)
        return elementData
      })
    }
    if (
      this.props.companyRecommendationsProducts?.OtherProducts &&
      Array.isArray(this.props.companyRecommendationsProducts?.OtherProducts) &&
      this.props.companyRecommendationsProducts?.OtherProducts.length > 0
    ) {
      otherProducts = this.props.companyRecommendationsProducts?.OtherProducts.filter(o => {
        if (this.state.query.length === 0) return true
        return o.Name.toLowerCase().indexOf(this.state.query.toLowerCase()) > -1
      })
    }
    this.setState({
      categoryProducts, otherProducts
    }, () => {
      if (isReaction) this.getProductReactions()
    })
  }

  getProductReactions() {
    let productIds = [];
    try {
      if (this.state.otherProducts && this.state.otherProducts.length) {
        var otherProductIds = this.state.otherProducts.map(p => parseInt(p.ID))
        productIds = otherProductIds
      }
      if (this.state.categoryProducts && this.state.categoryProducts.length) {
        const data = this.state.categoryProducts
        data.forEach((element, index) => {
          if (element.category_products && element.category_products.length > 0)
            productIds = [...productIds, ...element.category_products.map(o => parseInt(o.ProductDetails.ID))]
        })
      }
    } catch (e) { }

    this.props.checkWishlistedProducts(productIds);
    setTimeout(() => {
      this.props.fetchProductReaction('recommended', {
        productIds,
        source: 'recommended',
        sourceData: 'company-recommended'
      })
    }, 1000);

    setTimeout(() => {
      this.props.fetchProductReaction('all_product_blocking', {
        productIds,
        source: 'all_product_blocking',
        sourceData: 'all_product_blocking'
      })
    }, 2000);
  }


  addToCart(product_id) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(parseInt(product_id));
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(parseInt(product_id)) !== -1) {
        this.props.removeProductFromCart(
          this.props.evaluationCart.ID,
          parseInt(product_id)
        );
      } else {
        this.props.createEvaluationCart(
          parseInt(product_id),
          this.props.evaluationCart.ID
        );
      }
    }
  }

  render() {
    const classes = this.props.classes;
    const { query, categoryProducts, otherProducts } = this.state;
    const { fetchCompanyRecommendationsProductsProgress } = this.props;
    let totalProduct = otherProducts.length
    const data = categoryProducts
    data.forEach(element => {
      totalProduct += element.category_products.length
    })

    const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForUsersCheck')
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableEvaluateForAdminsCheck')
    let isShowCheckBox = true;
    if (((!EnableEvaluateForAdminsCheck && this.props.userRole !== 'OrgUser') || (!EnableEvaluateForUsersCheck && this.props.userRole === 'OrgUser'))) {
      isShowCheckBox = false;
    }

    return (
      <div className={classes.root}>
        <AppBarV2 title="Company Recommendations" withBack={true} />
        <div className={classes.rootContainer}>
          <Grid container justifyContent="space-between" className={classes.headSection}>
            <p className={classes.productCount}>
              {totalProduct} {" "}
              {totalProduct > 1 ? " Products" : " Product"}{" "}
              found
            </p>
            <div className={classes.searchSection}>
              <OutlinedInput
                id={"SearchPersonalizedProducts"}
                placeholder="Search Products"
                value={query}
                onChange={(event) => {
                  this.setState({
                    query: event.target.value
                  }, () => {
                    this.fetchData(false);
                  })
                }}
                startAdornment={
                  <InputAdornment>
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment>
                    {this.state.query.length > 0 && this.state.query !== '' && this.state.query !== null && <TooltipMsg placement="top" title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Clear</span>} arrow>
                      <Clear className={classes.searchIconPop}
                        onClick={() => {
                          this.setState({ query: '' }, () => {
                            this.fetchData(false)
                          })
                        }}
                      />
                    </TooltipMsg>}
                  </InputAdornment>
                }
                roundedCorner={true}
              />
            </div>
            <div>
            </div>
          </Grid>
          {fetchCompanyRecommendationsProductsProgress && (
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <CircularProgress />
            </div>
          )}

          {!fetchCompanyRecommendationsProductsProgress &&
            categoryProducts && (
              <>
                {categoryProducts.map((categoryProducts, index) => {
                  if (categoryProducts.category_products.length === 0) return null
                  return (
                    <>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        key={`category-${categoryProducts.category_id}-${index + 1}`}
                      >
                        <Typography className={classes.label}>
                          {categoryProducts.category_name}
                        </Typography>
                      </Grid>
                      <Grid container spacing={3}>
                        {categoryProducts.category_products.map((ele, i) => {
                          const product = ele.ProductDetails
                          return (
                            <Grid item lg={2} md={3} sm={6} xs={12} key={product.ID}>
                              <div className={classes.productCard}>
                                <LayeredCard
                                  title={product.Name}
                                  subTitle={product.CompanyName}
                                  description={product.Description}
                                  image={product.ProductLogo === null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo}
                                  toogleCheck={(e) => {
                                    this.addToCart(product.ID);
                                  }}
                                  showExplanation={true}
                                  explanation={ele.Explanation}
                                  ishoverExplanationToolTip={true}
                                  autoHideExplanationToolTip={true}
                                  onHoverExplanationToolTipText="Why am i seeing this?"
                                  onClick={() => { }}
                                  numLayer={0}
                                  showCheckbox={isShowCheckBox}
                                  id={product.ID}
                                  uniqueId={product.ID + '_o_' + i + 'category_products' + index}
                                  customRoute={'/app/products/product-details/'}
                                  type={'product'}
                                  typeOfBlock="all_product_blocking"
                                  showReactionResponses
                                  typeOfLike="recommended"
                                  fromPage={'company-recommendation'}
                                  isActionButtons
                                  showLike
                                  fireEvent
                                  eventPage={"companyRecommendation"}
                                  eventData={{
                                    type: "event.click.product.on-company-recommendation",
                                    data: {
                                      url: this.props.location.pathname + this.props?.location?.search,
                                      product: {
                                        id: product.ID,
                                        name: product.Name,
                                        position: i + 1,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </>
                  )
                })}
              </>
            )}

          {!fetchCompanyRecommendationsProductsProgress &&
            otherProducts && otherProducts.length > 0 && (
              <>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography className={classes.label}>
                    Other Products
                  </Typography>
                </Grid>
                <Grid container spacing={3}>
                  {otherProducts.map((product, i) => (
                    <Grid item lg={2} md={3} sm={6} xs={12} key={product.ID}>
                      <div className={classes.productCard}>
                        <LayeredCard
                          title={product.Name}
                          subTitle={product.CompanyName}
                          description={product.Description}
                          image={product.ProductLogo === null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo}
                          toogleCheck={(e) => {
                            this.addToCart(product.ID);
                          }}
                          showExplanation={true}
                          explanation={product.Explanation}
                          ishoverExplanationToolTip={true}
                          autoHideExplanationToolTip={true}
                          onHoverExplanationToolTipText="Why am i seeing this?"
                          onClick={() => { }}
                          numLayer={0}
                          showCheckbox={isShowCheckBox}
                          id={product.ID}
                          uniqueId={product.ID + '_o_' + i}
                          customRoute={'/app/products/product-details/'}
                          type={'product'}
                          typeOfBlock="all_product_blocking"
                          showReactionResponses
                          typeOfLike="recommended"
                          fromPage={'company-recommendation'}
                          isActionButtons
                          showLike
                          fireEvent
                          eventPage={"companyRecommendation"}
                          eventData={{
                            type: "event.click.product.on-company-recommendation",
                            data: {
                              url: this.props.location.pathname + this.props?.location?.search,
                              product: {
                                id: product.ID,
                                name: product.Name,
                                position: i + 1,
                              },
                            },
                          }}
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
        </div>
      </div>);
  }
}

export default connector(compose(withRouter, withStyles(styles))(PersonalizedProducts));