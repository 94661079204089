import React, { useState, useEffect } from 'react';
import classnames from "classnames";
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
    Dialog, CircularProgress, DialogContent, DialogActions,
    Slide, DialogTitle
} from '@material-ui/core';
import SqureButton from 'Components/Common/SqureButton';
import CommonCss from 'commonCss';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => createStyles({
    formControl: {
        ...CommonCss.formControl,
        '& > div': {
            backgroundColor: 'tarsparent !important',
            border: 'none',
            paddingRight: 0,
        },
        padding: '.375rem',
    },
    formGroup: {
        marginTop: 5,
        marginBottom: 16,
        '& input': {
            color: '#6C6C6C !important'
        }
    },
    dialogContent: {
        minHeight: 170,
        overflowY: 'hidden',
        "& [class*=select__menu]": {
            maxHeight: 100
        }
    },
});

const BlockProductMessageDialog = ({
    classes,
    progress = false,
    isOpen,
    onSubmit,
    onClose,
    value = "",
}) => {
    const [message, setMessage] = useState("")
    const handleOnClose = () => {
        setMessage("")
        onClose()
    }
    useEffect(() => {
        setMessage(value)
    }, [value])
    return (
        <Dialog
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleOnClose()
                }
            }}
            aria-labelledby=""
            open={isOpen}
            TransitionComponent={Transition}
            scroll={"body"}
            fullWidth={true}
            className={classnames(classes.dialog)}
            id="productBLockDialog"
            style={{ zIndex: 10001 }}
        >
            <DialogTitle id="form-dialog-title">Reason for rejecting</DialogTitle>
            <DialogContent>
                <div className={classes.formGroup}>
                    <SlateInputField
                        placeholder="Product rejected by your organization. Please contact your admin for more information"
                        as={SlateInputField}
                        onChangeEvent={(val) => {
                            setMessage(val);
                        }}
                        initValue={message}
                        textContainerStyle={{ color: "#707070" }}
                        isFile={false}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {progress && <CircularProgress />}
                {!progress && (
                    <div style={{ padding: '10px 24px' }}>
                        <SqureButton
                            variant={"outlined"}
                            onClick={() => {
                                handleOnClose()
                            }}
                        >
                            Cancel
                        </SqureButton>
                        &nbsp; &nbsp; &nbsp;
                        <SqureButton
                            variant={"contained"}
                            onClick={() => {
                                onSubmit(message)
                                handleOnClose()
                            }}
                        >
                            Save
                        </SqureButton>
                    </div>
                )}
            </DialogActions>
        </Dialog >
    )
}


export default React.memo(withStyles(styles)(BlockProductMessageDialog));
