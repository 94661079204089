import { CommonFn } from "./commonFn";
import { Axios } from "./index";

export class HttpFactory{
    
    static _instance;
       
    static instance() {
        if (HttpFactory._instance == null) {
            HttpFactory._instance = new HttpFactory();
        }
        return HttpFactory._instance;
    }
    
    getHeader() {
        return {'Authorization': CommonFn.getStorage('authType')+' '+CommonFn.getStorage('authToken')};
    }
   
    async getMethod(url, params = null, disableDuplicateCall = false){
        if(!CommonFn.getStorage('authType') || !CommonFn.getStorage('authToken')){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try {

            
            if(!disableDuplicateCall){
                let duplicateCall = url.split('?')[0] || '';
                //Check if there are any previous pending requests
                if (typeof this.getPreviousCall !== typeof undefined && this.duplicateGetPreviousCall === duplicateCall) {
                    this.getPreviousCall.cancel("cancelled");
                }
                //Save the cancel token for the current request
                this.getPreviousCall = Axios.CancelToken.source();
                this.duplicateGetPreviousCall = duplicateCall;
            }
            if(params){
                response  =  await Axios.get(url, 
                    { 
                        params: params,
                        headers: this.getHeader()
                    }
                );
            }else{
                response  =  await Axios.get(url, 
                    { 
                        headers: this.getHeader(), 
                        cancelToken:  this.getPreviousCall.token 
                    }
                );
            }

             
        } catch(error){
            if(error.message === 'cancelled') {
                return [null,{'message':"cancelled"}];
            } else if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response){
                return [null,error.response.data];
            }
        }
        if(!response.data){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getMethodV3(url, params = null, disableDuplicateCall = false){
        if(!CommonFn.getStorage('authType') || !CommonFn.getStorage('authToken')){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try {

            
            if(!disableDuplicateCall){
                let duplicateCall = url.split('?')[0] || '';
                //Check if there are any previous pending requests
                if (typeof this.getPreviousV3Call !== typeof undefined && this.duplicateGetPreviousV3Call === duplicateCall) {
                    this.getPreviousV3Call.cancel("cancelled");
                }
                //Save the cancel token for the current request
                this.getPreviousV3Call = Axios.CancelToken.source();
                this.duplicateGetPreviousV3Call = duplicateCall;
            }
            if(params){
                response  =  await Axios.get(url, 
                    { 
                        params: params,
                        headers: this.getHeader()
                    }
                );
            }else{
                response  =  await Axios.get(url, 
                    { 
                        headers: this.getHeader(), 
                        cancelToken:  this.getPreviousV3Call.token 
                    }
                );
            }

             
        } catch(error){
            console.log(error);
            if(error.message === 'cancelled') {
                return [null,{'message':"cancelled"}];
            } else if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response){
                return [null,error.response.data];
            }
        }
        if(!response.data){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getMethodV2(url,checkAuth){
        if(checkAuth === undefined){
            checkAuth = true;
        }
        if(checkAuth && (!CommonFn.getStorage('authType') || !CommonFn.getStorage('authToken'))){
            return [null,{'message':"User logged out.","status":-1}];
        }


        let response;
        try {
            response    =   await Axios.get(url, {
                headers: this.getHeader()
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response){
                return [null,error.response.data];
            }
        }
        if(!response.data){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async putMethod(url, payload,checkAuth){
        if(checkAuth === undefined){
            checkAuth = true;
        }
        if(checkAuth && (!CommonFn.getStorage('authType') || !CommonFn.getStorage('authToken'))){
            return [null,{'message':"User logged out.","status":-1}];
        }


        let response;
        try {
            response    =   await Axios.put(url, payload, {
                headers: this.getHeader()
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response){
                return [null,error.response.data];
            }
        }
        if(!response.data){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async deleteMethod(url,checkAuth, formData = {}){
        if(checkAuth === undefined){
            checkAuth = true;
        }
        if(checkAuth && (!CommonFn.getStorage('authType') || !CommonFn.getStorage('authToken'))){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try {
            response    =   await Axios.delete(url, {
                headers: this.getHeader(),
                data: formData
            },
            
            );
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response){
                return [null,error.response.data];
            }
        }
        if(!response.data){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async postMethod(url, formData, duplicateCallNotAllow = true,checkAuth=true){
        if(checkAuth === undefined){
            checkAuth = true;
        }
       
        let duplicateCall = url.split('?')[0] || '';
        if(this.postPreviousCall == undefined){
            this.postPreviousCall = {}
        }
        var prevCall = this.postPreviousCall[duplicateCall];
        if(prevCall != undefined){
            console.log(prevCall,(new Date()).getTime(),prevCall['timestamp'],(new Date()).getTime() - prevCall['timestamp'])
        }
        //Check if there are any previous pending requests
        if (typeof prevCall !== typeof undefined && ((new Date()).getTime() - prevCall['timestamp']) < 10000 && duplicateCallNotAllow) {
            prevCall.token.cancel("cancelled");
        }
    
        //Save the cancel token for the current request
        this.postPreviousCall[duplicateCall] = {token:Axios.CancelToken.source(),timestamp:(new Date()).getTime()};
        // this.duplicatePostPreviousCall = duplicateCall;

        if(checkAuth && (!CommonFn.getStorage('authType') || !CommonFn.getStorage('authToken'))){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try {
            response    =   await Axios.post(url, formData, {
                headers: this.getHeader(),
                // withCredentials: true,
                cancelToken:  this.postPreviousCall[duplicateCall].token.token 
            });
        } catch(error){
            console.log(error)
            if(error.message === 'cancelled') {
                return [null,{'message':"cancelled"}];
            } else if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response){
                return [null,error.response.data];
            }
        }
        if(!response.data){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }
}