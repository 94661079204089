import {ProductFactory} from "../../../services/products";
import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const START_SUBCATEGORY_FETCH     =   "START_SUBCATEGORY_FETCH";
export const END_SUBCATEGORY_FETCH       =   "END_SUBCATEGORY_FETCH";
export const START_SUBCATEGORY_FEATURE_FETCH     =   "START_SUBCATEGORY_FEATURE_FETCH";
export const END_SUBCATEGORY_FEATURE_FETCH       =   "END_SUBCATEGORY_FEATURE_FETCH";
export const START_CATEGORY_SUBCATEGORY_FETCH = "START_CATEGORY_SUBCATEGORY_FETCH";
export const END_CATEGORY_SUBCATEGORY_FETCH = "END_CATEGORY_SUBCATEGORY_FETCH";

export const START_SUBCATEGORYV2_FETCH = "START_SUBCATEGORYV2_FETCH";
export const END_SUBCATEGORYV2_FETCH = "END_SUBCATEGORYV2_FETCH";

function startSubCategoryV2FeatureFetch(page){
    return {
        type:START_SUBCATEGORYV2_FETCH,
        payload:page
    };
}

function endSubCategoryV2FeatureFetch(searchQuery, success,error){
    return {
        type:END_SUBCATEGORYV2_FETCH,
        payload:{
            searchQuery, success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchProductSubCategoryV2(searchQuery){
    const api_server = Environment.api_host("SEARCH");
    const timestamp  = (new Date()).getTime();
    const url        = `${api_server}/v2/dsl/attributes?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startSubCategoryV2FeatureFetch(searchQuery));
        try{
            let [response,error] =  await HttpFactory.instance().postMethod(url, searchQuery, true);
            dispatch(endSubCategoryV2FeatureFetch(searchQuery, response,error));
        } catch(e){
            dispatch(endSubCategoryV2FeatureFetch(searchQuery, null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startProductSubCategoryFetch(page){
    return {
        type:START_SUBCATEGORY_FETCH,
        payload:page
    };
}

function endProductSubCategoryFetch(success,error){
    return {
        type:END_SUBCATEGORY_FETCH,
        payload:{
            success,error
        }
    };
}

function startSubCategoryFeatureFetch(page){
    return {
        type:START_SUBCATEGORY_FEATURE_FETCH,
        payload:page
    };
}

function endSubCategoryFeatureFetch(success,error){
    return {
        type:END_SUBCATEGORY_FEATURE_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchProductSubCategory(categoryId,page,pageSize,query,withFeatures){
    if(withFeatures === undefined){
        withFeatures = false
    }
    //console.log(categoryId,page,pageSize,query)
    return async (dispatch) => {
        dispatch(startProductSubCategoryFetch(page));
        try{
            let [response,error]    =   await ProductFactory.instance().getSubCategories(categoryId,page,pageSize,query,withFeatures);
            dispatch(endProductSubCategoryFetch(response,error));
        } catch(e){
            dispatch(endProductSubCategoryFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//Thunk to perform the authorizedUser
export  function fetchSubCategoryFeature(subCategoryId,page,pageSize,query){
    return async (dispatch) => {
        dispatch(startSubCategoryFeatureFetch(page));
        try{
            let [response,error]    =   await ProductFactory.instance().getSubCategoryFeatures(subCategoryId,page,pageSize,query);
            dispatch(endSubCategoryFeatureFetch(response,error));
        } catch(e){
            dispatch(endSubCategoryFeatureFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 



function startCategorySubCategoryFetch(page){
    return {
        type:START_CATEGORY_SUBCATEGORY_FETCH,
        payload:page
    };
}

function endCategorySubCategoryFetch(success,error){
    return {
        type:END_CATEGORY_SUBCATEGORY_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the Category
export  function fetchCategorySubCategory(categoryId,page,pageSize,query,withFeatures){
    if(withFeatures === undefined){
        withFeatures = false
    }
    //console.log(categoryId,page,pageSize,query)
    return async (dispatch) => {
        dispatch(startCategorySubCategoryFetch(page));
        try{
            let [response,error]    =   await ProductFactory.instance().getSubCategories(categoryId,page,pageSize,query,withFeatures);
            dispatch(endCategorySubCategoryFetch(response,error));
        } catch(e){
            dispatch(endCategorySubCategoryFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 