import {
  Dialog, DialogContent, Slide, Typography, FormControl, MenuItem, Select, CircularProgress
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import Alert from '@material-ui/lab/Alert';
import CommonCss from 'commonCss';
import SqureButton from 'Components/Common/SqureButton';

import { hideAddAlertDialog,addNewAlert } from 'redux/alerts/action';
import { showSnackBar } from "redux/snackbar/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.alert.dialogAddAlertOpen,
});

const connectionActions = {
  hideDialog: hideAddAlertDialog,
  addNewAlert:addNewAlert,
  showSnackBar:showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
    margin: 'auto',
    marginBottom: 60
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  title: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
  },
  alert: {
    marginBottom: 10,
    marginTop: 25,
  },
  body: {
    paddingTop: 20,
    "& [class*='editableField']": {
      maxHeight: '105px !important;',
      minHeight: '50px !important',
    }
  },
  formControl: {
    width: '100%',
    '& > div': {
      color: '#282D30',
      fontSize: theme.spacing(2.2),
    },
  },
  filterTitle: {
    marginBottom: theme.spacing(1),
    color: '#282D30',
    fontSize: theme.spacing(2.2)
  },
  formItem: {
    marginBottom: 20
  },
  inputItem: {
    '& [class*="MuiInputBase-input"]': {
      padding: 12
    }
  },
  inputFormControl: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '10.5px',
  },
  bottomActions: {
    textAlign: 'right'
  },
  actionBtn: {
    marginLeft: 10
  },
  required:{
    color:'red'
  }
});

class AddAlertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      types: [{
        name: 'Trigger',
        value: 1
      }],
      alertType: 1,
      alertTarget: 1,
      targetTypes: [{
        name: 'Application Catlog',
        value: 1
      }],
      title:'',
      notification:'',
    }
    this.addAlert = this.addAlert.bind(this);
    this.clearError = this.clearError.bind(this);
    
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  clearError(){
    this.setState({
      success :'',
      error: ''
    })
  }

  addAlert() {
    this.clearError();
    const { title, notification, alertTarget, alertType } = this.state;
    if(title === '' || title.length <= 0){
      this.setState({
        error: 'Please enter title'
      })
      return;
    }

    if(notification === '' || notification.length <= 0){
      this.setState({
        error: 'Please enter notification'
      })
      return;
    }

    let postData = {
      title: title,
      notification : notification,
      type:alertType,
      target:alertTarget
    }

    this.props.addNewAlert(postData);
  }

  render() {
    const classes = this.props.classes;
    const { title, notification, alertTarget, targetTypes, alertType, types } = this.state;

    return <Dialog
      onClose={this.props.hideDialog}
      aria-labelledby="app-integrationDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"md"}
      scroll="body"
      id="evMetaDialog"
    >
      <DialogContent classes={{ root: classes.dialogContent }} >
        <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
        <Typography variant={"h4"} className={classes.title}>Add Alert</Typography>
        <div className={classes.body}>
          <div className={classes.alert}>
            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
          </div>

          <div className={classes.formItem}>
            <Typography variant={"h4"} className={classes.filterTitle}>Title<span className={classes.required}>*</span></Typography>
            <input className={classes.inputFormControl} placeholder="Enter title" name="title"
              value={title}
              autoFocus={true}
              onChange={e => {
                this.setState({
                  title : e.target.value
                },()=>{
                  this.clearError()
                })
              }}
            />
          </div>

          <div className={classes.formItem}>
            <Typography variant={"h4"} className={classes.filterTitle}>Notification<span className={classes.required}>*</span></Typography>
            <textarea rows={3} className={classes.inputFormControl} placeholder="Enter notification" name="notification"
              value={notification}
              onChange={e => {
                this.setState({
                  notification : e.target.value
                },()=>{
                  this.clearError()
                })
              }}
            />
          </div>

          {false && <><div className={classes.formItem}>
            <Typography variant={"h4"} className={classes.filterTitle}>Target</Typography>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <Select
                disabled={false}
                value={alertTarget}
                onChange={(e) => {
                  this.setState({ alertTarget: e.target.value });
                }}>
                {targetTypes.map((item, k) => (
                  <MenuItem value={item.value} style={{ fontSize: 13 }}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={classes.formItem}>
            <Typography variant={"h4"} className={classes.filterTitle}>Type</Typography>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <Select
                disabled={false}
                value={alertType}
                onChange={(e) => {
                  this.setState({ alertType: e.target.value });
                }}>
                {types.map((item, k) => (
                  <MenuItem value={item.value} style={{ fontSize: 13 }}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div></>}

          <div className={classes.bottomActions}>
            <SqureButton
              cls={classes.actionBtn}
              variant={"outlined"}
              onClick={this.props.hideDialog}>Cancel
            </SqureButton>

            <SqureButton
              cls={classes.actionBtn}
              variant={"contained"}
              onClick={this.addAlert}>
              {this.props.addNewTaskProgress ? <CircularProgress style={{ width: 24, height: 24 }} /> : 'Add'}
            </SqureButton>

          </div>
        </div>
      </DialogContent>
    </Dialog>
  }
}

AddAlertDialog.defaultProps = {
  isWorkFlow: false,
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(AddAlertDialog));