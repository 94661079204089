import React, { useState, useEffect } from 'react';
import classnames from "classnames";

import { withStyles, createStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import TextField from '@material-ui/core/TextField';

const styles = () => createStyles({
    savetickMark: {
        color: '#2EB77D',
        height: 30,
        width: 30,
    },
    canceltickMark: {
        color: 'red',
        height: 30,
        width: 30,
    },
    counter: {
        color: 'rgb(112, 112, 112)',
        fontSize: 10,
        margin: 0,
        marginTop: 2,
        padding: 3,
        backgroundColor: '#f1f1f1',
        width: 'max-content',
        borderRadius: 3,
    },
    actionIcon: {
        width: 35,
        height: 35,
        borderRadius: 5,
        textAlign: 'center',
        marginLeft: 5,
        border: '1px solid #4b86f8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    micon: {
        fontSize: 20,
        color: '#4b86f8',
    },
    outlinedInputBox: {
        display: 'flex',
        '& [class*="MuiOutlinedInput-input"]': {
            padding: '5px 10px',
            height: 25,
            fontSize: 14,
        },
    },
    box1: {
        flex: 1,
    }
});

const OutlinedActionInput = ({ classes, label = "", value, type = "text", maxLength = 0, showCount = false, onSave = () => { }, onCancel = () => { }, onChange = () => { } }) => {
    const [inputValue, setValue] = useState("")
    useEffect(() => {
        setValue(value)
    }, [value])

    return (
        <div className={classes.outlinedInputBox}>
            <div className={classes.box1}>
                <TextField
                    id="outlined-full-width"
                    fullWidth
                    type={type}
                    variant="outlined"
                    maxLength={maxLength}
                    placeholder={'Add response here'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={inputValue}
                    onChange={(event) => {
                        let val = event.target.value;
                        if(val.length <= maxLength){
                            setValue(event.target.value)
                            onChange(event.target.value)
                        }
                    }}
                />
                {maxLength > 0 && showCount && (
                    <div style={{float:'right'}}>
                        <p className={classes.counter}>Character Limit {maxLength}&nbsp; &nbsp;{inputValue.length}/{maxLength}</p>
                    </div>
                )}
            </div>
            <div
                className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                onClick={(e) => {
                    e.stopPropagation()
                    onSave(inputValue)
                }}
            >
                <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
            </div>
            <div
                className={classes.actionIcon}
                onClick={(e) => {
                    e.stopPropagation()
                    onCancel(value)
                }}
            >
                <ClearIcon className={classes.micon} />
            </div>
        </div>
    )
}

export default withStyles(styles)(OutlinedActionInput)