export const chartTypes = [
  { label: "Line", value: "LINE" },
  { label: "Bar", value: "BAR" },
  { label: "Pie", value: "PIE" },
  { label: "Doughnut", value: "DOUGHNUT" },
  { label: "Data Card", value: "DATACARD" },
  { label: "Link Card", value: "LINKCARD" },
  { label: "Data Table", value: "TABLE" },
];
export const chartConfigurations = [
  {
    type: "LINE",
    config: {
      locale: "en-US",
      fill: false,
      //common config as bar chart
      responsive: true,
      maintainAspectRatio: false,
      borderColor: "rgb(153, 102, 255)",
      borderWidth: 2,
      backgroundColor: "rgb(153, 102, 255,0.5)", // array prefered with different colors
      layout: {
        padding: 20,
      },
      legendBottomMargin: null,
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            pinch: {
              enabled: true, // Enable pinch zooming
            },
            wheel: {
              enabled: true, // Enable wheel zooming
            },
            mode: "x",
          },
        },
        title: {
          display: true,
          text: "Line Chart Title",
          color: "rgba(0, 0, 0, 0.54)",
          align: "center",
          font: {
            size: 14,
            weight: 800,
            color: "rgba(0, 0, 0, 0.54)",
            style: "normal",
          },
        },
        subtitle: {
          display: true,
          text: "Line Chart Subtitle",
          color: "rgba(0, 0, 0, 0.54)",
          align: "center",
          font: {
            size: 12,
            weight: 600,
            color: "rgba(0, 0, 0, 0.54)",
            style: "italic",
          },
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: "auto",
          color: "rgba(0, 0, 0, 0.54)",
          anchor: "end",
          align: "top",
          rotation: 0,
          formatter: function(value) {
            return value;
          },
          transformExpression: "",
          font: {
            size: 12,
            weight: 400,
            color: "rgba(0, 0, 0, 0.54)",
            style: "normal",
          },
        },
        legend: {
          display: true,
          position: "top",
          align: "center",
          labels: {
            color: "rgba(0, 0, 0, 0.54)",
            font: {
              size: 12,
              weight: 600,
              style: "normal",
            },
          },
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Month",
            color: "rgba(0, 0, 0, 0.54)",
            font: {
              size: 14,
              weight: 400,
              color: "rgba(0, 0, 0, 0.54)",
              style: "normal",
            },
          },
          ticks: {
            callback: function format(value) {
              return value;
            },
            padding: 1,
            font: {
              size: 12,
              weight: 400,
              color: "rgba(0, 0, 0, 0.54)",
              style: "normal",
            },
          },
          grid: {
            display: true,
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Sales",
            font: {
              size: 14,
              weight: 400,
              color: "rgba(0, 0, 0, 0.54)",
              style: "normal",
            },
          },
          ticks: {
            callback: function format(value) {
              return value;
            },
            transformExpression: "",
            font: {
              size: 12,
              weight: 400,
              color: "rgba(0, 0, 0, 0.54)",
              style: "normal",
            },
            stepSize: null,
            padding: 1,
          },
          grid: {
            display: true,
          },
          min: 0,
          max: null,
        },
      },
    },
  },
  {
    type: "BAR",
    config: {
      locale: "en-US",
      indexAxis: 'x',
      borderRadius: 2,
      maxBarThickness: 50,
      barThickness: null,
      borderSkipped: false,
      barPercentage: 0.9, //0, 0.1, 0.2,.....1
      categoryPercentage: 0.8, //0, 0.1, 0.2,.....1
      base: 0, //default value for bars
      //common config linear chart
      responsive: true,
      maintainAspectRatio: false,
      borderColor: "rgb(54, 162, 235)",
      backgroundColor: "rgb(54, 162, 235, 0.5)",
      borderWidth: 1,
      layout: {
        padding: 20,
      },
      legendBottomMargin: null,
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            pinch: {
              enabled: true, // Enable pinch zooming
            },
            wheel: {
              enabled: true, // Enable wheel zooming
            },
            mode: "x",
          },
        },
        title: {
          display: true,
          text: "Bar Chart Title",
          color: "rgba(0, 0, 0, 0.54)",
          align: "center",
          font: {
            size: 14,
            weight: 400,
            style: "normal",
            color: "rgba(0, 0, 0, 0.54)",
          },
        },
        subtitle: {
          display: true,
          text: "Bar Chart Subtitle",
          color: "rgba(0, 0, 0, 0.54)",
          align: "center",
          font: {
            size: 12,
            weight: "normal",
            color: "rgba(0, 0, 0, 0.54)",
            style: "normal",
          },
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: "auto",
          color: "rgba(0, 0, 0, 0.54)",
          anchor: "end",
          align: "top",
          rotation: 0,
          formatter: function(value) {
            return value;
          },
          transformExpression: "",
          font: {
            size: 12,
            weight: 400,
            color: "rgba(0, 0, 0, 0.54)",
            style: "normal",
          },
        },
        legend: {
          display: true,
          position: "top",
          align: "center",
          labels: {
            color: "rgba(0, 0, 0, 0.54)",
            font: {
              size: 12,
              weight: 600,
              style: "normal",
            },
          },
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Month",
            color: "rgba(0, 0, 0, 0.54)",
            font: {
              size: 14,
              weight: 400,
              color: "rgba(0, 0, 0, 0.54)",
              style: "normal",
            },
          },
          ticks: {
            callback: function format(value) {
              return value;
            },
            padding: 1,
            font: {
              size: 12,
              weight: 400,
              color: "rgba(0, 0, 0, 0.54)",
              style: "normal",
            },
          },
          grid: {
            display: true,
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Sales",
            font: {
              size: 14,
              weight: 400,
              color: "rgba(0, 0, 0, 0.54)",
              style: "normal",
            },
          },
          ticks: {
            callback: function format(value) {
              return value;
            },
            transformExpression: "",
            font: {
              size: 12,
              weight: 400,
              color: "rgba(0, 0, 0, 0.54)",
              style: "normal",
            },
            stepSize: null,
            padding: 1,
          },
          grid: {
            display: true,
          },
          min: 0,
          max: null,
        },
      },
    },
  },
  {
    type: "PIE",
    config: {
      locale: "en-US",
      maintainAspectRatio: false,
      hoverOffset: 4, //onhover displacement
      //common config as bar chart
      responsive: true,
      layout: {
        padding: {
          left: 20,
          top: 20,
          right: 20,
          bottom: 20,
        }
      },
      legendBottomMargin: null,
      outerLabel: false,
      plugins: {
        title: {
          display: true,
          text: "Pie Chart Title",
          color: "rgba(0, 0, 0, 0.54)",
          align: "center",
          font: {
            size: 14,
            weight: 400,
            color: "rgba(0, 0, 0, 0.54)",
            style: "normal",
          },
        },
        subtitle: {
          display: true,
          text: "Pie Chart Subtitle",
          color: "rgba(0, 0, 0, 0.54)",
          align: "center",
          font: {
            size: 12,
            weight: "normal",
            color: "rgba(0, 0, 0, 0.54)",
            style: "normal",
          },
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: false,
          color: "rgba(0, 0, 0, 0.54)",
          anchor: "end",
          align: "top",
          rotation: 0,
          formatter: function(value) {
            return value;
          },
          transformExpression: "",
          font: {
            size: 12,
            weight: 400,
            color: "rgba(0, 0, 0, 0.54)",
            style: "normal",
          },
        },
        legend: {
          display: true,
          position: "top",
          align: "center",
          labels: {
            color: "rgba(0, 0, 0, 0.54)",
            font: {
              size: 12,
              weight: 600,
              style: "normal",
            },
          },
        },
      },
    },
  },
  {
    type: "DOUGHNUT",
    config: {
      locale: "en-US",
      maintainAspectRatio: false,
      hoverOffset: 4, //onhover displacement
      //common config as bar chart
      responsive: true,
      layout: {
        padding: {
          left: 20,
          top: 20,
          right: 20,
          bottom: 20,
        }
      },
      legendBottomMargin: null,
      outerLabel: false,
      plugins: {
        title: {
          display: true,
          text: "Pie Chart Title",
          color: "rgba(0, 0, 0, 0.54)",
          align: "center",
          font: {
            size: 14,
            weight: 400,
            color: "rgba(0, 0, 0, 0.54)",
            style: "normal",
          },
        },
        subtitle: {
          display: true,
          text: "Pie Chart Subtitle",
          color: "rgba(0, 0, 0, 0.54)",
          align: "center",
          font: {
            size: 12,
            weight: "normal",
            color: "rgba(0, 0, 0, 0.54)",
            style: "normal",
          },
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: false,
          color: "rgba(0, 0, 0, 0.54)",
          anchor: "end",
          align: "top",
          rotation: 0,
          formatter: function(value) {
            return value;
          },
          transformExpression: "",
          font: {
            size: 12,
            weight: 400,
            color: "rgba(0, 0, 0, 0.54)",
            style: "normal",
          },
        },
        legend: {
          display: true,
          position: "top",
          align: "center",
          labels: {
            color: "rgba(0, 0, 0, 0.54)",
            font: {
              size: 12,
              weight: 600,
              style: "normal",
            },
          },
        },
      },
    },
  },
  {
    type: "TABLE",
    config: {
      headerBackgroundColor: "rgb(250, 250, 250)",
      headerColor: "rgb(19, 18, 19)",
      responsive: true,
      pagination: false,
      columnBorder: true,
      dense: false,
    },
  },
];

export const barChartFormArr = [
  {
    name: "General",
    sections: [
      {
        name: "Chart Configuration",
        items: [
          {
            name: "indexAxis",
            type: "select",
            label: "Direction",
            statePath: ["config", "indexAxis"],
            options: ["x", "y"],
          },
          {
            name: "padding",
            type: "number",
            label: "Padding",
            placeholder: "Enter Padding",
            statePath: ["config", "layout", "padding"],
          },
          {
            name: "borderRadius",
            type: "number",
            label: "Border Radius",
            placeholder: "Enter Border Radius",
            statePath: ["config", "borderRadius"],
          },
          {
            name: "borderWidth",
            type: "number",
            label: "Border Width",
            placeholder: "Enter Border Width",
            statePath: ["config", "borderWidth"],
          },
          {
            name: "barThickness",
            type: "number",
            label: "Bar Thickness",
            placeholder: "Enter Bar Thickness",
            statePath: ["config", "barThickness"],
          },
          {
            name: "maxBarThickness",
            type: "number",
            label: "Maximum Bar Thickness",
            placeholder: "Enter Maximum Bar Thickness",
            statePath: ["config", "maxBarThickness"],
          },
          {
            name: "borderColor",
            type: "color",
            label: "Border Color",
            statePath: ["config", "borderColor"],
          },
          {
            name: "backgroundColor",
            type: "color",
            label: "Background Color",
            statePath: ["config", "backgroundColor"],
          },
        ],
      },
      {
        name: "Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "title", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "plugins", "title", "text"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "title", "color"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "title", "align"],
            isVisible: ["plugins", "title", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "title", "font", "size"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "title", "font", "weight"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "plugins", "title", "font", "style"],
            isVisible: ["plugins", "title", "display"],
          },
        ],
      },
      {
        name: "Sub Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "subtitle", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "plugins", "subtitle", "text"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "subtitle", "color"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "subtitle", "align"],
            isVisible: ["plugins", "subtitle", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "subtitle", "font", "size"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "subtitle", "font", "weight"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "plugins", "subtitle", "font", "style"],
            isVisible: ["plugins", "subtitle", "display"],
          },
        ],
      },
      {
        name: "Datalabels",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "datalabels", "display"],
          },
          {
            name: "rotation",
            type: "number",
            label: "Rotate",
            placeholder: "Enter degree of rotation",
            statePath: ["config", "plugins", "datalabels", "rotation"],
          },
          {
            name: "transformExpression",
            type: "function",
            label: "Format",
            placeholder: "",
            statePath: ["config", "plugins", "datalabels", "transformExpression"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "datalabels", "color"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "datalabels", "align"],
            isVisible: ["plugins", "datalabels", "display"],
            options: ["center", "start", "end", "right", "bottom", "left", "top"],
          },
          {
            name: "anchor",
            type: "select",
            label: "Anchor",
            statePath: ["config", "plugins", "datalabels", "anchor"],
            isVisible: ["plugins", "datalabels", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "datalabels", "font", "size"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            placeholder: "Font Weight",
            statePath: ["config", "plugins", "datalabels", "font", "weight"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            placeholder: "Choose Style",
            statePath: ["config", "plugins", "datalabels", "font", "style"],
            isVisible: ["plugins", "datalabels", "display"],
          },
        ],
      },
      {
        name: "Legend",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "legend", "display"],
          },
          {
            name: "legendBottomMargin",
            type: "number",
            label: "Margin bottom",
            placeholder: "Enter Margin bottom",
            statePath: ["config", "legendBottomMargin"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "legend", "labels", "color"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "legend", "align"],
            isVisible: ["plugins", "legend", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "position",
            type: "select",
            label: "Position",
            statePath: ["config", "plugins", "legend", "position"],
            isVisible: ["plugins", "legend", "display"],
            options: ["right", "bottom", "left", "top"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "legend", "labels", "font", "size"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "legend", "labels", "font", "weight"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            placeholder: "Choose Style",
            statePath: ["config", "plugins", "legend", "labels", "font", "style"],
            isVisible: ["plugins", "legend", "display"],
          },
        ],
      },
      {
        name: "tooltip",
        items: [
          {
            name: "enabled",
            type: "switch",
            label: "Display Tooltip",
            statePath: ["config", "plugins", "tooltip", "enabled"],
          },
        ],
      },
    ],
  },
  {
    name: "Filters",
    isFilter: true,
  },
  {
    name: "Axes",
    sections: [
      {
        name: "General",
        items: [
          {
            name: "min",
            type: "number",
            label: "Minimum X-Axes Scale",
            placeholder: "Enter X-Axes Minimum Scale",
            statePath: ["config", "scales", "x", "min"],
          },
          {
            name: "max",
            type: "number",
            label: "Maximum X-Axes Scale",
            placeholder: "Enter X-Axes Maximum Scale",
            statePath: ["config", "scales", "x", "max"],
          },
          {
            name: "min",
            type: "number",
            label: "Minimum Y-Axes Scale",
            placeholder: "Enter Minimum Scale",
            statePath: ["config", "scales", "y", "min"],
          },
          {
            name: "max",
            type: "number",
            label: "Maximum Y-Axes Scale",
            placeholder: "Enter Y-Axes Maximum Scale",
            statePath: ["config", "scales", "y", "max"],
          },
        ],
      },
      {
        name: "xAxes Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "scales", "x", "title", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "scales", "x", "title", "text"],
            isVisible: ["scales", "x", "title", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "scales", "x", "title", "color"],
            isVisible: ["scales", "x", "title", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "scales", "x", "title", "align"],
            isVisible: ["scales", "x", "title", "display"],
            options: ["center","start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "scales", "x", "title", "font", "size"],
            isVisible: ["scales", "x", "title", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "scales", "x", "title", "font", "weight"],
            isVisible: ["scales", "x", "title", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "scales", "x", "title", "font", "style"],
            isVisible: ["scales", "x", "title", "display"],
          },
        ],
      },
      {
        name: "xAxes Ticks",
        items: [
          {
            name: "stepSize",
            type: "number",
            label: "Step Size",
            placeholder: "Enter Step Size",
            statePath: ["config", "scales", "x", "ticks", "stepSize"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "scales", "x", "ticks", "font", "size"],
            isVisible: ["scales", "x", "ticks", "display"],
          },
          {
            name: "transformExpression",
            type: "function",
            label: "Format",
            placeholder: "",
            statePath: ["config", "scales", "x", "ticks", "transformExpression"],
            isVisible: ["scales", "x", "ticks", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "scales", "x", "ticks", "font", "weight"],
            isVisible: ["scales", "x", "ticks", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "scales", "x", "ticks", "font", "style"],
            isVisible: ["scales", "x", "ticks", "display"],
          },
        ],
      },
      {
        name: "xAxes Grid",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Grid",
            statePath: ["config", "scales", "x", "grid", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "scales", "x", "grid", "color"],
            isVisible: ["scales", "x", "grid", "display"],
          },
          {
            name: "borderColor",
            type: "color",
            label: "Border Color",
            statePath: ["config", "scales", "x", "grid", "borderColor"],
            isVisible: ["scales", "x", "grid", "display"],
          },
          {
            name: "borderWidth",
            type: "number",
            label: "Border Width",
            placeholder: "Enter Border Width",
            statePath: ["config", "scales", "x", "grid", "borderWidth"],
            isVisible: ["scales", "x", "grid", "display"],
          },
        ],
      },
      {
        name: "yAxes Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "scales", "y", "title", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "scales", "y", "title", "text"],
            isVisible: ["scales", "y", "title", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "scales", "y", "title", "color"],
            isVisible: ["scales", "y", "title", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "scales", "y", "title", "align"],
            isVisible: ["scales", "y", "title", "display"],
            options: ["center","start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "scales", "y", "title", "font", "size"],
            isVisible: ["scales", "y", "title", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "scales", "y", "title", "font", "weight"],
            isVisible: ["scales", "y", "title", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "scales", "y", "title", "font", "style"],
            isVisible: ["scales", "y", "title", "display"],
          },
        ],
      },
      {
        name: "yAxes Ticks",
        items: [
          {
            name: "stepSize",
            type: "number",
            label: "Step Size",
            placeholder: "Enter Step Size",
            statePath: ["config", "scales", "y", "ticks", "stepSize"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "scales", "y", "ticks", "font", "size"],
            isVisible: ["scales", "y", "ticks", "display"],
          },
          {
            name: "transformExpression",
            type: "function",
            label: "Format",
            placeholder: "",
            statePath: ["config", "scales", "y", "ticks", "transformExpression"],
            isVisible: ["scales", "y", "ticks", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "scales", "y", "ticks", "font", "weight"],
            isVisible: ["scales", "y", "ticks", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "scales", "y", "ticks", "font", "style"],
            isVisible: ["scales", "y", "ticks", "display"],
          },
        ],
      },
      {
        name: "yAxes Grid",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Grid",
            statePath: ["config", "scales", "y", "grid", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "scales", "y", "grid", "color"],
            isVisible: ["scales", "y", "grid", "display"],
          },
          {
            name: "borderColor",
            type: "color",
            label: "Border Color",
            statePath: ["config", "scales", "y", "grid", "borderColor"],
            isVisible: ["scales", "y", "grid", "display"],
          },
          {
            name: "borderWidth",
            type: "number",
            label: "Border Width",
            placeholder: "Enter Border Width",
            statePath: ["config", "scales", "y", "grid", "borderWidth"],
            isVisible: ["scales", "y", "grid", "display"],
          },
        ],
      },
    ],
  },
];

export const lineChartFormArr = [
  {
    name: "General",
    sections: [
      {
        name: "Chart Configuration",
        items: [
          {
            name: "fill",
            type: "switch",
            label: "Fill Line Area",
            statePath: ["config", "fill"],
          },
          {
            name: "padding",
            type: "number",
            label: "Padding",
            placeholder: "Enter Padding",
            statePath: ["config", "layout", "padding"],
          },
          {
            name: "borderWidth",
            type: "number",
            label: "Border Width",
            placeholder: "Enter Border Width",
            statePath: ["config", "borderWidth"],
          },
          {
            name: "borderColor",
            type: "color",
            label: "Border Color",
            statePath: ["config", "borderColor"],
          },
          {
            name: "backgroundColor",
            type: "color",
            label: "Background Color",
            statePath: ["config", "backgroundColor"],
          },
        ],
      },
      {
        name: "Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "title", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "plugins", "title", "text"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "title", "color"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "title", "align"],
            isVisible: ["plugins", "title", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "title", "font", "size"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "title", "font", "weight"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "plugins", "title", "font", "style"],
            isVisible: ["plugins", "title", "display"],
          },
        ],
      },
      {
        name: "Sub Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "subtitle", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "plugins", "subtitle", "text"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "subtitle", "color"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "subtitle", "align"],
            isVisible: ["plugins", "subtitle", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "subtitle", "font", "size"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "subtitle", "font", "weight"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "plugins", "subtitle", "font", "style"],
            isVisible: ["plugins", "subtitle", "display"],
          },
        ],
      },
      {
        name: "Datalabels",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "datalabels", "display"],
          },
          {
            name: "rotation",
            type: "number",
            label: "Rotate",
            placeholder: "Enter degree of rotation",
            statePath: ["config", "plugins", "datalabels", "rotation"],
          },
          {
            name: "transformExpression",
            type: "function",
            label: "Format",
            placeholder: "",
            statePath: ["config", "plugins", "datalabels", "transformExpression"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "datalabels", "color"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "datalabels", "align"],
            isVisible: ["plugins", "datalabels", "display"],
            options: ["center", "start", "end", "right", "bottom", "left", "top"],
          },
          {
            name: "anchor",
            type: "select",
            label: "Anchor",
            statePath: ["config", "plugins", "datalabels", "anchor"],
            isVisible: ["plugins", "datalabels", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "datalabels", "font", "size"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            placeholder: "Font Weight",
            statePath: ["config", "plugins", "datalabels", "font", "weight"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            placeholder: "Choose Style",
            statePath: ["config", "plugins", "datalabels", "font", "style"],
            isVisible: ["plugins", "datalabels", "display"],
          },
        ],
      },
      {
        name: "Legend",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "legend", "display"],
          },
          {
            name: "legendBottomMargin",
            type: "number",
            label: "Margin bottom",
            placeholder: "Enter Margin bottom",
            statePath: ["config", "legendBottomMargin"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "legend", "labels", "color"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "legend", "align"],
            isVisible: ["plugins", "legend", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "position",
            type: "select",
            label: "Position",
            statePath: ["config", "plugins", "legend", "position"],
            isVisible: ["plugins", "legend", "display"],
            options: ["right", "bottom", "left", "top"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "legend", "labels", "font", "size"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "legend", "labels", "font", "weight"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            placeholder: "Choose Style",
            statePath: ["config", "plugins", "legend", "labels", "font", "style"],
            isVisible: ["plugins", "legend", "display"],
          },
        ],
      },
      {
        name: "tooltip",
        items: [
          {
            name: "enabled",
            type: "switch",
            label: "Display Tooltip",
            statePath: ["config", "plugins", "tooltip", "enabled"],
          },
        ],
      },
    ],
  },
  {
    name: "Filters",
    isFilter: true,
  },
  {
    name: "Axes",
    sections: [
      {
        name: "General",
        items: [
          {
            name: "min",
            type: "number",
            label: "Minimum X-Axes Scale",
            placeholder: "Enter X-Axes Minimum Scale",
            statePath: ["config", "scales", "x", "min"],
          },
          {
            name: "max",
            type: "number",
            label: "Maximum X-Axes Scale",
            placeholder: "Enter X-Axes Maximum Scale",
            statePath: ["config", "scales", "x", "max"],
          },
          {
            name: "min",
            type: "number",
            label: "Minimum Y-Axes Scale",
            placeholder: "Enter Minimum Scale",
            statePath: ["config", "scales", "y", "min"],
          },
          {
            name: "max",
            type: "number",
            label: "Maximum Y-Axes Scale",
            placeholder: "Enter Y-Axes Maximum Scale",
            statePath: ["config", "scales", "y", "max"],
          },
        ],
      },
      {
        name: "xAxes Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "scales", "x", "title", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "scales", "x", "title", "text"],
            isVisible: ["scales", "x", "title", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "scales", "x", "title", "color"],
            isVisible: ["scales", "x", "title", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "scales", "x", "title", "align"],
            isVisible: ["scales", "x", "title", "display"],
            options: ["center","start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "scales", "x", "title", "font", "size"],
            isVisible: ["scales", "x", "title", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "scales", "x", "title", "font", "weight"],
            isVisible: ["scales", "x", "title", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "scales", "x", "title", "font", "style"],
            isVisible: ["scales", "x", "title", "display"],
          },
        ],
      },
      {
        name: "xAxes Ticks",
        items: [
          {
            name: "stepSize",
            type: "number",
            label: "Step Size",
            placeholder: "Enter Step Size",
            statePath: ["config", "scales", "x", "ticks", "stepSize"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "scales", "x", "ticks", "font", "size"],
            isVisible: ["scales", "x", "ticks", "display"],
          },
          {
            name: "transformExpression",
            type: "function",
            label: "Format",
            placeholder: "",
            statePath: ["config", "scales", "x", "ticks", "transformExpression"],
            isVisible: ["scales", "x", "ticks", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "scales", "x", "ticks", "font", "weight"],
            isVisible: ["scales", "x", "ticks", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "scales", "x", "ticks", "font", "style"],
            isVisible: ["scales", "x", "ticks", "display"],
          },
        ],
      },
      {
        name: "xAxes Grid",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Grid",
            statePath: ["config", "scales", "x", "grid", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "scales", "x", "grid", "color"],
            isVisible: ["scales", "x", "grid", "display"],
          },
          {
            name: "borderColor",
            type: "color",
            label: "Border Color",
            statePath: ["config", "scales", "x", "grid", "borderColor"],
            isVisible: ["scales", "x", "grid", "display"],
          },
          {
            name: "borderWidth",
            type: "number",
            label: "Border Width",
            placeholder: "Enter Border Width",
            statePath: ["config", "scales", "x", "grid", "borderWidth"],
            isVisible: ["scales", "x", "grid", "display"],
          },
        ],
      },
      {
        name: "yAxes Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "scales", "y", "title", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "scales", "y", "title", "text"],
            isVisible: ["scales", "y", "title", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "scales", "y", "title", "color"],
            isVisible: ["scales", "y", "title", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "scales", "y", "title", "align"],
            isVisible: ["scales", "y", "title", "display"],
            options: ["center","start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "scales", "y", "title", "font", "size"],
            isVisible: ["scales", "y", "title", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "scales", "y", "title", "font", "weight"],
            isVisible: ["scales", "y", "title", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "scales", "y", "title", "font", "style"],
            isVisible: ["scales", "y", "title", "display"],
          },
        ],
      },
      {
        name: "yAxes Ticks",
        items: [
          {
            name: "stepSize",
            type: "number",
            label: "Step Size",
            placeholder: "Enter Step Size",
            statePath: ["config", "scales", "y", "ticks", "stepSize"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "scales", "y", "ticks", "font", "size"],
            isVisible: ["scales", "y", "ticks", "display"],
          },
          {
            name: "callback",
            type: "function",
            label: "Format",
            placeholder: "",
            statePath: ["config", "scales", "x", "ticks", "callback"],
            isVisible: ["scales", "x", "ticks", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "scales", "y", "ticks", "font", "weight"],
            isVisible: ["scales", "y", "ticks", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "scales", "y", "ticks", "font", "style"],
            isVisible: ["scales", "y", "ticks", "display"],
          },
        ],
      },
      {
        name: "yAxes Grid",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Grid",
            statePath: ["config", "scales", "y", "grid", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "scales", "y", "grid", "color"],
            isVisible: ["scales", "y", "grid", "display"],
          },
          {
            name: "borderColor",
            type: "color",
            label: "Border Color",
            statePath: ["config", "scales", "y", "grid", "borderColor"],
            isVisible: ["scales", "y", "grid", "display"],
          },
          {
            name: "borderWidth",
            type: "number",
            label: "Border Width",
            placeholder: "Enter Border Width",
            statePath: ["config", "scales", "y", "grid", "borderWidth"],
            isVisible: ["scales", "y", "grid", "display"],
          },
        ],
      },
    ],
  },
];

export const pieChartFormArr = [
  {
    name: "General",
    sections: [
      {
        name: "Chart Configuration",
        items: [
          {
            name: "hoverOffset",
            type: "number",
            label: "On Hover Desplacement",
            placeholder: "Enter On Hover Desplacement",
            statePath: ["config", "hoverOffset"],
          },
          {
            name: "padding",
            type: "number",
            label: "Padding Top",
            placeholder: "Enter Padding",
            statePath: ["config", "layout", "padding", "top"],
          },
          {
            name: "padding",
            type: "number",
            label: "Padding Right",
            placeholder: "Enter Padding",
            statePath: ["config", "layout", "padding", "right"],
          },
          {
            name: "padding",
            type: "number",
            label: "Padding Bottom",
            placeholder: "Enter Padding",
            statePath: ["config", "layout", "padding", "bottom"],
          },
          {
            name: "padding",
            type: "number",
            label: "Padding Left",
            placeholder: "Enter Padding",
            statePath: ["config", "layout", "padding", "left"],
          },
        ],
      },
      {
        name: "Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "title", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "plugins", "title", "text"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "title", "color"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "title", "align"],
            isVisible: ["plugins", "title", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "title", "font", "size"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "title", "font", "weight"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "plugins", "title", "font", "style"],
            isVisible: ["plugins", "title", "display"],
          },
        ],
      },
      {
        name: "Sub Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "subtitle", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "plugins", "subtitle", "text"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "subtitle", "color"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "subtitle", "align"],
            isVisible: ["plugins", "subtitle", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "subtitle", "font", "size"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "subtitle", "font", "weight"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "plugins", "subtitle", "font", "style"],
            isVisible: ["plugins", "subtitle", "display"],
          },
        ],
      },
      {
        name: "Datalabels",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "datalabels", "display"],
          },
          {
            name: "outerLabel",
            type: "switch",
            label: "Show Labels outside",
            statePath: ["config", "outerLabel"],
          },
          {
            name: "rotation",
            type: "number",
            label: "Rotate",
            placeholder: "Enter degree of rotation",
            statePath: ["config", "plugins", "datalabels", "rotation"],
          },
          {
            name: "transformExpression",
            type: "function",
            label: "Format",
            placeholder: "",
            statePath: ["config", "plugins", "datalabels", "transformExpression"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "datalabels", "color"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "datalabels", "align"],
            isVisible: ["plugins", "datalabels", "display"],
            options: ["center", "start", "end", "right", "bottom", "left", "top"],
          },
          {
            name: "anchor",
            type: "select",
            label: "Anchor",
            statePath: ["config", "plugins", "datalabels", "anchor"],
            isVisible: ["plugins", "datalabels", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "datalabels", "font", "size"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            placeholder: "Font Weight",
            statePath: ["config", "plugins", "datalabels", "font", "weight"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            placeholder: "Choose Style",
            statePath: ["config", "plugins", "datalabels", "font", "style"],
            isVisible: ["plugins", "datalabels", "display"],
          },
        ],
      },
      {
        name: "Legend",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "legend", "display"],
          },
          {
            name: "legendBottomMargin",
            type: "number",
            label: "Margin bottom",
            placeholder: "Enter Margin bottom",
            statePath: ["config", "legendBottomMargin"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "legend", "labels", "color"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "legend", "align"],
            isVisible: ["plugins", "legend", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "position",
            type: "select",
            label: "Position",
            statePath: ["config", "plugins", "legend", "position"],
            isVisible: ["plugins", "legend", "display"],
            options: ["right", "bottom", "left", "top"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "legend", "labels", "font", "size"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "legend", "labels", "font", "weight"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            placeholder: "Choose Style",
            statePath: ["config", "plugins", "legend", "labels", "font", "style"],
            isVisible: ["plugins", "legend", "display"],
          },
        ],
      },
      {
        name: "tooltip",
        items: [
          {
            name: "enabled",
            type: "switch",
            label: "Display Tooltip",
            statePath: ["config", "plugins", "tooltip", "enabled"],
          },
        ],
      },
    ],
  },
  {
    name: "Filters",
    isFilter: true,
  },
];

export const doughnutChartFormArr = [
  {
    name: "General",
    sections: [
      {
        name: "Chart Configuration",
        items: [
          {
            name: "hoverOffset",
            type: "number",
            label: "On Hover Desplacement",
            placeholder: "Enter On Hover Desplacement",
            statePath: ["config", "hoverOffset"],
          },
          {
            name: "padding",
            type: "number",
            label: "Padding Top",
            placeholder: "Enter Padding",
            statePath: ["config", "layout", "padding", "top"],
          },
          {
            name: "padding",
            type: "number",
            label: "Padding Right",
            placeholder: "Enter Padding",
            statePath: ["config", "layout", "padding", "right"],
          },
          {
            name: "padding",
            type: "number",
            label: "Padding Bottom",
            placeholder: "Enter Padding",
            statePath: ["config", "layout", "padding", "bottom"],
          },
          {
            name: "padding",
            type: "number",
            label: "Padding Left",
            placeholder: "Enter Padding",
            statePath: ["config", "layout", "padding", "left"],
          },
        ],
      },
      {
        name: "Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "title", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "plugins", "title", "text"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "title", "color"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "title", "align"],
            isVisible: ["plugins", "title", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "title", "font", "size"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "title", "font", "weight"],
            isVisible: ["plugins", "title", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "plugins", "title", "font", "style"],
            isVisible: ["plugins", "title", "display"],
          },
        ],
      },
      {
        name: "Sub Title",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "subtitle", "display"],
          },
          {
            name: "text",
            type: "text",
            label: "Text",
            placeholder: "Enter Text",
            statePath: ["config", "plugins", "subtitle", "text"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "subtitle", "color"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "subtitle", "align"],
            isVisible: ["plugins", "subtitle", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "subtitle", "font", "size"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "subtitle", "font", "weight"],
            isVisible: ["plugins", "subtitle", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            statePath: ["config", "plugins", "subtitle", "font", "style"],
            isVisible: ["plugins", "subtitle", "display"],
          },
        ],
      },
      {
        name: "Datalabels",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "datalabels", "display"],
          },
          {
            name: "outerLabel",
            type: "switch",
            label: "Show Labels outside",
            statePath: ["config", "outerLabel"],
          },
          {
            name: "rotation",
            type: "number",
            label: "Rotate",
            placeholder: "Enter degree of rotation",
            statePath: ["config", "plugins", "datalabels", "rotation"],
          },
          {
            name: "transformExpression",
            type: "function",
            label: "Format",
            placeholder: "",
            statePath: ["config", "plugins", "datalabels", "transformExpression"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "datalabels", "color"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "datalabels", "align"],
            isVisible: ["plugins", "datalabels", "display"],
            options: ["center", "start", "end", "right", "bottom", "left", "top"],
          },
          {
            name: "anchor",
            type: "select",
            label: "Anchor",
            statePath: ["config", "plugins", "datalabels", "anchor"],
            isVisible: ["plugins", "datalabels", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "datalabels", "font", "size"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            placeholder: "Font Weight",
            statePath: ["config", "plugins", "datalabels", "font", "weight"],
            isVisible: ["plugins", "datalabels", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            placeholder: "Choose Style",
            statePath: ["config", "plugins", "datalabels", "font", "style"],
            isVisible: ["plugins", "datalabels", "display"],
          },
        ],
      },
      {
        name: "Legend",
        items: [
          {
            name: "display",
            type: "switch",
            label: "Display Title",
            statePath: ["config", "plugins", "legend", "display"],
          },
          {
            name: "legendBottomMargin",
            type: "number",
            label: "Margin bottom",
            placeholder: "Enter Margin bottom",
            statePath: ["config", "legendBottomMargin"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "color",
            type: "color",
            label: "Choose Color",
            statePath: ["config", "plugins", "legend", "labels", "color"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "align",
            type: "select",
            label: "Alignment",
            statePath: ["config", "plugins", "legend", "align"],
            isVisible: ["plugins", "legend", "display"],
            options: ["center", "start", "end"],
          },
          {
            name: "position",
            type: "select",
            label: "Position",
            statePath: ["config", "plugins", "legend", "position"],
            isVisible: ["plugins", "legend", "display"],
            options: ["right", "bottom", "left", "top"],
          },
          {
            name: "size",
            type: "number",
            label: "Font Size",
            placeholder: "Enter Font Size",
            statePath: ["config", "plugins", "legend", "labels", "font", "size"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "weight",
            type: "select",
            options: [400, 600, 800],
            label: "Font Weight",
            statePath: ["config", "plugins", "legend", "labels", "font", "weight"],
            isVisible: ["plugins", "legend", "display"],
          },
          {
            name: "style",
            type: "select",
            options: ["normal", "italic"],
            label: "Choose Style",
            placeholder: "Choose Style",
            statePath: ["config", "plugins", "legend", "labels", "font", "style"],
            isVisible: ["plugins", "legend", "display"],
          },
        ],
      },
      {
        name: "tooltip",
        items: [
          {
            name: "enabled",
            type: "switch",
            label: "Display Tooltip",
            statePath: ["config", "plugins", "tooltip", "enabled"],
          },
        ],
      },
    ],
  },
  {
    name: "Filters",
    isFilter: true,
  },
];

export const dataTableFormArr = [
  {
    name: "General",
    sections: [
      {
        name: "Table Configuration",
        items: [
          {
            name: "headerBackgroundColor",
            type: "color",
            label: "Header background",
            placeholder: "Header background",
            statePath: ["config", "headerBackgroundColor"],
          },
          {
            name: "headerColor",
            type: "color",
            label: "Header color",
            placeholder: "Header Color",
            statePath: ["config", "headerColor"],
          },
          {
            name: "pagination",
            type: "switch",
            label: "Pagination",
            statePath: ["config", "pagination"],
          },
          {
            name: "columnBorder",
            type: "switch",
            label: "Column Border",
            statePath: ["config", "columnBorder"],
          },
          {
            name: "responsive",
            type: "switch",
            label: "Responsive",
            statePath: ["config", "responsive"],
          },
          {
            name: "dense",
            type: "switch",
            label: "Dense",
            statePath: ["config", "dense"],
          },
        ],
      },
    ]
  },
  {
    name: "Filters",
    isFilter: true,
  },
];

export const CHART_COLORS = ["#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0", "#36a2eb", "#9966ff", "#c9cbcf", "#ee4035", "#f37736", "#fdf498", "#7bc043", "#0392cf"];

export const setNestedState = (obj = {}, [first, ...rest], value) => ({
  ...obj,
  [first]: rest.length ? setNestedState(obj[first], rest, value) : value,
});

export const getNestedState = (obj, path) => {
  if (path.length === 0) return obj;
  // eslint-disable-next-line eqeqeq
  if (path[0] != null && path[0] != undefined && obj[path[0]] != null && obj[path[0]] != undefined) {
    return getNestedState(obj[path[0]], path.slice(1));
  } else {
    return obj;
  }
};

export const generateColor = (count) => {
  let colorsArr = [];
  let multiples = Math.floor(count / CHART_COLORS.length);
  for (let i = 0; i < multiples; i++) {
    colorsArr.push(...CHART_COLORS);
  }
  let remainingColorCount = count % CHART_COLORS.length;
  colorsArr.push(...CHART_COLORS.slice(0, remainingColorCount));
  return colorsArr;
};
