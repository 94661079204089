import React, { Component } from 'react'
import { connect } from "react-redux";
import { compose } from "recompose";
import { generate } from 'shortid';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withStyles, createStyles } from "@material-ui/core/styles"
import {
  Grid, CircularProgress
} from "@material-ui/core";
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import CommonCss from 'commonCss';
// import Image from "Components/Common/image.jsx";
import SqureButton from 'Components/Common/SqureButton';
import AppBarV2 from "Components/Application/AppBarV2";
import SearchTeamsPoper from "./dialogs/SearchTeamsPoper";
import classnames from "classnames";

import {
  fetchDocTypesTabs,addDocTypeSection, deleteDocTypeSection,
  updateDocTypeSection, addDocTypeSectionItem,
  updateDocTypeSectionItem, updateDocTypeSequence,
  updateDocTypeSectionSequence, deleteDocTypeSectionItem,
} from "redux/documentTypesV2/action";

import { updateDrawerStyle } from "redux/drawer/action";
import FormBuilder from './FormBuilder.jsx'
import { showSnackBar } from "redux/snackbar/action";
import { fetchVisualization } from "redux/visualization/action";
import { getOrgWorkflow } from "redux/evaluation/workflow/action";

const connectedProps = (state) => ({
  docTypeTabs: state.documentTypeV2.docTypeTabs,
  isLoading: state.documentTypeV2.docTypeTabsProgress,
  addDocTypeSectionProgress: state.documentTypeV2.addDocTypeSectionProgress,
  addDocTypeSectionSuccess: state.documentTypeV2.addDocTypeSectionSuccess,
  addDocTypeSectionError: state.documentTypeV2.addDocTypeSectionError,
  deleteDocTypeSectionProgress: state.documentTypeV2.deleteDocTypeSectionProgress,
  updateDocTypeSectionError: state.documentTypeV2.updateDocTypeSectionError,
  updateDocTypeSectionProgress: state.documentTypeV2.updateDocTypeSectionProgress,
  addDocTypeSectionItemProgress: state.documentTypeV2.addDocTypeSectionItemProgress,
  addDocTypeSectionItemError: state.documentTypeV2.addDocTypeSectionItemError,
  updateDocTypeSectionItemProgress: state.documentTypeV2.updateDocTypeSectionItemProgress,
  updateDocTypeSectionItemError: state.documentTypeV2.updateDocTypeSectionItemError,
  deleteDocTypeSectionItemProgress: state.documentTypeV2.deleteDocTypeSectionItemProgress,
  deleteDocTypeSectionItemError: state.documentTypeV2.deleteDocTypeSectionItemError,

  visualizations: state.visualization.visualizations,
  visualizationFetchProgress: state.visualization.visualizationFetchProgress,
  visualizationFetchError: state.visualization.visualizationFetchError,

  orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
});

const connectionActions = {
  fetchDocTypesTabs: fetchDocTypesTabs,
  addDocTypeSection: addDocTypeSection,
  deleteDocTypeSection: deleteDocTypeSection,
  updateDocTypeSection: updateDocTypeSection,
  addDocTypeSectionItem: addDocTypeSectionItem,
  updateDocTypeSectionItem: updateDocTypeSectionItem,
  updateDocTypeSequence: updateDocTypeSequence,
  updateDocTypeSectionSequence: updateDocTypeSectionSequence,
  deleteDocTypeSectionItem: deleteDocTypeSectionItem,
  updateDrawerStyle: updateDrawerStyle,
  showSnackBar: showSnackBar,
  fetchVisualization: fetchVisualization,
  getOrgWorkflow: getOrgWorkflow,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    backgroundColor: '#FFF',
    minHeight: '100vh',
  },
  formControl: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  mb16: {
    marginBottom: 16,
  },
  formGroup: {
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  ...CommonCss.ProductDetails,
  leftSection: {
    width: 200,
    minWidth: 200,
  },
  rightSection: {
    flex: 1,
    paddingLeft: 40
  },
  tabs: {
    "& [class*='MuiTab-root']": {
      padding: '0 6px',
      maxWidth: 'max-content',
      width: 'max-content'
    },
    "border-bottom": '1px solid #d8d8d8',
  },
  container: {
    marginTop: 81
  },
  "popoverText": {
    color: "#74798C"
  },
  listItem: {
    paddingTop: theme.spacing(2),
  },
  listIcon: {
    minWidth: theme.spacing(5),
  },
  diabled: {
    opacity: 0.4
  },
  editIcon: {
    color: "#4A87F8",
    verticalAlign: "middle",
    cursor: 'pointer'
  },
  deleteImage: {
    cursor: "pointer",
  },
  rootContainer: CommonCss.rootContainer
})

class DocumentTypesCatalog extends Component {
  constructor(props) {
    super()
    this.state = {
      tabId: null,
      activeSection: null,
      activeNewSection: null,
      typeOfVisibility: null,
      newTabSection: null,
      dragFromSeq: null,
      isDrapSection: false,
      isUpdateHappen: false,
      isUpdateItemHappen: false,
      isAddItemHappen: false,
      addTeamsDialogOpen: false,
      selectedTeams: [],
      addTeamsDialogType: null,
      addTeamsDialogTypeData: null,
    }
    this.onSectionDragEnd = this.onSectionDragEnd.bind(this)
  }
  componentDidMount() {
      this.props.getOrgWorkflow({ types: [1], page: 0, pageSize: 1000 });
      this.props.fetchDocTypesTabs(this.props.match?.params?.id)
      this.props.fetchVisualization({
        query: '',
        status: "",
        forView: true,
      });
      this.setState({tabId: this.props.match?.params?.id});
      setTimeout(() => {
        this.props.updateDrawerStyle(false, true);
      }, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      addDocTypeSectionProgress,
      addDocTypeSectionSuccess,
      updateDocTypeSectionProgress, isLoading,
      deleteDocTypeSectionItemProgress,
      deleteDocTypeSectionProgress,
      deleteOrgVendorDetailTabsSectionError
    } = this.props;

    if (!isLoading && prevProps.isLoading) {
      this.setState({
        dragFromSeq: null,
        isDrapSection: false,
      })
    }

    if (!addDocTypeSectionProgress && prevProps.addDocTypeSectionProgress && addDocTypeSectionSuccess) {
      this.props.showSnackBar("Section added successfully.", "success", 3000);
      // const data = this.state.newTabSection;
      // const items = data?.items || []
      // items.forEach((item, k) => {
      //   this.props.addDocTypeSectionItem(
      //     data.tabId, addDocTypeSectionSuccess.id,
      //     {
      //       name: item.name,
      //       description: item.description,
      //       // visibility: item.visibility,
      //       type: item.type,
      //       additional_data: JSON.stringify(item.additional_data),
      //       sequence: k + 1,
      //     }
      //   )
      // })

      this.setState({
        newTabSection: null,
        activeSection: null,
        activeNewSection: null
      }, () => {
        this.props.fetchDocTypesTabs(this.props.match?.params?.id)
        setTimeout(() => {
          this.setState({
            isUpdateItemHappen: this.state.isUpdateItemHappen ? false : true
          })
        }, 3000);
      })
    }

    if (!deleteDocTypeSectionProgress && prevProps.deleteDocTypeSectionProgress === true) {
      if (deleteOrgVendorDetailTabsSectionError) {
        this.props.showSnackBar("Failed to delete.", "error", 3000);
      } else {
        this.props.showSnackBar("Section deleted successfully.", "success", 3000);
      }
    }

    if (!deleteDocTypeSectionItemProgress && prevProps.deleteDocTypeSectionItemProgress === true) {
      this.setState({
        activeSection: null,
        isUpdateItemHappen: this.state.isUpdateItemHappen ? false : true
      });
    }

    if (!updateDocTypeSectionProgress && prevProps.updateDocTypeSectionProgress) {
      if (this.state.newTabSection === null) {
        this.props.showSnackBar("Section updated successfully.", "success", 3000)
      }
      if (!this.state.isDrapSection) {
        const data = this.state.newTabSection;
        const items = data?.items || []
        console.log(items, data, 'tabSectionapi');
        let addNewItems = []
        items.forEach((item, k) => {
          const newData = {
            name: item.name,
            description: item.description,
            visibility: item.visibility,
            type: item.type,
            additional_data: JSON.stringify(item.additional_data),
            sequence: k + 1
          }
          if (item?.isNew) {
            addNewItems.push(newData);
          } else {
            this.props.updateDocTypeSectionItem(
              this.state.tabId, data.id, item.itemId, newData
            )
          }
        });
        if(addNewItems.length>0){
          this.props.addDocTypeSectionItem(this.state.tabId, data.id, addNewItems)
        }
      }
      this.setState({
        newTabSection: null,
        activeSection: null,
        activeNewSection: null
      }, () => {
        setTimeout(() => {
          this.setState({
            isUpdateItemHappen: this.state.isUpdateItemHappen ? false : true
          })
        }, 3000);
        // fetchDocTypesTabs({page:1,page_size:10})
      })
    }
  }

  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  handleCreateNewSection = (data) => {
    // const { newTabSection } = this.state
    // if (newTabSection === null) {
      const currentTab = this.props.docTypeTabs[this.state.tab];
      let seq = currentTab?.sections?.length + 1 || 1
      // this.setState({
      //   newTabSection: { ...data }
      // }, () => {
        this.props.addDocTypeSection(
          this.state.tabId,
          [{
            name: data.name,
            description: "",
            visibility: data.visibility,
            sequence: seq,
            items: data?.items?.map((item, k) => ({
              name: item.name,
              description: item.description,
              // visibility: item.visibility,
              type: item.type,
              additional_data: JSON.stringify(item.additional_data),
              sequence: k + 1,
            }))
          }]
        )
    //   })
    // }
  }

  handleUpdateSection = (data, isCustom, id) => {
    const { newTabSection } = this.state
    console.log(newTabSection, 'tabSectionapi new')
    if (newTabSection === null) {
      this.setState({
        newTabSection: isCustom ? { ...data, id } : null
      }, () => {
        this.props.updateDocTypeSection(
          this.state.tabId,
          id,
          {
            name: data.name,
            description: "",
            visibility: data.visibility
          }
        )
      })
    }
  }

  handleUpdateSectionVisibility = (data, id) => {
    const { newTabSection } = this.state
    if (newTabSection === null) {
      this.props.updateDocTypeSection(
        this.state.tabId,
        id,
        {
          visibility: data.visibility
        }
      )
    }
  }

  handleDeleteSection = (id) => {
    this.setState({ activeSection: null }, () => {
      this.props.deleteDocTypeSection(this.state.tabId, id)
    })
  }

  handleDeleteItem = (id, itemId) => {
    this.setState({
      activeSection: null,
    }, () => {
      this.props.deleteDocTypeSectionItem(this.state.tabId, id, itemId)
    })
  }

  handleSaveSectionData = (index, data) => {
    const { tabs, tab } = this.state
    const tabView = tabs[tab]
    tabView.sections[index] = { ...tabView.sections[index], ...data }
    tabs[tab] = tabView
    this.setState({ tabs, activeSection: null })
  }

  onSectionDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const { destination, draggableId, source } = result;
    if (draggableId && destination.index !== source.index) {
      const itemIndex = parseInt(draggableId)
      const destinationIndex = parseInt(destination.index)
      const currentTab = this.props.docTypeTabs
      const dragedSection = currentTab.sections[itemIndex]

      let firstIndex = currentTab.sections[destinationIndex - 1]?.sequence;
      let lastIndex = currentTab.sections[destinationIndex]?.sequence;

      if (destinationIndex === 0) {
        firstIndex = 0;
      } else if (destinationIndex === (currentTab.sections.length - 1)) {
        lastIndex = currentTab.sections[destinationIndex]?.sequence + 1;
      }

      const newSeq = (firstIndex + lastIndex) / 2
      this.setState({
        isDrapSection: true
      }, () => {
        this.props.updateDocTypeSectionSequence(
          currentTab.id,
          dragedSection.id,
          {
            sequence: newSeq
          },
          'document_types'
        )
      })
    }
  }

  render() {

    const {
      classes, docTypeTabs, isLoading, addDocTypeSectionProgress,
      deleteDocTypeSectionProgress, updateDocTypeSectionProgress,
      addDocTypeSectionItemProgress, updateDocTypeSectionItemProgress,
      deleteDocTypeSectionItemProgress,
    } = this.props;

    const {
      activeSection,
      activeNewSection, isDrapSection, isUpdateHappen, isUpdateItemHappen
    } = this.state;

    if (isLoading && !docTypeTabs?.sections) {
      return (<Grid container alignItems="center" justify="center" style={{ height: "50vh" }}><CircularProgress /></Grid>)
    }

    let tabView = docTypeTabs?.sections || []

    return (
      <div className={classes.root}>
        <AppBarV2
          title={"Document"}
          withBack={true}
          dropdown={true}
        />
        <Grid container className={classnames(classes.container, classes.rootContainer)}>
          <div className={classes.leftSection}>
            <DragDropContext onDragEnd={this.onSectionDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <ul className={classes.list} style={{marginTop: 0}}>
                      {![null, undefined, 'NaN'].includes(tabView) &&
                        tabView.filter(o => !(o.name && o.name?.length === 0))
                          .map((obj, index) => {
                            if (obj.name === null || obj.name === '' || obj.name.length <= 0) {
                              return null
                            }
                            return <Draggable key={String(index)} draggableId={String(index)} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <a href={`#${obj.name.toLowerCase().split(" ").join("-")}`}>
                                    <li className={classes.item} key={`section-list-${index}`}>
                                      <Grid container alignItems="center">
                                        <DragIndicatorOutlinedIcon id={index} style={{ color: '#D9D9D9' }} />
                                        <p style={{ flex: 1, padding: 0, margin: 0, wordBreak: 'break-word' }}>{obj.name}</p>
                                      </Grid>
                                    </li>
                                  </a>
                                </div>
                              )}
                            </Draggable>
                          })}
                    </ul>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className={classes.rightSection}>
            <div className={classes.tabContainer}>
              {(updateDocTypeSectionProgress || isLoading || deleteDocTypeSectionItemProgress) && isDrapSection ? (
                <Grid container alignItems="center" justify="center" style={{ height: "20vh" }}><CircularProgress /></Grid>
              ) : (
                <>
                  {tabView && (
                    <>
                      <DragDropContext onDragEnd={this.onSectionDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {tabView?.map((obj, index) => (
                                <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <FormBuilder
                                        config={obj}
                                        id={`${obj.name.toLowerCase().split(" ").join("-")}`}
                                        key={obj.id}
                                        isNew={false}
                                        isLoader={deleteDocTypeSectionProgress ||
                                          updateDocTypeSectionProgress ||
                                          updateDocTypeSectionItemProgress ||
                                          deleteDocTypeSectionItemProgress
                                        }
                                        isCustom={!obj.is_default}
                                        onDelete={() => this.handleDeleteSection(obj.id)}
                                        deleteItem={itemId => this.handleDeleteItem( obj.id, itemId)}
                                        onCancel={() => this.setState({ activeSection: null })}
                                        isActive={activeSection !== null && activeSection === index}
                                        activeSection={() => this.setState({ activeSection: index })}
                                        onUpdate={data => this.handleUpdateSection(data, true, obj.id)}
                                        onUpdateSectionVisibility={data => this.handleUpdateSectionVisibility(data, obj.id)}
                                        addTeams={() => this.setState({
                                          addTeamsDialogOpen: true,
                                          addTeamsDialogType: 'Section',
                                          addTeamsDialogTypeData: { ...obj }
                                        })}
                                        isUpdateHappen={isUpdateHappen}
                                        isUpdateItemHappen={isUpdateItemHappen}
                                        visualizations={this.props.visualizations}
                                        orgWorkFlowList={this.props.orgWorkFlowList}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))
                              }
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </>
                  )}
                  {activeNewSection === null ? (
                    <Grid container justify="center">
                      <SqureButton
                        variant={"contained"}
                        onClick={() => {
                          this.setState({ activeNewSection: docTypeTabs })
                          setTimeout(() => {
                            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
                          }, 100)
                        }}
                      >
                        + New Section
                      </SqureButton>
                    </Grid>
                  ) : (
                    <FormBuilder
                      isNew
                      isLoader={
                        addDocTypeSectionProgress ||
                        addDocTypeSectionItemProgress
                      }
                      isCustom={true}
                      isActive={true}
                      key={generate()}
                      config={{ visibility: [] }}
                      onSave={data => this.handleCreateNewSection(data)}
                      onCancel={() => this.setState({ activeNewSection: null })}
                      visualizations={this.props.visualizations}
                      orgWorkFlowList={this.props.orgWorkFlowList}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Grid>
        
        {this.state.addTeamsDialogOpen && <SearchTeamsPoper
          onSubmit={(teams) => {
            if (this.state.tab === 'Tab') {
              const { id, tabId } = this.state.addTeamsDialogTypeData;
              this.props.updateDocTypeSection(tabId, id, { teams });
            } else {
              const { id } = this.state.addTeamsDialogTypeData;
              this.props.updateDocType(id, { teams });
            }
          }}
          onCancel={() => {
            this.setState({
              addTeamsDialogOpen: false,
              addTeamsDialogType: null,
              addTeamsDialogTypeData: null,
            })
          }}
          selectedTeams={this.state.addTeamsDialogTypeData?.teams}
        />}

      </div>
    )
  }
}

export default connector(compose(withStyles(styles))(DocumentTypesCatalog));