import { HttpFactory } from "services/httpService";
import * as Environment from "../../util/Environment";

export const START_EVALUTION_META_FETCH = "START_EVALUTION_META_FETCH";
export const END_EVALUTION_META_FETCH = "END_EVALUTION_META_FETCH";

export const START_SELLER_THREAD_FETCH = "START_SELLER_THREAD_FETCH";
export const END_SELLER_THREAD_FETCH = "END_SELLER_THREAD_FETCH";

export const START_SELLER_RESPONSE_SUBMIT = "START_SELLER_RESPONSE_SUBMIT";
export const END_SELLER_RESPONSE_SUBMIT = "END_SELLER_RESPONSE_SUBMIT";

export const SHOW_SELLER_COLLABORATOR_DIALOG = "SHOW_SELLER_COLLABORATOR_DIALOG";
export const HIDE_SELLER_COLLABORATOR_DIALOG = "HIDE_SELLER_COLLABORATOR_DIALOG";

export const SHOW_ADD_NEW_COLLABORATOR_DIALOG = "SHOW_ADD_NEW_COLLABORATOR_DIALOG";
export const HIDE_ADD_NEW_COLLABORATOR_DIALOG = "HIDE_ADD_NEW_COLLABORATOR_DIALOG";

export const START_SELLER_COLLOBORATOR_FETCH = "START_SELLER_COLLOBORATOR_FETCH";
export const END_SELLER_COLLOBORATOR_FETCH = "END_SELLER_COLLOBORATOR_FETCH";

export const START_SELLER_COLLOBORATOR_ADD = "START_SELLER_COLLOBORATOR_ADD";
export const END_SELLER_COLLOBORATOR_ADD = "END_SELLER_COLLOBORATOR_ADD";

export const START_SELLER_REQ_COLLOBORATOR_FETCH = "START_SELLER_REQ_COLLOBORATOR_FETCH";
export const END_SELLER_REQ_COLLOBORATOR_FETCH = "END_SELLER_REQ_COLLOBORATOR_FETCH";

export const START_SELLER_REQ_COLLOBORATOR_ADD = "START_SELLER_REQ_COLLOBORATOR_ADD";
export const END_SELLER_REQ_COLLOBORATOR_ADD = "END_SELLER_REQ_COLLOBORATOR_ADD";

export const START_SELLER_DRAFT_RESPONSE_SUBMIT = "START_SELLER_DRAFT_RESPONSE_SUBMIT";
export const END_SELLER_DRAFT_RESPONSE_SUBMIT = "END_SELLER_DRAFT_RESPONSE_SUBMIT";

export const SHOW_SUBMIT_ALL_SELLER_RESPONSES_DIALOG = "SHOW_SUBMIT_ALL_SELLER_RESPONSES_DIALOG";
export const HIDE_SUBMIT_ALL_SELLER_RESPONSES_DIALOG = "HIDE_SUBMIT_ALL_SELLER_RESPONSES_DIALOG";

export const START_SELLER_DRAFT_RESPONSES_FETCH = "START_SELLER_DRAFT_RESPONSES_FETCH";
export const END_SELLER_DRAFT_RESPONSES_FETCH = "END_SELLER_DRAFT_RESPONSES_FETCH";

export const START_SELLER_DRAFT_RESPONSES_UPDATE = "START_SELLER_DRAFT_RESPONSES_UPDATE";
export const END_SELLER_DRAFT_RESPONSES_UPDATE = "END_SELLER_DRAFT_RESPONSES_UPDATE";

export const START_SELLER_REQUIREMENT_RESPONSES_ADD = "START_SELLER_REQUIREMENT_RESPONSES_ADD";
export const END_SELLER_REQUIREMENT_RESPONSES_ADD = "END_SELLER_REQUIREMENT_RESPONSES_ADD";

export const SHOW_SELLER_FINALIZE_REQ_DIALOG = "SHOW_SELLER_FINALIZE_REQ_DIALOG";
export const HIDE_SELLER_FINALIZE_REQ_DIALOG = "HIDE_SELLER_FINALIZE_REQ_DIALOG";

export const START_SELLER_REQ_TREE_FETCH = "START_SELLER_REQ_TREE_FETCH";
export const END_SELLER_REQ_TREE_FETCH = "END_SELLER_REQ_TREE_FETCH";

export const START_SELLER_USER_LAST_RESPONSE_FETCH = "START_SELLER_USER_LAST_RESPONSE_FETCH";
export const END_SELLER_USER_LAST_RESPONSE_FETCH = "END_SELLER_USER_LAST_RESPONSE_FETCH";

export const SET_SELECTED_REQUIREMENT_FOR_SELLER = "SET_SELECTED_REQUIREMENT_FOR_SELLER";

export const START_FETCH_SELLER_ACTIVITY = "START_FETCH_SELLER_ACTIVITY"
export const END_FETCH_SELLER_ACTIVITY = "END_FETCH_SELLER_ACTIVITY"

export const START_REQUIREMENT_RESPONSES_ADD_IN_BULK = "START_REQUIREMENT_RESPONSES_ADD_IN_BULK"
export const END_REQUIREMENT_RESPONSES_ADD_IN_BULK = "END_REQUIREMENT_RESPONSES_ADD_IN_BULK"

export const START_SELLER_INSTRUCTION_FETCH = "START_SELLER_INSTRUCTION_FETCH";
export const END_SELLER_INSTRUCTION_FETCH = "END_SELLER_INSTRUCTION_FETCH";

export const START_SELLER_POPUP_STATE_UPDATE = "START_SELLER_POPUP_STATE_UPDATE";
export const END_SELLER_POPUP_STATE_UPDATE = "END_SELLER_POPUP_STATE_UPDATE";

export const START_FETCH_PRODUCT_RFP_MAP = "START_FETCH_PRODUCT_RFP_MAP";
export const END_FETCH_PRODUCT_RFP_MAP = "END_FETCH_PRODUCT_RFP_MAP";


function startFetchSellerActivity() {
    return {
        type: START_FETCH_SELLER_ACTIVITY,
        payload: null
    };
}

function endFetchSellerActivity(success, error) {
    return {
        type: END_FETCH_SELLER_ACTIVITY,
        payload: {
            success, error
        }
    };
}

export function fetchSellerActivity(uuid) {
    return async (dispatch) => {
        dispatch(startFetchSellerActivity());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/askseller/activity?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endFetchSellerActivity(response, error));
        } catch (e) {
            dispatch(endFetchSellerActivity(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startProductRFPMap() {
    return {
        type: START_FETCH_PRODUCT_RFP_MAP,
        payload: null
    };
}

function endProductRFPMap(success, error) {
    return {
        type: END_FETCH_PRODUCT_RFP_MAP,
        payload: {
            success, error
        }
    };
}

export function productRFPMapFetch(evaluationID) {
    return async (dispatch) => {
        dispatch(startProductRFPMap());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${evaluationID}/products-rfp-map?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endProductRFPMap(response, error));
        } catch (e) {
            dispatch(endProductRFPMap(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startEvaluationMetaFetch() {
    return {
        type: START_EVALUTION_META_FETCH,
        payload: null
    };
}

function endEvaluationMetaFetch(success, error) {
    return {
        type: END_EVALUTION_META_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchEvaluationMeta(uuid, org_id) {
    return async (dispatch) => {
        dispatch(startEvaluationMetaFetch());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/askseller/meta?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endEvaluationMetaFetch(response, error));
        } catch (e) {
            dispatch(endEvaluationMetaFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startEvaluationThreadFetch() {
    return {
        type: START_SELLER_THREAD_FETCH,
        payload: null
    };
}

function endEvaluationThreadFetch(success, error) {
    return {
        type: END_SELLER_THREAD_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchEvaluationThread(uuid, org_id, requirement_id) {
    return async (dispatch) => {
        dispatch(startEvaluationThreadFetch());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/requirement/${requirement_id}/seller/askseller-thread?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endEvaluationThreadFetch(response, error));
        } catch (e) {
            dispatch(endEvaluationThreadFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startEvaluationResponseSubmit() {
    return {
        type: START_SELLER_RESPONSE_SUBMIT,
        payload: null
    };
}

function endEvaluationResponseSubmit(success, error) {
    return {
        type: END_SELLER_RESPONSE_SUBMIT,
        payload: {
            success, error
        }
    };
}

export function submitEvaluationResponse(uuid, org_id, requirement_id, message) {
    return async (dispatch) => {
        dispatch(startEvaluationResponseSubmit());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/requirement/${requirement_id}/askseller/response?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().postMethod(url, {
                response: message
            }, true, false);
            dispatch(endEvaluationResponseSubmit(response, error));
        } catch (e) {
            dispatch(endEvaluationResponseSubmit(null, {
                message: e.message
            }));
            return;
        }
    };
}

/**
 * seller collaborator dialog
 */
export function showSellerCollaboratorDialog(target) {
    return {
        type: SHOW_SELLER_COLLABORATOR_DIALOG,
        payload: {
            target
        }
    };
}

export function hideSellerCollaboratorDialog() {
    return {
        type: HIDE_SELLER_COLLABORATOR_DIALOG,
        payload: null
    };
}

/**
 * seller collaborator dialog
 */
export function showAddNewUserDialog() {
    return {
        type: SHOW_ADD_NEW_COLLABORATOR_DIALOG,
        payload: null
    };
}

export function hideAddNewUserDialog() {
    return {
        type: HIDE_ADD_NEW_COLLABORATOR_DIALOG,
        payload: null
    };
}


function startSellerColloboratorFetch() {
    return {
        type: START_SELLER_COLLOBORATOR_FETCH,
        payload: null
    };
}

function endSellerColloboratorFetch(success, error) {
    return {
        type: END_SELLER_COLLOBORATOR_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchSellerColloborator(uuid, org_id) {
    return async (dispatch) => {
        dispatch(startSellerColloboratorFetch());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/colloborator?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endSellerColloboratorFetch(response, error));
        } catch (e) {
            dispatch(endSellerColloboratorFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startSellerColloboratorAdd() {
    return {
        type: START_SELLER_COLLOBORATOR_ADD,
        payload: null
    };
}

function endSellerColloboratorAdd(success, error) {
    return {
        type: END_SELLER_COLLOBORATOR_ADD,
        payload: {
            success, error
        }
    };
}

export function addSellerColloborator(uuid, org_id, params) {
    return async (dispatch) => {
        dispatch(startSellerColloboratorAdd());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/colloborator?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().postMethod(url, params, true, false);
            dispatch(endSellerColloboratorAdd(response, error));
        } catch (e) {
            dispatch(endSellerColloboratorAdd(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startSellerRequirementColloboratorFetch() {
    return {
        type: START_SELLER_REQ_COLLOBORATOR_FETCH,
        payload: null
    };
}

function endSellerRequirementColloboratorFetch(success, error) {
    return {
        type: END_SELLER_REQ_COLLOBORATOR_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchSellerRequirementColloborator(uuid, org_id, req_id) {
    return async (dispatch) => {
        dispatch(startSellerRequirementColloboratorFetch());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/requirement/${req_id}/colloborator?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endSellerRequirementColloboratorFetch(response, error));
        } catch (e) {
            dispatch(endSellerRequirementColloboratorFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startSellerRequirementColloboratorAdd() {
    return {
        type: START_SELLER_REQ_COLLOBORATOR_ADD,
        payload: null
    };
}

function endSellerRequirementColloboratorAdd(success, error) {
    return {
        type: END_SELLER_REQ_COLLOBORATOR_ADD,
        payload: {
            success, error
        }
    };
}

export function addSellerRequirementColloborator(uuid, org_id, req_id, params) {
    return async (dispatch) => {
        dispatch(startSellerRequirementColloboratorAdd());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/requirement/${req_id}/colloborator?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().postMethod(url, params, true, false);
            dispatch(endSellerRequirementColloboratorAdd(response, error));
        } catch (e) {
            dispatch(endSellerRequirementColloboratorAdd(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startEvaluationDraftResponseSubmit() {
    return {
        type: START_SELLER_DRAFT_RESPONSE_SUBMIT,
        payload: null
    };
}

function endEvaluationDraftResponseSubmit(success, error) {
    return {
        type: END_SELLER_DRAFT_RESPONSE_SUBMIT,
        payload: {
            success, error
        }
    };
}

export function submitEvaluationDraftResponse(uuid, org_id, requirement_id, message) {
    return async (dispatch) => {
        dispatch(startEvaluationDraftResponseSubmit());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/requirement/${requirement_id}/askseller/response?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().postMethod(url, {
                response: message,
                is_draft: true
            }, true, false);
            dispatch(endEvaluationDraftResponseSubmit(response, error));
        } catch (e) {
            dispatch(endEvaluationDraftResponseSubmit(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function showSubmitAllDraftResponcesDialog() {
    return {
        type: SHOW_SUBMIT_ALL_SELLER_RESPONSES_DIALOG,
        payload: null
    };
}

export function hideSubmitAllDraftResponcesDialog() {
    return {
        type: HIDE_SUBMIT_ALL_SELLER_RESPONSES_DIALOG,
        payload: null
    };
}


function startfetchSellerDraftResponses() {
    return {
        type: START_SELLER_DRAFT_RESPONSES_FETCH,
        payload: null
    };
}

function endfetchSellerDraftResponses(success, error) {
    return {
        type: END_SELLER_DRAFT_RESPONSES_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchSellerDraftResponses(uuid, org_id) {
    return async (dispatch) => {
        dispatch(startfetchSellerDraftResponses());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/seller-draft-responses?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endfetchSellerDraftResponses(response, error));
        } catch (e) {
            dispatch(endfetchSellerDraftResponses(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateSellerDraftResponsesStatus() {
    return {
        type: START_SELLER_DRAFT_RESPONSES_UPDATE,
        payload: null
    };
}

function endUpdateSellerDraftResponsesStatus(success, error) {
    return {
        type: END_SELLER_DRAFT_RESPONSES_UPDATE,
        payload: {
            success, error
        }
    };
}

export function updateSellerDraftResponsesStatus(uuid, org_id, params) {
    return async (dispatch) => {
        dispatch(startUpdateSellerDraftResponsesStatus());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/submit-seller-draft-responses?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().postMethod(url, params, true, false);
            dispatch(endUpdateSellerDraftResponsesStatus(response, error));
        } catch (e) {
            dispatch(endUpdateSellerDraftResponsesStatus(null, {
                message: e.message
            }));
            return;
        }
    };
}



function startAddSellerReqResponse() {
    return {
        type: START_SELLER_REQUIREMENT_RESPONSES_ADD,
        payload: null
    };
}

function endAddSellerReqResponse(success, error) {
    return {
        type: END_SELLER_REQUIREMENT_RESPONSES_ADD,
        payload: {
            success, error
        }
    };
}

export function addSellerRequirementResponse(uuid, org_id, req_id, params) {
    return async (dispatch) => {
        dispatch(startAddSellerReqResponse());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/requirement/${req_id}/askseller/requirement/response?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().postMethod(url, params, true, false);
            dispatch(endAddSellerReqResponse(response, error));
        } catch (e) {
            dispatch(endAddSellerReqResponse(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function showSellerFinalizeReqDialog(type, isShowViewDetails = true) {
    return {
        type: SHOW_SELLER_FINALIZE_REQ_DIALOG,
        payload: { type, isShowViewDetails }
    };
}

export function hideSellerFinalizeReqDialog() {
    return {
        type: HIDE_SELLER_FINALIZE_REQ_DIALOG,
        payload: {}
    };
}

function startfetchSellerRequirementTree() {
    return {
        type: START_SELLER_REQ_TREE_FETCH,
        payload: null
    };
}

function endfetchSellerRequirementTree(success, error) {
    return {
        type: END_SELLER_REQ_TREE_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchSellerRequirementTree(uuid, org_id) {
    return async (dispatch) => {
        dispatch(startfetchSellerRequirementTree());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/requirement-tree?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endfetchSellerRequirementTree(response, error));
        } catch (e) {
            dispatch(endfetchSellerRequirementTree(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startfetchSellerLastUserResponse() {
    return {
        type: START_SELLER_USER_LAST_RESPONSE_FETCH,
        payload: null
    };
}

function endfetchSellerLastUserResponse(success, error) {
    return {
        type: END_SELLER_USER_LAST_RESPONSE_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchSellerLastUserResponse(uuid, org_id) {
    return async (dispatch) => {
        dispatch(startfetchSellerLastUserResponse());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/seller-last-response?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endfetchSellerLastUserResponse(response, error));
        } catch (e) {
            dispatch(endfetchSellerLastUserResponse(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function setSelectedRequirement(req_id) {
    return {
        type: SET_SELECTED_REQUIREMENT_FOR_SELLER,
        payload: {
            req_id
        }
    };
}


function startAddBulkResponses() {
    return {
        type: START_REQUIREMENT_RESPONSES_ADD_IN_BULK,
        payload: null
    };
}

function endAddBulkResponses(success, error) {
    return {
        type: END_REQUIREMENT_RESPONSES_ADD_IN_BULK,
        payload: {
            success, error
        }
    };
}

export function addBulkRequirementResponse(uuid, org_id, params) {
    return async (dispatch) => {
        dispatch(startAddBulkResponses());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/askseller/requirements/responses?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().postMethod(url, params, true, false);
            dispatch(endAddBulkResponses(response, error));
        } catch (e) {
            dispatch(endAddBulkResponses(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startfetchSellerInstruction() {
    return {
        type: START_SELLER_INSTRUCTION_FETCH,
        payload: null
    };
}

function endfetchSellerInstruction(success, error) {
    return {
        type: END_SELLER_INSTRUCTION_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchSellerInstruction(uuid, org_id) {
    return async (dispatch) => {
        dispatch(startfetchSellerInstruction());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${uuid}/${org_id}/instructions?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().getMethodV2(url, false);
            dispatch(endfetchSellerInstruction(response, error));
        } catch (e) {
            dispatch(endfetchSellerInstruction(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startSellerDetailsPopupStateUpdate() {
    return {
        type: START_SELLER_POPUP_STATE_UPDATE,
        payload: null
    };
}

function endSellerDetailsPopupStateUpdate(success, error) {
    return {
        type: END_SELLER_POPUP_STATE_UPDATE,
        payload: {
            success, error
        }
    };
}

export function sellerDetailsPopupStateUpdate(evaluationID, params) {
    return async (dispatch) => {
        dispatch(startSellerDetailsPopupStateUpdate());
        try {
            const api_server = Environment.api_host("EVALUATION");
            const timestamp = (new Date()).getTime();
            const url = `${api_server}/${evaluationID}/ask_seller?t=${timestamp}`;
            let [response, error] = await HttpFactory.instance().putMethod(url, params, true, false);
            dispatch(endSellerDetailsPopupStateUpdate(response, error));
        } catch (e) {
            dispatch(endSellerDetailsPopupStateUpdate(null, {
                message: e.message
            }));
            return;
        }
    };
} 