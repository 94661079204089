/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import { CircularProgress, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withLastLocation } from "react-router-last-location";
import { createStyles, withStyles } from "@material-ui/core/styles";
// Icons
import ArrowForward from "assets/images/arrow_forward_white.svg";
import AddUpdateTaskDialog from "Components/Common/Dialogs/AddUpdateTaskDialog";
import AddUpdateVendorServiceTaskDialog from "Components/Common/Dialogs/AddUpdateVendorServiceTaskDialog";
import * as Validator from "util/Validation";
// Assets
import classnames from "classnames";
import BottomAction from "Components/Application/Components/Evaluate/Components/Evaluation/Common/BottomAction.jsx";
// Components
import Image from "Components/Common/image.jsx";
import SqureButton from "Components/Common/SqureButton";
import SearchUserAndTeamPoper from "Components/Common/TeamsAndUser/SearchUserAndTeamPoper";
import SearchUserPoper from "Components/Common/UserGroup/SearchUserPoper";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { toggleEvaluateNeverReviewed, toggleEvaluateView } from "redux/applications/action";
import {
  fetchEvaluationApprovalDetails,
  fetchProductApprovalDetails,
  resetAddApprovalForCriteria,
  fetchApprovalCriteria,
  addApprovalCriteriaApprover,
  addApprovalCriteria,
  resetApprovalCriterias
} from "redux/evaluation/approval/action";
import { fetchCriteria, fetchScores } from "redux/evaluation/criteria/action";
import { fetchEvaluationMetaData, showEvaluationFinishDialog, showSendNotificationDialog, updateEvaluationInfo, updateEvaluationProduct, deleteEvaluationApprovals, updateApprovalInfo } from "redux/evaluation/metadata/action";
import { fetchAllOrgSettings } from "redux/org/user/action";
import { hideEvaluationCollaboratorDialog } from "redux/evaluate/action";
// Redux
import { showSnackBar } from "redux/snackbar/action";
import EvaluationBar from "../Common/EvaluationBar";

import { addWorkflowComponentUserResponse, fetchWorkflowComponentDetails } from "redux/workflow/action";
import { fetchEvaluationCart } from "redux/evaluation/cart/action";
import { fetchUserWorkflowTimeline } from "redux/workflow/action";
import { getNextComponent, getPreviousComponent } from "util/Common";
// import WorkflowSummary from "../WorkFlow/Components/Summary/index";
import ApprovalAction from "./ApprovalAction";
import ProductDetails from "./ProductDetails";
import ApprovalCriteriaThread from "./ApprovalCriteriaThread";
import { approvalStages } from "util/Workflow";

import {
  showConfirmCallbackDialog,
  hideConfirmCallbackDialog
} from "redux/applications/action";

import ConfirmCallbackDialog from "Components/Common/ConfirmCallbackDialog";
import { getNewPlatform } from "util/Environment";

const connectedProps = (state) => ({
  orguser: state.orgUser,
  user: state.authUser.user,
  evaluation: state.evaluationMetaData.evaluation,
  criterias: state.evaludationCriteria.criterias,
  deleteCriteriaProgress: state.evaludationCriteria.deleteCriteriaProgress,
  errorCriteriaDelete: state.evaludationCriteria.errorCriteriaDelete,
  scores: state.evaludationCriteria.scores,
  productApprovalDetails: state.evaluationApproval.productApprovalDetails,
  productApprovalDetailsInProgress: state.evaluationApproval.productApprovalDetailsInProgress,
  addApprovalForCriteria: state.evaluationApproval.addApprovalForCriteria,
  addApprovalForCriteriaSuccess: state.evaluationApproval.addApprovalForCriteriaSuccess,

  evaluationFetchProgress: state.evaluationMetaData.fetchProgress,
  updateProgress: state.evaluationMetaData.updateProgress,
  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
  collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  fetchworkflowComponentDetailProgress: state.workflow.fetchworkflowComponentDetailProgress,
  userTimeline: state.workflow.userTimeline,

  approvalCriterias: state.evaluationApproval.approvalCriterias,
  fetchApprovalCriteriasProgress: state.evaluationApproval.fetchApprovalCriteriasProgress,
  fetchApprovalCriteriasError: state.evaluationApproval.fetchApprovalCriteriasError,
  addApprovalCriteriaApproverProgress: state.evaluationApproval.addApprovalCriteriaApproverProgress,

  addApprovalCriteriaError: state.evaluationApproval.addApprovalCriteriaError,
  addApprovalCriteriaSuccess: state.evaluationApproval.addApprovalCriteriaSuccess,
  addApprovalCriteriaProgress: state.evaluationApproval.addApprovalCriteriaProgress,

  updateSequenceApprovalCriteriaError: state.evaluationApproval.updateSequenceApprovalCriteriaError,
  updateSequenceApprovalCriteriaProgress: state.evaluationApproval.updateSequenceApprovalCriteriaProgress,

  updateApprovalCriteriaError: state.evaluationApproval.updateApprovalCriteriaError,
  updateApprovalCriteriaProgress: state.evaluationApproval.updateApprovalCriteriaProgress,
  skipApprovalCriteriaError: state.evaluationApproval.skipApprovalCriteriaError,
  skipApprovalCriteriaProgress: state.evaluationApproval.skipApprovalCriteriaProgress,
  deleteApprovalCriteriaError: state.evaluationApproval.deleteApprovalCriteriaError,
  deleteApprovalCriteriaProgress: state.evaluationApproval.deleteApprovalCriteriaProgress,

  addApprovalCriteriaApproverError: state.evaluationApproval.addApprovalCriteriaApproverError,
  isOpenTaskDialog: state.productTasks.showAddTaskDialog,
  evalInfoUpdateProgress: state.evaluationMetaData.evalInfoUpdateProgress,
  evalInfoUpdateError: state.evaluationMetaData.evalInfoUpdateError,
  deleteApprovalsProgress: state.evaluationMetaData.deleteApprovalsProgress,
  deleteApprovalsError: state.evaluationMetaData.deleteApprovalsError,
  fetchWokflowUserTimelineProgress: state.workflow.fetchWokflowUserTimelineProgress,

  evalApprovalInfoUpdateProgress: state.evaluationMetaData.evalApprovalInfoUpdateProgress,
  evalApprovalInfoUpdateError: state.evaluationMetaData.evalApprovalInfoUpdateError,
  evalApprovalInfo: state.evaluationMetaData.evalApprovalInfo,

  showAddTaskDialog: state.vendors.showAddTaskDialog,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  fetchProductApprovalDetails: fetchProductApprovalDetails,
  fetchEvaluationApprovalDetails: fetchEvaluationApprovalDetails,
  resetAddApprovalForCriteria: resetAddApprovalForCriteria,
  fetchScores: fetchScores,
  fetchCriteria: fetchCriteria,
  fetchApprovalCriteria: fetchApprovalCriteria,
  resetApprovalCriterias: resetApprovalCriterias,
  toggleEvaluateView: toggleEvaluateView,
  toggleEvaluateNeverReviewed: toggleEvaluateNeverReviewed,
  showSendNotificationDialog: showSendNotificationDialog,
  fetchAllOrgSettings: fetchAllOrgSettings,
  showEvaluationFinishDialog: showEvaluationFinishDialog,
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  fetchEvaluationCart: fetchEvaluationCart,
  fetchWorkflowComponentDetails: fetchWorkflowComponentDetails,
  addApprovalCriteria: addApprovalCriteria,
  addApprovalCriteriaApprover: addApprovalCriteriaApprover,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
  fetchUserWorkflowTimeline: fetchUserWorkflowTimeline,
  updateEvaluationInfo: updateEvaluationInfo,
  updateEvaluationProduct: updateEvaluationProduct,
  showConfirmCallbackDialog: showConfirmCallbackDialog,
  hideConfirmCallbackDialog: hideConfirmCallbackDialog,
  deleteEvaluationApprovals: deleteEvaluationApprovals,
  updateApprovalInfo: updateApprovalInfo
};
var connector = connect(connectedProps, connectionActions);

// Styles
const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5, 10),
    },
    copyGoesHere: {
      textAlign: "left",
      color: "#282D30",
      fontSize: 15,
      fontWeight: 400,
    },
    loader: {
      position: "fixed",
      right: "45%",
      top: "40%",
    },
    headTitle: {
      fontSize: 19,
      fontWeight: 500,
    },
    details: {
      display: "block",
      background: "#f7f7f7",
    },
    topHead: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      background: '#fff',
      padding: 5,
      borderRadius: 4,
      border: '1px solid #3f4e6b29'
    },
    titleName: {
      flex: 1
    },
    withdrawBtn: {
      textAlign: 'right'
    },
    approvalRoot: {
    }
  });

class GetApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evaluation_id: this.props.match?.params?.evaluation_id,
      product_id: this.props.match?.params?.product_id,
      component_id: this.props.match?.params?.component_id,
      workflow_id: this.props.match?.params?.workflow_id,
      activeCriteria: null,
      activeResponse: null,
      approvalInit: false,
      isWorkFlow: false,
      nextClickLoader: false,
      expanded: "approvels",
      previousComponentData: null,
      approvalStages: [],
      isFinalApproval: true,
      isConfigCheck: false,
      redirectToNext: false,
      nextComponentForRedirect: null,
      isFetchedCriterias: false,
      isFetchedComponentConfig: false,
      isCallOnlyOnce: true,
      callOnce: true
    };

    this.withdrawApprovals = this.withdrawApprovals.bind(this)
    this.startApprovals = this.startApprovals.bind(this)
    this.callBack = this.callBack.bind(this);
    this.onCancelWithdraw = this.onCancelWithdraw.bind(this);
  }

  componentDidMount() {
    let link = `${getNewPlatform()}/project/${this.props.match?.params?.evaluation_id}/workflow/${this.props.match?.params?.workflow_id}/component/${this.props.match?.params?.component_id}`;
    window.open(link, "_self");
    // this.checkEvaluationType();
    // const { evaluation_id, product_id } = this.state;
    // this.props.fetchEvaluationMetaData(evaluation_id);
    // this.props.fetchUserWorkflowTimeline(evaluation_id, null, this.props.match.params.component_id);
    // this.props.fetchScores(evaluation_id);
    // this.props.fetchProductApprovalDetails(evaluation_id, product_id, this.props.match.params.component_id);
    // this.props.fetchAllOrgSettings();
    // this.props.fetchApprovalCriteria(evaluation_id, this.props.match.params.component_id);
    // setTimeout(() => {
    //   this.props.fetchEvaluationCart();
    // }, 1000);
  }

  componentDidUpdate(prevProps) {
    const { evaluation_id, product_id } = this.state;

    if (!this.props.addApprovalCriteriaApproverProgress && prevProps.addApprovalCriteriaApproverProgress) {
      if (this.props.addApprovalCriteriaApproverError) {
        this.props.showSnackBar("Failed to add User", "error", 3000);
      } else {
        this.props.showSnackBar("Users updated successfully", "success", 3000);
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.props.match.params.component_id);
      }
    }

    if (!this.props.deleteCriteriaProgress && prevProps.deleteCriteriaProgress) {
      if (this.props.deleteCriteriaError) {
        this.props.showSnackBar("Failed to delete criteria", "error", 3000);
      } else {
        this.props.showSnackBar("criteria deleted successfully", "success", 3000);
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.props.match.params.component_id);
      }
    }

    if (!this.props.fetchApprovalCriteriasProgress && prevProps.fetchApprovalCriteriasProgress) {
      this.setState({
        isFetchedCriterias: true
      }, () => {
        // this.initializeCriteriasForApprovals()
        this.updateApprovalStages();
        this.initActiveCriteria();
      })
    }

    if (this.props.updateSequenceApprovalCriteriaProgress === false && prevProps.updateSequenceApprovalCriteriaProgress === true) {
      if (this.props.updateSequenceApprovalCriteriaError == null) {
        this.props.showSnackBar("Sequence updated successfully", "success", 3000);
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.props.match.params.component_id);
      } else {
        this.props.showSnackBar("Failed to update sequence", "error", 3000);
      }
    }

    if (this.props.addApprovalCriteriaProgress === false && prevProps.addApprovalCriteriaProgress === true) {
      if (this.props.addApprovalCriteriaError == null) {
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.props.match.params.component_id);
      }
    }

    if (this.props.updateApprovalCriteriaProgress === false && prevProps.updateApprovalCriteriaProgress === true) {
      if (this.props.updateApprovalCriteriaError == null) {
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.props.match.params.component_id);
      }
    }

    if (this.props.skipApprovalCriteriaProgress === false && prevProps.skipApprovalCriteriaProgress === true) {
      if (this.props.skipApprovalCriteriaError == null) {
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.props.match.params.component_id);
      } else {
        this.props.showSnackBar("Failed to skip criteria", "error", 3000);
      }
    }

    if (this.props.deleteApprovalCriteriaProgress === false && prevProps.deleteApprovalCriteriaProgress === true) {
      if (this.props.deleteApprovalCriteriaError == null) {
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.props.match.params.component_id);
      }
    }

    if (prevProps.fetchworkflowComponentDetailProgress && !this.props.fetchworkflowComponentDetailProgress && !this.props.fetchApprovalCriteriasError) {
      this.setState({
        isFetchedComponentConfig: true
      }, () => {
        // this.initializeCriteriasForApprovals()
        this.updateApprovalStages();
      })
    }

    if (prevProps.productApprovalDetailsInProgress && !this.props.productApprovalDetailsInProgress) {
      this.initActiveCriteria();
    }

    if (this.props.addApprovalForCriteriaSuccess == true) {
      // alert message
      this.props.showSnackBar("Status Updated", "success", 3000);

      // reset old state
      this.props.resetAddApprovalForCriteria();
      // fetch approval details
      this.props.fetchProductApprovalDetails(evaluation_id, product_id, this.props.match.params.component_id);
      this.props.fetchEvaluationApprovalDetails(evaluation_id, this.props.match.params.component_id);
      this.props.fetchApprovalCriteria(evaluation_id, this.props.match.params.component_id);
    }

    if (prevProps.addApprovalCriteriaApproverProgress && !this.props.addApprovalCriteriaApproverProgress) {
      this.props.hideEvaluationCollaboratorDialog();
    }

    if (this.props.evaluationFetchProgress === false && prevProps.evaluationFetchProgress === true) {
      if (!this.props.errorFetch) {
        this.setReview();
        // this.checkApprovalType()
      }
    }

    if (this.props.updateProgress == false && prevProps.updateProgress == true) {
      if (this.props.updateError == null) {
        this.setReview();

      }
    }

    if (this.props.addComponentUserResponseProgress === false && prevProps.addComponentUserResponseProgress === true) {
      this.props.history.push("/app/evaluate/" + this.props.match.params.evaluation_id + "/workflow/interim");
    }

    if (!this.props.evalApprovalInfoUpdateProgress && prevProps.evalApprovalInfoUpdateProgress) {
      if (this.props.evalApprovalInfoUpdateError === null && this.state.approvalConfirmType === 'REMOVE') {
        if (this.state.previousComponentData !== null) {
          this.props.history.push("/app/evaluate/" + this.state.previousComponentData?.EvaluationId + "/workflow/" + this.state.previousComponentData?.WorkflowId + "/component/" + this.state.previousComponentData?.ComponentId);
        } else {
          this.props.history.push("/app/evaluate/" + this.state.evaluation_id + "/workflow/" + this.state.workflow_id + "/component/" + this.state.component_id);
        }
      } else if (this.state.redirectToNext) {
        const nextComponent = this.state.nextComponentForRedirect
        let redirectPath = `/app/evaluate/${nextComponent.EvaluationId}/workflow/${nextComponent.WorkflowId}/component/${nextComponent.ComponentId}`;
        this.props.history.push(redirectPath);
      }


    }

    if (!this.props.deleteApprovalsProgress && prevProps.deleteApprovalsProgress) {
      if (this.props.deleteApprovalsError === null) {
        // this.props.updateEvaluationInfo(this.state.evaluation_id, {
        //   approvalStarted: false
        // })
        this.props.updateApprovalInfo(this.state.evaluation_id, {
          type: 'withdraw',
          approval_component_id: parseInt(this.props.match.params.component_id)
        })
      } else {
        this.props.showSnackBar(
          "Failed to remove evaluation approvals.",
          "error",
          3000
        );
      }
    }

    if (this.props.fetchWokflowUserTimelineProgress === false && prevProps.fetchWokflowUserTimelineProgress === true) {
      this.checkPreviousComponent();
    }
  }

  initializeCriteriasForApprovals() {
    if (this.state.isFetchedCriterias && this.state.isFetchedComponentConfig && this.state.isCallOnlyOnce) {
      if (this.props.approvalCriterias && this.props.approvalCriterias.Response && this.props.approvalCriterias.Response.length === 0) {
        if (this.props.workflowComponentDetail?.OrgWorkflowComponentConfigurations?.length) {
          let componentConfig = this.props.workflowComponentDetail?.OrgWorkflowComponentConfigurations.filter(o => o.Form.Name === 'evaluation_approval')[0];
          let parseCompCriterias = Validator.isJson(componentConfig.Value) ? JSON.parse(componentConfig.Value) : [];
          let criterias = [];

          parseCompCriterias.forEach((criteria) => {
            let users = [];
            let teams = [];
            let rules = [];

            if (criteria.rules) {
              rules = criteria.rules;
            }

            if (criteria.approvers.users) {
              criteria.approvers.users.forEach((data) => {
                users.push(data.ID);
              });
              criteria.approvers.teams.forEach((data) => {
                teams.push(data.ID);
              });
            } else {
              users = criteria.approvers.map((c) => {
                return c.ID;
              });
            }

            criterias.push({
              stakeHolder: criteria.name,
              criteriaID: criteria.criteriaId || 0,
              configID: String(criteria.id) || "",
              criteriaType: "",
              approvers: {
                approver_ids: users,
                team_ids: teams,
                productID: Number(this.state.product_id),
              },
              rules: rules,
              componentId: Number(this.props.match.params.component_id),
            });
          });
          let data = { criterias };
          this.props.addApprovalCriteria(this.state.evaluation_id, data);
        }
      }
      this.setState({
        isCallOnlyOnce: false
      }, () => {
        this.updateApprovalStages();
      })
    }
  }

  initActiveCriteria = () => {
    let respondedCriterias = this.props.productApprovalDetails || [];
    let respondedCriteriasIds = respondedCriterias.map((O) => O.CriteriaID);
    let yourResponse = this.props.approvalCriterias?.Response || [];
    let nextCriteria = yourResponse.find((O) => (!respondedCriteriasIds.includes(O.ID) && O.Status !== 0 && O.Status !== 5 && O.YourResponse));
    const urlPrams = new URLSearchParams(this.props.location.search);
    var criteriaId = urlPrams.get("approval_criteria_id");
    if (criteriaId && !this.state.approvalInit && yourResponse.length) {
      let paramsCriteria = yourResponse.find((O) => ((O.ID === parseInt(criteriaId)) && O.Status !== 0 && O.Status !== 5 && O.YourResponse));
      if (paramsCriteria) {
        this.setActiveCriteria(paramsCriteria);
      } else {
        this.setActiveCriteria(null);
      }
      this.setState({ approvalInit: true });
      return;
    }
    if (nextCriteria) {
      this.setActiveCriteria(nextCriteria);
    } else if (respondedCriteriasIds.length > 0) {
      let firstResponed = yourResponse.find((O) => (respondedCriteriasIds.includes(O.ID) && O.Status !== 0 && O.Status !== 5 && O.YourResponse));
      this.setActiveCriteria(firstResponed);
    } else {
      this.setActiveCriteria(null);
    }
    this.setActiveResponse(null);
  }

  updateApprovalStages() {
    const { workflowComponentDetail, evaluation } = this.props;
    let finalStages = []
    if (workflowComponentDetail?.OrgWorkflowComponentConfigurations?.length) {
      let stageConfig = this.props.workflowComponentDetail?.OrgWorkflowComponentConfigurations.filter(o => o.Form.Name === 'approval_stages')[0];
      if (stageConfig !== undefined && stageConfig !== null && stageConfig !== '') {
        let stages = Validator.isJson(stageConfig.Value) ? JSON.parse(stageConfig.Value) : [];
        finalStages = stages;
      } else {
        finalStages = approvalStages;
      }
    } else {
      finalStages = approvalStages;
    }

    let isFinalApproval = true;
    if (this.props.workflowComponentDetail?.OrgWorkflowComponentConfigurations?.length) {
      let componentConfig = this.props.workflowComponentDetail?.OrgWorkflowComponentConfigurations.filter(o => o.Form.Name === 'is_final_approval')[0];
      if (componentConfig !== undefined && componentConfig !== null) {
        if (componentConfig?.Value === false || componentConfig?.Value === 'false') {
          this.checkPreviousComponent();
          isFinalApproval = false;
        }
      }
    }

    this.setState({
      approvalStages: finalStages,
      isFinalApproval: isFinalApproval,
      isConfigCheck: true
    }, () => {
      this.checkApprovalType()
    })
  }

  checkApprovalType() {
    const { evaluation } = this.props;
    const { isFinalApproval, isConfigCheck } = this.state;
    var nextComponent = getNextComponent(this.props.userTimeline, this.props.match.params.component_id);
    if (isConfigCheck && evaluation && nextComponent === null) {
      if (isFinalApproval && evaluation.ApprovalStarted === 0 || (evaluation.ApprovalStarted === 3 && evaluation.ApprovalComponentID !== parseInt(this.state.component_id))) {
        if (this.state.callOnce) {
          this.setState({
            callOnce: false
          }, () => {
            this.showWithdrawWarningDialog('ADD')
          })
        }
      }
    }
  }

  checkPreviousComponent() {
    const { userTimeline } = this.props;
    if (userTimeline) {
      let preComponent = getPreviousComponent(this.props.userTimeline, this.props.match.params.component_id);
      if (preComponent !== undefined && preComponent !== null && preComponent !== '') {
        this.setState({
          previousComponentData: preComponent
        })
      }
    }
  }

  getDifference = (array1, array2) => {
    return array1.find((object1) => {
      return !array2.some((object2) => {
        return object1.ID === object2.ID;
      });
    });
  };

  checkEvaluationType() {
    if (this.props.match.params.workflow_id !== undefined && this.props.match.params.workflow_id !== null && this.props.match.params.workflow_id !== "") {
      this.setState(
        {
          isWorkFlow: true,
        },
        () => {
          this.props.fetchWorkflowComponentDetails(this.props.match.params.workflow_id, this.props.match.params.component_id, this.state.evaluation_id);
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.resetApprovalCriterias();
    try {
      this.props.toggleEvaluateView(false, null);
      this.props.toggleEvaluateNeverReviewed(false, null);
      document.body.classList.remove("evaluate-view");
    } catch (e) { }
  }

  setReview = () => {
    try {
      if (this.props.evaluation?.Status === 4 && this.props.evaluation?.Type === 2 && moment(new Date()).diff(moment(this.props.evaluation?.EvaluationUpdatedAt), "days") > 30) {
        document.body.classList.add("evaluate-view");
        this.props.toggleEvaluateView(true, this.props.evaluation?.Products[0]?.ID);
      } else if (
        (this.props.evaluation?.Status <= 3 || (this.props.evaluation?.Status === 4 && moment(new Date()).diff(moment(this.props.evaluation?.EvaluationUpdatedAt), "days") <= 30)) &&
        this.props.evaluation?.Type === 2
      ) {
        this.props.toggleEvaluateNeverReviewed(true, this.props.evaluation?.Products[0]?.ID);
      }
    } catch (e) { }
  };

  setResetStats = () => {
    this.setState({
      activeCriteria: null,
      activeResponse: null,
    });
  };

  setActiveCriteria = (criteria) => {
    this.setState({
      activeCriteria: criteria,
    });
  };

  setActiveResponse = (value) => {
    this.setState({
      activeResponse: value,
    });
  };

  submitResponse() {
    this.setState(
      {
        nextClickLoader: true,
      },
      () => {
        var nextComponent = getNextComponent(this.props.userTimeline, this.props.match.params.component_id);
        if (nextComponent !== null) {
          const { evaluation } = this.props;
          // console.log(evaluation,'evaluation1111111111111')
          // console.log(this.state.isFinalApproval,'this.state.isFinalApproval')
          // console.log(evaluation.ApprovalComponentID,'evaluation.ApprovalComponentID')
          // console.log(this.state.component_id,'this.state.component_id')
          // console.log(evaluation.ApprovalStarted,'evaluation.ApprovalStarted')
          if (this.state.isFinalApproval && evaluation.ApprovalStarted === 1 && evaluation.ApprovalComponentID === parseInt(this.state.component_id)) {
            this.setState({
              redirectToNext: true,
              nextComponentForRedirect: nextComponent
            }, () => {
              this.props.updateApprovalInfo(this.state.evaluation_id, {
                type: 'end',
                approval_component_id: parseInt(this.state.component_id)
              })
            })
          } else {
            let redirectPath = `/app/evaluate/${nextComponent.EvaluationId}/workflow/${nextComponent.WorkflowId}/component/${nextComponent.ComponentId}`;
            this.props.history.push(redirectPath);
          }
        } else {
          var responseDatas = [];
          if (this.state.isFinalApproval) {
            this.props.updateApprovalInfo(this.state.evaluation_id, {
              type: 'end',
              approval_component_id: parseInt(this.state.component_id)
            })
          }
          this.props.addWorkflowComponentUserResponse(this.props.match.params.evaluation_id, this.props.match.params.component_id, {
            data: [],
            forward: true,
          });
        }
      }
    );
  }

  handleChange = (panel) => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  callBack() {
    const { approvalConfirmType } = this.state;
    if (approvalConfirmType === 'ADD') {
      this.startApprovals();
    } else {
      this.withdrawApprovals();
    }
  }

  onCancelWithdraw() {
    const { approvalConfirmType } = this.state;
    if (approvalConfirmType === 'ADD') {
      this.props.hideConfirmCallbackDialog();
      if (this.state.previousComponentData !== null) {
        this.props.history.push("/app/evaluate/" + this.state.previousComponentData?.EvaluationId + "/workflow/" + this.state.previousComponentData?.WorkflowId + "/component/" + this.state.previousComponentData?.ComponentId);
      } else {
        this.props.history.push("/app/evaluate/" + this.state.evaluation_id + "/workflow/" + this.state.workflow_id + "/component/" + this.state.component_id);
      }
    } else {
      this.props.hideConfirmCallbackDialog();
    }
  }

  showWithdrawWarningDialog(type) {
    this.setState({
      approvalConfirmType: type
    }, () => {
      this.props.showConfirmCallbackDialog();
    })
  };

  startApprovals() {
    this.props.updateApprovalInfo(this.state.evaluation_id, {
      type: 'start',
      approval_component_id: parseInt(this.state.component_id)
    })
    this.props.hideConfirmCallbackDialog();
  }

  withdrawApprovals() {
    this.props.updateEvaluationProduct(this.state.evaluation_id, this.state.product_id, { is_approved: false });
    this.props.deleteEvaluationApprovals(this.state.evaluation_id, this.props.match.params.component_id)
    this.props.hideConfirmCallbackDialog();
  }

  render() {
    const classes = this.props.classes;
    const { evaluation, user } = this.props;
    // console.log(this.props.history,'Historyyyyyyyyyyyyy')
    if (true) {
      return (
        <span className={classes.loader}>
          <CircularProgress />
        </span>
      );
    }

    if (this.props.fetchworkflowComponentDetailProgress || (!this.props.approvalCriterias && this.props.fetchApprovalCriteriasProgress) || this.props.addApprovalCriteriaProgress) {
      return (
        <span className={classes.loader}>
          <CircularProgress />
        </span>
      );
    }

    const products = this.props.evaluation?.Products.length ? this.props.evaluation?.Products : [];
    // find top ranker
    let high_score = 0;
    let topper = null;

    let adminForward = false;
    var isCreatorOrAdmin = false;
    var role = user?.Role;
    if (this.state.isWorkFlow) {
      adminForward =
        this.props.workflowComponentDetail?.AdminForward !== undefined && this.props.workflowComponentDetail?.AdminForward !== null && this.props.workflowComponentDetail?.AdminForward === true
          ? true
          : false;
    }

    if (evaluation?.UserID === user?.ID) {
      isCreatorOrAdmin = true;
    }

    if (user?.Role === "OrgAdmin" || user?.Role === 'OrgManager') {
      isCreatorOrAdmin = true;
    }

    if (products.length) {
      for (let product of products) {
        product.score = 0;
        let product_score = 0;
        if (this.props.scores[product.ID] !== undefined) {
          var score_obj = this.props.scores[product.ID];
          var totalOpted = 0;
          var totalMax = 0;
          for (var criteriId in score_obj) {
            var criteriScore = score_obj[criteriId];
            totalOpted += criteriScore.totalOpted;
            totalMax += criteriScore.totalMax;
          }
          if (totalMax !== 0) {
            product_score = Math.round(parseFloat((parseFloat(totalOpted) / parseFloat(totalMax)) * 100));
            product.score = product_score;
          }
        }
        if (product_score > high_score && product_score > 0) {
          high_score = product_score;
          topper = product.ID;
        }
      }
    }

    // filter current product
    const findProduct = products.filter((item) => item.ID === parseInt(this.state.product_id));
    const product = findProduct.length ? findProduct[0] : {};

    let allCriteria = this.props.approvalCriterias?.Response || [];
    let requiredCriteria = allCriteria.filter((item) => item.Status === 1);
    let responedIds = this.props.productApprovalDetails ? this.props.productApprovalDetails.map((item) => item.CriteriaID) : [];
    let disableCriterias = allCriteria.filter((item) => item.Status === 2);
    let isDisable = false;
    if (disableCriterias.length > 0) {
      isDisable = true;
    }

    if (this.props.approvalCriterias && this.props.approvalCriterias.Response && this.props.approvalCriterias.Response.length === 0) {
      isDisable = true
    }

    const messageDataWithdraw = {
      head: "Are you sure?",
      subHead: "Do you really want to withdraw? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    const messageDataStart = {
      head: "Are you sure?",
      subHead: "You are about to submit the request for approval. You will not be able to edit your request.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let messageData = this.state.approvalConfirmType === 'ADD' ? messageDataStart : messageDataWithdraw;


    let isShowWithdraw = false;
    if (this.props.productApprovalDetails && this.props.productApprovalDetails.length > 0) {
      let totalItms = this.props.productApprovalDetails.length;
      let totalCriterias = this.props.approvalCriterias && this.props.approvalCriterias.Response ? this.props.approvalCriterias?.Response.length : 0;
      this.props.productApprovalDetails.forEach(function (itm) {
        if (itm.Status === 4) {
          isShowWithdraw = true;
        }
      })
      if (totalCriterias !== totalItms) {
        isShowWithdraw = true;
      }
    } else {
      isShowWithdraw = true;
    }

    return (
      <div className={classes.approvalRoot}>
        {this.state.isWorkFlow ? <EvaluationBar
          isShowCollabrations={false}
          isShowEvalDetails={true}
          isWorkFlow={true}
          isShowStepper={this.props.user?.Role === "OrgAdmin" || this.props.user?.Role === "OrgManager" || this.props.evaluation?.UserID === this.props.user?.ID}
          isShowFinishSummaryBtn={true}
        /> :
          <EvaluationBar
            isWorkFlow={false}
          />}
        {/* {this.state.isWorkFlow && this.props.isCreatorOrAdmin && <EvaluationBar isShowCollabrations={true} isShowEvalDetails={true} isWorkFlow={true} />} */}

        {/* <div className={classes.workflowSummary}>
          <WorkflowSummary />
        </div> */}

        <div className={classes.root}>
          {/* <Accordion expanded={this.state.expanded === "approvels"} onChange={this.handleChange("approvels")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className={classes.headTitle}>Approvals</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}> */}
          <div className={classes.mainRoot}>
            <Typography variant="h5" className={classes.headTitle}>Approvals</Typography>

            <Grid container justify="flex-end" style={{ marginBottom: 10 }}>
              {false && (
                <SqureButton
                  variant="outlined"
                  onClick={() => {
                    const { history, match } = this.props;
                    if (match && match.params && match.params.evaluation_id) {
                      history.push(`/app/evaluate/${match.params.evaluation_id}/report`);
                    }
                  }}
                >
                  View Report
                </SqureButton>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <ProductDetails
                  component_id={this.state.component_id}
                  evaluation_id={this.state.evaluation_id}
                  product_id={this.state.product_id}
                  product={product}
                  topper={topper}
                  approvalCriterias={this.props.approvalCriterias}
                  activeCriteria={this.state.activeCriteria}
                  setActiveCriteria={this.setActiveCriteria}
                  setActiveResponse={this.setActiveResponse}
                  addApprovalCriteriaApprover={this.props.addApprovalCriteriaApprover}
                  userTimeline={this.props.userTimeline}
                  addRemoveApproverFn={(users, criteriaId) => {
                    let approver_ids = [];
                    let team_ids = [];
                    users.forEach((data) => {
                      if (data.hasOwnProperty("GroupId")) {
                        team_ids.push(data.ID);
                      } else {
                        approver_ids.push(data.ID);
                      }
                    });
                    this.props.addApprovalCriteriaApprover(this.props.match.params.evaluation_id, criteriaId, { approver_ids, team_ids, productID: Number(this.state.product_id) });
                  }}
                  addApproverProgress={this.props.addApprovalCriteriaApproverProgress}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={7} lg={8}>
                {this.state.activeCriteria && (
                  <ApprovalAction
                    product={product}
                    component_id={this.state.component_id}
                    evaluation_id={this.state.evaluation_id}
                    product_id={this.state.product_id}
                    approvalCriterias={this.props.approvalCriterias}
                    activeCriteria={this.state.activeCriteria}
                    activeResponse={this.state.activeResponse}
                    setResetStats={this.setResetStats}
                    setActiveResponse={this.setActiveResponse}
                    approvalStages={this.state.approvalStages}
                    userTimeline={this.props.userTimeline}
                    addRisk={(url) => {
                      this.props.history.push(url)
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ApprovalCriteriaThread
                  evaluationId={this.state.evaluation_id}
                  componentId={this.state.component_id}
                />
              </Grid>
            </Grid>

            {(!adminForward || (adminForward && isCreatorOrAdmin)) && (
              <BottomAction>
                <Grid container className={classes.bottomNav}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={5} style={{ textAlign: "right" }}>

                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'right' }}>

                      {isShowWithdraw && this.state.isFinalApproval && <SqureButton
                        style={{ marginRight: 10 }}
                        variant="outlined"
                        onClick={() => {
                          this.showWithdrawWarningDialog('REMOVE')
                        }}
                      >
                        Withdraw
                      </SqureButton>}

                      {this.props.updateProgress && <CircularProgress />}

                      {!this.props.updateProgress && (
                        <div className={classnames(classes.finishBtn)}>
                          <span>
                            <SqureButton
                              variant={"contained"}
                              endIcon={<Image src={ArrowForward} alt="arrow forward" />}
                              disabled={!requiredCriteria.every((item) => responedIds.includes(item.ID)) || isDisable}
                              onClick={() => {
                                if (this.state.isWorkFlow) {
                                  this.submitResponse();
                                } else {
                                  this.props.showEvaluationFinishDialog();
                                }
                              }}
                            >
                              {this.state.isWorkFlow ? this.state.nextClickLoader ? <CircularProgress style={{ width: 27, height: 27 }} /> : "Next" : "Finish"}
                            </SqureButton>
                          </span>
                        </div>
                      )}
                    </div>

                  </Grid>
                </Grid>
              </BottomAction>
            )}

            {this.props.collaboratorDialogOpen && this.props.collaboratorDialogData?.position !== "EvaluationBar" ? (
              <SearchUserAndTeamPoper {...this.props.collaboratorDialogData} />
            ) : (
              <SearchUserPoper {...this.props.collaboratorDialogData} />
            )}

            {this.props.isOpenTaskDialog && <AddUpdateTaskDialog />}

            {this.props.showAddTaskDialog && <AddUpdateVendorServiceTaskDialog />}

          </div>
          {/* </AccordionDetails>
          </Accordion> */}
        </div>

        <ConfirmCallbackDialog
          messageData={messageData}
          callBack={this.callBack}
          closeCallBack={this.onCancelWithdraw}
          closeDialog={this.onCancelWithdraw}
        />

      </div>
    );
  }
}
export default connector(compose(withStyles(styles), withLastLocation)(GetApproval));
