import { Button, Checkbox, makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from "@material-ui/core/Radio";
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from "@material-ui/core/TextField";
import DoneIcon from "@material-ui/icons/Done";
// icons
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
// components
import RatingPoper from "Components/Application/Components/Evaluate/Components/Evaluation/InProgress/RatingPoper.jsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Creatable from "react-select/creatable";
import { addRequirementResponse } from "redux/evaluation/criteria/action";
// redux
import { showSnackBar } from "redux/snackbar/action";
import { CommonFn } from "services/commonFn";
import { generate } from "shortid";
import _ from "underscore";
import * as Environment from "util/Environment";

// Component styles
const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: '16px',
    width: "100%",
    "& ul.answers": {
      listStyle: "none",
      paddingLeft: 0,
      "& li": {
        border: "1px solid #EBEBEB",
        bordeRadius: "4px",
        paddingLeft: "8px",
        marginBottom: "8px",
        "& span": {
          fontSize: 13,
          color: "#6D7278",
        },
      },
    },
  },
  title: {
    color: "#AFAFAF",
    fontSize: "12px",
    marginBottom: "16px",
    display: "block",
  },
  textArea: {
    background: "#F7F7F7 0% 0% no-repeat padding-box",
    border: "1px solid #EBEBEB",
    borderRadius: "4px",
    padding: "8px",
    width: "100%",
  },
  dropDown: {
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    placeContent: "start",
    alignItems: "center",
    // maxWidth: '250px',
    "& label": {
      // fontSize: '12px',
      color: "#707070",
      marginRight: 30,
    },
    "& [class*='MuiSelect-root-']": {
      padding: "0.5em 0.75em",
      fontSize: "12px",
      color: "#707070",
      paddingRight: "32px",
    },
  },
  chips: {
    marginBottom: 16,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  answerChips: {
    margin: "16px 0",
    "& [class*='MuiChip-root-']": {
      marginRight: "5px",
      marginBottom: "5px",
      "&.otherChip": {
        backgroundColor: "#49B8FA",
        color: "#ffff",
        "& [class*='MuiChip-deleteIconSmall-']": {
          color: "#fff",
        },
      },
    },
  },
  addOtherEditIcon: {
    position: "absolute",
    right: "6px",
    top: "10px",
    cursor: "pointer",
    "& svg": {
      width: "16px",
    },
  },
  addOtherSaveIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
    "& svg": {
      width: "20px",
    },
    backgroundColor: "transparent",
    padding: 0,
    border: "none",
  },
  addOtherCloseIcon: {
    position: "absolute",
    right: "6px",
    top: "18px",
    cursor: "pointer",
    "& svg": {
      width: "20px",
    },
  },
}));

// redux props & actions
const connectedProps = (state) => ({
  evaludationCriteria: state.evaludationCriteria,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  addRequirementResponse: addRequirementResponse,
};

var connector = connect(connectedProps, connectionActions);

function AnswerReply(props) {
  const classes = useStyles();
  const [ratingPoperEl, setRatingPoperEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [filename, setFilename] = useState("");
  const { requirement, onChange, userOptions,fetchResponseCounter, maxScore } = props;
  const [editOther, setEditOther] = useState(false);
  const [otherOption, setOtherOption] = useState(false);
  const [otherOptionText, setOtherOptionText] = useState("");
  const type = requirement?.answer_type;
  let answers = requirement?.options;

  // console.log('=============================================')
  // console.log(requirement, 'requirement')
  // console.log(selectedOption, 'selectedOption555')
  // console.log(response,'response')
  // console.log(userOptions,'userOptions')

  useEffect(() => {
    // console.log(userOptions,'userOptions6565656565')
    if (type === 2 || type === 3) {
      var selectedValue;
      if(userOptions !== undefined && userOptions !== null  && userOptions !== ''){
        selectedValue = userOptions.filter((o) => o.Answer === "").map((i) => {
          return i.OptionID;
        });
      } 

      const OtherOption = userOptions.filter((o) => o.Answer !== "");
      if (OtherOption.length) {
        setOtherOption(true);
        setOtherOptionText(OtherOption[0].Answer);
      } else {
        setOtherOption(false);
        setOtherOptionText("");
      }
      selectedValue = _.uniq(selectedValue);
      if (type === 3) {
        setSelectedOption(selectedValue);
      } else {
        setSelectedOptions(selectedValue);
      }
    } else if (type === 4) {
      if (userOptions.length > 0) {
        setSelectedOption(String(userOptions[0].Answer));
      }
    } else if (type === 5) {
      // console.log(userOptions,'userOptionssss')
      if (userOptions.length > 0) {
        setSelectedOption(String(userOptions[0].Answer));
      }else{
        setSelectedOption('');
      }
    } else if (type === 6) {
      if (userOptions.length > 0) {
        let fileUrl = userOptions[0].Answer || "";
        if (validURL(fileUrl)) {
          setSelectedOption(String(fileUrl));
          let filename = new URL(fileUrl).pathname.split("/").pop();
          setFilename(filename);
        }
      }
    }
    if (
      props.evaludationCriteria.addResponseProgress === false &&
      props.evaludationCriteria.successResponseAdd
    ) {
      props.showSnackBar("Response updated", "success", 1500);
    }
  }, [props, type, userOptions, fetchResponseCounter]);

  const validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  const updateAnswer = (item, isOtherOption) => {
    if (type === 3) {
      if (item) {
        if (isOtherOption === 'DeleteItem') {
          setSelectedOption([]);
          onChange([]);
        } else {
          if (selectedOption.indexOf(item.ID) > -1) {
            setSelectedOption([]);
            onChange([parseInt(item.ID)]);
          } else {
            setSelectedOption([parseInt(item.ID)]);
            onChange([parseInt(item.ID)]);
          }
        }
      } else {
        onChange([], isOtherOption);
      }
      return;
    }
    if (item) {
      let options = [];
      if (selectedOptions.indexOf(item.ID) !== -1) {
        selectedOptions.splice(selectedOptions.indexOf(item.ID), 1);
      } else {
        selectedOptions.push(item.ID);
      }
      options = _.uniq(selectedOptions);
      setSelectedOptions([...options]);
      onChange(options);
    } else {
      onChange(selectedOptions, isOtherOption);
    }
  };

  const _userOptions = _.map(props.userOptions, "OptionID");
  const fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const { OrgID, evaluation_id, requirement } = props;
      const file = event.target.files[0];
      var formData = new FormData();
      formData.append(
        "folder",
        `${OrgID}/evaluation/${evaluation_id}/requirement/${requirement.id}`
      );
      const timestamp = new Date().getTime();
      formData.append(
        "file",
        file,
        `${timestamp}.${file.name.substring(file.name.indexOf(".") + 1)}`
      );
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;

      axios.post(url, formData, {
        headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        onChange(null, `${response.data.filepath}`);
        props.showSnackBar("File uploaded", "success", 1500);
      })
        .catch(err => {
          if (err.response) {
            props.showSnackBar(err.response.data.error, "error", 3000);
          } else if (err.request) {
            props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          } else {
            props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          }
        })
    }
  };

  const Options = answers
    .filter((o) => !_userOptions.includes(o.ID))
    .map((item) => ({
      value: item.ID,
      label: item.Title,
    }));
  const otherOptionCreatable =
    requirement && requirement.is_other_option && otherOption
      ? []
      : [
        {
          value: "Other",
          label: "Other",
        },
      ];

  return (
    <div className={classes.root} key={'answerReply'+requirement?.id}>
      {/* Multi and sigle select */}
      {(type === 2 || type === 3) && (
        <>
          {/* Answers < 5 */}
          {answers.length <= 5 && (
            <>
              <span className={classes.title}>
                Select one{" "}
                {type === 2 ? "or more options" : "option from below"}
              </span>
              <ul className={"answers"}>
                {type === 2 &&
                  answers.map((item, i) => (
                    <li key={i}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          boxSizing: "border-box",
                          placeContent: "center flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          id={item.ID}
                          checked={selectedOptions.indexOf(item.ID) !== -1}
                          name={item.ID}
                          color="primary"
                          onChange={() => {
                            let options = [];
                            if (selectedOptions.indexOf(item.ID) !== -1) {
                              selectedOptions.splice(
                                selectedOptions.indexOf(item.ID),
                                1
                              );
                            } else {
                              selectedOptions.push(item.ID);
                            }
                            options = _.uniq(selectedOptions);
                            setSelectedOptions([...options]);
                            onChange(options, otherOptionText);
                          }}
                        />
                        <label htmlFor={item.ID}>{item.Title}</label>
                      </div>
                    </li>
                  ))}
                {type === 3 &&
                  answers.map((item, i) => (
                    <li key={i}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          boxSizing: "border-box",
                          placeContent: "center flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          value={String(item.ID)}
                          checked={selectedOption.includes(item.ID)}
                          inputProps={{
                            name: "RadioData",
                            "data-sequence": item.Sequence,
                          }}
                          onChange={(e) => {
                            setSelectedOption([parseInt(e.target.value)]);
                            setOtherOption(false);
                            onChange([parseInt(e.target.value)], "");
                          }}
                        />
                        <label htmlFor={item.ID}>{item.Title}</label>
                      </div>
                    </li>
                  ))}
                {requirement && requirement.is_other_option && (
                  <li key={generate()}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        boxSizing: "border-box",
                        placeContent: "center flex-start",
                        alignItems: "center",
                      }}
                    >
                      {type === 3 && (
                        <Radio
                          value="Other"
                          checked={otherOption}
                          inputProps={{
                            name: "RadioData",
                            "data-sequence": "data",
                          }}
                          onChange={(e) => {
                            setOtherOption(true);
                            setEditOther(true);
                            onChange([], otherOptionText || 'Other');
                          }}
                        />
                      )}
                      {type === 2 && (
                        <Checkbox
                          id="other"
                          checked={otherOption}
                          name="other"
                          color="primary"
                          onChange={(e) => {
                            if (!otherOption) {
                              setOtherOption(true);
                              setEditOther(true);
                              onChange(selectedOptions, otherOptionText || 'Other');
                            } else {
                              setOtherOption(false);
                              setOtherOptionText("");
                              onChange(selectedOptions, "remove");
                            }
                          }}
                        />
                      )}
                      <label htmlFor="otherOption">
                        <b>Other:</b>
                      </label>
                      {otherOption && (
                        <OtherTextField
                          classes={classes}
                          value={otherOptionText === 'Other' ? '' : otherOptionText}
                          editOther={editOther}
                          onEditOther={(value) => setEditOther(value)}
                          placeholder={'Other'}
                          onClick={(value) => {
                            setOtherOption(value ? true : false);
                            setOtherOptionText(value);
                            let options = _.uniq(
                              type === 3 ? selectedOptions : selectedOptions
                            );
                            onChange(options, value || "remove");
                            setEditOther(false);
                          }}
                        />
                      )}
                    </div>
                  </li>
                )}
              </ul>
            </>
          )}

          {answers.length > 5 && (
            <>
              <div className={classes.answerChips}>
                {answers.map((item, i) => {
                  if (_userOptions.includes(item.ID)) {
                    return (
                      <Chip
                        size="small"
                        label={item.Title}
                        onDelete={(e) => {
                          updateAnswer(item, 'DeleteItem');
                        }}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
                {requirement && requirement.is_other_option && otherOption && (
                  <Chip
                    size="small"
                    label="Other"
                    onDelete={(e) => {
                      setOtherOption(false);
                      updateAnswer(false, "remove");
                    }}
                  />
                )}
              </div>
              <div className={classes.searchAndSelect}>
                <Creatable
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  isSearchable={true}
                  options={[...Options, ...otherOptionCreatable]}
                  value={""}
                  className={classes.formControl}
                  classNamePrefix="select"
                  placeholder="Select one or more options from below"
                  name="answer_options"
                  onChange={(e) => {
                    if (e && e.value && e.value === "Other") {
                      setOtherOption(true);
                      updateAnswer(false, " ");
                    } else if (e && e.value) {
                      let item = {};
                      item["ID"] = e.value;
                      item["Title"] = e.label;
                      updateAnswer(item);
                    }
                  }}
                  formatCreateLabel={(userInput) => `Add Other: ${userInput}`}
                  menuPortalTarget={document.body}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999999 }),
                  }}
                />
              </div>
            </>
          )}

        </>
      )}

      {type === 4 &&
        <>
          <span className={classes.title}>Select one option from below</span>
          <RadioGroup name={`option_${requirement.id}`} value={String(selectedOption)} onChange={(e) => {
            setSelectedOption(e.target.value)
            onChange(e.target.value)
          }}>
            <ul className={"answers"}>
              {answers.map((item, k) => {
                return <>
                  <li key={k}><FormControlLabel value={item.Description} control={<Radio />} label={item.Title} /></li>
                </>
              })}
              {/* // <li  key={1}><FormControlLabel value='Yes' control={<Radio />} label='Yes' /></li>
                // <li  key={2}><FormControlLabel value='No' control={<Radio />} label='No' /></li> */}
            </ul>
          </RadioGroup>
        </>
      }


      {/* Score */}
      {type === 5 && (
        <div
          style={{
            display: "flex",
            padding: "4px 21px",
            background: "#f9f9f9",
            boxSizing: "border-box",
            alignItems: "center",
            borderRadius: "8px",
            placeContent: "center space-between",
            flexDirection: "row",
            maxWidth: "200px",
          }}
          key={'scoreBox_'+requirement?.id}
        >
          <label>Response</label>&nbsp;&nbsp;
          <Button
            variant="outlined"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              setRatingPoperEl(event.currentTarget);
            }}
          >
            {selectedOption ? selectedOption + "" : "+ Score"}
          </Button>
          <RatingPoper
            value={selectedOption ? selectedOption : 0}
            anchorEl={ratingPoperEl}
            key={'ratingPoper_'+requirement?.id}
            maxScore={maxScore}
            onClose={() => {
              setRatingPoperEl(null);
            }}
            onSave={(value) => {
              onChange(null, `${value}`);
              setRatingPoperEl(null);
            }}
          />
        </div>
      )}

      {/* File Upload */}
      {type === 6 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "16px",
          }}
        >
          {selectedOption && (
            <a
              href={selectedOption}
              target="_blank"
              style={{
                marginBottom: "25px",
                display: "inline-block",
                textDecoration: "underline",
                color: "#4A87F8",
              }}
              rel="noreferrer"
            >
              {filename}
            </a>
          )}
          <input
            id="uploadRequirementResponse"
            onChange={fileSelectHandler}
            type="file"
          />
        </div>
      )}
    </div>
  );
}

export default connector(AnswerReply);

const OtherTextField = ({
  classes,
  value,
  onClick,
  editOther,
  onEditOther,
  placeholder
}) => {
  const [optionValue, setOptionValue] = useState(value);
  useEffect(() => {
    setOptionValue(value);
  }, [value]);
  return (
    <div
      style={{
        flex: 1,
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      {editOther && (
        <TextField
          style={{
            width: "80%",
            marginLeft: 10,
            color: "#04363D",
            fontSize: 13,
          }}
          value={optionValue}
          placeholder={placeholder}
          onChange={(e) => setOptionValue(e.target.value)}
        />
      )}
      {!editOther && (
        <p
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "80%",
            marginLeft: 10,
          }}
        >
          {optionValue}
        </p>
      )}
      {!editOther && (
        <span
          onClick={() => {
            onEditOther(true);
          }}
          className={classes.addOtherEditIcon}
        >
          <EditIcon />
        </span>
      )}
      {editOther && (
        <button
          disabled={/^ *$/.test(optionValue)}
          className={classes.addOtherSaveIcon}
          onClick={() => onClick(optionValue)}
        >
          <DoneIcon />
        </button>
      )}
    </div>
  );
};
