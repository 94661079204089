import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { Checkbox } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, Grid
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import Image from "Components/Common/image.jsx";
import { addWorkflowComponentUserResponse } from "redux/workflow/action";
import BottomActions from './BottomActions';
import { updateProductFromCart } from "redux/evaluation/cart/action";

const connectedProps = (state) => ({
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
});

const connectionActions = {
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  updateProductFromCart: updateProductFromCart
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    background: '#fff',
    padding: 20,
    height: '89.5vh',
    overflow: 'scroll',
    width: '80%',
    margin: '0px auto'
  },
  actions: {
    textAlign: "center",
    marginTop: theme.spacing(2)
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  title: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
    margin: '20px 0px'
  },
  productCard: {
    minWidth: 120,
    maxWidth: 120,
    height: '100%',
    border: '1px solid #E2E2E2',
    borderRadius: 10,
    opacity: 1,
    padding: '12px 5px',
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    aligmItems: 'center',
    justifyContent: 'space-between'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    '& > img': {
      width: 40,
      height: 'auto',
      marginBottom: 10,
    }
  },
  productImg: {

  },
  productName: {
    color: '#707070',
    fontSize: 13,
    marginBottom: 9,
    fontWeight: 600,
    textAlign: 'center',
  },
  selected: {
    boxShadow: '0px 3px 25px #398AF526',
    border: '1px solid #398AF5',
    background: '#f1f1f1'
  },
  productShortlisted: {
    marginTop: '20px 0px',
  },
  footer: {
    textAlign: 'right'
  },
  selectBox: {
    position: 'absolute',
    top: 3,
    left: 3,
    padding: 0
  }
});

class ProductShortlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      feedback: "",
      feedback_error: "",
      products: [],
      selectedProducts: []
    }
    this.avatarName = this.avatarName.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.submitResponse = this.submitResponse.bind(this);
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  componentDidMount() {
    this.initProductsList()
  }

  componentDidUpdate(prevProps) {

  }

  initProductsList() {
    var products = this.props.evaluation !== undefined && this.props.evaluation?.Products !== undefined && this.props.evaluation?.Products.length > 0 ? this.props.evaluation?.Products : [];
    this.setState({
      products: products
    })
  }

  setSelected(id) {
    var selectedProducts = [...this.state.selectedProducts];
    if (selectedProducts.indexOf(id) === -1) {
      selectedProducts.push(id);
    } else {
      selectedProducts.splice(selectedProducts.indexOf(id), 1);
    }
    this.setState({
      selectedProducts: selectedProducts
    })
  }

  submitResponse() {
    var responseDatas = [];
    this.props.workflowComponentsForms.forEach((data) => {
      if (data.IsConfiguration === false) {
        var obj = {
          "form_id": data.ID
        }
        var value = '';
        if (data.Name === 'evaluation.name') {
          value = this.state.name;
        }
        obj['value'] = value;
        responseDatas.push(obj);
      }
    });

    this.props.addWorkflowComponentUserResponse(this.props.match.params.evaluation_id, this.props.workflowComponentId, {
      "data": responseDatas,
      "forward":true
    });
    // this.props.showFeedbackTakeDialog();
  }

  render() {
    const classes = this.props.classes;
    let { products, selectedProducts } = this.state;
    const { evaluation } = this.props;

    return <div className={classes.root}>
      <div classes={classes.dialogContent} >
        <Typography variant={"h4"} className={classes.title}>Product Shortlist</Typography>
        <div className={classes.productShortlisted}>
          <Grid container spacing={6}>
            {products.map((product) => {
              return <Grid item>
                <div
                  className={classnames(classes.productCard, selectedProducts.indexOf(product.ID) !== -1 ? classes.selected : {})}
                >
                  <Checkbox 
                    className={classes.selectBox} 
                    onChange={(e) => {
                      this.props.updateProductFromCart(
                        evaluation.ID,
                        product.EntityObjectID,
                        { is_shortlisted: e.target.checked }
                      )
                      this.setSelected(product.ID)
                    }} 
                    checked={selectedProducts.includes(product.ID)} 
                  />
                  <div className={classes.logoContainer}>
                    <Image className={classes.productImg} src={product.ProductLogo} alt={product.Name} />
                  </div>
                  <Typography className={classes.productName}>{product.Name}</Typography>
                </div>
              </Grid>
            })}
          </Grid>
        </div>
      </div>
      <BottomActions
        submitResponse={this.submitResponse}
      />
    </div>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ProductShortlist));