import {
    SHOW_PRODUCT_CLAIM_DIALOG, HIDE_PRODUCT_CLAIM_DIALOG,
    START_CLAIM_PRODUCT, END_CLAIM_PRODUCT,
    START_FETCH_SELLER_PRODUCT, END_FETCH_SELLER_PRODUCT,
} from "./action";

const initState = {
    claimProductInitQuery: null,
    claimProductDialogOpen: false,

    claimProductProg: false,
    claimProductError: null,

    sellerProductProg: false,
    sellerProductError:null,
    sellerProducts: {},
}

export function sellerProductReducer(state = initState, action) {
    switch (action.type) {
        case SHOW_PRODUCT_CLAIM_DIALOG:
            let query = {}
            if (action.payload !== null) {
                query.name = action.payload.name
            }
            return {
                ...state,
                claimProductDialogOpen: true,
                claimProductInitQuery: query
            };

        case HIDE_PRODUCT_CLAIM_DIALOG:
            return {
                ...state,
                claimProductDialogOpen: false
            };

        case START_CLAIM_PRODUCT:
            return {
                ...state,
                claimProductProg: true,
                claimProductError:null
            }

        case END_CLAIM_PRODUCT: {
            return {
                ...state,
                claimProductProg: false,
                claimProductError:action.payload.error
            }
        }

        case START_FETCH_SELLER_PRODUCT:
            return {
                ...state,
                sellerProductProg: true,
                sellerProductError:null
            }
        case END_FETCH_SELLER_PRODUCT: {
            const { success, error } = action.payload
            let sellerProducts = state.sellerProducts
            if (error === null) {
                if (success.page === 0) {
                    sellerProducts = success
                } else {
                    sellerProducts.Data = [...sellerProducts.Data, ...success.Data]
                }
            }
            return {
                ...state,
                sellerProductProg: false,
                sellerProductError:action.payload.error,
                sellerProducts,
            }
        }

        default:
            return state;
    }
}  