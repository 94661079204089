import { Button, CircularProgress, ClickAwayListener, Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { EditOutlined as EditIcon, Search as SearchIcon, Settings } from "@material-ui/icons";
// import FolderIcon from '@material-ui/icons/Folder';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import RightArrow from "assets/images/right-arrow.svg";
import axios from 'axios';
import classnames from "classnames";
import CommonCss from "commonCss";
import CartDrawer from "Components/Application/CartDrawer";
import MetricVendor from "Components/Application/Components/Metrics/MetricVendor";
import AddUpdateTaskDialog from 'Components/Common/Dialogs/AddUpdateTaskDialog';
import Image from "Components/Common/image.jsx";
import MessageDialog from "Components/Common/MessageDialog";
import DialogOrgProductChampions from "Components/Dialogs/DialogOrgProductChampions";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import { withAsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";
import { compose } from "recompose";
import { size } from 'underscore';
// import { Link } from "react-router-dom";

import {
  hideMessageDialog, showMessageDialog
} from "redux/applications/action";
import { authorizedUser } from "redux/authuser/action";
import { hideProductDetailsDialog, hideSearchExternalUsersPoperDialog, showRequestAccessInformationDialog } from "redux/dialogs/action";
import { updateDrawerStyle } from "redux/drawer/action";
import {
  fetchCompletedEvaluation,
  fetchInProgressEvaluation, showAddNewEvaluationDialog
} from "redux/evaluate/action";
import {
  createEvaluationCart, fetchEvaluationCart, removeProductFromCart
} from "redux/evaluation/cart/action";
import { fetchProductCompletedRequests, fetchProductInprogressRequests } from "redux/evaluation/requests/action";
import { addComplianceCert, addPii } from "redux/master/action";
import { fetchAllOrgSettings } from "redux/org/user/action";
import { deleteProductState, fetchProductDetails } from "redux/product/action";
// Redux
import {
  deleteProduct,
  fetchCompanyProductDetails,
  fetchComplementaryProducts,
  fetchOrgProductAlternatives,
  fetchTabsSectionsData,
  hideAddOrgProductChampionsDialog,
  hideAddProductWorkflowDialog,
  showAddOrgProductChampionsDialog,
  showAddProductWorkflowDialog
} from "redux/product/orgProduct/action";
import {
  addOrgContactDetails,
  addOrgDocument,
  addOrgSecurityCertDoc,
  fetchOrgProductDetails,
  fetchOrgProductTabs,
  orgProductAddVendorPoc,
  updateOrgProductDetails,
  updateOrgSecurityCert
} from "redux/product/orgProduct/action.js";
import CollaboratorPopper from './Common/CollaboratorPopper';

import { addProductExternalCollaborator, fetchExternalCollaboratorProductTabs, removeProductExternalCollaborator } from "redux/externalCollaborator/action";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import { showSnackBar } from "redux/snackbar/action";
import { searchUser } from "redux/usersearch/action";
//shared
import { CommonFn } from "services/commonFn";
import { isEnabledReview } from "util/Common";
import * as Environment from "util/Environment";
// assets
//components
import DialogOrgProductRequestAccess from "Components/Dialogs/DialogOrgProductRequestAccess";
import DialogOrgProductWorkflow from "Components/Dialogs/DialogOrgProductWorkflow";
import { hideStartNewWorkflowDialg, startNewWorkflow } from 'redux/workflow/action';
import { isJson } from "util/Validation";
import AppBarV2 from "../../Application/AppBarV2";
import StartWorkflowDialog from "../Dialogs/StartWorkflowDialog";
import SearchExternalUserPoper from "../TeamsAndUser/SearchExternalUserPoper";
import ContactTab from "./ContactTab";
import CustomSection from "./CustomSection";
import RequestAccessInformation from "./Dialogs/RequestAccessInformation";
import FinanceTab from "./FinanceTab";
import OrgProductDetailsTab from "./OrgProductDetailsTab";
import RenewalTab from "./Renewal";
import SecurityAndPrivacyTab from "./SecurityAndPrivacyTab";
import SurveyTab from "./SurveyTab";
import TrendAnalysisTab from "./TrendAnalysisTab";

const CreatableAsyncPaginate = withAsyncPaginate(Select);

const connectedProps = (state) => ({
  user: state.authUser.user,
  orgUser: state.orgUser,
  productDetails: state.orgProduct.orgProductDetails,
  orgProductDetailsFetchProgress:
    state.orgProduct.orgProductDetailsFetchProgress,
  orgProductDetailsFetchError:
    state.orgProduct.orgProductDetailsFetchError,
  orgProductStates: state.orgProduct.orgProductStates,
  fetchProductAlternativesUnderProgress:
    state.orgProduct.fetchProductAlternativesUnderProgress,
  productAlternatives: state.orgProduct.productAlternatives,
  fetchCompanyProductsUnderProgress:
    state.orgProduct.fetchCompanyProductsUnderProgress,
  companyProducts: state.orgProduct.companyProducts,
  evaluationCart: state.evaluationCart.evaluation,

  fetchComplementaryProductsUnderProgress:
    state.orgProduct.fetchComplementaryProductsUnderProgress,
  complementaryProducts: state.orgProduct.complementaryProducts,
  ProductChampUsers: state.userSearch.users,
  inProgressEvaluationProgress: state.evaluate.inProgressEvaluationProgress,
  inProgress: state.evaluate.inProgress,
  inProgressTotal: state.evaluate.inProgressTotal,
  completedEvaluationProgress: state.evaluate.completedEvaluationProgress,
  completed: state.evaluate.completed,
  completedTotal: state.evaluate.completedTotal,
  orgBussinessPocDetailsFetchProgress:
    state.orgProduct.orgBussinessPocDetailsFetchProgress,
  orgBussinessPocDetails: state.orgProduct.orgBussinessPocDetails,
  orgVendorPocDetails: state.orgProduct.orgVendorPocDetails,
  orgVendorPocDetailsFetchProgress:
    state.orgProduct.orgVendorPocDetailsFetchProgress,
  orgProductDetailsUpdateProgress:
    state.orgProduct.orgProductDetailsUpdateProgress,
  orgProductGroupAddProgress: state.orgProduct.orgProductGroupAddProgress,

  fetchInprogressProductRequestProgress: state.evaluationRequestData.fetchInprogressProductRequestProgress,
  fetchInprogressProductRequestError: state.evaluationRequestData.fetchInprogressProductRequestError,
  requestInprogressProductData: state.evaluationRequestData.requestInprogressProductData,

  fetchCompletedProductRequestProgress: state.evaluationRequestData.fetchCompletedProductRequestProgress,
  fetchCompletedProductRequestError: state.evaluationRequestData.fetchCompletedProductRequestError,
  requestCompletedProductData: state.evaluationRequestData.requestCompletedProductData,

  userType: state.authUser.user?.Role,
  orgIntegration: state.orgProduct.orgIntegration,
  orgSimilarProduct: state.orgProduct.orgSimilarProduct,
  orgSameProduct: state.orgProduct.orgSameProduct,
  orgComplementaryProduct: state.orgProduct.orgComplementaryProduct,
  orgSubCategories: state.orgProduct.orgSubCategories,
  orgProductDepartment: state.orgProduct.orgProductDepartment,
  orgFeatures: state.orgProduct.orgFeatures,
  orgPlatform: state.orgProduct.orgPlatform,
  orgCategories: state.orgProduct.orgCategories,
  orgContactDetails: state.orgProduct.orgContactDetails,
  deleteProductProgress: state.orgProduct.deleteProductProgress,
  orgAlternativeProducts: state.orgProduct.orgAlternativeProducts,
  deleteOrgCategoriesProgress: state.orgProduct.deleteOrgCategoriesProgress,
  orgProductDetailTabs: state.orgProduct.orgProductDetailTabs,
  isLoadingTab: state.orgProduct.orgProductDetailTabsProgress,
  orgFetchSecurityCert: state.orgProduct.orgFetchSecurityCert,
  orgFetchPii: state.orgProduct.orgFetchPii,
  orgFetchSecurityCertDocs: state.orgProduct.orgFetchSecurityCertDocs,
  productPrice: state.orgProduct.productPrice,
  orgFinanceDetails: state.orgProduct.orgFinanceDetails,
  productSurveys: state.survey.productSurveys,
  productCompletedSurveys: state.survey.productCompletedSurveys,
  showNewWorkflowStartDialog: state.workflow.showNewWorkflowStartDialog,
  isOpenUserDialog: state.dialogs.isShowSearchExternalUserPoperDialog,
  additionalDataForExternalUser: state.dialogs.searchExternalUserPoperAdditionData,
  activeContractDocument: state.orgProduct.activeContractDocument,
  orgFetchDocuments: state.orgProduct.orgFetchDocuments,
  orgExisingSimilarProducts: state.orgProduct.orgExisingSimilarProducts,
  orgFinanceDetailsProgress: state.orgProduct.orgFinanceDetailsProgress,
  orgFetchDocumentsProgress: state.orgProduct.orgFetchDocumentsProgress,

  externalCollaboratorAddProgress: state.externalCollaborator.externalCollaboratorAddProgress,
  externalCollaboratorAddError: state.externalCollaborator.externalCollaboratorAddError,
  externalCollaboratorAddSuccess: state.externalCollaborator.externalCollaboratorAddSuccess,

  externalCollaboratorRemoveProgress: state.externalCollaborator.externalCollaboratorRemoveProgress,
  externalCollaboratorRemoveError: state.externalCollaborator.externalCollaboratorRemoveError,
  externalCollaboratorRemoveSuccess: state.externalCollaborator.externalCollaboratorRemoveSuccess,

  externalProductTabs: state.externalCollaborator.externalProductTabs,
  externalProductTabsFetchProgress: state.externalCollaborator.externalProductTabsFetchProgress,
  externalProductTabsFetchError: state.externalCollaborator.externalProductTabsFetchError,

  isOpenTaskDialog: state.productTasks.showAddTaskDialog,

  fetchExpentDirectoryProductsProgress: state.expentDirectory.fetchExpentDirectoryProductsProgress,
  fetchExpentDirectoryProductsError: state.expentDirectory.fetchExpentDirectoryProductsError,
  expentDirectoryProducts: state.expentDirectory.products,

  contactDetailLinks: state.orgProduct.contactDetailLinks,
  orgProductDetailTabsSectionItemData: state.orgProduct.orgProductDetailTabsSectionItemData,

  orgProductDetailTabsSectionItemDataProgress: state.orgProduct.orgProductDetailTabsSectionItemDataProgress,

  isOpenReqAccInfoDialog: state.dialogs.isShowRequestAccInfoDialog,

  startNewWorkflowProgress: state.workflow.startNewWorkflowProgress,
  startNewWorkflowError: state.workflow.startNewWorkflowError,
  workflowData: state.workflow.workflowData,
});

const connectionActions = {
  fetchAllOrgSettings: fetchAllOrgSettings,
  updateDrawerStyle: updateDrawerStyle,
  fetchProductDetails: fetchProductDetails,
  fetchOrgProductAlternatives: fetchOrgProductAlternatives,
  fetchComplementaryProducts: fetchComplementaryProducts,
  fetchCompanyProductDetails: fetchCompanyProductDetails,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
  showSnackBar: showSnackBar,
  hideProductDetailsDialog: hideProductDetailsDialog,
  searchProductChampUser: searchUser,
  fetchCompletedEvaluation: fetchCompletedEvaluation,
  fetchInProgressEvaluation: fetchInProgressEvaluation,
  fetchOrgProductDetails: fetchOrgProductDetails,
  updateOrgProductDetails: updateOrgProductDetails,
  addOrgContactDetails: addOrgContactDetails,
  orgProductAddVendorPoc: orgProductAddVendorPoc,
  updateOrgSecurityCert: updateOrgSecurityCert,
  addPii: addPii,
  hideStartNewWorkflowDialg: hideStartNewWorkflowDialg,
  addComplianceCert: addComplianceCert,
  addOrgDocument: addOrgDocument,
  showAddOrgProductChampionsDialog: showAddOrgProductChampionsDialog,
  showAddNewEvaluationDialog: showAddNewEvaluationDialog,
  hideAddOrgProductChampionsDialog: hideAddOrgProductChampionsDialog,
  authorizedUser: authorizedUser,
  addOrgSecurityCertDoc: addOrgSecurityCertDoc,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  deleteProduct: deleteProduct,
  fetchOrgProductTabs: fetchOrgProductTabs,
  deleteProductState: deleteProductState,
  fetchTabsSectionsData: fetchTabsSectionsData,
  hideSearchExternalUsersPoperDialog: hideSearchExternalUsersPoperDialog,
  addProductExternalCollaborator: addProductExternalCollaborator,
  removeProductExternalCollaborator: removeProductExternalCollaborator,
  fetchExternalCollaboratorProductTabs: fetchExternalCollaboratorProductTabs,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  fetchProductInprogressRequests: fetchProductInprogressRequests,
  fetchProductCompletedRequests: fetchProductCompletedRequests,
  showRequestAccessInformationDialog: showRequestAccessInformationDialog,
  startNewWorkflow: startNewWorkflow,
  hideAddProductWorkflowDialog: hideAddProductWorkflowDialog,
  showAddProductWorkflowDialog: showAddProductWorkflowDialog
};

let userRole = ["OrgAdmin", "OrgManager"];
var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
    },
    ...CommonCss.ProductDetails,
    contentSection: {
      ...CommonCss.ProductDetails.contentSection,
      height: "calc(100vh - 314px)",
    },
    ProductNameSection: {
      ...CommonCss.ProductDetails.ProductNameSection,
      justifyContent: 'space-between',
      maxWidth: '100%',
    },
    tabSection: {
      marginBottom: 17,
    },
    formGroup: {
      marginTop: 5,
    },
    label: {
      marginBottom: 5,
      display: "block",
    },
    creatable: {
      padding: 0,
    },
    searchAndSelect: {
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
        minHeight: 44,
      },
      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    uploadImageLabel: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    uploadImage: {
      width: 0,
      overflow: "hidden",
    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 19,
      margin: "0px !important",
    },

    productTitleSection: {
      ...CommonCss.productTitleSection,
      marginTop: 40,
      marginBottom: 10,
    },
    ...CommonCss.Home,
    evaluateCard: {
      ...CommonCss.Home.evaluateCard,
      padding: "24px 24px",
      border: "1px solid #ddd",
      position: "relative",
    },
    evaluateType: {
      borderRadius: "5px",
      position: "absolute",
      textAlign: "left",
      fontSize: "11px",
      padding: "3px 10px",
      right: "0px",
      top: "0px",
    },
    isEvaluate: {
      background: "#E6FFF3",
      color: "#2EB77D",
    },
    isReview: {
      background: "#cce5ff",
      color: "#004085",
    },
    showMore: {
      display: "inline-block",
      border: "1px solid #FFF",
      cursor: "pointer",
      padding: "10px 14px",
      fontSize: "12px",
      background: "#4A87F8",
      borderRadius: "20px",
      color: "white",
    },
    DashboardNoDataLayout: {
      width: "calc(100% - 10px)",
      margin: "22px 0px 0px 0px",
      minHeight: 450,
      textAlign: "center",
      background: "#ffffff",
      padding: "23px",
      borderRadius: "6px",
      "& button": {
        margin: "20px 0",
      },
    },
    orgProductTab: {
      borderBottom: "1px solid #d8d8d8",
      "&:hover": {
        // background: '#cadbff',
      },
    },
    bottomHead: {
      ...CommonCss.ProductDetails.bottomHead,
      marginBottom: 0
    },
    tickMark: {
      color: "#4b86f8",
      border: "1px solid #4b86f8",
      marginRight: 10,
      minWidth: '50px !important',
      "&:hover": {
        color: "#4b86f8",
      }
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 16,
      marginBottom: 5,
      cursor: "pointer"
    },
    reviewFrequency: {
      alignItems: 'center',
      marginBottom: "5px",
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
        minHeight: 44,
      },
      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    productSection: {
      display: 'flex',
      alignItems: 'center'
    },
    itemSection: {
      border: '1px solid #d8d8d8',
      padding: 17,
      borderRadius: 5,
      background: '#FFF',
      marginBottom: 17,
      "& [class*=PricingSection-head]": {
        color: '#6C6C6C',
        fontSize: 18,
        opacity: 1,
        fontWeight: 600,
        margin: 0,
        marginBottom: 11
      },
    },
    head: {
      color: '#6C6C6C',
      fontSize: 18,
      opacity: 1,
      fontWeight: 600,
      margin: 0,
      marginBottom: 11
    },
    productLeftSection: {
      maxWidth: 200,
      width: 200,
      minWidth: 'auto',
      position: 'sticky',
      top: 20,
      alignSelf: 'flex-start'
    },
    accessbtn: {
      display: 'flex',
      alignItems: 'center',
      '& > botton': {
        color: 'white !important'
      },

      '& > a': {
        color: 'white !important'
      }
    },
    requestAccessBtn: {
      marginLeft: 20
    },
    searchArea: {
      flex: '1 !important',
      padding: '0px 10px'
    },
    searchInput: {
      background: '#F7F7F7',
      border: '1px solid #888',
      borderRadius: 17,
      width: 34,
      height: 34,
      display: 'flex',
      alignItem: 'center',
      padding: '0px 10px',
      justifyContent: 'center',
      cursor: 'pointer',
      '& > div': {},
      '-webkit-transition': 'width 500ms',
      '-moz-transition': 'width 500ms',
      'transition': 'width 500ms',
    },
    expandSearch: {
      width: '400px',
      maxWidth: '400px',
      '& .select__indicators': {
        display: 'none'
      }
    },
    rootContainer: CommonCss.rootContainer,
    productDetailsData: {
      marginTop: '0px !important'
    },
    addReviewBtn: {
      textAlign: 'right',
      marginRight: 30
    },
    list: {
      overflowY: 'auto',
      maxHeight: "calc(100vh - 280px)",
      padding: '0px 30px 30px 0px',
      fontSize: '13px',
      listStyle: 'none',
      marginTop: '30px',
      marginLeft: '0',
    },
    groupName: {
      color: '#4a87f8',
      cursor: 'pointer',
      fontWeight: 500,
    },
    groupWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 10,
      marginTop: 20
    },
    loader: {
      textAlign: 'center',
      marginTop: 30
    }
  });

class OrgProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRequestAccessConfigDialog: false,
      enableReviewForAdminsCheck: false,
      isReviewFrequency: false,
      reviewFrequency: '',
      showMoreProducts: false,
      showMoreCompanyProducts: false,
      showMoreComplementaryProducts: false,
      viewMore: [],
      isCartShow: false,
      tabValue: 0,
      activeNumber: 0,
      champ: [],
      is404: false,
      query: "",
      page: 0,
      pageSize: 5,
      inPorgressStatus: [2, 3],
      completedStatus: [4],
      items: [],
      reviewInprogress: {
        inPorgressStatus: [2, 3],
        page: 0,
        pageSize: 5,
      },
      reviewCompleted: {
        inPorgressStatus: [4],
        page: 0,
        pageSize: 5,
      },
      reviewOption: [
        { label: "3 Months", value: 3 },
        { label: "6 Months", value: 6 },
        { label: "9 Months", value: 9 },
        { label: "12 Months", value: 12 },
      ],
      deleteProductId: null,
      isSearchProduct: false,
      selectedUsers: [],
      productNameText: "",
      isProductNameEdit: false,
      openMsgDialog: false,
      showProductWorkflowDialogFrom: null,
      selectedWorkflow: null,
    };
    this.addToCart = this.addToCart.bind(this);
  }

  componentDidMount() {
    if (this.props.userType === 'OrgExternal') {
      this.props.fetchExternalCollaboratorProductTabs(this.props.match?.params?.id);
    } else {
      this.props.fetchOrgProductTabs(this.props.match?.params?.id);
    }

    this.props.hideProductDetailsDialog();
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);
    this.fetchData();
    this.fetchOrgProductDetails();
    const enableReviewForAdminsCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableReviewForAdminsCheck')

    this.setState({
      enableReviewForAdminsCheck
    })
  }

  componentDidUpdate(prevProps) {

    if ((this.props.userType === 'OrgExternal') && prevProps.externalProductTabsFetchProgress && !this.props.externalProductTabsFetchProgress) {
      if (this.props.externalProductTabsFetchError) {
        this.props.showSnackBar('Failed to fetch data', "error", 3000);
      }
    }

    if (this.props.startNewWorkflowProgress === false && prevProps.startNewWorkflowProgress === true) {
      if (this.props.startNewWorkflowError === null) {
        this.props.hideStartNewWorkflowDialg();
        this.props.history.push(`/app/evaluate/${this.props.workflowData.ID}/workflow/interim`);
      } else {
        this.props.showSnackBar("Failed to start workflow", "error", 3000);
      }
    }

    if (!this.props.orgProductDetailsFetchProgress && prevProps.orgProductDetailsFetchProgress) {
      if (this.props.orgProductDetailsFetchError?.message && this.props.orgProductDetailsFetchError?.message !== null) {
        console.log('orgProductDetailsFetchError', this.props.orgProductDetailsFetchError)
        this.setState({ is404: true })
      }
    }
    if (this.props.match.params.id !== prevProps.match?.params?.id) {
      this.fetchOrgProductDetails();
    }
    this.cartDrawer(prevProps);
    if (
      !this.props.orgProductDetailsUpdateProgress &&
      prevProps.orgProductDetailsUpdateProgress
    ) {
      this.props.fetchOrgProductDetails(this.props.match?.params?.id);
      this.props.hideAddOrgProductChampionsDialog();
      this.props.hideAddProductWorkflowDialog();
      this.props.showSnackBar(
        "Product details updated successfully",
        "success",
        3000
      );
      if (this.state.showProductWorkflowDialogFrom === 'BUTTON') {
        var data = {
          product_ids: [{
            entity_id: String(this.props.productDetails?.productID),
            type: "PRODUCT",
          }],
          title: 'Request for ' + this.props.productDetails?.name,
          description: '',
          workflow_id: this.state.selectedWorkflow.value,
          type: 10
        }
        this.props.startNewWorkflow(data);
      }
    }
    if (!this.props.deleteProductProgress && prevProps.deleteProductProgress) {
      this.props.showSnackBar('Product deleted successfully', "success", 3000);
      this.props.history.push("/app/products/org-products")
    }
    if (this.props?.location?.hash !== prevProps.location?.hash || (!this.props.isLoadingTab && prevProps.isLoadingTab)) {

      let tabs = [];
      let all_tabs = this.getOrgRoleTabs();
      all_tabs.forEach(function (itm) {
        if (itm.TabUID === 'prod_survey' && process.env.REACT_APP_ENV !== 'dev') {
          return;
        }
        tabs.push(itm);
      });

      if (tabs && tabs.length > 0) {
        let currentTab = tabs[0]
        let tabfiltered = tabs.filter(tab => (`#${tab.TabUID}` === this.props?.location?.hash))
        if (tabfiltered.length) {
          currentTab = tabfiltered[0]
          const tabValue = tabs.findIndex(tab => (`#${tab.TabUID}` === this.props?.location?.hash))
          this.setState({ tabValue })
        }
        if (currentTab && currentTab?.TabUID && this.props.match?.params?.id) {
          const itemsUIDs = []
          const sections = currentTab?.OrgProductTabSections
          if (sections && Array.isArray(sections) && sections.length > 0) {
            sections.forEach(section => {
              const items = section?.OrgProductTabItems
              if (items && Array.isArray(items) && items.length > 0) {
                items.forEach(item => {
                  itemsUIDs.push(item.ItemUID)
                })
              }
            })
          }
          this.props.fetchTabsSectionsData(this.props.match?.params?.id, currentTab.TabUID, itemsUIDs)
        }
      }
    }

    if (prevProps.externalCollaboratorAddProgress && !this.props.externalCollaboratorAddProgress) {
      if (this.props.externalCollaboratorAddError) {
        this.props.showSnackBar('failed to add', "error", 3000);
      } else if (this.props.externalCollaboratorAddSuccess) {
        this.props.showSnackBar('vendor added successfully', "success", 3000);
      } else {
        this.props.showSnackBar('something went wrong', "error", 3000);
      }
    }

    if (prevProps.externalCollaboratorRemoveProgress && !this.props.externalCollaboratorRemoveProgress) {
      if (this.props.externalCollaboratorRemoveError) {
        this.props.showSnackBar('failed to remove', "error", 3000);
      } else if (this.props.externalCollaboratorRemoveSuccess) {
        this.props.showSnackBar('vendor removed successfully', "success", 3000);
      } else {
        this.props.showSnackBar('something went wrong', "error", 3000);
      }
    }
  }

  handleChange = (event, newValue) => {
    // this.props.history.push(
    //   `/app/products/org-product-details/${this.props.match.params.id}#${newValue}`
    // );
    this.setState({ tabValue: newValue, activeNumber: 0 });
  };

  getOrgRoleTabs = () => {
    const { orgProductDetailTabs, externalProductTabs } = this.props;

    if (this.props.userType === 'OrgExternal' && externalProductTabs) {
      return externalProductTabs.length ? externalProductTabs : [];
    } else if (orgProductDetailTabs) {
      if (orgProductDetailTabs.length) {
        let tabs = [...orgProductDetailTabs];
        if ((process.env.REACT_APP_ENV === 'dev') || (process.env.REACT_APP_ENV === 'production' && this.props.user?.OrgID === 67)) {
          tabs.push({
            TabUID: "custom_trend_analysis",
            TabName: "Trend analysis",
            isStatic: true,
            OrgProductTabSections: [
              {
                SectionUID: "overall_acore_analysis",
                SectionName: "Overall Score Analysis",
              },
              {
                SectionUID: "team_wise_score_analysis",
                SectionName: "Team wise Score Analysis",
              },
              {
                SectionUID: "requirement_wise_core_analysis",
                SectionName: "Question wise Score Analysis",
              },
              {
                SectionUID: "ream_requirement_trend",
                SectionName: "Team Question Trend",
              }
            ]
          })
        }

        return tabs;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  cartDrawer = (preProps) => {
    if (
      (this.props.evaluationCart &&
        this.props.evaluationCart.Products &&
        this.props.evaluationCart.Products.length) !==
      (preProps.evaluationCart &&
        preProps.evaluationCart.Products &&
        preProps.evaluationCart.Products.length)
    ) {
      this.setState({ isCartShow: true });
      setTimeout(() => {
        this.setState({ isCartShow: false });
      }, 2000);
    }
  };

  fetchOrgProductDetails = () => {
    this.props.fetchAllOrgSettings()
    this.props.fetchOrgProductDetails(this.props.match?.params?.id);
  };

  componentWillUnmount() {
    this.props.updateDrawerStyle(true, true);
  }

  scrollContent = (e) => {
    e.persist();
  };

  updateProducts = (value) => {
    var data = {
      url: value.URL,
      name: value.name,
      
      deployementType: value.DeployementType,
      BuisnessCriticality: value.buisnessCriticality,
      description: value.description,
      email: value.email,
      heading: value.heading,
      phone: value.phone,
      productLogo: value.productLogo,
      productURL: value.productURL,
      supportEmail: value.supportEmail,
      supportPhone: value.supportPhone,
      companyName: value.companyName,
      accessLink: value.accessLink,
      accessLinkMessage: value.RequestAccessConfig,
      timeForReReview: value.timeForReReview,
      workflowID: value.WorkflowID
    };
    this.props.updateOrgProductDetails(this.props.match?.params?.id, data);
  };

  addToCart(item) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(item.productID);
      this.props.showSnackBar("Product added to evaluation", "success", 3000);
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(item.productID) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, item.productID);
      } else {
        this.props.createEvaluationCart(item.productID, this.props.evaluationCart.ID);
        this.props.showSnackBar("Product added to evaluation", "success", 3000);
      }
    }
  }

  viewMore = (value, state) => {
    const { viewMore } = this.state;
    let values = [...viewMore];
    if (state) {
      const index = values.indexOf(value);
      if (index > -1) {
        values.splice(index, 1);
      }
    } else {
      values.push(value);
    }
    this.setState({
      viewMore: values,
    });
  };

  cartOpen = () => {
    this.setState({ isCartShow: true });
  };

  cartClose = () => {
    this.setState({ isCartShow: false });
  };

  fetchChampUsersData = (value) => {
    this.props.searchProductChampUser(value);
  };

  productChampChange = (newValue) => {
    this.setState({ champ: newValue });
  };

  fetchData() {
    this.fetchOrgproductInprogressReview();
    this.fetchOrgproductCompletedReview();
  }

  fetchOrgproductInprogressReview = () => {
    const { page, pageSize } = this.state.reviewInprogress;
    let productId = parseInt(this.props.match?.params?.id);
    this.props.fetchProductInprogressRequests({ product_ids: [productId], query: '', page, pageSize, status: [2, 3], type: [1, 2, 3, 4, 10] });
  };

  fetchOrgproductCompletedReview = () => {
    const { page, pageSize } = this.state.reviewCompleted;
    let productId = parseInt(this.props.match?.params?.id);
    this.props.fetchProductCompletedRequests({ product_ids: [productId], query: '', page, pageSize, status: [4], type: [1, 2, 3, 4, 10] });
  };

  routeChange = (id) => {
    let redirectPath = `/app/evaluate/${id}/`;
    if (userRole.indexOf(this.props.userType) > -1) {
      this.props.history.push(redirectPath);
    }
  };


  saveData = (data) => {
    const {
      name,
      title,
      email,
      dept,
      type,
      user,
      securityCert,
      filePath,
      link,
      description,
    } = data;
    if (type === "businessPoc") {
      this.props.addOrgContactDetails(this.props.match?.params?.id, {
        type: "BusinessPoc",
        accUserIDs: user.filter((e) => {
          return !e.isIntegration
        }).map((e) => e.value),
        orgUserIDs: user.filter((e) => {
          return e.isIntegration
        }).map((e) => e.value),
      });
    } else if (type === "champion") {
      this.props.addOrgContactDetails(this.props.match?.params?.id, {
        type: "Champion",
        accUserIDs: user.filter((e) => {
          return !e.isIntegration
        }).map((e) => e.value),
        orgUserIDs: user.filter((e) => {
          return e.isIntegration
        }).map((e) => e.value),
      });
    } else if (type === "vendor") {
      this.props.orgProductAddVendorPoc(this.props.match?.params?.id, {
        name,
        title,
        email,
        dept,
      });
    } else if (type === "securityCert") {
      this.props.addOrgSecurityCertDoc(
        this.props.match?.params?.id,
        (securityCert.value === undefined || String(securityCert.value).trim().length === 0) ? "0" : securityCert.value,
        {
          certificateUrl: filePath || link,
          certificateTitle: title,
          description: description,
          certName: securityCert.data === undefined ? undefined : securityCert.data.Name,
          certType: securityCert.data === undefined ? undefined : securityCert.data.CertType
        }
      );
    } else if (type === "addSecurityCert") {
      this.props.addComplianceCert({
        name: name,
        description: description,
        isVerified: true,
      });
    } else if (type === "addPii") {
      this.props.addPii({ name: name, description: title, isVerified: true });
    } else if (type === "documents") {
      this.props.addOrgDocument(this.props.match?.params?.id, {
        documents: [
          {
            title: title || "",
            description: description || "",
            url: filePath || link,
          },
        ],
      });
    } else if (type === "requestAccessLink") {
      this.updateProducts({
        ...this.props.productDetails,
        AccessLink: link,
        AccessLinkMessage: description
      })
    }
  };

  saveProductWorkflow = (workflow) => {
    this.setState({
      selectedWorkflow: workflow
    }, () => {
      this.updateProducts({
        ...this.props.productDetails,
        WorkflowID: workflow.value,
      })
    })
  };

  saveReviewFrequency = () => {
    if (this.state.reviewFrequency) {
      this.updateProducts({ ...this.props.productDetails, timeForReReview: this.state.reviewFrequency })
      this.setState({ isReviewFrequency: false });
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  }

  saveRequestAccessConfig = (data) => {
    this.updateProducts({
      ...this.props.productDetails,
      RequestAccessConfig: JSON.stringify(data),
    })
  }


  getReviewValue = (value) => {
    return this.state.reviewOption.find(
      (element) => element.value === value
    );
  };

  // Delete Product
  deleteProductClick = (id) => {
    let me = this;
    this.setState({ deleteProductId: id, openMsgDialog: true }, () => {
      me.props.showMessageDialog();
    })
  }
  callBack = () => {
    if (this.state.deleteProductId) {
      this.setState({
        openMsgDialog: false
      })
      this.props.hideMessageDialog();
      this.props.deleteProduct(this.state.deleteProductId);
    }
  };

  handleTabRedirect = (TabUID) => {
    this.props.history.push(`${this.props.match.url}#${TabUID}`)
  }

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page
    }
    const api_server = Environment.api_host("SEARCH");
    const timestamp = (new Date()).getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      }
    }

    let formData = {
      field_setname: "all",
      text: inputValue,
      filter_fields: {},
      analytics_only: false,
      qtype_page: null,
      page_size: 100,
      sort_fields: []
    }
    // setPage(page+1)
    let url = `${api_server}/v2/dsl/app_catalogue?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { 'authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'application/json' }
    });

    let productsData = [];

    try {
      if (response?.data?.body) {

        let products = response?.data?.body?.existing?.items || [];

        products.forEach(m => {
          const data = m
          if (data.id && (m.name || m.company_name)) {
            data.value = parseInt(m.id);
            data.label = m.name || m.company_name;
            productsData.push(data);
          }
        })
      }
    } catch (e) { }
    return {
      options: productsData,
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  }

  getSelectedTab() {
    let tabs = [];
    let all_tabs = this.getOrgRoleTabs();
    all_tabs.forEach(function (itm) {
      if (itm.TabUID === 'prod_survey' && process.env.REACT_APP_ENV !== 'dev') {
        return;
      }
      tabs.push(itm);
    });
    let currentTab = tabs[this.state.tabValue]
    return currentTab;
  }

  checkVisibilityForTabSections = (field, tabShowForUserObj) => {
    let isShow = false;
    if (field && field !== null && field !== undefined) {
      isShow = true;
    }
    if (tabShowForUserObj.hasOwnProperty(field)) {
      if (tabShowForUserObj[field] === true) {
        isShow = true;
      } else {
        isShow = false;
      }
    }
    if (field === "prod_subcategories") {
      isShow = false;
    }
    return isShow;
  }

  checkVisibilityForContractTab(field) {
    const { orgFinanceDetails } = this.props;
    let isShow = false;
    if (false && field === 'prod_financial_details') {
      if (orgFinanceDetails && orgFinanceDetails.length > 0) {
        let detailsData = orgFinanceDetails.filter((o) => o?.DocumentPath === this.props.activeContractDocument)[0];
        if (detailsData) {
          isShow = true;
        }
      }
    } else if (false && field === 'add_financial_details') {
      const isAdd = this.props.orgFetchDocuments && this.props.orgFetchDocuments.length > 0 && this.props.orgFetchDocuments.filter(obj => obj.Type === "default").length > 0 ? false : true
      if (this.props.userType === 'OrgAdmin' && isAdd) {
        isShow = false;
      }
    } else if (field === 'prod_contact_documents') {
      if (this.props.orgFetchDocuments.length > 0) {
        isShow = true;
      }
    }
    return isShow;
  }

  checkVisibilityForRenewalTab(field) {
    const { orgFinanceDetails } = this.props;
    let isShow = false;
    if (orgFinanceDetails && orgFinanceDetails.length > 0) {
      orgFinanceDetails[orgFinanceDetails.length - 1].OrgProductFinancialDocumentAttributes.forEach((o, index) => {
        if ([field].includes(o.Attribute) && o.Value) {
          isShow = true;
        }
      });
    }
    return isShow;
  }

  checkVisibilityForBusinessPoc() {
    const { orgContactDetails } = this.props;
    const businessPocDetails = orgContactDetails.map((e) => {
      if (e.ContactType === "BusinessPoc") {
        return e;
      } else {
        return false
      }
    }).filter(o => o !== false);
    if (businessPocDetails !== undefined && businessPocDetails !== null && businessPocDetails.length > 0) {
      return true
    } else {
      return false
    }
  }

  render() {
    const classes = this.props.classes;

    if (this.props.isLoadingTab || this.props.externalProductTabsFetchProgress) {
      return (<Grid container className={classes.loader} style={{ height: "50vh" }}>
        <CircularProgress />
      </Grid>)
    }
    const { productDetails, userType } = this.props;
    var tabValue = this.state.tabValue;

    let role = this.props.user?.Role;

    const isCart = this.props.evaluationCart !== null && this.props.evaluationCart !== '' && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length > 0;

    const userFlag = userRole?.indexOf(userType) === -1;
    const adminFlag = userRole.indexOf(userType) > -1;

    let currentTab = null
    let sections = []
    let tabs = [];
    let all_tabs = this.getOrgRoleTabs();
    all_tabs.forEach(function (itm) {
      if (itm.TabUID === 'prod_survey' && process.env.REACT_APP_ENV !== 'dev') {
        return;
      }
      tabs.push(itm);
    });


    const sectionObj = {}
    if (tabs.length > 0) {
      currentTab = tabs[tabValue]
      if (currentTab?.OrgProductTabSections && currentTab?.OrgProductTabSections.length > 0) {
        sections = currentTab?.OrgProductTabSections
      }
      sections = sections.filter(section => {
        sectionObj[section.SectionUID] = { permission: section.Permission };

        // if(section?.OrgProductTabItems && section.OrgProductTabItems.length > 0){
        //   sectionObj[section.SectionUID] = true;
        // }else{
        //   sectionObj[section.SectionUID] = false;
        // }
        return 1;
      })
    }

    let isCloud =
      (this.props.orgPlatform &&
        this.props.orgPlatform?.filter(
          (item) => item.Name === "Cloud" || item.Name === "On-Premise"
        )) ||
      [];

    let isSupport =
      (this.props.orgPlatform &&
        this.props.orgPlatform?.filter(
          (item) => item.Name === "Online" || item.Name === "Business Hours"
        )) ||
      [];

    let isDesktop =
      (this.props.orgPlatform &&
        this.props.orgPlatform?.filter(
          (item) =>
            item.Name === "Windows" ||
            item.Name === "Mac" ||
            item.Name === "Web" ||
            item.Name === "Linux" ||
            // item.Name === "On-Premise" || 
            item.Name === 'Chrome Extension'
        )) ||
      [];

    let isMobile =
      (this.props.orgPlatform &&
        this.props.orgPlatform?.filter(
          (item) => item.Name === "Android" || item.Name === "IOS"
        )) ||
      [];

    const championDetails = [];
    const businessPocDetails = [];
    this.props.orgContactDetails.forEach((e) => {
      if (e.ContactType === "Champion") {
        championDetails.push(e);
      } else {
        businessPocDetails.push(e);
      }
    });
    const getConfigValue = (array, key, value) => {
      if (value) {
        let result = '';
        try {
          result = array.find(element => element[key] === value)['Value'];
        } catch (e) { }
        return result || '';
      }
    }

    let alternativeProducts = [];

    if (
      this.props.orgAlternativeProducts &&
      this.props.orgAlternativeProducts.length > 0
    ) {
      alternativeProducts = alternativeProducts.concat(
        this.props.orgAlternativeProducts
      );
    }

    if (this.props.orgProductDetailsFetchProgress || this.state.is404) {
      return (
        <div>
          <AppBarV2 title="Product Details" withBack={true} dropdown={true} />
          <div className={classes.productDetails}>
            {this.state.is404 && (
              <div style={{ width: '100%', textJustify: 'center', textAlign: 'center' }}>
                <p className={classes.productName}>
                  404
                </p>
                <p className={classes.emptyText}>
                  Product does not exists
                </p>
              </div>
            )}
            {!this.state.is404 && (
              <CircularProgress />
            )}
          </div>
        </div>
      )
    }

    // const accessType = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "RequestAccessType") || '{"label":"Redirect to URL","value":"AccessLink"}';
    // const RequestAccessTypeValue = JSON.parse(accessType);

    // const AccessLink = String(getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'SettingName', 'AccessLink')).trim()
    // const RequestAccessMessageValue = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "RequestAccessMessage") || '';
    // const accessWorkflow = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "RequestAccessWorkflow") || '{}';
    // const RequestAccessWorkflow = JSON.parse(accessWorkflow);

    // const RequestAccessProductSepecificWorkflow = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "RequestAccessProductSepecificWorkflow") || 'Yes';
   
    const RequestAccessConfig = isJson(productDetails?.AccessLinkMessage) && 
     JSON.parse(productDetails?.AccessLinkMessage)?.RequestAccessTypeOptions    
      ?
      JSON.parse(productDetails?.AccessLinkMessage) : {
      RequestAccessTypeOptions: [
        { label: "Redirect to URL", value: "AccessLink" },
        { label: "Show message", value: "RequestAccessMessage" },
        { label: "Execute Workflow", value: "RequestAccessWorkflow" },
      ],
      RequestAccessType: {
        label: "Redirect to URL",
        value: "AccessLink",
      },
      RequestAccessMessage: [{ type: "paragraph", children: [{ text: "" }] }],
      RequestAccessWorkflow: null,
      AccessLink: "",
    };

    console.log(RequestAccessConfig, "RequestAccessConfig")

    const ReviewFrequency = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'SettingName', 'ReviewFrequency')

    const productDetailsHideForUser = {
      prod_pricing_information: this.props.productPrice && size(this.props.productPrice) && sectionObj?.prod_pricing_information,
      prod_about: (((userFlag && productDetails?.description) || (sectionObj?.prod_about?.permission === "write")) || adminFlag) && sectionObj?.prod_about,
      prod_company_details: (((userFlag && (productDetails?.productURL || productDetails?.companyName)) || (sectionObj?.prod_company_details?.permission === "write")) || adminFlag) && sectionObj?.prod_company_details,
      prod_subcategories: (((userFlag && this.props.orgSubCategories?.length) || (sectionObj?.prod_subcategories?.permission === "write")) || adminFlag) && sectionObj?.prod_subcategories,
      // buisnessCriticality: ((userFlag && productDetails?.buisnessCriticality) || adminFlag),
      // orgProductDepartment: ((userFlag && this.props.orgProductDepartment?.length > 0) || adminFlag),
      prod_approved_integrations: (((userFlag && this.props.orgIntegration.length > 0) || (sectionObj?.prod_approved_integrations?.permission === "write")) || adminFlag) && sectionObj?.prod_approved_integrations,
      prod_approved_platforms: (((userFlag && (isDesktop?.length > 0 || isMobile?.length > 0 || isCloud?.length > 0)) || (sectionObj?.prod_approved_platforms?.permission === "write")) || adminFlag) && sectionObj?.prod_approved_platforms,
      // prod_approved_platforms: sectionObj?.prod_approved_platforms,
      prod_feature_offered: (((userFlag && this.props.orgFeatures?.length > 0) || (sectionObj?.prod_feature_offered?.permission === "write")) || adminFlag) && sectionObj?.prod_feature_offered,
      prod_similar_products: (((userFlag && this.props.orgSimilarProduct?.length > 0) || (sectionObj?.prod_similar_products?.permission === "write")) || adminFlag) && sectionObj?.prod_similar_products,
      prod_other_products: (((userFlag && this.props.orgSameProduct?.length > 0) || (sectionObj?.prod_other_products?.permission === "write")) || adminFlag) && sectionObj?.prod_other_products,
      prod_complementary_products: (((userFlag && this.props.orgComplementaryProduct?.length > 0) || (sectionObj?.prod_complementary_products?.permission === "write")) || adminFlag) && sectionObj?.prod_complementary_products,
      prod_categories: (((userFlag && this.props.orgCategories?.length > 0) || (sectionObj?.prod_categories?.permission === "write")) || adminFlag) && sectionObj?.prod_categories,
      companies_like_you_use: this.props.orgAlternativeProducts?.length > 0 && sectionObj?.companies_like_you_use,
    }

    const renewalHideForUser = {
      business_point_of_contact: this.checkVisibilityForBusinessPoc() && sectionObj?.business_point_of_contact,
      start_date: (((userFlag && this.checkVisibilityForRenewalTab("start_date")) || (sectionObj?.start_date?.permission === "write")) || adminFlag) && sectionObj?.start_date,
      end_date: (((userFlag && this.checkVisibilityForRenewalTab("end_date")) || (sectionObj?.end_date?.permission === "write")) || adminFlag) && sectionObj?.end_date,
      renewal_date: (((userFlag && this.checkVisibilityForRenewalTab("renewal_date")) || (sectionObj?.renewal_date?.permission === "write")) || adminFlag) && sectionObj?.renewal_date,
      total_cost_from_contract: (((userFlag && this.checkVisibilityForRenewalTab("total_cost_from_contract")) || (sectionObj?.total_cost_from_contract?.permission === "write")) || adminFlag) && sectionObj?.total_cost_from_contract,
      contract: this.props.orgFetchDocuments?.length > 0 && sectionObj?.contract,
      usage_summary: (((userFlag && championDetails?.length > 0) || (sectionObj?.usage_summary?.permission === "write")) || adminFlag) && sectionObj?.usage_summary,
      latest_review_and_evaluation: (this.props.requestCompletedProductData?.Data?.length > 0 || this.props.requestInprogressProductData?.Data?.length > 0) && sectionObj?.latest_review_and_evaluation,
      pricing: this.props.productPrice && size(this.props.productPrice) && sectionObj?.pricing,
      existing_similar_products: this.props.orgExisingSimilarProducts?.similar_products?.length > 0 && sectionObj?.existing_similar_products,
      alternative_products: alternativeProducts?.length > 0 && sectionObj?.alternative_products,
      latest_survey: sectionObj?.latest_survey,
    }

    const contactHideForUser = {
      prod_product_champions: (((userFlag && championDetails?.length > 0) || (sectionObj?.prod_product_champions?.permission === "write")) || adminFlag) && sectionObj?.prod_product_champions,
      prod_business_poc: (((userFlag && businessPocDetails?.length > 0) || (sectionObj?.prod_business_poc?.permission === "write")) || adminFlag) && sectionObj?.prod_business_poc,
      prod_vendor_poc: (((userFlag && this.props.orgVendorPocDetails?.length > 0) || (sectionObj?.prod_vendor_poc?.permission === "write")) || adminFlag) && sectionObj?.prod_vendor_poc,
      prod_support: (((userFlag && (this.props.productDetails?.supportEmail || this.props.productDetails?.supportPhone)) || (sectionObj?.prod_support?.permission === "write")) || adminFlag) && sectionObj?.prod_support,
      prod_departments: (((userFlag && this.props.orgProductDepartment?.length > 0) || (sectionObj?.prod_departments?.permission === "write")) || adminFlag) && sectionObj?.prod_departments,
      prod_other_resources: (((userFlag && this.props.contactDetailLinks?.length > 0) || (sectionObj?.prod_other_resources?.permission === "write")) || adminFlag) && sectionObj?.prod_other_resources,
    }

    const DocumentationFolder = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'SettingName', 'DocumentationFolder')

    const securityHideForUser = {
      prod_business_criticality: (((userFlag && productDetails?.buisnessCriticality) || (sectionObj?.prod_business_criticality?.permission === "write")) || adminFlag) && sectionObj?.prod_business_criticality,
      prod_security_certification: (((userFlag && this.props.orgFetchSecurityCert && this.props.orgFetchSecurityCert?.length > 0) || (sectionObj?.prod_security_certification?.permission === "write")) || adminFlag) && sectionObj?.prod_security_certification,
      prod_perdonal_data_shared: (((userFlag && this.props.orgFetchPii && this.props.orgFetchPii.length > 0) || (sectionObj?.prod_perdonal_data_shared?.permission === "write")) || adminFlag) && sectionObj?.prod_perdonal_data_shared,
      prod_certification_n_agreement: (((userFlag && ((this.props.orgFetchSecurityCertDocs && this.props.orgFetchSecurityCertDocs.length > 0) || DocumentationFolder)) || (sectionObj?.prod_certification_n_agreement?.permission === "write")) || adminFlag) && sectionObj?.prod_certification_n_agreement
    }

    const financeHideForUser = {
      prod_contact_documents: ((userFlag && this.checkVisibilityForContractTab("prod_contact_documents")) || adminFlag) && sectionObj?.prod_contact_documents,
      prod_financial_details: this.checkVisibilityForContractTab("prod_financial_details") && sectionObj?.prod_financial_details,
      add_financial_details: this.checkVisibilityForContractTab("add_financial_details") && sectionObj?.add_financial_details,
    }

    const evaluationHideForUser = {
      review_frequency: (((userFlag && ((this.props.productDetails?.timeForReReview === 0 && ReviewFrequency) || this.props.productDetails?.timeForReReview > 0)) || (sectionObj?.review_frequency?.permission === "write")) || adminFlag) && sectionObj?.review_frequency,
      prod_evaluation_reviews: (((userFlag && (this.props.requestInprogressProductData?.Data?.length > 0 || this.props.requestCompletedProductData?.Data?.length > 0)) || (sectionObj?.prod_evaluation_reviews?.permission === "write")) || adminFlag) && sectionObj?.prod_evaluation_reviews,
    }

    const metricHideForUser = {
      usagesMetrics: sectionObj.prod_usages_metrics,
    }

    let requestLink = RequestAccessConfig?.AccessLink ||"";
    let requestMsg = RequestAccessConfig?.RequestAccessMessage ||"";

    // const workflowID = productDetails?.WorkflowID;
    const workflowID = RequestAccessConfig?.RequestAccessWorkflow?.value

    if (requestMsg === '[{"type":"paragraph","children":[{"text":""}]}]' || '{"children":[{"text":""}],"type":"paragraph"}' === requestMsg) {
      requestMsg = ''
    }
    const champion = {
      head: "Request Access",
      isLink: true,
      link: requestLink,
      isLinkRequired: false,
      linkPlaceholder: 'Link',
      type: 'requestAccessLink',
      isMessage: true,
      messagePlaceholder: 'Please enter your message.',
      message: requestMsg,
      description: requestMsg
    }

    const championAccessLink = {
      head: "Request Access",
      isLink: true,
      link: RequestAccessConfig?.AccessLink,
      isLinkRequired: false,
      linkPlaceholder: 'Link',
      type: 'requestAccessLink',
      isMessage: true,
      messagePlaceholder: 'Please enter your message.',
      message: requestMsg,
      description: requestMsg
    }

    const championMessageLink = {
      head: "Request Access",
      isLink: true,
      link: '',
      isLinkRequired: false,
      linkPlaceholder: 'Link',
      type: 'requestAccessLink',
      isMessage: true,
      messagePlaceholder: 'Please enter your message.',
      message: RequestAccessConfig?.RequestAccessMessage || '',
      description: RequestAccessConfig?.RequestAccessMessage || ''
    }

    const reqAccessMessageAdmin = {
      head: "Request Access",
      isLink: false,
      link: requestLink,
      isLinkRequired: false,
      linkPlaceholder: 'Link',
      type: 'requestAccessLink',
      isMessage: true,
      messagePlaceholder: 'There is no request access link anywhere. Please edit it at the product level.',
      message: 'There is no request access link anywhere. Please edit it at the product level.',
    }

    const reqAccessMessageOrgUser = {
      head: "Request Access",
      isLink: false,
      link: requestLink,
      isLinkRequired: false,
      linkPlaceholder: 'Link',
      type: 'requestAccessLink',
      isMessage: true,
      messagePlaceholder: 'Please reach out to admin for request access.',
      message: 'Please reach out to admin for request access.',
    }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    const selectStyles = {
      container: base => ({
        ...base,
        flex: 1,
      }),
      control: () => ({
        height: 30,
        minHeight: 30,
        fontSize: 16,
        border: 'none',
        borderRadius: 0,
        width: "100%",
        textAlign: "left",
        cursor: "pointer"
      }),
      valueContainer: base => ({
        ...base,
        padding: '0 !important'
      })
    };

    if (productDetails == null) {
      return null;
    }
    // console.log(productDetails, 'productDetails-1111111111111111111111111111');
    // console.log(workflowID, 'workflowID1111111111111111111111111111');

    return (
      <div className={classes.root}>
        {
          <div hidden={!productDetails}>
            <AppBarV2
              title="Organization Product Details"
              withBack={true}
              dropdown={true}
            >
              {this.props.userType !== "OrgExternal" && <Grid className={classes.searchArea} alignItems="center" container justify="flex-end">
                <ClickAwayListener
                  onClickAway={(e) => {
                    e.stopPropagation()
                    this.setState({
                      isSearchProduct: false
                    })
                  }}
                >
                  <div className={classnames(classes.searchInput, this.state.isSearchProduct ? classes.expandSearch : {})}>
                    <SearchIcon
                      style={{ color: '#888', marginTop: 3 }}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (!this.state.isSearchProduct) {
                          this.setState({ isSearchProduct: true })
                        }
                      }}
                    />
                    {this.state.isSearchProduct && (
                      <CreatableAsyncPaginate
                        isClearable={false}
                        autoFocus
                        isSearchable={true}
                        classNamePrefix="select"
                        placeholder="Search Products"
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        onInputChange={(e) => {
                        }}
                        additional={{
                          page: 0,
                        }}
                        onChange={(e) => {
                          if (e.value) {
                            const { hash } = this.props.location
                            let url = `/app/products/org-product-details/${e.value}`
                            if (hash) {
                              url += hash
                            }
                            window.location = url
                          }
                        }}
                        isValidNewOption={(input) => input.length > 0}
                        defaultValue={{}}
                        loadOptions={this.getOptions}
                        styles={selectStyles}
                      />
                    )}
                  </div>
                </ClickAwayListener>
              </Grid>}
            </AppBarV2>
            <div className={classes.rootContainer}>
              <div className={classnames(classes.productDetails, classes.productDetailsData)}>
                <div className={classes.productLeftSection}>
                  <div style={{ textAlign: 'center' }}>
                    <Image
                      src={
                        productDetails?.productLogo
                          ? productDetails?.productLogo
                          : ""
                      }
                      style={{ maxWidth: "90%" }}
                    />
                    {role === "OrgAdmin" && <Button className={classes.btnDeleteProduct}
                      onClick={() => {
                        if (this.props.orgProductStates) {
                          const filteredState = this.props.orgProductStates.filter(product => product.State === 'existing')
                          if (filteredState[0]) {
                            const state = filteredState[0]
                            this.props.deleteProductState(state.ProductID, state.ID)
                          }
                        }
                        this.deleteProductClick(this.props.match?.params?.id)
                      }}>- Archive Product</Button>}
                  </div>

                  {/* <div className={classes.groupWrap}>
                    <div><FolderIcon color="primary"/></div>
                    <div className={classes.groupName} onClick={()=>{
                      this.props.history.push(`/app/products/cluster-products/${1}`)
                    }}>Test group intagram</div>
                  </div> */}

                  {/* Tab submenu */}
                  <ul className={classes.list}>
                    {sections && sections.length > 0 && sections.map((section, k) => {

                      var isShow = true;
                      var tab = this.getSelectedTab();
                      if (tab.TabUID === "prod_details") {
                        isShow = this.checkVisibilityForTabSections(section.SectionUID, productDetailsHideForUser);
                      } else if (tab.TabUID === "contact_details") {
                        isShow = this.checkVisibilityForTabSections(section.SectionUID, contactHideForUser);
                      } else if (tab.TabUID === "renewal_page") {
                        isShow = this.checkVisibilityForTabSections(section.SectionUID, renewalHideForUser)
                      } else if (tab.TabUID === "security_n_privacy") {
                        isShow = this.checkVisibilityForTabSections(section.SectionUID, securityHideForUser);
                      } else if (tab.TabUID === "evaluation_reviews") {
                        isShow = this.checkVisibilityForTabSections(section.SectionUID, evaluationHideForUser);
                      } else if (tab.TabUID === "contract_details") {
                        if (this.props.orgFinanceDetailsProgress || this.props.orgFetchDocumentsProgress) {
                          isShow = false;
                        } else {
                          isShow = this.checkVisibilityForTabSections(section.SectionUID, financeHideForUser);
                        }
                      }


                      if (!isShow) {
                        return '';
                      }

                      return (
                        <a href={`#${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                          <li className={classes.item} key={k}>
                            {((section.SectionUID === "prod_evaluation_reviews") || (section.SectionUID === "latest_review_and_evaluation")) ? "Workflows" : section.SectionName}
                          </li>
                        </a>
                      );
                    })}
                  </ul>
                </div>
                <div className={classes.productRightSection}>
                  <div className={classes.ProductNameSection}>
                    <div className={classes.productSection}>
                      {!this.state.isProductNameEdit && (<>
                        <p className={classes.productName}>{productDetails?.name}</p>
                        {role === "OrgAdmin" && (
                          <EditIcon
                            fontSize={"small"}
                            className={classes.editIcon}
                            onClick={() => {
                              this.setState({
                                isProductNameEdit: true,
                                productNameText: productDetails?.name,
                              });
                            }}
                          />
                        )}
                      </>)}
                      {this.state.isProductNameEdit && (
                        <Grid container spacing={3} className={classes.buttonGroup}>
                          <Grid item sm={8}>
                            <div>
                              <input
                                style={{ padding: 10 }}
                                className={classes.formControl}
                                placeholder="Enter the product name"
                                onChange={(event) => {
                                  this.setState({
                                    productNameText: event.target.value,
                                  });
                                }}
                                value={this.state.productNameText}
                              />
                            </div>
                          </Grid>
                          <Grid item sm={4} style={{ width: "200px", alignSelf: 'center' }}>
                            <Button
                              className={classes.tickMark}
                              onClick={() => {
                                if (this.state.productNameText) {
                                  this.updateProducts({
                                    ...this.props.productDetails,
                                    name: this.state.productNameText,
                                  });
                                  this.setState({ productNameText: "" });
                                } else {
                                  this.props.showSnackBar("Please enter product name", "error", 3000);
                                  return;
                                }
                                this.setState({ isProductNameEdit: false });
                              }}
                            >
                              <DoneIcon />
                            </Button>
                            <Button
                              className={classes.tickMark}
                              onClick={() =>
                                this.setState({ isProductNameEdit: false })
                              }
                            >
                              <CloseIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      {this.props.userType !== "OrgExternal" && productDetails &&
                        <div className={classes.accessbtn}>
                          &nbsp;
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.requestAccessBtn}
                            onClick={(e) => {
                              e.stopPropagation()
                              console.log(RequestAccessConfig?.RequestAccessType?.value,"RequestAccessConfig?.RequestAccessType?.value")
                              if (RequestAccessConfig?.RequestAccessType?.value === 'RequestAccessWorkflow') {
                                if (RequestAccessConfig?.RequestAccessWorkflow) {
                                  if (workflowID !== undefined && workflowID !== null && workflowID !== 0) {
                                    var data = {
                                      product_ids: [{
                                        entity_id: String(productDetails?.productID),
                                        type: "PRODUCT",
                                      }],
                                      title: 'Request for ' + productDetails?.name,
                                      description: '',
                                      workflow_id: RequestAccessConfig?.RequestAccessWorkflow.value,
                                      type: 10
                                    }
                                    this.props.startNewWorkflow(data);
                                  } else {
                                    this.setState({
                                      showProductWorkflowDialogFrom: 'BUTTON'
                                    }, () => {
                                      this.props.showAddProductWorkflowDialog()
                                    })
                                  }
                                } else {
                                  var data1 = {
                                    product_ids: [{
                                      entity_id: String(productDetails?.productID),
                                      type: "PRODUCT",
                                    }],
                                    title: 'Request for ' + productDetails?.name,
                                    description: '',
                                    workflow_id: RequestAccessConfig?.RequestAccessWorkflow.value,
                                    type: 10
                                  }
                                  this.props.startNewWorkflow(data1);
                                }
                              } else if (requestLink && requestLink.length > 0) {
                                console.log("requestLink", requestLink)
                                this.props.showRequestAccessInformationDialog(champion)
                              } else if (requestMsg && requestMsg.length > 0 && JSON.stringify(requestMsg) !== '[{"type":"paragraph","children":[{"text":""}]}]' && '{"children":[{"text":""}],"type":"paragraph"}' !== JSON.stringify(requestMsg)) {
                                console.log("requestMsg", requestMsg)
                                this.props.showRequestAccessInformationDialog(champion)
                              } else if (RequestAccessConfig?.RequestAccessType?.value === 'AccessLink' && RequestAccessConfig?.AccessLink && RequestAccessConfig?.AccessLink?.length !== 0) {
                                console.log("championAccessLink1", championAccessLink)
                                this.props.showRequestAccessInformationDialog(championAccessLink)
                              } else if (RequestAccessConfig?.RequestAccessType?.value === 'RequestAccessMessage' && RequestAccessConfig?.RequestAccessMessage && RequestAccessConfig?.RequestAccessMessage?.length !== 0  && RequestAccessConfig?.RequestAccessMessage !== '[{"type":"paragraph","children":[{"text":""}]}]' && '{"children":[{"text":""}],"type":"paragraph"}' !== RequestAccessConfig?.RequestAccessMessage) {
                                console.log("championMessageLink", championMessageLink)
                                this.props.showRequestAccessInformationDialog(championMessageLink)
                              } else if (RequestAccessConfig?.RequestAccessType?.value === 'RequestAccessWorkflow') {

                                var data = {
                                  product_ids: [{
                                    entity_id: String(productDetails?.productID),
                                    type: "PRODUCT",
                                  }],
                                  title: 'Request for ' + productDetails?.name,
                                  description: '',
                                  workflow_id: RequestAccessConfig?.RequestAccessWorkflow.value,
                                  type: 10
                                }
                                console.log("data", data)
                                this.props.startNewWorkflow(data);
                              } else if (RequestAccessConfig?.AccessLink && RequestAccessConfig?.AccessLink?.length !== 0) {
                                console.log("championAccessLink2", championAccessLink)
                                this.props.showRequestAccessInformationDialog(championAccessLink)
                              } else {
                                
                                if (role === "OrgUser") {
                                  console.log("reqAccessMessageOrgUser", reqAccessMessageOrgUser)
                                  this.props.showRequestAccessInformationDialog(reqAccessMessageOrgUser)
                                } else {
                                  console.log("reqAccessMessageAdmin", reqAccessMessageAdmin)
                                  this.props.showRequestAccessInformationDialog(reqAccessMessageAdmin)
                                }
                              }
                            }}
                          >
                            Request Access
                          </Button>
                          {role === "OrgAdmin" && <EditIcon fontSize={"small"} className={classes.editIcon} onClick={() => {
                            // if (RequestAccessConfig?.RequestAccessType.value === 'RequestAccessWorkflow' && RequestAccessConfig?.RequestAccessProductSepecificWorkflow === 'Yes') {
                            //   this.props.showAddProductWorkflowDialog()
                            // } else {
                            //   // this.props.showAddOrgProductChampionsDialog(champion)
                            //   this.setState({ showRequestAccessConfigDialog: true });
                            // }
                            this.setState({ showRequestAccessConfigDialog: true });
                          }}
                          />}                          
                        </div>}
                    </div>
                    <div>
                      {false && this.state.enableReviewForAdminsCheck && adminFlag && <Button className={classes.newUserBtn} onClick={() => this.props.history.push(`/app/products/product-review/${this.props.match?.params?.id}/`)}>
                        + Review
                      </Button>}
                    </div>
                  </div>
                  {/* <a target="_blank" href={productDetails?.ProductUrl} className={classes.productLink}>By {productDetails?.CompanyName}</a> */}

                  <div className={classes.tabSection}>
                    <Tabs
                      value={tabValue}
                      // onChange={this.handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      className={classes.tabWidth}
                    >
                      {tabs && tabs.length > 0 && tabs.map((item, key) => {
                        return <Tab
                          className={classes.orgProductTab}
                          label={item.TabName}
                          key={key}
                          onClick={() => this.handleTabRedirect(item.TabUID)}
                        />
                      })}
                    </Tabs>
                  </div>

                  {currentTab && currentTab !== null && currentTab?.TabUID && currentTab?.IsDeletable && (
                    <div>
                      {sections && sections.length > 0 && sections.map(section => (
                        <CustomSection
                          key={section.SectionUID}
                          tabUID={currentTab?.TabUID}
                          section={section}
                          isEditable={(section.Permission === 'write') || (section.Access === 2)}
                          productID={productDetails.productID}
                        />
                      ))}
                    </div>
                  )}

                  {currentTab && currentTab !== null && currentTab?.isStatic && (
                    <div>
                      <TrendAnalysisTab
                        productDetails={productDetails}
                        sections={sections}
                        productID={productDetails.productID}
                      />
                    </div>
                  )}

                  {currentTab && currentTab !== null && currentTab?.TabUID && !currentTab?.IsDeletable && (
                    <>
                      {/* Renewal details */}
                      {currentTab.TabUID === "renewal_page" &&
                        <div
                          onScroll={(e) => this.scrollContent(e)}
                        >
                          <RenewalTab
                            productDetails={productDetails}
                            updateProducts={this.updateProducts}
                            hideFlagForUser={renewalHideForUser}
                            sections={sections}
                            adminFlag={adminFlag}
                          />
                        </div>}

                      {/* Product details */}
                      {currentTab.TabUID === "prod_details" &&
                        <div
                          onScroll={(e) => this.scrollContent(e)}
                        >
                          <OrgProductDetailsTab
                            productDetails={productDetails}
                            updateProducts={this.updateProducts}
                            hideFlagForUser={productDetailsHideForUser}
                            isCloud={isCloud}
                            isDesktop={isDesktop}
                            isMobile={isMobile}
                            isSupport={isSupport}
                            sections={sections}
                            adminFlag={adminFlag}
                          />
                        </div>}

                      {/* Survey Tab */}
                      {currentTab.TabUID === "prod_survey" &&
                        <div onScroll={(e) => this.scrollContent(e)}>
                          <SurveyTab
                            productDetails={productDetails}
                            updateProducts={this.updateProducts}
                            hideFlagForUser={contactHideForUser}
                            sections={sections}
                            adminFlag={adminFlag}
                          />
                        </div>}

                      {/* Contact tab */}
                      {currentTab.TabUID === "contact_details" &&
                        <div
                          onScroll={(e) => this.scrollContent(e)}
                        >
                          <ContactTab
                            productDetails={productDetails}
                            updateProducts={this.updateProducts}
                            hideFlagForUser={contactHideForUser}
                            sections={sections}
                            adminFlag={adminFlag}
                          />
                        </div>}

                      {/* usages metrics */}
                      {currentTab.TabUID === "usages_metrics" &&
                        <div
                          onScroll={(e) => this.scrollContent(e)}
                        >
                          {sections && sections.length > 0 && sections.map(section => {
                            if (section.IsDeletable && section.IsEditable) {
                              return (
                                <CustomSection
                                  key={section.SectionUID}
                                  section={section}
                                  isEditable={(section.Permission === "write") || (section.Access === 2)}
                                  productID={productDetails.productID}
                                />
                              )
                            }
                            if (section.SectionUID === "prod_usages_metrics" && sectionObj.prod_usages_metrics) {
                              return (

                                <div className={classes.itemSection}>
                                  {this.props.userType === "OrgAdmin" && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                                  <MetricVendor
                                    params={{ id: this.props.match?.params?.id }}
                                    isOrgProductDetaisPage
                                    sections={sections}
                                    hideFlagForUser={metricHideForUser}
                                    adminFlag={adminFlag}
                                    isEditable={(section.Permission === "write") || (section.Access === 2)}
                                  />
                                </div>
                              )
                            } else {
                              return (<>/</>)
                            }
                          })}
                        </div>}

                      {/* Evaluation & Reviews */}
                      {currentTab.TabUID === "evaluation_reviews" &&
                        <div
                          onScroll={(e) => this.scrollContent(e)}
                        >
                          {sections && sections.length > 0 && sections.map(section => {
                            if (section.IsDeletable && section.IsEditable) {
                              return (
                                <CustomSection
                                  key={section.SectionUID}
                                  section={section}
                                  isEditable={(section.Permission === "write") || (section.Access === 2)}
                                  productID={productDetails.productID}
                                />
                              )
                            }
                            else if (section.SectionUID === 'review_frequency' && evaluationHideForUser.review_frequency) {
                              return (
                                <Grid container className={classes.itemSection} style={{ position: 'relative' }}>
                                  {this.props.userType === "OrgAdmin" && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}

                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className={classnames(classes.formGroup, classes.groupFlexLeft)}>
                                      <p className={classes.head}> Review Frequency</p>

                                      {this.props.productDetails?.timeForReReview === 0 &&
                                        <>
                                          <p>
                                            {
                                              this.state.isReviewFrequency === false &&
                                              <>
                                                {ReviewFrequency > 0 ? `${ReviewFrequency} Months` : `No data`}
                                              </>
                                            }
                                            {!this.state.isReviewFrequency && ((section.Permission === "write") || (section.Access === 2)) && <EditIcon fontSize={"small"} className={classes.editIcon} onClick={() => { this.setState({ isReviewFrequency: true, reviewFrequency: this.props.productDetails?.timeForReReview }) }} />}
                                          </p>
                                        </>
                                      }

                                      {this.props.productDetails?.timeForReReview > 0 &&
                                        <>
                                          <p>
                                            {
                                              this.state.isReviewFrequency === false &&
                                              <>
                                                {this.props.productDetails?.timeForReReview > 0 ? `${this.props.productDetails?.timeForReReview} Months` : `No data`}
                                              </>
                                            }
                                            {!this.state.isReviewFrequency && ((section.Permission === "write") || (section.Access === 2)) && <EditIcon fontSize={"small"} className={classes.editIcon} onClick={() => { this.setState({ isReviewFrequency: true, reviewFrequency: this.props.productDetails?.timeForReReview }) }} />}
                                          </p>
                                        </>
                                      }

                                      {this.state.isReviewFrequency && ((section.Permission === "write") || (section.Access === 2)) &&
                                        <Grid container spacing={3} className={classnames(classes.reviewFrequency, classes.inputSection)}>
                                          <Grid item sm={6} md={4} lg={4}>
                                            <CreatableSelect
                                              isClearable
                                              className={classnames(
                                                classes.formControl,
                                                classes.creatable
                                              )}
                                              isSearchable={false}
                                              options={this.state.reviewOption}
                                              classNamePrefix="select"
                                              placeholder="Choose review frequency"
                                              defaultValue={
                                                this.getReviewValue(this.props.productDetails?.timeForReReview > 0 ? this.props.productDetails?.timeForReReview : parseInt(ReviewFrequency))
                                              }
                                              onInputChange={(e) => { }}
                                              onChange={(e) => {
                                                this.setState({ reviewFrequency: e?.value });
                                              }}
                                            />
                                          </Grid>
                                          <Grid item sm={6} >
                                            <Button className={classes.tickMark} onClick={() => this.saveReviewFrequency()}><DoneIcon /></Button>
                                            <Button className={classes.tickMark} onClick={() => this.setState({ isReviewFrequency: false })}><CloseIcon /></Button>
                                          </Grid>
                                        </Grid>}
                                    </div>
                                  </Grid>
                                </Grid>
                              )
                            } else if (section.SectionUID === 'prod_evaluation_reviews' && evaluationHideForUser.prod_evaluation_reviews) {
                              return (
                                <Grid container className={classes.itemSection} style={{ position: 'relative' }}>
                                  {this.props.userType === "OrgAdmin" && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}

                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className={classes.addReviewBtn}>
                                      {this.state.enableReviewForAdminsCheck && adminFlag && <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          if (adminFlag) {
                                            this.props.history.push(`/app/products/product-review/${this.props.match?.params?.id}/`)
                                          }
                                        }}>+ Review
                                      </Button>}
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className={classes.bottomHead}>
                                      <p className={classes.head}> In Progress</p>
                                    </div>
                                    <div>
                                      {this.props.requestInprogressProductData &&
                                        this.props.requestInprogressProductData?.Data?.map(
                                          (item, index) => (
                                            <div
                                              className={classes.evaluateCard}
                                              key={index}
                                              onClick={() => this.routeChange(item.ID)}
                                            >
                                              <span
                                                className={classnames(
                                                  classes.evaluateType,
                                                  item.Type === 1
                                                    ? classes.isEvaluate
                                                    : classes.isReview
                                                )}
                                              >
                                                {item.Type === 1 ? "Evaluation" : item.Type === 2 ? 'Review' : 'Survey'}
                                              </span>
                                              <div className={classes.evaluateText}>
                                                <p className={classes.evaluateCardTitle}>
                                                  {item.Name}
                                                </p>
                                                <p className={classes.evaluateCardSubTitle}>
                                                  Updated At:{" "}
                                                  {CommonFn.formateDate(item.UpdatedAt)}
                                                </p>
                                              </div>
                                              <div className={classes.evaluateLogo}>
                                                <div className={classes.productLogoContainer}>
                                                  {item.Products?.slice(
                                                    0,
                                                    item.Products?.length < 5
                                                      ? item?.Products?.length
                                                      : 3
                                                  ).map((item, i) => {
                                                    return (
                                                      <div
                                                        className={classes.productLogo}
                                                        title={item.Name}
                                                      >
                                                        <Image
                                                          src={item.ProductLogo}
                                                          alt={item.Name}
                                                          title={item.Name}
                                                        />
                                                      </div>
                                                    );
                                                  })}
                                                  {item.Products?.length > 4 && (
                                                    <div
                                                      className={classes.productLogo}
                                                      title={
                                                        item?.Products?.length -
                                                        3 +
                                                        "more product"
                                                      }
                                                    >
                                                      {" "}
                                                      + {item.Products?.length - 3}
                                                    </div>
                                                  )}
                                                </div>
                                                <Image
                                                  src={RightArrow}
                                                  className={classes.rightArrow}
                                                />
                                              </div>
                                            </div>
                                          )
                                        )}
                                      {!(this.props.requestInprogressProductData?.Data?.length === this.props.requestInprogressProductData?.total) && (
                                        <div style={{ padding: "16px 0px", textAlign: "center" }}>
                                          <span
                                            onClick={() => {
                                              var me = this;
                                              let temp = Object.assign(
                                                this.state.reviewInprogress
                                              );
                                              temp["page"] = temp.page + 1;
                                              this.setState(
                                                { reviewInprogress: { ...temp } },
                                                () => {
                                                  me.fetchOrgproductInprogressReview();
                                                }
                                              );
                                            }}
                                            className={classes.showMore}
                                          >
                                            Load more
                                          </span>
                                        </div>
                                      )}
                                      {this.props.fetchInprogressProductRequestProgress && (
                                        <span className={classes.loader}>
                                          <CircularProgress />
                                        </span>
                                      )}
                                      {!this.props.fetchInprogressProductRequestProgress && this.props.requestInprogressProductData?.Data?.length === 0 && (
                                        <span className={classes.noMoreData}>No data </span>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className={classes.bottomHead}>
                                      <p className={classnames(classes.head)}>Completed</p>
                                    </div>
                                    <div>
                                      {this.props.requestCompletedProductData && this.props.requestCompletedProductData?.Data?.map((item, index) => (
                                        <div
                                          className={classes.evaluateCard}
                                          key={index}
                                          onClick={() => this.routeChange(item.ID)}
                                        >
                                          <span
                                            className={classnames(
                                              classes.evaluateType,
                                              item.Type === 1
                                                ? classes.isEvaluate
                                                : classes.isReview
                                            )}
                                          >
                                            {item.Type === 1 ? "Evaluation" : "Review"}
                                          </span>
                                          <div className={classes.evaluateText}>
                                            <p className={classes.evaluateCardTitle}>
                                              {item.Name}
                                            </p>
                                            <p className={classes.evaluateCardSubTitle}>
                                              Updated At:{" "}
                                              {CommonFn.formateDate(item.UpdatedAt)}
                                            </p>
                                          </div>
                                          <div className={classes.evaluateLogo}>
                                            <div className={classes.productLogoContainer}>
                                              {item.Products?.slice(
                                                0,
                                                item.Products?.length < 5
                                                  ? item.Products?.length
                                                  : 3
                                              ).map((item, i) => {
                                                return (
                                                  <div
                                                    className={classes.productLogo}
                                                    title={item.Name}
                                                    key={i}
                                                  >
                                                    <Image
                                                      src={item.ProductLogo}
                                                      alt={item.Name}
                                                      title={item.Name}
                                                    />
                                                  </div>
                                                );
                                              })}
                                              {item.Products?.length > 4 && (
                                                <div
                                                  className={classes.productLogo}
                                                  title={
                                                    item.Products?.length -
                                                    3 +
                                                    "more product"
                                                  }
                                                >
                                                  {" "}
                                                  + {item.Products?.length - 3}
                                                </div>
                                              )}
                                            </div>
                                            <Image
                                              src={RightArrow}
                                              className={classes.rightArrow}
                                            />
                                          </div>
                                        </div>
                                      )
                                      )}
                                      {!(this.props.requestCompletedProductData?.Data?.length === this.props.requestCompletedProductData?.total) && (
                                        <div style={{ padding: "16px 0px", textAlign: "center" }}>
                                          <span
                                            onClick={() => {
                                              var me = this;
                                              let temp = Object.assign(
                                                this.state.reviewCompleted
                                              );
                                              temp["page"] = temp.page + 1;
                                              this.setState(
                                                { reviewCompleted: { ...temp } },
                                                () => {
                                                  me.fetchOrgproductCompletedReview();
                                                }
                                              );
                                            }}
                                            className={classes.showMore}
                                          >
                                            Load more
                                          </span>
                                        </div>
                                      )}
                                      {this.props.fetchCompletedProductRequestProgress && (
                                        <span className={classes.loader}>
                                          <CircularProgress />
                                        </span>
                                      )}
                                      {!this.props.fetchCompletedProductRequestProgress && this.props.requestCompletedProductData?.Data?.length === 0 && (
                                        <span className={classes.noMoreData}>No data </span>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              )
                            } else {
                              return (<></>)
                            }
                          })}
                        </div>}

                      <div
                        onScroll={(e) => this.scrollContent(e)}
                        hidden={!(currentTab.TabUID === "security_n_privacy")}
                      >
                        <SecurityAndPrivacyTab
                          productDetails={productDetails}
                          hideFlagForUser={securityHideForUser}
                          updateProducts={this.updateProducts}
                          sections={sections}
                          adminFlag={adminFlag}
                        />
                      </div>

                      {/* Finance */}
                      {currentTab.TabUID === "contract_details" &&
                        <div
                          onScroll={(e) => this.scrollContent(e)}
                        >
                          <FinanceTab
                            productName={productDetails?.name}
                            hideFlagForUser={financeHideForUser}
                            sections={sections}
                            adminFlag={adminFlag}
                            productDetails={productDetails}
                          />
                        </div>}
                    </>
                  )}
                </div>
              </div>
              <div
                id="homeCart"
                onMouseEnter={() => this.cartOpen()}
                onMouseLeave={() => this.cartClose()}
                className={classnames(
                  classes.cartSection,
                  this.state.isCartShow ? "showCart" : ""
                )}
              >
                {isCart && (
                  <div>
                    <CartDrawer />
                  </div>
                )}
                <DialogOrgProductChampions saveData={this.saveData} />
                <DialogOrgProductWorkflow saveData={this.saveProductWorkflow} workflowID={workflowID} />
                {/* <DialogOrgProductChampions saveData={data => alert(JSON.stringify(data))} /> */}
              </div>
            </div>
          </div>
        }

        {this.props.isOpenUserDialog && <SearchExternalUserPoper
          selectedUsers={this.props.additionalDataForExternalUser?.selectedUsers || []}
          onAdd={(user, access) => {
            let data = {
              user_id: user.ID,
              sections: [this.props.additionalDataForExternalUser?.sectionId],
              access: access
            }
            this.props.addProductExternalCollaborator(this.props.match?.params?.id, data);
          }}
          isLoading={this.props.externalCollaboratorAddProgress || this.props.externalCollaboratorRemoveProgress}
          onRemove={(user) => {
            this.props.removeProductExternalCollaborator(user.ID, this.props.match?.params?.id, this.props.additionalDataForExternalUser?.sectionId)
          }}
          onClose={() => {
            this.props.hideSearchExternalUsersPoperDialog();
          }}
        />}
        {
          <div hidden={productDetails}>
            <CircularProgress />
          </div>
        }
        {this.props.isOpenTaskDialog && <AddUpdateTaskDialog />}
        {this.state.openMsgDialog && <MessageDialog messageData={messageData} callBack={this.callBack} />}
        {this.props.showNewWorkflowStartDialog && <StartWorkflowDialog />}
        {this.props.isOpenReqAccInfoDialog && <RequestAccessInformation />}
        {this.state.showRequestAccessConfigDialog &&<DialogOrgProductRequestAccess 
          RequestAccessConfig={RequestAccessConfig}
          handleClose={()=>{this.setState({ showRequestAccessConfigDialog:false })}}
          handleSubmit={(data)=>{
            console.log(data, "data")
           
            this.saveRequestAccessConfig(data)
          }}
          open={this.state.showRequestAccessConfigDialog}
        />}
      </div>
    );
  }
}

export default connector(
  compose(withRouter, withStyles(styles))(OrgProductDetails)
);