/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {SAMLFactory} from "../../services/saml";

export const START_SAML_CONFIG_FETCH     =   "START_SAML_CONFIG_FETCH";
export const END_SAML_CONFIG_FETCH       =   "END_SAML_CONFIG_FETCH";

//Action to start the registration
function startSAMLConfigFetch(){
    return {
        type:START_SAML_CONFIG_FETCH,
        payload:null
    };
}

function endSAMLConfigFetch(type,success,error){
    return {
        type:END_SAML_CONFIG_FETCH,
        payload:{
            type,success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchConfig(){
    return async (dispatch) => {
        dispatch(startSAMLConfigFetch());
        try{
            let [response,error]    =   await SAMLFactory.instance().getAppConfig();
            dispatch(endSAMLConfigFetch("Google SAML",response,error));
        } catch(e){
            dispatch(endSAMLConfigFetch("Google SAML",null,{
                message:e.message
            }));
            return;
        }
    };
}   