import React from "react";
import classnames from "classnames";
import { createStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (theme) =>
  createStyles({
    Card: {
      border: "2px solid #EEEEEE",
      borderRadius: "10px",
      background: "#fff",
      padding: theme.spacing(2),
      cursor: "pointer",
    },
    activeTemplate: {
      border: "2px solid #4A87F8",
    },
    CardAlign: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    templateName: {
      fontSize: theme.spacing(2),
      fontWeight: 500,
    },
  });

const Card = ({ classes, template, selectedForm, onClick }) => (
  <div
    className={classnames(classes.Card, {
      [classes.activeTemplate]: selectedForm?.ID === template.ID,
    })}
  >
    <div className={classnames(classes.CardAlign)}>
      <Typography variant={"h5"} className={classes.templateName}>
        {template.FormTitle}
      </Typography>
    </div>
  </div>
);

export default React.memo(withStyles(styles)(Card));
