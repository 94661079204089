import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OverallScoreChart = ({ getBackgroundColor, products, scores, maxScore, productScoreMap }) => {
  // console.log(productScoreMap, 'productScoreMap5555')
  const getChartData = () => {
    var data = [];
    var backgroundColor = [];
    var labels = [];

    for (var productId in products) {
      var product = products[productId];
      labels.push(product.Name);
      var overAllTotalScore = 0;
      if (productScoreMap[product?.ID] !== undefined) {
        var overallScore = productScoreMap[product?.ID]?.OverallScore;
        var overAllScoreOpted = overallScore?.TotalOpted === null ? 0 : overallScore?.TotalOpted;
        var overAllScoreMax = overallScore?.TotalMax === null ? 0 : overallScore?.TotalMax;
        overAllTotalScore = (overAllScoreOpted / overAllScoreMax * 100);
        if (isNaN(overAllTotalScore)) {
          overAllTotalScore = 0;
        }
      }
      let finalScore = Math.round(overAllTotalScore);
      data.push(finalScore);
      backgroundColor.push(getBackgroundColor(finalScore));
      // var product_score = 0;
      // if (scores[product.ID] !== undefined) {
      //   var score_obj = scores[product.ID];
      //   var totalOpted = 0;
      //   var totalMax = 0;
      //   for (var criteriId in score_obj) {
      //     var criteriScore = score_obj[criteriId];
      //     totalOpted += criteriScore.totalOpted;
      //     totalMax += criteriScore.totalMax;
      //   }

      //   console.log('=================Score===================')
      //   console.log(totalOpted)
      //   console.log(totalMax)

      //   let overAllTotalScore = (totalOpted / totalMax * 100);
      //   if (isNaN(overAllTotalScore)) {
      //       overAllTotalScore = 0;
      //   }

      //   if (totalMax !== 0) {
      //     // product_score = Math.round(
      //     //   parseFloat((parseFloat(totalOpted) / parseFloat(totalMax)) * 100)
      //     // );

      //     product_score = ((Math.round(overAllTotalScore) * maxScore) / 100);

      //   }
      // }

      // data.push(product_score);
      // backgroundColor.push(getBackgroundColor(product_score));
    }

    const chartdata = {
      labels,
      datasets: [
        {
          label: "",
          color: "#20253A",
          barThickness: 25,
          barWidth: 0.5,
          backgroundColor,
          data,
        },
      ],
    };

    return chartdata;
  };

  const getOptions = () => {
    const images = products.map(product => ({
      src: '',
      width: 24,
      height: 24,
    }))
    return {
      maintainAspectRatio: false,
      events: [],
      responsive: true,
      layout: {
        padding: {
          top: 24,
        },
      },
      label: "",
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          anchor: 'end',
          align: 'top',
          formatter: (value,context) => {
            return value + "%"
          }
        }
      },
      animation: {
        duration: 1,
        // onComplete: function () {
        //   var chartInstance = this.chart,
        //     ctx = chartInstance.ctx;
        //   ctx.font = Chart.helpers.fontString(12, 12, Chart.defaults.global.defaultFontFamily);
        //   ctx.textAlign = 'center';
        //   ctx.textBaseline = 'bottom';

        //   this.data.datasets.forEach(function (dataset, i) {
        //     var meta = chartInstance.controller.getDatasetMeta(i);
        //     meta.data.forEach(function (bar, index) {
        //       var data = dataset.data[index];
        //       ctx.fillText(`${data}%`, bar._model.x, bar._model.y - 2);
        //     });
        //   });
          
        // }
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        x: {
            barPercentage: 1,
            grid: {
              display: false,
              offsetGridLines: false,
            },
            ticks: {
              font: {
                color: "#20253A",
                size: 13,
                weight: "bold",
              }
            },
          },
        y: {
            grid: {
              drawBorder: false,
            },
            ticks: {
              font: {
                color: "#A8A8A8",
                size: 10,
              },
              stepSize: 20,
              padding: 20,
              callback: function (value) {
                return `${value}%`;
              },
            },
            min: 0,
            max: 100,
          },
      },
    };
  };

  return <Bar height={210} data={getChartData()} options={getOptions()} />;
};

export default React.memo(OverallScoreChart);