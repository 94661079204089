import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
 
import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import uniqid from 'uniqid';
import Select from 'react-select';
import { generate } from 'shortid';
import classnames from 'classnames';
import { createStyles, withStyles } from '@material-ui/core/styles';
import * as Validator from "util/Validation";
import {
  Grid, IconButton, Typography, 
  FormControlLabel, Checkbox, 
  TextField, ListItem, List, Divider, ListItemIcon,
  Popover, CircularProgress
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CreateIcon from '@material-ui/icons/Create';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import SqureButton from 'Components/Common/SqureButton';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import CommonCss from 'commonCss';
import { itemTypes, binaryOptions } from './data';

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({root: {
  position: 'relative',
  border: '1px solid #d8d8d8',
  padding: theme.spacing(2),
  borderRadius: 5,
  marginBottom: theme.spacing(4),
  background: '#FFF'
},
productLink: {
  fontSize: 13,
  marginBottom: 0,
  color: 'black',
  fontWeight: 400,
},
itemContainer: {
  position: 'relative',
  border: '1px solid #d8d8d8',
  padding: theme.spacing(2, 2, 0, 2),
  borderRadius: 5,
  background: '#FFF',
  marginBottom: theme.spacing(4),
},
...CommonCss.ProductDetails,
formControl: {
  ...CommonCss.formControl,
  '& > div': {
    backgroundColor: 'tarsparent !important',
    border: 'none',
    paddingRight: 0,
  },
  padding: '.375rem',
},
mb16: {
  marginBottom: 16,
},
formGroup: {
  '& input': {
    color: '#6C6C6C !important'
  }
},
dialogContent: {
  minHeight: 170,
  overflowY: 'hidden',
  "& [class*=select__menu]": {
    maxHeight: 100
  }
},
fieldContainer: {
  marginBottom: 16,
},
actionIcon: {
  width: 30,
  height: 30,
  borderRadius: 5,
  textAlign: 'center',
  marginLeft: 5,
  border: '1px solid #4b86f8'
},
btn: {
  background: "#3C3C3C",
  borderRadius: 5,
  color: "#fff",
  fontSize: theme.spacing(1.9),
  minHeight: 35,
  padding: 0,
  marginLeft: 15,
  minWidth: 140,
  "&:hover": {
    background: "#3C3C3C",
  },
},
micon: {
  fontSize: 16,
  marginTop: 6,
  color: '#4b86f8',
},
headerContainer: {
  marginBottom: 16,
},
paper: {
  boxShadow: 'none',
  border: '1px solid #ddd'
},
tableCell: {
  width: "25%",
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100vw - 99vw)'
},
tabledeleteCell: {
  width: "10%"
},
resourceHead: {
  padding: 10
},
iconEle: {
  width: 18,
  marginLeft: 8,
  cursor: "pointer",
},
type: {
  fontSize: 14,
},
datePicker: {
  "& .react-datepicker-wrapper": {
    width: '30%',
  },
  "& .react-datepicker__input-container > div": {
    display: 'flex',
    marginBottom: 0,
    alignItems: 'center',
    paddingLeft: '8px !important',
    "& [class*=MuiInputBase-root]": {
      maxHeight: 33,
      minHeight: 33,
      width: 'inherit',
    },
    "& input": {
      fontSize: theme.spacing(1.8),
      padding: '14px 5px 14px 2px !important',
      textAlign: "center"
    },
    "& [class*=MuiFormLabel-root]": {
      marginBottom: 0,
      marginRight: 29
    }
  },
  "[class*=MuiMenuItem-root]": {
    color: "#707070",
    fontSize: theme.spacing(1.8)
  },
},
inputContainer: {
  flexDirection: 'row',
  boxSizing: 'border-box',
  display: 'flex',
  placeContent: 'center flex-start',
  alignItems: 'center',
  width: '100%',
  marginBottom: 16,
},
inputRow: {
  display: 'flex',
  alignItems: 'center',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #EBEBEB',
  borderRadius: '4px',
  padding: '5px',
  width: 'calc(100% - 35px)',
},
count: {
  display: 'inline-block',
  width: '20px',
  textAlign: 'center',
  color: '#6D7278',
  fontSize: '13px'
},
input: {
  border: '0px',
  height: '30px',
  width: 'calc(100% - 20px)',
  outline: 0,
  color: '#6D7278',
  fontSize: '13px'
},
dragIndicator: {
  color: '#D9D9D9',
  position: 'absolute',
  top: 15,
  left: '50%',
  transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
},
deleteIcon: {
  position: "absolute",
  right: 16,
  top: 13,
  cursor: "pointer",
},
cardSection: {
  position: "relative",
  flex: 1,
},
contactCardHeader: {
  flexDirection: "column",
  padding: "16px 4px !important",
  "& [class*='MuiCardHeader-avatar-']": {
    margin: "0px",
    marginBottom: 12,
  },
  "& [class*='MuiCardHeader-content']": {
    width: "100%",
  },
  "& [class*=MuiTypography-displayBlock]": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    margin: "0px -19px 0px 0px",
  },
},
contactCardRoot: {
  cursor: "pointer",
},
addNewChamp: {
  display: "block",
  textAlign: "center",
  color: "#bdbdbd",
  "& span": {
    display: "block",
    cursor: "pointer",
    color: "#000000",
  },
},
formControlDialog: {
  ...CommonCss.formControl,
  '& > div': {
    backgroundColor: 'tarsparent !important',
    border: 'none',
    paddingRight: 0,
  },
  padding: '.375rem',
},
formGroupDialog: {
  marginTop: 5,
  marginBottom: 16,
  '& input': {
    color: '#6C6C6C !important'
  }
},
resourceLinkText: {
  color: '#4b86f8 !important',
  cursor: 'pointer',
},
chipContainer: {
  padding: theme.spacing(1),
  display: 'flex',
  flexWrap: 'wrap',
  '& > *': {
    margin: theme.spacing(0.5),
  },
},
itemHead: {
  fontSize: '15px',
  fontWeight: 'bold',
},
borderNone: {
  border: 'none',
  padding: 0,
  minHeight: 0,
  '& [class*="MuiInputBase-root"]': {
    color: '#6D7278',
    fontSize: 13
  }
},
deleteImage: {
  cursor: "pointer",
},
description: {
  ...CommonCss.ProductDetails.description,
  "& p": {
    margin: "0px",
  },
  "& [class*=editor-editableField]": {
    minHeight: "auto !important",
    margin: "0px !important",
  },
  "& [class*=editor-formControl]": {
    border: "none !important",
  },
},
platformTitle: {
  marginTop: 0,
  fontSize: '0.83em',
  color: 'rgba(0, 0, 0, 0.87)'
},
platFormItem: {
  '& > img': {
    width: 50,
    height: 50,
  }
},
platformName: {
  fontSize: '0.83em',
  color: 'rgba(0, 0, 0, 0.4)'
},
productCard: {
  textAlign: 'center',
  border: '3px solid #EEEEEE',
  padding: '0em 0.25em',
  background: '#fff',
  borderRadius: '1em',
  width: '100%',
},
contactCard: {
  position: 'relative',
  textAlign: 'center',
  border: '1px solid #EEEEEE',
  padding: '0.25em',
  background: '#fff',
  borderRadius: 5,
  width: '100%',
},
reviewCard: {
  position: 'relative',
  border: '1px solid #EEEEEE',
  padding: '0.25em',
  background: '#fff',
  borderRadius: 5,
  width: '100%',
},
financialDetailsContainer: {
  position: 'relative',
  border: '1px solid #EEEEEE',
  padding: '0.25em',
  background: '#fff',
  borderRadius: 5,
  width: '100%',
},
label: {
  color: '#000000de',
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: '1.6px',
},
value: {
  color: '#000000de',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.65x',
},
tag: {
  position: 'absolute',
  right: 0,
  top: 0,
  background: '#E6FFF3',
  fontSize: '0.83em',
  color: '#2EB77D',
  padding: '3px 10px',
},
vendirImage: {
  width: 'auto',
  height: 'auto',
  maxWidth: '80px',
  maxHeight: '80px',
  margin: '0px auto'
},
card: {
  boxShadow: "none",
  '&.details-border': {
    border: '1px solid #ddd'
  }
},
statsContainer: {
  display: "flex",
  margin: theme.spacing(6, 0),
  padding: theme.spacing(1, 0),
},
vendorInfo: {
  padding: '0 1em',
  textAlign: "center",
  flex: 1,
},
stats: {
  flex: 1,
  textAlign: "center",
  flexDirection: 'column',
  boxSizing: 'border-box',
  display: 'flex',
  placeContent: 'center space-around',
  alignItems: 'center',
  maxWidth: '150px'
},
divider: {
  borderRight: "solid 1px #ccc",
},
active: {
  display: "inline-block",
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  background: "#33C162",
},
inactive: {
  display: "inline-block",
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  background: "#9FA0A5",
},
vendirImageContainer: {
  height: '80px',
  marginBottom: '10px',
  display: 'flex',
  alignItems: 'center'
},
stateTitle: {
  color: "#20253A",
  opacity: 0.7,
  fontSize: theme.spacing(1.8),
  marginBottom: theme.spacing(2),
},
stateValue: {
  color: "#20253A",
  fontSize: theme.spacing(3.2),
  fontWeight: 400
},
vendorTitle: {
  // fontWeight: 900,
  // fontSize: theme.spacing(2),
  overflow: 'hidden',
  fontSize: theme.spacing(2.4),
  fontWeight: 400
  // marginBottom: '30px'
},
tempSection: {
  position: "absolute",
  zIndex: 2,
  width: 200,
  opacity: 0,
  height: 47,
  bottom: 2,
},
downloadSection: {
  position: "relative",
},
chartSection: {
  maxWidth: "calc(100vw - 412px)",
  overflowX: "auto",
  overflowY: "hidden",
  padding: "0px 10px 10px 10px",
},
chartCard: {
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  justifyContent: "space-between",
  padding: 0,
  height: "100%",
},
chartLabel: {
  color: "#4B4B4B",
  fontSize: 10,
  margin: 0,
  textAlign: "center",
  padding: 10,
},
editIcon: {
  color: "#4A87F8",
  verticalAlign: "middle",
  cursor: 'pointer'
},
department: {
  cursor: "pointer"
},
dropdownTeamCol: {
  display: 'flex',
  alignItems: 'center',
  width: '200px',
  marginLeft: '10px',
  '& [class*="-container"]': {
    flex: 1,
    height: 39,
    '& .select__placeholder': {
      color: '#000',
      fontSize: '16px'
    },
    '& [class*="-ValueContainer"]': {
      color: '#000',
      fontSize: '16px'
    }
  }
},
meta: {
  margin: theme.spacing(3, 0),
},
cardData: {
  boxShadow: "none",
  border: '1px solid #ddd'
}});

class CustomFormBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      sectionUid: null,
      visibility: 3,
      items: [{
        isNew: true,
        name: "", type: 1, additional_data: { type: 0 },
        desciption: null, visibility: 3, is_multiselect: false,
        itemUid: uniqid("item-", `-${Number(new Date())}`)
      }],
      isDeletable: true,
      isEditable: true,
      activeItem: null,
      activeThead: false,
      open: false,
      anchorEl: null,
      editName: null,
      focusedOption: 0,
    }
  }

  componentDidMount() {
    const { config, isNew } = this.props;
    const data = {
      name: config?.SectionName || this.state.name,
      sectionId: config?.ID,
      visibility: config?.Visibility,
      isDeletable: config?.IsDeletable || this.state.isDeletable,
      isEditable: config?.IsEditable || this.state.isEditable,
    }

    if (!isNew && config?.OrgWorkflowComponentFormSectionItems && config?.OrgWorkflowComponentFormSectionItems.length > 0) {
      data.items = config?.OrgWorkflowComponentFormSectionItems.map(item => ({
        name: item.ItemName,
        type: item.Type,
        additional_data: Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : { type: 0 },
        desciption: item.Type === 9 ? parseInt(item.Description) : item.Description,
        visibility: item.Visibility,
        itemId: item.ID,
        itemUid: uniqid("item-", `-${Number(new Date())}`),
        is_multiselect: item.IsMultiselect,
        itemInfo : item
      }))
    }
    this.setState(data)
  }

  componentDidUpdate(prevProps) {
   
  }

  handleSave = () => {
    const { name, visibility, sectionUid, items, data } = this.state;
    if (this.props.isNew) {
      this.props.onSave({ name, visibility, sectionUid, items })
    } else {
      this.props.onUpdate({ name, visibility, items, data })
    }
  }

  handleUpdateItemValue = (name, key, index) => {
    const items = this.state.items
    const item = items[index]
    item[key] = name
    items[index] = item
    this.setState({ items })
  }

  handleChangeFile = (index, type, event, current) => {
    const checked = event.target.checked
    let currentCheck = current
    if (type === 'link') {
      if (currentCheck === 3 && !checked) {
        currentCheck = 2
      } else if (currentCheck === 2 && checked) {
        currentCheck = 3
      } else if (currentCheck === 1 && !checked) {
        currentCheck = 0
      } else if (currentCheck === 0 && checked) {
        currentCheck = 1
      }
    } else {
      if (currentCheck === 3 && !checked) {
        currentCheck = 1
      } else if (currentCheck === 1 && checked) {
        currentCheck = 3
      } else if (currentCheck === 2 && !checked) {
        currentCheck = 0
      } else if (currentCheck === 0 && checked) {
        currentCheck = 2
      }
    }
    const items = this.state.items
    items[index].additional_data.file = currentCheck
    this.setState({ items })
  }

  handleSectionVisibility = (type, current, isVisible) => {
    if (!this.props.isActive) return true;
    let visibility = current
    if (type === 'admin') {
      if (visibility === 3 && !isVisible) {
        visibility = 2
      } else if (visibility === 2 && isVisible) {
        visibility = 3
      } else if (visibility === 1 && !isVisible) {
        visibility = 0
      } else if (visibility === 0 && isVisible) {
        visibility = 1
      }
    } else {
      if (visibility === 3 && !isVisible) {
        visibility = 1
      } else if (visibility === 1 && isVisible) {
        visibility = 3
      } else if (visibility === 2 && !isVisible) {
        visibility = 0
      } else if (visibility === 0 && isVisible) {
        visibility = 2
      }
    }
    this.setState({ visibility })
  }

  handleChangeBinaryOptionLabel = (index, optionIndex, label) => {
    const items = this.state.items
    const item = items[index]
    item.additional_data[optionIndex].label = label
    items[index] = item
    this.setState({ items })
  }

  handleTextType = (value, index) => {
    const items = this.state.items
    let item = items[index]
    item.additional_data = { type: value }
    items[index] = item
    this.setState({ items })
  }

  handleChangeType = (e, index) => {
    const items = this.state.items
    const item = items[index]
    item.type = e?.value || 1
    const activeThead = false
    item.desciption = null
    item.is_multiselect = e && e?.value === 5 ? true : false
    item.additional_data = e && e?.value === 3 ? binaryOptions : []
    if (e && e?.value === 9) {
      item.additional_data = { file: 3 }
    }
    if (e && e?.value && e?.value === 7) {
      item.additional_data = { auto: true }
    }
    if (e && e?.value && e?.value === 1) {
      item.additional_data = { type: 0 }
    }
    items[index] = item
    this.setState({
      items,
      activeThead
    })
  }

  handleEditOption = (label, index, i) => {
    const items = this.state.items
    const item = items[index]
    item.additional_data[i].label = label
    this.setState({ items })
  }

  handleAddOption = (index) => {
    const items = this.state.items
    const item = items[index]
    item.additional_data.push({ label: "", value: Number(new Date()), id: Number(new Date()) })
    items[index] = item
    this.setState({ items })
  }

  handleRemoveOption = (index, i) => {
    const items = this.state.items
    const item = items[index]
    item.additional_data.splice(i, 1)
    items[index] = item
    this.setState({ items })
  }

  handleAddUser = (data) => {
    const { items, activeItem } = this.state
    const item = items[activeItem]
    item.additional_data.push(data)
    items[activeItem] = item
    this.setState({ items, activeItem: null })
  }

  handleDeleteUser = (itemIndex, userIndex) => {
    const { items, activeItem } = this.state
    const item = items[itemIndex]
    item.additional_data.splice(userIndex, 1)
    items[activeItem] = item
    this.setState({ items, activeItem: null })
  }

  handleAddItem = () => {
    const items = this.state.items
    items.push({
      isNew: true,
      name: "", type: 1, additional_data: { type: 0 },
      desciption: null, visibility: 3, is_multiselect: false,
      itemUid: uniqid("item-", `-${Number(new Date())}`)
    })
    this.setState({ items })
  }

  handleRemoveIcon = (index) => {
    const items = this.state.items
    items.splice(index, 1)
    this.setState({ items })
  }

  getSectionType = (id) => {
    // ,,,
    let type = 'Simple Text'
    if ([
      'prod_subcategories', 'prod_approved_platforms', 'prod_categories', 
      'prod_approved_integrations', 'prod_feature_offered', 'prod_departments',
      'prod_perdonal_data_shared', 'prod_security_certification'
    ].includes(id)) {
      type = 'Multi Select'
    } if (['prod_business_criticality'].includes(id)) {
      type = 'Single Select'
    } else if (['prod_about'].includes(id)) {
      type = 'Rich Text'
    } else if (['prod_product_champions' , 'prod_business_poc', 'prod_vendor_poc'].includes(id)) {
      type = 'Users'
    } else if ([
      'prod_financial_details', 'prod_contact_documents', 'prod_certification_n_agreement',
      'prod_other_resources', 'prod_evaluation_reviews', 'prod_usages_metrics'
    ].includes(id)) {
      type = 'Table'
    } else if (['prod_similar_products', 'prod_other_products', 'prod_complementary_products'].includes(id)) {
      type = 'Products'
    }
    return type
  }
  
  render() {
    const { classes, isActive } = this.props;

    const {
      name, items, sectionUid
    } = this.state;

    // console.log(config,'Configurationssssssssssssssssssssss')
    // console.log(items,'Configurationssssssssssssssssssssss')

    return (
      <div className={classes.root} id={this.props.id} >
        <DragIndicatorOutlinedIcon className={classes.dragIndicator} />
        <Grid container spacing={2} alignItems="center" justify="flex-end" className={classes.headerContainer}>
          <Grid item>
            <Grid container spacing={2} alignItems="center" justify="flex-end">
              {!this.props.isNew && <Grid item>
                {isActive ? (
                  <IconButton
                    className={classes.threeDot}
                    onClick={(event) => {
                      event.stopPropagation()
                      this.setState({ open: true, anchorEl: event.currentTarget || event.current })
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                ) : (
                  <IconButton style={{ width: 34, height: 34 }} onClick={() => this.setState({ openAddTabDialog: true })}>
                    <CreateIcon
                      onClick={e => {
                        e.stopPropagation()
                        this.props.activeSection()
                      }}
                    />
                  </IconButton>
                )}
              </Grid>}
              <Popover
                id={generate()}
                open={this.state.open}
                anchorEl={this.state.anchorEl}
                onClose={() => this.setState({ anchorEl: null, open: null })}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}>
                <List>
                  {!this.props.isNew && (
                    <>
                      <Divider />
                      <ListItem
                        classes={{
                          button: classes.listItem,
                        }}
                        button
                        onClick={e => {
                          e.stopPropagation()
                          this.props.onDelete()
                        }}
                      >
                        <ListItemIcon classes={{ root: classes.listIcon }}>
                          <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          Delete Section
                        </Typography>
                      </ListItem>
                    </>
                  )}
                </List>
              </Popover>
            </Grid>
          </Grid>
        </Grid>
        <div className={classnames(classes.formGroup, classes.mb16)}>
          {isActive && (
            <TextField
              fullWidth
              className={classes.formControl}
              value={name}
              isFocused={isActive && sectionUid === this.state.focusedOption}
              InputProps={{ disableUnderline: true }}
              placeholder="Enter Section Title"
              onChange={e => this.setState({ name: e.target.value, focusedOption: sectionUid })}
            />
          )}
          {!isActive && (
            <p className={classes.head}>{name}</p>
          )}
        </div>
        {items && items.length > 0 && items.map((item, index) => (
          <div className={classes.itemContainer} key={generate()}>
            {items.length > 1 && isActive && !this.props.isLoader && (
              <Grid container justify="flex-end">
                <IconButton
                  className={classes.threeDot}
                  onClick={(event) => {
                    event.stopPropagation()
                    if (item.isNew) {
                      this.handleRemoveIcon(index)
                    } else {
                      this.props.deleteItem({itemUid:item.itemUid,itemId:item.itemId})
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
            <div className={classnames(classes.formGroup, classes.mb16)}>
              {isActive && (
                <InputField
                  classes={classes}
                  val={item.name}
                  placeholder="Enter Item Title"
                  isFocused={isActive && item.itemUid === this.state.focusedOption}
                  onChange={value => {
                    this.setState({ focusedOption: item.itemUid })
                    this.handleUpdateItemValue(value, 'name', index)
                  }}
                />
              )}
              {!isActive && (
                <p className={classes.itemHead}>{item.name}</p>
              )}
            </div>
            <div className={classes.fieldContainer}>
              {isActive ? (
                <Select
                  options={itemTypes}
                  isDisabled={!(item.isNew === true)}
                  defaultValue={itemTypes.filter(type => type.value === item.type)[0]}
                  classNamePrefix="select"
                  placeholder={"Select Type"}
                  onChange={(e) => this.handleChangeType(e, index)}
                />
              ) : (
                <Typography className={classes.type}>Type - {itemTypes.filter(type => type.value === item.type)[0]?.label}</Typography>
              )}
            </div>
            <div className={classes.fieldContainer}>
              {item.type === 3 && (
                <>
                  {item.additional_data.map((obj, i) => (
                    <div className={classes.inputContainer} >
                      <div className={classes.inputRow}>
                        <span className={classes.count}>{i + 1}</span>
                        {isActive ? (
                          <InputField
                            disabled={!isActive}
                            classes={{ formControl: classes.input }}
                            val={obj.label}
                            isFocused={isActive && `${index}-${i}` === this.state.focusedOption}
                            onChange={value => {
                              this.setState({ focusedOption: `${index}-${i}` })
                              this.handleChangeBinaryOptionLabel(index, i, value)
                            }}
                          />
                        ) : (
                          <span className={classes.count}>&nbsp;{obj.label}</span>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}
              {[4, 5, 6].includes(item.type) && (
                <>
                  {item.additional_data.map((data, i) => (
                    <div className={classes.inputContainer}>
                      <div className={classes.inputRow}>
                        <span className={classes.count}>{i + 1}.</span>
                        <InputField
                          classes={classes}
                          disabled={!isActive}
                          val={data.label}
                          border={false}
                          onFocus={() => this.setState({ focusedOption: i })}
                          isFocused={isActive && data.id === this.state.focusedOption}
                          placeholder={`${item.type === 6 ? 'Enter Column' : 'Enter Option'}`}
                          onChange={value => {
                            this.setState({ focusedOption: data.id })
                            this.handleEditOption(value, index, i)
                          }}
                        />
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      {isActive && (
                        <Image
                          src={deleteIcon}
                          className={classes.deleteImage}
                          style={{ top: 15 }}
                          onClick={() => this.handleRemoveOption(index, i)}
                        />
                      )}
                    </div>
                  ))}
                  <div>
                    {isActive && (
                      <div>
                        <span
                          onClick={() => this.handleAddOption(index)}
                          style={{
                            fontSize: "12px",
                            color: "#4175DF",
                            cursor: "pointer",
                          }}
                        >
                          + Add {`${item.type === 6 ? 'column' : 'option'}`}
                        </span>
                      </div>
                    )}
                  </div>
                </>
              )}
              {item.type === 9 && (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!isActive}
                        checked={[1, 3].includes(item.additional_data.file)}
                        onChange={e => this.handleChangeFile(index, 'link', e, item.additional_data.file)}
                        name="checkedB"
                      />
                    }
                    label="Add Link"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!isActive}
                        checked={[2, 3].includes(item.additional_data.file)}
                        onChange={e => this.handleChangeFile(index, 'file', e, item.additional_data.file)}
                        name="checkedB"
                      />
                    }
                    label="Add File"
                  />
                </div>
              )}
            </div>
          </div>
        ))}

        {
          this.props.isActive && (
            <Grid container justifyContent="space-between" direction="row-reverse" alignItems="center">
              {this.props.isLoader ? (
                <CircularProgress />
              ) : (
                <>
                  <div>
                    <SqureButton
                      variant={"outlined"}
                      onClick={() => {
                        this.props.onCancel()
                      }}
                    >
                      Cancel
                    </SqureButton>
                    &nbsp; &nbsp; &nbsp;
                    <SqureButton
                      variant={"contained"}
                      disabled={name.length === 0}
                      onClick={this.handleSave}
                    >
                      Save
                    </SqureButton>
                  </div>
                  <div>
                    <SqureButton
                      variant={"contained"}
                      onClick={this.handleAddItem}
                    >
                      Add Item
                    </SqureButton>
                  </div>
                </>
              )}
            </Grid>
          )
        }
      </div >
    )
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CustomFormBuilder));

const InputField = ({ classes, onChange, val, placeholder, disabled = false, isFocused = false, border = true, onFocus }) => {
  const [value, setValue] = useState("")
  useEffect(() => {
    setValue(val)
  }, [val])
  return (
    <TextField
      fullWidth
      autoFocus={isFocused}
      className={classnames(classes.formControl, border ? {} : classes.borderNone)}
      value={value}
      disabled={disabled}
      InputProps={{ disableUnderline: true }}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
    // onBlur={() => onChange(value)}
    />
  )
}