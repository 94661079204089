import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { Typography, IconButton, InputAdornment, FormControlLabel } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { addNewAutomationAction, updateAutomationAction } from 'redux/automations/action';
import Variables from "./Variables";
import BottomActions from "./BottomActions";
import "../../Style/control.css";
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import deleteIcon from "assets/images/delete.svg";
import Image from "Components/Common/image.jsx";
import ReactSelect from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "assets/images/calendar.svg";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import { currencyOptions, currencyCodeMap } from "util/Currency";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Radio from "@material-ui/core/Radio";
import RadioGroup from '@material-ui/core/RadioGroup';
import TextInput from "./commonTypes/TextInput";

const connectedProps = (state) => ({
  fetchMasterTriggerProgress: state.automation.fetchMasterTriggerProgress,
  fetchMasterTriggerError: state.automation.fetchMasterTriggerError,
  masterTriggers: state.automation.masterTriggers,

  fetchMasterActionProgress: state.automation.fetchMasterActionProgress,
  fetchMasterActionError: state.automation.fetchMasterActionError,
});

const connectionActions = {
  addNewAutomationAction: addNewAutomationAction,
  updateAutomationAction: updateAutomationAction
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    marginTop: 10,
    padding: 15,
    height: '85vh',
    overflow: 'auto'
  },
  conFileds: {
    marginBottom: 10
  },
  input: {
    minHeight: 38,
    padding: "2px 8px",
    border: "1px solid #cccccc",
    borderRadius: 3,
    fontSize: 15,
    color: "rgb(51, 51, 51)",
    width: '100%'
  },
  delete: {
    position: 'absolute',
    right: 10,
    top: 5,
    cursor: 'pointer',
    color: '#ed6d64'
  },
  calendarPopout: {
    zIndex: '555555 !important'
  },
});

var currencyStyles = {
  control: styles => ({ ...styles, height: 56 }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  })
};

class ControlConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      inputField: '',
      isShowVariables: false,
      focusInputData: null,
      operands: [
        {
          value: 1,
          label: "==",
        },
        {
          value: 2,
          label: "!=",
        },
        {
          value: 3,
          label: "in",
        },
        {
          value: 4,
          label: "not in",
        },
        {
          value: 5,
          label: ">",
        },
        {
          value: 6,
          label: "<",
        },
        {
          value: 7,
          label: ">=",
        },
        {
          value: 8,
          label: "<=",
        },
      ],
      conditions: [{
        dataField: '',
        operand: 1,
        value: '',
        error: '',
        additionalData: '',
        valueType: 'ABSOLUTE'
      }],
    }

    this.handleAddVariable = this.handleAddVariable.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  componentDidMount() {
    this.initInputJson()
  }

  componentDidUpdate(prevProps) {

  }

  submitResponse() {

  }

  initializeConfigurations() {
    const { componentDetails } = this.props;
    console.log(componentDetails, 'componentDetails')

    const { inputField } = this.state;
    let inputData = inputField && inputField.length > 0 ? inputField : [];
    if (inputData.length > 0 && inputData[0].type === 'RULE_EXPRESSION' && componentDetails.InputData && componentDetails.InputData !== '') {
      const responseJson = JSON.parse(componentDetails.InputData)
      if (responseJson && responseJson !== '') {
        console.log(responseJson, 'responseJson')
        this.setState({
          conditions: responseJson
        })
      }
    } else if (inputData.length > 0 && inputData[0].type === 'ARRAY' && componentDetails.InputData && componentDetails.InputData !== '') {
      const responseJson = JSON.parse(componentDetails.InputData)
      if (responseJson && responseJson !== '') {
        console.log(responseJson, 'responseJson')
        inputData[0].value = responseJson
        this.setState({
          inputField: inputData
        })
      }
    }
  }

  initInputJson() {
    const { inputRef } = this.props;
    if (inputRef && inputRef !== '') {
      this.setState({
        inputField: inputRef.input_json
      }, () => {
        this.initializeConfigurations()
      })
    }
  }

  addAction(action) {
    console.log(action, 'SelecteddddddddAction')
    const { automationDetails } = this.props;
    // const masterAction = this.getMasterAction(actionId)
    // console.log(masterAction, 'masterAction')
    let params = {
      actionID: action?.id,
      name: action?.name,
      type: 'main',
      parentId: parseInt(this.props.match.params.id),
      inputData: JSON.stringify(action?.inputJson) || '{}'
    }
    this.props.addNewAutomationAction(automationDetails.ID, params);
  }

  updateComponent(component) {
    const { automationDetails, data } = this.props;
    console.log(automationDetails, 'automationDetails')
    console.log(component, 'component')
    this.props.updateAutomationAction(automationDetails.ID, data.id, {
      refType: 'Master',
      refID: component.id
    })
  }

  updateActionComponent() {

  }

  handleChange = (value, index, type) => {
    var inputData = [...this.state.inputField];
    inputData.forEach((item, mapIndex) => {
      if (index === mapIndex) {
        item.value = value;
      }
    });
    this.setState({
      inputField: inputData,
      error: ''
    })
  }

  validateInputExpression(expression) {
    var input = this.populateInput()
    try {
      return eval(expression)
    } catch (e) {

    }
    return false;
  }

  populateInput() {
    let params = {}
    this.state.inputField.forEach(function (ifield) {
      if (ifield.type === "SINGLE_SELECT" && ifield.value && ifield.value !== '') {
        params[ifield.name] = ifield.value.value
      } else {
        params[ifield.name] = ifield.value
      }
    })
    return params;
  }

  handleFormSubmit = () => {
    this.setState({
      error: '',
      success: '',
    })

    if (this.state.inputField && this.state.inputField[0].type === 'RULE_EXPRESSION') {
      this.props.updateAutomationAction(this.props.componentDetails?.AutomationID, this.props.componentDetails.ID, {
        inputData: JSON.stringify(this.state.conditions),
      })
    } else if (this.state.inputField && this.state.inputField[0].type === 'ARRAY') {
      this.props.updateAutomationAction(this.props.componentDetails?.AutomationID, this.props.componentDetails.ID, {
        inputData: JSON.stringify(this.state.inputField[0].value),
      })
    }

  }

  addNewCondition() {
    let conditions = this.state.conditions;
    conditions.push({
      dataField: '',
      operand: 1,
      value: '',
      error: '',
      additionalData: '',
      valueType: 'ABSOLUTE'
    })
    this.setState({
      conditions: conditions
    })
  }

  deleteCondition(index) {
    let conditions = this.state.conditions;
    conditions.splice(index, 1);
    this.setState({
      conditions: conditions
    })
  }

  handleValueUpdate(field, value, index) {
    let conditions = this.state.conditions;
    let condition = conditions[index];
    condition[field] = value;
    this.setState({
      conditions: conditions
    })
  }


  handleValueTypeUpdate(field, value, index) {
    let conditions = this.state.conditions;
    let condition = conditions[index];
    condition.valueType = value;
    condition.value = '';
    this.setState({
      conditions: conditions
    })
  }

  handleChangeCurrencyValue(field, value, index) {
    let conditions = this.state.conditions;
    let condition = conditions[index];
    condition.value[field] = value;
    this.setState({
      conditions: conditions
    })
  }

  setSelected(data) {
    this.setState({
      focusInputData: data,
      isShowVariables: true
    })
  }

  handleAddVariable(component, variable) {
    const { focusInputData } = this.state;
    if (focusInputData.type === 'CONDITION') {
      var conditions = [...this.state.conditions];
      let condition = conditions[focusInputData.index];
      // let lastValue = condition[focusInputData.field];
      let newValue = 'component_' + component.componentID + '.' + variable?.name
      condition[focusInputData.field] = newValue;

      if (variable.type === 'OBJECT' && variable.name === 'Currency') {
        condition.value = {}
      } else {
        condition.value = ''
      }

      condition.additionalData = variable
      this.setState({
        conditions: conditions,
      })
    } else if (focusInputData.type === 'INPUT_FIELD') {
      var inputData = [...this.state.inputField];
      let item = inputData[focusInputData.index];
      let newValue = 'component_' + component.componentID + '.' + variable?.name
      item.value = newValue;
      this.setState({
        inputField: inputData,
        error: ''
      })
    }
  }

  getVariableMap = () => {
    const { componentVariables } = this.props;
    let data = {
      map: {},
      variables: []
    }
    if (componentVariables) {
      componentVariables.forEach(function (component) {
        component.variables.forEach(function (variable) {
          let newValue = 'component_' + component.componentID + '.' + variable?.name;
          data.map[newValue] = variable?.label;
          data.variables.push(newValue)
        })
      })
    }
    return data;
  }

  render() {
    const { classes, componentVariables } = this.props;
    const { inputField, isShowVariables, conditions } = this.state;
    let variableDatas = this.getVariableMap();
    return (
      <div className={classes.root}>
        {inputField && inputField.length > 0 &&
          inputField.map((item, index) => {
            if (item.ngIf && item.ngIf !== '') {
              if (!this.validateInputExpression(item.ngIf)) {
                return null
              }
            }
            return <div key={index} className={classes.inputSection}>
              {item.type === 'RULE_EXPRESSION' &&
                <div className={classes.inputBoxes}>
                  <div class="controls">
                    {conditions && conditions.map((con, index) => {
                      return <div class="control">
                        <div class="control-condition">
                          <div class="control-condition__operand">{index === 0 ? 'If' : 'And'}</div>
                          <div class="control-condition__form">
                            {conditions.length > 1 && <div className={classes.delete}>
                              <IconButton onClick={() => this.deleteCondition(index)}>
                                <Image src={deleteIcon} />
                              </IconButton>
                            </div>}
                            <div className={classes.conFileds}>
                              <Typography>Data Field</Typography>
                              {/* <input
                                className={classes.input}
                                placeholder='Enter Option'
                                onFocus={() => {
                                  this.setSelected({
                                    index: index,
                                    field: 'dataField',
                                    type: 'CONDITION'
                                  })
                                }}
                                onChange={(e) => {
                                  this.handleValueUpdate('dataField', e.target.value, index)
                                }}
                                value={con.dataField}
                              /> */}
                              <TextInput
                                mirrorFor={'CONDITION'}
                                value={con.dataField}
                                handleChange={(value) => {
                                  this.handleValueUpdate('dataField', value, index)
                                }}
                                handleFocus={() => {
                                  this.setSelected({
                                    index: index,
                                    field: 'dataField',
                                    type: 'CONDITION'
                                  })
                                }}
                                variableMap={variableDatas.map}
                                availableVariables={variableDatas.variables}
                              />
                            </div>
                            <div className={classes.conFileds}>
                              <Typography>Control</Typography>
                              <OutlinedSelectInput
                                placeholder='Operand'
                                value={con.operand}
                                // options={operand}
                                options={this.state.operands}
                                noMargin={true}
                                compact={true}
                                onChange={(e) => {
                                  this.handleValueUpdate('operand', e.target.value, index)
                                }}
                              />
                            </div>
                            <div className={classes.conFileds}>
                              {con?.additionalData?.type !== 'DATE' && con?.additionalData?.type !== 'DATETIME' && <Typography>Value</Typography>}
                              {(con.additionalData === '' || (con.additionalData && con.additionalData.type === 'TEXT')) &&
                                // <input
                                //   className={classes.input}
                                //   placeholder='Enter Value'
                                //   onFocus={() => {
                                //     this.setSelected({
                                //       index: index,
                                //       field: 'value',
                                //       type: 'CONDITION'
                                //     })
                                //   }}
                                //   onChange={(e) => {
                                //     this.handleValueUpdate('value', e.target.value, index)
                                //   }}
                                //   value={con.value}
                                // />
                                <TextInput
                                  mirrorFor={'CONDITION'}
                                  value={con.dataField}
                                  handleChange={(value) => {
                                    this.handleValueUpdate('value', value, index)
                                  }}
                                  handleFocus={() => {
                                    this.setSelected({
                                      index: index,
                                      field: 'value',
                                      type: 'CONDITION'
                                    })
                                  }}
                                  variableMap={variableDatas.map}
                                  availableVariables={variableDatas.variables}
                                />
                              }

                              {con.additionalData && con.additionalData.type === 'NUMBER' && <input
                                className={classes.input}
                                type={'number'}
                                placeholder='Enter Value'
                                onFocus={() => {
                                  this.setSelected({
                                    index: index,
                                    field: 'value',
                                    type: 'CONDITION'
                                  })
                                }}
                                onChange={(e) => {
                                  this.handleValueUpdate('value', e.target.value, index)
                                }}
                                value={con.value}
                              />}

                              {con.additionalData && con.additionalData.type === 'OBJECT' && con.additionalData.name !== "Currency" && <input
                                className={classes.input}
                                placeholder='Enter Value'
                                onFocus={() => {
                                  this.setSelected({
                                    index: index,
                                    field: 'value',
                                    type: 'CONDITION'
                                  })
                                }}
                                onChange={(e) => {
                                  this.handleValueUpdate('value', e.target.value, index)
                                }}
                                value={con.value}
                              />}

                              {con.additionalData && con.additionalData.type === 'OBJECT' && con.additionalData.name === "Currency" && <div className={classes.currencyTypeFields} style={{ display: 'flex' }}>
                                <div style={{ width: 150, marginRight: 5, zIndex: 6565 }}>
                                  <ReactSelect
                                    isMulti={false}
                                    labelId="singleSelect-select-label"
                                    id="singleSelect-select"
                                    value={con.value && con.value.type ? con.value.type : 'USD'}
                                    disabled={false}
                                    onChange={(e) => {
                                      this.handleChangeCurrencyValue('type', e, index)
                                    }}
                                    options={currencyOptions}
                                    placeholder={"Select"}
                                    styles={currencyStyles}
                                  >
                                  </ReactSelect>
                                </div>

                                <CurrencyTextField
                                  label={''}
                                  variant="outlined"
                                  value={con.value && con.value.value ? con.value.value : '0'}
                                  disabled={false}
                                  currencySymbol={currencyCodeMap[con?.value?.type?.value]}
                                  //minimumValue="0"
                                  style={{ textAlign: 'left', width: '100%', background: '#fff' }}
                                  outputFormat="string"
                                  decimalCharacter="."
                                  digitGroupSeparator=","
                                  onChange={(e, v) => {
                                    this.handleChangeCurrencyValue('value', v, index)
                                  }}
                                />
                              </div>}

                              {con.additionalData && con.additionalData.type === 'SINGLE_SELECT' &&
                                <div className={classes.inputBoxes}>
                                  <Typography>{item.label}</Typography>
                                  <ReactSelect
                                    isClearable
                                    disabled={false}
                                    isSearchable={true}
                                    isMulti={con.operand === 3 || con.operand === 4}
                                    menuPosition='fixed'
                                    options={con.additionalData.options}
                                    classNamePrefix="select"
                                    placeholder={con.hint}
                                    onChange={(e) => {
                                      this.handleValueUpdate('value', e ? JSON.stringify(e) : e, index)
                                    }}
                                    value={con.value ? JSON.parse(con.value) : con.value}
                                  />
                                </div>
                              }
                              {con.additionalData && con.additionalData?.type === 'MULTI_SELECT' &&
                                <div className={classes.inputBoxes}>
                                  <Typography>{item.label}</Typography>
                                  <ReactSelect
                                    isClearable
                                    isMulti
                                    disabled={false}
                                    isSearchable={true}
                                    menuPosition='fixed'
                                    options={con.additionalData.options}
                                    classNamePrefix="select"
                                    placeholder={con.hint}
                                    onChange={(e) => {
                                      this.handleValueUpdate('value', e ? JSON.stringify(e) : e, index)
                                    }}
                                    value={con.value ? JSON.parse(con.value) : con.value}
                                  />
                                </div>
                              }

                              {con.additionalData && (con.additionalData?.type === 'DATE' || con.additionalData?.type === 'DATETIME') &&
                                <div className={classes.inputBoxes}>
                                  <Typography>Value Type</Typography>
                                  <RadioGroup aria-label="gender" name="gender1" style={{ flexDirection: 'row' }} value={con.valueType} onChange={(e) => {
                                    console.log(e.target.value)
                                    this.handleValueTypeUpdate('valueType', e.target.value, index)

                                  }}>
                                    <FormControlLabel value="NEXT" control={<Radio />} label="Next" />
                                    <FormControlLabel value="ABSOLUTE" control={<Radio />} label="Absolute" />
                                    {/* <FormControlLabel value="RELATIVE" control={<Radio />} label="Relative" /> */}
                                  </RadioGroup>
                                  <Typography>Value</Typography>

                                  {con.valueType === 'ABSOLUTE' && <DatePicker
                                    className={classes.datePicker}
                                    selected={con.value && con.value !== '' ? new Date(con.value) : ''}
                                    popperClassName={classes.calendarPopout}
                                    disabled={false}
                                    onChange={date => {
                                      this.handleValueUpdate('value', date, index)

                                    }}
                                    dateFormat="MMM do yyyy"
                                    portalId="evMetaDialog"
                                    minDate={null}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    customInput={
                                      <OutlinedInput
                                        readOnlyInput={true}
                                        startAdornment={
                                          <InputAdornment>
                                            <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                          </InputAdornment>
                                        }
                                      />
                                    }
                                  />}

                                  {con.valueType === 'NEXT' && <input
                                    className={classes.input}
                                    type={'number'}
                                    placeholder='Enter Value'
                                    onChange={(e) => {
                                      this.handleValueUpdate('value', e.target.value, index)
                                    }}
                                    value={con.value}
                                  />}

                                  {con.valueType === 'RELATIVE' && <input
                                    className={classes.input}
                                    placeholder='Enter Value'
                                    onFocus={() => {
                                      this.setSelected({
                                        index: index,
                                        field: 'value',
                                        type: 'CONDITION'
                                      })
                                    }}
                                    onChange={(e) => {
                                      this.handleValueUpdate('value', e.target.value, index)
                                    }}
                                    value={con.value}
                                  />}

                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                  <div class="control-conditions__add-section ">
                    <button class="control-conditions__action" onClick={() => {
                      this.addNewCondition()
                    }}>
                      <AddIcon />
                    </button>
                  </div>
                </div>
              }
              {item.type === 'ARRAY' &&
                <div className={classes.inputBoxes}>
                  <Typography>{item.label}</Typography>
                  <input
                    className={classes.input}
                    disabled={false}
                    placeholder={item.hint}
                    onFocus={() => {
                      this.setSelected({
                        index: index,
                        field: 'dataField',
                        type: 'INPUT_FIELD'
                      })
                    }}
                    onChange={(e) => {
                      this.handleChange(e.target?.value, index)
                    }}
                    value={item.value}
                  />
                </div>
              }
            </div>
          })}

        <BottomActions
          submit={() => {
            this.handleFormSubmit()
          }}
        />

        {isShowVariables && <Variables
          componentVariables={componentVariables}
          handleSelectVariable={(component, variable) => {
            console.log(component, '222Componentttttttttt')
            console.log(variable, '111Variableeeeeeeeeeee')
            this.handleAddVariable(component, variable)
          }}
        />}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ControlConfig));
