import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import CalendarImage from "assets/images/calendar.svg"
import { Avatar } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import addUsers from "assets/images/add_profile.svg";
import classnames from "classnames";
import Tooltip from '@material-ui/core/Tooltip';

import BottomActions from "./BottomActions";
import { addWorkflowComponentUserResponse } from "redux/workflow/action";
import EvaluationBar from "../../../Common/EvaluationBar";
import CommonCss from 'commonCss';
import DoneIcon from "@material-ui/icons/Done";
import { CommonFn } from "services/commonFn";

import {
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, IconButton, Paper,
  Button, InputAdornment
} from '@material-ui/core';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";

import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";

import { fetchProjectTimelineSteps, addProjectTimelineStep, updateProjectTimelineStep, deleteProjectTimelineStep, addProjectTimelineStepColloborators } from 'redux/evaluation/projectTimeline/action'
import { showSnackBar } from "redux/snackbar/action";
import { showEvaluationCollaboratorDialog, hideEvaluationCollaboratorDialog } from "redux/evaluate/action";
import SearchUserAndTeamPoper from "Components/Common/TeamsAndUser/SearchUserAndTeamPoper";


const connectedProps = (state) => ({
  OrgID: state.authUser.user.OrgID,
  user: state.authUser.user,
  evaluation: state.evaluationMetaData.evaluation,

  fetchStepsProgress: state.projectTimeline.fetchStepsProgress,
  fetchStepsError: state.projectTimeline.fetchStepsError,
  steps: state.projectTimeline.steps,

  addStepProgress: state.projectTimeline.addStepProgress,
  addStepError: state.projectTimeline.addStepError,
  addStepSuccess: state.projectTimeline.addStepSuccess,

  updateStepProgress: state.projectTimeline.updateStepProgress,
  updateStepError: state.projectTimeline.updateStepError,
  updateStepSuccess: state.projectTimeline.updateStepSuccess,

  deleteStepProgress: state.projectTimeline.deleteStepProgress,
  deleteStepError: state.projectTimeline.deleteStepError,
  deleteStepSuccess: state.projectTimeline.deleteStepSuccess,

  addStepColloboratorsProgress: state.projectTimeline.addStepColloboratorsProgress,
  addStepColloboratorsError: state.projectTimeline.addStepColloboratorsError,
  addStepColloboratorsSuccess: state.projectTimeline.addStepColloboratorsSuccess,

  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,

});

const connectionActions = {
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  fetchProjectTimelineSteps: fetchProjectTimelineSteps,
  addProjectTimelineStep: addProjectTimelineStep,
  updateProjectTimelineStep: updateProjectTimelineStep,
  deleteProjectTimelineStep: deleteProjectTimelineStep,
  showSnackBar: showSnackBar,
  showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
  addProjectTimelineStepColloborators: addProjectTimelineStepColloborators
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  root: {
    "& .react-datepicker-popper": {
      zIndex: 2222,
    },
    "& .react-datepicker__input-container > div": {
      display: "flex",
      minWidth: 300,
      marginBottom: 0,
      alignItems: "baseline",
      "& [class*=MuiInputBase-root]": {
        maxHeight: 33,
        minHeight: 33,
        width: 166,
        "& input": {
          fontSize: theme.spacing(1.8),
          // width: 107,
          textAlign: "center",
        },
      },
      "& [class*=MuiFormLabel-root]": {
        marginBottom: 0,
        marginRight: 29,
      },
    },
    "[class*=MuiMenuItem-root]": {
      color: "#707070",
      fontSize: theme.spacing(1.8),
    },
  },
  approversFlex: {
    display: 'flex',
    justifyContent: 'end',
    "& [class*=MuiAvatar-root]": {
      width: 25,
      height: 25,
      fontSize: 14
    },
    "& [class*=MuiAvatarGroup-avatar]": {
      margin: 0
    }
  },
  topActions: {
    textAlign: 'right',
    marginBottom: 20
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  nameCol: {
    width: '30%'
  },
  completedCol: {
    width: '20%'
  },
  collabCol: {
    width: '10%',
    textAlign:'right'
  },
  commentCol: {
    width: '30%'
  },
  actionCol: {
    textAlign: 'center',
    width: '10%'
  },
  stepDetails: {
    minHeight: "25px !important",
    padding: 6,
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    color: "#5F5F5F",
    fontSize: "1.1em",
    width: '95%',
  },
  date_popper: {
    zIndex: 2222
  },
});

class ProjectTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextClickLoaderSave: false,
      nextClickLoader: false,
      isDataLoading: true,
      alerts: [],
      page: 0,
      pageSize: 10,
      isLoading: true,
      totalAlerts: 0,
      steps: [],
      deleteItemIndex: null,
      selectedUsers: [],
      selectedIndex: null,
      selectedTask: '',
    }

    this.submitResponse = this.submitResponse.bind(this);
    this.addStepUsers = this.addStepUsers.bind(this);
    this.avatarName = this.avatarName.bind(this);


  }

  componentDidMount() {
    this.props.fetchProjectTimelineSteps(this.props.evaluation?.ID)
  }

  componentDidUpdate(prevProps) {
    const { fetchStepsProgress, fetchStepsError,
      addStepProgress, addStepError,
      updateStepProgress, updateStepError,
      deleteStepProgress, deleteStepError, addStepColloboratorsProgress,
      addStepColloboratorsError
    } = this.props;

    if (!fetchStepsProgress && prevProps.fetchStepsProgress) {
      if (fetchStepsError === null) {
        this.initializeSteps()
      } else {
        this.setState({
          isDataLoading: false
        })
      }
    }

    if (!addStepProgress && prevProps.addStepProgress) {
      if (addStepError === null) {
        this.props.showSnackBar("Action added successfully.", "success", 3000)
        this.removeFromEdit('ADD')
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!updateStepProgress && prevProps.updateStepProgress) {
      if (updateStepError === null) {
        this.props.showSnackBar("Action updated successfully.", "success", 3000)
        this.removeFromEdit('UPDATE')
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!deleteStepProgress && prevProps.deleteStepProgress) {
      if (deleteStepError === null) {
        console.log(this.props.deleteStepSuccess)
        this.props.showSnackBar("Action deleted successfully.", "success", 3000)
        if (this.state.deleteItemIndex !== null) {
          this.deleteLocally()
        }
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!addStepColloboratorsProgress && prevProps.addStepColloboratorsProgress) {
      if (addStepColloboratorsError === null) {
        this.props.showSnackBar("Action updated successfully.", "success", 3000)
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  initializeSteps() {
    const { steps } = this.props;
    if (steps) {
      let newData = steps.map((o) => {
        return {
          step: o?.ProjectStep,
          step_description: o?.StepDescription,
          status: o?.Status,
          startDate: new Date(o?.StartDate),
          endDate: new Date(o?.EndDate),
          isEdit: false,
          users: o.Users.length > 0 && o.Groups.length > 0 ? [...o.Users, ...o.Groups] : o.Users.length > 0 ? o.Users : o.Groups,
          ID: o?.ID,
          userDatas: o.Users,
          groupDatas: o.Groups
        }
      })
      this.setState({
        steps: newData,
        isDataLoading: false
      })
    }
  }

  removeFromEdit(type) {
    let { steps } = this.state;
    let successData = '';
    let index = null;
    if (type === 'ADD') {
      successData = this.props.addStepSuccess;
      index = steps.findIndex(o => o.step === successData.ProjectStep);
    } else {
      successData = this.props.updateStepSuccess;
      index = steps.findIndex(o => o.ID === successData.ID);
    }
    if (index !== -1 && index !== null) {
      console.log(index, 'index')
      let step = steps[index];
      step.isEdit = false;
      step.ID = successData.ID;
      this.setState({
        steps: steps
      })
    }
  }

  deleteLocally() {
    const { deleteItemIndex, steps } = this.state;
    steps.splice(deleteItemIndex, 1);
    this.setState({
      steps: steps,
      deleteItemIndex: null
    })
  }

  submitResponse() {
    this.setState({
      nextClickLoader:true
    },()=>{
      this.props.addWorkflowComponentUserResponse(this.props.evaluation_id, this.props.workflowComponentId, {
        "data": [],
        "forward": this.state.saveType === 'NEXT' ? true : false
      });
    })
  }

  addNewStep() {
    let { steps } = this.state;
    var obj = {
      step: '',
      step_description: '',
      status: '',
      startDate: '',
      endDate: '',
      isEdit: true,
      ID: null,
      users: [],
      userDatas: [],
      groupDatas: []
    }
    steps.push(obj)
    this.setState({
      steps: steps
    })
  }

  updateFieldValue(field, newValue, index) {
    let { steps } = this.state;
    let step = steps[index];
    step[field] = newValue;
    this.setState({
      steps: steps
    })
  }

  handleAddAndUpdate(index) {
    let { steps } = this.state;
    let stepData = steps[index];

    if (stepData.step === '') {
      this.props.showSnackBar("Please enter step details.", "error", 3000)
      return;
    }

    if (stepData.endDate === undefined || stepData.endDate === null || stepData.endDate === '') {
      this.props.showSnackBar("Please select completed by.", "error", 3000)
      return;
    }

    let params = {
      step: stepData.step,
      step_description: stepData.step_description,
      endDate: stepData.endDate.toISOString()
    }

    if (stepData.userDatas && stepData.userDatas.length > 0) {
      params['userIDs'] = stepData.userDatas.map(o => o.ID)
    }

    if (stepData.groupDatas && stepData.groupDatas.length > 0) {
      params['teamIDs'] = stepData.groupDatas.map(o => o.ID)
    }

    if (stepData.ID === null) {
      this.props.addProjectTimelineStep(this.props.evaluation?.ID, params)
    } else {
      this.props.updateProjectTimelineStep(this.props.evaluation?.ID, stepData.ID, params)
    }
  }

  handleEdit(index) {
    let { steps } = this.state;
    let step = steps[index];
    step.isEdit = true;
    this.setState({
      steps: steps
    })
  }

  handleDelete(index) {
    let { steps } = this.state;
    let step = steps[index];
    if (step.ID === null) {
      steps.splice(index, 1);
      this.setState({
        steps: steps
      })
    } else {
      this.setState({
        deleteItemIndex: index
      }, () => {
        this.props.deleteProjectTimelineStep(this.props.evaluation?.ID, step.ID)
      })
    }
  }

  addStepUsers(data) {
    let index = data.index;
    let userData = data.users;
    let { steps } = this.state;
    let step = steps[index];
    step.users = [...userData?.users, ...userData?.teams];
    step.userDatas = userData?.users;
    step.groupDatas = userData?.teams;
    this.setState({
      steps: steps
    }, () => {
      if (step.ID !== null) {
        this.props.addProjectTimelineStepColloborators(this.props.evaluation?.ID, step.ID, {
          userIDs: step.userDatas.length > 0 ? step.userDatas.map(o => o.ID) : [],
          teamIDs: step.groupDatas.length > 0 ? step.groupDatas.map(o => o.ID) : []
        })
      }
    })
  }

  render() {
    const { classes } = this.props;
    const { isDataLoading, steps } = this.state;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <div className={classes.root}>
        {this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation.Type !== 3 && <EvaluationBar
          isShowCollabrations={false}
          isShowEvalDetails={false}
          isWorkFlow={this.props.isWorkFlow}
          isShowViewBtns={false}
        />}
        <div className={classes.rootContainer}>
          <div className={classes.topActions}>
            <Button className={classes.newUserBtn} onClick={() => {
              this.addNewStep()
            }}>
              + Action
            </Button>
          </div>
          <div className={classes.timelineDatas}>
            <Paper style={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer style={{ maxHeight: '78vh' }}>
                <Table className={classes.mandatoryTable} size="small" stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.nameCol}>Action Required</TableCell>
                      <TableCell className={classes.completedCol}>Target Completion Date</TableCell>
                      <TableCell className={classes.collabCol}>Assigned to</TableCell>
                      <TableCell className={classes.commentCol}>Comments</TableCell>
                      <TableCell className={classes.actionCol}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isDataLoading && steps.map((task, index) => {
                      console.log(task.users, 'Usersssss')
                      if (task.isEdit) {
                        return <TableRow key={'task_' + task.ID}>
                          <TableCell className={classes.nameCol}>
                            <input
                              placeholder={'Enter Step Details'}
                              value={task.step} className={classes.stepDetails}
                              onChange={(event) => {
                                this.updateFieldValue('step', event.target.value, index)
                              }} />
                          </TableCell>
                          <TableCell className={classes.completedCol}>
                            <DatePicker
                              className={classes.datePicker}
                              selected={task?.endDate}
                              onChange={date => {
                                this.updateFieldValue('endDate', date, index)
                              }}
                              portalId="evMetaDialog"
                              dateFormat="MMM do yyyy"
                              minDate={new Date()}
                              popperClassName={classes.date_popper}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              customInput={
                                <OutlinedInput
                                  readOnlyInput={true}
                                  placeholder="Completed by"
                                  startAdornment={
                                    <InputAdornment>
                                      <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                    </InputAdornment>
                                  }
                                />
                              }
                            />
                          </TableCell>
                          <TableCell className={classes.collabCol}>
                            <div className={classes.approversFlex}>
                              {task.users !== undefined && task.users !== null && <AvatarGroup max={3}>
                                {
                                  task.users.map((user, k) => {
                                    return <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{user.Name + "\n" + user.Email}</span>} arrow>
                                      <Avatar style={{ zIndex: 0 }} key={k}> {this.avatarName(user.Name)}</Avatar></Tooltip>
                                  })
                                }
                              </AvatarGroup>}
                              <Image className={classnames(classes.addUser, 'evaluate-add-user')} alt="add user" src={addUsers} onClick={(event) => {
                                this.setState({
                                  selectedUsers: task.users,
                                  selectedIndex: index,
                                  selectedTask: task
                                }, () => {
                                  this.props.showEvaluationCollaboratorDialog();
                                })
                              }} />
                            </div>
                          </TableCell>
                          <TableCell className={classes.commentCol}>
                            <input
                              placeholder={'Enter Comments Here'}
                              value={task.step_description} className={classes.stepDetails}
                              onChange={(event) => {
                                this.updateFieldValue('step_description', event.target.value, index)
                              }} />
                          </TableCell>
                          <TableCell className={classes.actionCol}>
                            <IconButton onClick={() => this.handleAddAndUpdate(index)}>
                              <DoneIcon />
                            </IconButton>
                            <IconButton onClick={() => this.handleDelete(index)}>
                              <Image src={deleteIcon} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      } else {
                        return <TableRow key={'task_' + task.ID}>
                          <TableCell className={classes.nameCol}>{task?.step}</TableCell>
                          <TableCell className={classes.completedCol}>{CommonFn.formateDate(task?.endDate, true)}</TableCell>
                          <TableCell className={classes.collabCol}>
                            <div className={classes.approversFlex}>
                              {task.users !== undefined && task.users !== null && <><AvatarGroup max={3}>
                                {
                                  task.users.map((user, k) => {
                                    return <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{user.Name + "\n" + user.Email}</span>} arrow>
                                      <Avatar style={{ zIndex: 0 }} key={k}> {this.avatarName(user.Name)}</Avatar></Tooltip>
                                  })
                                }
                              </AvatarGroup>
                              </>}
                              <Image className={classnames(classes.addUser, 'evaluate-add-user')} alt="add user" src={addUsers} onClick={(event) => {
                                this.setState({
                                  selectedUsers: task.users,
                                  selectedIndex: index,
                                  selectedTask: task
                                }, () => {
                                  this.props.showEvaluationCollaboratorDialog();
                                })
                              }} />
                            </div>
                          </TableCell>
                          <TableCell className={classes.commentCol}>{task?.step_description}</TableCell>
                          <TableCell className={classes.actionCol}>
                            <IconButton onClick={() => this.handleEdit(index)}>
                              <Image src={editIcon} />
                            </IconButton>
                            <IconButton onClick={() => this.handleDelete(index)}>
                              <Image src={deleteIcon} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      }
                    })}
                    {(!isDataLoading && (steps === undefined || steps === null || steps.length <= 0)) && <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: 'center' }} className={classes.nameCol}>No Action Items</TableCell>
                    </TableRow>}

                    {isDataLoading && <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: 'center' }} className={classes.nameCol}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>}

                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
        <MessageDialog messageData={messageData} callBack={this.callBack} />
        <BottomActions
          user={this.props.user}
          isCreatorOrAdmin={this.props.isCreatorOrAdmin}
          adminForward={this.props.adminForward}
          submitResponse={this.submitResponse}
          nextClickLoaderSave={this.state.nextClickLoaderSave}
          nextClickLoader={this.state.nextClickLoader}
        />
        {this.props.collaboratorDialogOpen &&
          <SearchUserAndTeamPoper
            key={this.state.selectedIndex}
            onSubmit={(users) => {
              let Users = [];
              let Teams = [];
              users.forEach(data => {
                if (data.type === 'GROUP') {
                  Teams.push({ ID: data.ID, Name: data.Name, Email: data.Email, type: 'GROUP' });
                } else {
                  Users.push({ ID: data.ID, Name: data.Name, Email: data.Email, type: 'USER' });
                }
              });
              this.addStepUsers({
                users: { users: Users, teams: Teams },
                index: this.state.selectedIndex,
                task: this.state.selectedTask
              });
              this.props.hideEvaluationCollaboratorDialog();
            }}
            selectedUser={this.state.selectedUsers}
            selectText={'Assign'}
          />
        }
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ProjectTimeline));