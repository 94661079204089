import {
  START_EXPENT_DIRECTORY_PRODUCT_SEARCH, END_EXPENT_DIRECTORY_PRODUCT_SEARCH
} from "./action";

const initState = {
  fetchExpentDirectoryProductsProgress: false,
  fetchExpentDirectoryProductsError: null,
  products: {},
}

export function expentDirectoryReducer(state = initState, action) {
  switch (action.type) {

    case START_EXPENT_DIRECTORY_PRODUCT_SEARCH:
      var products = state.products;
      // if (action.payload?.searchQuery?.page === 0) {
      //   products = {}
      // }
      return {
        ...state,
        fetchExpentDirectoryProductsProgress: true,
        fetchExpentDirectoryProductsError:null,
        products: products
      };

    case END_EXPENT_DIRECTORY_PRODUCT_SEARCH: {
      let success = action.payload.success;
      let error = action.payload.error;
      let isTabular = action.payload.isTabular;
      let currentProducts = {...state.products};
      let newProducts = success?.body;
      if(!error){
        //append data if scrolling feature is available
        if(!isTabular && action.payload.searchQuery?.page>0){

          // if(newProducts?.existing?.items){
          //   currentProducts?.existing?.items.push(...newProducts?.existing?.items);
          // }
          if(newProducts?.others?.items){
            currentProducts?.others?.items.push(...newProducts?.others?.items);
          }
        }else{ 
          currentProducts = newProducts;
        }
      }
      return {
        ...state,
        fetchExpentDirectoryProductsProgress: false,
        fetchExpentDirectoryProductsError:action.payload.error,
        products: currentProducts,
      }
    }

    default:
      return state;
  }
}