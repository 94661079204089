import { END_PLANS_FETCH, START_PLANS_FETCH ,START_PLAN_FETCH,END_PLAN_FETCH} from "./action";

const initState = {
    fetchPlanProgress: false,
    plans: [],
    currrentPlanProgress: false,
    plan: [],
}

export  function paymentReducer(state=initState,action){
    switch(action.type){
        case START_PLANS_FETCH:
            return {
                ...state,
                fetchPlanProgress: true,
                plans: [],
            };
        case END_PLANS_FETCH:
            return {
                ...state,
                fetchPlanProgress: false,
                plans: action.payload.success || [],
            }
        case START_PLAN_FETCH:
            return {
                ...state,
                currrentPlanProgress: true,
                plan: [],
            };
        case END_PLAN_FETCH:
            return {
                ...state,
                currrentPlanProgress: false,
                plan: action.payload.success || [],
            }
        default:
            return state;
    }
}