import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { DialogContent, Typography, Grid, CircularProgress, Dialog, Slide } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { withRouter } from "react-router-dom";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { showSendNotificationDialog, hideSendNotificationDialog, sendEvaluationEmail } from "redux/evaluation/metadata/action";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import * as Validator from "util/Validation";
import { showSnackBar } from "redux/snackbar/action";
import { CommonFn } from "services/commonFn";

import { addWorkflowComponentUserResponse, populateEvaluationTemplateData, addEmailComponentActivity, fetchEmailComponentActivity } from "redux/workflow/action";

import BottomActions from "./BottomActions";
import EvaluationBar from "../../../Common/EvaluationBar";
import PopulateErrors from "./PopulateErrors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  notificationEmail: state.evaluationMetaData.sendNotificationEmail,
  sendEmailProgress: state.evaluationMetaData.sendEmailProgress,
  errorSendEmail: state.evaluationMetaData.errorSendEmail,
  evaluation: state.evaluationMetaData.evaluation,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,

  fetchPopulateDataProgress: state.workflow.fetchPopulateDataProgress,
  fetchPopulateDataError: state.workflow.fetchPopulateDataError,
  populatedDatas: state.workflow.populatedDatas,

  fetchEmailComponentActivityProgress: state.workflow.fetchEmailComponentActivityProgress,
  fetchEmailComponentActivityError: state.workflow.fetchEmailComponentActivityError,
  emailComponentActivity: state.workflow.emailComponentActivity,

  addEmailComponentActivityProgress: state.workflow.addEmailComponentActivityProgress,
  addEmailComponentActivityError: state.workflow.addEmailComponentActivityError,
  addEmailComponentActivitySuccess: state.workflow.addEmailComponentActivitySuccess,
});

const connectionActions = {
  showSendNotificationDialog: showSendNotificationDialog,
  hideDialog: hideSendNotificationDialog,
  sendEvaluationEmail: sendEvaluationEmail,
  showSnackBar: showSnackBar,
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  populateEvaluationTemplateData: populateEvaluationTemplateData,
  addEmailComponentActivity: addEmailComponentActivity,
  fetchEmailComponentActivity: fetchEmailComponentActivity,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    modalTitle: {
      fontSize: theme.spacing(2.5),
      marginBottom: theme.spacing(4),
    },
    lastDate: {
      fontSize: 12,
      marginBottom: theme.spacing(4),
    },
    actions: {
      float: "right",
      marginBottom: 20,
    },
    alert: {
      marginBottom: theme.spacing(1.6),
    },
    root: {
      background: "#fff",
      padding: 20,
      height: "89.5vh",
      overflow: "scroll",
      width: "80%",
      margin: "0px auto",
    },
    nameYour: {
      '& [class*="Outlined-input"]': {
        marginBottom: "10px !important",
      },
    },
    topHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    loader: {
      textAlign: "center",
      marginTop: "30vh",
    },
    bodyLoader: {
      position: "fixed",
      paddingTop: "100px",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      overflow: "auto",
      backgroundColor: "#00000033",
      zIndex: 11111,
    },
  });

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      to: "",
      to_error: "",
      cc: "",
      cc_error: "",
      bcc: "",
      bcc_error: "",
      subject: "",
      subject_error: "",
      body: "",
      body_error: "",
      populateErrors: [],
      isReviewRequired: true,
      isLoading: true,
      activityCheckDone: false,
      populateDataDone: false,
      skipProgress: false,
    };

    this.submitResponse = this.submitResponse.bind(this);
    this.skipEmail = this.skipEmail.bind(this);
  }

  componentDidMount() {
    this.props.fetchEmailComponentActivity(this.props.evaluation_id, this.props.workflowComponentId);
    // console.log(this.props.workflowComponentId,'this.props.workflowComponentId')

    if (this.props.notificationEmail) {
      this.setState({ to: this.props.notificationEmail });
    }

    if (this.props.isWorkFlow) {
      this.checkWorkflowConfiguration();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.fetchEmailComponentActivityProgress && prevProps.fetchEmailComponentActivityProgress) {
      if (this.props.fetchEmailComponentActivityError === null) {
        console.log(this.props.emailComponentActivity, "emailComponentActivity-success111");
        this.setState(
          {
            activityCheckDone: true,
          },
          () => {
            this.checkActivity();
          }
        );
      } else {
        console.log(this.props.fetchEmailComponentActivityError, "error");
      }
    }

    if (!this.props.addEmailComponentActivityProgress && prevProps.addEmailComponentActivityProgress) {
      if (this.props.addEmailComponentActivityError === null) {
        console.log(this.props.addEmailComponentActivitySuccess, "addEmailComponentActivitySuccess");
      } else {
        console.log(this.props.addEmailComponentActivityError, "error");
      }
    }

    if (this.props.sendEmailProgress === false && prevProps.sendEmailProgress === true) {
      if (this.props.errorSendEmail !== null) {
        var error = this.props.error;
        if (error.status === 400) {
          var errors = error.errors;
          var stateError = {
            to_error: "",
            cc_error: "",
            subject_error: "",
            body_error: "",
          };
          for (var field in errors) {
            switch (field) {
              case "to":
                stateError.to_error = errors[field][0];
                break;
              case "cc":
                stateError.cc_error = errors[field][0];
                break;
              case "subject":
                stateError.subject_error = errors[field][0];
                break;
              case "body":
                stateError.body_error = errors[field][0];
                break;
              case "bcc":
                stateError.bcc_error = errors[field][0];
                break;
              default:
            }
          }
          this.setState(stateError);
        } else {
          this.setState({ error: error.message });
        }
      } else {
        this.props.showSnackBar("Notification successfully sent.", "success", 3000);
        this.props.addEmailComponentActivity(this.props.evaluation_id, this.props.workflowComponentId);
        this.addUserResponse();
        // this.props.hideDialog()
      }
    }

    if (this.props.workflowComponentsFormsInProg === false && prevProps.workflowComponentsFormsInProg === true) {
      if (this.props.isWorkFlow) {
        this.checkWorkflowConfiguration();
      }
    }

    if (this.props.fetchPopulateDataProgress === false && prevProps.fetchPopulateDataProgress) {
      if (this.props.fetchPopulateDataError === null) {
        this.initializeValues();
      } else {
        this.props.showSnackBar("Something went wrong.", "success", 3000);
      }
    }
  }

  addUserResponse() {
    var responseDatas = [];
    this.props.addWorkflowComponentUserResponse(this.props.evaluation_id, this.props.workflowComponentId, {
      data: responseDatas,
      forward: true,
    });
  }

  initializeValues() {
    const { populatedDatas } = this.props;
    if (populatedDatas) {
      console.log(populatedDatas, "populatedDatas");
      let to,
        cc,
        subject,
        body,
        bcc = "";
      let isFoundError = false;
      let errors = [];
      populatedDatas.forEach(function (data, k) {
        if (data.is_error) {
          isFoundError = true;
          errors.push(data.error);
        } else {
          if (k === 0) {
            to = data.interpollated_value;
          } else if (k === 1) {
            cc = data.interpollated_value;
          } else if (k === 2) {
            subject = data.interpollated_value;
          } else if (k === 3) {
            body = data.interpollated_value;
          } else if (k === 4) {
            bcc = data.interpollated_value;
          }
        }
      });
      this.setState(
        {
          to: to,
          cc: cc,
          bcc: bcc,
          subject: subject,
          body: body,
          populateErrors: errors,
          isPopulateErrors: isFoundError,
        },
        () => {
          console.log(this.state.isReviewRequired, "isReviewRequired");
          if (this.state.isReviewRequired === false) {
            this.setState(
              {
                populateDataDone: true,
              },
              () => {
                this.checkActivity();
              }
            );
          } else {
            this.setState({
              isLoading: false,
            });
          }
        }
      );
    }
  }

  checkActivity() {
    if (this.state.activityCheckDone && this.state.populateDataDone) {
      if (this.props.emailComponentActivity && this.props.emailComponentActivity.length > 0) {
        this.addUserResponse();
      } else {
        setTimeout(() => {
          this.submitResponse(true);
        }, 100);
      }
    }
  }

  checkWorkflowConfiguration() {
    const { workflowComponentsForms, workflowComponentDetail } = this.props;
    if (workflowComponentsForms === null || workflowComponentDetail === null) {
      return;
    }

    var configurations = workflowComponentDetail.OrgWorkflowComponentConfigurations;
    if (configurations === undefined || configurations === null || configurations.length <= 0) {
      this.setState({
        isReviewRequired: true,
        isLoading: false,
      });
    } else {
      var emailToFormId = workflowComponentsForms.filter((o) => o.Name === "email_to").map((o) => o.ID)[0];
      var emailData = configurations.filter((o) => o.FormID === emailToFormId).map((o) => o.Value)[0];
      var emailToValue = "";
      if (emailData) {
        if (Validator.isJson(emailData)) {
          let newData = JSON.parse(emailData);
          emailToValue = newData.value;
        } else {
          emailToValue = emailData;
        }
      }

      var emailCCFormId = workflowComponentsForms.filter((o) => o.Name === "email_cc").map((o) => o.ID)[0];
      var ccData = configurations.filter((o) => o.FormID === emailCCFormId).map((o) => o.Value)[0];
      var emailCCValue = "";
      if (ccData) {
        if (Validator.isJson(ccData)) {
          let newData = JSON.parse(ccData);
          emailCCValue = newData.value;
        } else {
          emailCCValue = ccData;
        }
      }

      var emailBCCFormId = workflowComponentsForms.filter((o) => o.Name === "email_bcc").map((o) => o.ID)[0];
      var bccData = configurations.filter((o) => o.FormID === emailBCCFormId).map((o) => o.Value)[0];
      var emailBCCValue = "";
      if (bccData) {
        if (Validator.isJson(bccData)) {
          let newData = JSON.parse(bccData);
          emailBCCValue = newData.value;
        } else {
          emailBCCValue = bccData;
        }
      }

      var emailSubjectFormId = workflowComponentsForms.filter((o) => o.Name === "email_subject").map((o) => o.ID)[0];
      var subjectData = configurations.filter((o) => o.FormID === emailSubjectFormId).map((o) => o.Value)[0];
      var emailSubjectValue = "";
      if (subjectData) {
        if (Validator.isJson(subjectData)) {
          let newData = JSON.parse(subjectData);
          emailSubjectValue = newData.value;
        } else {
          emailSubjectValue = subjectData;
        }
      }

      var emailBodyFormId = workflowComponentsForms.filter((o) => o.Name === "email_body").map((o) => o.ID)[0];
      var emailBodyValue = configurations.filter((o) => o.FormID === emailBodyFormId).map((o) => o.Value)[0];

      var emailReviewFormId = workflowComponentsForms.filter((o) => o.Name === "is_email_review_needed").map((o) => o.ID)[0];
      var emailReviewValue = configurations.filter((o) => o.FormID === emailReviewFormId).map((o) => o.Value)[0];
      if (emailReviewValue !== undefined && emailReviewValue !== null && emailReviewValue !== "") {
        let newValue = emailReviewValue === "true" ? true : false;
        emailReviewValue = newValue;
      } else {
        emailReviewValue = false;
      }
      const checkParams = {
        to: emailToValue,
        cc: emailCCValue,
        bcc: emailBCCValue,
        subject: emailSubjectValue,
        body: emailBodyValue,
      };
      this.setState(
        {
          isReviewRequired: emailReviewValue,
        },
        () => {
          this.populateEmailData(checkParams);
        }
      );
    }
  }

  populateEmailData(data) {
    let params = {
      templates: [
        {
          template: data.to,
          is_slate: false,
        },
        {
          template: data.cc,
          is_slate: false,
        },
        {
          template: data.subject,
          is_slate: false,
        },
        {
          template: data.body,
          is_slate: true,
        },
        {
          template: data.bcc,
          is_slate: false,
        },
      ],
    };
    this.props.populateEvaluationTemplateData(this.props.evaluation?.ID, params);
  }

  submitResponse(isDirect = false) {
    this.setState({
      error: "",
      to_error: "",
      cc_error: "",
      subject_error: "",
      body_error: "",
    });

    var to = this.state.to;
    if (String(to).trim().length === 0) {
      this.setState({
        to_error: "Please enter Recipient Address(es).",
        isLoading: false,
      });
      return;
    }

    var toArray = to.split(",");
    for (var i = 0; i < toArray.length; i++) {
      if (Validator.validateEmail(String(toArray[i]).trim()) === false) {
        this.setState({
          to_error: "Please provide valid email.",
          isLoading: false,
        });
        return;
      }
    }

    if (String(this.state.cc).trim().length > 0) {
      var ccArray = this.state.cc.split(",");
      for (var j = 0; j < ccArray.length; j++) {
        if (Validator.validateEmail(String(ccArray[j]).trim()) === false) {
          this.setState({
            cc_error: "Please provide valid email.",
            isLoading: false,
          });
          return;
        }
      }
    }

    if (String(this.state.bcc).trim().length > 0) {
      var bccArray = this.state.bcc.split(",");
      for (var h = 0; h < bccArray.length; h++) {
        if (Validator.validateEmail(String(bccArray[h]).trim()) === false) {
          this.setState({
            bcc_error: "Please provide valid email.",
            isLoading: false,
          });
          return;
        }
      }
    }

    var subject = this.state.subject;
    if (String(subject).trim().length === 0) {
      this.setState({
        subject_error: "Please enter Subject.",
        isLoading: false,
      });
      return;
    }

    var body = this.state.body;
    console.log(body, "Bodyyyyy");
    if (!isDirect) {
      var bodyText = document.querySelectorAll("#evSendNotification .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "");
      console.log(bodyText, "bodyText");

      if (String(body).trim().length === 0 || String(bodyText).trim().length === 0) {
        this.setState({
          body_error: "Message should not be empty.",
          isLoading: false,
        });
        return;
      }
    }

    let params = {
      to: to,
      cc: this.state.cc,
      bcc: this.state.bcc,
      subject: subject,
      body: body,
    };

    console.log(params, "Parameters");
    this.props.sendEvaluationEmail(this.props.evaluation.ID, params);
  }

  skipEmail() {
    this.setState(
      {
        skipProgress: true,
      },
      () => {
        this.addUserResponse();
      }
    );
  }

  render() {
    const classes = this.props.classes;
    const { emailComponentActivity } = this.props;
    if (this.state.isLoading) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <>
        <EvaluationBar isShowCollabrations={false} isShowEvalDetails={false} isWorkFlow={this.props.isWorkFlow} isShowViewBtns={false} />
        <div className={classes.root} id="evSendNotification">
          <DialogContent classes={{ root: classes.dialogAction }}>
            <div>
              <div className={classes.topHead}>
                <Typography variant={"h2"} className={classes.modalTitle}>
                  Send Notification
                </Typography>
                {emailComponentActivity && emailComponentActivity.length > 0 && (
                  <div>
                    <Typography variant={"h2"} className={classes.lastDate}>
                      Last Email Sent On: {CommonFn.formateDate(emailComponentActivity[emailComponentActivity.length - 1].CreatedAt, true)}
                    </Typography>
                  </div>
                )}
              </div>
              <div className={classes.alert}>
                {this.state.error.length > 0 && (
                  <Alert variant="filled" severity="error">
                    {this.state.error}
                  </Alert>
                )}
              </div>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.nameYour}>
                    <OutlinedInput
                      label="To"
                      error={this.state.to_error.length > 0}
                      helperText={this.state.to_error}
                      value={this.state.to}
                      required={true}
                      onChange={(event) => {
                        this.setState({
                          to: event.target.value,
                        });
                      }}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.nameYour}>
                    <OutlinedInput
                      label="CC"
                      error={this.state.cc_error.length > 0}
                      helperText={this.state.cc_error}
                      value={this.state.cc}
                      onChange={(event) => {
                        this.setState({
                          cc: event.target.value,
                        });
                      }}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.nameYour}>
                    <OutlinedInput
                      label="BCC"
                      error={this.state.bcc_error.length > 0}
                      helperText={this.state.bcc_error}
                      value={this.state.bcc}
                      onChange={(event) => {
                        this.setState({
                          bcc: event.target.value,
                        });
                      }}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.nameYour}>
                    <OutlinedInput
                      label="Subject"
                      error={this.state.subject_error.length > 0}
                      helperText={this.state.subject_error}
                      value={this.state.subject}
                      required={true}
                      onChange={(event) => {
                        this.setState({
                          subject: event.target.value,
                        });
                      }}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <SlateInputField
                    label="Message"
                    required={true}
                    error={this.state.body_error.length > 0}
                    helperText={this.state.body_error}
                    folderPath={`/evaluation/${this.props?.evaluationId}/email`}
                    as={SlateInputField}
                    onChangeEvent={(value) => {
                      this.setState({
                        body: value,
                      });
                    }}
                    initValue={this.state.body}
                    textContainerStyle={{ color: "#707070" }}
                    isShowAddVariableOption={false}
                    isShowTableOption={true}
                    isShowImageOption={true}
                    isShowAlignmentOption={true}
                    isShowHeadingOption={true}
                  />
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </div>
        <BottomActions submitResponse={this.submitResponse} skipEmail={this.skipEmail} sendEmailProgress={this.props.sendEmailProgress} skipProgress={this.state.skipProgress} />
        {this.state.isPopulateErrors && (
          <PopulateErrors
            isOpen={this.state.isPopulateErrors}
            populateErrors={this.state.populateErrors}
            hideDialog={() => {
              this.setState({
                isPopulateErrors: false,
              });
            }}
          />
        )}

        {false && this.state.isLoading && (
          <div className={classes.bodyLoader}>
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(EmailForm));
