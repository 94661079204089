
import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const START_APP_PRODUCT_CATEGORY_FETCH     =   "START_APP_PRODUCT_CATEGORY_FETCH";
export const END_APP_PRODUCT_CATEGORY_FETCH       =   "END_APP_PRODUCT_CATEGORY_FETCH";


function startProductCategorySearch(){
    return {
        type:START_APP_PRODUCT_CATEGORY_FETCH,
        payload: {}
    };
}

function endProductCategorySearch(success,error){
    return {
        type:END_APP_PRODUCT_CATEGORY_FETCH,
        payload:{
            success,error
        }
    };
}


export  function searchAppProductCategory(filters){
    const api_server = Environment.api_host("SEARCH");
    const timestamp     =   (new Date()).getTime();
    var url   =   `${api_server}/v2/dsl/attributes?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startProductCategorySearch());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url, filters);
            if(!(error && error.message === 'cancelled')) {
                dispatch(endProductCategorySearch(response,error));
            }
        } catch(e){
            dispatch(endProductCategorySearch(null,{
                message:e.message
            }));
            return;
        }
    };
} 
