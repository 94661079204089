import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import moment from 'moment';
import AddApprover from '../../../../../../../Common/UserGroup/AddApprover';

import {
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  user: state.authUser.user,
  report: state.evaluationReportData.report,
  isLoading: state.evaluationReportData.isLoading,
  scores: state.evaludationCriteria.scores,

});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  criteriaHead: {
    padding: '10px',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    // borderTop: '10px solid #f7f7f7'
  },
  criteriaHead2: {
    padding: '10px',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    // borderTop: '10px solid #f7f7f7',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  criteriaExtended: {
    // borderBottom: '1px solid #EEEEEE;'
  },
  titleTxt: {
    fontSize: theme.spacing(2),
    color: '#282D30',
    fontWeight: 600,
    '& > img': {
      display: 'none'
    },
    '&:hover > img': {
      display: 'initial'
    }
  },
  actionTxt: {
    color: '#4175DF',
    width: 'max-content',
    fontSize: theme.spacing(1.75),
  },
  criteriaTitle: {
    fontSize: theme.spacing(2.1),
    fontWeight: 500
  },
  criteriaRequirementCount: {
    fontSize: theme.spacing(2),
    fontWeight: 400,
    color: "#ccc",
    textAlign: "right"
  },
  percent: {
    background: '#EBEFF0',
    borderRadius: 15,
    color: '#04363D',
    fontSize: 12,
    padding: 12,
    fontWeight: 500
  },
  secondary: {
    paddingTop: 10
  }
});

class CriteriaHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  render() {
    const { classes, report, type, criteria, product } = this.props;

    var scheduleText = "No Schedule";
    if (criteria.schedule !== null) {
      scheduleText = moment(criteria.schedule).format("MMM Do YYYY")
    }

    let priorityText = '';
    if (criteria.priority !== undefined && criteria.priority !== null && criteria.priority === 0) {
      priorityText = 'No Weightage';
    }
    if (criteria.priority !== undefined && criteria.priority !== null && criteria.priority > 0) {
      priorityText = 'Weightage: ' + criteria.priority + '%';
    }

    var criteria_score = 0;
    if (this.props.scores[product.ID] !== undefined && this.props.scores[product.ID][criteria.id] !== undefined && product.ID !== undefined) {
      var score_obj = this.props.scores[product.ID][criteria.id];
      criteria_score = parseFloat((parseFloat(score_obj.totalOpted) / parseFloat(score_obj.totalMax)) * 100).toFixed(1)
      if(isNaN(criteria_score)){
        criteria_score = 0;
      }
    }


    // var maxScore = this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation?.MaxScore && this.props.evaluation?.MaxScore !== null && this.props.evaluation?.MaxScore !== '' ? this.props.evaluation.MaxScore : 5;

    return (
      <div>
        {type === 'main' && <div>
          <Grid
            container
            alignItems="center"
            className={classnames(classes.criteriaHead, classes.criteriaExtended)}
          >
            <Grid item style={{ paddingRight: 20 }}>
              <Typography className={classes.titleTxt}>
                {this.state.name || criteria.name}
                {criteria.is_mandatory && <font style={{ color: "red" }}>*</font>}
              </Typography>
              <Grid container alignItems="center" style={{ marginTop: 7 }}>
                <Typography className={classes.actionTxt}>{scheduleText}</Typography>
                <Divider orientation="vertical" flexItem style={{ margin: '0px 10px' }} />
                <Typography className={classes.actionTxt}>{priorityText}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <AddApprover
                  evaluationId={this.props.match.params.evaluation_id}
                  criteriaId={criteria.id}
                  criterias={report?.Criterias}
                  editable={false}
                  position={'CriteriaHeadSetup'}
                  onAdd={(user) => {
                  }}
                  users={criteria.colloborator}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>}

        {type === 'secondary' && <div className={classnames(classes.criteriaHead2, classes.secondary)}>
          <Grid container>
            <Grid item lg={10}>
              <Typography className={classes.criteriaTitle}> {criteria.name} </Typography>
            </Grid>
            <Grid item lg={2}>
              <Typography className={classes.criteriaRequirementCount}>
                <span className={classes.percent}> {criteria_score}%</span>
              </Typography>
            </Grid>
          </Grid>
        </div>}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CriteriaHead));
