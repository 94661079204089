import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button } from '@material-ui/core';
import {updateInitalFlowFlag} from "../../../redux/InitialFlowFlag/action"
import Explore from "../../../assets/images/explore.svg";
import Image from 'Components/Common/image.jsx'

const connectedProps = (state) => ({
    isProgress:state.initialFlowFlag.isProgress,
    error:state.initialFlowFlag.error,
    success:state.initialFlowFlag.success,
});

const connectionActions = {
    updateInitalFlowFlag:updateInitalFlowFlag
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    'root': {
        boxShadow: "none",
        minHeight: 'calc(100vh - 3em)',
        padding: theme.spacing(10),
        textAlign: 'center',
        border: "1px solid #E5E5E5",
        borderRadius: 0
    },
    "completionContainer": {
        // width: "30%",
        margin: "35px auto",
        textAlign: "center",
        // paddingTop: theme.spacing(20)
    },
    title:{
        fontSize:theme.spacing(4.2),
        color: "#12344D",
        marginTop: theme.spacing(3.4),
        fontWeight: 500
    },
    "formAction": {
        paddingTop: theme.spacing(9.6),
        textAlign: "center",
    },
    "button": {
        fontSize:theme.spacing(3),
        fontWeight:600,
        width: "200px"
    },
    "image": {
        marginTop: theme.spacing(9.8)
    }
});

class Completion extends React.Component {

    componentDidUpdate(prevProps){
        if(this.props.isProgress === false && prevProps.isProgress === true){
            if(this.props.error !== null){
                var error   =   this.props.error;
                alert(error.message);
            } else {
                this.props.history.push("/app")
            }
        }
    }

    render() {
        const classes = this.props.classes;
        return <Paper className={classes.root}>
            <Image src={Explore} alt="Explore" className={classes.image}/>
            <div className={classes.completionContainer}>
                <Typography variant={"h3"} className={classes.title}>Setting up your account!</Typography>
            </div>
            <div className={classes.formAction}>
                <Button variant="contained" color="secondary" className={classes.button} onClick={() => {
                    this.props.updateInitalFlowFlag();
                }}>Explore Expent</Button>
            </div>
        </Paper>
    }
}

export default connector(compose(
    withStyles(styles)
)(Completion));