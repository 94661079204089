import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogContent, Slide, Typography, Grid
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Avatar } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Tooltip from '@material-ui/core/Tooltip';

import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
} from 'react-flow-renderer';



import { hideAllUsersWorkflowProgressDialog } from "redux/evaluation/workflow/action";

const connectedProps = (state) => ({
  isOpen: state.evaluationWorkflow.isAllUsersWorflowProgressDialogOpen,
});

const connectionActions = {
  hideDialog: hideAllUsersWorkflowProgressDialog,
}; 

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  title: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
    margin: '5px 0px'
  },
  dialogContent: {
    margin: 'auto',
    marginBottom: 10
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  flowSection: {
    height: '87vh',
    maxHeight: "calc( 100vh - 200px)",
    "& .draggable": {
      display: 'none',
    },
    "& .decision-rectangle": {
      width: 66,
      height: 66,
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: 11,
      left: 41,
      border: '1px solid #EEEEEE',
      background: '#fff',
      zIndex: -1
    },
    "& .decision-component": {
      position: 'relative',
      zIndex: 1,
      top: 34
    }
  },
  "@global": {
    '.applicationContent': {
      background: '#fff !important',
      padding: "0 !important"
    },
    ".react-flow__node": {
      padding: '0px !important',
      boxShadow: 'none'
    },
    ".react-flow__node-default, .react-flow__node-input, .react-flow__node-output": {
      padding: '5px 10px',
      fontSize: '7.5px',
      border: 'none'
    },
    '.react-flow__edge-path': {
      strokeWidth: 1.5
    },
  },

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AllUserProgress extends React.Component {
  constructor(props) {
    super(props);
    this.reactFlowWrapper = React.createRef(null);
    this.state = {
      reactFlowInstance: null,
    }
  }

  componentDidUpdate(prevProps) {
  }

  onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView()
    this.setState({
      reactFlowInstance: reactFlowInstance
    })
  }

  render() {
    const classes = this.props.classes;

    var elements = [
      {
        "id": "1064",
        "data": {
          "label": (
            <>
              <div className="node-container-eva">
                <div className='node-header'>
                  <p className="node-title">Start</p>
                </div>
                <div className="node-body">
                  <div className="node-users">
                    <p className='node-msg'>No User</p>
                  </div>
                </div>
              </div>
            </>
          )
        },
        "position": {
          "x": -10,
          "y": -350
        },
        "type": "input"
      },
      {
        "id": "807",
        "data": {
          "label": (
            <>
              <div className="node-container-eva">
                <div className='node-header'>
                  <p className="node-title">Decision Component</p>
                </div>
                <div className="node-body">
                  <div className="node-users">
                    <p className='node-msg'>No User</p>
                  </div>
                </div>
              </div>
            </>
          )
        },
        "position": {
          "x": -15,
          "y": -250
        }
      },
      {
        "id": "1061",
        "data": {
          "label": (
            <>
              <div className="node-container-eva">
                <div className='node-header'>
                  <p className="node-title">Evaluation Details</p>
                </div>
                <div className="node-body">
                  <div className="node-users">
                    <AvatarGroup max={3}>
                      <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{"Raju Kumar \n raaa@gmail.com"}</span>} arrow>
                        <Avatar> R </Avatar>
                      </Tooltip>
                    </AvatarGroup>
                  </div>
                </div>
              </div>
            </>
          )
        },
        "position": {
          "x": -165,
          "y": -150
        }
      },
      {
        "id": "810",
        "data": {
          "label": (
            <>
              <div className="node-container-eva">
                <div className='node-header'>
                  <p className="node-title">Question Gathering</p>
                </div>
                <div className="node-body">
                  <div className="node-users">
                    <p className='node-msg'>No User</p>
                  </div>
                </div>
              </div>
            </>
          )
        },
        "position": {
          "x": 135,
          "y": -150
        }
      },
      {
        "id": "809",
        "data": {
          "label": (
            <>
              <div className="node-container-eva">
                <div className='node-header'>
                  <p className="node-title">Evaluation</p>
                </div>
                <div className="node-body">
                  <div className="node-users">
                    <p className='node-msg'>No User</p>
                  </div>
                </div>
              </div>
            </>
          )
        },
        "position": {
          "x": -15,
          "y": -50
        }
      },
      {
        "id": "811",
        "data": {
          "label": (
            <>
              <div className="node-container-eva">
                <div className='node-header'>
                  <p className="node-title">Feedback Form</p>
                </div>
                <div className="node-body">
                  <div className="node-users">
                    <AvatarGroup max={3}>
                      <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{"Sunil Kumar \n suniltak7@gmail.com"}</span>} arrow>
                        <Avatar> S </Avatar>
                      </Tooltip>
                      <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{"User Demo \n admin@gmail.com"}</span>} arrow>
                        <Avatar> U </Avatar>
                      </Tooltip>
                    </AvatarGroup>
                  </div>
                </div>
              </div>
            </>
          )
        },
        "position": {
          "x": -15,
          "y": 50
        },
      },
      {
        "id": "812",
        "data": {
          "label": (
            <>
              <div className="node-container-eva">
                <div className='node-header'>
                  <p className="node-title">Approval Page</p>
                </div>
                <div className="node-body">
                  <div className="node-users">
                    <p className='node-msg'>No User</p>
                  </div>
                </div>
              </div>
            </>
          )
        },
        "position": {
          "x": -15,
          "y": 150
        }
      },
      {
        "id": "219",
        "source": "807",
        "target": "810",
        "type": "smoothstep",
        "arrowHeadType": 'arrowclosed',
        "style": {
          "stroke": '#3f4e6b',
        },
      },
      {
        "id": "221",
        "source": "810",
        "target": "809",
        "type": "smoothstep",
        "arrowHeadType": 'arrowclosed',
        "style": {
          "stroke": '#3f4e6b',
        },
      },
      {
        "id": "222",
        "source": "809",
        "target": "811",
        "type": "smoothstep",
        "arrowHeadType": 'arrowclosed',
        "style": {
          "stroke": '#3f4e6b',
        },
      },
      {
        "id": "223",
        "source": "811",
        "target": "812",
        "type": "smoothstep",
        "arrowHeadType": 'arrowclosed',
        "style": {
          "stroke": '#3f4e6b',
        },
      },
      {
        "id": "224",
        "source": "812",
        "target": "813",
        "type": "smoothstep",
        "arrowHeadType": 'arrowclosed',
        "style": {
          "stroke": '#3f4e6b',
        },
      },
      {
        "id": "225",
        "source": "813",
        "target": "814",
        "type": "smoothstep",
        "arrowHeadType": 'arrowclosed',
        "style": {
          "stroke": '#3f4e6b',
        },
      },
      {
        "id": "342",
        "source": "807",
        "target": "1061",
        "type": "smoothstep",
        "arrowHeadType": 'arrowclosed',
        "style": {
          "stroke": '#3f4e6b',
        },
      },
      {
        "id": "343",
        "source": "1061",
        "target": "809",
        "type": "smoothstep",
        "arrowHeadType": 'arrowclosed',
        "style": {
          "stroke": '#3f4e6b',
        },
      },
      {
        "id": "344",
        "source": "1064",
        "target": "807",
        "type": "smoothstep",
        "arrowHeadType": 'arrowclosed',
        "style": {
          "stroke": '#3f4e6b',
        },
      }
    ]

    return <Dialog
      onClose={this.props.hideDialog}
      aria-labelledby="app-integrationDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"xl"}
      scroll="body"
      id="evMetaDialog"
    >
      <DialogContent classes={{ root: classes.dialogContent }} >
        <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
        <Typography variant={"h4"} className={classes.title}>User wise Progress</Typography>
        <div className={classes.formBuilderMain}>
          <Grid container>
            <Grid item lg={12}>
              <div className={classes.flowSection} id="dropSection">
                <ReactFlowProvider>
                  <div style={{ width: '100%', height: '100%' }} ref={this.reactFlowWrapper}>
                    <ReactFlow
                      elements={elements}
                      onLoad={this.onLoad}
                      snapToGrid={true}
                      snapGrid={[15, 15]}
                      style={{ background: '#F8F7F7' }}
                      key="edge-with-button"
                      defaultZoom={1.5}
                    >
                      <Controls
                        onInteractiveChange={e => console.log(e)}
                      />
                      <Background color="#000" gap={30} />
                    </ReactFlow>
                  </div>
                </ReactFlowProvider>
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(AllUserProgress));  