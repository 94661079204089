import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AppBarV2 from "../../AppBarV2";
import { Button,  CircularProgress,  Paper  } from '@material-ui/core';
import { withRouter } from 'react-router-dom' 
import { enableOktaSSO,updateOktaSSOIDP,disableOktaSSO, getEnabledCognitoSSOApps } from "redux/authuser/action";
import {showSnackBar} from "redux/snackbar/action";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import * as Environment from "util/Environment";
import copy from 'copy-to-clipboard';
import CommonCss from 'commonCss';

const connectedProps = (state) => ({
    user:state.authUser.user,
    oktaSSOEnableProgress:state.authUser.oktaSSOEnableProgress,
    oktaSSODisableProgress:state.authUser.oktaSSODisableProgress,
    enabledCognitoSSOAppsData: state.authUser.enabledCognitoSSOAppsData,
    enabledCognitoSSOAppsProgress: state.authUser.enabledCognitoSSOAppsProgress,
});

const connectionActions = {
    enableOktaSSO:enableOktaSSO,
    updateOktaSSOIDP:updateOktaSSOIDP,
    disableOktaSSO:disableOktaSSO,
    showSnackBar:showSnackBar,
    getEnabledCognitoSSOApps:getEnabledCognitoSSOApps,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    rootContainer: CommonCss.rootContainer,
    companySection: {
        marginTop: theme.spacing(4.2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 30
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(2.5),
        marginLeft: 20
    },
    steperContent:{
        padding:theme.spacing(4)
    },
    "button": {
        background: "#3C3C3C",
        borderRadius: 5,
        color: "#fff",
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        padding: theme.spacing(1, 3),
        marginTop: 10,
        "&:hover": {
        background: "#3C3C3C",
        },
    },
    enableSSoContainer:{
        width:"30%",
        margin:'30px auto',
        textAlign:"center"
    },
    idpSSoContainer:{
        width:"90%",
        margin:'0px auto',
        marginBottom:theme.spacing(2),
        "& li":{
            marginBottom:theme.spacing(2),
        }
    },
    idpForm:{
        width:"45%",
        // margin:'0px auto',
        marginBottom:theme.spacing(2),
        marginTop:theme.spacing(2),
        border:"solid 1px #ccc",
        padding:theme.spacing(2,6),
        borderRadius:theme.spacing(1)
    },
    alert:{
        margin:'30px auto',
    },
    formAction:{
        textAlign:"right"
    },
    disableAction:{
        textAlign:"right"
    },
    copy:{
        cursor:"pointer",
        color:"#4A87F8",
        fontSize:"12px",
        marginLeft:theme.spacing(0.5)
    }
});


class OktaSSOIntegration extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            activeStep:-1,
            error:"",
            idp:"",
            idp_error:"",
        }
        this.enableSSO  =   this.enableSSO.bind(this);
        this.updateIDP = this.updateIDP.bind(this);   
        this.disableIntegration = this.disableIntegration.bind(this);   
        this.copyText = this.copyText.bind(this);   
    }

    componentDidMount(){
        this.props.getEnabledCognitoSSOApps()
    }

    componentDidUpdate(prevProps) {
        if(this.props.oktaSSODisableProgress === false && prevProps.oktaSSODisableProgress === true){
            this.props.getEnabledCognitoSSOApps()
        }
        if(this.props.oktaSSOEnableProgress === false && prevProps.oktaSSOEnableProgress === true){
            window.location.reload();
        }
    }    

    enableSSO(){
        this.props.enableOktaSSO();
    }

    disableIntegration(){
        this.props.disableOktaSSO();
    }

    copyText(text){
        copy(text)
        this.props.showSnackBar("Copied !!","success",500);
    }
    isValidHttpUrl(string) {
        let url;
        
        try {
          url = new URL(string);
        } catch (_) {
          return false;  
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
      }

    updateIDP(){
        this.setState({
            'error':"",
            "idp_error":""
        });
        var idp = this.state.idp;
        if(String(idp).trim().length == 0 || !this.isValidHttpUrl(String(idp).trim())){
            this.setState({
                "idp_error":"Please enter valid url."
            });
            return;
        }

        this.props.updateOktaSSOIDP(idp)

    }
    render() {
        var user_url = Environment.api_host("USER");
        const classes = this.props.classes;
        if(this.props.user == null){
            return null;
        }
        var org = this.props.user.Organization

        var activeStep = 0;
        var azureConfig  = this.props.enabledCognitoSSOAppsData?.find(item=> item.Driver === 'okta')
        if(this.state.activeStep == -1){
            if(!azureConfig){
                activeStep = 0;
            }
    
            if(azureConfig && (azureConfig?.IDPURL === undefined || azureConfig?.IDPURL === null || String(azureConfig?.IDPURL).trim().length === 0)){
                activeStep = 1;
            }
    
            if(azureConfig?.IDPURL !== undefined && azureConfig?.IDPURL !== null && String(azureConfig?.IDPURL).trim().length !== 0){
                activeStep = 2;
            }
        } else {
            activeStep=this.state.activeStep
        }

        const loading =  this.props.enabledCognitoSSOAppsProgress;
        

        return <div>
                <AppBarV2 title="Okta SSO Integration" withBack={true} />
                <div className={classes.rootContainer}>
                <div className={classes.companySection}>
                    <p className={classes.subTitle}></p>
                </div>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step >
                        <StepLabel >Enable SSO</StepLabel>
                    </Step>
                    <Step >
                        <StepLabel >Setup Expent SAML</StepLabel>
                    </Step>
                    <Step >
                        <StepLabel >Setup Expent App</StepLabel>
                    </Step>
                </Stepper>
                <Paper elevation={0} className={classes.steperContent}>
                    {activeStep == 0 && <div>
                            <div className={classes.enableSSoContainer}>
                                <div className={classes.alert}>
                                    {   this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                                </div>
                                {!this.props.oktaSSOEnableProgress && !loading &&  <Button type="submit" variant="contained" 
                                color="secondary" className={classes.button} 
                                onClick={this.enableSSO}>
                                    Enable Okta SSO
                                </Button>}
                                {(this.props.oktaSSOEnableProgress || loading) && <CircularProgress />}
                            </div>
                        </div>}
                    {activeStep == 1 && <div>
                        <div className={classes.idpSSoContainer}>
                            <div className={classes.disableAction}>
                                {!this.props.oktaSSODisableProgress &&  <Button  variant="contained" color="secondary" className={classes.button} onClick={this.disableIntegration}>Disable Integration</Button>}
                                {(this.props.oktaSSODisableProgress || loading) && <CircularProgress />}
                            </div>
                        </div>
                        <div className={classes.idpSSoContainer}>
                            <Typography variant={"h6"}>Create a SAML app in Okta</Typography>
                            <ol>
                                <li><Typography>Open the Okta Admin Console</Typography></li>
                                <li><Typography>On the left menu, choose <font style={{"fontWeight":"600"}}>Applications</font>, and then choose <font style={{"fontWeight":"600"}}>Applications</font></Typography></li>
                                <li><Typography>On the <font style={{"fontWeight":"600"}}>Applications</font> page, select the <font style={{"fontWeight":"600"}}>Create App Integration</font> button</Typography></li>
                                <li><Typography>In the <font style={{"fontWeight":"600"}}>Create a new app integration</font> dialog, for the <font style={{"fontWeight":"600"}}>Sign-on method</font>, choose <font style={{"fontWeight":"600"}}>SAML 2.0</font></Typography></li>
                                <li><Typography>Choose <font style={{"fontWeight":"600"}}>Next</font>.</Typography></li>
                            </ol>
                        </div>
                        <div className={classes.idpSSoContainer}>
                            <Typography variant={"h6"}>Configure SAML integration for your Okta app</Typography>
                            <ol>
                                <li><Typography>On the <font style={{"fontWeight":"600"}}>Create SAML Integration</font> page, under <font style={{"fontWeight":"600"}}>General Settings</font>, enter the App name as <font style={{"fontWeight":"600"}}>Expent-SSO</font><span className={classes.copy} onClick={()=>{
                                        this.copyText(`Expent-SSO`)
                                    }}>Copy</span></Typography></li>
                                <li><Typography><font style={{"fontWeight":"600"}}>Enable</font> the Do not display application icon to users and Do not display application icon in the Okta Mobile app checkboxes under <font style={{"fontWeight":"600"}}>App visibility</font></Typography></li>
                                <li><Typography>Choose <font style={{"fontWeight":"600"}}>Next</font>.</Typography></li>
                                <li>
                                    <Typography>In the <font style={{"fontWeight":"600"}}>Configure SAML</font> Tab, under <font style={{"fontWeight":"600"}}>SAML Settings</font>, for <font style={{"fontWeight":"600"}}>Single sign on URL</font></Typography>
                                    <Typography>enter <font style={{"fontWeight":"600"}}>{azureConfig?.DomainName}/saml2/idpresponse</font><span className={classes.copy} onClick={()=>{
                                        this.copyText(`${azureConfig?.DomainName}/saml2/idpresponse`)
                                    }}>Copy</span></Typography>
                                </li>
                                <li><Typography>For <font style={{"fontWeight":"600"}}>Audience URI (SP Entity ID)</font>, enter <font style={{"fontWeight":"600"}}>urn:amazon:cognito:sp:{azureConfig.PoolId}</font><span className={classes.copy} onClick={()=>{
                                        this.copyText(`urn:amazon:cognito:sp:${azureConfig?.PoolId}`)
                                    }}>Copy</span></Typography></li>
                                <li>
                                    <Typography>Under <font style={{"fontWeight":"600"}}>ATTRIBUTE STATEMENTS (OPTIONAL)</font> section, create an entry with the following information:</Typography>
                                    <Typography>For <font style={{"fontWeight":"600"}}>Name</font>, enter the SAML attribute name <font style={{"fontWeight":"600"}}>http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress</font><span className={classes.copy} onClick={()=>{
                                        this.copyText(`http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress`)
                                    }}>Copy</span></Typography>
                                    <Typography>For <font style={{"fontWeight":"600"}}>Value</font>, enter <font style={{"fontWeight":"600"}}>user.email</font><span className={classes.copy} onClick={()=>{
                                        this.copyText(`user.email`)
                                    }}>Copy</span></Typography>
                                </li>
                                <li><Typography>For all other settings on the page, leave them as their default values or set them according to your preference</Typography></li>
                                <li><Typography>Choose <font style={{"fontWeight":"600"}}>Next</font></Typography></li>
                                <li><Typography>Choose a feedback response for Okta Support. Choose I'm an Okta customer adding an internal app and leave the remaining fields with default values</Typography></li>
                                <li><Typography>Choose <font style={{"fontWeight":"600"}}>Finish</font></Typography></li>
                                <li><Typography>Navigate to the <font style={{"fontWeight":"600"}}>Assignments</font> tab</Typography></li>
                                <li><Typography>Assign individual users or groups to the <font style={{"fontWeight":"600"}}>Expent-SSO</font> app using the <font style={{"fontWeight":"600"}}>Assign</font> button on top left</Typography></li>
                            </ol>
                        </div>
                        <div className={classes.idpSSoContainer}>
                            <Typography variant={"h6"}>Get the IdP metadata for Okta application</Typography>
                            <ol>
                                <li><Typography>On the <font style={{"fontWeight":"600"}}>Sign On</font> tab for Okta app, find the <font style={{"fontWeight":"600"}}>Identity Provider metadata</font> hyperlink. Right-click the hyperlink, and then copy the URL, add it to the form field below and select <font style={{"fontWeight":"600"}}>Submit</font></Typography></li>
                            </ol>
                        </div>
                        <div className={classes.idpSSoContainer}>
                        <div className={classes.idpForm}>
                            <div className={classes.alert}>
                                {   this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                            </div>
                            <div className={classes.form}>
                                <OutlinedInput
                                    id={"idpInput"}
                                    label="Identity Provider metadata hyperlink"
                                    value={this.state.idp}
                                    onChange={(event) => {
                                        this.setState({ idp: event.target.value });
                                    }}
                                    required={true}
                                    error={this.state.idp_error.length > 0}
                                    helperText={this.state.idp_error}
                                />
                            </div>
                            <div className={classes.formAction}>
                                {!this.props.oktaSSOEnableProgress &&  <Button  variant="contained" color="secondary" className={classes.button} onClick={this.updateIDP}>Submit</Button>}
                                {(this.props.oktaSSOEnableProgress || loading) && <CircularProgress />}
                            </div>
                        </div>
                        </div>
                    </div>}
                    {activeStep == 2 && <div>
                        <div className={classes.idpSSoContainer}>
                            <div className={classes.disableAction}>
                                {!this.props.oktaSSODisableProgress && !loading && <Button  variant="contained" color="secondary" className={classes.button} onClick={this.disableIntegration}>Disable Integration</Button>}
                                {(this.props.oktaSSODisableProgress || loading) && <CircularProgress />}
                            </div>
                        </div>
                        <div className={classes.idpSSoContainer}>
                            <Typography variant={"h6"}>Add an Okta bookmark application</Typography>
                            <ol>
                                <li><Typography>Sign in to your Okta admin console</Typography></li>
                                <li><Typography>Navigate to  <font style={{"fontWeight":"600"}}>Applications &gt; Applications</font></Typography>
                                </li>
                                <li><Typography>Click <font style={{"fontWeight":"600"}}>Browse App Catalog</font></Typography></li>
                                <li><Typography>In the search bar type <font style={{"fontWeight":"600"}}>bookmark</font></Typography></li>
                                <li><Typography>In the search result, under Integrations select <font style={{"fontWeight":"600"}}>Bookmark app</font></Typography></li>
                                <li><Typography>Click <font style={{"fontWeight":"600"}}>Add</font></Typography></li>
                                <li>
                                    <Typography>In the General Settings section, enter the following details</Typography>
                                    <Typography>For <font style={{"fontWeight":"600"}}>Application label</font>, enter the application name <font style={{"fontWeight":"600"}}>Expent</font><span className={classes.copy} onClick={()=>{
                                        this.copyText("Expent")
                                    }}>Copy</span></Typography>
                                    <Typography>For <font style={{"fontWeight":"600"}}>URL</font>, enter <font style={{"fontWeight":"600"}}>{user_url}/cognito-login/okta/{org.UUID}</font><span className={classes.copy} onClick={()=>{
                                        this.copyText(user_url+`/cognito-login/okta/${org.UUID}`)
                                    }}>Copy</span></Typography>
                                </li>
                                <li><Typography>Click <font style={{"fontWeight":"600"}}>Done</font>.  The application will be added and the Assignments tab will open.</Typography></li>
                                <li><Typography>If returning to the app, select the <font style={{"fontWeight":"600"}}>Assignments</font> tab.</Typography></li>
                                <li><Typography>Assign individual users or groups to the <font style={{"fontWeight":"600"}}>Expent</font> app using the <font style={{"fontWeight":"600"}}>Assign</font> button on top left</Typography></li>
                                <li><Typography>Click on the profile picture of the Bookmark Expent app and upload the Expent logo from <a style={{color:"#4A87F8"}} href="https://assets.expent.ai/expent.png" target="_blank" rel="noreferrer">here</a>.</Typography></li>
                            </ol>
                        </div>
                    </div>}
                </Paper>
                </div>
        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(OktaSSOIntegration));