import {
    START_PRODUCTS_FETCH, END_PRODUCTS_FETCH,
    START_SUBCAT_ORG_PRODUCTS_FETCH, END_SUBCAT_ORG_PRODUCTS_FETCH,
    START_RECOMMENDED_PRODUCTS_FETCH, END_RECOMMENDED_PRODUCTS_FETCH,
    START_CATEGORY_WISE_PRODUCTS_FETCH, END_CATEGORY_WISE_PRODUCTS_FETCH,
    START_CATEGORY_WISE_ORG_PRODUCTS_FETCH, END_CATEGORY_WISE_ORG_PRODUCTS_FETCH,
    START_CATEGORY_SUBCATEGORY_WISE_ORG_PRODUCTS_FETCH, END_CATEGORY_SUBCATEGORY_WISE_ORG_PRODUCTS_FETCH,
    START_PRODUCT_DETAILS_FETCH, END_PRODUCT_DETAILS_FETCH,
    START_PRODUCT_RECOMMENDATION_FETCH, END_PRODUCT_RECOMMENDATION_FETCH,
    START_PRODUCT_REQUIREMENT_DETAIL_FETCH, END_PRODUCT_REQUIREMENT_DETAIL_FETCH,
    START_RECOMMENDED_PRODUCTS_FETCH_TO_ADD, END_RECOMMENDED_PRODUCTS_FETCH_TO_ADD,
    START_COMPLEMENTARY_PRODUCTS_REACTION_FETCH, END_COMPLEMENTARY_PRODUCTS_REACTION_FETCH,
    START_PRODUCTS_REACTION_FETCH, END_PRODUCTS_REACTION_FETCH,
    START_UPDATE_PRODUCTS_REACTION, END_UPDATE_PRODUCTS_REACTION,
    START_WISHLISTED_PRODUCTS_FETCH, END_WISHLISTED_PRODUCTS_FETCH,
    START_WISHLISTED_PRODUCTS_ADD, END_WISHLISTED_PRODUCTS_ADD,
    START_PRODUCTS_REACTION_BY_TYPE_FETCH, END_PRODUCTS_REACTION_BY_TYPE_FETCH,
    START_WISHLISTED_PRODUCTS_LIST_FETCH, END_WISHLISTED_PRODUCTS_LIST_FETCH, 
    START_ORG_WISHLISTED_PRODUCTS_LIST_FETCH,END_ORG_WISHLISTED_PRODUCTS_LIST_FETCH,
    SET_ORG_PRODUCT_SORT_FILTER_OPTIONS,
    START_PERSONALIZED_RECOMMENDATIONS_PRODUCTS, END_PERSONALIZED_RECOMMENDATIONS_PRODUCTS,
    ADD_PRODUCT_TO_IS_EXISTING,
    START_CHECK_WISHLISTED_PRODUCT_FETCH, END_CHECK_WISHLISTED_PRODUCT_FETCH,
    SET_ALL_PRODUCT_FILTER_OPTIONS,
    START_COMPANY_RECOMMENDATIONS_PRODUCTS, END_COMPANY_RECOMMENDATIONS_PRODUCTS,
    SHOW_PRODUCT_WHISLISTED_USERS_DIALOG, HIDE_PRODUCT_WHISLISTED_USERS_DIALOG,
    START_FETCH_PRODUCT_WISHLISTED_USERS, END_FETCH_PRODUCT_WISHLISTED_USERS,
    START_DELETE_UNVERIFIED_PRODUCT, END_DELETE_UNVERIFIED_PRODUCT,
    START_ADD_PRODUCT_STATE, END_ADD_PRODUCT_STATE,
    START_DELETE_PRODUCT_STATE, END_DELETE_PRODUCT_STATE,
    START_DELETE_ORG_PRODUCT, END_DELETE_ORG_PRODUCT,
    START_INREVIEW_PRODUCTS_FETCH, END_INREVIEW_PRODUCTS_FETCH,
    START_PRODUCT_SEARCH, END_PRODUCT_SEARCH,
    START_PRODUCT_ALL_SEARCH, END_PRODUCT_ALL_SEARCH 
} from "./action";

const initState = {
   
    subCategory: null,
    fetchProductsProgress: false,
    products: [],
    totalProduct: 0,
    fetchSubcatOrgProductsProgress: false,
    subcatOrgProducts: [],
    totalsubcatOrgProduct: 0,
    fetchRecommendedProductsProgress: false,
    recommendedSubcategory: null,
    recommendedProducts: [],
    fetchRecommendedProductsToAddProgress: false,
    recommendedProductsToAdd: [],
    totalRecommendedProducts: 0,
    experimentDetails: {},
    fetchCategoryWiseProductsProgress: false,
    categoryWiseProducts: [],
    totalCategoryWiseProducts: 0,
    fetchCategoryWiseOrgProductsProgress: false,
    categoryWiseOrgProducts: [],
    totalCategoryWiseOrgProducts: 0,
    productCategory: null,
    // For Product Details Page
    fetchProductDetailsUnderProgress: false,
    productDetails: null,
    // recommendation products
    fetchRecommendationProductsUnderProgress: false,
    recommendationProducts: null,
    productRequirement: {},

    fetchCategorySubCategoryWiseOrgProductsProgress: false,
    categorySubCategoryWiseOrgProducts: [],
    totalCategorySubCategoryWiseOrgProducts: 0,
    productFilters: {},

    fetchProductsReactionProgress: false,
    fetchComplementaryProductsReactionProgress: false,

    addwishlistedProductsProgress: false,

    fetchwishlistedProductsProgress: false,
    wishlistedProducts: null,

    fetchProductReactionByTypeProgress: false,

    fetchwishlistedProductListProgress: false,
    fetchOrgWishlistedProductListProgress:false,
    
    productSortType: null,
    productSortField: null,

    fetchPersonalizedRecommendationsProductsProgress: false,
    personalizedRecommendationsProducts: null,

    allProductfilterData: {},

    fetchCompanyRecommendationsProductsProgress: false,
    companyRecommendationsProducts: null,

    showProductWhislistedUsersDialog: false,
    showWhislistedUsersForProductId: null,

    fetchProductWishlistedUsersProgress: false,
    productWishlistedUsers: {},
    deleteUnverifiedProductProg: false,
    deleteUnverifiedProductError: null,
    addProductStateProg: false,
    addProductStateData: [],
    addProductStateError: null,
    deleteProductStateProg: false,
    deleteProductStateError: null,
    deleteOrgProductProg: false,
    deleteOrgProductError: null,
    wishlistProduct: [],

    fetchInreviewProductsProgress: false,
    inReviewProducts: [],
    productSearchProg: false,
    productSearchError: null,
    productAllSearchProg: false,
    productAllSearchDataTotal: 0,
    productAllSearchError: null,
    productAllSearchData: {},

    sellerUnverifiedProductProg: false,
    sellerUnverifiedProducts: {},
    myWishListedProducts: [],
    myOrgWishlistedProducts: [],
}

export function productsReducer(state = initState, action) {
    switch (action.type) {
     
        case START_PRODUCT_SEARCH: {
            return {
                ...state,
                productSearchProg: true,
                productSearchError: null,
            };
        }
        case END_PRODUCT_SEARCH: {
            return {
                ...state,
                productSearchProg: false,
                productSearchError: null
            };
        }

        case START_PRODUCT_ALL_SEARCH: {
            let productAllSearchData = state.productAllSearchData
            let productAllSearchDataTotal = state.productAllSearchDataTotal
            const { searchQuery } = action.payload
            if (searchQuery.page === 0) {
                productAllSearchData = {}
                productAllSearchDataTotal = 0
            }
            return {
                ...state,
                productAllSearchDataTotal,
                productAllSearchProg: true,
                productSearchError: null,
                productAllSearchData
            };
        }
        case END_PRODUCT_ALL_SEARCH: {
            let productAllSearchData = state.productAllSearchData
            let productAllSearchDataTotal = state.productAllSearchDataTotal
            const { searchQuery, error, success } = action.payload
            if (action.payload.error === null) {
                if (searchQuery.page === 0) {
                    productAllSearchData = success.data
                    productAllSearchDataTotal = success?.data?.total_products
                } else {
                    productAllSearchData.products = [...productAllSearchData.products, ...success.data.products]
                }

            }
            return {
                ...state,
                productAllSearchProg: false,
                productAllSearchError: error,
                productAllSearchData,
                productAllSearchDataTotal
            };
        }

        case START_ADD_PRODUCT_STATE: {
            return {
                ...state,
                addProductStateProg: true,
                addProductStateData: [],
                addProductStateError: null,
            };
        }
        case END_ADD_PRODUCT_STATE: {
            const { error } = action.payload
            return {
                ...state,
                addProductStateProg: false,
                addProductStateData: action.payload?.success || [],
                addProductStateError: error
            };
        }
        
        case START_DELETE_PRODUCT_STATE: {
            return {
                ...state,
                deleteProductStateProg: true,
                deleteProductStateError: null
            };
        }
        case END_DELETE_PRODUCT_STATE: {
            const { error } = action.payload
           
            return {
                ...state,
                deleteOrgProductProg: false,
                deleteOrgProductError: error
            };
        }
        case START_DELETE_ORG_PRODUCT: {
            return {
                ...state,
                deleteOrgProductProg: true,
                deleteOrgProductError: null
            };
        }
        case END_DELETE_ORG_PRODUCT: {
            const { error } = action.payload
           
            return {
                ...state,
                deleteProductStateProg: false,
                deleteProductStateError: error
            };
        }

        case START_DELETE_UNVERIFIED_PRODUCT: {
            return {
                ...state,
                deleteUnverifiedProductProg: true,
                deleteUnverifiedProductError: null
            };
        }
        case END_DELETE_UNVERIFIED_PRODUCT: {
            return {
                ...state,
                deleteUnverifiedProductProg: false,
                deleteUnverifiedProductError: action.payload.error
            };
        }

        case START_RECOMMENDED_PRODUCTS_FETCH: {
            // let _recommendedProducts = state.recommendedProducts;
            let experimentDetails = state.experimentDetails;
            if (action.payload === 0) {
                // _recommendedProducts = []
                experimentDetails = {}
            }
            return {
                ...state,
                experimentDetails,
                fetchRecommendedProductsProgress: true,
                recommendedProducts: []
            };
        }

        case END_RECOMMENDED_PRODUCTS_FETCH: {
            let success = action.payload.success;
            let _recommendedProducts = state.recommendedProducts;
            let totalRecommendedProducts = state.totalRecommendedProducts;
            let experimentDetails = state.experimentDetails
            let recommended_Subcategory = null;
            if (success !== null && success.Data !== undefined) {
                let Page = 0;
                let Data = success.Data;
                totalRecommendedProducts = success.Data.length;
                recommended_Subcategory = "";
                if (Page === 0) {
                    experimentDetails = success?.ExperimentDetails || {}
                    _recommendedProducts = Data;
                } else {
                    _recommendedProducts = _recommendedProducts.concat(Data)
                }
            }
            return {
                ...state,
                experimentDetails,
                fetchRecommendedProductsProgress: false,
                recommendedProducts: _recommendedProducts,
                totalRecommendedProducts: totalRecommendedProducts,
                recommendedSubcategory: recommended_Subcategory
            }
        }


        case START_RECOMMENDED_PRODUCTS_FETCH_TO_ADD:
            return {
                ...state,
                fetchRecommendedProductsToAddProgress: true,
                recommendedProductsToAdd: []
            };
        case END_RECOMMENDED_PRODUCTS_FETCH_TO_ADD: {
            let success = action.payload.success;
            let recommendedProductsToAdd = state.recommendedProductsToAdd;
            if (success !== null) {
                recommendedProductsToAdd = success?.Data || [];
            }
            return {
                ...state,
                fetchRecommendedProductsToAddProgress: false,
                recommendedProductsToAdd: recommendedProductsToAdd,
            }
        }


        case START_CATEGORY_WISE_PRODUCTS_FETCH: {
            let categoryWiseProducts = state.categoryWiseProducts;
            let productCategory = state.productCategory;
            if (action.payload === 0) {
                categoryWiseProducts = [];
                productCategory = null;
            }
            return {
                ...state,
                fetchCategoryWiseProductsProgress: true,
                categoryWiseProducts: categoryWiseProducts,
                productCategory: productCategory
            };
        }


        case END_CATEGORY_WISE_PRODUCTS_FETCH: {
            let success = action.payload.success;
            let categoryWiseProducts = state.categoryWiseProducts;
            let totalCategoryWiseProducts = state.totalCategoryWiseProducts;
            let productCategory = state.productCategory;
            let productFilters = {};
            if (success !== null) {
                let Page = success.page;
                let Data = success.Data;
                totalCategoryWiseProducts = success.total;
                if (success.aggs) {
                    productFilters = success.aggs
                }
                if (Page === 0) {
                    categoryWiseProducts = Data;
                } else {
                    categoryWiseProducts = categoryWiseProducts.concat(Data)
                }
                if (success?.Category) {
                    productCategory = success?.Category
                }
            }

            return {
                ...state,
                fetchCategoryWiseProductsProgress: false,
                categoryWiseProducts: categoryWiseProducts,
                totalCategoryWiseProducts: totalCategoryWiseProducts,
                productCategory: productCategory,
                productFilters
            }
        }

        case START_CATEGORY_WISE_ORG_PRODUCTS_FETCH: {
            let categoryWiseOrgProducts = state.categoryWiseOrgProducts;
            let productCategory = state.productCategory;
            if (action.payload === 0) {
                categoryWiseOrgProducts = [];
                productCategory = null;
            }
            return {
                ...state,
                fetchCategoryWiseOrgProductsProgress: true,
                categoryWiseOrgProducts: categoryWiseOrgProducts,
                productCategory: productCategory
            };
        }


        case END_CATEGORY_WISE_ORG_PRODUCTS_FETCH: {
            let success = action.payload.success;
            let categoryWiseOrgProducts = state.categoryWiseOrgProducts;
            let totalCategoryWiseOrgProducts = state.totalCategoryWiseOrgProducts;
            let productCategory = state.productCategory;
            if (success !== null) {
                let Page = success.page;
                let Data = success.Data;
                totalCategoryWiseOrgProducts = success.total;
                if (Page === 0) {
                    categoryWiseOrgProducts = Data;
                } else {
                    categoryWiseOrgProducts = categoryWiseOrgProducts.concat(Data)
                }
                if (success?.ProductCategory) {
                    productCategory = success?.ProductCategory
                }
            }

            return {
                ...state,
                fetchCategoryWiseOrgProductsProgress: false,
                categoryWiseOrgProducts: categoryWiseOrgProducts,
                totalCategoryWiseOrgProducts: totalCategoryWiseOrgProducts,
                productCategory: productCategory
            }
        }

        case START_PRODUCTS_FETCH:
            var products = state.products;
            if (action.payload === 0) {
                products = []
            }
            return {
                ...state,
                fetchProductsProgress: true,
                products: products,
                productFilters: {}
            };
        case END_PRODUCTS_FETCH: {
            let success = action.payload.success;
            let products = state.products;
            let subCategory = state.SubCategory
            let productCategory = state.ProductCategory
            let totalProduct = state.totalProduct;
            let productFilters = {};
            if (success !== null) {
                let Page = success.page;
                let Data = success.Data;
                totalProduct = success.total;
                if (success.aggs) {
                    productFilters = success.aggs
                }

                if (Page === 0) {
                    products = Data;
                } else {
                    products = products.concat(Data)
                }

                if (success.SubCategory !== null) {
                    subCategory = success.SubCategory
                }

                if (success.Category !== null) {
                    productCategory = success.Category
                }
            }
            return {
                ...state,
                fetchProductsProgress: false,
                subCategory: subCategory,
                productCategory: productCategory,
                products: products,
                totalProduct: totalProduct,
                productFilters: productFilters
            }
        }

        case ADD_PRODUCT_TO_IS_EXISTING: {
            let newProducts = state.products
            if (action.payload.error === null) {
                newProducts = newProducts.map(product => {
                    const data = product
                    if (data.ID === action.payload.query.productId) {
                        data.IsExisting = true
                    }
                    return data
                })
            }
            return {
                ...state,
                product: newProducts,
            }
        }
        case START_SUBCAT_ORG_PRODUCTS_FETCH: {
            let subcatOrgProducts = state.subcatOrgProducts;
            if (action.payload === 0) {
                subcatOrgProducts = []
            }
            return {
                ...state,
                fetchSubcatOrgProductsProgress: true,
                subcatOrgProducts: subcatOrgProducts
            };
        }

        case END_SUBCAT_ORG_PRODUCTS_FETCH: {
            let success = action.payload.success;
            let subcatOrgProducts = state.subcatOrgProducts;
            let subCategory = state.SubCategory
            let totalsubcatOrgProduct = state.totalsubcatOrgProduct;
            if (success !== null) {
                let Page = success.page;
                let Data = success.Data;
                totalsubcatOrgProduct = success.total;
                if (Page === 0) {
                    subcatOrgProducts = Data;
                } else {
                    subcatOrgProducts = subcatOrgProducts.concat(Data)
                }

                if (success.SubCategory !== null) {
                    subCategory = success.SubCategory
                }
            }
            return {
                ...state,
                fetchSubcatOrgProductsProgress: false,
                subCategory: subCategory,
                subcatOrgProducts: subcatOrgProducts,
                totalsubcatOrgProduct: totalsubcatOrgProduct
            }
        }

        case START_PRODUCT_DETAILS_FETCH:
            return {
                ...state,
                fetchProductDetailsUnderProgress: true,
                fetchProductDetailsUnderError: null
            }

        case END_PRODUCT_DETAILS_FETCH:
            let productDetails = {}
            var response = action.payload.success;
            if (response !== null) {
                productDetails = response
            }
            return {
                ...state,
                fetchProductDetailsUnderProgress: false,
                productDetails: productDetails,
                fetchProductDetailsUnderError: action.payload.error
            }

        case START_PRODUCT_RECOMMENDATION_FETCH:
            return {
                ...state,
                fetchRecommendationProductsUnderProgress: true
            }

        case END_PRODUCT_RECOMMENDATION_FETCH: {
            let recommendationProducts = {}
            let response = action.payload.success;
            if (response !== null) {
                recommendationProducts = response
            }
            return {
                ...state,
                fetchRecommendationProductsUnderProgress: false,
                recommendationProducts: recommendationProducts
            }
        }

        case START_PRODUCT_REQUIREMENT_DETAIL_FETCH:

            return {
                ...state,
                productRequirement: {},
            }
        case END_PRODUCT_REQUIREMENT_DETAIL_FETCH: {
            let _productRequirement = {};
            let response = action.payload.success;
            if (response !== null) {
                response.forEach((record) => {
                    if (_productRequirement[record.ProductID] === undefined) {
                        _productRequirement[record.ProductID] = {};
                    }

                    if (_productRequirement[record.ProductID][record.RequirementID] === undefined) {
                        _productRequirement[record.ProductID][record.RequirementID] = {};
                    }
                    _productRequirement[record.ProductID][record.RequirementID]['data'] = record
                })
            }
            return {
                ...state,
                productRequirement: _productRequirement,
            }
        }


        case START_CATEGORY_SUBCATEGORY_WISE_ORG_PRODUCTS_FETCH: {
            let categorySubCategoryWiseOrgProducts = state.categorySubCategoryWiseOrgProducts;
            return {
                ...state,
                fetchCategorySubCategoryWiseOrgProductsProgress: true,
                categorySubCategoryWiseOrgProducts: categorySubCategoryWiseOrgProducts,
                totalCategorySubCategoryWiseOrgProducts: 0
            };
        }


        case END_CATEGORY_SUBCATEGORY_WISE_ORG_PRODUCTS_FETCH: {
            let success = action.payload.success;
            let categorySubCategoryWiseOrgProducts = state.categorySubCategoryWiseOrgProducts;
            let Total = success?.total;
            if (success !== null) {
                let Page = success.page;
                let Data = success.Data;
                if (Page === 0) {
                    categorySubCategoryWiseOrgProducts = Data;
                } else {
                    categorySubCategoryWiseOrgProducts = categorySubCategoryWiseOrgProducts.concat(Data)
                }
            }

            return {
                ...state,
                fetchCategorySubCategoryWiseOrgProductsProgress: false,
                categorySubCategoryWiseOrgProducts: categorySubCategoryWiseOrgProducts,
                totalCategorySubCategoryWiseOrgProducts: Total
            }
        }

        case START_PRODUCTS_REACTION_FETCH:
            return {
                ...state,
                fetchProductsReactionProgress: true
            };

        case END_PRODUCTS_REACTION_FETCH: {
            const { success, productType } = action.payload;
            const fetchProductsReactionProgress = false
            let type = `${productType}ProductsReaction`
            let reactions = state[type];
            if (success !== null) {
                reactions = success
            }
            return {
                ...state,
                fetchProductsReactionProgress,
                [type]: reactions
            }
        }

        case START_COMPLEMENTARY_PRODUCTS_REACTION_FETCH:
            return {
                ...state,
                fetchComplementaryProductsReactionProgress: true
            };

        case END_COMPLEMENTARY_PRODUCTS_REACTION_FETCH: {
            const { success, productType } = action.payload;
            const fetchComplementaryProductsReactionProgress = false
            let type = `${productType}ProductsReaction`
            let reactions = state[type];
            if (success !== null) {
                reactions = success
            }
            return {
                ...state,
                fetchComplementaryProductsReactionProgress,
                [type]: reactions
            }
        }

        case START_UPDATE_PRODUCTS_REACTION:
            return {
                ...state,
                fetchProductsReactionProgress: true
            };

        case END_UPDATE_PRODUCTS_REACTION: {
            const { success, productType, reactions } = action.payload;
            let type = `${productType}ProductsReaction`;
            const fetchProductsReactionProgress = false;
            let productsReaction = state[type] || [];
            if (success !== null) {
                console.log(success, 'successReaction1');
                productsReaction = productsReaction.map(data => {
                    let productReaction = data;
                    const reactedProduct = success.find(obj => obj.ProductID === productReaction.ProductID)
                    if (reactedProduct) {
                        productReaction = reactedProduct
                    }
                    return productReaction
                })
                console.log(productsReaction, 'successReaction2');
                const reactionsProductIds = productsReaction.map(obj => obj.ProductID)
                const newReactedProducts = success.filter(obj => !reactionsProductIds.includes(obj.ProductID));
                console.log(newReactedProducts, 'successReaction3');
                productsReaction = [...productsReaction, ...newReactedProducts]
            } else if (reactions && productsReaction) {
                const deletedReactionProductIds = reactions?.filter(o => o.opType === 'delete').map(o => o.productId)
                productsReaction = productsReaction.filter(o => !deletedReactionProductIds.includes(o.ProductID))
            }
            return {
                ...state,
                fetchProductsReactionProgress,
                [type]: productsReaction
            }
        }

        case START_WISHLISTED_PRODUCTS_FETCH:
            return {
                ...state,
                fetchwishlistedProductsProgress: true,
                wishlistedProducts: null,
                wishlistedProductIds: []
            };

        case END_WISHLISTED_PRODUCTS_FETCH:
            let wishlistedData = action.payload.success;
            let wishlistedIds = [];
            if (wishlistedData !== null) {
                wishlistedData.forEach(function (wpro) {
                    wishlistedIds.push(wpro.ProductID);
                });
            }
            return {
                ...state,
                fetchwishlistedProductsProgress: false,
                wishlistedProducts: wishlistedData,
                wishlistedProductIds: wishlistedIds
            };

        case START_CHECK_WISHLISTED_PRODUCT_FETCH:
            return {
                ...state,
                checkWishlistedProductsProgress: true,
                wishlistedProducts: null,
                wishlistedProductIds: []
            };

        case END_CHECK_WISHLISTED_PRODUCT_FETCH:
            let checkedDatas = action.payload.success;
            let checkedWishlistedIds = [];
            let checkedWishlistedDatas = { wishlist_products: [] };
            if (checkedDatas !== null && checkedDatas.wishlisted_products !== undefined && checkedDatas.wishlisted_products !== null) {
                checkedWishlistedDatas.wishlist_products = checkedDatas.wishlisted_products;
                checkedDatas.wishlisted_products.forEach(function (wpro) {
                    if (wpro.is_wishListed === true) {
                        checkedWishlistedIds.push(wpro.productID);
                    }
                });
            }
            return {
                ...state,
                checkWishlistedProductsProgress: false,
                wishlistedProducts: checkedWishlistedDatas,
                wishlistedProductIds: checkedWishlistedIds
            };

        case START_WISHLISTED_PRODUCTS_LIST_FETCH:
            return {
                ...state,
                fetchwishlistedProductListProgress: true,
                wishlistProduct: []
            };

        case END_WISHLISTED_PRODUCTS_LIST_FETCH:
            const { success } = action.payload;
            let wishlistProduct = state.wishlistProduct;
            let myWishListedProducts = state.myWishListedProducts;
            if (success !== null) {
                wishlistProduct = success;
                myWishListedProducts = success;
            }

            return {
                ...state,
                fetchwishlistedProductListProgress: false,
                wishlistProduct,
                myWishListedProducts
            };


        case START_ORG_WISHLISTED_PRODUCTS_LIST_FETCH:
            return {
                ...state,
                fetchOrgWishlistedProductListProgress: true,
                myOrgWishlistedProducts: []
            };

        case END_ORG_WISHLISTED_PRODUCTS_LIST_FETCH:
            let myOrgWishlistedProducts = state.myOrgWishlistedProducts;
            if (action.payload.success !== null) {
                myOrgWishlistedProducts = action.payload.success;
            }
            return {
                ...state,
                fetchOrgWishlistedProductListProgress: false,
                myOrgWishlistedProducts
            };

        case START_WISHLISTED_PRODUCTS_ADD:
            return {
                ...state,
                addwishlistedProductsProgress: true
            };

        case END_WISHLISTED_PRODUCTS_ADD:
            var wishlistIds = [...state.wishlistedProductIds]
            if (action.payload.wishlistedData.wishlist_products !== undefined) {
                var wItems = action.payload.wishlistedData.wishlist_products;
                wItems.forEach(function (itm) {
                    if (itm.opType === 'upsert') {
                        wishlistIds.push(itm.productID);
                    } else {
                        wishlistIds.splice(wishlistIds.indexOf(itm.productID), 1);
                    }
                });
            }
            return {
                ...state,
                addwishlistedProductsProgress: false,
                wishlistedProductIds: wishlistIds
            };

        case START_PRODUCTS_REACTION_BY_TYPE_FETCH:
            return {
                ...state,
                fetchProductReactionByTypeProgress: true
            }

        case END_PRODUCTS_REACTION_BY_TYPE_FETCH: {
            const { success, type } = action.payload;
            let typeOfReaction = `${type}ProductsReactions`;
            return {
                ...state,
                fetchProductReactionByTypeProgress: false,
                [typeOfReaction]: success
            }
        }
        case SET_ORG_PRODUCT_SORT_FILTER_OPTIONS:
            return {
                ...state,
                productSortType: action.payload.sortType,
                productSortField: action.payload.sortValue,
            };

        case SET_ALL_PRODUCT_FILTER_OPTIONS:
            return {
                ...state,
                allProductfilterData: action.payload.filterData,
            };

        case START_PERSONALIZED_RECOMMENDATIONS_PRODUCTS:
            return {
                ...state,
                fetchPersonalizedRecommendationsProductsProgress: true,
                personalizedRecommendationsProducts: null
            }

        case END_PERSONALIZED_RECOMMENDATIONS_PRODUCTS: {
            const { success } = action.payload
            let personalizedRecommendationsProducts = state.personalizedRecommendationsProducts
            if (success) {
                if (success && success.status === 200) {
                    personalizedRecommendationsProducts = success?.Data || null
                }
            }
            return {
                ...state,
                fetchPersonalizedRecommendationsProductsProgress: false,
                personalizedRecommendationsProducts,
            }
        }

        case START_COMPANY_RECOMMENDATIONS_PRODUCTS:
            return {
                ...state,
                fetchCompanyRecommendationsProductsProgress: true,
                companyRecommendationsProducts: null
            }

        case END_COMPANY_RECOMMENDATIONS_PRODUCTS: {
            const { success } = action.payload
            return {
                ...state,
                fetchCompanyRecommendationsProductsProgress: false,
                companyRecommendationsProducts: success?.Data || [],
            }
        }

        case SHOW_PRODUCT_WHISLISTED_USERS_DIALOG:
            return {
                ...state,
                showProductWhislistedUsersDialog: true,
                showWhislistedUsersForProductId: action.payload.productId
            };
        case HIDE_PRODUCT_WHISLISTED_USERS_DIALOG:
            return {
                ...state,
                showProductWhislistedUsersDialog: false,
                showWhislistedUsersForProductId: null
            };

        case START_FETCH_PRODUCT_WISHLISTED_USERS:
            return {
                ...state,
                fetchProductWishlistedUsersProgress: true,
                productWishlistedUsers: {}
            };

        case END_FETCH_PRODUCT_WISHLISTED_USERS:
            return {
                ...state,
                fetchProductWishlistedUsersProgress: false,
                productWishlistedUsers: action.payload.success
            };

        case START_INREVIEW_PRODUCTS_FETCH:
            return {
                ...state,
                fetchInreviewProductsProgress: true,
            };

        case END_INREVIEW_PRODUCTS_FETCH: {
            let inreview_success = action.payload.success;
            return {
                ...state,
                fetchInreviewProductsProgress: false,
                inReviewProducts: inreview_success,
            }
        }

        default:
            return state;
    }
}