import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

// Redux
import { hideAddSurveyDialog, addNewSurvey, updateSurvey } from "redux/survey/action";
import { showSnackBar } from "redux/snackbar/action";
import { fetchOrgProducts } from "redux/product/orgProduct/action";

import { productSearch } from "redux/product/action";

import { getOrgWorkflow } from "redux/evaluation/workflow/action";
import { fetchUsers } from "redux/usersSettings/action";

import ReactSelect from "react-select";

import SqureButton from 'Components/Common/SqureButton';

import { fetchOrgForms } from "redux/forms/org/action";
import { fetchAppCatlogProducts } from "redux/product/appCatalog/action"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    user: state.authUser.user,
    isShowAddSurveyDialog: state.survey.isShowAddSurveyDialog,
    productFecthProgress: state.orgProduct.orgProductFetchProg,
    products: state.orgProduct.orgProducts,
    orgWorkFlowProgress: state.evaluationWorkflow.orgWorkFlowProgress,
    orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
    isUserProgress: state.authUser.isUserProgress,
    usersSettings: state.usersSettings,
    addNewSurveyProgress: state.survey.addNewSurveyProgress,
    addNewSurveyError: state.survey.addNewSurveyError,
    updateSurveyProgress: state.survey.updateSurveyProgress,
    updateSurveyError: state.survey.updateSurveyError,
    surveyData: state.survey.surveyData,
    isNewSurvey: state.survey.isNewSurvey,
    fetchOrgFormsProgress: state.orgForms.fetchOrgFormsProgress,
    forms: state.orgForms.forms,
    surveyForProduct: state.survey.surveyForProduct,
    fetchAppCatalogProductsProgress: state.appCatalog.fetchAppCatalogProductsProgress,
    fetchAppCatalogProductsError: state.appCatalog.fetchAppCatalogProductsError,
    appCatalogProducts: state.appCatalog.products,
    
});

const connectionActions = {
    showSnackBar: showSnackBar,
    hideAddSurveyDialog: hideAddSurveyDialog,
    fetchOrgProducts: fetchOrgProducts,
    getOrgWorkflow: getOrgWorkflow,
    fetchUsers: fetchUsers,
    addNewSurvey: addNewSurvey,
    updateSurvey: updateSurvey,
    fetchOrgForms: fetchOrgForms,
    productSearch: productSearch,
    fetchAppCatlogProducts: fetchAppCatlogProducts,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    header: {
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: ' center space-between',
        alignItems: 'center'
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(2.5),
    },
    close: {
        position: 'absolute',
        right: 15,
        top: 15,
        cursor: 'pointer',
        color: '#6F6F6F',
        '& svg': {
            stroke: 'white',
            fontSize: '24px'
        }
    },
    headingForm: {
        marginBottom: theme.spacing(1.6),
        fontSize: theme.spacing(2),
        color: "#6C6C6C"
    },
    nameBox: {
        marginTop: 20,
        '& [class*="inputRoot"]': {
            minHeight: '38px !important'
        },
        '& [class*="outlinedInput"]': {
            padding: '0px 10px !important',
            fontSize: 14,
            color: '#4a4141'
        }
    },
    surveyNameBox: {
        marginTop: 20,
        marginBottom: 20,
    },
    outlineInput: {
        color: '#4a4141',
        padding: 10,
        fontSize: 14,
        width: '100%',
        borderRadius: 4,
        border: '1px solid #cccccc',
        minHeight: 38,
        '&:focus': { 
            outline: 'none !important',
            border: '2px solid #2684FF',
         }
    },
    surveyBody: {
        padding: '15px 50px',
        border: '1px solid #f1f1f1'
    },
    bottomActions: {
        marginTop: 20,
        marginBottom: 20,
        textAlign: 'center'
    },
    required: {
        color: 'red'
    },
    alert: {
        marginBottom: 10
    }
});

class AddSurveyDialog extends React.Component {
    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.state = {
            error: '',
            productOptions: [],
            selectedProduct: null,
            workflowOptions: [],
            selectedWorkflow: null,
            selectedCollaborators: null,
            surveyName: '',
            page: 0,
            pageSize: 1000,
            query: '',
        }

        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.clearError = this.clearError.bind(this)
    }

    componentDidMount() {
        if (!this.props.isNewSurvey) {
            this.initSettings();
        }
        var additionalParam = {
            field_setname: 'all',
            text: '',
            filter_fields: { },
            analytics_only: false,
            qtype_page: ['existing',0],
            page_size: 1000,
            sort_fields: []
        }
        this.props.fetchOrgForms(this.state.page, this.state.pageSize);
        this.props.fetchAppCatlogProducts(additionalParam);

        if(this.props.surveyForProduct !== null){
            this.setState({
                selectedProduct : this.props.surveyForProduct,
                surveyName : 'Survey of '+ this.props.surveyForProduct?.label 
            })
        }
        // this.props.getOrgWorkflow({ types: [1], page: this.state.page, pageSize: this.state.pageSize })
    }

    componentDidUpdate(prevProps) {
        if (!this.props.productFecthProgress && prevProps.productFecthProgress) {
            let productOptions = []
            if (this.props.products !== undefined && this.props.products !== null) {
                this.props.products.forEach((item) => {
                    if (item.Name === undefined || item.ID === undefined) {
                        return;
                    }
                    productOptions.push({
                        label: item.Name,
                        value: item.ID
                    })
                })
            }
            this.setState({
                productOptions: productOptions
            })
        }

        if (!this.props.fetchAppCatalogProductsProgress && prevProps.fetchAppCatalogProductsProgress) {
            if (this.props.appCatalogProducts?.existing?.items) {
                let productOptions = []
                this.props.appCatalogProducts.existing?.items.forEach((item) => {
                    if (item.name === undefined || item.id === undefined) {
                        return;
                    }
                    productOptions.push({
                        label: item.name,
                        value: parseInt(item.id)
                    })
                })
                this.setState({
                    productOptions: productOptions
                })
            }
        }

        if (this.props.orgWorkFlowProgress === false && prevProps.orgWorkFlowProgress === true) {
            if (this.props.orgWorkFlowList !== undefined && this.props.orgWorkFlowList !== null && this.props.orgWorkFlowList.Data !== undefined) {
                let workflowOptions = []
                this.props.orgWorkFlowList.Data.forEach((item) => {
                    if (item.Name === undefined || item.ID === undefined) {
                        return;
                    }
                    workflowOptions.push({
                        label: item.Name,
                        value: item.ID,
                        type: item.Type
                    })
                })
                this.setState({
                    workflowOptions: workflowOptions
                })
            }
        }

        if (this.props.fetchOrgFormsProgress === false && prevProps.fetchOrgFormsProgress === true) {
            if (this.props.forms !== undefined && this.props.forms !== null) {
                let workflowOptions = []
                this.props.forms.forEach((item) => {
                    workflowOptions.push({
                        label: item.FormTitle,
                        value: item.ID,
                        type: 'ORG'
                    })
                })
                this.setState({
                    workflowOptions: workflowOptions
                })
            }
        }

    }

    initSettings() {
        console.log(this.props.surveyData, 'SurveyData')
        if (this.props.surveyData !== null) {
            const { surveyData } = this.props;
            this.setState({
                surveyName: surveyData.Name,
                selectedProduct: {
                    label: surveyData?.Products[0].Name,
                    value: surveyData?.Products[0].ID,
                },
                selectedWorkflow: {
                    label: surveyData?.Workflow.Name,
                    value: surveyData?.Workflow.ID,
                },
                selectedCollaborators: surveyData?.Collaborators.map(o => { return { label: o.Name, value: o.ID } })
            })
        }
    }

    fetchUsersList = (query = '') => {
        this.props.fetchUsers(0, 10, query);
    };

    handleChange = (selectedOptions) => {
        console.log(selectedOptions)
        var newSelectedOptions = null;
        if (selectedOptions !== null) {
            newSelectedOptions = selectedOptions.map((item) => {
                return {
                    value: item.value,
                    label: item.name,
                    name: item.name,
                    email: item.email,
                }
            })
        }
        this.setState({
            selectedCollaborators: newSelectedOptions
        }, () => {
            this.clearError();
        })
    }

    clearError() {
        this.setState({
            error: ''
        })
    }

    handleFormSubmit() {
        this.clearError();
        const { selectedProduct, selectedCollaborators, selectedWorkflow, surveyName } = this.state;
        if (selectedProduct === null) {
            this.setState({
                error: 'Please select product'
            })
            return;
        }

        if (surveyName === '' || surveyName === null) {
            this.setState({
                error: 'Please enter survey name'
            })
            return;
        }

        if (selectedWorkflow === null) {
            this.setState({
                error: 'Please select form'
            })
            return;
        }

        if (selectedCollaborators === null) {
            this.setState({
                error: 'Please select at least one collaborators'
            })
            return;
        }

        var data = {
            product_id: selectedProduct.value,
            collaborators: selectedCollaborators.map((col) => { return col.value }),
            title: surveyName,
            description: '',
            workflow_id: selectedWorkflow.value
        }

        if (this.props.isNewSurvey) {
            this.props.addNewSurvey(data);
        } else {
            data.name = surveyName;
            const { surveyData } = this.props;
            this.props.updateSurvey(surveyData.ID, data);
        }
        console.log(data, 'Dataaa');

    }


    render() {
        const classes = this.props.classes;
        const { productOptions, workflowOptions } = this.state;
        const {surveyForProduct } = this.props;
        let usersList = this.props.usersSettings.users.map((user) => {
            return { ...user };
        });

        return <Dialog
            onClose={this.props.hideAddSurveyDialog}
            aria-labelledby="add-upload-dialog"
            open={this.props.isShowAddSurveyDialog}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll={"body"}
            id="addProductDialog"
            PaperProps={{ style: { overflowY: 'visible' } }}
        >
            <DialogContent classes={{ root: classes.dialogContent }} style={{ overflowY: 'visible' }}>
                <span className={classes.close} onClick={() => this.props.hideAddSurveyDialog()}>
                    <CloseIcon className={classes.close} />
                </span>
                <Typography variant={"h6"} className={classes.title}> {this.props.isNewSurvey ? 'Add new survey' : 'Update Servey'}</Typography>
                <div className={classes.alert}>
                    {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                </div>

                <div className={classes.surveyBody}>
                    {surveyForProduct === null && <div>
                        <Typography className={classes.headingForm}>
                            Select Product <span className={classes.required}>*</span>
                        </Typography>
                        <ReactSelect
                            isClearable
                            isSearchable={true}
                            menuPosition='fixed'
                            options={productOptions}
                            classNamePrefix="select"
                            placeholder="Search Product"
                            onChange={(e) => {
                                this.setState({
                                    selectedProduct: e,
                                    surveyName: e !== null ? 'Survey of ' + e?.label : ''
                                }, () => {
                                    this.clearError();
                                })
                            }}
                            value={this.state.selectedProduct}
                        />
                    </div>}

                    <div className={classes.surveyNameBox}>
                        <Typography className={classes.headingForm}>
                            Survey Name <span className={classes.required}>*</span>
                        </Typography>
                        <input
                            className={classes.outlineInput}
                            type="text"
                            required
                            value={this.state.surveyName}
                            onChange={(event) => {
                                this.setState({
                                    surveyName: event.target.value
                                }, () => {
                                    this.clearError()
                                })
                            }}
                        />
                    </div>


                    <div>
                        <Typography className={classes.headingForm}>
                            Select Form <span className={classes.required}>*</span>
                        </Typography>
                        <ReactSelect
                            isClearable
                            isSearchable={true}
                            menuPosition='fixed'
                            options={workflowOptions}
                            classNamePrefix="select"
                            placeholder="Search Form"
                            onChange={(e) => {
                                this.setState({
                                    selectedWorkflow: e
                                }, () => {
                                    this.clearError()
                                })
                            }}
                            value={this.state.selectedWorkflow}
                        />
                    </div>

                    <div className={classes.nameBox}>
                        <Typography className={classes.headingForm}>
                            Colloborators <span className={classes.required}>*</span>
                        </Typography>
                        <ReactSelect
                            isClearable
                            isSearchable={true}
                            isMulti={true}
                            classNamePrefix="select"
                            placeholder="Users"
                            options={usersList.map((item) => {
                                return {
                                    value: item.ID,
                                    label: item.Name + ' - ' + item.Email,
                                    email: item.Email,
                                    name: item.Name,
                                }
                            })}
                            onChange={this.handleChange}
                            onInputChange={(e) => {
                                this.fetchUsersList(e)
                            }}
                            isLoading={this.props.isUserProgress}
                            menuPortalTarget={document.getElementById("dialogUserGroup")}
                            defaultValue={this.state.selectedCollaborators}
                            value={this.state.selectedCollaborators}
                        />
                    </div>

                    <div className={classes.bottomActions}>
                        <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideAddSurveyDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                        <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={() => { this.handleFormSubmit() }}>Save</SqureButton>
                    </div>

                </div>

            </DialogContent>
        </Dialog>
    }
}


export default connector(compose(
    withRouter,
    withStyles(styles)
)(AddSurveyDialog));