import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CommonCss from "commonCss";

import { updateDrawerStyle } from "redux/drawer/action";
import { fetchOrgProducts } from "redux/product/orgProduct/action";
import { authorizedUser } from "redux/authuser/action";
import DefaultImage from "assets/images/evaluation/default.svg";

// components
import Actions from "../Components/Actions";
import FeaturedProducts from "../Components/FeaturedProducts";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import ClusterFolder from "Components/Common/ClusterFolder";
import AppBarV2 from "Components/Application/AppBarV2";

const connectedProps = (state) => ({
  products: state.orgProduct.orgProducts,
  totalOrgProducts: state.orgProduct.totalOrgProducts,
  orgName: state.authUser.user?.Organization?.Name,
  userRole: state.authUser.user?.Role,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  fetchOrgProducts: fetchOrgProducts,
  authorizedUser: authorizedUser,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    actionContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
    },
    statsContainer: {
      marginBottom: theme.spacing(6),
    },
    commingSoon: {
      marginBottom: theme.spacing(6),
      "& div[class*=ComingSoon-root-]": {
        minHeight: 480,
        padding: 20,
        borderRadius: "6px",
      },
    },
    title: {
      // fontSize: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
      fontWeight: 600,
    },
    cardAction: {
      cursor: "pointer",
    },
    topHead: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 40,
      marginBottom: 8,
      "& > .appBar": {
        flex: 1,
      },
      '& h6':{
        fontSize: '1.4em',
        fontWeight: 600,
        margin: '0px'
      }
    },
    viewAll: CommonCss.viewAll,
    cartSection: {
      "& [class*=Cart-root], #cartSection": {
        transition: "all 400ms linear",
        padding: 21,
        right: -300,
        "& *": {
          opacity: 0,
          transition: "all 600ms ease-in",
          // whiteSpace: 'nowrap'
        },
      },
      "&.showCart": {
        "& [class*=Cart-root], #cartSection": {
          right: "0 !important",
          "& *": {
            opacity: 1,
          },
        },
      },
    },
  rootContainer: CommonCss.rootContainer
  });

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageSize: 6,
      sm1: window.matchMedia('(min-width: 576px) and (max-width: 667.98px)').matches,
      sm2: window.matchMedia('(min-width: 668px) and (max-width: 767.98px)').matches
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.props.updateDrawerStyle(true, true);
    this.fetchData();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  fetchData() {
    this.props.fetchOrgProducts(
      this.state.page,
      this.state.pageSize,
      this.state.query,
      "only_products"
    );
  }

  producDetail = (e, product) => {
    this.props.history.push(`/app/products/org-product-details/${product.ID}`);
  };
  reviewProduct = (id) => {
    // e.stopPropagation();
    let redirectPath = `/app/products/product-review/${id}/`;
    this.props.history.push(redirectPath);
  };

  updateDimensions = () => {
    let sm1 = window.matchMedia('(min-width: 576px) and (max-width: 667.98px)').matches;
    let sm2 = window.matchMedia('(min-width: 668px) and (max-width: 767.98px)').matches;
    this.setState({ 
        sm1: sm1, 
        sm2: sm2
    });
  };

  render() {
    const classes = this.props.classes;
    return (
      <div>
        <AppBarV2 title="Evaluation and Review" withBack />
        <div className={classes.rootContainer}>
        <div className={classes.actionContainer}>
          <div className={classes.topHead}>
            <h6>
              {this.props.totalOrgProducts > 0 ? `${this.props.orgName ? this.props.orgName+"'s" : ""} Application Catalog` : ''}
            </h6>
            {(
              <Link
                to="/app/products/org-products"
                className={classes.viewAll}
              >
                {" "}
                View all ({this.props.totalOrgProducts}){" "}
              </Link>
            )}
          </div>
          <Grid container spacing={3}>
            {this.props.products.slice(0, 6).map((product, index) => {
              if(product.orgProduct !== undefined && product.orgProduct['products'] !== undefined){
                return ( <Grid item  lg={2} md={3} key={index} className={classes.productCard}>
                  <div className={classes.productCard}>
                      <ClusterFolder group={product} />
                  </div>
                </Grid>)
              }
              return <Grid item lg={2} md={3} key={index}>
                <div onClick={(e) => this.producDetail(e, product)}>
                  <LayeredCard
                    title={product.Name}
                    subTitle={product.CompanyName}
                    description={product.Description}
                    image={
                      product.ProductLogo == null ||
                      product.ProductLogo.length === 0
                        ? DefaultImage
                        : product.ProductLogo
                    }
                    toogleCheck={(e) => {
                      this.reviewProduct(product.ID);
                    }}
                    onClick={() => {}}
                    numLayer={0}
                    showCheckbox={true}
                    id={product.ID}
                    uniqueId={product.ID + "_d_" + index}
                    IsExisting={true}
                  />
                </div>
              </Grid>
            })}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
              <FeaturedProducts title={"Featured Products"} />
          </Grid>
        </div>
        <div className={classes.statsContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Actions title={"My Tasks"} />
            </Grid>
          </Grid>
        </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Home));
