import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import ErrorPage from './Common/ErrorPage';

// Actions
import { fetchMasterComponents, fetchNextComponent, fetchWorkflowComponentUserResponse,showWorkflowLoader } from "redux/workflow/action";


const connectedProps = (state) => ({
  nextComponentDetails: state.workflow.nextComponentDetails,
  fetchNextComponentProgress: state.workflow.fetchNextComponentProgress,
  componentUserResponse: state.workflow.componentUserResponse,
  fetchComponentUserResponseProgress: state.workflow.fetchComponentUserResponseProgress,
  masterComponentsMap: state.workflow.masterComponentsMap,
  isShowWorkflowLoader: state.workflow.isShowWorkflowLoader,
});

const connectionActions = {
  fetchNextComponent: fetchNextComponent,
  fetchWorkflowComponentUserResponse: fetchWorkflowComponentUserResponse,
  fetchMasterComponents: fetchMasterComponents,
  showWorkflowLoader:showWorkflowLoader,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  loader: {
    textAlign: "center",
    padding: theme.spacing(10, 0)
  },
});

class Workflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isErrorPage : false
    }
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentDidMount() {
    this.props.showWorkflowLoader(true);
    this.props.fetchMasterComponents();
    // var nextEvaluationId = this.getParameterByName("nextEvaluation");
    // if(nextEvaluationId !== undefined && nextEvaluationId !== null){
    //   this.props.fetchNextComponent(nextEvaluationId)
    // }else{
      this.props.fetchNextComponent(this.props.match.params.evaluation_id)
    // }
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchNextComponentProgress === false && prevProps.fetchNextComponentProgress === true) {
      var nextComponent = this.props.nextComponentDetails;
      if(nextComponent !== null &&  nextComponent.WorkflowID !== undefined && nextComponent.ID !== undefined){
        if(nextComponent?.NextEvaluationID !== undefined && nextComponent?.NextEvaluationID !== null && nextComponent?.NextEvaluationID !== '0' && nextComponent?.NextEvaluationID !== 0){
          this.props.history.replace("/app/evaluate/" + nextComponent?.NextEvaluationID + "/workflow/" + nextComponent?.WorkflowID + "/component/" + nextComponent?.ID);
        }else{
          this.props.history.replace("/app/evaluate/" + this.props.match.params.evaluation_id + "/workflow/" + nextComponent?.WorkflowID + "/component/" + nextComponent?.ID);
        }
      }else{
        this.setState({
          isErrorPage : true
        },()=>{
          this.props.showWorkflowLoader();
        })
      }
    }
  }


  render() {
    const classes = this.props.classes;

    if (this.props.isShowWorkflowLoader) {
      return <div className={classes.loader}> <CircularProgress /></div>
    }



    return (
      <div>
          {this.state.isErrorPage && <ErrorPage/>}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Workflow));
