import { Card, CircularProgress } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
// assets

import ProfileForm from "./ProfileForm";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  fetchCompanyProfile,
  updateCompanyProfile,
} from "redux/companyProfileSettings/action";
import {
  fetchIndustries,
  fetchLocations,
  fetchNumOfEmployees,
  fetchOrgType,
} from "redux/master/action";
import { showSnackBar } from "redux/snackbar/action";
import CommonCss from "../../../../../commonCss";
import AppBarV2 from "../../../AppBarV2";

const connectedProps = (state) => ({
  details: state.companyProfileSettings.details,
  locations: state.master.locations,
  numOfEmployees: state.master.numOfEmployees,
  orgType: state.master.orgType,
  industries: state.master.industries,
  updateCompanyProfileProgress:
    state.companyProfileSettings.updateCompanyProfileProgress,
  updateErrorDetails: state.companyProfileSettings.updateErrorDetails,
  fetchCompanyProfileProgress:
    state.companyProfileSettings.fetchCompanyProfileProgress,
});

const connectionActions = {
  fetchCompanyProfile: fetchCompanyProfile,
  updateCompanyProfile: updateCompanyProfile,
  fetchIndustries: fetchIndustries,
  fetchLocations: fetchLocations,
  fetchNumOfEmployees: fetchNumOfEmployees,
  fetchOrgType: fetchOrgType,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    companySection: {
      marginTop: theme.spacing(4.2),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 30,
    },

    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,

      "&:hover": {
        background: "#3C3C3C",
      },
    },

    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginBottom: theme.spacing(2.5),
      marginLeft: 20,
    },

    addProductSection: {
      display: "flex",
      padding: 50,
    },

    productImage: {
      width: 200,
      height: 200,
      border: "1px solid #AAAAAA",
      borderRadius: 11,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },

    formControl: {
      ...CommonCss.formControl,
      "& [class*=MuiInputBase-input-]": {
        transition: "none !important",
        fontSize: "13px",
      },
      "& [class*=MuiInput-formControl-]": {
        width: "100%",
        "&::before": {
          borderBottom: "unset",
        },
        "& [class*=MuiSelect-select-]": {
          "&:focus": {
            backgroundColor: "unset",
          },
        },
      },
    },
    label: CommonCss.label,
    formGroup: CommonCss.formGroup,
    selectField: {
      ...CommonCss.selectField,
      "& [class*=MuiAutocomplete-inputRoot]": {
        minHeight: 44,
        padding: "0 8px",
        fontSize: 14,
        borderRadius: 6,
      },
    },
    rightSection: {
      flex: 1,
      marginLeft: 60,
      marginRight: 30,
      "&.editField": {
        "& .inputSection": {
          display: "block",
        },
        "& .textSection": {
          display: "none",
        },
      },
      "&.readOnly": {
        "& .inputSection, & .mandatory": {
          display: "none",
        },
        "& .textSection": {
          display: "block",
        },
      },
    },
    inputSection: {},
    textSection: {
      fontSize: 16,
      fontWeight: 500,
    },
    formDivide: {
      display: "flex",
    },
    groupFlexLeft: {
      marginRight: 10,
      flex: 1,
    },
    groupFlexRight: {
      marginLeft: 10,
      flex: 1,
    },
    uploadImageLabel: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      flexDirection: "column",
    },
    uploadImage: {
      width: 0,
      overflow: "hidden",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: 11,
    },
    deleteSection: {
      position: "absolute",
      background: "#fff",
      borderRadius: 11,
      padding: "2px 2px 0px 2px",
      top: 0,
      right: 0,
      cursor: "pointer",
    },
    delete: {
      margin: 5,
    },
    cancelIcon: {
      margin: 5,
      "& svg": {
        color: "#e7426f",
        width: 22,
        height: 22,
      },
    },
    buttonSection: {
      textAlign: "center",
      margin: 20,
    },
    button: {},
    mandatory: CommonCss.mandatory,
    profileInfoCard: {
      width: "1000px",
      margin: "0 auto",
      borderRadius: 0,
    },
    productForm: {
      border: "1px solid #F2F2F2",
      margin: 20,
    },
    btnSection: {
      textAlign: "right",
      margin: 10,
    },
    loader: {
      height: 25,
      width: "70%",
    },
    chipElement: {
      '& [class*="MuiChip-root"]': {
        background: "#4A87F8",
        border: "1px solid #4A87F8",
        color: "#fff",
      },
      '& [class*="MuiChip-deleteIcon"]': {
        fill: "#fff",
        width: 17,
      },
    },
    nocursor: {
      cursor: "auto",
    },
  });

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchCompanyProfile();
    this.props.fetchIndustries();
    this.props.fetchLocations();
    this.props.fetchNumOfEmployees();
    this.props.fetchOrgType();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.updateCompanyProfileProgress === false &&
      prevProps.updateCompanyProfileProgress === true
    ) {
      if (this.props.updateErrorDetails == null) {
        this.props.fetchCompanyProfile();
        this.props.showSnackBar("Updated Successfully.", "success", 3000);
      } else {
        this.props.showSnackBar("Failed to update fields.", "error", 3000);
      }
    }
  }

  render() {
    const classes = this.props.classes;
    if (this.props.updateCompanyProfileProgress) {
      return <CircularProgress />;
    }
    return (
      <div>
        <AppBarV2 title="Company profile" withBack={true} />
        <div className={classes.companySection}>
          <p className={classes.subTitle}></p>
        </div>
        <Card className={classes.profileInfoCard}>
          <ProfileForm {...this.props} />
        </Card>
      </div>
    );
  }
}

export default connector(
  compose(withRouter, withStyles(styles))(CompanyProfile)
);
