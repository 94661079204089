import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide } from '@material-ui/core';
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';

// redux
import { showAskLoginPlatformDialog, hideAskLoginPlatformDialog } from "redux/authuser/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    askLoginPlatformDialogOpen: state.authUser.askLoginPlatformDialogOpen,
});

const connectionActions = {
    showAskLoginPlatformDialog: showAskLoginPlatformDialog,
    hideAskLoginPlatformDialog: hideAskLoginPlatformDialog
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginLeft: 20,
        fontWeight: 500,
        marginBottom: 0
    },
    dialogContent: {
        textAlign: 'center'
    },
    subTitle: {
        color: '#4B4B4B',
        fontSize: 13,
    },
    dialog: {
        "& [class*=MuiDialog-paperWidth]": {
            maxWidth: 460,
            minHeight: 63,
            borderRadius: '10px'
        },
        "& [class*=MuiDialogContent-root], & [class*=MuiDialogContent-root]:first-child": {
            padding: 0,
        },
        "& [class*=Step-labelTypo]": {
            fontSize: 13,
            textAlign: 'left'
        }
    },

    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F',
        '& svg': {
            stroke: 'white',
            fontSize: '2em'
        }
    },
    productContainer: {
        padding: '20px 80px'
    }

});

class AskLoginPlatformDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        console.log(this.props)
    }

    render() {
        const classes = this.props.classes;

        return <Dialog
            onClose={this.props.askLoginPlatformDialogOpen}
            aria-labelledby="new-evaluation-dialog"
            open={this.props.productFilterDialog}
            TransitionComponent={Transition}
            fullWidth={true}
            scroll={"body"}
            className={classnames(classes.dialog)}
        >
            <DialogContent classes={{ root: classes.dialogContent }}>
                <div className={classes.close} onClick={() => this.props.hideAskLoginPlatformDialog()}><CloseIcon /></div>
                <div className={classes.productContainer}>
                        <p>SUnilllllllllllllll</p>
                </div>
            </DialogContent>
        </Dialog>
    }
}


export default connector(compose(withRouter, withStyles(styles))(AskLoginPlatformDialog));