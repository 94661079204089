import React from 'react';
import _ from 'underscore';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogContent, Slide, Typography, Grid, Switch, Divider, TextField, CircularProgress,
} from '@material-ui/core';
import { hideEvaluationRequirementAddDialog, addRequirement } from "../../../../../../redux/evaluation/criteria/action";
import { showSnackBar } from "../../../../../../redux/snackbar/action";
import OutlinedSelectInput from "../../../../../Common/Input/OutlinedSelectInput";
import UserGroup from "../../../../../Common/UserGroup/index";
import Alert from '@material-ui/lab/Alert';
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from 'react-router-dom';
import SqureButton from '../../../../../Common/SqureButton';
import classnames from "classnames";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import EvaluateMultiChoiceAnswer from 'Components/Common/EvaluateMultiChoiceAnswer'
import { showEvaluationCollaboratorDialog, fetchSimilarQuestion } from "redux/evaluate/action";

import { addFromOrgEvaluationRequirementTemplate } from "redux/templates/evaluation/org/action"
import RequirementSimilarQuestionDialog from './RequirementSimilarQuestionDialog'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.evaludationCriteria.showRequirementAddDialog,
    criteriaId: state.evaludationCriteria.addRequirementCriteriaId,
    addRequirementProgress: state.evaludationCriteria.addRequirementProgress,
    addRequirementTemplateProg: state.orgTemplateEvaluation.addFromOrgEvaluationRequirementTemplateProg,
    addRequirementTemplateError: state.orgTemplateEvaluation.addFromOrgEvaluationRequirementTemplateError,
    errorRequirementAdd: state.evaludationCriteria.errorRequirementAdd,
    evaluationId: state.evaludationCriteria.evaluationId,
    collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
    similarQuestion: state.evaluate.similarQuestion,
    similarQuestionProg: state.evaluate.similarQuestionProg
});

const connectionActions = {
    hideDialog: hideEvaluationRequirementAddDialog,
    addRequirement: addRequirement,
    showSnackBar: showSnackBar,
    addFromOrgEvaluationRequirementTemplate: addFromOrgEvaluationRequirementTemplate,
    showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog,
    fetchSimilarQuestion: fetchSimilarQuestion
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important"
    },
    modalTitle: {
        fontSize: theme.spacing(2.5),
        marginBottom: theme.spacing(4),
        textAlign: "center"
    },
    helptext: {
        fontSize: theme.spacing(1.8)
    },
    alerts: {
        marginTop: theme.spacing(4)
    },
    form: {
        marginTop: theme.spacing(2),
        width: '60%',
    },
    input: {
        marginBottom: theme.spacing(3)
    },
    scheduleTitle: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 600,
        fontSize: theme.spacing(2)
    },
    scheduleTitle1: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 600,
        fontSize: theme.spacing(1.8)
    },
    actions: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: "center",
    },
    loader: {
        textAlign: "center",
        padding: theme.spacing(10, 0)
    },
    dialogAction: {
        textAlign: "center"
    },
    addNewButton: {
        fontWeight: 600,
        fontSize: theme.spacing(2)
    },
    actionBtn: {
        minWidth: 110
    },
    priority: {
        "& > div": {
            marginBottom: 0,
            justifyContent: 'flex-start',
        },
        "& [class*=MuiInputBase-root]": {
            width: 100,
            color: '#707070',
            fontSize: 13,
            textAlign: 'center'
        },
        "& [class*=MuiSelect-outlined]": {
            color: "#707070",
            fontSize: theme.spacing(1.8),
            "&:after": {
                content: "''",
                width: 7,
                height: 7,
                position: 'absolute',
                left: 9,
                borderRadius: '50%',
                top: '50%',
                transform: 'translate(0px, -50%)'
            },
        },
        "&.low [class*=MuiSelect-outlined]:after": {
            background: 'green'
        },
        "&.medium [class*=MuiSelect-outlined]:after": {
            background: 'orange'
        },
        "&.high [class*=MuiSelect-outlined]:after": {
            background: 'red'
        }
    },

    scoringType: {
        display: 'flex',
        flexDirection: 'row-reverse',
        "& [class*=MuiFormLabel-root]": {
            fontSize: theme.spacing(1.8)
        },
        "& [class*=MuiSelect-outlined]": {
            paddingLeft: 10
        }
    },
    nameYour: {
        marginTop: 40,
        "& [class*=MuiInputBase-root]": {
            maxHeight: 40,
            minHeight: 40,
            "& input": {
                fontSize: theme.spacing(1.8)
            }
        },
    },
    yourQuestion: {
        marginTop: 10,
        "& [class*=MuiInputBase-root]": {
            padding: 0,
            fontSize: theme.spacing(1.8),
            minHeight: 120,
            color: "#707070",
            lineHeight: 1.2,
            "& textarea": {
                minHeight: 100,
            }
        }
    },
    optionLabel: {
        fontWeight: 'bold',
        fontSize: theme.spacing(2),
        padding: 0
    },
    optionDescription: {
        fontWeight: 500,
        padding: 0,
        fontSize: theme.spacing(1.5)
    },
    label: {
        color: '#6C6C6C',
        fontSize: '1em',
        margin: 0,
        padding: 0,
        marginBottom: '0.8em'
    },
    autoSelectInput: {
        "& [class*=MuiInputBase-input]": {
            padding: '0px !important'
        }
    },
    criteriaPriority: {
        minHeight: '25px !important',
        padding: 6,
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        color: '#5F5F5F',
        fontSize: '1.1em',
        marginLeft: 20,
        width: 75,
        textAlign: 'center'
    }
});

class RequirementAddDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isOpen: false,
            error: "",
            success: "",
            'name': '',
            'name_error': '',
            'priority': '',
            'scoring_type': 1,
            'description': '',
            'description_error': '',
            'colloborators': [],
            is_mandatory: false,
            is_editable: false,
            answer_type: 1,
            options: [],
            is_other_option: false,
            character_limit: 0
        }

        this.deleteRequirement = this.deleteRequirement.bind(this);
        this.addRequirementColloborator = this.addRequirementColloborator.bind(this);
        this.submitResponse = this.submitResponse.bind(this);
        this.cleanError = this.cleanError.bind(this);
        this.cleanState = this.cleanState.bind(this);
        this.removeRequirementColloborator = this.removeRequirementColloborator.bind(this)
    }

    cleanState() {
        this.setState({
            isOpen: false,
            open: false,
            error: "",
            success: "",
            'name': '',
            'name_error': '',
            'priority': '',
            'scoring_type': 1,
            is_mandatory: false,
            is_editable: false,
            'description': '',
            answer_type: 1,
            options: [],
            is_other_option: false,
            'description_error': '',
            'colloborators': [],
        });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.isOpen &&
            (this.props.isTemplate ? this.props.addRequirementTemplateProg === false : this.props.addRequirementProgress === false) &&
            (this.props.isTemplate ? prevProps.addRequirementTemplateProg === true : prevProps.addRequirementProgress === true)
        ) {
            if (this.props.errorRequirementAdd === null && this.props.addRequirementTemplateError === null) {
                this.props.hideDialog();
                this.cleanState();
                this.props.showSnackBar("Question Addedd", "success", 3000);
            } else {
                if (this.props.errorRequirementAdd.message !== undefined || this.props.addRequirementTemplateError !== undefined) {
                    this.setState({
                        "error": "Failed to add Question Details."
                    })
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }
    }

    cleanError() {
        this.setState({
            name_error: "",
            description_error: "",
        })
    }

    submitResponse() {
        this.cleanError();

        var name = this.state.name;
        if (name.trim().length === 0) {
            this.setState({
                name_error: "Please enter Section Name."
            });
            return;
        }

        var description = this.state.description || '';
        if (description.trim().length === 0) {
            this.setState({
                description_error: "Please enter Description."
            });
            return;
        }


        const data = {
            name: name,
            description: description,
            priority: parseInt(this.state.priority),
            scoring_type: parseInt(this.state.scoring_type),
            colloborators: this.state.colloborators.map((c) => {
                return c.ID
            }),
            answer_type: this.state.answer_type,
            options: this.state.options,
            is_mandatory: this.state.is_mandatory,
            is_editable: this.state.is_editable,
            is_other_option: this.state.is_other_option,
            character_limit: this.state.character_limit
        }
        if (this.props.isTemplate) {
            this.props.addFromOrgEvaluationRequirementTemplate(this.props.evaluationId, this.props.criteriaId, data)
        } else {
            this.props.addRequirement(this.props.evaluationId, this.props.criteriaId, data)
        }
    }

    addRequirementColloborator(user) {
        var colloborators = this.state.colloborators;
        var existing = colloborators.filter((u) => {
            if (u.ID === user.ID) {
                return true;
            }
            return false;
        })
        if (existing.length > 0) {
            return;
        }
        colloborators.push(user);
        this.setState({ colloborators: colloborators });
    }



    deleteRequirement(index) {
        if (this.state.requirements.length === 1) {
            return;
        }
        var requirements = this.state.requirements;
        requirements.splice(index, 1)
        this.setState({
            requirements: requirements
        });
    }

    handleOptions = () => {
        const element = document.getElementById('evalution_criteria_requiment')
        if (element && element.click() && [2, 3, 4,14,15].includes(this.state.answer_type)) {
            element.click()
        }
    }

    removeRequirementColloborator(userId) {
        var colloborators = this.state.colloborators;
        colloborators = colloborators.filter((u) => {
            if (u.ID === userId) {
                return false;
            }
            return true;
        })
        this.setState({ colloborators }, () => {
            this.props.showEvaluationCollaboratorDialog({
                ...this.props.collaboratorDialogData,
                existingUsers: colloborators,
                selectedUser: colloborators,
            })
        });
    }

    fetchSimilarQuestionList = (query, desc = "") => {
        this.props.fetchSimilarQuestion(query, desc)
    }

    replaceRequirement = (req, k) => {
        this.setState({ description: req.description, name: req.name })
    }

    render() {
        const classes = this.props.classes;
        return <>
            <Dialog
                onClose={this.props.hideDialog}
                aria-labelledby="app-requirementDialog"
                open={this.props.isOpen}
                TransitionComponent={Transition}
                disableBackdropClick={true}
                fullWidth={true}
                maxWidth={"md"}
                scroll="body"
                id="evMetaDialog"
            >
                <DialogContent classes={{ root: classes.dialogContent }} >
                    <Typography variant={"h2"} className={classes.modalTitle}>Add new Question</Typography>
                    <div className={classes.alerts}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                        {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                    </div>
                    <div className={classes.criteriaDetail}>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <div className={classes.nameYour}>
                                    <p className={classes.label} style={this.state.name_error ? { color: '#f44336' } : {}}>Name Your Question</p>
                                    <Autocomplete
                                        id="asynchronous-demo"
                                        open={this.state?.open}
                                        onOpen={() => {
                                            this.setState({ open: true })
                                        }}
                                        onClose={() => {
                                            this.setState({ open: false })
                                        }}
                                        value={{ label: this.state.name }}
                                        getOptionSelected={(option, value) => (option.name === value.name)}
                                        getOptionLabel={(option) => option.label}
                                        options={Array.isArray(this.props?.similarQuestion) ? this.props.similarQuestion.map((sqitem) => (
                                            { label: sqitem.name, value: sqitem }
                                        )) : []}
                                        renderOption={(option) => (
                                            <div>
                                                <p className={classes.optionlabel}>{option.label}</p>
                                                <p className={classes.optionDescription}>{option?.value?.description}</p>
                                            </div>
                                        )}
                                        loading={this.props.similarQuestionProg}
                                        onInputChange={(e) => {
                                            // if (this.state.open) {
                                                this.setState({ name: e?.target?.value })
                                                if (e?.target?.value && e?.target?.value.length > 0) this.fetchSimilarQuestionList(e?.target?.value, "")
                                            // }
                                        }}
                                        onChange={(event, value) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            this.handleOptions()
                                            this.setState({ 
                                                open: false 
                                            }, () => {
                                                this.setState({ name: value?.label, description: value?.value?.description })
                                                this.fetchSimilarQuestionList("")
                                            })
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                className={classes.autoSelectInput}
                                                error={this.state.name_error.length > 0}
                                                helperText={this.state.name_error}
                                                value={this.state.name ? { label: this.state.name } : ""}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {this.props.similarQuestionProg ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <div className={classes.yourQuestion}>
                                    <SlateInputField
                                        label="Explain Your Question"
                                        error={this.state.description_error.length > 0}
                                        helperText={this.state.description_error}
                                        placeholder=""
                                        as={SlateInputField}
                                        folderPath={`/evaluation/${this.props?.evaluationId}/criteria/${this.props?.criteriaId}/newrequirement`}
                                        onChangeEvent={(value) => {
                                            this.handleOptions()
                                            this.setState({ description: value });
                                        }}
                                        initValue={this.state.description}
                                        textContainerStyle={{ color: "#707070", }}
                                        customBtn={this.state.name?.length > 0}
                                        customBtnHTML={
                                            <SqureButton
                                                cls={classes.actionBtn}
                                                variant={"contained"}
                                                onClick={(e) => {
                                                    this.setState({ isOpen: true }, () => {
                                                        this.fetchSimilarQuestionList(this.state.name, this.state.description)
                                                    })
                                                }}
                                            >
                                                Check for similar questions
                                            </SqureButton>
                                        }
                                        isShowAddVariableOption={true}
                                        workflowId={0}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container justifyContent="space-between" spacing={3}>
                            <Grid item xs={6}>
                                <Grid container alignItems="center" style={{ height: '100%' }}>
                                    <EvaluateMultiChoiceAnswer
                                        place="AddTemplateDialog"
                                        editable={true}
                                        requirement={this.state}
                                        saveBtnId="evalution_criteria_requiment"
                                        isCriteriaRequirement
                                        isNew={true}
                                        onChange={(answer_type, options, is_other_option, character_limit) => {
                                            this.setState({
                                                answer_type,
                                                options,
                                                is_other_option,
                                                character_limit
                                            })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={4}>
                                <UserGroup
                                    position={'Requirement'}
                                    isNew={true}
                                    onAdd={(user) => {
                                        this.handleOptions()
                                        this.addRequirementColloborator(user)
                                    }}
                                    users={this.state.colloborators}
                                    removeCollaborator={this.removeRequirementColloborator}
                                />
                                <br />
                                {false && <div className={classnames(classes.priority, this.state.priority === '1' ? 'low' : this.state.priority === '2' ? 'medium' : this.state.priority === '3' ? 'high' : '')} >
                                    <OutlinedSelectInput
                                        label="Priority"
                                        labelStyle={"inline"}
                                        value={this.state.priority}
                                        options={[
                                            {
                                                value: "1",
                                                label: "Low"
                                            },
                                            {
                                                value: "2",
                                                label: "Medium"
                                            },
                                            {
                                                value: "3",
                                                label: "High"
                                            }

                                        ]}
                                        onChange={(event) => {
                                            this.handleOptions()
                                            this.setState({ priority: event.target.value });
                                        }}
                                    />
                                </div>}
                                <div className={classes.priorityC}>
                                    <span className={classes.label}>Weightage</span>
                                    <input
                                        value={this.state.priority}
                                        className={classes.criteriaPriority}
                                        onChange={(event) => {
                                            var priority = parseInt(event.target.value) || '';
                                            if (priority < 0 || priority > 100) {
                                                alert('Please enter weightage between 1 to 100.')
                                                return;
                                            } else {
                                                this.setState({ priority: priority });
                                            }
                                        }}
                                    ></input>&nbsp;%
                                </div>
                                <div className={classes.rightAction} style={{ marginTop: 10 }}>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <label className={classes.label} style={{ marginRight: 8 }}>
                                                Required
                                            </label>
                                            <br />
                                            <br />
                                            <label className={classes.label} style={{ marginRight: 8 }}>
                                                Editable
                                            </label>

                                        </Grid>
                                        <Grid item>
                                            <Switch
                                                checked={this.state.is_mandatory}
                                                onChange={e => {
                                                    e.stopPropagation()
                                                    this.handleOptions()
                                                    this.setState({
                                                        is_mandatory: e.target.checked
                                                    })
                                                }}
                                                name="is_mandatory"
                                                color="primary"
                                                inputProps={{ 'aria-label': 'is_mandatory checkbox' }}
                                            />
                                            <br />
                                            <Switch
                                                checked={this.state.is_editable}
                                                onChange={e => {
                                                    e.stopPropagation()
                                                    this.handleOptions()
                                                    this.setState({
                                                        is_editable: e.target.checked
                                                    })
                                                }}
                                                color="primary"
                                                name="is_editable"
                                                inputProps={{ 'aria-label': 'is_editable checkbox' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                    </div>
                    <div className={classes.actions}>
                        <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                        <SqureButton
                            cls={classes.actionBtn}
                            variant={"contained"}
                            onClick={() => {
                                this.handleOptions()
                                _.debounce(() => {
                                    this.submitResponse()
                                }, 1000)();
                            }}
                        >
                            {this.props.addRequirementProgress ? <CircularProgress style={{width:27,height:27}}/>: 'Save'}
                        </SqureButton>
                    </div>
                </DialogContent>
            </Dialog>
            <RequirementSimilarQuestionDialog
                isOpen={this.state?.isOpen}
                hideDialog={() => {
                    this.setState({ isOpen: false }, () => {
                        this.fetchSimilarQuestionList("")
                    })
                }}
                onSubmit={(req) => this.replaceRequirement(req)}
            />
        </>
    }
}

RequirementAddDialog.defaultProps = {
    isTemplate: false
}
export default connector(compose(
    withRouter,
    withStyles(styles)
)(RequirementAddDialog));