import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment";

export const START_FETCH_NEXT_COMPONENT = "START_FETCH_NEXT_COMPONENT";
export const END_FETCH_NEXT_COMPONENT = "END_FETCH_NEXT_COMPONENT";

export const START_FETCH_WORKFLOW_COMPONENT_FORM = "START_FETCH_WORKFLOW_COMPONENT_FORM";
export const END_FETCH_WORKFLOW_COMPONENT_FORM = "END_FETCH_WORKFLOW_COMPONENT_FORM";

export const START_FETCH_WORKFLOW_COMPONENT_USER_RESPONSE = "START_FETCH_WORKFLOW_COMPONENT_USER_RESPONSE";
export const END_FETCH_WORKFLOW_COMPONENT_USER_RESPONSE = "END_FETCH_WORKFLOW_COMPONENT_USER_RESPONSE";

export const START_ADD_WORKFLOW_COMPONENT_USER_RESPONSE = "START_ADD_WORKFLOW_COMPONENT_USER_RESPONSE";
export const END_ADD_WORKFLOW_COMPONENT_USER_RESPONSE = "END_ADD_WORKFLOW_COMPONENT_USER_RESPONSE";

export const START_FETCH_MASTER_COMPONENTS = "START_FETCH_MASTER_COMPONENTS";
export const END_FETCH_MASTER_COMPONENTS = "END_FETCH_MASTER_COMPONENTS";

export const START_FETCH_ORG_WORKFLOW_COMPONENTS = "START_FETCH_ORG_WORKFLOW_COMPONENTS";
export const END_FETCH_ORG_WORKFLOW_COMPONENTS = "END_FETCH_ORG_WORKFLOW_COMPONENTS";

export const START_FETCH_WORKFLOW_COMPONENT_DETAILS = "START_FETCH_WORKFLOW_COMPONENT_DETAILS";
export const END_FETCH_WORKFLOW_COMPONENT_DETAILS = "END_FETCH_WORKFLOW_COMPONENT_DETAILS";

export const START_FETCH_COMPONENT_DETAILS = "START_FETCH_COMPONENT_DETAILS";
export const END_FETCH_COMPONENT_DETAILS = "END_FETCH_COMPONENT_DETAILS";

export const START_ADD_CUSTOM_COMPONENT_RESPONSE = "START_ADD_CUSTOM_COMPONENT_RESPONSE";
export const END_ADD_CUSTOM_COMPONENT_RESPONSE = "END_ADD_CUSTOM_COMPONENT_RESPONSE";

export const SHOW_EVALUATION_GUIDE_FORM_DIALOG = "SHOW_EVALUATION_GUIDE_FORM_DIALOG";
export const HIDE_EVALUATION_GUIDE_FORM_DIALOG = "HIDE_EVALUATION_GUIDE_FORM_DIALOG";

export const START_FETCH_WORKFLOW_FORM_RESPONSE = "START_FETCH_WORKFLOW_FORM_RESPONSE";
export const END_FETCH_WORKFLOW_FORM_RESPONSE = "END_FETCH_WORKFLOW_FORM_RESPONSE";

export const SET_WORKFLOW_LOADER_STATUS = "SET_WORKFLOW_LOADER_STATUS";

export const START_FETCH_WORKFLOW_USER_TIMELINE = "START_FETCH_WORKFLOW_USER_TIMELINE";
export const END_FETCH_WORKFLOW_USER_TIMELINE = "END_FETCH_WORKFLOW_USER_TIMELINE";

export const START_FETCH_WORKFLOW_FORM_USERWISE_RESPONSE = "START_FETCH_WORKFLOW_FORM_USERWISE_RESPONSE";
export const END_FETCH_WORKFLOW_FORM_USERWISE_RESPONSE = "END_FETCH_WORKFLOW_FORM_USERWISE_RESPONSE";

export const SHOW_START_NEW_WORKFLOW_DIALOG = "SHOW_START_NEW_WORKFLOW_DIALOG";
export const HIDE_START_NEW_WORKFLOW_DIALOG = "HIDE_START_NEW_WORKFLOW_DIALOG";

export const START_NEW_WORKFLOW_FOR_PRODUCT = "START_NEW_WORKFLOW_FOR_PRODUCT";
export const END_NEW_WORKFLOW_FOR_PRODUCT = "END_NEW_WORKFLOW_FOR_PRODUCT";

export const START_FETCH_WORKFLOW_CONFIGURATIONS = "START_FETCH_WORKFLOW_CONFIGURATIONS";
export const END_FETCH_WORKFLOW_CONFIGURATIONS = "END_FETCH_WORKFLOW_CONFIGURATIONS";

export const SHOW_PARALLEL_COMPONENTS_DIALOG = "SHOW_PARALLEL_COMPONENTS_DIALOG";
export const HIDE_PARALLEL_COMPONENTS_DIALOG = "HIDE_PARALLEL_COMPONENTS_DIALOG";

export const START_FETCH_WORKFLOW_PROGRESS_NEW = "START_FETCH_WORKFLOW_PROGRESS_NEW";
export const END_FETCH_WORKFLOW_PROGRESS_NEW = "END_FETCH_WORKFLOW_PROGRESS_NEW";

export const START_FETCH_WORKFLOWS = "START_FETCH_WORKFLOWS";
export const END_FETCH_WORKFLOWS = "END_FETCH_WORKFLOWS";

export const START_FETCH_WORKFLOW_PROGRESS_STATUS = "START_FETCH_WORKFLOW_PROGRESS_STATUS";
export const END_FETCH_WORKFLOW_PROGRESS_STATUS = "END_FETCH_WORKFLOW_PROGRESS_STATUS";

export const START_WEB_HOOK_RUN = "START_WEB_HOOK_RUN";
export const END_WEB_HOOK_RUN = "END_WEB_HOOK_RUN";

export const START_GET_WORKFLOW_COMPONENT_DETAILS = "START_GET_WORKFLOW_COMPONENT_DETAILS";
export const END_GET_WORKFLOW_COMPONENT_DETAILS = "END_GET_WORKFLOW_COMPONENT_DETAILS";

export const SET_EVALUATION_SUMMARY_TYPE = "SET_EVALUATION_SUMMARY_TYPE";

export const SHOW_ADD_FORM_COLUMNS_DIALOG = "SHOW_ADD_FORM_COLUMNS_DIALOG";
export const HIDE_ADD_FORM_COLUMNS_DIALOG = "HIDE_ADD_FORM_COLUMNS_DIALOG";

export const START_EXPORT_WORKFLOW_DATA = "START_EXPORT_WORKFLOW_DATA";
export const END_EXPORT_WORKFLOW_DATA = "END_EXPORT_WORKFLOW_DATA";

export const START_ADD_WORKFLOW_SELLER_INSTRUCTION = "START_ADD_WORKFLOW_SELLER_INSTRUCTION";
export const END_ADD_WORKFLOW_SELLER_INSTRUCTION = "END_ADD_WORKFLOW_SELLER_INSTRUCTION";

export const START_FETCH_SELLER_INSTRUCTION = "START_FETCH_SELLER_INSTRUCTION";
export const END_FETCH_SELLER_INSTRUCTION = "END_FETCH_SELLER_INSTRUCTION";

export const START_FETCH_WORKFLOWS_PROJECT_TRACKING = "START_FETCH_WORKFLOWS_PROJECT_TRACKING";
export const END_FETCH_WORKFLOWS_PROJECT_TRACKING = "END_FETCH_WORKFLOWS_PROJECT_TRACKING";

export const START_FETCH_PROJECT_TRACKING = "START_FETCH_PROJECT_TRACKING";
export const END_FETCH_PROJECT_TRACKING = "END_FETCH_PROJECT_TRACKING";

export const START_ADD_PROJECT_TRACKING_RESPONSE = "START_ADD_PROJECT_TRACKING_RESPONSE";
export const END_ADD_PROJECT_TRACKING_RESPONSE = "END_ADD_PROJECT_TRACKING_RESPONSE";

export const SET_ACTIVE_PROJECTS_TAB = "SET_ACTIVE_PROJECTS_TAB";

export const START_FETCH_FINISHED_WORKFLOW_COMPONENTS = "START_FETCH_FINISHED_WORKFLOW_COMPONENTS";
export const END_FETCH_FINISHED_WORKFLOW_COMPONENTS = "END_FETCH_FINISHED_WORKFLOW_COMPONENTS";

export const START_FETCH_EVALUATION_WORKFLOW_MAPPING = "START_FETCH_EVALUATION_WORKFLOW_MAPPING";
export const END_FETCH_EVALUATION_WORKFLOW_MAPPING = "END_FETCH_EVALUATION_WORKFLOW_MAPPING";

export const START_POPULATE_EVALUATION_TEMPLATE_DATA = "START_POPULATE_EVALUATION_TEMPLATE_DATA";
export const END_POPULATE_EVALUATION_TEMPLATE_DATA = "END_POPULATE_EVALUATION_TEMPLATE_DATA";

export const START_FETCH_PROJECT_SCHEMA = "START_FETCH_PROJECT_SCHEMA";
export const END_FETCH_PROJECT_SCHEMA = "END_FETCH_PROJECT_SCHEMA";

export const START_FETCH_EMAIL_COMPONENT_ACTIVITY = "START_FETCH_EMAIL_COMPONENT_ACTIVITY"
export const END_FETCH_EMAIL_COMPONENT_ACTIVITY = "END_FETCH_EMAIL_COMPONENT_ACTIVITY"

export const START_ADD_EMAIL_COMPONENT_ACTIVITY = "START_ADD_EMAIL_COMPONENT_ACTIVITY"
export const END_ADD_EMAIL_COMPONENT_ACTIVITY = "END_ADD_EMAIL_COMPONENT_ACTIVITY"

export const START_FETCH_COMPONENT_COLLOBORATOR = "START_FETCH_COMPONENT_COLLOBORATOR"
export const END_FETCH_COMPONENT_COLLOBORATOR = "END_FETCH_COMPONENT_COLLOBORATOR"

export const START_ADD_COMPONENT_COLLOBORATOR = "START_ADD_COMPONENT_COLLOBORATOR"
export const END_ADD_COMPONENT_COLLOBORATOR = "END_ADD_COMPONENT_COLLOBORATOR"

export const START_DELETE_COMPONENT_COLLOBORATOR = "START_DELETE_COMPONENT_COLLOBORATOR"
export const END_DELETE_COMPONENT_COLLOBORATOR = "END_DELETE_COMPONENT_COLLOBORATOR"

export const START_FETCH_PROJECT_REQUEST_TYPES = "START_FETCH_PROJECT_REQUEST_TYPES"
export const END_FETCH_PROJECT_REQUEST_TYPES = "END_FETCH_PROJECT_REQUEST_TYPES"

export const START_GET_COMPONENT_PERMISSION = "START_GET_COMPONENT_PERMISSION"
export const END_GET_COMPONENT_PERMISSION = "END_GET_COMPONENT_PERMISSION"

export const SHOW_WORKFLOW_VIEW_DIALOG = "SHOW_WORKFLOW_VIEW_DIALOG";
export const HIDE_WORKFLOW_VIEW_DIALOG = "HIDE_WORKFLOW_VIEW_DIALOG";

export const START_FETCH_VISUALIZATION_WORKFLOWS="START_FETCH_VISUALIZATION_WORKFLOWS"
export const END_FETCH_VISUALIZATION_WORKFLOWS="END_FETCH_VISUALIZATION_WORKFLOWS"

export const START_FETCH_VISUALIZATION_WORKFLOWS_PROJECT_TRACKING="START_FETCH_VISUALIZATION_WORKFLOWS_PROJECT_TRACKING"
export const END_FETCH_VISUALIZATION_WORKFLOWS_PROJECT_TRACKING="END_FETCH_VISUALIZATION_WORKFLOWS_PROJECT_TRACKING"
export const START_VENDOR_SEND_ALL_QUESTIONS = "START_VENDOR_SEND_ALL_QUESTIONS";
export const END_VENDOR_SEND_ALL_QUESTIONS = "END_VENDOR_SEND_ALL_QUESTIONS";

export function showWorkflowViewsDialog(data) {
    return {
        type: SHOW_WORKFLOW_VIEW_DIALOG,
        payload: { data },
    };
}

export function hideWorkflowViewsDialog() {
    return {
        type: HIDE_WORKFLOW_VIEW_DIALOG,
        payload: {},
    };
}

function startExportWorkflowData() {
    return {
        type: START_EXPORT_WORKFLOW_DATA,
        payload: {}
    };
}

function endExportWorkflowData(success, error) {
    return {
        type: END_EXPORT_WORKFLOW_DATA,
        payload: {
            success, error
        }
    };
}

export function exportWorkflowData(data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/list/report?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startExportWorkflowData());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endExportWorkflowData(response, error));
        } catch (e) {
            dispatch(endExportWorkflowData(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchMasterComponents() {
    return {
        type: START_FETCH_MASTER_COMPONENTS,
        payload: {}
    };
}

function endFetchMasterComponents(success, error) {
    return {
        type: END_FETCH_MASTER_COMPONENTS,
        payload: {
            success, error
        }
    };
}

export function fetchMasterComponents() {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/workflow-components?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchMasterComponents());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchMasterComponents(response, error));
        } catch (e) {
            dispatch(endFetchMasterComponents(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchNextComponent() {
    return {
        type: START_FETCH_NEXT_COMPONENT,
        payload: {}
    };
}

function endFetchNextComponent(success, error) {
    return {
        type: END_FETCH_NEXT_COMPONENT,
        payload: {
            success, error
        }
    };
}

export function fetchNextComponent(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${id}/next_component?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchNextComponent());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url);
            dispatch(endFetchNextComponent(response, error));
        } catch (e) {
            dispatch(endFetchNextComponent(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOrgWorkflowComponents() {
    return {
        type: START_FETCH_ORG_WORKFLOW_COMPONENTS,
        payload: {}
    };
}

function endFetchOrgWorkflowComponents(success, error) {
    return {
        type: END_FETCH_ORG_WORKFLOW_COMPONENTS,
        payload: {
            success, error
        }
    };
}

export function fetchOrgWorkflowComponents(workflow_id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org-workflow/${workflow_id}/components?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOrgWorkflowComponents());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOrgWorkflowComponents(response, error));
        } catch (e) {
            dispatch(endFetchOrgWorkflowComponents(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchWorkflowComponentsForms() {
    return {
        type: START_FETCH_WORKFLOW_COMPONENT_FORM,
        payload: {}
    };
}

function endFetchWorkflowComponentsForms(success, error) {
    return {
        type: END_FETCH_WORKFLOW_COMPONENT_FORM,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowComponentsForms(id) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/workflow-components/${id}/workflow-components-forms?t=${timestamp}`;
    console.log(url)
    return async (dispatch) => {
        dispatch(startFetchWorkflowComponentsForms());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkflowComponentsForms(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowComponentsForms(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchWorkflowComponentDetails() {
    return {
        type: START_FETCH_WORKFLOW_COMPONENT_DETAILS,
        payload: {}
    };
}

function endFetchWorkflowComponentDetails(success, error) {
    return {
        type: END_FETCH_WORKFLOW_COMPONENT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowComponentDetails(workflowIdId, componentId, evaluationId = 0) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org-workflow/${workflowIdId}/component/${componentId}?evaluation_id=${evaluationId}&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflowComponentDetails());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkflowComponentDetails(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowComponentDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchComponentDetails() {
    return {
        type: START_FETCH_COMPONENT_DETAILS,
        payload: {}
    };
}

function endFetchComponentDetails(success, error) {
    return {
        type: END_FETCH_COMPONENT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function fetchComponentDetails(componentId) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/workflow-components/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchComponentDetails());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchComponentDetails(response, error));
        } catch (e) {
            dispatch(endFetchComponentDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchWorkflowComponentUserResponse() {
    return {
        type: START_FETCH_WORKFLOW_COMPONENT_USER_RESPONSE,
        payload: {}
    };
}

function endFetchWorkflowComponentUserResponse(success, error) {
    return {
        type: END_FETCH_WORKFLOW_COMPONENT_USER_RESPONSE,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowComponentUserResponse(evaId, componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evaId}/workflow-component/${componentId}/user-response?t=${timestamp}`;
    console.log(url)
    return async (dispatch) => {
        dispatch(startFetchWorkflowComponentUserResponse());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkflowComponentUserResponse(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowComponentUserResponse(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddWorkflowComponentUserResponse() {
    return {
        type: START_ADD_WORKFLOW_COMPONENT_USER_RESPONSE,
        payload: {}
    };
}

function endAddWorkflowComponentUserResponse(success, error, saveType) {
    return {
        type: END_ADD_WORKFLOW_COMPONENT_USER_RESPONSE,
        payload: {
            success, error, saveType
        }
    };
}

export function addWorkflowComponentUserResponse(evaId, componentId, param, saveType = 'NEXT') {
    // console.log(param, 'Parameterssssssssssssssss')
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evaId}/workflow-component/${componentId}/user-response?t=${timestamp}`;
    console.log(url)
    return async (dispatch) => {
        dispatch(startAddWorkflowComponentUserResponse());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, param);
            dispatch(endAddWorkflowComponentUserResponse(response, error, saveType));
        } catch (e) {
            dispatch(endAddWorkflowComponentUserResponse(null, {
                message: e.message
            }, saveType));
            return;
        }
    };
}

function startAddCustomFormResponse() {
    return {
        type: START_ADD_CUSTOM_COMPONENT_RESPONSE,
        payload: {}
    };
}

function endAddCustomFormResponse(success, error) {
    return {
        type: END_ADD_CUSTOM_COMPONENT_RESPONSE,
        payload: {
            success, error
        }
    };
}

export function addCustomFormResponse(evaId, form_id, param) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evaId}/workflow-form/${form_id}/capture-form-response?t=${timestamp}`;
    console.log(url)
    return async (dispatch) => {
        dispatch(startAddCustomFormResponse());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, param);
            dispatch(endAddCustomFormResponse(response, error));
        } catch (e) {
            dispatch(endAddCustomFormResponse(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function showWorkflowEvaluationGuideFormDialog(evaluationId) {
    return {
        type: SHOW_EVALUATION_GUIDE_FORM_DIALOG,
        payload: evaluationId
    };
}


export function hideWorkflowEvaluationGuideFormDialog() {
    return {
        type: HIDE_EVALUATION_GUIDE_FORM_DIALOG,
        payload: {}
    };
}

export function showAddFormColumnsDialog(selectedColumns) {
    return {
        type: SHOW_ADD_FORM_COLUMNS_DIALOG,
        payload: { selectedColumns }
    };
}


export function hideAddFormColumnsDialog() {
    return {
        type: HIDE_ADD_FORM_COLUMNS_DIALOG
    };
}


function startFetchWorkflowFormResponse() {
    return {
        type: START_FETCH_WORKFLOW_FORM_RESPONSE,
        payload: {}
    };
}

function endFetchWorkflowFormResponse(success, error) {
    return {
        type: END_FETCH_WORKFLOW_FORM_RESPONSE,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowFormResponse(evaluationId, formId, formType, componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${evaluationId}/workflow-form/${formId}/get-form-response/${formType}/for_component/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflowFormResponse());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkflowFormResponse(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowFormResponse(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchWorkflowFormUserWiseResponse() {
    return {
        type: START_FETCH_WORKFLOW_FORM_USERWISE_RESPONSE,
        payload: {}
    };
}

function endFetchWorkflowFormUserWiseResponse(success, error) {
    return {
        type: END_FETCH_WORKFLOW_FORM_USERWISE_RESPONSE,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowFormUserWiseResponse(evaluationId, userid) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/survey/${evaluationId}/user/${userid}/response?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflowFormUserWiseResponse());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkflowFormUserWiseResponse(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowFormUserWiseResponse(null, {
                message: e.message
            }));
            return;
        }
    };
}


export function showWorkflowLoader(status) {
    return {
        type: SET_WORKFLOW_LOADER_STATUS,
        payload: { status }
    };
}

function startUserWorkflowTimeline() {
    return {
        type: START_FETCH_WORKFLOW_USER_TIMELINE,
        payload: {}
    };
}

function endUserWorkflowTimeline(success, error) {
    return {
        type: END_FETCH_WORKFLOW_USER_TIMELINE,
        payload: {
            success, error
        }
    };
}

export function fetchUserWorkflowTimeline(evaluationId, componentIds = null, currentComId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    let url;
    if (componentIds !== null) {
        let ids = componentIds.join(',');
        url = `${api_server}/${evaluationId}/org-workflow/user-timeline?components=${ids}&currentComponentId=${currentComId}&t=${timestamp}`;
    } else {
        url = `${api_server}/${evaluationId}/org-workflow/user-timeline?currentComponentId=${currentComId}&t=${timestamp}`;
    }
    return async (dispatch) => {
        dispatch(startUserWorkflowTimeline());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endUserWorkflowTimeline(response, error));
        } catch (e) {
            dispatch(endUserWorkflowTimeline(null, {
                message: e.message
            }));
            return;
        }
    };
}


export function showStartNewWorkflowDialg(product) {
    return {
        type: SHOW_START_NEW_WORKFLOW_DIALOG,
        payload: {
            product
        }
    };
}


export function hideStartNewWorkflowDialg(success, error) {
    return {
        type: HIDE_START_NEW_WORKFLOW_DIALOG,
        payload: {}
    };
}


function startStartNewWorkflow() {
    return {
        type: START_NEW_WORKFLOW_FOR_PRODUCT,
        payload: {}
    };
}

function endStartNewWorkflow(success, error) {
    return {
        type: END_NEW_WORKFLOW_FOR_PRODUCT,
        payload: {
            success, error
        }
    };
}

export function startNewWorkflow(data = {}) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/workflow?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startStartNewWorkflow());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endStartNewWorkflow(response, error));
        } catch (e) {
            dispatch(endStartNewWorkflow(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchWorkflowConfigurations() {
    return {
        type: START_FETCH_WORKFLOW_CONFIGURATIONS,
        payload: {}
    };
}

function endFetchWorkflowConfigurations(success, error) {
    return {
        type: END_FETCH_WORKFLOW_CONFIGURATIONS,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowConfigurations(workflowId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org-workflow/${workflowId}/config?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflowConfigurations());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkflowConfigurations(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowConfigurations(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function showParallelComponentsDialog(items) {
    return {
        type: SHOW_PARALLEL_COMPONENTS_DIALOG,
        payload: { items }
    };
}


export function hideParallelComponentsDialog() {
    return {
        type: HIDE_PARALLEL_COMPONENTS_DIALOG,
        payload: {}
    };
}


function startWorkflowProgressNew() {
    return {
        type: START_FETCH_WORKFLOW_PROGRESS_NEW,
        payload: {}
    };
}

function endWorkflowProgressNew(success, error) {
    return {
        type: END_FETCH_WORKFLOW_PROGRESS_NEW,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowProgressNew(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${id}/org-workflow/workflow-state?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startWorkflowProgressNew());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endWorkflowProgressNew(response, error));
        } catch (e) {
            dispatch(endWorkflowProgressNew(null, {
                message: e.message
            }));
            return;
        }
    };
}



function startWorkflowProgressStatus() {
    return {
        type: START_FETCH_WORKFLOW_PROGRESS_STATUS,
        payload: {}
    };
}

function endWorkflowProgressStatus(success, error) {
    return {
        type: END_FETCH_WORKFLOW_PROGRESS_STATUS,
        payload: {
            success, error
        }
    };
}

function startFetchWorkflows(onInit = false) {
    let payload = {}
    payload.onInit = onInit
    return {
        type: START_FETCH_WORKFLOWS,
        payload: payload
    };
}

function endFetchWorkflows(success, error, activeTab) {
    return {
        type: END_FETCH_WORKFLOWS,
        payload: {
            success, error, activeTab
        }
    }
}

export function fetchOrgWorkflows(queryParam, activeTab = 1) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/list?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflows());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, queryParam, true);
            dispatch(endFetchWorkflows(response, error, activeTab));
        } catch (e) {
            dispatch(endFetchWorkflows(null, {
                message: e.message
            }, activeTab));
            return;
        }
    };
}


function startFetchVisualizationWorkflows(id) {
    let payload = {id}
    return {
        type: START_FETCH_VISUALIZATION_WORKFLOWS,
        payload: payload
    };
}

function endFetchVisualizationWorkflows(success, error,id) {
    return {
        type: END_FETCH_VISUALIZATION_WORKFLOWS,
        payload: {
            success, error,id
        }
    }
}

export function fetchVisualizationOrgWorkflows(queryParam,id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/list?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchVisualizationWorkflows(id));
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, queryParam, false);
            dispatch(endFetchVisualizationWorkflows(response, error,id));
        } catch (e) {
            dispatch(endFetchVisualizationWorkflows(null, {
                message: e.message
            },id));
            return;
        }
    };
}

export function fetchWorkflowProgressStatus(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${id}/org-workflow/progress?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startWorkflowProgressStatus());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endWorkflowProgressStatus(response, error));
        } catch (e) {
            dispatch(endWorkflowProgressStatus(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startWebHookTriggerRun() {
    return {
        type: START_WEB_HOOK_RUN,
        payload: {}
    };
}

function endWebHookTriggerRun(success, error, saveType) {
    return {
        type: END_WEB_HOOK_RUN,
        payload: {
            success, error, saveType
        }
    };
}

export function webHookTriggerRun(evaId, param) {
    // console.log(param, 'Parameterssssssssssssssss')
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evaId}/run/hook?t=${timestamp}`;
    console.log(url)
    return async (dispatch) => {
        dispatch(startWebHookTriggerRun());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, param);
            dispatch(endWebHookTriggerRun(response, error));
        } catch (e) {
            dispatch(endWebHookTriggerRun(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startgetComponentDetails() {
    return {
        type: START_GET_WORKFLOW_COMPONENT_DETAILS,
        payload: {}
    };
}

function endgetComponentDetails(success, error) {
    return {
        type: END_GET_WORKFLOW_COMPONENT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function getComponentDetails(workflowIdId, componentId, evaluationId = 0) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org-workflow/${workflowIdId}/component/${componentId}?evaluation_id=${evaluationId}&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startgetComponentDetails());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endgetComponentDetails(response, error));
        } catch (e) {
            dispatch(endgetComponentDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function setSummaryType(type) {
    return {
        type: SET_EVALUATION_SUMMARY_TYPE,
        payload: {
            type
        }
    };
}


function startAddSellerInstruction() {
    return {
        type: START_ADD_WORKFLOW_SELLER_INSTRUCTION,
        payload: {}
    };
}

function endAddSellerInstruction(success, error) {
    return {
        type: END_ADD_WORKFLOW_SELLER_INSTRUCTION,
        payload: {
            success, error
        }
    };
}

export function addSellerInstruction(evaId, componentId, param) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evaId}/component/${componentId}/instruction?t=${timestamp}`;
    console.log(url)
    return async (dispatch) => {
        dispatch(startAddSellerInstruction());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, param);
            dispatch(endAddSellerInstruction(response, error));
        } catch (e) {
            dispatch(endAddSellerInstruction(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchSellerInstruction() {
    return {
        type: START_FETCH_SELLER_INSTRUCTION,
        payload: {}
    };
}

function endFetchSellerInstruction(success, error) {
    return {
        type: END_FETCH_SELLER_INSTRUCTION,
        payload: {
            success, error
        }
    };
}

export function fetchSellerInstruction(evaId, componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evaId}/component/${componentId}/instruction?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchSellerInstruction());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchSellerInstruction(response, error));
        } catch (e) {
            dispatch(endFetchSellerInstruction(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchWorkflowProjectTracking(onInit = false) {
    let payload = {}
    payload.onInit = onInit
    return {
        type: START_FETCH_WORKFLOWS_PROJECT_TRACKING,
        payload: payload
    };
}

function endFetchWorkflowProjectTracking(success, error) {
    return {
        type: END_FETCH_WORKFLOWS_PROJECT_TRACKING,
        payload: {
            success, error
        }
    }
}

export function fetchWorkflowProjectTracking(queryParam) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/project_tracking/list?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflowProjectTracking());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, queryParam, true);
            dispatch(endFetchWorkflowProjectTracking(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowProjectTracking(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchVisualizationWorkflowProjectTracking() {
    let payload = {}
    return {
        type: START_FETCH_VISUALIZATION_WORKFLOWS_PROJECT_TRACKING,
        payload: payload
    };
}

function endFetchVisualizationWorkflowProjectTracking(success, error,id) {
    return {
        type: END_FETCH_VISUALIZATION_WORKFLOWS_PROJECT_TRACKING,
        payload: {
            success, error,id
        }
    }
}

export function fetchVisualizationWorkflowProjectTracking(queryParam,id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/project_tracking/list?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchVisualizationWorkflowProjectTracking());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, queryParam, true);
            dispatch(endFetchVisualizationWorkflowProjectTracking(response, error,id));
        } catch (e) {
            dispatch(endFetchVisualizationWorkflowProjectTracking(null, {
                message: e.message
            },id));
            return;
        }
    };
}


function startAddProjectTrackingResponse() {
    return {
        type: START_ADD_PROJECT_TRACKING_RESPONSE,
        payload: {}
    };
}

function endAddProjectTrackingResponse(success, error) {
    return {
        type: END_ADD_PROJECT_TRACKING_RESPONSE,
        payload: {
            success, error
        }
    };
}

export function addProjectTrackingResponse(evaId, formId, param) {
    // console.log(param, 'Parameterssssssssssssssss')
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${evaId}/project-tracking/${formId}?t=${timestamp}`;
    console.log(url)
    return async (dispatch) => {
        dispatch(startAddProjectTrackingResponse());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, param);
            dispatch(endAddProjectTrackingResponse(response, error));
        } catch (e) {
            dispatch(endAddProjectTrackingResponse(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchProjectTrackingDetails() {
    return {
        type: START_FETCH_PROJECT_TRACKING,
        payload: {}
    };
}

function endFetchProjectTrackingDetails(success, error) {
    return {
        type: END_FETCH_PROJECT_TRACKING,
        payload: {
            success, error
        }
    };
}

export function fetchProjectTrackingDetails(evaluationId, formId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${evaluationId}/project-tracking/${formId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProjectTrackingDetails());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchProjectTrackingDetails(response, error));
        } catch (e) {
            dispatch(endFetchProjectTrackingDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function setActiveProjectsTab(tab) {
    return {
        type: SET_ACTIVE_PROJECTS_TAB,
        payload: { tab }
    };
}


function startFetchFinishedComponentList() {
    return {
        type: START_FETCH_FINISHED_WORKFLOW_COMPONENTS,
        payload: {}
    };
}

function endFetchFinishedComponentList(success, error) {
    return {
        type: END_FETCH_FINISHED_WORKFLOW_COMPONENTS,
        payload: {
            success, error
        }
    };
}

export function fetchFinishedComponentList(evID) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evID}/finished_component_list?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchFinishedComponentList());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchFinishedComponentList(response, error));
        } catch (e) {
            dispatch(endFetchFinishedComponentList(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchWorkflowEvaluationMapping() {
    return {
        type: START_FETCH_EVALUATION_WORKFLOW_MAPPING,
        payload: {}
    };
}

function endFetchWorkflowEvaluationMapping(success, error) {
    return {
        type: END_FETCH_EVALUATION_WORKFLOW_MAPPING,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowEvaluationMapping(evID) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evID}/workflow_evaluation_mapping?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflowEvaluationMapping());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkflowEvaluationMapping(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowEvaluationMapping(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startpopulateEvaluationTemplateData() {
    let payload = {}
    return {
        type: START_POPULATE_EVALUATION_TEMPLATE_DATA,
        payload: payload
    };
}

function endpopulateEvaluationTemplateData(success, error) {
    return {
        type: END_POPULATE_EVALUATION_TEMPLATE_DATA,
        payload: {
            success, error
        }
    }
}

export function populateEvaluationTemplateData(evaluationId, queryParam) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evaluationId}/populate_template_data?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startpopulateEvaluationTemplateData());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, queryParam, true);
            dispatch(endpopulateEvaluationTemplateData(response, error));
        } catch (e) {
            dispatch(endpopulateEvaluationTemplateData(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startfetchProjectSchema() {
    return {
        type: START_FETCH_PROJECT_SCHEMA,
        payload: {}
    };
}

function endfetchProjectSchema(success, error) {
    return {
        type: END_FETCH_PROJECT_SCHEMA,
        payload: {
            success, error
        }
    };
}

export function fetchProjectSchema(workflowId = 0) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/template_schema?workflow_id=${workflowId}&t=${timestamp}`;
    console.log(url)
    return async (dispatch) => {
        dispatch(startfetchProjectSchema());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endfetchProjectSchema(response, error));
        } catch (e) {
            dispatch(endfetchProjectSchema(null, {
                message: e.message
            }));
            return;
        }
    };
}

// =================================================================//
function startFetchEmailComponentActivity() {
    return {
        type: START_FETCH_EMAIL_COMPONENT_ACTIVITY,
        payload: {}
    };
}

function endFetchEmailComponentActivity(success, error) {
    return {
        type: END_FETCH_EMAIL_COMPONENT_ACTIVITY,
        payload: {
            success, error
        }
    };
}

export function fetchEmailComponentActivity(projectId, componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${projectId}/component/${componentId}/activity?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchEmailComponentActivity());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchEmailComponentActivity(response, error));
        } catch (e) {
            dispatch(endFetchEmailComponentActivity(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddEmailComponentActivity() {
    let payload = {}
    return {
        type: START_ADD_EMAIL_COMPONENT_ACTIVITY,
        payload: payload
    };
}

function endAddEmailComponentActivity(success, error) {
    return {
        type: END_ADD_EMAIL_COMPONENT_ACTIVITY,
        payload: {
            success, error
        }
    }
}

export function addEmailComponentActivity(projectId, componentId, queryParam) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${projectId}/component/${componentId}/activity?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddEmailComponentActivity());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, queryParam, true);
            dispatch(endAddEmailComponentActivity(response, error));
        } catch (e) {
            dispatch(endAddEmailComponentActivity(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchComponentCollaborator() {
    return {
        type: START_FETCH_COMPONENT_COLLOBORATOR,
        payload: {}
    };
}

function endFetchComponentCollaborator(success, error) {
    return {
        type: END_FETCH_COMPONENT_COLLOBORATOR,
        payload: {
            success, error
        }
    };
}

export function fetchComponentCollaborators(projectId, componentId) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${projectId}/component/${componentId}/collaborator?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchComponentCollaborator());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchComponentCollaborator(response, error));
        } catch (e) {
            dispatch(endFetchComponentCollaborator(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddComponentColloborator() {
    let payload = {}
    return {
        type: START_ADD_COMPONENT_COLLOBORATOR,
        payload: payload
    };
}

function endAddComponentColloborator(success, error) {
    return {
        type: END_ADD_COMPONENT_COLLOBORATOR,
        payload: {
            success, error
        }
    }
}

export function addComponentColloborator(projectId, componentId, queryParam) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${projectId}/component/${componentId}/collaborator?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddComponentColloborator());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, queryParam, true);
            dispatch(endAddComponentColloborator(response, error));
        } catch (e) {
            dispatch(endAddComponentColloborator(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteComponentColloborator() {
    let payload = {}
    return {
        type: START_DELETE_COMPONENT_COLLOBORATOR,
        payload: payload
    };
}

function endDeleteComponentColloborator(success, error) {
    return {
        type: END_DELETE_COMPONENT_COLLOBORATOR,
        payload: {
            success, error
        }
    }
}

export function deleteComponentColloborator(projectId, componentId, params) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${projectId}/component/${componentId}/collaborator?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteComponentColloborator());
        try {
            let [response, error] = await await HttpFactory.instance().deleteMethod(url, true, params);
            dispatch(endDeleteComponentColloborator(response, error));
        } catch (e) {
            dispatch(endDeleteComponentColloborator(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchProjectRequestTypes() {
    return {
        type: START_FETCH_PROJECT_REQUEST_TYPES,
        payload: {}
    };
}

function endFetchProjectRequestTypes(success, error) {
    return {
        type: END_FETCH_PROJECT_REQUEST_TYPES,
        payload: {
            success, error
        }
    };
}

export function fetchProjectRequestTypes() {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/request_types?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProjectRequestTypes());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchProjectRequestTypes(response, error));
        } catch (e) {
            dispatch(endFetchProjectRequestTypes(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startGetComponentPermission() {
    return {
        type: START_GET_COMPONENT_PERMISSION,
        payload: {}
    };
}

function endGetComponentPermission(success, error) {
    return {
        type: END_GET_COMPONENT_PERMISSION,
        payload: {
            success, error
        }
    };
}

export function getComponentPermission(evaId, componentId, userid) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evaId}/component/${componentId}/user/${userid}/permission?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetComponentPermission());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endGetComponentPermission(response, error));
        } catch (e) {
            dispatch(endFetchProjectRequestTypes(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startVendorSendAllQuestions() {
    return {
        type: START_VENDOR_SEND_ALL_QUESTIONS,
        payload: {}
    };
}

function endVendorSendAllQuestions(success, error) {
    return {
        type: END_VENDOR_SEND_ALL_QUESTIONS,
        payload: {
            success, error
        }
    };
}

export function vendorSendAllQuestions(evaId, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evaId}/send_all_question?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startVendorSendAllQuestions());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endVendorSendAllQuestions(response, error));
        } catch (e) {
            dispatch(endVendorSendAllQuestions(null, {
                message: e.message
            }));
            return;
        }
    };
}