import {
    START_ORG_EVALUATION_TEMPLATE_FETCH, END_ORG_EVALUATION_TEMPLATE_FETCH,
    START_ORG_EVALUATION_TEMPLATE_GET, END_ORG_EVALUATION_TEMPLATE_GET,
    SHOW_SAVE_ORG_EVALUATION_TEMPLATE_TO_DIALOG, HIDE_SAVE_ORG_EVALUATION_TEMPLATE_TO_DIALOG,
    START_SAVE_FROM_ORG_EVALUATION_TEMPLATE, END_SAVE_FROM_ORG_EVALUATION_TEMPLATE, START_DELETE_FROM_ORG_EVALUATION_TEMPLATE, END_DELETE_FROM_ORG_EVALUATION_TEMPLATE, START_DELETE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE, END_DELETE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE,
    START_DELETE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE, END_DELETE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE,
    START_ADD_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE, END_ADD_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE, START_UPDATE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE, END_UPDATE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE,
    START_ADD_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE, END_ADD_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE,
    START_UPDATE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE, END_UPDATE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE, START_UPDATE_FROM_ORG_REQUIREMENT_TEMPLATE_OPTIONS, END_UPDATE_FROM_ORG_REQUIREMENT_TEMPLATE_OPTIONS,
    START_TEMPLATE_CRITERIA_COLLOBORATOR_ADD, END_TEMPLATE_CRITERIA_COLLOBORATOR_ADD, START_TEMPLATE_REQUIREMENT_COLLOBORATOR_ADD, END_TEMPLATE_REQUIREMENT_COLLOBORATOR_ADD, START_UPDATE_TEMPLATE_CRITERIA_USER_ROLE, END_UPDATE_TEMPLATE_CRITERIA_USER_ROLE, START_REMOVE_TEMPLATE_CRITERIA_USER_ROLE, END_REMOVE_TEMPLATE_CRITERIA_USER_ROLE,
    START_REMOVE_TEMPLATE_REQUIREMENT_COLLABORATOR, END_REMOVE_TEMPLATE_REQUIREMENT_COLLABORATOR,
    START_TEMPLATE_RULE_SAVE, END_TEMPLATE_RULE_SAVE, START_FETCH_SELECTED_TEMPLATE_CRITERIA, END_FETCH_SELECTED_TEMPLATE_CRITERIA,
    SHOW_IMPORT_TEMPLATE_DIALOG, HIDE_IMPORT_TEMPLATE_DIALOG,
    START_UPLOAD_TEMPLATE_FILE, END_UPLOAD_TEMPLATE_FILE,
    START_REQUIREMENT_TEMPLATE_RULE_SAVE, END_REQUIREMENT_TEMPLATE_RULE_SAVE
} from "./action";

const initState = {
    templates: [],
    templatesWithCriterias: [],
    fetchProg: false,
    fetchTemplateProg: false,
    template: null,
    evaluationTemplateSaveDialog: false,
    saveFromOrgEvaluationTemplateProg: false,
    saveFromOrgEvaluationTemplateError: null,
    deteleFromOrgEvaluationTemplateProg: false,
    deteleFromOrgEvaluationTemplateError: null,
    deteleFromOrgEvaluationCriteriaTemplateProg: true,
    deteleFromOrgEvaluationCriteriaTemplateError: null,
    addFromOrgEvaluationCriteriaTemplateProg: true,
    addFromOrgEvaluationCriteriaTemplateError: null,
    updateFromOrgEvaluationCriteriaTemplateProg: true,
    updateFromOrgEvaluationCriteriaTemplateError: null,
    deteleFromOrgEvaluationRequirementTemplateProg: true,
    deteleFromOrgEvaluationRequirementTemplateError: null,
    addFromOrgEvaluationRequirementTemplateProg: true,
    addFromOrgEvaluationRequirementTemplateError: null,
    updateFromOrgEvaluationRequirementTemplateProg: true,
    updateFromOrgEvaluationRequirementTemplateError: null,
    orgRequirementTemplateOptionInprogress: false,
    orgRequirementTemplateOptionSuccess: null,
    orgRequirementTemplateQuestionOptionError: null,
    addApproverInprogress: false,
    criteriaUserRoleInprogress: false,
    updateCriteriaUserRoleInprogress: false,
    removeApproverInprogress: false,
    ruleSaveProgress: false,
    ruleSaveSuccess: null,
    errorRuleSave: null,
    selectedTemplate: null,
    selectedTemplateProg: false,
    importTemplateDialogOpen: false,

    isUploadTemplateProgress: false,
    uploadedTemplateData: null,
    uploadTemplateError: null,
    setComponentFlowPostionProg: false,

    reqRuleSaveProgress: false,
    reqRuleSaveSuccess: null,
    errorReqRuleSave: null,
}

export function orgTemplateEvaluationReducer(state = initState, action) {
    switch (action.type) {
        case START_FETCH_SELECTED_TEMPLATE_CRITERIA:
            return {
                ...state,
                selectedTemplate: null,
                selectedTemplateProg: true,
            };
        case END_FETCH_SELECTED_TEMPLATE_CRITERIA:
            return {
                ...state,
                selectedTemplate: action.payload.success,
                selectedTemplateProg: false,
            };

        case START_ORG_EVALUATION_TEMPLATE_FETCH:
            var templates = state.templates;
            if (action.payload === 0) {
                templates = []
            }
            return {
                ...state,
                fetchProg: true,
                templates: templates,
                templatesWithCriterias: templates
            };
        case END_ORG_EVALUATION_TEMPLATE_FETCH:
            var success = action.payload.success;
            var templatesDatas = state.templates;
            if (success !== null) {
                var Page = success.page;
                var Data = success.Data;
                if (Page === 0) {
                    templatesDatas = Data;
                } else {
                    templatesDatas = templatesDatas.concat(Data)
                }
            }
            return {
                ...state,
                fetchProg: false,
                templates: templatesDatas,
                templatesWithCriterias: templatesDatas
            }
        case START_ORG_EVALUATION_TEMPLATE_GET:
            return {
                ...state,
                fetchTemplateProg: true,
                template: null
            }
        case END_ORG_EVALUATION_TEMPLATE_GET:
            var template = action.payload.success
            return {
                ...state,
                fetchTemplateProg: false,
                template,
            }
        case SHOW_SAVE_ORG_EVALUATION_TEMPLATE_TO_DIALOG:
            return {
                ...state,
                evaluationTemplateSaveDialog: true,
            }
        case HIDE_SAVE_ORG_EVALUATION_TEMPLATE_TO_DIALOG:
            return {
                ...state,
                evaluationTemplateSaveDialog: false,
            }
        case START_SAVE_FROM_ORG_EVALUATION_TEMPLATE:
            return {
                ...state,
                saveFromOrgEvaluationTemplateProg: true,
                saveFromOrgEvaluationTemplateError: null,
            }
        case END_SAVE_FROM_ORG_EVALUATION_TEMPLATE:
            return {
                ...state,
                saveFromOrgEvaluationTemplateProg: false,
                saveFromOrgEvaluationTemplateError: action.payload.error,
            }
        case START_DELETE_FROM_ORG_EVALUATION_TEMPLATE:
            return {
                ...state,
                deteleFromOrgEvaluationTemplateProg: true,
                deteleFromOrgEvaluationTemplateError: null,
            }
        case END_DELETE_FROM_ORG_EVALUATION_TEMPLATE:
            return {
                ...state,
                deteleFromOrgEvaluationTemplateProg: false,
                deteleFromOrgEvaluationTemplateError: action.payload.error,
            }

        case START_DELETE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE:
            return {
                ...state,
                deteleFromOrgEvaluationCriteriaTemplateProg: true,
                deteleFromOrgEvaluationCriteriaTemplateError: null,
            }
        case END_DELETE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE:
            return {
                ...state,
                deteleFromOrgEvaluationCriteriaTemplateProg: false,
                deteleFromOrgEvaluationCriteriaTemplateError: action.payload.error,
            }
        case START_ADD_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE:
            return {
                ...state,
                addFromOrgEvaluationCriteriaTemplateProg: true,
                addFromOrgEvaluationCriteriaTemplateError: null,
            }
        case END_ADD_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE:
            return {
                ...state,
                addFromOrgEvaluationCriteriaTemplateProg: false,
                addFromOrgEvaluationCriteriaTemplateError: action.payload.error,
            }
        case START_UPDATE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE:
            return {
                ...state,
                updateFromOrgEvaluationCriteriaTemplateProg: true,
                updateFromOrgEvaluationCriteriaTemplateError: null,
            }
        case END_UPDATE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE: {
            const template = state.template
            if (template !== null && action.payload?.success !== null) {
                template.Criterias = template.Criterias.map(c => {
                    let data = c
                    if (data.ID === action.payload.success.ID) {
                        data = action.payload.success
                    }
                    return data
                })
            }
            return {
                ...state,
                template,
                updateFromOrgEvaluationCriteriaTemplateProg: false,
                updateFromOrgEvaluationCriteriaTemplateError: action.payload.error,
            }
        }
        case START_DELETE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE:
            return {
                ...state,
                deteleFromOrgEvaluationRequirementTemplateProg: true,
                deteleFromOrgEvaluationRequirementTemplateError: null,
            }
        case END_DELETE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE:
            return {
                ...state,
                deteleFromOrgEvaluationRequirementTemplateProg: false,
                deteleFromOrgEvaluationRequirementTemplateError: action.payload.error,
            }
        case START_ADD_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE:
            return {
                ...state,
                addFromOrgEvaluationRequirementTemplateProg: true,
                addFromOrgEvaluationRequirementTemplateError: null,
            }
        case END_ADD_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE:
            return {
                ...state,
                addFromOrgEvaluationRequirementTemplateProg: false,
                addFromOrgEvaluationRequirementTemplateError: action.payload.error,
            }
        case START_UPDATE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE:
            return {
                ...state,
                updateFromOrgEvaluationRequirementTemplateProg: true,
                updateFromOrgEvaluationRequirementTemplateError: null,
            }
        case END_UPDATE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE: {
            const { success } = action.payload;
            const template = state.template
            if (success !== null) {
                var criterias = template?.Criterias
                const criteriaIndex = criterias.findIndex(o => o.ID === success.CriteriaID)
                if (criteriaIndex) {
                    let criteria = criterias[criteriaIndex]
                    let requirements = criteria?.Requirements
                    console.log(success)
                    const requirementIndex = requirements.findIndex(o => o.ID === success.ID)
                    if (requirementIndex) {
                        requirements[requirementIndex] = success
                    }
                    console.log(requirements)
                    criteria.Requirements = requirements
                    criterias[criteriaIndex] = criteria
                }
                template.Criterias = criterias
            }
            return {
                ...state,
                template,
                updateFromOrgEvaluationRequirementId: success?.ID || null,
                updateFromOrgEvaluationRequirementTemplateProg: false,
                updateFromOrgEvaluationRequirementTemplateError: action.payload.error,
            }
        }
        case START_UPDATE_FROM_ORG_REQUIREMENT_TEMPLATE_OPTIONS:
            return {
                ...state,
                orgRequirementTemplateOptionInprogress: true,
                orgRequirementTemplateOptionSuccess: null,
                orgRequirementTemplateQuestionOptionError: null,
            }

        case END_UPDATE_FROM_ORG_REQUIREMENT_TEMPLATE_OPTIONS: {
            let success = action.payload.success;
            let error = action.payload.error;

            let result = {}

            result.orgRequirementTemplateOptionInprogress = false;

            if (error) {
                result.orgRequirementTemplateOptionSuccess = null;
                result.orgRequirementTemplateQuestionOptionError = error.message;
            } else {
                result.orgRequirementTemplateOptionSuccess = success;
                result.orgRequirementTemplateQuestionOptionError = null;
            }

            return {
                ...state,
                ...result,
            }
        }

        case START_TEMPLATE_CRITERIA_COLLOBORATOR_ADD:
            return {
                ...state,
                addApproverInprogress: true,
                criteriaUserRoleInprogress: true,
            }

        case END_TEMPLATE_CRITERIA_COLLOBORATOR_ADD:
            var user = action.payload.success;
            var criteriaId = action.payload.criteriaId;
            var isApprover = action.payload.isApprover;
            var criterias = [...state.template.Criterias]
            if (user !== null && criteriaId !== null) {
                var temp = [];
                criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    if (_c.ID == criteriaId) {
                        if (!_c.Collaborators) {
                            _c.Collaborators = []
                        }
                        if (isApprover) {
                            user.IsApprover = true;
                        } else {
                            user.IsApprover = false;
                        }
                        _c.Collaborators.push(user);
                    }
                    temp.push(_c)
                })
                criterias = temp;
            }
            return {
                ...state,
                template: { ...state.template, Criterias: criterias },
                addApproverInprogress: false,
                criteriaUserRoleInprogress: false,
            }
        case START_TEMPLATE_REQUIREMENT_COLLOBORATOR_ADD:
            return {
                ...state,
                criteriaUserRoleInprogress: true,
            }

        case END_TEMPLATE_REQUIREMENT_COLLOBORATOR_ADD:
            var user = action.payload.success;
            var requirementId = action.payload.requirementId;
            var criterias = [...state.template.Criterias]
            if (user !== null && requirementId !== null) {
                var temp = [];
                criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    _c.Requirements = [];
                    criteria.Requirements.forEach((requirement) => {
                        if (requirement.ID == requirementId) {
                            if (!requirement.Collaborators) {
                                requirement.Collaborators = []
                            }
                            requirement.Collaborators.push(user);
                        }
                        _c.Requirements.push({ ...requirement })
                    })
                    temp.push(_c)
                })
                criterias = temp;
            }
            return {
                ...state,
                template: { ...state.template, Criterias: criterias },
                criteriaUserRoleInprogress: false,
            };
        case START_UPDATE_TEMPLATE_CRITERIA_USER_ROLE:
            return {
                ...state,
                updateCriteriaUserRoleInprogress: true,
                criteriaUserRoleInprogress: true,
            }

        case END_UPDATE_TEMPLATE_CRITERIA_USER_ROLE: {
            var _user = action.payload.success;
            var criteriaId = action.payload.criteriaId;
            var isApprover = action.payload.isApprover;
            var priority = action.payload.priority;
            var criterias = [...state.template.Criterias]
            if (_user !== null && criteriaId !== null) {
                var temp = [];
                criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    if (_c.ID === criteriaId) {
                        if (!_c.Collaborators) {
                            _c.Collaborators = []
                        }
                        _c.Collaborators.map(user => {
                            if (user.ID === _user.ID) {
                                if (isApprover !== null) {
                                    user.IsApprover = isApprover;
                                }
                                if (priority !== null) {
                                    user.TemplateCriteriaPriority = priority;
                                }
                            }
                        })
                    }
                    temp.push(_c)
                })
                criterias = temp;
            }
            return {
                ...state,
                template: { ...state.template, Criterias: criterias },
                updateCriteriaUserRoleInprogress: false,
                criteriaUserRoleInprogress: false,
            }
        }

        case START_REMOVE_TEMPLATE_CRITERIA_USER_ROLE:
            return {
                ...state,
                removeApproverInprogress: true,
                criteriaUserRoleInprogress: true,
            }
        case END_REMOVE_TEMPLATE_CRITERIA_USER_ROLE:
            var criteria_Id = action.payload.criteriaId;
            var user_Id = action.payload.userId;
            var success_ = action.payload.success;
            var criterias_ = [...state.template.Criterias]
            if (user_Id !== null && criteria_Id !== null && success_ && success_.message === 'Success') {
                var temp = [];
                criterias_.forEach((criteria) => {
                    var _c = { ...criteria }
                    if (_c.ID == criteria_Id) {
                        if (!_c.Collaborators) {
                            _c.Collaborators = []
                        }
                        _c.Collaborators = _c.Collaborators.filter(user => user.ID !== user_Id)
                    }
                    temp.push(_c)
                })
                criterias = temp;
            }
            return {
                ...state,
                template: { ...state.template, Criterias: criterias },
                removeApproverInprogress: false,
                criteriaUserRoleInprogress: false,
            }
        case START_REMOVE_TEMPLATE_REQUIREMENT_COLLABORATOR:
            return {
                ...state,
                criteriaUserRoleInprogress: true,
            }
        case END_REMOVE_TEMPLATE_REQUIREMENT_COLLABORATOR:
            var user = action.payload.success;
            var requirementId = action.payload.requirementId;
            var colloboratorId = action.payload.colloboratorId;
            var criterias = [...state.template.Criterias]
            if (user !== null && requirementId !== null) {
                var temp = [];
                criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    _c.Requirements = [];
                    criteria.Requirements.forEach((requirement) => {
                        if (requirement.ID === requirementId) {
                            if (!requirement.Collaborators) {
                                requirement.Collaborators = []
                            } else {
                                requirement.Collaborators.map((item, index) => {
                                    if (item.ID === parseInt(colloboratorId)) {
                                        requirement.Collaborators.splice(index, 1)
                                    }
                                })
                            }
                        }
                        _c.Requirements.push({ ...requirement })
                    })
                    temp.push(_c)
                })
                criterias = temp;
            }
            return {
                ...state,
                template: { ...state.template, Criterias: criterias },
                criteriaUserRoleInprogress: false,
            };
        case START_TEMPLATE_RULE_SAVE:
            return {
                ...state,
                ruleSaveProgress: true,
                ruleSaveSuccess: null,
                errorRuleSave: null,

            }
        case END_TEMPLATE_RULE_SAVE:
            let error = action.payload?.error;
            temp = {};
            if (error) {
                temp.ruleSaveSuccess = null;
                temp.errorRuleSave = action.payload?.error?.message;
            } else {
                temp.ruleSaveSuccess = true;
                temp.errorRuleSave = null;
            }
            return {
                ...state,
                ruleSaveProgress: false,
                ...temp
            }
        case SHOW_IMPORT_TEMPLATE_DIALOG:
            return {
                ...state,
                importTemplateDialogOpen: true
            };

        case HIDE_IMPORT_TEMPLATE_DIALOG:
            return {
                ...state,
                importTemplateDialogOpen: false
            };

        case START_UPLOAD_TEMPLATE_FILE:
            return {
                ...state,
                isUploadTemplateProgress: true,
                uploadedTemplateData: null,
                uploadTemplateError: null
            };
        case END_UPLOAD_TEMPLATE_FILE:
            var file_success = action.payload.success;
            return {
                ...state,
                isUploadTemplateProgress: false,
                uploadedTemplateData: file_success,
                uploadTemplateError: action.payload.error
            };


        case START_REQUIREMENT_TEMPLATE_RULE_SAVE:
            return {
                ...state,
                reqRuleSaveProgress: true,
                reqRuleSaveSuccess: null,
                errorReqRuleSave: null,
            }
        case END_REQUIREMENT_TEMPLATE_RULE_SAVE:
            let error_R = action.payload?.error;
            temp = {};
            if (error_R) {
                temp.reqRuleSaveSuccess = null;
                temp.errorReqRuleSave = action.payload?.error?.message;
            } else {
                temp.reqRuleSaveSuccess = true;
                temp.errorReqRuleSave = null;
            }
            return {
                ...state,
                reqRuleSaveProgress: false,
                ...temp
            }

        default:
            return state;
    }
}
