import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, IconButton, Dialog, DialogContent, Button, Slide, Typography, Grid, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import {showAddTeamDialog,hideAddTeamDialog,addTeamMember, addTeamMembers} from "../../redux/okta/action";
import OutlinedInput from "./Input/OutlinedInput";
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import * as Validator from "../../util/Validation";
import {fetchUsers, updateUser} from "../../redux/usersSettings/action";
import CreatableSelect from 'react-select/creatable'

//redux 
import {fetchOrgGroup, addOrgGroup } from "redux/org/group/action";
import {showSnackBar} from "redux/snackbar/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    addTeamDialogOpen:state.okta.addTeamDialogOpen,
    addTeamMemberProgress:state.okta.addTeamMemberProgress,
    addTeamMemberError:state.okta.addTeamMemberError,
    editUserError: state.usersSettings.editUserError,
    isEditUserProgress: state.usersSettings.isEditUserProgress,
    groups:state.orgGroup.groups,
    fetchGroupProg:state.orgGroup.fetchGroupProg,
});

const connectionActions = {
    showAddTeamDialog:showAddTeamDialog,
    hideAddTeamDialog:hideAddTeamDialog,
    addTeamMember:addTeamMember,
    addTeamMembers: addTeamMembers,
    fetchUsers: fetchUsers,
    updateUser: updateUser,
    fetchOrgGroup: fetchOrgGroup,
    showSnackBar: showSnackBar,
    addOrgGroup:addOrgGroup,

}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent:{
        padding: theme.spacing(6, 12) +" !important"
    },
    formControl: {
        marginBottom: theme.spacing(2),
        "& [class*='MuiSelect-outlined-']": {
            color: '#5F5F5F !important',
        },
        '& .select__value-container': {
            padding: '12px',
            '& .select__single-value, .select__placeholder':{
                color: '#5F5F5F',
                fontSize:'17px'
            }
        }
    },
    inputLabel: {
        marginBottom: theme.spacing(1)
    },
    "formAction": {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: "center",
    },
    title:{
        fontSize:theme.spacing(2.5),
        marginBottom:theme.spacing(2)
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    loader: {
        textAlign: 'center'
    },
    editEmail: {
        "& [class*='MuiOutlinedInput-root'] *": {
            border: 'none'
        },
        "& > div": {
            marginBottom: 0
        },
        "& [class*='MuiOutlinedInput-input']": {
            padding: 0
        },
        "& [class*='MuiOutlinedInput-inputRoot']": {
            minHeight: 30
        },
    },
    closeIcon: {
        position: "absolute",
        zIndex: 11,
        right: 15,
        top: 15,
        padding: 5
    },
});

class AddUserDialog extends React.Component {

    constructor(props){
        super(props);
        this.state =    {
            "name":"",
            "name_error":"",
            'email':"",
            "email_error":"",
            "role":"OrgUser",
            "role_error":"",
            "team":[],
            "title":"",
            "error":"",
            "isActive":"",
            teamQuery: ''
        }
        this.clearError = this.clearError.bind(this);
        this.addUser = this.addUser.bind(this);
        this.fetchOrgGroups = this.fetchOrgGroups.bind(this);
        this.handleOnChangeTeamSelect = this.handleOnChangeTeamSelect.bind(this)
    }
    componentWillMount() {
        this.fetchOrgGroups()
        this.setUserValues()
    }

    setUserValues =()=> {
        this.setState({
            "name": this.props.editValue.name|| "",
            "name_error": "",
            'email': this.props.editValue.email ||  "",
            "email_error":"",
            "role": this.props.editValue.role || "OrgUser",
            "isActive" : String(this.props.editValue.isActive),
            "role_error":"",
            "team": !this.props.editValue.team?[]: this.props.editValue.team.map((g)=>{
                return {
                    value:g.ID,
                    label:g.Name
                }
            }) ,
            "title": this.props.editValue.title|| "",
            "error":"",
            "id": this.props.editValue.id  || ""
        });   
    }


    componentDidUpdate(prevProps){
        if(this.props.addTeamDialogOpen === true && prevProps.addTeamDialogOpen === false){
            this.setState({
                "name": this.props.editValue.name|| "",
                "name_error": "",
                'email': this.props.editValue.email ||  "",
                "email_error":"",
                "role": this.props.editValue.role || "OrgUser",
                "isActive": String(this.props.editValue?.isActive),
                "role_error":"",
                "team":this.props.editValue.team === null?[]: this.props.editValue.team.map((g)=>{
                    return {
                        value:g.ID,
                        label:g.Name
                    }
                }),
                "title": this.props.editValue.title|| "",
                "error":"",
                "id": this.props.editValue.id  || ""
            });   
        }

        if(!this.props.addTeamMemberProgress && prevProps.addTeamMemberProgress){
            if(this.props.addTeamMemberError){
                // this.setState({'error':this.props.addTeamMemberError?.message })
            } else if(this.props.justHideDialog !== undefined && this.props.justHideDialog){
                this.props.hideAddTeamDialog();
                this.props.showSnackBar('User successfully created', "success", 3000)
            }else if(this.props.isFromSettings && !this.props.isEdit){
                this.props.hideAddTeamDialog();
                this.props.fetchUsers(0, 20, '');
                this.props.showSnackBar('User successfully created', "success", 3000)
            }
        }

        if(!this.props.isEditUserProgress && prevProps.isEditUserProgress) {
            if(this.props.editUserError) {
                this.setState({'error':this.props.editUserError?.message })
            }  else if(this.props.justHideDialog !== undefined && this.props.justHideDialog){
                this.props.hideAddTeamDialog();
                this.props.showSnackBar('User successfully updated', "success", 3000)
            }else if(this.props.isFromSettings && this.props.isEdit){
                this.props.hideAddTeamDialog();
                this.props.fetchUsers(0, 20, '');
                this.props.showSnackBar('User successfully updated', "success", 3000)
            }
        }
    }

    fetchOrgGroups(){
        this.props.fetchOrgGroup(0,15,this.state.teamQuery);
    }

    clearError(){
        this.setState({
            "name_error":"",
            "email_error":"",
            "role_error":"",
        }); 
    }

    addUser(){
        var me  =   this;
        me.clearError();
        var name    =   me.state.name;
        if(name === null || String(name).trim().length === 0){
            me.setState({name_error:"Please provide  name."});
            return;
        }

        var email   =    me.state.email;
        if(email === null || String(email).trim().length === 0){
            me.setState({email_error:"Please provide  email."});
            return;
        }

        if(Validator.validateEmail(email) === false){
            me.setState({email_error:"Please provide valid email."});
            return;
        }

        var team    =   me.state.team;

        if(this.props.isFromSettings) {
            if(!this.props.isEdit) {
                this.props.addTeamMembers([{
                    name:name,
                    team:"",
                    group_ids:team.map((g)=>{
                        return g.value
                    }),
                    email:email,
                    title:me.state.title,
                    role:me.state.role,
                    checked:true
                }]);
            } else {
                this.props.updateUser({
                    name:name,
                    team:"",
                    title:me.state.title,
                    group_ids:team !== null && team.length > 0 ? team.map((g)=>{return g.value}) : [],
                    role:me.state.role,
                    isActive:me.state.isActive === 'false' ? false : true,
                }, this.props.editValue.id)
            }
        } else {
            this.props.addTeamMember({
                name:name,
                title:me.state.title,
                role:me.state.role,
                team:team.label.toString(),
                email:email,
                checked:true
            })
            this.props.hideAddTeamDialog();
        }
        
    }

    handleOnChangeTeamSelect = () => {
        var me = this;
        const newTeam = (me.state.team !== null && me.state.team.filter(t => t.__isNew__)) || []
        newTeam.forEach(team => {
            me.props.addOrgGroup(team.label)
        })
    }

    render() {
        const classes = this.props.classes;
        console.log(this.props.groups.map(g => g.Name))
        return <Dialog 
                    onClose={this.props.hideAddTeamDialog} 
                    aria-labelledby="add-teamDialog" 
                    open={this.props.addTeamDialogOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"md"}
                    scroll={"body"}
                    id={"addUserDialog"}
                    >
                        
                        <DialogContent classes={{root:classes.dialogContent}}>
                        <IconButton className={classes.closeIcon} onClick={this.props.hideAddTeamDialog}><CloseIcon /></IconButton>
                            <Typography variant={"h6"} className={classes.title}> { this.props.isEdit === false ? 'Add New User': 'Edit User'}</Typography>
                            <div className={classes.alert}>
                    {           this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                             </div>
                            <Grid container spacing={2}>
                                <Grid item  xs={12} lg={12}>
                                    <OutlinedInput
                                        id={"nameInput"}
                                        label="Name"
                                        type="text"
                                        required={true}
                                        value={this.state.name || ''}
                                        onChange={(event) => {
                                            this.setState({ name: event.target.value });
                                        }}
                                        error={this.state.name_error.length > 0}
                                        helperText={this.state.name_error}
                                    />
                                </Grid>
                                <Grid item  xs={12} lg={12} className={this.props.isEdit? classes.editEmail : '' }>
                                    <OutlinedInput
                                        id={"emailInput"}
                                        label="Email"
                                        type="text"
                                        required={true}
                                        disabled={this.props.isEdit}
                                        value={this.state.email || '' }
                                        onChange={(event) => {
                                            this.setState({ email: event.target.value });
                                        }}
                                        error={this.state.email_error.length > 0}
                                        helperText={this.state.email_error}
                                    />
                                </Grid>
                                <Grid item  xs={12} lg={12}>
                                    <InputLabel id="rolelabel" className={classes.inputLabel}>
                                        Role<font style={{color:"red"}}>*</font>
                                    </InputLabel>
                                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                        <Select
                                            labelId="rolelabel"
                                            id="roleSelect"
                                            value={this.state.role || ''}
                                            onChange={(event) => {
                                                this.setState({ 'role': String(event.target.value) })
                                            }}
                                        >
                                            <MenuItem value={"OrgUser"}>User</MenuItem>
                                            <MenuItem value={"OrgManager"}>Manager</MenuItem>
                                            <MenuItem value={"OrgExternal"}>External</MenuItem>
                                            <MenuItem value={"OrgAdmin"}>Admin</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item  xs={12} lg={12}>
                                    <InputLabel id="teamlabel" className={classes.inputLabel}>
                                        Team
                                    </InputLabel>
                                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                        <CreatableSelect
                                            isClearable
                                            isSearchable={true}
                                            isMulti={true}
                                            options={this.props.groups.map((item)=>{
                                                return {
                                                    value: item.ID,
                                                    label: item.Name
                                                }
                                            })}
                                            classNamePrefix="select"
                                            placeholder="Team" 
                                            onInputChange={(e) => {
                                                this.setState({
                                                    teamQuery:e
                                                },()=>{
                                                    this.fetchOrgGroups()
                                                })
                                                
                                            }}
                                            isLoading={this.props.fetchGroupProg}
                                            onChange={(e,action)=>{
                                                this.setState({
                                                    team:e
                                                }, () => {
                                                    this.handleOnChangeTeamSelect()
                                                })
                                            }}
                                            menuPortalTarget={document.getElementById("CreatableSelect")} 
                                            defaultValue={this.state.team}
                                            value={this.state.team}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item  xs={12} lg={12}>
                                    <OutlinedInput
                                        id={"nameInput"}
                                        label="Title"
                                        type="text"
                                        required={false}
                                        value={this.state.title || ''}
                                        onChange={(event) => {
                                            this.setState({ title: event.target.value });
                                        }}
                                    />
                                </Grid>
                                {this.props.isEdit && <Grid item xs={12} lg={12}>
                                    <InputLabel id="rolelabel" className={classes.inputLabel}>
                                        Status
                                    </InputLabel>
                                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                        <Select
                                            labelId="rolelabel"
                                            id="roleSelect"
                                            value={this.state.isActive}
                                            onChange={(event) => {
                                                this.setState({ 
                                                    isActive: event.target.value
                                                })
                                            }}
                                        >
                                            <MenuItem value={'true'}>Active</MenuItem>
                                            <MenuItem value={'false'}>Inactive</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>}
                                
                            </Grid>
                            { ((this.props.isFromSettings === true && this.props.addTeamMemberProgress === false && this.props.isEditUserProgress === false) || this.props.isFromSettings === false) && <div className={classes.formAction}>
                                    <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideAddTeamDialog}>Cancel</Button>
                                        &nbsp;
                                        &nbsp;
                                        <Button variant="contained" color="secondary" className={classes.button} onClick={this.addUser}>Submit</Button>
                                    </div> }
                                {
                                    ((this.props.isFromSettings === true && this.props.addTeamMemberProgress === true) || (this.props.isFromSettings === true && this.props.isEditUserProgress === true))  && <div className={classes.loader}><CircularProgress /></div>
                                }
                        </DialogContent>
                </Dialog>
    }
}
AddUserDialog.defaultProps = {
    isFromSettings: false,
    isEdit: false,
    editValue: {name: '', email: '', team: '',  role: '', id: '', title: '',isActive:'' }
};

export default connector(compose(
    withStyles(styles)
)(AddUserDialog));