import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import RiskHome from "./Home/RiskHome";
import RiskForm from "./RiskForm";

const connectedProps = (state) => ({
  orgUser: state.orgUser,
});

const connectionActions = {

};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  
});

class Risks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={this.props.match.path} component={RiskHome} />
          <Route exact path={`${this.props.match.path}/risk/:id/:formId`} component={RiskForm} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Risks));
