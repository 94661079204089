import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
    TableContainer, Button, Table, TableBody, TableCell, TableHead, TableRow,
    CircularProgress, IconButton, Paper, MenuItem, FormControl,
    Select, Tooltip, Popover, Typography, List, ListItem, ListItemSecondaryAction, Switch, Divider, InputLabel
} from '@material-ui/core';

import * as Validator from "util/Validation";

import DragIndicatorOutlinedIcon from "@material-ui/icons/DragIndicatorOutlined";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { CommonFn } from "services/commonFn";
// import { renewalTableColumns,getRenewalColumns } from "util/Columns"
import { preciseDiffMoments } from "util/Common";
import LinkIcon from '@material-ui/icons/Link';
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import SqureButton from "Components/Common/SqureButton";
import { fetchOrgCatalogDetailsTabs } from "redux/vendor/vendorCatalog/action";
import { renewalValidColumnTypes } from "util/AppCatalogTypeOptions"
import { currencyCodeMap } from "util/Currency";
import { addOrgSettings } from "redux/org/user/action";

import AddColumnsDialog from "./AddColumnsDialog";
import SaveIcon from '@material-ui/icons/Save';

import {
    fetchOrgProductDetailsTabs
} from 'redux/product/orgProduct/action'
import { Select as MaterialSelect } from '@material-ui/core';

const renewalTableColumns = [
    { id: 'contractName', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Contract', parent: 'Default' },
    { id: 'renewalDate', type: 2, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Renewal Date', parent: 'Default' },
    { id: 'renewalInDays', type: 'Number', align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Renewal In Days', parent: 'Default' },
    { id: 'ownerName', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Contract Owner', parent: 'Default' },
    { id: 'requestStatus', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Request Status', parent: 'Default' },
    { id: 'currentStep', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Current Step', parent: 'Default' },
    { id: 'projectOwnerName', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Project Owner', parent: 'Default' },
    { id: 'status', type: 100, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Status', parent: 'Default' },
    // { id: 'entity', type: 1, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Entity' , parent:'Default'},
]

const contractColumns = [
    { id: 'amount_due', parent: 'Contract', label: 'Amount Due', type: 20, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'due_date', parent: 'Contract', label: 'Due Date', type: 2, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'end_date', parent: 'Contract', label: 'End Date', type: 2, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'freight_amount', parent: 'Contract', label: 'Freight Amount', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'invoice_date', parent: 'Contract', label: 'Invoice Date', type: 2, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'invoice_id', parent: 'Contract', label: 'Invoice Id', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'net_amount', parent: 'Contract', label: 'Net Amount', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'payment_terms', parent: 'Contract', label: 'Payment Terms', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'product_name', parent: 'Contract', label: 'Product Name', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'purchase_order', parent: 'Contract', label: 'Purchase Order', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'receiver_address', parent: 'Contract', label: 'Receiver Address', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'receiver_name', parent: 'Contract', label: 'Receiver Name', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'receiver_tax_id', parent: 'Contract', label: 'Receiver Tax Id', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'remit_to_address', parent: 'Contract', label: 'Remit to Address', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'remit_to_name', parent: 'Contract', label: 'Remit to Name', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'renewal_date', parent: 'Contract', label: 'Renewal Date', type: 2, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'ship_to_address', parent: 'Contract', label: 'Ship to Address', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'ship_to_name', parent: 'Contract', label: 'Ship to Name', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'supplier_address', parent: 'Contract', label: 'Supplier Address', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'supplier_email', parent: 'Contract', label: 'Supplier Email', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'supplier_iban', parent: 'Contract', label: 'Supplier iban', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'supplier_name', parent: 'Contract', label: 'Supplier Name', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'supplier_tax_id', parent: 'Contract', label: 'Supplier Tax Id', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'supplier_website', parent: 'Contract', label: 'Supplier Website', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'total_cost', parent: 'Contract', label: 'Total Cost', type: 20, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'total_tax_amount', parent: 'Contract', label: 'Total Tax Amount', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'vat', parent: 'Contract', label: 'VAT', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
    { id: 'vendor_name', parent: 'Contract', label: 'Vendor Name', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false },
]

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const connectedProps = (state) => ({
    orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,
    orgCatalogDetailTabsProgress: state.vendorCatalog.orgCatalogDetailTabsProgress,
    orgUser: state.orgUser,
    orgProductDetailTabs: state.orgProduct.orgProductDetailTabs,
    orgProductDetailTabsProgress: state.orgProduct.orgProductDetailTabsProgress,

});

const connectionActions = {
    fetchOrgCatalogDetailsTabs: fetchOrgCatalogDetailsTabs,
    addOrgSettings: addOrgSettings,
    fetchOrgProductDetailsTabs: fetchOrgProductDetailsTabs
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    topHeader: {
        display: "flex",
    },
    showBtnSection: {
        flex: 1,
        textAlign: "right",
        paddingTop: 3,
        paddingRight: 10,
    },
    columnDrawer: {
        width: 500,
        right: "0",
        padding: "15px",
        zIndex: "9",
    },
    bottomActions: {
        marginTop: 20,
        textAlign: "right",
    },
    accordionSummary: {
        background: '#f7f7f7',
        borderRadius: 4,
        color: '#222',
        minHeight: 50,
        border: '1px solid #222'
    },
    details: {
        display: 'block'
    },
    viewSelector: {
        marginTop: 3
    }
});

class RenewalTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renewalColumns: [],
            renewals: [],
            isShowSubMenu: false,
            subMenuEl: null,
            colVisibilityData: null,
            columnInitialized: false,
            columnsMap: {},
            isDataLoaded: false,
            configSuccessMsg: '',
            renewalColumnGroups: {},
            expanded: 'Default',
            showAddColumnDialog: false,
            isFetchedVendorTabs: false,
            isFetchedProductTabs: false,
            originalColumns: []
        }
        this.showColumnVisibityPoper = this.showColumnVisibityPoper.bind(this);
        this.updateColVisibilityStatus = this.updateColVisibilityStatus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onColumnsDragEnd = this.onColumnsDragEnd.bind(this);
    }

    componentDidMount() {
        this.props.fetchOrgCatalogDetailsTabs("vendor");
        this.props.fetchOrgProductDetailsTabs()
    }

    componentDidUpdate(prevProps) {
        if (this.props.onViewSelection !== prevProps.onViewSelection) {
            this.setState({
                renewalColumns: this.props.viewColumns && this.props.viewColumns.length > 0 ? this.props.viewColumns : this.state.originalColumns
            })
        }

        if (!this.props.orgCatalogDetailTabsProgress && prevProps.orgCatalogDetailTabsProgress) {
            this.setState({
                isFetchedVendorTabs: true
            }, () => {
                this.initializeColumns();
            })
        }

        if (!this.props.orgProductDetailTabsProgress && prevProps.orgProductDetailTabsProgress) {
            this.setState({
                isFetchedProductTabs: true
            }, () => {
                this.initializeColumns();
            })
        }

        if (this.props.renewalsData && this.props.renewalsData.length !== prevProps.renewalsData.length) {
            this.setState({
                isDataLoaded: false
            }, () => {
                this.initializeData()
            })
        }

        if (this.props.fetchProgress === false && prevProps.fetchProgress) {
            this.setState({
                isDataLoaded: false
            }, () => {
                this.initializeData()
            })
        }

        if (this.props.orgUser.addOrgSettingsInprogress === false && prevProps.orgUser.addOrgSettingsInprogress === true) {
            if (this.props.orgUser.addOrgSettingsFailure == null) {
                // this.props.showSnackBar(this.state.configSuccessMsg, "success", 3000)
                console.log('%c Columns Updated Successfully', 'color:green')
            }
        }
    }



    getConfigValue = (array, key, value) => {
        if (value) {
            let result = "";
            try {
                result = array.find((element) => element[key] === value)["Value"];
            } catch (e) { }
            return result.trim() || "";
        }
    };

    getRenewalColumns = (renewalColumns) => {
        let columns = [];
        // const localColumns = window.localStorage.getItem("renewalListingColumns");
        const localColumns = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "RENEWAL_TABLE_COLUMN") || '';
        if (localColumns !== undefined && localColumns !== null && localColumns !== 'null' && localColumns !== '') {
            let localStorageCols = JSON.parse(localColumns);
            if (localStorageCols.length > 0) {
                columns = localStorageCols;
            } else {
                columns = renewalColumns;
            }
        } else {
            columns = renewalColumns;
        }

        let isActionAlreadyExist = false;
        columns.forEach(function(col){
            if(col.id === 'actions'){
                isActionAlreadyExist = true
            }
        })

        if(!isActionAlreadyExist){
            columns.push({
                id: 'actions',
                type: 'Action',
                align: 'center',
                isVisible: true,
                isDefault: true,
                isSortRequire: false,
                disablePadding: false,
                label: 'Action',
                parent: 'Default'
            })
        }

        // this.updateColumnNames(columns)
        // window.localStorage.setItem("renewalListingColumns", JSON.stringify(columns));
        return columns;
    }

    initializeColumns() {
        const { isFetchedVendorTabs, isFetchedProductTabs } = this.state;
        // console.log(isFetchedVendorTabs, 'isFetchedVendorTabs')
        // console.log(isFetchedProductTabs, 'isFetchedProductTabs')

        if (isFetchedVendorTabs && isFetchedProductTabs) {
            let renewalColumns = [...renewalTableColumns];
            let validTypes = renewalValidColumnTypes()
            let columnsMap = {}
            let tabs = this.props.orgCatalogDetailTabs;
            tabs.forEach((tab) => {
                tab.sections.forEach((section) => {
                    section.items.forEach((item) => {
                        if (validTypes.indexOf(item.type) === -1) {
                            return;
                        }
                        columnsMap[item.slug] = item.type;
                    })
                })
            })

            let productTabs = this.props.orgProductDetailTabs;
            // console.log(productTabs, 'productTabs')
            productTabs.forEach((tab) => {
                tab.OrgProductTabSections.forEach((section) => {
                    section.OrgProductTabItems.forEach((item) => {
                        if (validTypes.indexOf(item.Type) === -1) {
                            return;
                        }
                        columnsMap[item.ItemUID] = item.Type;
                    })
                })
            })

            let finalColumns = this.getRenewalColumns(renewalColumns);
            this.setState({
                columnInitialized: true,
                columnsMap: columnsMap
            }, () => {
                this.setColumns(finalColumns)
                this.initializeData()
            })
        }
    }

    initializeData() {
        const { renewalsData } = this.props;
        const { columnsMap } = this.state;
        let renewals = []
        if (renewalsData) {
            renewals = renewalsData.map((o) => {
                let renewalInDays = preciseDiffMoments(new Date(), new Date(o.contract?.renewalDate))
                let obj = {
                    contractId: o.contract?.id,
                    contractName: o.contract?.name,
                    renewalDate: o.contract?.renewalDate,
                    status: o.contract?.status,
                    ownerName: o?.contract?.owner?.name,
                    ownerId: o?.contract?.owner?.id,
                    ownerEmail: o.contract?.owner?.email,
                    entity: o.entity,
                    requestStatus: o.project?.requestStatus || '',
                    currentStep: o.project?.currentStep || '',
                    projectId: o.project?.projectId || '',
                    projectOwnerName: o.project?.owner?.name || '',
                    projectOwnerId: o.project?.owner?.id || '',
                    projectOwnerEmail: o.project?.owner?.email || '',
                    renewalInDays: renewalInDays,
                    product: o.product,
                    project: o.project,
                    vendor: o.vendor,
                    contract: o.contract,
                    ...o.contract?.meta,
                }

                if (o.entity === 'PRODUCT') {
                    obj['name'] = o?.product?.name;
                    obj['contractName'] = o?.product?.name + ' - ' + o.contract?.name;
                    obj['product_id'] = o?.product?.id;
                    obj['product_logo'] = o?.product?.productLogo;
                    if (o.product && o.product.items) {
                        Object.keys(o.product.items).forEach(function (itm) {
                            let response = ''
                            if (o.product.items[itm]) {
                                try {
                                    response = JSON.parse(o.product.items[itm])
                                } catch (e) { }
                            }
                            // console.log(response, 'response=responseaaaaaaaaaaaaaaaaaaaaaa')

                            if (columnsMap[itm] && [7, 8].includes(columnsMap[itm]) && response && response !== '') {
                                let users = response.data.map(o => o.name)
                                obj[itm] = users.join(', ')
                            } else if (columnsMap[itm] && [4, 5].includes(columnsMap[itm]) && response && response !== '') {
                                let users = response.data.map(o => o.label)
                                obj[itm] = users.join(', ')
                            } else if (columnsMap[itm] && [20].includes(columnsMap[itm]) && response && response !== '') {
                                let str = currencyCodeMap[response?.data?.type?.value] + '' + response?.data?.value
                                if (str) {
                                    obj[itm] = str
                                }
                            } else if (columnsMap[itm] && [1, 2, 3, 4, 5, 15, 11, 12, 13, 14, 19].includes(columnsMap[itm]) && response && response !== '') {
                                obj[itm] = response.data
                            }
                        })
                    }
                } else if (o.entity === 'VENDOR') {
                    obj['name'] = o?.vendor?.name;
                    obj['contractName'] = o?.vendor?.name + ' - ' + o.contract?.name;
                    obj['product_id'] = o?.vendor?.id;
                    obj['product_logo'] = o?.vendor?.logo;
                    if (o.vendor && o.vendor.items) {
                        Object.keys(o.vendor.items).forEach(function (itm) {
                            if (columnsMap[itm] && (columnsMap[itm] === 4 || columnsMap[itm] === 5 || columnsMap[itm] === 7 || columnsMap[itm] === 8)) {
                                let str = o.vendor.items[itm] ? o.vendor.items[itm].map(o => o.label).join(', ') : '';
                                if (str) {
                                    obj[itm] = str
                                }
                            } else if (columnsMap[itm] && columnsMap[itm] === 20) {
                                let str = '';
                                if (o.vendor.items[itm]) {
                                    str = currencyCodeMap[o.vendor.items[itm].type.value] + '' + o.vendor.items[itm]?.value
                                }
                                if (str) {
                                    obj[itm] = str
                                }
                            } else if (columnsMap[itm] && columnsMap[itm] === 6) {
                                if (o.vendor.items[itm]) {
                                    let data_map = {}
                                    o.vendor.items[itm].forEach(function (itm) {
                                        itm.forEach(function (dt) {
                                            if (data_map[dt.Label] === undefined) {
                                                data_map[dt.Label] = []
                                                data_map[dt.Label].push(dt.Value)
                                            } else {
                                                data_map[dt.Label].push(dt.Value)
                                            }
                                        })
                                    })
                                    if (data_map) {
                                        Object.keys(data_map).forEach(function (row_dt) {
                                            let str = data_map[row_dt].join(', ');
                                            if (str) {
                                                obj[parseInt(row_dt)] = str;
                                            }
                                        })
                                    }
                                }
                            } else {
                                obj[itm] = o.vendor.items[itm] ? o.vendor.items[itm] : ''
                            }
                        })
                    }
                } else {
                    obj['name'] = o?.vendor_service?.name;
                    obj['contractName'] = o?.vendor_service?.name + ' - ' + o.contract?.name;
                    obj['product_id'] = o?.vendor_service?.id;
                    obj['product_logo'] = o?.vendor_service?.logo;
                    if (o.vendor_service && o.vendor_service.items) {
                        Object.keys(o.vendor_service.items).forEach(function (itm) {

                            obj[itm] = o.vendor_service.items[itm] ? o.vendor_service.items[itm] : ''
                        })
                    }
                }
                return obj;
            })
        }
        this.setState({
            renewals: renewals,
            isDataLoaded: true
        })
    }

    showColumnVisibityPoper(event) {
        this.setState({
            subMenuEl: event.currentTarget || event.current,
            isShowSubMenu: true,
            colVisibilityData: [...this.state.renewalColumns],
        });
    }

    handleCloseSubMenu() {
        this.setState({
            isShowSubMenu: false,
            subMenuEl: null,
            colVisibilityData: null,
            isShowProjectConfigPopover: false,
            projectTrackingEl: null,
        });
    }

    onColumnsDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const { destination, draggableId, source } = result;
        if (draggableId && destination.index !== source.index) {
            const newColVisibilityData = reorder(this.state.colVisibilityData, source.index, destination.index);

            this.setState({
                colVisibilityData: newColVisibilityData,
            });
        }
    }

    updateColVisibilityStatus(oldStatus, index) {
        let colVisibilityData = [...this.state.colVisibilityData];
        let myCol = colVisibilityData[index];
        let isVisible = oldStatus === true ? false : true;
        myCol = {
            ...myCol,
            isVisible: isVisible,
        };
        colVisibilityData[index] = myCol;

        this.setState({
            colVisibilityData: colVisibilityData,
        });
    }

    setVisibilityForColumns() {
        // console.log(this.state.colVisibilityData, 'colVisibilityData')
        this.setColumns(this.state.colVisibilityData);
        this.handleCloseSubMenu();
    }

    setColumns(columns) {
        this.setState({
            renewalColumns: columns,
            originalColumns: columns,
            colVisibilityData: null,
        }, () => {
            let customColumns = columns.filter(o => o.isVisible && !o.isDefault);
            // console.log(customColumns, 'customColumns')
            if (customColumns && customColumns.length > 0) {
                let columns = customColumns.map(o => {
                    return {
                        id: o.id.split(':tableColumn')[0],
                    }
                })
                this.props.fetchRenewals(columns)
            }
            // window.localStorage.setItem("renewalListingColumns", JSON.stringify(columns));
            this.updateColumnNames(columns)
        });
    }

    updateColumnNames(columns) {
        if (columns === undefined || columns === null || columns === '') {
            return;
        }
        let org_settings = [
            {
                name: "RENEWAL_TABLE_COLUMN",
                value: JSON.stringify(columns)
            }
        ]
        this.setState({
            configSuccessMsg: "Columns updated successfully"
        }, () => {
            this.props.addOrgSettings(org_settings);
        })
    }

    handleChange = (panel) => (event, isExpanded) => {
        // console.log(panel)
        // console.log(event)
        // console.log(isExpanded)

        event.preventDefault();
        this.setState({
            expanded: isExpanded ? panel : false
        });
    };


    callMe = (panel, isExpanded) => {
        this.setState({
            expanded: isExpanded ? panel : false
        });
    }

    handleClickRenewal(item) {
        console.log(item, 'Renewal')
        let url;
        if (item.entity === 'PRODUCT') {
            url = `/app/products/org-product-details/${item.product?.id}?document_id=${item.contract.id}#renewal_page`;
        } else if (item.entity === 'VENDOR') {
            url = `/app/vendors/org-vendor-details/${item.vendor?.id}?document_id=${item.contract.id}#${item.contract.meta?.tab_slug}`;
        } else {
        }

        if (url !== '') {

        }
        this.props.history.push(url);
    }

    render() {
        const { renewalColumns, renewals, colVisibilityData, columnInitialized, isDataLoaded } = this.state;
        const { classes, fetchProgress } = this.props;
        if (!columnInitialized || !isDataLoaded) {
            return null
        }

        return (
            <div>
                <Paper style={{ width: '100%', overflow: 'hidden' }}>
                    <div className={classes.topHeader}>
                        <div className={classes.showBtnSection}>
                            <Button variant="outlined" color="secondary" style={{ marginRight: 20 }} startIcon={<SaveIcon />} onClick={() => { this.props.showAddUpdateRenewalViewsDialog(renewalColumns) }}>
                                Save View
                            </Button>

                            {this.props.viewsList?.length > 0 && <FormControl variant="outlined" className={classes.viewSelector} size="small">
                                <MaterialSelect
                                    labelId="demo-simple-select-outlinedViewlabel"
                                    id="demo-simple-select-outlined"
                                    value={String(this.props.selectedFilterView)}
                                    onChange={(e) => {
                                        this.props.selectView(Number(e.target.value));
                                    }}
                                >

                                    <MenuItem value={0} key={'_default0'} style={{ fontSize: 13 }}>
                                        Select View
                                    </MenuItem>

                                    {this.props.viewsList?.map((item, i) => (
                                        <MenuItem value={String(item.id)} key={i} style={{ fontSize: 13 }}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </MaterialSelect>
                            </FormControl>}

                            <Tooltip title="Show Hide Columns">
                                <IconButton
                                    aria-label="filter list"
                                    onClick={(event) => {
                                        this.showColumnVisibityPoper(event);
                                    }}
                                >
                                    <SettingsOutlined />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>

                    <TableContainer style={{ maxHeight: '65vh' }}>
                        <Table stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
                            <TableHead>
                                <TableRow>
                                    {renewalColumns && renewalColumns.map((col) => {
                                        if (col.isVisible) {
                                            return <TableCell
                                                key={col?.id}
                                                align={col.align}
                                                style={{ whiteSpace: "nowrap" }}
                                                padding={col?.disablePadding ? "none" : "normal"}
                                            >{col.label}</TableCell>
                                        } else {
                                            return null
                                        }
                                    })}

                                    {/* <TableCell
                                        key={'action_head'}
                                        align={"center"}
                                        style={{ whiteSpace: "nowrap" }}
                                        padding={"normal"}
                                    >Action</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {!fetchProgress && risks.slice(page * pageSize, page * pageSize + pageSize).map((risk) => { */}
                                {!fetchProgress && renewals.map((renewal) => {
                                    return <TableRow
                                        hover
                                        tabIndex={-1}
                                        style={{ cursor: "pointer" }}
                                        key={'renwal_' + renewal.contractId}>

                                        {renewalColumns && renewalColumns.map((col) => {
                                            if (col.isVisible) {
                                                if (col.type === 2) {
                                                    return <TableCell
                                                        onClick={() => this.handleClickRenewal(renewal)}
                                                        key={col?.id}
                                                        align={"left"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        padding={col?.disablePadding ? "none" : "normal"}
                                                    >{renewal[col.id] ? CommonFn.formateDate(renewal[col.id], true) : ''}</TableCell>
                                                } else if (col.type === 6) {
                                                    return <TableCell
                                                        onClick={() => this.handleClickRenewal(renewal)}
                                                        key={col?.id}
                                                        align={"left"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        padding={col?.disablePadding ? "none" : "normal"}
                                                    >{renewal[col.tableColumnId] ? renewal[col.tableColumnId] : ''}</TableCell>
                                                } else if (col.type === 20) {
                                                    return <TableCell
                                                        key={col?.id}
                                                        onClick={() => this.handleClickRenewal(renewal)}
                                                        align={"left"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        padding={col?.disablePadding ? "none" : "normal"}
                                                    >{renewal[col.id] && renewal[col.id].value !== undefined ? renewal[col.id].value : renewal[col.id]}</TableCell>
                                                } else if (col.type === 100) {
                                                    return <TableCell
                                                        key={col?.id}
                                                        align={"left"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        padding={col.disablePadding ? "none" : "normal"}
                                                    > <FormControl
                                                        variant="outlined"
                                                        className={classes.select}
                                                        margin="dense"
                                                    >
                                                            {true && <Select
                                                                value={renewal[col.id]}
                                                                onClick={() => { }}
                                                                onChange={(event) => {
                                                                    if (event.target.value === 3) {
                                                                        let entity
                                                                        if (renewal.entity === 'VENDOR') {
                                                                            entity = { ...renewal.vendor, type: 'VENDOR' }
                                                                        } else {
                                                                            entity = { ...renewal.product, type: 'PRODUCT' }
                                                                        }
                                                                        this.props.showConfirmCallbackDialog(entity, renewal.contract)
                                                                    }
                                                                    if (renewal.entity === 'PRODUCT') {
                                                                        this.props.updateRenewal(renewal.product?.id, { date: renewal.contract?.renewalDate, status: event.target.value })
                                                                    } else {
                                                                        this.props.updateEntityRenewal(renewal.contract?.id, { date: renewal.contract?.renewalDate, status: event.target.value })
                                                                    }
                                                                }}>
                                                                {<MenuItem value={1}>Not started</MenuItem>}
                                                                {<MenuItem value={2}>In progress</MenuItem>}
                                                                {<MenuItem value={3}>Renewed</MenuItem>}
                                                                {<MenuItem value={4}>Not going to renew</MenuItem>}
                                                            </Select>}
                                                        </FormControl>
                                                    </TableCell>
                                                } else if (col.type === 'Action') {
                                                    return <TableCell
                                                        key={'actionCol'}
                                                        align={"center"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        padding={"normal"}
                                                    >

                                                        {renewal.project && <Button variant="contained" color="secondary" className={classes.sendBtn}
                                                            onClick={() => {
                                                                this.props.viewRenewal(renewal.project?.id)
                                                            }}
                                                        > View Renewal</Button>}

                                                        {renewal.project && <IconButton style={{ marginLeft: 25 }} ref={this.btnRef}></IconButton>}

                                                        {(renewal.entity === 'PRODUCT' && (renewal.project === undefined || renewal.project === null || renewal.project === '')) &&
                                                            <Button variant="contained" color="secondary" className={classes.sendBtn}
                                                                onClick={() => {
                                                                    this.props.showEvaluationProductSelectionDialog(renewal.contract, renewal.product, renewal.contract?.renewalDate);
                                                                }}
                                                            > Start Renewal</Button>}

                                                        {(renewal.entity !== 'PRODUCT' && (renewal.project === undefined || renewal.project === null || renewal.project === '')) &&
                                                            <Button
                                                                // disabled
                                                                variant="contained"
                                                                color="secondary"
                                                                className={classes.sendBtn}
                                                                onClick={() => {
                                                                    const product = {
                                                                        name: renewal.vendor.name,
                                                                        id: renewal.vendor.id,
                                                                        productLogo: renewal.vendor.logo,
                                                                        type: renewal.entity
                                                                    }
                                                                    this.props.showEvaluationProductSelectionDialog(renewal.contract, product, renewal.contract?.renewalDate);
                                                                }}
                                                            > Start Renewal</Button>}


                                                        {(renewal.project === undefined || renewal.project === null || renewal.project === '') &&
                                                            <Tooltip
                                                                title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{'Link Project'}</span>}
                                                                placement="top"
                                                                classes={{ tooltip: classes.noMaxWidth }}>
                                                                <IconButton ref={this.btnRef}
                                                                    onClick={(e) => {
                                                                        const product = renewal.entity === 'PRODUCT' ? {
                                                                            ...renewal.product,
                                                                            type: renewal.entity
                                                                        } : {
                                                                            name: renewal.vendor.name,
                                                                            id: renewal.vendor.id,
                                                                            productLogo: renewal.vendor.logo,
                                                                            type: renewal.entity
                                                                        };

                                                                        this.props.showAttachRequestDialog(product, renewal.contract, renewal.contract?.renewalDate, renewal.entity)

                                                                    }}>
                                                                    <LinkIcon />
                                                                </IconButton>
                                                            </Tooltip>}
                                                    </TableCell>
                                                } else {
                                                    return <TableCell
                                                        key={col.id}
                                                        onClick={() => this.handleClickRenewal(renewal)}
                                                        align={"left"}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        padding={col?.disablePadding ? "none" : "normal"}
                                                    >{renewal[col.id] ? renewal[col.id] : ''}</TableCell>
                                                }
                                            } else {
                                                return null
                                            }
                                        })}


                                    </TableRow>
                                })}

                                {(!fetchProgress && (renewals === undefined || renewals === null || renewals.length <= 0)) && <TableRow>
                                    <TableCell colSpan={renewalColumns.length + 1} style={{ textAlign: 'center' }} className={classes.nameCol}>
                                        {'No Renewals'}
                                    </TableCell>
                                </TableRow>}

                                {fetchProgress && <TableRow>
                                    <TableCell colSpan={renewalColumns.length + 1} style={{ textAlign: 'center' }} className={classes.nameCol}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                {this.state.isShowSubMenu && (
                    <Popover
                        open={this.state.isShowSubMenu}
                        anchorEl={this.state.subMenuEl}
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 0, left: window.innerWidth }}
                        onClose={this.handleCloseSubMenu}
                    >
                        <Paper className={classes.columnDrawer}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography style={{ marginTop: 10 }}>Select Visible Column</Typography>
                                <SqureButton variant={"outlined"} onClick={() => {
                                    this.setState({
                                        showAddColumnDialog: true
                                    }, () => {
                                        this.handleCloseSubMenu();
                                    })
                                }}>
                                    Add More Columns
                                </SqureButton>
                            </div>
                            {colVisibilityData !== null && (
                                <DragDropContext onDragEnd={this.onColumnsDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                <List>
                                                    {colVisibilityData.map((col, k) => (
                                                        <Draggable key={String(k)} draggableId={String(k)} index={k}>
                                                            {(provided, snapshot) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <ListItem button style={{ cursor: 'grab' }}>
                                                                        <DragIndicatorOutlinedIcon id={k} style={{ color: '#D9D9D9', marginRight: 5 }} />
                                                                        <Typography variant="inherit" style={{ paddingRight: 10 }}>{col.label}</Typography>
                                                                        <ListItemSecondaryAction>
                                                                            <Switch
                                                                                checked={col.isVisible}
                                                                                onChange={(event) => {
                                                                                    this.updateColVisibilityStatus(col.isVisible, k);
                                                                                }}
                                                                                color="primary"
                                                                                name="is_editable"
                                                                                inputProps={{ "aria-label": "is_editable checkbox" }}
                                                                            />
                                                                        </ListItemSecondaryAction>
                                                                        {/* )} */}
                                                                    </ListItem>
                                                                    <Divider />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                </List>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            )}

                            <div className={classes.bottomActions}>
                                {
                                    <SqureButton
                                        style={{ marginLeft: "10px" }}
                                        variant={"outlined"}
                                        onClick={() => {
                                            this.handleCloseSubMenu();
                                        }}
                                    >
                                        Cancel
                                    </SqureButton>
                                }

                                {
                                    <SqureButton
                                        style={{ marginLeft: "10px" }}
                                        variant={"contained"}
                                        onClick={() => {
                                            this.setVisibilityForColumns();
                                        }}
                                    >
                                        Confirm
                                    </SqureButton>
                                }
                            </div>
                        </Paper>
                    </Popover>
                )}

                {this.state.showAddColumnDialog && <AddColumnsDialog
                    renewalColumns={renewalColumns}
                    contractColumns={contractColumns}
                    vendorCatalogColumns={this.props.orgCatalogDetailTabs}
                    productCatalogColumns={this.props.orgProductDetailTabs}
                    isOpen={this.state.showAddColumnDialog}
                    validTypes={renewalValidColumnTypes()}
                    setNewColumns={(columns) => {
                        let renewalColumns = [...renewalTableColumns, ...columns];
                        this.setColumns(renewalColumns, 'renewalColumns')
                    }}
                    hideDialog={() => {
                        this.setState({
                            showAddColumnDialog: false
                        })
                    }}
                />}

            </div>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(RenewalTable));
