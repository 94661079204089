import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  Grid, CircularProgress, Button
} from '@material-ui/core';

import { addNewNote, updateNote, fetchNoteDatas, deleteNote } from "redux/vendor/note/action";
import { showSnackBar } from "redux/snackbar/action";
import AddUpdateNotesDialog from "../../../../Dialog/AddUpdateNotesDialog";
import Note from "./Note";
import moment from "moment";
import { sendAddMentionNotification } from "services/addMentionNotification";


// const ROLE = {
//   "OrgAdmin" : "Admin",
//   "OrgUser" : "User",
//   "OrgManager" : "Manager",
//   "OrgExternal" : "External User",
// }

const connectedProps = (state) => ({
  addNewNoteProgress: state.catalogNote.addNewNoteProgress,
  newNoteData: state.catalogNote.newNoteData,
  addNewNoteError: state.catalogNote.addNewNoteError,

  updateNoteProgress: state.catalogNote.updateNoteProgress,
  updatedNoteData: state.catalogNote.updatedNoteData,
  updateNoteError: state.catalogNote.updateNoteError,

  fetchDataProgress: state.catalogNote.fetchDataProgress,
  fetchDataError: state.catalogNote.fetchDataError,
  noteDatas: state.catalogNote.noteDatas,

  deleteNoteProgress: state.catalogNote.deleteNoteProgress,
  deleteNoteError: state.catalogNote.deleteNoteError,
});

const connectionActions = {
  addNewNote: addNewNote,
  updateNote: updateNote,
  fetchNoteDatas: fetchNoteDatas,
  deleteNote: deleteNote,
  showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    "& [class*='editableField']": {
      minHeight: 'auto !important',
    },
  },
  contactCardRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
    transitionDuration: "0ms",
    "&:hover": {
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
  },
  contactCardRootNew: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
  },
  addNewChamp: {
      display: "block",
      textAlign: "center",
      color: "#bdbdbd",
      "& span": {
        display: "block",
        cursor: "pointer",
        color: "#000000",
      },
  },
  cardInfo: {
    display: "flex",
    alignItems: "center",
    gap: 15
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  date: {
    color: "rgba(0, 0, 0, 0.54)",
    fontStyle: "italic",
    fontSize: 12,
  },
  user: {
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.54)"
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    gap: 15,
    marginLeft: 25,
    cursor: "pointer",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 0
  },
  addButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 15
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 35,
    padding: 0,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  textWrap: {      
    "& [class*='editor-editableField']": {
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": "4",
      "-webkit-box-orient": "vertical",
      minHeight: 'unset !important',
      "& p": {
        marginTop: 0,
        fontSize: 13,
        color: "rgba(0, 0, 0, 0.54)",
      }
    },
  },
  content: {
    paddingBottom: 16,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    fontFamily: "Poppins"
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
});

class NotesManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      openNoteDialog: false,
      noteDialogData: null,
      readOnly: false,
      isDataLoading: false,
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.fetchDataProgress[this.props.slug] && prevProps.fetchDataProgress[this.props.slug]) {
        this.initializeData();
    }

    if (!this.props.addNewNoteProgress[this.props.slug] && prevProps.addNewNoteProgress[this.props.slug]) {
      if (this.props.addNewNoteError === null) {
        this.props.showSnackBar("Note added successfully.", "success", 3000);
        this.fetchData();
        
        let note = this.props.newNoteData?.row_data[1]?.value || "";
        this.sendNotification(note);
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }

    if (!this.props.updateNoteProgress[this.props.slug] && prevProps.updateNoteProgress[this.props.slug]) {
      if (this.props.updateNoteError === null) {
        this.props.showSnackBar("Note updated successfully.", "success", 3000);
        this.fetchData();
                
        let note = this.props.updatedNoteData?.row_data[1]?.value || "";
        this.sendNotification(note);
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }

    if (!this.props.deleteNoteProgress[this.props.slug] && prevProps.deleteNoteProgress[this.props.slug]) {
      if (this.props.deleteNoteError === null) {
        this.props.showSnackBar("Note deleted successfully.", "success", 3000);
        this.fetchData();
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }
  }

  sendNotification = (note) => {
    let name = this.props.catalogType === 'vendor' ? this.props.appDetails?.name : this.props.appDetails?.service?.name + " By " + this.props.appDetails?.vendor?.name;
    let data = {
      vendor_name: name,
      subject: `You have been mentioned in ${name}`,
      note: note,
      note_time: moment().format("MMM Do YYYY hh:mm A")
    }
    sendAddMentionNotification(note, 'Vendor_Catalog_Note', data);
  }

  initializeData = () => {
    const { noteDatas } = this.props;
    let finalDatas = []
    if (noteDatas && noteDatas[this.props.slug]) {
      let datas = noteDatas[this.props.slug];
      datas.forEach(function (row) {
        let obj = {
          id: row.id,
          is_editable: row.is_editable,
          updatedAt: row?.metadata?.last_modified_time,
          userName: row?.metadata?.last_modified_user?.name,
          userRole: row?.metadata?.last_modified_user?.role,
          title: row?.row_data[0]?.value,
          description: row?.row_data[1]?.value,
        }
        finalDatas.push(obj)
      })
    }
    console.log(finalDatas, 'finalDatas')

    this.setState({
      datas: finalDatas,
    })
  }

  fetchData = () => {
    let params = {
      entity_id: this.props.appId,
      entity_type: this.props.catalogType === 'vendor' ? 'vendor' : 'vendorservice',
      item_slug: this.props.slug
    }
    this.props.fetchNoteDatas(params);
  }

  handleDelete = (id) => {
    this.props.deleteNote(this.props.slug,id);
  }

  handleAddOrUpdate(id, title, description) {
    if (!id) {
      let params = {
        "item_slug": this.props.slug,
        "entity_id": this.props.appId,
        "entity_type": this.props.catalogType === 'vendor' ? 'vendor' : 'vendorservice',
        "row_data": [
          {
            label: 'title',
            value: title
          },
          {
            label: 'description',
            value: description
          }
        ]
      }
      this.props.addNewNote(this.props.slug, params)
    } else {
      let params = {
        "id": id,
        "row_data": [{
          label: 'title',
          value: title
        },
        {
          label: 'description',
          value: description
        }]
      }
      this.props.updateNote(this.props.slug, id, params)
    }

  }

  render() {
    const { classes, updateAccess, fetchDataProgress, addNewNoteProgress, updateNoteProgress, deleteNoteProgress} = this.props;
    const { datas } = this.state;
    if(fetchDataProgress[this.props.slug] || addNewNoteProgress[this.props.slug] || updateNoteProgress[this.props.slug] || deleteNoteProgress[this.props.slug]){
      return <div className={classes.loader}><CircularProgress/></div>
    }
    console.log(datas, 'datasTable')
    return (
      <div className={classes.root} key={this.props.key}>
        {updateAccess && <div className={classes.addButton}>
          <Button className={classes.newUserBtn} onClick={() => this.setState({ openNoteDialog: true })}>
            + Add Notes
          </Button>
        </div>}
        <Grid container spacing={2}>
          {datas?.map((note,index) => (
            <Note 
              key={index}
              note={note}
              updateAccess={updateAccess}
              showInfo={() => {
                this.setState({
                  openNoteDialog: true,
                  noteDialogData: note,
                  readOnly: true,
                });
              }}
              onEdit={() => {
                this.setState({
                  openNoteDialog: true,
                  noteDialogData: note,
                });
              }}
              onDelete={() => {
                this.handleDelete(note.id);
              }}
            
            />
          ))}
        </Grid>
        {this.state.openNoteDialog && <AddUpdateNotesDialog
          classes={classes}
          data={this.state.noteDialogData}
          progress={addNewNoteProgress[this.props.slug] || updateNoteProgress[this.props.slug]}
          readOnly={this.state.readOnly}
          onClose={()=> {
            this.setState({ openNoteDialog: false, noteDialogData: null, readOnly: false });
          }}
          onSubmit={(title,description)=> {
            this.handleAddOrUpdate(this.state.noteDialogData?.id,title,description)
          }}
        />}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(NotesManagement));

function timeSince(date) {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " year";
    } else {
      return Math.floor(interval) + " years";
    }
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " month";
    } else {
      return Math.floor(interval) + " months";
    }
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " day";
    } else {
      return Math.floor(interval) + " days";
    }
  }
  interval = seconds / 3600;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " hour";
    } else {
      return Math.floor(interval) + " hours";
    }
  }
  interval = seconds / 60;
  if (interval > 1) {
    if (interval < 2) {
      return Math.floor(interval) + " minute";
    } else {
      return Math.floor(interval) + " minutes";
    }
  }
  return Math.floor(seconds) + " seconds";
}
