import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import SqureButton from "Components/Common/SqureButton";
import {
  Button, Backdrop, CircularProgress, InputAdornment, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, IconButton, Paper
} from '@material-ui/core';

import { FormControl, Select, MenuItem, Checkbox, Typography, Popover, Divider } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Chip from '@material-ui/core/Chip';

import InfiniteScroll from "react-infinite-scroll-component";
import classnames from "classnames";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import TeamProductShowDialog from './TeamProductShowDialog';
import TeamVendorShowDialog from './TeamVendorShowDialog';
// assets
import { Search as SearchIcon } from "@material-ui/icons";
import CommonTable from "../../../../Common/Table";
import editIcon from "assets/images/edit_icon.svg"
import deleteIcon from "assets/images/delete.svg"

// redux
import { resetOrgGroupState, fetchOrgGroup, addOrgGroup, showTeamProductsDialog, showTeamVendorsDialog, updateOrgGroup, showViaIntegrationDialog } from "redux/org/group/action";
import { showUserGroupDialog } from "redux/okta/action";
import { showSnackBar } from "redux/snackbar/action";

// important
import OrgUserGroupDialog from "Components/Common/OrgUserGroupDialog"
import Image from 'Components/Common/image.jsx'

import Clear from "@material-ui/icons/Clear";
import TooltipMsg from '@material-ui/core/Tooltip';
import { isEnabledEvaluate } from "util/Common";
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import TeamsViaIntegrationDialog from "./TeamsViaIntegrationDialog";

const connectedProps = (state) => ({
  groups: state.orgGroup.groups,
  fetchGroupProg: state.orgGroup.fetchGroupProg,
  fetchGroupTotal: state.orgGroup.fetchGroupTotal,
  addUserGroupDialog: state.okta.addUserGroupDialog,
  updateGroupProg: state.orgGroup.updateGroupProg,
  updateGroupError: state.orgGroup.updateGroupError,
  updateGroupProductProg: state.orgGroup.updateGroupProductProg,
  addGroupProg: state.orgGroup.addGroupProg,
  orgUser: state.orgUser,
  fetchUpdateGroupAPIState: state.orgGroup.fetchUpdateGroupAPIState,
  settings: state.orgUser.fetchAllOrgSettingsSuccess,
  viaIntegrationDialogOpen: state.orgGroup.viaIntegrationDialogOpen,
});

const connectionActions = {
  fetchOrgGroup: fetchOrgGroup,
  addOrgGroup: addOrgGroup,
  showUserGroupDialog: showUserGroupDialog,
  updateOrgGroup: updateOrgGroup,
  showSnackBar: showSnackBar,
  resetOrgGroupState: resetOrgGroupState,
  showTeamProductsDialog: showTeamProductsDialog,
  showTeamVendorsDialog: showTeamVendorsDialog,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  showViaIntegrationDialog: showViaIntegrationDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    '& thead': {
      '& span': {
        fontWeight: 600
      }
    }
  },
  tickBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchSection: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    // marginRight: 40,
    '& [class*="MuiOutlinedInput-root"]': {
      maxHeight: 40,
      minHeight: 40,
      maxWidth: 343,
    },
    "& [class*='MuiInputBase-input']": {
      fontSize: 13,
      width: 300,
    },
    "& [class*='MuiSvgIcon-root']": {
      fill: "#707070",
    },
    "& [class*=MuiGrid-container]": {
      alignItems: "center",
      marginBottom: 20,
    },
    "&>div ": {
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px',
    },
  },
  tableSection: {
    marginTop: theme.spacing(6.4),
    minHeight: 200,
    maxHeight: "calc(100vh - 250px)",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  thead: {
    width: "20%",
  },
  thead_ID: {
    width: "20%",
    // textAlign:'center'
  },
  tbodyCell_ID: {
    height: 50,
    // textAlign:'center'
  },
  action: {
    width: "20%",
    textAlign: 'center'
  },
  userCount: {
    color: "#282D30",
    fontSize: theme.spacing(2.5),
  },
  userSection: {
    marginTop: theme.spacing(6.2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  table: {},
  tbodyCell: {
    // padding: theme.spacing(1.5, 0),
    height: 50,
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  menuItem: {
    color: "#74798C",
    fontSize: theme.spacing(2),
    padding: theme.spacing(2.2),
    minWidth: 140,
  },
  backdrop: {
    textAlign: "center",
    marginTop: 10,
    minHeight: 100,
  },
  edit: {
    width: 18,
    cursor: "pointer",
    padding: theme.spacing(0.1),
    marginRight: 6,
    fill: "#4a88ff",
  },
  delete: {
    fill: "#dc2f3e",
  },
  searchIconPop: {
    cursor: 'pointer',
    fontSize: theme.spacing(3),
    marginRight: '10px',
    "&:hover": {
      color: "#fff !important",
      fill: "#fff !important",
      background: "#707070",
      borderColor: "#707070",
      padding: '3px',
      borderRadius: theme.spacing(20),
      fontSize: theme.spacing(3)
    }
  },
  "selectEmpty": {
    border: "1px solid #B9B9B9",
    borderRadius: 3,
    fontSize: theme.spacing(2),
    color: "#4B4B4B",
    "&:hover, &:before, &:after": {
      border: "1px solid #B9B9B9",
      content: "none"
    },
    "& [class*='selectMenu']": {
      paddingLeft: theme.spacing(1)
    }
  },
});

class UsersGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      page: 0,
      pageSize: 20,
      deleteItemData: {},
      isDataLoading: true,
      totalGroups: 0,
      tasks: [],
      Suppressed: false,
      anchorEl: null,
      newTeamOptions: [
        { url: "", label: "Add Manually", key: "addManually" },
        { url: "", key: "viaIntegration", label: "Via Integration" },
      ]
    };
    this.getAppSpecificFlag = this.getAppSpecificFlag.bind(this)
    this.handleClear = this.handleClear.bind(this);
    this.callBack = this.callBack.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    // group add
    if (!this.props.addGroupProg && prevProps.addGroupProg) {
      this.setState({ page: 0, isDataLoading: true }, () => {

        this.fetchData();
      });
    }
    // group update
    if (!this.props.updateGroupProg && prevProps.updateGroupProg && !this.props.updateGroupError) {
      this.props.showSnackBar("Team successfully updated", "success", 3000);
      this.setState({ page: 0, isDataLoading: true }, () => {
        this.fetchData();
      });
    }

    if (this.props.fetchGroupProg === false && prevProps.fetchGroupProg === true) {
      this.setState({
        isDataLoading: false
      })
    }
  }

  fetchData = () => {
    let suppressed = this.state.Suppressed ? "yes" : "no";
    this.props.fetchOrgGroup(this.state.page, this.state.pageSize, this.state.query, suppressed);
  }

  handleClear(event) {
    event.stopPropagation();
    var me = this;
    this.setState({ query: '', isDataLoading: true }, () => {
      me.fetchData();
    });
  }

  deleteOrgGroup = (row) => {
    let obj = {}
    obj.id = row.ID;
    obj.name = row.Name;
    obj.description = row.Description;
    obj.is_suppressed = true;
    var me = this;
    this.setState({ deleteItemData: obj }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack() {
    var me = this;
    console.log(me.state)
    if (me.state.deleteItemData.id !== undefined) {
      me.props.hideMessageDialog();
      this.setState({
        isDataLoading: true,
        page: 0
      }, () => {
        me.props.updateOrgGroup(this.state.deleteItemData)
      })
    }
  }

  nextFetchData = () => {
    this.setState({ page: this.state.page + 1 }, () => {
      this.fetchData()
    });
  };

  clickOnCell = (bodyData, parentIndex, childIndex, array, item, actionItem, event) => {
    console.log({ bodyData, parentIndex, childIndex, array, item, actionItem, event })
    if (actionItem !== undefined) {
      if (actionItem.action === 'viewProducts') {
        this.props.showTeamProductsDialog(bodyData[parentIndex])
      }
      if (actionItem.action === 'edit') {
        this.props.showUserGroupDialog(bodyData[parentIndex])
      }
      if (actionItem.action === 'delete') {
        this.deleteOrgGroup(bodyData[parentIndex])
      }
    }

    if (item !== undefined && item.key === "SyncUserFlag") {
      var group = bodyData[parentIndex];

      var obj = {}
      obj.id = group.ID;
      obj.name = group.Name;
      this.props.updateOrgGroup(obj)
    }

  }

  handleUpdateSyncFlag(event, group) {
    var obj = {}
    obj.sync_user = event.target.value === "YES" ? true : false
    obj.id = group.ID;
    obj.name = group.Name;
    obj.description = group.Description;
    this.props.updateOrgGroup(obj)
  }

  searchInput(classes) {
    return (
      <div className={classes.searchSection}>
        <OutlinedInput
          id={"searchProducts "}
          placeholder={'Search Team'}
          value={this.state.query}
          onChange={(event) => {
            var me = this;
            this.setState({ query: event.target.value, page: 0, isDataLoading: true }, () => {
              me.fetchData();
            });
          }}
          startAdornment={
            <InputAdornment>
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment>
              {this.state.query.length > 0 && this.state.query !== '' && this.state.query !== null && <TooltipMsg placement="top" title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Clear</span>} arrow>
                <Clear className={classes.searchIconPop} onClick={this.handleClear} />
              </TooltipMsg>}
            </InputAdornment>
          }
          roundedCorner={true}
        />
      </div>
    );
  }

  handleEdit(team) {
    this.props.showUserGroupDialog(team)
  }

  handleDelete(team) {
    this.deleteOrgGroup(team)
  }

  handleChangePage(e, newPage) {
    this.setState({
      page: newPage,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleChangeRowsPerPage(e) {
    this.setState({
      page: 0,
      pageSize: e.target.value,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  getAppSpecificFlag(appName, key) {
    var result = false;
    if (this.props.settings != null) {
      var setting = this.props.settings.filter((item) => {
        if (item.SettingName === appName + key) {
          return true
        }
        return false
      });

      if (setting.length > 0) {
        result = setting[0]['Value'] === "YES"
      }
    }
    return result;
  }

  updateOrgGroup = (data) => {
    let obj = {}
    obj.id = data?.ID;
    obj.name = data?.Name;
    obj.email = data?.Email;
    obj.is_suppressed = false;
    obj.user_ids = data?.Users;
    obj.description = data?.Description;
    this.props.updateOrgGroup(obj);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  navigate = (menu) => {
    if (menu.link) {
      this.props.history.push(menu.link);
    } else if (menu.key === "addManually") {
      this.props.showUserGroupDialog();
    } else if (menu.key === "viaIntegration") {
      this.props.showViaIntegrationDialog();
    }
    this.handleClose();
  };

  render() {
    const classes = this.props.classes;
    const tableData = {
      class: classes.table,
      isProgress: this.props.fetchGroupProg,
      head: [
        // {
        //   label: "Id",
        //   key: "ID",
        //   type: "text",
        //   hide: true,
        //   headClass: classes.thead_ID,
        //   cellClass: classes.tbodyCell_ID,
        // },
        {
          label: "Name",
          key: "Name",
          type: "text",
          headClass: classes.thead,
          cellClass: classes.tbodyCell,
        },
        // {
        //   label: "Email",
        //   key: "Email",
        //   type: "text",
        //   headClass: classes.thead,
        //   cellClass: classes.tbodyCell,
        // },
        {
          label: "Description",
          key: "Description",
          type: "text",
          headClass: classes.thead,
          cellClass: classes.tbodyCell,
        },
        // {
        //   label: "Users",
        //   key: "Users",
        //   type: "text",
        //   headClass: classes.thead,
        //   cellClass: classes.tbodyCell,
        // },
      ],
    };

    const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "DisableCatalogFeature");
    if(!DisableVendor){
      tableData.head.push({
        label: "Vendors",
        key: "VendorsCount",
        type: "view",
        headClass: classes.thead,
        cellClass: classes.tbodyCell,
        actionItem:
        {
          action: "viewVendors",
        },
      })
    }

    tableData.head.push({
      label: "Products",
      key: "ProductsCount",
      type: "view",
      headClass: classes.thead,
      cellClass: classes.tbodyCell,
      actionItem:
      {
        action: "viewProducts",
      },
    })

    var syncGroupFlag = this.getAppSpecificFlag("Okta", "_SYNC_SELECTED_GROUP") || this.getAppSpecificFlag("Google", "_SYNC_SELECTED_GROUP");

    if (syncGroupFlag) {
      tableData.head.push({
        label: "Sync Groups",
        key: "SyncUserFlag",
        type: "select",
        headClass: classes.thead,
        cellClass: classes.tbodyCell,
        options: [
          {
            id: "YES",
            label: "Yes"
          },
          {
            id: "NO",
            label: "No"
          }
        ],
        showFunction: function (item) {
          return item.Source !== "ORG"
        }
      })
    }

    tableData.head.push({
      label: "Action",
      key: "ID",
      type: "action",
      headClass: classes.action,
      actionItems: [
        {
          label: "",
          type: "muiicon",
          html: <Image alt="Edit" src={editIcon} className={classes.edit} />,
          action: "edit",
        },
        {
          label: "",
          type: "muiicon",
          html: (
            <Image alt="Edit" className={classnames(classes.edit, classes.delete)} src={deleteIcon} />
          ),
          action: "delete",
        },
      ],
    })

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    const { page, pageSize, isDataLoading } = this.state;

    let tasks = this.props.groups || [];
    tasks = tasks.map((item) => {
      item.SyncUserFlag = item.SyncUser === true ? "YES" : "NO";
      return item;
    })

    let totalGroups = this.props.fetchGroupTotal;

    let rowData = tasks.map((list) => {
      return tableData.head.map((obj) => {
        return {
          ...obj,
          ...list,
          html: list[obj.key],
        };
      });
    });

    console.log(rowData, 'RowwwwData')


    const open = Boolean(this.state.anchorEl);
    const id = open ? "newTeamPopover" : undefined;
    const { newTeamOptions } = this.state;
    console.log(newTeamOptions, 'newTeamOptions')


    return <div className={classes.root}>
      <Backdrop
        className={classes.fullSceen}
        open={this.props.fetchGroupProg}
        transitionDuration={100}
      >
        <CircularProgress />
      </Backdrop>
      <div className={classes.userSection}>
        <p className={classes.userCount}>
          {totalGroups} Team
          {totalGroups > 1 ? "s" : ""} found
        </p>
        <div className={classes.search}>{this.searchInput(classes)}</div>
        <div>
          <Button className={classes.newUserBtn}
            onClick={this.handleClick}
          // onClick={() => { this.props.showUserGroupDialog() }}
          >
            + New Team
          </Button>

          <Popover
            className={classes.popover}
            id={id}
            open={open}
            anchorEl={this.state.anchorEl}
            onClose={() => this.handleClose()}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {newTeamOptions?.map((item, childIndex) => {
              return (
                <div key={childIndex}>
                  {" "}
                  <MenuItem
                    value={item.label}
                    className={classes.menuItem}
                    onClick={() => this.navigate(item)}
                  >
                    {item.label}
                  </MenuItem>{" "}
                  <Divider />
                </div>
              );
            })}
          </Popover>
        </div>
      </div>
      {false && <div style={{ display: "flex", alignItems: "center", margin: '10px 0px' }}>
        <Checkbox
          label={"From Integration"}
          color="primary"
          checked={this.state.Suppressed}
          onChange={(e) => {
            this.setState({ Suppressed: e.target.checked, isDataLoading: true }, () => {
              this.fetchData();
            });
          }}
        />
        <Typography>From Integration</Typography>
      </div>}

      <Paper style={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer style={{ maxHeight: '65vh' }}>
          <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
            <TableHead>
              <TableRow>
                {tableData?.head?.map((e, index) => {
                  if (e.type === 'action') {
                    return <TableCell align="center" className={classes.nameCol}>{e.label}</TableCell>
                  }else if(e.key === "Description"){
                    return <TableCell style={{ width: "30%" }} className={classes.nameCol}>{e.label}</TableCell>
                  }
                  return <TableCell style={{ minWidth: e.key === 'Email' ? '200px' : '' }} className={classes.nameCol}>{e.label}</TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isDataLoading && rowData.slice(page * pageSize, page * pageSize + pageSize).map((team, parentIndex) => {
                return <TableRow key={'task_' + team.ID}>
                  {team.map((item, childIndex) => {
                    return <TableCell key={parentIndex + childIndex} className={classnames(classes.cell, item.cellClass)}>
                      {item?.type === 'text' && <span>
                        {item?.html}
                      </span>}
                      {item?.type === 'view' && <span>
                        <span onClick={(event) => {
                          if(item.key=== "ProductsCount"){
                            this.props.showTeamProductsDialog(item)
                          }else {
                            this.props.showTeamVendorsDialog(item)
                          }
                        }}>
                          <Chip
                            icon={<VisibilityIcon style={{ marginLeft: '5px' }} />}
                            label={<div className={classes.mtop1}>{item?.html}</div>}
                            size="small"
                            clickable
                            color="primary"
                            variant="outlined"
                          />
                        </span>
                      </span>}

                      {item?.type === 'checkbox' && <span>
                        <Checkbox
                          checked={item?.html}
                          checkedIcon={<span className={classnames(classes.icon, classes.checkedIcon)} />}
                          onChange={(event) => { }}
                          icon={<span className={classes.icon} />}
                        />
                      </span>}

                      {item?.type === 'select' &&
                        <>
                          {(item?.showFunction === undefined || item?.showFunction(tasks[parentIndex])) && <FormControl variant="standard" className={classes.formControl}>
                            <Select
                              value={item?.html}
                              name={`select_${parentIndex}_${childIndex}`}
                              className={classes.selectEmpty}
                              onChange={(event) => {
                                this.handleUpdateSyncFlag(event, item);
                              }}
                            >
                              {item.options?.map((option, childIndex) => {
                                return (<MenuItem key={childIndex} className={classes.menuItem} value={option.id}>{option.label}</MenuItem>)
                              })
                              }
                            </Select>
                          </FormControl>}
                          {item?.showFunction !== undefined && !item?.showFunction(tasks[parentIndex]) &&
                            <Typography> -</Typography>
                          }
                        </>
                      }

                      {
                        item?.type === 'action' && !this.state.Suppressed && item?.actionItems?.map((actionItem, index) => {
                          console.log(actionItem, 'actionItem')
                          if (actionItem.action === 'edit') {
                            return < IconButton onClick={() => this.handleEdit(item)}>
                              <Image src={editIcon} />
                            </IconButton>
                          }
                          if (actionItem.action === 'delete') {
                            return < IconButton onClick={() => this.handleDelete(item)}>
                              <Image src={deleteIcon} />
                            </IconButton>
                          }
                        })}
                      {item?.type === 'action' && this.state.Suppressed && <SqureButton
                        style={{ display: 'block', margin: '0 auto' }}
                        variant={"contained"}
                        disabled={this.props.updateGroupProg}
                        onClick={() => {
                          this.updateOrgGroup(item)
                        }}
                      >
                        Move to main view
                      </SqureButton>}
                    </TableCell>
                  })}
                </TableRow>
                // return <TableRow key={'task_' + user.ID}>
                //   <TableCell className={classes.statusCol}>{user.ID}</TableCell>
                //   <TableCell className={classes.statusCol}>{user.Name}</TableCell>
                //   <TableCell className={classes.statusCol}>{user.Email}</TableCell>
                //   <TableCell className={classes.statusCol}>{user.TeamText}</TableCell>
                //   <TableCell className={classes.statusCol}>{user.Role}</TableCell>
                //   <TableCell className={classes.statusCol}>{user.Title}</TableCell>
                //   <TableCell className={classes.actionCol}>
                //     <IconButton
                //       onClick={() => this.handleEdit(user)}
                //     >
                //       <Image
                //         src={editIcon}
                //       />
                //     </IconButton>

                //     <IconButton
                //       onClick={() => this.handleDelete(user)}
                //     >
                //       <Image
                //         src={deleteIcon}
                //       />
                //     </IconButton>

                //   </TableCell>
                // </TableRow>
              })}

              {(!isDataLoading && (tasks === undefined || tasks === null || tasks.length <= 0)) && <TableRow>
                <TableCell colSpan={7} style={{ textAlign: 'center' }} className={classes.nameCol}>
                  No Teams
                </TableCell>
              </TableRow>}

              {isDataLoading && <TableRow>
                <TableCell colSpan={7} style={{ textAlign: 'center' }} className={classes.nameCol}>
                  <CircularProgress />
                </TableCell>
              </TableRow>}

            </TableBody>
          </Table>
        </TableContainer >
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={totalGroups || 0}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper >

      {
        false && <div className={classes.tableSection} id="scrollableDiv">
          <InfiniteScroll
            dataLength={this.props.fetchGroupTotal}
            next={() => this.nextFetchData()}
            style={{ display: "flex", flexDirection: "column" }} //To put endMessage and loader to the top.
            hasMore={true}
            scrollableTarget="scrollableDiv"
          >
            <CommonTable
              tableData={tableData}
              bodyData={tasks}
              parentClickEvent={this.clickOnCell}
            />
          </InfiniteScroll>
          {this.props.fetchGroupProg === true && (
            <div className={classes.backdrop}>
              <CircularProgress />
            </div>
          )}
        </div>
      }
      < TeamProductShowDialog />
      < TeamVendorShowDialog />
      {this.props.viaIntegrationDialogOpen && <TeamsViaIntegrationDialog />}
      {
        this.props.addUserGroupDialog === true &&
        <OrgUserGroupDialog />
      }
      < MessageDialog messageData={messageData} callBack={this.callBack} />
    </div >
  }
}

export default connector(compose(withStyles(styles))(UsersGroups));