/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import { CircularProgress, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { createStyles, withStyles } from "@material-ui/core/styles";
// Icons
import ArrowForward from "assets/images/arrow_forward_white.svg";
import AddUpdateTaskDialog from "Components/Common/Dialogs/AddUpdateTaskDialog";
import * as Validator from "util/Validation";
// Assets
import classnames from "classnames";
import BottomAction from "Components/Application/Components/Evaluate/Components/Evaluation/Common/BottomAction.jsx";
// Components
import Image from "Components/Common/image.jsx";
import SqureButton from "Components/Common/SqureButton";
import SearchUserAndTeamPoper from "Components/Common/TeamsAndUser/SearchUserAndTeamPoper";
import SearchUserPoper from "Components/Common/UserGroup/SearchUserPoper";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { toggleEvaluateNeverReviewed, toggleEvaluateView } from "redux/applications/action";
import {
  fetchEvaluationApprovalDetails,
  fetchProductApprovalDetails,
  resetAddApprovalForCriteria,
  fetchApprovalCriteria,
  addApprovalCriteriaApprover,
  addApprovalCriteria,
} from "redux/evaluation/approval/action";
import { fetchCriteria, fetchScores } from "redux/evaluation/criteria/action";
import { showEvaluationFinishDialog, showSendNotificationDialog, updateEvaluationInfo, updateEvaluationProduct, deleteEvaluationApprovals, updateApprovalInfo } from "redux/evaluation/metadata/action";
import { fetchAllOrgSettings } from "redux/org/user/action";
import { hideEvaluationCollaboratorDialog } from "redux/evaluate/action";
// Redux
import { showSnackBar } from "redux/snackbar/action";

import { addWorkflowComponentUserResponse } from "redux/workflow/action";

import { fetchEvaluationCart } from "redux/evaluation/cart/action";
import { getNextComponent, getPreviousComponent } from "util/Common";
// import WorkflowSummary from "../WorkFlow/Components/Summary/index";
import ApprovalAction from "./ApprovalAction";
import ProductDetails from "./ProductDetails";
import ApprovalCriteriaThread from "./ApprovalCriteriaThread";
import { approvalStages } from "util/Workflow";

import {
  showConfirmCallbackDialog,
  hideConfirmCallbackDialog
} from "redux/applications/action";

import ConfirmCallbackDialog from "Components/Common/ConfirmCallbackDialog";
import { fetchEvaluationMetaDataForSummary,fetchWorkflowComponentDetails } from "redux/workflow/summary/action";

const connectedProps = (state) => ({
  orguser: state.orgUser,
  user: state.authUser.user,
  evaluation: state.workflowSummary.evaluation,
  evaluationFetchProgress: state.workflowSummary.fetchProgress,
  criterias: state.evaludationCriteria.criterias,
  deleteCriteriaProgress: state.evaludationCriteria.deleteCriteriaProgress,
  errorCriteriaDelete: state.evaludationCriteria.errorCriteriaDelete,
  scores: state.evaludationCriteria.scores,
  productApprovalDetails: state.evaluationApproval.productApprovalDetails,
  productApprovalDetailsInProgress: state.evaluationApproval.productApprovalDetailsInProgress,
  addApprovalForCriteria: state.evaluationApproval.addApprovalForCriteria,
  addApprovalForCriteriaSuccess: state.evaluationApproval.addApprovalForCriteriaSuccess,

  updateProgress: state.evaluationMetaData.updateProgress,
  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
  collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  workflowComponentDetail: state.workflowSummary.workflowComponentDetail,
  fetchworkflowComponentDetailProgress: state.workflowSummary.fetchworkflowComponentDetailProgress,
  userTimeline: state.workflow.userTimeline,

  approvalCriterias: state.evaluationApproval.approvalCriterias,
  fetchApprovalCriteriasProgress: state.evaluationApproval.fetchApprovalCriteriasProgress,
  fetchApprovalCriteriasError: state.evaluationApproval.fetchApprovalCriteriasError,
  addApprovalCriteriaApproverProgress: state.evaluationApproval.addApprovalCriteriaApproverProgress,
  addApprovalCriteriaError: state.evaluationApproval.addApprovalCriteriaError,
  addApprovalCriteriaProgress: state.evaluationApproval.addApprovalCriteriaProgress,

  updateApprovalCriteriaError: state.evaluationApproval.updateApprovalCriteriaError,
  updateApprovalCriteriaProgress: state.evaluationApproval.updateApprovalCriteriaProgress,
  skipApprovalCriteriaError: state.evaluationApproval.skipApprovalCriteriaError,
  skipApprovalCriteriaProgress: state.evaluationApproval.skipApprovalCriteriaProgress,
  deleteApprovalCriteriaError: state.evaluationApproval.deleteApprovalCriteriaError,
  deleteApprovalCriteriaProgress: state.evaluationApproval.deleteApprovalCriteriaProgress,

  addApprovalCriteriaApproverError: state.evaluationApproval.addApprovalCriteriaApproverError,
  isOpenTaskDialog: state.productTasks.showAddTaskDialog,
  evalInfoUpdateProgress: state.evaluationMetaData.evalInfoUpdateProgress,
  evalInfoUpdateError: state.evaluationMetaData.evalInfoUpdateError,
  deleteApprovalsProgress: state.evaluationMetaData.deleteApprovalsProgress,
  deleteApprovalsError: state.evaluationMetaData.deleteApprovalsError,
  fetchWokflowUserTimelineProgress: state.workflow.fetchWokflowUserTimelineProgress,

  evalApprovalInfoUpdateProgress: state.evaluationMetaData.evalApprovalInfoUpdateProgress,
  evalApprovalInfoUpdateError: state.evaluationMetaData.evalApprovalInfoUpdateError,
  evalApprovalInfo: state.evaluationMetaData.evalApprovalInfo,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchEvaluationMetaDataForSummary: fetchEvaluationMetaDataForSummary,
  fetchProductApprovalDetails: fetchProductApprovalDetails,
  fetchEvaluationApprovalDetails: fetchEvaluationApprovalDetails,
  resetAddApprovalForCriteria: resetAddApprovalForCriteria,
  fetchScores: fetchScores,
  fetchCriteria: fetchCriteria,
  fetchApprovalCriteria: fetchApprovalCriteria,
  toggleEvaluateView: toggleEvaluateView,
  toggleEvaluateNeverReviewed: toggleEvaluateNeverReviewed,
  showSendNotificationDialog: showSendNotificationDialog,
  fetchAllOrgSettings: fetchAllOrgSettings,
  showEvaluationFinishDialog: showEvaluationFinishDialog,
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  fetchEvaluationCart: fetchEvaluationCart,
  fetchWorkflowComponentDetails: fetchWorkflowComponentDetails,
  addApprovalCriteria: addApprovalCriteria,
  addApprovalCriteriaApprover: addApprovalCriteriaApprover,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
  updateEvaluationInfo: updateEvaluationInfo,
  updateEvaluationProduct: updateEvaluationProduct,
  showConfirmCallbackDialog: showConfirmCallbackDialog,
  hideConfirmCallbackDialog: hideConfirmCallbackDialog,
  deleteEvaluationApprovals: deleteEvaluationApprovals,
  updateApprovalInfo: updateApprovalInfo
};
var connector = connect(connectedProps, connectionActions);

// Styles
const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5, 10),
      background: '#f1f1f1'
    },
    copyGoesHere: {
      textAlign: "left",
      color: "#282D30",
      fontSize: 15,
      fontWeight: 400,
    },
    loader: {
      textAlign: 'center',
      display: 'block'
    },
    headTitle: {
      fontSize: 19,
      fontWeight: 500,
    },
    details: {
      display: "block",
      background: "#f7f7f7",
    },
    topHead: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      background: '#fff',
      padding: 5,
      borderRadius: 4,
      border: '1px solid #3f4e6b29'
    },
    titleName: {
      flex: 1
    },
    withdrawBtn: {
      textAlign: 'right'
    }
  });

class GetApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product_id: '',
      evaluation_id: this.props.evaluation_id,
      component_id: this.props.componentId,
      workflow_id: this.props.workflow_id,
      activeCriteria: null,
      activeResponse: null,
      approvalInit: true,
      isWorkFlow: false,
      nextClickLoader: false,
      expanded: "approvels",
      previousComponentData: null,
      approvalStages: [],
      approvedProduct: ''
    };

    this.withdrawApprovals = this.withdrawApprovals.bind(this)
    this.callBack = this.callBack.bind(this);
    this.onCancelWithdraw = this.onCancelWithdraw.bind(this);
  }

  componentDidMount() {
    const { evaluation_id, product_id, component_id } = this.state;
    this.props.fetchEvaluationMetaDataForSummary(evaluation_id);
    this.props.fetchApprovalCriteria(evaluation_id, component_id);
    this.props.fetchScores(evaluation_id);
    this.props.fetchAllOrgSettings();
    setTimeout(() => {
      this.props.fetchEvaluationCart();
    }, 1000);
  }

  getInitialDetails() {
    const { evaluation_id, component_id, workflow_id } = this.state;
    const { evaluation } = this.props;

    let approvedProduct;

    if (evaluation.Products.length === 1) {
      approvedProduct = evaluation.Products[0]
    } else {
      approvedProduct = evaluation.Products.find((product) => product.IsApproved);
    }

    if (approvedProduct === undefined) {
      return;
    }
    let product_id = approvedProduct?.ID;

    this.setState({
      isWorkFlow: true,
      product_id: product_id,
      approvedProduct: approvedProduct
    }, () => {
      this.props.fetchWorkflowComponentDetails(workflow_id, component_id, evaluation_id);
      this.props.fetchProductApprovalDetails(evaluation_id, product_id, component_id);
    });
  }

  componentDidUpdate(prevProps) {
    const { evaluation_id, product_id } = this.state;

    if (!this.props.addApprovalCriteriaApproverProgress && prevProps.addApprovalCriteriaApproverProgress) {
      if (this.props.addApprovalCriteriaApproverError) {
        this.props.showSnackBar("Failed to add User", "error", 3000);
      } else {
        this.props.showSnackBar("Users updated successfully", "success", 3000);
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.state.component_id);
      }
    }
    if (!this.props.deleteCriteriaProgress && prevProps.deleteCriteriaProgress) {
      if (this.props.deleteCriteriaError) {
        this.props.showSnackBar("Failed to delete criteria", "error", 3000);
      } else {
        this.props.showSnackBar("criteria deleted successfully", "success", 3000);
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.state.component_id);
      }
    }


    if (this.props.addApprovalCriteriaProgress === false && prevProps.addApprovalCriteriaProgress === true) {
      if (this.props.addApprovalCriteriaError == null) {
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.state.component_id);
      }
    }

    if (this.props.updateApprovalCriteriaProgress === false && prevProps.updateApprovalCriteriaProgress === true) {
      if (this.props.updateApprovalCriteriaError == null) {
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.state.component_id);
      }
    }

    if (this.props.skipApprovalCriteriaProgress === false && prevProps.skipApprovalCriteriaProgress === true) {
      if (this.props.skipApprovalCriteriaError == null) {
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.state.component_id);
      } else {
        this.props.showSnackBar("Failed to skip criteria", "error", 3000);
      }
    }

    if (this.props.deleteApprovalCriteriaProgress === false && prevProps.deleteApprovalCriteriaProgress === true) {
      if (this.props.deleteApprovalCriteriaError == null) {
        this.props.fetchApprovalCriteria(this.state.evaluation_id, this.state.component_id);
      }
    }

    if (prevProps.fetchworkflowComponentDetailProgress && !this.props.fetchworkflowComponentDetailProgress && !this.props.fetchApprovalCriteriasError) {
      if (this.props.approvalCriterias && this.props.approvalCriterias.Response && this.props.approvalCriterias.Response.length === 0) {
        if (this.props.workflowComponentDetail?.OrgWorkflowComponentConfigurations?.length) {
          let componentConfig = this.props.workflowComponentDetail?.OrgWorkflowComponentConfigurations.filter(o => o.Form.Name === 'evaluation_approval')[0];
          // let componentConfig = this.props.workflowComponentDetail?.OrgWorkflowComponentConfigurations[0];
          let parseCompCriterias = Validator.isJson(componentConfig.Value) ? JSON.parse(componentConfig.Value) : [];
          let criterias = [];

          parseCompCriterias.forEach((criteria) => {
            let users = [];
            let teams = [];
            let rules = [];

            if (criteria.rules) {
              rules = criteria.rules;
            }

            if (criteria.approvers.users) {
              criteria.approvers.users.forEach((data) => {
                users.push(data.ID);
              });
              criteria.approvers.teams.forEach((data) => {
                teams.push(data.ID);
              });
            } else {
              users = criteria.approvers.map((c) => {
                return c.ID;
              });
            }

            criterias.push({
              stakeHolder: criteria.name,
              criteriaID: criteria.criteriaId || 0,
              configID: String(criteria.id) || "",
              criteriaType: "",
              approvers: {
                approver_ids: users,
                team_ids: teams,
                productID: Number(this.state.product_id),
              },
              rules: rules,
              componentId: Number(this.state.component_id),
            });
          });
          let data = { criterias };
          this.props.addApprovalCriteria(this.state.evaluation_id, data);
        }
      }
      this.updateApprovalStages();
    }

    if (prevProps.productApprovalDetailsInProgress && !this.props.productApprovalDetailsInProgress) {
      this.initActiveCriteria();
    }


    if (!this.props.fetchApprovalCriteriasProgress && prevProps.fetchApprovalCriteriasProgress) {
      this.setState({
        isFetchedCriterias: true
      }, () => {
        this.initActiveCriteria();
      })
    }

    if (this.props.addApprovalForCriteriaSuccess == true) {
      // alert message
      this.props.showSnackBar("Status Updated", "success", 3000);

      // reset old state
      this.props.resetAddApprovalForCriteria();
      // fetch approval details
      this.props.fetchProductApprovalDetails(evaluation_id, product_id, this.state.component_id);
      this.props.fetchEvaluationApprovalDetails(evaluation_id, this.state.component_id);
      this.props.fetchApprovalCriteria(evaluation_id, this.state.component_id);
    }

    if (prevProps.addApprovalCriteriaApproverProgress && !this.props.addApprovalCriteriaApproverProgress) {
      this.props.hideEvaluationCollaboratorDialog();
    }

    if (this.props.evaluationFetchProgress === false && prevProps.evaluationFetchProgress === true) {
      this.setReview();
      this.getInitialDetails();
    }

    if (this.props.updateProgress == false && prevProps.updateProgress == true) {
      if (this.props.updateError == null) {
        this.setReview();
      }
    }

    if (this.props.addComponentUserResponseProgress === false && prevProps.addComponentUserResponseProgress === true) {
      this.props.history.push("/app/evaluate/" + this.state.evaluation_id + "/workflow/interim");
    }

    if (!this.props.evalApprovalInfoUpdateProgress && prevProps.evalApprovalInfoUpdateProgress) {
      if (this.props.evalApprovalInfoUpdateError === null) {
        if (this.state.previousComponentData !== null) {
          this.props.history.push("/app/evaluate/" + this.state.previousComponentData?.EvaluationId + "/workflow/" + this.state.previousComponentData?.WorkflowId + "/component/" + this.state.previousComponentData?.ComponentId);
        } else {
          this.props.history.push("/app/evaluate/" + this.state.evaluation_id + "/workflow/" + this.state.workflow_id + "/component/" + this.state.component_id);
        }
      } else {
        // this.props.showSnackBar(
        //   "Failed to update evaluation.",
        //   "error",
        //   3000
        // );
      }
    }


    if (!this.props.deleteApprovalsProgress && prevProps.deleteApprovalsProgress) {
      if (this.props.deleteApprovalsError === null) {
        // this.props.updateEvaluationInfo(this.state.evaluation_id, {
        //   approvalStarted: false
        // })
        this.props.updateApprovalInfo(this.state.evaluation_id, {
          type: 'withdraw',
          approval_component_id: parseInt(this.state.component_id)
        })
      } else {
        this.props.showSnackBar(
          "Failed to remove evaluation approvals.",
          "error",
          3000
        );
      }
    }


  }

  updateApprovalStages() {
    const { workflowComponentDetail } = this.props;
    let finalStages = []
    if (workflowComponentDetail?.OrgWorkflowComponentConfigurations?.length) {
      let stageConfig = this.props.workflowComponentDetail?.OrgWorkflowComponentConfigurations.filter(o => o.Form.Name === 'approval_stages')[0];
      if (stageConfig !== undefined && stageConfig !== null && stageConfig !== '') {
        let stages = Validator.isJson(stageConfig.Value) ? JSON.parse(stageConfig.Value) : [];
        finalStages = stages;
      } else {
        finalStages = approvalStages;
      }
    } else {
      finalStages = approvalStages;
    }
    this.setState({
      approvalStages: finalStages
    })
  }


  initActiveCriteria = () => {
    let respondedCriterias = this.props.productApprovalDetails || [];
    let respondedCriteriasIds = respondedCriterias.map((O) => O.CriteriaID);
    let yourResponse = this.props.approvalCriterias?.Response || [];
    let nextCriteria = yourResponse[0];
    this.setActiveCriteria(nextCriteria);

  }

  getDifference = (array1, array2) => {
    return array1.find((object1) => {
      return !array2.some((object2) => {
        return object1.ID === object2.ID;
      });
    });
  };


  componentWillUnmount() {
    try {
      this.props.toggleEvaluateView(false, null);
      this.props.toggleEvaluateNeverReviewed(false, null);
      document.body.classList.remove("evaluate-view");
    } catch (e) { }
  }

  setReview = () => {
    try {
      if (this.props.evaluation?.Status === 4 && this.props.evaluation?.Type === 2 && moment(new Date()).diff(moment(this.props.evaluation?.EvaluationUpdatedAt), "days") > 30) {
        document.body.classList.add("evaluate-view");
        this.props.toggleEvaluateView(true, this.props.evaluation?.Products[0]?.ID);
      } else if (
        (this.props.evaluation?.Status <= 3 || (this.props.evaluation?.Status === 4 && moment(new Date()).diff(moment(this.props.evaluation?.EvaluationUpdatedAt), "days") <= 30)) &&
        this.props.evaluation?.Type === 2
      ) {
        this.props.toggleEvaluateNeverReviewed(true, this.props.evaluation?.Products[0]?.ID);
      }
    } catch (e) { }
  };

  setResetStats = () => {
    this.setState({
      activeCriteria: null,
      activeResponse: null,
    });
  };

  setActiveCriteria = (criteria) => {
    this.setState({
      activeCriteria: criteria,
    });
  };

  setActiveResponse = (value) => {
    this.setState({
      activeResponse: value,
    });
  };

  submitResponse() {
    this.setState(
      {
        nextClickLoader: true,
      },
      () => {
        var nextComponent = getNextComponent(this.props.userTimeline, this.state.component_id);
        if (nextComponent !== null) {
          let redirectPath = `/app/evaluate/${nextComponent.EvaluationId}/workflow/${nextComponent.WorkflowId}/component/${nextComponent.ComponentId}`;
          this.props.history.push(redirectPath);
          return;
        } else {
          var responseDatas = [];
          this.props.addWorkflowComponentUserResponse(this.state.evaluation_id, this.state.component_id, {
            data: [],
            forward: true,
          });
        }
      }
    );
  }

  handleChange = (panel) => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  withdrawApprovals() {
    this.props.updateEvaluationProduct(this.state.evaluation_id, this.state.product_id, { is_approved: false });

    this.props.deleteEvaluationApprovals(this.state.evaluation_id, this.state.component_id)

    this.props.hideConfirmCallbackDialog();
  }

  callBack() {
    this.withdrawApprovals();
  }

  onCancelWithdraw() {
    this.props.hideConfirmCallbackDialog();
  }

  showWithdrawWarningDialog(type) {
    this.props.showConfirmCallbackDialog();
  };

  render() {
    const classes = this.props.classes;

    const { evaluation, user } = this.props;
    if (evaluation === null) {
      return (
        <span className={classes.loader}>
          <CircularProgress />
        </span>
      );
    }

    if (this.props.fetchworkflowComponentDetailProgress || this.props.fetchApprovalCriteriasProgress || this.props.addApprovalCriteriaProgress) {
      return (
        <span className={classes.loader}>
          <CircularProgress />
        </span>
      );
    }

    const products = this.props.evaluation?.Products.length ? this.props.evaluation?.Products : [];
    // find top ranker
    let high_score = 0;
    let topper = null;

    let adminForward = false;
    var isCreatorOrAdmin = false;
    var role = user?.Role;
    if (this.state.isWorkFlow) {
      adminForward =
        this.props.workflowComponentDetail?.AdminForward !== undefined && this.props.workflowComponentDetail?.AdminForward !== null && this.props.workflowComponentDetail?.AdminForward === true
          ? true
          : false;
    }

    if (evaluation?.UserID === user?.ID) {
      isCreatorOrAdmin = true;
    }

    if (user?.Role === "OrgAdmin") {
      isCreatorOrAdmin = true;
    }

    if (products.length) {
      for (let product of products) {
        product.score = 0;
        let product_score = 0;
        if (this.props.scores[product.ID] !== undefined) {
          var score_obj = this.props.scores[product.ID];
          var totalOpted = 0;
          var totalMax = 0;
          for (var criteriId in score_obj) {
            var criteriScore = score_obj[criteriId];
            totalOpted += criteriScore.totalOpted;
            totalMax += criteriScore.totalMax;
          }
          if (totalMax !== 0) {
            product_score = Math.round(parseFloat((parseFloat(totalOpted) / parseFloat(totalMax)) * 100));
            product.score = product_score;
          }
        }
        if (product_score > high_score && product_score > 0) {
          high_score = product_score;
          topper = product.ID;
        }
      }
    }

    // filter current product
    const findProduct = products.filter((item) => item.ID === parseInt(this.state.product_id));
    const product = findProduct.length ? findProduct[0] : {};

    let allCriteria = this.props.approvalCriterias?.Response || [];
    let requiredCriteria = allCriteria.filter((item) => item.Status === 1);
    let responedIds = this.props.productApprovalDetails ? this.props.productApprovalDetails.map((item) => item.CriteriaID) : [];
    let disableCriterias = allCriteria.filter((item) => item.Status === 2);
    let isDisable = false;
    if (disableCriterias.length > 0) {
      isDisable = true;
    }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to withdraw? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <>
        <div className={classes.root}>
          <div className={classes.mainRoot}>
            <Typography variant="h5" className={classes.headTitle}>Approvals</Typography>

            <Grid container justify="flex-end" style={{ marginBottom: 10 }}>
              {false && (
                <SqureButton
                  variant="outlined"
                  onClick={() => {
                    const { history, match } = this.props;
                    if (match && match.params && match.params.evaluation_id) {
                      history.push(`/app/evaluate/${match.params.evaluation_id}/report`);
                    }
                  }}
                >
                  View Report
                </SqureButton>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <ProductDetails
                  component_id={this.state.component_id}
                  evaluation_id={this.state.evaluation_id}
                  product_id={this.state.product_id}
                  product={product}
                  topper={topper}
                  approvalCriterias={this.props.approvalCriterias}
                  activeCriteria={this.state.activeCriteria}
                  setActiveCriteria={this.setActiveCriteria}
                  setActiveResponse={this.setActiveResponse}
                  addApprovalCriteriaApprover={this.props.addApprovalCriteriaApprover}
                  addRemoveApproverFn={(users, criteriaId) => {
                    let approver_ids = [];
                    let team_ids = [];
                    users.forEach((data) => {
                      if (data.hasOwnProperty("GroupId")) {
                        team_ids.push(data.ID);
                      } else {
                        approver_ids.push(data.ID);
                      }
                    });
                    this.props.addApprovalCriteriaApprover(this.state.evaluation_id, criteriaId, { approver_ids, team_ids, productID: Number(this.state.product_id) });
                  }}
                  addApproverProgress={this.props.addApprovalCriteriaApproverProgress}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={7} lg={8}>
                {this.state.activeCriteria && (
                  <ApprovalAction
                    product={product}
                    component_id={this.state.component_id}
                    evaluation_id={this.state.evaluation_id}
                    product_id={this.state.product_id}
                    approvalCriterias={this.props.approvalCriterias}
                    activeCriteria={this.state.activeCriteria}
                    activeResponse={this.state.activeResponse}
                    setResetStats={this.setResetStats}
                    setActiveResponse={this.setActiveResponse}
                    approvalStages={this.state.approvalStages}
                  />
                )}
                <ApprovalCriteriaThread
                  evaluationId={this.state.evaluation_id}
                  componentId={this.state.component_id}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}
export default connector(compose(withStyles(styles))(GetApproval));
