import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import ForgotPasswordContainer from "./Common/ForgotPasswordContainer";
// import ArrowBack from "../assets/images/back_arrow.svg";
import { withRouter } from 'react-router-dom';
import ForgotpassImage from "../assets/images/forgotpass.png";
import { Typography, Button, CircularProgress } from '@material-ui/core';
import OutlinedInput from "./Common/Input/OutlinedInput";
import { verifyAccount, verifyToken } from "../redux/verifyaccount/action";
import Alert from '@material-ui/lab/Alert';
import queryString from "query-string";

// Components
import Image from 'Components/Common/image.jsx'

const connectedProps = (state) => ({
    isProgress: state.accountVerify.isProgress,
    error: state.accountVerify.error,
    success: state.accountVerify.success,
    verifyTokenProgress: state.accountVerify.verifyTokenProgress,
    verifyTokenError: state.accountVerify.verifyTokenError,
    verifyTokenSuccess: state.accountVerify.verifyTokenSuccess,
});

const connectionActions = {
    verifyAccount: verifyAccount,
    verifyToken: verifyToken
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        margin: "0 auto",
    },
    backImage: {
        cursor: "pointer",
        width: theme.spacing(5)
    },
    titleImage: {
        width: "60%"
    },
    content: {
        width: "40%",
        margin: "0 auto",
        textAlign: "center",
        paddingTop: theme.spacing(8)
    },
    heading: {
        marginTop: theme.spacing(1),
        fontWeight: 900,
        color: "#333333",
        fontSize: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    sendBtn: {
        padding: theme.spacing(0.9, 10),
        fontSize: theme.spacing(2.2),
        fontWeight: 500
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    action: {
        marginTop: theme.spacing(4),
    },
    alert: {
        marginBottom: theme.spacing(1)
    }

});

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'password': "",
            'confirmPassword': "",
            "success": "",
            "error": "",
            queryParams: queryString.parse(this.props.location.search),
            evaluationId: null,
            isLoader: false
        }

        this.verifyUser = this.verifyUser.bind(this);
        this.clearError = this.clearError.bind(this);
    }

    componentDidMount() {
        // console.log(this.state.queryParams, 'queryParams')
        if (this.state.queryParams && this.state.queryParams.evaluation_id !== undefined && this.state.queryParams.evaluation_id !== null) {
            this.setState({
                evaluationId: this.state.queryParams.evaluation_id,
                isLoader: true
            }, () => {
                this.props.verifyToken({
                    'token': this.props.match.params.verifyToken
                });
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isProgress === false && prevProps.isProgress === true) {
            if (this.props.error !== null) {
                var error = this.props.error;
                if (error.status === 404 || error.status === 401) {
                    this.setState({ error: "Invalid URL to reset the Password." });
                } else {
                    this.setState({ error: error.message });
                }
            } else {
                this.props.history.push("/password-update/confirm")
            }
        }

        if (this.props.verifyTokenProgress === false && prevProps.verifyTokenProgress === true) {
            if (this.props.verifyTokenError === null) {
                // console.log(this.props.verifyTokenSuccess,'verifyTokenSuccess')
                if (this.props.verifyTokenSuccess && this.props.verifyTokenSuccess?.email_verified === false) {
                    this.props.history.push("/app/evaluate/" + this.state.evaluationId)
                }
            } else {
                console.log(this.props.verifyTokenError, 'verifyTokenError')
            }
            this.setState({
                isLoader: false
            })
        }
    }

    clearError() {
        this.setState({
            success: "",
            error: "",
        });
    }

    verifyUser() {
        var me = this;
        me.clearError();
        var password = me.state.password;
        if (password == null || String(password).trim().length === 0) {
            me.setState({ error: "Please enter password." });
            return;
        }

        if (password == null || String(password).trim().length < 8) {
            me.setState({ error: "Password should contain min of 8 chracters." });
            return;
        }

        var confirmPassword = me.state.confirmPassword;
        if (confirmPassword !== password) {
            me.setState({ error: "Password does not match." });
            return;
        }

        this.props.verifyAccount(this.props.match.params.verifyToken, confirmPassword);
    }

    render() {
        const classes = this.props.classes;

        if (this.state.isLoader) {
            return <div style={{ textAlign: 'center', marginTop: 20 }}>
                <CircularProgress />
            </div>
        }

        return <ForgotPasswordContainer>
            <div className={classes.root}>
                <div className={classes.content}>
                    <Image
                        src={ForgotpassImage}
                        alt="forgotpassword_image"
                        className={classes.titleImage}
                    />
                    <Typography variant={"h6"} className={classes.heading}>Create your Expent password</Typography>
                    <div className={classes.alert}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                        {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                    </div>
                    <OutlinedInput
                        label="Enter new password"
                        type="password"
                        passwordStenghtMeter={true}
                        value={this.state.password}
                        onChange={(event) => {
                            this.setState({ password: event.target.value });
                        }}
                    />
                    <OutlinedInput
                        label="Re-Enter new password"
                        type="password"
                        value={this.state.confirmPassword}
                        onChange={(event) => {
                            this.setState({ confirmPassword: event.target.value });
                        }}
                    />
                    <div className={classes.action}>
                        {!this.props.isProgress && <Button variant="contained" color="secondary" className={classes.sendBtn} onClick={this.verifyUser}>Set Password</Button>}
                        {this.props.isProgress && <CircularProgress />}

                    </div>
                </div>
            </div>
        </ForgotPasswordContainer>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ResetPassword));