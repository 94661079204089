import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import RefreshIcon from '@material-ui/icons/Refresh';
import { EditOutlined as EditIcon } from "@material-ui/icons";
import classnames from "classnames";
import { Paper, CircularProgress, Typography, Button, Grid, IconButton } from '@material-ui/core';
import CommonCss from 'commonCss';
import HistoryIcon from '@material-ui/icons/History';
import Image from 'Components/Common/image.jsx';
import _ from "underscore";
import {
    showApplicationGroupDialog, hideApplicationGroupDialog, showSyncHistoryDialog,
    showApplicationUserDialog, hideApplicationUserDialog, showGroupUserDialog, hideGroupUserDialog,
    showApplicationFetchInput, fetchAppConfigById, SyncApplication, fetchApplicationSyncStatus,
    showEditSyncPreferenceDialog
} from "redux/applications/action";
import { showSnackBar } from "redux/snackbar/action";
import { withRouter } from 'react-router';
import AppBarV2 from 'Components/Application/AppBarV2';
import AppIntegrationFormDialog from 'Components/Common/Apps/AppIntegrationFormDialog';
import SyncDetails from './SyncDetails';
import IntegrationGroupUserDialog from "Components/Common/Dialogs/IntegrationGroupUserDialog";
import IntegrationApplicationGroupDialog from "Components/Common/Dialogs/IntegrationApplicationGroupDialog";
import IntegrationApplicationUserDialog from "Components/Common/Dialogs/IntegrationApplicationUserDialog";
import SyncHistoryDialog from "./Dialogs/SyncHistoryDialog";
import EditSyncPreferenceDialog from './Dialogs/EditSyncPreferenceDialog';

const connectedProps = (state) => ({
    appConfigDetails: state.applications.appConfigDetails,
    appConfigDetailsProgress: state.applications.appConfigDetailsProgress,
    appConfigDetailsError: state.applications.appConfigDetailsError,
    appConfigSyncStatus: state.applications.appConfigSyncStatus,
    appConfigSyncStatusProgress: state.applications.appConfigSyncStatusProgress,
    appConfigSyncStatusError: state.applications.appConfigSyncStatusError,
    appConfigSyncSuccess: state.applications.appConfigSyncSuccess,
    appConfigSyncProgress: state.applications.appConfigSyncProgress,
    appConfigSyncError: state.applications.appConfigSyncError,
    settings: state.orgUser.fetchAllOrgSettingsSuccess,
    fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
    isGroupUserOpen: state.applications.showGroupUserDialog,
    isApplicationGroupOpen: state.applications.showApplicationGroupDialog,
    isApplicationUserOpen: state.applications.showApplicationUserDialog,
    isSyncHistoryOpen: state.applications.showSyncHistoryDialog,
    showDialog: state.applications.showInputForm,
    showEditPreferenceDialog: state.applications.showEditPreferenceDialog,
});

const connectionActions = {
    showSnackBar: showSnackBar,
    fetchAppConfigById: fetchAppConfigById,
    fetchApplicationSyncStatus: fetchApplicationSyncStatus,
    SyncApplication: SyncApplication,
    showApplicationFetchInput: showApplicationFetchInput,
    showApplicationGroupDialog: showApplicationGroupDialog,
    hideApplicationGroupDialog: hideApplicationGroupDialog,
    showApplicationUserDialog: showApplicationUserDialog,
    hideApplicationUserDialog: hideApplicationUserDialog,
    showGroupUserDialog: showGroupUserDialog,
    hideGroupUserDialog: hideGroupUserDialog,
    showSyncHistoryDialog: showSyncHistoryDialog,
    showEditSyncPreferenceDialog: showEditSyncPreferenceDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    backdrop: CommonCss.backdrop,
    rootContainer: {
        ...CommonCss.rootContainer,
    },

    editContent: {
        cursor: 'pointer',
        display: 'flex',
        fontWeight: 500,
        alignItems: 'center',
        justifyContent: 'end',
        color: '#4b86f8',
        float: 'right',
    },
    prevRequest: {
        cursor: 'pointer',
        display: 'flex',
        fontWeight: 500,
        alignItems: 'center',
        color: '#4b86f8',
    },
    appDetail: {
        textAlign: "center",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(3)
    },
    category: {
        color: "#aaacb4",
        fontWeight: 500,
    },
    appImage: {
        height: "40px",
        marginBottom: theme.spacing(3),
    },
    progress: {
        position: 'fixed',
        top: '45%',
        left: '50%',
    },
    actionItem: {
        background: "#FAFAFA",
        borderRadius: "6px",
        marginBottom: "15px",
        cursor: "pointer",
        padding: "15px",
        paddingLeft: "45px",
        position: "relative",
        boxShadow: '2px 2px 7px rgb(0 0 0 / 11%)',
        "& span": {
            position: "absolute",
            left: "5px",
        },
        "& h4": {
            margin: 0,
            marginBottom: 5,
            textAlign: "left",
            fontWeight: 500,
            fontSize: 16,
            color: "#282D30",
        },
        "& h6": {
            margin: 0,
            marginBottom: 15,
            textAlign: "left",
            fontSize: "12px",
            fontWeight: 300,
            color: "#282D30",
        },
    },
    formAction: {
        textAlign: 'right',
        marginTop: 15
    },
    button: {
        padding: theme.spacing(0.9),
        fontSize: theme.spacing(2.2),
        fontWeight: 600,
        width: "180px"
    },
    activeGrid: {
        border: "1px solid #007BF7",
    },
    selectedCount: {
        textDecoration: 'underline',
        color: "#007BFF",
        fontSize: '18px',
    },
    buttonWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        gap: '30px',
    },
    description: {
        fontSize: 12,
        fontWeight: 300,
    }
});

const syncOptionsV1 = [
    { slug: "SyncUser", label: "Sync Users", description: "Sync all users" },
    { slug: "SyncGroup", label: "Sync Groups", description: "Sync all groups" },
    { slug: "SyncApplication", label: "Sync Applications", description: "Sync all applications" },
    { slug: "SyncGroupUser", label: "Sync Group Users", description: "Sync users in a specific group" },
    { slug: "SyncApplicationUser", label: "Sync Application Users", description: "Sync users for a specific application" },
    { slug: "SyncApplicationGroup", label: "Sync Application Groups", description: "Sync groups for a specific application" },
]

const syncOptionsV2 = [
    { slug: "SyncUser", label: "Sync Users", description: "Sync all users" },
    { slug: "SyncGroup", label: "Sync Groups", description: "Sync all groups" },
    { slug: "SyncApplication", label: "Sync Applications", description: "Sync all applications" },
    { slug: "SyncGroupUser", label: "Sync Group Users", description: "Sync users in a specific group" },
    { slug: "SyncApplicationUser", label: "Sync Application Users", description: "Sync users for a specific application" },
    { slug: "SyncApplicationGroup", label: "Sync Application Groups", description: "Sync groups for a specific application" },
    { slug: "SyncApplicationLog", label: "Sync Application Log", description: "Sync application usage activity" },
]

class IntegrationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSyncGroupUser: [],
            selectedSyncApplicationUser: [],
            selectedSyncApplicationGroup: [],
            allSyncCheckBoxMap: {
                SyncUser: false,
                SyncGroup: false,
                SyncGroupUser: false,
                SyncApplication: false,
                SyncApplicationUser: false,
                SyncApplicationGroup: false,
                SyncApplicationLog: false,
            },
        }
    }

    componentDidMount() {
        this.props.fetchApplicationSyncStatus();
        if (this.props.match?.params?.id) {
            this.props.fetchAppConfigById(this.props.match?.params?.id);
        } else {
            this.props.showSnackBar("Something went wrong", "error", 3000);
            this.props.history.push('/app/settings/integrations');
        }
    }

    componentDidUpdate(prevProps) {

        if (!this.props.appConfigDetailsProgress && prevProps.appConfigDetailsProgress) {
            if (this.props.appConfigDetailsError) {
                this.props.showSnackBar("Something went wrong", "error", 3000);
                this.props.history.push('/app/settings/integrations');
            }
        }
        if (!this.props.appConfigSyncProgress && prevProps.appConfigSyncProgress) {
            if (this.props.appConfigSyncError) {
                this.props.showSnackBar("Failed to Sync", "error", 3000);
            } else {
                if (!this.props.isSyncHistoryOpen) {
                    this.props.showSnackBar("Success", "success", 3000);
                }
                this.props.fetchApplicationSyncStatus();
            }
        }
    }

    showAppLog = () => {
        var result = false;
        if (this.props.settings != null) {

            var setting = this.props.settings.find((item) => {
                if (item.SettingName === this.props.appConfigDetails?.AppName + "_SYNC_ACTIVITY_LOG") {
                    return true
                }
                return false
            });
            if (setting) {
                result = setting.Value === "YES"
            }
        }
        return result;
    }

    onRefresh = () => {
        this.props.fetchApplicationSyncStatus();
        this.setState({
            allSyncCheckBoxMap: {
                SyncUser: false,
                SyncGroup: false,
                SyncGroupUser: false,
                SyncApplication: false,
                SyncApplicationUser: false,
                SyncApplicationGroup: false,
                SyncApplicationLog: false,
            },
            selectedSyncGroupUser: [],
            selectedSyncApplicationUser: [],
            selectedSyncApplicationGroup: [],
        })
    }

    addSync = () => {
        let type = [];
        Object.keys(this.state.allSyncCheckBoxMap).forEach(key => {
            if (this.state.allSyncCheckBoxMap[key] === true) {
                type.push(key);
            }
        })

        let appUserIds = this.state.selectedSyncApplicationUser.map(o => o.value);
        let appGroupIds = this.state.selectedSyncApplicationGroup.map(o => o.value);

        let applicationIds = _.uniq([...appUserIds, ...appGroupIds]);

        let requestData = JSON.stringify({
            group_ids: this.state.selectedSyncGroupUser.map(o => o.value),
            application_ids: applicationIds
        });

        let data = {
            source: this.props.appConfigDetails?.AppName,
            request_type: type.toString(),
            request_data: requestData,
        }
        this.props.SyncApplication(data);
    }

    render() {
        const { classes, appConfigDetails } = this.props;
        const { selectedSyncGroupUser, selectedSyncApplicationGroup, selectedSyncApplicationUser, allSyncCheckBoxMap } = this.state;

        if (this.props.appConfigDetailsProgress || this.props.appConfigSyncStatusProgress) {
            return <div className={classes.progress}><CircularProgress /></div>
        }

        let show = this.showAppLog();
        let syncOptions = syncOptionsV1
        if (show) {
            syncOptions = syncOptionsV2
        }

        const appName = appConfigDetails?.AppName || '';
        let isShowPreferenceSyncBtn = false;
        if (appName === 'Okta') {
            isShowPreferenceSyncBtn = true
        }

        return <div>
            <AppBarV2 title={appConfigDetails?.AppName} withBack={true} />
            <div className={classes.rootContainer}>
                <Paper style={{ padding: '30px 50px', position: 'relative' }}>
                    {this.props.appConfigSyncStatus && <IconButton onClick={this.onRefresh} style={{ position: 'absolute', right: 30 }}>
                        <RefreshIcon />
                    </IconButton>}
                    <div className={classes.appDetail}>
                        <Image src={appConfigDetails?.AppLogo} alt="app_name" className={classes.appImage} />
                        <Typography variant={"subtitle1"} className={classes.category}>{appConfigDetails?.AppCategory}</Typography>
                    </div>
                    <div className={classes.buttonWrap}>
                        <div className={classes.editContent}
                            onClick={() => {
                                this.props.showApplicationFetchInput(appConfigDetails)
                            }}>
                            <EditIcon style={{ fontSize: 18, marginRight: 8 }} />
                            Edit Credentials
                        </div>

                        {isShowPreferenceSyncBtn && <div className={classes.editContent}
                            onClick={() => {
                                this.props.showEditSyncPreferenceDialog(appConfigDetails)
                            }}>
                            <EditIcon style={{ fontSize: 18, marginRight: 8 }} />
                            Edit Sync Preference
                        </div>}

                        <div className={classes.prevRequest}
                            onClick={() => {
                                this.props.showSyncHistoryDialog()
                            }}>
                            <HistoryIcon style={{ marginRight: 8 }} />
                            Sync history
                        </div>
                    </div>
                    {this.props.appConfigSyncStatus ? (
                        <div>
                            <Typography variant={"h4"} style={{ textAlign: 'center', marginBottom: 10, marginTop: 15, fontSize: 22 }}>Sync Status</Typography>
                            <SyncDetails details={this.props.appConfigSyncStatus} />
                        </div>
                    ) : (<>
                        <Grid container spacing={3} style={{ marginTop: 30 }}>
                            {syncOptions.map((item, key) => (
                                <Grid item xs={4} key={key}>
                                    <div className={classnames(classes.actionItem, allSyncCheckBoxMap[item.slug] === true ? classes.activeGrid : "")}
                                        onClick={() => {
                                            if (item.slug === "SyncGroupUser") {
                                                this.props.showGroupUserDialog();
                                            } else if (item.slug === "SyncApplicationUser") {
                                                this.props.showApplicationUserDialog();
                                            } else if (item.slug === "SyncApplicationGroup") {
                                                this.props.showApplicationGroupDialog();
                                            } else if (allSyncCheckBoxMap[item.slug] === true) {
                                                this.setState({
                                                    allSyncCheckBoxMap: {
                                                        ...allSyncCheckBoxMap,
                                                        [item.slug]: false
                                                    }
                                                })
                                            } else {
                                                this.setState({
                                                    allSyncCheckBoxMap: {
                                                        ...allSyncCheckBoxMap,
                                                        [item.slug]: true
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        <span>
                                            <Checkbox
                                                style={{ color: "#007BFF" }}
                                                checked={allSyncCheckBoxMap[item.slug] === true}
                                                name="Preliminary"
                                            />
                                        </span>
                                        <h4>{item.label}</h4>
                                        {this.state[`selected${item.slug}`] && this.state[`selected${item.slug}`].length > 0 && <div className={classes.selectedCount}>{this.state[`selected${item.slug}`].length} selected</div>}
                                        <div className={classes.description}>{item.description}</div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                        {!this.props.isSyncHistoryOpen && <div className={classes.formAction}>
                            {!this.props.appConfigSyncProgress && <Button variant="contained" color="secondary" disabled={!Object.values(allSyncCheckBoxMap).some(item => item === true)} className={classes.button} onClick={this.addSync}>Sync</Button>}
                            {this.props.appConfigSyncProgress && <>
                                <CircularProgress />
                            </>}
                        </div>}
                    </>)}

                </Paper>
            </div>
            {this.props.isGroupUserOpen && <IntegrationGroupUserDialog
                selectedGroups={selectedSyncGroupUser}
                appType={appConfigDetails?.AppName}
                onCancel={() => {
                    this.props.hideGroupUserDialog();
                }}
                onSubmit={(selectedData) => {
                    this.setState({
                        selectedSyncGroupUser: selectedData, allSyncCheckBoxMap: {
                            ...allSyncCheckBoxMap,
                            SyncGroupUser: true
                        }
                    }, () => {
                        this.props.hideGroupUserDialog();
                    })
                }}
                onDiscard={() => {
                    this.setState({ selectedSyncGroupUser: [] }, () => {
                        this.setState({
                            allSyncCheckBoxMap: {
                                ...allSyncCheckBoxMap,
                                SyncGroupUser: false
                            }
                        })
                        this.props.hideGroupUserDialog();
                    })
                }}
            />}

            {this.props.isApplicationGroupOpen && <IntegrationApplicationGroupDialog
                selectedApps={selectedSyncApplicationGroup}
                appType={appConfigDetails?.AppName}
                onCancel={() => {
                    this.props.hideApplicationGroupDialog();
                }}
                onSubmit={(selectedData) => {
                    this.setState({
                        selectedSyncApplicationGroup: selectedData, allSyncCheckBoxMap: {
                            ...allSyncCheckBoxMap,
                            SyncApplicationGroup: true
                        }
                    }, () => {
                        this.props.hideApplicationGroupDialog();
                    })
                }}
                onDiscard={() => {
                    this.setState({ selectedSyncApplicationGroup: [] }, () => {
                        this.setState({
                            allSyncCheckBoxMap: {
                                ...allSyncCheckBoxMap,
                                SyncApplicationGroup: false
                            }
                        })
                        this.props.hideApplicationGroupDialog();
                    })
                }}
            />}

            {this.props.isApplicationUserOpen && <IntegrationApplicationUserDialog
                selectedApps={selectedSyncApplicationUser}
                appType={appConfigDetails?.AppName}
                onCancel={() => {
                    this.props.hideApplicationUserDialog();
                }}
                onSubmit={(selectedData) => {
                    this.setState({
                        selectedSyncApplicationUser: selectedData, allSyncCheckBoxMap: {
                            ...allSyncCheckBoxMap,
                            SyncApplicationUser: true
                        }
                    }, () => {
                        this.props.hideApplicationUserDialog();
                    })
                }}
                onDiscard={() => {
                    this.setState({ selectedSyncApplicationUser: [] }, () => {
                        this.setState({
                            allSyncCheckBoxMap: {
                                ...allSyncCheckBoxMap,
                                SyncApplicationUser: false
                            }
                        })
                        this.props.hideApplicationUserDialog();
                    })
                }}
            />}
            <AppIntegrationFormDialog />
            {this.props.showEditPreferenceDialog && <EditSyncPreferenceDialog appName={appConfigDetails?.AppName}/>}
            {this.props.isSyncHistoryOpen && <SyncHistoryDialog appName={appConfigDetails?.AppName} />}
        </div>
    }
}

export default connector(compose(withRouter,
    withStyles(styles)
)(IntegrationDetails));