import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
import axios from "axios";
import ProjectFilter from "./ProjectFilter";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Paper, IconButton, CircularProgress, FormControl } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Image from "Components/Common/image.jsx";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { geFlowType, getUpdatedWorkflowColumns, getFinalTrackingColumns } from "util/Columns";
import { updateEvaluationTitle } from "redux/evaluation/metadata/action";
import addUsers from "assets/images/add_profile.svg";
import Select from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
const SelectAsyncPaginate = withAsyncPaginate(Select);

const connectedProps = (state) => ({
  user: state.authUser.user,
  userType: state.authUser.user?.Role,
  orgUser: state.orgUser,
});

const connectionActions = {
  updateEvaluationTitle: updateEvaluationTitle,
};

var connector = connect(connectedProps, connectionActions);

const RequestStatusMap = {
  Approved: "Approved",
  PendingApproval: "Pending Approval",
  Draft: "Draft",
  Rejected: "Rejected",
  CLOSED: "Closed",
  FINISHED: "Finished",
};

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    paper: {
      width: "100%",
      height: "100%",
      overflow: "auto",
    },
    formSelect: {
      width: 150,
    },
    editIconBtn: {
      width: 20,
    },
  });

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

class ListingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "name",
      selected: [],
      dense: false,
      page: 0,
      rowsPerPage: 5,
      query: "",
      rows: [],
      columns: [],
      invertDirection: {
        asc: "desc",
        desc: "asc",
      },
      colVisibilityData: null,
      activeTab: 1,
      statusFilter: [],
      projectStatusFilter: [],
      workflowNameFilter: "0",
      requestTypeFilter: "0",
      projectOwnerIDs: [],
      requestorIds: [],
      anchorEl: null,
      currentColumn: null,
      isShowProjectConfigPopover: false,
      projectTrackingEl: null,
      trackFormConfig: "",
      showFilters: [],
      workflowStatusfilterPopover: null,
      workflowProjectStatusfilterPopover: null,
      procurementOwnerText: "",
      isEditProcurementOwner: false,
    };
    this.btnRef = React.createRef();
  }

  componentDidMount() {
    let columns = [];
    if (this.props.activeTab === 3) {
      columns = getFinalTrackingColumns(this.props.formItemsIdMap);
      this.initWorkflowTrackingTableData();
    } else {
      columns = getUpdatedWorkflowColumns();
      this.initWorkflowTableData();
    }
    this.setColumns(columns);

    this.initLocalVariables();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUpdatedNewData !== prevProps.isUpdatedNewData) {
      if (this.props.activeTab !== 3) {
        this.initWorkflowTableData();
      } else if (this.props.activeTab === 3) {
        this.initWorkflowTrackingTableData();
      }
    }

    if (this.props.selectedView !== prevProps.selectedView) {
      this.initLocalVariablesByViews();
    }

    if (this.props.totalRows !== prevProps.totalRows && this.props.activeTab !== 3) {
      this.initWorkflowTableData();
    }

    if (this.props.trackDataTotalRows !== prevProps.trackDataTotalRows && this.props.activeTab === 3) {
      this.initWorkflowTrackingTableData();
    }

    if (this.props.updateTrackingForm !== prevProps.updateTrackingForm) {
      this.setState({
        trackFormConfig: this.props.projectTrackingForm,
      });
    }

    if (this.props.trackDataRows !== prevProps.trackDataRows && this.props.activeTab === 3) {
      this.initWorkflowTrackingTableData();
    }

    if (this.props.isChangesInFilters !== prevProps.isChangesInFilters) {
      this.initLocalVariables();
    }

    if (this.props.projectColumnWithNames !== prevProps.projectColumnWithNames) {
      this.updateWorkflowColumnNames();
    }
  }

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) {}
      return result.trim() || "";
    }
  };

  updateWorkflowColumnNames() {
    const { projectColumnWithNames } = this.props;
    if (projectColumnWithNames) {
      let prjColumns = JSON.parse(projectColumnWithNames);
      let map = {};
      prjColumns.forEach(function (col) {
        map[col.id] = col.label;
      });
      let columns = this.state.columns;
      columns.forEach(function (cl) {
        if (cl.id === "projectOwnerID") {
          cl.label = map[cl.id];
        }
      });
      this.setState({
        columns: columns,
      });
    }
  }

  initLocalVariablesByViews() {
    if (this.props.selectedView) {
      let state = {
        page: this.props.page,
        rowsPerPage: this.props.pageSize,
        query: this.props.searchQuery,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        dates: this.props.dates,
        duration: this.props.duration,
        columns: this.props.columns,
        statusFilter: this.props.statusFilter || [],
        projectStatusFilter: this.props.projectStatusFilter || [],
        workflowNameFilter: this.props.workflowNameFilter || "0",
        requestTypeFilter: this.props.requestTypeFilter || "0",
        trackFormConfig: this.props.projectTrackingForm,
        showFilters: this.props.showFilters || [],
        projectOwnerIds: this.props.projectOwnerIds || [],
        requestorIds: this.props.requestorIds || [],
      };

      if (this.props.isGlobalSortRequire) {
        state["order"] = this.props.sortType;
        state["orderBy"] = this.props.sortField;
      }
      this.setState(state);
    } else {
      this.setState({
        showFilters: [],
        statusFilter: ["completed", "in_progress", "closed"],
        projectStatusFilter: ["Draft", "PendingApproval", "Approved", "Rejected", "CLOSED", "FINISHED"],
        workflowNameFilter: "0",
        requestTypeFilter: "0",
        projectOwnerIDs: [],
        requestorIds: [],
        query: "",
        page: 0,
      });
    }
  }

  initLocalVariables() {
    let state = {
      page: this.props.page,
      rowsPerPage: this.props.pageSize,
      query: this.props.searchQuery,
      activeTab: this.props.activeTab,
      statusFilter: this.props.statusFilter || [],
      projectStatusFilter: this.props.projectStatusFilter || [],
      workflowNameFilter: this.props.workflowNameFilter || "0",
      requestTypeFilter: this.props.requestTypeFilter || "0",
      trackFormConfig: this.props.projectTrackingForm,
      showFilters: this.props.showFilters || [],
      projectOwnerIds: this.props.projectOwnerIds || [],
      requestorIds: this.props.requestorIds || [],
    };

    if (this.props.isGlobalSortRequire) {
      state["order"] = this.props.sortType;
      state["orderBy"] = this.props.sortField;
    }
    this.setState(state);
  }

  initWorkflowTableData() {
    if (this.props.rows !== undefined && this.props.rows !== null) {
      let newRows = this.props.rows.map((o) => {
        // console.log(o, 'EvaluationData')
        let date = CommonFn.formateDate(o?.CreatedAt, true);
        let lastUpdateDate = CommonFn.formateDate(o?.UpdatedAt, true);

        let totalDays = o?.DaysInCurrentStep !== null && o?.DaysInCurrentStep !== "" && o?.DaysInCurrentStep > 0 ? Math.round(o.DaysInCurrentStep) : 0;

        return {
          name: o?.Name,
          owner: o?.ProjectOwnerID !== "" && o?.ProjectOwnerID !== null ? { label: o.ProjectOwnerName, value: o.ProjectOwnerID } : "",
          requestId: o?.ID,
          workflow_name: o?.WorkflowName,
          workflow_type: o?.RequestType !== "" ? o?.RequestType : geFlowType(o?.Type),
          workflow_type_id: o?.Type,
          requester_name: { label: o?.RequestorName, value: o.RequestorID },
          requestStatus: o?.RequestStatus,
          status: o?.Status === 4 ? "Completed" : "In Progress",
          statusValue: o?.Status,
          currentStepDays: totalDays,
          creation_date: date,
          last_update: lastUpdateDate,
          id: o?.ID,
          workflowId: o?.WorkflowName,
          userId: o?.User?.ID,
          currentStep: o?.CurrentComponnetName,
          ...o?.FormFields,
          isEditOwner: false,
          isEditRequester: false,
        };
      });
      this.setState({
        rows: newRows,
      });
    }
  }

  initWorkflowTrackingTableData() {
    if (this.props.rows !== undefined && this.props.rows !== null) {
      let newRows = this.props.rows.map((o) => {
        let date = CommonFn.formateDate(o?.CreatedAt, true);
        let lastUpdateDate = CommonFn.formateDate(o?.UpdatedAt, true);

        let totalDays = o?.DaysInCurrentStep !== null && o?.DaysInCurrentStep !== "" && o?.DaysInCurrentStep > 0 ? Math.round(o.DaysInCurrentStep) : 0;

        return {
          name: o?.Name,
          owner: o?.ProjectOwnerID !== "" && o?.ProjectOwnerID !== null ? { label: o.ProjectOwnerName, value: o.ProjectOwnerID } : "",
          requestId: o?.ID,
          workflow_name: o?.WorkflowName,
          workflow_type: geFlowType(o?.Type),
          workflow_type_id: o?.Type,
          requester_name: { label: o?.RequestorName, value: o.RequestorID },
          requestStatus: o?.RequestStatus,
          status: o?.Status === 4 ? "Completed" : "In Progress",
          currentStepDays: totalDays,
          creation_date: date,
          last_update: lastUpdateDate,
          id: o?.ID,
          workflowId: o?.WorkflowName,
          userId: o?.User?.ID,
          currentStep: o?.CurrentComponnetName,
          ...o?.FormFields,
          isEditOwner: false,
          isEditRequester: false,
        };
      });

      this.setState({
        rows: newRows,
      });
    }
  }

  setColumns(columns) {
    this.setState(
      {
        columns: columns,
        colVisibilityData: null,
      },
      () => {
        if (this.state.activeTab !== 3) {
          window.localStorage.setItem("workflowListingColumns", JSON.stringify(columns));
          this.updateWorkflowColumnNames();
        } else if (this.state.activeTab === 3) {
          window.localStorage.setItem("projectTrackingTableColumns", JSON.stringify(columns));
          this.updateWorkflowColumnNames();
        }
      }
    );
  }

  setOrder(order) {
    this.setState({
      order: order,
    });
  }

  setOrderBy(orderBy) {
    this.setState({
      orderBy: orderBy,
    });
  }

  setSelected(selected) {
    this.setState({
      selected: selected,
    });
  }

  setPage(page) {
    this.setState(
      {
        page: page,
      },
      () => {
        this.props.onChangePage(page, this.state.rowsPerPage);
      }
    );
  }

  setRowsPerPage(rowsPerPage) {
    this.setState(
      {
        rowsPerPage: rowsPerPage,
      },
      () => {
        this.props.onChangePage(this.state.page, rowsPerPage);
      }
    );
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property ? this.state.invertDirection[this.state.order] : "asc";
    this.setState(
      {
        page: 0,
      },
      () => {
        this.setOrder(isAsc);
        this.setOrderBy(property);
        if (this.props.isGlobalSortRequire) {
          this.props.onChangeSort(isAsc, property);
        }
      }
    );
  };

  handleSort = (event, columnName) => {
    this.setState((state) => ({
      columnToSort: columnName,
      sortDirection: state.columnToSort === columnName ? this.state.invertDirection[state.sortDirection] : "asc",
    }));
  };

  routeChange = (item) => {
    if (this.state.activeTab === 3 && this.props.projectTrackingForm !== null && this.props.projectTrackingForm !== "") {
      this.props.history.push(`/app/project/${item.id}/tracking/${this.props.projectTrackingForm.value}`);
    } else {
      if (item.workflow_type_id !== 3) {
        if (item.workflowId !== undefined && item.workflowId !== null && item.workflowId !== "") {
          this.props.history.push(`/app/evaluate/${item.id}/workflow/interim`);
        } else {
          let redirectPath = `/app/evaluate/${item.id}/setup`;
          this.props.history.push(redirectPath);
        }
      } else if (item.workflow_type_id === 3) {
        let redirectPath = "";
        if (this.props.user.ID === item.userId) {
          redirectPath = `/app/survey/${item.id}`;
          this.props.history.push(redirectPath);
        } else {
          let redirectPath = `/app/survey/${item.id}/take-survey`;
          this.props.history.push(redirectPath);
        }
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
      () => {
        this.props.onChangePage(this.state.page, this.state.rowsPerPage);
      }
    );
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  handleClick = (event, row) => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget || event.current,
      currentColumn: row,
    });
  };

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      page: 0,
      pageSize: 5,
      query: inputValue,
    };
    const url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    // console.log(response)

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  editOwner(index) {
    let { rows } = this.state;
    let row = rows[index];
    row.isEditOwner = true;
    this.setState({
      rows: rows,
    });
  }

  editRequester(index) {
    let { rows } = this.state;
    let row = rows[index];
    row.isEditRequester = true;
    this.setState({
      rows: rows,
    });
  }

  updateOwner(id, user, index) {
    let ownerId = "";
    if (user !== undefined && user !== null && user !== "") {
      ownerId = user.value;
    }
    this.props.updateEvaluationTitle(id, {
      projectOwnerID: ownerId === "" ? 0 : ownerId,
    });

    let { rows } = this.state;
    let row = rows[index];
    row.isEditOwner = false;
    row.owner = user;
    this.setState({
      rows: rows,
    });
  }

  updateRequester(id, user, index) {
    let requesterId = "";
    if (user !== undefined && user !== null && user !== "") {
      requesterId = user.value;
    }
    this.props.updateEvaluationTitle(id, {
      userId: requesterId === "" ? 0 : requesterId,
    });
    let { rows } = this.state;
    let row = rows[index];
    row.isEditRequester = false;
    row.requester_name = user;
    this.setState({
      rows: rows,
    });
  }

  render() {
    const classes = this.props.classes;
    const { order, orderBy, page, rowsPerPage, rows, dense, columns } = this.state;
    const { totalRows, isGlobalSortRequire } = this.props;

    let newRows = isGlobalSortRequire ? rows : stableSort(rows, getComparator(order, orderBy));
    let userRole = ["OrgAdmin", "OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;

    return (
      <>
        <Paper className={classes.paper}>
          <ProjectFilter
            workflowFilterOptions={this.props.workflowNameOptions}
            requestTypeOptions={this.props.requestTypeOptions}
            filterData={this.props.filterData}
            applyFilter={(filters) => {
              this.props.applyFilter(filters);
            }}
          />
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  {columns &&
                    columns !== null &&
                    columns.map((headCell) => {
                      if (!headCell.isVisible) {
                        return null;
                      }
                      return (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "right" : "left"}
                          style={{ whiteSpace: "nowrap" }}
                          padding={headCell.disablePadding ? "none" : "normal"}
                          sortDirection={orderBy === headCell.id ? order : false}
                        >
                          {headCell.isSortRequire && (
                            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={this.createSortHandler(headCell.id)}>
                              {headCell.label}
                            </TableSortLabel>
                          )}

                          {!headCell.isSortRequire && <span>{headCell.label}</span>}
                        </TableCell>
                      );
                    })}
                  {this.props.showActions && this.state.activeTab !== 3 && adminFlag && <TableCell>Action</TableCell>}
                  {this.props.showActions && this.state.activeTab === 1 && this.props.userType === "OrgUser" && <TableCell>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {!this.props.isLoading &&
                  rows.length > 0 &&
                  newRows.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.name + index} style={{ cursor: "pointer" }}>
                        {columns &&
                          columns !== null &&
                          columns.map((col) => {
                            if (col.isVisible) {
                              if (col.id === "requestStatus") {
                                return (
                                  <TableCell
                                    onClick={(event) => {
                                      this.routeChange(row);
                                    }}
                                    align={col.align}
                                  >
                                    {RequestStatusMap[row[col.id]]}
                                  </TableCell>
                                );
                              } else if (col.type === 2 && row[col.id]) {
                                return <TableCell align={col.align}>{CommonFn.formateDate(row[col.id], true)}</TableCell>;
                              } else if (col.id === "projectOwnerID") {
                                return (
                                  <TableCell align={col.align}>
                                    {row[col.id]}

                                    {!row.isEditOwner && (
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <span>{row.owner?.label}</span>
                                        {adminFlag && (row.owner === "" || row.owner === null) && (
                                          <Image
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              this.editOwner(index);
                                            }}
                                            style={{ cursor: "pointer" }}
                                            alt="Edit"
                                            src={addUsers}
                                          />
                                        )}

                                        {adminFlag && row.owner !== "" && row.owner !== null && (
                                          <IconButton
                                            style={{ marginLeft: 8, cursor: "pointer" }}
                                            ref={this.btnRef}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.editOwner(index);
                                            }}
                                          >
                                            <EditIcon className={classes.editIconBtn} />
                                          </IconButton>
                                        )}
                                      </div>
                                    )}

                                    {row.isEditOwner && (
                                      <div>
                                        <FormControl className={classes.formSelect} style={{ zIndex: 11 }}>
                                          <SelectAsyncPaginate
                                            isClearable={true}
                                            isMulti={false}
                                            isSearchable={true}
                                            value={row.owner}
                                            menuPosition="fixed"
                                            classNamePrefix="select"
                                            isLoading={this.props.userSearchProgress}
                                            placeholder="Search User"
                                            additional={{
                                              page: 0,
                                            }}
                                            loadOptions={this.getOptions}
                                            onChange={(e) => {
                                              this.updateOwner(row.id, e, index);
                                            }}
                                          />
                                        </FormControl>
                                      </div>
                                    )}
                                  </TableCell>
                                );
                              } else if (col.id === "requester_name") {
                                return (
                                  <TableCell align={col.align}>
                                    {!row.isEditRequester && (
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <span>{row.requester_name?.label}</span>
                                        {adminFlag && (row.requester_name === "" || row.requester_name === null) && (
                                          <Image
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              this.editRequester(index);
                                            }}
                                            style={{ cursor: "pointer" }}
                                            alt="Edit"
                                            src={addUsers}
                                          />
                                        )}

                                        {adminFlag && row.requester_name !== "" && row.requester_name !== null && (
                                          <IconButton
                                            style={{ marginLeft: 8, cursor: "pointer" }}
                                            ref={this.btnRef}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.editRequester(index);
                                            }}
                                          >
                                            <EditIcon className={classes.editIconBtn} />
                                          </IconButton>
                                        )}
                                      </div>
                                    )}

                                    {row.isEditRequester && (
                                      <div>
                                        <FormControl className={classes.formSelect} style={{ zIndex: 11 }}>
                                          <SelectAsyncPaginate
                                            isClearable={true}
                                            isMulti={false}
                                            isSearchable={true}
                                            value={row.requester_name}
                                            menuPosition="fixed"
                                            classNamePrefix="select"
                                            isLoading={this.props.userSearchProgress}
                                            placeholder="Search User"
                                            additional={{
                                              page: 0,
                                            }}
                                            loadOptions={this.getOptions}
                                            onChange={(e) => {
                                              this.updateRequester(row.id, e, index);
                                            }}
                                          />
                                        </FormControl>
                                      </div>
                                    )}
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell
                                  onClick={(event) => {
                                    this.routeChange(row);
                                  }}
                                  align={col.align}
                                >
                                  {row[col.id]}
                                </TableCell>
                              );
                            } else {
                              return null;
                            }
                          })}
                        {adminFlag && this.state.activeTab !== 3 && (
                          <TableCell>
                            <IconButton ref={this.btnRef} className={classes.threeDot} onClick={(e) => this.handleClick(e, row)}>
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        )}

                        {this.props.userType === "OrgUser" && this.state.activeTab === 1 && (
                          <TableCell>
                            <IconButton ref={this.btnRef} className={classes.threeDot} onClick={(e) => this.handleClick(e, row)}>
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}

                {this.props.isLoading && (
                  <TableRow>
                    <TableCell colSpan={columns ? columns.length : 0} style={{ textAlign: "center" }} className={classes.nameCol}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {!this.props.isLoading && rows.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={columns.length} style={{ textAlign: "center" }} className={classes.nameCol}>
                      No Items
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={totalRows || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </>
    );
  }
}

ListingTable.defaultProps = {
  searchPlaceHolder: "Project Name",
  page: 0,
  rowsPerPage: 5,
  query: "",
  activeTab: 1,
  isGlobalSortRequire: false,
  selectedColumns: [],
};

export default connector(compose(withRouter, withStyles(styles))(ListingTable));
