import React from 'react';
import { connect } from "react-redux";
import classnames from "classnames";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress,Button, Typography} from '@material-ui/core';
import { Send as SendIcon} from '@material-ui/icons';
import { withRouter } from 'react-router';
import { Node } from 'slate'
import { CommonFn } from 'services/commonFn';

// assets
import AskSeller from "assets/images/ask-seller.svg"

// components
import Image from 'Components/Common/image.jsx';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";

//redux 
// import {submitEvaluationResponse} from "redux/seller/action"
import { addAskSellerRequest, fetchAskSellerThread, showAskSellerDialog } from "redux/evaluation/criteria/action";
import {showEvaluationProductEditDialog} from "redux/evaluation/cart/action"

const connectedProps = (state) => ({
    // seller response thread
    collectiveRequirement: state.evaludationCriteria.collectiveRequirement,
    collectiveProduct: state.evaludationCriteria.collectiveProduct,
    threadFetchProgress:state.evaludationCriteria.fetchAskSellerResponseInprogress,
    thread:state.evaludationCriteria.fetchAskSelerThread,
    saveProgress:state.evaludationCriteria.askSellerSubmitProgress,
});

const connectionActions = {
    addAskSellerRequest:addAskSellerRequest,
    fetchAskSellerThread:fetchAskSellerThread,
    showAskSellerDialog: showAskSellerDialog,
    showEvaluationProductEditDialog: showEvaluationProductEditDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    username:{
        fontWeight:"600",
        display: 'flex',
        placeContent: 'center space-between',
        alignItems: 'center',
        fontSize: 14,
    },
    responseTime: {
        fontSize: 14,
        marginTop: 1
    },
    sender:{
        width: '100%',
        marginBottom:20,
       
        '& .messageHeader':{
            display: 'flex',
            fontSize: 14,
            '& span':{
                marginLeft: '10px'
            }
        },
       '& .messageContainer':{
        background: '#F3F4F5 0% 0% no-repeat padding-box',
        border: '1px solid #D5D8DC',
        maxWidth: '60%',
        textAlign: 'left',
        fontSize:'14px',
        letterSpacing: '0px',
        color: '#1C2833',
        borderRadius: '18px !important',
        'border-top-left-radius': '0 !important',
        padding: '0px 16px',
        minWidth: 320
       }
    },
    receiver:{
        width: '100%',
        marginBottom:20,
        height: '100%',
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        placeContent: 'flex-end space-between',
        alignItems: 'flex-end',
        '& .messageHeader':{
            display: 'flex',
            '& span':{
                marginLeft: '10px'
            }
        },
        '& .messageContainer':{
            background: '#4b86f7 0% 0% no-repeat padding-box',
            // border: '1px solid #1C2833',
            maxWidth: '60%',
            textAlign: 'left',
            fontSize:'14px',
            letterSpacing: '0px',
            color: '#ffffff',
            // float: 'right',
            borderRadius: '18px !important',
            'border-top-right-radius': '0 !important',
            padding: '0px 16px',
            minWidth: 320,
            "&  *":{
                color: '#ffffff !important',
            }
        }
    },
    mainLoader:{
        position: 'fixed',
        left: 'calc(50vw - 34px)',
        top: 'calc(50vh - 36px)'
    },
    askSellerBtn: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border:' 1px solid #398AF5',
        borderRadius: '4px',
        letterSpacing: '0px',
        color: '#398AF5',
        padding: '3px 10px',
        cursor: 'pointer',
        alignItems: 'center',
        display: 'flex',
        marginLeft: '6px',
        fontSize: 16
    },
});

class BuyerChat extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            message:""
        }
        this.submit = this.submit.bind(this);
    }


    componentDidUpdate(prevProps){
        if(this.props.saveProgress == false && prevProps.saveProgress == true){
                this.fetchSelerThread();
        }
    }

    submit(){
        if(this.state.message.trim().length == 0){
            return;
        }
        var me      =   this;
        var response   =   me.state.message;

        this.props.addAskSellerRequest(this.props.collectiveProduct.VendorUUID,this.props.collectiveRequirement.id,response)

        this.setState({message:""})
    }

    fetchSelerThread =()=> {
        var product  =      this.props.collectiveProduct;
        var requirement  =      this.props.collectiveRequirement;
        this.props.fetchAskSellerThread(product.VendorUUID, requirement.id)
    }
    render() {
        const classes = this.props.classes;
        const { thread } = this.props;
        
        if(this.props.threadFetchProgress){
            return <div className={classes.mainLoader}> <CircularProgress/> </div>
        }
        const IsJsonString =(str)=> {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }
        const serialize = response => {
            if(IsJsonString(response)){
                response = JSON.parse(response);
                return response.map(n => Node.string(n)).join('\n')
            }else{
                return response
            }
        }

        var product  =      this.props.collectiveProduct;
        var requirement  =      this.props.collectiveRequirement;
        // let current_thread = thread && thread[`${product?.VendorUUID}_${requirement.id}`] || []

        let threads = Object.assign(thread || {})
        let _thread = threads[`${product.VendorUUID}_${requirement.id}`]
        let current_thread = _thread && _thread.length > 0 && _thread.filter( (item) => item.Type === 2) || [];
        
        return <div  className={classes.root}>
            <div className={classes.thread}>
            { current_thread && current_thread.length > 0 &&
                <>
                    {
                        _thread && _thread.map((response,k)=>{
                            let $previousValue = _thread[k - 1];
                            return <div key={k} className={classnames(
                                    classes.response, 
                                    response.Type === 2 ? classes.sender : classes.receiver,
                                    $previousValue?.Type === 2 && response?.Type === 2 ? classes.chatStart : ''
                                )}>
                                    <div className={'messageHeader'}>
                                        {
                                            response.Type === 2 ?
                                            <Typography className={classes.username}>{this.props.collectiveProduct.Name}</Typography>
                                            : <Typography className={classes.username}>You</Typography>
                                        }
                                        &nbsp;<span className={classes.responseTime}>{CommonFn.formateDateTime(response.UpdatedAt)}</span>
                                    </div>
                                    <div className={'messageContainer'}>
                                        <SlateReadonlyField initValue={response.Response}/>
                                    </div>
                                </div>
                        })
                    }
                    <div className={classnames(classes.form, 'evaluate-seller-edit')}>
                        <SlateInputField  
                            style={{width: '100%'}}
                            as={SlateInputField}
                            onChangeEvent={(value) => {
                                this.setState({ message: value });
                            }}
                            placeholder={!current_thread || current_thread?.length === 0 ? "Type your question here." : "Type your response here."}
                            initValue={this.state.message}
                            formControlStyle={{ overflow: 'overlay'}}
                            textContainerStyle={{ color: "#707070"}}/>
                        <Button  variant="contained" color="primary" endIcon={<SendIcon />} onClick={()=>{ this.submit(); }}>
                            Send
                        </Button>
                    </div>  
                </>
            }
            {
                !current_thread || current_thread.length === 0 &&
                <>
                    <Grid item xs={12} sm={12}  md={12}  lg={12}>
                        <p style={{padding: '6px', textAlign: 'left',letterSpacing: '0px',color: 'rgb(40, 45, 48)', marginTop: 0, fontSize: 16}}>
                            Vendor hasn’t responded yet.
                        </p>
                    </Grid>
                    <Grid item xs={6} sm={6}  md={6}  lg={6}>
                        <button className={classnames(classes.askSellerBtn, 'evaluate-ask-seller-btn')} onClick={()=>{
                            let evaluation_id = parseInt(this.props?.match?.params.evaluation_id)
                            if(product.VendorEmail !== null && product.VendorEmail.length !== 0){
                                this.props.showAskSellerDialog(requirement,product,evaluation_id)
                            } else {
                                this.props.showEvaluationProductEditDialog(evaluation_id,product)
                            }
                        }}><Image 
                            src={AskSeller}
                            alt="forgotpassword_image" 
                            className={classes.askImage}
                        />&nbsp;&nbsp;Ask Vendor</button>
                    </Grid>
                </>
            }
            </div>

        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(BuyerChat));