import React from "react";
import { withStyles, createStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from "@material-ui/core";
import classnames from "classnames";


const styles = (theme) => createStyles({
    step:{
        padding: theme.spacing(3.25,2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    indicator:{
        position:"relative"
    },
    line:{
        bottom: theme.spacing(1),
        left: "7px",
        height: "79px",
        position: "absolute",
        borderLeft: "2px dashed #707070",
        zIndex:1
    },
    circle:{
        backgroundColor: "#4A87F8",
        boxShadow: "0px 0px 6px #4A87F8E3",
        borderRadius: "100%",
        width: "16px",
        height: "16px",
        display: "inline-block",
        marginTop:theme.spacing(0.5),
        position:"relative",
        zIndex:2,
        visibility:"hidden"
    },
    label:{
        marginLeft: theme.spacing(2.5),
        display: "inline-block"
    },
    labelTypo:{
        fontSize:theme.spacing(2.3),
        color: "#FBFBFB",
        opacity:0.7,
    },
    completed:{
        "& $line":{
            borderLeft: "2px solid #4A87F8",
        },
        "& $circle":{
            visibility:"visible"
        },
        "& $labelTypo":{
            opacity:1
        }
    },
    hideIt:{
        visibility:"hidden !important"
    }
});

class Step extends React.Component {

    render(){
        const classes = this.props.classes;

        return <div className={classnames(classes.step,{[classes.completed]:this.props.completed}, {'step-completed': this.props.completed})}>
            <div className={classes.indicator}>
                {!this.props.isFirst && <div className={classnames(classes.line, 'step-line')}></div>}
                {<div className={classnames(classes.circle,{[classes.hideIt]:!this.props.circle}, 'step-circle',{'step-hideIt':!this.props.circle})}></div>}
            </div>
            <div className={classes.label}>
                <Typography className={classes.labelTypo}>{this.props.label}</Typography>
            </div>
        </div>
    }
}

Step.defaultProps = {
    completed: false,
    circle:false,
    isFirst:false,
}

Step.propTypes = {
    label: PropTypes.string.isRequired,
    completed: PropTypes.bool,
    circle:PropTypes.bool,
    isFirst:PropTypes.bool,

};

export default withStyles(styles)(Step)