import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import {
  fetchUsers,
  showViaIntegrationDialog,
  showUserUploadDialog,
  deleteUser, showUserLogginActivityDialog
} from "redux/usersSettings/action";
import { showSnackBar } from "redux/snackbar/action";
import {
  MenuItem,
  Popover,
  Button,
  Divider,
  Backdrop,
  CircularProgress,
  InputAdornment
} from "@material-ui/core";
import {
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, IconButton, Paper
} from '@material-ui/core';
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
import AddUserDialog from "../../../../Common/AddUserDialog";
import { showAddTeamDialog } from "../../../../../redux/okta/action";
import ViaIntegrationDialog from "./ViaIntegrationDialog";
import UserUploadDialog from "./UserAndProductUploadDialog";
import { Search as SearchIcon } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { CommonFn } from "../../../../../services/commonFn";
import MessageDialog from "../../../../Common/MessageDialog";
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import Image from 'Components/Common/image.jsx'
import editIcon from "assets/images/edit_icon.svg"
import deleteIcon from "assets/images/delete.svg"

import Clear from "@material-ui/icons/Clear";
import TooltipMsg from '@material-ui/core/Tooltip';
import LogginActivityDownloadDialog from "./Components/LogginActivityDownloadDialog";

const connectedProps = (state) => ({
  usersSettings: state.usersSettings,
  user: state.authUser.user,
  addTeamDialogOpen: state.okta.addTeamDialogOpen,
  isUserProgress: state.usersSettings.isUserProgress,
  users: state.usersSettings.users,
  totalUsers: state.usersSettings.total,
  addTeamMemberProgress: state.okta.addTeamMemberProgress,
  addTeamMemberError: state.okta.addTeamMemberError,
  isEditUserProgress: state.usersSettings.isEditUserProgress,
  editUserError: state.usersSettings.editUserError,
  isShowLogginActivityDialog: state.usersSettings.isShowLogginActivityDialog,
  okta: state.okta,
});

const connectionActions = {
  fetchUsers: fetchUsers,
  showAddTeamDialog: showAddTeamDialog,
  showViaIntegrationDialog: showViaIntegrationDialog,
  showUserUploadDialog: showUserUploadDialog,
  deleteUser: deleteUser,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  showSnackBar: showSnackBar,
  showUserLogginActivityDialog: showUserLogginActivityDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      '& thead': {
        '& span': {
          fontWeight: 600
        }
      }
    },
    searchSection: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      // marginRight: 40,
      '& [class*="MuiOutlinedInput-root"]': {
        maxHeight: 40,
        minHeight: 40,
        maxWidth: 343,
      },
      "& [class*='MuiInputBase-input']": {
        fontSize: 13,
        width: 300,
      },
      "& [class*='MuiSvgIcon-root']": {
        fill: "#707070",
      },
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
      "&>div ": {
        marginBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '16px',
      },
    },
    thead: {
      width: "20%",
    },
    action: {
      width: "15%",
      minWidth: '80px'
    },
    userCount: {
      color: "#282D30",
      fontSize: theme.spacing(2.5),
    },
    userSection: {
      marginTop: theme.spacing(6.2),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    table: {},
    tbodyCell: {
      padding: theme.spacing(1.5, 0),
      height: 50,
    },
    logginBtn: {
      background: "#fff",
      borderRadius: 5,
      color: "#3C3C3C",
      border: "1px solid #3C3C3C",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#fff",
      },
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    menuItem: {
      color: "#74798C",
      fontSize: theme.spacing(2),
      padding: theme.spacing(2.2),
      minWidth: 140,
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    edit: {
      width: 18,
      cursor: "pointer",
      padding: theme.spacing(0.1),
      marginRight: theme.spacing(2.5),
      fill: "#4a88ff",
    },
    delete: {
      fill: "#dc2f3e",
    },

    searchInput: {
      border: "1px solid #EEEEEE",
      borderRadius: 5,
      height: 32,
      minWidth: 300,
      paddingLeft: 28,
    },

    // search: {
    //   position: "relative",
    //   "& [class*='MuiSvgIcon-root']": {
    //     position: "absolute",
    //     top: 4,
    //     width: 20,
    //     left: 5,
    //     color: "#999999",
    //   },
    // },
    tableSection: {
      marginTop: theme.spacing(6.4),
      minHeight: 200,
      maxHeight: "calc(100vh - 250px)",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    searchIconPop: {
      cursor: 'pointer',
      fontSize: theme.spacing(3),
      marginRight: '10px',
      "&:hover": {
        color: "#fff !important",
        fill: "#fff !important",
        background: "#707070",
        borderColor: "#707070",
        padding: '3px',
        borderRadius: theme.spacing(20),
        fontSize: theme.spacing(3)
      }
    },
    fullSceen: CommonCss.backdrop,
    actionCol: {
      textAlign: 'center',
      width: '20%'
    },
  });

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 20,
      query: "",
      anchorEl: null,
      isEdit: false,
      editValue: {},
      error: "",
      deleteId: "",
      isDataLoading: true,
      totalUsers: 0,
      tasks: []
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.usersSettings.isDeleteUserProgress && prevProps.usersSettings.isDeleteUserProgress) {
      if (this.props.usersSettings.deleteUserError) {
        this.setState({ 'error': this.props.usersSettings.deleteUserError?.message })
      } else {
        this.setState({
          page: 0,
        }, () => {
          setTimeout(() => {
            this.fetchData();
          }, 1000);
          this.props.showSnackBar('User successfully deleted', "success", 3000)
        })
      }
    }

    if (this.props.isUserProgress === false && prevProps.isUserProgress === true) {
      // console.log(this.props.users, 'fetchDone');
      // console.log(this.props.totalUsers);
      this.setState({
        loader: false,
        tasks: this.props.usersSettings.users,
        totalUsers: this.props.usersSettings.total,
        isDataLoading: false
      })
    }

    if (!this.props.addTeamMemberProgress && prevProps.addTeamMemberProgress) {
      if (this.props.addTeamMemberError !== null) {
        this.props.showSnackBar(this.props.okta?.addTeamMemberError?.message, "error", 3000)
        // console.log(this.props.addTeamMemberError,'this.props.addTeamMemberError')
      } else if (!this.state.isEdit) {
        this.setState({
          page: 0,
        }, () => {
          this.fetchData();
          this.props.showSnackBar('User successfully created', "success", 3000)
        })
      }
    }

    if (!this.props.isEditUserProgress && prevProps.isEditUserProgress) {
      if (this.props.editUserError !== null) {
        this.props.showSnackBar('Something went wrong', "error", 3000)
      } else {
        this.setState({
          page: 0,
          isEdit: false
        }, () => {
          this.fetchData();
          this.props.showSnackBar('User successfully updated', "success", 3000)
        })
      }
    }
  }

  fetchData = () => {
    this.props.fetchUsers(
      this.state.page,
      this.state.pageSize,
      this.state.query,
      [],
      true,  // all active/inActive users
    );
  };

  handleClear(event) {
    event.stopPropagation();
    var me = this;
    this.setState({
      query: '',
      page: 0,
      isDataLoading: true
    }, () => {
      me.fetchData();
    });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  navigate = (menu) => {
    if (menu.link) {
      this.props.history.push(menu.link);
    } else if (menu.key === "addManually") {
      this.setState({
        editValue: { name: "", email: "", team: [], role: "", id: "", isActive: '' },
      });
      this.setState({ isEdit: false });
      this.props.showAddTeamDialog();
    } else if (menu.key === "viaIntegration") {
      this.props.showViaIntegrationDialog();
    } else if (menu.key === "bulkUpload") {
      this.props.showUserUploadDialog();
    }
    this.handleClose();
  };


  handleEdit(editValue) {
    // console.log(editValue)
    this.setState({ isEdit: true });
    this.props.showAddTeamDialog();
    this.setState({
      editValue: {
        name: editValue.Name,
        email: editValue.Email,
        team: editValue.Groups,
        role: CommonFn.getIdFromRoleName(editValue.Role),
        title: editValue.Title,
        id: editValue.ID,
        isActive: editValue.IsActive
      },
    });
  }

  handleDelete(user) {
    this.setState({ deleteId: user.ID }, () => {
      this.props.showMessageDialog();
    });
  }

  searchInput(classes) {
    return (
      <div className={classes.searchSection}>
        <OutlinedInput
          id={"searchProducts"}
          placeholder={'Search User'}
          value={this.state.query}
          onChange={(event) => {
            var me = this;
            this.setState({
              query: event.target.value,
              page: 0,
              isDataLoading: true
            }, () => {
              me.fetchData();
            });
          }}
          startAdornment={
            <InputAdornment>
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment>
              {this.state.query.length > 0 && this.state.query !== '' && this.state.query !== null && <TooltipMsg placement="top" title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Clear</span>} arrow>
                <Clear className={classes.searchIconPop} onClick={this.handleClear} />
              </TooltipMsg>}
            </InputAdornment>
          }
          roundedCorner={true}
        />
      </div>
    );
  }

  callBack = (data) => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteUser(this.state.deleteId);
    }
  };

  handleChangePage(e, newPage) {
    this.setState({
      page: newPage,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleChangeRowsPerPage(e) {
    this.setState({
      page: 0,
      pageSize: e.target.value,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  render() {
    const classes = this.props.classes;

    const newUserOptions = [
      { url: "", label: "Add Manually", key: "addManually" },
      { url: "", key: "viaIntegration", label: "Via Integration" },
      // { url: "", key: "bulkUpload", label: "Bulk Upload" },
    ];

    const open = Boolean(this.state.anchorEl);
    const id = open ? "newUserPopover" : undefined;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    const { page, pageSize, isDataLoading } = this.state;

    // console.log('-----------------------------')
    // console.log(this.props.usersSettings, 'usersSettings')
    // console.log(this.props.users, 'users')
    // console.log(this.props.totalUsers, 'totalUsers')

    let tasks = this.props.usersSettings.users.map((user) => {
      return { ...user, Role: CommonFn.getNameFromRole(user.Role), TeamText: user.Groups == null ? "" : user.Groups.map((g) => g.Name).join(", ") };
    });

    let totalUsers = this.props.usersSettings.total;

    return (
      <div className={classes.root}>
        <Backdrop
          className={classes.fullSceen}
          open={this.props.usersSettings.isDeleteUserProgress}
          transitionDuration={100}
        >
          <CircularProgress />
        </Backdrop>
        <div className={classes.userSection}>
          <p className={classes.userCount}>
            {this.props.usersSettings.total} user
            {this.props.usersSettings.total > 1 ? "s" : ""} found
          </p>
          <div className={classes.search}>{this.searchInput(classes)}</div>
          <div>
            <Button className={classes.logginBtn} onClick={() => { this.props.showUserLogginActivityDialog() }}>
              Login Activity
            </Button> &nbsp;&nbsp;&nbsp;&nbsp;
            <Button className={classes.newUserBtn} onClick={this.handleClick}>
              + New user
            </Button>
            <Popover
              className={classes.popover}
              id={id}
              open={open}
              anchorEl={this.state.anchorEl}
              onClose={() => this.handleClose()}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {newUserOptions?.map((item, childIndex) => {
                return (
                  <div key={childIndex}>
                    {" "}
                    <MenuItem
                      value={item.label}
                      className={classes.menuItem}
                      onClick={() => this.navigate(item)}
                    >
                      {item.label}
                    </MenuItem>{" "}
                    <Divider />
                  </div>
                );
              })}
            </Popover>
          </div>
        </div>
        <div className={classes.alert}>
          {this.state.error !== "" && (
            <Alert variant="filled" severity="error">
              {this.state.error}
            </Alert>
          )}
        </div>
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ maxHeight: '65vh' }}>
            <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
              <TableHead>
                <TableRow>
                  {/* <TableCell className={classes.nameCol}>Id</TableCell> */}
                  <TableCell className={classes.statusCol}>Name</TableCell>
                  <TableCell className={classes.statusCol}>E-mail</TableCell>
                  <TableCell className={classes.statusCol}>Team</TableCell>
                  <TableCell className={classes.statusCol}>Role</TableCell>
                  <TableCell className={classes.statusCol}>Title</TableCell>
                  <TableCell className={classes.statusCol}>Active</TableCell>
                  <TableCell className={classes.actionCol}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isDataLoading && tasks.slice(page * pageSize, page * pageSize + pageSize).map((user) => {
                  return <TableRow key={'task_' + user.ID}>
                    {/* <TableCell className={classes.statusCol}>{user.ID}</TableCell> */}
                    <TableCell className={classes.statusCol}>{user.Name}</TableCell>
                    <TableCell className={classes.statusCol}>{user.Email}</TableCell>
                    <TableCell className={classes.statusCol}>{user.TeamText}</TableCell>
                    <TableCell className={classes.statusCol}>{user.Role}</TableCell>
                    <TableCell className={classes.statusCol}>{user.Title}</TableCell>
                    <TableCell className={classes.statusCol}>{user.IsActive ? 'Yes' : 'No'}</TableCell>
                    <TableCell className={classes.actionCol}>
                      <IconButton
                        onClick={() => this.handleEdit(user)}
                      >
                        <Image
                          src={editIcon}
                        />
                      </IconButton>

                      <IconButton
                        onClick={() => this.handleDelete(user)}
                      >
                        <Image
                          src={deleteIcon}
                        />
                      </IconButton>

                    </TableCell>
                  </TableRow>
                })}

                {(!isDataLoading && (tasks === undefined || tasks === null || tasks.length <= 0)) && <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: 'center' }} className={classes.nameCol}>
                    No Users
                  </TableCell>
                </TableRow>}

                {isDataLoading && <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: 'center' }} className={classes.nameCol}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>}

              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={totalUsers || 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>

        {
          this.props.addTeamDialogOpen &&
          <AddUserDialog
            isFromSettings={true}
            isEdit={this.state.isEdit}
            editValue={this.state.editValue}
            justHideDialog={true}
          />
        }
        <ViaIntegrationDialog />
        <UserUploadDialog />
        <MessageDialog messageData={messageData} callBack={this.callBack} />
        {this.props.isShowLogginActivityDialog && <LogginActivityDownloadDialog />}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Users));
