import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogContent, Slide, Typography, Grid, InputAdornment, Button, CircularProgress
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from "react-datepicker";
import OutlinedInput from "../../../../../../Common/Input/OutlinedInput";
import Image from 'Components/Common/image.jsx';
import CalendarImage from "assets/images/calendar.svg"
import moment from 'moment';

import { hideWorkflowComponentTimelineDialog, addWorkflowComponentTimeline, updateWorkflowComponentTimeline, fetchWorkflowComponentTimeline } from "redux/evaluation/workflow/action";
import { showSnackBar } from "redux/snackbar/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.evaluationWorkflow.showWorkflowComponentTimelineDialog,
    addWorkflowCompoenentTimelineProgress: state.evaluationWorkflow.addWorkflowCompoenentTimelineProgress,
    updateWorkflowCompoenentTimelineProgress: state.evaluationWorkflow.updateWorkflowCompoenentTimelineProgress,
    fetchWorkflowCompoenentTimelineProgress: state.evaluationWorkflow.fetchWorkflowCompoenentTimelineProgress,
    workflowComponentTimeline: state.evaluationWorkflow.workflowComponentTimeline,
    workflowComponentTimelineError: state.evaluationWorkflow.workflowComponentTimelineError,
    workflowComponentDetail: state.workflow.workflowComponentDetail,
});

const connectionActions = {
    hideDialog: hideWorkflowComponentTimelineDialog,
    addWorkflowComponentTimeline: addWorkflowComponentTimeline,
    updateWorkflowComponentTimeline: updateWorkflowComponentTimeline,
    fetchWorkflowComponentTimeline: fetchWorkflowComponentTimeline,
    showSnackBar:showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    actions: {
        textAlign: "center",
        marginTop: theme.spacing(2)
    },
    actionBtn: {
        minWidth: '200px'
    },
    actionBtnDisabled: {
        minWidth: '200px',
    },
    dialogContent: {
        margin: 'auto',
        marginBottom: 30
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    productTitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    title: {
        textAlign: "center",
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
        margin: '20px 0px 0px'
    },
    datePickers: {
        marginTop: 30,
        "& [class*='MuiFormLabel-root']": {
            color: '#475867'
        },
        "& [class*=MuiOutlinedInput-root]": {
            minHeight: 40,
            color: '#282D30'
        }
    },
    footerActions: {
        textAlign: 'right'
    },
    outlined: {
        color: "#707070",
        padding: '6px 26px',
        borderColor: "#707070",
        "&:hover": {
            color: "#707070",
            borderColor: "#707070",
        }
    },
    contained: {
        color: '#fff',
        padding: '6px 26px',
        backgroundColor: "#3C3C3C",
        "&:hover": {
            backgroundColor: "#3C3C3C",
        }
    },
});

class ComponentTimelineDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            feedback: "",
            feedback_error: "",
            products: [],
            timelineStart: null,
            timelineStart_error: "",
            timelineEnd: null,
            timelineEnd_error: "",
        }
        this.addSchedule = this.addSchedule.bind(this);
    }

    componentDidMount(){
        this.clear();
        if(!this.props.isAddSchedule){
            this.setState({
                timelineStart :new Date(this.props.workflowComponentTimeline?.StartTime) ,
                timelineEnd : new Date(this.props.workflowComponentTimeline?.EndTime) ,
            })
        }
    }

    clear(){
        this.setState({
            timelineStart_error: '',
            timelineEnd_error: '',
        })
    }

    componentDidUpdate(prevProps) {
        
    }

    timelineEndChange = (date) => {
        this.setState({
            timelineEnd: date
        })

    }

    timelineStartChange = (date) => {
        this.setState({
            timelineStart: date,
        })
        if (this.state.timelineEnd) {
            var diff = moment(date).diff(moment(this.state.timelineEnd), 'days');
            if (diff > 0) {
                this.setState({
                    timelineEnd: null,
                })
            }
        }
    }

    addSchedule() {
        this.clear();

        var timelineStart = this.state.timelineStart;
        if (timelineStart === null) {
            this.setState({
                timelineStart_error: "Please select."
            })
            return
        }

        var timelineEnd = this.state.timelineEnd;
        if (timelineEnd === null) {
            this.setState({
                timelineEnd_error: "Please select."
            })
            return
        }

        if(this.props.isAddSchedule){
            this.props.addWorkflowComponentTimeline(this.props.match.params.evaluation_id, this.props.match.params.component_id, {
                "start_date": timelineStart,
                "end_date": timelineEnd,
            })
        }else{
            this.props.updateWorkflowComponentTimeline(this.props.match.params.evaluation_id, this.props.match.params.component_id,this.props.workflowComponentTimeline.ID, {
                "start_date": timelineStart,
                "end_date": timelineEnd,
            })
        }
    }


    render() {
        const classes = this.props.classes;

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>

                <div className={classes.finalizeForm}>
                    <Typography variant={"h4"} className={classes.title}>Schedule for {this.props.workflowComponentDetail?.Name}</Typography>

                    <Grid container className={classes.datePickers}>
                        <Grid item xs={6} style={{padding:10}}>
                            <DatePicker
                                selected={this.state.timelineStart}
                                onChange={(date) => {
                                    this.setState({timelineStart_error: ''})
                                    this.timelineStartChange(date)
                                }}
                                dateFormat="MMM do yyyy"
                                portalId="evMetaDialog"
                                maxDate={this.state.timelineEnd ? this.state.timelineEnd : null}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                customInput={
                                    <OutlinedInput
                                        label="Start Date"
                                        readOnlyInput={true}
                                        error={this.state.timelineStart_error.length > 0}
                                        helperText={this.state.timelineStart_error}
                                        startAdornment={
                                            <InputAdornment>
                                                <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                            </InputAdornment>
                                        }
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding:10}}>
                            <DatePicker
                                selected={this.state.timelineEnd}
                                onChange={(date) => {
                                    this.setState({timelineEnd_error: ''})
                                    this.timelineEndChange(date)
                                }}
                                dateFormat="MMM do yyyy"
                                portalId="evMetaDialog"
                                minDate={this.state.timelineStart ? this.state.timelineStart : null}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                customInput={
                                    <OutlinedInput
                                        label="End Date"
                                        readOnlyInput={true}
                                        error={this.state.timelineEnd_error.length > 0}
                                        helperText={this.state.timelineEnd_error}
                                        startAdornment={
                                            <InputAdornment>
                                                <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                            </InputAdornment>
                                        }
                                    />
                                }
                            />
                        </Grid>
                    </Grid>

                    <div className={classes.footerActions}>
                        {!this.props.addWorkflowCompoenentTimelineProgress && !this.props.updateWorkflowCompoenentTimelineProgress && <div> <Button className={classes.outlined} variant="outlined" onClick={this.props.hideDialog}>
                            Cancel
                        </Button> &nbsp; &nbsp;
                            <Button className={classes.contained} variant="contained" onClick={this.addSchedule}>
                                Save
                            </Button></div>}
                        {(this.props.addWorkflowCompoenentTimelineProgress || this.props.updateWorkflowCompoenentTimelineProgress) && <div>
                            <CircularProgress />
                        </div>}
                    </div>
                </div>
            </DialogContent>
        </Dialog >
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ComponentTimelineDialog));