import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Dialog, DialogContent, Button, Slide, Typography, FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { hideApplicationFetchInput, fetchFormInput, updateInput } from "../../../redux/applications/action";
import { addOrgSettings } from "../../../redux/org/user/action"
import OutlinedInput from "../Input/OutlinedInput";
import Alert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router';

import Image from 'Components/Common/image.jsx'

import classnames from "classnames";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    showDialog: state.applications.showInputForm,
    application: state.applications.inputFormApp,
    inputFetchProg: state.applications.fetchAppInputProgress,
    inputFetchError: state.applications.fetchAppInputError,
    appInputForm: state.applications.appInputForm,
    updateProgress: state.applications.updateProgress,
    updateError: state.applications.updateError,
    settings: state.orgUser.fetchAllOrgSettingsSuccess
});

const connectionActions = {
    hideApplicationFetchInput: hideApplicationFetchInput,
    fetchFormInput: fetchFormInput,
    updateInput: updateInput,
    addOrgSettings: addOrgSettings
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    'appDetail': {
        textAlign: "center",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(3)
    },
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important"
    },
    "app_image": {
        height: "40px",
        marginBottom: theme.spacing(3),
    },
    "label": {
        color: "#2C5CC5"
    },
    "labelIcon": {
        color: "#2C5CC5"
    },
    "appDetails": {
        cursor: "pointer"
    },
    "progress": {
        textAlign: "center",
    },
    "formAction": {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: "center",
        marginTop: theme.spacing(3)
    },
    "button": {
        padding: theme.spacing(0.9),
        fontSize: theme.spacing(2.2),
        fontWeight: 600,
        width: "200px"
    },
    alert: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    "description": {
        color: "#475867",
        marginTop: theme.spacing(2)
    },
    "category": {
        color: "#aaacb4"
    },
    cancel: {
        fontWeight: 400
    },
    "syncLabel": {
        marginLeft: "0"
    },
    "note": {
        color: "red"
    }
});

class AppIntegrationFormDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            success: '',
            syncUserFlag: false,
            syncGroupFlag: false,
            syncUserActivityLog: false,
            prevGroupFlagValue: ""
        }
        this.updateConfig = this.updateConfig.bind(this);
        this.syncSwitch = this.syncSwitch.bind(this)
        this.getAppSpecificFlag = this.getAppSpecificFlag.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.showDialog === true && prevProps.showDialog === false) {
            this.props.fetchFormInput(this.props.application.AppLink);
            const app = this.props.application;
            var syncUserFlag = false;
            var syncGroupFlag = false;
            var syncUserActivityLog = false;
            if (app != null) {
                syncUserFlag = this.getAppSpecificFlag(app.AppName, "_SYNC_USER")
                syncGroupFlag = this.getAppSpecificFlag(app.AppName, "_SYNC_SELECTED_GROUP")
                syncUserActivityLog = this.getAppSpecificFlag(app.AppName, "_SYNC_ACTIVITY_LOG")
                this.setState({
                    syncUserFlag: syncUserFlag ? true : false,
                    syncGroupFlag: syncGroupFlag ? true : false,
                    syncUserActivityLog: syncUserActivityLog ? true : false,
                    prevGroupFlagValue: syncGroupFlag ? true : false,
                })
            }

        }

        if (this.props.inputFetchProg === false && prevProps.inputFetchProg === true) {
            if (this.props.appInputForm !== null) {
                var appForm = this.props.appInputForm;
                var stateObj = {};
                var config = this.props.application?.currentConfig || {};
                appForm.forEach((elem) => {
                    stateObj[elem.name] = config !== null && config[elem.name] !== undefined ? config[elem.name] : "";
                    stateObj[elem.name + "_error"] = "";
                })
                this.setState(stateObj);
            }
        }

        if (this.props.updateProgress === false && prevProps.updateProgress === true) {
            if (this.props.updateError !== null) {
                var error = this.props.updateError;
                if (error.status === 400) {
                    var errors = error.errors;
                    var appForm = this.props.appInputForm;
                    var stateObj = {};
                    for (var field in errors) {
                        stateObj[field + "_error"] = errors[field][0];
                    }
                    console.log(stateObj);
                    this.setState(stateObj)
                } else {
                    this.setState({ error: error.message });
                }
            } else {
                var appForm = this.props.appInputForm;
                var stateObj = {};
                appForm.forEach((elem) => {
                    stateObj[elem.name] = "";
                    stateObj[elem.name + "_error"] = "";
                })
                this.setState(stateObj);
                this.props.hideApplicationFetchInput();
                if (this.state.prevGroupFlagValue !== this.state.syncGroupFlag && this.state.syncGroupFlag) {
                    this.props.history.push("/app/settings/users?team=1")
                }
            }
        }
    }

    updateConfig() {
        var appForm = this.props.appInputForm;
        var stateObj = {};
        appForm.forEach((elem) => {
            stateObj[elem.name + "_error"] = "";
        })
        this.setState(stateObj);

        var data = {};
        this.props.appInputForm.forEach((elem) => {
            data[elem.name] = this.state[elem.name];
        })
        console.log(data);
        this.props.updateInput(this.props.application.AppLink, data);
        this.syncSwitch();
    }

    syncSwitch() {
        const app = this.props.application;
        var data = [
            { name: app.AppName + "_SYNC_USER", value: this.state.syncUserFlag ? "YES" : "NO" },
            { name: app.AppName + "_SYNC_SELECTED_GROUP", value: this.state.syncGroupFlag ? "YES" : "NO" },
            { name: app.AppName + "_SYNC_ACTIVITY_LOG", value: this.state.syncUserActivityLog ? "YES" : "NO" }
        ];
        this.props.addOrgSettings(data)
    }

    getAppSpecificFlag(appName, key) {
        var result = false;
        if (this.props.settings != null) {
            var setting = this.props.settings.filter((item) => {
                if (item.SettingName === appName + key) {
                    return true
                }
                return false
            });

            if (setting.length > 0) {
                result = setting[0]['Value'] === "YES"
            }
        }
        return result;
    }

    render() {
        const classes = this.props.classes;
        const app = this.props.application;
        const appInputForm = this.props.appInputForm;
        // var syncUserFlag = false;
        // var syncGroupFlag = false;
        // var syncUserActivityLog = false;
        // if(app != null){
        //     syncUserFlag = this.getAppSpecificFlag(app.AppName,"_SYNC_USER")
        //     syncGroupFlag = this.getAppSpecificFlag(app.AppName,"_SYNC_SELECTED_GROUP")
        //     syncUserActivityLog = this.getAppSpecificFlag(app.AppName,"_SYNC_ACTIVITY_LOG")
        // }

        let appName = app?.AppName || '';
        let isHideSyncOptions = false;
        if(appName === 'Okta'){
            isHideSyncOptions = true
        }

        return <Dialog
            onClose={this.props.hideApplicationFetchInput}
            aria-labelledby="app-integrationDialog"
            open={this.props.showDialog}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
        >
            {app !== null && <React.Fragment>
                <DialogContent classes={{ root: classes.dialogContent }}>

                    <div className={classes.appDetail}>
                        <Image src={app.AppLogo} alt="app_name" className={classes.app_image} />
                        {/* <Typography variant={"subtitle1"} className={classes.category}>{app.AppCategory}</Typography> */}
                        <Typography className={classes.description}>Expent wants to access your organization information about users, groups, applications used and respective login activity</Typography>
                    </div>
                    <div className={classes.alert}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                        {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                    </div>
                    {this.props.inputFetchProg && <div className={classes.progress}><CircularProgress /></div>}
                    {appInputForm !== null && <div>{
                        appInputForm.map((form, k) => {
                            return <OutlinedInput
                                key={k}
                                id={`${form.name}Input`}
                                label={form.label}
                                type="text"
                                value={this.state[form.name] === undefined ? "" : this.state[form.name]}
                                onChange={(event) => {
                                    this.setState({ [form.name]: event.target.value });
                                }}
                                error={this.state[form.name + "_error"] === undefined ? false : this.state[form.name + "_error"].length > 0}
                                helperText={this.state[form.name + "_error"] === undefined ? "" : form.label + ' must not be empty.'}
                                rows={form.type === "TEXTAREA" ? 10 : 1}
                                multiline={form.type === "TEXTAREA" ? true : false}
                            />
                        })
                    }</div>
                    }
                    {!isHideSyncOptions && appInputForm !== null && app.AppCategory === "Active Directory" && <div>
                            <div>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={<Switch color="primary" checked={this.state.syncUserFlag} onChange={(event) => {
                                            this.setState({
                                                syncUserFlag: event.target.checked
                                            })
                                            // this.syncSwitch(event,"_SYNC_USER")
                                        }} />}
                                        label="Automatically synchronize users and create an account in Expent"
                                        labelPlacement="start"
                                        className={classes.syncLabel}
                                    />
                                </FormControl>
                            </div>
                            {this.state.syncUserFlag && <div><FormControl component="fieldset">
                                <FormControlLabel
                                    control={<Switch color="primary" checked={this.state.syncGroupFlag} onChange={(event) => {
                                        this.setState({
                                            syncGroupFlag: event.target.checked
                                        })
                                        // this.syncSwitch(event,"_SYNC_SELECTED_GROUP")
                                    }} />}
                                    label="Synchronize groups"
                                    labelPlacement="start"
                                    className={classes.syncLabel}
                                />
                            </FormControl></div>}
                            <div><FormControl component="fieldset">
                                <FormControlLabel
                                    control={<Switch color="primary" checked={this.state.syncUserActivityLog} onChange={(event) => {
                                        this.setState({
                                            syncUserActivityLog: event.target.checked
                                        })
                                        // this.syncSwitch(event,"_SYNC_ACTIVITY_LOG")
                                    }} />}
                                    label="Application Usage Activity"
                                    labelPlacement="start"
                                    className={classes.syncLabel}
                                />
                            </FormControl></div>
                        </div>
                    }
                    {this.state.prevGroupFlagValue !== this.state.syncGroupFlag && this.state.syncGroupFlag && <Typography className={classes.note}>Note :- You'll be able to select specific groups in the next step.</Typography>}
                    {appInputForm !== null && <div className={classes.formAction}>
                        {!this.props.updateProgress && <React.Fragment>
                            <Button variant="outlined" color="secondary" className={classnames(classes.button, classes.cancel)} onClick={this.props.hideApplicationFetchInput}>Cancel</Button>
                            &nbsp;
                            &nbsp;
                            <Button variant="contained" color="secondary" className={classes.button} onClick={this.updateConfig}>
                                {(this.state.prevGroupFlagValue !== this.state.syncGroupFlag && this.state.syncGroupFlag) ? "Next" : "Submit"}
                            </Button>
                        </React.Fragment>}
                        {this.props.updateProgress && <React.Fragment>
                            <CircularProgress />
                        </React.Fragment>}
                    </div>}
                </DialogContent>
            </React.Fragment>}
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AppIntegrationFormDialog));