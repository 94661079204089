import {
  Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import SqureButton from 'Components/Common/SqureButton';

import { hideRequestAccessInformationDialog } from "redux/dialogs/action";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.dialogs.isShowRequestAccInfoDialog,
  requestAccInfo: state.dialogs.requestAccInfo,
});

const connectionActions = {
  hideDialog: hideRequestAccessInformationDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
    margin: 'auto',
    marginBottom: 30
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  title: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
  },
  subMessage: {
    fontWeight: 400,
    color: '#282D30',
    fontSize: 16,
  },
  body: {
    marginTop: 20
  },
  linkDiv: {
    marginTop: 20
  },
  linkMsg: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 16,
  },
  bottomBtns: {
    marginTop: 20,
    textAlign: 'right'
  },
  linkitm: {
    color: '#4b86ff !important'
  }
});

var timerInterval = '';

class RequestAccessInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      timer: 10
    }
    this.goTolink = this.goTolink.bind(this);
    this.handleTimer = this.handleTimer.bind(this)
  }

  componentDidMount() {
    this.startTimer()
  }

  componentDidUpdate(prevProps) {

  }

  goTolink() {
    clearInterval(timerInterval)
    const { requestAccInfo } = this.props;
    const newWindow = window.open(requestAccInfo.link, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
    this.handleHideDialog()

  }

  startTimer() {
    const { requestAccInfo } = this.props;
    if (requestAccInfo && requestAccInfo.link && requestAccInfo.link !== '') {
      timerInterval = setInterval(this.handleTimer, 1000);
    }
  }

  handleTimer() {
    let timerNew = this.state.timer - 1;
    this.setState({
      timer: timerNew
    })
    if (timerNew <= 0) {
      clearInterval(timerInterval)
      this.goTolink()
      this.handleHideDialog()
    }
  }

  handleHideDialog(){
    setTimeout(() => {
      this.props.hideDialog()
    }, 1000);
  }



  render() {
    const classes = this.props.classes;

    const { requestAccInfo } = this.props;
    const { timer } = this.state;
    return <Dialog
      onClose={this.props.hideDialog}
      aria-labelledby="app-integrationDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"md"}
      scroll="body"
      id="evMetaDialog"
    >
      <DialogContent classes={{ root: classes.dialogContent }} >
        <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
        <Typography variant={"h4"} className={classes.title}>Request Access</Typography>
        <div className={classes.body}>
          {requestAccInfo && requestAccInfo.message && requestAccInfo.message !== '' && 
          
          // <Typography variant={"h5"} className={classes.subMessage}>{requestAccInfo?.message}</Typography>
          <SlateReadonlyField initValue={requestAccInfo?.message} />
          }

          {requestAccInfo && requestAccInfo.link && requestAccInfo.link !== '' && <div className={classes.linkDiv}>
            <Typography variant={"h4"} className={classes.linkMsg}>You are being redirected to &nbsp;
              <a className={classes.linkitm} target="_blank" rel="noopener noreferrer" href={requestAccInfo.link}>{requestAccInfo.link}</a> in {timer} seconds.
            </Typography>
          </div>}

          {requestAccInfo && requestAccInfo.link && requestAccInfo.link !== '' && <div className={classes.bottomBtns}>
            <SqureButton variant={"outlined"} onClick={() => this.props.hideDialog()}>Cancel</SqureButton>
            <SqureButton style={{ marginLeft: 10 }} variant={"contained"} onClick={() => { this.goTolink() }}>Take me there</SqureButton>
          </div>}

        </div>
      </DialogContent>
    </Dialog>
  }
}

RequestAccessInformation.defaultProps = {
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(RequestAccessInformation));