import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment";

export const START_SERVICE_FETCH = "START_SERVICE_FETCH";
export const END_SERVICE_FETCH = "END_SERVICE_FETCH";

export const START_ALL_SERVICE_FETCH = "START_ALL_SERVICE_FETCH";
export const END_ALL_SERVICE_FETCH = "END_ALL_SERVICE_FETCH";

export const START_ALL_VENDOR_SERVICE_FETCH = "START_ALL_VENDOR_SERVICE_FETCH";
export const END_ALL_VENDOR_SERVICE_FETCH = "END_ALL_VENDOR_SERVICE_FETCH";

export const START_SERVICE_DETAILS_FETCH = "START_SERVICE_DETAILS_FETCH";
export const END_SERVICE_DETAILS_FETCH = "END_SERVICE_DETAILS_FETCH";

function startServiceFetch(searchQuery) {
  return {
    type: START_SERVICE_FETCH,
    payload: {
      searchQuery,
    },
  };
}

function endServiceFetch(searchQuery, success, error) {
  return {
    type: END_SERVICE_FETCH,
    payload: {
      searchQuery,
      success,
      error,
    },
  };
}

export function fetchServices(data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendorservice/search?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startServiceFetch(data));
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
      dispatch(endServiceFetch(data, response, error));
    } catch (e) {
      dispatch(
        endServiceFetch(data, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}


function startAllVendorServiceFetch() {
  return {
    type: START_ALL_VENDOR_SERVICE_FETCH,
    payload: {},
  };
}

function endAllVendorServiceFetch(success, error) {
  return {
    type: END_ALL_VENDOR_SERVICE_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchAllVendorServices(data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/service/search?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAllVendorServiceFetch());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url,data,false);
      dispatch(endAllVendorServiceFetch(response, error));
    } catch (e) {
      dispatch(
        endAllVendorServiceFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}



function startAllServiceFetch() {
  return {
    type: START_ALL_SERVICE_FETCH,
    payload: {},
  };
}

function endAllServiceFetch(success, error) {
  return {
    type: END_ALL_SERVICE_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchAllServices(data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/service/search?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAllServiceFetch());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url,data,false);
      dispatch(endAllServiceFetch(response, error));
    } catch (e) {
      dispatch(
        endAllServiceFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchServiceById() {
  return {
    type: START_SERVICE_DETAILS_FETCH,
    payload: {},
  };
}

function endFetchServiceById(success, error) {
  return {
    type: END_SERVICE_DETAILS_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchServiceById( serviceId) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  var url = `${api_server}/entity/vendorservice/master/${serviceId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchServiceById());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endFetchServiceById(response, error));
    } catch (e) {
      dispatch(
        endFetchServiceById(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
