import React from "react";
import { Dialog, DialogContent, Slide, Typography, Button, Card } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import { compose } from "recompose";
import { showSnackBar } from "redux/snackbar/action";
import Response from "./Response";
import { addSellerAssessmentQuesionResponse } from "redux/seller/assessment/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  addSellerAssessmentQuesionResponseProgress: state.sellerAssessment.addSellerAssessmentQuesionResponseProgress,
  addSellerAssessmentQuesionResponseError: state.sellerAssessment.addSellerAssessmentQuesionResponseError,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  addSellerAssessmentQuesionResponse: addSellerAssessmentQuesionResponse,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title1: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    box: {
      marginTop: 25,
    },
    actionButton: {
      textAlign: "right",
      marginTop: 25,
    },
    alert: {
      marginBottom: 10,
      marginTop: 25,
    },
    btn: {
      padding: "1.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    root: {
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    profileInfoCard: {
      width: "100%",
      padding: "2rem",
      borderRadius: 0,
    },
    title: {
      marginBottom: "1rem",
      color: "#1f73b7",
    },
    actionItem: {
      background: "#FAFAFA",
      borderRadius: "6px",
      marginBottom: "15px",
      cursor: "pointer",
      padding: "15px",
      paddingLeft: "45px",
      position: "relative",
      boxShadow: "2px 2px 7px rgb(0 0 0 / 11%)",
    },
    checkbox: {
      position: "absolute",
      left: "5px",
    },
    activeGrid: {
      border: "1px solid #007BF7",
    },
    bodyTxt: {
      marginBottom: theme.spacing(2),
      borderBottom: "3px solid #cecece",
      "& [class*='editableField']": {
        "white-space": "normal !important",
      },
    },
    btnAction: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: 15,
    },
  });

class GenieChooseResponseDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: null,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (!this.props.addSellerAssessmentQuesionResponseProgress && prevProps.addSellerAssessmentQuesionResponseProgress) {
      if (this.props.addSellerAssessmentQuesionResponseError) {
        this.props.showSnackBar("Something went wrong", "error", 3000);
      } else {
        this.props.showSnackBar("Updated successfully", "success", 3000);
        this.props.onClose();
      }
    }
  };

  componentDidMount() {
    let index = this.props.chooseResponseDialogData?.findIndex((data) => {
      if (data?.match_object?.id === this.props.requirement?.answers?.reference_answer_id) {
        return true;
      } else {
        return false;
      }
    });
    if (index !== -1) {
      this.setState({ selectedIndex: index });
    }
  }

  onSubmit = () => {
    const { chooseResponseDialogData, requirement } = this.props;
    let { selectedIndex } = this.state;
    let answer = chooseResponseDialogData[selectedIndex];
    let params = {
      question_id: requirement?.ID,
      answer_id: requirement?.answers?.id,
      entity_type: "answer",
      answer: {
        created_from: "needs_review",
        reference_answer_id: answer?.match_object?.id,
        note: answer?.match_object?.note || "",
        answer_type: parseInt(answer?.match_object?.answer_type),
        options: answer?.match_object?.options,
        answer: answer?.match_object?.answer,
        options_answer: answer?.match_object?.options_answer,
      },
    };

    this.props.addSellerAssessmentQuesionResponse({
      action: "update",
      source: { id: this.props.match.params.uuid, name: "assessment" },
      data: [params],
    });
  };

  getConfidence = (score) => {
    // if (score <= 40) {
    //   return <div style={{ color: "red", display: "inline" }}>Low</div>;
    // } else if (score <= 70) {
    //   return <div style={{ color: "#ecb22e", display: "inline" }}>Medium</div>;
    // } else {
    //   return <div style={{ color: "#2eb77d", display: "inline" }}>High</div>;
    // }
    try {
      return Math.floor(score * 100) + "%";
    } catch (e) {
      return "0%";
    }
  };

  render() {
    const { classes, chooseResponseDialogData, requirement } = this.props;
    const { selectedIndex } = this.state;
    return (
      <Dialog
        onClose={this.props.onClose}
        aria-labelledby="app-integrationDialog"
        open={true}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        id="evMetaDialog34"
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.onClose()}>
            <CloseIcon />
          </div>
          <Typography variant={"h4"} className={classes.title1}>
            Use Answer from Library
          </Typography>
          <Card style={{ margin: "1.5rem 0rem" }}>
            <div className={classes.profileInfoCard}>
              <div style={{ pointerEvents: "none", marginBottom: 25 }}>
                <Typography component="h6">{requirement?.question?.name}</Typography>
                <Typography component="p" className={classes.bodyTxt}>
                  <SlateReadonlyField initValue={requirement?.question?.description || "Question"} />
                </Typography>
              </div>
              {chooseResponseDialogData.map((response, AnsIndex) => (
                <div
                  key={"res" + AnsIndex}
                  className={classnames(classes.actionItem, selectedIndex === AnsIndex ? classes.activeGrid : "")}
                  onClick={() => {
                    this.setState({ selectedIndex: AnsIndex });
                  }}
                >
                  <Typography style={{ textAlign: "right", fontWeight: 500 }}>Confidence Score: {this.getConfidence(response?.confidence_score)}</Typography>
                  {response.type !== "auto_populate" && (
                    <span className={classes.checkbox}>
                      <Checkbox color="primary" checked={selectedIndex === AnsIndex} name="checkedA" />
                    </span>
                  )}
                  <div style={{ marginLeft: 10 }}>
                    <Typography component="h6">{response?.question?.title}</Typography>
                    <Typography component="p">{response?.question?.text}</Typography>
                  </div>
                  <Response reqIndex={AnsIndex} respondable={true} requirement={response} />
                </div>
              ))}
            </div>
          </Card>
          {chooseResponseDialogData[0]?.type !== "auto_populate" && (
            <div className={classes.btnAction}>
              <Button disabled={this.props.addSellerAssessmentQuesionResponseProgress || selectedIndex === null} variant="contained" color="secondary" onClick={this.onSubmit}>
                Submit
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(GenieChooseResponseDialog));
