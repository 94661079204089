import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import makeStyles from '@material-ui/styles/makeStyles';

import classnames from "classnames";
import { withLinks } from './SlateImage';

import { SlateElement, SlateLeaf } from './SlateEditorUtil';

const useStyles = makeStyles((theme) => ({

}));

export const SlateReadonlyField = ({ initValue, ...props }) => {
  let editorInitialValue = [{
    type: 'paragraph',
    children: [{ text: '' }],
  }];
  const classes = useStyles();
  const [slateValue, setSlateValue] = useState(editorInitialValue);

  const editor = useMemo(
    () => withMentions(withLinks(withHistory(withReact(createEditor())))),
    []
  )

  useEffect(() => {
    if (initValue && typeof initValue === 'string') {
      try {
        editorInitialValue = JSON.parse(initValue);
      } catch (e) {
        editorInitialValue = [
          {
            type: 'paragraph',
            children: [{ text: initValue }],
          }
        ];
        // console.log(e)
      }
      setSlateValue(editorInitialValue);
    }
  }, [initValue]);

  const renderElement = useCallback((props) => <SlateElement {...props} />, []);
  const renderLeaf = useCallback((props) => <SlateLeaf {...props} />, []);

  return <Slate
    editor={editor}
    value={slateValue}
    onChange={(value) => {
      setSlateValue(value);
      props.onChangeEvent(JSON.stringify(value))
    }}
  >
    <Editable
      renderLeaf={renderLeaf}
      readOnly={true}
      renderElement={renderElement}
      className={classnames(classes.editableField, 'editor-editableField')}
    />
  </Slate>;
};

const withMentions = editor => {
  const { isInline, isVoid } = editor

  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  return editor
}