import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
// import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  Tooltip,
  Button,
  // TextField,
  // InputAdornment,
  CircularProgress,
  AppBar,
  FormControl,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Select as MaterialSelect } from "@material-ui/core";
// import DefaultImage from "assets/images/evaluation/default.svg";
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import { currencyCodeMap } from "util/Currency";
// import SearchIcon from "@material-ui/icons/Search";
import { fetchOrgCatalogDetailsTabs } from "redux/vendor/vendorCatalog/action";
import { addCatalogViews } from "redux/vendor/action";
import { showCatalogfieldsDialog, hideCatalogfieldsDialog } from "redux/vendor/action";
import ViewsDialog from "../../Dialog/ViewsDialog";
import ContractTable from "./ContractTable";
import { CommonFn } from "services/commonFn";
import * as Validator from "util/Validation";
import { fetchVendorCatalogViews, fetchServiceCatalogViews } from "redux/vendor/action";
import { showSnackBar } from "redux/snackbar/action";
import { getConfigValue } from "util/Common";
import SearchFilterComponent from "./SearchFilterComponent.jsx";

const connectedProps = (state) => ({
  user: state.authUser.user,
  orgUser: state.orgUser,
  showDialog: state.vendors.showDialog,

  catalogViewsAddProgress: state.vendors.catalogViewsAddProgress,
  catalogViewsAddError: state.vendors.catalogViewsAddError,
  catalogViewsAddSuccess: state.vendors.catalogViewsAddSuccess,

  vendorCatalogViews: state.vendors.vendorCatalogViews,
  fetchVendorCatalogViewsProgress: state.vendors.fetchVendorCatalogViewsProgress,
  fetchVendorCatalogViewsError: state.vendors.fetchVendorCatalogViewsError,

  serviceCatalogViews: state.vendors.serviceCatalogViews,
  fetchServiceCatalogViewsProgress: state.vendors.fetchServiceCatalogViewsProgress,
  fetchServiceCatalogViewsError: state.vendors.fetchServiceCatalogViewsError,

  fetchVendorServiceCatalogProgress: state.vendorServiceCatalog.fetchVendorServiceCatalogProgress,
  fetchVendorServiceCatalogError: state.vendorServiceCatalog.fetchVendorServiceCatalogError,
  VendorServiceCatalog: state.vendorServiceCatalog.VendorServiceCatalog,

  fetchVendorCatalogProgress: state.vendorCatalog.fetchVendorCatalogProgress,
  fetchVendorCatalogError: state.vendorCatalog.fetchVendorCatalogError,
  VendorCatalog: state.vendorCatalog.VendorCatalog,

  orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,
  orgCatalogDetailTabsProgress: state.vendorCatalog.orgCatalogDetailTabsProgress,
  orgCatalogDetailTabsError: state.vendorCatalog.orgCatalogDetailTabsError,
});

const connectionActions = {
  addCatalogViews: addCatalogViews,
  fetchVendorCatalogViews: fetchVendorCatalogViews,
  fetchServiceCatalogViews: fetchServiceCatalogViews,
  showSnackBar: showSnackBar,
  fetchOrgCatalogDetailsTabs: fetchOrgCatalogDetailsTabs,
  showCatalogfieldsDialog: showCatalogfieldsDialog,
  hideCatalogfieldsDialog: hideCatalogfieldsDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    workflowPaper: {
      padding: 20,
    },
    paper: {
      width: "100%",
      overflow: "hidden",
    },
    tableContainer: {
      // maxHeight: '68vh'
    },
    visuallyHidden: {
      marginLeft: 5,
    },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: "1 1 100%",
    },
    columnDrawer: {
      width: 400,
      right: "0",
      padding: "15px",
      zIndex: "9",
    },
    bottomActions: {
      textAlign: "right",
    },
    autoSelectInput: {
      "& [class*=MuiInputBase-input]": {
        padding: "10px !important",
      },
    },
    searchBox: {
      padding: theme.spacing(3, 3),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    table: {},
    topHeader: {
      display: "flex",
    },
    tabsSection: {
      flex: 1,
    },
    showBtnSection: {
      flex: 1,
      textAlign: "right",
      paddingTop: 3,
      paddingRight: 10,
    },
    answerBox: {
      backgroundColor: "#fff",
      borderBottom: "1px solid #f1f1f1",
      boxShadow: "none",
    },
    noDataField: {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
    filterTitle: {
      color: "#282D30",
      fontWeight: 400,
      fontSize: theme.spacing(2.2),
    },
    formControl: {
      width: "250px",
    },
    textWrap: {
      "& [class*='editor-editableField']": {
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
        minHeight: "unset !important",
        "& p": {
          marginTop: 0,
        },
      },
    },
    statusFilter: {
      display: "flex",
      alignItems: "center",
      gap: 20,
    },
    clearFilterBtn: {
      marginLeft: 10,
      padding: "4px 20px",
    },
  });

class TableContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "",
      orderBy: "",
      selected: [],
      dense: false,
      page: 0,
      rowsPerPage: 20,
      query: "",
      rows: [],
      columns: [],
      invertDirection: {
        asc: "desc",
        desc: "asc",
      },
      isShowSubMenu: false,
      subMenuEl: null,
      selectedItemIds: [],
      selectedItems: [],
      headers: [],
      totalRows: 0,
      basicInfo: [],
      selectedView: null,
      showContractView: false,
    };
  }

  componentDidMount() {
    this.props.fetchOrgCatalogDetailsTabs(this.props.type === "vendor" ? "vendor" : "vendorservice");
    const urlPrams = new URLSearchParams(this.props.location.search);
    let view = urlPrams.get("selectedView");
    if (view) {
      this.setState({ selectedView: view, showContractView: view === "contract_view" ? true : false });
      this.selectView(view);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.orgCatalogDetailTabsProgress && prevProps.orgCatalogDetailTabsProgress) {
      this.initialiseData();
    }

    if (!this.props.orgCatalogDetailTabsError && prevProps.orgCatalogDetailTabsError) {
      this.initialiseData();
    }

    if (!this.props.fetchVendorServiceCatalogProgress && prevProps.fetchVendorServiceCatalogProgress) {
      this.initialiseData();
    }

    if (!this.props.fetchVendorServiceCatalogError && prevProps.fetchVendorServiceCatalogError) {
      this.initialiseData();
    }

    if (!this.props.fetchVendorCatalogProgress && prevProps.fetchVendorCatalogProgress) {
      this.initialiseData();
    }

    if (!this.props.fetchVendorCatalogError && prevProps.fetchVendorCatalogError) {
      this.initialiseData();
    }

    if (!this.props.fetchVendorCatalogViewsProgress && prevProps.fetchVendorCatalogViewsProgress) {
      if (!this.props.fetchVendorCatalogViewsError) {
        if (!this.state.selectedView) {
          const ViewID = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "VendorTabularView");
          this.selectView(ViewID);
        } else {
          this.selectView(this.state.selectedView);
        }
      }
    }

    if (!this.props.fetchServiceCatalogViewsProgress && prevProps.fetchServiceCatalogViewsProgress) {
      if (!this.props.fetchServiceCatalogViewsError) {
        if (!this.state.selectedView) {
          const ViewID = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "ServiceTabularView");
          this.selectView(ViewID);
        } else {
          this.selectView(this.state.selectedView);
        }
      }
    }

    if (!this.props.catalogViewsAddProgress && prevProps.catalogViewsAddProgress) {
      if (this.props.catalogViewsAddSuccess) {
        // this.selectView(this.props.catalogViewsAddSuccess.id);
        this.props.showSnackBar("View created successfully", "success", 3000);
        if (this.props.type === "vendor") {
          this.props.fetchVendorCatalogViews();
        } else {
          this.props.fetchServiceCatalogViews();
        }
      } else {
        this.props.showSnackBar("Failed to create view", "error", 3000);
      }
    }
  }

  selectView = (id) => {
    let selectedView = [];
    if (this.props.type === "vendor") {
      selectedView = this.props.vendorCatalogViews.find((item) => item.id === id);
    } else {
      selectedView = this.props.serviceCatalogViews.find((item) => item.id === id);
    }
    let views = selectedView?.item_slugs?.map((o) => o) || [];
    this.setState({ selectedItems: views, selectedView: id }, () => {
      this.initialiseData();
      this.props.onApplyFilter([]);
    });
  };

  initialiseData = () => {
    if (!this.props.orgCatalogDetailTabs.length) return;

    let tabs = this.props.orgCatalogDetailTabs;

    let selectedItems = this.state.selectedItems;
    let tempSlug = [];
    let headers = [...this.state.basicInfo];
    tabs.forEach((tab) => {
      tab.sections.forEach((section) => {
        section.items.forEach((item) => {
          if (selectedItems?.includes(item.slug) && !tempSlug.includes(item.slug)) {
            tempSlug.push(item.slug);
            let itemName = item.name;
            if (!itemName || itemName.length === 0) {
              itemName = section.name;
            }
            headers.push({
              id: item.id,
              slug: item.slug,
              item_name: itemName,
              type: item.type,
              is_default: item.is_default,
              additionalData: Validator.isJson(item.additional_data) ? JSON.parse(item.additional_data) : item.additional_data ? item.additional_data : [],
            });
          }
        });
      });
    });

    this.setState({
      headers: headers,
    });
  };

  setOrder = (order) => {
    this.setState({
      order: order,
    });
  };

  setOrderBy = (orderBy) => {
    this.setState({
      orderBy: orderBy,
    });
  };

  setPage = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.props.onChangePage(page, this.state.rowsPerPage);
      }
    );
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState(
      {
        rowsPerPage: rowsPerPage,
      },
      () => {
        this.props.onChangePage(this.state.page, rowsPerPage);
      }
    );
  };

  updateSearchQuery = (searchQuery) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: 20,
        query: searchQuery,
        order: "",
        orderBy: "",
      },
      () => {
        this.props.onChangeSearch(0, 20, searchQuery);
      }
    );
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property ? this.state.invertDirection[this.state.order] : "asc";
    this.setState(
      {
        page: 0,
      },
      () => {
        this.setOrder(isAsc);
        this.setOrderBy(property);
        this.props.onChangeSort(isAsc, property);
      }
    );
  };

  routeChange = (item) => {
    let redirectPath = "";
    if (this.props.type === "vendor") {
      redirectPath = `/app/vendors/org-vendor-details/${item.id}`;
    } else {
      redirectPath = `/app/vendors/org-service-details/${item.id}`;
    }
    this.props.history.push(redirectPath);
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
      () => {
        this.props.onChangePage(this.state.page, this.state.rowsPerPage);
      }
    );
  };

  isSelected = (name) => {
    return this.state.selected.indexOf(name) !== -1;
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  createFieldsView = (name, item_ids) => {
    this.setState({
      selectedItemIds: item_ids,
    });
    this.props.addCatalogViews({
      name: name,
      item_slugs: item_ids,
      visibility: [],
      sequence: 1,
      team_ids: [],
      type: this.props.type,
    });
    this.props.hideCatalogfieldsDialog();
  };

  printData = (data, type, headerIndex) => {
    try {
      let header = this.state.headers[headerIndex];

      if (!header) {
        return data;
      }

      if (!data) {
        return "";
      }

      // if (type === 0 && this.props.type === "vendor") {
      //   //name with image
      //   return (
      //     <div style={{ display: "flex", alignItems: "center", gap: "10px", fontWeight: "600" }}>
      //       <img src={!data?.logo || data?.logo.length === 0 ? DefaultImage : data?.logo} alt={""} style={{ width: 40, height: "100%" }} />
      //       {data.name}
      //     </div>
      //   );
      // } else if (type === 0 && this.props.type === "vendorservice") {
      //   return (
      //     <div style={{ display: "flex", alignItems: "center", gap: "10px", fontWeight: "600" }}>
      //       {data.service} <span style={{ fontWeight: "normal" }}>by {data.vendor}</span>
      //     </div>
      //   );
      // }
      let value = data;

      // console.log(type, value, is_default, data, "datasets");

      if (type === 2) {
        return CommonFn.formateDate(value, true);
      } else if (type === 4 || type === 5 || type === 15) {
        let data1 = value?.map((o) => o.label);
        return String(data1);
      } else if (type === 7 || type === 8 || type === 10) {
        if (Array.isArray(value)) {
          let data2 = value?.map((o) => o.name);
          return String(data2);
        } else {
          return "";
        }
      } else if (type === 11) {
        return (
          <SlateInputField
            readOnly={true}
            isToolBar={false}
            placeholder=""
            style={{
              margin: 0,
            }}
            as={SlateInputField}
            initValue={value}
            formControlStyle={{ border: "none", borderColor: "#fff", overflow: "hidden", margin: "0px !important" }}
            textContainerStyle={{ minHeight: "10px !important", margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }}
          />
        );
      } else if ([1, 3, 12, 13, 14, 19].includes(type)) {
        return value;
      } else if (type === 20) {
        return (
          <div>
            {value && value.value && value.value !== "" && <div>{currencyCodeMap[value?.type?.value] + "" + value?.value}</div>}
            {value && value.value === "" && <div>-</div>}
          </div>
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const classes = this.props.classes;

    const { order, orderBy, page, rowsPerPage, dense, selectedItemIds, query, headers } = this.state;
    const { searchPlaceHolder, fetchVendorCatalogProgress, fetchVendorServiceCatalogProgress, catalogViewsAddProgress } = this.props;
    let isLoading = fetchVendorCatalogProgress || fetchVendorServiceCatalogProgress;

    let data = {};
    let allViews = [{ id: "contract_view", name: "Contract View" }];
    if (this.props.type === "vendor" && this.props.VendorCatalog) {
      allViews.push(...this.props.vendorCatalogViews);
      data = this.props.VendorCatalog || {};
    } else if (this.props.type === "vendorservice" && this.props.VendorServiceCatalog) {
      allViews.push(...this.props.serviceCatalogViews);
      data = this.props.VendorServiceCatalog || {};
    } else {
      return null;
    }

    return (
      <div>
        <Paper className={classes.paper}>
          <AppBar position="static" color="default" className={classes.answerBox}>
            <div className={classes.topHeader}>
              <div className={classes.showBtnSection}>
                <Tooltip title="Views">
                  <IconButton
                    aria-label="filter list"
                    onClick={(event) => {
                      this.props.showCatalogfieldsDialog();
                    }}
                  >
                    <SettingsOutlined />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </AppBar>
          <div className={classes.searchBox}>
            <div>
              {/* <Typography variant={"h6"} className={classes.filterTitle}>
                Search
              </Typography>
              
              {!this.state.showContractView && (
                <TextField
                  className={classes.autoSelectInput}
                  value={query}
                  variant="outlined"
                  placeholder={searchPlaceHolder}
                  type="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onInput={(e) => {
                    this.updateSearchQuery(e.target.value);
                  }}
                />
              )} */}

              {this.props.appliedFilters?.length > 0 && (
                <Button
                  className={classes.clearFilterBtn}
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.props.onApplyFilter([]);
                  }}
                >
                  Clear Filter
                </Button>
              )}
            </div>
            <div className={classes.statusFilter}>
              <Typography variant={"h6"} className={classes.filterTitle}>
                Select View
              </Typography>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <MaterialSelect
                  value={this.state.selectedView}
                  onChange={(e) => {
                    if (e.target.value === "contract_view") {
                      this.setState({ showContractView: true, selectedView: "contract_view" });
                    } else if (e.target.value === "create") {
                      this.setState({ showContractView: false });
                      this.props.showCatalogfieldsDialog();
                    } else {
                      this.setState({ showContractView: false });
                      this.selectView(e.target.value);
                    }
                    this.props.history.push("/app/vendors/org-vendors?activeView=table&selectedView=" + e.target.value);
                  }}
                >
                  {allViews.map((item, i) => (
                    <MenuItem value={item.id} style={{ fontSize: 13 }}>
                      {item.name}
                    </MenuItem>
                  ))}
                  {allViews?.length === 0 && (
                    <MenuItem value={"create"} style={{ fontSize: 13 }}>
                      Create View
                    </MenuItem>
                  )}
                </MaterialSelect>
              </FormControl>
            </div>
          </div>
          {this.state.showContractView ? (
            <ContractTable />
          ) : (
            <>
              <TableContainer className={classes.tableContainer} style={{ maxHeight: this.props.tableMaxHeight }}>
                <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} aria-label="enhanced table">
                  <TableHead>
                    <TableRow>
                      {headers.map((headCell, i) => {
                        return (
                          <TableCell
                            key={i}
                            align={"left"}
                            padding={"normal"}
                            style={{ left: "unset", whiteSpace: "nowrap", minWidth: headCell.width ? headCell.width : 200 }}
                            sortDirection={orderBy === headCell.item_name ? order : false}
                          >
                            {headCell.sort && (
                              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={this.createSortHandler(headCell.id)}>
                                {headCell.item_name}
                              </TableSortLabel>
                            )}
                            {!headCell.sort && !headCell.isEmpty && <span>{headCell.item_name}</span>}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      {headers.map((headCell, i) => {
                        return (
                          <TableCell
                            key={"search" + i}
                            align={"left"}
                            padding={"normal"}
                            style={{ left: "unset", whiteSpace: "nowrap", minWidth: headCell.width ? headCell.width : 200 }}
                            sortDirection={orderBy === headCell.item_name ? order : false}
                          >
                            <SearchFilterComponent
                              type={headCell.type}
                              title={headCell.item_name}
                              additionalData={headCell.additionalData}
                              appliedFilters={this.props.appliedFilters}
                              applyFilters={(value, operand) => {
                                let appliedFilters = this.props.appliedFilters || [];
                                let index = appliedFilters.findIndex((filter) => filter.id === headCell.id);
                                if (index !== -1) {
                                  if (value) {
                                    appliedFilters[index] = { ...headCell, value: value, operand: operand };
                                  } else {
                                    appliedFilters.splice(index, 1);
                                  }
                                } else {
                                  appliedFilters.push({ ...headCell, value: value, operand: operand });
                                }
                                this.props.onApplyFilter(appliedFilters);
                              }}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isLoading &&
                      this.state.selectedItems?.length > 0 &&
                      !catalogViewsAddProgress &&
                      data?.items?.map((row, index) => {
                        return (
                          <TableRow hover onClick={(event) => this.routeChange(row)} tabIndex={-1} key={index} style={{ cursor: "pointer" }}>
                            {headers.map((header, i) => {
                              // if (i === 0 && this.props.type === "vendorservice") {
                              //   return <TableCell>{this.printData({ vendor: row?.vendor?.name, service: row?.service?.name }, header.type, i)}</TableCell>;
                              // } else if (i === 0 && this.props.type === "vendor") {
                              //   return <TableCell>{this.printData({ name: row[header.slug], logo: row["logo"] }, header.type, i)}</TableCell>;
                              // } else {
                              return (
                                <TableCell>
                                  <div className={classes.textWrap}>{this.printData(row[header.slug], header.type, i)}</div>{" "}
                                </TableCell>
                              );
                              // }
                            })}
                          </TableRow>
                        );
                      })}

                    {!isLoading && this.state.selectedItems?.length === 0 && (
                      <TableRow>
                        <TableCell className={classes.nameCol} align="center">
                          Select view to see data
                        </TableCell>
                      </TableRow>
                    )}

                    {(isLoading || catalogViewsAddProgress) && (
                      <TableRow>
                        <TableCell colSpan={headers.length} className={classes.nameCol}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}

                    {!(isLoading || catalogViewsAddProgress) && data?.items?.length <= 0 && (
                      <TableRow>
                        <TableCell colSpan={headers.length} align="center" className={classes.nameCol}>
                          No Items
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50, 100]}
                component="div"
                count={data.total || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
        {this.props.showDialog && <ViewsDialog createFieldsView={this.createFieldsView} selectedItemIds={selectedItemIds} type={this.props.type} />}
      </div>
    );
  }
}

TableContent.defaultProps = {
  isShowSearch: false,
  searchPlaceHolder: "Search by Name",
  page: 0,
  rowsPerPage: 20,
  query: "",
  activeWorkflowTab: 1,
  tableMaxHeight: "50vh",
};

export default connector(compose(withRouter, withStyles(styles))(TableContent));
