import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, CircularProgress, IconButton, Paper,
  Typography, MenuItem, FormControl, Select
} from '@material-ui/core';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

import { fetchProductTasks, deleteProductTask, fetchProductFollowUpTasks, fetchProductRiskTasks, fetchProductTaskOnly } from "redux/evaluation/productTask/action";
import { showSnackBar } from "redux/snackbar/action";
import { CommonFn } from "services/commonFn";


const connectedProps = (state) => ({
  fetchProductTasksProgress: state.productTasks.fetchProductTasksProgress,
  fetchProductTasksError: state.productTasks.fetchProductTasksError,
  productTasks: state.productTasks.productTasks,
  deleteProductTaskProgress: state.productTasks.deleteProductTaskProgress,
  deleteProductTaskError: state.productTasks.deleteProductTaskError,
  fetchProductOnlyTasksProgress: state.productTasks.fetchProductOnlyTasksProgress,
  fetchProductOnlyTasksError: state.productTasks.fetchProductOnlyTasksError,
  fetchProductRiskTasksProgress: state.productTasks.fetchProductRiskTasksProgress,
  fetchProductRiskTasksError: state.productTasks.fetchProductRiskTasksError,
  fetchProductFollowUpTasksProgress: state.productTasks.fetchProductFollowUpTasksProgress,
  fetchProductFollowupTasksError: state.productTasks.fetchProductFollowupTasksError,
  productOnlyTasks: state.productTasks.productOnlyTasks,
  productRiskTasks: state.productTasks.productRiskTasks,
  productFollowupTasks: state.productTasks.productFollowupTasks,
  updateNewTaskProgress: state.productTasks.updateNewTaskProgress,
  updatedTaskData: state.productTasks.updatedTaskData,
  updateNewTaskError: state.productTasks.updateNewTaskError,
  addNewTaskProgress: state.productTasks.addNewTaskProgress,
  addNewTaskError: state.productTasks.addNewTaskError,
});

const connectionActions = {
  fetchProductTasks: fetchProductTasks,
  deleteProductTask: deleteProductTask,
  showSnackBar: showSnackBar,
  fetchProductFollowUpTasks: fetchProductFollowUpTasks,
  fetchProductRiskTasks: fetchProductRiskTasks,
  fetchProductTaskOnly: fetchProductTaskOnly
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    "& [class*='editableField']": {
      minHeight: 'auto !important',
    },
  },
  nameCol: {
    width: '40%'
  },
  statusCol: {
    width: '10%'
  },
  dueDateCol:{
    width: '20%'
  },
  actionCol: {
    textAlign: 'center',
    width: '20%'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25
  },
  responseFilter: {
    marginRight: 20
  },
  filterTitle1: {
    marginBottom: 1,
    color: "#282D30",
    fontSize: 14,
  },
  formControl: {
    width: 200,
    "& > div": {
      color: "hsl(0,0%,50%)",
      fontSize: 14,
      background: '#fff'
    },
  },
});

class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeFilter: 0,
      priorityFilter: 0,
      statusFilter: 0,
      tasks: [],
      loader: true,
      priorityMap: {
        1: 'Low',
        2: 'Medium',
        3: 'High',
      },
      statusMap: {
        1: 'New',
        2: 'In Progress',
        3: 'Completed',
      },
      page: 0,
      pageSize: 5,
      totalTasks: 0,
      isDataLoading: true,
      typeFilterDropdown: [{
        name: 'All',
        value: 0
      }, {
        name: 'Task',
        value: 1
      }, {
        name: 'Follow Up',
        value: 2
      }],
      priorityFIlterDropdown: [{
        name: 'All',
        value: 0
      }, {
        name: 'Low',
        value: 1
      }, {
        name: 'Medium',
        value: 2
      }, {
        name: 'High',
        value: 3
      }],
      statusFilterDropdown: [{
        name: 'All',
        value: 0
      }, {
        name: 'New',
        value: 1
      }, {
        name: 'In Progress',
        value: 2
      }, {
        name: 'Completed',
        value: 3
      }],
    }
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

    this.taskFiltering = this.taskFiltering.bind(this);
    this.priorityFiltering = this.priorityFiltering.bind(this);
    this.statusFiltering = this.statusFiltering.bind(this);
  }

  componentDidMount() {
    this.setState({
      typeFilter: this.props.filterTypes,
    }, () => {
      this.fetchData()
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchProductTasksProgress === false && prevProps.fetchProductTasksProgress === true) {
      if (this.props.fetchProductTasksError === null) {
        this.setState({
          loader: false,
          tasks: this.props.productTasks?.Data,
          totalTasks: this.props.productTasks?.total,
          isDataLoading: false
        })
      }
    }

    if (!this.props.addNewTaskProgress && prevProps.addNewTaskProgress) {
      if (this.props.addNewTaskError === null) {
        this.fetchData()
      }
    }

    if (this.props.deleteProductTaskProgress === false && prevProps.deleteProductTaskProgress === true) {
      if (this.props.deleteProductTaskError === null) {
        this.fetchData()
        this.props.showSnackBar("Task deleted successfully.", "success", 3000);
      } else {
        this.props.showSnackBar("Something went wrong to delete task.", "error", 3000)
      }
    }

    if (this.props.fetchProductOnlyTasksProgress === false && prevProps.fetchProductOnlyTasksProgress === true && this.props.type === 1) {
      if (this.props.fetchProductOnlyTasksError === null) {
        // console.log(this.props.productOnlyTasks, 'productOnlyTasks')
        this.setState({
          loader: false,
          tasks: this.props.productOnlyTasks?.Data,
          totalTasks: this.props.productOnlyTasks?.total,
          isDataLoading: false
        })
      }else{
        this.setState({
          loader: false,
          isDataLoading: false
        })
      }
    }

    if (this.props.fetchProductRiskTasksProgress === false && prevProps.fetchProductRiskTasksProgress === true && this.props.type === 3) {
      if (this.props.fetchProductRiskTasksError === null) {
        // console.log(this.props.productRiskTasks, 'productRiskTasks')
        this.setState({
          loader: false,
          tasks: this.props.productRiskTasks?.Data,
          totalTasks: this.props.productRiskTasks?.total,
          isDataLoading: false
        })
      }else{
        this.setState({
          loader: false,
          isDataLoading: false
        })
      }
    }

    if (this.props.fetchProductFollowUpTasksProgress === false && prevProps.fetchProductFollowUpTasksProgress === true && this.props.type === 2) {
      if (this.props.fetchProductFollowupTasksError === null) {
        // console.log(this.props.productFollowupTasks, 'productFollowupTasks')
        this.setState({
          loader: false,
          tasks: this.props.productFollowupTasks?.Data,
          totalTasks: this.props.productFollowupTasks?.total,
          isDataLoading: false
        })
      }else{
        this.setState({
          loader: false,
          isDataLoading: false
        })
      }
    }

    if (!this.props.updateNewTaskProgress && prevProps.updateNewTaskProgress) {
      if (this.props.updateNewTaskError === null) {
        const { from, type } = this.props;
        if (from === 'EVALUATION') {
          this.fetchProductTasksData();
        } else if (from === 'ORG_PRODUCT_PAGE') {
          if (type === 1) {
            this.fetchTasksOnly();
          } else if (type === 2) {
            this.fetchFollowUpTasks()
          } else if (type === 3) {
            this.fetchRiskTasks();
          }
        }
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

  }

  fetchData() {
    const { productId, requirementIds, from, type } = this.props;
    const { typeFilter, priorityFilter, statusFilter } = this.state;
    var queryParams = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      requirement_ids: requirementIds,
      types: typeFilter === 0 ? [1, 2, 3] : [typeFilter],
      priorities: priorityFilter === 0 ? [1, 2, 3] : [priorityFilter],
      status: statusFilter === 0 ? [1, 2, 3] : [statusFilter]
    }
    if (from === 'EVALUATION') {
      this.props.fetchProductTasks(productId, queryParams);
    } else if (from === 'ORG_PRODUCT_PAGE') {
      if (type === 1) {
        this.props.fetchProductTaskOnly(productId, queryParams);
      } else if (type === 2) {
        this.props.fetchProductFollowUpTasks(productId, queryParams);
      } else if (type === 3) {
        this.props.fetchProductRiskTasks(productId, queryParams);
      }
    }
  }

  fetchProductTasksData() {
    const { productId, requirementIds } = this.props;
    const { typeFilter, priorityFilter, statusFilter } = this.state;
    var queryParams = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      requirement_ids: requirementIds,
      types: [typeFilter],
      priorities: [priorityFilter],
      status: [statusFilter]
    }
    this.props.fetchProductTasks(productId, queryParams);
  }

  fetchTasksOnly() {
    const { productId, requirementIds } = this.props;
    const { typeFilter, priorityFilter, statusFilter } = this.state;
    var queryParams = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      requirement_ids: requirementIds,
      types: [typeFilter],
      priorities: priorityFilter === 0 ? [1, 2, 3] : [priorityFilter],
      status: statusFilter === 0 ? [1, 2, 3] : [statusFilter]
    }
    this.props.fetchProductTaskOnly(productId, queryParams);
  }

  fetchFollowUpTasks() {
    const { productId, requirementIds } = this.props;
    const { typeFilter, priorityFilter, statusFilter } = this.state;
    var queryParams = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      requirement_ids: requirementIds,
      types: [typeFilter],
      priorities: priorityFilter === 0 ? [1, 2, 3] : [priorityFilter],
      status: statusFilter === 0 ? [1, 2, 3] : [statusFilter]
    }
    this.props.fetchProductFollowUpTasks(productId, queryParams);
  }

  fetchRiskTasks() {
    const { productId, requirementIds } = this.props;
    const { typeFilter, priorityFilter, statusFilter } = this.state;
    var queryParams = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      requirement_ids: requirementIds,
      types: [typeFilter],
      priorities: priorityFilter === 0 ? [1, 2, 3] : [priorityFilter],
      status: statusFilter === 0 ? [1, 2, 3] : [statusFilter]
    }
    this.props.fetchProductRiskTasks(productId, queryParams);
  }

  handleChangePage(e, newPage) {
    this.setState({
      page: newPage,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleChangeRowsPerPage(e) {
    this.setState({
      page: 0,
      pageSize: e.target.value,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleEdit(task) {
    this.props.onEdit(task)
  }

  handleDelete(task) {
    const { productId } = this.props;
    this.props.deleteProductTask(productId, task.ID, task.Type, this.props.from);
  }

  taskFiltering(newValue) {
    this.setState({
      typeFilter: newValue
    }, () => {
      this.fetchData()
    })
  }

  priorityFiltering(newValue) {
    this.setState({
      priorityFilter: newValue
    }, () => {
      this.fetchData()
    })
  }

  statusFiltering(newValue) {
    this.setState({
      statusFilter: newValue
    }, () => {
      this.fetchData()
    })
  }

  render() {
    const { classes, showTypeFilter, showPriorityFilter, showStatusFilter } = this.props;
    const { loader, tasks, priorityMap, statusMap, page, pageSize, totalTasks, isDataLoading,
      typeFilter, statusFilter, priorityFilter,
      typeFilterDropdown, priorityFIlterDropdown, statusFilterDropdown } = this.state;
      
      var noTaskMsg = 'No Tasks'
    if (typeFilter === 1) {
        noTaskMsg = 'No Tasks';
    }else if(typeFilter === 2){
      noTaskMsg = 'No Follow ups';
    }else if(typeFilter === 3){
      noTaskMsg = 'No Risks';
    }

    if (loader) {
      return <div style={{ textAlign: 'center', marginTop: 20 }}>
        <CircularProgress />
      </div>
    }

    return (
      <div className={classes.root} key={this.props.key}>
        <div className={classes.filters}>
          {showTypeFilter && <div className={classes.responseFilter}>
            <Typography variant={"h4"} className={classes.filterTitle1}>
              Select Task Type
            </Typography>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <Select
                value={typeFilter}
                onChange={(e) => {
                  this.taskFiltering(e.target.value)
                }}
              >
                {typeFilterDropdown.map((itm) => {
                  return <MenuItem value={itm.value} style={{ fontSize: 13 }}>
                    {itm.name}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </div>}
          {showPriorityFilter && <div className={classes.responseFilter}>
            <Typography variant={"h4"} className={classes.filterTitle1}>
              Select Priority
            </Typography>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <Select
                value={priorityFilter}
                onChange={(e) => {
                  this.priorityFiltering(e.target.value)
                }}
              >
                {priorityFIlterDropdown.map((itm) => {
                  return <MenuItem value={itm.value} style={{ fontSize: 13 }}>
                    {itm.name}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </div>}
          {showStatusFilter && <div className={classes.responseFilter}>
            <Typography variant={"h4"} className={classes.filterTitle1}>
              Select Status
            </Typography>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <Select
                value={statusFilter}
                onChange={(e) => {
                  this.statusFiltering(e.target.value)
                }}
              >
                {statusFilterDropdown.map((itm) => {
                  return <MenuItem value={itm.value} style={{ fontSize: 13 }}>
                    {itm.name}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </div>}
        </div>
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ maxHeight: 560 }}>
            <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.nameCol}>Task</TableCell>
                  <TableCell className={classes.statusCol}>Priority</TableCell>
                  <TableCell className={classes.statusCol}>Status</TableCell>
                  <TableCell className={classes.dueDateCol}>Due Date</TableCell>
                  {this.props.updateAccess && (<TableCell className={classes.actionCol}>Action</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isDataLoading && tasks.slice(page * pageSize, page * pageSize + pageSize).map((task) => {
                  return <TableRow key={'task_' + task.ID}>
                    <TableCell className={classes.nameCol}>
                      <SlateInputField
                        readOnly={true}
                        isToolBar={false}
                        placeholder=""
                        style={{
                          margin: 0,
                        }}
                        as={SlateInputField}
                        initValue={task?.Name}
                        formControlStyle={{ border: 'none', borderColor: '#fff', overflow: 'hidden', margin: '0px !important' }}
                        textContainerStyle={{ minHeight: '10px !important', margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }} />
                    </TableCell>
                    <TableCell className={classes.statusCol}>{priorityMap[task?.Priority]}</TableCell>
                    <TableCell className={classes.statusCol}>{statusMap[task?.Status]}</TableCell>
                    <TableCell className={classes.dueDateCol}>{task?.DueDate === null || task?.DueDate === '' ? '-' : CommonFn.formateDate(task?.DueDate, true)}</TableCell>
                    {this.props.updateAccess && (<TableCell className={classes.actionCol}>
                      <IconButton
                        onClick={() => this.handleEdit(task)}
                      >
                        <Image
                          src={editIcon}
                        />
                      </IconButton>

                      <IconButton
                        onClick={() => this.handleDelete(task)}
                      >
                        <Image
                          src={deleteIcon}
                        />
                      </IconButton>

                    </TableCell>)}
                  </TableRow>
                })}

                {(!isDataLoading && (tasks === undefined || tasks === null || tasks.length <= 0)) && <TableRow>
                  <TableCell colSpan={this.props.updateAccess ? 5 : 4} style={{ textAlign: 'center' }} className={classes.nameCol}>
                    {noTaskMsg}
                  </TableCell>
                </TableRow>}

                {isDataLoading && <TableRow>
                  <TableCell colSpan={this.props.updateAccess ? 5 : 4} style={{ textAlign: 'center' }} className={classes.nameCol}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>}

              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={totalTasks || 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Tasks));
