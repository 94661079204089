import React from 'react';
import Select from 'react-select';
import classnames from "classnames";
import CommonCss from 'commonCss';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  Typography, CircularProgress, Grid, Card, CardContent, CardHeader, Avatar,
  FormControlLabel, Checkbox, InputAdornment, Slider, Button, FormControl
} from '@material-ui/core';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import _ from "underscore";
import Radio from "@material-ui/core/Radio";
import RadioGroup from '@material-ui/core/RadioGroup';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import deleteIcon from "assets/images/delete.svg";
import CalendarImage from "assets/images/calendar.svg";
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";
import Image from 'Components/Common/image.jsx';

import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";

import * as Environment from "util/Environment";
import axios from "axios";
import { CommonFn } from "services/commonFn";
import { showSnackBar } from "redux/snackbar/action";
import { fetchOrgGroup } from "redux/org/group/action";
import { searchUsers } from "redux/usersSettings/action";

import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import DefaultImage from "assets/images/evaluation/default.svg";
import TextField from '@material-ui/core/TextField';
import { fetchRecommendedProducts } from "redux/product/action";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { currencyOptions, currencyCodeMap } from "util/Currency";

import * as Validator from "util/Validation";
import FileAncher from '../FileAncher';

var currencyStyles = {
  control: styles => ({ ...styles, height: 56 }),
  menuPortal: base => ({ ...base, zIndex: 10 }),
  option: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  })
};

const connectedProps = (state) => ({
  groups: state.orgGroup.groups,
  users: state.usersSettings.users,
  similarExistingProductProgress: state.orgProduct.similarExistingProductProgress,
  similarExistingProducts: state.orgProduct.similarExistingProducts,
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  products: state.products.recommendedProducts,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchUsers: searchUsers,
  fetchOrgGroup: fetchOrgGroup,
  fetchRecommendedProducts: fetchRecommendedProducts,
};

export const itemTypes = [
  { label: 'Simple Text', value: 1 },
  { label: 'Date', value: 2 },
  { label: 'Binary', value: 3 },
  { label: 'Single Select', value: 4 },
  { label: 'Multi Select', value: 5 },
  { label: 'Users', value: 7 },
  { label: 'Teams', value: 8 },
  { label: 'File', value: 9 },
  { label: 'Products', value: 10 }, //Pending
  { label: 'Rich Text', value: 11 },
  { label: 'Email', value: 12 },
  { label: 'Phone', value: 13 },
  { label: 'Number', value: 15 },
  { label: 'URL', value: 14 },
  { label: 'Currency', value: 16 },
  { label: 'Existing Products', value: 17 },
]

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    // marginBottom: '16px',
    width: "100%",
    "& ul.answers": {
      listStyle: "none",
      paddingLeft: 0,
      "& li": {
        border: "1px solid #EBEBEB",
        bordeRadius: "4px",
        paddingLeft: "8px",
        marginBottom: "8px",
        "& span": {
          fontSize: 13,
          color: "#6D7278",
        },
      },
    },
    "& [class*='editableField']": {
      maxHeight: '10vh !important;',
      minHeight: '10vh !important',
    },
    "& .react-datepicker__input-container > div": {
      marginTop: 10
    }
  },
  itemTitle: {
    fontWeight: 400,
    color: '#282D30',
    fontSize: 14,
    margin: '30px 0px 0px'
  },
  formControl: {
    ...CommonCss.formControl,
  },
  creatable: {
    padding: 0
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 16
  },
  searchAndSelect: {
    padding: 0,
    "& .select__control, & .select__control:hover": {
      border: 'none',
      height: 'inherit',
      boxShadow: 'none',
      minHeight: 44
    },
    "& .select__multi-value, & .select__multi-value__label": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20,
      padding: theme.spacing(.3, .6),
      fontSize: 13
    },
    "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
      background: '#4A87F8',
      color: '#fff',
      borderRadius: 20
    },
  },
  deleteIcon: {
    position: "absolute",
    right: 16,
    top: 13,
    cursor: "pointer",
  },
  cardSection: {
    position: "relative",
  },
  contactCardHeader: {
    flexDirection: "column",
    padding: "16px 4px !important",
    "& [class*='MuiCardHeader-avatar-']": {
      margin: "0px",
      marginBottom: 12,
    },
    "& [class*='MuiCardHeader-content']": {
      width: "100%",
    },
    "& [class*=MuiTypography-displayBlock]": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      margin: "0px -19px 0px 0px",
    },
  },
  addNewChamp: {
    display: "block",
    textAlign: "center",
    color: "#bdbdbd",
    "& span": {
      display: "block",
      cursor: "pointer",
      color: "#000000",
    },
  },
  addContact: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    margin: "0px -19px 0px 0px",
  },
  dialogContent: {
    minHeight: 170,
    overflowY: 'hidden',
    "& [class*=select__menu]": {
      maxHeight: 100
    }
  },
  customTypeFields: {
    marginBottom: 15,
    marginTop: 15,
    '& [class*="MuiFormControl-root"]': {
      width: '100%'
    },
    '& [class*="MuiInputBase-input"]': {
      textAlign: 'left'
    }
  },
  requiredField: {
    // color: 'red'
  },
  ratingRoot: {
    // background: '#f1f1f1',
    color: "#fff",
    padding: '1px',
    width: '100%'
  },
  rating: {
    marginTop: '10px',
    marginBottom: '5px',
    '& [class*="MuiSlider-markActive"]': {
      background: '#bfb4b4'
    }
  },
  slider: {
    background: '#f7f7f7',
    display: 'flex',
    alignItems: 'center',
    padding: '2px 10px',
    borderRadius: '10px',
  },
  sliderCount: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
  },
  thumb: {
    color: "#1f73b7"
  },
  track: {
    backgroundColor: "#1f73b7"
  },
  rail: {
    backgroundColor: "#1f73b7"
  },
  errMessage: {
    margin: 0,
    fontSize: 12,
    color: '#f44336'
  },
  resListItem: {
    marginRight: '5px',
    marginLeft: '5px',
    borderRadius: '4px',
    border: '1px solid #707070',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: theme.spacing(1.8),
    color: '#707070',
    padding: '3px',
    width: '100%'
  },
  yourScoreRes: {
    marginRight: '5px',
    marginLeft: '5px',
    borderRadius: '4px',
    border: '1px solid #707070',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: theme.spacing(1.8),
    color: '#707070',
    padding: '3px',
    width: '100px'
  },
  counter1: {
    textAlign: 'left',
    paddingLeft: 6
  },
  counter2: {
    textAlign: 'right',
    paddingRight: 6
  },
  counterItem: {
    color: '#1f73b7'
  },
  product_list: {
    marginBottom: 10
  },
  productList: {
    position: 'relative',
    '& [class*=LayeredCard-root]': {
      marginTop: 0,
    },
  },
  deleteImage: {
    position: "absolute",
    top: 10,
    zIndex: 20,
    right: 10,
    padding: 4,
    cursor: "pointer",
  },
  tickMark: {
    color: "#4b86f8",
    marginLeft: 10,
    border: "1px solid #4b86f8",
    // marginRight: 10,
    padding: 6,
    minWidth: "50px !important",
    "&:hover": {
      color: "#4b86f8",
    },
  },
  noMarginTop: {
    marginTop: 0
  },
  description: {
    color: '#4B4B4B',
    fontSize: 12,
    margin: 0,
    paddingTop: 6
  },
  subHead: {
    color: "#AFAFAF",
    fontSize: "12px",
    marginBottom: "16px",
    display: "block",
  },
  currencyTypeFields: {
    marginBottom: 15,
    marginTop: 15,
    '& [class*="MuiFormControl-root"]': {
      width: '100%'
    },
    '& [class*="MuiInputBase-input"]': {
      textAlign: 'left'
    },
    '& [class*="MuiSelect-root"]': {
      height: 56
    }
  },
  characterLimit: {
    color: 'rgb(112, 112, 112)',
    fontSize: 10,
    margin: 0,
    padding: 3,
    backgroundColor: '#f1f1f1',
    width: 'max-content',
    borderRadius: 3,
  },
  inputRoot: {
    '& [class*="MuiInputBase-input"]': {
      textAlign: 'left',
      padding: 10,
    },
  }
});

class RequirementReply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      text_value: '',
      selectedOption: '',
      selectedOptions: [],
      selectedFiles: [],
      fileNames: [],
      selectedUsers: [],
      selectedTeams: [],
      dialogTitle: '',
      isUser: false,
      isOpenDialog: false,
      similarProducts: {},
      isValidationError: false,
      isEditProducts: false,
      productList: [],
      selectedProducts: [],
      formError: '',
      otherValue: '',
      currencyType: {
        label: 'USD',
        value: 'USD'
      }
    }
    this.fileRef = React.createRef();
    this.updateDate = this.updateDate.bind(this);
    this.updateTextValue = this.updateTextValue.bind(this);
    this.setSelectedOption = this.setSelectedOption.bind(this);
    this.setSelectedOptions = this.setSelectedOptions.bind(this);
    this.fileSelectHandler = this.fileSelectHandler.bind(this);
    this.producDetail = this.producDetail.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateURL = this.validateURL.bind(this);
    this.saveResponse = this.saveResponse.bind(this);
    this.clearError = this.clearError.bind(this);
    this.setSingleSelectOption = this.setSingleSelectOption.bind(this);
    this.setMultiSelectOptions = this.setMultiSelectOptions.bind(this);
    this.updateOtherValue = this.updateOtherValue.bind(this);
    this.setOtherOption = this.setOtherOption.bind(this);
  }


  componentDidMount() {
    this.initValues();
    if (this.props.type === 19) {
      this.fetchProduct();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.initValues();
    }

    if (this.props.selectedOptions !== prevProps.selectedOptions) {
      this.initValues();
    }
    if (this.props.index !== prevProps.index) {
      this.initValues();
    }

    // if (this.props.similarExistingProductProgress === false && prevProps.similarExistingProductProgress === true) {
    //   if (this.props.similarExistingProducts !== null) {
    //     alert(1)
    //     this.setState({
    //       similarProducts: this.props.similarExistingProducts
    //     })
    //   }
    // }

  }

  initValues() {
    let { type, value, selectedOptions, additional_data, from } = this.props;
    if (type === 7 || type === 1 || type === 8 || type === 9 || type === 10 || type === 12 || type === 5) {
      this.updateTextValue(value, false);
    } else if (type === 11) {
      this.updateDate(value, false);
    } else if (type === 4) {
      // console.log('------------------14')
      // console.log(value)
      this.setBinaryOption(value, false);
    } else if (type === 3) {
      let option;
      // console.log('---------Initttttttttttt--------')
      // console.log(additional_data, 'additional_data')
      // console.log(selectedOptions, 'selectedOptions')
      // console.log(value, 'value')

      if (selectedOptions !== undefined && selectedOptions !== null && selectedOptions.length > 0) {
        additional_data.forEach(function (itm) {
          let id = (from === 'SELLER' ? itm.ID : itm.ID)
          if (id === selectedOptions[0].OptionID) {
            option = {
              value: itm.ID,
              label: itm.Title
            };
          }
        });
      }
      this.setSingleSelectOption(option, false);
    } else if (type === 2) {
      let options = [];
      // console.log('-----------------')
      // console.log(additional_data, 'additional_data')
      // console.log(selectedOptions, 'selectedOptions')
      if (selectedOptions !== undefined && selectedOptions !== null && selectedOptions.length > 0) {
        let selectedIds = [];
        selectedOptions.forEach(function (si) {
          selectedIds.push(si.OptionID);
        })
        additional_data.forEach(function (itm) {
          if (selectedIds.indexOf(itm.ID) !== -1) {
            options.push({
              value: itm.ID,
              label: itm.Title
            });
          }
        });
      }
      this.setMultiSelectOptions(options, false);
    } else if (type === 13) {
      if (value !== undefined && value !== null && value !== '') {
        let newValue;
        try {
          newValue = JSON.parse(value);
        } catch { }
        if (newValue !== undefined && newValue !== null && newValue !== '' && newValue.value !== undefined && newValue.value !== null && newValue.value !== '') {
          this.setState({
            currencyType: newValue.type,
            text_value: newValue.value
          })
        } else if (value.type !== undefined && value.type !== null && value.type !== '') {
          this.setState({
            currencyType: value.type,
            text_value: value.value
          })
        } else {
          this.updateCurrencyValue(value, false);
        }
      } else {
        this.updateCurrencyValue(value, false);
      }
    }
    // else if (type === 14) {
    //   // console.log('------------------14')
    //   // console.log(value)
    //   if (value !== undefined && value !== null && value !== '') {
    //     if (value.indexOf('Other-') !== -1) {
    //       let newValue = value.replace('Other-', '');
    //       this.setOtherOption('9999999', false);
    //       this.updateTextValue(newValue, false);
    //     } else {
    //       this.setSelectedOption(value, false);
    //     }
    //   }
    // } 
    else if (type === 15 || type === 14) {
      let options = [];
      // console.log(selectedOptions, 'selectedOptions')
      let otherValue = '';
      selectedOptions.forEach((itm) => {
        if (itm.OptionID !== undefined) {
          options.push(itm.OptionID)
        } else {
          options.push(itm)
        }
        if (itm.Answer.indexOf('Other-') !== -1) {
          otherValue = itm.Answer;
        }
        // if(from === 'BUYER'){
        //   options.push(itm.OptionID)
        // }else if(from === 'SELLER'){
        //   options.push(itm)
        // }
      });
      this.setSelectedOptions(options, false);
      // console.log(otherValue,'--------------------1555')
      if (otherValue !== undefined && otherValue !== null && otherValue !== '') {
        if (otherValue.indexOf('Other-') !== -1) {
          let newValue = otherValue.replace('Other-', '');
          this.setOtherOption('9999999', false);
          this.updateTextValue(newValue, false);
        }
      }
    } else if (type === 16) {
      this.setSelectedUsersOnLoad(value, false);
    } else if (type === 17) {
      this.setSelectedTeamsOnLoad(value, false);
    } else if (type === 6) {
      if (value !== undefined && value !== null && value !== '') {
        let newValue;
        try {
          newValue = JSON.parse(value);
        } catch { }
        if (newValue !== undefined && newValue !== null && newValue !== '') {
          if (Array.isArray(newValue)) {
            this.setState({
              selectedFiles: newValue,
              fileNames: newValue.map(file => file.name)
            })
          } else {
            this.setState({
              selectedFiles: [{ name: newValue.name, url: newValue.url }],
              fileNames: [newValue.name]
            })
          }
        } else if (value !== undefined && value !== null && value !== '') {
          if (Array.isArray(value)) {
            this.setState({
              selectedFiles: value,
              fileNames: value.map(file => file.name)
            })
          } else {
            this.setState({
              selectedFiles: [{ name: value.name, url: value.url }],
              fileNames: [value.name]
            })
          }
        }
      }
    } else if (type === 18) {
      this.setSelectedProducts(value, false);
    }
  }

  updateDate(date, isUpdate) {
    this.setState({
      selectedDate: (date !== undefined && date !== null && date !== '') ? new Date(date) : null
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedDate)
      }
    })
  }

  updateTextValue(value, isUpdate, isChangeFound = false) {
    let { type } = this.props;
    this.setState({
      text_value: (value !== undefined && value !== null && value !== '') ? value : '',
      formError: '',
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.text_value)
      }
      if ([1, 5, 7, 8, 9, 10, 12].includes(type) && isChangeFound) {
        this.props.updateLocalOnChange(this.state.text_value)
      }
    })
  }

  updateCurrencyValue(value, isUpdate, isChangeFound = false) {
    // console.log(value)
    let { type } = this.props;
    this.setState({
      text_value: (value !== undefined && value !== null && value !== '') ? value : ''
    }, () => {
      if (isUpdate) {
        this.props.onChange({
          type: this.state.currencyType,
          value: this.state.text_value
        })
      }
      if (type === 13 && isChangeFound) {
        this.props.updateLocalOnChange({
          type: this.state.currencyType,
          value: this.state.text_value
        })
      }
    })
  }

  updateCurrencyType() {
    this.props.updateLocalOnChange({
      type: this.state.currencyType,
      value: this.state.text_value
    })
  }

  updateOtherValue(value, isUpdate) {
    this.setState({
      text_value: (value !== undefined && value !== null && value !== '') ? value : '',
      formError: '',
    }, () => {
      if (isUpdate) {
        let newText = 'Other-' + this.state.text_value;
        this.props.onChange(newText, true)
      }
    })
  }

  setSelectedOption(value, isUpdate) {
    // console.log(value, 'NewwwValueRadio')
    this.setState({
      selectedOption: (value !== undefined && value !== null && value !== '') ? value : '',
      otherValue: false
    }, () => {
      if (isUpdate) {
        this.props.onChange(parseInt(this.state.selectedOption))
      }
    })
  }

  setBinaryOption(value, isUpdate) {
    // console.log(value, 'NewwwValueRadio')
    this.setState({
      selectedOption: (value !== undefined && value !== null && value !== '') ? value : '',
      otherValue: false
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedOption)
      }
    })
  }

  setOtherOption(value, isUpdate) {
    // console.log(value, 'Newww Value Other')
    this.setState({
      selectedOption: '',
      otherValue: value
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedOption, true)
      }
    })
  }

  setSelectedOptions(options, isUpdate) {
    this.setState({
      selectedOptions: (options !== undefined && options !== null && options !== '') ? options : []
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedOptions)
      }
    })
  }

  setSingleSelectOption(option, isUpdate) {
    // console.log(option, 'SingleSelectOption');
    this.setState({
      selectedOption: (option !== undefined && option !== null && option !== '') ? option : []
    }, () => {
      if (isUpdate) {
        this.props.onChange([this.state.selectedOption.value])
      }
    })
  }

  setMultiSelectOptions(options, isUpdate, isChangeFound = false) {
    // console.log(options, isUpdate, isChangeFound, 'Optionsssss')
    if (isChangeFound && (!options || options.length === 0)) {
      this.props.showSnackBar("Please select atleast one option", "error", 3000);
      return;
    }
    this.setState({
      selectedOptions: (options !== undefined && options !== null && options !== '') ? options : []
    }, () => {
      if (isUpdate) {
        let sel_options = [];
        this.state.selectedOptions.forEach(function (itm) {
          sel_options.push(itm.value);
        })
        this.props.onChange(sel_options)
      }
      if (isChangeFound) {
        let sel_options = [];
        sel_options = this.state.selectedOptions.map((itm) => {
          return {
            OptionID: itm.value
          }
        })
        this.props.updateLocalOnChange(sel_options)
      }
    })
  }

  setSelectedUsersOnLoad(options, isUpdate) {
    this.setState({
      selectedUsers: (options !== undefined && options !== null && options !== '') ? options : []
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedUsers)
      }
    })
  }

  setSelectedTeamsOnLoad(options, isUpdate) {
    this.setState({
      selectedTeams: (options !== undefined && options !== null && options !== '') ? options : []
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedTeams)
      }
    })
  }

  setSelectedUsers(user, isUpdate) {
    var users = this.state.selectedUsers;
    var index = users.findIndex(t => t.ID === user.ID)
    if (index === -1) {
      users.push(user);
    } else {
      users.splice(index, 1);
    }
    this.setState({
      selectedUsers: users
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedUsers)
      }
    })
  }

  setSelectedTeams(team, isUpdate) {
    var teams = this.state.selectedTeams;
    var index = teams.findIndex(t => t.ID === team.ID)
    if (index === -1) {
      teams.push(team);
    } else {
      teams.splice(index, 1);
    }
    this.setState({
      selectedTeams: teams
    }, () => {
      if (isUpdate) {
        this.props.onChange(this.state.selectedTeams)
      }
    })
  }

  setSelectedProducts(product, isUpdate) {
    if (isUpdate) {
      var products = this.state.selectedProducts !== null ? this.state.selectedProducts : [];
      if (product !== undefined && product !== null && product !== '') {
        products.push(product)
      }
      this.setState({
        selectedProducts: products,
        selectedOption: null
      }, () => {
        this.props.onChange(this.state.selectedProducts)
      })
    } else {
      if (product !== undefined && product !== null && product !== '') {
        this.setState({
          selectedProducts: product
        })
      }
    }
  }

  handleRemoveProduct = (index) => {
    let data = this.state.selectedProducts;
    data.splice(index, 1)
    this.setState({
      selectedProducts: data
    }, () => {
      this.props.onChange(this.state.selectedProducts)
    })
  }

  handleDeleteFile = (index) => {
    let newFileNames = [...this.state.fileNames]
    let newSelectedFiles = [...this.state.selectedFiles]
    newFileNames.splice(index, 1);
    newSelectedFiles.splice(index, 1);
    this.setState({
      selectedFiles: newSelectedFiles,
      fileNames: newFileNames
    })
    this.props.onChange(newSelectedFiles);
    this.fileRef.current.value = '';
  }

  fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ isShowLoader: true })
      const { orgId, evaluationId, workflowId } = this.props;
      const files = event.target.files;
      var formData = new FormData();
      formData.append(
        "folder",
        `${orgId}/evaluation/${evaluationId}/workflow/${workflowId}`
      );
      formData.append("fileLength", files.length);
      const timestamp = new Date().getTime();
      let nameArr = [];
      for (const key of Object.keys(files)) {
        nameArr.push(files[key].name)
        formData.append(
          `file${key}`,
          files[key],
          `${timestamp}${files[key].name}`
        );
      }
      nameArr.push(...this.state.fileNames);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/external/multi-add?t=${timestamp}`;

      axios.post(url, formData, {
        headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        let resData = response.data?.filepath || {};
        let keyData = Object.keys(resData) || [];
        let data = keyData.map((key, i) => ({
          name: nameArr[i],
          url: resData[key]
        }))
        this.setState({
          selectedFiles: [...data, ...this.state.selectedFiles],
          fileNames: nameArr,
          isShowLoader: false
        }, () => {
          this.props.onChange(this.state.selectedFiles);
        })
        this.props.showSnackBar("File uploaded", "success", 1500);
      })
        .catch(err => {
          if (err.response) {
            this.props.showSnackBar(err.response.data.error, "error", 3000);
          } else if (err.request) {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          } else {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          }
          this.setState({ isShowLoader: false });
        })
    }
  };

  producDetail = (e, product) => {
    var url = `/app/products/org-product-details/${product.ID}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null;
  };

  validateEmail(value) {
    if (value !== '') {
      if (Validator.validateEmail(value) === false) {
        this.setState({
          isValidationError: true
        })
      } else {
        this.setState({
          isValidationError: false
        })
      }
    }
  }

  validateURL(value) {
    if (value !== '') {
      if (Validator.validateURL(value) === false) {
        this.setState({
          isValidationError: true
        })
      } else {
        this.setState({
          isValidationError: false
        })
      }
    }
  }

  fetchProduct = (value = "") => {
    this.props.fetchRecommendedProducts(0, {
      feature_ids: [],
      num_users: "",
      page: 0,
      pageSize: 10,
      query: value,
      type: 2,
    });
  };

  clearError() {
    this.setState({
      formError: ''
    })
  }

  saveResponse() {
    this.clearError();
    const { text_value, isValidationError } = this.state;
    console.log(text_value, 'text_value')
    const { type } = this.props;
    if (type === 8 && text_value === '') {
      this.setState({
        formError: 'Please enter a Email.'
      })
      return;
    } else if (type === 9 && text_value === '') {
      this.setState({
        formError: 'Please enter phone number.'
      })
      return;
    } else if (type === 8 && isValidationError) {
      this.setState({
        formError: 'Please enter a valid Email.'
      })
      return;
    } else if (type === 10 && text_value === '') {
      this.setState({
        formError: 'Please enter a URL.'
      })
      return;
    } else if (type === 10 && isValidationError) {
      this.setState({
        formError: 'Please enter a valid URL.'
      })
      return;
    } else if (type === 12 && text_value === '') {
      this.setState({
        formError: 'Please enter a number.'
      })
      return;
    } else if (type === 13 && text_value === '') {
      this.setState({
        formError: 'Please enter a value.'
      })
      return;
    } else if ((type === 1 || type === 7) && text_value === '') {
      this.setState({
        formError: 'Please enter a value.'
      })
      return;
    }

    if ([1, 5, 7, 8, 9, 10, 12].includes(type)) {
      let val = type === 5 ? String(this.state.text_value) : this.state.text_value;
      this.props.onChange(val)
    }

    if (type === 13) {
      let res = {
        type: this.state.currencyType,
        value: this.state.text_value
      }
      this.props.onChange(JSON.stringify(res))
    }

    if (type === 2) {
      let options = [];
      if (this.state.selectedOptions.length <= 0) {
        this.setState({
          formError: 'Please select atleast one option.'
        })
        return;
      }
      this.state.selectedOptions.forEach(function (itm) {
        options.push(itm.value);
      })
      this.props.onChange(options)
    }

  }

  render() {
    let { additional_data, type, title, key, index, isRequired, isFormEditable, item, isShowSaveButton, isUpdateOnChange, maxScore, isSummaryPage, from, isOtherOption, characterLimit } = this.props;
    const classes = this.props.classes;
    let { otherValue, selectedDate, selectedOption, text_value, selectedOptions, selectedFiles, fileNames, selectedUsers, selectedTeams, selectedProducts, isEditProducts, currencyType } = this.state;

    // console.log(this.state.similarProducts,'similarProducts')
    // console.log(this.props.evaluation,'Evaluationnnnnnnnnnnnnnnnnnn')
    // console.log(selectedUsers, ' selectedUsers  =' + type)
    // console.log(selectedTeams, ' selectedTeams  =' + type)

    let similarProducts = this.props.similarExistingProducts;
    // console.log(similarProducts,'similarProducts')

    let productList = []
    if (type === 18) {
      productList = this.props.products.map((m) => {
        m.value = m.ID;
        m.label = m.Name;
        return m
      });
    }

    // console.log(selectedProducts, 'selectedProducts')
    // if (type === 15) {
    //   console.log(title, 'title')
    //   console.log(additional_data, 'additional_data')
    //   console.log(selectedOptions, 'selectedOption6666S')
    // }

    // if (type === 3) {
    //   console.log('---------render')
    //   console.log(additional_data, 'additional_data')
    //   console.log(selectedOption, 'selectedOption')
    // }

    if (type === 4) {
      additional_data = additional_data.sort(function (a, b) {
        if (a.Sequence >= b.Sequence) {
          return 1
        } else if (a.Sequence <= b.Sequence) {
          return -1
        } else {
          return 0
        }
      })
    }

    let isFieldDisabled = false;
    if (isFormEditable === false) {
      isFieldDisabled = true;
    }

    return (
      <div key={key} className={classes.root}>
        {/* <p style={{ color: 'green' }}>Type: {type}</p> */}

        {this.state.formError.length > 0 && <p style={{ color: 'red' }}>{this.state.formError}</p>}

        <div className={classes.fieldContainer}>
          {type === 1 &&
            <div className={classes.customTypeFields}>
              <SlateInputField
                folderPath={`/evaluation/${this.props.evaluationId}/product/${this.props.productId}/requirement/${this.props.itemId}`}
                style={{ margin: 0 }}
                as={SlateInputField}
                readOnly={isSummaryPage ? true : false}
                isToolBar={isSummaryPage ? false : true}
                enableMention={true}
                autoFocus={false}
                maxLength={10}
                disabled={isFieldDisabled}
                onChangeEvent={(value) => {
                  this.updateTextValue(value, isUpdateOnChange, true);
                }}
                placeholder={"Add response here."}
                initValue={text_value}
                formControlStyle={isSummaryPage ? { border: 'none' } : {}}
                textContainerStyle={{ color: "#707070", fontSize: 14 }}
                external={from === 'SELLER'}
              />
            </div>
          }

          {(type === 2 || type === 3) &&
            <div className={classes.customTypeFields}>
              <FormControl className={classes.formSelect}>
                <Select
                  isClearable
                  isDisabled={isFieldDisabled}
                  isMulti={type === 2 ? true : false}
                  labelId="singleSelect-select-label"
                  id="singleSelect-select"
                  value={type === 2 ? selectedOptions : selectedOption}
                  onChange={(e) => {
                    if (type === 3) {
                      this.setSingleSelectOption(e, true);
                    } else {
                      this.setMultiSelectOptions(e, isUpdateOnChange, true);
                    }
                  }}
                  fullWidth
                  options={additional_data.map(o => { return { value: o.ID, label: o.Title } })}
                  placeholder={"Select a Option"}
                >
                </Select>
              </FormControl>
            </div>
          }

          {type === 4 &&
            <>
              {!isSummaryPage && <span className={classes.subHead}>Select one option from below</span>}
              <RadioGroup name={`option_${index}`} value={String(selectedOption)} onChange={(e) => {
                this.setBinaryOption(e.target.value, true)
              }}>
                <ul className={"answers"}>
                  {additional_data.map((item, i) => {
                    return <li key={i}><FormControlLabel value={item.Description} control={<Radio
                      disabled={isFieldDisabled}
                    />} label={item.Title} /></li>
                  })}
                </ul>
              </RadioGroup>
            </>
          }

          {type === 5 &&
            <div className={classes.customTypeFields}>
              {!isSummaryPage && <span className={classes.subHead}>Rate your response</span>}
              {isSummaryPage && <span className={classes.subHead}>Your response <Typography className={classes.yourScoreRes}>{text_value === '' ? 0 : text_value}</Typography></span>}
              {!isSummaryPage && <div className={classes.ratingRoot}>
                <Grid container className={classes.rating}>
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10} className={classes.slider}>
                    {<Slider
                      // defaultValue={text_value}
                      value={text_value}
                      disabled={isFieldDisabled}
                      valueLabelDisplay="auto"
                      step={0.50}
                      marks={false}
                      min={1}
                      max={maxScore}
                      onChange={(event, value) => {
                        this.updateTextValue(value, isUpdateOnChange, true);
                      }}
                      classes={{
                        thumb: classes.thumb,
                        track: classes.track,
                        rail: classes.rail
                      }}
                    />}
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={classes.sliderCount}>
                    <Typography className={classes.resListItem}>{text_value === '' ? 0 : text_value}</Typography>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className={classes.counter1}>
                    <Typography className={classes.counterItem}>1</Typography>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className={classes.counter2}>
                    <Typography className={classes.counterItem}>{maxScore}</Typography>
                  </Grid>
                </Grid>
              </div>}
            </div>
          }

          {type === 6 &&
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "16px",
                }}
              >
                {this.state.isShowLoader ? <CircularProgress style={{ height: 30, width: 30, marginBottom: 10 }} /> : <>
                  {selectedFiles && selectedFiles.map((file, key) => (
                    <div>
                      <FileAncher
                        fileName={fileNames[key]}
                        fileURL={file.url}
                      />
                      &nbsp;&nbsp;&nbsp;
                      {!isSummaryPage && <Image
                        src={deleteIcon}
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.handleDeleteFile(key)}
                      />}
                    </div>
                  ))}</>}
                {!isSummaryPage && !isFieldDisabled && <input
                  ref={this.fileRef}
                  id="uploadRequirementResponse"
                  onChange={this.fileSelectHandler}
                  type="file"
                  multiple
                />}
              </div>
            </>
          }

          {type === 65465464654654646 &&
            <>
              <TextField
                id="outlined-full-width"
                required={isRequired ? true : false}
                disabled={isFieldDisabled}
                label={title}
                // style={{ margin: 8 }}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={text_value}
                onChange={(e) => {
                  this.updateTextValue(e.target.value, isUpdateOnChange);
                }}
              />

            </>
          }

          {type === 7 && <div className={classes.customTypeFields}>
            <TextField
              id="outlined-full-width"
              required={isRequired ? true : false}
              disabled={isFieldDisabled}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={text_value}
              classes={{
                root: classes.inputRoot
              }}
              onChange={(e) => {
                let val = e.target.value;
                if (val.length <= characterLimit) {
                  this.updateTextValue(e.target.value, isUpdateOnChange, true);
                }
              }}
            />
            {!isSummaryPage && <div style={{ textAlign: 'right' }}>
              <span className={classes.characterLimit}>Character Limit {characterLimit}&nbsp; &nbsp;{text_value.length}/{characterLimit}</span>
            </div>}
          </div>}

          {type === 8 &&
            <>
              <TextField
                id="outlined-full-width"
                required={isRequired ? true : false}
                label={title}
                disabled={isFieldDisabled}
                // style={{ margin: 8 }}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={text_value}
                onChange={(e) => {
                  this.validateEmail(e.target.value);
                  this.updateTextValue(e.target.value, isUpdateOnChange, true);
                }}
                error={this.state.isValidationError}
              />
              {this.state.isValidationError && <p className={classes.errMessage}>Please enter valid email i.e. abc@mail.com</p>}
            </>
          }

          {type === 9 &&
            <>
              <div className={classes.customTypeFields}>

                <PhoneInput
                  country={'us'}
                  value={text_value}
                  onChange={(value) => {
                    this.updateTextValue(value, isUpdateOnChange, true);
                  }}
                  required
                  disabled={isFieldDisabled}
                  inputProps={{
                    name: text_value,
                    autoFocus: false,
                    id: "outlined-full-width",
                    required: isRequired ? true : false,
                    label: title,
                    style: { width: '100%' },
                    fullWidth: true,
                    margin: "normal",
                    variant: "outlined",
                    InputLabelProps: {
                      shrink: true
                    }
                  }}
                />
              </div>
            </>
          }

          {type === 10 &&
            <>
              <div className={classes.customTypeFields}>
                <TextField
                  id="outlined-full-width"
                  required={isRequired ? true : false}
                  label={title}
                  // style={{ margin: 8 }}
                  fullWidth
                  disabled={isFieldDisabled}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={text_value}
                  onChange={(e) => {
                    this.validateURL(e.target.value);
                    this.updateTextValue(e.target.value, isUpdateOnChange, true);
                  }}
                  error={this.state.isValidationError}
                />
                {this.state.isValidationError && <p className={classes.errMessage}>Please enter valid URL i.e. https://domain.xyz</p>}
              </div>
            </>
          }

          {type === 11 &&
            <>
              <DatePicker
                className={classes.datePicker}
                selected={selectedDate}
                onChange={date => {
                  this.updateDate(date, true);
                }}
                dateFormat="MMM do yyyy"
                disabled={isFieldDisabled}
                portalId="evMetaDialog"
                minDate={null}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={
                  <OutlinedInput
                    readOnlyInput={true}
                    startAdornment={
                      <InputAdornment>
                        <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                      </InputAdornment>
                    }
                  />
                }
              />
            </>
          }

          {type === 12 &&
            <>
              <TextField
                id="outlined-full-width"
                required={isRequired ? true : false}
                label={title}
                type={"number"}
                // style={{ margin: 8 }}
                fullWidth
                disabled={isFieldDisabled}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                value={text_value}
                onChange={(e) => {
                  this.updateTextValue(e.target.value, isUpdateOnChange, true);
                }}
              />
            </>
          }

          {type === 13 &&
            <div className={classes.currencyTypeFields} style={{ display: 'flex' }}>
              <div style={{ width: 100, marginRight: 5, zIndex: 1 }}>
                <Select
                  isMulti={false}
                  labelId="singleSelect-select-label"
                  id="singleSelect-select"
                  isDisabled={isFieldDisabled}
                  value={currencyType}
                  onChange={(e) => {
                    this.setState({
                      currencyType: e
                    }, () => {
                      this.updateCurrencyType()
                    })
                  }}
                  options={currencyOptions}
                  placeholder={"Select a Option"}
                  styles={currencyStyles}
                >
                </Select>
              </div>

              <CurrencyTextField
                label={title}
                variant="outlined"
                value={text_value}
                disabled={isFieldDisabled}
                currencySymbol={currencyCodeMap[currencyType.value]}
                //minimumValue="0"
                style={{ textAlign: 'left' }}
                outputFormat="string"
                decimalCharacter="."
                digitGroupSeparator=","
                required={isRequired ? true : false}
                onChange={(e, v) => {
                  // console.log(v)
                  this.updateCurrencyValue(v, isUpdateOnChange, true);
                }}
              />
            </div>
          }

          {type === 14 &&
            <>
              {!isSummaryPage && <span className={classes.subHead}>Select one option from below</span>}
              <ul className={"answers"}>
                {additional_data.map((item, i) => {
                  return <>
                    <li key={i}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          boxSizing: "border-box",
                          placeContent: "center flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          disabled={isFieldDisabled}
                          value={from === 'SELLER' ? item.RefOptionId : item.ID}
                          checked={from === 'SELLER' ? selectedOptions.indexOf(item.RefOptionId) !== -1 : selectedOptions.indexOf(item.ID) !== -1}
                          // checked={from === 'SELLER' ? (selectedOption === item.RefOptionId ? true : false) : (selectedOption === item.ID ? true : false)}
                          inputProps={{
                            name: "RadioData",
                            "data-sequence": item.Sequence,
                          }}
                          onChange={(e) => {
                            this.setSelectedOption((e.target.value), true);
                          }}
                        />
                        <label htmlFor={item.ID}>{item.Title}</label>
                      </div>
                    </li>
                  </>
                })}


              </ul>
            </>
          }

          {type === 15 &&
            <>
              {!isSummaryPage && <span className={classes.subHead}>Select one or more options from below</span>}
              <ul className={"answers"}>
                {additional_data.map((item, i) => {
                  return <>
                    <li key={i}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          boxSizing: "border-box",
                          placeContent: "center flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          disabled={isFieldDisabled}
                          id={from === 'SELLER' ? item.RefOptionId : item.ID}
                          checked={from === 'SELLER' ? selectedOptions.indexOf(item.RefOptionId) !== -1 : selectedOptions.indexOf(item.ID) !== -1}
                          name={from === 'SELLER' ? item.RefOptionId : item.ID}
                          color="primary"
                          onChange={(e) => {
                            let options = [];
                            let id = from === 'SELLER' ? item.RefOptionId : item.ID;
                            if (selectedOptions.indexOf(id) !== -1) {
                              selectedOptions.splice(
                                selectedOptions.indexOf(id),
                                1
                              );
                            } else {
                              selectedOptions.push(id);
                            }
                            options = _.uniq(selectedOptions);
                            this.setSelectedOptions(options, true);
                          }}
                        />
                        <label htmlFor={from === 'SELLER' ? item.RefOptionId : item.ID}>{item.Title}</label>
                      </div>
                    </li>
                  </>
                })}
              </ul>
            </>
          }

          {(type === 14 || type === 15) && isOtherOption !== undefined && isOtherOption !== null && isOtherOption && <ul className={"answers"}>
            <li key={'other_option_single'}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  boxSizing: "border-box",
                  placeContent: "center flex-start",
                  alignItems: "center",
                }}
              >
                {type === 14 && <Radio
                  disabled={isFieldDisabled}
                  value={'99999999'}
                  checked={otherValue}
                  inputProps={{
                    name: "OtherData",
                  }}
                  onChange={(e) => {
                    this.setOtherOption('99999999', false);
                  }}
                />}

                {type === 15 && <Checkbox
                  id="other"
                  disabled={isFieldDisabled}
                  checked={otherValue}
                  name="other"
                  color="primary"
                  onChange={(e) => {
                    if (otherValue.length <= 0) {
                      this.setOtherOption('99999999', false);
                    } else {
                      this.setOtherOption('', true);
                    }
                  }}
                />}

                <TextField
                  style={{
                    width: "85%",
                    marginLeft: 10,
                    color: "#04363D",
                    fontSize: 13,
                  }}
                  value={text_value}
                  disabled={isFieldDisabled}
                  placeholder={'Other'}
                  onChange={(e) => {
                    if (isUpdateOnChange) {
                      this.updateOtherValue(e.target.value, true);
                      this.updateTextValue(e.target.value, false);
                    } else {
                      this.updateTextValue(e.target.value, false);
                    }
                  }}
                />
                {!isUpdateOnChange && !isSummaryPage && otherValue && <Button
                  style={{ "marginLeft": "10px" }}
                  disabled={false}
                  className={classes.tickMark}
                  onClick={() => {
                    this.updateOtherValue(this.state.text_value, true);
                  }}>
                  <DoneIcon style={{ color: '#4b86f8' }} />
                </Button>}

              </div>
            </li></ul>
          }

          {type === 16 &&
            <Grid container spacing={2} className={classes.noMarginTop}>
              {selectedUsers.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={2}
                  key={index}
                  className={classes.cardSection}
                >
                  {!isFieldDisabled && <Image
                    src={deleteIcon}
                    alt="iamge"
                    className={classes.deleteIcon}
                    onClick={() => {
                      let finalSelectedUsers = this.state.selectedUsers
                      finalSelectedUsers.splice(index, 1)
                      this.setSelectedUsersOnLoad(finalSelectedUsers, true)
                    }}
                  />}
                  <Card variant="outlined" style={{ height: '100%' }}>
                    <CardHeader
                      className={classes.contactCardHeader}
                      style={{ textAlign: "center" }}
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          className={classes.contactCardAvatar}
                        >
                          {item.label.slice(0, 1)}
                        </Avatar>
                      }
                      title={item.label}
                    />
                  </Card>
                </Grid>
              ))}
              {!isFieldDisabled && <Grid item xs={12} sm={6} md={3} lg={2}>
                <Card
                  className={classes.contactCardRoot}
                  variant="outlined"
                  onClick={() => {
                    this.props.showUserAddDialog(item)
                  }}
                >
                  <CardContent>
                    <span className={classes.addNewChamp}>
                      <Image
                        src={AddEvaluationImage}
                        style={{ maxWidth: "58px", cursor: "pointer" }}
                      />
                      <span className={classes.addContact}>
                        Add Users
                      </span>
                    </span>
                  </CardContent>
                </Card>
              </Grid>}
            </Grid>
          }
          {type === 17 &&
            <Grid container spacing={2} className={classes.noMarginTop}>
              {selectedTeams.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={2}
                  key={index}
                  className={classes.cardSection}
                >
                  {!isFieldDisabled && <Image
                    src={deleteIcon}
                    alt="iamge"
                    className={classes.deleteIcon}
                    onClick={() => {
                      let finalSelectedTeams = this.state.selectedTeams
                      finalSelectedTeams.splice(index, 1)
                      this.setSelectedTeamsOnLoad(finalSelectedTeams, true)
                    }}
                  />}
                  <Card variant="outlined" style={{ height: '100%' }}>
                    <CardHeader
                      className={classes.contactCardHeader}
                      style={{ textAlign: "center" }}
                      avatar={
                        <Avatar
                          aria-label="recipe"
                          className={classes.contactCardAvatar}
                        >
                          {item.label.slice(0, 1)}
                        </Avatar>
                      }
                      title={item.label}
                    />
                  </Card>
                </Grid>
              ))}
              {!isFieldDisabled && <Grid item xs={12} sm={6} md={3} lg={2}>
                <Card
                  className={classes.contactCardRoot}
                  variant="outlined"
                  onClick={() => {
                    this.props.showTeamAddDialog(item)
                    // this.setState({ isOpenDialog: true, dialogTitle: 'Add Teams', isUser: false })
                  }
                  }
                >
                  <CardContent>
                    <span className={classes.addNewChamp}>
                      <Image
                        src={AddEvaluationImage}
                        style={{ maxWidth: "58px", cursor: "pointer" }}
                      />
                      <span className={classes.addContact}>
                        Add Teams
                      </span>
                    </span>
                  </CardContent>
                </Card>
              </Grid>}
            </Grid>
          }

          {type === 18 && (
            <>
              <Grid container spacing={2} className={classes.noMarginTop}>
                {selectedProducts !== undefined && selectedProducts !== null && selectedProducts.length > 0 && selectedProducts.map((product, k) => {
                  return (
                    <Grid
                      item
                      key={"similar_" + product.ID}
                      className={classes.productList}
                      xs={12}
                      sm={4}
                      md={3}
                      lg={2}
                    >
                      <div style={{ position: 'relative' }}>
                        {isFormEditable && <Image
                          src={deleteIcon}
                          className={classes.deleteImage}
                          onClick={() => {
                            this.handleRemoveProduct(k)
                          }}
                        />}
                        <LayeredCard
                          title={product.Name}
                          subTitle={product.CompanyName}
                          description={product.Description}
                          customRoute={""}
                          image={product.ProductLogo}
                          onClick={() => { }}
                          numLayer={0}
                          checked={false}
                          showCheckbox={false}
                          id={product.ID}
                          uniqueId={product.ID + "_q_" + k}
                          type={"product"}
                          IsExisting={product.IsExisting}
                          fireEvent
                          eventPage={'orgProductDetails'}
                          eventData={{
                            type: 'event.click.org-product.section',
                            data: {
                              url: this.props.location.pathname + this.props?.location?.search,
                              section: {
                                id: 'customtab',
                                name: 'customtab'
                              },
                              product: {
                                id: product.ID,
                                name: product.Name,
                                position: k + 1
                              }
                            }
                          }}
                        />
                      </div>
                    </Grid>
                  );
                })}
                {isFormEditable && <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                  onClick={() => {
                    this.setState({ isEditProducts: true });
                  }}
                >
                  <LayeredCard
                    title={"+Add Vendor"}
                    onClick={() => { }}
                    numLayer={0}
                    image={AddEvaluationImage}
                    showCheckbox={false}
                  />
                </Grid>}
              </Grid>
              {isEditProducts && (
                <Grid container alignItems="center" style={{ marginTop: 20 }}>
                  <Grid item xs={6} sm={5} md={4}>
                    <Select
                      isClearable
                      menuPortalTarget={document.body}
                      isSearchable={true}
                      options={productList.filter(product => {
                        let dataArray = selectedProducts || [];
                        return !dataArray.some((item) => product.ID === item.ID)
                      })}
                      classNamePrefix="select"
                      placeholder={"Start typing to populate products"}
                      onInputChange={(e) => {
                        this.fetchProduct(e);
                      }}
                      onChange={(e) => this.setState({ selectedOption: e })}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                  </Grid>
                  &nbsp;
                  <ActionButtom
                    classes={classes}
                    onCancel={() => this.setState({ isEditProducts: false })}
                    onSave={() => {
                      if (!this.state.selectedOption) {
                        this.props.showSnackBar('Please select product', 'error', 3000);
                        return;
                      }
                      this.setState({ isEditProducts: false })
                      this.setSelectedProducts(this.state.selectedOption, true)
                    }}
                  />
                </Grid>
              )}
            </>
          )}

          {type === 19 &&
            <div className={classnames(classes.customTypeFields)}>
              {this.props.similarExistingProductProgress === false && this.props.evaluation.Products.map((product, k) => {
                if (similarProducts[product.ID] === undefined) {
                  return null
                }
                var simProducts = similarProducts[product.ID];
                return <div className={classes.product_list}>
                  <Typography className={classes.label}>Similar product{simProducts.length > 1 ? "s" : ""} for {product.Name}</Typography>
                  <Grid container spacing={3} className={classes.noMarginTop}>
                    {simProducts.map((product, index) => (
                      <Grid item lg={2} md={3} key={index}>
                        <div onClick={(e) => {
                          this.producDetail(e, product)
                        }}>
                          <LayeredCard
                            title={product.Name}
                            subTitle={product.CompanyName}
                            description={product.Description}
                            image={
                              product.ProductLogo === null ||
                                product.ProductLogo.length === 0
                                ? DefaultImage
                                : product.ProductLogo
                            }
                            toogleCheck={(e) => {
                              this.reviewProduct(e, product.ID);
                            }}
                            onClick={() => {

                            }}
                            numLayer={0}
                            //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                            // showCheckbox={true}
                            id={product.ID}
                            uniqueId={product.ID + "_c_" + index}
                            //  type={'product'}
                            IsExisting={product.IsExisting}
                          />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              })}
            </div>
          }

          {isShowSaveButton && type !== 3 && type !== 4 && type !== 6 && type !== 14 && type !== 15 && type !== 18 && type !== 11 && <div style={{ textAlign: 'right' }}>
            <Button
              style={{ "marginLeft": "10px" }}
              disabled={false}
              className={classes.tickMark}
              onClick={() => {
                this.saveResponse();
              }}>
              <DoneIcon />
            </Button>
          </div>}

        </div>
      </div >
    );
  }
}

RequirementReply.defaultProps = {
  maxScore: 5,
  isSummaryPage: false,
  isFormEditable: true,
  characterLimit: 100
};

export default connector(compose(
  withRouter,
  withStyles(styles)
)(RequirementReply));


const ActionButtom = ({ classes, onSave, onCancel, isDisabled = false }) => (
  <div>
    <Button
      className={classes.tickMark}
      onClick={() => isDisabled ? {} : onSave()}
    >
      <DoneIcon />
    </Button>
    <Button
      className={classes.tickMark}
      onClick={onCancel}
    >
      <CloseIcon />
    </Button>
  </div>
)