import { Button, Drawer, Link, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// icons
import { HelpOutline as HelpOutlineIcon, Home as HomeIcon } from "@material-ui/icons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import classnames from "classnames";
// components
import Image from "Components/Common/image.jsx";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
//redux
import { storeSearchString } from "redux/applications/action";
import { authorizedUser } from "redux/authuser/action";
import { updateDrawerStyle } from "redux/drawer/action";
// util
import { getConfigValue, isEnabledReview } from "util/Common";
import Products from "../../assets/images/nav/products.svg";
import ProductsActive from "../../assets/images/nav/products_active.svg";
import Evaluate from "../../assets/images/nav/evaluate.svg";
import EvaluateActive from "../../assets/images/nav/evaluate_active.svg";
// assets
import otherInactive from "../../assets/images/nav/otherInactive.svg";
import otherActive from "../../assets/images/nav/otherActive.svg";

import Logo from "../../assets/images/white_logo.svg";

const connectedProps = (state) => ({
  expanded: state.drawer.expanded,
  show: state.drawer.show,
  user: state.authUser.user,
  orgUser: state.orgUser,
});

const connectionActions = {
  storeSearchString: storeSearchString,
  authorizedUser: authorizedUser,
  updateDrawerStyle: updateDrawerStyle,
};

var connector = connect(connectedProps, connectionActions);

const drawerWidthExpanded = 35;
const drawerWidthCompressed = 17;

const styles = (theme) =>
  createStyles({
    drawer: {
      width: theme.spacing(drawerWidthExpanded),
      flexShrink: 0,
      transition: "width 500ms",
      background: "#F7F7F7",
    },
    drawerPaper: {
      width: theme.spacing(drawerWidthExpanded),
      paddingTop: theme.spacing(5),
      background: "#20253A",
    },
    drawerCompressed: {
      width: theme.spacing(drawerWidthCompressed),
      flexShrink: 0,
    },
    drawerPaperCompressed: {
      width: theme.spacing(drawerWidthCompressed),
      paddingTop: theme.spacing(5),
      background: "#20253A",
      transition: "width 300ms",
      "@media (max-width: 991.98px)": {
        width: "6em",
        "& [class*='CDrawer-menu_item-']": {
          display: "flex",
          placeContent: "center",
        },
      },
    },
    logoimage: {
      textAlign: "center",
    },
    menu: {
      width: "90%",
      margin: "0 auto",
      marginTop: theme.spacing(5),
    },
    menu_item: {
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(1.2),
      "&:hover": {
        background: "#a3dcfc33",
        borderRadius: theme.spacing(1.2),
        // "& $menu_item_text": {
        //     color: "#49B8FA",
        // },
        // "& $circle": {
        //     background: "#49B8FA",
        // }
      },
    },
    menu_item_text: {
      color: "#91919C",
    },
    menu_item_icon: {
      minWidth: theme.spacing(6),
    },
    active_menu_item: {
      background: "#a3dcfc33",
      borderRadius: theme.spacing(1.2),
    },
    active_circle: {
      background: "#49B8FA",
    },
    active_menu_item_text: {
      color: "#4A87F8",
    },
    compressed: {
      padding: theme.spacing(2, 4),
    },
    compressedIcon: {
      textAlign: "center",
      margin: "0 auto",
      minWidth: "auto",
      "& img": {
        margin: "0 auto",
      },
      "& svg": {
        margin: "0 auto",
      },
    },
    logoImage: {
      width: 44,
    },
    logoimagesrc: {
      width: "30px !important",
    },
    appDrawer: {
      position: 'relative',
      '&:hover': {
        '& .toggleArrow': {
          opacity: 1,
          transition: "opacity .5s",
          "-webkit-transition": "opacity .5s",
        },
      },
      "@media (max-width: 991.98px)": {
        position: "absolute",
      },
    },
    btnToggle: {
      opacity: 0,
      // transition: 'opacity 5s',
      // '-webkit-transition': 'opacity 5s',
      position: "fixed",
      width: "30px",
      height: "30px",
      background: "#ffffff",
      // display: 'inline-block',
      border: "2px solid #202639",
      zIndex: 99999,
      borderRadius: "50%",
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&.max": {
        left: "228px",
      },
      "&.min": {
        left: "102px",
        "& svg": {
          marginLeft: "3px",
        },
      },
      "& svg": {
        width: "17px",
        height: "17px",
        marginLeft: "7px",
      },
      "@media (max-width: 991.98px)": {
        "&.min": {
          left: "68px",
          opacity: 1,
        },
        "&.max": {
          opacity: 1,
        },
      },
    },
    helpButton: {
      backgroundColor: "#1f73b7 !important",
      color: "#FFFFFF !important",
      fill: "#FFFFFF !important",
      padding: "0.52857rem 1.57143rem",
      borderRadius: "999rem",
      letterSpacing: "0.6",
      fontSize: "1.07143rem",
      textDecoration: "none",
      position: "fixed",
      bottom: "10px",
      left: "5px",
      "& span": {
        textDecoration: "none",
      },
    },
  });

const Menu = [
  // {
  //   link: "/sellerapp/home",
  //   label: "Home",
  //   applicableFor: [ "OrgAdmin","OrgUser"],
  //   active_icon: <HomeIcon style={{ color: "#4A87F8" }} />,
  //   inactive_icon: <HomeIcon style={{ color: "#91919c" }} />,
  // },
  {
    link: "/sellerapp/products",
    label: "Products",
    applicableFor: ["OrgUser", "OrgAdmin"],
    active_icon: <Image src={ProductsActive} />,
    inactive_icon: <Image src={Products} />,
  },
  {
    link: "/sellerapp/assessment",
    label: "Assessment",
    applicableFor: ["OrgAdmin", "OrgUser"],
    active_icon: <Image src={EvaluateActive} />,
    inactive_icon: <Image src={Evaluate} />,
  },
  {
    link: "/sellerapp/library",
    label: "Library",
    applicableFor: ["OrgUser", "OrgAdmin"],
    active_icon: <Image src={otherActive} />,
    inactive_icon: <Image src={otherInactive} />,
  },
];

class CDrawer extends React.Component {
  componentDidMount() {
    this.props.authorizedUser();
  }

  render() {
    let role = this.props.user?.Role;
    let QSRole = this.props.user?.QSRole;
    const classes = this.props.classes;
    var location = this.props.location.pathname;
    if (!this.props.show) {
      return null;
    }
    const EnableReviewForUsersCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableReviewForUsersCheck");
    const EnableReviewForAdminsCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableReviewForAdminsCheck");
    const EndUserHelpLink = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "EndUserHelpLink");
    let showHelp = false;
    let helpLink = "#";
    // console.log(EndUserHelpLink.trim(),role,'i am here')
    if (EndUserHelpLink.trim().length > 0 && role === "OrgUser") {
      showHelp = true;
      helpLink = EndUserHelpLink.trim();
      if (window.zE != undefined) {
        window.zE("webWidget", "hide");
      }
      // console.log('i am here hide')
    } else {
      if (window.zE != undefined) {
        window.zE("webWidget", "show");
      }
      // console.log('i am here show');
    }

    var me = this;
    return (
      <>
        <Drawer
          className={classnames(classes.appDrawer, this.props.expanded ? classes.drawer : classes.drawerCompressed)}
          variant="permanent"
          classes={{
            paper: this.props.expanded ? classes.drawerPaper : classes.drawerPaperCompressed,
          }}
          anchor="left"
        >
          <span
            className={classnames(classes.btnToggle, this.props.expanded ? "max" : "min", "toggleArrow")}
            onClick={() => {
              this.props.updateDrawerStyle(!this.props.expanded, true);
            }}
          >
            {this.props.expanded ? <ArrowBackIosIcon fontSize="small" /> : <ArrowForwardIosIcon fontSize="small" />}
          </span>

          <div className={classes.logoimage} id="sidebarLogo">
            <Image
              src={Logo}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.storeSearchString("");
                this.props.history.push("/sellerapp");
              }}
              alt="logo"
              className={classnames(classes.logoImage, {
                [classes.logoimagesrc]: !me.props.expanded,
              })}
            />
          </div>

          <List className={classes.menu}>
            {Menu.filter((obj) => obj.applicableFor.indexOf(role) > -1).map((item, k) => {
              var active = false;
              if (location.indexOf(item.link) !== -1) {
                active = true;
              }
              if (((!EnableReviewForAdminsCheck && role !== "OrgUser") || (!EnableReviewForUsersCheck && role === "OrgUser")) && item.label === "Review") {
                return null;
              }
              if (item.label === "Dashboard" && !(QSRole === 1 || QSRole === 2)) {
                return null;
              }
              if (item.label === "Dashboard" && process.env.REACT_APP_ENV !== "dev") {
                return null;
              }
              const listitem = (
                <ListItem
                  button
                  key={k}
                  className={classnames(classes.menu_item, {
                    [classes.active_menu_item]: active,
                    [classes.compressed]: !me.props.expanded,
                  })}
                  onClick={() => {
                    this.props.storeSearchString("");
                    this.props.history.push(item.link);
                  }}
                >
                  <ListItemIcon
                    className={classnames({
                      [classes.compressedIcon]: !me.props.expanded,
                    })}
                  >
                    {active ? item.active_icon : item.inactive_icon}
                  </ListItemIcon>

                  {this.props.expanded && (
                    <ListItemText
                      primary={item.label}
                      classes={{
                        primary: classnames(classes.menu_item_text, {
                          [classes.active_menu_item_text]: active,
                        }),
                      }}
                    />
                  )}
                </ListItem>
              );

              if (!this.props.expanded) {
                return (
                  <Tooltip title={<span style={{ fontSize: "14px", display: "inline-block", height: "25px", lineHeight: "25px" }}>{item.label}</span>} placement="right">
                    {listitem}
                  </Tooltip>
                );
              } else {
                return listitem;
              }
            })}
          </List>
          {showHelp && (
            <div>
              <Button component={Link} startIcon={<HelpOutlineIcon />} variant="contained" className={classes.helpButton} target="_blank" href={helpLink}>
                Help
              </Button>
            </div>
          )}
        </Drawer>
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(CDrawer));
