import {
    START_FETCH_RENEWAL, END_FETCH_RENEWAL, START_UPDATE_RENEWAL, END_UPDATE_RENEWAL,
    START_ADD_RENEWAL_COLLOBORATOR, END_ADD_RENEWAL_COLLOBORATOR,
    START_DELETE_RENEWAL_COLLOBORATOR, END_DELETE_RENEWAL_COLLOBORATOR,
    START_NEW_RENEWAL, END_NEW_RENEWAL,
    START_ATTACH_PRODUCT_TO_REQUEST, END_ATTACH_PRODUCT_TO_REQUEST,
    START_UPDATE_ENTITY_RENEWAL, END_UPDATE_ENTITY_RENEWAL
} from "./action";

const initState = {
    fetchRenewalProg: false,
    fetchRenewalData: [],
    updateRenewalProg: false,
    updateRenewalData: null,
    updateRenewalColloboratorProg: false,
    updateRenewalColloboratorErr: null,
    updateRenewalColloboratorData: null,
    deleteRenewalColloboratorProg: false,
    deleteRenewalColloboratorErr: null,
    deleteRenewalColloboratorData: null,
    productRenewalInfo: null,
    attachProductToRequestProg: false,
    attachProductToRequestErr: null,
    attachProductToRequestData: null,

    updateEntityRenewalData: null,
    updateEntityRenewalProg: false,
}

export function renewalReducer(state = initState, action) {
    switch (action.type) {
        case START_DELETE_RENEWAL_COLLOBORATOR:
            return {
                ...state,
                deleteRenewalColloboratorData: null,
                deleteRenewalColloboratorProg: true,
                deleteRenewalColloboratorErr: null
            }

        case END_DELETE_RENEWAL_COLLOBORATOR:
            let evaluaId = action.payload.evId;
            let userId = action.payload.userId;
            let renewalDatas1 = state.fetchRenewalData;
            console.log(renewalDatas1, 'renewalDatas11111')

            if (action.payload.error === null) {
                let rIndex = renewalDatas1.findIndex(o => o.evaluationId === evaluaId);
                let rData1 = renewalDatas1[rIndex];
                console.log(rData1, 'rData1rData1rData1rData1')
                let colIndex = rData1.colloborators.findIndex(o => o.ID === userId);
                rData1.colloborators.splice(colIndex, 1);
            }

            return {
                ...state,
                fetchRenewalData: renewalDatas1,
                deleteRenewalColloboratorData: action.payload.success || null,
                deleteRenewalColloboratorProg: false,
                deleteRenewalColloboratorErr: action.payload.error
            }
        case START_ADD_RENEWAL_COLLOBORATOR:
            return {
                ...state,
                updateRenewalColloboratorData: null,
                updateRenewalColloboratorProg: true,
                updateRenewalColloboratorErr: null,
            }

        case END_ADD_RENEWAL_COLLOBORATOR:
            let evaluationId = action.payload.evId;
            let renewalDatas = state.fetchRenewalData;
            if (action.payload.error === null) {
                let rIndex = renewalDatas.findIndex(o => o.evaluationId === evaluationId);
                if (rIndex !== undefined && rIndex !== null && rIndex !== -1) {
                    let rData = renewalDatas[rIndex];
                    if (rData.colloborators !== undefined && rData.colloborators !== null) {
                        rData.colloborators.push(action.payload.success)
                    } else {
                        rData.colloborators = [];
                        rData.colloborators.push(action.payload.success)
                    }
                }
            }

            return {
                ...state,
                updateRenewalColloboratorData: action.payload.success || null,
                updateRenewalColloboratorErr: action.payload.error,
                updateRenewalColloboratorProg: false,
                fetchRenewalData: renewalDatas
            }
        case START_ATTACH_PRODUCT_TO_REQUEST:
            return {
                ...state,
                attachProductToRequestProg: true,
                attachProductToRequestErr: null,
                attachProductToRequestData: null,
            }
        case END_ATTACH_PRODUCT_TO_REQUEST:
            return {
                ...state,
                attachProductToRequestProg: false,
                attachProductToRequestErr: action.payload.error,
                attachProductToRequestData: action.payload.success,
            }
        case START_UPDATE_RENEWAL:
            return {
                ...state,
                updateRenewalData: null,
                updateRenewalProg: true,
            }
        case END_UPDATE_RENEWAL:
            return {
                ...state,
                updateRenewalData: action.payload.success || null,
                updateRenewalProg: false,
            }

        case START_UPDATE_ENTITY_RENEWAL:
            return {
                ...state,
                updateEntityRenewalData: null,
                updateEntityRenewalProg: true,
            }
        case END_UPDATE_ENTITY_RENEWAL:
            return {
                ...state,
                updateEntityRenewalData: action.payload.success || null,
                updateEntityRenewalProg: false,
            }

        case START_FETCH_RENEWAL:
            return {
                ...state,
                fetchRenewalData: state.fetchRenewalData,
                fetchRenewalProg: true,
            }
        case END_FETCH_RENEWAL:
            return {
                ...state,
                fetchRenewalData: action.payload.success || [],
                fetchRenewalProg: false,
            }

        case START_NEW_RENEWAL: {
            return {
                ...state,
                startNewRenewalProgress: true,
                productRenewalInfo: null,
                productRenewalInfoError: null,
            }
        }

        case END_NEW_RENEWAL: {
            return {
                ...state,
                startNewRenewalProgress: false,
                productRenewalInfo: action.payload.success,
                productRenewalInfoError: action.payload.error,
            }
        }

        default:
            return state;
    }
}