import { Button, CircularProgress, ClickAwayListener, Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
// import { size } from 'underscore';
import deleteIcon from "assets/images/delete.svg";
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";
import ImgAndroid from "assets/images/platforms/android.png";
import ImgChromeExtension from "assets/images/platforms/chromeextension.png";
import ImgCloud from "assets/images/platforms/cloud.png";
import ImgIos from "assets/images/platforms/ios.png";
import ImgLinux from "assets/images/platforms/linux.png";
import ImgMac from "assets/images/platforms/mac.png";
import ImgPremise from "assets/images/platforms/premise.svg";
import ImgWeb from "assets/images/platforms/web.png";
import ImgWindows from "assets/images/platforms/windows.png";
import classnames from "classnames"; 
import CommonCss from "commonCss";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import Image from "Components/Common/image.jsx";
import { ProductOption, ProductOptionLabel, ProductSearchFunction } from 'Components/Common/SelectProductOption';
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { compose } from "recompose";
import { authorizedUser } from "redux/authuser/action";
// Redux
import { fetchProductPrice } from "redux/product/orgProduct/action";
import { hideProductDetailsDialog } from "redux/dialogs/action";
import { updateDrawerStyle } from "redux/drawer/action";
import {
  fetchCompletedEvaluation,
  fetchInProgressEvaluation
} from "redux/evaluate/action";
import {
  createEvaluationCart, fetchEvaluationCart, removeProductFromCart
} from "redux/evaluation/cart/action";
import { fetchPlatform } from "redux/master/action";
import { addOrgGroup, fetchOrgGroup } from "redux/org/group/action";
import { fetchProducts, fetchRecommendedProducts } from "redux/product/action";
import { fetchProductCategory } from "redux/product/category/action";
import {
  addNewOrgCategories, addOrgCategories, addOrgFeatures, addOrgPlatform, addOrgProductGroup, addOrgRelatedProducts, addOrgSubCategories, deleteOrgCategories, deleteOrgFeatures, deleteOrgPlatform, deleteOrgProductGroup, deleteOrgRelatedProducts, deleteOrgSubCategories, fetchAlternativeProducts, fetchCompanyProductDetails, fetchComplementaryProducts, fetchOrgFeatures, fetchOrgPlatform, fetchOrgProductAlternatives, fetchOrgProductDepartment, fetchOrgRelatedProducts, fetchOrgSubCategories
} from "redux/product/orgProduct/action";
import { showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import {
  fetchProductSubCategory,
  fetchSubCategoryFeature
} from "redux/product/subcategory/action";
import { showSnackBar } from "redux/snackbar/action";
import { searchUser } from "redux/usersearch/action";
import PricingSection from "../PricingSection";
import CustomSection from "./CustomSection";
import RejectedIntegrationReasonDialog from "./RejectedIntegrationReasonDialog";
import CollaboratorPopper from './Common/CollaboratorPopper';
import * as Validator from "util/Validation";
import { sendAddMentionNotification } from "services/addMentionNotification";
import { updateReportData } from "redux/reports/action";

const connectedProps = (state) => ({
  fetchProductAlternativesUnderProgress: state.orgProduct.fetchProductAlternativesUnderProgress,
  productAlternatives: state.orgProduct.productAlternatives,
  fetchCompanyProductsUnderProgress: state.orgProduct.fetchCompanyProductsUnderProgress,
  companyProducts: state.orgProduct.companyProducts,
  evaluationCart: state.evaluationCart.evaluation,
  fetchComplementaryProductsUnderProgress: state.orgProduct.fetchComplementaryProductsUnderProgress,
  complementaryProducts: state.orgProduct.complementaryProducts,
  subCategories: state.productSubCategory.subCategories,
  subCategoriesFetchProgress: state.productSubCategory.fetchSubCategoryProgress,
  categoryFetchProgress: state.productCategory.fetchCategoryProgress,
  categories: state.productCategory.categories,
  subCategoryFeatures: state.productSubCategory.subCategoryFeatures,
  fetchSubCategoryFeaturesProgress: state.productSubCategory.fetchSubCategoryFeaturesProgress,
  products: state.products.recommendedProducts,
  fetchRecommendedProductsProgress: state.products.fetchRecommendedProductsProgress,
  orgProductDetailsUpdateProgress: state.orgProduct.orgProductDetailsUpdateProgress,
  orgProductDetailsUpdateSuccess: state.orgProduct.orgProductDetailsUpdateSuccess,
  orgProductDetailsFetchProgress: state.orgProduct.orgProductDetailsFetchProgress,
  groups: state.orgGroup.groups,
  fetchGroupProg: state.orgGroup.fetchGroupProg,
  orgProductDepartment: state.orgProduct.orgProductDepartment,
  orgCategories: state.orgProduct.orgCategories,
  orgProductGroupAddProgress: state.orgProduct.orgProductGroupAddProgress,
  orgProductGroupDeleteProgress: state.orgProduct.orgProductGroupDeleteProgress,
  orgFeatures: state.orgProduct.orgFeatures,
  orgFeaturesProgress: state.orgProduct.orgFeaturesProgress,
  orgSubCategories: state.orgProduct.orgSubCategories,
  orgSubCategoriesProgress: state.orgProduct.orgSubCategoriesProgress,
  addOrgSubCategoriesProgress: state.orgProduct.addOrgSubCategoriesProgress,
  deleteOrgSubCategoriesProgress: state.orgProduct.deleteOrgSubCategoriesProgress,
  deleteOrgCategoriesProgress: state.orgProduct.deleteOrgCategoriesProgress,
  addOrgFeaturesProgress: state.orgProduct.addOrgFeaturesProgress,
  deleteOrgFeaturesProgress: state.orgProduct.deleteOrgFeaturesProgress,
  platform: state.master.platform,
  orgPlatform: state.orgProduct.orgPlatform,
  addOrgPlatformProgress: state.orgProduct.addOrgPlatformProgress,
  orgIntegration: state.orgProduct.orgIntegration,
  rejectedIntegrations: state.orgProduct.rejectedIntegrations,
  orgSimilarProduct: state.orgProduct.orgSimilarProduct,
  orgSameProduct: state.orgProduct.orgSameProduct,
  orgComplementaryProduct: state.orgProduct.orgComplementaryProduct,
  orgIntegrationTotal: state.orgProduct.orgIntegrationTotal,
  orgSimilarProductTotal: state.orgProduct.orgSimilarProductTotal,
  orgSameProductTotal: state.orgProduct.orgSameProductTotal,
  orgComplementaryProductTotal: state.orgProduct.orgComplementaryProductTotal,
  orgIntegrationProgress: state.orgProduct.orgIntegrationProgress,
  orgSimilarProductProgress: state.orgProduct.orgSimilarProductProgress,
  orgSameProductProgress: state.orgProduct.orgSameProductProgress,
  orgComplementaryProductProgress: state.orgProduct.orgComplementaryProductProgress,
  addOrgIntegrationProgress: state.orgProduct.addOrgIntegrationProgress,
  addOrgSimilarProductProgress: state.orgProduct.addOrgSimilarProductProgress,
  addOrgSameProductProgress: state.orgProduct.addOrgSameProductProgress,
  addOrgComplementaryProductProgress: state.orgProduct.addOrgComplementaryProductProgress,
  deleteOrgIntegrationProgress: state.orgProduct.deleteOrgIntegrationProgress,
  deleteOrgSimilarProductProgress: state.orgProduct.deleteOrgSimilarProductProgress,
  deleteOrgSameProductProgress: state.orgProduct.deleteOrgSameProductProgress,
  deleteOrgComplementaryProductProgress: state.orgProduct.deleteOrgComplementaryProductProgress,
  userType: state.authUser.user?.Role,
  currentUser: state.authUser.user,
  orgExisingSimilarProducts: state.orgProduct.orgExisingSimilarProducts,
  fetchExistingSimilarProductsProgress: state.orgProduct.fetchExistingSimilarProductsProgress,
  orgAlternativeProducts: state.orgProduct.orgAlternativeProducts,
  fetchAlternativeProductsProgress: state.orgProduct.fetchAlternativeProductsProgress,
  addNewOrgCategoriesProgress: state.orgProduct.addNewOrgCategoriesProgress,
  productPrice: state.orgProduct.productPrice,
  searchData: state.reports.searchData,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  fetchOrgProductAlternatives: fetchOrgProductAlternatives,
  fetchComplementaryProducts: fetchComplementaryProducts,
  fetchCompanyProductDetails: fetchCompanyProductDetails,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
  showSnackBar: showSnackBar,
  hideProductDetailsDialog: hideProductDetailsDialog,
  searchProductChampUser: searchUser,
  fetchCompletedEvaluation: fetchCompletedEvaluation,
  fetchInProgressEvaluation: fetchInProgressEvaluation,
  fetchProductSubCategory: fetchProductSubCategory,
  fetchProductCategory: fetchProductCategory,
  fetchSubCategoryFeature: fetchSubCategoryFeature,
  fetchProducts: fetchProducts,
  fetchRecommendedProducts: fetchRecommendedProducts,
  fetchOrgGroup: fetchOrgGroup,
  addOrgGroup: addOrgGroup,
  addOrgProductGroup: addOrgProductGroup,
  deleteOrgProductGroup: deleteOrgProductGroup,
  fetchOrgProductDepartment: fetchOrgProductDepartment,
  fetchOrgSubCategories: fetchOrgSubCategories,
  fetchOrgFeatures: fetchOrgFeatures,
  addOrgSubCategories: addOrgSubCategories,
  deleteOrgSubCategories: deleteOrgSubCategories,
  addOrgFeatures: addOrgFeatures,
  deleteOrgFeatures: deleteOrgFeatures,
  fetchOrgPlatform: fetchOrgPlatform,
  addOrgPlatform: addOrgPlatform,
  fetchPlatform: fetchPlatform,
  fetchOrgRelatedProducts: fetchOrgRelatedProducts,
  addOrgRelatedProducts: addOrgRelatedProducts,
  deleteOrgRelatedProducts: deleteOrgRelatedProducts,
  authorizedUser: authorizedUser,
  deleteOrgPlatform: deleteOrgPlatform,
  fetchAlternativeProducts: fetchAlternativeProducts,
  deleteOrgCategories: deleteOrgCategories,
  addOrgCategories: addOrgCategories,
  addNewOrgCategories: addNewOrgCategories,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
  fetchProductPrice:fetchProductPrice,
  updateReportData: updateReportData,
};

var connector = connect(connectedProps, connectionActions);
let userRole = ["OrgAdmin","OrgManager"];
const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
    },
    ...CommonCss.ProductDetails,
    description: {
      ...CommonCss.ProductDetails.description,
      "& p": {
        margin: "0px",
      },
      "& [class*=editor-editableField]": {
        minHeight: "auto !important",
        margin: "0px !important",
      },
      "& [class*=editor-formControl]": {
        border: "none !important",
      },
    },
    tabSection: {},
    formGroup: {
      display: "flex",
      alignItems: "center",
    },
    label: {
      marginBottom: 5,
      display: "block",
    },
    creatable: {
      padding: 0,
    },
    searchAndSelect: {
      marginBottom: "5px",
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
        minHeight: 44,
      },
      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    uploadImageLabel: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    uploadImage: {
      width: 0,
      overflow: "hidden",
    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 19,
      margin: "0px !important",
    },

    productTitleSection: {
      ...CommonCss.productTitleSection,
      marginTop: 40,
      marginBottom: 10,
    },
    ...CommonCss.Home,
    evaluateCard: {
      ...CommonCss.Home.evaluateCard,
      border: "1px solid #ddd",
    },
    iconEle: {
      width: 18,
      marginLeft: 8,
      cursor: "pointer",
      '&:hover': {
        background: '#4a87f8',
        borderRadius: '25px',
        color: '#fff',
      }
    },
    tagClose: {
      display: "flex",
      alignItems: "center",
      padding: "6px 13px",
    },
    addNew: {
      cursor: "pointer",
    },
    contained: {
      color: "#fff",
      padding: "6px 26px",
      backgroundColor: "#4b86f8",
      "&:hover": {
        backgroundColor: "#4b86f8",
      },
    },
    containedCancel: {
      color: '#3C3C3C',
      padding: '5px 26px',
      backgroundColor: "#fff",
      border: '1px solid #3C3C3C',
      marginLeft: 20,
      "&:hover": {
        backgroundColor: "#fff",
      }
    },
    tickMark: {
      color: "#4b86f8",
      border: "1px solid #4b86f8",
      marginRight: 10,
      minWidth: "50px !important",
      "&:hover": {
        color: "#4b86f8",
      },
    },
    colorBlue: {
      color: "#4b86f8",
    },
    buttonGroup: {
      alignItems: "center",
      position: "relative",
      zIndex: 1000,
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 6,
      marginBottom: 5,
      cursor: "pointer",
    },
    descriptionText: {
      float: "right",
    },
    supportLabel: {
      fontWeight: 600,
      marginTop: 3,
      marginBottom: 3,
      // minWidth: 129,
      display: "inline-block",
      paddingRight: 10,
    },
    productLink: {
      ...CommonCss.ProductDetails.productLink,
      fontSize: 14,
      marginBottom: 0,
    },
    itemAboutSection: {
      "& p": {
        margin: 0,
      },
    },
    itemSection: {
      border: '1px solid #d8d8d8',
      padding: 17,
      borderRadius: 5,
      background: '#FFF',
      marginBottom: 17,
      position: 'relative',
      "& [class*=PricingSection-head]": {
        color: '#6C6C6C',
        fontSize: 18,
        opacity: 1,
        fontWeight: 600,
        margin: 0,
        marginBottom: 11
      },
    },
    platformEdit: {
      alignItems: "center",
      marginBottom: 20,
      zIndex: 1000,
      position: 'relative',
    },
    complementary: {
      "& [class*=select__menu]": {
        maxHeight: 150,
      },
    },
    deleteImage: {
      position: "absolute",
      top: 24,
      zIndex: 20,
      right: 23,
      padding: 4,
      cursor: "pointer",
    },
    positionRelative: {
      position: 'relative',
    },
    productList: {
      position: "relative",
    },
    slateInputField: {
      "& > div": {
        paddingTop: "0px !important",
        paddingBottom: "30px !important",
      },
    },
    platformTitle: {
      marginTop: 0,
    },
    bottomSpace: {},
    topHead: {
      display: "flex",
      justifyContent: "space-between",
    },
    head: {
      color: '#6C6C6C',
      fontSize: 18,
      opacity: 1,
      fontWeight: 600,
      margin: 0,
      marginBottom: 11
    },
    nomargin: {
      margin: "0px !important",
    },
    marginTop: {
      marginTop: 10,
    },
    notAvailable: {
      marginTop: 0,
      marginBottom: 28
    },
    // platformItem:{
    //   // position:"relative",

    // },
    closeIcon: {
      position: "absolute",
      right: "-20px",
      top: 0,
      cursor: "pointer"
    },
    linkItem: {
      cursor: 'pointer !important'
    },
    cursorTag: {
      fontSize: '12px',
      borderRadius: '25px'
    },
    // platformName:{
    //   display:"block",
    //   textAlign:"center"
    // },
    pd_root: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 10px 20px #00000036',
      borderRadius: '10px',
      opacity: '1',
      width: '350px',
      minHeight: '150px',
      zIndex: 9999,
      padding: '30px',
      position: 'fixed',
      marginTop: '30px'
    },
    pd_headerContainer: {
      flexDirection: 'row',
      boxSizing: 'border-box',
      display: 'flex',
      placeContent: 'center flex-start',
      alignItems: 'center'
    },
    pd_title: {
      textAlign: 'left',
      wordBreak: 'break-word',
      fontWeight: 500,
      letterSpacing: '0px',
      color: '#20253A',
      opacity: 1,
      margin: '0px',
      marginBottom: '5px'
    },
    pd_subTitle: {
      textAlign: 'left',
      fontWeight: 400,
      letterSpacing: '0px',
      color: '#20253A',
      opacity: 0.5,
      margin: '0px',
    },
    pd_descriptionContainer: {
      textAlign: 'left',
      fontWeight: 500,
      letterSpacing: '0px',
      color: '#20253A',
      opacity: 0.5
    },
    pd_action: {
      textAlign: 'left',
      letterSpacing: '0px',
      color: '#0093E0 !important',
      opacity: 1,
      cursor: 'pointer',
      fontSize: theme.spacing(2.4),
    },
    ellipsis_3: {
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box'
    },
    close: {
      position: 'absolute',
      right: 8,
      top: 8,
      cursor: 'pointer',
      color: '#6F6F6F',
      '& svg': {
        stroke: 'white',
        fontSize: '24px'
      }
    },
    topIndex: {
      zIndex: 1
    },
    popover: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        "&::before": {
          top: "50px",
          left: "-16px",
          width: "0",
          borderTop: "8px solid transparent",
          height: "0px",
          content: "''",
          display: "block",
          position: "absolute",
          borderBottom: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid #fff"
        },
      },
    },
    threeDot: {
      position: "absolute",
      zIndex: 11,
      right: 5,
      top: 5,
      padding: 5
    },
    // listItem: {
    //   paddingTop: theme.spacing(2),
    // },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    menuList: {
      padding: '0px',
      "& > hr": {
        "&:first-child": {
          display: 'none'
        },
      }
    },
    proNotBlocked: {
      zIndex: 11,
      color: '#c1c1c1',
    },
  });

class OrgProductDetailsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreProducts: false,
      showMoreCompanyProducts: false,
      showMoreComplementaryProducts: false,
      showMoreAlternativeProducts: false,
      viewMore: [],
      isCartShow: false,
      champ: [],
      query: "",
      page: 0,
      pageSize: 5,
      inPorgressStatus: [2, 3],
      completedStatus: [4],
      items: [],
      isLinkEdit: false,
      linkText: "",
      isDescription: false,
      description: "",
      isNewSubCategory: false,
      isFeaturesOffered: false,
      isBusiness: false,
      isDepartment: false,
      businessText: 0,
      departmentQuery: "",
      departmentText: "",
      integrationText: "",
      isIntegration: false,
      companyNameText: "",
      isCompanyNameEdit: false,
      subCategories: [],
      features: [],
      isPlatformEdit: false,
      platform: [],
      integrationPage: 0,
      complementaryProductPage: 0,
      similarProductPage: 0,
      sameProductPage: 0,
      isSimilarProduct: false,
      isSameProduct: false,
      isComplementaryProduct: false,
      isAlternativeProduct: false,
      integration: "",
      sameProduct: "",
      similarProduct: "",
      complementaryProduct: "",
      isShowSimilarExisting: true,
      alternativeProduct: "",
      rejectedIntegration: false,
      openRejectIntegrationDialog: false,
      isRejectedIntegration: false,
      isNewCategory: false,
      category: "",
      reasonDialogOpenEvent: null,
      reasonDialogOpen: false,
      rejectedProduct: {},
      anchorEl: null,
      newDescription:false,
    };
    this.btnRef = React.createRef();
    this.dialogDetailsRef = React.createRef()
    this.addToCart = this.addToCart.bind(this);
    this.removePlatformFromProduct = this.removePlatformFromProduct.bind(this);
  }

  componentDidMount() {
    this.props.hideProductDetailsDialog();
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true)
    }, 100);
    this.props.fetchProductPrice(this.props.match?.params?.id);
    this.fetchSubCategoryData();
    this.fetchCategoryData();
    this.fetchFeatureData();
    this.fetchProduct();
    this.fetchOrgGroups();
    this.fetchOrgProductDepartment();
    this.fetchOrgSubCategories();
    this.fetchOrgFeatures();
    this.fetchOrgPlatform();
    this.props.fetchPlatform();
    this.fetchOrgRelatedProducts(1, this.state.integrationPage);
    this.fetchOrgRelatedProducts(2, this.state.similarProductPage);
    this.fetchOrgRelatedProducts(3, this.state.sameProductPage);
    this.fetchOrgRelatedProducts(4, this.state.complementaryProductPage);
    this.fetchAlternativeProducts();
  }

  componentDidUpdate(prevProps) {
    if ((!this.props.orgProductGroupAddProgress && prevProps.orgProductGroupAddProgress) || (!this.props.orgProductGroupDeleteProgress && prevProps.orgProductGroupDeleteProgress)) {
      this.fetchOrgProductDepartment();
    }

    if ((!this.props.addOrgSubCategoriesProgress && prevProps.addOrgSubCategoriesProgress) || (!this.props.deleteOrgSubCategoriesProgress && prevProps.deleteOrgSubCategoriesProgress)) {
      this.fetchOrgSubCategories();
    }

    if ((!this.props.addOrgFeaturesProgress && prevProps.addOrgFeaturesProgress) || (!this.props.deleteOrgFeaturesProgress && prevProps.deleteOrgFeaturesProgress)) {
      this.fetchOrgFeatures();
    }

    if ((!this.props.addOrgIntegrationProgress && prevProps.addOrgIntegrationProgress) || (!this.props.deleteOrgIntegrationProgress && prevProps.deleteOrgIntegrationProgress)) {
      this.fetchOrgRelatedProducts(1, 0);
    }

    if ((!this.props.addOrgSimilarProductProgress && prevProps.addOrgSimilarProductProgress) || (!this.props.deleteOrgSimilarProductProgress && prevProps.deleteOrgSimilarProductProgress)) {
      this.fetchOrgRelatedProducts(2, 0);
    }

    if ((!this.props.addOrgSameProductProgress && prevProps.addOrgSameProductProgress) || (!this.props.deleteOrgSameProductProgress && prevProps.deleteOrgSameProductProgress)) {
      this.fetchOrgRelatedProducts(3, 0);
    }

    if ((!this.props.addOrgComplementaryProductProgress && prevProps.addOrgComplementaryProductProgress) || (!this.props.deleteOrgComplementaryProductProgress && prevProps.deleteOrgComplementaryProductProgress)) {
      this.fetchOrgRelatedProducts(4, 0);
    }

    if (
      (!this.props.addOrgSubCategoriesProgress &&
        prevProps.addOrgSubCategoriesProgress) ||
      (!this.props.deleteOrgSubCategoriesProgress &&
        prevProps.deleteOrgSubCategoriesProgress)
    ) {
      this.fetchOrgSubCategories();
    }

    if (
      (!this.props.addOrgFeaturesProgress &&
        prevProps.addOrgFeaturesProgress) ||
      (!this.props.deleteOrgFeaturesProgress &&
        prevProps.deleteOrgFeaturesProgress)
    ) {
      this.fetchOrgFeatures();
    }

    if (
      (!this.props.addOrgIntegrationProgress &&
        prevProps.addOrgIntegrationProgress) ||
      (!this.props.deleteOrgIntegrationProgress &&
        prevProps.deleteOrgIntegrationProgress)
    ) {
      this.fetchOrgRelatedProducts(1, 0);
    }

    if (
      (!this.props.addOrgSimilarProductProgress &&
        prevProps.addOrgSimilarProductProgress) ||
      (!this.props.deleteOrgSimilarProductProgress &&
        prevProps.deleteOrgSimilarProductProgress)
    ) {
      this.fetchOrgRelatedProducts(2, 0);
    }

    if (
      (!this.props.addOrgSameProductProgress &&
        prevProps.addOrgSameProductProgress) ||
      (!this.props.deleteOrgSameProductProgress &&
        prevProps.deleteOrgSameProductProgress)
    ) {
      this.fetchOrgRelatedProducts(3, 0);
    }

    if (
      (!this.props.addOrgComplementaryProductProgress &&
        prevProps.addOrgComplementaryProductProgress) ||
      (!this.props.deleteOrgComplementaryProductProgress &&
        prevProps.deleteOrgComplementaryProductProgress)
    ) {
      this.fetchOrgRelatedProducts(4, 0);
    }

    if (this.props.fetchExistingSimilarProductsProgress === false && prevProps.fetchExistingSimilarProductsProgress === true) {
      if (this.props.orgExisingSimilarProducts === null || this.props.orgExisingSimilarProducts.status === undefined || this.props.orgExisingSimilarProducts.status !== 200) {
        this.setState({
          isShowSimilarExisting: false
        })
      }
    }

    if (
      !this.props.orgProductDetailsUpdateProgress &&
      prevProps.orgProductDetailsUpdateProgress
    ) {
      if(this.props.orgProductDetailsUpdateSuccess && this.state.newDescription){
        if(this.props.orgProductDetailsUpdateSuccess?.orgProduct?.description){
          let addMentionData = {
            product_name: this.props.productDetails?.name,
            subject: `You have been mentioned in ${this.props.productDetails?.name}`,
          }
          let data = this.props.orgProductDetailsUpdateSuccess?.orgProduct?.description;
          sendAddMentionNotification(data,'App_Catalogue',addMentionData); 
        }
      }
      this.setState({newDescription: false})
    }

  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  fetchOrgRelatedProducts(type, page) {
    this.props.fetchOrgRelatedProducts(type, this.props.match?.params?.id, {
      relationTypes: [type],
      page: page,
      pageSize: 20,
    });
  }

  fetchOrgProductDepartment() {
    this.props.fetchOrgProductDepartment(this.props.match?.params?.id);
  }

  fetchOrgFeatures = () => {
    this.props.fetchOrgFeatures(this.props.match?.params?.id);
  };

  fetchOrgSubCategories = () => {
    this.props.fetchOrgSubCategories(this.props.match?.params?.id);
  };

  fetchOrgGroups = () => {
    this.props.fetchOrgGroup(0, 50, this.state.departmentQuery);
  };

  addOrgProductGroup = (params) => {
    this.props.addOrgProductGroup(params);
  };

  fetchSubCategoryData = (value = "") => {
    this.props.fetchProductSubCategory(0, 0, 100, value);
  };

  fetchCategoryData = (value = "") => {
    this.props.fetchProductCategory(0, 60, value);
  };

  fetchFeatureData = (value = "") => {
    //source=evaluation-home
    this.props.fetchSubCategoryFeature(0, 0, 100, value);
  };

  fetchOrgPlatform = () => {
    this.props.fetchOrgPlatform(this.props.match?.params?.id);
  };

  fetchAlternativeProducts = () => {
    this.props.fetchAlternativeProducts(this.props.match?.params?.id);
  }

  fetchProduct = (value = "") => {
    this.props.fetchRecommendedProducts(0, {
      feature_ids: [],
      num_users: "",
      page: 0,
      pageSize: 40,
      query: value,
      type: 2,
    });
  };

  componentWillUnmount() {
    // this.props.updateDrawerStyle(true, true);
  }

  scrollContent = (e) => {
    e.persist();
  };

  addToCart(item) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(item.ID);
      this.props.showSnackBar("Product added to evaluation", "success", 3000);
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(item.ID) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, item.ID);
      } else {
        this.props.createEvaluationCart(item.ID, this.props.evaluationCart.ID);
        this.props.showSnackBar("Product added to evaluation", "success", 3000);
      }
    }
  }

  viewMore = (value, state) => {
    const { viewMore } = this.state;
    let values = [...viewMore];
    if (state) {
      const index = values.indexOf(value);
      if (index > -1) {
        values.splice(index, 1);
      }
    } else {
      values.push(value);
    }
    this.setState({
      viewMore: values,
    });
  };

  cartOpen = () => {
    this.setState({ isCartShow: true });
  };

  cartClose = () => {
    this.setState({ isCartShow: false });
  };

  fetchChampUsersData = (value) => {
    this.props.searchProductChampUser(value);
  };

  productChampChange = (newValue) => {
    this.setState({ champ: newValue });
  };

  routeChange = (id) => {
    let redirectPath = `/app/evaluate/${id}/`;
    this.props.history.push(redirectPath);
  };

  saveLink = () => {
    if (this.state.linkText && Validator.validateURL(this.state.linkText) === false) {
      this.props.showSnackBar("Please enter valid URL  i.e. https://www.abc.xyz", "error", 3000);
      return;
    } else {
      this.setValue({
        ...this.props.productDetails,
        productURL: this.state.linkText,
      });
    }
    this.setState({ isLinkEdit: false });
  };

  saveCompanyName = () => {
    // if (this.state.companyNameText) {
      this.setValue({
        ...this.props.productDetails,
        companyName: this.state.companyNameText,
      });
    // } else {
    //   this.props.showSnackBar("Please enter company name", "error", 3000);
    //   return;
    // }
    this.setState({ isCompanyNameEdit: false });
  };

  setValue = (value) => {
    this.props.updateProducts(value);
    this.setState({ description: "", linkText: "", companyNameText: "" });
  };

  saveDescription = (proudctDetails) => {
    this.setState({ isDescription: false,newDescription: true });
    this.setValue({ ...proudctDetails, description: this.state.description });
  };

  saveSubCategories = () => {
    if (this.state.subCategories?.length) {
      this.props.addOrgSubCategories(this.props.match?.params?.id, {
        subcategory_ids: this.state.subCategories?.map((e) => e.value),
      });
      this.setState({ isNewSubCategory: false });
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  };

  savePlatform = () => {
    if (this.state.platform?.length) {
      this.props.addOrgPlatform(this.props.match?.params?.id, {
        platform_ids: this.state.platform?.map((e) => e.value),
      });
      this.setState({ isPlatformEdit: false, platform: [] });
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  };

  saveDepartment = () => {
    if (this.state.departmentText && this.state.departmentText.length) {
      this.setState({ isDepartment: false });
      this.addOrgProductGroup({
        productID: parseInt(this.props.match?.params?.id),
        groupIDs: this.state.departmentText.map((e) => e.value),
      });
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  };

  saveFeaturesOffered = () => {
    const feature_ids = [];
    const feature_names = [];
    this.state.features.map((e) => {
      if (e.__isNew__) {
        feature_names.push(e.value);
      } else {
        feature_ids.push(e.value);
      }
      return e
    });
    if (this.state.features?.length) {
      this.props.addOrgFeatures(this.props.match?.params?.id, {
        feature_ids: feature_ids,
        feature_names: feature_names,
      });
      this.setState({ isFeaturesOffered: false });
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  };

  deleteOrgProductGroup = (id) => {
    this.props.deleteOrgProductGroup({
      productID: parseInt(this.props.match?.params?.id),
      groupIDs: [id],
    });
  };

  deleteOrgSubCategories = (item) => {
    this.props.deleteOrgSubCategories(this.props.match?.params?.id, item.ID);
  };

  deleteOrgCategories = (item) => {
    this.props.deleteOrgCategories(this.props.match?.params?.id, item.ID);
  };

  saveCategory = (productID) => {
    if (this.state.category?.value) {
      // this.props.addOrgCategories(this.props.match?.params?.id,
      //   { category_ids: [this.state.category?.value] }
      // );
      this.props.addNewOrgCategories(this.props.match?.params?.id, { name: this.state.category?.label, type: this.state.category?.type });
      this.setState({ isNewCategory: false, category: "" });
    } else {
      this.props.showSnackBar("Please choose category", "error", 3000);
    }
  };

  deleteOrgFeatures = (item) => {
    this.props.deleteOrgFeatures(this.props.match?.params?.id, item.ID);
  };

  saveIntegration = (productID, productName, relationTypeFlag, reason = "") => {
    if (this.state.integration?.value || this.state.rejectedIntegration) {
      this.props.addOrgRelatedProducts(
        1,
        this.props.match?.params?.id,
        {
          productID,
          relationType: 1,
          productName,
          relationTypeFlag,
          reason,
        }
      );
      this.setState({
        isIntegration: false,
        integration: "",
        isRejectedIntegration: false,
        rejectedIntegration: "",
        openRejectIntegrationDialog: false
      });
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  };

  addNewIntegration = (e) => {
    this.props.addOrgRelatedProducts(1, this.props.match?.params?.id, {
      productName: e,
      relationType: 1,
    });
  };


  deleteIntegration = (item) => {
    if (item.ID !== 0) {
      this.props.deleteOrgRelatedProducts(1, this.props.match?.params?.id, {
        productIDs: [item.ID],
        relationType: 1,
      });
    } else {
      this.props.deleteOrgRelatedProducts(1, this.props.match?.params?.id, {
        productNames: [item.Name],
        relationType: 1,
      });
    }

  };

  saveSimilarProduct = () => {
    if (this.state.similarProduct?.value) {
      this.props.addOrgRelatedProducts(2, this.props.match?.params?.id, {
        productID: this.state.similarProduct?.value,
        relationType: 2,
      });
      this.setState({ isSimilarProduct: false, similarProduct: "" });
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  };

  deleteSimilarProduct = (item) => {
    this.props.deleteOrgRelatedProducts(2, this.props.match?.params?.id, {
      productIDs: [item.ID],
      relationType: 2,
    });
  };

  saveSameProduct = () => {
    if (this.state.sameProduct?.value) {
      this.props.addOrgRelatedProducts(3, this.props.match?.params?.id, {
        productID: this.state.sameProduct?.value,
        relationType: 3,
      });
      this.setState({ isSameProduct: false, sameProduct: "" });
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  };

  deleteSameProduct = (item) => {
    this.props.deleteOrgRelatedProducts(3, this.props.match?.params?.id, {
      productIDs: [item.ID],
      relationType: 3,
    });
  };

  saveComplementaryProduct = () => {
    if (this.state.complementaryProduct?.value) {
      this.props.addOrgRelatedProducts(4, this.props.match?.params?.id, {
        productID: this.state.complementaryProduct?.value,
        relationType: 4,
      });
      this.setState({
        isComplementaryProduct: false,
        complementaryProduct: "",
      });
    } else {
      this.props.showSnackBar("Please choose value", "error", 3000);
    }
  };

  deleteComplementaryProduct = (item) => {
    this.props.deleteOrgRelatedProducts(4, this.props.match?.params?.id, {
      productIDs: [item.ID],
      relationType: 4,
    });
  };

  removePlatformFromProduct(platformId) {
    this.props.deleteOrgPlatform(this.props.match?.params?.id, platformId)
  }


  popupPosition = (event, item) => {
    let element = event.target;
    let product_container = element.closest(".product_container")
    let domRect = product_container.getBoundingClientRect();
    let obj = {};
    obj.left = domRect.x;
    obj.top = domRect.y;

    if (event.clientX > (document.body.clientWidth - 350)) {
      delete obj.left;
      obj.right = document.body.clientWidth - (domRect.x + domRect.width);
    }

    if (event.clientY > (document.body.clientHeight - 200)) {
      delete obj.top
      obj.top = domRect.y - domRect.height;
    }
    this.setState({
      reasonDialogOpenEvent: obj,
      reasonDialogOpen: true,
      rejectedProduct: item
    })
    return true
  }

  updateStateForItem(item, value) {
    var data = {
      isPlatformEdit: false,
      isNewCategory: false,
      isIntegration: false,
      isRejectedIntegration: false,
      isFeaturesOffered: false,
      isSimilarProduct: false,
      isSameProduct: false,
      isComplementaryProduct: false,
      isCompanyNameEdit: false,
      isLinkEdit: false,
      isDescription: false
    }
    data[item] = value;
    this.setState(data)
  }

  render() {
    const classes = this.props.classes;
    const { productDetails, userType, hideFlagForUser = {}, isCloud = [], isDesktop = [], isMobile = [], isSupport = [] } = this.props;
    const { viewMore, reasonDialogOpenEvent, reasonDialogOpen, rejectedProduct } = this.state;

    var isCart = this.props.evaluationCart !== null;
    var addedToCart =
      this.props.evaluationCart == null
        ? []
        : this.props.evaluationCart.Products.map((p) => p.ID);
    const subCategoriesName = (this.props.orgCategories && this.props.orgCategories.map(m => m.Name)) || []
    let subCategories = this.props.subCategories.map((m) => ({ value: m.ID, label: m.Name, type: m.Type }));
    let categories = subCategories.filter(d => !subCategoriesName.includes(d.label))

    const platformExists = (data) => {
      let exist = false
      if(isDesktop.find(item=>item.ID === data.ID) || 
          isCloud.find(item=>item.ID === data.ID) || 
          isSupport.find(item=>item.ID === data.ID) || 
          isMobile.find(item=>item.ID === data.ID)){
        exist = true
      }
      return exist;
    }

    let subCategoryFeatures = this.props.subCategoryFeatures.map((m) => {
      return {
        value: m.ID,
        label: m.Name,
      };
    });


    let productList = this.props.products.map((m) => {
      m.value = m.ID;
      m.label = m.Name;
      m.isProduct = true;
      return m
    });

    let platfromList = []
    this.props.platform.forEach((m) => {
      if(!platformExists(m)){
        platfromList.push({
          value: m.ID,
          label: m.Name,
        });
      }
    });

    let similarProducts = [];
    if (this.props.orgSimilarProduct && this.props.orgSimilarProduct.length > 0) {
      similarProducts = similarProducts.concat(this.props.orgSimilarProduct);
    }

    let productFromsameCompany = [];

    if (this.props.orgSameProduct && this.props.orgSameProduct.length > 0) {
      productFromsameCompany = productFromsameCompany.concat(
        this.props.orgSameProduct
      );
    }

    let complementryProducts = [];

    if (
      this.props.orgComplementaryProduct &&
      this.props.orgComplementaryProduct.length > 0
    ) {
      complementryProducts = complementryProducts.concat(
        this.props.orgComplementaryProduct
      );
    }

    let alternativeProducts = [];

    if (
      this.props.orgAlternativeProducts &&
      this.props.orgAlternativeProducts.length > 0
    ) {
      alternativeProducts = alternativeProducts.concat(
        this.props.orgAlternativeProducts
      );
    }

    return (
      <>
        <div>
          {/* Product details */}

          {!this.props.orgProductDetailsFetchProgress && (
            <div
              className={classes.contentSection}
              onScroll={(e) => this.scrollContent(e)}
            >
              {this.props.sections && this.props.sections.length > 0 && this.props.sections.map(section => {
                const hasWriteAccess = (section.Permission === "write") || (section.Access === 2);
                if (section.IsDeletable && section.IsEditable) {
                  return (
                    <CustomSection
                      key={section.SectionUID}
                      section={section}
                      isEditable={hasWriteAccess}
                      productID={productDetails.productID}
                    />
                  )
                }
                if (section.SectionUID === "prod_about") {
                  return (
                    <>
                      {(hideFlagForUser?.prod_about) && (
                        <div
                          className={classes.itemSection}
                          id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}
                        >
                          {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}

                          <p className={classes.head}>
                            {section.SectionName} {productDetails?.name}{" "}
                            <span>
                              {!this.state.isDescription &&
                                hasWriteAccess && (
                                  <EditIcon
                                    fontSize={"small"}
                                    className={classes.editIcon}
                                    onClick={() => {
                                      this.setState({
                                        description: productDetails?.description,
                                      }, () => {
                                        this.updateStateForItem('isDescription', true)
                                      });
                                    }}
                                  />
                                )}{" "}
                            </span>
                          </p>

                          {!this.state.isDescription && productDetails?.description && (
                            <div className={classes.description}>
                              <SlateInputField
                                placeholder=""
                                isToolBar={false}
                                readOnly={true}
                                as={SlateInputField}
                                onChangeEvent={(value) => {
                                  this.setState({ description: value });
                                }}
                                formControlStyle={{
                                  overflow: "overlay",
                                  borderRadius: 5,
                                }}
                                initValue={productDetails?.description}
                                textContainerStyle={{ color: "#707070" }}
                              />
                            </div>
                          )}

                          {!this.state.isDescription && !productDetails?.description && <p className={classes.notAvailable}>No data</p>}
                          {this.state.isDescription && (
                            <Grid
                              container
                              spacing={3}
                              className={classnames(
                                classes.buttonGroup,
                                classes.slateInputField,
                                classes.topIndex
                              )}
                            >
                              <Grid item sm={12}>
                                <SlateInputField
                                  placeholder=""
                                  enableMention={true}
                                  isToolBar={true}
                                  readOnly={false}
                                  as={SlateInputField}
                                  onChangeEvent={(value) => {
                                    this.setState({ description: value });
                                  }}
                                  formControlStyle={{
                                    overflow: "overlay",
                                    borderRadius: 5,
                                  }}
                                  initValue={productDetails?.description}
                                  textContainerStyle={{ color: "#707070" }}
                                />
                                <Grid  container justify="flex-end" style={{ marginTop: 10 }}>
                                  <Button
                                    className={classes.tickMark}
                                    onClick={() => this.saveDescription(productDetails)}
                                  >
                                    <DoneIcon />
                                  </Button>
                                  <Button
                                    className={classes.tickMark}
                                    onClick={() => this.setState({ isDescription: false })}
                                  >
                                    <CloseIcon />
                                  </Button>
                                </Grid>
                                
                              </Grid>
                            </Grid>
                          )}
                        </div>
                      )}
                    </>
                  )
                } else if (section.SectionUID === "prod_company_details") {
                  return (
                    <>
                      {hideFlagForUser?.prod_company_details && (
                        <div
                          className={classes.itemSection}
                          id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}
                        >
                          {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}

                          <p className={classes.head} id="102">
                            {section.SectionName}
                          </p>
                          <div style={{ display: "flex", marginTop: 10 }}>
                            <div style={{ marginRight: 30, minWidth: "400px" }}>
                              {!this.state.isLinkEdit && (
                                <p>
                                  <span className={classes.supportLabel}>Website: </span>
                                  <a
                                    target="_blank"
                                    href={productDetails.productURL !== undefined && productDetails.productURL !== null ? productDetails.productURL : ''}
                                    className={classes.productLink} rel="noopener noreferrer"
                                  >
                                    {productDetails?.productURL}
                                  </a>
                                  {hasWriteAccess && (
                                    <EditIcon
                                      fontSize={"small"}
                                      className={classes.editIcon}
                                      onClick={() => {
                                        this.setState({
                                          // isLinkEdit: true,
                                          linkText: productDetails?.productURL,
                                        }, () => {
                                          this.updateStateForItem('isLinkEdit', true)
                                        });
                                      }}
                                    />
                                  )}
                                </p>
                              )}
                              {this.state.isLinkEdit && (
                                <Grid container spacing={1} className={classes.buttonGroup}>
                                  <Grid item sm={8}>
                                    <div className={classes.formGroup}>
                                      <span
                                        className={classes.supportLabel}
                                        style={{ minWidth: "70px" }}
                                      >
                                        Website:{" "}
                                      </span>
                                      <input
                                        style={{ padding: 10 }}
                                        className={classes.formControl}
                                        placeholder="Enter the link"
                                        onChange={(event) => {
                                          this.setState({ linkText: event.target.value });
                                        }}
                                        value={this.state.linkText}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item sm={4}>
                                    <Button
                                      className={classes.tickMark}
                                      onClick={() => this.saveLink()}
                                    >
                                      <DoneIcon />
                                    </Button>
                                    <Button
                                      className={classes.tickMark}
                                      onClick={() => this.setState({ isLinkEdit: false })}
                                    >
                                      <CloseIcon />
                                    </Button>
                                  </Grid>
                                </Grid>
                              )}
                            </div>
                            <div style={{ minWidth: "400px" }}>
                              {!this.state.isCompanyNameEdit && (
                                <p>
                                  {" "}
                                  <span className={classes.supportLabel}>
                                    Company name:{" "}
                                  </span>{" "}
                                  <span>{productDetails?.companyName}</span>
                                  {hasWriteAccess && (
                                    <EditIcon
                                      fontSize={"small"}
                                      className={classes.editIcon}
                                      onClick={() => {
                                        this.setState({
                                          // isCompanyNameEdit: true,
                                          companyNameText: productDetails?.companyName,
                                        }, () => {
                                          this.updateStateForItem('isCompanyNameEdit', true)
                                        });
                                      }}
                                    />
                                  )}
                                </p>
                              )}
                              {this.state.isCompanyNameEdit && (
                                <Grid container spacing={3} className={classes.buttonGroup}>
                                  <Grid item sm={8}>
                                    <div className={classes.formGroup}>
                                      <span
                                        className={classes.supportLabel}
                                        style={{ minWidth: "140px" }}
                                      >
                                        Company name:{" "}
                                      </span>
                                      <input
                                        style={{ padding: 10 }}
                                        className={classes.formControl}
                                        placeholder="Enter the company name"
                                        onChange={(event) => {
                                          this.setState({
                                            companyNameText: event.target.value,
                                          });
                                        }}
                                        value={this.state.companyNameText}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item sm={4}>
                                    <Button
                                      className={classes.tickMark}
                                      onClick={() => this.saveCompanyName()}
                                    >
                                      <DoneIcon />
                                    </Button>
                                    <Button
                                      className={classes.tickMark}
                                      onClick={() =>
                                        this.setState({ isCompanyNameEdit: false })
                                      }
                                    >
                                      <CloseIcon />
                                    </Button>
                                  </Grid>
                                </Grid>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )
                } else if (section.SectionUID === "prod_categories") {
                  return (
                    <>
                      {hideFlagForUser?.prod_categories && (
                        <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                          {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                          <p className={classes.head}>{section.SectionName}</p>
                          <div>
                            <ul className={classes.UL}>
                              {this.props.orgCategories &&
                                this.props.orgCategories
                                  ?.slice(
                                    0,
                                    viewMore.indexOf("Categories") > -1
                                      ? this.props.orgCategories?.length
                                      : 5
                                  )
                                  .map((item, k) => {
                                    return (
                                      <li key={k} style={{ marginRight: "14px" }}>
                                        <span
                                          className={classnames(
                                            classes.tag,
                                            classes.tagClose,
                                            classes.inverse
                                          )}
                                        >
                                          <a href='#about' className={classes.linkItem} onClick={(e) => {
                                            e.preventDefault();
                                            if(userRole.indexOf(userType) > -1){
                                              this.props.history.push("/app/products/org-products?selectedCategory=" + item?.ID);
                                            }
                                          }}> <span className={classes.colorBlue}>{item?.Name} </span></a>
                                          {/* <span>{item?.Name} </span> */}

                                          {hasWriteAccess && (
                                            <CloseIcon
                                              className={classes.iconEle}
                                              onClick={() => {
                                                this.deleteOrgCategories(item);
                                              }}
                                            />
                                          )}
                                        </span>
                                      </li>
                                    );
                                  })}
                              {this.props.orgCategories &&
                                this.props.orgCategories?.length > 5 && (
                                  <li key={"viewMore"} style={{ marginRight: "14px" }}>
                                    <span
                                      className={classnames(
                                        classes.tag,
                                        classes.view_more
                                      )}
                                      onClick={() => {
                                        this.viewMore(
                                          "Categories",
                                          viewMore.indexOf("Categories") > -1
                                        );
                                      }}
                                    >
                                      {viewMore.indexOf("Categories") > -1
                                        ? "- View less"
                                        : "+ View more"}
                                    </span>
                                  </li>
                                )}
                              {!this.state.isNewCategory && hasWriteAccess && (
                                <li
                                  onClick={() =>
                                  // this.setState({ isNewCategory: true }
                                  { this.updateStateForItem('isNewCategory', true) }

                                  }
                                >
                                  <span
                                    className={classnames(
                                      classes.tag,
                                      classes.tagClose,
                                      classes.addNew
                                    )}
                                  >
                                    <span> Add new</span>
                                    <CheckIcon className={classes.iconEle} />
                                  </span>
                                </li>
                              )}
                            </ul>
                            {this.state.isNewCategory && (
                              <Grid container spacing={3} className={classes.buttonGroup}>
                                <Grid item sm={6}>
                                  <div className={classes.searchAndSelect}>
                                    <CreatableSelect
                                      isClearable
                                      className={classnames(
                                        classes.formControl,
                                        classes.creatable
                                      )}
                                      isSearchable={true}
                                      noOptionsMessage={() =>
                                        this.props.categoryFetchProgress
                                          ? "Loading..."
                                          : "No options"
                                      }
                                      createOptionPosition="first"
                                      options={categories}
                                      classNamePrefix="select"
                                      placeholder="Choose Category"
                                      onInputChange={(e) => {
                                        this.fetchSubCategoryData(e);
                                      }}
                                      onChange={(e) => {
                                        this.setState({ category: e });
                                      }}
                                      onCreateOption={(e) => {
                                        this.props.addNewOrgCategories(this.props.match?.params?.id, { name: e, logo: "" });
                                        this.setState({ isNewCategory: false })
                                      }}
                                      formatOptionLabel={ProductOption}
                                      filterOption={ProductSearchFunction}
                                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item sm={6}>
                                  <Button
                                    className={classes.tickMark}
                                    onClick={() => this.saveCategory(productDetails.productID)}
                                  >
                                    <DoneIcon />
                                  </Button>
                                  <Button
                                    className={classes.tickMark}
                                    onClick={() => this.setState({ isNewCategory: false })}
                                  >
                                    <CloseIcon />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </div>
                        </div>
                      )
                      }
                    </>
                  )
                } else if (false && section.SectionUID === "prod_subcategories-falseee") {
                  return (
                    <>
                      {(hideFlagForUser?.prod_subcategories) && <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                        <p className={classes.head}>{section.SectionName}</p>
                        <div>
                          <ul className={classes.UL}>
                            {this.props.orgSubCategories &&
                              this.props.orgSubCategories
                                ?.slice(
                                  0,
                                  viewMore.indexOf("SubCategories") > -1
                                    ? this.props.orgSubCategories?.length
                                    : 5
                                )
                                .map((item, k) => {
                                  return (
                                    <li key={k} style={{ marginRight: "14px" }}>
                                      <span
                                        className={classnames(
                                          classes.tag,
                                          classes.tagClose,
                                          classes.inverse
                                        )}
                                      >
                                        <span>{item?.Name} </span>
                                        {hasWriteAccess && (
                                          <CloseIcon
                                            className={classes.iconEle}
                                            onClick={() => {
                                              this.deleteOrgSubCategories(item);
                                            }}
                                          />
                                        )}
                                      </span>
                                    </li>
                                  );
                                })}
                            {this.props.orgSubCategories &&
                              this.props.orgSubCategories?.length > 5 && (
                                <li key={"viewMore"} style={{ marginRight: "14px" }}>
                                  <span
                                    className={classnames(classes.tag, classes.view_more)}
                                    onClick={() => {
                                      this.viewMore(
                                        "SubCategories",
                                        viewMore.indexOf("SubCategories") > -1
                                      );
                                    }}
                                  >
                                    {viewMore.indexOf("SubCategories") > -1
                                      ? "- View less"
                                      : "+ View more"}
                                  </span>
                                </li>
                              )}
                            {!this.state.isNewSubCategory &&
                              hasWriteAccess && (
                                <li
                                  onClick={() =>
                                    this.setState({ isNewSubCategory: true })
                                  }
                                >
                                  <span
                                    className={classnames(
                                      classes.tag,
                                      classes.tagClose,
                                      classes.addNew
                                    )}
                                  >
                                    <span> Add new</span>
                                    <CheckIcon className={classes.iconEle} />
                                  </span>
                                </li>
                              )}
                          </ul>
                          {this.state.isNewSubCategory && (
                            <Grid container spacing={3} className={classes.buttonGroup}>
                              <Grid item sm={6}>
                                <div className={classes.searchAndSelect}>
                                  <Select
                                    isClearable
                                    isMulti
                                    className={classnames(
                                      classes.formControl,
                                      classes.creatable
                                    )}
                                    noOptionsMessage={() =>
                                      this.props.subCategoriesFetchProgress
                                        ? "Loading..."
                                        : "No options"
                                    }
                                    isSearchable={true}
                                    options={subCategories}
                                    classNamePrefix="select"
                                    placeholder="Choose sub category"
                                    onInputChange={(e) => {
                                      this.fetchSubCategoryData(e);
                                    }}
                                    onChange={(e) => {
                                      this.setState({ subCategories: e });
                                    }}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                  />
                                </div>
                              </Grid>
                              <Grid item sm={6}>
                                <Button
                                  className={classes.tickMark}
                                  onClick={() => this.saveSubCategories()}
                                >
                                  <DoneIcon />
                                </Button>
                                <Button
                                  className={classes.tickMark}
                                  onClick={() =>
                                    this.setState({ isNewSubCategory: false })
                                  }
                                >
                                  <CloseIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </div>
                      </div>}
                    </>
                  )
                } else if (section.SectionUID === "prod_approved_integrations") {
                  
                  return (
                    <>
                      {(hideFlagForUser?.prod_approved_integrations) && (
                        <Grid container spacing={1} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`} alignItems="center">
                          <Grid item xs={12} sm={6} style={{ height: '100%' }}>
                            {<div className={classes.itemSection} id="109">
                              <p className={classes.head}>Approved integrations</p>
                              {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}

                              <ul className={classes.UL}>
                                {this.props.orgIntegration &&
                                  this.props.orgIntegration
                                    ?.slice(
                                      0,
                                      viewMore.indexOf("Integration") > -1
                                        ? this.props.orgIntegration?.length
                                        : 5
                                    )
                                    .map((item, k) => {
                                      return (
                                        <li key={k} style={{ marginRight: "14px" }}>
                                          <span
                                            className={classnames(
                                              classes.tag,
                                              classes.tagClose,
                                              classes.inverse
                                            )}
                                          >
                                            <span>{item?.Name} </span>
                                            {hasWriteAccess && (
                                              <CloseIcon
                                                className={classes.iconEle}
                                                onClick={() => this.deleteIntegration(item)}
                                              />
                                            )}
                                          </span>
                                        </li>
                                      );
                                    })}
                                {this.props.orgIntegration &&
                                  this.props.orgIntegration?.length > 5 && (
                                    <li key={"viewMore"} style={{ marginRight: "14px" }}>
                                      <span
                                        className={classnames(classes.tag, classes.view_more)}
                                        onClick={() => {
                                          this.viewMore(
                                            "Integration",
                                            viewMore.indexOf("Integration") > -1
                                          );
                                        }}
                                      >
                                        {viewMore.indexOf("Integration") > -1
                                          ? "- View less"
                                          : "+ View more"}
                                      </span>
                                    </li>
                                  )}
                                {!this.state.isIntegration && hasWriteAccess && (
                                  <li onClick={() =>
                                    // this.setState({ isIntegration: true })
                                    this.updateStateForItem('isIntegration', true)

                                  }>
                                    <span
                                      className={classnames(
                                        classes.tag,
                                        classes.tagClose,
                                        classes.addNew
                                      )}
                                    >
                                      <span> Add new </span>
                                      <CheckIcon className={classes.iconEle} />
                                    </span>
                                  </li>
                                )}
                              </ul>
                              {this.state.isIntegration && (
                                <Grid container spacing={3} className={classes.buttonGroup}>
                                  <Grid item sm={6}>
                                    <div className={classes.searchAndSelect}>
                                      <Select
                                        isClearable
                                        className={classnames(
                                          classes.formControl,
                                          classes.creatable
                                        )}
                                        isSearchable={true}
                                        noOptionsMessage={() =>
                                          this.props.fetchRecommendedProductsProgress
                                            ? "Loading..."
                                            : "No options"
                                        }
                                        options={productList}
                                        classNamePrefix="select"
                                        placeholder="Choose integration"
                                        onInputChange={(e) => {
                                          this.fetchProduct(e);
                                        }}
                                        onChange={(e) => {
                                          this.setState({ integration: e });
                                        }}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9998 }) }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item sm={6}>
                                    <Button
                                      className={classes.tickMark}
                                      onClick={() => {
                                        this.saveIntegration(
                                          this.state.integration?.ID,
                                          this.state.integration?.Name,
                                          true,
                                          ""
                                        )
                                      }}
                                    >
                                      <DoneIcon />
                                    </Button>
                                    <Button
                                      className={classes.tickMark}
                                      onClick={() => this.setState({ isIntegration: false })}
                                    >
                                      <CloseIcon />
                                    </Button>
                                  </Grid>
                                </Grid>
                              )}
                            </div>}
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ height: '100%',alignSelf: 'baseline' }}>
                            {<div className={classes.itemSection} id="109">
                              <p className={classes.head}>Rejected integrations</p>
                              {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}

                              <ul className={classnames(classes.UL, "product_container")}>
                                {this.props.rejectedIntegrations &&
                                  this.props.rejectedIntegrations
                                    .map((item, k) => {
                                      return (
                                        <li key={k} style={{ marginRight: "14px" }} onClick={event => {
                                          event.stopPropagation()
                                          this.popupPosition(event, item)
                                        }}>
                                          <span
                                            className={classnames(
                                              classes.tag,
                                              classes.tagClose,
                                              classes.inverse
                                            )}
                                          >
                                            <span>{item?.Name} </span>
                                            {hasWriteAccess && (
                                              <CloseIcon
                                                className={classes.iconEle}
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  this.deleteIntegration(item)
                                                }}
                                              />
                                            )}
                                          </span>
                                        </li>
                                      );
                                    })}
                                {!this.state.isRejectedIntegration && hasWriteAccess && (
                                  <li onClick={() =>
                                    // this.setState({ isRejectedIntegration: true })
                                    this.updateStateForItem('isRejectedIntegration', true)

                                  }>
                                    <span
                                      className={classnames(
                                        classes.tag,
                                        classes.tagClose,
                                        classes.addNew
                                      )}
                                    >
                                      <span> Add new </span>
                                      <CheckIcon className={classes.iconEle} />
                                    </span>
                                  </li>
                                )}
                                {reasonDialogOpenEvent !== null && reasonDialogOpen && (
                                  <ClickAwayListener
                                    onClickAway={(e) => {
                                      e.stopPropagation()
                                      this.setState({
                                        reasonDialogOpenEvent: null,
                                        reasonDialogOpen: false,
                                        rejectedProduct: {}
                                      })
                                    }}
                                  >
                                    <div className={classes.pd_root} style={reasonDialogOpenEvent}>
                                      <span className={classes.close}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          this.setState({
                                            reasonDialogOpenEvent: null,
                                            reasonDialogOpen: false,
                                            rejectedProduct: {}
                                          })
                                        }}>
                                        <CloseIcon className={classes.close} />
                                      </span>
                                      <div className={classes.pd_headerContainer}>
                                        <div style={{ width: '80px' }}>
                                          <Image src={rejectedProduct.ProductLogo} className={classes.pd_logo} style={{ width: '100%' }} />
                                        </div>
                                        <div style={{ marginLeft: '24px' }}>
                                          <h2 className={classes.pd_title}>
                                            <LinesEllipsis
                                              text={rejectedProduct?.Name}
                                              maxLine={2}
                                              ellipsis="..."
                                              basedOn="letters"
                                            />
                                          </h2>
                                          <h5 className={classes.pd_subTitle}>{this.props?.Description}</h5>
                                        </div>
                                      </div>
                                      <Grid container spacing={1}>
                                        <Grid item xs={11}>
                                          <div className={classes.pd_descriptionContainer} style={{ cursor: 'pointer' }}>
                                            <p>
                                              <SlateReadonlyField initValue={rejectedProduct?.Reason} />
                                            </p>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </ClickAwayListener>
                                )}
                              </ul>
                              {this.state.isRejectedIntegration && (
                                <Grid container spacing={3} className={classes.buttonGroup}>
                                  <Grid item sm={6}>
                                    <div className={classes.searchAndSelect}>
                                      <Select
                                        isClearable
                                        className={classnames(
                                          classes.formControl,
                                          classes.creatable
                                        )}
                                        isSearchable={true}
                                        noOptionsMessage={() =>
                                          this.props.fetchRecommendedProductsProgress
                                            ? "Loading..."
                                            : "No options"
                                        }
                                        options={productList}
                                        classNamePrefix="select"
                                        placeholder="Choose integration"
                                        onInputChange={(e) => {
                                          this.fetchProduct(e);
                                        }}
                                        onChange={(e) => {
                                          this.setState({ rejectedIntegration: e });
                                        }}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9997 }) }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item sm={6}>
                                    <Button
                                      className={classes.tickMark}
                                      onClick={() => {
                                        this.setState({
                                          rejectedIntegration: {
                                            productID: this.state.rejectedIntegration?.ID,
                                            productName: this.state.rejectedIntegration?.Name,
                                            relationTypeFlag: false,
                                          },
                                          openRejectIntegrationDialog: true
                                        })
                                      }}
                                    >
                                      <DoneIcon />
                                    </Button>
                                    <Button
                                      className={classes.tickMark}
                                      onClick={() => this.setState({ isRejectedIntegration: false, rejectedIntegration: "" })}
                                    >
                                      <CloseIcon />
                                    </Button>
                                  </Grid>
                                </Grid>
                              )}
                            </div>}
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )
                } else if (section.SectionUID === "prod_approved_platforms") {

                  return (
                    <>
                    {(hideFlagForUser?.prod_approved_platforms) && <div className={classes.itemSection}>
                        <p className={classes.head} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                          {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                          <span>{section.SectionName}</span>{" "}
                          {(isDesktop.length > 0 || isMobile.length > 0 || isCloud.length > 0 || isSupport.length > 0) &&
                            !this.state.isPlatformEdit &&
                            hasWriteAccess && (
                              <EditIcon
                                fontSize={"small"}
                                className={classes.editIcon}
                                onClick={() => {
                                  this.updateStateForItem('isPlatformEdit', true);
                                  // this.setState({ isPlatformEdit: true });
                                }}
                              />
                            )}
                        </p>
                        {
                          <div id="platforms">
                            <div className={classes.platforms}>
                              {hasWriteAccess && !this.state.isPlatformEdit && isDesktop.length === 0 && isMobile.length === 0 && isCloud.length === 0 && isSupport.length === 0 && (
                                <ul className={classes.UL}>
                                  <li onClick={() =>
                                    // this.setState({ isPlatformEdit: true })
                                    this.updateStateForItem('isPlatformEdit', true)

                                  }>
                                    <span
                                      className={classnames(
                                        classes.tag,
                                        classes.tagClose,
                                        classes.addNew
                                      )}
                                    >
                                      <span> Add new </span>
                                      <CheckIcon className={classes.iconEle} />
                                    </span>
                                  </li>
                                </ul>
                              )}
                              {isDesktop.length > 0 && (
                                <div className={classes.platform}>
                                  <h5 className={classes.platformTitle}>Desktop</h5>
                                  <ul className={classes.UL}>
                                    {isDesktop.map((item, k) => {
                                      return (
                                        <li key={k}>
                                          <div className={classes.platformItem}>
                                            {this.state.isPlatformEdit && hasWriteAccess &&
                                              <div className={classes.closeIcon} onClick={(event) => {
                                                this.removePlatformFromProduct(item.ID)
                                              }}>
                                                <CloseIcon />
                                              </div>
                                            }
                                            {item.Name === "Windows" && (
                                              <div>
                                                <Image src={ImgWindows} alt={item.Name} />
                                                <span className={classes.platformName}>
                                                  {item.Name}
                                                </span>
                                              </div>
                                            )}
                                            {item.Name === "Mac" && (
                                              <div>
                                                <Image src={ImgMac} alt={item.Name} />
                                                <span className={classes.platformName}>
                                                  {item.Name}
                                                </span>
                                              </div>
                                            )}
                                            {item.Name === "Web" && (
                                              <div>
                                                <Image src={ImgWeb} alt={item.Name} />
                                                <span className={classes.platformName}>
                                                  {item.Name}
                                                </span>
                                              </div>
                                            )}
                                            {item.Name === "Chrome Extension" && (
                                              <div>
                                                <Image src={ImgChromeExtension} alt={item.Name} />
                                                <span className={classes.platformName}>
                                                  {item.Name}
                                                </span>
                                              </div>
                                            )}
                                            {item.Name === "Linux" && (
                                              <div>
                                                <Image src={ImgLinux} alt={item.Name} />
                                                <span className={classes.platformName}>
                                                  {item.Name}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              )}

                              {isMobile.length > 0 && (
                                <div className={classes.platform}>
                                  <h5 className={classes.platformTitle}>
                                    Mobile &#38; Tablet
                                  </h5>
                                  <ul className={classes.UL}>
                                    {isMobile.map((item, k) => {
                                      return (
                                        <li key={k}>
                                          <div className={classes.platformItem}>
                                            {this.state.isPlatformEdit && hasWriteAccess &&
                                              <div className={classes.closeIcon} onClick={(event) => {
                                                this.removePlatformFromProduct(item.ID)
                                              }}>
                                                <CloseIcon />
                                              </div>
                                            }
                                            {item.Name === "Android" && (
                                              <>
                                                <Image src={ImgAndroid} alt={item.Name} />
                                                <span className={classes.platformName}>
                                                  {item.Name}
                                                </span>
                                              </>
                                            )}
                                            {item.Name === "IOS" && (
                                              <>
                                                <Image src={ImgIos} alt={item.Name} />
                                                <span className={classes.platformName}>
                                                  {item.Name}
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              )}

                              {isCloud.length > 0 && (
                                <div className={classes.platform}>
                                  <h5 className={classes.platformTitle}>Deployment</h5>
                                  <ul className={classes.UL}>
                                    {isCloud.map((item, k) => {
                                      return (
                                        <li key={k}>
                                          <div className={classes.platformItem}>
                                            {this.state.isPlatformEdit && hasWriteAccess &&
                                              <div className={classes.closeIcon} onClick={(event) => {
                                                this.removePlatformFromProduct(item.ID)
                                              }}>
                                                <CloseIcon />
                                              </div>
                                            }
                                            {item.Name === "Cloud" && (
                                              <div>
                                                <Image src={ImgCloud} alt={item.Name} />
                                                <span className={classes.platformName}>
                                                  {item.Name}
                                                </span>
                                              </div>
                                            )}
                                            {item.Name === "On-Premise" && (
                                              <div>
                                                <Image src={ImgPremise} alt={item.Name} />
                                                <span className={classes.platformName}>
                                                  {item.Name}
                                                </span>
                                              </div>
                                            )}{" "}
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              )}

                              {isSupport.length > 0 && (
                                <div className={classes.platform}>
                                  <h5 className={classes.platformTitle}>Support</h5>
                                  <ul className={classes.UL}>
                                    {isSupport.map((item, k) => {
                                      return (
                                        <li key={k}>
                                          <div className={classes.platformItem}>
                                            {this.state.isPlatformEdit && hasWriteAccess &&
                                              <div className={classes.closeIcon} onClick={(event) => {
                                                this.removePlatformFromProduct(item.ID)
                                              }}>
                                                <CloseIcon />
                                              </div>
                                            }
                                            {item.Name === "Online" && (
                                              <div>
                                                <Image src={ImgWindows} alt={item.Name} />
                                                <span className={classes.platformName}>
                                                  {item.Name}
                                                </span>
                                              </div>
                                            )}
                                            {item.Name === "Business Hours" && (
                                              <div>
                                                <Image src={ImgMac} alt={item.Name} />
                                                <span className={classes.platformName}>
                                                  {item.Name}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        }
                        {this.state.isPlatformEdit && (
                          <Grid container spacing={3} className={classes.platformEdit}>
                            <Grid item sm={6}>
                              <div className={classes.searchAndSelect}>
                                <CreatableSelect
                                  isClearable
                                  className={classnames(
                                    classes.formControl,
                                    classes.creatable
                                  )}
                                  isSearchable={true}
                                  isMulti
                                  options={platfromList}
                                  classNamePrefix="select"
                                  placeholder="Choose platform"
                                  onInputChange={(e) => { }}
                                  onChange={(e) => {
                                    this.setState({ platform: e });
                                  }}
                                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                              </div>
                            </Grid>
                            <Grid item sm={6}>
                              <Button
                                className={classes.tickMark}
                                onClick={() => this.savePlatform()}
                              >
                                <DoneIcon />
                              </Button>
                              <Button
                                className={classes.tickMark}
                                onClick={() => this.setState({ isPlatformEdit: false })}
                              >
                                <CloseIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </div>}
                    </>
                  )
                } else if (section.SectionUID === "prod_feature_offered") {
                  return (
                    <>
                      {(hideFlagForUser?.prod_feature_offered) && <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                        <p className={classes.head}>{section.SectionName}</p>
                        {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                        <div>
                          <ul className={classes.UL}>
                            {this.props.orgFeatures &&
                              this.props.orgFeatures
                                ?.slice(
                                  0,
                                  viewMore.indexOf("Features") > -1
                                    ? this.props.orgFeatures?.length
                                    : 5
                                )
                                .map((item, k) => {
                                  return (
                                    <li key={k} style={{ marginRight: "14px" }}>
                                      <span
                                        className={classnames(
                                          classes.tag,
                                          classes.tagClose,
                                          classes.inverse
                                        )}
                                      >
                                        <span>{item?.Name}</span>{" "}
                                        {hasWriteAccess && (
                                          <CloseIcon
                                            className={classes.iconEle}
                                            onClick={() => {
                                              this.deleteOrgFeatures(item);
                                            }}
                                          />
                                        )}
                                      </span>
                                    </li>
                                  );
                                })}
                            {this.props.orgFeatures &&
                              this.props.orgFeatures?.length > 5 && (
                                <li key={"viewMore"} style={{ marginRight: "14px" }}>
                                  <span
                                    className={classnames(classes.tag, classes.view_more)}
                                    onClick={() => {
                                      this.viewMore(
                                        "Features",
                                        viewMore.indexOf("Features") > -1
                                      );
                                    }}
                                  >
                                    {viewMore.indexOf("Features") > -1
                                      ? "- View less"
                                      : "+ View more"}
                                  </span>
                                </li>
                              )}
                            {!this.state.isFeaturesOffered &&
                              hasWriteAccess && (
                                <li
                                  onClick={() =>
                                    this.updateStateForItem('isFeaturesOffered', true)

                                    // this.setState({ isFeaturesOffered: true })

                                  }
                                >
                                  <span
                                    className={classnames(
                                      classes.tag,
                                      classes.tagClose,
                                      classes.addNew
                                    )}
                                  >
                                    <span> Add new</span>
                                    <CheckIcon className={classes.iconEle} />
                                  </span>
                                </li>
                              )}
                          </ul>
                          {this.state.isFeaturesOffered && (
                            <Grid container spacing={3} className={classes.buttonGroup}>
                              <Grid item sm={6}>
                                <div className={classes.searchAndSelect}>
                                  <CreatableSelect
                                    isClearable
                                    isMulti
                                    className={classnames(
                                      classes.formControl,
                                      classes.creatable
                                    )}
                                    formatCreateLabel={(userInput) =>
                                      this.props.fetchSubCategoryFeaturesProgress
                                        ? "Loading..."
                                        : `Create '${userInput}'`
                                    }
                                    noOptionsMessage={() =>
                                      "Start typing to select or features offered"
                                    }
                                    isSearchable={true}
                                    options={subCategoryFeatures}
                                    classNamePrefix="select"
                                    placeholder="Choose features offered"
                                    onInputChange={(e) => {
                                      this.fetchFeatureData(e);
                                    }}
                                    onChange={(e) => {
                                      this.setState({ features: e });
                                    }}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                  />
                                </div>
                              </Grid>
                              <Grid item sm={6}>
                                <Button
                                  className={classes.tickMark}
                                  onClick={() => this.saveFeaturesOffered()}
                                >
                                  <DoneIcon />
                                </Button>
                                <Button
                                  className={classes.tickMark}
                                  onClick={() =>
                                    this.setState({ isFeaturesOffered: false })
                                  }
                                >
                                  <CloseIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </div>
                      </div>}
                    </>
                  )
                } else if (section.SectionUID === "prod_similar_products") {
                  return (
                    <>
                      {/* Alternative products */}
                      {(hideFlagForUser?.prod_similar_products) && (
                        <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                          <div>
                            {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                            <div className={classes.topHead}>
                              <span>
                                <h3
                                  className={classes.head}
                                >
                                  {section.SectionName}
                                </h3>
                              </span>
                              {similarProducts !== undefined && similarProducts.length > 5 && (
                                <Link
                                  to={`/app/products/org-product-relation/${this.props.match?.params?.id}/2`}
                                  className={classes.viewAll}
                                >
                                  {" "}
                                  <div style={{ marginRight: '25px' }}>View all ({similarProducts.length}){" "}</div>
                                </Link>
                              )}
                            </div>
                          </div>
                          <Grid container spacing={3} className={classes.bottomSpace}>
                            {similarProducts &&
                              similarProducts
                                ?.slice(
                                  0,
                                  !this.state.showMoreProducts
                                    ? 5
                                    : similarProducts.length
                                )
                                .map((product, k) => {

                                  return (
                                    <Grid
                                      item
                                      key={"similar_" + product.ID}
                                      className={classes.productList}
                                      lg={isCart ? 3 : 2}
                                      md={isCart ? 4 : 3}
                                      sm={isCart ? 12 : 6}
                                      xs={12}
                                    >
                                      {hasWriteAccess && (
                                        <Image
                                          src={deleteIcon}
                                          alt={product.Name}
                                          className={classes.deleteImage}
                                          onClick={() =>
                                            this.deleteSimilarProduct(product)
                                          }
                                        />
                                      )}
                                      <LayeredCard
                                        title={product.Name}
                                        subTitle={product.CompanyName}
                                        description={product.Description}
                                        customRoute={product.IsExisting ? '/app/products/org-product-details/' : '/app/products/product-details/'}
                                        image={product.ProductLogo}
                                        onClick={() => {
                                          const payload = {
                                            event_type: "RedirectSearch",
                                            from: productDetails.productID,
                                            to: product.ID,
                                            entity_type: "product",
                                            page: "software product detail view",
                                            // query: productDetails?.name,
                                            query: this.props.searchData?.text
                                          };

                                          this.props.updateReportData(payload);
                                         }}
                                        numLayer={0}
                                        checked={addedToCart.indexOf(product.ID) !== -1}
                                        showCheckbox={false}
                                        id={product.ID}
                                        uniqueId={product.ID + "_q_" + k}
                                        type={"product"}
                                        IsExisting={product.IsExisting}
                                        fireEvent
                                        eventPage={'orgProductDetails'}
                                        eventData={{
                                          type: 'event.click.org-product.section',
                                          data: {
                                            url: this.props.location.pathname + this.props?.location?.search,
                                            section: {
                                              id: section.SectionUID,
                                              name: section.SectionName
                                            },
                                            product: {
                                              id: product.ID,
                                              name: product.Name,
                                              position: k + 1
                                            }
                                          }
                                        }}
                                      />
                                    </Grid>
                                  );
                                })}
                            {!this.state.isSimilarProduct && (
                              <Grid
                                item
                                lg={isCart ? 3 : 2}
                                md={isCart ? 4 : 3}
                                sm={isCart ? 12 : 6}
                                xs={12}
                              >
                                {this.props.orgSimilarProductProgress && <CircularProgress />}
                                {!this.props.orgSimilarProductProgress && hasWriteAccess && (
                                  <div onClick={() => {
                                    this.updateStateForItem('isSimilarProduct', true)
                                  }}>
                                    <LayeredCard
                                      title={"+Add Vendor"}
                                      onClick={() => { }}
                                      numLayer={0}
                                      image={AddEvaluationImage}
                                      showCheckbox={false}
                                    />
                                  </div>
                                )}
                              </Grid>
                            )}
                          </Grid>
                          {this.state.isSimilarProduct && (
                            <Grid
                              container
                              spacing={3}
                              className={classnames(
                                classes.buttonGroup,
                                classes.marginTop
                              )}
                            >
                              <Grid item sm={6}>
                                <div
                                  className={classnames(
                                    classes.searchAndSelect,
                                    classes.nomargin
                                  )}
                                >
                                  <Select
                                    isClearable
                                    className={classnames(
                                      classes.formControl,
                                      classes.creatable
                                    )}
                                    isSearchable={true}
                                    noOptionsMessage={() =>
                                      this.props.fetchRecommendedProductsProgress
                                        ? "Loading..."
                                        : "No options"
                                    }
                                    options={productList}
                                    classNamePrefix="select"
                                    placeholder="Choose product"
                                    onInputChange={(e) => {
                                      this.fetchProduct(e);
                                    }}
                                    onChange={(e) => {
                                      this.setState({ similarProduct: e });
                                    }}
                                    formatOptionLabel={ProductOptionLabel}
                                    filterOption={ProductSearchFunction}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9996 }) }}
                                  />
                                </div>
                              </Grid>
                              <Grid item sm={6}>
                                <Button
                                  className={classes.tickMark}
                                  onClick={() => this.saveSimilarProduct()}
                                >
                                  <DoneIcon />
                                </Button>
                                <Button
                                  className={classes.tickMark}
                                  onClick={() =>
                                    this.setState({ isSimilarProduct: false })
                                  }
                                >
                                  <CloseIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </div>
                      )}
                    </>
                  )
                } else if (section.SectionUID === "prod_other_products") {
                  return (
                    <>
                      {/* same company products */}
                      {!this.props.orgSameProductProgress && (hideFlagForUser?.prod_other_products) && (
                        <>
                          <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                            <div>
                              {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}

                              <div className={classes.topHead}>
                                <span>
                                  <h3
                                    className={classes.head}
                                  >
                                    {section.SectionName} by {productDetails?.companyName}
                                  </h3>
                                </span>
                                {this.props.orgSameProductTotal > 5 && (
                                  <Link
                                    to={`/app/products/org-product-relation/${this.props.match?.params?.id}/3`}
                                    className={classes.viewAll}
                                  >
                                    {" "}
                                    <div style={{ marginRight: '25px' }}>View all ({this.props.orgSameProductTotal}){" "}</div>

                                  </Link>
                                )}
                              </div>
                            </div>
                            <Grid container spacing={3} className={classes.bottomSpace}>
                              {productFromsameCompany &&
                                productFromsameCompany
                                  ?.slice(
                                    0,
                                    !this.state.showMoreCompanyProducts
                                      ? 5
                                      : productFromsameCompany.length
                                  )
                                  .map((product, k) => {
                                    return (
                                      <Grid
                                        item
                                        lg={isCart ? 3 : 2}
                                        key={"product_" + product.ID}
                                        className={classes.productList}
                                        md={isCart ? 4 : 3}
                                        sm={isCart ? 12 : 6}
                                        xs={12}
                                      >
                                        {hasWriteAccess && (
                                          <Image
                                            src={deleteIcon}
                                            alt={product.Name}
                                            className={classes.deleteImage}
                                            onClick={() => this.deleteSameProduct(product)}
                                          />
                                        )}
                                        <LayeredCard
                                          title={product.Name}
                                          subTitle={product.CompanyName}
                                          description={product.Description}
                                          image={product.ProductLogo}
                                          onClick={() => {  
                                              const payload = {
                                              event_type: "RedirectSearch",
                                              from: productDetails.productID,
                                              to: product.ID,
                                              entity_type: "product",
                                              page: "software product detail view",
                                              // query: productDetails?.name,
                                              query: this.props.searchData?.text
                                            };

                                            this.props.updateReportData(payload); }
                                          }
                                          numLayer={0}
                                          checked={addedToCart.indexOf(product.ID) !== -1}
                                          showCheckbox={false}
                                          id={product.ID}
                                          uniqueId={product.ID + "_r_" + k}
                                          type={"product"}
                                          IsExisting={product.IsExisting}
                                          fireEvent
                                          eventPage={'orgProductDetails'}
                                          eventData={{
                                            type: 'event.click.org-product.section',
                                            data: {
                                              url: this.props.location.pathname + this.props?.location?.search,
                                              section: {
                                                id: section.SectionUID,
                                                name: section.SectionName
                                              },
                                              product: {
                                                id: product.ID,
                                                name: product.Name,
                                                position: k + 1
                                              }
                                            }
                                          }}
                                        />
                                      </Grid>
                                    );
                                  })}

                              {!this.state.isSameProduct && hasWriteAccess && (
                                <Grid
                                  item
                                  lg={isCart ? 3 : 2}
                                  md={isCart ? 4 : 3}
                                  sm={isCart ? 12 : 6}
                                  xs={12}
                                  onClick={() => {
                                    this.updateStateForItem('isSameProduct', true)
                                  }}
                                >
                                  <LayeredCard
                                    title={"+Add Vendor"}
                                    onClick={() => { }}
                                    numLayer={0}
                                    image={AddEvaluationImage}
                                    showCheckbox={false}
                                  />
                                </Grid>
                              )}
                            </Grid>
                            {this.state.isSameProduct && (
                              <Grid
                                container
                                spacing={3}
                                className={classnames(
                                  classes.buttonGroup,
                                  classes.marginTop
                                )}
                              >
                                <Grid item sm={6}>
                                  <div
                                    className={classnames(
                                      classes.searchAndSelect,
                                      classes.nomargin
                                    )}
                                  >
                                    <Select
                                      isClearable
                                      className={classnames(
                                        classes.formControl,
                                        classes.creatable
                                      )}
                                      isSearchable={true}
                                      noOptionsMessage={() =>
                                        this.props.fetchRecommendedProductsProgress
                                          ? "Loading..."
                                          : "No options"
                                      }
                                      options={productList}
                                      classNamePrefix="select"
                                      placeholder="Choose product"
                                      onInputChange={(e) => {
                                        this.fetchProduct(e);
                                      }}
                                      onChange={(e) => {
                                        this.setState({ sameProduct: e });
                                      }}
                                      formatOptionLabel={ProductOptionLabel}
                                      filterOption={ProductSearchFunction}
                                      styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item sm={6}>
                                  <Button
                                    className={classes.tickMark}
                                    onClick={() => this.saveSameProduct()}
                                  >
                                    <DoneIcon />
                                  </Button>
                                  <Button
                                    className={classes.tickMark}
                                    onClick={() =>
                                      this.setState({ isSameProduct: false })
                                    }
                                  >
                                    <CloseIcon />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )
                } else if (section.SectionUID === "prod_complementary_products") {
                  return (
                    <>
                      {!this.props.orgComplementaryProductProgress && (hideFlagForUser?.prod_complementary_products) && (
                        <>
                          <div
                            className={classnames(
                              classes.itemSection,
                              classes.complementary
                            )}
                            id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}
                          >
                            <div>
                              {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}

                              <div className={classes.topHead}>
                                <span>
                                  <h3
                                    className={classes.head}
                                  >
                                    {section.SectionName}
                                  </h3>
                                </span>
                                {this.props.orgComplementaryProductTotal > 5 && (
                                  <Link
                                    to={`/app/products/org-product-relation/${this.props.match?.params?.id}/4`}
                                    className={classes.viewAll}
                                  >
                                    {" "}
                                    <div style={{ marginRight: '25px' }}>View all ({
                                      this.props.orgComplementaryProductTotal
                                    }){" "}</div>


                                  </Link>
                                )}
                              </div>
                            </div>
                            <Grid container spacing={3} className={classes.bottomSpace}>
                              {complementryProducts &&
                                complementryProducts
                                  ?.slice(
                                    0, !this.state.showMoreComplementaryProducts ? 5 : complementryProducts.length
                                  )
                                  .map((product, k) => {
                                    return (
                                      <Grid
                                        item
                                        lg={isCart ? 3 : 2}
                                        key={"complementary_" + product.ID}
                                        className={classes.productList}
                                        md={isCart ? 4 : 3}
                                        sm={isCart ? 12 : 6}
                                        xs={12}
                                      >
                                        {hasWriteAccess && (
                                          <Image
                                            src={deleteIcon}
                                            alt={product.Name}
                                            className={classes.deleteImage}
                                            onClick={() =>
                                              this.deleteComplementaryProduct(product)
                                            }
                                          />
                                        )}
                                        <LayeredCard
                                          title={product.Name}
                                          subTitle={product.CompanyName}
                                          description={product.Description}
                                          image={product.ProductLogo}
                                          onClick={() => {  
                                            const payload = {
                                            event_type: "RedirectSearch",
                                            from: productDetails.productID,
                                            to: product.ID,
                                            entity_type: "product",
                                            page: "software product detail view",
                                            // query: productDetails?.name,
                                            query: this.props.searchData?.text
                                            };

                                            this.props.updateReportData(payload); }
                                          }
                                          numLayer={0}
                                          checked={addedToCart.indexOf(product.ID) !== -1}
                                          showCheckbox={false}
                                          id={product.ID}
                                          uniqueId={product.ID + "_s_" + k}
                                          type={"product"}
                                          IsExisting={product.IsExisting}
                                          fireEvent
                                          eventPage={'orgProductDetails'}
                                          eventData={{
                                            type: 'event.click.org-product.section',
                                            data: {
                                              url: this.props.location.pathname + this.props?.location?.search,
                                              section: {
                                                id: section.SectionUID,
                                                name: section.SectionName
                                              },
                                              product: {
                                                id: product.ID,
                                                name: product.Name,
                                                position: k + 1
                                              }
                                            }
                                          }}
                                        />
                                      </Grid>
                                    );
                                  })}
                              {!this.state.isComplementaryProduct && hasWriteAccess && (
                                <Grid
                                  item
                                  lg={isCart ? 3 : 2}
                                  md={isCart ? 4 : 3}
                                  sm={isCart ? 12 : 6}
                                  xs={12}
                                  onClick={() => {
                                    this.updateStateForItem('isComplementaryProduct', true)
                                  }}
                                >
                                  <LayeredCard
                                    title={"+Add Vendor"}
                                    onClick={() => { }}
                                    numLayer={0}
                                    image={AddEvaluationImage}
                                    showCheckbox={false}
                                  />
                                </Grid>
                              )}
                            </Grid>
                            {/* {
                                      complementryProducts && complementryProducts.length > 5 &&
                                      <div style={{textAlign: 'center',padding: '35px 15px'}}>
                                          <span className={classes.show_more} onClick={()=>this.setState({showMoreComplementaryProducts: !this.state.showMoreComplementaryProducts})}>
                                              {
                                                  this.state.showMoreComplementaryProducts === false ? 'Show more' : 'Show less'
                                              }
                                          </span>
                                      </div>
                                  } */}
                            {this.state.isComplementaryProduct && (
                              <Grid
                                container
                                spacing={3}
                                className={classnames(
                                  classes.buttonGroup,
                                  classes.marginTop
                                )}
                              >
                                <Grid item sm={6}>
                                  <div
                                    className={classnames(
                                      classes.searchAndSelect,
                                      classes.nomargin
                                    )}
                                  >
                                    <Select
                                      isClearable
                                      className={classnames(
                                        classes.formControl,
                                        classes.creatable
                                      )}
                                      isSearchable={true}
                                      noOptionsMessage={() =>
                                        this.props.fetchRecommendedProductsProgress
                                          ? "Loading..."
                                          : "No options"
                                      }
                                      options={productList}
                                      classNamePrefix="select"
                                      placeholder="Choose product"
                                      onInputChange={(e) => {
                                        this.fetchProduct(e);
                                      }}
                                      onChange={(e) => {
                                        this.setState({ complementaryProduct: e });
                                      }}
                                      formatOptionLabel={ProductOptionLabel}
                                      filterOption={ProductSearchFunction}
                                      styles={{ menuPortal: base => ({ ...base, zIndex: 9994 }) }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item sm={6}>
                                  <Button
                                    className={classes.tickMark}
                                    onClick={() => this.saveComplementaryProduct()}
                                  >
                                    <DoneIcon />
                                  </Button>
                                  <Button
                                    className={classes.tickMark}
                                    onClick={() =>
                                      this.setState({ isComplementaryProduct: false })
                                    }
                                  >
                                    <CloseIcon />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )
                } else if (section.SectionUID === "prod_pricing_information") {
                  return (
                    <>   
                      {hideFlagForUser?.prod_pricing_information ? (
                        <>
                        <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                          {(this.props.adminFlag) && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers}/>}       
                          
                          <PricingSection
                            productName={productDetails?.name}
                            productId={this.props?.match?.params?.id}
                            title={section?.SectionName}
                          />
                          </div>
                        </>
                      ): null}
                    </>
                  )
                } else if (section.SectionUID === "companies_like_you_use") {
                  return (
                    <>
                      {hideFlagForUser?.companies_like_you_use && (

                        <div
                          className={classnames(
                            classes.itemSection,
                            classes.complementary
                          )}
                          id="companies-like-you-use"
                        >
                          <div>
                            {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                            <div className={classes.topHead}>
                              <span>
                                <h3
                                  className={classes.head}
                                >
                                  Companies like you use 1
                                </h3>
                              </span>
                            </div>
                          </div>
                          <Grid container spacing={3} className={classes.bottomSpace}>
                            {alternativeProducts &&
                              alternativeProducts
                                .map((product, k) => {
                                  return (
                                    <Grid
                                      item
                                      lg={2}
                                      key={"alternative_" + product.ID}
                                      className={classes.productList}
                                      md={3}
                                      sm={6}
                                      xs={12}
                                    >
                                      <LayeredCard
                                        title={product.Name}
                                        subTitle={product.CompanyName}
                                        description={product.Description}
                                        image={product.ProductLogo}
                                        onClick={() => {
                                          //trigger the RedirectSearch api with query, fromProductId, toProductId, entityType, page
                                          console.log("alternative Products", this.props.searchData)
                                          const payload = {
                                            event_type: "RedirectSearch",
                                            from: productDetails.productID,
                                            to: product.ID,
                                            entity_type: "product",
                                            page: "software product detail view",
                                            // query: productDetails?.name,
                                            query: this.props.searchData?.text
                                          };

                                          this.props.updateReportData(payload);

                                         }}
                                        numLayer={0}
                                        checked={addedToCart.indexOf(product.ID) !== -1}
                                        showCheckbox={false}
                                        id={product.ID}
                                        uniqueId={product.ID + "_s_" + k}
                                        type={"product"}
                                        IsExisting={product.IsExisting}
                                        fireEvent
                                        eventPage={'orgProductDetails'}
                                        eventData={{
                                          type: 'event.click.org-product.section',
                                          data: {
                                            url: this.props.location.pathname + this.props?.location?.search,
                                            section: {
                                              id: 'alternative_products',
                                              name: 'alternative_products'
                                            },
                                            product: {
                                              id: product.ID,
                                              name: product.Name,
                                              position: k + 1
                                            }
                                          }
                                        }}
                                      />
                                    </Grid>
                                  );
                                })}
                          </Grid>
                        </div>

                      )}
                    </>
                  )
                } else {
                  return null
                }
              })}
            </div>
          )}

          {this.props.orgProductDetailsFetchProgress && (
            <div>
              <CircularProgress />
            </div>
          )}
          <RejectedIntegrationReasonDialog
            isOpen={this.state.openRejectIntegrationDialog}
            onSubmit={msg => {
              const data = this.state.rejectedIntegration
              this.setState({ rejectedIntegration: true }, () => {
                this.saveIntegration(
                  data.productID,
                  data.productName,
                  data.relationTypeFlag,
                  msg
                )
              })
            }}
            value=""
            onClose={() => this.setState({ openRejectIntegrationDialog: false, isRejectedIntegration: false })}
          />
        </div>
      </>
    );
  }
}

export default connector(
  compose(withRouter, withStyles(styles))(OrgProductDetailsTab)
);
