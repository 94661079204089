/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {OktaFactory} from "../../services/okta";
import { UserFactory } from "../../services/user";

export const START_OKTA_CONFIG_FETCH     =   "START_OKTA_CONFIG_FETCH";
export const END_OKTA_CONFIG_FETCH       =   "END_OKTA_CONFIG_FETCH";

export const START_OKTA_USER_FETCH     =   "START_OKTA_USER_FETCH";
export const END_OKTA_USER_FETCH       =   "END_OKTA_USER_FETCH";

export const OKTA_ADD_TEAM          =      "OKTA_ADD_TEAM";
export const OKTA_UPDATE_TEAM       =   "OKTA_UPDATE_TEAM";
export const OKTA_TEAM_CHECK_ALL = "OKTA_TEAM_CHECK_ALL";

export const SHOW_ADD_TEAM_DIALOG   =   "SHOW_ADD_TEAM_DIALOG";
export const HIDE_ADD_TEAM_DIALOG   =   "HIDE_ADD_TEAM_DIALOG";

export const SHOW_USER_GROUP_DIALOG   =   "SHOW_USER_GROUP_DIALOG";
export const HIDE_USER_GROUP_DIALOG   =   "HIDE_USER_GROUP_DIALOG";

export const START_ADD_TEAM_MEMBERS   =   "START_ADD_TEAM_MEMBERS";
export const END_ADD_TEAM_MEMBERS   =   "END_ADD_TEAM_MEMBERS";

//Action to start the registration
function startOktaConfigFetch(){
    return {
        type:START_OKTA_CONFIG_FETCH,
        payload:null
    };
}

function endOktaConfigFetch(type,success,error){
    return {
        type:END_OKTA_CONFIG_FETCH,
        payload:{
            type,success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchConfig(){
    return async (dispatch) => {
        dispatch(startOktaConfigFetch());
        try{
            let [response,error]    =   await OktaFactory.instance().getAppConfig();
            dispatch(endOktaConfigFetch("Okta",response,error));
        } catch(e){
            dispatch(endOktaConfigFetch("Okta",null,{
                message:e.message
            }));
            return;
        }
    };
}   

//Action to start the registration
function startOktaUserFetch(page){
    return {
        type:START_OKTA_USER_FETCH,
        payload:page
    };
}

function endOktaUserFetch(success,error){
    return {
        type:END_OKTA_USER_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchUser(query,  page = 0, pageSize = 20){
    return async (dispatch) => {
        dispatch(startOktaUserFetch(page));
        try{
            let [response,error]    =   await OktaFactory.instance().fetchUser(query,  page, pageSize);
            dispatch(endOktaUserFetch(response,error));
        } catch(e){
            dispatch(endOktaUserFetch(null,{
                message:e.message
            }));
            return;
        }
    };
}   

export function addTeamMember(user){
    return {
        type:OKTA_ADD_TEAM,
        payload:user
    };
}

export  function updateTeamMember(user){
    return {
        type:OKTA_UPDATE_TEAM,
        payload:user
    };
}

export  function checkUnCheckAllTeamMember(status){
    return {
        type:OKTA_TEAM_CHECK_ALL,
        payload:status
    };
}


/**
 * Show add team dialog
 * @returns true || false
 */
export  function showAddTeamDialog(){
    return {
        type:SHOW_ADD_TEAM_DIALOG,
        payload:null
    };
}

export  function hideAddTeamDialog(){
    return {
        type:HIDE_ADD_TEAM_DIALOG,
        payload:null
    };
}


//Action to start the registration
function startAddTeamMember(){
    return {
        type:START_ADD_TEAM_MEMBERS,
        payload:null
    };
}

function endAddTeamMember(success,error){
    return {
        type:END_ADD_TEAM_MEMBERS,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function addTeamMembers(members){
    return async (dispatch) => {
        dispatch(startAddTeamMember());
        try{
            let [response,error]    =   await UserFactory.instance().addMembers(members);
            dispatch(endAddTeamMember(response,error));
        } catch(e){
            dispatch(endAddTeamMember(null,{
                message:e.message
            }));
            return;
        }
    };
}   

/**
 * Show add user group dialog
 * @returns true || false
 */
export  function showUserGroupDialog(data = null){
    return {
        type:SHOW_USER_GROUP_DIALOG,
        payload:{
            data
        }
    };
}

export  function hideUserGroupDialog(){
    return {
        type:HIDE_USER_GROUP_DIALOG,
        payload:null
    };
}