import React from 'react';
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser'
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import classnames from "classnames";
import { Node } from 'slate'
// import OutlinedActionInput from "Components/Common/Input/OutlinedActionInput";
import { withRouter } from 'react-router-dom';
import { Typography, CircularProgress, Button } from '@material-ui/core';
import * as Validator from "util/Validation";
import _ from "underscore";
import Box from '@material-ui/core/Box';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SecurityIcon from '@material-ui/icons/Security';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Messages from "assets/images/add_message.svg"
import editIcon from "assets/images/edit_icon.svg";
import Image from 'Components/Common/image.jsx';
import TooltipShow from '@material-ui/core/Tooltip';
import CachedIcon from '@material-ui/icons/Cached';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { getMentionedUsersIds } from "util/Common";
import { ArrowForwardIos as ArrowForwardIcon, ArrowBackIos as ArrowBackIcon } from '@material-ui/icons';
import CollectiveUserResponses from './CollectiveUserResponses';
import CollectiveSellerResponses from './CollectiveSellerResponses';
import UserGroup from '../../../../../../../Common/UserGroup';
import ProductReqMeet from '../common/ProductReqMeet';
// import ProductReqMeetSeller from '../common/ProductReqMeetSeller';
import SqureButton from 'Components/Common/SqureButton';

import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
import { showSnackBar } from "redux/snackbar/action";
import RequirementReply from 'Components/Common/FormReply/RequirementReply';
import {
  fetchRemoveRequirementCollaborator
} from "redux/evaluate/action";
import DoneIcon from "@material-ui/icons/Done";
import { CommonFn } from "services/commonFn";

import {
  addRequirementResponse,
  fetchRequirementUserResponse,
  setSelectedRequirement,
  setSelectedProduct,
  fetchColloborator,
  addRequirementColloborator,
  showAskSellerDialog,
  fetchCollectiveResponses,
  fetchAskSellerThread,
  addAskSellerRequest,
  setMoveToNextFlag, setIsNewChangesFound,
  fetchRequirementResponse,
} from "redux/evaluation/criteria/action"
import { showEvaluationProductEditDialog } from "redux/evaluation/cart/action";
import { tooglePreviousInteractionDialog, setResponseTab } from "redux/evaluation/criteria/action";

import { findRequirementPreviousInteraction } from '../../../../../../../../util/index';
import { showAddUpdateTaskDialog } from "redux/evaluation/productTask/action";
import { getNextAndPreviousIds } from "util/Common";
import CheckUnsavedResDialog from '../common/CheckUnsavedResDialog';

import {
  showResponseMessageDialog,
  hideResponseMessageDialog,
  showConfirmCallbackDialog,
  hideConfirmCallbackDialog
} from "redux/applications/action";

import ConfirmCallbackDialog from "Components/Common/ConfirmCallbackDialog";
import InternalChat from '../common/InternalChat';
import CloseIcon from "@material-ui/icons/Close";

const connectedProps = (state) => ({
  OrgID: state.authUser.user.OrgID,
  user: state.authUser.user,
  evaluation: state.evaluationMetaData.evaluation,
  lastEvaUserReponses: state.evaludationCriteria.lastEvaUserReponses,
  requirementResponses: state.evaludationCriteria.requirementResponses,
  fetchRequirementResponseProgress: state.evaludationCriteria.fetchRequirementResponseProgress,
  requirementUserResponses: state.evaludationCriteria.requirementUserResponses,
  addResponseProgress: state.evaludationCriteria.addResponseProgress,
  errorResponseAdd: state.evaludationCriteria.errorResponseAdd,
  successResponseAdd: state.evaludationCriteria.successResponseAdd,
  selectedRequirementId: state.evaludationCriteria.selectedRequirementId,
  selectedProductId: state.evaludationCriteria.selectedProductId,
  colloborators: state.evaludationCriteria.colloborators,
  evaluateView: state.applications.evaluateView,
  evaluateViewId: state.applications.evaluateViewId,
  criterias: state.evaludationCriteria.criterias,
  productRequirement: state.products.productRequirement,
  threadFetchProgress: state.evaludationCriteria.fetchAskSellerResponseInprogress,
  thread: state.evaludationCriteria.fetchAskSelerThread,
  saveProgress: state.evaludationCriteria.askSellerSubmitProgress,
  collectiveResponses: state.evaludationCriteria.collectiveResponses,
  fetchCollectiveResponsesLoading: state.evaludationCriteria.fetchCollectiveResponsesLoading,
  evaluationProductsList: state.evaluationMetaData.evaluationProductsList,
  sellerRequirement: state.evaludationCriteria.fetchAskSelerRequirement,
  sellerResponse: state.evaludationCriteria.fetchAskSelerResponse,
  requirementTree: state.evaludationCriteria.requirementTree,
  fetchReqTreeProgress: state.evaludationCriteria.fetchReqTreeUnderProgress,
  isShowEvaluationGuideFormDialog: state.workflow.isShowEvaluationGuideFormDialog,
  previousInteractions: state.evaludationCriteria.previousInteractions,
  fetchReqResponseUserResStatusProgress: state.evaludationCriteria.fetchReqResponseUserResStatusProgress,
  fetchReqResponseUserResProgress: state.evaludationCriteria.fetchReqResponseUserResProgress,
  evaluationInfoData: state.evaluationMetaData.evaluationInfoData,
  isMoveToNextReq: state.evaludationCriteria.isMoveToNextReq,
  goToRequirement: state.evaludationCriteria.goToRequirement,
  criteriaUserRoleInprogress: state.evaludationCriteria.criteriaUserRoleInprogress,
  deleteRequirementCollaboratorInprogress: state.evaluate.deleteRequirementCollaboratorInprogress,
  orgUser: state.orgUser,
  userRole: state.authUser.user?.Role,
});

const connectionActions = {
  addRequirementResponse: addRequirementResponse,
  fetchRequirementUserResponse: fetchRequirementUserResponse,
  setSelectedRequirement: setSelectedRequirement,
  setSelectedProduct: setSelectedProduct,
  fetchColloborator: fetchColloborator,
  addRequirementColloborator: addRequirementColloborator,
  showAskSellerDialog: showAskSellerDialog,
  fetchAskSellerThread: fetchAskSellerThread,
  fetchCollectiveResponses: fetchCollectiveResponses,
  addAskSellerRequest: addAskSellerRequest,
  showEvaluationProductEditDialog: showEvaluationProductEditDialog,
  tooglePreviousInteractionDialog: tooglePreviousInteractionDialog,
  setResponseTab: setResponseTab,
  showSnackBar: showSnackBar,
  showAddUpdateTaskDialog: showAddUpdateTaskDialog,
  setMoveToNextFlag: setMoveToNextFlag,
  showMessageDialog: showResponseMessageDialog,
  hideMessageDialog: hideResponseMessageDialog,
  showConfirmCallbackDialog: showConfirmCallbackDialog,
  hideConfirmCallbackDialog: hideConfirmCallbackDialog,
  setIsNewChangesFound: setIsNewChangesFound,
  fetchRequirementResponse: fetchRequirementResponse,
  removeRequirementCollaborator: fetchRemoveRequirementCollaborator
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  informationBox: {
    backgroundColor: 'hsl(196deg 72% 93%)',
    border: '2px solid hsl(187deg 55% 82%)',
    padding: theme.spacing(2, 1),
    borderRadius: theme.spacing(1.25),
    boxShadow: '0px 1px 10px -5px rgb(157 133 133 / 77%)'
  },
  infoText: {
    '& [class*="MuiFormLabel-root"]': {
      marginBottom: '0px !important'
    },
    '& p': {
      margin: '0px !important'
    },
  },
  infoReqText: {
    fontWeight: 400,
  },
  answerBox: {
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    borderBottom: '1px solid #f1f1f1',
  },
  tabPanel: {
    backgroundColor: '#fff',
    minHeight: theme.spacing(40),
  },
  tabPanelRes: {
    backgroundColor: '#fff',
    minHeight: theme.spacing(40),
    '& [class*=MuiBox-root]': {
      padding: '1.5em 1.5em 1.5em 1.5em'
    },
    '& [class*=makeStyles-label]': {
      display: 'none'
    }
  },
  optionMessage: {
    fontSize: theme.spacing(2),
    color: '#AFAFAF',
    margin: '0'
  },
  answerItmBox: {
    border: "1px solid #f1f1f1",
    padding: theme.spacing(0.2, 0),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  collUsers: {
    margin: theme.spacing(2, 0),
    border: '2px solid #f6f6f6',
    padding: theme.spacing(1, 1),
    borderRadius: theme.spacing(1.25),
    boxShadow: '0px 1px 10px -5px rgb(157 133 133 / 77%)',
    background: '#fff'
  },
  ansWerOption: {
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(2),
    color: '#6D7278'
  },
  respoRoot: {
    background: '#fff',
    paddingBottom: theme.spacing(2),
    position: 'relative',
    boxShadow: '0px 1px 10px -5px rgb(157 133 133 / 77%)'
  },
  formControl: {
    // width:theme.spacing(20.5)

  },
  resSelect: {
    padding: theme.spacing(0.2, 2),
    border: '1px solid #2EB77D',
    height: theme.spacing(4)
  },
  addText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80px',
    fontSize: 13,
    color: '#707070',
    cursor: 'pointer',
    transition: 'all linear .5s',
  },
  addTextimage: {
    marginRight: 10,
    width: 21,
    position: 'relative',
    top: 2
  },
  responseContent: {
    color: '#707070',
    fontSize: 13,
    padding: '5px 0px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRight: '1px solid #EEEEEE',
    '& p': {
      marginTop: 0,
      marginBottom: 0,
    }
  },
  rNote: {
    height: '100%',
    minHeight: '25px',
    fontSize: 13,
    color: '#707070',
    width: '100%',
    // margin: '0px auto',
    overflow: 'overlay'
    // textAlign:"center",
    // padding:theme.spacing(4)
  },
  noteSection: {
    width: 'calc(100% - 7px)',
    marginTop: 5,
    "& [class*='editableField']": {
      maxHeight: '105px !important;',
      minHeight: '105px !important',
    }
  },
  showNoteSection: {
    width: 'calc(100% - 7px)',
    "& [class*='editableField']": {
      margin: '0px !important',
      minHeight: 'auto !important',
      maxHeight: 'auto !important',
    }
  },
  flexListContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  boxRes: {
    // display: 'inline-block'
    border: '1px solid #222',
    borderRadius: '4px',
    padding: '0px 10px',
    marginRight: '10px',
    cursor: 'pointer'
  },
  boxResActive: {
    border: '1px solid #007BF7',
    borderRadius: '4px',
    padding: '0px 10px',
    marginRight: '10px',
    cursor: 'pointer',
    color: '#007BF7'
  },
  showSellerNoteSection: {
    width: 'calc(100% - 7px)',
    "& [class*='editableField']": {
      margin: '0px !important',
      minHeight: 'auto !important',
      maxHeight: 'auto !important',
    },
  },
  askSellerBtn: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: ' 1px solid #398AF5',
    borderRadius: '4px',
    letterSpacing: '0px',
    color: '#398AF5',
    padding: '3px 10px',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    marginLeft: '6px'
  },
  askImage: {
    marginRight: 5
  },
  collectiveUserResponses: {
    background: '#fff',
    // paddingTop: theme.spacing(1),
    // marginTop: theme.spacing(2),
  },
  yourResponse: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#707070',
    opacity: 1,
    fontSize: '13px',
    paddingRight: 25,// 32
    transition: 'height 0.3s ease-in'
  },
  response: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#04363D',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center space-between',
    alignItems: 'center',
    width: '100%',
    transition: "all linear 1s",
    position: 'relative',
    // marginTop: 16,
    // marginBottom: 6,
    "& [class*='MuiInputBase-inputMultiline']": {
      fontSize: 13,
      height: 70,
      color: '#707070'
    }
  },
  viewAllBtnDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  viewAllResponse: {
    margin: 0,
    padding: 0,
    cursor: 'pointer',
    textDecoration: 'underline',
    letterSpacing: '0px',
    color: '#398AF5',
    fontSize: theme.spacing(2),
  },
  addComment: {
    cursor: 'pointer',
    textDecoration: 'underline',
    letterSpacing: '0px',
    color: '#6D7278',
    fontSize: '13px',
    width: 140,
    textAlign: 'left',
    margin: '0px auto',
    '& span': {
      marginBottom: 6,
    }
  },
  viewAllResponseEdit: {
    cursor: 'pointer',
    // textAlign: 'left',
    textDecoration: 'underline',
    letterSpacing: '0px',
    color: '#6D7278',
    fontSize: '13px',
    lineHeight: '16px',
    display: 'block',
    textAlign: 'right',
    padding: '10px'
  },
  replySellerBtn: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: ' 1px solid #398AF5',
    borderRadius: '4px',
    letterSpacing: '0px',
    color: '#398AF5',
    padding: '3px 10px',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
  },
  editIcon: {
    color: "#999999",
    marginLeft: 17,
    marginRight: 15,
    cursor: 'pointer',
    position: 'absolute',
    right: '-20px',
    top: '10px',
  },
  editSellerIcon: {
    color: "#999999",
    cursor: 'pointer'
  },
  refreshIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 100,
    top: 13,
    color: '#999999',
    transitionDuration: '5s',
    transitionProperty: 'transform',
    zIndex: 1000
  },
  addTaskIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 60,
    top: 13,
    color: '#999999',
    transitionDuration: '5s',
    transitionProperty: 'transform',
    zIndex: 1000
  },
  addRiskIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
    top: 13,
    color: '#999999',
    transitionDuration: '5s',
    transitionProperty: 'transform',
    zIndex: 1000
  },
  underprogress: {
    transform: 'rotate(360deg)',
    '-webkit-transform': 'rotate(360deg) infinite linear',
  },
  comunityMsg: {
    padding: '6px',
    textAlign: 'center',
    letterSpacing: '0px',
    color: '#707070',
    marginTop: 0,
    fontSize: 14
  },
  nextPrevbtns: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  arrowIcons: {
    '&:hover': {
      background: '#f1f1f1',
      cursor: 'pointer',
      padding: '2px',
      borderRadius: '4px'
    }
  },
  notAuthorized: {
    textAlign: 'center'
  },
  pricingLink: {
    '& > a': {
      color: '#398AF5 !important',
      cursor: 'pointer',
      fontSize: '1em',
      letterSpacing: '0px',
      textDecoration: 'underline',
    }
  },
  note: {
    padding: 5,
    width: 'max-content',
    cursor: 'pointer',
    borderRadius: 3,
    color: 'rgb(112, 112, 112)',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#f1f1f1'
    }
  },
  pIBtn: {
    color: '#4b86ff',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#4b86ff',
    }
  },
  submitBtnDiv: {
    textAlign: 'right',
    marginTop: 10
  },
  submitBtn: {

  },
  tickMark: {
    color: "#4b86f8",
    marginLeft: 10,
    border: "1px solid #4b86f8",
    // marginRight: 10,
    padding: 6,
    minWidth: "50px !important",
    "&:hover": {
      color: "#4b86f8",
    },
  },
});

const communityDot = {
  width: 5,
  height: 5,
  background: 'red',
  position: 'absolute',
  left: '30px',
  // top: 14,
  // left: 144,
  borderRadius: '50%'
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

class Response extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAnswers: [1, 2],
      selectedTab: 0,
      editNote: false,
      editSellerNote: false,
      isDotHidden: false,
      isShowResponces: false,
      isShowSellerLoader: true,
      note: '',
      isShowLoader: true,
      isShowReqMeet: true,
      fetchResponseCounter: 0,
      isUpdateOnce: true,
      isUpdateOnceState: true,
      isCommentRequired: false,
      localResponse: '',
      localMsgResponse: null,
      isUpdateLocalResOnce: true,
      isNewChangesFound: false,
      goToRequirementId: null,
      addingCollaborator: false,
      removingCollaborator: false,
      removableCollaboratorIds: [],
      removableCollaboratorNames: [],
    }

    this.answerUpdate = this.answerUpdate.bind(this);
    this.updateEditNote = this.updateEditNote.bind(this);
    this.updateCommentRequired = this.updateCommentRequired.bind(this);
    this.submitReqResponse = this.submitReqResponse.bind(this);
    this.updateLocalResponseOnce = this.updateLocalResponseOnce.bind(this);
    this.saveAndGoToRequirement = this.saveAndGoToRequirement.bind(this);
    this.saveUnsavedChanges = this.saveUnsavedChanges.bind(this);
  }

  componentDidMount() {
    this.checkTab()
    this.props.fetchColloborator(this.props.match.params.evaluation_id);
  }

  componentDidUpdate(prevProps, prevStates) {
    if (this.props.selectedRequirementId !== prevProps.selectedRequirementId && this.props.selectedRequirementId !== null && this.props.selectedProductId !== null) {
      this.setState({
        // selectedTab: 0,
        isShowResponces: false,
        isShowLoader: true,
        isUpdateOnce: true,
        isUpdateOnceState: true,
        isCommentRequired: false,
        isUpdateLocalResOnce: true,
        localResponse: '',
        localMsgResponse: null,
        editNote: false,
        note: '',
        goToRequirementId: null,
        isNewChangesFound: false
      }, () => {
        this.props.setIsNewChangesFound(false)
      })
      this.onBlurInput(`requirement_${this.props.selectedProductId}`)
    }

    if (this.props.threadFetchProgress === false && prevProps.threadFetchProgress === true) {
      this.setState({
        isShowSellerLoader: false
      })
    }

    if (!this.props.addResponseProgress && prevProps.addResponseProgress) {
      if (this.props.errorResponseAdd === null) {

        this.props.showSnackBar("Response Updated Successfully.", "success", 3000)
        this.setState({ removableCollaboratorIds: [] });
        let note = this.props.successResponseAdd?.Note;
        let collaboratorIds = [];
        if (Validator.isJson(note) && Array.isArray(JSON.parse(note))) {
          collaboratorIds = getMentionedUsersIds(JSON.parse(note));
        }

        let prevResponse = this.props.requirementUserResponses || []
        let prevNote = prevResponse[0]?.Note || "[]";
        let prevCollaboratorIds = [];
        if (Validator.isJson(prevNote) && Array.isArray(JSON.parse(prevNote))) {
          prevCollaboratorIds = getMentionedUsersIds(JSON.parse(prevNote));
        }

        let UniqeCollaboratorIds = _.uniq(collaboratorIds);

        let existingCollaborators = this.props.requirementResponses?.colloborator || [];

        if (existingCollaborators.length > 0) {
          let existingIds = existingCollaborators.map(user => user.ID);
          let removableCollaboratorIds = prevCollaboratorIds.filter(id => (!UniqeCollaboratorIds.includes(id) && existingIds.includes(id)));

          if (removableCollaboratorIds.length > 0) {
            let removableCollaboratorNames = existingCollaborators.filter(user => removableCollaboratorIds.includes(user.ID)).map(O => O.Name).join(', ');

            this.setState({
              removableCollaboratorNames: removableCollaboratorNames,
              removableCollaboratorIds: removableCollaboratorIds,
              removingCollaborator: true,
            }, () => {
              this.props.showConfirmCallbackDialog();
            })
          }
          UniqeCollaboratorIds = UniqeCollaboratorIds.filter(id => !existingIds.includes(id))
        }

        if (UniqeCollaboratorIds.length > 0) {
          this.setState({
            addingCollaborator: true,
          }, () => {
            UniqeCollaboratorIds.forEach(userId => {
              this.props.addRequirementColloborator(
                this.props.match.params.evaluation_id,
                this.props.requirementResponses.criteriaId,
                this.props.requirementResponses.id,
                userId)
            })
          })
        }

        console.log(this.props.isMoveToNextReq, 'this.props.isMoveToNextReq')
        if (this.props.isMoveToNextReq === true) {
          let newValue = this.state.fetchResponseCounter + 1
          this.setState({
            fetchResponseCounter: newValue,
          }, () => {
            this.props.setMoveToNextFlag(false);
            var nextPrevData = getNextAndPreviousIds(this.props.requirementTree, this.props.selectedRequirementId);
            var nextReqId = nextPrevData?.nextReqId;
            if (nextReqId !== null) {
              this.props.setSelectedRequirement(nextReqId);
            } else if (this.props.goToRequirement !== null) {
              this.props.setSelectedRequirement(this.props.goToRequirement);
            }
          })
        } else if (this.state.goToRequirementId) {
          this.props.setSelectedRequirement(this.state.goToRequirementId);
        } else if (this.props.goToRequirement !== null) {
          this.props.setSelectedRequirement(this.props.goToRequirement);
        }
        this.setState({
          goToRequirementId: null,
          isNewChangesFound: false,
          localResponse: '',
          localMsgResponse: null,
        }, () => {
          this.props.setIsNewChangesFound(false);
        })
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if ((!this.props.criteriaUserRoleInprogress && prevProps.criteriaUserRoleInprogress)) {
      this.props.fetchRequirementResponse(this.props.match.params.evaluation_id, this.props.selectedRequirementId);
      this.setState({
        addingCollaborator: false,
      })
    }

    if (this.state.removingCollaborator && (!this.props.deleteRequirementCollaboratorInprogress && prevProps.deleteRequirementCollaboratorInprogress)) {
      this.props.fetchRequirementResponse(this.props.match.params.evaluation_id, this.props.selectedRequirementId);
      this.setState({
        removingCollaborator: false,
      })
    }

    if (this.props.fetchRequirementResponseProgress === false && prevProps.fetchRequirementResponseProgress === true) {
      let newValue = this.state.fetchResponseCounter + 1
      this.setState({
        fetchResponseCounter: newValue,
        editNote: false
      }, () => {
      })
    }

    if (this.props.fetchReqResponseUserResProgress === false && prevProps.fetchReqResponseUserResProgress === true) {
      var isShowReqMeet = true;
      let newValue = this.state.fetchResponseCounter + 1

      this.setState({
        isShowReqMeet: false,
        fetchResponseCounter: newValue
      })
      var response = (this.props.requirementUserResponses === null || this.props.requirementUserResponses.length <= 0) ? null : this.props.requirementUserResponses[0];
      if (response !== null && response?.Response !== null && response?.Response === -1) {
        isShowReqMeet = false;
      }

      this.setState({
        isShowReqMeet: isShowReqMeet
      })

      let interval = setInterval(() => {
        var requirement = this.props.requirementResponses;
        if (requirement !== undefined && requirement !== null && requirement !== '') {
          this.updateEditFlag();
          clearInterval(interval);
        }
      }, 500);

    }

    if (this.props.selectedProductId !== prevProps.selectedProductId) {
      this.setState({
        isShowLoader: true,
        isNewChangesFound: false
      }, () => {
        this.props.setIsNewChangesFound(false)
      })
    }

    if (this.props.fetchReqTreeProgress === false && prevProps.fetchReqTreeProgress === true) {
      if (this.props.requirementTree === null || this.props.requirementTree.length <= 0) {
        setTimeout(() => {
          this.setState({
            isShowLoader: false
          })
        }, 1000);
      }
    }

  }

  checkTab() {
    const { isHideAskSeller, isHideCommunityResponse, isHideYourResponse } = this.props;
    let selectedTab = 0;
    if (!isHideYourResponse) {
      selectedTab = 0;
      this.props.history.push({ hash: 'your-response' })
    } else if (!isHideCommunityResponse) {
      selectedTab = 1;
      this.props.history.push({ hash: 'community' })
    } else if (!isHideAskSeller) {
      selectedTab = 2;
      this.props.history.push({ hash: 'seller' })
    }
    this.setState({
      selectedTab: selectedTab
    }, () => {
      this.props.setResponseTab()
    })
  }

  updateEditFlag() {
    var requirement = this.props.requirementResponses;
    var response = (this.props.requirementUserResponses === null || this.props.requirementUserResponses.length <= 0) ? null : this.props.requirementUserResponses[0];
    // console.log(response,'response-call')
    // console.log(requirement,'requirement-call')
    if (requirement !== undefined && requirement !== null && requirement !== '' &&
      (requirement.answer_type === 1 || requirement.answer_type === 7) &&
      (response === null || response === '')) {
      this.setState({
        editNote: true,
        note: '',
        isShowLoader: false,
      })
    } else {
      this.setState({
        isShowLoader: false,
      })
    }
  }

  answerUpdate(event) {
    var selectedAnswers = this.state.selectedAnswers;
    var newValue = Number(event.target.value);
    if (selectedAnswers.indexOf(newValue) === -1) {
      selectedAnswers.push(newValue);
      this.setState({
        selectedAnswers: selectedAnswers
      })
    } else {
      selectedAnswers.splice(selectedAnswers.indexOf(newValue), 1);
      this.setState({
        selectedAnswers: selectedAnswers
      })
    }
  }

  changeNote = (e, response, className) => {
    //this.changeCardPosition(e);
    if (!this.state.editNote) {
      this.setState({
        editNote: true,
        note: ((response !== null && response.Note) || '')
      }, () => {
        try {
          const editHight = document.querySelectorAll(`.${className}`);
          if (editHight && editHight.length) {
            for (var i = 0; i < editHight.length; i++) {
              editHight[i].classList.add('rootRequirementHight');
            }
          }
        } catch (e) {

        }
      });
    }
  }

  onBlurInput = (className) => {
    this.setState({
      editNote: false
    }, () => {
      try {
        const responseNote = document.querySelectorAll(`.response_note .${className}`)
        const rootRequirementHight = document.querySelectorAll(`.${className}`);
        if (responseNote.length === 0 && rootRequirementHight && rootRequirementHight.length) {
          for (var i = 0; i < rootRequirementHight.length; i++) {
            rootRequirementHight[i].classList.remove('rootRequirementHight');
          }
        }
      } catch (e) {

      }
    });
  }

  getPricingLink(requirement) {
    let link = ''
    // console.log(this.props.productRequirement, 'productRequirement')
    if (Object.keys(this.props.productRequirement).length > 0 && this.props.productRequirement[this.props.selectedProductId] !== undefined) {
      Object.keys(this.props.productRequirement[this.props.selectedProductId]).forEach(id => {
        if (
          this.props.productRequirement[this.props.selectedProductId][id]?.data &&
          this.props.productRequirement[this.props.selectedProductId][id]?.data?.RequirementName &&
          this.props.productRequirement[this.props.selectedProductId][id]?.data?.RequirementName.trim().toLowerCase() === requirement?.name.trim().toLowerCase()
        ) {
          link = ReactHtmlParser(this.props.productRequirement[this.props.selectedProductId][id]?.data?.PricingLink)
        }
      })
    }

    return link;
  }

  getMaxLimit = (req) => {
    return req.character_limit || 100
  }

  getNote = (req, note) => {
    let rt = note
    if (Validator.isJson(note)) {
      let newNote = JSON.parse(note)
      newNote = newNote.map(n => Node.string(n)).join(' ')
      rt = newNote
      try {
        const limit = parseInt(req.character_limit || 0)
        if (limit > 0) {
          rt = rt.substring(0, limit)
        }
      } catch (e) { }
    }

    return rt
  }

  updateEditNote() {
    if (this.state.isUpdateOnce) {
      this.setState({
        isUpdateOnce: false,
        editNote: true,
      })
    }
  }

  updateCommentRequired() {
    if (this.state.isUpdateOnceState) {
      this.setState({
        isUpdateOnceState: false,
        isCommentRequired: true,
      })
    }
  }

  updateLocalResponseOnce(value) {
    if (this.state.isUpdateLocalResOnce && value !== undefined && value !== null) {
      this.setState({
        isUpdateLocalResOnce: false,
        localResponse: value,
      })
    }
  }

  submitReqResponse() {
    var response = (this.props.requirementUserResponses === null || this.props.requirementUserResponses.length <= 0) ? null : this.props.requirementUserResponses[0];
    var requirement = this.props.requirementResponses;

    let answerValue = null;

    if (this.state.localResponse !== undefined && this.state.localResponse !== null && this.state.localResponse !== '') {
      answerValue = this.state.localResponse;
    } else if (response !== undefined && response !== null && response !== '' && response.UserOptions !== undefined && response.UserOptions !== null) {
      answerValue = response?.UserOptions[0]?.Answer
    }

    if (answerValue === null) {
      this.props.showSnackBar("Please select score.", "error", 3000)
      return
    }

    var body = '';
    if (this.state.editNote) {
      body = this.state.note;
      var bodyText = document.querySelectorAll("#responseBoxText .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
      if (String(body).trim().length === 0 || String(bodyText).trim().length === 0 || String(bodyText).indexOf('Add comment here') !== -1) {
        this.props.showSnackBar("Please enter your comment.", "error", 3000)
        return
      }
    } else {
      body = response === null ? null : response.Note
    }

    let parameters = {};
    parameters = {
      note: body,
      value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
      response_options: {
        answer: String(answerValue)
      }
    };

    this.props.addRequirementResponse(
      this.props.match.params.evaluation_id,
      this.props.selectedProductId,
      requirement.id,
      parameters
    );

    // console.log(this.state.localResponse)
    // console.log(this.state.note)
  }

  saveAndGoToRequirement(reqId) {
    var me = this;
    this.setState({ goToRequirementId: reqId }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack = () => {
    if (this.state.goToRequirementId) {
      this.props.hideMessageDialog();
      this.saveUnsavedChanges();
    } else {
      this.props.hideMessageDialog();
      this.saveUnsavedChanges();
    }
  };

  closeCallBack = () => {
    if (this.state.goToRequirementId) {
      this.props.hideMessageDialog();
      this.props.setSelectedRequirement(this.state.goToRequirementId);
    } else if (this.props.goToRequirement !== null) {
      this.props.hideMessageDialog();
      this.props.setSelectedRequirement(this.props.goToRequirement);
    } else {
      this.props.hideMessageDialog();
    }
  }

  closeMessageDialog = () => {
    var me = this;
    this.setState({ goToRequirementId: null }, () => {
      me.props.hideMessageDialog();
    })
  }

  removeCollabCallBack = () => {
    this.props.hideConfirmCallbackDialog();
    this.state.removableCollaboratorIds.forEach(userId => {
      let data = {}
      data.evId = this.props.match.params.evaluation_id;
      data.criteriaId = this.props.requirementResponses.criteriaId;
      data.requirementId = this.props.requirementResponses.id;
      data.collaboratorId = userId;
      this.props.removeRequirementCollaborator(data);
    });
    this.setState({
      removableCollaboratorIds: [],
      removableCollaboratorName: []
    })
  };

  removeCollabCloseCallBack = () => {
    this.props.hideConfirmCallbackDialog();
    this.setState({
      removableCollaboratorIds: [],
      removableCollaboratorName: []
    })
  }

  removeCollabCloseMessageDialog = () => {
    this.setState({
      removableCollaboratorIds: [],
      removableCollaboratorName: []
    })
    this.props.hideConfirmCallbackDialog();
  }

  saveUnsavedChanges() {
    var response = (this.props.requirementUserResponses === null || this.props.requirementUserResponses.length <= 0) ? null : this.props.requirementUserResponses[0];
    var requirement = this.props.requirementResponses;
    const { localMsgResponse, localResponse } = this.state;
    // console.log(response, 'response')
    // console.log(requirement, 'requirement')
    // console.log(localMsgResponse, 'localMsgResponse')
    // console.log(localResponse, 'localResponse')
    let parameters = {};
    let isError = false;
    let errorMsg = '';
    if ([1, 7].includes(requirement.answer_type)) {
      parameters = {
        response_options: {
          answer: localResponse && localResponse !== '' ? localResponse : response && response?.UserOptions && response?.UserOptions.length > 0 ? response?.UserOptions[0]?.Answer : ''
        }
      };
    } else if ([4, 6, 11].includes(requirement.answer_type)) {
      parameters = {
        note: localMsgResponse,
        value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
        response_options: {
          answer: response && response.UserOptions && response.UserOptions.length > 0 ? (response?.UserOptions[0]?.Answer || '') : null
        }
      };
    } else if ([3].includes(requirement.answer_type)) {
      parameters = {
        note: localMsgResponse && localMsgResponse !== null && localMsgResponse.length > 0 ? localMsgResponse : response === null ? null : response.Note,
        value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
        response_options: {
          option_ids: response === null || response.UserOptions === null ? null : response.UserOptions.map((i) => {
            return i.OptionID
          }),
        }
      };
    } else if ([2, 14, 15].includes(requirement.answer_type)) {

      let answerText = '';
      if (response !== null && response?.UserOptions !== undefined && response?.UserOptions !== null && response?.UserOptions !== '') {
        let isOther = response?.UserOptions.filter(o => o.Answer.indexOf('Other-') !== -1)[0];
        if (isOther !== undefined && isOther !== null && isOther !== '') {
          answerText = isOther.Answer;
        }
      }

      parameters = {
        note: localMsgResponse && localMsgResponse !== null && localMsgResponse.length > 0 ? localMsgResponse : response === null ? null : response.Note,
        value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
        response_options: {
          option_ids: localResponse !== null && localResponse !== '' ? localResponse.map(o => o.OptionID) : (response == null || response.UserOptions == null ? [] : response.UserOptions.map((i) => {
            return i.OptionID
          })),
        }
      };

      if (answerText !== '') {
        parameters.response_options['answer'] = answerText;
      }

    } else if ([5].includes(requirement.answer_type)) {
      parameters = {
        note: localMsgResponse !== null && localMsgResponse.length > 0 ? localMsgResponse : response === null ? null : response.Note,
        value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
        response_options: {
          answer: String(localResponse)
        }
      };
      if (this.state.isCommentRequired && requirement.answer_type === 5) {
        if (localResponse === undefined || localResponse === null || localResponse === '') {
          this.props.showSnackBar("Please select score.", "error", 3000)
          return
        }
        var body = '';
        if (this.state.editNote) {
          body = this.state.note;
          var bodyText = document.querySelectorAll("#responseBoxText .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
          if (String(body).trim().length === 0 || String(bodyText).trim().length === 0 || String(bodyText).indexOf('Add comment here') !== -1) {
            isError = true;
            errorMsg = 'Please enter your comment.'
          }
        }
      }
    } else if ([8, 9, 10, 12].includes(requirement.answer_type)) {
      let ansValue = localResponse !== null && localResponse !== '' ? localResponse : response !== undefined && response !== null && response.UserOptions !== null ? response?.UserOptions[0]?.Answer : ''
      if (requirement.answer_type === 8 && Validator.validateEmail(ansValue) === false) {
        isError = true;
        errorMsg = 'Please enter a valid email'
      }
      if (requirement.answer_type === 10 && Validator.validateURL(ansValue) === false) {
        isError = true;
        errorMsg = 'Please enter a valid URL'
      }
      parameters = {
        note: localMsgResponse !== null && localMsgResponse.length > 0 ? localMsgResponse : response === null ? null : response.Note,
        value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
        response_options: {
          answer: ansValue
        }
      };
    } else if ([13].includes(requirement.answer_type)) {
      let ansValue = localResponse !== null && localResponse !== '' ? localResponse : response !== undefined && response !== null && response.UserOptions !== null ? response?.UserOptions[0]?.Answer : ''
      parameters = {
        note: localMsgResponse !== null && localMsgResponse.length > 0 ? localMsgResponse : response === null ? null : response.Note,
        value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
        response_options: {
          answer: JSON.stringify(ansValue)
        }
      };
    }

    if (isError) {
      this.props.showSnackBar(errorMsg, "error", 3000)
      return;
    }
    this.props.addRequirementResponse(
      this.props.match.params.evaluation_id,
      this.props.selectedProductId,
      requirement.id,
      parameters
    );
  }

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  };

  addNewRisk() {
    var products = this.props.evaluation !== undefined ? this.props.evaluation.Products : [];
    var product = products.filter(o => o.ID === this.props.selectedProductId)[0];
    let riskRegisterFormID = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "RISK_REGISTER_FORM");
    window.sessionStorage.setItem("riskForProject", JSON.stringify(product));
    if (riskRegisterFormID && riskRegisterFormID.length > 0) {
      this.props.history.push(`/app/products/risks/risk/0/${riskRegisterFormID}?isNewRisk=true&fE=true`)
    } else {
      this.props.history.push(`/app/products/risks/risk/0/0?isNewRisk=true&fE=true`)
    }
  }

  render() {
    const classes = this.props.classes;
    const { requirementTree, fetchRequirementResponseProgress, fetchReqTreeProgress, criteriaUserRoleInprogress, deleteRequirementCollaboratorInprogress } = this.props;
    const { isNewChangesFound } = this.state;
    var requirement = this.props.requirementResponses;

    let shouldLoading = fetchRequirementResponseProgress || deleteRequirementCollaboratorInprogress || criteriaUserRoleInprogress;
    // console.log(this.state.isShowLoader,'this.state.isShowLoader')
    if (this.state.isShowLoader || this.props.fetchReqResponseUserResProgress) {
      return <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    }

    if (fetchReqTreeProgress === false && (requirementTree === undefined || requirementTree === null || requirementTree.length <= 0)) {
      return <div className={classes.notAuthorized}>
        No requirement assigned
      </div>
    }

    if (requirement === undefined || requirement === null || requirement === '') {
      return <div className={classes.notAuthorized}>
        No question assigned
      </div>
    }

    var response = (this.props.requirementUserResponses === null || this.props.requirementUserResponses.length <= 0) ? null : this.props.requirementUserResponses[0];

    // console.log('--------------------------------------')
    // console.log(this.props.selectedRequirementId,'selectedRequirementId')
    // console.log(this.props.requirementUserResponses,'requirementUserResponses')
    // console.log(response,'response')

    var products = this.props.evaluation !== undefined ? this.props.evaluation.Products : [];
    var productMap = {};
    products.forEach(function (item) {
      productMap[item.ID] = item;
    });

    var product = productMap[this.props.selectedProductId];
    var criteriaId = requirement?.criteriaId;

    var criterias = (this.props.criterias !== undefined && this.props.criterias !== null) ? this.props.criterias : [];
    var criteriasMap = {};
    criterias.forEach(function (item) {
      criteriasMap[item.id] = item;
    });

    const criteria = criteriasMap[criteriaId];

    var editable = true;
    if (this.props.editable !== undefined) {
      editable = this.props.editable
    }

    let communityMessage = 'No response yet', matchFeature = '';
    let show_community_message = true;
    let backendMessage = "No response yet";
    let isDotHidden = true;

    if (criteria?.name === "Product Features") {
      show_community_message = true;
      if (requirement.name && this.props.evaluationProductsList.length) {
        this.props.evaluationProductsList.forEach((productDetail) => {
          if (productDetail.ID === this.props.selectedProductId) {
            matchFeature = productDetail.Features.find((feature) => {
              return feature.Name.toLowerCase() === requirement.name.toLowerCase();
            });
          }

          if (matchFeature) {
            communityMessage = `${product?.Name} supports ${matchFeature?.Name}`;
            isDotHidden = false;
          }
        });
      }
    } else {
      show_community_message = false;
      if (this.props.productRequirement[this.props.selectedProductId] !== undefined && this.props.productRequirement[this.props.selectedProductId][requirement?.ref_requirement_id] !== undefined && this.props.productRequirement[this.props.selectedProductId][requirement?.ref_requirement_id]['data'] !== null) {
        if (this.props.productRequirement[this.props.selectedProductId][requirement.ref_requirement_id]['fetchProg']) {
          backendMessage = "Loading..."
        } else {
          isDotHidden = false;
          backendMessage = this.props.productRequirement[this.props.selectedProductId][requirement.ref_requirement_id]['data']['Description']
        }
      }
    }


    let communityResponse = {};
    let communityTabHeader = 'Community';

    // const { sellerRequirement, sellerResponse } = this.props;
    var nextPrevData = getNextAndPreviousIds(this.props.requirementTree, this.props.selectedRequirementId);
    var nextReqId = nextPrevData.nextReqId;
    var previousReqId = nextPrevData.previousReqId;
    // console.log(nextReqId,'NewRequirement ID 2')
    var scoring_mehanism = this.props.scoring_mechanism?.id === 'Numerical' ? 'Numerical' : 'Textual';

    let tab = 0
    if (this.props?.location && this.props?.location?.hash) {
      const hashVal = this.props?.location?.hash
      if (hashVal === "#community") tab = 1
      if (hashVal === "#seller") tab = 2
    }

    var maxScore = this.props.evaluationInfoData !== undefined && this.props.evaluationInfoData !== null && this.props.evaluationInfoData?.MaxScore && this.props.evaluationInfoData?.MaxScore !== null && this.props.evaluationInfoData?.MaxScore !== '' ? this.props.evaluationInfoData.MaxScore : 5;

    // console.log(requirement, 'requirementDataaaa')
    // console.log(response, 'responseData')
    // console.log(response?.UserOptions,'ReqResponseNewwwwwwwwwwwwwwwwwwwwww')
    // console.log(requirement, 'Sunilllllllllllllllll')
    // console.log(response, 'response')

    if (this.props.commentRequired && requirement !== undefined && requirement !== null && requirement.answer_type === 5) {
      this.updateCommentRequired()
    }

    if (this.props.commentRequired && requirement !== undefined && requirement !== null && requirement.answer_type === 5 && (response === undefined || response === null || response?.Note === '')) {
      this.updateEditNote()
    }

    let resValue = ''
    if (this.props.commentRequired && requirement !== undefined && requirement !== null && requirement.answer_type === 5 && response !== undefined && response !== null && response.UserOptions !== null) {
      this.updateLocalResponseOnce(response?.UserOptions[0]?.Answer)
      resValue = response?.UserOptions[0]?.Answer
    } else {
      resValue = this.state.isCommentRequired ? this.state.localResponse : response !== undefined && response !== null && response.UserOptions !== null ? response?.UserOptions[0]?.Answer : '';
    }

    // console.log(resValue,'resValue111')
    // console.log(this.state.localResponse,'thislocalResponse')

    let selectedOptions = response !== undefined && response !== null && response.UserOptions !== null ? response?.UserOptions : [];

    if ([1, 7, 5, 8, 9, 10, 12].includes(requirement?.answer_type) && this.state.localResponse !== '') {
      resValue = String(this.state.localResponse);
    }

    if ([13].includes(requirement?.answer_type) && this.state.localResponse !== '') {
      resValue = JSON.stringify(this.state.localResponse);
    }

    if (requirement?.answer_type === 2 && this.state.localResponse !== '') {
      selectedOptions = this.state.localResponse;
    }

    let messageData = {
      head: requirement?.name,
      subHead: "You have some unsaved changes, do you want to save them before you continue?",
      firstBtn: "Discard",
      secondBtn: "Save",
    };

    let removeCollabMessageData = {
      head: requirement?.name,
      subHead: `Do you want to remove ${this.state.removableCollaboratorNames} from collaborators as well?`,
      firstBtn: "NO",
      secondBtn: "Yes",
    };

    // console.log(this.state.selectedTab, 'selectedTab')
    return (
      <div>
        <Box component="span" display="block" className={classes.informationBox}>
          {shouldLoading && <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>}
          {!shouldLoading && <div>
            {requirement != null && <Typography className={classes.infoReqText}>
              {requirement?.name}
            </Typography>}

            {requirement != null && <Typography className={classes.infoText}>
              <SlateInputFieldV2
                readOnly={true}
                isToolBar={false}
                placeholder=""
                style={{
                  margin: 0,
                }}
                as={SlateInputField}
                initValue={requirement.description}
                formControlStyle={{ border: 'none', overflow: 'hidden' }}
                textContainerStyle={{ minHeight: '100px !important', margin: "0px", background: "hsl(196deg 72% 93%)", color: "#707070", fontSize: 16 }} />
            </Typography>}

          </div>}
        </Box>

        <div className={classes.collUsers}>
          {!this.props.evaluateView && <>  <label>{"Collaborators"}</label>
            <UserGroup
              position={'Requirement'}
              criteriaId={requirement?.criteriaId}
              evaluationId={this.props.match.params.evaluation_id}
              requirement_info={{
                criteriaId: requirement?.criteriaId,
                requirementId: requirement?.id,
                evaluationId: this.props.match.params.evaluation_id
              }}
              editable={editable}
              searchTitle={"Approver"}
              selectedUser={requirement?.colloborator}
              onAdd={(user) => {
                this.props.addRequirementColloborator(
                  this.props.match.params.evaluation_id,
                  requirement.criteriaId,
                  requirement.id,
                  user.ID)
              }} users={requirement?.colloborator} criterias={this.props.criterias} />
          </>}
        </div>
        <div className={classes.respoRoot}>
          <AppBar position="static" color="default" className={classes.answerBox}>

            {this.props.product?.Type === "PRODUCT" && <TooltipShow className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Add Task</span>} arrow>
              <PlaylistAddIcon
                className={classnames(classes.addTaskIcon)}
                onClick={() => {
                  this.props.showAddUpdateTaskDialog({
                    product: product,
                    productId: product.ID,
                    requirement: requirement,
                    from: 'EVALUATION'
                  }, true)
                }}
              />
            </TooltipShow>}

            {this.props.product?.Type === "PRODUCT" && this.props.userRole === "OrgAdmin" && <TooltipShow className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Add Risk</span>} arrow>
              <SecurityIcon
                className={classnames(classes.addRiskIcon)}
                onClick={() => {
                  this.addNewRisk();
                }}
              />
            </TooltipShow>}


            {this.state.selectedTab === 2 && <CachedIcon className={classnames(classes.refreshIcon, this.props.threadFetchProgress === true ? classes.underprogress : null)} onClick={() => {
              this.props.fetchAskSellerThread(product.VendorUUID, requirement.id);
              this.props.fetchRequirementUserResponse(this.props.match.params.evaluation_id, this.props.selectedProductId, this.props.selectedRequirementId);
            }} />
            }
            <Tabs
              value={tab}
              onChange={(evt, newValue) => {
                if (this.state.selectedTab === newValue) {
                  return;
                }
                this.setState({
                  selectedTab: newValue
                });
                this.props.setResponseTab()
              }}
              indicatorColor="primary"
              textColor="primary"
            >
              {this.props.isHideYourResponse === false && <Tab value={0} label="Your Response" {...a11yProps(0)} onClick={() => this.props.history.push({ hash: 'your-response' })} />}
              {this.props.isHideCommunityResponse === false && <Tab
                value={1}
                label={
                  <span>
                    <span style={isDotHidden ? {} : communityDot}></span>
                    {communityTabHeader}
                  </span>
                }
                {...a11yProps(1)}
                onClick={() => this.props.history.push({ hash: 'community' })}
              />}
              {this.props.isHideAskSeller === false && <Tab value={2} label="Vendor" {...a11yProps(2)} onClick={() => this.props.history.push({ hash: 'seller' })} />}
            </Tabs>
          </AppBar>
          <TabPanel className={classes.tabPanelRes} value={tab} index={0}>
            {shouldLoading && <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>}
            {!shouldLoading && <div>
              {this.props.requirementResponses !== null && <div>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].includes(requirement.answer_type) && <RequirementReply
                  additional_data={requirement?.options}
                  options={requirement?.options}
                  type={requirement?.answer_type}
                  title={requirement?.name}
                  description={requirement?.description}
                  isRequired={requirement?.is_mandatory}
                  key={requirement.id + '_sectionrequirement'}
                  index={requirement.id}
                  value={resValue}
                  selectedOptions={selectedOptions}
                  evaluationId={this.props.match.params.evaluation_id}
                  orgId={this.props.OrgID}
                  workflowId={this.props.match.params.workflow_id}
                  isFormEditable={true}
                  itemId={requirement.id}
                  productId={product.ID}
                  sectionId={requirement.id}
                  maxScore={maxScore}
                  item={requirement}
                  error={requirement.error || ''}
                  isShowSaveButton={!this.state.isCommentRequired}
                  isUpdateOnChange={this.state.isCommentRequired}
                  from={'BUYER'}
                  isSummaryPage={false}
                  isOtherOption={requirement?.is_other_option}
                  characterLimit={requirement.character_limit || 100}
                  onChange={(output, isOther = false) => {
                    console.log(output, 'output')
                    if (!this.state.isCommentRequired) {
                      let parameters = {};
                      if ([1, 4, 5, 6, 7, 8, 9, 10, 12, 13].includes(requirement.answer_type)) {
                        parameters = {
                          response_options: {
                            answer: requirement.answer_type === 6 ? JSON.stringify(output) : output
                          }
                        };
                      }

                      if ([11].includes(requirement.answer_type)) {
                        const date = CommonFn.formatResponseDate(output, true)
                        parameters = {
                          response_options: {
                            answer: date
                          }
                        };
                      }


                      if ([2, 3].includes(requirement.answer_type)) {
                        parameters = {
                          response_options: {
                            option_ids: output,
                          }
                        };
                      }

                      if ([14, 15].includes(requirement.answer_type)) {
                        if (isOther) {
                          parameters = {
                            response_options: {
                              answer: output,
                              option_ids: requirement.answer_type === 15 ? (response == null || response.UserOptions == null ? [] : response.UserOptions.map((i) => {
                                return i.OptionID
                              })) : null,
                            }
                          };
                        } else {
                          let answerText = '';
                          if (response === null && response?.UserOptions !== undefined && response?.UserOptions !== null && response?.UserOptions !== '') {
                            let isOther = response?.UserOptions.filter(o => o.Answer.indexOf('Other-') !== -1)[0];
                            if (isOther !== undefined && isOther !== null && isOther !== '') {
                              answerText = isOther.Answer;
                            }
                          }
                          parameters = {
                            response_options: {
                              option_ids: requirement.answer_type === 15 ? output : [output],
                              answer: answerText
                            }
                          };
                        }
                      }

                      if (!this.props.enableScoring && requirement.answer_type !== 15 && !isOther) {
                        this.props.setMoveToNextFlag(true)
                      }

                      if (!this.props.enableScoring) {
                        parameters.value = 10
                      }

                      this.props.addRequirementResponse(
                        this.props.match.params.evaluation_id,
                        product.ID,
                        requirement.id,
                        parameters);

                    } else if (this.state.isCommentRequired) {
                      this.setState({
                        localResponse: output
                      })
                    }
                  }}
                  updateLocalOnChange={(output) => {
                    console.log(output, 'output')
                    this.setState({
                      localResponse: output,
                      isNewChangesFound: true,
                    }, () => {
                      this.props.setIsNewChangesFound(true)
                    })
                  }}
                  showUserAddDialog={(item) => {

                  }}
                  showTeamAddDialog={(data) => {

                  }}
                />}

                {this.state.editNote === false && requirement.answer_type !== 1 && requirement.answer_type !== 7 &&
                  <div style={{ position: 'relative', width: '100%' }}>
                    <div className={classes.rNote} >
                      {response && response.Note && response.Note !== '' ? (
                        <div className={classnames(classes.showNoteSection)}>
                          <SlateInputField
                            readOnly={true}
                            isToolBar={false}
                            placeholder="Add response here"
                            style={{ margin: 0 }}
                            as={SlateInputField}
                            onChangeEvent={(value) => {
                              this.setState({
                                note: value
                              })
                            }}
                            initValue={response.Note}
                            formControlStyle={{ border: 'none' }}
                            textContainerStyle={{ color: "#707070", fontSize: 14 }}
                          />
                        </div>
                      ) : (<>
                        {<div className={classnames(classes.addText, 'response-addText', 'evaluate-add-text')}
                          onClick={(e) =>
                            this.changeNote(e, response, `requirement_${this.props.selectedProductId}`)}>
                          <img className={classes.addTextimage} alt="" src={Messages} />
                          <span>{'Add Comment'}</span>
                        </div>}
                      </>
                      )}
                    </div>
                    {response !== null && response.Note !== null && response.Note !== '' &&
                      <div className={classnames(classes.editIcon, 'evaluate-response-edit')}
                        onClick={(e) => this.changeNote(e, response, `requirement_${this.props.selectedProductId}`)}
                        title="Edit"><Image alt="Edit" src={editIcon} />
                      </div>}
                  </div>
                }

                {this.state.editNote && requirement.answer_type !== 1 && requirement.answer_type !== 7 &&
                  <div id='responseBoxText' className={classnames(classes.noteSection)}>
                    <SlateInputField
                      folderPath={`/evaluation/${this.props.match?.params?.evaluation_id}/product/${product?.ID}/requirement/${requirement?.id}`}
                      placeholder={this.state.isCommentRequired ? "Add comment here" : "Add response here"}
                      style={{ margin: 0 }}
                      as={SlateInputField}
                      enableMention={true}
                      onChangeEvent={(value) => {
                        var body = value;
                        let isNewChangesFound = false;
                        var bodyText = document.querySelectorAll("#responseBoxText .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
                        if (String(body).trim().length > 0 && String(bodyText).trim().length > 0 && (String(bodyText).indexOf('Add response here') === -1) && (String(bodyText).indexOf('Add comment here') === -1)) {
                          isNewChangesFound = true;
                        }
                        this.setState({
                          note: value,
                          localMsgResponse: value,
                          isNewChangesFound: isNewChangesFound
                        }, () => {
                          this.props.setIsNewChangesFound(isNewChangesFound)
                        })
                      }}
                      maxLength={10}
                      initValue={this.state.note}
                      saveData={() => {
                        var body = this.state.note;
                        var bodyText = document.querySelectorAll("#responseBoxText .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
                        if (String(body).trim().length === 0 || String(bodyText).trim().length === 0 || String(bodyText).indexOf('Add response here') !== -1) {
                          this.props.showSnackBar("Please enter your response.", "error", 3000)
                          return
                        }
                        let parameters = {
                          note: this.state.note
                        }
                        if (!this.props.enableScoring) {
                          parameters.value = 10;
                          this.props.setMoveToNextFlag(true)
                        }

                        this.props.addRequirementResponse(
                          this.props.match.params.evaluation_id,
                          product.ID,
                          requirement.id,
                          parameters);

                        this.onBlurInput(`requirement_${this.props.selectedProductId}`)
                      }}
                      cancelSave={() => {
                        this.setState({
                          editNote: false,
                          isNewChangesFound: false
                        }, () => {
                          this.props.setIsNewChangesFound(false)
                        })
                        this.onBlurInput(`requirement_${this.props.selectedProductId}`)
                      }}
                      actionButtonsRequired={false}
                      formControlStyle={{}}
                      textContainerStyle={{ color: "#707070", fontSize: 14 }}
                    />

                    <ActionButtom
                      classes={classes}
                      onCancel={() => {
                        this.setState({
                          editNote: false,
                          isNewChangesFound: false
                        }, () => {
                          this.props.setIsNewChangesFound(false)
                        })
                        this.onBlurInput(`requirement_${this.props.selectedProductId}`)
                      }}
                      onSave={() => {
                        var body = this.state.note;
                        var bodyText = document.querySelectorAll("#responseBoxText .editor-editableField")[0].innerText.replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "")
                        if (String(body).trim().length === 0 || String(bodyText).trim().length === 0 || String(bodyText).indexOf('Add response here') !== -1) {
                          this.props.showSnackBar("Please enter your response.", "error", 3000)
                          return
                        }
                        let addOther = response?.UserOptions.filter((item) => item.OptionID === 0).sort((a, b) => b.ID - a.ID);
                        let parameters = {}
                        if (requirement.answer_type === 5 || requirement.answer_type === 6) {
                          parameters = {
                            note: this.state.note,
                            value: response == null ? null : response.Response,
                            response_options: {
                              answer: (response?.UserOptions[0]?.Answer || '')
                            },
                          };
                        } else if (requirement.answer_type === 4 || requirement.answer_type === 1) {
                          parameters = {
                            note: this.state.note,
                            value: response == null ? null : response.Response,
                            response_options: {
                              answer: requirement.answer_type === 1 ? 'Done' : (response?.UserOptions[0]?.Answer || '')
                            },
                          };
                        } else if ([8, 9, 10, 11, 12, 13].includes(requirement.answer_type)) {
                          parameters = {
                            note: this.state.note,
                            value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
                            response_options: {
                              answer: response === null ? null : response?.UserOptions[0]?.Answer
                            }
                          };
                        } else if ([2, 14, 15].includes(requirement.answer_type)) {
                          let answerText = '';
                          if (response !== null && response?.UserOptions !== undefined && response?.UserOptions !== null && response?.UserOptions !== '') {
                            let isOther = response?.UserOptions.filter(o => o.Answer.indexOf('Other-') !== -1)[0];
                            if (isOther !== undefined && isOther !== null && isOther !== '') {
                              answerText = isOther.Answer;
                            }
                          }

                          parameters = {
                            note: this.state.note,
                            value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
                            response_options: {
                              option_ids: (response == null || response.UserOptions == null ? [] : response.UserOptions.map((i) => {
                                return i.OptionID
                              })),
                            }
                          };
                          if (answerText !== '') {
                            parameters.response_options['answer'] = answerText;
                          }

                        } else {
                          parameters = {
                            note: this.state.note,
                            value: !this.props.enableScoring ? 10 : response == null ? null : response.Response,
                            response_options: {
                              option_ids: (response == null || response.UserOptions == null ? [] : response.UserOptions.map((i) => {
                                return i.OptionID
                              })),
                              answer: requirement.answer_type === 2 ? (addOther[0]?.Answer) : (response?.UserOptions[0]?.Answer || '')
                            },
                          };
                        }

                        if (!this.props.enableScoring) {
                          parameters.value = 10;
                          this.props.setMoveToNextFlag(true)
                        }

                        console.log(parameters, 'parameters')
                        this.props.addRequirementResponse(
                          this.props.match.params.evaluation_id,
                          product.ID,
                          requirement.id,
                          parameters);

                        this.onBlurInput(`requirement_${this.props.selectedProductId}`)
                      }}
                    />
                  </div>
                }

                {this.state.isCommentRequired &&
                  <div className={classes.submitBtnDiv}>
                    <Button
                      style={{ "marginLeft": "10px" }}
                      disabled={false}
                      className={classes.tickMark}
                      onClick={() => {
                        this.submitReqResponse();
                      }}>
                      <DoneIcon />
                    </Button>
                  </div>}


              </div>}

              {this.props.enableScoring && requirement != null && requirement?.answer_type !== 5 && <ProductReqMeet
                isShowReqMeet={this.state.isShowReqMeet}
                requirement={requirement}
                response={response}
                product={product}
                evaluation_id={this.props.match.params.evaluation_id}
                scoring_mehanism={scoring_mehanism}
                moveToNextReq={() => {
                  this.props.setMoveToNextFlag(true)
                }}
              />}

              {this.props.requirementTree && this.props.requirementTree.length > 1 && <div className={classes.nextPrevbtns}>
                {/* <TooltipShow className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Previous</span>} arrow>
                  <ArrowBackIcon
                    className={classes.arrowIcons}
                    onClick={() => {
                      if (isNewChangesFound) {
                        this.saveAndGoToRequirement(previousReqId);
                      } else if (previousReqId !== null) {
                        this.props.setSelectedRequirement(previousReqId);
                      }
                    }}
                  />
                </TooltipShow>

                <TooltipShow className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Next</span>} arrow>
                  <ArrowForwardIcon
                    className={classes.arrowIcons}
                    onClick={(e) => {
                      if (isNewChangesFound) {
                        this.saveAndGoToRequirement(nextReqId);
                      } else if (nextReqId !== null) {
                        this.props.setSelectedRequirement(nextReqId);
                      }
                    }}
                  />
                </TooltipShow> */}

                <SqureButton
                  disabled={this.props.isCompletedQuestionaire}
                  variant={"outlined"} startIcon={<ArrowBackIcon />} onClick={() => {
                    if (isNewChangesFound) {
                      this.saveAndGoToRequirement(previousReqId);
                    } else if (previousReqId !== null) {
                      this.props.setSelectedRequirement(previousReqId);
                    }
                  }}>Previous
                </SqureButton>

                <SqureButton
                  disabled={this.props.isCompletedQuestionaire}
                  variant={"outlined"} endIcon={<ArrowForwardIcon />} onClick={(e) => {
                    if (isNewChangesFound) {
                      this.saveAndGoToRequirement(nextReqId);
                    } else if (nextReqId !== null) {
                      this.props.setSelectedRequirement(nextReqId);
                    }
                  }}>Next
                </SqureButton>
              </div>}

              {false && <div className={classes.viewAllBtnDiv}>
                {this.props.selectedRequirementId && findRequirementPreviousInteraction(this.props.selectedRequirementId, this.props.previousInteractions, 'buyer').has && (
                  <Button className={classes.pIBtn} variant="contained" color="primary"
                    onClick={() => {
                      this.props.setResponseTab(0);
                      this.props.tooglePreviousInteractionDialog()
                    }} >View previous interaction</Button>
                )}
                {!this.state.isShowResponces && <p className={classes.viewAllResponse} onClick={(e) =>
                  this.setState({
                    isShowResponces: true
                  })
                }>View All Response </p>}
                {this.state.isShowResponces && <p className={classes.viewAllResponse} onClick={(e) =>
                  this.setState({
                    isShowResponces: false
                  })
                }>Hide All Response </p>}
              </div>}


              {this.state.isShowResponces && this.state.selectedTab === 0 && <div className={classes.collectiveUserResponses}>
                <CollectiveUserResponses
                  criteria={this.props.criteria}
                  evaluation_id={this.props.match.params.evaluation_id}
                  product={product}
                  requirement={requirement}
                  fetchCollectiveResponsesLoading={this.props.fetchCollectiveResponsesLoading}
                  collectiveResponses={this.props.collectiveResponses}
                  communityResponse={communityResponse}
                  collectiveRequirement={requirement}
                  scoring_mehanism={scoring_mehanism}
                  enableScoring={this.props.enableScoring}
                />
              </div>}

              <InternalChat
                evaluationId={this.props.match.params.evaluation_id}
                componentId={this.props.match.params.component_id}
                requirementId={this.props.selectedRequirementId}
                product={product}
                requirement={requirement}
                enableScoring={this.props.enableScoring}
              />


            </div>}
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={tab} index={1}>
            {show_community_message && <p className={classes.comunityMsg}>{communityMessage}</p>}
            {!show_community_message && <div className={classes.comunityMsg} dangerouslySetInnerHTML={{ __html: backendMessage }}></div>}
            <div className={classes.pricingLink}>{this.getPricingLink(requirement)}</div>

            {this.props.enableScoring && requirement != null && requirement?.answer_type !== 5 && <ProductReqMeet
              isShowReqMeet={this.state.isShowReqMeet}
              requirement={requirement}
              response={response}
              product={product}
              evaluation_id={this.props.match.params.evaluation_id}
              scoring_mehanism={scoring_mehanism}
              maxScore={this.props.maxScore}
              moveToNextReq={() => {
                this.props.setMoveToNextFlag(true)
              }}
            />}

          </TabPanel>
          <TabPanel className={classes.tabPanel} value={tab} index={2}>
            {<div>
              {requirement != null && <div className={classes.collectiveUserResponses}>
                <CollectiveSellerResponses
                  criteria={this.props.criteria}
                  evaluation_id={this.props.match.params.evaluation_id}
                  product={product}
                  answerType={requirement?.answer_type}
                  isHideYourResponse={this.props.isHideYourResponse}
                  productId={product?.ID}
                  collectiveTargetTab={2}
                  requirement={requirement}
                  fetchCollectiveResponsesLoading={this.props.fetchCollectiveResponsesLoading}
                  collectiveResponses={this.props.collectiveResponses}
                  communityResponse={communityResponse}
                  collectiveRequirement={requirement}
                  enableScoring={this.props.enableScoring}
                  buyerReq={requirement}
                  ProductReqMeet={{
                    isShowReqMeet: this.state.isShowReqMeet,
                    requirement: requirement,
                    response: response,
                    product: product,
                    evaluation_id: this.props.match.params.evaluation_id,
                    scoring_mehanism: scoring_mehanism
                  }}
                  moveToNextReq={() => {
                    this.props.setMoveToNextFlag(true)
                  }}
                  collectiveUserResponses={{
                    criteria: this.props.criteria,
                    evaluation_id: this.props.match.params.evaluation_id,
                    product: product,
                    requirement: requirement,
                    fetchCollectiveResponsesLoading: this.props.fetchCollectiveResponsesLoading,
                    collectiveResponses: this.props.collectiveResponses,
                    communityResponse: communityResponse,
                    collectiveRequirement: requirement,
                    scoring_mehanism: scoring_mehanism,
                    enableScoring: this.props.enableScoring,
                    evaluationId: this.props.match.params.evaluation_id,
                    componentId: this.props.match.params.component_id,
                    requirementId: this.props.selectedRequirementId,
                  }}
                />

                {false && this.props.enableScoring && requirement != null && requirement?.answer_type !== 5 && <ProductReqMeet
                  isShowReqMeet={this.state.isShowReqMeet}
                  requirement={requirement}
                  response={response}
                  product={product}
                  evaluation_id={this.props.match.params.evaluation_id}
                  scoring_mehanism={scoring_mehanism}
                  moveToNextReq={() => {
                    this.props.setMoveToNextFlag(true)
                  }}
                />}

                {this.props.selectedRequirementId && findRequirementPreviousInteraction(this.props.selectedRequirementId, this.props.previousInteractions, 'seller').has && (
                  <Button className={classes.pIBtn} variant="contained" color="primary"
                    onClick={() => {
                      this.props.setResponseTab(1);
                      this.props.tooglePreviousInteractionDialog()
                    }} >View past vendor interaction</Button>
                )}
              </div>}
            </div>}
          </TabPanel>
        </div >
        <CheckUnsavedResDialog
          messageData={messageData}
          callBack={this.callBack}
          closeCallBack={this.closeCallBack}
          closeDialog={this.closeMessageDialog}
        />
        <ConfirmCallbackDialog
          messageData={removeCollabMessageData}
          callBack={this.removeCollabCallBack}
          closeCallBack={this.removeCollabCloseCallBack}
          closeDialog={this.removeCollabCloseMessageDialog}
        />

      </div >
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Response));

const ActionButtom = ({ classes, onSave, onCancel, isDisabled = false }) => (
  <div style={{ textAlign: 'right', marginTop: 5 }}>
    <Button
      className={classes.tickMark}
      onClick={() => isDisabled ? {} : onSave()}
    >
      <DoneIcon />
    </Button>
    <Button
      className={classes.tickMark}
      onClick={onCancel}
    >
      <CloseIcon />
    </Button>
  </div>
)