import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment"

export const START_FETCH_REQUEST = "START_FETCH_REQUEST";
export const END_FETCH_REQUEST = "END_FETCH_REQUEST";

export const START_DELETE_REQUEST = 'START_DELETE_REQUEST';
export const END_DELETE_REQUEST = 'END_DELETE_REQUEST';

export const START_FETCH_INPROGRESS_REQUEST = "START_FETCH_INPROGRESS_REQUEST";
export const END_FETCH_INPROGRESS_REQUEST = "END_FETCH_INPROGRESS_REQUEST";

export const START_FETCH_PRODUCT_INPROGRESS_REQUEST = "START_FETCH_PRODUCT_INPROGRESS_REQUEST";
export const END_FETCH_PRODUCT_INPROGRESS_REQUEST = "END_FETCH_PRODUCT_INPROGRESS_REQUEST";

export const START_DELETE_INPROGRESS_REQUEST = 'START_DELETE_INPROGRESS_REQUEST';
export const END_DELETE_INPROGRESS_REQUEST = 'END_DELETE_INPROGRESS_REQUEST';

export const START_FETCH_COMPLETED_REQUEST = "START_FETCH_COMPLETED_REQUEST";
export const END_FETCH_COMPLETED_REQUEST = "END_FETCH_COMPLETED_REQUEST";

export const START_FETCH_PRODUCT_COMPLETED_REQUEST = "START_FETCH_PRODUCT_COMPLETED_REQUEST";
export const END_FETCH_PRODUCT_COMPLETED_REQUEST = "END_FETCH_PRODUCT_COMPLETED_REQUEST";

export const START_DELETE_COMPLETED_REQUEST = 'START_DELETE_COMPLETED_REQUEST';
export const END_DELETE_COMPLETED_REQUEST = 'END_DELETE_COMPLETED_REQUEST';

function startFetchRequests(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_FETCH_REQUEST,
        payload:payload
    };
}

function endFetchRequests(success,queryParam,error){
    return {
        type:END_FETCH_REQUEST,
        payload:{
            success,error,queryParam
        }
    };
}

export  function fetchRequests(queryParam){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/fetch?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchRequests());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endFetchRequests(response,queryParam,error));
        } catch(e){
            dispatch(endFetchRequests(null,{
                message:e.message
            }));
            return;
        }
    };
} 

export function deleteRequest(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/review/${id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteRequest());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteRequest(response, error,id));
        } catch (e) {
            dispatch(endDeleteRequest(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteRequest() {
    return {
        type: START_DELETE_REQUEST,
        payload: null
    };
}

function endDeleteRequest(success, error, id) {
    return {
        type: END_DELETE_REQUEST,
        payload: {
            success, error, id
        }
    };
}





function startFetchInprogressRequests(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_FETCH_INPROGRESS_REQUEST,
        payload:payload
    };
}

function endFetchInprogressRequests(success,queryParam,error){
    return {
        type:END_FETCH_INPROGRESS_REQUEST,
        payload:{
            success,error,queryParam
        }
    };
}

export  function fetchInprogressRequests(queryParam){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/fetch?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchInprogressRequests());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endFetchInprogressRequests(response,queryParam,error));
        } catch(e){
            dispatch(endFetchInprogressRequests(null,{
                message:e.message
            }));
            return;
        }
    };
} 



function startFetchProductInprogressRequests(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_FETCH_PRODUCT_INPROGRESS_REQUEST,
        payload:payload
    };
}

function endFetchProductInprogressRequests(success,queryParam,error){
    return {
        type:END_FETCH_PRODUCT_INPROGRESS_REQUEST,
        payload:{
            success,error,queryParam
        }
    };
}

export  function fetchProductInprogressRequests(queryParam){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/list?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductInprogressRequests());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endFetchProductInprogressRequests(response,queryParam,error));
        } catch(e){
            dispatch(endFetchProductInprogressRequests(null,{
                message:e.message
            }));
            return;
        }
    };
} 


export function deleteInprogressRequest(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/review/${id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteInprogressRequest());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteInprogressRequest(response, error,id));
        } catch (e) {
            dispatch(endDeleteInprogressRequest(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteInprogressRequest() {
    return {
        type: START_DELETE_INPROGRESS_REQUEST,
        payload: null
    };
}

function endDeleteInprogressRequest(success, error, id) {
    return {
        type: END_DELETE_INPROGRESS_REQUEST,
        payload: {
            success, error, id
        }
    };
}





function startFetchCompletedRequests(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_FETCH_COMPLETED_REQUEST,
        payload:payload
    };
}

function endFetchCompletedRequests(success,queryParam,error){
    return {
        type:END_FETCH_COMPLETED_REQUEST,
        payload:{
            success,error,queryParam
        }
    };
}

export  function fetchCompletedRequests(queryParam){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/fetch?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchCompletedRequests());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endFetchCompletedRequests(response,queryParam,error));
        } catch(e){
            dispatch(endFetchCompletedRequests(null,{
                message:e.message
            }));
            return;
        }
    };
} 



function startFetchProductCompletedRequests(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_FETCH_PRODUCT_COMPLETED_REQUEST,
        payload:payload
    };
}

function endFetchProductCompletedRequests(success,queryParam,error){
    return {
        type:END_FETCH_PRODUCT_COMPLETED_REQUEST,
        payload:{
            success,error,queryParam
        }
    };
}

export  function fetchProductCompletedRequests(queryParam){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/list?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductCompletedRequests());
        try{
            let [response,error]    =   await await HttpFactory.instance().postMethod(url,queryParam,false);
            dispatch(endFetchProductCompletedRequests(response,queryParam,error));
        } catch(e){
            dispatch(endFetchProductCompletedRequests(null,{
                message:e.message
            }));
            return;
        }
    };
}


export function deleteCompletedRequest(id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/review/${id}?t=${timestamp}`
    return async (dispatch) => {
        dispatch(startDeleteCompletedRequest());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteCompletedRequest(response, error,id));
        } catch (e) {
            dispatch(endDeleteCompletedRequest(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteCompletedRequest() {
    return {
        type: START_DELETE_COMPLETED_REQUEST,
        payload: null
    };
}

function endDeleteCompletedRequest(success, error, id) {
    return {
        type: END_DELETE_COMPLETED_REQUEST,
        payload: {
            success, error, id
        }
    };
}