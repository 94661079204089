import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppBarV2 from "../../../../../AppBarV2";
import CommonCss from 'commonCss';
import {
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, CircularProgress, IconButton, Paper,
  Button
} from '@material-ui/core';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";
import { showAddAlertDialog, hideAddAlertDialog, deleteAlert, fetchAlerts } from 'redux/alerts/action';
import AddAlertDialog from "../../dialogs/AddAlertDialog";
import { showSnackBar } from "redux/snackbar/action";

import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";

const connectedProps = (state) => ({
  isOpenAddDialog: state.alert.dialogAddAlertOpen,
  addNewAlertProgress: state.alert.addNewAlertProgress,
  addNewAlertError: state.alert.addNewAlertError,
  newTaskData: state.alert.newTaskData,
  deleteAlertProgress: state.alert.deleteAlertProgress,
  deleteAlertError: state.alert.deleteAlertError,
  fetchAlertsProgress: state.alert.fetchAlertsProgress,
  fetchAlertsError: state.alert.fetchAlertsError,
  alertDatas: state.alert.alertDatas,
});

const connectionActions = {
  showAddAlertDialog: showAddAlertDialog,
  hideAddAlertDialog: hideAddAlertDialog,
  showSnackBar: showSnackBar,
  deleteAlert: deleteAlert,
  fetchAlerts: fetchAlerts,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  topActions: {
    textAlign: 'right',
    marginBottom: 20
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  nameCol: {
    width: '30%'
  },
  statusCol: {
    width: '15%'
  },
  actionCol: {
    textAlign: 'center',
    width: '10%'
  },
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoading: true,
      alerts: [],
      page: 0,
      pageSize: 10,
      isLoading: true,
      totalAlerts: 0,
      typesMap: {
        1: 'Trigger',
      },
      targetTypeMap: {
        1: 'Application Catalog',
      },
      deleteId:null
    }
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.callBack = this.callBack.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.props.fetchAlerts({
      page: this.state.page,
      pageSize: this.state.pageSize
    })
  }

  componentDidUpdate(prevProps) {
    const { addNewAlertProgress, addNewAlertError,
      newTaskData, deleteAlertProgress, deleteAlertError, fetchAlertsProgress, fetchAlertsError,
      alertDatas } = this.props;
    if (!addNewAlertProgress && prevProps.addNewAlertProgress) {
      if (addNewAlertError === null) {
        this.props.showSnackBar("Alert added successfully.", "success", 3000);
        this.props.hideAddAlertDialog()
        this.handleEdit(newTaskData?.ID)
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!deleteAlertProgress && prevProps.deleteAlertProgress) {
      if (deleteAlertError === null) {
        this.props.showSnackBar("Alert deleted successfully.", "success", 3000);
        this.setState({
          page: 0,
          pageSize: 10,
        },()=>{
          this.fetchData();
        })
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!fetchAlertsProgress && prevProps.fetchAlertsProgress) {
      if (fetchAlertsError === null) {
        this.setState({
          isDataLoading: false,
          alerts: alertDatas?.Data,
          totalAlerts: alertDatas?.total
        })
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

  }

  handleEdit(id) {
    let redirectPath = `/app/settings/alerts/${id}`;
    this.props.history.push(redirectPath);
  }

  handleDelete(id) {
    var me = this;
    this.setState({ deleteId: id }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack() {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteAlert(this.state.deleteId);
    }
  }

  handleChangePage(e, newPage) {
    this.setState({
      page: newPage,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleChangeRowsPerPage(e) {
    this.setState({
      page: 0,
      pageSize: e.target.value,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  render() {
    const classes = this.props.classes;
    const { isDataLoading, alerts, page, pageSize, totalAlerts } = this.state;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <div>
        <AppBarV2 title="Alerts" withBack={true} />
        <div className={classes.rootContainer}>
          <div className={classes.topActions}>
            <Button className={classes.newUserBtn} onClick={() => {
              this.props.showAddAlertDialog()
            }}>
              + New Alert
            </Button>
          </div>
          <div className={classes.body}>
            <Paper style={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer style={{ maxHeight: '78vh' }}>
                <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.nameCol}>Title</TableCell>
                      <TableCell className={classes.nameCol}>Notification</TableCell>
                      {/* <TableCell className={classes.statusCol}>Target</TableCell>
                      <TableCell className={classes.statusCol}>Type</TableCell> */}
                      <TableCell className={classes.actionCol}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isDataLoading && alerts.slice(page * pageSize, page * pageSize + pageSize).map((task) => {
                      return <TableRow key={'task_' + task.ID}>
                        <TableCell className={classes.nameCol}>{task?.Title}</TableCell>
                        <TableCell className={classes.statusCol}>{task?.Notification}</TableCell>
                        {/* <TableCell className={classes.statusCol}>{targetTypeMap[task.Target]}</TableCell>
                        <TableCell className={classes.statusCol}>{typesMap[task.Type]}</TableCell> */}
                        <TableCell className={classes.actionCol}>
                          <IconButton
                            onClick={() => this.handleEdit(task?.ID)}
                          >
                            <Image
                              src={editIcon}
                            />
                          </IconButton>

                          <IconButton
                            onClick={() => this.handleDelete(task?.ID)}
                          >
                            <Image
                              src={deleteIcon}
                            />
                          </IconButton>

                        </TableCell>
                      </TableRow>
                    })}

                    {(!isDataLoading && (alerts === undefined || alerts === null || alerts.length <= 0)) && <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: 'center' }} className={classes.nameCol}>No alerts</TableCell>
                    </TableRow>}

                    {isDataLoading && <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: 'center' }} className={classes.nameCol}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>}

                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={totalAlerts || 0}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
        {this.props.isOpenAddDialog && <AddAlertDialog />}
        <MessageDialog messageData={messageData} callBack={this.callBack} />
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Home));
