import { CircularProgress,ListItemSecondaryAction,Button, List, ListItem, Dialog, DialogContent, InputAdornment, Slide, TextField, Paper, Popover, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import SearchIcon from "@material-ui/icons/Search";
import { showAddFormColumnsDialog, hideAddFormColumnsDialog } from "redux/workflow/action";
import { fetchOrgForms } from "redux/forms/org/action";
import { showSnackBar } from "redux/snackbar/action";
import Card from "./card";
import { getAllSelectedFormColumnsObj, getAllTrackingSelectedFormColumnsObj } from "util/Columns";

import CheckboxTree from "react-checkbox-tree";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  selectedFormColumns: state.workflow.selectedFormColumns,
  fetchOrgFormsProgress: state.orgForms.fetchOrgFormsProgress,
  forms: state.orgForms.forms,
  isOpenAddFormColumnsDialog: state.workflow.isOpenAddFormColumnsDialog,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchOrgForms: fetchOrgForms,
  showAddFormColumnsDialog: showAddFormColumnsDialog,
  hideAddFormColumnsDialog: hideAddFormColumnsDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(6, 12) + " !important",
    },
    uploadSection: {
      boxShadow: "5px 5px 25px rgb(0 0 0 / 8%)",
      borderRadius: 10,
      padding: "1rem",
    },
    uploadSubText: {
      color: "#475867",
    },
    uploadAnchor: {
      color: "#0091FF !important",
      textDecoration: "underline",
      opacity: 0,
      width: 0,
    },
    btnSection: {
      marginTop: 20,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    labelFile: {
      color: "#4a87f8",
      marginLeft: 5,
      border: "1px solid #4a87f8",
      padding: "5px 10px",
      borderRadius: 4,
      cursor: "pointer",
    },
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginBottom: theme.spacing(2.5),
      textAlign: "center",
      marginLeft: 20,
    },
    arrow: {
      paddingLeft: 5,
    },
    downloadTemplate: {
      textDecoration: "underline",
      color: "#0091FF !important",
    },
    buttonSection: {
      textAlign: "center",
      margin: 20,
    },
    progress: {
      textAlign: "center",
      paddingTop: 15,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    delete: {
      cursor: "pointer",
      marginLeft: 10,
    },
    fileNameSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      display: "flex",
    },
    cardItem: {
      width: "50%",
      maxHeight: 350,
      overflowY: "auto",
    },
    searchBox: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(3, 0),
    },
    contentList: {
      marginTop: 25,
      paddingLeft:20
    },
    autoSelectInput: {
      width: "100%",
      "& [class*=MuiInputBase-input]": {
        padding: "10px !important",
      },
    },
    headerFlexWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    columnDrawer: {
      minWidth: 150,
      maxWidth: 300,
      right: "0",
      zIndex: "9",
    },
    cancelColumn: {
      width: '30px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    }
  });

class FormColumnsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      query: "",
      isShowSubMenu: false,
      subMenuEl: null,
      nodes: [],
      selectAllItems: false,
      checked: [],
      forms: [],
      allFormColumnsArray: [],
      allSelectedFormColumnsArr: [],
      selectedForm: null,
      allSelectedFormColumnsObj: {}
    };
  }

  componentDidMount() {
    this.props.fetchOrgForms(0, 500);
    let allchecked = [];
    let allSelectedFormColumnsObj = {}
    if(this.props.activeTab && this.props.activeTab === 3){
      allSelectedFormColumnsObj = getAllTrackingSelectedFormColumnsObj();
    }else{
      allSelectedFormColumnsObj = getAllSelectedFormColumnsObj();
    }
    if(allSelectedFormColumnsObj){
      Object.keys(allSelectedFormColumnsObj).forEach(key=>{
        allSelectedFormColumnsObj[key].forEach(item=>{
          allchecked.push(`Item_${item.ID}`)
        })
      })
    }
      
    this.setState({
      checked: allchecked,
      allSelectedFormColumnsObj: allSelectedFormColumnsObj
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.fetchOrgFormsProgress && prevProps.fetchOrgFormsProgress) {
      this.setState({ forms: this.props.forms || [] });
    }
  }

  populateFormSections = () => {
    if(this.state.selectedForm && this.state.selectedForm.OrgWorkflowTemplateFormsSection ){
      let sections = this.state.selectedForm?.OrgWorkflowTemplateFormsSection || [];
      var nodes = [];
      let allFormColumnsArray = [...this.state.allFormColumnsArray];

      sections.forEach(function (sec) {
        var obj = {
          value: "Section_" + sec.ID,
          label: sec.SectionName,
          children: [],
        };
        let isGoodToAdd = true;
        if (sec.OrgWorkflowTemplateFormSectionItems && sec.OrgWorkflowTemplateFormSectionItems.length>0) {
          sec.OrgWorkflowTemplateFormSectionItems.forEach(function (item, i) {  
              obj.children.push({
                value: "Item_" + item.ID,
                label: item.ItemName,
              });
              if(!allFormColumnsArray.some(O=>(O.ID === item.ID))){
                allFormColumnsArray.push(item);
              }
          });
        } else {
          isGoodToAdd = false;
        }
        if (isGoodToAdd && obj.children.length > 0) {
          nodes.push(obj);
        }
      });
      
      this.setState({
        nodes,
        allFormColumnsArray
      });
    }
    
  };

  showSelectedItemsPoper = (event) => {
    this.setState({
      subMenuEl: event.currentTarget || event.current,
      isShowSubMenu: true,
    });
  };

  closeSelectedItemsPoper = () => {
    this.setState({
      isShowSubMenu: false,
      subMenuEl: null,
    });
  };

  confirmFields = () => {
    this.props.setFormColumns(this.state.allSelectedFormColumnsObj);
    this.props.hideAddFormColumnsDialog();
  }

  render() {
    const classes = this.props.classes;
    const { isOpenAddFormColumnsDialog, hideAddFormColumnsDialog } = this.props;
    return (
      <Dialog aria-labelledby="add-upload-dialog" open={isOpenAddFormColumnsDialog} TransitionComponent={Transition} disableBackdropClick={true} fullWidth={true} maxWidth={"lg"} scroll={"body"}>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => hideAddFormColumnsDialog()}>
            <CloseIcon />
          </div>
          <Typography variant={"h6"} className={classes.title}>
            Select Form Fields
          </Typography>
          <br />
            <div className={classes.headerFlexWrap}>
              <div>
                <TextField
                  className={classes.autoSelectInput}
                  value={this.state.query}
                  variant="outlined"
                  placeholder={"Search form"}
                  type="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    if (e.target.value && e.target.value.length) {
                      let formsArr = [...(this.props.forms || [])];
                      let filterForms = formsArr.filter((item) => item.FormTitle.toLowerCase().includes(e.target.value.toLowerCase()));
                      this.setState({ forms: filterForms, query: e.target.value});
                    } else {
                      this.setState({  query: '', forms: this.props.forms || [] });
                    }
                  }}
                />
              </div>

              { this.state.checked.length > 0 && <div className={classes.showBtnSection}>
                  <Button variant="outlined" color="secondary" className={classes.button}
                    onClick={(event) => {
                      this.showSelectedItemsPoper(event);
                    }}
                  >
                   {this.state.checked.length}{" "} fields selected
                  </Button>
              </div>}
            </div>

          <div className={classes.searchBox}>
            <div className={classnames(classes.uploadSection, classes.cardItem)}>
              
                {!this.props.fetchOrgFormsProgress && this.state.forms &&
                  this.state.forms.map((template, i) => {
                    return (
                      <div key={i} style={{margin: 8}} onClick={() => {
                        let selectedform =  this.props.forms.find((form)=> form.ID === template.ID);
                        this.setState({
                          selectedForm:selectedform
                        },()=>{
                          this.populateFormSections();
                        });
                      }}>
                        <Card template={template} selectedForm={this.state.selectedForm} />
                      </div>
                    );
                  })}

                  {this.props.fetchOrgFormsProgress && (
                    <div style={{textAlign: 'center'}}>
                      <CircularProgress />
                    </div>
                  )}

                  {!this.props.fetchOrgFormsProgress && this.state.forms.length === 0 && (
                    <div style={{textAlign: 'center'}}>
                      No Data...
                    </div>
                  )}
              
            </div>
            <div className={classnames(classes.cardItem,classes.contentList)}>
             
                <CheckboxTree
                  nodes={this.state.nodes}
                  checked={this.state.checked}
                  expanded={this.state.expanded}
                  onCheck={(checked) => {
                    let itemIds = [];
                    let allSelectedFormColumnsObj = {...this.state.allSelectedFormColumnsObj};
                    let formID = this.state.selectedForm.ID;
                    checked.forEach(function (itm) {
                      if (itm.indexOf("Item_") !== -1) {
                        let itmId = itm.replace("Item_", "");
                        itemIds.push(parseInt(itmId));
                      }
                    });
                    let columns = this.state.allFormColumnsArray.filter(item=>itemIds.includes(item.ID));
                    allSelectedFormColumnsObj[formID] = columns;
                    let allchecked = [];
                    Object.keys(allSelectedFormColumnsObj).forEach(key=>{
                      allSelectedFormColumnsObj[key].forEach(item=>{
                        allchecked.push(`Item_${item.ID}`)
                      })
                    })
                    this.setState({ 
                      checked: allchecked,
                      allSelectedFormColumnsObj
                    });
                  }}
                  onExpand={(expanded) => this.setState({ expanded })}
                  iconsClass="fa5"
                  icons={{
                    check: <CheckBoxIcon />,
                    uncheck: <CheckBoxOutlineBlankIcon />,
                    expandClose: <ChevronRightIcon />,
                    expandOpen: <ExpandMoreIcon />,
                    halfCheck: <CheckBoxOutlineBlankIcon />,
                  }}
                />
              
            </div>
          </div>
          <div className={classes.buttonSection}>
            <Button variant="outlined" color="secondary" className={classes.button} onClick={hideAddFormColumnsDialog}>
              Cancel
            </Button>
            &nbsp; &nbsp;
            
            <Button variant="contained" color="secondary" type="submit" className={classes.button} onClick={this.confirmFields}>
              Confirm fields
            </Button>
          </div>
        </DialogContent>
        {this.state.isShowSubMenu && (
          <Popover
            open={this.state.isShowSubMenu}
            anchorEl={this.state.subMenuEl}
            onClose={this.closeSelectedItemsPoper}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={classes.columnDrawer}>
              <List>
                {Object.keys(this.state.allSelectedFormColumnsObj).map((key)=>(
                    this.state.allSelectedFormColumnsObj[key].map((item, i) => (
                      <ListItem key={i}>
                        <Typography variant="inherit">{item.ItemName}</Typography>
                        <ListItemSecondaryAction>
                          <div className={classes.cancelColumn} onClick={() => {
                              let checked = [...this.state.checked];
                              let allSelectedFormColumnsObj = {...this.state.allSelectedFormColumnsObj};
                              let itmIndx = checked.findIndex((o) => (o.replace("Item_", "") == item.ID));
                              checked.splice(itmIndx, 1);
                              let itmIndx3 = allSelectedFormColumnsObj[key].findIndex((o) => (o.ID === item.ID));
                              allSelectedFormColumnsObj[key].splice(itmIndx3, 1);
                              let allchecked = [];
                              Object.keys(allSelectedFormColumnsObj).forEach(key=>{
                                allSelectedFormColumnsObj[key].forEach(item=>{
                                  allchecked.push(`Item_${item.ID}`)
                                })
                              })
                              this.setState({ 
                                checked: allchecked,
                                allSelectedFormColumnsObj
                              });
                              if(allchecked.length === 0){
                                this.closeSelectedItemsPoper()
                              }
                            }}>
                              <CancelRoundedIcon style={{ fontSize: "1.4rem", color: "#e11e5a" }} /> 
                          </div>
                        </ListItemSecondaryAction>
                        </ListItem>
                        ))
                    ))}
              </List>
            </Paper>
          </Popover>
        )}
      </Dialog>
    );
  }
}

export default connector(compose(withStyles(styles))(FormColumnsDialog));
