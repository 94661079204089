import { showSearchUsersPoperDialog, hideSearchUsersPoperDialog } from "redux/dialogs/action";
import { createStyles, withStyles } from "@material-ui/core/styles";

import axios from "axios";
import classnames from "classnames";
import CommonCss from "commonCss";
import { Button, Paper, CircularProgress } from "@material-ui/core";

import DialogOrgProductContractDetails from "Components/Dialogs/DialogOrgProductContractDetails";

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import CollaboratorPopper from "./Common/CollaboratorPopper";
import queryString from 'query-string';

// import ActiveImage from "assets/images/active_users.svg";
// import InActiveImage from "assets/images/inactive_users.svg";

// Redux
import {
  activeContractDetailsDocument,
  addOrgDocument, fetchContractFolders,
  deleteOrgDocument, fetchOrgDocuments,
  fetchOrgFinanceDetails, updateContractFolders,
  fetchParseContractDetails,
  hideAddOrgProductChampionsDialog,
  showAddOrgProductChampionsDialog,
  showAddOrgProductContractDetailsDialog,
  updateOrgFinanceDetails, updateOrgDocument
} from "redux/product/orgProduct/action";
import { showSnackBar } from "redux/snackbar/action";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import CustomSection from "./CustomSection";
import FinancialDetails from "./FinancialDetails";
import AddFinancialDetailsDefault from "./AddFinancialDetailsDefault";

import { showDocumentDetailDialog, hideDocumentDetailDialog } from "redux/dialogs/action";
import AddDocumentDetail from "./Dialogs/AddDocumentDetail";

import { fetchProductContract, addProductContract, updateProductContract, deleteProductContract } from "redux/product/orgProduct/contract/action";
import { fetchDocTypes } from 'redux/documentTypes/action'
import FolderBasedTable from "./FolderBasedTable";
import AddFolderDialog from "Components/Common/AddFolderDialog";
import AddFileToFolderDialog from "Components/Common/AddFileToFolderDialog";
import { getFileAndExtension, getFileExtension, getFileName } from "util/AppCatalogTypeOptions";

const connectedProps = (state) => ({
  orgFinanceDetails: state.orgProduct.orgFinanceDetails,
  orgFinanceDetailsProgress: state.orgProduct.orgFinanceDetailsProgress,
  orgUpdateFinanceDetailsProgress: state.orgProduct.orgUpdateFinanceDetailsProgress,
  orgFetchDocuments: state.orgProduct.orgFetchDocuments,
  orgFetchDocumentsProgress: state.orgProduct.orgFetchDocumentsProgress,
  addOrgDocumentProgress: state.orgProduct.addOrgDocumentProgress,
  deleteOrgDocumentProgress: state.orgProduct.deleteOrgDocumentProgress,
  activeContractDocument: state.orgProduct.activeContractDocument,
  addContractDetailsProg: state.orgProduct.addContractDetailsProg,
  userType: state.authUser.user?.Role,

  addContractProgress: state.orgProduct.addContractProgress,
  addContractError: state.orgProduct.addContractError,
  addContractSuccess: state.orgProduct.addContractSuccess,

  fetchProductContractProgress: state.productContracts.fetchProductContractProgress,
  fetchProductContractError: state.productContracts.fetchProductContractError,
  contracts: state.productContracts.contracts,

  addProductContractProgress: state.productContracts.addProductContractProgress,
  addProductContractError: state.productContracts.addProductContractError,
  addProductContractSuccess: state.productContracts.addProductContractSuccess,

  updateProductContractProgress: state.productContracts.updateProductContractProgress,
  updateProductContractError: state.productContracts.updateProductContractError,
  updateProductContractSuccess: state.productContracts.updateProductContractSuccess,

  deleteProductContractProgress: state.productContracts.deleteProductContractProgress,
  deleteProductContractError: state.productContracts.deleteProductContractError,
  deleteProductContractSuccess: state.productContracts.deleteProductContractSuccess,

  fetchDocTypesProgress: state.documentType.fetchDocTypesProgress,
  fetchDocTypeError: state.documentType.fetchDocTypeError,
  documentTypes: state.documentType.types,

  userSearchProgress: state.userSearch.searchProgress,
  users: state.userSearch.users,

  updateOrgDocumentProgress: state.orgProduct.updateOrgDocumentProgress,
  updateOrgDocumentError: state.orgProduct.updateOrgDocumentError,
  updateOrgDocumentSuccess: state.orgProduct.updateOrgDocumentSuccess,

  attachDocumentProgress: state.orgProduct.attachDocumentProgress,
  attachDocumentError: state.orgProduct.attachDocumentError,
  attachDocumentSuccess: state.orgProduct.attachDocumentSuccess,

  fetchContractFoldersProgress: state.orgProduct.fetchContractFoldersProgress,
  updateContractFoldersProgress: state.orgProduct.updateContractFoldersProgress,
  updateContractFoldersError: state.orgProduct.updateContractFoldersError,
});

const connectionActions = {
  fetchOrgFinanceDetails: fetchOrgFinanceDetails,
  updateOrgFinanceDetails: updateOrgFinanceDetails,
  showSnackBar: showSnackBar,
  fetchOrgDocuments: fetchOrgDocuments,
  addOrgDocument: addOrgDocument,
  deleteOrgDocument: deleteOrgDocument,
  showAddOrgProductChampionsDialog: showAddOrgProductChampionsDialog,
  hideAddOrgProductChampionsDialog: hideAddOrgProductChampionsDialog,
  showAddOrgProductContractDetailsDialog: showAddOrgProductContractDetailsDialog,
  fetchParseContractDetails: fetchParseContractDetails,
  activeContractDetailsDocument: activeContractDetailsDocument,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
  showDocumentDetailDialog: showDocumentDetailDialog,
  hideDocumentDetailDialog: hideDocumentDetailDialog,

  fetchProductContract: fetchProductContract,
  addProductContract: addProductContract,
  updateProductContract: updateProductContract,
  deleteProductContract: deleteProductContract,
  fetchDocTypes: fetchDocTypes,
  updateOrgDocument: updateOrgDocument,
  fetchContractFolders: fetchContractFolders,
  updateContractFolders: updateContractFolders,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
      paddingLeft: 20,
      flex: 1,
      padding: "6px 5px !important",
      minHeight: "auto !important",
    },
    ...CommonCss.ProductDetails,
    itemSection: {
      border: "1px solid #d8d8d8",
      padding: 17,
      borderRadius: 5,
      background: "#FFF",
      marginBottom: 17,
      position: "relative",
      "& [class*=PricingSection-head]": {
        color: "#6C6C6C",
        fontSize: 18,
        opacity: 1,
        fontWeight: 600,
        margin: 0,
        marginBottom: 11,
      },
    },
    loader: {
      width: 100,
      height: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    threeDot: {
      position: "absolute",
      zIndex: 11,
      right: 5,
      top: 5,
      padding: 5,
    },
    head: {
      color: "#6C6C6C",
      fontSize: 18,
      opacity: 1,
      fontWeight: 600,
      margin: 0,
      marginBottom: 11,
    },
    contentSection: {
      ...CommonCss.ProductDetails.contentSection,
      overflowX: "hidden",
      overflowY: "overlay",
    },
    tabSection: {
      marginBottom: 30,
    },
    formGroup: {
      marginTop: 5,
      marginBottom: 10,
      position: "relative",
    },
    label: {
      marginBottom: 5,
      display: "block",
      fontWeight: 500,
    },

    uploadImageLabel: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    uploadImage: {
      overflow: "hidden",
      width: 0,
      position: "absolute",
      top: 10,
      left: 0,
      opacity: 0,
    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 19,
      margin: "0px !important",
    },
    ...CommonCss.Home,

    buttonGroup: {
      alignItems: "center",
      position: "relative",
      zIndex: 1000,
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 6,
      marginBottom: 5,
      cursor: "pointer",
    },
    descriptionText: {
      float: "right",
    },
    labelDpa: {
      // width: 197
    },
    contained: {
      color: "#fff",
      padding: "6px 26px",
      backgroundColor: "#4b86f8",
      "&:hover": {
        backgroundColor: "#4b86f8",
      },
    },
    inputSection: {
      display: "flex",
      alignItems: "center",
      position: "relative",
      "& .react-datepicker-wrapper": {
        width: "100%",
        "& input": {
          paddingLeft: "28px !important",
        },
      },
      "& > div": {
        position: "relative",
      },
      "& .react-datepicker-popper": {
        zIndex: 2222,
      },
    },
    symbol: {
      position: "absolute",
      height: 44,
      left: 17,
      top: 22,
    },
    inputFrom: {
      display: "flex",
      alignItems: "center",
    },
    slash: {
      width: 25,
      padding: "4px 7px",
    },
    formSelect: {
      width: '100%',
    },
    deleteImage: {
      zIndex: 20,
      padding: 4,
      cursor: "pointer",
    },
    tickMark: {
      color: "#4b86f8",
      border: "1px solid #4b86f8",
      marginRight: 10,
      minWidth: "50px !important",
      "&:hover": {
        color: "#4b86f8",
      },
    },
    layeredCard: {
      "& .product_container img": {
        maxWidth: 40,
      },
    },
    contractHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      overflow: "hidden",
      marginBottom: 25,
    },
    layeredCardHead: {
      marginBottom: 0,
    },
    linkText: {
      position: "relative",
      cursor: "pointer",
      width: "100%",
      height: "100%",
    },
    resourceLink: {
      display: "flex",
      alignItems: "center",
    },
    resourceLinkText: {
      color: "#4b86f8 !important",
      cursor: "pointer",
      padding: 10,
    },
    positionRelative: {
      position: "relative",
    },
    tableCell: {
      width: "30%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "calc(100vw - 99vw)",
    },
    tabledeleteCell: {
      width: "10%",
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      marginRight: "30px",
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 35,
      padding: 0,
      marginLeft: 15,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    paper: {
      boxShadow: "none",
      overflowX: 'auto',
      border: "1px solid #ddd",
    },
    resourceHead: {
      padding: 10,
    },
    calendarImage: {
      top: 25,
      zIndex: 20,
    },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    menuList: {
      padding: "0px",
      "& > hr": {
        "&:first-child": {
          display: "none",
        },
      },
    },
    popover: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        "&::before": {
          top: "50px",
          left: "-16px",
          width: "0",
          borderTop: "8px solid transparent",
          height: "0px",
          content: "''",
          display: "block",
          position: "absolute",
          borderBottom: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid #fff",
        },
      },
    },
    formControlSel: {
      width: '100%'
    }
  });

class FinanceTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: queryString.parse(this.props.location.search),
      isNoOfLicenses: false,
      isCostPerLinceses: false,
      isTotalCost: false,
      noOfLicenses: "",
      costPerLinceses: "",
      costPerLincesesFrequency: "Month",
      totalCost: "",
      totalCostFrequency: "Month",
      startDate: "",
      renewDate: "",
      endDate: "",
      isStartDate: "",
      isRenewDate: "",
      isEndDate: "",
      imgSrc: null,
      currentDoc: "",
      activeRowIndex: null,
      orgDocuments: [],
      documentStatusOptions: [
        {
          label: 'Active',
          value: 'Active'
        },
        {
          label: 'Inactive',
          value: 'Inactive'
        }
      ],
      documentUserOptions: [
        {
          label: 'Sunil',
          value: 'Sunil'
        },
        {
          label: 'Anil',
          value: 'Anil'
        },
      ],
      openAddFolderDialog: false,
      openAddFolderDialogData: null,
      openAddFileToFolderDialog: false,
      openAddFileToFolderDialogData: null
    };
  }

  componentDidMount() {
    this.props.fetchContractFolders(this.props.match?.params?.id);
    this.fetchOrgFinanceDetails();
    this.props.fetchDocTypes()
    setTimeout(() => {
      this.fetchOrgDocuments();
    }, 500);

    if (this.state.queryParams && this.state.queryParams.document_id) {
      this.setState({
        activeRowIndex: parseInt(this.state.queryParams.document_id) || 0
      })
    }

    // For testing
    // this.props.fetchParseContractDetails({
    //     contract_url: "https://devattachments.expent.ai/3/orgProduct/contractsAndAgreements/64425/1632575535662.pdf",
    //     file_type: "invoice",
    //     product_id: "64425",
    //     product_name: "AWS Account Federation"
    // })
    // this.props.showAddOrgProductContractDetailsDialog({
    //     step: 2,
    //     head: '',
    //     filePath: "https://devattachments.expent.ai/3/orgProduct/contractsAndAgreements/64425/1632575535662.pdf",
    //     nextBtnText: 'Save',
    //     cancelBtnText: 'Cancel',
    // })
  }

  fetchOrgFinanceDetails = () => {
    this.props.fetchOrgFinanceDetails(this.props.match?.params?.id);
  };

  fetchOrgDocuments = () => {
    this.props.fetchOrgDocuments(this.props.match?.params?.id);
  };

  componentDidUpdate(prevProps) {
    if (!this.props.orgUpdateFinanceDetailsProgress && prevProps.orgUpdateFinanceDetailsProgress) {
      this.props.showSnackBar("finance details updated successfully", "success", 3000);
      this.fetchOrgFinanceDetails();
    }

    if (!this.props.orgFetchDocumentsProgress && prevProps.orgFetchDocumentsProgress) {
      this.props.activeContractDetailsDocument("undefined");
      if (this.props.orgFetchDocuments && this.props.orgFetchDocuments.length > 0) {
        const docUrl = this.props.orgFetchDocuments[this.props.orgFetchDocuments.length - 1].Title;
        this.setState({
          currentDoc: docUrl,
        }, () => {
          this.props.activeContractDetailsDocument(docUrl);
        });
      }
      this.initializeDocuments()
    }

    if (!this.props.addOrgDocumentProgress && prevProps.addOrgDocumentProgress) {
      this.props.showSnackBar("finance details updated successfully", "success", 3000);
      this.fetchOrgDocuments();
      this.fetchOrgFinanceDetails();
      this.props.hideAddOrgProductChampionsDialog();
      this.props.hideDocumentDetailDialog();
    }

    if (!this.props.updateContractFoldersProgress && prevProps.updateContractFoldersProgress) {
      this.props.showSnackBar("Folder added successfully", "success", 3000);
    }

    if (!this.props.updateOrgDocumentProgress && prevProps.updateOrgDocumentProgress) {
      this.props.showSnackBar("finance details updated successfully", "success", 3000);
      this.fetchOrgDocuments();
      this.fetchOrgFinanceDetails();
    }

    if (!this.props.addContractDetailsProg && prevProps.addContractDetailsProg) {
      this.setState({ currentDoc: "" }, () => {
        this.fetchOrgFinanceDetails();
        setTimeout(() => {
          this.fetchOrgDocuments();
        }, 100);
      });
    }

    if (!this.props.deleteOrgDocumentProgress && prevProps.deleteOrgDocumentProgress) {
      this.props.showSnackBar("document deleted successfully", "success", 3000);
      this.fetchOrgDocuments();
      this.fetchOrgFinanceDetails();
    }


    if (!this.props.fetchDocTypesProgress && prevProps.fetchDocTypesProgress) {
      if (this.props.fetchDocTypeError === null) {
        // console.log(this.props.documentTypes, 'documentTypes')
      }
    }

    if (!this.props.attachDocumentProgress && prevProps.attachDocumentProgress) {
      if (this.props.attachDocumentError === null) {
        this.setState({
          activeRowIndex: null
        })
        this.fetchOrgDocuments();
        this.props.showSnackBar("Document Updated Successfully", "success", 3000);
      }
    }
  }

  updateData = (id, value) => {
    const data = {
      numberOfLicenseProcured: parseInt(value.NumberOfLicenseProcured),
      costPerLicense: parseFloat(value.CostPerLicense),
      totalCost: parseFloat(value.TotalCost),
      costPerLicenseFrequency: value.CostPerLicenseFrequency,
      totalCostFrequency: value.TotalCostFrequency,
      startDate: value.StartDate,
      endDate: value.EndDate,
      renewalDate: value.RenewalDate,
    };
    this.props.updateOrgFinanceDetails(id, data);
  };

  saveNoOfLincense = () => {
    if (this.state.noOfLicenses) {
      this.setState({ isNoOfLicenses: false });
      this.updateData(this.props.match?.params?.id, { ...this.props.orgFinanceDetails, NumberOfLicenseProcured: this.state.noOfLicenses });
    } else {
      this.props.showSnackBar("Please enter value", "error", 3000);
    }
  };

  saveCostPerLincense = () => {
    if (this.state.costPerlicense) {
      this.setState({ isCostPerLinceses: false });
      this.updateData(this.props.match?.params?.id, {
        ...this.props.orgFinanceDetails,
        CostPerLicense: this.state.costPerlicense,
        CostPerLicenseFrequency: this.state.costPerLincesesFrequency,
      });
    } else {
      this.props.showSnackBar("Please enter value", "error", 3000);
    }
  };

  saveTotalCost = () => {
    if (this.state.totalCost) {
      this.setState({ isTotalCost: false });
      this.updateData(this.props.match?.params?.id, {
        ...this.props.orgFinanceDetails,
        TotalCost: this.state.totalCost,
        TotalCostFrequency: this.state.totalCostFrequency,
      });
    } else {
      this.props.showSnackBar("Please enter value", "error", 3000);
    }
  };

  deleteContracts = (item, index) => {
    if (item.Title === this.state.currentDoc) {
      this.setState({ currentDoc: "" });
    }
    this.props.deleteOrgDocument(this.props.match?.params?.id, { documentIds: [item.ID] });
  };

  onFileChange = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var formData = new FormData();
      if (file) {
        var reader = new FileReader();
        reader.onloadend = () => this.setState({ imgSrc: reader.result });
        reader.readAsDataURL(file);
      }
      formData.append("folder", `/orgProduct/contractsAndAgreements/${this.props.match?.params?.id}`);
      const timestamp = new Date().getTime();
      formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf(".") + 1)}`);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;

      axios
        .post(url, formData, {
          headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          document.getElementById("UploadContractFile").value = "";
          const options = {
            step: 1,
            head: "Contracts, Agreements & POs",
            filePath: response?.data?.filepath,
            title: `contractsAndAgreements${timestamp}-${file.name}`,
            nextBtnText: "Next",
            cancelBtnText: "Cancel",
            productName: this.props.productName,
          };
          this.props.showAddOrgProductContractDetailsDialog(options);
        })
        .catch((err) => {
          if (err.response) {
            this.props.showSnackBar(err.response.data.error, "error", 3000);
          } else if (err.request) {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          } else {
            this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
          }
        });
    }
  };

  onLink = () => {
    const options = {
      head: "Contracts, Agreements & POs",
      type: "documents",
      placeholder: "Add Name",
    };
    // this.props.showAddOrgProductChampionsDialog(options);
    this.props.showDocumentDetailDialog(options);
  };

  dateStartChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  saveStartDate = () => {
    if (this.state.startDate) {
      this.setState({ isStartDate: false });
      this.updateData(this.props.match?.params?.id, {
        ...this.props.orgFinanceDetails,
        StartDate: this.state.startDate,
      });
    } else {
      this.props.showSnackBar("Please enter value", "error", 3000);
    }
  };

  saveRenewDate = () => {
    if (this.state.renewDate) {
      this.setState({ isRenewDate: false });
      this.updateData(this.props.match?.params?.id, {
        ...this.props.orgFinanceDetails,
        RenewalDate: this.state.renewDate,
      });
    } else {
      this.props.showSnackBar("Please enter value", "error", 3000);
    }
  };

  saveEndDate = () => {
    if (this.state.endDate) {
      this.setState({ isEndDate: false });
      this.updateData(this.props.match?.params?.id, {
        ...this.props.orgFinanceDetails,
        EndDate: this.state.endDate,
      });
    } else {
      this.props.showSnackBar("Please enter value", "error", 3000);
    }
  };

  dateRenewChange = (date) => {
    this.setState({
      renewDate: date,
    });
  };

  dateEndChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handleShowDetails = (docUrl) => {
    this.setState({ currentDoc: docUrl }, () => {
      this.props.activeContractDetailsDocument(docUrl);
    });
  };

  addAttributesForDocument(item) {
  }

  initializeDocuments = () => {
    const { orgFetchDocuments } = this.props;
    let docs = [];
    if (orgFetchDocuments) {
      docs = orgFetchDocuments.map((o, k) => {
        return {
          ...o,
          isEdit: false,
          Status: o.isActive !== null ? o.isActive ? 'Active' : 'Inactive' : 'Active',
          User: o.Owner !== null && o.Owner !== '' ? {
            label: o.Owner.Name,
            value: o.Owner.ID
          } : '',
          Type: o.DocumentType !== null && o.DocumentType !== '' ? { label: o.DocumentType.name, value: o.DocumentType.id } : ''
        }
      })
    }

    this.setState({
      orgDocuments: docs
    })
  }

  getFileTitle = (fileName) => {
    let fileN = fileName.split("-");
    if (fileN[0].includes("contractsAndAgreements")) {
      fileN.splice(0, 1);
      return fileN.join("-");
    } else {
      return fileName;
    }
  };

  handleEdit = (index) => {
    let { orgDocuments } = this.state;
    let document = orgDocuments[index];
    document.isEdit = true;

    const fileTitleData = getFileAndExtension(this.getFileTitle(document.Title), document.URL)
    document.Title = fileTitleData.title;
    document.Extension = fileTitleData.extension;
    this.setState({
      orgDocuments: orgDocuments
    })
  }

  handleUpdate = (index) => {
    let { orgDocuments } = this.state;
    let document = orgDocuments[index];
    document.isEdit = false;
    let ownerId = 0;
    if (document.User !== undefined && document.User !== null && document.User !== '') {
      ownerId = document.User.value
    }
    let docTypeId = 0;
    if (document.Type !== undefined && document.Type !== null && document.Type !== '') {
      docTypeId = document.Type.value
    }
    const fileName = getFileName(document.Title)
    const ext = document.Extension ? '.' + document.Extension : ''
    const title = fileName + ext;
    let params = {
      title: title,
      ownerId: ownerId,
      documentTypeId: docTypeId,
      isActive: document.Status === 'Active' ? true : false,
    }
    this.setState({
      orgDocuments: orgDocuments
    }, () => {
      this.props.updateOrgDocument(this.props.match?.params?.id, document.ID, params)
    })
  }

  updateLocalValue = (value, field, index) => {
    // console.log(value, 'Valueeeeeeee')
    let { orgDocuments } = this.state;
    let document = orgDocuments[index];
    document[field] = value;
    this.setState({
      orgDocuments: orgDocuments
    })
  }

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      page: 0,
      pageSize: 20,
      query: inputValue
    };
    const url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    // console.log(response)

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  render() {
    const classes = this.props.classes;
    const { userType } = this.props;
    const { orgDocuments, documentStatusOptions, documentUserOptions } = this.state;
    const { documentTypes } = this.props;
    const typeOptions = documentTypes.map(o => {
      return { label: o.name, value: o.id }
    })
    return (
      <div>
        {/* Finance details */}
        <div className={classes.contentSection}>

          {(this.props.orgFinanceDetailsProgress || this.props.orgFetchDocumentsProgress || this.props.fetchContractFoldersProgress) && <div className={classes.loader}><CircularProgress /></div>}
          {!this.props.orgFinanceDetailsProgress && !this.props.orgFetchDocumentsProgress && this.props.sections &&
            this.props.sections.length > 0 &&
            this.props.sections.map((section) => {
              if (section.IsDeletable && section.IsEditable) {
                return <CustomSection key={section.SectionUID} section={section} isEditable={(section.Permission === "write") || (section.Access === 2)} productID={this.props.productDetails.productID} />;
              }
              if (false && section.SectionUID === "add_financial_details" && this.props.hideFlagForUser.add_financial_details) {
                return <AddFinancialDetailsDefault userType={userType} isEditable={(section.Permission === "write") || (section.Access === 2)} sectionName={section.SectionName.toLowerCase().split(" ").join("-")} section={section} />;
              } else if (false && section.SectionUID === "prod_financial_details" && this.props.hideFlagForUser.prod_financial_details) {
                return <FinancialDetails userType={userType} isEditable={(section.Permission === "write") || (section.Access === 2)} sectionName={section.SectionName.toLowerCase().split(" ").join("-")} section={section} />;
              } else if (this.props.hideFlagForUser.prod_contact_documents && section.SectionUID === "prod_contact_documents") {
                return (
                  <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                    {this.props.adminFlag && <CollaboratorPopper sectionId={section.ID} tabId={section.TabID} selectedUsers={section.ExternalUsers} />}
                    <div className={classes.contractHead}>
                      <p className={classnames(classes.head, classes.layeredCardHead)}> Contracts, Agreements & POs</p>
                      {((section.Permission === "write") || (section.Access === 2)) && (
                        <div>
                          <span className={classnames(classes.linkText)} onClick={() => this.setState({ openAddFolderDialog: true })}>
                            <Button className={classes.newUserBtn}>+ Add Folder</Button>
                          </span>
                          <span className={classnames(classes.linkText)} onClick={() => this.onLink()}>
                            <Button className={classes.newUserBtn}>+ Add Details</Button>
                          </span>
                          <Button className={classes.newUserBtn}>
                            <label htmlFor="UploadContractFile" className={classnames(classes.linkText)}>
                              + Add File
                              <input
                                id="UploadContractFile"
                                accept=".png, .jpg, .jpeg,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                className={classes.uploadImage}
                                onChange={this.onFileChange}
                                type="file"
                              />
                            </label>
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className={classes.labelDpa}>
                      <Paper className={classnames(classes.card, classes.paper, this.props.params?.id ? "details-border" : "")}>
                        <FolderBasedTable
                          orgDocuments={orgDocuments || []}
                          updateLocalValue={this.updateLocalValue}
                          typeOptions={typeOptions || []}
                          documentUserOptions={documentUserOptions || []}
                          userSearchProgress={this.props.userSearchProgress}
                          getOptions={this.getOptions}
                          documentStatusOptions={documentStatusOptions || []}
                          handleEdit={this.handleEdit}
                          handleUpdate={this.handleUpdate}
                          deleteContracts={this.deleteContracts}
                          userType={userType}
                          section={section}
                          classes={classes}
                          deleteFolder={(data) => this.props.updateContractFolders(this.props.match?.params?.id, data)}
                          updateFolder={(data) => this.setState({ openAddFolderDialogData: data, openAddFolderDialog: true })}
                          addFileToFolder={(data) => this.setState({ openAddFileToFolderDialogData: data, openAddFileToFolderDialog: true })}
                        />
                      </Paper>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
        <DialogOrgProductContractDetails imgSrc={this.state.imgSrc} />
        <AddDocumentDetail />
        {this.state.openAddFolderDialog && <AddFolderDialog
          isOpen={this.state.openAddFolderDialog}
          data={this.state.openAddFolderDialogData}
          onSubmit={(data) => this.props.updateContractFolders(this.props.match?.params?.id, data)}
          onClose={() => this.setState({ openAddFolderDialog: false, openAddFolderDialogData: null, })}
        />}
        {this.state.openAddFileToFolderDialog && <AddFileToFolderDialog
          isOpen={this.state.openAddFileToFolderDialog}
          data={this.state.openAddFileToFolderDialogData}
          onSubmit={(document, folderId) => this.props.updateOrgDocument(this.props.match?.params?.id, document.ID, { folder: folderId })}
          onClose={() => this.setState({ openAddFileToFolderDialog: false, openAddFileToFolderDialogData: null, })}
        />}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(FinanceTab));
