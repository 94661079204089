import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Tooltip, Paper } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Popover } from '@material-ui/core';
// import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Workflow from "assets/images/workflow/workflow.svg";
// import ClearIcon from "@material-ui/icons/Clear";
import Tabs from '@material-ui/core/Tabs';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import SqureButton from '../../../../../../Common/SqureButton';
import moment from 'moment';
// import SpinnerImg from "assets/images/spinner.gif";
import DownArrow from "assets/images/down-filled-arrow.svg";
import Image from 'Components/Common/image.jsx';
import EditDarkImg from 'assets/images/edit_dark.svg';
import FileDetailsImg from '../../../../../../../assets/images/fileDetails.svg';
import classnames from "classnames";
// import BranchIcon from "assets/images/evaluation/branchIcon2.svg";

import {
    ArrowDropDown as ArrowDropDownIcon,
} from "@material-ui/icons";

import UserWiseProgress from "../Common/WorkflowProgress/UserWiseProgress";
import ComponentTimelineDialog from './ComponentTimelineDialog';
import UserGroup from '../../../../../../Common/UserGroup';

import { showEvaluationMetaDialog, fetchPreEvaluationFeedbackData } from "redux/evaluation/metadata/action"
import { fetchColloborator, addColloborator } from "../../../../../../../redux/evaluation/criteria/action"
import { showEvaluationLinkDialog } from "redux/evaluation/link/action";
import { showPreFeedbackAdminDialog } from "redux/evaluation/feedback/action";
import PreFeedbackAdminDialog from "../../Evaluation/Common/PreFeedbackAdminDialog";

import { showUserWiseWorkflowProgressDialog, showAllUsersWorkflowProgressDialog, showWorkflowComponentTimelineDialog, hideWorkflowComponentTimelineDialog, fetchWorkflowComponentTimeline } from "redux/evaluation/workflow/action";
import { fetchUserWorkflowTimeline, showParallelComponentsDialog } from "redux/workflow/action";


import { showSnackBar } from "redux/snackbar/action";
import ShowParallelComponentsDialog from './ParallelWorkflow/ShowParallelComponentsDialog';
import WorkflowTreeNode from './WorkflowTreeNode';
import { getNewStepper, getFinishComponent } from "util/Common";
import { showRequestDetailsCart, showRequestSummaryCart } from "redux/evaluation/workflow/action";

const connectedProps = (state) => ({
    evaluation: state.evaluationMetaData.evaluation,
    fetchProgress: state.evaluationMetaData.fetchProgress,
    colloborators: state.evaludationCriteria.colloborators,
    isOpen: state.evaluationFeedback.showPreFeedbackAdminDialog,
    userRole: state.authUser.user?.Role,
    user: state.authUser.user,
    preFeedbackData: state.evaluationMetaData.preFeedbackData,
    isUserWiseWorflowProgressDialogOpen: state.evaluationWorkflow.isUserWiseWorflowProgressDialogOpen,
    isAllUsersWorflowProgressDialogOpen: state.evaluationWorkflow.isAllUsersWorflowProgressDialogOpen,
    isOpenComponentTimelineDialog: state.evaluationWorkflow.showWorkflowComponentTimelineDialog,
    userTimeline: state.workflow.userTimeline,
    fetchWokflowUserTimelineProgress: state.workflow.fetchWokflowUserTimelineProgress,
    workflowComponentTimeline: state.evaluationWorkflow.workflowComponentTimeline,

    fetchWorkflowCompoenentTimelineProgress: state.evaluationWorkflow.fetchWorkflowCompoenentTimelineProgress,
    addWorkflowCompoenentTimelineProgress: state.evaluationWorkflow.addWorkflowCompoenentTimelineProgress,
    updateWorkflowCompoenentTimelineProgress: state.evaluationWorkflow.updateWorkflowCompoenentTimelineProgress,
    workflowComponentTimelineError: state.evaluationWorkflow.workflowComponentTimelineError,
    parallelComponentsData: state.workflow.parallelComponentsData,
    isShowParallelDialog: state.workflow.isShowParallelDialog,
    evalInfo: state.evaluationMetaData.evalApprovalInfo,
    // evalApprovalInfo: state.evaluationMetaData.evalApprovalInfo,
});

const connectionActions = {
    showEvaluationMetaDialog: showEvaluationMetaDialog,
    fetchColloborator: fetchColloborator,
    addColloborator: addColloborator,
    showEvaluationLinkDialog: showEvaluationLinkDialog,
    showPreFeedbackAdminDialog: showPreFeedbackAdminDialog,
    fetchPreEvaluationFeedbackData: fetchPreEvaluationFeedbackData,
    showUserWiseWorkflowProgressDialog: showUserWiseWorkflowProgressDialog,
    showAllUsersWorkflowProgressDialog: showAllUsersWorkflowProgressDialog,
    fetchUserWorkflowTimeline: fetchUserWorkflowTimeline,
    showWorkflowComponentTimelineDialog: showWorkflowComponentTimelineDialog,
    fetchWorkflowComponentTimeline: fetchWorkflowComponentTimeline,
    hideWorkflowComponentTimelineDialog: hideWorkflowComponentTimelineDialog,
    showSnackBar: showSnackBar,
    showParallelComponentsDialog: showParallelComponentsDialog,
    showRequestDetailsCart: showRequestDetailsCart,
    showRequestSummaryCart: showRequestSummaryCart
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        // position: 'relative'
        // paddingBottom: theme.spacing(2),
    },
    appBar: {
        background: '#fff',
        padding: theme.spacing(0, 2),
        boxShadow: 'none',
        borderBottom: '2px solid #f1f1f1'
    },
    subTitle: {
        marginTop: 5
    },
    actions: {
        paddingTop: theme.spacing(2)
    },
    evaTabsDiv: {
        // width: '50%',
    },
    evaTabsType3: {
        width: '10px !important',
        minWidth: 'unset',
        marginTop: 7
    },
    evalBtn: {
        border: '1px solid #20253A !important'
    },
    workflowProgressBtn: {
        marginRight: 10,
        border: '1px solid #20253A !important'
    },
    preEvalBtn: {
        border: '1px solid red !important',
        marginRight: '10px !important'
    },
    evaTabs: {
        fontSize: theme.spacing(1.7),
        padding: theme.spacing(1, 0),
        // '& :hover':{
        //     color:'#222'
        // }
    },
    tabToggleIcon: {
        transform: 'rotate(180deg)',
    },
    evaDetails: {
        textAlign: 'right',
        paddingRight: theme.spacing(1)
    },
    colbTitle: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
        marginRight: 10,
    },
    tabRoot: {
        flexGrow: 1,
    },
    'MuiTab-root': {
        fontSize: theme.spacing(1),
    },
    activeBlock: {
        borderColor: '#398AF5'
    },
    viewButtons: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'right',
        minHeight: '45px',
        float: 'right'
    },
    colloborator: {
        padding: '10px 0'
    },
    tabContainer: {
        height: '100%',
    },
    workflowTabs: {
        '& [class*="MuiTab-root"]': {
        }
    },
    activeSubMenu: {
        background: '#1f73b7',
        color: '#fff!important',
        '&:hover': {
            color: '#222!important',
        }
    },
    arrowImg: {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
    },
    stepperName: {
        width: '135px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    scheduleTimeline: {
        position: 'absolute',
        left: 0,
        right: 0,
        margin: '0 auto',
        top: 15,
        textAlign: 'center',
        cursor: 'pointer',
        color: '#1f73b7',
        width: 250
    },
    scheduleTimeline1: {
        position: 'absolute',
        left: 0,
        right: 0,
        margin: '0 auto',
        top: 15,
        textAlign: 'center',
        color: '#1f73b7',
        width: 250
    },
    workflowDrawer: {
        width: '25%',
        height: '100vh',
        position: 'absolute',
        right: '0',
        padding: '15px',
        zIndex: '9',
    },
    workflowDrawer1: {
        width: 400,
        right: '0',
        padding: '15px',
        zIndex: '9',
    },
    parentItem: {
        margin: 0,
        display: 'flex',
        gap: '5px',
        cursor: 'pointer',
        fontWeight: '600',
        fontSize: theme.spacing(2)
    },
    parentItemDisable: {
        margin: 0,
        display: 'flex',
        gap: '5px',
        cursor: 'not-allowed',
        fontWeight: '600',
        fontSize: theme.spacing(2)
    },
    activeTree: {
        // background: '#f7f7f7',
        backgroundColor: '#398AF5',
        border: '1px solid #398AF5',
        color: '#fff',
        padding: theme.spacing(0.2, 2),
        borderRadius: theme.spacing(1.25),
    },
    antTree: {
        // padding: theme.spacing(2, 2),
        border: '1px solid #d5d5d5',
        borderRadius: theme.spacing(1.25),
        fontSize: theme.spacing(2),
        marginBottom: theme.spacing(2),
        position: 'relative',
        "&:before": {
            position: 'absolute',
            left: 0,
            top: 0,
            height: theme.spacing(7),
            display: 'block',
            width: theme.spacing(0.4),
            content: '""',
        }
    },
    arrowIcon: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        zIndex: 999
    },
    titleReq: {
        margin: 0,
        cursor: 'pointer',
        display: 'flex',
        gap: '5px',
        fontWeight: '600',
        fontSize: theme.spacing(2),
        padding: theme.spacing(2)
    },
    titleReqDisabled: {
        margin: 0,
        cursor: 'not-allowed',
        display: 'flex',
        gap: '5px',
        fontWeight: '600',
        fontSize: theme.spacing(2),
        padding: theme.spacing(2)
    },
});

class EvaluationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDownloadModal: false,
            evaluationStep: 2,
            showEvalDetails: false,
            showViewToggleBtn: false,
            fourBlockViewActive: false,
            twoBlockViewActive: false,
            isShowSubMenu: false,
            subMenuEl: null,
            subMenuData: [],
            activeSubMenuItem: '',
            isAddSchedule: true,
            subMenuDataType: 2,
            workflowDrawerOpen: false,
            collapsedComponents: [],
        }

        this.evalStepHandle = this.evalStepHandle.bind(this);
        this.avatarName = this.avatarName.bind(this);
        this.handleCloseSubMenu = this.handleCloseSubMenu.bind(this);
        this.userTimelineStepHandle = this.userTimelineStepHandle.bind(this);
        this.getNewUserTimeline = this.getNewUserTimeline.bind(this);
        this.stepperHandler = this.stepperHandler.bind(this);
    }

    componentDidMount() {
        this.props.fetchPreEvaluationFeedbackData(this.props.match.params.evaluation_id);
        this.props.fetchColloborator(this.props.match.params.evaluation_id);

        if (this.props.isWorkFlow !== undefined && this.props.isWorkFlow === true) {
            if (this.props.isShowEvalDetails !== undefined && this.props.isShowEvalDetails === true) {
                this.setState({
                    showEvalDetails: false
                })
            }
            if (this.props.isShowViewBtns !== undefined && this.props.isShowViewBtns === true) {
                this.setState({
                    showViewToggleBtn: true
                })
            }
            // this.props.fetchUserWorkflowTimeline(this.props.match.params.evaluation_id);
            this.props.fetchWorkflowComponentTimeline(this.props.match.params.evaluation_id, this.props.match.params.component_id)

        } else {
            if (this.props.location.pathname.indexOf('setup') !== -1) {
                this.setState({
                    evaluationStep: 2,
                    showEvalDetails: true,
                })
            } else if (this.props.location.pathname.indexOf('respond/comparison') !== -1) {
                this.setState({
                    evaluationStep: 3,
                    showEvalDetails: false,
                    showViewToggleBtn: true,
                    fourBlockViewActive: true,
                    twoBlockViewActive: false,
                })
            } else if (this.props.location.pathname.indexOf('respond') !== -1) {
                this.setState({
                    evaluationStep: 3,
                    showEvalDetails: false,
                    showViewToggleBtn: true,
                    fourBlockViewActive: false,
                    twoBlockViewActive: true,
                })
            } else if (this.props.location.pathname.indexOf('summary') !== -1) {
                this.setState({
                    evaluationStep: 4,
                    showEvalDetails: false,
                })
            } else if (this.props.location.pathname.indexOf('approval') !== -1) {
                this.setState({
                    evaluationStep: 5,
                    showEvalDetails: true,
                    fourBlockViewActive: true,
                })
            }
        }
        if (this.props.userTimeline) {
            const result = this.getActiveParentComponent(this.props.userTimeline, parseInt(this.props.match.params.component_id));
            if (result) {
                this.setState({ collapsedComponents: [result[0]] });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.fetchWorkflowCompoenentTimelineProgress === false && prevProps.fetchWorkflowCompoenentTimelineProgress === true) {
            if (this.props.workflowComponentTimeline !== null && this.props.workflowComponentTimeline.StartTime !== undefined) {
                this.setState({
                    isAddSchedule: false
                })
            }
        }

        if (this.props.addWorkflowCompoenentTimelineProgress === false && prevProps.addWorkflowCompoenentTimelineProgress === true) {
            if (this.props.workflowComponentTimelineError === null) {
                this.setState({
                    isAddSchedule: false
                }, () => {
                    this.props.hideWorkflowComponentTimelineDialog();
                    this.props.showSnackBar("Schedule Added Successfully.", "success", 3000)
                })
            } else {
                alert('Something Went Wrong.')
            }
        }

        if (this.props.updateWorkflowCompoenentTimelineProgress === false && prevProps.updateWorkflowCompoenentTimelineProgress === true) {
            if (this.props.workflowComponentTimelineError === null) {
                this.setState({
                    isAddSchedule: false
                }, () => {
                    this.props.hideWorkflowComponentTimelineDialog();
                    this.props.showSnackBar("Schedule Added Successfully.", "success", 3000)
                })
            } else {
                alert('Something Went Wrong.')
            }
        }
    }

    getActiveParentComponent(object, search) {
        if (object.ComponentId === search) return [object.ComponentId];
        else if ((object.Children) || Array.isArray(object)) {
            let Children = Array.isArray(object) ? object : object.Children;
            for (let child of Children) {
                let result = this.getActiveParentComponent(child, search);
                if (result) {
                    if (object.ComponentId) result.unshift(object.ComponentId);
                    return result;
                }
            }
        }
    }

    toggleCollapse = (id) => {
        var compTreeColDatas = [...this.state.collapsedComponents];

        if (compTreeColDatas.indexOf(id) === -1) {
            compTreeColDatas.push(id);
        } else {
            compTreeColDatas.splice(compTreeColDatas.indexOf(id), 1);
        }
        this.setState({ collapsedComponents: compTreeColDatas })
    }

    selectDownload = (isModalOpen) => {
        this.setState({ openDownloadModal: isModalOpen });
    };

    avatarName(name) {
        return name.substring(0, 1);
    }

    evalStepHandle(event, newValue) {
        if (newValue === 2 && this.props.evaluation.EvaluationUserStatus >= 3) {
            this.props.history.push(`/app/evaluate/${this.props.evaluation?.ID}/setup`);
        }

        if (newValue === 3 && this.props.evaluation.EvaluationUserStatus >= 3) {
            this.props.history.push(`/app/evaluate/${this.props.evaluation?.ID}/respond`);
        }

        if (newValue === 4 && this.props.evaluation.EvaluationUserStatus >= 4) {
            this.props.history.push(`/app/evaluate/${this.props.evaluation?.ID}/summary`);
        }

        if (newValue === 5 && this.props.evaluation.EvaluationUserStatus >= 4) {
            this.props.history.push(`/app/evaluate/${this.props.evaluation?.ID}/summary`);
        }
        if (newValue === 5 && this.props.evaluation.EvaluationUserStatus >= 4) {
            this.props.history.push(`/app/evaluate/${this.props.evaluation?.ID}/approval`);
        }
    }

    userTimelineStepHandle(event, newValue) {
        this.handleCloseSubMenu()
        const { userTimeline } = this.props;
        var stepData = userTimeline[newValue];
        // console.log(stepData,'SteppppData')
        if (stepData.Type === 1) {
            let pathComponentIds = this.getActiveParallelComponents(0);
            // console.log(pathComponentIds,'pathComponentIds')
            var redirectPath = '';
            if (pathComponentIds.length <= 0) {
                redirectPath = "/app/evaluate/" + stepData.EvaluationId + "/workflow/" + stepData.WorkflowId + "/component/" + stepData.ComponentId;
            } else {
                redirectPath = "/app/evaluate/" + stepData.EvaluationId + "/workflow/" + stepData.WorkflowId + "/component/" + stepData.ComponentId + "?pathComponentIds=" + pathComponentIds.join(',');
            }
            this.props.history.push(redirectPath);
        } else {
            var subMenuData = stepData.Children;
            let wrkComponentId = parseInt(this.props.match.params.component_id);
            var activeSubMenuItem = '';

            if (stepData.Type === 2) {
                subMenuData.forEach(function (citem) {
                    if (citem.ComponentId === wrkComponentId) {
                        activeSubMenuItem = citem.ComponentId;
                    }
                })
            } else if (stepData.Type === 3) {
                activeSubMenuItem = userTimeline[newValue + 1]?.ComponentId;
            }

            this.setState({
                subMenuData: subMenuData,
                subMenuDataType: stepData.Type,
                subMenuEl: event.currentTarget || event.current,
                isShowSubMenu: true,
                activeSubMenuItem: activeSubMenuItem
            })
        }
    }

    getWorkflowActiveComponent() {
        var activeComponent = '';
        const { userTimeline } = this.props;
        let wrkComponentId = parseInt(this.props.match.params.component_id);
        userTimeline.forEach(function (item, itemKey) {
            if (item.Type === 1 || item.Type === 2) {
                if (item.ComponentId === wrkComponentId) {
                    activeComponent = itemKey;
                }
            } else {
                if (item.Children !== undefined && item.Children !== null) {
                    item.Children.forEach(function (citem) {
                        if (citem.ComponentId === wrkComponentId) {
                            activeComponent = itemKey;
                        }
                    })
                }
            }
        })
        return activeComponent;
    }

    getActiveComponentId(stepperDatas) {
        var activeComponent = '';
        let wrkComponentId = parseInt(this.props.match.params.component_id);
        stepperDatas.forEach(function (itm, itemKey) {
            if (itm.ComponentId === wrkComponentId) {
                activeComponent = itemKey;
            }
        })
        return activeComponent;
    }

    stepperHandler(event, newValue) {
        let newStepper = getNewStepper(this.props.userTimeline, parseInt(this.props.match.params.component_id));
        var stepData = newStepper[newValue];
        if (stepData.Type === 'ShowMore') {
            this.setState({
                subMenuDataType: stepData.Type,
                subMenuEl: event.currentTarget || event.current,
                isShowSubMenu: true,
            })
        } else {
            let pathComponentIds = this.getActiveParallelComponents(0);
            // console.log(pathComponentIds,'pathComponentIds')
            var redirectPath = '';
            if (pathComponentIds.length <= 0) {
                redirectPath = "/app/evaluate/" + stepData.EvaluationId + "/workflow/" + stepData.WorkflowId + "/component/" + stepData.ComponentId;
            } else {
                redirectPath = "/app/evaluate/" + stepData.EvaluationId + "/workflow/" + stepData.WorkflowId + "/component/" + stepData.ComponentId + "?pathComponentIds=" + pathComponentIds.join(',');
            }
            this.props.history.push(redirectPath);
        }

    }

    handleCloseSubMenu() {
        this.setState({
            isShowSubMenu: false,
            subMenuEl: null
        })
    }

    redirectToPage = (item) => {
        this.handleCloseSubMenu();
        let pathComponentIds = this.getActiveParallelComponents(0);
        // console.log(pathComponentIds,'pathComponentIds')
        var redirectPath = '';
        if (pathComponentIds.length <= 0) {
            redirectPath = "/app/evaluate/" + item.EvaluationId + "/workflow/" + item.WorkflowId + "/component/" + item.ComponentId;
        } else {
            redirectPath = "/app/evaluate/" + item.EvaluationId + "/workflow/" + item.WorkflowId + "/component/" + item.ComponentId + "?pathComponentIds=" + pathComponentIds.join(',');;
        }
        this.props.history.push(redirectPath);
    }

    getActiveParallelComponents = (tillComponent) => {
        const { userTimeline } = this.props;
        // console.log(userTimeline,'userTimelineee')
        let isCheckNext = true;
        let componentIds = [];
        userTimeline.forEach(function (item, itemKey) {
            if (isCheckNext && item.Type === 3 && item.Children !== undefined && item.Children !== null && item.Children !== '') {
                item.Children.forEach(function (com) {
                    if (com.ComponentId === tillComponent) {
                        isCheckNext = false;
                    }
                })
                if (isCheckNext) {
                    let nextItm = userTimeline[itemKey + 1].ComponentId;
                    // console.log(nextItm,'nextItm')
                    componentIds.push(nextItm)
                }
            }
        })
        if (tillComponent !== 0) {
            componentIds.push(tillComponent)
        }
        return componentIds;
    }

    getNewUserTimeline(item) {
        // console.log(item,'Itemmmmmmmmmm-New User Timeline');
        // console.log(this.state.subMenuData);
        let componentIds = this.getActiveParallelComponents(item.ComponentId);
        // console.log(componentIds,'componentIds');
        this.handleCloseSubMenu();
        this.props.fetchUserWorkflowTimeline(this.props.match.params.evaluation_id, componentIds, this.props.match.params.component_id);
    }

    routeToProjectSummary() {
        let finishComponent = getFinishComponent(this.props.userTimeline);
        if (finishComponent) {
            this.props.history.push(`/app/evaluate/${finishComponent.EvaluationId}/workflow/${finishComponent.WorkflowId}/component/${finishComponent.ComponentId}/workflow_summary`)
        } else {
            this.props.history.push(`/app/evaluate/${this.props.match.params.evaluation_id}/workflow/${this.props.match.params.workflow_id}/component/${this.props.match.params.component_id}/workflow_summary`)
        }
    }

    render() {
        const classes = this.props.classes;
        var activeComponent = '';
        var activeStepper = '';
        if (this.props.isWorkFlow !== undefined && this.props.isWorkFlow === true && this.props.userTimeline !== null) {
            activeComponent = this.getWorkflowActiveComponent();
        }

        // let stepCount = 0;
        let componentId = this.props.match.params.component_id;
        let workflowId = this.props.match.params.workflow_id;
        let evaluationId = this.props.match.params.evaluation_id;

        let newStepper = getNewStepper(this.props.userTimeline, parseInt(this.props.match.params.component_id));
        activeStepper = this.getActiveComponentId(newStepper);



        let isEvaluationFinished = this.props.evaluation?.Status === 4 || this.props.evaluation?.Status === 5 ? true : false;
        // console.log(isEvaluationFinished,'isFinished')

        return <><div className={classes.root}>
            <AppBar className={classes.appBar} position="static" color="default">
                <Grid container justifyContent="space-between">
                    <Grid key={'eb-leftItems'} item >
                        {this.props.isShowCollabrations && <>
                            {!this.props.isSummaryView && <Grid
                                container
                                className={classes.colloborator}
                                alignItems={this.props.colloborators && this.props.colloborators.length ? 'end' : 'center'}
                                direction={this.props.colloborators && this.props.colloborators.length ? 'column' : 'row'}
                            >
                                <Typography variant={"h6"} className={classes.colbTitle}>Collaborators</Typography>

                                <UserGroup
                                    selectedUser={this.props.colloborators}
                                    existingUsers={this.props.colloborators}
                                    evaluationId={this.props.match.params.evaluation_id}
                                    position={'EvaluationBar'}
                                    showNotificationIcon={true}
                                    notificationData={{
                                        type: 'notification.evaluation.colloborator.nudge',
                                    }}
                                    onAdd={(user) => {
                                        var isWatcher = false;
                                        if (user.isWatcher !== undefined && user.isWatcher === true) {
                                            isWatcher = true;
                                        }
                                        this.props.addColloborator(this.props.match.params.evaluation_id, user.ID, isWatcher)
                                    }} users={this.props.colloborators} />
                            </Grid>}
                        </>}
                    </Grid>
                    <Grid key={'eb-centerItems'} item className={classes.evaTabsDiv}>
                        <Grid container alignItems="flex-end" className={classes.tabContainer}>
                            {this.props.isShowSchedule && !this.state.isAddSchedule && this.props.workflowComponentTimeline !== null && this.props.workflowComponentTimeline.StartTime !== null &&
                                <p className={this.props.userRole === "OrgAdmin" || this.props.evaluation?.UserID === this.props.user?.ID ? classes.scheduleTimeline : classes.scheduleTimeline1}
                                    onClick={() => {
                                        if (this.props.userRole === "OrgAdmin" || this.props.evaluation?.UserID === this.props.user?.ID) {
                                            this.props.showWorkflowComponentTimelineDialog();
                                        }
                                    }}
                                >
                                    {moment(this.props.workflowComponentTimeline.StartTime).format("MMM Do YYYY")} - {moment(this.props.workflowComponentTimeline.EndTime).format("MMM Do YYYY")}
                                </p>
                            }

                            {this.props.isShowSchedule && this.state.isAddSchedule && this.props.isWorkFlow !== undefined && this.props.isWorkFlow === true && (this.props.userRole === "OrgAdmin" || this.props.evaluation?.UserID === this.props.user?.ID) &&
                                <p className={classes.scheduleTimeline}
                                    onClick={() => {
                                        this.props.showWorkflowComponentTimelineDialog();
                                    }}>
                                    +Add schedule
                                </p>
                            }

                            {(this.props.isWorkFlow === undefined || this.props.isWorkFlow === false) && this.props.evaluation !== null && !this.props.isSummaryView && <Tabs
                                value={this.state.evaluationStep}
                                onChange={this.evalStepHandle}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab value={2} isLast={false} disabled={this.props.evaluation.EvaluationUserStatus >= 2 ? false : true} className={classes.evaTabs} label="1. Criteria Selection" />
                                <Tab value={3} isLast={false} disabled={this.props.evaluation.EvaluationUserStatus >= 3 ? false : true} className={classes.evaTabs} label="2. Evaluation" />
                                <Tab value={4} isLast={false} disabled={this.props.evaluation.EvaluationUserStatus >= 4 ? false : true} className={classes.evaTabs} label="3. Summary" />
                                <Tab value={5} isLast={true} disabled={this.props.evaluation.EvaluationUserStatus >= 4 ? false : true} className={classes.evaTabs} label="4. Approval" />
                            </Tabs>}

                            {!isEvaluationFinished && this.props.isShowStepper && <Tabs
                                value={activeStepper}
                                onChange={this.stepperHandler}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                className={classes.workflowTabs}
                                centered
                            >

                                {newStepper !== null && newStepper.map((item, z) => {
                                    let classDefine = classes.evaTabs;
                                    var label = <span className={classes.stepperName}>{item.Label}</span>;
                                    if (item.Type === 'ShowMore') {
                                        label = <span className={classes.stepperName}>{item.Label}<ArrowDropDownIcon className={classes.arrowImg} /> </span>;
                                    }
                                    return <Tab index={z} isLast={false} disabled={item.isDisable} className={classDefine} label={label} />
                                })}


                            </Tabs>}

                            <Popover
                                open={this.state.isShowSubMenu}
                                anchorEl={this.state.subMenuEl}
                                onClose={this.handleCloseSubMenu}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >

                                <Paper className={classes.workflowDrawer1} open={this.state.workflowDrawerOpen} >
                                    {this.props.userTimeline && this.props.userTimeline.map((item, index) => {
                                        let activeCls = '';
                                        let isCollapsed = true;
                                        if (activeComponent === index) {
                                            activeCls = classes.activeTree;
                                        }

                                        if (this.state.collapsedComponents.indexOf(item.ComponentId) > -1) {
                                            isCollapsed = false;
                                        }

                                        return <div className={classes.antTree} key={index + item.ComponentId}>
                                            {item.Children && <span className={classes.arrowIcon} onClick={(evt) =>
                                                this.toggleCollapse(item.ComponentId)
                                            }>
                                                <img className={classes.arrowImg} src={DownArrow} alt='Down Arrow' />
                                            </span>}

                                            {!item.Children && <p className={classnames(isEvaluationFinished && item.ComponentType !== 'FINISH' && item.ComponentType !== 'WORKFLOW_SUMMARY' ? classes.titleReqDisabled : classes.titleReq, activeCls)} onClick={() => {
                                                if (isEvaluationFinished && item.ComponentType !== 'FINISH' && item.ComponentType !== 'WORKFLOW_SUMMARY') {
                                                    return;
                                                }
                                                this.userTimelineStepHandle('', index)
                                            }}>
                                                {item.Label}
                                            </p>}

                                            {item.Children && isCollapsed && <p className={classnames(classes.titleReq, activeCls)} onClick={() => {
                                                this.toggleCollapse(item.ComponentId)
                                            }}>
                                                <div className="img"><Image src={Workflow} alt="workflow" /></div> {item.Label}
                                            </p>}
                                            {!isCollapsed && item.Children && <div className={classnames(classes.mainRootItem, activeCls)} >
                                                <WorkflowTreeNode
                                                    key={index}
                                                    itemData={item}
                                                    cls={classes.parentItem}
                                                    disableClass={classes.parentItemDisable}
                                                    selectedItem={activeComponent}
                                                    classes={this.props.classes}
                                                    componentId={componentId}
                                                    workflowId={workflowId}
                                                    evaluationId={evaluationId}
                                                    redirectToPage={this.redirectToPage}
                                                    getNewUserTimeline={this.getNewUserTimeline}
                                                    toggleCollapse={this.toggleCollapse}
                                                    parentComp={true}
                                                    isEvaluationFinished={isEvaluationFinished}
                                                />
                                            </div>}
                                        </div>
                                    })}
                                </Paper>
                            </Popover>

                        </Grid>
                    </Grid>

                    <Grid key={'eb-rightItems'} item className={classes.evaDetails}>
                        <Grid container alignItems="center" justify="flex-end" className={classes.tabContainer}>
                            {this.props.userRole === "OrgAdmin" && this.props.preFeedbackData !== null && this.state.showEvalDetails && <SqureButton style={{ marginRight: 10 }} className={classes.preEvalBtn} variant="outlined" startIcon={
                                !this.props.evaluateView ? <Image src={FileDetailsImg} alt="Edit Dark" /> : ''
                            } onClick={() => {
                                this.props.showPreFeedbackAdminDialog();
                            }}>Pre-Evaluation Feedback</SqureButton>}

                            {!this.props.isShowFinishSummaryBtn && this.props.isWorkFlow !== undefined && this.props.isWorkFlow === true && <SqureButton style={{ marginRight: 10, paddingLeft: 10, paddingRight: 10 }} className={classes.workflowProgressBtn} variant="outlined" onClick={() => {
                                this.props.showUserWiseWorkflowProgressDialog();
                            }}> Project Progress </SqureButton>}

                            {this.props.isShowProjectProgress && this.props.isWorkFlow !== undefined && this.props.isWorkFlow === true && <SqureButton style={{ marginRight: 10, paddingLeft: 10, paddingRight: 10 }} className={classes.workflowProgressBtn} variant="outlined" onClick={() => {
                                this.props.showUserWiseWorkflowProgressDialog();
                            }}> Project Progress </SqureButton>}

                            {this.props.isShowFinishSummaryBtn && this.props.isWorkFlow !== undefined && this.props.isWorkFlow === true && <SqureButton style={{ marginRight: 10, paddingLeft: 10, paddingRight: 10 }} className={classes.workflowProgressBtn} variant="outlined" onClick={() => {
                                //  this.routeToProjectSummary()
                                this.props.showRequestSummaryCart();
                            }}> Project Summary </SqureButton>}

                            {this.state.showEvalDetails && <SqureButton className={classes.evalBtn} variant="outlined" startIcon={
                                !this.props.evaluateView ? <Image src={EditDarkImg} alt="Edit Dark" /> : ''
                            } onClick={() => {
                                this.props.showEvaluationMetaDialog(this.props.evaluation.ID, true)
                            }}> {this.props.evaluateView || this.props.evaluateNeverReview ? 'Review' : 'Evaluation'} details</SqureButton>}



                            {this.props.userRole === "OrgAdmin" && (this.state.showViewToggleBtn || (this.props.isShowSummaryViewBtn !== undefined && this.props.isShowSummaryViewBtn !== true)) &&
                                <div className={classes.viewButtons}>
                                    <SqureButton variant="outlined" style={{ marginRight: 10, paddingLeft: 10, paddingRight: 10 }} onClick={() => {
                                        if (this.props.isSummaryView) {
                                            if (this.props.isWorkFlow !== undefined && this.props.isWorkFlow === true) {
                                                // this.props.history.push(`/app/evaluate/${this.props.match?.params?.evaluation_id}/workflow/interim`);
                                                this.props.history.replace("/app/evaluate/" + this.props.match.params.evaluation_id + "/workflow/" + this.props.match.params.workflow_id + "/component/" + this.props.match.params.component_id);
                                            } else {
                                                this.props.history.push(`/app/evaluate/${this.props.match?.params?.evaluation_id}/respond`);
                                            }
                                        } else {
                                            if (this.props.isWorkFlow !== undefined && this.props.isWorkFlow === true) {
                                                // this.props.history.push(`/app/evaluate/${this.props.match?.params?.evaluation_id}/respond/detailed?isWorkflow=true`);
                                                // this.props.history.push(`/app/evaluate/${this.props.match?.params?.evaluation_id}/respond/detailed`);
                                                this.props.history.replace("/app/evaluate/" + this.props.match.params.evaluation_id + "/workflow/" + this.props.match.params.workflow_id + "/component/" + this.props.match.params.component_id + '/respond/detailed?isWorkflow=true');

                                            } else {
                                                this.props.history.push(`/app/evaluate/${this.props.match?.params?.evaluation_id}/respond/detailed`);
                                            }
                                        }
                                    }}>
                                        {this.props.isSummaryView ? 'Focussed View' : 'Detailed View'}
                                    </SqureButton>
                                </div>
                            }

                            {false && this.props.isShowConfigureBtn && <SqureButton style={{ marginRight: 10, paddingLeft: 10, paddingRight: 10 }} className={classes.workflowProgressBtn} variant="outlined" onClick={() => {
                                this.props.history.push(`/app/evaluate/${this.props.evaluation?.ID || this.props.match.params.evaluation_id}/workflow/${this.props.evaluation?.WorkflowID || this.props.match.params.workflow_id}/configure`)
                            }}> Configure </SqureButton>}

                            <IconButton style={{ zIndex: 10, margin: 3, padding: 9 }} onClick={() => {
                                this.props.showRequestDetailsCart(this.props.match?.params?.evaluation_id);
                            }}>
                                <Tooltip title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Request Details</span>} arrow><InfoIcon style={{ color: "white", borderRadius: "20px", background: "#3c3c3c" }} /></Tooltip>
                            </IconButton>

                        </Grid>
                    </Grid>
                </Grid>
            </AppBar>
            {this.props.isOpen && <PreFeedbackAdminDialog evaluationId={this.props.match?.params?.evaluation_id} />}
            {this.props.isUserWiseWorflowProgressDialogOpen && <UserWiseProgress />}
            {this.props.isOpenComponentTimelineDialog && <ComponentTimelineDialog isAddSchedule={this.state.isAddSchedule} />}
            {this.props.isShowParallelDialog && <ShowParallelComponentsDialog />}
        </div>

            <div style={{ position: 'relative' }}>
                {this.state.workflowDrawerOpen && <Paper className={classes.workflowDrawer} open={this.state.workflowDrawerOpen} >
                    {this.props.userTimeline && this.props.userTimeline.map((item, index) => {
                        let activeCls = '';
                        let isCollapsed = true;
                        if (activeComponent === index) {
                            activeCls = classes.activeTree;
                        }

                        if (this.state.collapsedComponents.indexOf(item.ComponentId) > -1) {
                            isCollapsed = false;
                        }

                        return <div className={classes.antTree} key={index + item.ComponentId}>
                            {item.Children && <span className={classes.arrowIcon} onClick={(evt) =>
                                this.toggleCollapse(item.ComponentId)
                            }>
                                <img className={classes.arrowImg} src={DownArrow} alt='Down Arrow' />
                            </span>}
                            {!item.Children && <p className={classnames(classes.titleReq, activeCls)} onClick={() => {
                                this.userTimelineStepHandle('', index)
                            }}>
                                {item.Label}
                            </p>}

                            {item.Children && isCollapsed && <p className={classnames(classes.titleReq, activeCls)} onClick={() => {
                                this.toggleCollapse(item.ComponentId)
                            }}>
                                <div className="img"><Image src={Workflow} alt="workflow" /></div> {item.Label}
                            </p>}
                            {!isCollapsed && item.Children && <div className={classnames(classes.mainRootItem, activeCls)} >
                                <WorkflowTreeNode
                                    key={index}
                                    itemData={item}
                                    cls={classes.parentItem}
                                    selectedItem={activeComponent}
                                    classes={this.props.classes}
                                    componentId={componentId}
                                    workflowId={workflowId}
                                    evaluationId={evaluationId}
                                    redirectToPage={this.redirectToPage}
                                    getNewUserTimeline={this.getNewUserTimeline}
                                    toggleCollapse={this.toggleCollapse}
                                    parentComp={true}
                                />
                            </div>}
                        </div>
                    })}
                </Paper>}
            </div>
        </>
    }
}

EvaluationBar.defaultProps = {
    isSummaryView: false,
    isShowFinishSummaryBtn: false,
    isShowConfigureBtn: true,
    isShowSchedule: true,
    isShowStepper: true,
    isShowCollabrations: false,
    isShowProjectProgress: false
};

export default connector(compose(
    withRouter,
    withStyles(styles)
)(EvaluationBar));