import { SHOW_PRODUCT_MANUALLY_DIALOG, HIDE_PRODUCT_MANUALLY_DIALOG } from "./action";

const initState   =     {
    products:[],
    total: 0,
    addProductInitQuery: null,
    addProductManuallyDialogOpen: false,
}

export  function productsSettingsReducer(state=initState,action){
    switch(action.type){
        case SHOW_PRODUCT_MANUALLY_DIALOG:
            let query = {}
            if(action.payload !== null){
                query.name = action.payload.name
            }
            return {
                ...state,
                addProductManuallyDialogOpen: true,
                addProductInitQuery: query
            };

        case HIDE_PRODUCT_MANUALLY_DIALOG:
            return {
                ...state,
                addProductManuallyDialogOpen: false
            };
        default:
            return state;
    }
}  