import { Types } from "../multichoice/types";
import {
    SHOW_EVALUATION_CRITERIA_ADD_DIALOG, HIDE_EVALUATION_CRITERIA_ADD_DIALOG,
    SHOW_EVALUATION_CRITERIA_UPDATE_DIALOG, HIDE_EVALUATION_CRITERIA_UPDATE_DIALOG,
    SHOW_EVALUATION_REQUIREMENT_ADD_DIALOG, HIDE_EVALUATION_REQUIREMENT_ADD_DIALOG,
    START_EVALUATION_CRITERIA_ADD, END_EVALUATION_CRITERIA_ADD,
    START_EVALUATION_REQUIREMENT_ADD, END_EVALUATION_REQUIREMENT_ADD,
    START_EVALUATION_CRITERIA_FETCH, END_EVALUATION_CRITERIA_FETCH,
    START_EVALUATION_REQUIREMENT_RESPONSE_ADD,
    EVALUATION_CRITERIA_EXPAND,
    EVALUATION_REQUIREMENT_EXPAND,
    END_EVALUATION_REQUIREMENT_RESPONSE_ADD,
    START_EVALUATION_RESPONSE_FETCH, END_EVALUATION_RESPONSE_FETCH,
    SHOW_EVALUATION_REQUIREMENT_EDIT_DIALOG, HIDE_EVALUATION_REQUIREMENT_EDIT_DIALOG,
    START_EVALUATION_REQUIREMENT_EDIT, END_EVALUATION_REQUIREMENT_EDIT,
    SHOW_EVALUATION_CRITERIA_EDIT_DIALOG, HIDE_EVALUATION_CRITERIA_EDIT_DIALOG,
    START_EVALUATION_CRITERIA_EDIT, END_EVALUATION_CRITERIA_EDIT,
    START_EVALUATION_CRITERIA_DELETE, END_EVALUATION_CRITERIA_DELETE,
    START_EVALUATION_REQUIREMENT_DELETE, END_EVALUATION_REQUIREMENT_DELETE,
    START_EVALUATION_SCORE_FETCH, END_EVALUATION_SCORE_FETCH,
    START_EVALUATION_COLLOBORATOR_FETCH, END_EVALUATION_COLLOBORATOR_FETCH,
    START_EVALUATION_COLLOBORATOR_ADD, END_EVALUATION_COLLOBORATOR_ADD,
    START_UPDATE_CRITERIA_USER_ROLE, END_UPDATE_CRITERIA_USER_ROLE,
    START_REMOVE_CRITERIA_MEMBER, END_REMOVE_CRITERIA_MEMBER,
    START_CRITERIA_COLLOBORATOR_ADD, END_CRITERIA_COLLOBORATOR_ADD,
    START_REQUIREMENT_COLLOBORATOR_ADD, END_REQUIREMENT_COLLOBORATOR_ADD,
    START_REQUIREMENT_CRITERIA_IMPORT, END_REQUIREMENT_CRITERIA_IMPORT,
    HIDE_READMORE_DIALOG, SHOW_READMORE_DIALOG,
    SHOW_COLLECTIVE_RESPONSE_DIALOG, HIDE_COLLECTIVE_RESPONSE_DIALOG,
    START_COLLECTIVE_RESPONSES_CHAT, END_COLLECTIVE_RESPONSES_CHAT,
    SHOW_ASK_SELLER_DIALOG, HIDE_ASK_SELLER_DIALOG,
    START_ASK_SELLER_REQUEST, END_ASK_SELLER_REQUEST,
    START_GET_RESPONESE_FOR_THREAD, END_GET_RESPONESE_FOR_THREAD,
    START_FETCH_ASK_SELLER_THREAD, END_FETCH_ASK_SELLER_THREAD,
    START_REPLY_RESPONESE_FOR_THREAD, END_REPLY_RESPONESE_FOR_THREAD,
    START_UPDATE_RESPONESE_FOR_THREAD, END_UPDATE_RESPONESE_FOR_THREAD,
    START_DELETE_RESPONESE_THREAD, END_DELETE_RESPONESE_THREAD,
    START_LAST_EVALUATION_USER_RESPONSE_FETCH, END_LAST_EVALUATION_USER_RESPONSE_FETCH,
    START_REQUIREMENT_TREE_FETCH, END_REQUIREMENT_TREE_FETCH,
    START_REQUIREMENT_RESPONSE_FETCH, END_REQUIREMENT_RESPONSE_FETCH,
    START_REQUIREMENT_RESPONSE_USER_RESPONSE_FETCH, END_REQUIREMENT_RESPONSE_USER_RESPONSE_FETCH,
    SET_SELECTED_REQUIREMENT_FOR_EVALUATION, SET_SELECTED_PRODUCT_FOR_EVALUATION,
    UPDATE_SELLER_COLLECTIVE_RESPONSE_DIALOG,
    START_FETCH_EVALUATION_PRODUCTS_SCORE_AND_PROGRESS, END_FETCH_EVALUATION_PRODUCTS_SCORE_AND_PROGRESS,
    START_REQUIREMENT_RESPONSE_USER_RESPONSE_STATUS_FETCH, END_REQUIREMENT_RESPONSE_USER_RESPONSE_STATUS_FETCH,
    SET_REQUIREMENT_TREE_COLLAPSE_EXPAND,
    START_FETCH_EVALUATION_PRODUCTS_OVERALL_SCORE_AND_PROGRESS, END_FETCH_EVALUATION_PRODUCTS_OVERALL_SCORE_AND_PROGRESS,
    START_REQUIREMENT_ORG_PROGRESS_STATUS_FETCH, END_REQUIREMENT_ORG_PROGRESS_STATUS_FETCH,
    SET_CRITERIA_REQUIREMENT_SHOW_MAP, SET_SELECTED_SUMMARY_VIEW_FOR_EVALUATION,
    SET_SELECTED_PRODUCTS_FOR_SUMMARY_VIEW, SET_REQUIREMENT_FOR_ADMIN_FLAG, TOOGLE_PREVIOUS_INTERACTION_DIALOG,
    START_FETCH_PREVIOUS_INTERACTION, END_FETCH_PREVIOUS_INTERACTION, SET_RESPONSE_TAB,
    START_CRITERIA_COLLOBORATOR_UPDATE, END_CRITERIA_COLLOBORATOR_UPDATE,
    SET_SELECTED_CRITERIAS_FOR_SUMMARY_VIEW,
    SHOW_SCORING_SCALE_DIALOG, HIDE_SCORING_SCALE_DIALOG,
    SET_MOVE_TO_NEXT_REQUIREMENT_FLAG, SET_REQUIREMENT_lOCAL_RESPONSE,
    SET_GO_TO_REQUIREMENT,
    START_FETCH_QUESTIONAIRE_REQUIRED_STATUS, END_FETCH_QUESTIONAIRE_REQUIRED_STATUS,
    SET_SELECTED_REQUIREMENT_TREE_TYPE_FOR_EVALUATION,
    CLEAR_REQUIREMENT_RESPONSES
} from "./action";


const initState = {
    fetchProgress: false,
    showAddDialog: false,
    showUpdateDialog: false,
    criteriaData: null,
    addDialogForApproval: false,
    showRequirementAddDialog: false,
    readMore: {
        isOpen: false,
        title: "",
        description: ""
    },
    criteriaEditDialog: {
        show: false,
        evaluationId: null,
        criteriaId: null,
    },
    requirementEditDialog: {
        show: false,
        evaluationId: null,
        criteriaId: null,
        requirement: null,
    },
    showEditDialog: false,
    criterias: null,
    colloborators: [],
    fetchColloboratorProgress: false,
    evaluationId: null,
    componentId: null,
    productId: null,
    addRequirementCriteriaId: null,
    addCriteriaProgress: false,
    errorCriteriaAdd: null,
    addRequirementProgress: false,
    errorRequirementAdd: null,
    expandedCriteriaId: null,
    expandedRequirementId: null,
    addResponseProgress: false,
    successResponseAdd: null,
    errorResponseAdd: null,
    fetchUserResponseProgress: false,
    userReponses: {},
    editRequirementProgress: false,
    errorRequirementEdit: null,
    editCriteriaProgress: false,
    errorCriteriaEdit: null,
    deleteRequirementProgress: false,
    errorRequirementDelete: null,
    deleteCriteriaProgress: false,
    errorCriteriaDelete: null,
    fetchScoreProgress: false,
    collectiveResponseDialogOpen: false,
    collectiveResponses: [],
    collectiveProduct: [],
    fetchCollectiveResponsesLoading: false,
    scores: {},
    criteriaUserRoleInprogress: null,
    addApproverInprogress: false,
    updateCriteriaUserRoleInprogress: false,
    removeApproverInprogress: false,
    askSellerDialog: {
        show: false,
        evaluationId: null,
        product: null,
        requirement: null,
    },
    askSellerSubmitProgress: false,
    aslSellerSubmitError: null,

    fetchAskSellerResponseInprogress: false,
    fetchAskSelerThread: [],
    fetchAskSelerRequirement: {},
    fetchAskSelerResponse: {},
    fetchAskSellerResponseError: null,

    addReplyResponseThreadInprogress: false,
    addReplyResponseThread: null,
    addReplyResponseThreadError: null,


    updateResponseThreadInprogress: false,
    updateResponseThread: null,
    updateResponseThreadError: null,

    deleteResponseThreadInprogress: false,
    deleteResponseThread: null,
    deleteResponseThreadError: null,

    fetchLastEvaluationUserResponseProgress: false,
    lastEvaUserReponses: null,

    fetchReqTreeUnderProgress: false,
    fetchReqTreeError: null,
    requirementTree: null,

    fetchRequirementResponseProgress: false,
    requirementResponses: null,

    fetchReqResponseUserResProgress: false,
    requirementUserResponses: null,

    fetchEvaluationProductsScoreProgress: false,
    productsScore: null,

    selectedProductId: null,
    selectedRequirementId: null,

    fetchReqResponseUserResStatusProgress: false,
    requirementUserResponsesStatus: null,

    requirementTreeCollapsed: [],

    fetchReqOrgResponseStatusProgress: false,
    orgRequirementProgressStatus: null,

    isCheckTreeConditions: true,
    criteriaReqShowMap: {},
    showRequirementIds: [],

    summaryViewId: 1,
    summaryViewProducts: [],
    summaryViewCriterias: [],

    isRequirementForAdmin: true,
    showPreviousInteractionDialog: false,
    previousInteractions: [],
    previousInteractionsProgress: false,
    previousInteractionsError: null,
    responseTab: 0,

    isShowScoringScaleDialog: false,
    showScoringfor: null,

    addNewEvaColloboratorProgress: false,
    isMoveToNextReq: false,

    isNewChangesFound: null,
    goToRequirement: null,

    fetchQuestionaireStatusProgress: false,
    fetchQuestionaireStatusError: null,
    questionaireStatusData: null,

    selectedReqTreeType: 0,
}

export function evaluationCriteriaReducer(state = initState, action) {
    switch (action.type) {
        case SET_RESPONSE_TAB:
            return {
                ...state,
                responseTab: action.payload.tab
            }
        case START_FETCH_PREVIOUS_INTERACTION:
            return {
                ...state,
                previousInteractions: [],
                previousInteractionsProgress: false,
                previousInteractionsError: null
            }
        case END_FETCH_PREVIOUS_INTERACTION:
            let previousInteractions = []
            if (action.payload.error === null) {
                previousInteractions = action.payload.success.data || []
            }
            return {
                ...state,
                previousInteractions,
                previousInteractionsProgress: false,
                previousInteractionsError: null
            }
        case TOOGLE_PREVIOUS_INTERACTION_DIALOG:
            return {
                ...state,
                showPreviousInteractionDialog: !state.showPreviousInteractionDialog
            }
        case HIDE_READMORE_DIALOG:
            return {
                ...state,
                readMore: {
                    isOpen: false,
                    title: "",
                    description: ""
                }
            };
        case SHOW_READMORE_DIALOG:
            return {
                ...state,
                readMore: {
                    isOpen: true,
                    title: action.payload.title,
                    description: action.payload.description
                }
            };

        case SHOW_EVALUATION_CRITERIA_ADD_DIALOG:
            return {
                ...state,
                showAddDialog: true,
                evaluationId: action.payload.evaluationId,
                componentId: action.payload.componentId,
                productId: action.payload.productId,
                addDialogForApproval: action.payload.forApproval
            };
        case HIDE_EVALUATION_CRITERIA_ADD_DIALOG:
            return {
                ...state,
                showAddDialog: false,
                evaluationId: null,
                componentId: null,
                productId: null,
            };
        case SHOW_EVALUATION_CRITERIA_UPDATE_DIALOG:
            return {
                ...state,
                showUpdateDialog: true,
                criteriaData: action.payload.criteria,
                evaluationId: action.payload.evaluationId,
                componentId: action.payload.componentId,
                productId: action.payload.productId,
            };
        case HIDE_EVALUATION_CRITERIA_UPDATE_DIALOG:
            return {
                ...state,
                showUpdateDialog: false,
                criteriaData: null,
                evaluationId: null,
                componentId: null,
                productId: null,
            };
        case SHOW_EVALUATION_REQUIREMENT_ADD_DIALOG:
            return {
                ...state,
                showRequirementAddDialog: true,
                addRequirementCriteriaId: action.payload.criteriaId,
                evaluationId: action.payload.evaluationId
            };
        case HIDE_EVALUATION_REQUIREMENT_ADD_DIALOG:
            return {
                ...state,
                showRequirementAddDialog: false,
                addRequirementCriteriaId: null,
                evaluationId: null
            };
        case START_EVALUATION_CRITERIA_ADD:
            return {
                ...state,
                addCriteriaProgress: true,
                errorCriteriaAdd: null
            };
        case END_EVALUATION_CRITERIA_ADD:
            var criteria = action.payload.success
            state.criterias.push(criteria)

            return {
                ...state,
                addCriteriaProgress: false,
                errorCriteriaAdd: action.payload.error,
                criterias: [...state.criterias]
            };
        case START_EVALUATION_REQUIREMENT_ADD:
            return {
                ...state,
                addRequirementProgress: true,
                errorRequirementAdd: null
            };
        case END_EVALUATION_REQUIREMENT_ADD:
            var addRCriteria = [];
            if (action.payload.success !== null) {
                var requirement = action.payload.success.response
                var criteriaId = action.payload.success.criteriaId

                state.criterias.forEach((c) => {
                    if (c.id === criteriaId) {
                        c.requirements.push(requirement);
                    }
                    addRCriteria.push({ ...c })
                })
            } else {
                addRCriteria = [...state.criterias];
            }

            return {
                ...state,
                addRequirementProgress: false,
                errorRequirementAdd: action.payload.error,
                criterias: addRCriteria
            };
        case START_EVALUATION_CRITERIA_FETCH:
            return {
                ...state,
                fetchProgress: true,
            }
        case END_EVALUATION_CRITERIA_FETCH:
            return {
                ...state,
                fetchProgress: false,
                criterias: action.payload.success
            }
        case EVALUATION_CRITERIA_EXPAND: {
            return {
                ...state,
                expandedCriteriaId: action.payload
            }
        }

        case EVALUATION_REQUIREMENT_EXPAND: {
            return {
                ...state,
                expandedRequirementId: action.payload
            }
        }
        case START_EVALUATION_REQUIREMENT_RESPONSE_ADD:
            return {
                ...state,
                addResponseProgress: true,
                successResponseAdd: null,
                errorResponseAdd: null
            };

        case END_EVALUATION_REQUIREMENT_RESPONSE_ADD:
            var responses = action.payload.success;
            if (responses !== null && responses.Flow !== null) {
                return {
                    ...state,
                    addResponseProgress: false,
                    successResponseAdd: action.payload.success,
                    errorResponseAdd: action.payload.error,
                    // requirementTree: responses.Flow
                };
            } else {
                return {
                    ...state,
                    addResponseProgress: false,
                    successResponseAdd: action.payload.success,
                    errorResponseAdd: action.payload.error,
                };
            }
        case START_EVALUATION_RESPONSE_FETCH:
            return {
                ...state,
                fetchUserResponseProgress: true,
            };
        case END_EVALUATION_RESPONSE_FETCH:
            var eva_res_data = action.payload.success;
            var productReponse = {}
            if (eva_res_data !== null) {
                eva_res_data.forEach((response) => {
                    if (productReponse[response.ProductID] === undefined) {
                        productReponse[response.ProductID] = {};
                    }
                    productReponse[response.ProductID][response.RequirementID] = response;
                })
            }
            return {
                ...state,
                fetchUserResponseProgress: false,
                userReponses: productReponse,
            };

        case SHOW_EVALUATION_REQUIREMENT_EDIT_DIALOG:
            return {
                ...state,
                requirementEditDialog: {
                    show: true,
                    evaluationId: action.payload.evaluationId,
                    criteriaId: action.payload.criteriaId,
                    requirement: action.payload.requirement,
                }
            }
        case HIDE_EVALUATION_REQUIREMENT_EDIT_DIALOG:
            return {
                ...state,
                requirementEditDialog: {
                    show: false,
                    evaluationId: null,
                    criteriaId: null,
                    requirement: null,
                }
            }
        case START_EVALUATION_REQUIREMENT_EDIT:
            return {
                ...state,
                editRequirementProgress: true,
                errorRequirementEdit: null
            };
        case END_EVALUATION_REQUIREMENT_EDIT:
            var success = action.payload.success;
            var criterias = [...state.criterias]
            if (success !== null) {
                var temp = [];
                criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    _c.requirements = [];
                    criteria.requirements.forEach((requirement) => {
                        if (requirement.id === success.id) {
                            requirement = success;
                        }
                        _c.requirements.push({ ...requirement })
                    })
                    temp.push(_c)
                })
                criterias = temp;
            }
            return {
                ...state,
                editRequirementProgress: false,
                errorRequirementEdit: action.payload.error,
                criterias: criterias
            };
        case Types.END_ADD_REQIREMENT_OPTION:
            var success = action.payload.success;
            var oprequirement_id = action.payload.requirement_id
            var criterias = [...state.criterias]
            if (success !== null) {
                var temp = [];
                criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    _c.requirements = [];
                    criteria.requirements.forEach((requirement) => {
                        if (requirement.id === oprequirement_id) {
                            requirement.options = success;
                        }
                        _c.requirements.push({ ...requirement })
                    })
                    temp.push(_c)
                })
                criterias = temp;
            }
            console.log(success, criterias)
            return {
                ...state,
                editRequirementProgress: false,
                errorRequirementEdit: action.payload.error,
                criterias: criterias
            };
        case SHOW_EVALUATION_CRITERIA_EDIT_DIALOG:
            return {
                ...state,
                criteriaEditDialog: {
                    show: true,
                    evaluationId: action.payload.evaluationId,
                    criteria: action.payload.criteria,
                }
            }
        case HIDE_EVALUATION_CRITERIA_EDIT_DIALOG:
            return {
                ...state,
                criteriaEditDialog: {
                    show: false,
                    evaluationId: null,
                    criteria: null,
                },
            }
        case START_EVALUATION_CRITERIA_EDIT:
            return {
                ...state,
                editCriteriaProgress: true,
                errorCriteriaEdit: null
            };
        case END_EVALUATION_CRITERIA_EDIT:
            var success = action.payload.success;
            var criterias = [...state.criterias]
            if (success !== null) {
                var temp = [];
                criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    if (_c.id === success.id) {
                        _c = success;
                    }
                    temp.push(_c)
                })
                criterias = temp;
            }
            return {
                ...state,
                editCriteriaProgress: false,
                errorCriteriaEdit: action.payload.error,
                criterias: criterias
            };
        case START_EVALUATION_CRITERIA_DELETE:
            return {
                ...state,
                deleteCriteriaProgress: true,
                errorCriteriaDelete: null,
            };
        case END_EVALUATION_CRITERIA_DELETE:
            var criteriaId = action.payload.success;
            var criterias = [...state.criterias]
            if (criteriaId !== null) {
                var temp = [];
                criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    if (_c.id === criteriaId) {
                    } else {
                        temp.push(_c)
                    }

                })
                criterias = temp;
            }
            return {
                ...state,
                criterias: criterias,
                deleteCriteriaProgress: false,
                errorCriteriaDelete: action.payload.error,
            };
        case START_EVALUATION_REQUIREMENT_DELETE:
            return {
                ...state,
                deleteRequirementProgress: true,
                errorRequirementDelete: null,
            };
        case END_EVALUATION_REQUIREMENT_DELETE:
            var requirementId = action.payload.success;
            var criterias = [...state.criterias]
            if (requirementId !== null) {
                var temp = [];
                criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    _c.requirements = [];
                    criteria.requirements.forEach((requirement) => {
                        if (requirement.id === requirementId) {
                        } else {
                            _c.requirements.push({ ...requirement })
                        }

                    })
                    temp.push(_c)
                })
                criterias = temp;
            }
            return {
                ...state,
                criterias: criterias,
                deleteRequirementProgress: false,
                errorRequirementDelete: action.payload.error,
            };
        case START_EVALUATION_SCORE_FETCH:
            return {
                ...state,
                fetchScoreProgress: true,
                scores: {},
            }
        case END_EVALUATION_SCORE_FETCH:
            var iscores = action.payload.success;
            var scores = {};
            if (iscores !== null) {
                iscores.forEach((score) => {
                    if (scores[score.productId] === undefined) {
                        scores[score.productId] = {}
                    }
                    scores[score.productId][score.criteriaId] = score
                })
            }
            return {
                ...state,
                fetchScoreProgress: false,
                scores: scores,
            }
        case START_EVALUATION_COLLOBORATOR_FETCH:
            return {
                ...state,
                colloborators: [],
                fetchColloboratorProgress: true,
            }
        case END_EVALUATION_COLLOBORATOR_FETCH:
            return {
                ...state,
                colloborators: action.payload.success,
                fetchColloboratorProgress: false,
            }

        case START_EVALUATION_COLLOBORATOR_ADD:
            return {
                ...state,
                addNewEvaColloboratorProgress: true
            }
        case END_EVALUATION_COLLOBORATOR_ADD:
            var colloborators = [...state.colloborators];
            const newCollaborator = action.payload.success;
            const isWatcher = action.payload.isWatcher;
            if (action.payload.success !== null) {
                const indexCollab = colloborators.findIndex(obj => (parseInt(obj.ID) === parseInt(newCollaborator.ID)))
                if (indexCollab <= -1) {
                    let collab = action.payload.success;
                    collab.IsWatcher = isWatcher;
                    colloborators.push(collab)
                } else {
                    let collab = colloborators[indexCollab];
                    collab.IsWatcher = isWatcher;
                }
            }
            return {
                ...state,
                colloborators: colloborators,
                addNewEvaColloboratorProgress: false
            }

        case START_CRITERIA_COLLOBORATOR_ADD:
            return {
                ...state,
                addApproverInprogress: true,
                criteriaUserRoleInprogress: true,
            }

        case END_CRITERIA_COLLOBORATOR_ADD:
            var user = action.payload.success;
            var criteriaId = action.payload.criteriaId;
            var isApprover = action.payload.isApprover;
            var criterias = [...state.criterias]
            if (user !== null && criteriaId !== null) {
                var temp = [];
                criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    if (_c.id === criteriaId) {
                        if (_c.colloborator === undefined) {
                            _c.colloborator = []
                        }
                        if (isApprover) {
                            user.IsApprover = true;
                        } else {
                            user.IsApprover = false;
                        }
                        _c.colloborator.push(user);
                    }
                    temp.push(_c)
                })
                criterias = temp;
            }
            return {
                ...state,
                criterias: criterias,
                addApproverInprogress: false,
                criteriaUserRoleInprogress: false,
            }

        case START_CRITERIA_COLLOBORATOR_UPDATE:
            return {
                ...state,
                addApproverInprogress: true,
                criteriaUserRoleInprogress: true,
            }

        case END_CRITERIA_COLLOBORATOR_UPDATE:
            var updatedUser = action.payload.success;
            var criteriaIdN = action.payload.criteriaId;
            var priority = action.payload.priority;
            var userId = action.payload.userId;
            var criterias_all = [...state.criterias]
            if (updatedUser !== null && criteriaIdN !== null) {
                var temp1 = [];
                criterias_all.forEach((criteria) => {
                    var _c = { ...criteria }
                    if (_c.id === criteriaIdN) {
                        _c.colloborator.forEach((collab) => {
                            if (collab.ID === userId) {
                                collab.TemplateCriteriaPriority = priority;
                            }
                        })
                    }
                    temp1.push(_c)
                })
                criterias_all = temp1;
            }
            return {
                ...state,
                criterias: criterias_all,
                addApproverInprogress: false,
                criteriaUserRoleInprogress: false,
            }

        case START_UPDATE_CRITERIA_USER_ROLE:
            return {
                ...state,
                updateCriteriaUserRoleInprogress: true,
                criteriaUserRoleInprogress: true,
            }

        case END_UPDATE_CRITERIA_USER_ROLE:
            var _user = action.payload.success;
            var _criteriaId = action.payload.criteriaId;
            var _isApprover = action.payload.isApprover;
            var _criterias = [...state.criterias]
            if (_user !== null && _criteriaId !== null) {
                var temp = [];
                _criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    if (_c.id === _criteriaId) {
                        if (_c.colloborator === undefined) {
                            _c.colloborator = []
                        }
                        _c.colloborator.map(user => {
                            if (user.ID === _user.ID) {
                                user.IsApprover = _isApprover;
                            }
                        })
                    }
                    temp.push(_c)
                })
                _criterias = temp;
            }
            return {
                ...state,
                criterias: _criterias,
                updateCriteriaUserRoleInprogress: false,
                criteriaUserRoleInprogress: false,
            }

        case START_REMOVE_CRITERIA_MEMBER:
            return {
                ...state,
                removeApproverInprogress: true,
                criteriaUserRoleInprogress: true,
            }
        case END_REMOVE_CRITERIA_MEMBER:
            var criteria_Id = action.payload.criteriaId;
            var user_Id = action.payload.userId;
            var success_ = action.payload.success;
            var criterias_ = [...state.criterias]
            if (user_Id !== null && criteria_Id !== null && success_ && success_.message === 'Success') {
                var temp = [];
                criterias_.forEach((criteria) => {
                    var _c = { ...criteria }
                    if (_c.id === criteria_Id) {
                        if (_c.colloborator === undefined) {
                            _c.colloborator = []
                        }
                        _c.colloborator = _c.colloborator.filter(user => user.ID !== user_Id)
                    }
                    temp.push(_c)
                })
                criterias = temp;
            }
            return {
                ...state,
                criterias: criterias,
                removeApproverInprogress: false,
                criteriaUserRoleInprogress: false,
            }
        case START_REQUIREMENT_COLLOBORATOR_ADD:
            return {
                ...state,
                criteriaUserRoleInprogress: true,
            }
        case END_REQUIREMENT_COLLOBORATOR_ADD:
            var user = action.payload.success;
            var requirementId = action.payload.requirementId;
            var criterias = [...state.criterias]
            if (user !== null && requirementId !== null) {
                var temp = [];
                criterias.forEach((criteria) => {
                    var _c = { ...criteria }
                    _c.requirements = [];
                    criteria.requirements.forEach((requirement) => {
                        if (requirement.id === requirementId) {
                            if (requirement.colloborator === undefined) {
                                requirement.colloborator = []
                            }
                            requirement.colloborator.push(user);
                        }
                        _c.requirements.push({ ...requirement })
                    })
                    temp.push(_c)
                })
                criterias = temp;
            }
            return {
                ...state,
                criterias: criterias,
                criteriaUserRoleInprogress: false,
            };
        case START_REQUIREMENT_CRITERIA_IMPORT:
            return {
                ...state,
                fetchProgress: true
            }
        case END_REQUIREMENT_CRITERIA_IMPORT:
            var criterias = action.payload.success;
            if (criterias == null) {
                criterias = []
            }
            return {
                ...state,
                fetchProgress: false,
                criterias: criterias
            }

        // Collective response
        case SHOW_COLLECTIVE_RESPONSE_DIALOG:
            return {
                ...state,
                collectiveResponseDialogOpen: true,
                criteria: action.payload.criteria || null,
                collectiveRequirement: action.payload.collectiveRequirement || null,
                collectiveProduct: action.payload.collectiveProduct || null,
                evaluation_id: action.payload.evaluation_id || 0,
                collectiveTargetTab: action.payload.targetTab || 0,
            };

        case HIDE_COLLECTIVE_RESPONSE_DIALOG:
            return {
                ...state,
                collectiveResponseDialogOpen: false
            };

        case START_COLLECTIVE_RESPONSES_CHAT:
            return {
                ...state,
                collectiveResponses: [],
                fetchCollectiveResponsesLoading: true,
            }
        case END_COLLECTIVE_RESPONSES_CHAT:
            return {
                ...state,
                collectiveResponses: action.payload.success,
                fetchCollectiveResponsesLoading: false,
            }
        case SHOW_ASK_SELLER_DIALOG:
            return {
                ...state,
                askSellerDialog: {
                    show: true,
                    evaluationId: action.payload.evaluation_id,
                    product: action.payload.product,
                    requirement: action.payload.requirement,
                }
            }
        case HIDE_ASK_SELLER_DIALOG:
            return {
                ...state,
                askSellerDialog: {
                    show: false,
                    evaluationId: null,
                    product: null,
                    requirement: null,
                }
            }
        case START_ASK_SELLER_REQUEST:
            return {
                ...state,
                askSellerSubmitProgress: true,
                aslSellerSubmitError: null
            }
        case END_ASK_SELLER_REQUEST:
            return {
                ...state,
                askSellerSubmitProgress: false,
                aslSellerSubmitError: action.payload.error
            }

        case START_GET_RESPONESE_FOR_THREAD:
            return {
                ...state,
                fetchResponseThreadProgress: true,
                dataResponseThread: null,
                fetchResponseThreadError: null
            }
        case END_GET_RESPONESE_FOR_THREAD:
            var evaluation_id = action.payload.evaluation_id;
            var response_id = action.payload.response_id;
            var success = action.payload.success;
            var error = action.payload.error;
            let threadData = state.dataResponseThread || {}
            if (success) {
                threadData = {
                    ...threadData,
                    [`${evaluation_id}_${response_id}`]: success
                }
            }
            return {
                ...state,
                fetchResponseThreadProgress: false,
                dataResponseThread: threadData,
                fetchResponseThreadError: error
            }

        case START_FETCH_ASK_SELLER_THREAD:
            return {
                ...state,
                fetchAskSellerResponseInprogress: true,
                fetchAskSellerResponseError: null
            }

        case END_FETCH_ASK_SELLER_THREAD:
            var uuid = action.payload.uuid;
            var seller_requirement = {};
            var seller_response = {};
            var thread = [];
            let result = {};

            console.log(action.payload, 'ActionPayload---------------0')
            if (action.payload.success !== null) {
                var requirement_id = action.payload.requirement_id;
                thread = action.payload.success.thread;
                seller_requirement = action.payload.success.requirement;
                seller_response = action.payload.success.response;
                if (thread !== null) {
                    result = state.fetchAskSelerThread;
                    result[`${uuid}_${requirement_id}`] = thread;
                } else {
                    result = {}
                }
            }

            return {
                ...state,
                fetchAskSellerResponseInprogress: false,
                fetchAskSelerThread: result,
                fetchAskSelerRequirement: seller_requirement,
                fetchAskSelerResponse: seller_response,
                fetchAskSellerResponseError: action.payload.error
            }

        case START_REPLY_RESPONESE_FOR_THREAD:
            return {
                ...state,
                addReplyResponseThreadInprogress: true,
                addReplyResponseThread: null
            }
        case END_REPLY_RESPONESE_FOR_THREAD:
            return {
                ...state,
                addReplyResponseThreadInprogress: false,
                addReplyResponseThread: action.payload.success
            }
        case 'CLEAR_REPLY_RESPONESE_THREAD': {
            return {
                ...state,
                addReplyResponseThreadInprogress: false,
                addReplyResponseThread: null
            }
        }

        case START_UPDATE_RESPONESE_FOR_THREAD:
            return {
                ...state,
                updateResponseThreadInprogress: true,
                updateResponseThread: null
            }

        case END_UPDATE_RESPONESE_FOR_THREAD:
            return {
                ...state,
                updateResponseThreadInprogress: false,
                updateResponseThread: action.payload.success
            }

        case 'CLEAR_UPDATE_RESPONESE_THREAD': {
            return {
                ...state,
                updateResponseThreadInprogress: false,
                updateResponseThread: null
            }
        }

        case START_DELETE_RESPONESE_THREAD:
            return {
                ...state,
                deleteResponseThreadInprogress: true,
                deleteResponseThread: null,
                deleteResponseThreadError: null
            }

        case END_DELETE_RESPONESE_THREAD:
            return {
                ...state,
                deleteResponseThreadInprogress: false,
                deleteResponseThread: action.payload.success,
                deleteResponseThreadError: action.payload.error
            }

        case 'CLEAR_DELETE_RESPONESE_THREAD': {
            return {
                ...state,
                deleteResponseThreadInprogress: false,
                deleteResponseThread: null,
                deleteResponseThreadError: null
            }
        }

        case 'CLEAR_ADD_MAIN_RESPONSE': {
            return {
                ...state,
                addResponseProgress: false,
                successResponseAdd: null,
                errorResponseAdd: null
            }
        }

        case START_REQUIREMENT_TREE_FETCH:
            return {
                ...state,
                fetchReqTreeUnderProgress: true,
                fetchReqTreeError: null,
            }

        case END_REQUIREMENT_TREE_FETCH:
            // let requirementTree = []
            // var tree_response = action.payload.success;
            // if (tree_response !== null) {
            //     requirementTree = tree_response
            // }
            return {
                ...state,
                fetchReqTreeUnderProgress: false,
                fetchReqTreeError: action.payload.error,
                requirementTree: action.payload.success,
            }

        case START_LAST_EVALUATION_USER_RESPONSE_FETCH:
            return {
                ...state,
                fetchLastEvaluationUserResponseProgress: true,
                lastEvaUserReponses: null
            };

        case END_LAST_EVALUATION_USER_RESPONSE_FETCH:
            var eva_response = action.payload.success;
            return {
                ...state,
                fetchLastEvaluationUserResponseProgress: false,
                lastEvaUserReponses: eva_response,
            };

        case START_REQUIREMENT_RESPONSE_FETCH:
            return {
                ...state,
                fetchRequirementResponseProgress: true,
                requirementResponses: null
            };

        case END_REQUIREMENT_RESPONSE_FETCH:
            var req_response = action.payload.success;
            return {
                ...state,
                fetchRequirementResponseProgress: false,
                requirementResponses: req_response,
            };

        case START_REQUIREMENT_RESPONSE_USER_RESPONSE_FETCH:
            return {
                ...state,
                fetchReqResponseUserResProgress: true,
                requirementUserResponses: null
            };

        case END_REQUIREMENT_RESPONSE_USER_RESPONSE_FETCH:
            var user_response = action.payload.success;
            return {
                ...state,
                fetchReqResponseUserResProgress: false,
                requirementUserResponses: user_response,
            };

        case START_FETCH_EVALUATION_PRODUCTS_SCORE_AND_PROGRESS:
            return {
                ...state,
                fetchEvaluationProductsScoreProgress: true,
                productsScore: null
            };

        case END_FETCH_EVALUATION_PRODUCTS_SCORE_AND_PROGRESS:
            var pro_score_response = action.payload.success;
            return {
                ...state,
                fetchEvaluationProductsScoreProgress: false,
                productsScore: pro_score_response,
            };

        case SET_SELECTED_REQUIREMENT_FOR_EVALUATION:
            return {
                ...state,
                selectedRequirementId: action.payload.req_id,
                goToRequirement: null,
                isNewChangesFound: null,
            };

        case SET_SELECTED_REQUIREMENT_TREE_TYPE_FOR_EVALUATION:
            return {
                ...state,
                selectedReqTreeType: action.payload.type,
            };

        case SET_SELECTED_PRODUCT_FOR_EVALUATION:
            let selProductId = action.payload.product_id;
            if (selProductId === 0) {
                selProductId = null;
            }
            return {
                ...state,
                selectedProductId: selProductId,
            };

        case SET_REQUIREMENT_TREE_COLLAPSE_EXPAND:
            var reqTreeColDatas = [...state.requirementTreeCollapsed];
            if (action.payload.type === 'EMPTY') {
                reqTreeColDatas = [];
            } else {
                if (reqTreeColDatas.indexOf(action.payload.req_id) === -1) {
                    reqTreeColDatas.push(action.payload.req_id);
                } else {
                    reqTreeColDatas.splice(reqTreeColDatas.indexOf(action.payload.req_id), 1);
                }
            }
            console.log(reqTreeColDatas, 'ReqTreeCollepsed')
            return {
                ...state,
                requirementTreeCollapsed: reqTreeColDatas
            }
        case UPDATE_SELLER_COLLECTIVE_RESPONSE_DIALOG:
            return {
                ...state,
                criteria: action.payload.criteria || null,
                collectiveRequirement: action.payload.collectiveRequirement || null,
                collectiveProduct: action.payload.collectiveProduct || null,
                evaluation_id: action.payload.evaluation_id || 0,
                collectiveTargetTab: action.payload.targetTab || 0,
            };

        case START_REQUIREMENT_RESPONSE_USER_RESPONSE_STATUS_FETCH:
            return {
                ...state,
                fetchReqResponseUserResStatusProgress: true,
                requirementUserResponsesStatus: null
            };

        case END_REQUIREMENT_RESPONSE_USER_RESPONSE_STATUS_FETCH:
            var req_user_response = action.payload.success;
            return {
                ...state,
                fetchReqResponseUserResStatusProgress: false,
                requirementUserResponsesStatus: req_user_response,
            };

        case START_FETCH_EVALUATION_PRODUCTS_OVERALL_SCORE_AND_PROGRESS:
            return {
                ...state,
                fetchEvaluationOverallProductsScoreProgress: true,
                overallProductsScore: null
            };

        case END_FETCH_EVALUATION_PRODUCTS_OVERALL_SCORE_AND_PROGRESS:
            var overall_pro_score_response = action.payload.success;
            return {
                ...state,
                fetchEvaluationOverallProductsScoreProgress: false,
                overallProductsScore: overall_pro_score_response,
            };

        case START_REQUIREMENT_ORG_PROGRESS_STATUS_FETCH:
            return {
                ...state,
                fetchReqOrgResponseStatusProgress: true,
                orgRequirementProgressStatus: null
            };

        case END_REQUIREMENT_ORG_PROGRESS_STATUS_FETCH:
            var orgReqResponseStatus = action.payload.success;
            return {
                ...state,
                fetchReqOrgResponseStatusProgress: false,
                orgRequirementProgressStatus: orgReqResponseStatus,
            };

        case SET_SELECTED_SUMMARY_VIEW_FOR_EVALUATION:
            return {
                ...state,
                summaryViewId: action.payload.viewId,
            }

        case SET_CRITERIA_REQUIREMENT_SHOW_MAP:
            return {
                ...state,
                isCheckTreeConditions: action.payload.isCheckTreeConditions,
                criteriaReqShowMap: action.payload.criteriaReqShowMap,
                showRequirementIds: action.payload.showRequirementIds,
            }

        case SET_SELECTED_PRODUCTS_FOR_SUMMARY_VIEW:
            return {
                ...state,
                summaryViewProducts: action.payload.products,
            }

        case SET_SELECTED_CRITERIAS_FOR_SUMMARY_VIEW:
            return {
                ...state,
                summaryViewCriterias: action.payload.criterias,
            }

        case SET_REQUIREMENT_FOR_ADMIN_FLAG:
            return {
                ...state,
                isRequirementForAdmin: action.payload.state,
            }

        case SHOW_SCORING_SCALE_DIALOG:
            return {
                ...state,
                isShowScoringScaleDialog: true,
                showScoringfor: action.payload.from
            }

        case HIDE_SCORING_SCALE_DIALOG:
            return {
                ...state,
                isShowScoringScaleDialog: false,
                showScoringfor: null
            }

        case SET_MOVE_TO_NEXT_REQUIREMENT_FLAG:
            return {
                ...state,
                isMoveToNextReq: action.payload.flag,
            }

        case SET_REQUIREMENT_lOCAL_RESPONSE:
            return {
                ...state,
                isNewChangesFound: action.payload.data
            }

        case SET_GO_TO_REQUIREMENT:
            return {
                ...state,
                goToRequirement: action.payload.data
            }

        case START_FETCH_QUESTIONAIRE_REQUIRED_STATUS:
            return {
                ...state,
                fetchQuestionaireStatusProgress: true,
                fetchQuestionaireStatusError: null,
                questionaireStatusData: null,
            }

        case END_FETCH_QUESTIONAIRE_REQUIRED_STATUS:
            return {
                ...state,
                fetchQuestionaireStatusProgress: false,
                fetchQuestionaireStatusError: action.payload.error,
                questionaireStatusData: action.payload.success,
            }

        case CLEAR_REQUIREMENT_RESPONSES: {
            return {
                ...state,
                requirementResponses: null,
            }
        }

        default:
            return state;
    }
}