import { Button, Chip, CircularProgress, Grid, MenuItem, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
// assets
import DefaultImage from "assets/images/evaluation/default.svg";
import classnames from "classnames";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import MessageDialog from "Components/Common/MessageDialog";
import ClusterProductsListDialog from "Components/Dialogs/ClusterProductsListDialog";
// import Cart from "Components/Application/Components/Evaluate/Components/Common/Cart.jsx";
import CreateClusterGroupProductDialog from "Components/Dialogs/CreateClusterGroupProductDialog";
import queryString from 'query-string';
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  hideMessageDialog, showMessageDialog
} from "redux/applications/action";
import { updateDrawerStyle } from "redux/drawer/action";
import {
  createEvaluationCart, fetchEvaluationCart, removeProductFromCart
} from "redux/evaluation/cart/action";
import { fetchVendors } from "redux/metric/action";
import { fetchCategorySubCategoryWiseProducts, fetchPersonalizedRecommendationsProducts, fetchProductReaction, setSortFilterOptions } from "redux/product/action";
import { fetchAllCategories } from "redux/product/category/action";
// redux
import { fetchClusterGroups, fetchDeleteProductCluster, showClusterProductsDialog, showCreateClusterGroupDialog, storeClusterGroupProducts } from "redux/product/cluster/action";
import { deleteProduct, fetchOrgProducts, fetchOrgProductsSimilarExisting } from "redux/product/orgProduct/action";
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import { showSnackBar } from "redux/snackbar/action";
import _ from "underscore";
import { isEnabledReview } from "util/Common";
import CommonCss from "commonCss";
import AppBar from "Components/Application/AppBar";
// components
import AddProductManuallyDialog from "Components/Application/Components/Settings/AddProductManuallyDialog";
import ClusterFolder from "Components/Common/ClusterFolder";
import OutlinedInputV2 from "Components/Common/Input/OutlinedInputV2";
import SliderFilter from "Components/Common/SliderFilter";

const connectedProps = (state) => ({
  vendors: state.metric.vendors,
  productFecthProgress: state.orgProduct.orgProductFetchProg,
  products: state.orgProduct.orgProducts,
  totalOrgProducts: state.orgProduct.totalOrgProducts,
  evaluationCart: state.evaluationCart.evaluation,
  user: state.authUser.user,
  userType: state.authUser.user?.Role,
  deleteProductProgress: state.orgProduct.deleteProductProgress,
  // fetch product categories
  fetchAllCategoryProgress: state.productCategory.fetchAllCategoryProgress,
  allCategories: state.productCategory.allCategories,
  fetchAllCategoryError: state.productCategory.fetchAllCategoryError,
  // fetch category wise products
  fetchCategoryProductsProgress: state.products.fetchCategorySubCategoryWiseOrgProductsProgress,
  categoryProducts: state.products.categorySubCategoryWiseOrgProducts,
  categoryProductsTotal: state.products.totalCategorySubCategoryWiseOrgProducts,
  _createClusterGroupDialog: state.clusterProductReducer._createClusterGroupDialog,
  _clusterProductsDialog: state.clusterProductReducer._clusterProductsDialog,
  clusterProducts: state.clusterProductReducer.storeClusterGroupProducts,
  clusterGroups: state.clusterProductReducer.fetchClusterGroupSuccess,
  deleteClusterGroupProductsInprogress: state.clusterProductReducer.deleteClusterGroupProductsInprogress,
  deleteClusterGroupProductsSuccess: state.clusterProductReducer.deleteClusterGroupProductsSuccess,
  addProductManuallyDialogOpen: state.productsSettings.addProductManuallyDialogOpen,
  deleteClusterGroupInprogress: state.clusterProductReducer.deleteClusterGroupInprogress,
  productSortType: state.products.productSortType,
  productSortField: state.products.productSortField,
  personalizedRecommendationsProducts: state.products.personalizedRecommendationsProducts,
  fetchOrgProductsSimilarExistingProgress: state.orgProduct.fetchOrgProductsSimilarExistingProgress,
  orgProductsSimilarExisting: state.orgProduct.orgProductsSimilarExisting,
  searchOrgProductsData: state.orgProduct.searchOrgProductsData,
  orgUser: state.orgUser,
  deleteProductStateProg: state.products.deleteProductStateProg,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  fetchVendors: fetchVendors,
  fetchOrgProducts: fetchOrgProducts,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  fetchEvaluationCart: fetchEvaluationCart,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  showSnackBar: showSnackBar,
  deleteProduct: deleteProduct,
  fetchAllCategories: fetchAllCategories,
  fetchCategorySubCategoryWiseProducts: fetchCategorySubCategoryWiseProducts,
  storeClusterGroupProducts: storeClusterGroupProducts,
  showCreateClusterGroupDialog: showCreateClusterGroupDialog,
  fetchClusterGroups: fetchClusterGroups,
  showClusterProductsDialog: showClusterProductsDialog,
  fetchDeleteProductCluster: fetchDeleteProductCluster,
  setSortFilterOptions: setSortFilterOptions,
  fetchPersonalizedRecommendationsProducts: fetchPersonalizedRecommendationsProducts,
  fetchOrgProductsSimilarExisting: fetchOrgProductsSimilarExisting,
  fetchProductReaction: fetchProductReaction
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  filterSection: {
    border: "1px solid #3C3C3C",
    borderRadius: 5,
    minHeight: 32,
    minWidth: 120,
    color: "#20253A",
    fontSize: 15,
    padding: 0,
  },
  productCount: {
    color: "#282D30",
    fontSize: 14,
    margin: 0,
    flex: 1,
    fontWeight: 600,
  },
  feature_product_title: {
    color: "#282D30",
    fontSize: 18,
    margin: 0,
    marginBottom: "10px",
    flex: 1,
    fontWeight: 600,
  },
  similarProduct: {
    color: "#282D30",
    fontSize: 14,
    flex: 1,
    fontWeight: 600,
    margin: '40px 0px 0px 0px'
  },
  productSection: {
    marginTop: theme.spacing(6.2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  productSectionNew: {},
  searchSection: {
    minWidth: 400,
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  cardAction: {
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    flex: 1,

    "& [class*='CardAction-iconButton']": {
      color: "#74798C",
    },
  },

  primary: {
    ...CommonCss.primary,
    fontWeight: 600,
    lineHeight: 1.334,
    fontSize: '1em'
  },

  secondary: {
    textAlign: "center",
  },
  content: {
    ...CommonCss.content,
    flex: 2,
    display: 'flex',
    alignItems: 'center'
  },
  filter: {
    flex: 1,
    display: "flex",
    justifyContent: 'flex-end'
  },
  recommendedList: {
    maxHeight: "calc(100vh - 230px)",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 100,
    minHeight: "calc(100vh - 230px)",
  },
  emptyText: {
    textAlign: 'center',
    height: 50,
    paddingTop: 20
  },
  addProductLink: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  cartSection: {
    "& [class*=Cart-root], #cartSection": {
      transition: "all 400ms linear",
      padding: 21,
      right: -300,
      "& *": {
        opacity: 0,
        transition: "all 600ms ease-in",
        // whiteSpace: 'nowrap'
      },
    },
    "&.showCart": {
      "& [class*=Cart-root], #cartSection": {
        right: "0 !important",
        "& *": {
          opacity: 1,
        },
      },
    },
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  productCard: {
    position: 'relative'
  },
  closeIcon: {
    position: 'absolute',
    zIndex: 22,
    right: 12,
    top: 10,
    color: '#dcd7d7',
    cursor: 'pointer'
  },
  selectIcon: {
    position: 'absolute',
    zIndex: 22,
    right: 5,
    top: 5,
    color: '#dcd7d7',
    cursor: 'pointer'
  },
  "@global": {
    ".applicationContent": {
      padding: "0 !important",
    },
  },
  categorySidebar: {
    width: '270px',
    padding: '2em 0em 2em 1.4em',
    position: 'fixed',
    background: '#FFFFFF',
    boxShadow: '0px 2px 10px #0000000b',
    minHeight: '100vh'
  },
  productContainer: {
    flex: 1,
    padding: '3em 5em 0em 5em',
    marginLeft: '270px'
  },
  allCategory: {
    height: 'calc(100vh - 50px)', // calc(100vh - 120px)
    overflowY: 'overlay'
  },
  activeCategory: {
    color: "#4A87F8 !important",
    background: "#F2F7FF",
    fontSize: 13,
    marginRight: '1.6em !important',
  },
  innerText: {
    color: "#696B6E",
    padding: theme.spacing(1, 1.5, 1, 1),
    cursor: "pointer",
    display: "block",
    margin: 0,
  },
  sortByDiv: {
    marginLeft: theme.spacing(2)
  },
  recommendedText: {
    fontSize: 12,
    fontWeight: 500,
    color: '#2EB77D',
    textDecoration: 'underline',
    marginTop: 10,
  },
  txtCenter: {
    textAlign: 'center',
    marginBottom: 16
  },
  txtRight: {
    textAlign: 'right'
  },
  stickyBar: {
    position: 'sticky',
    top: 0,
    background: '#f7f7f7',
    borderRadius: 5,
    padding: '10px 1px',
    zIndex: 23,
  },
  label: {
    minHeight: 32,
  }
});


const sortFilterOptions = [{
  label: 'Name',
  key: 'name',
  orders: [{
    key: 'least',
    label: 'A-Z'
  }, {
    key: 'most',
    label: 'Z-A'
  }]
}, {
  label: 'Usage',
  key: 'usage',
  orders: [{
    key: 'least',
    label: 'Least used'
  }, {
    key: 'most',
    label: 'Most used'
  }]
}, {
  label: 'Business Criticality',
  key: 'business_criticality',
  orders: [{
    key: 'least',
    label: 'High to Low'
  }, {
    key: 'most',
    label: 'Low to High'
  }]
}, {
  label: 'Total cost',
  key: 'total_cost',
  orders: [{
    key: 'least',
    label: 'High to Low'
  }, {
    key: 'most',
    label: 'Low to High'
  }]
}, {
  label: 'Cost per license',
  key: 'cost_per_license',
  orders: [{
    key: 'least',
    label: 'High to Low'
  }, {
    key: 'most',
    label: 'Low to High'
  }]
}, {
  label: 'Cost per license frequency',
  key: 'cost_per_license_frequency',
  orders: [{
    key: 'least',
    label: 'Monthly'
  }, {
    key: 'most',
    label: 'Yearly'
  }]
}, {
  label: 'Number of license procured',
  key: 'number_of_license_procured',
  orders: [{
    key: 'least',
    label: 'High to Low'
  }, {
    key: 'most',
    label: 'Low to High'
  }]
}, {
  label: 'Start date',
  key: 'start_date',
  orders: [{
    key: 'least',
    label: 'Newest to Oldest'
  }, {
    key: 'most',
    label: 'Oldest to Newest'
  }]
}, {
  label: 'Renewal date',
  key: 'renewal_date',
  orders: [{
    key: 'least',
    label: 'Newest to Oldest'
  }, {
    key: 'most',
    label: 'Oldest to Newest'
  }]
}, {
  label: 'End date',
  key: 'end_date',
  orders: [{
    key: 'least',
    label: 'Newest to Oldest'
  }, {
    key: 'most',
    label: 'Oldest to Newest'
  }]
}];

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: '',
      page: 0,
      pageSize: 1000,
      query: '',
      productStateProg: '',
      queryParams: queryString.parse(this.props.location.search),
      deleteId: null,
      deleteGroupId: null,
      isCartShow: false,
      activeCategoryId: 0,
      showGroupProduct: false,
      cluster_products: [],
      showActionMenu: null,
      sortSelectedOptions: {
        selectedSortBy: 'name',
        selectedSortType: 'least',
      },
      selectedSortBy: 'name',
      selectedSortType: 'least',
      searchType: {
        'name': 'All',
        'value': 'all'
      },
      filterOptions: [{
        'name': 'All',
        'value': 'all'
      }, {
        'name': 'Product',
        'value': 'products'
      }, {
        'name': 'Department',
        'value': 'departments'
      }, {
        'name': 'Features',
        'value': 'features'
      }, {
        'name': 'Sub-Category',
        'value': 'subcategories'
      }],
      selectedProduct: null,
      lg_01: window.matchMedia('(min-width: 1281px) and (max-width: 1850px)').matches,
      lg_02: window.matchMedia('(min-width: 668px) and (max-width: 767.98px)').matches,
      headerSectionWidth: null,
      stateFilter: []
    }
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  handleActionClick = (event) => {
    this.setState({ showActionMenu: event.currentTarget })
  };

  handleActionClose = () => {
    this.setState({ showActionMenu: null })
  };

  onActionChange = (target) => {
    if (target === 10) {
      this.props.showAddProductManuallyDialog();
    } else if (target === 20) {
      this.props.showClusterProductsDialog(true)
    }
    this.setState({ showActionMenu: null })
  }

  searchData = (e) => {
    let me = this
    this.setState({ query: e.target.value, page: 0 }, () => {
      me.fetchVendorData();
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    var isSelectedCategory = (this.getParameterByName("selectedCategory") !== null);
    if (isSelectedCategory) {
      this.setState({
        activeCategoryId: parseInt(this.state.queryParams.selectedCategory)
      }, () => {
        this.callInitFunctions();
      })
    } else {
      this.callInitFunctions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  callInitFunctions() {
    this.props.fetchPersonalizedRecommendationsProducts()
    this.props.setSortFilterOptions(this.state.selectedSortBy, this.state.selectedSortType);
    this.props.fetchAllCategories(0, 500, '')
    // this.props.fetchClusterGroups();
    this.fetchVendorData()
    setTimeout(
      () => this.props.updateDrawerStyle(false, true),
      500
    );
  }

  componentDidUpdate(prevProps) {
    if (!this.props.deleteProductProgress && prevProps.deleteProductProgress) {
      this.props.showSnackBar('Product deleted successfully', "success", 3000);
      let me = this
      this.setState({ query: '', page: 0 }, () => {
        me.fetchVendorData();
      })
    }

    if (
      (!this.props.productFecthProgress && prevProps.productFecthProgress) ||
      (!this.props.fetchCategoryProductsProgress && prevProps.fetchCategoryProductsProgress) ||
      (!this.props.fetchOrgProductsSimilarExistingProgress && prevProps.fetchOrgProductsSimilarExistingProgress)
    ) {
      let productIDs = []
      const isProductSearchType = this.state.searchType && this.state.searchType?.value === 'products';
      if (!isProductSearchType) {
        if (this.state.activeCategoryId === 0) {
          productIDs = this.props.products || []
        } else {
          productIDs = this.props.categoryProducts || []
        }
      } else {
        productIDs = this.props.orgProductsSimilarExisting || []
      }
      productIDs = productIDs.map(prod => prod.ID).filter(id => id)
      if (productIDs) {
        this.getProductReactions(productIDs);
      }
    }

    if (!this.props.deleteProductStateProg && prevProps.deleteProductStateProg) {
      this.setState({
        productStateProg: false
      })
    }

    if (this.props.deleteProductStateProg && !prevProps.deleteProductStateProg) {
      this.setState({
        productStateProg: false
      })
    }


    if (!this.props.deleteClusterGroupInprogress && prevProps.deleteClusterGroupInprogress) {
      this.props.showSnackBar('Group deleted successfully', "success", 3000);
      let me = this
      this.setState({ query: '', page: 0 }, () => {
        me.fetchVendorData();
      })
    }

    const headerSectionWidth = this.divElement.clientWidth;
    if (this.state.headerSectionWidth !== headerSectionWidth) {
      this.setState({ headerSectionWidth });
    }
  }


  getProductReactions(productIDs) {
    this.props.fetchProductReaction('all_product_blocking', {
      productIDs,
      source: 'all_product_blocking',
      sourceData: 'all_product_blocking'
    })
  }

  fetchVendorData() {
    var additionalParam = {
      search_type: this.state.searchType.value,
      sortBy: [{
        field: this.props.productSortField !== null ? this.props.productSortField : this.state.selectedSortBy,
        type: this.props.productSortType !== null ? this.props.productSortType : this.state.selectedSortType
      }
      ]
    }

    if (this.state.activeCategoryId === 0) {
      this.props.fetchOrgProducts(this.state.page, this.state.pageSize, this.state.query, 'both', additionalParam);
      var me = this;
      setTimeout(() => {
        me.props.fetchEvaluationCart(null);
      }, 1000);
    } else {
      this.props.fetchCategorySubCategoryWiseProducts(this.state.activeCategoryId, 0, this.state.page, this.state.pageSize, this.state.query, additionalParam)
    }
  }

  navigate = (link) => {
    if (link) {
      this.props.history.push(link);
    }
  };

  toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ open: false });
  };

  cardClick = (event, index) => {
    if (this.props.isCardClickable) {
      this.props.onCardClicked(event, index);
    }
  };

  addToCart(item) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(item.ID);
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(item.ID) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, item.ID);
      } else {
        this.props.createEvaluationCart(item.ID, this.props.evaluationCart.ID);
      }
    }
  }

  reviewProduct = (id) => {
    // e.stopPropagation();
    let redirectPath = `/app/products/product-review/${id}/`;
    this.props.history.push(redirectPath);
  }

  surveyProduct = (id) => {
    // e.stopPropagation();
    let redirectPath = `/app/survey/product-survey/${id}`;
    this.props.history.push(redirectPath);
  }

  producDetail = (e, product) => {
    this.props.history.push(`/app/products/org-product-details/${product.ID}`)
  }

  closeIconCard = (e, product) => {
    e.stopPropagation();
    var me = this;
    this.setState({ deleteId: product?.ID }, () => {
      me.props.showMessageDialog();
    })
  }

  closeIconGroupCard = (e, product) => {
    e.stopPropagation();
    var me = this;
    this.setState({ deleteGroupId: product?.id }, () => {
      me.props.showMessageDialog();
    })
  }

  groupClusterProducts = (e, product) => {
    e.stopPropagation()
    let me = this;
    let products = me.props.clusterProducts || [];
    let existProducts = products.filter(o => o.ID === product.ID);
    if (existProducts.length > 0) {
      products = products.filter(o => o.ID !== product.ID);
    } else {
      products.push(product)
    }
    let ids = _.map(products, 'ID');
    this.setState({
      cluster_products: ids
    })
    this.props.storeClusterGroupProducts(products)
  }

  callBack = () => {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteProduct(this.state.deleteId);
    }
    if (this.state.deleteGroupId) {
      this.props.hideMessageDialog();
      this.props.fetchDeleteProductCluster(this.state.deleteGroupId)
    }
  };

  checkProductExistInStore = (id) => {
    let products = this.props.clusterProducts || [];
    let existProducts = products.filter(o => o.ID === id);
    if (existProducts.length) {
      return true;
    } else {
      return false;
    }
  }

  setSearchType = (type) => {
    var searchType = '';
    if (type === 'features') {
      searchType = {
        'name': 'Features',
        'value': 'features'
      }
    } else if (type === 'subcategories') {
      searchType = {
        'name': 'Sub-Category',
        'value': 'subcategories'
      }
    }
    this.setState({
      searchType: searchType,
      page: 0,
    },
      () => {
        this.fetchVendorData();
      }
    )
  }

  updateDimensions = () => {
    let lg_01 = window.matchMedia('(min-width: 1281px) and (max-width: 1850px)').matches;
    this.setState({
      lg_01: lg_01
    });
  };

  filterProduct = (isExisting, ProductStates, isCheck, state) => {
    if (isExisting && this.state.stateFilter.includes('existing')) {
      return false
    }

    let data = this.state.stateFilter
    if (isCheck) {
      data = state
    }
    if (ProductStates && data.length > 0) {
      let rt = true
      ProductStates.every(obj => {
        if (data.includes(obj.StateName)) {
          rt = false
          return false
        } else {
          return true
        }
      })
      return rt
    } else {
      return false
    }
  }

  handleOnClickState(newState) {
    let stateFilter = this.state.stateFilter || []
    if (stateFilter.includes(newState)) {
      stateFilter = stateFilter.filter(o => o !== newState)
    } else {
      stateFilter.push(newState)
    }
    this.setState({
      stateFilter
    })
  }

  isExisting(product) {
    var isExisting = false;
    if (product?.IsExisting) {
      isExisting = true
    } else {
      if (product.ProductStates !== undefined && product.ProductStates !== null) {
        isExisting = product.ProductStates.filter((p) => {
          return p.StateName === "existing"
        }).length > 0;
      }
    }
    return isExisting;
  }
  renderFeatured = (featuredProducts, content, lgGrid, isProduct = false, hasProducts = false) => {
    const classes = this.props.classes
    return (
      <>
        {this.state.query.trim().length === 0 && featuredProducts != null && featuredProducts.length > 0 && <>
          <p className={classes.feature_product_title}>
            Featured Products
          </p>
          <Grid container
            spacing={3}>
            {featuredProducts.map((product, i) => <Grid item lg={lgGrid} md={3} sm={6} xs={12} key={`featured_${product.ID}`}>
              <LayeredCard
                isActionButtons
                title={product?.Name}
                subTitle={product?.CompanyName}
                description={product?.Description}
                requestLink={product?.AccessLink}
                image={product?.ProductLogo === null || product.ProductLogo?.length === 0 ? DefaultImage : product?.ProductLogo}
                toogleCheck={(e) => {
                  this.reviewProduct(product.ID)
                }}
                showCheckbox={true}
                onClick={() => { }}
                numLayer={0}
                id={product.ID}
                productStates={product?.ProductStates}
                onClickState={state => this.handleOnClickState(state)}
                uniqueId={product.ID + '_o_' + i}
                customRoute={'/app/products/org-product-details/'}
                type={'product'}
                IsExisting={this.isExisting(product)}
                source={'SURVEY'}
                selectForSurvey={()=>{
                  this.surveyProduct(product.ID)
                }}
              />
            </Grid>)}
          </Grid>
        </>}
        {((this.state.query.trim().length === 0 &&
          featuredProducts != null &&
          featuredProducts.length > 0) || (isProduct && hasProducts)) && (
            <Grid container>
              <p className={classes.feature_product_title} style={{ marginTop: "20px" }}>
                {content.title}
              </p>
            </Grid>
          )}
      </>
    )
  }

  render() {
    const classes = this.props.classes;
    if (this.props.user == null) {
      return null;
    }
    const filters = [
      { label: "Category", list: [{ label: "Data" }, { label: "Data" }] },
      {
        label: "Pricing Options",
        list: [
          { label: "Free Trial" },
          { label: "Subscription" },
          { label: "Free Plan" },
        ],
      },
      {
        label: "Number of users",
        list: [
          { label: "0-250" },
          { label: "250-1000" },
          { label: "Greater than 1000" },
        ],
      },
      {
        label: "Platform",
        list: [
          { label: "Web" },
          { label: "Mobile" },
          { label: "Mac" },
          { label: "Windows" },
        ],
      },
      {
        label: "Deployment",
        list: [{ label: "Cloud" }, { label: "Self-Hosted" }],
      },
    ];

    const content = {
      ...this.props,
      parentClass: classes,
      title: `${this.props.user?.Organization?.Name}'s Application Catalog`,
      countLabel: "product",
      pluralCountLabel: "products",
      searchPlaceholder: "Search product",
      filters: filters,
      isFilterRequired: true
    };

    var isCart = this.props.evaluationCart !== null;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    let userRole = ["OrgAdmin","OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;
    var showAddProduct = false;
    const EnableAddProductForUserCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableAddProductForUserCheck')
    if (this.props.userType === "OrgUser") {
      if (EnableAddProductForUserCheck) {
        showAddProduct = true;
      }
    } else {
      showAddProduct = true;
    }

    const isProductSearchType = this.state.searchType && this.state.searchType?.value === 'products';
    let orgExistingProduct = []
    if (this.props.searchOrgProductsData && this.state.selectedProduct !== null) {
      orgExistingProduct = this.props.searchOrgProductsData.filter(product => (product.ID === this.state.selectedProduct && product.IsExisting === true))
    }
    const { lg_01 } = this.state;
    const lgGrid = lg_01 ? 3 : 2;

    const isHome = this.props.location.pathname === "/app/home"
    let masterProduct = []
    let masterLoader = false
    let featuredProduct = []
    if (!isProductSearchType) {
      if (this.props.productFecthProgress === true || this.props.fetchCategoryProductsProgress) {
        masterLoader = true
      }

      if (this.state.activeCategoryId === 0) {
        masterProduct = this.props.products
      } else {
        masterProduct = this.props.categoryProducts
      }

      if (masterProduct && this.state.stateFilter.length === 0) {
        masterProduct.every(prod => {
          if (prod?.ProductStates && !this.filterProduct(false, prod?.ProductStates, true, ['featured'])) {
            featuredProduct.push(prod)
          }
          return true
        })
      } else {
        masterProduct = masterProduct.filter(prod => {
          if (!prod?.ID) return false
          const ProductStates = prod?.ProductStates
          if (ProductStates && ProductStates.length > 0) {
            let rt = false
            ProductStates.every(obj => {
              if (this.state.stateFilter.includes(obj.StateName)) {
                rt = true
                return false
              } else {
                return true
              }
            })
            return rt
          } else {
            return false
          }
        })
      }
    } else {
      this.props.orgProductsSimilarExisting.every(prod => {
        if (prod?.ProductStates && !this.filterProduct(false, prod?.ProductStates, true, ['featured'])) {
          featuredProduct.push(prod)
        }
        return true
      })
    }


    return (
      <>
        <div style={{ display: 'flex' }}>
          {/* Categories list */}
          <div className={classes.categorySidebar}>
            <div className={classes.allCategory}>
              {/* All categories */}
              <p className={classnames(classes.innerText, this.state.activeCategoryId === 0 ? classes.activeCategory : '')} onClick={() => {
                let me = this;
                this.setState({ page: 0, activeCategoryId: 0 }, () => me.fetchVendorData())
              }}><b>All</b></p>
              {/* Sub categories */}
              {this.props.allCategories && this.props.allCategories?.Data?.map((category, index) => (
                <p key={index} className={classnames(classes.innerText, this.state.activeCategoryId === category.ID ? classes.activeCategory : '')} onClick={() => {
                  let me = this;
                  let searchType = this.state.searchType
                  if (isProductSearchType) {
                    searchType = {
                      'name': 'All',
                      'value': 'all'
                    }
                  }
                  this.setState({ page: 0, activeCategoryId: category.ID, searchType }, () => { me.fetchVendorData() })
                }}>
                  {category.Name}
                </p>
              ))}
            </div>
          </div>

          {/* products list */}
          <div className={classes.productContainer}>
            <AppBar title={isHome ? "Home" : content.title} withBack={!isHome} />
            <br />
            <div className={classes.stickyBar}>
              <Grid container
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                ref={(divElement) => { this.divElement = divElement }}
                className={classes.productSectionNew}
              >
                <Grid item>
                  {this.props.userType !== "OrgUser" && this.state.activeCategoryId === 0 &&
                    <p className={classes.productCount}>
                      {this.props.totalOrgProducts} {" "}
                      {this.props.totalOrgProducts > 1 ? content.pluralCountLabel : content.countLabel}{" "}
                      found
                    </p>}

                  {this.props.userType !== "OrgUser" && this.state.activeCategoryId > 0 &&
                    <p className={classes.productCount}>
                      {this.props.categoryProductsTotal} {" "}
                      {this.props.categoryProductsTotal > 1 ? content.pluralCountLabel : content.countLabel}{" "}
                      found
                    </p>}
                </Grid>
                <Grid item>
                  <OutlinedInputV2
                    id={"searchProducts"}
                    placeholder={'Search Products'}
                    searchType={this.state.searchType}
                    filterOptions={this.state.filterOptions}
                    isFilterPopover={true}
                    fromPage={'org-products'}
                    showTooltipMsg={'Show Sort Options'}
                    value={this.state.query}
                    sortFilterOptions={sortFilterOptions}
                    onChange={(event) => {
                      var me = this;
                      this.setState({ query: event.target.value, page: 0 }, () => {
                        me.fetchVendorData();
                      });
                    }}
                    onClickOption={e => {
                      this.setState({
                        selectedProduct: e.id
                      }, () => {
                        this.props.fetchOrgProductsSimilarExisting(e.id)
                      })
                    }}
                    isLoadingOption={false}
                    onSearchTypeChange={(value) => {
                      this.setState({
                        searchType: value,
                        page: 0,
                      },
                        () => {
                          if (this.state.searchType?.value !== 'products') {
                            this.fetchVendorData();
                          }
                        }
                      )
                    }}
                    handleSortSubmit={(data) => {
                      this.setState({
                        page: 0,
                      },
                        () => {
                          this.fetchVendorData();
                        }
                      )
                    }}
                    onClearSearch={() => {
                      var me = this;
                      this.setState({ query: '', page: 0 }, () => {
                        me.fetchVendorData();
                      });
                    }}
                  >
                  </OutlinedInputV2>
                </Grid>
                <Grid item >
                  {adminFlag && <>
                    <Button
                      className={classes.newUserBtn}
                      onClick={(e) => this.handleActionClick(e)}
                      aria-controls="simple-menu" aria-haspopup="true">
                      + Add New
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={this.state.showActionMenu}
                      keepMounted
                      open={Boolean(this.state.showActionMenu)}
                      onClose={() => { this.handleActionClose() }}
                    >
                      <MenuItem onClick={() => { this.onActionChange(10) }} value={10}>Product</MenuItem>
                      <MenuItem onClick={() => { this.onActionChange(20) }} value={20}>Products Group</MenuItem>
                    </Menu>
                  </>}
                  {!adminFlag && showAddProduct && <>
                    <Button className={classes.newUserBtn} onClick={() => this.onActionChange(10)}>
                      + Add vendor
                    </Button>
                  </>}
                </Grid>
              </Grid>
              {this.state.stateFilter.length === 0 &&
                this.props.personalizedRecommendationsProducts !== null &&
                this.props.personalizedRecommendationsProducts.length > 0 && (

                  <Link to="/app/products/org-products/user-recommendation">
                    <Typography align="center" className={classes.recommendedText}>
                      You have {this.props.personalizedRecommendationsProducts.length} product recommendations.
                    </Typography>
                  </Link>
                )}
              <Grid container alignItems="center" justify="center" spacing={1} className={classes.label}>
                {this.state.stateFilter.map(state => (
                  <Grid item>
                    <Chip
                      label={state.charAt(0).toUpperCase() + state.slice(1)}
                      color="primary"
                      onDelete={() => this.handleOnClickState(state)}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>

            {this.state.query &&
              !this.props.productFecthProgress &&
              !this.props.fetchCategoryProductsProgress &&
              !isProductSearchType &&
              masterProduct?.length > 0 && (
                <div>
                  <p className={classes.emptyText}>
                    <span>
                      <span>Not finding what you are looking for? Search the </span>
                      <span className={classes.addProductLink} onClick={() => this.setSearchType('features')}>features</span>
                      <span> and </span>
                      <span className={classes.addProductLink} onClick={() => this.setSearchType('subcategories')}>sub-categories.</span>
                      <span> or in the </span>
                      <span
                        className={classes.addProductLink}
                        onClick={() => {
                          this.props.history.push(`/app/products/all-products?s=${this.state.query}`)
                        }}
                      >
                        Expent's product directory
                      </span>
                      <span> for {this.state.query}.</span>
                    </span>
                  </p>
                </div>
              )}
            {/* All Products */}
            {!isProductSearchType && <div className={classes.recommendedList} id="recommendedList">
              <InfiniteScroll
                scrollableTarget="recommendedList"
                style={{ display: "flex", flexDirection: "column", overflow: "hidden", marginRight: 20 }} //To put endMessage and loader to the top.
                dataLength={this.state.activeCategoryId === 0
                  ? this.props.products.length
                  : this.props.categoryProducts.length} //This is important field to render the next data
                next={() => {
                  var me = this;
                  this.setState({ page: this.state.page + 1 }, () => {
                    me.fetchVendorData();
                  });
                }}
                hasMore={(this.state.activeCategoryId === 0
                  ? this.props.products.length
                  : this.props.categoryProducts.length) ===
                  (this.state.page + 1) * this.state.pageSize
                }
                endMessage={
                  masterProduct?.length === 0 && !masterLoader &&
                  <p className={classes.emptyText}>
                    <span>
                      <span>Not finding what you are looking for? Try searching based on </span>
                      <span className={classes.addProductLink} onClick={() => this.setSearchType('features')}>features</span>
                      <span> or </span>
                      <span className={classes.addProductLink} onClick={() => this.setSearchType('subcategories')}>sub-categories</span>
                      <span> or </span>
                      <span className={classes.addProductLink} onClick={() => {
                        this.props.history.push(`/app/products/all-products?s=${this.state.query}`)
                      }}>Expent product directory.
                      </span>
                    </span>
                  </p>
                }
              >
                {this.state.stateFilter.length === 0 && this.renderFeatured(featuredProduct, content, lgGrid)}
                {this.state.stateFilter.length === 0 && (
                  <Grid container spacing={3}>
                    {masterProduct.map((product, i) => {
                      if (product?.orgProduct !== undefined && product?.orgProduct['products'] !== undefined) {
                        return (
                          <Grid item lg={lgGrid} md={3} sm={6} xs={12} key={`orgProduct_${i}`} className={classes.productCard}>
                            <div className={classes.productCard}>
                              {adminFlag &&
                                <div className={classes.closeIcon} onClick={(event) => {
                                  this.closeIconGroupCard(event, product?.orgProduct)
                                }}>
                                  <CloseIcon />
                                </div>
                              }
                              <ClusterFolder group={product} />
                            </div>
                          </Grid>
                        )
                      }
                      return (
                        <Grid item lg={lgGrid} md={isCart ? 4 : 4} xs={12} sm={6} key={product.ID}>
                          <div className={classes.productCard}>
                            {this.state.showGroupProduct === true &&
                              <div className={classes.selectIcon}
                                onClick={(event) => {
                                  this.groupClusterProducts(event, product)
                                }}
                              >
                                <Checkbox
                                  defaultChecked={false}
                                  checked={this.props.clusterProducts?.filter(o => o.ID === product.ID).length > 0 ? true : false} />
                              </div>
                            }
                            <LayeredCard
                              isActionButtons
                              title={product.Name}
                              subTitle={product.CompanyName}
                              description={product.Description}
                              requestLink={product?.AccessLink}
                              image={product.ProductLogo === null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo}
                              toogleCheck={(e) => {
                                this.reviewProduct(product.ID)
                              }}
                              onClick={() => { }}
                              numLayer={0}
                              showCheckbox={true}
                              id={product.ID}
                              productStates={product?.ProductStates}
                              uniqueId={product.ID + '_o_' + i}
                              customRoute={'/app/products/org-product-details/'}
                              type={'product'}
                              onClickState={state => this.handleOnClickState(state)}
                              IsExisting={this.isExisting(product)}
                              typeOfBlock="all_product_blocking"
                              source={'SURVEY'}
                            />
                          </div>
                        </Grid>
                      )
                    }
                    )}
                  </Grid>
                )}
                {this.state.stateFilter.length > 0 && (
                  <Grid container spacing={3}>
                    {masterProduct.map((product, i) => (
                      <Grid item lg={lgGrid} md={isCart ? 4 : 4} xs={12} sm={6} key={product.ID}>
                        <div className={classes.productCard}>
                          {this.state.showGroupProduct === true &&
                            <div className={classes.selectIcon}
                              onClick={(event) => {
                                this.groupClusterProducts(event, product)
                              }}
                            >
                              <Checkbox
                                defaultChecked={false}
                                checked={this.props.clusterProducts?.filter(o => o.ID === product.ID).length > 0 ? true : false} />
                            </div>
                          }
                          <LayeredCard
                            isActionButtons
                            title={product.Name}
                            subTitle={product.CompanyName}
                            description={product.Description}
                            requestLink={product?.AccessLink}
                            image={product.ProductLogo === null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo}
                            toogleCheck={(e) => {
                              this.reviewProduct(product.ID)
                            }}
                            onClick={() => { }}
                            numLayer={0}
                            showCheckbox={true}
                            id={product.ID}
                            productStates={product?.ProductStates}
                            uniqueId={product.ID + '_o_' + i}
                            customRoute={'/app/products/org-product-details/'}
                            type={'product'}
                            onClickState={state => this.handleOnClickState(state)}
                            IsExisting={this.isExisting(product)}
                            typeOfBlock="all_product_blocking"
                            source={'SURVEY'}
                          />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {(this.state.productStateProg || masterLoader) && (
                  <Grid container justify="center" spacing={3}>
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                )}
              </InfiniteScroll>
            </div>}

            {/* Product */}
            {isProductSearchType && this.state.activeCategoryId === 0 && (
              <>
                {this.renderFeatured(featuredProduct, content, lgGrid, true, orgExistingProduct && orgExistingProduct.length > 0)}
                {this.state.selectedProduct !== null && (
                  <div>
                    {orgExistingProduct && orgExistingProduct.length > 0 ? (
                      <Grid container spacing={3}>
                        {orgExistingProduct.map((product, i) => (
                          <Grid item lg={lgGrid} md={isCart ? 4 : 3} xs={12} sm={6} key={i}>
                            <LayeredCard
                              isActionButtons
                              title={product.Name}
                              subTitle={product.CompanyName}
                              description={product.Description}
                              requestLink={product?.AccessLink}
                              image={product.ProductLogo === null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo}
                              toogleCheck={(e) => {
                                this.reviewProduct(product.ID)
                              }}
                              onClick={() => { }}
                              numLayer={0}
                              //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                              showCheckbox={true}
                              id={product.ID}
                              productStates={product?.ProductStates}
                              uniqueId={product.ID + '_o_' + i}
                              customRoute={'/app/products/org-product-details/'}
                              type={'product'}
                              IsExisting={this.isExisting(product)}
                              typeOfBlock="all_product_blocking"
                              source={'SURVEY'}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <p className={classes.emptyText}>
                        <span>
                          <span>The product that you chose is not in the org, please search from the </span>
                          <span className={classes.addProductLink} onClick={() => {
                            this.props.history.push(`/app/products/all-products?s=${this.state.query}`)
                          }}>Expent product directory.
                          </span>
                        </span>
                      </p>
                    )}
                  </div>
                )}
                {!this.props.fetchOrgProductsSimilarExistingProgress &&
                  this.props.orgProductsSimilarExisting &&
                  this.props.orgProductsSimilarExisting.length > 0 && (
                    <>
                      <Typography className={classes.similarProduct}>Similar Products</Typography>
                      <Grid container spacing={3}>
                        {/* Products list */}
                        {this.props.orgProductsSimilarExisting.map((product, i) => (
                          <Grid item lg={lgGrid} md={isCart ? 4 : 3} xs={12} sm={6} key={product.ID}>
                            <LayeredCard
                              isActionButtons
                              title={product.Name}
                              subTitle={product.CompanyName}
                              description={product.Description}
                              requestLink={product?.AccessLink}
                              image={product.ProductLogo === null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo}
                              toogleCheck={(e) => {
                                this.reviewProduct(product.ID)
                              }}
                              onClick={() => { }}
                              numLayer={0}
                              //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                              showCheckbox={true}
                              id={product.ID}
                              productStates={product?.ProductStates}
                              uniqueId={product.ID + '_o_' + i}
                              customRoute={'/app/products/org-product-details/'}
                              type={'product'}
                              IsExisting={this.isExisting(product)}
                              source={'SURVEY'}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      {this.props.fetchOrgProductsSimilarExistingProgress && (
                        <CircularProgress />
                      )}
                    </>
                  )}
              </>
            )}

            <SliderFilter
              open={this.state.open}
              toggleDrawer={this.toggleDrawer}
              filters={content.filters}
            />
            {
              this.props.addProductManuallyDialogOpen &&
              <AddProductManuallyDialog />
            }
            <MessageDialog messageData={messageData} callBack={this.callBack} />
          </div>
        </div>
        {
          this.props._createClusterGroupDialog === true &&
          <CreateClusterGroupProductDialog />
        }

        {
          this.props._clusterProductsDialog === true &&
          <ClusterProductsListDialog />
        }
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Products));