import React, { Component, useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import uniqid from 'uniqid';
import Select from 'react-select';
import moment from 'moment';
import classnames from 'classnames';
import * as Validator from "util/Validation";
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid, IconButton, Typography, Button,
  FormControlLabel, Checkbox,
  TextField, ListItem, List, Divider, ListItemIcon,
  Popover, CircularProgress, FormControl, MenuItem
} from '@material-ui/core'
import { Select as MaterialSelect } from '@material-ui/core'

import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import { EditOutlined as EditIcon } from "@material-ui/icons";
import SqureButton from 'Components/Common/SqureButton';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import CommonCss from 'commonCss';
import { vendorCatalogCatalogItemTypes, taskTypes, tableItemTypes, binaryOptions } from "util/AppCatalogTypeOptions"
// import DoneIcon from '@material-ui/icons/Done';
// import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => createStyles({
  root: {
    position: 'relative',
    border: '1px solid #d8d8d8',
    padding: 17,
    borderRadius: 5,
    background: '#FFF',
    marginBottom: 17,
    '& [class*=MuiInputBase-input]': {
      fontSize: theme.spacing(1.8),
      color: '#282D30',
      '&::-webkit-input-placeholder': {
        color: "#707070",
        fontSize: theme.spacing(1.8)
      }
    },

  },
  checkbox: {
    padding: '0px !important'
    // '& [class*=MuiCheckbox-root]': {
    // }
  },
  productLink: {
    fontSize: 13,
    color: 'black',
    fontWeight: 400,
  },
  productLinkNew: {
    display: 'inline-block',
    color: '#0093E0',
    fontSize: 18,
    textDecoration: 'underline',
    marginTop: 0,
  },
  pItem: {
    margin: 0
  },
  itemContainer: {
    position: 'relative',
    border: '1px solid #d8d8d8',
    padding: '17px 17px 0px 17px ',
    borderRadius: 5,
    background: '#FFF',
    marginBottom: 17
  },
  ...CommonCss.ProductDetails,
  formControl: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  mb16: {
    marginBottom: 17,
  },
  formGroup: {
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  dialogContent: {
    minHeight: 170,
    overflowY: 'hidden',
    "& [class*=select__menu]": {
      maxHeight: 100
    }
  },
  inputLabel: {
    color: '#6C6C6C',
    fontSize: 15,
    opacity: 0.9,
    marginBottom: 11
  },
  type: {
    fontSize: 14,
    color: '#6C6C6C',
    opacity: 1
  },
  head: {
    fontSize: 14,
    color: '#6C6C6C',
    opacity: 1,
    margin: 0
  },
  itemHead: {
    fontSize: 14,
    color: '#6C6C6C',
    opacity: 1
  },
  fieldContainer: {
    marginBottom: 17
  },
  addNewButton: {
    fontWeight: 600,
    fontSize: theme.spacing(2)
  },
  actionIcon: {
    width: 30,
    height: 30,
    borderRadius: 5,
    textAlign: 'center',
    marginLeft: 5,
    border: '1px solid #4b86f8'
  },
  btn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 35,
    padding: 0,
    marginLeft: 15,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  micon: {
    fontSize: 16,
    color: '#4b86f8',
  },
  headerContainer: {
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #ddd'
  },
  tableCell: {
    width: "25%",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100vw - 99vw)'
  },
  tabledeleteCell: {
    width: "10%"
  },
  resourceHead: {
    padding: 10
  },
  iconEle: {
    width: 18,
    marginLeft: 8,
    cursor: "pointer",
  },
  visibilityLabel: {
    display: 'flex',
    alignItems: 'center',
    color: '#2EB77D',
    padding: '3px 10px',
    fontSize: '11px',
    background: '#E6FFF3',
    textAlign: 'left',
    borderRadius: '5px',
  },
  datePicker: {
    "& .react-datepicker-wrapper": {
      width: '30%',
    },
    "& .react-datepicker__input-container > div": {
      display: 'flex',
      marginBottom: 0,
      alignItems: 'center',
      paddingLeft: '8px !important',
      "& [class*=MuiInputBase-root]": {
        maxHeight: 33,
        minHeight: 33,
        width: 'inherit',
      },
      "& input": {
        fontSize: theme.spacing(1.8),
        padding: '14px 5px 14px 2px !important',
        textAlign: "center"
      },
      "& [class*=MuiFormLabel-root]": {
        marginBottom: 0,
        marginRight: 29
      }
    },
    "[class*=MuiMenuItem-root]": {
      color: "#707070",
      fontSize: theme.spacing(1.8)
    },
  },
  inputContainer: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'center flex-start',
    alignItems: 'center',
    marginBottom: 5
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    padding: '5px',
    width: '50%',
  },
  inputRowBoolean: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    padding: '5px',
    marginBottom: 5,
    width: 'max-content'
  },
  count: {
    display: 'inline-block',
    textAlign: 'center',
    color: '#6D7278',
    fontSize: '13px',
    marginRight: 5
  },
  input: {
    border: '0px',
    height: '30px',
    width: 'calc(100% - 20px)',
    outline: 0,
    color: '#6D7278',
    fontSize: '13px'
  },
  dragIndicator: {
    color: '#D9D9D9',
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
  },
  deleteIcon: {
    position: "absolute",
    right: 16,
    top: 13,
    cursor: "pointer",
  },
  cardSection: {
    position: "relative",
    flex: 1,
  },
  contactCardHeader: {
    flexDirection: "column",
    padding: "16px 4px !important",
    "& [class*='MuiCardHeader-avatar-']": {
      margin: "0px",
      marginBottom: 12,
    },
    "& [class*='MuiCardHeader-content']": {
      width: "100%",
    },
    "& [class*=MuiTypography-displayBlock]": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      margin: "0px -19px 0px 0px",
    },
  },
  contactCardRoot: {
    cursor: "pointer",
  },
  addNewChamp: {
    display: "block",
    textAlign: "center",
    color: "#bdbdbd",
    "& span": {
      display: "block",
      cursor: "pointer",
      color: "#000000",
    },
  },
  formControlDialog: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  formGroupDialog: {
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  resourceLinkText: {
    color: '#4b86f8 !important',
    cursor: 'pointer',
  },
  chipContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  borderNone: {
    border: 'none',
    padding: 0,
    minHeight: 0,
    '& [class*="MuiInputBase-root"]': {
      color: '#6D7278',
      fontSize: 13
    }
  },
  deleteImage: {
    cursor: "pointer",
  },
  description: {
    ...CommonCss.ProductDetails.description,
    "& p": {
      margin: "0px",
    },
    "& [class*=editor-editableField]": {
      minHeight: "auto !important",
      margin: "0px !important",
    },
    "& [class*=editor-formControl]": {
      border: "none !important",
    },
  },
  platformTitle: {
    marginTop: 0,
    fontSize: '0.83em',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  platFormItem: {
    '& > img': {
      width: 50,
      height: 50,
    }
  },
  platformName: {
    fontSize: '0.83em',
    color: 'rgba(0, 0, 0, 0.4)'
  },
  productCard: {
    textAlign: 'center',
    border: '3px solid #EEEEEE',
    padding: '0em 0.25em',
    background: '#fff',
    borderRadius: '1em',
    width: '100%',
  },
  contactCard: {
    position: 'relative',
    textAlign: 'center',
    border: '1px solid #EEEEEE',
    padding: '0.25em',
    background: '#fff',
    borderRadius: 5,
    width: '100%',
  },
  reviewCard: {
    position: 'relative',
    border: '1px solid #EEEEEE',
    padding: '0.25em',
    background: '#fff',
    borderRadius: 5,
    width: '100%',
  },
  financialDetailsContainer: {
    position: 'relative',
    border: '1px solid #EEEEEE',
    padding: '0.25em',
    background: '#fff',
    borderRadius: 5,
    width: '100%',
    marginBottom: 17
  },
  label: {
    color: '#000000de',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.6px',
  },
  value: {
    color: '#000000de',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.65x',
  },
  tag: {
    position: 'absolute',
    right: 0,
    top: 0,
    background: '#E6FFF3',
    fontSize: '0.83em',
    color: '#2EB77D',
    padding: '3px 10px',
  },
  vendirImage: {
    width: 'auto',
    height: 'auto',
    maxWidth: '80px',
    maxHeight: '80px',
    margin: '0px auto'
  },
  card: {
    boxShadow: "none",
    '&.details-border': {
      border: '1px solid #ddd'
    }
  },
  statsContainer: {
    display: "flex",
    margin: theme.spacing(6, 0),
    padding: theme.spacing(1, 0),
  },
  vendorInfo: {
    padding: '0 1em',
    textAlign: "center",
    flex: 1,
  },
  stats: {
    flex: 1,
    textAlign: "center",
    flexDirection: 'column',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'center space-around',
    alignItems: 'center',
    maxWidth: '150px'
  },
  divider: {
    borderRight: "solid 1px #ccc",
  },
  active: {
    display: "inline-block",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: "#33C162",
  },
  inactive: {
    display: "inline-block",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: "#9FA0A5",
  },
  vendirImageContainer: {
    height: '80px',
    display: 'flex',
    alignItems: 'center'
  },
  stateTitle: {
    color: "#20253A",
    opacity: 0.7,
    fontSize: theme.spacing(1.8),
  },
  stateValue: {
    color: "#20253A",
    fontSize: theme.spacing(3.2),
    fontWeight: 400
  },
  vendorTitle: {
    overflow: 'hidden',
    fontSize: theme.spacing(2.4),
    fontWeight: 400
  },
  tempSection: {
    position: "absolute",
    zIndex: 2,
    width: 200,
    opacity: 0,
    height: 47,
    bottom: 2,
  },
  downloadSection: {
    position: "relative",
  },
  chartSection: {
    maxWidth: "calc(100vw - 412px)",
    overflowX: "auto",
    overflowY: "hidden",
    padding: "0px 10px 10px 10px",
  },
  chartCard: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    justifyContent: "space-between",
    padding: 0,
    height: "100%",
  },
  chartLabel: {
    color: "#4B4B4B",
    fontSize: 10,
    textAlign: "center",
    padding: 10,
  },
  editIcon: {
    color: "#4A87F8",
    verticalAlign: "middle",
    cursor: 'pointer'
  },
  department: {
    cursor: "pointer"
  },
  dropdownTeamCol: {
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    '& [class*="-container"]': {
      flex: 1,
      height: 39,
      '& .select__placeholder': {
        color: '#B5BCC2',
        fontSize: '14px'
      },
      '& [class*="-ValueContainer"]': {
        color: '#000',
        fontSize: '14px'
      }
    }
  },
  meta: {},
  cardData: {
    boxShadow: "none",
    border: '1px solid #ddd'
  },
  threeDot: {
    padding: 5
  },
  dashboardSelect: {
    width: '100%'
  },
});

class FormBuilder extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      sectionId: null,
      visibility: [],
      items: [{
        isNew: true,
        name: "", type: 1, additional_data: '',
        desciption: null,
        // visibility: 3,
        itemId: uniqid("item-", `-${Number(new Date())}`)
      }],
      isDeletable: true,
      isEditable: true,
      activeItem: null,
      activeThead: false,
      teams: null,
      open: false,
      anchorEl: null,
      editName: null,
    }
  }

  componentDidMount() {
    const { config, isNew } = this.props;
    const data = {
      name: config?.name || this.state.name,
      sectionId: config?.id,
      visibility: config?.visibility,
      isDeletable: config?.is_deletable,
      isEditable: config?.is_editable,
      teams: config.Teams === undefined || config?.Teams === null || config?.Teams.length <= 0 ? null : config?.Teams,
    }

    if (!isNew && config?.items && config?.items.length > 0) {
      var sectionItems = config?.items !== undefined ? config?.items : [];
      sectionItems = sectionItems.sort(function (a, b) {
        if (a.Seq > b.Seq) {
          return 1;
        } else if (a.Seq < b.Seq) {
          return -1
        } else {
          return 0;
        }
      })
      data.items = sectionItems.map(item => ({
        name: item.name,
        type: item.type,
        additional_data: Validator.isJson(item.additional_data) ? JSON.parse(item.additional_data) : [],
        desciption: item.type === 9 ? parseInt(item.description) : item.description,
        // visibility: item.visibility,
        itemId: item.id,
      }))
    }
    this.setState(data)
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUpdateHappen !== prevProps.isUpdateHappen) {
      const { config } = this.props;
      this.setState({
        teams: config?.Teams === null || config?.Teams.length <= 0 ? null : config?.Teams,
      })
    }

    if (this.props.isUpdateItemHappen !== prevProps.isUpdateItemHappen) {
      const data = {}
      const { config, isNew } = this.props;
      if (!isNew && config?.items && config?.items.length > 0) {
        var sectionItems = config?.items !== undefined ? config?.items : [];
        sectionItems = sectionItems.sort(function (a, b) {
          if (a.Seq > b.Seq) {
            return 1;
          } else if (a.Seq < b.Seq) {
            return -1
          } else {
            return 0;
          }
        })
        data.items = sectionItems.map(item => ({
          name: item.name,
          type: item.type,
          additional_data: Validator.isJson(item.additional_data) ? JSON.parse(item.additional_data) : [],
          desciption: item.type === 9 ? parseInt(item.description) : item.description,
          // visibility: item.visibility,
          itemId: item.id,
          isNew: false
        }))
      }
      this.setState(data)
    }
  }

  handleSave = () => {
    const { name, visibility, sectionId, items } = this.state

    let itemsData = items.map(item => {
      let additionalData = '';
      if(Array.isArray(item.additional_data)){
        additionalData = item.additional_data.map(Obj => {
          delete Obj.isNew;
          return Obj
        })
      }else {
        additionalData = item.additional_data;
      }
      return {
        ...item,
        additional_data: additionalData
      }
    })

    if (this.props.isNew) {
      this.props.onSave({ name, visibility, sectionId, items: itemsData })
    } else {
      this.props.onUpdate({ name, visibility, sectionId, items: itemsData })
    }
  }

  handleUpdateItemValue = (name, key, index) => {
    const items = this.state.items
    const item = items[index]
    item[key] = name
    items[index] = item
    this.setState({ items })
  }

  handleChangeFile = (index, type, event, current) => {
    const checked = event.target.checked
    let currentCheck = current
    if (type === 'link') {
      if (currentCheck === 3 && !checked) {
        currentCheck = 2
      } else if (currentCheck === 2 && checked) {
        currentCheck = 3
      } else if (currentCheck === 1 && !checked) {
        currentCheck = 0
      } else if (currentCheck === 0 && checked) {
        currentCheck = 1
      }
    } else {
      if (currentCheck === 3 && !checked) {
        currentCheck = 1
      } else if (currentCheck === 1 && checked) {
        currentCheck = 3
      } else if (currentCheck === 2 && !checked) {
        currentCheck = 0
      } else if (currentCheck === 0 && checked) {
        currentCheck = 2
      }
    }
    const items = this.state.items
    items[index].additional_data.file = currentCheck
    this.setState({ items })
  }

  handleSectionVisibility = (type, current) => {
    let visibility = current
    if (visibility.includes(type)) {
      let index = visibility.indexOf(type);
      visibility.splice(index, 1);
    } else {
      visibility.push(type)
    }

    this.setState({ visibility }, () => {
      const { name, visibility, sectionId, items } = this.state
      if (!this.props.isNew) {
        this.props.onUpdateSectionVisibility({ name, visibility, sectionId, items })
      }
    })
  }

  handleChangeBinaryOptionLabel = (index, optionIndex, label) => {
    const items = this.state.items
    const item = items[index]
    item.additional_data[optionIndex].label = label
    items[index] = item
    this.setState({ items })
  }

  handleTextType = (value, index) => {
    const items = this.state.items
    let item = items[index]
    item.additional_data = { type: value }
    items[index] = item
    this.setState({ items })
  }

  handleWorkflowLabelChange = (value, index) => {
    const items = this.state.items
    let item = items[index]
    item.additional_data = { ...item.additional_data, label: value }
    items[index] = item
    this.setState({ items })
  }

  handleWorkflowChange = (value, index) => {
    console.log(value)
    const items = this.state.items
    let item = items[index]
    item.additional_data = { ...item.additional_data, workflow: value }
    items[index] = item
    this.setState({ items })
  }

  handleWorkflowTaskTypeChange = (value, index) => {
    console.log(value)
    const items = this.state.items
    let item = items[index]
    item.additional_data = { type: value }
    items[index] = item
    this.setState({ items })
  }

  handleVisualizationChange = (value, index) => {
    console.log(value)
    const items = this.state.items
    let item = items[index]
    item.additional_data = { visualization: value }
    items[index] = item
    this.setState({ items })
  }

  handleChangeType = (e, index) => {
    const items = this.state.items
    const item = items[index]
    item.type = e?.value || 1
    const activeThead = false
    item.desciption = null
    // item.is_multiselect = e && e?.value === 5 ? true : false
    item.additional_data = e && e?.value === 3 ? binaryOptions : []
    if (e && e?.value === 9) {
      item.additional_data = { file: 3 }
    }
    if (e && e?.value && e?.value === 7) {
      item.additional_data = { auto: true }
    }
    if (e && e?.value && e?.value === 1) {
      item.additional_data = { type: 0 }
    }
    if (e && e?.value && e?.value === 18) {
      item.additional_data = { type: { label: 'Task', value: 1 } }
    }
    if (e && e?.value && e?.value === 21) {
      item.additional_data = { visualization: null }
    }
    if (e && e?.value && e?.value === 17) {
      item.additional_data = { workflow: null }
    }
    items[index] = item
    this.setState({
      items,
      activeThead
    })
  }

  handleChangeTableColumnType = (value, index, i) => {
    const items = this.state.items
    const item = items[index]
    item.additional_data[i].type = value
    if(value === 20){
      item.additional_data[i].value = {
        type: {
          label: 'USD',
          value: 'USD'
        },
        value: ''
      }
    }
    this.setState({ items })
  }

  handleEditOption = (label, index, i) => {
    const items = this.state.items
    const item = items[index]
    item.additional_data[i].label = label
    this.setState({ items })
  }

  handleAddOption = (index) => {
    const items = this.state.items
    const item = items[index]
    item.additional_data.push({ label: "", value: Number(new Date()), id: Number(new Date()), type: 1, isNew: true })
    items[index] = item
    this.setState({ items })
  }

  handleRemoveOption = (index, i) => {
    const items = this.state.items
    const item = items[index]
    item.additional_data.splice(i, 1)
    items[index] = item
    this.setState({ items })
  }

  handleAddUser = (data) => {
    const { items, activeItem } = this.state
    const item = items[activeItem]
    item.additional_data.push(data)
    items[activeItem] = item
    this.setState({ items, activeItem: null })
  }

  handleDeleteUser = (itemIndex, userIndex) => {
    const { items, activeItem } = this.state
    const item = items[itemIndex]
    item.additional_data.splice(userIndex, 1)
    items[activeItem] = item
    this.setState({ items, activeItem: null })
  }

  handleAddItem = () => {
    const items = this.state.items
    items.push({
      isNew: true,
      name: "", type: 1, additional_data: { type: 0 },
      desciption: null,
      //  visibility: 3, 
      itemId: uniqid("item-", `-${Number(new Date())}`)
    })
    this.setState({ items })
  }

  handleRemoveIcon = (index) => {
    const items = this.state.items
    items.splice(index, 1)
    this.setState({ items })
  }

  getSectionType = (id) => {
    let type = 'Simple Text'
    if ([
      'prod_subcategories', 'prod_approved_platforms', 'prod_categories',
      'prod_approved_integrations', 'prod_feature_offered', 'prod_departments',
      'prod_perdonal_data_shared', 'prod_security_certification'
    ].includes(id)) {
      type = 'Multi Select'
    } if (['prod_business_criticality'].includes(id)) {
      type = 'Single Select'
    } else if (['prod_about'].includes(id)) {
      type = 'Rich Text'
    } else if (['prod_product_champions', 'prod_business_poc', 'prod_vendor_poc', 'business_point_of_contact'].includes(id)) {
      type = 'Users'
    } else if ([
      'prod_financial_details', 'prod_contact_documents', 'prod_certification_n_agreement', 'latest_review_and_evaluation',
      'prod_other_resources', 'prod_evaluation_reviews', 'prod_usages_metrics', 'contract', 'usage_summary'
    ].includes(id)) {
      type = 'Table'
    } else if (['existing_similar_products', 'alternative_products', 'prod_similar_products', 'prod_other_products', 'prod_complementary_products'].includes(id)) {
      type = 'Products'
    }
    return type
  }

  getVisibilityMsg = (visibility = []) => {
    var str = ''
    if (visibility.includes('admin') && visibility.includes('user') && visibility.includes('team')) {
      str = 'Admin & End User & Teams'
    } else if (visibility.includes('user') && visibility.includes('team')) {
      str = 'End User & Teams'
    } else if (visibility.includes('admin') && visibility.includes('team')) {
      str = 'Admin & Teams'
    } else if (visibility.includes('team')) {
      str = 'Teams'
    } else if (visibility.includes('admin') && visibility.includes('user')) {
      str = 'Admin & End User'
    } else if (visibility.includes('user')) {
      str = 'End User'
    } else if (visibility.includes('admin')) {
      str = 'Admin'
    }
    return str;
  }


  render() {
    const { classes, isActive } = this.props;

    const {
      name, visibility, items, sectionId, 
      // isEditable
    } = this.state
    let visibilityMsg = this.getVisibilityMsg(visibility)

    if (!this.props.isCustom) {
      const data = [0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0]
      var labels = [];
      var activityDataMap = {};
      if (this.props.dailyChartData?.length > 0) {
        this.props.dailyChartData.forEach((item) => {
          var date = moment(item.activityDate, "YYYY-MM-DD").format("MMM DD");
          activityDataMap[date] = item.userSessions;
        });
      }
      var startDate = moment().subtract(30, "days");
      var endData = moment();
      for (
        var i = startDate.toDate().getTime();
        i <= endData.toDate().getTime();
        i = moment(i).add(1, "day").toDate().getTime()
      ) {
        var date = moment(i).format("MMM DD");
        labels.push(date);
        if (activityDataMap[date] !== undefined) {
          data.push(activityDataMap[date]);
        } else {
          data.push(0);
        }
      }

      return (
        <div className={classes.root} id={this.props.id} key={this.props.key}>
          {this.props.isLoader && this.props.isActive ? (
            <Grid container spacing={2} alignItems="center" justify="center" className={classes.headerContainer}>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <div className={classnames(classes.formGroup, classes.mb16)}>
                <Grid container alignItems="center" justifyContent="space-between" style={{ position: 'relative' }}>
                  <Grid item>
                    <p className={classes.inputLabel} style={{ marginTop: 0 }}>Section Title</p>
                  </Grid>
                  <DragIndicatorOutlinedIcon className={classes.dragIndicator} />
                  <Grid item>
                    <Grid container alignItems="center">
                      {visibility !== 0 && (
                        <div className={classes.visibilityLabel}>
                          <VisibilityIcon />&nbsp;
                          {visibilityMsg}
                        </div>
                      )}
                      <IconButton
                        className={classes.threeDot}
                        onClick={(event) => {
                          event.stopPropagation()
                          this.setState({ open: true, anchorEl: event.currentTarget || event.current })
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Popover
                    id="Popover-one90"
                    open={this.state.open}
                    disableRestoreFocus={true}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({ anchorEl: null, open: null })}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}>
                    <List>

                      {!isActive && (
                        <>
                          <ListItem
                            classes={{
                              button: classes.listItem,
                            }}
                            button
                            onClick={e => {
                              e.stopPropagation()
                              this.props.activeSection()
                              this.setState({
                                openAddTabDialog: true,
                                anchorEl: null, open: null
                              })
                            }}
                          >
                            <ListItemIcon classes={{ root: classes.listIcon }}>
                              <EditIcon
                                fontSize={"small"}
                                className={classes.editIcon}
                                onClick={(e) => {
                                  e.stopPropagation()

                                }}
                              />
                            </ListItemIcon>
                            <Typography variant="inherit">
                              Edit
                            </Typography>
                          </ListItem>
                          <Divider />
                        </>
                      )}
                      <ListItem
                        classes={{
                          button: classes.listItem,
                        }}
                      >
                        <Typography variant="inherit">
                          Visible For
                        </Typography>
                      </ListItem>
                      <ListItem
                        classes={{
                          button: classes.listItem,
                        }}
                        button
                      >
                        <ListItemIcon classes={{ root: classes.listIcon }}>
                          <Checkbox className={classes.checkbox} disabled={false} checked={visibility.includes('admin')} onChange={e => {
                            e.stopPropagation()
                            this.handleSectionVisibility('admin', visibility)
                          }} />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          Admin
                        </Typography>
                      </ListItem>
                      <ListItem
                        classes={{
                          button: classes.listItem,
                        }}
                        button
                      >
                        <ListItemIcon classes={{ root: classes.listIcon }}>
                          <Checkbox className={classes.checkbox} disabled={false} checked={visibility.includes('user')} onChange={e => {
                            e.stopPropagation()
                            this.handleSectionVisibility('user', visibility)
                          }} />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          End User
                        </Typography>
                      </ListItem>
                      <ListItem
                        disabled={false}
                        classes={{
                          button: classes.listItem,
                        }}
                        button
                      >
                        <ListItemIcon classes={{ root: classes.listIcon }}>
                          <Checkbox className={classes.checkbox} disabled={false} checked={visibility.includes('team')} onChange={e => {
                            e.stopPropagation()
                            this.handleSectionVisibility('team', visibility)
                          }} />
                        </ListItemIcon>
                        <Typography variant="inherit" onClick={e => {
                          e.stopPropagation()
                          this.props.addTeams();
                        }}>
                          Teams
                        </Typography>
                      </ListItem>
                    </List>
                  </Popover>
                </Grid>
                {!isActive ? (
                  <p className={classes.head}>{name}</p>
                ) : (
                  <TextField
                    fullWidth
                    className={classes.formControl}
                    value={name}
                    InputProps={{ disableUnderline: true }}
                    placeholder="Enter Section Name"
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                )}
              </div>
              <div className={classes.fieldContainer}>
                <p className={classes.inputLabel}>Section Type</p>
                <Typography className={classes.type}>{this.getSectionType(sectionId)}</Typography>
              </div>
              
              {this.props.isActive && (
                <Grid container justify="flex-end" alignItems="center">
                  <div>
                    <SqureButton
                      variant={"outlined"}
                      onClick={() => {
                        this.props.onCancel()
                      }}
                    >
                      Cancel
                    </SqureButton>
                    &nbsp; &nbsp; &nbsp;
                    <SqureButton
                      variant={"contained"}
                      disabled={false}
                      onClick={this.handleSave}
                    >
                      Save
                    </SqureButton>
                  </div>
                </Grid>
              )
              }
            </>
          )
          }
        </div >
      )
    }

    return (
      <div className={classes.root} id={this.props.id} key={this.props.key}>
        <div className={classnames(classes.formGroup, classes.mb16)} >
          <Grid container alignItems="center" justifyContent="space-between" style={{ position: 'relative' }}>
            <Grid item>
              <DragIndicatorOutlinedIcon className={classes.dragIndicator} />
              <p className={classes.inputLabel} style={{ marginTop: 0 }}>Section Title</p>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                {visibility !== 0 && (
                  <div className={classes.visibilityLabel}>
                    <VisibilityIcon />&nbsp;
                    {visibilityMsg}
                  </div>
                )}
                {/* {visibility === 0 && teams !== null && (
                  <div className={classes.visibilityLabel}>
                    {teams !== null ? 'Teams' : ''}
                  </div>
                )} */}
                <IconButton
                  className={classes.threeDot}
                  onClick={(event) => {
                    event.stopPropagation()
                    this.setState({ open: true, anchorEl: event.currentTarget || event.current })
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Popover
              id="Popover-two90"
              open={this.state.open}
              anchorEl={this.state.anchorEl}
              disableRestoreFocus={true}
              onClose={() => this.setState({ anchorEl: null, open: null })}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}>
              <List>

                {!this.props.isNew && !isActive && (
                  <>
                    <ListItem
                      classes={{
                        button: classes.listItem,
                      }}
                      button
                      onClick={e => {
                        e.stopPropagation()
                        this.props.activeSection()
                        this.setState({
                          openAddTabDialog: true,
                          anchorEl: null,
                          open: null
                        })
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.listIcon }}>
                        <EditIcon
                          fontSize={"small"}
                          className={classes.editIcon}
                        />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        Edit
                      </Typography>
                    </ListItem>
                    <Divider />
                  </>
                )}

                {!this.props.isNew && !isActive && ( 
                  <>
                    <ListItem
                      classes={{
                        button: classes.listItem,
                      }}
                      button
                      onClick={e => {
                        e.stopPropagation()
                        this.props.onDelete()
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.listIcon }}>
                        <Image
                          src={deleteIcon}
                          className={classes.deleteImage}
                        />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        Delete
                      </Typography>
                    </ListItem>
                    <Divider />
                  </>
                )}
                <ListItem
                  classes={{
                    button: classes.listItem,
                  }}
                >
                  <Typography variant="inherit">
                    Visible For
                  </Typography>
                </ListItem>
                <ListItem
                  classes={{
                    button: classes.listItem,
                  }}
                >
                  <ListItemIcon classes={{ root: classes.listIcon }}>
                    <Checkbox className={classes.checkbox} disabled={false} checked={visibility.includes('admin')} onChange={e => {
                      e.stopPropagation()
                      this.handleSectionVisibility('admin', visibility)
                    }} />
                  </ListItemIcon>
                  <Typography variant="inherit">
                    Admin
                  </Typography>
                </ListItem>
                <ListItem
                  classes={{
                    button: classes.listItem,
                  }}
                  button
                >
                  <ListItemIcon classes={{ root: classes.listIcon }}>
                    <Checkbox className={classes.checkbox} disabled={false} checked={visibility.includes('user')} onChange={e => {
                      e.stopPropagation()
                      this.handleSectionVisibility('user', visibility)
                    }} />
                  </ListItemIcon>
                  <Typography variant="inherit">
                    End User
                  </Typography>
                </ListItem>
                <ListItem
                  disabled={false}
                  classes={{
                    button: classes.listItem,
                  }}
                  button
                >
                  <ListItemIcon classes={{ root: classes.listIcon }}>
                    <Checkbox className={classes.checkbox} disabled={false} checked={visibility.includes('team')} onChange={e => {
                      e.stopPropagation()
                      this.handleSectionVisibility('team', visibility)
                    }} />
                  </ListItemIcon>
                  <Typography variant="inherit" onClick={e => {
                    e.stopPropagation()
                    this.props.addTeams();
                  }}>
                    Teams
                  </Typography>
                </ListItem>
              </List>
            </Popover>
          </Grid>
          {isActive && (
            <TextField
              fullWidth
              className={classes.formControl}
              value={name}
              InputProps={{ disableUnderline: true }}
              placeholder="Enter Section Title"
              onChange={e => this.setState({ name: e.target.value })}
            />
          )}
          {!isActive && (
            <p className={classes.head}>{name}</p>
          )}
        </div>
        {
          items && items.length > 0 && items.map((item, index) => (
            <div className={classes.itemContainer} key={item.ID}>
              <p className={classes.inputLabel}>Sub Section Title</p>
              <div className={classnames(classes.formGroup, classes.mb16)}>
                {isActive && (
                  <InputField
                    classes={classes}
                    val={item.name}
                    placeholder="Enter Sub Section Title"
                    onChange={value => {
                      this.handleUpdateItemValue(value, 'name', index)
                    }}
                  />
                )}
                {!isActive && (
                  <p className={classes.itemHead}>{item.name}</p>
                )}
              </div>
              <Grid container justifyContent="space-between" alignItems="center">
                <p className={classes.inputLabel} style={{ marginTop: 0 }}>Sub Section Type</p>
                {items.length > 1 && isActive && !this.props.isLoader && (
                  <div>
                    <Image
                      src={deleteIcon}
                      className={classes.deleteImage}
                      style={{ top: 15 }}
                      onClick={(event) => {
                        event.stopPropagation()
                        if (item.isNew) {
                          this.handleRemoveIcon(index)
                        } else {
                          this.props.deleteItem(item.itemId)
                        }
                      }}
                    />
                  </div>
                )}
              </Grid>
              <div className={classes.fieldContainer}>
                {isActive ? (
                  <Select
                    options={vendorCatalogCatalogItemTypes}
                    isDisabled={!(item.isNew === true)}
                    defaultValue={vendorCatalogCatalogItemTypes.filter(type => type.value === item.type)[0]}
                    classNamePrefix="select"
                    placeholder={"Select Type"}
                    onChange={(e) => this.handleChangeType(e, index)}
                  />
                ) : (
                  <Typography className={classes.type}>{vendorCatalogCatalogItemTypes.filter(type => type.value === item.type)[0]?.label}</Typography>
                )}
              </div>
              <div className={classes.fieldContainer}>
                {item.type === 3 && (
                  <>
                    {item.additional_data?.map((obj, i) => (
                      <div className={classes.inputRowBoolean} key={`additional_data-${i}`}>
                        <span className={classes.count}>{i + 1}</span>
                        {isActive ? (
                          <InputField
                            disabled={!isActive}
                            classes={{ formControl: classes.input }}
                            val={obj.label}
                            onChange={value => {
                              this.handleChangeBinaryOptionLabel(index, i, value)
                            }}
                          />
                        ) : (
                          <span className={classes.count}>&nbsp;{obj.label}</span>
                        )}
                      </div>
                    ))}
                  </>
                )}
                {[4, 5, 6, 15].includes(item.type) && (
                  <>
                    {item.additional_data.map((data, i) => (
                      <div className={classes.inputContainer} key={`additional_data-${item.type}-${i}`}>
                        <div className={classes.inputRow}>
                          <span className={classes.count}>{i + 1}.</span>
                          <InputField
                            classes={classes}
                            disabled={!isActive}
                            val={data.label}
                            border={false}
                            placeholder={`${item.type === 6 ? 'Enter Column' : 'Enter Option'}`}
                            onChange={value => {
                              this.handleEditOption(value, index, i)
                            }}
                          />
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        {item.type === 6 && 
                          <div style={{width: 200}}>
                            <Select
                              options={tableItemTypes}
                              isDisabled={!(data.isNew === true)}
                              value={tableItemTypes.find(type => type.value === data.type) || tableItemTypes[0]}
                              classNamePrefix="select"
                              placeholder={"Select Type"}
                              onChange={(e) => this.handleChangeTableColumnType(e.value, index, i)}
                            />
                          </div>}
                        &nbsp;&nbsp;&nbsp;
                        {isActive && (
                          <Image
                            src={deleteIcon}
                            className={classes.deleteImage}
                            style={{ top: 15 }}
                            onClick={() => {
                              setTimeout(() => {
                                this.handleRemoveOption(index, i)
                              }, 100)
                            }}
                          />
                        )}
                      </div>
                    ))}
                    <div>
                      {isActive && (
                        <div>
                          <span
                            onClick={() => {
                              setTimeout(() => {
                                this.handleAddOption(index)
                              }, 300)
                            }}
                            style={{
                              fontSize: "12px",
                              color: "#4175DF",
                              cursor: "pointer",
                            }}
                          >
                            + Add {`${item.type === 6 ? 'column' : 'option'}`}
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {item.type === 9 && (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!isActive}
                          checked={[1, 3].includes(item.additional_data.file)}
                          onChange={e => this.handleChangeFile(index, 'link', e, item.additional_data.file)}
                          name="checkedB"
                        />
                      }
                      label="Add Link"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!isActive}
                          checked={[2, 3].includes(item.additional_data.file)}
                          onChange={e => this.handleChangeFile(index, 'file', e, item.additional_data.file)}
                          name="checkedB"
                        />
                      }
                      label="File Upload"
                    />
                  </div>
                )}
                {item.type === 16 && (
                  <div style={{ marginTop: 25 }}>
                    {isActive ? (
                      <TextField
                        fullWidth
                        label="Button Label Name"
                        variant="outlined"
                        size="small"
                        value={item.additional_data.label}
                        onChange={e => {
                          this.handleWorkflowLabelChange(e.target.value, index)
                        }}
                      />
                    ) : (
                      <span className={classes.count}><b>Button Label Name:</b> {item.additional_data.label}</span>
                    )}
                  </div>
                )}
                {item.type === 17 && (
                  <div style={{ marginTop: 25 }}>
                    {isActive ? (
                      <>
                        <p className={classes.inputLabel}>What workflow do you want to use</p>
                        <Select
                          options={this.props.orgWorkFlowList?.Data?.map(item=> ({
                            label: item.Name,
                            value: item.ID
                          }))}
                          defaultValue={item.additional_data?.workflow}
                          classNamePrefix="select"
                          placeholder={"Select Workflow"}
                          onChange={(value) => this.handleWorkflowChange(value, index)}
                        />
                      </>
                    ) : (
                      <span className={classes.count}><b>Selected Workflow:</b> {item.additional_data?.workflow?.label}</span>
                    )}
                  </div>
                )}
                {item.type === 17 && (
                  <div style={{ marginTop: 25 }}>
                    {isActive ? (
                      <InputField
                        classes={classes}
                        placeholder="Button Label Name"
                        value={item.additional_data.label}
                        onChange={value => {
                          this.handleWorkflowLabelChange(value, index)
                        }}
                      />
                    ) : (
                      <span className={classes.count}><b>Button Label Name:</b> {item.additional_data.label}</span>
                    )}
                  </div>
                )}
                {item.type === 18 && (
                  <div style={{ marginTop: 25 }}>
                    {isActive ? (
                      <>
                        <p className={classes.inputLabel}>Task Type</p>
                        <Select
                          options={taskTypes}
                          defaultValue={item.additional_data?.type}
                          classNamePrefix="select"
                          placeholder={"Select Type"}
                          onChange={(e) => this.handleWorkflowTaskTypeChange(e, index)}
                        />
                      </>
                    ) : (
                      <span className={classes.count}><b>Task Type:</b> {item.additional_data?.type?.label}</span>
                    )}
                  </div>
                )}

                {item.type === 21 && (
                  <div style={{ marginTop: 25 }}>
                    {isActive ? (
                      <FormControl variant="outlined" size="small" className={classes.dashboardSelect}>
                        <MaterialSelect value={item.additional_data?.visualization} onChange={(e) => {
                          this.handleVisualizationChange(e.target.value, index)
                        }}>
                          {this.props.visualizations && this.props.visualizations?.data?.map((da) => {
                            return <MenuItem value={da?.visualizationID} style={{ fontSize: 13 }}>{da?.name}</MenuItem>
                          })}
                          <MenuItem value={'sadsad'} style={{ fontSize: 13 }}>{'asdsad'}</MenuItem>
                        </MaterialSelect>
                      </FormControl>
                    ) : (
                      <span className={classes.count}><b>Selected Visualization: </b>
                        {this.props.visualizations && this.props.visualizations?.data?.filter(o => o.visualizationID === item.additional_data.visualization)[0]?.name}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))
        }

        {
          this.props.isActive && (
            <Grid container justifyContent="space-between" direction="row-reverse" alignItems="center">
              {this.props.isLoader ? (
                <CircularProgress />
              ) : (
                <>
                  <div>
                    <SqureButton
                      variant={"outlined"}
                      onClick={() => {
                        this.props.onCancel()
                      }}
                    >
                      Cancel
                    </SqureButton>
                    &nbsp; &nbsp; &nbsp;
                    <SqureButton
                      variant={"contained"}
                      disabled={false}
                      onClick={this.handleSave}
                    >
                      Save
                    </SqureButton>
                  </div>
                  <div>
                    <Button
                      className={classes.addNewButton}
                      onClick={() => {
                        setTimeout(() => {
                          this.handleAddItem()
                        }, 100)
                      }}
                    >
                      + Add Sub Section
                    </Button>
                  </div>
                </>
              )}
            </Grid>
          )
        }
      </div >
    )
  }
}

export default React.memo(withStyles(styles)(FormBuilder));

const InputField = ({ classes, onChange, val, placeholder, disabled = false, border = true }) => {
  const [value, setValue] = useState("")
  useEffect(() => {
    setValue(val)
  }, [val])
  return (
    <TextField
      fullWidth
      className={classnames(classes.formControl, border ? {} : classes.borderNone)}
      value={value}
      disabled={disabled}
      InputProps={{ disableUnderline: true }}
      placeholder={placeholder}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value)
      }}
    // onBlur={() => onChange(value)}
    />
  )
}