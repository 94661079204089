import { Button, Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import CommonCss from "commonCss";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as Validator from "util/Validation";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({});

const connectionActions = {
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);
const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
    },
    ...CommonCss.ProductDetails,
    description2: {
      ...CommonCss.ProductDetails.description,
      "& p": {
        margin: "0px",
      },
      "& [class*=editor-editableField]": {
        minHeight: "auto !important",
        margin: "0px !important",
      },
      "& [class*=editor-formControl]": {
        border: "none !important",
      },
    },
    formGroup: {
      display: "flex",
      alignItems: "center",
    },
    label: {
      marginBottom: 5,
      display: "block",
    },
    ...CommonCss.Home,
    contained: {
      color: "#fff",
      padding: "6px 26px",
      backgroundColor: "#4b86f8",
      "&:hover": {
        backgroundColor: "#4b86f8",
      },
    },
    tickMark: {
      color: "#4b86f8",
      border: "1px solid #4b86f8",
      marginRight: 10,
      minWidth: "50px !important",
      "&:hover": {
        color: "#4b86f8",
      },
    },
    colorBlue: {
      color: "#4b86f8",
    },
    buttonGroup: {
      alignItems: "center",
      position: "relative",
      zIndex: 1000,
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 6,
      marginBottom: 5,
      cursor: "pointer",
    },
    descriptionText: {
      float: "right",
    },
    supportLabel: {
      fontWeight: 600,
      marginTop: 3,
      marginBottom: 3,
      // minWidth: 129,
      display: "inline-block",
      paddingRight: 10,
    },
    productLink: {
      ...CommonCss.ProductDetails.productLink,
      fontSize: 14,
      marginBottom: 0,
    },
    deleteImage: {
      position: "absolute",
      top: 24,
      zIndex: 20,
      right: 23,
      padding: 4,
      cursor: "pointer",
    },
    positionRelative: {
      position: "relative",
    },
    productList: {
      position: "relative",
    },
    slateInputField: {
      "& > div": {
        paddingTop: "0px !important",
        paddingBottom: "30px !important",
      },
    },
    nomargin: {
      margin: "0px !important",
    },
    closeIcon: {
      position: "absolute",
      right: "-20px",
      top: 0,
      cursor: "pointer",
    },
    topIndex: {
      zIndex: 1,
    },
    hint: {
      position: "absolute",
      opacity: 0.5,
      fontSize: 12,
    }
  });

class Url extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      value: "",
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  save = () => {
    if (Validator.validateURL(this.state.value) === false) {
      this.props.showSnackBar("Please enter valid URL", "error", 3000);
      return;
    } else {
      this.props.updateValue(this.state.value);
    }
    this.setState({ isEdit: false });
  };

  render() {
    const classes = this.props.classes;

    return (
      <div>
        {!this.state.isEdit && (
          <div>
            <span className={classes.supportLabel}>{this.props.label} </span>
            <span style={{ wordBreak: "break-word" }}>
              <a target="_blank" href={this.props.value} className={classes.productLink} rel="noopener noreferrer">
                {this.props.value}
              </a>
            </span>
            {this.props.editAccess && (
              <EditIcon
                fontSize={"small"}
                className={classes.editIcon}
                onClick={() => {
                  this.setState({
                    value: this.props.value,
                    isEdit: true,
                  });
                }}
              />
            )}
          </div>
        )}
        {this.state.isEdit && (
          <Grid container spacing={3} className={classes.buttonGroup}>
            <Grid item sm={8}>
              <div className={classes.formGroup}>
                <span className={classes.supportLabel} style={{ marginRight: 20 }}>
                  {this.props.label}{" "}
                </span>
                
                <div style={{width: '100%', position: 'relative'}}>
                  <input
                    style={{ padding: 10 }}
                    className={classes.formControl}
                    placeholder={this.props.placeholder}
                    onChange={(event) => {
                      this.setState({
                        value: event.target.value,
                      });
                    }}
                    value={this.state.value}
                  />
                <div className={classes.hint}>e.g. https://www.abc.xyz</div>
                </div>
              </div>
            </Grid>
            <Grid item sm={4}>
              <Button className={classes.tickMark} onClick={() => this.save()}>
                <DoneIcon />
              </Button>
              <Button className={classes.tickMark} onClick={() => this.setState({ isEdit: false })}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Url));
