import React from 'react';
import { connect } from "react-redux";
import _ from "underscore";
import InfiniteScroll from "react-infinite-scroll-component";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {Dialog,InputAdornment, DialogContent, Grid, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import Clear from "@material-ui/icons/Clear";
import TooltipMsg from '@material-ui/core/Tooltip';
import { Search as SearchIcon } from "@material-ui/icons";

// assets
import DefaultImage from "assets/images/evaluation/default.svg";

// components
import SqureButton from 'Components/Common/SqureButton';
import AppBar from "Components/Application/AppBar";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import CreateClusterGroupProductDialog from "Components/Dialogs/CreateClusterGroupProductDialog";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
// redux
import {hideClusterProductsDialog} from "redux/product/cluster/action";
import {showCreateClusterGroupDialog, showClusterProductsDialog, fetchClusterGroups, storeClusterGroupProducts, fetchAddProductToClusterGroup} from "redux/product/cluster/action";
import {allproductSearch} from "redux/product/orgProduct/action"
import { showSnackBar } from "redux/snackbar/action";
import { clearStoreState } from "redux/applications/action";
import { fetchAppCatlogProducts } from "redux/product/appCatalog/action"

const connectedProps = (state) => ({
    isOpen:state.clusterProductReducer._clusterProductsDialog,
    isNew:state.clusterProductReducer._clusterProductsDialogIsNew,
    clusterProducts: state.clusterProductReducer.storeClusterGroupProducts,
    clusterGroups: state.clusterProductReducer.fetchClusterGroupSuccess,
    products:state.orgProduct.orgProducts,
    existingProducts: state.clusterProductReducer._clusterProductsDialogExistingProducts,
    _createClusterGroupDialog: state.clusterProductReducer._createClusterGroupDialog,

    addProductInClusterGroupInprogress: state.clusterProductReducer.addProductInClusterGroupInprogress,
    addProductInClusterGroupSuccess: state.clusterProductReducer.addProductInClusterGroupSuccess,
    addProductInClusterGroupError: state.clusterProductReducer.addProductInClusterGroupError,

    fetchAppCatalogProductsProgress: state.appCatalog.fetchAppCatalogProductsProgress,
    fetchAppCatalogProductsError: state.appCatalog.fetchAppCatalogProductsError,
    appCatalogProducts: state.appCatalog.products,
});


const connectionActions = {
    hideDialog:hideClusterProductsDialog,
    showCreateClusterGroupDialog:showCreateClusterGroupDialog,
    showClusterProductsDialog:showClusterProductsDialog,
    fetchClusterGroups:fetchClusterGroups,
    storeClusterGroupProducts:storeClusterGroupProducts,
    fetchAddProductToClusterGroup: fetchAddProductToClusterGroup,
    showSnackBar: showSnackBar,
    clearStoreState: clearStoreState,
    allproductSearch: allproductSearch,
    fetchAppCatlogProducts: fetchAppCatlogProducts,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent:{
        padding: theme.spacing(6, 12) +" !important",
        backgroundColor:"#F7F7F7",
        maxHeight:"100%",
        height:"100%",
        position:"relative",
        overflow:"auto"
    },
    title:{
        color:"#282D30",
        fontSize:theme.spacing(2.5),
        marginBottom:0
    },
    tabs:{
        listStyle:"none",
        width:"300px",
        display:"flex",
        margin:0,
        padding:0,
        borderBottom:"solid 1px #e0e0e0"
    },
    tab:{

    },
    tabBtn:{
        padding:theme.spacing(2),
        borderRadius:0
    },
    activeBtn:{
        color:"#4A87F8",
        borderBottom:"solid 2px #4A87F8",
    },
    actions:{
        position:'fixed',
        bottom:0,
        left:0,
        right:0,
        padding:theme.spacing(2),
        background:"#fff",
        textAlign:"right",
        zIndex: 999
    },
    productCard: {
      position: 'relative'
    },
    selectIcon: {
      position: 'absolute',
      zIndex: 22,
      right: 5,
      top: 5,
      color: '#dcd7d7',
      cursor: 'pointer'
    },
    searchSection: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      // marginRight: 40,
      '& [class*="MuiOutlinedInput-root"]': {
        maxHeight: 40,
        minHeight: 40,
        maxWidth: 343,
      },
      "& [class*='MuiInputBase-input']": {
        fontSize: 13,
        width: 300,
      },
      "& [class*='MuiSvgIcon-root']": {
        fill: "#707070",
      },
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
      "&>div ": {
        marginBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '16px',
      },
    },
    searchIconPop: {
      cursor: 'pointer',
      fontSize: theme.spacing(3),
      marginRight: '10px',
      "&:hover": {
        color: "#fff !important",
        fill: "#fff !important",
        background: "#707070",
        borderColor: "#707070",
        padding: '3px',
        borderRadius: theme.spacing(20),
        fontSize: theme.spacing(3)
      }
    },
    searchContainer:{
      display: 'flex',
      placeContent:' space-between',
      placeItems: 'center',
      marginTop: '30px;'
    },
    productCount:{
      flex:1
    },
    actionSection:{
      flex:1,
      textAlign:"right"
    },
    progressIndicator:{
      position: 'fixed',
      top: '45%',
      left: '50%',
      zIndex: 99999
    }
});

class ClusterProductsListDialog extends React.Component {

    constructor(props){
        super(props);
        this.state  =   {
          page: 0,
          pageSize: 0,
          query: '',
          showGroupProduct: false,
          cluster_products: [],
          isGroupNameEdit: false,
          isGroupNameText: "",
        }
    }

    componentDidMount() {
      this.fetchVendorData()
    }

    componentDidUpdate() {
      if(!this.props.addProductInClusterGroupInprogress && this.props.addProductInClusterGroupSuccess){
        this.props.showSnackBar("Group updated successfully","success",3000);
        this.props.clearStoreState({
          'clusterProductReducer.addProductInClusterGroupSuccess': null,
        })
        this.props.hideDialog()
      }  
    }

    fetchVendorData =()=> {
      this.props.fetchAppCatlogProducts(
        {
          field_setname: 'all',
          text: this.state.query,
          filter_fields: {
            state: null, 
            feature_ids: null, 
            subcategory_ids: null, 
            platform_ids: null
          },
          analytics_only: false,
          qtype_page: ['existing',0],
          page_size: 1000,
          sort_fields: []
        }
      );
    }
    searchData = (e) => {
      let me = this
      this.setState({query:e.target.value, page: 0}, ()=> {
        me.fetchVendorData();
      })
    }

    handleClear = (event) => {
      event.stopPropagation();
      var me = this;
      this.setState({ query: '',page:0}, () => {
        me.fetchVendorData();
      });
    }

    groupClusterProducts = (e, product) => {
      e.stopPropagation()
      let me = this;
      let products = me.props.clusterProducts || [];
      let existProducts = products.filter(o => o.id === product.id);
      if(existProducts.length > 0){
        products = products.filter(o => o.id !== product.id);
      }else{
        products.push(product)
      }
      let ids = _.map(products, 'id');
      this.setState({
        cluster_products: ids
      })
      this.props.storeClusterGroupProducts(products)
    }

    addProductsIntoExistingGroup =()=> {
      let group_id = this.props.match?.params?.id;
      let products = this.props.clusterProducts || [];
      if(products.length<=0){
        this.props.showSnackBar("Please select products","error",3000);
        return;
      }
      let ids =  products.map(product=> Number(product.id));
      this.props.fetchAddProductToClusterGroup(group_id, {product_ids: ids})
    }

    render() {
        const classes = this.props.classes;
        // var me =     this;
        const content = {
          ...this.props,
          parentClass: classes,
          countLabel: "product",
          pluralCountLabel: "products",
          searchPlaceholder: "Search product",
          isFilterRequired: true
        };

        const new_group = this.props.isNew;
        let existingProducts = this.props.existingProducts || [];
        let allProducts = this.props.appCatalogProducts?.existing?.items || [];
        let filteredProducts = [];
        let excludeProducts =  _.map(existingProducts, 'product_id');
        filteredProducts = _.filter(allProducts, (p) => _.indexOf(excludeProducts, p.id) === -1)
        
        return <Dialog 
                    onClose={this.props.hideDialog} 
                    aria-labelledby="app-integrationDialog" 
                    open={this.props.isOpen}
                    disableBackdropClick={true}
                    fullWidth={true}
                    fullScreen 
                    maxWidth={"md"}
                    scroll="body"
                    className={classes.dialog}
                    id="evMetaDialog"
                    >
                        <DialogContent classes={{root:classes.dialogContent}} id="groupProductsListTarget" >

                        {this.props.fetchAppCatalogProductsProgress && (
                            <div className={classes.progressIndicator}>
                              <CircularProgress />
                            </div>
                        )}

                            <AppBar title="Group Products"  withBack={true} dispatchEvent={true} targetEvent={()=>{
                                        this.props.storeClusterGroupProducts([])
                                        this.props.hideDialog() 
                            }}/>


                            <div className={classes.searchContainer}>
                            <p className={classes.productCount}>
                            {this.props.fetchAppCatalogProductsProgress &&
                                  <>
                                 Loading...
                                  </>
                                
                              }
                              { new_group && !this.props.fetchAppCatalogProductsProgress &&
                                  <>
                                  {filteredProducts.length} {" "}
                                  {filteredProducts.length > 1 ? content.pluralCountLabel : content.countLabel}{" "}
                                  found
                                  </>
                                
                              }

                              { !new_group && !this.props.fetchAppCatalogProductsProgress &&
                                 <>
                                  {(filteredProducts.length) < 0 ? 0 : (filteredProducts.length)} {" "}
                                  {(filteredProducts.length) > 1 ? content.pluralCountLabel : content.countLabel}{" "}
                                  found
                                  </>
                              }
                              </p>
                              <div className={classes.searchSection}>
                                <OutlinedInput
                                  id={"searchProducts"}
                                  placeholder={'Search Products'}
                                  value={this.state.query}
                                  onChange={this.searchData}
                                  startAdornment={
                                    <InputAdornment>
                                      <SearchIcon className={classes.searchIcon} />
                                    </InputAdornment>
                                  }
                                  endAdornment={
                                    <InputAdornment>
                                      {this.state.query.length > 0 && this.state.query !== '' && this.state.query !== null && <TooltipMsg placement="top" title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Clear</span>} arrow>
                                        <Clear className={classes.searchIconPop} onClick={this.handleClear} />
                                      </TooltipMsg>}
                                    </InputAdornment>
                                  }
                                  roundedCorner={true}
                                />
                              </div>
                               
                              {/* <SearchInput
                                  placeholder={'Search Product'}
                                  searchClass={classes.searchSection}
                                  searchData={this.searchData}
                              /> */}

                              <div className={classes.actionSection}>
                              {
                                this.props.isNew === true &&
                                <SqureButton  variant={"contained"} 
                                onClick={()=>{
                                  this.props.showCreateClusterGroupDialog()
                                }}>Group Products</SqureButton>
                              }

                              {
                                this.props.isNew === false && !this.props.addProductInClusterGroupInprogress &&
                                <SqureButton  variant={"contained"} 
                                onClick={()=>{
                                  this.addProductsIntoExistingGroup()
                                }}>Save</SqureButton>
                              }
                              {
                                this.props.addProductInClusterGroupInprogress &&
                                <SqureButton  variant={"contained"} >Loading...</SqureButton>
                              }
                              </div>
                            </div>
                            

                            {/* <div>
                              <span onClick={ } style={{cursor: 'pointer'}} >Group Products</span>
                            </div> */}
                            <div id="groupProductsList" style={{marginBottom: '100px', marginTop: '30px'}}>
                              <InfiniteScroll
                                scrollableTarget="groupProductsListTarget"
                                style={{ display: "flex", flexDirection: "column", overflow: "hidden", marginRight: 'unset' }} //To put endMessage and loader to the top.
                                  dataLength={filteredProducts.length} //This is important field to render the next data
                                  // next={() => {
                                  //   var me = this;
                                  //   this.setState({ page: this.state.page + 1 }, () => {
                                  //     me.fetchVendorData();
                                  //   });
                                  // }}
                                  hasMore={
                                   true
                                  }
                                  endMessage={
                                    this.props.products?.length === 0 &&  this.props.fetchAppCatalogProductsProgress === false && <p className={classes.emptyText}>
                                      <span><span>Not there? Help us serve you better by adding</span> <span className={classes.addProductLink} onClick={() =>  this.props.showAddProductManuallyDialog({name:this.state.query})}>{this.state.query}</span>  </span> 
                                    </p>
                                  }
                                >
                                  <Grid container spacing={3}>
                                      {/* Products list */}
                                      {(new_group ? this.props.products : filteredProducts).map((product, i) => 
                                        {
                                          return ( <Grid item  lg={2} md={3} key={i}>
                                            <div className={classes.productCard}>
                        
                                                <div className={classes.selectIcon}
                                                    onClick={(event) => {
                                                      this.groupClusterProducts(event, product)
                                                    }}
                                                >
                                                    <Checkbox 
                                                      defaultChecked={false}
                                                      checked={this.props.clusterProducts?.filter(o => o.id === product.id).length > 0 ? true : false} />
                                                </div>

                                                <LayeredCard
                                                  title={product.name}
                                                  subTitle={product.company_name}
                                                  description={product.description}
                                                  image={product.logo == null || product.logo.length === 0 ? DefaultImage:product.logo}
                                                  onClick={()=>{}}
                                                  numLayer={0}
                                                  showCheckbox={false}
                                                  id={Number(product.id)}
                                                  uniqueId={product.id+'_x_'+i}
                                                  IsExisting={false}
                                                />
                                              </div>
                                          </Grid>
                                          )}
                                      )}
                                  </Grid>
                              </InfiniteScroll>
                            </div>
                            
                            <div className={classes.actions}>
                                <SqureButton  variant={"outlined"} onClick={()=>{
                                        this.props.storeClusterGroupProducts([])
                                        this.props.hideDialog()
                                    }}>Cancel</SqureButton>&nbsp;&nbsp;
                                {/* <SqureButton  variant={"contained"} 
                                 onClick={()=>{
                                  this.props.showCreateClusterGroupDialog()
                                 }}>Group Products</SqureButton> */}
                            </div>
                            {
                              this.props._createClusterGroupDialog === true &&
                              <CreateClusterGroupProductDialog />
                            }
                        </DialogContent> 
                </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ClusterProductsListDialog));