import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Switch } from 'react-router-dom';

// Actions
import { fetchMasterComponents, fetchNextComponent, fetchWorkflowComponentUserResponse } from "redux/workflow/action";


const connectedProps = (state) => ({
  nextComponentDetails: state.workflow.nextComponentDetails,
  fetchNextComponentProgress: state.workflow.fetchNextComponentProgress,
  componentUserResponse: state.workflow.componentUserResponse,
  fetchComponentUserResponseProgress: state.workflow.fetchComponentUserResponseProgress,
  masterComponentsMap: state.workflow.masterComponentsMap
});

const connectionActions = {
  fetchNextComponent: fetchNextComponent,
  fetchWorkflowComponentUserResponse: fetchWorkflowComponentUserResponse,
  fetchMasterComponents: fetchMasterComponents
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class ChildWorkflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchMasterComponents();
    this.props.fetchNextComponent(this.props.match.params.evaluation_id)
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchNextComponentProgress === false && prevProps.fetchNextComponentProgress === true) {
      var nextComponent = this.props.nextComponentDetails;
        this.props.history.push("/app/evaluate/" + this.props.match.params.evaluation_id + "/workflow/" + nextComponent?.WorkflowID + "/component/" + nextComponent?.ID + "/nextEvaluation/" + this.props.match.params.next_evaluation_id);
    }
  }


  render() {
    const classes = this.props.classes;

    var nextComponent = this.props.nextComponentDetails;

    if (nextComponent === null) {
      return <div className={classes.loader}> <CircularProgress /></div>
    }



    return (
      <div>
        <Switch>
        </Switch>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ChildWorkflow));
