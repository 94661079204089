import { Button, Checkbox, CircularProgress, Dialog, DialogContent, Divider, FormControlLabel, Grid, Slide, Switch, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import classnames from "classnames";
// components
import EvaluateMultiChoiceAnswer from 'Components/Common/EvaluateMultiChoiceAnswer';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import AddApprover from 'Components/Common/UserGroup/AddApprover';
import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import { addCriteria } from "redux/evaluation/criteria/action";
// redux
import { MultiChoiceQuizOptions } from "redux/evaluation/multichoice/action";
import { showSnackBar } from "redux/snackbar/action";
import { hideAddTemplateDialog, selectEvaluationTemplateType, unselectAllTemplate } from 'redux/templates/evaluation/action';
import { fetchMasterTemplate } from "redux/templates/evaluation/master/action";
import { addFromOrgEvaluationCriteriaTemplate, addFromOrgEvaluationRequirementTemplate, fetchOrgTemplate, fetchOrgTemplates, saveFromOrgEvaluationTemplate, updateFromOrgEvaluationCriteriaTemplate, updateFromOrgEvaluationRequirementTemplate } from "redux/templates/evaluation/org/action";
import _ from 'underscore';
import OutlinedInput from "../../../../../Common/Input/OutlinedInput";
import OutlinedSelectInput from "../../../../../Common/Input/OutlinedSelectInput";
import SqureButton from '../../../../../Common/SqureButton';
import UserGroup from "../../../../../Common/UserGroup/index";





const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.templateEvaluation.showAddTemplate,
    evaluationId: state.evaludationCriteria.evaluationId,
    addCriteriaProgress: state.evaludationCriteria.addCriteriaProgress,
    errorCriteriaAdd: state.evaludationCriteria.errorCriteriaAdd,
    saveProgress: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateProg,
    saveError: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateError,
    isRequiredTemplateSection: state.templateEvaluation.isRequiredTemplateSection,
    isRequiredCriteriaSection: state.templateEvaluation.isRequiredCriteriaSection,
    isRequiredRequirementSection: state.templateEvaluation.isRequiredRequirementSection,
    isRequiredAddRequirement: state.templateEvaluation.isRequiredAddRequirement,
    saveButtonText: state.templateEvaluation.saveButtonText,
    settingsTemplateId: state.templateEvaluation.settingsTemplateId,
    settingsCriteriaId: state.templateEvaluation.settingsCriteriaId,
    addCriteriaTemplateProg: state.orgTemplateEvaluation.addFromOrgEvaluationCriteriaTemplateProg,
    addCriteriaTemplateError: state.orgTemplateEvaluation.addFromOrgEvaluationCriteriaTemplateError,
    updateCriteriaTemplateProg: state.orgTemplateEvaluation.updateFromOrgEvaluationCriteriaTemplateProg,
    updateCriteriaTemplateError: state.orgTemplateEvaluation.updateFromOrgEvaluationCriteriaTemplateError,
    addRequirementTemplateProg: state.orgTemplateEvaluation.addFromOrgEvaluationRequirementTemplateProg,
    addRequirementTemplateError: state.orgTemplateEvaluation.addFromOrgEvaluationRequirementTemplateError,
    updateRequirementTemplateProg: state.orgTemplateEvaluation.updateFromOrgEvaluationRequirementTemplateProg,
    updateRequirementTemplateError: state.orgTemplateEvaluation.updateFromOrgEvaluationRequirementTemplateError,
    fetchTemplateProg: (state.orgTemplateEvaluation.fetchTemplateProg || state.masterTemplateEvaluation.fetchTemplateProg),
    criteria: state.templateEvaluation.criteria,
    requirement: state.templateEvaluation.requirement,
    templatesWithCriterias: state.masterTemplateEvaluation.templatesWithCriterias,
    orgTemplatesWithCriterias: state.orgTemplateEvaluation.templatesWithCriterias,
    templateMasterIds: state.templateEvaluation.templateMasterIds,
    templateOrgIds: state.templateEvaluation.templateOrgIds,
    user: state.authUser.user,
    orgTemplateForImport: state.orgTemplateEvaluation.template,
    masterTemplateForImport: state.masterTemplateEvaluation.template,
    quizOptions: state.criteriaMultiChoice.quizOptions
});

const connectionActions = {
    hideDialog: hideAddTemplateDialog,
    addCriteria: addCriteria,
    showSnackBar: showSnackBar,
    saveFromOrgEvaluationTemplate: saveFromOrgEvaluationTemplate,
    fetchOrgTemplates: fetchOrgTemplates,
    fetchOrgTemplate: fetchOrgTemplate,
    addFromOrgEvaluationRequirementTemplate: addFromOrgEvaluationRequirementTemplate,
    updateFromOrgEvaluationRequirementTemplate: updateFromOrgEvaluationRequirementTemplate,
    selectEvaluationTemplateType: selectEvaluationTemplateType,
    updateFromOrgEvaluationCriteriaTemplate: updateFromOrgEvaluationCriteriaTemplate,
    addFromOrgEvaluationCriteriaTemplate: addFromOrgEvaluationCriteriaTemplate,
    unselectAllTemplate: unselectAllTemplate,
    fetchMasterTemplate: fetchMasterTemplate,
    MultiChoiceQuizOptions: MultiChoiceQuizOptions
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important",
    },
    modalTitle: {
        fontSize: theme.spacing(2.4),
        marginBottom: theme.spacing(3),
        textAlign: "center",
    },
    helptext: {
        fontSize: theme.spacing(1.8)
    },
    alerts: {
        marginTop: theme.spacing(4),
        padding: '0px 40px'
    },
    form: {
        marginTop: theme.spacing(2),
        width: '60%',
    },
    input: {
        marginBottom: theme.spacing(3)
    },
    scheduleTitle: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 600,
        fontSize: theme.spacing(2)
    },
    scheduleTitle1: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 600,
        fontSize: theme.spacing(1.8)
    },
    actions: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: "center",
    },
    loader: {
        textAlign: "center",
        padding: theme.spacing(10, 0)
    },
    dialogAction: {
        textAlign: "center"
    },
    addNewButton: {
        fontWeight: 600,
        fontSize: theme.spacing(2)
    },
    actionBtn: {
        minWidth: 110
    },
    nameYour: {
        "& [class*=MuiInputBase-root]": {
            maxHeight: 40,
            minHeight: 40,
            "& input": {
                fontSize: theme.spacing(1.8)
            }
        },
    },
    nameOfCriteria: {
        "& > div": {
            marginBottom: 0
        },
    },
    priority: {
        "& > div": {
            marginBottom: 0,
            justifyContent: 'flex-start',
        },
        "& [class*=MuiInputBase-root]": {
            width: 100,
            color: '#707070',
            fontSize: 13,
            textAlign: 'center'
        },
        "& [class*=MuiSelect-outlined]": {
            color: "#707070",
            fontSize: theme.spacing(1.8),
            "&:after": {
                content: "''",
                width: 7,
                height: 7,
                position: 'absolute',
                left: 9,
                borderRadius: '50%',
                top: '50%',
                transform: 'translate(0px, -50%)'
            },
        },
        "&.low [class*=MuiSelect-outlined]:after": {
            background: 'green'
        },
        "&.medium [class*=MuiSelect-outlined]:after": {
            background: 'orange'
        },
        "&.high [class*=MuiSelect-outlined]:after": {
            background: 'red'
        }
    },
    scoring: {
        "& > div": {
            alignItems: "baseline",
            marginBottom: 0
        },
        "& [class*=MuiSelect-outlined]": {
            paddingLeft: 10
        }
    },
    yourQuestion: {
        "& [class*=MuiInputBase-root]": {
            padding: 0,
            fontSize: theme.spacing(1.8),
            minHeight: 120,
            color: "#707070",
            lineHeight: 1.2,
            "& textarea": {
                minHeight: 100,
            }
        }
    },
    addCriteriaDatePicker: {
        "& .react-datepicker__input-container > div": {
            display: 'flex',
            minWidth: 300,
            alignItems: 'baseline',
            "& [class*=MuiInputBase-root]": {
                maxHeight: 33,
                minHeight: 33,
                width: 166,
                "& input": {
                    fontSize: theme.spacing(1.8),
                    // width: 107,
                    textAlign: "center"
                }
            },
            "& [class*=MuiFormLabel-root]": {
                marginBottom: 0,
                marginRight: 29
            }
        },
        "[class*=MuiMenuItem-root]": {
            color: "#707070",
            fontSize: theme.spacing(1.8)
        }
    },
    criteriaPriority: {
        minHeight: '25px !important',
        padding: 6,
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        color: '#5F5F5F',
        fontSize: '1.1em',
        marginLeft: 20,
        width: 75,
        textAlign: 'center'
    }
});

class AddTemplateDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            activeMultiSelectId: false,
            name: "",
            templateName: '',
            template_name_error: '',
            name_error: "",
            is_mandatory: false,
            is_noneditable: false,
            criteria_schedule: null,
            criteria_schedule_error: "",
            colloborators: [],
            activeAnswerType: 1,
            requirements: [{
                'name': '',
                'name_error': '',
                'priority': 50,
                'scoring_type': 1,
                'description': '',
                'description_error': '',
                'colloborators': [],
                'answer_type': 1,
                'options': [],
                is_mandatory: false,
                is_editable: false,
                is_other_option: false,
            }]
        }

        this.addExtraRequirement = this.addExtraRequirement.bind(this);
        this.deleteRequirement = this.deleteRequirement.bind(this);
        this.addCriteriaColloborator = this.addCriteriaColloborator.bind(this);
        this.addRequirementColloborator = this.addRequirementColloborator.bind(this);
        this.submitResponse = this.submitResponse.bind(this);
        this.cleanError = this.cleanError.bind(this);
        this.cleanState = this.cleanState.bind(this);
    }

    cleanState() {
        this.setState({
            error: "",
            success: "",
            name: "",
            name_error: "",
            templateName: '',
            activeMultiSelectId: false,
            template_name_error: '',
            criteria_schedule: null,
            criteria_schedule_error: "",
            is_mandatory: false,
            is_noneditable: false,
            colloborators: [],
            activeAnswerType: 1,
            requirements: [{
                'name': '',
                'name_error': '',
                'priority': 50,
                'scoring_type': 1,
                'answer_type': 1,
                'options': [],
                'description': '',
                'description_error': '',
                'colloborators': [],
                is_mandatory: false,
                is_editable: false,
                is_other_option: false,
            }]
        });
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isOpen && prevProps.isOpen) {
            this.props.MultiChoiceQuizOptions([])
        }
        if (this.props.isOpen && !prevProps.isOpen) {
            this.props.MultiChoiceQuizOptions([])
            if (this.props.selectedTemplate !== null) {
                if (this.props.selectedTemplate?.type === "MASTER") {
                    this.props.fetchMasterTemplate(this.props.selectedTemplate.templateId)
                } else if (this.props.selectedTemplate?.type === "ORG") {
                    this.props.fetchOrgTemplate(this.props.selectedTemplate.templateId)
                }
            }
            if (this.props.criteria && Object.keys(this.props.criteria).length) {
                this.setState({
                    name: this.props.criteria.Name,
                    is_mandatory: this.props.criteria.IsMandatory,
                    is_noneditable: !this.props.criteria.IsEditable,
                })
            }

            if (this.props.requirement && Object.keys(this.props.requirement).length) {
                let requirement = {
                    'name': this.props.requirement.Name,
                    'name_error': '',
                    'priority': this.props.requirement.Priority,
                    'scoring_type': this.props.requirement.ScoringType,
                    'description': this.props.requirement.Description,
                    'answer_type': this.props.requirement.AnswerType,
                    'options': this.props.requirement.Options,
                    'description_error': '',
                    'colloborators': this.props.requirement.Collaborators,
                }
                this.setState({
                    requirements: [requirement]
                })
            }
        }

        if (this.props.saveProgress === false && prevProps.saveProgress === true) {
            if (this.props.saveError == null) {
                this.props.hideDialog();
                this.cleanState();
                this.props.showSnackBar("Template Saved", "success", 3000);
                this.props.fetchOrgTemplates(0, 60);
                this.props.selectEvaluationTemplateType("ORG")
                if (!this.props.isRequiredCriteriaSection && !this.props.isRequiredRequirementSection) {
                    this.props.history.push('/app/settings/templates/evaluation');
                    this.props.unselectAllTemplate();
                }
            } else {
                if (this.props.saveError.message !== undefined) {
                    this.setState({
                        "error": "Failed to save template."
                    })
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }

        if (this.props.addRequirementTemplateProg === false && prevProps.addRequirementTemplateProg === true) {
            if (this.props.addRequirementTemplateError == null) {
                this.props.hideDialog();
                this.cleanState();
                this.props.showSnackBar("Question saved successfully", "success", 3000);
                this.fetchData();
            } else {
                if (this.props.addRequirementTemplateError.message !== undefined) {
                    this.setState({
                        "error": "Failed to save question."
                    })
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }

        if (this.props.updateCriteriaTemplateProg === false && prevProps.updateCriteriaTemplateProg === true) {
            if (this.props.updateCriteriaTemplateError == null) {
                this.props.hideDialog();
                this.cleanState();
                this.props.showSnackBar("Criteria updated successfully", "success", 3000);
                this.fetchData();
            } else {
                if (this.props.updateCriteriaTemplateError.message !== undefined) {
                    this.setState({
                        "error": "Failed to update criteria."
                    })
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }

        if (this.props.updateRequirementTemplateProg === false && prevProps.updateRequirementTemplateProg === true) {
            if (this.props.updateRequirementTemplateError == null) {
                this.props.hideDialog();
                this.cleanState();
                this.props.showSnackBar("Question updated successfully", "success", 3000);
                this.fetchData();
            } else {
                if (this.props.updateRequirementTemplateError.message !== undefined) {
                    this.setState({
                        "error": "Failed to update Question."
                    })
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }

        if (this.props.addCriteriaTemplateProg === false && prevProps.addCriteriaTemplateProg === true) {
            if (this.props.addCriteriaTemplateError == null) {
                this.props.hideDialog();
                this.cleanState();
                this.props.showSnackBar("criteria added successfully", "success", 3000);
                this.fetchData();
            } else {
                if (this.props.addCriteriaTemplateError.message !== undefined) {
                    this.setState({
                        "error": "Failed to add criteria."
                    })
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }
    }

    fetchData = () => {
        let id = this.props.match.params.id;
        if (this.props.location.pathname.indexOf("custom") === -1) {
            this.props.fetchMasterTemplate(id)
        } else {
            this.setState({
                isDefaultTemplate: false
            })
            this.props.fetchOrgTemplate(id)
        }
    }

    cleanError() {
        var requirements = this.state.requirements;
        requirements.forEach((r) => {
            r.name_error = "";
            r.description_error = "";
        })
        this.setState({
            name_error: "",
            criteria_schedule_error: "",
            requirements: requirements
        })
    }

    submitResponse() {
        this.cleanError();
        // validation
        var templateName = this.state.templateName;
        if (this.props.isRequiredTemplateSection && this.state.templateName.trim().length === 0) {
            this.setState({
                template_name_error: "Please enter Template Name."
            });
            return;
        }

        // validation
        var name = this.state.name;
        if (this.props.isRequiredCriteriaSection && name.trim().length === 0) {
            this.setState({
                name_error: "Please enter Section Name."
            });
            return;
        }

        // validation
        var requirements = this.state.requirements;
        if (this.props.isRequiredRequirementSection) {
            for (var i = 0; i < requirements.length; i++) {
                var requirement = requirements[0];
                var rName = requirement.name;
                if (rName.trim().length === 0) {
                    requirement.name_error = "Please enter question."
                    this.setState({
                        requirements: requirements
                    });
                    return;
                }

                var rDesc = requirement.description;
                if (rDesc.trim().length === 0) {
                    requirement.description_error = "Please explain the requirement"
                    this.setState({
                        requirements: requirements
                    });
                    return;
                }
            }
        }


        // save or update data
        if (this.props.isRequiredTemplateSection) {
            var data = {}
            if (this.props.isRequiredCriteriaSection && this.props.isRequiredRequirementSection) {
                data = {
                    name: templateName,
                    criterias: [{
                        name: name,
                        is_editable: !this.state.is_noneditable,
                        is_mandatory: this.state.is_mandatory,
                        collaborators: this.state.colloborators.map((c) => {
                            return { userId: c.ID, isApprover: c.IsApprover }
                        }),
                        requirements: this.state.requirements.map((r) => {
                            return {
                                name: r.name,
                                description: r.description,
                                priority: parseInt(r.priority),
                                scoring_type: parseInt(r.scoring_type),
                                answer_type: parseInt(r.answer_type),
                                options: r.options,
                                collaborators: r.colloborators.map((c) => {
                                    return c.ID
                                }),
                            }
                        })
                    }]
                }
                this.props.saveFromOrgEvaluationTemplate(data)
            } else {
                var criterias = [];
                if (this.props.selectedTemplate !== null) {
                    var templateCriteria = []
                    if (this.props.selectedTemplate.type === "ORG") {
                        templateCriteria = this.props.orgTemplateForImport.Criterias;
                    } else if (this.props.selectedTemplate.type === "MASTER") {
                        templateCriteria = this.props.masterTemplateForImport.Criterias;
                    }
                }

                criterias = templateCriteria.map((c) => {
                    return {
                        name: c.Name,
                        is_editable: c.IsEditable,
                        is_mandatory: c.IsMandatory,
                        collaborators: c.Collaborators === undefined ? [] : c.Collaborators.map((cc) => {
                            return { userId: cc.ID, isApprover: cc.IsApprover }
                        }),
                        requirements: c.Requirements.map((r) => {

                            let rules = []
                            if (r.Rules && r.Rules.length > 0) {
                                r.Rules.forEach((rule) => {
                                    var ruleElement = [];
                                    rule.Elements.forEach(function (ruleElem, k) {
                                        ruleElement.push({
                                            leftExp: ruleElem.LeftExpression,
                                            opId: ruleElem.OpID,
                                            rightExp: ruleElem.RightExpression,
                                            seq: ruleElem.Seq
                                        })
                                    })
                                    rules.push({
                                        conditions: ruleElement,
                                        action: {
                                            type: rule.ActionType,
                                            data: rule.ActionData
                                        }
                                    })
                                })
                            }

                            return {
                                name: r.Name,
                                description: r.Description,
                                priority: parseInt(r.Priority),
                                scoring_type: parseInt(r.ScoringType),
                                answer_type: parseInt(r.AnswerType),
                                is_editable: r.IsEditable,
                                is_mandatory: r.IsMandatory,
                                rules: rules,
                                options: r.Options,
                                collaborators: r.Collaborators === undefined ? [] : r.Collaborators.map((rc) => {
                                    return rc.ID
                                }),
                            }
                        })
                    }
                })


                data = {
                    name: templateName,
                    criterias: criterias
                }
                this.props.saveFromOrgEvaluationTemplate(data)
            }
        } else if (this.props.isRequiredCriteriaSection && this.props.settingsCriteriaId) {
            this.props.updateFromOrgEvaluationCriteriaTemplate(this.props.settingsTemplateId, this.props.settingsCriteriaId, {
                name: name,
                is_editable: !this.state.is_noneditable,
                is_mandatory: this.state.is_mandatory,
                collaborators: this.state.colloborators.map((c) => {
                    return { userId: c.ID, isApprover: c.IsApprover }
                }),
            })
        } else if (this.props.isRequiredCriteriaSection) {
            this.props.addFromOrgEvaluationCriteriaTemplate(this.props.settingsTemplateId, {
                name: name,
                is_editable: !this.state.is_noneditable,
                is_mandatory: this.state.is_mandatory,
                collaborators: this.state.colloborators.map((c) => {
                    return { userId: c.ID, isApprover: c.IsApprover }
                }),
                requirements: this.state.requirements.map((r) => {
                    return {
                        name: r.name,
                        description: r.description,
                        priority: parseInt(r.priority),
                        scoring_type: parseInt(r.scoring_type),
                        answer_type: parseInt(r.answer_type),
                        options: r.options,
                        collaborators: r.colloborators.map((c) => {
                            return c.ID
                        }),
                    }
                })
            })
        } else if (this.props.isRequiredRequirementSection && this.props.requirement && this.props.requirement.ID) {
            console.log('update template section question')
            this.props.updateFromOrgEvaluationRequirementTemplate(this.props.settingsTemplateId, this.props.settingsCriteriaId, this.props.requirement.ID,
                {
                    "name": this.state.requirements[0].name,
                    "description": this.state.requirements[0].description,
                    "priority": parseInt(this.state.requirements[0].priority),
                    "scoring_type": parseInt(this.state.requirements[0].scoring_type),
                    answer_type: parseInt(this.state.requirements[0].answer_type),
                    options: this.props.quizOptions?.length ? this.props.quizOptions : this.state.requirements[0].options,
                    collaborators: this.state.requirements[0].colloborators.map((c) => {
                        return c.ID
                    }),
                }
            )
        } else if (this.props.isRequiredRequirementSection) {
            this.props.addFromOrgEvaluationRequirementTemplate(this.props.settingsTemplateId, this.props.settingsCriteriaId,
                {
                    "name": this.state.requirements[0].name,
                    "description": this.state.requirements[0].description,
                    "priority": parseInt(this.state.requirements[0].priority),
                    "scoring_type": parseInt(this.state.requirements[0].scoring_type),
                    answer_type: parseInt(this.state.requirements[0].answer_type),
                    options: this.state.requirements[0].options,
                    collaborators: this.state.requirements[0].colloborators.map((c) => {
                        return c.ID
                    }),
                }
            )
        }
    }

    addCriteriaColloborator(user) {

        var colloborators = this.state.colloborators;
        var existing = colloborators.filter((u) => {
            if (u.ID === user.ID) {
                return true;
            }
            return false;
        })
        if (existing.length > 0) {
            return;
        }

        colloborators.push({ ...user, IsApprover: user.isApprover });
        this.setState({ colloborators: colloborators });
    }

    addRequirementColloborator(index, user) {
        var requirements = this.state.requirements;
        var requirement = requirements[index];
        var colloborators = requirement.colloborators;
        var existing = colloborators.filter((u) => {
            if (u.ID === user.ID) {
                return true;
            }
            return false;
        })
        if (existing.length > 0) {
            return;
        }
        colloborators.push(user);
        this.setState({ requirements: requirements });
    }

    removeTemplateRequirementCollaborator = (index, user) => {
        var requirements = this.state.requirements;
        var requirement = requirements[index];
        var colloborators = requirement.colloborators;
        colloborators.map((u, removeIndex) => {
            if (u.ID === user.ID) {
                colloborators.splice(removeIndex, 1)
            }
            return u
        })
        requirements[index].colloborators = colloborators;
        this.setState({ requirements: requirements });
    }

    addExtraRequirement() {
        var requirements = this.state.requirements;
        requirements.push({
            'name': '',
            'name_error': '',
            'priority': 50,
            'scoring_type': 1,
            'description': '',
            'description_error': '',
            'colloborators': [],
            is_mandatory: false,
            is_editable: false,
            is_other_option: false,
        });
        this.setState({
            requirements: requirements
        });
    }

    handleOptions = (id, answerType) => {
        let activeMultiSelectId = id
        if ([2, 3, 4].includes(answerType)) {
            const element = document.getElementById(activeMultiSelectId)
            if (element && element.click()) {
                element.click()
            }
            if (activeMultiSelectId !== this.state.activeMultiSelectId) {
                this.setState({ activeMultiSelectId, activeAnswerType: answerType })
            }
        }
    }

    handleUpdateRequirement = (index, requirement, key, value, options = false, is_other_option = false, character_limit = 0) => {
        const newRequirement = requirement
        newRequirement[key] = value
        if (options) {
            newRequirement.options = options
            newRequirement.is_other_option = is_other_option
        }
        if (key === 'answer_type' && value === 7) {
            newRequirement.character_limit = character_limit
        }
        const requirements = this.state.requirements
        requirements[index] = newRequirement
        if (key !== 'answer_type') this.handleOptions(`evalution_criteria_requiment-${index}`, requirement.answer_type)
        this.setState({ requirements })
    }

    deleteRequirement(index) {
        if (this.state.requirements.length === 1) {
            return;
        }
        var requirements = this.state.requirements;
        requirements.splice(index, 1)
        this.setState({
            requirements: requirements
        });
    }

    updateNewCriteriaColloborator = ({ user } = {}) => {
        if (user) {
            var colloborators = this.state.colloborators;
            colloborators.map((u, index) => {
                if (u.ID === user.ID) {
                    if (user.isRemove) {
                        colloborators.splice(index, 1);
                    } else {
                        colloborators[index] = { ...user, IsApprover: user.isApprover };
                    }
                }
                return u
            })
            this.setState({ colloborators: colloborators });
        }
    }

    render() {
        const classes = this.props.classes;
        var isEditable = true;

        if (this.props.user?.Role === "OrgAdmin" || this.props.evaluation?.UserID === this.props.user?.ID) {
            isEditable = true
        }

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-addtemplate"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="addtemplateDialog"
        >
            {/*  Alert messages */}
            <div className={classes.alerts}>
                {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
            </div>

            {/* Template Name */}
            {this.props.isRequiredTemplateSection && <>
                <DialogContent classes={{ root: classes.dialogContent }} >
                    <div>
                        <Typography variant={"h2"} className={classes.modalTitle}>Add New Template</Typography>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <div className={classnames(classes.nameOfCriteria, classes.nameYour)}>
                                    <OutlinedInput
                                        label="Name your Template"
                                        error={this.state.template_name_error.length > 0}
                                        helperText={this.state.template_name_error}
                                        value={this.state.templateName}
                                        onChange={(event) => {
                                            this.setState({ templateName: event.target.value });
                                        }}
                                    />
                                </div>
                            </Grid>

                        </Grid>
                    </div>
                </DialogContent>
                <Divider />
            </>}

            {/* Name your Section */}
            {this.props.isRequiredCriteriaSection &&
                <div>
                    <DialogContent classes={{ root: classes.dialogContent }} >
                        <div className={classes.criteriaDetail}>
                            <Typography variant={"h2"} className={classes.modalTitle}> {this.props.saveButtonText === 'Update' ? 'Update' : 'Add New'} Criteria</Typography>

                            <Grid container>
                                <Grid item xs={12} lg={12}>
                                    <div className={classnames(classes.nameOfCriteria, classes.nameYour)}>
                                        <OutlinedInput
                                            label="Name your Section"
                                            error={this.state.name_error.length > 0}
                                            helperText={this.state.name_error}
                                            value={this.state.name}
                                            onChange={(event) => {
                                                this.setState({ name: event.target.value });
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container alignItems="center" justifyContent="space-between">
                                <div>
                                    <Grid container>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.is_mandatory}
                                                        onChange={(event) => {
                                                            this.setState({ is_mandatory: event.target.checked })

                                                        }}
                                                        name="mandatory"
                                                    />
                                                }
                                                label="Mandatory"
                                            />
                                        </div>
                                        <div>
                                            {!this.state.is_mandatory && <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.is_noneditable}
                                                        onChange={(event) => {
                                                            this.setState({ is_noneditable: event.target.checked })

                                                        }}
                                                        name="editable"
                                                    />
                                                }
                                                label="Non Editable"
                                            />}
                                        </div>
                                    </Grid>
                                </div>
                                <div>
                                    {!(this.props.isRequiredCriteriaSection && this.props.settingsCriteriaId) && <AddApprover
                                        evaluationId={this.props.match.params.id}
                                        isfromSettingsTemplate={true}
                                        isNew={true}
                                        criterias={[{ id: 0, Collaborators: this.state.colloborators }]}
                                        criteriaId={0}
                                        editable={isEditable}
                                        updateNewCollaborator={this.updateNewCriteriaColloborator}
                                        onAdd={(user) => {
                                            this.addCriteriaColloborator(
                                                user)
                                        }}
                                        users={this.state.colloborators} />}
                                </div>
                            </Grid>
                        </div>
                    </DialogContent>
                    <Divider />
                </div>
            }

            {/* Section Questions */}
            {this.props.isRequiredRequirementSection &&
                this.state.requirements.map((requirement, k) => {
                    return <React.Fragment key={k}>
                        <DialogContent classes={{ root: classes.dialogContent }} >
                            <Typography variant={"h2"} className={classes.modalTitle}>{this.props.saveButtonText === 'Update' ? 'Update' : 'Add New'} Requirement</Typography>
                            <div className={classes.criteriaDetail}>
                                <Grid container>
                                    <Grid item xs={12} lg={12}>
                                        <div className={classes.nameYour}>
                                            <OutlinedInput
                                                label="Name Your Question"
                                                error={requirement.name_error.length > 0}
                                                helperText={requirement.name_error}
                                                value={requirement.name}
                                                onChange={(event) => {
                                                    this.handleUpdateRequirement(k, requirement, 'name', event.target.value)
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <div className={classes.yourQuestion}>
                                            <SlateInputField
                                                label="Explain Your Question"
                                                error={requirement.description_error.length > 0}
                                                helperText={requirement.description_error}
                                                value={requirement.description}
                                                folderPath={`/templates`}
                                                placeholder=""
                                                as={SlateInputField}
                                                onChangeEvent={(value) => {
                                                    this.handleUpdateRequirement(k, requirement, 'description', value)
                                                }}
                                                initValue={requirement.description}
                                                textContainerStyle={{ color: "#707070", }} />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="space-between" spacing={3}>
                                    <Grid item xs={6}>
                                        <EvaluateMultiChoiceAnswer
                                            editable={true}
                                            isCriteriaRequirement
                                            requirement={{
                                                answer_type: requirement.answer_type,
                                                options: requirement.options
                                            }}
                                            saveBtnId={`evalution_criteria_requiment-${k}`}
                                            isNew={true}
                                            onChange={(answerType, options, is_other_option, character_limit) => {
                                                this.handleUpdateRequirement(k, requirement, 'answer_type', answerType, options, is_other_option, character_limit)
                                            }}
                                        />
                                    </Grid>
                                    <Divider orientation="vertical" flexItem />
                                    <Grid item xs={4}>
                                        {!(this.props.isRequiredRequirementSection && this.props.requirement && this.props.requirement.ID) && <UserGroup
                                            isfromSettingsTemplate={true}
                                            criteriaId={k}
                                            isNew={true}
                                            editable={isEditable}
                                            onAdd={(user) => {
                                                this.addRequirementColloborator(k, user)
                                            }}
                                            updateNewCollaborator={({ user } = {}) => {
                                                this.removeTemplateRequirementCollaborator(k, user);
                                            }}
                                            evaluationId={this.props.match.params.id}
                                            criterias={[{ id: k, Collaborators: requirement.colloborators }]}
                                            users={requirement.colloborators} />}
                                        <br />
                                        {false && <div className={classnames(classes.priority, requirement.priority && requirement.priority === '1' ? 'low' : requirement.priority && requirement.priority === '2' ? 'medium' : requirement.priority && requirement.priority === '3' ? 'high' : '')}>
                                            <OutlinedSelectInput
                                                label="Priority"
                                                labelStyle={"inline"}
                                                id="priority"
                                                value={requirement.priority}
                                                options={[
                                                    {
                                                        value: "1",
                                                        label: "Low"
                                                    },
                                                    {
                                                        value: "2",
                                                        label: "Medium"
                                                    },
                                                    {
                                                        value: "3",
                                                        label: "High"
                                                    }

                                                ]}
                                                onChange={(event) => {
                                                    this.handleUpdateRequirement(k, requirement, 'priority', event.target.value)
                                                }}
                                            />
                                        </div>}

                                        <div className={classes.priorityC}>
                                            <span className={classes.label}>Weightage</span>
                                            <input
                                                value={requirement.priority}
                                                className={classes.criteriaPriority}
                                                onChange={(event) => {
                                                    var priority = parseInt(event.target.value) || '';
                                                    if (priority < 0 || priority > 100) {
                                                        alert('Please enter weightage between 1 to 100.')
                                                        return;
                                                    } else {
                                                        this.handleUpdateRequirement(k, requirement, 'priority', event.target.value)
                                                    }
                                                }}
                                            ></input>&nbsp;%
                                        </div>
                                        <div className={classes.rightAction} style={{ marginTop: 10 }}>
                                            <Grid container alignItems="center">
                                                <Grid item>
                                                    <label className={classes.label} style={{ marginRight: 8 }}>
                                                        Required
                                                    </label>
                                                    <br />
                                                    <br />
                                                    <label className={classes.label} style={{ marginRight: 8 }}>
                                                        Editable
                                                    </label>

                                                </Grid>
                                                <Grid item>
                                                    <Switch
                                                        checked={requirement.is_mandatory}
                                                        onChange={event => {
                                                            event.stopPropagation()
                                                            this.handleUpdateRequirement(k, requirement, 'is_mandatory', event.target.checked)
                                                        }}
                                                        name="is_mandatory"
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'is_mandatory checkbox' }}
                                                    />
                                                    <br />
                                                    <Switch
                                                        checked={requirement.is_editable}
                                                        onChange={event => {
                                                            event.stopPropagation()
                                                            this.handleUpdateRequirement(k, requirement, 'is_editable', event.target.checked)
                                                        }}
                                                        color="primary"
                                                        name="is_editable"
                                                        inputProps={{ 'aria-label': 'is_editable checkbox' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <Divider />
                    </React.Fragment>
                })
            }
            {
                !this.props.fetchTemplateProg && <DialogContent classes={{ root: classes.dialogAction }} >
                    {this.props.isRequiredAddRequirement && <Button className={classes.addNewButton}
                        onClick={e => {
                            e.stopPropagation()
                            this.handleOptions(this.state.activeMultiSelectId, this.state.activeAnswerType)
                            _.debounce(() => {
                                this.addExtraRequirement()
                            }, 1000)();
                        }}>+ Add new Question</Button>}
                    {!this.props.saveProgress && <div className={classes.actions}>
                        <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={() => { this.cleanState(); this.props.hideDialog() }}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                        <SqureButton cls={classes.actionBtn} variant={"contained"}
                            onClick={(e) => {
                                e.stopPropagation()
                                this.handleOptions(this.state.activeMultiSelectId, this.state.activeAnswerType)
                                _.debounce(() => {
                                    this.submitResponse()
                                }, 1000)();
                            }}>{this.props.saveButtonText}</SqureButton>
                    </div>}
                    {this.props.saveProgress && <div className={classes.actions}>
                        <CircularProgress />
                    </div>}
                </DialogContent>
            }

        </Dialog >
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AddTemplateDialog));