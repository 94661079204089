import {
    START_EVALUTION_META_FETCH,
    END_EVALUTION_META_FETCH,
    START_SELLER_THREAD_FETCH,
    END_SELLER_THREAD_FETCH,
    START_SELLER_RESPONSE_SUBMIT, END_SELLER_RESPONSE_SUBMIT,
    SHOW_SELLER_COLLABORATOR_DIALOG, HIDE_SELLER_COLLABORATOR_DIALOG,
    SHOW_ADD_NEW_COLLABORATOR_DIALOG, HIDE_ADD_NEW_COLLABORATOR_DIALOG,
    START_SELLER_COLLOBORATOR_FETCH, END_SELLER_COLLOBORATOR_FETCH,
    START_SELLER_COLLOBORATOR_ADD, END_SELLER_COLLOBORATOR_ADD,
    START_SELLER_REQ_COLLOBORATOR_FETCH, END_SELLER_REQ_COLLOBORATOR_FETCH,
    START_SELLER_REQ_COLLOBORATOR_ADD, END_SELLER_REQ_COLLOBORATOR_ADD,
    START_SELLER_DRAFT_RESPONSE_SUBMIT, END_SELLER_DRAFT_RESPONSE_SUBMIT,
    SHOW_SUBMIT_ALL_SELLER_RESPONSES_DIALOG, HIDE_SUBMIT_ALL_SELLER_RESPONSES_DIALOG,
    START_SELLER_DRAFT_RESPONSES_FETCH, END_SELLER_DRAFT_RESPONSES_FETCH,
    START_SELLER_DRAFT_RESPONSES_UPDATE, END_SELLER_DRAFT_RESPONSES_UPDATE,
    START_SELLER_REQUIREMENT_RESPONSES_ADD, END_SELLER_REQUIREMENT_RESPONSES_ADD,
    SHOW_SELLER_FINALIZE_REQ_DIALOG, HIDE_SELLER_FINALIZE_REQ_DIALOG,
    START_SELLER_REQ_TREE_FETCH, END_SELLER_REQ_TREE_FETCH,
    START_SELLER_USER_LAST_RESPONSE_FETCH, END_SELLER_USER_LAST_RESPONSE_FETCH,
    SET_SELECTED_REQUIREMENT_FOR_SELLER, START_FETCH_SELLER_ACTIVITY, END_FETCH_SELLER_ACTIVITY,
    START_REQUIREMENT_RESPONSES_ADD_IN_BULK, END_REQUIREMENT_RESPONSES_ADD_IN_BULK,
    START_SELLER_INSTRUCTION_FETCH, END_SELLER_INSTRUCTION_FETCH,
    START_SELLER_POPUP_STATE_UPDATE, END_SELLER_POPUP_STATE_UPDATE,
    START_FETCH_PRODUCT_RFP_MAP, END_FETCH_PRODUCT_RFP_MAP
} from "./action";

const initState = {
    metaFetchProgress: false,
    evaluation: null,
    threadFetchProgress: false,
    thread: [],
    requirement: {},
    response: {},
    submitResponseProgress: false,
    sellerCollaboratorDialogOpen: false,
    sellerCollaboratorDialogData: null,

    addNewCollaboratorDialogOpen: false,

    fetchSellerReqColloboratorProgress: false,
    sellerRequirementColloborators: null,
    addSellerReqColloboratorProgress: false,

    submitDraftResponseProgress: false,
    submitAllSellerResponseDialogOpen: false,

    fetchSellerDraftResponsesProgress: false,
    fetchSellerDraftResponsesError: null,
    sellerDraftResponses: [],

    updateSellerDraftResponsesProgress: false,

    addSellerRequirementResponseProgress: false,
    showRequirementType: '',
    showRequirementFinalizeDialog: false,

    isShowViewDetails: true,

    requirementTree: [],
    fetchRequirementTreeProgress: false,

    lastUserResponse: {},
    fetchLastUserResponseProgress: false,

    requirementMap: {},
    evaluationMetafetchError: null,

    selectedRequirementId: null,
    requirementTreeError: null,

    sellerActivity: [],
    sellerActivityProg: false,

    addBulkResponseProgress: false,
    addBulkResponseError: null,
    addBulkResponseSuccess: null,

    sellerInstructionProgress: true,
    sellerInstructionError: null,
    sellerInstructionSuccess: null,

    updateSellerStateProgress: false,
    updateSellerStateError: null,
    updateSellerStateSuccess: null,

    productRFPMapProgress: false,
    productRFPMapError: null,
    productRFPMapSuccess: {},
}

export function sellerReducer(state = initState, action) {
    switch (action.type) {
        case START_FETCH_SELLER_ACTIVITY:
            return {
                ...state,
                sellerActivity: [],
                sellerActivityProg: true,
            };
        case END_FETCH_SELLER_ACTIVITY:
            let sellerActivity = state.sellerActivity
            if (action.payload.error === null) {
                sellerActivity = action.payload.success
            }
            return {
                ...state,
                sellerActivity,
                sellerActivityProg: false,
            }
        case START_EVALUTION_META_FETCH:
            return {
                ...state,
                metaFetchProgress: true,
                evaluationMetafetchError: null
            };
        case END_EVALUTION_META_FETCH:
            const { success, error } = action.payload;
            return {
                ...state,
                metaFetchProgress: false,
                evaluation: success,
                evaluationMetafetchError: error,
            }
        case START_SELLER_THREAD_FETCH:
            return {
                ...state,
                threadFetchProgress: true,
                thread: [],
                requirement: {},
                response: {},
            }
        case END_SELLER_THREAD_FETCH:
            return {
                ...state,
                threadFetchProgress: false,
                thread: action.payload.success?.thread || [],
                requirement: action.payload.success?.requirement,
                response: action.payload.success?.response
            }
        case START_SELLER_RESPONSE_SUBMIT:
            return {
                ...state,
                submitResponseProgress: true
            }

        case END_SELLER_RESPONSE_SUBMIT:
            var _thread = [...state.thread];
            if (action.payload.error == null && action.payload.success !== null) {
                _thread.push(action.payload.success)
            }
            return {
                ...state,
                submitResponseProgress: false,
                thread: _thread
            }

        case SHOW_SELLER_COLLABORATOR_DIALOG:
            return {
                ...state,
                sellerCollaboratorDialogOpen: true,
                sellerCollaboratorDialogData: action.payload.target || null
            };

        case HIDE_SELLER_COLLABORATOR_DIALOG:
            return {
                ...state,
                sellerCollaboratorDialogOpen: false,
                sellerCollaboratorDialogData: null
            };

        case SHOW_ADD_NEW_COLLABORATOR_DIALOG:
            return {
                ...state,
                addNewCollaboratorDialogOpen: true,
            };

        case HIDE_ADD_NEW_COLLABORATOR_DIALOG:
            return {
                ...state,
                addNewCollaboratorDialogOpen: false,
            };


        case START_SELLER_COLLOBORATOR_FETCH:
            return {
                ...state,
                fetchSellerColloboratorProgress: true,
                sellerColloborators: null
            };

        case END_SELLER_COLLOBORATOR_FETCH:
            return {
                ...state,
                fetchSellerColloboratorProgress: false,
                sellerColloborators: action.payload.success
            };

        case START_SELLER_COLLOBORATOR_ADD:
            return {
                ...state,
                addSellerColloboratorProgress: true,
            };

        case END_SELLER_COLLOBORATOR_ADD:
            return {
                ...state,
                addSellerColloboratorProgress: false,
            };


        case START_SELLER_REQ_COLLOBORATOR_FETCH:
            return {
                ...state,
                fetchSellerReqColloboratorProgress: true,
                sellerRequirementColloborators: null
            };

        case END_SELLER_REQ_COLLOBORATOR_FETCH:
            return {
                ...state,
                fetchSellerReqColloboratorProgress: false,
                sellerRequirementColloborators: action.payload.success
            };

        case START_SELLER_REQ_COLLOBORATOR_ADD:
            return {
                ...state,
                addSellerReqColloboratorProgress: true,
            };

        case END_SELLER_REQ_COLLOBORATOR_ADD:
            return {
                ...state,
                addSellerReqColloboratorProgress: false,
            };

        case START_SELLER_DRAFT_RESPONSE_SUBMIT:
            return {
                ...state,
                submitDraftResponseProgress: true
            }

        case END_SELLER_DRAFT_RESPONSE_SUBMIT:
            var _threadNew = [...state.thread];
            if (action.payload.error == null && action.payload.success !== null) {
                _threadNew.push(action.payload.success)
            }
            return {
                ...state,
                submitDraftResponseProgress: false,
                thread: _threadNew
            }

        case SHOW_SUBMIT_ALL_SELLER_RESPONSES_DIALOG:
            return {
                ...state,
                submitAllSellerResponseDialogOpen: true,
            }

        case HIDE_SUBMIT_ALL_SELLER_RESPONSES_DIALOG:
            return {
                ...state,
                submitAllSellerResponseDialogOpen: false,
            }

        case START_SELLER_DRAFT_RESPONSES_FETCH:
            return {
                ...state,
                fetchSellerDraftResponsesProgress: true,
                fetchSellerDraftResponsesError: null,
                sellerDraftResponses: []
            }

        case END_SELLER_DRAFT_RESPONSES_FETCH:
            return {
                ...state,
                fetchSellerDraftResponsesProgress: false,
                fetchSellerDraftResponsesError: action.payload.error,
                sellerDraftResponses: action.payload.success
            }

        case START_SELLER_DRAFT_RESPONSES_UPDATE:
            return {
                ...state,
                updateSellerDraftResponsesProgress: true,
            }

        case END_SELLER_DRAFT_RESPONSES_UPDATE:
            return {
                ...state,
                updateSellerDraftResponsesProgress: false,
            }

        case START_SELLER_REQUIREMENT_RESPONSES_ADD:
            return {
                ...state,
                addSellerRequirementResponseProgress: true
            }

        case END_SELLER_REQUIREMENT_RESPONSES_ADD:
            return {
                ...state,
                addSellerRequirementResponseProgress: false,
                response: action.payload.success
            }

        case SHOW_SELLER_FINALIZE_REQ_DIALOG:
            return {
                ...state,
                showRequirementType: action.payload.type,
                isShowViewDetails: action.payload.isShowViewDetails,
                showRequirementFinalizeDialog: true
            }

        case HIDE_SELLER_FINALIZE_REQ_DIALOG:
            return {
                ...state,
                showRequirementType: '',
                isShowViewDetails: true,
                showRequirementFinalizeDialog: false
            }

        case START_SELLER_REQ_TREE_FETCH:
            return {
                ...state,
                fetchRequirementTreeProgress: true,
                requirementTreeError: null
            }

        case END_SELLER_REQ_TREE_FETCH:
            let newReqMap = {};
            var allRequirements = (action.payload.success !== undefined && action.payload.success !== null) ? action.payload.success : [];
            allRequirements.forEach(function (requirement) {
                requirement.Childrens.forEach(function (req) {
                    newReqMap[req.Name] = req.ID;
                });
            })
            return {
                ...state,
                requirementTree: action.payload.success,
                requirementTreeError: action.payload.error,
                requirementMap: newReqMap,
                fetchRequirementTreeProgress: false
            }

        case START_SELLER_USER_LAST_RESPONSE_FETCH:
            return {
                ...state,
                fetchLastUserResponseProgress: true
            }

        case END_SELLER_USER_LAST_RESPONSE_FETCH:
            return {
                ...state,
                lastUserResponse: action.payload.success,
                fetchLastUserResponseProgress: false
            }

        case SET_SELECTED_REQUIREMENT_FOR_SELLER:
            return {
                ...state,
                selectedRequirementId: action.payload.req_id,
            };

        case START_REQUIREMENT_RESPONSES_ADD_IN_BULK:
            return {
                ...state,
                addBulkResponseProgress: true,
                addBulkResponseError: null,
                addBulkResponseSuccess: null,
            }

        case END_REQUIREMENT_RESPONSES_ADD_IN_BULK:
            return {
                ...state,
                addBulkResponseProgress: false,
                addBulkResponseError: action.payload.error,
                addBulkResponseSuccess: action.payload.success,
            }

        case START_SELLER_INSTRUCTION_FETCH:
            return {
                ...state,
                sellerInstructionProgress: true,
                sellerInstructionError: null,
                sellerInstructionSuccess: null,
            }

        case END_SELLER_INSTRUCTION_FETCH:
            return {
                ...state,
                sellerInstructionProgress: false,
                sellerInstructionError: action.payload.error,
                sellerInstructionSuccess: action.payload.success,
            }

        case START_SELLER_POPUP_STATE_UPDATE:
            return {
                ...state,
                updateSellerStateProgress: true,
                updateSellerStateError: null,
                updateSellerStateSuccess: null,
            }

        case END_SELLER_POPUP_STATE_UPDATE:
            return {
                ...state,
                updateSellerStateProgress: false,
                updateSellerStateError: action.payload.error,
                updateSellerStateSuccess: action.payload.success,
            }

        case START_FETCH_PRODUCT_RFP_MAP:
            return {
                ...state,
                productRFPMapProgress: true,
                productRFPMapError: null,
                productRFPMapSuccess: {},
            }

        case END_FETCH_PRODUCT_RFP_MAP:
            return {
                ...state,
                productRFPMapProgress: false,
                productRFPMapError: action.payload.error,
                productRFPMapSuccess: action.payload.success,
            }


        default:
            return state;
    }
}