import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classnames from "classnames";
import CommonCss from '../../commonCss';
import { FormControl, Select, MenuItem, Checkbox, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Chip from '@material-ui/core/Chip';

const styles = (theme) => createStyles({
    "table": {
        width: '100%',
    },
    "cell": {
        color: "#4B4B4B",
        fontWeight: 400,
        fontSize: theme.spacing(1.9),
        textAlign: 'left',
        borderBottom: '1px solid #EEEEEE',
        paddingBottom: '6px'
    },

    "selectEmpty": {
        border: "1px solid #B9B9B9",
        borderRadius: 3,
        fontSize: theme.spacing(2),
        color: "#4B4B4B",
        "&:hover, &:before, &:after": {
            border: "1px solid #B9B9B9",
            content: "none"
        },
        "& [class*='selectMenu']": {
            paddingLeft: theme.spacing(1)
        }
    },
    formControl: {
        width: 97,
    },
    imgGroup: {
        float: "left",
        width: "30px",
        height: "30px",
        marginRight: "5px",
    },
    viewMore: {
        cursor: "pointer",
        fontWeight: "500",
    },
    editIcon: {
        color: "#4A87F8",
        verticalAlign: "middle",
        marginLeft: 6,
        marginBottom: 5,
        cursor: "pointer",
    },
    mtop1: {
        marginTop: "1px",
    },
    icon: CommonCss.icon,
    checkedIcon: CommonCss.checkedIcon,

    "menuItem": {
        fontSize: theme.spacing(2),
        color: "#4B4B4B"
    },

    "emptyMessage": {
        height: 50,
        fontSize: 16,
        textAlign: 'center',
        color: "#4B4B4B",
    }
});

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            
        }
    }

    render() {
        const classes = this.props.classes;
        let rowData = this.props.bodyData.map((list) => {
            return this.props.tableData.head.map((obj) => {
                return {
                    ...obj,
                    html: list[obj[this.props.key]],
                };
            });
        });

        return <div>
            <table className={classnames(classes.table, this.props.tableData.class)}>
                <thead>
                    <tr>
                        {this.props.tableData?.head?.map((e, index) => {
                            return ((e.hide === false || e.hide === undefined) && <th key={index} className={classnames(classes.cell, e.headClass)}>
                                {(e.type === 'text' || e.type === 'view' || e.type === 'select' || e.type === 'action') && <span>
                                    {e.label}
                                </span>}

                                {e.type === 'checkbox' && <span>
                                    <Checkbox
                                        color="primary"
                                        className={classes.checkBox}
                                        checkedIcon={<span className={classnames(classes.icon, classes.checkedIcon)} />}
                                        icon={<span className={classes.icon} />}
                                    />
                                </span>}
                            </th>)
                        })}
                    </tr>
                </thead>

                <tbody>
                    {rowData?.map((array, parentIndex) => (
                        <tr key={parentIndex}>
                            {
                                array?.map((item, childIndex) => {
                                    return ((item.hide === false || item.hide === undefined) &&
                                        <th key={parentIndex + childIndex} className={classnames(classes.cell, item.cellClass)}>

                                            {item?.type === 'text' && <span>
                                                {item?.html}
                                            </span>}
                                            {item?.type === 'view' && <span>
                                                <span onClick={(event) => this.props.parentClickEvent(this.props.bodyData, parentIndex, childIndex, array, item, item?.actionItem, event)}>
                                                    <Chip
                                                        icon={<VisibilityIcon style={{ marginLeft: '5px' }} />}
                                                        label={<div className={classes.mtop1}>{item?.html}</div>}
                                                        size="small"
                                                        clickable
                                                        color="primary"
                                                        variant="outlined"
                                                    />
                                                </span>
                                            </span>}

                                            {item?.type === 'checkbox' && <span>
                                                <Checkbox
                                                    checked={item?.html}
                                                    checkedIcon={<span className={classnames(classes.icon, classes.checkedIcon)} />}
                                                    onChange={(event) => this.props.parentClickEvent(this.props.bodyData, parentIndex, childIndex, array, item, undefined, event)}
                                                    icon={<span className={classes.icon} />}
                                                />
                                            </span>}

                                            {item?.type === 'select' &&
                                                <>
                                                    {(item?.showFunction === undefined || item?.showFunction(this.props.bodyData[parentIndex])) && <FormControl variant="standard" className={classes.formControl}>
                                                        <Select
                                                            value={item?.html}
                                                            name={`select_${parentIndex}_${childIndex}`}
                                                            className={classes.selectEmpty}
                                                            onChange={(event) => this.props.parentClickEvent(this.props.bodyData, parentIndex, childIndex, array, item, undefined, event)}
                                                        >
                                                            {item.options?.map((option, childIndex) => {
                                                                return (<MenuItem key={childIndex} className={classes.menuItem} value={option.id}>{option.label}</MenuItem>)
                                                            })
                                                            }
                                                        </Select>
                                                    </FormControl>}
                                                    {item?.showFunction !== undefined && !item?.showFunction(this.props.bodyData[parentIndex]) &&
                                                        <Typography> -</Typography>
                                                    }
                                                </>
                                            }

                                            {
                                                item?.type === 'action' && item?.actionItems?.map((actionItem, index) => {
                                                    return (
                                                        <span key={index} onClick={(event) => this.props.parentClickEvent(this.props.bodyData, parentIndex, childIndex, array, item, actionItem, event,)}>
                                                            {actionItem.type === 'muiicon' && actionItem.html}
                                                        </span>
                                                    )
                                                })
                                            }
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    ))}
                    {rowData.length === 0 && this.props.tableData.isProgress === false &&
                        <tr>
                            <td colSpan={this.props.tableData?.head?.length} className={classes.emptyMessage}>{this.props.emptyMessage}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    }
}


Table.defaultProps = {
    tableData: {
        head: [],
        class: '',
        isProgress: true
    },
    emptyMessage: 'Data not found',
    bodyData: [],
    key: 'key',

};



export default withStyles(styles)(Table)