import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const SHOW_IMPORT_FORM_DIALOG = "SHOW_IMPORT_FORM_DIALOG";
export const HIDE_IMPORT_FORM_DIALOG = "HIDE_IMPORT_FORM_DIALOG";

export const SHOW_ADD_FORM_DIALOG = "SHOW_ADD_FORM_DIALOG";
export const HIDE_ADD_FORM_DIALOG = "HIDE_ADD_FORM_DIALOG";

export const START_ADD_ORG_FORM_DETAILS = "START_ADD_ORG_FORM_DETAILS";
export const END_ADD_ORG_FORM_DETAILS = "END_ADD_ORG_FORM_DETAILS";

export const START_UPDATE_ORG_FORM_DETAILS = "START_UPDATE_ORG_FORM_DETAILS";
export const END_UPDATE_ORG_FORM_DETAILS = "END_UPDATE_ORG_FORM_DETAILS";

export const START_DELETE_ORG_FORM_DETAILS = "START_DELETE_ORG_FORM_DETAILS";
export const END_DELETE_ORG_FORM_DETAILS = "END_DELETE_ORG_FORM_DETAILS";

export const START_FETCH_ORG_FORMS = "START_FETCH_ORG_FORMS";
export const END_FETCH_ORG_FORMS = "END_FETCH_ORG_FORMS";

export const START_FETCH_USED_PLACES = "START_FETCH_USED_PLACES";
export const END_FETCH_USED_PLACES = "END_FETCH_USED_PLACES";

export const START_FETCH_ORG_FORM_BY_ID = "START_FETCH_ORG_FORM_BY_ID";
export const END_FETCH_ORG_FORM_BY_ID = "END_FETCH_ORG_FORM_BY_ID";

export const START_ADD_ORG_FORM_SECTION = "START_ADD_ORG_FORM_SECTION";
export const END_ADD_ORG_FORM_SECTION = "END_ADD_ORG_FORM_SECTION";

export const START_UPDATE_ORG_FORM_SECTION = "START_UPDATE_ORG_FORM_SECTION";
export const END_UPDATE_ORG_FORM_SECTION = "END_UPDATE_ORG_FORM_SECTION";

export const START_FETCH_ORG_FORM_SECTIONS = "START_FETCH_ORG_FORM_SECTIONS";
export const END_FETCH_ORG_FORM_SECTIONS = "END_FETCH_ORG_FORM_SECTIONS";

export const START_DELETE_ORG_FORM_SECTION = "START_DELETE_ORG_FORM_SECTION";
export const END_DELETE_ORG_FORM_SECTION = "END_DELETE_ORG_FORM_SECTION";

export const START_ADD_ORG_FORM_SECTION_ITEM = "START_ADD_ORG_FORM_SECTION_ITEM";
export const END_ADD_ORG_FORM_SECTION_ITEM = "END_ADD_ORG_FORM_SECTION_ITEM";

export const START_UPDATE_ORG_FORM_SECTION_ITEM = "START_UPDATE_ORG_FORM_SECTION_ITEM";
export const END_UPDATE_ORG_FORM_SECTION_ITEM = "END_UPDATE_ORG_FORM_SECTION_ITEM";

export const START_FETCH_ORG_FORM_SECTION_ITEMS = "START_FETCH_ORG_FORM_SECTION_ITEMS";
export const END_FETCH_ORG_FORM_SECTION_ITEMS = "END_FETCH_ORG_FORM_SECTION_ITEMS";

export const START_DELETE_ORG_FORM_SECTION_ITEM = "START_DELETE_ORG_FORM_SECTION_ITEM";
export const END_DELETE_ORG_FORM_SECTION_ITEM = "END_DELETE_ORG_FORM_SECTION_ITEM";

export const SET_FORM_ACTIVE_TAB = "SET_FORM_ACTIVE_TAB";

export const SHOW_USED_PLACES_DIALOG   =   "SHOW_USED_PLACES_DIALOG";
export const HIDE_USED_PLACES_DIALOG       =   "HIDE_USED_PLACES_DIALOG";

export function showUsedPlacesDialog(data){
    return {
        type:SHOW_USED_PLACES_DIALOG,
        payload: data
    };
}

export function hideUsedPlacesDialog(){
    return {
        type:HIDE_USED_PLACES_DIALOG,
        payload:null
    };
}

export  function showImportFormDialog(){
  return     {
      type:SHOW_IMPORT_FORM_DIALOG,
      payload:null
  };
}

export  function hideImportFormDialog(){
  return     {
      type:HIDE_IMPORT_FORM_DIALOG,
      payload:null
  };
}

export function showAddFormDialog(payload = {}) {
  return {
    type: SHOW_ADD_FORM_DIALOG,
    payload: {},
  };
}

export function hideAddFormDialog() {
  return {
    type: HIDE_ADD_FORM_DIALOG,
    payload: {},
  };
}

// Workflow custom form actions start
function startAddOrgFormData() { 
  return {
      type: START_ADD_ORG_FORM_DETAILS,
      payload: {}
  };
}

function endAddOrgFormData(success, error) {
  return {
      type: END_ADD_ORG_FORM_DETAILS,
      payload: {
          success, error
      }
  };
}

export function addOrgFormData(data={}) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startAddOrgFormData());
      try {
          let [response, error] = await HttpFactory.instance().postMethod(url, data);
          dispatch(endAddOrgFormData(response, error));
      } catch (e) {
          dispatch(endAddOrgFormData(null, {
              message: e.message
          }));
          return;
      }
  };
}

function startUpdateOrgFormData() {
  return {
      type: START_UPDATE_ORG_FORM_DETAILS,
      payload: {}
  };
}

function endUpdateOrgFormData(success, error) {
  return {
      type: END_UPDATE_ORG_FORM_DETAILS,
      payload: {
          success, error
      }
  };
}

export function updateOrgFormData(formId, data={}) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startUpdateOrgFormData());
      try {
          let [response, error] = await HttpFactory.instance().putMethod(url, data);
          dispatch(endUpdateOrgFormData(response, error));
      } catch (e) {
          dispatch(endUpdateOrgFormData(null, {
              message: e.message
          }));
          return;
      }
  };
}

function startDeleteOrgFormData() {
  return {
      type: START_DELETE_ORG_FORM_DETAILS,
      payload: {}
  };
}

function endDeleteOrgFormData(success, error) {
  return {
      type: END_DELETE_ORG_FORM_DETAILS,
      payload: {
          success, error
      }
  };
}

export function deleteOrgFormData(formId) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startDeleteOrgFormData());
      try {
          let [response, error] = await HttpFactory.instance().deleteMethod(url);
          dispatch(endDeleteOrgFormData(response, error));
      } catch (e) {
          dispatch(endDeleteOrgFormData(null, {
              message: e.message
          }));
          return;
      }
  };
}

function startFetchOrgForms() {
  return {
      type: START_FETCH_ORG_FORMS,
      payload: {}
  };
}

function endFetchOrgForms(success, error) {
  return {
      type: END_FETCH_ORG_FORMS,
      payload: {
          success, error
      }
  };
}

export function fetchOrgForms() {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/forms?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startFetchOrgForms());
      try {
          let [response, error] = await HttpFactory.instance().getMethod(url);
          dispatch(endFetchOrgForms(response, error));
      } catch (e) {
          dispatch(endFetchOrgForms(null, {
              message: e.message
          }));
          return;
      }
  };
}


function startFetchUsedPlaces() {
    return {
        type: START_FETCH_USED_PLACES,
        payload: {}
    };
  }
  
  function endFetchUsedPlaces(success, error) {
    return {
        type: END_FETCH_USED_PLACES,
        payload: {
            success, error
        }
    };
  }
  
  export function fetchUsedPlaces(data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/template/form/workflows?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchUsedPlaces());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url,data);
            dispatch(endFetchUsedPlaces(response, error));
        } catch (e) {
            dispatch(endFetchUsedPlaces(null, {
                message: e.message
            }));
            return;
        }
    };
  }


function startFetchOrgFormUsingId() {
  return {
      type: START_FETCH_ORG_FORM_BY_ID,
      payload: {}
  };
}

function endFetchOrgFormUsingId(success, error) {
  return {
      type: END_FETCH_ORG_FORM_BY_ID,
      payload: {
          success, error
      }
  };
}

export function fetchOrgFormUsingId(formId,project_id=0) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}?t=${timestamp}&project_id=${project_id}`;
  return async (dispatch) => {
      dispatch(startFetchOrgFormUsingId());
      try {
          let [response, error] = await HttpFactory.instance().getMethod(url);
          dispatch(endFetchOrgFormUsingId(response, error));
      } catch (e) {
          dispatch(endFetchOrgFormUsingId(null, {
              message: e.message
          }));
          return;
      }
  };
}

function startAddOrgFormSection() { 
  return {
      type: START_ADD_ORG_FORM_SECTION,
      payload: {}
  };
}

function endAddOrgFormSection(success, error) {
  return {
      type: END_ADD_ORG_FORM_SECTION,
      payload: {
          success, error
      }
  };
}

export function addOrgFormSection(formId, data={}) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}/section?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startAddOrgFormSection());
      try {
          let [response, error] = await HttpFactory.instance().postMethod(url, data);
          dispatch(endAddOrgFormSection(response, error));
      } catch (e) {
          dispatch(endAddOrgFormSection(null, {
              message: e.message
          }));
          return;
      }
  };
}



function startUpdateOrgFormSection() { 
  return {
      type: START_UPDATE_ORG_FORM_SECTION,
      payload: {}
  };
}

function endUpdateOrgFormSection(success, error) {
  return {
      type: END_UPDATE_ORG_FORM_SECTION,
      payload: {
          success, error
      }
  };
}

export function updateOrgFormSection(formId,sectionId, data={}) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}/section/${sectionId}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startUpdateOrgFormSection());
      try {
          let [response, error] = await HttpFactory.instance().putMethod(url, data);
          dispatch(endUpdateOrgFormSection(response, error));
      } catch (e) {
          dispatch(endUpdateOrgFormSection(null, {
              message: e.message
          }));
          return;
      }
  };
}



function startFetchOrgFormSections() {
  return {
      type: START_FETCH_ORG_FORM_SECTIONS,
      payload: {}
  };
}

function endFetchOrgFormSections(success, error) {
  return {
      type: END_FETCH_ORG_FORM_SECTIONS,
      payload: {
          success, error
      }
  };
}

export function fetchOrgFormSections(formId) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}/sections?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startFetchOrgFormSections());
      try {
          let [response, error] = await HttpFactory.instance().getMethod(url);
          dispatch(endFetchOrgFormSections(response, error));
      } catch (e) {
          dispatch(endFetchOrgFormSections(null, {
              message: e.message
          }));
          return;
      }
  };
}



function startDeleteOrgFormSection() {
  return {
      type: START_DELETE_ORG_FORM_SECTION,
      payload: {}
  };
}

function endDeleteOrgFormSection(success, error) {
  return {
      type: END_DELETE_ORG_FORM_SECTION,
      payload: {
          success, error
      }
  };
}

export function deleteOrgFormSection(formId, sectionId) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}/section/${sectionId}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startDeleteOrgFormSection());
      try {
          let [response, error] = await HttpFactory.instance().deleteMethod(url);
          dispatch(endDeleteOrgFormSection(response, error));
      } catch (e) {
          dispatch(endDeleteOrgFormSection(null, {
              message: e.message
          }));
          return;
      }
  };
}



function startAddOrgFormSectionItem() { 
  return {
      type: START_ADD_ORG_FORM_SECTION_ITEM,
      payload: {}
  };
}

function endAddOrgFormSectionItem(success, error) {
  return {
      type: END_ADD_ORG_FORM_SECTION_ITEM,
      payload: {
          success, error
      }
  };
}

export function addOrgFormSectionItem(formId, sectionId, data={}) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}/section/${sectionId}/item?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startAddOrgFormSectionItem());
      try {
          let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
          dispatch(endAddOrgFormSectionItem(response, error));
      } catch (e) {
          dispatch(endAddOrgFormSectionItem(null, {
              message: e.message
          }));
          return;
      }
  };
}



function startUpdateOrgFormSectionItem() { 
  return {
      type: START_UPDATE_ORG_FORM_SECTION_ITEM,
      payload: {}
  };
}

function endUpdateOrgFormSectionItem(success, error) {
  return {
      type: END_UPDATE_ORG_FORM_SECTION_ITEM,
      payload: {
          success, error
      }
  };
}

export function updateOrgFormSectionItem(formId, sectionId, itemId, data={}) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}/section/${sectionId}/item/${itemId}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startUpdateOrgFormSectionItem());
      try {
          let [response, error] = await HttpFactory.instance().putMethod(url, data);
          dispatch(endUpdateOrgFormSectionItem(response, error));
      } catch (e) {
          dispatch(endUpdateOrgFormSectionItem(null, {
              message: e.message
          }));
          return;
      }
  };
}



function startFetchOrgFormSectionItems() {
  return {
      type: START_FETCH_ORG_FORM_SECTION_ITEMS,
      payload: {}
  };
}

function endFetchOrgFormSectionItems(success, error) {
  return {
      type: END_FETCH_ORG_FORM_SECTION_ITEMS,
      payload: {
          success, error
      }
  };
}

export function fetchOrgFormSectionItems(formId,sectionId) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}/section/${sectionId}/items?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startFetchOrgFormSectionItems());
      try {
          let [response, error] = await HttpFactory.instance().getMethod(url);
          dispatch(endFetchOrgFormSectionItems(response, error));
      } catch (e) {
          dispatch(endFetchOrgFormSectionItems(null, {
              message: e.message
          }));
          return;
      }
  };
}

function startDeleteOrgFormSectionItem() {
  return {
      type: START_DELETE_ORG_FORM_SECTION_ITEM,
      payload: {}
  };
}

function endDeleteOrgFormSectionItem(success, error) {
  return {
      type: END_DELETE_ORG_FORM_SECTION_ITEM,
      payload: {
          success, error
      }
  };
}

export function deleteOrgFormSectionItem(formId, sectionId, itemId) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}/section/${sectionId}/item/${itemId}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startDeleteOrgFormSectionItem());
      try {
          let [response, error] = await HttpFactory.instance().deleteMethod(url);
          dispatch(endDeleteOrgFormSectionItem(response, error));
      } catch (e) {
          dispatch(endDeleteOrgFormSectionItem(null, {
              message: e.message
          }));
          return;
      }
  };
}

export function updateOrgFormSectionSequence(formId, sectionId, data) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = (new Date()).getTime();
  var url = `${api_server}/org-workflow/template/form/${formId}/section/${sectionId}/seq?t=${timestamp}`;
  return async (dispatch) => {
      try{
          await HttpFactory.instance().putMethod(url, data);
          dispatch(fetchOrgFormSections(formId));
      } catch(e){
          return;
      }
  };
}

export function updateOrgFormSectionItemSequence(formId, sectionId, itemId, data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/template/form/${formId}/section/${sectionId}/item/${itemId}/seq?t=${timestamp}`;
    return async (dispatch) => {
        try{
            await HttpFactory.instance().putMethod(url, data);
            dispatch(fetchOrgFormSections(formId));
        } catch(e){
            return;
        }
    };
  }


export function setFormActiveTab(id){
    return {
        type:SET_FORM_ACTIVE_TAB,
        payload:{
            id
        }
    };
}

