import { createStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import CommonCss from "commonCss";
import { showSnackBar } from "redux/snackbar/action";
import Editor from "@monaco-editor/react";
import { generate } from "shortid";
import SqureButton from "Components/Common/SqureButton";
import { Dialog, DialogContent, DialogActions, Slide, DialogTitle } from "@material-ui/core";
// "@material-ui/styles": "^4.10.0",
// "@monaco-editor/react": "^4.4.6",

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({});

const connectionActions = {
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);
const styles = (theme) =>
  createStyles({
    formGroup: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    formControl: {
      ...CommonCss.formControl,
      minHeight: 34,
      padding: "0px",
      border: 0
    },
    label: {
      marginBottom: 5,
      display: "block",
    },
    supportLabel: {
      marginTop: 3,
      marginBottom: 3,
      display: "inline-block",
    },
    addCode: {
      color: "#4175DF",
      cursor: "pointer",
      fontSize: 13,
      fontWeight: 500,
    },
  });

class CodeEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      open: false,
    };
  }

  componentDidMount() {
    if (typeof this.props.value === "string") {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const classes = this.props.classes;
    return (
      <>
        <div className={classes.formGroup}>
          <span className={classes.supportLabel}>{this.props.label}:</span>
          <div className={classes.addCode} onClick={() => {
            this.setState({ open: true });
          }}>
            Open Editor
          </div>
        </div>
        <Dialog
            onClose={() => {
              this.setState({ open: false });
            }}
            aria-labelledby=""
            open={this.state.open}
            TransitionComponent={Transition}
            scroll={"body"}
            fullWidth={true}
            id={`addNameDialog${generate()}`}
            style={{ zIndex: 10001 }}
          >
            <DialogTitle id={`form-dialog-title${generate()}`}>{this.props.label}</DialogTitle>
            <DialogContent>
            <Editor
                className={classes.formControl}
                height="200px"
                width={`100%`}
                language={"javascript"}
                value={this.state.value}
                theme={"vs-dark"}
                onChange={(value) => {
                  this.setState({ value: value });
                }}
              />
            </DialogContent>
            <DialogActions>
              <div style={{ padding: "10px 24px" }}>
                <SqureButton
                  variant={"outlined"}
                  onClick={() => {
                    this.setState({ open: false });
                  }}
                >
                  Cancel
                </SqureButton>
                &nbsp; &nbsp; &nbsp;
                <SqureButton
                  variant={"contained"}
                  onClick={() => {
                    this.props.updateValue(this.state.value);
                    this.setState({ open: false });
                  }}
                >
                  Save
                </SqureButton>
              </div>
            </DialogActions>
          </Dialog>
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(CodeEditor));
