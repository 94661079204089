
/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
 import { HttpFactory } from "services/httpService";
 import * as Environment from "util/Environment";
import {MetricFactory} from "../../services/metric";

export const START_METRIC_VENDOR_FETCH     =   "START_METRIC_VENDOR_FETCH";
export const END_METRIC_VENDOR_FETCH       =   "END_METRIC_VENDOR_FETCH";


export const START_METRIC_CATEGORY_VENDOR_FETCH     =   "START_METRIC_CATEGORY_VENDOR_FETCH";
export const END_METRIC_CATEGORY_VENDOR_FETCH       =   "END_METRIC_CATEGORY_VENDOR_FETCH";

export const START_VENDOR_REFACTOR_UPDATE = "START_VENDOR_REFACTOR_UPDATE";
export const END_VENDOR_REFACTOR_UPDATE = "END_VENDOR_REFACTOR_UPDATE";

function startVendorRefactorUpdate() {
    return {
      type: START_VENDOR_REFACTOR_UPDATE,
      payload: {},
    };
  }
  
  function endVendorRefactorUpdate(success, error) {
    return {
      type: END_VENDOR_REFACTOR_UPDATE,
      payload: {
        success,
        error,
      },
    };
  }
  
  export function updateVendorRefactor(productId,data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = new Date().getTime();
    const url = `${api_server}/org/product/${productId}/refactor?t=${timestamp}`;
    return async (dispatch) => {
      dispatch(startVendorRefactorUpdate());
      try {
        let [response, error] = await HttpFactory.instance().postMethod(url, data, true);
        dispatch(endVendorRefactorUpdate(response, error));
      } catch (e) {
        dispatch(
          endVendorRefactorUpdate(null, {
            message: e.message,
          })
        );
        return;
      }
    };
  }


//Action to start the registration
function startMetricVendorFetch(page){
    return {
        type:START_METRIC_VENDOR_FETCH,
        payload:page
    };
}

function endMetricVendorFetch(success,error){
    return {
        type:END_METRIC_VENDOR_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchVendors(page,pageSiz,query,extraQuery){
    return async (dispatch) => {
        dispatch(startMetricVendorFetch(page));
        try{
            let [response,error]    =   await MetricFactory.instance().getVendors(page,pageSiz,query,extraQuery);
            dispatch(endMetricVendorFetch(response,error));
        } catch(e){
            dispatch(endMetricVendorFetch(null,{
                message:e.message
            }));
            return;
        }
    };
}   



//Action to start the registration
function startMetricCategoryVendorFetch(page){
    return {
        type:START_METRIC_CATEGORY_VENDOR_FETCH,
        payload:page
    };
}

function endMetricCategoryVendorFetch(success,error){
    return {
        type:END_METRIC_CATEGORY_VENDOR_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchMetricCategoryVendors(categoryId,page,pageSiz,query,extraQuery){
    return async (dispatch) => {
        dispatch(startMetricCategoryVendorFetch(page));
        try{
            let [response,error]    =   await MetricFactory.instance().getMetricCategoryVendors(categoryId,page,pageSiz,query,extraQuery);
            dispatch(endMetricCategoryVendorFetch(response,error));
        } catch(e){
            dispatch(endMetricCategoryVendorFetch(null,{
                message:e.message
            }));
            return;
        }
    };
}   
