import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, DialogContent, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { withRouter } from 'react-router-dom';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
// import SlateInputFieldNew  from "Components/Common/SlateEditorNew/index";

import { showSendNotificationDialog, hideSendNotificationDialog, sendEvaluationEmail } from "redux/evaluation/metadata/action"
import { showSnackBar } from "redux/snackbar/action";

import { addWorkflowComponentUserResponse, fetchSellerInstruction, addSellerInstruction } from "redux/workflow/action";
import {
    downloadPDFReport
} from "redux/evaluation/reportdata/action"
import SqureButton from 'Components/Common/SqureButton';

const connectedProps = (state) => ({
    sendEmailProgress: state.evaluationMetaData.sendEmailProgress,
    errorSendEmail: state.evaluationMetaData.errorSendEmail,
    evaluation: state.evaluationMetaData.evaluation,
    workflowComponentsForms: state.workflow.workflowComponentsForms,
    workflowComponentDetail: state.workflow.workflowComponentDetail,
    workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
    userRole: state.authUser.user?.Role,

    addSellerInstructionProgress: state.workflow.addSellerInstructionProgress,
    addSellerInstructionError: state.workflow.addSellerInstructionError,
    addSellerInstructionSuccess: state.workflow.addSellerInstructionSuccess,

    fetchSellerInstructionProgress: state.workflow.fetchSellerInstructionProgress,
    fetchSellerInstructionError: state.workflow.fetchSellerInstructionError,
    sellerInstruction: state.workflow.sellerInstruction,


    downloadPDFProgress: state.evaluationReportData.downloadPDFProgress,
    downloadPDFData: state.evaluationReportData.downloadPDFData,
    downloadPDFError: state.evaluationReportData.downloadPDFError,

});

const connectionActions = {
    showSendNotificationDialog: showSendNotificationDialog,
    hideDialog: hideSendNotificationDialog,
    sendEvaluationEmail: sendEvaluationEmail,
    showSnackBar: showSnackBar,
    addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
    addSellerInstruction: addSellerInstruction,
    fetchSellerInstruction: fetchSellerInstruction,
    downloadPDFReport: downloadPDFReport
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    modalTitle: {
        fontSize: theme.spacing(2.5),

        // marginBottom: theme.spacing(4),
    },
    actions: {
        float: 'right',
        marginBottom: 20
    },
    alert: {
        marginBottom: theme.spacing(1.6),
    },
    root: {
        background: '#fff',
        padding: 20,
        width: '80%',
        margin: '0px auto'
    },
    dialogAction: {
        paddingBottom: 120,
    },
    topHead: {
        display: 'flex',
        alignItems: 'center',
    },
    name: {
        flex: 1
    },
    actionBtn: {
        textAlign: 'right',
        flex: 1
    }
});

class EmailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            instructionData: "",
            body_error: "",
            isEdit: false,
            nextClickLoaderSave: false,
            nextClickLoader: false,
            fetchDone: false,
            addInstructionRequire: false,
            checkConfigDone: false,
            configInstruction: "",
            isLoader: true,
            submitType: null,
            isDownloadingPDF: false
        }
        this.divRef = React.createRef();

        this.submitResponse = this.submitResponse.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.saveInstruction = this.saveInstruction.bind(this);

    }

    componentDidMount() {
        this.props.fetchSellerInstruction(this.props.evaluation_id, this.props.componentId)
        this.checkWorkflowConfiguration();
    }

    componentDidUpdate(prevProps) {
        if (this.props.workflowComponentsFormsInProg === false && prevProps.workflowComponentsFormsInProg === true) {
            if (this.props.isWorkFlow) {
                this.checkWorkflowConfiguration();
            }
        }

        if (!this.props.fetchSellerInstructionProgress && prevProps.fetchSellerInstructionProgress) {
            if (this.props.fetchSellerInstructionError === null) {
                this.setState({
                    fetchDone: true,
                    addInstructionRequire: false
                }, () => {
                    this.checkInstruction();
                })
            } else {
                this.setState({
                    fetchDone: true,
                    addInstructionRequire: true
                }, () => {
                    this.checkInstruction();
                })
            }
        }

        if (!this.props.addSellerInstructionProgress && prevProps.addSellerInstructionProgress) {
            if (this.props.addSellerInstructionError === null) {
                this.setState({
                    nextClickLoader: false,
                    nextClickLoaderSave: false,
                }, () => {
                    if (this.state.submitType === 'NEXT') {
                        var responseDatas = [];
                        this.props.addWorkflowComponentUserResponse(this.props.evaluation_id, this.props.workflowComponentId, {
                            "data": responseDatas,
                            "forward": true
                        });
                    } else if (this.state.submitType === 'SAVE') {
                        this.props.showSnackBar("Instruction updated successfully.", "success", 3000)
                    }
                    this.setState({
                        submitType: null,
                        addInstructionRequire: false,
                        isEdit: false,
                        instructionData: this.props.addSellerInstructionSuccess?.Instruction || ''
                    })
                })
            }
        }


        if (!this.props.downloadPDFProgress && prevProps.downloadPDFProgress) {
            if (this.props.downloadPDFData !== null && this.props.downloadPDFData?.path) {
                const link = document.createElement('a');
                link.href = this.props.downloadPDFData.path;
                link.setAttribute('download', "report.xlsx");
                this.divRef.current.appendChild(link)
                link.click();
                link.parentNode.removeChild(link);
            }
            this.setState({ isDownloadingPDF: false })
        }
    }

    checkInstruction() {
        // console.log(this.props.sellerInstruction, 'sellerInstruction')
        const { fetchDone, checkConfigDone, addInstructionRequire, configInstruction } = this.state;
        if (fetchDone && checkConfigDone && addInstructionRequire) {
            if (configInstruction) {
                // this.setState({
                //     instructionData: configInstruction
                // }, () => {
                this.props.addSellerInstruction(this.props.evaluation_id, this.props.component_id, {
                    instruction: configInstruction
                })
                // })
            }
        } else {
            this.setState({
                instructionData: this.props.sellerInstruction?.Instruction || ''
            })
        }
    }


    checkWorkflowConfiguration() {
        const { workflowComponentsForms, workflowComponentDetail } = this.props;
        if (workflowComponentsForms === null || workflowComponentDetail === null) {
            return;
        }

        var configurations = workflowComponentDetail.OrgWorkflowComponentConfigurations;
        console.log(configurations, 'configurations')

        var instructionId = workflowComponentsForms.filter(o => o.Name === "intructions").map(o => o.ID)[0];
        var instructionData = configurations.filter(o => o.FormID === instructionId).map(o => o.Value)[0];
        console.log(instructionData, 'instructionData')

        this.setState({
            configInstruction: instructionData,
            checkConfigDone: true
        })

    }

    submitResponse() {
        this.setState({
            nextClickLoader: true,
            submitType: 'NEXT'
        }, () => {
            this.props.addSellerInstruction(this.props.match.params.evaluation_id, this.props.component_id, {
                instruction: this.state.instructionData
            })
        })
    }

    saveInstruction() {
        this.setState({
            nextClickLoaderSave: true,
            submitType: 'SAVE'
        }, () => {
            this.props.addSellerInstruction(this.props.match.params.evaluation_id, this.props.component_id, {
                instruction: this.state.instructionData
            })
        })
    }

    handleDownload = () => {
        const url = `/evaluation/${this.props.evaluation_id}/component/${this.props.componentId}/instruction/download?t=1`
        var data = {
            "filename": this.props.component?.Name || 'instructions',
            "url": url,
            "filepath": "evaluation/sellerInstruction",
            "timeout": 4,
            "pdfSize": [8.3, 11.69]
        }
        this.setState({
            isDownloadingPDF: true
        }, () => {
            this.props.downloadPDFReport(data);
        })
    }

    render() {
        const classes = this.props.classes;
        return <>
            <div className={classes.root} id="evSendNotification">
                <DialogContent classes={{ root: classes.dialogAction }} >
                    <div>
                        <div className={classes.topHead}>
                            <div className={classes.actionBtn}>
                                <SqureButton
                                    style={{ "marginLeft": "10px" }}
                                    variant={"outlined"}
                                    onClick={() => {
                                        this.handleDownload()
                                    }}>
                                    {this.state.isDownloadingPDF ? <CircularProgress style={{ width: 25, height: 25 }} /> : 'Download'}
                                </SqureButton>
                            </div>
                        </div>
                        <div className={classes.alert}>
                            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                        </div>
                        <Grid container>
                            <Grid item lg={12}>
                                {!this.state.isEdit && <SlateInputField
                                    readOnly={true}
                                    isToolBar={false}
                                    placeholder=""
                                    style={{
                                        margin: 0,
                                    }}
                                    as={SlateInputField}
                                    initValue={this.state.instructionData}
                                    formControlStyle={{ border: 'none', overflow: 'hidden' }}
                                    textContainerStyle={{ minHeight: '100px !important', margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }}
                                />}
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <div ref={this.divRef} id="downloadlink"></div>

            </div>
        </>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(EmailForm));