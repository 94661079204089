import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import classnames from "classnames";
import { JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

const connectedProps = (state) => ({
  fetchMasterTriggerProgress: state.automation.fetchMasterTriggerProgress,
  fetchMasterTriggerError: state.automation.fetchMasterTriggerError,
  masterTriggers: state.automation.masterTriggers,

  fetchMasterActionProgress: state.automation.fetchMasterActionProgress,
  fetchMasterActionError: state.automation.fetchMasterActionError,
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
  },
  topTabs: {
    display: 'flex',
    alignItems: 'center',
    background: '#f7f9fa',
    borderBottom: '1px solid #e4e8eb'
  },
  tabButton: {
    fontSize: 12,
    fontWeight: 600,
    background: 'none',
    border: 'none',
    lineHeight: 1,
    padding: '11px 12px 12px',
    position: 'relative',
    color: '#637a87',
    '&:hover': {
      borderBottom: '2px solid #e4e8eb'
    }
  },
  selectedButton: {
    color: '#065f69',
    borderBottom: '2px solid #133440'
  },
  configBody: {
    padding: 20,
    background:'#f3f5f6',
    height:'90vh'
  },
  vContainer:{
    lineHeight: 1.2
  },
  vbasicChildStyle:{
    margin: '0 10px',
    padding: 0
  },
  vlabel:{
    color:'#5d69b6',
    marginRight:5,
    fontSize:12,
    lineHeight: 1.2
  },
  vnumberValue:{
    color:'#5aa028',
    fontSize:12

  },
  vstringValue:{
    color:'#5aa028',
    fontSize:12
  },
  vbooleanValue:{
    color:'#5aa028',
    fontSize:12
  },
  votherValue:{
    color:'#5aa028',
    fontSize:12
  },
  nullValue:{
    color:'#e67009',
    fontSize:12
  },
  vundefinedValue:{
    color:'#e67009',
    fontSize:12
  },
  vexpander:{
    color:'#24434f',
    marginRight:5,
    fontSize:12
  },
  vpunctuation:{
    color:'#24434f',
    marginRight:5,
  },
  vpointer:{
    color:'#24434f',
    marginRight:5,
  }
 
});

class JobConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      inputJson: '',
      selectedTab: 'Input',
      configData: {},
      errorData:''
    }
  }

  componentDidMount() {
    this.initData()
  }

  componentDidUpdate(prevProps) {

  }

  handleTabClick(type) {
    this.setState({
      selectedTab: type
    },()=>{
      this.initData()
    })
  }

  initData() {
    const { data, jobComponentConfigs, jobComponentConfigMap } = this.props;
    const { selectedTab } = this.state;

    console.log('=====================================')
    if(data && jobComponentConfigMap[data.id]){
      let config = jobComponentConfigMap[data.id];
      let jsonData = config[selectedTab]
      let jObj = {}
      try{
        jObj = JSON.parse(jsonData)
      }catch(e){}
      this.setState({
        configData: jObj,
        errorData:config[selectedTab]
      })
    }
    console.log(data, 'data')
    console.log(jobComponentConfigs, 'jobComponentConfigs')
    console.log(jobComponentConfigMap, 'jobComponentConfigMap')
  }

  render() {
    const { classes } = this.props;
    const { selectedTab, configData,errorData } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.topTabs}>
          <button type='button' onClick={() => this.handleTabClick('Input')} className={classnames(classes.tabButton, selectedTab === 'Input' ? classes.selectedButton : '')}>Input</button>
          <button type='button' onClick={() => this.handleTabClick('Output')} className={classnames(classes.tabButton, selectedTab === 'Output' ? classes.selectedButton : '')}>Output</button>
          <button type='button' onClick={() => this.handleTabClick('Error')} className={classnames(classes.tabButton, selectedTab === 'Error' ? classes.selectedButton : '')}>Error</button>
        </div>
        <div className={classes.configBody}>
          {selectedTab !== 'Error' && <JsonView
            data={configData}
            shouldInitiallyExpand={(level) => true}
            // collapseAllNested={(level)=>true}
            // style={defaultStyles}
            style={{
              container:classes.vContainer,
              basicChildStyle:classes.vbasicChildStyle,
              expander:classes.vexpander,
              label:classes.vlabel,
              nullValue:classes.vnullValue,
              undefinedValue:classes.vundefinedValue,
              numberValue:classes.vnumberValue,
              stringValue:classes.vstringValue,
              booleanValue:classes.vbooleanValue,
              otherValue:classes.votherValue,
              punctuation:classes.vpunctuation,
              pointer:classes.vpointer,
            }} 
            
            />}

            {selectedTab === 'Error' && <p className={classes.nullValue}>{errorData}</p>}
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(JobConfig));
