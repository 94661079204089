import React, { useRef, useState } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript";
import "codemirror/addon/display/autorefresh";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import "./codeMirror.css";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    decorationClass: {
      border: "1px solid #ced5db",
      background: "#f7f7f7",
    },
  });

const CodeMirrorExample = () => {
  const codeMirrorRef = useRef(null);
  const codeMirrorInstance = useRef(null);
  const [editorValue, setEditorValue] = useState(` {{ .name }} `);

  const handleDecorateText2 = () => {
    if (!codeMirrorInstance.current) return;

    const codeMirror = codeMirrorInstance.current;
    const decorationClass = "custom-decoration";
    const code = editorValue;

    // Regular expression to match text inside parentheses
    const regex = /\(([^)]+)\)/g;

    // Remove existing decorations
    codeMirror.getAllMarks().forEach((mark) => mark.clear());

    // Iterate through matches and decorate
    let match;
    while ((match = regex.exec(code)) !== null) {
      const startIndex = match.index;
      const endIndex = regex.lastIndex;

      codeMirror.markText(
        {
          line: codeMirror.posFromIndex(startIndex).line,
          ch: startIndex - codeMirror.posFromIndex(startIndex).ch,
        },
        {
          line: codeMirror.posFromIndex(endIndex).line,
          ch: endIndex - codeMirror.posFromIndex(endIndex).ch,
        },
        {
          className: decorationClass,
        }
      );
    }
  };

  const handleDecorateText3 = () => {
    const editor = codeMirrorRef.current.editor;

    // Specify the text to decorate
    const searchText = "world";

    // Define a CSS class for the decoration
    const decorationClass = "custom-decoration";

    // Iterate through each line and search for the text to decorate
    editor.eachLine((lineHandle) => {
      const lineText = lineHandle.text;
      const startIndex = lineText.indexOf(searchText);

      if (startIndex !== -1) {
        // Mark the text with the specified class
        editor.markText(
          { line: lineHandle.lineNo(), ch: startIndex },
          { line: lineHandle.lineNo(), ch: startIndex + searchText.length },
          { className: decorationClass }
        );
      }
    });
  };

  const handleDecorateText4 = () => {
    const editor = codeMirrorRef.current.editor;

    // Specify the text to decorate (change "world" to "new world")
    const searchText = "form.email";

    // Define a CSS class for the decoration
    const decorationClass = "custom-decoration";

    // Iterate through each line and search for the text to decorate
    editor.eachLine((lineHandle) => {
      const lineText = lineHandle.text;
      const startIndex = lineText.indexOf(searchText);

      if (startIndex !== -1) {
        // Mark the text with the specified class
        editor.markText(
          { line: lineHandle.lineNo(), ch: startIndex },
          { line: lineHandle.lineNo(), ch: startIndex + searchText.length },
          { className: decorationClass }
        );
        const code = editor.getValue();
        const updatedCode = code.replace(new RegExp(searchText, 'g'), 'SunilEmail');
        editor.setValue(updatedCode);
      }
    });
  };

  const variables = ['{{ .name }}', '{{ .component_1035.id }}', '{{ .component_1035.vendor_name_text }}', '{{ .component_1035.vendor_test_number_number_1 }}']

  const map = {
    '.name': 'UserName',
    '.component_1035.id': 'ID',
    '.component_1035.vendor_name_text': 'VendorName',
    '.component_1035.vendor_test_number_number_1': 'VendorTestNumberNumber1',
  }

  const getConvertableTexts = (text) => {
    const regex = /{{(.*?)}}/g;
    const matches = [];
    let match;
    const searchTexts = [".name", ".component_1035.id", ".component_1035.vendor_name_text", ".component_1035.vendor_test_number_number_1"];

    while ((match = regex.exec(text)) !== null) {
      if (searchTexts.indexOf(match[1].trim()) !== -1) {
        matches.push(match[1].trim());
      }
    }
    console.log(matches);
    return matches
  }


  const handleDecorateText = () => {
    const editor = codeMirrorRef.current.editor;

    // Specify the text to decorate (change "world" to "new world")

    // Define a CSS class for the decoration
    const decorationClass = "custom-decoration";

    // Iterate through each line and search for the text to decorate
    editor.eachLine((lineHandle) => {
      const lineText = lineHandle.text;
      let convertableText = getConvertableTexts(lineText)
      console.log(convertableText, 'convertableText')
      if (convertableText) {
        convertableText.forEach(function (txt) {
          const startIndex = lineText.indexOf(txt);
          if (startIndex !== -1) {
            // Mark the text with the specified class
            editor.markText(
              { line: lineHandle.lineNo(), ch: startIndex },
              { line: lineHandle.lineNo(), ch: startIndex + txt.length },
              { className: "custom-decoration", }
            );
            // const code = editor.getValue();
            // const updatedCode = code.replace(new RegExp(txt, 'g'), map[txt]);
            // editor.setValue(updatedCode);
          }
        })
      }
    });
  };

  const handleDecorateText6 = () => {
    const editor = codeMirrorRef.current.editor;
    const decorationClass = "custom-decoration";

    // Iterate through each line and search for the text to decorate
    editor.eachLine((lineHandle) => {
      const lineText = lineHandle.text;
      const matches = lineText.match(/{{(.*?)}}/g);

      if (matches) {
        matches.forEach((match) => {
          const textToDecorate = match.slice(2, -2); // Remove {{ and }} from the match
          const replacement = map[textToDecorate];
          if (replacement) {
            const startIndex = lineText.indexOf(match);
            if (startIndex !== -1) {
              // Mark the text with the specified class
              editor.markText(
                { line: lineHandle.lineNo(), ch: startIndex - 3 },
                { line: lineHandle.lineNo(), ch: startIndex + match.length + 3 },
                { className: decorationClass }
              );

              const code = editor.getValue();
              const updatedCode = code.replace(new RegExp(escapeRegExp(match), 'g'), replacement);
              editor.setValue(updatedCode);
            }
          }
        });
      }
    });
  };

  // Helper function to escape special characters in regex
  function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const addNewWidget2 = () => {
    const editor = codeMirrorRef.current.editor;
    const widget = document.createElement("span");
    widget.textContent = 'VendorName';
    widget.className = "widget";
    editor.addWidget(editor.getDoc().getCursor(), widget, false)
    // editor.getDoc().setBookmark(editor.getDoc().getCursor(), { widget });
  }

  const addNewWidget3 = () => {
    const editor = codeMirrorRef.current.editor;
    const cursorPos = editor.getCursor(); // Get the current cursor position
    const lineHandle = editor.getLineHandle(cursorPos.line); // Get the line handle
    const widget = document.createElement("span");
    widget.textContent = 'VendorName';
    widget.className = "widget";

    // Use the replaceRange method to insert the widget at the specified position
    editor.replaceRange(widget.textContent, { line: cursorPos.line, ch: cursorPos.ch }, { line: cursorPos.line, ch: cursorPos.ch });
  }

  const addNewWidget = () => {
    const editor = codeMirrorRef.current.editor;
    const cursorPos = editor.getCursor(); // Get the current cursor position
    const widget = document.createElement("span");
    widget.textContent = 'VendorName';
    widget.className = "widget";
    editor.addWidget(cursorPos, widget, false);
  }

  const getIndexes = (lineText, searchString) => {
    const indexes = [];
    let index = -1;
    while ((index = lineText.indexOf(searchString, index + 1)) !== -1) {
      indexes.push(index);
    }

    return indexes
  }

  const updateText = () => {
    const editor = codeMirrorRef.current.editor;
    // Iterate through each line and search for the text to decorate
    editor.eachLine((lineHandle) => {
      const lineText = lineHandle.text;
      console.log(lineText, 'lineText')
      let convertableText = getConvertableTexts(lineText)
      console.log(convertableText, 'convertableText')
      if (convertableText) {
        convertableText.forEach(function (txt) {
          const indexes = getIndexes(lineText, txt)
          console.log('------------------')
          console.log(txt, 'txt')
          console.log(indexes, 'indexes')
          console.log('------------------')
          if (indexes) {
            indexes.forEach((startIndex) => {
              if (startIndex !== -1) {
                const widget = document.createElement("span");
                widget.textContent = map[txt];
                widget.className = "widget";
                editor.markText(
                  { line: lineHandle.lineNo(), ch: startIndex - 3 },
                  { line: lineHandle.lineNo(), ch: startIndex + txt.length + 3 },
                  {
                    replacedWith: widget,
                  }
                );
              }
            })
          }
        })
      }
    });
  };

  return (
    <div>
      <h2>CodeMirror Text Decoration Example</h2>
      <button onClick={updateText}>Add Widget</button>

      <ul>
        {variables.map((variable) => {
          return <li key={variable} id="widget1" onClick={() => {
            const editorValueData = editorValue;
            const newValue = editorValueData + ' ' + variable
            setEditorValue(newValue)
          }}>{variable}</li>
        })}
      </ul>
      <div style={{ margin: '20px' }}>
        <CodeMirror
          value={editorValue}
          mode="text/html"
          className="custom-codemirror"
          options={{
            mode: "text/html",
            lineNumbers: false,
            gutters: ["CodeMirror-linenumbers"],
            lineWrapping: true, // Enable line wrapping
          }}
          editorDidMount={(editor) => {
            codeMirrorInstance.current = editor;
            console.log('editorDidMount')

          }}
          ref={codeMirrorRef}
          onChange={(editor, data, value) => {
            console.log(value, 'onChange')
            // handleDecorateText()
            setEditorValue(value)
            updateText()
          }}
          onBeforeChange={(editor, data, value) => {
            console.log(value, 'onBeforeChange')

            setEditorValue(value); // Update the state with the edited value
          }}
        />
      </div>

    </div>
  );
};

export default connector(
  compose(withStyles(styles))(CodeMirrorExample)
);
