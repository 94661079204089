import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";

export const START_FETCH_PRODUCT_APPROVAL_DETAILS = "START_FETCH_PRODUCT_APPROVAL_DETAILS";
export const END_FETCH_PRODUCT_APPROVAL_DETAILS = "END_FETCH_PRODUCT_APPROVAL_DETAILS";

export const START_FETCH_EVALUATION_APPROVAL_DETAILS = "START_FETCH_EVALUATION_APPROVAL_DETAILS";
export const END_FETCH_EVALUATION_APPROVAL_DETAILS = "END_FETCH_EVALUATION_APPROVAL_DETAILS";

export const RESET_ADD_APPROVAL_FOR_CRITERIA = "RESET_ADD_APPROVAL_FOR_CRITERIA";
export const START_ADD_APPROVAL_FOR_CRITERIA = "START_ADD_APPROVAL_FOR_CRITERIA";
export const END_ADD_APPROVAL_FOR_CRITERIA = "END_ADD_APPROVAL_FOR_CRITERIA";

export const SHOW_EVALUATION_APPROVAL_DIALOG = 'SHOW_EVALUATION_APPROVAL_DIALOG';
export const HIDE_EVALUATION_APPROVAL_DIALOG = 'HIDE_EVALUATION_APPROVAL_DIALOG';

export const START_APPROVAL_CRITERIA_ADD = "START_APPROVAL_CRITERIA_ADD";
export const END_APPROVAL_CRITERIA_ADD = "END_APPROVAL_CRITERIA_ADD";

export const START_APPROVAL_CRITERIA_UPDATE = "START_APPROVAL_CRITERIA_UPDATE";
export const END_APPROVAL_CRITERIA_UPDATE = "END_APPROVAL_CRITERIA_UPDATE";

export const START_APPROVAL_CRITERIA_DELETE = "START_APPROVAL_CRITERIA_DELETE";
export const END_APPROVAL_CRITERIA_DELETE = "END_APPROVAL_CRITERIA_DELETE";

export const START_APPROVAL_CRITERIA_SKIP = "START_APPROVAL_CRITERIA_SKIP";
export const END_APPROVAL_CRITERIA_SKIP = "END_APPROVAL_CRITERIA_SKIP";

export const START_APPROVAL_CRITERIA_FETCH = "START_APPROVAL_CRITERIA_FETCH";
export const END_APPROVAL_CRITERIA_FETCH = "END_APPROVAL_CRITERIA_FETCH";

export const START_APPROVAL_CRITERIA_APPROVER_ADD = "START_APPROVAL_CRITERIA_APPROVER_ADD";
export const END_APPROVAL_CRITERIA_APPROVER_ADD = "END_APPROVAL_CRITERIA_APPROVER_ADD";

export const START_APPROVAL_CRITERIA_APPROVER_REMOVE = "START_APPROVAL_CRITERIA_APPROVER_REMOVE";
export const END_APPROVAL_CRITERIA_APPROVER_REMOVE = "END_APPROVAL_CRITERIA_APPROVER_REMOVE";

export const START_ADD_APPROVAL_CRITERIA_THREAD = "START_ADD_APPROVAL_CRITERIA_THREAD";
export const END_ADD_APPROVAL_CRITERIA_THREAD = "END_ADD_APPROVAL_CRITERIA_THREAD";

export const START_FETCH_APPROVAL_CRITERIA_THREAD = "START_FETCH_APPROVAL_CRITERIA_THREAD";
export const END_FETCH_APPROVAL_CRITERIA_THREAD = "END_FETCH_APPROVAL_CRITERIA_THREAD";

export const START_FETCH_THREAD_REPLIES = "START_FETCH_THREAD_REPLIES";
export const END_FETCH_THREAD_REPLIES = "END_FETCH_THREAD_REPLIES";

export const START_UPDATE_APPROVAL_CRITERIA_THREAD = "START_UPDATE_APPROVAL_CRITERIA_THREAD";
export const END_UPDATE_APPROVAL_CRITERIA_THREAD = "END_UPDATE_APPROVAL_CRITERIA_THREAD";

export const START_DELETE_APPROVAL_CRITERIA_THREAD = "START_DELETE_APPROVAL_CRITERIA_THREAD";
export const END_DELETE_APPROVAL_CRITERIA_THREAD = "END_DELETE_APPROVAL_CRITERIA_THREAD";

export const START_APPROVAL_CRITERIA_UPDATE_SEQUENCE = "START_APPROVAL_CRITERIA_UPDATE_SEQUENCE";
export const END_APPROVAL_CRITERIA_UPDATE_SEQUENCE = "END_APPROVAL_CRITERIA_UPDATE_SEQUENCE";

export const RESET_APPROVAL_CRITERIAS = "RESET_APPROVAL_CRITERIAS";


/**
 * Fetch Evaluation single product details
 */

 export  function resetApprovalCriterias(){
    return     {
        type:RESET_APPROVAL_CRITERIAS,
        payload: {}
    };
}

function startProductApprovalDetails(){
    return {
        type:START_FETCH_PRODUCT_APPROVAL_DETAILS,
        payload:{}
    };
}

function endProductApprovalDetails(success,error){
    return {
        type:END_FETCH_PRODUCT_APPROVAL_DETAILS,
        payload:{
            success,error
        }
    };
}

export  function fetchProductApprovalDetails(evaluationId, productId, componentId=0){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();

    const url   =   `${api_server}/${evaluationId}/product/${productId}/approval/${componentId}?t=${timestamp}`;
    // const url   =   `${api_server}/${evaluationId}/approvals?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startProductApprovalDetails());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endProductApprovalDetails(response,error));
        } catch(e){
            dispatch(endProductApprovalDetails(null,{
                message:e.message
            }));
            return;
        }
    };
} 


/**
 * Fetch Evaluation approval product details
 */

function startEvaluationApprovalDetails(){
    return {
        type:START_FETCH_EVALUATION_APPROVAL_DETAILS,
        payload:{}
    };
}

function endEvaluationApprovalDetails(success,error){
    return {
        type:END_FETCH_EVALUATION_APPROVAL_DETAILS,
        payload:{
            success,error
        }
    };
}

export  function fetchEvaluationApprovalDetails(evaluationId,componentId=0){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();

    const url   =   `${api_server}/${evaluationId}/approvals/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startEvaluationApprovalDetails());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endEvaluationApprovalDetails(response,error));
        } catch(e){
            dispatch(endEvaluationApprovalDetails(null,{
                message:e.message
            }));
            return;
        }
    };
} 


/**
 * Adding the Approval:
 */
function startAddApprovalForCriteria(){
    return {
        type:START_ADD_APPROVAL_FOR_CRITERIA,
        payload:{}
    };
}

function endAddApprovalForCriteria(success,error){
    return {
        type:END_ADD_APPROVAL_FOR_CRITERIA,
        payload:{
            success,error
        }
    };
}

export  function fetchAddApprovalForCriteria(params){
    const {evaluation_id,  product_id, criteria_id, data} = params;
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();

    const url   =   `${api_server}/${evaluation_id}/product/${product_id}/criteria/${criteria_id}/approval?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startAddApprovalForCriteria());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddApprovalForCriteria(response,error));
        } catch(e){
            dispatch(endAddApprovalForCriteria(null,{
                message:e.message
            }));
            return;
        }
    };
} 


/**
 * DELETING the Approval:
 */
 function startUpdateApprovalCriteria(){
    return {
        type:START_APPROVAL_CRITERIA_UPDATE,
        payload:{}
    };
}

function endUpdateApprovalCriteria(success,error){
    return {
        type:END_APPROVAL_CRITERIA_UPDATE,
        payload:{
            success,error
        }
    };
}

export  function updateApprovalCriteria(evId,criteriaId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/approval_criteria/${criteriaId}?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startUpdateApprovalCriteria());
        try{
            let [response,error]    =   await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateApprovalCriteria(response,error));
        } catch(e){
            dispatch(endUpdateApprovalCriteria(null,{
                message:e.message
            }));
            return;
        }
    };
} 


export function resetAddApprovalForCriteria(){
    return {
        type:RESET_ADD_APPROVAL_FOR_CRITERIA,
        payload:{}
    };
}


export  function showApprovalDialog(content){
    return     {
        type:SHOW_EVALUATION_APPROVAL_DIALOG,
        payload: content
    };
}


export  function hideApprovalDialog(){
    return     {
        type:HIDE_EVALUATION_APPROVAL_DIALOG,
        payload:{}
    };
}




//-------------------------------------
function startApprovalCriteriaFetch(){
    return {
        type:START_APPROVAL_CRITERIA_FETCH,
        payload:{}
    };
}

function endApprovalCriteriaFetch(success,error){
    return {
        type:END_APPROVAL_CRITERIA_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchApprovalCriteria(evId,compId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/approval_criterias/${compId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startApprovalCriteriaFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url,null,false);
            dispatch(endApprovalCriteriaFetch(response,error));
        } catch(e){
            dispatch(endApprovalCriteriaFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------



//-------------------------------------
function startApprovalCriteriaAdd(){
    return {
        type:START_APPROVAL_CRITERIA_ADD,
        payload:{}
    };
}

function endApprovalCriteriaAdd(success,error){
    return {
        type:END_APPROVAL_CRITERIA_ADD,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function addApprovalCriteria(evId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/approval_criteria?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startApprovalCriteriaAdd());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endApprovalCriteriaAdd(response,error));
        } catch(e){
            dispatch(endApprovalCriteriaAdd(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------


function startUpdateApprovalCriteriaSequence() {
    return {
        type: START_APPROVAL_CRITERIA_UPDATE_SEQUENCE,
        payload: null
    }
}

function endUpdateApprovalCriteriaSequence(success, error) {
    return {
        type: END_APPROVAL_CRITERIA_UPDATE_SEQUENCE,
        payload: {
            success,
            error,
        }
    }
}


export function updateApprovalCriteriaSequence(evlId,criteriaId,data) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${evlId}/approval_criteria/${criteriaId}/seq?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateApprovalCriteriaSequence());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url,data);
            dispatch(endUpdateApprovalCriteriaSequence(response, error));
        } catch (e) {
            dispatch(endUpdateApprovalCriteriaSequence(null, {
                message: e.message
            }));
            return;
        }
    };
}



//-------------------------------------
function startApprovalCriteriaApproverAdd(){
    return {
        type:START_APPROVAL_CRITERIA_APPROVER_ADD,
        payload:{}
    };
}

function endApprovalCriteriaApproverAdd(success,error){
    return {
        type:END_APPROVAL_CRITERIA_APPROVER_ADD,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function addApprovalCriteriaApprover(evId,criteriaId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/approval_criteria/${criteriaId}/criteria_approver?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startApprovalCriteriaApproverAdd());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endApprovalCriteriaApproverAdd(response,error));
        } catch(e){
            dispatch(endApprovalCriteriaApproverAdd(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------




//-------------------------------------
function startApprovalCriteriaApproverRemove(){
    return {
        type:START_APPROVAL_CRITERIA_APPROVER_REMOVE,
        payload:{}
    };
}

function endApprovalCriteriaApproverRemove(success,error){
    return {
        type:END_APPROVAL_CRITERIA_APPROVER_REMOVE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function removeApprovalCriteriaApprover(evId,criteriaId,approverId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/approval_criteria/${criteriaId}/criteria_approver/${approverId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startApprovalCriteriaApproverRemove());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
            dispatch(endApprovalCriteriaApproverRemove(response,error));
        } catch(e){
            dispatch(endApprovalCriteriaApproverRemove(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------



//-------------------------------------
function startSkipApprovalCriteria(){
    return {
        type:START_APPROVAL_CRITERIA_SKIP,
        payload:{}
    };
}

function endSkipApprovalCriteria(success,error){
    return {
        type:END_APPROVAL_CRITERIA_SKIP,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function skipApprovalCriteria(evId,criteriaId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/approval_criteria/${criteriaId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startSkipApprovalCriteria());
        try{
            let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
            dispatch(endSkipApprovalCriteria(response,error));
        } catch(e){
            dispatch(endSkipApprovalCriteria(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------



//-------------------------------------
function startDeleteApprovalCriteria(){
    return {
        type:START_APPROVAL_CRITERIA_DELETE,
        payload:{}
    };
}

function endDeleteApprovalCriteria(success,error){
    return {
        type:END_APPROVAL_CRITERIA_DELETE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function deleteApprovalCriteria(evId,criteriaId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/approval_criteria/${criteriaId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteApprovalCriteria());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteApprovalCriteria(response,error));
        } catch(e){
            dispatch(endDeleteApprovalCriteria(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------


//-------------------------------------
function startFetchApprovalCriteriaThread(){
    return {
        type:START_FETCH_APPROVAL_CRITERIA_THREAD,
        payload:{}
    };
}

function endFetchApprovalCriteriaThread(success,error){
    return {
        type:END_FETCH_APPROVAL_CRITERIA_THREAD,
        payload:{
            success,error
        }
    };
}

export  function fetchApprovalCriteriaThread(evId,componentId,sortBy, threadType=''){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/component/${componentId}/thread?sortBy=${sortBy}&thread_type=${threadType}&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchApprovalCriteriaThread());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url,null,false);
            dispatch(endFetchApprovalCriteriaThread(response,error));
        } catch(e){
            dispatch(endFetchApprovalCriteriaThread(null,{
                message:e.message
            }));
            return;
        }
    };
} 



//-------------------------------------
function startFetchThreadReplies(){
    return {
        type:START_FETCH_THREAD_REPLIES,
        payload:{}
    };
}

function endFetchThreadReplies(success,error,threadId){
    return {
        type:END_FETCH_THREAD_REPLIES,
        payload:{
            success,error,threadId
        }
    };
}

export  function fetchThreadReplies(evId,componentId,threadId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/component/${componentId}/thread/${threadId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchThreadReplies());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url,null,false);
            dispatch(endFetchThreadReplies(response,error,threadId));
        } catch(e){
            dispatch(endFetchThreadReplies(null,{
                message:e.message
            },threadId));
            return;
        }
    };
} 

//------------------------------------------



//-------------------------------------
function startAddApprovalCriteriaThread(){
    return {
        type:START_ADD_APPROVAL_CRITERIA_THREAD,
        payload:{}
    };
}

function endAddApprovalCriteriaThread(success,error){
    return {
        type:END_ADD_APPROVAL_CRITERIA_THREAD,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function addApprovalCriteriaThread(evId,componentId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/component/${componentId}/thread?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddApprovalCriteriaThread());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endAddApprovalCriteriaThread(response,error));
        } catch(e){
            dispatch(endAddApprovalCriteriaThread(null,{
                message:e.message
            }));
            return;
        }
    };
} 



//-------------------------------------
function startUpdateApprovalCriteriaThread(){
    return {
        type:START_UPDATE_APPROVAL_CRITERIA_THREAD,
        payload:{}
    };
}

function endUpdateApprovalCriteriaThread(success,error){
    return {
        type:END_UPDATE_APPROVAL_CRITERIA_THREAD,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateApprovalCriteriaThread(evId,componentId,threadId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/component/${componentId}/thread/${threadId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateApprovalCriteriaThread());
        try{
            let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
            dispatch(endUpdateApprovalCriteriaThread(response,error));
        } catch(e){
            dispatch(endUpdateApprovalCriteriaThread(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------



//-------------------------------------
function startDeleteApprovalCriteriaThread(){
    return {
        type:START_DELETE_APPROVAL_CRITERIA_THREAD,
        payload:{}
    };
}

function endDeleteApprovalCriteriaThread(success,error){
    return {
        type:END_DELETE_APPROVAL_CRITERIA_THREAD,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function deleteApprovalCriteriaThread(evId,componentId,threadId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/component/${componentId}/thread/${threadId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteApprovalCriteriaThread());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteApprovalCriteriaThread(response,error));
        } catch(e){
            dispatch(endDeleteApprovalCriteriaThread(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------

