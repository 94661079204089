import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment"

export const SHOW_ADD_AUTOMATION_DIALOG = "SHOW_ADD_AUTOMATION_DIALOG";
export const HIDE_ADD_AUTOMATION_DIALOG = "HIDE_ADD_AUTOMATION_DIALOG";

export const START_ADD_NEW_AUTOMATION = "START_ADD_NEW_AUTOMATION";
export const END_ADD_NEW_AUTOMATION = "END_ADD_NEW_AUTOMATION";

export const START_UPDATE_AUTOMATION = "START_UPDATE_AUTOMATION";
export const END_UPDATE_AUTOMATION = "END_UPDATE_AUTOMATION";

export const START_FETCH_AUTOMATIONS = "START_FETCH_AUTOMATIONS";
export const END_FETCH_AUTOMATIONS = "END_FETCH_AUTOMATIONS";

export const START_DELETE_AUTOMATION = "START_DELETE_AUTOMATION";
export const END_DELETE_AUTOMATION = "END_DELETE_AUTOMATION";

export const START_FETCH_AUTOMATION_DATA = "START_FETCH_AUTOMATION_DATA";
export const END_FETCH_AUTOMATION_DATA = "END_FETCH_AUTOMATION_DATA";

export const START_FETCH_MASTER_TRIGGERS = "START_FETCH_MASTER_TRIGGERS";
export const END_FETCH_MASTER_TRIGGERS = "END_FETCH_MASTER_TRIGGERS";

export const START_FETCH_MASTER_ACTIONS = "START_FETCH_MASTER_ACTIONS"
export const END_FETCH_MASTER_ACTIONS = "END_FETCH_MASTER_ACTIONS"

export const START_ADD_NEW_AUTOMATION_ACTION = "START_ADD_NEW_AUTOMATION_ACTION";
export const END_ADD_NEW_AUTOMATION_ACTION = "END_ADD_NEW_AUTOMATION_ACTION";

export const START_UPDATE_AUTOMATION_ACTION = "START_UPDATE_AUTOMATION_ACTION";
export const END_UPDATE_AUTOMATION_ACTION = "END_UPDATE_AUTOMATION_ACTION";

export const START_DELETE_AUTOMATION_ACTION = "START_DELETE_AUTOMATION_ACTION";
export const END_DELETE_AUTOMATION_ACTION = "END_DELETE_AUTOMATION_ACTION";

export const START_FETCH_AUTOMATION_ACTION_DATA = "START_FETCH_AUTOMATION_ACTION_DATA";
export const END_FETCH_AUTOMATION_ACTION_DATA = "END_FETCH_AUTOMATION_ACTION_DATA";


export const START_ADD_NEW_AUTOMATION_ACTION_CONNECTION = "START_ADD_NEW_AUTOMATION_ACTION_CONNECTION";
export const END_ADD_NEW_AUTOMATION_ACTION_CONNECTION = "END_ADD_NEW_AUTOMATION_ACTION_CONNECTION";

export const START_DELETE_AUTOMATION_ACTION_CONNECTION = "START_DELETE_AUTOMATION_ACTION_CONNECTION";
export const END_DELETE_AUTOMATION_ACTION_CONNECTION = "END_DELETE_AUTOMATION_ACTION_CONNECTION";

export const START_FETCH_AUTOMATION_ACTION_CONNECTION_DATA = "START_FETCH_AUTOMATION_ACTION_CONNECTION_DATA";
export const END_FETCH_AUTOMATION_ACTION_CONNECTION_DATA = "END_FETCH_AUTOMATION_ACTION_CONNECTION_DATA";

export const START_UPDATE_AUTOMATION_CONNECTION = "START_UPDATE_AUTOMATION_CONNECTION";
export const END_UPDATE_AUTOMATION_CONNECTION = "END_UPDATE_AUTOMATION_CONNECTION";

export const START_FETCH_JOBS = "START_FETCH_JOBS";
export const END_FETCH_JOBS = "END_FETCH_JOBS";

export const START_FETCH_AUTOMATION_COMPONENTS = "START_FETCH_AUTOMATION_COMPONENTS";
export const END_FETCH_AUTOMATION_COMPONENTS = "END_FETCH_AUTOMATION_COMPONENTS";

export const START_FETCH_AUTOMATION_COMPONENT_DETAILS = "START_FETCH_AUTOMATION_COMPONENT_DETAILS";
export const END_FETCH_AUTOMATION_COMPONENT_DETAILS = "END_FETCH_AUTOMATION_COMPONENT_DETAILS";

export const START_FETCH_AUTOMATION_COMPONENT_VARIABLES = "START_FETCH_AUTOMATION_COMPONENT_VARIABLES";
export const END_FETCH_AUTOMATION_COMPONENT_VARIABLES = "END_FETCH_AUTOMATION_COMPONENT_VARIABLES";

export const START_FETCH_AUTOMATION_JOBS = "START_FETCH_AUTOMATION_JOBS"
export const END_FETCH_AUTOMATION_JOBS = "END_FETCH_AUTOMATION_JOBS"

export const START_FETCH_AUTOMATION_JOB = "START_FETCH_AUTOMATION_JOB"
export const END_FETCH_AUTOMATION_JOB = "END_FETCH_AUTOMATION_JOB"

export const START_FETCH_AUTOMATION_JOB_COMPONENT = "START_FETCH_AUTOMATION_JOB_COMPONENT"
export const END_FETCH_AUTOMATION_JOB_COMPONENT = "END_FETCH_AUTOMATION_JOB_COMPONENT"

export const BUILDER_SUMMARY_SELECTED_TAB = "BUILDER_SUMMARY_SELECTED_TAB"

export const SHOW_AUTOMATION_OPTIONAL_FIELD_SELECT_DIALOG = "SHOW_AUTOMATION_OPTIONAL_FIELD_SELECT_DIALOG"
export const HIDE_AUTOMATION_OPTIONAL_FIELD_SELECT_DIALOG = "HIDE_AUTOMATION_OPTIONAL_FIELD_SELECT_DIALOG"

export const START_UPDATE_AUTOMATION_CONFIG_ON_CHANGE = "START_UPDATE_AUTOMATION_CONFIG_ON_CHANGE"
export const END_UPDATE_AUTOMATION_CONFIG_ON_CHANGE = "END_UPDATE_AUTOMATION_CONFIG_ON_CHANGE"

export const START_FETCH_ADMIN_AUTOMATIONS = "START_FETCH_ADMIN_AUTOMATIONS"
export const END_FETCH_ADMIN_AUTOMATIONS = "END_FETCH_ADMIN_AUTOMATIONS"

export const START_ADD_NEW_EXISTING_AUTOMATION = "START_ADD_NEW_EXISTING_AUTOMATION"
export const END_ADD_NEW_EXISTING_AUTOMATION = "END_ADD_NEW_EXISTING_AUTOMATION"

export function builderSummarySelectTab(value) {
    return {
        type: BUILDER_SUMMARY_SELECTED_TAB,
        payload: { value }
    };
}

export function showAddAutomationDialog() {
    return {
        type: SHOW_ADD_AUTOMATION_DIALOG,
        payload: {}
    };
}

export function hideAddAutomationDialog() {
    return {
        type: HIDE_ADD_AUTOMATION_DIALOG,
        payload: {}
    };
}

export function showSelectOptionFieldsDialog() {
    return {
        type: SHOW_AUTOMATION_OPTIONAL_FIELD_SELECT_DIALOG,
        payload: {}
    };
}

export function hideSelectOptionFieldsDialog() {
    return {
        type: HIDE_AUTOMATION_OPTIONAL_FIELD_SELECT_DIALOG,
        payload: {}
    };
}

function startFetchAutomations() {
    return {
        type: START_FETCH_AUTOMATIONS,
        payload: {}
    };
}

function endFetchAutomations(success, error) {
    return {
        type: END_FETCH_AUTOMATIONS,
        payload: {
            success, error
        }
    };
}

export function fetchAutomations(queryParam) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/list?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAutomations());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url, queryParam, false);
            dispatch(endFetchAutomations(response, error));
        } catch (e) {
            dispatch(endFetchAutomations(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startAddNewAutomation() {
    return {
        type: START_ADD_NEW_AUTOMATION,
        payload: {}
    };
}

function endAddNewAutomation(success, error) {
    return {
        type: END_ADD_NEW_AUTOMATION,
        payload: {
            success, error
        }
    };
}

export function addNewAutomation(data = {}) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewAutomation());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewAutomation(response, error));
        } catch (e) {
            dispatch(endAddNewAutomation(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateAutomation() {
    return {
        type: START_UPDATE_AUTOMATION,
        payload: {}
    };
}

function endUpdateAutomation(success, error) {
    return {
        type: END_UPDATE_AUTOMATION,
        payload: {
            success, error
        }
    };
}

export function updateAutomation(automationId, data) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateAutomation());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateAutomation(response, error));
        } catch (e) {
            dispatch(endUpdateAutomation(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteAutomation() {
    return {
        type: START_DELETE_AUTOMATION,
        payload: null
    };
}

function endDeleteAutomation(automationId, success, error) {
    return {
        type: END_DELETE_AUTOMATION,
        payload: {
            success, error, automationId
        }
    };
}

export function deleteAutomation(automationId) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteAutomation());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteAutomation(automationId, response, error));
        } catch (e) {
            dispatch(endDeleteAutomation(automationId, null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchAutomationData() {
    return {
        type: START_FETCH_AUTOMATION_DATA,
        payload: {}
    };
}

function endFetchAutomationData(success, error) {
    return {
        type: END_FETCH_AUTOMATION_DATA,
        payload: {
            success, error
        }
    };
}

export function fetchAutomation(automationId) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAutomationData());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAutomationData(response, error));
        } catch (e) {
            dispatch(endFetchAutomationData(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchMasterTriggers() {
    return {
        type: START_FETCH_MASTER_TRIGGERS,
        payload: {}
    };
}

function endFetchMasterTriggers(success, error) {
    return {
        type: END_FETCH_MASTER_TRIGGERS,
        payload: {
            success, error
        }
    };
}

export function fetchMasterTriggers() {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/master-triggers?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchMasterTriggers());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchMasterTriggers(response, error));
        } catch (e) {
            dispatch(endFetchMasterTriggers(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchMasterActions() {
    return {
        type: START_FETCH_MASTER_ACTIONS,
        payload: {}
    };
}

function endFetchMasterActions(success, error) {
    return {
        type: END_FETCH_MASTER_ACTIONS,
        payload: {
            success, error
        }
    };
}

export function fetchMasterActions() {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/master-actions?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchMasterActions());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchMasterActions(response, error));
        } catch (e) {
            dispatch(endFetchMasterActions(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAddAutomationAction() {
    return {
        type: START_ADD_NEW_AUTOMATION_ACTION,
        payload: {}
    };
}

function endAddNewAutomationAction(success, error) {
    return {
        type: END_ADD_NEW_AUTOMATION_ACTION,
        payload: {
            success, error
        }
    };
}

export function addNewAutomationAction(id, data = {}) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${id}/component?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddAutomationAction());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewAutomationAction(response, error));
        } catch (e) {
            dispatch(endAddNewAutomationAction(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateAutomationAction() {
    return {
        type: START_UPDATE_AUTOMATION_ACTION,
        payload: {}
    };
}

function endUpdateAutomationAction(success, error) {
    return {
        type: END_UPDATE_AUTOMATION_ACTION,
        payload: {
            success, error
        }
    };
}

export function updateAutomationAction(automationId, actionId, data) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/component/${actionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateAutomationAction());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateAutomationAction(response, error));
        } catch (e) {
            dispatch(endUpdateAutomationAction(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteAutomationAction() {
    return {
        type: START_DELETE_AUTOMATION_ACTION,
        payload: null
    };
}

function endDeleteAutomationAction(automationId, success, error) {
    return {
        type: END_DELETE_AUTOMATION_ACTION,
        payload: {
            success, error, automationId
        }
    };
}

export function deleteAutomationAction(automationId, actionId) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/action/${actionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteAutomationAction());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteAutomationAction(automationId, response, error));
        } catch (e) {
            dispatch(endDeleteAutomationAction(automationId, null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchAutomationActionData() {
    return {
        type: START_FETCH_AUTOMATION_ACTION_DATA,
        payload: {}
    };
}

function endFetchAutomationActionData(success, error) {
    return {
        type: END_FETCH_AUTOMATION_ACTION_DATA,
        payload: {
            success, error
        }
    };
}

export function fetchAutomationActions(automationId) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/actions?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAutomationActionData());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAutomationActionData(response, error));
        } catch (e) {
            dispatch(endFetchAutomationActionData(null, {
                message: e.message
            }));
            return;
        }
    };
}





function startAddAutomationActionConnection() {
    return {
        type: START_ADD_NEW_AUTOMATION_ACTION_CONNECTION,
        payload: {}
    };
}

function endAddNewAutomationActionConnection(success, error) {
    return {
        type: END_ADD_NEW_AUTOMATION_ACTION_CONNECTION,
        payload: {
            success, error
        }
    };
}

export function addNewAutomationActionConnection(id, data = {}) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${id}/action/connection?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddAutomationActionConnection());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewAutomationActionConnection(response, error));
        } catch (e) {
            dispatch(endAddNewAutomationActionConnection(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteAutomationActionConnection() {
    return {
        type: START_DELETE_AUTOMATION_ACTION_CONNECTION,
        payload: null
    };
}

function endDeleteAutomationActionConnection(automationId, success, error) {
    return {
        type: END_DELETE_AUTOMATION_ACTION_CONNECTION,
        payload: {
            success, error, automationId
        }
    };
}

export function deleteAutomationActionConnection(automationId, connectionId) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/action/connection/${connectionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteAutomationActionConnection());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteAutomationActionConnection(automationId, response, error));
        } catch (e) {
            dispatch(endDeleteAutomationActionConnection(automationId, null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateAutomationConnection() {
    return {
        type: START_UPDATE_AUTOMATION_CONNECTION,
        payload: {}
    };
}

function endUpdateAutomationConnection(success, error) {
    return {
        type: END_UPDATE_AUTOMATION_CONNECTION,
        payload: {
            success, error
        }
    };
}

export function updateAutomationConnection(automationId, connectionId, data) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/action/connection/${connectionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateAutomationConnection());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateAutomationConnection(response, error));
        } catch (e) {
            dispatch(endUpdateAutomationConnection(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchAutomationActionConnectionData() {
    return {
        type: START_FETCH_AUTOMATION_ACTION_CONNECTION_DATA,
        payload: {}
    };
}

function endFetchAutomationActionConnectionData(success, error) {
    return {
        type: END_FETCH_AUTOMATION_ACTION_CONNECTION_DATA,
        payload: {
            success, error
        }
    };
}

export function fetchAutomationActionConnections(automationId) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/action/connections?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAutomationActionConnectionData());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAutomationActionConnectionData(response, error));
        } catch (e) {
            dispatch(endFetchAutomationActionConnectionData(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchAutomationComponents() {
    return {
        type: START_FETCH_AUTOMATION_COMPONENTS,
        payload: {}
    };
}

function endFetchAutomationComponents(success, error) {
    return {
        type: END_FETCH_AUTOMATION_COMPONENTS,
        payload: {
            success, error
        }
    };
}

export function fetchAutomationComponents(automationId) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/component?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAutomationComponents());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAutomationComponents(response, error));
        } catch (e) {
            dispatch(endFetchAutomationComponents(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startComponentDetails() {
    return {
        type: START_FETCH_AUTOMATION_COMPONENT_DETAILS,
        payload: {}
    };
}

function endComponentDetails(success, error) {
    return {
        type: END_FETCH_AUTOMATION_COMPONENT_DETAILS,
        payload: {
            success, error
        }
    };
}

export function fetchComponentDetails(automationId, componentId) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/component/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startComponentDetails());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endComponentDetails(response, error));
        } catch (e) {
            dispatch(endComponentDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}



function startFechComponentVariables() {
    return {
        type: START_FETCH_AUTOMATION_COMPONENT_VARIABLES,
        payload: {}
    };
}

function endFetchComponentVariables(success, error) {
    return {
        type: END_FETCH_AUTOMATION_COMPONENT_VARIABLES,
        payload: {
            success, error
        }
    };
}

export function fetchComponentVariables(automationId, componentId) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/variables/${componentId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFechComponentVariables());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchComponentVariables(response, error));
        } catch (e) {
            dispatch(endFetchComponentVariables(null, {
                message: e.message
            }));
            return;
        }
    };
}


// Jobs apis
function startFechAutomationJobs() {
    return {
        type: START_FETCH_AUTOMATION_JOBS,
        payload: {}
    };
}

function endFetchAutomationJobs(success, error) {
    return {
        type: END_FETCH_AUTOMATION_JOBS,
        payload: {
            success, error
        }
    };
}

export function fetchAutomationJobs(automationId, queryParam) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/jobs?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFechAutomationJobs());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url, queryParam, false);
            dispatch(endFetchAutomationJobs(response, error));
        } catch (e) {
            dispatch(endFetchAutomationJobs(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchAutomationJob() {
    return {
        type: START_FETCH_AUTOMATION_JOB,
        payload: {}
    };
}

function endFetchAutomationJob(success, error) {
    return {
        type: END_FETCH_AUTOMATION_JOB,
        payload: {
            success, error
        }
    };
}

export function fetchAutomationJob(automationId, jobId) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/job/${jobId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAutomationJob());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAutomationJob(response, error));
        } catch (e) {
            dispatch(endFetchAutomationJob(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchAutomationJobComponent() {
    return {
        type: START_FETCH_AUTOMATION_JOB_COMPONENT,
        payload: {}
    };
}

function endFetchAutomationJobComponent(success, error) {
    return {
        type: END_FETCH_AUTOMATION_JOB_COMPONENT,
        payload: {
            success, error
        }
    };
}

export function fetchAutomationJobComponents(automationId, jobId) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/job/${jobId}/components?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAutomationJobComponent());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchAutomationJobComponent(response, error));
        } catch (e) {
            dispatch(endFetchAutomationJobComponent(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startUpdateActionConfig() {
    return {
        type: START_UPDATE_AUTOMATION_CONFIG_ON_CHANGE,
        payload: {}
    };
}

function endUpdateActionConfig(success, error) {
    return {
        type: END_UPDATE_AUTOMATION_CONFIG_ON_CHANGE,
        payload: {
            success, error
        }
    };
}

export function updateActionConfigOnChange(automationId, actionId, data) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/${automationId}/component/${actionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateActionConfig());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endUpdateActionConfig(response, error));
        } catch (e) {
            dispatch(endUpdateActionConfig(null, {
                message: e.message
            }));
            return;
        }
    };
}



function startFetchAdminAutomations() {
    return {
        type: START_FETCH_ADMIN_AUTOMATIONS,
        payload: {}
    };
}

function endFetchAdminAutomations(success, error) {
    return {
        type: END_FETCH_ADMIN_AUTOMATIONS,
        payload: {
            success, error
        }
    };
}

export function fetchAdminAutomations(queryParam) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/templates/automation?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchAdminAutomations());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url, queryParam, false);
            dispatch(endFetchAdminAutomations(response, error));
        } catch (e) {
            dispatch(endFetchAdminAutomations(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startAddNewExistingAutomation() {
    return {
        type: START_ADD_NEW_EXISTING_AUTOMATION,
        payload: {}
    };
}

function endAddNewExistingAutomation(success, error) {
    return {
        type: END_ADD_NEW_EXISTING_AUTOMATION,
        payload: {
            success, error
        }
    };
}

export function addNewExistingAutomation(data = {}) {
    const api_server = Environment.api_host("AUTOMATION");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/use_template?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewExistingAutomation());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewExistingAutomation(response, error));
        } catch (e) {
            dispatch(endAddNewExistingAutomation(null, {
                message: e.message
            }));
            return;
        }
    };
}