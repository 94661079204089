import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Box, Grid, Typography } from "@material-ui/core";
import AutomationCard from "./automationCard";

const connectedProps = (state) => ({

});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  variableDiv: {
    width: '25%',
    position: 'fixed',
    right: '41%',
    bottom: 20,
    transition: 'width .2s ease,height .2s ease',
    border: '1px solid #ced5db',
    borderTop: '6px solid #133440',
    background: '#f7f7f7'
  },
  minVar: {
    height: 60,
  },
  maxVar: {
    height: '60vh',
  },
  heading: {
    borderBottom: '1px solid #ced5db',
    background: '#fff',
    padding: 10,
    display: 'flex',
    width: '100%'
  },
  title: {
    fontSize: 16,
    fontWeight: 600
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: '#919ba5'
  },
  list: {
    padding: 10,
    height: '50vh',
    overflow: 'auto'
  },
  // listItem: {
  //   background: '#fff',
  //   border: '1px solid #ced5db',
  //   color: '#24434f',
  //   padding: '4px 8px',
  //   marginBottom: 10,
  //   cursor: 'pointer'
  // },
  titleSec: {
    flex: '1'
  },
  actionSec: {
    textAlign: 'right'
  },
  expandIcon: {
    cursor: 'pointer'
  },
  iconType: {
    width: 18,
    height: 18
  },
  accRoot: {
    background: 'transparent',
    boxShadow: 'none'
  },
  accDetailRoot: {
    padding: '0px 5px',
    display: 'block'
  }
});

class ExistingAutomationSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    const { classes, automations, selectedAutomation } = this.props;
    console.log(automations, 'automations')
    console.log(selectedAutomation, 'selectedAutomation.id')
    return (
      <>
        <Typography style={{ marginBottom: '8px' }}>Select Automation Template</Typography>
        <Grid container alignItems="center" className={classes.mb16}>
          {automations && automations?.map((automation, index) => {
            return (
              <Grid item xs={12} sm={6} lg={3} style={{ paddingRight: '8px' }}>
                <AutomationCard
                  key={index}
                  automation={automation}
                  isSelected={selectedAutomation && selectedAutomation?.id === automation.id}
                  handleClickCard={() => {
                    this.props.selectAutomation(automation);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ExistingAutomationSelection));
