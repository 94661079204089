import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import classnames from "classnames";

// components
import Image from 'Components/Common/image.jsx'
import Response from './Response';
// assets
import DefaultImage from "assets/images/evaluation/default.svg"
import { CommonFn } from "services/commonFn";
import Tooltip from '@material-ui/core/Tooltip';

const connectedProps = (state) => ({
    evaluation: state.evaluationMetaData.evaluation,
    criterias: state.evaludationCriteria.criterias,
    expandedCriteriaId: state.evaludationCriteria.expandedCriteriaId,
    scores: state.evaludationCriteria.scores,
    evaluationProductsList: state.evaluationMetaData.evaluationProductsList,
    dialogEvaluationAskseller: state.templateEvaluation.dialogEvaluationAskseller,
    userReponses: state.evaludationCriteria.userReponses,
    user: state.authUser.user,
    report: state.evaluationReportData.report,
});

const connectionActions = {
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        width: "450px",
        marginRight: "30px",
        marginTop: 10,
    },
    'colbTitle': {
        fontSize: theme.spacing(2)
    },
    actions: {
        paddingTop: theme.spacing(2)
    },
    productImageContainer: {
        textAlign: "center"
    },
    productTitle: {
        textAlign: "center",
        fontSize: theme.spacing(2.2),
        fontWeight: 500,
        color: '#20253A',
        marginTop: 10
    },
    productScore: {
        textAlign: "center",
        fontSize: theme.spacing(2.5),
        fontWeight: 600,
        color: "#ccc",
        flex: 1,
        alignSelf: "flex-end",
        paddingTop: theme.spacing(2)
    },
    productScoreControl: {

    },
    askSellerMultiSelect: {
        textAlign: "center",
        fontSize: theme.spacing(2.5),
        fontWeight: 600,
        flex: 1,
        cursor: "pointer",
        alignSelf: "flex-end"
    },
    askSellerMultiSelectImage: {
        height: "30px"
    },
    askSellerTitle: {
        textAlign: "center",
        fontSize: theme.spacing(1.5),
        fontWeight: 500,
        color: '#20253A',
        marginTop: 10,
        marginBottom: 0,
    },
    prodHeading: {
        background: "#fff",
        position: 'relative',
    },
    prodHeadingTop: {
        padding: theme.spacing(2),
        height: "100px",
        background: "#fff",
        position: 'relative',
        display: "flex",
    },
    prodHeadingBottom: {
        position: 'absolute',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        right: theme.spacing(2),
    },
    criteriaTitle: {
        fontSize: theme.spacing(2.1),
        fontWeight: 500
    },
    criteriaRequirementCount: {
        fontSize: theme.spacing(2),
        fontWeight: 400,
        color: "#ccc",
        textAlign: "right"
    },
    overallScore: {
        fontSize: theme.spacing(2),
        fontWeight: 400,
        color: "#ccc",
        textAlign: "center"
    },
    criteria: {
        marginBottom: 15,
        background: "#fff",
        "&.options-hide": {
            opacity: 0.2,
            pointerEvents: 'none'
        }
    },
    activeCriteria: {
        paddingBottom: 18,
    },
    criteriaHead: {
        padding: theme.spacing(3.3, 2),
        borderTop: "solid 1px #EEEEEE",
        borderBottom: "solid 1px #EEEEEE",
        "&.highLight": {
            borderTop: "solid 1px #49B8FA"
        }
    },

    expandBottomSpace: {
        height: 66,
        background: "#f7f7f7",
        borderTop: "solid 1px #49B8FA"
    },
    percent: {
        background: '#EBEFF0',
        borderRadius: 15,
        color: '#04363D',
        fontSize: 12,
        padding: 12,
        fontWeight: 500
    },
    logoContainer: {
        height: '100%',
        textAlign: 'center',
        placeContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    productName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    productImageContainer1: {
        width: 40,
        height: 40,
        padding: 4,
        margin:'0 auto',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 6px #0000001A',
        borderRadius: 10
    },
    productImage: {
        width: '100%',
        height: 'auto',
    },
    overAllDiv: {
        background: '#f1f1f1',
        borderRadius: theme.spacing(1.25),
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between'
    },
    overAllDivCriteriaHead: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    overAllText: {
        fontWeight: 600,
        fontSize: theme.spacing(1.8)
    },
    overAllPer: {
        float: 'right',
        color: 'green'
    },
});

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            askSellerMulti: false
        }
    }

    render() {
        const classes = this.props.classes;
        var product = this.props.product;

        const { report } = this.props;
        if (report === undefined || report === null) {
            return null
        }

        const allCriterias = report?.Criterias;

        var ruleFailRequirementIds = [];
        var ruleFailCriteriaIds = [];

        const rulePassRequirementIds = [];
        const rulePassCriteriaIds = [];

        if (this.props.isRespond) {
            allCriterias && this.props.userReponses && allCriterias.forEach((criteria) => {
                if (criteria?.rules?.length) {
                    criteria.rules.forEach((rule) => {
                        if (rule?.ActionType === 2) {
                            let pushToRequirementArray = CommonFn.findAnswer(this.props.userReponses, rule, criteria.requirements);

                            if (pushToRequirementArray && ruleFailRequirementIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                ruleFailRequirementIds.push(parseInt(rule?.ActionData));
                            } else if (!pushToRequirementArray && rulePassRequirementIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                rulePassRequirementIds.push(parseInt(rule?.ActionData))
                            }
                        }
                        if (rule?.ActionType === 1) {
                            let pushToCrtieriaArray = CommonFn.findAnswer(this.props.userReponses, rule, criteria.requirements);
                            if (pushToCrtieriaArray && ruleFailCriteriaIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                ruleFailCriteriaIds.push(parseInt(rule?.ActionData));
                            } else if (!pushToCrtieriaArray && rulePassCriteriaIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                rulePassCriteriaIds.push(parseInt(rule?.ActionData))
                            }
                        }
                    });
                }
            });
        }

        ruleFailRequirementIds = ruleFailRequirementIds.filter((item) => {
            if (rulePassRequirementIds.indexOf(item) > -1) {
                return false;
            }
            return true;
        });

        ruleFailCriteriaIds = ruleFailCriteriaIds.filter((item) => {
            if (rulePassCriteriaIds.indexOf(item) > -1) {
                return false;
            }
            return true;
        });

        const criterias = allCriterias && allCriterias.map((criteria) => {
            criteria['hide'] = false;
            if (ruleFailCriteriaIds.indexOf(criteria.id.toString()) > -1) {
                criteria['hide'] = true;
            }
            criteria.requirements.map((requirement) => {
                requirement['hide'] = false;
                if (ruleFailRequirementIds.indexOf(requirement.id.toString()) > -1) {
                    requirement['hide'] = true;
                }
            });
            return criteria;
        });

        var overAllTotalScore = 0;
        var productProgress = 0;
        // console.log(product, 'productttttttttttttttt')
        if (this.props.productScoreMap[product.ID] !== undefined) {
            var overallScore = this.props.productScoreMap[product.ID]?.OverallScore;
            var overAllScoreOpted = overallScore?.TotalOpted === null ? 0 : overallScore?.TotalOpted;
            var overAllScoreMax = overallScore?.TotalMax === null ? 0 : overallScore?.TotalMax;
            overAllTotalScore = (overAllScoreOpted / overAllScoreMax * 100);
            if (isNaN(overAllTotalScore)) {
                overAllTotalScore = 0;
            }
            var overallProgress = this.props.productScoreMap[product.ID]?.OverallProgress;
            productProgress = overallProgress[0]?.Completed === 0 ? 0 : Math.round(overallProgress[0]?.Completed * 100 / overallProgress[0]?.Total)
        }

        var maxScore = this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation?.MaxScore && this.props.evaluation?.MaxScore !== null && this.props.evaluation?.MaxScore !== '' ? this.props.evaluation.MaxScore : 5;
        var overallAbsoluteScore = ((Math.round(overAllTotalScore) * maxScore) / 100);



        return <div className={!this.props.isPDfReport ? classes.root : ''}>
            {<div className={classes.prodHeading}>
                <Grid container alignItems={'center'}>
                    <Grid item lg={12}>
                        <div style={{ padding: 10, textAlign:'center' }}>
                            <div className={classes.productImageContainer1}>
                                <Image alt="" src={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo} className={classes.productImage} />
                            </div>
                            <Tooltip className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{product.Name}</span>} arrow>
                                <Typography className={classes.productName}>{product.Name}</Typography>
                            </Tooltip>
                        </div>
                    </Grid>
                    {/* {this.props.enableScoring && <Grid item lg={6} style={{ textAlign: 'right' }}>
                        <CircleProgress className={classes.productScoreControl} value={productProgress} label={productProgress + '%'} width={70} height={70} />
                    </Grid>} */}
                    {this.props.enableScoring && <Grid item lg={12} style={{ padding: '0px 10px 10px 10px' }}>
                        <div className={classes.overAllDiv}>
                            <span className={classes.overAllText}>Overall Progress</span>
                            <span className={classes.overAllPer}>{productProgress}%</span>
                        </div>
                    </Grid>}
                    {this.props.enableScoring && <Grid item lg={12} style={{ padding: '0px 10px 10px 10px' }}>
                        <div className={classes.overAllDiv}>
                            <span className={classes.overAllText}>Overall Score</span>
                            <span className={classes.overAllPer}>{overallAbsoluteScore + '/' + maxScore}</span>
                            <span className={classes.overAllPer}>{Math.round(overAllTotalScore)}%</span>
                        </div>
                    </Grid>}
                </Grid>
            </div>}
            <div className={classes.criterias}>
                {
                    criterias !== null && criterias.map((criteria, k) => {
                        if (criteria.hide) {
                            return null;
                        }
                        var crespondable = true
                        var criteria_score = 0;
                        if (this.props.scores[product.ID] !== undefined && this.props.scores[product.ID][criteria.id] !== undefined) {
                            var score_obj = this.props.scores[product.ID][criteria.id];
                            criteria_score = parseFloat((parseFloat(score_obj.totalOpted) / parseFloat(score_obj.totalMax)) * 100).toFixed(1)
                            if (isNaN(criteria_score)) {
                                criteria_score = 0
                            }
                        }
                        
                        var criteriaAbsoluteScore = ((Math.round(criteria_score) * maxScore) / 100);
                        if (isNaN(criteriaAbsoluteScore)) {
                            criteriaAbsoluteScore = 0;
                        }


                        return <div className={classnames(classes.criteria, crespondable ? 'options-show' : 'options-hide', this.props.expandedCriteriaId === criteria.id ? classes.activeCriteria : {})} key={k}>
                            <div className={classnames(classes.criteriaHead, this.props.expandedCriteriaId === criteria.id ? 'highLight' : '')}>
                                {this.props.enableScoring && <div className={classes.overAllDivCriteriaHead}>
                                    <span className={classes.criteriaTitle}>{criteria.name}</span>
                                    <span className={classes.overAllPer}>{criteriaAbsoluteScore + '/' + maxScore}</span>
                                    <span className={classes.overAllPer}> {criteria_score}%</span>
                                </div>}
                                {!this.props.enableScoring && <div className={classes.overAllDivCriteriaHead}>
                                    <span className={classes.criteriaTitle}>{criteria.name}</span>
                                </div>}
                            </div>
                            {<div className={classes.productResponseSection}>
                                {
                                    criteria.requirements !== null && criteria.requirements.map((requirement, j) => {
                                        var rrespondable = true;
                                        if (requirement.hide) {
                                            return null;
                                        }
                                        return (
                                            <Response
                                                key={j}
                                                reqIndex={j}
                                                product={product}
                                                index={j}
                                                respondable={rrespondable}
                                                requirement={requirement}
                                                criteria={criteria}
                                                scoring_mechanism={this.props.scoring_mechanism}
                                                isPDfReport={this.props.isPDfReport}
                                                productScoreMap={this.props.productScoreMap}
                                                isHideAskSeller={this.props.isHideAskSeller}
                                                isHideCommunityResponse={this.props.isHideCommunityResponse}
                                                enableScoring={this.props.enableScoring}
                                            />
                                        )
                                    })
                                }
                            </div>}
                        </div>
                    })
                }
            </div>
        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Product));