import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CommonCss from 'commonCss';
import { CircularProgress, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ComponentLoader from "./ComponentLoader";
import SqureButton from 'Components/Common/SqureButton';

import { workflowComponents } from "redux/master/action";
import { getOrgWorkflowComponent } from "redux/evaluation/workflow/action";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import { getWorkflowSummaryItems } from "util/Common";
import { setSummaryType } from "redux/workflow/action";
import BottomActions from "./BottomActions";
import { updateEvaluationInfo } from "redux/evaluation/metadata/action";
import EvaluationBar from "../../../Common/EvaluationBar";
import { addWorkflowComponentUserResponse } from "redux/workflow/action";
import Image from 'Components/Common/image';
import ReviewsEmptyState from "assets/images/reviews_empty_state.svg";
import { requestDetails } from "redux/evaluation/workflow/action";
import { CommonFn } from "services/commonFn";

import { fetchUserWorkflowTimeline } from "redux/workflow/action";

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },

}))(MuiAccordionDetails);

const connectedProps = (state) => ({
  user: state.authUser.user,
  evaluation: state.evaluationMetaData.evaluation,
  userTimeline: state.workflow.userTimeline,
  orgWorkFlowCompList: state.evaluationWorkflow.orgWorkFlowCompList,
  orgWorkFlowCompError: state.evaluationWorkflow.orgWorkFlowCompError,
  orgWorkFlowCompProgress: state.evaluationWorkflow.orgWorkFlowCompProgress,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
  fetchWokflowUserTimelineProgress: state.workflow.fetchWokflowUserTimelineProgress,
});

const connectionActions = {
  workflowComponents: workflowComponents,
  getOrgWorkflowComponent: getOrgWorkflowComponent,
  setSummaryType: setSummaryType,
  requestDetailsData: requestDetails,
  updateEvaluationInfo: updateEvaluationInfo,
  addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
  fetchUserWorkflowTimeline:fetchUserWorkflowTimeline
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  accordian: {
    // "& [class*=MuiAccordionDetails-root]": {
    //   display: 'block'
    // }
  },
  details: {
    display: 'block'
  },
  title: {
    marginBottom: 5,
    fontSize: 19
  },
  accordionSummary: {
    background: '#f7f7f7',
    borderRadius: 4,
    color: '#222',
    minHeight: 50,
    border: '1px solid #222'
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  heading: {
    fontSize: 14,
    fontWeight: 500,
    flex: 1
  },
  heading1: {
    fontSize: 14,
    fontWeight: 500,
    color: '#c7c0c0'
  },
  summaryForms: {
    margin: '0 auto',
    // background:'#fff',
    minHeight: 'max-content',
    marginBottom: 100
  },
  DashboardNoDataLayout: {
    margin: '22px 0px 0px 0px',
    minHeight: 450,
    textAlign: 'center',
    // background: '#ffffff',
    padding: '23px',
    borderRadius: '6px',
    '& button': {
      margin: '20px 0',
      color: '#fff',
      background: '#3C3C3C',
      '&:hover': {
        color: '#FFF',
        background: '#3C3C3C',
      }
    }
  },
});

class WorkflowSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      isLoading: true,
      summaryItems: []
    }
    this.handleChange = this.handleChange.bind(this);
    this.createAccordianData = this.createAccordianData.bind(this)
    this.callBack = this.callBack.bind(this);
    this.submitResponse = this.submitResponse.bind(this);
  }

  componentDidMount() {
    this.props.getOrgWorkflowComponent(this.props.evaluation?.WorkflowID);
    this.props.requestDetailsData(this.props.evaluation?.ID);
    this.props.fetchUserWorkflowTimeline(this.props.evaluation?.ID, null, this.props.match.params.component_id);

    // this.props.workflowComponents();

    // console.log(this.props.userTimeline, 'userTimeline')
  }

  componentDidUpdate(prevProps) {
    if (this.props.orgWorkFlowCompProgress === false && prevProps.orgWorkFlowCompProgress === true) {
      if (this.props.orgWorkFlowCompError === null) {
        // this.createAccordianData();
      }
    }

    if (this.props.fetchWokflowUserTimelineProgress === false && prevProps.fetchWokflowUserTimelineProgress === true) {
      this.createAccordianData();
    }



  }

  getComponentData(componentId) {
    const { orgWorkFlowCompList } = this.props;
    let data = null;
    let comIndx = orgWorkFlowCompList.findIndex(o => o.ID === componentId);
    if (comIndx !== undefined && comIndx !== -1) {
      let component = orgWorkFlowCompList[comIndx];
      data = {
        slug: component?.MasterComponent?.Slug,
        details: component
      }
    }
    return data;
  }

  createAccordianData() {
    const { userTimeline } = this.props;
    let summaryItems = [];
    summaryItems.push({
      ComponentType: "REQUEST_DETAILS",
      Label: 'Project Details',
      Name: 'Project Details',
      slug: "REQUEST_DETAILS",
      EvaluationId: this.props.match.params.evaluation_id,
      ComponentId: this.props.match.params.evaluation_id + '_RequestStatus'
    })

    if (userTimeline !== undefined && userTimeline !== null && userTimeline !== '') {
      let approvedProduct = null;
      try{
        approvedProduct = this.props.evaluation.Products.find((product) => product.IsApproved);
      }catch(e){}
      let isProductApproved = approvedProduct ? true : false;
      let userTimelineItems = getWorkflowSummaryItems([...userTimeline], [], this.props.evaluation?.ID, this.props.evaluation?.Name, true, isProductApproved)
      if (userTimelineItems && userTimelineItems.length > 0) {
        userTimelineItems.forEach(function (itm) {
          summaryItems.push(itm);
        })
      }
    }

    this.setState({
      summaryItems: summaryItems,
      isLoading: false,
      expanded: summaryItems.length > 0 ? summaryItems[0].ComponentId : false
    })
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false
    });
  };



  callBack() {
    this.props.updateEvaluationInfo(this.props.evaluation?.ID, {
      approvalStarted: true
    })
    this.props.hideMessageDialog();
  }

  submitResponse() {
    var responseDatas = [];
    this.props.workflowComponentsForms.forEach((data) => {
      if (data.IsConfiguration === false) {
        var obj = {
          "form_id": data.ID
        }
        var value = '';
        if (data.Name === 'evaluation.name') {
          value = this.state.name;
        }
        obj['value'] = value;
        responseDatas.push(obj);
      }
    });

    this.props.addWorkflowComponentUserResponse(this.props.match.params.evaluation_id, this.props.workflowComponentId, {
      "data": responseDatas,
      "forward": true
    });
    // this.props.showFeedbackTakeDialog();
  }

  render() {
    const { classes } = this.props;
    const { expanded, isLoading, summaryItems } = this.state;
    if (isLoading) {
      return <div style={{ textAlign: 'center', marginTop: 30 }}>
        <CircularProgress />
      </div>
    }

    // let messageData = {
    //   head: "Are you sure?",
    //   subHead: "You are about to submit the request for approval. You will not be able to edit your request.",
    //   firstBtn: "Cancel",
    //   secondBtn: "Yes",
    // };

    return (
      <div>
        <EvaluationBar
          isShowCollabrations={false}
          isShowEvalDetails={false}
          isWorkFlow={this.props.isWorkFlow}
          isShowViewBtns={false}
        />
        <div className={classes.rootContainer}>
          <div className={classes.summaryForms}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <Typography variant="h5" className={classes.title}>Project Summary</Typography>
              </div>
              {false && <div style={{ textAlign: 'right', flex: 1 }}>
                <SqureButton
                  cls={classes.actionBtn}
                  variant={"outlined"}
                  onClick={() => this.props.setSummaryType('EXECUTIVE_SUMMARY')}>
                  Executive Summary
                </SqureButton>
              </div>}
            </div>


            {summaryItems && summaryItems.length > 0 && summaryItems.map((itm) => {
              let comId = itm.ComponentId
              return <Accordion square expanded={expanded === comId} onChange={this.handleChange(comId)} className={classes.accordian}>
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={"panel1bh-content"}
                  id="panel1bh-header"
                >
                  {itm.slug !== 'REQUEST_DETAILS' && <div className={classes.head}>
                    <Typography className={classes.heading}>{itm?.Name}</Typography>
                    {itm.End && <Typography className={classes.heading1}>Completed on {CommonFn.formateDate(itm?.End,true)} at {CommonFn.formatTime(itm?.End,true)} </Typography>}
                  </div>}

                  {itm.slug === 'REQUEST_DETAILS' && <Typography className={classes.heading}>{itm?.Name}</Typography>}

                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  {expanded === comId && <ComponentLoader
                    title={itm?.Label}
                    componentId={itm?.ComponentId}
                    evaluation_id={itm?.EvaluationId}
                    status={itm?.Status}
                    type={itm?.Type}
                    workflow_id={itm?.WorkflowId}
                    slug={itm?.slug}
                    component={itm?.componentDetails}
                    isWorkFlow={true}
                  />}
                </AccordionDetails>
              </Accordion>
            })}

            {(summaryItems === undefined || summaryItems === null || summaryItems.length <= 0) && <div style={{ textAlign: 'center' }}>
              <div className={classes.DashboardNoDataLayout}>
                <Image src={ReviewsEmptyState} />
                <h2>No Summay Items Found</h2>
              </div>
            </div>
            }

          </div>
        </div>
        <BottomActions
          nextClickLoader={this.props.addComponentUserResponseProgress}
          submitResponse={() => {
            this.submitResponse()
          }}
        />
        {/* <MessageDialog messageData={messageData} callBack={this.callBack} /> */}
      </div>
    )
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(WorkflowSummary));
