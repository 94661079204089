import { Grid } from "@material-ui/core";
import { createStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { showEvaluationSelectDialog } from "redux/templates/evaluation/action";
import Integration from "assets/images/integration.svg";
import Templates from "assets/images/templates.svg";
import User from "assets/images/user.svg";
import { showAddCompanyProfileDialog } from "redux/companyProfileSettings/action";
import CardAction from "Components/Common/CardAction";
import AppBarV2 from "../../../Application/AppBarV2";
import classnames from "classnames";
import CommonCss from 'commonCss';

const connectedProps = (state) => ({
  authUser: state.authUser,
  drawer_expanded: state.drawer.expanded,
  userRole: state.authUser.user?.Role,
});

const connectionActions = {
  showAddCompanyProfileDialog: showAddCompanyProfileDialog,
  showEvaluationSelectDialog: showEvaluationSelectDialog,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  "actionContainer": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),

    "& [class*='MuiGrid-item']": {
      padding: theme.spacing(2)
    }
  },

  "cardAction": {
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    height: '100%'
  },

  "Primary": {
    textAlign: 'center',
    fontSize: theme.spacing(2.4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },

  "Secondary": {
    textAlign: 'center',
  },

  "Content": {
    padding: theme.spacing(2, 2, 0, 2),
    flex: 'inherit'
  },
  rootContainer: CommonCss.rootContainer
});

class SettingsCard extends React.Component {

  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      error: '',
      sm1: window.matchMedia('(min-width: 576px) and (max-width: 667.98px)').matches,
      sm2: window.matchMedia('(min-width: 668px) and (max-width: 767.98px)').matches
    }
  }

  updateDimensions = () => {
    let sm1 = window.matchMedia('(min-width: 576px) and (max-width: 667.98px)').matches;
    let sm2 = window.matchMedia('(min-width: 668px) and (max-width: 767.98px)').matches;
    this.setState({
      sm1: sm1,
      sm2: sm2
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const classes = this.props.classes;
    const cardContent = [
      // { primary: "Products", secondary: "Manage your vendors", image: Products,  activeImage: true, link: '/app/settings/products' },
      { primary: "Users & Teams", secondary: "Manage your Users and Teams", image: User, activeImage: false, link: '/sellerapp/settings/users' },
      { primary: "Library", secondary: "Manage your Integrations", image: Integration, activeImage: false, link: '/sellerapp/settings/library' },
    ];
    const { sm1, sm2 } = this.state;
    const sm = sm1 ? 12 : sm2 ? 6 : 4;

    return <div className={'PageSettings'}>
      <AppBarV2 title="Settings" />
      <div className={classnames(classes.actionContainer, classes.rootContainer)}>
        <Grid container spacing={3}>
          {cardContent.map((e, index) => {
            if (e.isHide) {
              return null
            }
            return (
              <Grid item xs={12} sm={sm} md={3} lg={3} xl={3} key={index}>
                <CardAction
                  link={e.link}
                  image={e.image}
                  primary={e.primary}
                  secondary={e.secondary}
                  activeImage={e.activeImage}
                  cardActionClass={classes.cardAction}
                  primaryClass={classes.Primary}
                  secondaryClass={classes.Secondary}
                  contentClass={classes.Content}
                />
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  }
}

export default connector(compose(
  withStyles(styles)
)(SettingsCard));