import { HttpFactory } from "services/httpService";
import * as Environment from "util/Environment";

export const START_VENDOR_FETCH = "START_VENDOR_FETCH";
export const END_VENDOR_FETCH = "END_VENDOR_FETCH";

export const START_VENDOR_DETAILS_FETCH = "START_VENDOR_DETAILS_FETCH";
export const END_VENDOR_DETAILS_FETCH = "END_VENDOR_DETAILS_FETCH";

export const START_SERVICE_DETAILS_FETCH = "START_SERVICE_DETAILS_FETCH";
export const END_SERVICE_DETAILS_FETCH = "END_SERVICE_DETAILS_FETCH";

export const START_VENDOR_PRODUCT_FETCH = "START_VENDOR_PRODUCT_FETCH";
export const END_VENDOR_PRODUCT_FETCH = "END_VENDOR_PRODUCT_FETCH";

export const START_CATALOG_STATE_UPDATE = "START_CATALOG_STATE_UPDATE";
export const END_CATALOG_STATE_UPDATE = "END_CATALOG_STATE_UPDATE";

export const START_VENDOR_CATALOG_VIEWS_FETCH = "START_VENDOR_CATALOG_VIEWS_FETCH";
export const END_VENDOR_CATALOG_VIEWS_FETCH = "END_VENDOR_CATALOG_VIEWS_FETCH";

export const START_SERVICE_CATALOG_VIEWS_FETCH = "START_SERVICE_CATALOG_VIEWS_FETCH";
export const END_SERVICE_CATALOG_VIEWS_FETCH = "END_SERVICE_CATALOG_VIEWS_FETCH";

export const START_CATALOG_VIEWS_ADD = "START_CATALOG_VIEWS_ADD";
export const END_CATALOG_VIEWS_ADD = "END_CATALOG_VIEWS_ADD";

export const START_CATALOG_VIEWS_UPDATE = "START_CATALOG_VIEWS_UPDATE";
export const END_CATALOG_VIEWS_UPDATE = "END_CATALOG_VIEWS_UPDATE";

export const START_CATALOG_VIEWS_DELETE = "START_CATALOG_VIEWS_DELETE";
export const END_CATALOG_VIEWS_DELETE = "END_CATALOG_VIEWS_DELETE";

export const SHOW_CATALOG_FIELDS_DIALOG = "SHOW_CATALOG_FIELDS_DIALOG";
export const HIDE_CATALOG_FIELDS_DIALOG = "HIDE_CATALOG_FIELDS_DIALOG";

export const SHOW_CLONE_CATALOG_SECTION_DIALOG = "SHOW_CLONE_CATALOG_SECTION_DIALOG";
export const HIDE_CLONE_CATALOG_SECTION_DIALOG = "HIDE_CLONE_CATALOG_SECTION_DIALOG";

export const SHOW_ADD_UPDATE_TASK_DIALOG = "SHOW_ADD_UPDATE_TASK_DIALOG";
export const HIDE_ADD_UPDATE_TASK_DIALOG = "HIDE_ADD_UPDATE_TASK_DIALOG";

export const START_EXT_COLLABORATOR_VENDOR_FETCH = "START_EXT_COLLABORATOR_VENDOR_FETCH";
export const END_EXT_COLLABORATOR_VENDOR_FETCH = "END_EXT_COLLABORATOR_VENDOR_FETCH";

export const START_VENDOR_EXT_COLLABORATOR_ADD = "START_VENDOR_EXT_COLLABORATOR_ADD";
export const END_VENDOR_EXT_COLLABORATOR_ADD = "END_VENDOR_EXT_COLLABORATOR_ADD";

export const START_VENDOR_EXT_COLLABORATOR_REMOVE = "START_VENDOR_EXT_COLLABORATOR_REMOVE";
export const END_VENDOR_EXT_COLLABORATOR_REMOVE = "END_VENDOR_EXT_COLLABORATOR_REMOVE";
export const SHOW_VENDOR_PRODUCTS_SERVICES_DIALOG = "SHOW_VENDOR_PRODUCTS_SERVICES_DIALOG";
export const HIDE_VENDOR_PRODUCTS_SERVICES_DIALOG = "HIDE_VENDOR_PRODUCTS_SERVICES_DIALOG";

export const SHOW_ADD_TO_EXISTING_DIALOG = "SHOW_ADD_TO_EXISTING_DIALOG";
export const HIDE_ADD_TO_EXISTING_DIALOG = "HIDE_ADD_TO_EXISTING_DIALOG";

export const START_FETCH_CONTRACT_FOLDERS = 'START_FETCH_CONTRACT_FOLDERS';
export const END_FETCH_CONTRACT_FOLDERS = 'END_FETCH_CONTRACT_FOLDERS';

export const START_UPDATE_CONTRACT_FOLDERS = 'START_UPDATE_CONTRACT_FOLDERS';
export const END_UPDATE_CONTRACT_FOLDERS = 'END_UPDATE_CONTRACT_FOLDERS';

export function showAddToExistingDialog() {
  return {
    type: SHOW_ADD_TO_EXISTING_DIALOG,
    payload: {},
  };
}

export function hideAddToExistingDialog() {
  return {
    type: HIDE_ADD_TO_EXISTING_DIALOG,
    payload: {},
  };
}

export function showCatalogfieldsDialog() {
  return {
    type: SHOW_CATALOG_FIELDS_DIALOG,
    payload: {},
  };
}

export function hideCatalogfieldsDialog() {
  return {
    type: HIDE_CATALOG_FIELDS_DIALOG,
    payload: {},
  };
}

export function showVendorProductsAndServicesDialog(vendor) {
  return {
    type: SHOW_VENDOR_PRODUCTS_SERVICES_DIALOG,
    payload: {vendor},
  };
}

export function hideVendorProductsAndServicesDialog() {
  return {
    type: HIDE_VENDOR_PRODUCTS_SERVICES_DIALOG,
    payload: {},
  };
}

export function showCloneCatalogSectionDialog() {
  return {
    type: SHOW_CLONE_CATALOG_SECTION_DIALOG,
    payload: {},
  };
}

export function hideCloneCatalogSectionDialog() {
  return {
    type: HIDE_CLONE_CATALOG_SECTION_DIALOG,
    payload: {},
  };
}

export  function showAddUpdateTaskDialog(data={},isNew=true){
  return     {
      type:SHOW_ADD_UPDATE_TASK_DIALOG,
      payload:{data,isNew}
  };
}

export function hideAddUpdateTaskDialog() {
  return {
    type: HIDE_ADD_UPDATE_TASK_DIALOG,
    payload: {},
  };
}



function startFetchContractFolders() {
  return {
      type: START_FETCH_CONTRACT_FOLDERS,
      payload: null
  };
}

function endFetchContractFolders(success, error) {
  return {
      type: END_FETCH_CONTRACT_FOLDERS,
      payload: {
          success, error
      }
  };
}

export function fetchContractFolders(id,type, item_slug) {
  const api_server = Environment.api_host("PRODUCT");
  const timestamp = (new Date()).getTime();
  let url = `${api_server}/${type}/${id}/item/${item_slug}/folder?t=${timestamp}`
  return async (dispatch) => {
      dispatch(startFetchContractFolders(id));
      try {
          let [response, error] = await HttpFactory.instance().getMethod(url);
          dispatch(endFetchContractFolders(response, error));
      } catch (e) {
          dispatch(endFetchContractFolders(null, {
              message: e.message
          }));
          return;
      }
  };
}



function startUpdateContractFolders() {
  return {
      type: START_UPDATE_CONTRACT_FOLDERS,
      payload: null
  };
}

function endUpdateContractFolders(success, error) {
  return {
      type: END_UPDATE_CONTRACT_FOLDERS,
      payload: {
          success, error
      }
  };
}

export function updateContractFolders(id,type, item_slug, data) {
  const api_server = Environment.api_host("PRODUCT");
  const timestamp = (new Date()).getTime();
  let url = `${api_server}/${type}/${id}/item/${item_slug}/folder?t=${timestamp}`
  return async (dispatch) => {
      dispatch(startUpdateContractFolders(id));
      try {
          let [response, error] = await HttpFactory.instance().putMethod(url, data);
          dispatch(endUpdateContractFolders(response, error));
      } catch (e) {
          dispatch(endUpdateContractFolders(null, null, {
              message: e.message
          }));
          return;
      }
  };
}

function startVendorCatalogViewsFetch() {
  return {
    type: START_VENDOR_CATALOG_VIEWS_FETCH,
    payload: {},
  };
}

function endVendorCatalogViewsFetch(success, error) {
  return {
    type: END_VENDOR_CATALOG_VIEWS_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchVendorCatalogViews() {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/view/vendor?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startVendorCatalogViewsFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, false);
      dispatch(endVendorCatalogViewsFetch(response, error));
    } catch (e) {
      dispatch(
        endVendorCatalogViewsFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startServiceCatalogViewsFetch() {
  return {
    type: START_SERVICE_CATALOG_VIEWS_FETCH,
    payload: {},
  };
}

function endServiceCatalogViewsFetch(success, error) {
  return {
    type: END_SERVICE_CATALOG_VIEWS_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchServiceCatalogViews() {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/view/vendorservice?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startServiceCatalogViewsFetch());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, false);
      dispatch(endServiceCatalogViewsFetch(response, error));
    } catch (e) {
      dispatch(
        endServiceCatalogViewsFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startCatalogViewsAdd() {
  return {
    type: START_CATALOG_VIEWS_ADD,
    payload: {},
  };
}

function endCatalogViewsAdd(success, error) {
  return {
    type: END_CATALOG_VIEWS_ADD,
    payload: {
      success,
      error,
    },
  };
}

export function addCatalogViews(data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/view?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startCatalogViewsAdd());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, true);
      dispatch(endCatalogViewsAdd(response, error));
    } catch (e) {
      dispatch(
        endCatalogViewsAdd(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startCatalogViewsUpdate() {
  return {
    type: START_CATALOG_VIEWS_UPDATE,
    payload: {},
  };
}

function endCatalogViewsUpdate(success, error) {
  return {
    type: END_CATALOG_VIEWS_UPDATE,
    payload: {
      success,
      error,
    },
  };
}

export function updateCatalogViews(id,data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  let url = `${api_server}/view/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startCatalogViewsUpdate());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url,data, true);
      dispatch(endCatalogViewsUpdate(response, error));
    } catch (e) {
      dispatch(
        endCatalogViewsUpdate(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}


function startCatalogViewsDelete() {
  return {
    type: START_CATALOG_VIEWS_DELETE,
    payload: null,
  };
}

function endCatalogViewsDelete(success, error) {
  return {
    type: END_CATALOG_VIEWS_DELETE,
    payload: {
      success,
      error,
    },
  };
}

export function deleteCatalogViews(id) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/view/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startCatalogViewsDelete());
    try {
      let [response, error] = await HttpFactory.instance().deleteMethod(url);
      dispatch(endCatalogViewsDelete(response, error));
    } catch (e) {
      dispatch(
        endCatalogViewsDelete(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}


function startCatalogStateUpdate() {
  return {
    type: START_CATALOG_STATE_UPDATE,
    payload: {},
  };
}

function endCatalogStateUpdate(success, error) {
  return {
    type: END_CATALOG_STATE_UPDATE,
    payload: {
      success,
      error,
    },
  };
}

export function updateCatalogState(data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendor?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startCatalogStateUpdate());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
      dispatch(endCatalogStateUpdate(response, error));
    } catch (e) {
      dispatch(
        endCatalogStateUpdate(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startVendorProductsFetch() {
  return {
    type: START_VENDOR_PRODUCT_FETCH,
    payload: {},
  };
}

function endVendorProductsFetch(success, error) {
  return {
    type: END_VENDOR_PRODUCT_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchVendorProducts(searchQuery) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/vendor/products?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startVendorProductsFetch());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, searchQuery, true);
      dispatch(endVendorProductsFetch(response, error));
    } catch (e) {
      dispatch(
        endVendorProductsFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startVendorFetch(searchQuery) {
  return {
    type: START_VENDOR_FETCH,
    payload: {
      searchQuery,
    },
  };
}

function endVendorFetch(searchQuery, success, error) {
  return {
    type: END_VENDOR_FETCH,
    payload: {
      searchQuery,
      success,
      error,
    },
  };
}

export function fetchVendors(data) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  const url = `${api_server}/entity/vendor/search?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startVendorFetch(data));
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, data, false);
      dispatch(endVendorFetch(data, response, error));
    } catch (e) {
      dispatch(
        endVendorFetch(data, null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchVendorById() {
  return {
    type: START_VENDOR_DETAILS_FETCH,
    payload: {},
  };
}

function endFetchVendorById(success, error) {
  return {
    type: END_VENDOR_DETAILS_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchVendorById(id) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  var url = `${api_server}/entity/vendor/master/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchVendorById());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endFetchVendorById(response, error));
    } catch (e) {
      dispatch(
        endFetchVendorById(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchServiceById() {
  return {
    type: START_SERVICE_DETAILS_FETCH,
    payload: {},
  };
}

function endFetchServiceById(success, error) {
  return {
    type: END_SERVICE_DETAILS_FETCH,
    payload: {
      success,
      error,
    },
  };
}

export function fetchServiceById(vendorId, serviceId) {
  const api_server = Environment.api_host("CATALOG");
  const timestamp = new Date().getTime();
  var url = `${api_server}/entity/${vendorId}/services/${serviceId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchServiceById());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endFetchServiceById(response, error));
    } catch (e) {
      dispatch(
        endFetchServiceById(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

//---------------EXTERNAL USER PERMISSIONS----------------------
function startExternalCollaboratorProductFetch(){
    return {
        type:START_EXT_COLLABORATOR_VENDOR_FETCH,
        payload:{}
    };
}

function endExternalCollaboratorProductFetch(success,error){
    return {
        type:END_EXT_COLLABORATOR_VENDOR_FETCH,
        payload:{
            success,error
        }
    };
}

export  function fetchExternalCollaboratorVendors(){
    const api_server = Environment.api_host("CATALOG");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/ext/products?page=0&pageSize=500&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startExternalCollaboratorProductFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endExternalCollaboratorProductFetch(response,error));
        } catch(e){
            dispatch(endExternalCollaboratorProductFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 


//-------------------------------------
function startVendorExternalCollaboratorAdd(){
    return {
        type:START_VENDOR_EXT_COLLABORATOR_ADD,
        payload:{}
    };
}

function endVendorExternalCollaboratorAdd(success,error){
    return {
        type:END_VENDOR_EXT_COLLABORATOR_ADD,
        payload:{
            success,error
        }
    };
}


export  function addVendorExternalCollaborator(data){
    const api_server = Environment.api_host("CATALOG");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/permission/ext/user?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startVendorExternalCollaboratorAdd());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endVendorExternalCollaboratorAdd(response,error));
        } catch(e){
            dispatch(endVendorExternalCollaboratorAdd(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------




//-------------------------------------
function startVendorExternalCollaboratorRemove(){
    return {
        type:START_VENDOR_EXT_COLLABORATOR_REMOVE,
        payload:{}
    };
}

function endVendorExternalCollaboratorRemove(success,error,userId){
    return {
        type:END_VENDOR_EXT_COLLABORATOR_REMOVE,
        payload:{
            success,error,userId
        }
    };
}

export  function removeVendorExternalCollaborator(userId,productId,sectionId){
    const api_server = Environment.api_host("CATALOG");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/ext/${userId}/product/${productId}/section/${sectionId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startVendorExternalCollaboratorRemove());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
            dispatch(endVendorExternalCollaboratorRemove(response,error,userId));
        } catch(e){
            dispatch(endVendorExternalCollaboratorRemove(null,{
                message:e.message
            }));
            return;
        }
    };
} 

//------------------------------------------

