import {
    SHOW_ADD_INVENTORY_DIALOG, HIDE_ADD_INVENTORY_DIALOG,
} from "./action";

const initState = {
    isNewInventory: true,
    isShowInventoryDialog: false,
    inventoryData:null
}

export function hardwareProductReducer(state = initState, action) {
    switch (action.type) {
        case SHOW_ADD_INVENTORY_DIALOG:
            return {
                ...state,
                isShowInventoryDialog: true,
                isNewInventory: action.payload.isNew,
                inventoryData: action.payload.inventoryData
            };

        case HIDE_ADD_INVENTORY_DIALOG:
            return {
                ...state,
                isNewInventory: true,
                inventoryData: {},
                isShowInventoryDialog: false,
            };

        default:
            return state;
    }
}