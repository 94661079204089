import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const START_DELETE_SELLER_ASSESSMENT = "START_DELETE_SELLER_ASSESSMENT";
export const END_DELETE_SELLER_ASSESSMENT = "END_DELETE_SELLER_ASSESSMENT";

export const START_FETCH_SELLER_ASSESSMENTS = "START_FETCH_SELLER_ASSESSMENTS";
export const END_FETCH_SELLER_ASSESSMENTS = "END_FETCH_SELLER_ASSESSMENTS";

export const START_FETCH_SELLER_ASSESSMENT_DETAILS = "START_FETCH_SELLER_ASSESSMENT_DETAILS";
export const END_FETCH_SELLER_ASSESSMENT_DETAILS = "END_FETCH_SELLER_ASSESSMENT_DETAILS";

export const SHOW_IMPORT_ASSESSMENT_DIALOG = "SHOW_IMPORT_ASSESSMENT_DIALOG";
export const HIDE_IMPORT_ASSESSMENT_DIALOG = "HIDE_IMPORT_ASSESSMENT_DIALOG";
export const SET_IMPORT_ASSESSMENT_DATA = "SET_IMPORT_ASSESSMENT_DATA";

export const START_IMPORT_SELLER_ASSESSMENT = "START_IMPORT_SELLER_ASSESSMENT";
export const END_IMPORT_SELLER_ASSESSMENT = "END_IMPORT_SELLER_ASSESSMENT";

export const START_EXPORT_SELLER_ASSESSMENT = "START_EXPORT_SELLER_ASSESSMENT";
export const END_EXPORT_SELLER_ASSESSMENT = "END_EXPORT_SELLER_ASSESSMENT";

export const START_ADD_ASSESSMENT_QUESTION_RESPONSE = "START_ADD_ASSESSMENT_QUESTION_RESPONSE";
export const END_ADD_ASSESSMENT_QUESTION_RESPONSE = "END_ADD_ASSESSMENT_QUESTION_RESPONSE";

export const START_FETCH_SEARCH_SETTNGS = "START_FETCH_SEARCH_SETTNGS";
export const END_FETCH_SEARCH_SETTNGS = "END_FETCH_SEARCH_SETTNGS";

export const START_UPDATE_SEARCH_SETTNGS = "START_UPDATE_SEARCH_SETTNGS";
export const END_UPDATE_SEARCH_SETTNGS = "END_UPDATE_SEARCH_SETTNGS";

export function showImportAssessmentDialog() {
  return {
    type: SHOW_IMPORT_ASSESSMENT_DIALOG,
    payload: null,
  };
}

export function hideImportAssessmentDialog() {
  return {
    type: HIDE_IMPORT_ASSESSMENT_DIALOG,
    payload: null,
  };
}

export function setImportAssessmentData(data) {
  return {
    type: SET_IMPORT_ASSESSMENT_DATA,
    payload: { data },
  };
}

function startImportSellerAssessment(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_IMPORT_SELLER_ASSESSMENT,
    payload: payload,
  };
}

function endImportSellerAssessment(success, error) {
  return {
    type: END_IMPORT_SELLER_ASSESSMENT,
    payload: {
      success,
      error,
    },
  };
}

export function importSellerAssessment(entity, queryParam) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/import?entity=${entity}&t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startImportSellerAssessment());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, queryParam);
      dispatch(endImportSellerAssessment(response, error));
    } catch (e) {
      dispatch(
        endImportSellerAssessment(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startExportSellerAssessment(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_EXPORT_SELLER_ASSESSMENT,
    payload: payload,
  };
}

function endExportSellerAssessment(success, error) {
  return {
    type: END_EXPORT_SELLER_ASSESSMENT,
    payload: {
      success,
      error,
    },
  };
}

export function exportSellerAssessment(id) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/export/assessment/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startExportSellerAssessment());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endExportSellerAssessment(response, error));
    } catch (e) {
      dispatch(
        endExportSellerAssessment(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchSellerAssessments() {
  return {
    type: START_FETCH_SELLER_ASSESSMENTS,
    payload: {},
  };
}

function endFetchSellerAssessments(success, error) {
  return {
    type: END_FETCH_SELLER_ASSESSMENTS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchSellerAssessments(params) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/assessment?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchSellerAssessments());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, params, false);
      dispatch(endFetchSellerAssessments(response, error));
    } catch (e) {
      dispatch(
        endFetchSellerAssessments(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startDeleteSellerAssessment() {
  return {
    type: START_DELETE_SELLER_ASSESSMENT,
    payload: {},
  };
}

function endDeleteSellerAssessment(success, error) {
  return {
    type: END_DELETE_SELLER_ASSESSMENT,
    payload: {
      success,
      error,
    },
  };
}

export function deleteSellerAssessment(id) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/assessment/delete/${id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startDeleteSellerAssessment());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endDeleteSellerAssessment(response, error));
    } catch (e) {
      dispatch(
        endDeleteSellerAssessment(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchSellerAssessmentDetails() {
  return {
    type: START_FETCH_SELLER_ASSESSMENT_DETAILS,
    payload: {},
  };
}

function endFetchSellerAssessmentDetails(success, error) {
  return {
    type: END_FETCH_SELLER_ASSESSMENT_DETAILS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchSellerAssessmentDetails(params) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/library?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchSellerAssessmentDetails());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, params, false);
      dispatch(endFetchSellerAssessmentDetails(response, error));
    } catch (e) {
      dispatch(
        endFetchSellerAssessmentDetails(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startAddSellerAssessmentQuesionResponse(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_ADD_ASSESSMENT_QUESTION_RESPONSE,
    payload: payload,
  };
}

function endAddSellerAssessmentQuesionResponse(success, error) {
  return {
    type: END_ADD_ASSESSMENT_QUESTION_RESPONSE,
    payload: {
      success,
      error,
    },
  };
}

export function addSellerAssessmentQuesionResponse(queryParam) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/update_library?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAddSellerAssessmentQuesionResponse());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, queryParam, false);
      dispatch(endAddSellerAssessmentQuesionResponse(response, error));
    } catch (e) {
      dispatch(
        endAddSellerAssessmentQuesionResponse(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startFetchSearchSettings() {
  return {
    type: START_FETCH_SEARCH_SETTNGS,
    payload: {},
  };
}

function endFetchSearchSettings(success, error) {
  return {
    type: END_FETCH_SEARCH_SETTNGS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchSearchSettings(assessment_id) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/search_settings/${assessment_id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startFetchSearchSettings());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url, false);
      dispatch(endFetchSearchSettings(response, error));
    } catch (e) {
      dispatch(
        endFetchSearchSettings(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startUpdateSearchSettings(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_UPDATE_SEARCH_SETTNGS,
    payload: payload,
  };
}

function endUpdateSearchSettings(success, error) {
  return {
    type: END_UPDATE_SEARCH_SETTNGS,
    payload: {
      success,
      error,
    },
  };
}

export function updateSearchSettings(assessment_id, data) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/search_settings/${assessment_id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startUpdateSearchSettings());
    try {
      let [response, error] = await HttpFactory.instance().putMethod(url, data, false);
      dispatch(endUpdateSearchSettings(response, error));
    } catch (e) {
      dispatch(
        endUpdateSearchSettings(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
