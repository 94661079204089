import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import * as classnames from "classnames";
import { setSelectedRequirement } from "redux/evaluation/criteria/action"
import Workflow from "assets/images/workflow/workflow.svg";
import Image from 'Components/Common/image.jsx';

const connectedProps = (state) => ({
  selectedRequirementId: state.evaludationCriteria.selectedRequirementId,
  selectedProductId: state.evaludationCriteria.selectedProductId,
});

const connectionActions = {
  setSelectedRequirement: setSelectedRequirement,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  mainRootItem: {
    border: '1px solid #222',
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '4px',
  },
  subChild: {
    paddingLeft: '20px'
  },
  parentItem: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: 0
  },

  rootItemNoBefore: {
    padding: '10px'
  },
  redLegend : {
    color:'red',
    marginLeft: '10px',
    position:'relative',
    top: '2px',
  },
  greenLegend : {
    color: '#2EB77D',
    marginLeft: '10px',
    position:'relative',
    top: '4px',
  },
  whiteLegend : {
    color:'#fff',
    marginLeft: '10px',
    position:'relative',
    top: '4px',
  },
  reqTitle: {
    color: '#282D30',
    fontSize: theme.spacing(2.2)
  },
  antTree: {
    // padding: theme.spacing(2, 2),
    border: '1px solid #f1f1f1',
    borderRadius: theme.spacing(1.25),
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
    "&:before": {
      position: 'absolute',
      left: 0,
      top: 0,
      height: theme.spacing(7),
      display: 'block',
      width: theme.spacing(0.4),
      content: '""',
    }
  },
  reqBox: {
    marginBottom: theme.spacing(2)
  },
  arrowIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 999
  },
  rootItem: {
    position: 'relative',
    padding: '10px',
    "&:before": {
      position: 'absolute',
      left: '15px',
      top: '40px',
      display: 'block',
      width: '3px',
      bottom: '30px',
      borderRight: '1px solid hsl(206deg 5% 74%)',
      content: '""',
    }
  },
  childItem: {
    position: 'relative',
    margin: 0,
    padding: theme.spacing(0.2, 1),
    cursor: 'pointer',
    display: 'flex',
    gap: '5px',
    '&:after': {
      position: 'absolute',
      left: '-23px',
      top: '14px',
      display: 'block',
      width: '21px',
      height: '1px',
      /* bottom: 25px, */
      background: 'hsl(206deg 5% 74%)',
      content: '""',
    }
  },
  selectedItem: {
    backgroundColor: '#398AF5',
    border: '1px solid #398AF5',
    color: '#fff',
    padding: theme.spacing(0.2, 2),
    borderRadius: theme.spacing(1.25),
  },
  activeTree: {
    // background: '#f7f7f7',
    backgroundColor: '#398AF5',
    border: '1px solid #398AF5',
    color: '#fff',
    padding: theme.spacing(0.2, 2),
    borderRadius: theme.spacing(1.25),
  },
  reqTitleDiv: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  }
});

class WorkflowTreeNode extends React.Component {

  render() {
    const { selectedItem, parentComp, itemData, cls, componentId, workflowId, evaluationId, classes,redirectToPage,getNewUserTimeline,toggleCollapse,isEvaluationFinished, ...rest } = this.props;
    var childList = [];
    var selectedItem2 = this.props.selectedItem !== undefined ? this.props.selectedItem : '';
    var selectedClass = ''; 

     if ((this.props.itemData !== null && this.props.itemData?.Children !== undefined && this.props.itemData?.Children !== null && this.props.itemData?.Children.length > 0)) {
      this.props.itemData.Children.forEach(function (item, key) { 
        if (workflowId === item.WorkflowId && componentId === item.ComponentId && evaluationId === item.EvaluationId) {
          selectedClass = classes.selectedItem;
        } else {
          selectedClass = '';
        }

        childList.push(<WorkflowTreeNode
          {...rest}
          key={item.ID}
          itemData={item}
          selectedItem={selectedItem2}
          cls={classnames(classes.childItem, selectedClass)}
          classes={classes}
          componentId={componentId}
          workflowId={workflowId}
          evaluationId={evaluationId}
          redirectToPage={redirectToPage}
          getNewUserTimeline={getNewUserTimeline}
          isEvaluationFinished={isEvaluationFinished}
        ></WorkflowTreeNode>);
      });
    }

    return (
      <div className={classnames((this.props.itemData.Children && this.props.itemData.Children.length > 0) ? classes.rootItem : classes.rootItemNoBefore)}>  
        <p className={this.props.cls}  style={{fontWeight:itemData.Type === 2 ? '600' : 'normal' }} onClick={() => {
          if(parentComp){
            toggleCollapse(itemData.ComponentId);
            return;
          }
          if(itemData.Type === 1){
            if(isEvaluationFinished && this.props.itemData.ComponentType !== 'FINISH' && this.props.itemData.ComponentType !== 'WORKFLOW_SUMMARY'){
                return;              
            }
            redirectToPage(itemData);
          }else if(itemData.Type === 2){
            return ;
          }else if(itemData.Type === 3){
              getNewUserTimeline(itemData);
          }
        }}>
          {itemData.Type === 2 && <div className="img"><Image src={Workflow} alt="workflow" /></div>} 
          {this.props.itemData.Label}
        </p>
        <div className={classes.subChild}> 
          {childList}
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(WorkflowTreeNode));

// export default WorkflowTreeNode;
