import {
  START_FETCH_DATASETS,
  END_FETCH_DATASETS,
  START_FETCH_DASHBOARD,
  END_FETCH_DASHBOARD,
  START_GET_DASHBOARD_DETAILS,
  END_GET_DASHBOARD_DETAILS,
  START_ADD_DASHBOARD,
  END_ADD_DASHBOARD,
  START_UPDATE_DASHBOARD,
  END_UPDATE_DASHBOARD,
  START_DELETE_DASHBOARD,
  END_DELETE_DASHBOARD,
  START_FETCH_VISUALIZATION,
  END_FETCH_VISUALIZATION,
  START_GET_VISUALIZATION_DETAILS,
  END_GET_VISUALIZATION_DETAILS,
  START_GET_VISUALIZATION_CHART_DATA,
  END_GET_VISUALIZATION_CHART_DATA,
  START_GET_VISUALIZATION_TABLE_DATA,
  END_GET_VISUALIZATION_TABLE_DATA,
  START_ADD_VISUALIZATION,
  END_ADD_VISUALIZATION,
  START_UPDATE_VISUALIZATION,
  END_UPDATE_VISUALIZATION,
  START_DELETE_VISUALIZATION,
  END_DELETE_VISUALIZATION,
} from "./action";

const initState = {
  datasets: [],
  datasetFetchProgress: false,
  datasetFetchError: false,

  visualizationChartDataMap: {},
  fetchVisualizationChartDataProgress: false,
  fetchVisualizationChartDataError: false,

  dashboards: [],
  dashboardFetchProgress: false,
  dashboardFetchError: false,

  dashboardDetails: {},
  fetchDashboardDetailsProgress: false,
  fetchDashboardDetailsError: false,

  addDashboardProgress: false,
  addDashboardError: false,
  addDashboardSuccess: null,

  updateDashboardProgress: false,
  updateDashboardError: false,
  updateDashboardSuccess: null,

  deleteDashboardProgress: false,
  deleteDashboardError: false,

  visualizations: [],
  visualizationFetchProgress: false,
  visualizationFetchError: false,

  visualizationDetails: {},
  fetchVisualizationDetailsProgress: false,
  fetchVisualizationDetailsError: false,

  addVisualizationProgress: false,
  addVisualizationError: false,
  addVisualizationSuccess: false,

  updateVisualizationProgress: false,
  updateVisualizationError: false,
  updateVisualizationSuccess: false,

  deleteVisualizationProgress: false,
  deleteVisualizationError: false,
};

export function visualizationReducer(state = initState, action) {
  switch (action.type) {
    case START_FETCH_DASHBOARD:
      return {
        ...state,
        dashboardFetchProgress: true,
        dashboardFetchError: false,
      };

    case END_FETCH_DASHBOARD:
      return {
        ...state,
        dashboardFetchProgress: false,
        dashboardFetchError: action.payload.error,
        dashboards: action.payload.success,
      };
    case START_FETCH_DATASETS:
      return {
        ...state,
        datasetFetchProgress: true,
        datasetFetchError: false,
      };

    case END_FETCH_DATASETS:
      return {
        ...state,
        datasetFetchProgress: false,
        datasetFetchError: action.payload.error,
        datasets: action.payload.success || [],
      };
    case START_GET_DASHBOARD_DETAILS:
      return {
        ...state,
        dashboardDetails: {},
        fetchDashboardDetailsProgress: true,
        fetchDashboardDetailsError: false,
      };
    case END_GET_DASHBOARD_DETAILS:
      return {
        ...state,
        fetchDashboardDetailsProgress: false,
        fetchDashboardDetailsError: action.payload.error,
        dashboardDetails: action.payload.success,
      };
    case START_ADD_DASHBOARD:
      return {
        ...state,
        addDashboardProgress: true,
        addDashboardError: false,
        addDashboardSuccess: false,
      };

    case END_ADD_DASHBOARD:
      return {
        ...state,
        addDashboardProgress: false,
        addDashboardError: action.payload.error,
        addDashboardSuccess: action.payload.success,
      };
    case START_UPDATE_DASHBOARD:
      return {
        ...state,
        updateDashboardProgress: true,
        updateDashboardError: false,
        updateDashboardSuccess: false,
      };

    case END_UPDATE_DASHBOARD:
      return {
        ...state,
        updateDashboardProgress: false,
        updateDashboardError: action.payload.error,
        updateDashboardSuccess: action.payload.success,
      };
    case START_DELETE_DASHBOARD:
      return {
        ...state,
        deleteDashboardProgress: true,
        deleteDashboardError: false,
      };

    case END_DELETE_DASHBOARD:
      return {
        ...state,
        deleteDashboardProgress: false,
        deleteDashboardError: action.payload.error,
      };

    case START_FETCH_VISUALIZATION:
      return {
        ...state,
        visualizationFetchProgress: true,
        visualizationFetchError: false,
      };
    case END_FETCH_VISUALIZATION:
      return {
        ...state,
        visualizationFetchProgress: false,
        visualizationFetchError: action.payload.error,
        visualizations: action.payload.success,
      };
    case START_GET_VISUALIZATION_CHART_DATA:
      return {
        ...state,
        fetchVisualizationChartDataProgress: true,
        fetchVisualizationChartDataError: false,
      };
    case END_GET_VISUALIZATION_CHART_DATA:
      var visualizationChartDataMap = { ...state.visualizationChartDataMap };

      if (action.payload.success) {
        visualizationChartDataMap[action.payload.visualizationId] = { ...visualizationChartDataMap[action.payload.visualizationId], config: action.payload.success };
      }
      return {
        ...state,
        visualizationChartDataMap: visualizationChartDataMap,
        fetchVisualizationChartDataProgress: false,
        fetchVisualizationChartDataError: action.payload.error,
        visualizationDetails: action.payload.success,
      };
    case START_GET_VISUALIZATION_TABLE_DATA:
      return {
        ...state,
        fetchVisualizationChartDataProgress: true,
        fetchVisualizationChartDataError: false,
      };
    case END_GET_VISUALIZATION_TABLE_DATA:
      visualizationChartDataMap = { ...state.visualizationChartDataMap };
      if (action.payload.success) {
        visualizationChartDataMap[action.payload.visualizationId] = { ...visualizationChartDataMap[action.payload.visualizationId], data: action.payload.success?.data };
      } else {
        visualizationChartDataMap[action.payload.visualizationId] = { ...visualizationChartDataMap[action.payload.visualizationId], data: null };
      }
      return {
        ...state,
        visualizationChartDataMap: visualizationChartDataMap,
        fetchVisualizationChartDataProgress: false,
        fetchVisualizationChartDataError: action.payload.error,
      };
    case START_GET_VISUALIZATION_DETAILS:
      return {
        ...state,
        visualizationDetails: {},
        fetchVisualizationDetailsProgress: true,
        fetchVisualizationDetailsError: false,
      };
    case END_GET_VISUALIZATION_DETAILS:
      visualizationChartDataMap = { ...state.visualizationChartDataMap };
      if (action.payload.success) {
        visualizationChartDataMap[action.payload.visualizationId] = { ...visualizationChartDataMap[action.payload.visualizationId], data: action.payload.success?.data };
      } else {
        visualizationChartDataMap[action.payload.visualizationId] = { ...visualizationChartDataMap[action.payload.visualizationId], data: null };
      }
      return {
        ...state,
        visualizationChartDataMap: visualizationChartDataMap,
        fetchVisualizationDetailsProgress: false,
        fetchVisualizationDetailsError: action.payload.error,
      };
    case START_ADD_VISUALIZATION:
      return {
        ...state,
        addVisualizationProgress: true,
        addVisualizationError: false,
        addVisualizationSuccess: null,
      };

    case END_ADD_VISUALIZATION:
      return {
        ...state,
        addVisualizationProgress: false,
        addVisualizationError: action.payload.error,
        addVisualizationSuccess: action.payload.success,
      };
    case START_UPDATE_VISUALIZATION:
      return {
        ...state,
        updateVisualizationProgress: true,
        updateVisualizationError: false,
        updateVisualizationSuccess: null,
      };

    case END_UPDATE_VISUALIZATION:
      return {
        ...state,
        updateVisualizationProgress: false,
        updateVisualizationError: action.payload.error,
        updateVisualizationSuccess: action.payload.success,
      };
    case START_DELETE_VISUALIZATION:
      return {
        ...state,
        deleteVisualizationProgress: true,
        deleteVisualizationError: false,
      };

    case END_DELETE_VISUALIZATION:
      return {
        ...state,
        deleteVisualizationProgress: false,
        deleteVisualizationError: action.payload.error,
      };
    default:
      return state;
  }
}
