import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { CommonFn } from "services/commonFn";
import classnames from "classnames";
import {
  Grid, FormControl, 
  OutlinedInput, InputLabel, InputAdornment, Button, List,
  ListItem, ListItemIcon, Popover,
  Typography, Divider
} from "@material-ui/core"
// import classnames from "classnames";
import Image from 'Components/Common/image';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import DatePicker from "react-datepicker";
import CalendarImage from "assets/images/calendar.svg"
import moment from 'moment'
import DoneIcon from '@material-ui/icons/Done';

const connectedProps = (state) => ({
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  addCriteriaDatePicker: {
    marginTop: 15,
    background: '#fff',
    position: 'relative',
    "& .react-datepicker__input-container > div": {
      display: 'flex',
      alignItems: 'center',
      zIndex: 2000,
      "& [class*=MuiInputBase-input-]": {
        width: 115,
        height: 10,
      },
      "& [class*=MuiInputAdornment-root-]": {
        marginRight: 10,
      }
    },
    "[class*=MuiMenuItem-root]": {
      color: "#707070",
      fontSize: theme.spacing(1.8)
    },
  },
  inputlabel: {
    marginTop: -5,
    background: '#fff',
    padding: 5,
    borderRadius: 2
  },
  select: {
    // background: "#fff",
    width: "175px",
    textAlign: "left",
    marginTop: 8,
    marginBottom: 0,
    padding: 3,
    '& [class*=MuiSelect-root]': {
      background: '#fff'
    }
  },
  filterBtn: {
    background: '#fff',
    border: '1px solid #888',
    borderRadius: 4,
    minWidth: 200,
    color: '#222',
    boxShadow: 'none',
    padding: '4px 20px',
    fontSize: 16,
    '&:hover': {
      background: '#fff',
      color: '#222'
    }
  },
  filterRoot: {
    marginTop: 10,
    '& [class*=MuiListItem-root]': {
      minWidth: 200
    },
    "& .react-datepicker-popper": {
      zIndex: 2222
    }
  },
  listItem: {
    // paddingTop: theme.spacing(2),
    minWidth: 200
  },
  customRangeFilter: {
    padding: 20
  },
  date_popper:{
    zIndex: 2222
  },
  doneIcon:{
    marginRight:10,
    color:'#888'
  }
});

class DateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      duration: 15,
      isShowCustom: false,
      isAny: true,
      isCustomDates:false,
      profileAnchorEl: null,
      filterEl: false,
      customRangeEl: false
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(type) {

  }

  componentDidMount() {
    this.initValues()
  }

  initValues() {
    this.setState({
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      duration: this.props.duration,
    })
  }

  updateDuration(val) {
    let isShowCustom = false;
    let isAny = false;
    let isCustomDates = false;

    let customRangeEl = false;

    if (val === 100) {
      isShowCustom = true;
      customRangeEl = true;
      isCustomDates = true;
    }

    if (val === 0) {
      isAny = true
    }
    const { startDate, endDate } = this.state;

    let from = val === 100 ? moment(startDate).toISOString() : moment().toISOString();
    let to = val === 100 ? moment(endDate).toISOString() : moment().add(val, "days").toISOString();
    let _from = moment(from).format('YYYY-MM-DD');
    let _to = moment(to).format('YYYY-MM-DD');

    this.setState({
      duration: val,
      isShowCustom: isShowCustom,
      startDate: _from,
      endDate: _to,
      isAny: isAny,
      isCustomDates:isCustomDates,
      filterEl: false,
      customRangeEl: customRangeEl
    }, () => {
      if (val !== 100) {
        this.updateParentDate()
      }
    })
  }

  updateParentDate() {
    this.props.onChangeDates({
      startDate: new Date(this.state.startDate),
      endDate: new Date(this.state.endDate),
      isAny: this.state.isAny,
      isCustomDates:this.state.isCustomDates,
      duration:this.state.duration,
    });
  }

  applyCustomRange() {
    this.setState({
      customRangeEl : false
    },()=>{
      this.props.onChangeDates({
        startDate: new Date(this.state.startDate),
        endDate: new Date(this.state.endDate),
        duration:this.state.duration,
        isCustomDates:this.state.isCustomDates,
        isAny: this.state.isAny
      });
    })
  }

  updateStartDate(date) {
    this.setState({
      startDate: date,
      duration: 100
    }, () => {
      // this.updateParentDate()
    })
  }

  updateEndDate(date) {
    this.setState({
      endDate: date,
      duration: 100
    }, () => {
      // this.updateParentDate()
    })
  }

  render() {
    var classes = this.props.classes;
    const { startDate, endDate, duration } = this.state;

    let _from = startDate;
    let _to = endDate;

    // console.log(startDate, 'startDate')
    // console.log(endDate, 'endDate')

    return (
      <div className={classes.filterRoot}>
        <Button
          variant="contained"
          color="primary"
          className={classnames(classes.filterBtn,this.props.btnClass ? this.props.btnClass : '')}
          onClick={(event) => {
            this.setState({
              filterEl: true,
              profileAnchorEl: event.currentTarget
            }); 
          }}
        > 
          {duration === 0 && <>All</>}
          {duration === 15 && <> 15 Days</>}
          {duration === 30 && <> 30 Days</>}
          {duration === 60 && <> 60 Days</>}
          {duration === 90 && <> 90 Days</>}
          {duration === -1 && <> Past Dues</>}
          {duration === 100 && <> {moment(startDate).format('ll')} - {moment(endDate).format('ll')}</>}
        </Button>

        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          id="profileMenu"
          classes={{ paper: classes.profileMenu }}
          anchorEl={this.state.profileAnchorEl}
          keepMounted
          elevation={2}
          open={Boolean(this.state.filterEl)}
          onClose={() => {
            this.setState({ filterEl: false });
          }}
        >

          <List>
            <ListItem
              classes={{ button: classes.listItem, }}
              button
              onClick={() => {
                this.updateDuration(0)
              }}>
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {duration === 0 ? <DoneIcon /> : ''}
              </ListItemIcon>
              <Typography variant="inherit">All</Typography>
            </ListItem>
            <ListItem
              classes={{ button: classes.listItem, }}
              button
              onClick={() => {
                this.updateDuration(15);
              }}>
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {duration === 15 ? <DoneIcon /> : ''}
              </ListItemIcon>
              <Typography variant="inherit">15 Days</Typography>
            </ListItem>
            <ListItem
              classes={{ button: classes.listItem, }}
              button
              onClick={() => {
                this.updateDuration(30);
              }}>
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {duration === 30 ? <DoneIcon /> : ''}
              </ListItemIcon>
              <Typography variant="inherit">30 Days</Typography>
            </ListItem>
            <ListItem
              classes={{ button: classes.listItem, }}
              button
              onClick={() => {
                this.updateDuration(60);
              }}>
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {duration === 60 ? <DoneIcon /> : ''}
              </ListItemIcon>
              <Typography variant="inherit">60 Days</Typography>
            </ListItem>
            <ListItem
              classes={{ button: classes.listItem, }}
              button
              onClick={() => {
                this.updateDuration(90);
              }}>
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {duration === 90 ? <DoneIcon /> : ''}
              </ListItemIcon>
              <Typography variant="inherit">90 Days</Typography>
            </ListItem>
            {this.props.showPastDue ? (
              <ListItem
                classes={{ button: classes.listItem, }}
                button
                onClick={() => {
                  this.updateDuration(-1);
                }}>
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  {duration === -1 ? <DoneIcon /> : ''}
                </ListItemIcon>
                <Typography variant="inherit">Past Dues</Typography>
              </ListItem>
            ) : null}
            <Divider />
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={() => {
                this.updateDuration(100);
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {duration === 100 ? <DoneIcon /> : ''}
              </ListItemIcon>
              <Typography variant="inherit">Custom Range</Typography>
            </ListItem>
          </List>
        </Popover>

        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          id="profileMenu"
          classes={{ paper: classes.profileMenu }}
          anchorEl={this.state.profileAnchorEl}
          keepMounted
          elevation={2}
          open={Boolean(this.state.customRangeEl)}
          onClose={() => {
            this.setState({
              customRangeEl: false,
              profileAnchorEl: null,
            });
          }}
        >

          <div className={classes.customRangeFilter}>
            <Grid container spacing={1} alignItems="center" justifyContent="space-between">
              {<Grid item>
                <div className={classes.addCriteriaDatePicker}>
                  {<DatePicker
                    selected={new Date(_from)}
                    onChange={date => {
                      this.updateStartDate(date);
                    }}
                    maxDate={new Date(_to)}
                    // minDate={new Date(CommonFn.getPreviousDate(90))}
                    portalId="evMetaDialog"
                    dateFormat="yyyy/MM/dd"
                    popperClassName={classes.date_popper}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={
                      <FormControl fullWidth className={classes.margin} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-amount" className={classes.inputlabel}>Start Date</InputLabel>
                        <OutlinedInput
                          placeholder="YYYY-MM-DD"
                          id="outlined-adornment-amount"
                          value={_from}
                          startAdornment={
                            <InputAdornment>
                              <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment>
                              <ArrowDropDownIcon />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    }
                  />}
                </div>
              </Grid>}
              {<Grid item>
                <div className={classes.addCriteriaDatePicker}>
                  <DatePicker
                    minDate={new Date(_from)}
                    // maxDate={new Date()}
                    selected={new Date(_to)}
                    onChange={date => {
                      this.updateEndDate(date);
                    }}
                    dateFormat="MMM do yyyy"
                    portalId="dateDialog"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    popperClassName={classes.date_popper}
                    customInput={
                      <FormControl fullWidth className={classes.margin} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-amount" className={classes.inputlabel}>End Date</InputLabel>
                        <OutlinedInput
                          placeholder="YYYY-MM-DD"
                          id="outlined-adornment-amount"
                          value={_to}
                          startAdornment={
                            <InputAdornment>
                              <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment>
                              <ArrowDropDownIcon />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    }
                  />
                </div>
              </Grid>}
            </Grid>
            <div style={{ textAlign: 'right', marginTop: 20, zIndex: -1000 }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.applyBtn}
                onClick={(event) => {
                  this.applyCustomRange();
                }}
              > Apply
              </Button>
            </div>
          </div>
        </Popover>

      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(DateFilter));
