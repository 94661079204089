import React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import LayeredCard from "../../Application/Components/Evaluate/Components/Common/LayeredCard";
import { Grid, InputAdornment, CircularProgress } from "@material-ui/core";

import { Search as SearchIcon } from "@material-ui/icons";
import CommonCss from "commonCss";

import DefaultImage from "../../../assets/images/evaluation/default.svg";
// import { PRODUCTS } from "../../../services/products";
// import Cart from "../../Application/Components/Evaluate/Components/Common/Cart";
import CartDrawer from "Components/Application/CartDrawer";

import OutlinedInput from "../../Common/Input/OutlinedInput";
import SliderFilter from "../../Common/SliderFilter";
import { showSnackBar } from "redux/snackbar/action";
import classnames from "classnames";
import AppBarV2 from "../../Application/AppBarV2";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchProducts, fetchProductReaction, fetchWishListedProducts, fetchRecommendedProducts } from "redux/product/action";
import { updateDrawerStyle } from "redux/drawer/action";
import { createEvaluationCart, removeProductFromCart, fetchEvaluationCart } from "redux/evaluation/cart/action";
import queryString from "query-string";
import AddProductManuallyDialog from "../../Application/Components/Settings/AddProductManuallyDialog";
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import AddProductButton from "Components/Common/AddProductButton";
// redux
import { hideProductDetailsDialog } from "redux/dialogs/action";
import { storeSearchString } from "redux/applications/action";
import TooltipMsg from "@material-ui/core/Tooltip";
import Clear from "@material-ui/icons/Clear";
// Dilogs
import { isEnabledEvaluate } from "util/Common";

const connectedProps = (state) => ({
  searchString: state.applications.searchString,
  products: state.products.products,
  totalProduct: state.products.totalProduct,
  addProductStateProg: state.products.addProductStateProg,
  addProductStateData: state.products.addProductStateData,
  addProductStateError: state.products.addProductStateError,
  fetchProductsProgress: state.products.fetchProductsProgress,
  evaluationCart: state.evaluationCart.evaluation,
  recommendedProducts: state.products.recommendedProducts,
  fetchRecommendedProductsProgress: state.products.fetchRecommendedProductsProgress,
  addProductManuallyDialogOpen: state.productsSettings.addProductManuallyDialogOpen,
  orgUser: state.orgUser,
  userRole: state.authUser.user?.Role,
  addOrgProductProgress: state.orgProduct.addOrgProductProgress,
});

const connectionActions = {
  storeSearchString: storeSearchString,
  fetchProducts: fetchProducts,
  showSnackBar: showSnackBar,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  updateDrawerStyle: updateDrawerStyle,
  fetchEvaluationCart: fetchEvaluationCart,
  fetchRecommendedProducts: fetchRecommendedProducts,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  hideProductDetailsDialog: hideProductDetailsDialog,
  fetchWishListedProducts: fetchWishListedProducts,
  fetchProductReaction: fetchProductReaction,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    topContainer: {
      marginTop: theme.spacing(3),
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
    },
    title: {
      fontSize: theme.spacing(2.4),
    },
    filterSection: {
      border: "1px solid #3C3C3C",
      borderRadius: 5,
      minHeight: 32,
      minWidth: 120,
      color: "#20253A",
      fontSize: 15,
      padding: 0,
      marginRight: 20,
    },
    mainSection: {
      transition: "all 200ms ease-in-out",
      width: "100%",
    },
    addSection: {
      width: "calc(100% - 400px)",
    },
    headSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      flex: 1,
    },
    categoriesList: {
      marginTop: theme.spacing(6.4),
      maxHeight: "calc(100vh - 210px)",
      minHeight: 200,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },

    recommendedList: {
      marginTop: theme.spacing(6.4),
      maxHeight: "calc(56vh - 210px)",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: 250,
    },

    othersList: {
      marginTop: theme.spacing(6.4),
      maxHeight: "calc(56vh - 210px)",
      minHeight: 200,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    main: {
      maxWidth: "calc(100% - 280px)",
      flexBasis: "calc(100% - 280px)",
    },
    filter: {
      flex: 1,
      textAlign: "right",
    },
    recommended: {
      fontSize: 18,
      fontWeight: 600,
      flex: 1,
    },
    searchSection: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      '& [class*="MuiOutlinedInput-root"]': {
        maxHeight: 40,
        minHeight: 40,
        maxWidth: 343,
      },
      "& [class*='MuiInputBase-input']": {
        fontSize: 13,
        width: 300,
      },
      // "& [class*='MuiSvgIcon-root']": {
      //   fill: "#707070",
      // },
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
      "&>div ": {
        marginBottom: 0,
      },
    },
    emptyText: {
      textAlign: "center",
      height: 100,
      paddingTop: 20,
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    cartSection: {
      "& [class*=Cart-root], #cartSection": {
        transition: "all 400ms linear",
        padding: 21,
        right: -300,
        "& *": {
          opacity: 0,
          transition: "all 600ms ease-in",
          // whiteSpace: 'nowrap'
        },
      },
      "&.showCart": {
        "& [class*=Cart-root], #cartSection": {
          right: "0 !important",
          "& *": {
            opacity: 1,
          },
        },
      },
    },
    searchIconPop: {
      cursor: "pointer",
      fontSize: theme.spacing(3),
      marginRight: "10px",
      "&:hover": {
        color: "#fff !important",
        background: "#707070",
        borderColor: "#707070",
        padding: "3px",
        borderRadius: theme.spacing(20),
        fontSize: theme.spacing(3),
      },
    },
    rootContainer: CommonCss.rootContainer,
  });

class VendorInReviewProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageSize: 60,
      items: [],
      isCartShow: false,
      queryParams: queryString.parse(this.props.location.search),
      newProductId: 0,
      searchRestored: false,
      lastLocation: this.props.lastLocation,
    };
    this.fetchData = this.fetchData.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    this.props.fetchWishListedProducts();
  }
  componentDidUpdate(prevProps, prevStats) {
    const { lastLocation } = this.state;
    if (lastLocation?.pathname === "/app/evaluate" || (lastLocation?.pathname === "/app/home" && prevProps?.searchString !== "")) {
      this.setState({ lastLocation: null }, () => {
        this.props.storeSearchString("");
      });
    } else if (lastLocation?.pathname && prevProps?.searchString !== "" && this.state.searchRestored == false) {
      this.setState({ query: prevProps.searchString, searchRestored: true }, () => {
        this.fetchData();
      });
    }
    this.cartDrawer(prevProps);

    if (!this.props.fetchProductsProgress && prevProps.fetchProductsProgress) {
      if(this.props.products && this.props.products.length > 0) {
        let productIds = this.props.products.map((product) => parseInt(product.ID));
        this.getProductReactions(productIds);
      }
    }

    if (!this.props.addOrgProductProgress && prevProps.addOrgProductProgress) {
      this.fetchData();
    }

    if (prevProps.addProductStateProg && !this.props.addProductStateProg) {
      if (!this.props.addProductStateError) {
        this.props.showSnackBar("product state updated successfully", "success", 3000);
        if(Array.isArray(this.props.addProductStateData)){
          this.props.history.push('/app/products/org-products');
        }
      } else {
        this.props.showSnackBar("failed to update product state", "error", 3000);
      }
    }
  }

  getProductReactions(productIDs) {
    this.props.fetchProductReaction("all_product_blocking", {
      productIDs,
      source: "all_product_blocking",
      sourceData: "all_product_blocking",
    });
  }

  cartDrawer = (preProps) => {
    if (
      (this.props.evaluationCart && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length) !==
      (preProps.evaluationCart && preProps.evaluationCart.Products && preProps.evaluationCart.Products.length)
    ) {
      this.setState({ isCartShow: true });
      setTimeout(() => {
        this.setState({ isCartShow: false });
      }, 2000);
    }
  };

  fetchData() {
    var subCategory = 0;
    var type = "";
    var featureId = [];
    if (Object.keys(this.state.queryParams).length && !this.state.queryParams.newProductId) {
      subCategory = this.state.queryParams.subcategory;

      if (this.state.queryParams.featureId) {
        featureId = this.state.queryParams.featureId.split(",");
        featureId = featureId.map((value) => parseInt(value));
      }

      this.props.fetchRecommendedProducts(subCategory, {
        feature_ids: featureId,
        num_users: this.state.queryParams?.user || "",
        page: this.state.page,
        pageSize: this.state.pageSize,
        query: this.state.query,
        type: 2,
      });
    }

    // if it is new product
    if (Object.keys(this.state.queryParams).length && this.state.queryParams.newProductId) {
      this.setState({ newProductId: this.state.queryParams.newProductId });
    }

    //source=evaluation-home
    if (subCategory !== 0) {
      type = 1;
    }
    this.props.fetchProducts(subCategory, this.state.page, this.state.pageSize, this.state.query, type, featureId, this.state.queryParams?.user || "", 2);
  }

  addToCart(item) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(item.ID);
    } else {
      var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.map((p) => p.ID);
      if (addedToCart.indexOf(item.ID) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, item.ID);
      } else {
        this.props.createEvaluationCart(item.ID, this.props.evaluationCart.ID);
      }
    }
  }

  toggleDrawer = (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    this.setState({ open: false });
  };

  cartOpen = () => {
    this.setState({ isCartShow: true });
  };

  cartClose = () => {
    this.setState({ isCartShow: false });
  };

  handleClear(event) {
    event.stopPropagation();
    var me = this;
    this.props.storeSearchString("");
    this.setState({ query: "", page: 0 }, () => {
      me.fetchData();
    });
  }

  productStates = (arr) => {
    if (arr && Array.isArray(arr)) {
      const data = arr.map((item,index) => ({
        StateName: item.State,
        StateID: index
      }));
      return data;
    } else {
      return [];
    }
  };

  render() {
    const classes = this.props.classes;

    const isCart = this.props.evaluationCart !== null && this.props.evaluationCart !== '' && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length > 0;

    var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.map((p) => p.ID);

    const filters = [
      {
        label: "Category",
        isMultiAutoCompleteSelect: true,
        placeholder: "Search category",
        list: [{ label: "Data" }, { label: "Data" }],
      },
      {
        label: "Pricing Options",
        isMultiCheckboxSelect: true,
        list: [{ label: "Free Trial" }, { label: "Subscription" }, { label: "Free Plan" }],
      },
      {
        label: "Number of users",
        isMultiCheckboxSelect: true,
        list: [{ label: "0-250" }, { label: "250-1000" }, { label: "Greater than 1000" }],
      },
      {
        label: "Platform",
        isMultiCheckboxSelect: true,
        list: [{ label: "Web" }, { label: "Mobile" }, { label: "Mac" }, { label: "Windows" }],
      },
      {
        label: "Deployment",
        isMultiCheckboxSelect: true,
        list: [{ label: "Cloud" }, { label: "Self-Hosted" }],
      },
    ];
    const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForUsersCheck");
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForAdminsCheck");
    let isShowCheckBox = true;
    if ((!EnableEvaluateForAdminsCheck && this.props.userRole !== "OrgUser") || (!EnableEvaluateForUsersCheck && this.props.userRole === "OrgUser")) {
      isShowCheckBox = false;
    }

    return (
      <div>
        <div>
          <div className={isCart && this.state.isCartShow ? classes.main : ""}>
            <AppBarV2 title="Unverified Products" withBack={true} dropdown={true} />
            <div className={classnames(classes.bottomContainer, classes.rootContainer)}>
              <div className={classes.headSection}>
                {
                  <p className={classes.productCount}>
                    {this.props.totalProduct.toLocaleString("en-US")}
                    {this.props.totalProduct > 1 ? " Products" : " Product"} found
                  </p>
                }
                <div className={classes.searchSection}>
                  <OutlinedInput
                    id={"searchProducts"}
                    placeholder="Search Products"
                    value={this.state.query}
                    onChange={(event) => {
                      var me = this;
                      this.props.storeSearchString(event.target.value);
                      this.setState({ query: event.target.value, page: 0 }, () => {
                        me.fetchData();
                      });
                    }}
                    startAdornment={
                      <InputAdornment>
                        <SearchIcon className={classes.searchIcon} />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment>
                        {this.state.query.length > 0 && this.state.query !== "" && this.state.query !== null && (
                          <TooltipMsg placement="top" title={<span style={{ fontSize: "14px", display: "inline-block", height: "25px", lineHeight: "25px" }}>Clear</span>} arrow>
                            <Clear className={classes.searchIconPop} onClick={this.handleClear} />
                          </TooltipMsg>
                        )}
                      </InputAdornment>
                    }
                    roundedCorner={true}
                  />
                </div>
                <div className={classes.filter}>
                  <AddProductButton title={'Add Product'}/>
                </div>
              </div>
              {
                <div className={classes.categoriesList} id="allProducts">
                  <InfiniteScroll
                    scrollableTarget="allProducts"
                    style={{ display: "flex", flexDirection: "column", overflow: "hidden", marginRight: 20 }} //To put endMessage and loader to the top.
                    dataLength={this.props.products?.length} //This is important field to render the next data
                    next={() => {
                      var me = this;
                      this.setState({ page: this.state.page + 1 }, () => {
                        me.fetchData();
                      });
                    }}
                    hasMore={this.props.products.length === (this.state.page + 1) * this.state.pageSize}
                    endMessage={
                      <p className={classes.emptyText}>
                        {this.props.products?.length === 0 && this.props.fetchProductsProgress == false && (
                          <span>
                            <span>Not there? Help us serve you better by adding</span>{" "}
                            <span className={classes.addProductLink} onClick={() => this.props.showAddProductManuallyDialog({ name: this.state.query })}>
                              {this.state.query}
                            </span>{" "}
                          </span>
                        )}
                      </p>
                    }
                  >
                    <Grid container spacing={3}>
                      {this.props.products.map((product, index) => (
                        <Grid item lg={isCart ? 3 : 2} key={index} md={isCart ? 4 : 3} xs={12} sm={6} className={this.state.newProductId === product.ID ? "newProduct" : ""}>
                          <LayeredCard
                            isActionButtons
                            showReactionResponses
                            title={product.Name}
                            subTitle={product.CompanyName}
                            description={product.Description}
                            image={product.ProductLogo === null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo}
                            toogleCheck={() => {
                              // this.props.history.push("/app/evaluate/subcategory/1")
                              this.addToCart(product);
                            }}
                            productStates={this.productStates(product.States)}
                            onClick={() => {}}
                            numLayer={0}
                            checked={addedToCart.indexOf(product.ID) !== -1}
                            showCheckbox={isShowCheckBox}
                            id={product.ID}
                            uniqueId={product.ID + "_z_" + index}
                            type={"product"}
                            IsExisting={product.IsExisting}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </InfiniteScroll>
                  {this.props.fetchProductsProgress === true && this.props.products?.length === 0 && (
                    <div className={classes.backdrop}>
                      {" "}
                      <CircularProgress />
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
          <div id="homeCart" onMouseEnter={() => this.cartOpen()} onMouseLeave={() => this.cartClose()} className={classnames(classes.cartSection, this.state.isCartShow ? "showCart" : "")}>
            {isCart && (
              <div>
                <CartDrawer />
              </div>
            )}
          </div>
          <SliderFilter open={this.state.open} toggleDrawer={this.toggleDrawer} filters={filters} />
        </div>
        {this.props.addProductManuallyDialogOpen && <AddProductManuallyDialog />}
      </div>
    );
  }
}

export default connector(compose(withStyles(styles), withLastLocation)(VendorInReviewProducts));
