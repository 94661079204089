import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide} from '@material-ui/core';
import ComingSoon from './ComingSoon';
import FeedbackCS from "../../assets/images/home/feedback_comming_soon.svg";
import CloseIcon from '@material-ui/icons/Close';

// redux
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
    notifyMeFetchInProgress: state.common.notifyMeFetchInProgress,
    notifyMeFetchResponse: state.common.notifyMeFetchResponse
});
const connectionActions = {
    showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const styles = (theme) => createStyles({
    "title":{
        fontSize:theme.spacing(2.5),
        marginBottom:theme.spacing(2),
        marginLeft: 400
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
});

class ComingSoonDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidUpdate(){
        console.log('login 1',this.props.notifyMeFetchInProgress)
        console.log('login 2',this.props.notifyMeFetchResponse)
        if(this.props.notifyMeFetchInProgress == false && this.props.notifyMeFetchResponse?.message === 'Success'){
            this.props.closeDownload(false)
        }
    }

    uploadImageChange = (event) => {
        console.log(event.target.files[0])
    }

    deleteImage = () => {
        console.log('delete')
    }

    handleParentData = (formModel) => {
        console.log(formModel);
    }
    render() {
        const classes = this.props.classes;
       
        return <Dialog 
                    onClose={this.props.closeDownload} 
                    aria-labelledby="add-upload-dialog" 
                    open={this.props.comingSoonOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"md"}
                    scroll={"body"}
                    >
                    <DialogContent classes={{root:classes.dialogContent}}>
                            <div className={classes.close} onClick={()=>{this.props.closeDownload(false)}}><CloseIcon/></div>
                            <p className={classes.title}>Coming Soon</p>
                            <ComingSoon 
                            feature={this.props.feature}
                            image={FeedbackCS} title={""}
                            description={ this.props.description || "Shareable Dashboard. Interested in learning more?"}/>
                    </DialogContent>
                </Dialog>
    }
}


export default connector(compose(
    withStyles(styles)
)(ComingSoonDialog));