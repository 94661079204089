import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import classnames from "classnames";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles, createStyles } from "@material-ui/core/styles";
import AppBarV2 from "Components/Application/AppBarV2";
import TimelineIcon from "@material-ui/icons/Timeline";
import deleteIcon from "assets/images/delete.svg";
import Image from "Components/Common/image.jsx";
import { Paper, Divider, IconButton, Button, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import * as Validator from "util/Validation";
import { chartTypes, chartConfigurations, barChartFormArr, lineChartFormArr, pieChartFormArr, doughnutChartFormArr, dataTableFormArr, setNestedState } from "util/ChartConfig";
import ReactSelect from "react-select";
import FormSections from "./FormSections";
import { fetchDatasets, updateVisualization, getVisualizationChartData, getVisualizationDetails } from "redux/visualization/action";
import { showSnackBar } from "redux/snackbar/action";
import Input from "../Common/Input";
import Switch from "../Common/Switch";
import Number from "../Common/Number";
import Select from "../Common/Select";
import Color from "../Common/Color";
import CommonCss from "commonCss";
import ChartsRenderer from "../Common/ChartsRenderer";
import uniqid from "uniqid";
import AddFiltersDialog from "../dialogs/AddFiltersDialog";
import FilterListIcon from "@material-ui/icons/FilterList";
import IconPicker from "../Common/IconPicker";

const connectedProps = (state) => ({
  datasets: state.visualization.datasets,
  datasetFetchProgress: state.visualization.datasetFetchProgress,
  datasetFetchError: state.visualization.datasetFetchError,

  updateVisualizationProgress: state.visualization.updateVisualizationProgress,
  updateVisualizationSuccess: state.visualization.updateVisualizationSuccess,
  updateVisualizationError: state.visualization.updateVisualizationError,

  visualizationChartDataMap: state.visualization.visualizationChartDataMap,
  fetchVisualizationChartDataProgress: state.visualization.fetchVisualizationChartDataProgress,
  fetchVisualizationChartDataError: state.visualization.fetchVisualizationChartDataError,

  visualizationDetails: state.visualization.visualizationDetails,
});

const connectionActions = {
  fetchDatasets: fetchDatasets,
  updateVisualization: updateVisualization,
  getVisualizationDetails: getVisualizationDetails,
  getVisualizationChartData: getVisualizationChartData,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 2),
      width: "85%",
    },
    rootTable: {
      padding: theme.spacing(2, 2, 0, 2),
      width: "85%",
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
      textAlign: "center",
    },

    configContainer: {
      display: "flex",
    },
    disabledBox: {
      pointerEvents: "none",
      opacity: 0.5,
    },
    submenu1: {
      width: "13%",
      maxWidth: "170px",
      minWidth: "150px",
    },
    submenu2: {
      width: "40%",
      maxWidth: "500px",
    },
    container: {
      // display: "flex",
    },
    mainMenu: {
      height: "100vh",
      background: "#fff",
      borderRight: "1px solid gainsboro",
      "& [class*='MuiAccordion-root']": {
        margin: "0 !important",
      },
      "& [class*='MuiIconButton-root']": {
        padding: "6px",
      },
      "& [class*='MuiAccordionSummary-content']": {
        margin: 0,
      },
      "& [class*='MuiAccordionSummary-root']": {
        minHeight: "45px",
      },
    },
    mainFormMenu: {
      padding: "0.8rem",
      height: "100vh",
      overflowY: "auto",
      background: "#fff",
      borderRight: "1px solid gainsboro",
    },
    accordion: {
      width: "100%",
      // marginTop: 10,
      position: "relative",
    },
    flexWrap: {
      display: "flex",
      gap: 15,
      marginTop: 10,
      flexDirection: "column",
      alignItems: "center",
    },
    innerText: {
      color: "#696B6E",
      padding: "0.8rem 1rem",
      cursor: "pointer",
      display: "block",
      margin: 0,
    },
    "@global": {
      ".applicationContent": {
        padding: "0 !important",
      },
    },
    products: {
      display: "flex",
    },
    activemainMenu: {
      color: "#4A87F8",
      background: "#F2F7FF",
      fontSize: 13,
    },
    heading: {
      fontWeight: 500,
    },
    filter: {
      textAlign: "right",
    },
    formGroupElements: {
      marginBottom: 10,
    },
    chartWraper: {
      maxWidth: "1000px",
      margin: "0 auto",
      padding: "3rem",
      overflowX: "auto",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    listItem: {
      cursor: "pointer",
      padding: "0.5rem 1rem",
    },
    formLabel: {
      marginBottom: 20,
      fontWeight: 600,
      marginTop: 10,
      fontSize: "1rem",
    },
    supportLabel: {
      marginBottom: 6,
      display: "block",
    },
    fieldWraper: {
      margin: "6px 0px",
    },
    fieldWraper2: {
      margin: "10px 0px",
    },
    addFilters: {
      color: "#4175DF",
      cursor: "pointer",
      fontSize: 16,
      fontWeight: 500,
      display: "flex",
      marginBottom: 10,
    },
    actionButton: {
      padding: 10,
      position: "absolute",
      right: 5,
      top: 5,
      cursor: "pointer",
    },
    fullSceen: CommonCss.backdrop,
    flexWrap2: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      gap: 5,
    },
    filterTitle: {
      fontSize: "15px",
      fontWeight: "500",
      marginBottom: "0.8rem",
    },
    filterContent: {
      width: "100%",
      padding: "10px",
      border: "1px solid #cbcbcb",
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
      borderRadius: "5px",
    },
    flexField: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    fieldSupportLabel: {
      marginTop: 3,
      marginBottom: 3,
      display: "inline-block",
      marginRight: 30,
    },
  });

class VisualizationBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDefaultForm: true,
      activeMenu: "default",
      chartType: null,
      chartForms: [],
      currentChartForm: null,
      currentChartConfig: null,
      xAxisValues: null,
      yAxisValues: [
        {
          uid: uniqid(),
        },
      ],
      dataCardTitle: "",
      dataCardSubTitle: "",
      dataCardLink: "",
      dataCardType: "",
      dataCardIsButton: false,
      dataCardIcon: null,
      dataCardColor: "#1f73b7",
      globalFilters: [],
      dataConfiguration: [],
      yAxisAggregation: {},
      xAxisAggregation: {},
      openFilters: false,
      filterValueData: null,
      visualizationId: null,
      tableColumns: [
        {
          uid: uniqid(),
        },
      ],
      tableConfiguration: {},
      filters: [{}],
    };
  }

  componentDidMount() {
    let visualizationId = null;
    if (this.props.visualizationFor === "EDIT_VIEW") {
      visualizationId = this.props.match.params.id;
    } else {
      visualizationId = this.props.id;
    }
    this.setState(
      {
        visualizationId: visualizationId,
      },
      () => {
        this.props.fetchDatasets();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (!this.props.updateVisualizationProgress && prevProps.updateVisualizationProgress) {
      if (this.props.updateVisualizationSuccess) {
        this.props.getVisualizationChartData(this.state.visualizationId);
        // this.props.showSnackBar("Updated successfully", "success", 3000);
      } else {
        // this.props.showSnackBar("Failed to update", "error", 3000);
      }
    }

    if (!this.props.fetchVisualizationChartDataProgress && prevProps.fetchVisualizationChartDataProgress) {
      this.initializeConfigurations();
    }
  }

  initializeConfigurations = () => {
    if (this.props.visualizationChartDataMap) {
      let id = this.props.match?.params?.id;
      let dataObject = this.props.visualizationChartDataMap[id];

      if (dataObject?.config) {
        let config = dataObject?.config?.configuration;
        let chartType;
        let chartConfig = {};
        let xAxisValues = null;
        let yAxisAggregation = {};
        let xAxisAggregation = {};
        let yAxisValues = [];
        let filters = [{}];

        if (Validator.isJson(config)) {
          config = JSON.parse(config);
          chartType = chartTypes.find((item) => item.value === config.chartType);
          chartConfig = config.configurations;

          if (config.xaxis) {
            xAxisValues = {
              label: config.xaxis.label,
              value: config.xaxis.id,
              filters: config.globalFilters || this.state.globalFilters,
              aggregation: config.xaxis.aggregationArr,
            };
            if (chartType?.value === "DATACARD") {
              xAxisAggregation = {
                label: config.xaxis.aggregation,
                value: config.xaxis.aggregation,
              };
            }
          }
          if (config.filters) {
            filters = config.filters;
          }

          if (config.yaxis) {
            config.yaxis.forEach((item) => {
              yAxisValues.push({
                label: item.label,
                value: item.id,
                aggregation: item.aggregationArr,
                filters: item.filters,
                uid: item.uid,
              });
              yAxisAggregation[item.uid] = {
                label: item.aggregation,
                value: item.aggregation,
              };
            });
          }
        }

        if (chartType) {
          this.onSelectChartType(chartType, chartConfig, true);
        }

        this.setState({
          dataCardTitle: config.dataCardTitle || this.state.dataCardTitle,
          dataCardSubTitle: config.dataCardSubTitle || this.state.dataCardSubTitle,
          dataCardLink: config.dataCardLink || this.state.dataCardLink,
          dataCardType: config.dataCardType || this.state.dataCardType,
          dataCardIsButton: config.dataCardIsButton || this.state.dataCardIsButton,
          dataCardIcon: config.dataCardIcon || this.state.dataCardIcon,
          dataCardColor: config.dataCardColor || this.state.dataCardColor,
          globalFilters: xAxisValues?.filters || this.state.globalFilters,
          chartType: chartType,
          xAxisValues: xAxisValues,
          yAxisValues: yAxisValues.length > 0 ? yAxisValues : [{ uid: uniqid() }],
          dataConfiguration: config.datasets || [],
          yAxisAggregation: yAxisAggregation,
          xAxisAggregation: xAxisAggregation,
          filters: filters,
          tableColumns: config.columns || [{ uid: uniqid() }],
        });
      }
    }
  };

  isValidData = () => {
    let flag = true;
    const { tableColumns, xAxisValues, yAxisValues, chartType, dataCardTitle } = this.state;

    if (!this.props.visualizationDetails) {
      flag = false;
    }
    if (!xAxisValues && !["LINKCARD", "TABLE"].includes(chartType?.value)) {
      flag = false;
    }
    if (!yAxisValues && !["LINKCARD", "DATACARD", "TABLE"].includes(chartType?.value)) {
      flag = false;
    }
    if (["LINKCARD", "DATACARD"].includes(chartType?.value) && dataCardTitle.length === 0) {
      flag = false;
    }
    if (chartType?.value === "TABLE" && !tableColumns[0]?.value) {
      flag = false;
    }
    return flag;
  };

  onUpdate = () => {
    const {
      tableColumns,
      xAxisValues,
      dataConfiguration,
      visualizationId,
      yAxisValues,
      dataCardColor,
      yAxisAggregation,
      globalFilters,
      chartType,
      dataCardTitle,
      dataCardSubTitle,
      xAxisAggregation,
      currentChartConfig,
      filters,
      dataCardLink,
      dataCardType,
      dataCardIsButton,
      dataCardIcon,
    } = this.state;
    if (!this.isValidData()) {
      return;
    }

    let data = {
      xaxis: {
        id: xAxisValues?.value,
        label: xAxisValues?.label,
        aggregationArr: xAxisValues?.aggregation,
        tranformation: "",
        filters: xAxisValues?.filters || [],
        aggregation: xAxisAggregation?.value,
      },
      chartType: chartType?.value,
      filters: filters,
      configurations: currentChartConfig?.config,
    };

    if (["LINKCARD", "DATACARD"].includes(chartType?.value)) {
      data = {
        ...data,
        dataCardTitle: dataCardTitle,
        dataCardSubTitle: dataCardSubTitle,
        dataCardLink: dataCardLink,
        dataCardType: dataCardType,
        dataCardIsButton: dataCardIsButton,
        dataCardIcon: dataCardIcon,
        dataCardColor: dataCardColor,
        globalFilters: globalFilters,
        yaxis: [],
        datasets: [],
      };
    } else if (chartType?.value === "TABLE") {
      data = {
        columns: tableColumns.map((item) => ({
          ...item,
          id: item.value,
        })),
        filters: globalFilters,
        chartType: chartType?.value,
        configurations: currentChartConfig?.config,
      };
    } else {
      data = {
        ...data,
        yaxis: yAxisValues.map((item) => ({
          id: item.value,
          uid: item.uid,
          label: item.label,
          aggregationArr: item.aggregation,
          tranformation: "",
          filters: item.filters || [],
          aggregation: yAxisAggregation[item.uid]?.value,
        })),
        datasets: dataConfiguration,
      };
    }

    this.props.updateVisualization(visualizationId, { name: this.props.visualizationDetails.name, configuration: JSON.stringify(data) });
  };

  onSelectChartType = (e, initConfig = false, disableUpdateCall = false) => {
    let chartForms = [];
    let currentChartConfig = chartConfigurations.find((config) => config.type === e?.value);
    if (e.value === "LINE") {
      chartForms = lineChartFormArr;
    } else if (e.value === "BAR") {
      chartForms = barChartFormArr;
    } else if (e.value === "PIE") {
      chartForms = pieChartFormArr;
    } else if (e.value === "DOUGHNUT") {
      chartForms = doughnutChartFormArr;
    } else if (e.value === "DATACARD") {
      this.setState({ yAxisValues: [], yAxisAggregation: {} });
    } else if (e.value === "LINKCARD") {
      this.setState({ yAxisValues: [], yAxisAggregation: {}, xAxisValues: null, xAxisAggregation: {} });
    } else if (e.value === "TABLE") {
      chartForms = dataTableFormArr;
      // this.setState({ yAxisValues: [], yAxisAggregation: {} });
    }

    if (initConfig) {
      currentChartConfig.config = initConfig;
    }
    this.setState(
      {
        chartType: e,
        chartForms,
        currentChartConfig,
      },
      () => {
        if (!disableUpdateCall) {
          this.onUpdate();
        }
      }
    );
  };

  onUpdateDatasetsHandler = (index, key, value) => {
    let newdataConfiguration = [...this.state.dataConfiguration];
    if (!newdataConfiguration[index]) {
      newdataConfiguration[index] = {};
    }
    newdataConfiguration[index] = { ...newdataConfiguration[index], [key]: value };
    this.setState({ dataConfiguration: newdataConfiguration }, () => {
      this.onUpdate();
    });
  };

  onSelectYAxis = (e, uid) => {
    let dataset = [...this.state.dataConfiguration];
    let yAxisValues = [...this.state.yAxisValues];
    let index = this.state.dataConfiguration.findIndex((o) => o.uid === uid);
    if (index === -1) {
      dataset.push({ id: e.value, label: e.label, uid: uid, data: [] });
    } else {
      dataset[index] = { ...dataset[index], id: e.value, label: e.label, uid: uid, data: [] };
    }
    let index2 = this.state.yAxisValues.findIndex((o) => o.uid === uid);
    if (index2 > -1) {
      yAxisValues[index2] = { ...yAxisValues[index2], value: e.value, label: e.label, uid: uid, aggregation: e.aggregation };
    }
    this.setState({ yAxisValues: yAxisValues, dataConfiguration: dataset }, () => {
      this.onUpdate();
    });
  };

  addYAxis = () => {
    let yAxisValues = [...this.state.yAxisValues];
    yAxisValues.push({
      uid: uniqid(),
    });
    this.setState({ yAxisValues: yAxisValues });
  };

  removeYAxis = (index) => {
    let newYAxisValues = [...this.state.yAxisValues];
    let newDataConfiguration = [...this.state.dataConfiguration];
    newYAxisValues.splice(index, 1);
    newDataConfiguration.splice(index, 1);
    this.setState({ yAxisValues: newYAxisValues, dataConfiguration: newDataConfiguration }, () => {
      this.onUpdate();
    });
  };

  onUpdateTableColumnHandler = (index, key, value) => {
    let newtableColumns = [...this.state.tableColumns];
    if (!newtableColumns[index]) {
      newtableColumns[index] = {};
    }
    newtableColumns[index] = { ...newtableColumns[index], [key]: value };
    this.setState({ tableColumns: newtableColumns }, () => {
      this.onUpdate();
    });
  };

  onSelectTableColumn = (e, uid) => {
    let dataset = [...this.state.tableColumns];
    let index = this.state.tableColumns.findIndex((o) => o.uid === uid);
    if (index === -1) {
      dataset.push({ value: e.value, label: e.label, uid: uid });
    } else {
      dataset[index] = { ...dataset[index], value: e.value, label: e.label, uid: uid };
    }
    this.setState({ tableColumns: dataset }, () => {
      this.onUpdate();
    });
  };

  addTableColumn = (index) => {
    let tableColumns = [...this.state.tableColumns];
    let data = {
      uid: uniqid(),
    };
    tableColumns.splice(index + 1, 0, data);
    this.setState({ tableColumns: tableColumns });
  };

  removeTableColumn = (index) => {
    let newtableColumns = [...this.state.tableColumns];
    newtableColumns.splice(index, 1);
    this.setState({ tableColumns: newtableColumns }, () => {
      this.onUpdate();
    });
  };

  addNewFilter = (i) => {
    let filters = [...this.state.filters];
    let data = {
      value: null,
    };
    filters.splice(i + 1, 0, data);
    this.setState({ filters: filters }, () => {
      this.validateFilters();
    });
  };

  deleteFilter = (i) => {
    let newFilter = [...this.state.filters];
    newFilter.splice(i, 1);
    this.setState({ filters: newFilter }, () => {
      this.validateFilters();
    });
  };

  validateFilters = () => {
    let updateCall = true;
    this.state.filters.forEach((filter) => {
      if (!filter.value) {
        updateCall = false;
      }
    });
    if (updateCall || this.state.filters?.length === 1) {
      this.onUpdate();
    }
  };

  render() {
    const { classes, datasets, visualizationDetails } = this.props;

    const {
      dataCardTitle,
      dataCardSubTitle,
      dataCardColor,
      globalFilters,
      chartType,
      xAxisValues,
      xAxisAggregation,
      dataConfiguration,
      yAxisValues,
      yAxisAggregation,
      chartForms,
      currentChartConfig,
      activeMenu,
      currentChartForm,
      tableColumns,
      filters,
      dataCardLink,
      dataCardIcon,
      dataCardType,
      dataCardIsButton,
    } = this.state;

    let axisDataList = [];

    datasets.forEach((parent) => {
      parent?.attributes.forEach((item) => {
        axisDataList.push({
          label: item.name,
          value: `${parent.id}.${item.id}`,
          aggregation: item.aggregation,
          uid: uniqid(),
          type: item.type,
          entity_type: parent.id,
        });
      });
    });

    console.log(
      {
        chartType,
        dataCardTitle,
        dataCardSubTitle,
        dataCardColor,
        currentChartConfig,
        dataConfiguration,
        xAxisValues,
        yAxisValues,
        yAxisAggregation,
        xAxisAggregation,
        chartForms,
        currentChartForm,
        tableColumns,
        visualizationDetails,
        filters,
        dataCardLink,
        dataCardIcon,
        dataCardType,
        dataCardIsButton,
      },
      "datavisualization"
    );

    return (
      <div className={classes.container}>
        {this.props.visualizationFor === "EDIT_VIEW" && (
          <AppBarV2
            title={visualizationDetails?.name}
            newHeader={true}
            withBack={true}
            isEditableTitle={true}
            submitTitle={(value) => {
              this.props.updateVisualization(this.state.visualizationId, { name: value, configuration: visualizationDetails.configuration });
            }}
            isSubmittingTitle={this.props.updateVisualizationProgress}
          />
        )}
        <div className={classes.configContainer}>
          {this.props.visualizationFor === "EDIT_VIEW" && (
            <div className={classnames(classes.submenu1)}>
              <div className={classes.mainMenu}>
                <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItem
                    className={classnames(classes.innerText, classes.listItem, activeMenu === "default" ? classes.activemainMenu : "")}
                    onClick={() => {
                      this.setState({ showDefaultForm: true, activeMenu: "default", currentChartForm: null });
                    }}
                  >
                    <ListItemText primary="Structure" />
                    <ListItemSecondaryAction>
                      <TimelineIcon />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
                {chartType && (
                  <Accordion>
                    <AccordionSummary expanded={false} expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                      <div className={classes.heading}>Configurations</div>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: "column", padding: "0em" }}>
                      {chartForms.map((obj, i) => (
                        <div key={i}>
                          <Divider />
                          <p
                            className={classnames(classes.innerText, activeMenu === obj.name ? classes.activemainMenu : "")}
                            onClick={() => {
                              this.setState({
                                showDefaultForm: false,
                                activeMenu: obj.name,
                                currentChartForm: obj,
                              });
                            }}
                          >
                            {obj.name}
                          </p>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>
            </div>
          )}
          {this.props.visualizationFor === "EDIT_VIEW" && (
            <div className={classnames(classes.submenu2)}>
              {!currentChartForm ? (
                <div className={classes.mainFormMenu}>
                  <div className={classes.formLabel}>Structure</div>
                  <div>
                    <div className={classes.formGroupElements}>
                      <span className={classes.supportLabel}>Select Chart:</span>
                      <ReactSelect
                        isClearable={false}
                        value={chartType}
                        options={chartTypes}
                        classNamePrefix="select"
                        placeholder={"Select"}
                        onChange={(e) => {
                          this.onSelectChartType(e);
                        }}
                      />
                    </div>
                    {chartType && !["TABLE", "LINKCARD"].includes(chartType?.value) && (
                      <div className={classes.formGroupElements}>
                        <span className={classes.supportLabel}>X-Axis Data:</span>
                        <ReactSelect
                          isClearable={false}
                          options={axisDataList}
                          value={xAxisValues}
                          classNamePrefix="select"
                          placeholder={"Select"}
                          onChange={(e) => {
                            this.setState({ xAxisValues: e, xAxisAggregation: {} }, () => {
                              this.onUpdate();
                            });
                          }}
                        />
                      </div>
                    )}
                    {((xAxisValues && chartType && chartType?.value === "DATACARD") || chartType?.value === "LINKCARD") && (
                      <>
                        <div className={classes.fieldWraper2} style={{ marginTop: 20 }}>
                          <Input
                            label={"Title"}
                            value={dataCardTitle}
                            placeholder={"Enter Title"}
                            updateValue={(value) =>
                              this.setState({ dataCardTitle: value }, () => {
                                this.onUpdate();
                              })
                            }
                          />
                        </div>
                        <div className={classes.fieldWraper2} style={{ marginTop: 20 }}>
                          <Input
                            label={"Sub title"}
                            value={dataCardSubTitle}
                            placeholder={"Enter Sub title"}
                            updateValue={(value) =>
                              this.setState({ dataCardSubTitle: value }, () => {
                                this.onUpdate();
                              })
                            }
                          />
                        </div>
                        {chartType?.value === "LINKCARD" && (
                          <>
                            <div className={classes.fieldWraper2}>
                              <Switch
                                label={"Button"}
                                value={dataCardIsButton}
                                updateValue={(value) =>
                                  this.setState({ dataCardIsButton: value }, () => {
                                    this.onUpdate();
                                  })
                                }
                              />
                            </div>
                            <div className={classes.fieldWraper2}>
                              <Select
                                isClearable
                                label={"Quick Access"}
                                value={dataCardType}
                                options={["Start a new project", "Vendor catalog", "Expent vendor directory", "Activity"]}
                                updateValue={(value) =>
                                  this.setState({ dataCardType: value }, () => {
                                    this.onUpdate();
                                  })
                                }
                              />
                            </div>
                            <div className={classes.fieldWraper2}>
                              <Input
                                label={"Link"}
                                value={dataCardLink}
                                placeholder={"Enter Link"}
                                updateValue={(value) =>
                                  this.setState({ dataCardLink: value }, () => {
                                    this.onUpdate();
                                  })
                                }
                              />
                            </div>
                            <div className={classes.fieldWraper2}>
                              <IconPicker
                                selectedIconName={dataCardIcon}
                                onIconSelect={(iconName) => {
                                  this.setState({ dataCardIcon: iconName }, () => {
                                    this.onUpdate();
                                  });
                                }}
                              />
                            </div>
                          </>
                        )}
                        {chartType?.value === "DATACARD" && (
                          <div className={classes.fieldWraper2}>
                            <div
                              className={classes.addFilters}
                              onClick={() => {
                                this.setState({
                                  openFilters: true,
                                  filterValueData: {
                                    filters: globalFilters,
                                    label: dataCardTitle,
                                  },
                                });
                              }}
                            >
                              <FilterListIcon style={{ marginRight: 10 }} /> Filters
                            </div>
                          </div>
                        )}
                        <div className={classes.fieldWraper2}>
                          <Color
                            label={"Color"}
                            value={dataCardColor}
                            updateValue={(value) =>
                              this.setState({ dataCardColor: value }, () => {
                                this.onUpdate();
                              })
                            }
                          />
                        </div>
                        {chartType?.value === "DATACARD" && (
                          <div className={classes.fieldWraper2}>
                            <div className={classes.formGroupElements}>
                              <span className={classes.supportLabel}>Aggregation :</span>
                              <ReactSelect
                                isClearable={false}
                                options={xAxisValues.aggregation?.map((item) => ({
                                  label: item,
                                  value: item,
                                }))}
                                value={xAxisAggregation}
                                classNamePrefix="select"
                                placeholder={"Select"}
                                onChange={(e) => {
                                  this.setState({ xAxisAggregation: e }, () => {
                                    this.onUpdate();
                                  });
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {chartType && ["LINE", "BAR", "PIE"].includes(chartType?.value) && <span className={classes.supportLabel}>Y-Axis Data:</span>}

                    {chartType && ["LINE", "BAR", "PIE"].includes(chartType?.value) && yAxisValues && yAxisValues.length > 0
                      ? this.state.yAxisValues.map((item, index) => (
                        <div className={classes.flexWrap} key={index}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                              <div className={classes.heading} style={{ width: "95%" }}>
                                <p>Configuration for</p>
                                <ReactSelect
                                  isClearable={false}
                                  value={yAxisValues.find((o) => o.value === item.value)}
                                  options={axisDataList}
                                  classNamePrefix="select"
                                  placeholder={"Select"}
                                  onChange={(e) => {
                                    this.onSelectYAxis(e, item.uid);
                                  }}
                                />
                              </div>
                              {yAxisValues.length > 1 ? (
                                <div className={classes.actionButton}>
                                  <Image src={deleteIcon} onClick={() => this.removeYAxis(index)} />
                                </div>
                              ) : null}
                            </AccordionSummary>
                            {item.label && (
                              <AccordionDetails style={{ flexDirection: "column", padding: "0em 1em" }}>
                                <div className={classes.fieldWraper}>
                                  <div className={classes.formGroupElements}>
                                    <span className={classes.supportLabel}>Aggregation :</span>
                                    <ReactSelect
                                      isClearable={false}
                                      options={item.aggregation?.map((o) => ({
                                        label: o,
                                        value: o,
                                      }))}
                                      value={yAxisAggregation[item.uid]}
                                      classNamePrefix="select"
                                      placeholder={"Select"}
                                      onChange={(e) => {
                                        this.setState({ yAxisAggregation: { ...this.state.yAxisAggregation, [item.uid]: e } }, () => {
                                          this.onUpdate();
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className={classes.fieldWraper}>
                                  <div
                                    className={classes.addFilters}
                                    onClick={() => {
                                      this.setState({ openFilters: true, filterValueData: item });
                                    }}
                                  >
                                    <FilterListIcon style={{ marginRight: 10 }} /> Filters
                                  </div>
                                </div>
                                <div className={classes.fieldWraper}>
                                  <Input
                                    label={"Legend Title"}
                                    value={dataConfiguration[index]?.label}
                                    placeholder={"Enter legend"}
                                    updateValue={(value) => this.onUpdateDatasetsHandler(index, "label", value, item.value)}
                                  />
                                </div>
                                {chartType?.value !== "PIE" && (
                                  <div className={classes.fieldWraper}>
                                    <Color
                                      label={"Background Color"}
                                      value={dataConfiguration[index]?.backgroundColor}
                                      updateValue={(value) => this.onUpdateDatasetsHandler(index, "backgroundColor", value, item.value)}
                                    />
                                  </div>
                                )}
                                <div className={classes.fieldWraper}>
                                  <Color
                                    label={"Border Color"}
                                    value={dataConfiguration[index]?.borderColor}
                                    updateValue={(value) => this.onUpdateDatasetsHandler(index, "borderColor", value, item.value)}
                                  />
                                </div>
                              </AccordionDetails>
                            )}
                          </Accordion>
                          {index + 1 === yAxisValues.length ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                this.addYAxis();
                              }}
                            >
                              Add Y-Axis
                            </Button>
                          ) : null}
                        </div>
                      ))
                      : null}

                    {chartType && ["TABLE"].includes(chartType?.value) && tableColumns && tableColumns.length > 0 ? (
                      <div>
                        {tableColumns.map((item, index) => (
                          <div className={classes.flexWrap} key={index}>
                            {index === 0 && (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  this.addTableColumn(index - 1);
                                }}
                              >
                                Add Column
                              </Button>
                            )}
                            <Accordion className={classes.accordion}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                                <div className={classes.heading} style={{ width: "95%" }}>
                                  <p>Select Column</p>
                                  <ReactSelect
                                    isClearable={false}
                                    value={tableColumns.find((o) => o.value === item.value)}
                                    options={axisDataList}
                                    classNamePrefix="select"
                                    placeholder={"Select"}
                                    onChange={(e) => {
                                      this.onSelectTableColumn(e, item.uid);
                                    }}
                                  />
                                </div>
                                {tableColumns.length > 1 ? (
                                  <div className={classes.actionButton}>
                                    <Image src={deleteIcon} onClick={() => this.removeTableColumn(index)} />
                                  </div>
                                ) : null}
                              </AccordionSummary>
                              {item.label && (
                                <AccordionDetails style={{ flexDirection: "column", padding: "0em 1em" }}>
                                  <div className={classes.fieldWraper}>
                                    <Input
                                      label={"Title"}
                                      value={tableColumns[index]?.title}
                                      placeholder={"Enter Column title"}
                                      updateValue={(value) => this.onUpdateTableColumnHandler(index, "title", value, item.value)}
                                    />
                                  </div>
                                  <div className={classes.fieldWraper}>
                                    <Number
                                      label={"Column Width"}
                                      value={tableColumns[index]?.width}
                                      placeholder={"Enter Column Width"}
                                      updateValue={(value) => this.onUpdateTableColumnHandler(index, "width", value, item.value)}
                                    />
                                  </div>
                                  {/* <div className={classes.fieldWraper}>
                                    <Number
                                      label={"Font Size"}
                                      value={tableColumns[index]?.fontSize}
                                      placeholder={"Enter Font Size"}
                                      updateValue={(value) => this.onUpdateTableColumnHandler(index, "fontSize", value, item.value)}
                                    />
                                  </div> */}
                                  <div className={classes.fieldWraper}>
                                    <Select
                                      label={"Alignment"}
                                      value={tableColumns[index]?.align}
                                      options={["center", "right", "left"]}
                                      updateValue={(value) => this.onUpdateTableColumnHandler(index, "align", value, item.value)}
                                    />
                                  </div>
                                  {/* <div className={classes.fieldWraper}>
                                    <Select
                                      label={"Font Weight"}
                                      value={tableColumns[index]?.fontWeight}
                                      options={[100,200,300,400,500,600,700,800,900]}
                                      updateValue={(value) => this.onUpdateTableColumnHandler(index, "fontWeight", value, item.value)}
                                    />
                                  </div> */}

                                  {/* <div className={classes.fieldWraper}>
                                    <Color
                                      label={"Color"}
                                      value={tableColumns[index]?.color}
                                      updateValue={(value) => this.onUpdateTableColumnHandler(index, "color", value, item.value)}
                                    />
                                  </div>
                                  <div className={classes.fieldWraper}>
                                    <Color
                                      label={"Background Color"}
                                      value={tableColumns[index]?.backgroundColor}
                                      updateValue={(value) => this.onUpdateTableColumnHandler(index, "backgroundColor", value, item.value)}
                                    />
                                  </div>
                                  <div className={classes.fieldWraper}>
                                    <Color
                                      label={"Border Color"}
                                      value={tableColumns[index]?.borderColor}
                                      updateValue={(value) => this.onUpdateTableColumnHandler(index, "borderColor", value, item.value)}
                                    />
                                  </div>
                                  <div className={classes.fieldWraper}>
                                    <Number
                                      label={"Border Width"}
                                      value={tableColumns[index]?.width}
                                      placeholder={"Enter Border Width"}
                                      updateValue={(value) => this.onUpdateTableColumnHandler(index, "borderWidth", value, item.value)}
                                    />
                                  </div> */}
                                </AccordionDetails>
                              )}
                            </Accordion>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                this.addTableColumn(index);
                              }}
                            >
                              Add Column
                            </Button>
                          </div>
                        ))}
                        <div className={classes.fieldWraper2}>
                          <div
                            className={classes.addFilters}
                            onClick={() => {
                              this.setState({
                                openFilters: true,
                                filterValueData: {
                                  filters: globalFilters,
                                  label: "Data Table",
                                },
                              });
                            }}
                          >
                            <FilterListIcon style={{ marginRight: 10 }} /> Filters
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className={classes.mainFormMenu}>
                  {currentChartForm.isFilter ? (
                    <div className={classes.filterWrap}>
                      <div className={classes.formLabel}>Filters</div>
                      {filters?.map((filter, i) => (
                        <div className={classes.flexWrap} key={"filter" + i}>
                          {i === 0 && (
                            <IconButton size="small" onClick={() => this.addNewFilter(i - 1)}>
                              <AddCircleIcon />
                            </IconButton>
                          )}
                          <div className={classes.filterContent}>
                            <div className={classes.flexWrap2}>
                              <div style={{ width: "90%" }}>
                                <ReactSelect
                                  menuPosition="fixed"
                                  isSearchable={true}
                                  options={axisDataList.filter((o) => o.type !== "DATE")}
                                  classNamePrefix="select"
                                  placeholder="Apply filter on"
                                  onChange={(e) => {
                                    if (e) {
                                      var filterElement = filters[i];
                                      filterElement = { ...e, title: e.label };
                                      filters[i] = filterElement;
                                      this.setState({ filters: filters }, () => {
                                        this.validateFilters();
                                      });
                                    }
                                  }}
                                  value={filter}
                                />
                              </div>
                              {filters?.length > 1 && (
                                <IconButton
                                  size="medium"
                                  onClick={() => {
                                    this.deleteFilter(i);
                                  }}
                                >
                                  <Image alt="Delete" src={deleteIcon} />
                                </IconButton>
                              )}
                            </div>
                            {filter.value && (
                              <div className={classes.fieldWraper} style={{ width: "90%" }}>
                                <Input
                                  label={"Label"}
                                  value={filter.title}
                                  placeholder={"Enter Label"}
                                  updateValue={(value) => {
                                    var filterElement = filters[i];
                                    filterElement = { ...filterElement, title: value };
                                    filters[i] = filterElement;
                                    this.setState({ filters: filters }, () => {
                                      this.validateFilters();
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <IconButton size="small" onClick={() => this.addNewFilter(i)}>
                            <AddCircleIcon />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div className={classes.formLabel}>{currentChartForm.name}</div>
                      <FormSections
                        formSections={currentChartForm.sections || []}
                        configValues={currentChartConfig}
                        onUpdateHandler={(statePath, value) => {
                          let newChartConfig = setNestedState(currentChartConfig, statePath, value);
                          this.setState({ currentChartConfig: newChartConfig }, () => {
                            this.onUpdate();
                          });
                        }}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          )}
          <div className={classes.root}>
            <Paper className={classes.chartWraper}>
              <ChartsRenderer visualizationId={this.state.visualizationId} />
            </Paper>
          </div>
        </div>
        {this.state.openFilters && (
          <AddFiltersDialog
            onSave={(filters) => {
              if (["DATACARD", "TABLE"].includes(chartType?.value)) {
                this.setState({ globalFilters: filters, xAxisValues: { ...xAxisValues, filters: filters }, openFilters: false, filterValueData: null }, () => {
                  this.onUpdate();
                });
              } else {
                let yAxisValues = [...this.state.yAxisValues];
                let index = yAxisValues.findIndex((o) => o.uid === this.state.filterValueData.uid);
                if (index > -1) {
                  yAxisValues[index].filters = filters;
                }
                this.setState({ yAxisValues: yAxisValues, openFilters: false, filterValueData: null }, () => {
                  this.onUpdate();
                });
              }
            }}
            isOpen={this.state.openFilters}
            filterValueData={this.state.filterValueData}
            axisDataList={chartType?.value === "TABLE" ? tableColumns : axisDataList}
            onClose={() => this.setState({ openFilters: false, filterValueData: null })}
          />
        )}
      </div>
    );
  }
}

VisualizationBuilder.defaultProps = {
  visualizationFor: "EDIT_VIEW",
};

export default connector(compose(withStyles(styles), withLastLocation)(VisualizationBuilder));