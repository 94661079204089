import {
    Dialog, DialogContent, Slide, Typography, IconButton, CircularProgress
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import Select from 'react-select';
import SqureButton from 'Components/Common/SqureButton';
import classnames from "classnames";
import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/delete.svg";

import { hideEvaluationDataCopyDialog } from 'redux/workflow/summary/action';
import { fetchOrgProductDetailsTabs } from 'redux/product/orgProduct/action';
import ReactSelect from "react-select";
import { showSnackBar } from "redux/snackbar/action";

import { getAppCatalogTypeUsingRequirementType, getItemDefaultField, catalogDefaultItemsForUpdate } from "util/AppCatalogTypeOptions";
import { updateOrgProdutDetailsTabSectionItemData, updateOrgProductDetails } from "redux/product/orgProduct/action";
// import { CommonFn } from 'services/commonFn';

import { getRequirementTypeUsingAppCatalogType } from "util/RequirementTypeOptions"
// import options from 'Components/Common/Input/MultiChoiceSelectAnswer/options';

import { fetchOrgCatalogDetailsTabs, updateOrgVendorTabSectionItemData } from "redux/vendor/vendorCatalog/action";
import { updateOrgServiceTabSectionItemData } from "redux/vendor/serviceCatalog/action";
import { addNewRow } from "redux/vendor/table/action";
import { addOrgDocument } from "redux/product/orgProduct/action.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.workflowSummary.isShowEvaluationDataCopyDialog,
    orgProductDetailTabs: state.orgProduct.orgProductDetailTabs,
    orgProductDetailTabsProgress: state.orgProduct.orgProductDetailTabsProgress,
    isUpdatingOrgProductDetailTabsSectionItemData: state.orgProduct.isUpdatingOrgProductDetailTabsSectionItemData,
    orgProductDetailsUpdateProgress: state.orgProduct.orgProductDetailsUpdateProgress,

    orgCatalogDetailTabsProgress: state.vendorCatalog.orgCatalogDetailTabsProgress,
    orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,

    orgVendorTabSectionItemDataUpdateProgress: state.vendorCatalog.orgVendorTabSectionItemDataUpdateProgress,
    orgVendorTabSectionItemDataUpdateError: state.vendorCatalog.orgVendorTabSectionItemDataUpdateError,

    orgServiceTabSectionItemDataUpdateProgress: state.serviceCatalog.orgServiceTabSectionItemDataUpdateProgress,
    orgServiceTabSectionItemDataUpdateError: state.serviceCatalog.orgServiceTabSectionItemDataUpdateError,

    addNewRowProgress: state.catalogTable.addNewRowProgress,
    addNewRowError: state.catalogTable.addNewRowError,

    addOrgDocumentProgress: state.orgProduct.addOrgDocumentProgress,
    addOrgDocumentError: state.orgProduct.addOrgDocumentError,
});

const connectionActions = {
    hideDialog: hideEvaluationDataCopyDialog,
    fetchOrgProductDetailsTabs: fetchOrgProductDetailsTabs,
    showSnackBar: showSnackBar,
    updateOrgProdutDetailsTabSectionItemData: updateOrgProdutDetailsTabSectionItemData,
    updateOrgProductDetails: updateOrgProductDetails,
    fetchOrgCatalogDetailsTabs: fetchOrgCatalogDetailsTabs,
    updateOrgVendorTabSectionItemData: updateOrgVendorTabSectionItemData,
    updateOrgServiceTabSectionItemData: updateOrgServiceTabSectionItemData,
    addNewRow: addNewRow,
    addOrgDocument: addOrgDocument
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    heading: {
        fontWeight: 400,
        fontSize: 14,
    },
    body: {
        marginTop: 20,
        border: '1px solid #aeaeae',
        borderRadius: 4,
        padding: 20
    },
    section: {
        marginBottom: 20
    },
    ruleTable: {
        width: "100%",
        '& [class*=MuiSelect-root]': {
            minHeight: 22
        }
    },
    leftOp: {
        width: "45%",
        paddingTop: 10,
    },
    rightOp: {
        width: "45%",
        paddingTop: 10,
    },
    noPaddingTop: {
        paddingTop: 0
    },
    action: {
        width: '10%',
        textAlign: 'center'
    },
    addRuleDiv: {
        textAlign: 'center',
        marginTop: 50
    },
    bottomAction: {
        textAlign: 'right',
        marginTop: 20,
        marginBottom: 20,
    },
    actionBottomBtn: {
        marginLeft: 10
    },
    mappings: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    source: {
        flex: 1,
    },
    target: {
        flex: 1,
    },
    userRes: {
        flex: 1,
        marginLeft: 20,
        marginRight: 20
    },
    rules: {
        flex: 1,
        marginTop: 20
    }
});

class EvaluationDataCopyDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            productOptions: [],
            selectedProduct: '',
            ruleDatas: [],
            optionIdMap: {},
            fieldMaps: [],
            formItemOptions: [],
            selectedCatalogField: '',
            selectedSource: '',
            selectedUser: '',
            userOptions: [],
            isTableType: false,
            isContractType: false,
            tableFields: []
        }

        this.updateCatalogOptions = this.updateCatalogOptions.bind(this);
        this.checkCatalogFieldType = this.checkCatalogFieldType.bind(this);
    }

    componentDidMount() {
        this.initProducts();

    }

    componentDidUpdate(prevProps) {
        if (this.props.orgProductDetailTabsProgress === false && prevProps.orgProductDetailTabsProgress === true) {
            // console.log(this.props.orgProductDetailTabs, 'tabsssss-1')
            if (this.props.orgProductDetailTabs) {
                let allItems = [];
                this.props.orgProductDetailTabs.forEach(function (tab) {
                    tab.OrgProductTabSections.forEach(function (section) {
                        section.OrgProductTabItems.forEach(function (item) {
                            allItems.push({
                                ...item,
                                TabName: tab.TabName
                            })
                        })
                    })
                })
                let appCatType = getAppCatalogTypeUsingRequirementType();
                this.createOptionMapProduct(appCatType, allItems);
            }

        }

        if (this.props.orgCatalogDetailTabsProgress === false && prevProps.orgCatalogDetailTabsProgress === true) {
            // console.log(this.props.orgCatalogDetailTabs, 'tabsssss-2')
            if (this.props.orgCatalogDetailTabs) {
                let allItems = [];
                this.props.orgCatalogDetailTabs.forEach(function (tab) {
                    tab.sections.forEach(function (section) {
                        section.items.forEach(function (item) {
                            allItems.push({
                                ...item,
                                TabName: tab.name
                            })
                        })
                    })
                })
                let appCatType = getAppCatalogTypeUsingRequirementType();
                this.createOptionMapVendor(appCatType, allItems);
            }
        }

        if (this.props.isUpdatingOrgProductDetailTabsSectionItemData === false && prevProps.isUpdatingOrgProductDetailTabsSectionItemData === true) {
            this.props.showSnackBar("Product updated successfully.", "success", 3000)
            this.props.hideDialog()
        }

        if (this.props.orgProductDetailsUpdateProgress === false && prevProps.orgProductDetailsUpdateProgress === true) {
            this.props.showSnackBar("Product updated successfully.", "success", 3000)
            this.props.hideDialog()
        }

        if (!this.props.orgVendorTabSectionItemDataUpdateProgress && prevProps.orgVendorTabSectionItemDataUpdateProgress) {
            if (!this.props.orgVendorTabSectionItemDataUpdateError) {
                this.props.showSnackBar("Vendor updated successfully.", "success", 3000)
                this.props.hideDialog()
            }
        }

        if (!this.props.addNewRowProgress && prevProps.addNewRowProgress) {
            if (this.props.addNewRowError === null) {
                this.props.showSnackBar("Record added successfully.", "success", 3000);
                this.props.hideDialog()
            } else {
                this.props.showSnackBar("Something went wrong.", "error", 3000);
            }
        }

        if (!this.props.orgServiceTabSectionItemDataUpdateProgress && prevProps.orgServiceTabSectionItemDataUpdateProgress) {
            if (!this.props.orgServiceTabSectionItemDataUpdateError) {
                this.props.showSnackBar("Service updated successfully.", "success", 3000)
                this.props.hideDialog()
            } else {
                this.props.showSnackBar("Failed", "error", 3000);
            }
        }

        if (!this.props.addOrgDocumentProgress && prevProps.addOrgDocumentProgress) {
            if (this.props.addOrgDocumentError === null) {
                this.props.showSnackBar("Contract added successfully.", "success", 3000)
                this.props.hideDialog()
            } else {
                this.props.showSnackBar("Failed", "error", 3000);
            }
        }
    }

    initProducts() {
        const { reportData } = this.props;
        // console.log(reportData, 'reportData')
        let products = reportData.Products.map(o => {
            return {
                label: o.Name,
                value: o.ID,
                entityId: o.EntityID,
                type: o.Type
            }
        })

        let selectedProduct = '';
        if (this.props.selectedProduct === undefined || this.props.selectedProduct === null || this.props.selectedProduct === '') {
            selectedProduct = products[0];
        } else {
            selectedProduct = this.props.selectedProduct;
        }


        this.setState({
            selectedProduct: selectedProduct,
            productOptions: products,
        }, () => {
            this.getProductDetailsTabs();
        })
    }

    getProductDetailsTabs() {
        const { selectedProduct } = this.state;
        if (selectedProduct) {
            let selectedPrdType = selectedProduct.type;
            if (selectedPrdType === 'PRODUCT') {
                this.props.fetchOrgProductDetailsTabs()
            } else if (selectedPrdType === 'CUSTOM_VENDOR' || selectedPrdType === 'MASTER_VENDOR') {
                this.props.fetchOrgCatalogDetailsTabs("vendor");
            } else if (selectedPrdType === 'CUSTOM_VENDOR_SERVICE' || selectedPrdType === 'MASTER_VENDOR_SERVICE') {
                this.props.fetchOrgCatalogDetailsTabs("vendorservice");
            }
        }
    }

    initEvaluationSettings(type, isReturn = false) {
        const { reportData } = this.props;
        const { selectedProduct } = this.state;
        let criterias = reportData.AllCriterias;
        let responses = reportData.Responses;
        let sellerResponses = reportData.SellerResponses;
        let selectedProductId = selectedProduct.value;
        let formItemOptions = [];
        const validTypes = getRequirementTypeUsingAppCatalogType(type);
        // console.log(validTypes, 'validTypes')
        criterias.forEach(function (criteria) {
            criteria.requirements.forEach(function (req) {
                if (validTypes.indexOf(req.answer_type) === -1) {
                    return;
                }
                let obj = {
                    label: req?.name,
                    value: req?.id,
                    response: [],
                    sellerResponse: [],
                    type: req.answer_type,
                    data: req.options !== null && req.options.length > 0 ? req.options : [],
                    userOptions: []
                };
                let isAdd = false;
                let userOptions = [];
                if (responses[req.id] !== undefined && responses[req.id][selectedProductId] !== undefined && responses[req.id][selectedProductId].length > 0) {
                    obj.response = responses[req.id][selectedProductId];
                    isAdd = true;
                    responses[req.id][selectedProductId].forEach(function (res) {
                        userOptions.push({
                            response: res,
                            label: res.User.Name,
                            value: res.User.ID,
                            isSellerRes: false
                        })
                    })
                }

                if (sellerResponses[req.id] !== undefined && sellerResponses[req.id][selectedProductId] !== undefined && responses[req.id][selectedProductId] !== null) {
                    obj.sellerResponse = sellerResponses[req.id][selectedProductId];
                    isAdd = true;
                    userOptions.push({
                        response: sellerResponses[req.id][selectedProductId],
                        label: 'Vendor Response',
                        value: '9999',
                        isSellerRes: true
                    })
                }

                if (isAdd) {
                    obj.userOptions = userOptions;
                    formItemOptions.push(obj)
                }
            })
        })
        // console.log(formItemOptions,'requirementOptions')
        if (isReturn) {
            return formItemOptions;
        } else {
            this.setState({
                formItemOptions: formItemOptions
            })
        }
    }

    createOptionMapProduct(types, allItems) {
        var optionsData = [];
        var optionMap = {};
        var validTypes = types;
        let optionIdMap = {};
        if (allItems === undefined || allItems === null || allItems.length <= 0) {
            return;
        }

        allItems.forEach(function (item) {
            if (validTypes.indexOf(item.Type) !== -1) {
                let tabName = item.TabName;
                // let sectionName = section.SectionName;
                let itemName = item.ItemName;
                let itemId = item.ID;
                var parentOpName = tabName;
                // var parentOpName = tabName + ' - ' + sectionName;
                if (itemName === null || itemName === '') {
                    return;
                }
                let isCustom = item.ItemUID.indexOf('item-') !== -1 ? true : false;

                if (item.ItemUID.indexOf('prod_') !== -1 && catalogDefaultItemsForUpdate.indexOf(item.ItemUID) === -1) {
                    return;
                }

                if (optionMap[parentOpName] === undefined) {
                    optionMap[parentOpName] = {
                        label: parentOpName,
                        options: [{
                            label: itemName,
                            value: itemId,
                            type: item.Type,
                            isCustom: isCustom,
                            itemUID: item.ItemUID,
                            data: item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : []
                        }]
                    }
                } else {
                    optionMap[parentOpName].options.push({
                        label: itemName,
                        value: itemId,
                        type: item.Type,
                        isCustom: isCustom,
                        itemUID: item.ItemUID,
                        data: item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : []
                    })
                }

                optionIdMap[itemId] = {
                    label: itemName,
                    value: itemId,
                    type: item.Type,
                    itemUID: item.ItemUID,
                    isCustom: isCustom,
                    data: item.AdditionalData !== null && item.AdditionalData.length > 0 ? JSON.parse(item.AdditionalData) : []
                }
            }
        })


        optionMap['Contract Details'] = {
            label: 'Contract Details',
            options: [{
                label: 'Contracts, Agreements & POs',
                value: 'contracts',
                type: 100,
                isCustom: true,
                itemUID: 'contracts',
                data: []
            }]
        }

        optionIdMap['contracts'] = {
            label: 'Contracts, Agreements & POs',
            value: 'contracts',
            type: 100,
            itemUID: 'contracts',
            isCustom: true,
            data: [{
                label: 'Contract Name',
                value: 'title',
                type: 1,
                isCustom: true,
                itemUID: 'title',
                data: []
            }, {
                label: 'File',
                value: 'url',
                type: 9,
                isCustom: true,
                itemUID: 'url',
                data: []
            }]
        }


        Object.keys(optionMap).forEach(function (key) {
            optionsData.push(optionMap[key]);
        });
        this.setState({
            ruleDatas: optionsData,
            optionIdMap: optionIdMap
        })
    }

    createOptionMapVendor(types, allItems) {
        var optionsData = [];
        var optionMap = {};
        var validTypes = types;
        let optionIdMap = {};
        if (allItems === undefined || allItems === null || allItems.length <= 0) {
            return;
        }

        allItems.forEach(function (item) {
            if (validTypes.indexOf(item.type) !== -1) {
                let tabName = item.TabName;
                // let sectionName = section.SectionName;
                let itemName = item.name;
                let itemId = item.id;
                var parentOpName = tabName;
                // var parentOpName = tabName + ' - ' + sectionName;
                if (itemName === null || itemName === '') {
                    return;
                }
                let isCustom = item.slug.indexOf('custom-') !== -1 ? true : false;

                if (optionMap[parentOpName] === undefined) {
                    optionMap[parentOpName] = {
                        label: parentOpName,
                        options: [{
                            label: itemName,
                            value: itemId,
                            type: item.type,
                            isCustom: isCustom,
                            itemUID: item.slug,
                            data: item.additional_data !== null && item.additional_data.length > 0 ? JSON.parse(item.additional_data) : []
                        }]
                    }
                } else {
                    optionMap[parentOpName].options.push({
                        label: itemName,
                        value: itemId,
                        type: item.type,
                        isCustom: isCustom,
                        itemUID: item.slug,
                        data: item.additional_data !== null && item.additional_data.length > 0 ? JSON.parse(item.additional_data) : []
                    })
                }

                optionIdMap[itemId] = {
                    label: itemName,
                    value: itemId,
                    type: item.type,
                    itemUID: item.slug,
                    isCustom: isCustom,
                    data: item.additional_data !== null && item.additional_data.length > 0 ? JSON.parse(item.additional_data) : []
                }
            }
        })


        Object.keys(optionMap).forEach(function (key) {
            optionsData.push(optionMap[key]);
        });
        this.setState({
            ruleDatas: optionsData,
            optionIdMap: optionIdMap
        })
    }

    addNewFieldMap() {
        var fields = this.state.fieldMaps;
        var newData = [
            ...fields,
            {
                source: '',
                target: '',
                error: ''
            }]
        this.setState({
            fieldMaps: newData
        })
    }

    onSourceChange = (k, e) => {
        var fieldMaps = this.state.fieldMaps;
        var rule = fieldMaps[k];
        rule.source = e;
        rule.error = '';
        this.setState({
            fieldMaps: fieldMaps
        })
    }

    onTargetChange = (k, e) => {
        var fieldMaps = this.state.fieldMaps;
        var rule = fieldMaps[k];
        rule.target = e;
        rule.error = '';
        this.setState({
            fieldMaps: fieldMaps
        })
    }

    handleDelete = (k) => {
        var fieldMaps = this.state.fieldMaps;
        fieldMaps.splice(k, 1);
        this.setState({
            fieldMaps: fieldMaps
        })
    }

    updateCatalogOptions = () => {
        let appCatType = getAppCatalogTypeUsingRequirementType();
        this.createOptionMap(appCatType)
    }

    setSelectedProduct(product) {
        this.setState({
            selectedProduct: product,
            selectedSource: '',
            selectedCatalogField: ''
        }, () => {
            this.getProductDetailsTabs()
        })
    }

    checkCatalogFieldType() {
        const { selectedCatalogField, optionIdMap } = this.state;
        if (selectedCatalogField && selectedCatalogField !== '') {
            var isTableType = false;
            var isContractType = false;
            var tableFields = [];
            const optionData = optionIdMap[selectedCatalogField.value]
            // console.log(optionData, 'optionData')
            if (optionData.type === 6) {
                isTableType = true;
                tableFields = optionData.data.map((o) => {
                    return {
                        ...o,
                        selectedSource: '',
                        selectedUser: '',
                        userOptions: [],
                        sourceOptions: this.initEvaluationSettings(o.type, true)
                    }
                });
            } else if (optionData.type === 100) {
                isContractType = true;
                tableFields = optionData.data.map((o) => {
                    return {
                        ...o,
                        selectedSource: '',
                        selectedUser: '',
                        userOptions: [],
                        sourceOptions: this.initEvaluationSettings(o.type, true)
                    }
                });
            } else {
                isTableType = false;
                tableFields = []
            }
            this.setState({
                isContractType: isContractType,
                isTableType: isTableType,
                tableFields: tableFields
            })
            this.initEvaluationSettings(optionData.type);
        } else {
            this.setState({
                isContractType: false,
                isTableType: false,
                tableFields: [],
                selectedSource: '',
                selectedUser: '',
            })
        }
    }

    updateTableValues(field, value, index) {
        let tableFields = this.state.tableFields;
        let col = tableFields[index];
        col[field] = value;
        if (field === 'selectedSource') {
            col.selectedUser = '';
            col.userOptions = value.userOptions;
        }
        this.setState({
            tableFields: tableFields
        })
    }

    getUserOptions(selectedRequirement, isReturn) {
        this.setState({
            userOptions: selectedRequirement.userOptions
        })
        if (isReturn) {
            return selectedRequirement.userOptions;
        }
    }

    getResponseForVendor(finalResponse, selectedSource, selectedCatalogField, forTable = false) {
        // console.log(selectedSource.type, 'SourceType')
        // console.log(finalResponse, 'finalResponse')
        // console.log(selectedSource, 'selectedSource')
        let response = ''
        if (finalResponse) {
            if ([1, 7].includes(selectedSource.type)) {
                response = finalResponse.Note || ''
            } else if ([2, 3].includes(selectedSource.type)) {
                let selectedOption = finalResponse?.UserOptions.map(o => o.OptionID);
                let options = selectedSource?.data.filter(o => selectedOption.indexOf(o.ID) !== -1).map(o => (o.Title).trim())
                if (options && selectedCatalogField && selectedCatalogField.data && selectedCatalogField.data.length > 0) {
                    response = selectedCatalogField.data.filter(o => options.indexOf((o.label).trim()) !== -1)
                }
            } else if ([4].includes(selectedSource.type) && finalResponse.UserOptions && finalResponse.UserOptions.length > 0) {
                const finalAns = finalResponse.UserOptions[0]
                response = finalAns.Answer === 'Yes' || finalAns.Answer === 'yes' ? 'yes' : 'no'
            } else if ([5, 8, 9, 10, 11, 12].includes(selectedSource.type) && finalResponse.UserOptions && finalResponse.UserOptions.length > 0) {
                const finalAns = finalResponse.UserOptions[0]
                response = finalAns.Answer || ''
            } else if ([13].includes(selectedSource.type) && finalResponse.UserOptions && finalResponse.UserOptions.length > 0) {
                const finalAns = finalResponse.UserOptions[0]
                if (finalAns.Answer && finalAns.Answer !== '') {
                    response = JSON.parse(finalAns.Answer) || ''
                }
            } else if ([6].includes(selectedSource.type) && finalResponse.UserOptions && finalResponse.UserOptions.length > 0) {
                const finalAns = JSON.parse(finalResponse.UserOptions[0].Answer)
                // console.log(finalAns,'ForrrrrrrrrrrrFile')
                if (finalAns) {
                    response = {
                        "name": finalAns[0].name,
                        "link": finalAns[0].url,
                        "createdAt": new Date()
                    }
                }
            }
        }
        // console.log(response, 'Finalllllllll')
        console.log(selectedCatalogField, 'selectedCatalogField')

        return response;
    }

    getResponseForSoftProduct(finalResponse, selectedSource, selectedCatalogField, forTable = false) {
        console.log(selectedSource.type, 'SourceType')
        console.log(finalResponse, 'finalResponse')
        console.log(selectedSource, 'selectedSource')
        let response = '';
        let isAppendRes = false;
        if (finalResponse !== undefined && finalResponse !== null && finalResponse !== '') {
            if ([7, 1].includes(selectedSource.type)) {
                response = finalResponse?.Note;
            } else if (selectedSource.type === 4) {
                response = (finalResponse?.UserOptions[0]?.Answer === 'Yes' || finalResponse?.UserOptions[0]?.Answer === 'yes') ? 'yes' : 'no';
            } else if ([13].includes(selectedSource.type) && finalResponse.UserOptions && finalResponse?.UserOptions.length > 0) {
                response = JSON.parse(finalResponse?.UserOptions[0].Answer);
            } else if (selectedSource.type === 11) {
                let date = ''
                try {
                    date = finalResponse.UserOptions[0].Answer
                } catch { }
                response = date;
            } else if ([5, 8, 9, 10, 11, 12].includes(selectedSource.type)) {
                response = finalResponse.UserOptions[0].Answer;
            } else if ([2, 3, 14, 15].includes(selectedSource.type) && finalResponse.UserOptions !== undefined && finalResponse.UserOptions.length > 0) {
                // let selectedOption = finalResponse?.UserOptions.map(o => o.OptionID);
                // let options = selectedSource?.data.filter(o => selectedOption.indexOf(o.ID) !== -1).map(o => o.Title)
                // if (options !== undefined && options !== null && options.length > 0) {
                //     response = options.join(', ')
                // }
                let selectedOption = finalResponse?.UserOptions.map(o => o.OptionID);
                let options = selectedSource?.data.filter(o => selectedOption.indexOf(o.ID) !== -1).map(o => (o.Title).trim())
                if (options && selectedCatalogField && selectedCatalogField.data && selectedCatalogField.data.length > 0) {
                    response = selectedCatalogField.data.filter(o => options.indexOf((o.label).trim()) !== -1)
                }
            } else if ([6].includes(selectedSource.type)) {
                let fileData = null;
                try {
                    fileData = JSON.parse(finalResponse?.UserOptions[0]?.Answer)
                } catch { }
                if (fileData !== null) {
                    response = [{
                        name: fileData?.name,
                        link: fileData?.url,
                        createdAt: new Date()
                    }]
                    isAppendRes = true;
                }
            } else {
                response = finalResponse?.Note;
            }
        }
        console.log(selectedCatalogField, 'selectedCatalogField')

        return { response, isAppendRes };
    }

    updateDataToProduct() {
        let { selectedSource, selectedCatalogField, selectedProduct, selectedUser, tableFields, isTableType, isContractType } = this.state;

        let selectedPrdType = selectedProduct.type;
        console.log(isTableType, 'isTableType')
        console.log(isContractType, 'isContractType')
        if (isTableType || isContractType) {
            let isError = false;
            let errorMsg = '';
            if (tableFields) {
                tableFields.forEach(function (data) {
                    if (isError) {
                        return
                    }
                    if (data.selectedSource && (data.selectedSource.length <= 0 || data.selectedSource === '' || data.selectedSource === null)) {
                        isError = true
                        errorMsg = "Please select source."
                        return;
                    }

                    if (data.selectedUser && (data.selectedUser.length <= 0 || data.selectedUser === '' || data.selectedUser === null)) {
                        isError = true
                        errorMsg = "Please select user."
                        return;
                    }

                })
            }
            if (isError) {
                this.props.showSnackBar(errorMsg, "error", 3000)
                return;
            }
        } else {
            if (selectedSource.length <= 0 || selectedSource === '' || selectedSource === null) {
                this.props.showSnackBar("Please select source.", "error", 3000)
                return;
            }

            if (selectedUser.length <= 0 || selectedUser === '' || selectedUser === null) {
                this.props.showSnackBar("Please select user.", "error", 3000)
                return;
            }

            if (selectedCatalogField.length <= 0 || selectedCatalogField === '' || selectedCatalogField === null) {
                this.props.showSnackBar("Please select target.", "error", 3000)
                return;
            }
        }


        // if (selectedSource.type === 6) {
        //     console.log(selectedSource, 'selectedSource')
        //     console.log(selectedCatalogField, 'selectedCatalogField')
        // }

        if (!isTableType && !isContractType) {

            // console.log(selectedUser, 'selectedUser')
            let finalResponse = selectedUser.response;
            if (selectedUser.isSellerRes) {
                finalResponse.UserOptions = selectedUser.response.SellerOptions;
            }

            // let response = '';
            // let isAppendRes = false;


            if (selectedPrdType === 'PRODUCT') {


                const finalRes = this.getResponseForSoftProduct(finalResponse, selectedSource, selectedCatalogField, false)
                let response = finalRes.response;
                let isAppendRes = finalRes.isAppendRes;
                console.log(finalRes, 'FinalllllllllResponse')
                console.log(response, 'FinalllllllllResponse')
                if (selectedCatalogField.isCustom) {
                    this.props.updateOrgProdutDetailsTabSectionItemData(selectedProduct.value, selectedCatalogField.itemUID, {
                        values: [JSON.stringify({ data: response })],
                        append_response: isAppendRes
                    });
                } else {
                    var data = {};
                    let field = getItemDefaultField(selectedCatalogField.itemUID)
                    data[field] = response;
                    this.props.updateOrgProductDetails(selectedProduct.value, data);
                }
            } else {
                let response = this.getResponseForVendor(finalResponse, selectedSource, selectedCatalogField, false);
                let params = {
                    value: response,
                }

                // console.log(response,'FinalResponse')
                if (selectedPrdType === 'CUSTOM_VENDOR' || selectedPrdType === 'MASTER_VENDOR') {
                    this.props.updateOrgVendorTabSectionItemData(selectedProduct.entityId, selectedCatalogField.itemUID, params);
                } else if (selectedPrdType === 'CUSTOM_VENDOR_SERVICE' || selectedPrdType === 'MASTER_VENDOR_SERVICE') {
                    this.props.updateOrgServiceTabSectionItemData(selectedProduct.entityId.split('-')[0], selectedCatalogField.itemUID, params);
                }
            }
        } else if (isContractType) {
            let title = '';
            let url = '';
            tableFields.forEach((td) => {
                let selectedUser = td.selectedUser;
                let selectedSource = td.selectedSource;
                let finalResponse = selectedUser.response;
                if (selectedUser.isSellerRes) {
                    finalResponse.UserOptions = selectedUser.response.SellerOptions;
                }

                if (finalResponse) {
                    if ([7].includes(selectedSource.type)) {
                        title = finalResponse.Note || '';
                    } else if ([6].includes(selectedSource.type)) {
                        let data = finalResponse.UserOptions[0].Answer;
                        if (data) {
                            let fData = JSON.parse(data)
                            if (fData) {
                                url = fData[0].url
                            }
                        }
                    }
                }
            })
            let params = {
                "documents":
                    [{
                        "description": "",
                        "title": title,
                        "url": url,
                    }]
            }
            // console.log(params, 'ContractParams')
            this.props.addOrgDocument(
                selectedProduct.value,
                params
            );
        } else {
            if (selectedPrdType === 'PRODUCT') {
                // console.log(tableFields, 'tableFields-2')
                let responseData = tableFields.map((td) => {
                    let selectedUser = td.selectedUser;
                    let selectedSource = td.selectedSource;
                    let finalResponse = selectedUser.response;
                    if (selectedUser.isSellerRes) {
                        finalResponse.UserOptions = selectedUser.response.SellerOptions;
                    }
                    //  [5, 6, 8, 10, 11]  // Table
                    const finalRes = this.getResponseForSoftProduct(finalResponse, selectedSource, selectedCatalogField, false)
                    let response = finalRes.response;
                    return {
                        label: response,
                        value: td.id,
                        id: td.id,
                        type: td.type
                    }
                });
                // console.log(responseData, 'responseData')

                let paramsD = JSON.stringify({ data: [responseData] })
                // console.log(paramsD, 'paramsD')

                this.props.updateOrgProdutDetailsTabSectionItemData(selectedProduct.value, selectedCatalogField.itemUID, {
                    values: [paramsD],
                    append_response: true
                });
            } else {
                let responseData = tableFields.map((td) => {
                    let selectedUser = td.selectedUser;
                    let selectedSource = td.selectedSource;
                    let finalResponse = selectedUser.response;
                    if (selectedUser.isSellerRes) {
                        finalResponse.UserOptions = selectedUser.response.SellerOptions;
                    }
                    let response = this.getResponseForVendor(finalResponse, selectedSource, selectedCatalogField, false);
                    return {
                        label: response,
                        value: td.id,
                        id: td.id,
                        type: td.type
                    }
                })

                let params = {
                    "item_slug": selectedCatalogField.itemUID,
                    "entity_id": selectedProduct.entityId,
                    "entity_type": selectedPrdType === 'CUSTOM_VENDOR' || selectedPrdType === 'MASTER_VENDOR' ? 'vendor' : 'vendorservice',
                    "row_data": [
                    ]
                }
                responseData.forEach(function (dt) {
                    params.row_data.push({
                        label: dt.id,
                        value: dt.label
                    })
                })
                // console.log(params, 'FinalParamssssssss')
                this.props.addNewRow(params)
            }

        }
        // console.log(response,'response')
        // console.log(selectedSource,'selectedSource')


    }

    render() {
        const classes = this.props.classes;
        const { productOptions, selectedProduct, fieldMaps, ruleDatas, formItemOptions,
            selectedCatalogField, selectedUser, selectedSource, userOptions,
            isTableType, isContractType, tableFields } = this.state;

        let isUpdateGoingOn = false;
        if (this.props.addNewRowProgress || this.props.orgServiceTabSectionItemDataUpdateProgress || this.props.orgVendorTabSectionItemDataUpdateProgress || this.props.isUpdatingOrgProductDetailTabsSectionItemData) {
            isUpdateGoingOn = true;
        }
        // console.log(selectedCatalogField, 'selectedCatalogField-1')
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Copy Data to Catalog</Typography>
                {<div className={classes.body}>
                    <div className={classes.section}>
                        <Typography variant={"h4"} className={classes.heading}>Select Product</Typography>
                        <Select
                            isMulti={false}
                            labelId="singleSelect-select-label"
                            id="singleSelect-select"
                            value={selectedProduct}
                            disabled={productOptions.length <= 0 ? true : false}
                            onChange={(e) => {
                                this.setSelectedProduct(e)
                            }}
                            options={productOptions}
                            placeholder={"Select a Product"}
                        >
                        </Select>
                    </div>
                    <div className={classes.mappings}>
                        <div className={classes.source}>
                            <ReactSelect
                                isClearable
                                menuPosition='fixed'
                                isSearchable={true}
                                options={ruleDatas}
                                classNamePrefix="select"
                                placeholder="Select Catalog Field"
                                onChange={(e) => {
                                    // console.log(e, 'Selected Dataaa')
                                    this.setState({
                                        selectedCatalogField: e,
                                        selectedSource: '',
                                        selectedUser: ''
                                    }, () => {
                                        this.checkCatalogFieldType()
                                    })
                                }}
                                value={selectedCatalogField}
                            />
                        </div>
                        {!isTableType && !isContractType && <div className={classes.userRes}>
                            <ReactSelect
                                isClearable
                                menuPosition='fixed'
                                isSearchable={true}
                                options={formItemOptions}
                                classNamePrefix="select"
                                placeholder="Select Question"
                                onChange={(e) => {
                                    this.setState({
                                        selectedSource: e,
                                        selectedUser: ''
                                    }, () => {
                                        this.getUserOptions(e, false)
                                    })
                                }}
                                value={selectedSource}
                            />
                        </div>}
                        {!isTableType && !isContractType && <div className={classes.target}>
                            <ReactSelect
                                isClearable
                                menuPosition='fixed'
                                isSearchable={true}
                                options={userOptions}
                                classNamePrefix="select"
                                placeholder="Select User Response"
                                onChange={(e) => {
                                    this.setState({
                                        selectedUser: e,
                                        // selectedCatalogField: ''
                                    })
                                }}
                                value={selectedUser}
                            />
                        </div>}
                    </div>
                    {(isTableType || isContractType) && <div className={classes.mappings}>
                        {tableFields.length > 0 && <div className={classes.rules}>
                            <Typography variant={"h4"} className={classes.heading}>{isTableType ? 'Map with table columns' : 'Map with contract columns'}</Typography>
                            <table className={classes.ruleTable}>
                                <tbody>
                                    {tableFields.map((rule, k) => {
                                        return (
                                            <React.Fragment key={k}>
                                                <tr>
                                                    <td>
                                                        {rule.label}
                                                    </td>
                                                    <td className={classnames(classes.leftOp)}>
                                                        <ReactSelect
                                                            isClearable
                                                            menuPosition='fixed'
                                                            isSearchable={true}
                                                            options={rule.sourceOptions}
                                                            classNamePrefix="select"
                                                            placeholder="Select Question"
                                                            onChange={(e) => {
                                                                this.updateTableValues('selectedSource', e, k)
                                                            }}
                                                            value={rule.selectedSource}
                                                        />
                                                    </td>
                                                    <td className={classnames(classes.rightOp)}>
                                                        <ReactSelect
                                                            isClearable
                                                            menuPosition='fixed'
                                                            isSearchable={true}
                                                            options={rule.userOptions}
                                                            classNamePrefix="select"
                                                            placeholder="Select User Response"
                                                            onChange={(e) => {
                                                                this.updateTableValues('selectedUser', e, k)
                                                            }}
                                                            value={rule.selectedUser}
                                                        />
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </div>}
                </div>}
                {false && <div className={classes.body}>
                    <div className={classes.section}>
                        <Typography variant={"h4"} className={classes.heading}>Select Product</Typography>
                        <Select
                            isMulti={false}
                            labelId="singleSelect-select-label"
                            id="singleSelect-select"
                            value={selectedProduct}
                            disabled={productOptions.length <= 0 ? true : false}
                            onChange={(e) => {
                                this.setState({
                                    currencyType: e
                                })
                            }}
                            options={productOptions}
                            placeholder={"Select a Product"}
                        >
                        </Select>
                    </div>
                    <div>
                        {fieldMaps.length > 0 && <div className={classes.rules}>
                            <table className={classes.ruleTable}>
                                <tbody>
                                    {fieldMaps.map((rule, k) => {
                                        return (
                                            <React.Fragment key={k}>
                                                {rule.error !== null && <tr>
                                                    <td colSpan="4" style={{ width: '100%' }}>
                                                        {rule.error !== null && <span style={{ color: 'red' }}>{rule.error}</span>}
                                                    </td>
                                                </tr>}
                                                <tr>
                                                    <td className={classnames(classes.leftOp, rule.error !== null ? classes.noPaddingTop : '')}>
                                                        <ReactSelect
                                                            isClearable
                                                            menuPosition='fixed'
                                                            isSearchable={true}
                                                            options={formItemOptions}
                                                            classNamePrefix="select"
                                                            placeholder="Select Source"
                                                            onChange={(e) => {
                                                                this.onSourceChange(k, e);
                                                            }}
                                                            value={rule.source}
                                                        />
                                                    </td>
                                                    <td className={classnames(classes.rightOp, rule.error !== null ? classes.noPaddingTop : '')}>
                                                        <ReactSelect
                                                            isClearable
                                                            menuPosition='fixed'
                                                            isSearchable={true}
                                                            options={ruleDatas}
                                                            classNamePrefix="select"
                                                            placeholder="Select Target"
                                                            onChange={(e) => {
                                                                this.onTargetChange(k, e);
                                                            }}
                                                            value={rule.target}
                                                        />
                                                    </td>
                                                    <td className={classes.action}>
                                                        <span>
                                                            <IconButton size="small" onClick={() => { this.handleDelete(k) }}>
                                                                <Image alt="Delete" src={deleteIcon} />
                                                            </IconButton>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>}

                        {<div className={classes.addRuleDiv}>
                            <SqureButton
                                cls={classes.actionBtn}
                                variant={"outlined"}
                                onClick={() => {
                                    this.addNewFieldMap();
                                }}>
                                + Add Field Mapping
                            </SqureButton>
                        </div>}
                    </div>
                </div>}
                <div className={classes.bottomAction}>
                    <SqureButton
                        cls={classes.actionBottomBtn}
                        variant={"outlined"}
                        onClick={() => {
                            this.props.hideDialog();
                        }}>
                        Cancel
                    </SqureButton>
                    <SqureButton
                        cls={classes.actionBottomBtn}
                        variant={"contained"}
                        onClick={() => {
                            this.updateDataToProduct();
                        }}>
                        {isUpdateGoingOn ? <CircularProgress style={{ width: 27, height: 27 }} /> : 'Save'}
                    </SqureButton>
                </div>
            </DialogContent>
        </Dialog>
    }
}

EvaluationDataCopyDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(EvaluationDataCopyDialog));