import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, CircularProgress } from "@material-ui/core";

import { showSnackBar } from "redux/snackbar/action";

import { generate } from 'shortid';
import SqureButton from 'Components/Common/SqureButton';
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FormBuilder from "./FormBuilder";
import CloseIcon from '@material-ui/icons/Close';
import { fetchMasterForms } from "redux/forms/master/action";

import { getFormSequenceMap, getSortByCreatedDate, getFormSectionSeqOriginal, checkSectionItemsConditions } from "util/FormTypeOptions";

import {
  fetchOrgForms,
  addOrgFormSection,
  updateOrgFormSection,
  fetchOrgFormSections,
  deleteOrgFormSection,
  addOrgFormSectionItem,
  updateOrgFormSectionItem,
  fetchOrgFormSectionItems,
  deleteOrgFormSectionItem,
  updateOrgFormSectionSequence,
  updateOrgFormSectionItemSequence
} from "redux/forms/org/action";


const connectedProps = (state) => ({
  addOrgFormSectionProgress: state.orgForms.addOrgFormSectionProgress,
  updateOrgFormSectionProgress: state.orgForms.updateOrgFormSectionProgress,
  deleteOrgFormSectionProgress: state.orgForms.deleteOrgFormSectionProgress,
  fetchOrgFormSectionsProgress: state.orgForms.fetchOrgFormSectionsProgress,
  addOrgFormSectionItemProgress: state.orgForms.addOrgFormSectionItemProgress,
  addOrgFormSectionItemError: state.orgForms.addOrgFormSectionItemError,
  addOrgFormSectionItemSuccess: state.orgForms.addOrgFormSectionItemSuccess,
  updateOrgFormSectionItemProgress: state.orgForms.updateOrgFormSectionItemProgress,
  updateOrgFormSectionItemSuccess: state.orgForms.updateOrgFormSectionItemSuccess,
  updateOrgFormSectionItemError: state.orgForms.updateOrgFormSectionItemError,
  fetchOrgFormSectionItemProgress: state.orgForms.fetchOrgFormSectionItemProgress,
  deleteOrgFormSectionItemProgress: state.orgForms.deleteOrgFormSectionItemProgress,
  deleteOrgFormSectionItemError: state.orgForms.deleteOrgFormSectionItemError,
  newwlyAddedOrgSectionData: state.orgForms.newwlyAddedOrgSectionData,
  orgFormSections: state.orgForms.orgFormSections,
  masterForms: state.masterForms.forms,
  orgForms: state.orgForms.forms,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  addOrgFormSection: addOrgFormSection,
  updateOrgFormSection: updateOrgFormSection,
  fetchOrgFormSections: fetchOrgFormSections,
  deleteOrgFormSection: deleteOrgFormSection,
  addOrgFormSectionItem: addOrgFormSectionItem,
  updateOrgFormSectionItem: updateOrgFormSectionItem,
  fetchOrgFormSectionItems: fetchOrgFormSectionItems,
  deleteOrgFormSectionItem: deleteOrgFormSectionItem,
  fetchOrgForms: fetchOrgForms,
  updateOrgFormSectionSequence: updateOrgFormSectionSequence,
  updateOrgFormSectionItemSequence: updateOrgFormSectionItemSequence,
  fetchMasterForms: fetchMasterForms
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  answerType: {
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    marginTop: 30,
    placeContent: "start",
    alignItems: "center",
    maxWidth: "250px",
    "& label": {
      color: "#707070",
      marginRight: 20,
      fontSize: "14px",
      fontWeight: 500,
    },
    "& [class*='MuiSelect-root-']": {
      padding: "0.5em 0.75em",
      fontSize: "14px",
      color: "#707070",
      paddingRight: "32px",
    },
  },
  input: {
    minHeight: 38,
    padding: "2px 8px",
    border: "1px solid #cccccc",
    borderRadius: 3,
    fontSize: 15,
    color: "rgb(51, 51, 51)",
    width: '100%'
  },
  indexNumber: {
    width: 10,
    marginTop: 5,
  },
  inputSection: {
    display: "flex",
    marginTop: 21,
    justifyContent: "space-between",
  },
  requiredField: {
    color: "red",
    marginLeft: 5,
  },
  inputController: {
    marginLeft: 5,
    flex: 1,
  },
  image: {
    marginTop: 5,
    minWidth: 10,
    cursor: "pointer",
  },
  saveBtnSection: {
    position: "absolute",
    bottom: 0,
    right: 12,
    left: "auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: '#f1f1f1',
    padding: '10px'
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  newUserBtnDisabled: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    border: "none",
    cursor: "no-drop",
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  addMore: {
    fontSize: "12px",
    color: "#4175DF",
    cursor: "pointer",
    display: "inline-block",
    marginTop: 10,
  },
  label: {
    fontSize: 15,
    fontWeight: 500,
  },
  radioBox: {
    '& [class*=MuiFormControlLabel-label]': {
      fontSize: 15
    }
  },
  alerts: {
    marginTop: 10
  },
  tabs: {
    listStyle: "none",
    display: "flex",
    marginBottom: 10,
    padding: 0,
    marginTop: 0

  },
  tab: {
    borderBottom: "solid 1px #e0e0e0",
  },
  tabBtn: {
    padding: '1em',
    borderRadius: 0
  },
  activeBtn: {
    color: "#4A87F8",
    borderBottom: "solid 2px #4A87F8",
  },
  customSection: {
    marginTop: 17,
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
});

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNewSection: null,
      newTabSection: null,
      tabs: [],
      activeSection: null,
      customSections: [],
      customFormTitle: '',
      customFormDescription: '',
      isShowCustomForm: false,
      addingNewSectionProgress: false,
      updateSectionProgress: false,
      goToItem: null
    }
    this.onSectionDragEnd = this.onSectionDragEnd.bind(this)

  }

  componentDidMount() {
    this.props.fetchMasterForms(0, 50);
    this.props.fetchOrgForms(0, 200);
    this.initSettings();
    if (this.props.type === 'custom') {
      this.props.fetchOrgFormSections(this.props.orgFormData.ID);
    } else {
      this.setState({
        customSections: this.props.orgFormData.MasterWorkflowTemplateFormsSection,
        isShowCustomForm: true
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      addOrgFormSectionProgress,
      updateOrgFormSectionProgress,
      fetchOrgFormSectionsProgress,
      deleteOrgFormSectionProgress,
      deleteOrgFormSectionItemProgress
    } = this.props;

    if (!fetchOrgFormSectionsProgress && prevProps.fetchOrgFormSectionsProgress === true) {
      if (this.props.orgFormSections !== null) {
        this.setState({
          customSections: this.props.orgFormSections
        }, () => {
          if (this.state.goToItem !== null) {
            console.log(this.state.goToItem, 'GoToItemmmmmmmmmmmmmmmmm')
            setTimeout(() => {
              document.getElementById(this.state.goToItem).scrollIntoView({ block: "center" });
              this.setState({
                goToItem: null
              })
            }, 1000)
          }
        })
      }

      this.setState({
        isShowCustomForm: true,
        addingNewSectionProgress: false,
        updateSectionProgress: false,
      })
    }

    if (!addOrgFormSectionProgress && prevProps.addOrgFormSectionProgress === true) {
      this.setState({
        newTabSection: null,
        activeSection: null,
        activeNewSection: null,
        addingNewSectionProgress: true
      }, () => {
        this.props.showSnackBar("New Section added successfully", "success", 3000);
        this.setState({
          goToItem: 'Section_Box_' + this.props.newwlyAddedOrgSectionData?.ID
        }, () => {
          this.props.fetchOrgFormSections(this.props.orgFormData.ID);
        })
      });
    }

    if (!updateOrgFormSectionProgress && prevProps.updateOrgFormSectionProgress === true) {
      this.setState({
        newTabSection: null,
        isShowCustomForm: true,
        activeSection: null,
        updateSectionProgress: true
      }, () => {
        this.props.showSnackBar("Section updated successfully", "success", 3000);
        this.props.fetchOrgFormSections(this.props.orgFormData.ID);
      })
    }

    if (!deleteOrgFormSectionProgress && prevProps.deleteOrgFormSectionProgress === true) {
      this.props.showSnackBar("Section deleted successfully", "success", 3000);
      this.props.fetchOrgFormSections(this.props.orgFormData.ID);
    }



    if (this.props.addOrgFormSectionItemProgress === false && prevProps.addOrgFormSectionItemProgress === true) {
      if (this.props.addOrgFormSectionItemError === null) {
        this.props.showSnackBar("Section item added successfully", "success", 3000);
        this.setState({
          goToItem: 'SectionItem_Box_' + this.props.addOrgFormSectionItemSuccess?.ID
        }, () => {
          this.props.fetchOrgFormSections(this.props.orgFormData.ID);
        })
      } else {
        this.props.showSnackBar("Failed to add section item", "error", 3000);
      }
    }

    if (this.props.updateOrgFormSectionItemProgress === false && prevProps.updateOrgFormSectionItemProgress === true) {
      if (this.props.updateOrgFormSectionItemError === null) {
        this.props.showSnackBar("Section item updated successfully", "success", 3000);
        this.props.fetchOrgFormSections(this.props.orgFormData.ID);
      } else {
        this.props.showSnackBar("Failed to update section item", "error", 3000);
      }
    }

    if (!deleteOrgFormSectionItemProgress && prevProps.deleteOrgFormSectionItemProgress === true) {
      if (this.props.deleteOrgFormSectionItemError === null) {
        this.props.showSnackBar("Section item deleted successfully", "success", 3000);
        this.props.fetchOrgFormSections(this.props.orgFormData.ID);
      } else {
        this.props.showSnackBar("Failed to delete section item", "error", 3000);
      }
    }

  }

  onSectionDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const { destination, draggableId, source } = result;
    if (draggableId && destination.index !== source.index) {
      const itemIndex = parseInt(draggableId)
      const draggedSection = this.state.customSections[itemIndex];
      const destinationIndex = parseInt(destination.index);

      this.setState({
        isShowCustomForm: false
      })

      this.props.updateOrgFormSectionSequence(
        this.props.orgFormData.ID,
        draggedSection.ID,
        {
          seq: destinationIndex
        }
      )

    }
  }

  handleUpdateSectionSequence(sectionId, newSequence) {
    this.props.updateOrgFormSectionSequence(
      this.props.orgFormData.ID,
      sectionId,
      {
        seq: newSequence
      }
    )
  }

  handleUpdateSectionItemSequence(sectionId, itmId, newSequence) {
    this.props.updateOrgFormSectionItemSequence(
      this.props.orgFormData.ID,
      sectionId,
      itmId,
      {
        seq: newSequence
      }
    )
  }

  initSettings() {
    if (this.props.isShowNewSectionbtn === false) {
      this.setState({
        activeNewSection: true
      })
    }
  }

  handleCreateNewSection = (data) => {
    this.setState({
      activeSection: null
    }, () => {
      this.props.addOrgFormSection(
        this.props.orgFormData.ID,
        {
          name: data.section.name,
          description: data.section.desc,
          visibility: 1
        }
      )
    })
  }

  handleUpdateSection = (data, isCustom, sectionData) => {
    const { newTabSection } = this.state;
    if (newTabSection === null) {

      this.setState({
        newTabSection: isCustom ? { ...data, ...sectionData } : null,
        // isShowCustomForm : false
      }, () => {
        this.props.updateOrgFormSection(
          this.props.orgFormData.ID,
          sectionData.ID,
          {
            name: data.name,
            description: "",
            visibility: data.visibility
          }
        )
      })
    }
  }

  handleUpdateSectionNew = (data, isCustom, sectionData) => {
    this.props.updateOrgFormSection(
      this.props.orgFormData.ID,
      sectionData.ID,
      {
        name: data.section.name,
        description: data.section.desc,
      }
    )
  }

  handleDeleteSection = (sectionData) => {
    this.setState({ activeSection: null }, () => {
      this.props.deleteOrgFormSection(
        this.props.orgFormData.ID,
        sectionData.ID)
    })
  }

  handleDeleteItem = (sectionData, itemData) => {
    this.setState({ activeSection: null }, () => {
      this.props.deleteOrgFormSectionItem(
        this.props.orgFormData.ID,
        sectionData.ID,
        itemData.itemId)
    })
  }

  addNewSectionItem(sectionData, data) {
    const item = data?.item || null;
    if (item !== null) {
      const timestamp = (new Date()).getTime();
      let itemSlug = item.name.replace(/[^a-zA-Z0-9]/g, '_') + '_' + timestamp;
      this.props.addOrgFormSectionItem(
        this.props.orgFormData?.ID,
        sectionData.ID,
        {
          name: item.name,
          slug: itemSlug,
          description: item.description,
          visibility: 1,
          type: item.type,
          additional_data: JSON.stringify(item.additional_data),
          is_multiselect: item.is_multiselect,
          is_required: item.is_required,
          seq: 1,
          is_other_option: item.is_other_option
        }
      )
    }
    this.setState({
      newTabSection: null,
      activeSection: null,
      activeNewSection: null,
      addingNewSectionProgress: true
    });
  }

  updateSectionItem(sectionData, data) {
    const item = data?.item || null;
    if (item !== null) {
      var itemSlug = item?.slug || "";
      if (itemSlug === '') {
        const timestamp = (new Date()).getTime();
        itemSlug = item.name.replace(/[^a-zA-Z0-9]/g, '_') + '_' + timestamp;
      } else {
        itemSlug = itemSlug.replace(/[^a-zA-Z0-9]/g, '_');
      }

      const newData = {
        name: item.name,
        slug: itemSlug,
        description: item.description,
        visibility: item.visibility,
        type: item.type,
        additional_data: JSON.stringify(item.additional_data),
        is_multiselect: item.is_multiselect,
        is_required: item.is_required,
        seq: item.seq,
        is_other_option: item.is_other_option
      }
      console.log(item, 'UpdateItemData')

      this.props.updateOrgFormSectionItem(
        this.props.orgFormData?.ID,
        sectionData.ID,
        item.itemId,
        newData
      )
    }

    this.setState({
      newTabSection: null,
      isShowCustomForm: true,
      activeSection: null,
      updateSectionProgress: true
    })
  }

  render() {
    const classes = this.props.classes;

    const { activeSection, activeNewSection } = this.state;

    // let tabView = getSortByCreatedDate(this.state.customSections)
    let tabView = this.state.customSections

    if (this.state.updateSectionProgress) {
      return <div style={{ textAlign: 'center', marginTop: 20 }}>
        <CircularProgress />
      </div>
    }

    let formType = this.props.type !== undefined ? this.props.type : 'custom';
    let sectionSeqMap = getFormSectionSeqOriginal(this.state.customSections);
    let sectionViewMap = getFormSequenceMap(this.state.customSections);
    let isConditionsAdded = checkSectionItemsConditions(this.state.customSections, formType);

    return (
      <div>

        {!this.state.isShowCustomForm && <div className={classes.loader}><CircularProgress /></div>}

        {this.state.isShowCustomForm && <div className={classes.customSection}>
          {!this.props.isEditForm && <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>}
          <Grid container>
            <Grid lg={12} md={12} sm={12} xs={12}>
              {tabView && (
                <>
                  {tabView?.map((obj, index) => (
                    <div>
                      <FormBuilder
                        config={obj}
                        id={`${obj.SectionName.toLowerCase().split(" ").join("-")}`}
                        key={generate()}
                        isNew={false}
                        sectionNumber={index + 1}
                        sectionIndex={index}
                        sectionID={obj.ID}
                        isLoader={false}
                        isCustom={obj.IsDeletable}
                        onDeleteSection={() => this.handleDeleteSection(obj)}
                        handleDeleteItem={itemData => this.handleDeleteItem(obj, itemData)}
                        onCancel={() => this.setState({ activeSection: null })}
                        isActive={activeSection !== null && activeSection === index}
                        isConditionsAdded={isConditionsAdded}
                        activeSection={(isEmpty = false) => {
                          if (isEmpty) {
                            this.setState({ activeSection: null })
                          } else {
                            if (activeSection !== index) {
                              this.setState({ activeSection: index })
                            }
                          }
                        }}
                        onUpdate={data => this.handleUpdateSection(data, obj.IsDeletable, obj)}
                        type={formType}
                        allSections={this.state.customSections}
                        masterForms={this.props.masterForms}
                        orgForms={this.props.orgForms}
                        isShowNewSectionBtn={this.props.type === 'custom' && activeNewSection === null && this.props.isShowNewSectionbtn === true && this.state.activeSection === null}
                        addNewSection={() => {
                          this.setState({
                            activeNewSection: true,
                            activeSection: null
                          })
                          setTimeout(() => {
                            document.getElementById('goToNewSectionCreation').scrollIntoView(false);
                          }, 100)
                        }}
                        showSnackBar={(msg, type) => {
                          this.props.showSnackBar(msg, type, 3000);
                        }}
                        onUpdateSection={(data) => {
                          this.handleUpdateSectionNew(data, obj.IsDeletable, obj);
                        }}
                        handleSaveItem={(data) => {
                          this.addNewSectionItem(obj, data);
                        }}
                        handleUpdateItem={(data) => {
                          this.updateSectionItem(obj, data);
                        }}
                        handleUpdateSectionSequence={(secId, seq) => {
                          this.handleUpdateSectionSequence(secId, seq);
                        }}
                        handleUpdateSectionItemSequence={(secId, itmId, seq) => {
                          this.handleUpdateSectionItemSequence(secId, itmId, seq);
                        }}
                        sectionViewMap={sectionViewMap}
                        sectionSeqMap={sectionSeqMap}
                      />
                    </div>
                  ))}
                </>
              )}

              {this.state.addingNewSectionProgress && <div style={{ textAlign: 'center', marginTop: 20 }}>
                <CircularProgress />
              </div>}

              {activeNewSection !== null && <FormBuilder
                isNew
                isLoader={false}
                isCustom={true}
                isActive={true}
                key={generate()}
                sectionNumber={tabView.length + 1}
                allSections={tabView}
                config={{ Visibility: 3 }}
                onSave={data => this.handleCreateNewSection(data)}
                onSaveSection={data => this.handleCreateNewSection(data)}
                onCancel={() => this.setState({ activeNewSection: null, activeSection: null })}
                activeSection={(isEmpty = false) => {
                  if (isEmpty) {
                    this.setState({ activeSection: null })
                  }
                }}
                masterForms={this.props.masterForms}
                orgForms={this.props.orgForms}
                showSnackBar={(msg, type) => {
                  this.props.showSnackBar(msg, type, 3000);
                }}
              />}

              {tabView && tabView.length <= 0 && activeNewSection === null && <Grid container justify="center">
                <SqureButton
                  variant={"contained"}
                  onClick={() => {
                    this.setState({
                      activeNewSection: true,
                      activeSection: null
                    })
                    setTimeout(() => {
                      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
                    }, 100)
                  }}
                >
                  + Form Section
                </SqureButton>
              </Grid>}

            </Grid>
          </Grid>
        </div>
        }
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Form));
