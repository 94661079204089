import React, { useState, useEffect } from "react";
import TreeMenu from "react-simple-tree-menu";
import {
  ListItem,
  TextField,
  List,
  Popover,
  Button,
  Grid
} from "@material-ui/core";
import { compose } from "recompose";
import { connect } from "react-redux";
import { createStyles, withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/delete.svg";

const connectedProps = (state) => ({
  contractFoldersData: state.orgProduct.contractFoldersData,
});

const connectionActions = {

};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  filterBtn: {
    background: '#fff',
    border: '1px solid #888',
    borderRadius: 4,
    minWidth: 200,
    color: '#222',
    boxShadow: 'none',
    padding: '4px 20px',
    fontSize: 16,
    '&:hover': {
      background: '#fff',
      color: '#222'
    }
  },
});

const DEFAULT_PADDING = 8;
const ICON_SIZE = 8;
const LEVEL_SPACE = 8;

const ToggleIcon = ({ on }) => <span style={{ marginRight: 8 }}>{on ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}</span>;

const FoldersTreeSelect = (props) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(()=>{
    setValue(props.value)
  },[props.value])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ListItemComp = ({
    level = 0,
    hasNodes,
    isOpen,
    label,
    key,
    searchTerm,
    openNodes,
    toggleNode,
    matchSearch,
    focused,
    ...props
  }) => (

    <ListItem
      {...props}
      className={props.classes?.listItem}
      style={{
        paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE + (!hasNodes ? 24 : 0),
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      {hasNodes && (
        <div
          style={{ display: 'inline-block' }}
          onClick={e => {
            hasNodes && toggleNode && toggleNode();
            e.stopPropagation();
          }}
        >
          <ToggleIcon on={isOpen} />
        </div>
      )}
      {label}
    </ListItem>
  );

  return (
    <>
      {value && value.label ? (<Grid container spacing={3} alignItems="center">
        <Grid item>
          <div style={{fontSize: 16}}>
            Selected Folder
          </div>
        </Grid>
        <Grid item>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: "15px",
            fontWeight: "500",
            textDecoration: 'underline',
            fontSize: 16
          }}
        >
          {value.label}
          <Image
            src={deleteIcon}
            alt="delete"
            style={{
              zIndex: 20,
              padding: 4,
              cursor: "pointer"
            }}
            onClick={() => {
              setValue(null);
              props.handleChange(null);
            }}
          />
        </div>
        </Grid>
      </Grid>) : (
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <div style={{fontSize: 16}}>
              Select Folder
            </div>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              aria-describedby={id}
              color="primary"
              onClick={handleClick}
              className={props.classes?.filterBtn}
            > 
              {value && value.label ? value.label : "Select"}
            </Button>
          </Grid>
        </Grid>
      )}
      <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <TreeMenu
            data={props.contractFoldersData?.meta || []}
            onClickItem={({ key, label }) => {
              setValue({key,label});
              props.handleChange({id: key?.split('/')?.pop(),label: label});
              handleClose();
            }}
            debounceTime={125}
          >
            {({ search, items }) => {
              return (
                <div style={{padding: 10}}>
                  <TextField
                    onChange={(e) => search(e.target.value)}
                    variant="outlined"
                    size="small"
                    placeholder="Search"
                  />
                  <List style={{maxHeight: 250, overflowY: 'auto'}}>
                    {items.map((props) => (
                      <ListItemComp {...props} />
                    ))}
                  </List>
                </div>
              );
            }}
          </TreeMenu>
        </Popover>
    </>
  );
};

export default connector(
  compose(withStyles(styles))(FoldersTreeSelect)
);
