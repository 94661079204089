export const Types = {
  /**
   *  Create Questions Start
   */

  START_ADD_REQIREMENT_OPTION: 'START_ADD_REQIREMENT_OPTION',
  END_ADD_REQIREMENT_OPTION: 'END_ADD_REQIREMENT_OPTION',

  START_GET_REQIREMENT_OPTIONS: 'START_GET_REQIREMENT_OPTIONS',
  END_GET_REQIREMENT_OPTIONS: 'END_GET_REQIREMENT_OPTIONS',

  START_UPDATE_REQIREMENT_OPTION: 'START_UPDATE_REQIREMENT_OPTION',
  END_UPDATE_REQIREMENT_OPTION: 'END_UPDATE_REQIREMENT_OPTION',

  START_DELETE_REQIREMENT_OPTION: 'START_DELETE_REQIREMENT_OPTION',
  END_DELETE_REQIREMENT_OPTION: 'END_DELETE_REQIREMENT_OPTION',

  QUIZ_OPTIONS: 'QUIZ_OPTIONS'

}