import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment"

export const SHOW_ADD_OAUTH_DIALOG = "SHOW_ADD_OAUTH_DIALOG";
export const HIDE_ADD_OAUTH_DIALOG = "HIDE_ADD_OAUTH_DIALOG";

export const SHOW_SCOPE_ACCESS_DIALOG = "SHOW_SCOPE_ACCESS_DIALOG";
export const HIDE_SCOPE_ACCESS_DIALOG = "HIDE_SCOPE_ACCESS_DIALOG";

export const START_ADD_NEW_OAUTH = "START_ADD_NEW_OAUTH";
export const END_ADD_NEW_OAUTH = "END_ADD_NEW_OAUTH";

export const START_UPDATE_OAUTH = "START_UPDATE_OAUTH";
export const END_UPDATE_OAUTH = "END_UPDATE_OAUTH";

export const START_FETCH_OAUTHS = "START_FETCH_OAUTHS";
export const END_FETCH_OAUTHS = "END_FETCH_OAUTHS";

export const START_DELETE_OAUTH = "START_DELETE_OAUTH";
export const END_DELETE_OAUTH = "END_DELETE_OAUTH";

export const START_FETCH_OAUTH_DATA="START_FETCH_OAUTH_DATA";
export const END_FETCH_OAUTH_DATA="END_FETCH_OAUTH_DATA";

export const START_FETCH_OAUTH_CREDENCIALS_DATA="START_FETCH_OAUTH_CREDENCIALS_DATA";
export const END_FETCH_OAUTH_CREDENCIALS_DATA="END_FETCH_OAUTH_CREDENCIALS_DATA";

export const START_FETCH_OAUTH_SCOPES = "START_FETCH_OAUTH_SCOPES";
export const END_FETCH_OAUTH_SCOPES = "END_FETCH_OAUTH_SCOPES";

export const START_FETCH_MASTER_OAUTH_SCOPES = "START_FETCH_MASTER_OAUTH_SCOPES";
export const END_FETCH_MASTER_OAUTH_SCOPES = "END_FETCH_MASTER_OAUTH_SCOPES";

export const START_ADD_OAUTH_SCOPE = "START_ADD_OAUTH_SCOPE"
export const END_ADD_OAUTH_SCOPE = "END_ADD_OAUTH_SCOPE"

export const START_REMOVE_OAUTH_SCOPE = "START_REMOVE_OAUTH_SCOPE"
export const END_REMOVE_OAUTH_SCOPE = "END_REMOVE_OAUTH_SCOPE"

export function showAddOauthDialog() {
    return {
        type: SHOW_ADD_OAUTH_DIALOG,
        payload: {}
    };
}

export function hideAddOauthDialog() {
    return {
        type: HIDE_ADD_OAUTH_DIALOG,
        payload: {}
    };
}

export function showScopeAccessDialog(data) {
    return {
        type: SHOW_SCOPE_ACCESS_DIALOG,
        payload: {data}
    };
}

export function hideScopeAccessDialog() {
    return {
        type: HIDE_SCOPE_ACCESS_DIALOG,
        payload: {}
    };
}

function startAddNewOauth() {
    return {
        type: START_ADD_NEW_OAUTH,
        payload: {}
    };
}

function endAddNewOauth(success, error) {
    return {
        type: END_ADD_NEW_OAUTH,
        payload: {
            success, error
        }
    };
}

export function addNewOauth(data) {
    const api_server = Environment.api_host("USER");
    let domain = new URL(api_server);
    domain = domain.host
    let dataObj = {
        ...data,
        domain
    }
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/oauth/client?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewOauth());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, dataObj);
            dispatch(endAddNewOauth(response, error));
        } catch (e) {
            dispatch(endAddNewOauth(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startUpdateOauth() {
    return {
        type: START_UPDATE_OAUTH,
        payload: {}
    };
}

function endUpdateOauth(success, error) {
    return {
        type: END_UPDATE_OAUTH,
        payload: {
            success, error
        }
    };
}

export function updateOauth(oauthId,data) {
    const api_server = Environment.api_host("USER");
    let domain = new URL(api_server);
    domain = domain.host
    let dataObj = {
        name: data.name,
        email: data.email,
        status: data.status,
        domain
    }
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/oauth/client/${oauthId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateOauth());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, dataObj);
            dispatch(endUpdateOauth(response, error));
        } catch (e) {
            dispatch(endUpdateOauth(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchOauths() {
    return {
        type: START_FETCH_OAUTHS,
        payload: {}
    };
}

function endFetchOauths(success, error) {
    return {
        type: END_FETCH_OAUTHS,
        payload: {
            success, error
        }
    };
}

export function fetchOauths() {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/oauth/client?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOauths());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url, false);
            dispatch(endFetchOauths(response, error));
        } catch (e) {
            dispatch(endFetchOauths(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startDeleteOauth() {
    return {
        type: START_DELETE_OAUTH,
        payload: null
    };
}

function endDeleteOauth(oauthId, success, error) {
    return {
        type: END_DELETE_OAUTH,
        payload: {
            success, error, oauthId
        }
    };
}

export function deleteOauth(oauthId) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/oauth/client/${oauthId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteOauth());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false);
            dispatch(endDeleteOauth(oauthId,response, error));
        } catch (e) {
            dispatch(endDeleteOauth(oauthId,null, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchOauthData() {
    return {
        type: START_FETCH_OAUTH_DATA,
        payload: {}
    };
}

function endFetchOauthData(success, error) {
    return {
        type: END_FETCH_OAUTH_DATA,
        payload: {
            success, error
        }
    };
}

export function fetchOauth(oauthId) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/oauth/client/${oauthId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOauthData());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOauthData(response, error));
        } catch (e) {
            dispatch(endFetchOauthData(null, {
                message: e.message
            }));
            return;
        }
    };
} 


function startFetchOauthCredencialsData() {
    return {
        type: START_FETCH_OAUTH_CREDENCIALS_DATA,
        payload: {}
    };
}

function endFetchOauthCredencialsData(success, error) {
    return {
        type: END_FETCH_OAUTH_CREDENCIALS_DATA,
        payload: {
            success, error
        }
    };
}

export function fetchOauthCredencials(oauthId) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/oauth/${oauthId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOauthCredencialsData());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOauthCredencialsData(response, error));
        } catch (e) {
            dispatch(endFetchOauthCredencialsData(null, {
                message: e.message
            }));
            return;
        }
    };
} 


function startFetchOauthScopes() {
    return {
        type: START_FETCH_OAUTH_SCOPES,
        payload: {}
    };
}

function endFetchOauthScopes(success, error) {
    return {
        type: END_FETCH_OAUTH_SCOPES,
        payload: {
            success, error
        }
    };
}

export function fetchOauthScopes(clientId) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/oauth/client/${clientId}/scopes?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchOauthScopes());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchOauthScopes(response, error));
        } catch (e) {
            dispatch(endFetchOauthScopes(null, {
                message: e.message
            }));
            return;
        }
    };
} 

function startFetchMasterOauthScopes() {
    return {
        type: START_FETCH_MASTER_OAUTH_SCOPES,
        payload: {}
    };
}

function endFetchMasterOauthScopes(success, error) {
    return {
        type: END_FETCH_MASTER_OAUTH_SCOPES,
        payload: {
            success, error
        }
    };
}

export function fetchMasterOauthScopes() {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/oauth/scope?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchMasterOauthScopes());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchMasterOauthScopes(response, error));
        } catch (e) {
            dispatch(endFetchMasterOauthScopes(null, {
                message: e.message
            }));
            return;
        }
    };
} 

function startAddOauthScope() {
    return {
        type: START_ADD_OAUTH_SCOPE,
        payload: {}
    };
}

function endAddOauthScope(success, error) {
    return {
        type: END_ADD_OAUTH_SCOPE,
        payload: {
            success, error
        }
    };
}

export function addOauthScope(oauthId,scopeId) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/oauth/client/${oauthId}/scope/${scopeId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddOauthScope());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url);
            dispatch(endAddOauthScope(response, error));
        } catch (e) {
            dispatch(endAddOauthScope(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startRemoveOauthScope() {
    return {
        type: START_REMOVE_OAUTH_SCOPE,
        payload: {}
    };
}

function endRemoveOauthScope(success, error) {
    return {
        type: END_REMOVE_OAUTH_SCOPE,
        payload: {
            success, error
        }
    };
}

export function removeOauthScope(oauthId,scopeId) {
    const api_server = Environment.api_host("USER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/oauth/client/${oauthId}/scope/${scopeId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startRemoveOauthScope());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endRemoveOauthScope(response, error));
        } catch (e) {
            dispatch(endRemoveOauthScope(null, {
                message: e.message
            }));
            return;
        }
    };
}


