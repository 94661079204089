/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const SHOW_IMPORT_LIBRARY_DIALOG = "SHOW_IMPORT_LIBRARY_DIALOG";
export const HIDE_IMPORT_LIBRARY_DIALOG = "HIDE_IMPORT_LIBRARY_DIALOG";

export const START_FETCH_SELLER_LIBRARY_QUESTION = "START_FETCH_SELLER_LIBRARY_QUESTION";
export const END_FETCH_SELLER_LIBRARY_QUESTION = "END_FETCH_SELLER_LIBRARY_QUESTION";

export const START_FETCH_GENIE_SUMMARY = "START_FETCH_GENIE_SUMMARY";
export const END_FETCH_GENIE_SUMMARY = "END_FETCH_GENIE_SUMMARY";

export const START_GENIE_QUESTION_SEARCH = "START_GENIE_QUESTION_SEARCH";
export const END_GENIE_QUESTION_SEARCH = "END_GENIE_QUESTION_SEARCH";

// Fetch Vendor Evaluation detais
export function showImportLibraryDialog() {
  return {
    type: SHOW_IMPORT_LIBRARY_DIALOG,
    payload: null,
  };
}

export function hideImportLibraryDialog() {
  return {
    type: HIDE_IMPORT_LIBRARY_DIALOG,
    payload: null,
  };
}

function startQuestionSearch(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_FETCH_SELLER_LIBRARY_QUESTION,
    payload: payload,
  };
}

function endQuestionSearch(success, error) {
  return {
    type: END_FETCH_SELLER_LIBRARY_QUESTION,
    payload: {
      success,
      error,
    },
  };
}

export function sellerQuestionSearch(queryParam) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/seller_question_search?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startQuestionSearch());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, queryParam, false);
      dispatch(endQuestionSearch(response, error));
    } catch (e) {
      dispatch(
        endQuestionSearch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startGenieQuestionSearch(questionIndex) {
  let payload = { questionIndex };
  return {
    type: START_GENIE_QUESTION_SEARCH,
    payload: payload,
  };
}

function endGenieQuestionSearch(success, error) {
  return {
    type: END_GENIE_QUESTION_SEARCH,
    payload: {
      success,
      error,
    },
  };
}

export function sellerGenieQuestionSearch(queryParam, questionIndex) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/seller_question_search?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startGenieQuestionSearch(questionIndex));
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, queryParam, false);
      dispatch(endGenieQuestionSearch(response, error));
    } catch (e) {
      dispatch(
        endGenieQuestionSearch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startCheckGenieSummary() {
  let payload = {};
  return {
    type: START_FETCH_GENIE_SUMMARY,
    payload: payload,
  };
}

function endCheckGenieSummary(success, error) {
  return {
    type: END_FETCH_GENIE_SUMMARY,
    payload: {
      success,
      error,
    },
  };
}

export function sellerCheckGenieSummary(assessment_id) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/genie_summary/${assessment_id}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startCheckGenieSummary());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endCheckGenieSummary(response, error));
    } catch (e) {
      dispatch(
        endCheckGenieSummary(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
