import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, Slide, Button } from "@material-ui/core";
import classnames from "classnames"; 
import { withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import DefaultImage from "assets/images/evaluation/default.svg";
import { fetchAddOrgProduct } from 'redux/product/orgProduct/action';
import { addProductState } from "redux/product/action";
// components
import Image from "Components/Common/image.jsx";
import ReactSelect from "react-select";
// redux
import { showSnackBar } from "redux/snackbar/action";
import { hideAddToExistingDialog } from "redux/vendor/action";
import { addOrgVendor } from "redux/vendor/vendorCatalog/action";
import { fetchEvaluationMetaData } from "redux/evaluation/metadata/action";
import { updateCatalogState } from "redux/vendor/action";
import { updateOrgCatalogState } from "redux/vendor/vendorCatalog/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({

  updateClusterGroupInprogress: state.clusterProductReducer.updateClusterGroupInprogress,
  updateClusterGroupSuccess: state.clusterProductReducer.updateClusterGroupSuccess,
  updateClusterGroupError: state.clusterProductReducer.updateClusterGroupError,

  evaluation: state.evaluationMetaData.evaluation,
  showAddToExistingDialog: state.vendors.showAddToExistingDialog,

  addProductStateProg: state.products.addProductStateProg,
  addProductStateError: state.products.addProductStateError,

  orgVendorAddProgress: state.vendorCatalog.orgVendorAddProgress,
  orgVendorAddError: state.vendorCatalog.orgVendorAddError,
  orgVendorAddSuccess: state.vendorCatalog.orgVendorAddSuccess,

  addOrgProductProgress: state.orgProduct.addOrgProductProgress,
  addorgProductStatus: state.orgProduct.addorgProductStatus,
  addorgProductStatusError: state.orgProduct.addorgProductStatusError,

  updateCatalogStateProgress: state.vendors.updateCatalogStateProgress,
  updateCatalogStateError: state.vendors.updateCatalogStateError,
  updateOrgCatalogStateProgress: state.vendorCatalog.updateOrgCatalogStateProgress,
  updateOrgCatalogStateError: state.vendorCatalog.updateOrgCatalogStateError,

});

const connectionActions = {
  showSnackBar: showSnackBar,
  hideAddToExistingDialog: hideAddToExistingDialog,
  addOrgVendor: addOrgVendor,
  addProductState: addProductState,
  fetchAddOrgProduct: fetchAddOrgProduct,
  fetchEvaluationMetaData: fetchEvaluationMetaData,
  updateCatalogState: updateCatalogState,
  updateOrgCatalogState: updateOrgCatalogState,
};

var connector = connect(connectedProps, connectionActions);

  const stateOption = [
    { label: "Existing", value: "existing" },
    { label: "Featured", value: "featured" },
    { label: "Limited Use", value: "limited"},
    { label: "Preferred", value: "preferred" },
    { label: "In Review", value: "in_review" },
    { label: "Evaluated", value: "evaluated" },
    { label: "Blocked", value: "blocked"},
    { label: "Deprecated", value: "deprecated" },
  ]

const styles = (theme) =>
  createStyles({
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginLeft: 20,
      fontWeight: 500,
      marginBottom: 0,
    },
    dialogContent: {
      textAlign: "center",
    },
    subTitle: {
      color: "#4B4B4B",
      fontSize: 13,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
      "& svg": {
        stroke: "white",
        fontSize: "2em",
      },
    },
    productContainer: {
      padding: "20px",
      paddingTop: 0,
      "& ul": {
        listStyle: "none",
        padding: "0px",
        "& li": {
          marginBottom: "16px",
        },
      },
    },
    item: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center flex-start",
      alignItems: "center",
    },
    productItem: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center space-between",
      alignItems: "center",
      boxShadow: "0px 2px 6px #00000012",
      borderRadius: "0.6em",
      padding: "10px",
      "& img": {
        width: "35px",
        height: "auto",
      },
    },
    formAction: {
      marginTop: "30px",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    // removeProduct: {
    //   color: "#dcd7d7",
    //   cursor: "pointer",
    // },
  });

class EvaluationAddToExistingDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStates: [],
    };
  }

  componentDidMount() {
    let count = this.props.evaluation?.Products?.length || 0
    let filledArr = Array(count).fill(null)
    this.setState({selectedStates: filledArr})
  }

  componentDidUpdate(prevProps) {
    if ((this.props.addOrgProductProgress === false && prevProps.addOrgProductProgress === true) || (this.props.updateCatalogStateProgress === false && prevProps.updateCatalogStateProgress === true )) {
        this.props.showSnackBar("Added successfully", "success", 3000);
        this.props.hideAddToExistingDialog();
        this.props.fetchEvaluationMetaData(this.props.match?.params?.evaluation_id)
    }
  }

  handleSubmit = () => {
    let submitable = false
    this.props.evaluation?.Products?.forEach((product,index)=>{
      if(!product.IsExisting && this.state.selectedStates[index]?.value){
        submitable = true
        if(product.Type === "PRODUCT"){
          let data = { 
            productId: parseInt(product.EntityID),
            name: product.Name,
            isExsting: true,
          }
          this.props.fetchAddOrgProduct(data, true);
          this.props.addProductState(product.EntityID, [this.state.selectedStates[index]?.value]);
        }else if(product.Type === "MASTER_VENDOR"){
          let data = {
            master_vendor_id: product.EntityID,
            states: [{name: this.state.selectedStates[index]?.value, description: ''}]
          }
          this.props.updateCatalogState(data);
        }else if(product.Type === "MASTER_VENDOR_SERVICE"){
          let ids = product.EntityID?.split('-')
          let data = {
            master_vendor_id: ids[0],
            states: [{name: this.state.selectedStates[index]?.value, description: ''}],
            entities: [{
              master_id: ids[1],
              type: 'service',
              states:[{name: this.state.selectedStates[index]?.value, description: ''}]
            }]
          }
          this.props.updateCatalogState(data);
        }
      }
    })
    if(submitable === false){
      this.props.showSnackBar("Please select tag first", "error", 3000);
    }
  }

  render() {
    const classes = this.props.classes;
    var name = "";
    this.props.evaluation?.Products?.forEach(function (product, k) {
      if(!product.IsExisting && (product.Type !== "MASTER_VENDOR_SERVICE")){
        if (k > 0) {
          name += " & ";
        }
        name += product.Name;
      }
    });

    return (
      <Dialog
        onClose={this.props.hideAddToExistingDialog}
        aria-labelledby="new-evaluation-dialog"
        open={this.props.showAddToExistingDialog}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"sm"}
        scroll={"body"}
        className={classnames(classes.dialog)}
      >
        <DialogTitle style={{width: '92%'}}>Do you want to add {name} to the catalog?</DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.hideAddToExistingDialog()}>
            <CloseIcon />
          </div>
          <div className={classes.productContainer}>
            
            <div className={classes.productList}>
              <ul>
                {this.props.evaluation?.Products?.map((product, index) => {
                  if(product.IsExisting || (product.Type === "MASTER_VENDOR_SERVICE")){
                    return null;
                  } else return (
                    <li key={index}>
                      <div className={classes.productItem}>
                        <div className={classes.item}>
                          <Image src={product.ProductLogo?.length > 0 ? product.ProductLogo : DefaultImage} />
                          <span style={{marginLeft: 10}}>{product.Name}</span>
                        </div>
                        <div style={{minWidth:170}}>
                          <ReactSelect
                            placeholder="Select Tag"
                            options={stateOption}
                            value={this.state.selectedStates[index]}
                            onChange={(data) => {
                              let newStates = [...this.state.selectedStates];
                              newStates[index] = data
                              this.setState({selectedStates: newStates})
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className={classes.formAction}>
              <Button
                onClick={() => {
                  this.handleSubmit();
                }}
                color="secondary"
                disabled={this.props.addOrgProductProgress || this.props.updateCatalogStateProgress}
                variant="contained"
                className={classes.button}
              >
                Save
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(EvaluationAddToExistingDialog));
