const toolTipJson = {
    0:{label: 'View all the products currently in Expent platform'},
    1:{label: 'Integrate with SSO or SAML providers to <br/> get visibility into your current vendor stack'},
    2:{label: 'Quick Snapshot of utilization of current vendor stack'},
    3:{label: `Quick Snapshot of evaluations`},
    4:{label: `Watch this space for new product announcements`},
    5:{label: `Products that are used <br/>  inside your organization`},
    6:{label: `Alternatives for products currently <br/>  used within your organization`},
    7:{label: `Call it Standard Template - covers the entire gamut of what <br/> questions are important for organizations to consider`},
    8:{label: `Call it Org Names' Template - Custom requirments <br/> created within the organization`},
    9:{label: `Products that have been added manually.  <br/>  These will be validated by Expent before  <br/>  they get added to all products`, wrapperStyle: {position: 'absolute',
        top: 6,
        right: 6}},
    10:{label: `Products that are used inside your organization  <br/>  - tap to view and select for evaluation`, wrapperStyle: {position: 'absolute',
    top: 6,
    right: 6}},
    11:{label: `Expent takes into consideration multiple factors such as - problem you are trying to solve,  <br/>current vendor stack, most important features and in general company's profile to recommend the best products`},
    12:{label: `Expent takes into consideration multiple factors such as - problem you are trying to solve, <br/> current vendor stack, most important features and in general company's profile <br/> to recommend the best products`},
    13:{label: `Explore other popular alternatives for  <br/> the problem you are trying to solve`},
    14:{label: `View all the products currently in Expent platform`},
    15:{label: `Search through the different products by <br/> the different categories they belong to.`},
    16:{label: `Select the amount of time you need to collect and <br/> finalize evaluation questions`},
    17:{label: `Select the amount of time you are going to spend <br/> evaluating solutions against your questions`},
    18:{label: `Invite all stakeholders that you want to collaborate with on this <br/> evaluation or Stakeholders who need visibility into this evaluation`},
    19:{label: `Add or remove products you would like to evaluate.  <br/> Add seller email address to invite sellers into this evaluation.`},
    20:{label: `Get started with the evaluation questions provided below, edit, <br/> delete or add questions based on your custom needs. Don't forget to <br/> save them by choose "Save as Template" when you make changes. `},
    21:{label: `Pick evaluation questions from a default library <br/> of templates or from your custom library. `},
    22:{label: `Create a new Section (for example - Legal) and <br/>  add a schedule to communicate your deadlines.`},
    23:{label: `Add your questions for the chosen <br/> criteira to evaluate vendors`},
    24:{label: `Add collaborators that would be responsible <br/> for evaluating this question`},
    25:{label: `Pick how important this question<br/>  is for your organization`},
    26:{label: `Pick how you want this question to be graded, pick pass/fail <br/> if it is a objective question and score if it is subjective`},
    27:{label: `Invite all stakeholders that you want to collaborate with on this evaluation`},
    28:{label: `Create a new Section (Example - Legal) and add a schedule to communicate your deadlines to collaborators and stakeholders.`},
    29:{label: `Add your questions against which you choose to evaluate vendors.`},
    30:{label: `Add collaborators that would be responsible for evaluating this question`},
    31:{label: `Pick how important this question is for your organization`},
    32:{label: `Pick how you want this question to be graded, pick pass/fail if it is a objective question and score if it is subjective`},
    33:{label: `Record your response here. Check out the community <br/> and seller tabs for pre-populated responses.`},
    34:{label: `You can see responses for some <br/> questions pre-populated by Expent`},
    35:{label: `If you invited seller into this evaluation, you can find their response here. <br/> If you did not invite seller, use the Ask Vendor button to invite them`},
    36:{label: `In case multiple collaborators are assigned to the <br/> same question you can find everyone's response here`},
    37:{label: `Drag and drop products based on your evaluation to indicate <br/> your preferred choice of vendor based on your evaluation`},
    38:{label: `Provide additional context on your product stack ranking`},
    39:{label: `Pick the vendor that you would like to procure using the check box and <br/> press the Get Approval button to send out Approval requests to all the stakeholders`},
    40:{label: `Below you'll find the feedback for the evaluation from <br/> all the collaborators and stakeholders.`},
    41:{label: `Recommended products for company.`},
    42:{label: `Vendors that are used inside your organization  <br/>  - tap to view`, wrapperStyle: {position: 'absolute',
    top: 6,
    right: 6}},
}

export default toolTipJson;