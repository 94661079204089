import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide} from '@material-ui/core';
import { hideProductRankingDialog } from "redux/evaluate/action";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import products_ranking_dialog from 'assets/images/products_ranking_dialog.png'
import Image from 'Components/Common/image.jsx';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    evaluate: state.evaluate,
    dataProductRanking: state.evaluate.dataProductRanking
});

const connectionActions = {
    hideProductRankingDialog: hideProductRankingDialog,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginLeft: 20,
        fontWeight: 500,
        marginBottom: 0
    },
    dialogContent: {
        textAlign: 'center'
    },
    subTitle: {
        color: '#4B4B4B',
        fontSize: 13,
    },
    dialog: {
        "& [class*=MuiDialog-paperWidth]": {
            maxWidth: 460,
            minHeight: 63,
            borderRadius: '10px'
        },
        // "& [class*=MuiDialog-paperWidthMd]": {
        //     maxWidth: 460,
        //     minHeight: 63,
        //     borderRadius: '10px'
        // },
        "& [class*=MuiDialogContent-root], & [class*=MuiDialogContent-root]:first-child": {
            padding: 0,
        },
        "& [class*=Step-labelTypo]": {
            fontSize: 13,
            textAlign: 'left'
        }
    },
     
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F',
        '& svg':{
            stroke: 'white',
            fontSize: '2em'
        }
    },
    rankingContainer: {
        padding: '20px 80px'
    },
    rankingTitle: {
        fontSize: '20px',
        fontWeight: 600
    },
    rankingList: {
        listStyle: 'none',
        padding: '0px'
    },
    rankingItem:{
        display: 'inline-block',
        width: '100%',
        border: '1px solid #EBEBEB',
        borderRadius: '4px',
        height: '40px',
        textAlign: 'left',
        marginBottom: '15px',
        textAlign: 'left',
        lineHeight: '40px',
        fontWeight: 400,
        letterSpacing: '0px',
        color: '#6D7278',
        paddingLeft: '24px',
        fontSize: '13px'
    },
    rankingFeedback:{
        //background: '#F4F6F8 0% 0% no-repeat padding-box',
        borderRadius: '7px',
        textAlign: 'left',
        fontWeight: 500,
        letterSpacing: '0.58px',
        color: '#7A7E81',
        // textTransform: 'capitalize',
        fontSize: '12px',
        minHeight: '70px',
        //padding: '10px'
        lineHeight: '24px',
        "& [class*='editableField']": {
            maxHeight: 'auto',
            minHeight: 'auto !important',
            margin: '0px !important',
        }
    },
    image: {
        width: '100%',
        maxWidth: '250px'
    }
});

class ProductsRankingDialog extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        console.log(this.props)
    }

    render() {
        const classes = this.props.classes;
        const {Products, Feedback} = this.props.dataProductRanking;

        return <Dialog 
                    onClose={this.props.hideProductRankingDialog} 
                    aria-labelledby="new-evaluation-dialog" 
                    open={this.props.evaluate?.dataProductRanking}
                    TransitionComponent={Transition}
                    fullWidth={true}
                    scroll={"body"}
                    className={classnames(classes.dialog)}
                    >
                    <DialogContent classes={{root:classes.dialogContent}}>
                        <div className={classes.close} onClick={() => this.props.hideProductRankingDialog()}><CloseIcon/></div>
                        <div className={classes.rankingContainer}>
                            <Image className={classes.image} src={products_ranking_dialog} alt="Product Ranking" />
                            <h3 className={classes.rankingTitle}>Product ranking</h3>
                            <ul className={classes.rankingList}>

                                {Products !== null && Products.map((product,k)=>{
                                    return  <li key={k}>
                                        <span className={classes.rankingItem}>{k+1}.&nbsp;&nbsp;&nbsp;&nbsp;{product.Name}</span>
                                    </li>;
                                })}

                            </ul>
                            <div className={classes.rankingFeedback}>
                                <SlateInputField 
                                    as={SlateInputField}
                                    isToolBar={false}
                                    readOnly={true}
                                    onChangeEvent={(value) => {
                                    }}
                                    initValue={Feedback && Feedback || ''}
                                    formControlStyle={{border: 'none'}}
                                    textContainerStyle={{ color: "#707070", }}/>
                            </div>
                        </div>                       
                    </DialogContent>
                </Dialog>
    }
}


export default connector(compose( withRouter, withStyles(styles) )(ProductsRankingDialog));