import { Backdrop, CircularProgress, CssBaseline } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import classnames from "classnames";
import CriteriaRuleDialog from 'Components/Common/CriteriaRuleDialog';
import React from 'react';
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { compose } from "recompose";
import { fetchEvaluationCart } from "redux/evaluation/cart/action";
import { fetchAllOrgSettings } from "redux/org/user/action";
import { getConfigValue, getUserConfigValue, isEnabledProjectTracking } from "util/Common";
import { authorizedUser, logout } from "../../redux/authuser/action";
import { updateDrawerStyle } from "../../redux/drawer/action";
import OrgUserUpdateDialog from "./../Common/OrgUserUpdateDialog";
import Snackbar from "./../Common/Snackbar";
import { withRouter } from "react-router-dom";
import CartDrawer from "./CartDrawer";
import Analytics from "./Components/Analytics/index";
import Dashboard from "./Components/Dashboard/index";
import Evaluate from "./Components/Evaluate";
import Feedback from "./Components/Feedback";
import EvaluationAndReview from "./Components/Home/User/index";
import Metrics from "./Components/Metrics/index";
import Notifications from "./Components/Notification/Notifications";
import Notification from "./Components/Notifications";
import Products from "./Components/Products";
import Vendors from "./Components/Vendors";
import ExternalVendors from "./Components/ExternalUser/Vendors";
import ExternalProducts from "./Components/ExternalUser/Products";
import ExternalServices from "./Components/ExternalUser/Services";
import Profile from "./Components/Profile";
import Review from "./Components/Review";
import Settings from "./Components/Settings/index";
import Survey from "./Components/Survey/index";
import Drawer from "./Drawer";
import { fireEvent } from "redux/common/action";
import Home from "./Components/Home/index";
import UserHome from "./Components/Home/User/home";
import CurrentProducts from "../Application/Components/Products/Components/AppCatalog";
import HomeDashBoard from "./Components/Home/dashboard"
import CommonCss from "commonCss";
import NotificationSocket from "Components/Application/Components/Notification/NotificationSocket";
import Renewal from './Components/Renewal';
import OtherWorkflows from './Components/OtherWorkflows';
import RenewalWorkflows from "./Components/RenewalWorkflows";

import Workflows from './Components/Workflows';
import Tracking from "./Components/Workflows/Tracking"
import { fetchUserConfig } from "redux/usersSettings/action";
import { getNewPlatform } from 'util/Environment';
import * as URL from "util/URL";
import DrawerV2 from "./DrawerV2"
const connectedProps = (state) => ({
    authProgress: state.authUser.authProgress,
    authError: state.authUser.authError,
    user: state.authUser.user,
    evaluationCart: state.evaluationCart.evaluation,
    orgUser: state.orgUser,
    fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
    organization: state.authUser.user?.Organization,
    logoutFromPage: state.authUser.logoutFromPage,

    userConfigs: state.usersSettings.userConfigs,
    fetchUserConfigProgress: state.usersSettings.fetchUserConfigProgress,
});

const connectionActions = {
    authorizedUser: authorizedUser,
    logout: logout,
    updateDrawerStyle: updateDrawerStyle,
    fetchAllOrgSettings: fetchAllOrgSettings,
    fetchEvaluationCart: fetchEvaluationCart,
    fireEvent: fireEvent,
    fetchUserConfig: fetchUserConfig
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        // display: 'flex',
    },
    content: {
        paddingTop: '5.25rem !important',
        flexGrow: 1,
        padding: theme.spacing(4, 10),
        background: "#F7F7F7",
        minHeight: "100vh",
        '@media (max-width: 991.98px)': {
            padding: '2em 2em 2em 8em'
        }
    },
    content2: {
        paddingTop: '5.25rem !important',
        flexGrow: 1,
        background: "#F7F7F7",
        minHeight: "100vh"
    },
    fullSceen: CommonCss.backdrop,
});

class Application extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.props.authorizedUser();
        this.props.fetchUserConfig();
        this.props.fetchAllOrgSettings();
        this.props.updateDrawerStyle(true, true);
        this.props.fetchEvaluationCart();
    }

    componentDidUpdate(prevProps) {
        if (this.props.authProgress === false && prevProps.authProgress === true) {
            // console.log(this.props.user, 'Usssssssseeerrr')
            let redirectTo = this.props.location.pathname + this.props.location.search + this.props.location.hash
            if (this.props.authError !== null) {
                var error = this.props.authError;
                console.log(error.message);
                if (error.message.indexOf("Token") !== -1) {

                    //LOGOUT
                    this.props.logout();
                    if (this.props.logoutFromPage === null) {
                        this.props.history.push("/?redirectTo=" + encodeURIComponent(redirectTo))
                    } else {
                        this.props.history.push("/")
                        window.location.reload();
                    }
                } else {
                    this.props.logout();
                    if (this.props.logoutFromPage === null) {
                        this.props.history.push("/?redirectTo=" + encodeURIComponent(redirectTo))
                    } else {
                        this.props.history.push("/")
                        window.location.reload();
                    }
                }
            } else {
                if (this.props.user == null) {
                    this.props.logout();
                    if (this.props.logoutFromPage === null) {
                        this.props.history.push("/?redirectTo=" + encodeURIComponent(redirectTo))
                    } else {
                        this.props.history.push("/")
                        window.location.reload();
                    }
                } else {
                    // const role = this.props.user.Role;
                    // const EndUserHelpLink = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'SettingName', 'EndUserHelpLink')
                    // if (document.getElementById("launcher") != null) {
                    //     if (EndUserHelpLink.trim().length > 0 && role === "OrgUser") {
                    //         console.log("I am here")
                    //         document.getElementById("launcher").style.visibility = "hidden";
                    //     } else {
                    //         document.getElementById("launcher").style.visibility = "visible";
                    //     }
                    // }

                    const IsInitialFlow = this.props.user.IsInitialFlow;
                    if (!IsInitialFlow) {
                        this.props.history.push("/app")
                    }
                }
            }
        }

        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.fireEvent({
                type: "event.user.page.view",
                data: {
                    url: this.props.location.pathname,
                },
            })
        }
        if (this.props.fetchAllOrgSettingsInprogress === false && prevProps.fetchAllOrgSettingsInprogress === true) {
            const EnableVendorCatalogV2 = isEnabledProjectTracking(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableVendorCatalogV2");
            if (EnableVendorCatalogV2 && (window?.location?.pathname === "/app" || window?.location?.pathname === "/app/home")) {
                let link = `${getNewPlatform()}/home`
                window.open(link, "_self");
            } else {
                this.setState({ loading: false })
            }
        }
    }

    render() {
        const classes = this.props.classes;

        if (this.state.loading) {
            return <Backdrop className={classes.fullSceen} open={true} transitionDuration={100}>
                <CircularProgress />
            </Backdrop>
        }

        const isCart = this.props.evaluationCart !== null && this.props.evaluationCart !== '' && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length > 0;
        var location = this.props.location.pathname;

        let userRole = ["OrgAdmin", "OrgManager"];
        const adminFlag = userRole.indexOf(this.props.user?.Role) > -1;

        // var isShowPadding = location.indexOf('/app/evaluate') == -1 && location.indexOf('/setup') == -1 && location.indexOf('/summary') == -1 && location.indexOf('/respond') == -1 ? true : false;
        // var isShowPadding = location.indexOf('/app/evaluate') === -1 && location.indexOf('/app/survey') === -1 && location.indexOf('/app/notifications') === -1 ? true : false;
        var isShowPadding = false;

        if (location.indexOf('/app/custom-fields') > -1) {
            isShowPadding = false
        }
        const preg_match = (regex, str) => {
            return (new RegExp(regex).test(str))
        }
        if (this.props.user == null) {
            return null;
        }
        const allowed_path = [
            "^/app/products/my-wishlisted$",
            "^/app/products/org-wishlisted$",
            "^/app/products/company-recommendation$",
            "^/app/products/all-products$",
            "^/app/products/recommended-products$",
            "^/app/products/org-products$",
            "^/app/products$",
            "^/app/products/category-wise-product/([0-9]+)$",
            "^/app/products/product-details/([0-9]+)$",
            "^/app/evaluate$",
            "^/app/vendors/all-vendors$",
            "^/app/vendors/org-vendors$",
            "^/app/vendors/all-vendors/([0-9]+)$",
            "^/app/vendors/services$",
            "^/app/vendors/org-services$",
            "^/app/vendors/services/([0-9]+)$",
            "^/app/vendors/([0-9]+)/service/([0-9]+)"
        ]

        let showCart = false;
        for (let regex of allowed_path) {
            if (preg_match(regex, this.props.location.pathname)) {
                showCart = true;
            }
        }



        var HomePageValue = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'SettingName', 'HomePage') || 'true';
        HomePageValue = HomePageValue === 'true' ? true : false;

        var HomePageAdminValue = getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'SettingName', 'HomePageAdmin') || 'true';
        let HomePageConfig = getUserConfigValue(this.props.userConfigs, "homePageAdmin");

        let Component = Home

        if (HomePageConfig && HomePageConfig.Value?.length > 0) {
            if (HomePageConfig.Value === '2') {
                Component = CurrentProducts
            } else if (HomePageConfig.Value === '3') {
                Component = UserHome
            } else if (HomePageConfig.Value === '4') {
                Component = HomeDashBoard
            }
        } else if (adminFlag) {
            if (HomePageAdminValue === '2') {
                Component = CurrentProducts
            } else if (HomePageAdminValue === '3') {
                Component = UserHome
            } else if (HomePageAdminValue === '4') {
                Component = HomeDashBoard
            }
        } else {
            if (HomePageValue) {
                Component = CurrentProducts
            } else {
                Component = UserHome
            }
        }

        // if(this.props.organization.IsBuyer === false){
        //     return <div>Organization dont have buyer access</div>
        // }

        // const showCart = allowed_path.includes(this.props.location.pathname);
        return <div className={classes.root}>
            <CssBaseline />
            {/* <Drawer /> */}
            <DrawerV2 {...this.props} />
            <div className={classnames('applicationContent', isShowPadding ? [classes.content] : [classes.content2]
            )}>
                <Switch>
                    {adminFlag && <Route exact path={`${this.props.match.path}/home`} component={Component} />}
                    {this.props.user.Role === "OrgUser" && <Route exact path={`${this.props.match.path}/home`} component={Component} />}
                    {/* <Route exact path={`${this.props.match.path}/home-old`} component={Home} /> */}
                    <Route exact path={`${this.props.match.path}/notifications/:status`} component={Notification} />
                    {this.props.user.Role === "OrgUser" && <Route exact path={`${this.props.match.path}/home-v2`} component={UserHome} />}
                    {this.props.user.Role === "OrgUser" && <Route exact path={`${this.props.match.path}/home-v2/evaluation-review`} component={EvaluationAndReview} />}
                    {adminFlag && <Route path={`${this.props.match.path}/metrics`} component={Metrics} />}
                    <Route exact path={`${this.props.match.path}/external/products`} component={ExternalProducts} />
                    <Route exact path={`${this.props.match.path}/external/vendors`} component={ExternalVendors} />
                    <Route exact path={`${this.props.match.path}/external/services`} component={ExternalServices} />
                    <Route path={`${this.props.match.path}/products`} component={Products} />
                    <Route path={`${this.props.match.path}/vendors`} component={Vendors} />
                    <Route path={`${this.props.match.path}/workflows_listing`} component={Workflows} />
                    <Route path={`${this.props.match.path}/project/:id/tracking/:formId`} component={Tracking} />
                    <Route path={`${this.props.match.path}/evaluate`} component={Evaluate} />
                    <Route path={`${this.props.match.path}/renewal_workflows`} component={RenewalWorkflows} />
                    <Route path={`${this.props.match.path}/other_workflows`} component={OtherWorkflows} />
                    <Route path={`${this.props.match.path}/review`} component={Review} />
                    <Route exact path={`${this.props.match.path}/feedback`} component={Feedback} />
                    <Route path={`${this.props.match.path}/survey`} component={Survey} />
                    {this.props.user.Role === "OrgAdmin" && <Route path={`${this.props.match.path}/settings`} component={Settings} />}
                    <Route exaxt path={`${this.props.match.path}/renewal`} component={Renewal} />
                    <Route exact path={`${this.props.match.path}/profile`} component={Profile} />
                    <Route exact path={`${this.props.match.path}/dashboard`} component={Analytics} />
                    <Route path={`${this.props.match.path}/dashboard-v2`} component={Dashboard} />
                    <Route path={`${this.props.match.path}/notifications`} component={Notifications} />
                    {this.props.user.Role === "OrgExternal" ? <Redirect exact path={this.props.match.path} to={`${this.props.match.path}/external/products`} /> :
                        <Redirect exact path={this.props.match.path} to={`${this.props.match.path}/home`} />}
                </Switch>
            </div>
            {isCart && showCart &&
                <CartDrawer />
            }
            <Snackbar />
            <OrgUserUpdateDialog />
            <CriteriaRuleDialog />
            <NotificationSocket />
        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Application));