/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import { SellerEvaluationFactory } from "services/sellerEvaluation";
import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

// Fetch Vendor Evaluation detais
export const START_VENDORD_EVALUATION_PROGRESS = "START_VENDORD_EVALUATION_PROGRESS";
export const END_VENDORD_EVALUATION_PROGRESS = "END_VENDORD_EVALUATION_PROGRESS";

// Fetch inprogress Evaluation details
export const START_INPROGESS_EVALUATION = "START_INPROGESS_EVALUATION";
export const END_INPROGESS_EVALUATION = "END_INPROGESS_EVALUATION";

// Fetch completed Evaluation details
export const START_COMPLETED_EVALUATION = "START_COMPLETED_EVALUATION";
export const END_COMPLETED_EVALUATION = "END_COMPLETED_EVALUATION";

export const START_FETCH_SELLER_LIBRARY = "START_FETCH_SELLER_LIBRARY";
export const END_FETCH_SELLER_LIBRARY = "END_FETCH_SELLER_LIBRARY";

export const START_ADD_REQ_TO_SELLER_LIBRARY = "START_ADD_REQ_TO_SELLER_LIBRARY";
export const END_ADD_REQ_TO_SELLER_LIBRARY = "END_ADD_REQ_TO_SELLER_LIBRARY";

export const START_REMOVE_REQ_FROM_SELLER_LIBRARY = "START_REMOVE_REQ_FROM_SELLER_LIBRARY";
export const END_REMOVE_REQ_FROM_SELLER_LIBRARY = "END_REMOVE_REQ_FROM_SELLER_LIBRARY";

export const START_CHECK_REQ_IN_SELLER_LIBRARY = "START_CHECK_REQ_IN_SELLER_LIBRARY";
export const END_CHECK_REQ_IN_SELLER_LIBRARY = "END_CHECK_REQ_IN_SELLER_LIBRARY";

export const START_IMPORT_TO_SELLER_LIBRARY = "START_IMPORT_TO_SELLER_LIBRARY";
export const END_IMPORT_TO_SELLER_LIBRARY = "END_IMPORT_TO_SELLER_LIBRARY";

/**
 * Vendor Evaluation API Start
 */

function startVendorEvaluationFetch(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_VENDORD_EVALUATION_PROGRESS,
    payload: payload,
  };
}

function endVendorEvaluationFetch(success, error) {
  return {
    type: END_VENDORD_EVALUATION_PROGRESS,
    payload: {
      success,
      error,
    },
  };
}

export function fetchVendorEvaluation(queryParam) {
  return async (dispatch) => {
    dispatch(startVendorEvaluationFetch(queryParam.onInit));
    try {
      let [response, error] = await SellerEvaluationFactory.instance().getVendorEvaluation(queryParam, true);
      response.onInit = queryParam.onInit;
      dispatch(endVendorEvaluationFetch(response, error));
    } catch (e) {
      dispatch(
        endVendorEvaluationFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startInProgessEvaluationFetch(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_INPROGESS_EVALUATION,
    payload: payload,
  };
}

function endInProgessEvaluationFetch(success, error) {
  return {
    type: END_INPROGESS_EVALUATION,
    payload: {
      success,
      error,
    },
  };
}

export function fetchInProgressEvaluation(queryParam) {
  const api_server = Environment.api_host("EVALUATION");
  const timestamp = new Date().getTime();
  const url = `${api_server}/seller/evaluations?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startInProgessEvaluationFetch());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, queryParam, false);
      dispatch(endInProgessEvaluationFetch(response, error));
    } catch (e) {
      dispatch(
        endInProgessEvaluationFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startCompletedEvaluationFetch(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_COMPLETED_EVALUATION,
    payload: payload,
  };
}

function endCompletedEvaluationFetch(success, error) {
  return {
    type: END_COMPLETED_EVALUATION,
    payload: {
      success,
      error,
    },
  };
}

export function fetchCompletedEvaluation(queryParam) {
  return async (dispatch) => {
    dispatch(startCompletedEvaluationFetch(queryParam.onInit));
    try {
      let [response, error] = await SellerEvaluationFactory.instance().getVendorEvaluation(queryParam);
      response.onInit = queryParam.onInit;
      dispatch(endCompletedEvaluationFetch(response, error));
    } catch (e) {
      dispatch(
        endCompletedEvaluationFetch(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startGetSellerLibrary(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_FETCH_SELLER_LIBRARY,
    payload: payload,
  };
}

function endGetSellerLibrary(success, error) {
  return {
    type: END_FETCH_SELLER_LIBRARY,
    payload: {
      success,
      error,
    },
  };
}

export function fetchSellerLibrary(queryParam) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/library?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startGetSellerLibrary());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, queryParam, false);
      dispatch(endGetSellerLibrary(response, error));
    } catch (e) {
      dispatch(
        endGetSellerLibrary(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startAddReqToSellerLibrary(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_ADD_REQ_TO_SELLER_LIBRARY,
    payload: payload,
  };
}

function endAddReqToSellerLibrary(success, error) {
  return {
    type: END_ADD_REQ_TO_SELLER_LIBRARY,
    payload: {
      success,
      error,
    },
  };
}

export function addReqToSellerLibrary(queryParam) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/update_library?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startAddReqToSellerLibrary());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, queryParam, false);
      dispatch(endAddReqToSellerLibrary(response, error));
    } catch (e) {
      dispatch(
        endAddReqToSellerLibrary(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startRemoveReqFromSellerLibrary(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_REMOVE_REQ_FROM_SELLER_LIBRARY,
    payload: payload,
  };
}

function endRemoveReqFromSellerLibrary(success, error) {
  return {
    type: END_REMOVE_REQ_FROM_SELLER_LIBRARY,
    payload: {
      success,
      error,
    },
  };
}

export function removeReqFromSellerLibrary(queryParam) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/update_library?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startRemoveReqFromSellerLibrary());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, queryParam, false);
      dispatch(endRemoveReqFromSellerLibrary(response, error));
    } catch (e) {
      dispatch(
        endRemoveReqFromSellerLibrary(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startCheckRequirementInLibrary(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_CHECK_REQ_IN_SELLER_LIBRARY,
    payload: payload,
  };
}

function endCheckRequirementInLibrary(success, error) {
  return {
    type: END_CHECK_REQ_IN_SELLER_LIBRARY,
    payload: {
      success,
      error,
    },
  };
}

export function checkRequirementInLibrary(type, reqId, entityId = "0") {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/check/${type}/${reqId}/${entityId}?t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startCheckRequirementInLibrary());
    try {
      let [response, error] = await HttpFactory.instance().getMethod(url);
      dispatch(endCheckRequirementInLibrary(response, error));
    } catch (e) {
      dispatch(
        endCheckRequirementInLibrary(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}

function startImportToSellerLibrary(onInit = false) {
  let payload = {};
  payload.onInit = onInit;
  return {
    type: START_IMPORT_TO_SELLER_LIBRARY,
    payload: payload,
  };
}

function endImportToSellerLibrary(success, error) {
  return {
    type: END_IMPORT_TO_SELLER_LIBRARY,
    payload: {
      success,
      error,
    },
  };
}

export function importToSellerLibrary(queryParam) {
  const api_server = Environment.api_host("DOMAIN");
  const timestamp = new Date().getTime();
  const url = `${api_server}/requirements/import?entity=library&t=${timestamp}`;
  return async (dispatch) => {
    dispatch(startImportToSellerLibrary());
    try {
      let [response, error] = await HttpFactory.instance().postMethod(url, queryParam);
      dispatch(endImportToSellerLibrary(response, error));
    } catch (e) {
      dispatch(
        endImportToSellerLibrary(null, {
          message: e.message,
        })
      );
      return;
    }
  };
}
