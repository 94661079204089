import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Grid, InputAdornment, CircularProgress } from '@material-ui/core';
import { Search as SearchIcon } from "@material-ui/icons";
import AppBarV2 from "Components/Application/AppBarV2";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import DefaultImage from "assets/images/evaluation/default.svg";
import CommonCss from 'commonCss';
import Clear from "@material-ui/icons/Clear";
import TooltipMsg from '@material-ui/core/Tooltip';

// Redux Actions
import { fetchPersonalizedRecommendationsProducts } from "redux/product/action";

const connectedProps = (state) => ({
  personalizedRecommendationsProducts: state.products.personalizedRecommendationsProducts,
  fetchPersonalizedRecommendationsProductsProgress: state.products.fetchPersonalizedRecommendationsProductsProgress
});

const connectionActions = {
  fetchPersonalizedRecommendationsProducts: fetchPersonalizedRecommendationsProducts
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {},
  searchIcon: {
    marginRight: theme.spacing(2)
  },
  searchSection: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    '& [class*="MuiOutlinedInput-root"]': {
      maxHeight: 40,
      minHeight: 40,
      maxWidth: 343,
    },
    "& [class*='MuiInputBase-input']": {
      fontSize: 13,
      width: 300,
    },
    "& [class*='MuiSvgIcon-root']": {
      fill: "#707070",
    },
    "& [class*=MuiGrid-container]": {
      alignItems: "center",
      marginBottom: 20,
    },
    "&>div ": {
      marginBottom: 0,
    },
  },
  searchIconPop: {
    cursor: 'pointer',
    fontSize: theme.spacing(3),
    marginRight: '10px',
    "&:hover": {
      color: "#fff !important",
      fill: "#fff !important",
      background: "#707070",
      borderColor: "#707070",
      padding: '3px',
      borderRadius: theme.spacing(20),
      fontSize: theme.spacing(3)
    }
  },
  headSection: {
    marginTop: '2.5em',
    marginBottom: '2.5em'
  },
  rootContainer:CommonCss.rootContainer
});

class PersonalizedProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      data: [],
    }
  }

  componentDidMount() {
    this.props.fetchPersonalizedRecommendationsProducts()
  }

  componentDidUpdate(prevProps) {
    const { personalizedRecommendationsProducts, fetchPersonalizedRecommendationsProductsProgress } = this.props;
    if (!fetchPersonalizedRecommendationsProductsProgress && prevProps.fetchPersonalizedRecommendationsProductsProgress && personalizedRecommendationsProducts !== null) {
      this.setState({
        data: personalizedRecommendationsProducts
      })
    }
  }

  filterProduct() {
    const { query } = this.state
    const { personalizedRecommendationsProducts } = this.props;
    if (query) {
      const data = personalizedRecommendationsProducts.filter(o => o.Name.toLowerCase().indexOf(query) > -1)
      this.setState({ data })
    } else {
      this.setState({
        data: personalizedRecommendationsProducts
      })
    }
  }

  reviewProduct = (id) => {
    let redirectPath = `/app/products/product-review/${id}/`;
    this.props.history.push(redirectPath);
  }

  handleClear = (event) => {
    event.stopPropagation();
    var me = this;
    this.setState({
      query: '',
      page: 0,
      isDataLoading: true
    }, () => {
      me.filterProduct();
    });
  }

  render() {
    const classes = this.props.classes;
    const { query, data } = this.state;
    const { fetchPersonalizedRecommendationsProductsProgress } = this.props;
    const totalProduct = data.length
    return (
      <div className={classes.root}>
        <AppBarV2 title="Personalized Recommendations" withBack={true} />
        <div className={classes.rootContainer}>
          <Grid container justifyContent="space-between" className={classes.headSection}>
            <p className={classes.productCount}>
              {totalProduct} {" "}
              {totalProduct > 1 ? " Products" : " Product"}{" "}
              found
            </p>
            <div className={classes.searchSection}>
              <OutlinedInput
                id={"SearchPersonalizedProducts"}
                placeholder="Search Personalized Products"
                value={query}
                onChange={(event) => {
                  this.setState({
                    query: event.target.value
                  }, () => {
                    this.filterProduct();
                  })
                }}
                startAdornment={
                  <InputAdornment>
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment>
                    {this.state.query.length > 0 && this.state.query !== '' && this.state.query !== null && <TooltipMsg placement="top" title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Clear</span>} arrow>
                      <Clear className={classes.searchIconPop} onClick={this.handleClear} />
                    </TooltipMsg>}
                  </InputAdornment>
                }
                roundedCorner={true}
              />
            </div>
            <div>
            </div>
          </Grid>
          {fetchPersonalizedRecommendationsProductsProgress && (
            <CircularProgress />
          )}
          <Grid container spacing={3}>
            {!fetchPersonalizedRecommendationsProductsProgress &&
              ![null, undefined].includes(data) &&
              data.map((product, i) => (
                <Grid item lg={2} md={3} sm={6} xs={12} key={i}>
                  <div className={classes.productCard}>
                    <LayeredCard
                      title={product.Name}
                      subTitle={product.CompanyName}
                      description={product.Description}
                      showExplanation={true}
                      explanation={product.Explanation}
                      autoHideExplanationToolTip={true}
                      ishoverExplanationToolTip={true}
                      onHoverExplanationToolTipText="Why am i seeing this?"
                      image={product.ProductLogo === null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo}
                      toogleCheck={(e) => {
                        this.reviewProduct(product.ID)
                      }}
                      onClick={() => { }}
                      numLayer={0}
                      showCheckbox={true}
                      isOrgProduct
                      id={product.ID}
                      uniqueId={product.ID + '_o_' + i}
                      customRoute={'/app/products/org-product-details/'}
                      type={'product'}
                      IsExisting={true}
                      fireEvent
                      eventPage={"userRecommendation"}
                      eventData={{
                        type: "event.click.product.on-user-recommendation",
                        data: {
                          url: this.props.location.pathname + this.props?.location?.search,
                          product: {
                            id: product.ID,
                            name: product.Name,
                            position: i + 1,
                          },
                        },
                      }}
                    />
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
      </div>);
  }
}

export default connector(compose(withRouter, withStyles(styles))(PersonalizedProducts));