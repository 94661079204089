import React from 'react';
import { connect } from "react-redux";
import { createStyles, withStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withRouter } from 'react-router-dom'    
import { Grid } from "@material-ui/core";
import { Button } from '@material-ui/core';
import Image from 'Components/Common/image.jsx'

// redux
import { notifyMeFetch, resetNotifyMeFetch } from "redux/common/action";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
    notifyMeFetchInProgress: state.common.notifyMeFetchInProgress,
    notifyMeFetchResponse: state.common.notifyMeFetchResponse
});
const connectionActions = {
    notifyMeFetch: notifyMeFetch,
    resetNotifyMeFetch: resetNotifyMeFetch,
    showSnackBar: showSnackBar
};
var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root:{
        background:theme.palette.common.white,
        borderRadius:theme.spacing(2),
        padding:40,
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden'
    },
    imageContainer:{
        textAlign:"right",
    },
    img:{
        maxHeight: 242
    },
    title:{
        fontSize:theme.spacing(2.5),
        margin: 0
    },
    body:{
        paddingBottom:theme.spacing(2),
        color: '#04363D',
        fontSize: 16,
        maxWidth: '612px',
        textAlign: 'left',
        marginTop: 0,
        lineHeight: 1.7,
        marginRight: 30
    },
    action:{
        textAlign:"center"
    },
    link:{
        color:"#3C9CF9",
        textTransform:"none",
        fontSize:theme.spacing(1.8),
        cursor:"pointer"
    },
    linkImage:{
        width:theme.spacing(1)
    },
    subscribe: {
        boxShadow: '4px 0px 4px #2B2D3703',
        border: '1px solid #20253A',
        borderRadius: 4,
        padding: '3px 41px'
    },
    input: {
        border: '1px solid #D8D8D8',
        padding: '6px 10px',
        marginRight: 16,
        width: 350,
        borderRadius: 4,
        fontSize: 13,
    }
});

class ComingSoon extends React.Component {

    componentDidMount(){
        this.props.resetNotifyMeFetch()
    }
    
    notifyMe =()=> {
        let data = {}
        data.feature = this.props.feature

        let info = {};
        info.url = this.props.location?.pathname
        data.json = JSON.stringify(info)
        this.props.notifyMeFetch(data)
    }

    componentDidUpdate(){
        if(this.props.notifyMeFetchInProgress === false && this.props.notifyMeFetchResponse?.message === 'Success'){
            this.props.showSnackBar("Notification sent successfully....","success",3000);
            this.props.resetNotifyMeFetch()
        }
    }
    render() {
        const classes = this.props.classes;
        const {  layout } = this.props;
        if(layout === 'row'){
            return <>
                <Grid container spacing={2} className={classes.root}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p className={classes.title}>{this.props.title}</p>
                        <p className={classes.body}>{this.props.description}</p>
                        <div style={{textAlign: 'center'}}>
                                {/* <input className={classes.input} placeholder="Email address"/> */}
                                <Button className={classes.subscribe} onClick={()=>{this.notifyMe()}}>Notify me</Button>
                        </div>
                    </Grid>
                    <Grid className={classes.imageContainer} item xs={12} sm={12} md={12} lg={12} style={{textAlign: 'center'}}>
                        <Image src={this.props.image} className={classes.img} alt="coming soon" />
                    </Grid>
                </Grid>
                </>
        }
        return <>
                <Grid container spacing={2} className={classes.root}>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                        <p className={classes.title}>{this.props.title}</p>
                        <p className={classes.body}>{this.props.description}</p>
                        <div>
                                {/* <input className={classes.input} placeholder="Email address"/> */}
                                <Button className={classes.subscribe} onClick={()=>{this.notifyMe()}}>Notify me</Button>
                        </div>
                    </Grid>
                    <Grid className={classes.imageContainer} item xs={12} sm={4} md={4} lg={4}>
                        <Image src={this.props.image} className={classes.img} alt="coming soon" />
                    </Grid>
                </Grid>
                </>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ComingSoon));