import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Divider, Box, Typography, FormControl, Select, MenuItem, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import CommonCss from 'commonCss';
import PropTypes from "prop-types";
import AppBarV2 from "Components/Application/AppBarV2";
import { showSnackBar } from "redux/snackbar/action";
import Builder from "./Components/Builder";
import AutomationJobs from "./Components/AutomationJobs";
import SqureButton from 'Components/Common/SqureButton';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import StopIcon from '@material-ui/icons/Stop';
import EditIcon from '@material-ui/icons/Edit';
import { fetchAutomation, updateAutomation, fetchMasterTriggers, fetchMasterActions, builderSummarySelectTab } from 'redux/automations/action';

const connectedProps = (state) => ({
  fetchAutomationProgress: state.automation.fetchAutomationProgress,
  fetchAutomationError: state.automation.fetchAutomationError,
  automationDetails: state.automation.automationDetails,

  updateAutomationProgress: state.automation.updateAutomationProgress,
  updateAutomationError: state.automation.updateAutomationError,
  updatedAutomationData: state.automation.updatedAutomationData,

  fetchMasterTriggerProgress: state.automation.fetchMasterTriggerProgress,
  fetchMasterTriggerError: state.automation.fetchMasterTriggerError,
  masterTriggers: state.automation.masterTriggers,

  fetchMasterActionProgress: state.automation.fetchMasterActionProgress,
  fetchMasterActionError: state.automation.fetchMasterActionError,
  masterActions: state.automation.masterActions,

  builderSummarySelectedTab: state.automation.builderSummarySelectedTab,

});

const connectionActions = {
  updateAutomation: updateAutomation,
  fetchAutomation: fetchAutomation,
  showSnackBar: showSnackBar,
  fetchMasterTriggers: fetchMasterTriggers,
  fetchMasterActions: fetchMasterActions,
  builderSummarySelectTab: builderSummarySelectTab
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  paper: {
    padding: 20
  },
  tabPanel: {
    backgroundColor: '#fff',
    minHeight: theme.spacing(40),
  },
  tabPanelRes: {
    backgroundColor: '#fff',
    '& [class*=MuiBox-root]': {
      padding: '2px'
    },
    '& [class*=makeStyles-label]': {
      display: 'none'
    }
  },
  topActions: {
    display: 'flex',
    alignItems: 'center'
  },
  rightActions: {
    textAlign: 'right',
    flex: 1
  },
  loader: {
    textAlign: 'center',
    marginTop: 30
  }
});

class Evaluation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      status: 0,
      isShowLoader: true,
      automationId: this.props.match.params.id,
      name: '',
      automationData: ''
    }
  }

  componentDidMount() {
    this.setState({
      activeTab: this.props.builderSummarySelectedTab
    })

    this.props.fetchAutomation(this.props.match.params.id);
    this.props.fetchMasterTriggers()
    this.props.fetchMasterActions()

  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchAutomationProgress === false && prevProps.fetchAutomationProgress) {
      if (this.props.fetchAutomationError === null) {
        this.setState({
          automationData: this.props.automationDetails
        }, () => {
          this.initData()
        })
      }
    }

    if (!this.props.updateAutomationProgress && prevProps.updateAutomationProgress) {
      if (this.props.updateAutomationError === null) {
        this.setState({
          automationData: this.props.updatedAutomationData
        }, () => {
          this.initData()
        })
        this.props.showSnackBar('Automation updated successfully', 'success', 3000)
      } else {
        this.props.showSnackBar('Something went wrong', 'error', 3000)
      }
    }
  }

  initData() {
    const { automationData } = this.state;
    this.setState({
      isShowLoader: false,
      status: automationData?.Status !== null ? automationData?.Status : 0,
      name: automationData?.Name
    })
  }

  updateAutomationStatus() {
    let params = {
      name: this.state.name,
      status: this.state.status === 0 ? 1 : 0
    }
    this.props.updateAutomation(this.state.automationId, params)
  }

  handleSelectTabs(newValue) {
    this.setState({ activeTab: newValue }, () => {
      this.props.builderSummarySelectTab(newValue)
    })
  }

  render() {
    const { classes } = this.props;
    const { isShowLoader, automationId, automationData } = this.state;
    const { fetchMasterTriggerProgress, fetchMasterActionProgress, masterTriggers, masterActions } = this.props;

    if (isShowLoader || fetchMasterTriggerProgress || fetchMasterActionProgress) {
      return <div className={classes.loader}>
        <CircularProgress />
      </div>
    }


    return (
      <div>
        <AppBarV2 title={automationData?.Name || 'Automation Update'} withBack={true} />
        <div className={classes.rootContainer}>
          <div className={classes.body}>
            <div className={classes.topActions}>
              <div className={classes.rightActions}>
                {<SqureButton style={{ "marginLeft": "10px" }}
                  variant={"outlined"}
                  startIcon={<EditIcon />}
                  disabled={this.state.status === 1}
                  onClick={() => {
                    let redirectPath = `/app/settings/automations/builder/${this.state.automationId}`;
                    this.props.history.push(redirectPath);
                  }}
                >
                  Edit Recipe
                </SqureButton>}

                {<SqureButton style={{ "marginLeft": "10px", "marginRight": "10px" }}
                  variant={"contained"}
                  startIcon={this.state.status === 0 ? <PlayCircleOutlineIcon /> : <StopIcon />}
                  onClick={() => {
                    this.updateAutomationStatus();
                  }}
                >
                  {this.state.status === 0 ? 'Start Automation' : 'Stop Automation'}
                </SqureButton>}


                {false && <FormControl variant="outlined" className={classes.formControl} size="small">
                  <Select value={this.state.status} onChange={(e) => {
                    this.setState({ status: e.target.value }, () => {
                      this.updateAutomationStatus();
                    })
                  }}>
                    <MenuItem value={0} style={{ fontSize: 13 }}>Active</MenuItem>
                    <MenuItem value={1} style={{ fontSize: 13 }}>Inactive</MenuItem>
                  </Select>
                </FormControl>}
              </div>
            </div>
            <Tabs
              value={this.state.activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => {
                this.handleSelectTabs(newValue)
              }}
            >
              <Tab label="Recipe" />
              <Tab label="Jobs" />
            </Tabs>
            <Divider />
            {this.state.activeTab === 0 && <TabPanel className={classes.tabPanelRes} value={this.state.activeTab} index={0}>
              <Builder
                id={automationId}
                automationDetails={automationData}
                masterTriggers={masterTriggers}
                masterActions={masterActions}
                isForTestJobs={false}
                isForSummary={true}
              />
            </TabPanel>}
            {this.state.activeTab === 1 && <TabPanel className={classes.tabPanelRes} value={this.state.activeTab} index={1}>
              <AutomationJobs
                id={automationId}
                automationDetails={automationData}
              />
            </TabPanel>}
          </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Evaluation));
