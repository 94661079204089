import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import DashboardHome from "./Home";
import CommonCss from "commonCss";
import VisualizationBuilder from "./VisualizationBuilder";
import DashboardAndVisualizationTabs from "./DashboardAndVisualizationTabs";
import DashboardBuilder from "./DashboardBuilder";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
  });

class Products extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={this.props.match.path} component={DashboardHome} />
          <Route exact path={`${this.props.match.path}/visualization-builder/:id`} component={VisualizationBuilder} />
          <Route exact path={`${this.props.match.path}/list`} component={DashboardAndVisualizationTabs} />
          <Route exact path={`${this.props.match.path}/dashboard-builder/:id`} component={DashboardBuilder} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default connector(compose(withStyles(styles))(Products));
