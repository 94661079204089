import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Typography, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import CommonCss from 'commonCss';
import AppBarV2 from "Components/Application/AppBarV2";
import { showSnackBar } from "redux/snackbar/action";
import SqureButton from 'Components/Common/SqureButton';
import EditIcon from '@material-ui/icons/Edit';
import Builder from "../Summary/Components/Builder";
import { CommonFn } from "services/commonFn";

import { fetchAutomation, fetchMasterTriggers, fetchMasterActions, fetchAutomationJob, fetchAutomationJobComponents } from 'redux/automations/action';

const connectedProps = (state) => ({
  fetchAutomationProgress: state.automation.fetchAutomationProgress,
  fetchAutomationError: state.automation.fetchAutomationError,
  automationDetails: state.automation.automationDetails,

  fetchMasterTriggerProgress: state.automation.fetchMasterTriggerProgress,
  fetchMasterTriggerError: state.automation.fetchMasterTriggerError,
  masterTriggers: state.automation.masterTriggers,

  fetchMasterActionProgress: state.automation.fetchMasterActionProgress,
  fetchMasterActionError: state.automation.fetchMasterActionError,
  masterActions: state.automation.masterActions,

  fetchAutomationJobProgress: state.automation.fetchAutomationJobProgress,
  fetchAutomationJobError: state.automation.fetchAutomationJobError,
  automationJob: state.automation.automationJob,

  fetchAutomationJobComponentProgress: state.automation.fetchAutomationJobComponentProgress,
  fetchAutomationJobComponentError: state.automation.fetchAutomationJobComponentError,
  jobsComponents: state.automation.jobsComponents,

});

const connectionActions = {
  fetchAutomation: fetchAutomation,
  showSnackBar: showSnackBar,
  fetchMasterTriggers: fetchMasterTriggers,
  fetchMasterActions: fetchMasterActions,
  fetchAutomationJob: fetchAutomationJob,
  fetchAutomationJobComponents: fetchAutomationJobComponents
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  paper: {
    padding: 20
  },
  loader: {
    textAlign: 'center',
    marginTop: 30
  },
  topHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #b5b5b5'
  },
  jobActionBtns: {
    textAlign: 'right'
  }
});

class JobDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      status: 0,
      isShowLoader: true,
      automationId: this.props.match.params.id,
      jobId: this.props.match.params.job_id,
      name: '',
      job: '',
      statusFilterMap: {
        Error: 'Failed',
        InProgress: 'Processing',
        Finished: 'Successful',
      },
      components: [],
      connections: [],
      componentConfigs: [],
      componentConfigMap: [],
    }
  }

  componentDidMount() {
    this.props.fetchAutomation(this.props.match.params.id);
    this.props.fetchAutomationJob(this.props.match.params.id, this.props.match.params.job_id);
    this.props.fetchAutomationJobComponents(this.props.match.params.id, this.props.match.params.job_id);
    this.props.fetchMasterTriggers()
    this.props.fetchMasterActions()
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchAutomationProgress === false && prevProps.fetchAutomationProgress) {
      if (this.props.fetchAutomationError === null) {
        this.initData()
      }
    }

    if (this.props.fetchAutomationJobProgress === false && prevProps.fetchAutomationJobProgress) {
      if (this.props.fetchAutomationJobComponentError === null) {
        this.initJobData()
      }
    }

    if (this.props.fetchAutomationJobComponentProgress === false && prevProps.fetchAutomationJobComponentProgress) {
      if (this.props.fetchAutomationJobError === null) {
        this.setJobComponents()
      }
    }

  }

  initData() {
    const { automationDetails } = this.props;
    this.setState({
      status: automationDetails?.Status,
      name: automationDetails?.Name
    })
  }

  setJobComponents() {
    const { jobsComponents } = this.props;
    console.log(jobsComponents, 'jobsComponents')
    if (jobsComponents) {
      let comMap = {}
      jobsComponents.forEach((itm) => {
        comMap[itm.ComponentID] = itm;
      })
      this.setState({
        componentConfigMap: comMap,
        componentConfigs: jobsComponents
      })
    }
  }

  initJobData() {
    const { automationJob } = this.props;
    console.log(automationJob, 'automationJob')
    const snapShotData = JSON.parse(automationJob.Snapshot)
    const components = snapShotData?.components;
    const connections = snapShotData?.connections;
    this.setState({
      isShowLoader: false,
      job: automationJob,
      components: components,
      connections: connections
    })
  }



  render() {
    const { classes } = this.props;
    const { isShowLoader, automationId, job, statusFilterMap, components, connections, componentConfigs, componentConfigMap } = this.state;
    const { automationDetails, fetchMasterTriggerProgress, fetchMasterActionProgress, masterTriggers, masterActions } = this.props;

    if (isShowLoader || fetchMasterTriggerProgress || fetchMasterActionProgress) {
      return <div className={classes.loader}>
        <CircularProgress />
      </div>
    }

    console.log(job, 'Jobbbbbbbbbbbbbbbbbbbbb') 

    return (
      <div>
        <AppBarV2 title={automationDetails?.Name || 'Automation Update'} withBack={true} />
        <div className={classes.rootContainer}>
          <div className={classes.topHeader}>
            <div className={classes.jobDetails}>
              <Typography variant="h5">{CommonFn.formatTime(job?.CreatedAt, true)}, {CommonFn.formateDate(job?.CreatedAt)} | {statusFilterMap[job?.ExecutionStatus]} </Typography>
              <p>Started at {CommonFn.formatTime(job?.CreatedAt, true)}  on {CommonFn.formateDate(job?.CreatedAt, true)}</p>
            </div>
            <div className={classes.jobActionBtns}>
              {<SqureButton
                variant={"contained"}
                disabled={this.state.status === 1}
                startIcon={<EditIcon />}
                onClick={() => {
                  let redirectPath = `/app/settings/automations/builder/${this.state.automationId}`;
                  this.props.history.push(redirectPath);
                }}
              >
                Edit recipe
              </SqureButton>}
              <p>Repeats and edits always use the latest recipe version</p>
            </div>
          </div>
          <div className={classes.body}>
            <Builder
              id={automationId}
              automationDetails={automationDetails}
              masterTriggers={masterTriggers}
              masterActions={masterActions}
              isForTestJobs={true}
              isForSummary={false}
              jobComponents={components}
              jobConnections={connections}
              jobComponentConfigs={componentConfigs}
              jobComponentConfigMap={componentConfigMap}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(JobDetail));
