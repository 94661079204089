import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';

// icons
import CloseIcon from '@material-ui/icons/Close';

// Redux
import { hideCollectiveResponseDialog, fetchCollectiveResponses, fetchAskSellerThread } from "redux/evaluation/criteria/action";
import {addRequirementResponse, fetchResponseForThread, addAskSellerRequest, addReplyForResponseThread, updateResponseThread} from "redux/evaluation/criteria/action";
import { fetchProductDetails } from "redux/product/action";

// Components
import CollectiveResponse from './CollectiveResponse/CollectiveResponse'

const connectedProps = (state) => ({
    evaluate: state.evaluate,
    criterias: state.evaludationCriteria.criterias,
    collectiveResponseDialogOpen: state.evaludationCriteria.collectiveResponseDialogOpen,
    evaluation_id: state.evaludationCriteria.evaluation_id,
    criteria: state.evaludationCriteria.criteria,
    collectiveRequirement: state.evaludationCriteria.collectiveRequirement,
    collectiveProduct: state.evaludationCriteria.collectiveProduct,
    collectiveTargetTab: state.evaludationCriteria.collectiveTargetTab,
    fetchCollectiveResponsesLoading: state.evaludationCriteria.fetchCollectiveResponsesLoading,
    collectiveResponses: state.evaludationCriteria.collectiveResponses,
    fetchProductDetailsUnderProgress: state.products.fetchProductDetailsUnderProgress,
    productDetails: state.products.productDetails,
    productRequirement: state.products.productRequirement
});

const connectionActions = {
  hideCollectiveResponseDialog: hideCollectiveResponseDialog,
  fetchCollectiveResponses:fetchCollectiveResponses,
  fetchProductDetails: fetchProductDetails,
  fetchAskSellerThread:fetchAskSellerThread,
  addRequirementResponse: addRequirementResponse,
  updateResponseThread: updateResponseThread,
  fetchResponseForThread: fetchResponseForThread,
  addReplyForResponseThread: addReplyForResponseThread
}


var connector = connect(connectedProps, connectionActions);

const drawerWidth = "50%";

const useStylesDrawer = createStyles({
    drawer: {
        width: drawerWidth,
        maxWidth: '700px',
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        maxWidth: '700px',
    },
    close: {
      position: 'absolute',
      left: 8,
      top: 8,
      cursor: 'pointer',
      color: '#6F6F6F',
      '& svg':{
        stroke: 'white',
        fontSize: '24px'
      }
    }
});


class CollectiveResponseDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          anchor: 'right'
        }
    }

    componentDidMount() {
      // fetch collective respose data
      this.fetchCollectiveResponse()
      // this.fetchSelerThread()
    }

    fetchSelerThread =()=> {
      var product  =      this.props.collectiveProduct;
      var requirement  =      this.props.collectiveRequirement;
      this.props.fetchAskSellerThread(product.VendorUUID, requirement.id)
    }

    fetchCollectiveResponse =()=> {
        let queryparams = {}
        queryparams.evaluation_id = this.props.evaluation_id | 0;
        queryparams.product_id = this.props.collectiveProduct.ID | 0;
        queryparams.requirement_id = this.props.collectiveRequirement.id | 0;
        this.props.fetchCollectiveResponses(queryparams);
        this.props.fetchProductDetails(queryparams.product_id)
    }

    render() {
      const { productRequirement } = this.props;
      const classes = this.props.classes;
      let communityResponse = {};
      let note = 'No response yet'

      var product  =  this.props.collectiveProduct;
      var requirement  =      this.props.collectiveRequirement;
      
      if(productRequirement && productRequirement[product.ID] && productRequirement[product.ID][requirement.ref_requirement_id]){
        let data = productRequirement[product.ID][requirement.ref_requirement_id];
        communityResponse.response = true;
        communityResponse.Note = data.data.Description;
        communityResponse.User = {
          Name: 'Expent'
        }
      }else{
        communityResponse.response = false;
      }
      
      if(this.props.productDetails && !communityResponse.response) {
        let rsponse = false;
        let matchFeature = this.props.productDetails.Features.find((feature)=> {
          return feature.Name.toLowerCase() == this.props.collectiveRequirement.name.toLowerCase();
        });

        if(matchFeature) {
          rsponse = true;
          note = `${this.props.productDetails.Name} supports ${matchFeature?.Name}`;
        }

        communityResponse = {
          response : rsponse,
          User: {
            Name: 'Expent'
          },
          Note: note
        }
      }

      var product  =      this.props.collectiveProduct;
      var requirement  =      this.props.collectiveRequirement;
      const { fetchCollectiveResponsesLoading }=this.props;

        return <>
            <Drawer 
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }} 
            anchor={this.state.anchor} open={this.props.collectiveResponseDialogOpen} onClose={this.props.hideCollectiveResponseDialog}>
                  <span className={classes.close} onClick={() => this.props.hideCollectiveResponseDialog()}>
                    <CloseIcon className={classes.close} />
                  </span>
                  {
                    fetchCollectiveResponsesLoading == true &&
                    <span style={{    display: 'block', textAlign: 'center', marginTop: '50%', color: '#707070'}}>Loding...</span>
                  }
                  {
                    fetchCollectiveResponsesLoading == false &&
                    <CollectiveResponse 
                          criteria={this.props.criteria}
                          evaluation_id={this.props.evaluation_id}
                          product={product}
                          requirement={requirement}
                          collectiveTargetTab={this.props.collectiveTargetTab}
                          fetchCollectiveResponsesLoading={this.props.fetchCollectiveResponsesLoading}
                          collectiveResponses={this.props.collectiveResponses} 
                          communityResponse = {communityResponse}
                          collectiveRequirement={this.props.collectiveRequirement}
                          fetchResponseForThread={this.props.fetchResponseForThread}
                          addReplyForResponseThread={this.props.addReplyForResponseThread}
                          addRequirementResponse={this.props.addRequirementResponse}
                          updateResponseThread={this.props.updateResponseThread}
                          />
                        }
            </Drawer>
        </>
    }
}


export default connector(compose( withRouter,  withStyles(useStylesDrawer) )(CollectiveResponseDialog));