import {
  Chip,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

function MultiSelect(props) {
  const styles = {
    // Styles remain unchanged
  };

  const [selectedOptions, setSelectedOptions] = useState(
    props.selectedOptions || []
  );
  const [editMode, setEditMode] = useState(props.editMode);
  const [error, setError] = useState(false); // New state for error handling

  useEffect(() => {
    setEditMode(props.editMode);
  }, [props.editMode]);

  // Function to handle validation and update the error state
  const handleValidation = () => {
    const isValid = props.isRequired ? selectedOptions.length > 0 : true;
    setError(!isValid);
  };

  useEffect(() => {
    handleValidation();
  }, [selectedOptions]);

  return (
    <div style={{ ...styles.customTypeFields }}>
      {!editMode ? (
        <>
          <div style={{ ...styles.chips }}>
            {selectedOptions.length > 0 ? (
              selectedOptions.map((value) => (
                <Chip
                  key={value}
                  label={props.options.find((o) => o.value === value)?.label}
                  style={{ ...styles.chip }}
                />
              ))
            ) : (
              <Typography variant="body2">Select</Typography>
            )}
          </div>
        </>
      ) : (
        <FormControl style={{ zIndex: 1, width: "80%" }}>
          <Select
            variant="outlined"
            multiple
            isClearable
            disabled={!editMode}
            value={selectedOptions}
            onChange={(e) => {
              setSelectedOptions(e.target.value);
              props?.onSubmit?.(e.target.value);
            }}
            renderValue={(selected) => (
              <div style={{ ...styles.chips }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={props.options.find((o) => o.value === value)?.label}
                    style={{ ...styles.chip }}
                  />
                ))}
              </div>
            )}
            fullWidth
            menuPortalTarget={document.body}
            menuPlacement={"auto"}
            placeholder={"Select"}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 99999 }),
              width: "100%",
            }}
          >
            {props.options.map((option) => {
              return (
                <MenuItem
                  style={{
                    borderBottom: "1px solid whitesmoke",
                  }}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {error && (
        <Typography variant="body2" color="error">
          {props.isRequired ? "This field is required." : "Error"}
        </Typography>
      )}
    </div>
  );
}

export default MultiSelect;
