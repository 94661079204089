import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slide, Typography, Button, Checkbox } from "@material-ui/core";
// import Alert from "@material-ui/lab/Alert";
// import DeleteIcon from '@material-ui/icons/Delete';
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
// import classnames from "classnames";
import { CommonFn } from "../../../../services/commonFn";
// import CardAction from "../../../Common/CardAction";
import CommonCss from "../../../../commonCss";
// import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
// import Image from 'Components/Common/image.jsx'
import { IsExistingProduct } from "Components/Common/SelectProductOption";

// Redux
import { showAddProductManuallyDialog, hideAddProductManuallyDialog } from "redux/productsSettings/action";
import { fetchAddOrgProduct } from "redux/product/orgProduct/action";
import { fetchProductSubCategory } from "redux/product/subcategory/action";
import { searchUser } from "redux/usersearch/action";
import { fetchRecommendedProductsToAdd } from "redux/product/action";
import { showSnackBar } from "redux/snackbar/action";
import { resetAddOrgProductData } from "redux/product/orgProduct/action";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import { hideAddVendorDialog } from "redux/vendor/vendorCatalog/action";
// dropdown
// import Select from 'react-select';
// import AsyncSelect from 'react-select/async';
// import AsyncCreatableSelect from 'react-select/async-creatable';
// import Creatable from "react-select/creatable";
import CreatableSelect from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
// import { AsyncPaginate } from 'react-select-async-paginate';

// env
import * as Environment from "util/Environment";

const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  user: state.authUser.user,
  addProductManuallyDialogOpen: state.productsSettings.addProductManuallyDialogOpen,
  addProductInitQuery: state.productsSettings.addProductInitQuery,

  subCategories: state.productSubCategory.subCategories,
  subCategoriesFetchProgress: state.productSubCategory.fetchSubCategoryProgress,

  ProductChampUserProgress: state.userSearch.searchProgress,
  ProductChampUsers: state.userSearch.users,

  products: state.products.recommendedProductsToAdd,
  addOrgProductProgress: state.orgProduct.addOrgProductProgress,
  addorgProductStatus: state.orgProduct.addorgProductStatus,
  addorgProductCatsStatus: state.orgProduct.addorgProductCatsStatus,
  addorgProductChampStatus: state.orgProduct.addorgProductChampStatus,
  addorgProductStatusError: state.orgProduct.addorgProductStatusError,
  fetchRecommendedProductsToAddProgress: state.products.fetchRecommendedProductsToAddProgress,
  fetchExpentDirectoryProductsProgress: state.expentDirectory.fetchExpentDirectoryProductsProgress,
  fetchExpentDirectoryProductsError: state.expentDirectory.fetchExpentDirectoryProductsError,
  expentDirectoryProducts: state.expentDirectory.products,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  hideAddProductManuallyDialog: hideAddProductManuallyDialog,
  hideAddVendorDialog: hideAddVendorDialog,
  fetchAddOrgProduct: fetchAddOrgProduct,
  fetchProductSubCategory: fetchProductSubCategory,
  searchProductChampUser: searchUser,
  fetchRecommendedProducts: fetchRecommendedProductsToAdd,
  resetAddOrgProductData: resetAddOrgProductData,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    header: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: " center space-between",
      alignItems: "center",
    },
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginBottom: theme.spacing(2.5),
      marginLeft: 20,
    },

    addProductSection: {
      display: "flex",
      border: "1px solid #F2F2F2",
      padding: 50,
      margin: 20,
    },

    productImage: {
      width: 200,
      height: 200,
      border: "1px solid #AAAAAA",
      borderRadius: 11,
    },
    subTitle: {
      color: "#282D30",
      fontSize: 16,
    },
    formControl: CommonCss.formControl,
    label: CommonCss.label,
    formGroup: CommonCss.formGroup,
    rightSection: {
      flex: 1,
      marginLeft: 60,
      marginRight: 30,
    },
    formDivide: {
      display: "flex",
    },
    groupFlexLeft: {
      marginRight: 10,
      flex: 1,
    },
    groupFlexRight: {
      marginLeft: 10,
      flex: 1,
    },
    buttonSection: {
      textAlign: "center",
      margin: 20,
    },
    button: {},
    arrow: {
      paddingLeft: 5,
    },
    uploadImageLabel: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    uploadImage: {
      width: 0,
      overflow: "hidden",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: 11,
    },
    deleteSection: {
      position: "absolute",
      background: "#fff",
      borderRadius: 11,
      padding: "2px 2px 0px 2px",
      top: 0,
      right: 0,
      cursor: "pointer",
    },
    delete: {
      fill: "#ff1200",
    },
    mandatory: CommonCss.mandatory,
    searchAndSelect: {
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
      },

      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    otherDetails: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 10px #398AF51A",
      border: "1px solid #398AF5",
      borderRadius: "5px",
    },
    close: {
      position: "absolute",
      right: 15,
      top: 15,
      cursor: "pointer",
      color: "#6F6F6F",
      "& svg": {
        stroke: "white",
        fontSize: "24px",
      },
    },
    divider: {
      color: "#4b86ff",
    },
  });

class AddProductManuallyDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      selectedProduct: null,
      companyName: "",
      productURL: "",
      internalTool: false,
      existingTool: false,
      existingToolDisabled: false,
      expanded: false,
      cat: [],
      Setchamp: [],
      cNameError: false,
      urlError: false,
      loading: false,
      addingNewProduct: false
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.addProductManuallyDialogOpen && !prevProps.addProductManuallyDialogOpen) {
      this.setState({ loading: false });
    }
    
    if (this.props.addOrgProductProgress === false && prevProps.addOrgProductProgress === true && this.state.addingNewProduct) {
      if (this.props.addorgProductStatusError === null) {
        this.setState({
          addingNewProduct:false
        },()=>{
          setTimeout(() => {
            this.props.hideAddProductManuallyDialog();
            this.props.hideAddVendorDialog();
            this.props.showSnackBar("Product Successfully Added", "success", 3000);
            if (this.state.internalTool || this.state.existingTool) {
              this.props.history.push("/app/products/org-products");
            } else if (this.props.isSeller !== undefined && this.props.isSeller === true) {
              this.props.history.push("/sellerapp/products/unverified-product");
            } else {
              this.props.history.push("/app/products/unverified-product");
            }
            this.props.resetAddOrgProductData();
          }, 2000);
        })
      } else {
        let msg = "Something went wrong. Please try again!";
        if (this.props.addorgProductStatusError?.message) {
          msg = "Failed to add vendor!";
        }
        this.setState({ loading: false });
        this.props.showSnackBar(msg, "error", 3000);
      }
    }
  }

  clearError = () => {
    this.setState({
      cNameError: false,
      urlError: false,
    });
  };

  onSubmit = () => {
    this.clearError();
    if (this.state.selectedProduct?.label === undefined) {
      this.props.showSnackBar("Please Select Vendor.", "error", 3000);
      return;
    }
    if (this.state.selectedProduct?.productStates && this.state.selectedProduct?.productStates.hasOwnProperty("existing")) {
      this.props.showSnackBar("This product is already added", "error", 3000);
      return;
    }

    if (this.props.isSeller) {
      if (String(this.state.productURL).trim().length === 0) {
        this.setState({
          urlError: false,
        });
        return;
      }
      if (String(this.state.companyName).trim().length === 0) {
        this.setState({
          cNameError: false,
        });
        return;
      }
    }

    let product = {
      companyName: this.state.companyName,
      productURL: this.state.productURL,
    };

    if (this.state.selectedProduct?.__isNew__) {
      product.productId = null;
    } else {
      product.productId = this.state.selectedProduct?.value || "";
    }
    product.name = this.state.selectedProduct?.label || "";
    product.productLogo = "";
    product.description = "";
    product.deploymentType = "";
    product.phone = "";
    product.email = "";
    product.isInternal = this.state.internalTool;
    if (this.state.internalTool) {
      product.isExsting = true;
    } else {
      product.isExsting = this.state.existingTool;
    }

    product.financialDetails = {
      numberOfLicenseProcured: 0,
      paymentTerm: 0,
      costPerLicense: 0,
      totalCost: "",
      invocieDiscount: 0,
    };
    product.cat = this.state.cat;
    product.champ = this.state.champ;
    this.setState({ loading: true,addingNewProduct:true });
    this.props.fetchAddOrgProduct(product);
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      isExpanded: isExpanded ? panel : false,
    });
  };

  fetchData = (value) => {
    this.props.fetchProductSubCategory(0, 0, 100, value);
  };

  fetchChampUsersData = (value) => {
    this.props.searchProductChampUser(value);
  };

  productCatChange = (newValue) => {
    this.setState({
      cat: newValue,
    });
  };

  productChampChange = (newValue) => {
    this.setState({
      Setchamp: newValue,
    });
  };

  getOptions = async (inputValue, loadedOptions, additional) => {
    // console.log(inputValue,loadedOptions,additional);
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("SEARCH");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      field_setname: "all",
      text: inputValue,
      page,
      page_size: 150,
      sort_fields: [],
      filter_fields: {},
      merge_existing_others: true,
    };
    // setPage(page+1)
    let url = `${api_server}/v2/dsl/expent_catalogue?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let products = response?.data?.body?.items || [];
    let total = response?.data?.body?.total || 0;
    products = products.map((m) => {
      m.value = parseInt(m.id);
      m.label = m.name;
      m.isProduct = true;
      m.productStates = m.states;
      m.isDisabled = m.states?.blocked !== undefined;
      return m;
    });
    return {
      options: products,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  render() {
    const classes = this.props.classes;
    const { loading, companyName, productURL, internalTool, existingTool, existingToolDisabled, cNameError, urlError } = this.state;
    return (
      <Dialog
        onClose={this.props.hideAddProductManuallyDialog}
        aria-labelledby="add-upload-dialog"
        open={this.props.addProductManuallyDialogOpen}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll={"body"}
        id="addProductDialog"
        PaperProps={{ style: { overflowY: "visible" } }}
      >
        <DialogContent classes={{ root: classes.dialogContent }} style={{ overflowY: "visible" }}>
          <Typography variant={"h6"} className={classes.title}>
            Add new vendor
          </Typography>
          <div>
            <span className={classes.close} onClick={() => this.props.hideAddProductManuallyDialog()}>
              <CloseIcon className={classes.close} />
            </span>
            <div className={classes.addProductSection}>
              <div className={classes.rightSection}>
                <div className={classes.header}>
                  <h3 className={classes.subTitle}>General details</h3>
                </div>

                <div className={classes.formGroup}>
                  <label className={classes.label}>
                    Name of the vendor<span className={classes.mandatory}>*</span>
                  </label>
                  <CreatableAsyncPaginate
                    isClearable
                    formatCreateLabel={(userInput) => `Create '${userInput}'`}
                    noOptionsMessage={() => "Start typing to select or create vendor"}
                    isSearchable={true}
                    createOptionPosition={"first"}
                    classNamePrefix="select"
                    placeholder="Name of the vendor"
                    additional={{
                      page: 0,
                    }}
                    components={{ IndicatorSeparator: () => null }}
                    formatOptionLabel={IsExistingProduct}
                    isValidNewOption={(input) => input.length > 0}
                    defaultValue={this.props.addProductInitQuery?.name ? { label: this.props.addProductInitQuery?.name, value: "" } : {}}
                    loadOptions={this.getOptions}
                    value={this.state.selectedProduct}
                    // defaultValue={this.state.selectedProduct}
                    onChange={(data) => {
                      this.setState({
                        existingTool: false,
                        existingToolDisabled: false,
                      });
                      if (!data?.__isNew__ && !(data?.productStates && data?.productStates.hasOwnProperty("existing"))) {
                        this.setState({
                          existingTool: true,
                          existingToolDisabled: true,
                        });
                      }
                      this.setState({
                        selectedProduct: data,
                      });
                      if (data?.company_name) {
                        this.setState({
                          companyName: data.company_name,
                        });
                      }
                      if (data?.product_url) {
                        this.setState({
                          productURL: data.product_url,
                        });
                      }
                    }}
                  />
                </div>

                <div className={classes.formGroup}>
                  <label className={classes.label}>Product URL{this.props.isSeller && <span className={classes.mandatory}>*</span>}</label>
                  <input className={classes.formControl} placeholder="Product URL" name="url" value={productURL} onChange={(e) => this.setState({ productURL: e.target.value })} />
                  <span className={classes.mandatory}>{urlError && "Product Url is required"}</span>
                </div>
                <div className={classes.formGroup}>
                  <label className={classes.label}>Company Name{this.props.isSeller && <span className={classes.mandatory}>*</span>}</label>
                  <input className={classes.formControl} value={companyName} placeholder="Company Name" name="companyName" onChange={(e) => this.setState({ companyName: e.target.value })} />
                  <span className={classes.mandatory}>{cNameError && "Company Name is required"}</span>
                </div>

                {!this.props.isSeller && (
                  <div style={{ textAlign: "center" }}>
                    <label className={classes.label}>
                      <Checkbox
                        name="internalTool"
                        onChange={() => this.setState({ internalTool: !this.state.internalTool })}
                        checked={internalTool}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />{" "}
                      Built Internally
                    </label>
                    {!internalTool && (
                      <React.Fragment>
                        &nbsp; <span className={classes.divider}>|</span> &nbsp;
                        <label className={classes.label}>
                          <Checkbox
                            name="existingTool"
                            onChange={() => this.setState({ existingTool: !this.state.existingTool })}
                            checked={existingTool}
                            color="primary"
                            disabled={existingToolDisabled}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />{" "}
                          Existing Product
                        </label>
                      </React.Fragment>
                    )}
                  </div>
                )}

                {/* {false && internalTool && (
              <div className={classes.formGroup}>
                <label className={classes.label}>Description{internalTool && <span className={classes.mandatory}>*</span>}</label>
                <input className={classes.formControl} placeholder="Description" name="description" ref={register({ required: internalTool })} />
                <span className={classes.mandatory}>{errors.description && "Description is required"}</span>
              </div>
            )} */}

                {/* {false && internalTool && (
              <Grid container>
                <Grid item lg={12} md={12}>
                  <div className={classes.formGroup}>
                    <label className={classes.label}>Category{internalTool && <span className={classes.mandatory}>*</span>}</label>
                    <div className={classes.searchAndSelect}>
                      <Creatable
                        getOptionLabel={(e) => e.Name}
                        getOptionValue={(e) => e.ID}
                        isSearchable={true}
                        isMulti
                        options={this.props.subCategories}
                        className={classes.formControl}
                        classNamePrefix="select"
                        placeholder="Select categories that best match the product"
                        name="category"
                        inputRef={register({ required: internalTool, name: "categories" })}
                        onInputChange={(e) => {
                          fetchData(e);
                        }}
                        onChange={productCatChange}
                      />
                    </div>
                    <span className={classes.mandatory}>{errors.category && "Category is required"}</span>
                  </div>
                </Grid>
              </Grid>
            )} */}

                {/* {false && internalTool && (
              <>
                <div className={classes.formGroup}>
                  <label className={classes.label}>Deployment Type{internalTool && <span className={classes.mandatory}>*</span>}</label>
                  <input className={classes.formControl} placeholder="Deployment Type" name="deploymentType" ref={register({ required: internalTool })} />
                  <span className={classes.mandatory}>{errors.deploymentType && "Deployment Type is required"}</span>
                </div>
                <div className={classes.formGroup}>
                  <label className={classes.label}>Departments using the product{internalTool && <span className={classes.mandatory}>*</span>}</label>
                  <input className={classes.formControl} placeholder="Departments using the product" name="departments" ref={register({ required: internalTool })} />
                  <span className={classes.mandatory}>{errors.departments && "Departments is required"}</span>
                </div>
              </>
            )} */}

                {/* {false && (
              <Accordion className={classes.otherDetails} expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <div>
                    <Typography variant={"h3"} className={classes.subTitle}>
                      Other details
                    </Typography>
                    <Typography variant={"p"}>{!internalTool && "Financial details ,"}Contact details, Operational details etc.</Typography>
                  </div>
                </AccordionSummary>

                <AccordionDetails>
                  <div style={{ width: "100%" }}>
                    {!internalTool && (
                      <div className={classes.formGroup}>
                        <label className={classes.label}>Description{internalTool && <span className={classes.mandatory}>*</span>}</label>
                        <input className={classes.formControl} placeholder="Description" name="description" ref={register({ required: internalTool })} />
                        <span className={classes.mandatory}>{errors.description && "Description is required"}</span>
                      </div>
                    )}
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className={classnames(classes.formGroup, classes.groupFlexLeft)}>
                          <label className={classes.label}>Support Phone</label>
                          <input className={classes.formControl} placeholder="1 (415) 509-6995" name="phone" ref={register()} />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className={classnames(classes.formGroup, classes.groupFlexRight)}>
                          <label className={classes.label}>Support Email</label>
                          <input className={classes.formControl} placeholder="user@email.com" name="email" ref={register()} />
                        </div>
                      </Grid>
                    </Grid>

                    {internalTool && (
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className={classes.formGroup}>
                            <label className={classes.label}>Owner Name</label>
                            <input className={classes.formControl} placeholder="Owner Name" name="ownerName" ref={register()} />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className={classes.formGroup}>
                            <label className={classes.label}>Owner Department</label>
                            <input className={classes.formControl} placeholder="Owner Department" name="ownerDepartment" ref={register()} />
                          </div>
                        </Grid>
                      </Grid>
                    )}

                    {!internalTool && (
                      <div className={classes.formGroup}>
                        <label className={classes.label}>Category{internalTool && <span className={classes.mandatory}>*</span>}</label>
                        <div className={classes.searchAndSelect}>
                          <Creatable
                            getOptionLabel={(e) => e.Name}
                            getOptionValue={(e) => e.ID}
                            isSearchable={true}
                            isMulti
                            options={this.props.subCategories}
                            className={classes.formControl}
                            classNamePrefix="select"
                            placeholder="Select categories that best match the product"
                            name="category"
                            inputRef={register({ required: internalTool })}
                            onInputChange={(e) => {
                              fetchData(e);
                            }}
                            onChange={productCatChange}
                          />
                        </div>
                        <span className={classes.mandatory}>{errors.category && "Category is required"}</span>
                      </div>
                    )}

                    {!internalTool && (
                      <div>
                        <p className={classes.subTitle}>Financial details</p>
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <div className={classnames(classes.formGroup, classes.groupFlexLeft)}>
                              <label className={classes.label}>Number of licenses procured</label>
                              <input className={classes.formControl} placeholder="Number of licenses procured" name="category" ref={register()} />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <div className={classnames(classes.formGroup, classes.groupFlexLeft, classes.groupFlexRight)}>
                              <label className={classes.label}>Cost per license</label>
                              <input className={classes.formControl} placeholder="Cost per license" name="costPerLicense" ref={register()} />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <div className={classnames(classes.formGroup, classes.groupFlexRight)}>
                              <label className={classes.label}>Total cost</label>
                              <input className={classes.formControl} placeholder="Total cost" name="totalCost" ref={register()} />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    )}

                    <p className={classes.subTitle}>Operational details</p>
                    {!internalTool && (
                      <>
                        <div className={classes.formGroup}>
                          <label className={classes.label}>Deployment Type{internalTool && <span className={classes.mandatory}>*</span>}</label>
                          <input className={classes.formControl} placeholder="Cloud" name="deploymentType" ref={register({ required: internalTool })} />
                          <span className={classes.mandatory}>{errors.deploymentType && "Deployment Type is required"}</span>
                        </div>
                        <div className={classes.formGroup}>
                          <label className={classes.label}>Departments using the product{internalTool && <span className={classes.mandatory}>*</span>}</label>
                          <input className={classes.formControl} placeholder="Marketing" name="departments" ref={register({ required: internalTool })} />
                          <span className={classes.mandatory}>{errors.departments && "Departments is required"}</span>
                        </div>
                      </>
                    )}

                    <div className={classes.formGroup}>
                      <label className={classes.label}>Internal Product champions</label>
                      <div className={classes.searchAndSelect}>
                        <Creatable
                          getOptionLabel={(e) => e.Name}
                          getOptionValue={(e) => e.ID}
                          isSearchable={true}
                          isMulti
                          className={classes.formControl}
                          options={this.props.ProductChampUsers}
                          classNamePrefix="select"
                          placeholder="Tony Stark"
                          name="category"
                          inputRef={register({ required: internalTool })}
                          onInputChange={(e) => {
                            fetchChampUsersData(e);
                          }}
                          onChange={productChampChange}
                        />
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )} */}
              </div>
            </div>

            <div className={classes.buttonSection}>
              <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideAddProductManuallyDialog}>
                Cancel
              </Button>
              &nbsp; &nbsp;
              {this.props.addOrgProductProgress || loading ? (
                <Button variant="contained" color="secondary" type="submit" className={classes.button}>
                  Loading...
                </Button>
              ) : (
                <Button variant="contained" color="secondary" onClick={this.onSubmit} className={classes.button}>
                  Add vendor <ArrowForwardIcon className={classes.arrow} />
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

AddProductManuallyDialog.defaultValue = {
  isSeller: false,
};

export default connector(compose(withRouter, withStyles(styles))(AddProductManuallyDialog));
