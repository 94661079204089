import { Axios } from "./index";
import * as Environment from "./../util/Environment";

export class ProductFactory{
    static _instance;
    
    static instance() {
        if (ProductFactory._instance == null) {
            ProductFactory._instance = new ProductFactory();
        }
        return ProductFactory._instance;
    }

   
    async getCategories(page,pageSize,query){
        const api_server = Environment.api_host("PRODUCT");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/categories?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            //Check if there are any previous pending requests
            if (typeof this.getCatPreviousCall !== typeof undefined) {
                this.getCatPreviousCall.cancel("cancelled");
            }
        
            //Save the cancel token for the current request
            this.getCatPreviousCall = Axios.CancelToken.source();

            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken},
                 cancelToken:  this.getCatPreviousCall.token 
            });
        } catch(error){
            if(error.message === 'cancelled') {
                return [null,{'message':"cancelled"}];
            } else if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getSubCategories(categoryId,page,pageSize,query,withFeatures){

        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        if(withFeatures === true){
            const timestamp     =   (new Date()).getTime();
            const api_server = Environment.api_host("SEARCH");
            const url   =   `${api_server}/product/categories/${categoryId}/subcategories?t=${timestamp}`;
            
            
            let duplicateCall = url.split('?')[0] || '';
            //Check if there are any previous pending requests
            if (typeof this.getPreviousCall !== typeof undefined && this.duplicateGetPreviousCall === duplicateCall) {
                this.getPreviousCall.cancel("cancelled");
            }
            //Save the cancel token for the current request
            this.getPreviousCall = Axios.CancelToken.source();
            this.duplicateGetPreviousCall = duplicateCall;

            let response;
            try{
                response    =   await Axios.post(url,{
                    page:page,
                    pageSize:pageSize,
                    query:query
                }, {
                    headers: {'Authorization': authType+' '+authToken},
                    cancelToken:  this.getPreviousCall.token 
                });
            } catch(error){
                if(error.message === "Network Error"){
                    //TODO Handle Network Error
                    return [null,{'message':"Unable to connect to Server."}];
                } else if(error.response !== undefined){
                    return [null,error.response.data];
                }
            }
            if(response.data === undefined){
                return [null,{"message":"Invalid Response from Server."}]
            }
            return [response.data,null];
        } else {
            const timestamp     =   (new Date()).getTime();
            const api_server = Environment.api_host("PRODUCT");
            const url   =   `${api_server}/categories/${categoryId}/subcategories?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}&withFeatures=${withFeatures}`;
            
            let response;
            try{
                response    =   await Axios.get(url, {
                    headers: {'Authorization': authType+' '+authToken}
                });
            } catch(error){
                if(error.message === "Network Error"){
                    //TODO Handle Network Error
                    return [null,{'message':"Unable to connect to Server."}];
                } else if(error.response !== undefined){
                    return [null,error.response.data];
                }
            }
            if(response.data === undefined){
                return [null,{"message":"Invalid Response from Server."}]
            }
            return [response.data,null];
        }
        
    }


    async getSubCategoryFeatures(subcategory_id,page,pageSize,query){
        const api_server = Environment.api_host("EVALUATION");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/subcategory/${subcategory_id}/features?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getProducts(subcategoryId,page, pageSize, query, type = '', feature_ids = [], num_users = '',forOrgFlag=0,additionalParam={}){
        const api_server = Environment.api_host("PRODUCT");
        const productapi_server = Environment.api_host("PRODUCT");
        const searchapi_server = Environment.api_host("SEARCH");
        const timestamp     =   (new Date()).getTime();
       
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try {
            var cancelToken     =   true
            if(additionalParam.cancelToken !== undefined){
                cancelToken = additionalParam.cancelToken;
            }
            if(cancelToken){
                 //Check if there are any previous pending requests
                if (typeof this.getProductPreviousCall !== typeof undefined) {
                    this.getProductPreviousCall.cancel("cancelled");
                }
            }
            
        
            //Save the cancel token for the current request
            this.getProductPreviousCall = Axios.CancelToken.source();

            if(type) {
                let url   =   `${api_server}/categories/0/subcategories/${subcategoryId}/products?t=${timestamp}`;
                if(forOrgFlag !== 0){
                    if(forOrgFlag == 1){
                        url = `${productapi_server}/org/master/products`
                    } else if(forOrgFlag == 2) {
                        url = `${productapi_server}/org/inreview/products`
                    }
                }
                var postParam = { page: page, pageSize: pageSize, query: query, type: type, feature_ids: feature_ids, num_users: num_users }
                var keys = Object.keys(additionalParam)
                keys.forEach((k)=>{
                    postParam[k]=additionalParam[k]
                })
                response    =   await Axios.post(url,postParam , {
                    headers: {'Authorization': authType+' '+authToken},
                     cancelToken:  this.getProductPreviousCall.token 
                });
            } else {
                if(subcategoryId === 0 && forOrgFlag === 0){
                    let url   =   `${searchapi_server}/products`;
                    var postParam = { page: page, pageSize: pageSize, query: query }
                    var keys = Object.keys(additionalParam)
                    keys.forEach((k)=>{
                        postParam[k]=additionalParam[k]
                    })
                    response    =   await Axios.post(url,postParam , {
                        headers: {'Authorization': authType+' '+authToken},
                         cancelToken:  this.getProductPreviousCall.token 
                    });
                } else {
                    let url   =   `${api_server}/categories/0/subcategories/${subcategoryId}/products?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`;
                    var keys = Object.keys(additionalParam)
                    keys.forEach((k)=>{
                        url += "&"+k+"="+additionalParam[k]
                    })
                    if(forOrgFlag !== 0){
                        if(forOrgFlag == 1){
                            url = `${productapi_server}/org/master/products?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`
                        } else if(forOrgFlag == 2) {
                            url = `${productapi_server}/org/inreview/products?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`
                            // url = `http://localhost:3003/product/org/inreview/products?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`
                        }
                    }
                    response    =   await Axios.get(url, {
                        headers: {'Authorization': authType+' '+authToken},
                        cancelToken:  this.getProductPreviousCall.token 
                    });
                }
                
            }
        } catch(error){
            if(error.message === 'cancelled') {
                return [null,{'message':"cancelled"}];
            } else if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getProductsV2(subcategoryId,page, pageSize, query, type = '', feature_ids = [], num_users = '',forOrgFlag=0,additionalParam={}){
        const api_server = Environment.api_host("PRODUCT");
        const productapi_server = Environment.api_host("PRODUCT");
        const timestamp     =   (new Date()).getTime();
       
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken === null || authType === null || authToken === undefined || authType === null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try {
            var cancelToken     =   true
            if(additionalParam.cancelToken !== undefined){
                cancelToken = additionalParam.cancelToken;
            }
            if(cancelToken){
                 //Check if there are any previous pending requests
                if (typeof this.getProductPreviousV2Call !== typeof undefined) {
                    this.getProductPreviousV2Call.cancel("cancelled");
                }
            }
        
            //Save the cancel token for the current request
            this.getProductPreviousV2Call = Axios.CancelToken.source();

            let url   =   `${api_server}/categories/0/subcategories/${subcategoryId}/products?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`;
            var keys = Object.keys(additionalParam)
            keys.forEach((k)=>{
                url += "&"+k+"="+additionalParam[k]
            })
            if(forOrgFlag !== 0){
                if(forOrgFlag === 1){
                    url = `${productapi_server}/org/master/products?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`
                } else if(forOrgFlag === 2) {
                    url = `${productapi_server}/org/inreview/products?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`
                    // url = `http://localhost:3003/product/org/inreview/products?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`
                }
            }
            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken},
                cancelToken:  this.getProductPreviousV2Call.token 
            });
        } catch(error){
            if(error.message === 'cancelled') {
                return [null,{'message':"cancelled"}];
            } else if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getAllCategories(page,pageSize,query,additionalParam={}){
        const api_server = Environment.api_host("PRODUCT");
        const timestamp     =   (new Date()).getTime();
        var url   =   `${api_server}/org/categories?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`;
        for(var key in additionalParam){
            url = url + "&"+key+"="+additionalParam[key]
        }
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            //Check if there are any previous pending requests
            if (typeof this.getCatPreviousCall !== typeof undefined) {
                this.getCatPreviousCall.cancel("cancelled");
            }
        
            //Save the cancel token for the current request
            this.getCatPreviousCall = Axios.CancelToken.source();

            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken},
                 cancelToken:  this.getCatPreviousCall.token 
            });
        } catch(error){
            if(error.message === 'cancelled') {
                return [null,{'message':"cancelled"}];
            } else if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getCategorySubCategoryWiseProducts(categoryId, subcategoryId,page, pageSize, query,additionalParam){
        const api_server = Environment.api_host("SEARCH");
        const timestamp     =   (new Date()).getTime();
    
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try {

            var bodyData = {
                page:page,
                pageSize:pageSize,
                query:query
            }

            if(additionalParam !== null){
                Object.keys(additionalParam).forEach(function(key){
                    bodyData[key] = additionalParam[key];
                });
            }
            //Save the cancel token for the current request
            this.getProductPreviousCall = Axios.CancelToken.source();
            let url   =   `${api_server}/product/org/categories/${categoryId}/products?t=${timestamp}`;
            response    =   await Axios.post(url,bodyData, {
                headers: {'Authorization': authType+' '+authToken},
                cancelToken:  this.getProductPreviousCall.token 
            });
        } catch(error){
            if(error.message === 'cancelled') {
                return [null,{'message':"cancelled"}];
            } else if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }
}
