import React from 'react';
import _ from "underscore";
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, IconButton, Slide,Typography, Grid, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router';
import CloseIcon from '@material-ui/icons/Close';
import SqureButton from './SqureButton';
import OutlinedInput from "./Input/OutlinedInput";
import ReactSelect from "react-select";
import CommonCss from 'commonCss';
import Alert from '@material-ui/lab/Alert';
import * as Validator from "util/Validation";

// components
import { CommonFn } from "services/commonFn";

// redux
import { hideUserGroupDialog } from "redux/okta/action";
import {fetchUsers} from "redux/usersSettings/action";
import {resetOrgGroupState, addOrgGroup, updateOrgGroup, fetchOrgGroupUser} from "redux/org/group/action";
import {showSnackBar} from "redux/snackbar/action";

const connectedProps = (state) => ({
    open:state.okta.addUserGroupDialog,
    addUserGroupDialogData:state.okta.addUserGroupDialogData,
    usersSettings: state.usersSettings,
    isUserProgress: state.authUser.isUserProgress,
    groupUser:state.orgGroup.groupUser,
    updateGroupProg:state.orgGroup.updateGroupProg,
    fetchUpdateGroupAPIState:state.orgGroup.fetchUpdateGroupAPIState,
    addGroupProg:state.orgGroup.addGroupProg,
    fetchAddGroupAPIState:state.orgGroup.fetchAddGroupAPIState,
    updateGroupError: state.orgGroup.updateGroupError,
});
const connectionActions = {
    hideDialog:hideUserGroupDialog,
    fetchUsers:fetchUsers,
    addOrgGroup: addOrgGroup,
    updateOrgGroup: updateOrgGroup,
    fetchOrgGroupUser: fetchOrgGroupUser,
    resetOrgGroupState:resetOrgGroupState,
    showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const styles = (theme) => createStyles({
    dialogContent:{
        padding: theme.spacing(6, 12) +" !important",
        paddingBottom:theme.spacing(1)+" !important",
    },
    modalTitle:{
        fontSize:theme.spacing(2.4),
        marginBottom:theme.spacing(3),
        textAlign:"left",
    },
    actions:{
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3),
        textAlign:"center",
    },
    loader:{
        textAlign:"center",
        padding:theme.spacing(10,0)
    },
    dialogAction:{
        textAlign:"center"
    },
    actionBtn:{
        minWidth: 110
    },
    alerts:{
        marginTop:theme.spacing(4)
    },
    label: CommonCss.label,
    formGroup: CommonCss.formGroup,
    formGroup:{
        '& .select__value-container':{
            padding: '11px 14px;'
        },
        '& [class*="MuiOutlinedInput-multiline-"]': {
            padding: '0 !important'
        }
    },
    closeIcon: {
        position: "absolute",
        zIndex: 11,
        right: 15,
        top: 15,
        padding: 5
      },
});

class OrgUserGroupDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            group:{
                id: null,
                name: '',
                name_error:'',
                email: '',
                email_error:'',
                description: '',
                description_error: '',
                user_ids: [],
                selectedUsers: [],
            },
            departmentquery:"",
            department:null,
            error:""
        }
    }

    componentDidMount() {
        this.fetchUsersList()
        if(this.props.addUserGroupDialogData){
            // update group
            const { ID, Name, Email, Description,} = this.props.addUserGroupDialogData;

            // fetch fetchOrgGroupUser
            this.props.fetchOrgGroupUser(ID);

            let group = {};
            group['id'] = ID;
            group['name'] = Name;
            group['email'] = Email;
            group['description'] = Description;
            this.setState(
                {
                    group : {
                        ...group
                    }
                },
                () => console.log(this.state.group)
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { groupUser } = this.props;
        const { selectedUsers } = this.state.group;
        if(groupUser && groupUser.length && selectedUsers === undefined){
            // binds form details
            let group = Object.assign(this.state.group);
            let userIds = _.map(groupUser, 'ID');
            let selectedUsers = groupUser?.map((item)=>{
                return {
                    value: item.ID,
                    label: item.Name
                }
            });
            group['user_ids'] = userIds;
            group['selectedUsers'] = selectedUsers;
            this.setState(
                {
                    group : {
                        ...group
                    }
                },
                () => console.log(this.state.group)
            );
        }

        // group add
        if(this.props.addGroupProg ===false && this.props.fetchAddGroupAPIState == true){
            this.props.showSnackBar("Team successfully created","success",3000);
            this.props.hideDialog();
            this.props.resetOrgGroupState();
        }
        // group update
        if(!this.props.updateGroupProg && prevProps.updateGroupProg){
            if (this.props.updateGroupError !== null) {
                let msg = 'Something went wrong, Please try again!'
                if (this.props.updateGroupError?.message !== undefined) {
                    msg = this.props.updateGroupError?.message
                }
                this.props.showSnackBar(msg,"error",3000);
            } else {
                this.props.hideDialog();
                this.props.resetOrgGroupState();
            }
        }
    }

    handleFormSubmit =()=> {
        var me  =   this;
        me.clearError();
        var group_name    =   me.state.group.name;
        if(group_name == null || String(group_name).trim().length == 0){
            let group = Object.assign(this.state.group);
            group['name_error'] = "Please provide  name.";
            me.setState({group:{...group}});
            return;
        }

        // var group_email   =    me.state.group.email;
        
        // if(group_email == null || String(group_email).trim().length){
        //     let group = Object.assign(this.state.group);
        //     if(Validator.validateEmail(group_email) === false){
        //         group['email_error'] = "Please provide valid email.";
        //         me.setState({group:{...group}});
        //         return;
        //     }  
        // }

        // var group_description   =    me.state.group.description;
        // if(group_description == null || String(group_description).trim().length == 0){
        //     let group = Object.assign(this.state.group);
        //     group['description_error'] = "Please provide  description.";
        //     me.setState({group:{...group}});
        //     return;
        // }
        const {id, name, email, description, user_ids} =this.state.group;
        // add group
        if(this.props.addUserGroupDialogData == null){
            this.props.addOrgGroup(name, email, description);
        }else{
            // update group
            let obj = {}
            obj.id = id;
            obj.name = name;
            obj.email = email;
            obj.description = description;
            obj.user_ids = user_ids;
            this.props.updateOrgGroup(obj);
        }
    }


    clearError =()=>{
        let group = Object.assign(this.state.group);
        group['name_error'] = "";
        // group['email_error'] = "";
        // group['description_error'] = "";
        this.setState({group:{...group}});
    }

    handleInput =(e)=> {
        let value = e.target.value;
        let name = e.target.name;
        let group = this.state.group;
        group[name] = value;
        this.setState(
            {
                group : {
                    ...group
                }
            },
            () => console.log(this.state.group)
        );
    }

    handleChange =(selectedOption)=> {
        let userIds = _.map(selectedOption, 'value');
        let group = this.state.group;
        group['user_ids'] = userIds;
        group['selectedUsers'] = selectedOption;
        this.setState(
            {
                group : {
                    ...group
                }
            },
            () => console.log(this.state.group)
        );
    }

    
    fetchUsersList = (query = '') => {
        this.props.fetchUsers(0, 10, query);
    };
   
    render() {
        const classes = this.props.classes;

        const isNew = this.props.addUserGroupDialogData == null ? true : false;

        let usersList = this.props.usersSettings.users.map((user) => {
            return { ...user, Role: CommonFn.getNameFromRole(user.Role) };
          });

        return <Dialog 
                    onClose={this.props.hideDialog} 
                    open={this.props.open}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"sm"}
                    scroll={"body"}
                    id="dialogUserGroup"
                    >
                    <DialogContent classes={{root:classes.dialogContent}}>
                    <IconButton className={classes.closeIcon} onClick={this.props.hideDialog}><CloseIcon /></IconButton>
                            <Typography variant={"h2"} className={classes.modalTitle}>
                                { isNew ? 'New Team' : 'Edit Team'}
                            </Typography>
                            <div className={classes.alerts}>
                                {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                            </div>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <OutlinedInput
                                        id={"name"}
                                        label="Name"
                                        type="text"
                                        name="name"
                                        value={this.state.group.name}
                                        onChange={(e) => {
                                            this.handleInput(e)
                                        }}
                                        error={this.state.group.name_error?.length > 0}
                                        helperText={this.state.group.name_error}
                                    />
                                </Grid>

                                {false && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <OutlinedInput
                                        id={"email"}
                                        label="Email"
                                        type="email"
                                        name="email"
                                        value={this.state.group.email}
                                        onChange={(e) => {
                                            this.handleInput(e)
                                        }}
                                        // error={this.state.group.email_error?.length > 0}
                                        // helperText={this.state.group.email_error}
                                    />
                                </Grid>}

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className={classes.formGroup}>
                                        <OutlinedInput
                                            multiline={true}
                                            rows={2}
                                            label="Description"
                                            name="description"
                                            value={this.state.group.description}
                                            onChange={(e) => {
                                                this.handleInput(e)
                                            }}
                                            className={classes.description}
                                            // error={this.state.group.description_error?.length > 0}
                                            // helperText={this.state.group.description_error}
                                        />
                                    </div>
                                </Grid>

                                {!isNew &&
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className={classes.formGroup}>
                                            <label className={classes.label}>Users</label>
                                            <ReactSelect
                                                isClearable
                                                isSearchable={true}
                                                isMulti={true}
                                                classNamePrefix="select"
                                                placeholder="Users"
                                                options={usersList.map((item)=>{
                                                    return {
                                                        value: item.ID,
                                                        label: item.Name
                                                    }
                                                })}
                                                onChange={this.handleChange}
                                                onInputChange={(e) => {
                                                    this.fetchUsersList(e)
                                                }}
                                                isLoading={this.props.isUserProgress }
                                                menuPortalTarget={document.body} 
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                defaultValue={this.state.group.selectedUsers}
                                                value={this.state.group.selectedUsers}
                                                maxMenuHeight={180}
                                            />
                                        </div>
                                    </Grid>
                                }

                            </Grid>
                            <div className={classes.actions}>
                                {(this.props.addGroupProg || this.props.updateGroupProg) && (
                                    <CircularProgress />
                                )}
                                {!this.props.addGroupProg && !this.props.updateGroupProg && (
                                    <>
                                        <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                                        <SqureButton  cls={classes.actionBtn} variant={"contained"} onClick={()=>{this.handleFormSubmit()}}>Save</SqureButton>
                                    </>
                                )}
                            </div>
                    </DialogContent>
                </Dialog>
    }
}


export default connector(compose(
    withRouter,
    withStyles(styles)
)(OrgUserGroupDialog));