import {
    START_VERIFICATION, END_VERIFICATION,
    START_VERIFY_TOKEN, END_VERIFY_TOKEN
} from "./action";

const initState = {
    isProgress: false,
    error: null,
    success: null,

    verifyTokenProgress: false,
    verifyTokenError: null,
    verifyTokenSuccess: null,
}

export function verificationReducer(state = initState, action) {
    switch (action.type) {
        case START_VERIFICATION:
            return {
                ...state,
                isProgress: true,
                error: null,
                success: null
            };
        case END_VERIFICATION:
            return {
                ...state,
                isProgress: false,
                error: action.payload.error,
                success: action.payload.success
            }

        case START_VERIFY_TOKEN:
            return {
                ...state,
                verifyTokenProgress: true,
                verifyTokenError: null,
                verifyTokenSuccess: null,
            }

        case END_VERIFY_TOKEN:
            return {
                ...state,
                verifyTokenProgress: false,
                verifyTokenError: action.payload.error,
                verifyTokenSuccess: action.payload.success,
            }

        default:
            return state;
    }
}