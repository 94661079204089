import {
    Dialog, DialogContent, Slide, Table, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import AnsweredIcon from 'assets/images/evaluation/QusAnswered.svg';
import MandatoryIcon from 'assets/images/evaluation/QusMandatory.svg';
import Image from 'Components/Common/image.jsx';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import { fetchRequirementTree, setSelectedProduct, setSelectedRequirement } from "redux/evaluation/criteria/action";
import { showFeedbackTakeDialog } from "redux/evaluation/feedback/action";
import { hideFinalizeReqDialog } from "redux/evaluation/feedback/action";
import SqureButton from '../../../../../../../Common/SqureButton';
import { addWorkflowComponentUserResponse } from "redux/workflow/action";

import { getNextComponent } from "util/Common";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.evaluationFeedback.showFinalizeRequirementDialog,
    isShowNextButton: state.evaluationFeedback.isShowNextButton,
    evaluation: state.evaluationMetaData.evaluation,
    requirementTree: state.evaludationCriteria.requirementTree,
    requirementUserResponsesStatus: state.evaludationCriteria.requirementUserResponsesStatus,
    user: state.authUser.user,
    addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
    workflowComponentsForms: state.workflow.workflowComponentsForms,
    userTimeline: state.workflow.userTimeline,
});

const connectionActions = {
    hideDialog: hideFinalizeReqDialog,
    showFeedbackTakeDialog: showFeedbackTakeDialog,
    setSelectedRequirement: setSelectedRequirement,
    setSelectedProduct: setSelectedProduct,
    fetchRequirementTree: fetchRequirementTree,
    addWorkflowComponentUserResponse: addWorkflowComponentUserResponse
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    actions: {
        textAlign: "center",
        marginTop: theme.spacing(2)
    },
    actionBtn: {
        minWidth: '200px'
    },
    actionBtnDisabled: {
        minWidth: '200px',
    },
    dialogContent: {
        margin: 'auto',
        marginBottom: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    productTitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    title: {
        textAlign: "center",
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
        margin: '20px 0px 0px'
    },
    prdHead: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    productName: {
        fontWeight: 600,
        marginLeft: theme.spacing(1),
        fontSize: theme.spacing(2.2),
        color: '#282D30',
    },
    productImg: {
        width: theme.spacing(3),
        marginRight: theme.spacing(0.6)
    },
    legendBox: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    },
    green: {
        background: '#2EB77D',
        color: '#fff',
        padding: theme.spacing(0.50, 1),
        marginLeft: theme.spacing(3),
        border: '1px solid'
    },
    orange: {
        background: 'orange',
        color: '#fff',
        padding: theme.spacing(0.50, 1),
        marginLeft: theme.spacing(3),
        border: '1px solid'
    },
    red: {
        background: 'red',
        color: '#fff',
        padding: theme.spacing(0.50, 1),
        marginLeft: theme.spacing(3),
        border: '1px solid'
    },
    greenLegend: {
        background: '#2EB77D',
        padding: '0px 10px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    orangeLegend: {
        background: 'orange',
        padding: '0px 10px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    redLegend: {
        background: 'red',
        padding: '0px 10px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    legends: {
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    reqName: {
        width: '200px',
        paddingLeft: '30px !important'
    },
    criteriaName: {
        width: '200px',
        fontWeight: 600
    },
    prdName: {
        width: '20%',
        textAlign: 'center'
    },
    currProductTitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    otherProductTitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        color: '#282D30',
        fontSize: theme.spacing(2.2)
    },
    otherProductTotal: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        color: '#282D30',
        opacity: '0.6',
        fontSize: theme.spacing(2)
    },
    finalizeForm: {
        '& [class*=MuiTable-root]': {

        },
        '& [class*=MuiTableCell]': {
            border: '1px solid #DEDEDE',
        },
        '& [class*=MuiTableCell-root]': {
            padding: theme.spacing(1, 1)
        },
    },
    mandatoryTable: {
        borderCollapse: 'separate !important',
        width: '100%',
        borderSpacing: 0,
        border: '1px solid #EB3D1A',
        borderRadius: '4px',
    },
    otherTable: {
        borderCollapse: 'separate !important',
        width: '100%',
        borderSpacing: 0,
        borderRadius: '4px',
        border: '1px solid #DEDEDE',
    },
    pd_action: {
        letterSpacing: '0px',
        color: '#0093E0 !important',
        opacity: 1,
        cursor: 'pointer',
        fontSize: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    mandatoryItems: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

class FinalizeRequiremntViewDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            feedback: "",
            feedback_error: "",
            products: [],
            nextClickLoader: false
        }
        this.showFeedbackDialog = this.showFeedbackDialog.bind(this);
    }

    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
        if (this.props.addComponentUserResponseProgress === true && prevProps.addComponentUserResponseProgress === false) {
            this.props.hideDialog();
        }
    }

    showFeedbackDialog() {
        if (this.props.isWorkFlow) {
            this.setState({
                nextClickLoader: true
            }, () => {
                var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);
                if (nextComponent !== null) {
                    let redirectPath = `/app/evaluate/${nextComponent.EvaluationId}/workflow/${nextComponent.WorkflowId}/component/${nextComponent.ComponentId}`;
                    this.props.history.push(redirectPath);
                    return;
                } else {
                    var responseDatas = [];
                    this.props.workflowComponentsForms.forEach((data) => {
                        if (data.IsConfiguration === false) {
                            var obj = {
                                "form_id": data.ID
                            }
                            var value = '';
                            if (data.Name === 'evaluation.name') {
                                value = this.state.name;
                            }
                            obj['value'] = value;
                            responseDatas.push(obj);
                        }
                    });

                    this.props.addWorkflowComponentUserResponse(this.props.match.params.evaluation_id, this.props.workflowComponentId, {
                        "data": responseDatas,
                        "forward": true
                    });
                }
            })
        } else {
            this.props.hideDialog();
            this.props.showFeedbackTakeDialog();
        }
    }

    render() {
        const classes = this.props.classes;

        var reqTreeData = this.props.requirementUserResponsesStatus === null ? [] : this.props.requirementUserResponsesStatus;
        var eva_products = this.props.evaluation !== undefined ? [...this.props.evaluation.Products] : [];
        var productMap = {};
        eva_products.forEach(function (item) {
            productMap[item.ID] = item;
        });

        var totalMandQues = 0;
        var totalMandAnswered = 0;
        var totalOtherQues = 0;
        var totalOtherAnswered = 0;

        var reqStatusNewMap = {
            'Mandatory': {},
            'Other': {}
        };
        var isShowNext = true;
        var products = [];
        var productsMap = [];
        let requirementIdMap = {};

        reqTreeData.forEach(function (item) {
            var productName = productMap[item.ProductId]?.Name;
            var productLogo = productMap[item.ProductId]?.ProductLogo;
            var criteriaName = item.CriteriaName;
            var requirementName = item.Name;
            var requirementId = item.Id;

            var type = item.IsMandatory ? 'Mandatory' : 'Other';
            if (productsMap.indexOf(productName) === -1) {
                productsMap.push(productName);
                products.push({
                    productName: productName,
                    productLogo: productLogo,
                });
            }

            if (type === 'Mandatory') {
                totalMandQues++;
                if (item.IsAnswered === true) {
                    totalMandAnswered++;
                }
            } else {
                totalOtherQues++;
                if (item.IsAnswered === true) {
                    totalOtherAnswered++;
                }
            }

            if (type === 'Mandatory' && !item.IsAnswered) {
                isShowNext = false;
            }
            if (requirementIdMap[requirementId] === undefined) {
                requirementIdMap[requirementId] = requirementName;
            }

            if (reqStatusNewMap[type][criteriaName] === undefined) {
                reqStatusNewMap[type][criteriaName] = {};
                reqStatusNewMap[type][criteriaName][requirementId] = {};
                reqStatusNewMap[type][criteriaName][requirementId][productName] = {
                    isAnswered: item.IsAnswered,
                    requirementId: item.Id,
                    productId: item.ProductId
                }
            } else if (reqStatusNewMap[type][criteriaName][requirementId] === undefined) {
                reqStatusNewMap[type][criteriaName][requirementId] = {};
                reqStatusNewMap[type][criteriaName][requirementId][productName] = {
                    isAnswered: item.IsAnswered,
                    requirementId: item.Id,
                    productId: item.ProductId
                }
            } else {
                reqStatusNewMap[type][criteriaName][requirementId][productName] = {
                    isAnswered: item.IsAnswered,
                    requirementId: item.Id,
                    productId: item.ProductId
                }
            }
        });

        const mandatoryData = reqStatusNewMap['Mandatory'];
        const otherData = reqStatusNewMap['Other'];
        if (Object.keys(mandatoryData).length <= 0 && Object.keys(otherData).length <= 0) {
            return <Dialog
                onClose={this.props.hideDialog}
                aria-labelledby="app-integrationDialog"
                open={this.props.isOpen}
                TransitionComponent={Transition}
                disableBackdropClick={true}
                fullWidth={true}
                maxWidth={"md"}
                scroll="body"
                id="evMetaDialog"
            >
                <DialogContent classes={{ root: classes.dialogContent }} >
                    <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>

                    <div className={classes.finalizeForm}>
                        <Typography variant={"h4"} className={classes.title}>User Response Status</Typography>
                        <div style={{ textAlign: 'center', marginTop: 30 }}>
                            <p>No Questions</p>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        }

        let isCompleted = false;
        if (totalMandQues === totalMandAnswered && totalOtherQues === totalOtherAnswered) {
            isCompleted = true;
        }

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>

                {!isCompleted && <div className={classes.finalizeForm}>
                    <Typography variant={"h4"} className={classes.title}>User Response Status</Typography>
                    {Object.keys(mandatoryData).length > 0 && <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <Typography variant={"h6"} className={classes.otherProductTitle}>
                                    Mandatory Questions
                                </Typography>
                            </div>
                            <div style={{ flex: 1, textAlign: 'right' }}>
                                <Typography variant={"h6"} className={classes.otherProductTotal}>
                                    {totalMandAnswered}/{totalMandQues} Completed
                                </Typography>
                            </div>
                        </div>

                        <Table className={classes.mandatoryTable} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.reqName}></TableCell>
                                    {products.map((prd) => {
                                        return <TableCell className={classes.prdName}> <div className={classes.prdHead}><Image alt="Mandatory" className={classes.productImg} src={prd.productLogo} /> &nbsp;&nbsp;{prd.productName}</div></TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(mandatoryData).map((criteria) => {
                                    return (<>
                                        <TableRow  >
                                            <TableCell colSpan={products.length + 1} className={classes.criteriaName}>{criteria}</TableCell>
                                        </TableRow>
                                        {Object.keys(mandatoryData[criteria]).map((req) => {
                                            var reqName = requirementIdMap[req];
                                            return <TableRow>
                                                <TableCell className={classes.reqName}>{reqName}</TableCell>
                                                {products.map((prd) => {
                                                    if (mandatoryData[criteria][req][prd.productName] !== undefined) {
                                                        if (mandatoryData[criteria][req][prd.productName].isAnswered) {
                                                            return <TableCell className={classes.prdName}><Image alt="Answered" src={AnsweredIcon} /></TableCell>
                                                        } else {
                                                            return <TableCell className={classes.prdName}><div className={classes.mandatoryItems}><Image alt="Mandatory" src={MandatoryIcon} />
                                                                <span className={classes.pd_action} onClick={() => {
                                                                    this.props.hideDialog();
                                                                    this.props.setSelectedRequirement(mandatoryData[criteria][req][prd.productName].requirementId);
                                                                    this.props.setSelectedProduct(mandatoryData[criteria][req][prd.productName].productId);
                                                                    this.props.fetchRequirementTree(this.props.match.params.evaluation_id, mandatoryData[criteria][req][prd.productName].productId, this.props.isRequirementForAdmin);
                                                                }}>
                                                                    Answer Now
                                                                </span></div>
                                                            </TableCell>
                                                        }
                                                    } else {
                                                        return <TableCell className={classes.prdName}></TableCell>
                                                    }
                                                })}
                                            </TableRow>
                                        })}
                                    </>)
                                })}
                            </TableBody>
                        </Table></div>}
                    {Object.keys(otherData).length > 0 && <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <Typography variant={"h6"} className={classes.otherProductTitle}>
                                Other Questions
                            </Typography>
                        </div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            <Typography variant={"h6"} className={classes.otherProductTotal}>
                                {totalOtherAnswered}/{totalOtherQues} Completed
                            </Typography>
                        </div>
                    </div>}
                    {Object.keys(otherData).length > 0 && <Table className={classes.otherTable} aria-label="simple table">
                        {Object.keys(mandatoryData).length <= 0 && <TableHead>
                            <TableRow>
                                <TableCell className={classes.reqName}></TableCell>
                                {products.map((prd) => {
                                    return <TableCell className={classes.prdName}> <div className={classes.prdHead}><Image alt="Mandatory" className={classes.productImg} src={prd.productLogo} /> &nbsp;&nbsp;{prd.productName}</div></TableCell>
                                })}
                            </TableRow>
                        </TableHead>}
                        <TableBody>
                            {Object.keys(otherData).map((criteria) => {
                                let totalRows = 0;
                                Object.keys(otherData[criteria]).forEach((req) => {
                                    var totalProducts = products.length;
                                    var totalAnswered = 0;

                                    products.forEach((prd) => {
                                        if (otherData[criteria][req][prd.productName] !== undefined) {
                                            if (otherData[criteria][req][prd.productName].isAnswered) {
                                                totalAnswered++
                                            }
                                        }
                                    })

                                    if (totalProducts === totalAnswered) {
                                        return '';
                                    } else {
                                        totalRows++;
                                    }
                                })

                                if (totalRows === 0) {
                                    return ''
                                }

                                return (<>
                                    <TableRow  >
                                        <TableCell colSpan={products.length + 1} className={classes.criteriaName}>{criteria}</TableCell>
                                    </TableRow>
                                    {Object.keys(otherData[criteria]).map((req) => {
                                        var reqName = requirementIdMap[req];

                                        // var totalProducts = products.length;
                                        // let totalAnswered = 0;

                                        // products.forEach((prd) => {
                                        //     if (otherData[criteria][req][prd.productName] !== undefined) {
                                        //         if (otherData[criteria][req][prd.productName].isAnswered) {
                                        //             totalAnswered++
                                        //         }
                                        //     }
                                        // })

                                        // if (totalProducts === totalAnswered) {
                                        //     return '';
                                        // }


                                        return <TableRow>
                                            <TableCell className={classes.reqName}>{reqName}</TableCell>
                                            {products.map((prd) => {
                                                if (otherData[criteria][req][prd.productName] !== undefined) {
                                                    if (otherData[criteria][req][prd.productName].isAnswered) {
                                                        return <TableCell className={classes.prdName}><Image alt="Answered" src={AnsweredIcon} /></TableCell>
                                                    } else {
                                                        return <TableCell className={classes.prdName}><Image alt="Mandatory" src={MandatoryIcon} /></TableCell>
                                                    }
                                                } else {
                                                    return <TableCell className={classes.prdName}></TableCell>
                                                }
                                            })}
                                        </TableRow>
                                    })}
                                </>)
                            })}
                        </TableBody>
                    </Table>}
                </div>}

                {isCompleted && <div className={classes.finalizeForm}>
                    <Typography variant={"h4"} className={classes.title}>User Response Status</Typography>
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant={"h6"} className={classes.otherProductTotal}>
                            All Requirements Responded
                        </Typography>
                        <Typography variant={"h6"} className={classes.otherProductTotal}>
                            {totalMandAnswered + totalOtherAnswered}/{totalMandQues + totalOtherQues} Responded
                        </Typography>
                    </div>
                </div>}

                {this.props.isShowNextButton && <div className={classes.actions}>
                    {isShowNext && <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.showFeedbackDialog}>
                        {this.state.nextClickLoader ? <CircularProgress style={{ width: 27, height: 27 }} /> : 'Next'}
                    </SqureButton>}
                    {!isShowNext && <SqureButton cls={classes.actionBtnDisabled} variant={"contained"} disabled>Next</SqureButton>}
                </div>}


            </DialogContent>
        </Dialog>
    }
}

FinalizeRequiremntViewDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(FinalizeRequiremntViewDialog));