import {
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import CheckboxTree from 'react-checkbox-tree';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import SqureButton from 'Components/Common/SqureButton';
import Checkbox from '@material-ui/core/Checkbox';

import { showSnackBar } from "redux/snackbar/action";
import {
    fetchdownloadProductCSVFileLink
} from "redux/product/orgProduct/action";
import "./style.css";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({

});

const connectionActions = {
    showSnackBar: showSnackBar,
    fetchdownloadProductCSVFileLink: fetchdownloadProductCSVFileLink
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    body: {
        marginTop: 20
    },
    actions: {
        textAlign: "center"
    },
    actionBtn: {
        minWidth: '200px',
        marginLeft: 10
    },
});

class ProductExportItemsSelectionDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            nodes: [],
            itemIds: [],
            selectAllItems: false
        }
        this.downloadFile = this.downloadFile.bind(this);
        this.selectAllItems = this.selectAllItems.bind(this);
    }

    componentDidMount() {
        this.initNodes()
    }
    componentDidUpdate(prevProps) {

    }

    initNodes() {
        const { productTabItems } = this.props;
        var nodes = [];
        var checked = []
        productTabItems.forEach(function (tab) {
            console.log(tab, 'TabDaya')
            var obj = {
                value: 'Tab_' + tab.ID,
                label: tab.TabName,
                children: []
            };
            let isGoodToAdd = true;
            // checked.push('Tab_' + tab.ID)
            if (tab.OrgProductTabSections !== null) {
                tab.OrgProductTabSections.forEach(function (section) {
                    let isGoodToAddSection = true;
                    var secObj = {
                        value: 'Section_' + section.ID,
                        label: section.SectionName,
                        children: []
                    };
                    // checked.push('Section_' + section.ID)
                    if (section.OrgProductTabItems !== null && section.OrgProductTabItems.length > 0) {
                        section.OrgProductTabItems.forEach(function (item) {
                            secObj.children.push({
                                value: 'Item_' + item.ID,
                                label: item.ItemName,
                            })
                            // checked.push('Item_' + item.ID)
                        })
                    } else {
                        isGoodToAddSection = false;
                        // secObj.children.push({
                        //     value: 'sec_dummy_' + section.ID,
                        //     label: section.SectionName,
                        // })
                        // checked.push('sec_dummy_' + section.ID)
                    }
                    if(isGoodToAddSection && secObj.children.length > 0){
                        obj.children.push(secObj);
                    }
                })
            } else {
                isGoodToAdd = true;
                // var secObj1 = {
                //     value: 'sec_dummy_' + tab.ID,
                //     label: tab.TabName,
                //     children: [{
                //         value: 'sec_dummy_itm' + tab.ID,
                //         label: tab.TabName,
                //     }]
                // };
                // obj.children.push(secObj1);
            }
            if(isGoodToAdd && obj.children.length > 0){
                nodes.push(obj)
            }
        })
        this.setState({
            nodes: nodes,
            checked: checked
        })
    }

    selectAllItems() {
        var type = this.state.selectAllItems === true ? false : true;
        var checkedItems = []
        var nodes = this.state.nodes;
        if (type) {
            nodes.forEach(function (tab) {
                checkedItems.push(tab.value)
                tab.children.forEach(function (section) {
                    checkedItems.push(section.value)
                    section.children.forEach(function (item) {
                        checkedItems.push(item.value)
                    });
                })
            })
        }
        this.setState({
            selectAllItems: type,
            checked: checkedItems
        })
    }

    downloadFile() {
        let checkedData = this.state.checked;
        console.log(checkedData)
        var itemIds = [];
        checkedData.forEach(function (itm) {
            if (itm.indexOf('Item_') !== -1) {
                let itmId = parseInt(itm.replace('Item_', ''))
                itemIds.push(itmId)
            }
        })
        if (itemIds.length <= 0) {
            this.props.showSnackBar("Please select at least one item.", "error", 3000)
            return;
        }
        console.log(itemIds, 'Item-Ids')
        this.props.fetchdownloadProductCSVFileLink(itemIds)
        this.props.hideDialog()
    }


    render() {
        const classes = this.props.classes;

        const { nodes, checked, expanded } = this.state;


        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Select Items for Export</Typography>
                <div className={classes.body}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 25 }}>
                        <Checkbox
                            label={'Select All'}
                            className={classes.criteriaAllSelect}
                            color="primary"
                            checked={this.state.selectAllItems}
                            onChange={() => {
                                this.selectAllItems()
                            }}
                        />
                        <Typography>Select All</Typography>
                    </div>

                    <CheckboxTree
                        nodes={nodes}
                        checked={checked}
                        expanded={expanded}
                        onCheck={checked => {
                            console.log(checked, 'Checkeddddd')
                            this.setState({ checked })
                        }}
                        onExpand={expanded => this.setState({ expanded })}
                        iconsClass="fa5"
                        icons={{
                            check: <CheckBoxIcon />,
                            uncheck: <CheckBoxOutlineBlankIcon />,
                            expandClose: <ChevronRightIcon />,
                            expandOpen: <ExpandMoreIcon />,
                            halfCheck: <CheckBoxOutlineBlankIcon />,
                        }}
                    />
                </div>
                <div className={classes.actions}>
                    <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>
                    <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.downloadFile}>Download</SqureButton>
                </div>
            </DialogContent>
        </Dialog>
    }
}


export default connector(compose(
    withRouter,
    withStyles(styles)
)(ProductExportItemsSelectionDialog));