import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
   TextField, FormControl, MenuItem, Select, CircularProgress, Button
  } from "@material-ui/core";
import {addUserConfig,updateUserConfig,fetchUserConfig} from "redux/usersSettings/action";
import { fetchDashboard } from "redux/visualization/action";
import { showSnackBar } from "redux/snackbar/action";
import CommonCss from 'commonCss';
import Autocomplete from "@material-ui/lab/Autocomplete";

const connectedProps = (state) => ({
    user:state.authUser.user,
    dashboards: state.visualization.dashboards,
    dashboardFetchProgress: state.visualization.dashboardFetchProgress,
    dashboardFetchError: state.visualization.dashboardFetchError,

    userConfigs: state.usersSettings.userConfigs,
    fetchUserConfigProgress: state.usersSettings.fetchUserConfigProgress,
    fetchUserConfigError: state.usersSettings.fetchUserConfigError,

    updateUserConfigProgress: state.usersSettings.updateUserConfigProgress,
    updateUserConfigError: state.usersSettings.updateUserConfigError,
    updateUserConfigSuccess: state.usersSettings.updateUserConfigSuccess,

    addUserConfigProgress: state.usersSettings.addUserConfigProgress,
    addUserConfigError: state.usersSettings.addUserConfigError,
    addUserConfigSuccess: state.usersSettings.addUserConfigSuccess,

});

const connectionActions = {
    addUserConfig,updateUserConfig,fetchUserConfig,fetchDashboard,showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    formGroup: CommonCss.formGroup,
    textSection: {
        fontSize: 16,
        fontWeight: 500,
    },
    label: {
        ...CommonCss.label,
        display: 'flex'
    },
    selectField: {
        ...CommonCss.selectField,
        "& [class*=MuiAutocomplete-inputRoot]": {
            minHeight: 44,
            padding: '0 8px',
            fontSize: 14,
            borderRadius: 6,
        },
        "& [class*=MuiSvgIcon-root-]": {
            opacity: 0
        },
        "& [class*=MuiAutocomplete-clearIndicator-]": {
            "& [class*=MuiSvgIcon-root-]": {
                opacity: 1
            },
        },
    },
    sendBtn:{
        fontSize:theme.spacing(2.2),
        fontWeight:500,
        padding: '6px 40px'
    },
    action: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
    },
    dashboardSelect: {
        width: '100%'
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

class GeneralConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePageAdmin: "",
            homePageDashboard: "",
            updateData: null,
        }
    }

    componentDidMount(){
        this.props.fetchUserConfig();
        this.props.fetchDashboard();
    }

    componentDidUpdate(prevProps){
        if(!this.props.fetchUserConfigProgress && prevProps.fetchUserConfigProgress){
            if(this.props.fetchUserConfigError){
                this.props.showSnackBar("Failed to fetch setting.", "error", 3000)
            }else if(this.props.userConfigs && this.props.userConfigs.length> 0) {
                let data = this.props.userConfigs.find(item=> item.Key === "homePageAdmin");
                if(data){
                    this.setState({
                        homePageAdmin: data.Value,
                        homePageDashboard: data.AdditionalValue,
                        updateData: data,
                    });
                }
            }
        }

        if(!this.props.addUserConfigProgress && prevProps.addUserConfigProgress){
            if(this.props.addUserConfigError){
                this.props.showSnackBar("Failed to update setting.", "error", 3000)
            } else {
                this.props.fetchUserConfig();
                this.props.showSnackBar("Updated Successfully.", "success", 3000)
            }
        }

        if(!this.props.updateUserConfigProgress && prevProps.updateUserConfigProgress){
            if(this.props.updateUserConfigError){
                this.props.showSnackBar("Failed to update setting.", "error", 3000)
            } else {
                this.props.fetchUserConfig();
                this.props.showSnackBar("Updated Successfully.", "success", 3000)
            }
        }
    }

    saveConfig = () => {
      let data = {
        key: "homePageAdmin",
        value: this.state.homePageAdmin,
      };
      
      if(this.state.homePageAdmin === "4"){
        if(this.state.homePageDashboard.length === 0){
            this.props.showSnackBar("Please select dashboard.", "error", 3000)
            return;
        } else {
            data['additional_value'] = this.state.homePageDashboard;
            console.log(data);
        }
      }
      if(this.state.updateData) {
        this.props.updateUserConfig(this.state.updateData.ID, data)
      } else {
          this.props.addUserConfig(data)
      }
    }

    render() {
        const classes = this.props.classes;
        const loading = this.props.addUserConfigProgress || this.props.updateUserConfigProgress;
        if(this.props.fetchUserConfigProgress){
            return <div className={classes.loader}><CircularProgress /></div>
        }

        return <div>
            <div className={classes.formGroup}>
              <label className={classes.label}>Home Page</label>
              <div>
                <Autocomplete
                  className={classes.selectField}
                  options={["1", "2", "3", "4"]}
                  getOptionLabel={(option) => {
                    if (option === "2") {
                      return "Vendor Catalog";
                    } else if (option === "3") {
                      return "Quick Access Links";
                    } else if (option === "4") {
                      return "Dashboard";
                    } else if(option === "1") {
                      return "Summary";
                    }
                  }}
                  defaultValue={this.state.homePageAdmin}
                  value={this.state.homePageAdmin}
                  onChange={(e, value) => {
                    this.setState({ homePageAdmin: value });
                  }}
                  renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                />

              </div>
            </div>

            {this.state.homePageAdmin === '4' && <div className={classes.formGroup}>
              <div>
                <label className={classes.label}>Select Dashboard</label>
                <FormControl variant="outlined" size="small" className={classes.dashboardSelect}>
                  <Select value={this.state.homePageDashboard} onChange={(e) => {
                    this.setState({ homePageDashboard: e.target.value })
                  }}>
                    {this.props.dashboards?.data && this.props.dashboards?.data?.map((da) => {
                      return <MenuItem value={da?.dashboardID} style={{ fontSize: 13 }}>{da?.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>}
            <div className={classes.action}>
                {!loading && <Button variant="contained" color="secondary" className={classes.sendBtn} onClick={this.saveConfig}>Update</Button>}
                {loading && <CircularProgress />}
            </div>
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(GeneralConfig));