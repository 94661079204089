export const SHOW_PRODUCT_MANUALLY_DIALOG = "SHOW_PRODUCT_MANUALLY_DIALOG";
export const HIDE_PRODUCT_MANUALLY_DIALOG = "HIDE_PRODUCT_MANUALLY_DIALOG";


export function showAddProductManuallyDialog(query){
    return {
        type: SHOW_PRODUCT_MANUALLY_DIALOG,
        payload: query || null
    };
}

export function hideAddProductManuallyDialog(){
    return {
        type: HIDE_PRODUCT_MANUALLY_DIALOG,
        payload:null
    };
}
