import {
  START_MASTER_FORMS_FETCH, END_MASTER_FORMS_FETCH,
  START_FETCH_MASTER_FORM_BY_ID,END_FETCH_MASTER_FORM_BY_ID
} from "./action";

const initState = {
  fetchMasterFormProgress: false,
  forms : [],

  fetchMasterFormsByIdProgress: false,
  masterFormData: null
}

export function masterFormReducer(state = initState, action) {
  switch (action.type) {
    case START_MASTER_FORMS_FETCH:
      return {
        ...state,
        fetchMasterFormProgress: true,
      };

    case END_MASTER_FORMS_FETCH:
      return {
        ...state,
        fetchMasterFormProgress: false,
        forms : action.payload.success
      }

      
    case START_FETCH_MASTER_FORM_BY_ID:
      return {
        ...state,
        fetchMasterFormsByIdProgress: true,
        masterFormData: null
      }
    case END_FETCH_MASTER_FORM_BY_ID:
      return {
        ...state,
        fetchMasterFormsByIdProgress: false,
        masterFormData: action.payload.success
      }

    default:
      return state;
  }
}