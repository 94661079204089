import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Checkbox, CircularProgress, Button, ButtonGroup, Tooltip } from "@material-ui/core";
import AppBarV2 from "Components/Application/AppBarV2";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import SearchInput from "Components/Common/Input/SearchInput";
import ProductTypeFilter from "Components/Common/Filters/ProductTypeFilter";
import PostAddIcon from "@material-ui/icons/PostAdd";
// redux
import { updateDrawerStyle } from "redux/drawer/action";
import { showAddVendorDialog } from "redux/vendor/vendorCatalog/action";
import { createEvaluationCart, removeProductFromCart, fetchEvaluationCart } from "redux/evaluation/cart/action";
import { showSnackBar } from "redux/snackbar/action";
import { hideClusterProductsDialog } from "redux/product/cluster/action";

import CommonCss from "commonCss";
import ListIcon from "@material-ui/icons/List";
import AppsIcon from "@material-ui/icons/Apps";
import { matchPath } from "react-router-dom";
import { isEnabledReview } from "util/Common";

import Breadcrumb from "Components/Common/Breadcrumb";
import { storeSearchString } from "redux/applications/action";
import { fetchVendorCatalog } from "redux/vendor/vendorCatalog/action";
import { showVendorProductsAndServicesDialog } from "redux/vendor/action";
import AddVendorOptionDialog from "Components/Common/Dialogs/AddVendorOptionDialog";
import VendorServiceTabular from "./VendorServiceTabular";
import { storeClusterGroupProducts } from "redux/product/cluster/action";
import { fetchOrgGroupVendors, updateOrgGroupVendor } from "redux/org/group/action";
import SqureButton from "Components/Common/SqureButton";
import VendorProductsAndServicesDialog from "Components/Common/Dialogs/VendorProductsAndServicesDialog";
import { fetchOrgCatalogDetailsTabs } from "redux/vendor/vendorCatalog/action";

import { fetchVendorServiceCatalog } from "redux/vendor/vendorServiceCatalog/action";
import { fetchAppCatlogProducts } from "redux/product/appCatalog/action";

const connectedProps = (state) => ({
  addVendorDialogOpen: state.vendorCatalog.addVendorDialogOpen,
  evaluationCart: state.evaluationCart.evaluation,
  userType: state.authUser.user?.Role,
  orgUser: state.orgUser,
  orgName: state.authUser.user?.Organization?.Name,
  searchString: state.applications.searchString,
  searchType: state.applications.searchType,

  updateOrgCatalogStateProgress: state.vendorCatalog.updateOrgCatalogStateProgress,
  updateOrgCatalogStateError: state.vendorCatalog.updateOrgCatalogStateError,

  fetchVendorCatalogProgress: state.vendorCatalog.fetchVendorCatalogProgress,
  fetchVendorCatalogError: state.vendorCatalog.fetchVendorCatalogError,
  VendorCatalog: state.vendorCatalog.VendorCatalog,
  orgVendorAddProgress: state.vendorCatalog.orgVendorAddProgress,

  clusterProducts: state.clusterProductReducer.storeClusterGroupProducts,
  fetchGroupVendorProg: state.orgGroup.fetchGroupVendorProg,
  groupVendors: state.orgGroup.groupVendors,
  updateGroupVendorProg: state.orgGroup.updateGroupVendorProg,
  updateGroupVendorError: state.orgGroup.updateGroupVendorError,
  openVendorProductsAndServiceDialog: state.vendors.openVendorProductsAndServiceDialog,

  orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,
  orgCatalogDetailTabsProgress: state.vendorCatalog.orgCatalogDetailTabsProgress,

  fetchVendorServiceCatalogProgress: state.vendorServiceCatalog.fetchVendorServiceCatalogProgress,
  fetchVendorServiceCatalogError: state.vendorServiceCatalog.fetchVendorServiceCatalogError,
  VendorServiceCatalog: state.vendorServiceCatalog.VendorServiceCatalog,

  fetchAppCatalogProductsProgress: state.appCatalog.fetchAppCatalogProductsProgress,
  fetchAppCatalogProductsError: state.appCatalog.fetchAppCatalogProductsError,
  appCatalogProducts: state.appCatalog.products,
});

const connectionActions = {
  hideDialog: hideClusterProductsDialog,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  showAddVendorDialog: showAddVendorDialog,
  updateDrawerStyle: updateDrawerStyle,
  fetchEvaluationCart: fetchEvaluationCart,
  showSnackBar: showSnackBar,
  storeClusterGroupProducts: storeClusterGroupProducts,
  fetchVendorCatalog: fetchVendorCatalog,
  storeSearchString: storeSearchString,
  fetchOrgGroupVendors: fetchOrgGroupVendors,
  updateOrgGroupVendor: updateOrgGroupVendor,
  showVendorProductsAndServicesDialog: showVendorProductsAndServicesDialog,
  fetchOrgCatalogDetailsTabs: fetchOrgCatalogDetailsTabs,
  fetchVendorServiceCatalog: fetchVendorServiceCatalog,
  fetchAppCatlogProducts: fetchAppCatlogProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 2),
    },
    rootTable: {
      padding: theme.spacing(2, 2, 0, 2),
    },
    productMain: {
      padding: theme.spacing(2, 2, 0, 2),
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
      textAlign: "center",
    },
    emptyText: {
      cursor: "pointer",
      textAlign: "center",
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
      paddingLeft: 20,
    },
    productContainer: {
      maxHeight: "calc(100vh - 250px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 250px)",
      padding: theme.spacing(4, 0),
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    label: {
      fontSize: "1.4em",
      fontWeight: 600,
      lineHeight: "1.6",
      marginBottom: 10,
    },
    productHead: {
      ...CommonCss.productHead,
      fontSize: 18,
      margin: "0px !important",
    },
    productMenu: {
      background: "#FFFFFF",
      boxShadow: "0px 2px 10px #0000000B",
      padding: theme.spacing(4, 0, 4, 2.8),
      width: 270,
      minHeight: "100vh",
      position: "fixed",
    },
    menuHead: {
      display: "flex",
      marginBottom: 18,
    },
    container: {
      // display: "flex",
    },
    recommendedText: {
      fontSize: 12,
      fontWeight: 500,
      color: "#2EB77D",
      textDecoration: "underline",
      marginTop: 10,
      width: "max-content",
    },
    topContainer: {
      marginTop: theme.spacing(3),
    },
    headSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterSection: {
      ...CommonCss.filterSection,
      marginRight: 20,
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
    },
    title: {
      fontSize: theme.spacing(2.4),
      flex: 1,
    },
    categoriesText: {
      color: "#000000",
      fontSize: 18,
      marginLeft: 16,
      fontWeight: 500,
    },

    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    main: {
      maxWidth: "calc(100% - 280px)",
      flexBasis: "calc(100% - 280px)",
    },

    innerText: {
      color: "#696B6E",
      padding: theme.spacing(1, 1.5, 1, 1),
      cursor: "pointer",
      display: "block",
      margin: 0,
    },
    "@global": {
      ".applicationContent": {
        padding: "0 !important",
      },
    },
    products: {
      display: "flex",
    },

    filter: {
      // flex: 1,
      textAlign: "right",
    },
    txtCenter: {
      textAlign: "center",
      marginBottom: 16,
    },

    productsSection: {
      padding: "1em",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },

    isAllProducts: {
      background: "#ff0000a3",
    },
    isExistingProducts: {
      color: "white",
      background: "#2EB77D",
    },
    isFeaturedProducts: {
      color: "white",
      background: "#ff9800",
    },
    isGroupProducts: {
      width: "18px",
      marginRight: "10px",
      marginLeft: "2px",
    },
    isOtherProducts: {
      color: "white",
      background: "#2e81da",
    },
    productCounts: {
      fontSize: "12px",
      marginLeft: "auto",
      fontWeight: "600",
      color: "#4a87f8",
    },
    viewAll: {
      color: "#4A87F8",
      fontSize: "14px",
      paddingRight: "10px",
      cursor: "pointer",
    },
    flexWrapIcons: {
      display: "flex",
      gap: "20px",
      marginTop: 40,
      justifyContent: "center",
    },
    flexWrapButtonRight: {
      display: "flex",
      alignItems: "center",
      margin: "15px 30px 0px",
      justifyContent: "space-between",
    },
    flexWrapButtonRight2: {
      display: "flex",
      alignItems: "center",
      margin: "15px 30px 0px",
      gap: 30,
    },
    productCard: {
      position: "relative",
    },
    selectIcon: {
      position: "absolute",
      zIndex: 22,
      right: 5,
      top: 5,
      color: "#dcd7d7",
      cursor: "pointer",
    },
    disabledDiv: {
      pointerEvents: "none",
    },
    teamNote: {
      fontSize: "0.9rem",
    },
    teamNote2: {
      fontSize: "0.95rem",
    },
    required: {
      color: "red",
    },
    clearFilterBtn: {
      marginLeft: 10,
      padding: "4px 20px",
    },
  });

class OrgVendorCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerSectionWidth: null,
      selectedProduct: null,
      isProduct: false,
      appCatalogObj: {
        field_setname: "all",
        text: "",
        filter_fields: {},
        analytics_only: false,
        qtype_page: null,
        page_size: 0,
        sort_fields: [],
      },
      queryParams: queryString.parse(this.props.location.search),
      filters: {
        sortby: "relevance",
        order: "",
      },
      isCreateGroup: false,
      selectedGroupProduct: [],
      selectedState: [],
      searchObj: {
        field_setname: "all",
        text: "",
        page: 0,
        page_size: 30,
      },
      showActionMenu: null,
      lastLocation: this.props.lastLocation,
      productType: "VENDOR",
      searchRestored: false,
      selectedView: "grid",
      showGroupView: false,
      teamId: null,
      vendorTeamProductsAndServices: {},
      searchFilterOptions: [],
      catalogFieldValue: "",
      hardFilter: {},
      hardFilterMessageData: null,
      isFetchProductCatalog: false,
      isFetchServiceCatalog: false,
      orgExistingProductCount: 0,
      orgExistingServiceCount: 0,
    };
  }

  componentDidMount() {
    this.checkDataOnLoad();
    this.props.fetchOrgCatalogDetailsTabs("vendor");
    const urlPrams = new URLSearchParams(this.props.location.search);
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);

    var evId = urlPrams.get("evaluationId");
    if (evId !== null) {
      setTimeout(() => {
        this.props.fetchEvaluationCart(evId);
      }, 1000);
    }
    if (urlPrams.get("activeView") === "table") {
      this.setState({ selectedView: "table" });
    } else {
      if (urlPrams.get("s") !== null) {
        this.setState({ searchObj: { ...this.state.searchObj, text: urlPrams.get("s") } }, () => this.fetchData());
      } else {
        this.fetchData();
      }
    }
    var updateTeamVendors = urlPrams.get("updateTeamVendors");

    if (updateTeamVendors !== null && (updateTeamVendors === "true" || updateTeamVendors === true)) {
      this.setState({ showGroupView: true });
      this.props.storeClusterGroupProducts([]);
      var teamId = urlPrams.get("teamId");
      this.setState({ teamId: teamId });
      this.props.fetchOrgGroupVendors(teamId, 0, 60);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { lastLocation } = this.state;
    var pathName = lastLocation?.pathname;
    var isMatched = false;
    if (pathName !== undefined) {
      if (
        matchPath(pathName, {
          path: "/app/products/org-product-details/:id",
          exact: true,
          strict: false,
        })
      ) {
        isMatched = true;
      }
    }

    if (isMatched && pathName && this.props.searchString !== "" && this.state.searchRestored === false) {
      this.setState(
        {
          searchObj: { ...this.state.searchObj, field_setname: this.props.searchType, text: prevProps.searchString },
          searchRestored: true,
        },
        () => {
          this.fetchData();
        }
      );
    }

    if (prevProps.orgVendorAddProgress && !this.props.orgVendorAddProgress) {
      this.fetchData();
    }

    if (!this.props.updateOrgCatalogStateProgress && prevProps.updateOrgCatalogStateProgress) {
      if (this.props.updateOrgCatalogStateError) {
        this.props.showSnackBar("Failed to update", "error", 3000);
      } else {
        this.props.showSnackBar("Updated successfully", "success", 3000);
        this.setState(
          {
            searchObj: { ...this.state.searchObj, page_size: 60, page: 0 },
          },
          () => {
            this.fetchData();
          }
        );
      }
    }

    if (prevProps.fetchGroupVendorProg && !this.props.fetchGroupVendorProg) {
      let vendorsArray = this.props.groupVendors || [];
      let selectedVendorObj = {};
      vendorsArray.forEach((vendor) => {
        selectedVendorObj[vendor.id] = {
          productIds: vendor?.existing_products?.map((o) => o.master_product_id) || [],
          serviceIds: vendor?.existing_services?.map((o) => o.id) || [],
        };
      });
      this.setState({ vendorTeamProductsAndServices: selectedVendorObj });
    }

    if (prevProps.updateGroupVendorProg && !this.props.updateGroupVendorProg) {
      if (this.props.updateGroupVendorError) {
        this.props.showSnackBar("Something went wrong, please try again later.", "error", 3000);
      } else {
        this.props.showSnackBar("Updated successfully", "success", 3000);
        this.props.history.push("/app/settings/users");
      }
    }

    if (!this.props.orgCatalogDetailTabsProgress && prevProps.orgCatalogDetailTabsProgress) {
      this.initialiseFilterData();
    }

    if (!this.props.fetchVendorServiceCatalogProgress && prevProps.fetchVendorServiceCatalogProgress) {
      this.setState(
        {
          isFetchServiceCatalog: true,
        },
        () => {
          this.checkTotalProducts();
        }
      );
    }

    if (!this.props.fetchAppCatalogProductsProgress && prevProps.fetchAppCatalogProductsProgress) {
      this.setState(
        {
          isFetchProductCatalog: true,
        },
        () => {
          this.checkTotalProducts();
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.storeClusterGroupProducts([]);
  }

  checkDataOnLoad() {
    this.props.fetchAppCatlogProducts(this.state.appCatalogObj);

    this.props.fetchVendorServiceCatalog({ query: "", page: 0, page_size: 6, filters: { is_org: "1" } });
  }

  checkTotalProducts() {
    const { isFetchProductCatalog, isFetchServiceCatalog } = this.state;
    const { appCatalogProducts, VendorServiceCatalog } = this.props;
    if (isFetchProductCatalog && isFetchServiceCatalog && appCatalogProducts && VendorServiceCatalog) {
      let orgExistingProductCount = this.props.appCatalogProducts?.existing?.total || 0;
      let orgExistingServiceCount = this.props.VendorServiceCatalog?.total || 0;
      this.setState({
        orgExistingProductCount: orgExistingProductCount,
        orgExistingServiceCount: orgExistingServiceCount,
      });
    }
  }

  initialiseFilterData = () => {
    if (!this.props.orgCatalogDetailTabs.length) return;
    let tabs = this.props.orgCatalogDetailTabs;
    let fields = [];
    tabs.forEach((tab) => {
      tab.sections.forEach((section) => {
        section.items.forEach((item) => {
          if ([1, 4, 5, 6].includes(item.type)) {
            let itemName = item.name;
            if (!itemName || itemName.length === 0) {
              itemName = section.name;
            }
            fields.push({
              id: item.id,
              slug: item.slug,
              name: itemName,
              type: item.type,
              is_default: item.is_default,
              additional_data: item.additional_data,
            });
          }
        });
      });
    });

    this.setState({
      searchFilterOptions: fields,
    });
  };

  handleUpdateTeamVendors = () => {
    let vendorsData = this.state.vendorTeamProductsAndServices;
    let submitData = [];
    Object.keys(vendorsData).forEach((key) => {
      submitData.push({
        vendor_id: key,
        entities: [
          // {
          //   type: "product",
          //   ids: vendorsData[key]?.productIds || [],
          // },
          // {
          //   type: "vendorservice",
          //   ids: vendorsData[key]?.serviceIds || [],
          // },
        ],
      });
    });
    if (this.props.groupVendors?.length === 0 && submitData.length <= 0) {
      this.props.showSnackBar("Please select vendors", "error", 3000);
    } else {
      this.props.updateOrgGroupVendor(this.state.teamId, submitData);
    }
  };

  // handleClusterVendor = (vendor) => {
  //   let vendors = [...this.props.clusterProducts];
  //   if (vendors.findIndex((obj) => obj.org_vendor_id === vendor.org_vendor_id) < 0) {
  //     const data = {
  //       org_vendor_id: vendor.org_vendor_id,
  //       is_existing: true,
  //       name: vendor.name,
  //       logo: vendor.logo,
  //     };
  //     vendors.push(data);
  //   } else {
  //     vendors = vendors.filter((o) => o.org_vendor_id !== vendor.org_vendor_id);
  //   }
  //   this.props.storeClusterGroupProducts(vendors);
  // };

  fetchData() {
    const { searchObj } = this.state;
    let queryParams = {
      query: searchObj.text,
      page_size: searchObj.page_size,
      page: searchObj.page,
      filters: searchObj.filters || {},
    };
    if (this.state.hardFilter && this.state.hardFilter.selectedFilter?.type) {
      let nested_key = "";
      let value = this.state.hardFilter.selectedValue;
      if ([4, 5].includes(this.state.hardFilter.selectedFilter?.type) || [7, 8].includes(this.state.hardFilter.selectedOption?.type)) {
        nested_key = "value";
        if ([4, 5].includes(this.state.hardFilter.selectedFilter?.type)) {
          value = this.state.hardFilter.selectedOption?.value;
        }
      }
      if ([7, 8].includes(this.state.hardFilter.selectedOption?.type)) {
        value = this.state.hardFilter.selectedValue?.ID;
      }

      let data = {
        item_slug: this.state.hardFilter.selectedFilter?.slug,
        nested_key: nested_key,
        value: value,
        regex: this.state.hardFilter.selectedFilter?.type === 1 || this.state.hardFilter.selectedOption?.type === 1 ? true : false,
      };
      if (this.state.hardFilter.selectedFilter?.type === 6) {
        data["label"] = String(this.state.hardFilter.selectedOption?.value);
      }
      queryParams["hard_filter"] = data;
    }
    queryParams.filters.states = this.state.selectedState;
    this.props.fetchVendorCatalog({ ...queryParams, filters: { ...queryParams.filters, is_org: "1" } });
  }

  productStates = (arr) => {
    if (arr) {
      const data = arr.map((state) => ({
        StateName: state.name,
        StateID: state.name,
      }));
      return data;
    } else {
      return [];
    }
  };

  setSearchType = (type) => {
    const searchObj = this.state.searchObj;
    if (type === "features") {
      searchObj.field_setname = "features";
    } else if (type === "subcategories") {
      searchObj.field_setname = "subcategories";
    }

    searchObj.qtype_page = null;
    searchObj.text = "";
    this.setState({ searchObj }, () => {
      this.fetchData();
    });
  };

  handleActionClick = (event) => {
    this.setState({ showActionMenu: event.currentTarget });
  };

  handleActionClose = () => {
    this.setState({ showActionMenu: null });
  };

  handleRedirect = (type) => {
    if (type === "VENDOR") {
      this.props.history.push(`/app/vendors/org-vendors`);
    } else if (type === "SOFTWARE") {
      this.props.history.push(`/app/products/org-products`);
    } else if (type === "HARDWARE") {
      this.props.history.push(`/app/vendors/org-hardwares`);
    } else if (type === "SERVICE") {
      this.props.history.push(`/app/vendors/org-services`);
    }
  };

  handleOnClickState = (newState) => {
    let selectedState = [...this.state.selectedState];
    if (selectedState.includes(newState)) {
      selectedState = selectedState.filter((state) => state !== newState);
    } else {
      selectedState.push(newState);
    }
    this.setState(
      {
        selectedState,
        searchObj: { ...this.state.searchObj, page_size: 60, page: 0 },
      },
      () => {
        this.fetchData();
      }
    );
  };

  addToCart(id, type = "CUSTOM_VENDOR") {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(id, null, type);
    } else {
      var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.filter((p) => parseInt(p.EntityID) === parseInt(id));
      if (addedToCart && addedToCart.length > 0) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, parseInt(addedToCart[0].EntityObjectID));
      } else {
        this.props.createEvaluationCart(id, this.props.evaluationCart.ID, type);
      }
    }
  }

  // updateVendorProductsAndServices = (vendorId, productIds, serviceIds) => {
  updateVendorProductsAndServices = (vendorId) => {
    let newData = { ...this.state.vendorTeamProductsAndServices };
    if (newData[vendorId]) {
      delete newData[vendorId];
    } else {
      newData[vendorId] = {
        // productIds,
        // serviceIds,
      };
    }

    this.setState({ vendorTeamProductsAndServices: newData });
  };

  isSelectedGroupVendor = (id) => {
    const products = this.props.clusterProducts || [];
    return products.findIndex((obj) => obj.org_vendor_id === id) > -1;
  };

  render() {
    const { classes, VendorCatalog, fetchVendorCatalogProgress, updateGroupVendorProg } = this.props;
    let userRole = ["OrgAdmin", "OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;
    var showAddProduct = false;
    const EnableAddProductForUserCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableAddProductForUserCheck");
    if (this.props.userType === "OrgUser") {
      if (EnableAddProductForUserCheck) {
        showAddProduct = true;
      }
    } else {
      showAddProduct = true;
    }
    const { searchObj } = this.state;

    var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.map((p) => parseInt(p.EntityID));

    return (
      <div className={classes.container}>
        <AppBarV2 title={this.props.orgName ? this.props.orgName + "'s Vendor Catalog" : "Vendor Catalog"} withBack={true} dropdown={true} />
        <Breadcrumb routeList={[{ name: "Vendors", path: "/app/products" }, { name: "Vendor Catalog" }]} />
        <div className={classes.productMain}>
          {this.state.selectedView === "grid" && (
            <div className={classes.stickyBar}>
              {!this.state.showGroupView && (
                <ProductTypeFilter
                  type={this.state.productType}
                  hideHardware
                  onChange={(type) => {
                    this.handleRedirect(type);
                  }}
                  isShowVendorTab={this.state.orgExistingProductCount > 0 || this.state.orgExistingServiceCount > 0 ? true : false}
                  isShowProductTab={this.state.orgExistingProductCount > 0 ? true : false}
                  isShowServiceTab={this.state.orgExistingServiceCount > 0 ? true : false}
                />
              )}

              <Grid
                container
                justifyContent="space-between"
                ref={(divElement) => {
                  this.divElement = divElement;
                }}
                className={classes.productSectionNew}
              >
                <Grid item>
                  <p className={classes.productCount}>{this.props.VendorCatalog?.total} Vendor's found</p>
                </Grid>

                <Grid item>
                  <SearchInput
                    page="vendor_catalog"
                    roundedCorner
                    searchFilterOptions={this.state.searchFilterOptions}
                    showTypes={false}
                    filters={this.state.filters}
                    searchObj={this.state.searchObj}
                    placeholder="Search Vendors"
                    onChange={(searchObj) => {
                      this.setState({ searchObj: { ...this.state.searchObj, page_size: 20, text: searchObj.text, page: 0 } }, () => {
                        this.props.storeSearchString(searchObj.text);
                        this.fetchData();
                      });
                    }}
                    selectedState={this.state.selectedState}
                    hardFilter={this.state.hardFilter}
                    onChangeState={(newState) => {
                      this.handleOnClickState(newState);
                    }}
                    clearFilterMessageData={() => this.setState({ hardFilterMessageData: null })}
                    onCatalogFieldFilter={(value) => {
                      this.setState({ catalogFieldValue: value || "" }, () => {
                        this.fetchData();
                      });
                    }}
                    onChangeFilter={(filters) => {
                      this.setState({ hardFilter: filters, searchObj: { ...this.state.searchObj, page: 0 } }, () => {
                        this.fetchData();
                      });
                    }}
                    showState
                    type="vendor"
                  />
                </Grid>
                <Grid item style={{ minWidth: 160 }}>
                  {(adminFlag || showAddProduct) && !this.state.showGroupView && (
                    <div className={classes.flexWrapIcons}>
                      <Tooltip title={<span style={{ fontSize: "13px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>Add Vendor</span>} placement="top" arrow>
                        <Button
                          color="primary"
                          variant={"contained"}
                          onClick={() => {
                            this.props.showAddVendorDialog();
                          }}
                        >
                          <PostAddIcon />
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          )}

          {this.state.hardFilter && this.state.hardFilter.selectedFilter && (
            <div className={classes.flexWrapButtonRight2}>
              <div className={classes.teamNote2}>
                Showing results for {this.state.hardFilter.selectedFilter.name} and{" "}
                {[4, 5, 6].includes(this.state.hardFilter.selectedFilter.type) ? this.state.hardFilter.selectedOption?.label : this.state.hardFilter.selectedValue}
              </div>
              <div>
                <Button
                  className={classes.clearFilterBtn}
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.setState({ hardFilter: {}, searchObj: { ...this.state.searchObj, page: 0 } }, () => {
                      this.fetchData();
                    });
                  }}
                >
                  Clear Filter
                </Button>
              </div>
            </div>
          )}

          {adminFlag && (
            <>
              {this.state.showGroupView ? (
                <div className={classes.flexWrapButtonRight}>
                  <div className={classes.teamNote}>
                    Please click on vendor below to select <span className={classes.required}>*</span>
                  </div>
                  <div>
                    <SqureButton
                      variant="contained"
                      color="secondary"
                      disabled={updateGroupVendorProg}
                      onClick={() => {
                        this.handleUpdateTeamVendors();
                      }}
                      style={{ marginRight: 15 }}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                    >
                      Update Team Vendors
                    </SqureButton>
                    <SqureButton
                      aria-controls="simple-menu"
                      variant="outlined"
                      color="secondary"
                      aria-haspopup="true"
                      onClick={() => {
                        this.props.storeClusterGroupProducts([]);
                        this.props.history.goBack();
                      }}
                    >
                      Cancel
                    </SqureButton>
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: "right", marginBottom: 20, paddingRight: "30px" }}>
                  <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Tooltip title="Grid View">
                      <Button
                        variant={this.state.selectedView === "grid" ? "contained" : "outlined"}
                        onClick={() => {
                          this.setState(
                            {
                              selectedView: "grid",
                            },
                            () => {
                              this.props.history.push("/app/vendors/org-vendors");
                            }
                          );
                          this.fetchData();
                        }}
                      >
                        <AppsIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Tabular View">
                      <Button
                        variant={this.state.selectedView === "table" ? "contained" : "outlined"}
                        onClick={() => {
                          this.setState(
                            {
                              selectedView: "table",
                            },
                            () => {
                              this.props.history.push("/app/vendors/org-vendors?activeView=table");
                            }
                          );
                        }}
                      >
                        <ListIcon />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </div>
              )}
            </>
          )}

          {this.state.selectedView === "grid" && (
            <div className={classes.productContainer} id="productContainer">
              <InfiniteScroll
                scrollableTarget="productContainer"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  marginRight: 20,
                  paddingLeft: 20,
                }}
                dataLength={VendorCatalog?.items?.length || 0}
                next={() => {
                  this.setState({ searchObj: { ...searchObj, page: searchObj.page + 1 } }, () => {
                    this.fetchData();
                  });
                }}
                hasMore={VendorCatalog?.total > VendorCatalog?.items?.length}
              >
                {VendorCatalog && (
                  <Grid container spacing={3} style={{ marginBottom: 10 }}>
                    {VendorCatalog?.items?.map((vendor, index) => (
                      <Grid item lg={2} md={3} sm={4} xs={6} key={index}>
                        {this.state.showGroupView ? (
                          <div
                            className={classes.productCard}
                            onClick={() => {
                              // this.props.showVendorProductsAndServicesDialog(vendor);
                              this.updateVendorProductsAndServices(vendor?.org_vendor_id);
                            }}
                          >
                            <div className={classes.selectIcon}>
                              <Checkbox
                                disabled
                                // checked={
                                //   this.state.vendorTeamProductsAndServices[vendor.org_vendor_id]?.productIds?.length > 0 ||
                                //   this.state.vendorTeamProductsAndServices[vendor.org_vendor_id]?.serviceIds?.length > 0
                                //     ? true
                                //     : false
                                // }
                                checked={this.state.vendorTeamProductsAndServices[vendor.org_vendor_id] ? true : false}
                                style={{ color: "#1f73b7" }}
                              />
                            </div>
                            <LayeredCard
                              title={vendor.name || ""}
                              subTitle={vendor.name || ""}
                              description={vendor.description || ""}
                              image={vendor.logo || ""}
                              onClick={() => {}}
                              numLayer={0}
                              showCheckbox={false}
                              id={parseInt(vendor.id)}
                              uniqueId={vendor.id + "_" + index}
                              isExisting={true}
                            />
                          </div>
                        ) : (
                          <LayeredCard
                            title={vendor.name || ""}
                            subTitle={vendor.name || ""}
                            description={vendor.description || ""}
                            cardType={"vendor"}
                            isActionButtons
                            image={vendor.logo || ""}
                            onClick={() => {
                              this.props.history.push("/app/vendors/org-vendor-details/" + vendor.org_vendor_id);
                            }}
                            numLayer={0}
                            id={parseInt(vendor.id)}
                            uniqueId={vendor.id + "_" + index}
                            type={"vendor"}
                            isExisting={true}
                            org_vendor_id={vendor.org_vendor_id}
                            productStates={this.productStates(vendor.states)}
                            onClickState={(state) => this.handleOnClickState(state)}
                            typeOfBlock="all_vendor_blocking"
                            typeOfFeatured="featured"
                            showCheckbox
                            checked={addedToCart.indexOf(parseInt(vendor.org_vendor_id)) !== -1}
                            toogleCheck={(e) => {
                              this.addToCart(vendor.org_vendor_id);
                            }}
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                )}
                {(fetchVendorCatalogProgress || updateGroupVendorProg) && (
                  <Grid container justify="center" style={{ marginTop: 30 }}>
                    <CircularProgress />
                  </Grid>
                )}
              </InfiniteScroll>
            </div>
          )}
          {this.state.selectedView === "table" && (
            <div style={{ padding: "0rem 2rem" }}>
              <VendorServiceTabular type="vendor" />
            </div>
          )}
        </div>
        {/* {this.props.openVendorProductsAndServiceDialog && (
          <VendorProductsAndServicesDialog updateVendorProductsAndServices={this.updateVendorProductsAndServices} selectedData={this.state.vendorTeamProductsAndServices} />
        )} */}

        {this.props.addVendorDialogOpen && <AddVendorOptionDialog type={"vendor"} />}
      </div>
    );
  }
}

export default connector(compose(withStyles(styles), withLastLocation)(OrgVendorCatalog));
