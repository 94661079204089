import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { fetchProjectSchema } from "redux/workflow/action";
import { CircularProgress } from "@material-ui/core";
import SchemaNodes from "./SchemaNodes";
const connectedProps = (state) => ({
  fetchProjectSchemaProgress: state.workflow.fetchProjectSchemaProgress,
  fetchProjectSchemaError: state.workflow.fetchProjectSchemaError,
  projectSchema: state.workflow.projectSchema,
});

const connectionActions = {
  fetchProjectSchema: fetchProjectSchema
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  schemaRoot: {
    marginTop: 20,
    maxHeight: 500,
    overflow: 'auto'
  },
  tagName: {
    cursor: 'pointer',
    border: '1px solid #c2c2c2',
    borderRadius: 4,
    background: '#fff',
    margin: 0,
    marginBottom: 5,
    padding: 5
  },
  childTagName: {
    cursor: 'pointer',
    border: '1px solid #c2c2c2',
    borderRadius: 4,
    background: '#fff',
    margin: 0,
    marginBottom: 5,
    marginLeft: 15,
    padding: 5
  },
  loader: {
    textAlign: 'center',
    marginTop: 10
  },
  subChild: {
    paddingLeft: '20px'
  },
});

class Schema extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      openCloseMap: {}
    }
    this.getTagsWithId = this.getTagsWithId.bind(this)
  }

  componentDidMount() {
    if (this.props.loadEverytime) {
      this.props.fetchProjectSchema(this.props.workflowId)
    } else {
      if (this.props.projectSchema === undefined || this.props.projectSchema === null || this.props.projectSchema === '') {
        this.props.fetchProjectSchema(this.props.workflowId)
      } else {
        this.setState({
          tags: this.props.projectSchema
        })
      }
    }
  }

  getTagsWithId(tags, finalData) {
    let me = this;
    tags.forEach(function (tag) {
      if (tag.childrens) {
        finalData[tag.label] = false;
        me.getTagsWithId(tag.childrens, finalData);
      }
    })
    return finalData;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.fetchProjectSchemaProgress && prevProps.fetchProjectSchemaProgress) {
      if (this.props.fetchProjectSchemaError === null) {
        // console.log(this.props.projectSchema, 'projectSchema')
        this.setState({
          tags: this.props.projectSchema
        })
      }
    }
  }

  handleClick(tag, additionalData) {
    // console.log(additionalData, 'additionalData1')
    this.props.handleClick(tag, additionalData)
  }

  openCloseTag(index) {
    let tags = this.state.tags;
    let tag = tags[index];
    tag.isOpen = tag.isOpen ? false : true;
    this.setState({ tags })
  }

  render() {
    const { tags } = this.state;
    const { classes } = this.props;

    if (this.props.fetchProjectSchemaProgress) {
      return <div className={classes.loader}>
        <CircularProgress />
      </div>
    }
    return (
      <div className={classes.schemaRoot}>
        {tags && tags.map((tag, index) => {
          let isDisabled = false;
          if(this.props.isFromSlate && !tag.isSlate){
            isDisabled=true
          }
          return <SchemaNodes
            key={tag.label}
            index={index}
            tag={tag}
            classes={this.props.classes}
            handleClick={(data, additional_data) => {
              this.handleClick(data, additional_data)
            }}
            isDisabled={isDisabled}
            isFromSlate={this.props.isFromSlate}
          />
        })}
      </div>
    );
  }
}

Schema.defaultProps = {
  loadEverytime: true,
  isFromSlate:false
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Schema));
