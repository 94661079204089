import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppBarV2 from "Components/Application/AppBarV2";
import { Grid } from "@material-ui/core";
import {
  Typography,
  Divider,
  ListItemIcon,
  ListItem,
  List,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SqureButton from "Components/Common/SqureButton";
import CheckIcon from "@material-ui/icons/Check";
import classnames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import {
  fetchNotifications,
  markAsRead,
  markAllAsRead,
} from "redux/notification/action";
import { getMessage } from "util/Notification";
import moment from "moment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Logo from "assets/images/logo/logo.svg";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
  user: state.authUser.user,
  fetchNotificationProgress: state.notifications.fetchNotificationProgress,
  fetchNotificationError: state.notifications.fetchNotificationError,
  notifications: state.notifications.notifications,
  totalNotifications: state.notifications.totalNotifications,
  markAsReadProgress: state.notifications.markAsReadProgress,
  toastNotificationCount: state.notifications.toastNotificationCount,
});

const connectionActions = {
  fetchNotifications: fetchNotifications,
  markAsRead: markAsRead,
  markAllAsRead: markAllAsRead,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    bodyItem: {
      padding: 30,
    },
    leftMenu: {},
    rightPart: {
      paddingLeft: 20,
    },
    notificationMenu: {
      background: "#fff",
      '& [class*="MuiListItem-root"]': {
        cursor: "pointer",
        padding: 20,
        // '& :hover': {
        //   background: '#f7f7f7',
        // }
      },
    },
    notificationList: {
      background: "#fff",
    },
    listItem: {
      paddingTop: theme.spacing(2),
    },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    notificationHead: {
      padding: "16px 20px",
      borderBottom: "1px solid #f7f7f7",
    },
    notificationBody: {
      height: "80vh",
      overflow: "scroll",
    },
    notificationTitle: {
      fontSize: 16,
      fontWeight: 500,
    },
    notificationCard: {
      display: "flex",
      alignItems: "center",
      background: "#fff",
      padding: "16px 20px",
      // '& :hover': {
      //   background: '#f7f7f7'
      // }
    },
    notificationItem: {
      borderLeft: "1px solid #fff",
      borderRight: "1px solid #fff",
      "& :hover": {
        background: "#f7f7f7",
      },
    },
    msgContainer: {
      flex: 7,
      cursor: "pointer",
    },
    timeContainer: {
      flex: 2,
      justifyContent: "right",
      textAlign: "right",
    },
    checkContainer: {
      flex: 1,
      justifyContent: "center",
      textAlign: "center",
    },
    micon: {
      fontSize: 16,
      marginTop: 6,
      color: "#4b86f8",
      width: 30,
      height: 30,
      "& :hover": {
        background: "#f7f7f7",
        borderRadius: 10,
      },
    },
    tooltipMsg: {
      fontSize: "14px",
      whiteSpace: "pre-line",
      display: "inline-block",
      height: "16px",
      lineHeight: "16px",
    },
    tickBar: {
      flex: 1,
      cursor: "pointer",
      "& :hover": {
        background: "#f7f7f7",
        borderRadius: 10,
        padding: 2,
      },
    },
    activeMenu: {
      background: "#1f73b7",
      color: "#fff",
    },
    notificationTime: {
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      color: "#1876f2",
    },
    scheduleIcon: {
      fontSize: 14,
      color: "#1876f2",
      marginRight: 2,
    },
    userImage: {
      paddingLeft: 10,
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
        marginRight: 20,
        background: "#1f73b7",
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    msgText: {
      width: "auto",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      hyphens: "auto",
      paddingRight: 20,
    },
    logoImage: {
      width: 30,
      height: 30,
      fontSize: 14,
      marginRight: 20,
      background: "#f7f7f7",
      borderRadius: "50%",
      padding: 5,
    },
  });

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 100,
      readStatus: "ALL",
      totalUnread: 0,
      popoverNotifications: [],
      menuType: 1,
      isShowLoader: true,
    };
    this.avatarName = this.avatarName.bind(this);
    this.markAsRead = this.markAsRead.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.fetchNotificationProgress === false &&
      prevProps.fetchNotificationProgress === true
    ) {
      if (this.props.fetchNotificationError === null) {
        this.checkNotifications();
        setTimeout(() => {
          this.setState({
            isShowLoader: false,
          });
        }, 1000);
      } else {
        // this.props.showSnackBar("Something went wrong to fetch notifications.", "error", 3000)
      }
    }

    if (
      this.props.markAsReadProgress === false &&
      prevProps.markAsReadProgress === true
    ) {
      this.fetchData();
    }

    if (
      this.props.toastNotificationCount !== prevProps.toastNotificationCount
    ) {
      this.checkNotifications();
    }
  }

  fetchData() {
    this.props.fetchNotifications({
      page: this.state.page,
      pageSize: this.state.pageSize,
      readStatus: this.state.readStatus,
    });
  }

  checkNotifications() {
    var me = this;
    var totalUnread = "";
    var popoverNotifications = [];
    if (
      this.props.notifications !== undefined &&
      this.props.notifications !== null &&
      this.props.notifications.length > 0
    ) {
      var allNotifications = this.props.notifications.sort(function (a, b) {
        var aTime = moment(a.timestamp).format("X");
        var bTime = moment(b.timestamp).format("X");
        if (aTime > bTime) {
          return -1;
        } else if (aTime < bTime) {
          return 1;
        } else {
          return 0;
        }
      });
      allNotifications.forEach(function (item, k) {
        if (item.readAt === null) {
          totalUnread++;
        }
        var obj = {
          info: getMessage(item, me.props.user),
          id: item.id,
          data: item.data,
          readAt: item.readAt,
          timestamp: item.timestamp,
          showLoader: false
        };
        popoverNotifications.push(obj);
      });
    }
    this.setState({
      totalUnread: totalUnread,
      popoverNotifications: popoverNotifications,
    });
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  markAsRead(itemId) {
    let popoverNotifications = this.state.popoverNotifications;
    let itemIndx = popoverNotifications.findIndex(o => o.id === itemId);
    var noti = popoverNotifications[itemIndx];
    noti.showLoader = true;
    this.setState({
      popoverNotifications: popoverNotifications
    }, () => {
      this.props.markAsRead(itemId);
    })
  }

  render() {
    const classes = this.props.classes;
    const { popoverNotifications, menuType, isShowLoader } = this.state;

    var title = "";
    if (menuType === 1) {
      title = "All Notifications";
    } else if (menuType === 2) {
      title = "Unread Notifications";
    } else if (menuType === 3) {
      title = "All Notifications";
    }

    return (
      <div>
        <AppBarV2
          title="Notifications"
          withBack={true}
          hideNotifications={true}
        />
        <div className={classes.bodyItem}>
          <Grid container className={classes.content}>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className={classes.leftMenu}
            >
              <div className={classes.notificationMenu}>
                <List>
                  <ListItem
                    className={menuType === 1 ? classes.activeMenu : ""}
                    onClick={() => {
                      this.setState(
                        {
                          readStatus: "ALL",
                          menuType: 1,
                        },
                        () => {
                          this.fetchData();
                        }
                      );
                    }}
                  >
                    <ListItemIcon classes={{ root: classes.listIcon }}>
                      <NotificationsIcon
                        className={menuType === 1 ? classes.activeMenu : ""}
                      />
                    </ListItemIcon>
                    <Typography variant="inherit">All Notifications</Typography>
                  </ListItem>
                  <Divider />
                  <ListItem
                    className={menuType === 2 ? classes.activeMenu : ""}
                    onClick={() => {
                      this.setState(
                        {
                          readStatus: "UNREAD",
                          menuType: 2,
                        },
                        () => {
                          this.fetchData();
                        }
                      );
                    }}
                  >
                    <ListItemIcon classes={{ root: classes.listIcon }}>
                      <NotificationImportantIcon
                        className={menuType === 2 ? classes.activeMenu : ""}
                      />
                    </ListItemIcon>
                    <Typography variant="inherit">Unread</Typography>
                  </ListItem>
                </List>
              </div>
              {false && (
                <SqureButton
                  className={classes.surveyBtn}
                  variant="outlined"
                  style={{ width: "100%", marginTop: 30 }}
                  onClick={() => { }}
                >
                  {" "}
                  Mark all as read{" "}
                </SqureButton>
              )}
            </Grid>
            <Grid
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
              xl={9}
              className={classes.rightPart}
            >
              <div className={classes.notificationList}>
                <div className={classes.notificationHead}>
                  <Typography className={classes.notificationTitle}>
                    {title}
                  </Typography>
                </div>
                {isShowLoader && (
                  <div
                    className={classes.notificationBody}
                    style={{ textAlign: "center", marginTop: 20 }}
                  >
                    <CircularProgress />
                  </div>
                )}

                {!isShowLoader && (
                  <div className={classes.notificationBody}>
                    {popoverNotifications.map((item, k) => {
                      return (
                        <div className={classes.notificationItem} id={item.id}>
                          <div className={classes.notificationCard}>
                            <div className={classes.userImage}>
                              {item.info !== undefined &&
                                item.info !== null &&
                                item.info.user !== undefined &&
                                item.info.user !== null && (
                                  <Tooltip
                                    placement="top"
                                    title={
                                      <span className={classes.tooltipMsg}>
                                        {item.info.user.name}
                                      </span>
                                    }
                                    arrow
                                  >
                                    <Avatar key={k}>
                                      {" "}
                                      {this.avatarName(item.info.user.name)}
                                    </Avatar>
                                  </Tooltip>
                                )}
                              {(item.info === undefined ||
                                item.info === null ||
                                item.info.user === undefined ||
                                item.info.user === null) && (
                                  <img
                                    src={Logo}
                                    alt="logo"
                                    className={classes.logoImage}
                                  />
                                )}
                            </div>
                            <div
                              className={classes.msgContainer}
                              onClick={() => {
                                this.props.history.push(item.info.redirectURL);
                              }}
                            >
                              <Typography className={classes.msgText}>
                                {item.info.message}
                              </Typography>
                            </div>
                            <div className={classes.timeContainer}>
                              <Typography
                                variant="inherit"
                                className={classes.notificationTime}
                              >
                                <ScheduleIcon
                                  className={classnames(classes.scheduleIcon)}
                                />
                                {moment
                                  .utc(item.timestamp)
                                  .local()
                                  .startOf("seconds")
                                  .fromNow()}
                              </Typography>
                            </div>
                            <div className={classes.checkContainer}>
                              {item.showLoader && item.readAt === null && (
                                <CircularProgress style={{width:20,height:20}}/>
                              )}
                              {!item.showLoader && item.readAt === null && (
                                <Tooltip
                                  placement="top"
                                  title={
                                    <span className={classes.tooltipMsg}>
                                      Mark as read
                                    </span>
                                  }
                                  arrow
                                >
                                  <div className={classes.tickBar}>
                                    <CheckIcon
                                      className={classnames(
                                        classes.micon,
                                        classes.submitMicon
                                      )}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.markAsRead(item.id);
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default connector(
  compose(withRouter, withStyles(styles))(Notifications)
);
