import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppBarV2 from "Components/Application/AppBarV2";
import CommonCss from 'commonCss';
import {
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Paper,
} from '@material-ui/core';
import { showSnackBar } from "redux/snackbar/action";
import { CommonFn } from "services/commonFn";

import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";

import { fetchUserProfileMeta,fetchDepartments } from "redux/departments/action"


const connectedProps = (state) => ({
  isOpenAddDialog: state.automation.dialogAddAutomationOpen,
  addNewAutomationProgress: state.automation.addNewAutomationProgress,
  addNewAutomationError: state.automation.addNewAutomationError,
  newAutomationData: state.automation.newAutomationData,
  deleteAutomationProgress: state.automation.deleteAutomationProgress,
  deleteAutomationError: state.automation.deleteAutomationError,

  automationDatas: state.automation.automationDatas,

  fetchUserMetaProgress: state.departments.fetchUserMetaProgress,
  fetchUserMetaError: state.departments.fetchUserMetaError,
  userProfileMeta: state.departments.userProfileMeta,

  fetchDepartmentsProgress: state.departments.fetchDepartmentsProgress,
  fetchDepartmentsError: state.departments.fetchDepartmentsError,
  departments: state.departments.departments,

});

const connectionActions = {
  showSnackBar: showSnackBar,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  fetchUserProfileMeta:fetchUserProfileMeta,
  fetchDepartments:fetchDepartments
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  topActions: {
    textAlign: 'right',
    marginBottom: 20
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 32,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  nameCol: {
    width: '30%'
  },
  statusCol: {
    width: '15%'
  },
  actionCol: {
    textAlign: 'center',
    width: '10%'
  },
  department:{
    cursor:'pointer'
  }
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoading: true,
      departments: [],
      page: 0,
      pageSize: 10,
      isLoading: true,
      totalAutomations: 0,
      deleteId: null
    }
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.callBack = this.callBack.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.props.fetchDepartments()
  }

  componentDidUpdate(prevProps) {
    const { deleteAutomationProgress, deleteAutomationError, fetchDepartmentsProgress, fetchDepartmentsError,
      departments,newAutomationData } = this.props;


    if (!this.props.addNewAutomationProgress && prevProps.addNewAutomationProgress) {
      if (this.props.addNewAutomationError === null) {
        this.props.showSnackBar('Automation added successfully', 'success', 3000)
        this.handleEdit(newAutomationData?.ID)
        this.setState({
          page: 0,
          pageSize: 10,
        }, () => {
          this.fetchData();
        })
      } else {
        this.props.showSnackBar('Something went wrong', 'error', 3000)
      }
    }

    if (!deleteAutomationProgress && prevProps.deleteAutomationProgress) {
      if (deleteAutomationError === null) {
        this.props.showSnackBar("Automation deleted successfully.", "success", 3000);
        this.setState({
          page: 0,
          pageSize: 10,
        }, () => {
          this.fetchData();
        })
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!fetchDepartmentsProgress && prevProps.fetchDepartmentsProgress) {
      if (fetchDepartmentsError === null) {
        console.log(departments,'departments111')
        this.setState({
          isDataLoading: false,
          departments: departments,
        })
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

  }

  handleEdit(id) {
    let redirectPath = `/app/settings/departments/${id}`;
    this.props.history.push(redirectPath);
  }

  handleDelete(id) {
    var me = this;
    this.setState({ deleteId: id }, () => {
      me.props.showMessageDialog();
    })
  }

  callBack() {
    if (this.state.deleteId) {
      this.props.hideMessageDialog();
      this.props.deleteAutomation(this.state.deleteId);
    }
  }

  handleChangePage(e, newPage) {
    this.setState({
      page: newPage,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  handleChangeRowsPerPage(e) {
    this.setState({
      page: 0,
      pageSize: e.target.value,
      isDataLoading: true
    }, () => {
      this.fetchData()
    })
  }

  render() {
    const classes = this.props.classes;
    const { isDataLoading, departments} = this.state;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <div>
        <AppBarV2 title="Departments" withBack={true} />
        <div className={classes.rootContainer}>
          <div className={classes.body}>
            <Paper style={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer style={{ maxHeight: '78vh' }}>
                <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.nameCol}>Name</TableCell>
                      <TableCell className={classes.statusCol}>Created Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isDataLoading && departments.map((task) => {
                      return <TableRow key={'task_' + task.ID} className={classes.department} onClick={() => this.handleEdit(task?.ID)}>
                        <TableCell className={classes.nameCol}>{task?.Name}</TableCell>
                        <TableCell className={classes.statusCol}>{CommonFn.formateDate(task?.CreatedAt, true)} </TableCell>
                      </TableRow>
                    })}

                    {(!isDataLoading && (departments === undefined || departments === null || departments.length <= 0)) && <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: 'center' }} className={classes.nameCol}>No Departments</TableCell>
                    </TableRow>}

                    {isDataLoading && <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: 'center' }} className={classes.nameCol}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>}

                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
        <MessageDialog messageData={messageData} callBack={this.callBack} />
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Home));
