import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
import { CommonFn } from "services/commonFn";
import moment from "moment";
import * as Validator from "util/Validation";
import { fetchAllOrgSettings } from "redux/org/user/action";

// Dilogs
import { getUpdatedWorkflowColumns, getFinalTrackingColumns } from "util/Columns";
import { getOrgWorkflow } from "redux/evaluation/workflow/action";
import ListingTable from "./Common/ListingTable";
import { showSnackBar } from "redux/snackbar/action";
import { fetchVisualizationOrgWorkflows, fetchProjectRequestTypes } from "redux/workflow/action";
import { fetchOrgForms } from "redux/forms/org/action";
import { hideEvaluationProductSelectionDialog } from "redux/evaluation/metadata/action";

const connectedProps = (state) => ({
  fetchVisualizationWorkflowsProgress: state.workflow.fetchVisualizationWorkflowsProgress,
  fetchVisualizationWorkflowsError: state.workflow.fetchVisualizationWorkflowsError,
  orgVisualizationWorkflows: state.workflow.orgVisualizationWorkflows,
  orgVisualizationWorkflowsTotal: state.workflow.orgVisualizationWorkflowsTotal,
  user: state.authUser.user,
  userType: state.authUser.user?.Role,
  orgUser: state.orgUser,
  evalTitleUpdateProgress: state.evaluationMetaData.evalTitleUpdateProgress,
  evalTitleUpdateError: state.evaluationMetaData.evalTitleUpdateError,
  orgForms: state.orgForms.forms,

  fetchWorkflowsTrackingProgress: state.workflow.fetchWorkflowsTrackingProgress,
  fetchWorkflowsTrackingError: state.workflow.fetchWorkflowsTrackingError,
  orgWorkflowsTracking: state.workflow.orgWorkflowsTracking,

  orgWorkflowsTrackingTotal: state.workflow.orgWorkflowsTrackingTotal,

  orgWorkFlowProgress: state.evaluationWorkflow.orgWorkFlowProgress,
  orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
  orgWorkFlowError: state.evaluationWorkflow.orgWorkFlowError,

  updateProgress: state.evaluationMetaData.updateProgress,

  fetchProjectReqTypesProgress: state.workflow.fetchProjectReqTypesProgress,
  fetchProjectReqTypesError: state.workflow.fetchProjectReqTypesError,
  requestTypes: state.workflow.requestTypes,

  fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
});

const connectionActions = {
  fetchVisualizationOrgWorkflows: fetchVisualizationOrgWorkflows,
  showSnackBar: showSnackBar,
  fetchOrgForms: fetchOrgForms,
  getOrgWorkflow: getOrgWorkflow,
  hideEvaluationProductSelectionDialog: hideEvaluationProductSelectionDialog,
  fetchProjectRequestTypes: fetchProjectRequestTypes,
  fetchAllOrgSettings: fetchAllOrgSettings,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
  });

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageSize: 5,
      query: "",
      activeWorkflowTab: this.props.activeTab,
      isUpdateData: true,
      sortField: "creation_date",
      sortType: "desc",
      statusFilter: ["completed", "in_progress", "closed"],
      projectStatusFilter: ["Draft", "PendingApproval", "Approved", "Rejected", "CLOSED", "FINISHED"],
      workflowNameFilter: "0",
      requestTypeFilter: "0",
      workflowNameOptions: [],
      projectOwnerIds: [],
      requestorIds: [],
      formFieldsIds: [],
      deleteId: null,
      startDate: CommonFn.getPreviousDate(90),
      endDate: new Date(),
      dates: null,
      duration: 0,
      tabsOptions: [],
      projectTrackingForm: "",
      updateTrackingForm: false,
      trackingFormItemIds: [],
      trackingFormItems: [],
      formItemsIdMap: {},
      trackingFormFields: [],
      firstCallDone: false,
      filterTabWiseHistory: {},
      isChangesInFilters: false,
      showFilters: [],
      showProjectDeleteConfirm: false,
      projectColumnWithNames: null,
      configSuccessMsg: "",
      requestTypes: [],
      tabsHashMap: {
        0: "all",
        1: "requested_by_me",
        2: "assigned_to_me",
        3: "project_tracking",
      },
      columns: [],
      selectedView: null,
      viewsList: [],
      addUpdateDialog: false,
      filterData: {
        query: "",
        duration: 0,
        startDate: CommonFn.getPreviousDate(90),
        endDate: new Date(),
        projectOwnerIDs: [],
        contractOwnerIDs: [],
        dates: null,
        statusFilter: ["completed", "in_progress", "closed"],
        requestorIds: [],
        projectStatusFilter: ["Draft", "PendingApproval", "Approved", "Rejected", "CLOSED", "FINISHED"],
        workflowNameFilter: "0",
        requestTypeFilter: "0",
      },
    };
    this.divRef = React.createRef();
    this.updatePageAndSize = this.updatePageAndSize.bind(this);
    this.updateSorting = this.updateSorting.bind(this);
  }

  componentDidMount() {
    this.props.fetchProjectRequestTypes();
    this.props.hideEvaluationProductSelectionDialog();
    this.props.fetchOrgForms(0, 1000);
    this.props.getOrgWorkflow({ types: [1], page: 0, pageSize: 1000 });
    this.setWorkflowFormFields();
    this.setTrackingFormFields();
    this.props.fetchAllOrgSettings();
  }

  componentDidUpdate(prevProps) {
    if (this.props.evalTitleUpdateProgress === false && prevProps.evalTitleUpdateProgress === true) {
      if (this.props.evalTitleUpdateError === null) {
        this.props.showSnackBar("Request Updated Successfully", "success", 3000);
      } else {
        this.props.showSnackBar("Failed to update Procurement Owner", "error", 3000);
      }
    }

    if (this.props.fetchVisualizationWorkflowsProgress[this.props.Vid] === false && prevProps.fetchVisualizationWorkflowsProgress[this.props.Vid] === true) {
      if (this.props.fetchVisualizationWorkflowsError === null) {
        this.setState({
          isUpdateData: this.state.isUpdateData === true ? false : true,
        });
      }
    }

    if (!this.props.fetchVisualizationWorkflowsError && prevProps.fetchVisualizationWorkflowsError) {
      this.setState({
        isUpdateData: this.state.isUpdateData === true ? false : true,
      });
    }

    if (this.props.fetchWorkflowsTrackingProgress[this.props.Vid] === false && prevProps.fetchWorkflowsTrackingProgress[this.props.Vid] === true) {
      if (this.props.fetchWorkflowsTrackingError === null) {
        this.setState({
          isUpdateData: this.state.isUpdateData === true ? false : true,
        });
      }
    }

    if (!this.props.fetchWorkflowsTrackingError && prevProps.fetchWorkflowsTrackingError) {
      this.setState({
        isUpdateData: this.state.isUpdateData === true ? false : true,
      });
    }

    if (this.props.fetchAllOrgSettingsInprogress === false && prevProps.fetchAllOrgSettingsInprogress === true) {
      this.initViews();
    }

    if (!this.props.orgWorkFlowProgress && prevProps.orgWorkFlowProgress) {
      if (this.props.orgWorkFlowError === null) {
        this.initWorkflowNameFilter();
      }
    }

    if (this.props.fetchProjectReqTypesProgress === false && prevProps.fetchProjectReqTypesProgress) {
      if (this.props.fetchProjectReqTypesError === null && this.props.requestTypes) {
        let reqTypeOptions = [
          {
            label: "All",
            value: "0",
          },
        ];
        this.props.requestTypes?.RequestTypes.forEach(function (rt) {
          reqTypeOptions.push({
            label: rt,
            value: rt,
          });
        });
        this.setState({
          requestTypes: reqTypeOptions,
        });
      }
    }
  }

  initViews = () => {
    if ([0, 3].includes(this.state.activeWorkflowTab)) {
      const { orgUser } = this.props;
      let configKey = "ALL_PROJECTS_VIEWS";
      if (this.state.activeWorkflowTab === 3) {
        configKey = "ALL_PROJECTS_TRACKING_VIEWS";
      }
      let viewsList = this.getConfigValue(orgUser?.fetchAllOrgSettingsSuccess, "SettingName", configKey) || [];
      viewsList = Validator.isJson(viewsList) ? JSON.parse(viewsList) : [];

      this.setState(
        {
          viewsList: viewsList,
          isLoading: false,
        },
        () => {
          this.selectView(this.props.defaultSelectedView);
        }
      );
    }
  };

  setTrackingFormFields = () => {
    let columns = getFinalTrackingColumns();
    let columnIds = [];
    columns.forEach((O) => {
      if (O.isDynamic) {
        columnIds.push(O.id);
      }
    });
    this.setState({
      trackingFormFields: columnIds,
    });
  };

  setWorkflowFormFields = () => {
    let columns = getUpdatedWorkflowColumns();
    let columnIds = [];
    columns.forEach((O) => {
      if (O.isDynamic) {
        columnIds.push(O.id);
      }
    });
    this.setState({
      formFieldsIds: columnIds,
    });
  };

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) {}
      return result.trim() || "";
    }
  };

  getPageSortType() {
    let sortStr = "peo.created_at";
    const { sortField } = this.state;
    if (sortField === "name") {
      sortStr = "peo.name";
    } else if (sortField === "workflow_name") {
      sortStr = "owo.name";
    } else if (sortField === "workflow_type") {
      sortStr = "peo.type";
    } else if (sortField === "requester_name") {
      sortStr = "u.name";
    } else if (sortField === "status") {
      sortStr = "peo.status";
    } else if (sortField === "creation_date") {
      sortStr = "peo.created_at";
    } else if (sortField === "last_update") {
      sortStr = "peo.updated_at";
    }
    return sortStr;
  }

  getStatusFilter() {
    let statusForFiltering = [];
    const { statusFilter } = this.state.filterData;

    if (statusFilter.includes("closed")) {
      statusForFiltering.push(5);
    }
    if (statusFilter.includes("completed")) {
      statusForFiltering.push(4);
    }
    if (statusFilter.includes("in_progress")) {
      statusForFiltering.push(2, 3);
    }
    return statusForFiltering;
  }

  getProjectStatusFilter() {
    const { projectStatusFilter } = this.state.filterData;
    return projectStatusFilter;
  }

  initWorkflowNameFilter() {
    const { orgWorkFlowList } = this.props;
    if (orgWorkFlowList && orgWorkFlowList.Data) {
      let workflowNameOptions = [
        {
          label: "All",
          value: "0",
        },
      ];
      orgWorkFlowList.Data.forEach((itm) => {
        workflowNameOptions.push({
          label: itm.Name,
          value: itm.Name,
        });
      });

      this.setState({
        workflowNameOptions: workflowNameOptions,
      });
    }
  }

  fetchData() {
    let sortType = this.getPageSortType();
    let statusFilter = this.getStatusFilter();
    let projectStatusFilter = this.getProjectStatusFilter();
    let parameters = {
      from_date: moment(this.state.filterData?.startDate).toISOString(),
      to_date: moment(this.state.filterData?.endDate).toISOString(),
      query: this.state.filterData?.query,
      page: this.state.page,
      pageSize: this.state.pageSize,
      status: statusFilter,
      type: [1, 2, 4, 10],
      sortBy: sortType,
      sortOrder: this.state.sortType,
      requestStatus: projectStatusFilter,
      product_ids: this.props.filterProjects,
      entity_ids: this.props.filterEntities,
      for_me: 1,
    };
    // if (this.state.activeWorkflowTab === 0) {
    //     delete parameters.from_date;
    //     delete parameters.to_date;
    // }
    if (this.state.activeWorkflowTab === 0 || this.state.activeWorkflowTab === 3) {
      parameters["allEvaluations"] = true;
    } else if (this.state.activeWorkflowTab === 1) {
      if (this.props.from === "PAGE") {
        parameters["requestor_ids"] = [this.props.user.ID];
      }
    } else if (this.state.activeWorkflowTab === 2) {
      parameters["colloborator_ids"] = [this.props.user.ID];
    }
    if (this.state.formFieldsIds?.length > 0) {
      parameters["form_fields"] = this.state.formFieldsIds;
    }
    if (this.state.filterData?.projectOwnerIds?.length > 0) {
      parameters["project_owner_ids"] = this.state.filterData?.projectOwnerIds?.map((item) => item.value) || [];
    }

    if (this.state.activeWorkflowTab !== 1 && this.state.filterData?.requestorIds && this.state.filterData?.requestorIds.length > 0) {
      parameters["requestor_ids"] = this.state.filterData?.requestorIds?.map((item) => item.value) || [];
    }

    if (this.state.activeWorkflowTab === 3) {
      parameters["form_fields"] = [...this.state.trackingFormFields, ...this.state.trackingFormItemIds];
    }

    if (this.state.workflowNameFilter !== "0") {
      parameters["workflowName"] = this.state.filterData?.workflowNameFilter;
    }

    if (this.state.requestTypeFilter !== "0") {
      parameters["request_type"] = this.state.filterData?.requestTypeFilter;
    }

    this.props.fetchVisualizationOrgWorkflows(parameters, this.props.Vid);
  }

  updatePageAndSize(page, pageSize) {
    this.setState(
      {
        page: page,
        pageSize: pageSize,
      },
      () => {
        this.fetchData();
      }
    );
  }

  updateSorting(type, field) {
    this.setState(
      {
        page: 0,
        sortField: field,
        sortType: type,
      },
      () => {
        this.fetchData();
      }
    );
  }

  selectView = (id) => {
    let view = this.state.viewsList?.find((view) => view.id === id);
    if (view) {
      this.setState(
        {
          pageSize: this.state.pageSize,
          query: view?.filters?.query,
          startDate: view?.filters?.startDate || this.state.startDate,
          endDate: view?.filters?.endDate || this.state.endDate,
          dates: view?.filters?.dates || this.state.dates,
          duration: view?.filters?.duration || this.state.duration,
          columns: view?.filters?.columns || this.state.columns,
          sortField: view?.filters?.sortField || this.state.sortField,
          sortType: view?.filters?.sortType || this.state.sortType,
          statusFilter: view?.filters?.statusFilter || this.state.statusFilter,
          projectStatusFilter: view?.filters?.projectStatusFilter || this.state.projectStatusFilter,
          workflowNameFilter: view?.filters?.workflowNameFilter || this.state.workflowNameFilter,
          requestTypeFilter: view?.filters?.requestTypeFilter || this.state.requestTypeFilter,
          projectTrackingForm: view?.filters?.projectTrackingForm || this.state.projectTrackingForm,
          showFilters: view?.filters?.showFilters || this.state.showFilters,
          projectOwnerIds: view?.filters?.projectOwnerIds || this.state.projectOwnerIds,
          requestorIds: view?.filters?.requestorIds || this.state.requestorIds,
        },
        () => {
          this.fetchData();
        }
      );
    } else {
      this.fetchData();
    }
    this.setState({ selectedView: id });
  };

  render() {
    const { projectTrackingForm, updateTrackingForm } = this.state;

    console.log(this.state.formFieldsIds, "formFieldsIds");
    return (
      <>
        <ListingTable
          page={this.state.page}
          pageSize={this.state.pageSize}
          searchQuery={this.state.query}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          dates={this.state.dates}
          duration={this.state.duration}
          columns={this.state.columns}
          sortField={this.state.sortField}
          sortType={this.state.sortType}
          statusFilter={this.state.statusFilter}
          projectStatusFilter={this.state.projectStatusFilter}
          workflowNameFilter={this.state.workflowNameFilter}
          workflowFilterOptions={this.state.workflowNameOptions}
          isLoading={this.props.fetchVisualizationWorkflowsProgress[this.props.Vid] || this.props.fetchWorkflowsTrackingProgress[this.props.Vid]}
          isUpdatedNewData={this.state.isUpdateData}
          rows={this.props.orgVisualizationWorkflows[this.props.Vid] || []}
          totalRows={this.props.orgVisualizationWorkflowsTotal[this.props.Vid] || 0}
          trackDataRows={this.props.orgWorkflowsTracking}
          trackDataTotalRows={this.props.orgWorkflowsTrackingTotal}
          searchPlaceHolder={"Project Name"}
          isGlobalSortRequire={true}
          showActions={true}
          activeTab={this.state.activeWorkflowTab}
          filterData={this.state.filterData}
          applyFilter={(filters) => {
            this.setState({ filterData: filters, page: 0 }, () => {
              this.fetchData();
            });
          }}
          projectTrackingForm={projectTrackingForm}
          updateTrackingForm={updateTrackingForm}
          trackingFormItems={this.state.trackingFormItems}
          formItemsIdMap={this.state.formItemsIdMap}
          isChangesInFilters={this.state.isChangesInFilters}
          showFilters={this.state.showFilters}
          projectOwnerIds={this.state.projectOwnerIds}
          requestorIds={this.state.requestorIds}
          projectColumnWithNames={this.state.projectColumnWithNames}
          requestTypeFilter={this.state.requestTypeFilter}
          requestTypeOptions={this.state.requestTypes}
          selectedView={this.state.selectedView}
          onChangePage={(page, pageSize) => {
            this.updatePageAndSize(page, pageSize);
          }}
          onChangeSort={(type, field) => {
            this.updateSorting(type, field);
          }}
        />
        <div ref={this.divRef} id="downloadlink"></div>
      </>
    );
  }
}

Projects.defaultProps = {
  filterProjects: [],
  filterEntities: [],
  activeTab: null,
  showTopBar: true,
  showTabs: true,
  showStartNewProjectBtn: false,
  selectedVendorType: {
    label: "Products",
    value: "PRODUCT",
  },
  selectionForStart: null,
  from: "PAGE",
};

export default connector(compose(withRouter, withStyles(styles))(Projects));
