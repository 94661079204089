import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { ListItemText, List, ListItem, DialogContent, CircularProgress, Typography, Dialog, Button, Slide } from '@material-ui/core';
import {  hideUsedPlacesDialog, fetchUsedPlaces } from "redux/forms/org/action";
import { showSnackBar } from "redux/snackbar/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    usedPlacesDialogOpen: state.orgForms.usedPlacesDialogOpen,
    usedPlacesDialogData: state.orgForms.usedPlacesDialogData,
    usedPlaces: state.orgForms.usedPlaces,
    usedPlacesProgress: state.orgForms.usedPlacesProgress,
});

const connectionActions = {
    hideUsedPlacesDialog: hideUsedPlacesDialog,
    fetchUsedPlaces: fetchUsedPlaces,
    showSnackBar: showSnackBar
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    button: {
        textAlign: 'right',
        marginBottom: 10
    },
    title: {
        fontSize: 18,
    },
    subHead: {
        marginTop: 20,
        fontSize: 15,
        fontWeight: 500
    },
    listItemText:{
        fontSize:'1em',
      },
    list: {
        maxHeight: 300,
        overflowY: 'auto',
        margin: 14,
        "& a": {
            padding: 0,
        }
    }
});

const ListItemLink = (props) => {
    return <ListItem button component="a" {...props} />;
}

class UsedPlacesDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
        }
       
    }

    componentDidMount() {
        if(this.props.usedPlacesDialogData?.ID){
            this.props.fetchUsedPlaces({entity_id: this.props.usedPlacesDialogData?.ID, entity_type: this.props.usedPlacesDialogData.dialogType });
        }else {
            this.props.showSnackBar("Something went wrong", "error", 3000);
            this.props.hideUsedPlacesDialog();
        }
    }


    render() {
        const { usedPlacesDialogData,usedPlaces,classes} = this.props;
       
        return <Dialog 
                    onClose={this.props.hideUsedPlacesDialog} 
                    open={this.props.usedPlacesDialogOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"sm"}
                    scroll={"body"}
                    >
                        <DialogContent classes={{root:classes.dialogContent}}>
                            <Typography variant={"h6"} className={classes.title}> {usedPlacesDialogData?.FormTitle || usedPlacesDialogData?.Name} is being used in following workflows</Typography>
                            {this.props.usedPlacesProgress ? <div style={{textAlign:'center',marginTop:10}}>
                                <CircularProgress/>
                            </div> : (
                                <List className={classes.list}>
                                    {usedPlaces?.map((item,i)=> (
                                        <ListItemLink href={`/app/settings/form-builder/default/${item.ID}`} key={item.ID} target="_blank">
                                            <ListItemText primary={`${i+1}. ${item.Name} (${item.FormCount || 1})`} classes={{primary:classes.listItemText}}/>
                                        </ListItemLink>
                                    ))} 
                                </List>
                            )}

                                {usedPlacesDialogData?.isDelete && <p className={classes.subHead}>Do you really want to delete?</p> }
                                <div className={classes.button}>
                                    <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideUsedPlacesDialog}>{usedPlacesDialogData?.isDelete ? "Cancel" : "Close"}</Button>
                                    {usedPlacesDialogData?.isDelete && <Button style={{marginLeft: 20}} variant="contained" color="secondary" className={classes.button} onClick={() => {
                                        this.props.callBack(this.props.usedPlacesDialogData);
                                        this.props.hideUsedPlacesDialog()
                                        }}>Delete</Button>}
                                </div> 
                        </DialogContent>
                </Dialog>
    }
}


export default connector(compose(
    withStyles(styles)
)(UsedPlacesDialog));