import {
  SHOW_EVALUATION_RULE_DIALOG, HIDE_EVALUATION_RULE_DIALOG,
  START_EVALUATION_RULE_SAVE, END_EVALUATION_RULE_SAVE,
  START_EVALUATION_RULE_FETCH, END_EVALUATION_RULE_FETCH,
  START_EVALUATION_REQUIREMENT_RULE_SAVE, END_EVALUATION_REQUIREMENT_RULE_SAVE
} from "./action";

const initState = {
  showDialog: false,
  dialogType: "",
  dialogContent: null,
  ruleSaveProgress: false,
  errorRuleSave: null,
  rule: null,
  rulefetchProgress: false
}

export function evaluationRuleReducer(state = initState, action) {
  switch (action.type) {
    case SHOW_EVALUATION_RULE_DIALOG:
      return {
        ...state,
        showDialog: true,
        dialogType: action.payload.type,
        dialogContent: action.payload.content
      }
    case HIDE_EVALUATION_RULE_DIALOG:
      return {
        ...state,
        showDialog: false,
        dialogType: "",
        dialogContent: null

      }

    case START_EVALUATION_RULE_SAVE:
      return {
        ...state,
        ruleSaveProgress: true,
        errorRuleSave: null,

      }
    case END_EVALUATION_RULE_SAVE:
      return {
        ...state,
        ruleSaveProgress: false,
        errorRuleSave: action.payload?.error?.message || null,
      }

    case START_EVALUATION_RULE_FETCH:
      return {
        ...state,
        rulefetchProgress: true,
        rule: null,
      }
    case END_EVALUATION_RULE_FETCH:
      return {
        ...state,
        rulefetchProgress: false,
        rule: action.payload.success,
      }

    case START_EVALUATION_REQUIREMENT_RULE_SAVE:
      return {
        ...state,
        evaluationReqRuleSaveProgress: true,
        evaluationReqRuleSaveError: null,

      }

    case END_EVALUATION_REQUIREMENT_RULE_SAVE:
      return {
        ...state,
        evaluationReqRuleSaveProgress: false,
        evaluationReqRuleSaveError: action.payload?.error?.message || null,
      }

    default:
      return state;
  }
}