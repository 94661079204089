import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slide, Typography, Button, TextField } from "@material-ui/core";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import ReactSelect from "react-select";
import { withRouter } from "react-router-dom";
// import exportImage from "assets/images/export.svg"
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";

import Image from "Components/Common/image.jsx";
import deleteIcon from "assets/images/delete.svg";
import { hideImportAssessmentDialog, setImportAssessmentData } from "redux/seller/assessment/action";
import { showSnackBar } from "redux/snackbar/action";

// import * as Environment from "util/Environment";
// import axios from "axios";
// import { CommonFn } from "services/commonFn";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  sellerProducts: state.sellerProduct.sellerProducts,
  importAssessmentDialogData: state.sellerAssessment.importAssessmentDialogData,
});

const connectionActions = {
  setImportAssessmentData: setImportAssessmentData,
  hideImportAssessmentDialog: hideImportAssessmentDialog,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(6, 12) + " !important",
    },
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginBottom: theme.spacing(2.5),
    },

    uploadSection: {
      textAlign: "center",
      border: "1px dotted #333",
      padding: theme.spacing(6, 0),
      "&.activeDrop": {
        border: "2px dotted #4a88ff",
      },
    },

    uploadText: {
      fontSize: 20,
      color: "#12344D",
    },

    uploadSubText: {
      color: "#475867",
      marginTop: 0,
    },

    uploadAnchor: {
      color: "#0091FF !important",
      textDecoration: "underline",
      opacity: 0,
      width: 0,
    },
    btnSection: {
      marginTop: 20,
      textAlign: "center",
    },
    delete: {
      cursor: "pointer",
    },
    fileText: {
      maxWidth: 300,
      wordBreak: "break-all",
      fontSize: 14,
      border: "1px solid #333",
      borderRadius: 3,
      marginRight: 20,
      padding: 5,
      marginTop: 0,
      marginBottom: 0,
    },
    fileNameSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 15,
    },
    labelFile: {
      color: "#4a87f8",
      marginLeft: 5,
      border: "1px solid #4a87f8",
      padding: "5px 10px",
      borderRadius: 4,
      cursor: "pointer",
    },
    button: {},
    bottomAddBtn: {
      display: "none",
    },
    arrow: {
      paddingLeft: 5,
    },
    downloadTemplate: {
      textDecoration: "underline",
      color: "#0091FF !important",
    },
    progress: {
      textAlign: "center",
      paddingTop: 15,
    },
    tbodyCell: {
      minHeight: 40,
    },
    selectAll: {
      border: 0,
      color: "#0091FF",
      background: "#fff",
      cursor: "pointer",
      textDecoration: "underline",
      fontSize: 15,

      "&:focus": {
        outline: "none",
      },
    },
    headSection: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    userCount: {
      color: "#282D30",
      fontSize: 17,
    },
    buttonSection: {
      textAlign: "center",
      marginTop: 40,
    },
    checked: {
      opacity: 0,
      pointerEvents: "none",
      width: 50,
    },
    alert: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    cardAction: {
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      position: "relative",
      marginBottom: 0,
      border: "1px solid #E2E2E2",

      '& [class*="MuiSvgIcon-root"]': {
        fill: "#74798C",
      },
    },

    primary: {
      textAlign: "center",
      fontSize: theme.spacing(2.4),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },

    secondary: {
      textAlign: "center",
    },

    content: {
      padding: theme.spacing(2, 2, 0, 2),
    },
    cardSection: {
      "& [class*='MuiGrid-item']": {
        padding: 14,
      },

      "& [class*=CardAction-iconSection]": {
        position: "absolute",
        bottom: 24,
        right: 24,
      },
    },
    footerActions: {
      textAlign: "right",
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    criteriaAllSelect: {
      marginRight: 10,
      padding: 0,
    },
    filterTitle: {
      color: "#6C6C6C",
      fontSize: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
    },
    reqTypes: {
      marginBottom: theme.spacing(3.5),
    },
  });

class ImportTemplateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      name: "",
      fileName: "",
      activeOnDrop: "",
      file: null,
      error: "",
      productOptions: [
        {
          label: "Global",
          value: "global",
        },
      ],
      product: null,
    };
  }

  componentDidMount() {
    if (this.props.sellerProducts && this.props.sellerProducts.Data && this.props.sellerProducts.Data.length > 0) {
      let newOptions = [...this.state.productOptions];
      this.props.sellerProducts?.Data.forEach((o) => {
        newOptions.push({
          label: o.Name,
          value: o.ID,
        });
      });
      this.setState({ productOptions: newOptions });
    }
  }

  componentDidUpdate(prevProps) { }

  fileUpload(event) {
    if (event.target.files[0]) {
      if (!event.target?.files[0]?.name?.match(/\.(xlsx|xls|csv|xlsm)$/)) {
        this.props.showSnackBar("Please Upload Excel File", "error", 3000);
      } else {
        this.setState({ fileName: event.target.files[0].name, file: event.target.files[0] });
      }
    }
  }

  deleteFile = () => {
    this.setState({ fileName: "" });
    this.fileRef.current.value = "";
  };

  dropHandler = (event) => {
    this.setState({ activeOnDrop: "" });
    event.preventDefault();
    if (event.dataTransfer.files[0]) {
      if (!event.dataTransfer?.files[0]?.name?.match(/\.(xlsx|xls|csv|xlsm)$/)) {
        this.props.showSnackBar("Please Upload Excel File", "error", 3000);
      } else {
        this.setState({ fileName: event.dataTransfer.files[0].name, file: event.dataTransfer.files[0] });
      }
    }
  };

  dragOverHandler = (event) => {
    event.preventDefault();
    if (!this.state.activeOnDrop) {
      this.setState({ activeOnDrop: "activeDrop" });
    }
  };

  dragLeaveHandler = () => {
    if (this.state.activeOnDrop) {
      this.setState({ activeOnDrop: "" });
    }
  };

  importQuestionBank = () => {
    if (!this.props.isFromLibrary && this.state.name?.trim().length === 0) {
      this.props.showSnackBar("Please enter assessment name", "error", 3000);
      return;
    }
    if (!this.state.product) {
      this.props.showSnackBar("Please select product", "error", 3000);
      return;
    }
    if (!this.state.file) {
      this.props.showSnackBar("Please upload file", "error", 3000);
      return;
    }
    let data = {
      name: this.state.name,
      product: this.state.product,
      file: this.state.file,
      fileName: this.state.fileName,
      type: this.props.type,
    };
    console.log(data, 'Dataaaaaaaaaaaaa')
    console.log(this.props.isFromLibrary, 'library')
    this.props.setImportAssessmentData(data);
    this.props.history.push("/sellerapp/import");

    // if (this.props.isFromLibrary) {
    //   this.props.history.push("/sellerapp/import-library");
    // } else {
    //   this.props.history.push("/sellerapp/import");
    // }
  };

  cancel = () => {
    this.setState({
      fileName: "",
      activeOnDrop: "",
      file: null,
      error: "",
    });
    this.props.hideImportAssessmentDialog();
  };

  render() {
    const classes = this.props.classes;

    return (
      <Dialog
        onClose={this.props.hideImportAssessmentDialog}
        aria-labelledby="add-upload-dialog"
        open={true}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"md"}
        scroll={"body"}
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.hideImportAssessmentDialog()}>
            <CloseIcon />
          </div>
          <Typography variant={"h6"} className={classes.title}>
            Import {this.props.isFromLibrary ? "Library" : "Assessment"}
          </Typography>
          <div className={classes.alert}>
            {this.state.error.length > 0 && (
              <Alert variant="filled" severity="error">
                {this.state.error}
              </Alert>
            )}
          </div>
          {!this.props.isFromLibrary && (
            <div className={classes.reqTypes}>
              <Typography variant={"h4"} className={classes.filterTitle}>
                Assessment Name
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={this.state.name}
                onChange={(event) => {
                  this.setState({ name: event.target.value });
                }}
              />
            </div>
          )}
          <div className={classes.reqTypes}>
            <Typography variant={"h4"} className={classes.filterTitle}>
              Select Product
            </Typography>
            <ReactSelect
              isClearable
              isSearchable={true}
              options={this.state.productOptions}
              classNamePrefix="select"
              placeholder="Search Product"
              onChange={(e) => {
                this.setState({ product: e });
              }}
              value={this.state.product}
            />
          </div>
          <div>
            <div
              className={classnames(classes.uploadSection, this.state.activeOnDrop)}
              onDrop={(event) => this.dropHandler(event)}
              onDragOver={(event) => this.dragOverHandler(event)}
              onDragLeave={() => this.dragLeaveHandler()}
            >
              {/* <Image src={exportImage} alt="export image" /> */}
              <p className={classes.uploadSubText}>Drag and drop your file.</p>
              <label htmlFor="inputFile" className={classes.labelFile}>
                Upload File
              </label>
              <input
                ref={this.fileRef}
                id="inputFile"
                type="file"
                className={classes.uploadAnchor}
                // accept=".csv, xlsx, .xls, .csv, .xlsm"
                onChange={(event) => this.fileUpload(event)}
              />

              {this.state.fileName !== "" && (
                <div className={classes.fileNameSection}>
                  <p className={classes.fileText}>{this.state.fileName}</p>
                  <Image alt="Delete" className={classes.delete} src={deleteIcon} onClick={() => this.deleteFile()} />
                </div>
              )}
            </div>
            <div>
              <div className={classes.btnSection}>
                <Button variant="outlined" color="secondary" className={classes.button} onClick={this.cancel}>
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button variant="contained" color="secondary" className={classes.button} onClick={this.importQuestionBank} disabled={this.state.fileName ? false : true}>
                  Next <ArrowForwardIcon className={classes.arrow} />
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
ImportTemplateDialog.defaultProps = {
  isFromLibrary: false,
};

export default connector(compose(withRouter, withStyles(styles))(ImportTemplateDialog));
