import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import AppBarV2 from "../AppBarV2";
import { Tabs, Tab, Card, Box, Divider } from '@material-ui/core';
import AccountSettings from './ProfileComponent/AccountSettings';
import ChangePassword from './ProfileComponent/ChangePassword';
import GeneralConfig from './ProfileComponent/GeneralConfig';

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box p={3}>{children}</Box>
        )}
        </div>
    );
}

const connectedProps = (state) => ({
    user:state.authUser.user,
});

const connectionActions = {
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    "profileContainer": {
        marginTop: '2rem'
    },
    "profileInfoCard": {
        width: "70%",
        margin: "0 auto",
        display: 'flex',
        padding: '2rem',
        borderRadius: 0
    },
    tabs: {
        width: '270px',
        paddingRight: 20,
        borderRight: "1px solid #ccc",
        '& button:nth-child(2)': {
            borderTop: '1px solid #ccc',
            borderBottom: '1px solid #ccc',
          },
    },
    tab: {
        fontSize: '0.9rem',
        fontWeight: 500,
        textTransform: "capitalize",
    }
});

const MyTab = withStyles(theme => ({
    root: {
        textAlign: 'left',
    },
    wrapper: {
        display: 'block'
    },
    selected: {
      background: "#4b86ff1a",
      borderLeft: "2px solid #4b86ff",
      color: "#4b86ff"
    }
  }))(Tab);

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
        }
    }

    render() {
        if(this.props.user == null){
            return null;
        }

        const classes = this.props.classes;
        return <div>
            <AppBarV2 title="Profile" dropdown={false} withBack={true} />
            <div className={classes.profileContainer}>
                <Card className={classes.profileInfoCard}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        indicatorColor="primary"
                        textColor="primary"
                        value={this.state.tabValue}
                        onChange={(e,value)=> this.setState({ tabValue: value })}
                        aria-label="Vertical tabs example"
                        className={classes.tabs}
                    >
                        <MyTab label={<div className={classes.tab}>Profile</div>} {...a11yProps(0)} />
                        <MyTab label={<div className={classes.tab}>Change Password</div>} {...a11yProps(1)} />
                        <MyTab label={<div className={classes.tab}>Settings</div>} {...a11yProps(2)} />
                    </Tabs>
                    <TabPanel value={this.state.tabValue} style={{width: '100%'}} index={0}>
                        <AccountSettings/>
                    </TabPanel>
                    <TabPanel value={this.state.tabValue} style={{width: '100%'}} index={1}>
                        <ChangePassword/>
                    </TabPanel>
                    <TabPanel value={this.state.tabValue} style={{width: '100%'}} index={2}>
                        <GeneralConfig/>
                    </TabPanel>
                </Card>
            </div>
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(Profile));