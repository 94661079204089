import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import deleteIcon from "assets/images/delete.svg";
import classnames from "classnames";
import { FormControl, MenuItem, TableRow, TableCell } from "@material-ui/core";
import Image from "Components/Common/image.jsx";
import moment from "moment";
import editIcon from "assets/images/edit_icon.svg";
import addUsers from "assets/images/add_profile.svg";
import DoneIcon from "@material-ui/icons/Done";
import Select from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
import { Select as MaterialSelect } from "@material-ui/core";
import FinancialDetails from "./FinancialDetails";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CreateNewFolderOutlinedIcon from "@material-ui/icons/CreateNewFolderOutlined";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { DEFAULT_OPTIONS, getTheme } from "@table-library/react-table-library/material-ui";
import { useTree, CellTree } from "@table-library/react-table-library/tree";
import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import FileAncher from "../FileAncher";
const SelectAsyncPaginate = withAsyncPaginate(Select);

const connectedProps = (state) => ({
  fetchContractFoldersProgress: state.orgProduct.fetchContractFoldersProgress,
  contractFoldersData: state.orgProduct.contractFoldersData,
  fetchContractFoldersError: state.orgProduct.fetchContractFoldersError,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

const THEME = {
  Table: `
    width: 100%;
    display: table;
  `,
  Header: `
    display: table-header-group;
  `,
  Body: `
    display: table-row-group;
  `,
  Row: `
    display: table-row;
  `,
  BaseRow: `
    font-size: 14px;
  `,
  HeaderRow: `
    display: table-row;
    font-size: 14px;
    background-color: #f1f1f1;
  `,
  HeaderCell: `
    font-weight: 500;
    display: table-cell;
  `,
  BaseCell: `
    display: table-cell;
    padding: 16px 8px;
    &:nth-of-type(1) {
      width: 30%;
      padding: 16px 16px;
    }
    &:nth-of-type(2) {
      width: 15%;
      max-width: 200px;
      min-width: 150px;
    }
    &:nth-of-type(3) {
      width: 15%;
      min-width: 150px;
    }
    &:nth-of-type(4) {
      width: 15%;
    }
    &:nth-of-type(5) {
      width: 10%;
    }
    &:nth-of-type(6) {
      width: 10%;
    }
  `,
};

let FOUND = false;

const FolderBasedTable = (props) => {
  const [activeRowId, setActiveRowId] = useState(null);
  const [folderCollection, setFolderCollection] = useState([]);
  const materialTheme = getTheme(DEFAULT_OPTIONS);
  const theme = useTheme([materialTheme, THEME]);


  useEffect(() => {
    let folders = []
    try {
      folders = JSON.parse(JSON.stringify(props.contractFoldersData?.meta || []));
    } catch (e) {
    }
    const documents = [...props.orgDocuments || []];
    documents.forEach((document, index) => {
      FOUND = false;
      initializeFolders(folders, document, index);
      if (!FOUND) {
        folders = [...folders, {
          documentIndex: index,
        }]
      }
    })
    setFolderCollection(folders)
  }, [props.orgDocuments, props.contractFoldersData?.meta?.length]);

  const initializeFolders = (folders, document, index) => {
    folders.forEach((item, i) => {
      let existingIndex = item.nodes?.findIndex(O => O.id !== document.ID);
      if (existingIndex && existingIndex > -1) {
        FOUND = true;
      } else if (document.Folder === item.id) {
        item.nodes = [
          ...item?.nodes,
          {
            documentIndex: index,
          },
        ];
        FOUND = true;
      }
      if (!FOUND && item.nodes && item.nodes.length > 0) {
        initializeFolders(item.nodes, document, index);
      }
    });
  };

  const onTreeChange = (action, state) => {
    console.log(action, state);
  };

  const data = { nodes: folderCollection };

  const tree = useTree(
    data,
    {
      onChange: onTreeChange,
    },
    {
      treeIcon: {
        margin: "4px",
        size: "20px",
        iconDefault: <InsertDriveFileOutlinedIcon fontSize="small" />,
        iconRight: <FolderIcon fontSize="small" />,
        iconDown: <FolderOpenIcon fontSize="small" />,
      },
    }
  );

  const deleteFolder = (id) => {
    let folders = props.contractFoldersData?.meta || []
    updateFoldersData(folders, id);
    props.deleteFolder({ meta: folders })
  }

  const updateFoldersData = (folders, id) => {
    folders.forEach((item, i) => {
      if (id === item.id) {
        folders.splice(i, 1);
      } else {
        updateFoldersData(item.nodes || [], id);
      }
    });
  };

  const getFileTitle = (fileName) => {
    let fileN = fileName.split("-");
    if (fileN[0].includes("contractsAndAgreements")) {
      fileN.splice(0, 1);
      return fileN.join("-");
    } else {
      return fileName;
    }
  };

  return (
    <Table data={data} tree={tree} theme={theme} layout={{ custom: true }}>
      {(tableList) => (
        <>
          <Header>
            <HeaderRow>
              <HeaderCell resize>Resource</HeaderCell>
              <HeaderCell>Type</HeaderCell>
              <HeaderCell>Owner</HeaderCell>
              <HeaderCell>Status</HeaderCell>
              <HeaderCell>Created At</HeaderCell>
              <HeaderCell></HeaderCell>
            </HeaderRow>
          </Header>

          <Body>
            {tableList.map((row, index) => {
              let item = row;
              if (item.documentIndex !== undefined && item.documentIndex !== null) {
                item = { ...item, ...props.orgDocuments[item.documentIndex] }
              }

              return (<>
                <Row key={index} item={item} style={{ cursor: "pointer", background: activeRowId === item.ID ? "#dbd8d8" : "" }}>
                  {(item.documentIndex !== undefined && item.documentIndex !== null) ? (<>
                    <CellTree item={item}>
                      {!item.isEdit && <div>
                        {(!item.URL || item.URL === "") ? (
                          <p style={{ marging: 0, padding: "0px 10px" }}>{item?.Title}</p>
                        ) : (
                          <FileAncher
                            fileName={(item.Title?.length > 0) ? getFileTitle(item.Title) : item?.URL}
                            fileURL={item?.URL}
                          />
                        )}
                      </div>}

                      {item.isEdit && <div>
                        <input
                          className={props.classes.formControl}
                          value={item.Title}
                          onChange={(e) => {
                            props.updateLocalValue(e.target.value, 'Title', item.documentIndex)
                          }}
                        />
                      </div>}

                    </CellTree>
                    <Cell>
                      {!item.isEdit && <div>
                        {item.DocumentType !== '' && item.DocumentType !== null ? item.DocumentType?.name : 'Other'}
                      </div>}

                      {item.isEdit && <div>
                        <FormControl className={props.classes?.formSelect} style={{ zIndex: 11 }}>
                          <Select
                            isClearable
                            isMulti={false}
                            labelId="singleSelect-select-label"
                            id="singleSelect-select"
                            isDisabled={false}
                            value={item.Type}
                            onChange={(e) => {
                              props.updateLocalValue(e, 'Type', item.documentIndex)
                            }}
                            fullWidth
                            options={props.typeOptions}
                            menuPortalTarget={document.body}
                            // menuPosition={'fixed'}
                            menuPlacement={'auto'}
                            placeholder={"Select Option"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                          />
                        </FormControl>
                      </div>}
                    </Cell>
                    <Cell>
                      {!item.isEdit && <span>
                        {item.User !== null && item.User !== '' ? item.User?.label : <Image className={classnames(props.classes?.addUser, 'evaluate-add-user')} alt="add user" src={addUsers} onClick={(event) => { }} />}
                      </span>}

                      {item.isEdit && <div>
                        <FormControl className={props.classes?.formSelect} style={{ zIndex: 11 }}>
                          {false && <Select
                            isClearable
                            isMulti={false}
                            labelId="singleSelect-select-label"
                            id="singleSelect-select"
                            isDisabled={false}
                            value={item.User}
                            onChange={(e) => {
                              props.updateLocalValue(e, 'User', item.documentIndex)
                            }}
                            fullWidth
                            options={props.documentUserOptions}
                            menuPortalTarget={document.body}
                            // menuPosition={'fixed'}
                            menuPlacement={'auto'}
                            placeholder={"Select Option"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                          />}

                          <SelectAsyncPaginate
                            isClearable={true}
                            isMulti={false}
                            isSearchable={true}
                            value={item.User}
                            menuPosition='fixed'
                            classNamePrefix="select"
                            isLoading={props.userSearchProgress}
                            placeholder="Search User"
                            additional={{
                              page: 0,
                            }}
                            loadOptions={props.getOptions}
                            onChange={(e) => {
                              props.updateLocalValue(e, 'User', item.documentIndex)
                            }}
                          />
                        </FormControl>
                      </div>}
                    </Cell>
                    <Cell>
                      {!item.isEdit && <div>
                        {item.Status}
                      </div>}
                      {item.isEdit && <div>
                        <FormControl variant="outlined" className={props.classes?.formControlSel} size="small">
                          <MaterialSelect value={item.Status} onChange={(e) => {
                            props.updateLocalValue(e.target.value, 'Status', item.documentIndex)
                          }}>
                            {props.documentStatusOptions.map((op) => {
                              return <MenuItem value={op.value} style={{ fontSize: 13 }}>{op.label}</MenuItem>
                            })}
                          </MaterialSelect>
                        </FormControl>
                      </div>}
                    </Cell>
                    <Cell>
                      {moment(item?.CreatedAt).format("DD-MMM-YYYY")}
                    </Cell>
                    <Cell>
                      <div style={{ display: 'flex' }}>
                        {!item.isEdit && <IconButton onClick={() => props.handleEdit(item.documentIndex)}>
                          <Image src={editIcon} />
                        </IconButton>}

                        {item.isEdit && <IconButton onClick={() => props.handleUpdate(item.documentIndex)}>
                          <DoneIcon />
                        </IconButton>}

                        {!item.isEdit && <IconButton onClick={(e) => {
                          e.stopPropagation();
                          props.deleteContracts(item, item.documentIndex);
                        }}>
                          <Image src={deleteIcon} />
                        </IconButton>}
                        <IconButton onClick={() => {
                          setActiveRowId(activeRowId === item.ID ? null : item.ID)
                        }}>
                          {activeRowId === item.ID ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>

                        <IconButton onClick={(e) => {
                          e.stopPropagation();
                          props.addFileToFolder(item);
                        }}>
                          <CreateNewFolderOutlinedIcon />
                        </IconButton>

                      </div>
                    </Cell>
                  </>) : (
                    <>
                      <CellTree item={item}><div style={{ marging: 0, padding: "0px 10px" }}>{item.label}</div></CellTree>
                      <Cell></Cell>
                      <Cell></Cell>
                      <Cell></Cell>
                      <Cell>
                        {moment(item?.CreatedAt).format("DD-MMM-YYYY")}
                      </Cell>
                      <Cell>
                        <div style={{ display: 'flex' }}>
                          <IconButton onClick={(e) => {
                            e.stopPropagation();
                            props.updateFolder(item);
                          }}>
                            <Image src={editIcon} />
                          </IconButton>
                          <IconButton onClick={(e) => {
                            e.stopPropagation();
                            deleteFolder(item.id);
                          }}>
                            <Image src={deleteIcon} />
                          </IconButton>
                        </div>
                      </Cell>
                    </>
                  )}

                </Row>
                {activeRowId === item.ID && <TableRow>
                  <TableCell padding="checkbox" colSpan="7">
                    <div>
                      <FinancialDetails
                        document={item}
                        userType={props.userType}
                        isEditable={(props.section?.Permission === "write") || (props.section?.Access === 2)}
                        sectionName={props.section?.SectionName.toLowerCase().split(" ").join("-")}
                        section={props.section} />
                    </div>
                  </TableCell>
                </TableRow>}
              </>)
            })}
            {tableList.length <= 0 && <TableRow><TableCell colSpan={7} style={{ textAlign: 'center' }}> No Document</TableCell></TableRow>}
          </Body>
        </>
      )}
    </Table>
  )
}

export default connector(compose(withRouter, withStyles(styles))(FolderBasedTable));
