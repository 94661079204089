import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, Typography} from '@material-ui/core';
import {hideReadMoreDialog } from "../../../../../../../redux/evaluation/criteria/action"
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    readMore:state.evaludationCriteria.readMore,
});

const connectionActions = {
    hideDialog:hideReadMoreDialog,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    title:{
        textAlign:"center",
        fontWeight:500,
        color: '#282D30',
        fontSize: 20,
        margin: '20px 0px'
    },
    dialogContent: {
        margin: 'auto',
        marginBottom: 20
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    description:{
        color:"#707070",
        fontSize: 15,
        marginTop: 30
    },
});

class ReadMoreDialog extends React.Component {

    componentDidUpdate(prevProps){
    }
 

    render() {
        const classes = this.props.classes;
        return <Dialog 
                    onClose={this.props.hideDialog} 
                    aria-labelledby="app-integrationDialog" 
                    open={this.props.readMore.isOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"md"}
                    scroll="body"
                    id="evMetaDialog"
                    >
                        <DialogContent classes={{root:classes.dialogContent}} >
                        <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon/></div>
                            <Typography variant={"h4"} className={classes.title}>{this.props.readMore.title}</Typography>
                            <p className={classes.description}>{this.props.readMore.description}</p>
                        </DialogContent>

                </Dialog>
    }
}

export default connector(compose(
    withStyles(styles)
)(ReadMoreDialog));