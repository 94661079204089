import {
    START_FETCH_PROJECT_TIMELINE, END_FETCH_PROJECT_TIMELINE,
    START_ADD_PROJECT_TIMELINE_STEP, END_ADD_PROJECT_TIMELINE_STEP,
    START_UPDATE_PROJECT_TIMELINE_STEP, END_UPDATE_PROJECT_TIMELINE_STEP,
    START_DELETE_PROJECT_TIMELINE_STEP, END_DELETE_PROJECT_TIMELINE_STEP,
    START_ADD_PROJECT_TIMELINE_STEP_COLLABORATORS, END_ADD_PROJECT_TIMELINE_STEP_COLLABORATORS
} from "./action";

const initState = {
    fetchStepsProgress: false,
    fetchStepsError: null,
    steps: [],

    addStepProgress: false,
    addStepError: null,
    addStepSuccess: null,

    updateStepProgress: false,
    updateStepError: null,
    updateStepSuccess: null,

    deleteStepProgress: false,
    deleteStepError: null,
    deleteStepSuccess: null,

    addStepColloboratorsProgress: false,
    addStepColloboratorsError: null,
    addStepColloboratorsSuccess: null,
}

export function evaluationProjectTimelineReducer(state = initState, action) {
    switch (action.type) {
        case START_FETCH_PROJECT_TIMELINE:
            return {
                ...state,
                fetchStepsProgress: true,
                fetchStepsError: null,
            }
        case END_FETCH_PROJECT_TIMELINE:
            return {
                ...state,
                fetchStepsProgress: false,
                fetchStepsError: action.payload.error,
                steps: action.payload.success,
            }

        case START_ADD_PROJECT_TIMELINE_STEP:
            return {
                ...state,
                addStepProgress: true,
                addStepError: null,
                addStepSuccess: null,
            }
        case END_ADD_PROJECT_TIMELINE_STEP:
            return {
                ...state,
                addStepProgress: false,
                addStepError: action.payload.error,
                addStepSuccess: action.payload.success,
            }

        case START_UPDATE_PROJECT_TIMELINE_STEP:
            return {
                ...state,
                updateStepProgress: true,
                updateStepError: null,
                updateStepSuccess: null,
            }
        case END_UPDATE_PROJECT_TIMELINE_STEP:
            return {
                ...state,
                updateStepProgress: false,
                updateStepError: action.payload.error,
                updateStepSuccess: action.payload.success,
            }

        case START_DELETE_PROJECT_TIMELINE_STEP:
            return {
                ...state,
                deleteStepProgress: true,
                deleteStepError: null,
                deleteStepSuccess: null,
            }
        case END_DELETE_PROJECT_TIMELINE_STEP:
            return {
                ...state,
                deleteStepProgress: false,
                deleteStepError: action.payload.error,
                deleteStepSuccess: action.payload.success,
            }

        case START_ADD_PROJECT_TIMELINE_STEP_COLLABORATORS:
            return {
                ...state,
                addStepColloboratorsProgress: true,
                addStepColloboratorsError: null,
                addStepColloboratorsSuccess: null,
            }

        case END_ADD_PROJECT_TIMELINE_STEP_COLLABORATORS:
            return {
                ...state,
                addStepColloboratorsProgress: false,
                addStepColloboratorsError: action.payload.error,
                addStepColloboratorsSuccess: action.payload.success,
            }
        default:
            return state;
    }
}




