import { combineReducers } from "redux";
import { commonReducer } from "./common/reducer";
import { registerReducer } from "./register/reducer";
import { verificationReducer } from "./verifyaccount/reducer";
import { forgotPasswordReducer } from "./forgotpassword/reducer";
import { resetPasswordReducer } from "./resetPassword/reducer";
import { loginReducer } from "./login/reducer";
import { authUserReducer } from "./authuser/reducer";
import { updatePasswordReducer } from "./updatePassword/reducer";
import { initialFlowFlagReducer } from "./InitialFlowFlag/reducer";
import { applicationReducer } from "./applications/reducer";
import { oktaReducer } from "./okta/reducer";
import { samlReducer } from "./saml/reducer";
import { metricReducer } from "./metric/reducer";
import { metricAppReducer } from "./metricapplication/reducer";
import { productCategoryReducer } from "./product/category/reducer";
import { productSubCategoryReducer } from "./product/subcategory/reducer";
import { productsReducer } from "./product/reducer";
import { orgProductReducer } from "./product/orgProduct/reducer";
import { evaluationCartReducer } from "./evaluation/cart/reducer";
import { evaluationMetaDataReducer } from "./evaluation/metadata/reducer";
import { evaluationCriteriaReducer } from "./evaluation/criteria/reducer";
import { evaluationFeedbackReducer } from "./evaluation/feedback/reducer";
import { evaluationApprovalReducer } from "./evaluation/approval/reducer";
import { evaluationReportReducer } from "./evaluation/report/reducer";
import { evaluationReportDataReducer } from "./evaluation/reportdata/reducer";
import { evaluationRequestReducer } from "./evaluation/requests/reducer";
import { criteriaMultiChoice } from "./evaluation/multichoice/reducer";
import { evaluationLinkReducer } from "./evaluation/link/reducer";
import { evaluationRuleReducer } from "./evaluation/rule/reducer";
import { evaluationWorkflowReducer } from "./evaluation/workflow/reducer";
import { evaluationWrapperWorkflowReducer } from "./evaluation/wrapperWorkflow/reducer";

import { snackbarReducer } from "./snackbar/reducer";
import { drawerReducer } from "./drawer/reducer";
import { userSearchReducer } from "./usersearch/reducers";

import { usersSettingsReducer } from "./usersSettings/reducer";
import { productsSettingsReducer } from "./productsSettings/reducer";
import { companyProfileSettingsReducer } from "./companyProfileSettings/reducer";
import { evaluateReducer } from "./evaluate/reducer";
import { templateEvaluationReducer } from "./templates/evaluation/reducers";
import { masterTemplateEvaluationReducer } from "./templates/evaluation/master/reducers";
import { orgTemplateEvaluationReducer } from "./templates/evaluation/org/reducers";

import { dialogsReducer } from "./dialogs/reducer";
import { masterReducer } from "./master/reducer";
import { paymentReducer } from "./payments/reducer";
import { sellerReducer } from "./seller/reducer";
import { sellerEvaluateReducer } from "./seller/evaluate/reducer";
import { sellerAssessmentReducer } from "./seller/assessment/reducer";

import { orgUserReducer } from "./org/user/reducer";
import { orgGroupReducer } from "./org/group/reducer";
import { notificationsReducer } from "./notifications/reducer";

import { clusterProductReducer } from "./product/cluster/reducer";
import { workflowReducer } from "./workflow/reducer";
import { quicksightReducer } from "./quicksight/reducer";

import { masterFormReducer } from "./forms/master/reducers";
import { orgFormReducer } from "./forms/org/reducers";

import { surveyReducer } from "./survey/reducers";
import { notificationsReducers } from "./notification/reducers";

import { masterWorkflowReducer } from "./master/workflow/reducer";
import { hardwareProductReducer } from "./hardwareProduct/reducers";

import { orgCategoriesReducers } from "./org/categories/reducers";
import { renewalReducer } from "./renewal/reducers";

import { evaluationTaskReducer } from "./evaluation/productTask/reducer";

import { alertReducers } from "./alerts/reducer";

import { oauthReducers } from "./oauth/reducer";

import { externalCollaboratorReducer } from "./externalCollaborator/reducer";
import { reviewReducer } from "./review/reducer";
import { appCatalogReducer } from "./product/appCatalog/reducer";
import { vendorReducer } from "./vendor/reducer";
import { serviceReducer } from "./vendor/service/reducer";
import { vendorCatalogReducer } from "./vendor/vendorCatalog/reducer";
import { serviceCatalogReducer } from "./vendor/serviceCatalog/reducer";
import { vendorServiceCatalogReducer } from "./vendor/vendorServiceCatalog/reducer";
import { expentDirectoryReducer } from "./product/expentDirectory/reducer";
import { appProductCategoryReducer } from "./product/appProductCategory/reducer";
import { riskReducer } from "./risk/reducer";
import { workflowSummaryReducer } from "./workflow/summary/reducer";
import { sellerProductReducer } from "./seller/product/reducer";
import { sellerLibraryResucer } from "./seller/library/reducer";
import { cataclogNoteReducers } from "./vendor/note/reducer";
import { evaluationProjectTimelineReducer } from "./evaluation/projectTimeline/reducer";
import { documentTypeReducer } from "./documentTypes/reducer";
import { documentTypeV2Reducer } from "./documentTypesV2/reducer";
import { orgProductContractsReducer } from "./product/orgProduct/contract/reducer";
import { visualizationReducer } from "./visualization/reducer";
import { automationReducers } from "./automations/reducer";
import { catalogTaskReducer } from "./vendor/task/reducer";
import { cataclogTableReducers } from "./vendor/table/reducer";
import { automationBuilderReducer } from "./automations/builder/reducers";
import { evaluationThreadReducer } from "./workflow/thread/reducer";
import { departmentsReducers } from "./departments/reducer";
import { publicFormReducers } from "./publicForm/reducer";
import { reportsReducer } from "./reports/reducer";
import { companyProfileReducer } from "./companyProfile/reducer";
const all_reducers = combineReducers({
  common: commonReducer,
  register: registerReducer,
  accountVerify: verificationReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  login: loginReducer,
  authUser: authUserReducer,
  updatePassword: updatePasswordReducer,
  applications: applicationReducer,
  okta: oktaReducer,
  saml: samlReducer,
  initialFlowFlag: initialFlowFlagReducer,
  metric: metricReducer,
  metricApp: metricAppReducer,
  productCategory: productCategoryReducer,
  productSubCategory: productSubCategoryReducer,
  products: productsReducer,
  orgProduct: orgProductReducer,
  evaluationCart: evaluationCartReducer,
  evaluationMetaData: evaluationMetaDataReducer,
  snackbar: snackbarReducer,
  usersSettings: usersSettingsReducer,
  evaludationCriteria: evaluationCriteriaReducer,
  evaluationFeedback: evaluationFeedbackReducer,
  evaluationApproval: evaluationApprovalReducer,
  evalutionReport: evaluationReportReducer,
  criteriaMultiChoice: criteriaMultiChoice,
  evaluationLink: evaluationLinkReducer,
  evaluationRule: evaluationRuleReducer,
  evaluationWorkflow: evaluationWorkflowReducer,
  evaluationWrapperWorkflow: evaluationWrapperWorkflowReducer,
  drawer: drawerReducer,
  userSearch: userSearchReducer,
  productsSettings: productsSettingsReducer,
  companyProfileSettings: companyProfileSettingsReducer,
  evaluate: evaluateReducer,
  templateEvaluation: templateEvaluationReducer,
  masterTemplateEvaluation: masterTemplateEvaluationReducer,
  orgTemplateEvaluation: orgTemplateEvaluationReducer,
  dialogs: dialogsReducer,
  master: masterReducer,
  payment: paymentReducer,
  seller: sellerReducer,
  sellerEvaluate: sellerEvaluateReducer,
  sellerAssessment: sellerAssessmentReducer,
  orgUser: orgUserReducer,
  orgGroup: orgGroupReducer,
  notification: notificationsReducer,
  clusterProductReducer: clusterProductReducer,
  evaluationReportData: evaluationReportDataReducer,
  evaluationRequestData: evaluationRequestReducer,
  workflow: workflowReducer,
  quicksight: quicksightReducer,
  masterForms: masterFormReducer,
  orgForms: orgFormReducer,
  survey: surveyReducer,
  masterWorkflow: masterWorkflowReducer,
  notifications: notificationsReducers,
  hardwareProduct: hardwareProductReducer,
  orgCategory: orgCategoriesReducers,
  renewal: renewalReducer,
  productTasks: evaluationTaskReducer,
  alert: alertReducers,
  oauth: oauthReducers,
  externalCollaborator: externalCollaboratorReducer,
  review: reviewReducer,
  appCatalog: appCatalogReducer,
  expentDirectory: expentDirectoryReducer,
  risk: riskReducer,
  appProductCategory: appProductCategoryReducer,
  workflowSummary: workflowSummaryReducer,
  vendors: vendorReducer,
  services: serviceReducer,
  vendorCatalog: vendorCatalogReducer,
  serviceCatalog: serviceCatalogReducer,
  vendorServiceCatalog: vendorServiceCatalogReducer,
  sellerProduct: sellerProductReducer,
  sellerLibrary: sellerLibraryResucer,
  projectTimeline: evaluationProjectTimelineReducer,
  documentType: documentTypeReducer,
  documentTypeV2: documentTypeV2Reducer,
  productContracts: orgProductContractsReducer,
  visualization: visualizationReducer,
  automation: automationReducers,
  catalogTasks: catalogTaskReducer,
  catalogTable: cataclogTableReducers,
  catalogNote: cataclogNoteReducers,
  publicForm: publicFormReducers,
  companyProfile: companyProfileReducer,
  automationBuilder: automationBuilderReducer,
  evaluationThread: evaluationThreadReducer,
  departments: departmentsReducers,
  reports: reportsReducer,
  companyProfile: companyProfileReducer,
});

// reset the state of a redux store
var _ = require("lodash");
const rootReducer = (state, action) => {
  // common action
  if (action.type === "RESET_STATE") {
    let obj = action.payload.states;
    Object.keys(obj).forEach(function (key) {
      var val = obj[key];
      state = _.set(state, key, val);
    });
  }
  return all_reducers(state, action);
};

export default rootReducer;
