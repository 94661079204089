import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {updateDrawerStyle} from "../../../../redux/drawer/action";
import {fetchEmbedableLinkStatus} from "../../../../redux/quicksight/action";
import AppBarV2 from "../../AppBarV2";
import { CircularProgress } from '@material-ui/core';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'; 
const connectedProps = (state) => ({
  embedProgress:state.quicksight.embedProgress,
  embedURL:state.quicksight.embedURL,
});

const connectionActions = {
    updateDrawerStyle:updateDrawerStyle,
    fetchEmbedableLinkStatus:fetchEmbedableLinkStatus
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        '& [class*="quicksight-embedding-iframe"]': {
            width: '100% !important',
            marginTop: 5,
            height: 'calc(100vh - 95px) !important'
        }
    }
});

class AnalyticsIndex extends React.Component {
    componentDidMount(){
        this.props.updateDrawerStyle(true,true)
        this.props.fetchEmbedableLinkStatus();
    }

    componentDidUpdate(prevProps){
        if(this.props.embedProgress === false && prevProps.embedProgress){
          var containerDiv = document.getElementById("embeddingContainer");
                var options = {
                    // replace this dummy url with the one generated via embedding API
                    url: this.props.embedURL, // replace this dummy url with the one generated via embedding API
                    container: containerDiv,
                    parameters: {
                        country: "United States"
                    },
                    scrolling: "no",
                    height: "700px",
                    width: "1000px",
                    locale: "en-US",
                    footerPaddingEnabled: true,
                    defaultEmbeddingVisualType: "TABLE", // this option only applies to QuickSight console embedding and is not used for dashboard embedding
                };
                var session = QuickSightEmbedding.embedSession(options);
                session.on("error", (payload)=>{
                  console.log(payload)
                });
        }
    }
    render() {
        return <div className={this.props.classes.root}>
            <AppBarV2 title="Analytics" withBack={true}/>
           {!this.props.embedProgress &&  <div id="embeddingContainer"></div>}
            {this.props.embedProgress &&  <CircularProgress />}
        </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(AnalyticsIndex));