import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";

// redux
import { fetchVendorCatalogViews, fetchServiceCatalogViews } from "redux/vendor/action";
import { fetchVendorCatalog } from "redux/vendor/vendorCatalog/action";
import { fetchVendorServiceCatalog } from "redux/vendor/vendorServiceCatalog/action";
// Dilogs
import TableContent from "./TableContent";

const connectedProps = (state) => ({});

const connectionActions = {
  fetchVendorCatalogViews: fetchVendorCatalogViews,
  fetchServiceCatalogViews: fetchServiceCatalogViews,
  fetchVendorCatalog: fetchVendorCatalog,
  fetchVendorServiceCatalog: fetchVendorServiceCatalog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class VendorServiceTabular extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageType: 0,
      pageSize: 20,
      sortField: "",
      sortType: "asc",
      isTabular: true,
      appliedFilters: [],
    };

    this.updatePageAndSize = this.updatePageAndSize.bind(this);
    this.selectNewTab = this.selectNewTab.bind(this);
    this.updateSearchString = this.updateSearchString.bind(this);
    this.updateSorting = this.updateSorting.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    if (this.props.type === "vendor") {
      this.props.fetchVendorCatalogViews();
    } else {
      this.props.fetchServiceCatalogViews();
    }
  }

  componentDidUpdate(prevProps) {}

  getPageSortType() {
    let sortStr;
    const { sortField, sortType } = this.state;
    if (sortField.length === 0) {
      return false;
    }
    if (sortType === "asc") {
      sortStr = sortField;
    } else {
      sortStr = "-" + sortField;
    }
    return sortStr;
  }

  fetchData = () => {
    let queryParams = {
      query: this.state.query,
      page_size: this.state.pageSize,
      page: this.state.page,
      filters: { is_org: "1" },
      tab_fields: true,
    };
    queryParams["document_filters"] = [];

    let appliedFilters = this.state.appliedFilters || [];

    appliedFilters.forEach((filter) => {
      let nested_key = "";
      let value = filter.value;
      if ([3, 4, 5, 7, 8, 10, 15].includes(filter.type)) {
        nested_key = "value";
        if(filter.type === 10){
          nested_key = "product_id";
        }
        if ([3, 4, 5, 15].includes(filter.type)) {
          value = value?.value;
        }
        if ([7, 8, 10].includes(filter.type)) {
          value = value?.ID;
        }
      }

      let filterData = {
        item_slug: filter.slug,
        nested_key: nested_key,
        type: filter.type,
        operator: [1, 11].includes(filter.type) ? "regex" : filter.operand?.value,
        value: value,
      };

      // if (filter.type === 6) {
      //   filterData["label"] = String(value?.value);
      // }
      queryParams["document_filters"].push(filterData);
    });
    if (this.props.type === "vendor") {
      this.props.fetchVendorCatalog(queryParams);
    } else {
      this.props.fetchVendorServiceCatalog(queryParams);
    }
  };

  updatePageAndSize = (page, pageSize) => {
    this.setState(
      {
        page: page,
        pageSize: pageSize,
      },
      () => {
        this.fetchData();
      }
    );
  };

  updateSearchString = (page, pageSize, searchString) => {
    this.setState(
      {
        query: searchString,
        page: page,
        pageSize: pageSize,
        sortField: "",
        sortType: "asc",
      },
      () => {
        this.fetchData();
      }
    );
  };

  updateFilters = (filters) => {
    this.setState(
      {
        appliedFilters: filters,
      },
      () => {
        this.fetchData();
      }
    );
  };

  updateSorting(type, field) {
    this.setState(
      {
        page: 0,
        sortField: field,
        sortType: type,
      },
      () => {
        this.fetchData();
      }
    );
  }

  selectNewTab(newPageType) {
    this.setState(
      {
        page: 0,
        pageType: newPageType,
      },
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.rootContainers}>
          <TableContent
            type={this.props.type}
            page={this.state.page}
            pageSize={this.state.pageSize}
            searchQuery={this.state.query}
            sortField={this.state.sortField}
            sortType={this.state.sortType}
            pageType={this.state.pageType}
            appliedFilters={this.state.appliedFilters}
            searchPlaceHolder={"Find by name"}
            tableMaxHeight={"67vh"}
            isGlobalSortRequire={true}
            onChangePage={(page, pageSize) => {
              this.updatePageAndSize(page, pageSize);
            }}
            onChangeSearch={(page, pageSize, searchString) => {
              this.updateSearchString(page, pageSize, searchString);
            }}
            onChangeTab={(newPageType) => {
              this.selectNewTab(newPageType);
            }}
            onChangeSort={(type, field) => {
              this.updateSorting(type, field);
            }}
            onApplyFilter={(filters) => {
              this.updateFilters(filters);
            }}
            fetchData={this.fetchData}
          />
        </div>
      </>
    );
  }
}

export default connector(compose(withStyles(styles), withLastLocation)(VendorServiceTabular));
