import {
  START_VENDOR_FETCH,
  END_VENDOR_FETCH,
  START_VENDOR_DETAILS_FETCH,
  END_VENDOR_DETAILS_FETCH,
  START_SERVICE_DETAILS_FETCH,
  END_SERVICE_DETAILS_FETCH,
  START_VENDOR_PRODUCT_FETCH,
  END_VENDOR_PRODUCT_FETCH,
  START_VENDOR_CATALOG_VIEWS_FETCH,
  END_VENDOR_CATALOG_VIEWS_FETCH,
  START_SERVICE_CATALOG_VIEWS_FETCH,
  END_SERVICE_CATALOG_VIEWS_FETCH,
  START_CATALOG_STATE_UPDATE,
  END_CATALOG_STATE_UPDATE,
  START_CATALOG_VIEWS_ADD,
  END_CATALOG_VIEWS_ADD,
  START_CATALOG_VIEWS_UPDATE,
  END_CATALOG_VIEWS_UPDATE,
  START_CATALOG_VIEWS_DELETE,
  END_CATALOG_VIEWS_DELETE,
  SHOW_CATALOG_FIELDS_DIALOG,
  HIDE_CATALOG_FIELDS_DIALOG,
  SHOW_CLONE_CATALOG_SECTION_DIALOG,
  HIDE_CLONE_CATALOG_SECTION_DIALOG,
  SHOW_ADD_UPDATE_TASK_DIALOG,
  HIDE_ADD_UPDATE_TASK_DIALOG,
  START_EXT_COLLABORATOR_VENDOR_FETCH,
  END_EXT_COLLABORATOR_VENDOR_FETCH,
  START_VENDOR_EXT_COLLABORATOR_ADD,
  END_VENDOR_EXT_COLLABORATOR_ADD,
  START_VENDOR_EXT_COLLABORATOR_REMOVE,
  END_VENDOR_EXT_COLLABORATOR_REMOVE,
  SHOW_VENDOR_PRODUCTS_SERVICES_DIALOG,
  HIDE_VENDOR_PRODUCTS_SERVICES_DIALOG,
  HIDE_ADD_TO_EXISTING_DIALOG,
  SHOW_ADD_TO_EXISTING_DIALOG,
  START_FETCH_CONTRACT_FOLDERS,
  END_FETCH_CONTRACT_FOLDERS,
  START_UPDATE_CONTRACT_FOLDERS,
  END_UPDATE_CONTRACT_FOLDERS,
} from "./action";

const initState = {
  fetchVendorProgress: false,
  fetchVendorError: null,
  vendorsData: {},

  vendorDetails: {},
  fetchVendorDetailsError: null,
  fetchVendorDetailsProgress: false,

  serviceDetails: {},
  fetchServiceDetailsError: null,
  fetchServiceDetailsProgress: false,

  vendorProducts: [],
  fetchVendorProductsError: null,
  fetchVendorProductsProgress: false,

  updateCatalogStateError: null,
  updateCatalogStateProgress: false,

  vendorCatalogViews: [],
  fetchvendorCatalogViewsError: null,
  fetchvendorCatalogViewsProgress: false,

  serviceCatalogViews: [],
  fetchServiceCatalogViewsError: null,
  fetchServiceCatalogViewsProgress: false,

  showDialog: false,
  showAddToExistingDialog: false,

  openVendorProductsAndServiceDialog: false,
  openVendorData: null,
  
  openCloneCatalogSectionDialog: false,

  addUpdateTaskData: null,
  showAddTaskDialog: false,
  isNewTask: true,

  catalogViewsAddError: null,
  catalogViewsAddSuccess: null,
  catalogViewsAddProgress: false,

  updateCatalogViewsError: null,
  updateCatalogViewsProgress: false,

  deleteCatalogViewsError: null,
  deleteCatalogViewsProgress: false,

  externalCollaboratorVendors: [],
  externalCollaboratorVendorsFetchProgress: false,
  externalCollaboratorVendorsFetchError: null,

  externalCollaboratorAddProgress: false,
  externalCollaboratorAddError: null,
  externalCollaboratorAddSuccess: null,

  externalCollaboratorRemoveProgress: false,
  externalCollaboratorRemoveError: null,
  externalCollaboratorRemoveSuccess: null,

  contractFoldersData: {},
  fetchContractFoldersError: null,
  fetchContractFoldersProgress: false,

  updateContractFoldersError: null,
  updateContractFoldersProgress: false,
};

export function vendorReducer(state = initState, action) {
  switch (action.type) {
    case START_UPDATE_CONTRACT_FOLDERS: {
      return {
        ...state,
        contractFoldersData: {},
        updateContractFoldersError: null,
        updateContractFoldersProgress: true,
      };
    }
    case END_UPDATE_CONTRACT_FOLDERS: {
      return {
        ...state,
        contractFoldersData: action.payload.success || {},
        updateContractFoldersProgress: false,
        updateContractFoldersError: action.payload.error,
      };
    }
    case START_FETCH_CONTRACT_FOLDERS: {
      return {
        ...state,
        contractFoldersData: {},
        fetchContractFoldersError: null,
        fetchContractFoldersProgress: true,
      };
    }
    case END_FETCH_CONTRACT_FOLDERS: {
      return {
        ...state,
        contractFoldersData: action.payload.success || {},
        fetchContractFoldersProgress: false,
        fetchContractFoldersError: action.payload.error,
      };
    }
    case START_EXT_COLLABORATOR_VENDOR_FETCH:
      return {
          ...state,
          externalCollaboratorVendors: [],
          externalCollaboratorVendorsFetchProgress: true,
          externalCollaboratorVendorsFetchError: null,
      };
    case END_EXT_COLLABORATOR_VENDOR_FETCH:
      var data = [];
      if(action.payload.success && action.payload.success.Data){
          data = action.payload.success.Data;
      }
      return {
          ...state,
          externalCollaboratorVendors: data,
          externalCollaboratorVendorsFetchProgress: false,
          externalCollaboratorVendorsFetchError: action.payload.error,
      };
    case START_VENDOR_EXT_COLLABORATOR_ADD:
      return {
          ...state,
          externalCollaboratorAddProgress: true,
          externalCollaboratorAddError: null,
          externalCollaboratorAddSuccess: null,
      };
    case END_VENDOR_EXT_COLLABORATOR_ADD:
      return {
          ...state,
          externalCollaboratorAddProgress: false,
          externalCollaboratorAddError: action.payload.error,
          externalCollaboratorAddSuccess: action.payload.success,
      };

    case START_VENDOR_EXT_COLLABORATOR_REMOVE:
        return {
            ...state,
            externalCollaboratorRemoveProgress: true,
            externalCollaboratorRemoveError: null,
            externalCollaboratorRemoveSuccess: null,
        };
    case END_VENDOR_EXT_COLLABORATOR_REMOVE:
        return {
            ...state,
            externalCollaboratorRemoveProgress: false,
            externalCollaboratorRemoveError: action.payload.error,
            externalCollaboratorRemoveSuccess: action.payload.success,
        };
    case SHOW_ADD_TO_EXISTING_DIALOG:
      return {
        ...state,
        showAddToExistingDialog: true,
      };

    case HIDE_ADD_TO_EXISTING_DIALOG: 
      return {
        ...state,
        showAddToExistingDialog: false,
      };
    case SHOW_CATALOG_FIELDS_DIALOG:
      return {
        ...state,
        showDialog: true,
      };

    case HIDE_CATALOG_FIELDS_DIALOG: 
      return {
        ...state,
        showDialog: false,
      };
    case SHOW_VENDOR_PRODUCTS_SERVICES_DIALOG:
      return {
        ...state,
        openVendorProductsAndServiceDialog: true,
        openVendorData: action.payload.vendor,
      };

    case HIDE_VENDOR_PRODUCTS_SERVICES_DIALOG: 
      return {
        ...state,
        openVendorProductsAndServiceDialog: false,
        openVendorData: null,
      };
    case SHOW_CLONE_CATALOG_SECTION_DIALOG:
      return {
        ...state,
        openCloneCatalogSectionDialog: true,
      };

    case HIDE_CLONE_CATALOG_SECTION_DIALOG: 
      return {
        ...state,
        openCloneCatalogSectionDialog: false,
      };
    case SHOW_ADD_UPDATE_TASK_DIALOG:
      return {
        ...state,
        addUpdateTaskData: action.payload.data,
        showAddTaskDialog: true,
        isNewTask: action.payload.isNew
      }

    case HIDE_ADD_UPDATE_TASK_DIALOG:
      return {
        ...state,
        addUpdateTaskData: null,
        showAddTaskDialog: false,
      }
    case START_VENDOR_CATALOG_VIEWS_FETCH:
      return {
        ...state,
        vendorCatalogViews: [],
        fetchVendorCatalogViewsError: null,
        fetchVendorCatalogViewsProgress: true,
      };
    case END_VENDOR_CATALOG_VIEWS_FETCH:
      return {
        ...state,
        vendorCatalogViews: action.payload.success || [],
        fetchVendorCatalogViewsError: action.payload.error,
        fetchVendorCatalogViewsProgress: false,
      };
    case START_SERVICE_CATALOG_VIEWS_FETCH:
      return {
        ...state,
        serviceCatalogViews: [],
        fetchServiceCatalogViewsError: null,
        fetchServiceCatalogViewsProgress: true,
      };
    case END_SERVICE_CATALOG_VIEWS_FETCH:
      return {
        ...state,
        serviceCatalogViews: action.payload.success || [],
        fetchServiceCatalogViewsError: action.payload.error,
        fetchServiceCatalogViewsProgress: false,
      };
    case START_CATALOG_VIEWS_ADD:
      return {
        ...state,
        catalogViewsAddProgress: true,
        catalogViewsAddSuccess: null,
        catalogViewsAddError: null,
      };
    case END_CATALOG_VIEWS_ADD:
      return {
        ...state,
        catalogViewsAddSuccess: action.payload.success,
        catalogViewsAddProgress: false,
        catalogViewsAddError: action.payload.error,
      };
    case START_VENDOR_FETCH:
      return {
        ...state,
        fetchVendorProgress: true,
        fetchVendorError: null,
      };

    case END_VENDOR_FETCH: {
      let currentData = { ...state.vendorsData };
      let newData = action.payload.success;
      if (action.payload?.searchQuery?.page > 0) {
        currentData?.items?.push(...newData?.items);
      } else {
        currentData = newData;
      }
      return {
        ...state,
        fetchVendorProgress: false,
        fetchVendorError: action.payload.error,
        vendorsData: currentData,
      };
    }
    case START_CATALOG_STATE_UPDATE:
      return {
        ...state,
        updateCatalogStateError: null,
        updateCatalogStateProgress: true,
      };
    case END_CATALOG_STATE_UPDATE:
      return {
        ...state,
        updateCatalogStateError: action.payload.error,
        updateCatalogStateProgress: false,
      };
    case START_CATALOG_VIEWS_UPDATE:
      return {
        ...state,
        updateCatalogViewsError: null,
        updateCatalogViewsProgress: true,
      };
    case END_CATALOG_VIEWS_UPDATE:
      return {
        ...state,
        updateCatalogViewsError: action.payload.error,
        updateCatalogViewsProgress: false,
      };
    case START_CATALOG_VIEWS_DELETE:
      return {
        ...state,
        deleteCatalogViewsError: null,
        deleteCatalogViewsProgress: true,
      };
    case END_CATALOG_VIEWS_DELETE:
      return {
        ...state,
        deleteCatalogViewsError: action.payload.error,
        deleteCatalogViewsProgress: false,
      };
    case START_VENDOR_PRODUCT_FETCH:
      return {
        ...state,
        vendorProducts: [],
        fetchVendorProductsError: null,
        fetchVendorProductsProgress: true,
      };
    case END_VENDOR_PRODUCT_FETCH:
      return {
        ...state,
        vendorProducts: action.payload.success,
        fetchVendorProductsError: action.payload.error,
        fetchVendorProductsProgress: false,
      };
    case START_VENDOR_DETAILS_FETCH:
      return {
        ...state,
        vendorDetails: {},
        fetchVendorDetailsError: null,
        fetchVendorDetailsProgress: true,
      };
    case END_VENDOR_DETAILS_FETCH:
      return {
        ...state,
        vendorDetails: action.payload.success,
        fetchVendorDetailsError: action.payload.error,
        fetchVendorDetailsProgress: false,
      };
    case START_SERVICE_DETAILS_FETCH:
      return {
        ...state,
        serviceDetails: {},
        fetchServiceDetailsError: null,
        fetchServiceDetailsProgress: true,
      };
    case END_SERVICE_DETAILS_FETCH:
      return {
        ...state,
        serviceDetails: action.payload.success,
        fetchServiceDetailsError: action.payload.error,
        fetchServiceDetailsProgress: false,
      };

    default:
      return state;
  }
}
