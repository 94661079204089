import {
    SHOW_SNACKBAR,HIDE_SNACKBAR,
} from "./action";

const initState   =     {
    show:false,
    message:"",
    type:"success",
    duration:6000,
    showOnClickAway: false

}

export  function snackbarReducer(state=initState,action){
    switch(action.type){
        case SHOW_SNACKBAR:
            // console.log(action.payload)
            return {
                ...state,
                show:true,
                message:action.payload.message,
                type:action.payload.type,
                duration:action.payload.duration,
                showOnClickAway: action.payload.showOnClickAway
            };
        case HIDE_SNACKBAR:
            return {
                ...state,
                show:false,
                message:"",
                type:"success",
                duration:6000,
                showOnClickAway: false
            };
        default:
            return state;
    }
}