import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from "classnames";
import * as zxcvbn from  "zxcvbn";

const styles = (theme) => createStyles({
    strenghtMeter:{
        marginTop:theme.spacing(1),
        display:"flex",
        marginBottom:theme.spacing(0.5),

    },
    indicator:{
        height:"10px",
        background:"#ccc",
        flex:1,
        borderRadius:"5px",
        margin:theme.spacing(0.5),
    },
    weak:{
        background:"#F75E3E"
    },
    medium:{
        background:"#FFB255"
    },
    strong:{
        background:"#00AD9F"
    },
    weak_label:{
        color:"#F75E3E"
    },
    medium_label:{
        color:"#FFB255"
    },
    strong_label:{
        color:"#00AD9F"
    },
    label:{
        flex:1,
    }
});

class PasswordStrenght extends React.Component {
    constructor(props) {
        super(props);

        this.createPasswordLabel   =   this.createPasswordLabel.bind(this);
    }

    createPasswordLabel(result){
        var classes = this.props.classes;
        switch (result.score) {
          case 0:
            return <div class={classnames(classes.label,classes.weak_label)}>Weak</div>;
          case 1:
            return <div class={classnames(classes.label,classes.weak_label)}>Weak</div>;
          case 2:
            return <div class={classnames(classes.label,classes.medium_label)}>Good</div>;
          case 3:
            return <div class={classnames(classes.label,classes.medium_label)}>Good</div>;
          case 4:
            return <div class={classnames(classes.label,classes.strong_label)}>Strong</div>;
          default:
            return <div class={classnames(classes.label,classes.weak_label)}>Weak</div>;
        }
    }

    render() {
        var classes = this.props.classes;
        const result    =   zxcvbn(this.props.value);
        // console.log(this.props.value,);
        return <div className={classes.strenghtMeter}>
            <div className={classnames(classes.indicator,{[classes.weak]:result.score >= 0})}></div>    
            <div className={classnames(classes.indicator,{[classes.medium]:result.score >= 2})}></div>    
            <div className={classnames(classes.indicator,{[classes.strong]:result.score >= 4})}></div>  
            {this.createPasswordLabel(result)} 
        </div>
    }
}

PasswordStrenght.defaultProps = {
    value:""    
}

PasswordStrenght.propTypes = {
    value: PropTypes.string.isRequired,
};

export default withStyles(styles)(PasswordStrenght)