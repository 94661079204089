import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';

const connectedProps = (state) => ({

});

const connectionActions = {

}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
});

class NotFound extends React.Component {

    render() {
        return <div>
            Page Not Found
    </div>
    }
}

export default connector(compose(
    withStyles(styles)
)(NotFound));