import { Button, CircularProgress, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import GoogleButton from 'assets/images/Google-Button.svg';
import Image from 'Components/Common/image.jsx';
import React from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { fireEvent } from 'redux/common/action';
import Logo from "../assets/images/colour_logo.svg";
import { createClientToken, login } from "../redux/login/action";
import { isClientTokenPresent } from '../util';
import * as Environment from "../util/Environment";
import * as Validator from "../util/Validation";
import AuthContainer from "./Common/AuthContainer";
import OutlinedInput from "./Common/Input/OutlinedInput";
import queryString from "query-string";
import { getNewPlatform } from "util/Environment";

const connectedProps = (state) => ({
    isProgress: state.login.isProgress,
    error: state.login.error,
    success: state.login.success,
});

const connectionActions = {
    login: login,
    createClientToken: createClientToken,
    fireEvent: fireEvent
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    "loginform": {
        textAlign: "center",
        paddingBottom: theme.spacing(3.6),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        margin: "0 auto",
        '@media (max-width: 991.98px)': {
            paddingTop: theme.spacing(3.6),
        }
    },
    "caption": {
        marginTop: theme.spacing(2)
    },
    "heading": {
        marginBottom: theme.spacing(1),
        fontSize: theme.spacing(4.2),
        color: "#4A4A4A",
    },
    "subtitle": {
        fontSize: theme.spacing(2.2),
        // marginBottom: theme.spacing(6.4),
        fontWeight: 600,
        color: "#4A4A4A",
    },
    "form": {
        marginTop: theme.spacing(5),

        "& [class*=MuiFormControl-root]": {
            marginBottom: theme.spacing(1)
        },

        "& [class*=MuiOutlinedInput-inputRoot]": {
            minHeight: '54px'
        }

    },
    "formAction": {
        marginTop: theme.spacing(4),
        margin: "0 auto",
        width: "100%"
    },
    "socialFormAction": {
        marginTop: '20px',
        margin: "0 auto",
        width: "100%"
    },
    "button": {
        padding: theme.spacing(0.8, 0.8),
        fontSize: theme.spacing(2.8),
        fontWeight: 500,
        marginTop: theme.spacing(1.2)
    },
    orRule: {
        margin: '25px 0',
        '& h2': {
            width: '100%',
            textAlign: 'center',
            borderBottom: '1px solid #BFBFBF',
            lineHeight: '0.1em',
            margin: '10px 0 20px',
            '& span': {
                background: '#fff',
                padding: '0 10px',
                color: '#BFBFBF',
                fontSize: '16px'
            }
        }
    },
    "socialBtn": {
        position: 'relative',
        // padding: theme.spacing(0.8, 0.8),
        fontSize: theme.spacing(2.8),
        fontWeight: 500,
        marginTop: theme.spacing(1.2),
        background: "#4285F4",
        '& img': {
            width: 34,
            height: 34,
            position: 'absolute',
            left: 6
        },
        "&:hover": {
            background: "#4285F4",
        }
    },
    "forgotpassword_link": {
        marginTop: theme.spacing(5.6),
        display: "block",
        "&:any-link": {
            color: theme.palette.secondary.main,
        },
        "&:any-link:active": {
            color: theme.palette.secondary.main,
        }
    },
    "register_link": {
        marginTop: theme.spacing(2.8),
        display: "block"
    },
    "registerlable": {
        textAlign: "left",
        fontSize: theme.spacing(2.4),
        textDecoration: "underline",
        color: "#4A4A4A"
    },
    "forgotlabel": {
        textAlign: "left",
        fontSize: theme.spacing(2.4),
        textDecoration: "underline",
        color: "#4A4A4A"
    },
});

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'email': "",
            "email_error": "",
            'password': "",
            "password_error": "",
            "success": "",
            "error": "",
            queryParams: queryString.parse(this.props.location.search),
            redirectTo: null
        }
        this.loginUser = this.loginUser.bind(this);
        this.clearError = this.clearError.bind(this);
        this.loginWithGoogle = this.loginWithGoogle.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        const newURL = getNewPlatform() + window.location.search
        window.open(newURL, "_self")
        // if (!isClientTokenPresent()) {
        //     this.props.createClientToken()
        // }
        // if (document.getElementById("launcher") != null) {
        //     document.getElementById("launcher").style.visibility = "visible";
        // }

        // if (this.state.queryParams && this.state.queryParams.redirectTo !== undefined && this.state.queryParams.redirectTo !== null) {
        //     console.log(this.state.queryParams.redirectTo, 'RedirectURLLLLLLLLL')
        //     this.setState({
        //         redirectTo: this.state.queryParams.redirectTo
        //     })
        // }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isProgress === false && prevProps.isProgress === true) {
            if (this.props.error !== null) {
                var error = this.props.error;
                if (error.status === 400) {
                    var errors = error.errors;
                    var stateError = {
                        'email_error': "",
                        "password_error": "",
                    };
                    for (var field in errors) {
                        switch (field) {
                            case "email":
                                stateError.email_error = errors[field][0];
                                break;
                            case "password":
                                stateError.password_error = errors[field][0];
                                break;
                            default:
                        }
                    }
                    this.setState(stateError)
                } else {
                    this.setState({ error: error.message });
                }
            } else {
                if (this.state.redirectTo === null) {
                    this.props.history.push("/splash");
                } else {
                    this.props.history.push("/splash?redirectTo=" + encodeURIComponent(this.state.redirectTo));
                }
                this.props.fireEvent({
                    type: "event.user.login",
                    data: {
                        email: this.state.email,
                    },
                })
            }
        }
    }

    clearError() {
        this.setState({
            email_error: "",
            password_error: "",
            success: "",
            error: ""
        });
    }

    loginWithGoogle() {
        var url = Environment.api_host("USER");
        window.location = url + "/cognito-login/google";
    }

    onKeyDown(e) {
        if (e.key === 'Enter') {
            this.loginUser();
        }
    }

    loginUser() {
        var me = this;
        me.clearError();

        var email = me.state.email;
        if (email == null || String(email).trim().length === 0) {
            me.setState({ email_error: "Please provide your email." });
            return;
        }

        if (Validator.validateEmail(email) === false) {
            me.setState({ email_error: "Please provide valid email." });
            return;
        }

        var password = me.state.password;
        if (password == null || String(password).trim().length === 0) {
            me.setState({ password_error: "Please enter password." });
            return;
        }

        me.props.login(email, password);
    }

    render() {
        return ''
    }
    // render() {
    //     var classes = this.props.classes;
    //     var showSso = true;
    //     if (window.location.host === "demo.expent.ai") {
    //         showSso = false;
    //     }
    //     return <AuthContainer>
    //         <div className={classes.loginform}>
    //             <Image src={Logo} alt="logo" />
    //             <div className={classes.caption}>
    //                 <Typography variant={"h5"} className={classes.heading}>Expent</Typography>
    //                 <Typography className={classes.subtitle}>Welcome Back!</Typography>
    //             </div>
    //             <div>
    //                 {this.state.error?.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
    //                 {this.state.success?.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
    //             </div>
    //             {showSso && <div className={classes.socialFormAction}>
    //                 {<Button variant="contained" fullWidth color="secondary" className={classes.socialBtn} onClick={this.loginWithGoogle}>
    //                     <Image src={GoogleButton} alt="Google Button" />
    //                     Login with Google
    //                 </Button>}
    //             </div>}
    //             <div className={classes.orRule}>
    //                 <h2><span>or</span></h2>
    //             </div>
    //             <form>
    //                 <div className={classes.form}>
    //                     <OutlinedInput
    //                         id={"emailIdInput"}
    //                         label="Email"
    //                         value={this.state.email}
    //                         onChange={(event) => {
    //                             this.setState({ email: event.target.value });
    //                         }}
    //                         error={this.state.email_error.length > 0}
    //                         helperText={this.state.email_error}
    //                         onKeyDown={this.onKeyDown}
    //                     />
    //                     <OutlinedInput
    //                         id={"passwordInput"}
    //                         label="Password"
    //                         type="password"
    //                         value={this.state.password}
    //                         onChange={(event) => {
    //                             this.setState({ password: event.target.value });
    //                         }}
    //                         error={this.state.password_error.length > 0}
    //                         helperText={this.state.password_error}
    //                         onKeyDown={this.onKeyDown}
    //                     />
    //                 </div>
    //                 <div className={classes.formAction}>
    //                     {!this.props.isProgress && <Button fullWidth variant="contained" color="secondary" className={classes.button} onClick={this.loginUser}>Login</Button>}
    //                     {this.props.isProgress && <CircularProgress />}
    //                     {/* <Button variant="contained" color="secondary" fullWidth className={classes.button} onClick={() => {
    //                         this.props.history.push("/onboard");
    //                     }}>Login</Button> */}
    //                 </div>
    //             </form>
    //             <Link to={"/forgot-password"} className={classes.forgotpassword_link}><Typography className={classes.forgotlabel}>Forgot Password?</Typography></Link>

    //             {/* <Link to={"/register"} className={classes.register_link}><Typography className={classes.registerlable}>New user? Signup</Typography></Link> */}
    //         </div>
    //     </AuthContainer>
    // }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Login));