import { CircularProgress } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import AppBarV2 from "Components/Application/AppBarV2";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { updateDrawerStyle } from "../../../../redux/drawer/action";
import DashboardBuilder from "../Dashboard/DashboardBuilder";
import { fetchAllOrgSettings } from "redux/org/user/action";
import WelcomeBack from "Components/Common/WelcomePages/WelcomeBack";
import { getUserConfigValue } from "util/Common";

const connectedProps = (state) => ({
  orgName: state.authUser.user?.Organization?.Name,
  userRole: state.authUser.user?.Role,
  user: state.authUser.user,
  fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
  orgUser: state.orgUser,
  userConfigs: state.usersSettings.userConfigs,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  fetchAllOrgSettings: fetchAllOrgSettings
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    actionContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(0, 10),
    },
    loader: {
      textAlign: 'center',
      marginTop: 30,

    }
  });

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardId: null,
      isLoader: true,
      isShowWelcome: true
    };
  }

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  };

  componentDidMount() {
    this.props.updateDrawerStyle(true, true);
    const HomePageDashboardValue = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "HomePageDashboard");
    const HomePageConfig = getUserConfigValue(this.props.userConfigs,"homePageAdmin");
    if(HomePageConfig){
      this.setState({
        dashboardId: HomePageConfig.AdditionalValue,
        isLoader: false
      })
    }else{
      this.setState({
        dashboardId: HomePageDashboardValue,
        isLoader: false
      })
    }
    // setTimeout(() => {
    //   this.setState({
    //     isShowWelcome: false
    //   })
    // }, 5000);
    
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps){
    if(this.props.fetchAllOrgSettingsInprogress !== prevProps.fetchAllOrgSettingsInprogress){
      const HomePageDashboardValue = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "HomePageDashboard");
      const HomePageConfig = getUserConfigValue(this.props.userConfigs,"homePageAdmin");
      if(HomePageConfig){
        this.setState({
          dashboardId: HomePageConfig.AdditionalValue,
          isLoader: false
        })
      }else{
        this.setState({
          dashboardId: HomePageDashboardValue,
          isLoader: false
        })
      }
    }
  }



  render() {
    const classes = this.props.classes;
    const { isLoader, dashboardId, isShowWelcome } = this.state;
    return (
      <div>
        <AppBarV2 title={"Home"} />
        {isLoader && <div className={classes.loader}>
          <CircularProgress />
        </div>}

        {isShowWelcome && <div style={{ margin: '2em' }}>
          <WelcomeBack />
        </div>}

        {!isLoader && <DashboardBuilder
          id={dashboardId}
          dashboardFor={'HOME'}
        />}

      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Home));
