import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  TableContainer,
  Button,
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton,
  Paper,
  MenuItem,
  FormControl,
  Select,
  Tooltip,
  Popover,
  Typography,
  List,
  ListItem,
  AppBar,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import * as Environment from "util/Environment";
import { CommonFn } from "services/commonFn";
import { preciseDiffMoments } from "util/Common";
import SqureButton from "Components/Common/SqureButton";
import { fetchOrgCatalogDetailsTabs } from "redux/vendor/vendorCatalog/action";
import { renewalValidColumnTypes } from "util/AppCatalogTypeOptions";
import { currencyCodeMap } from "util/Currency";
import { addOrgSettings } from "redux/org/user/action";
import DateFilter from "Components/Common/Filters/DateFilter";

import { fetchOrgProductDetailsTabs } from "redux/product/orgProduct/action";
import ReactSelect from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
const SelectAsyncPaginate = withAsyncPaginate(ReactSelect);

const renewalTableColumns = [
  { id: "contractName", type: 1, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Contract", parent: "Default" },
  { id: "renewalDate", type: 2, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Renewal Date", parent: "Default" },
  { id: "renewalInDays", type: "Number", align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Renewal In Days", parent: "Default" },
  { id: "ownerName", type: 1, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Contract Owner", parent: "Default" },
  { id: "requestStatus", type: 1, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Request Status", parent: "Default" },
  { id: "currentStep", type: 1, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Current Step", parent: "Default" },
  { id: "projectOwnerName", type: 1, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Project Owner", parent: "Default" },
  { id: "status", type: 100, align: "left", isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: "Status", parent: "Default" },
  // { id: 'entity', type: 1, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Entity' , parent:'Default'},
];

const stateArr = [
  { label: "Approved", value: "Approved" },
  { label: "Rejected", value: "Rejected" },
  { label: "Draft", value: "Draft" },
  { label: "Pending Approval", value: "PendingApproval" },
];

const statusFilterOptions = [
  // { label: "All", shortName: "NS", value: 0, classname: 'isFeatured' },
  { label: "Not Started", shortName: "NS", value: 1, classname: "isFeatured" },
  { label: "In Progress", shortName: "IP", value: 2, classname: "isExisting" },
  { label: "Renewed", shortName: "R", value: 3, classname: "isInReview" },
  { label: "Not going to renew", shortName: "NR", value: 4, classname: "isLimited" },
];

const connectedProps = (state) => ({
  orgCatalogDetailTabs: state.vendorCatalog.orgCatalogDetailTabs,
  orgCatalogDetailTabsProgress: state.vendorCatalog.orgCatalogDetailTabsProgress,
  orgUser: state.orgUser,
  orgProductDetailTabs: state.orgProduct.orgProductDetailTabs,
  orgProductDetailTabsProgress: state.orgProduct.orgProductDetailTabsProgress,
});

const connectionActions = {
  fetchOrgCatalogDetailsTabs: fetchOrgCatalogDetailsTabs,
  addOrgSettings: addOrgSettings,
  fetchOrgProductDetailsTabs: fetchOrgProductDetailsTabs,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    select: {
      background: "#fff",
      width: 200,
      textAlign: "left",
      marginTop: 0,
      marginBottom: 0,
      "& [class*=MuiSelect-root]": {
        padding: 9,
      },
    },
    columnDrawer: {
      width: 400,
      right: "0",
      padding: "15px",
      zIndex: "9",
    },
    filterTitle: {
      color: "#282D30",
      fontSize: theme.spacing(2.2),
    },
    selectComp: {
      width: 250,
      marginTop: 10,
    },
    autoSelectInput: {
      width: "100%",
      background: "white",
      marginTop: 10,
      "& [class*=MuiInputBase-input]": {
        padding: "10px !important",
      },
    },
    searchBox: {
      maxWidth: "500px",
      minWidth: "250px",
    },
    statusfilterBtn: {
      background: "#fff",
      border: "1px solid #888",
      borderRadius: 4,
      width: "100%",
      color: "#222",
      boxShadow: "none",
      padding: "4px 20px",
      fontSize: 16,
      "&:hover": {
        background: "#fff",
        color: "#222",
      },
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    showBtnSection: {
      flex: 1,
      textAlign: "right",
      paddingTop: 3,
      paddingRight: 10,
    },
    filterWrap: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      margin: "20px 0px",
    },
    bottomActions: {
      marginTop: 20,
      textAlign: "right",
    },
  });

class RenewalTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renewalColumns: [],
      renewals: [],
      colVisibilityData: null,
      columnInitialized: false,
      columnsMap: {},
      isDataLoaded: false,
      expanded: "Default",
      isFetchedVendorTabs: false,
      isFetchedProductTabs: false,
      originalColumns: [],
      query: "",
      duration: 0,
      startDate: new Date(),
      endDate: new Date(),
      isOpen: false,
      projectStatus: "",
      projectOwnerIDs: [],
      contractOwnerIDs: [],
      anchorEl: null,
      dates: null,
      statusFilter: [1, 2],
      renewalStatusfilterPopover: null,
      isShowFilters: false,
      filterEl: null,
      page: 0,
    };
  }

  componentDidMount() {
    this.props.fetchOrgCatalogDetailsTabs("vendor");
    this.props.fetchOrgProductDetailsTabs();
  }

  componentDidUpdate(prevProps) {
    if (this.props.onViewSelection !== prevProps.onViewSelection) {
      this.setState({
        renewalColumns: this.props.viewColumns && this.props.viewColumns.length > 0 ? this.props.viewColumns : this.state.originalColumns,
      });
    }

    if (!this.props.orgCatalogDetailTabsProgress && prevProps.orgCatalogDetailTabsProgress) {
      this.setState(
        {
          isFetchedVendorTabs: true,
        },
        () => {
          this.initializeColumns();
        }
      );
    }

    if (!this.props.orgProductDetailTabsProgress && prevProps.orgProductDetailTabsProgress) {
      this.setState(
        {
          isFetchedProductTabs: true,
        },
        () => {
          this.initializeColumns();
        }
      );
    }

    if (this.props.renewalsData && this.props.renewalsData.length !== prevProps.renewalsData.length) {
      this.setState(
        {
          isDataLoaded: false,
        },
        () => {
          this.initializeData();
        }
      );
    }

    if (this.props.fetchProgress === false && prevProps.fetchProgress) {
      this.setState(
        {
          isDataLoaded: false,
        },
        () => {
          this.initializeData();
        }
      );
    }
  }

  searchQuery = (text) => {
    this.setState({
      query: text,
    });
  };

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) {}
      return result.trim() || "";
    }
  };

  getRenewalColumns = (renewalColumns) => {
    let columns = [];
    // const localColumns = window.localStorage.getItem("renewalListingColumns");
    const localColumns = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "RENEWAL_TABLE_COLUMN") || "";
    if (localColumns !== undefined && localColumns !== null && localColumns !== "null" && localColumns !== "") {
      let localStorageCols = JSON.parse(localColumns);
      if (localStorageCols.length > 0) {
        columns = localStorageCols;
      } else {
        columns = renewalColumns;
      }
    } else {
      columns = renewalColumns;
    }
    // this.updateColumnNames(columns)
    // window.localStorage.setItem("renewalListingColumns", JSON.stringify(columns));
    return columns;
  };

  initializeColumns() {
    const { isFetchedVendorTabs, isFetchedProductTabs } = this.state;

    if (isFetchedVendorTabs && isFetchedProductTabs) {
      let renewalColumns = [...renewalTableColumns];
      let validTypes = renewalValidColumnTypes();
      let columnsMap = {};
      let tabs = this.props.orgCatalogDetailTabs;
      tabs.forEach((tab) => {
        tab.sections.forEach((section) => {
          section.items.forEach((item) => {
            if (validTypes.indexOf(item.type) === -1) {
              return;
            }
            columnsMap[item.slug] = item.type;
          });
        });
      });

      let productTabs = this.props.orgProductDetailTabs;
      // console.log(productTabs, 'productTabs')
      productTabs.forEach((tab) => {
        tab.OrgProductTabSections.forEach((section) => {
          section.OrgProductTabItems.forEach((item) => {
            if (validTypes.indexOf(item.Type) === -1) {
              return;
            }
            columnsMap[item.ItemUID] = item.Type;
          });
        });
      });

      let finalColumns = this.getRenewalColumns(renewalColumns);
      this.setState(
        {
          columnInitialized: true,
          columnsMap: columnsMap,
        },
        () => {
          this.setColumns(finalColumns);
          this.initializeData();
        }
      );
    }
  }

  initializeData() {
    const { renewalsData } = this.props;
    const { columnsMap } = this.state;
    let renewals = [];
    if (renewalsData) {
      renewals = renewalsData.map((o) => {
        let renewalInDays = preciseDiffMoments(new Date(), new Date(o.contract?.renewalDate));
        let obj = {
          contractId: o.contract?.id,
          contractName: o.contract?.name,
          renewalDate: o.contract?.renewalDate,
          status: o.contract?.status,
          ownerName: o?.contract?.owner?.name,
          ownerId: o?.contract?.owner?.id,
          ownerEmail: o.contract?.owner?.email,
          entity: o.entity,
          requestStatus: o.project?.requestStatus || "",
          currentStep: o.project?.currentStep || "",
          projectId: o.project?.projectId || "",
          projectOwnerName: o.project?.owner?.name || "",
          projectOwnerId: o.project?.owner?.id || "",
          projectOwnerEmail: o.project?.owner?.email || "",
          renewalInDays: renewalInDays,
          product: o.product,
          project: o.project,
          vendor: o.vendor,
          contract: o.contract,
          ...o.contract?.meta,
        };

        if (o.entity === "PRODUCT") {
          obj["name"] = o?.product?.name;
          obj["contractName"] = o?.product?.name + " - " + o.contract?.name;
          obj["product_id"] = o?.product?.id;
          obj["product_logo"] = o?.product?.productLogo;
          if (o.product && o.product.items) {
            Object.keys(o.product.items).forEach(function (itm) {
              let response = "";
              if (o.product.items[itm]) {
                try {
                  response = JSON.parse(o.product.items[itm]);
                } catch (e) {}
              }
              // console.log(response, 'response=responseaaaaaaaaaaaaaaaaaaaaaa')

              if (columnsMap[itm] && [7, 8].includes(columnsMap[itm]) && response && response !== "") {
                let users = response.data.map((o) => o.name);
                obj[itm] = users.join(", ");
              } else if (columnsMap[itm] && [4, 5].includes(columnsMap[itm]) && response && response !== "") {
                let users = response.data.map((o) => o.label);
                obj[itm] = users.join(", ");
              } else if (columnsMap[itm] && [20].includes(columnsMap[itm]) && response && response !== "") {
                let str = currencyCodeMap[response?.data?.type?.value] + "" + response?.data?.value;
                if (str) {
                  obj[itm] = str;
                }
              } else if (columnsMap[itm] && [1, 2, 3, 4, 5, 15, 11, 12, 13, 14, 19].includes(columnsMap[itm]) && response && response !== "") {
                obj[itm] = response.data;
              }
            });
          }
        } else if (o.entity === "VENDOR") {
          obj["name"] = o?.vendor?.name;
          obj["contractName"] = o?.vendor?.name + " - " + o.contract?.name;
          obj["product_id"] = o?.vendor?.id;
          obj["product_logo"] = o?.vendor?.logo;
          if (o.vendor && o.vendor.items) {
            Object.keys(o.vendor.items).forEach(function (itm) {
              if (columnsMap[itm] && (columnsMap[itm] === 4 || columnsMap[itm] === 5 || columnsMap[itm] === 7 || columnsMap[itm] === 8)) {
                let str = o.vendor.items[itm] ? o.vendor.items[itm].map((o) => o.label).join(", ") : "";
                if (str) {
                  obj[itm] = str;
                }
              } else if (columnsMap[itm] && columnsMap[itm] === 20) {
                let str = "";
                if (o.vendor.items[itm]) {
                  str = currencyCodeMap[o.vendor.items[itm].type.value] + "" + o.vendor.items[itm]?.value;
                }
                if (str) {
                  obj[itm] = str;
                }
              } else if (columnsMap[itm] && columnsMap[itm] === 6) {
                if (o.vendor.items[itm]) {
                  let data_map = {};
                  o.vendor.items[itm].forEach(function (itm) {
                    itm.forEach(function (dt) {
                      if (data_map[dt.Label] === undefined) {
                        data_map[dt.Label] = [];
                        data_map[dt.Label].push(dt.Value);
                      } else {
                        data_map[dt.Label].push(dt.Value);
                      }
                    });
                  });
                  if (data_map) {
                    Object.keys(data_map).forEach(function (row_dt) {
                      let str = data_map[row_dt].join(", ");
                      if (str) {
                        obj[parseInt(row_dt)] = str;
                      }
                    });
                  }
                }
              } else {
                obj[itm] = o.vendor.items[itm] ? o.vendor.items[itm] : "";
              }
            });
          }
        } else {
          obj["name"] = o?.vendor_service?.name;
          obj["contractName"] = o?.vendor_service?.name + " - " + o.contract?.name;
          obj["product_id"] = o?.vendor_service?.id;
          obj["product_logo"] = o?.vendor_service?.logo;
          if (o.vendor_service && o.vendor_service.items) {
            Object.keys(o.vendor_service.items).forEach(function (itm) {
              obj[itm] = o.vendor_service.items[itm] ? o.vendor_service.items[itm] : "";
            });
          }
        }
        return obj;
      });
    }
    this.setState({
      renewals: renewals,
      isDataLoaded: true,
    });
  }

  setColumns(columns) {
    this.setState(
      {
        renewalColumns: columns,
        originalColumns: columns,
      },
      () => {
        let customColumns = columns.filter((o) => o.isVisible && !o.isDefault);
        // console.log(customColumns, 'customColumns')
        if (customColumns && customColumns.length > 0) {
          let columns = customColumns.map((o) => {
            return {
              id: o.id.split(":tableColumn")[0],
            };
          });
          this.props.fetchRenewals(columns);
        }
      }
    );
  }

  handleCloseFilter = () => {
    this.setState({
      isShowFilters: false,
      filterEl: null,
    });
  };

  showFiltersPoper = (event) => {
    this.setState({
      filterEl: event.currentTarget || event.current,
      isShowFilters: true,
      query: this.props.filterData?.query || "",
      duration: this.props.filterData?.duration || 0,
      startDate: this.props.filterData?.startDate || new Date(),
      endDate: this.props.filterData?.endDate || new Date(),
      projectStatus: this.props.filterData?.projectStatus || "",
      projectOwnerIDs: this.props.filterData?.projectOwnerIDs || [],
      contractOwnerIDs: this.props.filterData?.contractOwnerIDs || [],
      dates: this.props.filterData?.dates || null,
      statusFilter: this.props.filterData?.statusFilter || [1, 2],
    });
  };

  clearFilter = () => {
    this.setState({
      query: "",
      duration: 0,
      startDate: new Date(),
      endDate: new Date(),
      projectStatus: "",
      projectOwnerIDs: [],
      contractOwnerIDs: [],
      dates: null,
      statusFilter: [1, 2],
    });
  };

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      let appList = [];
      this.props.users?.forEach((item, index) => {
        appList.push({
          label: item.Name,
          value: item.ID,
        });
      });
      return {
        options: appList,
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }
    let formData = {
      page: 0,
      pageSize: 10,
      query: inputValue,
    };
    let url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  handleClickRenewal(item) {
    console.log(item, "Renewal");
    let url;
    if (item.entity === "PRODUCT") {
      url = `/app/products/org-product-details/${item.product?.id}?document_id=${item.contract.id}#renewal_page`;
    } else if (item.entity === "VENDOR") {
      url = `/app/vendors/org-vendor-details/${item.vendor?.id}?document_id=${item.contract.id}#${item.contract.meta?.tab_slug}`;
    } else {
    }

    if (url !== "") {
    }
    this.props.history.push(url);
  }

  render() {
    const { renewalColumns, renewals, columnInitialized, isDataLoaded, page } = this.state;
    const { classes, fetchProgress } = this.props;
    if (!columnInitialized || !isDataLoaded) {
      return null;
    }

    return (
      <>
        <Paper style={{ width: "100%", height: "100%", overflow: "auto" }}>
          <AppBar position="static" color="default" className={classes.answerBox}>
            <div className={classes.showBtnSection}>
              <Tooltip title="Apply Filters">
                <IconButton
                  aria-label="filter list"
                  onClick={(event) => {
                    this.showFiltersPoper(event);
                  }}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div>
          </AppBar>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" key={"table_" + this.props.key}>
              <TableHead>
                <TableRow>
                  {renewalColumns &&
                    renewalColumns.map((col) => {
                      if (col.isVisible) {
                        return (
                          <TableCell key={col?.id} align={"left"} style={{ whiteSpace: "nowrap" }} padding={col?.disablePadding ? "none" : "normal"}>
                            {col.label}
                          </TableCell>
                        );
                      } else {
                        return null;
                      }
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {!fetchProgress &&
                  renewals.slice(page * 5, page * 5 + 5).map((renewal) => {
                    return (
                      <TableRow hover tabIndex={-1} style={{ cursor: "pointer" }} key={"renwal_" + renewal.contractId}>
                        {renewalColumns &&
                          renewalColumns.map((col) => {
                            if (col.isVisible) {
                              if (col.type === 2) {
                                return (
                                  <TableCell
                                    onClick={() => this.handleClickRenewal(renewal)}
                                    key={col?.id}
                                    align={"left"}
                                    style={{ whiteSpace: "nowrap" }}
                                    padding={col?.disablePadding ? "none" : "normal"}
                                  >
                                    {renewal[col.id] ? CommonFn.formateDate(renewal[col.id], true) : ""}
                                  </TableCell>
                                );
                              } else if (col.type === 6) {
                                return (
                                  <TableCell
                                    onClick={() => this.handleClickRenewal(renewal)}
                                    key={col?.id}
                                    align={"left"}
                                    style={{ whiteSpace: "nowrap" }}
                                    padding={col?.disablePadding ? "none" : "normal"}
                                  >
                                    {renewal[col.tableColumnId] ? renewal[col.tableColumnId] : ""}
                                  </TableCell>
                                );
                              } else if (col.type === 20) {
                                return (
                                  <TableCell
                                    onClick={() => this.handleClickRenewal(renewal)}
                                    key={col?.id}
                                    align={"left"}
                                    style={{ whiteSpace: "nowrap" }}
                                    padding={col?.disablePadding ? "none" : "normal"}
                                  >
                                    {renewal[col.id] && renewal[col.id].value !== undefined ? renewal[col.id].value : renewal[col.id]}
                                  </TableCell>
                                );
                              } else if (col.type === 100) {
                                return (
                                  <TableCell key={col?.id} align={"left"} style={{ whiteSpace: "nowrap" }} padding={col.disablePadding ? "none" : "normal"}>
                                    <FormControl variant="outlined" className={classes.select} margin="dense">
                                      {true && (
                                        <Select
                                          value={renewal[col.id]}
                                          onClick={() => {}}
                                          onChange={(event) => {
                                            if (event.target.value === 3) {
                                              let entity;
                                              if (renewal.entity === "VENDOR") {
                                                entity = { ...renewal.vendor, type: "VENDOR" };
                                              } else {
                                                entity = { ...renewal.product, type: "PRODUCT" };
                                              }
                                              this.props.showConfirmCallbackDialog(entity, renewal.contract);
                                            }
                                            if (renewal.entity === "PRODUCT") {
                                              this.props.updateRenewal(renewal.product?.id, { date: renewal.contract?.renewalDate, status: event.target.value });
                                            } else {
                                              this.props.updateEntityRenewal(renewal.contract?.id, { date: renewal.contract?.renewalDate, status: event.target.value });
                                            }
                                          }}
                                        >
                                          {<MenuItem value={1}>Not started</MenuItem>}
                                          {<MenuItem value={2}>In progress</MenuItem>}
                                          {<MenuItem value={3}>Renewed</MenuItem>}
                                          {<MenuItem value={4}>Not going to renew</MenuItem>}
                                        </Select>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    onClick={() => this.handleClickRenewal(renewal)}
                                    key={col.id}
                                    align={"left"}
                                    style={{ whiteSpace: "nowrap" }}
                                    padding={col?.disablePadding ? "none" : "normal"}
                                  >
                                    {renewal[col.id] ? renewal[col.id] : ""}
                                  </TableCell>
                                );
                              }
                            } else {
                              return null;
                            }
                          })}
                      </TableRow>
                    );
                  })}

                {!fetchProgress && (renewals === undefined || renewals === null || renewals.length <= 0) && (
                  <TableRow>
                    <TableCell colSpan={renewalColumns.length + 1} style={{ textAlign: "center" }} className={classes.nameCol}>
                      {"No Renewals"}
                    </TableCell>
                  </TableRow>
                )}

                {fetchProgress && (
                  <TableRow>
                    <TableCell colSpan={renewalColumns.length + 1} style={{ textAlign: "center" }} className={classes.nameCol}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={renewals?.length || 0}
            rowsPerPage={5}
            page={page}
            onPageChange={(e, newPage) => {
              this.setState({ page: newPage });
            }}
            onRowsPerPageChange={() => {}}
          />
        </Paper>

        {this.state.isShowFilters && (
          <Popover
            open={this.state.isShowFilters}
            anchorEl={this.state.filterEl}
            onClose={this.handleCloseFilter}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={classes.columnDrawer}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ marginTop: 10 }} variant="h6">
                  Filters
                </Typography>
                <SqureButton style={{ marginTop: 10 }} variant={"outlined"} onClick={this.clearFilter}>
                  Clear
                </SqureButton>
              </div>
              <div className={classes.filterWrap}>
                <div className={classes.searchBox}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Search Renewal
                  </Typography>
                  <TextField
                    className={classes.autoSelectInput}
                    value={this.state.query}
                    variant="outlined"
                    placeholder={"Search Renewal"}
                    type="search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onInput={(e) => {
                      this.searchQuery(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Renewal Status
                  </Typography>
                  <div className={classes.selectComp} style={{ width: "200px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.statusfilterBtn}
                      onClick={(event) => {
                        this.setState({
                          renewalStatusfilterPopover: event.currentTarget,
                        });
                      }}
                    >
                      Status
                    </Button>
                    <Popover
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      id="renewalStatusfilterPopover"
                      className={classes.popover}
                      anchorEl={this.state.renewalStatusfilterPopover}
                      keepMounted
                      open={Boolean(this.state.renewalStatusfilterPopover)}
                      onClose={() => {
                        this.setState({ renewalStatusfilterPopover: null });
                      }}
                    >
                      <List>
                        {statusFilterOptions.map((obj, i) => (
                          <ListItem key={i} classes={{ button: classes.listItem }} button onClick={() => {}}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  onChange={(e) => {
                                    let newStatusFilter = [...this.state.statusFilter];
                                    let index = newStatusFilter.indexOf(obj.value);
                                    if (index > -1) {
                                      newStatusFilter.splice(index, 1);
                                    } else {
                                      newStatusFilter.push(obj.value);
                                    }
                                    this.setState({ statusFilter: newStatusFilter });
                                  }}
                                  checked={this.state.statusFilter?.includes(obj.value)}
                                />
                              }
                              name={obj.value}
                              label={obj.label}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Popover>
                  </div>
                </div>
                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Date
                  </Typography>
                  <DateFilter
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    duration={this.state.duration}
                    showPastDue={true}
                    onChangeDates={(dates) => {
                      this.setState({ dates: dates });
                    }}
                  />
                </div>
                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Project Status
                  </Typography>
                  <div className={classes.selectComp}>
                    <ReactSelect
                      isClearable
                      menuPosition="fixed"
                      isSearchable={false}
                      options={stateArr}
                      classNamePrefix="select"
                      placeholder="Select Project Status"
                      onChange={(e) => {
                        this.setState({ projectStatus: e });
                      }}
                      value={this.state.projectStatus}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </div>
                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Project Owner
                  </Typography>
                  <div className={classes.selectComp}>
                    <SelectAsyncPaginate
                      isClearable={false}
                      isMulti
                      isSearchable={true}
                      value={this.state.projectOwnerIDs}
                      menuPosition="fixed"
                      classNamePrefix="select"
                      isLoading={false}
                      placeholder="Select project owner"
                      additional={{
                        page: 0,
                      }}
                      loadOptions={this.getOptions}
                      onChange={(e) => {
                        this.setState({
                          projectOwnerIDs: e,
                        });
                      }}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </div>
                <div>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Contract Owner
                  </Typography>
                  <div className={classes.selectComp}>
                    <SelectAsyncPaginate
                      isClearable={false}
                      isMulti
                      isSearchable={true}
                      value={this.state.contractOwnerIDs}
                      menuPosition="fixed"
                      classNamePrefix="select"
                      isLoading={false}
                      placeholder="Select contract owner"
                      additional={{
                        page: 0,
                      }}
                      loadOptions={this.getOptions}
                      onChange={(e) => {
                        this.setState({
                          contractOwnerIDs: e,
                        });
                      }}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.bottomActions}>
                <SqureButton
                  style={{ marginLeft: "10px" }}
                  variant={"outlined"}
                  onClick={() => {
                    this.handleCloseFilter();
                  }}
                >
                  Cancel
                </SqureButton>

                <SqureButton
                  style={{ marginLeft: "10px" }}
                  variant={"contained"}
                  onClick={() => {
                    this.props.applyFilter({
                      query: this.state.query || "",
                      duration: this.state.duration || 0,
                      startDate: this.state.startDate || new Date(),
                      endDate: this.state.endDate || new Date(),
                      projectStatus: this.state.projectStatus || "",
                      projectOwnerIDs: this.state.projectOwnerIDs || [],
                      contractOwnerIDs: this.state.contractOwnerIDs || [],
                      dates: this.state.dates || null,
                      statusFilter: this.state.statusFilter || [1, 2],
                    });
                    this.handleCloseFilter();
                  }}
                >
                  Confirm
                </SqureButton>
              </div>
            </Paper>
          </Popover>
        )}
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(RenewalTable));
