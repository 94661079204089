import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import Item from "./item";
import ItemGroup from "./itemGroup";
import { Add } from "@material-ui/icons";
import { storeData } from "redux/companyProfile/action";
import { itemGroupMap } from "util/CompanyProfile";

const connectedProps = () => ({});

const connectionActions = {};

const ProfileSection = ({ classes, isEdit, activeMenu, section }) => {
  const { storeDataSuccess } = useSelector((state) => state.companyProfile);
  const dispatch = useDispatch();

  useEffect(() => { }, [storeDataSuccess]);

  const addItemGroup = () => {
    const newStoreData = { ...storeDataSuccess };
    if (newStoreData[activeMenu]) {
      newStoreData[activeMenu].forEach(function (s) {
        if (s.sectionKey === section.sectionKey) {
          const itemGrpKey = section.itemGroups.length > 0 ? section.itemGroups[0].itemGroupKey : null;
          if (itemGrpKey && itemGroupMap[itemGrpKey]) {
            const newItemGroup = { ...itemGroupMap[itemGrpKey] };
            newItemGroup.items = newItemGroup.items.map(o => ({ ...o, value: null }));
            section.itemGroups.push(newItemGroup);
          }
        }
      });
      dispatch(storeData(newStoreData));
    }
  };

  const deleteItemGroup = (itemGroupIndex) => {
    let newStoreData = { ...storeDataSuccess };
    let sectionToUpdate = newStoreData[activeMenu]?.find(
      (s) => s.sectionKey === section.sectionKey
    );
    sectionToUpdate.itemGroups.splice(itemGroupIndex, 1)
    dispatch(storeData(newStoreData));
  };
  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.heading}>
        {section.sectionName}
      </Typography>
      <Grid container>
        {section.itemGroups ? (
          <>
            {section.itemGroups.length > 0 &&
              section.itemGroups.map((itemGroup, index) => {
                return (
                  <ItemGroup
                    key={'itmGroup_' + section.sectionKey + '_ig' + index}
                    index={index}
                    deleteItemGroup={deleteItemGroup}
                    section={section}
                    itemGroup={itemGroup}
                    isEdit={isEdit}
                    activeMenu={activeMenu}
                  />
                );
              })}
            {isEdit && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "end",
                  margin: "10px 0px 5px 0px",
                }}
              >
                <Button
                  startIcon={<Add htmlColor={"#17BEBB"} />}
                  style={{ color: "#17BEBB" }}
                  onClick={() => {
                    addItemGroup()
                  }}
                >
                  Add{" "}
                  {section.sectionName.toLowerCase() === "primary address"
                    ? "Another Address"
                    : section.sectionName}
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            {section.items &&
              section.items.length > 0 &&
              section.items.map((item, index) => (
                <Grid item xs={item.size} key={'item_' + item.itemKey + '_' + index}>
                  <Box sx={{ paddingRight: "24px" }}>
                    <Item
                      item={item}
                      isEdit={isEdit}
                      activeMenu={activeMenu}
                      section={section}
                    />
                  </Box>
                </Grid>
              ))}
          </>
        )}
      </Grid>
    </div>
  );
};

ProfileSection.defaultProps = {
  isEdit: false,
};

const styles = (theme) =>
  createStyles({
    root: {},
    heading: {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 500,
      color: "#003B4D",
      textTransform: "uppercase",
    },
  });

export default connect(
  connectedProps,
  connectionActions
)(withStyles(styles)(ProfileSection));
