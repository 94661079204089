import React, { Component } from "react";
import Select from "react-select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import _ from "underscore";
import * as Environment from "util/Environment";
import axios from "axios";
import { CommonFn } from "services/commonFn";
import { Typography, TextField, FormControl, FormControlLabel, Checkbox, InputAdornment, Slider, Grid, CircularProgress, Chip } from "@material-ui/core";
import Image from "Components/Common/image.jsx";
// import deleteIcon from "assets/images/delete.svg";
import CommonCss from "commonCss";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { typeOptionsSeller, questionLibbinaryOptions } from "util/RequirementTypeOptions";
// import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { currencyOptions, currencyCodeMap } from "util/Currency";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "assets/images/calendar.svg";

import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import * as Validator from "util/Validation";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import deleteIcon from "assets/images/delete.svg";
import FileAncher from "Components/Common/FileAncher";

var currencyStyles = {
  control: (styles) => ({ ...styles, height: 56 }),
  menuPortal: (base) => ({ ...base, zIndex: 10 }),
  option: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
  }),
};

const styles = (theme) =>
  createStyles({
    root_new: {
      "& ul.answers": {
        listStyle: "none",
        paddingLeft: 0,
        "& li": {
          border: "1px solid #EBEBEB",
          bordeRadius: "4px",
          paddingLeft: "8px",
          marginBottom: "8px",
          "& span": {
            fontSize: 13,
            color: "#6D7278",
          },
        },
      },
    },
    root: {
      position: "relative",
      border: "1px solid #dadce0",
      padding: 30,
      borderRadius: 5,
      background: "#FFF",
      marginBottom: 17,

      "& [class*=MuiInputBase-input]": {
        fontSize: theme.spacing(1.8),
        color: "#282D30",
        "&::-webkit-input-placeholder": {
          color: "#707070",
          fontSize: theme.spacing(1.8),
        },
      },
    },
    checkbox: {
      padding: "0px !important",
      // '& [class*=MuiCheckbox-root]': {
      // }
    },
    formControl: {
      ...CommonCss.formControl,
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
      },
      padding: ".375rem",
    },
    formGroup: {
      "& input": {
        color: "#6C6C6C !important",
      },
    },
    dialogContent: {
      minHeight: 170,
      overflowY: "hidden",
      "& [class*=select__menu]": {
        maxHeight: 100,
      },
    },
    type: {
      fontSize: 14,
      color: "#6C6C6C",
      opacity: 1,
    },
    head: {
      fontSize: 16,
      color: "#6C6C6C",
      opacity: 1,
      margin: 0,
      fontWeight: 500,
      marginBottom: 15,
    },
    itemHead: {
      fontSize: 14,
      color: "#6C6C6C",
      opacity: 1,
    },
    fieldContainer: {
      // marginTop: 15,
      marginBottom: 20,
    },
    inputContainer: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center flex-start",
      alignItems: "center",
      marginBottom: 5,
    },
    inputContainer1: {
      flex: 1,
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center flex-start",
      alignItems: "center",
      marginBottom: 5,
    },
    conditionBox: {
      flex: 1,
    },
    inputRow: {
      display: "flex",
      alignItems: "center",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #EBEBEB",
      borderRadius: "4px",
      padding: "5px",
      marginTop: "10px",
      flex: 1,
    },
    inputRow1: {
      display: "flex",
      alignItems: "center",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #EBEBEB",
      borderRadius: "4px",
      padding: "5px",
      width: "95%",
    },
    inputRowBoolean: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #EBEBEB",
      borderRadius: "4px",
      padding: "5px",
      width: "max-content",
      marginRight: 10,
    },
    count: {
      display: "inline-block",
      textAlign: "center",
      color: "#6D7278",
      fontSize: "13px",
      marginRight: 10,
      width: 20,
    },
    input: {
      border: "0px",
      height: "30px",
      width: "calc(100% - 20px)",
      outline: 0,
      color: "#6D7278",
      fontSize: "13px",
    },
    dragIndicator: {
      color: "#D9D9D9",
      position: "absolute",
      top: 15,
      left: "50%",
      transform: "rotate(90deg) translateY(-50%) translateX(-50%)",
    },
    deleteIcon: {
      position: "absolute",
      right: 16,
      top: 13,
      cursor: "pointer",
    },
    borderNone: {
      border: "none",
      padding: 0,
      minHeight: 0,
      '& [class*="MuiInputBase-root"]': {
        color: "#6D7278",
        fontSize: 13,
      },
    },
    deleteImage: {
      cursor: "pointer",
    },
    label: {
      color: "#000000de",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.6px",
    },
    value: {
      color: "#000000de",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.65x",
    },
    card: {
      boxShadow: "none",
      "&.details-border": {
        border: "1px solid #ddd",
      },
    },
    active: {
      display: "inline-block",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      background: "#33C162",
    },
    meta: {},
    requiredItem: {},
    activeSwitch: {
      "& .Mui-disabled": {
        color: "#4b86ff!important",
      },
    },
    leftBar: {
      display: "flex",
      height: "calc(100% + 2px)",
      left: "-1px",
      paddingRight: "5px",
      position: "absolute",
      bottom: "-1px",
      width: "1px",
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      background: "#4285f4",
    },
    greenLeftBar: {
      display: "flex",
      height: "calc(100% + 2px)",
      left: "-1px",
      paddingRight: "5px",
      position: "absolute",
      bottom: "-1px",
      width: "1px",
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      background: "green",
    },
    editItem: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
      "& .MuiFilledInput-multiline": {
        padding: "15px 15px 10px",
      },
    },
    bottomActions: {
      borderTop: "1px solid #dadce0",
      padding: "17px 0px 0px 17px",
      // marginTop: 17,
      textAlign: "right",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    bottomItem: {
      // marginRight: 20
    },
    dividerBtm: {
      margin: "0px 10px",
    },
    additionalDataContainer: {
      marginTop: 10,
    },
    optionContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: 5,
    },
    threeDot: {
      position: "absolute",
      right: 0,
      top: 0,
    },
    threeDotForConditional: {
      position: "absolute",
      right: 45,
      top: 0,
    },
    numberOptionContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: 5,
      justifyContent: "space-between",
    },
    numberInputRow1: {
      marginLeft: 10,
      marginRight: 10,
      display: "flex",
      alignItems: "center",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #9f9f9f87",
      borderRadius: "4px",
      padding: "5px",
    },
    numberInputCol2: {
      "& [class*=MuiInputBase-input]": {
        fontSize: theme.spacing(1.8),
        color: "#282D30",
        padding: 9,
        "&::-webkit-input-placeholder": {
          color: "#707070",
          fontSize: theme.spacing(1.8),
        },
      },
    },
    description: {
      fontSize: 14,
      color: "#B5BCC2",
      opacity: 1,
    },
    rules: {
      marginTop: 20,
    },
    ruleTable: {
      width: "100%",
      "& [class*=MuiSelect-root]": {
        minHeight: 22,
      },
    },
    copiedText: {
      textAlign: "left",
      fontSize: "14px",
      color: "#2EB77D",
    },
    copyText: {
      fontWeight: 400,
      fontSize: theme.spacing(2),
    },
    filterTitle1: {
      fontSize: 14,
      marginBottom: 5,
      color: "#6C6C6C",
    },
    errMessage: {
      margin: 0,
      fontSize: 12,
      color: "#f44336",
    },
    yourQuestion: {
      marginTop: 10,
    },
    currencyTypeFields: {
      marginBottom: 15,
      marginTop: 15,
      '& [class*="MuiFormControl-root"]': {
        width: "100%",
      },
      '& [class*="MuiInputBase-input"]': {
        textAlign: "left",
      },
      '& [class*="MuiSelect-root"]': {
        height: 56,
      },
    },
    flexWrap: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 20,
    },
    inputCriteria: {
      minHeight: 38,
      padding: "2px 8px",
      border: "1px solid #cccccc",
      borderRadius: 3,
      fontSize: 15,
      color: "rgb(51, 51, 51)",
      width: "85%",
      marginRight: 10,
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 38,
      minWidth: 140,
      border: "none",
      cursor: "pointer",
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    customTypeFields: {
      marginBottom: 20,
      marginTop: 20,
      '& [class*="MuiFormControl-root"]': {
        width: "100%",
      },
      '& [class*="MuiInputBase-input"]': {
        textAlign: "left",
      },
    },
    subHead: {
      color: "#AFAFAF",
      fontSize: "12px",
      marginBottom: "16px",
      display: "block",
    },
    ratingRoot: {
      // background: '#f1f1f1',
      color: "#fff",
      padding: "1px",
      width: "100%",
    },
    rating: {
      marginTop: "10px",
      marginBottom: "5px",
      '& [class*="MuiSlider-markActive"]': {
        background: "#bfb4b4",
      },
    },
    slider: {
      background: "#f7f7f7",
      display: "flex",
      alignItems: "center",
      padding: "2px 10px",
      borderRadius: "10px",
    },
    sliderCount: {
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
    },
    thumb: {
      color: "#1f73b7",
    },
    track: {
      backgroundColor: "#1f73b7",
    },
    rail: {
      backgroundColor: "#1f73b7",
    },
    resListItem: {
      marginRight: "5px",
      marginLeft: "5px",
      borderRadius: "4px",
      border: "1px solid #707070",
      justifyContent: "center",
      cursor: "pointer",
      textAlign: "center",
      fontSize: theme.spacing(1.8),
      color: "#707070",
      padding: "3px",
      width: "100%",
    },
    yourScoreRes: {
      marginRight: "5px",
      marginLeft: "5px",
      borderRadius: "4px",
      border: "1px solid #707070",
      justifyContent: "center",
      cursor: "pointer",
      textAlign: "center",
      fontSize: theme.spacing(1.8),
      color: "#707070",
      padding: "3px",
      width: "100px",
    },
    counter1: {
      textAlign: "left",
      paddingLeft: 6,
    },
    counter2: {
      textAlign: "right",
      paddingRight: 6,
    },
    counterItem: {
      color: "#1f73b7",
    },
  });

class FormItem extends Component {
  constructor(props) {
    super();
    this.state = {
      item: {},
      currencyType: {
        label: "USD",
        value: "USD",
      },
      optionName: "",
      isShowLoader: false,
      fileNames: [],
    };
    this.updateTextValue = this.updateTextValue.bind(this);
  }

  componentDidMount() {
    const { isNew } = this.props;
    const { requirement } = this.props;
    let fileNames = [];

    let options = requirement.options;
    let selectedOptions = [];
    let text_value = requirement?.answer;
    let note = requirement?.note;
    if (requirement.answer_type === 1) {
      note = text_value;
    }

    console.log(text_value, "text_value For File Type");
    if (requirement.selectedOptions && requirement.selectedOptions.length > 0) {
      if (requirement.answer_type === 2) {
        requirement.selectedOptions.forEach(function (op, k) {
          selectedOptions.push({
            value: op?.description,
            label: op?.title,
          });
        });
      } else if (requirement.answer_type === 3) {
        requirement.selectedOptions.forEach(function (op, k) {
          selectedOptions = {
            value: op?.description,
            label: op?.title,
          };
        });
      } else if (requirement.answer_type === 14) {
        selectedOptions = requirement.selectedOptions;
        text_value = requirement.selectedOptions[0].description;
      } else {
        requirement.selectedOptions.forEach(function (op, k) {
          selectedOptions.push(op?.description);
        });
      }
    }

    if (requirement.answer_type === 4) {
      options = questionLibbinaryOptions;
    }

    if (requirement.answer_type === 6) {
      if (Array.isArray(text_value)) {
        fileNames = text_value.map((o) => o.name);
      }
      this.setState({
        fileNames: fileNames,
      });
    }

    var obj = {
      name: requirement?.name,
      type: requirement.answer_type || 1,
      options: options,
      selectedOptions: selectedOptions,
      description: requirement.description,
      itemId: requirement.id + "_item",
      is_multiselect: false,
      is_required: requirement?.is_mandatory,
      isNew: isNew,
      isTakeConditions: false,
      condition: false,
      is_other_option: requirement?.is_other_option,
      is_editable: requirement?.is_editable,
      character_limit: requirement?.character_limit,
      text_value: text_value,
      note: note,
    };
    console.log(obj, requirement, "fileNames");
    var data = obj;
    data.item = obj;
    this.setState(data);
  }

  handleSave = () => {
    const { items, data, item } = this.state;
    if (this.props.isNew) {
      this.props.onSave({ item });
    } else {
      this.props.onUpdate({ items, data, item });
    }
  };

  handleChangeBinaryOptionLabel = (index, optionIndex, title) => {
    const item = this.state.item;
    item.additional_data[optionIndex].title = title;
    this.setState({ item }, () => {
      this.props.onChangeData(false, item);
    });
  };

  handleChangeCharactorLimit = (index, limit) => {
    const item = this.state.item;
    item.character_limit = limit;
    this.setState({ item }, () => {
      this.props.onChangeData(false, item);
    });
  };

  handleChangeType = (e, index) => {
    const item = this.state.item;
    item.type = e?.value || 1;

    item.description = null;
    item.is_multiselect = e && e?.value === 2 ? true : false;

    item.options = e && e?.value === 4 ? questionLibbinaryOptions : [];
    item.selectedOptions = [];

    item.text_value = "";
    if (e?.value === 13) {
      item.text_value = {
        type: {
          label: "USD",
          value: "USD",
        },
        value: "",
      };
    } else if (e?.value === 6) {
      item.text_value = [];
    }

    this.setState(
      {
        item,
      },
      () => {
        if (this.props.isUpdateOnChange) {
          this.props.onChangeData(true, item);
        }
      }
    );
  };

  handleEditOption = (title, index, i) => {
    const item = this.state.item;
    item.additional_data[i].title = title;
    this.setState({ item }, () => {
      this.props.onChangeData(false, item);
    });
  };

  handleAddOption = (index) => {
    const item = this.state.item;
    var additionsDatas = item.additional_data;
    var newOption = {
      title: "",
      description: "",
      sequence: additionsDatas.length,
    };
    additionsDatas.push(newOption);
    item.additional_data = additionsDatas;
    this.setState({ item }, () => {
      this.props.onChangeData(false, item);
    });
  };

  handleRemoveOption = (index, i) => {
    const item = this.state.item;
    item.additional_data.splice(i, 1);
    this.setState({ item }, () => {
      this.props.onChangeData(false, item);
    });
  };

  handleAddOtherOption = (index) => {
    const item = this.state.item;
    item.is_other_option = true;
    this.setState({ item }, () => {
      this.props.onChangeData(false, item);
    });
  };

  handleRemoveOtherOption = (index) => {
    const item = this.state.item;
    item.is_other_option = false;
    this.setState({ item }, () => {
      this.props.onChangeData(false, item);
    });
  };

  // Answer Update Code for All Types
  updateTextValue(value, isUpdate, isChangeFound = false) {
    console.log(value, "Value");
    let item = { ...this.state.item };
    item.text_value = value !== undefined && value !== null && value !== "" ? value : "";
    this.setState(
      {
        item: item,
        formError: "",
      },
      () => {
        this.props.onChangeData(false, item);
      }
    );
  }

  setOptionValues(newOption, state) {
    let { item } = this.state;
    let selectedOptions;
    let newText = "";
    if (item.type === 14) {
      let options = item.options;
      if (newOption && newOption !== "") {
        selectedOptions = options.filter((o) => o.description === newOption)[0];
        console.log(selectedOptions, "selectedOptionsaaaas");
        newText = selectedOptions.description;
      }
    } else {
      selectedOptions = newOption;
    }
    item.selectedOptions = selectedOptions;
    item.text_value = newText;
    this.setState(
      {
        item: item,
      },
      () => {
        this.props.onChangeData(false, item);
      }
    );
  }

  updateNoteValue(value, isUpdate, isChangeFound = false) {
    let item = { ...this.state.item };
    if (item.type === 1) {
      item.text_value = value !== undefined && value !== null && value !== "" ? value : "";
    } else {
      item.note = value !== undefined && value !== null && value !== "" ? value : "";
    }
    this.setState(
      {
        item: item,
        formError: "",
      },
      () => {
        this.props.onChangeData(false, item);
      }
    );
  }

  updateBinaryValue(value, isChangeFound = false) {
    console.log(value, "Valueeee");
    let item = { ...this.state.item };
    let options = item.options;
    let selectedOptions = "";
    if (value && value !== "") {
      selectedOptions = options.filter((o) => o.description === value)[0];
    }
    console.log(selectedOptions, "selectedOptions");
    item.text_value = value;
    item.selectedOptions = selectedOptions;
    this.setState(
      {
        item: item,
      },
      () => {
        this.props.onChangeData(false, item);
      }
    );
  }

  validateEmail(value) {
    if (value !== "") {
      if (Validator.validateEmail(value) === false) {
        this.setState({
          isValidationError: true,
        });
      } else {
        this.setState({
          isValidationError: false,
        });
      }
    }
  }

  validateURL(value) {
    if (value !== "") {
      if (Validator.validateURL(value) === false) {
        this.setState({
          isValidationError: true,
        });
      } else {
        this.setState({
          isValidationError: false,
        });
      }
    }
  }

  addNewOption() {
    let { item, optionName } = this.state;
    if (optionName === "") {
      return;
    }
    let options = item.options;
    let index = options.findIndex((O) => O.title === optionName);
    if (index > -1) {
      return;
    }
    options.push({
      title: optionName,
      description: optionName,
      seq: options.length,
    });
    this.setState(
      {
        item: item,
        optionName: "",
      },
      () => {
        this.props.onChangeData(false, item);
      }
    );
  }

  deleteOption(index) {
    let { item } = this.state;
    let options = item.options;
    let selectedOptions = item.selectedOptions;
    let option = options[index];
    if (item.type === 3 || item.type === 14) {
      if (selectedOptions !== null && selectedOptions !== "" && option.description === selectedOptions.value) {
        selectedOptions = "";
      }
    } else {
      if (selectedOptions !== null && selectedOptions !== "" && selectedOptions.length > 0) {
        let selIndex = selectedOptions.findIndex((o) => o.value === option.description);
        if (selIndex !== null && selIndex !== -1) {
          selectedOptions.splice(selIndex, 1);
        }
      }
    }
    options.splice(index, 1);
    item.selectedOptions = selectedOptions;
    item.options = options;
    this.setState({
      item: item,
    });
  }

  fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ isShowLoader: true });
      const files = event.target.files;
      var formData = new FormData();
      formData.append("folder", `/seller-library/questions/new`);
      formData.append("fileLength", files.length);
      const timestamp = new Date().getTime();
      let nameArr = [];
      for (const key of Object.keys(files)) {
        nameArr.push(files[key].name);
        formData.append(`file${key}`, files[key], `${timestamp}${files[key].name}`);
      }
      nameArr.push(...this.state.fileNames);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/external/multi-add?t=${timestamp}`;

      axios
        .post(url, formData, {
          headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          let resData = response.data?.filepath || {};
          let keyData = Object.keys(resData) || [];
          let data = keyData.map((key, i) => ({
            name: nameArr[i],
            url: resData[key],
          }));
          let { item } = this.state;
          let selectedFiles = item.text_value;
          item.text_value = [...data, ...selectedFiles];
          this.setState(
            {
              item: item,
              fileNames: nameArr,
              isShowLoader: false,
            },
            () => {
              this.props.onChangeData(false, item);
            }
          );
          console.log("File uploaded", "success", 1500);
        })
        .catch((err) => {
          if (err.response) {
            console.warn(err.response.data.error, "error", 3000);
          } else if (err.request) {
            console.warn("Something went wrong. Please try after sometime.", "error", 3000);
          } else {
            console.warn("Something went wrong. Please try after sometime.", "error", 3000);
          }
          this.setState({ isShowLoader: false });
        });
    }
  };

  handleDeleteFile = (index) => {
    let { item } = this.state;
    let newFileNames = [...this.state.fileNames];
    let newSelectedFiles = [...item.text_value];
    newFileNames.splice(index, 1);
    newSelectedFiles.splice(index, 1);
    item.text_value = newSelectedFiles;
    this.setState(
      {
        item: item,
        fileNames: newFileNames,
      },
      () => {
        this.props.onChangeData(false, item);
      }
    );
    this.fileRef.current.value = "";
  };

  render() {
    const { classes } = this.props;

    const { item, fileNames } = this.state;
    const text_value = item.text_value;
    const note = item.note;

    console.log(item, text_value, "ItemDatassssss");
    // console.log(text_value, 'text_value')

    const isUpdateOnChange = false;
    const isRequired = false;

    return (
      <div key={"SectionItem_" + item.ID} className={classes.root_new} id={this.props.id}>
        {true && (
          <div className={classes.editItemBox} key={item.itemId + "_item"}>
            <div className={classes.editItem}>
              <div className={classes.fieldContainer}>
                <Typography variant={"h4"} className={classes.filterTitle1}>
                  Answer Type
                </Typography>
                <Select
                  options={typeOptionsSeller}
                  isDisabled={false}
                  defaultValue={typeOptionsSeller.filter((it) => it.value === item.type)[0]}
                  classNamePrefix="select"
                  placeholder={"Select Type"}
                  onChange={(e) => this.handleChangeType(e, 0)}
                  menuPortalTarget={document.body}
                  menuPlacement={"auto"}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                />
              </div>
              <div className={classes.additionalDataContainer}>
                {[2, 3, 14, 15].includes(item.type) && (
                  <>
                    <div style={{ marginBottom: 10 }}>
                      <Typography variant={"h4"} className={classes.filterTitle1}>
                        Available Options
                      </Typography>
                      {item.options.length > 0 &&
                        item.options.map((o, k) => {
                          return (
                            <Chip
                              label={o.title}
                              style={{ marginRight: 5 }}
                              variant="outlined"
                              onDelete={() => {
                                this.deleteOption(k);
                              }}
                            />
                          );
                        })}
                    </div>
                    <div className={classes.flexWrap}>
                      <input
                        className={classes.inputCriteria}
                        disabled={false}
                        placeholder="Enter Option"
                        onChange={(e) => {
                          this.setState({
                            optionName: e.target?.value,
                          });
                        }}
                        value={this.state.optionName}
                      />
                      <button
                        type="button"
                        disabled={false}
                        className={classes.newUserBtn}
                        onClick={() => {
                          this.addNewOption();
                        }}
                      >
                        + Add
                      </button>
                    </div>

                    {(item.type === 2 || item.type === 3) && (
                      <div className={classes.customTypeFields}>
                        <FormControl className={classes.formSelect} style={{ zIndex: 100 }}>
                          <Select
                            isClearable
                            isMulti={item.type === 2 ? true : false}
                            labelId="singleSelect-select-label"
                            id="singleSelect-select"
                            isDisabled={false}
                            value={item.selectedOptions}
                            onChange={(e) => {
                              this.setOptionValues(e, false);
                            }}
                            fullWidth
                            options={
                              item.options.length > 0
                                ? item.options.map((o) => {
                                    return { label: o.title, value: o.description };
                                  })
                                : []
                            }
                            menuPortalTarget={document.body}
                            // menuPosition={'fixed'}
                            menuPlacement={"auto"}
                            placeholder={"Select a Option"}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                          ></Select>
                        </FormControl>
                      </div>
                    )}

                    {item.type === 14 && (
                      <>
                        <ul className={"answers"}>
                          {item.options.map((op, i) => {
                            return (
                              <>
                                {
                                  <li key={i}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        boxSizing: "border-box",
                                        placeContent: "center flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Radio
                                        value={op.description}
                                        checked={item.text_value === op.description ? true : false}
                                        disabled={false}
                                        inputProps={{
                                          name: "RadioData",
                                          "data-sequence": op.Sequence,
                                        }}
                                        onChange={(e) => {
                                          this.setOptionValues(e.target.value, false);
                                        }}
                                      />
                                      <label htmlFor={op.title}>{op.title}</label>
                                    </div>
                                  </li>
                                }
                              </>
                            );
                          })}
                        </ul>
                      </>
                    )}

                    {item.type === 15 && (
                      <>
                        <ul className={"answers"}>
                          {item.options.map((op, i) => {
                            return (
                              <>
                                <li key={i}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      boxSizing: "border-box",
                                      placeContent: "center flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkbox
                                      id={op.description}
                                      checked={item.selectedOptions.indexOf(op.description) !== -1}
                                      name={op.description}
                                      disabled={false}
                                      color="primary"
                                      onChange={(e) => {
                                        let options = [];
                                        let itemId = op.description;
                                        if (item.selectedOptions.indexOf(itemId) !== -1) {
                                          item.selectedOptions.splice(item.selectedOptions.indexOf(itemId), 1);
                                        } else {
                                          item.selectedOptions.push(itemId);
                                        }
                                        options = _.uniq(item.selectedOptions);
                                        this.setOptionValues(options, false);
                                      }}
                                    />
                                    <label htmlFor={op.description}>{op.title}</label>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  </>
                )}

                {item.type === 4 && (
                  <>
                    <RadioGroup
                      name={`option_binary`}
                      value={String(text_value)}
                      onChange={(e) => {
                        this.updateBinaryValue(e.target.value, false);
                      }}
                    >
                      <ul className={"answers"}>
                        {item.options.map((item, i) => {
                          return (
                            <li key={i}>
                              <FormControlLabel value={item.description} control={<Radio disabled={false} />} label={item.title} />
                            </li>
                          );
                        })}
                      </ul>
                    </RadioGroup>
                  </>
                )}

                {item.type === 5 && (
                  <div className={classes.customTypeFields}>
                    {<span className={classes.subHead}>Rate your response</span>}
                    {
                      <div className={classes.ratingRoot}>
                        <Grid container className={classes.rating}>
                          <Grid item xs={10} sm={10} md={10} lg={10} xl={10} className={classes.slider}>
                            {
                              <Slider
                                // defaultValue={text_value}
                                value={text_value}
                                valueLabelDisplay="auto"
                                step={0.5}
                                marks={false}
                                min={1}
                                max={10}
                                onChange={(event, value) => {
                                  this.updateTextValue(value, isUpdateOnChange, true);
                                }}
                                classes={{
                                  thumb: classes.thumb,
                                  track: classes.track,
                                  rail: classes.rail,
                                }}
                              />
                            }
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={classes.sliderCount}>
                            <Typography className={classes.resListItem}>{text_value === "" ? 0 : text_value}</Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className={classes.counter1}>
                            <Typography className={classes.counterItem}>1</Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className={classes.counter2}>
                            <Typography className={classes.counterItem}>{10}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                    }
                  </div>
                )}

                {item.type === 6 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "16px",
                      }}
                    >
                      {this.state.isShowLoader ? (
                        <CircularProgress style={{ height: 30, width: 30, marginBottom: 10 }} />
                      ) : (
                        <>
                          {text_value &&
                            text_value.map((file, key) => (
                              <div>
                                <FileAncher fileName={fileNames[key]} fileURL={file.url} />
                                &nbsp;&nbsp;&nbsp;
                                {<Image src={deleteIcon} style={{ cursor: "pointer" }} onClick={() => this.handleDeleteFile(key)} />}
                              </div>
                            ))}
                        </>
                      )}
                      {<input ref={this.fileRef} id="uploadRequirementResponse" onChange={this.fileSelectHandler} type="file" multiple />}
                    </div>
                  </>
                )}

                {item.type === 7 && (
                  <>
                    <TextField
                      id="outlined-full-width"
                      label={"Enter Response"}
                      required={false}
                      fullWidth
                      margin="normal"
                      size="small"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={text_value}
                      onChange={(e) => {
                        this.updateTextValue(e.target.value, false);
                      }}
                    />
                  </>
                )}

                {item.type === 8 && (
                  <>
                    <TextField
                      id="outlined-full-width"
                      required={isRequired ? true : false}
                      label={"Email"}
                      // style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={text_value}
                      onChange={(e) => {
                        this.validateEmail(e.target.value);
                        this.updateTextValue(e.target.value, isUpdateOnChange, true);
                      }}
                      error={this.state.isValidationError}
                    />
                    {this.state.isValidationError && <p className={classes.errMessage}>Please enter valid email i.e. abc@mail.com</p>}
                  </>
                )}

                {item.type === 9 && (
                  <>
                    <div className={classes.customTypeFields}>
                      <PhoneInput
                        country={"us"}
                        value={text_value}
                        onChange={(value) => {
                          this.updateTextValue(value, false, true);
                        }}
                        required
                        inputProps={{
                          name: text_value,
                          autoFocus: false,
                          id: "outlined-full-width",
                          required: false,
                          label: "",
                          style: { width: "100%" },
                          fullWidth: true,
                          margin: "normal",
                          variant: "outlined",
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                      />
                    </div>
                  </>
                )}

                {item.type === 10 && (
                  <>
                    <div className={classes.customTypeFields}>
                      <TextField
                        id="outlined-full-width"
                        required={isRequired ? true : false}
                        label={"URL"}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={text_value}
                        onChange={(e) => {
                          this.validateURL(e.target.value);
                          this.updateTextValue(e.target.value, isUpdateOnChange, true);
                        }}
                        error={this.state.isValidationError}
                      />
                      {this.state.isValidationError && <p className={classes.errMessage}>Please enter valid URL i.e. https://domain.xyz</p>}
                    </div>
                  </>
                )}

                {item.type === 11 && (
                  <>
                    <DatePicker
                      className={classes.datePicker}
                      selected={text_value ? new Date(text_value) : new Date()}
                      onChange={(date) => {
                        this.updateTextValue(date, isUpdateOnChange, true);

                        // this.updateDate(date, true);
                      }}
                      dateFormat="MMM do yyyy"
                      portalId="evMetaDialog"
                      minDate={null}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <OutlinedInput
                          readOnlyInput={true}
                          startAdornment={
                            <InputAdornment>
                              <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                            </InputAdornment>
                          }
                        />
                      }
                    />
                  </>
                )}

                {item.type === 12 && (
                  <>
                    <TextField
                      id="outlined-full-width"
                      required={isRequired ? true : false}
                      label={"Enter Number Value"}
                      type={"number"}
                      // style={{ margin: 8 }}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                      value={text_value}
                      onChange={(e) => {
                        this.updateTextValue(e.target.value, isUpdateOnChange, true);
                      }}
                    />
                  </>
                )}

                {item.type === 13 && (
                  <div className={classes.currencyTypeFields} style={{ display: "flex" }}>
                    <div style={{ width: 100, marginRight: 5, zIndex: 1 }}>
                      <Select
                        isMulti={false}
                        labelId="singleSelect-select-label"
                        id="singleSelect-select"
                        value={text_value?.type || { label: "USD", value: "USD" }}
                        onChange={(e) => {
                          let newVal = {
                            type: e,
                            value: text_value.value,
                          };
                          this.updateTextValue(newVal, isUpdateOnChange, true);
                        }}
                        options={currencyOptions}
                        placeholder={"Select a Option"}
                        styles={currencyStyles}
                      ></Select>
                    </div>

                    <CurrencyTextField
                      label={"Enter Currency"}
                      variant="outlined"
                      value={text_value?.value}
                      currencySymbol={currencyCodeMap[text_value?.value]}
                      //minimumValue="0"
                      style={{ textAlign: "left" }}
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      required={isRequired ? true : false}
                      onChange={(e, v) => {
                        let newVal = {
                          type: text_value?.type,
                          value: v,
                        };
                        this.updateTextValue(newVal, isUpdateOnChange, true);
                      }}
                    />
                  </div>
                )}

                {item.type !== 7 && (
                  <div className={classes.yourQuestion}>
                    <SlateInputField
                      label="Enter Response"
                      isSmallContainer={true}
                      folderPath={`/seller-library/questions/new`}
                      as={SlateInputField}
                      onChangeEvent={(value) => {
                        this.updateNoteValue(value, isUpdateOnChange, true);
                      }}
                      initValue={note}
                      autoFocus={false}
                      textContainerStyle={{ color: "#707070" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default React.memo(withStyles(styles)(FormItem));
