import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { fetchEvaluationMetaData, updateEvaluationMetaData } from "../../../../../../../redux/evaluation/metadata/action"
import { showEvaluationCriteriaAddDialog, importRequirement } from "../../../../../../../redux/evaluation/criteria/action"
import { showEvaluationSelectDialog } from "../../../../../../../redux/templates/evaluation/action"
import { showSaveEvaluationAsTemplateDialog } from "../../../../../../../redux/templates/evaluation/org/action"
import { showSnackBar } from "../../../../../../../redux/snackbar/action"
import { withRouter } from 'react-router-dom';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import EvaluationBar from "../Common/EvaluationBar";
import ProductCart from '../Common/ProductCart';
import BottomAction from '../Common/BottomAction';
import SqureButton from 'Components/Common/SqureButton';
import CriteriaRequirement from "../Common/CriteriaRequirement";
import ChooseTemplateModal from "../../../../Settings/Templates/Common/ChooseTemplateModal";

import ArrowForward from "assets/images/arrow_forward_white.svg"
import SaveIcon from "assets/images/save_icon.svg"

import Image from 'Components/Common/image.jsx'
import TemplateGrayImg from 'assets/images/template-gray.svg';
// components
import SearchUserPoper from "Components/Common/UserGroup/SearchUserPoper";

// redux
import { fetchMasterTemplate } from "redux/templates/evaluation/master/action";
import { showEvaluationMetaDialog } from "redux/evaluation/metadata/action";
import { toggleEvaluateView, toggleEvaluateNeverReviewed } from "redux/applications/action";
import classnames from "classnames";
import moment from 'moment';
import { showEvaluationLinkDialog } from "redux/evaluation/link/action";
import { Link as LinkIcon } from '@material-ui/icons';
import { addWorkflowComponentUserResponse } from "redux/workflow/action";
import EvaluationGuideForm from "../WorkFlow/Components/EvaluationGuideForm";
import { showWorkflowEvaluationGuideFormDialog, hideWorkflowEvaluationGuideFormDialog } from "redux/workflow/action";

import { fetchEvaluationSelectedFeatures } from "redux/evaluation/metadata/action";
import { getNextComponent } from "util/Common";

const connectedProps = (state) => ({
    user: state.authUser.user,
    evaluation: state.evaluationMetaData.evaluation,
    fetchProgress: state.evaluationMetaData.fetchProgress,
    updateProgress: state.evaluationMetaData.updateProgress,
    updateError: state.evaluationMetaData.errorUpdate,
    templateModalshow: state.templateEvaluation.show,
    templateType: state.templateEvaluation.templateType,
    templateId: state.templateEvaluation.templateId,
    importCriteriaIds: state.templateEvaluation.criteriaIds,
    defaultCriteriaIds: state.masterTemplateEvaluation.template,
    criterias: state.evaludationCriteria.criterias,
    criteriasFetchProgress: state.evaludationCriteria.fetchProgress,
    saveProgress: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateProg,
    saveError: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateError,
    template: state.masterTemplateEvaluation.template,
    evaluateView: state.applications.evaluateView,
    evaluateViewId: state.applications.evaluateViewId,
    evaluateNeverReview: state.applications.evaluateNeverReview,
    evaluateNeverReviewId: state.applications.evaluateNeverReviewId,
    collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
    collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
    workflowComponentsForms: state.workflow.workflowComponentsForms,
    addComponentUserResponseProgress: state.workflow.addComponentUserResponseProgress,
    workflowComponentDetail: state.workflow.workflowComponentDetail,
    workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
    isShowEvaluationGuideFormDialog: state.workflow.isShowEvaluationGuideFormDialog,
    evaluationSelectedFeaturesError: state.evaluationMetaData.evaluationSelectedFeaturesError,
    evaluationSelectedFeatures: state.evaluationMetaData.evaluationSelectedFeatures,
    evaluationSelectedFeaturesProgress: state.evaluationMetaData.evaluationSelectedFeaturesProgress,
    userTimeline: state.workflow.userTimeline
});

const connectionActions = {
    fetchEvaluationMetaData: fetchEvaluationMetaData,
    updateEvaluationMetaData: updateEvaluationMetaData,
    showSnackBar: showSnackBar,
    showEvaluationCriteriaAddDialog: showEvaluationCriteriaAddDialog,
    showEvaluationSelectDialog: showEvaluationSelectDialog,
    importRequirement: importRequirement,
    showSaveEvaluationAsTemplateDialog: showSaveEvaluationAsTemplateDialog,
    fetchMasterTemplate: fetchMasterTemplate,
    showEvaluationMetaDialog: showEvaluationMetaDialog,
    toggleEvaluateView: toggleEvaluateView,
    toggleEvaluateNeverReviewed: toggleEvaluateNeverReviewed,
    showEvaluationLinkDialog: showEvaluationLinkDialog,
    addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
    showWorkflowEvaluationGuideFormDialog: showWorkflowEvaluationGuideFormDialog,
    hideWorkflowEvaluationGuideFormDialog: hideWorkflowEvaluationGuideFormDialog,
    fetchEvaluationSelectedFeatures: fetchEvaluationSelectedFeatures,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    criteriaTitle: {
        fontSize: theme.spacing(2),
        color: '#282D30',
        fontWeight: 600
    },
    titleTxt: {
        fontSize: theme.spacing(2),
        color: '#282D30',
        fontWeight: 600
    },
    headSection: {
        justifyContent: 'right',
        // justifyContent: 'space-between',
        marginBottom: 16.5,
    },
    headerBtnSection: {
        '& button': {
            marginLeft: 16.5
        },
    },
    btns: {
        "& button": {
            boxShadow: '4px 0px 4px #2B2D3703',
            border: '1px solid #20253a33',
            background: 'transparent',
            borderRadius: '8px',
            opacity: 1,
            color: '#707070',
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'rgba(75, 134, 255, 0.04)'
            }
        }
    },
    bottomNav: {
        padding: theme.spacing(1.6, 0),
        justifyContent: 'space-between'
    },
    contentContainer: {
        marginBottom: '100px'
    },
    loader: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        minHeight: 200
    },
    saveButton: {
        "& img": {
            paddingRight: 10,
        },
        "& button": {
            boxShadow: '4px 0px 4px #2B2D3703',
            border: '1px solid #4175DF',
            borderRadius: 4,
            color: '#4175DF'
        }
    },
    saveAndContinue: {
        marginRight: 30,
        display: "inline-block"
    },
    subTitle: {
        marginTop: 0
    },
    linkEvaluation: {
        width: "100%",
        marginTop: theme.spacing(3)

    },
    content: {
        padding: theme.spacing(4, 10),
        background: "#F7F7F7",
        marginTop: 0,
        minHeight: "100vh"
    },
});

class Setup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requirementFetched: false,
            forAdmin: false,
            ev_id: null,
            weighting_mechanism: 'Textual',
            featureRecommendation: false,
            configurationCheckDone: false,
            featureRecommendationFetchDone: false,
            callOnce: true,
            showLoader: true,
            isNotification: false,
            saveType: 'NEXT',
            nextClickLoader : false,
            enableScoring:false
        }
        this.updateStatus = this.updateStatus.bind(this);
    }

    componentDidMount() {
        this.checkPage();
        if (this.props.isWorkFlow) {
            this.checkWorkflowConfiguration();
        }
        const ev_id = this.props.isWorkFlow ? this.props.evaluation_id : this.props.match.params.evaluation_id;
        this.props.fetchEvaluationMetaData(ev_id)
        // get default criterias
        this.props.fetchMasterTemplate(1, ev_id)
        this.setState({ ev_id })
    }

    checkPage() {
        var isNotification = this.getParameterByName("isNotification");
        if (isNotification !== undefined && isNotification !== null) {
            this.setState({
                isNotification: true
            })
        }
    }

    componentWillUnmount() {
        try {
            this.props.toggleEvaluateView(false, null);
            this.props.toggleEvaluateNeverReviewed(false, null);
            document.body.classList.remove('evaluate-view');
        } catch (e) { }
    }

    getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }


    setReview = () => {
        try {
            if (this.props.evaluation?.Status === 4 && this.props.evaluation?.Type === 2 && moment(new Date()).diff(moment(this.props.evaluation?.EvaluationUpdatedAt), 'days') > 30) {
                document.body.classList.add('evaluate-view');
                this.props.toggleEvaluateView(true, this.props.evaluation?.Products[0]?.ID);
            } else if ((((this.props.evaluation?.Status <= 3 || this.props.evaluation?.Status === 4) && moment(new Date()).diff(moment(this.props.evaluation?.EvaluationUpdatedAt), 'days') <= 30) && this.props.evaluation?.Type === 2)) {
                this.props.toggleEvaluateNeverReviewed(true, this.props.evaluation?.Products[0]?.ID);
            }
        } catch (e) { }
    }

    componentDidUpdate(prevProps) {
        if (this.props.saveProgress === false && prevProps.saveProgress === true) {
            if (this.props.saveError == null) {
                this.props.fetchEvaluationMetaData(this.state.ev_id)
            }
        }
        if (this.props.templateModalshow === false && prevProps.templateModalshow) {
            if (this.props.templateId !== null) {
                this.props.importRequirement(this.state.ev_id, this.props.importCriteriaIds, this.props.templateType)
            }
        }

        if (this.props.fetchProgress === false && prevProps.fetchProgress === true) {
            if (!this.props.errorFetch) {
                this.setReview();
                if (this.props.isWorkFlow) {
                    // this.props.fetchEvaluationSelectedFeatures(this.state.ev_id);
                    this.setState({
                        featureRecommendationFetchDone: true
                    }, () => {
                        this.checkFeatureRecommendationConfig()
                    })
                } else {
                    this.setState({
                        showLoader: false
                    })
                }
            }
        }

        if (this.props.updateProgress === false && prevProps.updateProgress === true) {
            if (this.props.updateError == null) {
                if (this.props.isWorkFlow) {
                    this.nextPage();
                } else {
                    this.setReview()
                    if (this.props.evaluation.EvaluationUserStatus === 3) {
                        this.props.history.push("/app/evaluate/" + this.props.evaluation.ID);
                    }
                }
                this.props.showSnackBar("Updated Successfully.", "success", 3000)
            } else {
                this.props.showSnackBar("Failed to update evaluation status.", "error", 3000)
            }
        }

        if (this.props.addComponentUserResponseProgress === false && prevProps.addComponentUserResponseProgress === true) {
            if (this.state.isUpdateStatus) {
                this.props.updateEvaluationMetaData(this.state.ev_id, {
                    Status: 3
                });
            }
        }

        if (this.props.workflowComponentsFormsInProg === false && prevProps.workflowComponentsFormsInProg === true) {
            if (this.props.isWorkFlow) {
                this.checkWorkflowConfiguration();
            }
        }

        if (this.props.evaluationSelectedFeaturesProgress === false && prevProps.evaluationSelectedFeaturesProgress === true) {
            this.setState({
                featureRecommendationFetchDone: true
            }, () => {
                this.checkFeatureRecommendationConfig()
            })
        }

        if (this.props.isShowEvaluationGuideFormDialog === false && prevProps.isShowEvaluationGuideFormDialog === true) {
            this.setState({
                showLoader: false
            })
        }

    }

    checkWorkflowConfiguration() {
        const { workflowComponentsForms, workflowComponentDetail } = this.props;
        if (workflowComponentsForms === null || workflowComponentDetail === null) {
            return;
        }

        var configurations = workflowComponentDetail.OrgWorkflowComponentConfigurations;
        if (configurations === undefined || configurations === null || configurations.length <= 0) {
            this.setState({
                showLoader: false
            })
            return;
        } else {
            var featureRecommendationFormId = workflowComponentsForms.filter(o => o.Name === 'feature_recommendation').map(o => o.ID)[0];
            var frValue = configurations.filter(o => o.FormID === featureRecommendationFormId).map(o => o.Value)[0];
            frValue = frValue === 'true' ? true : false;
            this.setState({
                featureRecommendation: frValue,
            })

            var weightMachFormId = workflowComponentsForms.filter(o => o.Name === "weighting_mechanism").map(o => o.ID)[0];
            var weighting_mechanism_val = configurations.filter(o => o.FormID === weightMachFormId).map(o => o.Value)[0];
            if (weighting_mechanism_val !== undefined && weighting_mechanism_val !== null && weighting_mechanism_val !== '') {
                try {
                    this.setState({
                        weighting_mechanism: JSON.parse(weighting_mechanism_val)
                    })
                } catch (e) {}
                
            }

            var enableScoringFormId = workflowComponentsForms.filter(o => o.Name === "enable_scoring").map(o => o.ID)[0];
            var enableScoringValue = configurations.filter(o => o.FormID === enableScoringFormId).map(o => o.Value)[0];
            if (enableScoringValue !== undefined && enableScoringValue !== null && enableScoringValue !== '') {
                enableScoringValue = enableScoringValue === 'true' ? true : false;
                this.setState({
                    enableScoring: enableScoringValue
                })
                // console.log(enableScoringValue,'enableScoring')
            }

            

            this.setState({
                configurationCheckDone: true,
                callOnce: true
            }, () => {
                this.checkFeatureRecommendationConfig()
            })
        }
    }


    checkFeatureRecommendationConfig() {
        if (this.state.configurationCheckDone === true && this.state.featureRecommendationFetchDone === true && this.state.callOnce) {
            if (this.state.featureRecommendation && this.props.evaluation.SubcategoryID === null) {
                this.props.showWorkflowEvaluationGuideFormDialog();
                this.setState({
                    callOnce: false
                })
            } else {
                this.props.hideWorkflowEvaluationGuideFormDialog();
                this.setState({
                    callOnce: false,
                    showLoader: false
                })
            }
        }
    }

    updateStatus() {
        if (this.props.isWorkFlow) {
            this.setState({
                isUpdateStatus: true,
                nextClickLoader : true
            }, () => {
                var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);
                if (nextComponent !== null) {
                    let redirectPath = `/app/evaluate/${nextComponent.EvaluationId}/workflow/${nextComponent.WorkflowId}/component/${nextComponent.ComponentId}`;
                    this.props.history.push(redirectPath);
                    return;
                } else {
                    this.nextPage()
                }
            })
        } else {
            this.props.updateEvaluationMetaData(this.props.match.params.evaluation_id, {
                Status: 3
            });
        }
    }

    nextPage = () => {
        if (this.props.isWorkFlow) {

            var responseDatas = [];
            this.props.workflowComponentsForms.forEach((data) => {
                if (data.IsConfiguration === false) {
                    var obj = {
                        "form_id": data.ID
                    }
                    var value = '';
                    // if (data.Name === 'evaluation.name') {
                    //     value = this.state.name;
                    // } 
                    obj['value'] = value;
                    responseDatas.push(obj);
                }
            });

            this.props.addWorkflowComponentUserResponse(this.props.match.params.evaluation_id, this.props.workflowComponentId, {
                "data": responseDatas,
                "forward": this.state.saveType === 'NEXT' ? true : false
            });
        } else {
            let redirectPath = `/app/evaluate/${this.props.match.params.evaluation_id}`;
            this.props.history.push(redirectPath);
        }
    }

    proceedToEvaluation = () => {
        if (this.props.evaluation.WorkflowID !== null && this.props.evaluation.WorkflowID !== '') {
            let redirectPath = `/app/evaluate/${this.props.evaluation?.ID}/workflow/interim`;
            this.props.history.push(redirectPath);
        } else {
            let redirectPath = '/app/evaluate/' + this.props.evaluation?.ID + '/setup';
            this.props.history.push(redirectPath);
            window.location.reload();
        }
    }

    render() {
        const classes = this.props.classes;
        const { adminForward } = this.props;

        var nextComponent = getNextComponent(this.props.userTimeline, this.props.workflowComponentId);

        return <div>
            {!this.state.isNotification && this.props.isWorkFlow && <EvaluationBar
                isShowCollabrations={true}
                isShowEvalDetails={true}
                isWorkFlow={true}
            />}

            {!this.state.isNotification && !this.props.isWorkFlow && <EvaluationBar
                isWorkFlow={false}
            />}

            {this.state.showLoader === false && <Grid container className={classnames(classes.contentContainer, classes.content)}>
                <Grid item xs={8}>
                    <Grid container alignItems="center" className={classes.headSection}>
                        {false && <Typography
                            variant={"h6"}
                            className={classes.titleTxt}
                        >
                            {this.props?.evaluateView || this.props.evaluateNeverReview ? 'Review' : 'Evaluation'} Criteria
                        </Typography>}

                        <div className={classnames(classes.btns, classes.headerBtnSection)}>
                            <SqureButton
                                ariant="outlined"
                                startIcon={<Image src={TemplateGrayImg} alt="template" />}
                                onClick={() => {
                                    this.props.showEvaluationSelectDialog(this.state.ev_id)
                                }}
                            >
                                Templates
                            </SqureButton>
                            <SqureButton
                                ariant="outlined"
                                onClick={() => {
                                    this.props.showEvaluationCriteriaAddDialog(this.state.ev_id)
                                }}
                            >
                                + New section
                            </SqureButton>
                        </div>
                    </Grid>
                    {<CriteriaRequirement 
                    evaluationId={this.state.ev_id} 
                    weighting_mechanism={this.state.weighting_mechanism}
                    enableScoring={this.state.enableScoring}
                    />}
                    {this.props.criteriasFetchProgress && <div style={{textAlign:'center'}}>
                        <p>Loading...</p>
                    </div>}
                </Grid>
                <Grid item xs={4} style={{ 'paddingLeft': '20px' }}>
                    {<ProductCart
                        isWorkFlow={this.props.isWorkFlow}
                    />}
                    {false && <div className={classnames(classes.linkEvaluation)}>
                        <SqureButton fullWidth variant={"contained"} startIcon={
                            <LinkIcon />
                        } onClick={() => {
                            this.props.showEvaluationLinkDialog()
                        }}>Link Evaluation</SqureButton>
                    </div>}
                </Grid>
            </Grid>}
            <BottomAction>
                {!this.state.isNotification && <Grid container className={classnames(classes.bottomNav)}>
                    <Grid item xs={3}>
                        <div className={classnames(classes.saveButton, 'evaluate-save-template')}><SqureButton variant={"outlined"} onClick={() => {
                            this.props.showSaveEvaluationAsTemplateDialog()
                        }}><Image alt="save" src={SaveIcon} />Save Template</SqureButton></div>
                    </Grid>
                    <Grid item xs={5} style={{ "textAlign": "right" }}>
                        {this.props.updateProgress && <CircularProgress />}
                        {!this.props.updateProgress && <div>
                            {false && <div className={classnames(classes.saveAndContinue, 'evaluate-save-continue')}>
                                <SqureButton variant={"outlined"} onClick={() => {
                                    this.props.history.push("/app/evaluate")
                                }} >Save as Draft</SqureButton>
                            </div>}

                            {<span className={'evaluate-proceed-btn'}>
                                &nbsp;&nbsp;&nbsp;
                                <SqureButton
                                    variant={"contained"}
                                    disabled={((!adminForward || (adminForward && this.props.isCreatorOrAdmin)) || nextComponent !== null ) ? false : true}
                                    endIcon={<Image src={ArrowForward} alt="arrow forward" />}
                                    onClick={this.updateStatus}>
                                    {this.state.nextClickLoader ?  <CircularProgress style={{width:27,height:27}} /> : 'Next'}
                                </SqureButton>
                            </span>} 

                            {false && <span className={'evaluate-next'}>
                                &nbsp;&nbsp;&nbsp;
                                <SqureButton variant={"contained"}
                                    endIcon={<Image src={ArrowForward} alt="arrow forward" />}
                                    onClick={this.nextPage}>Next</SqureButton>
                            </span>}
                        </div>}
                    </Grid>
                </Grid>}

                {this.state.isNotification && <Grid container className={classnames(classes.bottomNav)}>
                    <Grid item xs={12} style={{ "textAlign": "right" }}>
                        <SqureButton variant={"contained"} endIcon={
                            <Image src={ArrowForward} alt="arrow forward" />
                        } onClick={this.proceedToEvaluation}>Proceed to Evaluation</SqureButton>
                    </Grid>
                </Grid>}

            </BottomAction>
            <ChooseTemplateModal />
            {
                this.props.collaboratorDialogOpen &&
                <SearchUserPoper {...this.props.collaboratorDialogData} />
            }

            {this.props.isShowEvaluationGuideFormDialog &&
                <EvaluationGuideForm />
            }
        </div>
    }
}

Setup.defaultProps = {
    isWorkFlow: false,
    adminForward: false,
    isCreatorOrAdmin: false
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Setup));