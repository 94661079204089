import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Typography, Table, TableBody, TableRow, TableHead, TableCell, CircularProgress, Divider, Grid, IconButton, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { withRouter } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
// icons
import LinkIcon from '@material-ui/icons/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SendIcon from '@material-ui/icons/Send';
import LinearProgress from '@material-ui/core/LinearProgress';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import classnames from "classnames";
import { CommonFn } from 'services/commonFn';
//assets
import Image from 'Components/Common/image.jsx'

// components
import SqureButton from 'Components/Common/SqureButton';
import AppBar from "Components/Application/AppBar";
import editIcon from "assets/images/edit_icon.svg";

// redux
import { hideEvaluationAsksellerSelectDialog } from "redux/templates/evaluation/action";
import { askSellerMultiRequirement, reSendAskSellerMultiRequirement } from "redux/templates/evaluation/action";
import { showSnackBar } from "redux/snackbar/action";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import { showSellerFinalizeReqDialog, fetchEvaluationMeta, fetchSellerActivity } from "redux/seller/action";
import { setSelectedRequirement, tooglePreviousInteractionDialog, setResponseTab } from "redux/evaluation/criteria/action";
import FinalizeRequiremntViewDialog from "../../../../../Seller/Components/Common/FinalizeRequiremntViewDialog";
import { findRequirementPreviousInteraction } from 'util/index'

const connectedProps = (state) => ({
    user: state.authUser.user,
    isOpenPreviousInteractionDialog: state.evaludationCriteria.showPreviousInteractionDialog,
    isOpen: state.templateEvaluation.dialogEvaluationAskseller,
    criterias: state.evaludationCriteria.criterias,
    evaluationId: state.templateEvaluation.multiSelectAskEvaluationId,
    product: state.templateEvaluation.multiSelectAskProduct,
    submitProgress: state.templateEvaluation.askSellerMultiProgress,
    submitError: state.templateEvaluation.askSellerMultiError,
    sellerEvaluationMeta: state.seller.evaluation,
    metaFetchProgress: state.seller.metaFetchProgress,
    sellerActivity: state.seller.sellerActivity,
    sellerActivityProg: state.seller.sellerActivityProg,
    selectedRequirementId: state.evaludationCriteria.selectedRequirementId,
    previousInteractions: state.evaludationCriteria.previousInteractions,
    reSendAskSellerWithSellerResponseProgress: state.templateEvaluation.reSendAskSellerWithSellerResponseProgress,
});

const connectionActions = {
    hideDialog: hideEvaluationAsksellerSelectDialog,
    askSellerMultiRequirement: askSellerMultiRequirement,
    showSellerFinalizeReqDialog: showSellerFinalizeReqDialog,
    fetchEvaluationMeta: fetchEvaluationMeta,
    showSnackBar: showSnackBar,
    setSelectedRequirement: setSelectedRequirement,
    tooglePreviousInteractionDialog: tooglePreviousInteractionDialog,
    setResponseTab: setResponseTab,
    reSendAskSellerMultiRequirement: reSendAskSellerMultiRequirement,
    fetchSellerActivity: fetchSellerActivity
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important",
        backgroundColor: "#F7F7F7",
        maxHeight: "100%",
        height: "100%",
        position: "relative",
        overflow: "auto"
    },
    title: {
        color: "#282D30",
        fontSize: theme.spacing(2.5),
        marginBottom: 0
    },
    tabs: {
        listStyle: "none",
        width: "300px",
        display: "flex",
        margin: 0,
        padding: 0,
        borderBottom: "solid 1px #e0e0e0"
    },
    tab: {

    },
    linkIcon: {
        padding: theme.spacing(.5, 1),
        backgroundColor: '#528bff',
        display: 'flex',
        borderRadius: '50%',
        widht: 36,
        height: 36,
        justifyContent: 'center',
        alignItems: 'center'
    },
    tabBtn: {
        padding: theme.spacing(2),
        borderRadius: 0
    },
    activeBtn: {
        color: "#4A87F8",
        borderBottom: "solid 2px #4A87F8",
    },
    actions: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(2),
        background: "#fff",
        textAlign: "right"
    },
    templates: {
        padding: theme.spacing(2, 0),
        marginBottom: 35
    },
    templateCard: {
        border: "2px solid #EEEEEE",
        borderRadius: '10px',
        background: "#fff",
        padding: theme.spacing(2),
        cursor: "pointer"
    },
    activeTemplate: {
        border: "2px solid #4A87F8",
    },
    templateName: {
        fontSize: theme.spacing(2),
        fontWeight: 400
    },
    iconContainer: {
        marginRight: theme.spacing(3),
        cursor: "pointer",
        display: "flex",
        marginBottom: theme.spacing(2)
    },
    image: {
        width: theme.spacing(3.5),
    },
    modalappBar: {
        display: "flex"
    },
    criteriaRequirement: {
        width: "90%",
        margin: '10px auto',
        paddingBottom: 50
    },
    container: {
        margin: '10px 0 50px'
    },
    productDetail: {
        marginTop: '25px'
    },
    UL: {
        width: '100%',
        //   padding: '0px',
        //   position: 'relative',
        '& li': {
            listStyle: 'none',
            marginBottom: '30px',
            borderBottom: ' 1px solid #aeaeae',
            paddingBottom: '30px',
            '& p': {
                margin: '0px'
            }
        }
    },
    multiSelect: {
        position: 'absolute',
        top: '-8px'
    },
    criteriaAllSelect: {
        marginRight: 10,
        padding: 0
    },
    AccordionHeader: {
        '& [class*=MuiAccordionSummary-content-]': {
            padding: 12
        },
        '& [class*=MuiBadge-badge-]': {
            right: '-8px'
        }
    },
    productName: {
        fontSize: theme.spacing(2.5),
        // marginBottom:theme.spacing(4),
        textAlign: "center",
        fontWeight: 500
    },
    productLogo: {
        height: '30px',
        width: '80px',
        objectFit: 'contain',
        marginRight: '10px'
    },
    isExisting: {
        background: '#E6FFF3',
        borderRadius: '5px',
        textAlign: 'left',
        fontSize: '11px',
        color: '#2EB77D',
        padding: '3px 10px',
        marginLeft: '10px'
    },
    copiedText: {
        textAlign: 'left',
        fontSize: '14px',
        color: '#2EB77D',
    },
    copyText: {
        fontWeight: 400,
        fontSize: theme.spacing(2)
    },
    viewActivity: {
        fontWeight: 400,
        fontSize: theme.spacing(2)
    },
    cartItem: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        padding: 12,
        border: '1px solid #EEEEEE',
        borderRadius: 10,
        justifyContent: 'space-between'
    },
    productNameContainer: {
        flex: 0.99,
        position: 'relative'
    },
    table: {
        borderCollapse: 'separate !important',
        width: '100%',
        borderSpacing: 0,
        borderRadius: '4px',
        border: '1px solid #DEDEDE',
    },
    productImage: {
        width: '100%',
        height: 'auto',
        marginRight: theme.spacing(2)
    },
    productImageContainer: {
        width: 67,
        height: 57,
        padding: 8,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 10px 5px #0000001A',
        borderRadius: 10
    },
    productNameTitle: {
        fontSize: theme.spacing(2),
    },
    productPerCom: {
        display: 'flex'
    },
    totalAnswered: {
        flex: 1,
        textAlign: 'right',
        margin: theme.spacing(1, 0),
        fontSize: theme.spacing(1.6)
    },
    progTextComp: {
        margin: theme.spacing(1, 0),
        fontSize: theme.spacing(1.6)
    },
    progressBar: {
        cursor: 'pointer'
    },
    refreshIcon: {
        cursor: 'pointer',
        color: '#999999',
        transitionDuration: '5s',
        transitionProperty: 'transform',
        zIndex: 1000,
        marginTop: 5,
    },
    underprogress: {
        transform: 'rotate(360deg)',
        '-webkit-transform': 'rotate(360deg) infinite linear',
    },
    viewAllResponse: {
        cursor: 'pointer',
        textDecoration: 'underline',
        letterSpacing: '0px',
        color: '#398AF5',
        fontSize: theme.spacing(2),
    }
});

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#2EB77D',
    },

}))(LinearProgress);

class ChooseAsksellerSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            copiedLink: false,
            pageSize: 1000,
            sellerActivityDialog: false,
            selectedIds: [],
            requirementIds: [],
            alreadySelectedReqIds: [],
            criteriaReqMap: {},
            criteriaShowAllMap: {},
            alreadySelectedCriteriaMap: {},
            criteriaWiseTotalSelected: {},
            totalRequirementsCount: null,
            isSendDisable: false,
            isRuleUsedForEvaluation: false,
            mainRequirementId: false,
            isOpenShareDialog: false,
            checkAllCriterias: false
        }
        this.toggleRequirement = this.toggleRequirement.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
        this.selectAllRequirtments = this.selectAllRequirtments.bind(this);
        this.showFinalizeDialog = this.showFinalizeDialog.bind(this);
    }

    showFinalizeDialog(type) {
        this.props.showSellerFinalizeReqDialog(type, false);
    }

    componentDidMount() {
        this.initializeData()
    }

    componentDidUpdate(preProps) {
        if (this.props.isOpen === true && preProps.isOpen === false) {
            this.initializeData()
        }

        if (this.props.reSendAskSellerWithSellerResponseProgress === false && preProps.reSendAskSellerWithSellerResponseProgress === true) {
            this.props.showSnackBar("Notification sent to seller", "success", 3000)
        }

        if (this.props.submitProgress === false && preProps.submitProgress === true) {
            if (this.props.submitError !== null) {
                this.props.showSnackBar("Request failed to sent to seller", "error", 3000)
            } else {
                this.props.fetchEvaluationMeta(this.props.product?.VendorUUID, this.props.user.Organization.ID);
                this.props.showSnackBar("Request sent to seller", "success", 3000)
                if (this.props.isOpenPreviousInteractionDialog) {
                    this.props.tooglePreviousInteractionDialog()
                } else {
                    this.handleCloseDialog();
                }
            }

        }
    }

    initializeData() {
        var criteriaReqMap = {};
        var totalReqLegth = 0;
        var isRuleUsed = false;
        if (this.props.criterias !== null && this.props.criterias !== undefined) {
            this.props.criterias.forEach(function (item) {
                if (item?.rules.length > 0) {
                    isRuleUsed = true;
                }
                if (item.requirements) {
                    item.requirements.forEach(function (req) {
                        if (req?.rules.length > 0) {
                            isRuleUsed = true;
                        }
                    })
                }
                if (criteriaReqMap[item.id] === undefined) {
                    criteriaReqMap[item.id] = [];
                    var requirements = item.requirements.map(o => o.id);
                    totalReqLegth += requirements.length;
                    criteriaReqMap[item.id] = requirements;
                }
            })
        }

        this.setState({
            criteriaReqMap: criteriaReqMap,
            criteriaShowAllMap: {},
            criteriaWiseTotalSelected: {},
            totalRequirementsCount: totalReqLegth,
            isRuleUsedForEvaluation: isRuleUsed,
            mainRequirementId: this.props.selectedRequirementId,
        })

        var selectAllIds = [];
        if (isRuleUsed) {
            Object.keys(criteriaReqMap).forEach(function (criteria) {
                criteriaReqMap[criteria].forEach(function (req) {
                    selectAllIds.push(req);
                });
            })
        }

        const { sellerEvaluationMeta } = this.props;
        var criterias = (sellerEvaluationMeta !== undefined && sellerEvaluationMeta !== null) ? sellerEvaluationMeta?.criterias : [];
        var selectedIds = [];
        criterias.forEach(function (criteria) {
            criteria.requirements.forEach(function (req) {
                selectedIds.push(req.id)
            })
        });

        if (selectedIds.length > 0) {
            var isSendDisable = false;
            if (totalReqLegth === selectedIds.length) {
                isSendDisable = true;
            }
            this.setState({
                requirementIds: selectedIds,
                alreadySelectedReqIds: selectedIds,
                isSendDisable: isSendDisable,
                selectedIds,
            }, () => {
                this.updateSelectAlloption();
                this.checkSelectAllOptionOnLoad();
            })
        } else {
            this.setState({
                requirementIds: selectAllIds,
                selectedIds
            }, () => {
                this.updateSelectAlloption();
            })
        }
    }

    toggleRequirement(requirementId) {
        var selectRequirementIds = [...this.state.requirementIds];
        if (selectRequirementIds.indexOf(requirementId) === -1) {
            selectRequirementIds.push(requirementId)
        } else {
            selectRequirementIds.splice(selectRequirementIds.indexOf(requirementId), 1)
        }
        this.setState({
            requirementIds: selectRequirementIds
        }, () => {
            this.updateSelectAlloption();
        })
    }

    submitRequest() {
        var asksellerMap = [];
        var selectRequirementIds = this.state.requirementIds;
        var alreadySelectedReqIds = this.state.alreadySelectedReqIds;
        this.props.criterias.forEach((criteria) => {
            criteria.requirements.forEach((requirement) => {
                if (alreadySelectedReqIds.indexOf(requirement.id) !== -1) {
                    return;
                } else if (selectRequirementIds.indexOf(requirement.id) !== -1) {
                    var description = requirement.description
                    if (requirement.description === null || String(requirement.description).trim().length === 0) {
                        description = requirement.name
                    }
                    asksellerMap.push({
                        'requirementId': requirement.id,
                        'response': description
                    })
                }
            })
        });

        if (asksellerMap.length === 0) {
            this.props.showSnackBar("Please select atleast one requirement", "error", 3000)
            return;
        }
        this.props.askSellerMultiRequirement(this.props.product.VendorUUID, asksellerMap)
    }

    updateSelectAlloption() {
        var criteriaShowAllMap = {};
        var criteriaWiseTotalSelected = {};
        if (this.state.requirementIds !== undefined && this.state.criteriaReqMap !== undefined && this.state.criteriaReqMap !== null && Object.keys(this.state.criteriaReqMap).length > 0) {
            var allCriterias = this.state.criteriaReqMap;
            var allSelReqs = [...this.state.requirementIds];
            Object.keys(allCriterias).forEach(function (criteria) {
                var totalReqLength = allCriterias[criteria].length;
                var totalSelected = 0;
                allCriterias[criteria].forEach(function (req) {
                    if (allSelReqs.indexOf(req) !== -1) {
                        totalSelected++;
                    }
                })
                criteriaWiseTotalSelected[criteria] = totalSelected;
                if (totalSelected === totalReqLength) {
                    criteriaShowAllMap[criteria] = true;
                } else {
                    criteriaShowAllMap[criteria] = false;
                }
            });
        }
        this.setState({
            criteriaShowAllMap: criteriaShowAllMap,
            criteriaWiseTotalSelected: criteriaWiseTotalSelected
        }, () => {
            this.checkSelectAllOption()
        })
    }

    checkSelectAllOptionOnLoad() {
        var alreadySelectedCriteriaMap = {};
        var criteriaWiseTotalSelected = {};
        if (this.state.alreadySelectedReqIds !== undefined && this.state.criteriaReqMap !== undefined && this.state.criteriaReqMap !== null && Object.keys(this.state.criteriaReqMap).length > 0) {
            var allCriterias = this.state.criteriaReqMap;
            var alreadyAllSelReqs = this.state.alreadySelectedReqIds;
            Object.keys(allCriterias).forEach(function (criteria) {
                var totalReqLength = allCriterias[criteria].length;
                var totalSelected = 0;
                allCriterias[criteria].forEach(function (req) {
                    if (alreadyAllSelReqs.indexOf(req) !== -1) {
                        totalSelected++;
                    }
                })
                criteriaWiseTotalSelected[criteria] = totalSelected;
                if (totalSelected === totalReqLength) {
                    alreadySelectedCriteriaMap[criteria] = true;
                } else {
                    alreadySelectedCriteriaMap[criteria] = false;
                }
            });
        }
        this.setState({
            alreadySelectedCriteriaMap: alreadySelectedCriteriaMap,
            criteriaWiseTotalSelected: criteriaWiseTotalSelected
        })
    }

    selectAllRequirtments(criteriaId) {
        var selectRequirementIds = [...this.state.requirementIds];
        var allCriteriaIdMap = this.state.criteriaReqMap;
        if (allCriteriaIdMap[criteriaId] !== undefined) {
            if (this.state.criteriaShowAllMap[criteriaId]) {
                allCriteriaIdMap[criteriaId].forEach(function (requirementId) {
                    if (selectRequirementIds.indexOf(requirementId) !== -1) {
                        selectRequirementIds.splice(selectRequirementIds.indexOf(requirementId), 1)
                    }
                });
            } else {
                allCriteriaIdMap[criteriaId].forEach(function (requirementId) {
                    if (selectRequirementIds.indexOf(requirementId) === -1) {
                        selectRequirementIds.push(requirementId)
                    }
                });
            }
        }
        this.setState({
            requirementIds: selectRequirementIds
        }, () => {
            this.updateSelectAlloption();
        })
    }

    handleCloseDialog = () => {
        this.props.setSelectedRequirement(this.state.mainRequirementId);
        this.props.hideDialog()
    }

    selectAllCriterias() {
        console.log('Calling')
        const state = this.state;
        var selectRequirementIds = [...this.state.requirementIds];
        var allCriteriaIdMap = this.state.criteriaReqMap;
        let isAllSelected = Object.values(state.criteriaShowAllMap).find(flag => flag === false);
        var criterias = this.props.criterias !== undefined && this.props.criterias !== null ? this.props.criterias : [];
        criterias.forEach(function (cri) {
            console.log(cri, 'Criteriaaaaa')
            let criteriaId = cri.id;
            if (allCriteriaIdMap[criteriaId] !== undefined) {
                if (isAllSelected || isAllSelected === undefined) {
                    allCriteriaIdMap[criteriaId].forEach(function (requirementId) {
                        if (selectRequirementIds.indexOf(requirementId) !== -1) {
                            selectRequirementIds.splice(selectRequirementIds.indexOf(requirementId), 1)
                        }
                    });
                } else {
                    allCriteriaIdMap[criteriaId].forEach(function (requirementId) {
                        if (selectRequirementIds.indexOf(requirementId) === -1) {
                            selectRequirementIds.push(requirementId)
                        }
                    });
                }
            }
        })

        console.log(selectRequirementIds, 'selectRequirementIds')
        this.setState({
            requirementIds: selectRequirementIds
        }, () => {
            this.updateSelectAlloption();
        })
    }

    checkSelectAllOption() {
        var me = this;
        var criterias = this.props.criterias !== undefined && this.props.criterias !== null ? this.props.criterias : [];
        console.log(criterias, 'criterias01')
        var totalCriterias = criterias.length;
        console.log(this.state.criteriaShowAllMap, 'criteriaShowAllMap');
        var totalChecked = 0;
        Object.keys(me.state.criteriaShowAllMap).forEach(function (itm) {
            if (me.state.criteriaShowAllMap[itm]) {
                totalChecked++;
            }
        })
        var isChecked = false;
        if (totalChecked === totalCriterias) {
            isChecked = true;
        }
        this.setState({
            checkAllCriterias: isChecked
        })
    }

    render() {
        const classes = this.props.classes;

        const { sellerEvaluationMeta } = this.props;
        var progress = (sellerEvaluationMeta !== undefined && sellerEvaluationMeta !== null) ? sellerEvaluationMeta.progress : {};
        var totalReq = progress?.Total;
        var totalDone = progress?.Completed;
        var totalDoneProgress = 0;

        totalDoneProgress = Math.round(((totalDone * 100) / totalReq));

        if (isNaN(totalDoneProgress)) {
            totalDoneProgress = 0;
        }

        return <Dialog
            onClose={this.handleCloseDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            disableBackdropClick={true}
            fullWidth={true}
            fullScreen
            maxWidth={"md"}
            scroll="body"
            className={classes.dialog}
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <AppBar title="Ask Vendor" withBack={true} dispatchEvent={true} targetEvent={() => {
                    this.handleCloseDialog()
                }} />

                {this.props.product && <div className={classes.productDetail} style={{ display: 'flex' }}>
                    <Grid container alignItems="center" className={classes.cartItem}>
                        <Grid item className={classes.productImageContainer}>
                            <Image alt="product" src={this.props.product?.ProductLogo} className={classes.productImage} />
                        </Grid>
                        <Grid item className={classes.productNameContainer}>
                            <Grid container alignItems='center' justifyContent="space-between">
                                <Typography variant={"h5"} className={classes.productNameTitle}>{this.props.product.Name}</Typography>

                                <Grid container alignItems='center' spacing={1} style={{ width: 'max-content' }}>
                                    {this.state.selectedIds.length > 0 && (
                                        <>
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    className={classes.viewActivity}
                                                    onClick={() => this.setState({ sellerActivityDialog: true })}
                                                >
                                                    View Vendor Activity
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    className={classes.viewActivity}
                                                    onClick={() => this.setState({ isOpenShareDialog: true })}
                                                >
                                                    Share With Vendor
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item>
                                        <CachedIcon
                                            className={classnames(classes.refreshIcon, this.props.metaFetchProgress === true ? classes.underprogress : null)}
                                            onClick={() => {
                                                this.props.fetchEvaluationMeta(this.props.product?.VendorUUID, this.props.user.Organization.ID)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider style={{ margin: '8px 0px' }} />
                            <div className={classes.productCompBox}>
                                <span>{this.props.product.VendorEmail} {this.props.product.Deadline !== '' && this.props.product.Deadline !== null ? '(Deadline: ' + CommonFn.formateDate(this.props.product.Deadline, true) + ')' : ''}
                                    <IconButton onClick={() => {
                                        this.props.onClickEdit()
                                        this.handleCloseDialog()
                                    }}>
                                        <Image src={editIcon} />
                                    </IconButton>
                                </span>
                            </div>
                            <Divider style={{ margin: '8px 0px' }} />
                            <div className={classes.progressBar} onClick={() => { this.showFinalizeDialog('DONE') }}>
                                <Typography className={classes.progText}>
                                    <div className={classes.productPerCom}>
                                        <span className={classes.progTextComp}>{totalDoneProgress}% Completed</span>
                                        <span className={classes.totalAnswered}>{totalDone}/{totalReq}</span>
                                    </div>
                                </Typography>
                                <BorderLinearProgress variant="determinate" value={totalDoneProgress} />
                            </div>
                        </Grid>
                    </Grid>

                    {/* 
                    <Image className={classes.productLogo} alt="product" src={this.props.product?.ProductLogo} />
                    <Typography className={classes.productName}>{this.props.product.Name} </Typography>
                    <span style={{ marginLeft: 10, marginTop: 5 }}>({this.props.product.VendorEmail})</span> */}


                </div>}

                <div style={{ display: 'flex', alignItems: 'center', marginTop: 25 }}>
                    <Checkbox
                        label={'Select All'}
                        className={classes.criteriaAllSelect}
                        disabled={this.state.isRuleUsedForEvaluation}
                        color="primary"
                        checked={this.state.checkAllCriterias}
                        onChange={() => {
                            this.selectAllCriterias()
                        }}
                    />
                    <Typography>Select All</Typography>
                </div>

                <div className={classes.container}>
                    {
                        this.props.criterias && this.props.criterias?.map((item, index) => {
                            if (item?.requirements.length <= 0) {
                                return '';
                            }
                            return <Accordion key={index}>
                                <AccordionSummary className={classes.AccordionHeader}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label={item.name}
                                    aria-controls={`additional-actions${index + 1}-content`}
                                    id={`additional-actions${index + 1}-header`}
                                >
                                    <Checkbox
                                        className={classes.criteriaAllSelect}
                                        color="primary"
                                        disabled={(this.state.alreadySelectedCriteriaMap[item.id] !== undefined && this.state.alreadySelectedCriteriaMap[item.id] === true) || this.state.isRuleUsedForEvaluation ? true : false}
                                        checked={this.state.criteriaShowAllMap[item.id] !== undefined ? this.state.criteriaShowAllMap[item.id] : false}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            this.selectAllRequirtments(item.id)
                                        }}
                                    />

                                    <Typography className={classes.heading}>{item.name}</Typography>

                                    {this.state.criteriaWiseTotalSelected[item.id] !== undefined && this.state.criteriaWiseTotalSelected[item.id] > 0 && <span className={classes.isExisting}>{this.state.criteriaWiseTotalSelected[item.id] === 1 ? this.state.criteriaWiseTotalSelected[item.id] + ' Question Selected' : this.state.criteriaWiseTotalSelected[item.id] + ' Questions Selected'}</span>}

                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul className={classes.UL}>
                                        {
                                            item?.requirements?.map((list, index) =>
                                                <li style={{ position: 'relative' }} key={index + 'ask_requirement'}>
                                                    <Checkbox
                                                        className={classes.multiSelect}
                                                        color="primary"
                                                        disabled={(this.state.alreadySelectedReqIds.indexOf(list.id) !== -1 || this.state.isRuleUsedForEvaluation) ? true : false}
                                                        checked={(this.state.requirementIds.indexOf(list.id) === -1) ? this.state.alreadySelectedReqIds.indexOf(list.id) !== -1 ? true : false : true}
                                                        onChange={() => {
                                                            this.toggleRequirement(list.id)
                                                        }}
                                                    />
                                                    <p style={{ paddingLeft: 50, marginBottom: 15 }}>
                                                        <b>{list.name}</b>
                                                        &nbsp;&nbsp;
                                                        {this.props.selectedRequirementId && findRequirementPreviousInteraction(this.props.selectedRequirementId, this.props.previousInteractions, 'seller').has && (
                                                            <span
                                                                onClick={() => {
                                                                    this.props.setSelectedRequirement(list.id)
                                                                    setTimeout(() => {
                                                                        this.props.setResponseTab(2);
                                                                        this.props.tooglePreviousInteractionDialog()
                                                                    }, 100)
                                                                }}
                                                                className={classes.viewAllResponse}
                                                            >View previous interaction</span>
                                                        )}
                                                    </p>
                                                    <p style={{ paddingLeft: 50 }}>
                                                        <SlateReadonlyField
                                                            initValue={list.description} /></p>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        })
                    }
                </div>

                <div className={classes.actions}>
                    {!this.props.submitProgress && <React.Fragment>
                        <SqureButton variant={"outlined"} onClick={() => {
                            this.handleCloseDialog()
                        }}>Cancel</SqureButton>&nbsp;&nbsp;
                        <SqureButton
                            disabled={this.state.isSendDisable}
                            variant={"contained"}
                            onClick={() => {
                                this.submitRequest()
                            }}>Send</SqureButton>
                    </React.Fragment>}
                    {this.props.submitProgress && <CircularProgress />}
                </div>
            </DialogContent>
            <FinalizeRequiremntViewDialog />
            <SellerActivityDialog
                classes={classes}
                sellerActivityProg={this.props.sellerActivityProg}
                sellerActivity={this.props.sellerActivity}
                VendorUUID={this.props.product?.VendorUUID}
                fetchSellerActivity={this.props.fetchSellerActivity}
                isOpen={this.state.sellerActivityDialog}
                handleCloseDialog={() => this.setState({ sellerActivityDialog: false })}
            />
            <ShareDialog
                classes={classes}
                isOpen={this.state.isOpenShareDialog}
                onClose={() => this.setState({ isOpenShareDialog: false })}
                resend={() => this.props.reSendAskSellerMultiRequirement(this.props.product.VendorUUID)}
                copyLink={() => {
                    navigator.clipboard.writeText(`${window.location.origin}/seller/evaluate/${this.props.product.VendorUUID}/${this.props.user.OrgID}/${this.props.product.ID}`)
                }}
                sending={this.props.reSendAskSellerWithSellerResponseProgress}
            />
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(ChooseAsksellerSelectModal));


const SellerActivityDialog = ({ classes, isOpen, sellerActivityProg, handleCloseDialog, sellerActivity, VendorUUID, fetchSellerActivity }) => {
    useEffect(() => {
        fetchSellerActivity(VendorUUID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, VendorUUID])
    return (
        <Dialog
            onClose={handleCloseDialog}
            aria-labelledby="app-integrationDialog-seller-activity"
            open={isOpen}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog-seller-activity"
        >
            <DialogContent >
                <Grid container justifyContent="space-between">
                    <Typography>Vendor Activity</Typography>
                    <IconButton onClick={handleCloseDialog}><ClearIcon /></IconButton>
                </Grid>
                {sellerActivityProg && <Grid container justifyContent="center"><CircularProgress /></Grid>}
                {!sellerActivityProg && sellerActivity &&
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell width={5}></TableCell>
                                <TableCell>User Name</TableCell>
                                <TableCell>Requirement</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sellerActivity.map((activity, index) => (
                                <TableRow key={index} >
                                    <TableCell width={5}>{index + 1}</TableCell>
                                    <TableCell>{activity.user_name}</TableCell>
                                    <TableCell>{activity.requirement_name}</TableCell>
                                    <TableCell>{CommonFn.formateDate(activity.craeted_at)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
            </DialogContent>
        </Dialog>
    )
}


const ShareDialog = ({ classes, isOpen, resend, onClose, copyLink, sending }) => {
    const [copy, setCopy] = useState(false)
    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="app-integrationDialog-seller-activity"
            open={isOpen}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialog-seller-activity"
        >
            <DialogContent>
                <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 10 }}>
                    <Typography className={classes.heading} style={{ fontSize: '1.4rem' }}><strong>Share with vendor</strong></Typography>
                    <IconButton onClick={onClose}><ClearIcon /></IconButton>
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center" style={{ padding: '12px 0px' }}>
                    <Grid container alignItems="center" style={{ width: 'max-content' }}>
                        <div className={classes.linkIcon}>
                            <LinkIcon style={{ color: 'white' }} />
                        </div>
                        &nbsp;&nbsp;
                        <Typography className={classes.heading}>Copy link to share with vendor</Typography>
                    </Grid>
                    {copy && (
                        <Typography className={classes.copiedText}>Copied Link</Typography>
                    )}
                    {!copy && (
                        <Button
                            color="primary"
                            className={classes.copyText}
                            onClick={() => {
                                copyLink()
                                setCopy(true)
                                setTimeout(() => {
                                    setCopy(false)
                                }, 3000);
                            }}
                        >
                            Copy Link
                        </Button>
                    )}
                </Grid>
                <Divider style={{ margin: '12px 0px' }} />
                <Grid container justifyContent="space-between" alignItems="center" style={{ padding: '12px 0px 24px 0px' }}>
                    <Grid container alignItems="center" style={{ width: 'max-content' }}>
                        <div className={classes.linkIcon}>
                            <SendIcon style={{ color: 'white' }} />
                        </div>
                        &nbsp;&nbsp;
                        <Typography className={classes.heading}>Resend notification to Seller</Typography>
                    </Grid>
                    {sending ?
                        <CircularProgress style={{ fontSize: 12 }} />
                        :
                        <Button
                            color="primary"
                            className={classes.copyText}
                            onClick={() => {
                                resend(false)
                            }}
                        >
                            Resend
                        </Button>
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}