import React from 'react';
import { connect } from "react-redux";
import classnames from "classnames";
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';
import { createStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogContent, CircularProgress, Slide, Typography, Grid, TextField
} from '@material-ui/core';

import SqureButton from 'Components/Common/SqureButton';

import { fetchOrgTemplates, saveFromOrgEvaluationTemplate } from "redux/templates/evaluation/org/action";
import { showSnackBar } from "redux/snackbar/action";
import { showEvaluationCollaboratorDialog } from "redux/evaluate/action";

import { fetchOrgForms, addOrgFormData } from "redux/forms/org/action";


import Form from "./Form";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  addOrgFormError: state.orgForms.addOrgFormError,
  addOrgFormProgress: state.orgForms.addOrgFormProgress,
  orgFormData: state.orgForms.orgFormData,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  saveFromOrgEvaluationTemplate: saveFromOrgEvaluationTemplate,
  fetchOrgTemplates: fetchOrgTemplates,
  showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog,
  addOrgFormData: addOrgFormData,
  fetchOrgForms: fetchOrgForms
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
    padding: theme.spacing(6, 12) + " !important",
  },
  dialogContent2:{
    padding: theme.spacing(6, 12) + " !important",
    background:'#f7f7f7'
  },
  modalTitle: {
    fontSize: theme.spacing(2.4),
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  helptext: {
    fontSize: theme.spacing(1.8)
  },
  alerts: {
    marginTop: theme.spacing(4)
  },
  form: {
    marginTop: theme.spacing(2),
    width: '60%',
  },
  input: {
    marginBottom: theme.spacing(3)
  },
  scheduleTitle: {
    display: "block",
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    fontSize: theme.spacing(2)
  },
  scheduleTitle1: {
    display: "block",
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    fontSize: theme.spacing(1.8)
  },
  actions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  loader: {
    textAlign: "center",
    padding: theme.spacing(10, 0)
  },
  dialogAction: {
    textAlign: "center"
  },
  addNewButton: {
    fontWeight: 600,
    fontSize: theme.spacing(2)
  },
  actionBtn: {
    minWidth: 110
  },
  nameYour: {
    "& [class*=MuiInputBase-root]": {
      maxHeight: 40,
      minHeight: 40,
      "& input": {
        fontSize: theme.spacing(1.8)
      }
    },
  },
  nameOfCriteria: {
    "& > div": {
      marginBottom: 0
    },
  },
  priority: {
    "& > div": {
      marginBottom: 0,
      justifyContent: 'flex-start',
    },
    "& [class*=MuiInputBase-root]": {
      width: 100,
      color: '#707070',
      fontSize: 13,
      textAlign: 'center'
    },
    "& [class*=MuiSelect-outlined]": {
      color: "#707070",
      fontSize: theme.spacing(1.8),
      "&:after": {
        content: "''",
        width: 7,
        height: 7,
        position: 'absolute',
        left: 9,
        borderRadius: '50%',
        top: '50%',
        transform: 'translate(0px, -50%)'
      },
    },
    "&.low [class*=MuiSelect-outlined]:after": {
      background: 'green'
    },
    "&.medium [class*=MuiSelect-outlined]:after": {
      background: 'orange'
    },
    "&.high [class*=MuiSelect-outlined]:after": {
      background: 'red'
    }
  },
  scoring: {
    "& > div": {
      alignItems: "baseline",
      marginBottom: 0
    },
    "& [class*=MuiSelect-outlined]": {
      paddingLeft: 10
    }
  },
  yourQuestion: {
    "& [class*=MuiInputBase-root]": {
      padding: 0,
      fontSize: theme.spacing(1.8),
      minHeight: 120,
      color: "#707070",
      lineHeight: 1.2,
      "& textarea": {
        minHeight: 100,
      }
    }
  },
  addCriteriaDatePicker: {
    "& .react-datepicker__input-container > div": {
      display: 'flex',
      minWidth: 300,
      marginBottom: 0,
      alignItems: 'baseline',
      "& [class*=MuiInputBase-root]": {
        maxHeight: 33,
        minHeight: 33,
        width: 166,
        "& input": {
          fontSize: theme.spacing(1.8),
          // width: 107,
          textAlign: "center"
        }
      },
      "& [class*=MuiFormLabel-root]": {
        marginBottom: 0,
        marginRight: 29
      }
    },
    "[class*=MuiMenuItem-root]": {
      color: "#707070",
      fontSize: theme.spacing(1.8)
    }
  },
  titleHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleImage: {
    cursor: 'pointer'
  },
  marginBottom: {
    marginBottom: 10,
  },

});

class CreateFormDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      activeMultiSelectId: false,
      name: "",
      name_error: "",
      step: 1,
      customSections: [],
      tabView: [],
    }

    this.submitResponse = this.submitResponse.bind(this);
    this.cleanError = this.cleanError.bind(this);
    this.cleanState = this.cleanState.bind(this);
  }

  cleanState() {
    this.setState({
      error: "",
      success: "",
      name: "",
      name_error: "",
      form_name_error: "",
      form_name: "",
      form_description_error: "",
      form_description: "",
      step: 1
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.addOrgFormProgress === false && prevProps.addOrgFormProgress === true) {
      if (this.props.addOrgFormError === null) {
        this.cleanState();
        this.props.showSnackBar("Form Saved", "success", 3000);
        this.props.fetchOrgForms(0, 60);
        this.props.hideDialog();
        this.props.history.push("/app/settings/templates/forms/custom/"+this.props.orgFormData?.ID);
        

        // this.setState({
        //   step: 2
        // })
      } else {
        if (this.props.addOrgFormError.message !== undefined) {
          this.setState({
            "error": "Failed to save template."
          })
        } else {
          this.setState({
            "error": "Something went wrong."
          })
        }
      }
    }
  }
  cleanError() {
    this.setState({
      error: "",
      success: "",
    })
  }

  submitResponse() {
    this.cleanError();
    var formName = this.state.form_name || "";
    var formDescription = this.state.form_description || "";
    const timestamp = (new Date()).getTime();
    var formUUID = formName.replace(/[^a-zA-Z0-9]/g, '_')+'_'+timestamp;

    if (formName.trim().length === 0) {
      this.setState({
        error: "Please enter Form Name."
      });
      return;
    }

    const data = {
      form_title: formName,
      form_slug:formUUID,
      form_description: formDescription,
      form_type: "ORG",
      sections: []
    }

    this.props.addOrgFormData(data)
  }


  render() {
    const classes = this.props.classes;

    const { step } = this.state;

    return <Dialog
      onClose={this.props.hideDialog}
      aria-labelledby="app-integrationDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"md"}
      scroll="body"
      id="evMetaDialog"
    >
      {step === 1 && <DialogContent classes={{ root: classes.dialogContent }} >
        <Typography variant={"h2"} className={classes.modalTitle}>Add New Form</Typography>
        <div className={classes.alerts}>
          {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
          {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
        </div>
        <div className={classes.criteriaDetail}>
          <div className={classnames(classes.marginBottom, classes.nameOfCriteria, classes.nameYour)}>
            <Grid container>
              <Grid lg={12} md={12} sm={12} xs={12} >
                <TextField
                  id="standard-multiline-flexible"
                  fullWidth
                  label="Title"
                  value={this.state.form_name}
                  placeholder='Enter your form name here.'
                  onChange={(event) => {
                    this.setState({
                      form_name: event.target.value
                    })
                  }}
                />
              </Grid>
              <Grid lg={12} md={12} sm={12} xs={12} style={{marginTop:20}}>
                <TextField
                  id="standard-multiline-flexible"
                  fullWidth
                  label="Description"
                  value={this.state.form_description}
                  placeholder={"Enter your description here"}
                  onChange={(event) => {
                    this.setState({
                      form_description: event.target.value
                    })
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.actions}>
          {this.props.addOrgFormProgress && <CircularProgress />}
          {!this.props.addOrgFormProgress && (
            <>
              <SqureButton
                cls={classes.actionBtn}
                variant={"outlined"}
                onClick={() => {
                  this.cleanError()
                  this.props.hideDialog()
                }}
              >
                Cancel
              </SqureButton>&nbsp;&nbsp;&nbsp;
              <SqureButton
                cls={classes.actionBtn}
                variant={"contained"}
                onClick={(e) => {
                  this.submitResponse()
                }}
              >
                Save
              </SqureButton>
            </>
          )}
        </div>
      </DialogContent>}

      {step === 2 && <DialogContent classes={{ root: classes.dialogContent2 }} >
        <div className={classes.section2}>
          <Typography variant={"h2"} className={classes.modalTitle}>Add Section</Typography>
          <div className={classes.alerts}>
            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
            {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
          </div>

          <Form
            orgFormData={this.props.orgFormData}
            hideDialog={() => {
              this.props.hideDialog()
            }}
            isShowNewSectionbtn={false}
            isEditForm={false}
            type={'custom'}
          />

        </div>
      </DialogContent>}

    </Dialog >
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CreateFormDialog));