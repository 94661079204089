import { createStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import CommonCss from "commonCss";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({});

const connectionActions = {
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);
const styles = (theme) =>
  createStyles({
    formGroup: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    formControl: {
      ...CommonCss.formControl,
      minHeight: 34,
      padding: "0px 10px",
    },
    label: {
      marginBottom: 5,
      display: "block",
    },
    supportLabel: {
      marginTop: 3,
      marginBottom: 3,
      display: "inline-block",
      marginRight: 30,
    },
  });

class SwitchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
    };
  }

  componentDidMount() {
    this.setState({ checked: this.props.value });
  }

  componentDidUpdate(prevProps) {}

  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.formGroup}>
        <span className={classes.supportLabel}>{this.props.label}:</span>
        <Switch
          checked={this.state.checked}
          color="primary"
          onChange={(e) => {
            this.setState({ checked: e.target.checked });
            this.props.updateValue(e.target.checked);
          }}
          name="checkedA"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(SwitchComponent));
