import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {hideSnackBar} from "../../redux/snackbar/action"
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const connectedProps = (state) => ({
    snackbar:state.snackbar
});

const connectionActions = {
    hideSnackBar:hideSnackBar
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
   
});

class Snackbar extends React.Component {

    hideSnack = (event, reason) => {
        if (reason === 'clickaway' && this.props.snackbar?.showOnClickAway) {
            return;
        }
        this.props.hideSnackBar();
    }

    render() {
        const classes = this.props.classes;
        return <MuiSnackbar open={this.props.snackbar.show} autoHideDuration={this.props.snackbar.duration} onClose={this.hideSnack} anchorOrigin={{ vertical:"bottom", horizontal:"center" }}>
                    <Alert onClose={this.hideSnack} severity={this.props.snackbar.type}>
                    {this.props.snackbar.message}
                    </Alert>
                </MuiSnackbar>
    }
}

export default connector(compose(
    withStyles(styles)
)(Snackbar));