import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide,Typography, CircularProgress, Grid} from '@material-ui/core';
import { withRouter } from 'react-router';
import {hideOrgUserUpdateDialog,getOrgUser,updateOrgUser} from "../../redux/org/user/action";
import {fetchOrgGroup,addOrgGroup} from "../../redux/org/group/action";
import SqureButton from './SqureButton';
import OutlinedInput from "./Input/OutlinedInput";
import CreatableSelect from "react-select/creatable";
import CommonCss from 'commonCss';
import Alert from '@material-ui/lab/Alert';
const connectedProps = (state) => ({
    open:state.orgUser.showUserEditDialog,
    userId:state.orgUser.updateUserId,
    getUserProg:state.orgUser.getUserProg,
    user:state.orgUser.user,
    groups:state.orgGroup.groups,
    fetchGroupProg:state.orgGroup.fetchGroupProg,
    addGroupProg:state.orgGroup.addGroupProg,
    addedGroup:state.orgGroup.addedGroup,
    updateUserProg:state.orgUser.updateUserProg
});
const connectionActions = {
    hideDialog:hideOrgUserUpdateDialog,
    getOrgUser:getOrgUser,
    fetchOrgGroup:fetchOrgGroup,
    addOrgGroup:addOrgGroup,
    updateOrgUser:updateOrgUser
};

var connector = connect(connectedProps, connectionActions);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const styles = (theme) => createStyles({
    dialogContent:{
        padding: theme.spacing(6, 12) +" !important",
        paddingBottom:theme.spacing(1)+" !important",
    },
    modalTitle:{
        fontSize:theme.spacing(2.4),
        marginBottom:theme.spacing(3),
        textAlign:"center",
    },
    actions:{
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3),
        textAlign:"center",
    },
    loader:{
        textAlign:"center",
        padding:theme.spacing(10,0)
    },
    dialogAction:{
        textAlign:"center"
    },
    actionBtn:{
        minWidth: 110
    },
    alerts:{
        marginTop:theme.spacing(4),
        marginBottom:theme.spacing(4),
    },
    label: CommonCss.label,
    formGroup: {
        ...CommonCss.formGroup,
        '& .select__value-container': {
            padding: '12px',
            '& .select__single-value':{
                color: '#5F5F5F',
                // padding: '0.75em',
                fontSize:'17px'
            }
        }
    },
});

class OrgUserUpdateDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            teamquery:"",
            team:null,
            error:""
        }
        this.fetchOrgGroups     =   this.fetchOrgGroups.bind(this);
        this.updateOrgUser  =   this.updateOrgUser.bind(this);
    }

    componentDidMount(){
        this.setState({
            error:''
        })
    }

    fetchOrgGroups(){
        this.props.fetchOrgGroup(0,15,this.state.teamquery);
    }

    updateOrgUser(){
        var team  =   this.state.team;
        if(team === null || team.value === undefined){
            this.setState({
                error:"Please select the team."
            })
            return;
        }
        this.props.updateOrgUser(this.props.user.ID,{
            primary_group_id:team.value
        })
    }

    componentDidUpdate(prevProps){
        if(this.props.open === true && prevProps.open === false){
            this.props.getOrgUser(this.props.userId)
            this.fetchOrgGroups();
            this.setState({
                error:''
            })
        }

        if(this.props.addGroupProg === false && prevProps.addGroupProg === true){
            if(this.props.addedGroup !== null){
                this.setState({"team":{
                    value:this.props.addedGroup.ID,
                    label:this.props.addedGroup.Name
                }});
            }
        }

        if(this.props.updateUserProg === false && prevProps.updateUserProg === true){
            this.props.hideDialog();
        }

        if(this.props.getUserProg === false && prevProps.getUserProg === true){
            if(this.props.user !== null && this.props.user.PrimaryGroup !== null){
                this.setState({"team":{
                    value:this.props.user.PrimaryGroup.ID,
                    label:this.props.user.PrimaryGroup.Name
                }});
            }
        }
    }

   
    render() {
        const classes = this.props.classes;
       
        return <Dialog 
                    onClose={this.props.hideDialog} 
                    open={this.props.open}
                    TransitionComponent={Transition}
                    disableBackdropClick={true}
                    fullWidth={true}
                    maxWidth={"sm"}
                    scroll={"body"}
                    id="dialogUpdateUser"
                    >
                    {this.props.getUserProg && <DialogContent classes={{root:classes.dialogContent}}>
                        <div className={classes.loader}>
                            <CircularProgress />
                        </div>
                    </DialogContent>}
                    {!this.props.getUserProg && this.props.user !== null &&  <React.Fragment>
                        <DialogContent classes={{root:classes.dialogContent}}>
                            <Typography variant={"h2"} className={classes.modalTitle}>Update User</Typography>
                            <div className={classes.alerts}>
                                {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                            </div>
                            <Grid container>
                                <Grid item lg={12}>
                                    <OutlinedInput
                                        label="Name"
                                        value={this.props.user.Name}
                                        onChange={(event) => {
                                        }}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item lg={12}>
                                    <OutlinedInput
                                        label="Email"
                                        value={this.props.user.Email}
                                        onChange={(event) => {
                                        }}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item lg={12}>
                                    <div className={classes.formGroup}>
                                        <label className={classes.label}>Team</label>
                                        <CreatableSelect
                                            isClearable
                                            isSearchable={true}
                                            options={this.props.groups.map((item)=>{
                                                return {
                                                    value: item.ID,
                                                    label: item.Name
                                                }
                                            })}
                                            classNamePrefix="select"
                                            placeholder="Team" 
                                            onInputChange={(e) => {
                                                this.setState({
                                                    teamquery:e
                                                },()=>{
                                                    this.fetchOrgGroups()
                                                })
                                                
                                            }}
                                            isLoading={this.props.fetchGroupProg || this.props.addGroupProg}
                                            onChange={(e,action)=>{
                                               this.setState({
                                                   team:e
                                               })
                                            }}
                                            onCreateOption={(e)=>{
                                                console.log(e)
                                                this.props.addOrgGroup(e,null)
                                            }}
                                            menuPortalTarget={document.body} 
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            maxMenuHeight={180}
                                            defaultValue={this.state.team}
                                            value={this.state.team}
                                        />
                                    </div>
                                </Grid>

                            </Grid>
                            <div className={classes.actions}>
                                <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                                <SqureButton  cls={classes.actionBtn} variant={"contained"} 
                                onClick={this.updateOrgUser}>Save</SqureButton>
                            </div>
                        </DialogContent>
                    </React.Fragment>}
                </Dialog>
    }
}


export default connector(compose(
    withRouter,
    withStyles(styles)
)(OrgUserUpdateDialog));