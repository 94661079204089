import React, { useState } from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root:{
    '& .react-read-more-read-less':{
      cursor: 'pointer',
      color: '#4A87F8'
    }
  }
}))

function ReadMore(props) {
  const { children } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <div style={isHidden ? hidden : {}}>
        {children}
      </div>
      <div>
      <span onClick={() => setIsHidden(!isHidden)} style={readMore}>{isHidden ? "Read More" : "Read Less"}</span>
      </div> */}
      <ReactReadMoreReadLess
                charLimit={props.charLimit}
                readMoreText={"Read more"}
                readLessText={"Read less"}
            >
                {children}
            </ReactReadMoreReadLess>
    </div>
  );
}

export default ReadMore;
