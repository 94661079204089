import {
  SHOW_IMPORT_FORM_DIALOG, HIDE_IMPORT_FORM_DIALOG,
  SHOW_ADD_FORM_DIALOG, HIDE_ADD_FORM_DIALOG,
  START_ADD_ORG_FORM_DETAILS, END_ADD_ORG_FORM_DETAILS,
  START_UPDATE_ORG_FORM_DETAILS, END_UPDATE_ORG_FORM_DETAILS,
  START_DELETE_ORG_FORM_DETAILS, END_DELETE_ORG_FORM_DETAILS,
  START_FETCH_ORG_FORMS, END_FETCH_ORG_FORMS,
  START_FETCH_ORG_FORM_BY_ID, END_FETCH_ORG_FORM_BY_ID,
  START_ADD_ORG_FORM_SECTION, END_ADD_ORG_FORM_SECTION,
  START_UPDATE_ORG_FORM_SECTION, END_UPDATE_ORG_FORM_SECTION,
  START_FETCH_ORG_FORM_SECTIONS, END_FETCH_ORG_FORM_SECTIONS,
  START_DELETE_ORG_FORM_SECTION, END_DELETE_ORG_FORM_SECTION,
  START_ADD_ORG_FORM_SECTION_ITEM, END_ADD_ORG_FORM_SECTION_ITEM,
  START_UPDATE_ORG_FORM_SECTION_ITEM, END_UPDATE_ORG_FORM_SECTION_ITEM,
  START_FETCH_ORG_FORM_SECTION_ITEMS, END_FETCH_ORG_FORM_SECTION_ITEMS,
  START_DELETE_ORG_FORM_SECTION_ITEM, END_DELETE_ORG_FORM_SECTION_ITEM,
  SET_FORM_ACTIVE_TAB, SHOW_USED_PLACES_DIALOG,
  HIDE_USED_PLACES_DIALOG, START_FETCH_USED_PLACES,
  END_FETCH_USED_PLACES,
} from "./action";

const initState = {
  isShowImportFormDialog: false,
  isShowAddFormDialog: false,
  addOrgFormProgress: false,
  updateOrgFormProgress: false,
  deleteOrgFormProgress: false,
  fetchOrgFormsProgress: false,
  fetchOrgFormsByIdProgress: false,
  addOrgFormSectionProgress: false,
  updateOrgFormSectionProgress: false,
  fetchOrgFormSectionsProgress: false,
  addOrgFormSectionItemProgress: false,
  addOrgFormSectionItemSuccess: null,
  addOrgFormSectionItemError: null,
  updateOrgFormSectionItemProgress: false,
  updateOrgFormSectionItemSuccess: null,
  updateOrgFormSectionItemError: null,
  fetchOrgFormSectionItemProgress: false,
  deleteOrgFormSectionItemProgress: false,
  deleteOrgFormSectionItemError: null,
  deleteOrgFormSectionProgress: false,

  forms: null,
  addOrgFormError: null,
  orgFormData: null,

  orgFormSections: null,
  newwlyAddedOrgSectionData: null,

  formActiveTab: 1,

  usedPlacesDialogOpen: false,
  usedPlacesDialogData: null,

  usedPlaces: [],
  usedPlacesProgress: false,
}

export function orgFormReducer(state = initState, action) {
  switch (action.type) {
    case SHOW_USED_PLACES_DIALOG:
      return {
        ...state,
        usedPlacesDialogOpen: true,
        usedPlacesDialogData: action.payload
      };
    case HIDE_USED_PLACES_DIALOG:
      return {
        ...state,
        usedPlacesDialogOpen: false,
        usedPlacesDialogData: null
      };
    case SHOW_IMPORT_FORM_DIALOG:
      return {
        ...state,
        isShowImportFormDialog: true
      }

    case HIDE_IMPORT_FORM_DIALOG:
      return {
        ...state,
        isShowImportFormDialog: false
      }

    case SHOW_ADD_FORM_DIALOG:
      return {
        ...state,
        isShowAddFormDialog: true
      }

    case HIDE_ADD_FORM_DIALOG:
      return {
        ...state,
        isShowAddFormDialog: false
      }

    case START_ADD_ORG_FORM_DETAILS:
      return {
        ...state,
        addOrgFormProgress: true,
        addOrgFormError: null
      }

    case END_ADD_ORG_FORM_DETAILS:
      return {
        ...state,
        addOrgFormProgress: false,
        addOrgFormError: action.payload.error,
        orgFormData: action.payload.success
      }

    case START_UPDATE_ORG_FORM_DETAILS:
      return {
        ...state,
        updateOrgFormProgress: true,
      }
    case END_UPDATE_ORG_FORM_DETAILS:
      return {
        ...state,
        updateOrgFormProgress: false,
        orgFormData: action.payload.success
      }

    case START_DELETE_ORG_FORM_DETAILS:
      return {
        ...state,
        deleteOrgFormProgress: true,
      }
    case END_DELETE_ORG_FORM_DETAILS:
      return {
        ...state,
        deleteOrgFormProgress: false,
      }

    case START_FETCH_ORG_FORMS:
      return {
        ...state,
        fetchOrgFormsProgress: true,
        forms: null
      }
    case END_FETCH_ORG_FORMS:
      return {
        ...state,
        fetchOrgFormsProgress: false,
        forms: action.payload.success
      }
    case START_FETCH_USED_PLACES:
      return {
        ...state,
        usedPlacesProgress: true,
        usedPlaces: []
      }
    case END_FETCH_USED_PLACES:
      return {
        ...state,
        usedPlacesProgress: false,
        usedPlaces: action.payload.success || []
      }

    case START_FETCH_ORG_FORM_BY_ID:
      return {
        ...state,
        fetchOrgFormsByIdProgress: true,
        orgFormData: null
      }
    case END_FETCH_ORG_FORM_BY_ID:
      return {
        ...state,
        fetchOrgFormsByIdProgress: false,
        orgFormData: action.payload.success
      }

    case START_ADD_ORG_FORM_SECTION:
      return {
        ...state,
        addOrgFormSectionProgress: true,
      }

    case END_ADD_ORG_FORM_SECTION:
      let orgFormSectionDatas = [];
      if (state.orgFormSections !== null) {
        orgFormSectionDatas = [...state.orgFormSections]
      }
      if (action.payload.success !== null) {
        orgFormSectionDatas.push(action.payload.success)
      }
      return {
        ...state,
        addOrgFormSectionProgress: false,
        orgFormSections: orgFormSectionDatas,
        newwlyAddedOrgSectionData: action.payload.success,
      }

    case START_UPDATE_ORG_FORM_SECTION:
      return {
        ...state,
        updateOrgFormSectionProgress: true,
      }
    case END_UPDATE_ORG_FORM_SECTION:
      return {
        ...state,
        updateOrgFormSectionProgress: false,
      }

    case START_FETCH_ORG_FORM_SECTIONS:
      return {
        ...state,
        fetchOrgFormSectionsProgress: true,
        orgFormSections: null
      }
    case END_FETCH_ORG_FORM_SECTIONS:
      return {
        ...state,
        fetchOrgFormSectionsProgress: false,
        orgFormSections: action.payload.success
      }

    case START_DELETE_ORG_FORM_SECTION:
      return {
        ...state,
        deleteOrgFormSectionProgress: true,
      }

    case END_DELETE_ORG_FORM_SECTION:
      return {
        ...state,
        deleteOrgFormSectionProgress: false,
      }

    case START_ADD_ORG_FORM_SECTION_ITEM:
      return {
        ...state,
        addOrgFormSectionItemProgress: true,
        addOrgFormSectionItemError: null,
        addOrgFormSectionItemSuccess: null,
      }
    case END_ADD_ORG_FORM_SECTION_ITEM:
      return {
        ...state,
        addOrgFormSectionItemProgress: false,
        addOrgFormSectionItemError: action.payload.error,
        addOrgFormSectionItemSuccess: action.payload.success,
      }

    case START_UPDATE_ORG_FORM_SECTION_ITEM:
      return {
        ...state,
        updateOrgFormSectionItemProgress: true,
        updateOrgFormSectionItemError: null,
        updateOrgFormSectionItemSuccess: null,
      }
    case END_UPDATE_ORG_FORM_SECTION_ITEM:
      return {
        ...state,
        updateOrgFormSectionItemProgress: false,
        updateOrgFormSectionItemError: action.payload.error,
        updateOrgFormSectionItemSuccess: action.payload.success,
      }

    case START_FETCH_ORG_FORM_SECTION_ITEMS:
      return {
        ...state,
        fetchOrgFormSectionItemProgress: true,
      }
    case END_FETCH_ORG_FORM_SECTION_ITEMS:
      return {
        ...state,
        fetchOrgFormSectionItemProgress: false,
      }

    case START_DELETE_ORG_FORM_SECTION_ITEM:
      return {
        ...state,
        deleteOrgFormSectionItemProgress: true,
        deleteOrgFormSectionItemError: null,
      }
    case END_DELETE_ORG_FORM_SECTION_ITEM:
      return {
        ...state,
        deleteOrgFormSectionItemProgress: false,
        deleteOrgFormSectionItemError: action.payload.error,
      }

    case SET_FORM_ACTIVE_TAB:
      return {
        ...state,
        formActiveTab: action.payload.id
      }



    default:
      return state;
  }
}