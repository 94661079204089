import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import moment from 'moment';

const connectedProps = (state) => ({
    user: state.authUser.user
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    wrapper: {

    },
    title: {
        fontSize: 20,
        padding: 5,
    },
    subTitle: {
        fontSize: 14,
        padding: 5,
        marginBottom: 5,
        color: '#9b9eac'
    }
});

class WelcomeBack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            welcomeMsg: ''
        }
    }

    componentDidMount() {
        let isAlreadyWelcome = window.sessionStorage.getItem("isAlreadyWelcome");
        let welcomeMsg = 'Welcome Back'
        if (isAlreadyWelcome) {
            welcomeMsg = 'Hello'
        } else {
            window.sessionStorage.setItem("isAlreadyWelcome", true);
        }
        this.setState({
            welcomeMsg: welcomeMsg
        })
    }

    componentDidUpdate(prevProps) {

    }

    render() {
        const { classes, user } = this.props;

        return (
            <div className={classes.wrapper}>
                <Typography variant={"h6"} className={classes.title}>
                    {this.state.welcomeMsg}, {user?.Name}
                </Typography>
                <Typography variant={"h6"} className={classes.subTitle}>
                    Today is {moment().format('dddd, Do MMMM YYYY')}
                </Typography>
            </div>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(WelcomeBack));
