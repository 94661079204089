import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DefaultImage from "assets/images/evaluation/default.svg";

class Image extends Component {
  constructor(props) {
    super(props);
    this.state ={
      
    }
  }

  onError = () => {
    this.img.src = DefaultImage
  }

  render() {
    const {
      src,
      ...props
    } = this.props;

   
    return (
      <img
        src={src}
        ref={img => this.img = img}
        onError={this.onError}
        alt={this.props.alt || ''}
        {...props}
      />
    );
  }
}

Image.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
};

export default Image;
