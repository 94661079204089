import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  Typography,
  Button,
  ListItem,
  Popover,
  List,
  Avatar,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import classnames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Logo from "assets/images/logo/logo.svg";

import {
  fetchNotifications,
  markAsRead,
  markAllAsRead,
} from "redux/notification/action";
import { getMessage } from "util/Notification";
import moment from "moment";

const connectedProps = (state) => ({
  user: state.authUser.user,
  fetchNotificationProgress: state.notifications.fetchNotificationProgress,
  fetchNotificationError: state.notifications.fetchNotificationError,
  notifications: state.notifications.notifications,
  totalNotifications: state.notifications.totalNotifications,
  markAsReadProgress: state.notifications.markAsReadProgress,
  toastNotificationCount: state.notifications.toastNotificationCount,
});

const connectionActions = {
  fetchNotifications: fetchNotifications,
  markAsRead: markAsRead,
  markAllAsRead: markAllAsRead,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    notificationDiv: {
      background: "#fff",
      borderRadius: 4,
      minWidth: 360,
    },
    topBar: {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #f7f7f7",
      padding: "16px 20px",
    },
    msgBox: {
      flex: 9,
    },
    title: {
      fontSize: 16,
      fontWeight: 500,
    },
    description: {
      fontSize: 12,
    },
    tickBar: {
      flex: 1,
      cursor: "pointer",
      "& :hover": {
        background: "#f7f7f7",
        borderRadius: 10,
        padding: 2,
      },
    },
    bottomItems: {
      textAlign: "center",
      borderTop: "1px solid #f7f7f7",
    },
    viewAllBtn: {
      width: "100%",
      color: "#1f73b7",
      fontWeight: 500,
      padding: 16,
    },
    micon: {
      fontSize: 16,
      marginTop: 6,
      color: "#4b86f8",
      width: 30,
      height: 30,
      "& :hover": {
        background: "#f7f7f7",
        borderRadius: 10,
      },
    },
    submitMicon: {},
    tooltipMsg: {
      fontSize: "14px",
      whiteSpace: "pre-line",
      display: "inline-block",
      height: "16px",
      lineHeight: "16px",
    },
    notificationList: {
      '& [class*="MuiListItem-root"]': {
        padding: "0px !important",
        display: "block",
        cursor: "pointer",
        // '& :hover': {
        //   background: '#f7f7f7',
        // }
      },
    },
    notiContainer: {
      display: "flex",
      alignItems: "center",
    },
    unreadContainer: {
      background: "#e4f3ff",
    },
    userImage: {
      paddingLeft: 10,
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
        marginRight: 2,
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    notificationItem: {
      padding: "16px 20px",
      width: 300,
      // whiteSpace: 'nowrap',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
      overflowWrap: "break-word",
      wordWrap: "break-word",
      hyphens: "auto",
    },
    notificationTime: {
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      color: "#1876f2",
    },
    scheduleIcon: {
      fontSize: 14,
      color: "#1876f2",
      marginRight: 2,
    },
    timeDiv: {
      marginTop: 10,
    },
    markRead: {},
    dot: {
      height: 10,
      width: 10,
      backgroundColor: "#1876f2",
      borderRadius: "50%",
      display: "inline-block",
    },
    logoImage: {
      width: 25,
      height: 25,
      fontSize: 14,
      marginRight: 2,
      background: "#f7f7f7",
      borderRadius: "50%",
      padding: 5,
    },
  });

class NotificationPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalUnread: 0,
      popoverNotifications: [],
    };
    this.avatarName = this.avatarName.bind(this);
  }

  componentDidMount() {
    this.checkNotifications();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.fetchNotificationProgress === false &&
      prevProps.fetchNotificationProgress === true
    ) {
      if (this.props.fetchNotificationError === null) {
        this.checkNotifications();
      } else {
        // this.props.showSnackBar("Something went wrong to fetch notifications.", "error", 6000)
      }
    }

    if (
      this.props.markAsReadProgress === false &&
      prevProps.markAsReadProgress === true
    ) {
      // this.checkNotifications();
      this.props.fetchNotifications({
        page: 0,
        pageSize: 1000,
        readStatus: "ALL",
      });
    }

    if (
      this.props.toastNotificationCount !== prevProps.toastNotificationCount
    ) {
      this.checkNotifications();
    }
  }

  checkNotifications() {
    var me = this;
    var totalUnread = 0;
    var popoverNotifications = [];
    var unreadNotifications = [];
    if (
      this.props.notifications !== undefined &&
      this.props.notifications !== null &&
      this.props.notifications.length > 0
    ) {
      var allNotifications = this.props.notifications.sort(function (a, b) {
        var aTime = moment(a.timestamp).format("X");
        var bTime = moment(b.timestamp).format("X");
        if (aTime > bTime) {
          return -1;
        } else if (aTime < bTime) {
          return 1;
        } else {
          return 0;
        }
      });
      allNotifications.forEach(function (item, k) {
        if (item.readAt === null) {
          totalUnread++;
          var obj = {
            info: getMessage(item, me.props.user),
            id: item.id,
            data: item.data,
            readAt: item.readAt,
            timestamp: item.timestamp,
          };
          unreadNotifications.push(obj);
        }
        if (k < 5) {
          var obj1 = {
            info: getMessage(item, me.props.user),
            id: item.id,
            data: item.data,
            readAt: item.readAt,
            timestamp: item.timestamp,
          };
          popoverNotifications.push(obj1);
        }
      });
    }
    // if(totalUnread < 5){
    //   var moreNeeded = 5-totalUnread;
    //   popoverNotifications.forEach(function(item,k){
    //     if(k+1 === moreNeeded){
    //       return;
    //     }
    //     unreadNotifications.push(item);
    //   })
    // }

    this.setState({
      totalUnread: totalUnread,
      popoverNotifications: popoverNotifications,
    });
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  render() {
    const classes = this.props.classes;
    const { isOpen } = this.props;
    const { popoverNotifications, totalUnread } = this.state;
    // console.log(popoverNotifications,'popoverNotifications')
    return (
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="profileMenu"
        classes={{ paper: classes.profileMenu }}
        anchorEl={isOpen}
        keepMounted
        elevation={2}
        open={Boolean(isOpen)}
        onClose={() => {
          this.props.onClose();
        }}
      >
        <div className={classes.notificationDiv}>
          <div className={classes.topBar}>
            <div className={classes.msgBox}>
              <Typography className={classes.title}>Notifications</Typography>
              <Typography className={classes.description}>
                You have {totalUnread} unread messages
              </Typography>
            </div>
            {false && (
              <Tooltip
                placement="top"
                title={
                  <span className={classes.tooltipMsg}>Mark all as read</span>
                }
                arrow
              >
                <div className={classes.tickBar}>
                  <CheckIcon
                    className={classnames(classes.micon, classes.submitMicon)}
                  />
                </div>
              </Tooltip>
            )}
          </div>
          <div className={classes.notificationList}>
            <List>
              {popoverNotifications.map((item, k) => {
                // console.log(item,'NotiItem')
                return (
                  <ListItem
                    key={item.id}
                    onClick={() => {
                      // let redirectPath = `/app/notifications`;
                      // this.props.history.push(redirectPath);
                    }}
                  >
                    <div
                      className={classnames(
                        classes.notiContainer,
                        item.readAt === null ? classes.unreadContainer : ""
                      )}
                    >
                      <div className={classes.userImage}>
                        {item.info !== undefined &&
                          item.info !== null &&
                          item.info.user !== undefined &&
                          item.info.user !== null && (
                            <Tooltip
                              placement="top"
                              title={
                                <span className={classes.tooltipMsg}>
                                  {item.info.user.name}
                                </span>
                              }
                              arrow
                            >
                              <Avatar key={k}>
                                {" "}
                                {this.avatarName(item.info.user.name)}
                              </Avatar>
                            </Tooltip>
                          )}
                        {(item.info === undefined ||
                          item.info === null ||
                          item.info.user === undefined ||
                          item.info.user === null) && (
                          <img
                            src={Logo}
                            alt="logo"
                            className={classes.logoImage}
                          />
                        )}
                      </div>
                      <div
                        className={classes.notificationItem}
                        onClick={() => {
                          this.props.history.push(item.info.redirectURL);
                        }}
                      >
                        <Typography variant="inherit">
                          {item.info.message}
                        </Typography>
                        <div className={classes.timeDiv}>
                          <Typography
                            variant="inherit"
                            className={classes.notificationTime}
                          >
                            <ScheduleIcon
                              className={classnames(classes.scheduleIcon)}
                            />
                            {moment
                              .utc(item.timestamp)
                              .local()
                              .startOf("seconds")
                              .fromNow()}
                          </Typography>
                        </div>
                      </div>
                      {item.readAt === null && (
                        <div className={classes.markRead}>
                          <Tooltip
                            placement="top"
                            title={
                              <span className={classes.tooltipMsg}>
                                Mark as read
                              </span>
                            }
                            arrow
                          >
                            <Typography
                              className={classes.dot}
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.markAsRead(item.id);
                              }}
                            ></Typography>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </ListItem>
                );
              })}
            </List>
          </div>
          <div className={classes.bottomItems}>
            <Button
              className={classes.viewAllBtn}
              onClick={() => {
                let redirectPath = `/app/notifications`;
                this.props.history.push(redirectPath);
              }}
            >
              View All
            </Button>
          </div>
        </div>
      </Popover>
    );
  }
}

export default connector(
  compose(withRouter, withStyles(styles))(NotificationPopover)
);
