import {
    START_NOTIFY_ME_FETCH, END_NOTIFY_ME_FETCH,
    RESET_NOTIFY_ME_FETCH, HIDE_CRITERIA_RULE_DIALOG,
    SHOW_CRITERIA_RULE_DIALOG, START_ADD_EVENT, END_ADD_EVENT,
    HIDE_APPROVAL_CRITERIA_RULE_DIALOG, SHOW_APPROVAL_CRITERIA_RULE_DIALOG,
    SHOW_REQUIREMENT_CONDITION_DIALOG, HIDE_REQUIREMENT_CONDITION_DIALOG
} from "./action";
const initState = {
    notifyMeFetchInProgress: false,
    notifyMeFetchResponse: null,
    isCriteriaDialogOpen: false,
    ruleDialog: {},
    addEventProgress: false,

    isApprovalCriteriaRuleDialogOpen: false,
    approvalRuleDialog: {},

    isReqConditionDialogOpen:false,
    requirementCondition: {}
}

export function commonReducer(state = initState, action) {
    switch (action.type) {

        case START_NOTIFY_ME_FETCH:
            return {
                ...state,
                notifyMeFetchInProgress: true,
            };

        case END_NOTIFY_ME_FETCH:
            var response = action.payload?.success;
            let result = null;

            if (response !== null) {
                result = response
            }
            return {
                ...state,
                notifyMeFetchInProgress: false,
                notifyMeFetchResponse: result
            };

        case RESET_NOTIFY_ME_FETCH:
            return {
                ...state,
                notifyMeFetchInProgress: false,
                notifyMeFetchResponse: null
            };
        case HIDE_CRITERIA_RULE_DIALOG:
            return {
                ...state,
                isCriteriaDialogOpen: false,
            };
        case SHOW_CRITERIA_RULE_DIALOG:
            return {
                ...state,
                isCriteriaDialogOpen: true,
                ruleDialog: action.payload
            };

        case START_ADD_EVENT:
            return {
                ...state,
                addEventProgress: true
            }

        case END_ADD_EVENT:
            return {
                ...state,
                addEventProgress: false
            }

        case SHOW_APPROVAL_CRITERIA_RULE_DIALOG:
            return {
                ...state,
                isApprovalCriteriaRuleDialogOpen: true,
                approvalRuleDialog: action.payload
            };

        case HIDE_APPROVAL_CRITERIA_RULE_DIALOG:
            return {
                ...state,
                isApprovalCriteriaRuleDialogOpen: false,
            };

        case SHOW_REQUIREMENT_CONDITION_DIALOG:
            return {
                ...state,
                isReqConditionDialogOpen:true,
                requirementCondition: action.payload
            }

        case HIDE_REQUIREMENT_CONDITION_DIALOG:
            return {
                ...state,
                isReqConditionDialogOpen:false,
                requirementCondition: {}
            }


        default:
            return state;
    }
}