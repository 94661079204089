import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Button, Slide, Typography, Grid } from '@material-ui/core';
import OutlinedInput from "Components/Common/Input/OutlinedInput"
import * as Validator from "util/Validation";
import Alert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router';

import { hideAddNewUserDialog, addSellerColloborator,addSellerRequirementColloborator,hideSellerCollaboratorDialog } from "redux/seller/action";

//redux 

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    addTeamDialogOpen: state.seller.addNewCollaboratorDialogOpen,
    selectedRequirementId: state.seller.selectedRequirementId,
});

const connectionActions = {
    hideAddNewUserDialog: hideAddNewUserDialog,
    addSellerColloborator: addSellerColloborator,
    addSellerRequirementColloborator:addSellerRequirementColloborator,
    hideSellerCollaboratorDialog:hideSellerCollaboratorDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important"
    },
    formControl: {
        marginBottom: theme.spacing(2),
        "& [class*='MuiSelect-outlined-']": {
            color: '#5F5F5F !important',
        },
        '& .select__value-container': {
            padding: '12px',
            '& .select__single-value, .select__placeholder': {
                color: '#5F5F5F',
                fontSize: '17px'
            }
        }
    },
    inputLabel: {
        marginBottom: theme.spacing(1)
    },
    "formAction": {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: "center",
    },
    title: {
        fontSize: theme.spacing(2.5),
        marginBottom: theme.spacing(2)
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    loader: {
        textAlign: 'center'
    },
    editEmail: {
        "& [class*='MuiOutlinedInput-root'] *": {
            border: 'none'
        },
        "& > div": {
            marginBottom: 0
        },
        "& [class*='MuiOutlinedInput-input']": {
            padding: 0
        },
        "& [class*='MuiOutlinedInput-inputRoot']": {
            minHeight: 30
        },
    }
});

class AddUserDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "name": "",
            "name_error": "",
            'email': "",
            "email_error": "",
        }
        this.clearError = this.clearError.bind(this);
        this.addUser = this.addUser.bind(this);
    }

    componentWillMount() {
        this.setUserValues()
    }

    setUserValues = () => {
        this.setState({
            "name": this.props.editValue.name || "",
            "name_error": "",
            'email': this.props.editValue.email || "",
            "email_error": "",
            "role": this.props.editValue.role || "OrgUser",
            "role_error": "",
            "team": this.props.editValue.team == null ? [] : this.props.editValue.team.map((g) => {
                return {
                    value: g.ID,
                    label: g.Name
                }
            }),
            "title": this.props.editValue.title || "",
            "error": "",
            "id": this.props.editValue.id || ""
        });
    }


    componentDidUpdate(prevProps) {
        if (this.props.addTeamDialogOpen === true && prevProps.addTeamDialogOpen === false) {
            this.setState({
                "name": this.props.editValue.name || "",
                "name_error": "",
                'email': this.props.editValue.email || "",
                "email_error": "",
                "role": this.props.editValue.role || "OrgUser",
                "role_error": "",
                "team": this.props.editValue.team === null ? [] : this.props.editValue.team.map((g) => {
                    return {
                        value: g.ID,
                        label: g.Name
                    }
                }),
                "title": this.props.editValue.title || "",
                "error": "",
                "id": this.props.editValue.id || ""
            });
        }
    }

    clearError() {
        this.setState({
            "name_error": "",
            "email_error": "",
            "role_error": "",
        });
    }

    addUser() {
        var me = this;
        me.clearError();
        var name = me.state.name;
        if (name === null || String(name).trim().length === 0) {
            me.setState({ name_error: "Please provide  name." });
            return;
        }

        var email = me.state.email;
        if (email === null || String(email).trim().length === 0) {
            me.setState({ email_error: "Please provide  email." });
            return;
        }

        if (Validator.validateEmail(email) === false) {
            me.setState({ email_error: "Please provide valid email." });
            return;
        }

        var parameters = {
            'name': me.state.name,
            'email': me.state.email,
        }

        if (this.props.addUserType === "SELLER_COLLOBORATOR") {
            this.props.addSellerColloborator(this.props.match.params.uuid, this.props.match.params.org_id, parameters);
            this.props.hideAddNewUserDialog();
            this.props.hideSellerCollaboratorDialog();
        } else if(this.props.addUserType === "SELLER_REQ_COLLOBORATOR"){
            this.props.addSellerRequirementColloborator(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId, parameters)
            this.props.hideAddNewUserDialog();
            this.props.hideSellerCollaboratorDialog();
        }

    }



    render() {
        const classes = this.props.classes;

        return <Dialog
            onClose={this.props.hideAddNewUserDialog}
            aria-labelledby="add-teamDialog"
            open={this.props.addTeamDialogOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll={"body"}
            id={"addUserDialog"}
        >

            <DialogContent classes={{ root: classes.dialogContent }}>
                <Typography variant={"h6"} className={classes.title}> {this.props.isEdit === false ? 'Add New User' : 'Edit User'}</Typography>
                <div className={classes.alert}>
                    {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={12}>
                        <OutlinedInput
                            id={"nameInput"}
                            label="Name"
                            type="text"
                            required={true}
                            value={this.state.name || ''}
                            onChange={(event) => {
                                this.setState({ name: event.target.value });
                            }}
                            error={this.state.name_error.length > 0}
                            helperText={this.state.name_error}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} className={this.props.isEdit ? classes.editEmail : ''}>
                        <OutlinedInput
                            id={"emailInput"}
                            label="Email"
                            type="text"
                            required={true}
                            disabled={this.props.isEdit}
                            value={this.state.email || ''}
                            onChange={(event) => {
                                this.setState({ email: event.target.value });
                            }}
                            error={this.state.email_error.length > 0}
                            helperText={this.state.email_error}
                        />
                    </Grid>
                </Grid>

                <div className={classes.formAction}>
                    <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideAddNewUserDialog}>Cancel</Button>
                    &nbsp;
                    &nbsp;
                    <Button variant="contained" color="secondary" className={classes.button} onClick={this.addUser}>Submit</Button>
                </div>
            </DialogContent>
        </Dialog>
    }
}

AddUserDialog.defaultProps = {
    isFromSettings: false,
    isEdit: false,
    editValue: { name: '', email: '', team: '', role: '', id: '', title: '' }
};

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AddUserDialog));