import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { InputLabel,FormControl,Grid,Button,Divider, CircularProgress,Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ReactSelect from 'react-select'
import {fetchVendorEvaluation} from "redux/evaluate/action"
import {updateEvaluationMetaData} from "redux/evaluation/metadata/action"
import {fetchParentEvaluation} from "redux/evaluation/link/action"


import EvaluationCard from "./EvaluationCard"
import Alert from '@material-ui/lab/Alert';
const connectedProps = (state) => ({
  isOpen:state.evaluationLink.showDialog,
  evaluations:state.evaluate.vendorEvaluations.Data==undefined?[]:state.evaluate.vendorEvaluations.Data,
  currentEvaluation:state.evaluationMetaData.evaluation,
  parentfetchProgress:state.evaluationLink.parentfetchProgress,
  parentEvaluation:state.evaluationLink.parentEvaluation,
  updateProgress:state.evaluationMetaData.updateProgress,
  updateError:state.evaluationMetaData.errorUpdate,
});

const connectionActions = {
  fetchEvaluation:fetchVendorEvaluation,
  updateEvaluationMetaData:updateEvaluationMetaData,
  fetchParentEvaluation:fetchParentEvaluation,
}



var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  parentForm:{
    padding:theme.spacing(3)
  },
  inputLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight:600
  },
  formControl: {
    marginBottom: theme.spacing(1)
  },
  alerts:{
    marginBottom:theme.spacing(2)
},
});

class Parent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          searchQuery:"",
          error:"",
          success:"",
          evaluation:null
        }
        this.updateAsParent   =   this.updateAsParent.bind(this);
        this.fetchParentEvaluation = this.fetchParentEvaluation.bind(this);
    }

    componentDidUpdate(prevProps){
      if(this.props.isOpen && prevProps.isOpen == false){
        this.fetchParentEvaluation();
      }
      if(this.props.updateProgress === false && prevProps.updateProgress === true){
        if(this.props.updateError == null){
          this.fetchParentEvaluation();
        }
      }
    }

    componentDidMount(){
      this.fetchEvaluation();
      this.fetchParentEvaluation();
    }

    fetchParentEvaluation(){
      if(this.props.currentEvaluation !== null){
        this.props.fetchParentEvaluation(this.props.currentEvaluation.ParentEvaluationID)
      }
    }

    fetchEvaluation(){
      this.props.fetchEvaluation({
        query:this.state.searchQuery
      })
    }

    updateAsParent(){
      this.setState({"error":"","success":""});
      if(this.state.evaluation === null){
        this.setState({"error":"Please select evaluation."});
        return
      }

      this.props.updateEvaluationMetaData(this.props.currentEvaluation.ID,{
        parent_evaluation_id:this.state.evaluation.value
      })
    }

    render() {
        const classes   =   this.props.classes;
        
        return <div className={classes.parentForm}>
                    <div className={classes.alerts}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                        {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                    </div>
                    
                    
                    {!this.props.parentfetchProgress && this.props.parentEvaluation !== null && <>
                        <Typography >Parent Evaluation</Typography>
                        <EvaluationCard evaluation={this.props.parentEvaluation} onDelete={()=>{
                          this.props.updateEvaluationMetaData(this.props.currentEvaluation.ID,{
                            parent_evaluation_id:null
                          })
                        }}/>
                      </>}
                    {!this.props.parentfetchProgress && this.props.parentEvaluation === null && <Typography >No Parent Evaluation Found</Typography>}
                    {this.props.parentfetchProgress && <CircularProgress/>}
                    <Divider/>
                    <Grid container spacing={2}>
                      <Grid item lg={8}>
                          <InputLabel id="teamlabel" className={classes.inputLabel}>Update Parent Evaluation</InputLabel>
                          <FormControl fullWidth variant="outlined" className={classes.formControl}>
                              <ReactSelect
                                  isClearable
                                  isSearchable={true}
                                  options={this.props.evaluations.map((item)=>{
                                      return {
                                          value: item.ID,
                                          label: item.Name +" ["+item.Products.map((p)=>{
                                            return p.Name
                                          }).join(" | ")+"]",
                                          data:item
                                      }
                                  })}
                                  classNamePrefix="select"
                                  placeholder="Search Evaluation" 
                                  onInputChange={(e) => {
                                      this.setState({
                                          searchQuery:e
                                      },()=>{
                                          this.fetchEvaluation()
                                      })
                                      
                                  }}
                                  isLoading={this.props.fetchGroupProg }
                                  onChange={(e,action)=>{
                                      console.log('e',e)
                                      this.setState({
                                        evaluation:e
                                      })
                                  }}
                                  menuPortalTarget={document.getElementById("evLinkDialog")} 
                                  defaultValue={this.state.evaluation}
                                  value={this.state.evaluation}
                              />
                          </FormControl>
                      </Grid>
                      <Grid item lg={4}>
                        <InputLabel id="teamlabel" className={classes.inputLabel} style={{"visibility":"hidden"}}>&nbsp;</InputLabel>
                        {!this.props.updateProgress && <Button variant="contained" color="secondary"  fullWidth onClick={this.updateAsParent}>Update as Parent</Button>}
                        {this.props.updateProgress && <CircularProgress/>}
                      </Grid>
                    </Grid>
                </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Parent));