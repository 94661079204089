import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import AppBarV2 from "Components/Application/AppBarV2";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import SearchInput from "Components/Common/Input/SearchInput";
// redux
import {
  productSearch,
  fetchProductReaction,
  fetchSubCategoryWiseProducts,
  fetchCategoryWiseProducts,
  fetchWishListedProducts,
} from "redux/product/action";
import { updateDrawerStyle } from "redux/drawer/action";
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import {
  createEvaluationCart,
  removeProductFromCart,
  fetchEvaluationCart,
} from "redux/evaluation/cart/action";
import { fetchCategorySubCategory } from "redux/product/subcategory/action";
// Dilogs
import CommonCss from "commonCss";
import ProductTypeFilter from "Components/Common/Filters/ProductTypeFilter";

const connectedProps = (state) => ({
  productSearchData: state.products.productSearchData,
  evaluationCart: state.evaluationCart.evaluation,
  productCategory: state.products.productCategory,
  subCategories: state.productSubCategory.catSubCategories,
  subCategoryTotal: state.productSubCategory.catSubCategoryTotal,
  catSubCategoryProgress: state.productSubCategory.catSubCategoryProgress,
  productFilters: state.products.productFilters,
  productsRec: state.products,
  fetchProductReactionByTypeProgress:
    state.products.fetchProductReactionByTypeProgress,
  deleteProductStateProg: state.products.deleteProductStateProg,
  addProductStateProg: state.products.addProductStateProg,
  allProductfilterData: state.products.allProductfilterData,
});

const connectionActions = {
  productSearch: productSearch,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  fetchProductReaction: fetchProductReaction,
  updateDrawerStyle: updateDrawerStyle,
  fetchCategorySubCategory: fetchCategorySubCategory,
  fetchEvaluationCart: fetchEvaluationCart,
  fetchProducts: fetchSubCategoryWiseProducts,
  fetchCategoryWiseProducts: fetchCategoryWiseProducts,
  fetchWishListedProducts: fetchWishListedProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 2),
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
    },
    productContainer: {
      maxHeight: "calc(100vh - 230px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 230px)",
      padding: theme.spacing(4, 0),
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    label: {
      fontSize: "1.4em",
      fontWeight: 600,
      lineHeight: "1.6",
      marginBottom: 10,
    },
    productMain: {
      flex: 1,
    },
    productHead: {
      ...CommonCss.productHead,
      fontSize: 18,
      margin: "0px !important",
    },
    productMenu: {
      background: "#FFFFFF",
      boxShadow: "0px 2px 10px #0000000B",
      padding: theme.spacing(4, 0, 4, 2.8),
      width: 270,
      minHeight: "100vh",
      position: "fixed",
    },
    menuHead: {
      display: "flex",
      marginBottom: 18,
    },
    container: {
      display: "flex",
    },
    topContainer: {
      marginTop: theme.spacing(3),
    },
    headSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterSection: {
      ...CommonCss.filterSection,
      marginRight: 20,
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
    },
    title: {
      fontSize: theme.spacing(2.4),
      flex: 1,
    },
    categoriesText: {
      color: "#000000",
      fontSize: 18,
      marginLeft: 16,
      fontWeight: 500,
    },
    categoriesList: {
      marginTop: theme.spacing(6.4),
      minHeight: 200,
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    main: {
      maxWidth: "calc(100% - 280px)",
      flexBasis: "calc(100% - 280px)",
    },
    subCategory: {
      height: "calc(100vh - 120px)",
      overflowY: "overlay",
    },
    innerText: {
      color: "#696B6E",
      padding: theme.spacing(1, 1.5, 1, 1),
      cursor: "pointer",
      display: "block",
      margin: 0,
    },
    "@global": {
      ".applicationContent": {
        padding: "0 !important",
      },
    },
    products: {
      display: "flex",
    },
    activeSubCategory: {
      color: "#4A87F8",
      background: "#F2F7FF",
      fontSize: 13,
      marginRight: theme.spacing(3.2),
    },
    emptyMessage: {
      textAlign: "center",
      flex: 1,
      "& p": {
        margin: 5,
      },
    },
    suggestText: {
      fontWeight: 600,
    },
    filter: {
      // flex: 1,
      textAlign: "right",
    },
    txtCenter: {
      textAlign: "center",
      marginBottom: 16,
    },
    subCategories: {
      background: '#fff',
      maxWidth: 250,
      padding: theme.spacing(1, 0, 1, 2)
    }
  });

class CategoryWiseHardwareProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      items: [],
      page: 0,
      pageSize: 60,
      subCategoryPageSize: 500,
      subCategoryPage: 0,
      activeSubCategoryId: null,
      activeSubCategory: {},
      subCatsClicked: false,
      headerSectionWidth: null,
      queryParams: queryString.parse(this.props.location.search),
      checkBoxFilterStatus: {
        featured: false,
        wishlisted: false,
        org_wishlisted: false,
        all_product_blocking: false,
      },
      isCheckBoxFilter: false,
      selectedCheckBoxFilter: "",
      filters: {},
      selectedState: [],
      scrollToSubcat: true,
      searchObj: {
        search_name: "all_products",
        type: "all",
        page: 0,
        size: 100,
        text: "",
        filter_fields_value: {},
        sort_field: null,
        ascending: null,
      },
      productType: 'HARDWARE',
      productSearchData: {
        "aggregations": {
          "features": [
            [
              "61",
              "Billing & Invoicing"
            ],
            [
              "105",
              "Compliance Management"
            ],
            [
              "502",
              "Risk Management"
            ],
            [
              "4966",
              "Online payment portal"
            ],
            [
              "64",
              "Document Management"
            ],
            [
              "5",
              "Reporting/Analytics"
            ],
            [
              "425",
              "Expense Management"
            ],
            [
              "234",
              "Inventory Management"
            ],
            [
              "576",
              "Electronic Signature"
            ],
            [
              "892",
              "Auditing"
            ],
            [
              "70",
              "Accounts Receivable"
            ],
            [
              "2604",
              "Discount Management"
            ],
            [
              "81",
              "Tax Management"
            ],
            [
              "520",
              "Barcode Scanning"
            ],
            [
              "1647",
              "Customer Account Profiles"
            ],
            [
              "555",
              "Multi-Currency"
            ],
            [
              "85",
              "Cash Management"
            ],
            [
              "342",
              "Audit Trail"
            ],
            [
              "556",
              "Online Invoicing"
            ],
            [
              "558",
              "Recurring/Subscription Billing"
            ],
            [
              "298",
              "Multi-Location"
            ],
            [
              "189",
              "Mobile Device Management"
            ],
            [
              "551",
              "Customizable Invoices"
            ],
            [
              "240",
              "Returns Management"
            ],
            [
              "71",
              "Bank Reconciliation"
            ]
          ],
          "platforms": [
            [
              "1",
              "Web"
            ],
            [
              "2",
              "Cloud"
            ],
            [
              "4",
              "Windows"
            ],
            [
              "5",
              "iPhone/iPad"
            ],
            [
              "3",
              "Android"
            ]
          ]
        },
        "org_products": [
          {
            "access_link": null,
            "company_name": "Apple",
            "description": "MacBook Pro delivers groundbreaking processing, graphics, and machine learning (ML) performance whether running on battery or plugged in, as well as amazing battery life - enabling workflows previously unimaginable on a notebook. It also features a stunning Liquid Retina XDR display, a wide range of ports for advanced connectivity, a 1080p FaceTime HD camera, and great audio system. Shattering the limits of what a notebook can do, MacBook Pro is designed for developers, photographers, filmmakers, 3D artists, scientists, music producers, and anyone who wants a powerful notebook.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6738975c?$product-detail$",
            "name": "Apple MacBook Pro",
            "product_id": "160",
            "score": 1,
            "sort_score": null,
            "states": {},
            "type": "1"
          },
          {
            "access_link": null,
            "company_name": "Acer",
            "description": "This workhorse has the security and speed demanded by the modern business person as they work and play throughout the day. Work on and share documents in comfort with the professional design features which make working a little easier.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6314278?$product-200x144$",
            "name": "Acer TravelMate Spin P4",
            "product_id": "35930",
            "score": 1,
            "sort_score": null,
            "states": {},
            "type": "1"
          },
          {
            "access_link": null,
            "company_name": "Boxx",
            "description": "Bring CAD design and motion media to completion faster with the BOXX GoBOXX SLM 17 Notebook Computer. This workstation laptop is perfect for a variety of professional applications. Driven by the Intel® Core™ i7-11800H processor and 32 GB of RAM, the GoBOXX SLM provides ample performance and reliability, empowering you to work from anywhere. A storage capacity of 1 TB accommodates the OS, data and software.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6801621a?$product-detail$",
            "name": "BOXX GoBOXX",
            "product_id": "160",
            "score": 1,
            "sort_score": null,
            "states": {},
            "type": "1"
          }
        ],
        "products": [
          {
            "company_name": "Dell",
            "description": "Dell Latitude 5420 |CORE_I5_11-1145_G7|8GB (1DIMMS)|256GB SS|TCH|1920x1080|WLS|W10|4C|14.0in.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6404350a?$product-detail$",
            "name": "Dell Latitude 5420 - 14",
            "product_id": "35930",
            "score": 2.135286,
            "sort_score": null,
            "states": {}
          },
          {
            "company_name": "HP",
            "description": "The sleek, thin, and light HP EliteBook x360 1040 transforms to the workstyle of your choice. A powerful processor, wireless connections, and strong security help keep you safely connected with coworkers and clients.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6688896d?$product-detail$",
            "name": "HP EliteBook",
            "product_id": "35930",
            "score": 2.11662,
            "sort_score": null,
            "states": {}
          },
          {
            "company_name": "Lenovo",
            "description": "Premium processing on the Intel Evo platformStunning clarity and vibrant sound optimized with Dolby Vision Ease of use– features best-in-class connectivity& incredible battery lifeAI-optimized performance that adapts to your needsUltralight& portable, you can rely at home and on the road.",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/6738284?$product-detail$",
            "name": "Lenovo IdeaPad",
            "product_id": "35930",
            "score": 2.1035645,
            "sort_score": null,
            "states": {}
          },
          {
            "company_name": "Cherry",
            "description": "MFG.PART: G80-11900LUMEU-0 CDW PART: 973876 UNSPSC: 43211706",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/973876?$product-detail$",
            "name": "Cherry TouchBoard G80-11900",
            "product_id": "160",
            "score": 2.1035645,
            "sort_score": null,
            "states": {}
          },
          {
            "company_name": "Adesso",
            "description": "Adesso Tru-Form Media Contoured Ergonomic Keyboard with Hot Keys",
            "logo": "https://webobjects2.cdw.com/is/image/CDW/947770?$product-detail$",
            "name": "Adesso Tru-Form",
            "product_id": "160",
            "score": 2.1035645,
            "sort_score": null,
            "states": {}
          }
        ],
        "total_products": 6935
      }
    };
  }

  componentDidMount() {
    this.props.fetchWishListedProducts();
    this.props.fetchCategorySubCategory(
      this.props.match.params.category_id,
      this.state.page,
      this.state.subCategoryPageSize,
      this.state.query
    );
    const urlPrams = new URLSearchParams(this.props.location.search);
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);

    var evId = urlPrams.get("evaluationId");
    if (evId !== null) {
      setTimeout(() => {
        this.props.fetchEvaluationCart(evId);
      }, 1000);
    }
    if (urlPrams.get("subcategory") !== null) {
      this.setState(
        {
          activeSubCategoryId: parseInt(urlPrams.get("subcategory")),
        },
        () => {
          this.props.fetchProducts(
            this.props.match.params.category_id,
            this.state.activeSubCategoryId,
            this.state.page,
            this.state.pageSize,
            "",
            {
              agg: true,
              filters: this.props?.allProductfilterData?.filters,
            }
          );
        }
      );
    } else {
      this.props.fetchCategoryWiseProducts(
        this.props.match.params.category_id,
        this.state.page,
        this.state.pageSize,
        "",
        false,
        {
          agg: true,
          filters: this.props?.allProductfilterData?.filters,
        }
      );
    }
    if (urlPrams.get("s") !== null) {
      this.setState(
        {
          searchObj: { ...this.state.searchObj, text: urlPrams.get("s") },
        },
        () => this.fetchData()
      );
    } else {
      this.fetchData();
    }
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.isSeller &&
      !this.props.fetchProductReactionByTypeProgress &&
      prevProps.fetchProductReactionByTypeProgress
    ) {
      var reactionProducts = this.props.productsRec?.wishlistProduct.Data || [];
      if (reactionProducts.length > 0) {
        const productIDs = reactionProducts.map((o) => o.ID);
        this.getProductReactions(productIDs);
      }
    }

    const urlPrams = new URLSearchParams(this.props.location.search);
    if (urlPrams.get("subcategory") !== null && this.state.scrollToSubcat) {
      const target = document.getElementById(urlPrams.get("subcategory"));
      const parent = document.getElementById("subcat-container");
      if (target && parent) {
        parent.scrollTop = target.offsetTop - parent.offsetTop;
        this.setState({ scrollToSubcat: false });
      }
    }
    this.updateDimensions();
  }

  fetchProductsData = () => {
    this.props.fetchProducts(
      this.props.match.params.category_id,
      this.state.activeSubCategoryId,
      this.state.page,
      this.state.pageSize,
      this.state.query,
      {
        agg: true,
        filters: this.props.allProductfilterData.filters,
      }
    );
    this.props.fetchCategoryWiseProducts(
      this.props.match.params.category_id,
      this.state.page,
      this.state.pageSize,
      this.state.query,
      false,
      {
        agg: true,
        filters: this.props.allProductfilterData.filters,
      }
    );
  };

  getProductReactions(productIDs) {
    this.props.fetchProductReaction("all_product_blocking", {
      productIDs,
      source: "all_product_blocking",
      sourceData: "all_product_blocking",
    });
  }

  updateDimensions = () => {
    const headerSectionWidth = this.divElement.clientWidth;
    if (this.state.headerSectionWidth !== headerSectionWidth) {
      this.setState({ headerSectionWidth });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  fetchData() {
    const { searchObj, filters, selectedState } = this.state;
    searchObj.filter_fields_value = {};
    if (filters?.feature_ids && filters?.feature_ids.length > 0) {
      searchObj.filter_fields_value["feature_ids"] = filters?.feature_ids;
    }
    if (filters?.platform_ids && filters?.platform_ids.length > 0) {
      searchObj.filter_fields_value["platform_ids"] = filters?.platform_ids;
    }
    if (selectedState && selectedState.length > 0) {
      searchObj.filter_fields_value["state"] = selectedState;
    }
    if (this.props.match.params.category_id) {
      searchObj.filter_fields_value["category_ids"] = [
        parseInt(this.props.match.params.category_id),
      ];
    }
    const urlParams = new URLSearchParams(this.props.location.search);
    if (urlParams.get("subcategory") !== null) {
      searchObj.filter_fields_value["subcategory_ids"] = [
        parseInt(urlParams.get("subcategory")),
      ];
    }
    this.props.productSearch(searchObj);
  }

  addToCart(item) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(parseInt(item.product_id));
    } else {
      var addedToCart =
        this.props.evaluationCart == null
          ? []
          : this.props.evaluationCart.Products.map((p) => p.ID);
      if (addedToCart.indexOf(parseInt(item.product_id)) !== -1) {
        this.props.removeProductFromCart(
          this.props.evaluationCart.ID,
          parseInt(item.product_id)
        );
      } else {
        this.props.createEvaluationCart(
          parseInt(item.product_id),
          this.props.evaluationCart.ID
        );
      }
    }
  }

  productStates = (obj) => {
    if (obj) {
      const data = Object.keys(obj).map((key) => ({
        StateName: obj[key],
        StateID: parseInt(key),
      }));
      return data;
    } else {
      return [];
    }
  };

  setSearchType = (type) => {
    const searchObj = this.state.searchObj;
    if (type === "features") {
      searchObj.type = "features";
    } else if (type === "subcategories") {
      searchObj.type = "subcategories";
    }
    searchObj.page = 0;
    searchObj.text = "";
    this.setState({ searchObj }, () => {
      this.fetchData();
    });
  };

  handleOnClickState(newState) {
    let selectedState = this.state.selectedState;
    if (selectedState.includes(newState)) {
      selectedState = selectedState.filter((o) => o !== newState);
    } else {
      selectedState.push(newState);
    }
    this.setState(
      { selectedState, searchObj: { ...this.state.searchObj, page: 0 } },
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    const {
      classes,
      isSeller,
    } = this.props;

    const { productSearchData } = this.state;
    console.log(productSearchData, 'productSearchData')

    const { searchObj, headerSectionWidth } = this.state;
    var addedToCart =
      this.props.evaluationCart == null
        ? []
        : this.props.evaluationCart.Products.map((p) => p.ID);
    let spliter = 5;
    if (headerSectionWidth < 768) spliter = 1;
    if (headerSectionWidth >= 768 && headerSectionWidth < 1280) spliter = 3;
    if (headerSectionWidth >= 1280 && headerSectionWidth <= 1365) spliter = 4;

    const totalCount = 0;

    return (
      <>
        <div className={classes.container}>
          <div className={classes.productMain}>
            <AppBarV2 title={'Hardware Products'} withBack={true} dropdown={true} />
            <div className={classes.root}>
              <div className={classes.stickyBar}>
                <Grid
                  container
                  justifyContent="space-between"
                  ref={(divElement) => {
                    this.divElement = divElement;
                  }}
                  className={classes.productSectionNew}
                >
                  <Grid item>
                    {false && <p className={classes.productCount}>
                      {totalCount.toLocaleString("en-US")}
                      {totalCount > 1 ? " Products" : " Product"} found
                    </p>}
                  </Grid>
                  <Grid item>
                    {process.env.REACT_APP_ENV === 'dev' && <ProductTypeFilter
                      type={this.state.productType}
                      onChange={() => {
                        let redirectPath = `/app/products/category-wise-product/1`;
                        this.props.history.push(redirectPath);
                      }}
                    />}
                    <SearchInput
                      page="category_wise_product"
                      searchIcon={false}
                      filters={this.state.filters}
                      showState={!isSeller}
                      selectedState={this.state.selectedState}
                      onChangeState={(newState) =>
                        this.handleOnClickState(newState)
                      }
                      onChangeFilter={(filters) => {
                        this.setState(
                          {
                            filters,
                            searchObj: { ...searchObj, page: 0 },
                          },
                          () => {
                            this.fetchData();
                          }
                        );
                      }}
                      searchObj={this.state.searchObj}
                      onChange={(searchObj) => {
                        this.setState(
                          {
                            searchObj,
                          },
                          () => {
                            this.fetchData();
                          }
                        );
                      }}
                    />

                  </Grid>
                  <Grid item>
                    {/* <AddProductButton /> */}
                  </Grid>
                </Grid>
              </div>
              <div className={classes.productContainer} id="productContainer">
                
                <InfiniteScroll
                  scrollableTarget="productContainer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    marginRight: 20,
                  }}
                  dataLength={productSearchData?.products?.length || 0}
                  next={() => {
                    this.setState(
                      {
                        searchObj: { ...searchObj, page: searchObj.page + 1 },
                      },
                      () => {
                        this.fetchData();
                      }
                    );
                  }}
                  hasMore={false}
                  endMessage={""}
                >
                  {!isSeller &&
                    productSearchData?.org_products &&
                    productSearchData?.org_products.length > 0 && (
                      <>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography className={classes.label}>
                            Existing Products
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          style={{ marginBottom: 10 }}
                        >
                          {productSearchData.org_products
                            .slice(0, spliter)
                            .map((product, index) => (
                              <Grid
                                item
                                lg={2}
                                md={3}
                                sm={4}
                                xs={6}
                                key={"product_" + parseInt(product.product_id)}
                              >
                                <LayeredCard
                                  isActionButtons
                                  title={
                                    product.name || product.company_name || ""
                                  }
                                  subTitle={
                                    product.company_name || product.name || ""
                                  }
                                  description={product.description || ""}
                                  image={product.logo || ""}
                                  toogleCheck={() => {
                                    this.addToCart(product);
                                  }}
                                  IsExisting
                                  onClick={() => { }}
                                  numLayer={0}
                                  checked={addedToCart.indexOf(index) !== -1}
                                  showCheckbox={true}
                                  id={parseInt(product.product_id)}
                                  uniqueId={"_orgj_" + index}
                                  type={"product"}
                                  productStates={this.productStates(
                                    product.states
                                  )}
                                  onClickState={(state) =>
                                    this.handleOnClickState(state)
                                  }
                                  source={"BROWSE"}
                                  showReactionResponses
                                  typeOfBlock="all_product_blocking"
                                  typeOfFeatured="featured"
                                  customRoute={`/app/products/org-hardware-product-details/`}


                                />
                              </Grid>
                            ))}
                        </Grid>
                      </>
                    )}
                  {productSearchData?.products &&
                    productSearchData?.products.length > 0 && (
                      <>
                        {!isSeller && (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography className={classes.label}>
                              Other Products
                            </Typography>
                          </Grid>
                        )}
                        <Grid container spacing={3}>
                          {productSearchData.products.map((product, index) => (
                            <Grid
                              item
                              lg={2}
                              md={3}
                              sm={4}
                              xs={6}
                              key={"product_" + parseInt(product.product_id)}
                            >
                              <LayeredCard
                                isActionButtons={!isSeller}
                                title={
                                  product.name || product.company_name || ""
                                }
                                subTitle={
                                  product.company_name || product.name || ""
                                }
                                description={product.description || ""}
                                image={product.logo || ""}
                                toogleCheck={() => {
                                  this.addToCart(product);
                                }}
                                onClick={() => { }}
                                numLayer={0}
                                checked={addedToCart.indexOf(index) !== -1}
                                showCheckbox={true}
                                id={parseInt(product.product_id)}
                                uniqueId={"_j_" + index}
                                type={"product"}
                                productStates={this.productStates(
                                  product.states
                                )}
                                onClickState={(state) =>
                                  this.handleOnClickState(state)
                                }
                                source={isSeller ? "SELLER" : "BROWSE"}
                                showReactionResponses
                                typeOfBlock="all_product_blocking"
                                typeOfFeatured="featured"
                                customRoute={`/app/products/hardware-product-details/`}

                              />
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                  
                </InfiniteScroll>
              </div>
              
            </div>
          </div>
        </div>
      </>
    );
  }
}

CategoryWiseHardwareProducts.defaultProps = {
  isSeller: false,
};

export default connector(
  compose(withStyles(styles), withLastLocation)(CategoryWiseHardwareProducts)
);
