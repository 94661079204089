import {
  START_FETCH_USER_PROFILE_META, END_FETCH_USER_PROFILE_META,
  START_FETCH_DEPARTMENTS, END_FETCH_DEPARTMENTS,
  START_GET_DEPARTMENT, END_GET_DEPARTMENT,
  START_GET_DEPARTMENT_APPROVERS, END_GET_DEPARTMENT_APPROVERS,
  START_ADD_DEPARTMENT_APPROVER, END_ADD_DEPARTMENT_APPROVER,
  START_DELETE_DEPARTMENT_APPROVER, END_DELETE_DEPARTMENT_APPROVER,
  START_UPDATE_DEPARTMENT_APPROVER, END_UPDATE_DEPARTMENT_APPROVER,
  START_FETCH_FIELD_OPTIONS, END_FETCH_FIELD_OPTIONS
} from "./action";


// Initial State
const initState = {
  fetchUserMetaProgress: false,
  fetchUserMetaError: null,
  userProfileMeta: [],

  fetchDepartmentsProgress: false,
  fetchDepartmentsError: null,
  departments: [],

  getDepartmentProgress: false,
  getDepartmentError: null,
  departmentData: {},

  getDepartmentApproversProgress: false,
  getDepartmentApproversError: null,
  approvers: [],

  addDepartmentApproverProgress: false,
  addDepartmentApproverError: null,

  deleteDepartmentApproverProgress: false,
  deleteDepartmentApproverError: null,

  updateDepartmentApproverProgress: false,
  updateDepartmentApproverError: null,

  fetchFieldOptionsProgress: false,
  fetchFieldOptionsError: null,
  fieldOptions: [],
}

export function departmentsReducers(state = initState, action) {
  switch (action.type) {

    case START_FETCH_USER_PROFILE_META:
      return {
        ...state,
        fetchUserMetaProgress: true,
        fetchUserMetaError: null,
        userProfileMeta: [],
      }

    case END_FETCH_USER_PROFILE_META:
      return {
        ...state,
        fetchUserMetaProgress: false,
        fetchUserMetaError: action.payload.error,
        userProfileMeta: action.payload.success,
      }

    case START_FETCH_FIELD_OPTIONS:
      return {
        ...state,
        fetchFieldOptionsProgress: true,
        fetchFieldOptionsError: null,
        fieldOptions: [],
      }

    case END_FETCH_FIELD_OPTIONS:
      return {
        ...state,
        fetchFieldOptionsProgress: false,
        fetchFieldOptionsError: action.payload.error,
        fieldOptions: action.payload.success,
      }

    case START_FETCH_DEPARTMENTS:
      return {
        ...state,
        fetchDepartmentsProgress: true,
        fetchDepartmentsError: null,
        departments: [],
      }

    case END_FETCH_DEPARTMENTS:
      return {
        ...state,
        fetchDepartmentsProgress: false,
        fetchDepartmentsError: action.payload.error,
        departments: action.payload.success,
      }

    case START_GET_DEPARTMENT:
      return {
        ...state,
        getDepartmentProgress: true,
        getDepartmentError: null,
      }

    case END_GET_DEPARTMENT:
      return {
        ...state,
        getDepartmentProgress: false,
        getDepartmentError: action.payload.error,
        departmentData: action.payload.success,
      }

    case START_GET_DEPARTMENT_APPROVERS:
      return {
        ...state,
        getDepartmentApproversProgress: true,
        getDepartmentApproversError: null,
      }

    case END_GET_DEPARTMENT_APPROVERS:
      return {
        ...state,
        getDepartmentApproversProgress: false,
        getDepartmentApproversError: action.payload.error,
        approvers: action.payload.success,
      }

    case START_ADD_DEPARTMENT_APPROVER:
      return {
        ...state,
        addDepartmentApproverProgress: true,
        addDepartmentApproverError: null,
      }

    case END_ADD_DEPARTMENT_APPROVER:
      return {
        ...state,
        addDepartmentApproverProgress: false,
        addDepartmentApproverError: action.payload.error,
      }

    case START_DELETE_DEPARTMENT_APPROVER:
      return {
        ...state,
        deleteDepartmentApproverProgress: true,
        deleteDepartmentApproverError: null,
      }

    case END_DELETE_DEPARTMENT_APPROVER:
      return {
        ...state,
        deleteDepartmentApproverProgress: false,
        deleteDepartmentApproverError: action.payload.error,
      }

    case START_UPDATE_DEPARTMENT_APPROVER:
      return {
        ...state,
        updateDepartmentApproverProgress: true,
        updateDepartmentApproverError: null,
      }

    case END_UPDATE_DEPARTMENT_APPROVER:
      return {
        ...state,
        updateDepartmentApproverProgress: false,
        updateDepartmentApproverError: action.payload.error,
      }

    default:
      return state;
  }

}