import React, { useState } from 'react';
import { connect } from "react-redux";
import classnames from 'classnames'
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, CircularProgress, DialogContent, Slide, DialogActions, DialogTitle } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import SqureButton from '../../../../../Common/SqureButton';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    similarQuestion: state.evaluate.similarQuestion,
    similarQuestionProg: state.evaluate.similarQuestionProg
});

const connectionActions = {}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    optionLabel: {
        fontWeight: 'bold',
        fontSize: theme.spacing(2),
        padding: 0
    },
    optionDescription: {
        fontWeight: 500,
        padding: 0,
        fontSize: theme.spacing(1.5)
    },
    label: {
        color: '#6C6C6C',
        fontSize: '1em',
        margin: 0,
        padding: 0,
        marginBottom: '0.8em'
    },
    requirementCard: {
        position: 'relative',
        border: '1px solid #EBEBEB',
        borderRadius: 5,
        marginBottom: 15,
        cursor: 'pointer',
        padding: '0px 15px'
    },
    selected: {
        border: '1px solid green',
        boxShadow: '0 2.8px 2.2px rgba(0, 0, 0, 0.034)'
    },
    requirementContainer: {
        maxheight: '40vh'
    },
    actions: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: "center",
    },
    actionBtn: {
        minWidth: 110
    },
});

const RequirementSimilarQuestionDialog = ({
    isOpen,
    hideDialog,
    isLoading,
    similarQuestion = [],
    classes,
    onSubmit,
    similarQuestionProg,
}) => {
    const [selected, setSelected] = useState(false)
    return (
        <Dialog
            onClose={() => hideDialog()}
            aria-labelledby="app-integrationDialog"
            open={isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialogRequirementSimilarQuestion"
        >
            <DialogTitle>Similar Questions</DialogTitle>
            <DialogContent>
                {similarQuestionProg && <div style={{textAlign:'center'}}><CircularProgress /></div>}
                {!similarQuestionProg && (
                    <div className={classes.requirementContainer}>
                        {Array.isArray(similarQuestion) && similarQuestion.map((requirement, k) => (
                            <div className={classnames(classes.requirementCard, selected === k ? classes.selected : {})} onClick={() => setSelected(k)} key={k}>
                                <p className={classes.optionLabel}>{requirement.name}</p>
                                <p className={classes.optionDescription}>{requirement.description}</p>
                            </div>
                        ))}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <div className={classes.actions}>
                    <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={() => hideDialog()}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                    <SqureButton
                        disabled={selected === false}
                        cls={classes.actionBtn}
                        variant={"contained"}
                        onClick={(e) => {
                            onSubmit(similarQuestion[selected])
                            setSelected(false)
                            hideDialog()
                        }}
                    >
                        Use this instead
                    </SqureButton>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(RequirementSimilarQuestionDialog));
