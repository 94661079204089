import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Card, Typography, Grid } from '@material-ui/core';
import { CommonFn } from 'services/commonFn';

import {
  fetchEvaluationMeta,
  fetchEvaluationThread,
  fetchSellerColloborator,
  hideSubmitAllDraftResponcesDialog,
  showSubmitAllDraftResponcesDialog,
  fetchSellerRequirementTree,
  fetchSellerLastUserResponse,
  setSelectedRequirement,
  fetchSellerInstruction,
  fetchSellerDraftResponses
} from "redux/seller/action";
import { setRequirementTreeColExpand } from "redux/evaluation/criteria/action"

import { withRouter } from 'react-router';
import { showSnackBar } from "redux/snackbar/action";

import UserGroup from "./Common/UserGroup";
import Products from './Sections/Products';
import Chat from './Sections/Chat';
import TreeNode from './Sections/TreeNode';
import DownArrow from "assets/images/down-filled-arrow.svg";
import Logo from "assets/images/ExpentLogo.png";
import { SlateInputFieldV2 } from "Components/Common/SlateEditor/SlateInputFieldV2.jsx";
import InstructionBottomActions from './Common/InstructionBottomActions';
import classnames from "classnames";
import Image from "Components/Common/image.jsx";
import ArrowBack from "assets/images/arrow_back.svg";
import { getAllRequirements } from "util/Common"


const connectedProps = (state) => ({
  metaProg: state.seller.metaFetchProgress,
  evaluation: state.seller.evaluation,
  fetchSellerColloboratorProgress: state.seller.fetchSellerColloboratorProgress,
  sellerColloborators: state.seller.sellerColloborators,
  addSellerColloboratorProgress: state.seller.addSellerColloboratorProgress,
  collapsedRequirements: state.evaludationCriteria.requirementTreeCollapsed,
  updateSellerDraftResponsesProgress: state.seller.updateSellerDraftResponsesProgress,
  addSellerRequirementResponseProgress: state.seller.addSellerRequirementResponseProgress,
  requirementTree: state.seller.requirementTree,
  fetchRequirementTreeProgress: state.seller.fetchRequirementTreeProgress,
  lastUserResponse: state.seller.lastUserResponse,
  fetchLastUserResponseProgress: state.seller.fetchLastUserResponseProgress,
  selectedRequirementId: state.seller.selectedRequirementId,

  sellerInstructionProgress: state.seller.sellerInstructionProgress,
  sellerInstructionError: state.seller.sellerInstructionError,
  sellerInstructionSuccess: state.seller.sellerInstructionSuccess,


  fetchSellerDraftResponsesProgress: state.seller.fetchSellerDraftResponsesProgress,
  fetchSellerDraftResponsesError: state.seller.fetchSellerDraftResponsesError,
  sellerDraftResponses: state.seller.sellerDraftResponses,
});

const connectionActions = {
  fetchEvaluationMeta: fetchEvaluationMeta,
  fetchEvaluationThread: fetchEvaluationThread,
  fetchSellerColloborator: fetchSellerColloborator,
  showSnackBar: showSnackBar,
  setRequirementTreeColExpand: setRequirementTreeColExpand,
  hideSubmitAllDraftResponcesDialog: hideSubmitAllDraftResponcesDialog,
  showSubmitAllDraftResponcesDialog: showSubmitAllDraftResponcesDialog,
  fetchSellerRequirementTree: fetchSellerRequirementTree,
  fetchSellerLastUserResponse: fetchSellerLastUserResponse,
  setSelectedRequirement: setSelectedRequirement,
  fetchSellerInstruction: fetchSellerInstruction,
  fetchSellerDraftResponses: fetchSellerDraftResponses
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    // marginTop: '10px'
  },
  mainLoader: {
    textAlign: "center",
    padding: theme.spacing(2),
    position: 'fixed',
    left: 'calc(50vw - 34px)',
    top: 'calc(50vh - 36px)'
  },
  subtitle: {
    fontSize: theme.spacing(2),
    color: "#ccc",
  },
  content: {
    display: "flex"
  },
  nav: {
    flex: 1,
    minHeight: "700px",
    borderRight: "solid 1px #ccc",
    minWidth: '300px'
  },
  chatArea: {
    flex: 4
  },
  topBar: {
    display: 'flex',
    borderBottom: "solid 1px #ccc",
    padding: theme.spacing(2),
  },
  titleSection: {
    borderBottom: "solid 1px #ccc",
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  collabSection: {
    borderBottom: "solid 1px #ccc",
    padding: theme.spacing(2),
    alignItems: 'center'
  },
  mainTitle: {
    fontSize: theme.spacing(2.8),
    fontWeight: 500,
    marginRight: 10,
  },
  title: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
    marginRight: 10,
  },
  reqContainer: {
    background: '#fff',
    maxHeight: "78vh",
    minHeight: "78vh",
    padding: theme.spacing(4, 4),
    overflowY: 'auto'
  },
  productContainer: {
    background: '#fff',
    maxHeight: "78vh",
    minHeight: "78vh",
    padding: theme.spacing(4, 4),
    overflowY: 'auto'
  },
  mainContainer: {
    padding: theme.spacing(2, 2),
    overflow: 'hidden scroll',
    height: "78vh",
    background: '#f7f7f7'
  },
  reqTitle: {
    marginBottom: theme.spacing(2),
    color: '#282D30',
    fontSize: theme.spacing(2.2)
  },
  antTree: {
    // padding: theme.spacing(2, 2),
    border: '1px solid #f1f1f1',
    borderRadius: theme.spacing(1.25),
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
    "&:before": {
      position: 'absolute',
      left: 0,
      top: 0,
      height: theme.spacing(7),
      display: 'block',
      width: theme.spacing(0.4),
      background: '#2EB77D',
      content: '""',
    }
  },
  reqBox: {
    marginBottom: theme.spacing(2)
  },
  arrowIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    zIndex: 999
  },
  titleReq: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    padding: theme.spacing(2)
  },
  arrowImg: {
    cursor: 'pointer',
  },
  mainRootItem: {
    // border: '1px solid #222',
    // padding: '10px',
    // marginBottom: '20px',
    // borderRadius: '4px',
  },
  subChild: {
    paddingLeft: '20px'
  },
  parentItem: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: theme.spacing(2)
  },
  rootItem: {
    position: 'relative',
    padding: '10px',
    "&:before": {
      position: 'absolute',
      left: '15px',
      top: '40px',
      display: 'block',
      width: '3px',
      bottom: '30px',
      borderRight: '1px solid hsl(206deg 5% 74%)',
      content: '""',
    }
  },
  childItem: {
    position: 'relative',
    margin: 0,
    padding: theme.spacing(0.2, 1),
    cursor: 'pointer',
    '&:after': {
      position: 'absolute',
      left: '-23px',
      top: '14px',
      display: 'block',
      width: '21px',
      height: '1px',
      /* bottom: 25px, */
      background: 'hsl(206deg 5% 74%)',
      content: '""',
    }
  },
  selectedItem: {
    backgroundColor: '#398AF5',
    border: '1px solid #398AF5',
    color: '#fff',
    padding: theme.spacing(0.2, 2),
    borderRadius: theme.spacing(1.25),
  },
  rootItemNoBefore: {
    padding: '10px'
  },
  activeTree: {
    background: '#f7f7f7'
  },
  contentContainer: {
    background: '#f7f7f7'
  },
  logoImage: {
    height: '40px',
    position: 'absolute',
    top: 20,
    right: 0,
    left: 0,
    margin: '0 auto'
  },
  logo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center"
  },
  topRightMenu: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
  },
  instruction: {
    margin: '0 auto',
    width: '80%'
  }
});

class Respond extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requirementCriteriaMap: {},
      checkOnce: true,
      instructionMessage: '',
      isShowInstruction: true,
      isInstructionAvailable: false,
      isCompletedQuestionaire: false,
      draftRequirements: [],
    }
    this.submitAll = this.submitAll.bind(this);
    this.submitInstruction = this.submitInstruction.bind(this)
  }

  submitAll() {
    this.props.showSubmitAllDraftResponcesDialog();
  }

  componentDidMount() {
    this.props.fetchSellerInstruction(this.props.match.params.uuid, this.props.match.params.org_id);
    // this.props.fetchEvaluationMeta(this.props.match.params.uuid, this.props.match.params.org_id)
    // this.props.fetchEvaluationThread(this.props.match.params.uuid, this.props.match.params.org_id, this.props.match.params.requirement_id)
    // this.props.fetchSellerColloborator(this.props.match.params.uuid, this.props.match.params.org_id);
    this.props.fetchSellerLastUserResponse(this.props.match.params.uuid, this.props.match.params.org_id);
    this.props.fetchSellerRequirementTree(this.props.match.params.uuid, this.props.match.params.org_id);
  }

  componentDidUpdate(preProps) {
    if (this.props.selectedRequirementId !== preProps.selectedRequirementId) {
      this.props.fetchEvaluationMeta(this.props.match.params.uuid, this.props.match.params.org_id)
      this.props.fetchEvaluationThread(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId)
      this.props.fetchSellerColloborator(this.props.match.params.uuid, this.props.match.params.org_id);
    }

    if (this.props.addSellerColloboratorProgress === false && preProps.addSellerColloboratorProgress === true) {
      this.props.showSnackBar("Collaborator added successfully", "success", 3000);
      this.props.fetchSellerColloborator(this.props.match.params.uuid, this.props.match.params.org_id);
    }

    if (this.props.updateSellerDraftResponsesProgress === false && preProps.updateSellerDraftResponsesProgress === true) {
      this.props.showSnackBar("Draft Responses submited successfully", "success", 3000);
      this.props.hideSubmitAllDraftResponcesDialog();
      this.props.fetchEvaluationMeta(this.props.match.params.uuid, this.props.match.params.org_id)
      this.props.fetchEvaluationThread(this.props.match.params.uuid, this.props.match.params.org_id, this.props.selectedRequirementId)
    }

    // if (this.props.addSellerRequirementResponseProgress === false && preProps.addSellerRequirementResponseProgress === true) {
    //   this.props.showSnackBar("Response submited successfully", "success", 3000);
    //   this.props.fetchEvaluationMeta(this.props.match.params.uuid, this.props.match.params.org_id);
    //   this.props.fetchSellerRequirementTree(this.props.match.params.uuid, this.props.match.params.org_id);
    // }

    if (this.props.fetchLastUserResponseProgress === false && preProps.fetchLastUserResponseProgress === true) {
      if (this.props.lastUserResponse === undefined || this.props.lastUserResponse === null || this.props.lastUserResponse.data === null) {
        // this.props.setSelectedRequirement(parseInt(this.props.match.params.requirement_id));
        this.setRequirementId();
      } else {
        this.props.setSelectedRequirement(this.props.lastUserResponse.RequirementID);
      }
    }

    if (this.props.fetchRequirementTreeProgress === false && preProps.fetchRequirementTreeProgress === true) {
      this.initRequirementTreeMap();
      if (this.props.selectedRequirementId === null) {
        this.setRequirementId();
      }
    }

    if (!this.props.sellerInstructionProgress && preProps.sellerInstructionProgress) {
      if (this.props.sellerInstructionError === null) {
        if (this.props.sellerInstructionSuccess) {
          this.showInstructions()
        }
      } else {
        this.setState({
          isShowInstruction: false
        })
        console.warn('Instruction not found')
      }
    }


    if (this.props.fetchSellerDraftResponsesProgress === false && preProps.fetchSellerDraftResponsesProgress === true) {
      if (this.props.fetchSellerDraftResponsesError === null) {
        var ids = this.props.sellerDraftResponses.map(o => o.ID);
        this.setState({
          draftRequirements: ids
        })
        console.log(ids, 'draftRequirements')
      }
    }

    if (!this.props.metaProg && preProps.metaProg) {
      this.checkQuestionaireStatus();
      setTimeout(() => {
        this.props.fetchSellerDraftResponses(this.props.match.params.uuid, this.props.match.params.org_id);
      }, 200);
    }

  }

  setRequirementId() {
    var allRequirements = (this.props.requirementTree !== undefined && this.props.requirementTree !== null) ? [...this.props.requirementTree] : [];
    if (this.props.selectedRequirementId === null && allRequirements.length > 0 && (this.props.lastUserResponse === undefined || this.props.lastUserResponse === null || this.props.lastUserResponse.data === null)) {
      this.props.setSelectedRequirement(allRequirements?.[0]?.Childrens?.[0]?.ID);
    }
  }

  initRequirementTreeMap() {
    var allRequirements = (this.props.requirementTree !== undefined && this.props.requirementTree !== null) ? [...this.props.requirementTree] : [];
    var requirementIds = {};
    var allItems = {};

    if (allRequirements !== null && allRequirements.length > 0) {
      requirementIds = getAllItems(this.props.requirementTree, allItems);
    }

    this.setState({
      requirementCriteriaMap: requirementIds
    })

    var parent = '';
    function getAllItems(datas, allItems) {
      datas.forEach(function (item) {
        if (item.Type === 1) {
          parent = item.ID;
          allItems[parent] = [];
        } else {
          allItems[parent].push(item.ID);
        }
        if (item.Childrens != null) {
          getAllItems(item.Childrens, allItems);
        }
      });
      return allItems;
    }
  }

  showInstructions() {
    const { sellerInstructionSuccess } = this.props;
    console.log(sellerInstructionSuccess, 'sellerInstructionSuccess')
    if (sellerInstructionSuccess !== undefined && sellerInstructionSuccess !== null && sellerInstructionSuccess !== '' && sellerInstructionSuccess.length > 0) {
      let firstData = sellerInstructionSuccess[0];
      let insCheckStr = this.props.match.params.uuid + '_' + this.props.match.params.org_id + '_Instructions';
      const isHideInstructions = window.sessionStorage.getItem(insCheckStr);
      let isRequireToShow = true;
      console.log(isHideInstructions, 'isHideInstructions')
      if (isHideInstructions !== undefined && isHideInstructions !== null && (isHideInstructions === true || isHideInstructions === 'true')) {
        isRequireToShow = false;
      } else {
        isRequireToShow = true;
      }

      this.setState({
        isShowInstruction: isRequireToShow,
        instructionMessage: firstData?.Instruction,
        isInstructionAvailable: true
      })
    } else {
      this.setState({
        isShowInstruction: false
      })
    }
  }

  submitInstruction() {
    let insCheckStr = this.props.match.params.uuid + '_' + this.props.match.params.org_id + '_Instructions';
    window.sessionStorage.setItem(insCheckStr, true);
    this.setState({
      isShowInstruction: false
    })
  }

  checkQuestionaireStatus() {
    const { evaluation } = this.props;
    if (evaluation) {
      var isCompletedQuestionaire = false;
      this.props.evaluation.criterias.forEach((criteria, key) => {
        criteria.requirements.forEach((requirement) => {
          if (requirement.response_status === 'DONE') {
            isCompletedQuestionaire = true;
          }
        })
      })
      this.setState({
        isCompletedQuestionaire: isCompletedQuestionaire
      })
    }
  }

  render() {
    const classes = this.props.classes;
    var numQuestions = 0;

    let requirementIds = getAllRequirements(this.props.requirementTree, []);
    numQuestions = requirementIds.length;

    const { requirementCriteriaMap } = this.state;
    var selectedReqId = this.props.selectedRequirementId;
    var isActiveTree = '';
    if (this.props.requirementTree !== null && selectedReqId !== null) {
      Object.keys(requirementCriteriaMap).forEach(function (criteria) {
        if (requirementCriteriaMap[criteria].indexOf(selectedReqId) !== -1) {
          isActiveTree = parseInt(criteria);
        }
      })
    }

    return (
      <Card className={classes.root}>
        {/* {this.props.metaProg && <div className={classes.mainLoader}>
          <CircularProgress />
        </div>} */}


        {this.props.evaluation !== null && <React.Fragment>
          <div className={classes.titleSection}>
            {this.state.isInstructionAvailable && !this.state.isShowInstruction && <div
              title='Go to instructions'
              className={classes.iconContainer}
              onClick={() => {
                this.setState({
                  isShowInstruction: true
                })
              }}
            >
              <Image
                src={ArrowBack}
                alt="arrow_back"
                className={classes.image}
              />
            </div>}
            <div style={{ marginLeft: 10 }}>
              <Typography variant={"h5"} className={classes.mainTitle}>Assessment from {this.props.evaluation.orgName}</Typography>
              {/* <Typography variant={"h5"} className={classes.mainTitle}>{this.props.evaluation.orgName} is Evaluating {this.props.evaluation.productName}</Typography> */}
              <Typography className={classes.subtitle}>{numQuestions} Requirement{numQuestions > 1 ? 's' : ''}</Typography>
            </div>
            <img src={Logo} alt="logo" className={classes.logoImage} />
          </div>


          {!this.state.isShowInstruction && <Grid container className={classnames(classes.collabSection)}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography variant={"h5"} className={classes.title}>Collaborators</Typography>
              <UserGroup
                editable={true}
                selectedUser={[]}
                existingUsers={this.props.sellerColloborators}
                evaluationId={this.props.match.params.uuid}
                position={'SecondaryBar'}
                onAdd={(user) => {
                  console.log('Adding new ')
                }}
                users={this.props.sellerColloborators}
                addUserType={'SELLER_COLLOBORATOR'}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} className={classes.topRightMenu}>
              <Typography variant={"h5"} className={classes.title}>{this.props.evaluation.deadline !== '' && this.props.evaluation.deadline !== null ? 'Deadline: ' + CommonFn.formateDate(this.props.evaluation.deadline, true) : ''}</Typography>
            </Grid>
          </Grid>}


          {!this.state.isShowInstruction && <Grid container className={classnames(classes.contentContainer)}>
            <Grid item lg={3} md={3} sm={3} xs={12} className={classnames(classes.reqContainer)}>
              {this.props.requirementTree !== null && this.props.requirementTree.map((item, index) => {
                let activeCls = '';
                if (isActiveTree === item.ID) {
                  activeCls = classes.activeTree
                }

                let isCollapsed = false;
                if (this.props.collapsedRequirements.indexOf(item.ID) !== -1) {
                  isCollapsed = true;
                }

                return <div className={classes.antTree} key={index + item.ID}>
                  <span className={classes.arrowIcon} onClick={(evt) =>
                    this.props.setRequirementTreeColExpand(item.ID, 'ADD')
                  }>
                    <img className={classes.arrowImg} src={DownArrow} alt='Down Arrow' />
                  </span>

                  {isCollapsed && <p className={classes.titleReq}>
                    {item.Name}
                  </p>}
                  {!isCollapsed && <div className={classnames(classes.mainRootItem, activeCls)} >
                    <TreeNode
                      key={index}
                      itemData={item}
                      cls={classes.parentItem}
                      selectedItem={this.props.selectedRequirementId}
                      classes={this.props.classes}
                    >
                    </TreeNode>
                  </div>}
                </div>
              })}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} className={classnames(classes.mainContainer)}>
              <Chat
                isInstructionAvailable={this.state.isInstructionAvailable}
                showInstruction={() => {
                  this.setState({
                    isShowInstruction: true
                  })
                }}
                draftRequirements={this.state.draftRequirements}
                isCompletedQuestionaire={this.state.isCompletedQuestionaire}

              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={12} className={classnames(classes.productContainer)}>
              <Products
                isCompletedQuestionaire={this.state.isCompletedQuestionaire}
              />
            </Grid>
          </Grid>}

          {this.state.isShowInstruction && <div className={classes.instruction}>
            <SlateInputFieldV2
              // label="Instruction"
              readOnly={true}
              isToolBar={false}
              placeholder=""
              style={{
                margin: 0,
              }}
              as={SlateInputFieldV2}
              initValue={this.state.instructionMessage}
              formControlStyle={{ border: 'none', overflow: 'hidden' }}
              textContainerStyle={{ minHeight: '100px !important', margin: "0px", background: "#fff", color: "#707070", fontSize: 16 }} />

            <InstructionBottomActions
              submitInstruction={this.submitInstruction}
            />
          </div>}
        </React.Fragment>}

      </Card>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Respond));
