import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Tooltip, Avatar, Chip } from "@material-ui/core";
import { getRequirementType } from "util/RequirementTypeOptions";
import classnames from "classnames";
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import { CommonFn } from "services/commonFn";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      position: "relative",
      border: "1px solid #EBEBEB",
      cursor: "pointer",
      borderRadius: 5,
      '&:hover':{
        border: "1px solid #49B8FA",
        boxShadow: "#49B8FA 0px 1px 1px 0px, #49B8FA 0px 0px 0px 1px"
      }
    },

    requirementExpanded01: {
      border: "1px solid #8d7d7d",
      backgroundColor: "white",
      height: "100%",
    },
    requirementCollapse: {
      padding: "12px 16px 0 25px",
      cursor: "pointer",
    },
    requirementContainer: {
      padding: "20px",
      // padding: '30px 16px 0 25px',
      justifyContent: "space-between",
    },
    title: {
      fontSize: theme.spacing(2),
      color: "#333333",
      fontWeight: 600,
      width: "max-content",
      "& > img": {
        display: "none",
      },
      "&:hover > img": {
        display: "initial",
      },
    },
    collapseTitle: {
      fontSize: theme.spacing(2),
      color: "#333333",
      fontWeight: 600,
    },
    collapseTxt: {
      fontSize: theme.spacing(2),
      color: "#707070",
      marginTop: theme.spacing(1),
    },
    bodyTxt: {
      borderRadius: 8,
      backgroundColor: "#FAFAFA",
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      fontSize: theme.spacing(2),
      "& [class*='editableField']": {
        "white-space": "normal !important",
      },
    },
    editIcon: {
      color: "#999999",
    },
    caretIcon: {
      color: "#999999",
    },
    descriptionContainer: {
      marginTop: 18,
    },
    description: {
      color: "#707070",
      fontSize: 13,
      minHeight: 50, // 106
    },
    "@global": {
      ".rootRequirementHight": {
        "& [class*='editor-formControl']": {
          maxHeight: 165,
        },
      },
    },
    label: {
      color: "#707070",
      fontSize: 13,
      fontWeight: 400,
    },
    rightAction: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 20,
    },
    RightPanel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
  });

class Requirement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      options: [],
      answertype: 1,
    };
  }

  componentDidMount() {
    try {
      this.setState({
        name: this.props.requirement?.question?.name || "",
        description: this.props.requirement?.question?.description || "",
        answertype: this.props.requirement?.answers?.answer_type || 1,
        options: this.props.requirement?.answers?.options || [],
      });
    } catch (e) {}
  }

  getType(req) {
    let type = "Text";
    if (req && req?.answer_type) {
      const answer_type = parseInt(req?.answer_type);
      if (2 === answer_type) type = "Multi Select";
      if (3 === answer_type) type = "Single Select";
      if (4 === answer_type) type = "Binary";
      if (5 === answer_type) type = "Score";
      if (6 === answer_type) type = "File upload";
    }
    return type;
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  render() {
    const classes = this.props.classes;
    const requirement = this.props.requirement;
    if (requirement == null || requirement === undefined) {
      return null;
    }
    // var response = this.props.response?.match_object || {};

    // let answerType = response?.answer_type;
    // let options = response?.options_answer;
    // let printableAnswer = null;
    // if (![2, 3, 14, 15].includes(answerType)) {
    //   let answer = response?.answer;
    //   if (answer && answerType === 11) {
    //     printableAnswer = CommonFn.formateDate(answer, true);
    //   } else if (answer && answerType === 13) {
    //     let data = JSON.parse(answer);
    //     printableAnswer = data.value + data.type.label;
    //   } else if (answer && answer !== null && answer !== "" && answerType === 6) {
    //     printableAnswer = answer?.map((dt) => {
    //       return (
    //         <p>
    //           <a style={{ color: "#4b86ff" }} without rel="noreferrer" target={"_blank"} href={dt?.url}>
    //             {dt.name}
    //           </a>
    //         </p>
    //       );
    //     });
    //   } else if (answerType === 1 || answerType === 7) {
    //     printableAnswer = <SlateReadonlyField initValue={answer} />;
    //   } else {
    //     printableAnswer = answer;
    //   }
    // } else {
    //   printableAnswer = options?.map((u) => u.title).join(", ") || "-";
    // }

    return (
      <div className={classnames(classes.root, classes.requirementExpanded01)}>
        <Grid container className={classes.requirementContainer}>
          <Grid item xs={12}>
            <Typography component="p" className={classes.title}>
              {this.state.name || requirement.name}
            </Typography>
            <Typography component="p" className={classes.bodyTxt}>
              <SlateReadonlyField initValue={this.state.description || requirement.description} />
            </Typography>
          </Grid>
          {/* <Grid item md={12} className={classes.RightPanel}>
            <div>
              <Typography component="p" className={classes.collapseTxt}>
                Answer type - {getRequirementType([], this.state.answertype)}
              </Typography>
              {[2, 3, 4, 14, 15].includes(this.state.answertype) && (
                <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
                  {this.state.options?.map((option, i) => (
                    <Chip key={i} label={option.title} variant="outlined" color="primary" size="small" />
                  ))}
                </div>
              )}
            </div>

            {requirement?.created_by?.length > 0 && (
              <div className={classes.rightAction}>
                <label className={classes.label}>Created by</label>
                <div>
                  <Typography className={classes.userInfoItem}>
                    <Tooltip
                      placement="top"
                      title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{requirement?.created_by}</span>}
                      arrow
                    >
                      <Avatar className={classes.avatarImg}> {this.avatarName(requirement?.created_by || "")}</Avatar>
                    </Tooltip>
                  </Typography>
                </div>
              </div>
            )}
          </Grid>
          {this.props.response && (
            <div className={classes.disableForm}>
              <Typography component="p" className={classes.collapseTxt}>
                Response
              </Typography>
              <div className={classes.textWrap}>{printableAnswer}</div>
            </div>
          )} */}
        </Grid>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Requirement));
