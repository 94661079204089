import React from "react";
import { CircularProgress } from "@material-ui/core";

const DocViewer = ({ source, isImage, isPdf }) => {
  if (!source) {
    return <CircularProgress />;
  }

  const src = source;

  if (isImage) {
    return <img src={src} width="100%" height="auto" alt="document" />;
  }
  if (isPdf) {
    return (
      <embed
        src={`${source}#toolbar=0&navpanes=0&scrollbar=0`}
        wmode="transparent"
        type="application/pdf"
        frameBorder="0"
        scrolling="auto"
        height="100%"
        width="100%"
      />
    );
  } else {
    return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${src}`}
          title="file"
          width="100%"
          height="100%"
          frameborder="no"
        />
    );
  }
};

export default DocViewer;
