import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import RequirementReply from "Components/Common/FormReply/RequirementReply";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  responseWrap: {
    // minWidth: 300,
    // maxWidth: '60%',
    width: '100%',
  },
  responseNote: {
    "& [class*='editableField']": {
      minHeight: "auto !important",
      margin: "0px !important",
    },
    display: "block",
    background: "#f1f1f1",
    // border: "1px solid #217fec",
    padding: "10px",
    paddingTop: "8px",
    borderRadius: "16px",
    fontSize: "13px",
  },
  replyActionContainer: {
    marginTop: "14px",
    flexDirection: "row",
    boxSizing: "border-box",
    display: "flex",
    gap: 10,
    // placeContent: "space-between",
    alignItems: "flex-start",
  },
  userAvatar: {},
  replyAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& svg": {
      width: "16px",
      height: "16px",
      color: "#217FEC",
      marginRight: "15px",
      cursor: "pointer",
    },
  },
  btnWrap: {
    textAlign: 'right'
  },
  slateWrap: {
    marginLeft: '90px',
    marginTop: '10px',
    "& [class*='editableField']": {
      minHeight: "70px !important",
      margin: "0px !important",
    },
  },
  reqMatchDiv: {
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(1, 1),
    color: '#282D30',
    fontWeight: 400,
    fontSize: theme.spacing(1.8)
  },
  selectedReqOption: {
    border: '1px solid #707070',
    color: '#282D30',
    borderRadius: '4px',
    padding: theme.spacing(0, 4),
    fontSize: theme.spacing(1.8)
  },
});

class Evaluation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  avatarName = (name) => {
    if (name) {
      return name.substring(0, 1);
    }
  };

  render() {
    const { classes, response, requirement } = this.props;
    console.log(response, 'response1111111111')
    console.log(requirement, 'requirement')

    return (
      <div className={classes.replyActionContainer}>
        <span className={classes.userAvatar}>
          <Avatar style={{ width: "25px", height: "25px", fontSize: "15px" }}>{this.avatarName(response?.User?.Name || "User")}</Avatar>
        </span>
        <div className={classes.responseWrap}>
          <span className={classes.responseNote}>
            <div style={{ display: "flex", placeContent: "space-between" }}>
              <span style={{ fontWeight: 600, marginLeft: 7 }}>{response?.User?.Name || "User"}</span>
            </div>
            <div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].includes(requirement.answer_type) && response && response.UserOptions && response.UserOptions.length > 0 && <RequirementReply
                additional_data={requirement?.options}
                options={requirement?.options}
                type={requirement?.answer_type}
                title={requirement?.name}
                description={requirement?.description}
                isRequired={requirement?.is_mandatory}
                key={requirement.id + '_sectionrequirement'}
                index={requirement.id}
                value={response !== undefined && response !== null && response.UserOptions !== null ? response?.UserOptions[0]?.Answer : ''}
                selectedOptions={response !== undefined && response !== null && response.UserOptions !== null ? response?.UserOptions : []}
                evaluationId={this.props.match.params.evaluation_id}
                orgId={this.props.OrgID}
                workflowId={this.props.workflow_id}
                isFormEditable={false}
                itemId={requirement.id}
                sectionId={requirement.id}
                maxScore={100}
                item={requirement}
                error={requirement.error || ''}
                isShowSaveButton={false}
                isUpdateOnChange={false}
                from={'BUYER'}
                isSummaryPage={true}
                isOtherOption={requirement?.is_other_option}
                onChange={(output) => {

                }}
                showUserAddDialog={(item) => {

                }}
                showTeamAddDialog={(data) => {

                }}
              />}
            </div>

            {response && response.Note && response?.Note.length > 0 && <SlateInputField
              placeholder=""
              isToolBar={false}
              enableMention={true}
              readOnly={true}
              as={SlateInputField}
              formControlStyle={{ border: "none", background: "transparent" }}
              initValue={response.Note}
              textContainerStyle={{ minHeight: '100px !important', maxHeight: '100px !important', margin: "0px", color: "#707070", fontSize: 16 }}
              paperStyle={{ fontWeight: 400, background: "transparent" }}
              style={{ width: "100%", marginBottom: 0, background: "transparent" }}
              id={'child_thread_' + response?.ID}
            />}

            {this.props.enableScoring && requirement.answer_type !== 5 && <div className={classes.reqMatchDiv}>
              Score <span className={classes.selectedReqOption}>{response.Response === -1 ? 'Not Applicable' : response.Response}</span>
            </div>}

          </span>
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Evaluation));
