import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";


export const RESET_ORG_GROUP_STATE     =   "RESET_ORG_GROUP_STATE";

export const START_ORG_GROUP_FETCH     =   "START_ORG_GROUP_FETCH";
export const END_ORG_GROUP_FETCH       =   "END_ORG_GROUP_FETCH";

export const START_ORG_GROUP_PRODUCT_FETCH     =   "START_ORG_GROUP_PRODUCT_FETCH";
export const END_ORG_GROUP_PRODUCT_FETCH       =   "END_ORG_GROUP_PRODUCT_FETCH";

export const START_ORG_GROUP_VENDOR_FETCH     =   "START_ORG_GROUP_VENDOR_FETCH";
export const END_ORG_GROUP_VENDOR_FETCH       =   "END_ORG_GROUP_VENDOR_FETCH";

export const START_ORG_GROUP_VENDOR_UPDATE     =   "START_ORG_GROUP_VENDOR_UPDATE";
export const END_ORG_GROUP_VENDOR_UPDATE       =   "END_ORG_GROUP_VENDOR_UPDATE";

export const START_ORG_GROUP_PRODUCT_UPDATE     =   "START_ORG_GROUP_PRODUCT_UPDATE";
export const END_ORG_GROUP_PRODUCT_UPDATE       =   "END_ORG_GROUP_PRODUCT_UPDATE";

export const START_ORG_GROUP_ADD     =   "START_ORG_GROUP_ADD";
export const END_ORG_GROUP_ADD       =   "END_ORG_GROUP_ADD";

export const START_ORG_GROUP_UPDATE     =   "START_ORG_GROUP_UPDATE";
export const END_ORG_GROUP_UPDATE       =   "END_ORG_GROUP_UPDATE";

export const START_ORG_GROUP_USER_FETCH     =   "START_ORG_GROUP_USER_FETCH";
export const END_ORG_GROUP_USER_FETCH       =   "END_ORG_GROUP_USER_FETCH";

export const START_UPDATE_LICENSE_PURCHAGED     =   "START_UPDATE_LICENSE_PURCHAGED";
export const END_UPDATE_LICENSE_PURCHAGED       =   "END_UPDATE_LICENSE_PURCHAGED";

export const SHOW_GROUP_PRODUCT_DIALOG = "SHOW_GROUP_PRODUCT_DIALOG";
export const HIDE_GROUP_PRODUCT_DIALOG = "HIDE_GROUP_PRODUCT_DIALOG";

export const SHOW_GROUP_VENDOR_DIALOG = "SHOW_GROUP_VENDOR_DIALOG";
export const HIDE_GROUP_VENDOR_DIALOG = "HIDE_GROUP_VENDOR_DIALOG";

export const SET_ACTIVE_USER_TAB = "SET_ACTIVE_USER_TAB";

export const SHOW_VIA_INTEGRATION_DIALOG = "SHOW_VIA_INTEGRATION_DIALOG";
export const HIDE_VIA_INTEGRATION_DIALOG = "HIDE_VIA_INTEGRATION_DIALOG";

export function setActiveUserTab(id){
    return {
        type:SET_ACTIVE_USER_TAB,
        payload:{
            id
        }
    };
}


//Action to start the registration
function startUpdateLicensePurchaged(){
    return {
        type:START_UPDATE_LICENSE_PURCHAGED,
        payload:null
    };
}

function endUpdateLicensePurchaged(license,success,error){
    return {
        type:END_UPDATE_LICENSE_PURCHAGED,
        payload:{
            success,error, license
        }
    };
}

//Thunk to perform the updateLicensePurchaged
export  function updateLicensePurchaged(licenseProduced, productId) {
    return async (dispatch) => {
        dispatch(startUpdateLicensePurchaged());
        try{
            const api_server = Environment.api_host("PRODUCT");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/org/products/${productId}/license?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().putMethod(url, { licenseProduced });
            dispatch(endUpdateLicensePurchaged(licenseProduced, response,error));
        } catch(e){
            dispatch(endUpdateLicensePurchaged(licenseProduced, null,{
                message:e.message
            }));
            return;
        }
    };
}   

export function resetOrgGroupState(){
    return {
        type:RESET_ORG_GROUP_STATE,
        payload:null
    };
}

function startFetchOrgGroup(page){
    return {
        type:START_ORG_GROUP_FETCH,
        payload:page
    };
}

function endFetchOrgGroup(success,error){
    return {
        type:END_ORG_GROUP_FETCH,
        payload:{
            success,error
        }
    };
}

export  function fetchOrgGroup(page,pageSize,query,suppressed){
    return async (dispatch) => {
        dispatch(startFetchOrgGroup(page));
        try{
            const api_server = Environment.api_host("USER");
            const timestamp     =   (new Date()).getTime();
            let url   =   `${api_server}/groups?page=${page}&pageSize=${pageSize}&query=${query}&t=${timestamp}`;
            if(suppressed!==null && suppressed !== undefined){
                url   =   `${api_server}/groups?page=${page}&pageSize=${pageSize}&query=${query}&is_suppressed=${suppressed}&t=${timestamp}`;
            }
            let [response,error]    =   await HttpFactory.instance().getMethod(url,null,false);
            dispatch(endFetchOrgGroup(response,error));
        } catch(e){
            console.log(e);
            dispatch(endFetchOrgGroup(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startFetchOrgGroupProducts(page){
    return {
        type:START_ORG_GROUP_PRODUCT_FETCH,
        payload:page
    };
}

function endFetchOrgGroupProducts(success,error){
    return {
        type:END_ORG_GROUP_PRODUCT_FETCH,
        payload:{
            success,error
        }
    };
}

export  function fetchOrgGroupProducts(groupId,page,pageSize){
    return async (dispatch) => {
        dispatch(startFetchOrgGroupProducts(page));
        try{
            const api_server = Environment.api_host("USER");
            const timestamp     =   (new Date()).getTime();
            const data = {page,pageSize};
            const url   =   `${api_server}/group/${groupId}/fetch_products?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endFetchOrgGroupProducts(response,error));
        } catch(e){
            console.log(e);
            dispatch(endFetchOrgGroupProducts(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startFetchOrgGroupVendors(page){
    return {
        type:START_ORG_GROUP_VENDOR_FETCH,
        payload:page
    };
}

function endFetchOrgGroupVendors(success,error){
    return {
        type:END_ORG_GROUP_VENDOR_FETCH,
        payload:{
            success,error
        }
    };
}

export  function fetchOrgGroupVendors(groupId,page,pageSize){
    return async (dispatch) => {
        dispatch(startFetchOrgGroupVendors(page));
        try{
            let data = {
                page: page,
                page_size: pageSize
            }
            const api_server = Environment.api_host("CATALOG");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/entity/vendor/org/teams/${groupId}?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endFetchOrgGroupVendors(response,error));
        } catch(e){
            console.log(e);
            dispatch(endFetchOrgGroupVendors(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startUpdateOrgGroupVendor(){
    return {
        type:START_ORG_GROUP_VENDOR_UPDATE,
        payload:null
    };
}

function endUpdateOrgGroupVendor(success,error){
    return {
        type:END_ORG_GROUP_VENDOR_UPDATE,
        payload:{
            success,error
        }
    };
}

export  function updateOrgGroupVendor(groupId,data){
    return async (dispatch) => {
        dispatch(startUpdateOrgGroupVendor());
        try{
            const api_server = Environment.api_host("CATALOG");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/entity/teams/${groupId}?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endUpdateOrgGroupVendor(response,error));
        } catch(e){
            dispatch(endUpdateOrgGroupVendor(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startUpdateOrgGroupProduct(){
    return {
        type:START_ORG_GROUP_PRODUCT_UPDATE,
        payload:null
    };
}

function endUpdateOrgGroupProduct(success,error){
    return {
        type:END_ORG_GROUP_PRODUCT_UPDATE,
        payload:{
            success,error
        }
    };
}

export  function updateOrgGroupProduct(groupId,data){
    return async (dispatch) => {
        dispatch(startUpdateOrgGroupProduct());
        try{
            const api_server = Environment.api_host("USER");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/group/${groupId}/products?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endUpdateOrgGroupProduct(response,error));
        } catch(e){
            dispatch(endUpdateOrgGroupProduct(null,{
                message:e.message
            }));
            return;
        }
    };
} 




function startAddOrgGroup(){
    return {
        type:START_ORG_GROUP_ADD,
        payload:null
    };
}

function endAddOrgGroup(success,error){
    return {
        type:END_ORG_GROUP_ADD,
        payload:{
            success,error
        }
    };
}

export  function addOrgGroup(name,email,description){
    return async (dispatch) => {
        dispatch(startAddOrgGroup());
        try{
            const api_server = Environment.api_host("USER");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/group?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().postMethod(url,{
                name:name,
                email:email,
                description:description
            });
            dispatch(endAddOrgGroup(response,error));
            dispatch(fetchOrgGroup(0,20,''))
        } catch(e){
            dispatch(endAddOrgGroup(null,{
                message:e.message
            }));
            return;
        }
    };
} 






function startUpdateOrgGroup(){
    return {
        type:START_ORG_GROUP_UPDATE,
        payload:null
    };
}

function endUpdateOrgGroup(success,error){
    return {
        type:END_ORG_GROUP_UPDATE,
        payload:{
            success,error
        }
    };
}

export  function updateOrgGroup(params){
    const { id, name, email, description, is_suppressed = false, user_ids = [],sync_user=false } = params;
    let data = {
        name: name,
        email: email,
        description: description,
        user_ids: user_ids,
        is_suppressed:is_suppressed,
        sync_user:sync_user
    }
    if(is_suppressed){
        delete data.user_ids;
    }
    // else{
    //     delete data.is_suppressed;
    // }
    return async (dispatch) => {
        dispatch(startUpdateOrgGroup());
        try{
            const api_server = Environment.api_host("USER");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/group/${id}?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
            dispatch(endUpdateOrgGroup(response,error));
        } catch(e){
            dispatch(endUpdateOrgGroup(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startFetchOrgGroupUser(page){
    return {
        type:START_ORG_GROUP_USER_FETCH,
        payload:page
    };
}

function endFetchOrgGroupUser(success,error){
    return {
        type:END_ORG_GROUP_USER_FETCH,
        payload:{
            success,error
        }
    };
}

export  function fetchOrgGroupUser(group_id = null){
    return async (dispatch) => {
        dispatch(startFetchOrgGroupUser());
        try{
            const api_server = Environment.api_host("USER");
            const timestamp     =   (new Date()).getTime();
            const url   =   `${api_server}/group/${group_id}?t=${timestamp}`;
            let [response,error]    =   await HttpFactory.instance().getMethod(url,null,false);
            dispatch(endFetchOrgGroupUser(response,error));
        } catch(e){
            console.log(e);
            dispatch(endFetchOrgGroupUser(null,{
                message:e.message
            }));
            return;
        }
    };
} 

export function showTeamProductsDialog(data){
    return {
        type:SHOW_GROUP_PRODUCT_DIALOG,
        payload: data
    };
}

export function hideTeamProductsDialog(){
    return {
        type:HIDE_GROUP_PRODUCT_DIALOG,
        payload: null
    };
}

export function showTeamVendorsDialog(data){
    return {
        type:SHOW_GROUP_VENDOR_DIALOG,
        payload: data
    };
}

export function hideTeamVendorsDialog(){
    return {
        type:HIDE_GROUP_VENDOR_DIALOG,
        payload: null
    };
}


export function showViaIntegrationDialog() {
    return {
        type: SHOW_VIA_INTEGRATION_DIALOG,
        payload: null
    };
}

export function hideViaIntegrationDialog() {
    return {
        type: HIDE_VIA_INTEGRATION_DIALOG,
        payload: null
    };
}
