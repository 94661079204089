import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Tabs, Backdrop, CircularProgress, Tab, Button } from "@material-ui/core";
import CommonCss from "commonCss";
import SqureButton from "Components/Common/SqureButton";
import AppBarV2 from "Components/Application/AppBarV2";
import Image from "Components/Common/image";
import ReviewsEmptyState from "assets/images/reviews_empty_state.svg";
import { Add as AddIcon } from "@material-ui/icons";
import { fetchDashboard } from "redux/visualization/action";
import { updateDrawerStyle } from "redux/drawer/action";
import { showSnackBar } from "redux/snackbar/action";
import * as Validator from "util/Validation";
import ProductSelectionDialog from "../Evaluate/Components/Evaluation/Common/ProductSelectionDialog";
import { isEnabledEvaluate } from "util/Common";
import DashboardRender from "./Common/DashboardRender.jsx";
import queryString from "query-string";

const borderCards = ["LINE", "BAR", "PIE", "DOUGHNUT"]

const connectedProps = (state) => ({
  orgUser: state.orgUser,
  userRole: state.authUser.user?.Role,
  dashboards: state.visualization.dashboards,
  dashboardFetchProgress: state.visualization.dashboardFetchProgress,
  dashboardFetchError: state.visualization.dashboardFetchError,
  expanded: state.drawer.expanded,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  showSnackBar: showSnackBar,
  fetchDashboard: fetchDashboard,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFF",
      minHeight: "100vh",
    },
    fullSceen: CommonCss.backdrop,
    formControl: {
      ...CommonCss.formControl,
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
      },
      padding: ".375rem",
    },
    ...CommonCss.ProductDetails,
    tabs: {
      "& [class*='MuiTab-root']": {
        padding: "0 6px",
        maxWidth: "max-content",
        width: "max-content",
      },
      "border-bottom": "1px solid #d8d8d8",
    },
    container: {
      marginTop: 81,
    },
    DashboardNoDataLayout: {
      width: "calc(100% - 10px)",
      margin: "120px 0px 0px 0px",
      minHeight: 450,
      textAlign: "center",
      background: "#ffffff",
      padding: "23px",
      borderRadius: "6px",
      "& button": {
        margin: "20px 0",
      },
    },
    rootContainer: CommonCss.rootContainer,
    chartWrap: {
      display: "flex",
      alignItems: "start",
    },
    chartContent: {
      padding: "10px",
      width: "100%",
    },
    contentRoot: {
      border: "1px solid #d8d8d8",
      padding: 10,
      borderRadius: 5,
      background: "#FFF",
    },
    inputLabel: {
      color: "#6C6C6C",
      fontSize: 15,
      opacity: 0.9,
      marginBottom: 5,
    },
    inputSubLabel: {
      color: "#6C6C6C",
      fontSize: 13,
      opacity: 0.9,
      marginBottom: 11,
    },
    button: {
      padding: "6px 8px",
      fontSize: 13,
      fontWeight: 400,
      width: theme.spacing(30),
      marginRight: 15,
    },
    dashboardWrap: {
      marginTop: "1rem",
      width: "100%",
    },
    secondarySection: {
      height: 50,
      display: "flex",
      background: "white",
      alignItems: "center",
      paddingLeft: "32px",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      paddingRight: 15,
      borderBottom: "1px solid #f1f1f1",
    },
    actionBtn: {
      minWidth: "200px",
      marginLeft: 10,
    },
    workflowBtns: {
      textAlign: "right",
    },
    rowWrap: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    flexWrap: {
      display: "flex",
    },
    columnWrap: {
      display: "flex",
      // alignItems: "center",
      minWidth: 300,
    },
    paper: {
      width: "100%",
      padding: "1rem",
      position: "relative",
    },
    visualizationWrap: {
      padding: "1rem",
      paddingTop: "2rem",
    },
    label: {
      color: "#282D30",
      fontSize: theme.spacing(1.6),
    },
    widthFields: {
      height: 30,
    },
    textFields: {
      width: "90%",
      marginBottom: 10,
      "& [class*='MuiInputBase']": {
        fontSize: 14,
      },
    },
  });

class DashboardHome extends Component {
  constructor(props) {
    super();
    this.state = {
      tab: 0,
      loading: true,
    };
  }

  componentDidMount() {
    this.props.fetchDashboard({ query: "", status: "", forView: false });
    let params = queryString.parse(this.props.location.search);
    if (params && params.activeTab) {
      this.setState({ tab: Number(params.activeTab) });
    }
    setTimeout(() => {
      this.setState({ loading: false });
    }, 200);
    // this.props.updateDrawerStyle(true, true);
  }

  componentDidUpdate(prevProps) {
    // if(this.props.expanded !== prevProps.expanded){
    //   this.setState({ loading: true });
    //   setTimeout(() => {
    //     this.setState({ loading: false });
    //   }, 200);
    // }
  }

  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  handleChangeTab = (event, tab) => {
    this.setState({ tab, loading: true });
    setTimeout(() => {
      this.setState({ tab, loading: false });
    }, 200);
    this.props.history.push(`/app/dashboard-v2?activeTab=${tab}`);
  };

  getConfigValue(array, key, value) {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  }

  render() {
    const { classes, dashboards, dashboardFetchProgress } = this.props;

    const { tab, loading } = this.state;

    let isRequestType = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "REQUEST_TYPES");
    let requestTypesData = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "REQUEST_TYPES") || [];
    let requestTypes = [];
    if (
      isRequestType &&
      Validator.isJson(requestTypesData) &&
      requestTypesData !== undefined &&
      requestTypesData !== "NULL" &&
      requestTypesData !== null &&
      requestTypesData !== "null" &&
      requestTypesData.length > 0
    ) {
      requestTypes = JSON.parse(requestTypesData);
    }


    if (!dashboardFetchProgress && dashboards?.data?.length === 0) {
      return (
        <div className={classes.root}>
          <AppBarV2 title="Dashboard" withBack={true} dropdown={true} />
          <div className={classes.DashboardNoDataLayout}>
            <div style={{ height: 220 }}>
              <Image src={ReviewsEmptyState} />
            </div>
            <h2>No data found</h2>
            {["OrgAdmin", "OrgManager"].includes(this.props.userRole) && (
              <>
                <div style={{ height: 50 }}>
                  <p>Get started on your first dashboard by tapping Dashboard below</p>
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    this.props.history.push("/app/dashboard-v2/list");
                  }}
                >
                  Dashboard
                </Button>
              </>
            )}
          </div>
        </div>
      );
    }

    let tabView = [];
    if (dashboards?.data?.length > 0 && dashboards?.data[tab]) {
      tabView = dashboards?.data[tab];
    }

    let layout = [];
    let configuration = tabView.configuration;

    if (Validator.isJson(configuration)) {
      layout = JSON.parse(configuration, function (key, value) {
        return value === "Infinity" ? Infinity : value;
      });
    }

    return (
      <div className={classes.root}>
        <AppBarV2 title="Dashboard" withBack={true} dropdown={true} />
        <div className={classes.rootContainer}>
          {["OrgAdmin", "OrgManager"].includes(this.props.userRole) && (
            <Grid container style={{ marginBottom: "0.5rem", justifyContent: "end" }}>
              <Grid item>
                <SqureButton variant={"contained"} onClick={() => this.props.history.push("/app/dashboard-v2/list")}>
                  Dashboards
                </SqureButton>
              </Grid>
            </Grid>
          )}
          {loading ? (
            <Backdrop className={classes.fullSceen} open={true} transitionDuration={100}>
              {/* <CircularProgress /> */}
            </Backdrop>
          ) : (
            <>
              <Grid container alignItems="center" className={classes.mb16}>
                <Grid item md={12}>
                  {dashboards && dashboards?.data?.length > 0 && (
                    <Tabs
                      value={tab}
                      onChange={this.handleChangeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      className={classes.tabs}
                    >
                      {dashboards?.data?.map((obj, index) => (
                        <Tab
                          key={index}
                          id={index}
                          label={
                            <Grid container alignItems="center" justifyContent="center" id={index}>
                              <Grid item id={index}>
                                {obj.name}
                              </Grid>
                            </Grid>
                          }
                          {...this.a11yProps(0)}
                        />
                      ))}
                    </Tabs>
                  )}
                </Grid>
              </Grid>
              {dashboards?.data?.length > 0 && <DashboardRender layout={layout} editMode={false} />}
            </>
          )}
        </div>
        <ProductSelectionDialog isRequestType={isRequestType && requestTypes.length > 0 ? true : false} requestTypes={requestTypes} />
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(DashboardHome));
