import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Button, Dialog, DialogContent, Slide, Typography, Grid, InputAdornment } from '@material-ui/core';
import { fetchEvaluationMetaData, updateEvaluationMetaData } from "../../../../../../redux/evaluation/metadata/action";

import OutlinedInput from "../../../../../Common/Input/OutlinedInput";
import Alert from '@material-ui/lab/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarImage from "../../../../../../assets/images/calendar.svg"
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import whiteTick from "assets/images/whitetick.svg";
import Image from 'Components/Common/image.jsx';
import classnames from "classnames";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import queryString from 'query-string';
import { hideUpdateSurveyDialog } from "redux/survey/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.survey.isShowUpdateSurveyDialog,
    isEvaluationMetaEditPage: state.evaluationMetaData.isEvaluationMetaEditPage,
    evaluation: state.evaluationMetaData.evaluation,
    evaluationId: state.evaluationMetaData.evaluationId,
    fetchProgress: state.evaluationMetaData.fetchProgress,
    updateProgress: state.evaluationMetaData.updateProgress,
    updateError: state.evaluationMetaData.errorUpdate,
    evaluateView: state.applications.evaluateView,
    evaluateViewId: state.applications.evaluateViewId,
    evaluateNeverReview: state.applications.evaluateNeverReview,
});

const connectionActions = {
    hideDialog: hideUpdateSurveyDialog,
    fetchDetails: fetchEvaluationMetaData,
    updateDetails: updateEvaluationMetaData,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: "0px !important"
    },
    title: {
        fontSize: theme.spacing(2.5),
        marginBottom: theme.spacing(2)
    },
    helptext: {
        fontSize: theme.spacing(1.8)
    },
    alerts: {
        marginTop: theme.spacing(2)
    },
    form: {
        marginTop: theme.spacing(2),
        // width:'60%',
    },
    input: {
        marginBottom: theme.spacing(3),
        "& [class*='MuiFormLabel-root']": {
            color: '#282D30',
            fontWeight: 500,
        },
        "& [class*=MuiOutlinedInput-root]": {
            minHeight: 40,
            color: '#282D30',
            padding: 0
        }
    },
    scheduleTitle: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 500,
        fontSize: theme.spacing(2)
    },
    scheduleTitle1: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 400,
        fontSize: theme.spacing(2)
    },
    actions: {
        marginTop: theme.spacing(3)
    },
    loader: {
        textAlign: "center",
        padding: theme.spacing(10, 0)
    },
    dialog: {
        "& [class*='MuiOutlinedInput-input']": {
            fontSize: theme.spacing(1.8)
        }
    },
    datePickers: {
        "& [class*='MuiFormLabel-root']": {
            color: '#475867'
        },
        "& [class*=MuiOutlinedInput-root]": {
            minHeight: 40,
            color: '#282D30'
        }
    },
    stepSection: {
        "&.evaluate-review-details": {
            "& input, [class*=MuiOutlinedInput-root], .editor-formControl > [class*=MuiPaper-root]": {
                PointerEvent: 'none',
                background: '#f5f5f5'
            }
        },

    },
    stepLeftSection: {
        background: '#20253A',
        boxShadow: '4px 0px 4px #2B2D3703',
        minWidth: 278,
        width: 278
    },
    stepRightSection: {
        padding: 50,
        // minWidth: 700,
        // maxWidth: 800
        // minWidth: 600
    },
    menu: {
        marginTop: theme.spacing(5),
        marginRight: 13,
        "& .step-line": {
            borderLeft: "2px solid #4A87F8"
        },
        "& .step-hideIt": {
            boxShadow: 'none',
            visibility: 'visible !important',
            // backgroundImage: 'none !important'

        },
        "& .step-circle": {
            border: '1px solid #4A87F8',
            background: '#202639',
            boxShadow: 'none',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '4px 4.5px'
        },
        "& .step-completed .step-circle": {
            backgroundColor: '#4A87F8',
            backgroundImage: `url(${whiteTick})`,
        },
        "& [class*=MuiTypography-root]": {
            fontSize: 13,
            textAlign: 'left',
            paddingTop: 1
        }
    },
    outlined: {
        color: "#707070",
        padding: '6px 26px',
        borderColor: "#707070",
        "&:hover": {
            color: "#707070",
            borderColor: "#707070",
        }
    },
    contained: {
        color: '#fff',
        padding: '6px 26px',
        backgroundColor: "#3C3C3C",
        "&:hover": {
            backgroundColor: "#3C3C3C",
        }
    },
    image: {
        width: theme.spacing(3.5),
        marginRight: 20,
        marginTop: 5,
        cursor: 'pointer'
    },
    headSection: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    stepHead: {
        color: '#282D30',
        fontSize: 18,
        margin: 0,
        fontWeight: 500,
        marginBottom: 10
    },
    stepSubHead: {
        color: '#4B4B4B',
        fontSize: 12,
        margin: 0,
        paddingBottom: 20
    },
    inputLabel: {
        display: 'flex',
        border: '2px solid #EEEEEE',
        borderRadius: 10,
        alignItems: 'center',
        margin: '0px 25px 20px 0px',
        padding: 7
    },
    inputLabelSelected: {
        border: '2px solid #4b86f8',
    },
    categoryText: {
        color: '#282D30',
        fontSize: 14,
        fontWeight: 600,
        margin: 0,

    },
    categoryTextSection: {
        paddingLeft: 10
    },
    categorySubText: {
        color: '#282D30',
        fontSize: 12,
        opacity: .6
    },
    categorySection: {
        padding: 50,
        minWidth: 735,
        width: 735,
        minHeight: 800
    },
    categoryImage: {
        paddingLeft: 10,
        width: 57,
        maxWidth: 57,
        minWidth: 57
    },
    rightStepTwo: {
        display: 'flex'
    },
    previewSection: {
        background: '#F7F7F7',
        minWidth: 353,
        padding: '50px 30px'
    },
    previewText: {
        color: '#282D30',
        fontSize: 16,
        fontWeight: 500,
        margin: 0
    },
    previewSubText: {
        marginTop: 0,
        marginBottom: 25,
        fontSize: 12,
        color: '#4B4B4B'
    },
    requirementText: {
        color: '#282D30',
        fontSize: 14,
        fontWeight: 500,
        margin: '0px 0px 10px 0px'
    },
    questionText: {
        color: '#707070',
        fontSize: 13,
        margin: '0px 0px 17px 0px'
    },
    categoryList: {
        marginTop: 20
    },
    textSection: {
        marginTop: 40,
        lineHeight: 1.5
    },
    head: {
        color: '#282D30',
        fontSize: 14,
        marginBottom: 0,
        fontWeight: 500
    },
    featureLabel: {
        border: '1px solid #4A87F8',
        borderRadius: 20,
        padding: '7px 16px',
        marginRight: 25,
        marginTop: 18,
        color: '#4A87F8',
        display: 'inline-flex',
        fontSize: 13,
        cursor: 'pointer',
        transition: 'all'
    },
    iconEle: {
        fontSize: 19,
        marginLeft: 10
    },
    selectedLabel: {
        background: '#4A87F8',
        color: '#fff',
        cursor: 'default'
    },
    removeIcon: {
        display: 'inline-flex',
        cursor: 'pointer',
        marginLeft: 10,
        "& [class*=iconEle]": {
            marginLeft: 0,
        }
    },
    features: {
        overflow: 'auto',
        height: 200,
        marginBottom: 30
    },
    radioBox: {
        width: 13,
        height: 13,
        maxWidth: 13,
        minWidth: 13
    },
    previewInnerSection: {
        maxHeight: 650,
        overflow: 'overlay',
        paddingRight: 10
    },
    backdrop: {
        marginTop: 30,
        height: 100,
        textAlign: 'center'
    },
    editorSection: {
        border: '1px solid #ddd',
        borderRadius: 5,
        marginBottom: 20
    }
});

class EvaluationMetaDataUpdateDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            name: "",
            name_error: "",
            problemStatement: "",
            problemStatement_error: "",
            criteriaSelectionStart: null,
            criteriaSelectionStart_error: "",
            criteriaSelectionEnd: null,
            criteriaSelectionEnd_error: "",
            evaluationStart: null,
            evaluationStart_error: "",
            evaluationEnd: null,
            evaluationEnd_error: "",
            step: 1,
            selectedFeatureList: [],
            featureSize: 50,
            featurePage: 0,
            listOfFeature: [],
            featureQuery: '',
            previewSize: 50,
            previewPage: 0,
            previewQuery: '',
            subCategoryId: '',
            stepTwoError: '',
            isUpdatePage: false,
            subCatQuery: null,
            queryParams: queryString.parse(this.props.location.search),
            featureId: [],
            preDefinedFeature: true,
            defaultTemplateType: "MASTER",
            defaultCriteriaIds: []
        }
        this.submitEvaluationMeta = this.submitEvaluationMeta.bind(this);
    }

    componentDidMount() {
        this.initSettings();
    }

    componentDidUpdate(prevProps) {
        if (this.props.updateProgress === false && prevProps.updateProgress === true) {
            if (this.props.updateError === null) {
                this.props.hideDialog();
                if (this.props.evaluationId) {
                    this.props.fetchDetails(this.props.evaluationId);
                }
            } else {
                if (this.props.updateError.message !== undefined) {
                    var errorMessage = this.props.updateError.message;
                    if (errorMessage.indexOf("NotFound Error") !== -1) {
                        this.setState({
                            "error": "Failed to update Evaluation Details."
                        })
                    } else {
                        this.setState({
                            "error": "Something went wrong."
                        })
                    }
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }
    }

    initSettings() {
        var state = this.state;
        var evaluation = this.props.evaluation;
        if (evaluation.Name !== null) {
            state.name = evaluation.Name
        }

        if (evaluation.ProblemStatement !== null) {
            state.problemStatement = evaluation.ProblemStatement
        }

        if (evaluation.CriteriaSelectionSd !== null) {
            state.criteriaSelectionStart = new Date(evaluation.CriteriaSelectionSd)
        }

        if (evaluation.CriteriaSelectionEd !== null) {
            state.criteriaSelectionEnd = new Date(evaluation.CriteriaSelectionEd)
        }

        if (evaluation.EvaluationSd !== null) {
            state.evaluationStart = new Date(evaluation.EvaluationSd)
        }

        if (evaluation.EvaluationEd !== null) {
            state.evaluationEnd = new Date(evaluation.EvaluationEd)
        }
        this.setState(state);
    }

    submitEvaluationMeta() {
        this.setState({
            error: "",
            success: "",
            name_error: "",
            problemStatement_error: "",
            criteriaSelectionStart_error: "",
            criteriaSelectionEnd_error: "",
            evaluationStart_error: "",
            evaluationEnd_error: "",
        });

        var name = this.state.name;
        if (name === null || name.trim().length === 0) {
            this.setState({
                name_error: ` Please specify name of survey.`
            })
            return
        }

        var problemStatement = this.state.problemStatement;
        if (problemStatement === null || problemStatement.trim().length === 0) {
            this.setState({
                problemStatement_error: `Please specify survey goal.`
            })
            return
        }

        var evaluationStart = this.state.evaluationStart;
        if (evaluationStart === null) {
            this.setState({
                evaluationStart_error: "Please select."
            })
            return
        }

        var evaluationEnd = this.state.evaluationEnd;
        if (evaluationEnd === null) {
            this.setState({
                evaluationEnd_error: "Please select."
            })
            return
        }

        this.updateDetails(1);
    }

    updateDetails = (status, options = {}) => {
        this.props.updateDetails(this.props.evaluation.ID, {
            name: this.state.name,
            problemStatement: this.state.problemStatement,
            evaluationSd: this.state.evaluationStart.toISOString(),
            evaluationEd: this.state.evaluationEnd.toISOString(),
            ...options
        })
    }


    evaluationEndChange = (date) => {
        this.setState({
            evaluationEnd: date
        })

    }

    evaluationStartChange = (date) => {
        this.setState({
            evaluationStart: date,
        })
        if (this.state.evaluationEnd) {
            var diff = moment(date).diff(moment(this.state.evaluationEnd), 'days');
            if (diff > 0) {
                this.setState({
                    evaluationEnd: null,
                })
            }
        }
    }


    render() {
        const classes = this.props.classes;

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={'md'}
            scroll="body"
            className={classes.dialog}
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classnames(classes.stepSection, this.props.evaluateView ? 'evaluate-review-details' : '')}>
                    <div>
                        {<div className={classes.stepRightSection}>
                            {this.props.fetchProgress && <div className={classes.loader}>
                                <CircularProgress />
                            </div>}
                            {!this.props.fetchProgress && <React.Fragment>
                                <div className={classes.headSection}>
                                    <div>
                                        <p className={classes.stepHead}>Survey details</p>
                                        <p className={classes.stepSubHead}>Provide details about your survey below so we can help you keep your survey on track. </p>
                                    </div>
                                </div>
                                <div className={classes.alerts}>
                                    {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                                    {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                                </div>
                                <div className={classes.form}>
                                    <div className={classes.input}>
                                        <OutlinedInput
                                            id={"nameOfEvaluation"}
                                            label={`Name your survey`}
                                            value={this.state.name}
                                            onChange={(event) => {
                                                this.setState({ name: event.target.value });
                                            }}

                                            error={this.state.name_error.length > 0}
                                            helperText={this.state.name_error}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <SlateInputField
                                            label={`Survey goal`}
                                            error={this.state.problemStatement_error.length > 0}
                                            helperText={this.state.problemStatement_error}
                                            placeholder=""
                                            folderPath={`/evaluation/${this.props?.evaluation?.ID}`}
                                            as={SlateInputField}
                                            onChangeEvent={(value) => {
                                                this.setState({ problemStatement: value });
                                            }}
                                            initValue={this.state.problemStatement}
                                            textContainerStyle={{}} readOnly={this.props.evaluateView ? true : false}
                                            isToolBar={this.props.evaluateView ? false : true} />
                                    </div>

                                    <Typography variant={"body1"} className={classes.scheduleTitle}>Survey schedule</Typography>
                                    <Grid container spacing={4} className={classes.datePickers}>
                                        <Grid item xs={6}>
                                            <DatePicker
                                                selected={this.state.evaluationStart}
                                                onChange={(date) => this.evaluationStartChange(date)}
                                                dateFormat="MMM do yyyy"
                                                portalId="evMetaDialog"
                                                minDate={this.state.criteriaSelectionEnd ? this.state.criteriaSelectionEnd : null}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                customInput={
                                                    <OutlinedInput
                                                        label="Start Date"
                                                        readOnlyInput={true}
                                                        error={this.state.evaluationStart_error.length > 0}
                                                        helperText={this.state.evaluationStart_error}
                                                        startAdornment={
                                                            <InputAdornment>
                                                                <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                                            </InputAdornment>
                                                        }
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DatePicker
                                                selected={this.state.evaluationEnd}
                                                onChange={(date) => this.evaluationEndChange(date)}
                                                dateFormat="MMM do yyyy"
                                                portalId="evMetaDialog"
                                                minDate={this.state.evaluationStart ? this.state.evaluationStart : null}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                customInput={
                                                    <OutlinedInput
                                                        label="End Date"
                                                        readOnlyInput={true}
                                                        error={this.state.evaluationEnd_error.length > 0}
                                                        helperText={this.state.evaluationEnd_error}
                                                        startAdornment={
                                                            <InputAdornment>
                                                                <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                                            </InputAdornment>
                                                        }
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                {this.props.updateProgress && <Grid container className={classes.actions}>
                                    <Grid item xs={12} style={{ "textAlign": "right" }}>
                                        <CircularProgress />
                                    </Grid>
                                </Grid>}
                                {!this.props.updateProgress && <Grid container className={classes.actions}>
                                    <Grid item xs={6} style={{ "textAlign": "left" }}>
                                        {!this.props.evaluateView && <Button className={classes.outlined} variant="outlined" onClick={this.props.hideDialog}>
                                            Cancel
                                        </Button>}
                                    </Grid>
                                    <Grid item xs={6} style={{ "textAlign": "right" }}>
                                    
                                        {<Button className={classes.contained} onClick={() => this.submitEvaluationMeta()}>
                                            Save
                                        </Button>}
                                    </Grid>
                                </Grid>}
                            </React.Fragment>}
                        </div>}
                    </div>
                </div>

            </DialogContent>
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(EvaluationMetaDataUpdateDialog));