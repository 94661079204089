import { showSnackBar } from "redux/snackbar/action";
import { HttpFactory } from "../../../../services/httpService";
import * as Environment from "../../../../util/Environment"
export const START_ORG_EVALUATION_TEMPLATE_FETCH = "START_ORG_EVALUATION_TEMPLATE_FETCH";
export const END_ORG_EVALUATION_TEMPLATE_FETCH = "END_ORG_EVALUATION_TEMPLATE_FETCH";

export const START_ORG_EVALUATION_TEMPLATE_GET = "START_ORG_EVALUATION_TEMPLATE_GET";
export const END_ORG_EVALUATION_TEMPLATE_GET = "END_ORG_EVALUATION_TEMPLATE_GET";

export const SHOW_SAVE_ORG_EVALUATION_TEMPLATE_TO_DIALOG = "SHOW_SAVE_ORG_EVALUATION_TEMPLATE_TO_DIALOG";
export const HIDE_SAVE_ORG_EVALUATION_TEMPLATE_TO_DIALOG = "HIDE_SAVE_ORG_EVALUATION_TEMPLATE_TO_DIALOG";

export const START_SAVE_FROM_ORG_EVALUATION_TEMPLATE = "START_SAVE_FROM_ORG_EVALUATION_TEMPLATE";
export const END_SAVE_FROM_ORG_EVALUATION_TEMPLATE = "END_SAVE_FROM_ORG_EVALUATION_TEMPLATE";

export const START_DELETE_FROM_ORG_EVALUATION_TEMPLATE = "START_DELETE_FROM_ORG_EVALUATION_TEMPLATE";
export const END_DELETE_FROM_ORG_EVALUATION_TEMPLATE = "END_DELETE_FROM_ORG_EVALUATION_TEMPLATE";

export const START_DELETE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE = "START_DELETE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE";
export const END_DELETE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE = "END_DELETE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE";

export const START_ADD_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE = "START_ADD_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE";
export const END_ADD_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE = "END_ADD_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE";

export const START_UPDATE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE = "START_UPDATE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE";
export const END_UPDATE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE = "END_UPDATE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE";

export const START_DELETE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE = "START_DELETE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE";
export const END_DELETE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE = "END_DELETE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE";

export const START_ADD_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE = "START_ADD_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE";
export const END_ADD_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE = "END_ADD_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE";

export const START_UPDATE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE = "START_UPDATE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE";
export const END_UPDATE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE = "END_UPDATE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE";

export const START_UPDATE_FROM_ORG_REQUIREMENT_TEMPLATE_OPTIONS = "START_UPDATE_FROM_ORG_REQUIREMENT_TEMPLATE_OPTIONS";
export const END_UPDATE_FROM_ORG_REQUIREMENT_TEMPLATE_OPTIONS = "END_UPDATE_FROM_ORG_REQUIREMENT_TEMPLATE_OPTIONS";

export const START_TEMPLATE_CRITERIA_COLLOBORATOR_ADD = "START_TEMPLATE_CRITERIA_COLLOBORATOR_ADD";
export const END_TEMPLATE_CRITERIA_COLLOBORATOR_ADD = "END_TEMPLATE_CRITERIA_COLLOBORATOR_ADD";

export const START_TEMPLATE_REQUIREMENT_COLLOBORATOR_ADD = 'START_TEMPLATE_REQUIREMENT_COLLOBORATOR_ADD';
export const END_TEMPLATE_REQUIREMENT_COLLOBORATOR_ADD = 'END_TEMPLATE_REQUIREMENT_COLLOBORATOR_ADD';

export const START_UPDATE_TEMPLATE_CRITERIA_USER_ROLE = 'START_UPDATE_TEMPLATE_CRITERIA_USER_ROLE';
export const END_UPDATE_TEMPLATE_CRITERIA_USER_ROLE = 'END_UPDATE_TEMPLATE_CRITERIA_USER_ROLE';

export const START_REMOVE_TEMPLATE_CRITERIA_USER_ROLE = 'START_REMOVE_TEMPLATE_CRITERIA_USER_ROLE';
export const END_REMOVE_TEMPLATE_CRITERIA_USER_ROLE = 'END_REMOVE_TEMPLATE_CRITERIA_USER_ROLE';

export const START_REMOVE_TEMPLATE_REQUIREMENT_COLLABORATOR = "START_REMOVE_TEMPLATE_REQUIREMENT_COLLABORATOR";
export const END_REMOVE_TEMPLATE_REQUIREMENT_COLLABORATOR = "END_REMOVE_TEMPLATE_REQUIREMENT_COLLABORATOR";

export const START_TEMPLATE_RULE_SAVE = 'START_TEMPLATE_RULE_SAVE';
export const END_TEMPLATE_RULE_SAVE = 'END_TEMPLATE_RULE_SAVE';

export const START_FETCH_SELECTED_TEMPLATE_CRITERIA = "START_FETCH_SELECTED_TEMPLATE_CRITERIA";
export const END_FETCH_SELECTED_TEMPLATE_CRITERIA = "END_FETCH_SELECTED_TEMPLATE_CRITERIA";

export const SHOW_IMPORT_TEMPLATE_DIALOG = "SHOW_IMPORT_TEMPLATE_DIALOG";
export const HIDE_IMPORT_TEMPLATE_DIALOG = "HIDE_IMPORT_TEMPLATE_DIALOG";

export const START_UPLOAD_TEMPLATE_FILE = "START_UPLOAD_TEMPLATE_FILE";
export const END_UPLOAD_TEMPLATE_FILE = "END_UPLOAD_TEMPLATE_FILE";

export const START_REQUIREMENT_TEMPLATE_RULE_SAVE="START_REQUIREMENT_TEMPLATE_RULE_SAVE";
export const END_REQUIREMENT_TEMPLATE_RULE_SAVE="END_REQUIREMENT_TEMPLATE_RULE_SAVE";

function startFetchSelectedTemplateCriteria() {
    return {
        type: START_FETCH_SELECTED_TEMPLATE_CRITERIA,
        payload: {}
    };
}

function endFetchSelectedTemplateCriteria(success, error) {
    return {
        type: END_FETCH_SELECTED_TEMPLATE_CRITERIA,
        payload: {
            success, error
        }
    };
}

//Thunk to perform the authorizedUser
export function fetchSelectedTemplateCriteria(templateId) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/evaluation/org/${templateId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchSelectedTemplateCriteria());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchSelectedTemplateCriteria(response, error));
        } catch (e) {
            dispatch(endFetchSelectedTemplateCriteria(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startOrgEvaluationTemplateFetch(page) {
    return {
        type: START_ORG_EVALUATION_TEMPLATE_FETCH,
        payload: page
    };
}

function endOrgEvaluationTemplateFetch(success, error) {
    return {
        type: END_ORG_EVALUATION_TEMPLATE_FETCH,
        payload: {
            success, error
        }
    };
}

//Thunk to perform the authorizedUser
export function fetchOrgTemplates(page, pageSize) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/evaluation/org?t=${timestamp}&page=${page}&pageSize=${pageSize}`;
    return async (dispatch) => {
        dispatch(startOrgEvaluationTemplateFetch(page));
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endOrgEvaluationTemplateFetch(response, error));
        } catch (e) {
            dispatch(endOrgEvaluationTemplateFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startOrgEvaluationTemplateGet() {
    return {
        type: START_ORG_EVALUATION_TEMPLATE_GET,
        payload: {}
    };
}

function endOrgEvaluationTemplateGet(success, error) {
    return {
        type: END_ORG_EVALUATION_TEMPLATE_GET,
        payload: {
            success, error
        }
    };
}

//Thunk to perform the authorizedUser
export function fetchOrgTemplate(templateId, evaluationId) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/evaluation/org/${templateId}?t=${timestamp}`;
    if (evaluationId !== undefined && evaluationId !== null) {
        url = url + "&forEvaluation=" + evaluationId;
    }
    return async (dispatch) => {
        dispatch(startOrgEvaluationTemplateGet());
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url);
            dispatch(endOrgEvaluationTemplateGet(response, error));
        } catch (e) {
            dispatch(endOrgEvaluationTemplateGet(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function showSaveEvaluationAsTemplateDialog() {
    return {
        type: SHOW_SAVE_ORG_EVALUATION_TEMPLATE_TO_DIALOG,
        payload: {}
    };
}

export function hideSaveEvaluationAsTemplateDialog() {
    return {
        type: HIDE_SAVE_ORG_EVALUATION_TEMPLATE_TO_DIALOG,
        payload: {
        }
    };
}

function startSaveFromOrgEvaluationTemplate() {
    return {
        type: START_SAVE_FROM_ORG_EVALUATION_TEMPLATE,
        payload: {}
    };
}

function endSaveFromOrgEvaluationTemplate(success, error) {
    return {
        type: END_SAVE_FROM_ORG_EVALUATION_TEMPLATE,
        payload: {
            success, error
        }
    };
}

function startDeleteFromOrgEvaluationTemplate() {
    return {
        type: START_DELETE_FROM_ORG_EVALUATION_TEMPLATE,
        payload: {}
    };
}

function endDeleteFromOrgEvaluationTemplate(success, error) {
    return {
        type: END_DELETE_FROM_ORG_EVALUATION_TEMPLATE,
        payload: {
            success, error
        }
    };
}

function startDeleteFromOrgEvaluationCriteriaTemplate() {
    return {
        type: START_DELETE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE,
        payload: {}
    };
}

function endDeleteFromOrgEvaluationCriteriaTemplate(success, error) {
    return {
        type: END_DELETE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE,
        payload: {
            success, error
        }
    };
}

function startAddFromOrgEvaluationCriteriaTemplate() {
    return {
        type: START_ADD_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE,
        payload: {}
    };
}

function endAddFromOrgEvaluationCriteriaTemplate(success, error) {
    return {
        type: END_ADD_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE,
        payload: {
            success, error
        }
    };
}

function startUpdateFromOrgEvaluationCriteriaTemplate() {
    return {
        type: START_UPDATE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE,
        payload: {}
    };
}

function endUpdateFromOrgEvaluationCriteriaTemplate(success, error) {
    return {
        type: END_UPDATE_FROM_ORG_EVALUATION_CRITERIA_TEMPLATE,
        payload: {
            success, error
        }
    };
}

function startDeleteFromOrgEvaluationRequirementTemplate() {
    return {
        type: START_DELETE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE,
        payload: {}
    };
}

function endDeleteFromOrgEvaluationRequirementTemplate(success, error) {
    return {
        type: END_DELETE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE,
        payload: {
            success, error
        }
    };
}

function startAddFromOrgEvaluationRequirementTemplate() {
    return {
        type: START_ADD_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE,
        payload: {}
    };
}

function endAddFromOrgEvaluationRequirementTemplate(success, error) {
    return {
        type: END_ADD_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE,
        payload: {
            success, error
        }
    };
}

function startUpdateFromOrgEvaluationRequirementTemplate() {
    return {
        type: START_UPDATE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE,
        payload: {}
    };
}

function endUpdateFromOrgEvaluationRequirementTemplate(success, error) {
    return {
        type: END_UPDATE_FROM_ORG_EVALUATION_REQUIREMENT_TEMPLATE,
        payload: {
            success, error
        }
    };
}


function startOrgRequirementTemplateOptions() {
    return {
        type: START_UPDATE_FROM_ORG_REQUIREMENT_TEMPLATE_OPTIONS,
        payload: {}
    };
}

function endOrgRequirementTemplateOptions(success, error, id, criteria_id, requirement_id) {
    return {
        type: END_UPDATE_FROM_ORG_REQUIREMENT_TEMPLATE_OPTIONS,
        payload: {
            success, error, id, criteria_id, requirement_id
        }
    };
}

function startTemplateCriteriaColloboratorAdd() {
    return {
        type: START_TEMPLATE_CRITERIA_COLLOBORATOR_ADD,
        payload: {}
    };
}

function endTemplateCriteriaColloboratorAdd(criteriaId, isApprover, success, error) {
    return {
        type: END_TEMPLATE_CRITERIA_COLLOBORATOR_ADD,
        payload: {
            criteriaId, isApprover, success, error
        }
    };
}


function startTemplateRequirementColloboratorAdd() {
    return {
        type: START_TEMPLATE_REQUIREMENT_COLLOBORATOR_ADD,
        payload: {}
    };
}

function endTemplateRequirementColloboratorAdd(requirementId, success, error) {
    return {
        type: END_TEMPLATE_REQUIREMENT_COLLOBORATOR_ADD,
        payload: {
            requirementId, success, error
        }
    };
}

function startUpdateTemplateCriteriaColloborator() {
    return {
        type: START_UPDATE_TEMPLATE_CRITERIA_USER_ROLE,
        payload: {}
    };
}

function endUpdateTemplateCriteriaColloborator(criteriaId, isApprover,priority, success, error) {
    return {
        type: END_UPDATE_TEMPLATE_CRITERIA_USER_ROLE,
        payload: {
            criteriaId, isApprover, priority, success, error
        }
    };
}

function startRemoveTemplateCriteriaApprover() {
    return {
        type: START_REMOVE_TEMPLATE_CRITERIA_USER_ROLE,
        payload: {}
    };
}

function endRemoveTemplateCriteriaApprover(criteriaId, userId, success, error) {
    return {
        type: END_REMOVE_TEMPLATE_CRITERIA_USER_ROLE,
        payload: {
            criteriaId, userId, success, error
        }
    };
}

function startRemoveTemplateRequirementCollaborator() {
    return {
        type: START_REMOVE_TEMPLATE_REQUIREMENT_COLLABORATOR,
        payload: {}
    };
}

function endRemoveTemplateRequirementCollaborator(requirementId, colloboratorId, success, error) {
    return {
        type: END_REMOVE_TEMPLATE_REQUIREMENT_COLLABORATOR,
        payload: {
            requirementId, colloboratorId, success, error
        }
    };
}





//Thunk to perform the authorizedUser
export function saveFromOrgEvaluationTemplate(data) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/evaluation/org?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startSaveFromOrgEvaluationTemplate());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, data);
            dispatch(endSaveFromOrgEvaluationTemplate(response, error));
        } catch (e) {
            dispatch(endSaveFromOrgEvaluationTemplate(null, {
                message: e.message
            }));
            return;
        }
    };
}

//Thunk to perform the authorizedUser
export function updateFromOrgEvaluationTemplate(templateId, data) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/evaluation/org/${templateId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startSaveFromOrgEvaluationTemplate());
        try {
            let [response, error] = await await HttpFactory.instance().putMethod(url, data);
            dispatch(endSaveFromOrgEvaluationTemplate(response, error));
        } catch (e) {
            dispatch(endSaveFromOrgEvaluationTemplate(null, {
                message: e.message
            }));
            return;
        }
    };
}


export function deleteFromOrgEvaluationTemplate(id) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/evaluation/org/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteFromOrgEvaluationTemplate());
        try {
            let [response, error] = await await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteFromOrgEvaluationTemplate(response, error));
        } catch (e) {
            dispatch(endDeleteFromOrgEvaluationTemplate(null, {
                message: e.message
            }));
            return;
        }
    };
}


export function deleteFromOrgEvaluationCriteriaTemplate(id, criteriaId) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();

    var url = `${api_server}/evaluation/org/${id}/criteria/${criteriaId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteFromOrgEvaluationCriteriaTemplate());
        try {
            let [response, error] = await await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteFromOrgEvaluationCriteriaTemplate(response, error));
            dispatch(fetchOrgTemplate(id))
            const msgData = {
                message: 'Criteria Deleted successfully',
                type: 'success'
            }
            if (error !== null) {
                msgData.type = 'error'
                if (error.message !== undefined) {
                    msgData.message = 'Failed to update criteria.'
                } else {
                    msgData.message = 'Something went wrong.'
                }
            }
            dispatch(showSnackBar(msgData.message, msgData.type, 3000))
        } catch (e) {
            dispatch(endDeleteFromOrgEvaluationCriteriaTemplate(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function addFromOrgEvaluationCriteriaTemplate(id, data) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();

    var url = `${api_server}/evaluation/org/${id}/criteria?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddFromOrgEvaluationCriteriaTemplate());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddFromOrgEvaluationCriteriaTemplate(response, error));
            dispatch(fetchOrgTemplate(id))
        } catch (e) {
            dispatch(endAddFromOrgEvaluationCriteriaTemplate(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function updateFromOrgEvaluationCriteriaTemplate(id, criteriaId, data) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();

    var url = `${api_server}/evaluation/org/${id}/criteria/${criteriaId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateFromOrgEvaluationCriteriaTemplate());
        try {
            let [response, error] = await await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateFromOrgEvaluationCriteriaTemplate(response, error));
        } catch (e) {
            dispatch(endUpdateFromOrgEvaluationCriteriaTemplate(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function deleteFromOrgEvaluationRequirementTemplate(id, criteriaId, requirementId) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();

    var url = `${api_server}/evaluation/org/${id}/criteria/${criteriaId}/requirement/${requirementId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteFromOrgEvaluationRequirementTemplate());
        try {
            let [response, error] = await await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteFromOrgEvaluationRequirementTemplate(response, error));
            dispatch(fetchOrgTemplate(id))
            const msgData = {
                message: 'Question Deleted successfully',
                type: 'success'
            }
            if (error !== null) {
                msgData.type = 'error'
                if (error.message !== undefined) {
                    msgData.message = 'Failed to update Question.'
                } else {
                    msgData.message = 'Something went wrong.'
                }
            }
            dispatch(showSnackBar(msgData.message, msgData.type, 3000))
        } catch (e) {
            dispatch(endDeleteFromOrgEvaluationRequirementTemplate(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function addFromOrgEvaluationRequirementTemplate(id, criteriaId, data) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();

    var url = `${api_server}/evaluation/org/${id}/criteria/${criteriaId}/requirement?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddFromOrgEvaluationRequirementTemplate());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddFromOrgEvaluationRequirementTemplate(response, error));
            dispatch(fetchOrgTemplate(id))
        } catch (e) {
            dispatch(endAddFromOrgEvaluationRequirementTemplate(null, {
                message: e.message
            }));
            return;
        }
    };
}



export function updateFromOrgEvaluationRequirementTemplate(id, criteriaId, requirementId, data) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();

    var url = `${api_server}/evaluation/org/${id}/criteria/${criteriaId}/requirement/${requirementId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateFromOrgEvaluationRequirementTemplate());
        try {
            let [response, error] = await await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateFromOrgEvaluationRequirementTemplate(response, error));
            dispatch(fetchOrgTemplate(id))
            const msgData = {
                message: 'Question updated successfully',
                type: 'success'
            }
            if (error !== null) {
                msgData.type = 'error'
                if (error.message !== undefined) {
                    msgData.message = 'Failed to update criteria.'
                } else {
                    msgData.message = 'Something went wrong.'
                }
            }
            dispatch(showSnackBar(msgData.message, msgData.type, 3000))
        } catch (e) {
            dispatch(endUpdateFromOrgEvaluationRequirementTemplate(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function updateOrgRequirementTemplateOptions(id, criteria_id, requirement_id, options) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();

    const url = `${api_server}/evaluation/org/${id}/requirement/${requirement_id}/options?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startOrgRequirementTemplateOptions());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, options);
            dispatch(endOrgRequirementTemplateOptions(response, error, id, criteria_id, requirement_id));
            dispatch(fetchOrgTemplate(id))
        } catch (e) {
            dispatch(endOrgRequirementTemplateOptions(null, {
                message: e?.message
            }, requirement_id));
            return;
        }
    };
}

export function addTemplateCriteriaColloborator(id, criteriaId, userId, isApprover = false) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${id}/criteria/${criteriaId}/colloborator?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startTemplateCriteriaColloboratorAdd());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, {
                userId: userId,
                isApprover: isApprover
            });
            dispatch(endTemplateCriteriaColloboratorAdd(criteriaId, isApprover, response, error));
        } catch (e) {
            dispatch(endTemplateCriteriaColloboratorAdd(null, null, null, {
                message: e.message
            }));
            return;
        }
    };
}

export function addTemplateRequirementColloborator(id, criteriaId, requirementId, userId) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${id}/criteria/${criteriaId}/requirement/${requirementId}/colloborator?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startTemplateRequirementColloboratorAdd());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, {
                userId: userId
            });
            dispatch(endTemplateRequirementColloboratorAdd(requirementId, response, error));
        } catch (e) {
            dispatch(endTemplateRequirementColloboratorAdd(null, null, {
                message: e.message
            }));
            return;
        }
    };
}

//Thunk to perform the authorizedUser
export function updateTemplateCriteriaColloborator(id, criteriaId, userId, isApprover, priority = null) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${id}/criteria/${criteriaId}/colloborator?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateTemplateCriteriaColloborator());
        try {
            let params = {
                userId: userId
            }
            if(isApprover !== null){
                params['isApprover'] = isApprover;
            }
            if(priority !== null){
                params['priority'] = priority;
            }
            let [response, error] = await HttpFactory.instance().putMethod(url, params);
            dispatch(endUpdateTemplateCriteriaColloborator(criteriaId, isApprover, priority, response, error));
        } catch (e) {
            dispatch(endUpdateTemplateCriteriaColloborator(null, null,null, null, {
                message: e.message
            }));
            return;
        }
    };
}

export function removeTemplateCriteriaApprover(id, criteriaId, approverId) {
    const api_server = Environment.api_host("TEMPLATES");
    const url = `${api_server}/${id}/criteria/${criteriaId}/colloborator/${approverId}`;
    return async (dispatch) => {
        dispatch(startRemoveTemplateCriteriaApprover());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endRemoveTemplateCriteriaApprover(criteriaId, approverId, response, error));
        } catch (e) {
            dispatch(endRemoveTemplateCriteriaApprover(null, null, null, {
                message: e.message
            }));
            return;
        }
    };
}

export function removeTemplateRequirementCollaborator(id, criteriaId, requirementId, colloboratorId) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${id}/criteria/${criteriaId}/requirement/${requirementId}/colloborator/${colloboratorId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startRemoveTemplateRequirementCollaborator());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endRemoveTemplateRequirementCollaborator(requirementId, colloboratorId, response, error));
        } catch (e) {
            dispatch(endRemoveTemplateRequirementCollaborator(null, null, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startTemplateRuleSave() {
    return {
        type: START_TEMPLATE_RULE_SAVE,
        payload: {}
    };
}

function endTemplateRuleSave(success, error) {
    return {
        type: END_TEMPLATE_RULE_SAVE,
        payload: {
            success, error
        }
    };
}

export function saveTemplateRule(id, criteriaId, rules) {
    const api_server = Environment.api_host("TEMPLATES");
    var url = `${api_server}/evaluation/org/${id}/criteria/${criteriaId}/rule`;
    return async (dispatch) => {
        dispatch(startTemplateRuleSave());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, {
                rules: rules
            });
            dispatch(endTemplateRuleSave(response, error));
            dispatch(fetchOrgTemplate(id))
        } catch (e) {
            dispatch(endTemplateRuleSave(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function showImportTemplateDialog() {
    return {
        type: SHOW_IMPORT_TEMPLATE_DIALOG,
        payload: null
    };
}

export function hideImportTemplateDialog() {
    return {
        type: HIDE_IMPORT_TEMPLATE_DIALOG,
        payload: null
    };
}

function startUploadUserFile() {
    return {
        type: START_UPLOAD_TEMPLATE_FILE,
        payload: null
    };
}

function endUploadUserFile(success, error) {
    return {
        type: END_UPLOAD_TEMPLATE_FILE,
        payload: {
            success, error
        }
    };
}

export function uploadFile(formData, obj = {}) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp = (new Date()).getTime();
    let url = ''
    if (Object.keys(obj).length > 0 && obj.template_type !== undefined) {
        url = `${api_server}/evaluation/org/import-add?t=${timestamp}&template_type=${obj.template_type}`;
    } else {
        url = `${api_server}/evaluation/org/import-add?t=${timestamp}`;
    }

    return async (dispatch) => {
        dispatch(startUploadUserFile());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, formData);
            dispatch(endUploadUserFile(response, error));
        } catch (e) {
            dispatch(endUploadUserFile(null, {
                message: e.message
            }));
            return;
        }
    };
}



function startTemplateRequirementRuleSave() {
    return {
        type: START_REQUIREMENT_TEMPLATE_RULE_SAVE,
        payload: {}
    };
}

function endTemplateRequirementRuleSave(success, error) {
    return {
        type: END_REQUIREMENT_TEMPLATE_RULE_SAVE,
        payload: {
            success, error
        }
    };
}

export function saveTemplateRequirementRule(id, reqId, rules) {
    const api_server = Environment.api_host("TEMPLATES");
    var url = `${api_server}/evaluation/org/${id}/requirement/${reqId}/rule`;
    return async (dispatch) => {
        dispatch(startTemplateRequirementRuleSave());
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, {
                rules: rules
            });
            dispatch(endTemplateRequirementRuleSave(response, error));
            dispatch(fetchOrgTemplate(id))
        } catch (e) {
            dispatch(endTemplateRequirementRuleSave(null, {
                message: e.message
            }));
            return;
        }
    };
}