import {
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";

import { hideSelectOptionFieldsDialog } from 'redux/automations/action';
import Checkbox from '@material-ui/core/Checkbox';
import SqureButton from 'Components/Common/SqureButton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.automation.dialogSelectAutomationOptionFieldsOpen,
});

const connectionActions = {
    hideDialog: hideSelectOptionFieldsDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 20
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    countOp: {
        marginTop: 20,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    opLibe: {
        borderTop: '1px solid #ced5db',
        margin: 10,
        flex: 1
    },
    criteriaAllSelect: {
        marginRight: 10,
        padding: 0
    },
    UL: {
        width: '100%',
        padding: '0px',
        paddingLeft: 10,
        maxHeight: '50vh',
        overflow: 'auto',
        //   position: 'relative',
        '& li': {
            listStyle: 'none',
            '& p': {
                margin: '0px'
            }
        }
    },
    multiSelect: {
        position: 'absolute',
        top: '-8px'
    },
    actionBtns:{
        textAlign:'right'
    }
});

class OptionalFieldsDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            optionalFields: [],
            selectedFields: [],
            totalFields: 0,
            checkAllFields: false
        }

        this.selectField = this.selectField.bind(this)
    }

    componentDidMount() {
        this.initializeItems()

    }

    componentDidUpdate(prevProps) {

    }

    initializeItems() {
        const { optionalItems,alreadySelected } = this.props;
        console.log(optionalItems, 'optionalItems')
        console.log(alreadySelected, 'alreadySelected')
        let items = []
        optionalItems.forEach((o) => {
            o['checked'] = false
            items.push(o)
        })
        this.setState({
            optionalFields: items,
            totalFields: optionalItems.length,
            selectedFields:alreadySelected
        })
    }

    selectAllFields() {
        let { selectedFields, totalFields, optionalFields } = this.state;
        let type = true;
        if (selectedFields.length === totalFields) {
            type = false
        }
        let newSelectedFields = []
        optionalFields.forEach((itm) => {
            itm.checked = type;
            if (type) {
                newSelectedFields.push(itm.name)
            }
        })
        this.setState({
            optionalFields: optionalFields,
            selectedFields: newSelectedFields
        }, () => {
            this.checkAllFields()
        })
    }

    checkAllFields() {
        let { selectedFields, totalFields } = this.state;
        let selectAll = false;
        if (selectedFields.length === totalFields) {
            selectAll = true
        }
        this.setState({
            checkAllFields: selectAll
        })
    }

    selectField(field) {
        let { selectedFields } = this.state;
        console.log(selectedFields, 'selectedFields')
        console.log(field, 'field')
        console.log(selectedFields.indexOf(field), 'selectedFields.indexOf(field)')
        if (selectedFields.indexOf(field) === -1) {
            selectedFields.push(field)
        } else {
            selectedFields.splice(selectedFields.indexOf(field), 1);
        }
        this.setState({
            selectedFields: selectedFields
        }, () => {
            this.checkAllFields()
        })
    }

    applyChanges() {
        const {selectedFields,optionalFields} = this.state;
        let fields = [];
        let selectedFieldIds = [];
        optionalFields.forEach(function(of){
            let id = of.name;
            if(selectedFields.indexOf(id) !== -1){
                fields.push(of)
                selectedFieldIds.push(id)
            }
        })
        this.props.onSelectionSubmit(fields,selectedFieldIds)
        this.props.hideDialog();
    }


    render() {
        const classes = this.props.classes;
        const { optionalFields, selectedFields, totalFields } = this.state;

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.head}>
                    <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                    <Typography variant={"h4"} className={classes.title}>Optional Fields</Typography>
                </div>
                <div className={classes.body}>
                    <div className={classes.countOp}>
                        <div className={classes.countTxt}>
                            {selectedFields.length > 0 && <>
                                {selectedFields.length} of {totalFields} results selected
                            </>}

                            {selectedFields.length <= 0 && <>
                                {totalFields} results
                            </>}
                        </div>
                        <hr className={classes.opLibe}></hr>
                    </div>

                    <div className={classes.opFields}>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 25 }}>
                            <Checkbox
                                label={'Select All'}
                                className={classes.criteriaAllSelect}
                                color="primary"
                                checked={this.state.checkAllFields}
                                onChange={() => {
                                    this.selectAllFields()
                                }}
                            />
                            <Typography>Select all</Typography>
                        </div>

                        <ul className={classes.UL}>
                            {optionalFields && optionalFields.map((field, index) => {
                                return <li style={{ position: 'relative' }} key={index + 'ask_requirement'}>
                                    <Checkbox
                                        className={classes.multiSelect}
                                        color="primary"
                                        checked={selectedFields.indexOf(field.name) !== -1 ? true : false}
                                        onChange={() => {
                                            this.selectField(field.name)
                                        }}
                                    />
                                    <p style={{ paddingLeft: 50, marginBottom: 15 }}>{field.label}</p>
                                </li>
                            })}
                        </ul>

                    </div>
                    <div className={classes.actionBtns}>
                        <SqureButton
                            cls={classes.actionBtn}
                            variant={"outlined"}
                            onClick={(e) => {
                                this.props.hideDialog()
                            }}
                        >
                            Cancel
                        </SqureButton> &nbsp;&nbsp;&nbsp;
                        <SqureButton
                            cls={classes.actionBtn}
                            variant={"contained"}
                            onClick={(e) => {
                                this.applyChanges()
                            }}
                        >
                            Apply Changes
                        </SqureButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    }
}

OptionalFieldsDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(OptionalFieldsDialog));