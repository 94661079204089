import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import ReqTypeHandler from "./ReqTypeHandler";
import { CommonFn } from "services/commonFn";
import { addReqToSellerLibrary } from "redux/seller/evaluate/action";

const connectedProps = (state) => ({
  loading: state.sellerEvaluate.addReqToSellerLibraryProgress,
});

const connectionActions = {
  addReqToSellerLibrary: addReqToSellerLibrary,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    requirementContainer: {
      padding: "30px 16px 10px 25px",
      justifyContent: "space-between",
    },
    bottomContainer: {
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
      borderRadius: 8,
      backgroundColor: "#FAFAFA",
      justifyContent: "right",
    },
    actionItem: {
      textAlign: "center",
      cursor: "pointer",
      marginRight: 4,
    },
    Btn: {
      marginLeft: 10,
    },
    filterTitle: {
      color: "#6C6C6C",
      fontSize: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
    },
    reqTypes: {
      marginBottom: 20,
    },
  });

class ReqAnswerUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      description_error: "",
      answer: "",
      note: "",
      answer_error: "",
      answer_type: 1,
      type: 1,
      options: [],
      selectedOptions: [],
      character_limit: "",
      is_other_option: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { response } = this.props;
    let answerType = response.answer_type;
    let options = response.options;
    let note = response?.note || "";
    let answerData = "";

    let resOptions = [];
    try {
      if (![2, 3, 14, 15].includes(answerType)) {
        let answer = response.answer;
        if (answerType === 11) {
          // answerData = CommonFn.formateDate(answer, true);
          answerData = answer;
        } else if (answerType === 6) {
          answerData = answer;
        } else if (answerType === 13) {
          answerData = answer;
        } else if (answerType === 1 || answerType === 7) {
          note = answer;
          answerData = answer;
        } else {
          answerData = answer;
        }
      } else if ([2, 3, 14, 15].includes(answerType) && options) {
        resOptions = response.options_answer;
      }
    } catch (e) {}

    this.setState({
      answer_type: answerType,
      answer: answerData,
      options: response.options || [],
      selectedOptions: [2, 3, 14, 15].includes(answerType) ? resOptions : [],
      note: note,
      isLoading: false,
      id: response.id,
    });
  }

  componentDidUpdate(prevProps) {}

  clearError = () => {
    this.setState({
      description_error: "",
      answer_error: "",
    });
  };

  updateRequirement() {
    const { answer_type, note, id, options, selectedOptions, character_limit } = this.state;
    let { answer } = this.state;

    if (parseInt(answer_type) === 8) {
    } else if (parseInt(answer_type) === 11) {
      answer = answer ? answer?.toISOString() : new Date().toISOString();
    }

    let params = {
      question_id: this.props.requirement?.id,
      answer_id: id,
      entity_type: "answer",
      answer: {
        answer_type: parseInt(answer_type),
        answer: answer,
        note: note || "",
        options: options,
        options_answer: selectedOptions,
      },
    };

    console.log(params, "params");

    this.props.addReqToSellerLibrary({
      source: { id: null, name: "library" },
      action: "update",
      data: [params],
    });
  }

  updateRequirementLocally(updatedData, isUpdateNow) {
    let options = [];
    let selectedOptions = [];
    if (updatedData.options && updatedData.options.length > 0) {
      options = updatedData.options;
    }

    if (updatedData.selectedOptions && updatedData.selectedOptions.length > 0 && updatedData.type === 2) {
      let sOptions = updatedData.selectedOptions.map((o) => o.value);

      let sellOps = [];
      updatedData.options.forEach(function (itm) {
        if (sOptions.indexOf(itm.description) !== -1) {
          sellOps.push(itm);
        }
      });
      selectedOptions = sellOps;
    } else if (updatedData.selectedOptions && updatedData.type === 3) {
      let index = updatedData.options.findIndex((o) => o.description === updatedData.selectedOptions.value);
      selectedOptions = [updatedData.options[index]];
    } else if (updatedData.selectedOptions && (updatedData.type === 4 || updatedData.type === 14)) {
      selectedOptions = [updatedData.selectedOptions];
    } else if (updatedData.selectedOptions && updatedData.type === 15) {
      let sellOps = [];
      updatedData.options.forEach(function (itm) {
        if (updatedData.selectedOptions.indexOf(itm.description) !== -1) {
          sellOps.push(itm);
        }
      });
      selectedOptions = sellOps;
    } else {
      selectedOptions = updatedData.selectedOptions;
    }

    let answer_type = updatedData.type;
    let type = updatedData.type;
    let character_limit = updatedData.character_limit;
    let is_other_option = updatedData.is_other_option;
    let note = updatedData.note;
    // let answer = updatedData.text_value;
    let answer = "";
    if (![2, 3, 14, 15].includes(answer_type)) {
      answer = updatedData.text_value;
    }

    this.setState({
      options: options,
      selectedOptions: selectedOptions,
      answer_type: answer_type,
      type: type,
      character_limit: character_limit,
      is_other_option: is_other_option,
      note: note,
      answer: answer,
    });
  }

  render() {
    const classes = this.props.classes;
    const { isLoading } = this.state;
    console.log(this.state, "Stateeeeeeeeeeeeeeeeeeee");

    if (isLoading) {
      return null;
    }

    return (
      <>
        <div className={classes.requirementContainer}>
          <div>
            <ReqTypeHandler
              requirement={this.state}
              isActive={true}
              isUpdateOnChange={true}
              onChangeData={(isUpdateNow, item) => {
                this.updateRequirementLocally(item, isUpdateNow);
              }}
            />
          </div>
        </div>
        <Grid container className={classes.bottomContainer}>
          <div className={classes.actionItem}>
            <Button
              className={classes.Btn}
              variant="outlined"
              color="secondary"
              onClick={() => {
                this.props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={this.props.loading}
              className={classes.Btn}
              variant="contained"
              color="secondary"
              onClick={() => {
                this.updateRequirement();
              }}
            >
              Update
            </Button>
          </div>
        </Grid>
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(ReqAnswerUpdate));
