import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import queryString from "query-string";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import AppBarV2 from "../../../AppBarV2";
import AddProductButton from "../../../../Common/AddProductButton";
import LayeredCard from "../../Evaluate/Components/Common/LayeredCard";
import SearchInput from "../../../../Common/Input/SearchInput";
import { fireEvent } from "redux/common/action";
import { matchPath } from 'react-router-dom';
import { storeSearchString } from "redux/applications/action";
// redux
import { productSearch, fetchProductReaction, fetchWishListedProducts } from "redux/product/action";
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import { createEvaluationCart, removeProductFromCart, fetchEvaluationCart } from "redux/evaluation/cart/action";
import { fetchProductSubCategory } from "redux/product/subcategory/action";
import { updateDrawerStyle } from "redux/drawer/action";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import { showSnackBar } from "redux/snackbar/action";
// Dilogs
import Breadcrumb from "Components/Common/Breadcrumb";
import viewMoreImage from "assets/images/icons/viewMore.svg";
import ProductTypeFilter from "Components/Common/Filters/ProductTypeFilter";
import { isEnabledEvaluate } from "util/Common";

import {
  fetchProductCategory,
} from "redux/product/category/action";

const connectedProps = (state) => ({
  evaluationCart: state.evaluationCart.evaluation,
  productFilters: state.products.productFilters,
  productsRec: state.products,
  addProductStateData: state.products.addProductStateData,
  fetchProductReactionByTypeProgress: state.products.fetchProductReactionByTypeProgress,
  deleteProductStateProg: state.products.deleteProductStateProg,
  addProductStateProg: state.products.addProductStateProg,
  recommendedProducts: state.products.recommendedProducts,
  experimentDetails: state.products.experimentDetails,
  recommendedSubcategory: state.products.recommendedSubcategory,
  orgUser: state.orgUser,
  userRole: state.authUser.user?.Role,
  searchString: state.applications.searchString,
  searchType: state.applications.searchType,
  fetchExpentDirectoryProductsProgress: state.expentDirectory.fetchExpentDirectoryProductsProgress,
  fetchExpentDirectoryProductsError: state.expentDirectory.fetchExpentDirectoryProductsError,
  expentDirectoryProducts: state.expentDirectory.products,
  deleteProductProgress: state.orgProduct.deleteProductProgress,
  categoryFetchProgress: state.productCategory.fetchCategoryProgress,
  categories: state.productCategory.categories,
  subCategories: state.productSubCategory.subCategories,
  subCategoryTotal: state.productSubCategory.subCategoryTotal,
  fetchSubCategoryProgress: state.productSubCategory.fetchSubCategoryProgress,
});

const connectionActions = {
  productSearch: productSearch,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  fetchProductReaction: fetchProductReaction,
  fetchEvaluationCart: fetchEvaluationCart,
  updateDrawerStyle: updateDrawerStyle,
  fetchWishListedProducts: fetchWishListedProducts,
  fireEvent: fireEvent,
  showSnackBar: showSnackBar,
  storeSearchString: storeSearchString,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  fetchProductCategory: fetchProductCategory,
  fetchProductSubCategory: fetchProductSubCategory,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      paddingTop: 0,
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
    },
    productContainer: {
      maxHeight: "calc(100vh - 230px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 230px)",
      padding: theme.spacing(4, 0),
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    label: {
      fontSize: "1.4em",
      fontWeight: 600,
      lineHeight: "1.6",
      marginBottom: 10,
    },
    serviceSidebar: {
      minWidth: '330px',
      maxWidth: '330px',
      width: '100%',
      padding: '1em 0.5em 1em 1.5em',
      background: '#FFFFFF',
      boxShadow: '0px 2px 10px #0000000b',
    },
    allService: {
      height: 'calc(100vh - 50px)',
      overflowY: 'overlay'
    },
    innerText: {
      color: "#696B6E",
      padding: theme.spacing(1, 1.5, 1, 1),
      cursor: "pointer",
      marginLeft: 11,
      fontSize: 15,
      display: "block",
      margin: 0,
    },
    activeService: {
      color: "#4A87F8 !important",
      background: "#F2F7FF",
      fontSize: 15,
      marginRight: '1.6em !important',
    },
    treeViewContainer: {
      display: "block",
      marginRight: '24px',
      '& [class*="MuiTreeItem-label"]': {
        padding: "0.4rem 0rem",
        fontSize: "14px",
      },
    }
  });

class AllProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerSectionWidth: null,
      queryParams: queryString.parse(this.props.location.search),
      checkBoxFilterStatus: {
        featured: false,
        wishlisted: false,
        org_wishlisted: false,
        all_product_blocking: false,
      },
      isCheckBoxFilter: false,
      selectedCheckBoxFilter: "",
      filters: {},
      selectedState: [],
      isScroll: true,
      lastLocation: this.props.lastLocation,
      searchRestored: false,
      searchObj: {
        field_setname: "all",
        text: "",
        filter_fields: {},
        page: 0,
        page_size: 60,
        sort_fields: [],
      },
      productType: "SOFTWARE",
      isForRequirementSelection:false,
      expandedCategory: [],
      selectedSubcategoryId: null,
      selectedCategoryId: null,
    };
  }

  componentDidMount() {
    this.props.fetchWishListedProducts();
    this.props.fetchProductCategory(0, 60, "");
    const urlPrams = new URLSearchParams(this.props.location.search);
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);
    var evId = urlPrams.get("evaluationId");
    if (evId !== null) {
      this.setState({
        isForRequirementSelection:true
      })
      setTimeout(() => {
        this.props.fetchEvaluationCart(evId);
      }, 1000);
    }
    if (urlPrams.get("s") !== null) {
      this.setState(
        {
          searchObj: { ...this.state.searchObj, text: urlPrams.get("s") },
        },
        () => this.fetchData()
      );
    }else if(urlPrams.get("searchType") !== null){
      let filters = {}
      if (urlPrams.get("searchType") === "Features") {
        filters['feature_ids'] = [urlPrams.get("searchValue")];
      } 
      this.setState({ filters }, () => {
        this.fetchData();
      });
    } else {
      this.fetchData();
    }
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProps) {

    const { lastLocation } = this.state;
    var pathName = lastLocation?.pathname;
    var isMatched = false;
    if (pathName !== undefined) {
      if (matchPath(pathName, {
        path: "/app/products/product-details/:id",
        exact: true,
        strict: false
      })) {
        isMatched = true;
      }
    }

    if (isMatched && pathName && this.props.searchString !== '' && this.state.searchRestored === false) {
      this.setState({
        searchObj: {...this.state.searchObj,field_setname: this.props.searchType, text: prevProps.searchString},
        searchRestored: true,
      }, (() => {
        this.fetchData();
      }))
    }

    if (!this.props.isSeller && !this.props.fetchExpentDirectoryProductsProgress && prevProps.fetchExpentDirectoryProductsProgress) {
      let prodIDs = [];
      let orgIDs = [];
      if (this.props.expentDirectoryProducts?.others?.items && this.props.expentDirectoryProducts?.others?.items.length > 0) {
        prodIDs = this.props.expentDirectoryProducts?.others?.items.map((p) => parseInt(p.id));
      }
      if (this.props.expentDirectoryProducts?.existing?.items && this.props.expentDirectoryProducts?.existing?.items.length > 0) {
        orgIDs = this.props.expentDirectoryProducts?.existing?.items.map((p) => parseInt(p.id));
      }
      this.getProductReactions([...orgIDs, ...prodIDs]);
      if (this.state.isScroll) {
        let count = 0;
        if (this.props.expentDirectoryProducts && this.props.expentDirectoryProducts?.others?.items) {
          count = this.props.expentDirectoryProducts?.others?.items.length;
        }

        this.setState({ isScroll: false }, () => {
          this.props.fireEvent({
            type: "event.scroll.product.on-all-products",
            data: {
              url: this.props.location.pathname + this.props?.location?.search,
              query: this.state.searchObj.text,
              page: this.state.searchObj.page,
              productCount: count,
            },
          });
        });
      }
    }

    if (!this.props.isSeller && !this.props.fetchRecommendedProductsProgress && prevProps.fetchRecommendedProductsProgress && this.props.recommendedProducts) {
      const productIDs = this.props.recommendedProducts.map((o) => o.ID);
      if (this.props.history?.location.search && productIDs) {
        this.props.fetchProductReaction("recommended", {
          productIDs,
          source: "recommended",
          sourceData: this.props.history.location.search.substring(1),
        });
        this.getProductReactions(productIDs);
      }
    }

    // if (prevProps.deleteProductProgress && !this.props.deleteProductProgress) {
    //   this.fetchData();
    // }

    if (prevProps.addProductStateProg && !this.props.addProductStateProg) {
      if (!this.props.addProductStateError) {
        this.props.showSnackBar("product state updated successfully", "success", 3000);
        if(Array.isArray(this.props.addProductStateData) && this.props.addProductStateData?.find(item=> (item.State === 'blocked' || item.State === 'deprecated' ))){
          // setTimeout(() => {
          //   this.fetchData();
          // }, 3000);
          window.location.reload()
        }else{
          this.fetchData();
        }
      } else {
        this.props.showSnackBar("failed to update product state", "error", 3000);
      }
    }

    if (prevProps.deleteProductStateProg && !this.props.deleteProductStateProg) {
      if (!this.props.deleteProductStateError) {
        this.props.showSnackBar("product state updated successfully", "success", 3000);
        this.fetchData();
      } else {
        this.props.showSnackBar("failed to update product state", "error", 3000);
      }
    }

    if (!this.props.isSeller && !this.props.fetchProductReactionByTypeProgress && prevProps.fetchProductReactionByTypeProgress) {
      var reactionProducts = this.props.productsRec?.wishlistProduct.Data || [];
      if (reactionProducts.length > 0) {
        const productIDs = reactionProducts.map((o) => o.ID);
        this.getProductReactions(productIDs);
      }
    }
    this.updateDimensions();
  }

  getProductReactions(productIDs) {
    this.props.fetchProductReaction("all_product_blocking", {
      productIDs,
      source: "all_product_blocking",
      sourceData: "all_product_blocking",
    });
    setTimeout(() => {
      this.props.fetchProductReaction('all_products_deprecating', {
        productIDs,
        source: 'all_products_deprecating',
        sourceData: 'all_products_deprecating'
      })
    }, 2000);
  }

  updateDimensions = () => {
    const headerSectionWidth = this.divElement.clientWidth;
    if (this.state.headerSectionWidth !== headerSectionWidth) {
      this.setState({ headerSectionWidth });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  fetchData() {
    const { searchObj, filters, selectedState, selectedSubcategoryId, selectedCategoryId } = this.state;
    searchObj.filter_fields = {};
    if (filters?.feature_ids && filters?.feature_ids.length > 0) {
      searchObj.filter_fields["feature_ids"] = filters?.feature_ids;
    }
    if (filters?.platform_ids && filters?.platform_ids.length > 0) {
      searchObj.filter_fields["platform_ids"] = filters?.platform_ids;
    }
    if (selectedState && selectedState.length > 0) {
      searchObj.filter_fields["state"] = selectedState;
    }
    if (selectedCategoryId && selectedCategoryId.length > 0) {
      searchObj.filter_fields["category_ids"] = [selectedCategoryId];
    }
    if (selectedSubcategoryId) {
      searchObj.filter_fields["subcategory_ids"] = [selectedSubcategoryId];
    }

    var searchText = "";
    if (searchObj?.text.length > 2) {
      searchText = searchObj?.text;
    }
    searchObj.text = searchText;
    this.props.fetchExpentDirectoryProducts(searchObj);
  }

  addToCart(id) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(parseInt(id));
    } else {
      var addedToCart = this.props.evaluationCart === null ? [] : this.props.evaluationCart?.Products?.filter((p) => parseInt(p.EntityID) === parseInt(id));
      if (addedToCart && addedToCart.length > 0) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, addedToCart[0].EntityObjectID);
      } else {
        this.props.createEvaluationCart(id, this.props.evaluationCart.ID, 'PRODUCT');
      }
    }
  }

  productStates = (obj) => {
    if (obj) {
      const data = Object.keys(obj).map((key) => ({
        StateName: key,
        StateID: obj[key],
      }));
      return data;
    } else {
      return [];
    }
  };

  setSearchType = (type) => {
    const searchObj = this.state.searchObj;
    if (type === "features") {
      searchObj.field_setname = "features";
    } else if (type === "subcategories") {
      searchObj.field_setname = "subcategories";
    }
    searchObj.page = 0;
    searchObj.text = "";
    this.setState({ searchObj }, () => {
      this.fetchData();
    });
  };

  handleOnClickState(newState) {
    let { selectedState } = this.state;
    let newStates = [...selectedState];
    if (newStates.includes(newState)) {
      newStates = newStates.filter((o) => o !== newState);
    } else {
      newStates.push(newState);
    }
    this.setState(
      {
        selectedState: newStates,
        searchObj: { ...this.state.searchObj, page: 0 },
      },
      () => {
        this.fetchData();
      }
    );
  }

  handleRedirect = (type) => {
    if (type === "VENDOR") {
      this.props.history.push(`/app/vendors/all-vendors`);
    } else if (type === "SOFTWARE") {
      this.props.history.push(`/app/products/all-products`);
    } else if (type === "HARDWARE") {
      this.props.history.push(`/app/vendors/hardwares`);
    } else if (type === "SERVICE") {
      this.props.history.push(`/app/vendors/services`);
    }
  };

  handleSelect = (event, nodeId) => {
    if(nodeId && typeof nodeId === 'string' &&  nodeId.includes('category')){
      let categoryArr = nodeId.split('-');
      let categoryID = categoryArr[1];
      let newExpandedCategory = [nodeId];
      if(this.state.expandedCategory[0] === nodeId){
        newExpandedCategory = [];
      }
      this.setState({selectedCategoryId: categoryID, expandedCategory: newExpandedCategory},()=> {
        this.props.fetchProductSubCategory(categoryID, 0, 100,'');
      })
    }else{
      this.setState({selectedSubcategoryId: nodeId},()=>{
        this.fetchData();
      })
    }
  };

  render() {
 
    const { classes, expentDirectoryProducts, fetchExpentDirectoryProductsProgress, deleteProductStateProg, addProductStateProg, isSeller } = this.props;
    const { searchObj, headerSectionWidth } = this.state;

    var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.map((p) => p.ID);
    let spliter = 5;
    if (headerSectionWidth < 768) spliter = 2;
    if (headerSectionWidth >= 768 && headerSectionWidth < 1280) spliter = 5;
    // if (headerSectionWidth >= 1280 && headerSectionWidth <= 1365) spliter = 4;

    const otherProductsCount = this.props.expentDirectoryProducts?.others?.total || 0;
    const existingProductsCount = this.props.expentDirectoryProducts?.existing?.total || 0;
    const totalCount = otherProductsCount + existingProductsCount;

    const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForUsersCheck");
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForAdminsCheck");
    let isShowCheckBox = true;
    if ((!EnableEvaluateForAdminsCheck && this.props.userRole !== "OrgUser") || (!EnableEvaluateForUsersCheck && this.props.userRole === "OrgUser")) {
      isShowCheckBox = false;
    }

    if(this.state.isForRequirementSelection){
      isShowCheckBox = true;
    }

    const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'DisableCatalogFeature')

    return (
      <>
        <AppBarV2 title={this.state.queryParams?.subcategory && this.props.recommendedSubcategory ? this.props.recommendedSubcategory : "Expent Product Directory"} withBack={true} dropdown={true} />
        <div style={{display:'flex'}}>
          <div className={classes.serviceSidebar}>
            <Typography variant="h6" style={{marginBottom: 15, fontSize: 18}}>Categories</Typography>
            <div className={classes.allService}>
                <p className={classnames(classes.innerText, this.state.expandedCategory?.length === 0 ? classes.activeService : '')} onClick={() => {
                  this.setState({ 
                    searchObj: { page: 0, page_size: 60, text: '', field_setname: "all", sort_fields: [] }, 
                    expandedCategory: [],
                    selectedSubcategoryId: null,
                    selectedCategoryId: null,
                  }, () => this.fetchData()) }}><b>All</b>
                </p>
              <TreeView
                className={classes.treeViewContainer}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={this.state.expandedCategory}
                selected={this.state.selectedSubcategoryId}
                onNodeSelect={this.handleSelect}
                multiSelect={false}
              >
               {this.props.categories?.map((category,index) => (
                <TreeItem nodeId={`category-${category.ID}`} label={category.Name} key={index}>
                  {!this.props.fetchSubCategoryProgress && this.props.subCategories?.length > 0 ? this.props.subCategories?.map((subCategory,index)=> (
                    <TreeItem nodeId={subCategory.ID} label={subCategory.Name} />
                  )):(  
                    <CircularProgress style={{width: 30, height: 30}}/>
                  )}
                </TreeItem>
               ))}
              </TreeView>
            </div>
          </div>
          <div style={{width: '100%'}}>
            <Breadcrumb routeList={[{ name: "Software products" }]} />
            <div className={classes.root}>
              <div className={classes.stickyBar}>
                <Grid
                  container
                  justifyContent="space-between"
                  ref={(divElement) => {
                    this.divElement = divElement;
                  }}
                  className={classes.productSectionNew}
                >
                  <Grid item>
                    <p className={classes.productCount}>
                      {totalCount.toLocaleString("en-US")}
                      {totalCount > 1 ? " Products" : " Product"} found
                    </p>
                  </Grid>
                  <Grid item>
                    { !DisableVendor && (
                      <ProductTypeFilter
                        type={this.state.productType}
                        hideHardware
                        onChange={(type) => {
                          this.handleRedirect(type);
                        }}
                      />
                    )}
                    <SearchInput
                      showTypes
                      isFilter
                      page="all_products"
                      filters={this.state.filters}
                      showState={!isSeller}
                      selectedState={this.state.selectedState}
                      onChangeState={(newState) => this.handleOnClickState(newState)}
                      onChangeFilter={(filters) => {
                        this.setState(
                          {
                            filters,
                            searchObj: { ...searchObj, text: "", page: 0 },
                          },
                          () => {
                            this.fetchData();
                          }
                        );
                      }}
                      searchObj={this.state.searchObj}
                      onChange={(searchObj) => {
                        delete searchObj.type;
                        this.setState(
                          {
                            searchObj: { ...searchObj,page: 0 },
                          },
                          () => {
                            this.props.storeSearchString(searchObj.text, searchObj.field_setname);
                            this.fetchData();
                          }
                        );
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <AddProductButton title={'Add Software'}/>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.productContainer} id="productContainer">
                {expentDirectoryProducts?.others?.items?.length === 0 && !fetchExpentDirectoryProductsProgress && (
                  <Typography align="center">
                    <span>Not finding what you are looking for? Try searching based on </span>
                    <span className={classes.addProductLink} onClick={() => this.setSearchType("features")}>
                      features{" "}
                    </span>
                    <span>or </span>
                    <span className={classes.addProductLink} onClick={() => this.setSearchType("subcategories")}>
                      sub-categories.
                    </span>
                  </Typography>
                )}
                <InfiniteScroll
                  scrollableTarget="productContainer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    marginRight: 20,
                  }}
                  dataLength={expentDirectoryProducts?.others?.items?.length || 0}
                  next={() => {
                    this.setState(
                      {
                        searchObj: { ...searchObj, page: searchObj.page + 1 },
                        isScroll: true,
                      },
                      () => {
                        this.fetchData();
                      }
                    );
                  }}
                  hasMore={fetchExpentDirectoryProductsProgress ? false : totalCount > expentDirectoryProducts?.others?.items?.length}
                  endMessage={""}
                >
                  {!isSeller && expentDirectoryProducts?.existing?.items && expentDirectoryProducts?.existing?.items.length > 0 && (
                    <>
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Typography className={classes.label}>Existing Products</Typography>
                      </Grid>
                      <Grid container spacing={3} style={{ marginBottom: 10 }}>
                        {expentDirectoryProducts?.existing?.items.slice(0, spliter).map((product, index) => (
                          <Grid item lg={2} md={3} sm={4} xs={6} key={"product_" + parseInt(product.id)}>
                            <LayeredCard
                              IsExisting
                              isActionButtons
                              title={product.name || product.company_name || ""}
                              subTitle={product.company_name || product.name || ""}
                              description={product.description || ""}
                              image={product.logo || ""}
                              toogleCheck={() => {
                                this.addToCart(product.id);
                              }}
                              onClick={() => {}}
                              numLayer={0}
                              checked={addedToCart.indexOf(parseInt(product.id)) !== -1}
                              showCheckbox={isShowCheckBox}
                              isForRequirementSelection={this.state.isForRequirementSelection}
                              id={parseInt(product.id)}
                              uniqueId={"_orgj_" + index}
                              type={"product"}
                              productStates={this.productStates(product.states)}
                              onClickState={(state) => this.handleOnClickState(state)}
                              source={"BROWSE"}
                              showReactionResponses
                              typeOfBlock="all_product_blocking"
                              typeOfFeatured="featured"
                              fireEvent={true}
                              eventPage={"productAllProducts"}
                              eventData={{
                                type: "event.click.product.on-all-products",
                                data: {
                                  url: this.props.location.pathname + this.props?.location?.search,
                                  product: {
                                    id: product.ID,
                                    name: product.Name,
                                    position: index + 1,
                                  },
                                  query: this.state.searchObj.text,
                                },
                              }}
                            />
                          </Grid>
                        ))}
                        {expentDirectoryProducts?.existing?.items && expentDirectoryProducts?.existing?.items.length > 5 && (
                          <Grid item lg={2} md={3} sm={4} xs={6} key={"org_product"}>
                            <LayeredCard
                              title={"View More"}
                              image={viewMoreImage}
                              subTitle=""
                              color="BLUE"
                              onClick={() => {
                                localStorage.setItem("activeTab",2);
                                this.props.history.push("/app/products/org-products");
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                  {!isSeller && expentDirectoryProducts?.others?.items && expentDirectoryProducts?.others?.items.length > 0 && (
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Typography className={classes.label}>Other Products </Typography>
                    </Grid>
                  )}
                  <Grid container spacing={3}>
                    {expentDirectoryProducts?.others?.items &&
                      expentDirectoryProducts?.others?.items.map((product, index) => (
                        <Grid item lg={2} md={3} sm={4} xs={6} key={"product_" + parseInt(product.id)}>
                          <LayeredCard
                            isActionButtons={!isSeller}
                            title={product.name || product.company_name || ""}
                            subTitle={product.company_name || product.name || ""}
                            description={product.description || ""}
                            image={product.logo || ""}
                            toogleCheck={() => {
                              this.addToCart(product.id);
                            }}
                            onClick={() => {}}
                            numLayer={0}
                            customRoute={`/${isSeller ? "sellerapp" : "app"}/products/product-details/`}
                            checked={addedToCart.indexOf(parseInt(product.id)) !== -1}
                            showCheckbox={isShowCheckBox}
                            isForRequirementSelection={this.state.isForRequirementSelection}
                            id={parseInt(product.id)}
                            uniqueId={"_j_" + index}
                            type={"product"}
                            productStates={this.productStates(product.states)}
                            onClickState={(state) => this.handleOnClickState(state)}
                            source={isSeller ? "SELLER" : ""}
                            showReactionResponses
                            typeOfBlock="all_product_blocking"
                            typeOfFeatured="featured"
                            fireEvent={true}
                            eventPage={"productAllProducts"}
                            eventData={{
                              type: "event.click.product.on-all-products",
                              data: {
                                url: this.props.location.pathname + this.props?.location?.search,
                                product: {
                                  id: product.id,
                                  name: product.name,
                                  position: index + 1,
                                },
                                query: this.state.searchObj.text,
                              },
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                  {fetchExpentDirectoryProductsProgress && (
                    <Grid container justify="center" style={{ marginTop: 30 }}>
                      <CircularProgress />
                    </Grid>
                  )}
                </InfiniteScroll>
              </div>
              {(deleteProductStateProg || addProductStateProg) && (
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

AllProducts.defaultProps = {
  isSeller: false,
};

export default connector(compose(withStyles(styles), withLastLocation)(AllProducts));
