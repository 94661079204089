import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import ToolTip from "Components/Common/ToolTip.jsx";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Typography, Button, FormControl, OutlinedInput, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, LinearProgress } from "@material-ui/core";
import copy from "copy-to-clipboard";
import { showSnackBar } from "redux/snackbar/action";
import { fetchOauthScopes } from "redux/oauth/action";

const connectedProps = (state) => ({
  oauth: state.oauth.oauthDetails,
  oauthScopes: state.oauth.oauthScopes,
  fetchOauthScopeError: state.oauth.fetchOauthScopeError,
  fetchOauthScopeProgress: state.oauth.fetchOauthScopeProgress,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchOauthScopes: fetchOauthScopes,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    container: {
      border: "1px solid #d8d8d8",
      padding: 30,
      borderRadius: 5,
      background: "#FFF",
      marginTop: 30,
    },
    flexContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "1.5rem",
    },
    label: {
      fontSize: "17px",
      fontWeight: "500",
    },
    outlinedInput: {
      fontSize: theme.spacing(2.2),
      color: "#5F5F5F",
      padding: theme.spacing(0.4),
      width: "500px",
      "& input": {
        padding: "10px 14px",
      },
    },
    flexIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginRight: "40px",
    },
  });

class General extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSecret: false,
    };
  }

  componentDidMount() {
    this.props.fetchOauthScopes(this.props.oauth?.ClientID);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.fetchOauthScopeProgress && prevProps.fetchOauthScopeProgress) {
      if (this.props.fetchOauthScopeError) {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }
  }

  copyText(text) {
    copy(text);
    this.props.showSnackBar("Copied !!", "success", 500);
  }

  render() {
    const { showSecret } = this.state;
    const { classes } = this.props;

    return (
      <>
        <div className={classes.container}>
          <Typography variant="h6">Client Credencial</Typography>
          <div className={classes.flexContent}>
            <div className={classes.label}>Client ID</div>
            <FormControl variant="outlined">
              <OutlinedInput
                type={"text"}
                value={this.props.oauth?.ClientID}
                className={classes.outlinedInput}
                endAdornment={
                  <MuiTooltip title="Copy" placement="top">
                    <Button onClick={() => this.copyText(this.props.oauth?.ClientID)}>
                      <AssignmentTurnedInIcon color="primary" />
                    </Button>
                  </MuiTooltip>
                }
                labelWidth={0}
                readOnly={true}
              />
            </FormControl>
          </div>
          <div className={classes.flexContent}>
            <div className={classes.label}>Client Secret</div>
            <FormControl variant="outlined">
              <OutlinedInput
                type={showSecret ? "text" : "password"}
                value={this.props.oauth?.Secret}
                className={classes.outlinedInput}
                endAdornment={
                  <div className={classes.flexIcon}>
                    <MuiTooltip title={showSecret ? "Hide" : "Show"} placement="top">
                      <Button onClick={() => this.setState((prevState) => ({ ...prevState, showSecret: !prevState.showSecret }))}>
                        {showSecret ? <VisibilityOffIcon color="primary" /> : <VisibilityIcon color="primary" />}
                      </Button>
                    </MuiTooltip>
                    <MuiTooltip title="Copy" placement="top">
                      <Button onClick={() => this.copyText(this.props.oauth?.Secret)}>
                        <AssignmentTurnedInIcon color="primary" />
                      </Button>
                    </MuiTooltip>
                  </div>
                }
                labelWidth={0}
                readOnly={true}
              />
            </FormControl>
          </div>
        </div>
        <div className={classes.container}>
          <Typography variant="h6">Granted Scopes</Typography>
          <TableContainer style={{ maxHeight: 650 }}>
            <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={"table_" + this.props.key}>
              <TableHead>
                <TableRow>
                  <TableCell sytle={{ width: "20%" }}>S.No.</TableCell>
                  <TableCell>Scope</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.fetchOauthScopeProgress ? (
                  <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: "center", padding: 0 }}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  this.props.oauthScopes.map((row, key) => (
                    <TableRow id={key}>
                      <TableCell>{key + 1}</TableCell>
                      <TableCell className={classes.nameCol}>
                        {row.Name} <ToolTip showCustom={true} toolTip={row.Description} />
                      </TableCell>
                    </TableRow>
                  ))
                )}

                {!this.props.fetchOauthScopeProgress && !this.props.oauthScopes.length && (
                  <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: "center" }}>
                      No Scopes
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(General));
