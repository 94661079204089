import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { updateDrawerStyle } from "redux/drawer/action";

import ReactFlow, { ReactFlowProvider, Background, Controls, Position } from 'react-flow-renderer';

import TriggerNode from "./NodeTypes/TriggerNode";
import AddNode from "./NodeTypes/AddNode";
import FinishNode from "./NodeTypes/FinishNode";
import ActionNode from "./NodeTypes/ActionNode";
import IfConditionNode from "./NodeTypes/IfConditionNode";
import LoopNode from "./NodeTypes/LoopNode";

import { getNewActionData } from "./Components/NewActionDataMap"

import { showBuilderConfigDrawer, hideBuilderConfigDrawer } from "redux/automations/builder/action"
import Configurations from "./Configurations";
import { showSnackBar } from "redux/snackbar/action";
import { getComponentType } from "./Components/FlowData"
import { getAutomationComponents } from "./Components/BuilderFunctions"

import {
  updateAutomation, addNewAutomationAction,
  deleteAutomationAction, fetchAutomationActions, fetchAutomationActionConnections,
  deleteAutomationActionConnection,
  addNewAutomationActionConnection, updateAutomationConnection, fetchAutomationComponents
} from 'redux/automations/action';


const nodeTypes = {
  triggerNode: TriggerNode,
  addNode: AddNode,
  actionNode: ActionNode,
  ifConditionNode: IfConditionNode,
  loopNode: LoopNode,
  finishNode: FinishNode
};

const connectedProps = (state) => ({
  isShowConfigDrawer: state.automationBuilder.isShowConfigDrawer,

  updateAutomationProgress: state.automation.updateAutomationProgress,
  updateAutomationError: state.automation.updateAutomationError,
  updatedAutomationData: state.automation.updatedAutomationData,

  fetchActionsProgress: state.automation.fetchActionsProgress,
  fetchActionsError: state.automation.fetchActionsError,
  automationActions: state.automation.automationActions,

  addActionProgress: state.automation.addActionProgress,
  addActionError: state.automation.addActionError,
  addActionSuccess: state.automation.addActionSuccess,

  updateActionProgress: state.automation.updateActionProgress,
  updateActionError: state.automation.updateActionError,
  updateActionSuccess: state.automation.updateActionSuccess,

  fetchComponentsProgress: state.automation.fetchComponentsProgress,
  fetchComponentsError: state.automation.fetchComponentsError,
  automationComponents: state.automation.automationComponents,

  fetchActionConnectionsProgress: state.automation.fetchActionConnectionsProgress,
  fetchActionConnectionsError: state.automation.fetchActionConnectionsError,
  automationConnections: state.automation.actionConnections,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  showBuilderConfigDrawer: showBuilderConfigDrawer,
  hideBuilderConfigDrawer: hideBuilderConfigDrawer,

  updateAutomation: updateAutomation,
  addNewAutomationAction: addNewAutomationAction,
  deleteAutomationAction: deleteAutomationAction,
  fetchAutomationActions: fetchAutomationActions,
  fetchAutomationActionConnections: fetchAutomationActionConnections,
  addNewAutomationActionConnection: addNewAutomationActionConnection,
  deleteAutomationActionConnection: deleteAutomationActionConnection,
  showSnackBar: showSnackBar,
  updateAutomationConnection: updateAutomationConnection,

  fetchAutomationComponents: fetchAutomationComponents

};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {

  },
  flowSection: {
    flex: 1,
    height: '94vh',
    // height: 'calc(94vh - 100px)',
    "& .react-flow": {
      background: '#fff !important'
    },
    "& .react-flow__node": {
      position: 'relative',
      opacity: 1
    },
    "& .draggable": {
      display: 'none',
    },
    '& .react-flow__controls': {
      top: 10,
      right: 10,
      left: 'auto',
      bottom: 'auto'
    }
  },
  "@global": {
    '.applicationContent': {
      background: '#fff !important',
      padding: "0 !important"
    },
    ".react-flow__node": {
      padding: '0px !important',
      boxShadow: 'none'
    },
    ".react-flow__node-default, .react-flow__node-input, .react-flow__node-output": {
      padding: '5px 10px',
      fontSize: '7.5px',
      border: 'none'
    },
    '.react-flow__edge-path': {
      strokeWidth: 1.5
    },
  },
});


class FlowBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.reactFlowWrapper = React.createRef(null);

    this.state = {
      elements: [],
      components: [],
      isRefresh: false,
      automationDetails: null,
      isActionFetched: false,
      isComponentFetched: false,
      isConnectionFetched: false,
      refreshToken: true,
      automation_Components: [],
      automation_Connections: []
    }
  }

  componentDidMount() {
    // console.log(this.props, 'AllPropssssssssssssssssss')
    // this.initializeBuilder()
    this.props.hideBuilderConfigDrawer()
    this.initData();

  }

  componentDidUpdate(prevProps) {
    const { fetchActionsProgress, fetchActionsError, addActionProgress, addActionError,
      addActionSuccess, updateActionProgress, updateActionError,
      fetchActionConnectionsProgress, fetchActionConnectionsError,
      fetchComponentsProgress, fetchComponentsError } = this.props;



    if (!fetchActionsProgress && prevProps.fetchActionsProgress) {
      if (fetchActionsError === null) {
        this.setState({
          isActionFetched: true,
          isConnectionFetched: false,
        }, () => {
          this.checkFetchResponse()
        })
      }
    }

    if (!addActionProgress && prevProps.addActionProgress) {
      if (addActionError === null) {
        // console.log(addActionSuccess, 'addActionSuccess')
        this.props.showSnackBar('Component added successfully', 'success', 3000)
        this.setState({
          isActionFetched: false,
          isConnectionFetched: false,
          isComponentFetched: false
        }, () => {
          this.setConfigChanges(addActionSuccess);
          this.props.hideBuilderConfigDrawer()
          this.getActionsAndConnections()
        })
      } else {
        this.props.showSnackBar('Something went wrong', 'error', 3000)
      }
    }

    if (!updateActionProgress && prevProps.updateActionProgress) {
      if (updateActionError === null) {
        // console.log(updateActionSuccess, 'updateActionSuccess')
        this.setState({
          isActionFetched: false,
          isComponentFetched: false,
          isConnectionFetched: false
        }, () => {
          this.getActionsAndConnections()
          // this.props.hideBuilderConfigDrawer()
          this.props.showSnackBar('Component updated successfully', 'success', 3000)
        })
      } else {
        this.props.showSnackBar('Something went wrong', 'error', 3000)
      }
    }

    if (!fetchComponentsProgress && prevProps.fetchComponentsProgress) {
      if (fetchComponentsError === null) {
        this.setState({
          isComponentFetched: true
        }, () => {
          this.checkFetchResponse()
        })
      }
    }

    if (!fetchActionConnectionsProgress && prevProps.fetchActionConnectionsProgress) {
      if (fetchActionConnectionsError === null) {
        this.setState({
          isConnectionFetched: true
        }, () => {
          this.checkFetchResponse()
        })
      }
    }
  }

  setConfigChanges(actionData) {

  }

  updateRefreshToken(type) {
    this.setState({
      refreshToken: type
    })
  }

  initData() {
    this.setState({
      automationDetails: this.props.automationDetails
    }, () => {
      if (this.props.isForTestJobs) {
        this.updateRefreshToken(true)
        this.initializeDatasForJob()
      } else {
        this.getActionsAndConnections();
      }
    })
  }

  getActionsAndConnections() {
    this.props.fetchAutomationActionConnections(this.props.id)
    // this.props.fetchAutomationActions(this.props.id)
    this.props.fetchAutomationComponents(this.props.id)
  }

  checkFetchResponse() {
    const { isComponentFetched, isConnectionFetched } = this.state;

    if (isComponentFetched && isConnectionFetched) {
      this.updateRefreshToken(true)
      this.initializeDatas()
    }
  }

  getMasterTrigger(id) {
    const { masterTriggers } = this.props;
    let trigger = masterTriggers.filter(o => o.id === id)[0];
    return trigger;
  }

  getMasterAction(id) {
    let action = '';
    const { masterActions } = this.props;
    if (masterActions) {
      action = masterActions.filter(o => o.id === id)[0];
    }
    return action;
  }

  getComponentData(id) {
    let action = {}
    const { automationComponents } = this.props;
    if (automationComponents) {
      action = automationComponents.filter(o => o.ID === id)[0];
    }
    return action;
  }

  getTriggerLabel(action) {
    let label = '';
    console.log('-------------------------action')
    console.log(action, 'action')
    const { masterTriggers } = this.props;
    console.log(masterTriggers, 'masterTriggers')
    if (action.InputData && action.InputData !== null) {
      let response = JSON.parse(action.InputData);
      if (response.schedule && response.schedule !== '') {
        const RefID = action.RefID;
        let triggerData = masterTriggers.filter(o => o.id === RefID)[0]
        console.log(triggerData, 'triggerData')
        let inputJson = triggerData.input_json ? triggerData.input_json.filter(o => o.name === 'schedule')[0] : null
        let dataMap = {}
        if (inputJson) {
          inputJson.options.forEach(function (itm) {
            dataMap[itm.value] = 'Trigger Every ' + itm.label;
          })
          if (dataMap && dataMap[response.schedule]) {
            label = dataMap[response.schedule]
          }
        }
      }
    }
    return label
  }

  getComponentTargets() {

  }

  initializeDatas() {
    let { automationComponents, automationConnections } = this.props;
    this.setState({
      automation_Components: automationComponents,
      automation_Connections: automationConnections,
    })

    let datas = getAutomationComponents(automationComponents[0].ID, automationConnections, automationComponents)
    // console.log(datas, 'datas5555555555555555555555555555555555')
    this.setState({
      components: datas
    }, () => {
      this.initializeBuilderCom(datas)
    })
  }

  initializeDatasForJob() {
    let { jobComponents, jobConnections } = this.props;
    this.setState({
      automation_Components: jobComponents,
      automation_Connections: jobConnections,
    })
    let datas = getAutomationComponents(jobComponents[0].ID, jobConnections, jobComponents)
    // console.log(datas, 'datas5555555555555555555555555555555555')
    this.setState({
      components: datas
    }, () => {
      this.initializeBuilderCom(datas)
    })
  }

  initializeBuilderCom(automationComponents) {
    let elementList = []
    const { automation_Components, automation_Connections } = this.state;
    if (automationComponents) {
      let me = this;
      automationComponents.forEach(function (action, index) {
        // console.log(action, 'Actionnnnnnnnnnnnnnnnnnnnnn')
        if (action && (action.Type === "Trigger" || action.Type === "Action") && action.RefType === 'Master') {
          // console.log(action, 'Actionnnnnnnnnnnnnn')
          let masterData = '';
          let label = '';
          if (action.Type === "Trigger") {
            masterData = me.getMasterTrigger(action.RefID)
            label = me.getTriggerLabel(action);
          } else if (action.Type === "Action") {
            masterData = me.getMasterAction(action.RefID)
          }
          if (label === '') {
            label = masterData?.name;
          }
          let dataObjects = {
            id: action.ID,
            type: 'actionNode',
            componentType: action.Type,
            slug: 'ACTION',
            dragHandle: '.custom-drag-handle',
            style: { opacity: 1 },
            position: { x: 100, y: 50 },
            data: {
              id: action?.ID,
              isNew: false,
              label: label,
              type: 'actionNode',
              componentType: action.Type,
              slug: 'ACTION',
              inputJson: masterData?.input_json,
              responseJson: action.InputData,
              object: action,
              masterData: masterData,
              isShowAddOption: !me.props.isForSummary && !me.props.isForTestJobs ? true : false,
              automation_Components: automation_Components,
              automation_Connections: automation_Connections,
            }
          }
          elementList.push(dataObjects)
        } else if (action && action.Type === "Trigger") {
          let dataObjects = {
            id: action.ID,
            type: 'actionNode',
            componentType: action.Type,
            slug: 'ACTION',
            dragHandle: '.custom-drag-handle',
            style: { opacity: 1 },
            position: { x: 100, y: 50 },
            data: {
              id: action?.ID,
              isNew: false,
              label: 'Select Trigger Type',
              type: 'actionNode',
              componentType: action?.Type,
              slug: 'ACTION',
              inputJson: '',
              responseJson: action?.InputData,
              object: action,
              masterData: '',
              isShowAddOption: !me.props.isForSummary && !me.props.isForTestJobs ? true : false,
              automation_Components: automation_Components,
              automation_Connections: automation_Connections,
            }
          }
          elementList.push(dataObjects)
        } else if (action && action.Type === 'Action') {
          let dataObjects = {
            id: action.ID,
            type: 'actionNode',
            componentType: action.Type,
            slug: 'ACTION',
            dragHandle: '.custom-drag-handle',
            style: { opacity: 1 },
            position: { x: 100, y: 50 },
            data: {
              id: action?.ID,
              isNew: false,
              label: 'Select Action Type',
              type: 'actionNode',
              componentType: action.Type,
              slug: 'ACTION',
              inputJson: '',
              responseJson: action.InputData,
              object: action,
              masterData: '',
              isShowAddOption: !me.props.isForSummary && !me.props.isForTestJobs ? true : false,
              automation_Components: automation_Components,
              automation_Connections: automation_Connections,
            }
          }
          elementList.push(dataObjects)
        } else if (action && action.Type === 'Control' && (action.RefID === 'if' || action.RefID === 'ifelse' || action.RefID === 'else')) {
          let dataObjects = {
            id: action.ID,
            type: 'ifConditionNode',
            componentType: action.Type,
            slug: 'ACTION',
            dragHandle: '.custom-drag-handle',
            style: { opacity: 1 },
            position: { x: 100, y: 50 },
            data: {
              id: action?.ID,
              isNew: false,
              label: 'Select Action Type',
              type: 'ifConditionNode',
              componentType: action.Type,
              slug: 'ACTION',
              inputJson: '',
              responseJson: action.InputData,
              object: action,
              masterData: '',
              isShowAddOption: !me.props.isForSummary && !me.props.isForTestJobs ? true : false,
              automation_Components: automation_Components,
              automation_Connections: automation_Connections,
            }
          }
          elementList.push(dataObjects)

        } else if (action && action.Type === 'Control' && action.RefID === 'for') {
          let dataObjects = {
            id: action.ID,
            type: 'loopNode',
            componentType: action.Type,
            slug: 'ACTION',
            dragHandle: '.custom-drag-handle',
            style: { opacity: 1 },
            position: { x: 100, y: 50 },
            data: {
              id: action?.ID,
              isNew: false,
              label: 'Select Action Type',
              type: 'loopNode',
              componentType: action.Type,
              slug: 'ACTION',
              inputJson: '',
              responseJson: action.InputData,
              object: action,
              masterData: '',
              isShowAddOption: !me.props.isForSummary && !me.props.isForTestJobs ? true : false,
              automation_Components: automation_Components,
              automation_Connections: automation_Connections,
            }
          }
          elementList.push(dataObjects)
        }
        // let masterAction = me.getMasterAction(action?.ActionID)
        // let dataObjects = {
        //   id: action.ID,
        //   type: 'actionNode',
        //   slug: 'ACTION',
        //   dragHandle: '.custom-drag-handle',
        //   style: { opacity: 1 },
        //   position: { x: 100, y: 50 },
        //   data: {
        //     id: action?.ID,
        //     isNew: false,
        //     label: action?.Name,
        //     type: 'actionNode',
        //     slug: 'ACTION',
        //     inputJson: masterAction.inputJson,
        //     responseJson: action.InputData,
        //     object: action,
        //     masterData: masterAction
        //   }
        // }
        // elementList.push(dataObjects)
      })
    }

    if (!this.props.isForSummary && !this.props.isForTestJobs) {
      elementList.push({
        id: 99999999,
        type: 'addNode',
        slug: 'ADD',
        dragHandle: '.addNode',
        style: { opacity: 1 },
        position: { x: 100, y: 50 },
        data: {
          addNewAction: this.addNewComponent
        }
      })
    }

    if (this.props.isForSummary || this.props.isForTestJobs) {
      elementList.push({
        id: 99999999,
        type: 'finishNode',
        slug: 'FINISH',
        dragHandle: '.finishNode',
        style: { opacity: 1 },
        position: { x: 100, y: 50 },
        data: {
          addNewAction: this.addNewComponent
        }
      })
    }

    this.setState({
      elements: [...elementList],
    }, () => {
      this.updateRefreshToken(false)
    })
  }


  onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView()
    this.setState({
      reactFlowInstance: reactFlowInstance
    }, () => {
      // const element = document.getElementsByClassName("react-flow__controls-fitview")
      // console.log(element, 'element5656565')
      // if (element && element.length > 0) {
      //   setTimeout(() => {
      //     element[element.length - 1].click()
      //   }, 1000)
      // }
    })
  }

  onElementClick = (e) => {
    console.log(e)
    // if (e.type !== 'addNode') {
    //   this.props.showBuilderConfigDrawer(e)
    // }
  }

  addNewAction_bkp = (type) => {
    let data = getNewActionData(type)
    let flowDatas = [...this.state.elements];
    let totalLen = flowDatas.length;
    flowDatas.splice(totalLen - 1, 0, data)
    // const { nodes: layoutedNodes } = getLayoutedElements(flowDatas, 'TB');
    this.setState({
      elements: flowDatas,
    }, () => {
      this.props.showBuilderConfigDrawer(data.data)
    })
    setTimeout(() => {
      this.setState({
        elements: [...this.state.elements],
      })
    }, 500);
  }

  addNewComponent = (component_type) => {
    // console.log(component_type, 'Type')
    let componentData = getComponentType(component_type)
    const { components } = this.state;
    const { automationDetails } = this.state;
    console.log(components, 'components-automationComponents')
    console.log(automationDetails, 'automationDetails')

    const params = {
      ...componentData,
      sourceComponentID: components[components.length - 1].ID
    }
    console.log(params, 'params')

    this.props.addNewAutomationAction(automationDetails?.ID, params)
  }


  // addNewAction = (type) => {
  //   let data = getNewActionData(type)
  //   let flowDatas = [...this.state.elements];
  //   let totalLen = flowDatas.length;
  //   flowDatas.splice(totalLen - 1, 0, data)
  //   // const { nodes: layoutedNodes } = getLayoutedElements(flowDatas, 'TB');
  //   this.setState({
  //     elements: flowDatas,
  //   }, () => {
  //     this.props.showBuilderConfigDrawer(data.data)
  //   })
  //   setTimeout(() => {
  //     this.setState({
  //       elements: [...this.state.elements],
  //     })
  //   }, 500);
  // }

  onConnect = () => {

  }

  onElementsRemove = () => {

  }

  onNodeDragStop = (event, node) => {
    event.stopPropagation()
    return;
  }

  render() {
    const { classes } = this.props;
    const { elements } = this.state;
    const { isShowConfigDrawer } = this.props;
    // console.log(elements, 'elements')
    // console.log(isShowConfigDrawer, 'isShowConfigDrawer')


    return (
      <div className={classes.root}>
        <div className={classes.flowSection}>
          <ReactFlowProvider>
            <div style={{ width: '100%', height: '100%' }} ref={this.reactFlowWrapper}>
              <ReactFlow
                elements={this.state.refreshToken ? [] : elements}
                onConnect={this.onConnect}
                onElementsRemove={this.onElementsRemove}
                onElementClick={(event, element) => this.onElementClick(element, event)}
                nodesDraggable={false}
                onLoad={this.onLoad}
                snapToGrid={true}
                snapGrid={[15, 15]}
                // style={{ background: '#F8F7F7' }}
                onNodeDragStop={this.onNodeDragStop}
                onEdgeMouseEnter={(event, edge) => {

                }}
                onEdgeMouseLeave={(event, edge) => {

                }}
                nodeTypes={nodeTypes}
                // style={{ maxHeight: "94vh",width:'100%', overflow: "scroll" }}
                // nodesConnectable={false}
                // onlyRenderVisibleNodes={false}
                zoomOnScroll={false}
                panOnScroll={true}
                zoomOnDoubleClick={false}
              // selectNodesOnDrag={false}
              // onPaneScroll={(wheelEvent) => {
              //   useCanvasScrollEvent(wheelEvent);
              //   console.log('111')
              // }}
              >
                <Controls
                  showInteractive={false}
                  onInteractiveChange={e => { }}
                  position={Position.Right}
                />
                <Background color="#000" gap={30} />
              </ReactFlow>
            </div>
          </ReactFlowProvider>
        </div>
        {isShowConfigDrawer && <Configurations
          automationDetails={this.state.automationDetails}
          isForTestJobs={this.props.isForTestJobs}
          isForSummary={this.props.isForSummary}
          jobComponentConfigs={this.props.jobComponentConfigs}
          jobComponentConfigMap={this.props.jobComponentConfigMap}
        />}
      </div>
    );
  }
}

FlowBuilder.defaultProps = {
  isForTestJobs: false,
  isForSummary: false,
  jobComponentConfigs: [],
  jobComponentConfigMap: {}
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(FlowBuilder));