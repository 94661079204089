/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React from "react";
import { Avatar } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import Checkbox from "@material-ui/core/Checkbox";
import TooltipShow from "@material-ui/core/Tooltip";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { createStyles, withStyles } from "@material-ui/core/styles";
import AdjustRoundedIcon from "@material-ui/icons/AdjustRounded";
import SecurityIcon from "@material-ui/icons/Security";
import { CommonFn } from "services/commonFn";

// Icons
import Brightness1RoundedIcon from "@material-ui/icons/Brightness1Rounded";
import classnames from "classnames";
import SqureButton from "Components/Common/SqureButton";
import { connect } from "react-redux";
import { compose } from "recompose";
import { fetchAddApprovalForCriteria } from "redux/evaluation/approval/action";
import { showAddUpdateTaskDialog } from "redux/evaluation/productTask/action";
import { getNextComponent } from "util/Common";

const connectedProps = (state) => ({
  orgUser: state.orgUser,
  user: state.authUser.user,
  productApprovalDetails: state.evaluationApproval.productApprovalDetails,
  addApprovalForCriteriaInProgress: state.evaluationApproval.addApprovalForCriteriaInProgress,
  approvalCriterias: state.evaluationApproval.approvalCriterias,
  userRole: state.authUser.user?.Role,
});

const connectionActions = {
  fetchAddApprovalForCriteria: fetchAddApprovalForCriteria,
  showAddUpdateTaskDialog: showAddUpdateTaskDialog,
};
var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      color: "#FFFFFF",
      "&$checked": {
        color: "#007BFF",
        "& svg": {
          stroke: "unset",
        },
      },
      "& svg": {
        stroke: "#999",
      },
    },
    checked: {},
    approvalActionContainer: {
      paddingLeft: "15px",
      paddingTop: 0,
      // paddingBottom: 50,
      // overflowY: "auto",
      // height: '75vh',
      // maxHeight: '75vh',
    },
    disabledApprovalActionContainer: {
      paddingLeft: "15px",
      paddingTop: 0,
      opacity: 0.6,
      pointerEvents: "none",
    },
    approvalAction: {
      // border: '1px solid #007BF7',
      marginBottom: 30,
    },
    approvalBody: {
      background: "#FFFFFF",
      borderRadius: "5px",
      padding: "20px",
    },
    header: {
      marginBottom: 25,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    title: {
      textAlign: "left",
      fontWeight: 600,
      fontSize: "17px",
      letterSpacing: "0px",
      color: "#282D30",
    },
    approver: {
      fontSize: "13px",
      fontWeight: 400,
      marginRight: 13,
      lineHeight: "26px",
    },
    approverAvatar: {
      width: "25px",
      height: "25px",
      fontSize: "14px",
      marginRight: 13,
    },
    approverName: {
      color: "#000000",
      fontWeight: 600,
      lineHeight: "26px",
      position: "relative",
    },
    actions: {},
    actionItem: {
      background: "#FAFAFA",
      borderRadius: "6px",
      marginBottom: "15px",
      padding: "15px",
      paddingLeft: "45px",
      position: "relative",
      "& span": {
        position: "absolute",
        left: "5px",
      },
      "& h4": {
        margin: 0,
        marginBottom: 5,
        textAlign: "left",
        fontWeight: 500,
        fontSize: 16,
        color: "#282D30",
      },
      "& h6": {
        margin: 0,
        marginBottom: 15,
        textAlign: "left",
        fontSize: "12px",
        fontWeight: 300,
        color: "#282D30",
      },
    },
    activeGrid: {
      border: "1px solid #007BF7",
    },
    common: {
      width: "100%",
      border: "0px",
      padding: "10px",
      borderRadius: "6px",
      fontFamily: "POPPINS",
    },
    footer: {
      background: " #FAFAFA",
      borderRadius: "6px",
      padding: "15px",
      textAlign: "right",
      "& button": {
        marginRight: "15px",
      },
    },
    divProductTask: {
      width: 80,
      position: "absolute",
      right: "0",
      top: "30px",
      cursor: "pointer",
      textAlign: "right",
    },
    addRiskIcon: {
      cursor: "pointer",
      marginLeft: 10,
      transitionDuration: "5s",
      transitionProperty: "transform",
    },
  });

class ApprovalAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      criteriaNote: "",
    };

    this.addNewRisk = this.addNewRisk.bind(this);
  }

  handleSubmit = () => {
    let data = {};
    data.evaluation_id = this.props.evaluation_id;
    data.product_id = this.props.product_id;
    data.criteria_id = this.props.activeCriteria?.ID || this.props.approvalCriterias?.Response[0]?.ID;
    data.data = {
      status: this.props.activeResponse,
      note: this.state.criteriaNote,
      component_id: Number(this.props.component_id),
    };
    this.props.fetchAddApprovalForCriteria(data);
    this.setState({ criteriaNote: "" });
  };

  avatarName = (name) => {
    if (name) {
      return name.substring(0, 1);
    }
    return "";
  };

  getApprovalDetails = () => {
    if (!this.props.activeCriteria) return null;
    const findCriteria =
      this.props.productApprovalDetails && this.props.productApprovalDetails.length ? this.props.productApprovalDetails.find((item) => item.CriteriaID === parseInt(this.props.activeCriteria.ID)) : {};
    return findCriteria;
  };

  checkApprovedState = () => {
    const { user } = this.props;
    let userCanUpdate = true;
    if (!this.props.activeCriteria) return userCanUpdate;
    const findCriteria =
      this.props.productApprovalDetails && this.props.productApprovalDetails.length
        ? this.props.productApprovalDetails.find((item) => item.CriteriaID === parseInt(this.props.activeCriteria.ID))
        : null;
    if (findCriteria !== undefined && findCriteria !== null && findCriteria !== "") {
      if (findCriteria.UserID !== user.ID || findCriteria.UserID === user.ID) {
        userCanUpdate = false;
      }
    }
    return userCanUpdate;
  };

  getConfigValue = (array, key, value) => {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) {}
      return result.trim() || "";
    }
  };

  addNewRisk() {
    let riskRegisterFormID = this.getConfigValue(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "SettingName", "RISK_REGISTER_FORM");
    window.sessionStorage.setItem("riskForProject", JSON.stringify(this.props.product));
    let url;
    if (riskRegisterFormID && riskRegisterFormID.length > 0) {
      url = `/app/products/risks/risk/0/${riskRegisterFormID}?isNewRisk=true&fE=true`;
    } else {
      url = `/app/products/risks/risk/0/0?isNewRisk=true&fE=true`;
    }
    this.props.addRisk(url);
  }

  render() {
    const classes = this.props.classes;
    const { activeResponse, activeCriteria, approvalCriterias, approvalStages } = this.props;
    const { criteriaNote } = this.state;
    if (!activeCriteria?.Status) {
      return null;
    }
    const username = this.props.user?.Name || "Loading...";
    let canUserUpdate = this.checkApprovedState();
    // console.log('5555555555555555555555555555555555')
    // console.log(canUserUpdate, 'canUserUpdate')
    // console.log(this.props.productApprovalDetails, 'productApprovalDetails')
    // console.log(activeResponse, 'activeResponse')
    // console.log(approvalCriterias, 'approvalCriterias')
    // console.log(approvalStages, 'approvalStages')
    const approvedDetails = this.getApprovalDetails();
    console.log(approvedDetails, "approvedDetails");

    return (
      <div className={classes.disabledApprovalActionContainer}>
        <div className={classes.approvalAction}>
          <div className={classnames(classes.approvalBody, "evaluate-approval-body")}>
            <div className={classes.header} style={{ display: "flex" }}>
              <div>
                <h4 className={classes.title}>{activeCriteria?.CriteriaName || (approvalCriterias?.Response && approvalCriterias?.Response[0]?.CriteriaName)}&nbsp;</h4>
              </div>
              <div style={{ display: "flex" }}>
                {approvedDetails && approvedDetails.User !== undefined && <span className={classes.approver}>Approved By {approvedDetails?.User?.Name}</span>}
                {false && approvedDetails && approvedDetails.User !== undefined && (
                  <Avatar className={classes.approverAvatar} alt={"Nicky Fury"}>
                    {this.avatarName(approvedDetails?.User?.Name)}
                  </Avatar>
                )}
              </div>
            </div>

            {
              <div className={classes.actions}>
                {approvalStages.map((stage) => {
                  if (!stage.isChecked) {
                    return null;
                  }
                  return (
                    <div
                      className={classnames(
                        classes.actionItem,
                        (activeResponse && activeResponse == stage.id) || (!activeResponse && this.getApprovalDetails()?.Status == stage.id) ? classes.activeGrid : ""
                      )}
                      onClick={() => {
                        if (canUserUpdate) {
                          this.props.setActiveResponse(stage.id);
                        }
                      }}
                    >
                      <span>
                        <Checkbox
                          classes={{ root: classes.root, checked: classes.checked }}
                          checked={activeResponse ? activeResponse == stage.id : this.getApprovalDetails()?.Status == stage.id}
                          icon={<Brightness1RoundedIcon />}
                          checkedIcon={<AdjustRoundedIcon />}
                          name="Preliminary"
                        />
                      </span>
                      <h4>{stage.name}</h4>
                      {((activeResponse && activeResponse == stage.id) || (!activeResponse && this.getApprovalDetails()?.Status == stage.id)) && (
                        <>
                          {activeCriteria?.YourResponse ? (
                            <textarea
                              disabled={!canUserUpdate}
                              onChange={(e) => {
                                this.setState({ criteriaNote: e.target.value });
                              }}
                              value={(this.getApprovalDetails()?.Status == stage.id && this.getApprovalDetails()?.Note) || criteriaNote}
                              className={classes.common}
                              placeholder="Add your comments here"
                            ></textarea>
                          ) : (
                            <p className={classes.common}> {(this.getApprovalDetails()?.Status == stage.id && this.getApprovalDetails()?.Note) || criteriaNote}</p>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            }
            {approvedDetails && approvedDetails.User !== undefined && (
              <p style={{ textAlign: "right" }}>
                {approvedDetails?.StatusText} on {CommonFn.formateDate(approvedDetails.UpdatedAt, true)} at {CommonFn.formatTime(approvedDetails.UpdatedAt, true)}
              </p>
            )}
          </div>
          {canUserUpdate && (
            <div className={classnames(classes.footer, "evaluate-approval-action")}>
              <SqureButton
                variant={"outlined"}
                onClick={() => {
                  this.props.history.push(`/app/evaluate/${this.props.evaluation_id}`);
                }}
              >
                Cancel
              </SqureButton>
              <SqureButton
                variant={"contained"}
                disabled={this.props.addApprovalForCriteriaInProgress || !activeResponse}
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Submit response
              </SqureButton>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(ApprovalAction));
