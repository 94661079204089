import moment from "moment";

export const isEnabledReview = (array = [], key, value) => {
  let result = true;
  try {
    result = array.find((element) => element["SettingName"] === key)["Value"];
    if (result) {
      result = result === "false" ? false : true;
    }
  } catch (e) {}
  return result;
};

export const isEnabledProjectTracking = (array = [], key, value) => {
  let result = false;
  if (key == "EnableVendorCatalogV2") {
    return true;
  }
  try {
    result = array.find((element) => element["SettingName"] === key)["Value"];
    if (result) {
      result = result === "true" ? true : false;
    }
  } catch (e) {}
  return result;
};

export const isEnabledEvaluate = (array = [], key, value) => {
  let result = true;
  try {
    result = array.find((element) => element["SettingName"] === key)["Value"];
    if (result) {
      result = result === "false" ? false : true;
    }
  } catch (e) {}
  return result;
};

export const isSyncActivityLog = (array = [], key, value) => {
  let result = false;
  try {
    result = array.find((element) => element["SettingName"] === key)["Value"];
    if (result) {
      result = result === "YES" ? true : false;
    }
  } catch (e) {}
  return result;
};

export const getConfigValue = (array, key, value) => {
  if (value) {
    let result = "";
    try {
      result = array.find((element) => element[key] === value)["Value"];
    } catch (e) {}
    return result.trim() || "";
  }
};

export const getUserConfigValue = (array, key) => {
  if (key && array && array.length) {
    let data = array.find((item) => item.Key === key);
    if (data) {
      return data;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

function getComponentStatus(userTimeline, workflowComId) {
  var component = null;
  userTimeline.forEach(function (itm) {
    if (itm.Children !== null) {
      itm.Children.forEach(function (childItm) {
        if (childItm.ComponentId === workflowComId) {
          component = childItm;
        }
      });
    } else {
      if (itm.ComponentId === workflowComId) {
        component = itm;
      }
    }
  });
  return component;
}

function getComponentIndex(userTimeline, workflowComId) {
  var nextComponent = null;
  var comIndex = null;
  var childComIndex = null;
  userTimeline.forEach(function (itm, index) {
    if (itm.Children !== null) {
      itm.Children.forEach(function (childItm, cIndex) {
        if (childItm.ComponentId === workflowComId) {
          comIndex = index;
          childComIndex = cIndex;
        }
      });
    } else {
      if (itm.ComponentId === workflowComId) {
        comIndex = index;
      }
    }
  });

  if (comIndex !== null && childComIndex !== null) {
    var nextChildIndex = childComIndex + 1;
    if (userTimeline !== undefined && userTimeline[comIndex] !== undefined && userTimeline[comIndex].Children !== null && userTimeline[comIndex].Children[nextChildIndex] !== undefined) {
      nextComponent = userTimeline[comIndex].Children[nextChildIndex];
    } else {
      var nextComIndex = comIndex + 1;
      if (userTimeline[nextComIndex] !== undefined) {
        nextComponent = userTimeline[nextComIndex];
      }
    }
  } else if (comIndex !== null) {
    var nextComIndex1 = comIndex + 1;
    if (userTimeline[nextComIndex1] !== undefined) {
      nextComponent = userTimeline[nextComIndex1];
    }
  }
  return nextComponent;
}

export const getNextComponent = (userTimeline, workflowComponentId) => {
  // console.log(userTimeline, 'userTimeline');
  if (userTimeline !== null && userTimeline !== undefined) {
    let nextComponent = null;
    const workflowComId = parseInt(workflowComponentId);
    var componentStatus = getComponentStatus(userTimeline, workflowComId);
    // console.log(componentStatus,'CurrentComponentStatus')
    // var componentStatus = userTimeline.filter(o => o.ComponentId === workflowComId)[0];
    if (componentStatus !== undefined && componentStatus !== null && componentStatus.Status === 2) {
      let nextItem = getComponentIndex(userTimeline, workflowComId);
      if (nextItem !== undefined && nextItem !== null && nextItem.Children !== null && nextItem.Children.length > 0) {
        nextComponent = nextItem?.Children[0];
      } else {
        nextComponent = nextItem;
      }
      // if (nextItem !== undefined && nextItem !== null && nextItem?.WorkflowId !== 0) {
      //   nextComponent = nextItem
      // }
      // if (nextItem !== undefined && nextItem !== null && nextItem?.WorkflowId === 0) {
      //   nextComponent = nextItem?.Children[0]
      // }
    }
    return nextComponent;
  } else {
    return null;
  }
};

export const getPreviousComponent = (userTimeline, workflowComponentId) => {
  // console.log(userTimeline, 'userTimeline');
  if (userTimeline !== null && userTimeline !== undefined) {
    let proeviousComponent = null;
    const workflowComId = parseInt(workflowComponentId);
    let completeTimeline = getAllComponents(userTimeline, []);
    var indexOfCurrent = completeTimeline.findIndex((o) => o.ComponentId === workflowComId);
    if (indexOfCurrent !== undefined && indexOfCurrent !== -1) {
      var indexOfPrevious = null;
      if (indexOfCurrent !== 0) {
        indexOfPrevious = indexOfCurrent - 1;
        if (completeTimeline[indexOfPrevious] !== undefined && completeTimeline[indexOfPrevious] !== null) {
          proeviousComponent = completeTimeline[indexOfPrevious];
        }
      }
    }
    return proeviousComponent;
  } else {
    return null;
  }
};

export const getAllRequirements = (datas, allItems) => {
  if (datas !== undefined && datas !== null && datas !== "") {
    datas.forEach(function (item) {
      if (item.Type !== 1) {
        allItems.push(item.ID);
      }
      if (item.Childrens != null) {
        getAllRequirements(item.Childrens, allItems);
      }
    });
    return allItems;
  }
  return [];
};

export const getFinishComponent = (userTimeline) => {
  // console.log(userTimeline, 'userTimeline');
  if (userTimeline !== null && userTimeline !== undefined) {
    let completeTimeline = getAllComponents(userTimeline, []);
    let finishComponent = completeTimeline.filter((o) => o.ComponentType === "FINISH")[0];
    if (finishComponent) {
      return finishComponent;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getNextAndPreviousIds = (requirementTree, currentReq) => {
  var nextReqId = null;
  var previousReqId = null;

  var allRequirements = requirementTree !== undefined && requirementTree !== null ? [...requirementTree] : [];
  var requirementIds = [];
  var allItems = [];
  if (allRequirements !== null && allRequirements.length > 0) {
    requirementIds = getAllRequirements(requirementTree, allItems);
  }

  var indexOfCurrent = requirementIds.indexOf(currentReq);
  var indexOfNext = null;
  var indexOfPrevious = null;

  if (indexOfCurrent !== requirementIds.length - 1) {
    indexOfNext = indexOfCurrent + 1;
    nextReqId = requirementIds[indexOfNext];
  }

  if (indexOfCurrent !== 0) {
    indexOfPrevious = indexOfCurrent - 1;
    previousReqId = requirementIds[indexOfPrevious];
  }

  return {
    nextReqId: nextReqId,
    previousReqId: previousReqId,
  };
};

export const getAllComponents = (datas, allItems) => {
  datas.forEach(function (item) {
    if (item.Type === 1) {
      // console.log(item,'55555555555555555555555555555555555555555')
      allItems.push(item);
    } else if (item.Type === 2 && item.Children != null) {
      // console.log(item)
      // console.log(item.Children,'Childrennnnnnnnnnnnnnnnnn')
      getAllComponents(item.Children, allItems);
    }
  });
  return allItems;
};

export const getNewStepper = (userTimeline, activeComponentId) => {
  let newTimeline = [];
  if (userTimeline !== undefined && userTimeline !== null && userTimeline !== "") {
    // console.log(userTimeline, 'UserrrTimeline')
    let userTimelineItems = getAllComponents(userTimeline, []);
    // console.log(userTimelineItems, 'userTimelineItems')
    let isFinishedAvailable = false;
    let finishComponent = userTimelineItems.filter((o) => o.ComponentType === "FINISH")[0];
    if (finishComponent !== undefined && finishComponent !== null && finishComponent !== "") {
      isFinishedAvailable = true;
    }

    var indexOfCurrent = userTimelineItems.findIndex((o) => o.ComponentId === activeComponentId);
    if (indexOfCurrent !== -1) {
      var indexOfNext = null;
      var nextData = null;
      var indexOfPrevious = null;
      var previousData = null;

      let currentData = userTimelineItems[indexOfCurrent];
      if (isFinishedAvailable && currentData.ComponentType !== "FINISH" && currentData.ComponentType !== "WORKFLOW_SUMMARY") {
        currentData["isDisable"] = true;
      } else {
        currentData["isDisable"] = false;
      }
      currentData["isActive"] = true;

      if (indexOfCurrent !== userTimelineItems.length - 1) {
        indexOfNext = indexOfCurrent + 1;
        if (userTimelineItems[indexOfNext] !== undefined && userTimelineItems[indexOfNext] !== null) {
          nextData = userTimelineItems[indexOfNext];
          nextData["isActive"] = false;
          if (isFinishedAvailable && nextData.ComponentType !== "FINISH" && nextData.ComponentType !== "WORKFLOW_SUMMARY") {
            nextData["isDisable"] = true;
          } else {
            nextData["isDisable"] = false;
          }
        }
      }

      if (indexOfCurrent !== 0) {
        indexOfPrevious = indexOfCurrent - 1;
        if (userTimelineItems[indexOfPrevious] !== undefined && userTimelineItems[indexOfPrevious] !== null) {
          previousData = userTimelineItems[indexOfPrevious];
          previousData["isActive"] = false;
          if (isFinishedAvailable && previousData.ComponentType !== "FINISH" && previousData.ComponentType !== "WORKFLOW_SUMMARY") {
            previousData["isDisable"] = true;
          } else {
            previousData["isDisable"] = false;
          }
        }
      }

      if (previousData !== null) {
        newTimeline.push(previousData);
      }

      if (currentData !== null) {
        newTimeline.push(currentData);
      }
      if (nextData !== null) {
        newTimeline.push(nextData);
      }

      if (userTimelineItems.length > 2) {
        newTimeline.push({
          Type: "ShowMore",
          ID: "SHOW",
          Label: "Show More",
          isActive: false,
          isDisable: false,
        });
      }
      // console.log(currentData, 'currentData')
      // console.log(previousData, 'previousData')
      // console.log(nextData, 'nextData')
      // console.log(newTimeline, 'newTimeline')
    }
  }

  return newTimeline;
};

export const checkWorkflowFinished = (userTimeline) => {
  let data = {
    isFinished: false,
    workflowSummary: null,
  };
  if (userTimeline !== undefined && userTimeline !== null && userTimeline !== "") {
    let userTimelineItems = getAllComponents(userTimeline, []);
    // console.log(userTimelineItems, 'userTimelineItems')
    let isFinishedAvailable = false;
    let finishComponent = userTimelineItems.filter((o) => o.ComponentType === "FINISH")[0];
    if (finishComponent !== undefined && finishComponent !== null && finishComponent !== "") {
      isFinishedAvailable = true;
    }

    let workflowCom = null;
    let workflowSummaryComponent = userTimelineItems.filter((o) => o.ComponentType === "WORKFLOW_SUMMARY")[0];
    if (workflowSummaryComponent !== undefined && workflowSummaryComponent !== null && workflowSummaryComponent !== "") {
      workflowCom = workflowSummaryComponent;
    }
    data.isFinished = isFinishedAvailable;
    data.workflowSummary = workflowCom;
  }
  return data;
};

export const getNewActionsList = (actions, summaryIds) => {
  // console.log(actions, 'actions5555555555555555555')
  // console.log(summaryIds, 'summaryIds')
  summaryIds.forEach(function (chkId) {
    let sourceData = null;
    let targetData = null;

    let sourceIndex = actions.findIndex((o) => o.TargetComponentID === chkId);
    if (sourceIndex !== -1 && sourceIndex !== undefined && sourceIndex !== null) {
      sourceData = actions[sourceIndex];
      console.log(sourceData, "srcActionData");
    }

    let targetIndex = actions.findIndex((o) => o.SourceComponentID === chkId);
    if (targetIndex !== -1 && targetIndex !== undefined && targetIndex !== null) {
      targetData = actions[targetIndex];
      console.log(targetData, "targetActionData");
    }

    if (sourceData !== null && targetData !== null) {
      actions[sourceIndex].TargetComponentID = targetData.TargetComponentID;
      actions[targetIndex].SourceComponentID = sourceData.SourceComponentID;
    }
  });

  return actions;
};

let ids = [];
export const getMentionedUsersIds = (data) => {
  ids = [];
  interate(data);
  return ids;
};

const interate = (data) => {
  data.forEach((item) => {
    if (item.type === "mention") {
      ids.push(item.id);
    }
    if (item.children) {
      interate(item.children);
    }
  });
};

export const getWorkflowSummaryItems = (userTimeline, summaryItems, currentEvaId, evName, isFromFinish = false, isProductApproved = false) => {
  userTimeline.forEach(function (itm) {
    if (itm?.Type === 1) {
      let name = "";
      if (evName !== undefined && currentEvaId !== undefined && itm.EvaluationId !== currentEvaId) {
        name = evName + " - " + itm.Label;
      } else {
        name = itm.Label;
      }
      itm["Name"] = name;
      if (itm.ComponentType === "FORM") {
        summaryItems.push({
          ...itm,
          slug: itm.ComponentType,
          componentDetails: "",
        });
      } else if (itm.ComponentType === "QUESTIONNAIRE") {
        summaryItems.push({
          ...itm,
          slug: itm.ComponentType,
          componentDetails: "",
        });
      } else if (itm.ComponentType === "SELLER_INSTRUCTION") {
        summaryItems.push({
          ...itm,
          slug: itm.ComponentType,
          componentDetails: "",
        });
      } else if (isFromFinish && isProductApproved && itm.ComponentType === "APPROVAL_WORKFLOW") {
        summaryItems.push({
          ...itm,
          slug: itm.ComponentType,
          componentDetails: "",
        });
      }
    } else if (itm.Type === 2 && itm?.Children !== null) {
      getWorkflowSummaryItems(itm.Children, summaryItems, currentEvaId, itm.Label, isFromFinish, isProductApproved);
    }
  });
  return summaryItems;
};

export const dateDiffUsingMoment = (start, end) => {
  var a = moment(start, "M/D/YYYY");
  var b = moment(end, "M/D/YYYY");
  // var diffDaysMoment = b.diff(a, 'days');
  // console.log('Moments.js : ', diffDaysMoment);
  return preciseDiffMoments(a, b);
};

export const preciseDiffMoments = (start, end) => {
  var m1 = moment(start, "M/D/YYYY");
  var m2 = moment(end, "M/D/YYYY");
  m1.add(m2.utcOffset() - m1.utcOffset(), "minutes"); // shift timezone of m1 to m2
  var yDiff = m2.year() - m1.year();
  var mDiff = m2.month() - m1.month();
  var dDiff = m2.date() - m1.date();
  if (dDiff < 0) {
    var daysInLastFullMonth = moment(m2.year() + "-" + (m2.month() + 1), "YYYY-MM")
      .subtract(1, "M")
      .daysInMonth();
    if (daysInLastFullMonth < m1.date()) {
      // 31/01 -> 2/03
      dDiff = daysInLastFullMonth + dDiff + (m1.date() - daysInLastFullMonth);
    } else {
      dDiff = daysInLastFullMonth + dDiff;
    }
    mDiff--;
  }
  if (mDiff < 0) {
    mDiff = 12 + mDiff;
    yDiff--;
  }

  let finalDiff = "";
  if (yDiff > 0) {
    finalDiff = yDiff + (yDiff === 1 ? " Year " : " Years ");
  }
  if (mDiff > 0) {
    finalDiff += mDiff + (mDiff === 1 ? " Month " : " Months ");
  }
  if (dDiff > 0) {
    finalDiff += dDiff + (dDiff === 1 ? " Day " : " Days ");
  }

  if (finalDiff === "") {
    finalDiff = "Today";
  }
  return finalDiff;
  // console.log('getMomentum() Y:', yDiff, ', M:', mDiff, ', D:', dDiff);
};

// items types
export const quickLinkItems = [
  { slug: "EnableSubmitIntakeForm", label: "Start a new project", value: 1 },
  { slug: "EnableApplicationCatalog", label: "Vendor Catalog", value: 2 },
  { slug: "EnableExpentProductDirectory", label: "Expent Vendor Directory", value: 3 },
  { slug: "EnableActivity", label: "Activity", value: 4 },
];

export const OWASP_Risk_Assessment = [
  {
    sectionName: "Threat Agent Factors",
    factors: [
      {
        name: "Skill level",
        slug: "SkillLevel",
        selectedValue: 1,
        options: [
          {
            label: "No technical skills (1)",
            value: 1,
          },
          {
            label: "Some technical skills (3)",
            value: 3,
          },
          {
            label: "Advanced computer user (5)",
            value: 5,
          },
          {
            label: "Network and programming skills (6)",
            value: 6,
          },
          {
            label: "Security penetration skills (9)",
            value: 9,
          },
        ],
      },
      {
        name: "Motive",
        slug: "Motive",
        selectedValue: 1,
        options: [
          {
            label: "Low or no reward (1)",
            value: 1,
          },
          {
            label: "Possible reward (4)",
            value: 4,
          },
          {
            label: "High reward (9)",
            value: 9,
          },
        ],
      },
      {
        name: "Opportunity",
        slug: "Opportunity",
        selectedValue: 0,
        options: [
          {
            label: "Full access or expensive resources required (0)",
            value: 0,
          },
          {
            label: "Special access or resources required (4)",
            value: 4,
          },
          {
            label: "Some access or resources required (7)",
            value: 7,
          },
          {
            label: "No access or resources required (9)",
            value: 9,
          },
        ],
      },
      {
        name: "Size",
        slug: "Size",
        selectedValue: 2,
        options: [
          {
            label: "Developers, System administrators (2)",
            value: 2,
          },
          {
            label: "Intranet users (4)",
            value: 4,
          },
          {
            label: "Partners (5)",
            value: 5,
          },
          {
            label: "Authenticated users (6)",
            value: 6,
          },
          {
            label: "Anonymous Internet users (9)",
            value: 9,
          },
        ],
      },
    ],
  },
  {
    sectionName: "Technical Impact Factors",
    factors: [
      {
        name: "Loss of confidentiality",
        slug: "LossOfConfidentiality",
        selectedValue: 1,
        options: [
          {
            label: "Minimal non-sensitive data disclosed (2)",
            value: 1,
          },
          {
            label: "minimal critical data disclosed (6)",
            value: 3,
          },
          {
            label: "extensive non-sensitive data disclosed (6)",
            value: 5,
          },
          {
            label: "extensive critical data disclosed (7)",
            value: 6,
          },
          {
            label: "all data disclosed (9)",
            value: 9,
          },
        ],
      },
      {
        name: "Loss of integrity",
        slug: "LossOfIntegrity",
        selectedValue: 1,
        options: [
          {
            label: "Less than the cost to fix the vulnerability (1)",
            value: 1,
          },
          {
            label: "Minor effect on annual profit (3)",
            value: 4,
          },
          {
            label: "Significant effect on annual profit (7)",
            value: 9,
          },
          {
            label: "Bankruptcy (9)",
            value: 9,
          },
        ],
      },
      {
        name: "Loss of availability",
        slug: "LossOfAvailability",
        selectedValue: 1,
        options: [
          {
            label: "Minimal secondary services interrupted (1)",
            value: 1,
          },
          {
            label: "Minimal primary services interrupted (5)",
            value: 5,
          },
          {
            label: "Extensive secondary services interrupted (5)",
            value: 5,
          },
          {
            label: "Extensive primary services interrupted (7)",
            value: 7,
          },
          {
            label: "All services completely lost (9)",
            value: 9,
          },
        ],
      },
      {
        name: "Loss of accountability",
        slug: "LossOfAccountability",
        selectedValue: 1,
        options: [
          {
            label: "Fully traceable (1)",
            value: 1,
          },
          {
            label: "Possibly traceable (7)",
            value: 7,
          },
          {
            label: "Completely anonymous (9)",
            value: 9,
          },
        ],
      },
    ],
  },
  {
    sectionName: "Vulnerability Factors",
    factors: [
      {
        name: "Ease of discovery",
        slug: "EaseOfDiscovery",
        selectedValue: 1,
        options: [
          {
            label: "Practically impossible (1)",
            value: 1,
          },
          {
            label: "Difficult (3)",
            value: 3,
          },
          {
            label: "Easy (7)",
            value: 7,
          },
          {
            label: "Automated tools available (9)",
            value: 9,
          },
        ],
      },
      {
        name: "Ease of exploit",
        slug: "EaseOfExploit",
        selectedValue: 1,
        options: [
          {
            label: "Theoretical (1)",
            value: 1,
          },
          {
            label: "Difficult (3)",
            value: 3,
          },
          {
            label: "Easy (5)",
            value: 5,
          },
          {
            label: "Automated tools available (9)",
            value: 9,
          },
        ],
      },
      {
        name: "Awareness",
        slug: "Awareness",
        selectedValue: 1,
        options: [
          {
            label: "Unknown (1)",
            value: 1,
          },
          {
            label: "Hidden (4)",
            value: 4,
          },
          {
            label: "Obvious (6)",
            value: 6,
          },
          {
            label: "Public knowledge (9)",
            value: 9,
          },
        ],
      },
      {
        name: "Intrusion detection",
        slug: "IntrusionDetection",
        selectedValue: 1,
        options: [
          {
            label: "Active detection in application (1)",
            value: 1,
          },
          {
            label: "Logged and reviewed (3)",
            value: 3,
          },
          {
            label: "Logged without review (8)",
            value: 8,
          },
          {
            label: "Not logged (9)",
            value: 9,
          },
        ],
      },
    ],
  },
  {
    sectionName: "Business Impact Factors",
    factors: [
      {
        name: "Financial damage",
        slug: "FinancialDamage",
        selectedValue: 1,
        options: [
          {
            label: "Less than the cost to fix the vulnerability (1)",
            value: 1,
          },
          {
            label: "Minor effect on annual profit (3)",
            value: 3,
          },
          {
            label: "Significant effect on annual profit (7)",
            value: 7,
          },
          {
            label: "Bankruptcy (9)",
            value: 9,
          },
        ],
      },
      {
        name: "Reputation damage",
        slug: "ReputationDamage",
        selectedValue: 1,
        options: [
          {
            label: "Minimal damage (1)",
            value: 1,
          },
          {
            label: "Loss of major accounts (4)",
            value: 4,
          },
          {
            label: "Loss of goodwill (5)",
            value: 5,
          },
          {
            label: "Brand damage (9)",
            value: 9,
          },
        ],
      },
      {
        name: "Non-compliance",
        slug: "NonCompliance",
        selectedValue: 2,
        options: [
          {
            label: "Minor violation (2)",
            value: 2,
          },
          {
            label: "Clear violation (5)",
            value: 5,
          },
          {
            label: "High profile violation (7)",
            value: 7,
          },
        ],
      },
      {
        name: "Privacy violation",
        slug: "PrivacyViolation",
        selectedValue: 3,
        options: [
          {
            label: "One individual (3)",
            value: 3,
          },
          {
            label: "Hundreds of people (5)",
            value: 5,
          },
          {
            label: "Thousands of people (7)",
            value: 7,
          },
          {
            label: "Millions of people (9)",
            value: 9,
          },
        ],
      },
    ],
  },
];
