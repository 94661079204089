import {
  START_NOTIFICATION_FETCH,END_NOTIFICATION_FETCH,
  START_NOTIFICATION_MARKREAD,END_NOTIFICATION_MARKREAD,
  START_NOTIFICATION_DELETE,END_NOTIFICATION_DELETE,
} from "./action";

const initState   =     {
    fetchProg:false,
    notifications:[],
    totalNotifications: 0
}

export  function notificationsReducer(state=initState,action){
  switch(action.type){
    case START_NOTIFICATION_FETCH:
            var notifications = state.notifications;
            var totalNotifications = state.totalNotifications;
            if(action.payload == 0){
              notifications = [];
              totalNotifications = 0;
            }
            return {
                ...state,
                fetchProg:true,
                notifications:notifications,
                totalNotifications: totalNotifications
            };
    case END_NOTIFICATION_FETCH:
        var success     =   action.payload.success;
        var notifications     =   state.notifications;
        var totalNotifications = state.totalNotifications;
        if(success !== null){
            var Page    =   success.page;
            var Data    =   success.Data;
            var totalNotifications =  success.total;
            if(Page == 0){
              notifications     =  Data; 
            } else {
              notifications     = notifications.concat(Data)
            }
        }
        return {
            ...state,
            fetchProg:false,
            notifications:notifications,
            totalNotifications: totalNotifications
        }
    case END_NOTIFICATION_MARKREAD:
    case END_NOTIFICATION_DELETE:
        var notificationId     =   action.payload.success;
        var _notifications  = [];
        state.notifications.forEach((n)=>{
          if(n.ID !== notificationId){
            _notifications.push(n)
          }
        });
        return {
          notifications:_notifications,
          totalNotifications:(state.totalNotifications-1)
        }
    default:
        return state;
  }
}