import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Collapse, Divider, Switch, IconButton, CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';

import UserGroup from '../../../../../../../Common/UserGroup';
import OutlinedSelectInput from '../../../../../../../Common/Input/OutlinedSelectInput';
import { getRequirementType } from 'util/RequirementTypeOptions';

import {
    showReadMoreDialog, showEvaluationRequirementEditDialog,
    editRequirement, deleteRequirement, addRequirementColloborator
} from "../../../../../../../../redux/evaluation/criteria/action";
import { showSnackBar } from "../../../../../../../../redux/snackbar/action";
import classnames from "classnames";
import deleteIcon from "../../../../../../../../assets/images/delete.svg"
import { showEvaluationRuleDialog } from "redux/evaluation/rule/action";
import Image from 'Components/Common/image.jsx'
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

// components
import EvaluateMultiChoiceAnswer from 'Components/Common/EvaluateMultiChoiceAnswer'
import { showMessageDialog, hideMessageDialog, } from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import { showRequirementConditionDialog } from "redux/common/action";


const connectedProps = (state) => ({
    evaluateView: state.applications.evaluateView,
    evaluateViewId: state.applications.evaluateViewId,
    criterias: state.evaludationCriteria.criterias,
    editRequirementProgress: state.evaludationCriteria.editRequirementProgress,
    errorRequirementEdit: state.evaludationCriteria.errorRequirementEdit,
    criteriaMultiChoice: state.criteriaMultiChoice,
    addRequirementOptionInprogress: state.criteriaMultiChoice.addRequirementOptionInprogress,
    addRequirementOptionSuccess: state.criteriaMultiChoice.addRequirementOptionSuccess,
    addRequirementQuestionOptionError: state.criteriaMultiChoice.addRequirementQuestionOptionError,
    quizOptions: state.criteriaMultiChoice.quizOptions
});

const connectionActions = {
    showEvaluationRequirementEditDialog: showEvaluationRequirementEditDialog,
    editRequirement: editRequirement,
    deleteRequirement: deleteRequirement,
    addRequirementColloborator: addRequirementColloborator,
    showReadMoreDialog: showReadMoreDialog,
    showEvaluationRuleDialog: showEvaluationRuleDialog,
    showSnackBar: showSnackBar,
    showMessageDialog: showMessageDialog,
    hideMessageDialog: hideMessageDialog,
    showRequirementConditionDialog: showRequirementConditionDialog
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        position: 'relative',
        border: '1px solid #EBEBEB',
        borderRadius: 5,
        marginBottom: 18
    },
    expand: {
        transform: 'rotate(0deg)',
        padding: 0,
        borderRadius: 3,
        position: 'absolute',
        top: 16,
        right: 16,
        border: '1px solid #EDEDED',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    requirementExpanded: {
        border: '1px solid #49B8FA',
    },
    requirementCollapsed: {
        paddingBottom: 12
    },
    requirementCollapse: {
        padding: '12px 16px 0 25px',
        cursor: 'pointer',
    },
    requirementContainer: {
        padding: '30px 16px 0 25px',
        justifyContent: 'space-between'
    },
    title: {
        fontSize: theme.spacing(2),
        color: '#333333',
        fontWeight: 600,
        width: 'max-content',
        '&:hover': {
            background: '#f7f7f7'
        }
    },
    collapseTitle: {
        fontSize: theme.spacing(2),
        color: '#333333',
        fontWeight: 600,
    },
    collapseTxt: {
        fontSize: theme.spacing(2),
        color: '#707070',
        marginTop: theme.spacing(1),
    },
    bottomContainer: {
        padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
        borderRadius: 8,
        marginTop: 20,
        backgroundColor: '#FAFAFA',
        justifyContent: 'space-between',
    },
    bodyTxt: {
        borderRadius: 8,
        backgroundColor: '#FAFAFA',
        padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        fontSize: theme.spacing(2)
    },
    leftPanel: {
    },
    rightPanel: {
        paddingTop: 25
    },
    actions: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        maxWidth: 80,
        marginLeft: 'auto'
    },
    actionItem: {
        textAlign: "center",
        cursor: "pointer",
        marginRight: 4
    },
    editIcon: {
        color: "#999999"
    },
    caretIcon: {
        color: "#999999"
    },
    descriptionContainer: {
        marginTop: 18
    },
    description: {
        color: "#707070",
        fontSize: 13,
        minHeight: 50 // 106
    },
    priority: {
        marginTop: theme.spacing(3),
        "& > div": {
            marginBottom: 0,
        },
        "&.evaluate-priority": {
            display: 'flex',
            marginLeft: 0,
        },
        "& [class*=MuiFormLabel-root]": {
            fontSize: '0.9em'
        },
        "& [class*=MuiInputBase-root]": {
            color: '#707070',
            fontSize: 13,
            textAlign: 'center'
        },
        "& [class*=MuiSelect-outlined]": {
            color: "#707070",
            fontSize: theme.spacing(1.8),
            "&:after": {
                content: "''",
                width: 7,
                height: 7,
                position: 'absolute',
                left: 9,
                borderRadius: '50%',
                top: '50%',
                transform: 'translate(0px, -50%)'
            },
        },
        "&.low [class*=MuiSelect-outlined]:after": {
            background: 'green'
        },
        "&.medium [class*=MuiSelect-outlined]:after": {
            background: 'orange'
        },
        "&.high [class*=MuiSelect-outlined]:after": {
            background: 'red'
        }
    },
    scoringType: {
        marginLeft: 0,
        display: 'flex',
        flexDirection: 'row-reverse',
        "& [class*=MuiFormLabel-root]": {
            fontSize: theme.spacing(1.8)
        },
        "& [class*=MuiSelect-outlined]": {
            paddingLeft: 10
        }
    },
    userAndSelect: {
        display: "flex",
        flexDirection: 'column',
        '& label': {
            color: '#6C6C6C',
            fontSize: '0.9em',
            marginBottom: '0.7em'
        }
    },
    readMore: {
        color: '#4a88ff',
        cursor: 'pointer'
    },
    descriptionSection: {
        "& [class*='editableField']": {
            minHeight: 'auto !important',
            margin: '0px 10px 0px 0px !important'
        },
        "& [class*='editor-formControl']": {
            maxHeight: 80,
            transition: 'all linear .5s',
        },
        "& [class*='makeStyles-input-']": {
            marginBottom: 0,
            '& p': {
                marginTop: 0
            }
        }
    },
    '@global': {
        '.rootRequirementHight': {
            "& [class*='editor-formControl']": {
                maxHeight: 165
            }
        }
    },
    addSchedule: {
        marginTop: 6,
        fontSize: theme.spacing(1.6),
        color: "#4175DF",
        cursor: "pointer",
        display: 'inline-block'
    },
    label: {
        color: '#707070',
        fontSize: 12,
        fontWeight: 400,
    },
    nameEditCotnainer: {
        position: 'relative',
        paddingBottom: 20,
        flex: 1,
    },
    saveNameAction: {
        display: 'flex',
        position: 'absolute',
        bottom: 8,
        right: -1,
        margin: 'auto',
    },
    actionIcon: {
        width: 30,
        height: 30,
        borderRadius: 5,
        textAlign: 'center',
        marginLeft: 5,
        border: '1px solid #4b86f8'
    },
    micon: {
        fontSize: 16,
        marginTop: 6,
        color: '#4b86f8',
    },
    btn: {
        border: '1px solid #EBEBEB',
        borderRadius: '3px',
        outline: 0,
        padding: '6px 14px',
        cursor: 'pointer'
    },
    btnCancel: {
        background: '#FAFAFA',
        color: '#707070',
        borderColor: '#EBEBEB',
        marginRight: 16
    },
    btnSave: {
        background: '#FFFFFF',
        color: '#4175DF',
        borderColor: '#9DBDFA'
    },
    dragIconArea: {
        position: 'absolute',
        top: 15,
        left: '50%',
        color: '#D9D9D9',
        transform: 'rotate(90deg) translateY(-50%) translateX(-50%)',
    },
    OutlinedInput: {
        minHeight: '25px !important',
        padding: 6,
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        color: '#5F5F5F',
        fontSize: '1.1em'
    },
    priorityOutlinedInput: {
        minHeight: '25px !important',
        padding: 5,
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        color: '#5F5F5F',
        fontSize: '1.1em',
        width: 75,
        textAlign: 'center'
    },
    actionTxt: {
        color: '#4175DF',
        width: 'max-content',
        fontSize: theme.spacing(1.75),
        marginTop: 10,
        marginBottom: 10
    },
    saveLoader: {
        width: '15px!important',
        height: '15px!important',
    },
    loaderSpan: {
        margin: 17,
        fontSize: 25
    },
});

class Requirement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.requirement?.name || "",
            name_error: "",
            description: '',
            answertype: 1,
            is_editable: false,
            is_mandatory: false,
            description_error: "",
            isEditName: false,
            isEditDec: false,
            deleteCriteriaId: null,
            deleteReqId: null,
            isActiveDialog: false,
            priority: props.requirement?.priority === 0 ? '' : props.requirement?.priority,
            priority_error: '',
            isUpdateRequirementPriority: false,
            mandatoryLoader: false,
            editableLoader: false,
        }
        this.deleteRequirementItem = this.deleteRequirementItem.bind(this);
        this.callBackReq = this.callBackReq.bind(this);
        this.updateExisitngRequirement = this.updateExisitngRequirement.bind(this);
    }

    componentDidMount() {
        try {
            this.setState({
                name: this.props.requirement?.name || '',
                description: this.props.requirement?.description || '',
                priority: this.props.requirement?.priority === 0 ? '' : this.props.requirement?.priority,
                answertype: this.props.requirement?.AnswerType || this.props.requirement?.answer_type || 1,
                is_editable: this.props.requirement?.is_editable || false,
                is_mandatory: this.props.requirement?.is_mandatory || false
            })
        } catch (e) { }
    }

    componentDidUpdate(prevProps) {
        if (this.props.editRequirementProgress === false && prevProps.editRequirementProgress === true) {
            if (this.props.errorRequirementEdit === null) {
                this.setState({
                    error: "",
                    success: "",
                    'name_error': '',
                    'description_error': '',
                });
                this.props.showSnackBar("Question Updated", "success", 3000);
            } else {
                this.props.showSnackBar("Something went wrong", "error", 3000);
            }
            this.stopLoaders();
        }

        if (this.props.addRequirementOptionInprogress === false && prevProps.addRequirementOptionInprogress === true) {
            // console.log(this.props.addRequirementOptionSuccess,'addRequirementOptionSuccess')
            if (this.props.addRequirementOptionSuccess !== undefined && this.props.addRequirementOptionSuccess !== null && this.props.addRequirementOptionSuccess !== '' && this.props.addRequirementOptionSuccess.length > 0 && this.props.addRequirementOptionSuccess[0].RequirementID === this.props.requirement?.id) {
                if (this.props.addRequirementQuestionOptionError === null) {
                    // console.log('iiiii555555555555555555555555555555555555555555')
                    // this.updateRequirementData(this.props.criteria, this.props.requirement)
                }
            }
        }
    }

    stopLoaders() {
        this.setState({
            mandatoryLoader: false,
            editableLoader: false,
        })
    }

    getType(req) {
        let type = 'Rich Text'
        if (req && req?.answer_type) {
            const answer_type = parseInt(req?.answer_type)
            if (2 === answer_type) type = 'Multi Select'
            if (3 === answer_type) type = 'Single Select'
            if (4 === answer_type) type = 'Binary'
            if (5 === answer_type) type = 'Score'
            if (6 === answer_type) type = 'File upload'
            if (7 === answer_type) type = 'Simple Text'
        }
        return type
    }

    cleanError() {
        this.setState({
            name_error: "",
            description_error: "",
        })
    }

    saveName(name, description, evaluation_id, criteria_id, requirement_id) {
        this.cleanError();
        this.setState({
            name_error: "",
            description_error: ""
        });

        if (name.trim().length === 0) {
            this.setState({
                name_error: "Please enter Section Name."
            });
            return;
        }
        if (description.trim().length === 0) {
            this.setState({
                description_error: "Please enter Description."
            });
            return;
        }

        this.props.editRequirement(
            evaluation_id,
            criteria_id,
            requirement_id,
            {
                name: name,
                description: description,
                priority: this.props.requirement.priority,
                answertype: this.props.requirement.AnswerType,
                is_editable: this.props.requirement.is_editable,
                is_mandatory: this.props.requirement.is_mandatory
            },
            this.props.match?.params?.component_id
        )
        this.setState({
            isEditName: false,
            isEditDec: false
        });
    }

    deleteRequirementItem(e, criteria_id, req_id) {
        e.stopPropagation();
        var me = this;
        this.setState({
            deleteCriteriaId: criteria_id,
            deleteReqId: req_id,
            isActiveDialog: true,
        }, () => {
            me.props.showMessageDialog();
        })
    }

    callBackReq = () => {
        if (this.state.deleteCriteriaId) {
            this.props.hideMessageDialog();
            this.props.deleteRequirement(this.props.match.params.evaluation_id, this.state.deleteCriteriaId, this.state.deleteReqId)
            this.setState({
                isActiveDialog: false
            })
        }
    };

    handleMultiSelect = (id, answer_type) => {
        const element = document.getElementById(id)
        if (element && element.click() && [2, 3, 4, 14, 15].includes(answer_type)) {
            element.click()
        }
    }

    getCharLimit = (req) => {
        let rt = ''
        if (req.answer_type === 7 && req.character_limit) {
            rt = `- Character Limit ${req.character_limit}`
        }
        return rt
    }

    updatePriority(requirementId) {
        this.setState({
            priority_error: ''
        })
        if (this.state.priority <= 0 || this.state.priority > 100) {
            this.setState({
                priority_error: 'Please enter weightage between 1 to 100.'
            })
        } else {
            this.props.editRequirement(
                this.props.match.params.evaluation_id,
                this.props.criteria.id,
                requirementId,
                {
                    priority: parseInt(this.state.priority)
                },
                this.props.match?.params?.component_id
            )

            this.setState({
                isUpdateRequirementPriority: false
            });
        }
    }

    updateExisitngRequirement(btnId, criteria, requirement) {
        if ([2, 3, 4, 14, 15].includes(requirement?.answer_type)) {
            this.handleMultiSelect(btnId, requirement?.answer_type)
        } else {
            this.updateRequirementData(criteria, requirement)
        }
    }

    updateRequirementData(criteria, requirement) {
        // console.log(this.props.quizOptions,'quizOptions')
        // console.log(requirement,'requirement')
        // console.log(criteria,'criteria')
        const { quizOptions } = this.props;
        this.props.editRequirement(
            this.props.match.params.evaluation_id,
            criteria.id,
            requirement.id,
            {
                name: requirement.name,
                description: requirement.description,
                priority: requirement.priority,
                answertype: requirement.answer_type,
                is_editable: requirement.is_editable,
                is_mandatory: requirement.is_mandatory,
                character_limit: quizOptions !== null && quizOptions.length > 0 ? parseInt(quizOptions[0].title) : requirement.character_limit !== undefined && requirement.character_limit !== null && requirement.character_limit !== '' ? requirement.character_limit : 0
            },
            this.props.match?.params?.component_id
        )
        this.props.toggleRequirement(false)
    }

    render() {

        const classes = this.props.classes;
        const requirement = this.props.requirement;
        const criteria = this.props.criteria;
        if (requirement == null || requirement === undefined) {
            return null;
        }
        var editable = true;
        if (this.props.editable !== undefined) {
            editable = this.props.editable
        }
        const btnId = `criteria_requirement_sbtn_${criteria.id}_${requirement.id}`

        let messageData = {
            head: "Are you sure?",
            subHead: "Do you really want to delete? This process cannot be undone.",
            firstBtn: "Cancel",
            secondBtn: "Yes",
        };

        var pOptions1 = [
            {
                value: 1,
                label: "Critical"
            },
            {
                value: 2,
                label: "High"
            },
            {
                value: 3,
                label: "Medium"
            },
            {
                value: 4,
                label: "Low"
            },
            {
                value: 5,
                label: "Not Important"
            }
        ]

        var pOptions2 = [
            {
                value: 1,
                label: "1"
            },
            {
                value: 2,
                label: "2"
            },
            {
                value: 3,
                label: "3"
            },
            {
                value: 4,
                label: "4"
            },
            {
                value: 5,
                label: "5"
            }
        ]

        var priorityOptions = this.props.weighting_mechanism?.id === 'Numerical' ? pOptions2 : pOptions1;

        var priorityText = "Add Weightage";
        if (requirement.priority !== null && requirement.priority !== '' && requirement.priority > 0) {
            priorityText = 'Weightage: ' + requirement.priority + '%';
        }

        return (
            <div id={`requirement_${this.props.criteria?.id}_${this.props.requirement?.id}`} className={classnames(classes.root, `requirement_${requirement.id}`, this.props.isOpen ? classes.requirementExpanded : classes.requirementCollapsed)}>
                <DragIndicatorOutlinedIcon className={classes.dragIconArea} />
                {!this.state.isEditName && (
                    <IconButton
                        className={classnames(classes.expand, {
                            [classes.expandOpen]: this.props.isOpen,
                        })}
                        aria-expanded={requirement.id}
                        aria-label="show more"
                        onClick={() => { this.props.toggleRequirement(this.props.isOpen ? false : `requirement_${requirement?.id}`) }}
                    >
                        <ArrowDropDownIcon />
                    </IconButton>
                )}
                {!this.props.isOpen && (
                    <div
                        className={classes.requirementCollapse}
                        onClick={e => {
                            e.stopPropagation()
                            this.props.toggleRequirement(`requirement_${requirement?.id}`)
                        }}
                    >
                        <Typography component="p" className={classes.collapseTitle}>{requirement.name}</Typography>
                        <Typography component="p" className={classes.collapseTxt}>
                            <SlateReadonlyField initValue={requirement.description} />
                        </Typography>
                        <Typography component="p" className={classes.collapseTxt}>Answer type - {getRequirementType(requirement, requirement?.answer_type)} {this.getCharLimit(requirement)}</Typography>
                    </div>
                )}
                <Collapse in={this.props.isOpen} timeout="auto" unmountOnExit>
                    <Grid container className={classes.requirementContainer}>
                        <Grid item xs={12}>
                            {/* <p>Weight Mac: {this.props.weighting_mechanism}</p> */}
                            {!this.state.isEditName
                                ? (
                                    <Typography component="p" className={classes.title}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (editable) {
                                                this.setState({
                                                    isEditName: true
                                                })
                                            }
                                        }}
                                    >
                                        {this.state.name}
                                    </Typography>
                                ) : (
                                    <Grid container style={{ alignItems: 'end' }}>
                                        <input
                                            value={this.state.name}
                                            onChange={(event) => {
                                                event.stopPropagation()
                                                this.setState({ name: event.target.value });
                                            }}
                                            className={classes.OutlinedInput}
                                        />
                                        <Grid container style={{ width: 'max-content', alignItems: 'end' }}>
                                            <div
                                                className={classes.actionIcon}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.handleMultiSelect(btnId, requirement.answer_type)
                                                    this.cleanError()
                                                    this.setState({ name: requirement.name, isEditName: false })
                                                }}
                                            >
                                                <ClearIcon className={classes.micon} />
                                            </div>
                                            {this.state.name && (
                                                <div
                                                    className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.saveName(
                                                            this.state.name || requirement.name,
                                                            this.state.description || requirement.description,
                                                            this.props.match.params.evaluation_id,
                                                            criteria.id,
                                                            requirement.id
                                                        )
                                                    }}
                                                >
                                                    <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                )
                            }

                            {!this.state?.isEditDec ? (
                                <Typography component="p" className={classes.bodyTxt} onClick={() => { if (editable) this.setState({ isEditDec: true }) }}>
                                    <SlateReadonlyField initValue={this.state.description || requirement.description} />
                                </Typography>
                            ) : (
                                <div className={classes.nameEditCotnainer}>
                                    <SlateInputField
                                        error={this.state.description_error.length > 0}
                                        helperText={this.state.description_error}
                                        folderPath={`/evaluation/${this.props?.editDialog?.evaluationId}/criteria/${this.props?.editDialog?.criteriaId}/requirement/${this.props?.editDialog?.requirement?.id}`}
                                        placeholder=""
                                        as={SlateInputField}
                                        onChangeEvent={(value) => {
                                            this.setState({ description: value });
                                        }}
                                        initValue={this.state.description || requirement.description}
                                        textContainerStyle={{ color: "#707070", }}
                                    />
                                    <div className={classes.saveNameAction}>
                                        <div
                                            className={classes.actionIcon}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                this.handleMultiSelect(btnId, requirement.answer_type)
                                                this.cleanError()
                                                this.setState({ name: requirement.name, isEditDec: false })
                                            }}
                                        >
                                            <ClearIcon className={classes.micon} />
                                        </div>
                                        <div
                                            className={classnames(classes.actionIcon)}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                this.saveName(
                                                    this.state.name || requirement.name,
                                                    this.state.description || requirement.description,
                                                    this.props.match.params.evaluation_id,
                                                    criteria.id,
                                                    requirement.id
                                                )
                                            }}
                                        >
                                            <CheckIcon className={classnames(classes.micon)} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={6} className={classes.leftPanel}>
                            <EvaluateMultiChoiceAnswer
                                evaluation_id={this.props.match.params.evaluation_id}
                                requirement={requirement}
                                criteria={criteria}
                                editable={editable}
                                isTemplate={false}
                                saveBtnId={btnId}
                                isCriteriaRequirement
                            />
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={4} className={classes.RightPanel}>
                            {!this.props.evaluateView && (
                                <div className={classes.rightAction}>
                                    <label className={classes.label} style={{ marginBottom: 10 }}>
                                        {editable ? "Add members" : "Members"}
                                    </label>
                                    <UserGroup
                                        position={'Requirement'}
                                        showNotificationIcon={true}
                                        notificationData={{
                                            type: 'notification.evaluation.requirement.colloborator.nudge',
                                            criteria: criteria,
                                            requirement: requirement,
                                        }}
                                        requirement_info={{
                                            criteriaId: criteria.id,
                                            requirementId: requirement.id,
                                            evaluationId: this.props.match.params.evaluation_id
                                        }}
                                        editable={editable}
                                        onAdd={(user) => {
                                            this.handleMultiSelect(btnId, requirement.answer_type)
                                            this.props.addRequirementColloborator(
                                                this.props.match.params.evaluation_id,
                                                criteria.id,
                                                requirement.id,
                                                user.ID)
                                        }} users={requirement.colloborator} criterias={this.props.criterias} />
                                </div>
                            )}

                            {false && <div className={classnames(classes.rightAction, classes.priority, 'evaluate-priority', requirement.priority && parseInt(requirement.priority) === 1 ? 'low' : requirement.priority && parseInt(requirement.priority) === 2 ? 'medium' : requirement.priority && parseInt(requirement.priority) === 3 ? 'high' : '')}>
                                <OutlinedSelectInput
                                    toolTip={this.props.index === 0 ? "25" : ''}
                                    label="Priority"
                                    value={requirement.priority}
                                    compact={true}
                                    options={priorityOptions}
                                    disabled={!editable}
                                    onChange={(event) => {
                                        event.stopPropagation()
                                        this.handleMultiSelect(btnId, requirement.answer_type)
                                        this.props.editRequirement(
                                            this.props.match.params.evaluation_id,
                                            criteria.id,
                                            requirement.id,
                                            {
                                                priority: parseInt(event.target.value)
                                            },
                                            this.props.match?.params?.component_id
                                        )
                                    }}
                                />
                            </div>}

                            {this.state.priority_error !== null && this.state.priority_error.length > 0 && <>
                                <span style={{ color: 'red', fontSize: 12 }}>{this.state.priority_error}</span>
                            </>}

                            {this.props.enableScoring && <>
                                {!this.state.isUpdateRequirementPriority
                                    ? (
                                        <Typography
                                            className={classes.actionTxt}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({
                                                    isUpdateRequirementPriority: true
                                                })
                                            }}>{priorityText}</Typography>
                                    ) : (
                                        <Grid container style={{ marginTop: 10, alignItems: 'end' }}>
                                            <input
                                                value={this.state.priority}
                                                pattern="[0-9]"
                                                className={classes.priorityOutlinedInput}
                                                onChange={(event) => {
                                                    var newValue = parseInt(event.target.value) || '';
                                                    this.setState({ priority: newValue });
                                                }}
                                            ></input>
                                            <Grid container style={{ width: 'max-content', alignItems: 'end' }}>
                                                <div
                                                    className={classes.actionIcon}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.cleanError()
                                                        this.setState({
                                                            priority_error: '',
                                                            priority: requirement.priority === 0 ? '' : requirement.priority,
                                                            isUpdateRequirementPriority: false
                                                        })
                                                    }}
                                                >
                                                    <ClearIcon className={classes.micon} />
                                                </div>
                                                {this.state.priority && (
                                                    <div
                                                        className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                                                        onClick={(e) => {
                                                            e.stopPropagation()

                                                            this.updatePriority(requirement?.id)
                                                        }}
                                                    >
                                                        <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                            </>}

                            <Typography
                                className={classes.actionTxt}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.showRequirementConditionDialog({
                                        rules: requirement.rules,
                                        requirement: requirement,
                                        criterias: this.props.criterias,
                                        isEvaluation: true,
                                        criteria: criteria,
                                        criteriaId: criteria.id,
                                        id: this.props.match.params?.evaluation_id,
                                        requirements: criteria.requirements,
                                        maxScore: this.props.maxScore
                                    })
                                }}>
                                {requirement.rules && requirement.rules.length > 0 ? 'Update Condition' : 'Add Condition'}
                            </Typography>

                            <div className={classes.rightAction} style={{ marginTop: 10 }}>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <label className={classes.label} style={{ marginRight: 8 }}>
                                            Required
                                        </label>
                                        <br />
                                        <br />
                                        <label className={classes.label} style={{ marginRight: 8 }}>
                                            Editable
                                        </label>

                                    </Grid>
                                    <Grid item>
                                        <Switch
                                            checked={requirement.is_mandatory}
                                            disabled={!editable}
                                            onChange={e => {
                                                e.stopPropagation()
                                                this.setState({
                                                    mandatoryLoader: true
                                                })
                                                this.handleMultiSelect(btnId, requirement.answer_type)
                                                this.props.editRequirement(
                                                    this.props.match.params.evaluation_id,
                                                    criteria.id,
                                                    requirement.id,
                                                    {
                                                        is_mandatory: e.target.checked
                                                    },
                                                    this.props.match?.params?.component_id
                                                )
                                            }}
                                            name="is_mandatory"
                                            color="primary"
                                            inputProps={{ 'aria-label': 'is_mandatory checkbox' }}
                                        />
                                        <br />
                                        <Switch
                                            checked={requirement.is_editable}
                                            disabled={!editable}
                                            onChange={e => {
                                                e.stopPropagation()
                                                this.setState({
                                                    editableLoader: true
                                                })
                                                this.handleMultiSelect(btnId, requirement.answer_type)
                                                this.props.editRequirement(
                                                    this.props.match.params.evaluation_id,
                                                    criteria.id,
                                                    requirement.id,
                                                    {
                                                        is_editable: e.target.checked
                                                    },
                                                    this.props.match?.params?.component_id
                                                )
                                            }}
                                            color="primary"
                                            name="is_editable"
                                            inputProps={{ 'aria-label': 'is_editable checkbox' }}
                                        />
                                    </Grid>
                                    {<Grid item>
                                        {this.state.mandatoryLoader && <label className={classes.label} style={{ marginRight: 8 }}>
                                            <CircularProgress className={classes.saveLoader} />
                                        </label>}
                                        {!this.state.mandatoryLoader && <label className={classes.label} style={{ marginRight: 8 }}>
                                        </label>}
                                        <br />
                                        <br />
                                        {this.state.editableLoader && <label className={classes.label} style={{ marginRight: 8 }}>
                                            <CircularProgress className={classes.saveLoader} />
                                        </label>}
                                        {!this.state.editableLoader && <label className={classes.label} style={{ marginRight: 8 }}>
                                        </label>}
                                    </Grid>}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.bottomContainer} >
                        <div className={classes.actionItem}>
                            {editable && (
                                <Grid
                                    container
                                    alignItems="center"
                                    onClick={(e) => {
                                        this.deleteRequirementItem(e, criteria.id, requirement.id);
                                    }}
                                >
                                    <Typography><span className={classes.label}>Delete</span></Typography>&nbsp;&nbsp;
                                    <Image alt="Delete" src={deleteIcon} />
                                </Grid>
                            )}
                        </div>

                        <div className={classes.actionItem}>
                            <button
                                type="button"
                                className={classnames(classes.btn, classes.btnCancel)}
                                onClick={() => { this.props.toggleRequirement(false) }}
                            >
                                Cancel
                            </button>
                            {editable && (
                                <button
                                    type="button"
                                    className={classnames(classes.btn, classes.btnSave)}
                                    onClick={() => {

                                        this.updateExisitngRequirement(btnId, criteria, requirement);
                                        // this.handleMultiSelect(btnId, requirement.answer_type)
                                        // _.debounce(() => {
                                        //     this.props.editRequirement(
                                        //         this.props.match.params.evaluation_id,
                                        //         criteria.id,
                                        //         requirement.id,
                                        //         {
                                        //             name: requirement.name,
                                        //             description: requirement.description,
                                        //             priority: requirement.priority,
                                        //             answertype: requirement.AnswerType,
                                        //             is_editable: requirement.is_editable,
                                        //             is_mandatory: requirement.is_mandatory
                                        //         }
                                        //     )
                                        //     this.props.toggleRequirement(false)
                                        // }, 1000)();
                                    }}
                                >
                                    Save
                                </button>
                            )}
                        </div>
                    </Grid>
                </Collapse>
                {this.state.isActiveDialog && <MessageDialog messageData={messageData} callBack={this.callBackReq} />}
            </div>
        )
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Requirement));