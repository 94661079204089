import React, { useEffect, useState } from 'react';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import ImageIcon from '@material-ui/icons/Image';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import LinkIcon from '@material-ui/icons/Link';
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption';
import CodeIcon from '@material-ui/icons/Code';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';

import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { isBlockActive, isMarkActive, toggleBlock, toggleMark } from './SlateEditorUtil';
//import { InsertVideoButton } from './SlateEmbeds';
import { InsertLinkButton, InsertAttachmentButton, InsertImageButton } from './SlateImage';
import { InsertAddVariableButton } from './InsertVariables';
import { InsertAddVisualizationButton } from './InsertVisualization';

import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";
import Table from "../SlateEditorNew/Elements/Table/Table";
import InTable from "../SlateEditorNew/Elements/Table/InTable";
import ColorPicker from "../SlateEditorNew/Elements/ColorPicker/ColorPicker";


import HeadingOne from "assets/images/icons/heading1.svg"
import HeadingTwo from "assets/images/icons/heading2.svg"
import HeadingThree from "assets/images/icons/heading3.svg"
import Image from 'Components/Common/image.jsx'

import useTable from "../SlateEditorNew/utils/useTable";
import PieChartIcon from '@material-ui/icons/PieChart';

import { InsertAddAutomationVariableButton } from "./InsertAutomationVariables"

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      style={{ opacity: 0.5 }}
      value={format}
      selected={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      value={format}
      selected={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

export const SlateToolbar = (props) => {
  const classes = useStyles();
  const isFile = props?.isFile === false ? false : true
  const editor = useSlate();
  const isTable = useTable(editor);

  return (
    <Paper className={classes.paper}>
      <StyledToggleButtonGroup size="small" arial-label="text formatting">
        {MarkButton({ format: "bold", icon: <FormatBoldIcon size={18} /> })}
        {MarkButton({
          format: "italic",
          icon: <FormatItalicIcon size={18} />,
        })}
        {MarkButton({
          format: "underline",
          icon: <FormatUnderlinedIcon size={18} />,
        })}
        {MarkButton({
          format: "code",
          icon: <CodeIcon size={20} />,
        })}
        <div className={classes.buttonTable}>
          <ColorPicker
            activeMark={activeMark}
            format={"color"}
            editor={editor}
          />
        </div>
        <div className={classes.buttonTable}>
          <ColorPicker
            activeMark={activeMark}
            format={"bgColor"}
            editor={editor}
          />
        </div>
      </StyledToggleButtonGroup>
      <Divider orientation="vertical" className={classes.divider} />
      <StyledToggleButtonGroup
        size="small"
        exclusive
      >
        {!isTable && BlockButton({
          format: "numbered-list",
          icon: <FormatListNumberedIcon size={24} />,
        })}

        {!isTable && BlockButton({
          format: "bulleted-list",
          icon: <FormatListBulletedIcon size={34} />,
        })}

        {!isTable && props.isShowHeadingOption && BlockButton({
          format: "heading-one",
          icon: <Image style={{ width: 30 }} src={HeadingOne} alt="Heading 1" />,
        })}

        {!isTable && props.isShowHeadingOption && BlockButton({
          format: "heading-two",
          icon: <Image style={{ width: 30 }} src={HeadingTwo} alt="Heading 2" />,
        })}

        {!isTable && props.isShowHeadingOption && BlockButton({
          format: "heading-three",
          icon: <Image style={{ width: 30 }} src={HeadingThree} alt="Heading 3" />,
        })}

        {!isTable && props.isShowAlignmentOption && BlockButton({
          format: "alignLeft",
          icon: <FormatAlignLeftIcon size={34} />,
        })}

        {!isTable && props.isShowAlignmentOption && BlockButton({
          format: "alignCenter",
          icon: <FormatAlignCenterIcon size={34} />,
        })}

        {!isTable && props.isShowAlignmentOption && BlockButton({
          format: "alignRight",
          icon: <FormatAlignRightIcon size={34} />,
        })}

      </StyledToggleButtonGroup>
      {isFile && (
        <div className={classes.button}>
          {InsertLinkButton({
            icon: <LinkIcon size={28} />,
          })}
          {InsertAttachmentButton({
            icon: <AttachFileIcon size={28} />,
            folderPath: props.folderPath || '',
            external: props.external || false,
            id: props.id
          })}
          {props.isShowImageOption && InsertImageButton({
            icon: <ImageIcon size={28} />,
            folderPath: props.folderPath || '',
            external: props.external || false
          })}
          {props.isShowAddVariableOption && InsertAddVariableButton({
            icon: <ClosedCaptionIcon size={28} />,
            workflowId: props.workflowId
          })}

          {props.isShowAddAutomationVariableOption && InsertAddAutomationVariableButton({
            icon: <ClosedCaptionIcon size={28} />,
            automationVariables: props.automationVariables
          })}

          {props.isShowAddVisualizationOption && InsertAddVisualizationButton({
            icon: <PieChartIcon size={28} />,
            workflowId: props.workflowId
          })}

        </div>
      )}

      {props.isShowTableOption && <>
        <Divider orientation="vertical" className={classes.divider} />
        <div className={classes.buttonTable}>
          <Table editor={editor} />
        </div>
        <Divider orientation="vertical" className={classes.divider} />
      </>}

      {isTable && <>
        <div className={classes.buttonTable}>
          <InTable editor={editor} />
        </div>
      </>}


      {props.actionButtonsRequired !== undefined && props.actionButtonsRequired === true &&
        <Divider orientation="vertical" className={classes.divider} />}
      <div className={classes.actionButtons}>
        {props.customBtn && props.customBtnHTML}
        {props.actionButtonsRequired !== undefined && props.actionButtonsRequired === true && (
          <>
            <Button
              className={classes.savetickMark}
              onClick={() => props.saveData()}
            >
              <DoneIcon />
            </Button>
            <Button
              className={classes.canceltickMark}
              onClick={() => props.cancelSave()}
            >
              <CloseIcon />
            </Button>
          </>
        )}
      </div>
    </Paper>
  );
};

export const HoveringToolbar = () => {
  const editor = useSlate();
  const [showToolbar, setShowToolbar] = useState(false);
  const { selection } = editor;
  useEffect(() => {
    if (!selection || Editor.string(editor, selection) === "") {
      setShowToolbar(false);
    } else {
      setShowToolbar(true);
    }
  }, [editor, selection]);

  return (
    <div hidden={!showToolbar}>
      <SlateToolbar />
    </div>
  );
};

export const activeMark = (editor, format) => {
  const defaultMarkData = {
    color: "black",
    bgColor: "black",
    fontSize: "normal",
    fontFamily: "sans"
  };
  const marks = Editor.marks(editor);
  const defaultValue = defaultMarkData[format];
  return marks?.[format] ?? defaultValue;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexWrap: "wrap",
      boxShadow: 'none',
      borderTop: '1px solid rgba(0, 0, 0, 0.23)',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    },
    divider: {
      alignSelf: "stretch",
      height: "auto",
      margin: theme.spacing(1, 0.5),
    },
    button: {
      border: "none",
      "& [class*='MuiIconButton-root']": {
        padding: 8
      }
    },
    actionButtons: {
      position: 'absolute',
      right: '10px',
      bottom: '5px',
      display: 'flex'
    },
    savetickMark: {
      color: '#2EB77D'
    },
    canceltickMark: {
      color: 'red'
    },
    buttonTable: {
      display: 'flex',
      alignItems: 'center'
    }
  })
);

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(0.5),
    border: "none",
    padding: theme.spacing(0, 1.2),
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);