import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { Grid, CircularProgress } from "@material-ui/core";
import SqureButton from "Components/Common/SqureButton";
import classnames from "classnames";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    bottomLinks: {
      position: "fixed",
      bottom: "0",
      padding: theme.spacing(1),
      left: 0,
      right: 0,
      paddingLeft: theme.spacing(24),
      paddingRight: theme.spacing(8),
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px -1px 6px #00000010",
      zIndex: 1000,
    },
  });

class BottomActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    const classes = this.props.classes;
    return (
      <div>
        <Grid container className={classnames(classes.bottomLinks)}>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            {
              <SqureButton
                style={{ marginLeft: "10px" }}
                variant={"outlined"}
                onClick={() => {
                  this.props.skipEmail();
                }}
              >
                {this.props.skipProgress ? <CircularProgress style={{ width: 22, height: 22 }} /> : "Skip"}
              </SqureButton>
            }

            {!this.props.sendEmailProgress && (
              <SqureButton style={{ marginLeft: "10px" }} variant={"contained"} onClick={this.props.submitResponse}>
                Send
              </SqureButton>
            )}
            {this.props.sendEmailProgress && (
              <div className={classes.actions}>
                {" "}
                <CircularProgress />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(BottomActions));
