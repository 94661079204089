import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import UserWiseProgress from "./UserWiseProgress";   
import AllUserProgress from "./allUserProgress";   
import SqureButton from '../../../../../../../Common/SqureButton';

import { showUserWiseWorkflowProgressDialog, showAllUsersWorkflowProgressDialog } from "redux/evaluation/workflow/action";

const connectedProps = (state) => ({
  isUserWiseWorflowProgressDialogOpen: state.evaluationWorkflow.isUserWiseWorflowProgressDialogOpen,
  isAllUsersWorflowProgressDialogOpen: state.evaluationWorkflow.isAllUsersWorflowProgressDialogOpen,
}); 

const connectionActions = {
  showUserWiseWorkflowProgressDialog: showUserWiseWorkflowProgressDialog,
  showAllUsersWorkflowProgressDialog: showAllUsersWorkflowProgressDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  actionBtn:{
    margin:10
  }
});

class WorkflowProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  render() {
    const classes = this.props.classes;

    return (
      <div>
        <div style={{textAlign:'center',marginTop:30}}>
          <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={() => {
            this.props.showUserWiseWorkflowProgressDialog();
          }}>Project Progress</SqureButton>
          <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={() => {
            this.props.showAllUsersWorkflowProgressDialog();
          }}>All User Progress</SqureButton>
        </div>
        <UserWiseProgress />
        <AllUserProgress />
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(WorkflowProgress));
