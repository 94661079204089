import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment"

export const START_EVALUATION_USER_FEEDBACK_FETCH         =   "START_EVALUATION_USER_FEEDBACK_FETCH";
export const END_EVALUATION_USER_FEEDBACK_FETCH         =   "END_EVALUATION_USER_FEEDBACK_FETCH";


export const SHOW_EVALUATION_FEEDBACK_TAKE_DIALOG         =   "SHOW_EVALUATION_FEEDBACK_TAKE_DIALOG";
export const HIDE_EVALUATION_FEEDBACK_TAKE_DIALOG         =   "HIDE_EVALUATION_FEEDBACK_TAKE_DIALOG";

export const START_EVALUATION_FEEDBACK_SUBMIT         =   "START_EVALUATION_FEEDBACK_SUBMIT";
export const END_EVALUATION_FEEDBACK_SUBMIT         =   "END_EVALUATION_FEEDBACK_SUBMIT";

export const START_EVALUATION_FEEDBACK_FETCH         =   "START_EVALUATION_FEEDBACK_FETCH";
export const END_EVALUATION_FEEDBACK_FETCH         =   "END_EVALUATION_FEEDBACK_FETCH";

export const SHOW_EVALUATION_PRE_FEEDBACK_TAKE_DIALOG         =   "SHOW_EVALUATION_PRE_FEEDBACK_TAKE_DIALOG";
export const HIDE_EVALUATION_PRE_FEEDBACK_TAKE_DIALOG         =   "HIDE_EVALUATION_PRE_FEEDBACK_TAKE_DIALOG";

export const START_EVALUATION_PRE_FEEDBACK_SUBMIT         =   "START_EVALUATION_PRE_FEEDBACK_SUBMIT";
export const END_EVALUATION_PRE_FEEDBACK_SUBMIT         =   "END_EVALUATION_PRE_FEEDBACK_SUBMIT";

export const SHOW_EVALUATION_FINALIZE_REQ_DIALOG         =   "SHOW_EVALUATION_FINALIZE_REQ_DIALOG";
export const HIDE_EVALUATION_FINALIZE_REQ_DIALOG         =   "HIDE_EVALUATION_FINALIZE_REQ_DIALOG";

export const SHOW_EVALUATION_PRE_FEEDBACK_ADMIN_DIALOG         =   "SHOW_EVALUATION_PRE_FEEDBACK_ADMIN_DIALOG";
export const HIDE_EVALUATION_PRE_FEEDBACK_ADMIN_DIALOG         =   "HIDE_EVALUATION_PRE_FEEDBACK_ADMIN_DIALOG";

export const SHOW_EVALUATION_OVERALL_REQ_DIALOG         =   "SHOW_EVALUATION_OVERALL_REQ_DIALOG";
export const HIDE_EVALUATION_OVERALL_REQ_DIALOG         =   "HIDE_EVALUATION_OVERALL_REQ_DIALOG";

export const SHOW_OVERALL_USER_WISE_PROGRESS_DIALOG         =   "SHOW_OVERALL_USER_WISE_PROGRESS_DIALOG";
export const HIDE_OVERALL_USER_WISE_PROGRESS_DIALOG         =   "HIDE_OVERALL_USER_WISE_PROGRESS_DIALOG";

export const SHOW_USER_COUNT_DEAILS_DIALOG = "SHOW_USER_COUNT_DEAILS_DIALOG";
export const HIDE_USER_COUNT_DEAILS_DIALOG = "HIDE_USER_COUNT_DEAILS_DIALOG";

export  function showFeedbackTakeDialog(){
    return     {
        type:SHOW_EVALUATION_FEEDBACK_TAKE_DIALOG,
        payload:{}
    };
}


export  function hideFeedbackTakeDialog(){
    return     {
        type:HIDE_EVALUATION_FEEDBACK_TAKE_DIALOG,
        payload:{}
    };
}

//-------------------------------------
function startEvauationFeedbackAdd(){
    return {
        type:START_EVALUATION_FEEDBACK_SUBMIT,
        payload:{}
    };
}

function endEvauationFeedbackAdd(success,error){
    return {
        type:END_EVALUATION_FEEDBACK_SUBMIT,
        payload:{
            success,error
        }
    };
}


//Thunk to perform the authorizedUser
export  function addEvaluationFeedback(evId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/feedback?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startEvauationFeedbackAdd());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endEvauationFeedbackAdd(response,error));
        } catch(e){
            dispatch(endEvauationFeedbackAdd(null,null,{
                message:e.message
            }));
            return;
        }
    };
}  
//------------------------------------------

//-------------------------------------
function startEvauationFeedbackFetch(){
    return {
        type:START_EVALUATION_FEEDBACK_FETCH,
        payload:{}
    };
}

function endEvauationFeedbackFetch(success,error){
    return {
        type:END_EVALUATION_FEEDBACK_FETCH,
        payload:{
            success,error
        }
    };
}


//Thunk to perform the authorizedUser
export  function fetchEvaluationFeedback(evId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/feedback?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startEvauationFeedbackFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endEvauationFeedbackFetch(response,error));
        } catch(e){
            dispatch(endEvauationFeedbackFetch(null,null,{
                message:e.message
            }));
            return;
        }
    };
} 
//------------------------------------------

//-------------------------------------
function startEvauationUserFeedbackFetch(){
    return {
        type:START_EVALUATION_USER_FEEDBACK_FETCH,
        payload:{}
    };
}

function endEvauationUserFeedbackFetch(success,error){
    return {
        type:END_EVALUATION_USER_FEEDBACK_FETCH,
        payload:{
            success,error
        }
    };
}


//Thunk to perform the authorizedUser
export  function fetchEvaluationUserFeedback(evId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/feedback-for-user?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startEvauationUserFeedbackFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endEvauationUserFeedbackFetch(response,error));
        } catch(e){
            dispatch(endEvauationUserFeedbackFetch(null,null,{
                message:e.message
            }));
            return;
        }
    };
} 
//------------------------------------------


export  function showPreFeedbackTakeDialog(){
    return     {
        type:SHOW_EVALUATION_PRE_FEEDBACK_TAKE_DIALOG,
        payload:{}
    };
}


export  function hidePreFeedbackTakeDialog(){
    return     {
        type:HIDE_EVALUATION_PRE_FEEDBACK_TAKE_DIALOG,
        payload:{}
    };
}

//-------------------------------------
function startEvauationPreFeedbackAdd(){
    return {
        type:START_EVALUATION_PRE_FEEDBACK_SUBMIT,
        payload:{}
    };
}

function endEvauationPreFeedbackAdd(success,error){
    return {
        type:END_EVALUATION_PRE_FEEDBACK_SUBMIT,
        payload:{
            success,error
        }
    };
}


//Thunk to perform the authorizedUser
export  function addEvaluationPreFeedback(evId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/prefeedback?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startEvauationPreFeedbackAdd());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endEvauationPreFeedbackAdd(response,error));
        } catch(e){
            dispatch(endEvauationPreFeedbackAdd(null,null,{
                message:e.message
            }));
            return;
        }
    };
}

export  function showFinalizeReqDialog(isShowNextButton=null){
    return     {
        type:SHOW_EVALUATION_FINALIZE_REQ_DIALOG,
        payload:{isShowNextButton}
    };
}

export  function hideFinalizeReqDialog(){
    return     {
        type:HIDE_EVALUATION_FINALIZE_REQ_DIALOG,
        payload:{}
    };
}

export  function showOverallReqDialog(isShowNextButton=null,isFromNextButton=false){
    return     {
        type:SHOW_EVALUATION_OVERALL_REQ_DIALOG,
        payload:{isShowNextButton,isFromNextButton}
    };
}

export  function hideOverallReqDialog(){
    return     {
        type:HIDE_EVALUATION_OVERALL_REQ_DIALOG,
        payload:{}
    };
}
//------------------------------------------

export  function showPreFeedbackAdminDialog(){
    return     {
        type:SHOW_EVALUATION_PRE_FEEDBACK_ADMIN_DIALOG,
        payload:{}
    };
}


export  function hidePreFeedbackAdminDialog(){
    return     {
        type:HIDE_EVALUATION_PRE_FEEDBACK_ADMIN_DIALOG,
        payload:{}
    };
}

export  function showShowOverallUserProgressDialog(){
    return     {
        type:SHOW_OVERALL_USER_WISE_PROGRESS_DIALOG,
        payload:{}
    };
}


export  function hideShowOverallUserProgressDialog(){
    return     {
        type:HIDE_OVERALL_USER_WISE_PROGRESS_DIALOG,
        payload:{}
    };
}

export  function showUserCountDetailsDialog(userDetails){
    return     {
        type:SHOW_USER_COUNT_DEAILS_DIALOG,
        payload:{userDetails}
    };
}


export  function hideUserCountDetailsDialog(){
    return     {
        type:HIDE_USER_COUNT_DEAILS_DIALOG,
        payload:{}
    };
}

