import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, FormControl, MenuItem, Select, Button, CircularProgress } from '@material-ui/core';
import { createStyles, withStyles } from "@material-ui/core/styles"
import Summary from "../Summary/index";
import ProgressSummary from "../ProgressSummary/index";
import Logo from "assets/images/ExpentLogo.png";

import {
  downloadxlsReport,
  downloadPDFReport
} from "redux/evaluation/reportdata/action"

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  downloadxlsProg: state.evaluationReportData.downloadxlsProg,
  downloadxlsError: state.evaluationReportData.downloadxlsError,
  downloadxlsData: state.evaluationReportData.downloadxlsData,

  downloadPDFProgress: state.evaluationReportData.downloadPDFProgress,
  downloadPDFData: state.evaluationReportData.downloadPDFData,
  downloadPDFError: state.evaluationReportData.downloadPDFError,
  summaryViewId: state.evaludationCriteria.summaryViewId,
  summaryViewProducts: state.evaludationCriteria.summaryViewProducts,
  summaryViewCriterias: state.evaludationCriteria.summaryViewCriterias,
  report: state.evaluationReportData.report,
});

const connectionActions = {
  downloadxlsReport: downloadxlsReport,
  downloadPDFReport: downloadPDFReport
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 4),
    },
    filterTitle: {
      marginBottom: theme.spacing(2),
      color: '#282D30',
      fontSize: theme.spacing(2.2)
    },
    formControl: {
      '& > div': {
        color: '#282D30',
        fontSize: theme.spacing(2.2),
      },
    },
    dwnldbtn: {
      color: '#282D30',
      fontSize: theme.spacing(2.2),
      marginLeft: 10
    },
    logoImage: {
      height: '40px',
    },
    topBar: {
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      background: '#fff'
    },
    reportRoot: {
      background: '#f7f7f7',
    },
    evName: {
      flex: 1,
      textAlign: 'right',
    },
    ename: {
      fontSize: 24,
      fontWeight: 500
    },
    showTopBarExpent: {
      display: 'none'
    }
  });

function removeSpecialCharactors(inputString) {
  // Remove special characters using regular expression
  let cleanString = inputString.replace(/[^\w\s]/gi, '');

  // Replace spaces with underscores
  cleanString = cleanString.replace(/\s+/g, '_');

  return cleanString;
}

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      isDownloading: false,
      isDownloadingPDF: false
    };
    this.divRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.downloadxlsProg && prevProps.downloadxlsProg) {
      if (this.props.downloadxlsData !== null && this.props.downloadxlsData?.ReportUrl) {
        const link = document.createElement('a');
        link.href = this.props.downloadxlsData.ReportUrl;
        link.setAttribute('download', "report.xlsx");
        this.divRef.current.appendChild(link)
        link.click();
        link.parentNode.removeChild(link);
      }
      this.setState({ isDownloading: false })
    }

    if (!this.props.downloadPDFProgress && prevProps.downloadPDFProgress) {
      if (this.props.downloadPDFData !== null && this.props.downloadPDFData?.path) {
        const link = document.createElement('a');
        link.href = this.props.downloadPDFData.path;
        link.setAttribute('download', "report.xlsx");
        this.divRef.current.appendChild(link)
        link.click();
        link.parentNode.removeChild(link);
      }
      this.setState({ isDownloadingPDF: false })
    }

  }

  handleClickDownloadReport = (e, fileName = "report") => {
    var quotes = document.getElementById('report');
    var button = document.getElementById("pdfHideButton");
    if (button != undefined && button != null) {
      button.style.display = "none";
    }
    let type = this.state.value === 0 ? ' Full Report' : ' Executive Summary'
    fileName += type;
    this.setState({
      isDownloading: true
    }, () => {
      html2canvas(quotes, {
        scale: 1.5,
        onclone: function (clonedDoc) {
          clonedDoc.getElementById('showTopBarExpent').style.display = 'block';
        }
      })
        .then((canvas) => {
          var imgData = canvas.toDataURL('image/png');
          var imgWidth = 210;
          var pageHeight = 295;
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;

          var doc = new jsPDF('p', 'mm', 'a4');
          var position = 0;

          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save(`${fileName.toLowerCase().split(" ").join("-")}.pdf`);
        }).then(() => {
          this.setState({ isDownloading: false });
          if (button != undefined && button != null) {
            button.style.display = "block";
          }
        })
    })
  }

  downloadPDFReport() {
    const { summaryViewProducts, summaryViewCriterias, evaluation } = this.props;
    console.log(summaryViewProducts)
    console.log(summaryViewCriterias)

    var productIds = [];
    var criteriaIds = [];
    if (summaryViewProducts !== null) {
      summaryViewProducts.forEach(function (prd) {
        if (prd.checked) {
          productIds.push(prd.value)
        }
      })
    }

    if (summaryViewCriterias !== null) {
      summaryViewCriterias.forEach(function (prd) {
        criteriaIds.push(prd.value)
      })
    }

    // const authToken = window.localStorage.getItem("authToken");
    // var site = '';
    // if (process.env.REACT_APP_ENV === 'dev') {
    //   site = "https://dev.expent.ai"
    // } else if (process.env.REACT_APP_ENV === 'test') {
    //   site = "https://demo.expent.ai"
    // } else {
    //   site = "https://app.expent.ai"
    // }

    let type = this.state.value === 0 ? 'Full Report' : ' Executive Summary'
    // var url = site + "/evaluation/" + this.props.match.params.evaluation_id + "/report?tab=" + this.props.summaryViewId + "&workflowId=" + this.props.match.params.workflow_id + "&componentId=" + this.props.match.params.component_id + "&token=" + authToken;
    var url = "/evaluation/" + this.props.match.params.evaluation_id + "/report?tab=" + this.props.summaryViewId + "&workflowId=" + this.props.match.params.workflow_id + "&componentId=" + this.props.match.params.component_id;

    if (productIds.length > 0) {
      url += '&products=' + productIds.join(',');
    }
    if (criteriaIds.length > 0) {
      url += '&criterias=' + criteriaIds.join(',');
    }

    var body = document.body, html = document.documentElement;

    // var width = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
    // var widthInches = width / 96;
    let totalProductWidth = productIds.length * 600;
    var widthInches = (800 + totalProductWidth + 500) / 96;

    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    var heightInches = height / 96;

    var data = {
      "filename": removeSpecialCharactors(evaluation?.Name) + " - " + type,
      "url": url,
      "filepath": "demo",
      "timeout": 8,
      "pdfSize": [widthInches, heightInches]
    }

    this.setState({
      isDownloadingPDF: true
    }, () => {
      this.props.downloadPDFReport(data);
    })
  }

  render() {
    const { classes, evaluation } = this.props

    return (
      <>
        <Grid container className={classes.root} justifyContent="space-between" alignItems="flex-end">
          <div>
            <Typography variant={"h4"} className={classes.filterTitle}>Filters</Typography>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <Select value={this.state.value} onChange={(e) => {
                this.setState({ value: e.target.value });
              }}>
                <MenuItem value={0} style={{ fontSize: 13 }}>Full Report</MenuItem>
                <MenuItem value={1} style={{ fontSize: 13 }}>Executive Summary</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            {this.state.isDownloading && <CircularProgress />}

            {!this.state.isDownloading && this.state.value === 1 && (
              <Button
                disabled={this.state.value === 0 ? true : false}
                className={classes.dwnldbtn}
                variant="outlined" onClick={(e) => {
                  this.handleClickDownloadReport(e, evaluation?.Name);
                }}>
                Download pdf
              </Button>
            )}

            {this.state.value === 0 && <Button
              disabled={false}
              // disabled={process.env.REACT_APP_ENV !== 'dev' ? true : false}
              className={classes.dwnldbtn}
              variant="outlined" onClick={(e) => {
                this.downloadPDFReport(e, evaluation?.Name);
              }}>
              {this.state.isDownloadingPDF ? <CircularProgress style={{ width: 25, height: 25 }} /> : 'Download pdf'}
            </Button>
            }

            {this.state.value === 0 && !this.state.isDownloading && (
              <Button
                // disabled={this.state.value === 1 ? true : false}
                className={classes.dwnldbtn} variant="outlined" onClick={() => {
                  this.setState({ isDownloading: true })
                  this.props.downloadxlsReport(this.props.match.params.evaluation_id)
                }}>
                Download xlsx
              </Button>
            )}

          </div>
        </Grid>
        <div id="report">
          {this.state.value === 0 && (
            <>
              <ProgressSummary
                isReport={true}
              />
              <Summary isReport={true} onlyShowFeedBack />
            </>
          )}
          {this.state.value === 1 && <>
            {<div id='showTopBarExpent' className={classes.showTopBarExpent}>
              <div className={classes.topBar}>
                <img src={Logo} alt="logo" className={classes.logoImage} />
                <div className={classes.evName}>
                  <Typography className={classes.ename}>{evaluation?.Name}</Typography>
                </div>
              </div>
            </div>}
            <Summary isReport={true} />
          </>
          }
        </div>
        <div ref={this.divRef} id="downloadlink"></div>
      </>
    );
  }
}


export default connector(compose(
  withRouter,
  withStyles(styles)
)(Report));
