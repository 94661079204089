import {
  Button, Grid, IconButton, InputAdornment, Chip, OutlinedInput, TextField,
  FormControlLabel, Radio, RadioGroup, Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, Card, CardHeader, Avatar, CardContent
} from "@material-ui/core";
import * as Validator from "util/Validation";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import Select from 'react-select';
import deleteIcon from "assets/images/delete.svg";
import axios from 'axios';
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import PhoneInput from 'react-phone-input-2'
import AddEvaluationImage from "assets/images/evaluation/add-evaluation.svg";
import DoneIcon from "@material-ui/icons/Done";
import { createStyles, withStyles } from '@material-ui/styles';
import CalendarImage from "assets/images/calendar.svg";
import CommonCss from 'commonCss';
import Image from 'Components/Common/image.jsx';
import { SlateReadonlyField } from "Components/Common/SlateEditor/ReadOnlySlate.jsx";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { showSnackBar } from "redux/snackbar/action";
import AddFileDialog from "./AddFileDialog"
import AddUserDialog from "./AddUserDialog";
const connectedProps = (state) => ({
  userType: state.authUser.user?.Role,
  updateOrgCategoryTabSectionItemProgress: state.orgCategory.updateOrgCategoryTabSectionItemProgress,
});

const connectionActions = {
  showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    marginBottom: 15,
    '& [class*=product_container]': {
      marginTop: 0,
    },
    "& [class*=edit-btn]": {
      visibility: 'hidden',
    },
    "&:hover [class*=edit-btn]": {
      visibility: 'visible',
    }
  },
  ...CommonCss.ProductDetails,
  formControlDialog: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
      width: '100%'
    },
    padding: '.375rem',
  },
  formControl2: {
    ...CommonCss.formControl,
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 80
  },
  formGroupDialog: {
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  formControl: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  mb16: {
    marginBottom: 16,
  },
  editIcon: {
    color: "#4A87F8",
    verticalAlign: "middle",
    cursor: "pointer",
    fontSize: 16
  },
  subSection: {},
  productList: {
    position: 'relative',
    '& [class*=LayeredCard-root]': {
      marginTop: 0,
    },
  },
  deleteImage: {
    position: "absolute",
    top: 10,
    zIndex: 20,
    right: 10,
    padding: 4,
    cursor: "pointer",
  },
  saveBtn: {
    color: "#fff",
    padding: "6px 26px",
    backgroundColor: "#4b86f8",
    "&:hover": {
      backgroundColor: "#4b86f8",
    },
  },
  datePicker: {
    "& .react-datepicker-wrapper": {
      width: '100%',
    },
    "& .react-datepicker__input-container > div": {
      display: 'flex',
      marginBottom: 0,
      alignItems: 'center',
      paddingLeft: '8px !important',
      "& [class*=MuiInputBase-root]": {
        maxHeight: 33,
        minHeight: 33,
        width: 'inherit',
      },
      "& input": {
        fontSize: theme.spacing(1.8),
        padding: '14px 5px 14px 2px !important',
        textAlign: "center"
      },
      "& [class*=MuiFormLabel-root]": {
        marginBottom: 0,
        marginRight: 29
      }
    },
    "[class*=MuiMenuItem-root]": {
      color: "#707070",
      fontSize: theme.spacing(1.8)
    },
  },
  tickMark: {
    color: "#4b86f8",
    marginLeft: 10,
    border: "1px solid #4b86f8",
    padding: 7,
    minWidth: "50px !important",
    "&:hover": {
      color: "#4b86f8",
    },
  },
  chipContainer: {
    padding: theme.spacing(1, 0),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(0.5),
    },
  },
  contactCardHeader: {
    flexDirection: "column",
    padding: "16px 4px !important",
    "& [class*='MuiCardHeader-avatar-']": {
      margin: "0px",
      marginBottom: 12,
    },
    "& [class*='MuiCardHeader-content']": {
      width: "100%",
    },
    "& [class*=MuiTypography-displayBlock]": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      margin: "0px -19px 0px 0px",
    },
  },
  customTypeFields: {
    "& [class*=form-control]": {
      paddingTop: 12,
      paddingRight: 12,
      paddingBottom: 12
    }
  },
  contactCardRoot: {
    cursor: "pointer",
  },
  addNewChamp: {
    display: "block",
    textAlign: "center",
    color: "#bdbdbd",
    "& span": {
      display: "block",
      cursor: "pointer",
      color: "#000000",
    },
  },
  deleteIcon: {
    position: "absolute",
    right: 16,
    top: 13,
    cursor: "pointer",
  },
  cardSection: {
    position: "relative",
    width: 'inherit'
  },
  contractHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  layeredCardHead: {
    marginBottom: 0
  },
  linkText: {
    position: 'relative',
    cursor: 'pointer',
    width: "100%",
    height: '100%'
  },
  resourceLink: {
    display: 'flex',
    alignItems: 'center',
  },
  resourceLinkText: {
    color: '#4b86f8 !important',
    cursor: 'pointer',
    padding: 10
  },
  tableCell: {
    width: "60%",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100vw - 99vw)'
  },
  tabledeleteCell: {
    width: "10%"
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 35,
    padding: 0,
    marginLeft: 15,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  paper: {
    boxShadow: 'none',
    border: '1px solid #ddd'
  },
  resourceHead: {
    padding: 10
  },
  uploadImage: {
    overflow: 'hidden',
    width: 0,
    position: 'absolute',
    top: 10,
    left: 0,
    opacity: 0,
  },
  editIcon1: {
    color: "#4A87F8",
    verticalAlign: "middle",
    marginLeft: 6,
    marginBottom: 5,
    cursor: "pointer",
  },
  itemHead: {
    color: '#6C6C6C',
    fontSize: 15,
    opacity: 0.9,
  },
  data: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 0
  },
  productLink: {
    ...CommonCss.ProductDetails.productLink,
    fontSize: 14,
    marginBottom: 0,
  },
})

class CustomItem extends Component {
  constructor(props) {
    super()
    this.state = {
      data: null,
      additionalData: [],
      openAddContactDialog: false,
      openAddFileDialog: false,
      uploadingFile: false,
      edit: false,
      selectedOption: null,
      error: "",
      focusedId: null,
      showEdit: false,
      fileLink: "",
      fileName: "",
    }
  }

  componentDidMount() {
    this.initData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.answer !== prevProps.answer) {
      this.initData()
    }

    if (!this.props.updateOrgCategoryTabSectionItemProgress && prevProps.updateOrgCategoryTabSectionItemProgress
    ) {
      this.props.showSnackBar("Updated sub sectioon successfully.", "success", 3000)
    }
  }

  initData = () => {
    let data = ""
    if ([4, 5, 7, 8, 9].includes(this.props.type)) {
      data = []
    }
    let additionalData = []
    try {
      if ([4, 5, 7, 8, 9].includes(this.props.type)) {
        if(Validator.isJson(this.props.answer)){
          data = JSON.parse(this.props.answer)
        }
      } else {
        data = this.props.answer
      }
    } catch (e) { }
    try {
      additionalData = JSON.parse(this.props.additionalData)
    } catch (e) { }
    this.setState({ data, additionalData })
  }

  handleSave = () => {
    this.props.updateSubSection(
      this.props.categoryID,
      this.props.tabUID,
      this.props.sectionUID,
      this.props.itemUID,
      {
        name: this.props.title,
        input_type: this.props.type,
        answer: JSON.stringify(this.state.data)
      }
    )
  }

  handleOnChangeTextField = (value, type, isUpdate) => {
    let error = ""
    if (type === 12) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(value).toLowerCase())) {
        error = 'Please enter valid email address.'
      }
    }

    if (type === 13) {
      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (!value.match(phoneno)) {
        error = 'Please enter valid phone number.'
      }
    }

    if (type === 14) {
      var url = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
      if (!url.test(value)) {
        error = 'Please enter valid url.'
      }
    }
    this.setState({ data: value, error }, () => {
      if (isUpdate) {
        this.setState({ edit: false })
        this.handleSave()
      }
    })
  }

  getPlaceHolder(type) {
    let placeholder = 'Enter Description'
    if (type === 12) placeholder = 'Enter Email'
    else if (type === 13) placeholder = 'Enter Phone'
    else if (type === 14) placeholder = 'Enter Url'
    return placeholder
  }

  binaryValue(addData, val) {
    if (val && addData && Array.isArray(addData) && addData.length > 0) {
      return addData.filter(o => o.value === val)[0].label
    } else {
      return ''
    }
  }

  onUploadFileChange = async event => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var formData = new FormData();
      formData.append("folder", `/category/${this.props.match?.params?.id}/file/subsection/${this.props.itemUID}`);
      const timestamp = (new Date()).getTime();
      formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf('.') + 1)}`);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;
      this.setState({ uploadingFile: true })
      axios.post(url, formData, {
        headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        this.setState({ openAddFileDialog: true, fileLink: response?.data?.filepath, fileName: file.name })
      }).catch(err => {
        this.setState({ uploadingFile: false })
        if (err.response) {
          this.props.showSnackBar(err.response.data.error, "error", 3000);
        } else if (err.request) {
          this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
        } else {
          this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
        }
      })
      this.setState({ uploadingFile: false })
    }
  }

  render() {
    const { classes, title, type, isAdmin, index } = this.props;
    const { data, edit, error, additionalData } = this.state;
    return (
      <div className={classes.root} key={this.props.key}>
        <div className={classes.itemHead}>
          {title}
        </div>
        {[1, 14, 12, 15].includes(type) && (
          <Grid container>
            {edit && (
              <Grid item xl={10}>
                <Grid container alignItems='center'>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    className={classes.formControlDialog}
                    placeholder={this.getPlaceHolder(type)}
                    onChange={e => this.handleOnChangeTextField(e.target.value, type)}
                    value={(data !== null && data) || ""}
                  />
                </Grid>
                <label style={{ textAlign: 'center', lineHeight: 0 }}>{error}</label>
              </Grid>
            )}
            {edit && (
              <Grid item>
                <ActionButtom
                  classes={classes}
                  isDisabled={error.length > 0}
                  onCancel={() => {
                    let data = ""
                    try {
                      data = JSON.parse(this.props.answer)
                    } catch (e) {

                    }
                    this.setState({ edit: false, data, error: "" })
                  }}
                  onSave={() => {
                    if (error.length === 0) {
                      this.handleSave()
                      this.setState({ edit: false })
                    }
                  }}
                />
              </Grid>
            )}
            {!edit && (
              <div>
                <p className={classes.data}>
                  {data !== null ? data : ''}
                </p>
              </div>
            )}
            {isAdmin && !edit && (
              <IconButton
                style={{ width: 22, height: 22 }}
                onClick={() => {
                  this.setState({
                    edit: true
                  });
                }}
                className="edit-btn"
              >
                <EditIcon
                  fontSize={"small"}
                  className={classes.editIcon}
                />
              </IconButton>
            )}
          </Grid>
        )}
        {type === 13 &&
          <Grid container>
            {edit && (
              <Grid item xl={10} className={classes.customTypeFields}>
                <PhoneInput
                  country={'us'}
                  value={data}
                  onChange={(value) => {
                    this.handleOnChangeTextField(value, true);
                  }}
                  required
                  inputProps={{
                    name: data,
                    autoFocus: false,
                    id: "outlined-full-width",
                    label: "",
                    style: { width: '100%' },
                    fullWidth: true,
                    margin: "normal",
                    variant: "outlined",
                    InputLabelProps: {
                      shrink: true
                    }
                  }}
                />
              </Grid>
            )}
            {edit && (
              <Grid item>
                <ActionButtom
                  classes={classes}
                  onCancel={() => {
                    let data = ""
                    try {
                      data = JSON.parse(this.props.answer)
                    } catch (e) {

                    }
                    this.setState({ edit: false, data, error: "" })
                  }}
                  onSave={() => {
                    if (error.length === 0) {
                      this.handleSave()
                      this.setState({ edit: false })
                    }
                  }}
                />
              </Grid>
            )}
            {!edit && (
              <div>
                <p className={classes.data}>
                  {data !== null ? data : ''}
                </p>
              </div>
            )}
            {isAdmin && !edit && (
              <IconButton
                style={{ width: 22, height: 22 }}
                onClick={() => {
                  this.setState({
                    edit: true
                  });
                }}
                className="edit-btn"
              >
                <EditIcon
                  fontSize={"small"}
                  className={classes.editIcon}
                />
              </IconButton>
            )}
          </Grid>
        }
        {type === 2 && (
          <Grid container alignItems="center" className={classes.subSection}>
            {edit && (
              <div className={classes.datePicker}>
                <DatePicker
                  readOnly={false}
                  selected={data && data !== null && data.length > 0 ? new Date(data) : new Date()}
                  onChange={date => this.setState({ data: date })}
                  dateFormat="MMM do yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={
                    <OutlinedInput
                      value={data}
                      readOnlyInput={true}
                      placeholder="Select Date"
                      labelStyle={"inline"}
                      startAdornment={
                        <InputAdornment>
                          <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                        </InputAdornment>
                      }
                    />
                  }
                />
              </div>
            )}
            {edit && (
              <ActionButtom
                classes={classes}
                onCancel={() => {
                  if(Validator.isJson(this.props.answer)){
                    this.setState({ data: JSON.parse(this.props.answer), edit: false })
                  }
                }}
                onSave={() => {
                  this.handleSave()
                  this.setState({ edit: false })
                }}
              />
            )}
            {!edit && (
              <div>
                <p className={classes.data}>{moment((data !== null && data) || new Date()).format("DD MMM YYYY")}</p>
              </div>
            )}
            {isAdmin && !edit && (
              <IconButton
                onClick={() => {
                  this.setState({
                    edit: true
                  });
                }}
                style={{ width: 22, height: 22 }}
                className="edit-btn"
              >
                <EditIcon
                  fontSize={"small"}
                  className={classes.editIcon}
                />
              </IconButton>
            )}
          </Grid>
        )}
        {type === 11 && (
          <Grid container alignItems="center">
            {edit && (
              <>
                <Grid item xs={12}>
                  <SlateInputField
                    placeholder="Enter Description"
                    as={SlateInputField}
                    onChangeEvent={value => this.setState({ data: value })}
                    initValue={(data !== null && data) || ""}
                    textContainerStyle={{ color: "#707070" }}
                    isFile={false}
                  />
                </Grid>
                <Grid container justify="flex-end">
                  <ActionButtom
                    classes={classes}
                    onCancel={() => {
                      if(Validator.isJson(this.props.answer)){
                        this.setState({ data: JSON.parse(this.props.answer), edit: false })
                      }
                    }}
                    onSave={() => {
                      this.handleSave()
                      this.setState({ edit: false })
                    }}
                  />
                </Grid>
              </>
            )}
            {!edit && (
              <SlateReadonlyField initValue={(data !== null && data) || ""} />
            )}
            {isAdmin && !edit && (
              <IconButton
                onClick={() => {
                  this.setState({
                    edit: true
                  });
                }}
                style={{ width: 22, height: 22 }}
                className="edit-btn"
              >
                <EditIcon
                  fontSize={"small"}
                  className={classes.editIcon}
                />
              </IconButton>
            )}
          </Grid>
        )}
        {type === 3 && (
          <>
            {edit && (
              <RadioGroup
                aria-label="binary"
                name="binary"
                value={data}
                onChange={(event) => this.handleOnChangeTextField(event.target.value, true, true)}
              >
                <Grid container alignItems="center" spacing={2}>
                  {additionalData && additionalData.map(item => (
                    <Grid item>
                      <FormControlLabel value={item.value} control={<Radio />} label={item.label} />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            )}
            {!edit && (
              <Grid container alignItems="center">
                <p className={classes.data}>{this.binaryValue(additionalData, data)}</p>
                {isAdmin && (
                  <IconButton
                    onClick={() => {
                      this.setState({
                        edit: true
                      });
                    }}
                    style={{ width: 22, height: 22 }}
                    className="edit-btn"
                  >
                    <EditIcon
                      fontSize={"small"}
                      className={classes.editIcon}
                    />
                  </IconButton>
                )}
              </Grid>
            )}
          </>
        )}
        {(type === 4 || type === 5) && (
          <>
            <Grid container alignItems="center" spacing={1}>
              {data && Array.isArray(data) && data.length > 0 && data.map((o, index) => (
                <Grid item>
                  <Chip
                    label={o.label}
                    color="primary"
                    variant="outlined"
                  />
                </Grid>
              ))}
              {!edit && isAdmin && (
                <Grid item>
                  <IconButton
                    onClick={() => {
                      this.setState({
                        edit: true
                      });
                    }}
                    style={{ width: 22, height: 22 }}
                    className="edit-btn"
                  >
                    <EditIcon
                      fontSize={"small"}
                      className={classes.editIcon}
                    />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            {edit && isAdmin && (
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Select
                    isMulti={type === 5}
                    value={type === 5 ? data : (data && data[0]) || ""}
                    name="select"
                    options={additionalData}
                    className="basic-select"
                    classNamePrefix="select"
                    onChange={e => this.setState({ data: type === 5 ? e : [e] })}
                  />
                </Grid>
                <Grid item>
                  <ActionButtom
                    classes={classes}
                    onCancel={() => {
                      if(Validator.isJson(this.props.answer)){
                        this.setState({ data: JSON.parse(this.props.answer), edit: false })
                      }
                    }}
                    onSave={() => {
                      this.handleSave()
                      this.setState({ edit: false })
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
        {(type === 7 || type === 8) && (
          <>
            <Grid container spacing={2}>
              {data !== null && data.length > 0 &&
                data.map((user, userIndex) => (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={2}
                    key={userIndex}
                    alignItems="center"
                    className={classes.cardSection}
                  >
                    <Image
                      src={deleteIcon}
                      className={classes.deleteImage}
                      style={{ top: 15 }}
                      onClick={() => {
                        this.setState({ data: data.filter((o, i) => i !== userIndex) }, () => {
                          this.handleSave()
                        })
                      }}
                    />
                    <Card variant="outlined" style={{ height: '100%' }}>
                      <CardHeader
                        className={classes.contactCardHeader}
                        style={{ textAlign: "center" }}
                        avatar={
                          <Avatar
                            aria-label="recipe"
                            className={classes.contactCardAvatar}
                          >
                            {user?.name?.slice(0, 1)}
                          </Avatar>
                        }
                        title={user?.name}
                        subheader={user?.email}
                      />
                    </Card>
                  </Grid>
                ))}
              <Grid
                item xs={12}
                sm={4}
                md={3}
                lg={2}
              >
                <Card
                  className={classes.contactCardRoot}
                  variant="outlined"
                  onClick={() => {
                    this.setState({
                      openAddContactDialog: true,
                    })
                  }}
                >
                  <CardContent>
                    <span className={classes.addNewChamp}>
                      <Image
                        src={AddEvaluationImage}
                        style={{ maxWidth: "58px", cursor: "pointer" }}
                      />
                      <span className={classes.addContact}>
                        {type === 7 ? 'Add User' : 'Add Group'}
                      </span>
                    </span>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {[7, 8].includes(type) && (
              <AddUserDialog
                isOpen={this.state.openAddContactDialog}
                type={type}
                onSubmit={(newData) => {
                  this.setState({ openAddContactDialog: false, data: [...data, newData] }, () => {
                    this.handleSave()
                  })
                  this.setState({ openAddContactDialog: false })
                }}
                onClose={() => this.setState({ openAddContactDialog: false })}
              />
            )}
          </>
        )}
        {type === 9 && (
          <>
            {isAdmin && (
              <Grid container alignItems="center" justify="flex-end">
                <Button className={classes.newUserBtn} disabled={this.state.uploadingFile} onClick={() => this.setState({ openAddFileDialog: true, flieLink: "", fileName: "" })}>
                  + Add Link
                </Button>
                &nbsp;
                <Button className={classes.newUserBtn} disabled={this.state.uploadingFile}>
                  <label htmlFor={`UploadContractFileFile${index}`} className={classes.linkText}>
                    + Add File
                    <input id={`UploadContractFileFile${index}`} className={classes.uploadImage} onChange={this.onUploadFileChange} type="file" />
                  </label>
                </Button>
              </Grid>
            )}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="40%">Name</TableCell>
                  <TableCell>Link</TableCell>
                  {isAdmin && (
                    <TableCell width="5%"></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data && Array.isArray(data) && data.length > 0 && data.map((option, index) => (
                  <TableRow key={index}>
                    <TableCell>{option.name}</TableCell>
                    <TableCell style={{ wordBreak: 'break-all' }}>
                      <a href={option.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>View File</a>
                    </TableCell>
                    {isAdmin && (
                      <TableCell width="5%">
                        <IconButton
                          onClick={() => {
                            this.setState({ data: data.filter((o, i) => i !== index) }, () => {
                              this.handleSave()
                            });
                          }}
                        >
                          <Image
                            src={deleteIcon}
                            className={classes.deleteImage}
                          />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <AddFileDialog
              isOpen={this.state.openAddFileDialog}
              onSubmit={(newData) => {
                this.setState({ openAddFileDialog: false, fileLink: "", fileName: "", data: [...data, newData] }, () => {
                  this.handleSave()
                })
              }}
              onClose={() => this.setState({ openAddFileDialog: false, fileLink: "", fileName: "" })}
              fileName={this.state.fileName}
              fileLink={this.state.fileLink}
            />
          </>
        )}
      </div>
    )
  }
}

export default connector(
  compose(withRouter, withStyles(styles))(CustomItem)
);


const ActionButtom = ({ classes, onSave, onCancel, isDisabled = false }) => (
  <div>
    <Button
      className={classes.tickMark}
      onClick={() => isDisabled ? {} : onSave()}
    >
      <DoneIcon />
    </Button>
    <Button
      className={classes.tickMark}
      onClick={onCancel}
    >
      <CloseIcon />
    </Button>
  </div>
)
