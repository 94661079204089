import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, Slide, Typography, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import CommonCss from "commonCss";
import { withRouter } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import Image from 'Components/Common/image';

import { CommonFn } from "services/commonFn";
import ReactSelect from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
import * as Environment from "util/Environment";
import axios from "axios";
const SelectAsyncPaginate = withAsyncPaginate(ReactSelect);

// env
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({

});

const connectionActions = {

};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    header: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: " center space-between",
      alignItems: "center",
    },
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginBottom: theme.spacing(2.5),
      display: 'flex',
      alignItems: 'center',
      gap: 15
    },
    mainForm: {
      border: "1px solid #F2F2F2",
      padding: 15,
      margin: '20px 0px',
    },
    subTitle: {
      color: "#282D30",
      fontSize: 16,
    },
    formControl: CommonCss.formControl,
    label: {
      ...CommonCss.label,
      marginRight: 20
    },
    formGroup: CommonCss.formGroup,
    rightSection: {
      flex: 1,
      marginLeft: 60,
      marginRight: 30,
    },
    buttonSection: {
      textAlign: "center",
      margin: 20,
    },
    button: {},
    arrow: {
      paddingLeft: 5,
    },
    mandatory: CommonCss.mandatory,
    close: {
      position: "absolute",
      right: 15,
      top: 15,
      cursor: "pointer",
      color: "#6F6F6F",
      "& svg": {
        stroke: "white",
        fontSize: "24px",
      },
    },
    divider: {
      color: "#4b86ff",
    },
    inputField: {
      color: '#5F5F5F',
      padding: '0.5em 0.5rem',
      fontSize: '1.1em',
      borderRadius: '5px',
      width: '100%',
      "&:focus": {
        outline: 'none'
      }
    },
  });

class AttachRequestDialog extends React.Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      error: "",
      requestId: '',
      requestIdError: '',
    };
    this.submitForm = this.submitForm.bind(this)
    this.getOptions = this.getOptions.bind(this)
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  clearError() {
    this.setState({
      requestIdError: '',
    })
  }

  submitForm() {
    this.clearError();
    const { requestId } = this.state;
    if (requestId === null || requestId === '' || requestId.length === 0) {
      this.setState({
        requestIdError: 'Please select project'
      })
      return;
    }
    // console.log(requestId,'requestId')
    this.props.onSubmit(requestId.value)
  }

  getOptions = async (inputValue, loadedOptions, additional) => {
    // console.log(this.props.selectedRenewalProduct,'selectedRenewalProduct')
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = new Date().getTime();
    // if (!inputValue) {
    //   return {
    //     options: [],
    //     hasMore: false,
    //     additional: {
    //       page: 0,
    //     },
    //   };
    // }
    let date = new Date();
    let formData = {
      from_date: new Date(date.setDate(date.getDate() - 100)).toISOString(),
      to_date: new Date().toISOString(),
      query: inputValue,
      page: 0,
      pageSize: 10,
      status: [2, 3, 4],
      type: [1, 2, 4, 10],
      product_ids: [],
      entity_ids: [],
      sortBy: "peo.created_at",
      sortOrder: "desc",
      allEvaluations: false
    };

    // if (this.props.selectedRenewalProduct.type === 'PRODUCT') {
    //   formData.product_ids = this.props.selectedRenewalProduct ? [this.props.selectedRenewalProduct?.id] : []
    // } else {
    //   formData.entity_ids = this.props.selectedRenewalProduct ? [this.props.selectedRenewalProduct?.id] : []
    // }

    let url = `${api_server}/list?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    let requests = [];
    if (response?.data?.Data) {
      requests = response?.data?.Data;
    }
    let total = response?.data?.total || 0;
    requests = requests.map((m) => {
      m.value = parseInt(m.ID);
      m.label = parseInt(m.ID) + '-' + m.Name;
      return m;
    });
    return {
      options: requests,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  }

  ProductLabels = (props) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
        <div>
          <span style={{ color: "#000", fontSize: "16px" }}>{props.label}</span>&nbsp;
          {props.isProduct !== undefined && props.isProduct && <span style={{ color: "#6C6C6C", fontSize: "12px" }}>By {props.company_name}</span>}
        </div>
      </div>
    );
  };

  render() {
    const classes = this.props.classes;
    const { requestIdError } = this.state;

    // console.log(this.props.selectedRenewalProduct,'selectedRenewalProduct')
    // console.log(this.props.selectedRenewalContract,'selectedRenewalContract')
    return (
      <Dialog
        onClose={this.props.hideDialog}
        aria-labelledby="add-upload-dialog"
        open={true}
        TransitionComponent={Transition}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={"sm"}
        scroll={"body"}
        id="attachRequestDialog"
        PaperProps={{ style: { overflowY: "visible" } }}
      >
        <DialogContent classes={{ root: classes.dialogContent }} style={{ overflowY: "visible" }}>
          <div className={classes.close} onClick={this.props.hideDialog}><CloseIcon /></div>
          <Typography variant={"h6"} className={classes.title}>
            Link Project for: <Image src={this.props.selectedRenewalProduct?.productLogo} alt={this.props.selectedRenewalProduct?.name} title={this.props.selectedRenewalProduct?.name} style={{ display: 'block', width: 35 }} />
            {this.props.selectedRenewalProduct?.name}
          </Typography>
          <div className={classes.alert}>
            {this.state.error.length > 0 && (
              <Alert variant="filled" severity="error">
                {this.state.error}
              </Alert>
            )}
          </div>

          <div className={classes.mainForm}>
            <div className={classes.formGroup}>
              <label className={classes.label}>
                Project ID <span className={classes.mandatory}>*</span>
              </label>
              {/* <input className={classes.inputField} value={this.state.text} onChange={(e) => {
                this.setState({
                  requestId: e.target.value,
                })
              }}
                placeholder="Enter Project ID"
                autoFocus /> */}

              {<SelectAsyncPaginate
                isClearable
                formatCreateLabel={(userInput) => `Create '${userInput}'`}
                noOptionsMessage={() => "Start typing to select project"}
                isSearchable={true}
                createOptionPosition={"first"}
                classNamePrefix="select"
                placeholder="Select Project"
                onChange={(data) => {
                  this.setState({
                    requestId: data
                  }, () => {
                    this.clearError()
                  })
                }}
                additional={{
                  page: 0,
                }}
                isValidNewOption={(input) => input.length > 0}
                value={this.state.requestId}
                loadOptions={this.getOptions}
              />}

              {/* <FormControl variant="outlined" className={classes.formControl} size="small">
                  <SelectAsyncPaginate
                    isSearchable={true}
                    isClearable
                    classNamePrefix="select"
                    placeholder="Name of the vendor"
                    onChange={(e) => {
                      if (e?.value) {
                        this.setState({
                          requestId: e?.value,
                        }, () => {
                          this.clearError()
                        });
                      }
                    }}
                    additional={{
                      page: 0,
                    }}
                    formatOptionLabel={this.ProductLabels}
                    isValidNewOption={(input) => input.length > 0}
                    loadOptions={this.getOptions}
                  />
                </FormControl> */}

              {requestIdError.length > 0 && <span className={classes.mandatory}>{requestIdError}</span>}
            </div>

            <div className={classes.buttonSection}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={this.props.hideDialog}>
                Cancel
              </Button>
              &nbsp; &nbsp;
              <Button
                variant="contained"
                color="secondary"
                onClick={() => { this.submitForm() }}
                className={classes.button}>
                <span style={{ display: 'flex' }}>Next  <ArrowForwardIcon className={classes.arrow} /></span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(AttachRequestDialog));
