import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
    TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, FormControl, MenuItem, CircularProgress, TablePagination
} from '@material-ui/core';

import { Select as MaterialSelect } from '@material-ui/core';

import { updateAutomation, fetchAutomationJobs } from 'redux/automations/action';
import { CommonFn } from "services/commonFn";

const connectedProps = (state) => ({
    updateAutomationProgress: state.automation.updateAutomationProgress,
    updateAutomationError: state.automation.updateAutomationError,
    updatedAutomationData: state.automation.updatedAutomationData,

    fetchAutomationJobsProgress: state.automation.fetchAutomationJobsProgress,
    fetchAutomationJobsError: state.automation.fetchAutomationJobsError,
    automationJobs: state.automation.automationJobs,


});

const connectionActions = {
    updateAutomation: updateAutomation,
    fetchAutomationJobs: fetchAutomationJobs
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    jobRoot: {
        marginTop: 15
    },
    topFilter: {
        marginBottom: 15
    },
    autoSelectInput: {
        width: '20%',
        "& [class*=MuiInputBase-input]": {
            padding: "10px !important",
        },
        // width: '30%'
    },
    formControl: {
        marginLeft: 10
    },
    loader: {
        textAlign: 'center'
    },
    alignCenter: {
        textAlign: 'center'
    },
    tableRow: {
        cursor: 'pointer'
    }
});

class AutomationJobs extends React.Component {
    constructor(props) {
        super(props);
        this.reactFlowWrapper = React.createRef(null);

        this.state = {
            isShow: true,
            jobs: [],
            totalJobs: 0,
            page: 0,
            pageSize: 10,
            query: '',
            statusFilter: 0,
            statusFilterOptions: [{
                label: 'All job status',
                value: 0
            }, {
                label: 'Successful',
                value: 'Finished'
            }, {
                label: 'Processing',
                value: 'InProgress'
            }, {
                label: 'Failed',
                value: 'Error'
            }],
            statusFilterMap: {
                Error: 'Failed',
                InProgress: 'Processing',
                Finished: 'Successful',
            },
            typeFilter: 0,
            typeFilterOptions: [{
                label: 'All job types',
                value: 0
            }, {
                label: 'Repeted',
                value: 1
            }, {
                label: 'Tested',
                value: 2
            }],
            periodFilter: 0,
            periodFilterOptions: [{
                label: 'All periods',
                value: 0
            }, {
                label: 'Last Hour',
                value: 1
            }, {
                label: 'Last 24 Hours',
                value: 2
            }, {
                label: 'Last 7 Days',
                value: 3
            }, {
                label: 'Last 30 Days',
                value: 4
            }, {
                label: 'Custom Range',
                value: 5
            }],
            isDataLoading: true
        }
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.fetchAutomationJobsProgress === false && prevProps.fetchAutomationJobsProgress === true) {
            if (this.props.fetchAutomationJobsError === null) {
                if (this.props.automationJobs) {
                    this.setState({
                        jobs: this.props.automationJobs.Data,
                        isDataLoading: false,
                        totalJobs: this.props.automationJobs.total
                    })
                }
            }
        }
    }


    fetchData() {
        const { statusFilter } = this.state;
        let params = {
            page: this.state.page,
            pageSize: this.state.pageSize
        }
        if (statusFilter !== 0) {
            params['status'] = statusFilter
        }
        this.props.fetchAutomationJobs(this.props.id, params)
    }

    handleChangePage(e, newPage) {
        this.setState({
            page: newPage,
            isDataLoading: true
        }, () => {
            this.fetchData()
        })
    }

    handleChangeRowsPerPage(e) {
        this.setState({
            page: 0,
            pageSize: e.target.value,
            isDataLoading: true
        }, () => {
            this.fetchData()
        })
    }

    render() {
        const { classes } = this.props;
        const { jobs, statusFilterMap, isDataLoading, totalJobs, page, pageSize, statusFilter, statusFilterOptions, typeFilter, typeFilterOptions, periodFilter, periodFilterOptions } = this.state;

        return (
            <div className={classes.jobRoot}>
                <div className={classes.topFilter}>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <MaterialSelect
                            value={statusFilter}
                            onChange={(e) => {
                                this.setState({
                                    statusFilter: e.target.value
                                }, () => {
                                    this.fetchData()
                                })
                            }}
                        >
                            {statusFilterOptions.map((itm, k) => {
                                return <MenuItem key={'statusFilter' + k} value={itm.value} style={{ fontSize: 13 }}>
                                    {itm.label}
                                </MenuItem>
                            })}
                        </MaterialSelect>
                    </FormControl>

                    {false && <FormControl variant="outlined" className={classes.formControl} size="small">
                        <MaterialSelect
                            value={typeFilter}
                            onChange={(e) => {
                                this.setState({
                                    typeFilter: e.target.value
                                })
                            }}
                        >
                            {typeFilterOptions.map((itm, k) => {
                                return <MenuItem key={'typeFilter' + k} value={itm.value} style={{ fontSize: 13 }}>
                                    {itm.label}
                                </MenuItem>
                            })}
                        </MaterialSelect>
                    </FormControl>}

                    {false && <FormControl variant="outlined" className={classes.formControl} size="small">
                        <MaterialSelect
                            value={periodFilter}
                            onChange={(e) => {
                                this.setState({
                                    periodFilter: e.target.value
                                })
                            }}
                        >
                            {periodFilterOptions.map((itm, k) => {
                                return <MenuItem key={'periodFilter' + k} value={itm.value} style={{ fontSize: 13 }}>
                                    {itm.label}
                                </MenuItem>
                            })}
                        </MaterialSelect>
                    </FormControl>}


                </div>
                <div className={classes.body}>
                    <Paper style={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer style={{ maxHeight: '78vh' }}>
                            <Table className={classes.mandatoryTable} stickyHeader aria-label="sticky table" key={'table_' + this.props.key}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.nameCol}>Time</TableCell>
                                        <TableCell className={classes.nameCol}>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!isDataLoading && jobs && jobs.length > 0 && jobs.map((job, k) => {
                                        return <TableRow key={k} className={classes.tableRow} onClick={() => {
                                            let redirectPath = `/app/settings/automations/builder/${this.props.id}/job/${job.ID}`;
                                            this.props.history.push(redirectPath);
                                        }}>
                                            <TableCell className={classes.nameCol}>{CommonFn.formateDateTime(job?.CreatedAt)}</TableCell>
                                            <TableCell className={classes.nameCol}>{statusFilterMap[job.ExecutionStatus]}</TableCell>
                                        </TableRow>
                                    })}
                                    {!isDataLoading && jobs && jobs.length <= 0 && <TableRow>
                                        <TableCell className={classes.alignCenter} colSpan={2}>No Jobs</TableCell>
                                    </TableRow>}
                                    {isDataLoading && <TableRow>
                                        <TableCell className={classes.loader} colSpan={2}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                component="div"
                                count={totalJobs || 0}
                                rowsPerPage={pageSize}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Paper>
                </div>
            </div>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AutomationJobs));



