import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "../Style/index.css";
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import Arrow from '../Components/Arrow';

import { showBuilderConfigDrawer } from "redux/automations/builder/action"

const connectedProps = () => ({});

const connectionActions = {
  showBuilderConfigDrawer: showBuilderConfigDrawer
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  triggerNode: {
    border: '1px solid #222'
  }
});

class TriggerNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerOn: ''
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {

  }

  initialValue() {
    const data = this.props.data;
    if (data && data.responseJson) {
      const responseDatas = JSON.parse(data.responseJson);
      let cronExp = "cron_expression"
      let value = responseDatas.filter(o => o.name === cronExp)[0].value
      if (value) {
        this.setState({
          triggerOn: value
        })
      }
    }
  }

  onConnect = (params) => {
    console.log('handle onConnect', params)
  };

  render() {
    // const { classes } = this.props;
    // console.log(this.props, 'Trigger-Propssssssssssss')
    const data = this.props.data;
    return (
      <>
        <p className={'e_steps_title'}>Trigger</p>
        <div className={'e_step_header'} onClick={() => {
          this.props.showBuilderConfigDrawer(this.props.data)
        }}>
          <div className={'e_step_icon'}>
            <TrendingDownIcon />
          </div>
          <div className={'e_step_title'}>
            {<p className={'e_step_title_text'}>{data.label}</p>}
          </div>
        </div>
        <Arrow
          type={'common'}
          height={90}
          label={'ACTIONS'}
          isShowArrow={true}
        />
      </>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(TriggerNode));
