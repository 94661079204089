import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, FormControl, Select, MenuItem, Button, IconButton } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon, Add as AddIcon } from "@material-ui/icons"
import classnames from "classnames";
import { withRouter } from 'react-router-dom';
import ToolTip from "Components/Common/ToolTip.jsx";
import { Link } from "react-router-dom";

// assets
import CommonCss from "commonCss";
import Image from 'Components/Common/image'
import MetricsEmptyState from "assets/images/metrics_empty_state.svg";

// redux
import { fetchAppCatlogProducts } from "redux/product/appCatalog/action"
import { productSearch } from "redux/product/action";


const connectedProps = (state) => ({
    fetchAppCatalogProductsProgress: state.appCatalog.fetchAppCatalogProductsProgress,
    fetchAppCatalogProductsError: state.appCatalog.fetchAppCatalogProductsError,
    appCatalogProducts: state.appCatalog.products,
});

const connectionActions = {
    productSearch: productSearch,
    fetchAppCatlogProducts: fetchAppCatlogProducts
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        // background:theme.palette.common.white,
        borderRadius: theme.spacing(2),
    },
    header: {
        height: '100%',
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: 'center space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    tblSelecter: {
        marginRight: '16px',
        '& [class*=MuiSelect-outlined-]': {
            fontSize: 13,
            padding: '8px 12px',
            background: '#ffffff'
        }
    },
    viewAll: CommonCss.viewAll,
    viewAllbutton: {
        ...CommonCss.viewAllbutton,
        //    marginRight: 10
    },
    formControl: {
        width: theme.spacing(20.5)
    },
    button: {
        padding: theme.spacing(0.9),
        fontSize: theme.spacing(2.2),
        fontWeight: 400,
        width: theme.spacing(30),
    },
    actionAction: {
        padding: theme.spacing(5),
        textAlign: "center"
    },
    headCell: {
    },
    firstCell: {
        paddingLeft: theme.spacing(5) + " !important"
    },
    cell: {
        color: "#4B4B4B",
        padding: theme.spacing(1)
    },
    icon: {
        color: "#BABABA"
    },
    tbody: {
        overflowY: 'auto !important',
        height: '225px',
        overflow: 'auto',
        display: 'inline-grid',
        width: '100%'
    },
    rowCenterCenter: {
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent: 'center',
        alignItems: 'center'
    },
    tblContainer: {
        width: 'calc(100% - 10px)',
        minHeight: 448
    },
    tblHead: {
        width: '100%',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 25px #55555500',
        borderRadius: '5px'
    },
    tblBody: {
        // height: 255,
        width: '100%'
    },
    tblRow: {
        display: 'flex',
        margin: '16px 0',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 25px #55555500',
        borderRadius: '5px'
    },
    tblHeadCol: {
        // borderBottom: '1px solid #ebebeb',
        padding: "8px 0px 8px 30px"
    },
    tblCol: {
        // borderBottom: '1px solid #ebebeb',
        padding: "16px 0px 16px 30px",
        // display: 'flex !important',
        // alignItems: 'center',
    },
    arrowCol: {
        padding: "8px 30px 8px 0px",
    },
    productLogo: {
        float: 'left',
        width: '35px',
        height: '35px',
        border: '1px solid #EEEEEE',
        borderRadius: '4px',
        marginRight: '10px',
        textAlign: 'center',
        lineHeight: '33px',
        fontWeight: 600,
        fontSize: 11,
        color: '#A0A5BA',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'scale-down'
        }
    },
    DashboardNoDataLayout: {
        width: 'calc(100% - 10px)',
        margin: '22px 0px 0px 0px',
        minHeight: 450,
        textAlign: 'center',
        background: '#ffffff',
        padding: '23px',
        borderRadius: '6px',
        '& button': {
            margin: '20px 0'
        },
        '& a': {
            color: '#1f73b7 !important',
            textDecoration: 'underline'
        }
    }
});

class Metric extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedMetric: 10,
            metricsQueryParam: {
                page: 0,
                pageSize: 5,
            }
        }

        this.myInput = React.createRef()
    }

    componentDidMount() {
        this.onChange(10)
        console.log(this.myInput)
    }

    onChange = (value) => {
        let sort = ["-usage"];
        if (value === 20) {
            sort = ["usage"];
        }

        this.setState({
            selectedMetric: value
        })
        this.props.fetchAppCatlogProducts({
            field_setname: 'all',
            text: '',
            filter_fields: {},
            analytics_only: true,
            qtype_page: ["existing", 0],
            page_size: 5,
            sort_fields: sort
        });
    }

    routeChange = (id) => {
        let redirectPath = `/app/metrics/vendor/${id}/`;
        this.props.history.push(redirectPath);
    }

    render() {
        const { classes, appCatalogProducts } = this.props;
        let metrics = appCatalogProducts?.existing?.items || []

        return <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant={"h6"} className={classes.title}>
                    {this.props.title} <ToolTip tooltip={this.props.title} />
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                    <div className={classes.tblSelecter}>
                        <FormControl variant="outlined" className={classes.formControl} size="small">
                            <Select value={this.state.selectedMetric} onChange={(e) => { this.onChange(e.target?.value) }}>
                                <MenuItem value={10} style={{ fontSize: 13 }}>Most used</MenuItem>
                                <MenuItem value={20} style={{ fontSize: 13 }}>Least used</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <Link
                            to="/app/metrics"
                            className={classes.viewAllbutton}
                        >
                            View all
                        </Link>
                    </div>
                </div>

            </div>
            {!this.props.fetchAppCatalogProductsProgress && metrics.length > 0 &&
                <>
                    <div className={classes.tblContainer}>
                        {!this.props.fetchAppCatalogProductsProgress &&
                            <div className={classes.tblHead}>
                                <div className={classes.tblRow}>
                                    <span className={classes.tblHeadCol} style={{ display: 'block', width: '40%', float: 'left', fontWeight: 500 }}>Products</span>
                                    <span className={classes.tblHeadCol} style={{ display: 'block', width: '25%', float: 'left', fontWeight: 500, textAlign: 'center' }}>Weekly</span>
                                    <span className={classes.tblHeadCol} style={{ display: 'block', width: '25%', float: 'left', fontWeight: 500, textAlign: 'center' }}>Monthly</span>
                                    <span className={classnames(classes.tblHeadCol, classes.arrowCol)} style={{ display: 'block', width: '10%', float: 'left', fontWeight: 500 }}></span>
                                </div>
                            </div>
                        }
                        <div className={classes.tblBody}>
                            {!this.props.fetchAppCatalogProductsProgress && metrics.map((item, i) => {
                                return (
                                    <div className={classes.tblRow}>
                                        <span className={classes.tblCol} style={{ width: '40%', float: 'left', display: 'flex', alignItems: 'center' }}>
                                            <div className={classes.productLogo} title={item.name}>
                                                <Image src={item.logo} alt={item.Name} title={item.Name} />
                                            </div>
                                            {item.name}
                                        </span>
                                        <span className={classes.tblCol} style={{ display: 'block', width: '25%', float: 'left', textAlign: 'center', lineHeight: '35px' }}>{Math.ceil(item?.usage?.weeklyactive)}</span>
                                        <span className={classes.tblCol} style={{ display: 'block', width: '25%', float: 'left', textAlign: 'center', lineHeight: '35px' }}>{Math.ceil(item?.usage?.monthlyactive)}</span>
                                        <span className={classnames(classes.tblCol, classes.arrowCol)} style={{ display: 'flex', width: '10%', float: 'left', lineHeight: '35px', alignItems: 'center' }}>
                                            <IconButton size="small">
                                                <ArrowForwardIcon onClick={() => this.routeChange(item.id)} className={classnames(classes.icon)} />
                                            </IconButton>
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>
            }
            {this.props.fetchAppCatalogProductsProgress &&
                <div>
                    <span className={classes.tblCol} style={{ margin: '0px auto', width: '100%' }}>
                        <span style={{ display: 'block', padding: '95px', textAlign: 'center' }}><CircularProgress /></span>
                    </span>
                </div>
            }
            {!this.props.fetchAppCatalogProductsProgress && metrics.length === 0 &&
                <div className={classes.DashboardNoDataLayout}>
                    <div style={{ height: 220 }}>
                        <Image src={MetricsEmptyState} />
                    </div>
                    <h2>No Metrics found</h2>
                    <div style={{ height: 50 }}>
                        <p>Integrate with your Single Sign-On solution to understand usage of your existing vendors</p>
                    </div>
                    <Button variant="outlined" color="secondary" className={classes.button} startIcon={<AddIcon />}
                        onClick={() => { this.props.history.push('/app/settings/integrations') }}
                    >Integrate</Button>
                </div>
            }
        </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Metric));