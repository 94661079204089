import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Image from "Components/Common/image";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import verifiedIcon from "assets/images/mdi_verified.svg";
const connectedProps = () => ({});

const connectionActions = {};

const connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      paddingTop: "24px",
      paddingBottom: "24px",
    },
    heading: {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 600,
      color: "#003b4d99",
      textTransform: "uppercase",
    },
  });

const ProfileItemTextType = (props) => {
  const {
    classes,
    data,
    isEdit,
    isRequired = false,
    value,
    type,
    onSubmit,
  } = props;

  const [inputValue, setInputValue] = useState(value || "");
  const [error, setError] = useState(data.error || "");
  const [touched, setTouched] = useState(data.error || false); // New state variable to track user interaction

  useEffect(() => {
    if (data.error) {
      setError(data.error);
    }
    if (data.error) {
      setTouched(data.error);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    // Perform validation when the type or value changes, and only if the input has been touched
    if (touched && isRequired && !inputValue) {
      setError("This field is required");
    } else if (!inputValue) {
      setError("");
    } else if (inputValue) {
      if (type === "url" && !isValidURL(inputValue)) {
        setError("Invalid URL");
      } else if (type === "phone" && !isValidPhoneNumber(inputValue)) {
        let error_n = "Invalid " + data?.label + '.';
        setError(error_n);
      } else if (type === "email" && !isValidEmail(inputValue)) {
        setError("Invalid email address");
      } else if (type === "number" && isNaN(inputValue)) {
        setError("Invalid number");
      } else {
        setError("");
      }
    }
  }, [isRequired, inputValue, type, touched]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setTouched(true); // Set the touched state to true when the user interacts with the input
  };

  const isValidURL = (url) => {
    // Basic URL validation using a regular expression
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  const isValidPhoneNumber = (phone) => {
    // Basic phone number validation using a regular expression
    const phonePattern = /^\d{10}$/; // Assumes a 10-digit phone number
    return phonePattern.test(phone);
  };

  const isValidEmail = (email) => {
    // Basic email validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <>
      {isEdit && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            id="outlined-full-width"
            required={isRequired ? true : false}
            fullWidth
            size={"small"}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type={type || "text"}
            value={inputValue}
            error={touched && error} // Show the error only when the input has been touched and there's an error
            helperText={touched ? error : ""} // Show the error message when the input has been touched
            onChange={handleChange}
            onBlurCapture={() => {
              onSubmit?.(inputValue);
            }}
          />
        </div>
      )}
      {!isEdit && (
        <div style={{display:"flex", alignItems:"center", flexDirection: "row"}}>
          <Typography variant="body1" style={{
            fontFamily: "Manrope",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: '10px'
          }}>
            {inputValue ? inputValue : "-"}
          </Typography>{data?.additionalData?.isVerified &&   <Image
                          src={verifiedIcon}
                          alt={"verified"}
                          title={"verified"}
                        />}
        </div>
      )}
    </>
  );
};

export default connector(
  compose(withRouter, withStyles(styles))(ProfileItemTextType)
);
