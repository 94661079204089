import React from "react";
import { connect } from "react-redux";
import _ from "underscore";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Grid, IconButton } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import RatingPoper from "./RatingPoper";

// icons
// import { Send as SendIcon} from '@material-ui/icons';
import CachedIcon from "@material-ui/icons/Cached";
// import CloseIcon from '@material-ui/icons/Close';
// import {  Message as MessageIcon } from '@material-ui/icons';
import DoneIcon from "@material-ui/icons/Done";

// assets
import Messages from "assets/images/add_message.svg";
import AskSeller from "assets/images/ask-seller.svg";
import classnames from "classnames";
import Image from "Components/Common/image.jsx";

// components
import Tabs from "Components/Common/Tabs/Tabs";
import Tab from "Components/Common/Tabs/Tab";
import AnswerReply from "Components/Common/EvaluateMultiChoiceAnswer/AnswerReply";
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import ComingSoonDialog from "Components/Common/ComingSoonDialog";
import editIcon from "assets/images/edit_icon.svg";
// import SaveIcon from '@material-ui/icons/Save';

// redux
import { fetchProductDetails, fetchProductRequirementDetails } from "redux/product/action";
import {
  addRequirementResponse,
  fetchUserRequirementResponse,
  showCollectiveResponseDialog,
  showAskSellerDialog,
  fetchCollectiveResponses,
  fetchAskSellerThread,
  addAskSellerRequest,
} from "redux/evaluation/criteria/action";
import { showEvaluationProductEditDialog } from "redux/evaluation/cart/action";
import { showProductRankingDialog } from "redux/evaluate/action";

import ToolTip from "Components/Common/ToolTip.jsx";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

import CollectiveSellerResponses from "./CollectiveSellerResponses";

const connectedProps = (state) => ({
  OrgID: state.authUser.user.OrgID,
  expandedCriteriaId: state.evaludationCriteria.expandedCriteriaId,
  userReponses: state.evaludationCriteria.userReponses,
  addResponseProgress: state.evaludationCriteria.addResponseProgress,
  errorResponseAdd: state.evaludationCriteria.errorResponseAdd,
  collectiveResponseDialogOpen: state.evaluate.collectiveResponseDialogOpen,
  evaluationProductsList: state.evaluationMetaData.evaluationProductsList,
  threadFetchProgress: state.evaludationCriteria.fetchAskSellerResponseInprogress,
  thread: state.evaludationCriteria.fetchAskSelerResponse,
  saveProgress: state.evaludationCriteria.askSellerSubmitProgress,
  productRequirement: state.products.productRequirement,
  evaluateView: state.applications.evaluateView,
  criteria_requrement_height: state.applications.criteria_requrement_height,
  expandedRequirementId: state.evaludationCriteria.expandedRequirementId,
  fetchCollectiveResponsesLoading: state.evaludationCriteria.fetchCollectiveResponsesLoading,
  collectiveResponses: state.evaludationCriteria.collectiveResponses,
});

const connectionActions = {
  addRequirementResponse: addRequirementResponse,
  fetchUserRequirementResponse: fetchUserRequirementResponse,
  showProductRankingDialog: showProductRankingDialog,
  showCollectiveResponseDialog: showCollectiveResponseDialog,
  fetchProductDetails: fetchProductDetails,
  showEvaluationProductEditDialog: showEvaluationProductEditDialog,
  showAskSellerDialog: showAskSellerDialog,
  fetchAskSellerThread: fetchAskSellerThread,
  fetchCollectiveResponses: fetchCollectiveResponses,
  addAskSellerRequest: addAskSellerRequest,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    requirement: {
      // height: 220,
      cursor: "pointer",
      padding: "0 .5em",
      marginTop: 18,
      borderTop: "solid 1px #EEEEEE",
      display: "flex",
      overflow: "hidden",
      // alignItems: 'center',
      flexDirection: "column",
      // justifyContent: 'space-between',
      // maxHeight: 195,
      position: "relative",
      transition: "all linear .5s",
      "&.rootRequirementHight": {
        height: 300,
      },

      "&.options-hide": {
        opacity: 0.2,
        pointerEvents: "none",
      },
    },
    responseActionContainer: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center flex-start",
      alignItems: "center",
      marginTop: 13,
    },
    responseAction: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: " center space-between",
      alignItems: "center",
      background: "#f9f9f9",
      borderRadius: "8px",
      padding: "4px 21px",
    },
    rNote: {
      // height: '100%',
      minHeight: "25px",
      fontSize: 13,
      color: "#707070",
      width: "100%",
      position: "relative",
      overflow: "overlay",
      // textAlign:"center",
      // padding:theme.spacing(4)
    },
    sellerNote: {
      height: "100%",
      minHeight: "25px",
      fontSize: 13,
      color: "#707070",
      width: "100%",
      // margin: '0px auto',
      overflow: "overlay",
      // textAlign:"center",
      // padding:theme.spacing(4)
    },
    selectResult: {
      "& > div": {
        marginBottom: 0,
      },
      display: "flex",
      justifyContent: "flex-end",
      position: "relative",
      marginRight: 10,
      height: "100%",
      alignItems: "center",
      minWidth: 80,

      "& button": {
        color: "#707070",
        background: "#fff",
        padding: "1.5px 9px",
        minWidth: 80,
      },
      "& [class*=MuiFormControl-root]": {
        minWidth: 90,
        width: "inherit",
      },
      "& [class*=MuiSelect-root]": {
        fontSize: 13,
        color: "#707070",
        padding: "0.29em 0.75em",
      },
      "&.pass": {
        "& [class*=MuiSelect-root], svg": {
          color: "#2EB77D",
        },
        "& [class*=MuiOutlinedInput-notchedOutline]": {
          border: "1px solid #2EB77D",
        },
      },
      "&.fail": {
        "& [class*=MuiSelect-root], svg": {
          color: "#b94141",
        },
        "& [class*=MuiOutlinedInput-notchedOutline]": {
          border: "1px solid #b94141",
        },
      },
    },
    iconSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    thread: {
      cursor: "pointer",
      padding: 4,
      marginBottom: 10,
      width: 27,
      marginTop: 5,
    },
    addText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "80px",
      fontSize: 13,
      color: "#707070",
      cursor: "pointer",
      transition: "all linear .5s",
    },
    addTextimage: {
      marginRight: 10,
      width: 21,
      position: "relative",
      top: 2,
    },
    responseContent: {
      color: "#707070",
      fontSize: 13,
      padding: "5px 0px",
      textAlign: "center",
      cursor: "pointer",
      borderRight: "1px solid #EEEEEE",

      "& p": {
        marginTop: 0,
        marginBottom: 0,
      },
    },

    askSeller: {
      borderRight: "none",
    },
    bottomContent: {
      paddingTop: 15,
    },
    requirementName: {
      margin: "0px 0px 8px 0px",
      alignSelf: "flex-start",
      fontSize: 12,
      color: "#04363D",
      fontWeight: 500,
    },
    saveText: {
      color: "#4b86f8",
    },
    saveHighlight: {
      border: "1px solid #4b86f8",
      borderRadius: 5,
    },
    yourResponse: {
      textAlign: "left",
      letterSpacing: "0px",
      color: "#707070",
      opacity: 1,
      fontSize: "13px",
      paddingRight: 25, // 32
      transition: "height 0.3s ease-in",
    },
    response: {
      textAlign: "left",
      letterSpacing: "0px",
      color: "#04363D",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      placeContent: "center space-between",
      alignItems: "center",
      width: "100%",
      transition: "all linear 1s",
      position: "relative",
      // marginTop: 16,
      // marginBottom: 6,
      "& [class*='MuiInputBase-inputMultiline']": {
        fontSize: 13,
        height: 70,
        color: "#707070",
      },
    },
    viewAllResponse: {
      cursor: "pointer",
      textDecoration: "underline",
      letterSpacing: "0px",
      color: "#6D7278",
      fontSize: "13px",
      // marginTop: 10,
      marginBottom: 0,
      width: 140,
      textAlign: "right",
      margin: "0px auto",
    },
    addComment: {
      cursor: "pointer",
      textDecoration: "underline",
      letterSpacing: "0px",
      color: "#6D7278",
      fontSize: "13px",
      width: 140,
      textAlign: "left",
      margin: "0px auto",
      "& span": {
        marginBottom: 6,
      },
    },
    viewAllResponseEdit: {
      cursor: "pointer",
      textAlign: "left",
      textDecoration: "underline",
      letterSpacing: "0px",
      color: "#6D7278",
      fontSize: "13px",
      lineHeight: "16px",
      display: "block",
      textAlign: "right",
      padding: "10px",
    },
    askSellerBtn: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: " 1px solid #398AF5",
      borderRadius: "4px",
      letterSpacing: "0px",
      color: "#398AF5",
      padding: "3px 10px",
      cursor: "pointer",
      alignItems: "center",
      display: "flex",
      marginLeft: "6px",
    },
    replySellerBtn: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: " 1px solid #398AF5",
      borderRadius: "4px",
      letterSpacing: "0px",
      color: "#398AF5",
      padding: "3px 10px",
      cursor: "pointer",
      alignItems: "center",
      display: "flex",
    },
    editIcon: {
      color: "#999999",
      marginTop: 6,
      marginLeft: 17,
      marginRight: 15,
      cursor: "pointer",
      position: "absolute",
      right: "-20px",
      top: "20px",
    },
    editSellerIcon: {
      color: "#999999",
      cursor: "pointer",
    },
    saveIcon: {
      marginTop: 0,
      marginLeft: 18,
      marginRight: 15,
      cursor: "pointer",
      position: "absolute",
      right: "-38px",
      top: "6px",
      "& svg": {
        width: 18,
        fill: "#4b86f8",
      },
    },
    closeIcon: {
      marginTop: 0,
      marginLeft: 18,
      marginRight: 15,
      cursor: "pointer",
      position: "absolute",
      right: "-38px",
      top: "30px",
      "& svg": {
        width: 18,
        fill: "#4b86f8",
      },
    },
    sellerSaveIcon: {
      position: "absolute",
      right: "-33px",
      top: 0,
      cursor: "pointer",
      "& svg": {
        width: 18,
        fill: "#4b86f8",
      },
    },
    cancelEdit: {
      position: "absolute",
      right: "-33px",
      top: 30,
      cursor: "pointer",
      "& svg": {
        width: 18,
        fill: "#4b86f8",
      },
    },
    editSection: {
      position: "absolute",
      right: -36,
      top: 0,
    },
    askImage: {
      marginRight: 5,
    },
    noteSection: {
      position: "relative",
      width: "calc(100% - 7px)",
      "& [class*='editableField']": {
        maxHeight: "50px !important;",
        minHeight: "50px !important",
      },
    },
    showNoteSection: {
      // width: 'calc(100% - 7px)',
      width: "calc(100% - 30px)",
      "& [class*='editableField']": {
        margin: "0px !important",
        minHeight: "auto !important",
        maxHeight: "auto !important",
      },
    },
    showSellerNoteSection: {
      width: "calc(100% - 7px)",
      "& [class*='editableField']": {
        margin: "0px !important",
        minHeight: "auto !important",
        maxHeight: "auto !important",
      },
    },
    "@global": {
      ".rootRequirementHight": {
        "& [class*='response-addText']": {
          height: "150px",
        },
        "& [class*='editor-formControl']": {
          maxHeight: "150px",
        },
      },
    },
    expandDiv: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      right: 13,
      top: 13,
    },
    refreshIcon: {
      cursor: "pointer",
      color: "#999999",
      transitionDuration: "5s",
      transitionProperty: "transform",
      marginRight: 10,
    },
    underprogress: {
      transform: "rotate(360deg)",
      "-webkit-transform": "rotate(360deg) infinite linear",
    },
    gridViewConversation: {
      flexDirection: "row",
      boxSizing: "border-box",
      display: "flex",
      placeContent: "center",
      alignItems: "center",
    },
    reqMatch: {
      display: "flex",
      flexDirection: "row",
      placeContent: "center flex-start",
      alignItems: "center",
      background: "#f9f9f9",
      padding: "12px 2px",
      borderRadius: "6px",
      "& span": {
        marginRight: 10,
        marginLeft: 10,
      },
    },
    expand: {
      transform: "rotate(0deg)",
      padding: 0,
      borderRadius: 3,
      border: "1px solid #EDEDED",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  });

class Response extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ratingPoperEl: null,
      editNote: false,
      editSellerNote: false,
      sellerNoteUpdated: false,
      fetchSellerThread: "",
      note: "",
      response: "",
      value: 0,
      openDownloadModal: false,
      isDotHidden: false,
      selectedTab: null,
      requirementHight: null,
      addComment: false,
    };
  }

  componentDidMount() {
    this.requirementHight();
    window.addEventListener("resize", this.requirementHight);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.requirementHight);
  }

  requirementHight = () => {
    var requirement = this.props.requirement;
    let reqEle = document.getElementById(`requirement_${this.props.criteria.id}_${requirement.id}`);
    if (reqEle && this.state.requirementHight !== reqEle.offsetHeight) {
      this.setState({
        requirementHight: reqEle.offsetHeight,
      });
    }
  };

  selectDownload = (isModalOpen) => {
    this.setState({ openDownloadModal: isModalOpen });
  };

  componentDidUpdate(prevProps) {
    if (this.props.saveProgress === false && prevProps.saveProgress === true) {
      this.setState({ editSellerNote: false }, () => {
        this.fetchSelerThread();
      });
    }
    _.debounce(() => {
      this.requirementHight();
    }, 500)();
  }

  fetchSelerThread = () => {
    var me = this;
    if (this.state.sellerNoteUpdated === true) {
      this.setState(
        {
          sellerNoteUpdated: false,
          editSellerNote: false,
        },
        () => {
          var product = me.props.product;
          var requirement = me.props.requirement;
          me.props.fetchAskSellerThread(product.VendorUUID, requirement.id);
        }
      );
    }
  };

  openCollectiveResponses = (e, targetTab = 0) => {
    var criteria = this.props.criteria;
    let requirement = this.props.requirement;
    let product = this.props.product;
    let evaluation_id = parseInt(this.props?.match?.params.evaluation_id);
    this.props.showCollectiveResponseDialog(criteria, requirement, product, evaluation_id, targetTab);
    // this.changeCardPosition(e);
  };

  changeCardPosition = (e) => {
    let index, length;
    try {
      const slider = document.querySelector(".sliderItems");
      if (e.target.closest(".sliderContent")) {
        index = parseInt(e.target.closest(".sliderContent").getAttribute("data-index"));
        length = parseInt(e.target.closest(".sliderContent").getAttribute("data-length"));
      }

      if (index > -1) {
        slider.scrollTo(index * (slider.scrollWidth / length), 0);
      }
    } catch (e) {
      console.log(e);
    }
  };

  changeNote = (e, response, className) => {
    //this.changeCardPosition(e);
    if (!this.state.editNote) {
      this.setState({ editNote: true, note: (response !== null && response.Note) || "" }, () => {
        try {
          const editHight = document.querySelectorAll(`.${className}`);
          if (editHight && editHight.length) {
            for (var i = 0; i < editHight.length; i++) {
              editHight[i].classList.add("rootRequirementHight");
            }
          }
        } catch (e) {}
      });
    }
  };
  onBlurInput = (className) => {
    this.setState(
      {
        editNote: false,
      },
      () => {
        try {
          const responseNote = document.querySelectorAll(`.response_note .${className}`);
          const rootRequirementHight = document.querySelectorAll(`.${className}`);
          if (responseNote.length == 0 && rootRequirementHight && rootRequirementHight.length) {
            for (var i = 0; i < rootRequirementHight.length; i++) {
              rootRequirementHight[i].classList.remove("rootRequirementHight");
            }
          }
        } catch (e) {}
      }
    );
  };

  tabEvent = (selectedTab) => {
    this.setState({ selectedTab: selectedTab });
    if (selectedTab === "seller") {
      var product = this.props.product;
      var requirement = this.props.requirement;
      this.props.fetchAskSellerThread(product.VendorUUID, requirement.id);
    }
  };

  refreshTabData = () => {
    this.tabEvent("seller");
    let queryparams = {};
    queryparams.evaluation_id = this.props.match.params.evaluation_id || 0;
    queryparams.product_id = this.props.product.ID || 0;
    queryparams.requirement_id = this.props.requirement.id || 0;
    this.props.fetchCollectiveResponses(queryparams);
  };

  render() {
    const classes = this.props.classes;
    var requirement = this.props.requirement;
    var criteria = this.props.criteria;
    var product = this.props.product;

    let _height = this.state.requirementHight - 69;

    var response = null;
    try {
      if (this.props.userReponses[product.ID] !== undefined && this.props.userReponses[product.ID][requirement.id] !== undefined) {
        response = this.props.userReponses[product.ID][requirement.id];
      }
    } catch (e) {
      console.error(e);
    }

    let communityMessage = "",
      matchFeature = "";
    let show_community_message = true;
    let backendMessage = "No data";
    let isDotHidden = true;
    if (criteria.name == "Product Features") {
      show_community_message = true;
      if (requirement.name) {
        this.props.evaluationProductsList.map((productDetail) => {
          if (productDetail.ID == this.props.product.ID) {
            matchFeature = productDetail.Features.find((feature) => {
              return feature.Name.toLowerCase() == requirement.name.toLowerCase();
            });
          }

          if (matchFeature) {
            communityMessage = `${this.props.product.Name} supports ${matchFeature?.Name}`;
            isDotHidden = false;
          }
        });
      }
    } else {
      show_community_message = false;
      if (
        this.props.productRequirement[this.props.product.ID] !== undefined &&
        this.props.productRequirement[this.props.product.ID][requirement.ref_requirement_id] !== undefined &&
        this.props.productRequirement[this.props.product.ID][requirement.ref_requirement_id]["data"] !== null
      ) {
        if (this.props.productRequirement[this.props.product.ID][requirement.ref_requirement_id]["fetchProg"]) {
          backendMessage = "Loading...";
        } else {
          isDotHidden = false;
          backendMessage = this.props.productRequirement[this.props.product.ID][requirement.ref_requirement_id]["data"]["Description"];
        }
      }
    }

    // Vendor response props start
    const { thread } = this.props;
    let threads = Object.assign(thread || {});
    let _thread = threads[`${product.VendorUUID}_${requirement.id}`];
    let current_thread = (_thread && _thread.length > 0 && _thread.filter((item) => item.Type === 2)) || [];

    let selectOptions = [];
    if (this.props.evaluateView) {
      selectOptions = [
        {
          value: "1",
          label: "Yes",
        },
        {
          value: "2",
          label: "Partial",
        },
        {
          value: "3",
          label: "No",
        },
      ];
    } else {
      selectOptions = [
        // {
        //     value:"-1",
        //     label:"Select"
        // },
        {
          value: "1",
          label: "Yes",
        },
        {
          value: "2",
          label: "Partial",
        },
        {
          value: "3",
          label: "No",
        },
      ];
    }
    return (
      <div
        className={this.state.editNote ? "response_note" : ""}
        onClick={() => {
          const id = `requirement_${requirement?.id}`;
          if (id === this.props.expandedRequirementId) {
          } else {
            this.props.expand(id);
          }
        }}
      >
        <div className={classnames(classes.requirement, `requirement_${criteria.id}_${requirement.id}`, this.props.respondable ? "options-show" : "options-hide")} onClick={this.changeCardPosition}>
          {/* <p className={classes.requirementName}>{requirement?.name}</p> */}
          <div className={classes.expandDiv}>
            {this.state.selectedTab === "seller" && (
              <CachedIcon
                className={classnames(classes.refreshIcon, this.props.threadFetchProgress === true ? classes.underprogress : null)}
                onClick={() => {
                  this.refreshTabData();
                }}
              />
            )}
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: this.props.expandedRequirementId === `requirement_${requirement?.id}`,
              })}
              aria-expanded={this.props.expandedRequirementId === `requirement_${requirement?.id}`}
              aria-label="show more"
              onClick={() => {
                const id = `requirement_${requirement?.id}`;
                if (id === this.props.expandedRequirementId) {
                  this.props.expand(false);
                } else {
                  this.props.expand(id);
                }
              }}
            >
              <ArrowDropDownIcon />
            </IconButton>
          </div>

          <Tabs
            emitTabEvent={(e, selectedTab) => {
              this.tabEvent(selectedTab);
              // if(selectedTab == "community" && criteria.name !== "Product Features"){
              //     this.props.fetchProductRequirementDetails(this.props.product.ID,requirement.ref_requirement_id);
              // }
            }}
          >
            <Tab value="response" header="Your response" toolTip={this.props.index === 0 ? "33" : ""}>
              {
                <div className={classes.yourResponse} style={{ height: _height, overflow: "hidden scroll" }}>
                  <Grid container>
                    <Grid item lg={12} className={classes.response}>
                      {![1].includes(requirement.answer_type) && (
                        <AnswerReply
                          menuPortalTarget={document.getElementById("root")}
                          requirement={requirement}
                          evaluation_id={this.props.match.params.evaluation_id}
                          OrgID={this.props.OrgID}
                          response={response}
                          product={product}
                          userOptions={response == null || response.UserOptions == null ? [] : response.UserOptions}
                          onChange={(options, add_other = null) => {
                            if (requirement.answer_type === 5 || requirement.answer_type === 6) {
                              this.props.addRequirementResponse(this.props.match.params.evaluation_id, product.ID, requirement.id, {
                                note: response == null ? null : response.Note,
                                value: response == null ? null : response.Response,
                                response_options: {
                                  answer: add_other !== null ? add_other : response?.UserOptions[0]?.Answer || "",
                                },
                              });
                            } else if (requirement.answer_type === 4) {
                              this.props.addRequirementResponse(this.props.match.params.evaluation_id, product.ID, requirement.id, {
                                note: response == null ? null : response.Note,
                                value: response == null ? null : response.Response,
                                response_options: {
                                  answer: options,
                                },
                              });
                            } else {
                              this.props.addRequirementResponse(this.props.match.params.evaluation_id, product.ID, requirement.id, {
                                note: response == null ? null : response.Note,
                                value: response == null ? null : response.Response,
                                response_options: {
                                  option_ids: options,
                                  answer: add_other === "remove" ? "" : add_other !== null ? add_other : response?.UserOptions[0]?.Answer || "",
                                },
                              });
                            }
                          }}
                        />
                      )}

                      {this.state.editNote === false && (
                        <div style={{ position: "relative", width: "100%" }}>
                          <div className={classes.rNote}>
                            {response !== null && response.Note !== null && response.Note !== "" ? (
                              <>
                                <div className={classnames(classes.showNoteSection)}>
                                  <SlateInputField
                                    readOnly={true}
                                    isToolBar={false}
                                    placeholder=""
                                    style={{ margin: 0 }}
                                    as={SlateInputField}
                                    onChangeEvent={(value) => {
                                      this.setState({
                                        note: value,
                                      });
                                    }}
                                    initValue={response.Note}
                                    formControlStyle={{ border: "none" }}
                                    textContainerStyle={{ color: "#707070", fontSize: 14 }}
                                  />
                                </div>
                              </>
                            ) : (
                              <div
                                className={classnames(classes.addText, "response-addText", "evaluate-add-text")}
                                onClick={(e) => this.changeNote(e, response, `requirement_${criteria.id}_${requirement.id}`)}
                              >
                                <img className={classes.addTextimage} alt="" src={Messages} />
                                <span>{requirement.answer_type === 1 ? "Add Text" : "Add Comment"}</span>
                              </div>
                            )}
                          </div>
                          {response !== null && response.Note !== null && response.Note !== "" && (
                            <div
                              className={classnames(classes.editIcon, "evaluate-response-edit")}
                              onClick={(e) => this.changeNote(e, response, `requirement_${criteria.id}_${requirement.id}`)}
                              title="Edit"
                            >
                              <Image alt="Edit" src={editIcon} />
                            </div>
                          )}
                        </div>
                      )}

                      {this.state.editNote && (
                        <div className={classnames(classes.noteSection)}>
                          <SlateInputField
                            folderPath={`/evaluation/${this.props.match?.params?.evaluation_id}/product/${product?.ID}/requirement/${requirement?.id}`}
                            placeholder=""
                            style={{ margin: 0 }}
                            as={SlateInputField}
                            onChangeEvent={(value) => {
                              this.setState({
                                note: value,
                              });
                            }}
                            initValue={this.state.note}
                            formControlStyle={{ overflow: "overlay" }}
                            textContainerStyle={{ color: "#707070", fontSize: 14 }}
                          />

                          {this.state.editNote === true && (
                            <>
                              <div
                                className={classes.saveIcon}
                                onClick={() => {
                                  // check add other exist
                                  let addOther = response?.UserOptions.filter((item) => item.OptionID === 0).sort((a, b) => b.ID - a.ID);
                                  if (requirement.answer_type === 5 || requirement.answer_type === 6) {
                                    this.props.addRequirementResponse(this.props.match.params.evaluation_id, product.ID, requirement.id, {
                                      note: this.state.note,
                                      value: response == null ? null : response.Response,
                                      response_options: {
                                        answer: requirement.answer_type === 2 || requirement.answer_type === 3 ? addOther[0]?.Answer : response?.UserOptions[0]?.Answer || "",
                                      },
                                    });
                                  } else if (requirement.answer_type === 4 || requirement.answer_type === 1) {
                                    this.props.addRequirementResponse(this.props.match.params.evaluation_id, product.ID, requirement.id, {
                                      note: this.state.note,
                                      value: response == null ? null : response.Response,
                                      response_options: {
                                        answer: requirement.answer_type === 1 ? "Done" : response?.UserOptions[0]?.Answer || "",
                                      },
                                    });
                                  } else {
                                    this.props.addRequirementResponse(this.props.match.params.evaluation_id, product.ID, requirement.id, {
                                      note: this.state.note,
                                      value: response == null ? null : response.Response,
                                      response_options: {
                                        option_ids:
                                          response == null || response.UserOptions == null
                                            ? []
                                            : response.UserOptions.map((i) => {
                                                return i.OptionID;
                                              }),
                                        answer: requirement.answer_type === 2 || requirement.answer_type === 3 ? addOther[0]?.Answer : response?.UserOptions[0]?.Answer || "",
                                      },
                                    });
                                  }
                                  this.onBlurInput(`requirement_${criteria.id}_${requirement.id}`);
                                }}
                                title="Save"
                              >
                                <DoneIcon />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </Grid>

                    <Grid item xs={12} className={classes.responseActionContainer}>
                      <Grid item lg={8} className={classes.reqMatch}>
                        <span>Score</span>
                        <div
                          className={classnames(
                            "evaluate-select-result",
                            classes.selectResult,
                            (response && response.Response + "") === "1" ? "pass" : (response && response.Response + "") === "2" ? "fail" : ""
                          )}
                        >
                          <OutlinedSelectInput
                            value={response !== null && response.Response !== null ? response.Response + "" : "-1"}
                            compact={true}
                            options={selectOptions}
                            onChange={(event) => {
                              if (requirement.answer_type === 5 || requirement.answer_type === 6) {
                                this.props.addRequirementResponse(this.props.match.params.evaluation_id, product.ID, requirement.id, {
                                  note: response == null ? null : response.Note,
                                  value: parseFloat(event.target.value),
                                  response_options: {
                                    answer: response?.UserOptions[0]?.Answer || "",
                                  },
                                });
                              } else if (requirement.answer_type === 4) {
                                this.props.addRequirementResponse(this.props.match.params.evaluation_id, product.ID, requirement.id, {
                                  note: response == null ? null : response.Note,
                                  value: parseFloat(event.target.value),
                                  response_options: {
                                    answer: response?.UserOptions[0]?.Answer || "",
                                  },
                                });
                              } else {
                                let addOther = response?.UserOptions.filter((item) => item.OptionID === 0).sort((a, b) => b.ID - a.ID);
                                this.props.addRequirementResponse(this.props.match.params.evaluation_id, product.ID, requirement.id, {
                                  note: response == null ? null : response.Note,
                                  value: parseFloat(event.target.value),
                                  response_options: {
                                    option_ids:
                                      response == null || response.UserOptions == null
                                        ? []
                                        : response.UserOptions.map((i) => {
                                            return i.OptionID;
                                          }),
                                    answer: requirement.answer_type === 2 || requirement.answer_type === 3 ? addOther && addOther[0]?.Answer : response?.UserOptions[0]?.Answer || "",
                                  },
                                });
                              }
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <p className={classes.viewAllResponse} onClick={(e) => this.openCollectiveResponses(e)}>
                          View All Response <ToolTip toolTip={this.props.index === 0 ? "36" : ""} />
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              }
              {/* {!this.props.respondable && <div className={classes.yourResponse} style={{height: _height, overflow:'hidden scroll'}}> 
                                        <Typography>Cant Respond based on rule set by admin.</Typography>
                        </div>} */}
            </Tab>
            <Tab value="community" header="Community" toolTip={this.props.index === 0 ? "34" : ""} isDot={!isDotHidden}>
              <div style={{ height: _height }}>
                {show_community_message && <p style={{ padding: "6px", textAlign: "left", letterSpacing: "0px", color: "#707070", marginTop: 0, fontSize: 14 }}>{communityMessage}</p>}
                {!show_community_message && (
                  <div style={{ padding: "6px", textAlign: "left", letterSpacing: "0px", color: "#707070", marginTop: 0, fontSize: 14 }} dangerouslySetInnerHTML={{ __html: backendMessage }}></div>
                )}
              </div>
            </Tab>
            <Tab value="seller" header="Vendor" toolTip={this.props.index === 0 ? "35" : ""}>
              {
                <div style={{ height: _height }}>
                  {requirement != null && (
                    <div className={classes.collectiveUserResponses}>
                      <CollectiveSellerResponses
                        criteria={this.props.criteria}
                        evaluation_id={this.props.match.params.evaluation_id}
                        product={product}
                        productId={product?.ID}
                        collectiveTargetTab={2}
                        requirement={requirement}
                        fetchCollectiveResponsesLoading={this.props.fetchCollectiveResponsesLoading}
                        collectiveResponses={this.props.collectiveResponses}
                        style={{
                          maxHeight: _height,
                          overflow: "auto",
                        }}
                        // communityResponse={communityResponse}
                        collectiveRequirement={requirement}
                      />
                    </div>
                  )}
                </div>
              }
              {false && (
                <div style={{ height: _height }}>
                  <Grid container>
                    {/* Response UI */}
                    {this.state.editSellerNote === false && (
                      <>
                        {current_thread && current_thread.length > 0 ? (
                          <>
                            <Grid item xs={12} sm={12} md={12} lg={12} className={classnames(classes.showSellerNoteSection)}>
                              <SlateInputField
                                readOnly={true}
                                isToolBar={false}
                                placeholder=""
                                style={{ margin: 0 }}
                                as={SlateInputField}
                                onChangeEvent={(value) => {
                                  this.setState({
                                    response: value,
                                  });
                                }}
                                initValue={current_thread[current_thread.length - 1].Response}
                                formControlStyle={{ border: "none", maxHeight: 110, overflow: "hidden" }}
                                textContainerStyle={{ color: "#707070", fontSize: 14 }}
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <p style={{ padding: "6px", textAlign: "left", letterSpacing: "0px", color: "#707070", marginTop: 0, fontSize: 14 }}>Vendor hasn’t responded yet. </p>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6}>
                                <button
                                  className={classnames(classes.askSellerBtn, "evaluate-ask-seller-btn")}
                                  onClick={() => {
                                    let evaluation_id = parseInt(this.props?.match?.params.evaluation_id);
                                    if (product.VendorEmail !== null && product.VendorEmail.length !== 0) {
                                      this.props.showAskSellerDialog(requirement, product, evaluation_id);
                                    } else {
                                      this.props.showEvaluationProductEditDialog(evaluation_id, product);
                                    }
                                  }}
                                >
                                  <Image src={AskSeller} alt="forgotpassword_image" className={classes.askImage} /> Ask Vendor
                                </button>
                              </Grid>
                            </>
                          </>
                        )}
                      </>
                    )}
                    {this.state.editSellerNote === false && current_thread && current_thread.length > 0 && (
                      <>
                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ marginTop: "10px" }}>
                          <div className={classes.editSellerIcon} title="Edit">
                            <button
                              className={classes.replySellerBtn}
                              onClick={(e) => {
                                this.setState({ editSellerNote: true });
                                this.changeNote(e, response, `requirement_${criteria.id}_${requirement.id}`);
                              }}
                            >
                              Reply
                            </button>
                          </div>
                        </Grid>
                      </>
                    )}
                    {this.state.editSellerNote === false && current_thread?.length > 0 && (
                      <Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right", paddingRight: 32 }}>
                        <span className={classes.viewAllResponse} style={{ lineHeight: "16px", display: "inline-block" }} onClick={(e) => this.openCollectiveResponses(e, 2)}>
                          View Conversation
                        </span>
                      </Grid>
                    )}

                    {/* Response UI */}
                    <>
                      {this.state.editSellerNote && (
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Grid container>
                                <Grid item xs={7} sm={7} md={7} lg={7}>
                                  <SlateInputField
                                    readOnly={true}
                                    isToolBar={false}
                                    placeholder=""
                                    style={{ margin: 0 }}
                                    as={SlateInputField}
                                    onChangeEvent={(value) => {
                                      this.setState({
                                        response: value,
                                      });
                                    }}
                                    initValue={current_thread[current_thread.length - 1].Response}
                                    formControlStyle={{ border: "none", maxHeight: 70, overflow: "hidden" }}
                                    textContainerStyle={{ color: "#707070" }}
                                  />
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} lg={5} className={classes.gridViewConversation}>
                                  {this.state.editSellerNote && current_thread?.length > 0 && (
                                    <span className={classes.viewAllResponseEdit} onClick={(e) => this.openCollectiveResponses(e, 2)}>
                                      View Conversation
                                    </span>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <div style={{ position: "relative" }}>
                                <SlateInputField
                                  folderPath={`/evaluation/${this.props.match?.params?.evaluation_id}/product/${product?.ID}/requirement/${requirement?.id}`}
                                  placeholder="Type your response here."
                                  style={{ margin: 0 }}
                                  as={SlateInputField}
                                  onChangeEvent={(value) => {
                                    this.setState({
                                      response: value,
                                    });
                                  }}
                                  initValue={""}
                                  formControlStyle={{ overflow: "overlay" }}
                                  textContainerStyle={{ color: "#707070" }}
                                />
                                {this.state.editSellerNote === true && (
                                  <>
                                    <div
                                      className={classes.sellerSaveIcon}
                                      onClick={() => {
                                        this.props.addAskSellerRequest(product.VendorUUID, requirement.id, this.state.response);
                                        this.setState({
                                          sellerNoteUpdated: true,
                                        });
                                        this.onBlurInput(`requirement_${criteria.id}_${requirement.id}`);
                                      }}
                                      title="Save"
                                    >
                                      <DoneIcon />
                                    </div>
                                    {/* <span className={classes.cancelEdit} onClick={()=>{
                                                                            this.setState({response:'',editSellerNote:false})
                                                                            this.onBlurInput(`requirement_${criteria.id}_${requirement.id}`)
                                                                            }}>
                                                                                <CloseIcon />
                                                                        </span> */}
                                  </>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  </Grid>
                </div>
              )}
            </Tab>
          </Tabs>

          <ComingSoonDialog
            feature="Collective responses"
            comingSoonOpen={this.state.openDownloadModal}
            closeDownload={this.selectDownload}
            description={"Collaborate with vendors. Interested in learning more? "}
          />
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Response));
