import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import AuthContainer from "./Common/AuthContainer";
import Logo from "../assets/images/colour_logo.svg";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import OutlinedInput from "./Common/Input/OutlinedInput";
import { Link, withRouter } from "react-router-dom";
import * as Validator from "../util/Validation";
import { register } from "../redux/register/action";
import Alert from "@material-ui/lab/Alert";
import GoogleButton from "assets/images/Google-Button.svg";
import * as Environment from "../util/Environment";
import Image from "Components/Common/image.jsx";
import queryString from "query-string";
import { fetchOrgMetaRequestData } from "redux/companyProfile/action";
import { isClientTokenPresent } from '../util';
import { createClientToken } from "redux/login/action";
import { getNewPlatform } from "util/Environment";

const connectedProps = (state) => ({
  isProgress: state.register.isProgress,
  error: state.register.error,
  success: state.register.success,
  fetchOrgMetaRequestDataInprogress: state.companyProfile.fetchOrgMetaRequestDataInprogress,
  fetchOrgMetaRequestDataSuccess: state.companyProfile.fetchOrgMetaRequestDataSuccess,
  fetchOrgMetaRequestDataFailure: state.companyProfile.fetchOrgMetaRequestDataFailure,
});

const connectionActions = {
  register: register,
  fetchOrgMetaRequestData: fetchOrgMetaRequestData,
  createClientToken: createClientToken,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    registerForm: {
      textAlign: "center",
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      margin: "0 auto",
    },
    caption: {
      marginTop: theme.spacing(3),
    },
    heading: {
      marginBottom: theme.spacing(1),
      fontSize: theme.spacing(3.2),
      color: "#4A4A4A",
      letterSpacing: "0.11px",
    },
    subtitle: {
      fontSize: theme.spacing(2.2),
      marginBottom: theme.spacing(0),
      fontWeight: 600,
      color: "#4A4A4A",
    },
    form: {
      marginTop: theme.spacing(6),
    },
    forgotpassword_link: {
      "&:any-link": {
        color: theme.palette.secondary.main,
      },
      "&:any-link:active": {
        color: theme.palette.secondary.main,
      },
    },
    forgotlabel: {
      textAlign: "right",
    },
    formAction: {
      marginTop: theme.spacing(4),
      margin: "0 auto",
      "& button": {
        width: "100%",
      },
    },
    button: {
      padding: theme.spacing(0.8, 11.6),
      fontSize: theme.spacing(2.8),
      fontWeight: 500,
      marginTop: theme.spacing(0.4),
    },

    register_link: {
      marginTop: theme.spacing(3.2),
      display: "block",
    },
    registerlable: {
      textAlign: "center",
      fontSize: theme.spacing(2.4),
      textDecoration: "underline",
      color: "#4A4A4A",
    },
    termLink: {
      textDecoration: "underline",
    },
    termText: {
      margin: 0,
      paddingTop: 20,
    },
    loginText: {
      margin: "0 auto",
      textAlign: "center",
      marginTop: "24px",
      fontSize: 18,
    },
    socialFormAction: {
      marginTop: "20px",
      margin: "0 auto",
      width: "100%",
    },
    socialBtn: {
      position: "relative",
      // padding: theme.spacing(0.8, 0.8),
      fontSize: theme.spacing(2.8),
      fontWeight: 500,
      marginTop: theme.spacing(1.2),
      background: "#4285F4",
      "& img": {
        width: 34,
        height: 34,
        position: "absolute",
        left: 6,
      },
      "&:hover": {
        background: "#4285F4",
      },
    },
    orRule: {
      margin: "25px 0",
      "& h2": {
        width: "100%",
        textAlign: "center",
        borderBottom: "1px solid #BFBFBF",
        lineHeight: "0.1em",
        margin: "10px 0 20px",
        "& span": {
          background: "#fff",
          padding: "0 10px",
          color: "#BFBFBF",
          fontSize: "16px",
        },
      },
    },
  });

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      name_error: "",
      email: "",
      email_error: "",
      company: "",
      company_error: "",
      success: "",
      error: "",
      queryParams: queryString.parse(this.props.location.search),
      rid: null,
      oid: null,
      org_name: null,
      entity_name: null,
    };

    this.registerUser = this.registerUser.bind(this);
    this.clearError = this.clearError.bind(this);
    this.loginWithGoogle = this.loginWithGoogle.bind(this);
  }

  componentDidMount() {
    const newURL = getNewPlatform() + '/register' + window.location.search
    window.open(newURL, "_self")
    // if (!isClientTokenPresent()) {
    //   this.props.createClientToken();
    // }
    // if (this.state.queryParams) {
    //   if (this.state.queryParams.oid && this.state.queryParams.rid) {
    //     this.setState(
    //       {
    //         oid: this.state.queryParams.oid,
    //         rid: this.state.queryParams.rid,
    //       },
    //       () => {
    //         this.props.fetchOrgMetaRequestData(this.state.queryParams.oid, this.state.queryParams.rid);
    //       }
    //     );
    //   }
    // }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isProgress === false && prevProps.isProgress === true) {
      if (this.props.error !== null) {
        var error = this.props.error;
        if (error.status === 400) {
          var errors = error.errors;
          if (errors !== null) {
            var stateError = {
              name_error: "",
              email_error: "",
              company_error: "",
            };
            for (var field in errors) {
              switch (field) {
                case "email":
                  stateError.email_error = errors[field][0];
                  break;
                case "name":
                  stateError.name_error = errors[field][0];
                  break;
                case "company":
                  stateError.company_error = errors[field][0];
                  break;
                default:
              }
            }
            this.setState(stateError);
          } else {
            this.setState({ error: error.message });
          }
        } else {
          this.setState({ error: error.message });
        }
      } else {
        this.setState({
          success: this.props.success.message,
          name: "",
          email: "",
          company: "",
        });
      }
    }

    if (this.props.fetchOrgMetaRequestDataInprogress === false && prevProps.fetchOrgMetaRequestDataInprogress) {
      if (this.props.fetchOrgMetaRequestDataFailure === null) {
        const vendor_name = this.props.fetchOrgMetaRequestDataSuccess?.data?.vendor_name;
        const vendor_contact_name = this.props.fetchOrgMetaRequestDataSuccess?.data?.vendor_contact_name;
        const vendor_contact_email = this.props.fetchOrgMetaRequestDataSuccess?.data?.vendor_contact_email;
        const org_name = this.props.fetchOrgMetaRequestDataSuccess?.data?.org_name;
        const entity = this.props.fetchOrgMetaRequestDataSuccess?.data?.entity;
        const entity_name = entity?.name;
        console.log(entity_name, "entity_name");
        this.setState({
          name: vendor_contact_name,
          email: vendor_contact_email,
          company: vendor_name,
          org_name: org_name,
          entity_name: entity_name,
        });
      }
    }
  }

  getRequestData = (datas, type) => {
    let entityData = datas.filter((o) => o.Key === type)[0]?.Value;
    return entityData;
  };

  clearError() {
    this.setState({
      name_error: "",
      email_error: "",
      company_error: "",
      success: "",
      error: "",
    });
  }

  registerUser() {
    var me = this;
    me.clearError();
    var name = me.state.name;
    if (name == null || String(name).trim().length === 0) {
      me.setState({ name_error: "Please provide your name." });
      return;
    }

    var email = me.state.email;
    if (email == null || String(email).trim().length === 0) {
      me.setState({ email_error: "Please provide your email." });
      return;
    }

    if (Validator.validateEmail(email) === false) {
      me.setState({ email_error: "Please provide valid email." });
      return;
    }

    var company = me.state.company;
    if (company == null || String(company).trim().length === 0) {
      me.setState({ company_error: "Please provide your company detail." });
      return;
    }

    me.props.register(name, email, company, me.state.oid, me.state.rid);
  }

  loginWithGoogle() {
    var url = Environment.api_host("USER");
    window.location = url + "/cognito-login/google";
  }

  render() {
    var classes = this.props.classes;

    const { rid, oid, org_name, entity_name } = this.state;
    return ''
    return (
      <AuthContainer>
        <div className={classes.registerForm}>
          <Image src={Logo} alt="logo" />
          <div className={classes.caption}>
            <Typography variant={"h5"} className={classes.heading}>
              Expent
            </Typography>
            <Typography className={classes.subtitle}>Create your Account!</Typography>
          </div>
          <div>
            {this.state.error.length > 0 && (
              <Alert variant="filled" severity="error">
                {this.state.error}
              </Alert>
            )}
            {this.state.success.length > 0 && (
              <Alert variant="filled" severity="success">
                {this.state.success}
              </Alert>
            )}
          </div>
          {rid === null && oid === null && (
            <>
              <div className={classes.socialFormAction}>
                {
                  <Button variant="contained" fullWidth color="secondary" className={classes.socialBtn} onClick={this.loginWithGoogle}>
                    <Image src={GoogleButton} alt="Google Button" />
                    Signup With Google
                  </Button>
                }
              </div>
              <div className={classes.orRule}>
                <h2>
                  <span>or</span>
                </h2>
              </div>
            </>
          )}

          {rid !== null && oid !== null && (
            <div>
              <p className={classes.termText}>
                {org_name} have requested your profile for {entity_name}.
              </p>
            </div>
          )}

          <div className={classes.form}>
            <OutlinedInput
              id={"nameInput"}
              label="Name"
              value={this.state.name}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
              error={this.state.name_error.length > 0}
              helperText={this.state.name_error}
            />
            <OutlinedInput
              id={"emailIdInput"}
              label="Email"
              value={this.state.email}
              onChange={(event) => {
                this.setState({ email: event.target.value });
              }}
              error={this.state.email_error.length > 0}
              helperText={this.state.email_error}
            />
            <OutlinedInput
              id={"companyInput"}
              label="Company"
              value={this.state.company}
              onChange={(event) => {
                this.setState({ company: event.target.value });
              }}
              error={this.state.company_error.length > 0}
              helperText={this.state.company_error}
            />
          </div>

          <div className={classes.formAction}>
            {!this.props.isProgress && (
              <Button variant="contained" color="secondary" className={classes.button} onClick={this.registerUser}>
                Signup
              </Button>
            )}
            {this.props.isProgress && <CircularProgress />}
            <p className={classes.loginText}>
              <Link to="/" className={classes.termLink}>
                Tap to Login
              </Link>
            </p>
          </div>
          <div>
            <p className={classes.termText}>
              By clicking Signup, you agree to our{" "}
              <a className={classes.termLink} href="https://www.expent.ai/terms" target="_blank" rel="noopener noreferrer">
                General Terms of Use
              </a>{" "}
              and you acknowledge our{" "}
              <a className={classes.termLink} href="https://www.expent.ai/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Statement
              </a>
              .
            </p>
          </div>
        </div>
      </AuthContainer>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Register));
