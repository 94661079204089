import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { InputLabel, Typography } from '@material-ui/core';

const connectedProps = (state) => ({
    user:state.authUser.user,
});

const connectionActions = {
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    "profileContainer": {

    },
    "profileInfoCard": {
        width: "50%",
        margin: "0 auto",
        borderRadius: 0
    },
    "profileInfoTable": {
        width: "100%",
        borderCollapse: "collapse",
        border: "none"
    },
    "profileInfoTableRow": {
        border: "solid 1px #ccc"
    },
    "profileInfoTableCelLeft": {
        borderRight: "solid 1px #ccc",
        padding: theme.spacing(1),
        width: "50%"
    },
    "profileInfoTableCelRight": {
        padding: theme.spacing(1),
        width: "50%"
    },
    content: {
        margin: "0 auto",
        textAlign: "center",
        padding: theme.spacing(4)
    },
    action: {
        marginTop: theme.spacing(4),
    },
    userAttr: {
        padding: theme.spacing(2.6, 4)
    },
    userLabel: {
        marginBottom: theme.spacing(1)
    },
    imageContainer: {
        display: "flex"
    },
    userAction: {
        textAlign: "center",
        flex: 1
    },
    userActionBtn: {
        margin: theme.spacing(0.5),
        cursor: "pointer"
    },
    alert:{
        marginBottom:theme.spacing(1)
    },
});

class AccountSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

    
    getNameFromRole(role){
      switch(role){
          case "OrgAdmin":
              return "Admin";
          case "OrgManager":
              return "Manager";
          case "OrgUser":
              return "User";
          default: 
              return "";
      }
  }

    render() {
       
        const classes = this.props.classes;
        return <table className={classes.profileInfoTable} >
                  {/* <tr className={classes.profileInfoTableRow}>
                      <td className={classes.profileInfoTableCelLeft}>
                          <div className={classes.userAttr}>
                              <div className={classes.imageContainer}>
                                  <div className={classes.userImage}>
                                      <Avatar alt={"User Image"} src={DefaultUser} />
                                  </div>
                                  <div className={classes.userAction}>
                                      <Image src={EditImage} alt="edit" className={classes.userActionBtn} />
                                      <Image src={RemoveImage} alt="remove" className={classes.userActionBtn} />
                                  </div>
                              </div>
                          </div>
                      </td>
                      <td className={classes.profileInfoTableCelLeft}>
                          <div className={classes.userAttr}>
                              <InputLabel className={classes.userLabel}>NAME</InputLabel>
                              <Typography>Arivoli Tamilarasan</Typography>
                          </div>
                      </td>
                  </tr> */}
                  <tr className={classes.profileInfoTableRow}>
                      <td className={classes.profileInfoTableCelLeft}>
                          <div className={classes.userAttr}>
                              <InputLabel className={classes.userLabel}>Name</InputLabel>
                              <Typography>{this.props.user.Name}</Typography>
                          </div>
                      </td>
                      <td className={classes.profileInfoTableCelRight}>
                          <div className={classes.userAttr}>
                              <InputLabel className={classes.userLabel}>Email</InputLabel>
                              <Typography>{this.props.user.Email}</Typography>
                          </div>
                      </td>
                  </tr>
                  <tr className={classes.profileInfoTableRow}>
                      <td className={classes.profileInfoTableCelLeft}>
                          <div className={classes.userAttr}>
                              <InputLabel className={classes.userLabel}>Role</InputLabel>
                              <Typography>{this.getNameFromRole(this.props.user.Role)}</Typography>
                          </div>
                      </td>
                      <td className={classes.profileInfoTableCelRight}>
                          <div className={classes.userAttr}>
                              <InputLabel className={classes.userLabel}>Team</InputLabel>
                              <Typography>{this.props.user.Team == null || String(this.props.user.Team).trim().length == 0?"-":String(this.props.user.Team).trim()}</Typography>
                          </div>
                      </td>
                  </tr>
                </table>
    }
}

export default connector(compose(
    withStyles(styles)
)(AccountSettings));