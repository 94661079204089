import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppBarV2 from "../../../../Application/AppBarV2";
import CommonCss from "commonCss";
import TablePagination from "@material-ui/core/TablePagination";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import ReactSelect from "react-select";
import Requirement from "./Components/Requirement";
import { fetchSellerLibrary } from "redux/seller/evaluate/action";
import { addReqToSellerLibrary } from "redux/seller/evaluate/action";
import { showSnackBar } from "redux/snackbar/action";
import MessageDialog from "Components/Common/MessageDialog";
import { showMessageDialog, hideMessageDialog } from "redux/applications/action";
import { showImportAssessmentDialog } from "redux/seller/assessment/action";
// import ImportLibraryDialog from "../Dialogs/ImportLibraryDialog";
import CreateQuestionDialog from "../Dialogs/CreateQuestionDialog";
import ImportAssessmentDialog from "Components/SellerApplication/Dialogs/ImportAssesmentDialog";

const connectedProps = (state) => ({
  fetchSellerLibraryProgress: state.sellerEvaluate.fetchSellerLibraryProgress,
  fetchSellerLibraryError: state.sellerEvaluate.fetchSellerLibraryError,
  sellerLibrary: state.sellerEvaluate.sellerLibrary,
  addReqToSellerLibraryProgress: state.sellerEvaluate.addReqToSellerLibraryProgress,
  addReqToSellerLibraryError: state.sellerEvaluate.addReqToSellerLibraryError,
  sellerLibraryAddData: state.sellerEvaluate.sellerLibraryAddData,
  isOpenImportDialog: state.sellerLibrary.importLibraryDialogOpen,
  sellerProducts: state.sellerProduct.sellerProducts,
  sellerProductProg: state.sellerProduct.sellerProductProg,
  importToSellerLibraryProgress: state.sellerEvaluate.importToSellerLibraryProgress,
  importToSellerLibraryError: state.sellerEvaluate.importToSellerLibraryError,
  sellerLibraryImportData: state.sellerEvaluate.sellerLibraryImportData,
  importAssessmentDialogOpen: state.sellerAssessment.importAssessmentDialogOpen,
});

const connectionActions = {
  fetchSellerLibrary: fetchSellerLibrary,
  addReqToSellerLibrary: addReqToSellerLibrary,
  showSnackBar: showSnackBar,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  showImportAssessmentDialog: showImportAssessmentDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    // rootContainer: CommonCss.rootContainer,
    headerWrap: {
      padding: "2em 5em",
      paddingBottom: "0rem",
      marginTop: "0px !important",
      position: "sticky",
      zIndex: 999,
      top: 0,
      background: "#f7f7f7",
    },
    headTitle: {
      fontSize: 19,
      fontWeight: 500,
      marginBottom: 20,
    },
    filterTitle: {
      color: "#282D30",
      fontWeight: 400,
      marginBottom: 5,
      fontSize: theme.spacing(2.2),
    },
    topHead: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    nameSec: {
      flex: 1,
      marginBottom: 10,
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    menuItem: {
      color: "#74798C",
      fontSize: theme.spacing(2),
      padding: theme.spacing(2.2),
      minWidth: 140,
    },
    actionSec: {
      // marginBottom: 10,
    },
    searchBox: {
      maxWidth: "300px",
      minWidth: "250px",
    },
    paginationWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    libraryWrap: {
      padding: "0rem 4.5rem",
    },
  });

class Library extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerLibrary: [],
      activeRequirement: null,
      deleteObj: null,
      anchorEl: null,
      newOptions: [
        { url: "", label: "Add Manually", key: "addManually", addManually: true },
        { url: "", key: "importLibrary", label: "Import Library" },
      ],
      isOpenCreateQuesDialog: false,
      loading: true,
      productOptions: [
        {
          label: "Global",
          value: "global",
        },
      ],
      selectedProduct: null,
      page: 0,
      rowsPerPage: 25,
    };

    this.handleDeleteAll = this.handleDeleteAll.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    let filters = {
      source: "library",
    };
    if (this.state.selectedProduct) {
      if (this.state.selectedProduct?.value === "global") {
        filters["is_global"] = "1";
      } else {
        filters["product_id"] = this.state.selectedProduct?.value;
      }
    }
    this.props.fetchSellerLibrary({
      filters: filters,
      page: this.state.page,
      page_size: this.state.rowsPerPage,
    });
  };

  componentDidUpdate(prevProps) {
    if (!this.props.sellerProductProg && prevProps.sellerProductProg) {
      if (this.props.sellerProducts && this.props.sellerProducts.Data && this.props.sellerProducts.Data.length > 0) {
        let newOptions = [...this.state.productOptions];
        this.props.sellerProducts?.Data.forEach((o) => {
          newOptions.push({
            label: o.Name,
            value: o.ID,
          });
        });
        this.setState({ productOptions: newOptions });
      }
    }

    if (!this.props.fetchSellerLibraryProgress && prevProps.fetchSellerLibraryProgress) {
      if (this.props.fetchSellerLibraryError === null) {
        if (this.props.sellerLibrary && this.props.sellerLibrary.data) {
          this.setState({
            sellerLibrary: this.props.sellerLibrary.data,
            loading: false,
          });
        }
      }
    }

    if (!this.props.addReqToSellerLibraryProgress && prevProps.addReqToSellerLibraryProgress) {
      if (this.props.addReqToSellerLibraryError === null) {
        this.fetchData();
        this.props.showSnackBar("Library updated successfully", "success", 3000);
        this.setState({
          // activeRequirement: null,
          deleteObj: null,
        });
      } else {
        console.warn("Something went wrong");
      }
    }

    if (!this.props.importToSellerLibraryProgress && prevProps.importToSellerLibraryProgress) {
      if (this.props.importToSellerLibraryError === null) {
        this.props.fetchSellerLibrary({
          actions: [{}],
          filters: {
            source: "library",
          },
        });
        this.props.showSnackBar("Library updated successfully", "success", 3000);
      } else {
        console.warn("Something went wrong");
      }
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDeleteAll() {
    let deleteIds = this.props.sellerLibrary?.data?.map((o) => o.question.id);
    this.props.addReqToSellerLibrary({
      action: "delete",
      entity_type: "question",
      ids: deleteIds,
    });
  }

  handleDelete(deleteObj) {
    this.setState({ deleteObj: deleteObj }, () => {
      this.props.showMessageDialog();
    });
  }

  callBack = () => {
    if (this.state.deleteObj) {
      this.props.hideMessageDialog();
      if (this.state.deleteObj.type === "QUESTION") {
        this.props.addReqToSellerLibrary({
          action: "delete",
          entity_type: "question",
          ids: [this.state.deleteObj.id],
        });
      } else if (this.state.deleteObj.type === "ANSWER") {
        this.props.addReqToSellerLibrary({
          action: "delete",
          entity_type: "answer",
          question_id: this.state.deleteObj?.questionId,
          ids: [this.state.deleteObj.id],
        });
      } else {
        this.props.showSnackBar("Something went wrong", "error", 3000);
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage, loading: true }, () => {
      this.fetchData();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10), loading: true }, () => {
      this.fetchData();
    });
  };

  render() {
    const classes = this.props.classes;
    const { sellerLibrary, loading } = this.state;

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <div classes={classes.libraryRoot}>
        <AppBarV2 title="Library" withBack={true} />
        <div className={classes.rootContainer}>
          <div className={classes.headerWrap}>
            <div className={classes.topHead}>
              <div className={classes.nameSec}>
                <div variant="h5" className={classes.searchBox}>
                  <Typography variant={"h4"} className={classes.filterTitle}>
                    Filter by Product
                  </Typography>
                  <ReactSelect
                    isSearchable={true}
                    isClearable
                    options={this.state.productOptions}
                    classNamePrefix="select"
                    placeholder="Search Product"
                    onChange={(e) => {
                      this.setState({ selectedProduct: e, loading: true, page: 0 }, () => {
                        this.fetchData();
                      });
                    }}
                    menuPortalTarget={document.getElementById("evRuleDialog")}
                    value={this.state.selectedProduct}
                  />
                </div>
              </div>
              <div className={classes.actionSec}>
                <div>
                  <Button className={classes.newUserBtn} style={{ marginRight: 10 }} onClick={this.handleDeleteAll}>
                    Delete All
                  </Button>

                  <Button
                    className={classes.newUserBtn}
                    style={{ marginRight: 10 }}
                    onClick={() =>
                      this.setState({
                        isOpenCreateQuesDialog: true,
                      })
                    }
                  >
                    + New Question
                  </Button>

                  <Button className={classes.newUserBtn} onClick={() => this.props.showImportAssessmentDialog("library")}>
                    Import Library
                  </Button>
                </div>
              </div>
            </div>
            {this.props.sellerLibrary?.total > this.state.rowsPerPage && (
              <div className={classes.paginationWrap}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={this.props.sellerLibrary?.total}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </div>
            )}
          </div>
          <div className={classes.libraryWrap}>
            {!loading &&
              sellerLibrary &&
              sellerLibrary.map((req, index) => {
                // if(req?.ref_requirement_id === null){
                //   return null
                // }
                return (
                  <Requirement
                    data={req}
                    reqIndex={index}
                    toggleRequirement={(id) => this.setState({ activeRequirement: id })}
                    isOpen={this.state.activeRequirement === index}
                    handleDelete={(deleteObj) => {
                      this.handleDelete(deleteObj);
                    }}
                  />
                );
              })}
            {!loading && sellerLibrary && sellerLibrary.length <= 0 && (
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <p>No Questions</p>
              </div>
            )}

            {loading && (
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
        {/* {this.props.isOpenImportDialog && <ImportLibraryDialog />} */}
        {this.state.isOpenCreateQuesDialog && (
          <CreateQuestionDialog
            isOpen={this.state.isOpenCreateQuesDialog}
            hideDialog={() => {
              this.setState({ isOpenCreateQuesDialog: false });
            }}
          />
        )}
        <MessageDialog messageData={messageData} callBack={this.callBack} />
        {this.props.importAssessmentDialogOpen && <ImportAssessmentDialog isFromLibrary={true} type={'library'} />}
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Library));
