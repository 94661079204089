import {
  Avatar, Button, Dialog, DialogContent, InputBase, List, ListItem, ListItemAvatar,
  ListItemText, MenuItem, Slide, Typography, Checkbox, FormControl, Select
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import { createStyles, withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import classnames from "classnames";
import AddUserDialog from "Components/Common/AddUserDialog.jsx";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
// redux
import { showAddTeamDialog } from "redux/okta/action";
import { hideEvaluationCollaboratorDialog } from "redux/evaluate/action";
import { fetchApprovalCriteria, removeApprovalCriteriaApprover, addApprovalCriteriaApprover } from "redux/evaluation/approval/action";
import { showSnackBar } from "redux/snackbar/action";
import { searchUser, searchUserTeam } from "redux/usersearch/action";
// import _ from "underscore";

import { showNudgeNotificationDialog } from "redux/notification/action";
import Radio from "@material-ui/core/Radio";
import RadioGroup from '@material-ui/core/RadioGroup';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  progress: state.userSearch.searchProgress,
  searchUserTeamProgress: state.userSearch.searchUserTeamProgress,
  searchUserTeamError: state.userSearch.searchUserTeamError,
  users: state.userSearch.users,
  usersAndTeams: state.userSearch.usersAndTeams,
  addTeamDialogOpen: state.okta.addTeamDialogOpen,
  open: state.evaluate.evaluationCollaboratorDialogOpen,
  criterias: state.evaludationCriteria.criterias,
  evaluation: state.evaluationMetaData.evaluation,
});

const connectionActions = {
  searchUser: searchUser,
  searchUserTeam: searchUserTeam,
  showSnackBar: showSnackBar,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
  showAddTeamDialog: showAddTeamDialog,
  showNudgeNotificationDialog: showNudgeNotificationDialog,
  fetchApprovalCriteria: fetchApprovalCriteria,
  removeApprovalCriteriaApprover: removeApprovalCriteriaApprover,
  addApprovalCriteriaApprover: addApprovalCriteriaApprover,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    collaboratorContainer: {
      minWidth: "310px",
      "& ul": {
        padding: 0,
      },
      "& ul.answers": {
        listStyle: "none",
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 0,
        "& li": {
          bordeRadius: "4px",
          flex: 1,
          paddingLeft: "8px",
          marginBottom: "8px",
          "& span": {
            fontSize: 13,
            color: "#6D7278",
          },
        },
      },
    },
    collaboratorHeader: {
      marginTop: 0,
      paddingBottom: 0,
    },
    searchCol: {
      paddingTop: "16px",
      paddingBottom: "16px",
      "& span": {
        textAlign: "left",
        fontSize: 12,
        color: "#202124",
        float: "right",
      },
    },
    title: {
      textAlign: "left",
      fontWeight: 400,
      color: "#20253A",
      display: "flex",
      placeItems: "center",
    },
    input: {
      padding: theme.spacing(0.5, 2),
      fontSize: ".875rem",
      marginRight: 15,
      background: "#f8f9fa",
      width: "100%",
      border: "0px",
      borderBottom: "1px solid #4b86f7",
      outline: "1px solid transparent",
      borderRadius: "6px 6px 0 0",
      minHeight: 27,
    },
    listItem: {
      borderBottom: "1px solid #EEEEEE",
      padding: "10px 0px",
      "&:last-child": {
        borderBottom: "unset",
      },
    },
    avatar: {
      width: "2.25rem",
      height: "2.25rem",
      fontSize: "1.125rem",
    },
    userEmail: {
      fontSize: "13px",
      color: "#4B4B4B",
    },
    userEmailText: {
      cursor: "pointer",
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      flex: 0.8,
      placeItems: "start",
      "& svg": {
        position: "relative",
        left: "5px",
        marginRight: 10,
      },
    },
    userState: {
      position: "relative",
      display: "inline-block",
      width: "40px",
      height: "12px",
      background: "#DDDDDD",
      opacity: 1,
      borderRadius: "20px",
      cursor: "pointer",
      "&::after": {
        content: "''",
        height: "20px",
        width: "20px",
        display: "inline-block",
        position: "absolute",
        background: " #DDDDDD",
        border: " 5px solid #A0A5BA",
        borderRadius: "50%",
        top: "-4px",
        left: "0px",
      },
      "&.active": {
        "&::after": {
          background: "#81A5F2",
          borderColor: "#4175DF",
          left: "20px",
        },
      },
      "&.inprogress": {
        cursor: "not-allowed",
      },
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#ffffff !important",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      marginLeft: "10px",
      "&:hover": {
        background: "#3C3C3C",
        color: "#ffffff !important",
      },
    },
    cancelBtn: {
      background: "#3C3C3C !important",
      borderRadius: 5,
      color: "#ffffff !important",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 80,
      marginRight: "20px",
      "&:hover": {
        background: "#3C3C3C",
        color: "#ffffff !important",
      },
    },
    shareIcon: {
      background: "#4175DF",
      color: "#ffffff",
      width: "40px",
      height: "40px",
      display: "inline-block",
      borderRadius: "50%",
      textAlign: "center",
      lineHeight: "50px",
      marginRight: "10px",
      "& svg": {
        marginTop: "6px",
      },
    },
    btn: {
      '&:disabled': {
        color: '#fff',
        opacity: 0.5
      }
    },
    footerAction: {
      padding: "0 20px 20px 20px",
      textAlign: "right",
      "& button": {
        backgroundColor: "#4175DF",
        color: "white",
      },
      "& button:hover": {
        backgroundColor: "#4175DF",
        color: "white",
      },
    },
    criteriaPriority: {
      minHeight: "25px !important",
      padding: 3,
      border: "1px solid #c4c4c4",
      borderRadius: 4,
      color: "#5F5F5F",
      fontSize: "1.1em",
      marginLeft: 4,
      width: 40,
      textAlign: "center",
    },
    notificationIcon: {
      fontSize: 24,
      marginTop: 5,
      color: "#4b86f8",
      cursor: "pointer",
    },
    nudgeBtn: {
      border: "1px solid #bda8a8",
      color: "#1f73b7",
      padding: 0,
      marginRight: '15px'
    },
    formControlSel: {
      width: 200,
    },
    managerLevel: {
      paddingLeft: 20
    }
  });

class SearchUserAndTeamPoper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      user: null,
      editValue: {},
      showActionMenu: null,
      showActionId: null,
      selectedUsers: [],
      allUsersAndTeams: [],
      isChanged: false,
      manager_approval: false,
      department_approval: false,
      approvalTypeOptions: [{
        label: 'Manager Approval',
        value: 'manager_approval'
      }, {
        label: 'Department Approval',
        value: 'department_approval'
      }],
      managerLevelOptions: [1, 2, 3, 4, 5],
      selectedOptions: [],
      manager_level: 1
    };
    this.avatarName = this.avatarName.bind(this);
  }

  componentDidMount() {
    this.setState({ query: "", user: null });
    let selectedOptions = [];
    if (this.props.manager_approval) {
      selectedOptions.push('manager_approval')
    }
    if (this.props.department_approval) {
      selectedOptions.push('department_approval')
    }
    this.setState({
      selectedUsers: this.props.selectedUser,
      manager_approval: this.props.manager_approval,
      department_approval: this.props.department_approval,
      manager_level: this.props.manager_level,
      selectedOptions: selectedOptions
    });
  }

  setUsersAndTeamsData = () => {
    if (this.props.usersAndTeams) {
      let usersAndTeamsData = [];
      if (this.props.usersAndTeams.Users) {
        this.props.usersAndTeams.Users.forEach(item => {
          item.type = 'USER';
          usersAndTeamsData.push(item);
        })
      }
      if (this.props.usersAndTeams.Groups) {
        this.props.usersAndTeams.Groups.forEach(item => {
          item.type = 'GROUP';
          usersAndTeamsData.push(item);
        })
      }
      this.setState({ allUsersAndTeams: usersAndTeamsData })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchUserTeamError && !this.props.searchUserTeamError) {
      this.setUsersAndTeamsData();
    }
    if (prevProps.searchUserTeamProgress && !this.props.searchUserTeamProgress) {
      this.setUsersAndTeamsData();
    }
  }

  checkUserState = (User) => {
    let criterias = this.props.criterias?.filter((item) => item.id === parseInt(this.props.criteriaId)) || [];

    let result = [];
    result = criterias.length > 0 ? criterias[0].colloborator.filter((item) => item.ID === User.ID) : [];

    if (result.length) return result[0];
    return false;
  };

  avatarName(name) {
    return name.substring(0, 1);
  }

  CreateNewUser = () => {
    this.setState(
      {
        editValue: { email: this.state.query },
      },
      () => {
        this.props.showAddTeamDialog();
      }
    );
  };

  handleActionClick = (event, id) => {
    this.setState({
      showActionMenu: event.currentTarget,
      showActionId: id,
    });
  };

  handleActionClose = (user, type) => {
    let users = [...this.state.selectedUsers];

    if (type === "ADD") {
      users.push(user);
    } else if (type === "REMOVE") {
      users = users.filter((o) => {
        let result = true;
        if (o.type === user.type && o.ID === user.ID) {
          result = false
        }
        return result;
      });
    }
    this.setState({ selectedUsers: users });
    this.setState({
      showActionMenu: null,
      showActionId: null,
      isChanged: true,
    });
  };

  setSelectedOption(options) {
    let manager_approval = false;
    let department_approval = false;
    if (options.indexOf('manager_approval') !== -1) {
      manager_approval = true;
    }
    if (options.indexOf('department_approval') !== -1) {
      department_approval = true;
    }
    this.setState({
      selectedOptions: options,
      manager_approval: manager_approval,
      department_approval: department_approval,
      isChanged: true
    })
  }

  render() {
    const classes = this.props.classes;

    let search_users_and_teams = this.state.allUsersAndTeams || [];

    let users = [...this.props.selectedUser];

    let users_list = this.state.query === "" ? users : search_users_and_teams;
    // users_list = _.uniq(users_list, "ID");
    const checkIsExistUser = (id) => {
      if (this.state.selectedUsers.filter((user) => user.ID === id).length > 0) {
        return true;
      } else {
        return false;
      }
    };

    let showNotificationIcon = false;
    if (this.props.showNotificationIcon !== undefined && this.props.showNotificationIcon === true) {
      showNotificationIcon = true;
    }

    return (
      <>
        <Dialog fullWidth maxWidth={"md"} open={this.props.open} onClose={this.props.hideEvaluationCollaboratorDialog} aria-labelledby="add-collaborator-dialog" TransitionComponent={Transition} className={classnames(classes.dialog)}>
          <DialogContent>
            <div className={classes.collaboratorContainer}>
              <div className={classes.collaboratorHeader}>
                <Typography className={classes.title}>
                  <span className={classes.shareIcon}>
                    <GroupAddOutlinedIcon />
                  </span>{" "}
                  Add users and teams
                </Typography>

                {this.props.isFromApprovalConfiguration && <div className={classes.chkApproverType}>
                  <ul className={"answers"}>
                    {this.state.approvalTypeOptions.map((item, i) => {
                      return <>
                        <li key={i}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              boxSizing: "border-box",
                              placeContent: "center flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              id={item.value}
                              checked={this.state.selectedOptions.indexOf(item.value) !== -1}
                              name={item.value}
                              color="primary"
                              onChange={(e) => {
                                let itemId = item.value;
                                let selectedOptions = this.state.selectedOptions || []
                                if (selectedOptions.indexOf(itemId) !== -1) {
                                  selectedOptions.splice(
                                    selectedOptions.indexOf(itemId),
                                    1
                                  );
                                } else {
                                  selectedOptions.push(itemId);
                                }
                                this.setSelectedOption(selectedOptions, true);
                              }}
                            />
                            <label htmlFor={item.value}>{item.label}</label>
                          </div>
                        </li>
                      </>
                    })}
                  </ul>

                  {this.state.selectedOptions && this.state.selectedOptions.length > 0 && this.state.selectedOptions.indexOf('manager_approval') !== -1 && <div className={classes.managerLevel}>
                    <Typography>Manager Level</Typography>
                    <FormControl variant="outlined" className={classes.formControlSel} size="small">
                      <Select value={this.state.manager_level} onChange={(e) => {
                        this.setState({
                          manager_level: e.target.value,
                          isChanged: true
                        })
                      }}>
                        {this.state.managerLevelOptions.map((tri) => {
                          return <MenuItem value={tri} style={{ fontSize: 13 }}>{tri}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </div>}

                </div>}

                <div className={classes.searchCol}>
                  <InputBase
                    className={classes.input}
                    placeholder={"Search Email"}
                    onChange={(event) => {
                      this.setState(
                        {
                          query: event.target.value,
                        },
                        () => {
                          this.props.searchUserTeam(this.state.query);
                        }
                      );
                    }}
                  />
                </div>
              </div>

              {users_list !== null && users_list.length > 0 && (
                <List dense={true}>
                  {users_list.map((user, k) => {
                    return (
                      <ListItem key={k} className={classes.listItem}>
                        <ListItemAvatar className={classes.listAvatar}>
                          <Avatar className={classes.avatar}>{this.avatarName(user.Name)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <div style={{ display: "flex", placeContent: "center space-between", alignItems: "center" }}>
                              <span className={classes.userEmailText}>
                                <span style={{ fontWeight: 500, color: "#202124" }}>{user.Name}</span>
                                <span style={{ color: "#5f6368" }}>{user.Email}</span>
                              </span>

                              <Button onClick={(e) => this.handleActionClick(e, user.ID)} aria-controls="simple-menu" aria-haspopup="true">
                                {checkIsExistUser(user.ID) && this.props.selectText}
                                {!checkIsExistUser(user.ID) && "Action"}
                                <ArrowDropDownIcon />
                              </Button>

                              {checkIsExistUser(user.ID) && showNotificationIcon && (
                                <div>
                                  <Button
                                    onClick={(event) => {
                                      this.props.showNudgeNotificationDialog(user, this.props.notificationData);
                                    }}
                                    className={classes.nudgeBtn}
                                    type="button"
                                  >
                                    Nudge
                                  </Button>
                                </div>
                              )}

                              <Menu
                                id="simple-menu"
                                anchorEl={this.state.showActionMenu}
                                keepMounted
                                open={Boolean(this.state.showActionId === user.ID)}
                                onClose={() => {
                                  this.handleActionClose();
                                }}
                              >
                                {!checkIsExistUser(user.ID) && (
                                  <MenuItem
                                    value={20}
                                    onClick={() => {
                                      this.handleActionClose(user, "ADD");
                                    }}
                                  >
                                    {this.props.selectText}
                                  </MenuItem>
                                )}

                                {checkIsExistUser(user.ID) && (
                                  <MenuItem
                                    value={30}
                                    onClick={() => {
                                      this.handleActionClose(user, "REMOVE");
                                    }}
                                  >
                                    Remove
                                  </MenuItem>
                                )}
                              </Menu>
                            </div>
                          }
                          className={classnames(classes.userEmail, this.checkUserState(user) ? classes.isMember : "")}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
              {this.state.query !== "" && !this.props.searchUserTeamProgress && !this.props.searchUserTeamError && this.state.allUsersAndTeams.length === 0 && (
                <span style={{ display: "block", textAlign: "center", padding: "50px 20px 10px", marginBottom: "12px" }}>
                  User and Team not found
                  {/* <Button
                    className={classes.newUserBtn}
                    onClick={() => {
                      this.CreateNewUser();
                    }}
                  >
                    + Add user
                  </Button> */}
                </span>
              )}
            </div>
            {this.props.addTeamDialogOpen && <AddUserDialog isFromSettings={true} isEdit={false} editValue={this.state.editValue} />}
          </DialogContent>
          <div className={classes.footerAction}>
            <Button
              className={classes.cancelBtn}
              onClick={() => {
                this.props.hideEvaluationCollaboratorDialog();
              }}
            >
              Cancel
            </Button>
            {this.props.btnLoading ? (
              <Button className={classes.btn} style={{ minWidth: 80 }}>
                Loading...
              </Button>
            ) : (
              <Button
                className={classes.btn}
                disabled={!this.state.isChanged}
                onClick={() => {
                  if (this.props.isFromApprovalConfiguration) {
                    this.props.onSubmit(this.state.selectedUsers, this.state.manager_approval, this.state.department_approval, this.state.manager_level);
                  } else {
                    this.props.onSubmit(this.state.selectedUsers, parseInt(this.props.criteriaId), true);
                  }
                }}
                style={{ minWidth: 80 }}
              >
                Done
              </Button>
            )}
          </div>
        </Dialog>
      </>
    );
  }
}

SearchUserAndTeamPoper.defaultProps = {
  searchTitle: "",
  selectedUser: [],
  criterias: [],
  selectText: 'Approver',
  isFromApprovalConfiguration: false,
  manager_approval: false,
  department_approval: false
};

export default connector(compose(withStyles(styles), withRouter)(SearchUserAndTeamPoper));