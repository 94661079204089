import {
    START_DELETE_PRODUCT_RISK, END_DELETE_PRODUCT_RISK,
    START_FETCH_PRODUCT_RISKS, END_FETCH_PRODUCT_RISKS,
    SHOW_RISK_UPDATE_SCORE_DIALOG, HIDE_RISK_UPDATE_SCORE_DIALOG,
    START_FETCH_PRODUCT_RISK_SCORE_UPDATE, END_FETCH_PRODUCT_RISK_SCORE_UPDATE,
    START_FETCH_PRODUCT_RISK_ADD, END_FETCH_PRODUCT_RISK_ADD,
    START_FETCH_PRODUCT_RISK_UPDATE, END_FETCH_PRODUCT_RISK_UPDATE,
    START_FETCH_PRODUCT_RISK_BY_ID, END_FETCH_PRODUCT_RISK_BY_ID
} from "./action";

const initState = {

    fetchProductRisksProgress: false,
    fetchProductRisksError: null,
    productRisks: [],

    deleteProductRiskProgress: false,
    deleteProductRiskError: null,

    showUpdateScoreDialog: false,
    selectedRiskForUpdateScore: null,

    updateProductScoreProgess: false,
    updateProductScoreError: null,
    productRiskScore: null,

    addProductRiskProgess: false,
    addProductRiskError: null,
    addProductRiskSuccess: null,

    updateProductRiskProgess: false,
    updateProductRiskError: null,
    updateProductRiskSuccess: null,

    fetchRiskDetailsProgess: false,
    fetchRiskDetailsError: null,
    riskDetails: null,
}

export function riskReducer(state = initState, action) {
    switch (action.type) {



        case START_FETCH_PRODUCT_RISK_BY_ID:
            return {
                ...state,
                fetchRiskDetailsProgess: true,
                fetchRiskDetailsError: null,
                riskDetails: null,
            }

        case END_FETCH_PRODUCT_RISK_BY_ID:
            return {
                ...state,
                fetchRiskDetailsProgess: false,
                fetchRiskDetailsError: action.payload.error,
                riskDetails: action.payload.success,
            }

        case START_FETCH_PRODUCT_RISK_SCORE_UPDATE:
            return {
                ...state,
                updateProductScoreProgess: true,
                updateProductScoreError: null,
                productRiskScore: null,
            }

        case END_FETCH_PRODUCT_RISK_SCORE_UPDATE:
            return {
                ...state,
                updateProductScoreProgess: false,
                updateProductScoreError: action.payload.error,
                productRiskScore: action.payload.success,
            }


        case START_FETCH_PRODUCT_RISK_ADD:
            return {
                ...state,
                addProductRiskProgess: true,
                addProductRiskError: null,
                addProductRiskSuccess: null,
            }

        case END_FETCH_PRODUCT_RISK_ADD:
            return {
                ...state,
                addProductRiskProgess: false,
                addProductRiskError: action.payload.error,
                addProductRiskSuccess: action.payload.success,
            }

        case START_FETCH_PRODUCT_RISK_UPDATE:
            return {
                ...state,
                updateProductRiskProgess: true,
                updateProductRiskError: null,
                updateProductRiskSuccess: null,
            }

        case END_FETCH_PRODUCT_RISK_UPDATE:
            return {
                ...state,
                updateProductRiskProgess: false,
                updateProductRiskError: action.payload.error,
                updateProductRiskSuccess: action.payload.success,
            }

        case SHOW_RISK_UPDATE_SCORE_DIALOG:
            return {
                ...state,
                showUpdateScoreDialog: true,
                selectedRiskForUpdateScore: action.payload.risk,
            }

        case HIDE_RISK_UPDATE_SCORE_DIALOG:
            return {
                ...state,
                showUpdateScoreDialog: false,
                selectedRiskForUpdateScore: null,
            }

        case START_DELETE_PRODUCT_RISK:
            return {
                ...state,
                deleteProductRiskProgress: true,
                deleteProductRiskError: null,
            }

        case END_DELETE_PRODUCT_RISK:
            return {
                ...state,
                deleteProductRiskProgress: false,
                deleteProductRiskError: action.payload.error,
            }

        case START_FETCH_PRODUCT_RISKS:
            return {
                ...state,
                fetchProductRisksProgress: true,
                fetchProductRisksError: null,
            }
        case END_FETCH_PRODUCT_RISKS:
            // let onlyRiskDatas = state.productRisks;
            // if (action.payload.error === null) {
            //     let successDatasO = action.payload.success;
            //     if (successDatasO.page === 0) {
            //         onlyRiskDatas = successDatasO
            //     } else {
            //         onlyRiskDatas.Data = [...onlyRiskDatas.Data, ...successDatasO.Data]
            //     }
            // }

            return {
                ...state,
                fetchProductRisksProgress: false,
                fetchProductRisksError: action.payload.error,
                productRisks: action.payload.success
            }
        default:
            return state;
    }
}