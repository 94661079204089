import {HttpFactory} from "services/httpService";
import * as Environment from "util/Environment";

import {Types} from './types';


export  function storeClusterGroupProducts(products = []){
  return     {
      type: Types.TEMP_STORE_CLUSTER_GROUPING_PRODUCTS,
      payload:{
        products
      }
  };
}

// cluster products dialog
export  function showClusterProductsDialog(is_new = false, existing_products = []){
  return     {
      type: Types.SHOW_CLUSTER_PRODUCTS_DIALOG,
      payload:{
        is_new,
        existing_products
      }
  };
}

export  function hideClusterProductsDialog(){
  return     {
      type: Types.HIDE_CLUSTER_PRODUCTS_DIALOG,
      payload:{}
  };
}


// Cluster group Dialog
export  function showCreateClusterGroupDialog(is_new = false, group_details = {}){
  return     {
      type: Types.SHOW_CREATE_CLUSTER_DIALOG,
      payload:{
        is_new,
        group_details
      }
  };
}

export  function hideCreateClusterGroupDialog(){
  return     {
      type: Types.HIDE_CREATE_CLUSTER_DIALOG,
      payload:{}
  };
}



/**
 *  Create a Product cluster where we can add multiple products to that cluster 
 */
function startCreateClusterGroup(){
  return {
      type:Types.START_CREATE_PRODUCT_CLUSTER_GROUP,
      payload:{}
  };
}

function endCreateClusterGroup(success,error){
  return {
      type:Types.END_CREATE_PRODUCT_CLUSTER_GROUP,
      payload:{
          success,error
      }
  };
}

export  function fetchCreateProductCluster(data){
  const api_server = Environment.api_host("PRODUCT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/org/products/groups?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startCreateClusterGroup());
      try{
        let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
        // add products into group
        if(response && response.orgProduct.id && data.with_products){
          dispatch(fetchAddProductToClusterGroup(response.orgProduct.id, {product_ids: data.products}))
        }
        // close dialog
        dispatch(hideClusterProductsDialog())
        dispatch(endCreateClusterGroup(response,error));
      } catch(e){
        dispatch(endCreateClusterGroup(null,{
            message:e.message
        }));
        return;
      }
  };
} 

/**
 * Get all product clustering groups
 */
 function startFetchClusterGroups(){
  return {
      type:Types.START_FETCH_PRODUCT_CLUSTER_GROUPS,
      payload:{}
  };
}

function endFetchClusterGroups(success,error){
  return {
      type:Types.END_FETCH_PRODUCT_CLUSTER_GROUPS,
      payload:{
          success,error
      }
  };
}

export  function fetchClusterGroups(){
  const api_server = Environment.api_host("PRODUCT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/org/products/groups?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startFetchClusterGroups());
      try{
        let [response,error]    =   await HttpFactory.instance().getMethod(url);
        dispatch(endFetchClusterGroups(response,error));
      } catch(e){
        dispatch(endFetchClusterGroups(null,{
            message:e.message
        }));
        return;
      }
  };
}

/**
 * Add products to the product clustering group
 */
 function startAddProductToClusterGroup(){
  return {
      type:Types.START_ADD_PRODUCTS_TO_CLUSTER_GROUP,
      payload:{}
  };
}

function endAddProductToClusterGroup(success,error){
  return {
      type:Types.END_ADD_PRODUCTS_TO_CLUSTER_GROUP,
      payload:{
          success,error
      }
  };
}

export  function fetchAddProductToClusterGroup(group_id,body){
  const api_server = Environment.api_host("PRODUCT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/org/products/groups/${group_id}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startAddProductToClusterGroup());
      try{
        let [response,error]    =   await HttpFactory.instance().postMethod(url,body,false);
        dispatch(endAddProductToClusterGroup(response,error));
        // clear array after adding products in to group
        dispatch(storeClusterGroupProducts([]))
        // close dialog
        dispatch(hideCreateClusterGroupDialog())
        // fetcg group details
        dispatch(fetchClusterGroupProducts(group_id));
      } catch(e){
        dispatch(endAddProductToClusterGroup(null,{
            message:e.message
        }));
        return;
      }
  };
} 


/**
 * Get the products of the product clustering group
 */
 function startFetchClusterGroupProducts(){
  return {
      type:Types.START_FETCH_CLUSTER_GROUP_PRODUCTS,
      payload:{}
  };
}

function endFetchClusterGroupProducts(success,error){
  return {
      type:Types.END_FETCH_CLUSTER_GROUP_PRODUCTS,
      payload:{
          success,error
      }
  };
}

export  function fetchClusterGroupProducts(group_id){
  const api_server = Environment.api_host("PRODUCT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/org/products/groups/${group_id}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startFetchClusterGroupProducts());
      try{
        let [response,error]    =   await HttpFactory.instance().getMethod(url,null,false);
        dispatch(endFetchClusterGroupProducts(response,error));
      } catch(e){
        dispatch(endFetchClusterGroupProducts(null,{
            message:e.message
        }));
        return;
      }
  };
}


/**
 * Remove products from the product clustering group
 */
 function startDeleteClusterGroupProduct(){
  return {
      type:Types.START_DELETE_PRODUCT_IN_CLUSTER_GROUP,
      payload:{}
  };
}

function endDeleteClusterGroupProduct(success,error){
  return {
      type:Types.END_DELETE_PRODUCT_IN_CLUSTER_GROUP,
      payload:{
          success,error
      }
  };
}

export  function fetchDeleteClusterGroupProduct(group_id,product_id){
  const api_server = Environment.api_host("PRODUCT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/org/products/groups/${group_id}/product/${product_id}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startDeleteClusterGroupProduct());
      try{
        let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
        dispatch(endDeleteClusterGroupProduct(response,error));
      } catch(e){
        dispatch(endDeleteClusterGroupProduct(null,{
            message:e.message
        }));
        return;
      }
  };
} 



/**
 *  Delete org products group 
 */
 function startDeleteClusterGroup(){
  return {
      type:Types.START_DELETE_PRODUCT_CLUSTER_GROUP,
      payload:{}
  };
}

function endDeleteClusterGroup(success,error){
  return {
      type:Types.END_DELETE_PRODUCT_CLUSTER_GROUP,
      payload:{
          success,error
      }
  };
}

export  function fetchDeleteProductCluster(id){
  const api_server = Environment.api_host("PRODUCT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/org/products/group/${id}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startDeleteClusterGroup());
      try{
        let [response,error]    =   await HttpFactory.instance().deleteMethod(url);
        dispatch(endDeleteClusterGroup(response,error));
        dispatch(fetchClusterGroups())
      } catch(e){
        dispatch(endDeleteClusterGroup(null,{
            message:e.message
        }));
        return;
      }
  };
} 


/**
 *  Update basic group details
 *  PUT: product/org/products/groups/:group_id
 */
 function startUpdateClusterGroup(){
  return {
      type:Types.START_UPDATE_PRODUCT_CLUSTER_GROUP,
      payload:{}
  };
}

function endUpdateClusterGroup(success,error){
  return {
      type:Types.END_UPDATE_PRODUCT_CLUSTER_GROUP,
      payload:{
          success,error
      }
  };
}

export  function fetchUpdateProductCluster(group_id, data){
  const api_server = Environment.api_host("PRODUCT");
  const timestamp     =   (new Date()).getTime();
  const url   =   `${api_server}/org/products/groups/${group_id}?t=${timestamp}`;
  return async (dispatch) => {
      dispatch(startUpdateClusterGroup());
      try{
        let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
        dispatch(endUpdateClusterGroup(response,error));
        dispatch(fetchClusterGroupProducts(group_id));
      } catch(e){
        dispatch(endUpdateClusterGroup(null,{
            message:e.message
        }));
        return;
      }
  };
} 
