import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import AppBarV2 from "Components/Application/AppBarV2";
import SqureButton from 'Components/Common/SqureButton';
import { Grid, IconButton, Checkbox, ListItem, List, ListItemIcon, Popover, Typography } from '@material-ui/core';
import { nodeLabel } from "../WorkFlowV2Components/data";
import ClearIcon from "@material-ui/icons/Clear";
import WorkFlowConfiguration from '../WorkFlowConfiguration';
import { WorkFlowRule } from '../WorkFlowRule';
import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
} from 'react-flow-renderer';
import dagre from 'dagre';

import {
  ArrowDropDown as ArrowDropDownIcon,
} from "@material-ui/icons";
import {
  getMasterWorkflow,
  fetchMasterWorkflowActions,
  fetchMasterWorkflowComponents,
  copyWorkflowMasterToOrg
} from "redux/master/workflow/action";

import { workflowComponents, formComponent } from "redux/master/action";

import { showSnackBar } from "redux/snackbar/action";

import { showMasterComponentDialog, hideMasterComponentDialog } from "redux/evaluation/workflow/action";

import { fetchMasterForms } from "redux/forms/master/action";
import { fetchOrgForms } from "redux/forms/org/action";
import { fetchOrgTemplates } from "redux/templates/evaluation/org/action";
import { fetchMasterTemplates } from "redux/templates/evaluation/master/action";
import { addOrgSettings } from "redux/org/user/action";


const connectedProps = (state) => ({
  masterWorkflow: state.masterWorkflow.masterWorkflow,
  masterWorkflowError: state.masterWorkflow.masterWorkflowError,
  masterWorkflowProgress: state.masterWorkflow.masterWorkflowProgress,

  actions: state.masterWorkflow.actions,
  actionsError: state.masterWorkflow.actionsError,
  actionsProgress: state.masterWorkflow.actionsProgress,

  components: state.masterWorkflow.components,
  componentsError: state.masterWorkflow.componentsError,
  componentsProgress: state.masterWorkflow.componentsProgress,

  workflowComponent: state.master.workflowComponent,
  workflowFormCompList: state.master.workflowFormCompList,

  copyMasterToOrgError: state.masterWorkflow.copyMasterToOrgError,
  copyMasterToOrgProgress: state.masterWorkflow.copyMasterToOrgProgress,
  copyMasterToOrgData: state.masterWorkflow.copyMasterToOrgData,


  isMasterComponentDialogOpen: state.evaluationWorkflow.isMasterComponentDialogOpen,

  fetchAllOrgSettingsSuccess: state.orgUser.fetchAllOrgSettingsSuccess,
  addOrgSettingsInprogress: state.orgUser.addOrgSettingsInprogress,

});

const connectionActions = {
  getMasterWorkflow: getMasterWorkflow,
  fetchMasterWorkflowActions: fetchMasterWorkflowActions,
  fetchMasterWorkflowComponents: fetchMasterWorkflowComponents,
  showSnackBar: showSnackBar,
  workflowComponents: workflowComponents,
  copyWorkflowMasterToOrg: copyWorkflowMasterToOrg,
  formComponent: formComponent,
  showMasterComponentDialog: showMasterComponentDialog,
  hideMasterComponentDialog: hideMasterComponentDialog,
  fetchMasterTemplates: fetchMasterTemplates,
  fetchOrgTemplates: fetchOrgTemplates,
  fetchOrgForms: fetchOrgForms,
  fetchMasterForms: fetchMasterForms,
  addOrgSettings: addOrgSettings
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  formBuilderMain: {
    display: 'flex',
    flex: 1
  },
  flowSection: {
    height: '100vh',
    maxHeight: "calc( 100vh - 120px)",
    "& .draggable": {
      display: 'none',
    },
    "& .decision-rectangle": {
      width: 66,
      height: 66,
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: 11,
      left: 58,
      border: '1px solid #EEEEEE',
      background: '#fff',
      zIndex: -1
    },
    "& .decision-component": {
      position: 'relative',
      zIndex: 1,
      top: 40
    }
  },
  secondarySection: {
    boxShadow: '0px 1px 6px #0000000D',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '32px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    paddingRight: 15,
    borderBottom: '1px solid #f1f1f1',
    background: '#fff'
  },
  workflowBtns: {
    textAlign: 'right'
  },
  elementDetails: {
    width: '100vw',
    background: '#fff'
  },
  componentDetailsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 24px',
  },
  componentDetailsBody: {
    padding: '0px 24px',
  },
  componentDetailsBodyHeader: {
    padding: '20px 24px 0px 24px',
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  componentDetailsSection: {
    position: 'relative',
    overflowY: 'auto',
    height: 'calc(100vh - 100px)',
    marginBottom: 0
  },
  detailText: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    color: '#282D30'
  },
  deleteIcon: {
    cursor: 'pointer',
    width: 16
  },
  saveBtnSection: {
    position: 'absolute',
    bottom: 10,
    right: 12,
    left: 'auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: '#f1f1f1',
    padding: '10px'
  },
});

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 400;
const nodeHeight = 150;

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  if (nodes !== undefined && nodes !== null) {
    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });
  }

  if (edges !== undefined && edges !== null) {
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
  }

  dagre.layout(dagreGraph);

  if (nodes !== undefined && nodes !== null) {
    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? 'left' : 'top';
      node.sourcePosition = isHorizontal ? 'right' : 'bottom';

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });
  }

  return { nodes, edges };
};

class masterWorkflow extends React.Component {
  constructor(props) {
    super(props);
    this.reactFlowWrapper = React.createRef(null);
    this.state = {
      checkFetchCount: 0,
      elements: [],
      reactFlowInstance: null,
      isShowMasterComponent: true,
      componentDetails: {},
      edgeDetails: {},
      isComponentEdit: false,
      selectedStatus: 1,
      selectedComponentName: '',
      formComponentList: [],
      showComponent: true,
      defaultWorkflow: false,
      defaultForReview: false,
      defaultForRenewal:false,
      open: false,
      anchorEl: null,
      isCopyWorkflow: false,
      workflowNodes: [],
      workflowEdges: [],
    }
  }

  componentDidMount() {
    this.props.workflowComponents();
    this.props.formComponent();
    this.props.getMasterWorkflow(this.props.match.params.id);
    this.props.fetchMasterWorkflowActions(this.props.match.params.id);
    this.props.fetchMasterWorkflowComponents(this.props.match.params.id);

    this.props.fetchOrgTemplates(0, 1000);
    this.props.fetchMasterTemplates(0, 1000);
    this.props.fetchMasterForms(0, 1000);
    this.props.fetchOrgForms(0, 1000);
  }

  componentDidUpdate(prevProps) {
    const { masterWorkflowProgress, masterWorkflowError, actionsError, actionsProgress, addOrgSettingsInprogress, componentsError, componentsProgress, copyMasterToOrgProgress, copyMasterToOrgError } = this.props;

    if (masterWorkflowProgress === false && prevProps.masterWorkflowProgress === true) {
      if (masterWorkflowError === null) {
        this.setState({
          workflowDetails: this.props.masterWorkflow,
          selectedStatus: this.props.masterWorkflow.Status,
          name: this.props.masterWorkflow.Name
        }, () => {
          this.checkForDefault();
        })
      } else {
        this.props.showSnackBar("Failed to get workflow details", "error", 3000);
      }
    }

    if (actionsProgress === false && prevProps.actionsProgress === true) {
      if (actionsError === null) {
        this.checkProcess();
      } else {
        this.props.showSnackBar("Failed to get workflow actions", "error", 3000);
      }
    }

    if (componentsProgress === false && prevProps.componentsProgress === true) {
      if (componentsError === null) {
        this.checkProcess();
      } else {
        this.props.showSnackBar("Failed to get workflow components", "error", 3000);
      }
    }

    if (copyMasterToOrgProgress === false && prevProps.copyMasterToOrgProgress === true) {
      if (copyMasterToOrgError === null) {
        console.log(this.props.copyMasterToOrgData, 'copyMasterToOrgData')
        this.toggleEvaluationTemplate('');
        this.props.showSnackBar("Workflow copied to org successfully.", "success", 3000);
      } else {
        this.props.showSnackBar("Failed to copy master to org", "error", 3000);
      }
    }

    if (addOrgSettingsInprogress === false && prevProps.addOrgSettingsInprogress === true && this.state.isCopyWorkflow === true) {
      let redirectPath = `/app/settings/form-builder/default/${this.props.copyMasterToOrgData.ID}`;
      this.props.history.push(redirectPath);
    }

    if (this.props.addOrgSettingsInprogress === false && prevProps.addOrgSettingsInprogress === true && this.state.isCopyWorkflow === false) {
      this.checkForDefault();
    }
  }

  checkForDefault() {
    const defaultObjWorkflow = this.props.fetchAllOrgSettingsSuccess.find((item) => item.SettingName === "ORG_DEFAULT_EVALUATION_WORKFLOW");
    const defaultObjReview = this.props.fetchAllOrgSettingsSuccess.find((item) => item.SettingName === "ORG_DEFAULT_REVIEW_WORKFLOW");
    const defaultObjRenew = this.props.fetchAllOrgSettingsSuccess.find((item) => item.SettingName === "ORG_DEFAULT_RENEWAL_WORKFLOW");
    var isDefaultForWorkflow = false;
    var isDefaultForReview = false;
    var isDefaultForRenewal = false;
    if (defaultObjWorkflow && defaultObjWorkflow?.Value && defaultObjWorkflow?.Value !== 'NULL') {
      try {
        isDefaultForWorkflow = JSON.parse(defaultObjWorkflow?.Value).wid === parseInt(this.props.match.params.id);
      } catch (e) {
        console.warn(e);
      }
    }
    if (defaultObjReview && defaultObjReview?.Value && defaultObjReview?.Value !== 'NULL') {
      try {
        isDefaultForReview = JSON.parse(defaultObjReview?.Value).wid === parseInt(this.props.match.params.id);
      } catch (e) {
        console.warn(e);
      }
    }

    if (defaultObjRenew && defaultObjRenew?.Value && defaultObjRenew?.Value !== 'NULL') {
      try {
        isDefaultForRenewal = JSON.parse(defaultObjRenew?.Value).wid === parseInt(this.props.match.params.id);
      } catch (e) {
        console.warn(e);
      }
    }
    this.setState({
      defaultWorkflow: isDefaultForWorkflow,
      defaultForReview: isDefaultForReview,
      defaultForRenewal:isDefaultForRenewal,
      selectedStatus: this.state.workflowDetails.Status
    })
  }

  toggleEvaluationTemplate = (e) => {
    let message = "NULL";
    let type = this.props.copyMasterToOrgData.Type === 1 ? 'EVALUATION' : 'REVIEW';
    message = JSON.stringify({
      type: 'ORG',
      wid: parseInt(this.props.copyMasterToOrgData.ID)
    })

    this.setState({
      isCopyWorkflow: true
    }, () => {
      this.props.addOrgSettings([
        {
          "name": "ORG_DEFAULT_" + type + "_WORKFLOW",
          "value": message
        }
      ])
    })
  }

  updateDefaultWorkflow = (e, type) => {
    let message = "NULL";
    if (e.target.checked) {
      message = JSON.stringify({
        type: 'MASTER',
        wid: parseInt(this.props.match.params.id)
      })
    }
    this.props.addOrgSettings([
      {
        "name": "ORG_DEFAULT_" + type + "_WORKFLOW",
        "value": message
      }
    ])
  }

  onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView()
    this.setState({
      reactFlowInstance: reactFlowInstance
    })
  }

  checkProcess() {
    var count = this.state.checkFetchCount;
    count = count + 1;
    this.setState({
      checkFetchCount: count
    }, () => {
      if (this.state.checkFetchCount === 2) {
        this.setElementToFlow();
      }
    })
  }

  getMasterObjectFromId = (id) => {
    return this.props.workflowComponent && this.props.workflowComponent.find((item) => item?.ID === parseInt(id));
  }


  setElementToFlow() {
    const { actions, components } = this.props;
    const orgWorkFlowCompList = components;
    const orgWorkFlowActionList = actions;
    var currentActiveComponent = 0;

    let workflowComponentMap = {};
    orgWorkFlowCompList.forEach(function (component) {
      workflowComponentMap[component.ID] = component;
    });


    const decisionComponentIds = [];
    const elementList = orgWorkFlowCompList ? orgWorkFlowCompList.map((item, index) => {
      let flowData = {};
      try {
        if (item.FlowData) {
          flowData = JSON.parse(item.FlowData);
        }
      } catch (e) {
        console.warn(e);
      }
      const masterObject = this.getMasterObjectFromId(item.ComponentID);

      let label = item.Name;
      const dataObjects = {
        id: item.ID.toString(),
        data: {
          label, componentId: item.ComponentID.toString(),
          flowData: item.FlowData,
          configurations: item.MasterWorkflowComponentConfigurations,
          object: item
        },
        position: flowData?.position ? flowData?.position : { x: 40, y: (index + 1) * 40 },
        style: {
          background: "transparent",
          border: "none",
          borderRadius: "0",
          width: "max-content",
          "&:hove": {
            boxShadow: "none",
          },
        },
        cuurrentActiveUsers: []
      }

      if (currentActiveComponent === item.ID) {
        dataObjects.cuurrentActiveUsers.push(this.props.user);
      }

      if (masterObject && masterObject?.Slug === 'DECISION_COMPONENT') {
        decisionComponentIds.push(item.ID)
      }

      var isShowPermissionDropDown = true;
      if (masterObject?.Slug === "START") {
        dataObjects.data['type'] = 'input';
        isShowPermissionDropDown = false;
      } else if (masterObject?.Slug === 'FINISH' || masterObject?.Slug === 'STOP') {
        dataObjects.data['type'] = 'output';
        isShowPermissionDropDown = false;
      }

      var isEdit = false;

      let isShowConfigIcon = false
      if (
        !['START', 'STOP'].includes(masterObject?.Slug) &&
        this.props.workflowFormCompList &&
        Array.isArray(this.props.workflowFormCompList) &&
        this.props.workflowFormCompList.length > 0
      ) {
        this.props.workflowFormCompList.every((witem) => {
          if (item.ComponentID && witem.ComponentIds !== null && witem.ComponentIds.indexOf(parseInt(item.ComponentID)) !== -1) {
            isShowConfigIcon = true
            return false
          } else {
            return true
          }
        });
      }

      if (masterObject && masterObject?.Slug === 'DECISION_COMPONENT') {
        isShowConfigIcon = true
        isShowPermissionDropDown = false;
      }

      let permission = item?.Permission;

      dataObjects.data.label = nodeLabel(
        isShowConfigIcon,
        masterObject?.Slug,
        dataObjects.id,
        item.Name,
        dataObjects.position.x,
        dataObjects.position.y,
        this.onSelectWorkflowComponent,
        this.onClickDecisionComponent,
        this.onClickWorkFlowComponent,
        isEdit,
        dataObjects.cuurrentActiveUsers,
        this.removeUsers,
        isShowPermissionDropDown,
        permission,
        this.updateComponentPermission
      )

      return dataObjects
    }) : [];

    const componentEdges = orgWorkFlowActionList ? orgWorkFlowActionList.map((item, index) => {
      let animated = false;
      return {
        id: item.ID.toString(),
        source: item.SourceComponentID.toString(),
        target: item.TargetComponentID.toString(),
        type: decisionComponentIds.includes(item.SourceComponentID) ? "smoothstep" : "buttonedge",
        label: decisionComponentIds.includes(item.SourceComponentID) ? item?.Name : "",
        style: { stroke: '#d8d8d8', strokeWidth: '1px' },
        animated: animated
      }
    }) : [];


    // this.setState({
    //   elements: [...elementList, ...componentEdges]
    // })

    if (elementList !== undefined && elementList !== null && componentEdges !== undefined && componentEdges !== null && elementList.length > 0 && componentEdges.length > 0) {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(elementList, componentEdges, 'TB');
      this.setState({
        elements: [...layoutedNodes, ...layoutedEdges],
        workflowNodes: elementList,
        workflowEdges: componentEdges,
      })
    } else {
      this.setState({
        elements: [...elementList, ...componentEdges],
        workflowNodes: elementList,
        workflowEdges: componentEdges,
      })
    }
  }

  getMasterId(sourceId) {
    return this.state.elements.find(element => element.id === sourceId.toString())?.data?.componentId || '';
  }

  getMasterFormComponent = (masterComponentId, isConfiguration) => {
    const formCompList = [];
    this.props.workflowFormCompList.forEach((item) => {
      if (masterComponentId && item.ComponentIds !== null && item.ComponentIds.indexOf(parseInt(masterComponentId)) !== -1) {
        formCompList.push(item);
      }
    });
    this.setState({
      formComponentList: formCompList
    })
    return formCompList;
  }

  onElementClick = (element, ev = false) => {
    if (ev) {
      ev.stopPropagation()
      const className = ev.target.className.toString()
      const isEventTrue = className.trim().includes('show-config')
      if (!isEventTrue) return true;
    }
    this.props.hideMasterComponentDialog();
    if (element.data) {
      this.setState({
        showComponent: false,
        componentDetails: element,
        selectedComponentName: element.data?.object?.Name,
        edgeDetails: {}
      })
      const masterComponentId = this.getMasterId(element.id);
      this.getMasterFormComponent(masterComponentId, true);
    } else if (element?.source) {
      const masterComponentId = this.getMasterId(element.source);
      const actionObject = this.getActionObjectFromId(element.id);
      const sourceObject = this.getComponentObjectFromId(element.source);
      const targetObject = this.getComponentObjectFromId(element.target);
      this.getMasterFormComponent(masterComponentId, false);
      this.setState({
        componentDetails: {},
        edgeDetails: {
          ...element, ...actionObject, sourceName: sourceObject?.Name, targetName: targetObject?.Name
        }
      })
    }

    this.setState({
      isComponentEdit: false
    }, () => {
      this.props.showMasterComponentDialog();
    })
  }

  resetView() {
    this.setState({
      componentDetails: {},
      edgeDetails: {},
      formComponentList: [],
    })
    this.props.hideMasterComponentDialog();
  }

  closeRightModal() {
    this.setState({
      showComponent: true
    })
    this.props.hideMasterComponentDialog();
  }

  copyWorkflow() {
    this.props.copyWorkflowMasterToOrg(this.props.match.params.id);
  }

  render() {
    const classes = this.props.classes;

    const { workflowDetails, elements, edgeDetails, defaultWorkflow, defaultForReview,defaultForRenewal, componentDetails, selectedComponentName, selectedStatus, formComponentList } = this.state;
    const { isMasterComponentDialogOpen } = this.props;

    console.log(workflowDetails, 'workflowDetails111')
    return (
      <div>
        <AppBarV2 title={this.props.masterWorkflow?.Name} newHeader={true} withBack={true} edit={true} />
        <div className={classes.secondarySection}>
          <div className={classes.workflowBtns}>

            {workflowDetails?.Status === 2 && workflowDetails?.Type && (
              <SqureButton
                className={classes.actionBtn}
                variant={"outlined"}
                endIcon={<ArrowDropDownIcon />}
                onClick={(event) => {
                  event.stopPropagation()
                  this.setState({ open: true, anchorEl: event.currentTarget || event.current })
                }}
              >
                Set Default
              </SqureButton>
            )} &nbsp;&nbsp;&nbsp;


            <SqureButton
              cls={classes.actionBtn}
              variant={"outlined"}
              onClick={(e) => {
                this.copyWorkflow()
              }}
            >Use this workflow
            </SqureButton>
          </div>
        </div>
        <div className={classes.formBuilderMain}>
          <Grid container>
            <Grid item lg={12}>
              <div className={classes.flowSection} id="dropSection">
                <ReactFlowProvider>
                  <div style={{ width: '100%', height: '100%' }} ref={this.reactFlowWrapper}>
                    <ReactFlow
                      elements={elements}
                      onLoad={this.onLoad}
                      snapToGrid={true}
                      snapGrid={[15, 15]}
                      style={{ background: '#F8F7F7' }}
                      key="edge-with-button"
                      defaultZoom={1.5}
                      onElementClick={(event, element) => this.onElementClick(element, event)}
                    >
                      <Controls
                        onInteractiveChange={e => console.log(e)}
                      />
                      <Background color="#000" gap={30} />
                    </ReactFlow>
                  </div>
                </ReactFlowProvider>
              </div>
            </Grid>
          </Grid>

          {isMasterComponentDialogOpen && (
            <div className={classes.elementDetails}>
              <div className={classes.componentDetailsSection}>
                <div className={classes.componentDetailsHeader}>
                  <div style={{ flex: 1 }}>
                    <span
                      className={classes.componentname}
                    >
                      {selectedComponentName}
                    </span>
                  </div>
                  &nbsp;
                  <IconButton aria-label="delete" className={classes.closeIconSection} onClick={() => this.closeRightModal(componentDetails)}>
                    <ClearIcon style={{ color: '#282D30' }} />
                  </IconButton>
                </div>
                <div className={classes.componentDetailsBody}>
                  {componentDetails?.data && (
                    <WorkFlowConfiguration
                      workflowStatus={selectedStatus}
                      submitData={this.submitConfiguration}
                      configurations={componentDetails?.data?.configurations}
                      formComponentList={formComponentList}
                      componentDetails={componentDetails}
                      orgWorkFlowCompList={this.props.orgWorkFlowCompList}
                      workflowComponents={this.props.workflowComponent}
                      workflowId={this.props.match.params.id}
                      closeConfiguration={this.resetView}
                      isMasterWorkflow={true}
                    />
                  )}
                  {edgeDetails?.source && (
                    <WorkFlowRule
                      workflowStatus={selectedStatus}
                      formComponentList={formComponentList}
                      orgWorkflowActionRuleElements={edgeDetails.OrgWorkflowActionRuleElements || []}
                      submitData={this.submitRule} />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div>

        </div>

        <Popover
          id="Popover-one"
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={() => this.setState({ anchorEl: null, open: null })}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <List>
            <ListItem
              classes={{
                button: classes.listItem,
              }}
            >
              <Typography variant="inherit">
                Set Default
              </Typography>
            </ListItem>
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <Checkbox
                  className={classes.checkbox}
                  disabled={false}
                  checked={defaultWorkflow}
                  onChange={e => {
                    e.stopPropagation()
                    this.updateDefaultWorkflow(e, 'EVALUATION')
                  }}
                />
              </ListItemIcon>
              <Typography variant="inherit">
                Evaluation
              </Typography>
            </ListItem>
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <Checkbox
                  className={classes.checkbox}
                  disabled={false}
                  checked={defaultForReview}
                  onChange={e => {
                    e.stopPropagation()
                    this.updateDefaultWorkflow(e, 'REVIEW')
                  }} />
              </ListItemIcon>
              <Typography variant="inherit">
                Review
              </Typography>
            </ListItem>
            <ListItem
              classes={{
                button: classes.listItem,
              }}
              button
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <Checkbox
                  className={classes.checkbox}
                  disabled={false}
                  checked={defaultForRenewal}
                  onChange={e => {
                    e.stopPropagation()
                    this.updateDefaultWorkflow(e, 'RENEWAL')
                  }}
                />
              </ListItemIcon>
              <Typography variant="inherit">
                Renewal
              </Typography>
            </ListItem>
          </List>
        </Popover>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(masterWorkflow));
