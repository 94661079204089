import envLocal from "../env.local.json";
import envDev from "../env.dev.json";
import envTest from "../env.test.json";
import envProd from "../env.prod.json";

const config = process.env.REACT_APP_ENV
// const config = "dev"

console.log(config, "CONFIG");
var env;
if (config === "local") {
  env = envLocal;
} else if (config === "test") {
  env = envTest;
} else if (config === "production") {
  env = envProd;
} else if (config === "dev") {
  env = envDev;
} else {
  env = envLocal;
}

export const getNewPlatform = () => {
  if (config === 'production') {
    return 'https://app2.expent.ai';
  } else if (config === 'test') {
    return 'https://demo2.expent.ai';
  } else {
    return 'https://dev2.expent.ai';
  }
};

export function get(key, default_value = null) {
  if (key === undefined || key === null) {
    return default_value;
  }

  key = key + "";

  var key_segment = key.split(".");
  var value = env;
  for (var i = 0; i < key_segment.length; i++) {
    var segment_name = key_segment[i];
    if (value[segment_name] === undefined) {
      return default_value;
    } else {
      value = value[segment_name];
    }
  }
  return value;
}

export function host() {
  return get("HOST");
}

export function app_name() {
  return get("APP_NAME");
}

export function api_host(name) {
  return get("API_SERVER." + name);
}

export function debug() {
  return get("APP_ENV") === "local";
}

export function getAppDateFormat(type) {
  return get("DATE_FORMAT." + type);
}
