import {
  SHOW_IMPORT_LIBRARY_DIALOG,
  HIDE_IMPORT_LIBRARY_DIALOG,
  START_FETCH_SELLER_LIBRARY_QUESTION,
  END_FETCH_SELLER_LIBRARY_QUESTION,
  START_FETCH_GENIE_SUMMARY,
  END_FETCH_GENIE_SUMMARY,
  START_GENIE_QUESTION_SEARCH,
  END_GENIE_QUESTION_SEARCH,
} from "./action";

const initState = {
  importLibraryDialogOpen: false,

  questionSearchProgress: false,
  questionSearchError: null,
  questionSearchData: [],

  genieSummaryProgress: false,
  genieSummaryError: null,
  genieSummaryData: [],

  genieQuestionSearchProgress: false,
  genieQuestionSearchError: null,
  genieQuestionSearchData: [],
  genieQuestionSearchIndex: -1,
};

export function sellerLibraryResucer(state = initState, action) {
  switch (action.type) {
    case SHOW_IMPORT_LIBRARY_DIALOG:
      return {
        ...state,
        importLibraryDialogOpen: true,
      };

    case HIDE_IMPORT_LIBRARY_DIALOG:
      return {
        ...state,
        importLibraryDialogOpen: false,
      };

    case START_FETCH_SELLER_LIBRARY_QUESTION:
      return {
        ...state,
        questionSearchProgress: true,
        questionSearchError: null,
      };

    case END_FETCH_SELLER_LIBRARY_QUESTION:
      return {
        ...state,
        questionSearchProgress: false,
        questionSearchError: action.payload.error,
        questionSearchData: action.payload.success,
      };
    case START_FETCH_GENIE_SUMMARY:
      return {
        ...state,
        genieSummaryProgress: true,
        genieSummaryError: null,
      };

    case END_FETCH_GENIE_SUMMARY:
      return {
        ...state,
        genieSummaryProgress: false,
        genieSummaryError: action.payload.error,
        genieSummaryData: action.payload.success,
      };
    case START_GENIE_QUESTION_SEARCH:
      return {
        ...state,
        genieQuestionSearchProgress: true,
        genieQuestionSearchError: null,
        genieQuestionSearchIndex: action.payload?.questionIndex,
      };

    case END_GENIE_QUESTION_SEARCH:
      return {
        ...state,
        genieQuestionSearchProgress: false,
        genieQuestionSearchError: action.payload.error,
        genieQuestionSearchData: action.payload.success,
      };

    default:
      return state;
  }
}
