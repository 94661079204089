import React, { useRef, useState } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript";
import "codemirror/addon/display/autorefresh";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import "./codeMirror.css";
import { useEffect } from "react";

const connectedProps = (state) => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    decorationClass: {
      border: "1px solid #ced5db",
      background: "#f7f7f7",
    },
  });




const TextInput = ({ value, handleChange, handleFocus, variableMap, availableVariables, mirrorFor = 'ACTION' }) => {
  const codeMirrorRef = useRef(null);
  const codeMirrorInstance = useRef(null);
  const [editorValue, setEditorValue] = useState(value);

  useEffect(() => {
    setEditorValue(value)
    setTimeout(() => {
      updateText()
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const getConvertableTexts = (text) => {
    if (mirrorFor === 'CONDITION') {
      const regex = /component_\w+\.\w+/g;
      const matches = text.match(regex);
      if (matches) {
        return matches;
      } else {
        return [];
      }
    } else {
      const regex = /{{(.*?)}}/g;
      const matches = [];
      let match;
      while ((match = regex.exec(text)) !== null) {
        if (availableVariables.indexOf(match[1].trim()) !== -1) {
          matches.push(match[1].trim());
        }
      }
      return matches
    }
  }

  const getIndexes = (lineText, searchString) => {
    const indexes = [];
    let index = -1;
    while ((index = lineText.indexOf(searchString, index + 1)) !== -1) {
      indexes.push(index);
    }
    return indexes
  }

  const updateText = () => {
    const editor = codeMirrorRef.current.editor;
    // Iterate through each line and search for the text to decorate
    editor.eachLine((lineHandle) => {
      const lineText = lineHandle.text;
      let convertableText = getConvertableTexts(lineText)
      if (convertableText) {
        convertableText.forEach(function (txt) {
          const indexes = getIndexes(lineText, txt)
          if (indexes) {
            indexes.forEach((startIndex) => {
              if (startIndex !== -1) {
                const widget = document.createElement("span");
                widget.textContent = variableMap[txt] || '';
                widget.className = "widget";
                editor.markText(
                  { line: lineHandle.lineNo(), ch: startIndex - 3 },
                  { line: lineHandle.lineNo(), ch: startIndex + txt.length + 3 },
                  {
                    replacedWith: widget,
                  }
                );
              }
            })
          }
        })
      }
    });
  };

  return (
    <div>
      <CodeMirror
        value={editorValue}
        mode="text/html"
        className="custom-codemirror"
        options={{
          mode: "text/html",
          lineNumbers: false,
          gutters: ["CodeMirror-linenumbers"],
          lineWrapping: true, // Enable line wrapping
        }}
        editorDidMount={(editor) => {
          codeMirrorInstance.current = editor;
        }}
        ref={codeMirrorRef}
        onChange={(editor, data, value) => {
          setEditorValue(value)
          updateText()
          handleChange(value)
        }}
        onBeforeChange={(editor, data, value) => {
          setEditorValue(value)
          updateText()
        }}
        onFocus={(editor, event) => {
          handleFocus()
        }}
        placeholder="Enter text here"
      />
    </div>
  );
};

export default connector(
  compose(withStyles(styles))(TextInput)
);
