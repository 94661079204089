import { Button, Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import classnames from "classnames";
import CommonCss from "commonCss";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({});

const connectionActions = {
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);
const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
    },
    ...CommonCss.ProductDetails,
    description2: {
      ...CommonCss.ProductDetails.description,
      marginBottom: 0,
      "& p": {
        margin: "0px",
      },
      "& [class*=editor-editableField]": {
        minHeight: "auto !important",
        margin: "0px !important",
      },
      "& [class*=editor-formControl]": {
        border: "none !important",
      },
    },
    formGroup: {
      display: "flex",
      alignItems: "center",
    },
    label: {
      marginBottom: 5,
      display: "block",
    },
    ...CommonCss.Home,
    contained: {
      color: "#fff",
      padding: "6px 26px",
      backgroundColor: "#4b86f8",
      "&:hover": {
        backgroundColor: "#4b86f8",
      },
    },
    tickMark: {
      color: "#4b86f8",
      border: "1px solid #4b86f8",
      marginRight: 10,
      minWidth: "50px !important",
      "&:hover": {
        color: "#4b86f8",
      },
    },
    colorBlue: {
      color: "#4b86f8",
    },
    buttonGroup: {
      alignItems: "center",
      position: "relative",
      zIndex: 1000,
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 6,
      marginBottom: 5,
      cursor: "pointer",
    },
    descriptionText: {
      float: "right",
    },
    supportLabel: {
      fontWeight: 600,
      marginTop: 3,
      marginBottom: 3,
      // minWidth: 129,
      display: "inline-block",
      paddingRight: 10,
    },
    productLink: {
      ...CommonCss.ProductDetails.productLink,
      fontSize: 14,
      marginBottom: 0,
    },
    deleteImage: {
      position: "absolute",
      top: 24,
      zIndex: 20,
      right: 23,
      padding: 4,
      cursor: "pointer",
    },
    positionRelative: {
      position: "relative",
    },
    productList: {
      position: "relative",
    },
    slateInputField: {
      "& > div": {
        paddingTop: "0px !important",
        paddingBottom: "30px !important",
      },
    },
    nomargin: {
      margin: "0px !important",
    },
    closeIcon: {
      position: "absolute",
      right: "-20px",
      top: 0,
      cursor: "pointer",
    },
    topIndex: {
      zIndex: 1,
    },
  });

class RichText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      value: "",
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  save = () => {
    this.props.updateValue(this.state.value);
    this.setState({ value: "", isEdit: false });
  };

  render() {
    const classes = this.props.classes;

    return (
      <>
        <div>
          <span className={classes.supportLabel}>{this.props.label} </span>
          <span>
            {!this.state.isEdit && this.props.editAccess && (
              <EditIcon
                fontSize={"small"}
                className={classes.editIcon}
                onClick={() => {
                  this.setState({
                    value: this.props.value,
                    isEdit: true,
                  });
                }}
              />
            )}
          </span>
        </div>

        {!this.state.isEdit && this.props.value && (
          <div className={classes.description2}>
            <SlateInputField
              placeholder=""
              isToolBar={false}
              readOnly={true}
              as={SlateInputField}
              onChangeEvent={(value) => {
                this.setState({ value: value });
              }}
              formControlStyle={{
                overflow: "overlay",
                borderRadius: 5,
              }}
              initValue={this.props.value}
              textContainerStyle={{ color: "#707070" }}
            />
          </div>
        )}

        {!this.state.isEdit && !this.props.value && <p className={classes.notAvailable}>No data</p>}
        {this.state.isEdit && (
          <Grid container spacing={3} className={classnames(classes.buttonGroup, classes.slateInputField, classes.topIndex)}>
            <Grid item sm={12}>
              <SlateInputField
                placeholder=""
                isToolBar={true}
                readOnly={false}
                as={SlateInputField}
                onChangeEvent={(value) => {
                  this.setState({ value: value });
                }}
                formControlStyle={{
                  overflow: "overlay",
                  borderRadius: 5,
                }}
                initValue={this.props.value}
                textContainerStyle={{ color: "#707070" }}
              />
              <Button className={classnames(classes.contained, classes.descriptionText)} onClick={() => this.save()}>
                Save
              </Button>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(RichText));
