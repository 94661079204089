import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import Form from "./Components/Form/index";
import Evaluation from "./Components/Evaluation/index";
import RequestDetailsTable from "./RequestDetailsTable";
import Approval from "./Components/Approval/GetApproval";

import Instruction from "./Components/Instruction";

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class ComponentLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  render() {
    const { title, slug, component, componentId, evaluation_id, workflow_id } = this.props;

    return (<div>

      {slug === 'REQUEST_DETAILS' && <RequestDetailsTable evaluation_id={evaluation_id} />}

      {slug === 'FORM' && <Form
        title={title}
        component={component}
        adminForward={false}
        isCreatorOrAdmin={true}
        componentId={componentId}
        evaluation_id={evaluation_id}
        workflow_id={workflow_id}
        workflowComponentId={componentId}
        isOpen={true}
        isWorkFlow={true}
        isFromCart={this.props.isFromCart}
      />
      }

      {slug === 'QUESTIONNAIRE' && <Evaluation
        title={title}
        component={component}
        adminForward={false}
        isCreatorOrAdmin={true}
        componentId={componentId}
        evaluation_id={evaluation_id}
        workflow_id={workflow_id}
        workflowComponentId={componentId}
        isOpen={true}
        isWorkFlow={true}
      />
      }

      {slug === 'APPROVAL_WORKFLOW' && <Approval
        title={title}
        component={component}
        adminForward={false}
        isCreatorOrAdmin={true}
        componentId={componentId}
        evaluation_id={evaluation_id}
        workflow_id={workflow_id}
        workflowComponentId={componentId}
        isOpen={true}
        isWorkFlow={true}
      />
      }

      {slug === 'SELLER_INSTRUCTION' && <Instruction
        title={title}
        component={component}
        adminForward={false}
        isCreatorOrAdmin={true}
        componentId={componentId}
        evaluation_id={evaluation_id}
        workflow_id={workflow_id}
        workflowComponentId={componentId}
        isOpen={true}
        isWorkFlow={true}
      />
      }
    </div>)
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ComponentLoader));
