import { createStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  IconButton,
  CircularProgress,
  Drawer,
  Typography,
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { CommonFn } from "services/commonFn";
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

// import { geFlowType} from "util/Columns";
import classnames from "classnames";
// components
import Image from "Components/Common/image.jsx";
import DefaultImage from "assets/images/evaluation/default.svg";
// import CloseImage from "assets/images/close.svg";
// import AddProductImage from "assets/images/add_product.svg";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
//redux
import { removeProductFromCart } from "redux/evaluation/cart/action";
import { requestDetails, hideRequestDetailsCart } from "redux/evaluation/workflow/action";
import { showSnackBar } from "redux/snackbar/action";
import { showMessageDialog, hideMessageDialog, } from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";
import SqureButton from 'Components/Common/SqureButton';
import { updateEvaluationMetaData } from "redux/evaluation/metadata/action";

const connectedProps = (state) => ({
  user: state.authUser.user,
  targetContainer: state.evaluationCart.targetContainer,
  isRequestDetailsCartOpen: state.evaluationWorkflow.isRequestDetailsCartOpen,
  isRequestDetailsCartData: state.evaluationWorkflow.isRequestDetailsCartData,
  requestDetailsProgress: state.evaluationWorkflow.requestDetailsProgress,
  requestDetails: state.evaluationWorkflow.requestDetails,
  requestDetailsError: state.evaluationWorkflow.requestDetailsError,
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  removeProductInProgress: state.evaluationCart.removeProductInProgress,
  evaluateView: state.applications.evaluateView,
  evaluateViewId: state.applications.evaluateViewId,
  evaluateNeverReview: state.applications.evaluateNeverReview,
  evaluateNeverReviewId: state.applications.evaluateNeverReviewId,
  colloborators: state.evaludationCriteria.colloborators,
  allProductCollaboratorProgress: state.evaluationMetaData.allProductCollaboratorProgress,
  userRole: state.authUser.user?.Role,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
  updateProgress: state.evaluationMetaData.updateProgress,

});

const connectionActions = {
  fetchRequestDetails: requestDetails,
  hideRequestDetailsCart: hideRequestDetailsCart,
  showSnackBar: showSnackBar,
  removeProductFromCart: removeProductFromCart,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
  updateDetails: updateEvaluationMetaData,
};

var connector = connect(connectedProps, connectionActions);


const styles = (theme) =>
  createStyles({
    drawer: {
      width: "50%",
      flexShrink: 0,
      transition: "width 300ms",
      background: "#F7F7F7",
    },
    drawerPaper: {
      width: "50%",
      transition: "width 300ms",
      paddingTop: theme.spacing(2),
      background: "#ffffff",
    },

    cartContainer: {
      padding: '0px 20px',
      '&.close': {
        opacity: 0
      }
    },
    title: {
      fontSize: theme.spacing(2.4),
      // textAlign: "center",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    title2: {
      fontSize: theme.spacing(2.4),
      marginTop: theme.spacing(1),
    },
    closeIcon: {
      width: '36px',
      marginLeft: '92%',
      padding: '8px',
    },
    progressIndicator: {
      margin: theme.spacing(2),
      textAlign: "center",
    },
    headSection: {
      justifyContent: "space-between",
      minHeight: 40,
    },
    cartItem: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #EEEEEE",
      borderRadius: 10,
      margin: `${theme.spacing(2)} 0`,
      justifyContent: "space-between",
      paddingRight: '10px'
    },
    productNameContainer: {
      flex: 0.95,
    },
    productName: {
      flex: 0.95,
    },
    productImage: {
      width: "100%",
      height: "auto",
      marginRight: theme.spacing(2),
    },
    productImageContainer: {
      width: 60,
      height: 60,
      padding: 13,
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      // boxShadow: "0px 1px 6px #0000001A",
      borderRadius: 10,
    },
    addProductImageContainer: {
      width: 60,
      height: 60,
      padding: 13,
    },
    productRemove: {
      marginTop: theme.spacing(1),
      cursor: "pointer",
    },
    counter: {
      padding: theme.spacing(1),
      fontSize: theme.spacing(2.2),
      opacity: 0.5,
      marginTop: theme.spacing(1),
    },
    productNameTitle: {
      fontSize: theme.spacing(2),
    },
    vendotDetail: {
      cursor: "pointer",
      color: "#4175DF",
      fontSize: theme.spacing(2),
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    productsWrap: {
      paddingBottom: theme.spacing(6),
    },
    editWorkflow: {
      marginLeft: '10px',
    }
  });


class RequestDetailsCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteProductId: null,
      deleteEvaluationId: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isRequestDetailsCartOpen === true && prevProps.isRequestDetailsCartOpen === false) {
      if (this.props.isRequestDetailsCartData) {
        this.props.fetchRequestDetails(this.props.isRequestDetailsCartData);
      } else {
        this.props.showSnackBar("Something went wrong", "error", 3000);
        this.props.hideRequestDetailsCart();
      }
    }
    if (this.props.requestDetailsProgress === false && prevProps.requestDetailsProgress === true) {
      if (this.props.requestDetailsError !== null) {
        // this.props.showSnackBar("Failed to fetch details", "error", 3000);
      }
    }

    if (this.props.updateProgress === false && prevProps.updateProgress === true) {
      this.props.showSnackBar("Project closed successfully.", "success", 3000)
      window.location.reload()
    }
  }

  removeProduct = (productId, evaluationId) => {
    this.setState({
      deleteProductId: productId,
      deleteEvaluationId: evaluationId,
    }, () => {
      this.props.showMessageDialog();
    })
  }

  callBackReq = () => {
    this.props.updateDetails(this.props.evaluation?.ID, {
      status: 5
    })
  };

  closeProject = () => {
    this.props.showMessageDialog();
  }

  render() {
    const { requestDetails, classes } = this.props;
    if (!this.props.isRequestDetailsCartOpen) {
      return null
    }

    let items = [];

    if (this.props.evaluateView && this.props.evaluation && this.props.evaluation.Products) {
      this.props.evaluation.Products.forEach((product) => {
        if (product.ID === this.props.evaluateViewId) {
          items.push(product);
        }
      });
    } else if (this.props.evaluation) {
      items = this.props.evaluation.Products;
    }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to close project?",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    return (
      <>
        <Drawer
          className={
            classnames(classes.appDrawer, classes.drawer)
          }
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="right"
        >
          <IconButton
            className={classes.closeIcon}
            onClick={this.props.hideRequestDetailsCart}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <div className={classnames(classes.cartContainer)}>
            <div style={{ paddingBottom: '2rem' }}>
              <Typography variant={"h5"} className={classes.title}>
                Project Details
              </Typography>

              {this.props.requestDetailsProgress && (
                <div className={classes.progressIndicator}>
                  <CircularProgress />
                </div>
              )}

              {!this.props.requestDetailsProgress && (
                <Paper className={classes.root}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell variant="head">Project ID</TableCell>
                          <TableCell>{requestDetails?.ID}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Project Name</TableCell>
                          <TableCell>{requestDetails?.Name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Requestor</TableCell>
                          <TableCell>{requestDetails?.RequestorName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Project Status</TableCell>
                          <TableCell>{requestDetails?.RequestStatus === "PendingApproval" ? "Pending Approval" : requestDetails?.RequestStatus}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Current Step</TableCell>
                          <TableCell>{requestDetails?.CurrentComponnetName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Workflow Used</TableCell>
                          <TableCell>
                            {requestDetails?.WorkflowName}
                            {this.props.userRole === "OrgAdmin" &&
                              <Tooltip title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{'Edit workflow for this project'}</span>} placement="top" classes={{ tooltip: classes.noMaxWidth }}>
                                <IconButton
                                  className={classes.editWorkflow}
                                  onClick={() => {
                                    this.props.hideRequestDetailsCart()
                                    this.props.history.push(`/app/evaluate/${this.props.evaluation?.ID || this.props.match.params.evaluation_id}/workflow/${this.props.evaluation?.WorkflowID || this.props.match.params.workflow_id}/configure`)
                                  }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton></Tooltip>}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Requested Products</TableCell>
                          <TableCell>
                            {!this.props.targetContainer &&
                              items.map((item, k) => {
                                return (
                                  <Grid container alignItems="center" className={classes.cartItem}>
                                    <Grid item className={classes.productImageContainer}>
                                      <Image alt="product" src={item.ProductLogo == null || item.ProductLogo.length === 0 ? DefaultImage : item.ProductLogo} className={classes.productImage} />
                                    </Grid>
                                    <Grid item className={classes.productNameContainer}>
                                      <Typography variant={"h5"} className={classes.productNameTitle}>
                                        {item.Name}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                );
                              })}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Created at</TableCell>
                          <TableCell>{CommonFn.formateDate(requestDetails?.CreatedAt, true)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Last Updated</TableCell>
                          <TableCell>{CommonFn.formateDate(requestDetails?.UpdatedAt, true)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {this.props.evaluation?.Status !== 4 && this.props.evaluation?.Status !== 5 && <SqureButton style={{ margin: 10, paddingLeft: 10, paddingRight: 10 }}
                    className={classes.workflowProgressBtn} variant="contained" onClick={() => {
                      this.closeProject();
                    }}> Close Project </SqureButton>}
                </Paper>
              )}
            </div>
          </div>
          {<MessageDialog messageData={messageData} callBack={this.callBackReq} />}
        </Drawer>
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(RequestDetailsCart));
