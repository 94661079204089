import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Tabs,Tab,Divider } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import NewEvaluation from "./NewEvaluation"
import NewReview from "./NewReview"
import SelectExisting from "./SelectExisting"
const connectedProps = (state) => ({
});

const connectionActions = {
}



var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    
});

class Child extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            actibeTab:0
        }
    }
    
    render() {
        const classes   =   this.props.classes;
        
        return <div >
                    <Tabs
                        value={this.state.actibeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(event,newValue)=>{
                            this.setState({actibeTab:newValue})
                        }}
                    >
                        <Tab label="New Evaluation" />
                        <Tab label="New Review" />
                        <Tab label="Select Existing" />
                    </Tabs>
                    <Divider/>
                    {this.state.actibeTab === 0 && <NewEvaluation />}
                    {this.state.actibeTab === 1 && <NewReview />}
                    {this.state.actibeTab === 2 && <SelectExisting />}
                </div>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Child));