import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import CommonCss from "commonCss";
const connectedProps = (state) => ({
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    ...CommonCss.ProductDetails,
    list: {
      padding: "0px 30px 30px 0px",
      fontSize: "13px",
      listStyle: "none",
      marginTop: "30px",
      maxHeight: "calc(100vh - 280px)",
      overflowY: "auto",
      marginLeft: "0"
    }
  });

class SectionDrawer extends Component {
  state = {};

  render() {
    const { classes, sections } = this.props;

    return (
      <ul className={classes.list}>
        {sections &&
          sections.length > 0 &&
          sections.map((section, k) => {
            return (
              <a href={`#${section.slug}`}>
                <li className={classes.item} key={k}>
                  {section.name}
                </li>
              </a>
            );
          })}
      </ul>
    );
  }
}

export default connector(withStyles(styles)(SectionDrawer));
