import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Expent from './Components/Expent.jsx';
import { Provider } from 'react-redux';
import { AppStore } from './redux';
import { BrowserRouter as Router } from "react-router-dom";
import * as Enviornment from "./util/Environment";
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import ExpentTheme, { ThemeType } from './theme';
import { createBrowserHistory } from 'history';
import { LastLocationProvider } from "react-router-last-location";
import ErrorFallback from 'Components/ErrorFallback.jsx';
import { ErrorBoundary } from 'react-error-boundary';

//const history = createBrowserHistory();
// const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
// if (path) {
//   history.push(path);
// }

const onErrorHandler = (error,errorData) => {
  console.log(error,errorData);
}

ReactDOM.render(
    <Provider store={AppStore}>
      <ThemeProvider theme={ExpentTheme(ThemeType.LIGHT)}>
        <CssBaseline />
        <Router basename={Enviornment.host()} history={createBrowserHistory()}>
          <LastLocationProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={onErrorHandler}>
              <Expent />
            </ErrorBoundary>
          </LastLocationProvider>
        </Router>
      </ThemeProvider>
    </Provider>,
  document.getElementById('root')
);