export const Types = {
  TEMP_STORE_CLUSTER_GROUPING_PRODUCTS: 'TEMP_STORE_CLUSTER_GROUPING_PRODUCTS',

  SHOW_CLUSTER_PRODUCTS_DIALOG: 'SHOW_CLUSTER_PRODUCTS_DIALOG',
  HIDE_CLUSTER_PRODUCTS_DIALOG: 'HIDE_CLUSTER_PRODUCTS_DIALOG',

  SHOW_CREATE_CLUSTER_DIALOG: 'SHOW_CREATE_CLUSTER_DIALOG',
  HIDE_CREATE_CLUSTER_DIALOG: 'HIDE_CREATE_CLUSTER_DIALOG',

  START_CREATE_PRODUCT_CLUSTER_GROUP: 'START_CREATE_PRODUCT_CLUSTER_GROUP',
  END_CREATE_PRODUCT_CLUSTER_GROUP: 'END_CREATE_PRODUCT_CLUSTER_GROUP',

  START_FETCH_PRODUCT_CLUSTER_GROUPS: 'START_FETCH_PRODUCT_CLUSTER_GROUPS',
  END_FETCH_PRODUCT_CLUSTER_GROUPS: 'END_FETCH_PRODUCT_CLUSTER_GROUPS',

  START_ADD_PRODUCTS_TO_CLUSTER_GROUP: 'START_ADD_PRODUCTS_TO_CLUSTER_GROUP',
  END_ADD_PRODUCTS_TO_CLUSTER_GROUP: 'END_ADD_PRODUCTS_TO_CLUSTER_GROUP',

  START_FETCH_CLUSTER_GROUP_PRODUCTS: 'START_FETCH_CLUSTER_GROUP_PRODUCTS',
  END_FETCH_CLUSTER_GROUP_PRODUCTS: 'END_FETCH_CLUSTER_GROUP_PRODUCTS',

  START_DELETE_PRODUCT_IN_CLUSTER_GROUP: 'START_DELETE_PRODUCT_IN_CLUSTER_GROUP',
  END_DELETE_PRODUCT_IN_CLUSTER_GROUP: 'END_DELETE_PRODUCT_IN_CLUSTER_GROUP',

  START_DELETE_PRODUCT_CLUSTER_GROUP: 'START_DELETE_PRODUCT_CLUSTER_GROUP',
  END_DELETE_PRODUCT_CLUSTER_GROUP: 'END_DELETE_PRODUCT_CLUSTER_GROUP',

  START_UPDATE_PRODUCT_CLUSTER_GROUP: 'START_UPDATE_PRODUCT_CLUSTER_GROUP',
  END_UPDATE_PRODUCT_CLUSTER_GROUP: 'END_UPDATE_PRODUCT_CLUSTER_GROUP'
}