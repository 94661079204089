import {UPDATE_DRAWER_STYLE} from "./action";
import {UPDATE_CART_DRAWER_STYLE} from "./action";

const initState   =     {
    expanded:true,
    show:true,

    cartExpanded:true,
    cartShow:true,
}

export  function drawerReducer(state=initState,action){
    switch(action.type){
        case UPDATE_DRAWER_STYLE:
            return {
                ...state,
                expanded:action.payload.expanded,
                show:action.payload.show,
            };

        case UPDATE_CART_DRAWER_STYLE:
            return {
                ...state,
                cartExpanded:action.payload.expanded,
                cartShow:action.payload.show,
            };
        default:
            return state;
    }
}