import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment"

export const START_ADD_NEW_NOTE = "START_ADD_NEW_NOTE";
export const END_ADD_NEW_NOTE = "END_ADD_NEW_NOTE";

export const START_UPDATE_NOTE = "START_UPDATE_NOTE";
export const END_UPDATE_NOTE = "END_UPDATE_NOTE";

export const START_FETCH_NOTES = "START_FETCH_NOTES";
export const END_FETCH_NOTES = "END_FETCH_NOTES";

export const START_DELETE_NOTE = "START_DELETE_NOTE";
export const END_DELETE_NOTE = "END_DELETE_NOTE";

function startAddNewNote(slug) {
    return {
        type: START_ADD_NEW_NOTE,
        payload: {slug}
    };
}

function endAddNewNote(success, error,slug) {
    return {
        type: END_ADD_NEW_NOTE,
        payload: {
            success, error, slug
        }
    };
}

export function addNewNote(slug, data = {}) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/catalog/table?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddNewNote(slug));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endAddNewNote(response, error,slug));
        } catch (e) {
            dispatch(endAddNewNote(null, {
                message: e.message
            },slug));
            return;
        }
    };
}

function startUpdateNote(slug) {
    return {
        type: START_UPDATE_NOTE,
        payload: {slug}
    };
}

function endUpdateNote(success, error, slug) {
    return {
        type: END_UPDATE_NOTE,
        payload: {
            success, error, slug
        }
    };
}

export function updateNote(slug, id, data) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/catalog/table/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateNote(slug));
        try {
            let [response, error] = await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateNote(response, error,slug));
        } catch (e) {
            dispatch(endUpdateNote(null, {
                message: e.message
            },slug));
            return;
        }
    };
}

function startFetchNotes(data) {
    return {
        type: START_FETCH_NOTES,
        payload: {data}
    };
}

function endFetchNotes(success, error, data) {
    return {
        type: END_FETCH_NOTES,
        payload: {
            success, error, data
        }
    };
}

export function fetchNoteDatas(queryParam) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/catalog/table?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchNotes(queryParam));
        try {
            let [response, error] = await await HttpFactory.instance().getMethod(url, queryParam, false);
            dispatch(endFetchNotes(response, error,queryParam));
        } catch (e) {
            dispatch(endFetchNotes(null, {
                message: e.message
            },queryParam));
            return;
        }
    };
}

function startDeleteNote(slug) {
    return {
        type: START_DELETE_NOTE,
        payload: {slug}
    };
}

function endDeleteNote(id, success, error, slug) {
    return {
        type: END_DELETE_NOTE,
        payload: {
            success, error, id, slug
        }
    };
}

export function deleteNote(slug,id,params) {
    const api_server = Environment.api_host("DOMAIN");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/catalog/table/${id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteNote(slug));
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url, false, params);
            dispatch(endDeleteNote(id,response, error,slug));
        } catch (e) {
            dispatch(endDeleteNote(id,null, {
                message: e.message
            },slug));
            return;
        }
    };
}
