/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CommonCss from "commonCss";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import AppBarV2 from "Components/Application/AppBarV2";
import SideBar from "./Components/sideBar";
import ProfileRenderer from "./Components/profileRenderer";
import {
  addCompanySettings,
  fetchAllCompanySettings,
  storeData,
} from "redux/companyProfile/action";
import { showSnackBar } from "redux/snackbar/action";


import { createDataForProfileRender, createDataForSave } from "util/CompanyProfile";


const connectedProps = (state) => ({
  userRole: state.authUser.user?.Role,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);
const styles = (theme) =>
  createStyles({
    ...CommonCss.ProductDetails,
    linkItem: {
      cursor: "pointer !important",
    },
    emptyText: {
      textAlign: "center",
      height: 50,
      paddingTop: 20,
    },
    rootContainer: CommonCss.rootContainer,
    mainBody: {
      display: "flex",
    },
    sideMenu: {
      flex: 0.2,
      background: "#f2f5f6",
    },
    body: {
      flex: 0.8,
    },
  });

function CompanyProfileComHome(props) {
  const dispatch = useDispatch();

  const {
    fetchAllCompanySettingsSuccess,
    addCompanySettingsInprogress,
    addCompanySettingsFailure,
    addCompanySettingsSuccess
  } = useSelector((state) => state.companyProfile);
  const [activeMenu, setActiveMenu] = useState("COMPANY_PROFILE");
  const [isLoading, setIsLoading] = useState(true);
  const [menus] = useState([
    "COMPANY_PROFILE",
    "ENVIRONMENTAL",
    "RISK_COMPLIANCE",
    "FINANCIAL_PERFORMANCE",
    "RATING_REFERENCES",
  ]);

  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    if (window.location.hash) {
      const selected = window.location.hash.substring(1);
      if (menus.indexOf(selected) !== -1) {
        setActiveMenu(selected);
      }
    }
  }, [menus]);

  useEffect(() => {
    dispatch(fetchAllCompanySettings());
  }, []);


  useEffect(() => {
    if (fetchAllCompanySettingsSuccess) {
      console.log(fetchAllCompanySettingsSuccess, 'fetchAllCompanySettingsSuccess')
      let data = createDataForProfileRender(fetchAllCompanySettingsSuccess)
      dispatch(storeData(data));
      setTimeout(() => {
        setIsLoading(false)
      }, 200);
    }
  }, [activeMenu, fetchAllCompanySettingsSuccess]);

  useEffect(() => {
    if (addCompanySettingsInprogress === false && addCompanySettingsFailure === null && addCompanySettingsSuccess !== null) {
      dispatch(showSnackBar('Company Profile Updated Successfully', 'success', 3000));

    }
  }, [addCompanySettingsInprogress, addCompanySettingsFailure]);


  const { storeDataSuccess } = useSelector((state) => state.companyProfile);

  const [error, setError] = useState(false);

  const isValidURL = (url) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  const isValidPhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phone);
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateData = () => {
    let isValid = true;
    const updatedDataMap = {};
    for (const [sectionType, sections] of Object.entries(storeDataSuccess)) {
      if (sectionType === activeMenu) {
        console.log("validating errors for menu", sectionType);
        updatedDataMap[sectionType] = [];
        for (let i = 0; i < sections.length; i++) {
          const section = { ...sections[i] };
          if (section.itemGroups) {
            // eslint-disable-next-line no-loop-func
            section.itemGroups = section.itemGroups.map((group) => {
              const { items, ...restGroup } = group;
              const updatedGroupItems = items.map((item) => {
                let error = null;

                // Rule 1: Check if the required item has a value
                if (item.isRequired && !item.value) {
                  error = "This field is required.";
                }

                // Rule 2: Check for URL validation
                if (
                  item.type === "URL" &&
                  item.value &&
                  !isValidURL(item.value)
                ) {
                  error = "Invalid URL.";
                }

                // Rule 3: Check for Email validation
                if (
                  item.type === "EMAIL" &&
                  item.value &&
                  !isValidEmail(item.value)
                ) {
                  error = "Invalid email.";
                }

                // Rule 4: Check for Phone validation
                if (
                  item.type === "PHONE" &&
                  item.value &&
                  !isValidPhoneNumber(item.value)
                ) {
                  error = "Invalid " + item.label + '.';
                }

                // Rule 5: Check for Number validation
                if (item.type === "NUMBER" && item.value && isNaN(item.value)) {
                  error = "Invalid number.";
                }
                if (error) {
                  isValid = false;
                }

                console.log(
                  isValid,
                  error,
                  "error",
                  item.label,
                  item.type,
                  item.value
                );
                // Add the error message to the item if there is an error
                return { ...item, error };
              });
              return { ...restGroup, items: updatedGroupItems };
            });
          } else {
            // eslint-disable-next-line no-loop-func
            const updatedItems = section.items.map((item) => {
              let error = null;

              // Rule 1: Check if the required item has a value
              if (item.isRequired && !item.value) {
                error = "This field is required.";
              }

              // Rule 2: Check for URL validation
              if (
                item.type === "URL" &&
                item.value &&
                !isValidURL(item.value)
              ) {
                error = "Invalid URL.";
              }

              // Rule 3: Check for Email validation
              if (
                item.type === "EMAIL" &&
                item.value &&
                !isValidEmail(item.value)
              ) {
                error = "Invalid email.";
              }

              // Rule 4: Check for Phone validation
              if (
                item.type === "PHONE" &&
                item.value &&
                !isValidPhoneNumber(item.value)
              ) {
                error = "Invalid " + item.label + '.';
              }

              // Rule 5: Check for Number validation
              if (item.type === "NUMBER" && item.value && isNaN(item.value)) {
                error = "Invalid number.";
              }
              if (error) {
                isValid = false;
              }
              console.log(
                isValid,
                error,
                "error",
                item.label,
                item.type,
                item.value
              );
              // Add the error message to the item if there is an error
              return { ...item, error };
            });
            section.items = updatedItems;
          }
          updatedDataMap[sectionType].push(section);
        }
      } else {
        updatedDataMap[sectionType] = storeDataSuccess[sectionType];
      }
    }

    return { updatedDataMap, isValid };
  };

  const removeErrorsFromItems = () => {
    const updatedDataMap = {};
    for (const [sectionType, sections] of Object.entries(storeDataSuccess)) {
      updatedDataMap[sectionType] = [];

      // if (sectionType === activeMenu) {
      for (let i = 0; i < sections.length; i++) {
        const section = { ...sections[i] };
        if (section.itemGroups) {
          section.itemGroups = section.itemGroups.map((group) => {
            const updatedGroupItems = group.items.map((item) => {
              // Remove the 'error' attribute from each item
              const { error, ...itemWithoutError } = item;
              return itemWithoutError;
            });
            return { ...group, items: updatedGroupItems };
          });
        } else {
          const updatedItems = section.items.map((item) => {
            // Remove the 'error' attribute from each item
            const { error, ...itemWithoutError } = item;
            return itemWithoutError;
          });
          section.items = updatedItems;
        }
        updatedDataMap[sectionType].push(section);
      }
      // } else {
      //   updatedDataMap[sectionType] = storeDataSuccess[sectionType];
      // }
    }

    // Return the updated data map with the 'error' attribute removed from all items
    return updatedDataMap;
  };

  const handleActionButton = async (type) => {
    if (type === "EDIT") {
      setIsEdit(true);
    } else if (type === "CANCEL") {
      setIsEdit(false);
      setError(false);
      dispatch(storeData(removeErrorsFromItems()));
      dispatch(fetchAllCompanySettings());
    } else if (type === "SAVE") {
      let { updatedDataMap, isValid } = validateData();
      // console.log("isValid", isValid);
      dispatch(storeData(updatedDataMap));
      if (isValid) {
        setIsEdit(false);
        setError(false);
        console.log("isValid", isValid);
        console.log("make api request");
        console.log(updatedDataMap[activeMenu], 'ActiveMenuData')
        let dataToSave = createDataForSave(updatedDataMap[activeMenu])
        console.log(dataToSave, 'dataToSave-ActiveMenuData')

        // Making API request
        await dispatch(addCompanySettings(dataToSave));
        dispatch(fetchAllCompanySettings());
        //End
      } else {
        setIsEdit(true);
        setError(true);
      }
    }
  };

  const classes = props.classes;

  if (props.fetchProductDetailsUnderProgress) {
    return (
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <AppBarV2 title="Profile" withBack={true} dropdown={true} />
      <div className={classes.mainBody}>
        <div className={classes.sideMenu}>
          <SideBar
            activeMenu={activeMenu}
            handleClickMenu={(value) => {
              setActiveMenu(value);
              setIsEdit(false);
              setError(false);
              dispatch(storeData(removeErrorsFromItems()));
              props.history.push("#" + value);
            }}
          />
        </div>
        <div className={classes.body}>
          {!isLoading && <ProfileRenderer
            error={error}
            isChanges={isLoading}
            isEdit={isEdit}
            activeMenu={activeMenu}
            handleClickActionButton={(type) => {
              handleActionButton(type);
            }}
          />}

          {isLoading && <Box style={{ textAlign: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </Box>}
        </div>
      </div>
    </div>
  );
}

export default connector(compose(withStyles(styles))(CompanyProfileComHome));
