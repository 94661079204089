import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import {  Dialog, DialogContent, Slide,Grid,List ,ListItem, ListItemText,DialogTitle,IconButton  } from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import {hideEvaluationLinkDialog} from "redux/evaluation/link/action";
import Parent from "./Parent";
import Child from "./Child";

// dialogs
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen:state.evaluationLink.showDialog,
  currentEvaluation:state.evaluationMetaData.evaluation,
});

const connectionActions = {
  hideDialog:hideEvaluationLinkDialog,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent:{
    padding: theme.spacing(6, 12) +" !important"
  },
  content:{
    padding: theme.spacing(1) +" !important"
  },
  nav:{
    border:"solid 1px #ccc",
    borderRadius:theme.spacing(1),
    padding:theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class EvaluationLinkDialog extends React.Component {

    constructor(props){
        super(props);
        this.state  =   {
          type:"parent"
        }
    }

    componentDidMount(){

    }

    componentDidUpdate(){
      
    }

    render() {
        const classes = this.props.classes;
        return <Dialog 
                    onClose={this.props.hideDialog} 
                    aria-labelledby="app-integrationDialog" 
                    open={this.props.isOpen}
                    TransitionComponent={Transition}
                    disableBackdropClick={false}
                    fullWidth={true}
                    maxWidth={"md"}
                    scroll="body"
                    id="evLinkDialog"
                    >
                    <DialogTitle disableTypography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.hideDialog}>
                          <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent classes={{root:classes.dialogContent}}>
                      <Grid container spacing={2}>
                          <Grid item lg={4}>
                            <List component="nav" className={classes.nav}>
                              <ListItem
                                button
                                selected={this.state.type === "parent"}
                                onClick={(event) => {
                                  this.setState({type:"parent"})
                                }}
                              >
                                <ListItemText primary="Parent Evaluation" />
                              </ListItem>
                              <ListItem
                                button
                                selected={this.state.type === "child"}
                                onClick={(event) => {
                                  this.setState({type:"child"})
                                }}
                              >
                                <ListItemText primary="Child Evaluation" />
                              </ListItem>
                            </List>
                          </Grid>
                          <Grid lg={8}>
                              <div className={classes.content}>
                                {this.state.type === "parent" && <Parent />}
                                {this.state.type === "child" && <Child />}
                              </div>
                          </Grid>
                      </Grid>
                    </DialogContent>
                </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(EvaluationLinkDialog));