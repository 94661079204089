import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Typography, List, ListItem, CircularProgress, Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid } from '@material-ui/core';

import AskSeller from "assets/images/ask-seller.svg"
import arrowRight from 'assets/images/evaluation/arrowRight.svg';
import { Avatar } from "@material-ui/core";

import Image from 'Components/Common/image.jsx';
import Tooltip from '@material-ui/core/Tooltip';
import classnames from "classnames";

import LinearProgress from '@material-ui/core/LinearProgress';
import { setSelectedRequirement, setSelectedProduct, fetchLastEvaluationUserResponse } from "../../../../../../../../redux/evaluation/criteria/action"
import { showEvaluationAsksellerSelectDialog, hideEvaluationAsksellerSelectDialog } from "redux/templates/evaluation/action"
import ChooseAsksellerSelectModal from "../../../../../Settings/Templates/Common/ChooseAsksellerSelectModal";
import { showEvaluationProductEditDialog } from "redux/evaluation/cart/action";

import { showFinalizeReqDialog, showOverallReqDialog, showShowOverallUserProgressDialog } from "redux/evaluation/feedback/action";
import { showSellerFinalizeReqDialog } from "redux/seller/action";

import DefaultImage from "assets/images/evaluation/default.svg"
import FinalizeRequiremntViewDialog from "../../../../../../../Seller/Components/Common/FinalizeRequiremntViewDialog";
import OverallUserWiseProgressDialog from "../dialogs/OverallUserWiseProgressDialog";

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  lastEvaUserReponses: state.evaludationCriteria.lastEvaUserReponses,
  requirementTree: state.evaludationCriteria.requirementTree,
  fetchReqTreeProgress: state.evaludationCriteria.fetchReqTreeUnderProgress,
  selectedProductId: state.evaludationCriteria.selectedProductId,
  selectedRequirementId: state.evaludationCriteria.selectedRequirementId,
  productsScore: state.evaludationCriteria.productsScore,
  fetchEvaluationProductsScoreProgress: state.evaludationCriteria.fetchEvaluationProductsScoreProgress,
  requirementUserResponsesStatus: state.evaludationCriteria.requirementUserResponsesStatus,
  overallProductsScore: state.evaludationCriteria.overallProductsScore,
  fetchEvaluationOverallProductsScoreProgress: state.evaludationCriteria.fetchEvaluationOverallProductsScoreProgress,
  sellerEvaluationMeta: state.seller.evaluation,
  userRole: state.authUser.user?.Role,
  evaluationInfoData: state.evaluationMetaData.evaluationInfoData,
  fetchEvaluationInfoProgress: state.evaluationMetaData.fetchEvaluationInfoProgress,
  dialogEvaluationAskseller: state.templateEvaluation.dialogEvaluationAskseller,
});

const connectionActions = {
  setSelectedRequirement: setSelectedRequirement,
  setSelectedProduct: setSelectedProduct,
  fetchLastEvaluationUserResponse: fetchLastEvaluationUserResponse,
  showEvaluationAsksellerSelectDialog: showEvaluationAsksellerSelectDialog,
  hideEvaluationAsksellerSelectDialog: hideEvaluationAsksellerSelectDialog,
  showEvaluationProductEditDialog: showEvaluationProductEditDialog,
  showFinalizeReqDialog: showFinalizeReqDialog,
  showOverallReqDialog: showOverallReqDialog,
  showSellerFinalizeReqDialog: showSellerFinalizeReqDialog,
  showShowOverallUserProgressDialog: showShowOverallUserProgressDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    "& [class*=MuiAvatar-root]": {
      width: 30,
      height: 30,
      fontSize: 14
    },
  },
  currProductTitle: {
    marginBottom: theme.spacing(2),
    color: '#282D30',
    fontSize: theme.spacing(2.2)
  },
  progressBox: {
    border: "1px solid #f1f1f1",
    padding: theme.spacing(2, 2),
    borderRadius: theme.spacing(1),
    boxShadow: '0px 1px 10px -5px rgb(157 133 133 / 77%)'
  },
  productName: {
    // fontWeight: 600,
    marginLeft: theme.spacing(1),
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#398AF5',
    whiteSpace: 'nowrap'
  },
  productNameOri: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  productCompletion: {
    fontWeight: 600,
    color: '#282D30',
    opacity: '0.6',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  productCompleted: {
    color: '#2EB77D',
    opacity: '1'
  },
  productImg: {
    width: theme.spacing(3),
    marginRight: theme.spacing(0.6)
  },
  askSellerImg: {
    width: 20,
    marginRight: theme.spacing(0.6)
  },
  overAllDiv: {
    background: '#f1f1f1',
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(1),
    margin: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'space-between'
  },
  overAllText: {
    fontWeight: 600,
    fontSize: theme.spacing(1.8)
  },
  overAllPer: {
    float: 'right',
    color: 'green'
  },
  progTextComp: {
    margin: theme.spacing(1, 0),
    fontSize: theme.spacing(1.6)
  },
  pd_action: {
    textAlign: 'right',
    letterSpacing: '0px',
    color: '#0093E0 !important',
    opacity: 1,
    cursor: 'pointer',
    fontSize: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  otherProTitle: {
    margin: theme.spacing(2, 0, 1, 0),
    color: '#282D30',
    fontSize: theme.spacing(2.2)
  },
  otherItmBox: {
    // border: "1px solid #f1f1f1",
    padding: 0,
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer'
  },
  otherItems: {
  },
  askSellerMultiSelect: {

  },
  askSellerMultiSelectImage: {

  },
  currentProductInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  currentProductAskSeller: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
  },
  otherProductInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2)
  },
  otherProductAskSeller: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.spacing(1, 2)
  },
  productCompBox: {
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(1, 2)
  },
  nextProductBtn: {
    textAlign: 'right',
    flex: 1
  },
  otherProductDetails: {
    borderTop: '1px solid #f1f1f1',
    marginTop: 10,
  },
  productPerCom: {
    display: 'flex'
  },
  overallDetails: {
    display: 'flex',
    margin: '10px 0'
  },
  totalAnswered: {
    flex: 1,
    textAlign: 'right',
    margin: theme.spacing(1, 0),
    fontSize: theme.spacing(1.6)
  },
  answerIcon: {
    position: 'relative',
    top: '2px',
    marginLeft: '6px',
  },
  cartItem: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    padding: 12,
    border: '1px solid #EEEEEE',
    borderRadius: 10,
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between'
  },
  productNameContainer: {
    flex: 0.9,
  },
  productImage: {
    width: '100%',
    height: 'auto',
    marginRight: theme.spacing(2)
  },
  productImageContainer: {
    width: 67,
    height: 57,
    padding: 8,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 6px #0000001A',
    borderRadius: 10
  },
  productNameTitle: {
    fontSize: theme.spacing(2),
  },
  vendotDetail: {
    cursor: "pointer",
    color: "#4175DF",
    fontSize: theme.spacing(2),
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  askSellerDiv: {
    marginTop: theme.spacing(2)
  },
  askSellerEmailDiv1: {
    marginTop: theme.spacing(2),
  },
  askSellerEmailDiv: {
    marginTop: theme.spacing(2),
    // textAlign: 'right'
  },
  askSellerBtn: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: ' 1px solid #398AF5',
    borderRadius: '4px',
    letterSpacing: '0px',
    color: '#398AF5',
    padding: '3px 10px',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    fontSize: 16
  },
  askSellerBtnFull: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: ' 1px solid #398AF5',
    borderRadius: '4px',
    letterSpacing: '0px',
    color: '#398AF5',
    padding: '3px 10px',
    cursor: 'pointer',
    fontSize: 16,
    width: '100%'
  },
  borderBox: {
    border: '1px solid #f1f1f1',
    padding: '5px',
    marginTop: 15,
    boxShadow: '0px 1px 20px -6px rgb(157 133 133 / 77%)',
    borderRadius: theme.spacing(1),
  },
  avatarImg: {
    marginRight: 10
  },
  overAllViewBtns: {
    display: 'flex',
    alignItems: 'center'
  },
  viewDetailsBtn: {
  },
  showMoreBtn: {
    textAlign: 'right',
    flex: 1
  }
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#2EB77D',
  },
}))(LinearProgress);


class CurrentProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAnswers: [1, 2],
      selectedTab: 0,
      selectedUserReq: null,
      headerSectionWidth: null,
      isGetMaxScore: true
    }
    this.selectAskSeller = this.selectAskSeller.bind(this);
    this.showFinalizeDialog = this.showFinalizeDialog.bind(this);
    this.avatarName = this.avatarName.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    setTimeout(() => {
      this.updateDimensions();
    }, 100);
    if (this.props.isWorkFlow === undefined || this.props.isWorkFlow === null || this.props.isWorkFlow === false) {
      this.setState({
        isGetMaxScore: false
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchEvaluationInfoProgress === false && prevProps.fetchEvaluationInfoProgress === true) {
      this.setState({
        isGetMaxScore: false
      })
    }
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  showFinalizeDialog(type) {
    this.props.showSellerFinalizeReqDialog(type, false);
  }

  updateDimensions = () => {
    var headerSectionWidth = this.divElement?.clientWidth;
    var newWidth = headerSectionWidth * 4;
    if (this.state.headerSectionWidth !== newWidth) {
      this.setState({ headerSectionWidth: newWidth });
    }
  };


  selectAskSeller = (evaluationId, product) => {
    if (product.VendorEmail !== null && product.VendorEmail.length !== 0) {
      if (this.props.dialogEvaluationAskseller === true) {
        this.props.hideEvaluationAsksellerSelectDialog()
      } else {
        this.props.showEvaluationAsksellerSelectDialog(evaluationId, product)
      }
    } else {
      this.props.showEvaluationProductEditDialog(evaluationId, product, null, true)
    }
  }

  showEditDetailsDialog() {
    let evaluation_id = parseInt(this.props?.match?.params.evaluation_id)
    var products = this.props.evaluation !== undefined ? this.props.evaluation.Products : [];
    let product = ''
    products.forEach((prd, k) => {
      if (prd.ID === this.props.selectedProductId) {
        product = prd;
      }
    })
    if (product) {
      this.props.showEvaluationProductEditDialog(evaluation_id, product, null, false)
    }
  }

  render() {

    const classes = this.props.classes;
    var products = this.props.evaluation !== undefined ? this.props.evaluation.Products : [];

    var productScoreMap = {};
    var prScores = this.props.productsScore !== null ? this.props.productsScore : [];
    prScores.forEach(function (product) {
      productScoreMap[product.ID] = product.Stats;
    });

    // console.log(productScoreMap, 'productScoreMap')
    // Your Progress Calculation
    var userScore = productScoreMap[this.props.selectedProductId]?.UserScore;
    var scoreOpted = userScore?.TotalOpted === null ? 0 : userScore?.TotalOpted;
    var scoreMax = userScore?.TotalMax === null ? 0 : userScore?.TotalMax;
    var totalScore = (scoreOpted / scoreMax * 100);
    if (isNaN(totalScore)) {
      totalScore = 0;
    }

    var userProgress = productScoreMap[this.props.selectedProductId]?.UserProgress[0];
    var userTotalAnswered = userProgress?.Completed;
    var userTotalQuestions = userProgress?.Total;
    var newTotalProgress = Math.round(userTotalAnswered * 100 / userTotalQuestions);
    if (isNaN(newTotalProgress)) {
      newTotalProgress = 0;
    }

    // Overall Progress Calculation
    var overallScore = productScoreMap[this.props.selectedProductId]?.OverallScore;
    var overAllScoreOpted = overallScore?.TotalOpted === null ? 0 : overallScore?.TotalOpted;
    var overAllScoreMax = overallScore?.TotalMax === null ? 0 : overallScore?.TotalMax;
    var overAllTotalScore = (overAllScoreOpted / overAllScoreMax * 100);
    if (isNaN(overAllTotalScore)) {
      overAllTotalScore = 0;
    }


    var allUserProgress = productScoreMap[this.props.selectedProductId]?.AllUserProgress;

    // Seller EvaluationMeta
    var sellerProgress = productScoreMap[this.props.selectedProductId]?.SellerProgress;
    var totalSellerReq = sellerProgress?.Total;
    var totalSellerDone = sellerProgress?.Completed;
    var totalSellerProgress = 0;

    totalSellerProgress = Math.round(((totalSellerDone * 100) / totalSellerReq));
    if (isNaN(totalSellerProgress)) {
      totalSellerProgress = 0;
    }


    // Overall Score
    // console.log(this.props.overallProductsScore,'overallProductsScore');
    var overallProductScoreMap = {};
    var overallPrScores = (this.props.overallProductsScore !== undefined && this.props.overallProductsScore !== null) ? this.props.overallProductsScore : [];
    overallPrScores.forEach(function (product) {
      overallProductScoreMap[product.ID] = product;
    });

    var overallProductScore = overallProductScoreMap[this.props.selectedProductId];
    var overallScoreOpted = overallProductScore?.EvaluationScoreOpted === null ? 0 : overallProductScore?.EvaluationScoreOpted;
    var overallScoreMax = overallProductScore?.EvaluationScoreMax === null ? 0 : overallProductScore?.EvaluationScoreMax;
    var totalOverallScore = (overallScoreOpted / overallScoreMax * 100);
    if (isNaN(totalOverallScore)) {
      totalOverallScore = 0;
    }

    var totalOverallProgress = overallProductScore?.UserProgress === null ? 0 : overallProductScore?.UserProgress;
    if (totalOverallProgress === undefined) {
      totalOverallProgress = 0;
    }

    if (this.props.fetchProgress) {
      return <CircularProgress />
    }

    var maxScore = this.props.evaluationInfoData !== undefined && this.props.evaluationInfoData !== null && this.props.evaluationInfoData?.MaxScore && this.props.evaluationInfoData?.MaxScore !== null && this.props.evaluationInfoData?.MaxScore !== '' ? this.props.evaluationInfoData.MaxScore : 5;
    // console.log(this.props.evaluationInfoData,'evaluationInfoData-cp')
    // console.log(maxScore,'maxScore-cp')
    var absoluteScore = ((Math.round(totalScore) * maxScore) / 100);
    var overallAbsoluteScore = ((Math.round(overAllTotalScore) * maxScore) / 100)
    // console.log(absoluteScore,'absoluteScore-cp')

    const { headerSectionWidth } = this.state;
    const isSm = headerSectionWidth >= 481 && headerSectionWidth <= 1800 ? true : false;

    return (
      <div className={classes.root}>
        {products.map((product, k) => {
          if (product.ID !== this.props.selectedProductId) {
            return null;
          }

          var overallProgress = productScoreMap[this.props.selectedProductId]?.OverallProgress;

          // console.log(this.props.isHideAskSeller,'isHideAskSeller')

          return <div
            ref={(divElement) => { this.divElement = divElement }}>
            <Typography variant={"h6"} className={classes.currProductTitle} >
              Current product
            </Typography>
            <Box component="span" display="block" className={classes.progressBox}>
              <Grid container>
                <Grid item lg={12} xs={12} className={classes.currentProductInfo}>
                  <Tooltip className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{product.Name}</span>} arrow>
                    <div>
                      <Image alt="product" src={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo} className={classes.productImg} />
                      <span className={classes.productNameOri}> {product.Name} </span>
                    </div>
                  </Tooltip>
                </Grid>
                {this.props.isHideAskSeller === false && <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.askSellerDiv}>
                    <button className={classnames(isSm ? classes.askSellerBtnFull : classes.askSellerBtn, 'evaluate-ask-seller-btn')} onClick={() => {
                      let evaluation_id = parseInt(this.props?.match?.params.evaluation_id)
                      this.selectAskSeller(evaluation_id, product)
                    }}>
                      {this.props.sellerEvaluationMeta === null && <Image
                        src={AskSeller}
                        alt="forgotpassword_image"
                        className={classes.askImage}
                      />}
                      &nbsp;&nbsp;
                      {this.props.sellerEvaluationMeta !== null && this.props.sellerEvaluationMeta.criterias.length > 0 ? 'Vendor Details' : 'Ask Vendor'}
                    </button>
                  </div>
                </Grid>}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={isSm ? classes.askSellerEmailDiv1 : classes.askSellerEmailDiv}>
                    <Typography className={classnames(classes.vendotDetail, 'evaluate-seller-email')} onClick={() => {
                      let evaluation_id = parseInt(this.props?.match?.params.evaluation_id)
                      this.props.showEvaluationProductEditDialog(evaluation_id, product, null, false)
                    }}>{product?.VendorEmail !== null && product?.VendorEmail.length > 0 ? product?.VendorEmail : ""}</Typography>
                  </div>
                </Grid>
              </Grid>

              <div className={classnames(classes.yourProgress, classes.borderBox)}>
                {this.props.enableScoring && <div className={classes.overAllDiv}>
                  <span className={classes.overAllText}>Your Score</span>
                  <span className={classes.overAllPer}>{this.state.isGetMaxScore ? '-' : absoluteScore + '/' + maxScore}</span>
                  <span className={classes.overAllPer}>{Math.round(totalScore)}%</span>
                </div>}
                <Typography className={classes.progText}>
                  <span className={classes.overAllText}>Your Progress</span>
                  <div className={classes.productPerCom}>
                    <p className={classes.progTextComp}>{newTotalProgress}% Completed</p>
                    <span className={classes.totalAnswered}>{userTotalAnswered}/{userTotalQuestions}</span>
                  </div>
                </Typography>
                <BorderLinearProgress variant="determinate" value={newTotalProgress} />
                <Typography className={classes.pd_action} onClick={() => { this.props.showFinalizeReqDialog(false) }}>
                  View details
                </Typography>
              </div>

              {(this.props.userRole === "OrgAdmin" || this.props.userRole === "OrgManager") && <div className={classnames(classes.ovarallProgress, classes.borderBox)}>
                {this.props.enableScoring && <div className={classes.overAllDiv}>
                  <span className={classes.overAllText}>Overall Score</span>
                  <span className={classes.overAllPer}>{overallAbsoluteScore + '/' + maxScore}</span>
                  <span className={classes.overAllPer}>{Math.round(overAllTotalScore)}%</span>
                </div>}

                <div className={classes.overAllViewBtns}>
                  <div className={classes.viewDetailsBtn}>
                    <span className={classes.overAllText}>Overall Progress</span>
                  </div>
                  <div className={classes.showMoreBtn}>
                    <Typography className={classes.pd_action} onClick={() => { this.props.showOverallReqDialog(false) }}>
                      View details
                    </Typography>
                  </div>
                </div>

                {overallProgress !== undefined && overallProgress.length === 1 && <> <Typography className={classes.progText}>
                  {/* <span className={classes.overAllText}>Overall Progress</span> */}
                  <div className={classes.productPerCom}>
                    <p className={classes.progTextComp}>{overallProgress[0]?.Completed === 0 ? 0 : Math.round(((overallProgress[0]?.Completed * 100) / overallProgress[0]?.Total))}% Completed</p>
                    <span className={classes.totalAnswered}>{overallProgress[0]?.Completed}/{overallProgress[0]?.Total}</span>
                  </div>
                </Typography>
                  <BorderLinearProgress variant="determinate" value={overallProgress[0]?.Completed === 0 ? 0 : Math.round(overallProgress[0]?.Completed * 100 / overallProgress[0]?.Total)} />
                </>
                }

                {/* {overallProgress !== undefined && overallProgress.length > 1 && <Typography className={classes.progText}>
                  <span className={classes.overAllText}>Overall Progress</span>
                </Typography>} */}

                {allUserProgress !== undefined && allUserProgress !== null && allUserProgress.map((productProgress, k) => {
                  if (k > 1) {
                    return ''
                  }
                  return <>
                    <Typography className={classes.progText}>
                      <div className={classes.overallDetails}>
                        <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{productProgress?.User?.Name + "\n" + productProgress?.User?.Email}</span>} arrow>
                          <Avatar className={classes.avatarImg}> {this.avatarName(productProgress?.User?.Name)}</Avatar></Tooltip>
                        <p className={classes.progTextComp}>{productProgress?.Completed === 0 ? 0 : Math.round(productProgress?.Completed * 100 / productProgress?.Total)}% Completed</p>
                        <span className={classes.totalAnswered}>{productProgress?.Completed}/{productProgress?.Total}</span>
                      </div>
                    </Typography>
                    <BorderLinearProgress variant="determinate" value={productProgress?.Completed === 0 ? 0 : Math.round(productProgress?.Completed * 100 / productProgress?.Total)} />
                  </>
                })}

                {/* {overallProgress !== undefined && overallProgress.length <= 2 && <Typography className={classes.pd_action} onClick={() => { this.props.showOverallReqDialog(false) }}>
                  View details
                </Typography>} */}

                {allUserProgress !== undefined && allUserProgress.length > 2 && <div className={classes.overAllViewBtns}>
                  <div className={classes.viewDetailsBtn}>
                    {/* <Typography className={classes.pd_action} onClick={() => { this.props.showOverallReqDialog(false) }}>
                      View details
                    </Typography> */}
                  </div>
                  <div className={classes.showMoreBtn}>
                    <Typography className={classes.pd_action} onClick={() => { this.props.showShowOverallUserProgressDialog() }}>
                      Show more
                    </Typography>
                  </div>
                </div>}

              </div>}

              {totalSellerReq !== undefined && totalSellerReq !== null && totalSellerReq > 0 && <div className={classnames(classes.sellerProgress, classes.borderBox)}>
                <Typography className={classes.progText}>
                  <span className={classes.overAllText}>Vendor Progress</span>
                  <div className={classes.productPerCom}>
                    <p className={classes.progTextComp}>{Math.round(totalSellerProgress)}% Completed</p>
                    <span className={classes.totalAnswered}>{totalSellerDone}/{totalSellerReq}</span>
                  </div>
                </Typography>
                <BorderLinearProgress variant="determinate" value={Math.round(totalSellerProgress)} />
                <Typography className={classes.pd_action} onClick={() => { this.showFinalizeDialog('DONE') }}>
                  View details
                </Typography>
              </div>}

            </Box></div>
        })}
        {products.length > 1 && <Typography variant={"h6"} className={classes.otherProTitle}>
          Other products
        </Typography>}
        <List component="nav" className={classes.nav}>
          {products.map((product, k) => {
            if (product.ID === this.props.selectedProductId) {
              return null;
            }

            var productProgress = productScoreMap[product.ID]?.UserProgress[0];
            var productTotalAnswered = productProgress?.Completed;
            var productTotalQuestions = productProgress?.Total;
            var productTotalProgress = Math.round(productTotalAnswered * 100 / productTotalQuestions);
            if (isNaN(productTotalProgress)) {
              productTotalProgress = 0;
            }

            return <ListItem className={classes.otherItmBox} key={k + product.ID + '-evProducts'} >

              <Grid container alignItems="center" className={classes.cartItem} key={k + '1'}>
                <Grid item className={classes.productImageContainer} onClick={() => {
                  this.props.setSelectedRequirement(null);
                  this.props.setSelectedProduct(product.ID);
                  this.props.fetchLastEvaluationUserResponse(this.props.match.params.evaluation_id, product.ID);
                }}>
                  <Image alt="product" src={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo} className={classes.productImage} />
                </Grid>
                <Grid item className={classes.productNameContainer}>
                  <Typography variant={"h5"} className={classes.productNameTitle} onClick={() => {
                    this.props.setSelectedRequirement(null);
                    this.props.setSelectedProduct(product.ID);
                    this.props.fetchLastEvaluationUserResponse(this.props.match.params.evaluation_id, product.ID);
                  }}>{product.Name}</Typography>
                  <Divider style={{ margin: '8px 0px' }} />
                  <div className={classes.productCompBox} onClick={() => {
                    this.props.setSelectedRequirement(null);
                    this.props.setSelectedProduct(product.ID);
                    this.props.fetchLastEvaluationUserResponse(this.props.match.params.evaluation_id, product.ID);
                  }}>
                    <div>
                      {<span className={classes.productCompletion}> {Math.round(productTotalProgress)}% Completed </span>}
                    </div>
                    <div className={classes.nextProductBtn}>
                      <img className={classes.askSellerImg} src={arrowRight} alt={product.Name} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </ListItem>
          })}
        </List>
        {this.props.dialogEvaluationAskseller && <ChooseAsksellerSelectModal
          onClickEdit={() => {
            this.showEditDetailsDialog()

          }}
        />}
        <FinalizeRequiremntViewDialog />
        <OverallUserWiseProgressDialog />
      </div>
    );
  }
}

CurrentProduct.defaultProps = {
  isWorkFlow: false,
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(CurrentProduct));