import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "../Style/index.css";
import CloseIcon from '@material-ui/icons/Close';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import LoopIcon from '@material-ui/icons/Loop';
import PhoneIcon from '@material-ui/icons/Phone';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import CallMissedIcon from '@material-ui/icons/CallMissed';
import Arrow from '../Components/Arrow';
const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  triggerNode: {
    border: '1px solid #222'
  }
});

class StepMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMenu: false
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  render() {
    const { showArrowTop, showArrowBottom } = this.props;
    return (
      <>
        {showArrowTop && <Arrow
          isShowAddOption={false}
        />}

        <div role="menu" class="add-step__container" >
          <div class="add-step__header ">What happens next?</div>
          <div class="add-step__content ">
            <div data-leave-actions-controls="" class="add-step__controls ">
              <button type="button" role="menuitem" class="add-step__control"
                onClick={() => {
                  this.props.onClickHandle('ACTION')
                }}
              >
                <span class="add-step__icon-wrapper ">
                  <SystemUpdateAltIcon />
                </span>
                <span class="add-step__name"> Action in an app </span>
              </button>
              <button type="button" role="menuitem" class="add-step__control"
                onClick={() => {
                  this.props.onClickHandle('IF')
                }}>
                <span class="add-step__icon-wrapper ">
                  <CallMissedIcon />
                </span>
                <span class="add-step__name"> IF condition </span>
              </button>
              <button type="button" role="menuitem" class="add-step__control"
                onClick={() => {
                  this.props.onClickHandle('IF_ELSE')
                }}>
                <span class="add-step__icon-wrapper ">
                  <CallSplitIcon />
                </span>
                <span class="add-step__name"> IF/ELSE condition </span>
              </button>
              {<button type="button" role="menuitem" class="add-step__control"
                onClick={() => {
                  this.props.onClickHandle('LOOP')
                }}>
                <span class="add-step__icon-wrapper ">
                  <LoopIcon />
                </span>
                <span class="add-step__name"> Repeat action </span>
              </button>}
              {false && <button type="button" role="menuitem" class="add-step__control"
                onClick={() => {
                  this.props.onClickHandle('FUNCTION_CALL')
                }}>
                <span class="add-step__icon-wrapper ">
                  <PhoneIcon />
                </span>
                <span class="add-step__name"> Call function </span>
              </button>}
            </div>
            <button type="button" aria-label="Close" class="add-step__close " onClick={() => {
              this.props.hideStepMenu()
            }}>
              <CloseIcon />
            </button>
          </div>
        </div>

        {showArrowBottom && <Arrow
          isShowAddOption={false}
        />}

      </>
    );
  }
}


StepMenu.defaultProps = {
  showArrowTop: false,
  showArrowBottom: false
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(StepMenu));

