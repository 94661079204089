// Dialog product Details
export const SHOW_PRODUCT_DETAILS_DIALOG = "SHOW_PRODUCT_DETAILS_DIALOG";
export const HIDE_PRODUCT_DETAILS_DIALOG = "HIDE_PRODUCT_DETAILS_DIALOG";

export const SHOW_SEARCH_TEAM_POPER_DIALOG = "SHOW_SEARCH_TEAM_POPER_DIALOG";
export const HIDE_SEARCH_TEAM_POPER_DIALOG = "HIDE_SEARCH_TEAM_POPER_DIALOG";

export const SHOW_SEARCH_USER_POPER_DIALOG = "SHOW_SEARCH_USER_POPER_DIALOG";
export const HIDE_SEARCH_USER_POPER_DIALOG = "HIDE_SEARCH_USER_POPER_DIALOG";

export const SHOW_SEARCH_USER_TEAM_POPER_DIALOG = "SHOW_SEARCH_USER_TEAM_POPER_DIALOG";
export const HIDE_SEARCH_USER_TEAM_POPER_DIALOG = "HIDE_SEARCH_USER_TEAM_POPER_DIALOG";

export const SHOW_USER_DETAILS_POPER_DIALOG = "SHOW_USER_DETAILS_POPER_DIALOG";
export const HIDE_USER_DETAILS_POPER_DIALOG = "HIDE_USER_DETAILS_POPER_DIALOG";

export const SHOW_CUSTOM_EMAIL_POPER_DIALOG = "SHOW_CUSTOM_EMAIL_POPER_DIALOG";
export const HIDE_CUSTOM_EMAIL_POPER_DIALOG = "HIDE_CUSTOM_EMAIL_POPER_DIALOG";

export const SHOW_SEARCH_EXTERNAL_USER_POPER_DIALOG = "SHOW_SEARCH_EXTERNAL_USER_POPER_DIALOG";
export const HIDE_SEARCH_EXTERNAL_USER_POPER_DIALOG = "HIDE_SEARCH_EXTERNAL_USER_POPER_DIALOG";

export const SHOW_SEARCH_VENDOR_EXTERNAL_USER_POPER_DIALOG = "SHOW_SEARCH_VENDOR_EXTERNAL_USER_POPER_DIALOG";
export const HIDE_SEARCH_VENDOR_EXTERNAL_USER_POPER_DIALOG = "HIDE_SEARCH_VENDOR_EXTERNAL_USER_POPER_DIALOG";

export const SHOW_DEPRECATE_PRODUCT_DIALOG = "SHOW_DEPRECATE_PRODUCT_DIALOG";
export const HIDE_DEPRECATE_PRODUCT_DIALOG = "HIDE_DEPRECATE_PRODUCT_DIALOG";

export const SHOW_BLOCK_PRODUCT_DIALOG = "SHOW_BLOCK_PRODUCT_DIALOG";
export const HIDE_BLOCK_PRODUCT_DIALOG = "HIDE_BLOCK_PRODUCT_DIALOG";

export const SHOW_REQUEST_ACCESS_INFO_DIALOG = "SHOW_REQUEST_ACCESS_INFO_DIALOG";
export const HIDE_REQUEST_ACCESS_INFO_DIALOG = "HIDE_REQUEST_ACCESS_INFO_DIALOG";

export const SHOW_DOCUMENT_DETAILS_DIALOG = "SHOW_DOCUMENT_DETAILS_DIALOG";
export const HIDE_DOCUMENT_DETAILS_DIALOG = "HIDE_DOCUMENT_DETAILS_DIALOG";

/**
 * product Details dialog
 */
export function showProductDetailsDialog(event, id) {
  return {
    type: SHOW_PRODUCT_DETAILS_DIALOG,
    payload: {
      event: event,
      id: id || null,
    },
  };
}

export function hideProductDetailsDialog() {
  return {
    type: HIDE_PRODUCT_DETAILS_DIALOG,
    payload: null,
  };
}

export function showDeprecateProductMessageDialog() {
  return {
    type: SHOW_DEPRECATE_PRODUCT_DIALOG,
    payload: {},
  };
}

export function hideDeprecateProductMessageDialog() {
  return {
    type: HIDE_DEPRECATE_PRODUCT_DIALOG,
    payload: {},
  };
}

export function showBlockProductMessageDialog() {
  return {
    type: SHOW_BLOCK_PRODUCT_DIALOG,
    payload: {},
  };
}

export function hideBlockProductMessageDialog() {
  return {
    type: HIDE_BLOCK_PRODUCT_DIALOG,
    payload: {},
  };
}

export function showUserDetailsPoperDialog(data) {
  return {
    type: SHOW_USER_DETAILS_POPER_DIALOG,
    payload: { data },
  };
}

export function hideUserDetailsPoperDialog() {
  return {
    type: HIDE_USER_DETAILS_POPER_DIALOG,
    payload: {},
  };
}

export function showAddCustomEmailPoperDialog() {
  return {
    type: SHOW_CUSTOM_EMAIL_POPER_DIALOG,
    payload: {},
  };
}

export function hideAddCustomEmailPoperDialog() {
  return {
    type: HIDE_CUSTOM_EMAIL_POPER_DIALOG,
    payload: {},
  };
}

export function showSearchTeamsPoperDialog() {
  return {
    type: SHOW_SEARCH_TEAM_POPER_DIALOG,
    payload: {},
  };
}

export function hideSearchTeamsPoperDialog() {
  return {
    type: HIDE_SEARCH_TEAM_POPER_DIALOG,
    payload: {},
  };
}

export function showSearchUserAndTeamsPoperDialog(data) {
  return {
    type: SHOW_SEARCH_USER_TEAM_POPER_DIALOG,
    payload: { data },
  };
}

export function hideSearchUserAndTeamsPoperDialog() {
  return {
    type: HIDE_SEARCH_USER_TEAM_POPER_DIALOG,
    payload: {},
  };
}

export function showSearchUsersPoperDialog(params = {}) {
  return {
    type: SHOW_SEARCH_USER_POPER_DIALOG,
    payload: { params },
  };
}

export function hideSearchUsersPoperDialog() {
  return {
    type: HIDE_SEARCH_USER_POPER_DIALOG,
    payload: {},
  };
}

export function showSearchExternalUsersPoperDialog(params = {}) {
  return {
    type: SHOW_SEARCH_EXTERNAL_USER_POPER_DIALOG,
    payload: { params },
  };
}

export function hideSearchExternalUsersPoperDialog() {
  return {
    type: HIDE_SEARCH_EXTERNAL_USER_POPER_DIALOG,
    payload: {},
  };
}

export function showSearchVendorExternalUsersPoperDialog(params = {}) {
  return {
    type: SHOW_SEARCH_VENDOR_EXTERNAL_USER_POPER_DIALOG,
    payload: { params },
  };
}

export function hideSearchVendorExternalUsersPoperDialog() {
  return {
    type: HIDE_SEARCH_VENDOR_EXTERNAL_USER_POPER_DIALOG,
    payload: {},
  };
}

// ---
export function showRequestAccessInformationDialog(payload) {
  return {
    type: SHOW_REQUEST_ACCESS_INFO_DIALOG,
    payload: payload,
  };
}

export function hideRequestAccessInformationDialog() {
  return {
    type: HIDE_REQUEST_ACCESS_INFO_DIALOG,
    payload: {},
  };
}

export function showDocumentDetailDialog(payload) {
  return {
    type: SHOW_DOCUMENT_DETAILS_DIALOG,
    payload: payload,
  };
}

export function hideDocumentDetailDialog() {
  return {
    type: HIDE_DOCUMENT_DETAILS_DIALOG,
    payload: {},
  };
}