import { Button, CircularProgress, Grid } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Beta from "assets/images/beta.svg";
import findProduct from "assets/images/find_product.svg";
import classnames from "classnames";
import AppBarV2 from "Components/Application/AppBarV2";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import NewEvaluationDialog from "Components/Application/Components/Evaluate/Components/Common/NewEvaluationDialog";
import { isEnabledEvaluate } from "util/Common";
// import ToolTip from "Components/Common/ToolTip";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";

import { authorizedUser } from "redux/authuser/action";
import { updateDrawerStyle } from "redux/drawer/action";
// Redux
import { fetchEvaluateStatusCount, showAddNewEvaluationDialog } from "redux/evaluate/action";
import { createEvaluationCart, fetchEvaluationCart, removeProductFromCart } from "redux/evaluation/cart/action";
// import Cart from "Components/Application/Components/Evaluate/Components/Common/Cart";
import { fetchCompanyRecommendationsProducts, fetchProductReaction, fetchWishListedProducts, productSearch, productAllSearch } from "redux/product/action";
import { fetchAddOrgProductCountStats, fetchOrgProducts } from "redux/product/orgProduct/action";
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import ToolTip from "Components/Common/ToolTip";
import { isEnabledReview } from "util/Common";
import DefaultImage from "assets/images/evaluation/default.svg";
import ExistingProductImage from "assets/images/evaluation/existing_product.svg";
import MyDevicesImage from "assets/images/icons/myDevices.svg";
import RecommendationImage from "assets/images/evaluation/recommendation.svg";
import ExistingUnderReview from "assets/images/evaluation/under_review.svg";
import AppCategoryImage from "assets/images/category_icon.svg";
import WishlistImage from "assets/images/evaluation/Wishlist.svg";
import CommonCss from "../../../../../../commonCss";
import { fetchProductCategory } from "redux/product/category/action";
import { fetchAppCatlogProducts } from "redux/product/appCatalog/action";
import { fetchVendors } from "redux/vendor/action";
import { showSnackBar } from "redux/snackbar/action";
import { fetchOrgWishlistedProducts, fetchWishListedProductList } from "redux/product/action";
import { fetchVendorCatalog } from "redux/vendor/vendorCatalog/action";
import { fetchProductRisk } from "redux/risk/action";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import { showAddVendorDialog } from "redux/vendor/vendorCatalog/action";
import AddVendorOptionDialog from "Components/Common/Dialogs/AddVendorOptionDialog";

const connectedProps = (state) => ({
  products: state.products.products,
  totalProduct: state.products.totalProduct,
  categoryFetchProgress: state.productCategory.fetchCategoryProgress,
  categories: state.productCategory.categories,
  totalCategories: state.productCategory.totalCategories,
  fetchProductsProgress: state.products.fetchProductsProgress,
  evaluationCart: state.evaluationCart.evaluation,
  statusCount: state.evaluate.statusCount,
  existingProductCount: state.metric.vendors,
  orgProductCountStats: state.orgProduct.orgProductCountStats,
  totalOrgProducts: state.orgProduct.totalOrgProducts,
  orgName: state.authUser.user?.Organization?.Name,
  userRole: state.authUser.user?.Role,
  orgProduct: state.orgProduct?.orgProducts,
  user: state.authUser.user,
  addwishlistedProductsProgress: state.products.addwishlistedProductsProgress,
  companyRecommendationsProducts: state.products.companyRecommendationsProducts,
  orgUser: state.orgUser,
  userType: state.authUser.user?.Role,
  addProductStateData: state.products.addProductStateData,
  addProductStateProg: state.products.addProductStateProg,
  addProductStateError: state.products.addProductStateError,
  deleteProductStateError: state.products.deleteProductStateError,
  deleteProductStateProg: state.products.deleteProductStateProg,
  // myWishListedProducts: state.products.myWishListedProducts,
  myWishListedProducts: state.products.wishlistedProducts,
  myOrgWishlistedProducts: state.products.myOrgWishlistedProducts,
  fetchAppCatalogProductsProgress: state.appCatalog.fetchAppCatalogProductsProgress,
  fetchAppCatalogProductsError: state.appCatalog.fetchAppCatalogProductsError,
  appCatalogProducts: state.appCatalog.products,
  fetchVendorProgress: state.vendors.fetchVendorProgress,
  fetchVendorError: state.vendors.fetchVendorError,
  vendors: state.vendors.vendorsData,
  VendorCatalog: state.vendorCatalog.VendorCatalog,
  fetchExpentDirectoryProductsProgress: state.expentDirectory.fetchExpentDirectoryProductsProgress,
  fetchExpentDirectoryProductsError: state.expentDirectory.fetchExpentDirectoryProductsError,
  expentDirectoryProducts: state.expentDirectory.products,
  fetchProductRisksProgress: state.risk.fetchProductRisksProgress,
  fetchProductRisksError: state.risk.fetchProductRisksError,
  productRisks: state.risk.productRisks,
  addVendorDialogOpen: state.vendorCatalog.addVendorDialogOpen,

});

const connectionActions = {
  fetchProductCategory: fetchProductCategory,
  showAddNewEvaluationDialog: showAddNewEvaluationDialog,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  updateDrawerStyle: updateDrawerStyle,
  fetchEvaluationCart: fetchEvaluationCart,
  fetchEvaluateStatusCount: fetchEvaluateStatusCount,
  fetchAddOrgProductCountStats: fetchAddOrgProductCountStats,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  fetchOrgProducts: fetchOrgProducts,
  authorizedUser: authorizedUser,
  showSnackBar: showSnackBar,
  fetchWishListedProducts: fetchWishListedProducts,
  fetchProductReaction: fetchProductReaction,
  fetchCompanyRecommendationsProducts: fetchCompanyRecommendationsProducts,
  productSearch: productSearch,
  productAllSearch: productAllSearch,
  fetchOrgWishlistedProducts: fetchOrgWishlistedProducts,
  fetchWishListedProductList: fetchWishListedProductList,
  fetchAppCatlogProducts: fetchAppCatlogProducts,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  fetchVendors: fetchVendors,
  fetchVendorCatalog: fetchVendorCatalog,
  fetchProductRisk: fetchProductRisk,
  showAddVendorDialog:showAddVendorDialog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    head: {
      position: "relative",
    },
    topContainer: {
      marginTop: theme.spacing(0),
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(6),
    },
    title: {
      fontSize: theme.spacing(2.4),
    },
    viewAll: CommonCss.viewAll,
    productHead: {
      ...CommonCss.productHead,
      fontSize: 18,
      margin: "0px !important",
    },

    productTitleSection: {
      ...CommonCss.productTitleSection,
      marginTop: 40,
      marginBottom: 25,
    },
    cartSection: {
      "& [class*=Cart-root], #cartSection": {
        transition: "all 400ms linear",
        padding: 21,
        right: -300,
        "& *": {
          opacity: 0,
          transition: "all 600ms ease-in",
          // whiteSpace: 'nowrap'
        },
      },
      "&.showCart": {
        "& [class*=Cart-root], #cartSection": {
          right: "0 !important",
          "& *": {
            opacity: 1,
          },
        },
      },
    },
    skeleton: {
      height: "174px",
      width: "100%",
      marginTop: 37,
      borderRadius: 10,
    },
    LinkTag: {
      textDecoration: "none",
      color: "unset",
    },
    topHead: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 40,
      marginBottom: 8,
      "& > .appBar": {
        flex: 1,
      },
    },
    actionContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 175,
      marginTop: 10,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    newProduct: {
      fontWeight: 600,
      color: "#282D30",
      fontSize: "1.45em",
      marginBottom: 0,
    },
    productSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // position: 'absolute',
      width: "100%",
      margin: "20px 0px 40px 0px",
    },
    recommendProduct: {
      position: "relative",
      "& .layer-card-title": {
        padding: 0,
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      },
    },
    myProductCard: {
      "& .layer-card-title": {
        padding: 0,
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      },
    },
    beta: {
      position: "absolute",
      // top: 46,
      right: 16,
      zIndex: 30,
    },
  });

class ProductsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageSize: 6,
      items: [],
      isCartShow: false,
      appCatalogObj: {
        field_setname: "all",
        text: "",
        filter_fields: {},
        analytics_only: false,
        qtype_page: null,
        page_size: 0,
        sort_fields: [],
      },
      vendorObj: {
        query: "",
        page: 0,
        page_size: 6,
      },
      refreshBrowseByProduct: true,
      showRiskRegister: true,
    };
    this.fetchData = this.fetchData.bind(this);
    this.addToCart = this.addToCart.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(preProps) {
    if (this.props.fetchProductRisksProgress === false && preProps.fetchProductRisksProgress === true) {
      if (this.props.fetchProductRisksError === null) {
        if (this.props.productRisks && this.props.productRisks.total !== undefined && this.props.productRisks.total > 0) {
          this.setState({
            showRiskRegister: true,
          });
        }
      }
    }

    this.cartDrawer(preProps);
  }

  cartDrawer = (preProps) => {
    if (
      (this.props.evaluationCart && this.props.evaluationCart.Products && this.props.evaluationCart.Products.length) !==
      (preProps.evaluationCart && preProps.evaluationCart.Products && preProps.evaluationCart.Products.length)
    ) {
      this.setState({ isCartShow: true });
      setTimeout(() => {
        this.setState({ isCartShow: false });
      }, 2000);
    }
  };

  fetchData() {
    this.props.fetchAppCatlogProducts(this.state.appCatalogObj);
    this.props.fetchAddOrgProductCountStats();
    this.props.fetchProductCategory(this.state.page, this.state.pageSize, "");
    this.props.fetchEvaluateStatusCount();
    if (this.props.userRole === "OrgUser") {
      this.props.fetchOrgProducts(this.state.page, this.state.pageSize, this.state.query);
    }
    this.props.fetchWishListedProducts();
    this.props.fetchCompanyRecommendationsProducts();
    this.props.fetchWishListedProductList(0, 500, "", "wishlisted");
    setTimeout(() => {
      this.props.fetchOrgWishlistedProducts("org_wishlisted");
    }, 1000);

    this.props.fetchProductRisk({
      page: this.state.page,
      pageSize: this.state.pageSize,
      product_ids: [],
      types: [3],
      priorities: [1, 2, 3],
      status: [1, 2, 3],
    });
    this.props.fetchExpentDirectoryProducts({
      field_setname: "all",
      text: "",
      filter_fields: {},
      page: 0,
      page_size: 6,
      sort_fields: [],
    });
    this.props.fetchVendorCatalog({ query: "", page: 0, page_size: 6, filters: { is_org: "1" } });
    this.props.fetchVendors(this.state.vendorObj);
  }

  addToCart(id) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(parseInt(id), null, "MASTER_VENDOR");
    } else {
      var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.filter((p) => parseInt(p.EntityID) === parseInt(id));
      if (addedToCart && addedToCart.length > 0) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, parseInt(addedToCart[0].ID));
      } else {
        this.props.createEvaluationCart(parseInt(id), this.props.evaluationCart.ID, "MASTER_VENDOR");
      }
    }
  }

  cartOpen = () => {
    this.setState({ isCartShow: true });
  };

  cartClose = () => {
    this.setState({ isCartShow: false });
  };

  routeChange = (path) => {
    this.props.history.push(`${this.props.match.path}/${path}`);
  };

  producDetail = (e, product) => {
    this.props.history.push(`/app/products/org-product-details/${product.ID}`);
  };
  reviewProduct = (id) => {
    // e.stopPropagation();
    let redirectPath = `/app/products/product-review/${id}/`;
    this.props.history.push(redirectPath);
  };

  productStates = (obj) => {
    if (obj) {
      const data = Object.keys(obj).map((key) => ({
        StateName: key,
        StateID: obj[key],
      }));
      return data;
    } else {
      return [];
    }
  };

  render() {
    const classes = this.props.classes;
    // var isCart = this.props.evaluationCart !== null;
    var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.map((p) => parseInt(p.EntityID));

    var showAddProduct = false;
    const EnableAddProductForUserCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableAddProductForUserCheck");
    if (this.props.userType === "OrgUser") {
      if (EnableAddProductForUserCheck) {
        showAddProduct = true;
      }
    } else {
      showAddProduct = true;
    }

    let lg = 2;
    if (addedToCart?.length > 0) {
      lg = 3;
    }

    let totalCompanyRecommendationsProducts = 0;
    if (
      this.props.companyRecommendationsProducts?.OtherProducts &&
      Array.isArray(this.props.companyRecommendationsProducts?.OtherProducts) &&
      this.props.companyRecommendationsProducts?.OtherProducts.length > 0
    ) {
      totalCompanyRecommendationsProducts += this.props.companyRecommendationsProducts?.OtherProducts.length;
    }
    if (
      this.props.companyRecommendationsProducts?.CategoryProducts &&
      Array.isArray(this.props.companyRecommendationsProducts?.CategoryProducts) &&
      this.props.companyRecommendationsProducts?.CategoryProducts.length > 0
    ) {
      const data = this.props.companyRecommendationsProducts?.CategoryProducts;
      data.forEach((element) => {
        totalCompanyRecommendationsProducts += element.category_products.length;
      });
    }

    let orgExistingProductCount = this.props.appCatalogProducts?.existing?.total || 0;
    let otherProductCount = this.props.expentDirectoryProducts?.others?.total || 0;
    let existingProductCount = this.props.expentDirectoryProducts?.existing?.total || 0;
    let expentProductCount = otherProductCount + existingProductCount;

    let vendorCount = this.props.vendors?.total || 0;

    var myWishlisted = this.props.myWishListedProducts || [];
    var orgWishlisted = this.props.myOrgWishlistedProducts?.Data || [];

    const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "DisableCatalogFeature");

    const EnableEvaluateForUsersCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForUsersCheck");
    const EnableEvaluateForAdminsCheck = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableEvaluateForAdminsCheck");
    let isShowCheckBox = true;
    if ((!EnableEvaluateForAdminsCheck && this.props.userRole !== "OrgUser") || (!EnableEvaluateForUsersCheck && this.props.userRole === "OrgUser")) {
      isShowCheckBox = false;
    }

    const EnableRecommendation = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableRecommendation')

    return (
      <div className={classes.head}>
        <AppBarV2 title="Vendors" />
        <div className={classes.rootContainer}>
          <div className={classes.productSection}>
            <p className={classes.newProduct}></p>
            {showAddProduct && (
              // <Button className={classes.newUserBtn} onClick={() => this.props.showAddProductManuallyDialog()}>
              //   + Add vendor
              // </Button>

              <Button className={classes.newUserBtn} onClick={() => this.props.showAddVendorDialog()}>
                + Add vendor
              </Button>
            )}
          </div>
          {showAddProduct && <br />}
          <div id="topContainer" className={classes.topContainer}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={lg} className={classes.myProductCard}>
                  <LayeredCard
                    title={this.props.orgName ? this.props.orgName + "'s Vendor Catalog" : "Vendor Catalog"}
                    subTitle={DisableVendor ? orgExistingProductCount.toLocaleString("en-US") + " Vendors" : this.props.VendorCatalog?.total + " Vendors"}
                    color="BLUE"
                    image={ExistingProductImage}
                    onClick={() => {
                      if (DisableVendor) {
                        this.props.history.push(this.props.match.path + "/org-products");
                      } else {
                        this.props.history.push("/app/vendors/org-vendors");
                      }
                    }}
                    toolTip="42"
                  />
                </Grid>

                {process.env.REACT_APP_ENV === "dev" && (
                  <Grid item xs={12} sm={6} md={4} lg={lg} className={classes.myProductCard}>
                    <LayeredCard
                      title="My devices"
                      subTitle="6 devices"
                      color="GREY"
                      image={MyDevicesImage}
                      onClick={() => {
                        this.props.history.push(this.props.match.path + "/my-devices");
                      }}
                    />
                  </Grid>
                )}
                {(["OrgAdmin","OrgManager"].includes(this.props.userRole) || showAddProduct) && this.props.orgProductCountStats?.inReview > 0 && (
                  <Grid item xs={12} sm={6} md={4} lg={lg}>
                    <LayeredCard
                      title="Unverified Products"
                      color="GREY"
                      subTitle={this.props.orgProductCountStats?.inReview + " Products"}
                      image={ExistingUnderReview}
                      onClick={() => {
                        this.props.history.push(this.props.match.path + "/unverified-product");
                      }}
                      toolTip="9"
                    />
                  </Grid>
                )}

                {false && orgExistingProductCount > 0 && (
                  <Grid item xs={12} sm={6} md={4} lg={lg} className={classes.myProductCard}>
                    <LayeredCard
                      title={this.props.orgName ? this.props.orgName + "'s Application Category" : "Application Category"}
                      subTitle={orgExistingProductCount + " Products"}
                      color="BLUE"
                      image={AppCategoryImage}
                      onClick={() => {
                        this.props.history.push(this.props.match.path + "/org-categories");
                      }}
                    // toolTip='10'
                    />
                  </Grid>
                )}

                {EnableRecommendation && <Grid item xs={12} sm={6} md={4} lg={lg} className={classes.recommendProduct}>
                    <img src={Beta} alt="beta" className={classes.beta} />
                    <LayeredCard
                      title="Want Expent to recommend products?"
                      subTitle={<span>&nbsp;&nbsp;</span>}
                      color="CYAN"
                      image={findProduct}
                      onClick={() => this.props.showAddNewEvaluationDialog(true)}
                    />
                  </Grid>
                }

                {["OrgAdmin","OrgManager"].includes(this.props.userRole) && EnableRecommendation && totalCompanyRecommendationsProducts > 0 && (
                  <Grid item xs={12} sm={6} md={4} lg={lg} className={classes.myProductCard}>
                    <LayeredCard
                      title={"Recommended products for " + this.props.orgName}
                      color="GREY"
                      subTitle={totalCompanyRecommendationsProducts + " Products"}
                      image={RecommendationImage}
                      onClick={() => {
                        this.props.history.push(this.props.match.path + "/company-recommendation");
                      }}
                    // toolTip='40'
                    />
                  </Grid>
                )}

                {myWishlisted !== undefined && myWishlisted !== null && myWishlisted.length > 0 && (
                  <Grid item xs={12} sm={6} md={4} lg={lg} className={classes.myProductCard}>
                    <LayeredCard
                      title={"My Wishlisted"}
                      color="GREY"
                      subTitle={myWishlisted.length.toLocaleString("en-US") + " Products"}
                      image={WishlistImage}
                      onClick={() => {
                        this.props.history.push(this.props.match.path + "/my-wishlisted");
                      }}
                    />
                  </Grid>
                )}

                {orgWishlisted !== undefined && orgWishlisted !== null && orgWishlisted.length > 0 && ["OrgAdmin","OrgManager"].includes(this.props.userRole) && (
                  <Grid item xs={12} sm={6} md={4} lg={lg} className={classes.myProductCard}>
                    <LayeredCard
                      title={"Org Wishlisted"}
                      color="GREY"
                      subTitle={orgWishlisted.length.toLocaleString("en-US") + " Products"}
                      image={WishlistImage}
                      onClick={() => {
                        this.props.history.push(this.props.match.path + "/org-wishlisted");
                      }}
                    />
                  </Grid>
                )}

                {["OrgAdmin","OrgManager"].includes(this.props.userRole) && this.state.showRiskRegister && (
                  <Grid item xs={12} sm={6} md={4} lg={lg}>
                    <LayeredCard
                      title="Risk Register"
                      color="GREY"
                      subTitle={<span>&nbsp;&nbsp;</span>}
                      image={ExistingUnderReview}
                      onClick={() => {
                        this.props.history.push(this.props.match.path + "/risks");
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
          <div className={classes.bottomContainer}>
            {DisableVendor && (
              <>
                {" "}
                <div className={classes.productTitleSection}>
                  <Link to="/app/products/all-categories" className={classnames(classes.productHead, classes.categories, classes.viewAll)}>
                    Browse by Categories
                  </Link>
                  {this.props.totalCategories > 6 && (
                    <Link to="/app/products/all-categories" className={classes.viewAll}>
                      View all ({this.props.totalCategories.toLocaleString("en-US")})
                    </Link>
                  )}
                </div>
                <div className={classes.categoriesList}>
                  <Grid container spacing={3}>
                    {/* Dummy Skeleton */}
                    {this.props.categoryFetchProgress && (
                      <>
                        {[1, 2, 3, 4, 5, 6].map((i, k) => {
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={lg} key={k}>
                              <Skeleton variant="rect" className={classes.skeleton} />
                            </Grid>
                          );
                        })}
                      </>
                    )}

                    {this.props.categories.map((category, k) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={lg} key={"cat_" + k}>
                          <LayeredCard
                            title={category.Name}
                            numTitleLines={3}
                            subTitle=""
                            image={category.Logo == null || category.Logo.length === 0 ? DefaultImage : category.Logo}
                            showSubtitle={false}
                            onClick={() => {
                              this.props.history.push("/app/products/category-wise-product/" + category.ID);
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
                <div>
                  <div className={classes.productTitleSection}>
                    <Link to="/app/products/all-products" className={classnames(classes.viewAll, classes.productHead)}>
                      Browse by Products <ToolTip toolTip="14" />
                    </Link>
                    {expentProductCount > 6 && (
                      <Link to="/app/products/all-products" className={classes.viewAll}>
                        View all ({expentProductCount.toLocaleString("en-US")})
                      </Link>
                    )}
                  </div>
                  <div className={classes.cardSection}>
                    {/* Dummy Skeleton */}
                    <Grid container spacing={3}>
                      {this.props.fetchExpentDirectoryProductsProgress === true && this.state.refreshBrowseByProduct && (
                        <Grid container spacing={3}>
                          {[1, 2, 3, 4, 5, 6].map((category, k) => {
                            return (
                              <Grid item xs={12} sm={6} md={3} lg={lg}>
                                {" "}
                                <Skeleton variant="rect" className={classes.skeleton} />
                              </Grid>
                            );
                          })}
                          ;
                        </Grid>
                      )}

                      {(!this.props.fetchExpentDirectoryProductsProgress || !this.state.refreshBrowseByProduct) &&
                        this.props.expentDirectoryProducts?.others?.items &&
                        this.props.expentDirectoryProducts?.others?.items.map((product, index) => {
                          return (
                            <Grid item xs={12} sm={6} md={3} lg={lg} key={index}>
                              <LayeredCard
                                isActionButtons={true}
                                title={product.name || product.company_name || ""}
                                subTitle={product.company_name || product.name || ""}
                                description={product.description || ""}
                                image={product.logo || ""}
                                toogleCheck={() => {
                                  this.addToCart(product.id);
                                }}
                                onClick={() => { }}
                                numLayer={0}
                                customRoute="/app/products/product-details/"
                                showCheckbox={isShowCheckBox}
                                id={parseInt(product.id)}
                                uniqueId={"_j_" + index}
                                type={"product"}
                                productStates={this.productStates(product.states)}
                                showReactionResponses
                                typeOfBlock="all_product_blocking"
                                typeOfFeatured="featured"
                                source={"BROWSE"}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Grid container justify="center">
                      {(this.props.addProductStateProg || this.props.deleteProductStateProg) && <CircularProgress />}
                    </Grid>
                  </div>
                </div>
              </>
            )}

            {!DisableVendor && (
              <div>
                <div className={classes.productTitleSection}>
                  <Link to="/app/vendors/all-vendors" className={classnames(classes.viewAll, classes.productHead)}>
                    Browse by Vendors
                  </Link>
                  {vendorCount > 6 && (
                    <Link to="/app/vendors/all-vendors" className={classes.viewAll}>
                      View all ({vendorCount.toLocaleString("en-US")})
                    </Link>
                  )}
                </div>
                <div className={classes.cardSection}>
                  {/* Dummy Skeleton */}
                  <Grid container spacing={3}>
                    {this.props.fetchVendorProgress === true && (
                      <Grid container spacing={3}>
                        {[1, 2, 3, 4, 5, 6].map((v, k) => {
                          return (
                            <Grid item xs={12} sm={6} md={3} lg={lg} key={k}>
                              <Skeleton variant="rect" className={classes.skeleton} />
                            </Grid>
                          );
                        })}
                      </Grid>
                    )}

                    {!this.props.fetchVendorProgress &&
                      this.props.vendors?.items?.map((vendor, index) => {
                        return (
                          <Grid item xs={12} sm={6} md={3} lg={lg} key={index}>
                            <LayeredCard
                              title={vendor.name}
                              numLayer={0}
                              subTitle={vendor.name}
                              image={vendor.logo == null || vendor.logo.length === 0 ? DefaultImage : vendor.logo}
                              onClick={() => {
                                this.props.history.push(`/app/vendors/all-vendors/${vendor.id}`);
                              }}
                              showCheckbox
                              id={parseInt(vendor.id)}
                              customRoute={`/app/vendors/all-vendors/${vendor.master_vendor_id}`}
                              checked={addedToCart?.indexOf(parseInt(vendor.master_vendor_id)) !== -1}
                              toogleCheck={(e) => {
                                this.addToCart(vendor.master_vendor_id);
                              }}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </div>
              </div>
            )}
          </div>
          <NewEvaluationDialog />
        {this.props.addVendorDialogOpen && <AddVendorOptionDialog type={"vendor"} />}

        </div>
      </div>
    );
  }
}

export default connector(compose(withStyles(styles))(ProductsHome));
