import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
// import classnames from "classnames";
import AppBarV2 from "Components/Application/AppBarV2";
import { Grid, ClickAwayListener } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
// import { withAsyncPaginate } from "react-select-async-paginate";
// import Select from "react-select";
import axios from "axios";
import * as Environment from "util/Environment";
import { CommonFn } from "services/commonFn";
// const CreatableAsyncPaginate = withAsyncPaginate(Select);

const connectedProps = (state) => ({
  userType: state.authUser.user?.Role,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    searchArea: {
      flex: "1 !important",
      padding: "0px 20px",
    },
    searchInput: {
      background: "#F7F7F7",
      border: "1px solid #888",
      borderRadius: 17,
      width: 34,
      height: 34,
      display: "flex",
      alignItem: "center",
      padding: "0px 10px",
      justifyContent: "center",
      cursor: "pointer",
      "& > div": {},
      "-webkit-transition": "width 500ms",
      "-moz-transition": "width 500ms",
      transition: "width 500ms",
    },
    expandSearch: {
      width: "100%",
    },
  });

class CollaboratorPopper extends Component {
  constructor(props) {
    super();
    this.state = {
      isSearchProduct: false,
    };
  }

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("SEARCH");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      search_name: "all_products",
      type: "all",
      page,
      size: 100,
      text: inputValue,
      filter_fields_value: {},
      sort_field: null,
      ascending: null,
    };
    // setPage(page+1)
    let url = `${api_server}/v2/products?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });

    let productsData = [];
    try {
      if (response?.data?.data) {
        const { org_products } = response.data.data;
        org_products.forEach((m) => {
          const data = m;
          if (data.product_id && (m.name || m.company_name)) {
            data.value = parseInt(m.product_id);
            data.label = m.name || m.company_name;
            productsData.push(data);
          }
        });
      }
    } catch (e) {}
    return {
      options: productsData,
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  };

  render() {
    const { classes } = this.props;

    // const selectStyles = {
    //   container: (base) => ({
    //     ...base,
    //     flex: 1,
    //   }),
    //   control: () => ({
    //     height: 30,
    //     minHeight: 30,
    //     fontSize: 16,
    //     border: "none",
    //     borderRadius: 0,
    //     width: "100%",
    //     textAlign: "left",
    //     cursor: "pointer",
    //   }),
    //   valueContainer: (base) => ({
    //     ...base,
    //     padding: "0 !important",
    //   }),
    // };

    return (
      <AppBarV2 title={this.props.title} withBack={true} dropdown={true}>
        <Grid className={classes.searchArea} alignItems="center" container justify="flex-end">
          {/* <ClickAwayListener
              onClickAway={(e) => {
                e.stopPropagation();
                this.setState({
                  isSearchProduct: false,
                });
              }}
            >
              <div className={classnames(classes.searchInput, this.state.isSearchProduct ? classes.expandSearch : {})}>
                <SearchIcon
                  style={{ color: "#888", marginTop: 3 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!this.state.isSearchProduct) {
                      this.setState({ isSearchProduct: true });
                    }
                  }}
                />
                {this.state.isSearchProduct && (
                  <CreatableAsyncPaginate
                    isClearable={false}
                    autoFocus
                    isSearchable={true}
                    classNamePrefix="select"
                    placeholder="Search Products"
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    onInputChange={(e) => {}}
                    additional={{
                      page: 0,
                    }}
                    onChange={(e) => {
                      if (e.value) {
                        const { hash } = this.props.location;
                        let url = `/app/products/org-product-details/${e.value}`;
                        if (hash) {
                          url += hash;
                        }
                        window.location = url;
                      }
                    }}
                    isValidNewOption={(input) => input.length > 0}
                    defaultValue={{}}
                    loadOptions={this.getOptions}
                    styles={selectStyles}
                  />
                )}
              </div>
            </ClickAwayListener> */}
        </Grid>
      </AppBarV2>
    );
  }
}

export default connector(withStyles(styles)(CollaboratorPopper));
