import * as Validator from "util/Validation";

export const workflowColumns = [
    { id: 'requestId', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Project Id' },
    { id: 'name', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Project Name' },
    { id: 'projectOwnerID', type: 'user', userUpdate: true, numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Procurement owner' },
    { id: 'workflow_name', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Workflow name' },
    { id: 'workflow_type', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Project Type' },
    { id: 'requester_name', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Requestor' },
    // { id: 'status', numeric: false, align: 'left', isVisible: true, isSortRequire:false, disablePadding: false, label: 'Status' },
    { id: 'currentStep', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Current Step' },
    { id: 'requestStatus', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Project Status' },
    // { id: 'currentStepDays', numeric: false, align: 'left', isVisible: true, isSortRequire:false, disablePadding: false, label: 'Number of days in current step' },
    { id: 'creation_date', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Created at' },
    { id: 'last_update', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Last Updated' },
    // { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
]

export const workflowTrackingColumns = [
    { id: 'requestId', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Project Id' },
    { id: 'name', numeric: false, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Project Name' },
]

export const appCatalogColumns = [
    { id: 'name', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Name' },
    { id: 'company_name', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Company Name' },
    { id: 'description', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Description' },
    // { id: 'logo', numeric: false, align:'left', isVisible:true,disablePadding: false, label: 'Logo' },
    { id: 'start_date', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Start Date' },
    { id: 'end_date', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'End Date' },
    { id: 'renewal_date', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Renewal Date' },
    { id: 'business_criticality', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Business Criticality' },
    { id: 'cost_per_license', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Cost Per License' },
    { id: 'number_of_license_procured', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Number of License Procured' },
    { id: 'total_cost', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Total Cost' },
    { id: 'departments', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Departments' },
    { id: 'type', numeric: false, align: 'left', isVisible: true, isSortRequire: true, disablePadding: false, label: 'Type' },
    // { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
]

export const renewalTableColumns = [
    { id: 'contractName', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Contract' },
    { id: 'renewalDate', type: 2, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Renewal Date' },
    { id: 'renewalInDays', type: 'Number', align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Renewal In Days' },
    { id: 'ownerName', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Contract Owner' },
    { id: 'requestStatus', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Request Status' },
    { id: 'currentStep', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Current Step' },
    { id: 'projectOwnerName', type: 1, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Project Owner' },
    { id: 'status', type: 100, align: 'left', isVisible: true, isDefault: true, isSortRequire: false, disablePadding: false, label: 'Status' },
    // { id: 'entity', type: 1, align: 'left', isVisible: true, isSortRequire: false, disablePadding: false, label: 'Entity' },
]

export function getUpdatedWorkflowColumns() {
    let columns = [...workflowColumns];
    const localColumns = window.localStorage.getItem("workflowListingColumns");
    if (localColumns !== undefined && localColumns !== null && Validator.isJson(localColumns)) {
        let oldColumns = JSON.parse(localColumns)
        if (oldColumns.length > 0) {

            let visibilityMap = {};
            oldColumns.forEach(function (cl) {
                visibilityMap[cl.id] = cl?.isVisible;
            })
            // let newColumns = [...workflowColumns];
            // oldColumns.forEach(item => {
            //     if (item.isDynamic) {
            //         newColumns.push(item)
            //     }
            // })
            // columns = newColumns.map((itm) => {
            columns = oldColumns.map((itm) => {
                let isVisible = visibilityMap[itm.id] !== undefined ? visibilityMap[itm.id] : true;
                return {
                    ...itm,
                    isVisible
                }
            })
            // console.log(columns,oldColumns, 'newStateColumns')
        }
    }
    // console.log(columns,'columns12345')
    return columns;
}

export function getRenewalColumns(renewalColumns) {
    let columns = [];
    const localColumns = window.localStorage.getItem("renewalListingColumns");
    if (localColumns !== undefined && localColumns !== null && Validator.isJson(localColumns)) {
        let localStorageCols = JSON.parse(localColumns);
        if (localStorageCols.length > 0) {
            let availColumns = []
            let availColumnsMap = {}
            renewalColumns.forEach(function (col) {
                availColumns.push(col.id)
                availColumnsMap[col.id] = col;
            })
            let addedFromColumns = []
            localStorageCols.forEach(function (col) {
                if (availColumns.indexOf(col.id) !== -1) {
                    addedFromColumns.push(String(col.id))
                    col.label = availColumnsMap[col.id].label
                    columns.push(col)
                }
            })
            Object.keys(availColumnsMap).forEach(function (k) {
                let col = availColumnsMap[k];
                if (addedFromColumns.indexOf(k) === -1) {
                    columns.push(col)
                }
            })
        } else {
            columns = renewalColumns;
        }
    } else {
        columns = renewalColumns;
    }
    window.localStorage.setItem("renewalListingColumns", JSON.stringify(columns));
    return columns;
}

export function getRiskRegisterColumns(riskRegisterColumns) {
    let columns = [];
    const localColumns = window.localStorage.getItem("riskRegisterColumns");
    if (localColumns !== undefined && localColumns !== null && Validator.isJson(localColumns)) {
        let localStorageCols = JSON.parse(localColumns);
        if (localStorageCols.length > 0) {
            let availColumns = []
            let availColumnsMap = {}
            riskRegisterColumns.forEach(function (col) {
                availColumns.push(col.id)
                availColumnsMap[col.id] = col;
            })
            let addedFromColumns = []
            localStorageCols.forEach(function (col) {
                if (availColumns.indexOf(col.id) !== -1 && col.id !== 'Action') {
                    addedFromColumns.push(String(col.id))
                    col.label = availColumnsMap[col.id].label
                    columns.push(col)
                }
            })
            let actionCol;
            Object.keys(availColumnsMap).forEach(function (k) {
                let col = availColumnsMap[k];
                if (addedFromColumns.indexOf(k) === -1 && col.id !== 'Action') {
                    columns.push(col)
                }
                if (col.id === 'Action') {
                    actionCol = col;
                }
            })
            columns.push(actionCol)
        } else {
            columns = riskRegisterColumns;
        }
    } else {
        columns = riskRegisterColumns;
    }
    window.localStorage.setItem("riskRegisterColumns", JSON.stringify(columns));
    return columns;
}

export function updateTrackingColumns(formColumns) {
    console.log('TrackingLocalColumns-CalllingggggggUpdate')
    let columns = [];
    const localColumns = window.localStorage.getItem("projectTrackingTableColumns");
    if (localColumns !== undefined && localColumns !== null && Validator.isJson(localColumns)) {
        let localStorageCols = JSON.parse(localColumns);
       
        if (localStorageCols.length > 0) {
            let availColumns = []
            let availColumnsMap = {}
            formColumns.forEach(function (col) {
                availColumns.push(col.id)
                availColumnsMap[col.id] = col;
            })
            let addedFromColumns = []
            localStorageCols.forEach(function (col) {
                if (col && availColumns.indexOf(col.id) !== -1 && col.id !== 'Action') {
                    addedFromColumns.push(String(col.id))
                    col.label = availColumnsMap[col.id].label
                    columns.push(col)
                }
            })
            let actionCol;
            Object.keys(availColumnsMap).forEach(function (k) {
                let col = availColumnsMap[k];
                if (addedFromColumns.indexOf(k) === -1 && col.id !== 'Action') {
                    columns.push(col)
                }
                if (col.id === 'Action') {
                    actionCol = col;
                }
            })
            columns.push(actionCol)
        } else {
            columns = formColumns;
        }
    } else {
        // columns = [...workflowColumns];
        formColumns.forEach(function (col) {
            columns.push({
                id: col.id,
                numeric: false,
                align: 'left',
                isVisible: true,
                isSortRequire: false,
                disablePadding: false,
                label: col.label,
                type: col.type
            })
        })
    }
    window.localStorage.setItem("projectTrackingTableColumns", JSON.stringify(columns));
}

export function getFinalTrackingColumns(formFields) {
    console.log('TrackingLocalColumns-Calllinggggggg-Getttttttttt')
    console.log(formFields,'formFields')
    let columns = [];
    const localColumns = window.localStorage.getItem("projectTrackingTableColumns");
    if (localColumns !== undefined && localColumns !== null && Validator.isJson(localColumns)) {
        let oldColumns = JSON.parse(localColumns)
        // console.log(oldColumns, 'oldColumnsTrackingggggggggggggg')
        if (oldColumns.length > 0) {
            let visibilityMap = {};
            oldColumns.forEach(function (cl) {
                if(cl){
                    visibilityMap[cl.id] = cl?.isVisible;
                }
            })
            oldColumns.forEach((itm) => {
                if(itm){
                    let isVisible = visibilityMap[itm.id] !== undefined ? visibilityMap[itm.id] : true;
                    // if (typeof (itm.id) === 'number' && formFields && formFields[String(itm.id)] === undefined) {
                    //     return;
                    // } else if (typeof (itm.id) === 'number' && formFields) {
                    //     itm.label = formFields[String(itm.id)].label
                    // }
                    columns.push({
                        ...itm,
                        isVisible
                    })
                }
            })
        }

        // const trackingFormColumns = window.localStorage.getItem("projectTrackingFormColumns");
        // if (trackingFormColumns !== undefined && trackingFormColumns !== null && Validator.isJson(trackingFormColumns)) {
        //     let oldColumns = JSON.parse(trackingFormColumns)
        //     oldColumns.forEach(function (cl) {
        //         columns.push(cl)
        //     })
        // }

    } else {
        columns = [...workflowTrackingColumns];
    }

    return columns;
}

export function getAllSelectedFormColumnsObj() {
    let prevFormColumnsObj = {};
    const formColumnsObj = window.localStorage.getItem("allSelectedFormColumnsObj");
    if (formColumnsObj !== undefined && formColumnsObj !== null && Validator.isJson(formColumnsObj)) {
        prevFormColumnsObj = JSON.parse(formColumnsObj)
    }
    return prevFormColumnsObj;
}

export function getAllTrackingSelectedFormColumnsObj() {
    let prevFormColumnsObj = {};
    const formColumnsObj = window.localStorage.getItem("allTrackingSelectedFormColumnsObj");
    if (formColumnsObj !== undefined && formColumnsObj !== null && Validator.isJson(formColumnsObj)) {
        prevFormColumnsObj = JSON.parse(formColumnsObj)
    }
    return prevFormColumnsObj;
}

export function getUpdatedAppCatalogColumns() {
    let columns = appCatalogColumns;
    const localColumns = window.localStorage.getItem("appCatalogColumns");
    if (localColumns !== undefined && localColumns !== null && Validator.isJson(localColumns)) {
        let oldColumns = JSON.parse(localColumns)
        if (oldColumns.length > 0) {
            let visibilityMap = {};
            oldColumns.forEach(function (cl) {
                visibilityMap[cl.id] = cl?.isVisible;
            })

            columns = appCatalogColumns.map((itm) => {
                let isVisible = visibilityMap[itm.id] !== undefined ? visibilityMap[itm.id] : true;
                return {
                    ...itm,
                    isVisible
                }
            })
            // console.log(columns, 'newStateColumns')
        }
    }
    return columns;
}

export function createWorkflowData() {
    let workflowDatas = [];
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].forEach(function (itm) {
        let obj = {
            name: 'Evaluation' + itm,
            workflow_name: 'Legal Review' + itm,
            workflow_type: itm % 2 === 0 ? 'Evaluation' : 'Review',
            requester_name: itm % 2 === 0 ? 'Sunil Kumar' : 'Lokesh',
            status: itm % 2 === 0 ? 'In Progress' : 'Completed',
            currentStepDays: 10,
            creation_date: itm % 2 === 0 ? '08/08/2022' : '01/01/2022',
            last_update: itm % 2 === 0 ? '08/08/2022' : '01/01/2022',
        }
        workflowDatas.push(obj);
    })
    return workflowDatas;
}

export function geFlowType(flow_type) {
    let type = 'Evaluation'
    if (flow_type !== undefined && flow_type !== null && flow_type !== '') {
        if (1 === flow_type) type = 'Evaluation'
        if (2 === flow_type) type = 'Review'
        if (3 === flow_type) type = 'Survey'
        if (4 === flow_type) type = 'Renewal'
        if (10 === flow_type) type = 'Other'
    }
    return type
}



