import { Typography, Switch, TextField, DialogTitle, DialogContent, Dialog, Slide } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import CheckboxTree from "react-checkbox-tree";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CommonCss from 'commonCss';
import SqureButton from "Components/Common/SqureButton";
import Checkbox from "@material-ui/core/Checkbox";
import { showSnackBar } from "redux/snackbar/action";
import { hideCloneCatalogSectionDialog } from "redux/vendor/action";
import { addOrgVendorDetailsTabSection } from "redux/vendor/vendorCatalog/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  orgCatalogAllTabs: state.vendorCatalog.orgCatalogAllTabs,
  hideCloneCatalogSectionDialog: state.vendors.hideCloneCatalogSectionDialog,
  addOrgVendorDetailTabsSectionProgress: state.vendorCatalog.addOrgVendorDetailTabsSectionProgress,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  hideCloneCatalogSectionDialog: hideCloneCatalogSectionDialog,
  addOrgVendorDetailsTabSection: addOrgVendorDetailsTabSection,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
      marginBottom: 60,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
    },
    body: {
    },
    actions: {
      textAlign: "center",
      margin: '2rem'
    },
    actionBtn: {
      minWidth: "200px",
      marginLeft: 10,
    },
    formControl: {
      ...CommonCss.formControl,
      '& > div': {
        backgroundColor: 'tarsparent !important',
        border: 'none',
        paddingRight: 0,
      },
      padding: '.375rem',
    },
  });

class CloneCatalogSectionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      name: "",
      nodes: [],
      itemIds: [],
      selectAllItems: false,
      checked: [],
      showAddView: false,
      readOnly: false,
    };
  }

  componentDidMount() {
    this.initNodes();
  }
  componentDidUpdate(prevProps) {}

  initNodes() {
    const { orgCatalogAllTabs } = this.props;
    let tabs = orgCatalogAllTabs || [];
    var nodes = [];
    tabs.forEach((tab) => {
      var obj = {
        value: "Tab_" + tab.slug,
        label: tab.name,
        children: [],
      };
      let isGoodToAdd = true;
      if (tab.sections) {
        tab.sections.forEach((section, i) => {
          let isGoodToAddSection = true;
          let sectionName = `Section ${i + 1}`;
          if (section.name && section.name.length > 0) {
            sectionName = section.name;
          }
          var secObj = {
            value: section.id,
            label: sectionName,
            children: [],
          };
          if (section.items) {
            section.items.forEach((item, j) => {
              let itemName = `Sub Section ${j + 1}`;
              if (item.name && item.name.length > 0) {
                itemName = item.name;
              }
              secObj.children.push({
                value: item.id,
                label: itemName,
              });
            });
          } else {
            isGoodToAddSection = false;
          }
          if (isGoodToAddSection && secObj.children.length > 0) {
            obj.children.push(secObj);
          }
        });
      } else {
        isGoodToAdd = true;
      }
      if (isGoodToAdd && obj.children.length > 0) {
        nodes.push(obj);
      }
    });
    this.setState({
      nodes: nodes,
    });
  }

  selectAllItems = () => {
    var type = this.state.selectAllItems === true ? false : true;
    var checkedItems = [];
    var nodes = this.state.nodes;
    if (type) {
      nodes.forEach((tab) => {
        checkedItems.push(tab.value);
        tab.children.forEach((section) => {
          checkedItems.push(section.value);
          section.children.forEach((item) => {
            checkedItems.push(item.value);
          });
        });
      });
    }
    this.setState({
      selectAllItems: type,
      checked: checkedItems,
    });
  };

  onSave = () => {
    let checkedData = this.state.checked;
    var itemIds = [...checkedData];
    
    let tabs = this.props.orgCatalogAllTabs || [];
    let seq = this.props.currentTab?.sections?.length + 1 || 1
    var sectionsData = [];
    tabs.forEach((tab) => {
      if (tab.sections) {
        tab.sections.forEach( (section, i) => {
          let itemArr = []
          if (section.items) {
            section.items.forEach( (item,j) => {
              if (itemIds.includes(item.id) ) {
                itemArr.push({
                  name: item.name,
                  description: item.description,
                  slug: item.slug,
                  is_default: item.is_default,
                  type: item.type,
                  additional_data: item.additional_data,
                  sequence: j + 1,
                });
              }
            });
          }
          if(itemArr.length > 0) {
            sectionsData.push({
              is_default: section.is_default,
              is_editable: !this.state.readOnly,
              name: this.state.name.length > 0 ? this.state.name : section.name,
              sequence: seq+i,
              slug: section.slug,
              visibility: section.visibility,
              teams: section.teams,
              items: itemArr
            })
          }
        });
      }
      
    });

    this.props.cloneSection(sectionsData);
  };

  render() {
    const classes = this.props.classes;

    const { name, nodes, checked, expanded } = this.state;

    const loading = this.props.addOrgVendorDetailTabsSectionProgress;

    return (
      <Dialog
        onClose={this.props.hideCloneCatalogSectionDialog}
        aria-labelledby="AddUserDialog"
        open={true}
        TransitionComponent={Transition}
        scroll={"body"}
        fullWidth={true}
        className={classes.dialog}
        id={`productBLockDialog-add-user`}
      >
        <DialogTitle id={`form-dialog-title-add-user`}>
          Clone Section
        </DialogTitle>
        <DialogContent style={{ overflowY: "unset" }}>
          <div className={classes.body}>
            
            <TextField
              fullWidth
              className={classes.formControl}
              value={name}
              InputProps={{ disableUnderline: true }}
              placeholder="Enter Section Name"
              onChange={e => this.setState({ name: e.target.value })}
            />
            <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
              <Typography>Read Only</Typography>
              <Switch
                label={"Read Only"}
                className={classes.criteriaAllSelect}
                color="primary"
                checked={this.state.readOnly}
                onChange={(e) => {
                  this.setState({readOnly: e.target.checked});
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
              <Checkbox
                label={"Select All"}
                className={classes.criteriaAllSelect}
                color="primary"
                checked={this.state.selectAllItems}
                onChange={() => {
                  this.selectAllItems();
                }}
              />
              <Typography>Select All</Typography>
            </div>

            <CheckboxTree
              nodes={nodes}
              checked={checked}
              expanded={expanded}
              onCheck={(checked) => {
                console.log(checked, "Checkeddddd");
                this.setState({ checked });
              }}
              onExpand={(expanded) => this.setState({ expanded })}
              iconsClass="fa5"
              icons={{
                check: <CheckBoxIcon />,
                uncheck: <CheckBoxOutlineBlankIcon />,
                expandClose: <ChevronRightIcon />,
                expandOpen: <ExpandMoreIcon />,
                halfCheck: <CheckBoxOutlineBlankIcon />,
              }}
            />
          </div>
          <div className={classes.actions}>
            <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.hideCloneCatalogSectionDialog}>
              Cancel
            </SqureButton>
            <SqureButton
              disabled={loading || this.state.checked?.length === 0}
              cls={classes.actionBtn}
              variant={"contained"}
              onClick={() => {
                this.onSave();
              }}
            >
              Clone
            </SqureButton>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(CloneCatalogSectionDialog));
