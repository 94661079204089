import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import EvaluateRespond from "./Respond";
import AssessmentRespond from "./AssessmentRespond";
import ProgressSummary from "./ProgressSummary";
import { fetchEvaluationCart } from "redux/evaluation/cart/action";
import { updateDrawerStyle } from "redux/drawer/action";

import Products from "./Products";
import Evaluations from "./Products/Components/Evaluations";
import AssessmentsList from "./AssessmentsList";

const connectedProps = () => ({});

const connectionActions = {
  fetchEvaluationCart: fetchEvaluationCart,
  updateDrawerStyle: updateDrawerStyle,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class Evaluate extends React.Component {
  componentDidMount() {
    this.props.fetchEvaluationCart();
    this.props.updateDrawerStyle(true, true);
  }
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={this.props.match.path} component={Products} />
          {/* <Route 
            exact
            path={`${this.props.match.path}/in-progress`} 
            render={(props) => (
              <VendorEvaluationCards {...props} layout="inProgress" title="In-Progress" />
            )}/>
          <Route 
            exact
            path={`${this.props.match.path}/completed`} 
            render={(props) => (
              <VendorEvaluationCards {...props} layout="completed" title="Completed" />
            )}/> */}
          <Route exact path={`${this.props.match.path}/evaluate/:productId`} component={Evaluations} />
          <Route exact path={`${this.props.match.path}/:productId`} component={AssessmentsList} />
          <Route exact path={`${this.props.match.path}/:uuid/questions`} component={AssessmentRespond} />
          <Route exact path={`${this.props.match.path}/:uuid/respond/detailed`} component={ProgressSummary} />
          <Route exact path={`${this.props.match.path}/:uuid/:org_id/:productId`} component={EvaluateRespond} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default connector(compose(withStyles(styles))(Evaluate));
