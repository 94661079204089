import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import classnames from "classnames";

import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import Product from "./Product";
import CriteriaHead from "./CriteriaHead";
import Requirement from "./Requirement";
import Response from "./Response";
import { fetchWorkflowConfigurations } from "redux/workflow/action";


const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  user: state.authUser.user,
  report: state.evaluationReportData.report,
  isLoading: state.evaluationReportData.isLoading,
  userReponses: state.evaludationCriteria.userReponses,
  fetchWorkflowConfigProgress: state.workflow.fetchWorkflowConfigProgress,
  workflowConfigurations: state.workflow.workflowConfigurations,
});

const connectionActions = {
  fetchWorkflowConfigurations: fetchWorkflowConfigurations
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootReport: {
    padding: theme.spacing(4, 4),
  },
  reportTable: {
    width: 'auto',
    '& [class*="MuiTableRow-root"]': {
      border: 'none',
      verticalAlign:'top',
      background: '#fff'
    },
    '& [class*="MuiTableCell-root"]': {
      border: '2px solid #617b937a',
      padding: 0,
      position:'relative'
    },
    '& [class*="MuiTableHead-root"]': {
      border: 'none'
    }
  },
  criteriaBox: {
    width: 800
  },
  productBox: {
    width: 600,
      // borderLeft: '15px solid #f7f7f7!important',
  },
  borderBtm:{
      // borderBottom: '2px solid #f7f7f7!important'
  },
});

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: queryString.parse(this.props.location.search),
      scoring_mechanism: {
        id: "Textual",
        type: "Textual",
      },
      selectedResponseTab : 1,
      enableScoring: false
    }
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentDidMount() {
    var tabType = (this.getParameterByName("tab") !== null);
    this.props.fetchWorkflowConfigurations(this.state.queryParams.workflowId);
    if(tabType){
      var tab = this.state.queryParams.tab;
      this.setState({
        selectedResponseTab : parseInt(tab)
      }) 
    }
    
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchWorkflowConfigProgress === false && prevProps.fetchWorkflowConfigProgress === true) {
      this.checkConfigurationNew();
    }
  }

  checkConfigurationNew() {
    const { workflowConfigurations } = this.props;
    console.log(workflowConfigurations, 'workflowConfigurations')
    var configurations = workflowConfigurations?.configurations;
    if (configurations === undefined || configurations === null || configurations.length <= 0) {
      return;
    } else {
      var enableScoringConfiguration = configurations.filter((o) => o.Form.Name === "enable_scoring")[0];
      if (enableScoringConfiguration !== undefined && enableScoringConfiguration !== null && enableScoringConfiguration.Value !== null && enableScoringConfiguration.Value !== '') {
        let enableScoring = enableScoringConfiguration.Value === 'true' ? true : false;
        this.setState({
          enableScoring: enableScoring
        })
      }
    }
  }

  render() {
    const { classes, report, isLoading } = this.props;
    const { selectedResponseTab } = this.state;
    if (report === null || isLoading === true) {
      return 'Loading..........'
    }

    console.log(selectedResponseTab,'selectedResponseTab')

    const products = report?.Products;
    const criterias = report?.Criterias;

    var requirementIdCriteriaMap = {};
    var totalRequirements = 0;
    if (criterias !== null && criterias.length) {
      criterias.forEach((criteria) => {
        criteria.requirements.forEach((requirement) => {
          requirementIdCriteriaMap[requirement.id] = criteria.id;
          totalRequirements++;
        });
      })
    }

    var userResponseCriteriaProductMap = {};
    var productWiseResponsesMap = {};
    if (this.props.userReponses !== null) {
      for (const [productId, product_responses] of Object.entries(this.props.userReponses)) {
        if (userResponseCriteriaProductMap[productId] === undefined) {
          userResponseCriteriaProductMap[productId] = {};
        }
        if (productWiseResponsesMap[productId] === undefined) {
          productWiseResponsesMap[productId] = 0;
        }
        for (const [requirementId, pr_responses] of Object.entries(product_responses)) {
          var criteriaId = requirementIdCriteriaMap[requirementId];
          if (criteriaId !== undefined) {
            if (userResponseCriteriaProductMap[productId][criteriaId] === undefined) {
              userResponseCriteriaProductMap[productId][criteriaId] = 0;
            }
            if (pr_responses.Response !== null && String(pr_responses.Response).trim().length !== 0 && pr_responses.Response !== 0) {
              userResponseCriteriaProductMap[productId][criteriaId] = userResponseCriteriaProductMap[productId][criteriaId] + 1;
              productWiseResponsesMap[productId] = productWiseResponsesMap[productId] + 1;
            }
          }
        }
      }
    }

    let criteriaDataMap = {};
    let criteriaIdMap = {};
    criterias.forEach((criteria) => {
      criteriaDataMap[criteria.id] = {
        'rows': [],
      };
      criteriaDataMap[criteria.id].rows.push(criteria);
      criteriaIdMap[criteria.id] = criteria;
      criteria.requirements.forEach((requirement) => {
        criteriaDataMap[criteria.id].rows.push(requirement);
      });
    })

    var productScoreMap = {};
    if (report.Products !== undefined && report.Products !== null) {
      report.Products.forEach(function (itm) {
        productScoreMap[itm.ID] = itm.Stats;
      })
    }

    return (
      <div className={classes.rootReport}>
        <Table className={classes.reportTable} aria-label="simple table" style={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.criteriaBox}> </TableCell>
              {products.map((product) => {
                return <TableCell className={classnames(classes.productBox,classes.borderBtm)}>
                  <Product
                    product={product}
                    report={report}
                    productWiseResponsesMap={productWiseResponsesMap}
                    totalRequirements={totalRequirements}
                    productScoreMap={productScoreMap}
                    enableScoring={this.state.enableScoring}
                  />
                </TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(criteriaDataMap).map((ckey, j) => {
              const criteriaDatas = criteriaDataMap[ckey].rows;
              return criteriaDatas.map((item, k) => {
                if (k === 0) {
                  return <TableRow>
                    <TableCell className={classes.criteriaBox}>
                      <CriteriaHead
                        type={'main'}
                        criteria={item}
                        report={report}
                        product={{}}
                      />
                    </TableCell>
                    {products.map((product) => {
                      return <TableCell className={classes.productBox}>
                        <CriteriaHead
                          type={'secondary'}
                          criteria={item}
                          product={product}
                          report={report}
                        />
                      </TableCell>
                    })}
                  </TableRow>
                } else {
                  return <TableRow>
                    <TableCell className={classnames(classes.criteriaBox,classes.borderAll)}>
                      <Requirement
                        criteriaId={ckey}
                        criteria={criteriaDataMap[ckey]?.rows[0]}
                        criteriaIdMap={criteriaIdMap}
                        criterias={criterias}
                        requirement={item}
                        report={report}
                        product={{}}
                        scoring_mechanism={this.state.scoring_mechanism}
                      />
                    </TableCell>
                    {products.map((product) => {
                      return <TableCell className={classnames(classes.productBox,classes.borderAll)}>
                        <Response
                          criteriaId={ckey}
                          criteria={criteriaDataMap[ckey]?.rows[0]}
                          criteriaIdMap={criteriaIdMap}
                          criterias={criterias}
                          requirement={item}
                          product={product}
                          report={report}
                          type={selectedResponseTab}
                          scoring_mechanism={this.state.scoring_mechanism}
                          enableScoring={this.state.enableScoring}
                        />
                      </TableCell>
                    })}
                  </TableRow>
                }
              })
            })}
          </TableBody>
        </Table>
      </div >
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Report));
