import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { fetchSellerAssessmentDetails } from "redux/seller/assessment/action";
import { showSnackBar } from "redux/snackbar/action";
import { withRouter } from "react-router-dom";
import AppBarV2 from "Components/Application/AppBarV2";
import Requirement from "./Requirement";
import Response from "./Response";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Product from "./products";
import classnames from "classnames";
import { Grid, Button, CircularProgress } from "@material-ui/core";

const connectedProps = (state) => ({
  sellerAssessmentDetailsProg: state.sellerAssessment.sellerAssessmentDetailsProg,
  sellerAssessmentDetailsError: state.sellerAssessment.sellerAssessmentDetailsError,
  sellerAssessmentDetails: state.sellerAssessment.sellerAssessmentDetails,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchSellerAssessmentDetails: fetchSellerAssessmentDetails,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    productBox: {},
    productContainer: {},
    productContainerReport: {
      display: "flex",
      marginBottom: 10,
    },
    report: {
      marginBottom: 0,
    },
    saveBtn: {
      display: "inline-block",
      marginRight: 30,
    },
    subText: {
      paddingBottom: theme.spacing(2),
    },
    appBar: {
      background: "#fff",
      padding: theme.spacing(1, 2),
      boxShadow: "none",
      borderBottom: "2px solid #f1f1f1",
    },
    mainLoader: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
    collabSection: {
      borderBottom: "solid 2px #f1f1f1",
      padding: theme.spacing(2),
      background: "white",
    },
    magicContainer: {
      textAlign: "right",
    },
    responseWrap: {
      padding: theme.spacing(0, 4),
      background: "#F7F7F7",
      display: "flex",
      alignItems: "stretch",
      justifyContent: "center",
      gap: 40,
      marginBottom: 20,
    },
  });

class ProgressSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completedCount: 0,
    };
  }

  componentDidMount() {
    this.props.fetchSellerAssessmentDetails({ filters: { assessment_id: this.props.match.params.uuid, fetch_assessment: true }, page: 0, page_size: 1000 });
  }

  componentDidUpdate(prevProps) {
    if (this.props.sellerAssessmentDetailsProg === false && prevProps.sellerAssessmentDetailsProg === true) {
      let data = this.props.sellerAssessmentDetails?.data || [];
      let completedCount = 0;

      data.forEach((item) => {
        let answerType = item?.answers?.answer_type;
        let resOptions = item?.answers?.options_answer;
        let answer = item?.answers?.answer;

        if (![2, 3, 14, 15].includes(answerType) && answer?.length > 0) {
          completedCount++;
        } else if ([2, 3, 14, 15].includes(answerType) && resOptions?.length > 0) {
          completedCount++;
        }
      });
      this.setState({ completedCount: completedCount });
    }
  }

  avatarName = (name) => {
    return name.substring(0, 1);
  };

  componentWillUnmount() {
    try {
      document.body.classList.remove("evaluate-view");
    } catch (e) {}
  }

  render() {
    const classes = this.props.classes;

    if (this.props.sellerAssessmentDetailsProg) {
      return (
        <span className={classes.mainLoader}>
          <CircularProgress />
        </span>
      );
    }

    if (!this.props.sellerAssessmentDetailsProg && this.props.sellerAssessmentDetails?.data?.length === 0) {
      return (
        <div className={classes.root}>
          <AppBarV2 title="Assessment" withBack={true} dropdown={true} />
          <div className={classes.NoDataLayout}>
            <h2>No data</h2>
          </div>
        </div>
      );
    }

    let assessmentName = "Assessment";
    if (this.props.sellerAssessmentDetails?.data?.length > 0) {
      let data = this.props.sellerAssessmentDetails?.data[0] || {};
      if (data?.assessment?.name) {
        assessmentName = data?.assessment?.name;
      }
    }

    return (
      <div className={classes.root}>
        <AppBarV2 withBack={true} title={assessmentName} />
        <Grid container alignItems="center" className={classnames(classes.collabSection)}>
          <Grid item lg={6} md={6} sm={6} xs={12} className={classes.topContainer}></Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} className={classes.magicContainer}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={() => {
                this.props.history.push("/sellerapp/assessment/" + this.props.match.params.uuid + "/questions");
              }}
            >
              Focussed View
            </Button>
          </Grid>
        </Grid>
        <div style={{ padding: "1.5rem 3rem" }}>
          <Grid className={classes.responseWrap}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div className={classes.productContainer}>
                <div className={classes.productBox}>
                  <Product completedCount={this.state.completedCount} totalCount={this.props.sellerAssessmentDetails?.total || 0} />
                </div>
              </div>
            </Grid>
          </Grid>
          {this.props.sellerAssessmentDetails?.data?.map((requirement, i) => {
            return (
              <Grid className={classes.responseWrap}>
                <Grid item xs={6}>
                  <Requirement key={"req" + i} requirement={requirement} />
                </Grid>
                <Grid item xs={6}>
                  <Response key={"res" + i} reqIndex={i} respondable={true} requirement={requirement} />
                </Grid>
              </Grid>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(ProgressSummary));
