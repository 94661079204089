import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { FormControl, FormControlLabel, Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

const connectedProps = () => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    binaryRoot: {},
    bLabel: {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 600,
      color: "#003B4D",
    },
    heading: {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 600,
      color: "#003b4d99",
      textTransform: "uppercase",
    },
  });

function ProfileItemBinaryType(props) {
  const { data, isEdit, value, classes, isRequired, onSubmit } = props;
  const [currentValue, setCurrentValue] = useState("");
  const [error, setError] = useState(data.error || false);

  useEffect(() => {
    setError(data.error || false);
  }, [JSON.stringify(data)]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  // Function to handle validation and update the error state
  const handleValidation = () => {
    const isValid = isRequired ? currentValue !== "" : true;
    setError(!isValid);
  };

  const handleChange = (e) => {
    console.log("handleChange", e.target.value);
    setCurrentValue(e.target.value);
    onSubmit?.(e.target.value);
  };

  return (
    <div className={classes.binaryRoot}>
      {isEdit && (
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={currentValue}
            onChange={handleChange}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel
              classes={{
                label: classes.bLabel,
              }}
              value="Yes"
              control={<Radio color="primary" />}
              label="Yes"
            />
            <FormControlLabel
              classes={{
                label: classes.bLabel,
              }}
              value="No"
              control={<Radio color="primary" />}
              label="No"
            />
          </RadioGroup>
          {error && (
            <Typography variant="body2" color="error">
              {isRequired ? "This field is required." : "Error"}
            </Typography>
          )}
        </FormControl>
      )}
      {!isEdit && (
        <Typography variant="body1" style={{ fontFamily: "Manrope" }}>
          {value !== "" ? value : "-"}
        </Typography>
      )}
    </div>
  );
}

export default connector(
  compose(withRouter, withStyles(styles))(ProfileItemBinaryType)
);
