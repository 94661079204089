import { CircularProgress, Dialog, DialogContent, Slide, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import exportImage from "assets/images/xls-icon.png";
import axios from 'axios';
import classnames from "classnames";
import Image from 'Components/Common/image.jsx';
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import {
    fetchdownloadProductCSVFileLink,
    importProductDataFile,
    fetchProductTabItems
} from "redux/product/orgProduct/action";
import { showSnackBar } from "redux/snackbar/action";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import ProductExportItemsSelectionDialog from './ProductExportItemsSelectionDialog';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    importProductDataFileData: state.orgProduct.importProductDataFileData,
    importProductDataFileProg: state.orgProduct.importProductDataFileProg,
    fetchdownloadProductCSVFileLinkData: state.orgProduct.fetchdownloadProductCSVFileLinkData,
    fetchdownloadProductCSVFileLinkDataProg: state.orgProduct.fetchdownloadProductCSVFileLinkDataProg,
    fetchOrgProductTabItems: state.orgProduct.fetchOrgProductTabItems,
    productTabItems: state.orgProduct.productTabItems,
});

const connectionActions = {
    showSnackBar: showSnackBar,
    fetchdownloadProductCSVFileLink: fetchdownloadProductCSVFileLink,
    fetchProductTabItems:fetchProductTabItems,
    importProductDataFile:importProductDataFile
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important"
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(2.5),
    },
    uploadSection: {
        textAlign: 'center',
        border: '1px dotted #333',
        padding: theme.spacing(6, 0),
        "&.activeDrop": {
            border: '2px dotted #4a88ff',
        }
    },
    uploadSubText: {
        color: '#475867'
    },
    uploadAnchor: {
        color: '#0091FF !important',
        textDecoration: 'underline',
        opacity: 0,
        width: 0
    },
    btnSection: {
        marginTop: 20,
        textAlign: 'center',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center'
    },
    labelFile: {
        color: '#4a87f8',
        marginLeft: 5,
        border: '1px solid #4a87f8',
        padding: '5px 10px',
        borderRadius: 4,
        cursor: 'pointer'
    },
    arrow: {
        paddingLeft: 5
    },
    downloadTemplate: {
        textDecoration: 'underline',
        color: '#0091FF !important',
    },
    progress: {
        textAlign: 'center',
        paddingTop: 15
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    delete: {
        cursor: 'pointer',
        marginLeft:10
    }, 
    fileNameSection:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    card:{
        display:"flex"
    },
    cardItem:{
        flex:1
    }
});

class ImportProductDialog extends React.Component {
    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.state = {
            activeOnDrop: "",
            fileName: "",
            filePath: false,
            downloadLinkAvailable:false,
            error:"",
            success:"",
            isShowSelectionDialog: false
        }
        this.fileUpload = this.fileUpload.bind(this)
        this.divRef = React.createRef();
    }

    componentDidMount() {
        this.props.fetchProductTabItems();
    }

    componentDidUpdate(prevProps) {
        if(this.props.importProductDataFileProg === false && prevProps.importProductDataFileProg === true){
            this.setState({success:'File successfully uploaded for processing. Please check back in 72 hours.'});
            // this.props.onClose()
        }

        if(this.props.fetchdownloadProductCSVFileLinkDataProg === false && prevProps.fetchdownloadProductCSVFileLinkDataProg === true){
            this.setState({
                downloadLinkAvailable : true,
            })
            const {  fetchdownloadProductCSVFileLinkData } = this.props;

            const link = document.createElement('a');
            link.href = fetchdownloadProductCSVFileLinkData;
            link.setAttribute('download', "application_catalog.xlsx");
            this.divRef.current.appendChild(link)
            link.click();
            link.parentNode.removeChild(link);
        }
    }

    onFileChange = async event => {
        if (this.state.filePath && this.state.filePath.length > 0) {
            const file = this.state.filePath[0];
            var formData = new FormData();
            formData.append("folder", `/orgProduct/product/import/`);
            const timestamp = (new Date()).getTime();
            formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf('.') + 1)}`);
            const api_server = Environment.api_host("STORAGE");
            const url = `${api_server}/add?t=${timestamp}`;
            axios.post(url, formData, {
                headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
            }).then(response => {
                console.log('response', response)
                this.props.importProductDataFile(response?.data?.filepath)
            })
                .catch(err => {
                    this.setState({ uploadingFile: false })
                    if (err.response) {
                        this.setState({error:err.response.data.error})
                    } else if (err.request) {
                        this.setState({error:"Something went wrong. Please try after sometime."})
                    } else {
                        this.setState({error:"Something went wrong. Please try after sometime."})
                    }
                })

        }
    }

    fileUpload(event) {
        var me  =   this;
        if (event.target.files[0]) {
            this.setState({ filePath: event.target.files, fileName: event.target.files[0].name, file: event.target.files[0] },()=>{
                me.onFileChange()
            })
        }
    }

    deleteFile = () => {
        this.setState({ fileName: '', filePath: false });
        this.fileRef.current.value = '';
    }

    dropHandler = (event) => {
        console.log(event.dataTransfer)
        this.setState({ activeOnDrop: '' })
        event.preventDefault();
        if (event.dataTransfer.files[0]) {
            this.setState({ filePath: event.dataTransfer.files, fileName: event.dataTransfer.files[0].name, file: event.dataTransfer.files[0] });
        }
    }

    dragOverHandler = (event) => {
        event.preventDefault();
        if (!this.state.activeOnDrop) {
            this.setState({ activeOnDrop: 'activeDrop' })
        }
    }

    dragLeaveHandler = () => {
        if (this.state.activeOnDrop) {
            this.setState({ activeOnDrop: '' })
        }
    }

    render() {
        const classes = this.props.classes;
        const { isOpen, onClose } = this.props;
        return (
            <Dialog
                onClose={onClose}
                aria-labelledby="add-upload-dialog"
                open={isOpen}
                TransitionComponent={Transition}
                disableBackdropClick={true}
                fullWidth={true}
                maxWidth={"md"}
                scroll={"body"}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <div className={classes.close} onClick={() => onClose()}><CloseIcon /></div>
                    <div className={classes.alert}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                        {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                    </div>
                    <br/>
                    <div className={classes.card}>
                        <div className={classnames(classes.uploadSection,classes.cardItem)} onDrop={(event) => this.dropHandler(event)} onDragOver={(event) => this.dragOverHandler(event)} onDragLeave={() => this.dragLeaveHandler()}>
                            <Typography variant={"h6"} className={classes.title}> Export Application Catalog</Typography>
                            <Image src={exportImage} alt="export image" width="70" height="100" />
                            <p className={classes.uploadSubText}>&nbsp;</p>
                            <label className={classes.labelFile} onClick={()=>{
                                // this.props.fetchdownloadProductCSVFileLink()
                                this.setState({
                                    isShowSelectionDialog : true
                                })
                            }}>Export</label>
                            {this.props.fetchdownloadProductCSVFileLinkDataProg && <div style={{marginTop:20}}>
                            <CircularProgress style={{width:20,height:20}}/> 
                            </div>}
                        </div>
                        <div className={classnames(classes.uploadSection, this.state.activeOnDrop,classes.cardItem)} onDrop={(event) => this.dropHandler(event)} onDragOver={(event) => this.dragOverHandler(event)} onDragLeave={() => this.dragLeaveHandler()}>
                            <Typography variant={"h6"} className={classes.title}> Import Application Catalog</Typography>
                            <Image src={exportImage} alt="export image" width="70" height="100" />
                            <p className={classes.uploadSubText}>Drag and drop your Excel file.</p>
                            
                            {this.props.importProductDataFileProg ? <CircularProgress /> : (<>
                                <label htmlFor="inputFile" className={classes.labelFile}>Upload File</label>
                                <input ref={this.fileRef}
                                    id="inputFile"
                                    type="file"
                                    disabled={this.props.importProductDataFileProg}
                                    className={classes.uploadAnchor}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={this.fileUpload}
                                /> 
                                <br/>
                            </>)}
                        </div>
                    </div>
                    <div ref={this.divRef} id="downloadlink"></div>
                    
                </DialogContent>
                
                {this.state.isShowSelectionDialog && <ProductExportItemsSelectionDialog
                    isOpen={this.state.isShowSelectionDialog}
                    productTabItems={this.props.productTabItems}
                    hideDialog={()=>{
                        this.setState({
                            isShowSelectionDialog: false
                        })
                    }}
                />}
            </Dialog>
        )
    }
}
ImportProductDialog.defaultProps = {
    isFromProduct: false
};

export default connector(compose(
    withStyles(styles)
)(ImportProductDialog));