import { Axios } from "./index";
import * as Environment from "./../util/Environment";

export class MetricFactory{
    static _instance;

    static instance() {
        if (MetricFactory._instance == null) {
            MetricFactory._instance = new MetricFactory();
        }
        return MetricFactory._instance;
    }

   
    async getVendors(page,pageSize,query="",extraQuery = null){
        const api_server = Environment.api_host("SEARCH");
        const timestamp     =   (new Date()).getTime();
        // let url   =   `${api_server}/vendors?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}`;
        let url   =   `${api_server}/metric/vendors?t=${timestamp}`;
        let sortBy = [];
        if(extraQuery){
            let temp = {};
            temp['used'] = extraQuery.sortOrder === 'asc' ? 'least' : 'most';
            sortBy.push(temp)
            // url += '&'+ this.serialize(extraQuery)
        }
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.post(url, {
                page: page,
                pageSize: pageSize,
                query: query,
                sortBy: sortBy
            },{
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getMetricCategoryVendors(categoryId, page, pageSize, query="",extraQuery = null){
        const api_server = Environment.api_host("SEARCH");
        const timestamp     =   (new Date()).getTime();
        let url   =   `${api_server}/metric/categories/${categoryId}/vendors?t=${timestamp}`;
        let sortBy = [];
        if(extraQuery){
            let temp = {};
            temp['used'] = extraQuery.sortOrder === 'asc' ? 'least' : 'most';
            sortBy.push(temp)
            // url += '&'+ this.serialize(extraQuery)
        }
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.post(url, {
                page: page,
                pageSize: pageSize,
                query: query,
                sortBy: sortBy
            },{
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getVendor(applicationId,departmentId,duration, appsId, sourcesId){
        if(departmentId === undefined){
            departmentId= 0
        }
        const api_server = Environment.api_host("METRIC");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/vendor/${applicationId}?groupId=${departmentId}&from=${duration.from}&to=${duration.to}&application_id=${appsId}&source=${sourcesId}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getVendorActivity(applicationId,duration,departmentId,userState, appsId, sourcesId){
        if(departmentId === undefined){
            departmentId= 0
        }
        let userStatePath = 'activity';
        if(userState == 10 || userState == 20){
            userStatePath = 'activity';
        }else{
            userStatePath = 'in-activity';
        }
        const api_server = Environment.api_host("METRIC");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/vendor/${applicationId}/${userStatePath}/${userState == 10 ? 1 : 0}?groupId=${departmentId}&from=${duration.from}&to=${duration.to}&application_id=${appsId}&source=${sourcesId}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    async getVendorActivityChartData(applicationId,duration,departmentId, appsId, sourcesId){
        if(departmentId === undefined){
            departmentId= 0
        }
        const api_server = Environment.api_host("METRIC");
        const timestamp     =   (new Date()).getTime();
        const url   =   `${api_server}/vendor/${applicationId}/chartdata/${1}?groupId=${departmentId}&from=${duration.from}&to=${duration.to}&application_id=${appsId}&source=${sourcesId}`;
        
        const authToken = window.localStorage.getItem("authToken");
        const authType = window.localStorage.getItem("authType");
        if(authToken == null || authType == null || authToken === undefined || authType == null){
            return [null,{'message':"User logged out.","status":-1}];
        }

        let response;
        try{
            response    =   await Axios.get(url, {
                headers: {'Authorization': authType+' '+authToken}
            });
        } catch(error){
            if(error.message === "Network Error"){
                //TODO Handle Network Error
                return [null,{'message':"Unable to connect to Server."}];
            } else if(error.response !== undefined){
                return [null,error.response.data];
            }
        }
        if(response.data === undefined){
            return [null,{"message":"Invalid Response from Server."}]
        }
        return [response.data,null];
    }

    serialize = function(obj) {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
      }
}