import { Box, Button, Checkbox, CircularProgress, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
// import DefaultImage from "assert/";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { fetchOrgProducts } from "redux/product/orgProduct/action";
import CommonCss from "../../../../commonCss";
import { authorizedUser } from "../../../../redux/authuser/action";
import { updateDrawerStyle } from "../../../../redux/drawer/action";
import AppBarV2 from "../../../Application/AppBarV2";
import Image from 'Components/Common/image.jsx';
import {
  fetchOrgMetaRequests, updateOrgMetaRequestStatus
} from "redux/companyProfile/action";
import { CommonFn } from "services/commonFn";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
  products: state.orgProduct.orgProducts,
  totalOrgProducts: state.orgProduct.totalOrgProducts,
  orgName: state.authUser.user?.Organization?.Name,
  userRole: state.authUser.user?.Role,

  fetchOrgMetaRequestsInprogress: state.companyProfile.fetchOrgMetaRequestsInprogress,
  fetchOrgMetaRequestsSuccess: state.companyProfile.fetchOrgMetaRequestsSuccess,
  fetchOrgMetaRequestsFailure: state.companyProfile.fetchOrgMetaRequestsFailure,

  updateOrgMetaRequestStatusInprogress: state.companyProfile.updateOrgMetaRequestStatusInprogress,
  updateOrgMetaRequestStatusSuccess: state.companyProfile.updateOrgMetaRequestStatusSuccess,
  updateOrgMetaRequestStatusFailure: state.companyProfile.updateOrgMetaRequestStatusFailure,
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
  fetchOrgProducts: fetchOrgProducts,
  authorizedUser: authorizedUser,
  fetchOrgMetaRequests: fetchOrgMetaRequests,
  showSnackBar: showSnackBar,
  updateOrgMetaRequestStatus: updateOrgMetaRequestStatus
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    homeRoot: {
      background: '#fff',
      height: '90vh'
    },
    homeBox: {
      borderRadius: '10px',
      border: '1px solid rgba(0, 59, 77, 0.15)',
      background: 'rgba(0, 59, 77, 0.05)',
    },
    boxTitle: {
      "color": "#003B4D",
      "fontFamily": "Manrope",
      "fontSize": "20px",
      "fontStyle": "normal",
      "fontWeight": "600",
      "lineHeight": "148%",
      "letterSpacing": "0.2px",

    },
    productLogo: {
      float: 'left',
      width: '40px',
      height: '40px',
      borderRadius: '4px',
      marginRight: '12px',
      textAlign: 'center',
      lineHeight: '33px',
      fontWeight: 600,
      fontSize: 11,
      color: '#A0A5BA',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'scale-down'
      }
    },
    boxCard: {
      padding: '16px',
      marginTop: '16px',
      borderRadius: "12px",
      background: "#FFF"
    },
    emptyBoxCard: {
      padding: '16px',
      marginTop: '16px',
      borderRadius: "12px",
      background: "#FFF",
      textAlign: 'center'
    },
    cardImg: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '12px',
      justifyContent: 'space-between'
    },
    cardLeft: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },
    cardMsg: {
      "color": "rgba(0, 59, 77, 0.60)",
      "fontFamily": "Manrope",
      "fontSize": "16px",
      "fontStyle": "normal",
      "fontWeight": "600",
      "lineHeight": "148%",
      "letterSpacing": "0.16px"
    },
    cardVendorName: {
      "color": "#003B4D",
      "fontFamily": "Manrope",
      "fontSize": "16px",
      "fontStyle": "normal",
      "fontWeight": "600",
      "lineHeight": "148%",
      "letterSpacing": "0.16px"
    },
    cardActions: {
      display: 'flex',
      alignItems: 'center'
    },
    leftActions: {
      flex: 1,
      display: 'flex',
      alignItems: 'center'
    },
    profileDetailsBtn: {
      "borderRadius": "6px",
      "background": "rgba(0, 59, 77, 0.15)",
      "color": "#003B4D",
      "fontFamily": "Manrope",
      "fontSize": "14px",
      "fontStyle": "normal",
      "fontWeight": "500",
      "lineHeight": "140%",
      "letterSpacing": "0.14px",
      padding: '4px 8px',
      marginLeft: '52px',
      marginRight: '16px',
    },
    requestDate: {
      "color": "rgba(0, 59, 77, 0.60)",
      "fontFamily": "Manrope",
      "fontSize": "14px",
      "fontStyle": "normal",
      "fontWeight": "500",
      "lineHeight": "140%",
      "letterSpacing": "0.14px"
    },
    rightActions: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end'
    },
    ignoreBtn: {
      "borderRadius": "8px",
      "border": "1px solid rgba(0, 59, 77, 0.15)",
      "background": "#FFF",
      "color": "#FF8082",
      "fontFamily": "Manrope",
      "fontSize": "14px",
      "fontStyle": "normal",
      "fontWeight": "600",
      "lineHeight": "140%",
      "letterSpacing": "0.14px",
      marginRight: '16px'
    },
    approveBtn: {
      "borderRadius": "8px",
      "border": "1px solid rgba(0, 59, 77, 0.15)",
      "background": "#FFF",
      "color": "#003B4D",
      "fontFamily": "Manrope",
      "fontSize": "14px",
      "fontStyle": "normal",
      "fontWeight": "600",
      "lineHeight": "140%",
      "letterSpacing": "0.14px"
    },
    topBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px',
      paddingBottom: '0px'
    },
    rejectedTasks: {
      "color": "#003B4D",
      "fontFamily": "Manrope",
      "fontSize": "14px",
      "fontStyle": "normal",
      "fontWeight": "600",
      "lineHeight": "140%",
      "letterSpacing": "0.14px"
    },
    requestListing: {
      maxHeight: '70vh',
      overflow: 'auto',
      padding: '24px',
      paddingTop: '0px'
    },
    rejStatus: {
      "color": "#FF8082",
      "textAlign": "right",
      "fontFamily": "Manrope",
      "fontSize": "14px",
      "fontStyle": "normal",
      "fontWeight": "500",
      "lineHeight": "140%",
      "letterSpacing": "0.14px",
      "borderRadius": "4px",
      "border": "1px solid rgba(255, 128, 130, 0.10)",
      "background": "rgba(255, 128, 130, 0.10)",
      "padding": "2px 10px"
    }
  });

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      page: 0,
      pageSize: 6,
      status: [1],
      isShowRejectedTasks: false
    };
  }
  componentDidMount() {
    this.fetchData()
  }
  componentWillUnmount() {
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateOrgMetaRequestStatusInprogress === false && prevProps.updateOrgMetaRequestStatusInprogress) {
      if (this.props.updateOrgMetaRequestStatusFailure === null) {
        this.props.showSnackBar(
          "Request status updated successfully.",
          "success",
          3000
        );
        this.fetchData()
      } else {
        this.props.showSnackBar(
          "Failed to update request status.",
          "error",
          3000
        );
      }
    }
  }

  fetchData() {
    this.props.fetchOrgMetaRequests({
      status: this.state.status
    })
  }

  getRequestData = (datas, type) => {
    let entityData = datas.filter((o) => o.Key === type)[0]?.Value;
    return entityData;
  }

  handleUpdateStatus = (requestId, status) => {
    this.props.updateOrgMetaRequestStatus(requestId, {
      status: status
    })
  }

  handleChangeShowRejected = () => {
    this.setState({
      isShowRejectedTasks: this.state.isShowRejectedTasks ? false : true,
      status: this.state.isShowRejectedTasks ? [1] : [1, 3],
    }, () => {
      this.fetchData()
    })
  }

  render() {
    const classes = this.props.classes;
    const { fetchOrgMetaRequestsSuccess, fetchOrgMetaRequestsInprogress } = this.props;
    const { isShowRejectedTasks } = this.state;
    console.log(fetchOrgMetaRequestsSuccess, 'fetchOrgMetaRequestsSuccess')

    return (
      <div className={classes.homeRoot}>
        <AppBarV2
          title={"Home"}
        />
        <div className={classes.rootContainer}>
          <Box className={classes.homeBox}>
            <div className={classes.topBar}>
              <Typography className={classes.boxTitle}>Tasks</Typography>
              <Box>
                <Checkbox className={classes.checkbox} disabled={false} checked={isShowRejectedTasks} onChange={() => {
                  this.handleChangeShowRejected()
                }} />
                <Typography variant="inherit" className={classes.rejectedTasks}>Rejected Tasks</Typography>
              </Box>
            </div>

            <div className={classes.requestListing}>

              {!fetchOrgMetaRequestsInprogress && fetchOrgMetaRequestsSuccess && fetchOrgMetaRequestsSuccess.map((itm, index) => {
                const entityData = this.getRequestData(itm?.Data, 'entity')
                return <div className={classes.boxCard}>
                  <div className={classes.cardImg}>
                    <div className={classes.cardLeft}>
                      <div className={classes.productLogo} title={this.getRequestData(entityData, 'name')}>
                        <Image src={this.getRequestData(entityData, 'logo')} alt={this.getRequestData(entityData, 'name')} title={this.getRequestData(entityData, 'name')} />
                      </div>
                      <Typography className={classes.cardMsg}><b className={classes.cardVendorName}>{this.getRequestData(entityData, 'name')}</b> has requested to view your profile information</Typography>
                    </div>
                    <div>
                      {itm?.Status === 3 && <Typography className={classes.rejStatus}>Rejected</Typography>}
                    </div>
                  </div>
                  <div className={classes.cardActions}>
                    <div className={classes.leftActions}>
                      <Typography className={classes.profileDetailsBtn}>Profile Details</Typography>
                      <Typography className={classes.requestDate}>{CommonFn.formateDate(itm.CreatedAt, true)}</Typography>
                    </div>
                    <div className={classes.rightActions}>
                      <Button
                        className={classes.ignoreBtn}
                        variant="outlined"
                        disabled={itm.Status === 3}
                        size="large"
                        onClick={() => {
                          this.handleUpdateStatus(itm.ID, 3)
                        }}>
                        Reject
                      </Button>
                      <Button
                        className={classes.approveBtn}
                        variant="outlined"
                        size="large"
                        onClick={() => {
                          this.handleUpdateStatus(itm.ID, 2)
                        }}>
                        Approve
                      </Button>
                    </div>
                  </div>
                </div>
              })}

              {!fetchOrgMetaRequestsInprogress && fetchOrgMetaRequestsSuccess && fetchOrgMetaRequestsSuccess.length <= 0 && <div className={classes.emptyBoxCard}>
                <Typography className={classes.cardMsg}>No Requests</Typography>
              </div>}


              {fetchOrgMetaRequestsInprogress && <div className={classes.emptyBoxCard}>
                <CircularProgress />
              </div>}
            </div>

          </Box>
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Home));
