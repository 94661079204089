import React from "react";
import { generate } from "shortid";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { removeProductFromCart, showEvaluationProductEditDialog } from "redux/evaluation/cart/action";
import { withRouter } from "react-router-dom";
import { Typography, Grid, Divider } from "@material-ui/core";
import { showEvaluationMetaDialog } from "redux/evaluation/metadata/action";
import DefaultImage from "assets/images/evaluation/default.svg";
import CloseImage from "assets/images/close.svg";
import AddProductImage from "assets/images/add_product.svg";

import Image from "Components/Common/image.jsx";
import classnames from "classnames";

import UserGroup from "../../../../../../Common/UserGroup";

// dialog
import VendorEmailDialog from "Components/Application/Components/Evaluate/Components/Evaluation/Common/VendorEmailDialog";

import { addProductColloborator, deleteProductCollaboraor } from "redux/evaluation/metadata/action";

const connectedProps = (state) => ({
  isOpen: state.evaluationCart.showProductUpdateDialog,
  targetContainer: state.evaluationCart.targetContainer,
  evaluation: state.evaluationMetaData.evaluation,
  fetchProgress: state.evaluationMetaData.fetchProgress,
  evaluateView: state.applications.evaluateView,
  evaluateViewId: state.applications.evaluateViewId,
  evaluateNeverReview: state.applications.evaluateNeverReview,
  evaluateNeverReviewId: state.applications.evaluateNeverReviewId,
  colloborators: state.evaludationCriteria.colloborators,
  allProductCollaboratorProgress: state.evaluationMetaData.allProductCollaboratorProgress,
  userRole: state.authUser.user?.Role,
  user: state.authUser.user,
  workflowComponentsForms: state.workflow.workflowComponentsForms,
  workflowComponentDetail: state.workflow.workflowComponentDetail,
  workflowComponentsFormsInProg: state.workflow.workflowComponentsFormsInProg,
});

const connectionActions = {
  showEvaluationMetaDialog: showEvaluationMetaDialog,
  removeProductFromCart: removeProductFromCart,
  showEvaluationProductEditDialog: showEvaluationProductEditDialog,
  addProductColloborator: addProductColloborator,
  deleteProductCollaboraor: deleteProductCollaboraor,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {},
    headSection: {
      justifyContent: "space-between",
      marginBottom: 16.5,
      minHeight: 40,
    },
    title: {
      fontSize: theme.spacing(2),
      color: "#282D30",
      fontWeight: 600,
    },
    cartItems: {
      backgroundColor: "#FFFFFF",
      borderRadius: 5,
      position: "relative",
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    },
    cartItem: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      padding: 12,
      border: "1px solid #EEEEEE",
      borderRadius: 10,
      margin: `${theme.spacing(2)} 0`,
      justifyContent: "space-between",
    },
    productNameContainer: {
      flex: 0.8,
    },
    productName: {
      flex: 0.9,
    },
    productImage: {
      width: "100%",
      height: "auto",
      marginRight: theme.spacing(2),
    },
    productImageContainer: {
      width: 67,
      height: 57,
      padding: 13,
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 1px 6px #0000001A",
      borderRadius: 10,
    },
    addProductImageContainer: {
      width: 67,
      height: 57,
      padding: 13,
    },
    productRemove: {
      marginTop: theme.spacing(1),
      cursor: "pointer",
    },
    counter: {
      padding: theme.spacing(1),
      fontSize: theme.spacing(2.2),
      opacity: 0.5,
      marginTop: theme.spacing(1),
    },
    productNameTitle: {
      fontSize: theme.spacing(2),
    },
    vendotDetail: {
      cursor: "pointer",
      color: "#4175DF",
      fontSize: theme.spacing(2),
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  });

class ProductCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdded: false,
      isHideAskSeller: false,
    };
  }

  componentDidMount() {
    if (this.props.isWorkFlow) {
      this.checkWorkflowConfiguration();
    }
  }

  checkWorkflowConfiguration() {
    const { workflowComponentsForms, workflowComponentDetail } = this.props;
    if (workflowComponentsForms === null || workflowComponentDetail === null) {
      return;
    }

    var configurations = workflowComponentDetail.OrgWorkflowComponentConfigurations;
    if (configurations === undefined || configurations === null || configurations.length <= 0) {
      this.setState({
        isHideAskSeller: true,
      });
    } else {
      var askSellerFormId = workflowComponentsForms.filter((o) => o.Name === "ask_seller").map((o) => o.ID)[0];
      var askSellerValue = configurations.filter((o) => o.FormID === askSellerFormId).map((o) => o.Value)[0];
      askSellerValue = askSellerValue === "false" ? false : true;
      if (askSellerValue === false) {
        this.setState({
          isHideAskSeller: true,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.allProductCollaboratorProgress === false && prevProps.allProductCollaboratorProgress === true) {
      this.setState({
        isAdded: true,
      });
    }

    if (this.props.workflowComponentsFormsInProg === false && prevProps.workflowComponentsFormsInProg === true) {
      if (this.props.isWorkFlow) {
        this.checkWorkflowConfiguration();
      }
    }
  }

  render() {
    const classes = this.props.classes;

    var items = [];

    if (this.props.evaluateView && this.props.evaluation && this.props.evaluation.Products) {
      this.props.evaluation.Products.map((product) => {
        if (product.ID === this.props.evaluateViewId) {
          items.push(product);
        }
      });
    } else if (this.props.evaluation) {
      items = this.props.evaluation.Products;
    }


    let isShowDelete = false;
    if(items.length > 1){
      isShowDelete = true;
    }

    return (
      <div className={classes.root} style={this.props.isOpen ? { height: "auto" } : {}}>
        <Grid container alignItems="center" className={classes.headSection}>
          <Typography variant={"h6"} className={classes.title}>
            Selected {items.length > 1 ? "Products" : "Product"}
          </Typography>
        </Grid>
        <div className={classes.cartItems}>
          {this.props.targetContainer && this.props.isOpen && (
            <div style={{ marginTop: 16 }}>
              <VendorEmailDialog />
            </div>
          )}
          <div>
            {!this.props.targetContainer &&
              !this.props.isOpen &&
              items.map((item, k) => {
                return (
                  <Grid container alignItems="center" className={classes.cartItem} key={generate()}>
                    <Grid item className={classes.productImageContainer}>
                      <Image alt="product" src={item.ProductLogo == null || item.ProductLogo.length === 0 ? DefaultImage : item.ProductLogo} className={classes.productImage} />
                    </Grid>
                    <Grid item className={classes.productNameContainer}>
                      <Typography variant={"h5"} className={classes.productNameTitle}>
                        {item.Name}
                      </Typography>
                      <Divider style={{ margin: "8px 0px" }} />

                      {this.state.isHideAskSeller === false && (
                        <Typography
                          className={classnames(classes.vendotDetail, "evaluate-seller-email")}
                          onClick={() => {
                            this.props.showEvaluationProductEditDialog(this.props.evaluation?.ID, item, "evaSetupCartItems");
                          }}
                        >
                          {item?.VendorEmail !== null && item?.VendorEmail.length > 0 ? item?.VendorEmail : "+Add Vendor Email"}
                        </Typography>
                      )}
                      {this.state.isHideAskSeller === false && <Divider style={{ margin: "8px 0px" }} />}

                      <div>
                        <UserGroup
                          selectedUser={item.EvaluationProductCollaborators}
                          existingUsers={item.EvaluationProductCollaborators}
                          evaluationId={this.props.match.params.evaluation_id}
                          productId={item.ID}
                          position={"ProductSetupCart"}
                          showNotificationIcon={true}
                          notificationData={{
                            type: "notification.evaluation.product.colloborator.nudge",
                            product: item,
                          }}
                          onAdd={(user) => {
                            var ids = [user.ID];
                            this.props.addProductColloborator(this.props.match.params.evaluation_id, item.ID, {
                              collaborator_ids: ids, //array of user IDs
                            });
                          }}
                          users={item.EvaluationProductCollaborators}
                        />
                      </div>
                    </Grid>
                   {isShowDelete && <Grid item onClick={() => { this.props.removeProductFromCart(this.props.evaluation.ID, item.ID) }}>
                      <Image alt="product remove" src={CloseImage} className={classes.productRemove} />
                    </Grid>}
                  </Grid>
                );
              })}
            {(this.props.userRole === "OrgAdmin" || this.props.user.ID === this.props.evaluation?.UserID) && !this.props.targetContainer && !this.props.isOpen && !this.props.evaluateView && this.props.evaluation?.Type !== 2 && (
              <Grid
                container
                alignItems="center"
                className={classes.cartItem}
                key={generate()}
                style={{cursor: 'pointer'}}
                onClick={() => {
                  this.props.history.push("/app/products/all-products?evaluationId=" + this.props.evaluation.ID);
                }}
              >
                <div className={classes.addProductImageContainer}>
                  <Image alt="add product" src={AddProductImage} className={classes.productImage} />
                </div>
                <div className={classes.productName}>
                  <Typography variant={"h5"} className={classes.productNameTitle}>
                    Add Product
                  </Typography>
                </div>
              </Grid>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ProductCart.defaultProps = {
  isWorkFlow: false,
};

export default connector(compose(withRouter, withStyles(styles))(ProductCart));
