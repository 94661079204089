import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Button, ButtonGroup } from "@material-ui/core";
import classnames from "classnames";

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {

  },
  modeSwitch:{
    position:'absolute',
    top:35,
    left:0,
    right:0,
    margin:'0 auto',
    textAlign:'center',
    zIndex:1
  },
  buttonRoot:{
    border: '2px solid #bac4cc',
    background:'#bac4cc',
    borderRadius:12
  },
  childItms:{
    border:'none',
    color:'#133440',
    fontWeight:"bold",
    padding:'0px 25px',

  },
  activeBtn:{
    background:'#133440',
    color:'#fff',
    borderRadius:'12px !important',
    '&:hover':{
      background:'#133440',
      color:'#fff',
      borderRadius:'12px !important',
    }
  }
});

class ModeSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  render() {
    const { classes, active } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.modeSwitch}>
          <ButtonGroup 
          size="small" 
          aria-label="small outlined button group"
          classes={{
            root:classes.buttonRoot,
            grouped:classes.childItms,
          }}
          disableFocusRipple={false}
          disableRipple={false}
          disableElevation={false}
          >
            <Button className={classnames(classes.default, {
              [classes.activeBtn]: active === 'RECIPE'
            }
            )}>RECIPE</Button>
             <Button className={classnames(classes.default, {
              [classes.activeBtn]: active === 'JOBS'
            }
            )}>TEST JOBS</Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(ModeSwitch));
