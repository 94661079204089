import React from 'react';
import { connect } from "react-redux";
import classnames from "classnames";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { Button, Grid, List, ListItem, CircularProgress } from '@material-ui/core';

import AnswerReply from "Components/Common/EvaluateMultiChoiceAnswer/AnswerReply"

// icons
import { Send as SendIcon } from '@material-ui/icons';

// assets
import Messages from "assets/images/add_message.svg"

import UserMessages from './UserMessages';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";

// redux
import { addRequirementResponse, fetchCollectiveResponses, fetchAskSellerThread, clearState } from "redux/evaluation/criteria/action";
import RatingPoper from "../common/RatingPoper";

const connectedProps = (state) => ({
  authUser: state.authUser.user,
  addResponseProgress: state.evaludationCriteria.addResponseProgress,
  successResponseAdd: state.evaludationCriteria.successResponseAdd,
  errorResponseAdd: state.evaludationCriteria.errorResponseAdd,
});

const connectionActions = {
  fetchCollectiveResponses: fetchCollectiveResponses,
  fetchAskSellerThread: fetchAskSellerThread,
  addRequirementResponse: addRequirementResponse,
  clearState: clearState
}

var connector = connect(connectedProps, connectionActions);
const useStyles = createStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  title: {
    fontWeight: 600,
    opacity: 1,
    textAlign: 'center',
    margin: 0,
    color: '#282D30',
    fontSize: theme.spacing(2.2)
  },
  subTitle: {
    fontWeight: 600,
    color: '#282D30',
    opacity: 1,
    fontSize: '1em',
    textAlign: 'center',
    background: '#F4F6F8 0% 0% no-repeat padding-box',
    borderRadius: '7px',
    padding: '15px',
    width: '90%',
    margin: '0px auto',
    marginBottom: '30px',
    "& [class*='editableField']": {
      background: '#F4F6F8',
      maxHeight: 'auto !important',
      minHeight: 'auto !important'
    }
  },
  appBar: {
    backgroundColor: '#ffffff',
    boxShadow: 'unset',
    borderBottom: '1px solid #EEEEEE'
  },
  privateTabIndicator: {
    border: '1.5px solid #4A87F8',
    background: '#4A87F8'
  },
  tabTitle: {
    color: '#282D30 !important',
    fontSize: '13px',
    fontWeight: 500
  },
  activeTabTitle: {
    color: '#4A87F8',
    fontSize: '14px',
    fontWeight: 600
  },
  description: {
    margin: 0,
    fontWeight: 400
  },
  name: {
    margin: 0,
    fontWeight: 600
  },
  comingSoon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 15,
    height: 'calc(100vh - 300px)',
    color: '#707070'
  },
  addTextimage: {
    marginRight: 10,
    width: 21,
    position: 'relative',
    top: 2
  },
  addText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80px',
    fontSize: 13,
    color: '#707070',
    cursor: 'pointer',
    transition: 'all linear .5s',
  },
  cummunityTabIndicator: {
    display: 'block',
    width: '6px',
    height: '6px',
    background: 'red',
    borderRadius: '4px',
    position: 'absolute',
    left: '-8px',
    top: '0px'
  },
  replylink: {
    fontWeight: 'normal',
    letterSpacing: '0px',
    marginRight: '25px',
    fontSize: '1rem',
    textDecoration: 'underline',
    color: '#4A87F8',
    marginTop: '16px',
    display: 'block'
  },
  emptyStateAnswer: {
    "& [class*='makeStyles-scoreResponse-']": {
      marginLeft: 0
    }
  },
  resList: {
    display: 'flex',
    flexGrow: 1
  },
  resListItem: {
    marginRight: '5px',
    padding: 0,
    borderRadius: '4px',
    border: '1px solid #707070',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    width: theme.spacing(20),
    height: theme.spacing(3.2)
  },
  resListItemActive: {
    marginRight: '5px',
    padding: 0,
    border: '1px solid #007BF7',
    justifyContent: 'center',
    color: ' #007BF7',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    width: theme.spacing(20),
    height: theme.spacing(3.2)

  },
}));

class CollectiveUserResponses extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabValue: this.props.collectiveTargetTab || 0,
      showMainReply: false,
      mainReply: ''
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue })
  };

  submitMainResponse = () => {
    const { evaluation_id, product, requirement, communityResponse } = this.props;
    this.props.addRequirementResponse(
      evaluation_id,
      product.ID,
      requirement.id, {
      note: this.state.mainReply,
      value: communityResponse == null ? null : communityResponse.Response,
    }
    )
  }

  componentDidMount() {
    this.fetchCollectiveResponse()
  }

  componentDidUpdate() {
    if (this.props.addResponseProgress === false && this.props.successResponseAdd) {
      this.props.clearState('clear_add_response')
      this.fetchCollectiveResponse()
    }
  }

  fetchCollectiveResponse = () => {
    if (this.props.product === undefined) {
      return;
    }
    let queryparams = {}
    queryparams.evaluation_id = this.props.evaluation_id | 0;
    queryparams.product_id = this.props.product.ID | 0;
    queryparams.requirement_id = this.props.requirement.id | 0;
    this.props.fetchCollectiveResponses(queryparams);
    this.props.fetchAskSellerThread(this.props.product.VendorUUID, this.props.requirement.id);
  }

  render() {
    const { classes } = this.props;
    const { collectiveRequirement, collectiveResponses, fetchCollectiveResponsesLoading } = this.props;

    const responses = collectiveResponses || [];


    if (this.props.requirement === null || responses.length <= 0) {
      return <div style={{ textAlign: 'center' }}>
        <p>{fetchCollectiveResponsesLoading ? <CircularProgress/> :'No Response'}</p>
      </div>;
    }

    let selectOptions = [
      {
        value: 10,
        label: "Yes"
      },
      {
        value: 5,
        label: "Partial"
      },
      {
        value: 1,
        label: "No"
      },
      {
        value: -1,
        label: "No"
      }
    ]

    return (
      <>
        <div>
          <div className={classes.root}>
            <>
              {
                responses.length === 0 && fetchCollectiveResponsesLoading === false &&
                <>
                  <div>
                    {
                      this.state.showMainReply === false && this.props.requirement.answer_type === 1 &&
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <div className={classnames(classes.addText, 'response-addText', 'evaluate-add-text')} onClick={() => { this.setState({ showMainReply: true }) }}>
                          <img className={classes.addTextimage} alt="" src={Messages} />
                          <span>Add Text</span>
                        </div>
                      </Grid>
                    }
                    {
                      fetchCollectiveResponsesLoading === false && this.state.showMainReply === true && (responses.length === 0 || responses[0].Note === '') &&
                      <>
                        <SlateInputField
                          style={{ width: '100%' }}
                          as={SlateInputField}
                          onChangeEvent={(value) => {
                            this.setState({ mainReply: value })
                          }}
                          placeholder={"Type your response here."}
                          initValue={""}
                          formControlStyle={{  }}
                          textContainerStyle={{ color: "#707070" }} />
                        <div style={{ textAlign: 'right' }}>
                          <Button style={{ marginRight: 20 }} variant="contained" color="primary" endIcon={<SendIcon />} onClick={() => {
                            this.submitMainResponse()
                          }}>
                            Save
                          </Button>
                          <Button variant="contained" color="primary" onClick={() => { this.setState({ showMainReply: false }) }}>
                            Cancel
                          </Button>
                        </div>
                      </>
                    }
                    <AnswerReply
                      requirement={this.props.requirement}
                      evaluation_id={this.props.evaluation_id}
                      OrgID={this.props.authUser.OrgID}
                      userOptions={[]}
                      onChange={(options, add_other = null) => {
                        if (this.props.requirement.answer_type === 5 || this.props.requirement.answer_type === 6) {
                          this.props.addRequirementResponse(
                            this.props.evaluation_id,
                            this.props.product.ID,
                            this.props.requirement.id, {
                            value: null,
                            note: null,
                            response_options: {
                              answer: add_other || ''
                            }
                          }
                          );
                        } else if (this.props.requirement.answer_type === 4) {
                          this.props.addRequirementResponse(
                            this.props.evaluation_id,
                            this.props.product.ID,
                            this.props.requirement.id, {
                            value: null,
                            note: null,
                            response_options: {
                              answer: options
                            }
                          }
                          );
                        } else {
                          this.props.addRequirementResponse(
                            this.props.evaluation_id,
                            this.props.product.ID,
                            this.props.requirement.id, {
                            value: null,
                            note: null,
                            response_options: {
                              option_ids: options,
                              answer: add_other || ''
                            }
                          }
                          );
                        }
                      }}
                    />

                    {
                      this.props.requirement.answer_type !== 1 &&
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        {!this.state.showComment &&
                          <div className={classnames(classes.addText, 'response-addText', 'evaluate-add-text')} onClick={() => { this.setState({ showComment: true }) }}>
                            <img className={classes.addTextimage} alt="" src={Messages} />
                            <span>Add Comment</span>
                          </div>
                        }

                        {this.state.showComment &&
                          <>
                            <SlateInputField
                              style={{ width: '100%' }}
                              as={SlateInputField}
                              onChangeEvent={(value) => {
                                this.setState({ mainReply: value })
                              }}
                              placeholder={"Type your response here."}
                              initValue={""}
                              formControlStyle={{  }}
                              textContainerStyle={{ color: "#707070" }} />
                            <div style={{ textAlign: 'right' }}>
                              <Button style={{ marginRight: 20 }} variant="contained" color="primary" endIcon={<SendIcon />} onClick={() => {
                                this.submitMainResponse()
                              }}>
                                Save
                              </Button>
                              <Button variant="contained" color="primary" onClick={() => { this.setState({ showComment: false }) }}>
                                Cancel
                              </Button>
                            </div>
                          </>
                        }
                      </Grid>
                    }

                    {this.props.enableScoring && <Grid item xs={12} className={classes.responseActionContainer}>
                      <div className={classes.reqMatch}>
                        <span>Score </span>

                        {this.props.scoring_mehanism === 'Textual' && <List className={classes.resList} disablePadding={false}>
                          {selectOptions.map((item, index) => {
                            var clsName = classes.resListItem;
                            if (item.value === responses?.Response) {
                              clsName = classes.resListItemActive;
                            }
                            return (
                              <ListItem key={index} className={clsName} onClick={() => {
                                let answer = {};
                                if (this.props.requirement.answer_type === 5 || this.props.requirement.answer_type === 4) {
                                  answer = {
                                    value: item.value,
                                    note: responses == null ? null : responses?.Note,
                                    response_options: {
                                      answer: (responses?.UserOptions && responses?.UserOptions[0]?.Answer) || ''
                                    }
                                  }
                                } else {
                                  answer = {
                                    value: item.value,
                                    note: responses == null ? null : responses?.Note,
                                    response_options: {
                                      option_ids: (responses == null || responses.UserOptions == null ? [] : responses.UserOptions.map((i) => {
                                        return i.OptionID
                                      }))
                                    }
                                  }
                                }
                                this.props._addRequirementResponse(
                                  this.props.match.params.evaluation_id,
                                  this.props.product.ID,
                                  this.props.requirement.id, {
                                  ...answer
                                }
                                );
                              }}>
                                {item.label}</ListItem>)
                          }
                          )}
                        </List>}

                        {this.props.scoring_mehanism === 'Numerical' &&
                          <RatingPoper
                            value={responses !== null && responses?.Response !== null ? responses?.Response : 0}
                            anchorEl={true}
                            onClose={() => {
                            }}
                            onSave={(value) => {
                              let answer = {};
                              if (this.props.requirement?.answer_type === 6 || this.props.requirement?.answer_type === 5 || this.props.requirement?.answer_type === 4) {
                                answer = {
                                  note: responses == null ? null : responses.Note,
                                  value: value,
                                  response_options: {
                                    answer: responses?.UserOptions[0]?.Answer
                                  }
                                }
                              } else {
                                answer = {
                                  note: responses == null ? null : responses.Note,
                                  value: value,
                                  response_options: {
                                    option_ids: (responses === null || responses.UserOptions === null ? [] : responses.UserOptions.map((i) => {
                                      return i.OptionID
                                    }))
                                  }
                                }
                              }

                              this.props.addRequirementResponse(
                                this.props.match.params.evaluation_id,
                                this.props.product.ID,
                                this.props.requirement.id, {
                                ...answer
                              }
                              );
                            }}
                          />}

                      </div>
                    </Grid>}

                  </div>
                </>
              }

              {/* (true || this.props.requirement?.answer_type !== 1 || responses.length > 0 && responses[0].Note !== '') */}
              {
                responses.map((response, k) => {
                  return <UserMessages
                    criteria={this.props.criteria}
                    product={this.props.product}
                    requirement={this.props.requirement}
                    evaluation_id={this.props.evaluation_id}
                    response={response}
                    collectiveRequirement={collectiveRequirement} key={k}
                    replyResponse={true}
                    scoring_mehanism={this.props.scoring_mehanism}
                    enableScoring={this.props.enableScoring}
                  />
                })
              }
            </>
          </div>
        </div>
      </>
    );
  }

}

export default connector(compose(withRouter, withStyles(useStyles))(CollectiveUserResponses));