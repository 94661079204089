import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, IconButton, Paper, Button } from "@material-ui/core";

import AppBarV2 from "../../../../AppBarV2";
import { fetchDocTypes, updateDocType } from "redux/documentTypes/action";
import { showSnackBar } from "redux/snackbar/action";
import { documentGlobalFields } from "util/AppCatalogTypeOptions";

const connectedProps = (state) => ({
  fetchDocTypesProgress: state.documentType.fetchDocTypesProgress,
  fetchDocTypesError: state.documentType.fetchDocTypesError,
  types: state.documentType.types,

  updateDocTypeProgress: state.documentType.updateDocTypeProgress,
  updateDocTypeError: state.documentType.updateDocTypeError,
  updateDocTypeSuccess: state.documentType.updateDocTypeSuccess,
});

const connectionActions = {
  fetchDocTypes: fetchDocTypes,
  updateDocType: updateDocType,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: {
      padding: "2rem 4rem",
    },
    root: {
      "& .react-datepicker-popper": {
        zIndex: 2222,
      },
      "& .react-datepicker__input-container > div": {
        display: "flex",
        minWidth: 300,
        marginBottom: 0,
        alignItems: "baseline",
        "& [class*=MuiInputBase-root]": {
          maxHeight: 33,
          minHeight: 33,
          width: 166,
          "& input": {
            fontSize: theme.spacing(1.8),
            // width: 107,
            textAlign: "center",
          },
        },
        "& [class*=MuiFormLabel-root]": {
          marginBottom: 0,
          marginRight: 29,
        },
      },
      "[class*=MuiMenuItem-root]": {
        color: "#707070",
        fontSize: theme.spacing(1.8),
      },
    },
    approversFlex: {
      display: "flex",
      justifyContent: "end",
      "& [class*=MuiAvatar-root]": {
        width: 25,
        height: 25,
        fontSize: 14,
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    topActions: {
      textAlign: "right",
      marginBottom: 20,
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    nameCol: {
      width: "80%",
    },

    actionCol: {
      textAlign: "center",
      width: "20%",
    },
  });

class DocumentTypesVisibility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenAttributes: {},
      documentType: null,
      disableButton: true,
    };
  }

  componentDidMount() {
    this.props.fetchDocTypes();
  }

  componentDidUpdate(prevProps) {
    const { updateDocTypeProgress, updateDocTypeError, fetchDocTypesProgress, fetchDocTypesError } = this.props;

    if (!updateDocTypeProgress && prevProps.updateDocTypeProgress) {
      if (updateDocTypeError === null) {
        this.props.fetchDocTypes();
        this.props.showSnackBar("Updated successfully.", "success", 3000);
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }

    if (!fetchDocTypesProgress && prevProps.fetchDocTypesProgress) {
      if (fetchDocTypesError === null) {
        this.initializeDocType();
      }
    }
  }

  initializeDocType() {
    const { types } = this.props;
    if (types) {
      let type = types.find((item) => item.id === Number(this.props.match?.params?.id));
      console.log(types, type, this.props.match?.params.id, "kk");
      let hiddenData = {};
      if (type && type.AdditionalData) {
        try {
          hiddenData = JSON.parse(type.AdditionalData);
        } catch (e) {}
      }
      this.setState({ hiddenAttributes: hiddenData, documentType: type });
    }
  }

  submitResponse() {
    let params = {
      name: this.state.documentType?.name,
      type: this.state.documentType?.type,
      AdditionalData: JSON.stringify(this.state.hiddenAttributes),
    };
    this.props.updateDocType(this.state.documentType?.id, params);
  }

  updateVisibility(attribute) {
    let { hiddenAttributes } = this.state;
    if (hiddenAttributes[attribute]) {
      delete hiddenAttributes[attribute];
    } else {
      hiddenAttributes[attribute] = true;
    }
    this.setState({
      hiddenAttributes: hiddenAttributes,
      disableButton: false,
    });
  }

  render() {
    const { classes, types } = this.props;
    let type = types.find((item) => item.id === Number(this.props.match?.params?.id));

    return (
      <div classn={classes.root}>
        <AppBarV2 title={type.name ? type.name : "Document Types"} withBack={true} />
        <div className={classes.rootContainer}>
          <div className={classes.topActions}>
            <Button
              color={"secondary"}
              variant={"contained"}
              disabled={this.state.disableButton || this.props.updateDocTypeProgress}
              onClick={() => {
                this.submitResponse();
              }}
            >
              Update
            </Button>
          </div>
          <div className={classes.timelineDatas}>
            <Paper style={{ width: "100%", overflow: "hidden" }}>
              <TableContainer style={{ maxHeight: "78vh" }}>
                <Table className={classes.mandatoryTable} size="small" stickyHeader aria-label="sticky table" key={"table_" + this.props.key}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.nameCol}>Name</TableCell>
                      <TableCell className={classes.actionCol}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!this.props.fetchDocTypesProgress &&
                      documentGlobalFields?.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className={classes.nameCol}>{item.Label}</TableCell>
                            <TableCell className={classes.actionCol}>
                              <IconButton onClick={() => this.updateVisibility(item.Attribute)}>{this.state.hiddenAttributes[item.Attribute] ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}

                    {this.props.fetchDocTypesProgress && (
                      <TableRow>
                        <TableCell colSpan={2} style={{ textAlign: "center" }} className={classes.nameCol}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(DocumentTypesVisibility));
