import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, IconButton, CircularProgress, FormControl, MenuItem, Select, Button, Grid, List, ListItem, Popover } from "@material-ui/core";
// import Image from "Components/Common/image.jsx";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
// import editIcon from "assets/images/edit_icon.svg";
import * as Validator from "util/Validation";
import { Avatar } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
// import SqureButton from 'Components/Common/SqureButton';
import Alert from "@material-ui/lab/Alert";
// import ReactSelect from "react-select";

import { showSnackBar } from "redux/snackbar/action";
import {
  showAddCustomEmailPoperDialog,
  hideAddCustomEmailPoperDialog,
  showSearchTeamsPoperDialog,
  hideSearchTeamsPoperDialog,
  showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog,
} from "redux/dialogs/action";
import SearchTeamsPoper from "Components/Common/TeamsAndUser/SearchTeamsPoper";
import SearchUserPoper from "Components/Common/TeamsAndUser/SearchUserPoper";
import AddCustomEmailDialog from "Components/Common/Dialogs/AddCustomEmailDialog";
import TargetAudienceDialog from "../../dialogs/TargetAudienceDialog";
import { showUserDetailsPoperDialog, hideUserDetailsPoperDialog } from "redux/dialogs/action";

import { addAlertTeam, updateAlert, fetchAlertTeams, deleteAlertTeam, addAlertUser, fetchAlertUsers, deleteAlertUser, addAlertEmail, fetchAlertEmails, deleteAlertEmail } from "redux/alerts/action";

const connectedProps = (state) => ({
  updateAlertProgress: state.alert.updateAlertProgress,
  updateAlertError: state.alert.updateAlertError,
  updatedTaskData: state.alert.updatedTaskData,
  fetchAlertProgress: state.alert.fetchAlertProgress,
  fetchAlertError: state.alert.fetchAlertError,
  alertDetails: state.alert.alertDetails,
  isOpenTeamDialog: state.dialogs.isShowSearchTeamPoperDialog,
  isOpenUserDialog: state.dialogs.isShowSearchUserPoperDialog,
  isAddCustomEmailPoperDialog: state.dialogs.isAddCustomEmailPoperDialog,
  addAlertTeamProgress: state.alert.addAlertTeamProgress,
  addAlertTeamError: state.alert.addAlertTeamError,
  fetchAlertTeamProgress: state.alert.fetchAlertTeamProgress,
  fetchAlertTeamError: state.alert.fetchAlertTeamError,
  deleteAlertTeamProgress: state.alert.deleteAlertTeamProgress,
  deleteAlertTeamError: state.alert.deleteAlertTeamError,
  addAlertUserProgress: state.alert.addAlertUserProgress,
  addAlertUserError: state.alert.addAlertUserError,
  fetchAlertUserProgress: state.alert.fetchAlertUserProgress,
  fetchAlertUserError: state.alert.fetchAlertUserError,
  deleteAlertUserProgress: state.alert.deleteAlertUserProgress,
  deleteAlertUserError: state.alert.deleteAlertUserError,
  addAlertEmailProgress: state.alert.addAlertEmailProgress,
  addAlertEmailError: state.alert.addAlertEmailError,
  fetchAlertEmailProgress: state.alert.fetchAlertEmailProgress,
  fetchAlertEmailError: state.alert.fetchAlertEmailError,
  deleteAlertEmailProgress: state.alert.deleteAlertEmailProgress,
  deleteAlertEmailError: state.alert.deleteAlertEmailError,
  alertTeams: state.alert.alertTeams,
  alertUsers: state.alert.alertUsers,
  alertEmails: state.alert.alertEmails,
  orgProductDetailTabs: state.orgProduct.orgProductDetailTabs,
  orgProductDetailTabsProgress: state.orgProduct.orgProductDetailTabsProgress,
  isOpenUserDetailDialog: state.dialogs.isUserDetailsPoperDialog,
});

const connectionActions = {
  addAlertTeam: addAlertTeam,
  fetchAlertTeams: fetchAlertTeams,
  deleteAlertTeam: deleteAlertTeam,
  addAlertUser: addAlertUser,
  fetchAlertUsers: fetchAlertUsers,
  deleteAlertUser: deleteAlertUser,
  addAlertEmail: addAlertEmail,
  fetchAlertEmails: fetchAlertEmails,
  deleteAlertEmail: deleteAlertEmail,
  updateAlert: updateAlert,
  showSnackBar: showSnackBar,
  showSearchTeamsPoperDialog: showSearchTeamsPoperDialog,
  hideSearchTeamsPoperDialog: hideSearchTeamsPoperDialog,
  showSearchUsersPoperDialog: showSearchUsersPoperDialog,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
  showAddCustomEmailPoperDialog: showAddCustomEmailPoperDialog,
  hideAddCustomEmailPoperDialog: hideAddCustomEmailPoperDialog,
  showUserDetailsPoperDialog: showUserDetailsPoperDialog,
  hideUserDetailsPoperDialog: hideUserDetailsPoperDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    rootContainer: CommonCss.rootContainer,
    paper: {
      padding: 20,
      minHeight: "87vh",
    },
    topHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    tickMark: {
      color: "#4b86f8",
      border: "1px solid #4b86f8",
      marginRight: 10,
      minWidth: "50px !important",
      "&:hover": {
        color: "#4b86f8",
      },
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 6,
      marginBottom: 5,
      cursor: "pointer",
    },
    alert: {
      marginBottom: 10,
      marginTop: 25,
    },
    tName: {
      padding: "12px 0px",
      fontWeight: 600,
    },
    editIconDiv: {
      textAlign: "right",
    },
    item: {
      marginBottom: 10,
    },
    itmHeading: {
      color: "#222",
      fontSize: theme.spacing(2.2),
      fontWeight: 500,
    },
    itmRes: {
      fontSize: 14,
    },
    bottomActions: {
      textAlign: "right",
    },
    actionBtn: {
      marginTop: 10,
      width: "100%",
    },
    formItem: {
      marginBottom: 20,
    },
    formUserItem: {
      marginBottom: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    inputItem: {
      '& [class*="MuiInputBase-input"]': {
        padding: 12,
      },
    },
    inputFormControl: {
      ...CommonCss.formControl,
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
      },
      padding: "10.5px",
    },
    formControl: {
      width: "100%",
      "& > div": {
        color: "#282D30",
        fontSize: theme.spacing(2.2),
      },
    },
    filterTitle: {
      marginBottom: theme.spacing(1),
      color: "#222",
      fontSize: theme.spacing(2.2),
    },
    // body: {
    //   marginTop: 20,
    //   border: '1px solid #d7c8c8',
    //   padding: 10,
    //   borderRadius: 4
    // },
    otherItems: {
      marginTop: 20,
    },
    userWrap: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& [class*=MuiAvatar-root]": {
        width: 30,
        height: 30,
        fontSize: 14,
        cursor: "pointer",
      },
      "& [class*=MuiAvatarGroup-root]": {
        alignItems: "center",
        width: 30,
        minWidth: 30,
        height: 30,
      },
      "& [class*=MuiAvatarGroup-avatar]": {
        margin: 0,
      },
    },
    teamName: {
      marginRight: 10,
      marginTop: 10,
      width: "max-content",
      border: "1px solid #222",
      borderRadius: 4,
      padding: "2px 5px",
    },
    addTeamBtn: {
      marginTop: 20,
      width: "100%",
      padding: "2px 10px",
      background: "#20253a",
    },
    chipItm: {
      marginRight: 10,
      marginBottom: 10,
    },
    noItem: {
      textAlign: "center",
    },
  });

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      isLoading: true,
      typesMap: {
        1: "Trigger",
      },
      targetTypeMap: {
        1: "Application Catalog",
      },
      alertInfo: {},
      types: [
        {
          name: "Trigger",
          value: 1,
        },
      ],
      title: "",
      editTitle: false,
      notification: "",
      editNotification: false,
      status: "",
      editStatus: false,
      selectedTeams: [],
      selectedUsers: [],
      selectedEmails: [],
      appCatlogTargetOptions: [],
      selectedAppCatlogTarget: null,
      selectedAppCatlogTargetId: null,
      anchorEl: null,
    };

    this.btnRef = React.createRef();
    this.clearError = this.clearError.bind(this);
    this.updateAlertValue = this.updateAlertValue.bind(this);
    this.handleDeleteTeam = this.handleDeleteTeam.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleDeleteEmail = this.handleDeleteEmail.bind(this);
  }

  componentDidMount() {
    this.props.fetchAlertTeams(this.props.match.params.alert_id);
    this.props.fetchAlertUsers(this.props.match.params.alert_id);
    this.props.fetchAlertEmails(this.props.match.params.alert_id);
    this.setState({
      alertInfo: this.props.alertDetails,
      status: this.props.alertDetails.Status,
      title: this.props.alertDetails?.Title,
      notification: this.props.alertDetails?.Notification,
      selectedAppCatlogTargetId: this.props.alertDetails?.AppCatalogTarget !== null ? this.props.alertDetails?.AppCatalogTarget : 0,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      fetchAlertProgress,
      fetchAlertError,
      updateAlertProgress,
      updateAlertError,
      addAlertTeamProgress,
      addAlertTeamError,
      deleteAlertTeamProgress,
      deleteAlertTeamError,
      fetchAlertTeamProgress,
      fetchAlertTeamError,
      addAlertUserProgress,
      addAlertUserError,
      deleteAlertUserProgress,
      deleteAlertUserError,
      fetchAlertUserProgress,
      fetchAlertUserError,
      addAlertEmailProgress,
      addAlertEmailError,
      deleteAlertEmailProgress,
      deleteAlertEmailError,
      fetchAlertEmailProgress,
      fetchAlertEmailError,
      orgProductDetailTabsProgress,
    } = this.props;

    if (!orgProductDetailTabsProgress && prevProps.orgProductDetailTabsProgress) {
      this.createAppCatlogOptionMap();
    }

    if (!addAlertTeamProgress && prevProps.addAlertTeamProgress) {
      if (addAlertTeamError === null) {
        this.props.fetchAlertTeams(this.props.match.params.alert_id);
      } else {
        this.props.showSnackBar("Failed to add team.", "error", 3000);
      }
    }

    if (!deleteAlertTeamProgress && prevProps.deleteAlertTeamProgress) {
      if (deleteAlertTeamError === null) {
        this.props.fetchAlertTeams(this.props.match.params.alert_id);
      } else {
        this.props.showSnackBar("Failed to delete team.", "error", 3000);
      }
    }

    if (!fetchAlertTeamProgress && prevProps.fetchAlertTeamProgress) {
      if (fetchAlertTeamError === null) {
        this.setState({
          selectedTeams: this.props.alertTeams,
        });
      } else {
        this.props.showSnackBar("Failed to fetch teams.", "error", 3000);
      }
    }

    if (!addAlertUserProgress && prevProps.addAlertUserProgress) {
      if (addAlertUserError === null) {
        this.props.fetchAlertUsers(this.props.match.params.alert_id);
      } else {
        this.props.showSnackBar("Failed to add User.", "error", 3000);
      }
    }

    if (!deleteAlertUserProgress && prevProps.deleteAlertUserProgress) {
      if (deleteAlertUserError === null) {
        this.props.fetchAlertUsers(this.props.match.params.alert_id);
      } else {
        this.props.showSnackBar("Failed to delete User.", "error", 3000);
      }
    }

    if (!fetchAlertUserProgress && prevProps.fetchAlertUserProgress) {
      if (fetchAlertUserError === null) {
        this.setState({
          selectedUsers: this.props.alertUsers,
        });
      } else {
        this.props.showSnackBar("Failed to fetch User.", "error", 3000);
      }
    }

    if (!addAlertEmailProgress && prevProps.addAlertEmailProgress) {
      if (addAlertEmailError === null) {
        this.props.fetchAlertEmails(this.props.match.params.alert_id);
      } else {
        this.props.showSnackBar("Failed to add Email.", "error", 3000);
      }
    }

    if (!deleteAlertEmailProgress && prevProps.deleteAlertEmailProgress) {
      if (deleteAlertEmailError === null) {
        this.props.fetchAlertEmails(this.props.match.params.alert_id);
      } else {
        this.props.showSnackBar("Failed to delete Email.", "error", 3000);
      }
    }

    if (!fetchAlertEmailProgress && prevProps.fetchAlertEmailProgress) {
      if (fetchAlertEmailError === null) {
        this.setState({
          selectedEmails: this.props.alertEmails,
        });
      } else {
        this.props.showSnackBar("Failed to fetch Email.", "error", 3000);
      }
    }

    if (!fetchAlertProgress && prevProps.fetchAlertProgress) {
      if (fetchAlertError === null) {
        this.setState({
          alertInfo: this.props.alertDetails,
          status: this.props.alertDetails.Status,
          title: this.props.alertDetails?.Title,
          notification: this.props.alertDetails?.Notification,
        });
      }
    }

    if (!updateAlertProgress && prevProps.updateAlertProgress) {
      if (updateAlertError === null) {
        this.setState({
          alertInfo: this.props.updatedTaskData,
          title: this.props.updatedTaskData?.Title,
          status: this.props.updatedTaskData.Status,
          notification: this.props.updatedTaskData?.Notification,
        });
        this.props.showSnackBar("Alert updated successfully.", "success", 3000);
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000);
      }
    }
  }

  createAppCatlogOptionMap() {
    var optionsData = [];
    var optionMap = {};
    var validTypes = [12];
    const selectedAppCatlogTargetId = this.props.alertDetails?.AppCatalogTarget !== null ? this.props.alertDetails?.AppCatalogTarget : 0;
    let selectedAppCatlogTarget = null;

    this.props.orgProductDetailTabs.forEach(function (tab) {
      tab.OrgProductTabSections.forEach(function (section) {
        section.OrgProductTabItems.forEach(function (item) {
          if (validTypes.indexOf(item.Type) !== -1) {
            let tabName = tab.TabName;
            // let sectionName = section.SectionName;
            let itemName = item.ItemName;
            if (itemName === null || itemName === "") {
              return;
            }
            let itemId = item.ID;
            var parentOpName = tabName;
            // var parentOpName = tabName + ' - ' + sectionName;
            if (optionMap[parentOpName] === undefined) {
              optionMap[parentOpName] = {
                label: parentOpName,
                options: [
                  {
                    label: itemName,
                    value: itemId,
                    type: item.Type,
                    data: Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [],
                  },
                ],
              };
            } else {
              optionMap[parentOpName].options.push({
                label: itemName,
                value: itemId,
                type: item.Type,
                data: Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [],
              });
            }
            if (itemId === selectedAppCatlogTargetId) {
              selectedAppCatlogTarget = {
                label: itemName,
                value: itemId,
                type: item.Type,
                data: Validator.isJson(item.AdditionalData) ? JSON.parse(item.AdditionalData) : [],
              };
            }
          }
        });
      });
    });
    Object.keys(optionMap).forEach(function (key) {
      optionsData.push(optionMap[key]);
    });
    this.setState({
      appCatlogTargetOptions: optionsData,
      selectedAppCatlogTarget: selectedAppCatlogTarget,
    });
  }

  clearError() {
    this.setState({
      success: "",
      error: "",
    });
  }

  updateAlertValue(newValue, field) {
    let alertInfo = this.state.alertInfo;
    alertInfo[field] = newValue;

    let selectedAppCatlogTarget = this.state.selectedAppCatlogTarget;
    if (field === "AppCatalogTarget") {
      selectedAppCatlogTarget = newValue;
    }

    this.setState(
      {
        alertInfo: alertInfo,
        selectedAppCatlogTarget: selectedAppCatlogTarget,
      },
      () => {
        this.updateOnChange();
      }
    );
  }

  updateOnChange = () => {
    let alertInfo = this.state.alertInfo;
    // console.log(alertInfo)
    // return;
    let postData = {
      target_type: alertInfo?.TargetType,
      app_catalog_target: alertInfo?.AppCatalogTarget !== null && alertInfo?.AppCatalogTarget !== 0 ? alertInfo?.AppCatalogTarget.value : 0,
    };
    this.props.updateAlert(this.props.match.params.alert_id, postData);
  };

  updateTitle = () => {
    this.clearError();
    const { title } = this.state;

    if (title === "" || title.length <= 0) {
      this.setState({
        error: "Please enter title",
      });
      return;
    }
    this.setState({ editTitle: false });
    let postData = {
      title: title,
    };

    this.props.updateAlert(this.props.match.params.alert_id, postData);
  };

  updateNotification = () => {
    this.clearError();
    const { notification } = this.state;

    if (notification === "" || notification.length <= 0) {
      this.setState({
        error: "Please enter notification message",
      });
      return;
    }
    this.setState({ editNotification: false });
    let postData = {
      notification: notification,
    };

    this.props.updateAlert(this.props.match.params.alert_id, postData);
  };

  updateStatus = () => {
    this.clearError();
    const { status } = this.state;

    let postData = {
      status: status,
    };
    this.setState({ editStatus: false });
    this.props.updateAlert(this.props.match.params.alert_id, postData);
  };

  handleDeleteTeam(teamId) {
    console.log(teamId);
    this.props.deleteAlertTeam(this.props.match.params.alert_id, teamId);
  }

  handleDeleteUser(userId) {
    console.log(userId);
    this.props.deleteAlertUser(this.props.match.params.alert_id, userId);
  }

  handleDeleteEmail(emailId) {
    console.log(emailId);
    this.props.deleteAlertEmail(this.props.match.params.alert_id, emailId);
  }

  handleAddAudienceClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleAudienceClose = () => {
    this.setState({ anchorEl: null });
  };

  avatarName(name) {
    return name.substring(0, 1).toUpperCase();
  }

  render() {
    const classes = this.props.classes;
    const { alertInfo, title, status, notification, selectedTeams, selectedUsers, selectedEmails, editTitle, editNotification, editStatus } = this.state;
    const { fetchAlertTeamProgress, fetchAlertUserProgress, fetchAlertEmailProgress, deleteAlertTeamProgress, deleteAlertUserProgress, deleteAlertEmailProgress } = this.props;
    const targetAudienceLoading = fetchAlertTeamProgress || fetchAlertUserProgress || fetchAlertEmailProgress || deleteAlertTeamProgress || deleteAlertUserProgress || deleteAlertEmailProgress;
    const openAddAudience = Boolean(this.state.anchorEl);
    return (
      <Paper className={classes.paper}>
        <div className={classes.topHead}>
          <div className={classes.title}>
            <Typography className={classes.tName}>Alert Details</Typography>
          </div>
        </div>

        <div className={classes.alert}>
          {this.state.error.length > 0 && (
            <Alert variant="filled" severity="error">
              {this.state.error}
            </Alert>
          )}
        </div>

        {
          <div className={classes.body}>
            <div>
              <div className={classes.formItem}>
                {!editTitle ? (
                  <>
                    <Typography variant={"h4"} className={classes.filterTitle}>
                      Title{" "}
                      <EditIcon
                        fontSize={"small"}
                        className={classes.editIcon}
                        onClick={() => {
                          this.setState({
                            editTitle: true,
                          });
                        }}
                      />
                    </Typography>
                    <Typography className={classes.itmRes}>{title}</Typography>
                  </>
                ) : (
                  <>
                    <Typography variant={"h4"} className={classes.filterTitle}>
                      Title
                    </Typography>
                    <input
                      className={classes.inputFormControl}
                      placeholder="Enter title"
                      name="title"
                      value={title}
                      onChange={(e) => {
                        this.setState({
                          title: e.target.value,
                        });
                      }}
                    />
                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                      <Grid item sm={12} style={{ marginTop: 10, textAlign: "right" }}>
                        <Button className={classes.tickMark} onClick={this.updateTitle}>
                          <DoneIcon />
                        </Button>
                        <Button className={classes.tickMark} onClick={() => this.setState({ editTitle: false, title: alertInfo?.Title, error: "" })}>
                          <CloseIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
              <div className={classes.formItem}>
                {!editNotification ? (
                  <>
                    <Typography variant={"h4"} className={classes.filterTitle}>
                      Notification{" "}
                      <EditIcon
                        fontSize={"small"}
                        className={classes.editIcon}
                        onClick={() => {
                          this.setState({
                            editNotification: true,
                          });
                        }}
                      />
                    </Typography>
                    <Typography className={classes.itmRes}>{notification}</Typography>
                  </>
                ) : (
                  <>
                    <Typography variant={"h4"} className={classes.filterTitle}>
                      Notification
                    </Typography>
                    <textarea
                      rows={3}
                      className={classes.inputFormControl}
                      placeholder="Enter notification"
                      name="notification"
                      value={notification}
                      onChange={(e) => {
                        this.setState({
                          notification: e.target.value,
                        });
                      }}
                    />
                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                      <Grid item sm={12} style={{ marginTop: 10, textAlign: "right" }}>
                        <Button className={classes.tickMark} onClick={this.updateNotification}>
                          <DoneIcon />
                        </Button>
                        <Button className={classes.tickMark} onClick={() => this.setState({ editNotification: false, notification: alertInfo?.Notification, error: "" })}>
                          <CloseIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>

              <div className={classes.formItem}>
                {!editStatus ? (
                  <>
                    <Typography variant={"h4"} className={classes.filterTitle}>
                      Status{" "}
                      <EditIcon
                        fontSize={"small"}
                        className={classes.editIcon}
                        onClick={() => {
                          this.setState({
                            editStatus: true,
                          });
                        }}
                      />
                    </Typography>
                    <Typography className={classes.itmRes}>{status === 1 ? "Active" : "Inactive"}</Typography>
                  </>
                ) : (
                  <>
                    <Typography variant={"h4"} className={classes.filterTitle}>
                      Status
                    </Typography>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                      <Select
                        disabled={false}
                        value={parseInt(status)}
                        onChange={(e) => {
                          this.setState({ status: e.target.value });
                        }}
                      >
                        <MenuItem value={1} style={{ fontSize: 13 }}>
                          Active
                        </MenuItem>
                        <MenuItem value={2} style={{ fontSize: 13 }}>
                          Inactive
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                      <Grid item sm={12} style={{ marginTop: 10, textAlign: "right" }}>
                        <Button className={classes.tickMark} onClick={this.updateStatus}>
                          <DoneIcon />
                        </Button>
                        <Button className={classes.tickMark} onClick={() => this.setState({ editStatus: false, status: this.props.alertDetails.Status, error: "" })}>
                          <CloseIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
            </div>
          </div>
        }

        <div className={classes.otherItems}>
          <div className={classes.formUserItem}>
            <Typography style={{ fontWeight: 500, marginBottom: 0 }} className={classes.filterTitle}>
              Target Audience
            </Typography>
            <IconButton ref={this.btnRef} className={classes.addUserIcon} onClick={this.handleAddAudienceClick}>
              <AddCircleIcon />
            </IconButton>
          </div>
          {targetAudienceLoading ? (
            <CircularProgress style={{ width: 24, height: 24 }} />
          ) : (
            <div className={classes.userWrap}>
              {selectedUsers.map((user, k) => {
                let tooltipMsg = user.Name + "\n" + user.Email;
                return (
                  <AvatarGroup>
                    <Tooltip placement="top" title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{tooltipMsg}</span>} arrow>
                      <Avatar style={{ zIndex: 0 }} key={k} onClick={() => this.props.showUserDetailsPoperDialog({ type: "User", Name: user.Name, Email: user.Email, ID: user.ID })}>
                        {this.avatarName(user.Name)}
                      </Avatar>
                    </Tooltip>
                  </AvatarGroup>
                );
              })}
              {selectedTeams.map((user, k) => {
                let tooltipMsg = user.Name + "\n" + user.Email;
                return (
                  <AvatarGroup>
                    <Tooltip placement="top" title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{tooltipMsg}</span>} arrow>
                      <Avatar style={{ zIndex: 0 }} key={k} onClick={() => this.props.showUserDetailsPoperDialog({ type: "Team", Name: user.Name, Email: user.Email, ID: user.ID })}>
                        {this.avatarName(user.Name)}
                      </Avatar>
                    </Tooltip>
                  </AvatarGroup>
                );
              })}
              {selectedEmails.map((user, k) => {
                let tooltipMsg = user.EmailID;
                return (
                  <AvatarGroup>
                    <Tooltip placement="top" title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>{tooltipMsg}</span>} arrow>
                      <Avatar style={{ zIndex: 0 }} key={k} onClick={() => this.props.showUserDetailsPoperDialog({ type: "Custom Email", Email: user.EmailID })}>
                        {this.avatarName(user.EmailID)}
                      </Avatar>
                    </Tooltip>
                  </AvatarGroup>
                );
              })}
            </div>
          )}
        </div>

        {this.props.isOpenTeamDialog && (
          <SearchTeamsPoper
            selectedTeams={this.state.selectedTeams}
            onAdd={(team) => {
              this.props.addAlertTeam(this.props.match.params.alert_id, team.ID);
            }}
            onRemove={(team) => {
              this.props.deleteAlertTeam(this.props.match.params.alert_id, team.ID);
            }}
            onClose={() => {
              this.props.hideSearchTeamsPoperDialog();
            }}
          />
        )}

        {this.props.isOpenUserDialog && (
          <SearchUserPoper
            selectedUsers={this.state.selectedUsers}
            onAdd={(user) => {
              this.props.addAlertUser(this.props.match.params.alert_id, user.ID);
            }}
            onRemove={(user) => {
              this.props.deleteAlertUser(this.props.match.params.alert_id, user.ID);
            }}
            onClose={() => {
              this.props.hideSearchUsersPoperDialog();
            }}
          />
        )}
        <Popover
          className={classes.popover}
          id={"add-vendor"}
          open={openAddAudience}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handleAudienceClose()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List className={classes.menuList}>
            <ListItem
              button
              onClick={() => {
                this.handleAudienceClose();
                this.props.showSearchUsersPoperDialog()();
              }}
            >
              <Typography variant="inherit">Add User</Typography>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.handleAudienceClose();
                this.props.showSearchTeamsPoperDialog()();
              }}
            >
              <Typography variant="inherit">Add Team</Typography>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.handleAudienceClose();
                this.props.showAddCustomEmailPoperDialog();
              }}
            >
              <Typography variant="inherit">Add Custom Email</Typography>
            </ListItem>
          </List>
        </Popover>

        {this.props.isAddCustomEmailPoperDialog && (
          <AddCustomEmailDialog
            onAdd={(emails) => {
              this.props.addAlertEmail(this.props.match.params.alert_id, { emails });
            }}
          />
        )}
        {this.props.isOpenUserDetailDialog && (
          <TargetAudienceDialog
            onRemove={(data) => {
              if (data?.type === "Team") {
                this.handleDeleteTeam(data?.ID);
              } else if (data?.type === "User") {
                this.handleDeleteUser(data?.ID);
              } else if (data?.type === "Custom Email") {
                this.handleDeleteEmail(data?.Email);
              }
            }}
          />
        )}
      </Paper>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Details));
