import React from 'react';
import _ from 'underscore';
import { connect } from "react-redux";
import classnames from "classnames";
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { createStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControlLabel, Checkbox, Dialog, DialogContent, Button,
  Slide, Typography, Grid, Switch, Divider, CircularProgress
} from '@material-ui/core';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import UserGroup from "Components/Common/UserGroup/index";
import SqureButton from 'Components/Common/SqureButton';
import Image from 'Components/Common/image.jsx';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import EvaluateMultiChoiceAnswer from 'Components/Common/EvaluateMultiChoiceAnswer'
import deleteIcon from "assets/images/delete.svg";
import { fetchOrgTemplates, saveFromOrgEvaluationTemplate } from "redux/templates/evaluation/org/action";
import { showSnackBar } from "redux/snackbar/action";
import { showEvaluationCollaboratorDialog } from "redux/evaluate/action";
import SchemaTagSelectionDialog from '../../WorkflowBuilder/Dialogs/SchemaTagSelectionDialog.jsx';
import CodeIcon from '@material-ui/icons/Code';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  saveProgress: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateProg,
  saveError: state.orgTemplateEvaluation.saveFromOrgEvaluationTemplateError,
  collaboratorDialogData: state.evaluate.evaluationCollaboratorDialogData,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  saveFromOrgEvaluationTemplate: saveFromOrgEvaluationTemplate,
  fetchOrgTemplates: fetchOrgTemplates,
  showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
    padding: theme.spacing(6, 12) + " !important",
  },
  modalTitle: {
    fontSize: theme.spacing(2.4),
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  helptext: {
    fontSize: theme.spacing(1.8)
  },
  alerts: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  form: {
    marginTop: theme.spacing(2),
    width: '60%',
  },
  input: {
    marginBottom: theme.spacing(3)
  },
  scheduleTitle: {
    display: "block",
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    fontSize: theme.spacing(2)
  },
  scheduleTitle1: {
    display: "block",
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    fontSize: theme.spacing(1.8)
  },
  actions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  loader: {
    textAlign: "center",
    padding: theme.spacing(10, 0)
  },
  dialogAction: {
    textAlign: "center"
  },
  addNewButton: {
    fontWeight: 600,
    fontSize: theme.spacing(2)
  },
  actionBtn: {
    minWidth: 110
  },
  nameYour: {
    "& [class*=MuiInputBase-root]": {
      maxHeight: 40,
      minHeight: 40,
      "& input": {
        fontSize: theme.spacing(1.8)
      }
    },
  },
  nameOfCriteria: {
    "& > div": {
      marginBottom: 0
    },
  },
  priority: {
    "& > div": {
      marginBottom: 0,
      justifyContent: 'flex-start',
    },
    "& [class*=MuiInputBase-root]": {
      width: 100,
      color: '#707070',
      fontSize: 13,
      textAlign: 'center'
    },
    "& [class*=MuiSelect-outlined]": {
      color: "#707070",
      fontSize: theme.spacing(1.8),
      "&:after": {
        content: "''",
        width: 7,
        height: 7,
        position: 'absolute',
        left: 9,
        borderRadius: '50%',
        top: '50%',
        transform: 'translate(0px, -50%)'
      },
    },
    "&.low [class*=MuiSelect-outlined]:after": {
      background: 'green'
    },
    "&.medium [class*=MuiSelect-outlined]:after": {
      background: 'orange'
    },
    "&.high [class*=MuiSelect-outlined]:after": {
      background: 'red'
    }
  },
  scoring: {
    "& > div": {
      alignItems: "baseline",
      marginBottom: 0
    },
    "& [class*=MuiSelect-outlined]": {
      paddingLeft: 10
    }
  },
  yourQuestion: {
    "& [class*=MuiInputBase-root]": {
      padding: 0,
      fontSize: theme.spacing(1.8),
      minHeight: 120,
      color: "#707070",
      lineHeight: 1.2,
      "& textarea": {
        minHeight: 100,
      }
    }
  },
  addCriteriaDatePicker: {
    "& .react-datepicker__input-container > div": {
      display: 'flex',
      minWidth: 300,
      marginBottom: 0,
      alignItems: 'baseline',
      "& [class*=MuiInputBase-root]": {
        maxHeight: 33,
        minHeight: 33,
        width: 166,
        "& input": {
          fontSize: theme.spacing(1.8),
          // width: 107,
          textAlign: "center"
        }
      },
      "& [class*=MuiFormLabel-root]": {
        marginBottom: 0,
        marginRight: 29
      }
    },
    "[class*=MuiMenuItem-root]": {
      color: "#707070",
      fontSize: theme.spacing(1.8)
    }
  },
  titleHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleImage: {
    cursor: 'pointer'
  },
  marginBottom: {
    marginBottom: 10,
  },
  criteriaPriority: {
    minHeight: '25px !important',
    padding: 6,
    border: '1px solid #c4c4c4',
    borderRadius: 4,
    color: '#5F5F5F',
    fontSize: '1.1em',
    marginLeft: 20,
    width: 75,
    textAlign: 'center'
  },
  priorityC: {
  },
  codeIcon: {
    cursor: 'pointer'
  }
});

class EvaluationCriteriaAddDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      activeMultiSelectId: false,
      name: "",
      name_error: "",
      is_mandatory: false,
      is_noneditable: false,
      criteria_schedule: null,
      priority: '',
      criteria_schedule_error: "",
      colloborators: [],
      activeAnswerType: 1,
      showProjectSchema: false,
      selectedVariableIndex: null,
      variableForCriteria: false,
      requirements: [{
        'name': '',
        'name_error': '',
        'priority': '',
        'scoring_type': 1,
        'description': '',
        'description_error': '',
        'colloborators': [],
        'answer_type': 1,
        'options': [],
        is_mandatory: false,
        is_editable: false,
        is_other_option: false,
      }]
    }

    this.addExtraRequirement = this.addExtraRequirement.bind(this);
    this.deleteRequirement = this.deleteRequirement.bind(this);
    this.addCriteriaColloborator = this.addCriteriaColloborator.bind(this);
    this.addRequirementColloborator = this.addRequirementColloborator.bind(this);
    this.submitResponse = this.submitResponse.bind(this);
    this.cleanError = this.cleanError.bind(this);
    this.cleanState = this.cleanState.bind(this);
    this.removeCriteriaCollaborator = this.removeCriteriaCollaborator.bind(this)
    this.removeRequirementColloborator = this.removeRequirementColloborator.bind(this)
  }

  cleanState() {
    this.setState({
      error: "",
      success: "",
      name: "",
      name_error: "",
      template_name_error: "",
      template_name: "",
      is_mandatory: false,
      is_noneditable: false,
      priority: '',
      criteria_schedule: null,
      criteria_schedule_error: "",
      colloborators: [],
      requirements: [{
        'name': '',
        'name_error': '',
        'priority': '',
        'scoring_type': 1,
        'answer_type': 1,
        'options': [],
        'description': '',
        'description_error': '',
        is_mandatory: false,
        is_editable: false,
        is_other_option: false,
        'colloborators': [],
      }]
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.saveProgress === false && prevProps.saveProgress === true) {
      if (this.props.saveError === null) {
        this.props.hideDialog();
        this.cleanState();
        this.props.showSnackBar("Template Saved", "success", 3000);
        this.props.fetchOrgTemplates(0, 60);
      } else {
        if (this.props.saveError.message !== undefined) {
          this.setState({
            "error": "Failed to save template."
          })
        } else {
          this.setState({
            "error": "Something went wrong."
          })
        }
      }
    }
  }

  cleanError() {
    var requirements = this.state.requirements;
    requirements.forEach((r) => {
      r.name_error = "";
      r.description_error = "";
    })
    this.setState({
      name_error: "",
      template_name_error: "",
      criteria_schedule_error: "",
      requirements: requirements
    })
  }

  submitResponse() {
    this.cleanError();
    var templateName = this.state.template_name || "";
    if (templateName.trim().length === 0) {
      this.setState({
        template_name_error: "Please enter Template Name."
      });
      return;
    }


    var name = this.state.name;
    if (name.trim().length === 0) {
      this.setState({
        name_error: "Please enter Section Name."
      });
      return;
    }

    var requirements = this.state.requirements;
    for (var i = 0; i < requirements.length; i++) {
      var requirement = requirements[0];
      var rName = requirement.name;
      if (rName.trim().length === 0) {
        requirement.name_error = "Please enter name."
        this.setState({
          requirements: requirements
        });
        return;
      }

      // var rDesc = requirement.description;
      // if (rDesc.trim().length === 0) {
      //   requirement.description_error = "Please enter question."
      //   this.setState({
      //     requirements: requirements
      //   });
      //   return;
      // }
    }

    const data = {
      name: templateName,
      maxScore: 5,
      criterias: [{
        name: name,
        is_editable: !this.state.is_noneditable,
        is_mandatory: this.state.is_mandatory,
        priority: parseInt(this.state.priority),
        colloborators: this.state.colloborators.map((c) => {
          return {
            userId: c.ID,
            priority: 50
          }
        }),
        requirements: this.state.requirements.map((r) => {
          return {
            name: r.name,
            description: r.description,
            priority: parseInt(r.priority),
            scoring_type: parseInt(r.scoring_type),
            answer_type: parseInt(r.answer_type),
            options: r.options,
            is_mandatory: r.is_mandatory,
            is_editable: r.is_editable,
            is_other_option: r.is_other_option,
            character_limit: parseInt(r.character_limit),
            colloborators: r.colloborators
              ? r.colloborators?.map((c) => {
                return c.ID
              })
              : [],
          }
        })
      }]
    }
    this.props.saveFromOrgEvaluationTemplate(data)
  }

  removeRequirementColloborator(index, userId) {
    const requirements = this.state.requirements;
    const requirement = requirements[index];
    let colloborators = requirement.colloborators;
    colloborators = colloborators.filter((u) => {
      if (u.ID === userId) {
        return false;
      }
      return true;
    })

    requirement.colloborators = colloborators
    requirements[index] = requirement
    this.setState({ requirements }, () => {
      this.props.showEvaluationCollaboratorDialog({
        ...this.props.collaboratorDialogData,
        existingUsers: colloborators,
        selectedUser: colloborators,
      })
    });
  }

  removeCriteriaCollaborator(userId) {
    var colloborators = this.state.colloborators;
    colloborators = colloborators.filter((u) => {
      if (u.ID === userId) {
        return false;
      }
      return true;
    })
    this.setState({ colloborators }, () => {
      this.props.showEvaluationCollaboratorDialog({
        ...this.props.collaboratorDialogData,
        existingUsers: colloborators,
        selectedUser: colloborators,
      })
    });
  }

  addCriteriaColloborator(user) {
    var colloborators = this.state.colloborators;
    var existing = colloborators.filter((u) => {
      if (u.ID === user.ID) {
        return true;
      }
      return false;
    })
    if (existing.length > 0) {
      return;
    }
    colloborators.push(user);
    this.setState({ colloborators: colloborators });
  }

  addRequirementColloborator(index, user) {
    const requirements = this.state.requirements;
    const requirement = requirements[index];
    const colloborators = requirement.colloborators;
    var existing = colloborators.filter((u) => {
      if (u.ID === user.ID) {
        return true;
      }
      return false;
    })
    if (existing.length > 0) {
      return;
    }
    colloborators.push(user);
    requirement.colloborators = colloborators
    requirements[index] = requirement
    this.setState({ requirements });
  }

  addExtraRequirement(e) {
    var requirements = this.state.requirements;
    requirements.push({
      'name': '',
      'name_error': '',
      'priority': '',
      'scoring_type': 1,
      'description': '',
      'description_error': '',
      'colloborators': [],
      is_mandatory: false,
      is_editable: false,
      is_other_option: false,
    });
    this.setState({
      requirements: requirements
    });
  }

  deleteRequirement(index) {
    if (this.state.requirements.length === 1) {
      return;
    }
    var requirements = this.state.requirements;
    requirements.splice(index, 1)
    this.setState({
      requirements: requirements
    });
  }

  handleOptions = (id, answerType) => {
    let activeMultiSelectId = id
    if ([2, 3, 4].includes(answerType)) {
      const element = document.getElementById(activeMultiSelectId)
      if (element && element.click()) {
        element.click()
      }
      if (activeMultiSelectId !== this.state.activeMultiSelectId) {
        this.setState({ activeMultiSelectId, activeAnswerType: answerType })
      }
    }
  }

  handleUpdateRequirement = (index, requirement, key, value, options = false, is_other_option = false, character_limit = 0) => {
    const newRequirement = requirement
    newRequirement[key] = value
    if (options) {
      newRequirement.options = options
      newRequirement.is_other_option = is_other_option
    }
    if (key === 'answer_type' && value === 7) {
      newRequirement.character_limit = character_limit
    }
    const requirements = this.state.requirements
    requirements[index] = newRequirement
    if (key !== 'answer_type') this.handleOptions(`evalution_criteria_requiment-${index}`, requirement.answer_type)
    this.setState({ requirements })
  }

  addTagValue(value, index) {
    if (this.state.variableForCriteria) {
      let newName = this.state.name + ' {{ ' + value + ' }} ';
      this.setState({
        name: newName
      })
    } else {
      let requirements = this.state.requirements
      let req = requirements[index];
      req.name = req.name + ' {{ ' + value + ' }} ';
      this.setState({ requirements })
    }
  }

  render() {
    const classes = this.props.classes;
    return <Dialog
      onClose={this.props.hideDialog}
      aria-labelledby="app-integrationDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"md"}
      scroll="body"
      id="evMetaDialog"
    >
      <DialogContent classes={{ root: classes.dialogContent }} >
        <Typography variant={"h2"} className={classes.modalTitle}>Add New Template</Typography>
        <div className={classes.alerts}>
          {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
          {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
        </div>
        <div className={classes.criteriaDetail}>
          <div className={classnames(classes.marginBottom, classes.nameOfCriteria, classes.nameYour)}>
            <OutlinedInput
              label="Name your Template"
              error={!!this.state.template_name_error}
              helperText={this.state.template_name_error}
              value={this.state.template_name}
              onChange={(event) => {
                this.setState({ template_name: event.target.value });
              }}
            />
          </div>
        </div>
      </DialogContent>
      <Divider />
      <DialogContent classes={{ root: classes.dialogContent }} >
        <Typography variant={"h2"} className={classes.modalTitle}>Add new Section</Typography>
        <div className={classes.criteriaDetail}>
          <div className={classnames(classes.marginBottom, classes.nameOfCriteria, classes.nameYour)}>
            <Typography style={{ display: 'flex' }}>Name your Section &nbsp;&nbsp;
              <CodeIcon
                onClick={() => {
                  this.setState({
                    showProjectSchema: true,
                    selectedVariableIndex: null,
                    variableForCriteria: true
                  })
                }}
              />
            </Typography>
            <OutlinedInput
              error={!!this.state.name_error}
              helperText={this.state.name_error}
              value={this.state.name}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
            />
          </div>
          <Grid container justifyContent="space-between">
            <Grid item sm={6}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.is_mandatory}
                        onChange={(event) => {
                          this.setState({ is_mandatory: event.target.checked })

                        }}
                        name="mandatory"
                      />
                    }
                    label="Required"
                  />
                </div>
                <div>
                  {!this.state.is_mandatory && <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.is_noneditable}
                        onChange={(event) => {
                          this.setState({ is_noneditable: event.target.checked })

                        }}
                        name="editable"
                      />
                    }
                    label="Editable"
                  />}
                </div>
              </div>
            </Grid>
            <Grid item sm={6}>
              <div className={classes.priorityC} style={{ display: 'flex', alignItems: 'center', padding: 5 }}>
                <div>
                  <span className={classes.label}>Weightage</span>
                  <input
                    value={this.state.priority}
                    className={classes.criteriaPriority}
                    onChange={(event) => {
                      var priority = parseInt(event.target.value) || '';
                      if (priority < 0 || priority > 100) {
                        alert('Please enter weightage between 1 to 100.')
                        return;
                      } else {
                        this.setState({ priority: priority });
                      }
                    }}
                  ></input>&nbsp;%
                </div>
              </div>
            </Grid>
          </Grid>
          <div className={classes.UserGroupSection}>
            <UserGroup
              position={'CriteriaHead'}
              isNew={true}
              onAdd={(user) => {
                this.addCriteriaColloborator(user)
              }}
              removeCollaborator={this.removeCriteriaCollaborator}
              criterias={[]}
              users={this.state.colloborators}
            />
          </div>
        </div>
      </DialogContent>
      <Divider />
      {
        this.state.requirements.map((requirement, k) => {
          return <React.Fragment key={k}>
            <DialogContent classes={{ root: classes.dialogContent }} >
              <div className={classes.titleHead}>
                <span></span>
                <Typography variant={"h2"} className={classes.modalTitle}>Add new Question</Typography>
                <div>
                  {k !== 0 && <div onClick={() => this.deleteRequirement(k)}>
                    <Image
                      src={deleteIcon}
                      alt="forgotpassword_image"
                      className={classes.titleImage}
                    /></div>}
                </div>
              </div>
              <div>
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <div className={classes.nameYour}>
                      <Typography style={{ display: 'flex' }}>Name Your Question &nbsp;&nbsp;
                        <CodeIcon
                          onClick={() => {
                            this.setState({
                              showProjectSchema: true,
                              selectedVariableIndex: k,
                              variableForCriteria: false
                            })
                          }}
                        />
                      </Typography>
                      <OutlinedInput
                        error={!!requirement.name_error}
                        helperText={requirement.name_error}
                        value={requirement.name}
                        onChange={(event) => {
                          this.handleUpdateRequirement(k, requirement, 'name', event.target.value)
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <div className={classes.yourQuestion}>
                      <SlateInputField
                        label="Explain Your Question"
                        error={!!requirement.description_error}
                        helperText={requirement.description_error}
                        folderPath={`/evaluation/${this.props?.evaluationId}/newcriteria`}
                        as={SlateInputField}
                        onChangeEvent={(value) => {
                          this.handleUpdateRequirement(k, requirement, 'description', value)
                        }}
                        initValue={this.state.description}
                        isShowAddVariableOption={true}
                        workflowId={0}
                        textContainerStyle={{ color: "#707070", }} />
                    </div>

                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between" spacing={3}>
                  <Grid item xs={6}>
                    {requirement && (
                      <EvaluateMultiChoiceAnswer
                        editable={true}
                        isCriteriaRequirement
                        requirement={{
                          answer_type: requirement.answer_type,
                          options: requirement.options
                        }}
                        saveBtnId={`evalution_criteria_requiment-${k}`}
                        isNew={true}
                        onChange={(answerType, options, is_other_option, character_limit) => {
                          // console.log(character_limit, 'character_limit')
                          this.handleUpdateRequirement(k, requirement, 'answer_type', answerType, options, is_other_option, character_limit)
                        }}
                        fromAddTemplate
                      />
                    )}
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={4}>
                    <UserGroup
                      position={'Requirement'}
                      isNew={true}
                      onAdd={(user) => {
                        this.addRequirementColloborator(k, user)
                      }}
                      users={requirement.colloborators}
                      removeCollaborator={id => this.removeRequirementColloborator(k, id)}
                    />
                    <br />
                    {false && <div className={classnames(classes.priority, requirement.priority && requirement.priority === '1' ? 'low' : requirement.priority && requirement.priority === '2' ? 'medium' : requirement.priority && requirement.priority === '3' ? 'high' : '')}>
                      <OutlinedSelectInput
                        label="Priority"
                        labelStyle={"inline"}
                        id="priority"
                        value={requirement.priority}
                        options={[
                          {
                            value: "1",
                            label: "Low"
                          },
                          {
                            value: "2",
                            label: "Medium"
                          },
                          {
                            value: "3",
                            label: "High"
                          }

                        ]}
                        onChange={(event) => {
                          this.handleUpdateRequirement(k, requirement, 'priority', event.target.value)
                        }}
                      />
                    </div>}


                    <div className={classes.priorityC}>
                      <span className={classes.label}>Weightage</span>
                      <input
                        value={requirement.priority}
                        className={classes.criteriaPriority}
                        onChange={(event) => {
                          var priority = parseInt(event.target.value) || '';
                          if (priority < 0 || priority > 100) {
                            alert('Please enter weightage between 1 to 100.')
                            return;
                          } else {
                            this.handleUpdateRequirement(k, requirement, 'priority', event.target.value)
                          }
                        }}
                      ></input>&nbsp;%
                    </div>

                    <div className={classes.rightAction} style={{ marginTop: 10 }}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <label className={classes.label} style={{ marginRight: 8 }}>
                            Required
                          </label>
                          <br />
                          <br />
                          <label className={classes.label} style={{ marginRight: 8 }}>
                            Editable
                          </label>

                        </Grid>
                        <Grid item>
                          <Switch
                            checked={requirement.is_mandatory}
                            onChange={event => {
                              event.stopPropagation()
                              this.handleUpdateRequirement(k, requirement, 'is_mandatory', event.target.checked)
                            }}
                            name="is_mandatory"
                            color="primary"
                            inputProps={{ 'aria-label': 'is_mandatory checkbox' }}
                          />
                          <br />
                          <Switch
                            checked={requirement.is_editable}
                            onChange={event => {
                              event.stopPropagation()
                              this.handleUpdateRequirement(k, requirement, 'is_editable', event.target.checked)
                            }}
                            color="primary"
                            name="is_editable"
                            inputProps={{ 'aria-label': 'is_editable checkbox' }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <Divider />
          </React.Fragment>
        })

      }
      <DialogContent classes={{ root: classes.dialogAction }} >
        <Button
          className={classes.addNewButton}
          onClick={(e) => {
            e.stopPropagation()
            this.handleOptions(this.state.activeMultiSelectId, this.state.activeAnswerType)
            _.debounce(() => {
              this.addExtraRequirement()
            }, 1000)();
          }}
        >
          + Add new Question
        </Button>
        <div className={classes.actions}>
          {this.props.saveProgress && <CircularProgress />}
          {!this.props.saveProgress && (
            <>

              <SqureButton
                cls={classes.actionBtn}
                variant={"outlined"}
                onClick={() => {
                  this.cleanError()
                  this.props.hideDialog()
                }}
              >
                Cancel
              </SqureButton>&nbsp;&nbsp;&nbsp;
              <SqureButton
                cls={classes.actionBtn}
                variant={"contained"}
                onClick={(e) => {
                  e.stopPropagation()
                  this.handleOptions(this.state.activeMultiSelectId, this.state.activeAnswerType)
                  _.debounce(() => {
                    this.submitResponse()
                  }, 1000)();
                }}
              >
                Save
              </SqureButton>
            </>
          )}
        </div>
        {this.state.showProjectSchema && <SchemaTagSelectionDialog
          isOpen={this.state.showProjectSchema}
          loadEverytime={false}
          workflowId={0}
          hideDialog={() => {
            this.setState({
              showProjectSchema: false,
              selectedVariableIndex: null
            })
          }}
          addTagValue={(value) => {
            this.addTagValue(value, this.state.selectedVariableIndex)
            this.setState({
              showProjectSchema: false,
              selectedVariableIndex: null
            })
          }}
        />
        }
      </DialogContent>
    </Dialog >
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(EvaluationCriteriaAddDialog));