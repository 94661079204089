import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { fetchSellerLibrary } from "redux/seller/evaluate/action"
import { fetchSellerProducts } from "redux/seller/product/action";
import Home from "./Home";

const connectedProps = (state) => ({
  fetchSellerLibraryProgress: state.sellerEvaluate.fetchSellerLibraryProgress,
  fetchSellerLibraryError: state.sellerEvaluate.fetchSellerLibraryError,
  sellerLibrary: state.sellerEvaluate.sellerLibrary,
});

const connectionActions = {
  fetchSellerLibrary: fetchSellerLibrary,
  fetchSellerProducts: fetchSellerProducts,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class Library extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchSellerProducts([1, 2], {
      query: '',
      page: 0,
      pageSize: 200,
    });
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return (
      <Switch>
        <Route exact path={this.props.match.path} component={Home} />
        {/* <Route exact path={`${this.props.match.path}/:template_id`} component={Template} /> */}
      </Switch>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Library));
