import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { Dialog, DialogContent, FormControl, Slide, InputAdornment, DialogTitle, Typography, IconButton, DialogActions, Button, CircularProgress } from "@material-ui/core";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
import ReactSelect from "react-select";
import classnames from "classnames";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import Alert from "@material-ui/lab/Alert";
import deleteIcon from "assets/images/delete.svg";
import Image from "Components/Common/image.jsx";
import DatePicker from "react-datepicker";
import moment from "moment";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import CalendarImage from "assets/images/calendar.svg";
// dialogs
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  ruleSaveProgress: state.evaluationRule.ruleSaveProgress,
  errorRuleSave: state.evaluationRule.errorRuleSave,
  templateRuleSaveProgress: state.orgTemplateEvaluation.ruleSaveProgress,
  templateErrorRuleSave: state.orgTemplateEvaluation.errorRuleSave,
});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(2, 2) + " !important",
      //minHeight:"300px"
      "& .react-datepicker-popper": {
        zIndex: 2222,
      },
      "& .react-datepicker__input-container > div": {
        display: "flex",
        marginBottom: 0,
        alignItems: "baseline",
        "& [class*=MuiInputBase-root]": {
          maxHeight: 38,
          minHeight: 38,
          "& input": {
            fontSize: theme.spacing(1.8),
            // width: 107,
            textAlign: "center",
          },
        },
        "& [class*=MuiFormLabel-root]": {
          marginBottom: 0,
          marginRight: 29,
        },
      },
      "[class*=MuiMenuItem-root]": {
        color: "#707070",
        fontSize: theme.spacing(1.8),
      },
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    ruleTable: {
      width: "100%",
      border: "1px solid #ddd",
      padding: 10,
      borderRadius: 5,
      marginBottom: 10,
    },
    ruleTableDisabled: {
      width: "100%",
      border: "1px solid #ddd",
      padding: 10,
      borderRadius: 5,
      marginBottom: 10,
      pointerEvents: "none",
    },
    deleteIcon: {
      color: "red",
    },
    op2: {
      width: "15%",
    },
    op1: {
      width: "15%",
    },
    leftOp: {
      width: "30%",
    },
    rightOp: {
      width: "30%",
      "& [class*=select__menu]": {
        maxHeight: 100,
      },
      "& [class*=react-datepicker-wrapper]": {
        width: "100%",
      },
    },
    textField: {
      "& [class*=Outlined-inputRoot]": {
        height: 38,
        minHeight: 38,
      },
      "& [class*=MuiOutlinedInput-adornedEnd]": {
        paddingRight: 25,
      },
      "& [class*=Outlined-outlinedInput]": {
        padding: "18.5px 14px",
        fontSize: "inherit",
      },
      "& [class*=Outlined-input]": {
        marginBottom: 0,
      },
    },
    action: {
      width: "10%",
      paddingLeft: 10,
    },
    alerts: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    conditions: {
      fontSize: 16,
      fontWeight: 500,
    },
    ruleTitle: {
      fontSize: 18,
      fontWeight: 600,
      marginTop: 0,
    },
    addAnotherRule: {
      textAlign: "center",
      color: "#4175DF",
      cursor: "pointer",
      paddingBottom: 50,
    },
    ruleSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    ruleDelete: {
      marginBottom: 16,
      cursor: "pointer",
    },
    saveBtn: {
      margin: "10px 15px",
    },
    disableRules: {
      pointerEvents: "none",
    },
    textFields: {
      width: "100%",
    },
    "@global": {
      ".react-datepicker__input-container > div": {
        margin: 0,
      },
      ".react-datepicker__input-container [class*=MuiInputBase-formControl]": {
        minHeight: 39,
        margin: 0,
      },
      ".react-datepicker__input-container [class*=MuiOutlinedInput-input]": {
        padding: 0,
        fontSize: 14,
        paddingLeft: 10,
        color: "rgb(51, 51, 51)",
      },
    },
    dialog: {
      '& [class*="MuiDialog-paper"]': {
        overflowY: "unset",
      },
    },
  });

class AddFiltersDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      typeOptions: [
        { value: "ABSOLUTE", label: "Absolute" },
        { value: "RELATIVE_FIELD", label: `Relative` },
        { value: "RELATIVE_DATE", label: "Date" },
      ],
      operand: [
        {
          value: 1,
          label: "==",
        },
        {
          value: 2,
          label: "!=",
        },
        // {
        //   value: 3,
        //   label: "in",
        // },
        // {
        //   value: 4,
        //   label: "not in",
        // },
        {
          value: 5,
          label: ">",
        },
        {
          value: 6,
          label: "<",
        },
        {
          value: 7,
          label: ">=",
        },
        {
          value: 8,
          label: "<=",
        },
      ],
      rules: [],
    };
  }

  componentDidMount() {
    var getRules = [];
    if (this.props.filterValueData && this.props.filterValueData?.filters?.length) {
      this.props.filterValueData.filters.forEach((value) => {
        let rightOp = value.right;
        if (value.type === "RELATIVE_DATE" && rightOp) {
          rightOp = Number(rightOp?.replace("h", "")) / 24;
        }
        getRules.push({
          leftOp: this.props.axisDataList.find((item) => item.value === value.left),
          op1: value.operation,
          type: value.type || "ABSOLUTE",
          rightOp: rightOp,
        });
      });
      this.setState({
        error: "",
        success: "",
        rules: getRules,
      });
    } else {
      this.reset();
    }
  }

  reset = () => {
    this.setState({
      error: "",
      success: "",
      answerType: null,
      rules: [
        // {
        //   leftOp: null,
        //   op1: 1,
        //   rightOp: [],
        // },
      ],
    });
  };

  addNewRuleElement = () => {
    var rules = this.state.rules;
    rules.push({
      leftOp: null,
      op1: 1,
      type: "ABSOLUTE",
      rightOp: null,
    });
    this.setState({
      rules: rules,
    });
  };

  deleteRuleElement = (ruleIndex) => {
    var rules = this.state.rules;
    rules.splice(ruleIndex, 1);
    this.setState({
      rules: rules,
    });
  };

  saveRule = () => {
    this.setState({
      error: "",
      success: "",
    });
    console.log(this.state.rules, "rulescc");
    var errorMessage = "";
    var filterData = [];
    this.state.rules.forEach((rule, i) => {
      if (!rule.leftOp || !rule.leftOp.value) {
        errorMessage = `Error Row ${i + 1} please select filter.`;
        return;
      }

      if (!rule.rightOp || rule.rightOp?.length === 0) {
        errorMessage = `Error Row ${i + 1} please enter value.`;
        return;
      }
      let rightOp = rule.rightOp;
      if (rule.type === "RELATIVE_DATE" && rightOp) {
        rightOp = Number(rightOp) * 24 + "h";
      }
      filterData.push({
        left: rule.leftOp.value,
        operation: rule.op1,
        type: rule.type,
        right: rightOp,
      });
    });
    if (!errorMessage) {
      this.props.onSave(filterData);
    } else {
      this.setState({ error: errorMessage });
    }
  };

  render() {
    const classes = this.props.classes;
    return (
      <Dialog
        onClose={this.props.onClose}
        aria-labelledby="app-evRuleDialog"
        open={this.props.isOpen}
        TransitionComponent={Transition}
        disableBackdropClick={false}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        id="evMetaDialogApprovalCondition"
        className={classes.dialog}
      >
        <DialogTitle disableTypography>
          <Typography variant={"h6"}>Filters for {this.props.filterValueData.label}</Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true} classes={{ root: classes.dialogContent }}>
          <span hidden={this.state.rules.length > 0}>
            <IconButton size="small" onClick={() => this.addNewRuleElement()}>
              <AddCircleIcon />
            </IconButton>
          </span>
          {this.state.rules.map((rule, ruleIndex) => {
            return (
              <table className={classes.ruleTable} key={ruleIndex}>
                <tr>
                  <td className={classes.leftOp}>
                    <ReactSelect
                      isClearable
                      menuPosition="fixed"
                      isSearchable={true}
                      options={this.props.axisDataList}
                      classNamePrefix="select"
                      placeholder="Apply filter on"
                      onChange={(e) => {
                        var rules = this.state.rules;
                        var ruleElement = rules[ruleIndex];
                        ruleElement.leftOp = e;
                        ruleElement.rightOp = "";
                        ruleElement.type = "ABSOLUTE";
                        rules[ruleIndex] = ruleElement;
                        this.setState({ rules: rules });
                      }}
                      menuPortalTarget={document.getElementById("evAddFiltersDialog")}
                      value={rule.leftOp}
                    />
                  </td>
                  <td className={classes.op1}>
                    <OutlinedSelectInput
                      value={rule.op1}
                      options={this.state.operand}
                      noMargin={true}
                      compact={true}
                      onChange={(event) => {
                        var rules = this.state.rules;
                        var ruleElement = rules[ruleIndex];
                        ruleElement.op1 = event.target.value;
                        rules[ruleIndex] = ruleElement;
                        this.setState({ rules: rules });
                      }}
                    />
                  </td>
                  <td className={classes.op1}>
                    <OutlinedSelectInput
                      value={rule.type}
                      options={this.state.typeOptions.filter(O => {
                        if (O.value !== "RELATIVE_DATE") {
                          return true;
                        } else {
                          return rule.leftOp?.type === "DATE";
                        }
                      })}
                      noMargin={true}
                      compact={true}
                      onChange={(event) => {
                        var rules = this.state.rules;
                        var ruleElement = rules[ruleIndex];
                        ruleElement.type = event.target.value;
                        rules[ruleIndex] = ruleElement;
                        this.setState({ rules: rules });
                      }}
                    />
                  </td>
                  {rule.type === "ABSOLUTE" && (
                    <td className={classes.rightOp}>
                      {rule.leftOp?.type === "DATE" ? (
                        <div>
                          <DatePicker
                            value={rule.rightOp && moment(rule.rightOp).format("DD/MM/YYYY")}
                            selected={""}
                            onChange={(date) => {
                              var rules = this.state.rules;
                              var ruleElement = rules[ruleIndex];
                              ruleElement.rightOp = moment(date)?.format("YYYY-MM-DD");
                              rules[ruleIndex] = ruleElement;
                              this.setState({ rules: rules });
                            }}
                            placeholder="Choose Date"
                            dateFormat="MMM do yyyy"
                            minDate={""}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={
                              <OutlinedInput
                                readOnlyInput={true}
                                startAdornment={
                                  <InputAdornment>
                                    <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                  </InputAdornment>
                                }
                              />
                            }
                          />
                        </div>
                      ) : (
                        <TextField
                          variant="outlined"
                          onChange={(e) => {
                            var rules = [...this.state.rules];
                            var ruleElement = rules[ruleIndex];
                            ruleElement.rightOp = e.target.value;
                            rules[ruleIndex] = ruleElement;
                            this.setState({ rules: rules });
                          }}
                          value={rule.rightOp}
                          placeholder="Enter value"
                          size="small"
                          className={classes.textFields}
                          type={["NUMBER", "CURRENCY"].includes(rule.leftOp?.type) && "number"}
                        />
                      )}
                    </td>
                  )}
                  {rule.type === "RELATIVE_FIELD" && (
                    <td className={classes.rightOp}>
                      <ReactSelect
                        isClearable
                        menuPosition="fixed"
                        isSearchable={true}
                        options={this.props.axisDataList.filter((O) => O.type === rule.leftOp?.type)}
                        classNamePrefix="select"
                        placeholder="Choose value"
                        onChange={(e) => {
                          var rules = this.state.rules;
                          var ruleElement = rules[ruleIndex];
                          ruleElement.rightOp = e.value;
                          rules[ruleIndex] = ruleElement;
                          this.setState({ rules: rules });
                        }}
                        menuPortalTarget={document.getElementById("evAddFiltersDialog")}
                        value={this.props.axisDataList.find((O) => O.value === rule.rightOp)}
                      />
                    </td>
                  )}
                  {rule.type === "RELATIVE_DATE" && (
                    <td className={classes.rightOp}>
                      <FormControl className={classnames(classes.margin, classes.withoutLabel, classes.textField)}>
                        <OutlinedInput
                          id="standard-adornment-weight"
                          value={rule.rightOp}
                          onChange={(e) => {
                            var rules = [...this.state.rules];
                            var ruleElement = rules[ruleIndex];
                            ruleElement.rightOp = e.target.value;
                            rules[ruleIndex] = ruleElement;
                            this.setState({ rules: rules });
                          }}
                          endAdornment={<InputAdornment position="end">Days</InputAdornment>}
                          aria-describedby="standard-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                          type="number"
                          size="small"
                        />
                      </FormControl>
                    </td>
                  )}
                  <td className={classes.action}>
                    <span>
                      <IconButton
                        size="small"
                        onClick={() => {
                          this.deleteRuleElement(ruleIndex);
                        }}
                      >
                        <Image alt="Delete" src={deleteIcon} />
                      </IconButton>
                    </span>
                    <span hidden={ruleIndex !== this.state.rules.length - 1}>
                      <IconButton size="small" onClick={() => this.addNewRuleElement()}>
                        <AddCircleIcon />
                      </IconButton>
                    </span>
                  </td>
                </tr>
              </table>
            );
          })}
          <div className={classes.alerts}>
            {this.state.error.length > 0 && (
              <Alert variant="filled" severity="error">
                {this.state.error}
              </Alert>
            )}
            {this.state.success.length > 0 && (
              <Alert variant="filled" severity="success">
                {this.state.success}
              </Alert>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {!this.props.ruleSaveProgress && (
            <>
              <Button variant="contained" color="secondary" className={classes.saveBtn} onClick={this.saveRule}>
                Save
              </Button>
              <Button variant="outlined" color="secondary" className={classes.saveBtn} onClick={this.props.onClose}>
                Cancel
              </Button>
            </>
          )}
          {this.props.ruleSaveProgress && <CircularProgress />}
        </DialogActions>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(AddFiltersDialog));
