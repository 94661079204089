import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { setSortFilterOptions } from "redux/product/action";
import { FormControl, Select, MenuItem, Typography } from "@material-ui/core"
import SqureButton from 'Components/Common/SqureButton';

const connectedProps = (state) => ({
  productSortType: state.products.productSortType,
  productSortField: state.products.productSortField,
});

const connectionActions = {
  setSortFilterOptions: setSortFilterOptions
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
},
reqTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color : '#282D30',
    fontSize : theme.spacing(2.2)
},
actionButtons: {
  textAlign: 'right',
  marginTop: theme.spacing(2)
}
});

class OrgProductsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClose = this.handleClose.bind(this);
    this.handleSortSubmit = this.handleSortSubmit.bind(this);
  }

  handleClose() {
    this.props.handleClose();
  };

  handleSortSubmit() {
    this.props.handleSortSubmit();
  }

  setSortOptions(value,type){
    this.props.setOptions();
    this.props.setSortFilterOptions(value, type)
  }

  render() {
    var classes = this.props.classes;

    var orderDropDownMap = {};
    if (this.props.sortFilterOptions !== undefined) {
        this.props.sortFilterOptions.map((dta) => {
            return orderDropDownMap[dta.key] = dta.orders;
        })
    }

    return (
      <div>
        <div className={classes.sortByDiv}>
          <Typography variant={"h6"} className={classes.reqTitle}>
            Sort by:
          </Typography>
          <FormControl variant="outlined" className={classes.formControl} size="small">
            <Select value={this.props.productSortField} onChange={(e) => {
              this.setSortOptions(e.target?.value, this.props.productSortType);
            }}>
              {this.props.sortFilterOptions.map((dta, index) => {
                return <MenuItem key={index} value={dta.key} >{dta.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        <div className={classes.sortByDiv}>
          <Typography variant={"h6"} className={classes.reqTitle}>
            Order:
          </Typography>
          <FormControl variant="outlined" className={classes.formControl} size="small">
            <Select value={this.props.productSortType} onChange={(e) => {
              this.setSortOptions(this.props.productSortField, e.target?.value);
            }}>
              {orderDropDownMap[this.props.productSortField] !== undefined && orderDropDownMap[this.props.productSortField].map((dta, index) => {
                return <MenuItem key={index} value={dta.key} >{dta.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        <div className={classes.actionButtons} >
          <SqureButton style={{ marginRight: 10 }} variant={"outlined"} onClick={() => this.handleClose()}>Cancel</SqureButton>
          <SqureButton variant={"contained"} onClick={() => this.handleSortSubmit()}>Submit</SqureButton>
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(OrgProductsFilter));
