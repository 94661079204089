import {EvaluationFactory} from "../../../services/evaluation";
import { isArray } from "underscore";
import { HttpFactory } from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const START_EVALUATION_FETCH_FOR_SUMMARY="START_EVALUATION_FETCH_FOR_SUMMARY";
export const END_EVALUATION_FETCH_FOR_SUMMARY="END_EVALUATION_FETCH_FOR_SUMMARY";

export const START_EVALUATION_SCORE_FETCH         =   "START_EVALUATION_SCORE_FETCH";
export const END_EVALUATION_SCORE_FETCH         =   "END_EVALUATION_SCORE_FETCH";

export const START_EVALUATION_CRITERIA_FETCH         =   "START_EVALUATION_CRITERIA_FETCH";
export const END_EVALUATION_CRITERIA_FETCH         =   "END_EVALUATION_CRITERIA_FETCH";

export const START_EVALUATION_FORM_DECISION_ITEMS = "START_EVALUATION_FORM_DECISION_ITEMS";
export const END_EVALUATION_FORM_DECISION_ITEMS = "END_EVALUATION_FORM_DECISION_ITEMS";

export const SHOW_FORM_DATA_COPY_DIALOG = "SHOW_FORM_DATA_COPY_DIALOG";
export const HIDE_FORM_DATA_COPY_DIALOG = "HIDE_FORM_DATA_COPY_DIALOG";

export const SHOW_EVALUATION_DATA_COPY_DIALOG = "SHOW_EVALUATION_DATA_COPY_DIALOG";
export const HIDE_EVALUATION_DATA_COPY_DIALOG = "HIDE_EVALUATION_DATA_COPY_DIALOG";

export const START_FETCH_WORKFLOW_COMPONENT_DETAILS_FOR_SUMMARY = "START_FETCH_WORKFLOW_COMPONENT_DETAILS_FOR_SUMMARY";
export const END_FETCH_WORKFLOW_COMPONENT_DETAILS_FOR_SUMMARY = "END_FETCH_WORKFLOW_COMPONENT_DETAILS_FOR_SUMMARY";

function startEvaluationFetchForSummary(){
    return {
        type:START_EVALUATION_FETCH_FOR_SUMMARY,
        payload:{}
    };
}

function endEvaluationFetchForSummary(success,error){
    return {
        type:END_EVALUATION_FETCH_FOR_SUMMARY,
        payload:{
            success,error
        }
    };
}

export  function fetchEvaluationMetaDataForSummary(evId){
    return async (dispatch) => {
        dispatch(startEvaluationFetchForSummary());
        try{
            let [response,error]    =   await EvaluationFactory.instance().getEvaluation(evId);
            dispatch(endEvaluationFetchForSummary(response,error));
        } catch(e){
            dispatch(endEvaluationFetchForSummary(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startEvaluationScoreFetch(){
    return {
        type:START_EVALUATION_SCORE_FETCH,
        payload:{}
    };
}

function endEvaluationScoreFetch(success,error){
    return {
        type:END_EVALUATION_SCORE_FETCH,
        payload:{
            success,error
        }
    };
}


//Thunk to perform the authorizedUser
export  function fetchScores(evId,userIds){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    var userQuery = ""
    if(userIds !== undefined && userIds !== null && isArray(userIds) && userIds.length > 0 ){
        userQuery = "&userIds="+userIds.join(",")
    }
    const url   =   `${api_server}/${evId}/score?t=${timestamp}${userQuery}`;
    return async (dispatch) => {
        dispatch(startEvaluationScoreFetch());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endEvaluationScoreFetch(response,error));
        } catch(e){
            dispatch(endEvaluationScoreFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startEvaluationCriteriaFetch(){
    return {
        type:START_EVALUATION_CRITERIA_FETCH,
        payload:{}
    };
}

function endEvaluationCriteriaFetch(success,error){
    return {
        type:END_EVALUATION_CRITERIA_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchCriteria(evId,forAdmin=false,componentId=0){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/criteria?forAdmin=${forAdmin}&componentId=${componentId}&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startEvaluationCriteriaFetch());
        try{
            let [response,error]    =   await await HttpFactory.instance().getMethod(url);
            dispatch(endEvaluationCriteriaFetch(response,error));
        } catch(e){
            dispatch(endEvaluationCriteriaFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startFetchFormDecisionItems(){
    return {
        type:START_EVALUATION_FORM_DECISION_ITEMS,
        payload:{}
    };
}

function endFetchFormDecisionItems(success,error){
    return {
        type:END_EVALUATION_FORM_DECISION_ITEMS,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchFormDecisionItems(evId,formId){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evId}/form/${formId}/decission-items?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchFormDecisionItems());
        try{
            let [response,error]    =   await await HttpFactory.instance().getMethod(url);
            dispatch(endFetchFormDecisionItems(response,error));
        } catch(e){
            dispatch(endFetchFormDecisionItems(null,{
                message:e.message
            }));
            return;
        }
    };
} 



export function showFormDataCopyDialog() {
    return {
        type: SHOW_FORM_DATA_COPY_DIALOG,
        payload: {}
    };
}

export function hideFormDataCopyDialog() {
    return {
        type: HIDE_FORM_DATA_COPY_DIALOG,
        payload: {}
    };
}


export function showEvaluationDataCopyDialog() {
    return {
        type: SHOW_EVALUATION_DATA_COPY_DIALOG,
        payload: {}
    };
}

export function hideEvaluationDataCopyDialog() {
    return {
        type: HIDE_EVALUATION_DATA_COPY_DIALOG,
        payload: {}
    };
}

function startFetchWorkflowComponentDetails() {
    return {
        type: START_FETCH_WORKFLOW_COMPONENT_DETAILS_FOR_SUMMARY,
        payload: {}
    };
}

function endFetchWorkflowComponentDetails(success, error) {
    return {
        type: END_FETCH_WORKFLOW_COMPONENT_DETAILS_FOR_SUMMARY,
        payload: {
            success, error
        }
    };
}

export function fetchWorkflowComponentDetails(workflowIdId, componentId, evaluationId = 0) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org-workflow/${workflowIdId}/component/${componentId}?evaluation_id=${evaluationId}&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchWorkflowComponentDetails());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchWorkflowComponentDetails(response, error));
        } catch (e) {
            dispatch(endFetchWorkflowComponentDetails(null, {
                message: e.message
            }));
            return;
        }
    };
}