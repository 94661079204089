import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { CircularProgress, Typography } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const connectedProps = (state) => ({
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  schemaRoot: {
    marginTop: 20,
    maxHeight: 500,
    overflow: 'auto'
  },
  tagName: {
    cursor: 'pointer',
    border: '1px solid #c2c2c2',
    borderRadius: 4,
    background: '#fff',
    margin: 0,
    marginBottom: 5,
    padding: 5
  },
  tagNameDisabled:{
    cursor: 'not-allowed',
    border: '1px solid #c2c2c2',
    borderRadius: 4,
    background: '#fff',
    margin: 0,
    marginBottom: 5,
    padding: 5
  },
  tagValue:{
    cursor: 'pointer',
    color:'#df8888'
  },
  tagValueDisabled:{
    color:'#df8888'
  },
  childTagName: {
    cursor: 'pointer',
    border: '1px solid #c2c2c2',
    borderRadius: 4,
    background: '#fff',
    margin: 0,
    marginBottom: 5,
    marginLeft: 15,
    padding: 5
  },
  loader: {
    textAlign: 'center',
    marginTop: 10
  },
  subChild: {
    paddingLeft: '35px'
  },
  schemaNodesRoot:{
  },
  
});

class SchemaNodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  // handleClick(tag) {
  //   let additionalData = {}
  //   this.props.handleClick(tag, additionalData)
  // }

  openCloseTag(index) {
    let tags = this.state.tags;
    let tag = tags[index];
    tag.isOpen = tag.isOpen ? false : true;
    this.setState({ tags })
  }

  render() {
    const { classes, tag, key,isDisabled,isFromSlate, ...rest } = this.props;
    var childList = [];
    if ((this.props.tag !== null && this.props.tag?.childrens !== undefined && this.props.tag?.childrens !== null && this.props.tag?.childrens.length > 0)) {
      let me = this;
      this.props.tag.childrens.forEach(function (item, index) {
        let isDisabled = false;
        if(isFromSlate && !item.isSlate){
          isDisabled=true
        }
        childList.push(<SchemaNodes
          {...rest}
          key={item.label}
          tag={item}
          classes={classes}
          handleClick={(selectedItem, additionalData) => {
            // console.log(additionalData?.name, 'additionalData?.name')
            let name = item.label
            if (additionalData?.name !== '') {
              name = item.label + '->' + additionalData?.name
            }
            me.props.handleClick(selectedItem, {
              name: name  
            })
          }}
          isFromSlate={isFromSlate}
          isDisabled={isDisabled}
        ></SchemaNodes>);
      });
    }
    // console.log(tag, 'Tag111')
    // console.log(childList, 'childList')

    let nodeTagName = ' {{ '+tag.value +' }}';
    
    return (
      <div className={classes?.schemaNodesRoot} key={key}>
        {childList.length <= 0 && <p className={classes?.tagName} onClick={() => {
        // {childList.length <= 0 && <p className={isDisabled ? classes?.tagNameDisabled : classes?.tagName} onClick={() => {
          // console.log(tag, 'SelectedTag')
          // if(isDisabled){
          //   return
          // }
          this.props.handleClick(tag, {
            name: ''
          })
        }}>{tag?.label} <span className={isDisabled ? classes?.tagValue : classes?.tagValue}> {nodeTagName}</span></p>}

        {childList.length > 0 && <Typography style={{ display: 'flex' }}>
          {!this.state.isOpen && <ExpandMoreIcon onClick={() => {
            this.setState({
              isOpen: true
            })
          }} />}
          {this.state.isOpen && <ExpandLessIcon onClick={() => {
            this.setState({
              isOpen: false
            })
          }} />}
          &nbsp; {tag.label} </Typography>}

        {this.state.isOpen && <div className={classes.subChild}>
          {childList}
        </div>}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(SchemaNodes));
