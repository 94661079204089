
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import Alert from '@material-ui/lab/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { hideAddInventoryDialog } from 'redux/hardwareProduct/action';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import CalendarImage from "assets/images/calendar.svg"
import Image from 'Components/Common/image.jsx';
import {
    Dialog, DialogContent, Slide, Typography, InputAdornment, FormControl, Grid, MenuItem, Select
} from '@material-ui/core';
import ReactSelect from "react-select";
import { fetchUsers } from "redux/usersSettings/action";
import SqureButton from 'Components/Common/SqureButton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.hardwareProduct.isShowInventoryDialog,
    isUserProgress: state.authUser.isUserProgress,
    usersSettings: state.usersSettings,
});

const connectionActions = {
    hideDialog: hideAddInventoryDialog,
    fetchUsers: fetchUsers
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 10,
        overflowY: 'hidden'
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    body: {
        marginTop: 20
    },
    datePicker: {
        "& .react-datepicker-wrapper": {
            width: '100%',
        },
    },
    headingForm: {
        fontSize: theme.spacing(2),
        color: '#6C6C6C',
        marginBottom: theme.spacing(0.8)
    },
    underWarranty: {
        "& [class*=MuiFormControl-root]": {
            width: '100%'
        },
        "& [class*=MuiSelect-outlined]": {
            color: "#707070",
            fontSize: theme.spacing(1.8),
            padding: 15,
        },
    },
    bottomActions: {
        marginTop: 20,
        marginBottom: 20,
        textAlign: 'center'
    },
    alerts:{
        marginBottom:10
    }
});

class AddInventoryDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            purchaseon: null,
            purchaseon_error: '',
            underWarranty: 'Yes',
            warrantyTill: null,
            warrantyTill_error: '',
            allocatedTo:null
        }

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {

    }

    fetchUsersList = (query = '') => {
        this.props.fetchUsers(0, 10, query);
    };

    clearError(){
        this.setState({
            error: '',
            purchaseon_error:''
        })
    }

    handleChange = (selectedOptions) => {
        console.log(selectedOptions)
        this.setState({
            allocatedTo: selectedOptions
        },()=>{
            this.clearError()
        })
    }

    handleFormSubmit(){
        this.clearError();
        const {underWarranty,purchaseon,warrantyTill,allocatedTo} = this.state;


        if (purchaseon === null) {
            this.setState({
                purchaseon_error: "Please select."
            })
            return
        }

        if(underWarranty === 'Yes' && warrantyTill === null){
            this.setState({
                warrantyTill_error: "Please select."
            })
            return
        }

        if (allocatedTo === null || allocatedTo === '') {
            this.setState({
                error: "Please select user."
            })
            return
        }

        
    }

    render() {
        const classes = this.props.classes;

        let usersList = this.props.usersSettings.users.map((user) => {
            return { ...user };
        });

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Add Inventory</Typography>
                <div className={classes.body}>
                    <div className={classes.alerts}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                        {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
                    </div>

                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} md={6} xl={6}>
                            <div className={classes.datePicker}>
                                <DatePicker
                                    selected={this.state.purchaseon}
                                    onChange={(date) => {
                                        this.setState({
                                            purchaseon: date,
                                        })
                                    }}
                                    dateFormat="MMM do yyyy"
                                    portalId="evMetaDialog"
                                    minDate={null}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    customInput={
                                        <OutlinedInput
                                            label="Purchase On *"
                                            readOnlyInput={true}
                                            error={this.state.purchaseon_error.length > 0}
                                            helperText={this.state.purchaseon_error}
                                            startAdornment={
                                                <InputAdornment>
                                                    <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                                </InputAdornment>
                                            }
                                        />
                                    }
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} xl={6} className={classes.underWarranty}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Typography className={classes.headingForm}>
                                    Under Warranty <span className={classes.required}>*</span>
                                </Typography>
                                <Select value={this.state.underWarranty} onChange={(e) => {
                                    this.setState({ underWarranty: e.target.value });
                                }}>
                                    <MenuItem value={'Yes'} style={{ fontSize: 13 }}>Yes</MenuItem>
                                    <MenuItem value={'No'} style={{ fontSize: 13 }}>No</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                        {this.state.underWarranty === 'Yes' && <Grid item xs={12} sm={12} md={12} xl={12}>
                            <div className={classes.datePicker}>
                                <DatePicker
                                    selected={this.state.warrantyTill}
                                    onChange={(date) => {
                                        this.setState({
                                            warrantyTill: date,
                                        })
                                    }}
                                    dateFormat="MMM do yyyy"
                                    portalId="evMetaDialog"
                                    minDate={null}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    customInput={
                                        <OutlinedInput
                                            label="Warranty Till"
                                            readOnlyInput={true}
                                            error={this.state.warrantyTill_error.length > 0}
                                            helperText={this.state.warrantyTill_error}
                                            startAdornment={
                                                <InputAdornment>
                                                    <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                                </InputAdornment>
                                            }
                                        />
                                    }
                                />
                            </div>
                        </Grid>}
                        <Grid item xs={12} sm={12} md={12} xl={12}>
                            <div className={classes.nameBox}>
                                <Typography className={classes.headingForm}>
                                    Colloborators <span className={classes.required}>*</span>
                                </Typography>
                                <ReactSelect
                                    isClearable
                                    isSearchable={true}
                                    isMulti={false}
                                    classNamePrefix="select"
                                    placeholder="Users"
                                    options={usersList.map((item) => {
                                        return {
                                            value: item.ID,
                                            label: item.Name + ' - ' + item.Email,
                                            email: item.Email,
                                            name: item.Name,
                                        }
                                    })}
                                    onChange={this.handleChange}
                                    onInputChange={(e) => {
                                        this.fetchUsersList(e)
                                    }}
                                    isLoading={this.props.isUserProgress}
                                    menuPortalTarget={document.getElementById("dialogUserGroup")}
                                    defaultValue={this.state.selectedCollaborators}
                                    value={this.state.selectedCollaborators}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <div className={classes.bottomActions}>
                    <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={()=>this.props.hideDialog()}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                    <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={() => { this.handleFormSubmit() }}>Save</SqureButton>
                </div>

            </DialogContent>
        </Dialog>
    }
}

AddInventoryDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AddInventoryDialog));