import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Table, TableHead, TableRow, TableBody, TableCell, Checkbox, CircularProgress, TextField, Menu, MenuItem } from '@material-ui/core';
// import OutlinedInput from "../../Common/Input/OutlinedInput";
import { InputAdornment, Grid, Button } from '@material-ui/core';
import { Search as SearchIcon,ArrowDropDown as  ArrowDropDownIcon} from "@material-ui/icons";
import classname from "classnames";
import {fetchConfig,fetchUser,addTeamMember,updateTeamMember,checkUnCheckAllTeamMember,showAddTeamDialog,addTeamMembers} from "../../../redux/okta/action";
import {fetchConfig as fetchSamlConfig} from "../../../redux/saml/action"
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddUserImage from "../../../assets/images/addUser.svg";
import AddUserDialog from "../../Common/AddUserDialog";
import Alert from '@material-ui/lab/Alert';
import CommonCss from '../../../commonCss';
import Image from 'Components/Common/image.jsx'

const connectedProps = (state) => ({
    addTeamDialogOpen:state.okta.addTeamDialogOpen,
    configFetchProgress:state.okta.configFetchProgress,
    configFetchError:state.okta.configFetchError,
    config:state.okta.config,
    configSamlFetchProgress:state.saml.configFetchProgress,
    configSamlFetchError:state.saml.configFetchError,
    configSaml:state.saml.config,
    team:state.okta.team,
    oktaUsers:state.okta.oktaUsers,
    fetchOktaUserProg:state.okta.fetchOktaUserProg,
    addTeamMemberProgress:state.okta.addTeamMemberProgress,
    addTeamMemberError:state.okta.addTeamMemberError
});

const connectionActions = {
    fetchConfig:fetchConfig,
    fetchUser:fetchUser,
    addTeamMember:addTeamMember,
    updateTeamMember:updateTeamMember,
    checkUnCheckAllTeamMember:checkUnCheckAllTeamMember,
    showAddTeamDialog:showAddTeamDialog,
    addTeamMembers:addTeamMembers,
    fetchSamlConfig:fetchSamlConfig
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    'root': {
        boxShadow: "none",
        minHeight: 'calc(100vh - 3em)',
        padding: theme.spacing(9, 12.8, 9, 14),
        border: "1px solid #E5E5E5",
        borderRadius: 0,
    },
    "heading": {
        margin: '0 auto',
        textAlign: "center",
        marginBottom: theme.spacing(10),
    },
    "title": {
        fontSize:theme.spacing(2.9),
        fontWeight:600,
        marginBottom: theme.spacing(2),
        color: '#12344D'
    },
    "subtitle":{
      color:"#475867", 
      width:"50%",
      margin:"0 auto",
      textAlign:"center" 
    },
    "searchBar": {
        margin: '0 auto',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        "& [class*='MuiOutlinedInput-root']": {
            padding: '4px 12px'
        }
    },
    "apps": {
        margin: '0 auto',
        marginBottom:theme.spacing(10),
        marginTop:theme.spacing(4)
    },
    "formAction": {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: "center",
    },
    "button": {
        padding: theme.spacing(0.9, 4),
        fontSize:theme.spacing(2),
        fontWeight:600,
        textTransform:"none"
    },
    "margin": {
        marginRight: theme.spacing(6),
        fontWeight:400,
        /* font-size: 1em; */
        padding:theme.spacing(.9, 6)
    },
    "section": {
        // width: "90%",
        margin: '0 auto',
        display: "flex",
        marginTop: theme.spacing(2)
    },
    "sectionTitle": {
        // marginTop: theme.spacing(2)
    },
    "head": {
        color: '#12344D',
        fontSize: theme.spacing(2.6),
        marginBottom: theme.spacing(0.95),
        marginTop: 0,
    },
    "sectionAction": {
        flex: 1,
        textAlign: "right"
    },
    "optionItem":{
        width:"100%"
    },
    "optionAction":{
        width:"100%",
        textAlign:"right"
    },
    "optionName":{
        color:"#12344D"
    },
    "optionMeta":{
        color:"#12344D",
        fontSize:"12px"
    },
    "dropDownButton":{
        textTransform:"none",
        color:"#12344D",
        padding: '1px 18px',
        border: "1px solid #B9B9B9",
        borderRadius: 2
    },
    "checkBoxCell":{
        width:"10%"
    },
    "nameCell":{
        width:"30%",
        color: "#4B4B4B",
        fontSize: theme.spacing(1.8),
        padding: '6px'
        
    },
    "metaCells":{
        width:"20%",
        color: "#4B4B4B",
        fontSize: theme.spacing(1.8),
        padding: '6px'
    },
    "alert":{
        marginTottom:"10px",
        width: "90%",
        margin: '0 auto',
    },
    "subHead": {
        color: "#738796",
        fontSize: theme.spacing(1.8),
        marginTop: 0,
        letterSpacing: 0
    },
    "appName": {
        color: '#4A87F8',
        opacity: 1,
    },
    "addUserBtn": {
        boxShadow: "4px 0px 4px #2B2D3703",
        border: "2px solid #20253A",
        borderRadius: "4px",
        padding:theme.spacing(0.8, 2.6),
        color: "#20253A",
        fontSize: theme.spacing(2),
        fontWeight: 400,
        "&:hover": {
            border: "2px solid #20253A",
        }
    },

    "checkBox": {
        padding: "6px"
    },

    icon: CommonCss.icon,
    checkedIcon: CommonCss.checkedIcon
});

class Team extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            autocompleteOpen:false,
            searchQuery:"",
            selectedValue:null,
            dropDownAnchorBtn:null,
            error:''
        }
        this.getNameFromRole    =   this.getNameFromRole.bind(this);
        this.addMembers     =   this.addMembers.bind(this);

    }
    componentDidMount(){
        this.props.fetchConfig()
        this.props.fetchSamlConfig()
    }

    componentDidUpdate(prevProps){
        if(this.props.addTeamMemberProgress == false && prevProps.addTeamMemberProgress == true){
            if(this.props.addTeamMemberError !== null){
                this.setState({'error':this.props.addTeamMemberError.message})
            } else {
                this.props.history.push("/onboard/completion");
            }
        }
    }

    getNameFromRole(role){
        switch(role){
            case "OrgAdmin":
                return "Admin";
            case "OrgManager":
                return "Org Manager";
            case "OrgUser":
                return "User";
        }
    }

    addMembers(){
        this.setState({'error':''})
        this.props.addTeamMembers(this.props.team.filter((i)=>(i.checked)));
    }

    render() {
        const classes = this.props.classes;

        var apps    =   [];
        

        if(this.props.config !== null){
            apps.push("Okta");
        } else if(this.props.configSaml !== null){
            apps.push("Google");
        }
       console.log(this.props.team, 'this.teams')
        return <Paper className={classes.root}>
            <div className={classes.heading}>
                <Typography variant={"h4"} className={classes.title} color="secondary">Invite your team</Typography>
                {/* <Typography className={classes.subtitle}>Slack is a collaboration hub that can replace email to help you and your team work together seamlessly.</Typography> */}
            </div>
            <div className={classes.searchBar}>
            <Autocomplete
            id="asynchronous-demo"
            style={{ width: "100%" }}
            open={this.state.autocompleteOpen}
            onOpen={() => {
                this.setState({autocompleteOpen:true});
            }}
            onClose={() => {
                this.setState({autocompleteOpen:false});
            }}
            renderOption={(user) => {
                return <div className={classes.optionItem}>
                    <Grid container>
                        <Grid item lg={10} sm={10} xs={10}>
                            <Typography className={classes.optionName}>{user.Name}</Typography>
                            <Typography className={classes.optionMeta}>{user.Email}</Typography>
                            <Typography className={classes.optionMeta}>{user.Groups.map((i)=>(i.Name)).join(" | ")}</Typography>
                        </Grid>
                        <Grid item lg={2} sm={2} xs={2}>
                            <div className={classes.optionAction}>
                                <Image src={AddUserImage} alt={"addUser"} className={classes.optionAddUserImage}/>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }}
            value={this.state.selectedValue}
            onChange={(event, newValue) => {
                this.setState({selectedValue:newValue})
                if(newValue !== null){
                    this.props.addTeamMember({
                        name:newValue.Name,
                        email:newValue.Email,
                        team:newValue.Groups.map((i)=>(i.Name)).join(" | "),
                        role:"OrgUser",
                        checked:true
                    })
                }
                
            }}
            getOptionSelected={(option, value) => option.Name === value.Name}
            getOptionLabel={(option) => option.Name}
            options={this.props.oktaUsers}
            loading={this.props.fetchOktaUserProg}
            onInputChange={(event, newInputValue) => {
                this.setState({searchQuery:newInputValue});
                this.props.fetchUser(this.state.searchQuery);
            }}
            renderInput={(params) => {
                return <TextField
                        {...params}
                        label=""
                        variant="outlined"
                        placeholder="Search User"
                        InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                            </React.Fragment>
                        ),
                        startAdornment:(
                                <InputAdornment>
                                    <SearchIcon className={classes.searchIcon} />
                                </InputAdornment>
                        )
                        }}
              />

                
            }}
            />
                
            </div>
            
            <div className={classes.section}>
                <div className={classes.sectionTitle}>
                    <Typography variant={"h6"} className={classes.head}>Add Users</Typography>
                    {apps?.length > 0 && <Typography className={classes.subHead}> These users are Populated from <b className={classes.appName}>{apps.join(", ")}</b></Typography>}
                    {/* {this.props.configFetchProgress && <CircularProgress />} */}
                </div>
                <div className={classes.sectionAction}>
                    <Button size={"small"} variant="outlined" color="secondary" className={classes.addUserBtn} onClick={() => {
                        this.props.showAddTeamDialog();
                    }}>Add User Manually</Button>
                </div>
            </div>
            <div className={classes.alert}>
                    {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
            </div>
            <div className={classes.apps}>
                <Table className={classes.table} aria-label="simple table" >
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" className={classes.checkBoxCell}>
                                <Checkbox
                                    color="primary"
                                    className={classes.checkBox}
                                    checkedIcon={<span className={classname(classes.icon, classes.checkedIcon)} />}
                                    icon={<span className={classes.icon} />}
                                    checked={this.props.team?.length == 0? false:this.props.team?.filter((i)=>(i.checked))?.length == this.props.team?.length}
                                    onChange={(event)=>{
                                        this.props.checkUnCheckAllTeamMember(event.target.checked)
                                    }}
                                />
                            </TableCell>
                            <TableCell className={classes.nameCell}>Name</TableCell>
                            <TableCell align="center" className={classes.metaCells}>Email</TableCell>
                            <TableCell align="center" className={classes.metaCells}>Team</TableCell>
                            <TableCell align="center" className={classes.metaCells}>Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.props.team.map((user,k)=>{
                                return <TableRow key={k}>
                                    <TableCell padding="checkbox" className={classes.checkBoxCell}>
                                        <Checkbox
                                            color="primary"
                                            checked={user.checked}
                                            checkedIcon={<span className={classname(classes.icon, classes.checkedIcon)} />}
                                            icon={<span className={classes.icon} />}
                                            className={classes.checkBox}
                                            onChange={(event)=>{
                                                user.checked = event.target.checked;
                                                this.props.updateTeamMember(user);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.nameCell}>{user.name}</TableCell>
                                    <TableCell align="center" className={classes.metaCells}>{user.email}</TableCell>
                                    <TableCell align="center" className={classes.metaCells}>{user.team}</TableCell>
                                    <TableCell align="center" className={classes.metaCells}>
                                        <Button 
                                            variant="outlined"
                                            color="default"
                                            className={classes.dropDownButton}
                                            // fullWidth={true}
                                            endIcon={<ArrowDropDownIcon />}
                                            onClick={(event)=>{
                                                this.setState({dropDownAnchorBtn:event.currentTarget})
                                            }}
                                            >{this.getNameFromRole(user.role)}</Button>
                                            <Menu
                                                id={`roleMemu_${k}`}
                                                anchorEl={this.state.dropDownAnchorBtn}
                                                keepMounted
                                                open={Boolean(this.state.dropDownAnchorBtn)}
                                                onClose={()=>{
                                                    this.setState({dropDownAnchorBtn:null})
                                                }}
                                                >
                                                <MenuItem onClick={()=>{
                                                    this.setState({dropDownAnchorBtn:null})
                                                    user.role = "OrgAdmin";
                                                    this.props.updateTeamMember(user)
                                                }}>Admin</MenuItem>
                                                {/* <MenuItem onClick={()=>{
                                                    this.setState({dropDownAnchorBtn:null})
                                                    user.role = "OrgManager";
                                                    this.props.updateTeamMember(user)
                                                }}>Org Manager</MenuItem> */}
                                                <MenuItem onClick={()=>{
                                                    this.setState({dropDownAnchorBtn:null})
                                                    user.role = "OrgUser";
                                                    this.props.updateTeamMember(user)
                                                }}>User</MenuItem>
                                            </Menu>
                                    </TableCell>
                                </TableRow>
                            })
                        }
                        
                        {this.props.team?.length == 0 && <TableRow>
                            <TableCell align="center" colSpan={5}>No Team Member Added</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
            {!this.props.addTeamMemberProgress && <div className={classes.formAction}>
                <Button variant="outlined" color="secondary" className={classname(classes.margin, classes.button)} onClick={() => {
                    this.props.history.push("/onboard/completion");
                }}>Cancel</Button>

                <Button variant="contained" color="secondary" disabled={this.props.team.filter((i)=>(i.checked))?.length == 0} className={classes.button} onClick={() => {
                    this.addMembers();
                    
                }}>Add selected users</Button>
            </div>}
            {this.props.addTeamMemberProgress && <div className={classes.formAction}>
                <CircularProgress />
            </div>}
            {
                this.props.addTeamDialogOpen &&
                    <AddUserDialog
                        isFromSettings={true}
                        isEdit={this.state.isEdit}
                        editValue={this.state.editValue}
                    />
            }
        </Paper >
    }
}

export default connector(compose(
    withStyles(styles)
)(Team));