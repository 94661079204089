import {START_UPDATEPASSWORD,END_UPDATEPASSWORD} from "./action";

const initState   =     {
    isProgress:false,
    error:null,
    success:null
}

export  function updatePasswordReducer(state=initState,action){
    switch(action.type){
        case START_UPDATEPASSWORD:
            return {
                ...state,
                isProgress:true,
                error:null,
                success:null
            };
        case END_UPDATEPASSWORD:
            return {
                ...state,
                isProgress:false,
                error:action.payload.error,
                success:action.payload.success
            }
        default:
            return state;
    }
}