import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Card, Typography, Button, Popover, IconButton, Divider } from '@material-ui/core';
import InActiveImage from "../../../../assets/images/inactive_users.svg"
import ActiveImage from "../../../../assets/images/active_users.svg"
import DefaultVendor from "../../../../assets/images/default-vendor.png"
import classnames from "classnames";
import { Link } from "react-router-dom";
import LinesEllipsis from 'react-lines-ellipsis'
import Image from 'Components/Common/image.jsx'
import { updateVendorRefactor } from "redux/metric/action";
import Google from 'assets/images/apps/google.png' 
import Okta from 'assets/images/apps/okta.png' 
import MergeProductDialog from './MergeProductDialog';

const connectedProps = (state) => ({

});

const connectionActions = {
    updateVendorRefactor: updateVendorRefactor,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    "card": {
        padding: theme.spacing(2),
        cursor: "pointer",
    },
    wrapper: {
        position: 'relative'
    },
    "head": {
        textAlign: "center",
        marginBottom:theme.spacing(3)
    },
    "stats": {
        display: "flex",
        width: "100%",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    "statItem": {
        flex: 1,
        textAlign: "center"
    },
    "divider": {
        borderRight: "solid 1px " + theme.palette.grey[300]
    },
    "action": {
        textAlign: "right"
    },
    "vendorImage":{
        maxHeight: '70px',
        maxWidth: '40%',
        width: 'auto',
        height: 'auto'

    },
    "vendorTitle":{
        overflow:"hidden",
        marginBottom: '30px',
        fontSize: theme.spacing(2.4),
        fontWeight: 400,
        height:"45px"
    },
    stateTitle:{
        color:"#20253A",
        opacity:0.7,
        padding: '0px 5px',
        fontSize:theme.spacing(1.8),
        marginBottom:theme.spacing(2)
    },
    stateValue:{
        color:"#20253A",
        // fontSize:theme.spacing(3.2),
        fontSize:'1.3em',
    },
    threeDot: {
        position: "absolute",
        zIndex: 1,
        right: 3,
        top: 0
    },
    div: {
        paddingRight: 8,
    },
    metricSource:{
        textAlign: 'right',
        marginRight: 40,
        '& img': {
            height: 20,
            width: 'auto'
        }
    },
    tags: {
        display: 'flex',
        width: 'max-content',
        height: 16,
        paddingTop: 2
      },
    popover: {
        "& [class*='paper']": {
            boxShadow: "0px 8px 12px #0000001D",
            border: "1px solid #EEEEEE",
            borderRadius: "5px",
            overflow: "inherit",

            "&::before": {
                top: "10px",
                left: "-16px",
                width: "0",
                borderTop: "8px solid transparent",
                height: "0px",
                content: "''",
                display: "block",
                position: "absolute",
                borderBottom: "8px solid transparent",
                borderLeft: "8px solid transparent",
                borderRight: "8px solid #fff"
            },
        },
    },
    popoverBtn: {
        width: "100%",
        padding: theme.spacing(0.5, 2, 0.5, 2),
        minHeight: "30px",
        lineHeight: "13px",

        "& > span": {
            textAlign: "left",
            display: "block"
        }
    },
    tag: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#f7f7f7',
        textAlign: 'center',
        marginRight: 2,
        position: 'relative',
        width: 'max-content',
        height: 18,
        borderRadius: 9,
        padding: '0px 5px',
        "& > span": {
          fontSize: '12px',
          fontWeigt: '300 !important',
          lineHieght: '1',
          backgroundColor: 'inherit',
        },
        "& span:nth-child(2)": {
          zIndex: 12,
        }
    },
    isNew: {
        background: '#ff9800',
        color: '#fff',
    },
});

class VendorCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            showMergeProductDialog: false,
        }
        this.btnRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(event) {
        this.setState({ anchorEl: event.currentTarget || event.current });
    };

    handleClose() {
        this.setState({ anchorEl: null });
    };

    render() {
        const classes = this.props.classes;
        const vendor    = this.props.vendor;
        if(vendor == null || vendor === undefined){
            return null;
        }
        const open = Boolean(this.state.anchorEl);
        
        return (
            <div className={classes.wrapper}>
                {/* {!vendor.existing && <IconButton ref={this.btnRef} className={classes.threeDot} onClick={(e) => {
                    e.stopPropagation();
                    this.handleClick(e);
                }}><MoreVertIcon /></IconButton>} */}
                <IconButton ref={this.btnRef} className={classes.threeDot} onClick={(e) => {
                    e.stopPropagation();
                    this.handleClick(e);
                }}><MoreVertIcon /></IconButton>
                <Popover
                    className={classes.popover}
                    id={"metrics_popover"}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.handleClose()}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}>
                    <Button className={classes.popoverBtn} onClick={() => {
                        let data = {
                            type: "new",
                            target: 0
                        };
                        this.props.updateVendorRefactor(vendor.application_id, data);
                        this.handleClose();
                    }}> <p className={classes.popoverText}>Add to existing</p></Button>
                    <Divider />
                    <Button className={classes.popoverBtn} onClick={() => {
                        this.setState({ showMergeProductDialog: true})
                    }}> <p className={classes.popoverText}>Merge with other product</p></Button>
                </Popover>
                <Link to={`/app/metrics/vendor/${vendor.application_id}`}>
                    <Card className={classes.card}>
                        <div className={classes.tags}>
                            {!vendor.existing && (
                                <div className={classnames(classes.tag, classes.isNew)}>
                                    <span>New</span>
                                </div>
                            )}
                        </div>
                        <div className={classes.head}>
                            <div className={classes.metricSource}>
                                { vendor.source && vendor.source.indexOf("Google") !== -1 && <Image src={Google} alt="Google" title="Google" />}
                                { vendor.source && vendor.source.indexOf("Okta") !== -1 && <Image src={Okta} alt="Okta" title="Okta"  />}
                                
                            </div>
                            <div style={{height:'90px',marginTop: '20px'}}>
                                {vendor?.logo?.length !== 0 && <Image src={vendor.logo} className={classes.vendorImage} />}
                                { ((vendor?.logo?.length === 0) || (!vendor?.logo)) && <Image src={DefaultVendor} className={classes.vendorImage} />}
                            </div>
                            <Typography variant={"h2"} className={classes.vendorTitle}>
                                <LinesEllipsis text={vendor.name === null ? '-' : vendor.name} maxLine='2' ellipsis='...' basedOn='letters'/>
                            </Typography>
                            {/* <Typography variant="subtitle1">{vendor.label}</Typography> */}
                        </div>
                        <div className={classes.stats}>
                            <div className={classnames(classes.statItem, classes.divider)}>
                                <Image src={ActiveImage} alt="active" />
                                <Typography variant="subtitle1" className={classes.stateTitle}>Monthly Active</Typography>
                                <Typography className={classes.stateValue}>{Math.ceil(vendor.monthlyActive) || 0}</Typography>
                            </div>
                            <div className={classnames(classes.statItem, classes.div)}>
                                <Image src={InActiveImage} alt="active" />
                                <Typography variant="subtitle1" className={classes.stateTitle}>Weekly Active</Typography>
                                <Typography className={classes.stateValue}>{Math.ceil(vendor.weeklyActive) || 0}</Typography>
                            </div>
                        </div>
                        {/* <div className={classes.action}>
                            <IconButton>
                                <ArrowForwardIcon />
                            </IconButton>
                        </div> */}
                    </Card>
                </Link>
                {this.state.showMergeProductDialog && <MergeProductDialog
                    hideDialog={() => {
                        this.setState({ showMergeProductDialog: false });
                    }}
                    onSubmit={(productId) => {
                        let data = {
                            type: "existing",
                            target: parseInt(productId)
                        };
                        this.props.updateVendorRefactor(vendor.application_id, data);
                        this.handleClose();
                        this.setState({ showMergeProductDialog: false });
                    }}
                    />}
            </div>
        )
    }
}

export default connector(compose(
    withStyles(styles)
)(VendorCard));