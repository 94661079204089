import React, { useState, useEffect } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import {
  Drawer,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  TextField, FormControlLabel, FormGroup
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CommonCss from "../../commonCss";
import classnames from "classnames";
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = (theme) =>
  createStyles({
    "@global": {
      "[class*='MuiAutocomplete-popper'] *": {
        fontSize: 13,
      },
    },
    appyBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      minHeight: 35,
      width: "calc(100% - 46px)",
      margin: 23,
      color: "#fff",
      fontSize: 14,
      padding: theme.spacing(0.5, 8),

      "&:hover": {
        background: "#3C3C3C",
      },
    },
    drawerSection: {
      minWidth: 350,
      maxWidth: 350,
      paddingTop: 30,
      "& [class*='MuiAccordionSummary-root']": {
        padding: theme.spacing(0, 3.6),
        minHeight: 48,
        width: "calc(100% - 10px)",
        "& [class*=MuiSvgIcon-root]": {
          width: 16,
        },
      },

      "& [class*='MuiAccordion-root']": {
        boxShadow: "none",
        borderBottom: "1px solid #EFEFEF",
        borderRadius: 0,
        margin: 0,
        "&:before": {
          background: "none",
        },
      },
      "& [class*='MuiAccordionSummary-content']": {
        margin: 0,
      },
      "& [class*='MuiAccordionDetails-root']": {
        padding: theme.spacing(0, 2),
      },

      "& [class*='MuiAccordionSummary-expandIcon']": {
        flexDirection: "row-reverse",
        padding: 0,
        "&.Mui-expanded ": {
          transform: "rotate(90deg)",
        },
      },

      "& [class*='MuiOutlinedInput-root']": {
        padding: "2px 60px 2px 2px !important",
        marginBottom: "10px !important",
        fontSize: 13,
      },
      "& [class*='MuiAutocomplete-root']": {
        width: "100%",
      },
      "& [class*='MuiAutocomplete-tag']": {
        background: "#4A87F8",
        color: "#fff",
        height: 28,
      },
    },
    textCenter: {
      textAlign: "center",
      marginTop: 30,
    },
    head: {
      color: "#282D30",
      fontSize: 18,
    },
    priceText: {
      color: "#1C1C1C",
      padding: theme.spacing(1, 3.9),
      fontSize: 13,
    },
    sliderRange: {
      width: "calc(100% - 70px)",
      margin: "auto",
      display: "block",
      marginBottom: 14,

      "& [class*='MuiSlider-thumb']": {
        background: "#FFFFFF",
        boxShadow: "0px 2px 4px #1000001A",
        border: "1px solid #E4E4E4",
        width: 22,
        height: 22,
        marginTop: -10,
      },
      "& [class*='MuiSlider-track']": {
        height: 3,
        backgroundColor: "#007BFF",
      },
      "& [class*='MuiSlider-root']": {
        color: "#abd0f5",
        height: "3px",
      },
    },
    sliderValue: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(0, 3.6),
      color: "#545454",
      fontSize: 13,
      "& p": {
        margin: 0,
      },
    },
    sliderText: {
      color: "#969090",
      border: "1px solid #969090",
      width: 50,
      display: "flex",
      height: 40,
      padding: 8,
      alignItems: "center",
      borderRadius: 4,
      marginTop: 10,
    },
    headSection: {
      display: "flex",
      justifyContent: "space-between",
      padding: 23,
      paddingBottom: 53,
    },
    checkboxHeadSection: {
      padding: 23,
      paddingBottom: 53,
      paddingTop: 53,
    },
    close: {
      fill: "#656262",
      cursor: "pointer",
    },
    label: {
      ...CommonCss.label,
      color: "#545454",
      fontSize: 14,
      marginBottom: 0,
    },
    filterText: {
      color: "#1C1C1C",
      fontSize: 13,
      margin: 0,
    },
    iconEle: {
      width: 18,
      color: "#fff",
      marginRight: 10,
      "&:hover": {
        color: "#e8e2e2",
      },
    },
    checkboxSection: {
      width: "100%",
      maxHeight: 300,
      overflowY: "auto",
      marginBottom: 10,
    },
    checkedIcon: CommonCss.checkedIcon,
    icon: CommonCss.icon,
    searchSection: {
      width: "96%",
      padding: 10,
      marginBottom: 10,
      marginLeft: 10,
      height: 40,
      borderRadius: 4,
      border: "1px solid #b7b5b5",
    },
    checkboxWithFilterSection: {
      width: "100%",
      position: "relative",
    },
    closeIcon: {
      position: "absolute",
      right: 4,
      top: 7,
      color: "#ada8a8",
      cursor: "pointer",
      "&:hover": {
        color: "#ada8a8",
      },
    },
    checkBoxFilter:{
      marginLeft : theme.spacing(1),
      marginBottom : theme.spacing(1),
    },
    formGroup : {
      marginLeft : 10,
      '& [class*=MuiTypography-root]' : {
        fontSize : '14px'
      }
    }
  });

const SliderFilter = ({ classes, toggleDrawer, open, filters, defaultFilter, onClickApplyFilter, onChangeCheckBoxFilter }) => {
  const [state, setState] = useState({
    open: false,
    value: [20, 37],
    expanded: false,
    searchFeatures: "",
    filters: {
      featureIds: [],
      platformIds: [],
    },
  })

  useEffect(() => {
    setState(prevState => ({ ...prevState, filters: defaultFilter }))
  }, [defaultFilter])

  const handleChangeFilter = (key, newValue, checked) => {
    let filters = state.filters
    if (key === 'featureIds') {
      filters[key] = newValue
    }
    if (key === 'platformIds') {
      const platformIds = filters.platformIds
      if (checked) {
        platformIds.push(newValue)
      } else {
        const index = platformIds.indexOf(newValue);
        if (index > -1) {
          platformIds.splice(index, 1);
        }
      }
      filters[key] = platformIds
    }
    setState(prevState => ({ ...prevState, filters }))
  };

  const handleOnClickApply = () => {
    onClickApplyFilter(state.filters)
  }

  const handleCheckBoxFilters = (e) => {
    onChangeCheckBoxFilter(e.target)
  }

  const accordionChange = (panel) => {
    setState({ ...state, expanded: panel === state.expanded ? false : panel });
  };

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={(e) => toggleDrawer(e)}
      >
        <div className={classes.drawerSection}>
          <div className={classes.headSection}>
            <div className={classes.head}>Filters</div>
            <div onClick={(e) => toggleDrawer(e)}>
              <CloseIcon className={classes.close} />
            </div>
          </div>
          <div>
            {filters.map((filter, index) => (
              <Accordion
                key={index}
                expanded={state.expanded === `panel${index}`}
                onChange={() => accordionChange(`panel${index}`)}
              >
                <AccordionSummary expandIcon={<ArrowForwardIosIcon />}>
                  <p className={classes.filterText}>{filter.label}</p>
                </AccordionSummary>
                <AccordionDetails>
                  {filter.isCheckBoxFilter !== undefined && filter.isCheckBoxFilter === true && <div className={classes.checkBoxFilter}>
                    {filter.list.map((checkbox, index) => {
                      return <FormGroup className={classes.formGroup}>
                        <FormControlLabel
                          control={

                            <Checkbox
                            checked={checkbox.checked}
                            checkedIcon={
                              <span
                                className={classnames(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            onChange={(e) => handleCheckBoxFilters(e)}
                            icon={<span className={classes.icon} />}
                            color="primary"
                            value={checkbox.key}
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                          />
                          }
                          label={checkbox.label}
                        /></FormGroup>
                    })}
                  </div>}
                  {filter.isMultiAutoCompleteSelect === true && (
                    <>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        value={filter.list.filter(obj => state.filters?.featureIds && state.filters.featureIds.includes(obj.value)) || []}
                        options={filter.list}
                        getOptionLabel={(option) => option.label}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={filter.placeholder || `Search ${filter.label}`}
                          />
                        )}
                        ChipProps={{
                          deleteIcon: (
                            <CloseIcon className={classes.iconEle} />
                          ),
                        }}
                        onChange={(e, value) => handleChangeFilter(filter.key, value.map(o => o.value))}
                      />
                    </>
                  )}
                  {filter.isMultiCheckboxSelect === true && (
                    <div className={classes.checkboxWithFilterSection}>
                      {filter.isSearchRequired === true && (
                        <div>
                          <input
                            placeholder={filter.placeholder}
                            className={classes.searchSection}
                            onInput={(event) =>
                              setState({
                                searchFeatures: event.target.value,
                              })
                            }
                          />
                          <CloseIcon
                            className={classnames(
                              classes.iconEle,
                              classes.closeIcon
                            )}
                          />
                        </div>
                      )}
                      <div className={classes.checkboxSection}>
                        {filter.list.map((object, index) => (
                          <div key={index}>
                            <label className={classes.label}>
                              {" "}
                              <Checkbox
                                checked={state.filters?.platformIds.includes(object.value)}
                                checkedIcon={
                                  <span
                                    className={classnames(
                                      classes.icon,
                                      classes.checkedIcon
                                    )}
                                  />
                                }
                                onChange={e => {
                                  handleChangeFilter(filter.key, object.value, e.target.checked)
                                }}
                                icon={<span className={classes.icon} />}
                                color="primary"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                              {object.label}
                            </label>
                          </div>
                        ))}
                      </div>{" "}
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          {/* <div>
            <p className={classes.priceText}>Price</p>
            <Slider
              className={classes.sliderRange}
              onChange={handleChange}
              value={state.value}
              aria-labelledby="range-slider"
            />
            <div className={classes.sliderValue}>
              <div>
                <p>Min</p>
                <span className={classes.sliderText}>
                  {state.value[0]}
                </span>
              </div>
              <div>
                <p>Max</p>
                <span className={classes.sliderText}>
                  {state.value[1]}
                </span>
              </div>
            </div>
          </div> */}
          <div className={classes.textCenter}>
            <Button className={classes.appyBtn} onClick={() => handleOnClickApply()}>Apply</Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default React.memo(compose(withRouter, withStyles(styles))(SliderFilter));
