import React, { useState } from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CriteriaRequirement from "./CriteriaRequirement";
import SqureButton from 'Components/Common/SqureButton';

// Components
import Image from 'Components/Common/image.jsx'

// assets
import ArrowBack from "assets/images/arrow_back.svg";
import {
    addCriteriaSelection
} from "redux/templates/evaluation/action";
import TemplateCreateMergeDialog from './TemplateCreateMergeDialog';

const connectedProps = (state) => ({
})

const connectionActions = {
    addCriteriaSelection: addCriteriaSelection,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        position: 'relative',
        overflow: "auto",
        height: "calc(100vh - 60px) !important",
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none'
    },
    image: {
        width: theme.spacing(3.5),
        cursor: 'pointer'
    },
    criterisSection: {
        flex: 1
    },
    imageSection: {
        marginRight: theme.spacing(3),
        paddingTop: theme.spacing(0.5)
    },
    "@global": {
        ".applicationContent": {
            position: 'relative'
        },
    },
    bottomNav: {
        display: 'none',
        position: 'absolute',
        justifyContent: 'space-between',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '20px 30px',
        background: '#fff',
    }
});

const Index = ({
    history,
    classes,
    isChooseTemplate = false,
    chooseTemplateId,
    chooseTemplateType,
    handleBack,
    addCriteriaSelection
}) => {
    const [open, setOpen] = useState(false)
    const [isCreateNew, setIsCreateNew] = useState(false)
    return (
        <>
            <Grid container className={classes.root}>
                {false && <div className={classes.imageSection}>
                    <Image
                        src={ArrowBack}
                        alt="arrow_back"
                        className={classes.image}
                        onClick={() => {
                            if (isChooseTemplate) {
                                handleBack()
                            } else {
                                history.goBack()
                            }
                        }}
                    />
                </div>}
                <div className={classes.criterisSection}>
                    <CriteriaRequirement
                        isSettingTemplate={!isChooseTemplate}
                        isChooseTemplate={isChooseTemplate}
                        chooseTemplateId={chooseTemplateId}
                        chooseTemplateType={chooseTemplateType}
                        handleBack={handleBack}
                    />
                </div>
            </Grid>
            <div className={classes.bottomNav} id="bottomDiv">
                <div>
                    <SqureButton
                        variant={"contained"}
                        onClick={() => {
                            addCriteriaSelection(null, null, null, null, true)
                        }}
                    >
                        Cancel
                    </SqureButton>
                </div>
                <div>
                    <SqureButton
                        variant={"outlined"}
                        onClick={() => setOpen(true)}
                    >
                        Merge with existing template
                    </SqureButton>
                    &nbsp;&nbsp;
                    <SqureButton
                        variant={"contained"}
                        onClick={() => {
                            setIsCreateNew(true)
                            setOpen(true)
                        }}
                    >
                        Create new template
                    </SqureButton>
                </div>
            </div>
            <TemplateCreateMergeDialog
                isOpen={open}
                isCreateNew={isCreateNew}
                handleOnClose={() => {
                    setOpen(false)
                    setInterval(() => {
                        setIsCreateNew(false)
                    }, 100)
                }}
            />
        </>
    )
}
export default connector(compose(
    withRouter,
    withStyles(styles)
)(Index));