import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment";

export const START_FETCH_PROJECT_TIMELINE = "START_FETCH_PROJECT_TIMELINE";
export const END_FETCH_PROJECT_TIMELINE = "END_FETCH_PROJECT_TIMELINE";

export const START_ADD_PROJECT_TIMELINE_STEP = "START_ADD_PROJECT_TIMELINE_STEP";
export const END_ADD_PROJECT_TIMELINE_STEP = "END_ADD_PROJECT_TIMELINE_STEP";

export const START_UPDATE_PROJECT_TIMELINE_STEP = "START_UPDATE_PROJECT_TIMELINE_STEP";
export const END_UPDATE_PROJECT_TIMELINE_STEP = "END_UPDATE_PROJECT_TIMELINE_STEP";

export const START_DELETE_PROJECT_TIMELINE_STEP = "START_DELETE_PROJECT_TIMELINE_STEP";
export const END_DELETE_PROJECT_TIMELINE_STEP = "END_DELETE_PROJECT_TIMELINE_STEP";

export const START_ADD_PROJECT_TIMELINE_STEP_COLLABORATORS = "START_ADD_PROJECT_TIMELINE_STEP_COLLABORATORS";
export const END_ADD_PROJECT_TIMELINE_STEP_COLLABORATORS = "END_ADD_PROJECT_TIMELINE_STEP_COLLABORATORS";


function startFetchProjectTimelineSteps(){
    return {
        type:START_FETCH_PROJECT_TIMELINE,
        payload:{}
    };
}

function endFetchProjectTimelineSteps(success,error){
    return {
        type:END_FETCH_PROJECT_TIMELINE,
        payload:{
            success,error
        }
    };
}

export function fetchProjectTimelineSteps(evaluationID){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProjectTimelineSteps());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endFetchProjectTimelineSteps(response,error));
        } catch(e){
            dispatch(endFetchProjectTimelineSteps(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startAddProjectTimelineStep(){
    return {
        type:START_ADD_PROJECT_TIMELINE_STEP,
        payload:{}
    };
}

function endAddProjectTimelineStep(success,error){
    return {
        type:END_ADD_PROJECT_TIMELINE_STEP,
        payload:{
            success,error
        }
    };
}

export function addProjectTimelineStep(evaluationID,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddProjectTimelineStep());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endAddProjectTimelineStep(response,error));
        } catch(e){
            dispatch(endAddProjectTimelineStep(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startAddProjectTimelineStepColloborators(){
    return {
        type:START_ADD_PROJECT_TIMELINE_STEP_COLLABORATORS,
        payload:{}
    };
}

function endAddProjectTimelineStepColloborators(success,error){
    return {
        type:END_ADD_PROJECT_TIMELINE_STEP_COLLABORATORS,
        payload:{
            success,error
        }
    };
}

export function addProjectTimelineStepColloborators(evaluationID,stepId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline/${stepId}/collaborators?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startAddProjectTimelineStepColloborators());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url,data);
            dispatch(endAddProjectTimelineStepColloborators(response,error));
        } catch(e){
            dispatch(endAddProjectTimelineStepColloborators(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startUpdateProjectTimelineStep(){
    return {
        type:START_UPDATE_PROJECT_TIMELINE_STEP,
        payload:{}
    };
}

function endUpdateProjectTimelineStep(success,error){
    return {
        type:END_UPDATE_PROJECT_TIMELINE_STEP,
        payload:{
            success,error
        }
    };
}

export function updateProjectTimelineStep(evaluationID,stepId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline/${stepId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateProjectTimelineStep());
        try{
            let [response,error]    =   await HttpFactory.instance().putMethod(url,data);
            dispatch(endUpdateProjectTimelineStep(response,error));
        } catch(e){
            dispatch(endUpdateProjectTimelineStep(null,{
                message:e.message
            }));
            return;
        }
    };
}

function startDeleteProjectTimelineStep(){
    return {
        type:START_DELETE_PROJECT_TIMELINE_STEP,
        payload:{}
    };
}

function endDeleteProjectTimelineStep(success,error){
    return {
        type:END_DELETE_PROJECT_TIMELINE_STEP,
        payload:{
            success,error
        }
    };
}

export function deleteProjectTimelineStep(evaluationID,stepId,data){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/${evaluationID}/step_timeline/${stepId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startDeleteProjectTimelineStep());
        try{
            let [response,error]    =   await HttpFactory.instance().deleteMethod(url,data);
            dispatch(endDeleteProjectTimelineStep(response,error));
        } catch(e){
            dispatch(endDeleteProjectTimelineStep(null,{
                message:e.message
            }));
            return;
        }
    };
}