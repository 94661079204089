import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Card, CardActions, CardContent } from '@material-ui/core';

import * as Environment from "util/Environment";
import axios from "axios";
import { CommonFn } from "services/commonFn";

import {
    Paper, IconButton, Typography
} from '@material-ui/core';

import SqureButton from "Components/Common/SqureButton";
import ReactSelect from "react-select";
import Image from "Components/Common/image.jsx";
import { showSnackBar } from "redux/snackbar/action";
import Tooltip from "@material-ui/core/Tooltip";
import deleteIcon from "assets/images/delete.svg";
import { withAsyncPaginate } from "react-select-async-paginate";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
import classnames from "classnames";
import { fetchFieldOptions } from "redux/departments/action"

const SelectAsyncPaginate = withAsyncPaginate(ReactSelect);

const connectedProps = (state) => ({
    userProfileMeta: state.departments.userProfileMeta,
    fieldOptions: state.departments.fieldOptions,
});

const connectionActions = {
    fetchFieldOptions: fetchFieldOptions,
    showSnackBar: showSnackBar
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    root: {
        marginBottom: 20
    },
    paper: {
        marginTop: 20,
        padding: 10,
        position: "relative",
        border: "1px solid #f7f7f7"
    },
    addRuleDiv: {
        textAlign: "center",
        marginTop: 50,
    },
    rules: {
        marginTop: 20,
        marginBottom: 20,
    },
    addMore: {
        fontSize: "12px",
        color: "#4175DF",
        cursor: "pointer",
        display: "inline-block",
        marginTop: 10,
    },
    input: {
        minHeight: 38,
        padding: "2px 8px",
        border: "1px solid #cccccc",
        borderRadius: 3,
        fontSize: 15,
        color: "rgb(51, 51, 51)",
        width: "100%",
    },
    operand: {
        minWidth: 87,
    },
    ruleTable: {
        width: "100%",
        "& [class*=MuiSelect-root]": {
            minHeight: 22,
        },
    },
    ruleRightElement: {},
    ruleLeftElement: {},
    condition: {
        marginTop: 8,
        fontSize: 14,
        fontWeight: 500,
    },
    op2: {
        width: "10%",
        "& [class*=MuiInputBase-formControl]": {
            marginRight: 10,
            margin: "0 auto",
            width: "100%",
        },
    },
    op1: {
        width: "10%",
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 10,
        "& [class*=MuiInputBase-formControl]": {
            width: "100%",
            marginRight: 10,
            margin: "0 auto",
        },
    },
    leftOp: {
        width: "30%",
        paddingTop: 10,
    },
    rightOp: {
        width: "30%",
        paddingTop: 10,
        "& [class*=select__menu]": {
            maxHeight: 100,
        },
        "& [class*=react-datepicker-wrapper]": {
            width: "100%",
        },
    },
    noPaddingTop: {
        paddingTop: 0,
    },
    action: {
        width: "20%",
        textAlign: "center",
    },
    alerts: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    ruleHead: {
        fontSize: 16,
        fontWeight: 500,
    },
    bottomActions: {
        bottom: "0",
        padding: theme.spacing(1),
        left: 0,
        right: 0,
        paddingRight: theme.spacing(2),
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px -1px 6px #00000010",
        zIndex: 1000,
        textAlign: "right",
        flex: 1
    },
    head: {
        marginBottom: 20
    },
    title: {
        fontWeight: 500
    }
});

class Update extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            operations: [{
                label: 'in',
                value: 'in'
            }, {
                label: '!in',
                value: '!in'
            }],
            approver: '',
            conditions: []
        }
    }

    componentDidMount() {
        this.setState({
            approver: this.props.approver,
            conditions: this.props.conditions
        })
    }

    componentDidUpdate(prevProps) {

    }

    handleUpdateUserValue(value) {
        let approver = this.state.approver;
        approver.User = value;
        this.setState({
            approver: approver
        })
    }

    getUsersOption = async (inputValue, loadedOptions, additional) => {
        var page = 0;
        if (additional !== undefined && additional.page !== undefined) {
            page = additional.page;
        }
        const api_server = Environment.api_host("USER");
        const timestamp = new Date().getTime();
        if (!inputValue) {
            let appList = [];
            this.props.users?.forEach((item, index) => {
                appList.push({
                    label: item.Name,
                    value: item.ID,
                    id: item.ID,
                    ID: item.ID,
                    Name: item.Name,
                    Email: item.Email,
                })
            })
            return {
                options: appList,
                hasMore: false,
                additional: {
                    page: 0,
                },
            };
        }
        let formData = {
            page: 0,
            pageSize: 10,
            query: inputValue
        };
        let url = `${api_server}/fetch?t=${timestamp}`;
        const response = await axios.post(url, formData, {
            headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
        });
        let apps = response?.data?.Data || [];
        let total = response?.data?.Data?.total || 0;
        apps = apps.map((m) => ({
            label: m.Name,
            value: m.ID,
        }));
        return {
            options: apps,
            hasMore: (page + 1) * 10 < total ? true : false,
            additional: {
                page: page + 1,
            },
        };
    };


    addNewCondition() {
        let conditions = this.state.conditions;
        let obj = {
            Field: "",
            Operation: "",
            Value: "",
        }
        conditions.push(obj)
        this.setState({
            conditions: conditions
        })
    }

    handleUpdateValue(field, res, index) {
        let conditions = this.state.conditions;
        let condition = conditions[index];
        condition[field] = res ? res.value : ''
        if (field === 'Field') {
            condition.Operation = ''
            condition.Value = ''
            this.getFieldOptions(res.value, index)
        }
        this.setState({ conditions: conditions })
    }

    handleUpdateResponse(field, res, index) {
        let conditions = this.state.conditions;
        let condition = conditions[index];
        condition[field] = res
        this.setState({ conditions: conditions })
    }

    getFieldOptions(field, index) {
        if (field) {
            this.setState({
                selectedFieldForOptions: index
            }, () => {
                this.props.fetchFieldOptions(field)
            })
        }
    }

    handleDelete(id, index) {
        let conditions = this.state.conditions;
        conditions.splice(index, 1);
        this.setState({
            conditions: conditions
        })
    }

    saveRule = () => {
        this.setState({
            error: "",
            success: "",
        });

        let approver = this.state.approver;
        if (approver.User === undefined || approver.User === null || approver.User === '') {
            this.props.showSnackBar('Please select approver.', 'error', 3000);
            return;
        }

        let conditions = [];
        let isAdded = false
        if (this.state.conditions) {
            this.state.conditions.forEach(function (con) {
                let obj = {
                    Field: con.Field,
                    Operation: con.Operation,
                    Value: con.Value ? JSON.stringify(con.Value) : '',
                }
                conditions.push(obj)
                isAdded = true
            })
        }
        this.props.addUpdateApprover(approver, isAdded ? conditions : null);
    };

    render() {
        const { classes } = this.props;
        const { operations, conditions, approver } = this.state;
        return (
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <div className={classes.head}>
                        <Typography className={classes.title}>{approver.isNew ? 'Add Approver' : 'Update Approver'} </Typography>
                    </div>
                    <div>
                        <SelectAsyncPaginate
                            isClearable
                            isMulti={false}
                            isSearchable={true}
                            classNamePrefix="select"
                            debounceTimeout={200}
                            placeholder={"Select Approver"}
                            onChange={(e) => {
                                this.handleUpdateUserValue(e)
                            }}
                            value={approver?.User}
                            loadOptions={this.getUsersOption}
                            menuPortalTarget={document.body}
                            menuPlacement={'auto'}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
                        />
                    </div>
                    {conditions && <Paper className={classes.paper}>
                        <Typography style={{ fontWeight: 600 }}>Conditions</Typography>
                        <div className={classes.ruleBody}>
                            {conditions.length === 0 && (
                                <div style={{ margin: 25, textAlign: 'center' }}>
                                    <Tooltip
                                        className={"tooltip"}
                                        placement="top"
                                        title={<span style={{ fontSize: "14px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>Add Condition</span>}
                                        arrow
                                    >
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                this.addNewCondition();
                                            }}
                                        >
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}

                            {conditions.length > 0 && (
                                <div className={classes.rules}>
                                    <table className={classes.ruleTable}>
                                        <tbody>
                                            {conditions.map((rule, k) => {
                                                return (
                                                    <React.Fragment key={k}>
                                                        {rule.error !== null && (
                                                            <tr>
                                                                <td colSpan="4" style={{ width: "100%" }}>
                                                                    {rule.error !== null && <span style={{ color: "red" }}>{rule.error}</span>}
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr className={rule.error === null ? classes.inputSection : classes.inputSection2}>
                                                            <td className={classes.op2}>
                                                                {k !== 0 && <div>AND</div>}
                                                                {k === 0 && <div className={classes.condition}>Condition</div>}
                                                            </td>
                                                            <td className={classnames(classes.inputController, classes.leftOp, rule.error !== null ? classes.noPaddingTop : "")}>
                                                                <ReactSelect
                                                                    isClearable={false}
                                                                    options={this.props.userProfileMeta ? this.props.userProfileMeta.map((itm) => {
                                                                        return { label: itm, value: itm }
                                                                    }) : []}
                                                                    classNamePrefix="select"
                                                                    placeholder={"Select Field"}
                                                                    onChange={(e) => {
                                                                        this.handleUpdateValue('Field', e, k)
                                                                    }}
                                                                    value={rule.Field ? { label: rule.Field, value: rule.Field } : ''}
                                                                    menuPortalTarget={document.body}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            </td>
                                                            <td className={classnames(classes.op1, rule.error !== null ? classes.noPaddingTop : "")}>
                                                                <ReactSelect
                                                                    isClearable={false}
                                                                    options={operations}
                                                                    classNamePrefix="select"
                                                                    placeholder={"Operation"}
                                                                    onChange={(e) => {
                                                                        this.handleUpdateValue('Operation', e, k)
                                                                    }}
                                                                    value={rule.Operation ? { label: rule.Operation, value: rule.Operation } : ''}
                                                                    menuPortalTarget={document.body}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            </td>
                                                            <td className={classnames(classes.ruleRightElement, classes.rightOp, rule.error !== null ? classes.noPaddingTop : "")}>
                                                                <ReactSelect
                                                                    isMulti={true}
                                                                    isClearable={false}
                                                                    options={this.props.fieldOptions ? this.props.fieldOptions.map((itm) => {
                                                                        return { label: itm, value: itm }
                                                                    }) : []}
                                                                    classNamePrefix="select"
                                                                    placeholder={"Select Value"}
                                                                    onChange={(e) => {
                                                                        this.handleUpdateResponse('Value', e, k)
                                                                    }}
                                                                    onFocus={() => {
                                                                        this.getFieldOptions(rule.Field)
                                                                    }}
                                                                    value={rule.Value}
                                                                    menuPortalTarget={document.body}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            </td>
                                                            <td className={classes.action}>
                                                                {conditions.length > 1 && <span style={{ margin: "0 7px" }}>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => {
                                                                            this.handleDelete(rule.ID, k);
                                                                        }}
                                                                    >
                                                                        <Image alt="Delete" src={deleteIcon} />
                                                                    </IconButton>
                                                                </span>}

                                                                <span style={{ margin: "0 2px" }} hidden={k !== conditions.length - 1}>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => {
                                                                            this.addNewCondition();
                                                                        }}
                                                                    >
                                                                        <AddCircleIcon />
                                                                    </IconButton>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </Paper>}
                </CardContent>
                <CardActions>
                    <div className={classes.bottomActions}>
                        <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={(e) => this.props.openInNonEdit()}>
                            Cancel
                        </SqureButton> &nbsp;&nbsp;&nbsp;&nbsp;
                        <SqureButton cls={classes.actionBtn} variant={"contained"} onClick={this.saveRule}>
                            Save
                        </SqureButton>
                    </div>
                </CardActions>
            </Card>
        );
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(Update));
