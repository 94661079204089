
export const currencyOptions1 = [
    {
        "label": "UAE dirhams",
        "value": "AED"
    },
    {
        "label": "Afghan Afghanis",
        "value": "AFN"
    },
    {
        "label": "Albanian lekë",
        "value": "ALL"
    },
    {
        "label": "Armenian drams",
        "value": "AMD"
    },
    {
        "label": "Netherlands Antillean guilders",
        "value": "ANG"
    },
    {
        "label": "Angolan kwanzas",
        "value": "AOA"
    },
    {
        "label": "Argentine pesos",
        "value": "ARS"
    },
    {
        "label": "Australian dollars",
        "value": "AUD"
    },
    {
        "label": "Aruban florin",
        "value": "AWG"
    },
    {
        "label": "Azerbaijani manats",
        "value": "AZN"
    },
    {
        "label": "Bosnia-Herzegovina convertible marks",
        "value": "BAM"
    },
    {
        "label": "Barbadian dollars",
        "value": "BBD"
    },
    {
        "label": "Bangladeshi takas",
        "value": "BDT"
    },
    {
        "label": "Bulgarian leva",
        "value": "BGN"
    },
    {
        "label": "Bahraini dinars",
        "value": "BHD"
    },
    {
        "label": "Burundian francs",
        "value": "BIF"
    },
    {
        "label": "Bermudan dollars",
        "value": "BMD"
    },
    {
        "label": "Brunei dollars",
        "value": "BND"
    },
    {
        "label": "Bolivian bolivianos",
        "value": "BOB"
    },
    {
        "label": "Bolivian mvdols",
        "value": "BOV"
    },
    {
        "label": "Brazilian reals",
        "value": "BRL"
    },
    {
        "label": "Bahamian dollars",
        "value": "BSD"
    },
    {
        "label": "Bhutanese ngultrums",
        "value": "BTN"
    },
    {
        "label": "Botswanan pulas",
        "value": "BWP"
    },
    {
        "label": "Belarusian rubles",
        "value": "BYN"
    },
    {
        "label": "Belize dollars",
        "value": "BZD"
    },
    {
        "label": "Canadian dollars",
        "value": "CAD"
    },
    {
        "label": "Congolese francs",
        "value": "CDF"
    },
    {
        "label": "WIR euros",
        "value": "CHE"
    },
    {
        "label": "Swiss francs",
        "value": "CHF"
    },
    {
        "label": "WIR francs",
        "value": "CHW"
    },
    {
        "label": "Chilean units of account (UF)",
        "value": "CLF"
    },
    {
        "label": "Chilean pesos",
        "value": "CLP"
    },
    {
        "label": "Chinese yuan (offshore)",
        "value": "CNH"
    },
    {
        "label": "Chinese yuan",
        "value": "CNY"
    },
    {
        "label": "Colombian pesos",
        "value": "COP"
    },
    {
        "label": "Colombian real value units",
        "value": "COU"
    },
    {
        "label": "Costa Rican colóns",
        "value": "CRC"
    },
    {
        "label": "Cuban convertible pesos",
        "value": "CUC"
    },
    {
        "label": "Cuban pesos",
        "value": "CUP"
    },
    {
        "label": "Cape Verdean escudos",
        "value": "CVE"
    },
    {
        "label": "Czech korunas",
        "value": "CZK"
    },
    {
        "label": "Djiboutian francs",
        "value": "DJF"
    },
    {
        "label": "Danish kroner",
        "value": "DKK"
    },
    {
        "label": "Dominican pesos",
        "value": "DOP"
    },
    {
        "label": "Algerian dinars",
        "value": "DZD"
    },
    {
        "label": "Egyptian pounds",
        "value": "EGP"
    },
    {
        "label": "Eritrean nakfas",
        "value": "ERN"
    },
    {
        "label": "Ethiopian birrs",
        "value": "ETB"
    },
    {
        "label": "euros",
        "value": "EUR"
    },
    {
        "label": "Fijian dollars",
        "value": "FJD"
    },
    {
        "label": "Falkland Islands pounds",
        "value": "FKP"
    },
    {
        "label": "British pounds",
        "value": "GBP"
    },
    {
        "label": "Georgian laris",
        "value": "GEL"
    },
    {
        "label": "Ghanaian cedis",
        "value": "GHS"
    },
    {
        "label": "Gibraltar pounds",
        "value": "GIP"
    },
    {
        "label": "Gambian dalasis",
        "value": "GMD"
    },
    {
        "label": "Guinean francs",
        "value": "GNF"
    },
    {
        "label": "Guatemalan quetzals",
        "value": "GTQ"
    },
    {
        "label": "Guyanaese dollars",
        "value": "GYD"
    },
    {
        "label": "Hong Kong dollars",
        "value": "HKD"
    },
    {
        "label": "Honduran lempiras",
        "value": "HNL"
    },
    {
        "label": "Croatian kunas",
        "value": "HRK"
    },
    {
        "label": "Haitian gourdes",
        "value": "HTG"
    },
    {
        "label": "Hungarian forints",
        "value": "HUF"
    },
    {
        "label": "Indonesian rupiahs",
        "value": "IDR"
    },
    {
        "label": "Israeli new shekels",
        "value": "ILS"
    },
    {
        "label": "Indian rupees",
        "value": "INR"
    },
    {
        "label": "Iraqi dinars",
        "value": "IQD"
    },
    {
        "label": "Iranian rials",
        "value": "IRR"
    },
    {
        "label": "Icelandic krónur",
        "value": "ISK"
    },
    {
        "label": "Jamaican dollars",
        "value": "JMD"
    },
    {
        "label": "Jordanian dinars",
        "value": "JOD"
    },
    {
        "label": "Japanese yen",
        "value": "JPY"
    },
    {
        "label": "Kenyan shillings",
        "value": "KES"
    },
    {
        "label": "Kyrgystani soms",
        "value": "KGS"
    },
    {
        "label": "Cambodian riels",
        "value": "KHR"
    },
    {
        "label": "Comorian francs",
        "value": "KMF"
    },
    {
        "label": "North Korean won",
        "value": "KPW"
    },
    {
        "label": "South Korean won",
        "value": "KRW"
    },
    {
        "label": "Kuwaiti dinars",
        "value": "KWD"
    },
    {
        "label": "Cayman Islands dollars",
        "value": "KYD"
    },
    {
        "label": "Kazakhstani tenges",
        "value": "KZT"
    },
    {
        "label": "Laotian kips",
        "value": "LAK"
    },
    {
        "label": "Lebanese pounds",
        "value": "LBP"
    },
    {
        "label": "Sri Lankan rupees",
        "value": "LKR"
    },
    {
        "label": "Liberian dollars",
        "value": "LRD"
    },
    {
        "label": "Lesotho lotis",
        "value": "LSL"
    },
    {
        "label": "Libyan dinars",
        "value": "LYD"
    },
    {
        "label": "Moroccan dirhams",
        "value": "MAD"
    },
    {
        "label": "Moldovan lei",
        "value": "MDL"
    },
    {
        "label": "Malagasy ariaries",
        "value": "MGA"
    },
    {
        "label": "Macedonian denari",
        "value": "MKD"
    },
    {
        "label": "Myanmar kyats",
        "value": "MMK"
    },
    {
        "label": "Mongolian tugriks",
        "value": "MNT"
    },
    {
        "label": "Macanese patacas",
        "value": "MOP"
    },
    {
        "label": "Mauritanian ouguiyas",
        "value": "MRO"
    },
    {
        "label": "Mauritian rupees",
        "value": "MUR"
    },
    {
        "label": "Malawian kwachas",
        "value": "MWK"
    },
    {
        "label": "Mexican pesos",
        "value": "MXN"
    },
    {
        "label": "Mexican investment units",
        "value": "MXV"
    },
    {
        "label": "Malaysian ringgits",
        "value": "MYR"
    },
    {
        "label": "Mozambican meticals",
        "value": "MZN"
    },
    {
        "label": "Namibian dollars",
        "value": "NAD"
    },
    {
        "label": "Nigerian nairas",
        "value": "NGN"
    },
    {
        "label": "Nicaraguan córdobas",
        "value": "NIO"
    },
    {
        "label": "Norwegian kroner",
        "value": "NOK"
    },
    {
        "label": "Nepalese rupees",
        "value": "NPR"
    },
    {
        "label": "New Zealand dollars",
        "value": "NZD"
    },
    {
        "label": "Omani rials",
        "value": "OMR"
    },
    {
        "label": "Panamanian balboas",
        "value": "PAB"
    },
    {
        "label": "Peruvian soles",
        "value": "PEN"
    },
    {
        "label": "Papua New Guinean kina",
        "value": "PGK"
    },
    {
        "label": "Philippine pisos",
        "value": "PHP"
    },
    {
        "label": "Pakistani rupees",
        "value": "PKR"
    },
    {
        "label": "Polish zlotys",
        "value": "PLN"
    },
    {
        "label": "Paraguayan guaranis",
        "value": "PYG"
    },
    {
        "label": "Qatari rials",
        "value": "QAR"
    },
    {
        "label": "Romanian lei",
        "value": "RON"
    },
    {
        "label": "Serbian dinars",
        "value": "RSD"
    },
    {
        "label": "Russian rubles",
        "value": "RUB"
    },
    {
        "label": "Rwandan francs",
        "value": "RWF"
    },
    {
        "label": "Saudi riyals",
        "value": "SAR"
    },
    {
        "label": "Solomon Islands dollars",
        "value": "SBD"
    },
    {
        "label": "Seychellois rupees",
        "value": "SCR"
    },
    {
        "label": "Sudanese pounds",
        "value": "SDG"
    },
    {
        "label": "Swedish kronor",
        "value": "SEK"
    },
    {
        "label": "Singapore dollars",
        "value": "SGD"
    },
    {
        "label": "St. Helena pounds",
        "value": "SHP"
    },
    {
        "label": "Sierra Leonean leones",
        "value": "SLL"
    },
    {
        "label": "Somali shillings",
        "value": "SOS"
    },
    {
        "label": "Surinamese dollars",
        "value": "SRD"
    },
    {
        "label": "South Sudanese pounds",
        "value": "SSP"
    },
    {
        "label": "São Tomé & Príncipe dobras (2018)",
        "value": "STN"
    },
    {
        "label": "Syrian pounds",
        "value": "SYP"
    },
    {
        "label": "Swazi emalangeni",
        "value": "SZL"
    },
    {
        "label": "Thai baht",
        "value": "THB"
    },
    {
        "label": "Tajikistani somonis",
        "value": "TJS"
    },
    {
        "label": "Tunisian dinars",
        "value": "TND"
    },
    {
        "label": "Tongan paʻanga",
        "value": "TOP"
    },
    {
        "label": "Turkish Lira",
        "value": "TRY"
    },
    {
        "label": "Trinidad & Tobago dollars",
        "value": "TTD"
    },
    {
        "label": "New Taiwan dollars",
        "value": "TWD"
    },
    {
        "label": "Tanzanian shillings",
        "value": "TZS"
    },
    {
        "label": "Ukrainian hryvnias",
        "value": "UAH"
    },
    {
        "label": "Ugandan shillings",
        "value": "UGX"
    },
    {
        "label": "US dollars",
        "value": "USD"
    },
    {
        "label": "US dollars (next day)",
        "value": "USN"
    },
    {
        "label": "Uruguayan pesos (indexed units)",
        "value": "UYI"
    },
    {
        "label": "Uruguayan pesos",
        "value": "UYU"
    },
    {
        "label": "Uzbekistani som",
        "value": "UZS"
    },
    {
        "label": "Venezuelan bolívars",
        "value": "VEF"
    },
    {
        "label": "Vietnamese dong",
        "value": "VND"
    },
    {
        "label": "Vanuatu vatus",
        "value": "VUV"
    },
    {
        "label": "Samoan tala",
        "value": "WST"
    },
    {
        "label": "Central African CFA francs",
        "value": "XAF"
    },
    {
        "label": "East Caribbean dollars",
        "value": "XCD"
    },
    {
        "label": "West African CFA francs",
        "value": "XOF"
    },
    {
        "label": "CFP francs",
        "value": "XPF"
    },
    {
        "label": "Yemeni rials",
        "value": "YER"
    },
    {
        "label": "South African rand",
        "value": "ZAR"
    },
    {
        "label": "Zambian kwachas",
        "value": "ZMW"
    }
]

export const currencyOptions = [
    {
        "label": "AED",
        "value": "AED"
    },
    {
        "label": "AFN",
        "value": "AFN"
    },
    {
        "label": "ALL",
        "value": "ALL"
    },
    {
        "label": "AMD",
        "value": "AMD"
    },
    {
        "label": "ANG",
        "value": "ANG"
    },
    {
        "label": "AOA",
        "value": "AOA"
    },
    {
        "label": "ARS",
        "value": "ARS"
    },
    {
        "label": "AUD",
        "value": "AUD"
    },
    {
        "label": "AWG",
        "value": "AWG"
    },
    {
        "label": "AZN",
        "value": "AZN"
    },
    {
        "label": "BAM",
        "value": "BAM"
    },
    {
        "label": "BBD",
        "value": "BBD"
    },
    {
        "label": "BDT",
        "value": "BDT"
    },
    {
        "label": "BGN",
        "value": "BGN"
    },
    {
        "label": "BHD",
        "value": "BHD"
    },
    {
        "label": "BIF",
        "value": "BIF"
    },
    {
        "label": "BMD",
        "value": "BMD"
    },
    {
        "label": "BND",
        "value": "BND"
    },
    {
        "label": "BOB",
        "value": "BOB"
    },
    {
        "label": "BOV",
        "value": "BOV"
    },
    {
        "label": "BRL",
        "value": "BRL"
    },
    {
        "label": "BSD",
        "value": "BSD"
    },
    {
        "label": "BTN",
        "value": "BTN"
    },
    {
        "label": "BWP",
        "value": "BWP"
    },
    {
        "label": "BYN",
        "value": "BYN"
    },
    {
        "label": "BZD",
        "value": "BZD"
    },
    {
        "label": "CAD",
        "value": "CAD"
    },
    {
        "label": "CDF",
        "value": "CDF"
    },
    {
        "label": "CHE",
        "value": "CHE"
    },
    {
        "label": "CHF",
        "value": "CHF"
    },
    {
        "label": "CHW",
        "value": "CHW"
    },
    {
        "label": "CLF",
        "value": "CLF"
    },
    {
        "label": "CLP",
        "value": "CLP"
    },
    {
        "label": "CNH",
        "value": "CNH"
    },
    {
        "label": "CNY",
        "value": "CNY"
    },
    {
        "label": "COP",
        "value": "COP"
    },
    {
        "label": "COU",
        "value": "COU"
    },
    {
        "label": "CRC",
        "value": "CRC"
    },
    {
        "label": "CUC",
        "value": "CUC"
    },
    {
        "label": "CUP",
        "value": "CUP"
    },
    {
        "label": "CVE",
        "value": "CVE"
    },
    {
        "label": "CZK",
        "value": "CZK"
    },
    {
        "label": "DJF",
        "value": "DJF"
    },
    {
        "label": "DKK",
        "value": "DKK"
    },
    {
        "label": "DOP",
        "value": "DOP"
    },
    {
        "label": "DZD",
        "value": "DZD"
    },
    {
        "label": "EGP",
        "value": "EGP"
    },
    {
        "label": "ERN",
        "value": "ERN"
    },
    {
        "label": "ETB",
        "value": "ETB"
    },
    {
        "label": "EUR",
        "value": "EUR"
    },
    {
        "label": "FJD",
        "value": "FJD"
    },
    {
        "label": "FKP",
        "value": "FKP"
    },
    {
        "label": "GBP",
        "value": "GBP"
    },
    {
        "label": "GEL",
        "value": "GEL"
    },
    {
        "label": "GHS",
        "value": "GHS"
    },
    {
        "label": "GIP",
        "value": "GIP"
    },
    {
        "label": "GMD",
        "value": "GMD"
    },
    {
        "label": "GNF",
        "value": "GNF"
    },
    {
        "label": "GTQ",
        "value": "GTQ"
    },
    {
        "label": "GYD",
        "value": "GYD"
    },
    {
        "label": "HKD",
        "value": "HKD"
    },
    {
        "label": "HNL",
        "value": "HNL"
    },
    {
        "label": "HRK",
        "value": "HRK"
    },
    {
        "label": "HTG",
        "value": "HTG"
    },
    {
        "label": "HUF",
        "value": "HUF"
    },
    {
        "label": "IDR",
        "value": "IDR"
    },
    {
        "label": "ILS",
        "value": "ILS"
    },
    {
        "label": "INR",
        "value": "INR"
    },
    {
        "label": "IQD",
        "value": "IQD"
    },
    {
        "label": "IRR",
        "value": "IRR"
    },
    {
        "label": "ISK",
        "value": "ISK"
    },
    {
        "label": "JMD",
        "value": "JMD"
    },
    {
        "label": "JOD",
        "value": "JOD"
    },
    {
        "label": "JPY",
        "value": "JPY"
    },
    {
        "label": "KES",
        "value": "KES"
    },
    {
        "label": "KGS",
        "value": "KGS"
    },
    {
        "label": "KHR",
        "value": "KHR"
    },
    {
        "label": "KMF",
        "value": "KMF"
    },
    {
        "label": "KPW",
        "value": "KPW"
    },
    {
        "label": "KRW",
        "value": "KRW"
    },
    {
        "label": "KWD",
        "value": "KWD"
    },
    {
        "label": "KYD",
        "value": "KYD"
    },
    {
        "label": "KZT",
        "value": "KZT"
    },
    {
        "label": "LAK",
        "value": "LAK"
    },
    {
        "label": "LBP",
        "value": "LBP"
    },
    {
        "label": "LKR",
        "value": "LKR"
    },
    {
        "label": "LRD",
        "value": "LRD"
    },
    {
        "label": "LSL",
        "value": "LSL"
    },
    {
        "label": "LYD",
        "value": "LYD"
    },
    {
        "label": "MAD",
        "value": "MAD"
    },
    {
        "label": "MDL",
        "value": "MDL"
    },
    {
        "label": "MGA",
        "value": "MGA"
    },
    {
        "label": "MKD",
        "value": "MKD"
    },
    {
        "label": "MMK",
        "value": "MMK"
    },
    {
        "label": "MNT",
        "value": "MNT"
    },
    {
        "label": "MOP",
        "value": "MOP"
    },
    {
        "label": "MRO",
        "value": "MRO"
    },
    {
        "label": "MUR",
        "value": "MUR"
    },
    {
        "label": "MWK",
        "value": "MWK"
    },
    {
        "label": "MXN",
        "value": "MXN"
    },
    {
        "label": "MXV",
        "value": "MXV"
    },
    {
        "label": "MYR",
        "value": "MYR"
    },
    {
        "label": "MZN",
        "value": "MZN"
    },
    {
        "label": "NAD",
        "value": "NAD"
    },
    {
        "label": "NGN",
        "value": "NGN"
    },
    {
        "label": "NIO",
        "value": "NIO"
    },
    {
        "label": "NOK",
        "value": "NOK"
    },
    {
        "label": "NPR",
        "value": "NPR"
    },
    {
        "label": "NZD",
        "value": "NZD"
    },
    {
        "label": "OMR",
        "value": "OMR"
    },
    {
        "label": "PAB",
        "value": "PAB"
    },
    {
        "label": "PEN",
        "value": "PEN"
    },
    {
        "label": "PGK",
        "value": "PGK"
    },
    {
        "label": "PHP",
        "value": "PHP"
    },
    {
        "label": "PKR",
        "value": "PKR"
    },
    {
        "label": "PLN",
        "value": "PLN"
    },
    {
        "label": "PYG",
        "value": "PYG"
    },
    {
        "label": "QAR",
        "value": "QAR"
    },
    {
        "label": "RON",
        "value": "RON"
    },
    {
        "label": "RSD",
        "value": "RSD"
    },
    {
        "label": "RUB",
        "value": "RUB"
    },
    {
        "label": "RWF",
        "value": "RWF"
    },
    {
        "label": "SAR",
        "value": "SAR"
    },
    {
        "label": "SBD",
        "value": "SBD"
    },
    {
        "label": "SCR",
        "value": "SCR"
    },
    {
        "label": "SDG",
        "value": "SDG"
    },
    {
        "label": "SEK",
        "value": "SEK"
    },
    {
        "label": "SGD",
        "value": "SGD"
    },
    {
        "label": "SHP",
        "value": "SHP"
    },
    {
        "label": "SLL",
        "value": "SLL"
    },
    {
        "label": "SOS",
        "value": "SOS"
    },
    {
        "label": "SRD",
        "value": "SRD"
    },
    {
        "label": "SSP",
        "value": "SSP"
    },
    {
        "label": "STN",
        "value": "STN"
    },
    {
        "label": "SYP",
        "value": "SYP"
    },
    {
        "label": "SZL",
        "value": "SZL"
    },
    {
        "label": "THB",
        "value": "THB"
    },
    {
        "label": "TJS",
        "value": "TJS"
    },
    {
        "label": "TND",
        "value": "TND"
    },
    {
        "label": "TOP",
        "value": "TOP"
    },
    {
        "label": "TRY",
        "value": "TRY"
    },
    {
        "label": "TTD",
        "value": "TTD"
    },
    {
        "label": "TWD",
        "value": "TWD"
    },
    {
        "label": "TZS",
        "value": "TZS"
    },
    {
        "label": "UAH",
        "value": "UAH"
    },
    {
        "label": "UGX",
        "value": "UGX"
    },
    {
        "label": "USD",
        "value": "USD"
    },
    {
        "label": "USN",
        "value": "USN"
    },
    {
        "label": "UYI",
        "value": "UYI"
    },
    {
        "label": "UYU",
        "value": "UYU"
    },
    {
        "label": "UZS",
        "value": "UZS"
    },
    {
        "label": "VEF",
        "value": "VEF"
    },
    {
        "label": "VND",
        "value": "VND"
    },
    {
        "label": "VUV",
        "value": "VUV"
    },
    {
        "label": "WST",
        "value": "WST"
    },
    {
        "label": "XAF",
        "value": "XAF"
    },
    {
        "label": "XCD",
        "value": "XCD"
    },
    {
        "label": "XOF",
        "value": "XOF"
    },
    {
        "label": "XPF",
        "value": "XPF"
    },
    {
        "label": "YER",
        "value": "YER"
    },
    {
        "label": "ZAR",
        "value": "ZAR"
    },
    {
        "label": "ZMW",
        "value": "ZMW"
    }
]

export const currencyNameMap = {
    "UAE dirhams": {
        "symbol": "AED",
        "name_plural": "UAE dirhams",
        "code": "AED",
        "symbol_native": "د.إ.‏",
        "decimal_digits": 2,
        "name": "United Arab Emirates Dirham",
        "rounding": 0
    },
    "Afghan Afghanis": {
        "symbol": "AFN",
        "name_plural": "Afghan Afghanis",
        "code": "AFN",
        "symbol_native": "؋",
        "decimal_digits": 0,
        "name": "Afghan Afghani",
        "rounding": 0
    },
    "Albanian lekë": {
        "symbol": "ALL",
        "name_plural": "Albanian lekë",
        "code": "ALL",
        "symbol_native": "Lekë",
        "decimal_digits": 0,
        "name": "Albanian Lek",
        "rounding": 0
    },
    "Armenian drams": {
        "symbol": "AMD",
        "name_plural": "Armenian drams",
        "code": "AMD",
        "symbol_native": "֏",
        "decimal_digits": 0,
        "name": "Armenian Dram",
        "rounding": 0
    },
    "Netherlands Antillean guilders": {
        "symbol": "ANG",
        "name_plural": "Netherlands Antillean guilders",
        "code": "ANG",
        "symbol_native": "NAf.",
        "decimal_digits": 2,
        "name": "Netherlands Antillean Guilder",
        "rounding": 0
    },
    "Angolan kwanzas": {
        "symbol": "AOA",
        "name_plural": "Angolan kwanzas",
        "code": "AOA",
        "symbol_native": "Kz",
        "decimal_digits": 2,
        "name": "Angolan Kwanza",
        "rounding": 0
    },
    "Argentine pesos": {
        "symbol": "ARS",
        "name_plural": "Argentine pesos",
        "code": "ARS",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Argentine Peso",
        "rounding": 0
    },
    "Australian dollars": {
        "symbol": "A$",
        "name_plural": "Australian dollars",
        "code": "AUD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Australian Dollar",
        "rounding": 0
    },
    "Aruban florin": {
        "symbol": "AWG",
        "name_plural": "Aruban florin",
        "code": "AWG",
        "symbol_native": "Afl.",
        "decimal_digits": 2,
        "name": "Aruban Florin",
        "rounding": 0
    },
    "Azerbaijani manats": {
        "symbol": "AZN",
        "name_plural": "Azerbaijani manats",
        "code": "AZN",
        "symbol_native": "₼",
        "decimal_digits": 2,
        "name": "Azerbaijani Manat",
        "rounding": 0
    },
    "Bosnia-Herzegovina convertible marks": {
        "symbol": "BAM",
        "name_plural": "Bosnia-Herzegovina convertible marks",
        "code": "BAM",
        "symbol_native": "КМ",
        "decimal_digits": 2,
        "name": "Bosnia-Herzegovina Convertible Mark",
        "rounding": 0
    },
    "Barbadian dollars": {
        "symbol": "BBD",
        "name_plural": "Barbadian dollars",
        "code": "BBD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Barbadian Dollar",
        "rounding": 0
    },
    "Bangladeshi takas": {
        "symbol": "BDT",
        "name_plural": "Bangladeshi takas",
        "code": "BDT",
        "symbol_native": "৳",
        "decimal_digits": 2,
        "name": "Bangladeshi Taka",
        "rounding": 0
    },
    "Bulgarian leva": {
        "symbol": "BGN",
        "name_plural": "Bulgarian leva",
        "code": "BGN",
        "symbol_native": "лв.",
        "decimal_digits": 2,
        "name": "Bulgarian Lev",
        "rounding": 0
    },
    "Bahraini dinars": {
        "symbol": "BHD",
        "name_plural": "Bahraini dinars",
        "code": "BHD",
        "symbol_native": "د.ب.‏",
        "decimal_digits": 3,
        "name": "Bahraini Dinar",
        "rounding": 0
    },
    "Burundian francs": {
        "symbol": "BIF",
        "name_plural": "Burundian francs",
        "code": "BIF",
        "symbol_native": "FBu",
        "decimal_digits": 0,
        "name": "Burundian Franc",
        "rounding": 0
    },
    "Bermudan dollars": {
        "symbol": "BMD",
        "name_plural": "Bermudan dollars",
        "code": "BMD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Bermudan Dollar",
        "rounding": 0
    },
    "Brunei dollars": {
        "symbol": "BND",
        "name_plural": "Brunei dollars",
        "code": "BND",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Brunei Dollar",
        "rounding": 0
    },
    "Bolivian bolivianos": {
        "symbol": "BOB",
        "name_plural": "Bolivian bolivianos",
        "code": "BOB",
        "symbol_native": "Bs",
        "decimal_digits": 2,
        "name": "Bolivian Boliviano",
        "rounding": 0
    },
    "Bolivian mvdols": {
        "symbol": "BOV",
        "name_plural": "Bolivian mvdols",
        "code": "BOV",
        "symbol_native": "BOV",
        "decimal_digits": 2,
        "name": "Bolivian Mvdol",
        "rounding": 0
    },
    "Brazilian reals": {
        "symbol": "R$",
        "name_plural": "Brazilian reals",
        "code": "BRL",
        "symbol_native": "R$",
        "decimal_digits": 2,
        "name": "Brazilian Real",
        "rounding": 0
    },
    "Bahamian dollars": {
        "symbol": "BSD",
        "name_plural": "Bahamian dollars",
        "code": "BSD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Bahamian Dollar",
        "rounding": 0
    },
    "Bhutanese ngultrums": {
        "symbol": "BTN",
        "name_plural": "Bhutanese ngultrums",
        "code": "BTN",
        "symbol_native": "Nu.",
        "decimal_digits": 2,
        "name": "Bhutanese Ngultrum",
        "rounding": 0
    },
    "Botswanan pulas": {
        "symbol": "BWP",
        "name_plural": "Botswanan pulas",
        "code": "BWP",
        "symbol_native": "P",
        "decimal_digits": 2,
        "name": "Botswanan Pula",
        "rounding": 0
    },
    "Belarusian rubles": {
        "symbol": "BYN",
        "name_plural": "Belarusian rubles",
        "code": "BYN",
        "symbol_native": "Br",
        "decimal_digits": 2,
        "name": "Belarusian Ruble",
        "rounding": 0
    },
    "Belize dollars": {
        "symbol": "BZD",
        "name_plural": "Belize dollars",
        "code": "BZD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Belize Dollar",
        "rounding": 0
    },
    "Canadian dollars": {
        "symbol": "CA$",
        "name_plural": "Canadian dollars",
        "code": "CAD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Canadian Dollar",
        "rounding": 0
    },
    "Congolese francs": {
        "symbol": "CDF",
        "name_plural": "Congolese francs",
        "code": "CDF",
        "symbol_native": "FC",
        "decimal_digits": 2,
        "name": "Congolese Franc",
        "rounding": 0
    },
    "WIR euros": {
        "symbol": "CHE",
        "name_plural": "WIR euros",
        "code": "CHE",
        "symbol_native": "CHE",
        "decimal_digits": 2,
        "name": "WIR Euro",
        "rounding": 0
    },
    "Swiss francs": {
        "symbol": "CHF",
        "name_plural": "Swiss francs",
        "code": "CHF",
        "symbol_native": "CHF",
        "decimal_digits": 2,
        "name": "Swiss Franc",
        "rounding": 0
    },
    "WIR francs": {
        "symbol": "CHW",
        "name_plural": "WIR francs",
        "code": "CHW",
        "symbol_native": "CHW",
        "decimal_digits": 2,
        "name": "WIR Franc",
        "rounding": 0
    },
    "Chilean units of account (UF)": {
        "symbol": "CLF",
        "name_plural": "Chilean units of account (UF)",
        "code": "CLF",
        "symbol_native": "CLF",
        "decimal_digits": 4,
        "name": "Chilean Unit of Account (UF)",
        "rounding": 0
    },
    "Chilean pesos": {
        "symbol": "CLP",
        "name_plural": "Chilean pesos",
        "code": "CLP",
        "symbol_native": "$",
        "decimal_digits": 0,
        "name": "Chilean Peso",
        "rounding": 0
    },
    "Chinese yuan (offshore)": {
        "symbol": "CNH",
        "name_plural": "Chinese yuan (offshore)",
        "code": "CNH",
        "symbol_native": "CNH",
        "decimal_digits": 2,
        "name": "Chinese Yuan (offshore)",
        "rounding": 0
    },
    "Chinese yuan": {
        "symbol": "CN¥",
        "name_plural": "Chinese yuan",
        "code": "CNY",
        "symbol_native": "¥",
        "decimal_digits": 2,
        "name": "Chinese Yuan",
        "rounding": 0
    },
    "Colombian pesos": {
        "symbol": "COP",
        "name_plural": "Colombian pesos",
        "code": "COP",
        "symbol_native": "$",
        "decimal_digits": 0,
        "name": "Colombian Peso",
        "rounding": 0
    },
    "Colombian real value units": {
        "symbol": "COU",
        "name_plural": "Colombian real value units",
        "code": "COU",
        "symbol_native": "COU",
        "decimal_digits": 2,
        "name": "Colombian Real Value Unit",
        "rounding": 0
    },
    "Costa Rican colóns": {
        "symbol": "CRC",
        "name_plural": "Costa Rican colóns",
        "code": "CRC",
        "symbol_native": "₡",
        "decimal_digits": 2,
        "name": "Costa Rican Colón",
        "rounding": 0
    },
    "Cuban convertible pesos": {
        "symbol": "CUC",
        "name_plural": "Cuban convertible pesos",
        "code": "CUC",
        "symbol_native": "CUC",
        "decimal_digits": 2,
        "name": "Cuban Convertible Peso",
        "rounding": 0
    },
    "Cuban pesos": {
        "symbol": "CUP",
        "name_plural": "Cuban pesos",
        "code": "CUP",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Cuban Peso",
        "rounding": 0
    },
    "Cape Verdean escudos": {
        "symbol": "CVE",
        "name_plural": "Cape Verdean escudos",
        "code": "CVE",
        "symbol_native": "​",
        "decimal_digits": 2,
        "name": "Cape Verdean Escudo",
        "rounding": 0
    },
    "Czech korunas": {
        "symbol": "CZK",
        "name_plural": "Czech korunas",
        "code": "CZK",
        "symbol_native": "Kč",
        "decimal_digits": 2,
        "name": "Czech Koruna",
        "rounding": 0
    },
    "Djiboutian francs": {
        "symbol": "DJF",
        "name_plural": "Djiboutian francs",
        "code": "DJF",
        "symbol_native": "Fdj",
        "decimal_digits": 0,
        "name": "Djiboutian Franc",
        "rounding": 0
    },
    "Danish kroner": {
        "symbol": "DKK",
        "name_plural": "Danish kroner",
        "code": "DKK",
        "symbol_native": "kr.",
        "decimal_digits": 2,
        "name": "Danish Krone",
        "rounding": 0
    },
    "Dominican pesos": {
        "symbol": "DOP",
        "name_plural": "Dominican pesos",
        "code": "DOP",
        "symbol_native": "RD$",
        "decimal_digits": 2,
        "name": "Dominican Peso",
        "rounding": 0
    },
    "Algerian dinars": {
        "symbol": "DZD",
        "name_plural": "Algerian dinars",
        "code": "DZD",
        "symbol_native": "د.ج.‏",
        "decimal_digits": 2,
        "name": "Algerian Dinar",
        "rounding": 0
    },
    "Egyptian pounds": {
        "symbol": "EGP",
        "name_plural": "Egyptian pounds",
        "code": "EGP",
        "symbol_native": "ج.م.‏",
        "decimal_digits": 2,
        "name": "Egyptian Pound",
        "rounding": 0
    },
    "Eritrean nakfas": {
        "symbol": "ERN",
        "name_plural": "Eritrean nakfas",
        "code": "ERN",
        "symbol_native": "Nfk",
        "decimal_digits": 2,
        "name": "Eritrean Nakfa",
        "rounding": 0
    },
    "Ethiopian birrs": {
        "symbol": "ETB",
        "name_plural": "Ethiopian birrs",
        "code": "ETB",
        "symbol_native": "ብር",
        "decimal_digits": 2,
        "name": "Ethiopian Birr",
        "rounding": 0
    },
    "euros": {
        "symbol": "€",
        "name_plural": "euros",
        "code": "EUR",
        "symbol_native": "€",
        "decimal_digits": 2,
        "name": "Euro",
        "rounding": 0
    },
    "Fijian dollars": {
        "symbol": "FJD",
        "name_plural": "Fijian dollars",
        "code": "FJD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Fijian Dollar",
        "rounding": 0
    },
    "Falkland Islands pounds": {
        "symbol": "FKP",
        "name_plural": "Falkland Islands pounds",
        "code": "FKP",
        "symbol_native": "£",
        "decimal_digits": 2,
        "name": "Falkland Islands Pound",
        "rounding": 0
    },
    "British pounds": {
        "symbol": "£",
        "name_plural": "British pounds",
        "code": "GBP",
        "symbol_native": "£",
        "decimal_digits": 2,
        "name": "British Pound",
        "rounding": 0
    },
    "Georgian laris": {
        "symbol": "GEL",
        "name_plural": "Georgian laris",
        "code": "GEL",
        "symbol_native": "₾",
        "decimal_digits": 2,
        "name": "Georgian Lari",
        "rounding": 0
    },
    "Ghanaian cedis": {
        "symbol": "GHS",
        "name_plural": "Ghanaian cedis",
        "code": "GHS",
        "symbol_native": "GH₵",
        "decimal_digits": 2,
        "name": "Ghanaian Cedi",
        "rounding": 0
    },
    "Gibraltar pounds": {
        "symbol": "GIP",
        "name_plural": "Gibraltar pounds",
        "code": "GIP",
        "symbol_native": "£",
        "decimal_digits": 2,
        "name": "Gibraltar Pound",
        "rounding": 0
    },
    "Gambian dalasis": {
        "symbol": "GMD",
        "name_plural": "Gambian dalasis",
        "code": "GMD",
        "symbol_native": "D",
        "decimal_digits": 2,
        "name": "Gambian Dalasi",
        "rounding": 0
    },
    "Guinean francs": {
        "symbol": "GNF",
        "name_plural": "Guinean francs",
        "code": "GNF",
        "symbol_native": "FG",
        "decimal_digits": 0,
        "name": "Guinean Franc",
        "rounding": 0
    },
    "Guatemalan quetzals": {
        "symbol": "GTQ",
        "name_plural": "Guatemalan quetzals",
        "code": "GTQ",
        "symbol_native": "Q",
        "decimal_digits": 2,
        "name": "Guatemalan Quetzal",
        "rounding": 0
    },
    "Guyanaese dollars": {
        "symbol": "GYD",
        "name_plural": "Guyanaese dollars",
        "code": "GYD",
        "symbol_native": "$",
        "decimal_digits": 0,
        "name": "Guyanaese Dollar",
        "rounding": 0
    },
    "Hong Kong dollars": {
        "symbol": "HK$",
        "name_plural": "Hong Kong dollars",
        "code": "HKD",
        "symbol_native": "HK$",
        "decimal_digits": 2,
        "name": "Hong Kong Dollar",
        "rounding": 0
    },
    "Honduran lempiras": {
        "symbol": "HNL",
        "name_plural": "Honduran lempiras",
        "code": "HNL",
        "symbol_native": "L",
        "decimal_digits": 2,
        "name": "Honduran Lempira",
        "rounding": 0
    },
    "Croatian kunas": {
        "symbol": "HRK",
        "name_plural": "Croatian kunas",
        "code": "HRK",
        "symbol_native": "HRK",
        "decimal_digits": 2,
        "name": "Croatian Kuna",
        "rounding": 0
    },
    "Haitian gourdes": {
        "symbol": "HTG",
        "name_plural": "Haitian gourdes",
        "code": "HTG",
        "symbol_native": "G",
        "decimal_digits": 2,
        "name": "Haitian Gourde",
        "rounding": 0
    },
    "Hungarian forints": {
        "symbol": "HUF",
        "name_plural": "Hungarian forints",
        "code": "HUF",
        "symbol_native": "Ft",
        "decimal_digits": 2,
        "name": "Hungarian Forint",
        "rounding": 0
    },
    "Indonesian rupiahs": {
        "symbol": "IDR",
        "name_plural": "Indonesian rupiahs",
        "code": "IDR",
        "symbol_native": "Rp",
        "decimal_digits": 0,
        "name": "Indonesian Rupiah",
        "rounding": 0
    },
    "Israeli new shekels": {
        "symbol": "₪",
        "name_plural": "Israeli new shekels",
        "code": "ILS",
        "symbol_native": "₪",
        "decimal_digits": 2,
        "name": "Israeli New Shekel",
        "rounding": 0
    },
    "Indian rupees": {
        "symbol": "₹",
        "name_plural": "Indian rupees",
        "code": "INR",
        "symbol_native": "₹",
        "decimal_digits": 2,
        "name": "Indian Rupee",
        "rounding": 0
    },
    "Iraqi dinars": {
        "symbol": "IQD",
        "name_plural": "Iraqi dinars",
        "code": "IQD",
        "symbol_native": "د.ع.‏",
        "decimal_digits": 0,
        "name": "Iraqi Dinar",
        "rounding": 0
    },
    "Iranian rials": {
        "symbol": "IRR",
        "name_plural": "Iranian rials",
        "code": "IRR",
        "symbol_native": "IRR",
        "decimal_digits": 0,
        "name": "Iranian Rial",
        "rounding": 0
    },
    "Icelandic krónur": {
        "symbol": "ISK",
        "name_plural": "Icelandic krónur",
        "code": "ISK",
        "symbol_native": "ISK",
        "decimal_digits": 0,
        "name": "Icelandic Króna",
        "rounding": 0
    },
    "Jamaican dollars": {
        "symbol": "JMD",
        "name_plural": "Jamaican dollars",
        "code": "JMD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Jamaican Dollar",
        "rounding": 0
    },
    "Jordanian dinars": {
        "symbol": "JOD",
        "name_plural": "Jordanian dinars",
        "code": "JOD",
        "symbol_native": "د.أ.‏",
        "decimal_digits": 3,
        "name": "Jordanian Dinar",
        "rounding": 0
    },
    "Japanese yen": {
        "symbol": "¥",
        "name_plural": "Japanese yen",
        "code": "JPY",
        "symbol_native": "￥",
        "decimal_digits": 0,
        "name": "Japanese Yen",
        "rounding": 0
    },
    "Kenyan shillings": {
        "symbol": "KES",
        "name_plural": "Kenyan shillings",
        "code": "KES",
        "symbol_native": "Ksh",
        "decimal_digits": 2,
        "name": "Kenyan Shilling",
        "rounding": 0
    },
    "Kyrgystani soms": {
        "symbol": "KGS",
        "name_plural": "Kyrgystani soms",
        "code": "KGS",
        "symbol_native": "сом",
        "decimal_digits": 2,
        "name": "Kyrgystani Som",
        "rounding": 0
    },
    "Cambodian riels": {
        "symbol": "KHR",
        "name_plural": "Cambodian riels",
        "code": "KHR",
        "symbol_native": "៛",
        "decimal_digits": 2,
        "name": "Cambodian Riel",
        "rounding": 0
    },
    "Comorian francs": {
        "symbol": "KMF",
        "name_plural": "Comorian francs",
        "code": "KMF",
        "symbol_native": "CF",
        "decimal_digits": 0,
        "name": "Comorian Franc",
        "rounding": 0
    },
    "North Korean won": {
        "symbol": "KPW",
        "name_plural": "North Korean won",
        "code": "KPW",
        "symbol_native": "KPW",
        "decimal_digits": 0,
        "name": "North Korean Won",
        "rounding": 0
    },
    "South Korean won": {
        "symbol": "₩",
        "name_plural": "South Korean won",
        "code": "KRW",
        "symbol_native": "₩",
        "decimal_digits": 0,
        "name": "South Korean Won",
        "rounding": 0
    },
    "Kuwaiti dinars": {
        "symbol": "KWD",
        "name_plural": "Kuwaiti dinars",
        "code": "KWD",
        "symbol_native": "د.ك.‏",
        "decimal_digits": 3,
        "name": "Kuwaiti Dinar",
        "rounding": 0
    },
    "Cayman Islands dollars": {
        "symbol": "KYD",
        "name_plural": "Cayman Islands dollars",
        "code": "KYD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Cayman Islands Dollar",
        "rounding": 0
    },
    "Kazakhstani tenges": {
        "symbol": "KZT",
        "name_plural": "Kazakhstani tenges",
        "code": "KZT",
        "symbol_native": "₸",
        "decimal_digits": 2,
        "name": "Kazakhstani Tenge",
        "rounding": 0
    },
    "Laotian kips": {
        "symbol": "LAK",
        "name_plural": "Laotian kips",
        "code": "LAK",
        "symbol_native": "₭",
        "decimal_digits": 0,
        "name": "Laotian Kip",
        "rounding": 0
    },
    "Lebanese pounds": {
        "symbol": "LBP",
        "name_plural": "Lebanese pounds",
        "code": "LBP",
        "symbol_native": "ل.ل.‏",
        "decimal_digits": 0,
        "name": "Lebanese Pound",
        "rounding": 0
    },
    "Sri Lankan rupees": {
        "symbol": "LKR",
        "name_plural": "Sri Lankan rupees",
        "code": "LKR",
        "symbol_native": "රු.",
        "decimal_digits": 2,
        "name": "Sri Lankan Rupee",
        "rounding": 0
    },
    "Liberian dollars": {
        "symbol": "LRD",
        "name_plural": "Liberian dollars",
        "code": "LRD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Liberian Dollar",
        "rounding": 0
    },
    "Lesotho lotis": {
        "symbol": "LSL",
        "name_plural": "Lesotho lotis",
        "code": "LSL",
        "symbol_native": "LSL",
        "decimal_digits": 2,
        "name": "Lesotho Loti",
        "rounding": 0
    },
    "Libyan dinars": {
        "symbol": "LYD",
        "name_plural": "Libyan dinars",
        "code": "LYD",
        "symbol_native": "د.ل.‏",
        "decimal_digits": 3,
        "name": "Libyan Dinar",
        "rounding": 0
    },
    "Moroccan dirhams": {
        "symbol": "MAD",
        "name_plural": "Moroccan dirhams",
        "code": "MAD",
        "symbol_native": "د.م.‏",
        "decimal_digits": 2,
        "name": "Moroccan Dirham",
        "rounding": 0
    },
    "Moldovan lei": {
        "symbol": "MDL",
        "name_plural": "Moldovan lei",
        "code": "MDL",
        "symbol_native": "L",
        "decimal_digits": 2,
        "name": "Moldovan Leu",
        "rounding": 0
    },
    "Malagasy ariaries": {
        "symbol": "MGA",
        "name_plural": "Malagasy ariaries",
        "code": "MGA",
        "symbol_native": "Ar",
        "decimal_digits": 0,
        "name": "Malagasy Ariary",
        "rounding": 0
    },
    "Macedonian denari": {
        "symbol": "MKD",
        "name_plural": "Macedonian denari",
        "code": "MKD",
        "symbol_native": "ден",
        "decimal_digits": 2,
        "name": "Macedonian Denar",
        "rounding": 0
    },
    "Myanmar kyats": {
        "symbol": "MMK",
        "name_plural": "Myanmar kyats",
        "code": "MMK",
        "symbol_native": "K",
        "decimal_digits": 0,
        "name": "Myanmar Kyat",
        "rounding": 0
    },
    "Mongolian tugriks": {
        "symbol": "MNT",
        "name_plural": "Mongolian tugriks",
        "code": "MNT",
        "symbol_native": "₮",
        "decimal_digits": 0,
        "name": "Mongolian Tugrik",
        "rounding": 0
    },
    "Macanese patacas": {
        "symbol": "MOP",
        "name_plural": "Macanese patacas",
        "code": "MOP",
        "symbol_native": "MOP$",
        "decimal_digits": 2,
        "name": "Macanese Pataca",
        "rounding": 0
    },
    "Mauritanian ouguiyas": {
        "symbol": "MRO",
        "name_plural": "Mauritanian ouguiyas",
        "code": "MRO",
        "symbol_native": "أ.م.‏",
        "decimal_digits": 0,
        "name": "Mauritanian Ouguiya",
        "rounding": 0
    },
    "Mauritian rupees": {
        "symbol": "MUR",
        "name_plural": "Mauritian rupees",
        "code": "MUR",
        "symbol_native": "Rs",
        "decimal_digits": 0,
        "name": "Mauritian Rupee",
        "rounding": 0
    },
    "Malawian kwachas": {
        "symbol": "MWK",
        "name_plural": "Malawian kwachas",
        "code": "MWK",
        "symbol_native": "MK",
        "decimal_digits": 2,
        "name": "Malawian Kwacha",
        "rounding": 0
    },
    "Mexican pesos": {
        "symbol": "MX$",
        "name_plural": "Mexican pesos",
        "code": "MXN",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Mexican Peso",
        "rounding": 0
    },
    "Mexican investment units": {
        "symbol": "MXV",
        "name_plural": "Mexican investment units",
        "code": "MXV",
        "symbol_native": "MXV",
        "decimal_digits": 2,
        "name": "Mexican Investment Unit",
        "rounding": 0
    },
    "Malaysian ringgits": {
        "symbol": "MYR",
        "name_plural": "Malaysian ringgits",
        "code": "MYR",
        "symbol_native": "RM",
        "decimal_digits": 2,
        "name": "Malaysian Ringgit",
        "rounding": 0
    },
    "Mozambican meticals": {
        "symbol": "MZN",
        "name_plural": "Mozambican meticals",
        "code": "MZN",
        "symbol_native": "MTn",
        "decimal_digits": 2,
        "name": "Mozambican Metical",
        "rounding": 0
    },
    "Namibian dollars": {
        "symbol": "NAD",
        "name_plural": "Namibian dollars",
        "code": "NAD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Namibian Dollar",
        "rounding": 0
    },
    "Nigerian nairas": {
        "symbol": "NGN",
        "name_plural": "Nigerian nairas",
        "code": "NGN",
        "symbol_native": "₦",
        "decimal_digits": 2,
        "name": "Nigerian Naira",
        "rounding": 0
    },
    "Nicaraguan córdobas": {
        "symbol": "NIO",
        "name_plural": "Nicaraguan córdobas",
        "code": "NIO",
        "symbol_native": "C$",
        "decimal_digits": 2,
        "name": "Nicaraguan Córdoba",
        "rounding": 0
    },
    "Norwegian kroner": {
        "symbol": "NOK",
        "name_plural": "Norwegian kroner",
        "code": "NOK",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "name": "Norwegian Krone",
        "rounding": 0
    },
    "Nepalese rupees": {
        "symbol": "NPR",
        "name_plural": "Nepalese rupees",
        "code": "NPR",
        "symbol_native": "नेरू",
        "decimal_digits": 2,
        "name": "Nepalese Rupee",
        "rounding": 0
    },
    "New Zealand dollars": {
        "symbol": "NZ$",
        "name_plural": "New Zealand dollars",
        "code": "NZD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "New Zealand Dollar",
        "rounding": 0
    },
    "Omani rials": {
        "symbol": "OMR",
        "name_plural": "Omani rials",
        "code": "OMR",
        "symbol_native": "ر.ع.‏",
        "decimal_digits": 3,
        "name": "Omani Rial",
        "rounding": 0
    },
    "Panamanian balboas": {
        "symbol": "PAB",
        "name_plural": "Panamanian balboas",
        "code": "PAB",
        "symbol_native": "B/.",
        "decimal_digits": 2,
        "name": "Panamanian Balboa",
        "rounding": 0
    },
    "Peruvian soles": {
        "symbol": "PEN",
        "name_plural": "Peruvian soles",
        "code": "PEN",
        "symbol_native": "S/",
        "decimal_digits": 2,
        "name": "Peruvian Sol",
        "rounding": 0
    },
    "Papua New Guinean kina": {
        "symbol": "PGK",
        "name_plural": "Papua New Guinean kina",
        "code": "PGK",
        "symbol_native": "K",
        "decimal_digits": 2,
        "name": "Papua New Guinean Kina",
        "rounding": 0
    },
    "Philippine pisos": {
        "symbol": "PHP",
        "name_plural": "Philippine pisos",
        "code": "PHP",
        "symbol_native": "₱",
        "decimal_digits": 2,
        "name": "Philippine Piso",
        "rounding": 0
    },
    "Pakistani rupees": {
        "symbol": "PKR",
        "name_plural": "Pakistani rupees",
        "code": "PKR",
        "symbol_native": "Rs",
        "decimal_digits": 0,
        "name": "Pakistani Rupee",
        "rounding": 0
    },
    "Polish zlotys": {
        "symbol": "PLN",
        "name_plural": "Polish zlotys",
        "code": "PLN",
        "symbol_native": "zł",
        "decimal_digits": 2,
        "name": "Polish Zloty",
        "rounding": 0
    },
    "Paraguayan guaranis": {
        "symbol": "PYG",
        "name_plural": "Paraguayan guaranis",
        "code": "PYG",
        "symbol_native": "Gs.",
        "decimal_digits": 0,
        "name": "Paraguayan Guarani",
        "rounding": 0
    },
    "Qatari rials": {
        "symbol": "QAR",
        "name_plural": "Qatari rials",
        "code": "QAR",
        "symbol_native": "ر.ق.‏",
        "decimal_digits": 2,
        "name": "Qatari Rial",
        "rounding": 0
    },
    "Romanian lei": {
        "symbol": "RON",
        "name_plural": "Romanian lei",
        "code": "RON",
        "symbol_native": "RON",
        "decimal_digits": 2,
        "name": "Romanian Leu",
        "rounding": 0
    },
    "Serbian dinars": {
        "symbol": "RSD",
        "name_plural": "Serbian dinars",
        "code": "RSD",
        "symbol_native": "RSD",
        "decimal_digits": 0,
        "name": "Serbian Dinar",
        "rounding": 0
    },
    "Russian rubles": {
        "symbol": "RUB",
        "name_plural": "Russian rubles",
        "code": "RUB",
        "symbol_native": "₽",
        "decimal_digits": 2,
        "name": "Russian Ruble",
        "rounding": 0
    },
    "Rwandan francs": {
        "symbol": "RWF",
        "name_plural": "Rwandan francs",
        "code": "RWF",
        "symbol_native": "RF",
        "decimal_digits": 0,
        "name": "Rwandan Franc",
        "rounding": 0
    },
    "Saudi riyals": {
        "symbol": "SAR",
        "name_plural": "Saudi riyals",
        "code": "SAR",
        "symbol_native": "ر.س.‏",
        "decimal_digits": 2,
        "name": "Saudi Riyal",
        "rounding": 0
    },
    "Solomon Islands dollars": {
        "symbol": "SBD",
        "name_plural": "Solomon Islands dollars",
        "code": "SBD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Solomon Islands Dollar",
        "rounding": 0
    },
    "Seychellois rupees": {
        "symbol": "SCR",
        "name_plural": "Seychellois rupees",
        "code": "SCR",
        "symbol_native": "SR",
        "decimal_digits": 2,
        "name": "Seychellois Rupee",
        "rounding": 0
    },
    "Sudanese pounds": {
        "symbol": "SDG",
        "name_plural": "Sudanese pounds",
        "code": "SDG",
        "symbol_native": "ج.س.",
        "decimal_digits": 2,
        "name": "Sudanese Pound",
        "rounding": 0
    },
    "Swedish kronor": {
        "symbol": "SEK",
        "name_plural": "Swedish kronor",
        "code": "SEK",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "name": "Swedish Krona",
        "rounding": 0
    },
    "Singapore dollars": {
        "symbol": "SGD",
        "name_plural": "Singapore dollars",
        "code": "SGD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Singapore Dollar",
        "rounding": 0
    },
    "St. Helena pounds": {
        "symbol": "SHP",
        "name_plural": "St. Helena pounds",
        "code": "SHP",
        "symbol_native": "£",
        "decimal_digits": 2,
        "name": "St. Helena Pound",
        "rounding": 0
    },
    "Sierra Leonean leones": {
        "symbol": "SLL",
        "name_plural": "Sierra Leonean leones",
        "code": "SLL",
        "symbol_native": "Le",
        "decimal_digits": 0,
        "name": "Sierra Leonean Leone",
        "rounding": 0
    },
    "Somali shillings": {
        "symbol": "SOS",
        "name_plural": "Somali shillings",
        "code": "SOS",
        "symbol_native": "S",
        "decimal_digits": 0,
        "name": "Somali Shilling",
        "rounding": 0
    },
    "Surinamese dollars": {
        "symbol": "SRD",
        "name_plural": "Surinamese dollars",
        "code": "SRD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Surinamese Dollar",
        "rounding": 0
    },
    "South Sudanese pounds": {
        "symbol": "SSP",
        "name_plural": "South Sudanese pounds",
        "code": "SSP",
        "symbol_native": "£",
        "decimal_digits": 2,
        "name": "South Sudanese Pound",
        "rounding": 0
    },
    "São Tomé & Príncipe dobras (2018)": {
        "symbol": "STN",
        "name_plural": "São Tomé & Príncipe dobras (2018)",
        "code": "STN",
        "symbol_native": "STN",
        "decimal_digits": 2,
        "name": "São Tomé & Príncipe Dobra (2018)",
        "rounding": 0
    },
    "Syrian pounds": {
        "symbol": "SYP",
        "name_plural": "Syrian pounds",
        "code": "SYP",
        "symbol_native": "ل.س.‏",
        "decimal_digits": 0,
        "name": "Syrian Pound",
        "rounding": 0
    },
    "Swazi emalangeni": {
        "symbol": "SZL",
        "name_plural": "Swazi emalangeni",
        "code": "SZL",
        "symbol_native": "E",
        "decimal_digits": 2,
        "name": "Swazi Lilangeni",
        "rounding": 0
    },
    "Thai baht": {
        "symbol": "THB",
        "name_plural": "Thai baht",
        "code": "THB",
        "symbol_native": "THB",
        "decimal_digits": 2,
        "name": "Thai Baht",
        "rounding": 0
    },
    "Tajikistani somonis": {
        "symbol": "TJS",
        "name_plural": "Tajikistani somonis",
        "code": "TJS",
        "symbol_native": "сом.",
        "decimal_digits": 2,
        "name": "Tajikistani Somoni",
        "rounding": 0
    },
    "Tunisian dinars": {
        "symbol": "TND",
        "name_plural": "Tunisian dinars",
        "code": "TND",
        "symbol_native": "د.ت.‏",
        "decimal_digits": 3,
        "name": "Tunisian Dinar",
        "rounding": 0
    },
    "Tongan paʻanga": {
        "symbol": "TOP",
        "name_plural": "Tongan paʻanga",
        "code": "TOP",
        "symbol_native": "T$",
        "decimal_digits": 2,
        "name": "Tongan Paʻanga",
        "rounding": 0
    },
    "Turkish Lira": {
        "symbol": "TRY",
        "name_plural": "Turkish Lira",
        "code": "TRY",
        "symbol_native": "₺",
        "decimal_digits": 2,
        "name": "Turkish Lira",
        "rounding": 0
    },
    "Trinidad & Tobago dollars": {
        "symbol": "TTD",
        "name_plural": "Trinidad & Tobago dollars",
        "code": "TTD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Trinidad & Tobago Dollar",
        "rounding": 0
    },
    "New Taiwan dollars": {
        "symbol": "NT$",
        "name_plural": "New Taiwan dollars",
        "code": "TWD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "New Taiwan Dollar",
        "rounding": 0
    },
    "Tanzanian shillings": {
        "symbol": "TZS",
        "name_plural": "Tanzanian shillings",
        "code": "TZS",
        "symbol_native": "TSh",
        "decimal_digits": 0,
        "name": "Tanzanian Shilling",
        "rounding": 0
    },
    "Ukrainian hryvnias": {
        "symbol": "UAH",
        "name_plural": "Ukrainian hryvnias",
        "code": "UAH",
        "symbol_native": "₴",
        "decimal_digits": 2,
        "name": "Ukrainian Hryvnia",
        "rounding": 0
    },
    "Ugandan shillings": {
        "symbol": "UGX",
        "name_plural": "Ugandan shillings",
        "code": "UGX",
        "symbol_native": "USh",
        "decimal_digits": 0,
        "name": "Ugandan Shilling",
        "rounding": 0
    },
    "US dollars": {
        "symbol": "$",
        "name_plural": "US dollars",
        "code": "USD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "US Dollar",
        "rounding": 0
    },
    "US dollars (next day)": {
        "symbol": "USN",
        "name_plural": "US dollars (next day)",
        "code": "USN",
        "symbol_native": "USN",
        "decimal_digits": 2,
        "name": "US Dollar (Next day)",
        "rounding": 0
    },
    "Uruguayan pesos (indexed units)": {
        "symbol": "UYI",
        "name_plural": "Uruguayan pesos (indexed units)",
        "code": "UYI",
        "symbol_native": "UYI",
        "decimal_digits": 0,
        "name": "Uruguayan Peso (Indexed Units)",
        "rounding": 0
    },
    "Uruguayan pesos": {
        "symbol": "UYU",
        "name_plural": "Uruguayan pesos",
        "code": "UYU",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "Uruguayan Peso",
        "rounding": 0
    },
    "Uzbekistani som": {
        "symbol": "UZS",
        "name_plural": "Uzbekistani som",
        "code": "UZS",
        "symbol_native": "сўм",
        "decimal_digits": 0,
        "name": "Uzbekistani Som",
        "rounding": 0
    },
    "Venezuelan bolívars": {
        "symbol": "VEF",
        "name_plural": "Venezuelan bolívars",
        "code": "VEF",
        "symbol_native": "Bs.",
        "decimal_digits": 2,
        "name": "Venezuelan Bolívar",
        "rounding": 0
    },
    "Vietnamese dong": {
        "symbol": "₫",
        "name_plural": "Vietnamese dong",
        "code": "VND",
        "symbol_native": "₫",
        "decimal_digits": 0,
        "name": "Vietnamese Dong",
        "rounding": 0
    },
    "Vanuatu vatus": {
        "symbol": "VUV",
        "name_plural": "Vanuatu vatus",
        "code": "VUV",
        "symbol_native": "VT",
        "decimal_digits": 0,
        "name": "Vanuatu Vatu",
        "rounding": 0
    },
    "Samoan tala": {
        "symbol": "WST",
        "name_plural": "Samoan tala",
        "code": "WST",
        "symbol_native": "WS$",
        "decimal_digits": 2,
        "name": "Samoan Tala",
        "rounding": 0
    },
    "Central African CFA francs": {
        "symbol": "FCFA",
        "name_plural": "Central African CFA francs",
        "code": "XAF",
        "symbol_native": "FCFA",
        "decimal_digits": 0,
        "name": "Central African CFA Franc",
        "rounding": 0
    },
    "East Caribbean dollars": {
        "symbol": "EC$",
        "name_plural": "East Caribbean dollars",
        "code": "XCD",
        "symbol_native": "$",
        "decimal_digits": 2,
        "name": "East Caribbean Dollar",
        "rounding": 0
    },
    "West African CFA francs": {
        "symbol": "CFA",
        "name_plural": "West African CFA francs",
        "code": "XOF",
        "symbol_native": "CFA",
        "decimal_digits": 0,
        "name": "West African CFA Franc",
        "rounding": 0
    },
    "CFP francs": {
        "symbol": "CFPF",
        "name_plural": "CFP francs",
        "code": "XPF",
        "symbol_native": "FCFP",
        "decimal_digits": 0,
        "name": "CFP Franc",
        "rounding": 0
    },
    "Yemeni rials": {
        "symbol": "YER",
        "name_plural": "Yemeni rials",
        "code": "YER",
        "symbol_native": "ر.ي.‏",
        "decimal_digits": 0,
        "name": "Yemeni Rial",
        "rounding": 0
    },
    "South African rand": {
        "symbol": "ZAR",
        "name_plural": "South African rand",
        "code": "ZAR",
        "symbol_native": "R",
        "decimal_digits": 2,
        "name": "South African Rand",
        "rounding": 0
    },
    "Zambian kwachas": {
        "symbol": "ZMW",
        "name_plural": "Zambian kwachas",
        "code": "ZMW",
        "symbol_native": "K",
        "decimal_digits": 2,
        "name": "Zambian Kwacha",
        "rounding": 0
    }
}

export const currencyCodeMap = {
    "AED": "د.إ.‏",
    "AFN": "؋",
    "ALL": "Lekë",
    "AMD": "֏",
    "ANG": "NAf.",
    "AOA": "Kz",
    "ARS": "$",
    "AUD": "$",
    "AWG": "Afl.",
    "AZN": "₼",
    "BAM": "КМ",
    "BBD": "$",
    "BDT": "৳",
    "BGN": "лв.",
    "BHD": "د.ب.‏",
    "BIF": "FBu",
    "BMD": "$",
    "BND": "$",
    "BOB": "Bs",
    "BOV": "BOV",
    "BRL": "R$",
    "BSD": "$",
    "BTN": "Nu.",
    "BWP": "P",
    "BYN": "Br",
    "BZD": "$",
    "CAD": "$",
    "CDF": "FC",
    "CHE": "CHE",
    "CHF": "CHF",
    "CHW": "CHW",
    "CLF": "CLF",
    "CLP": "$",
    "CNH": "CNH",
    "CNY": "¥",
    "COP": "$",
    "COU": "COU",
    "CRC": "₡",
    "CUC": "CUC",
    "CUP": "$",
    "CVE": "​",
    "CZK": "Kč",
    "DJF": "Fdj",
    "DKK": "kr.",
    "DOP": "RD$",
    "DZD": "د.ج.‏",
    "EGP": "ج.م.‏",
    "ERN": "Nfk",
    "ETB": "ብር",
    "EUR": "€",
    "FJD": "$",
    "FKP": "£",
    "GBP": "£",
    "GEL": "₾",
    "GHS": "GH₵",
    "GIP": "£",
    "GMD": "D",
    "GNF": "FG",
    "GTQ": "Q",
    "GYD": "$",
    "HKD": "HK$",
    "HNL": "L",
    "HRK": "HRK",
    "HTG": "G",
    "HUF": "Ft",
    "IDR": "Rp",
    "ILS": "₪",
    "INR": "₹",
    "IQD": "د.ع.‏",
    "IRR": "IRR",
    "ISK": "ISK",
    "JMD": "$",
    "JOD": "د.أ.‏",
    "JPY": "￥",
    "KES": "Ksh",
    "KGS": "сом",
    "KHR": "៛",
    "KMF": "CF",
    "KPW": "KPW",
    "KRW": "₩",
    "KWD": "د.ك.‏",
    "KYD": "$",
    "KZT": "₸",
    "LAK": "₭",
    "LBP": "ل.ل.‏",
    "LKR": "රු.",
    "LRD": "$",
    "LSL": "LSL",
    "LYD": "د.ل.‏",
    "MAD": "د.م.‏",
    "MDL": "L",
    "MGA": "Ar",
    "MKD": "ден",
    "MMK": "K",
    "MNT": "₮",
    "MOP": "MOP$",
    "MRO": "أ.م.‏",
    "MUR": "Rs",
    "MWK": "MK",
    "MXN": "$",
    "MXV": "MXV",
    "MYR": "RM",
    "MZN": "MTn",
    "NAD": "$",
    "NGN": "₦",
    "NIO": "C$",
    "NOK": "kr",
    "NPR": "नेरू",
    "NZD": "$",
    "OMR": "ر.ع.‏",
    "PAB": "B/.",
    "PEN": "S/",
    "PGK": "K",
    "PHP": "₱",
    "PKR": "Rs",
    "PLN": "zł",
    "PYG": "Gs.",
    "QAR": "ر.ق.‏",
    "RON": "RON",
    "RSD": "RSD",
    "RUB": "₽",
    "RWF": "RF",
    "SAR": "ر.س.‏",
    "SBD": "$",
    "SCR": "SR",
    "SDG": "ج.س.",
    "SEK": "kr",
    "SGD": "$",
    "SHP": "£",
    "SLL": "Le",
    "SOS": "S",
    "SRD": "$",
    "SSP": "£",
    "STN": "STN",
    "SYP": "ل.س.‏",
    "SZL": "E",
    "THB": "THB",
    "TJS": "сом.",
    "TND": "د.ت.‏",
    "TOP": "T$",
    "TRY": "₺",
    "TTD": "$",
    "TWD": "$",
    "TZS": "TSh",
    "UAH": "₴",
    "UGX": "USh",
    "USD": "$",
    "USN": "USN",
    "UYI": "UYI",
    "UYU": "$",
    "UZS": "сўм",
    "VEF": "Bs.",
    "VND": "₫",
    "VUV": "VT",
    "WST": "WS$",
    "XAF": "FCFA",
    "XCD": "$",
    "XOF": "CFA",
    "XPF": "FCFP",
    "YER": "ر.ي.‏",
    "ZAR": "R",
    "ZMW": "K"
}