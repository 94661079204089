import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Button, Slide, Typography, Grid, CircularProgress, FormControlLabel,Divider } from '@material-ui/core';
import { withRouter } from 'react-router';
import Checkbox from '@material-ui/core/Checkbox';

import { fetchSellerDraftResponses, updateSellerDraftResponsesStatus, hideSubmitAllDraftResponcesDialog } from "redux/seller/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpenSubmitAllDialog: state.seller.submitAllSellerResponseDialogOpen,
    fetchSellerDraftResponsesProgress: state.seller.fetchSellerDraftResponsesProgress,
    sellerDraftResponses: state.seller.sellerDraftResponses,
    updateSellerDraftResponsesProgress: state.seller.updateSellerDraftResponsesProgress,
    evaluation: state.seller.evaluation,
});

const connectionActions = {
    hideSubmitAllDraftResponcesDialog: hideSubmitAllDraftResponcesDialog,
    fetchSellerDraftResponses: fetchSellerDraftResponses,
    updateSellerDraftResponsesStatus: updateSellerDraftResponsesStatus
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important"
    },
    formControl: {
        marginBottom: theme.spacing(2),
        "& [class*='MuiSelect-outlined-']": {
            color: '#5F5F5F !important',
        },
        '& .select__value-container': {
            padding: '12px',
            '& .select__single-value, .select__placeholder': {
                color: '#5F5F5F',
                fontSize: '17px'
            }
        }
    },
    inputLabel: {
        marginBottom: theme.spacing(1)
    },
    "formAction": {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: "center",
    },
    title: {
        fontSize: theme.spacing(2.5),
        marginBottom: theme.spacing(2)
    },
    alert: {
        marginBottom: theme.spacing(2)
    },
    loader: {
        textAlign: 'center'
    },
    editEmail: {
        "& [class*='MuiOutlinedInput-root'] *": {
            border: 'none'
        },
        "& > div": {
            marginBottom: 0
        },
        "& [class*='MuiOutlinedInput-input']": {
            padding: 0
        },
        "& [class*='MuiOutlinedInput-inputRoot']": {
            minHeight: 30
        },
    },
    sender: {
        width: '100%',
        // marginBottom: 20,
        // border: '1px solid #f1f1f1',
        borderRadius: 4,
        // padding:theme.spacing(2),
        '& .messageHeader': {
            display: 'flex',
            fontSize: 14,
            // textTransform: 'capitalize',
            '& span': {
                marginLeft: '10px'
            }
        },
        '& .messageContainer': {
            background: '#4b86f7 0% 0% no-repeat padding-box',
            border: '1px solid #D5D8DC',
            maxWidth: '100%',
            textAlign: 'left',
            fontSize: '14px',
            letterSpacing: '0px',
            color: '#ffffff',
            borderRadius: '18px !important',
            // 'border-top-left-radius': '0 !important',
            padding: '0px 16px',
            minWidth: 320
        }
    },
    markAsCompleteDiv:{
        marginBottom:20,
        marginTop:20,
    }
});

class SubmitAllResponsesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requirementIdMap : {},
            checkedRequirements: [],
            markAsDone: false
        }
        this.checkUncheckRequirtment = this.checkUncheckRequirtment.bind(this);
        this.submitDraftResponses = this.submitDraftResponses.bind(this);
    }

    componentWillMount() {
        this.props.fetchSellerDraftResponses(this.props.match.params.uuid, this.props.match.params.org_id);
        this.initReqMap();
    }

    initReqMap() {
        var requirementIdMap = {};
        var allCriterias = this.props.evaluation?.criterias !== undefined ? [...this.props.evaluation?.criterias] : [];
        allCriterias.forEach(function (criteria) {
            criteria.requirements.forEach(function (req) {
                requirementIdMap[req.id] = req;
            })
        });
        this.setState({
            requirementIdMap: requirementIdMap,
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.fetchSellerDraftResponsesProgress === false && prevProps.fetchSellerDraftResponsesProgress === true) {
            var ids = this.props.sellerDraftResponses.map(o => o.ID);
            this.setState({
                checkedRequirements: ids
            })
        }
    }

    checkUncheckRequirtment(reqId) {
        var checkedItems = [...this.state.checkedRequirements];
        if (checkedItems.indexOf(reqId) !== -1) {
            checkedItems.splice(checkedItems.indexOf(reqId), 1);
        } else {
            checkedItems.push(reqId)
        }
        this.setState({
            checkedRequirements: checkedItems
        })
    }

    submitDraftResponses(){
        this.props.updateSellerDraftResponsesStatus(this.props.match.params.uuid, this.props.match.params.org_id,{
            response_ids: this.state.checkedRequirements,
            is_done: this.state.markAsDone
        })
    }

    render() {
        const classes = this.props.classes;
        var requirementIdMap = this.state.requirementIdMap;

        return <Dialog
            onClose={this.props.hideSubmitAllDraftResponcesDialog}
            aria-labelledby="add-teamDialog"
            open={this.props.isOpenSubmitAllDialog}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll={"body"}
            id={"SubmitAllResponsesDialog"}
        >

            <DialogContent classes={{ root: classes.dialogContent }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={6}>
                <Typography variant={"h6"} className={classes.title}>Draft Responses</Typography>
                    </Grid>
                    <Grid item xs={6} lg={6} style={{ textAlign: 'right' }}>
                
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    {this.props.fetchSellerDraftResponsesProgress === true && <Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </Grid>}

                    {this.props.sellerDraftResponses !== undefined && this.props.sellerDraftResponses.length > 0 && <Grid item xs={12} lg={12}>
                        {this.props.sellerDraftResponses.map((itm) => {
                            return <div className={classes.sender}>
                                <span><Checkbox
                                    className={classes.criteriaAllSelect}
                                    color="primary"
                                    checked={this.state.checkedRequirements.indexOf(itm.ID) !== -1 ? true : false}
                                    onChange={() => {
                                        this.checkUncheckRequirtment(itm.ID)
                                    }}
                                />
                                    <span>{requirementIdMap[itm.RequirementID] !== undefined ? requirementIdMap[itm.RequirementID].name : ''}</span>
                                    {/* <span><b>Requirement:</b> {requirementIdMap[itm.RequirementID] !== undefined ? requirementIdMap[itm.RequirementID].name : ''}</span> */}
                                </span>
                                {/* <div className={'messageContainer'}>
                                    <SlateReadonlyField initValue={itm.Note} />
                                </div> */}
                            </div>
                        })}
                    </Grid>}
                    {this.props.fetchSellerDraftResponsesProgress === false && this.props.sellerDraftResponses !== undefined && this.props.sellerDraftResponses.length <= 0 && <Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
                        <p>No Draft Responses</p>
                    </Grid>}

                </Grid>

                <div className={classes.formAction}>
                    <Divider />
                    <div className={classes.markAsCompleteDiv}>
                        <FormControlLabel control={
                            <Checkbox
                                className={classes.checkBox}
                                checked={this.state.markAsDone}
                                color="default"
                                onChange={(event) => {
                                    this.setState({ markAsDone: event.target.checked })

                                }}
                                name="mark_as_complete"
                            />
                        }
                            label="Mark as Complete"
                        />
                    </div>
                    <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideSubmitAllDraftResponcesDialog}>Cancel</Button>
                    &nbsp;
                    &nbsp;
                    <Button variant="contained" color="secondary" className={classes.button} onClick={this.submitDraftResponses}>Finalize</Button>
                </div>
            </DialogContent>
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(SubmitAllResponsesDialog));