import {
  START_APP_CATALOG_PRODUCT_SEARCH, END_APP_CATALOG_PRODUCT_SEARCH,
  START_FETCH_APP_CATALOG_FIELDS, END_FETCH_APP_CATALOG_FIELDS,
  START_SET_APP_CATALOG_FIELD_HEADERS,END_SET_APP_CATALOG_FIELD_HEADERS,
  SHOW_APP_CATALOG_FIELDS_DIALOG, HIDE_APP_CATALOG_FIELDS_DIALOG
} from "./action";

const initState = {
  
  showDialog: false,

  fetchAppCatalogProductsProgress: false,
  fetchAppCatalogProductsError: null,
  products: {},

  fetchAppCatalogFieldsProgress: false,
  fetchAppCatalogFieldsError: null,
  appcatalogFields: [],

  setAppCatalogFieldsHeadersProgress: false,
  setAppCatalogFieldsHeadersError: null,
}

export function appCatalogReducer(state = initState, action) {
  switch (action.type) {

    case SHOW_APP_CATALOG_FIELDS_DIALOG:
      return {
        ...state,
        showDialog: true
      };

    case HIDE_APP_CATALOG_FIELDS_DIALOG: {
      return {
        ...state,
        showDialog: false
      }
    }

    case START_APP_CATALOG_PRODUCT_SEARCH:
      var products = state.products;
      // if (!action.payload.searchQuery?.qtype_page || action.payload?.searchQuery?.qtype_page[1] === 0) {
      //   products = {}
      // }
      return {
        ...state,
        fetchAppCatalogProductsProgress: true,
        fetchAppCatalogProductsError:null,
        products: products
      };

    case END_APP_CATALOG_PRODUCT_SEARCH: {
      let success = action.payload.success;
      let error = action.payload.error;
      let isTabular = action.payload.isTabular;
      let currentProducts = {...state.products};
      let newProducts = success?.body;
      if(!error){
        //append data if scrolling feature is available
        if(!isTabular && action.payload.searchQuery?.qtype_page && action.payload.searchQuery?.qtype_page[1]>0){

          if(newProducts?.existing?.items){
            currentProducts?.existing?.items.push(...newProducts?.existing?.items);
          }
          if(newProducts?.featured?.items){
            currentProducts?.featured?.items.push(...newProducts?.featured?.items);
          }
          if(newProducts?.groups?.items){
            currentProducts?.groups?.items.push(...newProducts?.groups?.items);
          }
          if(newProducts?.others?.items){
            currentProducts?.others?.items.push(...newProducts?.others?.items);
          }
        }else{ 
          currentProducts = newProducts;
        }
      }
      
      return {
        ...state,
        fetchAppCatalogProductsProgress: false,
        fetchAppCatalogProductsError:action.payload.error,
        products: currentProducts,
      }
    }

    case START_FETCH_APP_CATALOG_FIELDS:
      return {
        ...state,
        fetchAppCatalogFieldsProgress: true,
        fetchAppCatalogFieldsError: null,
      };

    case END_FETCH_APP_CATALOG_FIELDS: {
      return {
        ...state,
        fetchAppCatalogFieldsProgress: false,
        fetchAppCatalogFieldsError: action.payload.error,
        appcatalogFields: action.payload.success?.body,
      }
    }
    case START_SET_APP_CATALOG_FIELD_HEADERS:
      return {
        ...state,
        setAppCatalogFieldsHeadersProgress: true,
        setAppCatalogFieldsHeadersError: null,
      };

    case END_SET_APP_CATALOG_FIELD_HEADERS: {
      return {
        ...state,
        setAppCatalogFieldsHeadersProgress: false,
        setAppCatalogFieldsHeadersError: action.payload.error,
      }
    }
    default:
      return state;
  }
}