import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import CommonCss from "commonCss";
import { CircularProgress, Grid } from "@material-ui/core";
import CustomSection from "../../Components/CustomSection";
import DefaultSection from "../../Components/DefaultSection";
import { showSnackBar } from "redux/snackbar/action";
import { fetchRecommendedProducts } from "redux/product/action";
import { fetchAllServices } from "redux/vendor/service/action";
import CollaboratorPopper from "../../Components/Common/CollaboratorPopper";

const connectedProps = (state) => ({
  orgProductInprogressReviews: state.orgProduct.orgProductInprogressReviews,
  orgProductCompletedReviews: state.orgProduct.orgProductCompletedReviews,
  fetchVendorCatalogTabDataProgress: state.vendorCatalog.fetchVendorCatalogTabDataProgress,
});

const connectionActions = {
  showSnackBar,
  fetchRecommendedProducts,
  fetchAllServices,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
    },
    ...CommonCss.ProductDetails,
    formGroup: {
      marginTop: 5,
    },
    editIcon: {
      color: "#4A87F8",
      verticalAlign: "middle",
      marginLeft: 16,
      marginBottom: 5,
      cursor: "pointer",
    },
    creatable: {
      padding: 0,
    },
    addReviewBtn: {
      textAlign: "right",
    },
    bottomHead: {
      ...CommonCss.ProductDetails.bottomHead,
      marginBottom: 0,
    },
    evaluateCard: {
      ...CommonCss.Home.evaluateCard,
      padding: "24px 24px",
      border: "1px solid #ddd",
      position: "relative",
    },
    evaluateType: {
      borderRadius: "5px",
      position: "absolute",
      textAlign: "left",
      fontSize: "11px",
      padding: "3px 10px",
      right: "0px",
      top: "0px",
    },
    isEvaluate: {
      background: "#E6FFF3",
      color: "#2EB77D",
    },
    isReview: {
      background: "#cce5ff",
      color: "#004085",
    },
    itemSection: {
      border: "1px solid #d8d8d8",
      padding: 17,
      borderRadius: 5,
      background: "#FFF",
      marginBottom: 17,
      position: "relative",
    },
    head: {
      color: "#6C6C6C",
      fontSize: 18,
      opacity: 1,
      fontWeight: 600,
      margin: 0,
      marginBottom: 11,
    },
  });

class Section extends Component {
  state = {
  
  };

  componentDidMount() {
    this.props.fetchRecommendedProducts(0, {
      feature_ids: [],
      num_users: "",
      page: 0,
      pageSize: 1000,
      query: '',
      type: 2,
    });
    this.props.fetchAllServices({ query: "", page: 0, page_size: 1000, filters: {} });
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    const { classes, role, currentTab, catalogType, appDetails, sections } = this.props;
    let userRole = ["OrgAdmin","OrgManager"];
    const adminFlag = userRole.indexOf(role) > -1;

    const externalUserFlag = role === "OrgExternal";

    if (!currentTab || !appDetails) {
      return null;
    }
    if (this.props.fetchVendorCatalogTabDataProgress){
      return (
        <Grid container alignItems="center" justify="center" style={{ height: "50vh" }}>
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <>
        {currentTab && currentTab !== null && currentTab?.id && (
          <div>
            {sections &&
              sections.length > 0 &&
              sections.map((section) => (
                <div className={classes.itemSection} id={section.slug} key={section.id}>
                  {adminFlag && <CollaboratorPopper appId={appDetails.id} sectionId={section.id} selectedUsers={section.ExternalUsers}/>}
                  <p className={classes.head}>
                    {section.name}
                  </p>
                  {section.is_default ? (
                    <DefaultSection
                      appDetails={appDetails}
                      section={section} 
                      hasUpdateAccess={adminFlag || (externalUserFlag && section.is_editable)}
                      catalogType={catalogType}
                    />
                  ) : (
                  <CustomSection
                    catalogType={catalogType}
                    section={section} 
                    hasUpdateAccess={adminFlag || (externalUserFlag && section.is_editable)} 
                    appId={appDetails.id}
                    appDetails={appDetails}
                    />
                  )}
                </div>
              ))}
          </div>
        )}
      </>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(Section));
