import {
  START_ADD_WRAPPER_WORKFLOW, END_ADD_WRAPPER_WORKFLOW,
  START_UPDATE_WRAPPER_WORKFLOW, END_UPDATE_WRAPPER_WORKFLOW,
  START_GET_WRAPPER_WORKFLOW, END_GET_WRAPPER_WORKFLOW,
  START_GET_BY_ID_WRAPPER_WORKFLOW, END_GET_BY_ID_WRAPPER_WORKFLOW,
  START_DELETE_WRAPPER_WORKFLOW, END_DELETE_WRAPPER_WORKFLOW,

  START_ADD_WRAPPER_WORKFLOW_COMPONENT, END_ADD_WRAPPER_WORKFLOW_COMPONENT,
  START_UPDATE_WRAPPER_WORKFLOW_COMPONENT, END_UPDATE_WRAPPER_WORKFLOW_COMPONENT,
  START_GET_WRAPPER_WORKFLOW_COMPONENT, END_GET_WRAPPER_WORKFLOW_COMPONENT,
  START_DELETE_WRAPPER_WORKFLOW_COMPONENT, END_DELETE_WRAPPER_WORKFLOW_COMPONENT,
  START_ADD_WRAPPER_WORKFLOW_ACTION,
  END_ADD_WRAPPER_WORKFLOW_ACTION,
  START_UPDATE_WRAPPER_WORKFLOW_ACTION,
  END_UPDATE_WRAPPER_WORKFLOW_ACTION,
  START_FETCH_WRAPPER_WORKFLOW_ACTION,
  END_FETCH_WRAPPER_WORKFLOW_ACTION,
  START_DELETE_WRAPPER_WORKFLOW_ACTION,
  END_DELETE_WRAPPER_WORKFLOW_ACTION,
  SHOW_MASTER_COMPONENT_DIALOG, HIDE_MASTER_COMPONENT_DIALOG,
  START_GET_WRAPPER_WORKFLOW_COMPONENT_DETAILS, END_GET_WRAPPER_WORKFLOW_COMPONENT_DETAILS,
  START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_BY_ID, END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_BY_ID,
  START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM, END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM,
  START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS, END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS,
  START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS, END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS,
  START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS, END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS,
  START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION, END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION,
  START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION, END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION,
  START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTIONS, END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTIONS,
  START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION, END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION,
  START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM, END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
  START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM, END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
  START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM, END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM,
  START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS, END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS,
  START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_SEQ, END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_SEQ,
  SHOW_WRAPPER_COMPONENT_DIALOG, HIDE_WRAPPER_COMPONENT_DIALOG,
  SHOW_WRAPPER_MASTER_COMPONENT_DIALOG,HIDE_WRAPPER_MASTER_COMPONENT_DIALOG
} from "./action";

const initState = {
  addOrgWorkFlowProgress: false,
  addOrgWorkFlowSuccess: null,
  addOrgWorkFlowError: null,
  updateOrgWorkFlowProgress: false,
  updateOrgWorkFlowSuccess: null,
  updateOrgWorkFlowError: null,
  orgWorkFlowProgress: false,
  orgWorkFlowList: null,
  orgWorkFlowError: null,
  orgWorkFlowByIdProgress: false,
  orgWorkFlowByIdList: null,
  orgWorkFlowByIdError: null,
  deleteOrgWorkFlowProgress: false,
  deleteOrgWorkFlowSuccess: null,
  deleteOrgWorkFlowError: null,

  addWrapperWorkFlowCompProgress: false,
  addWrapperWorkFlowCompSuccess: null,
  addWrapperWorkFlowCompError: null,
  updateWrapperWorkFlowCompProgress: false,
  updateWrapperWorkFlowCompSuccess: null,
  updateWrapperWorkFlowCompError: null,
  orgWorkFlowCompProgress: false,
  orgWorkFlowCompList: null,
  orgWorkFlowCompError: null,
  deleteWrapperWorkFlowCompProgress: false,
  deleteOrgWorkFlowCompSuccess: null,
  deleteOrgWorkFlowCompError: null,

  addOrgWorkFlowActionProgress: false,
  addOrgWorkFlowActionSuccess: null,
  addOrgWorkFlowActionError: null,
  updateOrgWorkFlowActionProgress: false,
  updateOrgWorkFlowActionSuccess: null,
  updateOrgWorkFlowActionError: null,
  orgWorkFlowActionProgress: false,
  orgWorkFlowActionList: null,
  orgWorkFlowActionError: null,
  deleteOrgWorkFlowActionProgress: false,
  deleteOrgWorkFlowActionSuccess: null,
  deleteOrgWorkFlowActionError: null,

  isMasterComponentDialogOpen: false,

  orgWorkFlowCompDetailsProgress: false,
  orgWorkFlowCompDetails: null,
  orgWorkFlowCompDetailsError: null,

  addWorkflowCustomFormProgress: false,
  updateWorkflowCustomFormProgress: false,
  deleteWorkflowCustomFormProgress: false,
  startFetchCustomFormDataProgress: false,
  fetchCustomFormProgress: false,

  workflowCustomForm: null,
  workflowCustomFormData: null,

  addCustomFormSectionProgress: false,
  fetchCustomFormSectionsProgress: false,
  updateCustomFormSectionProgress: false,
  deletCustomFormSectionProgress: false,
  addCustomFormSectionItemProgress: false,
  updateCustomFormSectionItemProgress: false,
  deleteCustomFormSectionItemProgress: false,
  fetchCustomFormSectionItemsProgress: false,
  customFormSectionItems: null,
  customFormSections: null,
  newwlyAddedCustomSectionData: null,

  startCustomFormSectionSeqProgress: false,
  isWrapperrComponentDialogOpen: false,
  isWrapperMasterComponentDialogOpen: false
}

export function evaluationWrapperWorkflowReducer(state = initState, action) {
  switch (action.type) {
    case START_ADD_WRAPPER_WORKFLOW:
      return {
        ...state,
        addOrgWorkFlowProgress: true,
        addOrgWorkFlowSuccess: null,
        addOrgWorkFlowError: null
      }
    case END_ADD_WRAPPER_WORKFLOW: {
      const { success, error } = action.payload
      return {
        ...state,
        addOrgWorkFlowProgress: false,
        addOrgWorkFlowSuccess: success,
        addOrgWorkFlowError: error
      }
    }
    case START_UPDATE_WRAPPER_WORKFLOW:
      return {
        ...state,
        updateOrgWorkFlowProgress: true,
        updateOrgWorkFlowSuccess: null,
        updateOrgWorkFlowError: null,
      }
    case END_UPDATE_WRAPPER_WORKFLOW:
      const { success, error } = action.payload
      return {
        ...state,
        updateOrgWorkFlowProgress: false,
        updateOrgWorkFlowSuccess: success,
        updateOrgWorkFlowError: error,
      }
    case START_GET_WRAPPER_WORKFLOW:
      return {
        ...state,
        orgWorkFlowProgress: true,
        orgWorkFlowList: null,
        orgWorkFlowError: null
      }
    case END_GET_WRAPPER_WORKFLOW: {
      const { success, error } = action.payload
      return {
        ...state,
        orgWorkFlowProgress: false,
        orgWorkFlowList: success,
        orgWorkFlowError: error
      }
    }
    case START_GET_BY_ID_WRAPPER_WORKFLOW:
      return {
        ...state,
        orgWorkFlowByIdProgress: true,
        orgWorkFlowByIdList: null,
        orgWorkFlowByIdError: null
      }
    case END_GET_BY_ID_WRAPPER_WORKFLOW: {
      const { success, error } = action.payload
      return {
        ...state,
        orgWorkFlowByIdProgress: false,
        orgWorkFlowByIdList: success,
        orgWorkFlowByIdError: error
      }
    }
    case START_DELETE_WRAPPER_WORKFLOW:
      return {
        ...state,
        deleteOrgWorkFlowProgress: true,
        deleteOrgWorkFlowSuccess: null,
        deleteOrgWorkFlowError: null
      }
    case END_DELETE_WRAPPER_WORKFLOW: {
      const { success, error } = action.payload
      return {
        ...state,
        deleteOrgWorkFlowProgress: false,
        deleteOrgWorkFlowSuccess: success,
        deleteOrgWorkFlowError: error
      }
    }
    case START_ADD_WRAPPER_WORKFLOW_COMPONENT:
      return {
        ...state,
        addWrapperWorkFlowCompProgress: true,
        addWrapperWorkFlowCompSuccess: null,
        addWrapperWorkFlowCompError: null
      }
    case END_ADD_WRAPPER_WORKFLOW_COMPONENT: {
      const { success, error } = action.payload
      return {
        ...state,
        addWrapperWorkFlowCompProgress: false,
        addWrapperWorkFlowCompSuccess: success,
        addWrapperWorkFlowCompError: error
      }
    }
    case START_UPDATE_WRAPPER_WORKFLOW_COMPONENT:
      return {
        ...state,
        updateWrapperWorkFlowCompProgress: true,
        updateWrapperWorkFlowCompSuccess: null,
        updateWrapperWorkFlowCompError: null,
      }
    case END_UPDATE_WRAPPER_WORKFLOW_COMPONENT: {
      const { success, error } = action.payload
      return {
        ...state,
        updateWrapperWorkFlowCompProgress: false,
        updateWrapperWorkFlowCompSuccess: success,
        updateWrapperWorkFlowCompError: error,
      }
    }
    case START_GET_WRAPPER_WORKFLOW_COMPONENT:
      return {
        ...state,
        orgWorkFlowCompProgress: true,
        orgWorkFlowCompList: null,
        orgWorkFlowCompError: null
      }
    case END_GET_WRAPPER_WORKFLOW_COMPONENT: {
      const { success, error } = action.payload
      return {
        ...state,
        orgWorkFlowCompProgress: false,
        orgWorkFlowCompList: success,
        orgWorkFlowCompError: error
      }
    }
    case START_GET_WRAPPER_WORKFLOW_COMPONENT_DETAILS:
      return {
        ...state,
        orgWorkFlowCompDetailsProgress: true,
        orgWorkFlowCompDetails: null,
        orgWorkFlowCompDetailsError: null
      }
    case END_GET_WRAPPER_WORKFLOW_COMPONENT_DETAILS: {
      const { success, error } = action.payload
      return {
        ...state,
        orgWorkFlowCompDetailsProgress: false,
        orgWorkFlowCompDetails: success,
        orgWorkFlowCompDetailsError: error
      }
    }
    case START_DELETE_WRAPPER_WORKFLOW_COMPONENT:
      return {
        ...state,
        deleteWrapperWorkFlowCompProgress: true,
        deleteWrapperWorkFlowCompSuccess: null,
        deleteWrapperWorkFlowCompError: null
      }
    case END_DELETE_WRAPPER_WORKFLOW_COMPONENT: {
      const { success, error } = action.payload
      return {
        ...state,
        deleteWrapperWorkFlowCompProgress: false,
        deleteWrapperWorkFlowCompSuccess: success,
        deleteWrapperWorkFlowCompError: error
      }
    }

    case START_ADD_WRAPPER_WORKFLOW_ACTION: {
      return {
        ...state,
        addOrgWorkFlowActionProgress: true,
        addOrgWorkFlowActionSuccess: null,
        addOrgWorkFlowActionError: null,
      }
    }

    case END_ADD_WRAPPER_WORKFLOW_ACTION: {
      const { success, error } = action.payload
      return {
        ...state,
        addOrgWorkFlowActionProgress: false,
        addOrgWorkFlowActionSuccess: success,
        addOrgWorkFlowActionError: error
      }
    }

    case START_UPDATE_WRAPPER_WORKFLOW_ACTION: {
      return {
        ...state,
        updateOrgWorkFlowActionProgress: true,
        updateOrgWorkFlowActionSuccess: null,
        updateOrgWorkFlowActionError: null,
      }
    }

    case END_UPDATE_WRAPPER_WORKFLOW_ACTION: {
      const { success, error } = action.payload
      return {
        ...state,
        updateOrgWorkFlowActionProgress: false,
        updateOrgWorkFlowActionSuccess: success,
        updateOrgWorkFlowActionError: error
      }
    }

    case START_FETCH_WRAPPER_WORKFLOW_ACTION: {
      return {
        ...state,
        orgWorkFlowActionProgress: true,
        // orgWorkFlowActionList: null,
        // orgWorkFlowActionError: null
      }
    }

    case END_FETCH_WRAPPER_WORKFLOW_ACTION: {
      const { success, error } = action.payload
      return {
        ...state,
        orgWorkFlowActionProgress: false,
        orgWorkFlowActionList: success,
        orgWorkFlowActionError: error
      }
    }

    case START_DELETE_WRAPPER_WORKFLOW_ACTION: {
      return {
        ...state,
        deleteOrgWorkFlowActionProgress: true,
        deleteOrgWorkFlowActionSuccess: null,
        deleteOrgWorkFlowActionError: null
      }
    }

    case END_DELETE_WRAPPER_WORKFLOW_ACTION: {
      const { success, error } = action.payload
      return {
        ...state,
        deleteOrgWorkFlowActionProgress: false,
        deleteOrgWorkFlowActionSuccess: success,
        deleteOrgWorkFlowActionError: error
      }
    }

    case SHOW_MASTER_COMPONENT_DIALOG:
      return {
        ...state,
        isMasterComponentDialogOpen: true
      }

    case HIDE_MASTER_COMPONENT_DIALOG:
      return {
        ...state,
        isMasterComponentDialogOpen: false
      }

    case START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        addWorkflowCustomFormProgress: true
      }

    case END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        addWorkflowCustomFormProgress: false,
        workflowCustomForm: action.payload.success
      }

    case START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        updateWorkflowCustomFormProgress: true
      }

    case END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        updateWorkflowCustomFormProgress: false
      }

    case START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        deleteWorkflowCustomFormProgress: true
      }

    case END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_DETAILS:
      return {
        ...state,
        deleteWorkflowCustomFormProgress: false
      }

    case START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM:
      return {
        ...state,
        fetchCustomFormProgress: true,
        workflowCustomForm: null
      }

    case END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM:
      console.log(action.payload, '000000000000000000000000000000000000000000000000000000000000000')
      return {
        ...state,
        fetchCustomFormProgress: false,
        workflowCustomForm: action.payload.success
      }

    case START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_BY_ID:
      return {
        ...state,
        startFetchCustomFormDataProgress: true,
        workflowCustomFormData: null,
      }

    case END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_BY_ID:
      return {
        ...state,
        startFetchCustomFormDataProgress: false,
        workflowCustomFormData: action.payload.success,
      }

    case START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION:
      return {
        ...state,
        addCustomFormSectionProgress: true,
        newwlyAddedCustomSectionData: null,
      }

    case END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION:
      let customFormSectionsDatas = [];
      if (state.customFormSections !== null) {
        customFormSectionsDatas = [...state.customFormSections]
      }
      if (action.payload.success !== null) {
        customFormSectionsDatas.push(action.payload.success)
      }
      return {
        ...state,
        addCustomFormSectionProgress: false,
        customFormSections: customFormSectionsDatas,
        newwlyAddedCustomSectionData: action.payload.success,
      }

    case START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION:
      return {
        ...state,
        updateCustomFormSectionProgress: true,
      }

    case END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION:
      return {
        ...state,
        updateCustomFormSectionProgress: false,
      }

    case START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTIONS:
      return {
        ...state,
        fetchCustomFormSectionsProgress: true,
        customFormSections: null
      }

    case END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTIONS:
      return {
        ...state,
        fetchCustomFormSectionsProgress: false,
        customFormSections: action.payload.success
      }

    case START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION:
      return {
        ...state,
        deletCustomFormSectionProgress: true,
      }

    case END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION:
      return {
        ...state,
        deletCustomFormSectionProgress: false,
      }

    case START_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        addCustomFormSectionItemProgress: true,
      }

    case END_ADD_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        addCustomFormSectionItemProgress: false
      }

    case START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        updateCustomFormSectionItemProgress: true
      }

    case END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        updateCustomFormSectionItemProgress: false
      }

    case START_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        deleteCustomFormSectionItemProgress: true,
      }

    case END_DELETE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEM:
      return {
        ...state,
        deleteCustomFormSectionItemProgress: false
      }

    case START_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS:
      return {
        ...state,
        fetchCustomFormSectionItemsProgress: true,
        customFormSectionItems: null
      }

    case END_FETCH_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_ITEMS:
      return {
        ...state,
        fetchCustomFormSectionItemsProgress: false,
        customFormSectionItems: action.payload.success
      }

    case START_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_SEQ:
      // console.log(action.payload.data,'Oooooooooooooooooooooooooooooooooooooooo Data Change');
      // const {data} = action.payload;
      // let sectionItems = [];
      // if(state.customFormSections !== null){
      //   sectionItems = [...state.customFormSections];
      //   sectionItems.forEach(function(item){
      //     if(item.ID === data.sectionId){
      //       item.Seq = data.data.seq;
      //     }
      //   });
      // }
      return {
        ...state,
        startCustomFormSectionSeqProgress: true,
        // customFormSections: sectionItems
      }

    case END_UPDATE_WRAPPER_WORKFLOW_CUSTOM_FORM_SECTION_SEQ:
      return {
        ...state,
        startCustomFormSectionSeqProgress: false,
      }

    case SHOW_WRAPPER_COMPONENT_DIALOG:
      return {
        ...state,
        isWrapperrComponentDialogOpen: true
      }

    case HIDE_WRAPPER_COMPONENT_DIALOG:
      return {
        ...state,
        isWrapperrComponentDialogOpen: false
      }

      case SHOW_WRAPPER_MASTER_COMPONENT_DIALOG:
        return {
          ...state,
          isWrapperMasterComponentDialogOpen: true
        }
  
      case HIDE_WRAPPER_MASTER_COMPONENT_DIALOG:
        return {
          ...state,
          isWrapperMasterComponentDialogOpen: false
        }

    default:
      return state;
  }
}