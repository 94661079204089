import { Box, Grid, IconButton } from "@material-ui/core";
import React from "react";
import Item from "../item";
import { Delete } from "@material-ui/icons";

const ItemGroup = ({
  section,
  itemGroup,
  isEdit,
  activeMenu,
  index,
  deleteItemGroup,
}) => {
  return (
    <Grid container style={{ position: 'relative' }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          width: "100%",
          position: 'absolute',
          right: '10px'
        }}
      >
        <div style={{ display: isEdit ? "block" : "none" }}>
          <IconButton
            onClick={() => {
              deleteItemGroup(index);
            }}
            style={{
              display: index == 0 ? "none" : "block",
            }}
          >
            <Delete htmlColor={"#FF8082"} />
          </IconButton>
        </div>
      </div>

      <Grid container>
        {itemGroup.items &&
          itemGroup.items.length > 0 &&
          itemGroup.items.map((item, key) => (
            <Grid item xs={item.size} key={key}>
              <Box sx={{ paddingRight: "24px" }}>
                <Item
                  item={item}
                  isEdit={isEdit}
                  activeMenu={activeMenu}
                  section={section}
                  itemGroup={itemGroup}
                  itemGroupIndex={index}
                />
              </Box>
            </Grid>
          ))}
        <div
          style={{
            borderBottom: "1px solid #003b4d16",
            margin: "20px",
            width: "100%",
          }}
        ></div>
      </Grid>
    </Grid>
  );
};
export default ItemGroup;
