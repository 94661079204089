/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {UserFactory} from "../../services/user";
import {HttpFactory} from "../../services/httpService";
import * as Environment from "../../util/Environment";

export const START_AUTH     =   "START_AUTH";
export const END_AUTH       =   "END_AUTH";

export const START_ENABLE_OKTA_SSO     =   "START_ENABLE_OKTA_SSO";
export const END_ENABLE_OKTA_SSO       =   "END_ENABLE_OKTA_SSO";

export const START_UPDATE_OKTA_SSO_IDP     =   "START_UPDATE_OKTA_SSO_IDP";
export const END_UPDATE_OKTA_SSO_IDP       =   "END_UPDATE_OKTA_SSO_IDP";

export const START_DISABLE_OKTA_SSO     =   "START_DISABLE_OKTA_SSO";
export const END_DISABLE_OKTA_SSO       =   "END_DISABLE_OKTA_SSO";

export const START_ENABLE_AZURE_SSO     =   "START_ENABLE_AZURE_SSO";
export const END_ENABLE_AZURE_SSO       =   "END_ENABLE_AZURE_SSO";

export const START_UPDATE_AZURE_SSO_IDP     =   "START_UPDATE_AZURE_SSO_IDP";
export const END_UPDATE_AZURE_SSO_IDP       =   "END_UPDATE_AZURE_SSO_IDP";

export const START_DISABLE_AZURE_SSO     =   "START_DISABLE_AZURE_SSO";
export const END_DISABLE_AZURE_SSO       =   "END_DISABLE_AZURE_SSO";

export const START_ENABLE_SLACK_NOTIFICATION     =   "START_ENABLE_SLACK_NOTIFICATION";
export const END_ENABLE_SLACK_NOTIFICATION       =   "END_ENABLE_SLACK_NOTIFICATION";

export const START_SET_CURRENT_ORG     =   "START_SET_CURRENT_ORG";
export const END_SET_CURRENT_ORG       =   "END_SET_CURRENT_ORG";

export const START_GET_ENABLED_COGNITO_SSO_APPS     =   "START_GET_ENABLED_COGNITO_SSO_APPS";
export const END_GET_ENABLED_COGNITO_SSO_APPS       =   "END_GET_ENABLED_COGNITO_SSO_APPS";

export const START_GET_SLACK_CHANNELS = "START_GET_SLACK_CHANNELS"
export const END_GET_SLACK_CHANNELS = "END_GET_SLACK_CHANNELS"

export const START_GET_SLACK_STATE_CODE = "START_GET_SLACK_STATE_CODE"
export const END_GET_SLACK_STATE_CODE = "END_GET_SLACK_STATE_CODE"

export const START_SET_SLACK_NOTIFICATION_CHANNELS = "START_SET_SLACK_NOTIFICATION_CHANNELS"
export const END_SET_SLACK_NOTIFICATION_CHANNELS = "END_SET_SLACK_NOTIFICATION_CHANNELS"

export const START_UNINSTALL_SLACK = "START_UNINSTALL_SLACK"
export const END_UNINSTALL_SLACK = "END_UNINSTALL_SLACK"
export const START_INSTALL_SLACK = "START_INSTALL_SLACK"
export const END_INSTALL_SLACK = "END_INSTALL_SLACK"

export const START_GET_NOTIFICATION_TYPE = "START_GET_NOTIFICATION_TYPE"
export const END_GET_NOTIFICATION_TYPE = "END_GET_NOTIFICATION_TYPE"

export const START_GET_NOTIFICATION_SETTINGS = "START_GET_NOTIFICATION_SETTINGS"
export const END_GET_NOTIFICATION_SETTINGS = "END_GET_NOTIFICATION_SETTINGS"

export const START_DELETE_NOTIFICATION_SETTINGS = "START_DELETE_NOTIFICATION_SETTINGS"
export const END_DELETE_NOTIFICATION_SETTINGS = "END_DELETE_NOTIFICATION_SETTINGS"

export const START_USER_SSO_LOGIN = "START_USER_SSO_LOGIN"
export const END_USER_SSO_LOGIN = "END_USER_SSO_LOGIN"

export const SHOW_ASK_LOGIN_PLATFORM_DIALOG = "SHOW_ASK_LOGIN_PLATFORM_DIALOG"
export const HIDE_ASK_LOGIN_PLATFORM_DIALOG = "HIDE_ASK_LOGIN_PLATFORM_DIALOG"

export const SET_LOGOUT_FROM_FLAG="SET_LOGOUT_FROM_FLAG"

//Action to start the registration
function startAuth(){
    return {
        type:START_AUTH,
        payload:null
    };
}

function endAuth(success,error){
    return {
        type:END_AUTH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function authorizedUser(token,password){
    return async (dispatch) => {
        dispatch(startAuth());
        try{
            let [response,error]    =   await UserFactory.instance().self();
            dispatch(endAuth(response,error));
        } catch(e){
            dispatch(endAuth(null,{
                message:e.message
            }));
            return;
        }
    };
}   

//Thunk to perform the authorizedUser
export  function logout(){
    return async (dispatch) => {
        dispatch(startAuth());
        try{
            let [response,error]    =   await UserFactory.instance().logout();
            dispatch(endAuth(response,error));
        } catch(e){
            dispatch(endAuth(null,{
                message:e.message
            }));
            return;
        }
    };
} 


//Action to start the registration
function startEnableOktaSSO(){
    return {
        type:START_ENABLE_OKTA_SSO,
        payload:null
    };
}

function endEnableOktaSSO(success,error){
    return {
        type:END_ENABLE_OKTA_SSO,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function enableOktaSSO(){
    return async (dispatch) => {
        dispatch(startEnableOktaSSO());
        try{
            let [response,error]    =   await UserFactory.instance().enableSSO('okta');
            dispatch(endEnableOktaSSO(response,error));
        } catch(e){
            dispatch(endEnableOktaSSO(null,{
                message:e.message
            }));
            return;
        }
    };
}

//Action to start the registration
function startUpdateOktaSSOIDP(){
    return {
        type:START_UPDATE_OKTA_SSO_IDP,
        payload:null
    };
}

function endUpdateOktaSSOIDP(success,error){
    return {
        type:END_UPDATE_OKTA_SSO_IDP,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateOktaSSOIDP(idpURL){
    return async (dispatch) => {
        dispatch(startUpdateOktaSSOIDP());
        try{
            let [response,error]    =   await UserFactory.instance().updateSSOIDP('okta',idpURL);
            dispatch(endUpdateOktaSSOIDP(response,error));
        } catch(e){
            dispatch(endUpdateOktaSSOIDP(null,{
                message:e.message
            }));
            return;
        }
    };
}

//Action to start the registration
function startDisableOktaSSO(){
    return {
        type:START_DISABLE_OKTA_SSO,
        payload:null
    };
}

function endDisableOktaSSO(success,error){
    return {
        type:END_DISABLE_OKTA_SSO,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function disableOktaSSO(){
    return async (dispatch) => {
        dispatch(startDisableOktaSSO());
        try{
            let [response,error]    =   await UserFactory.instance().disableSSO('okta');
            dispatch(endDisableOktaSSO(response,error));
        } catch(e){
            dispatch(endDisableOktaSSO(null,{
                message:e.message
            }));
            return;
        }
    };
}





//Action to start the registration
function startEnableAzureSSO(){
    return {
        type:START_ENABLE_AZURE_SSO,
        payload:null
    };
}

function endEnableAzureSSO(success,error){
    return {
        type:END_ENABLE_AZURE_SSO,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function enableAzureSSO(){
    return async (dispatch) => {
        dispatch(startEnableAzureSSO());
        try{
            let [response,error]    =   await UserFactory.instance().enableSSO('azure');
            dispatch(endEnableAzureSSO(response,error));
        } catch(e){
            dispatch(endEnableAzureSSO(null,{
                message:e.message
            }));
            return;
        }
    };
}

//Action to start the registration
function startUpdateAzureSSOIDP(){
    return {
        type:START_UPDATE_AZURE_SSO_IDP,
        payload:null
    };
}

function endUpdateAzureSSOIDP(success,error){
    return {
        type:END_UPDATE_AZURE_SSO_IDP,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function updateAzureSSOIDP(idpURL){
    return async (dispatch) => {
        dispatch(startUpdateAzureSSOIDP());
        try{
            let [response,error]    =   await UserFactory.instance().updateSSOIDP('azure',idpURL);
            dispatch(endUpdateAzureSSOIDP(response,error));
        } catch(e){
            dispatch(endUpdateAzureSSOIDP(null,{
                message:e.message
            }));
            return;
        }
    };
}

//Action to start the registration
function startDisableAzureSSO(){
    return {
        type:START_DISABLE_AZURE_SSO,
        payload:null
    };
}

function endDisableAzureSSO(success,error){
    return {
        type:END_DISABLE_AZURE_SSO,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function disableAzureSSO(){
    return async (dispatch) => {
        dispatch(startDisableAzureSSO());
        try{
            let [response,error]    =   await UserFactory.instance().disableSSO('azure');
            dispatch(endDisableAzureSSO(response,error));
        } catch(e){
            dispatch(endDisableAzureSSO(null,{
                message:e.message
            }));
            return;
        }
    };
}


//Action to start the enable slack notification
function startGetEnabledCognitoSSOApps(){
    return {
        type:START_GET_ENABLED_COGNITO_SSO_APPS,
        payload:null
    };
}

function endGetEnabledCognitoSSOApps(success,error){
    return {
        type:END_GET_ENABLED_COGNITO_SSO_APPS,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the GetEnabledCognitoSSOApps
export  function getEnabledCognitoSSOApps(){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/user/cognito-login/enabled?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetEnabledCognitoSSOApps());
        try{
            let [response,error] = await HttpFactory.instance().getMethod(url);
            dispatch(endGetEnabledCognitoSSOApps(response,error));
        } catch(e){
            dispatch(endGetEnabledCognitoSSOApps(null,{
                message:e.message
            }));
            return;
        }
    };
}



//Action to start the enable slack notification
function startEnableSlackNotification(){
    return {
        type:START_ENABLE_SLACK_NOTIFICATION,
        payload:null
    };
}

function endEnableSlackNotification(success,error){
    return {
        type:END_ENABLE_SLACK_NOTIFICATION,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the enableSlackNotification
export  function enableSlackNotification(){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/integration/slack/install?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startEnableSlackNotification());
        try{
            let [response,error] = await HttpFactory.instance().getMethod(url);
            dispatch(endEnableSlackNotification(response,error));
        } catch(e){
            dispatch(endEnableSlackNotification(null,{
                message:e.message
            }));
            return;
        }
    };
}


function startSetCurrentOrg(){
    return {
        type:START_SET_CURRENT_ORG,
        payload:null
    };
}

function endSetCurrentOrg(success,error){
    return {
        type:END_SET_CURRENT_ORG,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the SetCurrentOrg
export  function setCurrentOrg(orgId){
    const api_server = Environment.api_host("USER");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/set_current_org/${orgId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startSetCurrentOrg());
        try{
            let [response,error] = await HttpFactory.instance().postMethod(url);
            dispatch(endSetCurrentOrg(response,error));
        } catch(e){
            dispatch(endSetCurrentOrg(null,{
                message:e.message
            }));
            return;
        }
    };
}

//Action to start the get slack channels
function startGetSlackChannels(){
    return {
        type:START_GET_SLACK_CHANNELS,
        payload:null
    };
}

function endGetSlackChannels(success,error){
    return {
        type:END_GET_SLACK_CHANNELS,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the getSlackChannels
export  function getSlackChannels(){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/integration/slack/channels?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetSlackChannels());
        try{
            let [response,error] = await HttpFactory.instance().getMethod(url)
            dispatch(endGetSlackChannels(response,error));
        } catch(e){
            dispatch(endGetSlackChannels(null,{
                message:e.message
            }));
            return;
        }
    };
}

//Action to start the get slack state code
function startGetSlackStateCode(){
    return {
        type: START_GET_SLACK_STATE_CODE,
        payload:null
    };
}

function endGetSlackStateCode(success,error){
    return {
        type: END_GET_SLACK_STATE_CODE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the getSlackStateCode
export  function getSlackStateCode(){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/integration/slack/install_state?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetSlackStateCode());
        try{
            let [response,error] = await HttpFactory.instance().getMethod(url)
            dispatch(endGetSlackStateCode(response,error));
        } catch(e){
            dispatch(endGetSlackStateCode(null,{
                message:e.message
            }));
            return;
        }
    };
}

//Action to start the get slack state code
function startSetSlackNotificationChannels(){
    return {
        type: START_SET_SLACK_NOTIFICATION_CHANNELS,
        payload:null
    };
}

function endSetSlackNotificationChannels(success,error){
    return {
        type: END_SET_SLACK_NOTIFICATION_CHANNELS,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the setSlackNotificationChannels
export  function setSlackNotificationChannels(data){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/integration/slack/notification/groups?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startSetSlackNotificationChannels());
        try{
            let [response,error] = await HttpFactory.instance().postMethod(url, data)
            dispatch(endSetSlackNotificationChannels(response,error));
        } catch(e){
            dispatch(endSetSlackNotificationChannels(null,{
                message:e.message
            }));
            return;
        }
    };
}

//Action to start the get slack state code
function startUninstallSlack(){
    return {
        type: START_UNINSTALL_SLACK,
        payload:null
    };
}

function endUninstallSlack(success,error){
    return {
        type: END_UNINSTALL_SLACK,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the setSlackNotificationChannels
export  function uninstallSlack(){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/integration/slack/uninstall?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUninstallSlack());
        try{
            let [response,error] = await HttpFactory.instance().getMethod(url)
            dispatch(endUninstallSlack(response,error));
        } catch(e){
            dispatch(endUninstallSlack(null,{
                message:e.message
            }));
            return;
        }
    };
}

//Action to start the get slack state code
function startInstallSlack(){
    return {
        type: START_INSTALL_SLACK,
        payload:null
    };
}

function endInstallSlack(success,error){
    return {
        type: END_INSTALL_SLACK,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the setSlackNotificationChannels
export  function installSlack(){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/integration/slack/install?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startInstallSlack());
        try{
            let [response,error] = await HttpFactory.instance().getMethod(url)
            dispatch(endInstallSlack(response,error));
        } catch(e){
            dispatch(endInstallSlack(null,{
                message:e.message
            }));
            return;
        }
    };
}


//Action to start the get slack state code
function startGetNotificationTypes(){
    return {
        type: START_GET_NOTIFICATION_TYPE,
        payload:null
    };
}

function endGetNotificationTypes(success,error){
    return {
        type: END_GET_NOTIFICATION_TYPE,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the getNotificationTypes
export  function getNotificationTypes(){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/integration/slack/notification/groups?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetNotificationTypes());
        try{
            let [response,error] = await HttpFactory.instance().getMethod(url)
            dispatch(endGetNotificationTypes(response,error));
        } catch(e){
            dispatch(endGetNotificationTypes(null,{
                message:e.message
            }));
            return;
        }
    };
}

//Action to start the get slack state code
function startGetNotificationSettings(){
    return {
        type: START_GET_NOTIFICATION_SETTINGS,
        payload:null
    };
}

function endGetNotificationSettings(success,error){
    return {
        type: END_GET_NOTIFICATION_SETTINGS,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the getNotificationSettings
export  function getNotificationSettings(){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/integration/slack/get_notification_settings?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetNotificationSettings());
        try{
            let [response,error] = await HttpFactory.instance().getMethod(url)
            dispatch(endGetNotificationSettings(response,error));
        } catch(e){
            dispatch(endGetNotificationSettings(null,{
                message:e.message
            }));
            return;
        }
    };
}


//Action to start the get slack state code
function startDeleteNotificationSettings(){
    return {
        type: START_DELETE_NOTIFICATION_SETTINGS,
        payload:null
    };
}

function endDeleteNotificationSettings(success,error){
    return {
        type: END_DELETE_NOTIFICATION_SETTINGS,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the getNotificationSettings
export  function deleteNotificationSettings(){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/integration/slack/delete_notification_settings?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startGetNotificationSettings());
        try{
            let [response,error] = await HttpFactory.instance().deleteMethod(url)
            dispatch(startDeleteNotificationSettings(response,error));
        } catch(e){
            dispatch(endDeleteNotificationSettings(null,{
                message:e.message
            }));
            return;
        }
    };
}

//Action to start the get slack state code
function startSSOLogin(){
    return {
        type: START_USER_SSO_LOGIN,
        payload:null
    };
}

function endSSOLogin(success,error){
    return {
        type: END_USER_SSO_LOGIN,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the SSOLogin
export  function userSSOLogin(data){
    const api_server = Environment.api_host("USER");
    const timestamp     =   (new Date()).getTime();
    const url = `${api_server}/sso-login?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startSSOLogin());
        try{
            let [response,error] = await HttpFactory.instance().postMethod(url, data,true,false)
            if (process.env.REACT_APP_ENV === "production") {
                document.cookie = "prodToken="+ response?.authToken+";expires=604800;domain=.expent.ai;path=/;secure=true";
            }else if (process.env.REACT_APP_ENV === "test") {
                document.cookie = "testToken="+ response?.authToken+";expires=604800;domain=.expent.ai;path=/;secure=true";
            }else {
                document.cookie = "token="+ response?.authToken+";expires=604800;domain=.expent.ai;path=/;secure=true";
            }
            dispatch(endSSOLogin(response,error));
        } catch(e){
            dispatch(endSSOLogin(null,{
                message:e.message
            }));
            return;
        }
    };
}

export function showAskLoginPlatformDialog(success,error){
    return {
        type: SHOW_ASK_LOGIN_PLATFORM_DIALOG,
        payload:{
        }
    };
}

export function hideAskLoginPlatformDialog(success,error){
    return {
        type: HIDE_ASK_LOGIN_PLATFORM_DIALOG,
        payload:{
        }
    };
}

export function setLogoutFromFlag(state){
    return{
        type: SET_LOGOUT_FROM_FLAG,
        payload:{}
    }
}