import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";

const connectedProps = (state) => ({

});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  variableDiv: {
    width: '25%',
    position: 'fixed',
    right: '41%',
    bottom: 20,
    transition: 'width .2s ease,height .2s ease',
    border: '1px solid #ced5db',
    borderTop: '6px solid #133440',
    background: '#f7f7f7'
  },
  minVar: {
    height: 60,
  },
  maxVar: {
    height: '60vh',
  },
  heading: {
    borderBottom: '1px solid #ced5db',
    background: '#fff',
    padding: 10,
    display: 'flex',
    width: '100%'
  },
  title: {
    fontSize: 16,
    fontWeight: 600
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: '#919ba5'
  },
  list: {
    padding: 10,
    height: '50vh',
    overflow: 'auto'
  },
  // listItem: {
  //   background: '#fff',
  //   border: '1px solid #ced5db',
  //   color: '#24434f',
  //   padding: '4px 8px',
  //   marginBottom: 10,
  //   cursor: 'pointer'
  // },
  titleSec: {
    flex: '1'
  },
  actionSec: {
    textAlign: 'right'
  },
  expandIcon: {
    cursor: 'pointer'
  },
  iconType: {
    width: 18,
    height: 18
  },
  accRoot: {
    background: 'transparent',
    boxShadow: 'none'
  },
  accDetailRoot: {
    padding: '0px 5px',
    display: 'block'
  }
});

class AutomationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    const { classes, automation, isSelected } = this.props;
    return (<Box
      style={{
        borderRadius: '8px',
        background: '#fff',
        padding: '16px',
        justifyContent: 'space-between',
        position: 'relative',
        cursor: 'pointer',
        visibility: 'visible',
        border: isSelected
          ? '1px solid #133440'
          : '1px solid #ced5db',
        width: '100%',
      }}
      onClick={() => {
        this.props.handleClickCard();
      }}
      spacing={0.8}
    >
      <Box direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant="body1" >
          {automation?.name || 'Automation name'}
        </Typography>
      </Box>
    </Box>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(AutomationCard));
