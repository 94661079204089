import {
  Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  TextField, IconButton
} from '@material-ui/core';
import Select from 'react-select';
import classnames from 'classnames';
import Image from 'Components/Common/image.jsx';
import deleteIcon from "assets/images/delete.svg";
import SqureButton from 'Components/Common/SqureButton';

import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import * as Validator from "util/Validation";

import { webHookOptions } from '../../Templates/Forms/Common/data'
import { showSnackBar } from "redux/snackbar/action";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
});

const connectionActions = {
  showSnackBar:showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
    margin: 'auto',
    marginBottom: 60
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  title: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
  },
  ruleTable: {
    width: "100%",
    '& [class*=MuiSelect-root]': {
      minHeight: 22
    }
  },
  body: {
    marginTop: 20,
    marginBottom: 20,
    minHeight: '60vh'
  },
  rules: {
    marginTop: 30
  },
  bottomAction: {
    textAlign: 'right'
  },
  titleFom:{
    fontWeight: 500,
  }
});

class WebHookIntegrationDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      additional_data: {},
      isLoadDone: false
    }
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    let data = {
      triggerType: '',
      triggerURL: '',
      dataFields: []
    }

    if (this.props.additional_data !== undefined && this.props.additional_data !== null && this.props.additional_data !== '' && this.props.additional_data.triggerType !== undefined) {
      data = this.props.additional_data
    }

    this.setState({
      additional_data: data,
      isLoadDone: true
    })
  }

  componentDidUpdate(prevProps) {

  }

  handleSave(){
    const additional_data = this.state.additional_data;
    console.log(additional_data);
    if (additional_data?.triggerType?.value === 'other') {
      let addiData = additional_data;
      let targetURL = addiData.triggerURL;
      if (targetURL === '') {
        this.props.showSnackBar("Please enter URL.", "error");
        return;
      }

      if (Validator.validateURL(targetURL) === false) {
        this.props.showSnackBar("Please enter valid URL.", "error");
        return;
      }
    }

    if (additional_data.dataFields !== null && additional_data.dataFields.length > 0) {
      let dataFields = additional_data.dataFields;
      let count = 0;
      let isError = false;
      let errorMsg = '';
      dataFields.forEach(function (itm) {
        if (isError) {
          return;
        }
        count++;
        if (itm.form_type === '') {
          isError = true;
          errorMsg = 'Please select form type in row ' + count;
        } else if (itm.form_id === '') {
          isError = true;
          errorMsg = 'Please select form in row ' + count;
        } else if (itm.field_id === '') {
          isError = true;
          errorMsg = 'Please select field in row ' + count;
        }
      })

      if (isError) {
        this.props.showSnackBar(errorMsg, "error");
        return;
      }
    }
    this.props.saveWebHookConfiguration(this.state.additional_data)
  }

  handleWebHookOption = (index, value) => {
    const additional_data = this.state.additional_data;
    additional_data.triggerType = value;
    this.setState({ additional_data })
  }

  handleWebHookTargetURL = (index, value) => {
    const additional_data = this.state.additional_data;
    additional_data.triggerURL = value;
    this.setState({ additional_data })
  }

  handleChangeShowMessage = (index, value) => {
    const additional_data = this.state.additional_data;
    additional_data.message = value;
    this.setState({ additional_data })
  }

  handleWebHookDataFields = (index, newValue, fieldName) => {
    const additional_data = this.state.additional_data;
    additional_data.dataFields[index][fieldName] = newValue;
    if (fieldName === 'form_type') {
      additional_data.dataFields[index]['form_id'] = '';
      additional_data.dataFields[index]['field_id'] = '';
    } else if (fieldName === 'form_id') {
      additional_data.dataFields[index]['field_id'] = '';
    }
    this.setState({ additional_data })
  }

  addNewWebHookFiels = () => {
    const additional_data = this.state.additional_data;
    additional_data.dataFields.push({
      form_id: "",
      form_type: {
        value:'Org',
        label:'Org'
      },
      field_id: ""
    });
    this.setState({ additional_data })
  }

  deleteWebHookFields(rule, index) {
    const additional_data = this.state.additional_data;
    additional_data.dataFields.splice(index, 1);
    this.setState({ additional_data })
  };

  render() {
    const classes = this.props.classes;
    const { additional_data, isLoadDone } = this.state;
    const { orgForms, orgFormsFieldMap } = this.props;
    if (!isLoadDone) {
      return <div> Loading </div>;
    }
    console.log(additional_data, 'additional_data')

    return <Dialog
      onClose={this.props.onClose}
      aria-labelledby="app-integrationDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"md"}
      scroll="body"
      id="evMetaDialog"
    >
      <DialogContent classes={{ root: classes.dialogContent }} >
        <div className={classes.close} onClick={() => this.props.onClose()}><CloseIcon /></div>
        <Typography variant={"h4"} className={classes.title}>Web Hook Configuration</Typography>
        <div className={classes.body}>
          {<div>
            <Typography className={classes.titleFom}>Trigger Type</Typography>
            <Select
              value={additional_data.triggerType}
              options={webHookOptions}
              className={classnames(classes.creatable)}
              classNamePrefix="select"
              placeholder={"Select Trigger Type"}
              onChange={(e) => {
                this.handleWebHookOption(0, e)
              }}
            />

            {additional_data.triggerType.value === 'other' && <div style={{marginTop:30}}>
            <Typography className={classes.titleFom}>Trigger URL</Typography>
              <TextField
                id="standard-multiline-flexible"
                multiline
                fullWidth
                maxRows={4}
                value={additional_data.triggerURL}
                style={{ fontSize: 16 }}
                // variant="filled"
                placeholder='Enter URL'
                onChange={e => {
                  this.handleWebHookTargetURL(0, e.target.value)
                }}
              />
            </div>}

            {additional_data.dataFields.length > 0 && <div className={classes.rules}>
            <Typography className={classes.titleFom}>Fields</Typography>
              <table className={classes.ruleTable}>
                <tbody>
                  {additional_data.dataFields.map((rule, k) => {
                    return (
                      <React.Fragment key={k}>
                        <tr className={classes.inputSection}>
                          <td style={{ width: '49%' }}>
                            <Select
                              value={rule.form_id}
                              options={orgForms}
                              className={classnames(classes.creatable)}
                              classNamePrefix="select"
                              placeholder={"Select Form"}
                              onChange={(e) => {
                                this.handleWebHookDataFields(k, e, 'form_id');
                              }}
                            />
                          </td>
                          <td style={{ width: '49%' }}>
                            <Select
                              value={rule.field_id}
                              options={rule.form_id === null || rule.form_id === '' || rule.form_id.value === '' ? [] :orgFormsFieldMap[rule.form_id.value]}
                              className={classnames(classes.creatable)}
                              classNamePrefix="select"
                              placeholder={"Select Field"}
                              onChange={(e) => {
                                this.handleWebHookDataFields(k, e, 'field_id');
                              }}
                            />
                          </td>
                          <td className={classes.action} style={{ width: '2%' }}>
                            <IconButton size="small" onClick={() => { this.deleteWebHookFields(rule, k) }}>
                              <Image alt="Delete" src={deleteIcon} />
                            </IconButton>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>}

            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <SqureButton
                cls={classes.actionBtn}
                variant={"outlined"}
                onClick={() => {
                  this.addNewWebHookFiels();
                }}>
                + Field
              </SqureButton>
            </div>
          </div>}
        </div>
        <div className={classes.bottomAction}>
          <SqureButton
            variant={"contained"}
            disabled={false}
            onClick={this.handleSave}
          >
            Save
          </SqureButton>
        </div>
      </DialogContent>
    </Dialog>
  }
}

WebHookIntegrationDialog.defaultProps = {
  isWorkFlow: false,
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(WebHookIntegrationDialog));