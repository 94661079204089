import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment"


export const SHOW_EVALUATION_RULE_DIALOG         =   "SHOW_EVALUATION_RULE_DIALOG";
export const HIDE_EVALUATION_RULE_DIALOG         =   "HIDE_EVALUATION_RULE_DIALOG";

export const START_EVALUATION_RULE_SAVE         =   "START_EVALUATION_RULE_SAVE";
export const END_EVALUATION_RULE_SAVE         =   "END_EVALUATION_RULE_SAVE";

export const START_EVALUATION_RULE_FETCH         =   "START_EVALUATION_RULE_FETCH";
export const END_EVALUATION_RULE_FETCH         =   "END_EVALUATION_RULE_FETCH";

export const START_EVALUATION_REQUIREMENT_RULE_SAVE ="START_EVALUATION_REQUIREMENT_RULE_SAVE";
export const END_EVALUATION_REQUIREMENT_RULE_SAVE ="END_EVALUATION_REQUIREMENT_RULE_SAVE";

export  function showEvaluationRuleDialog(type,content){
  return     {
      type:SHOW_EVALUATION_RULE_DIALOG,
      payload:{type,content}
  };
}


export  function hideEvaluationRuleDialog(){
  return     {
      type:HIDE_EVALUATION_RULE_DIALOG,
      payload:{}
  };
}


function startEvaluationRuleSave(){
  return {
      type:START_EVALUATION_RULE_SAVE,
      payload:{}
  };
}

function endEvaluationRuleSave(success,error){
  return {
      type:END_EVALUATION_RULE_SAVE,
      payload:{
          success,error
      }
  };
}

export  function saveEvaluationRule(evaluation_id,criteriaId,rules){
  const api_server = Environment.api_host("EVALUATION");
  var url   = `${api_server}/${evaluation_id}/criteria/${criteriaId}/rule`;

  return async (dispatch) => {
      dispatch(startEvaluationRuleSave());
      try{
          let [response,error]    =   await HttpFactory.instance().putMethod(url,{
            rules:rules
          });
          dispatch(endEvaluationRuleSave(response,error));
      } catch(e){
          dispatch(endEvaluationRuleSave(null,{
              message:e.message
          }));
          return;
      }
  };
}

function startEvaluationRuleFetch(){
  return {
      type:START_EVALUATION_RULE_FETCH,
      payload:{}
  };
}

function endEvaluationRuleFetch(success,error){
  return {
      type:END_EVALUATION_RULE_FETCH,
      payload:{
          success,error
      }
  };
}

export  function fetchEvaluationRule(evaluation_id,type,content){
  const api_server = Environment.api_host("EVALUATION");
  var url   = `${api_server}/${evaluation_id}/criteria/${content.id}/rule`;
  if(type == "requirement"){
    url   = `${api_server}/${evaluation_id}/criteria/${content.criteriaId}/requirement/${content.id}/rule`;
  }

  return async (dispatch) => {
      dispatch(startEvaluationRuleFetch());
      try{
          let [response,error]    =   await HttpFactory.instance().getMethod(url);
          dispatch(endEvaluationRuleFetch(response,error));
      } catch(e){
          dispatch(endEvaluationRuleFetch(null,{
              message:e.message
          }));
          return;
      }
  };
}


function startEvaluationRequirementRuleSave(){
  return {
      type:START_EVALUATION_REQUIREMENT_RULE_SAVE,
      payload:{}
  };
}

function endEvaluationRequirementRuleSave(success,error){
  return {
      type:END_EVALUATION_REQUIREMENT_RULE_SAVE,
      payload:{
          success,error
      }
  };
}

export  function saveEvaluationRequirementRule(evaluation_id,requirementId,rules){
  const api_server = Environment.api_host("EVALUATION");
  var url   = `${api_server}/${evaluation_id}/requirement/${requirementId}/rule`;

  return async (dispatch) => {
      dispatch(startEvaluationRequirementRuleSave());
      try{
          let [response,error]    =   await HttpFactory.instance().putMethod(url,{
            rules:rules
          });
          dispatch(endEvaluationRequirementRuleSave(response,error));
      } catch(e){
          dispatch(endEvaluationRequirementRuleSave(null,{
              message:e.message
          }));
          return;
      }
  };
}