import * as React from "react";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { compose } from "recompose";
import queryString from "query-string";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles, createStyles } from "@material-ui/core/styles";
import AppBarV2 from "../../../AppBarV2";
import { Tune as TuneIcon } from "@material-ui/icons";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  // Menu,
  // MenuItem,
  // ClickAwayListener,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  ButtonGroup,
  Tooltip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import LayeredCard from "../../Evaluate/Components/Common/LayeredCard";
import SearchInput from "../../../../Common/Input/SearchInput";
import ListIcon from "@material-ui/icons/List";
import AppsIcon from "@material-ui/icons/Apps";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
// redux
import { fetchProductReaction, fetchPersonalizedRecommendationsProducts, fetchWishListedProducts } from "redux/product/action";
import { updateDrawerStyle } from "redux/drawer/action";
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import { fetchOrgProductsSimilarExisting, resetSimilarExistingProducts } from "redux/product/orgProduct/action";
import { createEvaluationCart, removeProductFromCart, fetchEvaluationCart } from "redux/evaluation/cart/action";
import CreateClusterGroupProductDialog from "Components/Dialogs/CreateClusterGroupProductDialog";
import { showSnackBar } from "redux/snackbar/action";
import { showCreateClusterGroupDialog, storeClusterGroupProducts, hideClusterProductsDialog } from "redux/product/cluster/action";
import { fetchOrgGroupProducts, updateOrgGroupProduct } from "redux/org/group/action";
import { fetchAllCategories } from "redux/product/category/action";
import AddProductManuallyDialog from "Components/Application/Components/Settings/AddProductManuallyDialog";
// Dilogs
import CommonCss from "commonCss";
import DefaultImage from "assets/images/evaluation/default.svg";
// import ExistingProductImage from "assets/images/evaluation/existing_product.svg";
import groupIconImage from "assets/images/icons/groups.svg";
import { matchPath } from "react-router-dom";
import { isEnabledReview } from "util/Common";

import ProductTypeFilter from "Components/Common/Filters/ProductTypeFilter";
import ImportProductDialog from "./Dialogs/ImportProductDialog";
import GroupsCard from "./Common/GroupsCard";
import ProductsCard from "./Common/ProductsCard";
import AppCatalogTabular from "./AppCatalogTabular";
import { storeSearchString } from "redux/applications/action";
import { fetchAppCatlogProducts } from "redux/product/appCatalog/action";
import { fetchExpentDirectoryProducts } from "redux/product/expentDirectory/action";
import Breadcrumb from "Components/Common/Breadcrumb";
import { isEnabledEvaluate } from "util/Common";
import { fetchVendorCatalog } from "redux/vendor/vendorCatalog/action";
import { fetchVendorServiceCatalog } from "redux/vendor/vendorServiceCatalog/action";
import StartWorkflowDialog from "Components/Common/Dialogs/StartWorkflowDialog";
import RequestAccessInformation from "Components/Common/OrgProductDetailsV2/Dialogs/RequestAccessInformation";

const connectedProps = (state) => ({
  addProductManuallyDialogOpen: state.productsSettings.addProductManuallyDialogOpen,
  evaluationCart: state.evaluationCart.evaluation,
  deleteProductStateProg: state.products.deleteProductStateProg,
  deleteProductStateError: state.products.deleteProductStateError,
  addProductStateProg: state.products.addProductStateProg,
  addProductStateData: state.products.addProductStateData,
  addProductStateError: state.products.addProductStateError,
  personalizedRecommendationsProducts: state.products.personalizedRecommendationsProducts,
  fetchAllCategoryProgress: state.productCategory.fetchAllCategoryProgress,
  allCategories: state.productCategory.allCategories,
  fetchAllCategoryError: state.productCategory.fetchAllCategoryError,
  fetchOrgProductsSimilarExistingProgress: state.orgProduct.fetchOrgProductsSimilarExistingProgress,
  orgProductsSimilarExisting: state.orgProduct.orgProductsSimilarExisting,
  _createClusterGroupDialog: state.clusterProductReducer._createClusterGroupDialog,
  userType: state.authUser.user?.Role,
  orgUser: state.orgUser,
  addOrgProductProgress: state.orgProduct.addOrgProductProgress,
  clusterProducts: state.clusterProductReducer.storeClusterGroupProducts,
  orgName: state.authUser.user?.Organization?.Name,
  fetchGroupProductProg: state.orgGroup.fetchGroupProductProg,
  groupProducts: state.orgGroup.groupProducts,
  updateGroupProductProg: state.orgGroup.updateGroupProductProg,
  updateGroupProductError: state.orgGroup.updateGroupProductError,
  searchString: state.applications.searchString,
  searchType: state.applications.searchType,

  fetchAppCatalogProductsProgress: state.appCatalog.fetchAppCatalogProductsProgress,
  fetchAppCatalogProductsError: state.appCatalog.fetchAppCatalogProductsError,
  appCatalogProducts: state.appCatalog.products,
  fetchExpentDirectoryProductsProgress: state.expentDirectory.fetchExpentDirectoryProductsProgress,
  fetchExpentDirectoryProductsError: state.expentDirectory.fetchExpentDirectoryProductsError,
  expentDirectoryProducts: state.expentDirectory.products,
  deleteProductProgress: state.orgProduct.deleteProductProgress,

  fetchVendorServiceCatalogProgress: state.vendorServiceCatalog.fetchVendorServiceCatalogProgress,
  fetchVendorServiceCatalogError: state.vendorServiceCatalog.fetchVendorServiceCatalogError,
  VendorServiceCatalog: state.vendorServiceCatalog.VendorServiceCatalog,

  fetchVendorCatalogProgress: state.vendorCatalog.fetchVendorCatalogProgress,
  fetchVendorCatalogError: state.vendorCatalog.fetchVendorCatalogError,
  VendorCatalog: state.vendorCatalog.VendorCatalog,
  showNewWorkflowStartDialog: state.workflow.showNewWorkflowStartDialog,

  isOpenReqAccInfoDialog: state.dialogs.isShowRequestAccInfoDialog,
  requestAccInfo: state.dialogs.requestAccInfo,
  
});

const connectionActions = {
  hideDialog: hideClusterProductsDialog,
  createEvaluationCart: createEvaluationCart,
  removeProductFromCart: removeProductFromCart,
  showAddProductManuallyDialog: showAddProductManuallyDialog,
  fetchProductReaction: fetchProductReaction,
  updateDrawerStyle: updateDrawerStyle,
  fetchEvaluationCart: fetchEvaluationCart,
  fetchAllCategories: fetchAllCategories,
  fetchPersonalizedRecommendationsProducts: fetchPersonalizedRecommendationsProducts,
  fetchOrgProductsSimilarExisting: fetchOrgProductsSimilarExisting,
  resetSimilarExistingProducts: resetSimilarExistingProducts,
  fetchWishListedProducts: fetchWishListedProducts,
  showCreateClusterGroupDialog: showCreateClusterGroupDialog,
  storeClusterGroupProducts: storeClusterGroupProducts,
  showSnackBar: showSnackBar,
  fetchOrgGroupProducts: fetchOrgGroupProducts,
  updateOrgGroupProduct: updateOrgGroupProduct,
  fetchAppCatlogProducts: fetchAppCatlogProducts,
  fetchExpentDirectoryProducts: fetchExpentDirectoryProducts,
  storeSearchString: storeSearchString,
  fetchVendorCatalog: fetchVendorCatalog,
  fetchVendorServiceCatalog: fetchVendorServiceCatalog
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 2),
      paddingTop: 0,
      width: "85%",
    },
    rootTable: {
      padding: theme.spacing(2, 2, 0, 2),
      width: "85%",
    },
    stickyBar: {
      position: "sticky",
      top: 0,
      background: "#f7f7f7",
      borderRadius: 5,
      padding: "10px 1px",
      zIndex: 23,
      textAlign: "center",
    },
    emptyText: {
      cursor: "pointer",
      textAlign: "center",
    },
    productCount: {
      color: "#282D30",
      fontSize: 14,
      marginTop: 10,
      paddingLeft: 5,
    },
    productContainer: {
      maxHeight: "calc(100vh - 250px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      minHeight: "calc(100vh - 250px)",
      padding: theme.spacing(4, 0),
    },
    addProductLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    label: {
      fontSize: "1.4em",
      fontWeight: 600,
      lineHeight: "1.6",
      marginBottom: 10,
    },
    productMain: {
      display: "flex",
    },
    productHead: {
      ...CommonCss.productHead,
      fontSize: 18,
      margin: "0px !important",
    },
    productMenu: {
      background: "#FFFFFF",
      boxShadow: "0px 2px 10px #0000000B",
      padding: theme.spacing(4, 0, 4, 2.8),
      width: 270,
      minHeight: "100vh",
      position: "fixed",
    },
    menuHead: {
      display: "flex",
      marginBottom: 18,
    },
    container: {
      // display: "flex",
    },
    recommendedText: {
      fontSize: 12,
      fontWeight: 500,
      color: "#2EB77D",
      textDecoration: "underline",
      marginTop: 10,
      width: "max-content",
    },
    topContainer: {
      marginTop: theme.spacing(3),
    },
    headSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterSection: {
      ...CommonCss.filterSection,
      marginRight: 20,
    },
    bottomContainer: {
      marginTop: theme.spacing(5),
      "& [class*=MuiGrid-container]": {
        alignItems: "center",
        marginBottom: 20,
      },
    },
    title: {
      fontSize: theme.spacing(2.4),
      flex: 1,
    },
    categoriesText: {
      color: "#000000",
      fontSize: 18,
      marginLeft: 16,
      fontWeight: 500,
    },
    categoriesList: {
      marginTop: theme.spacing(6.4),
      minHeight: 200,
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      textAlign: "center",
      marginTop: 10,
      minHeight: 100,
    },
    main: {
      maxWidth: "calc(100% - 280px)",
      flexBasis: "calc(100% - 280px)",
    },
    subCategory: {
      height: "100vh",
      overflowY: "overlay",
      background: "#fff",
      padding: theme.spacing(2),
      maxHeight: "100vh",
      borderRight: "1px solid gainsboro",
      "& [class*='MuiAccordion-root']": {
        margin: "0 !important",
      },
      "& [class*='MuiCollapse-root']": {
        overflowY: "auto",
      },
      "& [class*='MuiCollapse-wrapper']": {
        maxHeight: "calc(100vh - 360px)",
        minHeight: "calc(100vh - 360px)",
      },
      "& [class*='MuiAccordionSummary-content']": {
        margin: 0,
      },
      "& [class*='MuiAccordionSummary-root']": {
        minHeight: "inherit !important",
      },
    },
    innerText: {
      color: "#696B6E",
      padding: theme.spacing(1, 1.5, 1, 1),
      cursor: "pointer",
      display: "block",
      margin: 0,
    },
    "@global": {
      ".applicationContent": {
        padding: "0 !important",
      },
    },
    products: {
      display: "flex",
    },
    activeSubCategory: {
      color: "#4A87F8",
      background: "#F2F7FF",
      fontSize: 13,
      marginRight: theme.spacing(3.2),
    },
    activeProductsTabs: {
      color: "#4A87F8",
      background: "#F2F7FF",
    },
    emptyMessage: {
      textAlign: "center",
      flex: 1,
      "& p": {
        margin: 5,
      },
    },
    suggestText: {
      fontWeight: 600,
    },
    filter: {
      // flex: 1,
      textAlign: "right",
    },
    txtCenter: {
      textAlign: "center",
      marginBottom: 16,
    },
    newUserBtn: {
      background: "#4b86ff",
      borderRadius: 5,
      color: "#fff",
      fontWeight: 600,
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#4b86ff",
      },
    },
    productsSection: {
      padding: "1em",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    inputFormControl: {
      display: "block",
      width: "100%",
      padding: ".35rem .65rem",
      borderRadius: ".25rem",
      border: "1px solid #AAAAAA",
      minHeight: 34,
      marginBottom: "10px",
      fontFamily: "Poppins",
      "& > div": {
        backgroundColor: "tarsparent !important",
        border: "none",
        paddingRight: 0,
      },
    },
    labelInitProducts: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 22,
      height: 22,
      marginRight: 8,
      color: "#fff",
      borderRadius: 16,
      border: "1px solid",
      fontSize: 14,
      background: "#4a87f8",
    },
    isAllProducts: {
      background: "#ff0000a3",
    },
    isExistingProducts: {
      color: "white",
      background: "#2EB77D",
    },
    isFeaturedProducts: {
      color: "white",
      background: "#ff9800",
    },
    isGroupProducts: {
      width: "18px",
      marginRight: "10px",
      marginLeft: "2px",
    },
    isOtherProducts: {
      color: "white",
      background: "#2e81da",
    },
    productCounts: {
      fontSize: "12px",
      marginLeft: "auto",
      fontWeight: "600",
      color: "#4a87f8",
    },
    viewAll: {
      color: "#4A87F8",
      fontSize: "14px",
      paddingRight: "10px",
      cursor: "pointer",
    },
    flexWrapIcons: {
      display: "flex",
      gap: "20px",
      marginTop: 40,
      justifyContent: "center",
    },
    productCard: {
      position: "relative",
    },
    selectIcon: {
      position: "absolute",
      zIndex: 22,
      right: 5,
      top: 5,
      color: "#dcd7d7",
      cursor: "pointer",
    },
    disabledDiv: {
      pointerEvents: "none",
    },
  });

class AppHardwareCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCategoryId: null,
      lg_01: window.matchMedia("(min-width: 1281px) and (max-width: 1850px)").matches,
      lg_02: window.matchMedia("(min-width: 668px) and (max-width: 767.98px)").matches,
      headerSectionWidth: null,
      selectedProduct: null,
      isProduct: false,
      queryParams: queryString.parse(this.props.location.search),
      filters: {
        sortby: "relevance",
        order: "",
      },
      isCreateGroup: false,
      selectedGroupProduct: [],
      selectedState: [],
      searchObj: {
        field_setname: "all",
        text: "",
        filter_fields: {
          state: null,
          feature_ids: null,
          subcategory_ids: null,
          platform_ids: null,
        },
        analytics_only: false,
        qtype_page: null,
        page_size: 30,
        sort_fields: [],
      },
      showActionMenu: null,
      lastLocation: this.props.lastLocation,
      isSimilarExistingProduct: false,
      productType: "SOFTWARE",
      isOpenImportProductDialog: false,
      isTeamProductUpdate: false,
      teamId: "",
      orgProducts: [],
      orgProductsNextPage: 0,
      featuredProducts: [],
      featuredProductsNextPage: 0,
      groupsProducts: [],
      groupsProductsNextPage: 0,
      otherProducts: [],
      otherProductsNextPage: 0,
      activeProductsTabs: null,
      allCategories: [],
      selectedView: "grid",
      showGroupView: false,
      searchRestored: false,
      isFetchVendorCatalog: false,
      isFetchServiceCatalog: false,
      orgExistingVendorCount: 0,
      orgExistingServiceCount: 0,
    };
  }

  componentDidMount() {
    this.checkDataOnLoad()
    this.props.fetchWishListedProducts();
    const urlPrams = new URLSearchParams(this.props.location.search);
    this.props.fetchAllCategories(0, 500, "");
    this.props.fetchPersonalizedRecommendationsProducts();
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);

    var evId = urlPrams.get("evaluationId");
    if (evId !== null) {
      setTimeout(() => {
        this.props.fetchEvaluationCart(evId);
      }, 1000);
    }
    if (urlPrams.get("activeView") === "table") {
      this.setState({ selectedView: "table" })
    } else {
      if (urlPrams.get("s") !== null) {
        this.setState({ searchObj: { ...this.state.searchObj, text: urlPrams.get("s") } },
          () => this.fetchData()
        );
      } else {
        this.fetchData();
      }
    }
    var updateTeamProducts = urlPrams.get("updateTeamProducts");

    if (updateTeamProducts !== null && (updateTeamProducts === "true" || updateTeamProducts === true)) {
      this.setState({ showGroupView: true, activeProductsTabs: 2, showActionMenu: null, isCreateGroup: !this.state.isCreateGroup, isTeamProductUpdate: true });
      this.props.storeClusterGroupProducts([]);
      var teamId = urlPrams.get("teamId");
      this.setState({ teamId: teamId });
      this.props.fetchOrgGroupProducts(teamId, 0, 60);
    }
    window.addEventListener("resize", this.updateDimensions);
    if (urlPrams.get("activeTab")) {
      this.setState({ activeProductsTabs: parseInt(urlPrams.get("activeTab")) });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { lastLocation } = this.state;
    var pathName = lastLocation?.pathname;
    var isMatched = false;
    if (pathName !== undefined) {
      if (
        matchPath(pathName, {
          path: "/app/products/org-product-details/:id",
          exact: true,
          strict: false,
        })
      ) {
        isMatched = true;
      }
    }

    if (isMatched && pathName && this.props.searchString !== "" && this.state.searchRestored === false) {
      this.setState(
        {
          searchObj: { ...this.state.searchObj, field_setname: this.props.searchType, text: prevProps.searchString },
          searchRestored: true,
        },
        () => {
          this.fetchData();
        }
      );
    }

    if (!this.props.addProductInClusterGroupInprogress && prevProps.addProductInClusterGroupSuccess) {
      this.props.showSnackBar("Group updated successfully", "success", 3000);
      this.props.clearStoreState({
        "clusterProductReducer.addProductInClusterGroupSuccess": null,
      });
      this.props.hideDialog();
    }

    if (prevState.activeProductsTabs && prevState.activeProductsTabs !== this.state.activeProductsTabs && this.props.appCatalogProducts) {
      // this.setState({
      //   orgProducts: this.props.appCatalogProducts?.existing?.items.slice(0,30) || [],
      //   groupsProducts: this.props.appCatalogProducts?.groups?.items.slice(0,30) || [],
      //   featuredProducts: this.props.appCatalogProducts?.featured?.items.slice(0,30) || [],
      //   otherProducts: this.props.appCatalogProducts?.others?.items.slice(0,30) || [],
      //   orgProductsNextPage: 0,
      //   featuredProductsNextPage: 0,
      //   groupsProductsNextPage: 0,
      //   otherProductsNextPage: 0,
      // })

      this.setState(
        {
          searchObj: { ...this.state.searchObj, qtype_page: null },
          orgProductsNextPage: 0,
          featuredProductsNextPage: 0,
          groupsProductsNextPage: 0,
          otherProductsNextPage: 0,
        },
        () => {
          if (this.state.selectedView === "grid") {
            this.fetchData();
          }
        }
      );
    }

    if (prevProps.addOrgProductProgress && !this.props.addOrgProductProgress) {
      setTimeout(() => {
        this.fetchData();
      }, 2000);
    }

    // if (prevProps.deleteProductProgress && !this.props.deleteProductProgress) {
    //   this.fetchData();
    // }

    if (prevProps.addProductStateProg && !this.props.addProductStateProg) {
      if (!this.props.addProductStateError) {
        this.props.showSnackBar("product state updated successfully", "success", 3000);
        if (Array.isArray(this.props.addProductStateData) && this.props.addProductStateData?.find(item => (item.State === 'blocked' || item.State === 'deprecated'))) {
          // setTimeout(() => {
          //   this.fetchData();
          // }, 3000);
          this.saveQueryParams(4, this.state.selectedView)
          window.location.reload()
        } else {
          this.fetchData();
        }
      } else {
        this.props.showSnackBar("failed to update product state", "error", 3000);
      }
    }

    if (prevProps.deleteProductStateProg && !this.props.deleteProductStateProg) {
      if (!this.props.deleteProductStateError) {
        this.props.showSnackBar("product state updated successfully", "success", 3000);
        this.fetchData();
      } else {
        this.props.showSnackBar("failed to update product state", "error", 3000);
      }
    }

    if (prevProps.fetchGroupProductProg && !this.props.fetchGroupProductProg) {
      let productsArray = this.props.groupProducts || [];

      let data = productsArray.map((product) => ({
        AccessLink: product.access_link,
        CompanyName: product.Name,
        Description: product.Description,
        ID: product.ID,
        IsExisting: true,
        Name: product.name,
        ProductGroups: [],
        ProductLogo: product.ProductLogo,
        ProductStates: [],
      }));

      this.props.storeClusterGroupProducts(data);
    }

    if (prevProps.fetchAllCategoryProgress && !this.props.fetchAllCategoryProgress) {
      if (!this.props.fetchAllCategoryError && this.props.allCategories && this.props.allCategories.Data) {
        this.setState({ allCategories: this.props.allCategories.Data });
      }
    }

    if (prevProps.updateGroupProductProg && !this.props.updateGroupProductProg) {
      if (this.props.updateGroupProductError) {
        this.props.showSnackBar("Operation Failed", "error", 3000);
      } else {
        this.props.showSnackBar("Updated successfully", "success", 3000);
        this.props.history.push("/app/settings/users");
      }
    }

    //for search api which got canceled

    if (!this.props.fetchAppCatalogProductsError && prevProps.fetchAppCatalogProductsError) {
      if (this.props.appCatalogProducts?.existing?.items) {
        this.setState({ orgProducts: this.props.appCatalogProducts?.existing?.items || [] });
      }
      if (this.props.appCatalogProducts?.featured?.items) {
        this.setState({ featuredProducts: this.props.appCatalogProducts?.featured?.items || [] });
      }
      if (this.props.appCatalogProducts?.groups?.items) {
        this.setState({ groupsProducts: this.props.appCatalogProducts?.groups?.items || [] });
      }
      if (this.props.appCatalogProducts?.others?.items) {
        this.setState({ otherProducts: this.props.appCatalogProducts?.others?.items || [] });
      }
    }

    if (!this.props.fetchAppCatalogProductsProgress && prevProps.fetchAppCatalogProductsProgress) {
      let prodIDs = [];
      if (this.props.appCatalogProducts?.others?.items) {
        this.setState({ otherProducts: this.props.appCatalogProducts?.others?.items || [] });
        prodIDs = [...prodIDs, ...this.props.appCatalogProducts?.others?.items.map((p) => parseInt(p.id))];
      }
      if (this.props.appCatalogProducts?.existing?.items) {
        this.setState({ orgProducts: this.props.appCatalogProducts?.existing?.items || [] });
        prodIDs = [...prodIDs, ...this.props.appCatalogProducts?.existing?.items.map((p) => parseInt(p.id))];
      }

      if (this.props.appCatalogProducts?.featured?.items) {
        this.setState({ featuredProducts: this.props.appCatalogProducts?.featured?.items || [] });
        prodIDs = [...prodIDs, ...this.props.appCatalogProducts?.featured?.items.map((p) => parseInt(p.id))];
      }
      if (this.props.appCatalogProducts?.groups?.items) {
        this.setState({ groupsProducts: this.props.appCatalogProducts?.groups?.items || [] });
      }

      this.getProductReactions(prodIDs);
    }

    this.updateDimensions();

    if (!this.props.fetchVendorServiceCatalogProgress && prevProps.fetchVendorServiceCatalogProgress) {
      this.setState({
        isFetchServiceCatalog: true
      }, () => {
        this.checkTotalProducts()
      })
    }

    if (!this.props.fetchVendorCatalogProgress && prevProps.fetchVendorCatalogProgress) {
      this.setState({
        isFetchVendorCatalog: true
      }, () => {
        this.checkTotalProducts()
      })
    }
  }

  checkDataOnLoad() {
    this.props.fetchVendorCatalog({ query: "", page: 0, page_size: 6, filters: { is_org: "1" } });
    this.props.fetchVendorServiceCatalog({ query: "", page: 0, page_size: 6, filters: { is_org: "1" } });
  }

  checkTotalProducts() {
    const { isFetchVendorCatalog, isFetchServiceCatalog } = this.state;
    const { VendorCatalog, VendorServiceCatalog } = this.props;
    if (isFetchVendorCatalog && isFetchServiceCatalog && VendorCatalog && VendorServiceCatalog) {
      let orgExistingVendorCount = this.props.VendorCatalog?.total || 0;
      let orgExistingServiceCount = this.props.VendorServiceCatalog?.total || 0;
      this.setState({
        orgExistingVendorCount: orgExistingVendorCount,
        orgExistingServiceCount: orgExistingServiceCount,
      })
    }
  }

  getProductReactions(productIDs) {
    this.props.fetchProductReaction("all_product_blocking", {
      productIDs,
      source: "all_product_blocking",
      sourceData: "all_product_blocking",
    });
    setTimeout(() => {
      this.props.fetchProductReaction('all_products_deprecating', {
        productIDs,
        source: 'all_products_deprecating',
        sourceData: 'all_products_deprecating'
      })
    }, 2000);
  }

  updateDimensions = () => {
    const headerSectionWidth = this.divElement?.clientWidth;
    if (headerSectionWidth !== undefined && headerSectionWidth !== null && this.state.headerSectionWidth !== headerSectionWidth) {
      this.setState({ headerSectionWidth });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    this.props.storeClusterGroupProducts([]);
  }

  fetchData() {
    const { searchObj, filters, selectedState, activeCategoryId, isProduct } = this.state;
    if (isProduct) {
      this.props.fetchExpentDirectoryProducts({
        field_setname: "all",
        text: searchObj.text,
        filter_fields: {
          state: null,
          feature_ids: null,
          subcategory_ids: null,
          platform_ids: null,
        },
        page: 0,
        page_size: 10,
        sort_fields: [],
      });
    } else {
      console.log(searchObj, "searchObj00002");
      searchObj.filter_fields = {};
      if (selectedState && selectedState.length > 0) {
        searchObj.filter_fields["state"] = selectedState;
      }
      if (activeCategoryId !== null) {
        searchObj.filter_fields["category_ids"] = [parseInt(activeCategoryId)];
      } else {
        if (searchObj.filter_fields["category_ids"]) {
          delete searchObj.filter_fields["category_ids"];
        }
      }
      searchObj.sort_fields = [];
      if (filters && filters?.sortby && filters.sortby !== "relevance") {
        if (filters?.order && filters.order === "most") {
          searchObj.sort_fields = [`-${filters?.sortby}`];
        } else {
          searchObj.sort_fields = [filters?.sortby];
        }
      }

      var searchText = "";
      if (searchObj?.text.length > 2) {
        searchText = searchObj?.text;
      }
      searchObj.text = searchText;
      this.props.fetchAppCatlogProducts(searchObj);
    }
  }

  productStates = (obj) => {
    if (obj) {
      const data = Object.keys(obj).map((key) => ({
        StateName: key,
        StateID: obj[key],
      }));
      return data;
    } else {
      return [];
    }
  };

  handleOnClickState(newState) {
    let selectedState = this.state.selectedState;
    if (selectedState.includes(newState)) {
      selectedState = selectedState.filter((o) => o !== newState);
    } else {
      selectedState.push(newState);
    }
    this.setState(
      {
        selectedState,
        searchObj: { ...this.state.searchObj, qtype_page: null },
        orgProductsNextPage: 0,
        featuredProductsNextPage: 0,
        groupsProductsNextPage: 0,
        otherProductsNextPage: 0,
      },
      () => {
        this.fetchData();
      }
    );
  }

  addToCart(id) {
    if (this.props.evaluationCart == null) {
      this.props.createEvaluationCart(parseInt(id));
    } else {
      var addedToCart = this.props.evaluationCart == null ? [] : this.props.evaluationCart?.Products?.map((p) => p.ID);
      if (addedToCart.indexOf(parseInt(id)) !== -1) {
        this.props.removeProductFromCart(this.props.evaluationCart.ID, parseInt(id));
      } else {
        this.props.createEvaluationCart(parseInt(id), this.props.evaluationCart.ID);
      }
    }
  }

  reviewProduct = (id) => {
    let redirectPath = `/app/products/product-review/${id}/`;
    this.props.history.push(redirectPath);
  };

  setSearchType = (type) => {
    const searchObj = { ...this.state.searchObj };
    if (type === "features") {
      searchObj.field_setname = "features";
    } else if (type === "subcategories") {
      searchObj.field_setname = "subcategories";
    }

    searchObj.qtype_page = null;
    // searchObj.text = "";
    this.setState({ searchObj }, () => {
      this.fetchData();
    });
  };

  handleActionClick = (event) => {
    this.setState({ showActionMenu: event.currentTarget });
  };

  handleActionClose = () => {
    this.setState({ showActionMenu: null });
  };

  handleCusterProduct = (product) => {
    let products = [...this.props.clusterProducts];
    if (products.findIndex((obj) => obj.ID === parseInt(product.id)) < 0) {
      const data = {
        AccessLink: product.access_link,
        CompanyName: product.company_name,
        Description: product.description,
        ID: parseInt(product.id),
        IsExisting: true,
        Name: product.name,
        ProductGroups: [],
        ProductLogo: product.logo,
        ProductStates: [],
      };
      products.push(data);
    } else {
      products = products.filter((o) => o.ID !== parseInt(product.id));
    }
    this.props.storeClusterGroupProducts(products);
  };

  isSelectedGroupProduct = (id) => {
    const products = this.props.clusterProducts || [];
    return products.findIndex((obj) => obj.ID === id) > -1;
  };

  handleSimilarExistin = () => {
    this.setState(
      {
        isProduct: true,
        isSimilarExistingProduct: true,
        searchObj: { ...this.state.searchObj, field_setname: "products" },
      },
      () => this.fetchData()
    );
  };

  handleUpdateTeamProducts = () => {
    let products = this.props.clusterProducts || [];
    if ((!this.props.groupProducts || this.props.groupProducts.length === 0) && products.length <= 0) {
      this.props.showSnackBar("Please select products", "error", 3000);
    } else {
      let productIds = products.map((product) => product.ID);
      console.log({ products });
      let data = { products: productIds };
      this.props.updateOrgGroupProduct(this.state.teamId, data);
    }
  };

  getProductsCount = () => {
    switch (this.state.activeProductsTabs) {
      case 1:
        return this.props.fetchAppCatalogProductsProgress ? 0 : this.props.appCatalogProducts?.featured?.total || 0;
      case 2:
        return this.props.fetchAppCatalogProductsProgress ? 0 : this.props.appCatalogProducts?.existing?.total || 0;
      case 3:
        return this.props.fetchAppCatalogProductsProgress ? 0 : this.props.appCatalogProducts?.groups?.total || 0;
      case 4:
        return this.props.fetchAppCatalogProductsProgress ? 0 : this.props.appCatalogProducts?.others?.total || 0;
      default:
        let count = 0;

        count += this.props.appCatalogProducts?.existing?.total || 0;
        count += this.props.appCatalogProducts?.featured?.total || 0;
        count += this.props.appCatalogProducts?.others?.total || 0;
        return count;
    }
  };

  checkHasMore = () => {
    switch (this.state.activeProductsTabs) {
      case 1:
        return this.props.fetchAppCatalogProductsProgress ? false : this.props.appCatalogProducts?.featured?.total > this.state.featuredProducts.length;
      case 2:
        return this.props.fetchAppCatalogProductsProgress ? false : this.props.appCatalogProducts?.existing?.total > this.state.orgProducts.length;
      case 3:
        return this.props.fetchAppCatalogProductsProgress ? false : this.props.appCatalogProducts?.groups?.total > this.state.groupsProducts.length;
      case 4:
        return this.props.fetchAppCatalogProductsProgress ? false : this.props.appCatalogProducts?.others?.total > this.state.otherProducts.length;
      default:
        return false;
    }
  };

  getNextData = () => {
    let { searchObj, activeProductsTabs } = this.state;

    switch (activeProductsTabs) {
      case 1:
        this.setState(
          (prevState) => ({
            featuredProductsNextPage: prevState.featuredProductsNextPage + 1,
            searchObj: { ...searchObj, qtype_page: ["featured", this.state.featuredProductsNextPage + 1] },
          }),
          () => this.fetchData()
        );
        break;
      case 2:
        this.setState(
          (prevState) => ({
            orgProductsNextPage: prevState.orgProductsNextPage + 1,
            searchObj: { ...searchObj, qtype_page: ["existing", prevState.orgProductsNextPage + 1] },
          }),
          () => this.fetchData()
        );
        break;
      case 3:
        this.setState(
          (prevState) => ({
            groupsProductsNextPage: prevState.groupsProductsNextPage + 1,
            searchObj: { ...searchObj, qtype_page: ["groups", prevState.groupsProductsNextPage + 1] },
          }),
          () => this.fetchData()
        );
        break;
      case 4:
        this.setState(
          (prevState) => ({
            otherProductsNextPage: prevState.otherProductsNextPage + 1,
            searchObj: { ...searchObj, qtype_page: ["others", prevState.otherProductsNextPage + 1] },
          }),
          () => this.fetchData()
        );
        break;
      default:
    }
  };

  getDataLength = () => {
    switch (this.state.activeProductsTabs) {
      case 1:
        return this.props.appCatalogProducts?.featured?.items?.length || 0;
      case 2:
        return this.props.appCatalogProducts?.existing?.items?.length || 0;
      case 3:
        return this.props.appCatalogProducts?.groups?.items?.length || 0;
      case 4:
        return this.props.appCatalogProducts?.others?.items?.length || 0;
      default:
        return null;
    }
  };

  printGroupsCount = (count) => {
    return count > 1 ? `${count} Groups Found` : `${count} Group Found`;
  };

  printProductsCount = (count) => {
    return count > 1 ? `${count} Products Found` : `${count} Product Found`;
  };

  handleRedirect = (type) => {
    if (type === "VENDOR") {
      this.props.history.push(`/app/vendors/org-vendors`);
    } else if (type === "SOFTWARE") {
      this.props.history.push(`/app/products/org-products`);
    } else if (type === "HARDWARE") {
      this.props.history.push(`/app/vendors/org-hardwares`);
    } else if (type === "SERVICE") {
      this.props.history.push(`/app/vendors/org-services`);
    }
  };

  saveQueryParams = (tab, view) => {
    this.props.history.push(`/app/products/org-products?activeTab=${tab}&activeView=${view}`)
  }

  render() {
    const { classes, appCatalogProducts, expentDirectoryProducts, fetchAppCatalogProductsProgress, fetchExpentDirectoryProductsProgress, updateGroupProductProg } = this.props;
    let userRole = ["OrgAdmin", "OrgManager"];
    const adminFlag = userRole.indexOf(this.props.userType) > -1;
    var showAddProduct = false;
    const EnableAddProductForUserCheck = isEnabledReview(this.props?.orgUser?.fetchAllOrgSettingsSuccess, "EnableAddProductForUserCheck");
    if (this.props.userType === "OrgUser") {
      if (EnableAddProductForUserCheck) {
        showAddProduct = true;
      }
    } else {
      showAddProduct = true;
    }
    const { searchObj, isProduct } = this.state;
    let orgExistingProduct = [];
    let selectedOptions = [];
    if (isProduct && (expentDirectoryProducts?.existing?.items || expentDirectoryProducts?.others?.items)) {
      let existingProducts = [];
      let nonExistingProducts = [];

      let org_products = expentDirectoryProducts?.existing?.items || [];
      let products = expentDirectoryProducts?.others?.items || [];

      if (org_products.length > 0) {
        existingProducts = org_products.slice(0, 5).map((obj) => ({
          label: obj.name,
          company_name: obj.company_name,
          value: obj.name,
          id: obj.id,
          isExisting: true,
        }));
        if (this.state.selectedProduct !== null) {
          orgExistingProduct = org_products.filter((product) => parseInt(product.id) === parseInt(this.state.selectedProduct));
        }
      }
      if (products.length > 0) {
        nonExistingProducts = products.slice(0, 5).map((obj) => ({
          label: obj.name,
          company_name: obj.company_name,
          value: obj.name,
          id: obj.id,
          isExisting: false,
        }));
      }
      selectedOptions = [...existingProducts, ...nonExistingProducts];
    }

    let count = this.getProductsCount();

    const DisableVendor = isEnabledEvaluate(this.props?.orgUser?.fetchAllOrgSettingsSuccess, 'DisableCatalogFeature')

    return (
      <>
        <div className={classes.container}>
          <AppBarV2 title={this.props.orgName ? this.props.orgName + "'s Application Catalog" : "Application Catalog"} withBack={true} dropdown={true} />

          <div className={classes.productMain}>
            <div style={{ width: "15%", minWidth: "230px" }}>
              <div className={classes.subCategory}>
                <div
                  onClick={() => {
                    if (this.state.showGroupView) {
                      this.props.showSnackBar("Only existing products can be select", "error", 3000);
                      return;
                    }
                    this.setState({ activeProductsTabs: null, selectedView: "grid" }, () => {
                      this.saveQueryParams(0, 'grid');
                    });
                  }}
                  className={classnames(classes.productsSection, !this.state.activeProductsTabs ? classes.activeProductsTabs : "")}
                >
                  <div className={classnames(classes.isAllProducts, classes.labelInitProducts)}>A</div>
                  <div>All</div>
                </div>
                <Divider />
                <div
                  onClick={() => {
                    if (this.state.showGroupView) {
                      this.props.showSnackBar("Only existing products can be select", "error", 3000);
                      return;
                    }
                    this.setState({ activeProductsTabs: 1 }, () => {
                      this.saveQueryParams(1, this.state.selectedView);
                    });
                  }}
                  className={classnames(classes.productsSection, this.state.activeProductsTabs === 1 ? classes.activeProductsTabs : "")}
                >
                  <div className={classnames(classes.isFeaturedProducts, classes.labelInitProducts)}>F</div>
                  <div>Featured Products</div>
                  <div className={classes.productCounts}>{appCatalogProducts?.featured?.total || 0}</div>
                </div>
                <Divider />
                <div
                  onClick={() =>
                    this.setState({ activeProductsTabs: 2 }, () => {
                      this.saveQueryParams(2, this.state.selectedView);
                    })
                  }
                  className={classnames(classes.productsSection, this.state.activeProductsTabs === 2 ? classes.activeProductsTabs : 0)}
                >
                  <div className={classnames(classes.isExistingProducts, classes.labelInitProducts)}>E</div>
                  <div>Existing Products</div>
                  <div className={classes.productCounts}>{appCatalogProducts?.existing?.total || 0}</div>
                </div>
                <Divider />
                <div
                  onClick={() => {
                    if (this.state.showGroupView) {
                      this.props.showSnackBar("Only existing products can be select", "error", 3000);
                      return;
                    }
                    this.setState({ activeProductsTabs: 3, selectedView: "grid" }, () => {
                      this.saveQueryParams(3, this.state.selectedView);
                    });
                  }}
                  className={classnames(classes.productsSection, this.state.activeProductsTabs === 3 ? classes.activeProductsTabs : "")}
                >
                  <img src={groupIconImage} className={classes.isGroupProducts} alt="groupIcon" />
                  <div>Groups</div>
                  <div className={classes.productCounts}>{appCatalogProducts?.groups?.total || 0}</div>
                </div>
                <Divider />
                <div
                  onClick={() => {
                    if (this.state.showGroupView) {
                      this.props.showSnackBar("Only existing products can be select", "error", 3000);
                      return;
                    }
                    this.setState({ activeProductsTabs: 4, selectedView: "grid" }, () => {
                      this.saveQueryParams(4, this.state.selectedView);
                    });
                  }}
                  className={classnames(classes.productsSection, this.state.activeProductsTabs === 4 ? classes.activeProductsTabs : 0)}
                >
                  <div className={classnames(classes.isOtherProducts, classes.labelInitProducts)}>O</div>
                  <div>Other Products</div>
                  <div className={classes.productCounts}>{appCatalogProducts?.others?.total || 0}</div>
                </div>
                <Accordion style={{ boxShadow: "none" }}>
                  <AccordionSummary expanded={true} disabled={this.state.showGroupView} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TuneIcon />
                      <div style={{ marginLeft: "7px" }}>Filter Categories</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: "column" }}>
                    <input
                      className={classes.inputFormControl}
                      placeholder="Search"
                      name="search"
                      onChange={(e) => {
                        if (e.target.value && e.target.value.length) {
                          let categories = [...(this.props.allCategories?.Data || [])];
                          let filterCategories = categories.filter((item) => item.Name.toLowerCase().includes(e.target.value.toLowerCase()));
                          this.setState({ allCategories: filterCategories });
                        } else {
                          this.setState({ allCategories: this.props.allCategories?.Data || [] });
                        }
                      }}
                    />
                    <p
                      className={classnames(classes.innerText, this.state.activeCategoryId === null ? classes.activeSubCategory : "")}
                      onClick={() => {
                        this.setState(
                          {
                            searchObj: { ...searchObj, qtype_page: null },
                            activeCategoryId: null,
                            isProduct: false,
                            orgProductsNextPage: 0,
                            featuredProductsNextPage: 0,
                            groupsProductsNextPage: 0,
                            otherProductsNextPage: 0,
                          },
                          () => {
                            this.fetchData();
                          }
                        );
                      }}
                    >
                      <b>All</b>
                    </p>

                    {this.state.allCategories.map((category, index) => (
                      <p
                        key={`Cat_${index}`}
                        className={classnames(classes.innerText, this.state.activeCategoryId === category.ID ? classes.activeSubCategory : "")}
                        onClick={() => {
                          this.setState(
                            {
                              searchObj: { ...searchObj, qtype_page: null },
                              activeCategoryId: category.ID,
                              isProduct: false,
                              orgProductsNextPage: 0,
                              featuredProductsNextPage: 0,
                              groupsProductsNextPage: 0,
                              otherProductsNextPage: 0,
                            },
                            () => {
                              this.fetchData();
                            }
                          );
                        }}
                      >
                        {category.Name}
                      </p>
                    ))}

                    {!this.props.fetchAllCategoryProgress && this.state.allCategories.length === 0 && <p className={classes.innerText}>Not found</p>}
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div className={this.state.selectedView === "grid" ? classes.root : classes.rootTable}>
              <Breadcrumb routeList={[{ name: "Vendors", path: "/app/products" }, { name: "Product Catalog" }]} />
              {this.state.selectedView === "grid" && (
                <div className={classes.stickyBar}>
                  {!DisableVendor && (
                    <ProductTypeFilter
                      type={this.state.productType}
                      hideHardware
                      onChange={(type) => {
                        this.handleRedirect(type);
                      }}
                      isShowProductTab={this.state.orgExistingVendorCount > 0 || this.state.orgExistingServiceCount > 0 ? true : false}
                      isShowVendorTab={this.state.orgExistingVendorCount > 0 ? true : false}
                      isShowServiceTab={this.state.orgExistingServiceCount > 0 ? true : false}
                    />
                  )}
                  <Grid
                    container
                    justifyContent="space-between"
                    ref={(divElement) => {
                      this.divElement = divElement;
                    }}
                    className={classes.productSectionNew}
                  >
                    <Grid item style={{ flex: "15%" }}>
                      {this.state.activeProductsTabs && !this.state.isProduct && (
                        <p className={classes.productCount}>{!isProduct && this.state.activeProductsTabs === 3 ? this.printGroupsCount(count) : this.printProductsCount(count)}</p>
                      )}
                    </Grid>

                    <Grid item style={{ flex: "55%" }}>
                      <SearchInput
                        page="app_catalog"
                        isSubCategory={false}
                        searchIcon={false}
                        hideFeatured={true}
                        isSelectInput={isProduct}
                        loadingOption={fetchExpentDirectoryProductsProgress}
                        dropdownOptions={selectedOptions}
                        onClickOption={(e) => {
                          this.setState(
                            {
                              selectedProduct: e.id,
                            },
                            () => {
                              this.props.fetchOrgProductsSimilarExisting(e.id);
                            }
                          );
                        }}
                        filters={this.state.filters}
                        selectedState={this.state.selectedState}
                        onChangeState={(newState) => {
                          this.setState({ orgProducts: [], featuredProducts: [], groupsProducts: [], otherProducts: [] });
                          this.handleOnClickState(newState);
                        }}
                        showState={!isProduct}
                        onChangeSelectInput={(text) => {
                          this.setState(
                            {
                              orgProducts: [],
                              featuredProducts: [],
                              groupsProducts: [],
                              otherProducts: [],
                              orgProductsNextPage: 0,
                              featuredProductsNextPage: 0,
                              groupsProductsNextPage: 0,
                              otherProductsNextPage: 0,
                              searchObj: { ...searchObj, text, qtype_page: null },
                            },
                            () => {
                              this.fetchData();
                              this.props.storeSearchString(text, this.state.searchObj.field_setname);
                            }
                          );
                        }}
                        onChangeFilter={(filters) => {
                          this.setState(
                            {
                              filters,
                              orgProducts: [],
                              featuredProducts: [],
                              groupsProducts: [],
                              otherProducts: [],
                              orgProductsNextPage: 0,
                              featuredProductsNextPage: 0,
                              groupsProductsNextPage: 0,
                              otherProductsNextPage: 0,
                              searchObj: { ...searchObj, qtype_page: null },
                            },
                            () => {
                              this.fetchData();
                            }
                          );
                        }}
                        searchObj={searchObj}
                        onChange={(searchObj) => {
                          delete searchObj.page;
                          delete searchObj.type;
                          const isProduct = searchObj.field_setname === "products";
                          const data = {
                            searchObj: { ...searchObj, qtype_page: null },
                            isProduct,
                            orgProducts: [],
                            featuredProducts: [],
                            groupsProducts: [],
                            otherProducts: [],
                            orgProductsNextPage: 0,
                            featuredProductsNextPage: 0,
                            groupsProductsNextPage: 0,
                            otherProductsNextPage: 0,
                          };
                          if (isProduct) {
                            data.selectedProduct = null;
                            // data.searchObj.text = "";
                          }
                          if (this.props.orgProductsSimilarExisting.length > 0) {
                            this.props.resetSimilarExistingProducts()
                          }
                          this.setState(data, () => {
                            this.props.storeSearchString(data.searchObj.text, searchObj.field_setname);
                            this.fetchData();
                          });
                        }}
                      />
                      {!this.state.isTeamProductUpdate && this.props.personalizedRecommendationsProducts !== null && this.props.personalizedRecommendationsProducts.length > 0 && (
                        <Grid container justify="center">
                          <Link to="/app/products/org-products/user-recommendation">
                            <Typography align="center" className={classes.recommendedText}>
                              You have {this.props.personalizedRecommendationsProducts.length} product recommendations.
                            </Typography>
                          </Link>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item style={{ flex: "15%" }}>
                      {adminFlag && (
                        <>
                          {this.state.showGroupView ? (
                            <div className={classes.flexWrapIcons}>
                              {!updateGroupProductProg ? (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    if (this.state.isTeamProductUpdate) {
                                      this.handleUpdateTeamProducts();
                                    } else {
                                      const products = this.props.clusterProducts || [];
                                      if (products.length === 0) {
                                        this.props.showSnackBar("Please select atleast one product", "error", 3000);
                                        return;
                                      }
                                      this.props.showCreateClusterGroupDialog(true);
                                    }
                                  }}
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                >
                                  {this.state.isTeamProductUpdate ? "Update Team Products" : "Create Group"}
                                </Button>
                              ) : (
                                <Button aria-controls="simple-menu" aria-haspopup="true" variant="contained" color="secondary">
                                  Loading...
                                </Button>
                              )}
                              <Button
                                aria-controls="simple-menu"
                                variant="outlined"
                                color="secondary"
                                aria-haspopup="true"
                                onClick={() => {
                                  this.props.storeClusterGroupProducts([]);
                                  if (this.state.isTeamProductUpdate) {
                                    this.props.history.goBack();
                                    return;
                                  }
                                  this.setState({
                                    showGroupView: false,
                                    isCreateGroup: false,
                                  });
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          ) : (
                            <div className={classes.flexWrapIcons}>
                              <Tooltip
                                title={<span style={{ fontSize: "13px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>Add Software Product</span>}
                                placement="top"
                                arrow
                              >
                                <Button
                                  color="primary"
                                  variant={"contained"}
                                  onClick={() => {
                                    this.props.showAddProductManuallyDialog();
                                  }}
                                >
                                  <PostAddIcon />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                title={<span style={{ fontSize: "13px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>Group</span>}
                                placement="top"
                                arrow
                              >
                                <Button
                                  color="primary"
                                  variant={"contained"}
                                  onClick={() => {
                                    this.setState({
                                      showGroupView: true,
                                      isCreateGroup: true,
                                      activeProductsTabs: 2,
                                    });
                                  }}
                                >
                                  <CreateNewFolderIcon />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                title={<span style={{ fontSize: "13px", whiteSpace: "pre-line", display: "inline-block", height: "25px", lineHeight: "25px" }}>Import Data</span>}
                                placement="top"
                                arrow
                              >
                                <Button
                                  color="primary"
                                  variant={"contained"}
                                  onClick={() => {
                                    this.setState({ isOpenImportProductDialog: true });
                                  }}
                                >
                                  <ImportExportIcon />
                                </Button>
                              </Tooltip>
                            </div>
                          )}
                        </>
                      )}
                      {!adminFlag && showAddProduct && (
                        <Tooltip title="Add Vendor" placement="top" arrow>
                          <Button
                            color="primary"
                            variant={"contained"}
                            onClick={() => {
                              this.props.showAddProductManuallyDialog();
                            }}
                          >
                            <PostAddIcon />
                          </Button>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}

              {adminFlag && !this.state.showGroupView && (this.state.activeProductsTabs === 1 || this.state.activeProductsTabs === 2) && (
                <div style={{ textAlign: "right", marginBottom: 10 }}>
                  <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Tooltip title="Grid View">
                      <Button
                        variant={this.state.selectedView === "grid" ? "contained" : "outlined"}
                        onClick={() => {
                          this.setState({
                            selectedView: "grid",
                          });
                          this.saveQueryParams(this.state.activeProductsTabs, "grid");
                          this.fetchData();
                        }}
                      >
                        <AppsIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Tabular View">
                      <Button
                        variant={this.state.selectedView === "table" ? "contained" : "outlined"}
                        onClick={() => {
                          this.setState({
                            selectedView: "table",
                          });
                          this.saveQueryParams(this.state.activeProductsTabs, "table");
                        }}
                      >
                        <ListIcon />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </div>
              )}

              {this.state.selectedView === "grid" && (
                <div className={classes.productContainer} id="productContainer">
                  {!isProduct && (
                    <InfiniteScroll
                      scrollableTarget="productContainer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        marginRight: 20,
                        paddingLeft: 20,
                      }}
                      dataLength={this.getDataLength()}
                      next={() => {
                        this.getNextData();
                      }}
                      hasMore={this.checkHasMore()}
                      endMessage={
                        <>
                          {!fetchAppCatalogProductsProgress && searchObj.text && (
                            <div>
                              <p className={classnames(classes.emptyText, classes.addProductLink)} onClick={() => this.handleSimilarExistin()}>
                                Similar Existing Products
                              </p>
                              <p className={classes.emptyText}>
                                <span>
                                  <span>Not finding what you are looking for? Search the </span>
                                  <span className={classes.addProductLink} onClick={() => this.setSearchType("features")}>
                                    features
                                  </span>
                                  <span> and </span>
                                  <span className={classes.addProductLink} onClick={() => this.setSearchType("subcategories")}>
                                    sub-categories.
                                  </span>
                                  <span> or in the </span>
                                  <span
                                    className={classes.addProductLink}
                                    onClick={() => {
                                      this.props.history.push(`/app/products/all-products?s=${this.state.searchObj.text}`);
                                    }}
                                  >
                                    Expent's product directory
                                  </span>
                                  <span> for {this.state.searchObj.text}.</span>
                                </span>
                              </p>
                            </div>
                          )}
                        </>
                      }
                    >
                      <>
                        {(!this.state.activeProductsTabs || this.state.activeProductsTabs === 1) && appCatalogProducts?.featured?.items && (
                          <ProductsCard
                            data={!this.state.activeProductsTabs ? this.state.featuredProducts.slice(0, 6) : this.state.featuredProducts}
                            label={"Featured Products"}
                            activeProductsTabs={this.state.activeProductsTabs}
                            setActiveProductsTab={() => this.setState({ activeProductsTabs: 1 })}
                            isCreateGroup={this.state.isCreateGroup}
                            isSelectedGroupProduct={(id) => this.isSelectedGroupProduct(id)}
                            handleCusterProduct={(status, id) => this.handleCusterProduct(status, id)}
                            reviewProduct={(id) => this.reviewProduct(id)}
                            productStates={(state) => this.productStates(state)}
                            handleOnClickState={(state) => this.handleOnClickState(state)}
                            classes={classes}
                            allProductsLength={appCatalogProducts?.featured?.total || 0}
                            isOrg={true}
                            isShowGroupName={true}
                          />
                        )}

                        {(!this.state.activeProductsTabs || this.state.activeProductsTabs === 2) && appCatalogProducts?.existing?.items && (
                          <ProductsCard
                            data={!this.state.activeProductsTabs ? this.state.orgProducts.slice(0, 6) : this.state.orgProducts}
                            label={"Existing Products"}
                            activeProductsTabs={this.state.activeProductsTabs}
                            setActiveProductsTab={() => this.setState({ activeProductsTabs: 2 })}
                            isCreateGroup={this.state.isCreateGroup}
                            isSelectedGroupProduct={(id) => this.isSelectedGroupProduct(id)}
                            handleCusterProduct={(status, id) => this.handleCusterProduct(status, id)}
                            reviewProduct={(id) => this.reviewProduct(id)}
                            productStates={(state) => this.productStates(state)}
                            handleOnClickState={(state) => this.handleOnClickState(state)}
                            classes={classes}
                            allProductsLength={appCatalogProducts?.existing?.total || 0}
                            isOrg={true}
                            isShowGroupName={true}
                          />
                        )}

                        {(!this.state.activeProductsTabs || this.state.activeProductsTabs === 3) && !this.state.isCreateGroup && appCatalogProducts?.groups?.items && (
                          <GroupsCard
                            data={!this.state.activeProductsTabs ? this.state.groupsProducts.slice(0, 6) : this.state.groupsProducts}
                            label={"Groups"}
                            activeProductsTabs={this.state.activeProductsTabs}
                            setActiveProductsTab={() => this.setState({ activeProductsTabs: 3 })}
                            classes={classes}
                            allProductsLength={appCatalogProducts?.groups?.total}
                            isOrg={true}
                          />
                        )}

                        {(!this.state.activeProductsTabs || this.state.activeProductsTabs === 4) && appCatalogProducts?.others?.items && (
                          <ProductsCard
                            data={!this.state.activeProductsTabs ? this.state.otherProducts.slice(0, 6) : this.state.otherProducts}
                            label={"Other Products"}
                            activeProductsTabs={this.state.activeProductsTabs}
                            setActiveProductsTab={() => this.setState({ activeProductsTabs: 4 })}
                            isCreateGroup={this.state.isCreateGroup}
                            isSelectedGroupProduct={(id) => this.isSelectedGroupProduct(id)}
                            handleCusterProduct={(status, id) => this.handleCusterProduct(status, id)}
                            reviewProduct={(id) => this.addToCart(id)}
                            productStates={(state) => this.productStates(state)}
                            handleOnClickState={(state) => this.handleOnClickState(state)}
                            classes={classes}
                            allProductsLength={appCatalogProducts?.others?.total || 0}
                            isOrg={false}
                            isShowGroupName={false}
                          />
                        )}
                      </>
                      {(fetchAppCatalogProductsProgress || updateGroupProductProg) && (
                        <Grid container justify="center" style={{ marginTop: 30 }}>
                          <CircularProgress />
                        </Grid>
                      )}
                    </InfiniteScroll>
                  )}
                  {isProduct && (
                    <InfiniteScroll
                      scrollableTarget="productContainer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        marginRight: 20,
                      }}
                      dataLength={0}
                      next={() => { }}
                      hasMore={false}
                      endMessage={<></>}
                    >
                      {this.state.selectedProduct !== null && (
                        <>
                          {orgExistingProduct && orgExistingProduct.length > 0 ? (
                            <Grid container spacing={3} style={{ marginBottom: 20 }}>
                              {orgExistingProduct.map((product, i) => (
                                <Grid item lg={2} md={3} sm={4} xs={6} key={`orgExistingProduct-${product.id}`}>
                                  <LayeredCard
                                    isActionButtons
                                    title={product.name || product.company_name || ""}
                                    subTitle={product.company_name || product.name || ""}
                                    requestLink={product.access_link}
                                    description={product.description || ""}
                                    image={product.logo || ""}
                                    toogleCheck={(e) => {
                                      this.reviewProduct(product.id);
                                    }}
                                    IsExisting
                                    onClick={() => { }}
                                    numLayer={0}
                                    showCheckbox={true}
                                    id={product.id}
                                    productStates={this.productStates(product.states)}
                                    uniqueId={product.id + "_o_" + i}
                                    customRoute={"/app/products/org-product-details/"}
                                    type={"product"}
                                    typeOfFeatured="featured"
                                    typeOfBlock="all_product_blocking"
                                    showReactionResponses
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          ) : (
                            <p className={classes.emptyText}>
                              <span>
                                <span>The product that you chose is not in the org, please search from the </span>
                                <span
                                  className={classes.addProductLink}
                                  onClick={() => {
                                    this.props.history.push(`/app/products/all-products?s=${this.state.searchObj.text}`);
                                  }}
                                >
                                  Expent product directory.
                                </span>
                              </span>
                            </p>
                          )}
                          {!this.props.fetchOrgProductsSimilarExistingProgress && this.props.orgProductsSimilarExisting && this.props.orgProductsSimilarExisting.length > 0 && (
                            <>
                              <Grid container alignItems="center" justifyContent="space-between">
                                <Typography className={classes.label}>Similar Existing Products</Typography>
                              </Grid>
                              <Grid container spacing={3}>
                                {/* Products list */}
                                {this.props.orgProductsSimilarExisting.map((product, i) => (
                                  <Grid item lg={2} md={3} sm={4} xs={6} key={product.ID}>
                                    <LayeredCard
                                      isActionButtons
                                      title={product.Name}
                                      subTitle={product.CompanyName}
                                      description={product.Description}
                                      requestLink={product?.AccessLink}
                                      image={product.ProductLogo === null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo}
                                      toogleCheck={(e) => {
                                        this.reviewProduct(product.ID);
                                      }}
                                      IsExisting
                                      onClick={() => { }}
                                      numLayer={0}
                                      //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                                      showCheckbox={true}
                                      id={product.ID}
                                      productStates={product?.ProductStates}
                                      uniqueId={product.ID + "_o_" + i}
                                      customRoute={"/app/products/org-product-details/"}
                                      typeOfFeatured="featured"
                                      type={"product"}
                                      showReactionResponses
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                              {(this.props.fetchOrgProductsSimilarExistingProgress || updateGroupProductProg) && <CircularProgress />}
                            </>
                          )}
                        </>
                      )}
                    </InfiniteScroll>
                  )}
                </div>
              )}

              {this.state.selectedView === "table" && (
                <div>
                  <AppCatalogTabular activeTab={this.state.activeProductsTabs} />
                </div>
              )}
            </div>
          </div>
        </div>
        {
          this.props.addProductManuallyDialogOpen &&
          <AddProductManuallyDialog />
        }
        {this.props._createClusterGroupDialog && <CreateClusterGroupProductDialog isNew={true} />}
        {this.state.isOpenImportProductDialog && <ImportProductDialog isOpen={this.state.isOpenImportProductDialog} onClose={() => this.setState({ isOpenImportProductDialog: false })} />}
        {this.props.showNewWorkflowStartDialog && <StartWorkflowDialog />}
        {this.props.isOpenReqAccInfoDialog && <RequestAccessInformation />}
      </>
    );
  }
}

export default connector(compose(withStyles(styles), withLastLocation)(AppHardwareCatalog));
