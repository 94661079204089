import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
  InputAdornment, Paper, Stepper, Step, StepLabel, Button
  , CircularProgress, Grid, Typography,
  Table, TableBody, TableCell, TableRow, TableHead,
  Select, MenuItem
} from "@material-ui/core";
import AppBarV2 from "../../../AppBarV2";
import Alert from '@material-ui/lab/Alert';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import { Search as SearchIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { fetchIntegrationStatus, updateQuickSightIntegration, fetchQSUserRoleAssociation, updateUserRoleAssociation, removeUserRoleAssociation } from "redux/quicksight/action";
import { searchUser } from "redux/usersearch/action"

import Clear from "@material-ui/icons/Clear";
import TooltipMsg from '@material-ui/core/Tooltip';
import CommonCss from 'commonCss';

import classnames from "classnames";
import {
  showMessageDialog,
  hideMessageDialog,
} from "redux/applications/action";
import MessageDialog from "Components/Common/MessageDialog";


const connectedProps = (state) => ({
  integrationStatusProg: state.quicksight.fetchStatusProg,
  integrationStatus: state.quicksight.integrationStatus,
  updateProg: state.quicksight.updateIntegrationProg,
  updateError: state.quicksight.updateIntegrationError,
  userRoles: state.quicksight.userRoles,
  roleAssociationProgress: state.quicksight.roleAssociationProgress,
  searchProgress: state.userSearch.searchProgress,
  searchResult: state.userSearch.users,
});

const connectionActions = {
  fetchIntegrationStatus: fetchIntegrationStatus,
  updateQuickSightIntegration: updateQuickSightIntegration,
  searchUser: searchUser,
  updateUserRoleAssociation: updateUserRoleAssociation,
  fetchQSUserRoleAssociation: fetchQSUserRoleAssociation,
  removeUserRoleAssociation: removeUserRoleAssociation,
  showMessageDialog: showMessageDialog,
  hideMessageDialog: hideMessageDialog,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  root: {
    padding: "10px 20px",
    marginTop: "20px !important",
    margin: "20px !important",
    minHeight: "700px"
  },
  enableForm: {
    width: "45%",
    margin: '0px auto',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    border: "solid 1px #ccc",
    padding: theme.spacing(2, 6),
    borderRadius: theme.spacing(1)
  },
  alert: {
    margin: '30px auto',
  },
  formAction: {
    textAlign: "right"
  },
  disableBtn: {
    textAlign: "right"

  },
  removeBtn: {
    cursor: "pointer",
    color: "red"
  },
  searchIconPop: {
    cursor: 'pointer',
    fontSize: theme.spacing(3),
    marginRight: '10px',
    "&:hover": {
      color: "#fff !important",
      fill: "#fff !important",
      background: "#707070",
      borderColor: "#707070",
      padding: '3px',
      borderRadius: theme.spacing(20),
      fontSize: theme.spacing(3)
    }
  },
});

class QuicksightConfig extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: "",
      numAuthor: "",
      numViewer: "",
      userQuery: "",
      deleteId: null,
    }
    this.enableQuickSight = this.enableQuickSight.bind(this);
    this.disableQuickSight = this.disableQuickSight.bind(this);
    this.updateUserRole = this.updateUserRole.bind(this);
    this.handleClear = this.handleClear.bind(this);

  }

  componentDidMount() {
    this.props.fetchIntegrationStatus();
    this.props.fetchQSUserRoleAssociation()
  }

  componentDidUpdate(prevProps) {
    if (this.props.roleAssociationProgress === false && prevProps.roleAssociationProgress === true) {
      this.props.fetchQSUserRoleAssociation()
      this.setState({
        userQuery: ""
      })
    }
  }

  enableQuickSight() {
    this.setState({
      error: ""
    });

    var numAuthor = this.state.numAuthor;
    if (numAuthor.trim().length === 0 || isNaN(numAuthor)) {
      this.setState({
        error: "Please enter number of author you want to give access."
      });
      return;
    }
    numAuthor = parseInt(numAuthor)

    if (numAuthor <= 0) {
      this.setState({
        error: "Please enter number of authors greater than zero."
      });
      return;
    }

    var numViewer = this.state.numViewer;
    if (numViewer.trim().length === 0 || isNaN(numAuthor)) {
      this.setState({
        error: "Please enter number of viewers you want to give access."
      });
      return;
    }
    numViewer = parseInt(numViewer)
    if (numViewer <= 0) {
      this.setState({
        error: "Please enter number of viewers greater than zero."
      });
      return;
    }
    this.props.updateQuickSightIntegration({
      num_authors: numAuthor,
      num_viewers: numViewer
    })
  }

  disableQuickSight() {
    this.props.updateQuickSightIntegration({
      num_authors: 0,
      num_viewers: 0
    })
  }

  fetchUsers() {
    this.props.searchUser(this.state.userQuery);
  }

  updateUserRole(user_id, role) {
    if (role === -1) {
      return;
    }
    this.props.updateUserRoleAssociation({
      "user_id": user_id,
      "role": role
    })
  }

  handleClear(event) {
    event.stopPropagation();
    var me = this;
    this.setState({ userQuery: '', page: 0 }, () => {
      me.fetchUsers();
    });
  }

  handleDelete = (id) => {
    var me = this;
    this.setState({deleteId: id}, () => {
        me.props.showMessageDialog();
    })
  }

  callBack = () => {
      if (this.state.deleteId) {
          this.props.hideMessageDialog();
          this.props.removeUserRoleAssociation(this.state.deleteId);
      }
  };

  render() {
    const classes = this.props.classes;

    var activeStep = 0;
    if (this.props.integrationStatus != null && (this.props.integrationStatus.NumAuthors + this.props.integrationStatus.NumViewer) > 0) {
      activeStep = 1;
    }

    let messageData = {
      head: "Are you sure?",
      subHead: "Do you really want to delete? This process cannot be undone.",
      firstBtn: "Cancel",
      secondBtn: "Yes",
    };

    var authorCount = this.props.userRoles.filter((item) => {
      return item.Type === 1
    }).length
    var viewerCount = this.props.userRoles.filter((item) => {
      return item.Type === 2
    }).length
    return <div className={'PageSettings'}>
      <AppBarV2 title="Quicksight Setup" withBack={true} />
      {this.props.integrationStatusProg !== false && <Paper className={classes.root}>
        <CircularProgress />
      </Paper>}
      {this.props.integrationStatusProg === false && <Paper className={classnames(classes.root, classes.rootContainer)}>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step >
            <StepLabel >Enable Quicksight</StepLabel>
          </Step>
          <Step >
            <StepLabel >Setup User</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 && <div className={classes.enableForm}>
          <div className={classes.alert}>
            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
          </div>
          <div className={classes.form}>
            <OutlinedInput
              id={"numAuthorInput"}
              label="No. Of Authors"
              value={this.state.numAuthor}
              onChange={(event) => {
                this.setState({ numAuthor: event.target.value });
              }}
              required={true}
            />
            <OutlinedInput
              id={"numViewerInput"}
              label="No. Of Viewers"
              value={this.state.numViewer}
              onChange={(event) => {
                this.setState({ numViewer: event.target.value });
              }}
              required={true}
            />
          </div>
          <div className={classes.formAction}>
            {this.props.updateProg === false && <Button variant="contained" color="secondary" className={classes.button} onClick={this.enableQuickSight}>Enable Quicksight</Button>}
            {this.props.updateProg && <CircularProgress />}
          </div>
        </div>}
        {activeStep === 1 && <div className={classes.quickSightUsers}>
          <Grid container >
            <Grid item xs={4}>
              <Typography>Authors : <b>{authorCount}/{this.props.integrationStatus.NumAuthors}</b>, Viewer: <b>{viewerCount}/{this.props.integrationStatus.NumViewer}</b></Typography>
            </Grid>
            <Grid item xs={4}>
              <OutlinedInput
                id={"searchVendor"}
                placeholder="Search User"
                value={this.state.userQuery}
                onChange={(event) => {
                  var me = this;
                  this.setState({ userQuery: event.target.value, page: 0 }, () => {
                    me.fetchUsers();
                  });
                }}
                startAdornment={
                  <InputAdornment>
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment>
                    {this.state.userQuery.length > 0 && this.state.userQuery !== '' && this.state.userQuery !== null && <TooltipMsg placement="top" title={<span style={{ fontSize: '14px', display: 'inline-block', height: '25px', lineHeight: '25px' }}>Clear</span>} arrow>
                      <Clear className={classes.searchIconPop} onClick={this.handleClear} />
                    </TooltipMsg>}
                  </InputAdornment>
                }
                roundedCorner={false}
              />
            </Grid>
            <Grid item xs={4}>
              <div className={classes.disableBtn}>
                {this.props.updateProg === false && <Button variant="contained" color="secondary" className={classes.button} onClick={this.disableQuickSight}>Disable Quicksight</Button>}
                {this.props.updateProg && <CircularProgress />}
              </div>
            </Grid>
          </Grid>
          {this.state.userQuery.length !== 0 && <div>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(this.props.searchResult && this.props.searchResult.length) ? this.props.searchResult?.map((user, i) => {
                  var userRole = -1;
                  var exitingData = this.props.userRoles.filter((item) => {
                    return item.User.ID === user.ID
                  })
                  if (exitingData.length > 0) {
                    userRole = exitingData[0].Type;
                  }
                  return <TableRow>
                    <TableCell>{user.Name}</TableCell>
                    <TableCell>{user.Email}</TableCell>
                    <TableCell align="center">
                      <Select
                        value={userRole}
                        onChange={(event) => {
                          this.updateUserRole(user.ID, event.target.value)
                        }}
                      >
                        <MenuItem value={-1}>
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem value={1}>Author</MenuItem>
                        <MenuItem value={2}>Viewer</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell align="center">&nbsp;</TableCell>
                  </TableRow>
                }): !this.props.searchProgress && <TableCell colSpan={4} align="center">No Users</TableCell>}
              </TableBody>
            </Table>
          </div>}
          {this.state.userQuery.length === 0 && <div>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.userRoles?.map((role, i) => {
                  return <TableRow>
                    <TableCell>{role.User.Name}</TableCell>
                    <TableCell>{role.User.Email}</TableCell>
                    <TableCell align="center">
                      <Select
                        value={role.Type}
                        onChange={(event) => {
                          this.updateUserRole(role.User.ID, event.target.value)
                        }}
                      >
                        <MenuItem value={1}>Author</MenuItem>
                        <MenuItem value={2}>Viewer</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell align="center"><DeleteIcon className={classes.removeBtn} onClick={() => {
                      this.handleDelete(role.User.ID)
                    }} /></TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>}
        </div>
        }
      </Paper>}
      <MessageDialog messageData={messageData} callBack={this.callBack} />
        
    </div>
  }
}

export default connector(compose(
  withStyles(styles)
)(QuicksightConfig));