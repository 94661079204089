import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ReactSelect from "react-select";
import { fetchCriteriaRequirementWiseAnalysisData,resetAnalysisData,fetchAnalysisCriterias, fetchAnalysisRequirements,fetchOverallAnalysisData,fetchRequirementWiseAnalysisData,fetchTeamWiseAnalysisData } from "redux/product/orgProduct/action";
import { showSnackBar } from "redux/snackbar/action";
import ScoreAnalysisChart from "./Common/ScoreAnalysisChart";
import AnalysisFilter from "./Common/AnalysisFilter";

const connectedProps = (state) => ({
  teamWiseAnalysisData: state.orgProduct.teamWiseAnalysisData,
  fetchTeamWiseAnalysisError: state.orgProduct.fetchTeamWiseAnalysisError,
  fetchTeamWiseAnalysisProgress: state.orgProduct.fetchTeamWiseAnalysisProgress,
  requirementWiseAnalysisData: state.orgProduct.requirementWiseAnalysisData,
  fetchRequirementWiseAnalysisError: state.orgProduct.fetchRequirementWiseAnalysisError,
  fetchRequirementWiseAnalysisProgress: state.orgProduct.fetchRequirementWiseAnalysisProgress,
  overallAnalysisData: state.orgProduct.overallAnalysisData,
  fetchOverallAnalysisError: state.orgProduct.fetchOverallAnalysisError,
  fetchOverallAnalysisProgress: state.orgProduct.fetchOverallAnalysisProgress,
  analysisRequirementsData: state.orgProduct.analysisRequirementsData,
  fetchAnalysisRequirementsError: state.orgProduct.fetchAnalysisRequirementsError,
  fetchAnalysisRequirementsProgress: state.orgProduct.fetchAnalysisRequirementsProgress,
  analysisCriteriasData: state.orgProduct.analysisCriteriasData,
  fetchAnalysisCriteriasError: state.orgProduct.fetchAnalysisCriteriasError,
  fetchAnalysisCriteriasProgress: state.orgProduct.fetchAnalysisCriteriasProgress,

  teamRequirementWiseAnalysisData: state.orgProduct.teamRequirementWiseAnalysisData,
  fetchTeamRequirementWiseAnalysisError: state.orgProduct.fetchTeamRequirementWiseAnalysisError,
  fetchTeamRequirementWiseAnalysisProgress: state.orgProduct.fetchTeamRequirementWiseAnalysisProgress,
});

const connectionActions = {
  showSnackBar: showSnackBar,
  resetAnalysisData: resetAnalysisData,
  fetchAnalysisCriterias: fetchAnalysisCriterias,
  fetchAnalysisRequirements: fetchAnalysisRequirements,
  fetchOverallAnalysisData: fetchOverallAnalysisData,
  fetchRequirementWiseAnalysisData: fetchRequirementWiseAnalysisData,
  fetchTeamWiseAnalysisData: fetchTeamWiseAnalysisData,
  fetchCriteriaRequirementWiseAnalysisData: fetchCriteriaRequirementWiseAnalysisData,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  itemSection: {
    border: '1px solid #d8d8d8',
    padding: 17,
    borderRadius: 5,
    background: '#FFF',
    marginBottom: 17,
    position: 'relative',
  },
  head: {
    color: '#6C6C6C',
    fontSize: 18,
    opacity: 1,
    fontWeight: 600,
    margin: 0,
    marginBottom: 11
  },
  headerWrap: {
    display: 'flex',
    alignItems: 'end',
    gap: 40,
    marginTop: 20
  },
  legendsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 60,
    marginTop:15
  },
  legendWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap:10,
  },
  bgRed: {
    width: '40px',
    height: '10px',
    backgroundColor: '#cb3837',
    border: '1px solid red',
  },
  bgYellow: {
    width: '40px',
    height: '10px',
    backgroundColor: '#fec93e',
    border: '1px solid yellow',
  },
  bgGreen: {
    width: '40px',
    height: '10px',
    backgroundColor: '#63ac67',
    border: '1px solid green',
  },
  legendLabel: {
    fontSize:"12px"
  }
});

class TrendAnalysisTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      overallAnalysisData: [],
      teamWiseAnalysisData: [],
      requirementWiseAnalysisData: [],
      teamRequirementWiseAnalysisData: [],
      selectCriteria: null, 
      selectRequirement: null, 
    };
  }

  componentDidMount() {
    this.props.resetAnalysisData();
    if(this.props.productDetails?.productID){
      this.props.fetchOverallAnalysisData(this.props.productDetails?.productID)
      this.props.fetchAnalysisCriterias(this.props.productDetails?.productID)
      this.props.fetchAnalysisRequirements(this.props.productDetails?.productID)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchOverallAnalysisProgress === false && prevProps.fetchOverallAnalysisProgress === true) {
      if (this.props.fetchOverallAnalysisError === null) {
        let overallAnalysisData = [];
        this.props.overallAnalysisData?.forEach((item=>{
          overallAnalysisData.push({
            ...item,
            isChecked: true
          })
        }))
        this.setState({overallAnalysisData: overallAnalysisData});
      }
    }
    if (this.props.fetchTeamWiseAnalysisProgress === false && prevProps.fetchTeamWiseAnalysisProgress === true) {
      if (this.props.fetchTeamWiseAnalysisError === null) {
        let teamWiseAnalysisData = [];
        this.props.teamWiseAnalysisData?.forEach((item=>{
          teamWiseAnalysisData.push({
            ...item,
            isChecked: true
          })
        }))
        this.setState({teamWiseAnalysisData: teamWiseAnalysisData});
      }
    }
    if (this.props.fetchRequirementWiseAnalysisProgress === false && prevProps.fetchRequirementWiseAnalysisProgress === true) {
      if (this.props.fetchRequirementWiseAnalysisError === null) {
        let requirementWiseAnalysisData = [];
        this.props.requirementWiseAnalysisData?.forEach((item=>{
          requirementWiseAnalysisData.push({
            ...item,
            isChecked: true
          })
        }))
        this.setState({requirementWiseAnalysisData: requirementWiseAnalysisData});
      }
    }
    if (this.props.fetchTeamRequirementWiseAnalysisProgress === false && prevProps.fetchTeamRequirementWiseAnalysisProgress === true) {
      if (this.props.fetchTeamRequirementWiseAnalysisError === null) {
        let teamRequirementWiseAnalysisData = [];
        this.props.teamRequirementWiseAnalysisData?.forEach((item=>{
          teamRequirementWiseAnalysisData.push({
            ...item,
            isChecked: true
          })
        }))
        this.setState({teamRequirementWiseAnalysisData: teamRequirementWiseAnalysisData});
      }
    }
  }

  render() {
    const classes = this.props.classes;

    let criteriaList = [];
    this.props.analysisCriteriasData?.forEach((item, index) => {
        criteriaList.push({
            label: item.CriteriaName,
            value: item.CriteriaName
        })
    });

    let requirementList = [];
    this.props.analysisRequirementsData?.forEach((item, index) => {
        requirementList.push({
            label: item.RequirementName,
            value: item.RequirementName
        })
    })

    return (
        <div className={classes.contentSection} onScroll={(e) => this.scrollContent(e)}>
          {this.props.sections && this.props.sections.length > 0 && this.props.sections.map(section => {
            if (section.SectionUID === "overall_acore_analysis") {
              return (
                <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                  <p className={classes.head}>{section.SectionName}</p>
                  <div className={classes.headerWrap}>
                    {this.state.overallAnalysisData.length > 0 && <AnalysisFilter options={this.state.overallAnalysisData} handleSelect={(items)=>{
                      this.setState({overallAnalysisData: items});
                    }}/>}
                  </div>
                  <div style={{padding: 30, paddingBottom: 10}}>
                    <ScoreAnalysisChart dataArr={this.state.overallAnalysisData} />
                    <Lagends classes={this.props.classes}/>
                  </div>
                </div>)
            }
           else if (section.SectionUID === "team_wise_score_analysis") {
              return (
                <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                  <p className={classes.head}>{section.SectionName}</p>
                  <div className={classes.headerWrap}>
                    <div style={{maxWidth: 300, width: '100%'}}>
                      <Typography style={{fontSize: 14}}>Select Team</Typography> 
                      <ReactSelect
                        isClearable
                        isSearchable={true}
                        options={criteriaList}
                        classNamePrefix="select"
                        placeholder="Select Team"
                        onChange={(e) => {
                          this.props.fetchTeamWiseAnalysisData(this.props.productDetails?.productID,{names: [e.value]})
                        }}
                      />
                    </div>
                    {this.state.teamWiseAnalysisData.length > 0 && <AnalysisFilter options={this.state.teamWiseAnalysisData} handleSelect={(items)=>{
                      this.setState({teamWiseAnalysisData: items});
                    }}/>}
                  </div>
                  
                  <div style={{padding: 30, paddingBottom: 10}}>
                    <ScoreAnalysisChart dataArr={this.state.teamWiseAnalysisData} />
                    <Lagends classes={this.props.classes}/>
                  </div>
                </div>)
            }
            else if (section.SectionUID === "requirement_wise_core_analysis") {
              return (
                <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                    <p className={classes.head}>{section.SectionName}</p>
                    <div className={classes.headerWrap}>
                      <div style={{maxWidth: 300, width: '100%'}}>
                        <Typography style={{fontSize: 14}}>Select Question</Typography>
                        <ReactSelect
                          isClearable
                          isSearchable={true}
                          options={requirementList}
                          classNamePrefix="select"
                          placeholder="Select Question"
                          onChange={(e) => {
                            this.props.fetchRequirementWiseAnalysisData(this.props.productDetails?.productID,{names: [e.value]})
                          }}
                        />
                      </div>
                      {this.state.requirementWiseAnalysisData.length > 0 && <AnalysisFilter options={this.state.requirementWiseAnalysisData} handleSelect={(items)=>{
                        this.setState({requirementWiseAnalysisData: items});
                      }}/>}
                    </div>
                  <div style={{padding: 30, paddingBottom: 10}}>
                    <ScoreAnalysisChart dataArr={this.state.requirementWiseAnalysisData} />
                    <Lagends classes={this.props.classes}/>
                  </div>
                </div>)
            }else if (section.SectionUID === "ream_requirement_trend") {
              return (
                <div className={classes.itemSection} id={`${section.SectionName.toLowerCase().split(" ").join("-")}`}>
                    <p className={classes.head}>{section.SectionName}</p>
                    <div className={classes.headerWrap}>
                    <div style={{maxWidth: 300, width: '100%'}}>
                      <Typography style={{fontSize: 14}}>Select Team</Typography> 
                      <ReactSelect
                        isClearable
                        isSearchable={true}
                        options={criteriaList}
                        classNamePrefix="select"
                        placeholder="Select Team"
                        value={this.state.selectCriteria}
                        onChange={(e) => {
                          this.setState({selectCriteria: e},()=>{
                            if(this.state.selectCriteria && this.state.selectRequirement){
                              this.props.fetchCriteriaRequirementWiseAnalysisData(this.props.productDetails?.productID,{requirement_name: this.state.selectRequirement.value, criteria_name: this.state.selectCriteria.value})
                            }
                          })
                        }}
                      />
                    </div>
                      <div style={{maxWidth: 300, width: '100%'}}>
                        <Typography style={{fontSize: 14}}>Select Question</Typography>
                        <ReactSelect
                          isClearable
                          isSearchable={true}
                          options={requirementList}
                          classNamePrefix="select"
                          placeholder="Select Question"
                          onChange={(e) => {
                            this.setState({selectRequirement: e},()=>{
                              if(this.state.selectCriteria && this.state.selectRequirement){
                                this.props.fetchCriteriaRequirementWiseAnalysisData(this.props.productDetails?.productID,{requirement_name: this.state.selectRequirement.value, criteria_name: this.state.selectCriteria.value})
                              }
                            })
                          }}
                          value={this.state.selectRequirement}
                        />
                      </div>
                      {this.state.teamRequirementWiseAnalysisData.length > 0 && <AnalysisFilter options={this.state.teamRequirementWiseAnalysisData} handleSelect={(items)=>{
                        this.setState({teamRequirementWiseAnalysisData: items});
                      }}/>}
                    </div>
                  <div style={{padding: 30, paddingBottom: 10}}>
                    <ScoreAnalysisChart dataArr={this.state.teamRequirementWiseAnalysisData} />
                    <Lagends classes={this.props.classes}/>
                  </div>
                </div>)
            }else {
              return null
            }
          })}
        </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(TrendAnalysisTab));


export const Lagends = ({classes}) => {
 return (
  <div className={classes.legendsContainer}>
    <div className={classes.legendWrap}>
      <div className={classes.bgRed}></div>
      <div className={classes.legendLabel}>0 - 1</div>
    </div>
    <div className={classes.legendWrap}>
      <div className={classes.bgYellow}></div>
      <div className={classes.legendLabel}>1 - 2</div>
    </div>
    <div className={classes.legendWrap}>
      <div className={classes.bgGreen}></div>
      <div className={classes.legendLabel}>2 - 3</div>
    </div>
  </div>
 )
}

