import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';

import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

// components
import NewEvaluationDialog from "Components/Application/Components/Evaluate/Components/Common/NewEvaluationDialog";

// assets
import CommonCss from "commonCss";
import Image from 'Components/Common/image'
import ReviewsEmptyState from "assets/images/reviews_empty_state.svg";
import LayeredCard from "Components/Application/Components/Evaluate/Components/Common/LayeredCard";
import DefaultImage from "assets/images/evaluation/default.svg";

// redux
import { fetchProductReactionByType } from "redux/product/action";

const connectedProps = (state) => ({
    products: state.products,
    fetchProductReactionByTypeProgress: state.products.fetchProductReactionByTypeProgress,
});

const connectionActions = {
    fetchProductReactionByType: fetchProductReactionByType
};

var connector = connect(connectedProps, connectionActions);


const styles = (theme) => createStyles({
    root: {
        borderRadius: theme.spacing(2),
    },
    tblContainer: {
        borderRadius: theme.spacing(2),
    },
    viewAllbutton: {
        ...CommonCss.viewAllbutton,
        //    marginRight: 10
    },
    DashboardNoDataLayout: {
        width: 'calc(100% - 10px)',
        margin: '22px 0px 0px 0px',
        minHeight: 450,
        textAlign: 'center',
        background: '#ffffff',
        padding: '23px',
        borderRadius: '6px',
        '& button': {
            margin: '20px 0'
        }
    },
    topHead: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 40,
        marginBottom: 8,
        "& > .appBar": {
          flex: 1,
        },
        '& h6':{
          fontSize: '1.4em',
          fontWeight: 600,
          margin: '0px'
        }
      },
      viewAll: CommonCss.viewAll,
});

class FeaturedProducts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedReview: 10,
            page: 0,
            pageSize: 6,
            query: '',
            reactionType: 'featured',
        }
    }

    componentDidMount() {
        this.props.fetchProductReactionByType(this.state.page, this.state.pageSize, this.state.query, this.state.reactionType, this.state.reactionType);
    }

    reviewProduct = (id) => {
        // e.stopPropagation();
        let redirectPath = `/app/products/product-review/${id}/`;
        this.props.history.push(redirectPath);
      };

    routeChange = (id) => {
        this.props.history.push(`/app/products/product-details/` + id)
    }

    render() {
        const classes = this.props.classes;
        var reactionProducts = this.props.products[`${this.state.reactionType}ProductsReactions`] !== undefined && this.props.products[`${this.state.reactionType}ProductsReactions`] !== null && this.props.products[`${this.state.reactionType}ProductsReactions`].Data !== undefined && this.props.products[`${this.state.reactionType}ProductsReactions`].Data !== null ? this.props.products[`${this.state.reactionType}ProductsReactions`].Data : [];

        return <div className={classes.root}>

            <div className={classes.topHead}>
                <h6>{this.props.title}</h6>
                {(
                      <Link
                      to="/app/products/all-products?popularFilter=featured"
                      className={classes.viewAll}
                  >
                      View all
                  </Link>
                )}
            </div>

            {!this.props.fetchProductReactionByTypeProgress && reactionProducts.length > 0 &&
                <>
                    <div className={classes.tblContainer}>
                        <Grid container spacing={3}>
                            {!this.props.fetchProductReactionByTypeProgress && reactionProducts.map((product, index) => {
                                 return <Grid item lg={2} md={3} key={index}>
                                 <div onClick={(e) => this.producDetail(e, product)}>
                                   <LayeredCard
                                     title={product.Name}
                                     subTitle={product.CompanyName}
                                     description={product.Description}
                                     image={
                                       product.ProductLogo === null ||
                                       product.ProductLogo.length === 0
                                         ? DefaultImage
                                         : product.ProductLogo
                                     }
                                     toogleCheck={(e) => {
                                       this.reviewProduct(product.ID);
                                     }}
                                     onClick={() => {}}
                                     numLayer={0}
                                     //  checked={addedToCart.indexOf(parseInt(product.ID)) !== -1}
                                     showCheckbox={true}
                                     id={product.ID}
                                     uniqueId={product.ID + "_d_" + index}
                                     //  type={'product'}
                                     IsExisting={true}
                                   />
                                 </div>
                               </Grid>
                            })}
                        </Grid>
                    </div>
                </>
            }

            {this.props.fetchProductReactionByTypeProgress &&
                <div className={classes.tblRow}>
                    <span className={classes.tblCol} style={{ margin: '0px auto', width: '100%' }}>
                        <span style={{ display: 'block', padding: '95px', textAlign: 'center' }}><CircularProgress /></span>
                    </span>
                </div>
            }
            {!this.props.fetchProductReactionByTypeProgress && reactionProducts.length === 0 &&
                <div className={classes.DashboardNoDataLayout}>
                    <Image src={ReviewsEmptyState} />
                    <h2>No Featured Products found</h2>
                </div>
            }
            <NewEvaluationDialog />
        </div>
    }
}


export default connector(compose(withRouter, withStyles(styles))(FeaturedProducts));