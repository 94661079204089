import { Avatar, Button, Dialog, DialogContent, InputBase, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Slide, Typography, IconButton } from "@material-ui/core";
import Menu from '@material-ui/core/Menu';
import { createStyles, withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import classnames from "classnames";
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
// redux
import { hideSearchTeamsPoperDialog } from "redux/dialogs/action";
import { showSnackBar } from "redux/snackbar/action";
import { fetchOrgGroup } from "redux/org/group/action";

import _ from "underscore";
import { } from "redux/product/orgProduct/action";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    groups: state.orgGroup.groups,
    fetchGroupProg: state.orgGroup.fetchGroupProg,
    isOpen: state.dialogs.isShowSearchTeamPoperDialog
});

const connectionActions = {
    fetchOrgGroup: fetchOrgGroup,
    showSnackBar: showSnackBar,
    hideSearchTeamsPoperDialog: hideSearchTeamsPoperDialog
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    collaboratorContainer: {
        minWidth: '310px',
        '& ul': {
            padding: 0
        }
    },
    collaboratorHeader: {
        marginTop: 0,
        paddingBottom: 0
    },
    searchCol: {
        paddingTop: '16px',
        paddingBottom: '16px',
        '& span': {
            textAlign: 'left',
            fontSize: 12,
            color: '#202124',
            float: 'right'
        }
    },
    title: {
        textAlign: "left",
        fontWeight: 400,
        color: '#20253A',
        display: 'flex',
        placeItems: 'center'
    },
    closeIcon: {
        position: "absolute",
        zIndex: 11,
        right: 10,
        top: 10,
        padding: 5
    },
    input: {
        padding: theme.spacing(0.5, 2),
        fontSize: '.875rem',
        marginRight: 15,
        background: '#f8f9fa',
        width: '100%',
        border: '0px',
        borderBottom: '1px solid #4b86f7',
        outline: '1px solid transparent',
        borderRadius: '6px 6px 0 0',
        minHeight: 27
    },
    listItem: {
        borderBottom: '1px solid #EEEEEE',
        padding: '10px 0px',
        '&:last-child': {
            borderBottom: 'unset'
        }
    },
    avatar: {
        width: '2.25rem',
        height: '2.25rem',
        fontSize: '1.125rem'
    },
    userEmail: {
        fontSize: '13px',
        color: '#4B4B4B'
    },
    userEmailText: {
        cursor: 'pointer',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        placeItems: 'start',
        '& svg': {
            position: 'relative',
            left: '5px',
            marginRight: 10
        }
    },
    isMember: {
    },
    userState: {
        position: 'relative',
        display: 'inline-block',
        width: '40px',
        height: '12px',
        background: '#DDDDDD',
        opacity: 1,
        borderRadius: '20px',
        cursor: 'pointer',
        '&::after': {
            content: "''",
            height: '20px',
            width: '20px',
            display: 'inline-block',
            position: 'absolute',
            background: ' #DDDDDD',
            border: ' 5px solid #A0A5BA',
            borderRadius: '50%',
            top: '-4px',
            left: '0px'
        },
        '&.active': {
            '&::after': {
                background: '#81A5F2',
                borderColor: '#4175DF',
                left: '20px'
            }
        },
        '&.infetchGroupProg': {
            cursor: 'not-allowed'
        }
    },
    newUserBtn: {
        background: "#3C3C3C",
        borderRadius: 5,
        color: '#ffffff !important',
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        minWidth: 140,
        marginLeft: '10px',
        "&:hover": {
            background: "#3C3C3C",
            color: '#ffffff !important',
        },
    },
    shareIcon: {
        background: '#4175DF',
        color: '#ffffff',
        width: '40px',
        height: '40px',
        display: 'inline-block',
        borderRadius: '50%',
        textAlign: 'center',
        lineHeight: '50px',
        marginRight: '10px',
        '& svg': {
            marginTop: '6px'
        }
    },
    footerAction: {
        padding: '0 20px 20px 20px',
        textAlign: 'right',
        '& button': {
            backgroundColor: '#4175DF',
            color: 'white'
        },
        '& button:hover': {
            backgroundColor: '#4175DF',
            color: 'white'
        }
    }
});

class SearchTeamsPoper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            teams: null,
            editValue: {},
            showActionMenu: null,
            showActionId: null,
            existingTeams: [],
            pageSize: 10,
            page: 0
        };
        this.avatarName = this.avatarName.bind(this);
    }

    componentDidMount() {
        this.setState({
            existingTeams: this.props.selectedTeams
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedTeams.length !== prevProps.selectedTeams.length) {
            this.setState({
                existingTeams: this.props.selectedTeams
            })
        }
    }

    avatarName(name) {
        return name.substring(0, 1);
    }

    handleActionClick = (event, id) => {
        this.setState({
            showActionMenu: event.currentTarget,
            showActionId: id
        })
    };

    addTeam(team) {

    }

    removeTeam(team) {

    }

    handleActionClose = (team, target) => {
        if (team && target) {
            if (target === 10) {
                this.props.onAdd(team);
            }
            if (target === 20) {
                this.props.onRemove(team);
            }
        }
        this.setState({
            showActionMenu: null,
            showActionId: null,
        })
    };



    render() {
        const classes = this.props.classes;
        let groups = this.props.groups || [];
        let teams = this.state.existingTeams;
        let existTeamsIds = _.map(teams, 'ID');
        let teams_list = this.state.query === '' ? teams : groups;
        teams_list = _.uniq(teams_list, 'ID');

        const checkIsExistingTeam = (id) => {
            if (existTeamsIds.filter(user => user === id).length > 0) {
                return true;
            } else {
                return false;
            }
        }

        let me = this;
        return <>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                open={this.props.isOpen}
                onClose={(event, reason) => {
                    this.props.onClose()
                }}
                aria-labelledby="add-collaborator-dialog"
                TransitionComponent={Transition}
                className={classnames(classes.dialog)}
            >
                <DialogContent >
                    <div className={classes.collaboratorContainer}>
                        <div className={classes.collaboratorHeader}>
                            <IconButton className={classes.closeIcon} onClick={this.props.onClose}><CloseIcon /></IconButton>
                            <Typography className={classes.title}>
                                <span className={classes.shareIcon}><GroupAddOutlinedIcon /></span> Add Teams
                            </Typography>

                            <div className={classes.searchCol}>
                                <InputBase
                                    className={classes.input}
                                    placeholder={"Search Team"}
                                    onChange={(event) => {
                                        me.setState({
                                            query: event.target.value
                                        }, () => {
                                            me.props.fetchOrgGroup(this.state.page, this.state.pageSize, me.state.query);
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        {teams_list !== null && teams_list.length > 0 &&
                            <List dense={true}>
                                {
                                    teams_list.map((team, k) => {
                                        return <ListItem key={k} className={classes.listItem}>
                                            <ListItemAvatar className={classes.listAvatar}>
                                                <Avatar className={classes.avatar}>
                                                    {this.avatarName(team.Name)}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={
                                                <div style={{ display: 'flex', placeContent: 'center space-between' }}>
                                                    <span className={classes.userEmailText}>
                                                        <span style={{ fontWeight: 500, color: '#202124' }}>{team.Name}</span>
                                                        <span style={{ color: '#5f6368' }}>{team.Email}</span>
                                                        {/* { this.checkUserState(user).IsApprover == false && <DoneIcon color="primary"/> } */}
                                                    </span>
                                                    <Button
                                                        onClick={(e) => this.handleActionClick(e, team.ID)}
                                                        aria-controls="simple-menu" aria-haspopup="true">
                                                        {checkIsExistingTeam(team.ID) === true &&
                                                            <>Selected</>
                                                        }
                                                        {checkIsExistingTeam(team.ID) === false &&
                                                            <> Action</>
                                                        }
                                                        <ArrowDropDownIcon />
                                                    </Button>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={this.state.showActionMenu}
                                                        keepMounted
                                                        open={Boolean(this.state.showActionId === team.ID)}
                                                        onClose={() => { this.handleActionClose() }}
                                                    >
                                                        {checkIsExistingTeam(team.ID) === false && <MenuItem value={10} onClick={() => { this.handleActionClose(team, 10) }}>Add</MenuItem>}
                                                        {checkIsExistingTeam(team.ID) === true && (
                                                            <MenuItem
                                                                value={20}
                                                                onClick={() => {
                                                                    this.handleActionClose(team, 20)
                                                                }}
                                                            >
                                                                Remove
                                                            </MenuItem>
                                                        )}
                                                    </Menu>
                                                </div>
                                            }
                                                className={classnames(classes.userEmail, classes.isMember)} />
                                        </ListItem>
                                    })
                                }
                            </List>
                        }

                        {this.state.query !== "" && !this.props.fetchGroupProg && teams_list?.length === 0 && (
                            <span style={{ display: "block", textAlign: "center", padding: "50px 20px 10px", marginBottom: "12px" }}>
                                Team not found
                            </span>
                        )}
                    </div>

                </DialogContent>
                <div className={classes.footerAction}>
                    <Button className={classes.btn} onClick={() => { this.props.onClose() }}>
                        Done
                    </Button>
                </div>
            </Dialog>
        </>
    }
}

SearchTeamsPoper.defaultProps = {
    searchTitle: '',
    selectedUser: [],
    showToggle: false,
    isfromSettingsTemplate: false,
    criterias: [],
    isNew: false,
    updateNewCollaborator: () => { },
    removeCollaborator: () => { },
    hideCollaborator: false,
}

export default connector(compose(
    withStyles(styles),
    withRouter
)(SearchTeamsPoper));