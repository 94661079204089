import {
    START_SAML_CONFIG_FETCH,
    END_SAML_CONFIG_FETCH,
} from "./action";

const initState   =     {
    configFetchProgress:false,
    configFetchError:null,
    config:null,
}

export  function samlReducer(state=initState,action){
    switch(action.type){
        case START_SAML_CONFIG_FETCH:
            return {
                ...state,
                configFetchProgress:true,
                configFetchError:null,
                config:null,
            };
        case END_SAML_CONFIG_FETCH:
            return {
                ...state,
                configFetchProgress:false,
                configFetchError:action.payload.error,
                config:action.payload.success
            }
        default:
            return state;
    }
}