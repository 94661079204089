import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, Typography, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from 'react-router-dom';
import classnames from "classnames";

// components
import OutlinedInput from "../../../../../../Common/Input/OutlinedInput";
import SqureButton from '../../../../../../Common/SqureButton';
import { showSnackBar } from "redux/snackbar/action";

// redux

import { hideAddFormDialog, fetchOrgFormUsingId, addOrgFormData, fetchOrgForms } from "redux/forms/org/action";
import { fetchMasterFormUsingId } from "redux/forms/master/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.orgForms.isShowAddFormDialog,
    orgFormData: state.orgForms.orgFormData,
    masterFormData: state.masterForms.masterFormData,
    addOrgFormError: state.orgForms.addOrgFormError,
    addOrgFormProgress: state.orgForms.addOrgFormProgress,
    fetchOrgFormsByIdProgress: state.orgForms.fetchOrgFormsByIdProgress,
    fetchMasterFormsByIdProgress: state.masterForms.fetchMasterFormsByIdProgress,
});

const connectionActions = {
    hideDialog: hideAddFormDialog,
    fetchOrgFormUsingId: fetchOrgFormUsingId,
    addOrgFormData:addOrgFormData,
    fetchOrgForms:fetchOrgForms,
    showSnackBar:showSnackBar,
    fetchMasterFormUsingId:fetchMasterFormUsingId
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important",
    },
    modalTitle: {
        fontSize: theme.spacing(2.4),
        marginBottom: theme.spacing(3),
        textAlign: "center",
    },
    helptext: {
        fontSize: theme.spacing(1.8)
    },
    alerts: {
        marginTop: theme.spacing(4),
        padding: '0px 40px'
    },
    form: {
        marginTop: theme.spacing(2),
        width: '60%',
    },
    input: {
        marginBottom: theme.spacing(3)
    },
    scheduleTitle: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 600,
        fontSize: theme.spacing(2)
    },
    scheduleTitle1: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 600,
        fontSize: theme.spacing(1.8)
    },
    actions: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: "center",
    },
    loader: {
        textAlign: "center",
        padding: theme.spacing(10, 0)
    },
    dialogAction: {
        textAlign: "center"
    },
    addNewButton: {
        fontWeight: 600,
        fontSize: theme.spacing(2)
    },
    actionBtn: {
        minWidth: 110
    },
    nameYour: {
        "& [class*=MuiInputBase-root]": {
            maxHeight: 40,
            minHeight: 40,
            "& input": {
                fontSize: theme.spacing(1.8)
            }
        },
    },
    nameOfCriteria: {
        "& > div": {
            marginBottom: 0
        },
    },
    priority: {
        "& > div": {
            marginBottom: 0,
            justifyContent: 'flex-start',
        },
        "& [class*=MuiInputBase-root]": {
            width: 100,
            color: '#707070',
            fontSize: 13,
            textAlign: 'center'
        },
        "& [class*=MuiSelect-outlined]": {
            color: "#707070",
            fontSize: theme.spacing(1.8),
            "&:after": {
                content: "''",
                width: 7,
                height: 7,
                position: 'absolute',
                left: 9,
                borderRadius: '50%',
                top: '50%',
                transform: 'translate(0px, -50%)'
            },
        },
        "&.low [class*=MuiSelect-outlined]:after": {
            background: 'green'
        },
        "&.medium [class*=MuiSelect-outlined]:after": {
            background: 'orange'
        },
        "&.high [class*=MuiSelect-outlined]:after": {
            background: 'red'
        }
    },
    scoring: {
        "& > div": {
            alignItems: "baseline",
            marginBottom: 0
        },
        "& [class*=MuiSelect-outlined]": {
            paddingLeft: 10
        }
    },
    yourQuestion: {
        "& [class*=MuiInputBase-root]": {
            padding: 0,
            fontSize: theme.spacing(1.8),
            minHeight: 120,
            color: "#707070",
            lineHeight: 1.2,
            "& textarea": {
                minHeight: 100,
            }
        }
    },
    addCriteriaDatePicker: {
        "& .react-datepicker__input-container > div": {
            display: 'flex',
            minWidth: 300,
            alignItems: 'baseline',
            "& [class*=MuiInputBase-root]": {
                maxHeight: 33,
                minHeight: 33,
                width: 166,
                "& input": {
                    fontSize: theme.spacing(1.8),
                    // width: 107,
                    textAlign: "center"
                }
            },
            "& [class*=MuiFormLabel-root]": {
                marginBottom: 0,
                marginRight: 29
            }
        },
        "[class*=MuiMenuItem-root]": {
            color: "#707070",
            fontSize: theme.spacing(1.8)
        }
    },
});

class AddFormDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            activeMultiSelectId: false,
            name: "",
            formName: '',
            template_name_error: '',
            name_error: "",
            formData:null
        }

        this.addNewForm = this.addNewForm.bind(this);
        this.cleanError = this.cleanError.bind(this);
        this.cleanState = this.cleanState.bind(this);
    }

    cleanState() {
        this.setState({
            error: "",
            success: "",
            name: "",
            name_error: "",
            formName: '',
            activeMultiSelectId: false,
            template_name_error: '',
            formData:null
        });
    }

    componentDidMount() {
        console.log(this.props.selectedForm, 'selectedForm');
        console.log(this.props.activeTab, 'activeTab');
        
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.addOrgFormProgress === false && prevProps.addOrgFormProgress === true) {
            if (this.props.addOrgFormError === null) {
                this.props.showSnackBar("New form added successfully", "success", 3000);
                this.cleanState();
                this.props.fetchOrgForms(0, 1000);
                this.props.hideDialog()
            // console.log(this.props.orgFormData, 'orgFormData')

              } else {
                if (this.props.addOrgFormError.message !== undefined) {
                  this.setState({
                    "error": "Failed to save template."
                  })
                } else {
                  this.setState({
                    "error": "Something went wrong."
                  })
                }
              }
        }

        if (this.props.fetchOrgFormsByIdProgress === false && prevProps.fetchOrgFormsByIdProgress === true) {
            console.log(this.props.orgFormData, 'orgFormData')
            this.setState({
              formData:this.props.orgFormData,
            })
          }
      
          if (this.props.fetchMasterFormsByIdProgress === false && prevProps.fetchMasterFormsByIdProgress === true) {
            console.log(this.props.masterFormData, 'masterFormData')
            this.setState({
              formData:this.props.masterFormData,
            })
          }
    }

    fetchData = () => {
        let id = this.props.selectedForm.formId;
        if(this.props.selectedForm.type === 'ORG'){
            this.props.fetchOrgFormUsingId(id)
        }else{
            this.props.fetchMasterFormUsingId(id)
        }
    }

    cleanError() {
        var requirements = this.state.requirements;
        requirements.forEach((r) => {
            r.name_error = "";
            r.description_error = "";
        })
        this.setState({
            name_error: "",
            criteria_schedule_error: "",
            requirements: requirements
        })
    }

    addNewForm() {
        // const { orgFormData } = this.props;
        const { formData } = this.state;
        console.log(formData, 'orgFormData')

        this.setState({
            template_name_error: ''
        })

        if (this.state.formName.trim().length === 0) {
            this.setState({
                template_name_error: "Please enter Template Name."
            });
            return;
        }
        const timestamp = (new Date()).getTime();

        var data = {
            form_title: this.state.formName,
            form_description: formData?.FormDescription,
            form_type: "ORG",
            form_slug:formData?.FormSlug+timestamp,
            sections: []
        }

        var sectionName = this.props.activeTab === 0 ? 'MasterWorkflowTemplateFormsSection' : 'OrgWorkflowTemplateFormsSection';
        var sectionItemName = this.props.activeTab === 0 ? 'MasterWorkflowTemplateFormsSectionItems' : 'OrgWorkflowTemplateFormSectionItems';
        formData[sectionName].forEach(function(section){
            var sectionObj = {
                name: section.SectionName,
                description: section.Description,
                visibility: 1,
                seq: section.Seq,
                items : []
            }
            section[sectionItemName].forEach(function(item){
                const timestamp1 = (new Date()).getTime();
                var itemObj = {
                    "name": item.ItemName,
                    "type": item.Type,
                    "description": item.Description,
                    "additional_data": item.AdditionalData,
                    "visibility": item.Visibility,
                    "seq": item.Seq,
                    "slug": item.Slug+timestamp1,
                    "is_required": item.IsRequired,
                    "is_multiselect": item.IsMultiselect
                }
                sectionObj.items.push(itemObj);
            })
            data.sections.push(sectionObj);
        });

        this.props.addOrgFormData(data)
    }

    render() {
        const classes = this.props.classes;
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-addtemplate"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll="body"
            id="addtemplateDialog"
        >
            {/*  Alert messages */}
            <div className={classes.alerts}>
                {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
            </div>

            <DialogContent classes={{ root: classes.dialogContent }} >
                <div>
                    <Typography variant={"h2"} className={classes.modalTitle}>Add New Form</Typography>
                    <Grid container>
                        <Grid item xs={12} lg={12}>
                            <div className={classnames(classes.nameOfCriteria, classes.nameYour)}>
                                <OutlinedInput
                                    label="Name your Form"
                                    error={this.state.template_name_error.length > 0}
                                    helperText={this.state.template_name_error}
                                    value={this.state.formName}
                                    onChange={(event) => {
                                        this.setState({ formName: event.target.value });
                                    }}
                                />
                            </div>
                        </Grid>

                    </Grid>
                </div>
                {<div className={classes.actions}>
                    <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={() => {
                        this.cleanState();
                        this.props.hideDialog()
                    }}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                    <SqureButton cls={classes.actionBtn} variant={"contained"}
                        onClick={(e) => {
                            this.addNewForm()
                        }}>Save</SqureButton>
                </div>}
            </DialogContent>
        </Dialog >
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(AddFormDialog));