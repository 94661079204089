import { createStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import { CommonFn } from "services/commonFn";
// components
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
//redux
import { requestDetails, hideRequestDetailsCart } from "redux/evaluation/workflow/action";
import { showSnackBar } from "redux/snackbar/action";

const connectedProps = (state) => ({
  user: state.authUser.user,
  isRequestDetailsCartOpen: state.evaluationWorkflow.isRequestDetailsCartOpen,
  requestDetailsProgress: state.evaluationWorkflow.requestDetailsProgress,
  requestDetails: state.evaluationWorkflow.requestDetails,
  requestDetailsError: state.evaluationWorkflow.requestDetailsError,
  evaluation: state.evaluationMetaData.evaluation,
  evaluateView: state.applications.evaluateView,
});

const connectionActions = {
  fetchRequestDetails: requestDetails,
  hideRequestDetailsCart: hideRequestDetailsCart,
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const drawerWidthExpanded = 48;

const styles = (theme) =>
  createStyles({
    drawer: {
      width: theme.spacing(drawerWidthExpanded),
      flexShrink: 0,
      transition: "width 300ms",
      background: "#F7F7F7",
    },
    drawerPaper: {
      width: theme.spacing(drawerWidthExpanded),
      transition: "width 300ms",
      paddingTop: theme.spacing(3),
      background: "#ffffff",
    },
   
    cartContainer: {
      padding: '4px 20px',
      '&.close': {
        opacity: 0
      }
    },
    
    progressIndicator: {
      margin: theme.spacing(2),
      textAlign: "center",
    },
    headSection: {
      justifyContent: "space-between",
      minHeight: 40,
    },
    cartItem: {
      maxWidth: "400px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #EEEEEE",
      borderRadius: 10,
      margin: `${theme.spacing(2)} 0`,
      justifyContent: "space-between",
      paddingRight: '10px'
    },
    productNameContainer: {
      flex: 0.95,
    },
    productName: {
      flex: 0.95,
    },
    productImage: {
      width: "100%",
      height: "auto",
      marginRight: theme.spacing(2),
    },
    productNameTitle: {
      fontSize: theme.spacing(2),
    },
    productImageContainer: {
      width: 60,
      height: 60,
      padding: 13,
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      // boxShadow: "0px 1px 6px #0000001A",
      borderRadius: 10,
    },
  });

  const requests = {
    SyncUser: "Sync Users",
    SyncGroup: "Sync Groups",
    SyncGroupUser: "Sync Group Users",
    SyncApplication: "Sync Applications",
    SyncApplicationUser: "Sync Application Users",
    SyncApplicationGroup: "Sync Application Groups",
    SyncApplicationLog: "Sync Application Log",
  }
  const status = {
    NotStarted: "Not Started",
    InProgress: "InProgress",
    FailedWithError: "Failed with error",
    Completed: "Completed",
  }

class SyncDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { details, classes } = this.props;

    return (
      <div style={{padding: '1rem'}}>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableBody>
                <TableRow>
                  <TableCell variant="head">Status</TableCell>
                  <TableCell>{status[details?.Status]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">API</TableCell>
                  <TableCell className={classes.statusCol}>{details?.RequestTypes?.split(",")?.map((request,id)=>{
                    let applications  = [];
                    let groups  = [];
                    if(details.Applications?.length){
                        applications = details.Applications.map(o=> o.Name);
                    }
                    if(details.Groups?.length){
                        groups = details.Groups.map(o=> o.Name);
                    }
                    if(request === "SyncApplicationUser" || request === "SyncApplicationGroup"){
                        return <div key={id} style={{marginBottom:5}}><div style={{fontWeight:500}}>{requests[request]} </div>
                            {applications.join(', ')}                  
                        </div>
                    } else if(request === "SyncGroupUser"){
                        return <div key={id} style={{marginBottom:5}}><div style={{fontWeight:500}}>{requests[request]} </div>
                            {groups.join(', ')}                  
                        </div>
                    }else {
                        return <div key={id} style={{marginBottom:5}}><div style={{fontWeight:500}}>{requests[request]}</div></div>
                    }
                    
                })}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Requestor</TableCell>
                  <TableCell>{details?.Requestor?.Name || "System"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Created At</TableCell>
                  <TableCell>{details?.CreatedAt ? CommonFn.formateDateTime(details?.CreatedAt, true) : "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Started At</TableCell>
                  <TableCell>{details?.StartedAt ? CommonFn.formateDateTime(details?.StartedAt, true) : "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(SyncDetails));
