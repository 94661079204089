import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import * as Validator from "util/Validation";
import { Dialog, DialogContent, Slide, DialogTitle, InputAdornment, Typography, IconButton, DialogActions, Button, CircularProgress } from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import ReactSelect from 'react-select'
import { withRouter } from 'react-router-dom';
import { hideRequirementConditionDialog } from "redux/common/action";
import OutlinedSelectInput from "Components/Common/Input/OutlinedSelectInput";
import Alert from '@material-ui/lab/Alert';
import deleteIcon from "assets/images/delete.svg";
import Image from 'Components/Common/image.jsx';
import { saveEvaluationRequirementRule } from "redux/evaluation/rule/action";
import { saveTemplateRequirementRule } from "redux/templates/evaluation/org/action";
import { showSnackBar } from "redux/snackbar/action";
import DatePicker from "react-datepicker";
import moment from "moment";
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import CalendarImage from "assets/images/calendar.svg";

import { requirementRuleOperands } from "util/RequirementTypeOptions"
import { CommonFn } from "services/commonFn";

// dialogs
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  isOpen: state.common.isReqConditionDialogOpen,
  ruleDialog: state.common.requirementCondition,

  ruleSaveProgress: state.evaluationRule.evaluationReqRuleSaveProgress,
  errorRuleSave: state.evaluationRule.evaluationReqRuleSaveError,

  templateRuleSaveProgress: state.orgTemplateEvaluation.reqRuleSaveProgress,
  templateErrorRuleSave: state.orgTemplateEvaluation.errorReqRuleSave,
});

const connectionActions = {
  hideDialog: hideRequirementConditionDialog,
  saveEvaluationRequirementRule: saveEvaluationRequirementRule,
  saveTemplateRequirementRule: saveTemplateRequirementRule,
  showSnackBar: showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
    padding: theme.spacing(2, 2) + " !important",
    //minHeight:"300px"
  },
  dialogContent1: {
    padding: theme.spacing(2, 2) + " !important",
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  ruleTable: {
    width: "100%",
    border: '1px solid #ddd',
    padding: 10,
    borderRadius: 5,
    marginBottom: 10
  },
  deleteIcon: {
    color: "red"
  },
  op2: {
    width: "10%"
  },
  op1: {
    width: "10%"
  },
  leftOp: {
    width: "35%"
  },
  rightOp: {
    width: "35%",
    "& [class*=select__menu]": {
      maxHeight: 100,
    },
  },
  action: {
    width: "10%",
    paddingLeft: 10
  },
  alerts: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  conditions: {
    fontSize: 16,
    fontWeight: 500
  },
  ruleTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 0
  },
  addAnotherRule: {
    textAlign: 'center',
    color: '#4175DF',
    cursor: 'pointer',
    paddingBottom: 50
  },
  ruleSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  ruleDelete: {
    marginBottom: 16,
    cursor: 'pointer'
  },
  saveBtn: {
    margin: "10px 15px"
  },
  input: {
    minHeight: 38,
    padding: "2px 8px",
    border: "1px solid #cccccc",
    borderRadius: 3,
    fontSize: 15,
    color: "rgb(51, 51, 51)",
    width: "100%",
  },
  "@global": {
    ".react-datepicker__input-container > div": {
      margin: 0,
    },
    ".react-datepicker__input-container [class*=MuiInputBase-formControl]": {
      minHeight: 39,
      margin: 0,
    },
    ".react-datepicker__input-container [class*=MuiOutlinedInput-input]": {
      padding: 0,
      fontSize: 14,
      paddingLeft: 10,
      color: "rgb(51, 51, 51)",
    },
  },
  calendarPopout: {
    zIndex: '3000 !important'
  },
});

class RequirementConditionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      actionOption: [{ value: 1, label: 'Go to Criteria' }, { value: 2, label: 'Go to Requirement' }, { value: 3, label: 'Auto score based on vendor response' }],
      operand: [
        {
          value: 1,
          label: "=="
        },
        {
          value: 2,
          label: "in"
        }
      ],
      rules: [
        {
          ruleElement: [
            {
              "op2": 3,
              "leftOp": null,
              "op1": 1,
              "rightOP": []
            }
          ],
          actionElement: {
            type: '',
            data: ''
          }
        }
      ],
      goToReqOptions: [],
      goToCriteriaOptions: [],
      reqOperands: [],
      rulefetchProgress: true,
      maxScore: 5,
      responseOption: [
        { label: '5', value: 5 },
        { label: '4', value: 4 },
        { label: '3', value: 3 },
        { label: '2', value: 2 },
        { label: '1', value: 1 }
      ]
    }
  }

  componentDidMount() {
    this.initialSettings()
  }

  componentDidUpdate(prevProps) {
    if (this.props.ruleSaveProgress === false && prevProps.ruleSaveProgress) {
      if (this.props.errorRuleSave === null) {
        this.props.showSnackBar("Rule updated successfully.", "success", 3000);
        this.props.hideDialog()
      } else {
        this.setState({
          error: this.props.errorRuleSave,
          rulefetchProgress: false
        });
      }
    }

    if (this.props.templateRuleSaveProgress === false && prevProps.templateRuleSaveProgress) {
      if (this.props.templateErrorRuleSave === null) {
        this.props.showSnackBar("Rule updated successfully.", "success", 3000);
        this.props.hideDialog()
      } else {
        this.setState({
          error: this.props.errorRuleSave,
          rulefetchProgress: false
        });
      }
    }


  }

  initialSettings() {

    let goToReqOptions = [];
    let requirement = this.props.ruleDialog.requirement;
    this.props.ruleDialog?.requirements?.forEach((item) => {
      if (item.id === requirement.id) {
        return;
      }
      goToReqOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    let goToCriteriaOptions = []
    let criteria = this.props.ruleDialog.criteria;
    this.props.ruleDialog?.criterias?.forEach((item) => {
      if (item.id === criteria.id) {
        return;
      }
      goToCriteriaOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    let reqOperands = []
    requirementRuleOperands.forEach((o) => {
      if (o.forTypes.includes(requirement.answer_type)) {
        reqOperands.push(o)
      }
    })

    let resOptions = this.state.responseOption;
    if (this.props.ruleDialog.maxScore) {
      resOptions = []
      for (let i = this.props.ruleDialog.maxScore; i >= 1; i--) {
        resOptions.push({ label: i, value: i })
      }
    }

    this.setState({
      goToReqOptions: goToReqOptions,
      goToCriteriaOptions: goToCriteriaOptions,
      reqOperands: reqOperands,
      responseOption: resOptions
    }, () => {
      this.checkExistingRules()
    })


  }

  checkExistingRules() {
    if (this.props.isOpen === true) {
      var getRules = [];
      if (this.props.ruleDialog && this.props.ruleDialog?.rules?.length) {
        this.props.ruleDialog.rules.forEach((value) => {
          var elements = [];
          value.Elements.forEach((element) => {
            if (element.OpID === 3 || element.OpID === 4) {
              elements[elements.length - 1].op2 = element.OpID;
            } else {
              elements.push({
                leftOp: this.getLeftOp(element.LeftExpression),
                op1: element.OpID,
                op2: '',
                rightOP: this.getRightValue(this.getLeftOp(element.LeftExpression), element.RightExpression, element.OpID)
              })
            }
          });
          getRules.push({
            ruleElement: elements, actionElement: {
              type: this.getActionOptions(value.ActionType) || '',
              data: this.getActionType(value.ActionData, value.ActionType) || ''
            }
          })
        });
        this.setState({
          error: "",
          success: "",
          rules: getRules,
          rulefetchProgress: false
        });
      } else {
        this.reset();
      }
    }
  }

  reset = () => {
    if (this.props.ruleDialog?.requirement) {
      const reqOperands = this.state.reqOperands;
      const leftOp = this.getSelectedReq()
      const req = this.props.ruleDialog?.requirement;
      let initialRules = [
        {
          ruleElement: [
            {
              "op2": 3,
              "leftOp": leftOp,
              "op1": reqOperands && reqOperands.length > 0 ? reqOperands[0].value : 1,
              "rightOP": req.answer_type === 11 ? new Date() : []
            }
          ],
          actionElement: {
            type: '',
            data: ''
          }
        }
      ]
      this.setState({
        rules: initialRules,
        rulefetchProgress: false
      })
    } else {
      this.setState({
        error: "",
        success: "",
        answerType: null,
        rules: [
          {
            ruleElement: [
              {
                "op2": 3,
                "leftOp": null,
                "op1": 1,
                "rightOP": []
              }
            ],
            actionElement: {
              type: '',
              data: ''
            }
          }
        ],
        rulefetchProgress: false
      });
    }
  }

  getSelectedReq() {
    let requirement = this.props.ruleDialog.requirement;
    let leftOp = {
      answer_type: requirement.answer_type,
      value: "R=" + requirement.id,
      label: requirement.name,
      answerOptions: requirement.options
    }
    return leftOp;
  }

  getActionOptions = (option) => {
    if (Validator.isJson(option)) {
      return this.state.actionOption.find(element => option && element.value === JSON.parse(option));
    } else {
      return '';
    }
  }

  getLeftOp = (leftOp) => {
    if (this.props.ruleDialog?.requirement) {
      let leftOpValue = '';
      let requirement = this.props.ruleDialog.requirement;
      if (("R=" + requirement.id) === leftOp) {
        leftOpValue = {
          answer_type: requirement?.answer_type,
          value: "R=" + requirement.id,
          label: requirement.name,
          answerOptions: requirement.options
        }
      }
      return leftOpValue
    }
    return '';
  }

  getActionType = (actionData, actionType) => {
    const { responseOption } = this.state;
    if (actionType === 1) {
      return this.getSelectedValue(this.props.ruleDialog?.criterias, actionData);
    } else if (actionType === 2) {
      return this.getSelectedValue(this.props.ruleDialog?.requirements, actionData);
    } else if (actionType === 3) {
      const selectedOption = responseOption.filter(option => option.value === parseInt(actionData))
      if (selectedOption[0]) {
        return selectedOption[0]
      }
    }
    return ''
  }

  getSelectedValue = (arrayOption, actionData) => {
    let selectedObj = '';
    if (arrayOption?.length && Validator.isJson(actionData)) {
      arrayOption.map(element => {
        if (actionData && (element.id === JSON.parse(actionData))) {
          selectedObj = {
            value: element.id,
            label: element.name
          }
        }
      });
      return selectedObj;
    }
    return selectedObj;
  }

  getRightValue = (arrayOption, option, OpID) => {
    if ([1, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(arrayOption.answer_type)) {
      return option
    } else if ([4].includes(arrayOption.answer_type)) {
      let selectedObj;
      if (option !== '') {
        arrayOption.answerOptions.forEach(element => {
          if (option.toLowerCase() === element.Description.toLowerCase()) {
            selectedObj = {
              value: element.Description,
              label: element.Title
            };
          }
        })
        return selectedObj?.length === 0 ? '' : selectedObj;
      }
    } else {
      let selectedObj = [];
      if (arrayOption?.answerOptions?.length) {
        arrayOption.answerOptions.forEach(element => {
          if (OpID === 2 || OpID === 5) {
            if (option && Validator.isJson(option) && JSON.parse(option).indexOf(element.ID) > -1) {
              selectedObj.push({
                value: element.ID,
                label: element.Title
              })
            }
          } else if (OpID === 1) {
            var optionVal = option;
            try {
              if (option) {
                optionVal = JSON.parse(option);
              }
            } catch {

            }
            if (arrayOption.answer_type === 4) {
              if (optionVal === element.Description) {
                selectedObj = {
                  value: element.Description,
                  label: element.Title
                };
              }
            } else {
              if (optionVal === element.ID) {
                selectedObj = {
                  value: element.ID,
                  label: element.Title
                };
              }
            }
          }
        });
        return selectedObj?.length === 0 ? '' : selectedObj;
      }
    }

    return '';
  }

  addNewRuleElement = (ruleIndex) => {
    var rules = this.state.rules;
    var ruleElement = rules[ruleIndex].ruleElement;
    const reqOperands = this.state.reqOperands;
    const req = this.props.ruleDialog?.requirement;
    ruleElement.push({
      "op2": 3,
      "leftOp": this.getSelectedReq(),
      "op1": reqOperands && reqOperands.length > 0 ? reqOperands[0].value : 1,
      "rightOP": req.answer_type === 11 ? new Date() : []
    })
    rules[ruleIndex].ruleElement = ruleElement;
    this.setState({
      rules: rules
    })
  }

  deleteRuleElement = (ruleIndex, elementIndex) => {
    var rules = this.state.rules;
    rules[ruleIndex].ruleElement.splice(elementIndex, 1);
    this.setState({
      rules: rules
    })
  }

  saveRule = () => {
    var seq = 1;
    let rowNumber = 0;
    this.setState({
      "error": "",
      "success": ""
    })
    var errorMessage = "";

    var rules = [];
    this.state.rules.forEach((rule) => {
      rowNumber++;
      var ruleElement = [];

      if (!rule.actionElement.type) {
        errorMessage = `Please select action type`
        return;
      }

      if (!rule.actionElement.data) {
        errorMessage = `Please select criteria or requirement`
        return;
      }

      rule.ruleElement.forEach(function (ruleElem, k) {
        rowNumber++;
        if (errorMessage.length > 0) {
          return;
        }
        if (!ruleElem.leftOp) {
          errorMessage = `Error Row ${rowNumber} please select requirement.`
          return;
        }

        if (ruleElem.op1 !== 11 && (!ruleElem.rightOP || ruleElem.rightOP?.length === 0)) {
          errorMessage = `Error Row ${rowNumber} please select option.`
          return;
        }

        let rightExp = '';
        if (ruleElem.op1 === 11) {
          rightExp = String(ruleElem.rightOP) || 'RESPONDED'
        } else if ([1, 5, 6, 7, 8, 9, 10, 12, 13].includes(ruleElem.leftOp.answer_type)) {
          rightExp = String(ruleElem.rightOP)
        } else if (ruleElem.leftOp.answer_type === 11) {
          try {
            rightExp = CommonFn.formatResponseDate(ruleElem.rightOP, true)
          } catch (e) {
            rightExp = String(ruleElem.rightOP)
          }
        } else if ([4].includes(ruleElem.leftOp.answer_type)) {
          rightExp = String(ruleElem.rightOP.value)
        } else if ([2, 3, 14, 15].includes(ruleElem.leftOp.answer_type)) {
          rightExp = JSON.stringify(ruleElem.rightOP.map((i) => {
            return i.value
          }))
        }

        ruleElement.push({
          leftExp: ruleElem.leftOp.value,
          opId: ruleElem.op1,
          rightExp: rightExp,
          // rightExp: [1, 6, 7, 8, 9, 10, 11].includes(ruleElem.op1) ? String(ruleElem.rightOP.value) : JSON.stringify(ruleElem.rightOP.map((i) => {
          //   return i.value
          // })),
          seq: seq
        })
        seq++;

        if (k !== 0) {
          ruleElement.push({
            leftExp: "E=" + (seq - 2),
            opId: ruleElem.op2,
            rightExp: "E=" + (seq - 1),
            seq: seq
          })
          seq++;
        }
      })

      rules.push({ conditions: ruleElement, action: { type: rule.actionElement.type.value, data: (rule.actionElement.data.value).toString() } })
    })

    if (!errorMessage) {
      this.setState({
        rulefetchProgress: true
      }, () => {
        if (this.props.ruleDialog?.isEvaluation) {
          this.props.saveEvaluationRequirementRule(
            this.props.ruleDialog.id,
            this.props.ruleDialog.requirement.id,
            rules
          )
        } else {
          this.props.saveTemplateRequirementRule(
            this.props.ruleDialog.id,
            this.props.ruleDialog.requirement.id,
            rules
          )
        }
      })
    } else {
      this.setState({ error: errorMessage })
    }

  }

  addAnotherRole = () => {
    const rules = this.state.rules;
    const reqOperands = this.state.reqOperands;
    const req = this.props.ruleDialog?.requirement;
    var rule = {
      ruleElement: [
        {
          "op2": 0,
          "leftOp": this.getSelectedReq(),
          "op1": reqOperands && reqOperands.length > 0 ? reqOperands[0].value : 1,
          "rightOP": req.answer_type === 11 ? new Date() : []
        }
      ],
      actionElement: {
        type: '',
        data: ''
      }
    };
    rules.push(rule);
    this.setState({
      rules: rules
    })
  }

  deleteRule = (ruleIndex) => {
    const rules = this.state.rules;
    rules.splice(ruleIndex, 1);
    this.setState({
      rules: rules
    })
  }

  onRightChange = (ruleIndex, k, e) => {
    var rules = this.state.rules;
    var ruleElement = rules[ruleIndex].ruleElement[k];
    ruleElement.rightOP = e;
    rules[ruleIndex].ruleElement[k] = ruleElement;
    this.setState({ rules: rules });
  };

  updateRuleValue = (ruleIndex, k, field, value) => {
    var rules = this.state.rules;
    var ruleElement = rules[ruleIndex].ruleElement[k];
    ruleElement[field] = value;
    if (value === 11) {
      ruleElement.rightOP = 'RESPONDED';
    }
    rules[ruleIndex].ruleElement[k] = ruleElement;
    this.setState({ rules: rules });
  }

  render() {
    const classes = this.props.classes;
    const { goToReqOptions, goToCriteriaOptions, reqOperands, rulefetchProgress, responseOption } = this.state;
    var requirementOptions = [];

    if (this.props.ruleDialog?.requirements?.length > 0) {
      requirementOptions = this.props.ruleDialog?.requirements?.map((item) => {
        return {
          answer_type: item.answer_type,
          value: "R=" + item.id,
          label: item.name,
          answerOptions: item.options
        }
      })
    }

    return <Dialog
      onClose={this.props.hideDialog}
      aria-labelledby="app-evRuleDialog"
      open={this.props.isOpen}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={"md"}
      scroll="body"
      id="evRequirementConditionDialog"
    >
      <DialogTitle disableTypography>
        {this.props.dialogContent == null && <>
          <Typography variant={"h6"}> {this.props.ruleDialog?.criteria?.name} Rule</Typography>
        </>}
        <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.hideDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {rulefetchProgress && <DialogContent dividers={true} classes={{ root: classes.dialogContent1 }}>
        <CircularProgress />
      </DialogContent>}
      {!rulefetchProgress && <>
        <DialogContent dividers={true} classes={{ root: classes.dialogContent }}>
          {
            this.state.rules.map((element, ruleIndex) => {

              return (
                <table className={classes.ruleTable} key={ruleIndex}>
                  <tr>
                    <td colSpan="5">
                      <div className={classes.ruleSection}>
                        <p className={classes.ruleTitle}>Rule {ruleIndex + 1}</p>
                        {
                          <div className={classes.ruleDelete} onClick={() => this.deleteRule(ruleIndex)}><Image alt="Delete" src={deleteIcon} /></div>
                        }
                      </div>
                    </td>
                  </tr>

                  {element.ruleElement?.map((rule, k) => <tr key={k}>
                    <td className={classes.op2}>
                      {k !== 0 && <div>
                        <OutlinedSelectInput
                          value={rule.op2}
                          options={[
                            {
                              value: 3,
                              label: "And"
                            },
                            {
                              value: 4,
                              label: "Or"
                            }

                          ]}
                          noMargin={true}
                          compact={true}
                          onChange={(e) => {
                            var rules = this.state.rules;
                            var ruleElement = rules[ruleIndex].ruleElement[k];
                            ruleElement.op2 = e.target.value;
                            rules[ruleIndex].ruleElement[k] = ruleElement;
                            this.setState({ rules: rules });
                          }}
                        />
                      </div>}
                      {k === 0 && <div className={classes.conditions}>Condition</div>}
                    </td>
                    <td className={classes.leftOp}>
                      <ReactSelect
                        isDisabled={true}
                        menuPosition='fixed'
                        options={requirementOptions}
                        classNamePrefix="select"
                        placeholder="Search Requirement"
                        onChange={(e) => {
                          var rules = this.state.rules;
                          var ruleElement = rules[ruleIndex].ruleElement[k];
                          ruleElement.leftOp = e;
                          ruleElement.rightOP = null
                          if (e.answer_type === 3 || e.answer_type === 4) {
                            ruleElement.op1 = 1
                          } else {
                            ruleElement.op1 = 2
                          }
                          rules[ruleIndex].ruleElement[k] = ruleElement;
                          this.setState({ rules: rules });
                        }}
                        menuPortalTarget={document.getElementById("evRequirementConditionDialog")}
                        value={rule.leftOp}
                      />
                    </td>
                    <td className={classes.op1}>
                      <OutlinedSelectInput
                        value={rule.op1}
                        options={reqOperands}
                        noMargin={true}
                        compact={true}
                        onChange={(e) => {
                          this.updateRuleValue(ruleIndex, k, 'op1', e.target?.value || "")
                        }}
                      />
                    </td>
                    <td className={classes.rightOp}>
                      {rule.op1 !== 11 && <>
                        {!rule?.leftOp?.answer_type && (
                          <div>
                            <input placeholder="Enter Option" className={classes.input} />
                          </div>
                        )}
                        {[6, 7, 8, 9, 10].includes(rule.leftOp.answer_type) && (
                          <div>
                            <input
                              className={classes.input}
                              placeholder="Enter Option"
                              onChange={(e) => {
                                this.onRightChange(ruleIndex, k, e.target?.value || "");
                              }}
                              value={rule.rightOP}
                            />
                          </div>
                        )}
                        {[5, 12, 13].includes(rule?.leftOp?.answer_type) && (
                          <div>
                            <input
                              className={classes.input}
                              type="number"
                              placeholder="Enter Option"
                              onChange={(e) => {
                                this.onRightChange(ruleIndex, k, e.target?.value || "");
                              }}
                              value={rule.rightOP}
                            />
                          </div>
                        )}
                        {rule?.leftOp?.answer_type === 11 && (
                          <div>
                            <DatePicker
                              value={rule.rightOP && moment(rule.rightOP).format("DD/MM/YYYY")}
                              selected={""}
                              onChange={(date) => {
                                this.onRightChange(ruleIndex, k, date);
                              }}
                              placeholder="Choose Date"
                              dateFormat="MMM do yyyy"
                              portalId="workflow"
                              popperClassName={classes.calendarPopout}
                              minDate={""}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              customInput={
                                <OutlinedInput
                                  readOnlyInput={true}
                                  startAdornment={
                                    <InputAdornment>
                                      <Image src={CalendarImage} className={classes.inputCalendar} alt="input calendar" />
                                    </InputAdornment>
                                  }
                                />
                              }
                            />
                          </div>
                        )}

                        {[2, 3, 4, 14, 15].includes(rule?.leftOp?.answer_type) && <>
                          <ReactSelect
                            isClearable
                            isSearchable={true}
                            isMulti={rule?.leftOp?.answer_type === 4 ? false : true}
                            menuPosition='fixed'
                            options={rule?.leftOp?.answerOptions?.map((item, i) => {
                              if (rule?.leftOp?.answer_type === 4) {
                                return {
                                  value: (i && 'No') || 'Yes',
                                  label: item.Title,
                                }
                              } else {
                                return {
                                  value: item.ID,
                                  label: item.Title,
                                }
                              }
                            })}
                            classNamePrefix="select"
                            placeholder="Search Option"
                            onChange={(e) => {
                              // var rules = this.state.rules;
                              // var ruleElement = rules[ruleIndex].ruleElement[k];
                              // ruleElement.rightOP = e;
                              // rules[ruleIndex].ruleElement[k] = ruleElement;
                              // this.setState({ rules: rules });
                              this.onRightChange(ruleIndex, k, e);

                            }}
                            menuPortalTarget={document.getElementById("evRequirementConditionDialog")}
                            value={rule.rightOP}
                          />
                        </>}
                      </>}

                    </td>
                    <td className={classes.action}>
                      {<span hidden={element.ruleElement.length === 1}> <IconButton size="small" onClick={() => { this.deleteRuleElement(ruleIndex, k) }}>
                        <Image alt="Delete" src={deleteIcon} />
                      </IconButton> </span>}
                      {<span hidden={k !== element.ruleElement.length - 1}><IconButton size="small" onClick={() => this.addNewRuleElement(ruleIndex, k)}>
                        <AddCircleIcon />
                      </IconButton>  </span>}
                    </td>
                  </tr>
                  )}
                  <tr>
                    <td><p className={classes.conditions}>Action</p></td>
                    <td className={classes.rightOp}>
                      <ReactSelect
                        isClearable
                        isSearchable={true}
                        options={this.state.actionOption}
                        onChange={(e) => {
                          var rules = this.state.rules;
                          var actionElement = rules[ruleIndex].actionElement;
                          actionElement['data'] = '';
                          if (e?.value) {
                            actionElement['type'] = e;
                          } else {
                            actionElement['type'] = '';
                          }
                          rules[ruleIndex].actionElement = actionElement;
                          this.setState({ rules: rules });
                        }}
                        classNamePrefix="select"
                        placeholder="Choose Action"
                        value={element?.actionElement?.type}
                      />
                    </td>
                    {element?.actionElement?.type?.value === 1 && <td colSpan="2" className={classes.rightOp}>
                      <ReactSelect
                        isClearable
                        isSearchable={true}
                        options={goToCriteriaOptions}
                        onChange={(e) => {
                          var rules = this.state.rules;
                          var actionElement = rules[ruleIndex].actionElement;
                          if (e?.value) {
                            actionElement['data'] = e;
                          } else {
                            actionElement['data'] = '';
                          }
                          rules[ruleIndex].actionElement = actionElement;
                          this.setState({ rules: rules });

                        }}
                        classNamePrefix="select"
                        placeholder="Search Criteria"
                        value={element?.actionElement?.data}
                      />
                    </td>}
                    {element?.actionElement?.type?.value === 2 && <td colSpan="2" className={classes.rightOp}>
                      <ReactSelect
                        isClearable
                        isSearchable={true}
                        options={goToReqOptions}
                        onChange={(e) => {
                          var rules = this.state.rules;
                          var actionElement = rules[ruleIndex].actionElement;
                          if (e?.value) {
                            actionElement['data'] = e;
                          } else {
                            actionElement['data'] = '';
                          }
                          rules[ruleIndex].actionElement = actionElement;
                          this.setState({ rules: rules });
                        }}
                        classNamePrefix="select"
                        placeholder="Search Requirement"
                        value={element?.actionElement?.data}
                      />
                    </td>}
                    {element?.actionElement?.type?.value === 3 && <td colSpan="2" className={classes.rightOp}>
                      <ReactSelect
                        isClearable
                        isSearchable={true}
                        options={responseOption}
                        onChange={(e) => {
                          var rules = this.state.rules;
                          var actionElement = rules[ruleIndex].actionElement;
                          if (e?.value) {
                            actionElement['data'] = e;
                          } else {
                            actionElement['data'] = '';
                          }
                          rules[ruleIndex].actionElement = actionElement;
                          this.setState({ rules: rules });
                        }}
                        classNamePrefix="select"
                        placeholder="Search Response"
                        value={element?.actionElement?.data}
                      />
                    </td>}
                  </tr>
                </table>
              )
            })
          }
          <div className={classes.addAnotherRule} onClick={() => this.addAnotherRole()}>Add another rule</div>
          <div className={classes.alerts}>
            {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
            {this.state.success.length > 0 && <Alert variant="filled" severity="success">{this.state.success}</Alert>}
          </div>
        </DialogContent>
        <DialogActions>
          {!this.props.ruleSaveProgress && <>
            <Button variant="contained" color="secondary" className={classes.saveBtn} onClick={this.saveRule}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" className={classes.saveBtn} onClick={this.props.hideDialog}>
              Cancel
            </Button>
          </>}
          {this.props.ruleSaveProgress && <CircularProgress />}
        </DialogActions>
      </>}
    </Dialog>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(RequirementConditionDialog));