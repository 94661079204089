import {
  Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { TextField, FormControl } from '@material-ui/core';
import axios from "axios";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import SqureButton from 'Components/Common/SqureButton';
import { showSnackBar } from "redux/snackbar/action";
import FoldersTreeSelect from "Components/Common/FoldersTreeSelect";

import Select from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
const SelectAsyncPaginate = withAsyncPaginate(Select);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  fetchDocTypesProgress: state.documentTypeV2.fetchDocTypesProgress,
  fetchDocTypeError: state.documentTypeV2.fetchDocTypeError,
  documentTypes: state.documentTypeV2.types,
  contractFoldersData: state.vendors.contractFoldersData,
  addDocTypeFileProgress: state.documentTypeV2.addDocTypeFileProgress,
});

const connectionActions = {
  showSnackBar: showSnackBar,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  dialogContent: {
    margin: 'auto',
    marginBottom: 30
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    color: '#6F6F6F'
  },
  title: {
    fontWeight: 500,
    color: '#282D30',
    fontSize: 20,
  },
  subMessage: {
    fontWeight: 400,
    color: '#282D30',
    fontSize: 16,
  },
  bodyitm: {
    marginTop: 20
  },
  actionBtns: {
    marginTop: 20,
    textAlign: 'right'
  },
  formSelect: {
    width: '100%',
  },
  autoSelectInput: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: 10
    }
  },
  itemTitle: {
    fontWeight: 400,
    color: '#282D30',
    fontSize: 14,
    margin: '10px 0px 5px'
  },
});

class AddDocumentDetailsDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      name: '',
      user: '',
      type: '',
      parentfolder: null
    }

  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  onSave() {
    const { name, type, user,parentfolder } = this.state;
    if (name === '') {
      this.props.showSnackBar('Please enter name.', 'error', 3000);
      return;
    }

    if (type === undefined || type === null || type === '') {
      this.props.showSnackBar('Please select type.', 'error', 3000);
      return;
    }

    let params = {
      name: name,
      url: "",
      description: type.label,
      type_id: type.value,
      folder_id: parentfolder?.id,
      createdAt: new Date()
    }

    if (user !== undefined && user !== null && user !== '') {
      params['owner_id'] = String(user.value)
    }

    this.props.onSubmit(params);
  }


  onClose() {
    this.props.onClose()
  }

  getOptions = async (inputValue, loadedOptions, additional) => {
    var page = 0;
    if (additional !== undefined && additional.page !== undefined) {
      page = additional.page;
    }
    const api_server = Environment.api_host("USER");
    const timestamp = new Date().getTime();
    if (!inputValue) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        },
      };
    }

    let formData = {
      page: 0,
      pageSize: 20,
      query: inputValue
    };
    const url = `${api_server}/fetch?t=${timestamp}`;
    const response = await axios.post(url, formData, {
      headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
    });
    // console.log(response)

    let apps = response?.data?.Data || [];
    let total = response?.data?.Data?.total || 0;
    apps = apps.map((m) => ({
      label: m.Name,
      value: m.ID,
    }));
    return {
      options: apps,
      hasMore: (page + 1) * 10 < total ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };


  render() {
    const classes = this.props.classes;
    // console.log(this.props.addDetailsData, 'addDetailsData')
    const { name, user, type } = this.state;
    const { documentTypes } = this.props;
    const typeOptions = documentTypes.map(o => {
      return { label: o.name, value: o.id }
    })

    return <Dialog
      onClose={this.props.onClose}
      aria-labelledby="app-integrationDialog"
      open={true}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={"sm"}
      scroll="body"
      id="evMetaDialog"
    >
      <DialogContent classes={{ root: classes.dialogContent }} >
        <div className={classes.close} onClick={() => this.props.onClose()}><CloseIcon /></div>
        <Typography variant={"h4"} className={classes.title}>Document Details</Typography>
        <div className={classes.bodyitm}>
          <Typography variant={"h5"} className={classes.itemTitle} >Document Name</Typography>
          <TextField
            className={classes.autoSelectInput}
            value={name}
            fullWidth
            variant="outlined"
            placeholder={"Enter document name"}
            type="search"
            onInput={(e) => {
              this.setState({
                name: e.target.value
              })
            }}
          />
        </div>
        <div className={classes.bodyitm}>
          <Typography variant={"h5"} className={classes.itemTitle} >Document Type</Typography>
          <FormControl className={classes.formSelect} style={{ zIndex: 11 }}>
            <Select
              isClearable
              isMulti={false}
              labelId="singleSelect-select-label"
              id="singleSelect-select"
              isDisabled={false}
              value={type}
              onChange={(e) => {
                this.setState({
                  type: e
                })
              }}
              fullWidth
              options={typeOptions}
              menuPortalTarget={document.body}
              // menuPosition={'fixed'}
              menuPlacement={'auto'}
              placeholder={"Select Document Type"}
              styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
            />
          </FormControl>
        </div>
        <div className={classes.bodyitm}>
          <Typography variant={"h5"} className={classes.itemTitle} >Owner</Typography>
          <FormControl className={classes.formSelect} style={{ zIndex: 11 }}>
            <SelectAsyncPaginate
              isClearable={true}
              isMulti={false}
              isSearchable={true}
              value={user}
              menuPosition='fixed'
              classNamePrefix="select"
              isLoading={this.props.userSearchProgress}
              placeholder="Search User"
              additional={{
                page: 0,
              }}
              loadOptions={this.getOptions}
              onChange={(e) => {
                this.setState({
                  user: e
                })
              }}
            />
          </FormControl>
        </div>
        {this.props.contractFoldersData?.meta?.length > 0 && <div className={classes.bodyitm}>
            <FoldersTreeSelect handleChange={(value)=>this.setState({parentfolder: value})} value={this.state.parentfolder}/>
        </div>}
        <div className={classes.actionBtns}>
          <SqureButton
            variant={"outlined"}
            onClick={() => {
              this.onClose()
            }}
          >
            Cancel
          </SqureButton>

          <SqureButton
            disabled={this.props.fetchDocTypesProgress || this.props.addDocTypeFileProgress}
            style={{ marginLeft: 10 }}
            variant={"contained"}
            onClick={() => {
              this.onSave()
            }}
          >
            Save
          </SqureButton>
        </div>

      </DialogContent>
    </Dialog>
  }
}

AddDocumentDetailsDialog.defaultProps = {
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(AddDocumentDetailsDialog));