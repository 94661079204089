
import React from 'react';



import { InfoOutlined } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

const EntityCard = ({ showInfo = false, ...props }) => {
  return (
    <div 
      style={{
        display:"flex",
        flexDirection: "column",
        padding: "16px",
        justifyContent: "space-evenly",
        height: "100%",
        position: 'relative',
        borderRadius: "8px",
        border: `1px solid #003b4d26`,
        cursor: 'pointer',
        transitionDuration: '200ms',
        '&:hover': {
          '& .MuiButtonBase-root': {
            visibility: 'visible !important',
          },
        },
      }}
      onClick={props.handleClick}
    >
      <div  style={{display:"flex", flexDirection: "row", alignItems: "start" , justifyContent: "space-between"}} >
        <img
          src={props.logo}
          alt={'undraw_experience not found'}
          // width={40}
          height={40}
          // style={{marginRight: "8px"}}
        />
        {showInfo && (
          <InfoOutlined  
            style={{ cursor: 'pointer', height: "20px", width: "20px" }}
            handleClick={(e) => {
              if (props.handleClickInfo) {
                props.handleClickInfo(e);
              }
            }}
            />
        )}
      </div>
      <div style={{display:"flex", flexDirection: "column", marginTop: "12px" }}>
        <Typography
          variant="h6"
          style={{
            color: '#003B4D',
          }}
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          style={{ ...textEllipsisLines(1), color: '#003b4d99', mt: 0.5 }}
        >
          {props.subTitle}
        </Typography>
      </div>
    </div>
  );
};

export default EntityCard;
export const textEllipsisLines = (line) => {
  return {
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': `${line || 1}`,
    'line-clamp': `${line || 1}`,
    '-webkit-box-orient': 'vertical',
  };
};
