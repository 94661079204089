import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { connect } from "react-redux";
// redux
import { showAddVendorDialog } from "redux/vendor/vendorCatalog/action";

// Component styles
const useStyles = makeStyles(theme => ({
    btnAddVendor: {
        background: "#3C3C3C",
        borderRadius: 5,
        color: "#fff",
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        minWidth: 140,
        marginRight: 10,
        "&:hover": {
            background: "#3C3C3C",
            color: '#fff',
        },
    }
}));

const connectedProps = (state) => ({
    orgUser:state.orgUser,
    userType: state.authUser.user?.Role,
});

const connectionActions = {
    showAddVendorDialog: showAddVendorDialog,
};

var connector = connect(connectedProps, connectionActions);

function AddProductButton(props) {
    const classes = useStyles();
    return <>
        <Button className={classes.btnAddVendor} onClick={()=>{props.showAddVendorDialog();}}>
            + {props.title}
        </Button>
    </>;
}
 
export default connector(AddProductButton);
