import { Button, CircularProgress, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import AppBarV2 from "Components/Application/AppBarV2";
import DateFilter from "Components/Common/Filters/DateFilter";
import { useState } from "react";
import ReactSelect from "react-select";
import { CommonFn } from "services/commonFn";

import { useDispatch } from "react-redux";
import { downloadReport } from "redux/reports/action";

const Container = styled("div")({
  /* styles for the container div */
  display: "flex",
  flexDirection: "column",
  
  
});

const Content = styled("div")({
  /* styles for the content div */
  flex: 1,
  overflow: "auto",
  padding: "0 1rem",
  height: "100%",
  margin: "auto",
  marginTop: "4rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "center",
  width: 'fit-content',
  borderRadius: "8px", 
  padding: '2rem',
  backgroundColor: "#fff",
  
  //3d border 
  boxShadow: "0 0 0 1px rgba(0,0,0,.1), 0 4px 8px rgba(0,0,0,.1)",
  //marginBottom for each child
  "& > *": {
    marginBottom: "1rem",
  },
});

const LoadingContainer = styled("div")({
  /* styles for the loading container div */

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "2rem",
  width: "100%",

  height: "100%",
});

const SelectContainer = styled("div")({
  /* styles for the select container div */

  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "start",
  flexDirection: "row",
});

const SelectWrapper = styled("div")({
  /* styles for the select wrapper div */
  width: "fit-content",
  minWidth: "200px",
});
const DatePickerWrapper = styled("div")({
  /* styles for the date picker wrapper div */
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  flexDirection: "row",
  minWidth: "200px",
  width: "100%",
});
const FormGroup = styled("div")({
  /* styles for the form group div */
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  minWidth: "fit-content",
});
const Label = styled(Typography)({
  /* styles for the label typography */
  marginRight: "1rem",
  marginLeft: "1rem",
});

const Actions = styled("div")({
  /* styles for the actions div */
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  flexDirection: "row",
  width: "100%",
  marginTop: "2rem",
});

const Reports = () => {
  const [reportType, setReportType] = useState({
    value: "UserUsage",
    label: "User Usage",
  });
  const [startDate, setStartDate] = useState(new Date(CommonFn.getPreviousDate(180)));
  const [endDate, setEndDate] = useState(new Date());
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const generateReport = async () => {
   
    const payload = {
      event_type: reportType.value,
      from_date: startDate,
      to_date: endDate,
    };
    
    try {
      setLoading(true);
      // Dispatch the downloadReport action with the payload
      await dispatch(downloadReport(payload));
    } catch (error) {
      console.error("An error occurred while generating the report:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <AppBarV2 title={"Reports"} withBack={true} />
      <Content>
        <SelectContainer>
          <FormGroup>
            <Label variant={"body2"}>Report Type: </Label>
            <SelectWrapper>
              <ReactSelect
                isClearable={false}
                options={[
                  { value: "UserUsage", label: "User Usage" },
                  {
                    value: "SearchAndRequested",
                    label: "Search And Requested",
                  },
                  { value: "RedirectSearch", label: "Redirect Search" },
                  { value: "Applications", label: "Applications" },
                ]}
                classNamePrefix="select"
                placeholder={"Select"}
                onChange={(option) => {
                  console.log(option);
                  if (option) {
                    setReportType(option);
                  }
                }}
                value={reportType}
              />
            </SelectWrapper>
          </FormGroup>
        </SelectContainer>
        <DatePickerWrapper>
          <Label variant={"body2"}>Date Range: </Label>
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            duration={duration}
            onChangeDates={(dates) => {
              let startDate, endDate;
              if (dates.isCustomDates) {
                startDate = dates.startDate;
                let d = new Date(dates.endDate);
                d.setDate(d.getDate()+1)
                endDate = d;
              } else {
                if(dates.duration === 0){
                  // last six months
                  startDate = new Date(CommonFn.getPreviousDate(180));
                }else{
                  startDate = new Date(CommonFn.getPreviousDate(dates.duration));
                }
                endDate = new Date();
              }
              setStartDate(startDate);
              setEndDate(endDate);
              setDuration(dates.duration);
            }}
          />
        </DatePickerWrapper>
        {loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <Actions>
            {" "}
            <Button
              variant={"contained"}
              color="primary"
              onClick={generateReport}
            >
              Download Report
            </Button>
          </Actions>
        )}
      </Content>
    </Container>
  );
};

export default Reports;
