import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import classnames from "classnames";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import CommonCss from "../../commonCss";

import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField";
import SqureButton from "Components/Common/SqureButton";
import { useEffect } from "react";
import ReactSelect from "react-select";
import { getOrgWorkflow } from "redux/evaluation/workflow/action";
import * as Validator from "../../util/Validation";

const styles = (theme) =>
  createStyles({
    formControl: {
      ...CommonCss.formControl,
    },
    formGroup: {
      marginTop: 5,
      marginBottom: 16,
    },
    creatable: {
      padding: 0,
    },
    dialogContent: {
      minHeight: 170,
      overflowY: "hidden",
      "& [class*=select__menu]": {
        maxHeight: 100,
      },
    },
    searchAndSelect: {
      padding: 0,
      "& .select__control, & .select__control:hover": {
        border: "none",
        height: "inherit",
        boxShadow: "none",
        minHeight: 44,
      },
      "& .select__multi-value, & .select__multi-value__label": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
        padding: theme.spacing(0.3, 0.6),
        fontSize: 13,
      },
      "& .select__multi-value__remove, & .select__multi-value__remove:hover": {
        background: "#4A87F8",
        color: "#fff",
        borderRadius: 20,
      },
    },
    showNoteSection: {
      marginTop: 20,
      marginBottom: 20,
      "& [class*='editableField']": {
        padding: 5,
        margin: "0px !important",
        minHeight: "100px !important",
        maxHeight: "auto !important",
      },
      "& [data-slate-node='element']": {
        margin: 0,
        marginLeft: 5,
        marginTop: 5,
      },
    },
  });
function DialogOrgProductRequestAccess(props) {
  const { classes, open, handleClose, handleSubmit, RequestAccessConfig } =
    props;

  const dispatch = useDispatch();

  const { orgWorkFlowList } = useSelector((state) => state.evaluationWorkflow);

  const [error, setError] = useState("");
  const [workFlowList, setWorkFlowList] = useState(
    orgWorkFlowList?.Data
      ? orgWorkFlowList.Data.map((item) => {
          return {
            label: item.Name,
            value: item.ID,
          };
        })
      : []
  );

  const [selectedWorkflow, setSelectedWorkflow] = useState(
    RequestAccessConfig?.RequestAccessWorkflow || null
  );

  const [requestAccessConfig, setRequestAccessConfig] = useState(
    RequestAccessConfig
      ? RequestAccessConfig
      : {
          RequestAccessTypeOptions: [
            { label: "Redirect to URL", value: "AccessLink" },
            { label: "Show message", value: "RequestAccessMessage" },
            { label: "Execute Workflow", value: "RequestAccessWorkflow" },
          ],
          RequestAccessType: {
            label: "Redirect To URL",
            value: "AccessLink",
          },
          RequestAccessMessage: [
            { type: "paragraph", children: [{ text: "" }] },
          ],
          RequestAccessWorkflow: null,
          AccessLink: "",
        }
  );

  useEffect(() => {
    dispatch(getOrgWorkflow({ types: [1], page: 0, pageSize: 1000 }));
  }, []);

  const submitResponse = () => {
    // Implement the submit logic here
    let requestAccessConfigTemp = JSON.parse(
      JSON.stringify(requestAccessConfig)
    );

    if (
      requestAccessConfig.RequestAccessType?.value === "RequestAccessMessage"
    ) {
      if (
        requestAccessConfig?.RequestAccessMessage !==
          '[{"type":"paragraph","children":[{"text":""}]}]' &&
        '{"children":[{"text":""}],"type":"paragraph"}' !==
          requestAccessConfig?.RequestAccessMessage
      ) {
        requestAccessConfigTemp.AccessLink = "";
      } else {
        setError("Please enter message");
        return;
      }
    } else if (requestAccessConfig.RequestAccessType?.value === "AccessLink") {
      if (
        requestAccessConfig.AccessLink &&
        !Validator.validateURL(requestAccessConfig.AccessLink)
      ) {
        setError("Please enter valid URL");
        return;
      }
      requestAccessConfigTemp.RequestAccessMessage = [
        { type: "paragraph", children: [{ text: "" }] },
      ];
    } else if (
      requestAccessConfig.RequestAccessType?.value === "RequestAccessWorkflow"
    ) {
      // requestAccessConfigTemp.AccessLink = "";
      // requestAccessConfigTemp.RequestAccessMessage = [
      //   { type: "paragraph", children: [{ text: "" }] },
      // ];
      if (selectedWorkflow === null) {
        setError("Please select workflow");
        return;
      }
    }
    console.log(requestAccessConfigTemp);
    setRequestAccessConfig(requestAccessConfigTemp);
    handleSubmit?.(requestAccessConfigTemp);
    handleClose?.();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="new-champions-dialog"
      open={open}
      fullWidth={true}
      scroll={"body"}
      className={classnames(classes.dialog)}
      id="productChampDialog"
    >
      <DialogTitle id="form-dialog-title">{"Request Access"}</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.formGroup} style={{ marginBottom: "32px" }}>
          <label className={classes.label}>Request Access Type</label>
          <div
            className={classnames(classes.inputSection, "inputSection")}
            style={{ marginBottom: "12px" }}
          >
            <div
              style={{
                width: "100%",
                marginTop: "8px",
                marginBottom: "8px",
                zIndex: 9999999,
                position: "relative",
              }}
            >
              <ReactSelect
                isClearable={false}
                options={requestAccessConfig.RequestAccessTypeOptions}
                classNamePrefix=""
                placeholder={"Select"}
                onChange={(e) => {
                  console.log(e);
                  setRequestAccessConfig({
                    ...requestAccessConfig,
                    RequestAccessType: e,
                  });
                  setError("");
                }}
                css={{ height: "100%" }}
                value={requestAccessConfig.RequestAccessType}
              />
            </div>
          </div>
        </div>

        {requestAccessConfig.RequestAccessType?.value === "AccessLink" && (
          <div className={classes.formGroup}>
            <label className={classes.label}>
              Request Access Link<span className={classes.required}>*</span>
            </label>
            <div
              className={classnames(classes.inputSection, "inputSection")}
              style={{ marginTop: "8px" }}
            >
              <div className={classes.alert}>
                {error.length > 0 && (
                  <Alert variant="filled" severity="error">
                    {error}
                  </Alert>
                )}
              </div>
              <input
                defaultValue={requestAccessConfig.AccessLink}
                className={classes.formControl}
                placeholder="Request Access Link"
                name="AccessLink"
                onChange={(e) => {
                  if (Validator.validateURL(e.target.value)) {
                    setError("");
                  } else if (e.target.value) {
                    setError("Please enter valid URL");
                  }
                  setRequestAccessConfig({
                    ...requestAccessConfig,
                    AccessLink: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        )}

        {requestAccessConfig.RequestAccessType?.value ===
          "RequestAccessMessage" && (
          <div className={classes.formGroup}>
            <label className={classes.label} style={{ paddingTop: "8px" }}>
              Request Access Message
            </label>
            <div className={classnames(classes.inputSection, "inputSection")}>
              <div className={classes.alert}>
                {error.length > 0 && (
                  <Alert variant="filled" severity="error">
                    {error}
                  </Alert>
                )}
              </div>
              <SlateInputField
                style={{ width: "100%" }}
                as={SlateInputField}
                onChangeEvent={(value) => {
                  setError("");
                  setRequestAccessConfig({
                    ...requestAccessConfig,
                    RequestAccessMessage: value,
                  });
                }}
                placeholder={"Type Request Access Message"}
                initValue={
                  RequestAccessConfig.RequestAccessMessage || [
                    { type: "paragraph", children: [{ text: "" }] },
                  ] ||
                  ""
                }
                formControlStyle={{}}
                textContainerStyle={{}}
                external={true}
                folderPath={`/org_product/details/request_access`}
              />
            </div>
          </div>
        )}

        {requestAccessConfig.RequestAccessType?.value ===
          "RequestAccessWorkflow" && (
          <div className={classes.formGroup}>
            <>
              <div className={classes.body}>
                <div className={classes.alert}>
                  {error.length > 0 && (
                    <Alert variant="filled" severity="error">
                      {error}
                    </Alert>
                  )}
                </div>

                <div className={classes.box}>
                  <Typography className={classes.headingForm}>
                    Select Workflow <span className={classes.required}>*</span>
                  </Typography>

                  <ReactSelect
                    isClearable
                    isSearchable={true}
                    menuPosition="fixed"
                    options={workFlowList}
                    classNamePrefix=""
                    placeholder="Search Workflow"
                    onChange={(e) => {
                      setRequestAccessConfig({
                        ...requestAccessConfig,
                        RequestAccessWorkflow: e,
                      });
                      setSelectedWorkflow(e);
                      setError("");
                    }}
                    value={selectedWorkflow}
                  />
                </div>
              </div>
            </>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div style={{ padding: "10px 24px" }}>
          <>
            <SqureButton
              cls={classes.actionBtn}
              variant={"outlined"}
              onClick={handleClose}
            >
              Cancel
            </SqureButton>
            &nbsp;&nbsp;&nbsp;
            <SqureButton
              cls={classes.actionBtn}
              variant={"contained"}
              disabled={error.length > 0}
              onClick={submitResponse}
            >
              Save
            </SqureButton>
          </>
        </div>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  // Add your mapStateToProps logic here
});

const mapDispatchToProps = (dispatch) => ({
  // Add your mapDispatchToProps logic here
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DialogOrgProductRequestAccess)
);
