import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Button, FormControl, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { addOrgSettings, fetchAllOrgSettings } from "redux/org/user/action";
import "react-datepicker/dist/react-datepicker.css";
import deleteIcon from "assets/images/delete.svg";
import editIcon from "assets/images/edit_icon.svg";
import * as Validator from "util/Validation";
import Image from "Components/Common/image.jsx";
import Select from 'react-select';
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";

import {
  getOrgWorkflow
} from "redux/evaluation/workflow/action";

const connectedProps = (state) => ({
  user: state.orgUser,
  fetchAllOrgSettingsInprogress: state.orgUser.fetchAllOrgSettingsInprogress,
  orgWorkFlowProgress: state.evaluationWorkflow.orgWorkFlowProgress,
  orgWorkFlowList: state.evaluationWorkflow.orgWorkFlowList,
  orgWorkFlowError: state.evaluationWorkflow.orgWorkFlowError,
});

const connectionActions = {
  addOrgSettings: addOrgSettings,
  fetchAllOrgSettings: fetchAllOrgSettings,
  getOrgWorkflow: getOrgWorkflow
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    notificationRoot: {
      border: "1px solid #F2F2F2",
      margin: 20,
      minHeight: "69vh",
      padding: 10,
    },
    btnSection: {
      textAlign: "right",
      margin: "0 !important",
    },
    mainSection: {
      padding: 10,
      "& [class*=MuiTableContainer-root]": {
        overflowX: "clip",
      },
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#fff",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      "&:hover": {
        background: "#3C3C3C",
      },
    },
    formControl1: {
      padding: 10,
      display: "flex",
      border: "1px solid #b3b3b3",
      borderRadius: 4,
      width: '100%'
    },
    errorMessage: {
      color: 'red',
      fontSize: '12px'
    },
    criteriaName: {
      width: '40%'
    },
    approverData: {
      width: '40%',
    },
    actions: {
      width: '20%',
      textAlign: 'center'
    },
    customTypeFields: {
      // marginBottom: 15,
      // marginTop: 15,
      '& [class*="MuiFormControl-root"]': {
        width: '100%'
      },
      '& [class*="MuiInputBase-input"]': {
        textAlign: 'left'
      }
    },
    tickMark: {
      color: "#4b86f8",
      marginLeft: 10,
      border: "1px solid #4b86f8",
      // marginRight: 10,
      padding: 6,
      minWidth: "50px !important",
      "&:hover": {
        color: "#4b86f8",
      },
    },
    deleteBtn: {
      cursor: 'pointer',
      marginLeft: 10
    },
    actionBtns: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    actionBtns1: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20
    },
    errorMsg: {
      color: 'red'
    }
  });

class RequestTypeConfigurations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      requestTypes: "",
      isLoading: true,
      error: '',
      success: '',
      workflowOptions: [],
      showOnlyFor: '' // EXPENT
    };
    this.updateConfiguration = this.updateConfiguration.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount() {
    this.props.getOrgWorkflow({ types: [1], page: 0, pageSize: 200 })
    setTimeout(() => {
      this.initConfiguration();
    }, 1000);
    this.setState({
      error: "",
      success: "",
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchAllOrgSettingsInprogress === false && prevProps.fetchAllOrgSettingsInprogress === true) {
      this.initConfiguration();
    }

    if (this.props.orgWorkFlowProgress === false && prevProps.orgWorkFlowProgress === true) {
      if (this.props.orgWorkFlowError === null) {
        this.initWorkflowOptions();
      }
    }

    if (this.props.user.addOrgSettingsInprogress === false && prevProps.user.addOrgSettingsInprogress === true) {
      if (this.props.user.addOrgSettingsFailure == null) {
        // this.props.fetchAllOrgSettings();
        this.setState({
          isLoading: false,
        });
        this.props.showSnackBar("Updated Successfully.", "success", 3000);
      } else {
        this.setState({
          isLoading: false,
        });
        this.props.showSnackBar("Failed to update fields.", "error", 3000);
      }
    }
  }

  getConfigValue(array, key, value) {
    if (value) {
      let result = "";
      try {
        result = array.find((element) => element[key] === value)["Value"];
      } catch (e) { }
      return result.trim() || "";
    }
  }

  initConfiguration() {
    const { user } = this.props;
    const requestTypes = this.getConfigValue(user?.fetchAllOrgSettingsSuccess, "SettingName", "REQUEST_TYPES") || [];
    this.setState({
      requestTypes: Validator.isJson(requestTypes) ? JSON.parse(requestTypes) : [],
      isLoading: false,
    });
  }

  initWorkflowOptions() {
    const { orgWorkFlowList } = this.props;
    let workflowOptions = [];
    if (orgWorkFlowList) {
      workflowOptions = orgWorkFlowList.Data.map((wf) => {
        return {
          label: wf?.Name,
          value: wf?.ID
        }
      })
      this.setState({
        workflowOptions: workflowOptions
      })
    }
  }

  updateConfiguration() {
    this.setState({
      isLoading: true
    }, () => {
      let requestTypes = this.state.requestTypes;
      console.log(requestTypes, 'ruquestTypes')
      var notificationConfig = [
        {
          name: "REQUEST_TYPES",
          value: JSON.stringify(requestTypes),
        }
      ];
      this.props.addOrgSettings(notificationConfig);
    })
  }

  setDefaultProjectType(type) {
    let projectType;
    if (type === 'Vendors') {
      projectType = {
        label: 'Vendors',
        value: 'MASTER_VENDOR'
      }
    } else if (type === 'Products') {
      projectType = {
        label: 'Products',
        value: 'PRODUCT'
      }
    } else {
      projectType = {
        label: 'Services',
        value: 'MASTER_VENDOR_SERVICE'
      }
    }
    var defaultProjectConfig = [
      {
        name: "DEFAULT_PROJECT_TYPE_FOR_START",
        value: JSON.stringify(projectType),
      }
    ];
    this.props.addOrgSettings(defaultProjectConfig);
  }

  addNewRequestType = () => {
    let requestTypes = this.state.requestTypes;
    requestTypes.push({
      requestType: '',
      workflow: null,
      isEdit: true,
      isNew: true,
      workflowError: null,
      requestTypesError: null
    })
    this.setState({
      requestTypes: requestTypes
    })
  }

  removeRequirementType = (index) => {
    let requestTypes = this.state.requestTypes;
    let isNew = requestTypes[index].isNew;
    requestTypes.splice(index, 1);
    this.setState({
      requestTypes: requestTypes
    }, () => {
      if (!isNew) {
        this.updateConfiguration();
      }
    })
  }

  updateRequirementType = (index) => {
    let requestTypes = this.state.requestTypes;
    let data = requestTypes[index];
    let isError = false;

    data.requestTypesError = null;
    data.workflowError = null;

    if (data.requestType === '') {
      isError = true;
      data.requestTypesError = 'Please enter request type name';
    }

    if (data.workflow === null || data.workflow === '') {
      isError = true;
      data.workflowError = 'Please select workflow';
    }

    if (isError) {
      this.setState({
        requestTypes: requestTypes
      })
      return;
    }

    data.isNew = false;
    data.isEdit = false;
    data.workflowError = null;
    data.requestTypesError = null;

    this.updateConfiguration();
  }

  updateValue(index, field, value) {
    let requestTypes = this.state.requestTypes;
    let data = requestTypes[index];
    data.requestTypesError = null;
    data.workflowError = null;
    data[field] = value;
    this.setState({
      requestTypes: requestTypes
    })
  }

  render() {
    const { classes } = this.props;
    const { isLoading, requestTypes, workflowOptions } = this.state;

    console.log(workflowOptions, 'workflowOptions')

    if (isLoading) {
      return (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <div className={classes.notificationRoot}>
        <div className={classes.btnSection}>
          <Button
            className={classes.newUserBtn}
            onClick={() => {
              this.addNewRequestType()
            }}
          >
            + Add
          </Button>
        </div>
        <div className={classes.mainSection}>
          {(requestTypes === undefined || requestTypes === null || requestTypes === '' || requestTypes.length <= 0) && <div style={{ textAlign: 'center' }}>
            No Request Type
          </div>}

          {requestTypes !== undefined && requestTypes !== null && requestTypes !== '' && requestTypes.length > 0 && <TableContainer className={classes.tableContainer} style={{ minHeight: '65vh', maxHeight: '65vh', overflow: 'scroll' }}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={true ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.criteriaName}>Project Type</TableCell>
                  <TableCell className={classes.approverData}>Workflow</TableCell>
                  <TableCell className={classes.actions}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requestTypes.map((rt, k) => {
                  return <TableRow key={k}>
                    <TableCell className={classes.criteriaName}>
                      {(rt.isNew || rt.isEdit) && <div className={classnames(classes.inputSection, "inputSection")}>
                        <input
                          defaultValue={rt.requestType}
                          className={classes.formControl1}
                          placeholder="Project Type"
                          name="AccessLink"
                          // ref={register()}
                          onChange={(e) => {
                            this.updateValue(k, 'requestType', e.target.value)
                          }}
                        />
                      </div>}
                      {!rt.isNew && !rt.isEdit && <span>{rt?.requestType} </span>}
                      {rt.requestTypesError !== null && <span className={classes.errorMsg}>{rt.requestTypesError}</span>}
                    </TableCell>
                    <TableCell className={classes.approverData}>
                      {(rt.isNew || rt.isEdit) && <div className={classes.customTypeFields}>
                        <FormControl className={classes.formSelect}>
                          <Select
                            isClearable
                            isMulti={false}
                            labelId="singleSelect-select-label"
                            id="singleSelect-select"
                            value={rt.workflow}
                            onChange={(e) => {
                              this.updateValue(k, 'workflow', e)
                            }}
                            fullWidth
                            options={workflowOptions}
                            placeholder={"Select a Workflow"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          >
                          </Select>
                        </FormControl>
                      </div>}
                      {!rt.isNew && !rt.isEdit && <span>{rt?.workflow?.label} </span>}
                      {rt.workflowError !== null && <span className={classes.errorMsg}>{rt.workflowError}</span>}
                    </TableCell>
                    <TableCell className={classes.actions}>
                      {!rt.isNew && !rt.isEdit && <div className={classes.actionBtns}>
                        <Image className={classes.deleteBtn} src={editIcon} onClick={() => {
                          this.updateValue(k, 'isEdit', true)
                        }} />
                        <Image className={classes.deleteBtn} src={deleteIcon} onClick={() => {
                          this.removeRequirementType(k)
                        }} />
                      </div>}

                      {(rt.isNew || rt.isEdit) && <div className={rt.workflowError !== null || rt.requestTypesError !== null ? classes.actionBtns : classes.actionBtns} >
                        <Button
                          className={classes.tickMark}
                          onClick={() => {
                            this.updateRequirementType(k);
                          }}
                        >
                          <DoneIcon />
                        </Button>
                        <Button
                          className={classes.tickMark}
                          onClick={() => {
                            if (rt.isNew) {
                              this.removeRequirementType(k);
                            } else {
                              this.updateValue(k, 'isEdit', false)
                            }
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      </div>
                      }

                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>}

          {/* Do not remove below button code */}
          {this.state.showOnlyFor === 'EXPENT' && <Button
            className={classes.tickMark}
            onClick={() => {
              this.setDefaultProjectType('Vendors');
            }}
          >
            Set Default Project Type for Start new Project
          </Button>}
        </div>
      </div>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(RequestTypeConfigurations));
