
/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {HttpFactory} from "services/httpService";
import * as Environment from "../../util/Environment";
import {EvaluationFactory} from "services/evaluation";

// Add new evaluation dialog
export const SHOW_ADD_NEW_EVALUATION_DIALOG = "SHOW_ADD_NEW_EVALUATION_DIALOG";
export const HIDE_ADD_NEW_EVALUATION_DIALOG = "HIDE_ADD_NEW_EVALUATION_DIALOG";

// Product ranking dialog
export const SHOW_PRODUCT_RANKING_DIALOG = "SHOW_PRODUCT_RANKING_DIALOG";
export const HIDE_PRODUCT_RANKING_DIALOG = "HIDE_PRODUCT_RANKING_DIALOG";


// Evaluation Collaborator dialog
export const SHOW_EVALUATION_COLLABORATOR_DIALOG = "SHOW_EVALUATION_COLLABORATOR_DIALOG";
export const HIDE_EVALUATION_COLLABORATOR_DIALOG = "HIDE_EVALUATION_COLLABORATOR_DIALOG";

// My-products review filter dialog
export const SHOW_PRODUCTS_REVIEW_FILTER_DIALOG = "SHOW_PRODUCTS_REVIEW_FILTER_DIALOG";
export const HIDE_PRODUCTS_REVIEW_FILTER_DIALOG = "HIDE_PRODUCTS_REVIEW_FILTER_DIALOG";

// Fetch Vendor Evaluation detais
export const START_VENDORD_EVALUATION_PROGRESS = "START_VENDORD_EVALUATION_PROGRESS";
export const END_VENDORD_EVALUATION_PROGRESS = "END_VENDORD_EVALUATION_PROGRESS"

// Fetch inporgess, completed count
export const START_EVALUATE_STATUS_COUNT = "START_EVALUATE_STATUS_COUNT";
export const END_EVALUATE_STATUS_COUNT = "END_EVALUATE_STATUS_COUNT"

// Fetch inprogress Evaluation details
export const START_INPROGESS_EVALUATION = "START_INPROGESS_EVALUATION";
export const END_INPROGESS_EVALUATION = "END_INPROGESS_EVALUATION"

// Fetch completed Evaluation details
export const START_COMPLETED_EVALUATION = "START_COMPLETED_EVALUATION";
export const END_COMPLETED_EVALUATION = "END_COMPLETED_EVALUATION"

// delete evaluate collaborator
export const START_DELETE_EVALUATE_COLLABORATOR = "START_DELETE_EVALUATE_COLLABORATOR";
export const END_DELETE_EVALUATE_COLLABORATOR = "END_DELETE_EVALUATE_COLLABORATOR"

// delete criteria collaborator
export const START_DELETE_CRITERIA_COLLABORATOR = "START_DELETE_CRITERIA_COLLABORATOR";
export const END_DELETE_CRITERIA_COLLABORATOR = "END_DELETE_CRITERIA_COLLABORATOR"

// delete requirement collaborator
export const START_DELETE_REQUIREMENT_COLLABORATOR = "START_DELETE_REQUIREMENT_COLLABORATOR";
export const END_DELETE_REQUIREMENT_COLLABORATOR = "END_DELETE_REQUIREMENT_COLLABORATOR"

export const START_FETCH_SIMILAR_QUESTION = "START_FETCH_SIMILAR_QUESTION";
export const END_FETCH_SIMILAR_QUESTION = "END_FETCH_SIMILAR_QUESTION";

/**
 * fetch Similar question API Start
 */

 function startFetchSimilarQuestion(){
    return {
        type:START_FETCH_SIMILAR_QUESTION,
        payload:{}
    };
}

function endFetchSimilarQuestion(success,error){
    return {
        type:END_FETCH_SIMILAR_QUESTION,
        payload:{
            success,error
        }
    };
}

// API for evaluate inprogress and completed count
export  function fetchSimilarQuestion(name="", description="", type="evaluation-realtime", predefined_segment_ids=["org", "master"]){
    const api_server = Environment.api_host("DOMAIN");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/requirements/questions/search?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchSimilarQuestion());
        try{
            let [response,error]    =   await HttpFactory.instance().postMethod(url, 
                { 
                    predefined_segment_ids, 
                    name, 
                    description, 
                    type 
                },
                true
            );
            dispatch(endFetchSimilarQuestion(response,error));
        } catch(e){
            dispatch(endFetchSimilarQuestion(null,{
                message:e.message
            }));
            return;
        }
    };
} 

/**
 * Vendor Evaluation API Start
 */

function startEvaluateStatusCount(){
    return {
        type:START_EVALUATE_STATUS_COUNT,
        payload:{}
    };
}

function endEvaluateStatusCount(success,error){
    return {
        type:END_EVALUATE_STATUS_COUNT,
        payload:{
            success,error
        }
    };
}


/**
 * Add new evaluation dialog
 */
export function showAddNewEvaluationDialog(stepTwo = false){
    return {
        type: SHOW_ADD_NEW_EVALUATION_DIALOG,
        payload: { stepTwo: stepTwo }
    };
}

export function hideAddNewEvaluationDialog(){
    return {
        type: HIDE_ADD_NEW_EVALUATION_DIALOG,
        payload:null
    };
}


/**
 * Product ranking dialog
 */
export function showProductRankingDialog(data){
    return {
        type: SHOW_PRODUCT_RANKING_DIALOG,
        payload: {data}
    };
}

export function hideProductRankingDialog(){
    return {
        type: HIDE_PRODUCT_RANKING_DIALOG,
        payload:null
    };
}

/**
 * evaluation collaborator dialog
 */
export function showEvaluationCollaboratorDialog(target){
    return {
        type: SHOW_EVALUATION_COLLABORATOR_DIALOG,
        payload: {
            target
        }
    };
}

export function hideEvaluationCollaboratorDialog(){
    return {
        type: HIDE_EVALUATION_COLLABORATOR_DIALOG,
        payload:null
    };
}

/**
 * Products review filter dialog
 */
 export function showProductsReviewFilterDialog(selectedReview){
    return {
        type: SHOW_PRODUCTS_REVIEW_FILTER_DIALOG,
        payload: {
            selectedReview
        }
    };
}

export function hideProductsReviewFilterDialog(sortdata){
    const {daterange} = sortdata;
    return {
        type: HIDE_PRODUCTS_REVIEW_FILTER_DIALOG,
        payload:{
            daterange
        }
    };
}

/**
 * Vendor Evaluation API Start
 */

function startVendorEvaluationFetch(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_VENDORD_EVALUATION_PROGRESS,
        payload:payload
    };
}

function endVendorEvaluationFetch(success,error){
    return {
        type:END_VENDORD_EVALUATION_PROGRESS,
        payload:{
            success,error
        }
    };
}

function startInProgessEvaluationFetch(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_INPROGESS_EVALUATION,
        payload:payload
    };
}

function endInProgessEvaluationFetch(success,error){
    return {
        type:END_INPROGESS_EVALUATION,
        payload:{
            success,error
        }
    };
}

function startCompletedEvaluationFetch(onInit = false){
    let payload = {}
    payload.onInit = onInit
    return {
        type:START_COMPLETED_EVALUATION,
        payload:payload
    };
}

function endCompletedEvaluationFetch(success,error){
    return {
        type:END_COMPLETED_EVALUATION,
        payload:{
            success,error
        }
    };
}

export  function fetchVendorEvaluation(queryParam){
    return async (dispatch) => {
        dispatch(startVendorEvaluationFetch(queryParam.onInit));
        try{
            let [response,error]    =   await EvaluationFactory.instance().getVendorEvaluation(queryParam,false);
            response.onInit = queryParam.onInit;
            dispatch(endVendorEvaluationFetch(response,error));
        } catch(e){
            dispatch(endVendorEvaluationFetch(null,{
                message:e.message
            }));
            return;
        }
    };
}

export  function fetchInProgressEvaluation(queryParam){
    return async (dispatch) => {
        dispatch(startInProgessEvaluationFetch(queryParam.onInit));
        try{
            let [response,error]    =   await EvaluationFactory.instance().getVendorEvaluation(queryParam,false);
            response.onInit = queryParam.onInit;
            dispatch(endInProgessEvaluationFetch(response,error));
        } catch(e){
            dispatch(endInProgessEvaluationFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

export  function fetchCompletedEvaluation(queryParam){
    return async (dispatch) => {
        dispatch(startCompletedEvaluationFetch(queryParam.onInit));
        try{
            let [response,error]    =   await EvaluationFactory.instance().getVendorEvaluation(queryParam,false);
            response.onInit = queryParam.onInit;
            dispatch(endCompletedEvaluationFetch(response,error));
        } catch(e){
            dispatch(endCompletedEvaluationFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 
/**
 * Vendor Evaluation API Stop
 */


// API for evaluate inprogress and completed count
export  function fetchEvaluateStatusCount(queryParam){
    const api_server = Environment.api_host("EVALUATION");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/stats?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startEvaluateStatusCount());
        try{
            let [response,error]    =   await HttpFactory.instance().getMethod(url);
            dispatch(endEvaluateStatusCount(response,error));
        } catch(e){
            dispatch(endEvaluateStatusCount(null,{
                message:e.message
            }));
            return;
        }
    };
} 

// 1
function startRemoveEavluationCollaborator(){
    return {
        type:START_DELETE_EVALUATE_COLLABORATOR,
        payload:{}
    };
}

function endRemoveEavluationCollaborator(success,error){
    return {
        type:END_DELETE_EVALUATE_COLLABORATOR,
        payload:{
            success,error
        }
    };
}

export  function fetchRemoveEavluationCollaborator(queryParam){
    return async (dispatch) => {
        dispatch(startRemoveEavluationCollaborator());
        try{
            let [response,error]    =   await EvaluationFactory.instance().removeCollaboratorFromEvaluation(queryParam);
            dispatch(endRemoveEavluationCollaborator(response,error));
        } catch(e){
            dispatch(endRemoveEavluationCollaborator(null,{
                message:e.message
            }));
            return;
        }
    };
}
 
// 2
function startRemoveCriteriaCollaborator(){
    return {
        type:START_DELETE_CRITERIA_COLLABORATOR,
        payload:{}
    };
}

function endRemoveCriteriaCollaborator(success,error){
    return {
        type:END_DELETE_CRITERIA_COLLABORATOR,
        payload:{
            success,error
        }
    };
}

export  function fetchRemoveCriteriaCollaborator(queryParam){
    return async (dispatch) => {
        dispatch(startRemoveCriteriaCollaborator());
        try{
            let [response,error]    =   await EvaluationFactory.instance().removeCollaboratorFromCriteria(queryParam);
            dispatch(endRemoveCriteriaCollaborator(response,error));
        } catch(e){
            dispatch(endRemoveCriteriaCollaborator(null,{
                message:e.message
            }));
            return;
        }
    };
}


// 3
function startRemoveRequirementCollaborator(){
    return {
        type:START_DELETE_REQUIREMENT_COLLABORATOR,
        payload:{}
    };
}

function endRemoveRequirementCollaborator(success,error){
    return {
        type:END_DELETE_REQUIREMENT_COLLABORATOR,
        payload:{
            success,error
        }
    };
}

export  function fetchRemoveRequirementCollaborator(queryParam){
    return async (dispatch) => {
        dispatch(startRemoveRequirementCollaborator());
        try{
            let [response,error]    =   await EvaluationFactory.instance().removeCollaboratorFromRequirement(queryParam);
            dispatch(endRemoveRequirementCollaborator(response,error));
        } catch(e){
            dispatch(endRemoveRequirementCollaborator(null,{
                message:e.message
            }));
            return;
        }
    };
}


