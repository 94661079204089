const industries = [
  {
    label: "Accounting, bookkeeping and auditing activities; tax consultancy",
    value: "Accounting, bookkeeping and auditing activities; tax consultancy",
  },
  {
    label: "Activities of amusement parks and theme parks",
    value: "Activities of amusement parks and theme parks",
  },
  {
    label: "Activities of business and employers membership organizations",
    value: "Activities of business and employers membership organizations",
  },
  {
    label: "Activities of call centres",
    value: "Activities of call centres",
  },
  {
    label: "Activities of collection agencies and credit bureaus",
    value: "Activities of collection agencies and credit bureaus",
  },
  {
    label: "Activities of employment placement agencies",
    value: "Activities of employment placement agencies",
  },
  {
    label: "Activities of extraterritorial organizations and bodies",
    value: "Activities of extraterritorial organizations and bodies",
  },
  {
    label: "Activities of head offices",
    value: "Activities of head offices",
  },
  {
    label: "Activities of holding companies",
    value: "Activities of holding companies",
  },
  {
    label: "Activities of households as employers of domestic personnel",
    value: "Activities of households as employers of domestic personnel",
  },
  {
    label: "Activities of insurance agents and brokers",
    value: "Activities of insurance agents and brokers",
  },
  {
    label: "Activities of other membership organizations n.e.c.",
    value: "Activities of other membership organizations n.e.c.",
  },
  {
    label: "Activities of political organizations",
    value: "Activities of political organizations",
  },
  {
    label: "Activities of professional membership organizations",
    value: "Activities of professional membership organizations",
  },
  {
    label: "Activities of religious organizations",
    value: "Activities of religious organizations",
  },
  {
    label: "Activities of sports clubs",
    value: "Activities of sports clubs",
  },
  {
    label: "Activities of trade unions",
    value: "Activities of trade unions",
  },
  {
    label: "Administration of financial markets",
    value: "Administration of financial markets",
  },
  {
    label: "Advertising",
    value: "Advertising",
  },
  {
    label: "Architectural and engineering activities and related technical consultancy",
    value: "Architectural and engineering activities and related technical consultancy",
  },
  {
    label: "Beverage serving activities",
    value: "Beverage serving activities",
  },
  {
    label: "Book publishing",
    value: "Book publishing",
  },
  {
    label: "Botanical and zoological gardens and nature reserves activities",
    value: "Botanical and zoological gardens and nature reserves activities",
  },
  {
    label: "Building completion and finishing",
    value: "Building completion and finishing",
  },
  {
    label: "Building of pleasure and sporting boats",
    value: "Building of pleasure and sporting boats",
  },
  {
    label: "Building of ships and floating structures",
    value: "Building of ships and floating structures",
  },
  {
    label: "Camping grounds, recreational vehicle parks and trailer parks",
    value: "Camping grounds, recreational vehicle parks and trailer parks",
  },
  {
    label: "Cargo handling",
    value: "Cargo handling",
  },
  {
    label: "Casting of iron and steel",
    value: "Casting of iron and steel",
  },
  {
    label: "Casting of non-ferrous metals",
    value: "Casting of non-ferrous metals",
  },
  {
    label: "Central banking",
    value: "Central banking",
  },
  {
    label: "Collection of hazardous waste",
    value: "Collection of hazardous waste",
  },
  {
    label: "Collection of non-hazardous waste",
    value: "Collection of non-hazardous waste",
  },
  {
    label: "Combined facilities support activities",
    value: "Combined facilities support activities",
  },
  {
    label: "Combined office administrative service activities",
    value: "Combined office administrative service activities",
  },
  {
    label: "Compulsory social security activities",
    value: "Compulsory social security activities",
  },
  {
    label: "Computer consultancy and computer facilities management activities",
    value: "Computer consultancy and computer facilities management activities",
  },
  {
    label: "Computer programming activities",
    value: "Computer programming activities",
  },
  {
    label: "Construction of buildings",
    value: "Construction of buildings",
  },
  {
    label: "Construction of other civil engineering projects",
    value: "Construction of other civil engineering projects",
  },
  {
    label: "Construction of roads and railways",
    value: "Construction of roads and railways",
  },
  {
    label: "Construction of utility projects",
    value: "Construction of utility projects",
  },
  {
    label: "Courier activities",
    value: "Courier activities",
  },
  {
    label: "Creative, arts and entertainment activities",
    value: "Creative, arts and entertainment activities",
  },
  {
    label: "Cultural education",
    value: "Cultural education",
  },
  {
    label: "Cutting, shaping and finishing of stone",
    value: "Cutting, shaping and finishing of stone",
  },
  {
    label: "Data processing, hosting and related activities",
    value: "Data processing, hosting and related activities",
  },
  {
    label: "Defence activities",
    value: "Defence activities",
  },
  {
    label: "Demolition",
    value: "Demolition",
  },
  {
    label: "Distilling, rectifying and blending of spirits",
    value: "Distilling, rectifying and blending of spirits",
  },
  {
    label: "Educational support activities",
    value: "Educational support activities",
  },
  {
    label: "Electric power generation, transmission and distribution",
    value: "Electric power generation, transmission and distribution",
  },
  {
    label: "Electrical installation",
    value: "Electrical installation",
  },
  {
    label: "Event catering",
    value: "Event catering",
  },
  {
    label: "Extraction of crude petroleum",
    value: "Extraction of crude petroleum",
  },
  {
    label: "Extraction of natural gas",
    value: "Extraction of natural gas",
  },
  {
    label: "Extraction of peat",
    value: "Extraction of peat",
  },
  {
    label: "Extraction of salt",
    value: "Extraction of salt",
  },
  {
    label: "Financial leasing",
    value: "Financial leasing",
  },
  {
    label: "Finishing of textiles",
    value: "Finishing of textiles",
  },
  {
    label: "Foreign affairs",
    value: "Foreign affairs",
  },
  {
    label: "Forging, pressing, stamping and roll-forming of metal; powder metallurgy",
    value: "Forging, pressing, stamping and roll-forming of metal; powder metallurgy",
  },
  {
    label: "Freight air transport",
    value: "Freight air transport",
  },
  {
    label: "Freight rail transport",
    value: "Freight rail transport",
  },
  {
    label: "Freight transport by road",
    value: "Freight transport by road",
  },
  {
    label: "Freshwater aquaculture",
    value: "Freshwater aquaculture",
  },
  {
    label: "Freshwater fishing",
    value: "Freshwater fishing",
  },
  {
    label: "Fund management activities",
    value: "Fund management activities",
  },
  {
    label: "Funeral and related activities",
    value: "Funeral and related activities",
  },
  {
    label: "Gambling and betting activities",
    value: "Gambling and betting activities",
  },
  {
    label: "Gathering of non-wood forest products",
    value: "Gathering of non-wood forest products",
  },
  {
    label: "General cleaning of buildings",
    value: "General cleaning of buildings",
  },
  {
    label: "General public administration activities",
    value: "General public administration activities",
  },
  {
    label: "General secondary education",
    value: "General secondary education",
  },
  {
    label: "Growing of beverage crops",
    value: "Growing of beverage crops",
  },
  {
    label: "Growing of cereals (except rice), leguminous crops and oil seeds",
    value: "Growing of cereals (except rice), leguminous crops and oil seeds",
  },
  {
    label: "Growing of citrus fruits",
    value: "Growing of citrus fruits",
  },
  {
    label: "Growing of fibre crops",
    value: "Growing of fibre crops",
  },
  {
    label: "Growing of grapes",
    value: "Growing of grapes",
  },
  {
    label: "Growing of oleaginous fruits",
    value: "Growing of oleaginous fruits",
  },
  {
    label: "Growing of other non-perennial crops",
    value: "Growing of other non-perennial crops",
  },
  {
    label: "Growing of other perennial crops",
    value: "Growing of other perennial crops",
  },
  {
    label: "Growing of other tree and bush fruits and nuts",
    value: "Growing of other tree and bush fruits and nuts",
  },
  {
    label: "Growing of pome fruits and stone fruits",
    value: "Growing of pome fruits and stone fruits",
  },
  {
    label: "Growing of rice",
    value: "Growing of rice",
  },
  {
    label: "Growing of spices, aromatic, drug and pharmaceutical crops",
    value: "Growing of spices, aromatic, drug and pharmaceutical crops",
  },
  {
    label: "Growing of sugar cane",
    value: "Growing of sugar cane",
  },
  {
    label: "Growing of tobacco",
    value: "Growing of tobacco",
  },
  {
    label: "Growing of tropical and subtropical fruits",
    value: "Growing of tropical and subtropical fruits",
  },
  {
    label: "Growing of vegetables and melons, roots and tubers",
    value: "Growing of vegetables and melons, roots and tubers",
  },
  {
    label: "Hairdressing and other beauty treatment",
    value: "Hairdressing and other beauty treatment",
  },
  {
    label: "Higher education",
    value: "Higher education",
  },
  {
    label: "Hospital activities",
    value: "Hospital activities",
  },
  {
    label: "Hunting, trapping and related service activities",
    value: "Hunting, trapping and related service activities",
  },
  {
    label: "Inland freight water transport",
    value: "Inland freight water transport",
  },
  {
    label: "Inland passenger water transport",
    value: "Inland passenger water transport",
  },
  {
    label: "Installation of industrial machinery and equipment",
    value: "Installation of industrial machinery and equipment",
  },
  {
    label: "Investigation activities",
    value: "Investigation activities",
  },
  {
    label: "Landscape care and maintenance service activities",
    value: "Landscape care and maintenance service activities",
  },
  {
    label: "Leasing of intellectual property and similar products, except copyrighted works",
    value: "Leasing of intellectual property and similar products, except copyrighted works",
  },
  {
    label: "Legal activities",
    value: "Legal activities",
  },
  {
    label: "Library and archives activities",
    value: "Library and archives activities",
  },
  {
    label: "Life insurance",
    value: "Life insurance",
  },
  {
    label: "Logging",
    value: "Logging",
  },
  {
    label: "Maintenance and repair of motor vehicles",
    value: "Maintenance and repair of motor vehicles",
  },
  {
    label: "Management consultancy activities",
    value: "Management consultancy activities",
  },
  {
    label: "Manufacture of agricultural and forestry machinery",
    value: "Manufacture of agricultural and forestry machinery",
  },
  {
    label: "Manufacture of air and spacecraft and related machinery",
    value: "Manufacture of air and spacecraft and related machinery",
  },
  {
    label: "Manufacture of articles of concrete, cement and plaster",
    value: "Manufacture of articles of concrete, cement and plaster",
  },
  {
    label: "Manufacture of articles of fur",
    value: "Manufacture of articles of fur",
  },
  {
    label: "Manufacture of bakery products",
    value: "Manufacture of bakery products",
  },
  {
    label: "Manufacture of basic chemicals",
    value: "Manufacture of basic chemicals",
  },
  {
    label: "Manufacture of basic iron and steel",
    value: "Manufacture of basic iron and steel",
  },
  {
    label: "Manufacture of basic precious and other non-ferrous metals",
    value: "Manufacture of basic precious and other non-ferrous metals",
  },
  {
    label: "Manufacture of batteries and accumulators",
    value: "Manufacture of batteries and accumulators",
  },
  {
    label: "Manufacture of bearings, gears, gearing and driving elements",
    value: "Manufacture of bearings, gears, gearing and driving elements",
  },
  {
    label: "Manufacture of bicycles and invalid carriages",
    value: "Manufacture of bicycles and invalid carriages",
  },
  {
    label: "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers",
    value: "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers",
  },
  {
    label: "Manufacture of builders' carpentry and joinery",
    value: "Manufacture of builders' carpentry and joinery",
  },
  {
    label: "Manufacture of carpets and rugs",
    value: "Manufacture of carpets and rugs",
  },
  {
    label: "Manufacture of cement, lime and plaster",
    value: "Manufacture of cement, lime and plaster",
  },
  {
    label: "Manufacture of clay building materials",
    value: "Manufacture of clay building materials",
  },
  {
    label: "Manufacture of cocoa, chocolate and sugar confectionery",
    value: "Manufacture of cocoa, chocolate and sugar confectionery",
  },
  {
    label: "Manufacture of coke oven products",
    value: "Manufacture of coke oven products",
  },
  {
    label: "Manufacture of communication equipment",
    value: "Manufacture of communication equipment",
  },
  {
    label: "Manufacture of computers and peripheral equipment",
    value: "Manufacture of computers and peripheral equipment",
  },
  {
    label: "Manufacture of consumer electronics",
    value: "Manufacture of consumer electronics",
  },
  {
    label: "Manufacture of cordage, rope, twine and netting",
    value: "Manufacture of cordage, rope, twine and netting",
  },
  {
    label: "Manufacture of corrugated paper and paperboard and of containers of paper and paperboard",
    value: "Manufacture of corrugated paper and paperboard and of containers of paper and paperboard",
  },
  {
    label: "Manufacture of cutlery, hand tools and general hardware",
    value: "Manufacture of cutlery, hand tools and general hardware",
  },
  {
    label: "Manufacture of dairy products",
    value: "Manufacture of dairy products",
  },
  {
    label: "Manufacture of domestic appliances",
    value: "Manufacture of domestic appliances",
  },
  {
    label: "Manufacture of electric lighting equipment",
    value: "Manufacture of electric lighting equipment",
  },
  {
    label: "Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus",
    value: "Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus",
  },
  {
    label: "Manufacture of electronic components and boards",
    value: "Manufacture of electronic components and boards",
  },
  {
    label: "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines",
    value: "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines",
  },
  {
    label: "Manufacture of fertilizers and nitrogen compounds",
    value: "Manufacture of fertilizers and nitrogen compounds",
  },
  {
    label: "Manufacture of fibre optic cables",
    value: "Manufacture of fibre optic cables",
  },
  {
    label: "Manufacture of fluid power equipment",
    value: "Manufacture of fluid power equipment",
  },
  {
    label: "Manufacture of footwear",
    value: "Manufacture of footwear",
  },
  {
    label: "Manufacture of furniture",
    value: "Manufacture of furniture",
  },
  {
    label: "Manufacture of games and toys",
    value: "Manufacture of games and toys",
  },
  {
    label: "Manufacture of gas; distribution of gaseous fuels through mains",
    value: "Manufacture of gas; distribution of gaseous fuels through mains",
  },
  {
    label: "Manufacture of glass and glass products",
    value: "Manufacture of glass and glass products",
  },
  {
    label: "Manufacture of grain mill products",
    value: "Manufacture of grain mill products",
  },
  {
    label: "Manufacture of imitation jewellery and related articles",
    value: "Manufacture of imitation jewellery and related articles",
  },
  {
    label: "Manufacture of irradiation, electromedical and electrotherapeutic equipment",
    value: "Manufacture of irradiation, electromedical and electrotherapeutic equipment",
  },
  {
    label: "Manufacture of jewellery and related articles",
    value: "Manufacture of jewellery and related articles",
  },
  {
    label: "Manufacture of knitted and crocheted apparel",
    value: "Manufacture of knitted and crocheted apparel",
  },
  {
    label: "Manufacture of knitted and crocheted fabrics",
    value: "Manufacture of knitted and crocheted fabrics",
  },
  {
    label: "Manufacture of lifting and handling equipment",
    value: "Manufacture of lifting and handling equipment",
  },
  {
    label: "Manufacture of luggage, handbags and the like, saddlery and harness",
    value: "Manufacture of luggage, handbags and the like, saddlery and harness",
  },
  {
    label: "Manufacture of macaroni, noodles, couscous and similar farinaceous products",
    value: "Manufacture of macaroni, noodles, couscous and similar farinaceous products",
  },
  {
    label: "Manufacture of machinery for food, beverage and tobacco processing",
    value: "Manufacture of machinery for food, beverage and tobacco processing",
  },
  {
    label: "Manufacture of machinery for metallurgy",
    value: "Manufacture of machinery for metallurgy",
  },
  {
    label: "Manufacture of machinery for mining, quarrying and construction",
    value: "Manufacture of machinery for mining, quarrying and construction",
  },
  {
    label: "Manufacture of machinery for textile, apparel and leather production",
    value: "Manufacture of machinery for textile, apparel and leather production",
  },
  {
    label: "Manufacture of made-up textile articles, except apparel",
    value: "Manufacture of made-up textile articles, except apparel",
  },
  {
    label: "Manufacture of magnetic and optical media",
    value: "Manufacture of magnetic and optical media",
  },
  {
    label: "Manufacture of malt liquors and malt",
    value: "Manufacture of malt liquors and malt",
  },
  {
    label: "Manufacture of man-made fibres",
    value: "Manufacture of man-made fibres",
  },
  {
    label: "Manufacture of measuring, testing, navigating and control equipment",
    value: "Manufacture of measuring, testing, navigating and control equipment",
  },
  {
    label: "Manufacture of medical and dental instruments and supplies",
    value: "Manufacture of medical and dental instruments and supplies",
  },
  {
    label: "Manufacture of metal-forming machinery and machine tools",
    value: "Manufacture of metal-forming machinery and machine tools",
  },
  {
    label: "Manufacture of military fighting vehicles",
    value: "Manufacture of military fighting vehicles",
  },
  {
    label: "Manufacture of motor vehicles",
    value: "Manufacture of motor vehicles",
  },
  {
    label: "Manufacture of motorcycles",
    value: "Manufacture of motorcycles",
  },
  {
    label: "Manufacture of musical instruments",
    value: "Manufacture of musical instruments",
  },
  {
    label: "Manufacture of office machinery and equipment (except computers and peripheral equipment)",
    value: "Manufacture of office machinery and equipment (except computers and peripheral equipment)",
  },
  {
    label: "Manufacture of optical instruments and photographic equipment",
    value: "Manufacture of optical instruments and photographic equipment",
  },
  {
    label: "Manufacture of other articles of paper and paperboard",
    value: "Manufacture of other articles of paper and paperboard",
  },
  {
    label: "Manufacture of other chemical products n.e.c.",
    value: "Manufacture of other chemical products n.e.c.",
  },
  {
    label: "Manufacture of other electrical equipment",
    value: "Manufacture of other electrical equipment",
  },
  {
    label: "Manufacture of other electronic and electric wires and cables",
    value: "Manufacture of other electronic and electric wires and cables",
  },
  {
    label: "Manufacture of other fabricated metal products n.e.c.",
    value: "Manufacture of other fabricated metal products n.e.c.",
  },
  {
    label: "Manufacture of other food products n.e.c.",
    value: "Manufacture of other food products n.e.c.",
  },
  {
    label: "Manufacture of other general-purpose machinery",
    value: "Manufacture of other general-purpose machinery",
  },
  {
    label: "Manufacture of other non-metallic mineral products n.e.c.",
    value: "Manufacture of other non-metallic mineral products n.e.c.",
  },
  {
    label: "Manufacture of other porcelain and ceramic products",
    value: "Manufacture of other porcelain and ceramic products",
  },
  {
    label: "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials",
    value: "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials",
  },
  {
    label: "Manufacture of other pumps, compressors, taps and valves",
    value: "Manufacture of other pumps, compressors, taps and valves",
  },
  {
    label: "Manufacture of other rubber products",
    value: "Manufacture of other rubber products",
  },
  {
    label: "Manufacture of other special-purpose machinery",
    value: "Manufacture of other special-purpose machinery",
  },
  {
    label: "Manufacture of other textiles n.e.c.",
    value: "Manufacture of other textiles n.e.c.",
  },
  {
    label: "Manufacture of other transport equipment n.e.c.",
    value: "Manufacture of other transport equipment n.e.c.",
  },
  {
    label: "Manufacture of ovens, furnaces and furnace burners",
    value: "Manufacture of ovens, furnaces and furnace burners",
  },
  {
    label: "Manufacture of paints, varnishes and similar coatings, printing ink and mastics",
    value: "Manufacture of paints, varnishes and similar coatings, printing ink and mastics",
  },
  {
    label: "Manufacture of parts and accessories for motor vehicles",
    value: "Manufacture of parts and accessories for motor vehicles",
  },
  {
    label: "Manufacture of pesticides and other agrochemical products",
    value: "Manufacture of pesticides and other agrochemical products",
  },
  {
    label: "Manufacture of pharmaceuticals, medicinal chemical and botanical products",
    value: "Manufacture of pharmaceuticals, medicinal chemical and botanical products",
  },
  {
    label: "Manufacture of plastics and synthetic rubber in primary forms",
    value: "Manufacture of plastics and synthetic rubber in primary forms",
  },
  {
    label: "Manufacture of plastics products",
    value: "Manufacture of plastics products",
  },
  {
    label: "Manufacture of power-driven hand tools",
    value: "Manufacture of power-driven hand tools",
  },
  {
    label: "Manufacture of prepared animal feeds",
    value: "Manufacture of prepared animal feeds",
  },
  {
    label: "Manufacture of prepared meals and dishes",
    value: "Manufacture of prepared meals and dishes",
  },
  {
    label: "Manufacture of pulp, paper and paperboard",
    value: "Manufacture of pulp, paper and paperboard",
  },
  {
    label: "Manufacture of railway locomotives and rolling stock",
    value: "Manufacture of railway locomotives and rolling stock",
  },
  {
    label: "Manufacture of refined petroleum products",
    value: "Manufacture of refined petroleum products",
  },
  {
    label: "Manufacture of refractory products",
    value: "Manufacture of refractory products",
  },
  {
    label: "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres",
    value: "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres",
  },
  {
    label: "Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations",
    value: "Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations",
  },
  {
    label: "Manufacture of soft drinks; production of mineral waters and other bottled waters",
    value: "Manufacture of soft drinks; production of mineral waters and other bottled waters",
  },
  {
    label: "Manufacture of sports goods",
    value: "Manufacture of sports goods",
  },
  {
    label: "Manufacture of starches and starch products",
    value: "Manufacture of starches and starch products",
  },
  {
    label: "Manufacture of steam generators, except central heating hot water boilers",
    value: "Manufacture of steam generators, except central heating hot water boilers",
  },
  {
    label: "Manufacture of structural metal products",
    value: "Manufacture of structural metal products",
  },
  {
    label: "Manufacture of sugar",
    value: "Manufacture of sugar",
  },
  {
    label: "Manufacture of tanks, reservoirs and containers of metal",
    value: "Manufacture of tanks, reservoirs and containers of metal",
  },
  {
    label: "Manufacture of tobacco products",
    value: "Manufacture of tobacco products",
  },
  {
    label: "Manufacture of vegetable and animal oils and fats",
    value: "Manufacture of vegetable and animal oils and fats",
  },
  {
    label: "Manufacture of veneer sheets and wood-based panels",
    value: "Manufacture of veneer sheets and wood-based panels",
  },
  {
    label: "Manufacture of watches and clocks",
    value: "Manufacture of watches and clocks",
  },
  {
    label: "Manufacture of weapons and ammunition",
    value: "Manufacture of weapons and ammunition",
  },
  {
    label: "Manufacture of wearing apparel, except fur apparel",
    value: "Manufacture of wearing apparel, except fur apparel",
  },
  {
    label: "Manufacture of wines",
    value: "Manufacture of wines",
  },
  {
    label: "Manufacture of wiring devices",
    value: "Manufacture of wiring devices",
  },
  {
    label: "Manufacture of wooden containers",
    value: "Manufacture of wooden containers",
  },
  {
    label: "Marine aquaculture",
    value: "Marine aquaculture",
  },
  {
    label: "Marine fishing",
    value: "Marine fishing",
  },
  {
    label: "Market research and public opinion polling",
    value: "Market research and public opinion polling",
  },
  {
    label: "Materials recovery",
    value: "Materials recovery",
  },
  {
    label: "Medical and dental practice activities",
    value: "Medical and dental practice activities",
  },
  {
    label: "Mining of chemical and fertilizer minerals",
    value: "Mining of chemical and fertilizer minerals",
  },
  {
    label: "Mining of hard coal",
    value: "Mining of hard coal",
  },
  {
    label: "Mining of iron ores",
    value: "Mining of iron ores",
  },
  {
    label: "Mining of lignite",
    value: "Mining of lignite",
  },
  {
    label: "Mining of other non-ferrous metal ores",
    value: "Mining of other non-ferrous metal ores",
  },
  {
    label: "Mining of uranium and thorium ores",
    value: "Mining of uranium and thorium ores",
  },
  {
    label: "Mixed farming",
    value: "Mixed farming",
  },
  {
    label: "Motion picture projection activities",
    value: "Motion picture projection activities",
  },
  {
    label: "Motion picture, video and television programme distribution activities",
    value: "Motion picture, video and television programme distribution activities",
  },
  {
    label: "Motion picture, video and television programme post-production activities",
    value: "Motion picture, video and television programme post-production activities",
  },
  {
    label: "Motion picture, video and television programme production activities",
    value: "Motion picture, video and television programme production activities",
  },
  {
    label: "Museums activities and operation of historical sites and buildings",
    value: "Museums activities and operation of historical sites and buildings",
  },
  {
    label: "News agency activities",
    value: "News agency activities",
  },
  {
    label: "Non-life insurance",
    value: "Non-life insurance",
  },
  {
    label: "Non-specialized wholesale trade",
    value: "Non-specialized wholesale trade",
  },
  {
    label: "Operation of sports facilities",
    value: "Operation of sports facilities",
  },
  {
    label: "Organization of conventions and trade shows",
    value: "Organization of conventions and trade shows",
  },
  {
    label: "Other accommodation",
    value: "Other accommodation",
  },
  {
    label: "Other activities auxiliary to financial service activities",
    value: "Other activities auxiliary to financial service activities",
  },
  {
    label: "Other activities auxiliary to insurance and pension funding",
    value: "Other activities auxiliary to insurance and pension funding",
  },
  {
    label: "Other amusement and recreation activities n.e.c.",
    value: "Other amusement and recreation activities n.e.c.",
  },
  {
    label: "Other building and industrial cleaning activities",
    value: "Other building and industrial cleaning activities",
  },
  {
    label: "Other business support service activities n.e.c.",
    value: "Other business support service activities n.e.c.",
  },
  {
    label: "Other construction installation",
    value: "Other construction installation",
  },
  {
    label: "Other credit granting",
    value: "Other credit granting",
  },
  {
    label: "Other education n.e.c.",
    value: "Other education n.e.c.",
  },
  {
    label: "Other financial service activities, except insurance and pension funding activities, n.e.c.",
    value: "Other financial service activities, except insurance and pension funding activities, n.e.c.",
  },
  {
    label: "Other food service activities",
    value: "Other food service activities",
  },
  {
    label: "Other human health activities",
    value: "Other human health activities",
  },
  {
    label: "Other human resources provision",
    value: "Other human resources provision",
  },
  {
    label: "Other information service activities n.e.c.",
    value: "Other information service activities n.e.c.",
  },
  {
    label: "Other information technology and computer service activities",
    value: "Other information technology and computer service activities",
  },
  {
    label: "Other manufacturing n.e.c.",
    value: "Other manufacturing n.e.c.",
  },
  {
    label: "Other mining and quarrying n.e.c.",
    value: "Other mining and quarrying n.e.c.",
  },
  {
    label: "Other monetary intermediation",
    value: "Other monetary intermediation",
  },
  {
    label: "Other passenger land transport",
    value: "Other passenger land transport",
  },
  {
    label: "Other personal service activities n.e.c.",
    value: "Other personal service activities n.e.c.",
  },
  {
    label: "Other professional, scientific and technical activities n.e.c.",
    value: "Other professional, scientific and technical activities n.e.c.",
  },
  {
    label: "Other publishing activities",
    value: "Other publishing activities",
  },
  {
    label: "Other reservation service and related activities",
    value: "Other reservation service and related activities",
  },
  {
    label: "Other residential care activities",
    value: "Other residential care activities",
  },
  {
    label: "Other retail sale in non-specialized stores",
    value: "Other retail sale in non-specialized stores",
  },
  {
    label: "Other retail sale not in stores, stalls or markets",
    value: "Other retail sale not in stores, stalls or markets",
  },
  {
    label: "Other retail sale of new goods in specialized stores",
    value: "Other retail sale of new goods in specialized stores",
  },
  {
    label: "Other social work activities without accommodation",
    value: "Other social work activities without accommodation",
  },
  {
    label: "Other specialized construction activities",
    value: "Other specialized construction activities",
  },
  {
    label: "Other sports activities",
    value: "Other sports activities",
  },
  {
    label: "Other telecommunications activities",
    value: "Other telecommunications activities",
  },
  {
    label: "Other transportation support activities",
    value: "Other transportation support activities",
  },
  {
    label: "Packaging activities",
    value: "Packaging activities",
  },
  {
    label: "Passenger air transport",
    value: "Passenger air transport",
  },
  {
    label: "Passenger rail transport, interurban",
    value: "Passenger rail transport, interurban",
  },
  {
    label: "Pension funding",
    value: "Pension funding",
  },
  {
    label: "Photocopying, document preparation and other specialized office support activities",
    value: "Photocopying, document preparation and other specialized office support activities",
  },
  {
    label: "Photographic activities",
    value: "Photographic activities",
  },
  {
    label: "Plant propagation",
    value: "Plant propagation",
  },
  {
    label: "Plumbing, heat and air-conditioning installation",
    value: "Plumbing, heat and air-conditioning installation",
  },
  {
    label: "Post-harvest crop activities",
    value: "Post-harvest crop activities",
  },
  {
    label: "Postal activities",
    value: "Postal activities",
  },
  {
    label: "Pre-primary and primary education",
    value: "Pre-primary and primary education",
  },
  {
    label: "Preparation and spinning of textile fibres",
    value: "Preparation and spinning of textile fibres",
  },
  {
    label: "Printing",
    value: "Printing",
  },
  {
    label: "Private security activities",
    value: "Private security activities",
  },
  {
    label: "Processing and preserving of fish, crustaceans and molluscs",
    value: "Processing and preserving of fish, crustaceans and molluscs",
  },
  {
    label: "Processing and preserving of fruit and vegetables",
    value: "Processing and preserving of fruit and vegetables",
  },
  {
    label: "Processing and preserving of meat",
    value: "Processing and preserving of meat",
  },
  {
    label: "Public order and safety activities",
    value: "Public order and safety activities",
  },
  {
    label: "Publishing of directories and mailing lists",
    value: "Publishing of directories and mailing lists",
  },
  {
    label: "Publishing of newspapers, journals and periodicals",
    value: "Publishing of newspapers, journals and periodicals",
  },
  {
    label: "Quarrying of stone, sand and clay",
    value: "Quarrying of stone, sand and clay",
  },
  {
    label: "Radio broadcasting",
    value: "Radio broadcasting",
  },
  {
    label: "Raising of camels and camelids",
    value: "Raising of camels and camelids",
  },
  {
    label: "Raising of cattle and buffaloes",
    value: "Raising of cattle and buffaloes",
  },
  {
    label: "Raising of horses and other equines",
    value: "Raising of horses and other equines",
  },
  {
    label: "Raising of other animals",
    value: "Raising of other animals",
  },
  {
    label: "Raising of poultry",
    value: "Raising of poultry",
  },
  {
    label: "Raising of sheep and goats",
    value: "Raising of sheep and goats",
  },
  {
    label: "Raising of swine/pigs",
    value: "Raising of swine/pigs",
  },
  {
    label: "Real estate activities on a fee or contract basis",
    value: "Real estate activities on a fee or contract basis",
  },
  {
    label: "Real estate activities with own or leased property",
    value: "Real estate activities with own or leased property",
  },
  {
    label: "Regulation of and contribution to more efficient operation of businesses",
    value: "Regulation of and contribution to more efficient operation of businesses",
  },
  {
    label: "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security",
    value: "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security",
  },
  {
    label: "Reinsurance",
    value: "Reinsurance",
  },
  {
    label: "Remediation activities and other waste management services",
    value: "Remediation activities and other waste management services",
  },
  {
    label: "Renting and leasing of motor vehicles",
    value: "Renting and leasing of motor vehicles",
  },
  {
    label: "Renting and leasing of other machinery, equipment and tangible goods",
    value: "Renting and leasing of other machinery, equipment and tangible goods",
  },
  {
    label: "Renting and leasing of other personal and household goods",
    value: "Renting and leasing of other personal and household goods",
  },
  {
    label: "Renting and leasing of recreational and sports goods",
    value: "Renting and leasing of recreational and sports goods",
  },
  {
    label: "Renting of video tapes and disks",
    value: "Renting of video tapes and disks",
  },
  {
    label: "Repair of communication equipment",
    value: "Repair of communication equipment",
  },
  {
    label: "Repair of computers and peripheral equipment",
    value: "Repair of computers and peripheral equipment",
  },
  {
    label: "Repair of consumer electronics",
    value: "Repair of consumer electronics",
  },
  {
    label: "Repair of electrical equipment",
    value: "Repair of electrical equipment",
  },
  {
    label: "Repair of electronic and optical equipment",
    value: "Repair of electronic and optical equipment",
  },
  {
    label: "Repair of fabricated metal products",
    value: "Repair of fabricated metal products",
  },
  {
    label: "Repair of footwear and leather goods",
    value: "Repair of footwear and leather goods",
  },
  {
    label: "Repair of furniture and home furnishings",
    value: "Repair of furniture and home furnishings",
  },
  {
    label: "Repair of household appliances and home and garden equipment",
    value: "Repair of household appliances and home and garden equipment",
  },
  {
    label: "Repair of machinery",
    value: "Repair of machinery",
  },
  {
    label: "Repair of other equipment",
    value: "Repair of other equipment",
  },
  {
    label: "Repair of other personal and household goods",
    value: "Repair of other personal and household goods",
  },
  {
    label: "Repair of transport equipment, except motor vehicles",
    value: "Repair of transport equipment, except motor vehicles",
  },
  {
    label: "Reproduction of recorded media",
    value: "Reproduction of recorded media",
  },
  {
    label: "Research and experimental development on natural sciences and engineering",
    value: "Research and experimental development on natural sciences and engineering",
  },
  {
    label: "Research and experimental development on social sciences and humanities",
    value: "Research and experimental development on social sciences and humanities",
  },
  {
    label: "Residential care activities for mental retardation, mental health and substance abuse",
    value: "Residential care activities for mental retardation, mental health and substance abuse",
  },
  {
    label: "Residential care activities for the elderly and disabled",
    value: "Residential care activities for the elderly and disabled",
  },
  {
    label: "Residential nursing care facilities",
    value: "Residential nursing care facilities",
  },
  {
    label: "Restaurants and mobile food service activities",
    value: "Restaurants and mobile food service activities",
  },
  {
    label: "Retail sale in non-specialized stores with food, beverages or tobacco predominating",
    value: "Retail sale in non-specialized stores with food, beverages or tobacco predominating",
  },
  {
    label: "Retail sale of audio and video equipment in specialized stores",
    value: "Retail sale of audio and video equipment in specialized stores",
  },
  {
    label: "Retail sale of automotive fuel in specialized stores",
    value: "Retail sale of automotive fuel in specialized stores",
  },
  {
    label: "Retail sale of beverages in specialized stores",
    value: "Retail sale of beverages in specialized stores",
  },
  {
    label: "Retail sale of books, newspapers and stationary in specialized stores",
    value: "Retail sale of books, newspapers and stationary in specialized stores",
  },
  {
    label: "Retail sale of carpets, rugs, wall and floor coverings in specialized stores",
    value: "Retail sale of carpets, rugs, wall and floor coverings in specialized stores",
  },
  {
    label: "Retail sale of clothing, footwear and leather articles in specialized stores",
    value: "Retail sale of clothing, footwear and leather articles in specialized stores",
  },
  {
    label: "Retail sale of computers, peripheral units, software and telecommunications equipment in specialized stores",
    value: "Retail sale of computers, peripheral units, software and telecommunications equipment in specialized stores",
  },
  {
    label: "Retail sale of electrical household appliances, furniture, lighting equipment and other household articles in specialized stores",
    value: "Retail sale of electrical household appliances, furniture, lighting equipment and other household articles in specialized stores",
  },
  {
    label: "Retail sale of food in specialized stores",
    value: "Retail sale of food in specialized stores",
  },
  {
    label: "Retail sale of games and toys in specialized stores",
    value: "Retail sale of games and toys in specialized stores",
  },
  {
    label: "Retail sale of hardware, paints and glass in specialized stores",
    value: "Retail sale of hardware, paints and glass in specialized stores",
  },
  {
    label: "Retail sale of music and video recordings in specialized stores",
    value: "Retail sale of music and video recordings in specialized stores",
  },
  {
    label: "Retail sale of pharmaceutical and medical goods, cosmetic and toilet articles in specialized stores",
    value: "Retail sale of pharmaceutical and medical goods, cosmetic and toilet articles in specialized stores",
  },
  {
    label: "Retail sale of second-hand goods",
    value: "Retail sale of second-hand goods",
  },
  {
    label: "Retail sale of sporting equipment in specialized stores",
    value: "Retail sale of sporting equipment in specialized stores",
  },
  {
    label: "Retail sale of textiles in specialized stores",
    value: "Retail sale of textiles in specialized stores",
  },
  {
    label: "Retail sale of tobacco products in specialized stores",
    value: "Retail sale of tobacco products in specialized stores",
  },
  {
    label: "Retail sale via mail order houses or via Internet",
    value: "Retail sale via mail order houses or via Internet",
  },
  {
    label: "Retail sale via stalls and markets of food, beverages and tobacco products",
    value: "Retail sale via stalls and markets of food, beverages and tobacco products",
  },
  {
    label: "Retail sale via stalls and markets of other goods",
    value: "Retail sale via stalls and markets of other goods",
  },
  {
    label: "Retail sale via stalls and markets of textiles, clothing and footwear",
    value: "Retail sale via stalls and markets of textiles, clothing and footwear",
  },
  {
    label: "Risk and damage evaluation",
    value: "Risk and damage evaluation",
  },
  {
    label: "Sale of motor vehicle parts and accessories",
    value: "Sale of motor vehicle parts and accessories",
  },
  {
    label: "Sale of motor vehicles",
    value: "Sale of motor vehicles",
  },
  {
    label: "Sale, maintenance and repair of motorcycles and related parts and accessories",
    value: "Sale, maintenance and repair of motorcycles and related parts and accessories",
  },
  {
    label: "Satellite telecommunications activities",
    value: "Satellite telecommunications activities",
  },
  {
    label: "Sawmilling and planing of wood",
    value: "Sawmilling and planing of wood",
  },
  {
    label: "Sea and coastal freight water transport",
    value: "Sea and coastal freight water transport",
  },
  {
    label: "Sea and coastal passenger water transport",
    value: "Sea and coastal passenger water transport",
  },
  {
    label: "Security and commodity contracts brokerage",
    value: "Security and commodity contracts brokerage",
  },
  {
    label: "Security systems service activities",
    value: "Security systems service activities",
  },
  {
    label: "Seed processing for propagation",
    value: "Seed processing for propagation",
  },
  {
    label: "Service activities incidental to air transportation",
    value: "Service activities incidental to air transportation",
  },
  {
    label: "Service activities incidental to land transportation",
    value: "Service activities incidental to land transportation",
  },
  {
    label: "Service activities incidental to water transportation",
    value: "Service activities incidental to water transportation",
  },
  {
    label: "Service activities related to printing",
    value: "Service activities related to printing",
  },
  {
    label: "Sewerage",
    value: "Sewerage",
  },
  {
    label: "Short term accommodation activities",
    value: "Short term accommodation activities",
  },
  {
    label: "Silviculture and other forestry activities",
    value: "Silviculture and other forestry activities",
  },
  {
    label: "Site preparation",
    value: "Site preparation",
  },
  {
    label: "Social work activities without accommodation for the elderly and disabled",
    value: "Social work activities without accommodation for the elderly and disabled",
  },
  {
    label: "Software publishing",
    value: "Software publishing",
  },
  {
    label: "Sound recording and music publishing activities",
    value: "Sound recording and music publishing activities",
  },
  {
    label: "Specialized design activities",
    value: "Specialized design activities",
  },
  {
    label: "Sports and recreation education",
    value: "Sports and recreation education",
  },
  {
    label: "Steam and air conditioning supply",
    value: "Steam and air conditioning supply",
  },
  {
    label: "Support activities for animal production",
    value: "Support activities for animal production",
  },
  {
    label: "Support activities for crop production",
    value: "Support activities for crop production",
  },
  {
    label: "Support activities for other mining and quarrying",
    value: "Support activities for other mining and quarrying",
  },
  {
    label: "Support activities for petroleum and natural gas extraction",
    value: "Support activities for petroleum and natural gas extraction",
  },
  {
    label: "Support services to forestry",
    value: "Support services to forestry",
  },
  {
    label: "Tanning and dressing of leather; dressing and dyeing of fur",
    value: "Tanning and dressing of leather; dressing and dyeing of fur",
  },
  {
    label: "Technical and vocational secondary education",
    value: "Technical and vocational secondary education",
  },
  {
    label: "Technical testing and analysis",
    value: "Technical testing and analysis",
  },
  {
    label: "Television programming and broadcasting activities",
    value: "Television programming and broadcasting activities",
  },
  {
    label: "Temporary employment agency activities",
    value: "Temporary employment agency activities",
  },
  {
    label: "Tour operator activities",
    value: "Tour operator activities",
  },
  {
    label: "Transport via pipeline",
    value: "Transport via pipeline",
  },
  {
    label: "Travel agency activities",
    value: "Travel agency activities",
  },
  {
    label: "Treatment and coating of metals; machining",
    value: "Treatment and coating of metals; machining",
  },
  {
    label: "Treatment and disposal of hazardous waste",
    value: "Treatment and disposal of hazardous waste",
  },
  {
    label: "Treatment and disposal of non-hazardous waste",
    value: "Treatment and disposal of non-hazardous waste",
  },
  {
    label: "Trusts, funds and similar financial entities",
    value: "Trusts, funds and similar financial entities",
  },
  {
    label: "Undifferentiated goods-producing activities of private households for own use",
    value: "Undifferentiated goods-producing activities of private households for own use",
  },
  {
    label: "Undifferentiated service-producing activities of private households for own use",
    value: "Undifferentiated service-producing activities of private households for own use",
  },
  {
    label: "Urban and suburban passenger land transport",
    value: "Urban and suburban passenger land transport",
  },
  {
    label: "Veterinary activities",
    value: "Veterinary activities",
  },
  {
    label: "Warehousing and storage",
    value: "Warehousing and storage",
  },
  {
    label: "Washing and (dry-) cleaning of textile and fur products",
    value: "Washing and (dry-) cleaning of textile and fur products",
  },
  {
    label: "Water collection, treatment and supply",
    value: "Water collection, treatment and supply",
  },
  {
    label: "Weaving of textiles",
    value: "Weaving of textiles",
  },
  {
    label: "Web portals",
    value: "Web portals",
  },
  {
    label: "Wholesale of agricultural machinery, equipment and supplies",
    value: "Wholesale of agricultural machinery, equipment and supplies",
  },
  {
    label: "Wholesale of agricultural raw materials and live animals",
    value: "Wholesale of agricultural raw materials and live animals",
  },
  {
    label: "Wholesale of computers, computer peripheral equipment and software",
    value: "Wholesale of computers, computer peripheral equipment and software",
  },
  {
    label: "Wholesale of construction materials, hardware, plumbing and heating equipment and supplies",
    value: "Wholesale of construction materials, hardware, plumbing and heating equipment and supplies",
  },
  {
    label: "Wholesale of electronic and telecommunications equipment and parts",
    value: "Wholesale of electronic and telecommunications equipment and parts",
  },
  {
    label: "Wholesale of food, beverages and tobacco",
    value: "Wholesale of food, beverages and tobacco",
  },
  {
    label: "Wholesale of metals and metal ores",
    value: "Wholesale of metals and metal ores",
  },
  {
    label: "Wholesale of other household goods",
    value: "Wholesale of other household goods",
  },
  {
    label: "Wholesale of other machinery and equipment",
    value: "Wholesale of other machinery and equipment",
  },
  {
    label: "Wholesale of solid, liquid and gaseous fuels and related products",
    value: "Wholesale of solid, liquid and gaseous fuels and related products",
  },
  {
    label: "Wholesale of textiles, clothing and footwear",
    value: "Wholesale of textiles, clothing and footwear",
  },
  {
    label: "Wholesale of waste and scrap and other products n.e.c.",
    value: "Wholesale of waste and scrap and other products n.e.c.",
  },
  {
    label: "Wholesale on a fee or contract basis",
    value: "Wholesale on a fee or contract basis",
  },
  {
    label: "Wired telecommunications activities",
    value: "Wired telecommunications activities",
  },
  {
    label: "Wireless telecommunications activities",
    value: "Wireless telecommunications activities",
  },
  {
    label: "Aerospace Equipment and Systems",
    value: "Aerospace Equipment and Systems",
  },
  {
    label: "Agricultural Materials and Services",
    value: "Agricultural Materials and Services",
  },
  {
    label: "Apparel",
    value: "Apparel",
  },
  {
    label: "Architecture and Engineering Services",
    value: "Architecture and Engineering Services",
  },
  {
    label: "Banking and Investment Management",
    value: "Banking and Investment Management",
  },
  {
    label: "Building Products and Materials",
    value: "Building Products and Materials",
  },
  {
    label: "Buildings and Structures",
    value: "Buildings and Structures",
  },
  {
    label: "Business Process Outsourcing",
    value: "Business Process Outsourcing",
  },
  {
    label: "Charity",
    value: "Charity",
  },
  {
    label: "Chemicals and Biologicals",
    value: "Chemicals and Biologicals",
  },
  {
    label: "Construction Services and Equipment",
    value: "Construction Services and Equipment",
  },
  {
    label: "Consumer Electronics and Appliances",
    value: "Consumer Electronics and Appliances",
  },
  {
    label: "Contingent Workforce and Staffing",
    value: "Contingent Workforce and Staffing",
  },
  {
    label: "Corporate Expenditures",
    value: "Corporate Expenditures",
  },
  {
    label: "Employment Benefits",
    value: "Employment Benefits",
  },
  {
    label: "Event Management",
    value: "Event Management",
  },
  {
    label: "Facility Maintenance and Equipment",
    value: "Facility Maintenance and Equipment",
  },
  {
    label: "Food and Beverage",
    value: "Food and Beverage",
  },
  {
    label: "Furniture",
    value: "Furniture",
  },
  {
    label: "Healthcare Services",
    value: "Healthcare Services",
  },
  {
    label: "Hospitality and Entertainment",
    value: "Hospitality and Entertainment",
  },
  {
    label: "Human Resources Services",
    value: "Human Resources Services",
  },
  {
    label: "Industrial Equipment and Systems",
    value: "Industrial Equipment and Systems",
  },
  {
    label: "Insurance",
    value: "Insurance",
  },
  {
    label: "IT Hardware and Peripherals",
    value: "IT Hardware and Peripherals",
  },
  {
    label: "IT Services",
    value: "IT Services",
  },
  {
    label: "IT Software and Applications",
    value: "IT Software and Applications",
  },
  {
    label: "Janitorial Services and Supplies",
    value: "Janitorial Services and Supplies",
  },
  {
    label: "Lab Supplies and Equipment",
    value: "Lab Supplies and Equipment",
  },
  {
    label: "Manufacturing and Industrial Processing",
    value: "Manufacturing and Industrial Processing",
  },
  {
    label: "Marketing Services and Supplies",
    value: "Marketing Services and Supplies",
  },
  {
    label: "Media Production and Content",
    value: "Media Production and Content",
  },
  {
    label: "Medical Equipment and Supplies",
    value: "Medical Equipment and Supplies",
  },
  {
    label: "Membership Fees and Subscriptions",
    value: "Membership Fees and Subscriptions",
  },
  {
    label: "MRO Equipment and Supplies",
    value: "MRO Equipment and Supplies",
  },
  {
    label: "Office Supplies and Equipment",
    value: "Office Supplies and Equipment",
  },
  {
    label: "Oil and Gas/Mining Equipment and Services",
    value: "Oil and Gas/Mining Equipment and Services",
  },
  {
    label: "Packaging",
    value: "Packaging",
  },
  {
    label: "Personal Health and Care",
    value: "Personal Health and Care",
  },
  {
    label: "Pharmaceuticals",
    value: "Pharmaceuticals",
  },
  {
    label: "Power Generation and Distribution",
    value: "Power Generation and Distribution",
  },
  {
    label: "Print and Promotional Materials",
    value: "Print and Promotional Materials",
  },
  {
    label: "Professional Services",
    value: "Professional Services",
  },
  {
    label: "Real Estate and Property Management",
    value: "Real Estate and Property Management",
  },
  {
    label: "Recreational Activities and Supplies",
    value: "Recreational Activities and Supplies",
  },
  {
    label: "Research and Information",
    value: "Research and Information",
  },
  {
    label: "Retail Products and Services",
    value: "Retail Products and Services",
  },
  {
    label: "Safety Equipment and Services",
    value: "Safety Equipment and Services",
  },
  {
    label: "Shipping and Freight",
    value: "Shipping and Freight",
  },
  {
    label: "Taxes and Regulatory Fees",
    value: "Taxes and Regulatory Fees",
  },
  {
    label: "Telecommunications",
    value: "Telecommunications",
  },
  {
    label: "Transportation",
    value: "Transportation",
  },
  {
    label: "Travel and Expenses",
    value: "Travel and Expenses",
  },
  {
    label: "Utilities",
    value: "Utilities",
  },
  {
    label: "Vehicles Systems and Components",
    value: "Vehicles Systems and Components",
  },
  {
    label: "Warehousing and Distribution",
    value: "Warehousing and Distribution",
  },
  {
    label: "Other Category",
    value: "Other Category",
  },
  {
    label: "Other Goods",
    value: "Other Goods",
  },
  {
    label: "Other Services",
    value: "Other Services",
  },
  {
    label: "Live animals",
    value: "Live animals",
  },
  {
    label: "Domestic pet products",
    value: "Domestic pet products",
  },
  {
    label: "Animal feed",
    value: "Animal feed",
  },
  {
    label: "Animal containment and habitats",
    value: "Animal containment and habitats",
  },
  {
    label: "Saddlery and harness goods",
    value: "Saddlery and harness goods",
  },
  {
    label: "Seeds and bulbs and seedlings and cuttings",
    value: "Seeds and bulbs and seedlings and cuttings",
  },
  {
    label: "Floriculture and silviculture products",
    value: "Floriculture and silviculture products",
  },
  {
    label: "Fertilizers and plant nutrients and herbicides",
    value: "Fertilizers and plant nutrients and herbicides",
  },
  {
    label: "Pest control products",
    value: "Pest control products",
  },
  {
    label: "Live rose bushes",
    value: "Live rose bushes",
  },
  {
    label: "Live plants of high species or variety count flowers",
    value: "Live plants of high species or variety count flowers",
  },
  {
    label: "Live plants of low species or variety count flowers",
    value: "Live plants of low species or variety count flowers",
  },
  {
    label: "Live chrysanthemums",
    value: "Live chrysanthemums",
  },
  {
    label: "Live carnations",
    value: "Live carnations",
  },
  {
    label: "Live orchids",
    value: "Live orchids",
  },
  {
    label: "Fresh cut rose",
    value: "Fresh cut rose",
  },
  {
    label: "Fresh cut blooms of high species or variety count flowers",
    value: "Fresh cut blooms of high species or variety count flowers",
  },
  {
    label: "Fresh cut blooms of low species or variety count flowers",
    value: "Fresh cut blooms of low species or variety count flowers",
  },
  {
    label: "Fresh cut chrysanthemums",
    value: "Fresh cut chrysanthemums",
  },
  {
    label: "Fresh cut floral bouquets",
    value: "Fresh cut floral bouquets",
  },
  {
    label: "Fresh cut carnations",
    value: "Fresh cut carnations",
  },
  {
    label: "Fresh cut orchids",
    value: "Fresh cut orchids",
  },
  {
    label: "Dried cut roses",
    value: "Dried cut roses",
  },
  {
    label: "Dried cut blooms of high species or variety count flowers",
    value: "Dried cut blooms of high species or variety count flowers",
  },
  {
    label: "Dried cut blooms of low species or variety count flowers",
    value: "Dried cut blooms of low species or variety count flowers",
  },
  {
    label: "Dried cut chrysanthemums",
    value: "Dried cut chrysanthemums",
  },
  {
    label: "Dried cut carnations",
    value: "Dried cut carnations",
  },
  {
    label: "Dried cut orchids",
    value: "Dried cut orchids",
  },
  {
    label: "Fresh cut greenery",
    value: "Fresh cut greenery",
  },
  {
    label: "Minerals and ores and metals",
    value: "Minerals and ores and metals",
  },
  {
    label: "Earth and stone",
    value: "Earth and stone",
  },
  {
    label: "Non edible plant and forestry products",
    value: "Non edible plant and forestry products",
  },
  {
    label: "Non edible animal products",
    value: "Non edible animal products",
  },
  {
    label: "Scrap and waste materials",
    value: "Scrap and waste materials",
  },
  {
    label: "Fibers and threads and yarns",
    value: "Fibers and threads and yarns",
  },
  {
    label: "Fabrics and leather materials",
    value: "Fabrics and leather materials",
  },
  {
    label: "Alloys",
    value: "Alloys",
  },
  {
    label: "Metal oxide",
    value: "Metal oxide",
  },
  {
    label: "Metal waste scrap and by products",
    value: "Metal waste scrap and by products",
  },
  {
    label: "Explosive materials",
    value: "Explosive materials",
  },
  {
    label: "Elements and gases",
    value: "Elements and gases",
  },
  {
    label: "Additives",
    value: "Additives",
  },
  {
    label: "Colorants",
    value: "Colorants",
  },
  {
    label: "Waxes and oils",
    value: "Waxes and oils",
  },
  {
    label: "Solvents",
    value: "Solvents",
  },
  {
    label: "Compounds and mixtures",
    value: "Compounds and mixtures",
  },
  {
    label: "Pharmaceutical drug precursors",
    value: "Pharmaceutical drug precursors",
  },
  {
    label: "Rubber and elastomers",
    value: "Rubber and elastomers",
  },
  {
    label: "Resins and rosins and other resin derived materials",
    value: "Resins and rosins and other resin derived materials",
  },
  {
    label: "Paper materials",
    value: "Paper materials",
  },
  {
    label: "Paper products",
    value: "Paper products",
  },
  {
    label: "Industrial use papers",
    value: "Industrial use papers",
  },
  {
    label: "Fuels",
    value: "Fuels",
  },
  {
    label: "Gaseous fuels and additives",
    value: "Gaseous fuels and additives",
  },
  {
    label: "Lubricants and oils and greases and anti corrosives",
    value: "Lubricants and oils and greases and anti corrosives",
  },
  {
    label: "Fuel for nuclear reactors",
    value: "Fuel for nuclear reactors",
  },
  {
    label: "Mining and quarrying machinery and equipment",
    value: "Mining and quarrying machinery and equipment",
  },
  {
    label: "Well drilling and operation equipment",
    value: "Well drilling and operation equipment",
  },
  {
    label: "Oil and gas drilling and exploration equipment",
    value: "Oil and gas drilling and exploration equipment",
  },
  {
    label: "Oil and gas drilling and operation materials",
    value: "Oil and gas drilling and operation materials",
  },
  {
    label: "Oil and gas operating and production equipment",
    value: "Oil and gas operating and production equipment",
  },
  {
    label: "Agricultural and forestry and landscape machinery and equipment",
    value: "Agricultural and forestry and landscape machinery and equipment",
  },
  {
    label: "Fishing and aquaculture equipment",
    value: "Fishing and aquaculture equipment",
  },
  {
    label: "Heavy construction machinery and equipment",
    value: "Heavy construction machinery and equipment",
  },
  {
    label: "Raw materials processing machinery",
    value: "Raw materials processing machinery",
  },
  {
    label: "Petroleum processing machinery",
    value: "Petroleum processing machinery",
  },
  {
    label: "Textile and fabric machinery and accessories",
    value: "Textile and fabric machinery and accessories",
  },
  {
    label: "Lapidary machinery and equipment",
    value: "Lapidary machinery and equipment",
  },
  {
    label: "Leatherworking repairing machinery and equipment",
    value: "Leatherworking repairing machinery and equipment",
  },
  {
    label: "Industrial process machinery and equipment and supplies",
    value: "Industrial process machinery and equipment and supplies",
  },
  {
    label: "Foundry machines and equipment and supplies",
    value: "Foundry machines and equipment and supplies",
  },
  {
    label: "Industrial food and beverage equipment",
    value: "Industrial food and beverage equipment",
  },
  {
    label: "Mixers and their parts and accessories",
    value: "Mixers and their parts and accessories",
  },
  {
    label: "Mass transfer equipment",
    value: "Mass transfer equipment",
  },
  {
    label: "Electronic manufacturing machinery and equipment and accessories",
    value: "Electronic manufacturing machinery and equipment and accessories",
  },
  {
    label: "Chicken processing machinery and equipment",
    value: "Chicken processing machinery and equipment",
  },
  {
    label: "Sawmilling and lumber processing machinery and equipment",
    value: "Sawmilling and lumber processing machinery and equipment",
  },
  {
    label: "Metal cutting machinery and accessories",
    value: "Metal cutting machinery and accessories",
  },
  {
    label: "Metal forming machinery and accessories",
    value: "Metal forming machinery and accessories",
  },
  {
    label: "Rapid prototyping machinery and accessories",
    value: "Rapid prototyping machinery and accessories",
  },
  {
    label: "Welding and soldering and brazing machinery and accessories and supplies",
    value: "Welding and soldering and brazing machinery and accessories and supplies",
  },
  {
    label: "Metal treatment machinery",
    value: "Metal treatment machinery",
  },
  {
    label: "Industrial machine tools",
    value: "Industrial machine tools",
  },
  {
    label: "Wire machinery and equipment",
    value: "Wire machinery and equipment",
  },
  {
    label: "Material handling machinery and equipment",
    value: "Material handling machinery and equipment",
  },
  {
    label: "Containers and storage",
    value: "Containers and storage",
  },
  {
    label: "Packaging materials",
    value: "Packaging materials",
  },
  {
    label: "Industrial refrigeration",
    value: "Industrial refrigeration",
  },
  {
    label: "Packing supplies",
    value: "Packing supplies",
  },
  {
    label: "Motor vehicles",
    value: "Motor vehicles",
  },
  {
    label: "Marine transport",
    value: "Marine transport",
  },
  {
    label: "Railway and tramway machinery and equipment",
    value: "Railway and tramway machinery and equipment",
  },
  {
    label: "Aircraft",
    value: "Aircraft",
  },
  {
    label: "Spacecraft",
    value: "Spacecraft",
  },
  {
    label: "Non motorized cycles",
    value: "Non motorized cycles",
  },
  {
    label: "Transportation components and systems",
    value: "Transportation components and systems",
  },
  {
    label: "Vehicle bodies and trailers",
    value: "Vehicle bodies and trailers",
  },
  {
    label: "Transportation services equipment",
    value: "Transportation services equipment",
  },
  {
    label: "Aerospace systems and components and equipment",
    value: "Aerospace systems and components and equipment",
  },
  {
    label: "Power sources",
    value: "Power sources",
  },
  {
    label: "Batteries and generators and kinetic power transmission",
    value: "Batteries and generators and kinetic power transmission",
  },
  {
    label: "Electrical wire and cable and harness",
    value: "Electrical wire and cable and harness",
  },
  {
    label: "Power generation",
    value: "Power generation",
  },
  {
    label: "Atomic and nuclear energy machinery and equipment",
    value: "Atomic and nuclear energy machinery and equipment",
  },
  {
    label: "Hand tools",
    value: "Hand tools",
  },
  {
    label: "Hydraulic machinery and equipment",
    value: "Hydraulic machinery and equipment",
  },
  {
    label: "Pneumatic machinery and equipment",
    value: "Pneumatic machinery and equipment",
  },
  {
    label: "Automotive specialty tools",
    value: "Automotive specialty tools",
  },
  {
    label: "Part cleaning machinery and accessories",
    value: "Part cleaning machinery and accessories",
  },
  {
    label: "Structural components and basic shapes",
    value: "Structural components and basic shapes",
  },
  {
    label: "Concrete and cement and plaster",
    value: "Concrete and cement and plaster",
  },
  {
    label: "Roads and landscape",
    value: "Roads and landscape",
  },
  {
    label: "Structural building products",
    value: "Structural building products",
  },
  {
    label: "Insulation",
    value: "Insulation",
  },
  {
    label: "Exterior finishing materials",
    value: "Exterior finishing materials",
  },
  {
    label: "Interior finishing materials",
    value: "Interior finishing materials",
  },
  {
    label: "Doors and windows and glass",
    value: "Doors and windows and glass",
  },
  {
    label: "Plumbing fixtures",
    value: "Plumbing fixtures",
  },
  {
    label: "Construction and maintenance support equipment",
    value: "Construction and maintenance support equipment",
  },
  {
    label: "Portable Structure Building Components",
    value: "Portable Structure Building Components",
  },
  {
    label: "Underground mining structures and materials",
    value: "Underground mining structures and materials",
  },
  {
    label: "Structural materials",
    value: "Structural materials",
  },
  {
    label: "Castings and casting assemblies",
    value: "Castings and casting assemblies",
  },
  {
    label: "Extrusions",
    value: "Extrusions",
  },
  {
    label: "Machined castings",
    value: "Machined castings",
  },
  {
    label: "Forgings",
    value: "Forgings",
  },
  {
    label: "Moldings",
    value: "Moldings",
  },
  {
    label: "Rope and chain and cable and wire and strap",
    value: "Rope and chain and cable and wire and strap",
  },
  {
    label: "Hardware",
    value: "Hardware",
  },
  {
    label: "Bearings and bushings and wheels and gears",
    value: "Bearings and bushings and wheels and gears",
  },
  {
    label: "Packings glands boots and covers",
    value: "Packings glands boots and covers",
  },
  {
    label: "Grinding and polishing and smoothing materials",
    value: "Grinding and polishing and smoothing materials",
  },
  {
    label: "Adhesives and sealants",
    value: "Adhesives and sealants",
  },
  {
    label: "Paints and primers and finishes",
    value: "Paints and primers and finishes",
  },
  {
    label: "Dyeing and tanning extracts",
    value: "Dyeing and tanning extracts",
  },
  {
    label: "Machined raw stock",
    value: "Machined raw stock",
  },
  {
    label: "Industrial optics",
    value: "Industrial optics",
  },
  {
    label: "Pneumatic and hydraulic and electric control systems",
    value: "Pneumatic and hydraulic and electric control systems",
  },
  {
    label: "Housings and cabinets and casings",
    value: "Housings and cabinets and casings",
  },
  {
    label: "Machine made parts",
    value: "Machine made parts",
  },
  {
    label: "Stampings and sheet components",
    value: "Stampings and sheet components",
  },
  {
    label: "Machined extrusions",
    value: "Machined extrusions",
  },
  {
    label: "Machined forgings",
    value: "Machined forgings",
  },
  {
    label: "Fabricated pipe assemblies",
    value: "Fabricated pipe assemblies",
  },
  {
    label: "Fabricated bar stock assemblies",
    value: "Fabricated bar stock assemblies",
  },
  {
    label: "Fabricated structural assemblies",
    value: "Fabricated structural assemblies",
  },
  {
    label: "Fabricated sheet assemblies",
    value: "Fabricated sheet assemblies",
  },
  {
    label: "Fabricated tube assemblies",
    value: "Fabricated tube assemblies",
  },
  {
    label: "Fabricated plate assemblies",
    value: "Fabricated plate assemblies",
  },
  {
    label: "Refractories",
    value: "Refractories",
  },
  {
    label: "Magnets and magnetic materials",
    value: "Magnets and magnetic materials",
  },
  {
    label: "Machinings",
    value: "Machinings",
  },
  {
    label: "Gaskets",
    value: "Gaskets",
  },
  {
    label: "Seals",
    value: "Seals",
  },
  {
    label: "Sintered parts",
    value: "Sintered parts",
  },
  {
    label: "Printed circuits and integrated circuits and microassemblies",
    value: "Printed circuits and integrated circuits and microassemblies",
  },
  {
    label: "Discrete semiconductor devices",
    value: "Discrete semiconductor devices",
  },
  {
    label: "Passive discrete components",
    value: "Passive discrete components",
  },
  {
    label: "Electronic hardware and component parts and accessories",
    value: "Electronic hardware and component parts and accessories",
  },
  {
    label: "Electron tube devices and accessories",
    value: "Electron tube devices and accessories",
  },
  {
    label: "Automation control devices and components and accessories",
    value: "Automation control devices and components and accessories",
  },
  {
    label: "Lamps and lightbulbs and lamp components",
    value: "Lamps and lightbulbs and lamp components",
  },
  {
    label: "Lighting Fixtures and Accessories",
    value: "Lighting Fixtures and Accessories",
  },
  {
    label: "Electrical equipment and components and supplies",
    value: "Electrical equipment and components and supplies",
  },
  {
    label: "Electrical wire management devices and accessories and supplies",
    value: "Electrical wire management devices and accessories and supplies",
  },
  {
    label: "Heating and ventilation and air circulation",
    value: "Heating and ventilation and air circulation",
  },
  {
    label: "Fluid and gas distribution",
    value: "Fluid and gas distribution",
  },
  {
    label: "Industrial pumps and compressors",
    value: "Industrial pumps and compressors",
  },
  {
    label: "Industrial filtering and purification",
    value: "Industrial filtering and purification",
  },
  {
    label: "Pipe piping and pipe fittings",
    value: "Pipe piping and pipe fittings",
  },
  {
    label: "Tubes tubing and tube fittings",
    value: "Tubes tubing and tube fittings",
  },
  {
    label: "Laboratory and scientific equipment",
    value: "Laboratory and scientific equipment",
  },
  {
    label: "Measuring and observing and testing instruments",
    value: "Measuring and observing and testing instruments",
  },
  {
    label: "Measuring and observing and testing instruments",
    value: "Measuring and observing and testing instruments",
  },
  {
    label: "Veterinary equipment and supplies",
    value: "Veterinary equipment and supplies",
  },
  {
    label: "Medical apparel and textiles",
    value: "Medical apparel and textiles",
  },
  {
    label: "Patient care and treatment products and supplies",
    value: "Patient care and treatment products and supplies",
  },
  {
    label: "Dental equipment and supplies",
    value: "Dental equipment and supplies",
  },
  {
    label: "Dialysis equipment and supplies",
    value: "Dialysis equipment and supplies",
  },
  {
    label: "Emergency and field medical services products",
    value: "Emergency and field medical services products",
  },
  {
    label: "Patient exam and monitoring products",
    value: "Patient exam and monitoring products",
  },
  {
    label: "Medical facility products",
    value: "Medical facility products",
  },
  {
    label: "Medical diagnostic imaging and nuclear medicine products",
    value: "Medical diagnostic imaging and nuclear medicine products",
  },
  {
    label: "Independent living aids for the physically challenged",
    value: "Independent living aids for the physically challenged",
  },
  {
    label: "Intravenous and arterial administration products",
    value: "Intravenous and arterial administration products",
  },
  {
    label: "Clinical nutrition",
    value: "Clinical nutrition",
  },
  {
    label: "Orthopedic and prosthetic and sports medicine products",
    value: "Orthopedic and prosthetic and sports medicine products",
  },
  {
    label: "Physical and occupational therapy and rehabilitation products",
    value: "Physical and occupational therapy and rehabilitation products",
  },
  {
    label: "Orthopedic and prosthetic and sports medicine products",
    value: "Orthopedic and prosthetic and sports medicine products",
  },
  {
    label: "Physical and occupational therapy and rehabilitation products",
    value: "Physical and occupational therapy and rehabilitation products",
  },
  {
    label: "Medical sterilization products",
    value: "Medical sterilization products",
  },
  {
    label: "Surgical products",
    value: "Surgical products",
  },
  {
    label: "Medical training and education supplies",
    value: "Medical training and education supplies",
  },
  {
    label: "Wound care products",
    value: "Wound care products",
  },
  {
    label: "Orthopedic surgical implants",
    value: "Orthopedic surgical implants",
  },
  {
    label: "Communications Devices and Accessories",
    value: "Communications Devices and Accessories",
  },
  {
    label: "Components for information technology or broadcasting or telecommunications",
    value: "Components for information technology or broadcasting or telecommunications",
  },
  {
    label: "Computer Equipment and Accessories",
    value: "Computer Equipment and Accessories",
  },
  {
    label: "Data Voice or Multimedia Network Equipment or Platforms and Accessories",
    value: "Data Voice or Multimedia Network Equipment or Platforms and Accessories",
  },
  {
    label: "Software",
    value: "Software",
  },
  {
    label: "Office machines and their supplies and accessories",
    value: "Office machines and their supplies and accessories",
  },
  {
    label: "Office and desk accessories",
    value: "Office and desk accessories",
  },
  {
    label: "Office supplies",
    value: "Office supplies",
  },
  {
    label: "Printing and publishing equipment",
    value: "Printing and publishing equipment",
  },
  {
    label: "Audio and visual presentation and composing equipment",
    value: "Audio and visual presentation and composing equipment",
  },
  {
    label: "Photographic or filming or video equipment",
    value: "Photographic or filming or video equipment",
  },
  {
    label: "Photographic and recording media",
    value: "Photographic and recording media",
  },
  {
    label: "Photographic filmmaking supplies",
    value: "Photographic filmmaking supplies",
  },
  {
    label: "Light weapons and ammunition",
    value: "Light weapons and ammunition",
  },
  {
    label: "Conventional war weapons",
    value: "Conventional war weapons",
  },
  {
    label: "Missiles",
    value: "Missiles",
  },
  {
    label: "Rockets and subsystems",
    value: "Rockets and subsystems",
  },
  {
    label: "Launchers",
    value: "Launchers",
  },
  {
    label: "Law enforcement",
    value: "Law enforcement",
  },
  {
    label: "Public safety and control",
    value: "Public safety and control",
  },
  {
    label: "Security surveillance and detection",
    value: "Security surveillance and detection",
  },
  {
    label: "Personal safety and protection",
    value: "Personal safety and protection",
  },
  {
    label: "Fire protection",
    value: "Fire protection",
  },
  {
    label: "Defense and law enforcement and security and safety training equipment",
    value: "Defense and law enforcement and security and safety training equipment",
  },
  {
    label: "Workplace safety equipment and supplies and training materials",
    value: "Workplace safety equipment and supplies and training materials",
  },
  {
    label: "Military weapon and ammunition disarmament and disposal equipment and related products",
    value: "Military weapon and ammunition disarmament and disposal equipment and related products",
  },
  {
    label: "Water and wastewater treatment supply and disposal",
    value: "Water and wastewater treatment supply and disposal",
  },
  {
    label: "Industrial laundry and dry cleaning equipment",
    value: "Industrial laundry and dry cleaning equipment",
  },
  {
    label: "Janitorial equipment",
    value: "Janitorial equipment",
  },
  {
    label: "Cleaning and janitorial supplies",
    value: "Cleaning and janitorial supplies",
  },
  {
    label: "Toxic and hazardous waste cleanup products",
    value: "Toxic and hazardous waste cleanup products",
  },
  {
    label: "Institutional food services equipment",
    value: "Institutional food services equipment",
  },
  {
    label: "Vending machines",
    value: "Vending machines",
  },
  {
    label: "Gambling or wagering equipment",
    value: "Gambling or wagering equipment",
  },
  {
    label: "Funeral equipment and materials",
    value: "Funeral equipment and materials",
  },
  {
    label: "Collectibles and awards",
    value: "Collectibles and awards",
  },
  {
    label: "Camping and outdoor equipment and accessories",
    value: "Camping and outdoor equipment and accessories",
  },
  {
    label: "Fishing and hunting equipment",
    value: "Fishing and hunting equipment",
  },
  {
    label: "Watersports equipment",
    value: "Watersports equipment",
  },
  {
    label: "Winter sports equipment",
    value: "Winter sports equipment",
  },
  {
    label: "Field and court sports equipment",
    value: "Field and court sports equipment",
  },
  {
    label: "Gymnastics and boxing equipment",
    value: "Gymnastics and boxing equipment",
  },
  {
    label: "Target and table games and equipment",
    value: "Target and table games and equipment",
  },
  {
    label: "Fitness equipment",
    value: "Fitness equipment",
  },
  {
    label: "Other sports",
    value: "Other sports",
  },
  {
    label: "Sports equipment and accessories",
    value: "Sports equipment and accessories",
  },
  {
    label: "Recreation and playground and swimming and spa equipment and supplies",
    value: "Recreation and playground and swimming and spa equipment and supplies",
  },
  {
    label: "Nuts and seeds",
    value: "Nuts and seeds",
  },
  {
    label: "Meat and poultry products",
    value: "Meat and poultry products",
  },
  {
    label: "Seafood",
    value: "Seafood",
  },
  {
    label: "Dairy products and eggs",
    value: "Dairy products and eggs",
  },
  {
    label: "Edible oils and fats",
    value: "Edible oils and fats",
  },
  {
    label: "Chocolate and sugars and sweeteners and confectionary products",
    value: "Chocolate and sugars and sweeteners and confectionary products",
  },
  {
    label: "Seasonings and preservatives",
    value: "Seasonings and preservatives",
  },
  {
    label: "Bread and bakery products",
    value: "Bread and bakery products",
  },
  {
    label: "Prepared and preserved foods",
    value: "Prepared and preserved foods",
  },
  {
    label: "Beverages",
    value: "Beverages",
  },
  {
    label: "Tobacco and smoking products and substitutes",
    value: "Tobacco and smoking products and substitutes",
  },
  {
    label: "Cereal and pulse products",
    value: "Cereal and pulse products",
  },
  {
    label: "Fresh fruits",
    value: "Fresh fruits",
  },
  {
    label: "Organic fresh fruits",
    value: "Organic fresh fruits",
  },
  {
    label: "Dried fruit",
    value: "Dried fruit",
  },
  {
    label: "Dried organic fruit",
    value: "Dried organic fruit",
  },
  {
    label: "Frozen fruit",
    value: "Frozen fruit",
  },
  {
    label: "Frozen organic fruit",
    value: "Frozen organic fruit",
  },
  {
    label: "Canned or jarred fruit",
    value: "Canned or jarred fruit",
  },
  {
    label: "Canned or jarred organic fruit",
    value: "Canned or jarred organic fruit",
  },
  {
    label: "Fresh fruit purees",
    value: "Fresh fruit purees",
  },
  {
    label: "Organic fresh fruit purees",
    value: "Organic fresh fruit purees",
  },
  {
    label: "Fresh vegetables",
    value: "Fresh vegetables",
  },
  {
    label: "Organic fresh vegetables",
    value: "Organic fresh vegetables",
  },
  {
    label: "Dried vegetables",
    value: "Dried vegetables",
  },
  {
    label: "Dried organic vegetables",
    value: "Dried organic vegetables",
  },
  {
    label: "Frozen vegetables",
    value: "Frozen vegetables",
  },
  {
    label: "Frozen organic vegetables",
    value: "Frozen organic vegetables",
  },
  {
    label: "Canned or jarred vegetables",
    value: "Canned or jarred vegetables",
  },
  {
    label: "Canned or jarred organic vegetables",
    value: "Canned or jarred organic vegetables",
  },
  {
    label: "Fresh vegetable purees",
    value: "Fresh vegetable purees",
  },
  {
    label: "Organic fresh vegetable purees",
    value: "Organic fresh vegetable purees",
  },
  {
    label: "Nutritional supplements",
    value: "Nutritional supplements",
  },
  {
    label: "Non GMO fresh fruits",
    value: "Non GMO fresh fruits",
  },
  {
    label: "Dried non GMO fruits",
    value: "Dried non GMO fruits",
  },
  {
    label: "Frozen non GMO fruits",
    value: "Frozen non GMO fruits",
  },
  {
    label: "Canned or jarred non GMO fruits",
    value: "Canned or jarred non GMO fruits",
  },
  {
    label: "Non GMO fresh fruit purees",
    value: "Non GMO fresh fruit purees",
  },
  {
    label: "Non GMO fresh vegetables",
    value: "Non GMO fresh vegetables",
  },
  {
    label: "Dried Non GMO vegetables",
    value: "Dried Non GMO vegetables",
  },
  {
    label: "Frozen Non GMO vegetables",
    value: "Frozen Non GMO vegetables",
  },
  {
    label: "Canned or jarred Non GMO vegetables",
    value: "Canned or jarred Non GMO vegetables",
  },
  {
    label: "Non GMO fresh vegetable purees",
    value: "Non GMO fresh vegetable purees",
  },
  {
    label: "Amebicides and trichomonacides and antiprotozoals",
    value: "Amebicides and trichomonacides and antiprotozoals",
  },
  {
    label: "Antineoplastic agents",
    value: "Antineoplastic agents",
  },
  {
    label: "Antiarrythmics and antianginals and cardioplegics and drugs for heart failure",
    value: "Antiarrythmics and antianginals and cardioplegics and drugs for heart failure",
  },
  {
    label: "Hematolic drugs",
    value: "Hematolic drugs",
  },
  {
    label: "Central nervous system drugs",
    value: "Central nervous system drugs",
  },
  {
    label: "Autonomic nervous system drugs",
    value: "Autonomic nervous system drugs",
  },
  {
    label: "Drugs affecting the respiratory tract",
    value: "Drugs affecting the respiratory tract",
  },
  {
    label: "Drugs affecting the gastrointestinal system",
    value: "Drugs affecting the gastrointestinal system",
  },
  {
    label: "Hormones and hormone antagonists",
    value: "Hormones and hormone antagonists",
  },
  {
    label: "Agents affecting water and electrolytes",
    value: "Agents affecting water and electrolytes",
  },
  {
    label: "Immunomodulating drugs",
    value: "Immunomodulating drugs",
  },
  {
    label: "Drugs affecting the ears, eye, nose and skin",
    value: "Drugs affecting the ears, eye, nose and skin",
  },
  {
    label: "Adrenergic blocking agents",
    value: "Adrenergic blocking agents",
  },
  {
    label: "Anaesthetic drugs and related adjuncts and analeptics",
    value: "Anaesthetic drugs and related adjuncts and analeptics",
  },
  {
    label: "Antibacterials",
    value: "Antibacterials",
  },
  {
    label: "Antidepressants",
    value: "Antidepressants",
  },
  {
    label: "Antifungal drugs",
    value: "Antifungal drugs",
  },
  {
    label: "Antihistamines or H1 blockers",
    value: "Antihistamines or H1 blockers",
  },
  {
    label: "Antihyperlipidemic and hypocholesterolemic agents",
    value: "Antihyperlipidemic and hypocholesterolemic agents",
  },
  {
    label: "Antipsychotics",
    value: "Antipsychotics",
  },
  {
    label: "Antiviral drugs",
    value: "Antiviral drugs",
  },
  {
    label: "Estrogens and progestins and internal contraceptives",
    value: "Estrogens and progestins and internal contraceptives",
  },
  {
    label: "Hypnotics",
    value: "Hypnotics",
  },
  {
    label: "Controlled substance analgesics",
    value: "Controlled substance analgesics",
  },
  {
    label: "Nonsteroidal anti inflammatory drugs NSAIDs",
    value: "Nonsteroidal anti inflammatory drugs NSAIDs",
  },
  {
    label: "Sympathomimetic or adrenergic drugs",
    value: "Sympathomimetic or adrenergic drugs",
  },
  {
    label: "Tranquilizers and antimanic and antianxiety drugs",
    value: "Tranquilizers and antimanic and antianxiety drugs",
  },
  {
    label: "Vasodilators",
    value: "Vasodilators",
  },
  {
    label: "Corticosteroids",
    value: "Corticosteroids",
  },
  {
    label: "Antihypertensive drugs",
    value: "Antihypertensive drugs",
  },
  {
    label: "Radiopharmaceuticals and contrast media",
    value: "Radiopharmaceuticals and contrast media",
  },
  {
    label: "Anthelmintics",
    value: "Anthelmintics",
  },
  {
    label: "Herbal drugs",
    value: "Herbal drugs",
  },
  {
    label: "Antiseptics",
    value: "Antiseptics",
  },
  {
    label: "Floor coverings",
    value: "Floor coverings",
  },
  {
    label: "Bedclothes and table and kitchen linen and towels",
    value: "Bedclothes and table and kitchen linen and towels",
  },
  {
    label: "Window treatments",
    value: "Window treatments",
  },
  {
    label: "Domestic appliances",
    value: "Domestic appliances",
  },
  {
    label: "Domestic kitchenware and kitchen supplies",
    value: "Domestic kitchenware and kitchen supplies",
  },
  {
    label: "Consumer electronics",
    value: "Consumer electronics",
  },
  {
    label: "Domestic wall treatments",
    value: "Domestic wall treatments",
  },
  {
    label: "Clothing",
    value: "Clothing",
  },
  {
    label: "Footwear",
    value: "Footwear",
  },
  {
    label: "Luggage and handbags and packs and cases",
    value: "Luggage and handbags and packs and cases",
  },
  {
    label: "Personal care products",
    value: "Personal care products",
  },
  {
    label: "Sewing supplies and accessories",
    value: "Sewing supplies and accessories",
  },
  {
    label: "Jewelry",
    value: "Jewelry",
  },
  {
    label: "Timepieces",
    value: "Timepieces",
  },
  {
    label: "Gemstones",
    value: "Gemstones",
  },
  {
    label: "Printed media",
    value: "Printed media",
  },
  {
    label: "Electronic reference material",
    value: "Electronic reference material",
  },
  {
    label: "Signage and accessories",
    value: "Signage and accessories",
  },
  {
    label: "Accommodation furniture",
    value: "Accommodation furniture",
  },
  {
    label: "Commercial and industrial furniture",
    value: "Commercial and industrial furniture",
  },
  {
    label: "Classroom and instructional and institutional furniture and fixtures",
    value: "Classroom and instructional and institutional furniture and fixtures",
  },
  {
    label: "Merchandising furniture and accessories",
    value: "Merchandising furniture and accessories",
  },
  {
    label: "Decorative adornments",
    value: "Decorative adornments",
  },
  {
    label: "Developmental and professional teaching aids and materials and accessories and supplies",
    value: "Developmental and professional teaching aids and materials and accessories and supplies",
  },
  {
    label: "Classroom decoratives and supplies",
    value: "Classroom decoratives and supplies",
  },
  {
    label: "Arts and crafts equipment and accessories and supplies",
    value: "Arts and crafts equipment and accessories and supplies",
  },
  {
    label: "Musical Instruments and parts and accessories",
    value: "Musical Instruments and parts and accessories",
  },
  {
    label: "Toys and games",
    value: "Toys and games",
  },
  {
    label: "Bank offered products",
    value: "Bank offered products",
  },
  {
    label: "Securities",
    value: "Securities",
  },
  {
    label: "Insurable interest contracts",
    value: "Insurable interest contracts",
  },
  {
    label: "General agreements and contracts",
    value: "General agreements and contracts",
  },
  {
    label: "Governmental property right conferrals",
    value: "Governmental property right conferrals",
  },
  {
    label: "Fisheries and aquaculture",
    value: "Fisheries and aquaculture",
  },
  {
    label: "Horticulture",
    value: "Horticulture",
  },
  {
    label: "Livestock services",
    value: "Livestock services",
  },
  {
    label: "Land and soil preparation and management and protection",
    value: "Land and soil preparation and management and protection",
  },
  {
    label: "Crop production and management and protection",
    value: "Crop production and management and protection",
  },
  {
    label: "Forestry",
    value: "Forestry",
  },
  {
    label: "Wildlife and flora",
    value: "Wildlife and flora",
  },
  {
    label: "Water resources development and oversight",
    value: "Water resources development and oversight",
  },
  {
    label: "Mining and oil and gas services",
    value: "Mining and oil and gas services",
  },
  {
    label: "Oil and gas exploration services",
    value: "Oil and gas exploration services",
  },
  {
    label: "Well drilling and construction services",
    value: "Well drilling and construction services",
  },
  {
    label: "Oil and gas extraction and production enhancement services",
    value: "Oil and gas extraction and production enhancement services",
  },
  {
    label: "Oil and gas restoration and reclamation services",
    value: "Oil and gas restoration and reclamation services",
  },
  {
    label: "Oil and gas data management and processing services",
    value: "Oil and gas data management and processing services",
  },
  {
    label: "Oil and gas well project management services",
    value: "Oil and gas well project management services",
  },
  {
    label: "Building and facility maintenance and repair services",
    value: "Building and facility maintenance and repair services",
  },
  {
    label: "Residential building construction services",
    value: "Residential building construction services",
  },
  {
    label: "Nonresidential building construction services",
    value: "Nonresidential building construction services",
  },
  {
    label: "Heavy construction services",
    value: "Heavy construction services",
  },
  {
    label: "Specialized trade construction and maintenance services",
    value: "Specialized trade construction and maintenance services",
  },
  {
    label: "Plastic and chemical industries",
    value: "Plastic and chemical industries",
  },
  {
    label: "Wood and paper industries",
    value: "Wood and paper industries",
  },
  {
    label: "Metal and mineral industries",
    value: "Metal and mineral industries",
  },
  {
    label: "Food and beverage industries",
    value: "Food and beverage industries",
  },
  {
    label: "Fibers and textiles and fabric industries",
    value: "Fibers and textiles and fabric industries",
  },
  {
    label: "Manufacturing support services",
    value: "Manufacturing support services",
  },
  {
    label: "Machinery and transport equipment manufacture",
    value: "Machinery and transport equipment manufacture",
  },
  {
    label: "Manufacture of electrical goods and precision instruments",
    value: "Manufacture of electrical goods and precision instruments",
  },
  {
    label: "Machining and processing services",
    value: "Machining and processing services",
  },
  {
    label: "Decontamination services",
    value: "Decontamination services",
  },
  {
    label: "Cleaning and janitorial services",
    value: "Cleaning and janitorial services",
  },
  {
    label: "Refuse disposal and treatment",
    value: "Refuse disposal and treatment",
  },
  {
    label: "Toxic and hazardous waste cleanup",
    value: "Toxic and hazardous waste cleanup",
  },
  {
    label: "Environmental management",
    value: "Environmental management",
  },
  {
    label: "Environmental protection",
    value: "Environmental protection",
  },
  {
    label: "Pollution tracking and monitoring and rehabilitation",
    value: "Pollution tracking and monitoring and rehabilitation",
  },
  {
    label: "Pollutants tracking and monitoring and rehabilitation services",
    value: "Pollutants tracking and monitoring and rehabilitation services",
  },
  {
    label: "Environmental laboratory services",
    value: "Environmental laboratory services",
  },
  {
    label: "Mail and cargo transport",
    value: "Mail and cargo transport",
  },
  {
    label: "Passenger transport",
    value: "Passenger transport",
  },
  {
    label: "Material packing and handling",
    value: "Material packing and handling",
  },
  {
    label: "Storage",
    value: "Storage",
  },
  {
    label: "Transport services",
    value: "Transport services",
  },
  {
    label: "Transportation repair or maintenance services",
    value: "Transportation repair or maintenance services",
  },
  {
    label: "Management advisory services",
    value: "Management advisory services",
  },
  {
    label: "Human resources services",
    value: "Human resources services",
  },
  {
    label: "Legal services",
    value: "Legal services",
  },
  {
    label: "Real estate services",
    value: "Real estate services",
  },
  {
    label: "Marketing and distribution",
    value: "Marketing and distribution",
  },
  {
    label: "Trade policy and services",
    value: "Trade policy and services",
  },
  {
    label: "Business administration services",
    value: "Business administration services",
  },
  {
    label: "Public relations and professional communications services",
    value: "Public relations and professional communications services",
  },
  {
    label: "Professional engineering services",
    value: "Professional engineering services",
  },
  {
    label: "Computer services",
    value: "Computer services",
  },
  {
    label: "Economics",
    value: "Economics",
  },
  {
    label: "Statistics",
    value: "Statistics",
  },
  {
    label: "Manufacturing technologies",
    value: "Manufacturing technologies",
  },
  {
    label: "Earth science services",
    value: "Earth science services",
  },
  {
    label: "Information Technology Service Delivery",
    value: "Information Technology Service Delivery",
  },
  {
    label: "Biological science services",
    value: "Biological science services",
  },
  {
    label: "Advertising",
    value: "Advertising",
  },
  {
    label: "Writing and translations",
    value: "Writing and translations",
  },
  {
    label: "Reproduction services",
    value: "Reproduction services",
  },
  {
    label: "Photographic services",
    value: "Photographic services",
  },
  {
    label: "Graphic design",
    value: "Graphic design",
  },
  {
    label: "Professional artists and performers",
    value: "Professional artists and performers",
  },
  {
    label: "Film and theater production support services",
    value: "Film and theater production support services",
  },
  {
    label: "Utilities",
    value: "Utilities",
  },
  {
    label: "Telecommunications media services",
    value: "Telecommunications media services",
  },
  {
    label: "Information services",
    value: "Information services",
  },
  {
    label: "Development finance",
    value: "Development finance",
  },
  {
    label: "Accounting and bookkeeping services",
    value: "Accounting and bookkeeping services",
  },
  {
    label: "Banking and investment",
    value: "Banking and investment",
  },
  {
    label: "Insurance and retirement services",
    value: "Insurance and retirement services",
  },
  {
    label: "Credit agencies",
    value: "Credit agencies",
  },
  {
    label: "Comprehensive health services",
    value: "Comprehensive health services",
  },
  {
    label: "Disease prevention and control",
    value: "Disease prevention and control",
  },
  {
    label: "Medical practice",
    value: "Medical practice",
  },
  {
    label: "Medical science research and experimentation",
    value: "Medical science research and experimentation",
  },
  {
    label: "Alternative and holistic medicine",
    value: "Alternative and holistic medicine",
  },
  {
    label: "Food and nutrition services",
    value: "Food and nutrition services",
  },
  {
    label: "Medical Surgical Equipment Maintenance Refurbishment and Repair Services",
    value: "Medical Surgical Equipment Maintenance Refurbishment and Repair Services",
  },
  {
    label: "Death and dying support services",
    value: "Death and dying support services",
  },
  {
    label: "Diagnoses of infectious and parasitic diseases-part a",
    value: "Diagnoses of infectious and parasitic diseases-part a",
  },
  {
    label: "Diagnoses of infectious and parasitic diseases-part b",
    value: "Diagnoses of infectious and parasitic diseases-part b",
  },
  {
    label: "Diagnoses of neoplasms",
    value: "Diagnoses of neoplasms",
  },
  {
    label: "Diagnoses of endocrine, nutritional and metabolic diseases",
    value: "Diagnoses of endocrine, nutritional and metabolic diseases",
  },
  {
    label: "Diagnoses of mental and behavioral disorders",
    value: "Diagnoses of mental and behavioral disorders",
  },
  {
    label: "Diagnoses of diseases of the nervous system",
    value: "Diagnoses of diseases of the nervous system",
  },
  {
    label: "Diagnoses of diseases of the eye and adnexa",
    value: "Diagnoses of diseases of the eye and adnexa",
  },
  {
    label: "Diagnoses of diseases of the ear and mastoid process",
    value: "Diagnoses of diseases of the ear and mastoid process",
  },
  {
    label: "Diagnoses of diseases of the circulatory system",
    value: "Diagnoses of diseases of the circulatory system",
  },
  {
    label: "Diagnoses of diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism",
    value: "Diagnoses of diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism",
  },
  {
    label: "Diagnoses of diseases of the digestive system",
    value: "Diagnoses of diseases of the digestive system",
  },
  {
    label: "Diagnoses of diseases of the respiratory system",
    value: "Diagnoses of diseases of the respiratory system",
  },
  {
    label: "Diagnoses of diseases of the skin and subcutaneous tissue",
    value: "Diagnoses of diseases of the skin and subcutaneous tissue",
  },
  {
    label: "Diagnoses of diseases of the genitourinary system",
    value: "Diagnoses of diseases of the genitourinary system",
  },
  {
    label: "Diagnoses of diseases of the musculoskeletal system and connective tissue",
    value: "Diagnoses of diseases of the musculoskeletal system and connective tissue",
  },
  {
    label: "Diagnoses of certain conditions originating in the perinatal period",
    value: "Diagnoses of certain conditions originating in the perinatal period",
  },
  {
    label: "Diagnoses of pregnancy, childbirth conditions and the puerperium",
    value: "Diagnoses of pregnancy, childbirth conditions and the puerperium",
  },
  {
    label: "Diagnoses of congenital malformations, deformations, and chromosomal abnormalities",
    value: "Diagnoses of congenital malformations, deformations, and chromosomal abnormalities",
  },
  {
    label: "Vocational training",
    value: "Vocational training",
  },
  {
    label: "Alternative educational systems",
    value: "Alternative educational systems",
  },
  {
    label: "Educational institutions",
    value: "Educational institutions",
  },
  {
    label: "Specialized educational services",
    value: "Specialized educational services",
  },
  {
    label: "Educational facilities",
    value: "Educational facilities",
  },
  {
    label: "Restaurants and catering",
    value: "Restaurants and catering",
  },
  {
    label: "Hotels and lodging and meeting facilities",
    value: "Hotels and lodging and meeting facilities",
  },
  {
    label: "Travel facilitation",
    value: "Travel facilitation",
  },
  {
    label: "Performing arts",
    value: "Performing arts",
  },
  {
    label: "Commercial sports",
    value: "Commercial sports",
  },
  {
    label: "Entertainment services",
    value: "Entertainment services",
  },
  {
    label: "Personal appearance",
    value: "Personal appearance",
  },
  {
    label: "Domestic and personal assistance",
    value: "Domestic and personal assistance",
  },
  {
    label: "Public order and safety",
    value: "Public order and safety",
  },
  {
    label: "Military services and national defense",
    value: "Military services and national defense",
  },
  {
    label: "Security and personal safety",
    value: "Security and personal safety",
  },
  {
    label: "Political systems and institutions",
    value: "Political systems and institutions",
  },
  {
    label: "Socio political conditions",
    value: "Socio political conditions",
  },
  {
    label: "International relations",
    value: "International relations",
  },
  {
    label: "Humanitarian aid and relief",
    value: "Humanitarian aid and relief",
  },
  {
    label: "Community and social services",
    value: "Community and social services",
  },
  {
    label: "Public administration and finance services",
    value: "Public administration and finance services",
  },
  {
    label: "Taxation",
    value: "Taxation",
  },
  {
    label: "Trade policy and regulation",
    value: "Trade policy and regulation",
  },
  {
    label: "Work related organizations",
    value: "Work related organizations",
  },
  {
    label: "Religious organizations",
    value: "Religious organizations",
  },
  {
    label: "Clubs",
    value: "Clubs",
  },
  {
    label: "Civic organizations and associations and movements",
    value: "Civic organizations and associations and movements",
  },
  {
    label: "Land parcels",
    value: "Land parcels",
  },
  {
    label: "Thoroughfares",
    value: "Thoroughfares",
  },
  {
    label: "Permanent buildings and structures",
    value: "Permanent buildings and structures",
  },
  {
    label: "Portable buildings and structures",
    value: "Portable buildings and structures",
  },
  {
    label: "Prefabricated buildings and structures",
    value: "Prefabricated buildings and structures",
  },
];

export const itemGroupMap = {
  PRIMARY_ADDRESS_GROUP: {
    itemGroupKey: "PRIMARY_ADDRESS_GROUP",
    items: [
      {
        type: "SINGLESELECT",
        label: "Country/Region",
        size: 4,
        value: null,
        isRequired: false,
        itemKey: "COUNTRY/REGION_GROUP_ITEM",
        additionalData: {
          options: [],
          isCountry: true,
        },
      },
      {
        type: "SINGLESELECT",
        label: "State",
        size: 4,
        value: null,
        isRequired: false,
        itemKey: "STATE_GROUP_ITEM",
        additionalData: {
          options: [],
          isState: true,
        },
      },
      {
        type: "TEXT",
        label: "City",
        size: 4,
        value: null,
        isRequired: false,
        itemKey: "CITY_GROUP_ITEM",
        additionalData: null,
      },
      {
        type: "TEXT",
        label: "Address Line",
        size: 4,
        value: null,
        isRequired: false,
        itemKey: "ADDRESS_LINE_GROUP_ITEM",
        additionalData: null,
      },
      {
        type: "TEXT",
        label: "Address Line 2",
        size: 4,
        value: null,
        isRequired: false,
        itemKey: "ADDRESS_LINE_2_GROUP_ITEM",
        additionalData: null,
      },
      {
        type: "TEXT",
        label: "ZIP",
        size: 4,
        value: null,
        isRequired: false,
        itemKey: "ZIP_GROUP_ITEM",
        additionalData: null,
      },
    ],
  },
  "SUPPLIER_DIVERSITY_&_INCLUSION_GROUP": {
    itemGroupKey: "SUPPLIER_DIVERSITY_&_INCLUSION_GROUP",
    items: [
      {
        type: "SINGLESELECT",
        label: "Country/Region",
        size: 4,
        value: "",
        isRequired: false,
        itemKey: "DIVERSITY_CLA_COUNTRY_REGION",
        additionalData: {
          options: [],
          isCountry: true,
        },
      },
      {
        type: "SINGLESELECT",
        label: "Classifications",
        size: 4,
        value: "",
        isRequired: false,
        itemKey: "DIVERSITY_CLA_CLASSIFICATIONS",
        additionalData: {
          optionsForUS: [
            {
              label: "Ability One Program",
              value: 1,
            },
            {
              label: "Airport Concession Disadvantaged Business Enterprise",
              value: 2,
            },
            {
              label: "Alaskan Native Corporation",
              value: 3,
            },
            {
              label: "Disability Owned Business Disabled Veteran Business Enterprise",
              value: 4,
            },
            {
              label: "Disadvantaged Business Enterprise",
              value: 5,
            },
            {
              label: "Economically Disadvantaged Women-Owned Small Business",
              value: 6,
            },
            {
              label: "Historically Black College or University / Minority Institution Historically Underutilized Business Zone",
              value: 7,
            },
            {
              label: "Labor Surplus Area",
              value: 8,
            },
            {
              label: "Lesbian, Gay, Bisexual, Transgender Business Enterprise",
              value: 9,
            },
            {
              label: "Minority Owned Business Enterprise",
              value: 10,
            },
            {
              label: "Native American Owned",
              value: 11,
            },
            {
              label: "Non-profit Organization",
              value: 12,
            },
            {
              label: "SBA-8(A)",
              value: 13,
            },
            {
              label: "Service Disabled Veteran Owned Business Enterprise",
              value: 14,
            },
            {
              label: "Service Disabled Veteran Owned Small Business",
              value: 15,
            },
            {
              label: "Small Business Enterprise",
              value: 16,
            },
            {
              label: "Small Disadvantaged Business",
              value: 17,
            },
            {
              label: "Veteran Owned Business",
              value: 18,
            },
            {
              label: "Vietnam Veteran Owned",
              value: 19,
            },
            {
              label: "Woman Owned Business Enterprise Woman Owned Small Business",
              value: 20,
            },
          ],
          optionsForOthers: [
            {
              label: "Disability Owned Business",
              value: 1,
            },
            {
              label: "Ethnic Minority Business",
              value: 2,
            },
            {
              label: "Lesbian, Gay, Bisexual, Transgender Business Enterprise",
              value: 3,
            },
            {
              label: "Minority Owned Business Enterprise",
              value: 4,
            },
            {
              label: "Small Business Enterprise",
              value: 5,
            },
            {
              label: "Woman Owned Business Enterprise",
              value: 6,
            },
          ],
          options: [
            {
              label: "Disability Owned Business",
              value: 1,
            },
            {
              label: "Ethnic Minority Business",
              value: 2,
            },
            {
              label: "Lesbian, Gay, Bisexual, Transgender Business Enterprise",
              value: 3,
            },
            {
              label: "Minority Owned Business Enterprise",
              value: 4,
            },
            {
              label: "Small Business Enterprise",
              value: 5,
            },
            {
              label: "Woman Owned Business Enterprise",
              value: 6,
            },
          ],
        },
      },
      // {
      //   type: "POPUP",
      //   label: "Diversity Classifications and Certifications",
      //   size: 8,
      //   value: [
      //     {
      //       label: "",
      //       value: "",
      //     },
      //     {
      //       label: "",
      //       value: "",
      //     },
      //   ],
      //   isRequired: false,
      //   itemKey: "DIVERSITY_CLASSIFICATIONS_AND_CERTIFICATIONS_GROUP_ITEM",
      //   additionalData: {
      //     popupData: {
      //       items: [
      //         {
      //           type: "SINGLESELECT",
      //           label: "Country/Region",
      //           size: 4,
      //           value: "",
      //           isRequired: false,
      //           itemKey: "DIVERSITY_CLA_COUNTRY_REGION",
      //           additionalData: {
      //             options: [],
      //             isCountry: true,
      //           },
      //         },
      //         {
      //           type: "SINGLESELECT",
      //           label: "Classifications",
      //           size: 4,
      //           value: "",
      //           isRequired: false,
      //           itemKey: "DIVERSITY_CLA_CLASSIFICATIONS",
      //           additionalData: {
      //             optionsForUS: [
      //               {
      //                 label: "Ability One Program",
      //                 value: 1,
      //               },
      //               {
      //                 label: "Airport Concession Disadvantaged Business Enterprise",
      //                 value: 2,
      //               },
      //               {
      //                 label: "Alaskan Native Corporation",
      //                 value: 3,
      //               },
      //               {
      //                 label: "Disability Owned Business Disabled Veteran Business Enterprise",
      //                 value: 4,
      //               },
      //               {
      //                 label: "Disadvantaged Business Enterprise",
      //                 value: 5,
      //               },
      //               {
      //                 label: "Economically Disadvantaged Women-Owned Small Business",
      //                 value: 6,
      //               },
      //               {
      //                 label: "Historically Black College or University / Minority Institution Historically Underutilized Business Zone",
      //                 value: 7,
      //               },
      //               {
      //                 label: "Labor Surplus Area",
      //                 value: 8,
      //               },
      //               {
      //                 label: "Lesbian, Gay, Bisexual, Transgender Business Enterprise",
      //                 value: 9,
      //               },
      //               {
      //                 label: "Minority Owned Business Enterprise",
      //                 value: 10,
      //               },
      //               {
      //                 label: "Native American Owned",
      //                 value: 11,
      //               },
      //               {
      //                 label: "Non-profit Organization",
      //                 value: 12,
      //               },
      //               {
      //                 label: "SBA-8(A)",
      //                 value: 13,
      //               },
      //               {
      //                 label: "Service Disabled Veteran Owned Business Enterprise",
      //                 value: 14,
      //               },
      //               {
      //                 label: "Service Disabled Veteran Owned Small Business",
      //                 value: 15,
      //               },
      //               {
      //                 label: "Small Business Enterprise",
      //                 value: 16,
      //               },
      //               {
      //                 label: "Small Disadvantaged Business",
      //                 value: 17,
      //               },
      //               {
      //                 label: "Veteran Owned Business",
      //                 value: 18,
      //               },
      //               {
      //                 label: "Vietnam Veteran Owned",
      //                 value: 19,
      //               },
      //               {
      //                 label: "Woman Owned Business Enterprise Woman Owned Small Business",
      //                 value: 20,
      //               },
      //             ],
      //             optionsForOthers: [
      //               {
      //                 label: "Disability Owned Business",
      //                 value: 1,
      //               },
      //               {
      //                 label: "Ethnic Minority Business",
      //                 value: 2,
      //               },
      //               {
      //                 label: "Lesbian, Gay, Bisexual, Transgender Business Enterprise",
      //                 value: 3,
      //               },
      //               {
      //                 label: "Minority Owned Business Enterprise",
      //                 value: 4,
      //               },
      //               {
      //                 label: "Small Business Enterprise",
      //                 value: 5,
      //               },
      //               {
      //                 label: "Woman Owned Business Enterprise",
      //                 value: 6,
      //               },
      //             ],
      //           },
      //         },
      //       ],
      //     },
      //   },
      // },
    ],
  },
  ACCOMPLISHMENTS_GROUP: {
    itemGroupKey: "ACCOMPLISHMENTS_GROUP",
    items: [
      {
        type: "TEXT",
        label: "Title",
        size: 4,
        value: null,
        isRequired: false,
        itemKey: "TITLE_GROUP_ITEM",
        additionalData: null,
      },
      {
        type: "URL",
        label: "URL",
        size: 4,
        value: null,
        isRequired: false,
        itemKey: "URL_GROUP_ITEM",
        additionalData: null,
      },
    ],
  },
  BRANDS_GROUP: {
    itemGroupKey: "BRANDS_GROUP",
    items: [
      {
        type: "TEXT",
        label: "Brand Name",
        size: 12,
        value: null,
        isRequired: false,
        itemKey: "BRAND_NAME_GROUP_ITEM",
        additionalData: null,
      },
    ],
  },
};

export const profileDataMap = {
  COMPANY_PROFILE: [
    {
      sectionName: "Basic Details",
      items: [
        {
          type: "TEXT",
          label: "Company Name",
          size: 4,
          value: "",
          isRequired: true,
          itemKey: "COMPANY_NAME",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "Doing business as",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "DOING_BUSINESS_AS",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Is ultimate parent",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "IS_ULTIMATE_PARENT",
          additionalData: null,
        },
      ],
      sectionKey: "BASIC_DETAILS",
    },
    {
      sectionName: "Primary Address",
      itemGroups: [itemGroupMap["PRIMARY_ADDRESS_GROUP"]],
      sectionKey: "PRIMARY_ADDRESS",
    },
    {
      sectionName: "Primary Contact",
      items: [
        {
          type: "IMAGE",
          label: "Company Logo",
          size: 12,
          value: null,
          isRequired: false,
          itemKey: "COMPANY_LOGO",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "First Name",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "FIRST_NAME",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "Last Name",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "LAST_NAME",
          additionalData: null,
        },
        {
          type: "EMAIL",
          label: "Email",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "EMAIL",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "Role",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "ROLE",
          additionalData: null,
        },
        {
          type: "PHONE",
          label: "Preferred Phone",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "PREFERRED_PHONE",
          additionalData: null,
        },
        {
          type: "PHONE",
          label: "Mobile Number",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "MOBILE_NUMBER",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "Work Number",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "WORK_NUMBER",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "Fax Number",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "FAX_NUMBER",
          additionalData: null,
        },
      ],
      sectionKey: "PRIMARY_CONTACT",
    },
    {
      sectionName: "Company Information",
      items: [
        {
          type: "NUMBER",
          label: "Year Established",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "YEAR_ESTABLISHED",
          additionalData: null,
        },
        {
          type: "SINGLESELECT",
          label: "Company Size",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "COMPANY_SIZE",
          additionalData: {
            options: [
              {
                label: "Self-employed",
                value: 1,
              },
              {
                label: "2-9 employees",
                value: 2,
              },
              {
                label: "10-49 employees",
                value: 3,
              },
              {
                label: "50-99 employees",
                value: 4,
              },
              {
                label: "100-249 employees",
                value: 5,
              },
              {
                label: "250-499 employees",
                value: 6,
              },
              {
                label: "500-999 employees",
                value: 7,
              },
              {
                label: "1000-5000 employees",
                value: 8,
              },
              {
                label: "5000+ employees",
                value: 9,
              },
            ],
          },
        },
        {
          type: "NUMBER",
          label: "Number Of Employees",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "NUMBER_OF_EMPLOYEES",
          additionalData: null,
        },
        {
          type: "SINGLESELECT",
          label: "Ownership Type",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "OWNERSHIP_TYPE",
          additionalData: {
            options: [
              {
                label: "public",
                value: 1,
              },
              {
                label: "private",
                value: 2,
              },
            ],
          },
        },
        {
          type: "SINGLESELECT",
          label: "Industry",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "INDUSTRY",
          additionalData: {
            options: industries,
          },
        },
        {
          type: "EMAIL",
          label: "PO Delivery Email",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "PO_DELIVERY_EMAIL",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "Tax ID",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "TAX_ID",
          additionalData: { isVerified: true },
        },
        {
          type: "TEXT",
          label: "DUNS",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "DUNS",
          additionalData: null,
        },
      ],
      sectionKey: "COMPANY_INFORMATION",
    },
    {
      sectionName: "AREAS SERVED",
      sectionKey: "AREAS_SERVED",
      items: [
        {
          type: "SINGLESELECT",
          label: "",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "AREAS_SERVED_ITEM",
          additionalData: {
            options: [
              {
                label: "Global",
                value: 1,
              },
              {
                label: "Regional",
                value: 2,
              },
            ],
          },
        },
      ],
    },
    {
      sectionName: "ONLINE PRESENCE",
      items: [
        {
          type: "URL",
          label: "Website",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "WEBSITE",
          additionalData: null,
        },
        {
          type: "URL",
          label: "Twitter",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "TWITTER",
          additionalData: null,
        },
        {
          type: "URL",
          label: "LinkedIn",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "LINKEDIN",
          additionalData: null,
        },
        {
          type: "URL",
          label: "Facebook",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "FACEBOOK",
          additionalData: null,
        },
        {
          type: "URL",
          label: "Instagram",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "INSTAGRAM",
          additionalData: null,
        },
        {
          type: "URL",
          label: "YouTube",
          size: 4,
          value: null,
          isRequired: false,
          itemKey: "YOUTUBE",
          additionalData: null,
        },
      ],
      sectionKey: "ONLINE_PRESENCE",
    },
    {
      sectionName: "ABOUT",
      items: [
        {
          type: "TEXT",
          label: "Description",
          size: 12,
          value: null,
          isRequired: false,
          itemKey: "DESCRIPTION",
          additionalData: null,
        },
      ],
      sectionKey: "ABOUT",
    },
  ],
  ENVIRONMENTAL: [
    {
      sectionName: "Supplier Diversity & Inclusion",
      itemGroups: [itemGroupMap["SUPPLIER_DIVERSITY_&_INCLUSION_GROUP"]],
      sectionKey: "SUPPLIER_DIVERSITY_&_INCLUSION",
    },
    {
      sectionName: "Tier Two Supplier Diversities",
      items: [
        {
          type: "BINARY",
          label: "Do you have a workplace diversity program?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_HAVE_A_WORKPLACE_DIVERSITY_PROGRAM?",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Do you have a supplier Diversity Program?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_HAVE_A_SUPPLIER_DIVERSITY_PROGRAM?",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Do you measure Tier 2 Diversity spend?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_MEASURE_TIER2_DIVERSITY_SPEND?",
          additionalData: null,
        },
      ],
      sectionKey: "TIER_TWO_SUPPLIER_DIVERSITIES",
    },
    {
      sectionName: "Anti-Bribery & Anti Corruption",
      items: [
        {
          type: "BINARY",
          label: "Bribery and Corruption Policy",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "BRIBERY_AND_CORRUPTION_POLICY",
          additionalData: null,
        },
      ],
      sectionKey: "ANTI-BRIBERY_&_ANTI_CORRUPTION",
    },
    {
      sectionName: "Corporate Social Responsibility",
      items: [
        {
          type: "BINARY",
          label: "Do you have Corporate Social Responsibility Rating?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_HAVE_CORPORATE_SOCIAL_RESPONSIBILITY_RATING?",
          additionalData: null,
        },
      ],
      sectionKey: "CORPORATE_SOCIAL_RESPONSIBILITY",
    },
    {
      sectionName: "Child Labor",
      items: [
        {
          type: "BINARY",
          label: "Do you have cited for violations in the past?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_HAVE_CITED_FOR_VIOLATIONS_IN_THE_PAST?",
          additionalData: null,
        },
      ],
      sectionKey: "CHILD_LABOR",
    },
    {
      sectionName: "Modern Slavery",
      items: [
        {
          type: "BINARY",
          label: "Do you have labor in your supply chain?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_HAVE_LABOR_IN_YOUR_SUPPLY_CHAIN?",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Do you operate in regions with Modern Day Slavery compliance?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_OPERATE_IN_REGIONS_WITH_MODERN_DAY_SLAVERY_COMPLIANCE",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Have you been cited for violations in the past?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "HAVE_YOU_BEEN_CITED_FOR_VIOLATIONS_IN_THE_PAST?",
          additionalData: null,
        },
      ],
      sectionKey: "MODERN_SLAVERY",
    },
    {
      sectionName: "Conflict Minerals",
      items: [
        {
          type: "BINARY",
          label: "Do you have conflict minerals in your supply chain?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_HAVE_CONFLICT_MINERALS_IN_YOUR_SUPPLY_CHAIN?",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Do you operate in regions known for conflict minerals issues?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_OPERATE_IN_REGIONS_KNOWN_FOR_CONFLICT_MINERALS_ISSUES",
          additionalData: null,
        },
      ],
      sectionKey: "CONFLICT_MINERALS",
    },
    {
      sectionName: "Hazardous Materials",
      items: [
        {
          type: "BINARY",
          label: "Do you have hazardous materials in your value chain?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_HAVE_HAZARDOUS_MATERIALS_IN_YOUR_VALUE_CHAIN?",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Do you supply commodities with hazardous materials?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_SUPPLY_COMMODITIES_WITH_HAZARDOUS_MATERIALS",
          additionalData: null,
        },
      ],
      sectionKey: "HAZARDOUS_MATERIALS",
    },
    {
      sectionName: "ESG Report / Sustainability Goals",
      items: [
        {
          type: "NUMBER",
          label: "ESG Score",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "ESG_SCORE",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "Certifying Organization",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "CERTIFYING_ORGANIZATION",
          additionalData: null,
        },
        {
          type: "SINGLESELECT",
          label: "Major Environmental goals",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "MAJOR_ENVIRONMENTAL_GOALS",
          additionalData: {
            options: [
              { label: "Environmental", value: "Environmental" },
              { label: "Social", value: "Social" },
              { label: "Governance", value: "Governance" },
            ],
          },
        },
        {
          type: "BINARY",
          label: "Have published an ESG, CSR or Sustainability report(s)?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "HAVE_PUBLISHED_AN_ESG_CSR_OR_SUSTAINABILITY_REPORT",
          additionalData: null,
        },
      ],
      sectionKey: "ESG_REPORT_SUSTAINABILITY_GOALS",
    },
    {
      sectionName: "ESG Ratings",
      items: [
        {
          type: "NUMBER",
          label: "EcoVadis Sustainability Score?",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "ECOVADIS_SUSTAINABILITY_SCORE",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "CDP Score?",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "CDP_SCORE",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "CSR Hub ESG Ranking?",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "CSR_HUB_ESG_RANKING",
          additionalData: null,
        },
        {
          type: "SINGLESELECT",
          label: "EcoVadis Medal?",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "ECOVADIS_MEDAL",
          additionalData: {
            options: [
              { label: "Platinum", value: "Platinum" },
              { label: "Gold", value: "Gold" },
              { label: "Silver", value: "Silver" },
              { label: "Bronze", value: "Bronze" },
            ],
          },
        },
        {
          type: "TEXT",
          label: "Social Enterprise (by whom and why)?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "SOCIAL_ENTERPRISE_BY_WHOM_AND_WHY",
          additionalData: null,
        },
      ],
      sectionKey: "ESG_RATINGS",
    },
    {
      sectionName: "Sustainability Ratings",
      items: [
        {
          type: "BINARY",
          label: "Are you certified or rated for Sustainability?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "ARE_YOU_CERTIFIED_OR_RATED_FOR_SUSTAINABILITY",
          additionalData: null,
        },
      ],
      sectionKey: "SUSTAINABILITY_RATINGS",
    },
    {
      sectionName: "Carbon Footprint/GHG Emissions",
      items: [
        {
          type: "NUMBER",
          label: "What is your Scope 1 emissions footprint?",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "WHAT_IS_YOUR_SCOPE_1_EMISSIONS_FOOTPRINT?",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "What is your Scope 2 emissions footprint?",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "WHAT_IS_YOUR_SCOPE_2_EMISSIONS_FOOTPRINT?",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "What is your Scope 3 emissions footprint?",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "WHAT_IS_YOUR_SCOPE_3_EMISSIONS_FOOTPRINT?",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "What targets have you set for Scope 1 emissions reductions?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "WHAT_TARGETS_HAVE_YOU_SET_FOR_SCOPE_1_EMISSIONS_REDUCTIONS?",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "What targets have you set for Scope 2 emissions reductions?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "WHAT_TARGETS_HAVE_YOU_SET_FOR_SCOPE_2_EMISSIONS_REDUCTIONS?",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "What targets have you set for Scope 3 emissions reductions?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "WHAT_TARGETS_HAVE_YOU_SET_FOR_SCOPE_3_EMISSIONS_REDUCTIONS?",
          additionalData: null,
        },
        {
          type: "TEXT",
          label: "What is your progress towards these goals?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "WHAT_IS_YOUR_PROGRESS_TOWARDS_THESE_GOALS?",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Are you working with your suppliers on reduction strategies?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "ARE_YOU_WORKING_WITH_YOUR_SUPPLIERS_ON_REDUCTION_STRATEGIES",
          additionalData: null,
        },
      ],
      sectionKey: "CARBON_FOOTPRINT/GHG_EMISSIONS",
    },
    {
      sectionName: "Net Zero Targets",
      items: [
        {
          type: "BINARY",
          label: "Have you committed to Net Zero targets?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "HAVE_YOU_COMMITTED_TO_NET_ZERO_TARGETS",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Are you working with your suppliers on reduction strategies?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "ARE_YOU_WORKING_WITH_YOUR_SUPPLIERS_ON_REDUCTION_STRATEGIES",
          additionalData: null,
        },
      ],
      sectionKey: "NET_ZERO_TARGETS",
    },
    {
      sectionName: "Environment, Health & Safety (EHS)",
      items: [
        {
          type: "BINARY",
          label: "Do you have a Health & Safety (H&S) policy in place?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_HAVE_A_HEALTH_&_SAFETY_HS_POLICY_IN_PLACE",
          additionalData: null,
        },
      ],
      sectionKey: "ENVIRONMENT_HEALTH_SAFETY_EHS",
    },
  ],
  RISK_COMPLIANCE: [
    {
      sectionName: "Risk Management",
      items: [
        {
          type: "BINARY",
          label: "Is a risk management program in place to assess risk across the company?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "IS_A_RISK_MANAGEMENT_PROGRAM_IN_PLACE_TO_ASSESS_RISK_ACROSS_THE_COMPANY",
          additionalData: null,
        },
      ],
      sectionKey: "RISK_MANAGEMENT",
    },
    {
      sectionName: "Information Security",
      items: [
        {
          type: "BINARY",
          label: "Is a code of conduct policy in place across the country?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "IS_A_CODE_OF_CONDUCT_POLICY_IN_PLACE_ACROSS_THE_COUNTRY",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Is a physical security policy in place?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "IS_A_PHYSICAL_SECURITY_POLICY_IN_PLACE",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Are background checks performed on employees and contractors before they are granted access to data?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "ARE_BACKGROUND_CHECKS_PERFORMED_ON_EMPLOYEES_AND_CONTRACTORS_BEFORE_THEY_ARE_GRANTED_ACCESS_TO_DATA",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Is a change management policy in place?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "IS_A_CHANGE_MANAGEMENT_POLICY_IN_PLACE",
          additionalData: null,
        },
      ],
      sectionKey: "INFORMATION_SECURITY",
    },
    {
      sectionName: "Data Privacy",
      items: [
        {
          type: "BINARY",
          label: "Is a privacy policy in place and in compliance with applicable privacy regulations?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "IS_A_PRIVACY_POLICY_IN_PLACE_AND_IN_COMPLIANCE_WITH_APPLICABLE_PRIVACY_REGULATIONS",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Are employees and contractors required to complete a privacy training",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "ARE_EMPLOYEES_AND_CONTRACTORS_REQUIRED_TO_COMPLETE_A_PRIVACY_TRAINING",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Do you attest to compliance with data privacy requirements on an annual basis?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "DO_YOU_ATTEST_TO_COMPLIANCE_WITH_DATA_PRIVACY_REQUIREMENTS_ON_AN_ANNUAL_BASIS",
          additionalData: null,
        },
        {
          type: "BINARY",
          label: "Is personal information accessed, disclosed, processed, transmitted or retained outside the U.S?",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "IS_PERSONAL_INFORMATION_ACCESSED,_DISCLOSED,_PROCESSED,_TRANSMITTED_OR_RETAINED_OUTSIDE_THE_US",
          additionalData: null,
        },
      ],
      sectionKey: "DATA_PRIVACY",
    },
  ],
  FINANCIAL_PERFORMANCE: [
    {
      sectionName: "Preferred Currencies",
      items: [
        {
          type: "TEXT",
          label: "Country/Region",
          size: 12,
          value: "",
          isRequired: false,
          itemKey: "COUNTRY/REGION",
          additionalData: null,
        },
      ],
      sectionKey: "PREFERRED_CURRENCIES",
    },
    {
      sectionName: "Balance Sheet",
      items: [
        {
          type: "NUMBER",
          label: "Net Loss/Profit",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "NET_LOSS/PROFIT",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Current Liabilities",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "CURRENT_LIABILITIES",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Book Value",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "BOOK_VALUE",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Other Assets",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "OTHER_ASSETS",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Total Assets",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "TOTAL_ASSETS",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Other Liabilities",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "OTHER_LIABILITIES",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Working Capital",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "WORKING_CAPITAL",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Account Receivable",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "ACCOUNT_RECEIVABLE",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Debt Ratio",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "DEBT_RATIO",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Accounts Payable",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "ACCOUNTS_PAYABLE",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Total Debt",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "TOTAL_DEBT",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Inventory",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "INVENTORY",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Total Equity",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "TOTAL_EQUITY",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Net PPE",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "NET_PPE",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Current Assets",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "CURRENT_ASSETS",
          additionalData: null,
        },
      ],
      sectionKey: "BALANCE_SHEET",
    },
    {
      sectionName: "Cash Flow",
      items: [
        {
          type: "NUMBER",
          label: "Cashflow",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "CASHFLOW",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Financing Cash Flow",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "FINANCING_CASH_FLOW",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Operating Cash Flow",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "OPERATING_CASH_FLOW",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Capital Expenditures",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "CAPITAL_EXPENDITURES",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Investing Cash Flow",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "INVESTING_CASH_FLOW",
          additionalData: null,
        },
      ],
      sectionKey: "CASH_FLOW",
    },
    {
      sectionName: "Market Value, Risk & Ratios",
      items: [
        {
          type: "NUMBER",
          label: "Coorporate Credit Rating",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "COORPORATE_CREDIT_RATING",
          additionalData: null,
        },
        {
          type: "NUMBER",
          label: "Altman Z Score",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "ALTMAN_Z_SCORE",
          additionalData: null,
        },
      ],
      sectionKey: "MARKET_VALUE,_RISK_&_RATIOS",
    },
    {
      sectionName: "Revenue Histories",
      items: [
        {
          type: "TEXT",
          label: "Add Revenue History",
          size: 4,
          value: "",
          isRequired: false,
          itemKey: "ADD_REVENUE_HISTORY",
          additionalData: null,
        },
      ],
      sectionKey: "REVENUE_HISTORIES",
    },
  ],
  RATING_REFERENCES: [
    {
      sectionName: "Customers",
      items: [
        {
          type: "ADD_VENDOR",
          label: "Customers",
          size: 12,
          value: [],
          isRequired: false,
          itemKey: "CUSTOMERS",
          additionalData: null,
        },
      ],
      sectionKey: "CUSTOMERS",
    },
    {
      sectionName: "Accomplishments",
      itemGroups: [itemGroupMap["ACCOMPLISHMENTS_GROUP"]],
      sectionKey: "ACCOMPLISHMENTS",
    },
    {
      sectionName: "Brands",
      itemGroups: [itemGroupMap["BRANDS_GROUP"]],
      sectionKey: "BRANDS",
    },
    {
      sectionName: "Competitors",
      items: [
        {
          type: "ADD_VENDOR",
          label: "Competitors",
          size: 12,
          value: [],
          isRequired: false,
          itemKey: "COMPETITORS",
          additionalData: null,
        },
      ],
      sectionKey: "COMPETITORS",
    },
    {
      sectionName: "Peers",
      items: [
        {
          type: "ADD_VENDOR",
          label: "Peers",
          size: 12,
          value: [],
          isRequired: false,
          itemKey: "PEERS",
          additionalData: null,
        },
      ],
      sectionKey: "PEERS",
    },
  ],
};

export const profileMenuNameMap = {
  COMPANY_PROFILE: "Company",
  ENVIRONMENTAL: "Environmental, Social, Governance & Diversity",
  RISK_COMPLIANCE: "Risk & Compliance",
  FINANCIAL_PERFORMANCE: "Financial Performance",
  RATING_REFERENCES: "Ratings & References",
};

export const createDataForSave = (data) => {
  let finalData = [];
  if (data) {
    data.forEach(function (section) {
      if (section.itemGroups) {
        let itm_group_obj = {
          name: section.itemGroups[0].itemGroupKey,
          value: "",
        };
        let values = [];
        section.itemGroups.forEach(function (ig) {
          let obj = {};
          ig.items.forEach(function (itm) {
            obj[itm.itemKey] = itm.value;
          });
          values.push(obj);
        });
        itm_group_obj.value = JSON.stringify(values);
        finalData.push(itm_group_obj);
      }

      if (section.items) {
        section.items.forEach(function (itm) {
          if (itm.itemKey) {
            let itm_obj = {
              name: itm.itemKey,
              value: JSON.stringify(itm.value),
            };
            finalData.push(itm_obj);
          }
        });
      }
    });
  }
  return finalData;
};

const getResponseMap = (response) => {
  let map = {};
  if (response && response.length > 0) {
    response.forEach(function (res) {
      map[res.Slug] = res.Value && res.Value !== "" ? JSON.parse(res.Value) : "";
    });
  }
  return map;
};

export const createDataForProfileRender = (response) => {
  let datas = profileDataMap;
  let responseMap = getResponseMap(response);
  Object.keys(datas).forEach(function (activeMenu) {
    let activeMenuData = datas[activeMenu];
    activeMenuData.forEach(function (section) {
      if (section.items) {
        section.items.forEach(function (itm) {
          if (responseMap[itm.itemKey] !== undefined) {
            itm.value = responseMap[itm.itemKey];
          }
        });
      }
      if (section.itemGroups) {
        const itemGrpKey = section.itemGroups[0].itemGroupKey;
        if (responseMap[itemGrpKey] !== undefined) {
          let newgrpDatas = [];
          responseMap[itemGrpKey].forEach(function (res, index) {
            const newItemGroup = { ...itemGroupMap[itemGrpKey] };
            newItemGroup.items = newItemGroup.items.map((o) => ({ ...o, value: res[o.itemKey] ? res[o.itemKey] : null }));
            newgrpDatas.push(newItemGroup);
          });
          section.itemGroups = [...newgrpDatas];
        }
      }
    });
  });

  console.log(datas, "datas1111111111111111111111111111111111111111111");
  return datas;
};
