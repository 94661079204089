import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

const DoughnutChart = ({ chartdata, options }) => {
  const plugins = [
    {
      beforeInit(chart, legend, option) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          if (options?.legendBottomMargin) {
            return (this.height = this.height + options?.legendBottomMargin);
          } else {
            return (this.height += 20);
          }
        };
      },
    },
    {
      afterDatasetsDraw: (chart, args, pluginOptions) => {
        if (options.outerLabel) {
          const {
            ctx,
            data,
            chartArea: { top, left, width, height },
          } = chart;
          ctx.save();

          const halfWidth = width / 2 + left;
          const halfHeight = height / 2 + top;

          data.datasets[0].data.forEach((datapoint, index) => {
            const { x, y } = chart.getDatasetMeta(0).data[index].tooltipPosition();
            ctx.font = "500 12px Poppins";
            // ctx.fillStyle = data.datasets[0].backgroundColor[index];
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            const label = chartdata.labels[index];
            const xLine = x >= halfWidth ? x + 55 : x - 55;
            const yLine = y >= halfHeight ? y + 55 : y - 55;
            const extraLine = x >= halfWidth ? 40 : -40;

            let value = datapoint;
            if (options?.plugins?.datalabels?.transformExpression?.length > 0) {
              let formatter = Intl.NumberFormat("en", { notation: "compact" });
              try {
                value = eval(options?.plugins?.datalabels?.transformExpression);
              } catch (e) {
                console.log(e);
              }
            }

            const textWidth = ctx.measureText(value).width;
            const textWidthPosition = x >= halfWidth ? textWidth : -textWidth;
            const newTextWidth = textWidthPosition / 1.75;
            ctx.lineWidth = 2;
            ctx.strokeStyle = data.datasets[0].backgroundColor[index];

            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(xLine, yLine);
            ctx.lineTo(xLine + extraLine, yLine);
            ctx.stroke();
            ctx.fillText(value, xLine + extraLine + newTextWidth, yLine);
          });
        }
      },
    },
  ];

  return <Doughnut data={chartdata} options={options} plugins={plugins} />;
};

export default React.memo(DoughnutChart);
