import {
    SHOW_ORG_USER_UPDATE_DIALOG,
    HIDE_ORG_USER_UPDATE_DIALOG,
    START_ORG_USER_GET,
    END_ORG_USER_GET,
    START_ORG_USER_UPDATE,END_ORG_USER_UPDATE
} from "./action";

import { Types } from "./types"

const initState   =     {
    showUserEditDialog:false,
    updateUserId:null,
    user:null,
    getUserProg:false,
    updateUserProg:false,

    addOrgSettingsInprogress: false,
    addOrgSettingsSuccess: null,
    addOrgSettingsFailure: null,

    fetchAllOrgSettingsInprogress: false,
    fetchAllOrgSettingsSuccess: null,
    fetchAllOrgSettingsFailure: null,
}

export  function orgUserReducer(state=initState,action){
    switch(action.type){
        case SHOW_ORG_USER_UPDATE_DIALOG:
            return {
                ...state,
                showUserEditDialog:true,
                updateUserId:action.payload.userId,
            };
        case HIDE_ORG_USER_UPDATE_DIALOG:
            return {
                ...state,
                showUserEditDialog:false,
                updateUserId:null,
            };
        case START_ORG_USER_GET:
            return {
                ...state,
                getUserProg:true,
                user:null,
            };
        case END_ORG_USER_GET:
            return {
                ...state,
                getUserProg:false,
                user:action.payload.success,
            };
        case START_ORG_USER_UPDATE:
            return {
                ...state,
                updateUserProg:true,
            }
        case END_ORG_USER_UPDATE:
            return {
                ...state,
                updateUserProg:false,
                user:action.payload.success
            }

        case Types.START_ADD_ORG_SETTINGS:
            return {
                ...state,
                addOrgSettingsInprogress: true,
                addOrgSettingsSuccess: null,
                addOrgSettingsFailure: null,            
            }
        case Types.END_ADD_ORG_SETTINGS:{
            let success = action.payload.success;
            let error = action.payload.error;
            let result = {}
            
            var currentSetting = {};
            if(state.fetchAllOrgSettingsSuccess !== null){
                state.fetchAllOrgSettingsSuccess.forEach((i)=>{
                    currentSetting[i.ID] = i
                });
            }
            
            if(success !== null){
                success.forEach((i)=>{
                    currentSetting[i.ID] = i
                });
            }
            result.addOrgSettingsInprogress = false;
            if(error){
                result.addOrgSettingsSuccess = null;
                result.addOrgSettingsFailure = error.message;
            }else{
                result.addOrgSettingsSuccess = success;
                result.addOrgSettingsFailure = null;
                result.fetchAllOrgSettingsSuccess=Object.values(currentSetting)
            }
            return {
                ...state,
                ...result
            }
        }


        case Types.START_FETCH_ALL_ORG_SETTINGS:
            return {
                ...state,
                fetchAllOrgSettingsInprogress: true,
                fetchAllOrgSettingsFailure: null,            
            }
        case Types.END_FETCH_ALL_ORG_SETTINGS:{
            let success = action.payload.success;
            let error = action.payload.error;
            let result = {}
            result.fetchAllOrgSettingsInprogress = false;
            if(error){
                result.fetchAllOrgSettingsSuccess = null;
                result.fetchAllOrgSettingsFailure = error.message;
            }else{
                result.fetchAllOrgSettingsSuccess = success;
                result.fetchAllOrgSettingsFailure = null;
            }
            return {
                ...state,
                ...result
            }
        }
            
        default:
            return state;
    }
}