import {
    START_EVALUATION_CART_FETCH,END_EVALUATION_CART_FETCH,
    START_EVALUATION_CART_CREATE,END_EVALUATION_CART_CREATE,
    START_EVALUATION_CART_RM_PRODUCT,END_EVALUATION_CART_RM_PRODUCT,
    SHOW_EVALUATION_PRODUCT_UPDATE_DIALOG,HIDE_EVALUATION_PRODUCT_UPDATE_DIALOG,
    START_EVALUATION_PRODUCT_UPDATE,END_EVALUATION_PRODUCT_UPDATE
} from "./action";

const initState   =     {
    draftEvaluationFetchProgress:false,
    evaluation:null,
    cartCreationProgress:false,
    removeProductInProgress:false,
    updateProductInProgress:false,
    showProductUpdateDialog:false,
    productToUpdate:null,
    evaluationId:null,
}

export  function evaluationCartReducer(state=initState,action){
    switch(action.type){

        case START_EVALUATION_CART_FETCH:
            return {
                ...state,
                draftEvaluationFetchProgress:true,
            };
        case END_EVALUATION_CART_FETCH:
            var success     =   action.payload.success;
            var evaluation  =   null;
            if(success !== null){
                evaluation = success;
            }
            return {
                ...state,
                draftEvaluationFetchProgress:false,
                evaluation:evaluation
            }
        case START_EVALUATION_CART_CREATE:
            return {
                ...state,
                cartCreationProgress:true,
            }
        case END_EVALUATION_CART_CREATE:
            var _evaluation  =   null;
            var csuccess     =   action.payload.success;
            if(csuccess !== null){
                _evaluation = csuccess;
            }
            return {
                ...state,
                cartCreationProgress:false,
                evaluation:_evaluation
            }
        case START_EVALUATION_CART_RM_PRODUCT:
            return {
                ...state,
                removeProductInProgress:true,
            }
        case END_EVALUATION_CART_RM_PRODUCT:
            var __evaluation  =   null;
            var __csuccess     =   action.payload.success;
            if(__csuccess !== null){
                __evaluation = __csuccess;
            }
            return {
                ...state,
                removeProductInProgress:false,
                evaluation:__evaluation
            }
        case SHOW_EVALUATION_PRODUCT_UPDATE_DIALOG:
            return {
                ...state,
                showProductUpdateDialog:true,
                productToUpdate:action.payload.product,
                evaluationId:action.payload.evaluationId,
                targetContainer:action.payload.targetContainer,
                forSeller:action.payload.forSeller
            };
        case HIDE_EVALUATION_PRODUCT_UPDATE_DIALOG:
            return {
                ...state,
                showProductUpdateDialog:false,
                productToUpdate:null,
                evaluationId:null,
                targetContainer:null,
                forSeller:null
            };
        case START_EVALUATION_PRODUCT_UPDATE:
            return {
                ...state,
                updateProductInProgress:true,
            }
        case END_EVALUATION_PRODUCT_UPDATE:
            var evaluationa  =   null;
            var csuccess_n     =   action.payload.success;
            if(csuccess_n){
                evaluationa = csuccess_n;
            }
            return {
                ...state,
                updateProductInProgress:false,
                evaluation:evaluationa
            }
        default:
            return state;
    }
}