import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Slide, Typography, Button, Grid, CircularProgress} from '@material-ui/core';
import {hideTeamVendorsDialog,fetchOrgGroupVendors} from "redux/org/group/action";
import Image from 'Components/Common/image.jsx';
import { withRouter } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import { showSnackBar } from "redux/snackbar/action";
import DefaultImage from "assets/images/evaluation/default.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    teamVendorShowDialogOpen: state.orgGroup.teamVendorShowDialogOpen,
    teamVendorShowDialogData: state.orgGroup.teamVendorShowDialogData,
    groupVendors: state.orgGroup.groupVendors,
    fetchGroupVendorTotal: state.orgGroup.fetchGroupVendorTotal,
    fetchGroupVendorProg: state.orgGroup.fetchGroupVendorProg,
    fetchGroupVendorError: state.orgGroup.fetchGroupVendorError,
});

const connectionActions = {
    hideTeamVendorsDialog: hideTeamVendorsDialog,
    fetchOrgGroupVendors: fetchOrgGroupVendors,
    showSnackBar: showSnackBar,
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent:{
        padding: theme.spacing(6, 8) +" !important"
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(1.4),
    },

    title2: {
        color: '#282D30',
        fontSize: theme.spacing(2.4),
        marginBottom: theme.spacing(2),
        display:'block',
        fontWeight: '500'
    },

    tableSection: {
        minHeight: 200,
        maxHeight: "calc(90vh - 250px)",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      },

    productItem: {
      minWidth: "107px",
      maxWidth: "107px",
      borderRadius: "10px",
      border: "2px solid #eeeeee",
      margin: '0.5rem',
      '& > img': {
        width: "auto",
        height: "55px",
        maxWidth: "90%",
        objectFit: "contain",
        margin: "0 auto",
        display: "block",
      }
    },
    productHeader:{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    center: {
        textAlign: "center",
        marginTop: "1rem",
    },
    productName: {
      fontSize: '0.83em',
      fontWeight: '600',
      textAlign: 'center'
    },
    noVendor: {
        textAlign: 'center',
        width: '100%',
        fontSize: '1.2rem',
        marginTop: '3rem',
    },
    arrow: {
        paddingLeft: 5
    },
    progress: {
        textAlign: 'center',
        paddingTop: 15
    },
    buttonSection: {
        display: 'flex',
        justifyContent: "end",
        marginTop: 40
    },
});

class TeamVendorShowDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          error: '',
          page: 0,
          pageSize: 25
        }
    }

    componentDidMount(){
        console.log(this.props.teamVendorShowDialogData,'teamVendorShowDialogData')
    }

    componentDidUpdate(prevProps){
        if(this.props.teamVendorShowDialogOpen === true && prevProps.teamVendorShowDialogOpen === false) {
            this.setState({'error': '' });
            this.props.fetchOrgGroupVendors(this.props.teamVendorShowDialogData.ID, 0, this.state.pageSize);
        }
        if(!this.props.fetchGroupVendorProg && prevProps.fetchGroupVendorProg) {
            if(this.props.fetchGroupVendorError){
                this.props.showSnackBar('Failed to fetch vendors', "error", 3000);
            }
        }
    }

    nextFetchData = () => {
        let vendors = this.props.groupVendors || [];
        if(vendors.length !== this.props.fetchGroupVendorTotal){
            this.setState({ page: this.state.page + 1 }, () => {
            this.props.fetchOrgGroupVendors(
                this.props.teamVendorShowDialogData.ID,
                this.state.page,
                this.state.pageSize,
            );
            });
        }
      };


    render() {
        const classes = this.props.classes;
        let vendors = this.props.groupVendors || [];

        return <Dialog 
                    onClose={this.props.showTeamVendorsDialog} 
                    aria-labelledby="add-upload-dialog" 
                    open={this.props.teamVendorShowDialogOpen}
                    TransitionComponent={Transition}
                    disablecenterClick={true}
                    fullWidth={true}
                    maxWidth={"lg"}
                    scroll={"body"}
                    >
                    <DialogContent classes={{root:classes.dialogContent}}>
                      <div className={classes.productHeader}>
                        <Typography variant={"h6"} className={classes.title}>{this.props.teamVendorShowDialogData?.Name} Vendors</Typography>
                        {!this.props.fetchGroupVendorProg && <Typography variant={"caption"} className={classes.title2}>{vendors.length} {vendors.length>1?'Vendors':'Vendor'} </Typography>}
                      </div>
                      
                      
                      <div className={classes.tableSection} id="scrollableDiv12">
                        <InfiniteScroll
                            dataLength={vendors.length}
                            next={this.nextFetchData}
                            style={{ display: "flex", flexDirection: "column",overflowY: "hidden", padding: '1em'}} //To put endMessage and loader to the top.
                            hasMore={true}
                            scrollableTarget="scrollableDiv12"
                        >
                             
                            {this.props.fetchGroupVendorProg && this.state.page === 0 ? (
                                <div className={classes.center}>
                                <CircularProgress />
                                </div>
                            ) : (
                                <Grid container spacing={3}>
                                    {vendors.length> 0? vendors.map((item,index)=>(
                                        <Grid item className={classes.productItem} key={index}>
                                            <Image src={item.logo && item.logo.length > 0 ? item.logo : DefaultImage} alt={item.name} />
                                            <p className={classes.productName}>
                                                {item.name}
                                            </p>
                                        </Grid>
                                    )): <div className={classes.noVendor}>No vendor found</div>}  
                                </Grid> 
                            )}

                                {this.props.fetchGroupVendorProg && this.state.page !== 0 &&
                                <div className={classes.center}>
                                    <CircularProgress />
                                </div>
                                }
                            </InfiniteScroll>
                        
                      </div> 
                      <div className={classes.buttonSection}>
                        <Button variant="outlined" color="secondary" className={classes.button} onClick={()=>{
                            this.props.hideTeamVendorsDialog();
                            this.setState({page: 0});
                        }}>Cancel</Button>
                          &nbsp;
                          &nbsp;
                        <Button variant="contained" color="secondary" onClick={()=>{
                            this.props.hideTeamVendorsDialog();
                            this.props.history.push(`/app/vendors/org-vendors?updateTeamVendors=true&teamId=${this.props.teamVendorShowDialogData?.ID}`);
                            }}>
                            Add or Update Vendors
                        </Button>
                        </div>    
                    </DialogContent>
                </Dialog>
    }
}

export default connector(compose(
    withRouter,withStyles(styles)
)(TeamVendorShowDialog));