import React from 'react';
import { createStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import AppBarV2 from "../../../AppBarV2";
import { Grid } from "@material-ui/core";
import CardAction from "Components/Common/CardAction";
import { templates } from './Constant';
import CommonCss from 'commonCss';
import classnames from "classnames";

const styles = (theme) => createStyles({
    "actionContainer": {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6),

        "& [class*='MuiGrid-item']": {
            padding: theme.spacing(2)
        }
    },
    "cardAction": {
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        height: '100%'
    },
    "Primary": {
        textAlign: 'center',
        fontSize: theme.spacing(2.4),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5)
    },
    "Secondary": {
        textAlign: 'center',
    },
    "Content": {
        padding: theme.spacing(2, 2, 0, 2),
        flex: 'inherit'
    },
    rootContainer:CommonCss.rootContainer
});

const Templates = ({ classes }) => (<div>
    <AppBarV2 title="Templates" withBack={true} />
    <div className={classnames(classes.actionContainer,classes.rootContainer)}>
        <Grid container spacing={3}>
            {templates.map((e, index) => (
                <Grid item lg={3} md={3} key={index}>
                    <CardAction
                        link={e.link}
                        image={e.image}
                        primary={e.primary}
                        secondary={e.secondary}
                        activeImage={e.activeImage}
                        cardActionClass={classes.cardAction}
                        primaryClass={classes.Primary}
                        secondaryClass={classes.Secondary}
                        contentClass={classes.Content}
                    />
                </Grid>
            ))}
        </Grid>
    </div>
</div>
)

export default React.memo(withStyles(styles)(Templates));