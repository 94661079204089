import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import EvaluationEmptyState from "assets/images/evaluation_empty_state.svg";
import Image from 'Components/Common/image'
import { Button } from '@material-ui/core';
import EvaluationBar from "../../Common/EvaluationBar";

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  DashboardNoDataLayout: {
    width: 'calc(100% - 10px)',
    minHeight: '92vh',
    textAlign: 'center',
    background: '#ffffff',
    padding: '23px',
    borderRadius: '6px',
    '& button': {
      margin: '20px 0'
    }
  },
  button: {
    padding: '6px 8px',
    fontSize: 13,
    fontWeight: 400,
    width: theme.spacing(30),
    marginRight: 15,
    // width: '100%'
  },
});

class Dummy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  render() {

    const classes = this.props.classes;

    return (
      <div>
        <EvaluationBar
          isShowCollabrations={false}
          isShowEvalDetails={false}
          isWorkFlow={this.props.isWorkFlow}
          isShowViewBtns={false}
        />
        <div className={classes.DashboardNoDataLayout}>
          <div style={{ height: 220 }}>
            <Image src={EvaluationEmptyState} />
          </div>
          <h2>Evaluation in progress</h2>
          <Button variant="outlined" color="secondary" className={classes.button}
            onClick={() => { this.props.history.push('/app/home') }}
          >Home</Button>
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Dummy));
