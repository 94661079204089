import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "underscore";
import { compose } from "recompose";
import CommonCss from 'commonCss';
import { withRouter } from "react-router-dom";
import classnames from 'classnames';
import CreatableSelect from "react-select/creatable";
import {
  Dialog, DialogContent, DialogActions, Slide, DialogTitle, TextField, CircularProgress,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import SqureButton from 'Components/Common/SqureButton';
import { ProductOption, ProductSearchFunction } from 'Components/Common/SelectProductOption'
import {
  searchUsers,
} from "redux/usersSettings/action";
import { fetchOrgGroup, addOrgGroup } from "redux/org/group/action";
import { addTeamMembers } from "redux/okta/action";
const connectedProps = (state) => ({
  usersSettings: state.usersSettings.users,
  isUserProgress: state.usersSettings.isUserProgress,
  groups: state.orgGroup.groups,
  fetchGroupProg: state.orgGroup.fetchGroupProg,
  addGroupProg: state.orgGroup.addGroupProg,
  addTeamMemberProgress: state.okta.addTeamMemberProgress,
  addTeamMemberError: state.okta.addTeamMemberError,
  fetchAddGroupAPIState: state.orgGroup.fetchAddGroupAPIState,
});

const connectionActions = {
  fetchUsers: searchUsers,
  fetchOrgGroup: fetchOrgGroup,
  addOrgGroup: addOrgGroup,
  addTeamMembers: addTeamMembers
};

var connector = connect(connectedProps, connectionActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => createStyles({
  ...CommonCss.ProductDetails,
  formControlDialog: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  formControl: {
    ...CommonCss.formControl,
  },
  formGroup: {
    marginTop: 5,
    marginBottom: 80
  },
  formGroupDialog: {
    marginTop: 5,
    marginBottom: 16,
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  creatable: {
    padding: 0,
  },
  dialog: {
    '& [class*="MuiDialog-paper"]':{
      overflowY: 'unset',
    }
  }
});

class AddUserDialog extends Component {
  constructor(props) {
    super()
    this.state = {
      isNew: false,
      name: "",
      description: "",
      email: "",
      user: null,
      error: "",
    }
  }

  componentDidMount() {
    if (this.props.type !== 7) {
      this.fetchOrgGroups()
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.isOpen && !prevProps.isOpen && (this.props.type === 7)){
      this.fetchUsersList();
    }
    if (!this.props.addGroupProg && prevProps.addGroupProg) {
      if (this.props.fetchAddGroupAPIState === true) {
        this.fetchOrgGroups()
      }
    }

    if (!this.props.addTeamMemberProgress && prevProps.addTeamMemberProgress) {
      if (this.props.addTeamMemberError === null) {
        this.fetchUsersList()
      } else {
        if (this.props.addTeamMemberError.message !== null) {
          this.setState({ error: this.props.addTeamMemberError.message })
        } else {
          this.setState({ error: 'Something went worng, Please try again!' })
        }
      }
    }

    if (!this.props.isUserProgress && prevProps.isUserProgress) {
      if (this.state.isNew && this.state.name && this.state.email) {
        this.props.onSubmit({
          value: this.state.name,
          name: this.state.name,
          label: this.state.name,
          email: this.state.email,
        })
        this.handleOnClose()
      }
    }

    if (!this.props.fetchGroupProg && prevProps.fetchGroupProg) {
      if (this.state.isNew && this.state.name && this.state.description) {
        this.props.onSubmit({
          value: this.state.name,
          name: this.state.name,
          label: this.state.name,
          description: this.state.description,
        })
        this.handleOnClose()
      }
    }

  }

  fetchUsersList = (query = '') => {
    this.props.fetchUsers(0, 20, query);
  }

  fetchOrgGroups = (value = "") => {
    this.props.fetchOrgGroup(0, 50, value);
  };

  handleOnClose = () => {
    this.setState({
      isNew: false,
      name: "",
      description: "",
      email: "",
      user: null,
    })
    this.props.onClose()
  }

  handleSubmit = (e) => {
    this.props.onSubmit(this.state.user)
    this.handleOnClose()
  }

  handleSubmitNewUser = () => {
    if (this.props.type === 7) {
      this.props.addTeamMembers([{
        name: this.state.name,
        group_ids: [],
        email: this.state.email,
        role: "OrgUser",
        checked: true
      }]);
    } else {
      this.props.addOrgGroup(this.state.name, this.state.description);
    }
  }

  render() {
    const {
      classes,
      isOpen,
      type,
      usersSettings,
      groups,
    } = this.props
    const { isNew, description, email, user, error } = this.state
    let optionsList = []
    if (type === 7) {
      optionsList = usersSettings?.map(m => ({
        value: m.ID,
        name: m.Name,
        label: m.Name,
        email: m.Email,
      }))
    } else {
      optionsList = groups.map((m) => ({
        value: m.ID,
        label: m.Name,
        name: m.Name,
        description: m?.Email
      }))
    }

    return (
      <Dialog
        onClose={this.handleOnClose}
        aria-labelledby="AddUserDialog"
        open={isOpen}
        TransitionComponent={Transition}
        scroll={"body"}
        fullWidth={true}
        className={classes.dialog}
        id={`productBLockDialog-add-user`}
      >
        <DialogTitle id={`form-dialog-title-add-user`}>
          {type === 7 && 'Add User'}
          {type === 8 && 'Add Group'}
        </DialogTitle>
        <DialogContent style={{overflowY:'unset'}}>
          <div style={{ marginBottom: 20 }}>
            <CreatableSelect
              isClearable
              autoFocus={true}
              isSearchable={true}
              isDisabled={isNew}
              noOptionsMessage={() => "No options"}
              options={optionsList}
              className={classnames(classes.creatable)}
              classNamePrefix="select"
              placeholder={type === 7 ? "Search Users" : "Search Teams"}
              onInputChange={(e) => {
                if (e) {
                  if (type === 7) {
                    this.fetchUsersList(e);
                  } else {
                    this.fetchOrgGroups(e)
                  }
                }
              }}
              onChange={(e) => {
                this.setState({ user: e })
              }}
              onCreateOption={(e) => {
                this.setState({
                  isNew: true,
                  name: e,
                  user: null
                })
              }}
              formatOptionLabel={ProductOption}
              filterOption={ProductSearchFunction}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
            {isNew && (
              <>
                <br />
                {type === 8 ? (
                  <div className={classes.formContainer}>
                    <TextField
                      fullWidth
                      InputProps={{ disableUnderline: true }}
                      className={classes.formControlDialog}
                      placeholder="Enter Description"
                      onChange={(event) => this.setState({ description: event.target?.value })}
                      value={description}
                    />
                  </div>
                ) : (
                  <div className={classes.formContainer}>
                    <TextField
                      fullWidth
                      InputProps={{ disableUnderline: true }}
                      className={classes.formControlDialog}
                      placeholder="Enter Email"
                      onChange={(event) => {
                        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        let error = ""
                        if (!re.test(String(event.target?.value).toLowerCase())) {
                          error = 'Please enter valid email address.'
                        }
                        this.setState({ error, email: event.target?.value })
                      }}
                      value={email}
                    />
                    {error}
                  </div>
                )}
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {this.props.addGroupProg ||
            this.props.addTeamMemberProgress ||
            this.props.fetchGroupProg ||
            this.props.isUserProgress ? (
            <CircularProgress  style={{margin: '0 auto'}} />
          ) : (
            <div style={{ padding: '10px 24px' }}>
              <SqureButton
                variant={"outlined"}
                onClick={() => {
                  if (this.state.isNew) {
                    this.setState({ isNew: false, name: "", email: "", description: "" })
                  } else {
                    this.handleOnClose()
                  }
                }}
              >
                Cancel
              </SqureButton>
              &nbsp; &nbsp; &nbsp;
              <SqureButton
                variant={"contained"}
                disabled={
                  error.length > 0
                    ? true
                    : isNew
                      ? !(email.length > 0 || description.length > 0)
                      : _.isEmpty(user)
                }
                onClick={(e) => {
                  if (isNew) {
                    this.handleSubmitNewUser()
                  } else {
                    this.handleSubmit(e)
                  }
                }}
              >
                Save
              </SqureButton>
            </div>
          )}
        </DialogActions>
      </Dialog >
    )
  }
}

export default connector(
  compose(withRouter, withStyles(styles))(AddUserDialog)
);
