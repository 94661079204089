import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { connect } from "react-redux";
// redux
import { showAddProductManuallyDialog } from "redux/productsSettings/action";
import { isEnabledReview } from "util/Common";

// Component styles
const useStyles = makeStyles(theme => ({
    btnAddProduct: {
        background: "#3C3C3C",
        borderRadius: 5,
        color: "#fff",
        fontSize: theme.spacing(1.9),
        minHeight: 32,
        minWidth: 140,
        marginRight: 10,
        "&:hover": {
            background: "#3C3C3C",
            color: '#fff',
        },
    }
}));

const connectedProps = (state) => ({
    orgUser:state.orgUser,
    userType: state.authUser.user?.Role,
});

const connectionActions = {
    showAddProductManuallyDialog: showAddProductManuallyDialog,
};

var connector = connect(connectedProps, connectionActions);

function AddProductButton(props) {

    const classes = useStyles();
    var showAddProduct = false;
    const EnableAddProductForUserCheck = isEnabledReview(props?.orgUser?.fetchAllOrgSettingsSuccess, 'EnableAddProductForUserCheck')
    if(props.userType === "OrgUser"){
      if(EnableAddProductForUserCheck){
        showAddProduct = true;  
      }
    } else {
      showAddProduct = true;
    }
    if(showAddProduct === false){
        return null;
    }
    return <>
        <Button className={classes.btnAddProduct} onClick={()=>{props.showAddProductManuallyDialog();}}>
            + {props.title}
        </Button>
    </>;
}
 
export default connector(AddProductButton);
