import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import {
    CircularProgress, Dialog, DialogContent, Button, Slide, Typography, Grid, Divider
} from '@material-ui/core';
import { hideFeatureDialog, updateEvaluationMetaData, fetchEvaluationSelectedFeatures, updateEvaluationSelectedFeatures, deleteEvaluationSelectedFeatures } from "../../../../../../redux/evaluation/metadata/action";
import { fetchEvaluationCart } from "../../../../../../redux/evaluation/cart/action";
import Alert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router-dom';
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import CheckIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { fetchSubCategoryFeature } from "redux/product/subcategory/action";
import { fetchCriteria } from "redux/evaluation/criteria/action";
import OutlinedSelectInput from "../../../../../Common/Input/OutlinedSelectInput";
import UserGroup from "../../../../../Common/UserGroup/index";
import SqureButton from '../../../../../Common/SqureButton';
import OutlinedInput from "../../../../../Common/Input/OutlinedInput";
import { addRequirement } from "redux/evaluation/criteria/action";
import { showSnackBar } from "redux/snackbar/action";
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.evaluationMetaData.showFeatureDialog,
    evaluationId: state.evaluationMetaData.evaluationId,
    criteriaId: state.evaluationMetaData.criteriaId,
    evaluationSelectedFeaturesError: state.evaluationMetaData.evaluationSelectedFeaturesError,
    evaluationSelectedFeatures: state.evaluationMetaData.evaluationSelectedFeatures,
    evaluationSelectedFeaturesProgress: state.evaluationMetaData.evaluationSelectedFeaturesProgress,
    evaluationUpdateSelectedFeaturesProgress: state.evaluationMetaData.evaluationUpdateSelectedFeaturesProgress,
    evaluationDeleteSelectedFeaturesProgress: state.evaluationMetaData.evaluationDeleteSelectedFeaturesProgress,
    subCategoryFeatures: state.productSubCategory.subCategoryFeatures,
    evaluation: state.evaluationMetaData.evaluation,
    fetchSubCategoryFeaturesProgress: state.productSubCategory.fetchSubCategoryFeaturesProgress,
    addRequirementProgress: state.evaludationCriteria.addRequirementProgress,
    errorRequirementAdd: state.evaludationCriteria.errorRequirementAdd,
});

const connectionActions = {
    hideDialog: hideFeatureDialog,
    updateDetails: updateEvaluationMetaData,
    fetchEvaluationCart: fetchEvaluationCart,
    fetchSubCategoryFeature: fetchSubCategoryFeature,
    fetchEvaluationSelectedFeatures: fetchEvaluationSelectedFeatures,
    updateEvaluationSelectedFeatures: updateEvaluationSelectedFeatures,
    deleteEvaluationSelectedFeatures: deleteEvaluationSelectedFeatures,
    fetchCriteria: fetchCriteria,
    addRequirement: addRequirement,
    showSnackBar: showSnackBar
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: "0px !important"
    },
    title: {
        fontSize: theme.spacing(2.5),
        marginBottom: theme.spacing(2)
    },
    helptext: {
        fontSize: theme.spacing(1.8)
    },
    alerts: {
        marginTop: theme.spacing(2)
    },
    form: {
        marginTop: theme.spacing(2),
        // width:'60%',
    },
    actions: {
        marginTop: theme.spacing(3)
    },
    loader: {
        textAlign: "center",
        padding: theme.spacing(10, 0)
    },
    dialog: {
        "& [class*='MuiOutlinedInput-input']": {
            fontSize: theme.spacing(2.2)
        }
    },
    datePickers: {
        "& [class*='MuiFormLabel-root']": {
            color: '#475867'
        },
        "& [class*=MuiOutlinedInput-root]": {
            minHeight: 40,
            color: '#282D30'
        }
    },
    stepSection: {

    },
    stepLeftSection: {
        background: '#20253A',
        boxShadow: '4px 0px 4px #2B2D3703',
        minWidth: 278,
        width: 278
    },
    stepRightSection: {
        padding: '50px 50px 0 50px',
        width: '100%'
    },
    outlined: {
        color: "#707070",
        padding: '6px 26px',
        borderColor: "#707070",
        "&:hover": {
            color: "#707070",
            borderColor: "#707070",
        }
    },
    contained: {
        color: '#fff',
        padding: '6px 26px',
        backgroundColor: "#3C3C3C",
        "&:hover": {
            backgroundColor: "#3C3C3C",
        }
    },
    image: {
        width: theme.spacing(3.5),
        marginRight: 20,
        marginTop: 5,
        cursor: 'pointer'
    },
    headSection: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    stepHead: {
        color: '#282D30',
        fontSize: 18,
        margin: 0,
        fontWeight: 500,
        marginBottom: 10
    },
    stepSubHead: {
        color: '#4B4B4B',
        fontSize: 12,
        margin: 0,
        paddingBottom: 20
    },

    textSection: {
        marginTop: 40,
        lineHeight: 1.5
    },
    head: {
        color: '#282D30',
        fontSize: 14,
        marginBottom: 15,
        fontWeight: 500
    },
    featureLabel: {
        border: '1px solid #4A87F8',
        borderRadius: 20,
        padding: '7px 16px',
        marginRight: 25,
        marginTop: 18,
        color: '#4A87F8',
        display: 'inline-flex',
        fontSize: 13,
        cursor: 'pointer',
        transition: 'all'
    },
    iconEle: {
        fontSize: 19,
        marginLeft: 10
    },
    selectedLabel: {
        background: '#4A87F8',
        color: '#fff',
        cursor: 'default'
    },
    removeIcon: {
        display: 'inline-flex',
        cursor: 'pointer',
        marginLeft: 10,
        "& [class*=iconEle]": {
            marginLeft: 0,
        }
    },
    features: {
        overflow: 'auto',
        height: 200,
        marginBottom: 30
    },
    radioBox: {
        width: 13,
        height: 13,
        maxWidth: 13,
        minWidth: 13
    },
    previewInnerSection: {
        maxHeight: 650,
        overflow: 'overlay',
        paddingRight: 10
    },
    backdrop: {
        marginTop: 30,
        height: 100,
        textAlign: 'center'
    },
    addNewRequirements: {
        textAlign: 'center',
        fontWeight: 600
    },
    modalTitle: {
        fontSize: theme.spacing(2.4),
        marginBottom: theme.spacing(3),
        textAlign: "center",
    },
    input: {
        marginBottom: theme.spacing(3)
    },
    scheduleTitle: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 600,
        fontSize: theme.spacing(2)
    },
    scheduleTitle1: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 600,
        fontSize: theme.spacing(1.8)
    },

    dialogAction: {
        textAlign: "center",
        marginBottom: 30
    },
    addNewButton: {
        fontWeight: 600,
        fontSize: theme.spacing(2)
    },
    actionBtn: {
        minWidth: 110
    },
    nameYour: {
        "& [class*=MuiInputBase-root]": {
            maxHeight: 40,
            minHeight: 40,
            "& input": {
                fontSize: theme.spacing(1.8)
            }
        },
    },
    nameOfCriteria: {
        "& > div": {
            marginBottom: 0
        },
    },
    priority: {
        display: "flex",
        justifyContent: 'flex-end',
        "& > div": {
            alignItems: "baseline",
        },
        "& [class*=MuiSelect-outlined]": {
            color: "#707070",
            fontSize: theme.spacing(1.8),
            paddingLeft: 23,
            "&:after": {
                content: "''",
                width: 7,
                height: 7,
                position: 'absolute',
                left: 9,
                borderRadius: '50%',
                top: '50%',
                transform: 'translate(0px, -50%)'
            },
        },
        "&.low [class*=MuiSelect-outlined]:after": {
            background: 'green'
        },
        "&.medium [class*=MuiSelect-outlined]:after": {
            background: 'orange'
        },
        "&.high [class*=MuiSelect-outlined]:after": {
            background: 'red'
        }
    },
    scoring: {
        "& > div": {
            alignItems: "baseline",
            marginBottom: 0
        },
        "& [class*=MuiSelect-outlined]": {
            paddingLeft: 10
        }
    },
    yourQuestion: {
        "& [class*=MuiInputBase-root]": {
            padding: 0,
            fontSize: theme.spacing(1.8),
            minHeight: 120,
            color: "#707070",
            lineHeight: 1.2,
            "& textarea": {
                minHeight: 100,
            }
        }
    },
    addCriteriaDatePicker: {
        "& .react-datepicker__input-container > div": {
            display: 'flex',
            minWidth: 300,
            alignItems: 'baseline',
            "& [class*=MuiInputBase-root]": {
                maxHeight: 33,
                minHeight: 33,
                width: 166,
                "& input": {
                    fontSize: theme.spacing(1.8),
                    // width: 107,
                    textAlign: "center"
                }
            },
            "& [class*=MuiFormLabel-root]": {
                marginBottom: 0,
                marginRight: 29
            }
        },
        "[class*=MuiMenuItem-root]": {
            color: "#707070",
            fontSize: theme.spacing(2.2)
        }
    },
    newFeature: {
        padding: theme.spacing(4, 6) + " !important",
    }
});

class FeatureDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            name: "",
            name_error: "",
            selectedFeatureList: [],
            featureSize: 50,
            featurePage: 0,
            listOfFeature: [],
            featureQuery: '',
            criteria_schedule: null,
            criteria_schedule_error: "",
            colloborators: [],
            requirements: [],
            addingFeatureId: 0
        }
    }

    cleanState = () => {
        this.setState({
            error: "",
            success: "",
            name: "",
            name_error: "",
            criteria_schedule: null,
            criteria_schedule_error: "",
            colloborators: [],
            requirements: []
        });
    }

    componentDidMount() {
    }

    fetchFeatures = () => {
        this.props.fetchSubCategoryFeature(this.props.evaluation.SubcategoryID, this.state.featurePage, this.state.featureSize, this.state.featureQuery);
    }

    selectFeature = (index, item) => {
        this.setState({
            addingFeatureId: item.ID
        }, () => {
            this.props.updateEvaluationSelectedFeatures(this.props.evaluation.ID, { feature_id: item.ID });
        })
    }

    removeFeature = (index, item) => {
        this.props.deleteEvaluationSelectedFeatures(this.props.evaluation.ID, item.ID);
    }

    removeDuplicateFeature = () => {
        const evaluationSelectedFeatures = this.props.evaluationSelectedFeatures || [];
        const subCategoryFeatures = this.props.subCategoryFeatures;

        for (let i = 0; i < subCategoryFeatures.length; i++) {
            subCategoryFeatures[i]['visible'] = true;
            for (let selecteItem of evaluationSelectedFeatures) {
                if (selecteItem.ID === subCategoryFeatures[i].ID) {
                    subCategoryFeatures[i]['visible'] = false;
                }
            }
        }

        this.setState({
            listOfFeature: subCategoryFeatures,
        }) 
    }

    componentDidUpdate(prevProps) {
        if (this.props.fetchSubCategoryFeaturesProgress === false && prevProps.fetchSubCategoryFeaturesProgress === true) {
            this.removeDuplicateFeature();
        }

        if (((!this.props.evaluationDeleteSelectedFeaturesProgress && prevProps.evaluationDeleteSelectedFeaturesProgress) || (!this.props.evaluationSelectedFeaturesProgress && prevProps.evaluationSelectedFeaturesProgress))) {
            this.removeDuplicateFeature();
            this.setState({ 
                selectedFeatureList: this.props.evaluationSelectedFeatures || [],
                addingFeatureId:0 
            })
        }

        if (this.props.isOpen === true && prevProps.isOpen === false) {
            this.setState({ listOfFeature: [], subCategoryId: '', selectedFeatureList: [], featurePage: 0 })
            if (this.props.evaluationId !== null) {
                this.setState({
                    error: "",
                    success: "",
                })
                this.fetchFeatures();
                this.props.fetchEvaluationSelectedFeatures(this.props.evaluationId);
            }
        }

        if (this.props.isOpen === true && this.props.addRequirementProgress === false && prevProps.addRequirementProgress === true) {
            if (!this.props.errorRequirementAdd) {
                this.cleanState();
                this.changeStep();
                this.props.showSnackBar("Feature Addedd", "success", 3000);
            } else {
                if (this.props.errorRequirementAdd?.message !== undefined) {
                    this.setState({
                        "error": "Failed to add Feature Details."
                    })
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }

    }

    changeStep = () => {
        var componentId = this.props.match.params.component_id !== undefined ? this.props.match.params.component_id : 0;
        this.props.fetchCriteria(this.props.evaluationId, true, componentId);
        this.props.hideDialog();
    }

    addExtraRequirement = () => {
        var requirements = this.state.requirements;
        requirements.push({
            'name': '',
            'name_error': '',
            'priority': '1',
            'scoring_type': '1',
            'description': '',
            'description_error': '',
            'colloborators': [],
        });
        this.setState({
            requirements: requirements
        });
    }

    cleanError = () => {
        var requirements = this.state.requirements;
        requirements.forEach((r) => {
            r.name_error = "";
            r.description_error = "";
        })
        this.setState({
            name_error: "",
            criteria_schedule_error: "",
            requirements: requirements
        })
    }

    submitResponse = () => {
        if (this.state.requirements.length === 0) {
            this.changeStep();
            return;
        }
        this.cleanError();
        var requirements = this.state.requirements;
        for (var i = 0; i < requirements.length; i++) {
            var requirement = requirements[0];
            var rName = requirement.name;
            if (rName.trim().length === 0) {
                requirement.name_error = "Please enter name."
                this.setState({
                    requirements: requirements
                });
                return;
            }

            var rDesc = requirement.description;
            if (rDesc.trim().length === 0) {
                requirement.description_error = "Please enter question."
                this.setState({
                    requirements: requirements
                });
                return;
            }
        }
        this.state.requirements.map((r) => {
            setTimeout(() => {
                this.props.addRequirement(this.props.evaluationId, this.props.criteriaId, {
                    name: r.name,
                    description: r.description,
                    priority: parseInt(r.priority),
                    scoring_type: parseInt(r.scoring_type),
                    colloborators: r.colloborators.map((c) => {
                        return c.ID
                    }),
                }, false)
            }, 50)
        })
    }

    addRequirementColloborator(index, user) {
        var requirements = this.state.requirements;
        var requirement = requirements[index];
        var colloborators = requirement.colloborators;
        var existing = colloborators.filter((u) => {
            if (u.ID === user.ID) {
                return true;
            }
            return false;
        })
        if (existing.length > 0) {
            return;
        }
        colloborators.push(user);
        this.setState({ requirements: requirements });
    }

    render() {
        const classes = this.props.classes;
        const { addingFeatureId } = this.state;
        var me = this;

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-featureDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={'md'}
            scroll="body"
            className={classes.dialog}
            id="featureDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.stepSection}>

                    <div className={classes.stepRightSection}>
                        <div className={classes.headSection}>
                            <div>
                                <p className={classes.stepHead}>Features </p>
                                <p className={classes.stepSubHead}>Select features that you care about.</p>
                            </div>
                        </div>
                        <div className={classes.textSection}>
                            <p className={classes.head}>Selected Features</p>
                            <div className={classes.alerts}>
                                {this.props.evaluationSelectedFeaturesError !== null && <Alert variant="filled" severity="error">{this.props.evaluationSelectedFeaturesError?.message}</Alert>}
                            </div>
                            <div className={classes.features}>
                                {this.state.selectedFeatureList && this.state.selectedFeatureList.map((element, index) => <span key={index} className={classnames(classes.featureLabel, classes.selectedLabel, 'animate__animated animate__backInUp animate__faster')}>{element?.Name} <span onClick={() => this.removeFeature(index, element)} className={classes.removeIcon}><CloseIcon className={classes.iconEle} /></span></span>)}
                                {this.props.evaluationSelectedFeaturesProgress === true && (
                                    <div className={classes.backdrop}>
                                        <CircularProgress />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={classes.textSection}>
                            <p className={classes.head}>List of Features</p>
                            <div className={classes.features} id="featuresList">
                                <InfiniteScroll
                                    scrollableTarget="featuresList"
                                    dataLength={this.state.listOfFeature?.length} //This is important field to render the next data
                                    next={() => {
                                        var me = this;
                                        this.setState({ featurePage: this.state.featurePage + 1 }, () => {
                                            me.fetchFeatures();
                                        });
                                    }}
                                    hasMore={
                                        this.state.listOfFeature?.length ===
                                        (this.state.featurePage + 1) * this.state.featureSize
                                    }
                                    endMessage={
                                        <p style={{ textAlign: "center", marginTop: 30 }}>
                                            {this.state.listOfFeature?.length === 0 && this.props.fetchSubCategoryFeaturesProgress === false && <span>No More Data</span>}
                                        </p>
                                    }
                                >
                                    {this.state.listOfFeature.map((element, index) => {
                                        return (element?.visible !== false && addingFeatureId !== element.ID &&
                                            <span key={index} className={classnames(classes.featureLabel, 'animate__animated animate__backInDown animate__faster')} onClick={() => {
                                                this.selectFeature(index, element)
                                            }}>
                                                {element.Name}
                                                <CheckIcon className={classes.iconEle} />
                                            </span>
                                        )
                                    })}
                                </InfiniteScroll>
                                {this.props.fetchSubCategoryFeaturesProgress === true && this.state.listOfFeature?.length === 0 && (
                                    <div className={classes.backdrop}>
                                        <CircularProgress />
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                    {
                        this.state.requirements.map((requirement, k) => {
                            return <React.Fragment key={k}>
                                <Divider />
                                <           div className={classes.newFeature}>
                                    <DialogContent classes={{ root: classes.newFeaturedialogContent }} >
                                        <Typography variant={"h2"} className={classes.modalTitle}>Add new Feature</Typography>
                                        <div className={classes.criteriaDetail}>
                                            <Grid container spacing={2}>
                                                <Grid item lg={3}>
                                                    <UserGroup onAdd={(user) => {
                                                        this.addRequirementColloborator(k, user)
                                                    }} users={requirement.colloborators} />
                                                </Grid>
                                                <Grid item lg={4}>
                                                    <div className={classnames(classes.priority, requirement.priority && requirement.priority === '1' ? 'low' : requirement.priority && requirement.priority === '2' ? 'medium' : requirement.priority && requirement.priority === '3' ? 'high' : '')}>
                                                        <OutlinedSelectInput
                                                            label="Priority"
                                                            labelStyle={"inline"}
                                                            // error={this.state.name_error.length > 0}
                                                            // helperText={this.state.name_error}
                                                            id="priority"
                                                            value={requirement.priority}
                                                            options={[
                                                                {
                                                                    value: "1",
                                                                    label: "Low"
                                                                },
                                                                {
                                                                    value: "2",
                                                                    label: "Medium"
                                                                },
                                                                {
                                                                    value: "3",
                                                                    label: "High"
                                                                }

                                                            ]}
                                                            onChange={(event) => {
                                                                var requirements = me.state.requirements;
                                                                var requirement = requirements[k];
                                                                requirement.priority = event.target.value;
                                                                this.setState({ requirements: requirements });
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                                {/* <Grid item lg={5}>
                                                                    <div className={classnames(classes.priority, classes.scoring)}>
                                                                        <OutlinedSelectInput
                                                                            label="Scoring type"
                                                                            labelStyle={"inline"}
                                                                            error={this.state.name_error.length > 0}
                                                                            helperText={this.state.name_error}
                                                                            value={requirement.scoring_type}
                                                                            options={[
                                                                                {
                                                                                    value:"1",
                                                                                    label:"Pass/Fail"
                                                                                },
                                                                                {
                                                                                    value:"2",
                                                                                    label:"Score"
                                                                                }
                                                                            ]}
                                                                            onChange={(event) => {
                                                                                var requirements    =   me.state.requirements;
                                                                                var requirement  =  requirements[k];
                                                                                requirement.scoring_type    = event.target.value;  
                                                                                this.setState({ requirements: requirements });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid> */}
                                            </Grid>
                                            <Grid container>
                                                <Grid item lg={12}>
                                                    <div className={classes.nameYour}>
                                                        <OutlinedInput
                                                            label="Name your Feature"
                                                            error={requirement.name_error.length > 0}
                                                            helperText={requirement.name_error}
                                                            value={requirement.name}
                                                            onChange={(event) => {
                                                                var requirements = me.state.requirements;
                                                                var requirement = requirements[k];
                                                                requirement.name = event.target.value;
                                                                this.setState({ requirements: requirements });
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item lg={12}>
                                                    <div className={classes.yourQuestion}>
                                                        {/* <OutlinedInput
                                                                        label="Explain Your Feature"
                                                                        error={requirement.description_error.length > 0}
                                                                        helperText={requirement.description_error}
                                                                        value={requirement.description}
                                                                        multiline={true}
                                                                        row={10}
                                                                        onChange={(event) => {
                                                                            var requirements    =   me.state.requirements;
                                                                            var requirement  =  requirements[k];
                                                                            requirement.description    = event.target.value;  
                                                                            this.setState({ requirements: requirements });
                                                                        }}
                                                                    /> */}
                                                        <SlateInputField
                                                            label="Explain Your Feature"
                                                            error={requirement.description_error.length > 0}
                                                            helperText={requirement.description_error}
                                                            value={requirement.description}
                                                            folderPath={`/evaluation/${this.props?.evaluationId}/criteria/${this.props?.criteriaId}/newfeature`}
                                                            placeholder=""
                                                            as={SlateInputField}
                                                            onChangeEvent={(value) => {
                                                                var requirements = me.state.requirements;
                                                                var requirement = requirements[k];
                                                                requirement.description = value;
                                                                this.setState({ requirements: requirements });
                                                            }}
                                                            initValue={this.state.description}
                                                            textContainerStyle={{ color: "#707070", }} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </DialogContent>
                                </div>
                            </React.Fragment>
                        })

                    }
                    <Divider />

                    <DialogContent classes={{ root: classes.dialogAction }} >
                        <Button className={classes.addNewButton} onClick={this.addExtraRequirement}>+ Add new Feature</Button>
                        {this.props.addRequirementProgress === true && <div className={classes.actions}><CircularProgress /></div>}
                        {this.props.addRequirementProgress === false && <div className={classes.actions}>
                            <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.changeStep}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                            <SqureButton cls={classes.actionBtn} variant={"contained"}
                                onClick={this.submitResponse}>Save</SqureButton>
                        </div>}
                    </DialogContent>



                </div>

            </DialogContent>
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(FeatureDialog));