import {
  SHOW_ADD_OAUTH_DIALOG, HIDE_ADD_OAUTH_DIALOG,
  SHOW_SCOPE_ACCESS_DIALOG, HIDE_SCOPE_ACCESS_DIALOG,
  START_ADD_NEW_OAUTH, END_ADD_NEW_OAUTH,
  START_UPDATE_OAUTH, END_UPDATE_OAUTH,
  START_FETCH_OAUTHS, END_FETCH_OAUTHS,
  START_DELETE_OAUTH, END_DELETE_OAUTH,
  START_FETCH_OAUTH_DATA, END_FETCH_OAUTH_DATA,
  START_FETCH_OAUTH_CREDENCIALS_DATA, END_FETCH_OAUTH_CREDENCIALS_DATA,
  START_FETCH_OAUTH_SCOPES, END_FETCH_OAUTH_SCOPES,
  START_FETCH_MASTER_OAUTH_SCOPES, END_FETCH_MASTER_OAUTH_SCOPES,
  START_ADD_OAUTH_SCOPE, END_ADD_OAUTH_SCOPE,
  START_REMOVE_OAUTH_SCOPE, END_REMOVE_OAUTH_SCOPE,
} from "./action";

// Initial State
const initState = {
  dialogAddOauthOpen: false,

  dialogScopeAccessOpen: false,
  scopeAccessData: null,

  addNewOauthProgress: false,
  addNewOauthError: null,
  addNewOauthSuccess: null,

  updateOauthProgress: false,
  updateOauthError: null,
  updatedOauthSuccess: null,

  fetchOauthsProgress: false,
  fetchOauthsError: null,
  oauthDatas: [],

  deleteOauthProgress: false,
  deleteOauthError: null,
  deleteOauthSuccess: null,

  fetchOauthProgress: false,
  fetchOauthError: null,
  oauthDetails: {},

  fetchOauthScopeProgress: false,
  fetchOauthScopeError: null,
  oauthScopes: [],

  fetchMasterOauthScopeProgress: false,
  fetchMasterOauthScopeError: null,
  oauthMasterScopes: [],

  addOauthScopeProgress: false,
  addOauthScopeError: null,
  addOauthScopeSuccess: null,

  removeOauthScopeProgress: false,
  removeOauthScopeError: null,
  removeOauthScopeSuccess: null,

}


export function oauthReducers(state = initState, action) {
  switch (action.type) {

    case SHOW_ADD_OAUTH_DIALOG:
      return {
        ...state,
        dialogAddOauthOpen: true,
      };

    case HIDE_ADD_OAUTH_DIALOG:
      return {
        ...state,
        dialogAddOauthOpen: false,
      };
    case SHOW_SCOPE_ACCESS_DIALOG:
      return {
        ...state,
        dialogScopeAccessOpen: true,
        scopeAccessData: action.payload.data       
      };

    case HIDE_SCOPE_ACCESS_DIALOG:
      return {
        ...state,
        dialogScopeAccessOpen: false,
        scopeAccessData: null
      };
  
    case START_ADD_NEW_OAUTH:
      return {
        ...state,
        addNewOauthProgress: true,
        addNewOauthError: null,
        addNewOauthSuccess: null
      }

    case END_ADD_NEW_OAUTH:
      return {
        ...state,
        addNewOauthProgress: false,
        addNewOauthError: action.payload.error,
        addNewOauthSuccess: action.payload.success
      }

    case START_UPDATE_OAUTH:
      return {
        ...state,
        updateOauthProgress: true,
        updateOauthError: null,
        updatedOauthSuccess: null,
      }

    case END_UPDATE_OAUTH:
      return {
        ...state,
        updateOauthProgress: false,
        updateOauthError: action.payload.error,
        updatedOauthSuccess: action.payload.success,
      }

    case START_FETCH_OAUTHS:
      return {
        ...state,
        fetchOauthsProgress: true,
        fetchOauthsError: null,
      }

    case END_FETCH_OAUTHS:
      return {
        ...state,
        fetchOauthsProgress: false,
        fetchOauthsError: action.payload.error,
        oauthDatas: action.payload.success || []
      }

    case START_DELETE_OAUTH:
      return {
        ...state,
        deleteOauthProgress: true,
        deleteOauthError: null,
      }

    case END_DELETE_OAUTH:
      return {
        ...state,
        deleteOauthProgress: false,
        deleteOauthError: action.payload.error,
        deleteOauthSuccess: action.payload.success,
      }

    case START_FETCH_OAUTH_DATA:
      return {
        ...state,
        fetchOauthProgress: true,
        fetchOauthError: null,
      }

    case END_FETCH_OAUTH_DATA:
      return {
        ...state,
        fetchOauthProgress: false,
        fetchOauthError: action.payload.error,
        oauthDetails: action.payload.success
      }
    case START_FETCH_OAUTH_CREDENCIALS_DATA:
      return {
        ...state,
        fetchOauthProgress: true,
        fetchOauthError: null,
      }

    case END_FETCH_OAUTH_CREDENCIALS_DATA:
      return {
        ...state,
        fetchOauthProgress: false,
        fetchOauthError: action.payload.error,
        oauthDetails: action.payload.success
      }

    case START_FETCH_OAUTH_SCOPES:
      return {
        ...state,
        fetchOauthScopeProgress: true,
        fetchOauthScopeError: null,
        oauthScopes: []
      }

    case END_FETCH_OAUTH_SCOPES:
      return {
        ...state,
        fetchOauthScopeProgress: false,
        fetchOauthScopeError: action.payload.error,
        oauthScopes: action.payload.success
      }

    case START_FETCH_MASTER_OAUTH_SCOPES:
      return {
        ...state,
        fetchMasterOauthScopeProgress: true,
        fetchMasterOauthScopeError: null,
        oauthMasterScopes: []
      }

    case END_FETCH_MASTER_OAUTH_SCOPES:
      return {
        ...state,
        fetchMasterOauthScopeProgress: false,
        fetchMasterOauthScopeError: action.payload.error,
        oauthMasterScopes: action.payload.success
      }

    case START_ADD_OAUTH_SCOPE:
      return {
        ...state,
        addOauthScopeProgress: true,
        addOauthScopeError: null,
        addOauthScopeSuccess: null,
      }

    case END_ADD_OAUTH_SCOPE:
      return {
        ...state,
        addOauthScopeProgress: false,
        addOauthScopeError: action.payload.error,
        addOauthScopeSuccess: action.payload.success,
      }
    case START_REMOVE_OAUTH_SCOPE:
      return {
        ...state,
        removeOauthScopeProgress: true,
        removeOauthScopeError: null,
        removeOauthScopeSuccess: null,
      }

    case END_REMOVE_OAUTH_SCOPE:
      return {
        ...state,
        removeOauthScopeProgress: false,
        removeOauthScopeError: action.payload.error,
        removeOauthScopeSuccess: action.payload.success,
      }

    default:
      return state;
  }

}