/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import {UserFactory} from "../../services/user";

export const START_RESETPASSWORD     =   "START_RESETPASSWORD";
export const END_RESETPASSWORD       =   "END_RESETPASSWORD";

//Action to start the registration
function startResetPassword(){
    return {
        type:START_RESETPASSWORD,
        payload:null
    };
}

function endResetPassword(success,error){
    return {
        type:END_RESETPASSWORD,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the register
export  function resetPassword(token,password){
    return async (dispatch) => {
        dispatch(startResetPassword());
        try{
            let [response,error]    =   await UserFactory.instance().resetPassword(token,password);
            dispatch(endResetPassword(response,error));
        } catch(e){
            dispatch(endResetPassword(null,{
                message:e.message
            }));
            return;
        }
    };
}   