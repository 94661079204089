import React from 'react';
import { connect } from "react-redux";
import classnames from "classnames";
// import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {  Button, Typography, Grid } from '@material-ui/core';
import AnswerReply from "Components/Common/EvaluateMultiChoiceAnswer/AnswerReply"
import OutlinedSelectInput from 'Components/Common/Input/OutlinedSelectInput';


// icons
import { Send as SendIcon} from '@material-ui/icons';

// assets
import Messages from "assets/images/add_message.svg"

// Components
// import RatingPoper from "Components/Application/Components/Evaluate/Components/Evaluation/InProgress/RatingPoper.jsx";
// import OutlinedSelectInput from 'Components/Common/Input/OutlinedSelectInput';
import UserMessage from './UserMessage';
import { SlateInputField } from "Components/Common/SlateEditor/SlateInputField.jsx";
import BuyerChat from "Components/Chat/BuyerChat.jsx";


// redux
import { addRequirementResponse, fetchCollectiveResponses, clearState } from "redux/evaluation/criteria/action";


const connectedProps = (state) => ({
  authUser: state.authUser.user,
  addResponseProgress: state.evaludationCriteria.addResponseProgress,
  successResponseAdd: state.evaludationCriteria.successResponseAdd,
  errorResponseAdd: state.evaludationCriteria.errorResponseAdd,
});
const connectionActions = {
  fetchCollectiveResponses: fetchCollectiveResponses,
  addRequirementResponse: addRequirementResponse,
  clearState: clearState
}
var connector = connect(connectedProps, connectionActions);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  
  const useStyles = createStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    title: {
      fontWeight: 600,
      color: '#282D30',
      opacity: 1,
      fontSize: '0.875em',
      textAlign: 'center'
    },
    subTitle: {
      fontWeight: 600,
      color: '#282D30',
      opacity: 1,
      fontSize: '1em',
      textAlign: 'center',
      background: '#F4F6F8 0% 0% no-repeat padding-box',
      borderRadius: '7px',
      opacity: 1,
      padding: '15px',
      width: '90%',
      margin: '0px auto',
      marginBottom: '30px',
      "& [class*='editableField']": {
        background: '#F4F6F8',
        maxHeight: 'auto !important',
        minHeight: 'auto !important'
      }
    },
    appBar: {
      backgroundColor: '#ffffff',
      boxShadow: 'unset',
      borderBottom: '1px solid #EEEEEE'
    },
    privateTabIndicator: {
      border: '1.5px solid #4A87F8',
      background: '#4A87F8'
    },
    tabTitle: {
      color: '#282D30 !important',
      fontSize: '13px',
      fontWeight: 500
    },
    activeTabTitle: {
      color: '#4A87F8',
      fontSize: '14px',
      fontWeight: 600
    },
    description: {
      margin: 0,
      fontWeight: 400
    },
    name: {
      margin: 0,
      fontWeight: 600
    },
    comingSoon: {
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 15,
      height: 'calc(100vh - 300px)',
      color: '#707070'
    },
    addTextimage: {
      marginRight: 10,
      width: 21,
      position: 'relative',
      top: 2
      }, 
      addText: {
        display:'flex',
        alignItems:'center',
        justifyContent: 'center',
        height:'80px',
        fontSize:13,
        color: '#707070',
        cursor: 'pointer',
        transition: 'all linear .5s',
    },
    addTextimage: {
        marginRight: 10,
        width: 21,
        position: 'relative',
        top: 2
    }, 
    cummunityTabIndicator:{
      display: 'block',
      width: '6px',
      height: '6px',
      background: 'red',
      borderRadius: '4px',
      position: 'absolute',
      left: '-8px',
      top:'0px'
    },
    replylink:{
      fontWeight: 'normal',
      letterSpacing: '0px',
      color: '#000000',
      fontSize: '12px',
      marginRight: '25px',
      fontSize: '1rem',
      textDecoration: 'underline',
      color: '#4A87F8',
      marginTop: '16px',
      display: 'block'
    },
    emptyStateAnswer:{
      "& [class*='makeStyles-scoreResponse-']": {
        marginLeft: 0
      }
    },
    responseActionContainer: {
      flexDirection: 'row',
      boxSizing: 'border-box',
      display: 'flex',
      placeContent: 'center flex-start',
      alignItems: 'center',
      marginTop: 13
    },
    responseAction:{
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        placeContent:' center space-between',
        alignItems: 'center',
        background: '#f9f9f9',
        borderRadius: '8px',
        padding: '4px 21px',
    },
    selectResult: {
      "& > div": {
          marginBottom: 0
      },
      display: "flex",
      justifyContent: 'flex-end',
      position: 'relative',
      marginRight: 10,
      height: '100%',
      alignItems: 'center',
      minWidth: 80,

      "& button": {
          color:'#707070',
          background: '#fff',
          padding: '1.5px 9px',
          minWidth: 80
      },
      "& [class*=MuiFormControl-root]": {
          minWidth: 82,
          width: 'inherit',
      },
      "& [class*=MuiSelect-root]": {
          fontSize: 13,
          color: '#707070',
          padding: '0.29em 0.75em'

      },
      "&.pass": {
          "& [class*=MuiSelect-root], svg": {
           color: '#2EB77D',
          },
          "& [class*=MuiOutlinedInput-notchedOutline]": {
              border: '1px solid #2EB77D'
          },
          
      },
      "&.fail": {
          "& [class*=MuiSelect-root], svg": {
           color: '#b94141',
          },
          "& [class*=MuiOutlinedInput-notchedOutline]": {
              border: '1px solid #b94141'
          }
      }
  },
  reqMatch:{
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'center flex-start',
    alignItems: 'center',
    background: '#f9f9f9',
    padding: '12px 2px',
    borderRadius: '6px',
    '& span':{
        marginRight: 10,
        marginLeft: 10
    }
}
  }));
  
  class CollectiveResponse extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            tabValue: this.props.collectiveTargetTab || 0,
            showMainReply:false,
            showComment:false,
            mainReply:''
        }
    }

    handleChange = (event, newValue) => {
        this.setState({tabValue: newValue})
    };
    
    submitMainResponse = ()=> {
        const {evaluation_id, product, requirement, communityResponse} = this.props;
        this.props.addRequirementResponse(
          evaluation_id,
          product.ID,
          requirement.id,{
              note: this.state.mainReply,
              value: communityResponse == null ? null : communityResponse.Response,
          }
        )
      }


  fetchCollectiveResponse =()=> {
      let queryparams = {}
      queryparams.evaluation_id = this.props.evaluation_id | 0;
      queryparams.product_id = this.props.product.ID | 0;
      queryparams.requirement_id = this.props.requirement.id | 0;
      this.props.fetchCollectiveResponses(queryparams);
  }
 
  componentDidUpdate() {
    if(this.props.addResponseProgress == false && this.props.successResponseAdd){
      this.props.clearState('clear_add_response')
      this.fetchCollectiveResponse()
    }
  }


    render(){
        const {classes}=this.props;
        const {communityResponse, collectiveRequirement, collectiveResponses, fetchCollectiveResponsesLoading }=this.props;

        const responses = collectiveResponses || [];

        let selectOptions = [
          {
              value:"1",
              label:"Yes"
          },
          {
              value:"2",
              label:"Partial"
          },
          {
              value:"3",
              label:"No"
          }
        ]

          return (
            <>
              <div>
                <h6 className={classes.title}>Collective responses</h6>
                <div className={classes.subTitle}>
                <h4 className={classes.name}>{collectiveRequirement.name}</h4>
                <SlateInputField  
                  placeholder=""
                  isToolBar={false}
                  readOnly={true}
                  as={SlateInputField}
                  onChangeEvent={(value) => {
                    
                  }}
                  formControlStyle={{border: 'none', maxHeight: 130, overflow: 'overlay', background: '#F4F6F8'}}
                  initValue={collectiveRequirement.description}
                  textContainerStyle={{ color: '#282D30', }}
                  paperStyle={{ background: '#F4F6F8', color: '#282D30', fontWeight: 400}}
                  style={{marginBottom: 0}}
                  />
                </div>
                <div className={classes.root}>
                  <AppBar position="static" color="default"  className={classes.appBar}>
                    <Tabs
                      value={this.state.tabValue}
                      onChange={this.handleChange}
                      variant="fullWidth"
                      classes={{
                        indicator: classes.privateTabIndicator
                      }}
                    >
                      <Tab label="My team" {...a11yProps(0)} className={this.state.tabValue == 0 ? classes.activeTabTitle : classes.tabTitle} />
                      <Tab disabled={true} label={<span style={{position:'relative'}}>Community {communityResponse && communityResponse.response === true ? <span className={classes.cummunityTabIndicator}></span>: null}</span>} {...a11yProps(1)} className={this.state.tabValue === 1 ? classes.activeTabTitle : classes.tabTitle} />
                      <Tab label="Vendor" {...a11yProps(2)} className={this.state.tabValue == 2 ? classes.activeTabTitle : classes.tabTitle} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={this.state.tabValue} index={0}>
                    <>
                    {
                       responses.length === 0 && fetchCollectiveResponsesLoading === false  && 
                      <>
                        <div>
                            {
                              this.state.showMainReply === false && this.props.requirement.answer_type == 1 &&
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                              <div className={classnames(classes.addText, 'response-addText', 'evaluate-add-text')} onClick={()=>{this.setState({showMainReply:true})}}>
                                <img className={classes.addTextimage} alt="" src={Messages}/>
                                <span>Add Text</span>
                              </div>
                              </Grid>
                            }
                            {
                              fetchCollectiveResponsesLoading === false && this.state.showMainReply == true && (responses.length === 0 || responses[0].Note == '') &&
                              <>
                                <SlateInputField  
                                  style={{width: '100%'}}
                                  as={SlateInputField}
                                  onChangeEvent={(value) => {
                                    this.setState({mainReply: value})
                                  }}
                                  placeholder={"Type your response here."}
                                  initValue={""}
                                  formControlStyle={{ overflow: 'overlay'}}
                                  textContainerStyle={{ color: "#707070"}}/>
                                  <div  style={{textAlign:'right'}}>
                                    <Button style={{marginRight:20}}  variant="contained" color="primary" endIcon={<SendIcon />} onClick={()=>{
                                      this.submitMainResponse()
                                    }}>
                                        Save
                                    </Button>
                                    <Button  variant="contained" color="primary" onClick={()=>{this.setState({showMainReply:false})}}>
                                      Cancel
                                    </Button>
                                  </div>
                              </>
                            }
                            <AnswerReply 
                                requirement={this.props.requirement}
                                evaluation_id={this.props.evaluation_id}
                                OrgID={this.props.authUser.OrgID}
                                userOptions={[]}
                                onChange={(options, add_other = null)=>{
                                  if(this.props.requirement.answer_type === 5 || this.props.requirement.answer_type === 6){
                                    this.props.addRequirementResponse(
                                      this.props.evaluation_id,
                                      this.props.product.ID,
                                      this.props.requirement.id,{
                                        value: null,
                                        note: null,
                                        response_options:{
                                          answer: add_other || ''
                                        }
                                      }
                                  );
                                  }else{
                                    this.props.addRequirementResponse(
                                      this.props.evaluation_id,
                                      this.props.product.ID,
                                      this.props.requirement.id,{
                                        value: null,
                                        note: null,
                                        response_options:{
                                            option_ids: options,
                                            answer: add_other || ''
                                        }
                                      }
                                  );
                                  }
                                }}
                            />

                            {
                              this.props.requirement.answer_type !== 1 &&
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                { !this.state.showComment &&
                                    <div className={classnames(classes.addText, 'response-addText', 'evaluate-add-text')} onClick={()=>{this.setState({showComment:true})}}>
                                      <img className={classes.addTextimage} alt="" src={Messages}/>
                                      <span>Add Comment</span>
                                    </div>
                                }

                                { this.state.showComment &&
                                  <>
                                  <SlateInputField  
                                  style={{width: '100%'}}
                                  as={SlateInputField}
                                  onChangeEvent={(value) => {
                                    this.setState({mainReply: value})
                                  }}
                                  placeholder={"Type your response here."}
                                  initValue={""}
                                  formControlStyle={{ overflow: 'overlay'}}
                                  textContainerStyle={{ color: "#707070"}}/>
                                  <div style={{textAlign:'right'}}>
                                    <Button style={{marginRight:20}}  variant="contained" color="primary" endIcon={<SendIcon />} onClick={()=>{
                                      this.submitMainResponse()
                                    }}>
                                        Save
                                    </Button>
                                    <Button  variant="contained" color="primary" onClick={()=>{this.setState({showComment:false})}}>
                                      Cancel
                                    </Button>
                                  </div>
                                  </>
                                }
                              </Grid>
                            }
                            
                            {/* <span className={classes.replylink} onClick={()=>{this.setState({showMainReply:true})}} style={{cursor:'pointer'}}>Reply</span> */}
                            <Grid item xs={12} className={classes.responseActionContainer}>
                                <div className={classes.reqMatch}>
                                    <span>Score</span>
                                    <div className={classnames('evaluate-select-result',classes.selectResult, (responses && responses?.Response+"") === '1' ? 'pass': (responses && responses?.Response+"") === '2' ? 'fail': '')}>
                                        <OutlinedSelectInput
                                                value={responses !== null && responses?.Response !== null?responses?.Response+"" :"-1"}
                                                compact={true}
                                                options={selectOptions}
                                                onChange={(event) => {
                                                    // check add other exist
                                                    let answer = {};
                                                    answer = {
                                                      value: parseFloat(event.target.value),
                                                      note:responses == null?null:responses?.Note,
                                                      response_options:{
                                                          option_ids:[],
                                                          answer: (responses?.UserOptions && responses?.UserOptions[0]?.Answer) || ''
                                                      }
                                                    }
                                                    this.props.addRequirementResponse(
                                                        this.props.match.params.evaluation_id,
                                                        this.props.product.ID,
                                                        this.props.requirement.id,{
                                                                ...answer
                                                        }
                                                    )
                                                }}
                                            />
                                    </div>
                                </div>  
                            </Grid>
                          </div>
                          </>
                        }
                  
                        {/* (true || this.props.requirement?.answer_type !== 1 || responses.length > 0 && responses[0].Note !== '') */}
                        {
                          responses.map((response, k) => {
                            return <UserMessage 
                            criteria={this.props.criteria}
                            product={this.props.product}
                            requirement={this.props.requirement}
                            evaluation_id={this.props.evaluation_id} 
                            response={response} 
                            collectiveRequirement={collectiveRequirement} key={k} 
                            replyResponse={true} />
                          })
                        }
                    </>
                  </TabPanel>
        
                  <TabPanel value={this.state.tabValue} index={1}>
                    <UserMessage communityTab={true} response={communityResponse} collectiveRequirement={{}} flag={false} 
                    replyResponse={false}  requirement={this.props.requirement} 
                    evaluation_id={this.props.evaluation_id}  />
                  </TabPanel>
        
                  <TabPanel value={this.state.tabValue} index={2}>
                      <BuyerChat />
                  </TabPanel>
                </div>
              </div>
            </>
          );
    }

}
/*
const _useStyles = makeStyles(theme => ({
  scoreResponse:{
    height: '50px',
    color: 'rgb(4, 54, 61)',
    display: 'flex',
    placeContent: 'center space-around',
    alignItems: 'center',
    maxWidth: '220px',
    margin: '0px auto',
    background: '#f9f9f9',
    borderRadius: '5px',
    marginTop: '20px',
    marginBottom: '20px',
    "& [class*='Outlined-input-']": {
      margin: '0px !important'
    }
  }
}))

function UpdateScoreUI(props){
  const { requirement, product, evaluation_id } = props;

  const[ratingPoperEl, setPoperEl] = useState(null)

  const classes = _useStyles();

  const response = props.responses && props.responses[0] || null;

  let selectOptions = [];
       if(props.evaluateView) {
        selectOptions = [
            { value:"1", label:"Pass"},
            { value:"2",label:"Fail"}
        ]
       } else {
        selectOptions = [
            { value:"-1",label:"Select"},
            {value:"1",label:"Pass"},
            { value:"2",label:"Fail"}
        ]
      }
  return(
    <>
        <div container className={classes.responseAction}>
            { 
                <>
                    <div item  className={classes.scoreResponse}>
                        <div>
                          Response
                        </div>
                        {
                            requirement.scoring_type == 1 && <div className={classnames('evaluate-select-result',classes.selectResult, (response && response.Response+"") === '1' ? 'pass': (response && response.Response+"") === '2' ? 'fail': '')}>
                                <OutlinedSelectInput
                                        value={response !== null && response.Response !== null?response.Response+"" :"-1"}
                                        compact={true}
                                        options={selectOptions}
                                        onChange={(event) => {
                                            props.addRequirementResponse(
                                                evaluation_id,
                                                product.ID,
                                                requirement.id,{
                                                    value:parseFloat(event.target.value),
                                                    note:response == null?null:response.Note,
                                                }
                                            )
                                        }}
                                    />
                            </div>
                        }
                        {
                            requirement.scoring_type == 2 && 
                            <div className={classnames('evaluate-select-result', classes.selectResult)}>
                                <Button variant="outlined" size="small" onClick={(event)=>{
                                    event.stopPropagation();
                                    setPoperEl(event.currentTarget)
                                }}>{response !== null && response.Response !== null?response.Response+"" :"+ Score"}</Button>
                                {ratingPoperEl && <RatingPoper value={response !== null && response.Response !== null?response.Response :0} anchorEl={ratingPoperEl} onClose={()=>{
                                   setPoperEl(null)
                                }} onSave={(value)=>{
                                    props.addRequirementResponse(
                                        evaluation_id,
                                        product.ID,
                                        requirement.id,{
                                            value:parseFloat(value),
                                            note: response == null?null:response.Note
                                        }
                                    )
                                    setPoperEl(null)
                                }}/>}
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    </>
  )
}
*/

export default connector(compose( withRouter,  withStyles(useStyles) )(CollectiveResponse));