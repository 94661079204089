import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem, Typography, Button } from "@material-ui/core";

const styles = (theme) => createStyles({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  reqTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#282D30',
    fontSize: theme.spacing(2.2)
  },
  actionButtons: {
    textAlign: 'right',
    marginTop: theme.spacing(2)
  },
  appyBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    minHeight: 35,
    width: "calc(100% - 46px)",
    margin: 23,
    color: "#fff",
    fontSize: 14,
    padding: theme.spacing(0.5, 8),
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  head: {
    color: "#282D30",
    fontSize: 18,
  },
  headSection: {
    display: "flex",
    justifyContent: "space-between",
    padding: 23,
  },
  drawerSection: {
    padding: theme.spacing(0, 3.6),
    minHeight: 48,
    width: 400,
  }
});

class OrgProductFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      value: [20, 37],
      expanded: false,
      appliedfilters: {
        sortby: 'Relevance',
        order: '',
      },
      filters: [{
        label: 'Relevance',
        key: 'relevance',
      },{
        label: 'Name',
        key: 'name',
        orders: [{
          key: 'least',
          label: 'A-Z'
        }, {
          key: 'most',
          label: 'Z-A'
        }]
      }, {
        label: 'Usage',
        key: 'usage',
        orders: [{
          key: 'least',
          label: 'Least used'
        }, {
          key: 'most',
          label: 'Most used'
        }]
      }, {
        label: 'Business Criticality',
        key: 'business_criticality',
        orders: [{
          key: 'least',
          label: 'High to Low'
        }, {
          key: 'most',
          label: 'Low to High'
        }]
      },
      //  {
      //   label: 'Total cost',
      //   key: 'total_cost',
      //   orders: [{
      //     key: 'least',
      //     label: 'High to Low'
      //   }, {
      //     key: 'most',
      //     label: 'Low to High'
      //   }]
      // }, {
      //   label: 'Cost per license',
      //   key: 'cost_per_license',
      //   orders: [{
      //     key: 'least',
      //     label: 'High to Low'
      //   }, {
      //     key: 'most',
      //     label: 'Low to High'
      //   }]
      // }, {
      //   label: 'Cost per license frequency',
      //   key: 'cost_per_license_frequency',
      //   orders: [{
      //     key: 'least',
      //     label: 'Monthly'
      //   }, {
      //     key: 'most',
      //     label: 'Yearly'
      //   }]
      // }, {
      //   label: 'Number of license procured',
      //   key: 'number_of_license_procured',
      //   orders: [{
      //     key: 'least',
      //     label: 'High to Low'
      //   }, {
      //     key: 'most',
      //     label: 'Low to High'
      //   }]
      // }, {
      //   label: 'Start date',
      //   key: 'start_date',
      //   orders: [{
      //     key: 'least',
      //     label: 'Newest to Oldest'
      //   }, {
      //     key: 'most',
      //     label: 'Oldest to Newest'
      //   }]
      // }, {
      //   label: 'Renewal date',
      //   key: 'renewal_date',
      //   orders: [{
      //     key: 'least',
      //     label: 'Newest to Oldest'
      //   }, {
      //     key: 'most',
      //     label: 'Oldest to Newest'
      //   }]
      // }, {
      //   label: 'End date',
      //   key: 'end_date',
      //   orders: [{
      //     key: 'least',
      //     label: 'Newest to Oldest'
      //   }, {
      //     key: 'most',
      //     label: 'Oldest to Newest'
      //   }]
      // }
      ],
      orgUserfilters: [{
        label: 'Relevance',
        key: 'relevance',
      },{
        label: 'Name',
        key: 'name',
        orders: [{
          key: 'least',
          label: 'A-Z'
        }, {
          key: 'most',
          label: 'Z-A'
        }]
      }]
    };
  }

  componentDidMount() {
    const { appliedfilters } = this.props
    this.setState({ appliedfilters })
  }

  handleOnClickApply = () => {
    this.props.onApplyFilter(this.state.appliedfilters)
  }

  render() {
    var { classes, user } = this.props;
    const { appliedfilters, filters, orgUserfilters } = this.state
    let orders = []
    if (appliedfilters.sortby !== undefined) {
      orders = filters.filter(obj => (obj.key === appliedfilters.sortby))
      if (orders[0]) {
        orders = orders[0].orders
      }
    }

    let sortFilters = user !== undefined && user !== null && user?.Role !== undefined && user.Role === 'OrgAdmin' ? filters : orgUserfilters;

    return (
      <div className={classes.drawerSection}>
        <div className={classes.sortByDiv}>
          <Typography variant={"h6"} className={classes.reqTitle}>
            Sort by:
          </Typography>
          <FormControl variant="outlined" className={classes.formControl} size="small">
            <Select value={appliedfilters.sortby} onChange={(e) => this.setState({ appliedfilters: { sortby: e.target.value, order: "least" } })}>
              {sortFilters.map((dta, index) => {
                return <MenuItem key={index} value={dta.key} >{dta.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        {appliedfilters.sortby !== "relevance" && (
          <div className={classes.sortByDiv}>
            <Typography variant={"h6"} className={classes.reqTitle}>
              Order:
            </Typography>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <Select value={(appliedfilters?.sortby && appliedfilters?.order) || ""} onChange={(e) => this.setState({ appliedfilters: { ...appliedfilters, order: e.target.value } })}>
                {appliedfilters.sortby !== undefined && orders.map((dta, index) => {
                  return <MenuItem key={index} value={dta.key}>{dta.label}</MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
        )}
        <div className={classes.textCenter}>
          <Button className={classes.appyBtn} onClick={() => this.handleOnClickApply()}>Apply</Button>
        </div>
      </div>
    );
  }
}

OrgProductFilter.defaultProps = {
  appliedfilters: {}
}

export default React.memo(withStyles(styles)(OrgProductFilter));
