import { ProductFactory } from "../../services/products";
import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment";

export const START_PRODUCTS_FETCH = "START_PRODUCTS_FETCH";
export const END_PRODUCTS_FETCH = "END_PRODUCTS_FETCH";

export const START_SUBCAT_ORG_PRODUCTS_FETCH = "START_SUBCAT_ORG_PRODUCTS_FETCH";
export const END_SUBCAT_ORG_PRODUCTS_FETCH = "END_SUBCAT_ORG_PRODUCTS_FETCH";

export const START_RECOMMENDED_PRODUCTS_FETCH = "START_RECOMMENDED_PRODUCTS_FETCH";
export const END_RECOMMENDED_PRODUCTS_FETCH = "END_RECOMMENDED_PRODUCTS_FETCH";
export const START_CATEGORY_WISE_PRODUCTS_FETCH = "START_CATEGORY_WISE_PRODUCTS_FETCH";
export const END_CATEGORY_WISE_PRODUCTS_FETCH = "END_CATEGORY_WISE_PRODUCTS_FETCH";

export const START_CATEGORY_WISE_ORG_PRODUCTS_FETCH = "START_CATEGORY_WISE_ORG_PRODUCTS_FETCH";
export const END_CATEGORY_WISE_ORG_PRODUCTS_FETCH = "END_CATEGORY_WISE_ORG_PRODUCTS_FETCH";

export const START_CATEGORY_SUBCATEGORY_WISE_ORG_PRODUCTS_FETCH = "START_CATEGORY_SUBCATEGORY_WISE_ORG_PRODUCTS_FETCH";
export const END_CATEGORY_SUBCATEGORY_WISE_ORG_PRODUCTS_FETCH = "END_CATEGORY_SUBCATEGORY_WISE_ORG_PRODUCTS_FETCH";

export const START_PRODUCT_DETAILS_FETCH = "START_PRODUCT_DETAILS_FETCH";
export const END_PRODUCT_DETAILS_FETCH = "END_PRODUCT_DETAILS_FETCH";

export const START_PRODUCT_RECOMMENDATION_FETCH = "START_PRODUCT_RECOMMENDATION_FETCH";
export const END_PRODUCT_RECOMMENDATION_FETCH = "END_PRODUCT_RECOMMENDATION_FETCH";


export const START_PRODUCT_REQUIREMENT_DETAIL_FETCH = "START_PRODUCT_REQUIREMENT_DETAIL_FETCH";
export const END_PRODUCT_REQUIREMENT_DETAIL_FETCH = "END_PRODUCT_REQUIREMENT_DETAIL_FETCH";

export const START_RECOMMENDED_PRODUCTS_FETCH_TO_ADD = "START_RECOMMENDED_PRODUCTS_FETCH_TO_ADD";
export const END_RECOMMENDED_PRODUCTS_FETCH_TO_ADD = "END_RECOMMENDED_PRODUCTS_FETCH_TO_ADD";

export const START_PRODUCTS_REACTION_FETCH = "START_PRODUCTS_REACTION_FETCH";
export const END_PRODUCTS_REACTION_FETCH = "END_PRODUCTS_REACTION_FETCH";

export const START_COMPLEMENTARY_PRODUCTS_REACTION_FETCH = "START_COMPLEMENTARY_PRODUCTS_REACTION_FETCH";
export const END_COMPLEMENTARY_PRODUCTS_REACTION_FETCH = "END_COMPLEMENTARY_PRODUCTS_REACTION_FETCH";

export const START_UPDATE_PRODUCTS_REACTION = "START_UPDATE_PRODUCTS_REACTION";
export const END_UPDATE_PRODUCTS_REACTION = "END_UPDATE_PRODUCTS_REACTION";

export const START_WISHLISTED_PRODUCTS_FETCH = "START_WISHLISTED_PRODUCTS_FETCH";
export const END_WISHLISTED_PRODUCTS_FETCH = "END_WISHLISTED_PRODUCTS_FETCH";

export const START_WISHLISTED_PRODUCTS_ADD = "START_WISHLISTED_PRODUCTS_ADD";
export const END_WISHLISTED_PRODUCTS_ADD = "END_WISHLISTED_PRODUCTS_ADD";

export const START_PRODUCTS_REACTION_BY_TYPE_FETCH = "START_PRODUCTS_REACTION_BY_TYPE_FETCH";
export const END_PRODUCTS_REACTION_BY_TYPE_FETCH = "END_PRODUCTS_REACTION_BY_TYPE_FETCH";

export const START_WISHLISTED_PRODUCTS_LIST_FETCH = "START_WISHLISTED_PRODUCTS_LIST_FETCH";
export const END_WISHLISTED_PRODUCTS_LIST_FETCH = "END_WISHLISTED_PRODUCTS_LIST_FETCH";

export const START_ORG_WISHLISTED_PRODUCTS_LIST_FETCH = "START_ORG_WISHLISTED_PRODUCTS_LIST_FETCH";
export const END_ORG_WISHLISTED_PRODUCTS_LIST_FETCH = "END_ORG_WISHLISTED_PRODUCTS_LIST_FETCH";

export const SET_ORG_PRODUCT_SORT_FILTER_OPTIONS = "SET_ORG_PRODUCT_SORT_FILTER_OPTIONS";

export const START_PERSONALIZED_RECOMMENDATIONS_PRODUCTS = "START_PERSONALIZED_RECOMMENDATIONS_PRODUCTS"
export const END_PERSONALIZED_RECOMMENDATIONS_PRODUCTS = "END_PERSONALIZED_RECOMMENDATIONS_PRODUCTS"

export const ADD_PRODUCT_TO_IS_EXISTING = "ADD_PRODUCT_TO_IS_EXISTING";
export const START_CHECK_WISHLISTED_PRODUCT_FETCH = "START_CHECK_WISHLISTED_PRODUCT_FETCH";

export const END_CHECK_WISHLISTED_PRODUCT_FETCH = "END_CHECK_WISHLISTED_PRODUCT_FETCH";

export const SET_ALL_PRODUCT_FILTER_OPTIONS = "SET_ALL_PRODUCT_FILTER_OPTIONS";

export const START_COMPANY_RECOMMENDATIONS_PRODUCTS = "START_COMPANY_RECOMMENDATIONS_PRODUCTS";
export const END_COMPANY_RECOMMENDATIONS_PRODUCTS = "END_COMPANY_RECOMMENDATIONS_PRODUCTS";

export const SHOW_PRODUCT_WHISLISTED_USERS_DIALOG = "SHOW_PRODUCT_WHISLISTED_USERS_DIALOG";
export const HIDE_PRODUCT_WHISLISTED_USERS_DIALOG = "HIDE_PRODUCT_WHISLISTED_USERS_DIALOG";

export const START_FETCH_PRODUCT_WISHLISTED_USERS = "START_FETCH_PRODUCT_WISHLISTED_USERS";
export const END_FETCH_PRODUCT_WISHLISTED_USERS = "END_FETCH_PRODUCT_WISHLISTED_USERS";

export const START_DELETE_UNVERIFIED_PRODUCT = "START_DELETE_UNVERIFIED_PRODUCT";
export const END_DELETE_UNVERIFIED_PRODUCT = "END_DELETE_UNVERIFIED_PRODUCT";

export const START_ADD_PRODUCT_STATE = "START_ADD_PRODUCT_STATE";
export const END_ADD_PRODUCT_STATE = "END_ADD_PRODUCT_STATE"

export const START_DELETE_PRODUCT_STATE = "START_DELETE_PRODUCT_STATE";
export const END_DELETE_PRODUCT_STATE = "END_DELETE_PRODUCT_STATE";

export const START_DELETE_ORG_PRODUCT = "START_DELETE_ORG_PRODUCT";
export const END_DELETE_ORG_PRODUCT = "END_DELETE_ORG_PRODUCT";

export const START_INREVIEW_PRODUCTS_FETCH = "START_INREVIEW_PRODUCTS_FETCH";
export const END_INREVIEW_PRODUCTS_FETCH = "END_INREVIEW_PRODUCTS_FETCH";

export const START_PRODUCT_SEARCH = "START_PRODUCT_SEARCH";
export const END_PRODUCT_SEARCH = "END_PRODUCT_SEARCH";

export const START_PRODUCT_ALL_SEARCH = "START_PRODUCT_ALL_SEARCH";
export const END_PRODUCT_ALL_SEARCH = "END_PRODUCT_ALL_SEARCH";

function startProductSearch(searchQuery) {
    return {
        type: START_PRODUCT_SEARCH,
        payload: {
            searchQuery
        }
    }
}

function endProductSearch(searchQuery, success, error) {
    return {
        type: END_PRODUCT_SEARCH,
        payload: {
            searchQuery,
            success,
            error
        }
    }
}

export function productSearch(searchQuery) {
    const api_server = Environment.api_host("SEARCH");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/v2/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startProductSearch(searchQuery));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, searchQuery, true);
            dispatch(endProductSearch(searchQuery, response, error));
        } catch (e) {
            dispatch(endProductSearch(searchQuery, null, {
                message: e.message
            }));
            return;
        }
    };
}


function startProductAllSearch(searchQuery) {
    return {
        type: START_PRODUCT_ALL_SEARCH,
        payload: {
            searchQuery
        }
    }
}

function endProductAllSearch(searchQuery, success, error) {
    return {
        type: END_PRODUCT_ALL_SEARCH,
        payload: {
            searchQuery,
            success,
            error
        }
    }
}

export function productAllSearch(searchQuery) {
    const api_server = Environment.api_host("SEARCH");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/v2/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startProductAllSearch(searchQuery));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, searchQuery, false);
            dispatch(endProductAllSearch(searchQuery, response, error));
        } catch (e) {
            dispatch(endProductAllSearch(searchQuery, null, {
                message: e.message
            }));
            return;
        }
    };
}




function stateDeleteOrgProduct() {
    return {
        type: START_DELETE_ORG_PRODUCT,
        payload: {}
    }
}

function endDeleteOrgProduct(success, error) {
    return {
        type: END_DELETE_ORG_PRODUCT,
        payload: {
            success,
            error
        }
    }
}

//Thunk to perform the addProductState
export function deleteOrgProduct(product_id,) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/products/${product_id}/existing?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(stateDeleteOrgProduct());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteOrgProduct(response, error));
        } catch (e) {
            dispatch(endDeleteOrgProduct(null, {
                message: e.message
            }));
            return;
        }
    };
}



function stateDeleteProductState() {
    return {
        type: START_DELETE_PRODUCT_STATE,
        payload: {}
    }
}

function endDeleteProductState(productId, stateId, success, error) {
    return {
        type: END_DELETE_PRODUCT_STATE,
        payload: {
            productId,
            stateId,
            success,
            error
        }
    }
}

//Thunk to perform the addProductState
export function deleteProductState(product_id, state_id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${product_id}/states/${state_id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(stateDeleteProductState());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(endDeleteProductState(product_id, state_id, response, error));
        } catch (e) {
            dispatch(endDeleteProductState(product_id, state_id, null, {
                message: e.message
            }));
            return;
        }
    };
}

export function stateAddProductState() {
    return {
        type: START_ADD_PRODUCT_STATE,
        payload: {}
    }
}

export function endAddProductState(productId, success, error) {
    return {
        type: END_ADD_PRODUCT_STATE,
        payload: {
            productId,
            success,
            error
        }
    }
}

//Thunk to perform the addProductState
export function addProductState(product_id, states) {
    let newStates = [...states]
    let indexItm = newStates.findIndex(item => item === 'existing');
    if (indexItm > -1) {
        newStates.splice(indexItm, 1);
    }
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${product_id}/states?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(stateAddProductState());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, { states: newStates });
            dispatch(endAddProductState(product_id, response, error));
        } catch (e) {
            dispatch(endAddProductState(product_id, null, {
                message: e.message
            }));
            return;
        }
    };
}

function startdeleteUnVerifiedProduct() {
    return {
        type: START_DELETE_UNVERIFIED_PRODUCT,
        payload: {}
    };
}

function enddeleteUnVerifiedProduct(success, error) {
    return {
        type: END_DELETE_UNVERIFIED_PRODUCT,
        payload: {
            success, error
        }
    };
}

//Thunk to perform the deleteUnVerifiedProduct
export function deleteUnVerifiedProduct(product_id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/products/${product_id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startdeleteUnVerifiedProduct());
        try {
            let [response, error] = await HttpFactory.instance().deleteMethod(url);
            dispatch(enddeleteUnVerifiedProduct(response, error));
        } catch (e) {
            dispatch(enddeleteUnVerifiedProduct(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function addProductToIsExisting(query, error) {
    return {
        type: ADD_PRODUCT_TO_IS_EXISTING,
        payload: {
            query,
            error
        }
    };
}

function startProductsFetch(page) {
    return {
        type: START_PRODUCTS_FETCH,
        payload: page
    };
}

function endProductsFetch(success, error) {
    return {
        type: END_PRODUCTS_FETCH,
        payload: {
            success, error
        }
    };
}

function startSubcatOrgProductsFetch(page) {
    return {
        type: START_SUBCAT_ORG_PRODUCTS_FETCH,
        payload: page
    };
}

function endSubcatOrgProductsFetch(success, error) {
    return {
        type: END_SUBCAT_ORG_PRODUCTS_FETCH,
        payload: {
            success, error
        }
    };
}

function startRecommendedProductsFetch(page) {
    return {
        type: START_RECOMMENDED_PRODUCTS_FETCH,
        payload: page
    };
}

function endRecommendedProductsFetch(success, error) {
    return {
        type: END_RECOMMENDED_PRODUCTS_FETCH,
        payload: {
            success, error
        }
    };
}



function startRecommendedProductsFetchToAdd(page) {
    return {
        type: START_RECOMMENDED_PRODUCTS_FETCH_TO_ADD,
        payload: page
    };
}

function endRecommendedProductsFetchToAdd(success, error) {
    return {
        type: END_RECOMMENDED_PRODUCTS_FETCH_TO_ADD,
        payload: {
            success, error
        }
    };
}

function startCategoryWiseProductsFetch(page) {
    return {
        type: START_CATEGORY_WISE_PRODUCTS_FETCH,
        payload: page
    };
}

function endCategoryWiseProductsFetch(success, error) {
    return {
        type: END_CATEGORY_WISE_PRODUCTS_FETCH,
        payload: {
            success, error
        }
    };
}

function startCategoryWiseOrgProductsFetch(page) {
    return {
        type: START_CATEGORY_WISE_ORG_PRODUCTS_FETCH,
        payload: page
    };
}

function endCategoryWiseOrgProductsFetch(success, error) {
    return {
        type: END_CATEGORY_WISE_ORG_PRODUCTS_FETCH,
        payload: {
            success, error
        }
    };
}

//Thunk to perform the authorizedUser
export function fetchProducts(subcategoryId, page, pageSize, query, type, featureId, num_users, forOrg, additionalParam) {
    if (forOrg === undefined) {
        forOrg = 0
    }
    if (additionalParam === undefined) {
        additionalParam = {}
    }
    return async (dispatch) => {
        dispatch(startProductsFetch(page));
        try {
            let [response, error] = await ProductFactory.instance().getProducts(subcategoryId, page, pageSize, query, type, featureId, num_users, forOrg, additionalParam);
            if (!(error && error.message === 'cancelled')) {
                dispatch(endProductsFetch(response, error));
            }
        } catch (e) {
            dispatch(endProductsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

// API call for fetch category wise product
export function fetchSubCategoryWiseProducts(category_id, sub_category_id, page, pageSize, query, additionalParam = {}) {
    const api_server = Environment.api_host("SEARCH");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/product/categories/${category_id}/subactegories/${sub_category_id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startProductsFetch(page));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, {
                page: page,
                pageSize: pageSize,
                query: query,
                ...additionalParam
            });
            if (!(error && error.message === 'cancelled')) {
                dispatch(endProductsFetch(response, error));
            }
        } catch (e) {
            dispatch(endProductsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}


//Thunk to perform the authorizedUser
export function fetchSubcatOrgProducts(subcategoryId, page, pageSize, query, type, featureId, num_users, forOrg, additionalParam) {
    if (forOrg === undefined) {
        forOrg = 0
    }
    if (additionalParam === undefined) {
        additionalParam = {}
    }
    return async (dispatch) => {
        dispatch(startSubcatOrgProductsFetch(page));
        try {
            let [response, error] = await ProductFactory.instance().getProductsV2(subcategoryId, page, pageSize, query, type, featureId, num_users, forOrg, { ...additionalParam, existingType: "YES" });
            if (!(error && error.message === 'cancelled')) {
                dispatch(endSubcatOrgProductsFetch(response, error));
            }
        } catch (e) {
            dispatch(endSubcatOrgProductsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function getNewResponseWithFormat(data, params) {

    let newData = [];
    if (data?.body?.items) {
        data?.body?.items.forEach(function (itm) {
            let IsExisting = false;
            if (itm.states !== undefined && itm.states !== null) {
                if (Object.keys(itm.states).indexOf('existing') !== -1) {
                    IsExisting = true;
                }
            }

            let obj = {
                "ID": itm?.id,
                "Name": itm?.name,
                "CompanyName": itm?.company_name,
                "ProductLogo": itm?.logo,
                "DeletedAt": null,
                "CreatedAt": "0001-01-01T00:00:00Z",
                "UpdatedAt": "0001-01-01T00:00:00Z",
                "IsTop": false,
                "Description": "",
                "Stats": itm?.states,
                IsExisting: IsExisting
            }
            newData.push(obj)
        })
    }

    let newResponse = {
        "page": params?.page,
        "pageSize": params?.page_size,
        "total": data?.body?.total,
        "ProductCategory": null,
        "SubCategory": null,
        "Data": newData
    }
    return newResponse;
}

export function fetchRecommendedProducts_bkp(subCategory, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/categories/0/subcategories/${subCategory}/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startRecommendedProductsFetch());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            if (!(error && error.message === 'cancelled')) {
                dispatch(endRecommendedProductsFetch(response, error));
            }
        } catch (e) {
            dispatch(endRecommendedProductsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

// API call for fetch recommended product
export function fetchRecommendedProducts(subCategory, params) {
    let newParameters = {
        field_setname: "all",
        text: params?.query,
        page: params.page || 0,
        page_size: params?.pageSize || 50,
        sort_fields: [],
        filter_fields: {},
        merge_existing_others: true,
    };

    const api_server = Environment.api_host("SEARCH");
    const timestamp = (new Date()).getTime();
    let url = `${api_server}/v2/dsl/expent_catalogue?t=${timestamp}`;
    // const url   =   `${api_server}/categories/0/subcategories/${subCategory}/products?t=${timestamp}`;

    return async (dispatch) => {
        dispatch(startRecommendedProductsFetch());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, newParameters);
            if (!(error && error.message === 'cancelled')) {
                // console.log(response, 'ResponseNewWala')
                let newResponseFormat = getNewResponseWithFormat(response, newParameters);
                dispatch(endRecommendedProductsFetch(newResponseFormat, error));
            }
        } catch (e) {
            dispatch(endRecommendedProductsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function fetchRecommendedProductsToAdd(subCategory, params) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/categories/0/subcategories/${subCategory}/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startRecommendedProductsFetchToAdd());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, params);
            if (!(error && error.message === 'cancelled')) {
                dispatch(endRecommendedProductsFetchToAdd(response, error));
            }
        } catch (e) {
            dispatch(endRecommendedProductsFetchToAdd(null, {
                message: e.message
            }));
            return;
        }
    };
}

// API call for fetch category wise product
export function fetchCategoryWiseProducts(category_id, page, pageSize, query, existingType, additionalParam) {

    const api_server = Environment.api_host("SEARCH");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/product/categories/${category_id}?t=${timestamp}`;
    if (existingType !== undefined) {
        url = url + "&existingType=" + existingType
    }
    if (additionalParam === undefined) {
        additionalParam = {}
    }
    return async (dispatch) => {
        dispatch(startCategoryWiseProductsFetch(page));
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, {
                page: page,
                pageSize: pageSize,
                query: query,
                ...additionalParam
            });
            if (!(error && error.message === 'cancelled')) {
                dispatch(endCategoryWiseProductsFetch(response, error));
            }
        } catch (e) {
            dispatch(endCategoryWiseProductsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

export function fetchCategoryWiseOrgProducts(category_id, page, pageSize, query) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/categories/${category_id}/products?t=${timestamp}&page=${page}&pageSize=${pageSize}&query=${query}&existingType=YES`;
    return async (dispatch) => {
        dispatch(startCategoryWiseOrgProductsFetch(page));
        try {
            let [response, error] = await HttpFactory.instance().getMethodV3(url, false, false);
            if (!(error && error.message === 'cancelled')) {
                dispatch(endCategoryWiseOrgProductsFetch(response, error));
            }
        } catch (e) {
            dispatch(endCategoryWiseOrgProductsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}



function startProductDetailsFetch() {
    return {
        type: START_PRODUCT_DETAILS_FETCH,
        payload: {}
    };
}

function endProductDetailsFetch(success, error) {
    return {
        type: END_PRODUCT_DETAILS_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchProductDetails(product_id) {
    const api_server = Environment.api_host("EVALUATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product/${product_id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startProductDetailsFetch());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endProductDetailsFetch(response, error));
        } catch (e) {
            dispatch(endProductDetailsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}


export function fetchProductDetailsPage(product_id) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/master/${product_id}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startProductDetailsFetch());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endProductDetailsFetch(response, error));
        } catch (e) {
            dispatch(endProductDetailsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}



/**
 *  Fetch recommended products
 */


function startRecommendationProducts() {
    return {
        type: START_RECOMMENDED_PRODUCTS_FETCH,
        payload: {}
    };
}

function endRecommendationProducts(success, error) {
    return {
        type: END_RECOMMENDED_PRODUCTS_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchRecommendationProducts(subCategory, params) {
    const api_server = Environment.api_host("RECOMMENDATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/product-recommendation?t=${timestamp}&subcategory=${subCategory}&featureId=${(params.feature_ids || []).join(",")}`;
    return async (dispatch) => {
        dispatch(startRecommendationProducts());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endRecommendationProducts(response, error));
        } catch (e) {
            dispatch(endRecommendationProducts(null, {
                message: e.message
            }));
            return;
        }
    };
}

/**
 *  Fetch  product Question details fetch
 */


function startProductRequirementDetailsFetch() {
    return {
        type: START_PRODUCT_REQUIREMENT_DETAIL_FETCH,
        payload: {}
    };
}

function endProductRequirementDetailsFetch(success, error) {
    return {
        type: END_PRODUCT_REQUIREMENT_DETAIL_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchProductRequirementDetails(product_ids, evaluation_id, pricingUrl) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/master/requirements?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startProductRequirementDetailsFetch());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, {
                product_ids: product_ids,
                evaluation_id,
                url: pricingUrl
            });
            dispatch(endProductRequirementDetailsFetch(response, error));
        } catch (e) {
            dispatch(endProductRequirementDetailsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startCategorySubCategoryWiseProductsFetch(page) {
    return {
        type: START_CATEGORY_SUBCATEGORY_WISE_ORG_PRODUCTS_FETCH,
        payload: page
    };
}

function endCategorySubCategoryWiseProductsFetch(success, error) {
    return {
        type: END_CATEGORY_SUBCATEGORY_WISE_ORG_PRODUCTS_FETCH,
        payload: {
            success, error
        }
    };
}

//Thunk to perform the authorizedUser
export function fetchCategorySubCategoryWiseProducts(categoryId, subcategoryId, page, pageSize, query, additionalParam = null) {
    return async (dispatch) => {
        dispatch(startCategorySubCategoryWiseProductsFetch(page));
        try {


            let [response, error] = await ProductFactory.instance().getCategorySubCategoryWiseProducts(categoryId, subcategoryId, page, pageSize, query, additionalParam);
            dispatch(endCategorySubCategoryWiseProductsFetch(response, error));
        } catch (e) {
            dispatch(endCategorySubCategoryWiseProductsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startProductReactionFetch() {
    return {
        type: START_PRODUCTS_REACTION_FETCH,
        payload: {}
    };
}

function endProductReactionFetch(success, error, productType) {
    return {
        type: END_PRODUCTS_REACTION_FETCH,
        payload: {
            success,
            error,
            productType
        }
    };
}

// API call for fetch products reaction
export function fetchProductReaction(productType, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/master/get-reactions?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startProductReactionFetch());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endProductReactionFetch(response, error, productType));
        } catch (e) {
            dispatch(endProductReactionFetch(null, { message: e.message }, productType));
            return;
        }
    };
}


function startProductReactionByTypeFetch() {
    return {
        type: START_PRODUCTS_REACTION_BY_TYPE_FETCH,
        payload: {}
    };
}

function endProductReactionByTypeFetch(success, error, type) {
    return {
        type: END_PRODUCTS_REACTION_BY_TYPE_FETCH,
        payload: {
            success,
            error,
            type
        }
    };
}

export function fetchProductReactionByType(page, pageSize, query, source, sourceData) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/reactions/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startProductReactionByTypeFetch());
        try {
            var data = {
                page: page,
                pageSize: pageSize,
                query: query,
                source: source,
                sourceData: sourceData,
            }
            let [response, error] = await HttpFactory.instance().getMethod(url, data);
            dispatch(endProductReactionByTypeFetch(response, error, source));
        } catch (e) {
            dispatch(endProductReactionByTypeFetch(null, { message: e.message }, source));
            return;
        }
    };
}


function startComplementaryProductReactionFetch() {
    return {
        type: START_COMPLEMENTARY_PRODUCTS_REACTION_FETCH,
        payload: {}
    };
}

function endComplementaryProductReactionFetch(success, error, productType) {
    return {
        type: END_COMPLEMENTARY_PRODUCTS_REACTION_FETCH,
        payload: {
            success,
            error,
            productType
        }
    };
}


// API call for fetch products reaction
export function fetchComplementaryProductReaction(productType, data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/master/get-reactions?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startComplementaryProductReactionFetch());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            console.log('fetchComplementaryProductReaction', response, productType, error)
            dispatch(endComplementaryProductReactionFetch(response, error, productType));
        } catch (e) {
            dispatch(endComplementaryProductReactionFetch(null, { message: e.message }, productType));
            return;
        }
    };
}


function startUpdateProductReaction() {
    return {
        type: START_UPDATE_PRODUCTS_REACTION,
        payload: {}
    };
}

function endUpdateProductReaction(success, error, reactions, productType) {
    return {
        type: END_UPDATE_PRODUCTS_REACTION,
        payload: {
            success,
            error,
            reactions,
            productType,
        }
    };
}

// API call for fetch products reaction
export function UpdateProductReaction(productType, reactions) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/master/reaction?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateProductReaction());
        try {
            let [response, error] = await HttpFactory.instance().postMethod(url, { reactions });
            dispatch(endUpdateProductReaction(response, error, reactions, productType));
        } catch (e) {
            dispatch(endUpdateProductReaction(null, {
                message: e.message
            }, reactions, productType));
            return;
        }
    };
}

function startWishListedProductsFetch() {
    return {
        type: START_WISHLISTED_PRODUCTS_FETCH,
        payload: {}
    };
}

function endWishListedProductsFetch(success, error) {
    return {
        type: END_WISHLISTED_PRODUCTS_FETCH,
        payload: {
            success, error
        }
    };
}

// API call for fetch wishlisted product
export function fetchWishListedProducts() {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/master/user/wishlist?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startWishListedProductsFetch());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endWishListedProductsFetch(response, error));
        } catch (e) {
            dispatch(endWishListedProductsFetch(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startWishListedProductListFetch() {
    return {
        type: START_WISHLISTED_PRODUCTS_LIST_FETCH,
        payload: {}
    };
}

function endWishListedProductListFetch(success, error, type) {
    return {
        type: END_WISHLISTED_PRODUCTS_LIST_FETCH,
        payload: {
            success, error, type
        }
    };
}


// API call for fetch wishlisted product
export function fetchWishListedProductList(page, pageSize, query, type) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/wishlist/products?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startWishListedProductListFetch());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url, {
                page: page,
                pageSize: pageSize,
                query: query
            });
            dispatch(endWishListedProductListFetch(response, error, type));
        } catch (e) {
            dispatch(endWishListedProductListFetch(null, {
                message: e.message
            }, type));
            return;
        }
    };
}

function startOrgWishListedProductListFetch() {
    return {
        type: START_ORG_WISHLISTED_PRODUCTS_LIST_FETCH,
        payload: {}
    };
}

function endOrgWishListedProductListFetch(success, error, type) {
    return {
        type: END_ORG_WISHLISTED_PRODUCTS_LIST_FETCH,
        payload: {
            success, error, type
        }
    };
}

// API call for fetch wishlisted product
export function fetchOrgWishlistedProducts(type) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/wishlist?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startOrgWishListedProductListFetch());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endOrgWishListedProductListFetch(response, error, type));
        } catch (e) {
            dispatch(endOrgWishListedProductListFetch(null, {
                message: e.message
            }, type));
            return;
        }
    };
}


function startWishListedProductsAdd() {
    return {
        type: START_WISHLISTED_PRODUCTS_ADD,
        payload: {}
    };
}

function endWishListedProductsAdd(wishlistedData, success, error) {
    return {
        type: END_WISHLISTED_PRODUCTS_ADD,
        payload: {
            wishlistedData, success, error
        }
    };
}

export function addWishlistedProducts(data) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/master/wishlist?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startWishListedProductsAdd());
        try {
            let [response, error] = await await HttpFactory.instance().postMethod(url, data);
            dispatch(endWishListedProductsAdd(data, response, error));
        } catch (e) {
            dispatch(endWishListedProductsAdd([], null, {
                message: e.message
            }));
            return;
        }
    };
}

function startCheckWishlistedProducts() {
    return {
        type: START_CHECK_WISHLISTED_PRODUCT_FETCH,
        payload: {}
    };
}

function endCheckWishlistedProducts(success, error) {
    return {
        type: END_CHECK_WISHLISTED_PRODUCT_FETCH,
        payload: {
            success, error
        }
    };
}

// API call for fetch wishlisted product
export function checkWishlistedProducts(productIDs) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/master/is_wishlisted?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startCheckWishlistedProducts());
        try {
            var data = {
                "productIDs": productIDs
            }
            let [response, error] = await HttpFactory.instance().postMethod(url, data);
            dispatch(endCheckWishlistedProducts(response, error));
        } catch (e) {
            dispatch(endCheckWishlistedProducts(null, {
                message: e.message
            }));
            return;
        }
    };
}


export function setSortFilterOptions(sortValue, sortType) {
    return {
        type: SET_ORG_PRODUCT_SORT_FILTER_OPTIONS,
        payload: { sortValue, sortType }
    };
}

export function setAllProductFilterOptions(filterData) {
    return {
        type: SET_ALL_PRODUCT_FILTER_OPTIONS,
        payload: { filterData }
    };
}

function startFetchPersonalizedRecommendationsProducts() {
    return {
        type: START_PERSONALIZED_RECOMMENDATIONS_PRODUCTS,
        payload: {}
    };
}

function endFetchPersonalizedRecommendationsProducts(success, error) {
    return {
        type: END_PERSONALIZED_RECOMMENDATIONS_PRODUCTS,
        payload: {
            success,
            error
        }
    };
}

export function fetchPersonalizedRecommendationsProducts(page, query) {
    const api_server = Environment.api_host("RECOMMENDATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/user-recommendation?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchPersonalizedRecommendationsProducts());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchPersonalizedRecommendationsProducts(response, error));
        } catch (e) {
            dispatch(endFetchPersonalizedRecommendationsProducts(null, {
                message: e.message
            }));
            return;
        }
    };
}

function startFetchCompanyRecommendationProducts() {
    return {
        type: START_COMPANY_RECOMMENDATIONS_PRODUCTS,
        payload: {}
    };
}

function endFetchCompanyRecommendationProducts(success, error) {
    return {
        type: END_COMPANY_RECOMMENDATIONS_PRODUCTS,
        payload: {
            success,
            error
        }
    };
}

export function fetchCompanyRecommendationsProducts(page, query) {
    const api_server = Environment.api_host("RECOMMENDATION");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/company-recommendation?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchCompanyRecommendationProducts());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchCompanyRecommendationProducts(response, error));
        } catch (e) {
            dispatch(endFetchCompanyRecommendationProducts(null, {
                message: e.message
            }));
            return;
        }
    };
}



export function showProductWhistedUserDialog(productId) {
    return {
        type: SHOW_PRODUCT_WHISLISTED_USERS_DIALOG,
        payload: productId
    };
}


export function hideProductWhistedUserDialog() {
    return {
        type: HIDE_PRODUCT_WHISLISTED_USERS_DIALOG,
        payload: {}
    };
}


function startFetchProductWishListedUsers() {
    return {
        type: START_FETCH_PRODUCT_WISHLISTED_USERS,
        payload: {}
    };
}

function endFetchProductWishListedUsers(success, error) {
    return {
        type: END_FETCH_PRODUCT_WISHLISTED_USERS,
        payload: {
            success,
            error
        }
    };
}

export function fetchProductWishListedUsers(productId, page = 0, pageSize = 1000) {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/${productId}/wishlisted_by?page=${page}&pageSize=${pageSize}&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchProductWishListedUsers());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchProductWishListedUsers(response, error));
        } catch (e) {
            dispatch(endFetchProductWishListedUsers(null, {
                message: e.message
            }));
            return;
        }
    };
}


function startFetchInReviewProducts(page) {
    return {
        type: START_INREVIEW_PRODUCTS_FETCH,
        payload: page
    };
}

function endFetchInReviewProducts(success, error) {
    return {
        type: END_INREVIEW_PRODUCTS_FETCH,
        payload: {
            success, error
        }
    };
}

export function fetchInReviewProducts(page = 0, pageSize = 1000, query = '') {
    const api_server = Environment.api_host("PRODUCT");
    const timestamp = (new Date()).getTime();
    const url = `${api_server}/org/inreview/products?page=${page}&pageSize=${pageSize}&query=${query}&t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startFetchInReviewProducts());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchInReviewProducts(response, error));
        } catch (e) {
            dispatch(endFetchInReviewProducts(null, {
                message: e.message
            }));
            return;
        }
    };
}