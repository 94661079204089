import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import * as Validator from "util/Validation";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Switch, Typography, TableContainer
} from '@material-ui/core';
import deleteIcon from "assets/images/delete.svg";
import Image from "Components/Common/image.jsx";
import { Avatar } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import addUsers from "assets/images/add_profile.svg";
import classnames from "classnames";
import Tooltip from '@material-ui/core/Tooltip';
// import SearchUserPoper from "./Common/SearchUserPoper";

import {
  fetchWorkflowConditionRules,
  addOrgWorkflowAction,
  updateOrgWorkflowAction,
  showApproverSearchDialog,
  updateOrgWorkflowComponent,
  hideMasterComponentDialog
} from "redux/evaluation/workflow/action";
import { showEvaluationCollaboratorDialog, hideEvaluationCollaboratorDialog } from "redux/evaluate/action";
import SearchUserAndTeamPoper from "Components/Common/TeamsAndUser/SearchUserAndTeamPoper";

import { showApprovalCriteriaRuleDialog } from "redux/common/action";
import ApprovalCriteriaRuleDialog from "./Common/ApprovalCriteriaRuleDialog";

import { approvalStages } from "util/Workflow";
import ApprovalCriteriaConditionDialog from "./Common/ApprovalCriteriaConditionDialog";
import { fetchUserProfileMeta } from "redux/departments/action"

const connectedProps = (state) => ({
  fetchWorkflowConditionRulesProgress: state.evaluationWorkflow.fetchWorkflowConditionRulesProgress,
  workflowConditionRules: state.evaluationWorkflow.workflowConditionRules,
  orgWorkFlowCompList: state.evaluationWorkflow.orgWorkFlowCompList,
  orgWorkFlowActionList: state.evaluationWorkflow.orgWorkFlowActionList,
  collaboratorDialogOpen: state.evaluate.evaluationCollaboratorDialogOpen,
  showCriteriaApprovalSearchDialog: state.evaluationWorkflow.showCriteriaApprovalSearchDialog,
  workflowFormCompList: state.master.workflowFormCompList,
  isOpenRuleDialog: state.common.isApprovalCriteriaRuleDialogOpen,
});

const connectionActions = {
  fetchWorkflowConditionRules: fetchWorkflowConditionRules,
  addOrgWorkflowAction: addOrgWorkflowAction,
  updateOrgWorkflowAction: updateOrgWorkflowAction,
  showApproverSearchDialog: showApproverSearchDialog,
  updateOrgWorkflowComponent: updateOrgWorkflowComponent,
  hideMasterComponentDialog: hideMasterComponentDialog,
  showEvaluationCollaboratorDialog: showEvaluationCollaboratorDialog,
  hideEvaluationCollaboratorDialog: hideEvaluationCollaboratorDialog,
  showApprovalCriteriaRuleDialog: showApprovalCriteriaRuleDialog,
  fetchUserProfileMeta: fetchUserProfileMeta
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  decisionRoot: {
    "& ul.answers": {
      listStyle: "none",
      paddingLeft: 0,
      "& li": {
        border: "1px solid #EBEBEB",
        bordeRadius: "4px",
        paddingLeft: "8px",
        marginBottom: "8px",
        "& span": {
          fontSize: 13,
          color: "#6D7278",
        },
      },
    },
  },
  inputCriteria: {
    minHeight: 38,
    padding: "2px 8px",
    border: "1px solid #cccccc",
    borderRadius: 3,
    fontSize: 15,
    color: "rgb(51, 51, 51)",
    width: '85%',
    marginRight: 10
  },
  loader: {
    padding: theme.spacing(4),
    textAlign: "center"
  },
  newUserBtn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 38,
    minWidth: 140,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#3C3C3C",
    },
  },
  flexWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  saveBtnSection: {
    position: "fixed",
    bottom: 0,
    right: 12,
    marginBottom: '0%',
    left: "auto",
    width: "58vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: '#f1f1f1',
    padding: '10px'
  },
  reqName: {
    width: '5%'
  },
  criteriaName: {
    width: '30%'
  },
  rulesCls: {
    width: '10%'
  },
  approverData: {
    width: '30%',
    textAlign: 'center',
  },
  approversFlex: {
    display: 'flex',
    justifyContent: 'end',
    marginRight: '46%',
    "& [class*=MuiAvatar-root]": {
      width: 25,
      height: 25,
      fontSize: 14
    },
    "& [class*=MuiAvatarGroup-avatar]": {
      margin: 0
    }
  },
  actions: {
    width: '10%'
  },
  criteriaTable: {
    marginTop: 20
  },
  actionTxt: {
    color: '#4175DF',
    width: 'max-content',
    fontSize: theme.spacing(1.75),
    cursor: 'pointer'
  },
  label: {
    fontWeight: 500,
    fontSize: 14
  },
  stage: {
    marginBottom: 20,
    '& .Mui-disabled': {
      color: '#4b86ff!important'
    }
  },

});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? "white" : 'initial',
  ...draggableStyle
});

class DecisionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowLoader: true,
      criteriaName: '',
      criterias: [],
      approvers: [],
      selectedIndex: null,
      selectedCriteria: '',
      approvalOverRide: false,
      criteriaRuleData: {},
      criteriaConditionData: {},
      isShowRuleDialog: false,
      isShowConditionDialog: false,
      approvalStages: [],
      isFinalApproval: true,
      isAddToProject: false,
      manager_approval: false,
      department_approval: false,
      manager_level: 1,
    }
    this.saveApprovalConfiguration = this.saveApprovalConfiguration.bind(this);
    this.addNewCriteria = this.addNewCriteria.bind(this);
    this.addCriteriaColloborator = this.addCriteriaColloborator.bind(this);
    this.removeCriteria = this.removeCriteria.bind(this);
    this.avatarName = this.avatarName.bind(this);
    this.saveRuleForCriteria = this.saveRuleForCriteria.bind(this);
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  componentDidMount() {
    // console.log(this.props.builderFor, 'builderFor-Approval')
    // console.log(this.props.projectApprovalCriterias, 'projectApprovalCriterias-Approval')
    // console.log(this.props.componentDetails, 'componentDetailsApprovals')
    // console.log(this.props.selectedComponentData, 'selectedComponentData');
    // console.log(this.props.workflowFormCompList, 'workflowFormCompList');
    this.props.fetchUserProfileMeta()

    if (this.props.builderFor === 'NEW_WORKFLOW') {
      this.initConfigurations();
      this.initStagesConfigurations();
      this.initFinalApprovalConfig();
    } else {
      this.initConfigurationsForProject();
      this.initStagesConfigurations();
      this.initFinalApprovalConfig();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.builderFor === 'PROJECT' && this.props.projectApprovalCriterias.length !== prevProps.projectApprovalCriterias.length) {
      this.initConfigurationsForProject()
    }
  }

  getMasterApprovalForm = () => {
    var approvalForm = this.props.workflowFormCompList.filter(o => o.Name === 'evaluation_approval')[0];
    return approvalForm;
  }

  getMasterApprovalFormConfig = (type) => {
    var approvalForm = this.props.workflowFormCompList.filter(o => o.Name === type)[0];
    return approvalForm;
  }

  initConfigurations() {
    var approvalForm = this.getMasterApprovalForm();
    if (this.props.componentDetails?.data?.configurations !== null && this.props.componentDetails?.data?.configurations !== undefined && this.props.componentDetails?.data?.configurations.length > 0) {
      var approvalConfigurations = this.props.componentDetails?.data?.configurations;
      console.log(approvalConfigurations, 'approvalConfigurations')
      var formConfigs = approvalConfigurations.filter(o => o.FormID === approvalForm.ID)[0];
      console.log(formConfigs, 'formConfigs')

      if (formConfigs !== null && formConfigs !== undefined && Validator.isJson(formConfigs.Value)) {
        var existingConfigurations = JSON.parse(formConfigs.Value);
        existingConfigurations = existingConfigurations.map((o, k) => {
          if (o.id === undefined || o.id === null || o.id === '') {
            return {
              ...o,
              id: new Date().getTime() + k,
              rules: [],
              conditions: [],
              isNew: false
            }
          } else {
            return {
              ...o,
              conditions: o.conditions || [],
              isNew: false
            };
          }
        })
        console.log(existingConfigurations, 'existingConfigurations')
        this.setState({
          criterias: existingConfigurations,
          approvalOverRide: this.props.componentDetails?.data?.object?.OverrideReponse
        })
      }
    }
  }

  initStagesConfigurations() {
    var stageForm = this.getMasterApprovalFormConfig('approval_stages');
    // console.log(stageForm, 'stageForm')
    if (this.props.componentDetails?.data?.configurations !== null && this.props.componentDetails?.data?.configurations !== undefined && this.props.componentDetails?.data?.configurations.length > 0) {
      var approvalConfigurations = this.props.componentDetails?.data?.configurations;
      var formConfigs = approvalConfigurations.filter(o => o.FormID === stageForm.ID)[0];
      if (formConfigs !== null && formConfigs !== undefined && Validator.isJson(formConfigs.Value)) {
        var existingConfigurations = JSON.parse(formConfigs.Value);
        this.setState({
          approvalStages: existingConfigurations
        })
      } else {
        this.setState({
          approvalStages: approvalStages
        })
      }
    } else {
      this.setState({
        approvalStages: approvalStages
      })
    }
  }

  initFinalApprovalConfig() {
    var stageForm = this.getMasterApprovalFormConfig('is_final_approval');
    // console.log(stageForm, 'stageForm')
    if (this.props.componentDetails?.data?.configurations !== null && this.props.componentDetails?.data?.configurations !== undefined && this.props.componentDetails?.data?.configurations.length > 0) {
      var approvalConfigurations = this.props.componentDetails?.data?.configurations;
      var formConfigs = approvalConfigurations.filter(o => o.FormID === stageForm.ID)[0];
      console.log(formConfigs, 'formConfigs')
      if (formConfigs !== null && formConfigs !== undefined) {
        this.setState({
          isFinalApproval: formConfigs?.Value === 'false' ? false : true
        })
      } else {
        this.setState({
          isFinalApproval: true
        })
      }
    } else {
      this.setState({
        isFinalApproval: true
      })
    }
  }

  initConfigurationsForProject() {
    // console.log(this.props.builderFor, '22222222222222-builderFor-Approval')
    // console.log(this.props.projectApprovalCriterias, '22222222222-projectApprovalCriterias-Approval')
    if (this.props.projectApprovalCriterias && this.props.projectApprovalCriterias.length > 0) {
      let existingConfigurations = []
      this.props.projectApprovalCriterias.forEach(function (criteria) {
        console.log(criteria, 'criteriaAAAAAAAAA')
        let rules = [];
        if (criteria.Rules && criteria.Rules.length > 0) {
          criteria.Rules.forEach(function (rule) {
            let obj = {
              conditions: [],
              action: {
                type: rule?.ActionType,
                from_template: true,
                data: rule?.ActionData
              }
            }
            rule.Elements.forEach(function (ele) {
              obj.conditions.push({
                leftExp: ele.LeftExpression,
                opId: ele.OpID,
                rightExp: ele.RightExpression,
                seq: ele.Seq,
                from_template: true
              })
            })
            rules.push(obj)
          })
        }

        let obj = {
          name: criteria?.CriteriaName,
          criteriaId: criteria?.ID,
          status: criteria?.Status,
          id: criteria?.ID,
          approvers: {
            users: criteria?.Approvers?.Users,
            teams: criteria?.Approvers?.Teams
          },
          rules: rules,
          conditions: criteria?.conditions,
          isNew: false
        }
        existingConfigurations.push(obj)
      })
      this.setState({
        criterias: existingConfigurations,
        isAddToProject: true
      })
    } else {
      this.initConfigurations()
    }
  }

  saveApprovalConfiguration() {
    // console.log(this.state.criterias, 'criteriasss');
    var approvalForm = this.getMasterApprovalForm();
    var stageForm = this.getMasterApprovalFormConfig('approval_stages');
    var finalApprovalForm = this.getMasterApprovalFormConfig('is_final_approval');

    let configurations = [];

    var configDatas = [];
    this.state.criterias.forEach(function (criteria, k) {
      configDatas.push({
        name: criteria?.name,
        id: criteria?.id,
        criteriaId: criteria?.criteriaId || '',
        approvers: criteria?.approvers || '',
        rules: criteria?.rules,
        conditions: criteria?.conditions,
      })
    })

    var item = {
      ID: approvalForm.ID,
      value: JSON.stringify(configDatas)
    }

    configurations.push({ form_id: item.ID, value: item.value })

    var stageItem = {
      ID: stageForm.ID,
      value: JSON.stringify(this.state.approvalStages)
    }

    configurations.push({ form_id: stageItem.ID, value: stageItem.value })

    var finalApprovalItem = {
      ID: finalApprovalForm.ID,
      value: JSON.stringify(this.state.isFinalApproval)
    }

    configurations.push({ form_id: finalApprovalItem.ID, value: finalApprovalItem.value })

    const update = {
      name: this.props.componentDetails?.data?.object?.Name,
      flow_data: this.props.componentDetails?.data?.flowData,
      configurations: configurations,
      override_response: this.state.approvalOverRide
    };
    // console.log(update, this.props.componentDetails, 'update');
    this.props.updateOrgWorkflowComponent(this.props.match.params.id, this.props.componentDetails.id, update);
    this.props.hideMasterComponentDialog();
  }

  saveRuleForCriteria(rules) {
    const { criteriaRuleData } = this.state;
    // console.log(rules, 'rules')
    // console.log(criteriaRuleData, 'criteriaRuleData')
    let index = criteriaRuleData.index;
    var criterias = [...this.state.criterias];
    var criteria = criterias[index];
    criteria.rules = rules;
    this.setState({
      criterias: criterias,
      isShowRuleDialog: false,
      criteriaRuleData: {},
    }, () => {
      this.updateInProject(criteria, 'UPDATE_RULE')
    })
  }

  saveConditionForCriteria(conditions) {
    const { criteriaConditionData } = this.state;
    let index = criteriaConditionData.index;
    var criterias = [...this.state.criterias];
    var criteria = criterias[index];
    criteria.conditions = conditions;
    this.setState({
      criterias: criterias,
      isShowConditionDialog: false,
      criteriaConditionData: {},
    }, () => {
      this.updateInProject(criteria, 'UPDATE_CONDITION')
    })
  }

  addCriteriaColloborator(data) {
    var criterias = [...this.state.criterias];
    var criteria = criterias[data.index];
    console.log(data, 'Dataaaaaaaaaaaaaaaaaaaaaa')
    criteria.approvers = data.users;
    this.setState({
      criterias: criterias,
    }, () => {
      this.updateInProject(criteria, 'UPDATE_APPROVER')
    })
  }

  removeCriteria(index) {
    var criterias = [...this.state.criterias];
    let criteria = criterias[index]
    criterias.splice(index, 1);
    this.setState({
      criterias: criterias
    }, () => {
      if (criteria.isNew === false) {
        this.updateInProject(criteria, 'DELETE')
      }
    })
  }

  addNewCriteria() {
    if (this.state.criteriaName === '' || this.state.criteriaName.length <= 0) {
      return;
    }
    var criterias = [...this.state.criterias];
    var newObject = {
      name: this.state.criteriaName,
      id: new Date().getTime(),
      criteriaId: '',
      approvers: {
        users: [],
        teams: [],
        manager_approval: false,
        department_approval: false,
        manager_level: 1
      },
      rules: [],
      conditions: [],
      isNew: true,
    }
    criterias.push(newObject);
    this.setState({
      criterias: criterias,
      criteriaName: ''
    }, () => {
      this.updateInProject(newObject, 'ADD')
    })
  }

  updateInProject(data, type) {
    if (this.props.builderFor === 'PROJECT' && this.props.isEvalStarted && this.state.isAddToProject) {
      this.props.addUpdateApprovalCriterias(this.props.componentDetails, data, type)
    }
  }

  updateStage(index) {
    let stages = this.state.approvalStages;
    let stage = stages[index];
    stage.isChecked = stage.isChecked ? false : true;
    this.setState({
      stages: stages
    })
  }

  onCriteriaDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { destination, draggableId, source } = result;
    if (draggableId && destination.index !== source.index) {
      const newCriterias = reorder(this.state.criterias, source.index, destination.index);

      this.setState({
        criterias: newCriterias,
      });
    }
  }

  render() {
    const classes = this.props.classes;
    const { approvalStages, isFinalApproval } = this.state;
    console.log(this.state.criterias, 'criteriaApprovals')
    return (
      <div className={classes.decisionSection}>

        <div style={{ marginBottom: 16 }}>
          <label className={classes.label}>Final Approval</label>
          <Switch
            color="primary"
            disabled={this.props.workflowStatus === 2 ? true : false}
            checked={isFinalApproval}
            onChange={(e, v) => {
              var newValue = isFinalApproval === '' || isFinalApproval === false ? true : false;
              this.setState({
                isFinalApproval: newValue
              })
            }}
            name="gilad"
          />
        </div>

        {<div className={classes.stage}>
          <label className={classes.label}>Options</label>
          {approvalStages && approvalStages.map((stage, k) => {
            return <div title={stage.isDefault ? 'Default Stage' : ''}>
              <Switch
                color="primary"
                disabled={stage.isDefault ? true : false}
                checked={stage.isChecked}
                onChange={(e, v) => {
                  this.updateStage(k)
                }}
                name={stage.name}
              />
              <label className={classes.label}>{stage.name}</label>
            </div>
          })}
        </div>}

        <div className={classes.flexWrap}>
          <input
            className={classes.inputCriteria}
            disabled={this.props.workflowStatus === 2 ? true : false}
            placeholder='Enter Approval Step'
            onChange={(e) => {
              this.setState({
                criteriaName: e.target?.value
              })
              // this.handleChange(e.target?.value || '', index)
            }}
            value={this.state.criteriaName}
          />
          <button
            type="button"
            disabled={this.props.workflowStatus === 2 ? true : false}
            className={classes.newUserBtn}
            onClick={() => {
              this.addNewCriteria();
            }}>
            + Add
          </button>
        </div>

        <div>

          {this.state.criterias.length > 0 && <TableContainer className={classes.tableContainer} style={{ marginBottom: 80 }}>
            <Table stickyHeader className={classes.criteriaTable} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.reqName}></TableCell>
                  <TableCell className={classes.reqName}></TableCell>
                  <TableCell className={classes.criteriaName}>Approval Step</TableCell>
                  <TableCell className={classes.criteriaName}>Conditions</TableCell>
                  <TableCell className={classes.approverData}>Approvers</TableCell>
                  <TableCell className={classes.rulesCls}>Rules</TableCell>
                  <TableCell className={classes.actions}>Action</TableCell>
                </TableRow>
              </TableHead>
              {/* <TableBody> */}
              <DragDropContext onDragEnd={this.onCriteriaDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <TableBody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {this.state.criterias.map((criteria, k) => {

                        let ruleMessage = '';
                        let canUpdateRule = true;
                        if (this.props.approvedCriteriesIds.indexOf(criteria.criteriaId) !== -1 && criteria.rules.length > 0) {
                          ruleMessage = 'Show Rule'
                          canUpdateRule = false;
                        } else if (this.props.approvedCriteriesIds.indexOf(criteria.criteriaId) !== -1 && criteria.rules.length <= 0) {
                          ruleMessage = ' '
                          canUpdateRule = false;
                        } else if (criteria.rules.length <= 0) {
                          ruleMessage = 'Add Rule'
                        } else if (criteria.rules.length > 0) {
                          ruleMessage = 'Update Rule'
                        }

                        let selectedUsersAndTeams = [];
                        // if (criteria?.approvers.users) {
                        //   selectedUsersAndTeams = selectedUsersAndTeams.concat(criteria?.approvers.users)
                        // }
                        // if (criteria?.approvers.teams) {
                        //   selectedUsersAndTeams = selectedUsersAndTeams.concat(criteria?.approvers.teams)
                        // }

                        if (criteria?.approvers.teams) {
                          criteria?.approvers.teams.forEach(item => {
                            item.type = 'GROUP';
                            selectedUsersAndTeams.push(item);
                          })
                        }

                        if (criteria?.approvers.users) {
                          criteria?.approvers.users.forEach(item => {
                            item.type = 'USER';
                            selectedUsersAndTeams.push(item);
                          })
                        }

                        let manager_approval = false;
                        let department_approval = false;
                        let manager_level = 1;

                        if (criteria?.approvers.manager_approval) {
                          manager_approval = criteria?.approvers.manager_approval
                        }

                        if (criteria?.approvers.department_approval) {
                          department_approval = criteria?.approvers.department_approval
                        }

                        if (criteria?.approvers.manager_level) {
                          manager_level = criteria?.approvers.manager_level
                        }

                        let conditionMessage = '';
                        let canUpdateCondition = true;
                        if (this.props.approvedCriteriesIds.indexOf(criteria.criteriaId) !== -1 && criteria.conditions.length > 0) {
                          conditionMessage = 'Show Conditions'
                          canUpdateCondition = false;
                        } else if (this.props.approvedCriteriesIds.indexOf(criteria.criteriaId) !== -1 && criteria.conditions.length <= 0) {
                          conditionMessage = ' '
                          canUpdateCondition = false;
                        } else if (criteria.conditions.length <= 0) {
                          conditionMessage = 'Add Conditions'
                        } else if (criteria.conditions.length > 0) {
                          conditionMessage = 'Update Conditions'
                        }

                        return <Draggable key={String(k)} draggableId={String(k)} index={k}>
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <TableCell className={classes.reqName}><DragIndicatorOutlinedIcon style={{ color: 'grey' }} /></TableCell>
                              <TableCell className={classes.reqName}>{k + 1}</TableCell>
                              <TableCell className={classes.criteriaName}>
                                {criteria.name}
                              </TableCell>
                              <TableCell className={classes.rulesCls}>
                                <Typography
                                  className={classes.actionTxt}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                      criteriaConditionData: {
                                        conditions: criteria?.conditions || [],
                                        isApproval: true,
                                        criteria: criteria,
                                        criteriaId: criteria?.id,
                                        id: this.props.match.params?.evaluation_id,
                                        requirements: this.state.criterias,
                                        criterias: this.state.criterias,
                                        index: k,
                                        canUpdateCondition: canUpdateCondition
                                      },
                                      isShowConditionDialog: true
                                    })
                                  }}
                                >
                                  {conditionMessage}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.approverData}>
                                <div className={classes.approversFlex}>

                                  {manager_approval && <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>
                                    {'Manager Approval'}</span>} arrow>
                                    <Avatar style={{ zIndex: 0, backgroundColor: "#4b86ff", width: 22, height: 22, marginTop: 2 }} key={k}> {'M'}</Avatar></Tooltip>}

                                  {department_approval && <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>
                                    {'Department Approval'}</span>} arrow>
                                    <Avatar style={{ zIndex: 0, backgroundColor: "#ffb34b", width: 22, height: 22, marginTop: 2 }} key={k}> {'D'}</Avatar></Tooltip>}


                                  {criteria.approvers !== undefined && criteria.approvers !== null && <AvatarGroup max={3}>
                                    {
                                      selectedUsersAndTeams.map((user, k) => {
                                        return <Tooltip placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{user.Name + "\n" + user.Email}</span>} arrow>
                                          <Avatar style={{ zIndex: 0, backgroundColor: user.type === "GROUP" ? "#62a00b" : "#bdbdbd" }} key={k}> {this.avatarName(user.Name)}</Avatar></Tooltip>
                                      })
                                    }
                                  </AvatarGroup>}
                                  {<Image className={classnames(classes.addUser, 'evaluate-add-user')} alt="add user" src={addUsers} onClick={(event) => {
                                    this.setState({
                                      approvers: selectedUsersAndTeams,
                                      manager_approval: manager_approval,
                                      department_approval: department_approval,
                                      manager_level: manager_level,
                                      selectedIndex: k,
                                      selectedCriteria: criteria
                                    }, () => {
                                      this.props.showEvaluationCollaboratorDialog();
                                    })
                                  }} />}
                                </div>
                              </TableCell>
                              <TableCell className={classes.rulesCls}>
                                <Typography
                                  className={classes.actionTxt}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                      criteriaRuleData: {
                                        rules: criteria?.rules || [],
                                        isApproval: true,
                                        criteria: criteria,
                                        criteriaId: criteria?.id,
                                        id: this.props.match.params?.evaluation_id,
                                        requirements: this.state.criterias,
                                        criterias: this.state.criterias,
                                        index: k,
                                        canUpdateRule: canUpdateRule
                                      },
                                      isShowRuleDialog: true
                                    })
                                  }}
                                >
                                  {/* {this.props.approvedCriteriesIds[criteria?.criteriaId] !== undefined && criteria?.rules?.length > 0 ? 'Show Rule' : this.props.approvedCriteriesIds[criteria?.id] !== undefined && criteria?.rules?.length <= 0 ? '' : criteria?.rules?.length === 0 ? "Add Rule" : "Update Rule"} */}
                                  {ruleMessage}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.actions}>
                                <Image className="" src={deleteIcon} onClick={() => {
                                  this.removeCriteria(k)
                                }} />
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      })}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
              {/* </TableBody> */}
            </Table>
          </TableContainer>}

        </div>

        <div className={classes.saveBtnSection}>
          <button
            type="button"
            className={classes.newUserBtn}
            onClick={() => this.saveApprovalConfiguration()}> Save </button>
        </div>

        {
          this.props.collaboratorDialogOpen &&
          <SearchUserAndTeamPoper
            key={this.state.selectedIndex}
            onSubmit={(users, manager_approval, department_approval, manager_level) => {
              console.log(users, 'users')
              console.log(manager_approval, 'manager_approval')
              console.log(department_approval, 'department_approval')
              console.log(manager_level, 'manager_level')
              let Users = [];
              let Teams = [];
              users.forEach(data => {
                if (data.type === 'GROUP') {
                  Teams.push({ ID: data.ID, Name: data.Name, Email: data.Email, type: 'GROUP' });
                } else {
                  Users.push({ ID: data.ID, Name: data.Name, Email: data.Email, type: 'USER' });
                }
              });
              this.addCriteriaColloborator({
                users: {
                  users: Users,
                  teams: Teams,
                  manager_approval: manager_approval,
                  department_approval: department_approval,
                  manager_level: manager_level
                },
                index: this.state.selectedIndex,
                criteria: this.state.selectedCriteria
              });
              this.props.hideEvaluationCollaboratorDialog();
            }}
            selectedUser={this.state.approvers}
            manager_approval={this.state.manager_approval}
            department_approval={this.state.department_approval}
            manager_level={this.state.manager_level}
            isFromApprovalConfiguration={true}
          />
        }

        {this.state.isShowRuleDialog && <ApprovalCriteriaRuleDialog
          ruleDialog={this.state.criteriaRuleData}
          isOpen={this.state.isShowRuleDialog}
          approvalStages={approvalStages}
          onClose={() => {
            this.setState({
              isShowRuleDialog: false,
              criteriaRuleData: {},
            })
          }}
          onSave={(rules) => {
            this.saveRuleForCriteria(rules)
          }}
        />}

        {this.state.isShowConditionDialog && <ApprovalCriteriaConditionDialog
          conditionDialog={this.state.criteriaConditionData}
          workflowId={this.props.workflowId}
          componentId={this.props.componentId}
          isOpen={this.state.isShowConditionDialog}
          onClose={() => {
            this.setState({
              isShowConditionDialog: false,
              criteriaConditionData: {},
            })
          }}
          onSave={(conditions) => {
            this.saveConditionForCriteria(conditions)
          }}
        />}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(DecisionComponent));