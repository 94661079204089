import {
    CircularProgress,
    Dialog, DialogContent, Slide, Typography
} from '@material-ui/core';
import axios from "axios";
import * as Environment from "util/Environment";
import { CommonFn } from "services/commonFn";
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import ReactSelect from "react-select";
import SqureButton from 'Components/Common/SqureButton';
import Alert from '@material-ui/lab/Alert';
import { fetchApps,hideApplicationUserDialog } from "redux/applications/action";
import { withAsyncPaginate } from "react-select-async-paginate";
const SelectAsyncPaginate = withAsyncPaginate(ReactSelect);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen:state.applications.showApplicationUserDialog,
    appsData: state.applications.appsData,
    appsFetchProgress: state.applications.appsFetchProgress,
    appsFetchError: state.applications.appsFetchError,
});

const connectionActions = {
    hideDialog: hideApplicationUserDialog,
    fetchApps: fetchApps,
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
    },
    card: {
        border: "2px solid #EEEEEE",
        borderRadius: '10px',
        background: "#fff",
        paddingLeft: theme.spacing(2),
        cursor: "pointer",
        display: 'flex',
        placeContent: 'center',
        width: '100%',
        padding: '14px 16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 80,
    },
    cardText: {
        display: 'flex',
        flexDirection: 'column'
    },
    box: {
        marginTop: 25
    },
    actionButton: {
        textAlign: 'right',
        marginTop: 25
    },
    alert: {
        marginBottom: 10,
        marginTop: 25,
    }
});

class IntegrationApplicationUserDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            isLoading: true,
            searchQuery: '',
            selectedApps: [],
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearError = this.clearError.bind(this);
    }

    componentDidMount() {
        this.fetchAppsData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.appsFetchProgress === false && prevProps.appsFetchProgress === true) {
            this.setState({
                isLoading: false,
                selectedApps: this.props.selectedApps
            })
        }
    }

    fetchAppsData = () => {
        this.props.fetchApps({
            page:0,
            pageSize:20,
            is_suppressed:"all",
            sources:[this.props.appType],
            query: this.state.searchQuery
        })
    }

    clearError() {
        this.setState({
            error: ''
        })
    }

    handleSubmit() {
        const { selectedApps } = this.state;
        this.clearError();

        if (selectedApps.length === 0) {
            this.setState({
                error: 'Please select applications.'
            })
            return;
        }
        this.props.onSubmit(selectedApps);
    }

    getOptions = async (inputValue, loadedOptions, additional) => {
        var page = 0;
        if (additional !== undefined && additional.page !== undefined) {
          page = additional.page;
        }
        const api_server = Environment.api_host("USER");
        const timestamp = new Date().getTime();
        if (!inputValue) {
            let appList = [];
            this.props.appsData?.Data?.forEach((item, index) => {
                appList.push({
                    label: item.Name,
                    value: item.ApplicationId
                })
            })
          return {
            options: appList,
            hasMore: false,
            additional: {
              page: 0,
            },
          };
        }
        let formData = {
            page:0,
            pageSize:20,
            is_suppressed:"all",
            sources:[this.props.appType],
            query: inputValue
        };
        let url = `${api_server}/apps?t=${timestamp}`;
        const response = await axios.post(url, formData, {
          headers: { Authorization: CommonFn.getStorage("authType") + " " + CommonFn.getStorage("authToken"), "Content-Type": "application/json" },
        });
        let apps = response?.data?.Data || [];
        let total = response?.data?.Data?.total || 0;
        apps = apps.map((m) => ({
            label: m.Name,
            value: m.ApplicationId,
        }));
        return {
          options: apps,
          hasMore: (page + 1) * 10 < total ? true : false,
          additional: {
            page: page + 1,
          },
        };
      };

    render() {
        const classes = this.props.classes;
        const { isLoading } = this.state;

        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"sm"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.onCancel()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Select Applications</Typography>
                <div className={classes.body}>

                    <div className={classes.alert}>
                        {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                    </div>

                    {isLoading && <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <CircularProgress />
                    </div>}

                    {!isLoading && <div className={classes.box}>
                        <Typography className={classes.headingForm}>
                            Select Applications <span className={classes.required}>*</span>
                        </Typography>

                        <SelectAsyncPaginate
                            isClearable={false}
                            isMulti
                            isSearchable={true}
                            value={this.state.selectedApps}
                            menuPosition='fixed'
                            classNamePrefix="select"
                            isLoading={this.props.appsFetchProgress}
                            placeholder="Search Applications"
                            additional={{
                                page: 0,
                            }}
                            loadOptions={this.getOptions}
                            onChange={(e) => {
                                this.setState({
                                    selectedApps: e,
                                }, () => {
                                    this.clearError();
                                })
                            }}
                        />

                        <div className={classes.actionButton}>
                            <SqureButton cls={classes.actionBtn} variant={"outlined"} onClick={this.props.onDiscard}>Discard</SqureButton>&nbsp;&nbsp;&nbsp;
                            <SqureButton 
                            disabled={this.state.selectedApps === null ? true : false}
                            cls={classes.actionBtn} 
                            variant={"contained"} 
                            onClick={() => { this.handleSubmit() }}>Submit</SqureButton>
                        </div>
                    </div>}
                </div>
            </DialogContent>
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(IntegrationApplicationUserDialog));