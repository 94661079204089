import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import Home from "./Home/index";
import { updateDrawerStyle } from "redux/drawer/action";
import Summary from "./Summary";
import EditBuilder from "./Summary/Builder";
import JobDetail from "./JobDetail";
// views
const connectedProps = (state) => ({
});

const connectionActions = {
  updateDrawerStyle: updateDrawerStyle,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({});

class Survey extends React.Component {

  componentDidMount() {
    this.props.updateDrawerStyle(true, true);
  }

  render() {

    return (
      <React.Fragment>
        <Switch>
          <Route exact path={this.props.match.path} component={Home} />
          <Route
            path={`${this.props.match.path}/builder/:id/job/:job_id`}
            component={JobDetail}
          />
          <Route
            path={`${this.props.match.path}/builder/:id`}
            component={EditBuilder}
          />
          <Route
            path={`${this.props.match.path}/:id`}
            component={Summary}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default connector(compose(withStyles(styles))(Survey));
