
import {HttpFactory} from "../../../../services/httpService";
import * as Environment from "../../../../util/Environment"

export const START_MASTER_EVALUATION_TEMPLATE_FETCH         =   "START_MASTER_EVALUATION_TEMPLATE_FETCH";
export const END_MASTER_EVALUATION_TEMPLATE_FETCH           =   "END_MASTER_EVALUATION_TEMPLATE_FETCH";

export const START_MASTER_EVALUATION_TEMPLATE_GET         =   "START_MASTER_EVALUATION_TEMPLATE_GET";
export const END_MASTER_EVALUATION_TEMPLATE_GET           =   "END_MASTER_EVALUATION_TEMPLATE_GET";

export const START_UPDATE_FROM_MASTER_EVALUATION_CRITERIA_TEMPLATE = "START_UPDATE_FROM_MASTER_EVALUATION_CRITERIA_TEMPLATE"
export const END_UPDATE_FROM_MASTER_EVALUATION_CRITERIA_TEMPLATE = "END_UPDATE_FROM_MASTER_EVALUATION_CRITERIA_TEMPLATE"


export const START_UPDATE_FROM_MASTER_EVALUATION_REQUIREMENT_TEMPLATE = "START_UPDATE_FROM_MASTER_EVALUATION_REQUIREMENT_TEMPLATE"
export const END_UPDATE_FROM_MASTER_EVALUATION_REQUIREMENT_TEMPLATE = "END_UPDATE_FROM_MASTER_EVALUATION_REQUIREMENT_TEMPLATE"

export const SET_TEMPLATE_ACTIVE_TAB = "SET_TEMPLATE_ACTIVE_TAB"

function startMasterEvaluationTemplateFetch(page){
    return {
        type:START_MASTER_EVALUATION_TEMPLATE_FETCH,
        payload:page
    };
}

function endMasterEvaluationTemplateFetch(success,error){
    return {
        type:END_MASTER_EVALUATION_TEMPLATE_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchMasterTemplates(page,pageSize){
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/evaluation/master?t=${timestamp}&page=${page}&pageSize=${pageSize}`;
    return async (dispatch) => {
        dispatch(startMasterEvaluationTemplateFetch(page));
        try{
            let [response,error]    =   await await HttpFactory.instance().getMethod(url);
            dispatch(endMasterEvaluationTemplateFetch(response,error));
        } catch(e){
            dispatch(endMasterEvaluationTemplateFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startMasterEvaluationTemplateGet(){
    return {
        type:START_MASTER_EVALUATION_TEMPLATE_GET,
        payload:{}
    };
}

function endMasterEvaluationTemplateGet(success,error){
    return {
        type:END_MASTER_EVALUATION_TEMPLATE_GET,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchMasterTemplate(templateId,evaluationId = null){
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp     =   (new Date()).getTime();
    var url   =   `${api_server}/evaluation/master/${templateId}?t=${timestamp}`;
    if(evaluationId !== undefined && evaluationId !== null){
        url = url + "&forEvaluation="+evaluationId;
    }
    return async (dispatch) => {
        dispatch(startMasterEvaluationTemplateGet());
        try{
            let [response,error]    =   await await HttpFactory.instance().getMethod(url);
            dispatch(endMasterEvaluationTemplateGet(response,error));
        } catch(e){
            dispatch(endMasterEvaluationTemplateGet(null,{
                message:e.message
            }));
            return;
        }
    };
} 


function startUpdateFromMasterEvaluationCriteriaTemplate(){
    return {
        type:START_UPDATE_FROM_MASTER_EVALUATION_CRITERIA_TEMPLATE,
        payload:{}
    };
}

function endUpdateFromMasterEvaluationCriteriaTemplate(success,error){
    return {
        type:END_UPDATE_FROM_MASTER_EVALUATION_CRITERIA_TEMPLATE,
        payload:{
            success,error
        }
    };
}

export function updateFromMasterEvaluationCriteriaTemplate(id, criteriaId, data) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp     =   (new Date()).getTime();

    var url   =   `${api_server}/evaluation/master/${id}/criteria/${criteriaId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateFromMasterEvaluationCriteriaTemplate());
        try{
            let [response,error]    =   await await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateFromMasterEvaluationCriteriaTemplate(response,error));
            dispatch(fetchMasterTemplate)
        } catch(e){
            dispatch(endUpdateFromMasterEvaluationCriteriaTemplate(null,{
                message:e.message
            }));
            return;
        }
    };
}


function startUpdateFromMasterEvaluationRequirementTemplate(){
    return {
        type:START_UPDATE_FROM_MASTER_EVALUATION_REQUIREMENT_TEMPLATE,
        payload:{}
    };
}

function endUpdateFromMasterEvaluationRequirementTemplate(success,error){
    return {
        type:END_UPDATE_FROM_MASTER_EVALUATION_REQUIREMENT_TEMPLATE,
        payload:{
            success,error
        }
    };
}

export function updateFromMasterEvaluationRequirementTemplate(id, criteriaId, requirementId, data) {
    const api_server = Environment.api_host("TEMPLATES");
    const timestamp     =   (new Date()).getTime();

    var url   =   `${api_server}/evaluation/master/${id}/criteria/${criteriaId}/requirement/${requirementId}?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startUpdateFromMasterEvaluationRequirementTemplate());
        try{
            let [response,error]    =   await await HttpFactory.instance().putMethod(url, data);
            dispatch(endUpdateFromMasterEvaluationRequirementTemplate(response,error));
            dispatch(fetchMasterTemplate(id))
        } catch(e){
            dispatch(endUpdateFromMasterEvaluationRequirementTemplate(null,{
                message:e.message
            }));
            return;
        }
    };
}

 export function setTemplateActiveTab(id){
    return {
        type:SET_TEMPLATE_ACTIVE_TAB,
        payload:{
            id
        }
    };
}