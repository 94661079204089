import React from 'react';
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "../Style/index.css";
import AddIcon from "@material-ui/icons/Add";

const connectedProps = () => ({});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  triggerNode: {
    border: '1px solid #222'
  }
});

class SubTreeEndArrow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {

  }

  render() {
    const { isShowBottomSubStepMenu, isShowAddOption } = this.props;
    return (
      <div class="subtree-end-arrow">
        <div class="subtree-end-arrow__container">
          <div class="subtree-end-arrow__start adaptive-arrow adaptive-arrow_fixed-size">
            <svg preserveAspectRatio="none" viewBox="0 0 2 1" class="adaptive-arrow__end">
              <path d="M 1 0 v 1" fill="none"></path>
            </svg>
          </div>
          <svg class="subtree-end-arrow__left">
            <path fill="none" d="M 71,0
             q 0,6 -6,6
             h -58
             q -6,0 -6,6"></path>
          </svg>
          <div class="adaptive-arrow">
            <svg preserveAspectRatio="none" viewBox="0 0 2 1" class="adaptive-arrow__end">
              <path d="M 1 0 v 1" fill="none"></path>
            </svg>
          </div>
        </div>

        {isShowAddOption && !isShowBottomSubStepMenu && <div class="subtree-end-arrow__button-container subtree-end-arrow__button-container_inner ">
          <div class="recipe-step-marker">
            <div class="recipe-step-marker__inner">
              <div class="recipe-step-marker__line recipe-step-marker__line_before"></div>
              <div class="recipe-step-marker__buttons ">
                <button type="button" wtooltip="Add step" wtooltipclass="recipe-step-marker__overlay" class="recipe-step-marker__button recipe-step-marker__button_add " fdprocessedid="zayul">
                  <span class="recipe-step-marker__button-inner">
                    <AddIcon
                      onClick={() => {
                        this.props.showSubMenu(true)
                      }}
                    />
                  </span>
                </button>
              </div>
              <div class="recipe-step-marker__line recipe-step-marker__line_after"></div>
            </div>
          </div>
        </div>}

      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(SubTreeEndArrow));