import {START_SUBCATEGORY_FETCH,END_SUBCATEGORY_FETCH, START_SUBCATEGORY_FEATURE_FETCH, 
    END_SUBCATEGORY_FEATURE_FETCH, START_CATEGORY_SUBCATEGORY_FETCH, END_CATEGORY_SUBCATEGORY_FETCH,
    START_SUBCATEGORYV2_FETCH, END_SUBCATEGORYV2_FETCH
} from "./action";
const initState   =     {
    category:null,
    fetchSubCategoryProgress:false,
    subCategories:[],
    subCategoryTotal: 0,
    subCategoryFeatures: [],
    fetchSubCategoryFeaturesProgress: false,
    fetchSubCategoryFeaturesError: null,
    catSubCategories: [],
    catSubCategoryTotal: 0,
    catSubCategoryProgress: false,
    subCategoryV2: [],
    fetchSubCategoryV2Progress: false,
    fetchSubCategoryV2Error: null,
}

export  function productSubCategoryReducer(state=initState,action){
    switch(action.type){

        case START_SUBCATEGORYV2_FETCH:
            return {
                ...state,
                fetchSubCategoryV2Progress:true,
                fetchSubCategoryV2Error: null,
                subCategoryV2: []
            };

        case END_SUBCATEGORYV2_FETCH:
            let subCategoryV2 = state.subCategoryV2

            if(action.payload.error === null ) {
                subCategoryV2 = action.payload.success?.body?.items || []
            }
            return {
                ...state,
                subCategoryV2,
                fetchSubCategoryV2Progress:false,
                fetchSubCategoryV2Error:action.payload.error,
            };

        case START_SUBCATEGORY_FEATURE_FETCH:
            var subCategoryFeatures = state.subCategoryFeatures;
            if(parseInt(action.payload) === 0){
                subCategoryFeatures = []
            }
            return {
                ...state,
                fetchSubCategoryFeaturesProgress:true,
                fetchSubCategoryFeaturesError:null,
                subCategoryFeatures:subCategoryFeatures
            };

        case END_SUBCATEGORY_FEATURE_FETCH: {
                var success     =   action.payload.success;
                let subCategoryFeatures  =  state.subCategoryFeatures;
                if(success !== null){
                    var Page    =   success.page;
                    var Data    =   success.data;
                    if(Page === 0){
                        subCategoryFeatures     =  Data; 
                    } else {
                        subCategoryFeatures     = subCategoryFeatures.concat(Data)
                    }
                }
                return {
                    ...state,
                    fetchSubCategoryFeaturesProgress:false,
                    fetchSubCategoryFeaturesError:action.payload.error,
                    subCategoryFeatures:subCategoryFeatures
                };
            }
            case START_CATEGORY_SUBCATEGORY_FETCH:
                let catSubCategories = state.catSubCategories;
                let catSubCategoryTotal = state.catSubCategoryTotal;
                if(action.payload === 0){
                    catSubCategories = []
                    catSubCategoryTotal= 0
                }
                return {
                    ...state,
                    catSubCategoryProgress:true,
                    catSubCategories,
                    catSubCategoryTotal,
                };
            case END_CATEGORY_SUBCATEGORY_FETCH: {
                const { success }  =  action.payload;
                let catSubCategories = state.catSubCategories;
                let catSubCategoryTotal = state.catSubCategoryTotal;
                if(success !== null){
                    const Page    =   success.page;
                    const Data    =   success.Data;
                    catSubCategoryTotal    =   success.total;
                    if(Page === 0){
                        catSubCategories     =  Data; 
                    } else {
                        catSubCategories     = catSubCategories.concat(Data)
                    }
                }
                return {
                    ...state,
                    catSubCategoryProgress: false,
                    catSubCategories,
                    catSubCategoryTotal,
                }
            }
        case START_SUBCATEGORY_FETCH:
            return {
                ...state,
                fetchSubCategoryProgress:true,
                subCategories:[],
                subCategoryTotal: 0,
            };

        case END_SUBCATEGORY_FETCH: {
            var success_data     =   action.payload.success;
            var subCategories_data     =   state.subCategories;
            var category_data =  state.category
            if(success_data !== null){
                var Page_n    =   success_data.page;
                var Data_new    =   success_data.Data;
                var total    =   success_data.total;
                if(Page_n === 0){
                    subCategories_data     =  Data_new; 
                } else {
                    subCategories_data     = subCategories_data.concat(Data_new)
                }

                if(success_data.ProductFunction !== null ){
                    category_data = success_data.ProductFunction
                }
            }
            return {
                ...state,
                fetchSubCategoryProgress: false,
                subCategories: subCategories_data,
                subCategoryTotal: total,
                category: category_data
            }
        }
        default:
            return state;
    }
}