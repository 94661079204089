
import {HttpFactory} from "../../../services/httpService";
import * as Environment from "../../../util/Environment"

export const START_MASTER_FORMS_FETCH         =   "START_MASTER_FORMS_FETCH";
export const END_MASTER_FORMS_FETCH           =   "END_MASTER_FORMS_FETCH";

export const START_FETCH_MASTER_FORM_BY_ID         =   "START_FETCH_MASTER_FORM_BY_ID";
export const END_FETCH_MASTER_FORM_BY_ID           =   "END_FETCH_MASTER_FORM_BY_ID";

function startMasterFormsFetch(page){
    return {
        type:START_MASTER_FORMS_FETCH,
        payload:page
    };
}

function endMasterFormsFetch(success,error){
    return {
        type:END_MASTER_FORMS_FETCH,
        payload:{
            success,error
        }
    };
}

//Thunk to perform the authorizedUser
export  function fetchMasterForms(page,pageSize){
    const api_server = Environment.api_host("MASTER");
    const timestamp     =   (new Date()).getTime();
    const url   =   `${api_server}/org-workflow/template/forms?t=${timestamp}&page=${page}&pageSize=${pageSize}`;
    return async (dispatch) => {
        dispatch(startMasterFormsFetch(page));
        try{
            let [response,error]    =   await await HttpFactory.instance().getMethod(url);
            dispatch(endMasterFormsFetch(response,error));
        } catch(e){
            dispatch(endMasterFormsFetch(null,{
                message:e.message
            }));
            return;
        }
    };
} 

function startFetchMasterFormUsingId() {
    return {
        type: START_FETCH_MASTER_FORM_BY_ID,
        payload: {}
    };
  }
  
  function endFetchMasterFormUsingId(success, error) {
    return {
        type: END_FETCH_MASTER_FORM_BY_ID,
        payload: {
            success, error
        }
    };
  }
  
  export function fetchMasterFormUsingId(formId,project_id=0) {
    const api_server = Environment.api_host("MASTER");
    const timestamp = (new Date()).getTime();
    var url = `${api_server}/org-workflow/template/form/${formId}?t=${timestamp}&project_id=${project_id}`;
    return async (dispatch) => {
        dispatch(startFetchMasterFormUsingId());
        try {
            let [response, error] = await HttpFactory.instance().getMethod(url);
            dispatch(endFetchMasterFormUsingId(response, error));
        } catch (e) {
            dispatch(endFetchMasterFormUsingId(null, {
                message: e.message
            }));
            return;
        }
    };
  }
  