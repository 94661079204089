import { Avatar, Button, Dialog, DialogContent, InputBase, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Slide, Typography,IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import { createStyles, withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import classnames from "classnames";
import CloseIcon from '@material-ui/icons/Close';
import AddUserDialog from "Components/Common/AddUserDialog.jsx";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
// redux
import { hideSearchUsersPoperDialog } from "redux/dialogs/action";
import { searchUser } from "redux/usersearch/action";
import _ from "underscore";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
  progress: state.userSearch.searchProgress,
  users: state.userSearch.users,
  addTeamDialogOpen: state.okta.addTeamDialogOpen,
  open: state.dialogs.isShowSearchUserPoperDialog,
  additionalData: state.dialogs.searchUserPoperAdditionData,
});

const connectionActions = {
  searchUser: searchUser,
  hideSearchUsersPoperDialog: hideSearchUsersPoperDialog,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    collaboratorContainer: {
      minWidth: "310px",
      "& ul": {
        padding: 0,
      },
    },
    collaboratorHeader: {
      marginTop: 0,
      paddingBottom: 0,
    },
    searchCol: {
      paddingTop: "16px",
      paddingBottom: "16px",
      "& span": {
        textAlign: "left",
        fontSize: 12,
        color: "#202124",
        float: "right",
      },
    },
    title: {
      textAlign: "left",
      fontWeight: 400,
      color: "#20253A",
      display: "flex",
      placeItems: "center",
    },
    input: {
      padding: theme.spacing(0.5, 2),
      fontSize: ".875rem",
      marginRight: 15,
      background: "#f8f9fa",
      width: "100%",
      border: "0px",
      borderBottom: "1px solid #4b86f7",
      outline: "1px solid transparent",
      borderRadius: "6px 6px 0 0",
      minHeight: 27,
    },
    listItem: {
      borderBottom: "1px solid #EEEEEE",
      padding: "10px 0px",
      "&:last-child": {
        borderBottom: "unset",
      },
    },
    closeIcon: {
      position: "absolute",
      zIndex: 11,
      right: 10,
      top: 10,
      padding: 5
    },
    avatar: {
      width: "2.25rem",
      height: "2.25rem",
      fontSize: "1.125rem",
    },
    userEmail: {
      fontSize: "13px",
      color: "#4B4B4B",
    },
    userEmailText: {
      cursor: "pointer",
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      flex: 0.8,
      placeItems: "start",
      "& svg": {
        position: "relative",
        left: "5px",
        marginRight: 10,
      },
    },
    userState: {
      position: "relative",
      display: "inline-block",
      width: "40px",
      height: "12px",
      background: "#DDDDDD",
      opacity: 1,
      borderRadius: "20px",
      cursor: "pointer",
      "&::after": {
        content: "''",
        height: "20px",
        width: "20px",
        display: "inline-block",
        position: "absolute",
        background: " #DDDDDD",
        border: " 5px solid #A0A5BA",
        borderRadius: "50%",
        top: "-4px",
        left: "0px",
      },
      "&.active": {
        "&::after": {
          background: "#81A5F2",
          borderColor: "#4175DF",
          left: "20px",
        },
      },
      "&.inprogress": {
        cursor: "not-allowed",
      },
    },
    newUserBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: "#ffffff !important",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 140,
      marginLeft: "10px",
      "&:hover": {
        background: "#3C3C3C",
        color: "#ffffff !important",
      },
    },
    cancelBtn: {
      background: "#3C3C3C !important",
      borderRadius: 5,
      color: "#ffffff !important",
      fontSize: theme.spacing(1.9),
      minHeight: 32,
      minWidth: 80,
      marginRight: "20px",
      "&:hover": {
        background: "#3C3C3C",
        color: "#ffffff !important",
      },
    },
    shareIcon: {
      background: "#4175DF",
      color: "#ffffff",
      width: "40px",
      height: "40px",
      display: "inline-block",
      borderRadius: "50%",
      textAlign: "center",
      lineHeight: "50px",
      marginRight: "10px",
      "& svg": {
        marginTop: "6px",
      },
    },
    footerAction: {
      padding: "0 20px 20px 20px",
      textAlign: "right",
      "& button": {
        backgroundColor: "#4175DF",
        color: "white",
      },
      "& button:hover": {
        backgroundColor: "#4175DF",
        color: "white",
      },
    },
    criteriaPriority: {
      minHeight: "25px !important",
      padding: 3,
      border: "1px solid #c4c4c4",
      borderRadius: 4,
      color: "#5F5F5F",
      fontSize: "1.1em",
      marginLeft: 4,
      width: 40,
      textAlign: "center",
    },
    notificationIcon: {
      fontSize: 24,
      marginTop: 5,
      color: "#4b86f8",
      cursor: "pointer",
    },
    nudgeBtn: {
      border: "1px solid #bda8a8",
      color: "#1f73b7",
      padding: 0,
    },
  });

class SearchUserPoper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      user: null,
      editValue: {},
      showActionMenu: null,
      showActionId: null,
      selectedUsers: [],
    };
    this.avatarName = this.avatarName.bind(this);
  }

  componentDidMount() {
    this.setState({ query: "", user: null });
    if(this.props.additionalData !== null && this.props.additionalData.from === 'ORG_PRODUCT_DETAILS'){
      this.setState({ selectedUsers: this.props.additionalData.selectedUsers || [] });
    }else{
      this.setState({ selectedUsers: this.props.selectedUsers });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedUsers.length !== prevProps.selectedUsers.length) {
      this.setState({
        selectedUsers: this.props.selectedUsers
      })
    }
  }

  avatarName(name) {
    return name.substring(0, 1);
  }

  CreateNewUser = () => {
    this.setState(
      {
        editValue: { email: this.state.query },
      },
      () => {
        this.props.showAddTeamDialog();
      }
    );
  };

  handleActionClick = (event, id) => {
    this.setState({
      showActionMenu: event.currentTarget,
      showActionId: id,
    });
  };

  addNewUser= (user) => {
    let users = this.state.selectedUsers;
    users.push(user);
    this.setState({selectedUsers: users});
  }

  removeExistingUser= (user) => {
    let users = this.state.selectedUsers;
    let index = users.findIndex(o=>o.ID === user.ID);
    users.splice(index,1);
    this.setState({selectedUsers: users});
  }

  handleActionClose = (user, target) => {
    if (user && target) {
      if (target === 10) {
        // if(this.props.additionalData !==null && this.props.additionalData.from === 'ORG_PRODUCT_DETAILS'){
        //   this.addNewUser(user);
        // }
        this.props.onAdd(user);
      }
      if (target === 20) {
        // if(this.props.additionalData !==null && this.props.additionalData.from === 'ORG_PRODUCT_DETAILS'){
        //   this.removeExistingUser(user);
        // }
        this.props.onRemove(user);
      }
    }
    
    this.setState({
      showActionMenu: null,
      showActionId: null,
    })
  };

  render() {
    const classes = this.props.classes;

    let search_users = this.props.users || [];

    let users = [...this.props.selectedUsers];
    let users_list = this.state.query === "" ? users : search_users;
    users_list = _.uniq(users_list, "ID");
    // console.log(this.state.selectedUsers,this.props.selectedUsers,'SelecteddddUserss');
    const checkIsExistUser = (id) => {
      if (this.state.selectedUsers.filter((user) => user.ID === id).length > 0) {
        return true;
      } else {
        return false;
      }
    };

    return (
      <>
        <Dialog fullWidth maxWidth={"md"} open={this.props.open} onClose={this.props.onClose} aria-labelledby="add-collaborator-dialog" TransitionComponent={Transition} className={classnames(classes.dialog)}>
          <DialogContent>
            <div className={classes.collaboratorContainer}>
              <div className={classes.collaboratorHeader}>
              <IconButton className={classes.closeIcon} onClick={this.props.onClose}><CloseIcon /></IconButton>
                <Typography className={classes.title}>
                  <span className={classes.shareIcon}>
                    <GroupAddOutlinedIcon />
                  </span>{" "}
                  Add users and teams
                </Typography>

                <div className={classes.searchCol}>
                  <InputBase
                    className={classes.input}
                    placeholder={"Search Email"}
                    onChange={(event) => {
                      this.setState(
                        {
                          query: event.target.value,
                        },
                        () => {
                          if(this.props.additionalData !==null && this.props.additionalData.from === 'ORG_PRODUCT_DETAILS'){
                            this.props.searchUser(this.state.query,{roles:['orgExternal']});
                          }else{
                            this.props.searchUser(this.state.query);
                          }
                        }
                      );
                    }}
                  />
                </div>
              </div>

              {users_list !== null && users_list.length > 0 && (
                <List dense={true}>
                  {users_list.map((user, k) => {
                    return (
                      <ListItem key={k} className={classes.listItem}>
                        <ListItemAvatar className={classes.listAvatar}>
                          <Avatar className={classes.avatar}>{this.avatarName(user.Name)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <div style={{ display: "flex", placeContent: "center space-between", alignItems: "center" }}>
                              <span className={classes.userEmailText}>
                                <span style={{ fontWeight: 500, color: "#202124" }}>{user.Name}</span>
                                <span style={{ color: "#5f6368" }}>{user.Email}</span>
                              </span>

                              <Button
                                onClick={(e) => this.handleActionClick(e, user.ID)}
                                aria-controls="simple-menu" aria-haspopup="true">
                                {checkIsExistUser(user.ID) === true &&
                                  <>Selected</>
                                }
                                {checkIsExistUser(user.ID) === false &&
                                  <> Action</>
                                }
                                <ArrowDropDownIcon />
                              </Button>

                              <Menu
                                id="simple-menu"
                                anchorEl={this.state.showActionMenu}
                                keepMounted
                                open={Boolean(this.state.showActionId === user.ID)}
                                onClose={() => { this.handleActionClose() }}
                              >
                                {checkIsExistUser(user.ID) === false && <MenuItem value={10} onClick={() => { this.handleActionClose(user, 10) }}>Add</MenuItem>}
                                {checkIsExistUser(user.ID) === true && <MenuItem value={20} onClick={() => { this.handleActionClose(user, 20) }}>Remove</MenuItem>}
                              </Menu>
                            </div>
                          }
                          className={classnames(classes.userEmail)}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}

              {this.state.query !== "" && !this.props.progress && this.props.users?.length === 0 && (
                <span style={{ display: "block", textAlign: "center", padding: "50px 20px 10px", marginBottom: "12px" }}>
                  User not found
                </span>
              )}
            </div>
            {this.props.addTeamDialogOpen && <AddUserDialog isFromSettings={true} isEdit={false} editValue={this.state.editValue} />}
          </DialogContent>
          <div className={classes.footerAction}>
            <Button className={classes.btn} onClick={() => { this.props.onClose() }}>
              Done
            </Button>
          </div>
        </Dialog>
      </>
    );
  }
}

SearchUserPoper.defaultProps = {
  searchTitle: "",
  selectedUser: [],
  criterias: [],
};

export default connector(compose(withStyles(styles), withRouter)(SearchUserPoper));
