import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CircleProgress from 'Components/Common/CircleProgress.jsx'
import DefaultImage from "assets/images/evaluation/default.svg"
import Tooltip from '@material-ui/core/Tooltip';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import Image from "Components/Common/image.jsx";

const connectedProps = (state) => ({
  evaluation: state.evaluationMetaData.evaluation,
  user: state.authUser.user,
  report: state.evaluationReportData.report,
  isLoading: state.evaluationReportData.isLoading,
  scores: state.evaludationCriteria.scores,
});

const connectionActions = {
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  prodHeading: {
    background: "#fff",
    position: 'relative',
  },
  prodHeadingTop: {
    padding: theme.spacing(2),
    height: "100px",
    background: "#fff",
    position: 'relative',
    display: "flex",
  },
  prodHeadingBottom: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
  },
  percent: {
    background: '#EBEFF0',
    borderRadius: 15,
    color: '#04363D',
    fontSize: 12,
    padding: 12,
    fontWeight: 500
  },
  logoContainer: {
    height: '100%',
    textAlign: 'center',
    placeContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  productName: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'

  },
  productImageContainer1: {
    width: 40,
    height: 40,
    padding: 4,
    margin: '0 auto',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 6px #0000001A',
    borderRadius: 10
  },
  productImage: {
    width: '100%',
    height: 'auto',
  },
  productScoreControl: {

  },
  overAllDiv: {
    background: '#f1f1f1',
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  overAllDivCriteriaHead: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  overAllText: {
    fontWeight: 600,
    fontSize: theme.spacing(1.8)
  },
  overAllPer: {
    float: 'right',
    color: 'green'
  },
});

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  render() {
    const { classes, report, isLoading,productWiseResponsesMap,totalRequirements } = this.props;

    if (report === null || isLoading === true) {
      return null
    }

    const product = this.props.product;

    var product_score = 0;
    if (this.props.scores[product.ID] !== undefined) {
      var score_obj = this.props.scores[product.ID];
      var totalOpted = 0;
      var totalMax = 0;
      for (var criteriId in score_obj) {
        var criteriScore = score_obj[criteriId];
        totalOpted += criteriScore.totalOpted;
        totalMax += criteriScore.totalMax;
      }
      if (totalMax !== 0) {
        product_score = Math.round(parseFloat((parseFloat(totalOpted) / parseFloat(totalMax)) * 100))
      }
    }


    if (productWiseResponsesMap[product.ID] === undefined) {
      productWiseResponsesMap[product.ID] = 0;
    }

    var overAllTotalScore = 0;
    var productProgress = 0;

    if (this.props.productScoreMap[product.ID] !== undefined) {
      var overallScore = this.props.productScoreMap[product.ID]?.OverallScore;
      var overAllScoreOpted = overallScore?.TotalOpted === null ? 0 : overallScore?.TotalOpted;
      var overAllScoreMax = overallScore?.TotalMax === null ? 0 : overallScore?.TotalMax;
      overAllTotalScore = (overAllScoreOpted / overAllScoreMax * 100);
      if (isNaN(overAllTotalScore)) {
        overAllTotalScore = 0;
      }

      var overallProgress = this.props.productScoreMap[product.ID]?.OverallProgress;
      productProgress = overallProgress[0]?.Completed === 0 ? 0 : Math.round(overallProgress[0]?.Completed * 100 / overallProgress[0]?.Total)
    }

    var maxScore = this.props.evaluation !== undefined && this.props.evaluation !== null && this.props.evaluation?.MaxScore && this.props.evaluation?.MaxScore !== null && this.props.evaluation?.MaxScore !== '' ? this.props.evaluation.MaxScore : 5;
    var overallAbsoluteScore = ((Math.round(overAllTotalScore) * maxScore) / 100);

    return (
      <div className={classes.product}>
             {<div className={classes.prodHeading}>
          <Grid container alignItems={'center'}>
            <Grid item lg={12}>
              <div style={{ padding: 10, textAlign: 'center' }}>
                <div className={classes.productImageContainer1}>
                  <Image alt="" src={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo} className={classes.productImage} />
                </div>
                <Tooltip className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{product.Name}</span>} arrow>
                  <Typography className={classes.productName}>{product.Name}</Typography>
                </Tooltip>
              </div>
            </Grid>
            {this.props.enableScoring && <Grid item lg={12} style={{ padding: '0px 10px 10px 10px' }}>
              <div className={classes.overAllDiv}>
                <span className={classes.overAllText}>Overall Progress</span>
                <span className={classes.overAllPer}>{productProgress}%</span>
              </div>
            </Grid>}
            {this.props.enableScoring && <Grid item lg={12} style={{ padding: '0px 10px 10px 10px' }}>
              <div className={classes.overAllDiv}>
                <span className={classes.overAllText}>Overall Score</span>
                <span className={classes.overAllPer}>{overallAbsoluteScore + '/' + maxScore}</span>
                <span className={classes.overAllPer}>{Math.round(overAllTotalScore)}%</span>
              </div>
            </Grid>}
          </Grid>
        </div>}
        {false && <div className={classes.prodHeading}>
          <Grid container alignItems={'center'}>
            <Grid item lg={4}>
              <div style={{ padding: 10 }}>
                <div className={classes.productImageContainer1}>
                  <Image alt="" src={product.ProductLogo == null || product.ProductLogo.length === 0 ? DefaultImage : product.ProductLogo} className={classes.productImage} />
                </div>
                <Tooltip className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{product.Name}</span>} arrow>
                  <Typography className={classes.productName}>{product.Name}</Typography>
                </Tooltip>
              </div>
            </Grid>
            <Grid item lg={4} style={{ textAlign: 'center' }}>
              <Typography className={classes.overallScore}>
                <span className={classes.percent}>Overall Score {product_score}%</span>
              </Typography>
            </Grid>
            <Grid item lg={4} style={{ textAlign: 'right' }}>
              <CircleProgress className={classes.productScoreControl} value={Math.floor((productWiseResponsesMap[product.ID] / totalRequirements) * 100)} label={Math.floor((productWiseResponsesMap[product.ID] / totalRequirements) * 100) + '%'} width={70} height={70} />
            </Grid>
          </Grid>
        </div>}
      </div>
    );
  }
} 

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Product));