import React, { Component } from 'react'
import { connect } from "react-redux";
import { compose } from "recompose";
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withStyles, createStyles } from "@material-ui/core/styles"
import {
  Grid, Tabs, Tab, Popover, List, ListItem, ListItemIcon,
  Typography, Divider, IconButton, CircularProgress, Button
} from "@material-ui/core";
import { CommonFn } from "services/commonFn";
import * as Environment from "util/Environment";
import { EditOutlined as EditIcon } from "@material-ui/icons";
import deleteIcon from "assets/images/delete.svg";
import AddIcon from '@material-ui/icons/Add';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CommonCss from 'commonCss';
import Image from "Components/Common/image.jsx";
import SqureButton from 'Components/Common/SqureButton';
import AppBarV2 from "Components/Application/AppBarV2";
import SearchTeamsPoper from "./dialogs/SearchTeamsPoper";
import classnames from "classnames";

import DefaultImage from "assets/images/evaluation/default.svg";

import { updateDrawerStyle } from "redux/drawer/action";
import AddTabDialog from './AddTabDialog.jsx'
import FormBuilder from './FormBuilder.jsx'
import { showSnackBar } from "redux/snackbar/action";
import CustomSection from "./Components/CustomSection";
import CategoryProducts from "./Components/CategoryProducts";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import {
  fetchOrgCategory,
  deleteOrgCategory,
  updateOrgCategory,
  fetchOrgCategoryTabs,
  addOrgCategoryTab,
  deleteOrgCategoryTab,
  updateOrgCategoryTab,
  addOrgCategoryTabSection,
  updateOrgCategoryTabSection,
  deleteOrgCategoryTabSection,
  addOrgCategoryTabSectionItem,
  updateOrgCategoryTabSectionItem,
  deleteOrgCategoryTabSectionItem,
  updateOrgCategoryTabSequence,
  updateOrgCategoryTabSectionSequence
} from "redux/org/categories/action";

const connectedProps = (state) => ({
  user: state.authUser.user,
  fetchCategoryProgress: state.orgCategory.fetchCategoryProgress,
  fetchCategoryError: state.orgCategory.fetchCategoryError,
  categoryData: state.orgCategory.categoryData,
  orgCategoryTabs: state.orgCategory.orgCategoryTabs,
  isLoading: state.orgCategory.fetchTabProgress,
  addTabProgress: state.orgCategory.addTabProgress,
  updateCategoryProgress: state.orgCategory.updateCategoryProgress,
  updateCategoryError: state.orgCategory.updateCategoryError,
  deleteCategoryProgress: state.orgCategory.deleteCategoryProgress,
  deleteCategoryError: state.orgCategory.deleteCategoryError,
  updateOrgProductDetailTabsProgress: state.orgCategory.updateOrgProductDetailTabsProgress,
  deleteTabProgress: state.orgCategory.deleteTabProgress,
  deleteTabError: state.orgCategory.deleteTabError,
  updateTabProgress: state.orgCategory.updateTabProgress,
  updateTabError: state.orgCategory.updateTabError,
  newlyAddedSectionData: state.orgCategory.newlyAddedSectionData,
  addOrgCategoryTabSectionProgress: state.orgCategory.addOrgCategoryTabSectionProgress,
  addOrgCategoryTabSectionError: state.orgCategory.addOrgCategoryTabSectionError,
  updateOrgCategoryTabSectionProgress: state.orgCategory.updateOrgCategoryTabSectionProgress,
  updateOrgCategoryTabSectionError: state.orgCategory.updateOrgCategoryTabSectionError,
  deleteOrgCategoryTabSectionProgress: state.orgCategory.deleteOrgCategoryTabSectionProgress,
  deleteOrgCategoryTabSectionError: state.orgCategory.deleteOrgCategoryTabSectionError,
  addOrgCategoryTabSectionItemProgress: state.orgCategory.addOrgCategoryTabSectionItemProgress,
  addOrgCategoryTabSectionItemError: state.orgCategory.addOrgCategoryTabSectionItemError,
  updateOrgCategoryTabSectionItemProgress: state.orgCategory.updateOrgCategoryTabSectionItemProgress,
  updateOrgCategoryTabSectionItemError: state.orgCategory.updateOrgCategoryTabSectionItemError,
  deleteOrgCategoryTabSectionItemProgress: state.orgCategory.deleteOrgCategoryTabSectionItemProgress,
  deleteOrgCategoryTabSectionItemError: state.orgCategory.deleteOrgCategoryTabSectionItemError,
  updateOrgCategoryTabSectionSequenceProg: state.orgCategory.updateOrgCategoryTabSectionSequenceProg,
  updateOrgCategoryTabSequenceProg: state.orgCategory.updateOrgCategoryTabSequenceProg,
});

const connectionActions = {
  fetchOrgCategory: fetchOrgCategory,
  deleteOrgCategory: deleteOrgCategory,
  updateOrgCategory: updateOrgCategory,
  fetchOrgCategoryTabs: fetchOrgCategoryTabs,
  addOrgCategoryTab: addOrgCategoryTab,
  deleteOrgCategoryTab: deleteOrgCategoryTab,
  updateOrgCategoryTab: updateOrgCategoryTab,
  addOrgCategoryTabSection: addOrgCategoryTabSection,
  updateOrgCategoryTabSection: updateOrgCategoryTabSection,
  deleteOrgCategoryTabSection: deleteOrgCategoryTabSection,
  addOrgCategoryTabSectionItem: addOrgCategoryTabSectionItem,
  updateOrgCategoryTabSectionItem: updateOrgCategoryTabSectionItem,
  deleteOrgCategoryTabSectionItem: deleteOrgCategoryTabSectionItem,
  updateDrawerStyle: updateDrawerStyle,
  showSnackBar: showSnackBar,
  updateOrgCategoryTabSequence: updateOrgCategoryTabSequence,
  updateOrgCategoryTabSectionSequence: updateOrgCategoryTabSectionSequence
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  root: {
    backgroundColor: '#FFF',
    minHeight: '100vh',
  },
  titleTxt: {
    width: 'max-content',
    borderRadius: 1,
    fontSize: theme.spacing(4),
    color: '#282D30',
    fontWeight: 600,
    '&:hover': {
      background: '#f7f7f7'
    }
  },
  actionIcon: {
    width: 30,
    height: 30,
    borderRadius: 5,
    textAlign: 'center',
    marginLeft: 5,
    border: '1px solid #4b86f8'
  },
  micon: {
    fontSize: 16,
    marginTop: 6,
    color: '#4b86f8',
    cursor: 'pointer'
  },
  formControl: {
    ...CommonCss.formControl,
    '& > div': {
      backgroundColor: 'tarsparent !important',
      border: 'none',
      paddingRight: 0,
    },
    padding: '.375rem',
  },
  mb16: {
    marginBottom: 16,
  },
  formGroup: {
    '& input': {
      color: '#6C6C6C !important'
    }
  },
  ...CommonCss.ProductDetails,
  leftSection: {
    width: 200,
    minWidth: 200,
  },
  rightSection: {
    flex: 1,
    paddingLeft: 40
  },
  tabs: {
    "& [class*='MuiTab-root']": {
      padding: '0 6px',
      maxWidth: 'max-content',
      width: 'max-content'
    },
    "border-bottom": '1px solid #d8d8d8',
  },
  tabContainer: {
    paddingTop: theme.spacing(3)
  },
  container: {
    marginTop: 81
  },
  "popoverText": {
    color: "#74798C"
  },
  listItem: {
    paddingTop: theme.spacing(2),
  },
  listIcon: {
    minWidth: theme.spacing(5),
  },
  diabled: {
    opacity: 0.4
  },
  editIcon: {
    color: "#4A87F8",
    verticalAlign: "middle",
    cursor: 'pointer'
  },
  deleteImage: {
    cursor: "pointer",
  },
  rootContainer: CommonCss.rootContainer,
  ProductNameSection: {
    ...CommonCss.ProductDetails.ProductNameSection,
    justifyContent: 'space-between',
    maxWidth: '100%',
  },
  productSection: {
    display: 'flex',
    alignItems: 'center'
  },
  OutlinedInput: {
    minHeight: '25px !important',
    padding: 6,
    border: '1px solid #c4c4c4',
    borderRadius: 2,
    color: '#5F5F5F',
    fontSize: '1.1em'
  },
  uploadImage: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
  },
})

class ViewDetailsCustomFields extends Component {
  constructor(props) {
    super()
    this.state = {
      tab: 0,
      openAddTabDialog: false,
      activeSection: null,
      editTab: null,
      open: null,
      anchorEl: null,
      activeNewSection: null,
      typeOfVisibility: null,
      newTabSection: null,
      dragFromSeq: null,
      dragtabID: null,
      isDrapSection: false,
      deletingTab: 0,
      categoryId: 0,
      isProductTab: false,
      isEditName: false,
      name: "",
      logo: null,
    }
    this.onSectionDragEnd = this.onSectionDragEnd.bind(this)
    this.drop = this.drop.bind(this)
    this.drag = this.drag.bind(this)
  }
  componentDidMount() {
    this.props.fetchOrgCategory(this.props.match.params.id);
    this.props.fetchOrgCategoryTabs(this.props.match.params.id);
    this.setState({
      categoryId: this.props.match.params.id
    })
    setTimeout(() => {
      this.props.updateDrawerStyle(false, true);
    }, 100);
  }

  componentDidUpdate(prevProps) {
    const {
      addTabProgress, updateOrgProductDetailTabsProgress,
      deleteTabProgress, orgCategoryTabs, categoryData,
      isLoading, newlyAddedSectionData, fetchCategoryProgress,
      addOrgCategoryTabSectionProgress, updateOrgCategoryTabSectionProgress, addOrgCategoryTabSectionItemProgress,
    } = this.props;

    if (!fetchCategoryProgress && prevProps.fetchCategoryProgress) {
      this.setState({
        name: categoryData?.Name,
        logo: categoryData?.Logo
      })
    }

    if (this.props.deleteCategoryProgress === false && prevProps.deleteCategoryProgress === true) {
      if (this.props.deleteCategoryError === null) {
        this.props.showSnackBar("Category deleted successfully.", "success", 3000)
        let redirectPath = `/app/products/org-categories`;
        this.props.history.push(redirectPath);
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (this.props.deleteOrgCategoryTabSectionProgress === false && prevProps.deleteOrgCategoryTabSectionProgress === true) {
      if (this.props.deleteOrgCategoryTabSectionError === null) {
        this.props.showSnackBar("Section deleted successfully.", "success", 3000)
        this.props.fetchOrgCategoryTabs(this.props.match.params.id);
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (
      (!this.props.updateOrgCategoryTabSectionItemProgress && prevProps.updateOrgCategoryTabSectionItemProgress) ||
      (this.props.updateOrgCategoryTabSectionSequenceProg === false && prevProps.updateOrgCategoryTabSectionSequenceProg === true) ||
      (this.props.updateOrgCategoryTabSequenceProg === false && prevProps.updateOrgCategoryTabSequenceProg === true)
    ) {
      this.props.fetchOrgCategoryTabs(this.props.match.params.id);
    }

    if (this.props.updateCategoryProgress === false && prevProps.updateCategoryProgress === true) {
      if (this.props.updateCategoryError === null) {
        this.props.showSnackBar("Category updated successfully.", "success", 3000)
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

    if (!isLoading && prevProps.isLoading) {
      const data = {
        dragFromSeq: null,
        dragtabID: null,
        isDrapSection: false,
        isProductTab: this.props.orgCategoryTabs.length === 0
      }
      this.setState({ ...data })
    }
    if (!addTabProgress && prevProps.addTabProgress) {
      this.setState({
        tab: orgCategoryTabs.length - 1,
        activeNewSection: null,
        isProductTab: false
      })
    }

    if (!updateOrgProductDetailTabsProgress && prevProps.updateOrgProductDetailTabsProgress) {
      this.setState({
        typeOfVisibility: null
      })
    }

    if (!deleteTabProgress && prevProps.deleteTabProgress) {
      this.setState({
        tab: this.state.deletingTab > this.state.tab
          ? this.state.tab
          : this.state.tab - 1,
        editTab: null,
        open: false,
        anchorEl: null
      })
    }
    if (!addOrgCategoryTabSectionItemProgress && prevProps.addOrgCategoryTabSectionItemProgress) {
    }

    if (!addOrgCategoryTabSectionProgress && prevProps.addOrgCategoryTabSectionProgress) {
      const data = this.state.newTabSection;
      const items = data?.items || []
      items.forEach((item, index) => {
        this.props.addOrgCategoryTabSectionItem(
          this.state.categoryId, data.tabID, newlyAddedSectionData.ID,
          {
            name: item.name,
            description: item.description,
            input_type: item.input_type,
            answer: item.answer,
            additional_data: item.additional_data,
            seq: index
          }
        )
      })
      this.setState({
        newTabSection: null,
        activeSection: null,
        activeNewSection: null
      }, () => {
        setTimeout(() => {
          this.props.fetchOrgCategoryTabs(this.state.categoryId);
        }, 200)
      })
    }

    if (!updateOrgCategoryTabSectionProgress && prevProps.updateOrgCategoryTabSectionProgress) {
      this.props.showSnackBar("Section updated successfully.", "success", 3000)
      this.props.fetchOrgCategoryTabs(this.props.match.params.id);
    }
  }


  updateOrgSectionTitle = (catId, tabId, sectionId, data) => {
    this.props.updateOrgCategoryTabSection(catId, tabId, sectionId, data)
  }

  updateSubSection = (catId, tabId, sectionId, subSectionId, data) => {
    this.props.updateOrgCategoryTabSectionItem(catId, tabId, sectionId, subSectionId, data)
  }

  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  handleAddTab = (data) => {
    const { editTab } = this.state
    if (editTab === null) {
      if (data?.name) {
        const newTab = { name: data.name, description: '' }
        this.props.addOrgCategoryTab(this.state.categoryId, newTab)
      }
    } else {
      const updatedData = {
        name: data?.name,
        description: ''
      }
      this.props.updateOrgCategoryTab(this.state.categoryId, editTab.ID, updatedData);
    }
    this.setState({
      editTab: null,
    })
  }

  handleDeleteTab = (tabId, deletingTab) => {
    this.setState({ deletingTab }, () => {
      this.props.deleteOrgCategoryTab(this.state.categoryId, tabId);
    })
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(dragFromSeq, dragtabID) {
    this.setState({ dragFromSeq, dragtabID })
  }

  drop(ev) {
    const destinationId = parseInt(ev.target.id)
    const { dragFromSeq, dragtabID } = this.state
    if (dragtabID && ![null, NaN, undefined].includes(destinationId) && destinationId !== dragFromSeq) {
      this.props.updateOrgCategoryTabSequence(
        this.props.match.params.id,
        dragtabID,
        { seq: destinationId }
      )
    }
  }

  handleCreateNewSection = (data, tabID) => {
    console.log(data, tabID);
    const { newTabSection } = this.state;
    if (newTabSection === null) {
      this.setState({
        newTabSection: { ...data, tabID, sectionUID: data.sectionUid }
      }, () => {
        this.props.addOrgCategoryTabSection(
          this.state.categoryId,
          tabID,
          {
            name: data.name,
          }
        )
      })
    }
  }

  handleUpdateSection = (data, isCustom, tabID, sectionUID) => {
    const { newTabSection } = this.state
    if (newTabSection === null) {
      this.setState({
        newTabSection: isCustom ? { ...data, tabID, sectionUID } : null
      }, () => {
        this.props.updateOrgProductDetailsTabSection(
          tabID,
          sectionUID,
          {
            name: data.name,
            description: "",
            visibility: data.visibility
          }
        )
      })
    }
  }

  onSectionDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const { destination, draggableId, source } = result;
    if (draggableId && destination.index !== source.index) {
      const itemIndex = parseInt(draggableId)
      const destinationIndex = parseInt(destination.index)
      const currentTab = this.props.orgCategoryTabs[parseInt(this.state.tab)]
      const dragedSection = currentTab.Sections[itemIndex]
      this.setState({
        isDrapSection: true
      }, () => {
        this.props.updateOrgCategoryTabSectionSequence(
          this.props.match.params.id,
          currentTab.ID,
          dragedSection.ID,
          {
            seq: destinationIndex
          }
        )
      })
    }
  }

  onFileChange = async event => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var formData = new FormData();
      if (file) {
        var reader = new FileReader();
        reader.onloadend = () => this.setState({ imgSrc: reader.result })
        reader.readAsDataURL(file);
      }
      formData.append("folder", `/org/category/${this.props.match?.params?.id}`);
      const timestamp = (new Date()).getTime();
      formData.append("file", file, `${timestamp}.${file.name.substring(file.name.indexOf('.') + 1)}`);
      const api_server = Environment.api_host("STORAGE");
      const url = `${api_server}/add?t=${timestamp}`;

      axios.post(url, formData, {
        headers: { 'Authorization': CommonFn.getStorage('authType') + ' ' + CommonFn.getStorage('authToken'), 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        this.props.updateOrgCategory(this.props.categoryData.ID, { name: this.state.name, logo: response?.data?.filepath })
        this.setState({ logo: response?.data?.filepath })
      }).catch(err => {
        if (err.response) {
          this.props.showSnackBar(err.response.data.error, "error", 3000);
        } else if (err.request) {
          this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
        } else {
          this.props.showSnackBar("Something went wrong. Please try after sometime.", "error", 3000);
        }
      })
    }
  }

  render() {
    const {
      classes, isLoading,
      addTabProgress, updateOrgProductDetailTabsProgress,
      addOrgCategoryTabSectionProgress, addOrgCategoryTabSectionItemProgress,
      deleteTabProgress, fetchCategoryProgress, categoryData, orgCategoryTabs
    } = this.props;

    const {
      tab, dragFromSeq, dragtabID,
      openAddTabDialog, isProductTab,
      open, activeNewSection, typeOfVisibility
    } = this.state;

    let role = this.props.user?.Role;
    const categoryId = this.props.match.params.id;

    if (fetchCategoryProgress) {
      return (<Grid container alignItems="center" justify="center" style={{ height: "50vh" }}><CircularProgress /></Grid>)
    }

    let tabs = orgCategoryTabs;
    let currentTab = null
    let sections = []
    if (tabs.length > 0 && !isProductTab) {
      currentTab = tabs[tab]
      sections = currentTab?.Sections || []
    }

    return (
      <div className={classes.root}>
        <AppBarV2
          title="Category Details"
          withBack={true}
          dropdown={true}
        />
        <Grid container className={classnames(classes.container, classes.rootContainer)}>
          <div className={classes.leftSection}>
            <div style={{ textAlign: 'center' }}>
              <div style={{ position: 'relative', cursor: 'pointer' }}>
                <Image
                  src={this.state.logo === null ? DefaultImage : this.state.logo}
                  style={{ maxWidth: "90%" }}
                  onClick={() => {
                    const ele = document.getElementById('UploadContractFile')
                    if (ele) ele.onclick = () => { }
                  }}
                />
                <input id="UploadContractFile" accept=".png, .jpg" className={classes.uploadImage} onChange={this.onFileChange} type="file" />
              </div>
              {role === "OrgAdmin" && <Button className={classes.btnDeleteProduct}
                onClick={() => {
                  this.props.deleteOrgCategory(categoryId);
                }}>- Delete Category</Button>}
            </div>
            <DragDropContext onDragEnd={this.onSectionDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <ul className={classes.list}>
                      {sections && sections.length > 0 && sections.map((section, k) => {
                        if (section.Name.length === 0) return null
                        return (
                          <Draggable key={String(k)} draggableId={String(k)} index={k}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <a href={`#${section.Name.toLowerCase().split(" ").join("-")}`}>
                                  <li className={classes.item} key={k}>
                                    <Grid container alignItems="center">
                                      {role === "OrgAdmin" && <DragIndicatorOutlinedIcon id={k} style={{ color: '#D9D9D9' }} />}
                                      <p style={{ flex: 1, padding: 0, margin: 0, wordBreak: 'break-word' }}>{section.Name}</p>
                                    </Grid>
                                  </li>
                                </a>
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                    </ul>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className={classes.rightSection}>
            <div className={classes.ProductNameSection}>
              <div className={classes.productSection}>
                {!this.state.isEditName && <p className={classes.titleTxt} onClick={() => {
                  if (role === 'OrgAdmin') {
                    this.setState({ isEditName: true })
                  }
                }}>{this.state.name}</p>}
                {this.state.isEditName && (
                  <Grid container style={{ alignItems: 'end', margin: '28px 0px' }}>
                    <input
                      value={this.state.name}
                      onChange={(event) => {
                        this.setState({ name: event.target.value });
                      }}
                      className={classes.OutlinedInput}
                    />
                    <Grid container style={{ width: 'max-content', alignItems: 'end' }}>
                      <div
                        className={classes.actionIcon}
                        onClick={(e) => {
                          e.stopPropagation()
                          this.setState({ name: categoryData.Name, isEditName: false })
                        }}
                      >
                        <ClearIcon className={classes.micon} />
                      </div>
                      {this.state.name && (
                        <div
                          className={classnames(classes.actionIcon, classes.actionIconSubmit)}
                          onClick={(e) => {
                            e.stopPropagation()
                            if (this.state.name) {
                              this.setState({ isEditName: false })
                              this.props.updateOrgCategory(categoryData.ID, { name: this.state.name, logo: categoryData.Logo })
                            }
                          }}
                        >
                          <CheckIcon className={classnames(classes.micon, classes.submitMicon)} />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
            <Grid container alignItems="center" className={classes.mb16}>
              <Grid item xs="11">
                {(isLoading && dragFromSeq !== null && dragtabID !== null) ? (
                  <Grid container alignItems="center"><CircularProgress size={20} /></Grid>
                ) : (
                  <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className={classes.tabs}
                  >
                    {tabs.map((obj, index) => (
                      <Tab
                        draggable={role === "OrgAdmin"}
                        onDrop={this.drop}
                        onDragOver={this.allowDrop}
                        onDragStart={() => this.drag(index, obj.ID)}
                        key={index}
                        id={index}
                        onClick={() => this.setState({ isProductTab: false, tab: index })}
                        label={
                          <Grid container alignItems="center" id={index} >
                            {role === "OrgAdmin" && <DragIndicatorOutlinedIcon id={index} style={{ color: '#D9D9D9', marginRight: 10 }} />}
                            <Grid item id={index}>
                              {obj.Name}
                            </Grid>
                            &nbsp;
                            {role === "OrgAdmin" && (
                              <Grid item>
                                <IconButton
                                  className={classes.threeDot}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    this.setState({ open: index, anchorEl: event.currentTarget || event.current })
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </Grid>
                            )}
                            <Popover
                              id={index}
                              open={open === index}
                              anchorEl={this.state.anchorEl}
                              onClose={() => this.setState({ anchorEl: null, open: null })}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                              }}>
                              <List>
                                <ListItem
                                  classes={{
                                    button: classes.listItem,
                                  }}
                                  button
                                  onClick={e => {
                                    e.stopPropagation()
                                    this.setState({
                                      editTab: obj,
                                      openAddTabDialog: true,
                                    })
                                  }}
                                >
                                  {typeOfVisibility == null && updateOrgProductDetailTabsProgress ? (
                                    <Grid container justify="center">
                                      <CircularProgress size={20} />
                                    </Grid>
                                  ) : (
                                    <>
                                      <ListItemIcon classes={{ root: classes.listIcon }}>
                                        <EditIcon
                                          fontSize={"small"}
                                          className={classes.editIcon}
                                        />
                                      </ListItemIcon>
                                      <Typography variant="inherit">
                                        Edit Tab Title
                                      </Typography>
                                    </>
                                  )}
                                </ListItem>
                                <Divider />
                                <ListItem
                                  classes={{
                                    button: classes.listItem,
                                  }}
                                  button
                                  onClick={e => {
                                    e.stopPropagation()
                                    this.handleDeleteTab(obj.ID, index)
                                  }}
                                >
                                  {open && this.state.anchorEl !== null && deleteTabProgress ? (
                                    <Grid container justify="center">
                                      <CircularProgress size={20} />
                                    </Grid>
                                  ) : (
                                    <>

                                      <ListItemIcon classes={{ root: classes.listIcon }}>
                                        <Image
                                          src={deleteIcon}
                                          className={classes.deleteImage}
                                        />
                                      </ListItemIcon>
                                      <Typography variant="inherit">
                                        Delete Tab
                                      </Typography>
                                    </>
                                  )}
                                </ListItem>
                              </List>
                            </Popover>
                          </Grid>
                        }
                        {...this.a11yProps(0)}
                      />
                    ))}
                    <Tab
                      key={'category-products'}
                      id={'category-products'}
                      label={'Products'}
                      value={tabs.length}
                      onClick={() => this.setState({ isProductTab: true, tab: tabs.length })}
                    />
                    {addTabProgress && <Grid container alignItems="center"><CircularProgress size={20} /></Grid>}
                  </Tabs>
                )}
              </Grid>
              <Grid item>
                <IconButton
                  variant={"contained"}
                  onClick={() => this.setState({ openAddTabDialog: true, editTab: null, label: "" })}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <div className={classes.tabContainer}>
              {this.state.isProductTab && <div className={classes.products}>
                <CategoryProducts
                  categoryId={categoryId}
                />
              </div>}
              {!this.state.isProductTab && sections && sections.length > 0 && (
                <DragDropContext onDragEnd={this.onSectionDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {sections?.map((section, index) => (
                          <Draggable key={String(index)} draggableId={String(index)} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <CustomSection
                                  user={this.props.user}
                                  key={section.ID}
                                  tabUID={section.TabID}
                                  categoryID={categoryData.ID}
                                  updateOrgSectionTitle={this.updateOrgSectionTitle}
                                  deleteOrgTabSection={this.props.deleteOrgCategoryTabSection}
                                  section={section}
                                  isEditable={true}
                                  productID={categoryId}
                                  updateSubSection={this.updateSubSection}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              {!this.state.isProductTab && role === "OrgAdmin" && (
                <>
                  {activeNewSection === null ? (
                    <Grid container justify="center">
                      <SqureButton
                        variant={"contained"}
                        onClick={() => {
                          this.setState({ activeNewSection: tabs[tab] })
                          setTimeout(() => {
                            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
                          }, 100)
                        }}
                      >
                        + New Section
                      </SqureButton>
                    </Grid>
                  ) : (
                    <FormBuilder
                      isLoader={
                        addOrgCategoryTabSectionProgress ||
                        addOrgCategoryTabSectionItemProgress
                      }
                      onSave={data => this.handleCreateNewSection(data, tabs[tab].ID)}
                      onCancel={() => this.setState({ activeNewSection: null })}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Grid>
        <AddTabDialog
          isOpen={openAddTabDialog}
          data={this.state.editTab}
          onClose={() => this.setState({ openAddTabDialog: false })}
          onSubmit={this.handleAddTab}
        />
        {this.props.addProductTeamsDialogOpen && <SearchTeamsPoper />}

      </div>
    )
  }
}

export default connector(compose(withStyles(styles))(ViewDetailsCustomFields));