export const currencyList = ["USD", "EUR", "INR", "GBP"];

export const defaultLicense = [
  {label: 'License', value: '', confidence: 1, type: 'text', attribute: 'license'},
  {label: 'Start Date', value: 'null', confidence: 1, type: 'date', attribute: 'start_date'},
  {label: 'End Date', value: 'null', confidence: 1, type: 'date', attribute: 'end_date'},
  {label: 'Quantity', value: '', confidence: 1, type: 'number', attribute: 'quantity'},
  {label: 'Unit Price', value: 'USD', confidence: 1, type: 'price', attribute: 'unit_price'},
  {label: 'Price', value: 'USD', confidence: 1, type: 'price', attribute: 'price'},
]

export const dataKeys = {
  product_name: {
    type: "text",
    label: "Product Name",
    value: "",
  },
  end_date: {
    type: "date",
    label: "End Date",
    value: "",
  },
  renewal_date: {
    type: "date",
    label: "Renewal Date",
    value: "",
  },
  start_date: {
    type: "date",
    label: "Start Date",
    value: "",
  },
  total_cost: {
    type: "price",
    label: "Total Cost",
    value: 0,
  },
  quantity: {
    type: "number",
    label: "Quantity",
    value: 0,
  },
  unit_price: {
    type: "price",
    label: "Unit Price",
    value: 0,
  },
  price: {
    type: "price",
    label: "Price",
    value: 0,
  },
  vendor_name: {
    type: "text",
    label: "Vendor Name",
    value: "",
  },
  amount_due: {
    type: "price",
    label: "Amount Due",
    value: 0,
  },
  due_date: {
    type: "date",
    label: "Due Date",
    value: "",
  },
  freight_amount: {
    type: "price",
    label: "Freight Amount",
    value: 0,
  },
  invoice_date: {
    label: "Invoice Date",
    type: "date",
    value: "",
  },
  net_amount: {
    type: "price",
    label: "Net Amount",
    value: 0,
  },
  payment_terms: {
    type: "text",
    label: "Payment Terms",
    value: "",
  },
  purchase_order: {
    type: "text",
    label: "Purchase Order",
    value: '',
  },
  receiver_address: {
    type: "text",
    label: "Receiver Address",
    value: "",
  },
  receiver_name: {
    type: "text",
    label: "Receiver Name",
    value: "",
  },
  receiver_tax_id: {
    type: "text",
    label: "Receiver tax id",
    value: "",
  },
  remit_to_address: {
    type: "text",
    label: "Remit to address",
    value: "",
  },
  remit_to_name: {
    type: "text",
    label: "Remit to name",
    value: "",
  },
  ship_to_address: {
    type: "text",
    label: "Ship to address",
    value: "",
  },
  ship_to_name: {
    type: "text",
    label: "ship to name",
    value: "",
  },
  supplier_address: {
    type: "text",
    label: "Supplier address",
    value: "",
  },
  supplier_email: {
    type: "text",
    label: "Supplier email",
    value: "",
  },
  supplier_iban: {
    type: "text",
    label: "supplier iban",
    value: "",
  },
  supplier_tax_id: {
    type: "text",
    label: "Supplier tax id",
    value: "",
  },
  supplier_website: {
    type: "text",
    label: "supplier website",
    value: "",
  },
  total_tax_amount: {
    label: "totalTaxAmount",
    type: "price",
    value: 0,
  },
  vat: {
    label: "Vat",
    type: "price",
    value: 0,
  },
  supplier_name: {
    type: "text",
    label: "Supplier Name",
    value: "",
  },
  license: {
    type: "text",
    label: "License",
    value: "",
  },
};

export const InvoiceParser = {
  getData(type, data) {
    let value = data;
    if (type === "price") {
      value = Array.isArray(value)
        ? value.length > 0
          ? parseFloat(String(value[0]).split(",").join("").trim()).toFixed(2)
          : 0
        : parseFloat(
            String(value || 0)
              .split(",")
              .join("")
              .trim()
          ).toFixed(2);
    } else if (type === "number") {
      value = Array.isArray(value)
        ? value.length > 0
          ? parseInt(value[0])
          : 0
        : (value && parseInt(value)) || 0;
    } else {
      value = Array.isArray(value) ? (value.length > 0 ? value[0] : "") : value;
      if (value && type === 'text') {
        value = value.replace('\n',' ');
      }
    }
    return value;
  },
};
