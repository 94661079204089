import { ListItemSecondaryAction, Button, List, ListItem, Dialog, DialogContent, InputAdornment, Slide, TextField, Paper, Popover, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import SearchIcon from "@material-ui/icons/Search";
import { fetchOrgForms } from "redux/forms/org/action";
import { showSnackBar } from "redux/snackbar/action";
import Card from "./card";

import CheckboxTree from "react-checkbox-tree";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({

});

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchOrgForms: fetchOrgForms,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(6, 12) + " !important",
    },
    uploadSection: {
      boxShadow: "5px 5px 25px rgb(0 0 0 / 8%)",
      borderRadius: 10,
      padding: "1rem",
    },
    uploadSubText: {
      color: "#475867",
    },
    uploadAnchor: {
      color: "#0091FF !important",
      textDecoration: "underline",
      opacity: 0,
      width: 0,
    },
    btnSection: {
      marginTop: 20,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    labelFile: {
      color: "#4a87f8",
      marginLeft: 5,
      border: "1px solid #4a87f8",
      padding: "5px 10px",
      borderRadius: 4,
      cursor: "pointer",
    },
    title: {
      color: "#282D30",
      fontSize: theme.spacing(2.9),
      marginBottom: theme.spacing(2.5),
      textAlign: "center",
      marginLeft: 20,
    },
    arrow: {
      paddingLeft: 5,
    },
    downloadTemplate: {
      textDecoration: "underline",
      color: "#0091FF !important",
    },
    buttonSection: {
      textAlign: "center",
      margin: 20,
    },
    progress: {
      textAlign: "center",
      paddingTop: 15,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    delete: {
      cursor: "pointer",
      marginLeft: 10,
    },
    fileNameSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      display: "flex",
    },
    cardItem: {
      width: "50%",
      maxHeight: 350,
      overflowY: "auto",
    },
    searchBox: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(3, 0),
    },
    contentList: {
      marginTop: 25,
      paddingLeft: 20
    },
    autoSelectInput: {
      width: "100%",
      "& [class*=MuiInputBase-input]": {
        padding: "10px !important",
      },
    },
    headerFlexWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    columnDrawer: {
      minWidth: 150,
      maxWidth: 300,
      right: "0",
      zIndex: "9",
    },
    cancelColumn: {
      width: '30px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    }
  });

class AddColumnsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      query: "",
      isShowSubMenu: false,
      subMenuEl: null,
      nodes: [],
      selectAllItems: false,
      checked: [],
      forms: [{
        name: 'Contract',
        ID: 1,
      }, {
        name: 'Vendor',
        ID: 2,
      }, {
        name: 'Product',
        ID: 3,
      }],
      allFormColumnsArray: [],
      allSelectedFormColumnsArr: [],
      selectedForm: {
        name: 'Contract',
        ID: 1,
      },
      allSelectedFormColumnsObj: {},
      columnsMap: {},
      contractNodes: [],
      vendorNodes: [],
      vendorChecked: [],
      contractChecked: [],
      productNodes:[],
      productChecked:[]
    }
  }

  componentDidMount() {

    this.initializeCheckedColumns()
  }

  componentDidUpdate(prevProps) {

  }

  initializeCheckedColumns() {
    const { contractColumns, vendorCatalogColumns, renewalColumns, validTypes,productCatalogColumns } = this.props;
    // console.log('88888888888888888888888888888888888888888888888')
    // console.log(contractColumns, 'contractColumns')
    // console.log(vendorCatalogColumns, 'vendorCatalogColumns')
    // console.log(renewalColumns, 'renewalColumns')

    let checked = []
    let contractChecked = []
    let vendorChecked = []
    let productChecked = []
    let columnsMap = {};
    let contractNodes = []
    let vendorNodes = []
    let productNodes = []
    renewalColumns.forEach(function (itm) {
      if (itm.parent === 'Contract') {
        const colId = "Contract_" + itm.id;
        checked.push(colId)
        contractChecked.push(colId)
      } else if (itm.parent === 'Vendor' && itm.checkId) {
        vendorChecked.push(itm.checkId)
      } else if (itm.parent === 'Product' && itm.checkId) {
        productChecked.push(itm.checkId)
      }
    })

    contractColumns.forEach(function (col) {
      const colId = "Contract_" + col.id;
      var obj = {
        value: colId,
        label: col.label,
      };
      columnsMap[colId] = col;
      contractNodes.push(obj)
    })

    let tabs = vendorCatalogColumns;
    if (tabs) {
      tabs.forEach((tab) => {
        if (tab.sections) {
          tab.sections.forEach((section) => {
            var mainObj = {
              value: "Section_" + section.id,
              label: section.name,
              children: [],
            };
            let isGoodToAdd = true;
            if (section.items) {
              section.items.forEach((item) => {
                if (validTypes.indexOf(item.type) === -1) {
                  return;
                }
                if (item.type === 6) {
                  const datas = item.additional_data ? JSON.parse(item.additional_data) : [];
                  if (datas && datas.length > 0) {
                    datas.forEach(function (ad) {
                      let itmId = item.slug + ':tableColumn' + ad.id;

                      if ([1, 2, 12, 13, 14, 19].includes(ad.type)) {
                        let arr = [section.name]
                        if (item.name && item.name !== '') {
                          arr.push(item.name)
                        }
                        if (ad.label && ad.label !== '') {
                          arr.push(ad.label)
                        }
                        let name = arr.join(' -> ')

                        let obj = {
                          id: item.slug + ':tableColumn' + ad.id,
                          parent: 'Vendor',
                          type: item.type,
                          align: 'left',
                          isVisible: true,
                          isDefault: false,
                          isSortRequire: false,
                          disablePadding: false,
                          tableColumnId: ad.id,
                          label: name,
                          checkId: itmId
                        }

                        mainObj.children.push({
                          value: itmId,
                          label: obj.label,
                        });

                        columnsMap[itmId] = obj;
                        // renewalColumns.push(obj)
                      }
                    })
                  }
                } else {
                  let itmId = 'Item_' + item.slug + '_' + item.id;
                  let arr = [section.name]
                  if (item.name && item.name !== '') {
                    arr.push(item.name)
                  }
                  let name = item.name
                  let obj = {
                    id: item.slug,
                    parent: 'Vendor',
                    type: item.type,
                    align: 'left',
                    isVisible: true,
                    isDefault: false,
                    isSortRequire: false,
                    disablePadding: false,
                    label: name,
                    checkId: itmId
                  }

                  mainObj.children.push({
                    value: itmId,
                    label: name,
                  });
                  columnsMap[itmId] = obj;
                  // renewalColumns.push(obj)
                }
              })
            } else {
              isGoodToAdd = false
            }

            if (isGoodToAdd && mainObj.children.length > 0) {
              vendorNodes.push(mainObj);
            }

          })
        }
      })
    }

    let productTabs = productCatalogColumns;
    if (productTabs) {
      productTabs.forEach((tab) => {
        if (tab.OrgProductTabSections) {
          tab.OrgProductTabSections.forEach((section) => {
            var mainObj = {
              value: "Section_" + section.SectionUID,
              label: section.SectionName,
              children: [],
            };
            let isGoodToAdd = true;
            if (section.OrgProductTabItems) {
              section.OrgProductTabItems.forEach((item) => {
                if (validTypes.indexOf(item.Type) === -1 || item.ItemUID.indexOf('item-') === -1) {
                  return;
                }
                if (item.Type === 6) {
                  return;
                } else {
                  let itmId = 'Product_' + item.ItemUID+'_'+item.ID;
                  let arr = [section.SectionName]
                  if (item.ItemName && item.ItemName !== '') {
                    arr.push(item.ItemName)
                  }
                  let name = item.ItemName
                  let obj = {
                    id: item.ItemUID,
                    parent: 'Product',
                    type: item.Type,
                    align: 'left',
                    isVisible: true,
                    isDefault: false,
                    isSortRequire: false,
                    disablePadding: false,
                    label: name,
                    checkId: itmId
                  }

                  mainObj.children.push({
                    value: itmId,
                    label: name,
                  });
                  columnsMap[itmId] = obj;
                  // renewalColumns.push(obj)
                }
              })
            } else {
              isGoodToAdd = false
            }

            if (isGoodToAdd && mainObj.children.length > 0) {
              productNodes.push(mainObj);
            }

          })
        }
      })
    }



    // console.log(contractNodes, 'contractNodes')
    // console.log(vendorNodes, 'vendorNodes')
    // console.log(checked, 'checked')
    this.setState({
      checked: checked,
      contractChecked: contractChecked,
      vendorChecked: vendorChecked,
      columnsMap: columnsMap,
      contractNodes: contractNodes,
      vendorNodes: vendorNodes,
      nodes: contractNodes,
      productNodes:productNodes,
      productChecked:productChecked
    })
  }



  showSelectedItemsPoper = (event) => {
    this.setState({
      subMenuEl: event.currentTarget || event.current,
      isShowSubMenu: true,
    });
  };

  closeSelectedItemsPoper = () => {
    this.setState({
      isShowSubMenu: false,
      subMenuEl: null,
    });
  };

  confirmFields = () => {
    console.log(this.state.columnsMap, 'columnsMap')
    console.log(this.state.contractChecked, 'contractChecked')
    console.log(this.state.vendorChecked, 'vendorChecked')
    const { vendorChecked, contractChecked, productChecked } = this.state;
    let me = this;
    let finalColumns = [];
    contractChecked.forEach(function (ck) {
      if (me.state.columnsMap[ck]) {
        finalColumns.push(me.state.columnsMap[ck])
      }
    })
    vendorChecked.forEach(function (ck) {
      if (me.state.columnsMap[ck]) {
        finalColumns.push(me.state.columnsMap[ck])
      }
    })
    productChecked.forEach(function (ck) {
      if (me.state.columnsMap[ck]) {
        finalColumns.push(me.state.columnsMap[ck])
      }
    })
    console.log(finalColumns, 'finalColumns')

    this.props.setNewColumns(finalColumns);
    this.props.hideDialog();
  }

  populateColumns = () => {
    if (this.state.selectedForm) {
      var nodes = [];
      let checked = []
      const { selectedForm, vendorNodes, contractNodes, contractChecked, vendorChecked, productNodes,productChecked } = this.state;
      if (selectedForm.ID === 1) {
        nodes = contractNodes;
        checked = contractChecked;
      } else if (selectedForm.ID === 2) { // Vendor Catalog
        nodes = vendorNodes;
        checked = vendorChecked;
      }else if (selectedForm.ID === 3) { // Product Catalog
        nodes = productNodes;
        checked = productChecked;
      }
      this.setState({
        nodes: nodes,
        checked: checked,
      });
    }
  };

  checkNode(checked) {
    let vendorChecked = [];
    let contractChecked = [];
    let productChecked = [];
    const { selectedForm } = this.state;
    if (selectedForm.ID === 1) { // Contract
      contractChecked = checked;
    } else if (selectedForm.ID === 2) { // Vendor Catalog
      vendorChecked = checked;
    } else if (selectedForm.ID === 3) { // Product Catalog
      productChecked = checked;
    }
    console.log()
    this.setState({
      checked: checked,
      contractChecked: selectedForm.ID === 1 ? contractChecked : this.state.contractChecked,
      vendorChecked: selectedForm.ID === 2 ? vendorChecked : this.state.vendorChecked,
      productChecked: selectedForm.ID === 3 ? productChecked : this.state.productChecked,
    });
  }

  render() {
    const classes = this.props.classes;
    const { isOpen, hideDialog } = this.props;
    return (
      <Dialog aria-labelledby="add-upload-dialog" open={isOpen} TransitionComponent={Transition} disableBackdropClick={true} fullWidth={true} maxWidth={"lg"} scroll={"body"}>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => hideDialog()}>
            <CloseIcon />
          </div>
          <Typography variant={"h6"} className={classes.title}>
            Select More Fields
          </Typography>
          {false && <div className={classes.headerFlexWrap}>
            <div>
              <TextField
                className={classes.autoSelectInput}
                value={this.state.query}
                variant="outlined"
                placeholder={"Search form"}
                type="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  if (e.target.value && e.target.value.length) {
                    let formsArr = [...(this.props.forms || [])];
                    let filterForms = formsArr.filter((item) => item.FormTitle.toLowerCase().includes(e.target.value.toLowerCase()));
                    this.setState({ forms: filterForms, query: e.target.value });
                  } else {
                    this.setState({ query: '', forms: this.props.forms || [] });
                  }
                }}
              />
            </div>

            {this.state.checked.length > 0 && <div className={classes.showBtnSection}>
              <Button variant="outlined" color="secondary" className={classes.button}
                onClick={(event) => {
                  this.showSelectedItemsPoper(event);
                }}
              >
                {this.state.checked.length}{" "} fields selected
              </Button>
            </div>}
          </div>}

          <div className={classes.searchBox}>
            <div className={classnames(classes.uploadSection, classes.cardItem)}>
              {this.state.forms.map((template, i) => {
                return (
                  <div key={i} style={{ margin: 8 }} onClick={() => {
                    this.setState({
                      selectedForm: template
                    }, () => {
                      this.populateColumns();
                    });
                  }}>
                    <Card template={template} selectedForm={this.state.selectedForm} />
                  </div>
                );
              })}

            </div>
            <div className={classnames(classes.cardItem, classes.contentList)}>
              <CheckboxTree
                nodes={this.state.nodes}
                checked={this.state.checked}
                expanded={this.state.expanded}
                onCheck={(checked) => {
                  this.checkNode(checked)
                }}
                onExpand={(expanded) => this.setState({ expanded })}
                iconsClass="fa5"
                icons={{
                  check: <CheckBoxIcon />,
                  uncheck: <CheckBoxOutlineBlankIcon />,
                  expandClose: <ChevronRightIcon />,
                  expandOpen: <ExpandMoreIcon />,
                  halfCheck: <CheckBoxOutlineBlankIcon />,
                }}
              />
            </div>
          </div>
          <div className={classes.buttonSection}>
            <Button variant="outlined" color="secondary" className={classes.button} onClick={hideDialog}>
              Cancel
            </Button>
            &nbsp; &nbsp;

            <Button variant="contained" color="secondary" type="submit" className={classes.button} onClick={this.confirmFields}>
              Confirm fields
            </Button>
          </div>
        </DialogContent>
        {this.state.isShowSubMenu && (
          <Popover
            open={this.state.isShowSubMenu}
            anchorEl={this.state.subMenuEl}
            onClose={this.closeSelectedItemsPoper}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper className={classes.columnDrawer}>
              <List>
                {Object.keys(this.state.allSelectedFormColumnsObj).map((key) => (
                  this.state.allSelectedFormColumnsObj[key].map((item, i) => (
                    <ListItem key={i}>
                      <Typography variant="inherit">{item.ItemName}</Typography>
                      <ListItemSecondaryAction>
                        <div className={classes.cancelColumn} onClick={() => {
                          let checked = [...this.state.checked];
                          let allSelectedFormColumnsObj = { ...this.state.allSelectedFormColumnsObj };
                          let itmIndx = checked.findIndex((o) => (o.replace("Item_", "") === item.ID));
                          checked.splice(itmIndx, 1);
                          let itmIndx3 = allSelectedFormColumnsObj[key].findIndex((o) => (o.ID === item.ID));
                          allSelectedFormColumnsObj[key].splice(itmIndx3, 1);
                          let allchecked = [];
                          Object.keys(allSelectedFormColumnsObj).forEach(key => {
                            allSelectedFormColumnsObj[key].forEach(item => {
                              allchecked.push(`Item_${item.ID}`)
                            })
                          })
                          this.setState({
                            checked: allchecked,
                            allSelectedFormColumnsObj
                          });
                          if (allchecked.length === 0) {
                            this.closeSelectedItemsPoper()
                          }
                        }}>
                          <CancelRoundedIcon style={{ fontSize: "1.4rem", color: "#e11e5a" }} />
                        </div>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                ))}
              </List>
            </Paper>
          </Popover>
        )}
      </Dialog>
    );
  }
}

export default connector(compose(withStyles(styles))(AddColumnsDialog));
