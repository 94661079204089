import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";
import CommonCss from 'commonCss';
import AppBarV2 from "../../../../../AppBarV2";
import { CircularProgress, Grid } from "@material-ui/core";

import { addNewAlert, updateAlert, deleteAlert, fetchAlert } from 'redux/alerts/action';
import { showSnackBar } from "redux/snackbar/action";
import Details from "./Details";
import Rules from "./Rules";
import { fetchOrgProductDetailsTabs } from 'redux/product/orgProduct/action'

const connectedProps = (state) => ({
  addNewAlertProgress: state.alert.addNewAlertProgress,
  addNewAlertError: state.alert.addNewAlertError,
  newTaskData: state.alert.newTaskData,
  updateAlertProgress: state.alert.updateAlertProgress,
  updateAlertError: state.alert.updateAlertError,
  updatedTaskData: state.alert.updatedTaskData,
  fetchAlertProgress: state.alert.fetchAlertProgress,
  fetchAlertError: state.alert.fetchAlertError,
  alertDetails: state.alert.alertDetails,
  orgProductDetailTabs: state.orgProduct.orgProductDetailTabs,
  orgProductDetailTabsProgress: state.orgProduct.orgProductDetailTabsProgress,
});

const connectionActions = {
  addNewAlert: addNewAlert,
  updateAlert: updateAlert,
  deleteAlert: deleteAlert,
  fetchAlert: fetchAlert,
  showSnackBar: showSnackBar,
  fetchOrgProductDetailsTabs: fetchOrgProductDetailsTabs
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  rootContainer: CommonCss.rootContainer,
  paper: {
    padding: 20
  }
});

class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
    this.props.fetchOrgProductDetailsTabs();
    this.props.fetchAlert(this.props.match.params.alert_id);
  }

  componentDidUpdate(prevProps) {
    const { fetchAlertProgress, fetchAlertError } = this.props;

    if (!fetchAlertProgress && prevProps.fetchAlertProgress) {
      if (fetchAlertError === null) {
        this.setState({
          isLoading: false
        })
      } else {
        this.props.showSnackBar("Something went wrong.", "error", 3000)
      }
    }

  }

  render() {
    const classes = this.props.classes;

    const { isLoading } = this.state;
    const { alertDetails, orgProductDetailTabsProgress } = this.props;

    if (isLoading || orgProductDetailTabsProgress) {
      <div style={{ textAlign: 'center', marginTop: 50 }}>
        <CircularProgress />
      </div>
    }
    console.log(orgProductDetailTabsProgress, 'orgProductDetailTabs')
    console.log(this.props.orgProductDetailTabs, 'orgProductDetailTabs')

    return (
      <div>
        <AppBarV2 title={alertDetails !== undefined && alertDetails !== null && alertDetails?.Title !== null ? alertDetails.Title : 'Alert'} withBack={true} />
        <div className={classes.rootContainer}>
          <div className={classes.body}>
            <Grid container spacing={3}>
              <Grid item xs={9} className={classes.boxItem}>
                <Rules
                  alertId={this.props.match.params.alert_id}
                />
              </Grid>

              <Grid item xs={3} className={classes.boxItem}>
                <Details
                  alertId={this.props.match.params.alert_id}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Summary));
