import React from 'react';
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { CircularProgress, Switch, Grid, Typography } from '@material-ui/core';
import Criteria from "./Criteria";

import { fetchCriteria, fetchScores, editCriteria, showScoringScaleDialog, hideScoringScaleDialog } from "redux/evaluation/criteria/action"
// import RequirementUpdateDialog from "./../../../Common/RequirementUpdateDialog";
import CriteriaEditDialog from "./../../../Common/CriteriaEditDialog";
import { CommonFn } from "services/commonFn";

import ScoreScaleDialog from "Components/Common/ScoringScale/ScoreScaleDialog"
import { fetchEvaluationInfo } from "redux/evaluation/metadata/action";
import { showSnackBar } from "redux/snackbar/action"
import RequirementConditionDialog from 'Components/Application/Components/Settings/Templates/Dialogs/RequirementConditionDialog';


const connectedProps = (state) => ({
    fetchProgress: state.evaludationCriteria.fetchProgress,
    fetchEvaluationProgress: state.evaluationMetaData.fetchProgress,
    criterias: state.evaludationCriteria.criterias,
    fetchCriteriaProgress: state.evaludationCriteria.fetchProgress,
    ruleSaveProgress: state.evaluationRule.evaluationReqRuleSaveProgress,
    errorRuleSave: state.evaluationRule.evaluationReqRuleSaveError,
    evaluation: state.evaluationMetaData.evaluation,
    userReponses: state.evaludationCriteria.userReponses,
    user: state.authUser.user,
    editCriteriaProgress: state.evaludationCriteria.editCriteriaProgress,
    userRole: state.authUser.user?.Role,
    isShowScoringScaleDialog: state.evaludationCriteria.isShowScoringScaleDialog,
    fetchEvaluationInfoProgress: state.evaluationMetaData.fetchEvaluationInfoProgress,
    evaluationInforError: state.evaluationMetaData.evaluationInforError,
    evaluationInfoData: state.evaluationMetaData.evaluationInfoData,
    evalScoringScaleUpdateProgress: state.evaluationMetaData.evalScoringScaleUpdateProgress,
    evalScoringScaleUpdateError: state.evaluationMetaData.evalScoringScaleUpdateError,
    isReqConditionDialogOpen: state.common.isReqConditionDialogOpen,
});

const connectionActions = {
    fetchCriteria: fetchCriteria,
    fetchScores: fetchScores,
    editCriteria: editCriteria,
    showScoringScaleDialog: showScoringScaleDialog,
    hideScoringScaleDialog: hideScoringScaleDialog,
    fetchEvaluationInfo: fetchEvaluationInfo,
    showSnackBar: showSnackBar
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    loadercs: {
        textAlign: 'center',
        marginTop: 20
    },
    maxScoreItem: {
        boxShadow: '4px 0px 4px #2B2D3703',
        border: '1px solid #1f73b7',
        background: 'transparent',
        borderRadius: '8px',
        opacity: 1,
        color: '#707070',
        padding: '2px 8px',
        margin: 0,
        marginLeft: 10,
        width: 50,
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'rgba(75, 134, 255, 0.04)'
        }
    },
});

class CriteriaRequirement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            dragStart: false,
            forAdmin: false,
            maxScore: 5
        }
        this.onDragEnd = this.onDragEnd.bind(this);
        this.showCriterias = this.showCriterias.bind(this);
    }

    componentDidMount() {
        // this.setState({
        //     loader : false
        // },()=>{
        this.checkInit();
        // })
    }

    componentDidUpdate(prevProps) {
        if (this.props.ruleSaveProgress === false && prevProps.ruleSaveProgress === true) {
            if (this.props.errorRuleSave == null) {
                this.showCriterias()
                this.props.showSnackBar("Rule updated successfully.", "success", 3000);
                // this.props.fetchCriteria(this.props.evaluationId, true, this.props.match.params.component_id);
            }
        }

        if (!this.props.fetchProgress && prevProps.fetchProgress) {
            setTimeout(() => {
                this.setState({
                    loader: false,
                    dragStart: false,
                })
            }, 500);
        }

        if (this.props.fetchEvaluationProgress === false && prevProps.fetchEvaluationProgress === true) {
            this.checkInit();
        }

        if (this.props.editCriteriaProgress && !prevProps.editCriteriaProgress && this.state.dragStart) {
            this.setState({
                loader: true,
            })
        }

        if (!this.props.editCriteriaProgress && this.state.dragStart) {
            this.setState({
                loader: false,
                dragStart: false,
            })
        }

        if (this.props.fetchCriteriaProgress === false && prevProps.fetchCriteriaProgress === true) {
            this.setState({
                loader: false
            }, () => {
                this.props.fetchEvaluationInfo(this.props.match.params.evaluation_id);
            })
        }

        if (this.props.evalScoringScaleUpdateProgress === false && prevProps.evalScoringScaleUpdateProgress === true) {
            if (this.props.evalScoringScaleUpdateError === null) {
                this.props.showSnackBar("Scoring scale updated.", "success", 3000);
                this.props.hideScoringScaleDialog()
            } else {
                this.props.showSnackBar("Something went wrong.", "error", 3000);
            }
        }
    }

    checkInit() {
        if (this.props.user.ID === this.props.evaluation?.UserID || this.props.userRole === "OrgAdmin") {
            this.setState({
                forAdmin: true
            }, () => {
                this.props.fetchCriteria(this.props.evaluationId, true, this.props.match.params.component_id);
            })
        } else {
            this.props.fetchCriteria(this.props.evaluationId, false, this.props.match.params.component_id);
        }
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const { destination, draggableId, source } = result;
        if (draggableId && destination.index !== source.index) {
            this.props.editCriteria(
                this.props.match.params?.evaluation_id,
                parseInt(draggableId),
                {
                    "seq": destination.index
                },this.props.match?.params?.component_id
            )
        }
    }

    showCriterias() {
        var isAdmin = this.state.forAdmin;
        this.setState({
            forAdmin: isAdmin === true ? false : true
        }, () => {
            this.props.fetchCriteria(this.props.evaluationId, this.state.forAdmin, this.props.match.params.component_id);
        })
    }

    render() {

        const classes = this.props.classes;
        if (this.state.loader || this.props.fetchProgress) {
            return <div className={classes.loadercs}>
                <CircularProgress />
            </div>
        }

        var ruleFailRequirementIds = [];
        var ruleFailCriteriaIds = [];
        const rulePassRequirementIds = [];
        const rulePassCriteriaIds = [];

        // console.log(this.props.evaluationInfoData, 'evaluationInfoData---000001')
        var maxScore = this.props.evaluationInfoData !== undefined && this.props.evaluationInfoData !== null && this.props.evaluationInfoData?.MaxScore && this.props.evaluationInfoData?.MaxScore !== null && this.props.evaluationInfoData?.MaxScore !== '' ? this.props.evaluationInfoData.MaxScore : 5;

        if (this.props.isRespond) {
            this.props.criterias && this.props.userReponses && this.props.criterias.forEach((criteria) => {
                if (criteria?.rules?.length) {
                    criteria.rules.forEach((rule) => {
                        if (rule?.ActionType === 2) {
                            let pushToRequirementArray = CommonFn.findAnswer(this.props.userReponses, rule, criteria.requirements);
                            if (pushToRequirementArray && ruleFailRequirementIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                ruleFailRequirementIds.push(parseInt(rule?.ActionData));
                            } else if (!pushToRequirementArray && rulePassRequirementIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                rulePassRequirementIds.push(parseInt(rule?.ActionData))
                            }
                        }
                        if (rule?.ActionType === 1) {
                            let pushToCrtieriaArray = CommonFn.findAnswer(this.props.userReponses, rule, criteria.requirements);
                            if (pushToCrtieriaArray && ruleFailCriteriaIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                ruleFailCriteriaIds.push(parseInt(rule?.ActionData));
                            } else if (!pushToCrtieriaArray && rulePassCriteriaIds.indexOf(parseInt(rule?.ActionData)) === -1) {
                                rulePassCriteriaIds.push(parseInt(rule?.ActionData))
                            }
                        }
                    });
                }
            });
        }

        ruleFailRequirementIds = ruleFailRequirementIds.filter((item) => !(rulePassRequirementIds.indexOf(item) > -1));
        ruleFailCriteriaIds = ruleFailCriteriaIds.filter((item) => !(rulePassCriteriaIds.indexOf(item) > -1))
        const criterias = this.props.criterias && this.props.criterias.map((criteria) => {
            criteria['hide'] = false;
            if (ruleFailCriteriaIds.indexOf(criteria.id) > -1) {
                criteria['hide'] = true;
            }
            criteria.requirements.map((requirement) => {
                requirement['hide'] = false;
                if (ruleFailRequirementIds.indexOf(requirement.id) > -1) {
                    requirement['hide'] = true;
                }
            });
            return criteria;
        });

        return (
            <>
                <Grid container alignItems='center' style={{ marginBottom: 10 }}>
                    <Grid item xl={6} md={6} sm={6}>
                        <div style={{ display: 'none', alignItems: 'center' }}>
                                <Typography>Scoring Scale : </Typography>
                            <p
                                className={classes.maxScoreItem}
                                onClick={() => {
                                    this.props.showScoringScaleDialog('evaluation')
                                }}
                            >{this.props.fetchEvaluationInfoProgress ? '-' : maxScore}</p>
                        </div>
                    </Grid>
                    <Grid item xl={6} md={6} sm={6}>
                        {(this.props.userRole === "OrgAdmin" || this.props.user.ID === this.props.evaluation?.UserID) && <div style={{ textAlign: 'right' }}>
                            <span>Show my Questions</span>
                            <Switch color="primary" checked={!this.state.forAdmin} onChange={this.showCriterias} name="criterias" />
                        </div>}
                    </Grid>
                </Grid>

                <DragDropContext onDragEnd={this.onDragEnd} onDragStart={() => this.setState({ dragStart: true })}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {criterias !== null &&
                                    criterias.filter(o => !o.hide).map((criteria, index) => (
                                        <Draggable key={String(criteria?.id)} draggableId={String(criteria?.id)} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Criteria
                                                        key={"criteria_" + criteria.id}
                                                        evaluationId={this.props.evaluationId}
                                                        criteria={{ ...criteria }}
                                                        isEditable={this.props.isEditable}
                                                        weighting_mechanism={this.props.weighting_mechanism}
                                                        enableScoring={this.props.enableScoring}
                                                        maxScore={maxScore}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {/* <RequirementUpdateDialog /> */}
                <CriteriaEditDialog />

                {this.props.isShowScoringScaleDialog && <ScoreScaleDialog
                    minValue={1}
                    maxValue={10}
                    templateDetails={this.props.evaluationInfoData}
                    evaluationId={this.props.match.params.evaluation_id}
                />}

                {this.props.isReqConditionDialogOpen && <RequirementConditionDialog/>}
            </>
        )
    }
}

CriteriaRequirement.defaultProps = {
    isEditable: true,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(CriteriaRequirement));