import {
    Dialog, DialogContent, Slide, Typography, Grid, FormControl, Select, MenuItem
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import SqureButton from 'Components/Common/SqureButton';

import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";

import { hideUpdateRiskScoreDialog } from "redux/risk/action";
import { OWASP_Risk_Assessment } from "util/Common"
import { showSnackBar } from "redux/snackbar/action";
import {  updateProductRiskScore } from "redux/risk/action";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.risk.showUpdateScoreDialog,
    selectedRisk: state.risk.selectedRiskForUpdateScore,

    updateProductScoreProgess: state.risk.updateProductScoreProgess,
    updateProductScoreError: state.risk.updateProductScoreError,
    productRiskScore: state.risk.productRiskScore,
});

const connectionActions = {
    hideDialog: hideUpdateRiskScoreDialog,
    showSnackBar:showSnackBar,
    updateProductRiskScore:updateProductRiskScore
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        margin: 'auto',
        marginBottom: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
    title: {
        fontWeight: 500,
        color: '#282D30',
        fontSize: 20,
        textAlign: 'center'
    },
    topHeading: {
        textAlign: 'center',
        borderBottom: '1px solid #dee2e6',
        marginBottom: 10
    },
    sections: {
        marginTop: 20
    },
    sectionName: {
        fontSize: 18,
        fontWeight: 500
    },
    factorName: {
        fontSize: 16,
        fontWeight: 500
    },
    factorBox: {
        display: 'flex',
        alignItems: 'center',
        marginBottom:15
    },
    facName: {
        flex: .3
    },
    facDrop: {
        flex: .6
    },
    formControl: {
        width: '100%',
        '& > div': {
            color: '#282D30',
            fontSize: theme.spacing(2.2),
        },
    },
    section: {
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 30
    },
    actionBtns: {
        textAlign: 'center',
        marginRight: 30
    }
});

class UpdateScoreDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            owaspAssessment: OWASP_Risk_Assessment
        }
        this.submitScore = this.submitScore.bind(this);
    }

    componentDidMount() {
        console.log(this.props.selectedRisk, 'selectedRisk')
        console.log(OWASP_Risk_Assessment, 'OWASP_Risk_Assessment')
        this.initScore();
    }

    componentDidUpdate(prevProps) {
        if (this.props.updateProductScoreProgess === false && prevProps.updateProductScoreProgess === true) {
            if (this.props.updateProductScoreError === null) {
                this.props.showSnackBar("Score Updated Successfully.", "success", 3000)
                this.props.hideDialog();
            } else {
                this.props.showSnackBar("Something went wrong.", "error", 3000)
            }
        }
    }

    initScore() {
        const { selectedRisk } = this.props;
        let owaspAssessment = this.state.owaspAssessment;
        console.log(this.props.selectedRisk, 'selectedRisk')
        console.log(owaspAssessment, 'owaspAssessment')
        owaspAssessment.forEach(function (sec) {
            sec.factors.forEach(function (factor) {
                console.log(factor.slug, 'SLUG')
                if (selectedRisk[factor.slug] && selectedRisk[factor.slug] !== undefined && selectedRisk[factor.slug] !== 0) {
                    factor.selectedValue = selectedRisk[factor.slug]
                }
            })
        })
        this.setState({
            owaspAssessment: owaspAssessment
        })
    }

    updateFactorValue(secIndex, facIndex, value) {
        let owaspAssessment = this.state.owaspAssessment;
        let section = owaspAssessment[secIndex];
        let factor = section.factors[facIndex];
        factor.selectedValue = value;
        this.setState({
            owaspAssessment: owaspAssessment
        })
    }

    submitScore() {
        const { owaspAssessment } = this.state;
        const { selectedRisk } = this.props;
        console.log(owaspAssessment, 'owaspAssessment')

        let params = {}
        owaspAssessment.forEach(function (sec) {
            sec.factors.forEach(function (factor) {
                console.log(factor.slug, 'SLUG')
                params[factor.slug] = factor.selectedValue
            })
        })

        this.props.updateProductRiskScore(selectedRisk.ProductID,selectedRisk.ID,params)
    }

    render() {
        const classes = this.props.classes;

        const { owaspAssessment } = this.state;
        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"lg"}
            scroll="body"
            id="evMetaDialog"
        >
            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <Typography variant={"h4"} className={classes.title}>Update Score for {this.props.selectedRisk.Title}</Typography>
                <div className={classes.body}>
                    <Grid container className={classes.sections}>
                        {owaspAssessment.map((model, mk) => {
                            return <Grid item lg={6} md={6} sm={12} xs={12}>
                                <div className={classes.section}>
                                    <div className={classes.topHeading}>
                                        <Typography variant={"h5"} className={classes.sectionName}>{model.sectionName}</Typography>
                                    </div>
                                    {model.factors.map((factor, fk) => {
                                        return <div className={classes.factorBox}>
                                            <div className={classes.facName}>
                                                <Typography variant={"h5"} className={classes.factorName}>{factor.name}</Typography>
                                            </div>
                                            <div className={classes.facDrop}>
                                                <FormControl variant="outlined" className={classes.formControl} size="small">
                                                    <Select value={factor.selectedValue} onChange={(e) => {
                                                        this.updateFactorValue(mk, fk, e.target.value)
                                                    }}>
                                                        {factor.options.map((op) => {
                                                            return <MenuItem value={op.value} style={{ fontSize: 13 }}>{op.label}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </Grid>
                        })}
                    </Grid>
                    <div className={classes.actionBtns}>
                        <SqureButton cls={classes.actBtn} variant={"outlined"}
                            onClick={this.props.hideDialog}>Cancel</SqureButton>&nbsp;&nbsp;&nbsp;
                        <SqureButton cls={classes.actBtn} variant={"contained"}
                            onClick={this.submitScore}>Update</SqureButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    }
}

UpdateScoreDialog.defaultProps = {
    isWorkFlow: false,
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(UpdateScoreDialog));