import {
    START_SETTINGS_USERS_FETCH, END_SETTINGS_USERS_FETCH,
    START_USER_CONFIG_FETCH, END_USER_CONFIG_FETCH,
    START_USER_CONFIG_ADD, END_USER_CONFIG_ADD,
    START_USER_CONFIG_UPDATE, END_USER_CONFIG_UPDATE,
    START_SETTINGS_USER_UPDATE, END_SETTINGS_USER_UPDATE,
    SHOW_VIA_INTEGRATION_DIALOG, HIDE_VIA_INTEGRATION_DIALOG,
    SHOW_USER_UPLOAD_DIALOG, HIDE_USER_UPLOAD_DIALOG,
    START_SETTINGS_USER_DELETE, END_SETTINGS_USER_DELETE,
    START_UPLOAD_USER_FILE, END_UPLOAD_USER_FILE,
    SHOW_USER_LOGGIN_ACTIVITY_DIALOG, HIDE_USER_LOGGIN_ACTIVITY_DIALOG,
    START_DOWNLOAD_LOGGING_ACTIVITY, END_DOWNLOAD_LOGGING_ACTIVITY
} from "./action";

const initState = {
    isUserProgress: false,
    isEditUserProgress: false,
    users: [],
    total: 0,
    editUserError: null,
    editUserSuccess: null,
    viaIntegrationDialogOpen: false,
    userUploadDialogOpen: false,

    isDeleteUserProgress: false,
    deleteUserError: null,
    deleteUserSuccess: null,

    uploadedUserList: [],
    isUploadUserProgress: false,
    uploadUserError: null,

    isShowLogginActivityDialog: false,

    downloadLogginActivityProgress: false,
    downloadLogginActivityError: null,
    logginActivity: null,

    addUserConfigProgress: false,
    addUserConfigError: null,
    addUserConfigSuccess: null,

    updateUserConfigProgress: false,
    updateUserConfigError: null,
    updateUserConfigSuccess: null,

    fetchUserConfigProgress: false,
    fetchUserConfigError: null,
    userConfigs: [],
}

export function usersSettingsReducer(state = initState, action) {
    switch (action.type) {
        case START_USER_CONFIG_FETCH:
            return {
                ...state,
                fetchUserConfigProgress: true,
                userConfigs: [],
                fetchUserConfigError: null
            };
        case END_USER_CONFIG_FETCH:
            var success = action.payload.success;
            return {
                ...state,
                fetchUserConfigProgress: false,
                userConfigs: success || [],
                fetchUserConfigError: action.payload.error
            };
        case START_USER_CONFIG_ADD:
            return {
                ...state,
                addUserConfigProgress: true,
                addUserConfigSuccess: null,
                addUserConfigError: null
            };
        case END_USER_CONFIG_ADD:
            success = action.payload.success;
            return {
                ...state,
                addUserConfigProgress: false,
                addUserConfigSuccess: success,
                addUserConfigError: action.payload.error
            };
        case START_USER_CONFIG_UPDATE:
            return {
                ...state,
                updateUserConfigProgress: true,
                updateUserConfigSuccess: null,
                updateUserConfigError: null
            };
        case END_USER_CONFIG_UPDATE:
            success = action.payload.success;
            return {
                ...state,
                updateUserConfigProgress: false,
                updateUserConfigSuccess: success,
                updateUserConfigError: action.payload.error
            };

        case START_SETTINGS_USERS_FETCH:
            var users = state.users
            return {
                ...state,
                isUserProgress: true,
                users: users
            };

        case END_SETTINGS_USERS_FETCH:
            success = action.payload.success;
            var users_new = [...state.users];
            if (success) {
                var Page = success.page;
                var Data = success.Data;
                if (Page === 0) {
                    users_new = Data;
                } else {
                    users_new = users_new.concat(Data)
                }
            }
            return {
                ...state,
                isUserProgress: false,
                users: users_new,
                total: success?.total || 0
            };

        case START_SETTINGS_USER_UPDATE:
            return {
                ...state,
                isEditUserProgress: true,
                editUserSuccess: null,
                editUserError: null
            };
        case END_SETTINGS_USER_UPDATE: {
            let error = action.payload.error;
            let success = action.payload.success;
            let result = {};
            result.isEditUserProgress = false;
            if (error) {
                result.editUserSuccess = null;
                result.editUserError = error;
            } else {
                result.editUserError = null;
                result.editUserSuccess = success;
            }
            return {
                ...state,
                ...result
            };
        }

        case SHOW_VIA_INTEGRATION_DIALOG:
            return {
                ...state,
                uploadedUserList: [],
                viaIntegrationDialogOpen: true
            };

        case HIDE_VIA_INTEGRATION_DIALOG:
            return {
                ...state,
                viaIntegrationDialogOpen: false
            };
        case SHOW_USER_UPLOAD_DIALOG:
            return {
                ...state,
                userUploadDialogOpen: true
            };

        case HIDE_USER_UPLOAD_DIALOG:
            return {
                ...state,
                userUploadDialogOpen: false
            };

        case START_SETTINGS_USER_DELETE:
            return {
                ...state,
                isDeleteUserProgress: true,
                deleteUserSuccess: null,
                deleteUserError: null
            };
        case END_SETTINGS_USER_DELETE: {
            let error = action.payload.error;
            let success = action.payload.success;
            let result = {};
            result.isDeleteUserProgress = false;
            if (error) {
                result.deleteUserSuccess = null;
                result.deleteUserError = error;
            } else {
                result.deleteUserError = null;
                result.deleteUserSuccess = success;
            }
            return {
                ...state,
                ...result
            };
        }

        case START_UPLOAD_USER_FILE:
            return {
                ...state,
                isUploadUserProgress: true,
                uploadedUserList: [],
                uploadUserError: null
            };
        case END_UPLOAD_USER_FILE:
            var success = action.payload.success;
            return {
                ...state,
                isUploadUserProgress: false,
                uploadedUserList: success,
                uploadUserError: action.payload.error
            };

        case SHOW_USER_LOGGIN_ACTIVITY_DIALOG:
            return {
                ...state,
                isShowLogginActivityDialog: true
            }

        case HIDE_USER_LOGGIN_ACTIVITY_DIALOG:
            return {
                ...state,
                isShowLogginActivityDialog: false
            }

        case START_DOWNLOAD_LOGGING_ACTIVITY:
            return {
                ...state,
                downloadLogginActivityProgress: true,
                downloadLogginActivityError: null,
                logginActivity: null,
            }

        case END_DOWNLOAD_LOGGING_ACTIVITY:
            return {
                ...state,
                downloadLogginActivityProgress: false,
                downloadLogginActivityError: action.payload.error,
                logginActivity: action.payload.success,
            }

        default:
            return state;
    }
}  