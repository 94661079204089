import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { setSelectedRequirement } from "redux/evaluation/criteria/action"
import { Typography, Divider, Box, Tooltip, LinearProgress } from '@material-ui/core';
import DefaultImage from "assets/images/evaluation/default.svg";
import Image from 'Components/Common/image.jsx';
// import moment from 'moment';
import { CommonFn } from 'services/commonFn';

import { showSellerFinalizeReqDialog } from "redux/seller/action";


const connectedProps = (state) => ({
  evaluation: state.seller.evaluation,
});

const connectionActions = {
  setSelectedRequirement: setSelectedRequirement,
  showSellerFinalizeReqDialog: showSellerFinalizeReqDialog
};

var connector = connect(connectedProps, connectionActions);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#2EB77D',
  },
}))(LinearProgress);

const styles = (theme) => createStyles({
  otherItmBox: {
    border: "1px solid #f1f1f1",
    padding: 0,
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer'
  },
  otherProductInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2)
  },
  productImg: {
    width: theme.spacing(5),
    marginRight: theme.spacing(0.6)
  },
  currProductTitle: {
    marginBottom: theme.spacing(2),
    color: '#282D30',
    fontSize: theme.spacing(2.2)
  },
  progressBox: {
    border: "1px solid #f1f1f1",
    padding: theme.spacing(2, 2),
    borderRadius: theme.spacing(1)
  },
  productName: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  productNameOri: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  dueDateTxt: {
    fontWeight: 500,
    fontSize: 14
  },
  productCompletion: {
    fontWeight: 600,
    color: '#282D30',
    opacity: '0.6'
  },
  productCompleted: {
    color: '#2EB77D',
    opacity: '1'
  },
  currentProductInfo: {
    display: 'flex',
    alignItems: 'center'
  },
  dueInfo: {
    marginTop: 10
  },
  progTextComp: {
    margin: theme.spacing(1, 0),
    fontSize: theme.spacing(1.6),
    cursor: 'pointer'
  },
  totalAnswered: {
    flex: 1,
    textAlign: 'right',
    margin: theme.spacing(1, 0),
    fontSize: theme.spacing(1.6),
    cursor: 'pointer'
  },
  productPerCom: {
    display: 'flex'
  },
  overAllText: {
    fontWeight: 600,
  },
  progText: {
    marginTop: 20
  },
  progressBar: {
    cursor: 'pointer'
  }
});

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.showFinalizeDialog = this.showFinalizeDialog.bind(this);
  }

  componentDidUpdate(preProps) {

  }

  showFinalizeDialog(type) {
    this.props.showSellerFinalizeReqDialog(type);
  }

  render() {
    const classes = this.props.classes;

    const { evaluation } = this.props;
    // console.log(evaluation,'Evaluaaaatiooonnnn');

    var totalReq = 0;
    var totalDone = 0;
    var totalDraft = 0;
    var totalDoneProgress = 0;
    var totalDraftProgress = 0;

    var progress = (evaluation !== undefined && evaluation !== null) ? evaluation.progress : {};

    totalReq = progress?.Total;
    totalDone = progress?.Completed;
    totalDraft = progress?.Draft;

    totalDoneProgress = Math.round(((totalDone * 100) / totalReq));
    totalDraftProgress = Math.floor(((totalDraft * 100) / totalReq));

    if (isNaN(totalDoneProgress)) {
      totalDoneProgress = 0;
    }

    if (isNaN(totalDraftProgress)) {
      totalDraftProgress = 0;
    }

    return <div>
      <Typography variant={"h6"} className={classes.currProductTitle}>
        Progress
      </Typography>

      <Box component="span" display="block" className={classes.progressBox}>
        <div className={classes.currentProductInfo}>
          <Image alt="product" src={evaluation.productLogo == null || evaluation.productLogo.length === 0 ? DefaultImage : evaluation.productLogo} className={classes.productImg} />

          {/* <img className={classes.productImg} src={evaluation.productLogo === null || evaluation.productLogo.length === 0 ? DefaultImage : evaluation.productLogo} alt={evaluation.productName} /> */}
          <Tooltip className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{evaluation.productName}</span>} arrow>
            <span className={classes.productNameOri}> {evaluation.productName} </span>
          </Tooltip>
        </div>

        <div className={classes.dueInfo}>
          <Tooltip className={'tooltip'} placement="top" title={<span style={{ fontSize: '14px', whiteSpace: 'pre-line', display: 'inline-block', height: '25px', lineHeight: '25px' }}>{evaluation.productName}</span>} arrow>
            {/* <Typography className={classes.dueDateTxt}> Due Date: <span style={{fontWeight:400}}>{moment(this.props.evaluation?.EvaluationEd).format("MMM Do YYYY")} </span></Typography> */}
            <Typography className={classes.dueDateTxt}>{this.props.evaluation.deadline !== '' && this.props.evaluation.deadline !== null ? 'Deadline: ' + CommonFn.formateDate(this.props.evaluation.deadline, true) : ''}</Typography>
          </Tooltip>
        </div>

        <Divider style={{ margin: '8px 0px' }} />

        {this.props.isCompletedQuestionaire && <div onClick={() => { this.showFinalizeDialog('DONE') }}>
          <Typography className={classes.progText}>
            {/* <span className={classes.overAllText}>Progress</span> */}
            <div className={classes.productPerCom}>
              <p className={classes.progTextComp}>{totalDoneProgress}% Completed</p>
              <span className={classes.totalAnswered}>{totalDone}/{totalReq}</span>
            </div>
          </Typography>
          <BorderLinearProgress className={classes.progressBar} variant="determinate" value={totalDoneProgress} />
        </div>}


        {!this.props.isCompletedQuestionaire && <div onClick={() => { this.showFinalizeDialog('DRAFT') }}>
          <Typography className={classes.progText}>
            <div className={classes.productPerCom}>
              <p className={classes.progTextComp}>{totalDraftProgress}% Completed</p>
              <span className={classes.totalAnswered}>{totalDraft}/{totalReq}</span>
            </div>
          </Typography>
          <BorderLinearProgress className={classes.progressBar} variant="determinate" value={totalDraftProgress} />
        </div>}

      </Box>
    </div>
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(Products));

// export default Products;
