import { Dialog,Typography, DialogContent, Box, TextField, Slide } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CommonCss from "commonCss";
import { compose } from "recompose";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import SelectFieldComp from "../Catalog/VendorServiceTabular/SelectFieldComp";
import { showSnackBar } from "redux/snackbar/action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
 
});

const connectionActions = {
  showSnackBar: showSnackBar,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    dialogContent: {
      margin: "auto",
      marginBottom: 60,
    },
    close: {
      position: "absolute",
      right: 20,
      top: 20,
      cursor: "pointer",
      color: "#6F6F6F",
    },
    title: {
      fontWeight: 500,
      color: "#282D30",
      fontSize: 20,
      marginBottom: 30,
    },
    body: {
      marginTop: 20,
    },

    actionBtn: {
      minWidth: "200px",
      marginLeft: 10,
    },
    formControl: {
      ...CommonCss.formControl,
      padding: ".375rem",
      width: "50%",
    },
  });

class UpdateViewDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() { 
    this.setState({name: this.props.data?.name})
  }

  componentDidUpdate(prevProps) { }

  render() {
    const classes = this.props.classes;
    return (
      <Dialog
        aria-labelledby="app-integrationDialog"
        open={true}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        id="updateViewDialog"
        style={{ zIndex: 10001 }}
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.close} onClick={() => this.props.onClose()}>
            <CloseIcon />
          </div>
          <Typography variant={"h4"} className={classes.title}>
            Update View
          </Typography>
          <TextField
            fullWidth
            className={classes.formControl}
            value={this.state.name}
            InputProps={{ disableUnderline: true }}
            placeholder={"Enter name"}
            onChange={(e) => this.setState({name: e.target.value})}
          />
          <Box>
            <SelectFieldComp 
              isUpdate={true} 
              selectedItemIds={this.props.data?.item_slugs}
              updateView={(itemIds)=> {
                if(!this.state.name || this.state.name.length === 0){
                  this.props.showSnackBar("Name can not be empty.", "error", 3000)
                }else{
                  this.props.onSubmit(this.state.name, itemIds)
                }
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connector(compose(withRouter, withStyles(styles))(UpdateViewDialog));
