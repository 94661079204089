/*
 |  File contains the List of the Action
 |  required for verification Process
 |
 |
 */
import { UserFactory } from "../../services/user";
import { HttpFactory } from "../../services/httpService";
import * as Environment from "../../util/Environment";

export const START_LOGIN = "START_LOGIN";
export const END_LOGIN = "END_LOGIN";

//Action to start the registration
function startLogin() {
    return {
        type: START_LOGIN,
        payload: null
    };
}

function endLogin(success, error) {
    return {
        type: END_LOGIN,
        payload: {
            success, error
        }
    };
}

//Thunk to perform the register
export function login(email, password) {
    // const next_server = Environment.api_host("NEXT_SERVER");
    // const next_server = 'http://localhost:3000/api';
    // const timestamp     =   (new Date()).getTime();
    // const next_url = `${next_server}/auth?t=${timestamp}`;
    return async (dispatch) => {
        dispatch(startLogin());
        try {
            let [response, error] = await UserFactory.instance().login(email, password);
            // await HttpFactory.instance().postMethod(next_url,{token: response.authToken});
            if (process.env.REACT_APP_ENV === "production") {
                document.cookie = "prodToken=" + response?.authToken + ";expires=604800;domain=.expent.ai;path=/;secure=true";
            } else if (process.env.REACT_APP_ENV === "test") {
                document.cookie = "testToken=" + response?.authToken + ";expires=604800;domain=.expent.ai;path=/;secure=true";
            } else {
                document.cookie = "token=" + response.authToken + ";expires=604800;domain=.expent.ai;path=/";
                document.cookie = "token=" + response.authToken + ";expires=604800;domain=localhost;path=/";
            }
            dispatch(endLogin(response, error));
        } catch (e) {
            dispatch(endLogin(null, {
                message: e.message
            }));
            return;
        }
    };
}

//Thunk to perform fetch client token
export function createClientToken() {
    return async () => {
        try {
            await UserFactory.instance().clientToken();
        } catch (e) {
            return;
        }
    };
}  