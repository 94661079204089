import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent,InputAdornment, Slide, Typography, CircularProgress, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from "Components/Common/Input/OutlinedInput";
import { hideViaIntegrationDialog, showViaIntegrationDialog } from "../../../../../redux/usersSettings/action";
import CommonTable from "../../../../Common/Table";
import { Search as SearchIcon, Cancel as CancelIcon } from "@material-ui/icons";
import { fetchUser, addTeamMembers } from "../../../../../redux/okta/action";
import { CommonFn } from "../../../../../services/commonFn";
import { fetchUsers } from "../../../../../redux/usersSettings/action";
import InfiniteScroll from 'react-infinite-scroll-component';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    viaIntegrationDialogOpen: state.usersSettings.viaIntegrationDialogOpen,
    okta: state.okta,

});

const connectionActions = {
    showViaIntegrationDialog: showViaIntegrationDialog,
    hideViaIntegrationDialog: hideViaIntegrationDialog,
    addTeamMembers: addTeamMembers,
    fetchUser: fetchUser,
    fetchUsers: fetchUsers
}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: theme.spacing(6, 12) + " !important"
    },
    title: {
        color: '#282D30',
        fontSize: theme.spacing(2.9),
        marginBottom: theme.spacing(0),
    },
    progress: {
        textAlign: 'center',
        paddingTop: 15
    },
    tbodyCell: {
        minHeight: 40
    },
    selectAll: {
        border: 0,
        color: '#0091FF',
        background: '#fff',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 15,

        "&:focus": {
            outline: 'none'
        }
    },
    headSection: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    userCount: {
        color: '#282D30',
        fontSize: 17,
    },
    buttonSection: {
        textAlign: 'center',
        marginTop: 40
    },
    searchSection: {
        marginTop: 20,
        '& [class*="MuiOutlinedInput-root"]': {
          maxHeight: 40,
          minHeight: 40,
          maxWidth: 343,
          margin: '0 auto',
        },
        "& [class*='MuiInputBase-input']": {
          fontSize: 13,
          // width: 300,
        },
        "& [class*='MuiSvgIcon-root']": {
          fill: "#707070",
        },
        "& [class*=MuiGrid-container]": {
          alignItems: "center",
          marginBottom: 20,
        },
        "&>div ": {
          marginBottom: 0,
        },
      },
    checked: {
        opacity: 0,
        pointerEvents: 'none',
        width: 50
    },
    alert: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    closeIcon: {
        cursor: "pointer",
      },
    tableSection: {
        marginTop: theme.spacing(6.4),
        minHeight: 150,
        height: '500px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    close: {
        position: 'absolute',
        right: 30,
        top: 30,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
});

class ViaIntegrationDialog extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            query: "",
            searchQuery: '',
            oktauser: [],
            error: '',
            page: 0,
            pageSize: 20
        }
    }

    componentDidMount() {
        this.props.fetchUser(this.state.query, this.state.page, this.state.pageSize);
    }
    clickOnCell = (bodyData, parentIndex, childIndex, array, item, actionItem, event) => {
        let bodyDataLocal = bodyData;
        if (item.key === 'checked') {
            bodyDataLocal[parentIndex]['checked'] = !bodyDataLocal[parentIndex]['checked'];
        }

        if (item.key === 'Role') {
            bodyDataLocal[parentIndex]['Role'] = event.target.value;
        }
        // console.log(bodyDataLocal)

        this.setState({ oktauser: bodyDataLocal })
    }

    componentDidUpdate(prevProps) {

        if (this.props.viaIntegrationDialogOpen === true && prevProps.viaIntegrationDialogOpen === false) {
            this.props.fetchUser(this.state.searchQuery);
            this.setState({ 'error': '' })
        }
        if (this.props.okta.fetchOktaUserProg === false && prevProps.okta.fetchOktaUserProg === true) {
            this.setState({ oktauser: this.props.okta.oktaUsers })
        }
        if (this.props.okta.totalOktaUser !== prevProps.okta.totalOktaUser) {
            this.setState({ oktauser: this.props.okta.oktaUsers })
        }

        if (!this.props.okta?.addTeamMemberProgress && prevProps.okta?.addTeamMemberProgress) {
            if (this.props.okta.addTeamMemberError !== null) {
                this.setState({ 'error': this.props.okta?.addTeamMemberError?.message })
            } else if (this.props.viaIntegrationDialogOpen) {
                this.props.hideViaIntegrationDialog();
                this.props.fetchUsers(0, 20, '');
            }
        }
    }

    selectAll() {
        let userList = this.state.oktauser.map((user) => { return { ...user, checked: true } })
        this.setState({ oktauser: userList })
    }

    unSelectAll() {
        let userList = this.state.oktauser.map((user) => { return { ...user, checked: false } })
        this.setState({ oktauser: userList })
    }

    addUser = (oktaUsers) => {
        this.setState({ 'error': '' })
        let userList = []
        oktaUsers.map((user) => {
            if (user.checked) {
                userList.push({
                    "name": user.Name,
                    "email": user.Email,
                    "role": user.Role,
                    "team": user.Team
                })
            }
        })

        if (userList.length) {
            this.props.addTeamMembers(userList);
        }
    }

    userCheckedList = (users) => {
        let usersList = users.filter((i) => (i.checked))
        return usersList.length === 0
    }

    nextFetchData = () => {
        this.setState({ page: this.state.page + 1 }, () => {
            this.props.fetchUser(this.state.query, this.state.page, this.state.pageSize);
        })
    }

    isShowUncheckedBtn = () => {
        var isAllSelected = false;
        if (this.state.oktauser !== undefined && this.state.oktauser !== null) {
            let totalUsers = this.state.oktauser.length;
            let totalChecked = 0;
            this.state.oktauser.forEach(function (oc) {
                if (oc?.checked) {
                    totalChecked++;
                }
            })
            if (totalUsers === totalChecked) {
                isAllSelected = true;
            }
        }
        return isAllSelected;
    }

    render() {
        const classes = this.props.classes;
        const tableData = {
            class: classes.table,
            isProgress: this.props.okta?.fetchOktaUserProg,
            head: [
                { label: 'Id', key: 'ID', type: 'text', hide: true, headClass: classes.thead, cellClass: classes.tbodyCell },
                { label: '', key: 'checked', type: 'checkbox', hide: false, headClass: classes.checked, cellClass: classes.tbodyCell },
                { label: 'Name', key: 'Name', type: 'text', headClass: classes.thead, cellClass: classes.tbodyCell },
                { label: 'E-mail', key: 'Email', type: 'text', headClass: classes.thead, cellClass: classes.tbodyCell },
                { label: 'Team', key: 'Team', type: 'text', headClass: classes.thead, cellClass: classes.tbodyCell },
                { label: 'Role', key: 'Role', type: 'select', headClass: classes.action, cellClass: classes.tbodyCell, options: CommonFn.roleOptions() },
            ]
        };
        let oktaUsers = []
        if (this.state.oktauser !== undefined && this.state.oktauser?.length > 0) {
            // console.log(this.state.oktauser)
            oktaUsers = this.state.oktauser?.map((user) => {
                let team = '';
                user.Groups && user.Groups.map((group) => {
                    if (group.Source === "Okta") {
                        team += `${team ? ',' : ''} ${group.Name}`;
                    }
                })
                return { ...user, Team: team, Role: user.Role === undefined ? 'OrgUser' : user.Role, checked: user.checked === undefined ? false : user.checked }
            })
        }

        let isAllSelected = this.isShowUncheckedBtn();

        return <Dialog
            onClose={this.props.hideViaIntegrationDialog}
            aria-labelledby="via-integration-dialog"
            open={this.props.viaIntegrationDialogOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={"md"}
            scroll={"body"}
        >
            <DialogContent classes={{ root: classes.dialogContent }}>
            <div className={classes.close} onClick={this.props.hideViaIntegrationDialog}><CloseIcon /></div>
                <Typography variant={"h6"} className={classes.title}> Via Integration</Typography>
                <div className={classes.searchSection}>
                    <OutlinedInput
                        id={"searchUsers "}
                        placeholder="Search Users"
                        value={this.state.query}
                        onChange={(event) => {
                            if(event){
                                this.setState({ query: event.target.value,page: 0 }, () => {
                                    this.props.fetchUser(this.state.query, this.state.page, this.state.pageSize);
                                });
                            }
                        }}
                        fullWidth={true}
                        style={{ width: "100%" }}
                        startAdornment={
                        <InputAdornment>
                            <SearchIcon className={classes.searchIcon} />
                        </InputAdornment>
                        }
                        endAdornment={
                            this.state.query.length !== 0 ? (
                                <InputAdornment>
                                <CancelIcon
                                    className={classes.closeIcon}
                                    onClick={() => {
                                    this.setState({
                                        query: "",
                                        page: 0,
                                    },()=>this.props.fetchUser(this.state.query, this.state.page, this.state.pageSize));
                                    }}
                                />
                                </InputAdornment>
                            ) : null
                        }
                        roundedCorner={true}
                    />
                </div>
                <div className={classes.alert}>
                    {this.state.error.length > 0 && <Alert variant="filled" severity="error">{this.state.error}</Alert>}
                </div>

                {!isAllSelected && <div className={classes.headSection}>
                    <p className={classes.userCount}>{this.props.okta.totalOktaUser} user{this.props.okta.totalOktaUser > 1 ? 's' : ''} found</p>
                    <button className={classes.selectAll} onClick={() => this.selectAll()}>Select all</button>
                </div>}

                {isAllSelected && <div className={classes.headSection}>
                    <p className={classes.userCount}>{this.props.okta.totalOktaUser} user{this.props.okta.totalOktaUser > 1 ? 's' : ''} found</p>
                    <button className={classes.selectAll} onClick={() => this.unSelectAll()}>Unselect all</button>
                </div>}

                {this.state.oktauser?.length > 0 && <div className={classes.tableSection} id="scrollableViaIntegration">
                    <InfiniteScroll
                        dataLength={oktaUsers?.length}
                        next={() => this.nextFetchData()}
                        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                        hasMore={true}

                        scrollableTarget="scrollableViaIntegration"
                    >
                        <CommonTable tableData={tableData} bodyData={oktaUsers} parentClickEvent={this.clickOnCell} />
                    </InfiniteScroll>
                    {(this.props.okta?.fetchOktaUserProg === true || this.props.okta?.addTeamMemberProgress === true) &&
                        <div className={classes.progress}>
                            <CircularProgress />
                        </div>}
                </div>}
                {(this.props.okta?.fetchOktaUserProg === false && this.props.okta?.addTeamMemberProgress === false) && <div className={classes.buttonSection}>
                    <Button variant="outlined" color="secondary" className={classes.button} onClick={this.props.hideViaIntegrationDialog}>Cancel</Button>
                    &nbsp;
                    &nbsp;
                    <Button variant="contained" color="secondary" disabled={this.userCheckedList(oktaUsers)} className={classes.button} onClick={() => this.addUser(oktaUsers)}>Submit</Button>
                </div>}
            </DialogContent>
        </Dialog>
    }
}
ViaIntegrationDialog.defaultProps = {

};

export default connector(compose(
    withStyles(styles)
)(ViaIntegrationDialog));