import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";

const connectedProps = () => ({});

const connectionActions = {};

const connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    topActions: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: "16px",
      marginBottom: "24px",
      borderBottom: "1px solid #003b4d26",
    },
    heading: {
      fontFamily: "Manrope",
      fontSize: "20px",
      fontWeight: 600,
      color: "#003B4D",
    },
    editButton: {
      background: "transparent",
      borderRadius: "10px",
      border: "1px solid #003b4d26",
      boxShadow: "none",
      color: "#003B4D",
      padding: "12px 32px",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 600,
    },
    saveButton: {
      background: "#17bebb",
      borderRadius: "10px",
      border: "1px solid #003b4d26",
      boxShadow: "none",
      color: "#003B4D",
      padding: "12px 32px",
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 600,
    },
  });

const TopBar = ({ classes, label, isEdit, handleClick, error, activeMenu }) => {
  useEffect(() => {
    // componentDidMount logic here (if needed)
    return () => {
      // componentWillUnmount logic here (if needed)
    };
  }, []);

  useEffect(() => {
    // componentDidUpdate logic here (if needed)
  }, [isEdit]); // You can specify any dependency if needed

  return (
    <div className={classes.topActions}>
      <Typography variant="h5" className={classes.heading}>
        {label}
      </Typography>
      <Box>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            {" "}
            {!isEdit && (
              <Button
                className={classes.editButton}
                variant="contained"
                size="large"
                onClick={() => {
                  handleClick("EDIT");
                }}
              >
                Edit
              </Button>
            )}
            {isEdit && (
              <Button
                className={classes.editButton}
                variant="contained"
                size="large"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  handleClick("CANCEL");
                }}
              >
                Cancel
              </Button>
            )}
            {isEdit && (
              <Button
                className={classes.saveButton}
                variant="contained"
                size="large"
                onClick={() => {
                  handleClick("SAVE");
                }}
              >
                Save
              </Button>
            )}
          </div>
          {/* {error && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
              }}
            >
              <Typography variant="caption" style={{ color: "#FF8082" }}>
                Please enter valid data
              </Typography>
            </div>
          )} */}
        </div>
      </Box>
    </div>
  );
};

export default connector(compose(withRouter, withStyles(styles))(TopBar));
