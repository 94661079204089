/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ICOApproved from "assets/images/approval/ICO_Approved.svg";
import ICOAwaiting from "assets/images/approval/ICO_Awaiting.svg";
import ICOPartialyApproved from "assets/images/approval/ICO_Partialy_Approved.svg";
import ICORejected from "assets/images/approval/ICO_Rejected.svg";
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import LockIcon from '@material-ui/icons/Lock';
import { IconButton, List, ListItem, ListItemIcon, Popover, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SkipNextIcon from '@material-ui/icons/SkipNext';
// import deleteIcon from "assets/images/delete.svg";
import Image from "Components/Common/image.jsx";
import { compose } from "recompose";
import { deleteApprovalCriteria, skipApprovalCriteria } from "redux/evaluation/approval/action";
import { showEvaluationCriteriaUpdateDialog } from "redux/evaluation/criteria/action";
import classnames from "classnames";
// Components
import AddApprover from "Components/Common/UserGroup/AddApprover";

const connectedProps = (state) => ({
  userType: state.authUser.user?.Role,
  user: state.authUser.user,
  productApprovalDetails: state.evaluationApproval.productApprovalDetails,
});

const connectionActions = {
  showEvaluationCriteriaUpdateDialog: showEvaluationCriteriaUpdateDialog,
  skipApprovalCriteria: skipApprovalCriteria,
  deleteApprovalCriteria: deleteApprovalCriteria
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    approvalListItem: {
      padding: "6px",
      border: "1px solid #ffffff",
      display: "flex",
      alignItems: "center",
      marginBottom: "25px",
      "&:hover": {
        background: "#FAFAFA",
        // border: '1px solid #217FEC',
        borderRadius: "6px",
      },
      "&.disabled": {
        // backgroundColor: '#f1f1f1',
        opacity: 0.8,
        cursor: "not-allowed",
      },
      "&.notAllowed": {
        opacity: 0.8,
        cursor: "not-allowed",
      },
    },
    activeCriteria: {
      background: "#FAFAFA",
      border: "1px solid #217FEC",
      borderRadius: "6px",
    },
    listScore: {
      "& .approvedScore": {
        // backgroundColor: "#FAFAFA",
        // padding: 20,
        borderRadius: "6px",
        display: "inline-block",
        width: "30px",
        height: "44px",
        textAlign: "center",
        lineHeight: "44px",
        color: "#00C19C",
        "&.status_1": {
          backgroundColor: "#9e9e9e",
          opacity: "0.8",
        },
        "&.status_2": {
          backgroundColor: "#4caf50",
          opacity: "0.8",
        },
        "&.status_3": {
          backgroundColor: "#ff9800",
          opacity: "0.8",
        },
        "&.status_4": {
          backgroundColor: "#f44336",
          opacity: "0.8",
        },
      },
    },
    yourCR: {
      display: "inline",
      marginLeft: "5px",
      fontWeight: "bold",
      fontSize: "20px",
      color: "#30ea30",
    },
    approvalListTitle: {
      paddingLeft: "16px",
      flex: 1,
      "& h6": {
        fontSize: 13,
        textAlign: "left",
        color: "#4B4B4B",
        margin: "0px",
        marginBottom: "5px",
        fontWeight: 500,
      },
      "& span": {
        fontSize: 13,
        textAlign: "left",
        color: "#A0A5BA",
      },
      "& .state_0": {
        color: "#FFC942 !important",
      },
      "& .state_1": {
        color: "#3D9EF9 !important",
      },
      "& .state_2": {
        color: "#11AEBA !important",
      },
      "& .state_3": {
        color: "#3D9EF9 !important",
      },
      "& .state_4": {
        color: "#D80027 !important",
      },
      "& .state_5": {
        color: "#D80027 !important",
      },
    },
    popover: {
      "& [class*='paper']": {
        boxShadow: "0px 8px 12px #0000001D",
        border: "1px solid #EEEEEE",
        borderRadius: "5px",
        overflow: "inherit",
        "&::before": {
          top: "35px",
          left: "-16px",
          width: "0",
          borderTop: "8px solid transparent",
          height: "0px",
          content: "''",
          display: "block",
          position: "absolute",
          borderBottom: "8px solid transparent",
          borderLeft: "8px solid transparent",
          borderRight: "8px solid #fff"
        },
      },
    },
    threeDot: {
      padding: "5px",
      marginLeft: "7px",
      height: "33px",
    },
    deleteImage: {
      cursor: "pointer",
    },
    listItem: {
      paddingTop: theme.spacing(2),
    },
    listIcon: {
      minWidth: theme.spacing(5),
    },
    approvalIcon: {
      verticalAlign: "middle",
    },
    menuList: {
      padding: '0px',
      "& > hr": {
        "&:first-child": {
          display: 'none'
        },
      }
    },
  });

class ApprovalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.btnRef = React.createRef();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget || event.current });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getStatusMessage = () => {
    if (this.props.criteria.Status === 0) {
      return 'Locked';
    } else if (this.props.criteria.Status !== 5 && this.props.getApprovalStatus(this.props.criteria.ID, this.props.otherCriteria)?.StatusText) {
      return this.props.getApprovalStatus(this.props.criteria.ID, this.props.otherCriteria)?.StatusText;
    } else if (this.props.criteria.Status === 5) {
      return 'Skipped';
    }
  }

  getStatusClass = () => {
    if (this.props.criteria?.Status === 0) {
      return 'state_0';
    } else if (this.props.criteria?.Status === 5) {
      return 'state_5';
    } else {
      return `state_${this.props.getApprovalStatus(this.props.criteria.ID, this.props.otherCriteria)?.Status}`;
    }
  }

  checkApprovedState = (criteriaId) => {
    const { user } = this.props;
    let userCanUpdate = true;
    const findCriteria = this.props.productApprovalDetails && this.props.productApprovalDetails.length
      ? this.props.productApprovalDetails.find((item) => item.CriteriaID === parseInt(criteriaId))
      : null;
    if (findCriteria !== undefined && findCriteria !== null && findCriteria !== '') {
      if (findCriteria.UserID !== user.ID || findCriteria.UserID === user.ID) {
        userCanUpdate = false;
      }
    }
    return userCanUpdate;
  };

  render() {
    const classes = this.props.classes;
    const { criteria } = this.props;
    const open = Boolean(this.state.anchorEl);

    var criteria_score = 0;
    if (this.props.scores[this.props.product.ID] !== undefined && this.props.scores[this.props.product.ID][criteria.ID] !== undefined) {
      var score_obj = this.props.scores[this.props.product.ID][criteria.ID];
      criteria_score = parseFloat((parseFloat(score_obj.totalOpted) / parseFloat(score_obj.totalMax)) * 100).toFixed(1);
    }
    let selectedUsersAndTeams = [];
    if (criteria?.Approvers.Users) {
      selectedUsersAndTeams = selectedUsersAndTeams.concat(criteria?.Approvers.Users);
    }
    if (criteria?.Approvers.Teams) {
      selectedUsersAndTeams = selectedUsersAndTeams.concat(criteria?.Approvers.Teams);
    }

    let statusClass = this.getStatusClass();
    let statusMessage = this.getStatusMessage();

    let canUserUpdate = this.checkApprovedState(criteria.ID);

    return (
      <div
        onClick={() => {
          this.props.selectCriteria(criteria);
        }}
        className={classnames(classes.approvalListItem, this.props.activeCriteria?.ID == criteria.ID ? classes.activeCriteria : "", (!this.props.criteriaAccess(criteria)) ? "disabled" : "")}
      >
        {/* <DragIndicatorOutlinedIcon style={{color: 'grey'}}/> */}
        <div className={classes.listScore}>
          {((this.props.getApprovalStatus(criteria.ID, this.props.otherCriteria)?.Status == 0 && (criteria.Status !== 5)) || criteria.Status === 0) && (
            <span className={"approvedScore waiting"}>
              {/* {criteria_score} */}
              {criteria.Status === 0 ? <LockIcon className={classes.approvalIcon} /> : <Image alt="inter com" src={ICOAwaiting} className={classes.approvalIcon} />}
            </span>
          )}

          {(this.props.getApprovalStatus(criteria.ID, this.props.otherCriteria)?.Status == 1 && criteria.Status !== 0 && (criteria.Status !== 5)) && (
            <span className={"approvedScore"}>
              <Image alt="inter com" src={ICOPartialyApproved} className={classes.approvalIcon} />
            </span>
          )}

          {(this.props.getApprovalStatus(criteria.ID, this.props.otherCriteria)?.Status == 2 && criteria.Status !== 0 && (criteria.Status !== 5)) && (
            <span className={"approvedScore"}>
              <Image alt="inter com" src={ICOApproved} className={classes.approvalIcon} />
            </span>
          )}

          {(this.props.getApprovalStatus(criteria.ID, this.props.otherCriteria)?.Status == 3 && criteria.Status !== 0 && (criteria.Status !== 5)) && (
            <span className={"approvedScore"}>
              <Image alt="inter com" src={ICOPartialyApproved} className={classes.approvalIcon} />
            </span>
          )}

          {((this.props.getApprovalStatus(criteria.ID, this.props.otherCriteria)?.Status == 4 && criteria.Status !== 0) || (criteria.Status === 5)) && (
            <span className={"approvedScore"}>
              <Image alt="inter com" src={ICORejected} className={classes.approvalIcon} />
            </span>
          )}
        </div>
        <div className={classes.approvalListTitle}>
          <h6>{criteria.CriteriaName}</h6>
          <span className={statusClass}>
            {statusMessage}
          </span>
        </div>
        <AddApprover
          evaluationId={this.props.evaluation_id}
          criteriaId={criteria.ID}
          criterias={this.props.approvalCriterias?.Response}
          editable={canUserUpdate}
          isApproverPage={true}
          position={"CriteriaHead"}
          hideCollaborator={true}
          onAdd={(user) => {
            this.props.addApprovalCriteriaApprover(this.props.evaluation_id, criteria.ID, { approver_id: user.ID });
          }}
          onSubmit={this.props.addRemoveApproverFn}
          btnLoading={this.props.addApproverProgress}
          users={selectedUsersAndTeams}
          showNotificationIcon={true}
          notificationData={{
            type: "notification.evaluation.criteria.approver.nudge",
            criteria: criteria,
          }}
        />
        <Popover
          className={classes.popover}
          id={'add-vendor'}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handleClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}>

          <List className={classes.menuList}>
            <ListItem
              button
              onClick={() => {
                this.props.showEvaluationCriteriaUpdateDialog(criteria, this.props.evaluation_id, this.props.component_id, this.props.product?.ID);
                this.handleClose();
              }}
              classes={{
                button: classes.listItem,
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <EditIcon className={classes.proNotBlocked} fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Edit
              </Typography>
            </ListItem>
            {criteria.Status !== 5 && <ListItem
              button
              onClick={() => {
                this.props.skipCriteria(criteria);
                this.handleClose();
              }}
              classes={{
                button: classes.listItem,
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <SkipNextIcon className={classes.proNotBlocked} fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Skip
              </Typography>
            </ListItem>}
            <ListItem
              button
              onClick={() => {
                this.props.deleteCriteria(criteria.ID)
                this.handleClose();
              }}
              classes={{
                button: classes.listItem,
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                {/* <Image src={deleteIcon} alt="delete Image" className={classes.deleteImage} /> */}
                <DeleteIcon className={classes.proNotBlocked} fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" >
                Delete
              </Typography>
            </ListItem>

          </List>
        </Popover>
        {canUserUpdate && (this.props.userType === "OrgAdmin" || this.props.userType === "OrgManager") && <IconButton ref={this.btnRef} className={classes.threeDot} onClick={this.handleClick}><MoreVertIcon /></IconButton>}
      </div>
    );

  }
}

export default connector(compose(withStyles(styles))(ApprovalList));