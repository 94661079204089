import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Dialog, DialogContent, Grid, Slide, Button } from '@material-ui/core';
import { fetchEvaluationMetaData, 
    fetchSubCategoriesOfEvaluation, 
    fetchEvaluationPreview, 
    fetchEvaluationSelectedFeatures, 
    updateEvaluationSelectedFeatures, 
    deleteEvaluationSelectedFeatures, 
    updateEvaluationMetaDataWorkflow,
    addSelectedFeaturesForEvaluation 
} from "redux/evaluation/metadata/action";
import { importRequirement } from "redux/evaluation/criteria/action"
import { fetchEvaluationCart } from "redux/evaluation/cart/action";
import Alert from '@material-ui/lab/Alert';
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import whiteTick from "assets/images/whitetick.svg";
import Image from 'Components/Common/image.jsx';
import { Step, Steper } from "Components/Common/Steper/index";
import ArrowBack from "assets/images/arrow_back.svg";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import CheckIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { fetchSubCategoryFeature, fetchProductSubCategory,fetchProductSubCategoryV2 } from "redux/product/subcategory/action";
import { fetchMasterTemplate } from "redux/templates/evaluation/master/action";
import { fetchOrgTemplate } from "redux/templates/evaluation/org/action";
import Creatable from 'react-select/creatable';
import queryString from 'query-string';
import ArrowForward from "assets/images/arrow_forward_white.svg"

import { addWorkflowComponentUserResponse, hideWorkflowEvaluationGuideFormDialog } from "redux/workflow/action";

import BottomActions from "./BottomActions";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const connectedProps = (state) => ({
    isOpen: state.workflow.isShowEvaluationGuideFormDialog,
    evaluation: state.evaluationMetaData.evaluation,
    evaluationId: state.evaluationMetaData.evaluationId,
    fetchProgress: state.evaluationMetaData.fetchProgress,
    fetchImportProgress: state.evaludationCriteria.fetchProgress,
    errorFetch: state.evaluationMetaData.errorFetch,
    updateProgress: state.evaluationMetaData.updateProgressWorkflow,
    updateError: state.evaluationMetaData.errorUpdate,
    subCategoriesOfEvaluationProgress: state.evaluationMetaData.subCategoriesOfEvaluationProgress,
    subCategoriesOfEvaluation: state.evaluationMetaData.subCategoriesOfEvaluation,
    subCategoriesOfEvaluationError: state.evaluationMetaData.subCategoriesOfEvaluationError,
    evaluationPreviewProgress: state.evaluationMetaData.evaluationPreviewProgress,
    evaluationPreview: state.evaluationMetaData.evaluationPreview,
    evaluationPreviewError: state.evaluationMetaData.evaluationPreviewError,
    evaluationSelectedFeaturesError: state.evaluationMetaData.evaluationSelectedFeaturesError,
    evaluationSelectedFeatures: state.evaluationMetaData.evaluationSelectedFeatures,
    evaluationSelectedFeaturesProgress: state.evaluationMetaData.evaluationSelectedFeaturesProgress,
    evaluationUpdateSelectedFeaturesProgress: state.evaluationMetaData.evaluationUpdateSelectedFeaturesProgress,
    evaluationDeleteSelectedFeaturesProgress: state.evaluationMetaData.evaluationDeleteSelectedFeaturesProgress,
    evaluateView: state.applications.evaluateView,
    evaluateViewId: state.applications.evaluateViewId,
    evaluateNeverReview: state.applications.evaluateNeverReview,
    configurations: state.orgUser.fetchAllOrgSettingsSuccess,
    template: state.masterTemplateEvaluation.template,
    fetchTemplateProg: state.masterTemplateEvaluation.fetchTemplateProg,
    orgTemplate: state.orgTemplateEvaluation.template,
    fetchOrgTemplateProg: state.orgTemplateEvaluation.fetchTemplateProg,
    workflowComponentsForms: state.workflow.workflowComponentsForms,
    subCategoryFeatures: state.productSubCategory.subCategoryFeatures,
    fetchSubCategoryProgress: state.productSubCategory.fetchSubCategoryProgress,
    fetchSubCategoryFeaturesProgress: state.productSubCategory.fetchSubCategoryFeaturesProgress,
    subCategories: state.productSubCategory.subCategoryV2,
    subCategoriesFetchProgress: state.productSubCategory.fetchSubCategoryV2Progress,
    addFeaturesProgress: state.evaluationMetaData.addFeaturesProgress,
    addFeaturesError: state.evaluationMetaData.addFeaturesError,
    evaluationFeatures:state.evaluationMetaData.evaluationFeatures,
});

const connectionActions = {
    hideDialog: hideWorkflowEvaluationGuideFormDialog,
    fetchDetails: fetchEvaluationMetaData,
    updateDetails: updateEvaluationMetaDataWorkflow,
    fetchEvaluationCart: fetchEvaluationCart,
    fetchProductSubCategory: fetchProductSubCategory,
    fetchSubCategoryFeature: fetchSubCategoryFeature,
    fetchSubCategoriesOfEvaluation: fetchSubCategoriesOfEvaluation,
    fetchEvaluationPreview: fetchEvaluationPreview,
    fetchEvaluationSelectedFeatures: fetchEvaluationSelectedFeatures,
    updateEvaluationSelectedFeatures: updateEvaluationSelectedFeatures,
    deleteEvaluationSelectedFeatures: deleteEvaluationSelectedFeatures,
    importRequirement: importRequirement,
    fetchMasterTemplate: fetchMasterTemplate,
    fetchOrgTemplate: fetchOrgTemplate,
    addWorkflowComponentUserResponse: addWorkflowComponentUserResponse,
    fetchProductSubCategoryV2:fetchProductSubCategoryV2,
    addSelectedFeaturesForEvaluation:addSelectedFeaturesForEvaluation
}

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
    dialogContent: {
        padding: "0px !important",
        background: '#FFF',
        margin: '0px auto',
    },
    title: {
        fontSize: theme.spacing(2.5),
        marginBottom: theme.spacing(2)
    },
    helptext: {
        fontSize: theme.spacing(1.8)
    },
    alerts: {
        marginTop: theme.spacing(2)
    },
    form: {
        marginTop: theme.spacing(2),
        // width:'60%',
    },
    input: {
        marginBottom: theme.spacing(3),
        "& [class*='MuiFormLabel-root']": {
            color: '#282D30',
            fontWeight: 500,
        },
        "& [class*=MuiOutlinedInput-root]": {
            minHeight: 40,
            color: '#282D30',
            padding: 0
        }
    },
    scheduleTitle: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 500,
        fontSize: theme.spacing(2)
    },
    scheduleTitle1: {
        display: "block",
        marginBottom: theme.spacing(3),
        fontWeight: 400,
        fontSize: theme.spacing(2)
    },
    actions: {
        marginTop: theme.spacing(3)
    },
    loader: {
        textAlign: "center",
        padding: theme.spacing(10, 0)
    },
    dialog: {
        "& [class*='MuiOutlinedInput-input']": {
            fontSize: theme.spacing(1.8)
        }
    },
    datePickers: {
        "& [class*='MuiFormLabel-root']": {
            color: '#475867'
        },
        "& [class*=MuiOutlinedInput-root]": {
            minHeight: 40,
            color: '#282D30'
        }
    },
    stepSection: {
        display: 'flex',
        "&.evaluate-review-details": {
            "& input, [class*=MuiOutlinedInput-root], .editor-formControl > [class*=MuiPaper-root]": {
                PointerEvent: 'none',
                background: '#f5f5f5'
            }
        },

    },
    stepLeftSection: {
        background: '#20253A',
        boxShadow: '4px 0px 4px #2B2D3703',
        minWidth: 278,
        width: 278
    },
    stepRightSection: {
        padding: 50,
        minWidth: 700,
        maxWidth: 800
        // minWidth: 600
    },
    menu: {
        marginTop: theme.spacing(5),
        marginRight: 13,
        "& .step-line": {
            borderLeft: "2px solid #4A87F8"
        },
        "& .step-hideIt": {
            boxShadow: 'none',
            visibility: 'visible !important',
            // backgroundImage: 'none !important'

        },
        "& .step-circle": {
            border: '1px solid #4A87F8',
            background: '#202639',
            boxShadow: 'none',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '4px 4.5px'
        },
        "& .step-completed .step-circle": {
            backgroundColor: '#4A87F8',
            backgroundImage: `url(${whiteTick})`,
        },
        "& [class*=MuiTypography-root]": {
            fontSize: 13,
            textAlign: 'left',
            paddingTop: 1
        }
    },
    outlined: {
        color: "#707070",
        padding: '6px 26px',
        borderColor: "#707070",
        "&:hover": {
            color: "#707070",
            borderColor: "#707070",
        }
    },
    contained: {
        color: '#fff',
        padding: '6px 26px',
        backgroundColor: "#3C3C3C",
        "&:hover": {
            backgroundColor: "#3C3C3C",
        }
    },
    image: {
        width: theme.spacing(3.5),
        marginRight: 20,
        marginTop: 5,
        cursor: 'pointer'
    },
    headSection: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    stepHead: {
        color: '#282D30',
        fontSize: 18,
        margin: 0,
        fontWeight: 500,
        marginBottom: 10
    },
    stepSubHead: {
        color: '#4B4B4B',
        fontSize: 12,
        margin: 0,
        paddingBottom: 20
    },
    inputLabel: {
        display: 'flex',
        border: '2px solid #EEEEEE',
        borderRadius: 10,
        alignItems: 'center',
        margin: '0px 25px 20px 0px',
        padding: 7
    },
    inputLabelSelected: {
        border: '2px solid #4b86f8',
    },
    categoryText: {
        color: '#282D30',
        fontSize: 14,
        fontWeight: 600,
        margin: 0,

    },
    categoryTextSection: {
        paddingLeft: 10
    },
    categorySubText: {
        color: '#282D30',
        fontSize: 12,
        opacity: .6
    },
    categorySection: {
        padding: 50,
        minWidth: 735,
        width: 735,
        minHeight: 800
    },
    categoryImage: {
        paddingLeft: 10,
        width: 57,
        maxWidth: 57,
        minWidth: 57
    },
    rightStepTwo: {
        display: 'flex'
    },
    previewSection: {
        background: '#F7F7F7',
        minWidth: 353,
        padding: '50px 30px'
    },
    previewText: {
        color: '#282D30',
        fontSize: 16,
        fontWeight: 500,
        margin: 0
    },
    previewSubText: {
        marginTop: 0,
        marginBottom: 25,
        fontSize: 12,
        color: '#4B4B4B'
    },
    requirementText: {
        color: '#282D30',
        fontSize: 14,
        fontWeight: 500,
        margin: '0px 0px 10px 0px'
    },
    questionText: {
        color: '#707070',
        fontSize: 13,
        margin: '0px 0px 17px 0px'
    },
    categoryList: {
        marginTop: 20
    },
    textSection: {
        marginTop: 40,
        lineHeight: 1.5
    },
    head: {
        color: '#282D30',
        fontSize: 14,
        marginBottom: 15,
        fontWeight: 500
    },
    featureLabel: {
        border: '1px solid #4A87F8',
        borderRadius: 20,
        padding: '7px 16px',
        marginRight: 25,
        marginTop: 18,
        color: '#4A87F8',
        display: 'inline-flex',
        fontSize: 13,
        cursor: 'pointer',
        transition: 'all'
    },
    iconEle: {
        fontSize: 19,
        marginLeft: 10
    },
    selectedLabel: {
        background: '#4A87F8',
        color: '#fff',
        cursor: 'default'
    },
    removeIcon: {
        display: 'inline-flex',
        cursor: 'pointer',
        marginLeft: 10,
        "& [class*=iconEle]": {
            marginLeft: 0,
        }
    },
    features: {
        overflow: 'auto',
        height: 200,
        marginBottom: 30
    },
    radioBox: {
        width: 13,
        height: 13,
        maxWidth: 13,
        minWidth: 13
    },
    previewInnerSection: {
        maxHeight: 650,
        overflow: 'overlay',
        paddingRight: 10
    },
    backdrop: {
        marginTop: 30,
        height: 100,
        textAlign: 'center'
    },
    editorSection: {
        border: '1px solid #ddd',
        borderRadius: 5,
        marginBottom: 20
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
        color: '#6F6F6F'
    },
});

class EvaluationGuideForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            success: "",
            name: "",
            name_error: "",
            problemStatement: "",
            problemStatement_error: "",
            criteriaSelectionStart: null,
            criteriaSelectionStart_error: "",
            criteriaSelectionEnd: null,
            criteriaSelectionEnd_error: "",
            evaluationStart: null,
            evaluationStart_error: "",
            evaluationEnd: null,
            evaluationEnd_error: "",
            step: 1,
            selectedFeatureList: [],
            selectedFeatureListIds: [],
            featureSize: 50,
            featurePage: 0,
            listOfFeature: [],
            featureQuery: '',
            previewSize: 50,
            previewPage: 0,
            previewQuery: '',
            subCategoryId: '',
            stepTwoError: '',
            isUpdatePage: false,
            subCatQuery: null,
            queryParams: queryString.parse(this.props.location.search),
            featureId: [],
            preDefinedFeature: true,
            defaultTemplateType: "MASTER",
            defaultCriteriaIds: []
        }
        this.submitEvaluationMeta = this.submitEvaluationMeta.bind(this);
    }

    componentDidMount() {
        if (this.props.subCategories.length === 0) {
            this.getSubCategory('');
        }

        this.setState({ step: 2 });
        this.props.fetchSubCategoriesOfEvaluation(this.props.evaluation?.ID);
        this.fetchDefaultEvaluationTemplate();
    }

    fetchDefaultEvaluationTemplate() {
        var templateType = "MASTER";
        var templateId = 1
        if (this.props.configurations !== null) {
            var default_evaluation_setting = this.props.configurations.filter((i) => {
                return i.SettingName === "ORG_DEFAULT_EVALUATION_TEMPLATE";
            })

            if (default_evaluation_setting.length > 0) {
                var settingValue = default_evaluation_setting[0].Value
                if (settingValue !== "NULL") {
                    try {
                        var parsed = JSON.parse(settingValue)
                        if (parsed.type === "ORG") {
                            templateType = "ORG"
                            templateId = parseInt(parsed.tid)
                        } else if (parsed.type === "MASTER") {
                            templateType = "MASTER"
                            templateId = parseInt(parsed.tid)
                        }
                    } catch (e) { }
                }
            }
        }
        this.setState({
            defaultTemplateType: templateType
        })
        if (templateType === "MASTER") {
            this.props.fetchMasterTemplate(templateId, this.props.evaluation?.ID)
        } else {
            this.props.fetchOrgTemplate(templateId, this.props.evaluation?.ID)
        }
    }

    fetchFeatures = () => {
        this.props.fetchSubCategoryFeature(this.state.subCategoryId, this.state.featurePage, this.state.featureSize, this.state.featureQuery);
    }

    selectFeature = (index, item) => {
        console.log(item)
        let selectedFeatures = this.state.selectedFeatureList;
        let selectedFeatureListIds = this.state.selectedFeatureListIds;
        selectedFeatures.push(item);
        selectedFeatureListIds.push(item.ID);
        this.setState({
            selectedFeatureList : selectedFeatures,
            selectedFeatureListIds:selectedFeatureListIds
        })
        
        // this.props.updateEvaluationSelectedFeatures(this.props.evaluation?.ID, { feature_id: item.ID }, false);
        // return new Promise(resolve => setTimeout(resolve, 1000)).then(v => item.ID)
    }

    removeFeature = (index, item) => {
        console.log(item)
        let selectedFeatures = this.state.selectedFeatureList;
        let selectedFeatureListIds = this.state.selectedFeatureListIds;
        let indexItm = selectedFeatures.findIndex(o=>o.ID === item.ID);
        selectedFeatures.splice(indexItm,1);
        selectedFeatureListIds.splice(selectedFeatureListIds.indexOf(item.ID),1);
        this.setState({
            selectedFeatureList : selectedFeatures,
            selectedFeatureListIds:selectedFeatureListIds
        })
        // this.props.deleteEvaluationSelectedFeatures(this.props.match.params.evaluation_id, item.ID);
    }

    removeDuplicateFeature = () => {
        const evaluationSelectedFeatures = this.props.evaluationSelectedFeatures || [];
        const subCategoryFeatures = this.props.subCategoryFeatures;

        for (let i = 0; i < subCategoryFeatures.length; i++) {
            subCategoryFeatures[i]['visible'] = true;
            for (let selecteItem of evaluationSelectedFeatures) {
                if (selecteItem.ID === subCategoryFeatures[i].ID) {
                    subCategoryFeatures[i]['visible'] = false;
                }
            }
        }

        this.setState({ listOfFeature: subCategoryFeatures })

    }

    componentDidUpdate(prevProps) {
        if (this.state.defaultTemplateType === "MASTER") {
            if (this.props.fetchTemplateProg === false && prevProps.fetchTemplateProg === true) {
                if (this.props.template !== null) {
                    this.setState({
                        defaultCriteriaIds: this.props.template.Criterias.map((i) => {
                            return i.ID
                        })
                    })
                }
            }
        } else {
            if (this.props.fetchOrgTemplateProg === false && prevProps.fetchOrgTemplateProg === true) {
                if (this.props.orgTemplate !== null) {
                    this.setState({
                        defaultCriteriaIds: this.props.orgTemplate.Criterias.map((i) => {
                            return i.ID
                        })
                    })
                }
            }
        }

        if (this.props.fetchSubCategoryFeaturesProgress === false && this.state.listOfFeature.length !== this.props.subCategoryFeatures.length) {
            this.removeDuplicateFeature();
        }

        if (!this.state.isUpdatePage && (this.props.location.pathname.indexOf("setup") !== -1 || this.props.location.pathname.indexOf("respond") !== -1)) {
            this.setState({ isUpdatePage: true });
        }

        if ((!this.props.evaluationDeleteSelectedFeaturesProgress && prevProps.evaluationDeleteSelectedFeaturesProgress) || (!this.props.evaluationUpdateSelectedFeaturesProgress && prevProps.evaluationUpdateSelectedFeaturesProgress)) {
            if (this.props.evaluationSelectedFeaturesError === null) {
                this.props.fetchEvaluationSelectedFeatures(this.props.match.params.evaluation_id);
            }
        }

        if (((!this.props.evaluationDeleteSelectedFeaturesProgress && prevProps.evaluationDeleteSelectedFeaturesProgress) || (!this.props.evaluationSelectedFeaturesProgress && prevProps.evaluationSelectedFeaturesProgress))) {
            this.removeDuplicateFeature();

            if (this.state.preDefinedFeature) {
                let featureId = [];
                if (this.props.evaluationSelectedFeatures?.length) {
                    let evaluationSelectedFeatures = this.props.evaluationSelectedFeatures.map((item) => item.ID)

                    let presentId = '';
                    for (let j = 0; j < this.state.featureId.length; j++) {
                        if (evaluationSelectedFeatures.indexOf(parseInt(this.state.featureId)) === -1) {
                            presentId = this.state.featureId[j]
                        }
                    }
                    if (presentId) {
                        featureId.push(presentId)
                    }
                } else {
                    featureId = this.state.featureId;
                }
                this.setState({ preDefinedFeature: false }, () => {
                    this.updateSelectedFeatures(featureId)
                })

            }

            this.setState({ selectedFeatureList: this.props.evaluationSelectedFeatures || [] })
        }

        if (this.props.isOpen === true && prevProps.isOpen === false) {
            this.setState({ listOfFeature: this.props.subCategoryFeatures, step: 1, subCategoryId: '', stepTwoError: '', isUpdatePage: false, subCatQuery: null })
            if (this.props.match.params.evaluation_id !== null) {
                this.setState({
                    error: "",
                    success: "",
                    name: "",
                    name_error: "",
                    problemStatement: "",
                    problemStatement_error: "",
                    criteriaSelectionStart: null,
                    criteriaSelectionStart_error: "",
                    criteriaSelectionEnd: null,
                    criteriaSelectionEnd_error: "",
                    evaluationStart: null,
                    evaluationStart_error: "",
                    evaluationEnd: null,
                    evaluationEnd_error: "",
                    queryParams: queryString.parse(this.props.location.search),
                    preDefinedFeature: true,
                }, () => {
                    if (Object.keys(this.state.queryParams).length) {
                        if (this.state.queryParams.featureId) {
                            this.setState({ featureId: this.state.queryParams.featureId.split(','), subCategoryId: parseInt(this.state.queryParams.subcategory) });
                        }
                    } else {
                        this.setState({ featureId: [] })
                    }
                })
                this.props.fetchDetails(this.props.match.params.evaluation_id);
                this.props.fetchEvaluationSelectedFeatures(this.props.match.params.evaluation_id);
            }
        }

        if (this.props.updateProgress === false && prevProps.updateProgress === true) {
            if (this.props.updateError === null) {
                if (this.state.step === 1) {
                    if (this.state.isUpdatePage) {
                        this.props.hideDialog();
                        if (this.props.match.params.evaluation_id) {
                            this.props.fetchDetails(this.props.match.params.evaluation_id);
                        }
                    } else {
                        if (this.state.featureId.length) {
                            this.moveToStepThree();
                        } else {
                            this.setState({ step: 2 });
                            this.props.fetchSubCategoriesOfEvaluation(this.props.match.params.evaluation_id);
                        }
                    }
                } else if (this.state.step === 2) {
                    this.moveToStepThree();
                } else if (this.state.step === 3) {
                    this.migrateToPage();
                    // this.props.importRequirement(this.props.match.params.evaluation_id, this.state.defaultCriteriaIds, this.state.defaultTemplateType, true)
                }
            } else {
                if (this.props.updateError.message !== undefined) {
                    var errorMessage = this.props.updateError.message;
                    if (errorMessage.indexOf("NotFound Error") !== -1) {
                        this.setState({
                            "error": "Failed to update Evaluation Details."
                        })
                    } else {
                        this.setState({
                            "error": "Something went wrong."
                        })
                    }
                } else {
                    this.setState({
                        "error": "Something went wrong."
                    })
                }
            }
        }

        // if (this.props.fetchImportProgress === false && prevProps.fetchImportProgress === true) {
        //     if (this.props.isOpen === true) {
        //         this.migrateToPage();
        //     }
        // }

        if (this.props.fetchProgress === false && prevProps.fetchProgress === true) {
            if (this.props.errorFetch === null) {
                var state = this.state;
                var evaluation = this.props.evaluation;
                if (evaluation.Name !== null) {
                    state.name = evaluation.Name
                }

                if (evaluation.ProblemStatement !== null) {
                    state.problemStatement = evaluation.ProblemStatement
                }

                if (evaluation.CriteriaSelectionSd !== null) {
                    state.criteriaSelectionStart = new Date(evaluation.CriteriaSelectionSd)
                }

                if (evaluation.CriteriaSelectionEd !== null) {
                    state.criteriaSelectionEnd = new Date(evaluation.CriteriaSelectionEd)
                }

                if (evaluation.EvaluationSd !== null) {
                    state.evaluationStart = new Date(evaluation.EvaluationSd)
                }

                if (evaluation.EvaluationEd !== null) {
                    state.evaluationEnd = new Date(evaluation.EvaluationEd)
                }
                this.setState(state);
            } else {
                if (this.props.errorFetch.message !== undefined) {
                    let errorMessage = this.props.errorFetch.message;
                    if (errorMessage.indexOf("NotFound Error") !== -1) {
                        this.setState({
                            "error": "Failed to Fetch Evaluation Details."
                        })
                    } else {
                        this.setState({
                            "error": "Something went wrong."
                        })
                    }
                }
            }
        }

    }

    moveToStepThree = () => {
        this.setState({ step: 3 });
        if (this.state.subCategoryId) {
            this.fetchFeatures();
            this.props.fetchEvaluationSelectedFeatures(this.props.match.params.evaluation_id);
        } else {
            this.migrateToPage();
        }
    }

    migrateToPage = () => {
        this.props.hideDialog();
    }

    submitEvaluationMeta() {
        this.setState({
            error: "",
            success: "",
            name_error: "",
            problemStatement_error: "",
            criteriaSelectionStart_error: "",
            criteriaSelectionEnd_error: "",
            evaluationStart_error: "",
            evaluationEnd_error: "",
        });

        var name = this.state.name;
        if (name === null || name.trim().length === 0) {
            this.setState({
                name_error: ` Please specify name of ${this.props.evaluateView || this.props.evaluateNeverReview ? 'review' : 'evaluation'}.`
            })
            return
        }

        var problemStatement = this.state.problemStatement;
        if (problemStatement === null || problemStatement.trim().length === 0) {
            this.setState({
                problemStatement_error: `Please specify ${this.props.evaluateView || this.props.evaluateNeverReview ? 'review' : 'evaluation'} goal.`
            })
            return
        }

        var criteriaSelectionStart = this.state.criteriaSelectionStart;
        if (criteriaSelectionStart === null) {
            this.setState({
                criteriaSelectionStart_error: "Please select."
            })
            return
        }

        var criteriaSelectionEnd = this.state.criteriaSelectionEnd;
        if (criteriaSelectionEnd === null) {
            this.setState({
                criteriaSelectionEnd_error: "Please select."
            })
            return
        }

        var evaluationStart = this.state.evaluationStart;
        if (evaluationStart === null) {
            this.setState({
                evaluationStart_error: "Please select."
            })
            return
        }

        var evaluationEnd = this.state.evaluationEnd;
        if (evaluationEnd === null) {
            this.setState({
                evaluationEnd_error: "Please select."
            })
            return
        }

        this.updateDetails(1);
    }

    updateDetails = (status, options = {}) => {

        this.props.updateDetails(this.props.match.params.evaluation_id, {
            // name:this.state.name,
            // problemStatement:this.state.problemStatement,
            // criteriaSelectionSd:this.state.criteriaSelectionStart.toISOString(),
            // criteriaSelectionEd:this.state.criteriaSelectionEnd.toISOString(),
            // evaluationSd:this.state.evaluationStart.toISOString(),
            // evaluationEd:this.state.evaluationEnd.toISOString(),
            ...options
        })

    }


    updateSelectedFeatures = async (item) => {
        for (var i = 0; i < item.length; i++) {
            // let value = await this.selectFeature(i, { ID: parseInt(item[i]) });
        }
    }

    changeStep = (step) => {
        if (step === 2) {
            if (this.state.featureId.length) {
                this.updateDetails(1, { sub_category_id: this.state.subCategoryId });
            } else {
                this.submitEvaluationMeta()
            }
        } else if (step === 3) {
            this.updateDetails(1, { sub_category_id: this.state.subCategoryId });
        } else if (step === 4) {
            var subCategoryId = (this.state.subCategoryId === '' || this.state.subCategoryId === null || this.state.subCategoryId.length === 0) ? 0 : this.state.subCategoryId;
            if (this.state.step === 2 && subCategoryId === 0) {

                this.updateDetails(2, { status: 2, sub_category_id: subCategoryId });
                // this.props.importRequirement(this.props.match.params.evaluation_id, this.state.defaultCriteriaIds, this.state.defaultTemplateType);
            } else {
                this.props.addSelectedFeaturesForEvaluation(this.props.match.params.evaluation_id,{
                    feature_ids : this.state.selectedFeatureListIds
                })
                this.updateDetails(2, { status: 2, sub_category_id: subCategoryId })
            }
        }
    }

    evaluationEndChange = (date) => {
        this.setState({
            evaluationEnd: date
        })

    }

    evaluationStartChange = (date) => {
        this.setState({
            evaluationStart: date,
        })
        if (this.state.evaluationEnd) {
            var diff = moment(date).diff(moment(this.state.evaluationEnd), 'days');
            if (diff > 0) {
                this.setState({
                    evaluationEnd: null,
                })
            }
        }
    }

    criteriaSelectionEndChange = (date) => {
        this.setState({
            criteriaSelectionEnd: date,
        })
        if (this.state.evaluationStart) {
            var diff = moment(date).diff(moment(this.state.evaluationStart), 'days');
            if (diff > 0) {
                this.setState({
                    evaluationStart: null,
                })
            }
        }
    }

    criteriaSelectionStartChange = (date) => {
        this.setState({
            criteriaSelectionStart: date,
        })

        if (this.state.criteriaSelectionEnd) {
            var diff = moment(date).diff(moment(this.state.criteriaSelectionEnd), 'days');
            if (diff > 0) {
                this.setState({
                    criteriaSelectionEnd: null,
                })
            }
        }
    }

    productCatChange = (value) => {
        var me = this;
        this.setState({ subCategoryId: value.ID }, () => {
            me.updateDetails(1, { sub_category_id: this.state.subCategoryId });
        });
    }

    getSubCategory = (e) => {
        if (e === this.state.subCatQuery) {
            return
        }

        this.props.fetchProductSubCategoryV2( {
            "text": e,
            "types": ["subcategory"]
        });

        // this.props.fetchProductSubCategory(0, 0, 100, e, true);
        this.setState({ subCatQuery: e });
    }

    subCategoryChange = (item) => { 
        this.setState({
            stepTwoError: ""
        });
        this.props.fetchEvaluationPreview(item.ID, this.state.previewPage, this.state.previewSize, this.state.previewQuery);
        this.setState({ subCategoryId: item.ID })
    }

    subCategoryChangeForMatch = (item) => {
        this.setState({
            stepTwoError: ""
        });
        this.props.fetchEvaluationPreview(item.id, this.state.previewPage, this.state.previewSize, this.state.previewQuery);
        this.setState({ subCategoryId: parseInt(item.id) })
    }

    render() {
        const classes = this.props.classes;
        var isUpdatePage = false;
        if (this.props.location.pathname.indexOf("setup") !== -1 || this.props.location.pathname.indexOf("respond") !== -1) {
            isUpdatePage = true;
        }


        return <Dialog
            onClose={this.props.hideDialog}
            aria-labelledby="app-integrationDialog"
            open={this.props.isOpen}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={!isUpdatePage ? 'lg' : 'md'}
            scroll="body"
            className={classes.dialog}
            id="evMetaDialog"
        >

            <DialogContent classes={{ root: classes.dialogContent }} >
                <div className={classes.close} onClick={() => this.props.hideDialog()}><CloseIcon /></div>
                <div className={classnames(classes.stepSection, this.props.evaluateView ? 'evaluate-review-details' : '')}>
                    {!isUpdatePage && <div className={classes.stepLeftSection}>
                        <div className={classes.menu}>
                            <Steper>
                                {/* <Step completed={this.state.step > 0} label={'Evaluation details'} isFirst={true} circle={this.state.step > 0}/> */}
                                <Step completed={this.state.step > 1} label={"Pick a Category"} isFirst={true} circle={this.state.step > 1} />
                                <Step completed={this.state.step > 2} label={"Features"} circle={this.state.step > 2} />
                            </Steper>
                        </div>
                    </div>}
                    <div>
                        {this.state.step === 2 && <div>
                            <div>
                                <div className={classes.rightStepTwo}>
                                    <div className={classes.categorySection}>
                                        <div className={classes.headSection}>
                                            {/* <Image src={ArrowBack} alt="arrow_back" className={classes.image} onClick={() => { this.setState({ step: 1 }) }} /> */}
                                            <div>
                                                <p className={classes.stepHead}>Pick a Category </p>
                                                <p className={classes.stepSubHead}>Pick one category that best describes your problem space.</p>
                                            </div>
                                        </div>
                                        <div className={classes.alerts}>
                                            {(this.props.subCategoriesOfEvaluationError !== null || this.state.stepTwoError !== '') && <Alert variant="filled" severity="error">{this.props.subCategoriesOfEvaluationError}{this.state.stepTwoError}</Alert>}
                                        </div>

                                        {/* { this.state.isFromInReview === false && this.props.subCategoriesOfEvaluation.length === 0 &&  this.props.subCategoriesOfEvaluationProgress === false &&( <div style={{"textAlign":"center", marginTop: 30}} > No More Data</div>)} */}

                                        {this.props.subCategoriesOfEvaluation?.length === 0 && this.props.subCategoriesOfEvaluationProgress === false && (<div>
                                            <Creatable
                                                getOptionLabel={e => e.name}
                                                getOptionValue={e => e.id}
                                                isSearchable={true}
                                                options={this.props.subCategories}
                                                className={classes.formControl}
                                                classNamePrefix="select"
                                                placeholder="Select categories that best match the product" name="category"
                                                onInputChange={(e) => {
                                                    this.getSubCategory(e)
                                                }}
                                                onChange={this.subCategoryChangeForMatch}
                                            />
                                        </div>)
                                        }


                                        {this.props.subCategoriesOfEvaluationProgress && <Grid container className={classes.actions}>
                                            <Grid item xs={12} className={classes.backdrop}>
                                                <CircularProgress />
                                            </Grid>
                                        </Grid>}

                                        {!this.props.subCategoriesOfEvaluationProgress && <Grid container className={classnames(classes.actions, classes.categoryList)}>
                                            {this.props.subCategoriesOfEvaluation && this.props.subCategoriesOfEvaluation.map((item, index) => (
                                                <Grid item xs={6} style={{ "textAlign": "left" }} key={index}>
                                                    <label className={classnames(classes.inputLabel, item.ID === this.state.subCategoryId ? classes.inputLabelSelected : '')} htmlFor={'id_' + item.ID} >
                                                        <input type="radio" name='subCategory' checked={item.ID === this.state.subCategoryId} id={'id_' + item.ID} onChange={() => {
                                                            this.subCategoryChange(item)
                                                        }} className={classes.radioBox} />
                                                        <Image src={item.Logo} className={classes.categoryImage} />
                                                        <div className={classes.categoryTextSection}>
                                                            <p className={classes.categoryText}>{item.Name}</p>
                                                        </div>
                                                    </label>
                                                </Grid>))}
                                        </Grid>}

                                        {this.props.updateProgress && <Grid container className={classes.actions}>
                                            <Grid item xs={12} style={{ "textAlign": "right" }}>
                                                <CircularProgress />
                                            </Grid>
                                        </Grid>}
                                        {!this.props.updateProgress && <Grid container className={classes.actions}>
                                            <Grid item xs={6} style={{ "textAlign": "left" }}>
                                            </Grid>
                                            <Grid item xs={6} style={{ "textAlign": "right" }}>
                                                {!isUpdatePage && <Button className={classes.contained} endIcon={
                                                    <Image src={ArrowForward} alt="arrow forward" />
                                                } onClick={() => this.changeStep(this.state.subCategoryId === '' ? 4 : 3)}>
                                                    {this.state.subCategoryId === '' ? "Skip" : "Next"}
                                                </Button>}
                                                {isUpdatePage && <Button className={classes.contained} onClick={() => this.changeStep(this.state.subCategoryId === '' ? 4 : 3)}>
                                                    {this.state.subCategoryId === '' ? "Skip" : "Save"}
                                                </Button>}
                                            </Grid>
                                        </Grid>}
                                    </div>
                                    {this.state.subCategoryId !== '' && <div className={classes.previewSection}>
                                        <p className={classes.previewText}>Preview</p>
                                        <p className={classes.previewSubText}>Below are the features that are part of this problem category.</p>
                                        <div className={classes.alerts}>
                                            {this.props.evaluationPreviewError !== null && <Alert variant="filled" severity="error">{this.props.evaluationPreviewError}</Alert>}
                                        </div>
                                        {this.props.evaluationPreviewProgress && <Grid container className={classes.actions}>
                                            <Grid item xs={12} className={classes.backdrop}>
                                                <CircularProgress />
                                            </Grid>
                                        </Grid>}
                                        <div className={classes.previewInnerSection}>
                                            {this.props.evaluationPreview.map((item, index) => (<div key={index}>
                                                <p className={classes.requirementText}>{index + 1}. {item.Name}</p>
                                                <p className={classes.questionText}>{item.Description}</p>
                                            </div>))}
                                        </div>
                                        {this.props.evaluationPreview.length === 0 && this.props.evaluationPreviewProgress === false && (<div > No More Data</div>)}
                                    </div>}
                                </div>

                            </div>


                        </div>}

                        {this.state.step === 3 && <div>
                            <div className={classes.stepRightSection}>
                                <div className={classes.headSection}>
                                    <Image src={ArrowBack} alt="arrow_back" className={classes.image} onClick={() => {
                                        // this.props.history.push(`/app/evaluate`)
                                        // if (this.state.featureId.length) {
                                        //     this.setState({ step: 1 })
                                        // } else {
                                            this.setState({ step: 2 })
                                        // }
                                    }} />
                                    <div>
                                        <p className={classes.stepHead}>Features </p>
                                        <p className={classes.stepSubHead}>Select features that you care about.</p>
                                    </div>
                                </div>
                                <div className={classes.textSection}>
                                    <p className={classes.head}>Selected Features</p>
                                    <div className={classes.alerts}>
                                        {this.props.evaluationSelectedFeaturesError !== null && <Alert variant="filled" severity="error">{this.props.evaluationSelectedFeaturesError?.message}</Alert>}
                                    </div>
                                    <div className={classes.features}>
                                        {this.state.selectedFeatureList && this.state.selectedFeatureList.map((element, index) => 
                                        <span key={index} className={classnames(classes.featureLabel, classes.selectedLabel, 'animate__animated animate__backInUp animate__faster')}>
                                            {element?.Name} <span onClick={() => this.removeFeature(index, element)} 
                                            className={classes.removeIcon}><CloseIcon className={classes.iconEle} />
                                            </span></span>)}
                                        {this.props.evaluationSelectedFeaturesProgress === true && (
                                            <div className={classes.backdrop}>
                                                <CircularProgress />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.textSection}>
                                    <p className={classes.head}>List of Features</p>
                                    <div className={classes.features} id="featuresList">
                                        <InfiniteScroll
                                            scrollableTarget="featuresList"
                                            dataLength={this.state.listOfFeature?.length} //This is important field to render the next data
                                            next={() => {
                                                var me = this;
                                                this.setState({ featurePage: this.state.featurePage + 1 }, () => {
                                                    me.fetchFeatures();
                                                });
                                            }}
                                            hasMore={
                                                this.state.listOfFeature?.length ===
                                                (this.state.featurePage + 1) * this.state.featureSize
                                            }
                                            endMessage={
                                                <p style={{ textAlign: "center", marginTop: 30 }}>
                                                    {this.state.listOfFeature?.length === 0 && this.props.fetchSubCategoryFeaturesProgress === false && <span>No More Data</span>}
                                                </p>
                                            }
                                        >
                                            {this.state.listOfFeature.map((element, index) => {
                                                if(this.state.selectedFeatureListIds.indexOf(element.ID) !== -1){
                                                    return null;
                                                }
                                                return (element?.visible !== false &&
                                                    <span key={index} className={classnames(classes.featureLabel, 'animate__animated animate__backInDown animate__faster')} onClick={() => this.selectFeature(index, element)}>{element.Name} <CheckIcon className={classes.iconEle} /></span>
                                                )
                                            })}
                                        </InfiniteScroll>
                                        {this.props.fetchSubCategoryFeaturesProgress === true && this.state.listOfFeature?.length === 0 && (
                                            <div className={classes.backdrop}>
                                                <CircularProgress />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!(this.props.updateProgress || this.props.fetchImportProgress) && <Grid container className={classes.actions}>
                                    <Grid item xs={6} style={{ "textAlign": "left" }}>
                                    </Grid>
                                    <Grid item xs={6} style={{ "textAlign": "right" }}>
                                        {!isUpdatePage && <Button className={classes.contained} endIcon={
                                            <Image src={ArrowForward} alt="arrow forward" />
                                        } onClick={() => this.changeStep(4)}>
                                            Next
                                        </Button>}
                                        {isUpdatePage && <Button className={classes.contained} onClick={() => this.changeStep(4)}>
                                            Save
                                        </Button>}
                                    </Grid>
                                </Grid>}
                            </div>
                        </div>}
                    </div>
                </div>
                {false && <BottomActions
                    isLoading={this.props.updateProgress || this.props.fetchImportProgress}
                    step={this.state.step}
                    cancel={() => this.props.history.push(`/app/evaluate`)}
                    btnText={
                        this.state.step === 2
                            ? isUpdatePage ? this.state.subCategoryId === '' ? "Skip" : "Save" : this.state.subCategoryId === '' ? "Skip" : "Next"
                            : isUpdatePage ? "Save" : "Next"
                    }
                    submitResponse={() => {
                        if (this.state.step === 3) {
                            this.changeStep(4)
                        } else if (this.state.step === 2) {
                            this.changeStep(this.state.subCategoryId === '' ? 4 : 3)
                        }
                    }}
                />}
            </DialogContent>
        </Dialog>
    }
}

export default connector(compose(
    withRouter,
    withStyles(styles)
)(EvaluationGuideForm));