import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Paper,
} from "@material-ui/core";

import classnames from "classnames";
import { CommonFn } from "services/commonFn";
import AddInventoryDialog from "../Dialogs/AddInventoryDialog";
import ReactSelect from "react-select";

import { showAddInventoryDialog } from 'redux/hardwareProduct/action';
import editIcon from "assets/images/edit_icon.svg"
import Image from 'Components/Common/image.jsx';
import { fetchUsers } from "redux/usersSettings/action";

const connectedProps = (state) => ({
  isShowAddInventoryDialog: state.hardwareProduct.isShowInventoryDialog,
  isUserProgress: state.authUser.isUserProgress,
  usersSettings: state.usersSettings,
});

const connectionActions = {
  showAddInventoryDialog: showAddInventoryDialog,
  fetchUsers: fetchUsers
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  contentSection: {
    marginTop: 20,
  },
  tabSection: {
    marginTop: 15,
    marginBottom: 15,
  },
  orgProductTab: {
    borderBottom: "1px solid #d8d8d8",
  },
  numberSection: {
    padding: 10,
    borderRadius: 10,
    border: "2px solid #CECECE",
    width: "100",
    height: "inherit",
  },
  nSTitle: {
    wordBreak: "break-word",
    fontWeight: 500,
    letterSpacing: "0px",
    color: "#20253A",
    opacity: 1,
    margin: "0px",
    marginBottom: "5px",
  },
  nSSubTitle: {
    fontWeight: 400,
    letterSpacing: "0px",
    color: "#20253A",
    opacity: 0.5,
    margin: "0px",
  },
  paper: {
    marginTop: 15,
    boxShadow: "none",
    border: "1px solid #ddd",
  },
  btn: {
    background: "#3C3C3C",
    borderRadius: 5,
    color: "#fff",
    fontSize: theme.spacing(1.9),
    minHeight: 35,
    padding: 0,
    marginLeft: 15,
    minWidth: 140,
    "&:hover": {
      background: "#3C3C3C",
    },
  }
});

class InventoryTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row: [
        {
          under_warranty: "YES",
          purchage_on: "2022-01-11T11:26:02+0000",
          warronty_till: "2024-01-10T11:26:02+0000",
          allocated_to: "tonyStark@xyz.com",
        }, {
          under_warranty: "YES",
          purchage_on: "2021-01-25T11:26:02+0000",
          warronty_till: "2023-01-24T11:26:02+0000",
          allocated_to: "michaelJackson@xyz.com",
        }, {
          under_warranty: "No",
          purchage_on: "2020-02-02T11:26:02+0000",
          warronty_till: "",
          allocated_to: "",
        }, { 
          under_warranty: "No",
          purchage_on: "2019-04-16T11:26:02+0000",
          warronty_till: "",
          allocated_to: "vinDiesel@xyz.com",
        }, {
          under_warranty: "No",
          purchage_on: "2018-03-30T11:26:02+0000",
          warronty_till: "",
          allocated_to: "",
        },
      ]
    }
    this.setIsEditTrue = this.setIsEditTrue.bind(this);
  }

  componentDidMount() {
    this.props.fetchUsers(0, 1000, '');
  }

  componentDidUpdate(prevProps) {

  }

  setIsEditTrue(index) {
    var items = this.state.row;
    items.forEach(function (item, k) {
      if (k === index) {
        item['isEdit'] = true;
      } else {
        item['isEdit'] = false;
      }
    });
    this.setState({
      items: items
    })
  }

  handleChange = (selectedOption, index) => {
    console.log(selectedOption)
    console.log(index)
    var items = this.state.row;
    items.forEach(function (item, k) {
      if (k === index) {
        // item.allocated_to = selectedOption;
        item.isEdit = false;
      } 
    });
  }

  fetchUsersList = (query = '') => {
    this.props.fetchUsers(0, 10, query);
  };

  render() {
    const classes = this.props.classes;
    let usersList;
    if (this.props.usersSettings !== undefined && this.props.usersSettings !== null && this.props.usersSettings.users !== undefined) {
      usersList = this.props.usersSettings.users.map((user) => {
        return { ...user };
      });
    }
    console.log(this.props.usersSettings,'UserSettings')

    return (
      <div>
        <div
          className={classes.contentSection}
          onScroll={(e) => this.scrollContent(e)}
        >
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={12} sm={12} md={4}>
              <div className={classes.numberSection}>
                <Typography align="center" className={classes.nSTitle}>
                  Total
                </Typography>
                <Typography align="center" className={classes.nSSubTitle}>
                  5
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className={classes.numberSection}>
                <Typography align="center" className={classes.nSTitle}>
                  Allocated
                </Typography>
                <Typography align="center" className={classes.nSSubTitle}>
                  3
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className={classes.numberSection}>
                <Typography align="center" className={classes.nSTitle}>
                  Non Allocated
                </Typography>
                <Typography align="center" className={classes.nSSubTitle}>
                  2
                </Typography>
              </div>
            </Grid>
          </Grid>
          <br />
          <Grid container justify="flex-end">
            <Button
              className={classes.btn}
              onClick={() => this.props.showAddInventoryDialog(true, '')}
            >Add Inventory</Button></Grid>
          <Paper className={classnames(classes.paper, "details-border")}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Purchased on</TableCell>
                  <TableCell>Under warranty</TableCell>
                  <TableCell>Warranty till</TableCell>
                  <TableCell>Allocated to</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.row.map((item, index) => {
                  var isEdit = item.isEdit === undefined ? false : item.isEdit;
                  return <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{(item?.purchage_on && CommonFn.formateDate(item.purchage_on, true)) || ""}</TableCell>
                    <TableCell>{item.under_warranty}</TableCell>
                    <TableCell>{(item?.warronty_till && CommonFn.formateDate(item.warronty_till, true)) || ""}</TableCell>
                    <TableCell>
                      {isEdit === false && item.allocated_to !== null && item.allocated_to !== '' && <>
                        {item.allocated_to}
                        <Image style={{ marginLeft: 8, marginBottom: -4, cursor: 'pointer' }} onClick={(e) => {
                          e.stopPropagation();
                          this.setIsEditTrue(index);
                        }} alt="Edit" src={editIcon} /></>}

                      {isEdit === true && <>
                        <ReactSelect
                          isClearable
                          isSearchable={true}
                          isMulti={false}
                          classNamePrefix="select"
                          placeholder="Users"
                          options={usersList.map((user) => {
                            return {
                              value: user.ID,
                              label: user.Name,
                            }
                          })}
                          onChange={(e) => this.handleChange(e, index)}
                          onInputChange={(e) => {
                            this.fetchUsersList(e)
                          }}
                          isLoading={this.props.isUserProgress}
                          menuPortalTarget={document.getElementById("dialogUserGroup")}
                          defaultValue={item.allocated_to}
                          value={item.allocated_to}
                        />
                      </>}
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </Paper>
        </div>
        {this.props.isShowAddInventoryDialog && <AddInventoryDialog />}
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(InventoryTab));
