import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import * as classnames from "classnames";
import { setSelectedRequirement, setGoToNextRequirement } from "../../../../../../../../redux/evaluation/criteria/action"
import AnsweredIcon from 'assets/images/evaluation/reqAnswered.svg';
import MandatoryIcon from 'assets/images/evaluation/reqMandatory.svg';
import AnsweredIconWhite from 'assets/images/evaluation/reqAnsweredWhite.svg';
import ReqSent from 'assets/images/evaluation/reqSent.svg';
import ReqResponded  from 'assets/images/evaluation/reqResponded.svg';
import ReqSentWhite from 'assets/images/evaluation/reqSentWhite.svg';
import ReqRespondedWhite  from 'assets/images/evaluation/reqRespondedWhite.svg';

import Image from 'Components/Common/image.jsx';

import {
  showResponseMessageDialog,
  hideResponseMessageDialog,
} from "redux/applications/action";

const connectedProps = (state) => ({
  selectedRequirementId: state.evaludationCriteria.selectedRequirementId,
  selectedProductId: state.evaludationCriteria.selectedProductId,
  isNewChangesFound: state.evaludationCriteria.isNewChangesFound,
});

const connectionActions = {
  setSelectedRequirement: setSelectedRequirement,
  showMessageDialog: showResponseMessageDialog,
  hideMessageDialog: hideResponseMessageDialog,
  setGoToNextRequirement: setGoToNextRequirement
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  mainRootItem: {
    border: '1px solid #222',
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '4px',
  },
  subChild: {
    paddingLeft: '20px'
  },
  parentItem: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: 0
  },
  rootItem: {
    position: 'relative',
    padding: '10px',
    '& :before': {
      position: 'absolute',
      left: '15px',
      top: '40px',
      display: 'block',
      width: '3px',
      bottom: '30px',
      borderRight: '1px solid hsl(206deg 5% 74%)',
      content: '',
    }
  },
  childItem: {
    position: 'relative',
    margin: 0,
    padding: '2px',
    cursor: 'pointer',
    '&:after': {
      position: 'absolute',
      left: '-22px',
      top: '14px',
      display: 'block',
      width: '21px',
      height: '1px',
      /* bottom: 25px, */
      background: 'hsl(206deg 5% 74%)',
      content: '',
    }
  },
  selectedItem: {
    backgroundColor: '#398AF5',
    border: '1px solid #222',
    color: '#fff',
    padding: '2px',
    borderRadius: '4px',
  },
  rootItemNoBefore: {
    padding: '10px'
  },
  redLegend: {
    color: 'red',
    marginLeft: '10px',
    position: 'relative',
    top: '2px',
  },
  greenLegend: {
    color: '#2EB77D',
    marginLeft: '10px',
    position: 'relative',
    top: '4px',
  },
  whiteLegend: {
    color: '#fff',
    marginLeft: '10px',
    position: 'relative',
    top: '4px',
  },
  vendorResponded:{
    color:'#ebc866'
  }
});

class TreeNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goToRequirementId: null
    }

    this.selectNode = this.selectNode.bind(this)
    this.saveAndGoToRequirement = this.saveAndGoToRequirement.bind(this);
  }

  saveAndGoToRequirement(reqId) {
    this.props.setGoToNextRequirement(reqId)
    this.props.showMessageDialog();
  }

  selectNode(id) {
    const { isNewChangesFound} = this.props;
    if (isNewChangesFound) {
      this.saveAndGoToRequirement(id);
    } else {
      this.props.setSelectedRequirement(id);
    }
  }

  render() {

    const { selectedItem, itemData, cls, classes, ...rest } = this.props;
    var childList = [];
    var selectedItem2 = this.props.selectedItem !== undefined ? this.props.selectedItem : '';
    var selectedClass = '';

    if ((this.props.itemData !== null && this.props.itemData?.Childrens !== undefined && this.props.itemData?.Childrens !== null && this.props.itemData?.Childrens.length > 0)) {
      this.props.itemData.Childrens.forEach(function (item, key) {
        if (selectedItem2 === item.ID) {
          selectedClass = classes.selectedItem;
        } else {
          selectedClass = '';
        }
        childList.push(<TreeNode
          {...rest}
          key={item.ID}
          itemData={item}
          selectedItem={selectedItem2}
          cls={classnames(classes.childItem, selectedClass)}
          classes={classes}
        ></TreeNode>);
      });
    }
    return (
      <div className={classnames((this.props.itemData.Childrens != undefined && this.props.itemData.Childrens.length > 0) ? classes.rootItem : classes.rootItemNoBefore)}>

        <p className={this.props.cls} onClick={() => {
          if (this.props.itemData.Type === 1) {
            return;
          }
          this.selectNode(this.props.itemData.ID);
          // this.props.setSelectedRequirement(this.props.itemData.ID)
        }}>
          {this.props.itemData.Name} {this.props.itemData.ResponseStatus && this.props.itemData.ResponseStatus === 'DONE' ? <span className={classes.redLegend}><Image alt="Winer" src={selectedItem2 === this.props.itemData.ID ? ReqRespondedWhite : ReqResponded}  /> </span>  : ''}

          {this.props.itemData.Type !== 1 && this.props.itemData.IsAnswered === true && <span className={selectedItem2 === this.props.itemData.ID ? classes.whiteLegend : classes.greenLegend}><Image alt="Winer" src={selectedItem2 === this.props.itemData.ID ? AnsweredIconWhite : AnsweredIcon} /> </span>}
          {this.props.itemData.Type !== 1 && this.props.itemData.IsAnswered === false && this.props.itemData.IsMandatory === true && <span className={classes.redLegend}><Image alt="Winer" src={MandatoryIcon} /> </span>}
        </p>
        <div className={classes.subChild}>
          {childList}
        </div>
      </div>
    );
  }
}

export default connector(compose(
  withRouter,
  withStyles(styles)
)(TreeNode));

// export default TreeNode;
