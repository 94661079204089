export const requirementTypesOptions = [
  { label: 'Rich Text', value: 1 },
  { label: 'Single Select', value: 3 },
  { label: 'Multi Select', value: 2 },
  { label: 'Binary', value: 4 },
  { label: 'Score', value: 5 },
  { label: 'File Upload', value: 6 },
  { label: 'Simple Text', value: 7 },
  { label: 'Email Address', value: 8 },
  { label: 'Phone', value: 9 },
  { label: 'URL', value: 10 },
  { label: 'Date', value: 11 },
  { label: 'Number', value: 12 },
  { label: 'Currency', value: 13 },
  { label: 'Radio', value: 14 },
  { label: 'Checkbox', value: 15 },
  // { label: 'Users', value: 16 },
  // { label: 'Teams', value: 17 },
  // { label: 'Products', value: 18 },
  // { label: 'Existing Products', value: 19 },
]

export function getRequirementTypeUsingAppCatalogType(answerType) {
  let type = [1]
  if (answerType !== undefined && answerType !== null && answerType !== '') {
    const AnswerType = parseInt(answerType)
    if (1 === AnswerType) type = [7] //Simple
    if (2 === AnswerType) type = [11] // Date
    if (3 === AnswerType) type = [4] // Binary
    if (4 === AnswerType) type = [3] // Binary
    if (5 === AnswerType) type = [2] // Binary
    if (6 === AnswerType) type = [1, 5, 6, 7, 8, 9, 10, 11, 12, 13]  // Table
    if (7 === AnswerType) type = [] // Users
    if (8 === AnswerType) type = [] // Teams
    if (9 === AnswerType) type = [6] // File
    if (11 === AnswerType) type = [1, 7]  // Rich Text
    if (12 === AnswerType) type = [8] // Email
    if (13 === AnswerType) type = [9]  // Phone
    if (14 === AnswerType) type = [10] // URL
    if (19 === AnswerType) type = [5, 12]  // Number
    if (20 === AnswerType) type = [13]  // Currency
  }
  return type
  // let types = [1,2,3,6,7,8,9,10,11,12,13,14,19]
}
// export function getAppCatalogTypeUsingRequirementType() {
//   let types = [
//     1, // Simple Text
//     2, // Date
//     3, // Binary
//     4, // Single Select
//     5, // Multi Select
//     6, // Table
//     11, // Rich Text
//     12, // Email
//     13, // Phone
//     14, // URL
//     19, // Number
//     20  // Currency
//   ]
//   return types
// }


export const typeOptionsSeller = [
  { label: 'Rich Text', value: 1 },
  { label: 'Single Select', value: 3 },
  { label: 'Multi Select', value: 2 },
  { label: 'Binary', value: 4 },
  { label: 'Score', value: 5 },
  { label: 'File Upload', value: 6 },
  { label: 'Simple Text', value: 7 },
  { label: 'Email Address', value: 8 },
  { label: 'Phone', value: 9 },
  { label: 'URL', value: 10 },
  { label: 'Date', value: 11 },
  { label: 'Number', value: 12 },
  { label: 'Currency', value: 13 },
  { label: 'Radio', value: 14 },
  { label: 'Checkbox', value: 15 },
  // { label: 'Users', value: 16 },
  // { label: 'Teams', value: 17 },
  // { label: 'Products', value: 18 },
  // { label: 'Existing Products', value: 19 },
]


export function getRequirementType(req, answerType) {
  let type = 'Rich Text'
  if (answerType !== undefined && answerType !== null && answerType !== '') {
    const AnswerType = parseInt(answerType)
    if (1 === AnswerType) type = 'Rich Text'
    if (2 === AnswerType) type = 'Multi Select'
    if (3 === AnswerType) type = 'Single Select'
    if (4 === AnswerType) type = 'Binary'
    if (5 === AnswerType) type = 'Score'
    if (6 === AnswerType) type = 'File upload'
    if (7 === AnswerType) type = 'Simple Text'
    if (8 === AnswerType) type = 'Email Address'
    if (9 === AnswerType) type = 'Phone'
    if (10 === AnswerType) type = 'URL'
    if (11 === AnswerType) type = 'Date'
    if (12 === AnswerType) type = 'Number'
    if (13 === AnswerType) type = 'Currency'
    if (14 === AnswerType) type = 'Radio'
    if (15 === AnswerType) type = 'Checkbox'
    if (16 === AnswerType) type = 'Users'
    if (17 === AnswerType) type = 'Teams'
    if (18 === AnswerType) type = 'Products'
    if (19 === AnswerType) type = 'Existing Products'
  }
  return type
}


export function getFormItemType(answerType) {
  let type = 'Rich Text'
  if (answerType !== undefined && answerType !== null && answerType !== '') {
    const AnswerType = parseInt(answerType)
    if (1 === AnswerType) type = 'Simple Text'
    if (2 === AnswerType) type = 'Date'
    if (3 === AnswerType) type = 'Binary'
    if (4 === AnswerType) type = 'Single Select'
    if (5 === AnswerType) type = 'Multi Select'
    if (6 === AnswerType) type = '---'
    if (7 === AnswerType) type = 'Users'
    if (8 === AnswerType) type = 'Teams'
    if (9 === AnswerType) type = 'File'
    if (10 === AnswerType) type = 'Products'
    if (11 === AnswerType) type = 'Rich Text'
    if (12 === AnswerType) type = 'Email Address'
    if (13 === AnswerType) type = 'Phone'
    if (14 === AnswerType) type = 'URL'
    if (15 === AnswerType) type = 'Number'
    if (16 === AnswerType) type = 'Currency'
    if (17 === AnswerType) type = 'Existing Products'
    if (18 === AnswerType) type = 'Score'
    if (19 === AnswerType) type = 'Radio'
    if (20 === AnswerType) type = 'Checkbox'
    if (21 === AnswerType) type = 'Integrations'
    if (22 === AnswerType) type = 'Show Message'
  }
  return type
}



// Question Type
// 1---Rich Text
// 2---Multi Select
// 3---Single Select
// 4---Binary
// 5---Score
// 6---File
// 8---Email
// 9---Phone
// 10--URL
// 11--Date
// 12--Number
// 13--Currency
// 14---Radio
// 15--Checkbox
// 16--Users
// 17--Teams
// 18--Products
// 19--Existing Products

// Form Type
//  1 ---Simple Text
//  2 ---Date
//  3 ---Binary
//  4 ---Single Select
//  5 ---Multi Select
//  7 ---Users
//  8 ---Teams
//  9 ---File
//  10---Products
//  11---Rich Text
//  12---Email
//  13---Phone
//  14---URL
//  15---Number
//  16---Currency
//  17---Existing Products
//  18---Score
//  19---Radio
//  20---Checkbox
//  21---Web Hook Trigger

export const binaryOptions = [
  {
    title: 'Yes',
    description: 'yes',
    sequence: 1,
  },
  {
    title: 'No',
    description: 'no',
    sequence: 2,
  }
]

export const questionLibbinaryOptions = [
  {
    title: 'Yes',
    description: 'yes',
    seq: 1,
  },
  {
    title: 'No',
    description: 'no',
    seq: 2,
  }
]


// export const requirementTypesOptions = [
//   { label: 'Rich Text', value: 1 }, Done
//   { label: 'Single Select', value: 3 },
//   { label: 'Multi Select', value: 2 },
//   { label: 'Binary', value: 4 },
//   { label: 'Score', value: 5 }, Done
//   { label: 'File Upload', value: 6 }, Done
//   { label: 'Simple Text', value: 7 },      Done
//   { label: 'Email Address', value: 8 },Done
//   { label: 'Phone', value: 9 }, Done
//   { label: 'URL', value: 10 }, Done
//   { label: 'Date', value: 11 }, Done
//   { label: 'Number', value: 12 }, Done
//   { label: 'Currency', value: 13 }, Done
//   { label: 'Radio', value: 14 },
//   { label: 'Checkbox', value: 15 },
//   // { label: 'Users', value: 16 },
//   // { label: 'Teams', value: 17 },
//   // { label: 'Products', value: 18 },
//   // { label: 'Existing Products', value: 19 },
// ]

export const requirementRuleOperands = [
  {
    value: 1,
    label: "==",
    forTypes: [4,5,11,12,13]
  },
  {
    value: 2,
    label: "in",
    forTypes: [2,3,14,15]
  },
  {
    value: 5,
    label: "!in",
    forTypes: [2,3,14,15]
  },
  {
    value: 6,
    label: "!=",
    forTypes: [4,5,11,12,13]
  },
  {
    value: 7,
    label: ">",
    forTypes: [5,11,12,13]
  },
  {
    value: 8,
    label: ">=",
    forTypes: [5,11,12,13]
  },
  {
    value: 9,
    label: "<",
    forTypes: [5,11,12,13]
  },
  {
    value: 10,
    label: "<=",
    forTypes: [5,11,12,13]
  },
  {
    value: 11,
    label: "Responded",
    forTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  }
];
