import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid, Button } from "@material-ui/core";
import { showSnackBar } from "redux/snackbar/action";
import { fetchDocTypesTabs } from "redux/documentTypesV2/action";
import DocumentFileFields from "./DocumentFileFields";
import * as Validator from "util/Validation";
import SqureButton from "Components/Common/SqureButton";
import UpdateDocumentFileDialog from "./Dialogs/UpdateDocumentFileDialog";

const connectedProps = (state) => ({
  docTypeTabs: state.documentTypeV2.docTypeTabs,
  isLoading: state.documentTypeV2.docTypeTabsProgress,
  updateDocTypeFileProgress: state.documentTypeV2.updateDocTypeFileProgress,
}); 

const connectionActions = {
  showSnackBar: showSnackBar,
  fetchDocTypesTabs: fetchDocTypesTabs,
};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      "& [class*=MuiInputBase-root]": {
        fontSize: '14px !important'
      },
      padding: 17,
      border: "1px solid #ddd",
      borderRadius: 5,
      margin: 10,
      marginRight: 6,
    },
    link: {
      color: "#4b86f8 ",
    },
   
    head: {
      fontSize: "0.9rem",
      fontWeight: 600,
      lineHeight: 1.6,
      color: "#000000de",
      marginBottom: 15,
      marginTop: 5,
    },
    editBtn: {
      background: "#3C3C3C",
      borderRadius: 5,
      color: '#fff',
      border: '1px solid #3c3c3c',
      margin: "15px 7px 5px 0px",
      "&:hover": {
        color: '#3C3C3C'
      },
    },
    saveButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      margin: "20px 7px 20px 0px",
    }
  });

class DocumentFileDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      detailsForm: {},
      showUpdateFileDialog: false,
    };
    
  }

  componentDidMount() {
    if(this.props.document){
      this.props.fetchDocTypesTabs(this.props.document?.type?.tab_id)
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isLoading && prevProps.isLoading) {
      if(this.props.docTypeTabs){
        this.initializeData();
      }
    }
    if (!this.props.updateDocTypeFileProgress && prevProps.updateDocTypeFileProgress) {
        this.setState({isEdit: false})
    }
  }

  initializeData = () => {
    let form = {};
    this.props.docTypeTabs.sections?.forEach(section=> {
      section.items?.forEach(item=> {
        form[item.slug] = this.props.document[item.slug] || "";
      })
    })
    this.setState({detailsForm: form});
  }


  render() {
    const { classes,docTypeTabs,hasUpdateAccess } = this.props;
    const {  isEdit, detailsForm } = this.state;

    console.log(this.props.document,'detailsForm-Document')

    if (this.props.isLoading) {
      return <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
        <CircularProgress />
      </div>
    } 

      return <div>
        <Grid container alignItems="center" justifyContent="flex-end">
          {hasUpdateAccess && <Button className={classes.editBtn} onClick={() => this.setState({ showUpdateFileDialog: true })}>
                Update File
          </Button>}
          {hasUpdateAccess && !isEdit && (
              <Button className={classes.editBtn} onClick={() => this.setState({ isEdit: true })}>
                Edit
              </Button>
            )}
        </Grid>
        {docTypeTabs?.sections?.map((section,index) => (
          <div className={classes.root} key={index}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <p className={classes.head}>
                  {section.name}
                </p>
              </Grid>
            </Grid>
            {section.items &&
              section.items.length > 0 &&
              section.items.map((item, index) => {
                return <DocumentFileFields
                  key={item.id}
                  index={index}
                  type={item.type}
                  title={item.name}
                  additionalData={Validator.isJson(item.additional_data) ? JSON.parse(item.additional_data) : {}}
                  itemId={item.id}
                  value={detailsForm[item.slug]}
                  slug={item.slug}
                  sectionName={section.name}
                  item={item}
                  edit={isEdit}
                  onUpdateValue={(value)=>{
                    this.setState({ detailsForm: {...detailsForm, [item.slug] : value } }); 
                  }}
                />
                })} 
          </div>))}

          {isEdit && <div className={classes.saveButton}>
            <SqureButton variant={"outlined"} onClick={() => {
              this.setState({ isEdit: false })
              this.initializeData()
            }}>
              Cancel
            </SqureButton>
            &nbsp;&nbsp;&nbsp;
            <SqureButton
              variant={"contained"}
              disabled={this.props.updateDocTypeFileProgress}
              onClick={() => {
                let updatedData = {...detailsForm};
                if(updatedData["total_cost"]){
                  updatedData["total_cost"] = {
                    currency: updatedData["total_cost"].type?.value || "USD",
                    value: updatedData["total_cost"].value || '0.00',
                  }
                }
                this.props.onUpdateData(updatedData);
              }}
            >
              Save
            </SqureButton>
          </div>}

          {this.state.showUpdateFileDialog && <UpdateDocumentFileDialog
          hideDialog={()=> {
            this.setState({showUpdateFileDialog: false})
          }}
          document={this.props.document}
        />}

        </div>
    }
  }

export default connector(compose(withRouter, withStyles(styles))(DocumentFileDetails));
