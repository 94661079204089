import React from "react";
import { connect } from "react-redux";
import { createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";

const connectedProps = () => ({});

const connectionActions = {};

var connector = connect(connectedProps, connectionActions);

const styles = (theme) =>
  createStyles({
    root: {
      paddingTop: "40px",
      paddingLeft: "24px",
    },
    heading: {
      fontFamily: "Manrope",
      fontSize: "20px",
      fontWeight: 600,
      color: "#003B4D",
      marginBottom: "24px",
    },
    menuName: {
      fontFamily: "Manrope",
      padding: "8px 0px 8px 8px",
      fontSize: "14px",
      fontWeight: 600,
      cursor: "pointer",

      "&:hover": {
        borderRadius: `6px 0 0 6px`,

        background: "#003b4d0d",
      },
    },
    activeMenuName: {
      fontFamily: "Manrope",
      fontWeight: 600,
      padding: "8px 0px 8px 8px",
      fontSize: "14px",
      borderRadius: `6px 0 0 6px`,
      borderRight: `2px solid #17BEBB`,
      background: "#003b4d0d",
      cursor: "pointer",
    },
  });

function SideBar(props) {
  const { classes, activeMenu } = props;
  const menuItems = [
    { label: "Company", id: "COMPANY_PROFILE" },
    {
      label: "Environmental, Social, Governance & Diversity",
      id: "ENVIRONMENTAL",
    },
    { label: "Risk & Compliance", id: "RISK_COMPLIANCE" },
    { label: "Financial Performance", id: "FINANCIAL_PERFORMANCE" },
    { label: "Ratings & References", id: "RATING_REFERENCES" },
  ];

  return (
    <div className={classes.root}>
      {menuItems.map((itm, k) => (
        <Typography
          key={k}
          onClick={() => {
            props.handleClickMenu(itm.id);
          }}
          className={
            itm.id === activeMenu ? classes.activeMenuName : classes.menuName
          }
        >
          {itm.label}
        </Typography>
      ))}
    </div>
  );
}

export default connector(compose(withRouter, withStyles(styles))(SideBar));
